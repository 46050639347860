export const w1 = [
    [
      0.016886446624994278,
      -0.033719923347234726,
      0.0722372904419899,
      0.006570759229362011,
      -0.08489660173654556,
      0.006825957912951708,
      -0.08230569213628769,
      0.03145620971918106,
      -0.02761971205472946,
      -0.004070316907018423,
      -0.031864188611507416,
      0.024765238165855408,
      -0.02660229802131653,
      0.03642120584845543,
      0.03593726456165314,
      0.045189812779426575,
      0.0597437247633934,
      0.03138304874300957,
      -0.03791413828730583,
      0.018872734159231186,
      -0.025776930153369904,
      0.016986224800348282,
      0.009626887738704681,
      0.022692488506436348,
      -0.04057466238737106,
      0.008076684549450874,
      0.045417316257953644,
      -0.038525696843862534,
      -0.02495376206934452,
      0.06358711421489716,
      0.0013754817191511393,
      0.0689772516489029,
      -0.061502207070589066,
      -0.06005435436964035,
      -0.05900976061820984,
      -0.08764186501502991,
      0.005650459788739681,
      -0.013399058021605015,
      -0.01867959462106228,
      -0.023946503177285194,
      0.08422446995973587,
      -0.1001715138554573,
      -0.013677366077899933,
      -0.03551573306322098,
      0.05972439795732498,
      0.02536596730351448,
      0.0369996652007103,
      -0.012933272868394852,
      -0.050941552966833115,
      -0.042116791009902954,
      -0.018476394936442375,
      -0.08530773967504501,
      -0.05445854365825653,
      0.013637835159897804,
      -0.04851013794541359,
      -0.008834155276417732,
      0.02321409434080124,
      0.11397355049848557,
      0.051347725093364716,
      0.023090053349733353,
      -0.035033416002988815,
      0.028618060052394867,
      -0.029070373624563217,
      -0.030875984579324722,
      0.01898791827261448,
      -0.0014723496278747916,
      -0.007363700307905674,
      0.018414050340652466,
      -0.022073637694120407,
      0.0862102061510086,
      0.011177781969308853,
      -0.02148483693599701,
      -0.03357997536659241,
      0.029047833755612373,
      0.05731946974992752,
      -0.028515858575701714,
      -0.005110332276672125,
      -0.02865680493414402,
      -0.025725988671183586,
      0.025754131376743317,
      -0.025239961221814156,
      -0.020500291138887405,
      -0.06582508981227875,
      -0.08672168105840683,
      -0.015055984258651733,
      0.03880824148654938,
      0.06297557055950165,
      0.06215720996260643,
      0.012577573768794537,
      -0.12591731548309326,
      0.0031364657916128635,
      0.10032340884208679,
      0.054033294320106506,
      0.029877711087465286,
      -0.06004717946052551,
      -0.02606874890625477,
      0.018041064962744713,
      -0.024239806458353996,
      -0.13020512461662292,
      -0.03743370994925499,
      0.02136797271668911,
      0.019450070336461067,
      -0.011694762855768204,
      0.04078299179673195,
      -0.04026288166642189,
      -0.017039045691490173,
      -0.05545399338006973,
      0.05787768214941025,
      0.025449205189943314,
      -0.0611923411488533,
      -0.07188872992992401,
      -0.029109373688697815,
      -0.046368032693862915,
      0.0357431024312973,
      -0.014365839771926403,
      0.023469118401408195,
      0.014524606987833977,
      0.02111864648759365,
      -0.004610639996826649,
      -0.090654656291008,
      0.060350045561790466,
      0.09626851230859756,
      0.0034835843835026026,
      -0.012208481319248676,
      -0.006294244900345802,
      0.0319441482424736,
      -0.038533203303813934,
      -0.003252875292673707,
      0.022720739245414734,
      -0.06702331453561783,
      0.07249360531568527,
      -0.04995439201593399,
      0.006895397789776325,
      0.02320059947669506,
      0.022640975192189217,
      0.01250835508108139,
      0.004225814249366522,
      -0.04270221292972565,
      0.0407879576086998,
      -0.052139658480882645,
      -0.05893447995185852,
      0.037250544875860214,
      0.03079160861670971,
      -0.06657601147890091,
      0.04946465417742729,
      -0.015073915012180805,
      0.013871018774807453,
      -0.0336676724255085,
      0.02790200337767601,
      0.0483526848256588,
      0.0011568513000383973,
      -0.0004303936439100653,
      0.07499228417873383,
      -0.06615187972784042,
      -0.04889380559325218,
      0.030897507444024086,
      -0.07420273125171661,
      0.04880397766828537,
      0.05427884683012962,
      0.052613839507102966,
      0.06881623715162277,
      0.0335419625043869,
      -0.0763770043849945,
      0.021115059033036232,
      0.04696714133024216,
      0.01154649630188942,
      -0.060191210359334946,
      -0.05877585709095001,
      0.07739312201738358,
      -0.007184762507677078,
      -0.010258262045681477,
      -0.0011460966197773814,
      0.03678075969219208,
      -0.009132311679422855,
      0.012782329693436623,
      -0.026814274489879608,
      0.007299947086721659,
      -0.008696658536791801,
      -0.028631431981921196,
      -0.049304209649562836,
      0.0774528905749321,
      -0.02901460789144039,
      -0.025617383420467377,
      0.046651825308799744,
      -0.043688103556632996,
      -0.07872427999973297,
      -0.03315248340368271,
      -0.08394099026918411,
      0.0725623071193695,
      0.00018621343770064414,
      0.07416683435440063,
      0.03624716401100159,
      -0.051893994212150574,
      -0.028249170631170273,
      0.024670790880918503,
      0.05792903155088425,
      0.04832173138856888,
      0.061700984835624695,
      0.009390823543071747,
      -0.02139878459274769,
      0.05664970353245735,
      -0.014259720221161842,
      -0.0077524734660983086,
      0.02439708076417446,
      -0.08628903329372406,
      0.02285505086183548,
      -0.007911905646324158,
      0.0030347700230777264,
      0.007750327233225107,
      0.008728502318263054,
      0.07487209886312485,
      0.015347291715443134,
      0.04935029521584511,
      -0.01963423565030098,
      0.06772280484437943,
      -0.015485855750739574,
      0.10809937119483948,
      0.008984468877315521,
      0.005733761470764875,
      0.00808907300233841,
      -0.02027810923755169,
      -0.004532268270850182,
      0.018608693033456802,
      0.005909820552915335,
      -0.008405163884162903,
      -0.007676603738218546,
      -0.09650658816099167,
      -0.023065898567438126,
      0.03152866289019585,
      0.012755443342030048,
      0.020764166489243507,
      0.05387497320771217,
      0.04987074062228203,
      -0.002928214380517602,
      -0.0030564225744456053,
      0.0011890755267813802,
      0.02516317367553711,
      0.0004264900053385645,
      0.026148218661546707,
      0.0714450478553772,
      -0.08499564230442047,
      0.039206795394420624,
      -0.04265246540307999,
      -0.00604961346834898,
      0.07014655321836472,
      -0.08918961882591248,
      0.05455230548977852,
      -0.01623062789440155,
      0.03445103019475937,
      -0.01820245571434498,
      0.04438731074333191,
      -0.05582411587238312,
      -0.12032217532396317,
      -0.03312618285417557,
      -0.057108182460069656,
      0.0003576618619263172,
      -0.004981609992682934,
      -0.01371944509446621,
      0.05449191853404045,
      0.011460261419415474,
      0.017060238867998123,
      -0.019382832571864128,
      6.729766755597666e-05,
      0.04892744496464729,
      -0.06156766042113304,
      0.024853216484189034,
      -0.04545201361179352,
      -0.04860169067978859,
      0.007669394835829735,
      0.08982590585947037,
      -0.0781489834189415,
      0.005219609942287207,
      0.022590594366192818,
      0.07936721295118332,
      0.06782197952270508,
      -0.01326646376401186,
      -0.023589005693793297,
      -0.04103195667266846,
      0.005419163499027491,
      -0.06787973642349243,
      -0.12577268481254578,
      0.020106475800275803,
      0.009817470796406269,
      -0.0058578685857355595,
      0.0470612496137619,
      -0.06768137216567993,
      -0.06525233387947083,
      0.0004604125570040196,
      -0.027594689279794693,
      -0.0028933233115822077,
      -0.09082668274641037,
      0.014413416385650635,
      -0.01789182797074318,
      -0.03164743259549141,
      0.04261510819196701,
      -0.008366154506802559,
      -0.03931311517953873,
      0.008642968721687794,
      -0.016206976026296616,
      -0.027015455067157745,
      0.015660053119063377,
      -0.07600052654743195,
      0.03480306267738342,
      -0.0145416846498847,
      0.06474006175994873,
      -0.053251009434461594,
      0.004294614307582378,
      -0.04141902178525925,
      -0.016371529549360275,
      0.03666767477989197,
      0.03424960374832153,
      -0.09686598181724548,
      -0.08658137917518616,
      -0.004103081300854683,
      0.02339434251189232,
      0.013745376840233803,
      -0.004200883209705353,
      -0.05421047657728195,
      -0.049013931304216385,
      0.016370663419365883,
      -0.0227962639182806,
      0.06288572400808334,
      -0.056059032678604126,
      0.04920947551727295,
      0.03205418214201927,
      -0.003971780184656382,
      0.05730389803647995,
      -0.011997215449810028,
      -0.023241693153977394,
      -0.009287801571190357,
      0.04051695019006729,
      -0.03086521290242672,
      -0.01857549510896206,
      -0.02496742270886898,
      0.002305696252733469,
      0.0016870817635208368,
      0.06055561080574989,
      0.12628820538520813,
      -0.0845053642988205,
      0.06608814001083374,
      0.026942690834403038,
      -0.01933857426047325,
      -0.022992027923464775,
      0.01574942283332348,
      0.0005563392769545317,
      0.030481167137622833,
      0.07147723436355591,
      0.0011164422612637281,
      -0.06104440614581108,
      0.004620279651135206,
      0.0178716778755188,
      0.0406271331012249,
      -0.03045017272233963,
      0.036083005368709564,
      -0.04014797881245613,
      -0.07242336869239807,
      0.09616425633430481,
      -0.02566266991198063,
      -0.04333046078681946,
      0.055804766714572906,
      -0.08733805269002914,
      0.05087592452764511,
      0.09494716674089432,
      -0.01862252876162529,
      0.019061468541622162,
      0.02227601408958435,
      0.05880289152264595,
      -0.04739297926425934,
      0.00574148865416646,
      0.0358678363263607,
      -0.02667287364602089,
      -0.045481450855731964,
      0.02000998519361019,
      -0.008965404704213142,
      0.04110569506883621,
      0.015026642940938473,
      0.050843365490436554,
      0.021416286006569862,
      0.017995284870266914,
      -0.025028692558407784,
      0.02437281422317028,
      -0.015017567202448845,
      -0.005869439337402582,
      -0.016421226784586906,
      -0.05609448254108429,
      0.024352964013814926,
      -0.03296850249171257,
      0.012831728905439377,
      -0.010357171297073364,
      0.04207344725728035,
      0.0006144103826954961,
      0.012766536325216293,
      0.10464542359113693,
      0.034812990576028824,
      -0.001540503348223865,
      0.04525091499090195,
      -0.0131502291187644,
      -0.03124396689236164,
      0.00021378362725954503,
      0.0784619003534317,
      -0.07261072844266891,
      0.03308657929301262,
      0.021884078159928322,
      -0.04984895884990692,
      0.017433887347579002,
      -0.027810420840978622,
      -0.04516990855336189,
      0.08354029059410095,
      0.05880357697606087,
      0.037540532648563385,
      0.020575447008013725,
      -0.001418109517544508,
      0.042099226266145706,
      -0.08112052083015442,
      -0.043512631207704544,
      0.007589174434542656,
      0.0766991525888443,
      0.04147679731249809,
      -0.0171560849994421,
      0.03474242240190506,
      -0.03463392332196236,
      0.02068186178803444,
      -0.04387937858700752,
      -0.024143336340785027,
      -0.00411983160302043,
      0.02588440105319023,
      -0.04958229511976242,
      0.03730416297912598,
      -0.040822599083185196,
      -0.0021999969612807035,
      0.013648014515638351,
      0.10712429136037827,
      -0.04499036446213722,
      0.010402211919426918,
      0.057777758687734604,
      0.06906697154045105,
      0.02091062441468239,
      0.0054632751271128654,
      0.009750965051352978,
      -0.03470301628112793,
      0.013624202460050583,
      -0.02715512551367283,
      0.03150738403201103,
      -0.05672235041856766,
      -0.01515081338584423,
      0.0470043309032917,
      0.056179266422986984,
      -0.0004415150615386665,
      -0.019224589690566063,
      -0.021381162106990814,
      0.06345061212778091,
      -0.0040619829669594765,
      -0.136280357837677,
      -0.017740951851010323,
      0.016163295134902,
      0.039146021008491516,
      -0.03663834556937218,
      0.016745997592806816,
      0.09538231790065765,
      -0.007199503015726805,
      0.01909911446273327,
      -0.06431004405021667,
      0.1002405658364296,
      -0.018449461087584496,
      0.060192354023456573,
      0.05243698135018349,
      -0.027462491765618324,
      -0.026332473382353783,
      0.020417707040905952,
      -0.007284075487405062,
      -0.006543806754052639,
      0.02959406189620495,
      0.06685790419578552,
      -0.01318348664790392,
      -0.04857775941491127,
      0.02029099315404892,
      0.08011210709810257,
      -0.010826999321579933,
      0.060421381145715714,
      0.01561057846993208,
      0.05480320006608963,
      0.035919781774282455,
      0.029610035941004753,
      0.004818966146558523,
      -0.060310859233140945,
      -0.036491118371486664,
      0.09295709431171417,
      0.053077924996614456,
      -0.0098195755854249,
      0.018725091591477394,
      0.06642758101224899,
      -0.09648284316062927,
      0.09132566303014755,
      -0.01706312783062458,
      0.047341085970401764,
      -0.04754769429564476,
      -0.05423160642385483,
      -0.009468631818890572,
      0.018031813204288483,
      0.016029397025704384,
      -0.0038797659799456596,
      0.03458643704652786,
      -0.04276936501264572
    ],
    [
      0.0819578766822815,
      -0.05939793959259987,
      -0.032411836087703705,
      -0.0372200533747673,
      0.037835098803043365,
      0.010409815236926079,
      -0.02709686942398548,
      0.011331824585795403,
      0.050827715545892715,
      0.016206135973334312,
      -0.056722696870565414,
      -0.02669677510857582,
      -0.0028556822799146175,
      0.01653931848704815,
      -0.04322490841150284,
      -0.05331280827522278,
      0.044013749808073044,
      -0.029191799461841583,
      0.005047128070145845,
      -0.04712461680173874,
      0.033684853464365005,
      -0.04442032054066658,
      -0.026097189635038376,
      -0.040700171142816544,
      0.06435120850801468,
      -0.02589295618236065,
      -0.01620737835764885,
      -0.049413491040468216,
      0.029848406091332436,
      -0.03365253657102585,
      -0.030866170302033424,
      0.008198194205760956,
      0.11823263019323349,
      -0.013928673230111599,
      -0.1313501000404358,
      0.04434190317988396,
      0.0335591696202755,
      0.07428722828626633,
      -0.0049522798508405685,
      -0.02894001081585884,
      0.014384955167770386,
      0.019736068323254585,
      -0.08499426394701004,
      0.004833838902413845,
      -0.03082423098385334,
      -0.014077805913984776,
      -0.007440340239554644,
      0.04405311495065689,
      0.03819132223725319,
      -0.018903015181422234,
      0.06658945232629776,
      0.043809935450553894,
      0.01063303742557764,
      -0.05746132507920265,
      0.05723915249109268,
      0.021607408300042152,
      0.0950276106595993,
      0.07953810691833496,
      0.05130669102072716,
      -0.03201634809374809,
      0.07053928077220917,
      -0.040471017360687256,
      0.0560154989361763,
      0.07734417915344238,
      0.026576517149806023,
      0.014637849293649197,
      -0.004733721725642681,
      -0.02066788822412491,
      -0.09704509377479553,
      0.03467434272170067,
      -0.057678092271089554,
      0.022312166169285774,
      0.07465396821498871,
      0.013765675947070122,
      0.015524912625551224,
      -0.02765814960002899,
      -0.052614953368902206,
      0.03449373319745064,
      0.10999675840139389,
      0.01655697263777256,
      -0.04073983058333397,
      -0.044967975467443466,
      -0.05558234080672264,
      -0.015696873888373375,
      -0.009291419759392738,
      -0.10588721185922623,
      0.06294753402471542,
      0.036755383014678955,
      0.033714015036821365,
      0.0035437738988548517,
      -0.045356497168540955,
      0.02368541620671749,
      0.01176057942211628,
      -0.03970564529299736,
      -0.020790431648492813,
      0.018489420413970947,
      0.04139294847846031,
      0.08854405581951141,
      -0.032291948795318604,
      0.08799920976161957,
      -0.046509966254234314,
      -0.021058250218629837,
      0.026183519512414932,
      0.023048941045999527,
      -0.007055966183543205,
      -0.013891689479351044,
      0.02694964036345482,
      0.04159342497587204,
      0.07537113130092621,
      -0.011303535662591457,
      -0.07879095524549484,
      0.00805511511862278,
      -0.01546401996165514,
      -0.06947209686040878,
      0.01151369046419859,
      0.0443405881524086,
      -0.0724092349410057,
      0.007849454879760742,
      0.03200880065560341,
      -0.010246800258755684,
      0.013217469677329063,
      -0.012252450920641422,
      -0.0014702052576467395,
      -0.10036057233810425,
      -0.07819962501525879,
      -0.0022026514634490013,
      0.01982569321990013,
      -0.07115683704614639,
      0.008492245338857174,
      -0.017713557928800583,
      0.04370137304067612,
      0.034205157309770584,
      0.01078665442764759,
      -0.021547425538301468,
      0.007414417341351509,
      -0.05775345116853714,
      -0.0602726936340332,
      -0.023989813402295113,
      0.014360006898641586,
      -0.018099628388881683,
      0.018745865672826767,
      -0.009792541153728962,
      -0.030839666724205017,
      -0.022975848987698555,
      -0.012527353130280972,
      0.05987584590911865,
      0.04795970395207405,
      -0.0044996244832873344,
      -0.06498321890830994,
      0.08728887885808945,
      -0.018845079466700554,
      0.039807938039302826,
      0.030057067051529884,
      0.032786283642053604,
      0.07138871401548386,
      0.00018472068768460304,
      0.006994117982685566,
      -0.014717120677232742,
      0.019813673570752144,
      -0.012032958678901196,
      0.0022574535105377436,
      -0.02140474133193493,
      -0.01843474805355072,
      0.0308308694511652,
      -0.09949729591608047,
      0.03102351725101471,
      0.03779594227671623,
      0.024038061499595642,
      0.009420722723007202,
      -0.04246346279978752,
      -0.08818332850933075,
      -0.03235090523958206,
      -0.023804781958460808,
      -0.030565831810235977,
      -0.0473826602101326,
      0.009683245792984962,
      0.01263855118304491,
      0.021165547892451286,
      -0.009420120157301426,
      0.0002029640309046954,
      0.014674162492156029,
      0.0009169299737550318,
      -0.040892645716667175,
      -0.011086520738899708,
      0.10771411657333374,
      -0.028714768588542938,
      0.005448304582387209,
      -0.00568575132638216,
      0.043707869946956635,
      0.045294735580682755,
      -0.037607498466968536,
      -0.018297679722309113,
      -0.025077443569898605,
      0.004743590019643307,
      -0.053045399487018585,
      0.009372053667902946,
      0.02954295463860035,
      0.011269398964941502,
      0.016502877697348595,
      0.0013225394068285823,
      -0.029317090287804604,
      0.03434494137763977,
      0.01721329614520073,
      0.0224988404661417,
      -0.033402297645807266,
      0.045994844287633896,
      -0.037612736225128174,
      -0.01217933651059866,
      -0.044153083115816116,
      -0.05419262871146202,
      -0.005260040983557701,
      -0.011130142956972122,
      0.006246373988687992,
      0.03707500919699669,
      0.05462070181965828,
      0.013503988273441792,
      0.05038220062851906,
      0.02826017513871193,
      -0.050989389419555664,
      0.037916965782642365,
      0.056592803448438644,
      -0.03700399771332741,
      -0.082761250436306,
      0.020938336849212646,
      -0.006382178049534559,
      0.03328176587820053,
      -0.13298137485980988,
      -0.06048927828669548,
      -0.01611916907131672,
      -0.03998010978102684,
      0.012860666029155254,
      -0.028374336659908295,
      -0.007411541882902384,
      -0.0406268909573555,
      0.04954710975289345,
      -0.04101679101586342,
      -0.0016783669125288725,
      0.02178099751472473,
      0.036304306238889694,
      0.02930474653840065,
      -0.10701677948236465,
      0.07381786406040192,
      -0.014682399109005928,
      -0.07211098074913025,
      -0.028249874711036682,
      -0.023615729063749313,
      -0.0006210374413058162,
      -0.038891155272722244,
      0.04857040196657181,
      0.03825734928250313,
      0.027226893231272697,
      0.025439908728003502,
      -0.01956920698285103,
      -0.0011825705878436565,
      0.008331868797540665,
      0.09280126541852951,
      0.006028780713677406,
      -0.017993981018662453,
      -0.023662138730287552,
      -0.04254041239619255,
      -0.06468978524208069,
      -0.014917932450771332,
      0.03351271152496338,
      0.009296256117522717,
      0.02438829466700554,
      -0.02301047183573246,
      -0.09686584025621414,
      -0.009183401241898537,
      0.05416431650519371,
      -0.04726386442780495,
      0.03237305209040642,
      0.03701766952872276,
      -0.021530089899897575,
      0.09075183421373367,
      0.02102045714855194,
      -0.0014782679500058293,
      0.051691681146621704,
      -0.038859229534864426,
      0.035357698798179626,
      -0.05433139204978943,
      0.012490135617554188,
      -0.060951247811317444,
      -0.010514280758798122,
      0.008610966615378857,
      0.09711956977844238,
      -0.00018186411762144417,
      0.05431295186281204,
      -0.06829740852117538,
      0.006723891012370586,
      0.016303518787026405,
      0.03314664587378502,
      0.03711719065904617,
      0.03890402615070343,
      0.0007121270173229277,
      0.04153382405638695,
      0.00518441665917635,
      -0.00503819715231657,
      -0.04079288989305496,
      0.03524958714842796,
      -0.05829021334648132,
      -0.03885283321142197,
      -0.03756553307175636,
      0.03891364485025406,
      0.06866776198148727,
      0.048272792249917984,
      0.021758059039711952,
      0.04404473304748535,
      -0.004183115437626839,
      -0.0015914203831925988,
      -0.013124074786901474,
      -0.028190338984131813,
      -0.06363680213689804,
      0.03668232262134552,
      -0.03676334768533707,
      0.009698892943561077,
      -0.03226826712489128,
      -0.010022197850048542,
      0.006095745135098696,
      0.04507848992943764,
      -0.050936415791511536,
      -0.011318574659526348,
      -0.09895459562540054,
      0.01632562465965748,
      -0.0068240175023674965,
      -0.055205900222063065,
      0.002770288148894906,
      -0.04885319620370865,
      -0.022695589810609818,
      0.030867310240864754,
      0.03416680172085762,
      0.05880534276366234,
      0.022412296384572983,
      -0.0059426696971058846,
      0.03259905055165291,
      0.053840771317481995,
      -0.013612593524158001,
      0.06963504105806351,
      0.05517051741480827,
      -0.02264774776995182,
      -0.001528239925391972,
      -0.1191660687327385,
      -0.11072183400392532,
      -0.03391209617257118,
      0.0031421117018908262,
      0.018759766593575478,
      0.021923035383224487,
      -0.015337090939283371,
      -0.014538761228322983,
      -0.04348952695727348,
      -0.0699671059846878,
      -0.027503788471221924,
      -0.026953978464007378,
      -0.08229688555002213,
      0.016077503561973572,
      -0.008262231945991516,
      0.06719557195901871,
      -0.10224604606628418,
      0.05120617523789406,
      0.05686681345105171,
      -0.05409912019968033,
      -0.03312106803059578,
      -0.07754825800657272,
      -0.03417518362402916,
      -0.060959186404943466,
      -0.010543505661189556,
      0.04165227711200714,
      0.04416675120592117,
      0.03850783780217171,
      0.07656282931566238,
      -0.06739924848079681,
      0.041757892817258835,
      0.030407462269067764,
      -0.08779627084732056,
      0.0243424940854311,
      0.0856437012553215,
      -0.00678060669451952,
      0.05336062237620354,
      -0.011668185703456402,
      0.008176411502063274,
      0.068077951669693,
      -0.034454524517059326,
      0.006026672199368477,
      0.03353877738118172,
      0.06989831477403641,
      -0.0295969620347023,
      -0.06847210228443146,
      0.004185052122920752,
      0.04419785737991333,
      -0.015264453366398811,
      0.013507074676454067,
      0.0030495557002723217,
      0.04339507222175598,
      0.05040637403726578,
      0.007582944817841053,
      0.029973698779940605,
      0.023290008306503296,
      -0.02498295158147812,
      0.04847564175724983,
      -0.04445945471525192,
      -0.007267787121236324,
      -0.028199519962072372,
      0.014828414656221867,
      0.006124954205006361,
      -0.02893911674618721,
      -0.022640138864517212,
      0.048223428428173065,
      -0.045562949031591415,
      -0.06824108213186264,
      0.03155387192964554,
      -0.012390928342938423,
      -0.03846481814980507,
      0.029232384636998177,
      -0.08207030594348907,
      -0.028061270713806152,
      -0.02627706527709961,
      -0.021873027086257935,
      -0.02064334787428379,
      -0.007505503948777914,
      -0.08213721960783005,
      -0.0024722705129534006,
      -0.03557705134153366,
      0.04257791116833687,
      0.08262033015489578,
      -0.009378526359796524,
      0.04442377761006355,
      0.018325980752706528,
      0.05404231324791908,
      0.008309594355523586,
      0.025992238894104958,
      -0.059649448841810226,
      0.10572631657123566,
      -0.07361853867769241,
      0.04510711133480072,
      0.08545061945915222,
      -0.03745943680405617,
      -0.007803463842719793,
      0.032298680394887924,
      -0.02861548401415348,
      0.06384795159101486,
      -0.007203632965683937,
      -0.04172815755009651,
      0.024245314300060272,
      -0.061474043875932693,
      0.06981680542230606,
      0.07013236731290817,
      -0.01592753268778324,
      -0.04090850055217743,
      0.05758070573210716,
      -0.025417238473892212,
      -0.004230074118822813,
      0.03895523399114609,
      -0.040013592690229416,
      0.04172813519835472,
      -0.02811347134411335,
      -0.034420888870954514,
      0.021259814500808716,
      0.0242659542709589,
      0.09741294384002686,
      -0.039163172245025635,
      0.02012575790286064,
      0.05294216796755791,
      -0.017805323004722595,
      0.051216643303632736,
      -0.0222884863615036,
      0.07255268841981888,
      -0.05280346795916557,
      0.028241483494639397,
      -0.019813377410173416,
      0.06933605670928955,
      0.026950029656291008,
      0.0316619835793972,
      -0.03150976821780205,
      0.0013985909754410386,
      -0.0835522934794426,
      -0.019047079607844353,
      0.023748744279146194,
      -0.03394344821572304,
      -0.01699649728834629,
      0.0036048605106770992,
      0.09976085275411606,
      -0.04459354281425476,
      -0.05098121240735054,
      0.04727376997470856,
      0.06354022771120071,
      -0.03077629581093788,
      -0.0213356651365757,
      0.06777260452508926,
      0.05046552047133446,
      0.055244725197553635,
      0.024968530982732773,
      0.10558218508958817,
      -0.010904625989496708,
      -0.05462678521871567,
      -0.033727873116731644,
      0.0765860453248024,
      -0.015179800800979137,
      -0.017688937485218048,
      0.07078947871923447,
      -0.022699696943163872,
      0.0930606871843338,
      0.042501162737607956,
      0.008829613216221333,
      -0.04647371545433998
    ],
    [
      0.04728367552161217,
      0.04328025504946709,
      -0.06068650260567665,
      0.0028218019288033247,
      0.0380568727850914,
      -0.013859493657946587,
      0.004768895450979471,
      -0.051572393625974655,
      -0.013253404758870602,
      -0.005519096273928881,
      -0.00040442185127176344,
      -0.008479153737425804,
      0.01872197352349758,
      -0.04095899686217308,
      0.014850101433694363,
      -0.006526618730276823,
      -0.06349849700927734,
      0.018293099477887154,
      -0.023918168619275093,
      -0.024525010958313942,
      0.028510695323348045,
      0.025681784376502037,
      -0.0199892595410347,
      -0.06268887221813202,
      0.029231969267129898,
      -0.08366547524929047,
      0.04499378427863121,
      -0.026616079732775688,
      0.02056633122265339,
      -0.05915064737200737,
      -0.005437880288809538,
      -0.03256595879793167,
      -0.043515436351299286,
      -0.01513891015201807,
      0.020700443536043167,
      0.08340148627758026,
      -0.13082507252693176,
      -0.029729846864938736,
      -0.024306943640112877,
      0.09607566148042679,
      -0.04617474228143692,
      -0.011994289234280586,
      -0.04835551604628563,
      -5.057275848230347e-05,
      0.013127959333360195,
      -0.011721332557499409,
      0.059542879462242126,
      0.04230998083949089,
      -0.037458859384059906,
      0.03921525180339813,
      -0.010135606862604618,
      -0.007366986013948917,
      0.03702286258339882,
      -0.05366348475217819,
      0.04960291460156441,
      0.01127595640718937,
      0.013807633891701698,
      0.049707550555467606,
      0.08623545616865158,
      0.0312887579202652,
      0.020227864384651184,
      0.07271378487348557,
      -0.024032555520534515,
      -0.022361667826771736,
      0.02500629425048828,
      -0.04127863049507141,
      0.037597037851810455,
      0.005431757774204016,
      -0.0002398734213784337,
      -0.044464293867349625,
      -0.05432215705513954,
      -0.029868233948946,
      0.0009430396021343768,
      -0.10770105570554733,
      0.06719750910997391,
      -0.050513286143541336,
      -0.02370748296380043,
      -0.0167568176984787,
      0.10214988142251968,
      -0.03749271109700203,
      0.03623209893703461,
      -0.010647894814610481,
      0.05615517497062683,
      0.04203373193740845,
      -0.07570402324199677,
      -0.0018234438030049205,
      -0.00620332732796669,
      0.06646860390901566,
      0.04422318562865257,
      -0.022440509870648384,
      -0.004351561889052391,
      -0.0032191236969083548,
      0.02706983871757984,
      -0.01303690392524004,
      0.07748555392026901,
      -0.020847883075475693,
      0.022093603387475014,
      0.05108174681663513,
      0.016461437568068504,
      -0.03856423497200012,
      -0.027754919603466988,
      -0.03611452877521515,
      -0.00472988048568368,
      -0.004483287688344717,
      0.07432124018669128,
      -0.019896211102604866,
      -0.05530918017029762,
      0.0009765943977981806,
      0.0530858188867569,
      0.028620993718504906,
      0.01832403615117073,
      0.06655631959438324,
      -0.09949222207069397,
      -0.10654305666685104,
      -0.03431100770831108,
      -0.02060053125023842,
      0.029304247349500656,
      0.031670715659856796,
      -4.28141065640375e-05,
      -0.023750534281134605,
      -0.010970653034746647,
      0.022213447839021683,
      0.04641367122530937,
      0.020998800173401833,
      0.10109511762857437,
      0.03180235996842384,
      -0.022295841947197914,
      -0.07219887524843216,
      -0.033968355506658554,
      0.03206838294863701,
      -0.0034289767500013113,
      -0.022623129189014435,
      -0.014490725472569466,
      0.05778299272060394,
      0.005994027480483055,
      0.022862793877720833,
      0.009537062607705593,
      0.0011202874593436718,
      -0.030685896053910255,
      0.03527510538697243,
      -0.0016294579254463315,
      -0.006704895757138729,
      0.037703413516283035,
      0.08319206535816193,
      0.02763395942747593,
      0.04740550369024277,
      -0.023555077612400055,
      0.011505316942930222,
      0.01986956223845482,
      -0.056751806288957596,
      -0.029835399240255356,
      -0.061729300767183304,
      0.08286333084106445,
      -0.013682416640222073,
      0.048279520124197006,
      -0.0512310266494751,
      -0.013293211348354816,
      -0.035583045333623886,
      -0.0017063113627955317,
      0.03708118945360184,
      0.04001994803547859,
      -0.02649451605975628,
      0.026571327820420265,
      0.047717686742544174,
      -0.05188586190342903,
      -0.02342190407216549,
      -0.033976729959249496,
      -0.030593717470765114,
      -0.01650332473218441,
      -0.0013039312325417995,
      -0.029392682015895844,
      0.020497500896453857,
      -0.034585174173116684,
      0.0728311687707901,
      0.05723394453525543,
      -0.023226547986268997,
      0.012049099430441856,
      0.02065218612551689,
      0.03229181468486786,
      -0.007013759575784206,
      0.019499585032463074,
      -0.018047256395220757,
      0.0874643325805664,
      -0.027241332456469536,
      -0.013351183384656906,
      0.003958525136113167,
      0.00359251257032156,
      -0.02060493268072605,
      -0.05319974571466446,
      -0.0001346601638942957,
      -0.008849064819514751,
      0.005647480022162199,
      0.06644750386476517,
      0.06503541767597198,
      -0.03692856431007385,
      -0.045518677681684494,
      -0.03686220571398735,
      0.06867077946662903,
      0.059868164360523224,
      -0.0003899230796378106,
      -0.016484901309013367,
      0.0757051557302475,
      0.018225476145744324,
      -0.028891846537590027,
      -0.03570159897208214,
      -0.00959908775985241,
      0.0007088021375238895,
      0.02374807372689247,
      -0.001971856225281954,
      0.03902086988091469,
      0.04422690346837044,
      -0.012866022996604443,
      0.05423467606306076,
      -0.05176158994436264,
      0.03382590040564537,
      0.051696065813302994,
      -0.01893794536590576,
      0.0776427686214447,
      0.11054626107215881,
      0.0733742043375969,
      -0.008232693187892437,
      0.028165627270936966,
      -0.04400940239429474,
      -0.018160179257392883,
      0.04561104252934456,
      0.02782309427857399,
      0.02546115778386593,
      0.03679857403039932,
      0.04871309548616409,
      -0.07405132800340652,
      -0.08051585406064987,
      -0.0015079996082931757,
      0.025385651737451553,
      0.007287821266800165,
      -0.049599651247262955,
      0.06096832826733589,
      0.016287753358483315,
      0.050280552357435226,
      0.026570113375782967,
      0.018822602927684784,
      -0.045349180698394775,
      0.024723542854189873,
      -0.05746907368302345,
      0.010809487663209438,
      0.06678459793329239,
      0.06312490999698639,
      -0.0023088313173502684,
      0.005892256274819374,
      -0.05802076682448387,
      -0.06742816418409348,
      0.06248938664793968,
      0.09477598965167999,
      -0.050246547907590866,
      0.013219751417636871,
      -0.024650486186146736,
      0.042157337069511414,
      0.03360653668642044,
      0.04385363683104515,
      -0.04664704576134682,
      0.028513861820101738,
      0.0736972987651825,
      -0.057680029422044754,
      -0.018100978806614876,
      0.07090210169553757,
      -0.040433790534734726,
      0.011953017674386501,
      -0.010900547727942467,
      -0.01478128507733345,
      0.0078122420236468315,
      0.00018850158085115254,
      0.060925766825675964,
      -0.013555298559367657,
      -0.033173952251672745,
      -0.008488689549267292,
      -0.05136386305093765,
      -0.09356442838907242,
      -0.012328242883086205,
      -0.020541809499263763,
      0.04060109332203865,
      -0.01649513840675354,
      -0.039788372814655304,
      -0.04797818511724472,
      0.00850218441337347,
      -0.005069439299404621,
      -0.000916993070859462,
      -0.03902411460876465,
      -0.017409779131412506,
      -0.03402324393391609,
      -0.0021856711246073246,
      -0.030477404594421387,
      0.08750700950622559,
      -0.015113797970116138,
      -0.04930693656206131,
      -0.06799144297838211,
      0.00790694635361433,
      -0.020086048170924187,
      -0.03964761272072792,
      -0.05905156955122948,
      0.016841456294059753,
      -0.022176610305905342,
      0.08351972699165344,
      -0.03367147594690323,
      0.0020427757408469915,
      0.01788976788520813,
      -0.04156474396586418,
      -0.005103916395455599,
      0.01960192620754242,
      0.08771833032369614,
      -0.05200842767953873,
      -0.018224533647298813,
      0.02090580016374588,
      -0.004398955497890711,
      0.06314914673566818,
      -0.08121412992477417,
      0.0572982095181942,
      0.008885879069566727,
      -0.06836477667093277,
      -0.0010934513993561268,
      -0.01657291129231453,
      0.04888119176030159,
      0.020526837557554245,
      -0.007360146380960941,
      0.027269532904028893,
      0.05782632529735565,
      0.029497290030121803,
      -0.0005338958580978215,
      -0.055035654455423355,
      0.0423443466424942,
      -0.0357152596116066,
      0.06995908170938492,
      -0.06871187686920166,
      -0.010997891426086426,
      -0.10250547528266907,
      -0.07851551473140717,
      0.02228139154613018,
      -0.016824668273329735,
      -0.08905737102031708,
      0.07563963532447815,
      -0.0124937379732728,
      -0.022115657106041908,
      0.06943529099225998,
      -0.027192043140530586,
      0.029203832149505615,
      0.07116032391786575,
      0.018622934818267822,
      -0.01059092115610838,
      0.0047601074911653996,
      0.06976674497127533,
      0.03351422771811485,
      -0.08852256089448929,
      0.000963224854785949,
      -0.03412899747490883,
      -0.06879961490631104,
      -0.002039498882368207,
      -0.07686144858598709,
      0.035857319831848145,
      0.01755562052130699,
      -0.033060964196920395,
      0.05436963960528374,
      0.12481390684843063,
      0.01293039508163929,
      -0.025716761127114296,
      -0.006419352255761623,
      -0.03717707470059395,
      0.00216302159242332,
      0.00961354747414589,
      0.02881554327905178,
      0.011484846472740173,
      0.08813324570655823,
      -0.10575394332408905,
      -0.0583256334066391,
      0.05926963686943054,
      -0.06119868531823158,
      -0.04592987522482872,
      0.026390213519334793,
      0.006771432235836983,
      -0.029540466144680977,
      -0.01574241928756237,
      -0.001384515082463622,
      0.01826283521950245,
      -0.029707178473472595,
      0.09907329827547073,
      0.006824984680861235,
      -0.0075425198301672935,
      0.07226265221834183,
      0.008821411058306694,
      -0.024020936340093613,
      0.014827885664999485,
      0.037277787923812866,
      -0.07632274180650711,
      0.01537731196731329,
      0.02909521386027336,
      0.021027639508247375,
      0.0750364139676094,
      0.022034548223018646,
      0.059798773378133774,
      -0.028538484126329422,
      0.07656162977218628,
      -0.016630353406071663,
      0.05806862562894821,
      -0.09641125798225403,
      -0.04345360025763512,
      -0.0004129735170863569,
      -0.011352596804499626,
      -0.057884156703948975,
      0.04889065399765968,
      0.009307154454290867,
      -0.037837810814380646,
      -0.04134133830666542,
      -0.030215367674827576,
      -0.034595996141433716,
      0.003587370505556464,
      0.03362464904785156,
      0.04495108127593994,
      -0.04220232740044594,
      -0.0759328305721283,
      -0.03153674677014351,
      -0.007024263497442007,
      0.013577793724834919,
      -0.012892801314592361,
      0.025578083470463753,
      0.037194691598415375,
      -0.09281587600708008,
      0.0323241651058197,
      0.010035311803221703,
      0.0455264188349247,
      -0.07484439760446548,
      0.04515249282121658,
      0.019337249919772148,
      -0.0009425226598978043,
      0.00791711825877428,
      -0.03984415903687477,
      -0.050257544964551926,
      -0.018673408776521683,
      0.025314655154943466,
      0.035354409366846085,
      -0.0756916031241417,
      -0.026184003800153732,
      -0.01087889727205038,
      0.005694717634469271,
      -0.04719843715429306,
      0.007331618573516607,
      0.0499778650701046,
      0.04083523154258728,
      -0.006094310432672501,
      0.023061256855726242,
      0.026599979028105736,
      -0.04980689659714699,
      0.013843182474374771,
      0.04168815165758133,
      0.0822359099984169,
      -0.02280176803469658,
      0.011911854147911072,
      -0.07489033043384552,
      -0.007746383547782898,
      0.025486323982477188,
      0.00789911299943924,
      0.017044110223650932,
      -0.03553493693470955,
      0.01076066680252552,
      -0.019335750490427017,
      -0.041264504194259644,
      0.025043413043022156,
      0.024002017453312874,
      0.05869261920452118,
      -0.0003575385781005025,
      0.011734145693480968,
      -0.030386710539460182,
      -0.05855346471071243,
      0.05355701595544815,
      -0.07162663340568542,
      0.014203288592398167,
      -0.014561680145561695,
      0.04605475813150406,
      -0.05770016834139824,
      0.025606080889701843,
      0.04094168171286583,
      0.04323152080178261,
      0.004177212715148926,
      0.06903315335512161,
      0.00024550530361011624,
      -0.015331902541220188,
      -0.018210766837000847,
      0.017120009288191795,
      0.013093546032905579,
      0.018115825951099396,
      -0.07650746405124664,
      0.10735085606575012,
      0.018269386142492294,
      -0.03651522099971771,
      -0.005184534005820751,
      -0.014251332730054855,
      -0.08136273175477982,
      -0.018787162378430367,
      0.012438572943210602,
      -0.0048715476877987385,
      0.024553291499614716,
      -0.021218949928879738,
      -0.0011365647660568357,
      0.017943428829312325,
      0.03946681693196297,
      -0.05714034289121628,
      -0.04503869265317917
    ],
    [
      0.02411523275077343,
      0.038269732147455215,
      -0.076058030128479,
      0.031051764264702797,
      -0.06041437387466431,
      0.001989245880395174,
      0.01613013818860054,
      -0.021597733721137047,
      -0.007217756472527981,
      0.0022161260712891817,
      -0.013934046030044556,
      0.011945120058953762,
      -0.006952379830181599,
      -0.09906250983476639,
      0.012813341803848743,
      -0.0017365248640999198,
      -0.11007065325975418,
      0.00991189107298851,
      -0.0682009682059288,
      -0.04839930683374405,
      -0.015403584577143192,
      0.05760041996836662,
      -0.04617621377110481,
      -0.007192013785243034,
      0.08472767472267151,
      0.05697805434465408,
      0.01174444705247879,
      -0.01901291310787201,
      0.021025819703936577,
      -0.06059563532471657,
      0.03425424546003342,
      0.005584164056926966,
      0.018485357984900475,
      0.028742549940943718,
      -0.06371740251779556,
      0.024465393275022507,
      -0.04600873962044716,
      -0.034669436514377594,
      -6.841424328740686e-05,
      -0.025180285796523094,
      -0.05088529735803604,
      0.024821031838655472,
      0.030249496921896935,
      0.004505175165832043,
      0.020832687616348267,
      0.09537941962480545,
      -0.021257774904370308,
      -0.007070163264870644,
      0.02344336360692978,
      -0.051379863172769547,
      0.015763623639941216,
      0.05517745018005371,
      -0.051731765270233154,
      -0.012573197484016418,
      0.008018641732633114,
      0.03006967529654503,
      -0.040877360850572586,
      -0.02446088194847107,
      -0.0037183177191764116,
      0.0010870778933167458,
      0.04191505163908005,
      0.00879837479442358,
      -0.07701461017131805,
      0.01897297613322735,
      -0.02488674223423004,
      0.033321887254714966,
      -0.03148676082491875,
      0.04249485954642296,
      -0.04718694090843201,
      -0.10464610159397125,
      -0.015200464054942131,
      -0.02091403864324093,
      -0.036166027188301086,
      -0.008951128460466862,
      0.017307022586464882,
      0.0009962054900825024,
      0.010946917347609997,
      -0.05222093313932419,
      0.04705478250980377,
      0.052753888070583344,
      0.036920491605997086,
      -0.07035305351018906,
      0.08553209900856018,
      0.00942267756909132,
      0.005060991272330284,
      0.028977660462260246,
      0.0471281036734581,
      0.014096206054091454,
      0.018901994451880455,
      0.0278623029589653,
      0.027742082253098488,
      0.03845839574933052,
      -0.05875404551625252,
      0.04906460642814636,
      0.07093852013349533,
      -0.0184999518096447,
      0.03652513027191162,
      0.008779089897871017,
      -0.009475824423134327,
      0.026164425536990166,
      0.00665495079010725,
      0.015230790711939335,
      0.03659646213054657,
      0.002695644972845912,
      0.01918359100818634,
      0.052758291363716125,
      -0.030923781916499138,
      0.016726752743124962,
      0.07518421113491058,
      0.02234785258769989,
      -0.0002505418669898063,
      0.057949248701334,
      -0.07830637693405151,
      -0.017066698521375656,
      -0.07796431332826614,
      -0.0665014386177063,
      -0.023208308964967728,
      0.04052204266190529,
      -0.028345657512545586,
      0.07166998833417892,
      0.045254435390233994,
      -0.010212615132331848,
      -0.01484178937971592,
      0.03511451929807663,
      0.02442062832415104,
      0.02891138195991516,
      -0.02364489622414112,
      -0.060876812785863876,
      -0.015086685307323933,
      -0.028050808236002922,
      0.007847275584936142,
      -0.06115099787712097,
      0.014777056872844696,
      0.006705837789922953,
      0.028341399505734444,
      0.039148151874542236,
      0.06918405741453171,
      -0.008109663613140583,
      -0.003661236260086298,
      0.0012875418178737164,
      0.03507758677005768,
      0.01493702083826065,
      0.06330320984125137,
      0.023772340267896652,
      0.037177786231040955,
      0.029539503157138824,
      0.07593944668769836,
      0.03582298383116722,
      -0.06162986531853676,
      0.01313851773738861,
      0.06138182058930397,
      0.011136806569993496,
      0.07958719879388809,
      -0.02045629918575287,
      0.03834822028875351,
      -0.00254897796548903,
      0.04262883961200714,
      0.04393647238612175,
      -0.001669197459705174,
      0.03879621624946594,
      -0.018100038170814514,
      0.022607745602726936,
      0.03675014153122902,
      0.012623212300240993,
      -0.017437884584069252,
      -0.06475794315338135,
      0.02061356231570244,
      0.03515427187085152,
      0.04011591151356697,
      -0.04469475895166397,
      -0.05951182171702385,
      -0.043252553790807724,
      -0.01815992034971714,
      0.045543789863586426,
      -0.04917968809604645,
      0.02300962246954441,
      -0.04482622444629669,
      0.07943746447563171,
      -0.01193445548415184,
      0.021156344562768936,
      0.008390248753130436,
      -0.08027626574039459,
      -0.005220246035605669,
      -0.009689255617558956,
      0.011266708374023438,
      -0.0223021749407053,
      0.007106345146894455,
      -0.047292567789554596,
      -0.020426848903298378,
      0.07285969704389572,
      0.027195800095796585,
      0.05357212573289871,
      -0.006872930563986301,
      -0.004069153685122728,
      0.008670268580317497,
      0.005163426510989666,
      -0.014361093752086163,
      0.040265846997499466,
      0.021335870027542114,
      0.06832865625619888,
      -0.07330261170864105,
      0.02036338672041893,
      -0.008505603298544884,
      0.019267842173576355,
      0.005935764871537685,
      0.012658882886171341,
      -0.006447371561080217,
      0.024479281157255173,
      -0.017339009791612625,
      -0.04550923407077789,
      0.02228291891515255,
      -0.014526521787047386,
      0.05275905877351761,
      0.04054633527994156,
      0.04325712099671364,
      0.0530695877969265,
      -0.0062307012267410755,
      -0.03355313092470169,
      -0.03581388294696808,
      -0.010547994635999203,
      0.03259390965104103,
      -0.0167430080473423,
      -0.007926356978714466,
      -0.037478286772966385,
      0.03202882781624794,
      0.026002079248428345,
      -0.06178413704037666,
      -0.06143590807914734,
      0.0800752192735672,
      0.022328531369566917,
      -0.002372774062678218,
      0.03382335975766182,
      -0.004975347314029932,
      0.031450413167476654,
      0.0709410309791565,
      0.006613441742956638,
      0.009771524928510189,
      0.06033678352832794,
      -0.05100700631737709,
      0.01968812197446823,
      -0.013660901226103306,
      -0.06856148689985275,
      0.011757991276681423,
      0.012226120568811893,
      -0.04934171214699745,
      0.025396179407835007,
      0.04757954925298691,
      0.09004183858633041,
      -0.03987148404121399,
      -0.02793022431433201,
      -0.016344744712114334,
      -0.034567080438137054,
      -0.07089772075414658,
      -0.045398034155368805,
      0.02080870419740677,
      -0.008751390501856804,
      -0.04131685569882393,
      0.030829520896077156,
      -0.04773494228720665,
      -0.0460025891661644,
      -0.0022171048913151026,
      0.005021771881729364,
      -0.002824879717081785,
      0.0765053927898407,
      0.01381245069205761,
      -0.025931593030691147,
      0.0026798751205205917,
      -0.0013554858742281795,
      -0.00041528919246047735,
      -0.05643988400697708,
      0.012666928581893444,
      0.02986535243690014,
      -0.040221862494945526,
      -0.05899212136864662,
      -0.004226088989526033,
      0.06399045139551163,
      -0.012203949503600597,
      -0.018103227019309998,
      -0.0200127474963665,
      0.050450898706912994,
      0.06805458664894104,
      -0.06095710024237633,
      0.02925093099474907,
      -0.09638947993516922,
      -0.0792103111743927,
      -0.055265042930841446,
      -0.05673575773835182,
      0.040361952036619186,
      -0.09158629179000854,
      0.0511922687292099,
      0.03498474135994911,
      -0.019431589171290398,
      -0.04066721349954605,
      0.0066649713553488255,
      0.05524808168411255,
      0.049502402544021606,
      0.06614609807729721,
      0.03316819667816162,
      -0.0023544912692159414,
      0.03730221092700958,
      -0.039788827300071716,
      -0.013822980225086212,
      -0.029299421235919,
      -0.03338009864091873,
      0.024715181440114975,
      0.006508121266961098,
      -0.03762904182076454,
      -0.09615714848041534,
      0.0639563798904419,
      -0.059408582746982574,
      -0.012630103155970573,
      -0.028255080804228783,
      -0.008134582079946995,
      -0.00294061959721148,
      0.04506416246294975,
      -0.07150338590145111,
      0.004534651059657335,
      -0.044900041073560715,
      -0.029103949666023254,
      0.03826935961842537,
      -0.11360704898834229,
      -0.01517245452851057,
      0.042617909610271454,
      0.0846497118473053,
      -0.022240137681365013,
      -0.002280276035889983,
      0.02255510911345482,
      0.026007045060396194,
      0.016480499878525734,
      -0.02315494790673256,
      0.019383445382118225,
      -0.0369328036904335,
      -0.011918837204575539,
      0.0008889599703252316,
      0.026827720925211906,
      0.09576871246099472,
      -0.011488662101328373,
      0.08158276230096817,
      0.007072869688272476,
      -0.08894715458154678,
      -0.011632759124040604,
      0.02049616165459156,
      0.0052841524593532085,
      -0.059552647173404694,
      -0.06852350383996964,
      0.018809180706739426,
      -0.03706834465265274,
      -0.027901604771614075,
      -0.008938606828451157,
      -0.05996592342853546,
      0.030605468899011612,
      -0.011312877759337425,
      0.04318186640739441,
      0.03324532136321068,
      -0.04904032126069069,
      -0.004344092682003975,
      -0.07126150280237198,
      0.016104958951473236,
      -0.0778961181640625,
      -0.02026086114346981,
      0.07273248583078384,
      0.02715027704834938,
      8.175146649591625e-05,
      -0.04491862654685974,
      -0.042438603937625885,
      -0.01033315435051918,
      -0.009292583912611008,
      0.01217680424451828,
      -0.048743341118097305,
      -0.0042261057533323765,
      0.0037199801299721003,
      -0.031851090490818024,
      -0.013269276358187199,
      -0.0671859160065651,
      0.05629623308777809,
      0.05081035569310188,
      0.09288924932479858,
      -0.06836657971143723,
      0.0496135950088501,
      0.05775858834385872,
      0.05149972438812256,
      0.03541750833392143,
      0.06726782023906708,
      -0.007192280609160662,
      0.024595290422439575,
      -0.061392176896333694,
      0.03103899210691452,
      -0.03746025264263153,
      -0.0633268803358078,
      -0.03357931599020958,
      -0.03993237763643265,
      0.011366588994860649,
      -0.00782839022576809,
      -0.08150942623615265,
      0.016670363023877144,
      -0.05688093230128288,
      0.025425881147384644,
      0.04322141781449318,
      -0.060625188052654266,
      -0.017305349931120872,
      0.005800032522529364,
      -0.018504485487937927,
      0.01881825365126133,
      -0.005362852942198515,
      -0.01939711533486843,
      0.019260132685303688,
      -0.009370427578687668,
      0.029734203591942787,
      0.11625295877456665,
      0.019006313756108284,
      0.05032014101743698,
      0.026659155264496803,
      -0.05458959937095642,
      0.0026541585102677345,
      -0.03790250048041344,
      0.053735602647066116,
      -0.007067605387419462,
      -0.04914303496479988,
      -0.12542861700057983,
      0.028412286192178726,
      0.06255822628736496,
      -0.014802457764744759,
      0.008545196615159512,
      -0.03172186762094498,
      -0.012813985347747803,
      0.010828256607055664,
      -0.019978778436779976,
      -0.050952184945344925,
      0.029255736619234085,
      -0.0705123320221901,
      0.017355017364025116,
      0.06832892447710037,
      -0.06138254329562187,
      0.012280437164008617,
      0.0068907164968550205,
      0.0050557334907352924,
      0.045015331357717514,
      0.006100054830312729,
      0.03721213713288307,
      0.011110824532806873,
      -0.03057488426566124,
      -0.04983013868331909,
      0.032576095312833786,
      -0.01964445225894451,
      0.11923594772815704,
      0.04844232648611069,
      0.0425683818757534,
      -0.007366584148257971,
      -0.01722422055900097,
      0.006273385137319565,
      -0.0011395167093724012,
      0.04574326425790787,
      0.02155955880880356,
      -0.010588058270514011,
      -0.04124990478157997,
      -0.05816539004445076,
      -0.11382719874382019,
      -0.0956229567527771,
      0.05526149272918701,
      -0.0013854989083483815,
      -0.04558240622282028,
      0.05986255407333374,
      -0.021366221830248833,
      0.08069323748350143,
      0.01659378595650196,
      0.032962869852781296,
      0.0005299284239299595,
      -0.005016476847231388,
      0.02025434747338295,
      0.012496987357735634,
      -0.00941163208335638,
      -0.0009613133734092116,
      0.023070884868502617,
      0.004984703380614519,
      0.034379828721284866,
      -0.010492918081581593,
      0.046874817460775375,
      -0.0678727850317955,
      -0.03135735169053078,
      0.03657679632306099,
      0.004729246720671654,
      -0.021335864439606667,
      0.05551579222083092,
      -0.02140824683010578,
      -0.05583290755748749,
      -0.044990990310907364,
      0.012887758202850819,
      0.00566867645829916,
      0.025214310735464096,
      0.02615111880004406,
      -0.024308813735842705,
      0.01001007854938507,
      -0.05084610357880592,
      -0.01965380646288395,
      -0.025716878473758698,
      -0.04534963145852089,
      -0.007860390469431877,
      0.008781987242400646,
      0.046241018921136856,
      -0.009014620445668697,
      -0.02902926132082939,
      0.03158413618803024,
      -0.04105827957391739
    ],
    [
      -0.047158122062683105,
      0.018039468675851822,
      -0.03453078866004944,
      -0.023413516581058502,
      -0.019929712638258934,
      0.04657282307744026,
      -0.04250607639551163,
      -0.0068814861588180065,
      -0.06247171387076378,
      0.03702550008893013,
      0.010285173542797565,
      0.009736673906445503,
      0.009696073830127716,
      0.04566413164138794,
      -0.038803715258836746,
      0.0439046174287796,
      0.024015363305807114,
      0.02413603849709034,
      0.06360846757888794,
      0.03270573168992996,
      -0.035371553152799606,
      -0.08192354440689087,
      0.022535257041454315,
      0.014461323618888855,
      0.019661299884319305,
      0.01776299625635147,
      0.052222300320863724,
      -0.04781471937894821,
      0.04522183537483215,
      0.028520038351416588,
      -0.023393971845507622,
      -0.09060364216566086,
      0.06495953351259232,
      0.04539896175265312,
      -0.08602359890937805,
      -0.05254651606082916,
      0.11270064860582352,
      0.013430782593786716,
      -0.013654914684593678,
      -0.02354506403207779,
      0.002257877727970481,
      -0.007298639975488186,
      0.012521015480160713,
      -0.0021153753623366356,
      -0.0047936951741576195,
      0.002305559581145644,
      -0.05992390960454941,
      0.008564665913581848,
      0.056539908051490784,
      -0.025742055848240852,
      0.0634622573852539,
      0.08717545121908188,
      -0.011396816000342369,
      0.00817445944994688,
      0.012163938023149967,
      -0.04870319738984108,
      0.0017551559722051024,
      0.06524905562400818,
      -0.01750093884766102,
      0.0027111652307212353,
      -0.05636047199368477,
      -0.03724377602338791,
      -0.023236678913235664,
      -0.07899368554353714,
      0.054308243095874786,
      0.09360221773386002,
      -0.08051056414842606,
      -0.03469021990895271,
      -0.006280418019741774,
      0.1241312175989151,
      0.0544532909989357,
      0.06861715763807297,
      0.04939901456236839,
      -0.1061238944530487,
      -0.09447155892848969,
      0.036750324070453644,
      -0.024554818868637085,
      -0.011954117566347122,
      0.011697766371071339,
      -0.024594372138381004,
      -0.046303849667310715,
      0.011645304039120674,
      0.006382133811712265,
      0.03726313263177872,
      -0.034180521965026855,
      0.04793309420347214,
      0.033970899879932404,
      0.07800797373056412,
      0.02911985106766224,
      0.010813316330313683,
      -0.00026127242017537355,
      0.08722654730081558,
      0.010123249143362045,
      0.00822566170245409,
      0.044900886714458466,
      0.01668197475373745,
      -0.01325620710849762,
      -0.036671582609415054,
      0.04587039351463318,
      -0.01454352680593729,
      0.025983193889260292,
      -0.11315953731536865,
      -0.030529707670211792,
      0.08151289820671082,
      0.036969564855098724,
      -0.03153381869196892,
      0.03279591351747513,
      -0.06866084784269333,
      -0.01701684296131134,
      0.047325294464826584,
      0.0816158652305603,
      0.07466771453619003,
      0.022230658680200577,
      -0.05518367886543274,
      0.008545429445803165,
      0.022832369431853294,
      -0.05943998321890831,
      -0.0037872602697461843,
      0.09741386026144028,
      0.007303355727344751,
      -0.08434106409549713,
      -0.04036930575966835,
      0.016515828669071198,
      0.12520363926887512,
      0.04038148745894432,
      0.012270241044461727,
      0.024235939607024193,
      0.058602578938007355,
      -0.01836949773132801,
      0.0666017085313797,
      0.02441275492310524,
      -0.027103744447231293,
      0.018857605755329132,
      0.0035334450658410788,
      -0.04872612655162811,
      0.03978689759969711,
      0.0029626258183270693,
      -0.004528702702373266,
      -0.015070521272718906,
      -0.018555181100964546,
      0.04711223766207695,
      -0.030785979703068733,
      0.04710255563259125,
      -0.01851767487823963,
      0.04118805751204491,
      0.005259928293526173,
      0.0025747143663465977,
      0.049532655626535416,
      -0.011977831833064556,
      -0.008745195344090462,
      0.0433291494846344,
      -0.04283818602561951,
      0.002792918588966131,
      -0.012100565247237682,
      -0.01943192444741726,
      0.04273156076669693,
      0.009954784996807575,
      -0.010803336277604103,
      0.06940293312072754,
      0.05152280628681183,
      -0.04385535791516304,
      -0.06533914804458618,
      -0.00808330811560154,
      0.015005293302237988,
      0.04569239541888237,
      -0.0076217688620090485,
      -0.027252009138464928,
      -0.013209954835474491,
      -0.06514468044042587,
      -0.06499838083982468,
      0.08928941190242767,
      -0.0070645748637616634,
      0.03606591001152992,
      0.02033684402704239,
      0.00597776984795928,
      0.04995714873075485,
      -0.08724009990692139,
      -0.02274852804839611,
      0.08529532700777054,
      -0.06903007626533508,
      -0.007904108613729477,
      0.014129976741969585,
      -0.03738032653927803,
      -0.045856814831495285,
      0.030891571193933487,
      -0.062201909720897675,
      -0.029594162479043007,
      -0.00020702678011730313,
      0.08437754213809967,
      -0.03683069720864296,
      -0.040103185921907425,
      -0.04751705005764961,
      0.006527756806463003,
      -0.03463997319340706,
      -0.023125039413571358,
      0.08868502825498581,
      -0.01940205506980419,
      -0.11322735995054245,
      0.0013034474104642868,
      0.06494718044996262,
      -0.012422195635735989,
      0.01384168304502964,
      -0.06769104301929474,
      -0.022694289684295654,
      0.016596859320998192,
      -0.04647400975227356,
      0.015896659344434738,
      -0.027930524200201035,
      0.030278287827968597,
      -0.02514367550611496,
      -0.036355942487716675,
      -0.06370142102241516,
      -0.054000742733478546,
      0.004507002886384726,
      -0.04189512878656387,
      0.050499118864536285,
      -0.08706259727478027,
      0.09256598353385925,
      0.043451566249132156,
      0.00599809130653739,
      0.0051514809019863605,
      -0.000214148240047507,
      -0.03815808892250061,
      -0.032222505658864975,
      -0.005785476416349411,
      -0.06046193465590477,
      0.04297538474202156,
      -0.027413975447416306,
      0.05009579285979271,
      -0.01580861397087574,
      -0.019000772386789322,
      0.010398073121905327,
      0.09454833716154099,
      -0.0582028366625309,
      0.016153663396835327,
      0.0589899979531765,
      -0.07263707369565964,
      -0.07812467217445374,
      -0.031029773876070976,
      0.0555230975151062,
      -0.025738976895809174,
      -0.04844152182340622,
      0.061343174427747726,
      -0.045705899596214294,
      0.001228988403454423,
      0.033140089362859726,
      -0.07797371596097946,
      0.017945298925042152,
      0.016539480537176132,
      -0.0698571652173996,
      0.0057111335918307304,
      -0.02007111720740795,
      0.024852823466062546,
      0.06443972885608673,
      0.06624031811952591,
      -0.01842508092522621,
      -0.004163604695349932,
      -0.09627186506986618,
      0.018686680123209953,
      0.0423768013715744,
      -0.02527422085404396,
      -0.005373976659029722,
      0.014000522904098034,
      -0.05430331081151962,
      -0.034285470843315125,
      0.03311320021748543,
      0.014786130748689175,
      0.09606849402189255,
      0.022383585572242737,
      -0.014313915744423866,
      0.03835013508796692,
      -0.046926114708185196,
      0.03452954441308975,
      -0.022756479680538177,
      -0.06557825207710266,
      -0.019056832417845726,
      -0.04407094791531563,
      -0.06509275734424591,
      -0.07624965906143188,
      0.03886202722787857,
      -0.04187313839793205,
      -0.029430415481328964,
      0.053802747279405594,
      -0.03018553927540779,
      -0.004390475805848837,
      -0.01576021872460842,
      0.04769500344991684,
      -0.11163048446178436,
      0.033876173198223114,
      0.029907111078500748,
      0.07022108137607574,
      -0.04422147199511528,
      0.006503326818346977,
      -0.010841588489711285,
      0.04326246678829193,
      -0.04427658021450043,
      0.02837596833705902,
      0.022736862301826477,
      0.026724161580204964,
      0.08904992043972015,
      0.072491355240345,
      -0.02972056344151497,
      0.05718884989619255,
      0.02359112910926342,
      0.006801502779126167,
      0.035343099385499954,
      -0.003556416602805257,
      -0.03365393728017807,
      -0.0448022298514843,
      -0.027036188170313835,
      0.02163834311068058,
      0.001128421281464398,
      0.003490532748401165,
      0.0027393391355872154,
      -0.08029962331056595,
      -0.018227633088827133,
      0.10918179899454117,
      0.03106590360403061,
      -0.021638639271259308,
      0.01175711303949356,
      -0.021744955331087112,
      -0.00018968383665196598,
      0.015214754268527031,
      0.07416070252656937,
      0.06998731195926666,
      -0.002593428362160921,
      0.017632121220231056,
      0.02437000721693039,
      -0.01774505525827408,
      -0.010775696486234665,
      0.0010737818665802479,
      -0.059168215841054916,
      -0.07095407694578171,
      0.03149428218603134,
      -0.02261907234787941,
      0.040282897651195526,
      -0.02504940889775753,
      -0.0845026820898056,
      0.0038537157233804464,
      -0.07700908184051514,
      -0.01975017972290516,
      0.022196827456355095,
      -0.050068363547325134,
      -0.07792403548955917,
      0.0102712232619524,
      0.052149105817079544,
      -0.017073139548301697,
      -0.03738929703831673,
      -0.0006450141663663089,
      0.02434648387134075,
      -0.02958875149488449,
      -0.0008794488967396319,
      0.042327240109443665,
      -0.0017856888007372618,
      0.06102433800697327,
      -0.04154474660754204,
      -0.03947887569665909,
      0.04866674169898033,
      -0.034263357520103455,
      0.012251032516360283,
      -0.0405450202524662,
      0.04408454895019531,
      -0.01851736567914486,
      -0.040569234639406204,
      -0.02557491324841976,
      0.037836842238903046,
      -0.007661751005798578,
      -0.011314881965517998,
      0.012395191006362438,
      0.035061124712228775,
      0.019106898456811905,
      0.019236283376812935,
      -0.045637745410203934,
      -0.0033181312028318644,
      0.015866706147789955,
      0.010438901372253895,
      -0.02641018107533455,
      -0.0387333407998085,
      0.10558447986841202,
      -0.0012772631598636508,
      0.009469429031014442,
      -0.04656767100095749,
      0.07822056114673615,
      0.045403629541397095,
      -0.010134780779480934,
      0.10333523899316788,
      0.05931409075856209,
      -0.00858189258724451,
      -0.03713829442858696,
      -0.05806320905685425,
      0.08959599584341049,
      -0.05234558507800102,
      -0.028011050075292587,
      -0.07978210598230362,
      0.02857339195907116,
      0.037841882556676865,
      0.00636253459379077,
      -0.007355081383138895,
      -0.03745211660861969,
      -0.05496161803603172,
      -0.05663293972611427,
      -0.027659690007567406,
      0.043607402592897415,
      -0.02307044342160225,
      0.0943678617477417,
      0.027487214654684067,
      -0.04711644724011421,
      -0.02856680005788803,
      0.010082014836370945,
      -0.03137171268463135,
      -0.02117353491485119,
      0.04501569643616676,
      -0.015399649739265442,
      0.007195474114269018,
      -0.058788567781448364,
      0.04013405367732048,
      0.026132209226489067,
      0.01939092017710209,
      -0.035200756043195724,
      -0.00029709612135775387,
      0.06192473694682121,
      0.034040212631225586,
      -0.052659109234809875,
      -0.06738235801458359,
      -0.0009527952643111348,
      0.0978870540857315,
      0.018776223063468933,
      0.017103062942624092,
      -0.01684371754527092,
      0.03131331503391266,
      -0.040807440876960754,
      0.025264015421271324,
      0.0295663271099329,
      -0.10072260349988937,
      -0.057364266365766525,
      -0.08089923858642578,
      -0.13040263950824738,
      0.00724414549767971,
      0.02999737858772278,
      0.0031643572729080915,
      -0.05013119429349899,
      0.0417247973382473,
      -0.031562600284814835,
      0.0242153350263834,
      0.02937227673828602,
      -0.08521512895822525,
      -0.0048698969185352325,
      0.01482459343969822,
      -0.08080746233463287,
      0.07814423739910126,
      0.07738998532295227,
      0.0014006687561050057,
      0.060379479080438614,
      -0.003960121888667345,
      0.0290849469602108,
      0.0694337859749794,
      -0.03347419574856758,
      0.06260141730308533,
      -0.010929908603429794,
      0.06403771787881851,
      -0.03931940346956253,
      0.019282497465610504,
      0.08989576995372772,
      -0.005409863777458668,
      0.05617644265294075,
      0.002230418846011162,
      0.0386066734790802,
      -0.03529060259461403,
      -0.0716632679104805,
      0.015157382003962994,
      -0.015138206072151661,
      0.005386912729591131,
      -0.0649976134300232,
      -0.03701294958591461,
      0.02179880626499653,
      -0.06171318143606186,
      0.013177548535168171,
      -0.025353945791721344,
      -0.01163535937666893,
      0.0024203474167734385,
      0.033832862973213196,
      -0.06884925812482834,
      -0.019300205633044243,
      0.02290232852101326,
      -0.027147606015205383,
      0.017547596246004105,
      0.08500516414642334,
      -0.024064740166068077,
      -0.11735232919454575,
      -0.024924645200371742,
      0.10059242695569992,
      -0.04295504093170166,
      -0.09173312038183212,
      0.002942346967756748,
      -0.04934080317616463,
      0.03219345584511757,
      0.004305325914174318,
      0.04176455736160278,
      -0.02013305574655533,
      -0.01735789328813553,
      0.006427220534533262,
      0.008815952576696873,
      0.011449996381998062
    ],
    [
      0.01749177649617195,
      0.021260280162096024,
      -0.11835251748561859,
      -0.03754556179046631,
      0.03057240881025791,
      0.023218242451548576,
      -0.043145932257175446,
      -0.07641424238681793,
      -0.027256881818175316,
      0.03329119831323624,
      -0.063778355717659,
      -0.050714943557977676,
      0.043398015201091766,
      -0.0029987816233187914,
      -0.05299651622772217,
      0.051405444741249084,
      -0.049375664442777634,
      -0.03980596363544464,
      -0.015701668336987495,
      -0.033179137855768204,
      -0.06873440742492676,
      -0.02800414338707924,
      0.026770111173391342,
      -0.037011150270700455,
      -0.0736699104309082,
      0.03055950626730919,
      -0.013936063274741173,
      -0.0071199508383870125,
      -0.008732822723686695,
      0.024356646463274956,
      -0.019325656816363335,
      0.006755703128874302,
      0.034123241901397705,
      -0.03457457572221756,
      0.06401293724775314,
      0.025772886350750923,
      0.030181216076016426,
      0.01810305006802082,
      0.08015282452106476,
      0.022250011563301086,
      0.08151651918888092,
      -0.006379336584359407,
      0.05358327552676201,
      0.015337388031184673,
      0.02670270949602127,
      -0.008107122033834457,
      0.03216487914323807,
      -0.01643742248415947,
      0.01942562498152256,
      -0.02140624262392521,
      -0.0310722216963768,
      0.0014964280417189002,
      0.017594676464796066,
      -0.035835277289152145,
      0.02636784315109253,
      0.04066035524010658,
      0.04087064042687416,
      0.0012884826865047216,
      -0.030080286785960197,
      0.013424069620668888,
      -0.05063490942120552,
      0.02092106081545353,
      -0.05157170072197914,
      -0.0019055467564612627,
      0.00476599158719182,
      -0.025949880480766296,
      -0.06607116758823395,
      -0.05741069093346596,
      0.07116895914077759,
      0.010467090643942356,
      -0.07092484831809998,
      0.01912444457411766,
      0.006875926163047552,
      -0.006866089999675751,
      0.06124703958630562,
      -0.021152686327695847,
      -0.07323279976844788,
      0.011021723970770836,
      -0.011392121203243732,
      0.024313120171427727,
      0.04021775349974632,
      -0.04615749418735504,
      -0.039603475481271744,
      0.014026607386767864,
      -0.0956423431634903,
      -0.01073138602077961,
      -0.025158243253827095,
      0.02994442731142044,
      -0.06011062115430832,
      -0.12591534852981567,
      0.007994038052856922,
      0.06174737960100174,
      0.05104591324925423,
      -0.0850745216012001,
      -0.06785769015550613,
      0.025361016392707825,
      0.017244374379515648,
      0.08938144147396088,
      0.07426448166370392,
      -0.009042248129844666,
      -0.07116811722517014,
      0.05211098864674568,
      0.014991581439971924,
      0.012190958485007286,
      0.01431511901319027,
      0.07538114488124847,
      -0.013788335956633091,
      0.025044262409210205,
      -0.006026505958288908,
      -0.06538205593824387,
      0.03886140137910843,
      -0.022233981639146805,
      0.00401693070307374,
      -0.03791436180472374,
      0.014117289334535599,
      0.03585566207766533,
      -0.08167474716901779,
      0.03160221874713898,
      0.018943045288324356,
      0.04163352772593498,
      0.025088155642151833,
      -0.04395168274641037,
      0.08924134075641632,
      0.0771237313747406,
      0.017669962719082832,
      -0.020898960530757904,
      0.04738568142056465,
      -0.10952888429164886,
      0.003168246475979686,
      0.0682443380355835,
      -0.031953275203704834,
      0.02223677933216095,
      0.026453085243701935,
      -0.03316112607717514,
      -0.062306806445121765,
      0.04653427004814148,
      0.0175798162817955,
      0.008314834907650948,
      -0.016652120277285576,
      -0.04360385611653328,
      -0.04233863204717636,
      -0.041325654834508896,
      0.06963776051998138,
      -0.06394228339195251,
      -0.06809567660093307,
      0.02269386500120163,
      -0.014376073144376278,
      -0.05333178862929344,
      -0.021554460749030113,
      0.013302200473845005,
      -0.03836725652217865,
      0.013836598955094814,
      0.03657574579119682,
      -0.07057580351829529,
      0.014139201492071152,
      -0.02441154420375824,
      0.04829767346382141,
      -0.06424542516469955,
      0.0433490090072155,
      0.061821360141038895,
      0.04900890588760376,
      0.0011976506793871522,
      0.0609874352812767,
      -0.03299005702137947,
      -0.027147704735398293,
      0.0035639265552163124,
      0.01874907873570919,
      -0.022964460775256157,
      -0.027213113382458687,
      -0.0882154256105423,
      -0.07138409465551376,
      0.056778762489557266,
      -0.020496293902397156,
      0.03597649186849594,
      0.00044697721023112535,
      -0.05154659226536751,
      0.004697606898844242,
      0.022821493446826935,
      0.05992709845304489,
      -0.07241293042898178,
      -0.016892602667212486,
      0.009320874698460102,
      0.029158512130379677,
      0.0218252781778574,
      -0.03693799301981926,
      0.009693928994238377,
      0.04551239311695099,
      0.0312489066272974,
      0.0029387339018285275,
      -0.015931986272335052,
      0.023727094754576683,
      0.04348795488476753,
      -0.007152758538722992,
      0.02723204530775547,
      -0.02348260022699833,
      -0.10968486219644547,
      -0.07024088501930237,
      0.009589019231498241,
      0.028232552111148834,
      -0.013740988448262215,
      -0.024888845160603523,
      0.07342088967561722,
      -0.03285087272524834,
      0.00873356219381094,
      0.06664247810840607,
      0.027506981045007706,
      -0.015644118189811707,
      -7.142763206502423e-05,
      0.01364295743405819,
      -0.022347914054989815,
      -0.0846664309501648,
      0.006439891643822193,
      -0.046560727059841156,
      0.05531791225075722,
      -0.06828732043504715,
      0.0630996897816658,
      -0.055968910455703735,
      -0.02969898283481598,
      0.01865345425903797,
      0.03909265249967575,
      0.02022738754749298,
      0.03862784057855606,
      -0.048443447798490524,
      0.0008839145302772522,
      0.07881061732769012,
      -0.026416081935167313,
      0.03867054358124733,
      0.04813326895236969,
      0.03544706478714943,
      0.0638144314289093,
      -0.07246199250221252,
      0.05038359388709068,
      0.016245724633336067,
      -0.01585634984076023,
      0.06200699880719185,
      -0.05757395923137665,
      -0.01136559247970581,
      0.058118030428886414,
      0.013010474853217602,
      0.05088863894343376,
      0.0768686905503273,
      -0.02250084839761257,
      0.0012069109361618757,
      -0.009707053191959858,
      0.0886935368180275,
      0.03084828145802021,
      0.09433165192604065,
      0.02170041762292385,
      0.016666553914546967,
      -0.04631918668746948,
      -0.011746677570044994,
      -0.005642705596983433,
      -0.014358779415488243,
      -0.07792020589113235,
      0.08317374438047409,
      0.06715987622737885,
      -0.04667443037033081,
      -0.05173550546169281,
      -0.07001041620969772,
      -0.008748587220907211,
      -0.005381106864660978,
      0.004243675619363785,
      0.009022285230457783,
      0.010827934369444847,
      -0.01874847151339054,
      -0.05788375437259674,
      -0.06946862488985062,
      -0.06853942573070526,
      0.02486143819987774,
      0.02155456878244877,
      -0.015470351092517376,
      0.018279073759913445,
      0.0819137841463089,
      0.011869223788380623,
      0.004367619287222624,
      0.012440485879778862,
      -0.08928629010915756,
      -0.06011059507727623,
      0.009935220703482628,
      0.05766814202070236,
      0.009186985902488232,
      -0.03741069138050079,
      -0.022066382691264153,
      0.005363817792385817,
      -0.026847856119275093,
      -0.020393799990415573,
      -0.0646505281329155,
      -0.10449820011854172,
      0.04421572759747505,
      -0.02147778682410717,
      0.0459139421582222,
      0.0029975511133670807,
      -0.013265806250274181,
      -0.040329549461603165,
      -0.032070569694042206,
      -0.03911567106842995,
      0.012302840128540993,
      0.005181255284696817,
      0.0417402908205986,
      -0.002686465624719858,
      -0.024981146678328514,
      -0.01022987812757492,
      0.036514051258563995,
      -0.006751911714673042,
      0.06103311479091644,
      -0.021686607971787453,
      -0.09091982990503311,
      -0.029964275658130646,
      -0.018487635999917984,
      0.07639867812395096,
      -0.042909689247608185,
      -0.011343954131007195,
      0.009433945640921593,
      0.02422254905104637,
      -0.013106187805533409,
      -0.034077949821949005,
      0.0012056204723194242,
      0.040804672986269,
      -0.018888145685195923,
      -0.006051441188901663,
      -0.0030805415008217096,
      0.06751126050949097,
      -0.013704681769013405,
      -0.0005635467823594809,
      0.03447958081960678,
      -0.08559388667345047,
      0.050772830843925476,
      -0.026635726913809776,
      -0.020951291546225548,
      0.024252092465758324,
      0.043537650257349014,
      -0.053722526878118515,
      -0.04594855383038521,
      0.022071275860071182,
      0.041129160672426224,
      -0.06703542917966843,
      -0.031460221856832504,
      0.010873728431761265,
      0.04521314799785614,
      0.024330949410796165,
      -0.09379412233829498,
      -0.01632644049823284,
      0.01874564215540886,
      0.02960176207125187,
      -0.041071973741054535,
      -0.04106989502906799,
      -0.021397024393081665,
      0.0008059165556915104,
      0.014109463430941105,
      -0.10169116407632828,
      -0.06160298362374306,
      0.04460259899497032,
      0.08325789868831635,
      -0.024624286219477654,
      -0.015804214403033257,
      0.04014545679092407,
      -0.018629806116223335,
      0.007569813169538975,
      -0.023008551448583603,
      -0.05931892618536949,
      -0.005228456575423479,
      -0.03680485114455223,
      -0.025341548025608063,
      -0.011199060827493668,
      0.047053489834070206,
      0.028250180184841156,
      0.01402661669999361,
      0.06681130081415176,
      0.014731233939528465,
      -0.03234192728996277,
      0.06839918345212936,
      -0.022927388548851013,
      0.002203055890277028,
      -0.0004991042078472674,
      0.05790079012513161,
      0.05421314388513565,
      -0.004808926954865456,
      -0.020214354619383812,
      0.0509730726480484,
      -0.04919087141752243,
      0.08658195286989212,
      -0.07191827148199081,
      0.03767543286085129,
      -0.0008342040819115937,
      0.033566128462553024,
      0.055710431188344955,
      0.0688229575753212,
      0.017534706741571426,
      -0.004987786523997784,
      9.342918929178268e-05,
      0.040198441594839096,
      0.027362234890460968,
      -0.00659736106172204,
      -0.04978426918387413,
      0.04199889674782753,
      -0.05297493189573288,
      -0.04285655543208122,
      0.06465240567922592,
      -0.0017853614408522844,
      -0.026662778109312057,
      0.07837953418493271,
      -0.014035479165613651,
      0.02707100100815296,
      -0.02020147256553173,
      0.019940542057156563,
      0.0003434127720538527,
      0.01718086376786232,
      0.013643602840602398,
      0.022678781300783157,
      -0.038055676966905594,
      -0.016036678105592728,
      0.05709247663617134,
      -0.03430842608213425,
      -0.10849441587924957,
      -0.02274574153125286,
      -0.05116595700383186,
      -0.036895859986543655,
      0.03785305470228195,
      -0.024422140792012215,
      0.01258676964789629,
      0.040087923407554626,
      0.06291790306568146,
      -0.07472027093172073,
      -0.02300487644970417,
      0.025946108624339104,
      -0.009190983138978481,
      0.033842120319604874,
      0.06470510363578796,
      0.052997887134552,
      -0.010164296254515648,
      -0.11684204638004303,
      0.014973809942603111,
      0.06717818975448608,
      0.06088915839791298,
      -0.0168842151761055,
      0.07010781019926071,
      0.00032497194479219615,
      -0.021570947021245956,
      -0.06379694491624832,
      -0.01992480643093586,
      0.088842011988163,
      0.00751674035564065,
      0.004584455396980047,
      -0.09072981029748917,
      0.10201708227396011,
      -0.057068802416324615,
      -0.021423744037747383,
      -0.02442813850939274,
      0.026239853352308273,
      0.059490106999874115,
      0.02107502706348896,
      0.04520624130964279,
      -0.0009546118672005832,
      0.010203976184129715,
      0.011859169229865074,
      -0.02777056396007538,
      -0.03737827390432358,
      -0.05530007556080818,
      -0.02395225502550602,
      -0.01235307939350605,
      -0.09106744825839996,
      0.07963968068361282,
      0.01851811632514,
      -0.06896436214447021,
      0.060336992144584656,
      -0.0023087875451892614,
      0.04414772614836693,
      -0.04245079681277275,
      0.03273511677980423,
      0.037258394062519073,
      -0.038192763924598694,
      0.08941656351089478,
      0.03759780153632164,
      -0.03634331747889519,
      0.031532321125268936,
      0.042955927550792694,
      0.012932254932820797,
      -0.015510245226323605,
      -0.0010149389272555709,
      0.03672332689166069,
      -0.03325437754392624,
      0.01823795959353447,
      0.04605424776673317,
      -0.0013354243710637093,
      -0.045567724853754044,
      0.02483474649488926,
      0.010777377523481846,
      0.00846791546791792,
      0.020402684807777405,
      0.0016744080930948257,
      0.025557098910212517,
      0.023463495075702667,
      -0.038136571645736694,
      -0.00038550689350813627,
      0.06110810115933418,
      0.032728735357522964,
      -0.01947173662483692,
      0.004739849828183651,
      -0.031088631600141525,
      -0.00018478457059245557,
      -0.033705417066812515,
      -0.022576332092285156,
      -0.11414001137018204
    ],
    [
      0.07655135542154312,
      -0.01651676930487156,
      0.015486610122025013,
      -0.0007659923867322505,
      -0.03353447467088699,
      0.002527988748624921,
      0.013221334666013718,
      0.05570982024073601,
      0.0012989393435418606,
      -0.062235988676548004,
      -0.016892358660697937,
      0.03997841477394104,
      -0.005829471629112959,
      0.01288030855357647,
      0.029995588585734367,
      -0.036259718239307404,
      -0.040486499667167664,
      0.025180209428071976,
      -0.02086501196026802,
      0.02807650901377201,
      0.05774812400341034,
      0.020465075969696045,
      0.020414846017956734,
      -0.06457938998937607,
      0.09332317113876343,
      -0.03830535709857941,
      -0.024073868989944458,
      -0.00024570748792029917,
      -0.04720330983400345,
      0.02449548803269863,
      -0.026751451194286346,
      -0.10478470474481583,
      -0.021307267248630524,
      0.004981977865099907,
      0.0441025085747242,
      -0.030481358990073204,
      -0.022074293345212936,
      0.006292975507676601,
      0.04259058088064194,
      0.021833989769220352,
      0.05966952443122864,
      0.008382581174373627,
      -0.022982634603977203,
      -0.011723554693162441,
      -0.025684498250484467,
      0.07129977643489838,
      0.003356503089889884,
      0.029551733285188675,
      -0.05626555532217026,
      -0.023476626724004745,
      -0.11931206285953522,
      -0.014427154324948788,
      -0.007828927598893642,
      -0.05436345562338829,
      0.07484094053506851,
      -0.039378874003887177,
      0.11065825819969177,
      0.02272687293589115,
      0.028768830001354218,
      -0.01882810704410076,
      -0.037775296717882156,
      -0.013686725869774818,
      -0.006980004720389843,
      0.052456315606832504,
      0.002375427633523941,
      -0.04898415878415108,
      0.0179089717566967,
      0.06998208165168762,
      -0.027896950021386147,
      0.003084104508161545,
      0.011268054135143757,
      -0.007676613982766867,
      4.1401035559829324e-05,
      -0.0025937084574252367,
      0.08582994341850281,
      0.00822078064084053,
      -0.00970025360584259,
      -0.02752401866018772,
      0.10890079289674759,
      -0.0033811514731496572,
      -0.058042000979185104,
      -0.04408670961856842,
      -0.03081533871591091,
      0.007948491722345352,
      0.050819214433431625,
      -0.041103385388851166,
      -0.03727581351995468,
      -0.0184578038752079,
      -0.052230432629585266,
      -0.04242676496505737,
      -0.03984520956873894,
      0.03859741613268852,
      -0.00738748861476779,
      -0.008582640439271927,
      -0.07625042647123337,
      0.050943974405527115,
      -0.010339035652577877,
      0.012947428971529007,
      -0.057923417538404465,
      0.08383583277463913,
      0.06657581031322479,
      -0.02554863877594471,
      -0.046206265687942505,
      -0.08620893955230713,
      -0.023991946130990982,
      -0.07417804002761841,
      0.02238917164504528,
      -0.03396593779325485,
      0.0245355274528265,
      0.05171191692352295,
      0.07195527106523514,
      -0.029235612601041794,
      0.06332326680421829,
      -0.036644257605075836,
      0.04693639278411865,
      0.03213069960474968,
      0.0027907828334718943,
      -0.045874349772930145,
      -0.025640198960900307,
      0.07101429998874664,
      -0.0342768058180809,
      0.026313943788409233,
      -0.014524365775287151,
      -0.04540484771132469,
      0.026029188185930252,
      0.051624540239572525,
      0.04500730335712433,
      -0.00397416390478611,
      0.032581791281700134,
      -0.01862885057926178,
      0.006559309549629688,
      -0.10635045915842056,
      -0.052968598902225494,
      0.018905075266957283,
      -0.018031729385256767,
      -0.031507693231105804,
      -0.03904281184077263,
      -0.026587054133415222,
      0.004662013612687588,
      -0.0006961902836337686,
      0.06676355004310608,
      0.00646709930151701,
      -0.0273923110216856,
      0.05200289562344551,
      -0.027752462774515152,
      -0.014849740080535412,
      -0.018904156982898712,
      -0.03371073678135872,
      -0.00025670352624729276,
      -0.026422269642353058,
      0.023267997428774834,
      -0.0013019669568166137,
      0.034698888659477234,
      -0.013384685851633549,
      0.03691898286342621,
      -0.003974044695496559,
      -0.023184049874544144,
      0.03788163512945175,
      -0.0456131212413311,
      0.04331682249903679,
      -0.027970904484391212,
      -0.06623012572526932,
      -0.03279039263725281,
      -0.03017088584601879,
      0.048503052443265915,
      -0.10022235661745071,
      0.01175922155380249,
      0.020097428932785988,
      0.02445477806031704,
      -0.02409789152443409,
      -0.07005362212657928,
      -0.0022928924299776554,
      -0.02099449932575226,
      -0.06453622132539749,
      0.07299478352069855,
      0.04161818325519562,
      -0.037353694438934326,
      0.02022360824048519,
      0.016076425090432167,
      -0.03907351940870285,
      0.08864098787307739,
      0.07191843539476395,
      -0.033523667603731155,
      -0.03036714717745781,
      -0.0928972065448761,
      -0.04575008153915405,
      -0.04623088613152504,
      -0.01925230212509632,
      0.06629787385463715,
      -0.054744426161050797,
      0.021701136603951454,
      0.008212365210056305,
      -0.020270682871341705,
      -0.06481689214706421,
      -0.0664176195859909,
      0.012609188444912434,
      -0.00942348875105381,
      -0.05095524340867996,
      0.020671570673584938,
      -0.010125113651156425,
      -0.10472338646650314,
      -0.031584233045578,
      -0.009385943412780762,
      -0.04309174790978432,
      0.01622091792523861,
      0.0363101065158844,
      -0.10609417408704758,
      -0.0730632096529007,
      -5.366477489587851e-05,
      -0.0375111885368824,
      0.04546612128615379,
      0.03742755576968193,
      0.029465464875102043,
      0.059555716812610626,
      0.018617594614624977,
      0.03534821420907974,
      -0.024563690647482872,
      0.13933564722537994,
      -0.060705579817295074,
      0.052737731486558914,
      -0.019559968262910843,
      0.00429619662463665,
      -0.02112480439245701,
      0.029294786974787712,
      0.07361756265163422,
      0.03509432449936867,
      -0.025065872818231583,
      -0.045308105647563934,
      0.007442752365022898,
      -0.07198084890842438,
      -0.0644509419798851,
      -0.02270839549601078,
      0.01952429488301277,
      -0.05749344825744629,
      -0.04339732602238655,
      0.03201412037014961,
      0.01856893301010132,
      0.07719931751489639,
      0.015433715656399727,
      0.036965884268283844,
      -0.04254410043358803,
      -0.024876009672880173,
      -0.055486492812633514,
      -0.02992367371916771,
      -0.01979382522404194,
      0.04736686497926712,
      -0.11146426945924759,
      -0.036635931581258774,
      0.006840404123067856,
      0.09425457566976547,
      -0.036887865513563156,
      -0.0767197459936142,
      -0.00699297059327364,
      -0.04413759708404541,
      0.08843410015106201,
      0.024055903777480125,
      0.0064621614292263985,
      -0.05712682753801346,
      -0.008757397532463074,
      0.08285757154226303,
      -0.0014386136317625642,
      0.040960535407066345,
      0.0013436948647722602,
      0.042210742831230164,
      -0.019298776984214783,
      0.02729540318250656,
      0.0308846402913332,
      -0.05907021835446358,
      -0.030353156849741936,
      -0.03403858095407486,
      -0.0541050024330616,
      -0.014429878443479538,
      -0.0055450634099543095,
      -0.05833849683403969,
      -0.002761723706498742,
      0.044683828949928284,
      -0.015777673572301865,
      -0.031040150672197342,
      0.05078999698162079,
      0.015936559066176414,
      0.08462172746658325,
      -0.1211964339017868,
      -0.02227071113884449,
      -0.05102715268731117,
      0.06125536561012268,
      -0.024638263508677483,
      0.03053572215139866,
      0.08728890866041183,
      -0.022058041766285896,
      -0.09642816334962845,
      -0.015895003452897072,
      6.549380486831069e-07,
      0.013983422890305519,
      0.06641093641519547,
      0.006052426062524319,
      0.016044169664382935,
      0.05552658066153526,
      0.021033240482211113,
      -0.026203585788607597,
      0.021804098039865494,
      0.034471433609724045,
      0.008983046747744083,
      -0.05104309320449829,
      0.0792517215013504,
      0.02852821722626686,
      0.00744584109634161,
      0.007765974383801222,
      -0.040051739662885666,
      0.0306696854531765,
      -0.026217693462967873,
      -0.06601405143737793,
      -0.06876951456069946,
      0.04651081934571266,
      -0.014634765684604645,
      -0.002602229593321681,
      -0.04145496338605881,
      -0.021350352093577385,
      0.013188830576837063,
      0.03490839898586273,
      -0.07512946426868439,
      -0.05207781866192818,
      -0.01523104403167963,
      0.022136559709906578,
      -0.05740242451429367,
      0.06083810329437256,
      -0.02554902993142605,
      0.054375506937503815,
      -0.05835135653614998,
      0.0033699709456413984,
      -0.016779255121946335,
      -0.01727995276451111,
      -0.09138277918100357,
      -0.003975838888436556,
      0.05846003070473671,
      -0.02017991803586483,
      0.04044446349143982,
      -0.02833724394440651,
      -0.041464664041996,
      -0.011409086175262928,
      -0.0512184202671051,
      -0.007182840257883072,
      0.009971867315471172,
      -0.02346929907798767,
      0.014921856112778187,
      0.01628253608942032,
      0.0008298080647364259,
      -0.020163916051387787,
      0.005918018985539675,
      0.03816502168774605,
      -0.009831401519477367,
      0.03526223823428154,
      -0.021474573761224747,
      0.018023034557700157,
      -0.029449105262756348,
      -0.012413040734827518,
      0.03806573525071144,
      0.04766852781176567,
      -0.04371451213955879,
      -0.07018256187438965,
      -0.018594883382320404,
      0.0029786850791424513,
      -0.10992734879255295,
      -0.04886094480752945,
      -0.03320005163550377,
      0.029725193977355957,
      -0.03592689707875252,
      0.011659454554319382,
      -0.026021400466561317,
      -0.017704255878925323,
      -0.03350015729665756,
      -0.04879862442612648,
      -0.058653559535741806,
      -0.04989824444055557,
      0.046934183686971664,
      0.029833786189556122,
      -0.053809262812137604,
      -0.033614471554756165,
      -0.030779074877500534,
      -0.08332797139883041,
      0.0025202976539731026,
      0.010844754986464977,
      -0.01689642108976841,
      0.030613748356699944,
      0.024792753159999847,
      -0.06014673411846161,
      0.0546291321516037,
      -0.05650455877184868,
      0.015330378897488117,
      -0.009032565169036388,
      0.06690467894077301,
      -0.024599714204669,
      -0.0400523841381073,
      0.0745953619480133,
      0.006384916603565216,
      0.029150228947401047,
      -0.09464944899082184,
      0.018815653398633003,
      0.0038951283786445856,
      0.010669585317373276,
      0.020382247865200043,
      0.0891495794057846,
      0.05414246395230293,
      -0.03125148266553879,
      0.0118648000061512,
      0.05374349281191826,
      -0.03394859656691551,
      0.013811015523970127,
      -0.00973767600953579,
      0.04814044386148453,
      -0.08213281631469727,
      0.057206835597753525,
      0.05251604691147804,
      -0.045424941927194595,
      -0.023093294352293015,
      0.059999018907547,
      0.0197340939193964,
      -0.007483018096536398,
      -0.028835445642471313,
      -0.030015312135219574,
      -0.05744333565235138,
      -0.0052752117626369,
      -0.060318320989608765,
      -0.002488777507096529,
      0.0004194662906229496,
      0.0298948772251606,
      0.00901823490858078,
      -0.013874118216335773,
      -0.05021735280752182,
      -0.03897491469979286,
      -0.042387478053569794,
      -0.05115387588739395,
      0.03165308013558388,
      -0.020932083949446678,
      0.012963428162038326,
      0.037650447338819504,
      0.0219659972935915,
      -0.06204638630151749,
      0.03094797395169735,
      0.003256611293181777,
      -0.02390153706073761,
      -0.04196120798587799,
      0.05514759570360184,
      0.06079574301838875,
      -0.023897729814052582,
      0.11806447058916092,
      0.057851795107126236,
      -0.02378765679895878,
      -0.051942337304353714,
      -0.03827855363488197,
      -0.00449066748842597,
      0.06439565122127533,
      -0.044526249170303345,
      -0.004750657826662064,
      0.05131926387548447,
      0.002800878370180726,
      -0.0007588638109154999,
      -0.10102380812168121,
      0.006589910946786404,
      -0.06844279915094376,
      -0.1053028255701065,
      0.010786310769617558,
      0.06049790233373642,
      -0.020074523985385895,
      0.07875596731901169,
      -0.013085033744573593,
      -0.051388006657361984,
      -0.09052879363298416,
      0.0032668588683009148,
      -0.015915485098958015,
      0.042413633316755295,
      -0.01746469922363758,
      -0.048913855105638504,
      0.01916707120835781,
      0.05644747614860535,
      -0.0316956602036953,
      -0.0022766003385186195,
      -0.010559886693954468,
      -0.007141767535358667,
      0.04715485870838165,
      0.01578655280172825,
      -0.0007676101522520185,
      0.014257590286433697,
      -0.017235809937119484,
      0.008375999517738819,
      -0.019596774131059647,
      0.01672740839421749,
      0.027860159054398537,
      -0.06308036297559738,
      0.02310141548514366,
      -0.0538334846496582,
      -0.06790069490671158,
      -0.07108324021100998,
      -0.038553789258003235,
      -0.04212067276239395,
      -0.05085543915629387,
      0.006998172029852867,
      0.0249320100992918,
      -0.11443781852722168,
      0.11037921160459518,
      0.02937246300280094,
      -0.010148550383746624,
      -0.01991020329296589,
      -0.005316511727869511
    ],
    [
      -0.04862089827656746,
      0.09987664967775345,
      -0.06338685750961304,
      -0.051060907542705536,
      -0.04063127934932709,
      -0.014814342372119427,
      -0.05317389965057373,
      0.11587898433208466,
      -0.021479343995451927,
      0.013790999539196491,
      -0.006584763061255217,
      -0.04366523399949074,
      -0.01369784027338028,
      -0.005380536895245314,
      -0.003632861888036132,
      0.005843121558427811,
      -0.026964563876390457,
      -0.03483491390943527,
      -0.011006682179868221,
      0.014450921677052975,
      0.11576487123966217,
      -0.05531124770641327,
      0.03602909669280052,
      -0.08118826150894165,
      0.03891899436712265,
      0.014531204476952553,
      -0.029965801164507866,
      -0.09982124716043472,
      -0.020749755203723907,
      0.003014450194314122,
      -0.059759099036455154,
      -0.04160857945680618,
      0.1017352044582367,
      -0.025355173274874687,
      0.00117885775398463,
      0.03496897593140602,
      0.01076795719563961,
      0.047648243606090546,
      -0.04627242311835289,
      0.05433828756213188,
      -0.05974322929978371,
      0.020879825577139854,
      -0.026876239106059074,
      -0.051107630133628845,
      0.04338734596967697,
      0.026733431965112686,
      0.08363897353410721,
      0.0066148568876087666,
      0.06192505732178688,
      0.04556864872574806,
      -0.020058169960975647,
      0.005147429648786783,
      0.050462640821933746,
      -0.06343080848455429,
      -0.02062268927693367,
      -0.024803733453154564,
      0.053802505135536194,
      0.06537871807813644,
      0.059401389211416245,
      0.053594786673784256,
      -0.008440619334578514,
      0.03254014998674393,
      -0.07725610584020615,
      0.04181401804089546,
      0.08680234104394913,
      -0.03339499235153198,
      0.04713210090994835,
      0.04698969051241875,
      0.079679936170578,
      -0.04223163053393364,
      0.03586952015757561,
      0.026061374694108963,
      -0.005180001724511385,
      0.031756509095430374,
      -0.008843022398650646,
      0.033924151211977005,
      -0.020189791917800903,
      0.021771322935819626,
      0.05143393948674202,
      0.028888732194900513,
      0.08832605183124542,
      -0.07179904729127884,
      0.0455685518682003,
      -0.024532582610845566,
      -0.019842643290758133,
      0.0029363613575696945,
      -0.022023014724254608,
      0.049878645688295364,
      -0.05043048784136772,
      0.07739509642124176,
      -0.02597345970571041,
      0.05879001319408417,
      -0.01844281144440174,
      0.0036568266805261374,
      0.036072734743356705,
      -0.00804316345602274,
      -0.019096313044428825,
      -0.004230651073157787,
      0.017033759504556656,
      -0.04582465812563896,
      -0.0064169857650995255,
      0.00858778040856123,
      0.004770995117723942,
      -0.08819247037172318,
      0.008079463616013527,
      0.07628785073757172,
      -0.033264514058828354,
      0.02128082513809204,
      0.008658849634230137,
      0.05181633681058884,
      0.04222753643989563,
      0.05750320479273796,
      0.011683045886456966,
      0.01903294399380684,
      0.06307815760374069,
      0.016572721302509308,
      0.06098787114024162,
      -0.04074544087052345,
      0.006912559270858765,
      -0.028243184089660645,
      -0.03747032582759857,
      0.00837966613471508,
      0.04492873325943947,
      0.033011700958013535,
      0.04836050793528557,
      0.015792183578014374,
      -0.029977580532431602,
      0.010676431469619274,
      0.07742109894752502,
      0.059395477175712585,
      0.03157179802656174,
      0.024830421432852745,
      0.07264461368322372,
      -0.010996676981449127,
      -0.039677944034338,
      0.028024518862366676,
      -0.08844030648469925,
      -0.05813615024089813,
      0.058561764657497406,
      -0.05452396348118782,
      0.02646186202764511,
      0.0055963872000575066,
      0.02104504220187664,
      0.005295926705002785,
      0.029713980853557587,
      0.027469344437122345,
      -0.06640902906656265,
      -0.05190083384513855,
      -0.034249529242515564,
      -0.0740620493888855,
      -0.029405107721686363,
      -0.03959422558546066,
      -0.02426888607442379,
      0.005776065867394209,
      0.008769863285124302,
      -0.02710147574543953,
      0.02939225174486637,
      0.030415087938308716,
      -0.09539298713207245,
      -0.011788396164774895,
      -0.030563348904252052,
      0.017954006791114807,
      -0.06645998358726501,
      0.06055615469813347,
      -0.026607155799865723,
      -0.028762491419911385,
      0.03636736422777176,
      0.07034850120544434,
      -0.03141750395298004,
      -0.022246791049838066,
      -0.02354377880692482,
      0.016339419409632683,
      -0.05835001543164253,
      0.006230033002793789,
      0.028991498053073883,
      0.008399227634072304,
      -0.00925547257065773,
      -0.028167277574539185,
      0.06522443890571594,
      -0.00368896103464067,
      -0.015215820632874966,
      -0.012295224703848362,
      -0.01295098103582859,
      0.003554107155650854,
      -0.030317815020680428,
      0.015120125375688076,
      0.036881670355796814,
      -0.021497705951333046,
      -0.005952966399490833,
      0.052469607442617416,
      0.031157294288277626,
      -0.027792803943157196,
      -0.02332962676882744,
      -0.04033827781677246,
      -0.06069858372211456,
      -0.0025037801824510098,
      -0.022602807730436325,
      -0.047578856348991394,
      0.03802894428372383,
      -0.0043234736658632755,
      -0.021229803562164307,
      0.02503802999854088,
      0.003419435117393732,
      0.003028401406481862,
      -0.010215110145509243,
      -0.010721048340201378,
      -0.06091997027397156,
      -0.04426465183496475,
      0.10543684661388397,
      0.033439699560403824,
      0.03979192301630974,
      0.02479054406285286,
      0.02615469880402088,
      -0.013532448559999466,
      0.0238250270485878,
      0.0027201413176953793,
      -0.025115489959716797,
      0.05197571590542793,
      0.03445839136838913,
      -0.047012947499752045,
      0.016674967482686043,
      0.04238732159137726,
      0.021394293755292892,
      0.014697462320327759,
      -0.05839499086141586,
      -0.08705222606658936,
      0.004352431278675795,
      0.024595456197857857,
      0.05257359519600868,
      0.004992171656340361,
      -0.10328444838523865,
      -0.015591684728860855,
      0.006606466602534056,
      0.03109820932149887,
      -0.016590148210525513,
      0.050718873739242554,
      -0.032024797052145004,
      -0.013433970510959625,
      -0.06682878732681274,
      -0.024562658742070198,
      -0.06144348159432411,
      -0.04231297969818115,
      0.04668785631656647,
      0.06259690225124359,
      -0.019671089947223663,
      0.040872972458601,
      -0.01561039686203003,
      0.0007570715388283134,
      -0.054740894585847855,
      0.03555941581726074,
      -0.047547582536935806,
      -0.03674313798546791,
      -0.028981177136301994,
      0.006866656243801117,
      0.027247225865721703,
      0.025746216997504234,
      0.054837752133607864,
      -0.008046532049775124,
      -0.005936791654676199,
      0.027652205899357796,
      0.020638326182961464,
      0.016364717856049538,
      0.030442198738455772,
      -0.00624943757429719,
      -0.05342236906290054,
      -0.017137425020337105,
      0.020805181935429573,
      -0.059641897678375244,
      -0.0524430051445961,
      0.0778457447886467,
      0.027351753786206245,
      0.03324528783559799,
      -0.010084792971611023,
      -0.01575053296983242,
      0.024774452671408653,
      -0.0731511265039444,
      -0.07005896419286728,
      -0.016841286793351173,
      -0.033077891916036606,
      0.06485539674758911,
      0.030914347618818283,
      0.023892056196928024,
      -0.03193869814276695,
      0.027498694136738777,
      -0.006854677572846413,
      0.042893923819065094,
      -0.01008109375834465,
      -0.03226088732481003,
      0.0009981476468965411,
      0.049563489854335785,
      -0.040547024458646774,
      0.09154113382101059,
      0.013681869022548199,
      0.013993307948112488,
      -0.007599927950650454,
      0.003434036858379841,
      -0.013986925594508648,
      0.010573659092187881,
      0.05318492650985718,
      -0.04860447347164154,
      0.016307348385453224,
      -0.03855767473578453,
      -0.039975520223379135,
      -0.03794777765870094,
      0.03238113224506378,
      0.03517515957355499,
      -0.034896619617938995,
      -0.02163543552160263,
      0.019759317860007286,
      0.02217053435742855,
      -0.028176672756671906,
      -0.0030873166397213936,
      0.017647508531808853,
      -0.0017473188927397132,
      0.03266555443406105,
      -0.03950812667608261,
      0.0194601621478796,
      0.0392322763800621,
      0.04369452968239784,
      0.01876121386885643,
      -0.07002978771924973,
      -0.000728498911485076,
      0.006752273067831993,
      0.034435976296663284,
      0.06141902506351471,
      0.023789314553141594,
      0.05982782319188118,
      0.06765635311603546,
      -0.0011398941278457642,
      0.06799394637346268,
      0.013356073759496212,
      0.05400783568620682,
      0.05480041354894638,
      0.017392320558428764,
      0.036105863749980927,
      0.057813987135887146,
      -0.0028535891324281693,
      0.03160971403121948,
      -0.014919396489858627,
      0.029871737584471703,
      -0.022919930517673492,
      0.020922673866152763,
      0.03530732914805412,
      0.050086233764886856,
      -0.029041968286037445,
      0.0022362659219652414,
      0.05683383345603943,
      -0.05528004467487335,
      -0.03221483901143074,
      0.040612563490867615,
      -0.04909348115324974,
      -0.03670037165284157,
      -0.037741728127002716,
      0.0040528178215026855,
      0.01067460048943758,
      -0.0671498253941536,
      0.006733547430485487,
      -0.007636234164237976,
      -0.008484018966555595,
      0.028401793912053108,
      0.012025633826851845,
      0.07101596146821976,
      0.009833984076976776,
      0.05543673783540726,
      -0.012700210325419903,
      -0.07226039469242096,
      -0.057968996465206146,
      -0.007508091162890196,
      -0.0034215543419122696,
      -0.08958153426647186,
      -0.007294035051018,
      0.03290139138698578,
      0.014486841857433319,
      -0.06041672080755234,
      -0.024623658508062363,
      0.04196931794285774,
      -0.05493079498410225,
      0.0016347523778676987,
      0.10238370299339294,
      -0.0313163623213768,
      0.05857686325907707,
      0.025635039433836937,
      -0.009653019718825817,
      0.020948592573404312,
      -0.039673060178756714,
      0.061813149601221085,
      -0.009248246438801289,
      0.002199874259531498,
      0.009982794523239136,
      0.03726647049188614,
      -0.00413707597181201,
      -0.027882443740963936,
      -0.04819426313042641,
      -0.09817804396152496,
      -0.009869763627648354,
      -0.023208091035485268,
      -0.0065561216324567795,
      -0.058847710490226746,
      -0.006877330131828785,
      0.0034450357779860497,
      -0.048867981880903244,
      -0.03506769612431526,
      0.10619056969881058,
      0.06716931611299515,
      0.06101435050368309,
      -0.03714771196246147,
      -0.05101345106959343,
      -0.00407914025709033,
      0.01418217457830906,
      -0.038889747112989426,
      0.008574213832616806,
      -0.07753051817417145,
      -0.029542425647377968,
      0.02986810728907585,
      -0.05344128608703613,
      0.036421697586774826,
      -0.014097612351179123,
      -0.046708300709724426,
      0.03183387219905853,
      -0.07837627083063126,
      0.0279193464666605,
      -0.013782599940896034,
      0.039480067789554596,
      0.038073837757110596,
      0.03749352693557739,
      -0.03838087618350983,
      0.0013417995069175959,
      -0.10645239055156708,
      -0.056190185248851776,
      -0.061929766088724136,
      0.00033702535438351333,
      0.018339794129133224,
      0.006899106316268444,
      -0.004673411138355732,
      -0.03484485298395157,
      0.03597646951675415,
      -0.0032798827160149813,
      -0.022333752363920212,
      0.051759082823991776,
      -0.03872651979327202,
      0.037292126566171646,
      0.057202331721782684,
      0.005845848936587572,
      0.044399432837963104,
      -0.00218931189738214,
      -0.11588551104068756,
      0.0099377715960145,
      0.08013128489255905,
      -0.008731395937502384,
      0.032965052872896194,
      0.06850992888212204,
      0.021675266325473785,
      0.0643678605556488,
      0.0021974267438054085,
      -0.03579113632440567,
      0.02210974134504795,
      -0.008798038586974144,
      -0.029701927676796913,
      -0.027053743600845337,
      -0.07369282096624374,
      0.08125513046979904,
      0.011402360163629055,
      0.013536450453102589,
      0.0998920425772667,
      0.017219021916389465,
      -0.005286293104290962,
      0.0022947504185140133,
      0.031633730977773666,
      0.02406131848692894,
      -0.03499316796660423,
      -0.011988692916929722,
      -0.001807516091503203,
      0.04367135837674141,
      -0.020476339384913445,
      0.04622067138552666,
      0.01996638998389244,
      -0.06362040340900421,
      -0.017283659428358078,
      -0.054500844329595566,
      0.05784173682332039,
      0.05443895608186722,
      -0.042368486523628235,
      -0.04014362767338753,
      0.04674705117940903,
      -0.018218092620372772,
      0.08892016112804413,
      0.013659358024597168,
      0.015429365448653698,
      0.042171090841293335,
      0.00964949931949377,
      0.0611933209002018,
      -0.017864089459180832,
      -0.012160176411271095,
      -0.06303195655345917,
      0.008773903362452984,
      0.027600441128015518,
      0.06562983244657516,
      -0.022540707141160965,
      0.02477334626019001,
      -0.008795655332505703,
      0.04713115468621254,
      0.09372926503419876,
      -0.06828993558883667
    ],
    [
      0.018130194395780563,
      -0.02946486882865429,
      0.038106855005025864,
      0.039460439234972,
      0.031477607786655426,
      -0.009786234237253666,
      -0.08171011507511139,
      0.12632469832897186,
      0.04475190117955208,
      0.003263025777414441,
      -0.031102705746889114,
      -0.10690874606370926,
      -0.028297554701566696,
      0.07968858629465103,
      -0.054615236818790436,
      0.02656242623925209,
      -0.02022942341864109,
      -0.01754387654364109,
      -0.024098649621009827,
      -0.07867496460676193,
      0.028843721374869347,
      -0.03197360411286354,
      -0.0005394775071181357,
      -0.048442985862493515,
      0.10023404657840729,
      -0.0442490354180336,
      -0.03915950655937195,
      0.02320246398448944,
      -0.032315224409103394,
      0.017154229804873466,
      -0.00027194098220206797,
      0.05229117348790169,
      -0.01152893714606762,
      -0.011760926805436611,
      0.04342737793922424,
      0.0012466252082958817,
      -0.022075170651078224,
      -0.06491369009017944,
      -0.1012173667550087,
      0.001840424258261919,
      -0.08011458814144135,
      0.04192182049155235,
      0.02584506943821907,
      0.08587726950645447,
      0.0642833337187767,
      0.023673376068472862,
      0.030711714178323746,
      0.008462210185825825,
      0.05368081480264664,
      -0.02555009163916111,
      0.02926011197268963,
      -0.02401631698012352,
      -0.042787108570337296,
      0.024098742753267288,
      0.0021433429792523384,
      0.02397240325808525,
      0.11165551096200943,
      -0.038484618067741394,
      0.048366084694862366,
      -0.0016918752808123827,
      0.07574228942394257,
      0.05493845418095589,
      -0.05940290912985802,
      -0.02276437170803547,
      0.052075278013944626,
      0.058367740362882614,
      0.005093378480523825,
      0.010225963778793812,
      -0.06666301935911179,
      0.0057649328373372555,
      0.023804306983947754,
      0.0016634390922263265,
      -0.05582490190863609,
      0.04164622724056244,
      0.0014835861511528492,
      0.024202285334467888,
      -0.040277667343616486,
      -0.0071280659176409245,
      0.008530951105058193,
      -0.0001941968803294003,
      0.06427722424268723,
      -0.038090210407972336,
      -0.04350071772933006,
      -0.034845881164073944,
      0.06401213258504868,
      0.022220725193619728,
      0.02316245064139366,
      0.0028136810287833214,
      0.028331054374575615,
      -0.06255558133125305,
      -0.01689225807785988,
      0.0702931135892868,
      -0.04551592841744423,
      0.03342467546463013,
      0.12863586843013763,
      -0.0347236730158329,
      -0.013449731282889843,
      -0.06387829035520554,
      0.008829993195831776,
      -0.032861389219760895,
      0.02511056512594223,
      0.006931218318641186,
      0.010727365501224995,
      -0.02825242094695568,
      -0.04030700773000717,
      0.027457769960165024,
      -0.006349766161292791,
      0.09231213480234146,
      -0.02612951211631298,
      0.001863279496319592,
      0.014774555340409279,
      -0.02107441984117031,
      0.04376055672764778,
      0.017234206199645996,
      0.02719198539853096,
      -0.011764808557927608,
      -0.017681987956166267,
      -0.04830581694841385,
      0.056827612221241,
      0.03307430446147919,
      -0.12567350268363953,
      0.04040099307894707,
      0.010680071078240871,
      -0.021765008568763733,
      0.06746827811002731,
      -0.014719581231474876,
      -0.011289315298199654,
      -0.03519945591688156,
      0.02024427056312561,
      -0.021793851628899574,
      0.01206995639950037,
      -0.004541399888694286,
      0.06578271836042404,
      0.0808057114481926,
      0.00422687316313386,
      0.023821000009775162,
      -0.049325715750455856,
      -0.022138316184282303,
      -0.03473588079214096,
      0.015066532418131828,
      0.052556030452251434,
      0.02289757877588272,
      -0.06452444940805435,
      0.024213489145040512,
      -0.0559096485376358,
      -0.005300797987729311,
      0.026593249291181564,
      0.0017808222910389304,
      -0.016296295449137688,
      0.04519983008503914,
      -0.042504627257585526,
      -0.04780799150466919,
      -0.053309064358472824,
      0.024699673056602478,
      -0.014862664975225925,
      -0.0065508075058460236,
      -0.033258117735385895,
      -0.05151898413896561,
      -0.005147397983819246,
      0.0026001448277384043,
      -0.04194435849785805,
      -0.053092967718839645,
      0.05122130736708641,
      0.021583328023552895,
      0.035188958048820496,
      0.10577596724033356,
      0.029242604970932007,
      0.12520691752433777,
      -0.02340584434568882,
      -0.05126547813415527,
      -0.020033778622746468,
      -0.07907512038946152,
      0.03881484270095825,
      0.08798577636480331,
      -0.042014166712760925,
      -0.07352083176374435,
      0.06754440814256668,
      -0.08425809442996979,
      -0.00858511682599783,
      -0.0270857997238636,
      -0.06902410835027695,
      0.012376912869513035,
      -0.01722762919962406,
      0.04496569558978081,
      0.003374002408236265,
      0.05698727071285248,
      -0.0001361301401630044,
      -0.052434731274843216,
      -0.014424096792936325,
      -0.07660707086324692,
      0.13055704534053802,
      0.02091750130057335,
      -0.024661293253302574,
      -0.02937302552163601,
      -0.03411376476287842,
      0.0025720929261296988,
      -0.03012087196111679,
      -0.05454676225781441,
      0.01196150854229927,
      -0.058582812547683716,
      -0.02426477149128914,
      0.12064727395772934,
      -0.021840685978531837,
      0.05028979107737541,
      0.06839361786842346,
      0.003907822072505951,
      0.030372485518455505,
      -0.021249474957585335,
      -0.00591627974063158,
      -0.03954385221004486,
      0.08875176310539246,
      0.046853501349687576,
      0.006676046643406153,
      -0.02056787721812725,
      -0.05905582755804062,
      -0.05227112025022507,
      -0.0017446503043174744,
      -0.006056585349142551,
      -0.040662381798028946,
      0.0004415808944031596,
      -0.008078203536570072,
      0.12267866730690002,
      0.004037457052618265,
      0.025434736162424088,
      -0.02168908901512623,
      -0.06607991456985474,
      0.08691748976707458,
      -0.02619641087949276,
      0.04856207221746445,
      -0.0009577739983797073,
      0.030985955148935318,
      0.05288296937942505,
      -0.0016592413885518909,
      -0.06997821480035782,
      -0.05316941812634468,
      -0.020483870059251785,
      -0.026220189407467842,
      -0.006985949818044901,
      -0.020540857687592506,
      0.07813791930675507,
      0.02084103599190712,
      0.00801165122538805,
      -0.018173320218920708,
      -0.021310681477189064,
      0.0203972477465868,
      0.05238702893257141,
      -0.043997589498758316,
      0.02186385914683342,
      0.013395597226917744,
      0.062861867249012,
      0.04357985407114029,
      0.05886669084429741,
      -0.024079177528619766,
      0.003848654218018055,
      0.0030246723908931017,
      0.032320503145456314,
      -0.0045132748782634735,
      0.0947454497218132,
      -0.029939904808998108,
      0.02584083005785942,
      -0.035591233521699905,
      0.0037798364646732807,
      -0.00972649548202753,
      -0.007156675215810537,
      0.028674205765128136,
      0.004897515755146742,
      -0.018215611577033997,
      0.03875673562288284,
      -0.023626873269677162,
      0.03641609847545624,
      -0.052662163972854614,
      -0.02574831433594227,
      -0.014043038710951805,
      0.06143605336546898,
      -0.03867177665233612,
      -0.06930375844240189,
      -0.04485708847641945,
      0.0002755285822786391,
      0.025828521698713303,
      0.008064487017691135,
      -0.003097082255408168,
      -0.007535943761467934,
      -0.0392879880964756,
      -0.01568935438990593,
      0.0005189551739022136,
      -0.03562377765774727,
      0.01598316617310047,
      -0.09799572825431824,
      0.024130206555128098,
      0.034232545644044876,
      0.08610992878675461,
      -0.11225789040327072,
      -0.02629614621400833,
      -0.04266015812754631,
      0.003360958304256201,
      -0.047923341393470764,
      0.015596725977957249,
      -0.004534624051302671,
      -0.017219725996255875,
      0.03581559285521507,
      0.0031111545395106077,
      -0.024626385420560837,
      0.059375640004873276,
      0.06108994036912918,
      0.021429911255836487,
      -0.057315997779369354,
      0.025270787999033928,
      -0.013735012151300907,
      -0.008577682077884674,
      0.06833018362522125,
      -0.04370928555727005,
      0.029503049328923225,
      0.04541943594813347,
      0.008086945861577988,
      -0.08661407232284546,
      0.01761487126350403,
      -0.04246681183576584,
      0.035307515412569046,
      0.008126837201416492,
      0.04623439162969589,
      -0.0025453241541981697,
      0.0015802267007529736,
      0.010935612954199314,
      -0.002498000394552946,
      0.13229124248027802,
      0.05300167202949524,
      0.08206876367330551,
      0.07133225351572037,
      0.03609452396631241,
      -0.020701467990875244,
      0.01024283654987812,
      -0.08208867907524109,
      -0.013624042272567749,
      -0.0358629934489727,
      -0.052155692130327225,
      -0.07407457381486893,
      0.047004640102386475,
      0.03326066583395004,
      0.07793998718261719,
      -0.01562545821070671,
      -0.051312804222106934,
      0.04430084303021431,
      0.05933023989200592,
      -0.004058643244206905,
      -0.02252766862511635,
      -0.008525636047124863,
      0.02484467439353466,
      -0.09455900639295578,
      0.06531733274459839,
      -0.07827893644571304,
      -0.1358758509159088,
      -0.030699115246534348,
      0.029447847977280617,
      -0.058816514909267426,
      -0.00497124157845974,
      0.004656228236854076,
      0.005343269091099501,
      0.021732600405812263,
      0.016888359561562538,
      0.04163704812526703,
      -0.007345668040215969,
      -0.0025220199022442102,
      0.002105376683175564,
      0.03159092739224434,
      0.03225230053067207,
      0.05612024664878845,
      -0.034851592034101486,
      -0.027010731399059296,
      0.03510764613747597,
      0.050750114023685455,
      -0.049543872475624084,
      -0.025578271597623825,
      -0.02697264961898327,
      0.02833547443151474,
      0.014521533623337746,
      0.07779146730899811,
      0.0028267749585211277,
      -0.007203202228993177,
      0.005119470879435539,
      -0.040239959955215454,
      -0.07540227472782135,
      -0.06426011025905609,
      -0.02438168227672577,
      -0.016344809904694557,
      -0.03677074611186981,
      0.011720694601535797,
      -0.008990314789116383,
      0.05445583537220955,
      0.012181141413748264,
      -0.02467506192624569,
      -0.007566952612251043,
      -0.04537437483668327,
      -0.04551725462079048,
      0.06832104176282883,
      0.026424016803503036,
      0.0473010316491127,
      -0.004548118449747562,
      0.051035962998867035,
      -0.05155888572335243,
      -0.011621112003922462,
      0.014986037276685238,
      0.017703073099255562,
      -0.007014333736151457,
      0.04139174893498421,
      -0.04367554932832718,
      -0.05174276605248451,
      -0.04294133931398392,
      0.11736644804477692,
      0.0464671291410923,
      0.0902874693274498,
      -0.0139990309253335,
      -0.07727036625146866,
      -0.0699240043759346,
      -0.02856779657304287,
      0.03387078270316124,
      -0.03289489820599556,
      0.04771364480257034,
      0.01260710321366787,
      -0.0045234342105686665,
      0.032886430621147156,
      0.008985552005469799,
      0.07291028648614883,
      -0.011719249188899994,
      -0.07416965067386627,
      -0.008683721534907818,
      0.04083959758281708,
      -0.01849166490137577,
      -0.02821086160838604,
      0.03011712245643139,
      0.07633895426988602,
      0.0052933646366000175,
      0.04197947308421135,
      0.04015730693936348,
      0.029044590890407562,
      0.005011146422475576,
      0.07475333660840988,
      -0.037360817193984985,
      0.008214668370783329,
      -0.0516410693526268,
      0.11897190660238266,
      0.012179909273982048,
      0.054077353328466415,
      -0.008497231639921665,
      -0.10371660441160202,
      0.0599493682384491,
      0.02028684876859188,
      0.034844402223825455,
      -0.03556516766548157,
      0.03537483140826225,
      0.05509527027606964,
      0.047727081924676895,
      -0.01732664927840233,
      0.016232628375291824,
      -0.04021182656288147,
      -0.06619163602590561,
      0.01774180680513382,
      -0.06938928365707397,
      -0.02632853202521801,
      -0.0570281483232975,
      0.03642496466636658,
      -0.022550152614712715,
      0.018057938665151596,
      -0.06402331590652466,
      0.03772498294711113,
      0.12277429550886154,
      0.036364227533340454,
      0.06858228147029877,
      -0.03626953810453415,
      0.013054028153419495,
      -0.0426160991191864,
      -0.07959162443876266,
      0.0367107018828392,
      -0.09241806715726852,
      -0.014696364291012287,
      -0.04161599650979042,
      0.06973078101873398,
      0.04116544499993324,
      0.025512078776955605,
      -0.014285455457866192,
      0.014001037925481796,
      -0.028028711676597595,
      -0.06828606873750687,
      -0.04516676068305969,
      -0.026944797486066818,
      -0.08046261221170425,
      0.03911534324288368,
      0.02097472734749317,
      0.006968208122998476,
      0.013288531452417374,
      -0.025877682492136955,
      0.08058525621891022,
      -0.025729700922966003,
      -0.01185658946633339,
      0.014274682849645615,
      -0.02503754384815693,
      -0.04797857254743576,
      0.02386031672358513,
      -0.04260886460542679,
      0.01048743911087513,
      -0.02352231927216053,
      0.051308296620845795,
      0.036604322493076324,
      0.004625419620424509
    ],
    [
      -0.03316657245159149,
      0.056428179144859314,
      -0.03105795383453369,
      -0.027894431725144386,
      -0.007463270798325539,
      -0.03856749087572098,
      -0.05233670026063919,
      0.038925688713788986,
      0.0062848348170518875,
      0.03684356063604355,
      0.04357140138745308,
      -0.031011298298835754,
      -0.012114467099308968,
      -0.046815916895866394,
      -0.10276822745800018,
      -0.009598440490663052,
      -0.0023131072521209717,
      -0.03614525869488716,
      -0.027902841567993164,
      -0.030195031315088272,
      0.0009529381641186774,
      0.04063485935330391,
      -0.005499986931681633,
      0.0919303447008133,
      0.06686008721590042,
      -0.014126391150057316,
      0.043476611375808716,
      0.04519275575876236,
      0.03292372077703476,
      0.017887357622385025,
      0.05226139351725578,
      0.019868221133947372,
      -0.016132213175296783,
      0.014149455353617668,
      -0.0031791951041668653,
      -0.03976830095052719,
      0.036775827407836914,
      0.05791807547211647,
      -0.09899603575468063,
      -0.03911643847823143,
      -0.00858497153967619,
      0.0310765840113163,
      0.08047079294919968,
      0.00830401387065649,
      0.0023046520072966814,
      -0.05107331648468971,
      0.06980783492326736,
      0.0161354411393404,
      0.009835043922066689,
      0.028013378381729126,
      -0.06147101894021034,
      0.0223491620272398,
      -0.00044639644329436123,
      0.026442671194672585,
      -0.004805790260434151,
      0.00031446933280676603,
      -0.04453595355153084,
      -0.07638946175575256,
      -0.02096358686685562,
      0.019210079684853554,
      0.05888216942548752,
      0.050196852535009384,
      -0.07015419751405716,
      0.02860957197844982,
      0.00613961648195982,
      -0.05948726087808609,
      -0.03234143927693367,
      -0.020440731197595596,
      -0.020378585904836655,
      0.007650718092918396,
      0.04120316728949547,
      0.0066547091118991375,
      -0.005013898480683565,
      -0.01882154867053032,
      -0.009838086552917957,
      -0.01249907910823822,
      0.053824782371520996,
      0.018130775541067123,
      0.0941418781876564,
      0.052981406450271606,
      0.1348406970500946,
      0.07679206132888794,
      0.028208494186401367,
      0.03810419514775276,
      -0.03697872906923294,
      -0.013427145779132843,
      -0.08166524767875671,
      0.028263211250305176,
      0.03174010291695595,
      0.03333912417292595,
      0.05376996472477913,
      -0.029592733830213547,
      -0.018773166462779045,
      -0.00464192871004343,
      0.09209947288036346,
      -0.09702549129724503,
      -0.06369579583406448,
      -0.014223146252334118,
      0.043095629662275314,
      0.009892870672047138,
      -0.04008632153272629,
      0.02455020509660244,
      0.02171643078327179,
      -0.1524592638015747,
      0.0002101699501508847,
      0.036085572093725204,
      -0.03808159381151199,
      -0.026779258623719215,
      0.047916706651449203,
      -0.11547578126192093,
      0.09778371453285217,
      0.002320826519280672,
      0.04829782992601395,
      -0.037188541144132614,
      0.0109926238656044,
      -0.033151887357234955,
      0.0016969431890174747,
      -0.09833880513906479,
      -0.015884073451161385,
      -0.03468091040849686,
      -0.011648993007838726,
      -0.02306249737739563,
      -0.0461597703397274,
      -0.016327746212482452,
      0.0780896246433258,
      0.023831546306610107,
      0.02267228439450264,
      -0.0949016660451889,
      0.04388290271162987,
      0.00024225683591794223,
      -0.037722762674093246,
      0.02270451933145523,
      -0.05444127321243286,
      -0.004690725822001696,
      0.044413208961486816,
      -0.04845372214913368,
      0.09602557867765427,
      -0.041770949959754944,
      -0.014341444708406925,
      -0.024073658511042595,
      -0.047776829451322556,
      -0.03602123633027077,
      -0.025278551504015923,
      0.04974360391497612,
      -0.043851952999830246,
      -0.022062750533223152,
      0.01833040453493595,
      -0.016817811876535416,
      0.0528867244720459,
      -0.018308540806174278,
      -0.011660303920507431,
      0.02834506146609783,
      0.009556048549711704,
      -0.03409899026155472,
      -0.03339996188879013,
      -0.08005772531032562,
      0.0023744660429656506,
      0.004392068833112717,
      -0.12821801006793976,
      0.028523920103907585,
      0.024156684055924416,
      0.043362006545066833,
      -0.08906034380197525,
      0.0434315986931324,
      -0.03100503422319889,
      -0.06642937660217285,
      -0.0921596884727478,
      -0.03153706714510918,
      -0.05755724012851715,
      -0.028463440015912056,
      -0.02590855024755001,
      0.00630373228341341,
      0.02247260883450508,
      -0.018519781529903412,
      0.031195295974612236,
      -0.01638094335794449,
      -0.024543920531868935,
      -0.033154215663671494,
      -0.03787614777684212,
      0.009652880020439625,
      -0.013451538048684597,
      -0.04010516405105591,
      -0.03622645512223244,
      0.0005563987651839852,
      0.044541411101818085,
      0.01538180559873581,
      -0.042300447821617126,
      0.05183180794119835,
      0.11322922259569168,
      -0.010632977820932865,
      -0.004822396207600832,
      -0.04048735648393631,
      0.015340127982199192,
      0.024417473003268242,
      -0.05233532562851906,
      0.012087535113096237,
      -0.00080588593846187,
      -0.04733733832836151,
      0.04454052820801735,
      -0.06397458165884018,
      0.021245690062642097,
      0.11072340607643127,
      -0.034230973571538925,
      -0.03848453238606453,
      -0.01423164363950491,
      -0.031073639169335365,
      -0.004456734750419855,
      -0.04595334455370903,
      -0.04146610200405121,
      -0.01472077053040266,
      -0.005148930475115776,
      -0.028182055801153183,
      0.003984134178608656,
      0.006618700455874205,
      -0.019674787297844887,
      0.08052430301904678,
      -0.023921512067317963,
      -0.04514501243829727,
      0.05482739210128784,
      0.01587519608438015,
      0.05195768177509308,
      -0.056485507637262344,
      0.029885495081543922,
      0.027008656412363052,
      -0.02384667843580246,
      -0.058929555118083954,
      -0.016251832246780396,
      0.0626496896147728,
      0.0009704033727757633,
      -0.005505602806806564,
      0.0123776039108634,
      -0.04815111309289932,
      0.0369604229927063,
      -0.0217275433242321,
      -0.04090949147939682,
      0.027473103255033493,
      -0.007865268737077713,
      -0.0632627010345459,
      -0.05454249680042267,
      -0.07075738906860352,
      -0.013849255628883839,
      -0.032576676458120346,
      -0.06964992731809616,
      -0.009365621022880077,
      0.0503549724817276,
      -0.007937442511320114,
      -0.013901818543672562,
      -0.12104076892137527,
      -0.03270493447780609,
      0.02386079728603363,
      0.03761599212884903,
      0.024960434064269066,
      -0.019862139597535133,
      -0.028405308723449707,
      0.134335458278656,
      0.04575404152274132,
      -0.010643738321959972,
      0.05711013823747635,
      -0.0160664152354002,
      0.02238384261727333,
      -0.011224617250263691,
      -0.05123841017484665,
      0.009127422235906124,
      0.04085015505552292,
      0.04037371650338173,
      -0.0542115680873394,
      0.0057058376260101795,
      0.03486282005906105,
      0.0854995995759964,
      0.008833250030875206,
      -0.04700937122106552,
      -0.08450087904930115,
      -0.00992661714553833,
      0.0017023475375026464,
      -0.0955134779214859,
      0.05770999565720558,
      -0.005947164725512266,
      0.04109829664230347,
      0.03979276493191719,
      0.06640948355197906,
      0.05269010737538338,
      -0.009875928983092308,
      -0.037475068122148514,
      0.004805784206837416,
      -0.030366117134690285,
      0.0030285699758678675,
      0.036727841943502426,
      -0.028021754696965218,
      0.05620833858847618,
      0.005149065051227808,
      0.005315471440553665,
      -0.018138272687792778,
      0.05029195547103882,
      0.011768121272325516,
      -0.09142918139696121,
      -0.051135096698999405,
      -0.04606540501117706,
      0.05813448503613472,
      0.041448719799518585,
      -0.05041243135929108,
      0.006611192133277655,
      -0.08958067744970322,
      -0.06164159998297691,
      0.07062920182943344,
      -0.03845649212598801,
      -0.07279583811759949,
      0.06412442028522491,
      0.09559378027915955,
      0.007093352731317282,
      -0.044869594275951385,
      0.043580710887908936,
      -0.02118232101202011,
      -0.04847056046128273,
      0.00397849828004837,
      -0.07376179099082947,
      0.024222422391176224,
      -0.03036714531481266,
      -0.05770492926239967,
      0.04211502522230148,
      -0.02252635732293129,
      0.0303154606372118,
      0.0024083899334073067,
      0.019556164741516113,
      -0.11053571105003357,
      0.03010823018848896,
      -0.050736524164676666,
      -0.043035414069890976,
      0.02661147341132164,
      -0.0914868637919426,
      0.1042112335562706,
      0.07028495520353317,
      0.012136492878198624,
      -0.004752557259052992,
      -0.022690631449222565,
      0.025972627103328705,
      0.059117235243320465,
      -0.03422284498810768,
      -0.002107874257490039,
      0.04811079800128937,
      0.004880999214947224,
      -0.04622253403067589,
      0.07957703620195389,
      0.008252697996795177,
      -0.0235389806330204,
      -0.029683401808142662,
      -0.03805863857269287,
      0.0726013034582138,
      0.03688022866845131,
      -0.03170755133032799,
      -0.04788423329591751,
      0.011295872740447521,
      0.0398872084915638,
      0.13321435451507568,
      -0.0197380892932415,
      0.01615058444440365,
      0.0016062839422374964,
      -0.027184566482901573,
      0.009676848538219929,
      0.06871991604566574,
      0.0007210697513073683,
      0.036927253007888794,
      0.02118966355919838,
      -0.027756154537200928,
      0.0476992130279541,
      0.008737586438655853,
      -0.0005123206647112966,
      -0.019489584490656853,
      0.04714787378907204,
      0.05122485011816025,
      0.018512722104787827,
      0.004979033954441547,
      -0.03232221677899361,
      -0.019274072721600533,
      0.07847834378480911,
      -0.044219836592674255,
      0.029399840161204338,
      -0.034484006464481354,
      0.008942678570747375,
      -0.02701619081199169,
      0.0363956093788147,
      -0.032721973955631256,
      -0.021053461357951164,
      0.002221338916569948,
      -0.014064840041100979,
      0.03022073395550251,
      0.013587738387286663,
      0.02047172747552395,
      -0.06302135437726974,
      -0.010792503133416176,
      -0.00588022219017148,
      -0.023589616641402245,
      0.037014637142419815,
      -0.0250238049775362,
      0.010575367137789726,
      0.02760528028011322,
      0.09524793922901154,
      -0.048534732311964035,
      0.004127947613596916,
      -0.01952195353806019,
      0.01607881672680378,
      0.08045919984579086,
      -0.005350176710635424,
      0.0017339965561404824,
      -0.05981474742293358,
      -0.044872358441352844,
      -0.01928165927529335,
      -0.008668165653944016,
      0.005957909394055605,
      0.018579883500933647,
      0.0034044780768454075,
      0.007325775921344757,
      0.03204436972737312,
      0.035988062620162964,
      0.01385976281017065,
      0.005320706870406866,
      0.0937715470790863,
      0.09780912846326828,
      -0.04691876098513603,
      -0.013351770117878914,
      -0.08317010849714279,
      0.06832852214574814,
      -0.020644595846533775,
      0.018297960981726646,
      -0.0075641656294465065,
      0.0015206708339974284,
      -0.013077336363494396,
      0.010805527679622173,
      0.05226927995681763,
      -0.022716335952281952,
      -0.028803186491131783,
      -0.01524430699646473,
      0.04550943523645401,
      0.04176344722509384,
      0.019555864855647087,
      0.003343854798004031,
      0.016319219022989273,
      -0.019426191225647926,
      -0.012718180194497108,
      -0.06135782599449158,
      -0.05426488444209099,
      -0.06129545718431473,
      0.010752802714705467,
      -0.010093270801007748,
      -0.023052595555782318,
      0.020764397457242012,
      0.04773135483264923,
      -0.005624555982649326,
      0.06130334362387657,
      0.015884116291999817,
      -1.082272228813963e-05,
      0.033787548542022705,
      -0.04625362157821655,
      0.010514012537896633,
      0.05735030397772789,
      -0.02149888686835766,
      0.02539060451090336,
      -0.016185561195015907,
      0.006069101858884096,
      -0.002339170314371586,
      -0.036213431507349014,
      -0.0242527574300766,
      0.09098916500806808,
      0.040839992463588715,
      0.020049171522259712,
      -0.039145998656749725,
      0.056485939770936966,
      0.010865266434848309,
      -0.04483349993824959,
      0.04224797710776329,
      0.008730472065508366,
      -0.0323893241584301,
      0.028314633294939995,
      -0.0072707864455878735,
      0.0964837521314621,
      0.016454916447401047,
      -0.006406804546713829,
      -0.0017570960335433483,
      -0.06983713805675507,
      -0.0056157163344323635,
      -0.06569097191095352,
      0.050377730280160904,
      -0.016645537689328194,
      -0.016266515478491783,
      -0.004480387549847364,
      0.016742266714572906,
      -0.13694332540035248,
      0.02358190529048443,
      0.0009025384788401425,
      0.017705993726849556,
      0.07337898015975952,
      0.06443464010953903,
      -0.03055671975016594,
      0.04066508263349533,
      0.02746891975402832,
      0.019382882863283157,
      0.024891262874007225,
      0.016305556520819664,
      -0.024374280124902725,
      -0.009592344984412193,
      -0.056473128497600555,
      -0.0899418517947197,
      -0.04460914805531502,
      -0.06938406080007553
    ],
    [
      -0.029754849150776863,
      -0.04732784256339073,
      0.05436238273978233,
      -0.03063674457371235,
      -0.02102329023182392,
      0.009165441617369652,
      -0.018177932128310204,
      -0.015780922025442123,
      -0.0925351083278656,
      -0.00920744240283966,
      0.000739442533813417,
      0.029276104643940926,
      0.023020772263407707,
      -0.018540553748607635,
      -0.019459407776594162,
      0.04862343147397041,
      0.028346333652734756,
      -0.02017054706811905,
      0.019300999119877815,
      -0.03325822204351425,
      0.006129101384431124,
      0.0035914648324251175,
      -0.048891883343458176,
      0.05957143381237984,
      0.07074781507253647,
      -0.026724133640527725,
      -0.011061393655836582,
      0.09378006309270859,
      0.05910094827413559,
      -0.05055628716945648,
      0.039232246577739716,
      -0.04894928261637688,
      -0.04404937103390694,
      -0.11888236552476883,
      -0.021516820415854454,
      0.01822691038250923,
      0.03544996678829193,
      0.021091965958476067,
      0.04763882979750633,
      -0.021869011223316193,
      0.03982965648174286,
      -0.05008382722735405,
      -0.046153586357831955,
      0.03174956515431404,
      0.025105221197009087,
      0.011481410823762417,
      -0.029344232752919197,
      -0.031689539551734924,
      -0.001407500240020454,
      -0.008072756230831146,
      0.02953316457569599,
      -0.07008831202983856,
      -0.02683824673295021,
      0.05527712404727936,
      -0.10380998998880386,
      0.006096865516155958,
      0.048801615834236145,
      -0.08527214825153351,
      0.054142601788043976,
      0.08526052534580231,
      -0.08223729580640793,
      -0.01880897581577301,
      -0.07202482968568802,
      -0.053950097411870956,
      0.030705956742167473,
      -0.04111425578594208,
      -0.05277199670672417,
      -0.01827147975564003,
      -0.07301467657089233,
      -0.013699471950531006,
      0.011181204579770565,
      -0.06402629613876343,
      -0.05084473639726639,
      -0.022216912358999252,
      0.032446518540382385,
      -0.024113131687045097,
      0.06504026055335999,
      0.031759217381477356,
      -0.09973884373903275,
      0.05679946020245552,
      -0.002964602317661047,
      0.002307955641299486,
      -0.010387923568487167,
      0.037238508462905884,
      0.04664377123117447,
      -0.026650898158550262,
      -0.060016781091690063,
      0.04241187497973442,
      -0.0375002846121788,
      0.02888314239680767,
      -0.042407505214214325,
      0.047866396605968475,
      0.016738738864660263,
      0.02370120771229267,
      -0.011059092357754707,
      -0.018873613327741623,
      -0.07426609843969345,
      -0.02499895915389061,
      0.0029232653323560953,
      -0.014598122797906399,
      -0.03310302644968033,
      -0.02342127449810505,
      0.11667152494192123,
      -0.0222857054322958,
      -0.08574225753545761,
      -0.03987109661102295,
      0.015595104545354843,
      0.01712178811430931,
      0.09744928777217865,
      0.04160401225090027,
      0.024336012080311775,
      0.04137107729911804,
      -0.03159201517701149,
      0.028092384338378906,
      0.0015793191269040108,
      -0.03291523829102516,
      0.03454127162694931,
      -0.026321878656744957,
      0.05343874916434288,
      -0.0007743298774585128,
      -0.12165987491607666,
      -0.05904538929462433,
      0.027204452082514763,
      -0.01552863884717226,
      0.016894187778234482,
      -0.009909439831972122,
      -0.00965556688606739,
      -0.04157272353768349,
      0.05576836317777634,
      -0.04576586186885834,
      -0.016618309542536736,
      -0.004861466586589813,
      -0.02517562359571457,
      -0.009867805056273937,
      0.038865625858306885,
      -0.02987019345164299,
      -0.044195398688316345,
      0.00341780879534781,
      0.021412700414657593,
      0.017101285979151726,
      -0.003115316852927208,
      -0.040591515600681305,
      0.033636871725320816,
      0.014875860884785652,
      0.01357328612357378,
      -0.01917329803109169,
      -0.02705957368016243,
      -0.04228920489549637,
      -0.028710411861538887,
      -0.017838826403021812,
      0.06642252951860428,
      0.012769069522619247,
      0.013686363585293293,
      -0.05029609799385071,
      0.03997958451509476,
      -0.005698309279978275,
      0.015358268283307552,
      -0.047727491706609726,
      -0.02886740304529667,
      0.005040331277996302,
      0.08728347718715668,
      0.03914668411016464,
      0.01410568505525589,
      0.050360243767499924,
      -0.057339418679475784,
      0.011338640935719013,
      -0.039515171200037,
      -0.035124193876981735,
      -0.0018164128996431828,
      0.03936092555522919,
      -0.0146912457421422,
      0.0631803348660469,
      0.026748299598693848,
      0.08577171713113785,
      -0.017313458025455475,
      0.01682092435657978,
      -0.013604721985757351,
      -0.06410707533359528,
      -0.020916804671287537,
      -0.02325841784477234,
      -0.01788482442498207,
      0.07028573006391525,
      -0.031497880816459656,
      -0.03740459680557251,
      -0.020396141335368156,
      0.025129705667495728,
      0.07388818264007568,
      -0.005653433967381716,
      -0.038546252995729446,
      0.08627905696630478,
      -0.0040907179936766624,
      0.03153001144528389,
      0.01698809303343296,
      -0.0270244088023901,
      -0.017788274213671684,
      0.007489025127142668,
      0.012707673013210297,
      0.052067603915929794,
      -0.014990570023655891,
      -0.08054353296756744,
      -0.03853968530893326,
      0.00320701627060771,
      -0.05979890748858452,
      0.08123508840799332,
      -0.09497085958719254,
      0.039360132068395615,
      0.005889402702450752,
      0.009696302935481071,
      0.01925802417099476,
      -0.009775551036000252,
      0.027670957148075104,
      -0.08627904951572418,
      -0.11918178200721741,
      -0.01934233121573925,
      -0.01906636916100979,
      0.08666229993104935,
      0.011265291832387447,
      0.059246551245450974,
      0.02801831252872944,
      -0.01681925170123577,
      0.007439672481268644,
      -0.022152096033096313,
      0.027767738327383995,
      0.028875986114144325,
      0.021476490423083305,
      0.014890523627400398,
      -0.06662814319133759,
      0.0643305853009224,
      -0.009165297262370586,
      0.013784023933112621,
      0.02249613031744957,
      0.002467321464791894,
      -0.05369620397686958,
      -0.018961245194077492,
      -0.014568100683391094,
      0.012987261638045311,
      -0.020702039822936058,
      -0.000805407646112144,
      -0.05622139945626259,
      0.10600849241018295,
      0.028242934495210648,
      0.06421540677547455,
      0.020066415891051292,
      -0.048602622002363205,
      0.048406824469566345,
      0.07972806692123413,
      0.059374868869781494,
      0.016578389331698418,
      0.040061287581920624,
      -0.015477596782147884,
      -0.004319213330745697,
      0.055734604597091675,
      -0.037529218941926956,
      -0.0002963775768876076,
      0.020372042432427406,
      0.06002604961395264,
      -0.001876784604974091,
      -0.048456307500600815,
      -0.006019130349159241,
      0.0021262052468955517,
      -0.0460246317088604,
      0.06840234249830246,
      -0.006544425617903471,
      -0.041467372328042984,
      0.0004725415201392025,
      0.04055909812450409,
      -0.008714171126484871,
      -0.08124525845050812,
      -0.04521157965064049,
      -0.10433492809534073,
      0.0018557263538241386,
      -0.0715426653623581,
      0.060568057000637054,
      -0.014885134994983673,
      -0.022425223141908646,
      -0.002624349668622017,
      0.053973238915205,
      0.029100608080625534,
      -0.03173338621854782,
      -0.0006917424616403878,
      -0.024064064025878906,
      -0.01380997709929943,
      0.06784793734550476,
      0.08505022525787354,
      -0.03257910907268524,
      -0.07966215908527374,
      -0.10077216476202011,
      -0.013666659593582153,
      0.11299289762973785,
      0.048359066247940063,
      -0.05145691707730293,
      0.1068672463297844,
      0.022917229682207108,
      -0.016625436022877693,
      0.0317007340490818,
      -0.06660715490579605,
      0.045665182173252106,
      0.06953194737434387,
      -0.0027802009135484695,
      0.00024027214385569096,
      0.026141811162233353,
      -0.034992512315511703,
      -0.07275915145874023,
      0.057234060019254684,
      -0.045405857264995575,
      -0.11581974476575851,
      0.000908025074750185,
      -0.007858900353312492,
      0.048368267714977264,
      -0.04825343191623688,
      -0.0956917554140091,
      0.017110174521803856,
      -0.04302668571472168,
      -0.01607455685734749,
      0.05767790228128433,
      -0.012814291752874851,
      0.023651916533708572,
      -0.007488606031984091,
      -0.03270592540502548,
      -0.03633808344602585,
      0.06591877341270447,
      0.00239796144887805,
      0.05030602589249611,
      -0.01656993478536606,
      0.06600774824619293,
      0.018482787534594536,
      0.02325516752898693,
      -0.040805645287036896,
      -0.08655349165201187,
      0.022540956735610962,
      0.020598463714122772,
      -0.06796027719974518,
      -0.005322825629264116,
      0.00368537288159132,
      0.046601612120866776,
      -0.030287176370620728,
      -0.04123162850737572,
      0.02109588123857975,
      -0.07009854167699814,
      -0.06171069294214249,
      0.03148139640688896,
      -0.03468220680952072,
      -0.10379092395305634,
      0.0011708065867424011,
      0.10682683438062668,
      -0.06076517701148987,
      -0.06565161794424057,
      -0.001832235953770578,
      0.059046827256679535,
      0.060749366879463196,
      0.016290921717882156,
      0.006682249717414379,
      -0.0508352667093277,
      -0.08043546974658966,
      -0.014180563390254974,
      0.03156895935535431,
      -0.05224223807454109,
      -0.035891514271497726,
      0.03032335638999939,
      -0.019459640607237816,
      -0.00046402309089899063,
      -0.07070405781269073,
      0.007487328257411718,
      -0.02020067162811756,
      -0.012169349007308483,
      -0.029116325080394745,
      -0.007692783139646053,
      -0.003210559720173478,
      0.022516541182994843,
      -0.06028283014893532,
      0.01514343824237585,
      0.010947798378765583,
      -0.0043699671514332294,
      0.07358194142580032,
      0.033788926899433136,
      0.018527835607528687,
      0.0076728761196136475,
      -0.003269400680437684,
      0.05602100491523743,
      -0.059982217848300934,
      0.011091942898929119,
      0.017054986208677292,
      0.029685892164707184,
      0.03900103271007538,
      -0.0036275999154895544,
      0.08980946242809296,
      0.03391304984688759,
      0.04880864918231964,
      0.10894418507814407,
      -0.03668704628944397,
      -0.06661686301231384,
      0.039322949945926666,
      -0.017567522823810577,
      0.026704179123044014,
      -0.04227408766746521,
      0.09408549219369888,
      0.01304869819432497,
      -0.056582894176244736,
      0.08933272957801819,
      -0.03977133333683014,
      0.04695313423871994,
      0.07299353927373886,
      0.03109157644212246,
      0.030290264636278152,
      -0.038581185042858124,
      -0.07686726748943329,
      -0.040400344878435135,
      0.02969798818230629,
      0.05941556394100189,
      -0.09260164201259613,
      0.08491569012403488,
      -0.02423764578998089,
      -0.00814647413790226,
      -0.021502595394849777,
      -0.010172934271395206,
      0.0500979907810688,
      -0.03600098565220833,
      0.03757363557815552,
      -0.02477160096168518,
      0.01995997130870819,
      0.03608383238315582,
      0.010915126651525497,
      -0.030895566567778587,
      -0.03231176361441612,
      -0.04969393089413643,
      -0.014317421242594719,
      0.05077448487281799,
      -0.0776589885354042,
      0.023653101176023483,
      -0.04071268066763878,
      0.030361903831362724,
      -0.03947695344686508,
      0.0013989758444949985,
      -0.037767838686704636,
      -0.005576263181865215,
      -0.06663805991411209,
      -0.04293495789170265,
      -0.026677189394831657,
      0.00860447809100151,
      -0.03976787254214287,
      -0.0036698244512081146,
      0.08211992681026459,
      -0.059940747916698456,
      -0.08425810933113098,
      -0.015388188883662224,
      0.04238524287939072,
      0.01641886867582798,
      0.012468203902244568,
      -0.08232203125953674,
      -0.019527992233633995,
      0.02758432924747467,
      -0.02791789174079895,
      0.08071786910295486,
      -0.012628725729882717,
      0.029626909643411636,
      0.06540913879871368,
      0.01140003465116024,
      -0.056471966207027435,
      -0.004847750999033451,
      -0.004881921224296093,
      -0.03652133047580719,
      0.08149457722902298,
      -0.0011731438571587205,
      -0.0305183082818985,
      0.020973894745111465,
      0.04091284051537514,
      -0.03247617185115814,
      0.011820354498922825,
      -0.07505035400390625,
      0.04202701151371002,
      0.04585924372076988,
      -0.09364710003137589,
      0.044912803918123245,
      -0.005806483794003725,
      0.004671360366046429,
      -0.024093296378850937,
      0.03732975199818611,
      -0.016478510573506355,
      -0.07075382024049759,
      0.01878087781369686,
      0.010574455372989178,
      -0.032566189765930176,
      0.029732678085565567,
      0.06278105825185776,
      -0.032201528549194336,
      0.0026498872321099043,
      0.017308413982391357,
      0.000920231337659061,
      0.0004116055788472295,
      -0.026324549689888954,
      -0.00499570881947875,
      0.044448383152484894,
      0.012225547805428505,
      -0.0034108301624655724,
      0.05245479568839073,
      0.05035973712801933,
      0.0001023352742777206,
      -0.020164823159575462,
      0.03417808562517166,
      -0.05832293629646301,
      -0.034955136477947235,
      0.02011987380683422,
      -0.03239075094461441
    ],
    [
      -0.007492346689105034,
      0.020782478153705597,
      -0.015622028149664402,
      -0.06997077167034149,
      0.1199408546090126,
      0.02386663481593132,
      -0.025462603196501732,
      0.019684311002492905,
      -0.15017251670360565,
      -0.03150776028633118,
      0.019614234566688538,
      0.0153636010363698,
      -0.05559937283396721,
      0.014984721317887306,
      0.03834690526127815,
      -0.052230507135391235,
      -0.01786305010318756,
      -0.04297621548175812,
      0.014200447127223015,
      0.011986455880105495,
      -0.05011305585503578,
      -0.08572759479284286,
      -0.002500726841390133,
      0.03409861773252487,
      0.08622035384178162,
      -0.026067117229104042,
      0.028930384665727615,
      -0.04433470219373703,
      -0.014904413372278214,
      0.043261319398880005,
      0.009069196879863739,
      -0.05581122264266014,
      -0.058412179350852966,
      -0.08610318601131439,
      0.0692235678434372,
      0.054259832948446274,
      -0.002635031705722213,
      0.05635417625308037,
      -0.011097067967057228,
      -0.0039040097035467625,
      0.00875544361770153,
      -0.04887569323182106,
      0.0218117143958807,
      0.02009374275803566,
      -0.04053010046482086,
      0.014148116111755371,
      0.030069679021835327,
      -0.10328985750675201,
      0.024859141558408737,
      0.05714540556073189,
      -0.07144495844841003,
      -0.015290535055100918,
      0.01485944539308548,
      0.025681059807538986,
      -0.013617543503642082,
      0.07518057525157928,
      0.061994802206754684,
      0.04976747930049896,
      -0.00033006069133989513,
      -0.002151988446712494,
      0.002362995408475399,
      -0.04556936025619507,
      -0.06089276820421219,
      0.04281981289386749,
      0.0055595217272639275,
      0.027955325320363045,
      0.025250909850001335,
      0.012817402370274067,
      -0.10897821187973022,
      0.0146807124838233,
      0.0495922677218914,
      0.01971716806292534,
      0.017964927479624748,
      -0.07630927115678787,
      0.05050690472126007,
      0.013124018907546997,
      -0.03638873249292374,
      0.09344001859426498,
      0.019976725801825523,
      -0.029898080974817276,
      -0.015005144290626049,
      0.01732940413057804,
      0.06026534363627434,
      -0.053507883101701736,
      0.06565845012664795,
      0.05170252174139023,
      -0.13885775208473206,
      -0.012042025104165077,
      0.016146698966622353,
      -0.04554769769310951,
      0.09228921681642532,
      -0.04265512898564339,
      -0.03271609544754028,
      -0.014770230278372765,
      0.003914759028702974,
      -0.020499680191278458,
      -0.06835468113422394,
      0.016767608001828194,
      -0.0006381832645274699,
      0.07817504554986954,
      0.0032707720529288054,
      0.05447732284665108,
      0.036038756370544434,
      -0.038143157958984375,
      -0.012027788907289505,
      0.027502745389938354,
      0.006264392752200365,
      0.018161365762352943,
      0.06730076670646667,
      0.011957603506743908,
      -0.010783422738313675,
      -0.02151789329946041,
      0.020962033420801163,
      -0.00045414885971695185,
      0.05214955285191536,
      0.0025475206784904003,
      0.0030939283315092325,
      -0.07821392267942429,
      0.0031177077908068895,
      -0.001863116049207747,
      -0.14275643229484558,
      -0.0028744442388415337,
      -0.07522417604923248,
      0.02402695268392563,
      0.04863663762807846,
      0.01529794279485941,
      0.08549148589372635,
      -0.0018856707029044628,
      0.06338778883218765,
      0.09502631425857544,
      -0.07867458462715149,
      0.035109877586364746,
      0.0593072846531868,
      -0.024197682738304138,
      0.016511401161551476,
      0.04676932096481323,
      -0.05325904116034508,
      -0.02504926174879074,
      -0.08166982233524323,
      0.016970189288258553,
      0.06348501890897751,
      -0.024058545008301735,
      -0.028409352526068687,
      -0.016900938004255295,
      0.0013809757074341178,
      -0.01741492561995983,
      0.05746221914887428,
      0.014116941951215267,
      -0.03294206038117409,
      -0.05081300809979439,
      0.014097299426794052,
      -0.025465980172157288,
      0.040360718965530396,
      0.021217336878180504,
      -0.03934343531727791,
      -0.06673797219991684,
      -0.008581191301345825,
      0.047876957803964615,
      0.07377008348703384,
      -0.02063007280230522,
      -0.09455804526805878,
      -0.008321942761540413,
      -0.010520360432565212,
      -0.05241725966334343,
      -0.020856328308582306,
      -0.09419943392276764,
      -0.014031977392733097,
      -0.015845689922571182,
      0.042111802846193314,
      -0.009977166540920734,
      0.024472910910844803,
      -0.16025245189666748,
      0.033695608377456665,
      0.06204063072800636,
      0.06257272511720657,
      -0.08473801612854004,
      0.01584441214799881,
      -0.024224992841482162,
      -0.042377106845378876,
      0.0030604361090809107,
      0.01811010017991066,
      -0.016687285155057907,
      0.015864398330450058,
      -0.009718558751046658,
      -0.0703175961971283,
      0.06147454306483269,
      -0.057888031005859375,
      0.016979703679680824,
      0.005441074259579182,
      0.05194852873682976,
      0.022113364189863205,
      0.07861224561929703,
      0.007123647257685661,
      0.007880765944719315,
      -0.02387995459139347,
      -0.04939596354961395,
      -0.0407998152077198,
      0.012129176408052444,
      0.0030868600588291883,
      -0.021800780668854713,
      -0.0073267510160803795,
      0.05494464561343193,
      0.025459937751293182,
      -0.00811886228621006,
      0.03220037370920181,
      -0.01187635026872158,
      0.049817878752946854,
      -0.01335475966334343,
      -0.02998633310198784,
      -0.018508534878492355,
      -0.06471925228834152,
      0.10254909843206406,
      -0.018541883677244186,
      0.007601636461913586,
      -0.0037378461565822363,
      0.01882162317633629,
      -0.04476315155625343,
      -0.05188390612602234,
      0.04930645972490311,
      -0.03098229505121708,
      0.025771992281079292,
      0.014548019506037235,
      0.015601890161633492,
      0.04518571123480797,
      0.010340169072151184,
      0.06856182962656021,
      -0.01115336548537016,
      0.03827996551990509,
      0.033592287451028824,
      -0.01842990145087242,
      -0.11459365487098694,
      0.029548821970820427,
      -0.04075169935822487,
      -0.037651095539331436,
      -0.01985509693622589,
      -0.06603209674358368,
      0.017646221444010735,
      0.07104881852865219,
      -0.014144731685519218,
      -0.05833544582128525,
      -0.08561135828495026,
      -0.003538708668202162,
      -0.04050157591700554,
      0.024913709610700607,
      0.01162781473249197,
      -0.050500884652137756,
      -0.06256803125143051,
      0.05276539549231529,
      -0.006496402435004711,
      -0.0032072386238723993,
      -0.00703950971364975,
      0.04675228148698807,
      0.032529376447200775,
      -0.01990302838385105,
      0.07868857681751251,
      0.0073706405237317085,
      0.02193377912044525,
      0.03620944917201996,
      -0.045503806322813034,
      -0.045436080545186996,
      -0.05888380482792854,
      -0.0520801804959774,
      0.0214224923402071,
      0.015709219500422478,
      -0.061667297035455704,
      -0.07317482680082321,
      -0.02001328393816948,
      -0.09743768721818924,
      -0.014581947587430477,
      0.02954389527440071,
      0.0731586292386055,
      -0.06914721429347992,
      0.056272052228450775,
      0.03501061350107193,
      -0.01854753866791725,
      -0.024747520685195923,
      -0.013539394363760948,
      -0.03518601506948471,
      0.016427068039774895,
      0.004660434555262327,
      -0.01607433892786503,
      -0.00689969165250659,
      -0.12103670835494995,
      0.005567312240600586,
      -0.1197311133146286,
      0.05271925404667854,
      0.06729050725698471,
      -0.05907993018627167,
      -0.009453203529119492,
      0.03958696499466896,
      0.03314763680100441,
      0.02500971406698227,
      -0.010979033075273037,
      0.040520407259464264,
      -0.009780359454452991,
      -0.03746642917394638,
      -0.04823726788163185,
      0.07300013303756714,
      0.0032250075601041317,
      -0.022045407444238663,
      -0.04544942453503609,
      -0.04470696672797203,
      -0.017606602981686592,
      0.13686291873455048,
      0.04085775837302208,
      0.024867819622159004,
      -0.03395188972353935,
      -0.053898077458143234,
      -0.06570877879858017,
      0.023593666031956673,
      -0.015224172733724117,
      -0.043361835181713104,
      0.003766091074794531,
      0.016518300399184227,
      -0.04492352157831192,
      -0.07072770595550537,
      0.0009004535968415439,
      0.049697063863277435,
      0.05274992063641548,
      -0.041197650134563446,
      -0.00813213735818863,
      -0.00054452836047858,
      0.05206948146224022,
      -0.009333882480859756,
      0.03490660339593887,
      -0.04261967912316322,
      0.05573655292391777,
      0.01955348439514637,
      -0.02704957127571106,
      0.03182585537433624,
      0.04308014363050461,
      -0.05450405925512314,
      0.002286948962137103,
      0.0005701315240003169,
      -0.053434599190950394,
      0.058103159070014954,
      -0.018210120499134064,
      0.05812079831957817,
      -0.03526139259338379,
      -0.06316441297531128,
      0.026712831109762192,
      0.03592872619628906,
      0.0354619063436985,
      -0.024451380595564842,
      -0.09289243072271347,
      0.02456199936568737,
      0.06690067052841187,
      -0.010290540754795074,
      0.03713352233171463,
      0.025324810296297073,
      0.040259022265672684,
      -0.004384132102131844,
      -0.0038037800695747137,
      -0.10672642290592194,
      -0.06580986082553864,
      -0.005814710631966591,
      0.046974632889032364,
      -0.021335795521736145,
      -0.060805123299360275,
      -0.08345863968133926,
      0.07413063198328018,
      -0.033557455986738205,
      0.07411807775497437,
      0.026019785553216934,
      -0.05736561119556427,
      -0.008173779584467411,
      -0.038322675973176956,
      -0.0873722955584526,
      0.030030889436602592,
      0.06979530304670334,
      0.013605830259621143,
      -0.013246862217783928,
      -0.025200240314006805,
      0.05228278040885925,
      -0.024105267599225044,
      0.09677566587924957,
      0.012854054570198059,
      -0.005511881317943335,
      0.002764415694400668,
      -0.0044486611150205135,
      0.016071541234850883,
      0.018140355125069618,
      0.03700483962893486,
      0.03239684924483299,
      0.01149247121065855,
      -0.003383685601875186,
      -0.05565716698765755,
      -0.012596342712640762,
      0.024865789338946342,
      -0.019823716953396797,
      0.034160394221544266,
      0.057175155729055405,
      0.11056903749704361,
      -0.025846276432275772,
      -0.05394502356648445,
      -0.04323790594935417,
      0.05327986180782318,
      -0.07218177616596222,
      0.04871208220720291,
      0.031109917908906937,
      0.06457578390836716,
      0.007358545903116465,
      0.09437214583158493,
      -0.10657300800085068,
      -0.05146578326821327,
      -0.048163384199142456,
      -0.004177901893854141,
      0.016825642436742783,
      0.07008837163448334,
      0.03729847073554993,
      -0.05633895844221115,
      -0.109181709587574,
      -0.09383657574653625,
      0.0005053525674156845,
      0.11905943602323532,
      0.026180341839790344,
      -0.007132732775062323,
      0.004998502321541309,
      -0.0915076807141304,
      -0.022260159254074097,
      -0.015869906172156334,
      0.0629638209939003,
      -0.009013031609356403,
      0.0017893085023388267,
      -0.0289104375988245,
      0.0312831737101078,
      0.06964784860610962,
      -0.07043428719043732,
      -0.027703907340765,
      -0.040092065930366516,
      0.062007855623960495,
      0.0435519739985466,
      0.004817632492631674,
      0.0034323211293667555,
      0.03783566877245903,
      -0.019933130592107773,
      -0.05059252306818962,
      -0.023931127041578293,
      0.035044871270656586,
      -0.08363193273544312,
      -0.004450447857379913,
      0.015933005139231682,
      -0.027782021090388298,
      0.02119692787528038,
      0.024663124233484268,
      0.03321512043476105,
      -0.050369225442409515,
      0.08154171705245972,
      -0.03577597439289093,
      -0.0065854922868311405,
      -0.030871381983160973,
      0.06470486521720886,
      0.014889431186020374,
      0.019339030608534813,
      0.06426315009593964,
      0.03886956349015236,
      -0.006143511272966862,
      -0.035311441868543625,
      0.02190057374536991,
      0.04419809207320213,
      0.06265915185213089,
      -0.008017721585929394,
      0.01239396259188652,
      0.03918367251753807,
      0.08123604208230972,
      0.015859266743063927,
      0.0289579126983881,
      0.027234070003032684,
      -0.030585790053009987,
      0.027240443974733353,
      0.022271042689681053,
      -0.0007404269999824464,
      -0.05266932398080826,
      0.02650497294962406,
      0.0011124145239591599,
      0.02107440121471882,
      -0.05953842028975487,
      0.015871550887823105,
      0.04019817337393761,
      0.07866550981998444,
      0.08078975975513458,
      -0.035302694886922836,
      -0.03463953360915184,
      0.08051155507564545,
      0.09168411046266556,
      0.0514850914478302,
      0.05444691702723503,
      -0.03416334092617035,
      0.01672230288386345,
      0.06910098344087601,
      0.008172883652150631,
      -0.014031529426574707,
      -0.015072725713253021,
      -0.025315020233392715,
      -0.019155412912368774,
      0.009804497472941875,
      -0.03128734230995178,
      -0.10995981842279434,
      -0.10895214974880219,
      -0.02246546745300293,
      0.04488310217857361,
      0.011836965568363667,
      0.0023158295080065727
    ],
    [
      0.006083939224481583,
      -0.03613793104887009,
      0.00833083689212799,
      0.013944756239652634,
      0.08855072408914566,
      -0.07035147398710251,
      0.012208690866827965,
      -0.013285578228533268,
      0.012397799640893936,
      -0.01819574274122715,
      0.047752805054187775,
      0.08354548364877701,
      -0.03584509715437889,
      0.012397575192153454,
      0.11686458438634872,
      0.04657166078686714,
      0.010125148110091686,
      0.07903113216161728,
      0.02512397989630699,
      -0.015413003042340279,
      0.0577072948217392,
      0.01621580310165882,
      -0.0009524070774205029,
      0.06978640705347061,
      0.11899558454751968,
      0.0258500836789608,
      0.028526674956083298,
      -0.04521195963025093,
      -0.03388703614473343,
      0.024582505226135254,
      -0.0019052777206525207,
      0.02966497093439102,
      -0.015485715121030807,
      0.007822304032742977,
      -0.00979578960686922,
      -0.02929779142141342,
      -0.023394782096147537,
      0.053487151861190796,
      -0.014875656925141811,
      -0.014994487166404724,
      -0.024209098890423775,
      0.03564513102173805,
      0.09569337964057922,
      0.0630817711353302,
      -0.02596820332109928,
      0.03750254586338997,
      0.04038301110267639,
      -0.010440058074891567,
      0.0574294775724411,
      0.0020059209782630205,
      -0.10712170600891113,
      -0.017604462802410126,
      0.018315011635422707,
      0.021816661581397057,
      0.04580778628587723,
      0.016047177836298943,
      0.07039571553468704,
      0.030785636976361275,
      -0.003298460738733411,
      -0.003876766422763467,
      0.024391792714595795,
      0.067208431661129,
      0.017862757667899132,
      0.025463929399847984,
      -0.06576967239379883,
      0.08546243607997894,
      -0.001972366590052843,
      -0.03098829835653305,
      -0.07514602690935135,
      0.01315853651612997,
      -0.003594893030822277,
      -0.0290608499199152,
      0.0184785183519125,
      -0.00010286054748576134,
      -0.029710572212934494,
      0.022626137360930443,
      0.010196169838309288,
      0.00010044760711025447,
      -0.07146652787923813,
      0.03702133893966675,
      0.1118839830160141,
      -0.07167129218578339,
      0.04349678382277489,
      -0.022200262174010277,
      -0.03660433366894722,
      0.007640778552740812,
      -0.04399019107222557,
      -0.02598675712943077,
      -0.03069128841161728,
      -0.043383922427892685,
      -0.014885352924466133,
      0.050157271325588226,
      0.047096431255340576,
      -0.015198642387986183,
      -0.009113418869674206,
      0.006390903145074844,
      -0.08343338966369629,
      -0.02317594550549984,
      -0.002754640532657504,
      -0.05981862172484398,
      -0.02306556887924671,
      0.01980707421898842,
      0.019329970702528954,
      -0.03661370649933815,
      0.0167913269251585,
      -0.08645384758710861,
      -0.06881844997406006,
      -0.032293900847435,
      -0.0484711118042469,
      0.020564677193760872,
      0.028366776183247566,
      0.02943999506533146,
      0.026036709547042847,
      -0.026717668399214745,
      -0.00874996930360794,
      -0.10971681773662567,
      0.058565810322761536,
      -0.07689090073108673,
      -0.005719850771129131,
      -0.013313923962414265,
      -0.11226260662078857,
      -0.09694850444793701,
      -0.04928407818078995,
      0.011122269555926323,
      0.02845640666782856,
      -0.010262539610266685,
      -0.049381669610738754,
      -0.03311096131801605,
      -0.006284317001700401,
      -0.015349072404205799,
      -0.06424253433942795,
      0.032932259142398834,
      -0.09344689548015594,
      0.030380772426724434,
      -0.02582016959786415,
      -0.04769836738705635,
      -0.01409847941249609,
      0.060626912862062454,
      -0.004365991335362196,
      -0.011103452183306217,
      -0.049703434109687805,
      -0.08116890490055084,
      -0.0029027776326984167,
      0.053068723529577255,
      0.028370985761284828,
      0.0063979667611420155,
      0.01000604685395956,
      0.0023055300116539,
      -0.017447855323553085,
      -0.007782581262290478,
      -0.0650351494550705,
      0.011582348495721817,
      0.05914049595594406,
      0.07477723807096481,
      0.03934735059738159,
      -0.0633285641670227,
      -0.016358958557248116,
      0.02872130088508129,
      0.06483211368322372,
      0.016771625727415085,
      -0.03473642095923424,
      0.010243499651551247,
      -0.03126752749085426,
      0.036000192165374756,
      0.0174561720341444,
      -0.08695979416370392,
      0.0023996992968022823,
      -0.022901060059666634,
      0.06345459073781967,
      -0.008933818899095058,
      0.0005900694523006678,
      0.02582458406686783,
      -0.016714278608560562,
      0.026703672483563423,
      0.002353191841393709,
      0.0403965599834919,
      -0.015737855806946754,
      -0.0487191379070282,
      0.044749874621629715,
      -0.07691635191440582,
      0.03527143597602844,
      0.013105830177664757,
      0.018379393965005875,
      0.009503900073468685,
      0.02422913908958435,
      -0.005692527629435062,
      -0.0367058627307415,
      -0.0615534633398056,
      -0.01727021485567093,
      -0.007782415021210909,
      0.04153430089354515,
      -0.08027635514736176,
      -0.011179404333233833,
      -0.03689541295170784,
      0.0893198549747467,
      -0.0082978755235672,
      -0.006164290476590395,
      -0.04830634221434593,
      0.056426484137773514,
      -0.02884186990559101,
      -0.07870548963546753,
      -0.10931114107370377,
      0.025537386536598206,
      0.015206213109195232,
      0.001389812328852713,
      -0.05604857578873634,
      -0.014738786034286022,
      0.04063631594181061,
      0.005317063070833683,
      -0.021062908694148064,
      0.01875411719083786,
      -0.011153889819979668,
      -0.13498198986053467,
      -0.04080812633037567,
      -0.051632944494485855,
      0.03045869991183281,
      -0.06042523309588432,
      0.03209446370601654,
      0.04874563217163086,
      -0.06139345467090607,
      -0.06812994182109833,
      -0.022015461698174477,
      -0.03126206621527672,
      -0.10115213692188263,
      -0.06691554933786392,
      -0.027720313519239426,
      -0.04450015723705292,
      0.022807924076914787,
      0.05054367333650589,
      0.031008051708340645,
      0.010653344914317131,
      0.11624228209257126,
      -0.12420142441987991,
      -0.06578275561332703,
      0.05007101595401764,
      -0.008404613472521305,
      -0.06073325499892235,
      0.024245956912636757,
      -0.05071152001619339,
      0.051859211176633835,
      0.027726486325263977,
      -0.0576934777200222,
      0.0626322329044342,
      -0.0030141426250338554,
      0.011806890368461609,
      0.03498261049389839,
      0.024989375844597816,
      -0.015155267901718616,
      0.013954930938780308,
      -0.0015191228594630957,
      -0.07252772152423859,
      -0.05412799119949341,
      0.024954840540885925,
      -0.05801565572619438,
      0.03705057501792908,
      -0.030551107600331306,
      0.008443474769592285,
      -0.006135571748018265,
      -0.058223553001880646,
      -0.006658891681581736,
      0.005130994599312544,
      0.018302878364920616,
      -0.046063508838415146,
      -0.07010810822248459,
      0.06479530781507492,
      0.0071015204302966595,
      0.014248370192945004,
      -0.03147284314036369,
      -0.07703811675310135,
      0.0034247974399477243,
      -0.015666959807276726,
      -0.025200610980391502,
      -0.030746882781386375,
      0.019845034927129745,
      -0.06848621368408203,
      0.0005577037809416652,
      0.01093307975679636,
      -0.01152806356549263,
      -0.03746670112013817,
      0.032172031700611115,
      0.065130315721035,
      -0.00910278595983982,
      -0.08676191419363022,
      -0.009474013932049274,
      -0.023533895611763,
      -0.012577877379953861,
      0.022623077034950256,
      -0.07474537193775177,
      0.022379426285624504,
      -0.022237645462155342,
      0.0289971474558115,
      -0.00598103366792202,
      0.004649348556995392,
      -0.017108546569943428,
      0.0007173119229264557,
      0.0016714370576664805,
      0.019145498052239418,
      0.049148838967084885,
      0.012142344377934933,
      -0.0668829008936882,
      0.01668379455804825,
      0.0327669121325016,
      0.02999030239880085,
      0.11122293770313263,
      -0.04252230376005173,
      0.03240793198347092,
      -0.021470963954925537,
      -0.07134418934583664,
      0.14260230958461761,
      0.10178004205226898,
      -0.022880153730511665,
      0.03014335408806801,
      0.015136266127228737,
      0.020724410191178322,
      -0.0818667858839035,
      -0.005007975269109011,
      0.019672749564051628,
      0.07529854029417038,
      -0.04140564799308777,
      0.020184826105833054,
      0.06726348400115967,
      0.012613046914339066,
      0.050112903118133545,
      -0.07602906227111816,
      -0.027979522943496704,
      0.13007806241512299,
      -2.1834946892340668e-05,
      0.03819989413022995,
      -0.010147024877369404,
      0.07885733246803284,
      -0.056548841297626495,
      0.01589352637529373,
      -0.04392578825354576,
      0.013051695190370083,
      -0.028634371235966682,
      -0.02336101606488228,
      0.003295498201623559,
      0.010284200310707092,
      -0.04383309558033943,
      0.041575100272893906,
      0.022740179672837257,
      0.0020187462214380503,
      -0.011856659315526485,
      -0.005836439318954945,
      0.028509823605418205,
      -0.029136663302779198,
      -0.012212107889354229,
      0.015743300318717957,
      0.022269008681178093,
      -0.0027027565520256758,
      0.06389427930116653,
      -0.00046168611152097583,
      -0.04178015887737274,
      -0.07366983592510223,
      0.022552480921149254,
      -0.16007259488105774,
      -0.03425811603665352,
      0.09495313465595245,
      0.027567937970161438,
      -0.04131694883108139,
      0.05656593665480614,
      -0.025720305740833282,
      -0.0339304618537426,
      -0.04013408347964287,
      -0.019018277525901794,
      0.054209593683481216,
      0.024553049355745316,
      0.02180897444486618,
      -0.09348603338003159,
      -0.008111660368740559,
      0.033425506204366684,
      0.033173125237226486,
      -0.008244166150689125,
      5.737482933909632e-05,
      0.030678505077958107,
      0.037686366587877274,
      -0.0681268647313118,
      0.04069343954324722,
      0.06031741201877594,
      -0.008831893093883991,
      -0.08668321371078491,
      -0.04876288026571274,
      -0.000930373789742589,
      0.10020138323307037,
      -0.004101566970348358,
      0.05009621009230614,
      -0.033057551831007004,
      0.11062350869178772,
      0.019386375322937965,
      0.03515101969242096,
      0.005596387665718794,
      0.04203537106513977,
      0.0612616240978241,
      0.03121677041053772,
      0.017462056130170822,
      -0.11329936981201172,
      0.015389609150588512,
      -0.07184682786464691,
      0.026598995551466942,
      0.06983990222215652,
      0.09826426953077316,
      0.052319761365652084,
      0.07503461092710495,
      0.017197636887431145,
      0.012797599658370018,
      -0.08078377693891525,
      0.0488203801214695,
      0.028930330649018288,
      0.06586689502000809,
      0.06563210487365723,
      0.018089810386300087,
      -0.0723986104130745,
      -0.017622780054807663,
      -0.02744690515100956,
      -0.07817871868610382,
      0.008160429075360298,
      -0.032488562166690826,
      -0.05609313026070595,
      0.08413776010274887,
      -0.06688247621059418,
      0.054098501801490784,
      0.017064113169908524,
      0.02881106175482273,
      0.033418431878089905,
      0.023349933326244354,
      -0.00195127387996763,
      0.0867728665471077,
      -0.12182928621768951,
      0.013618182390928268,
      0.007991811260581017,
      0.02887454442679882,
      0.03139683976769447,
      -0.021236378699541092,
      -0.03928710147738457,
      -0.03177260234951973,
      -0.05435536056756973,
      0.053081661462783813,
      -0.01593613252043724,
      0.11607548594474792,
      -0.01605302281677723,
      0.0024649964179843664,
      0.013066395185887814,
      0.031994134187698364,
      0.09919048845767975,
      -0.020987361669540405,
      -0.01771898753941059,
      -0.07954864203929901,
      -0.05279453471302986,
      0.01786280795931816,
      0.034979239106178284,
      0.022096144035458565,
      0.0036080589052289724,
      0.03329028934240341,
      -0.046158187091350555,
      0.011416777037084103,
      -0.06370300054550171,
      -0.04120713844895363,
      -0.031862035393714905,
      -0.04293513670563698,
      -0.017784133553504944,
      -0.008446809835731983,
      0.05752779170870781,
      0.026723861694335938,
      0.0025576860643923283,
      0.025385355576872826,
      -0.014558820053935051,
      -0.036814771592617035,
      -0.014951199293136597,
      0.04736695438623428,
      -0.044115278869867325,
      0.005302125122398138,
      0.0058719986118376255,
      0.03134210780262947,
      -0.01685253158211708,
      -0.015309187583625317,
      -0.09022024273872375,
      0.056264229118824005,
      -0.03323341906070709,
      0.032764922827482224,
      0.06715672463178635,
      0.006379557307809591,
      0.026078319177031517,
      -0.009204251691699028,
      -0.08289726078510284,
      -0.01082648430019617,
      0.04628440737724304,
      -0.09309763461351395,
      -0.026812508702278137,
      0.044865842908620834,
      -0.027093740180134773,
      0.024200808256864548,
      -0.05390549451112747,
      0.027760012075304985,
      0.0863957405090332,
      -0.05847281217575073,
      0.05098767951130867,
      0.0025128244888037443,
      -0.06176227703690529,
      0.07717021554708481,
      0.009399429894983768,
      -0.029534295201301575,
      -0.006823905743658543,
      -0.03307883068919182
    ],
    [
      -0.050700102001428604,
      0.0451972633600235,
      0.06454208493232727,
      -0.022459570318460464,
      -0.005128246732056141,
      0.07160791754722595,
      0.06466580182313919,
      0.025799298658967018,
      -0.04299468547105789,
      -0.06905440241098404,
      0.02846589684486389,
      0.06006792560219765,
      0.010751068592071533,
      -0.03252628073096275,
      0.028723128139972687,
      -0.006312756333500147,
      -0.09263211488723755,
      -0.06780495494604111,
      -0.06396712362766266,
      -0.011358939111232758,
      -0.03230777010321617,
      0.012950977310538292,
      0.012652343139052391,
      0.021832015365362167,
      0.0806800127029419,
      0.02957686223089695,
      -0.02338886633515358,
      -0.06552754342556,
      0.0251486636698246,
      -0.024448895826935768,
      0.09123896062374115,
      0.07459571957588196,
      0.025393931195139885,
      0.0005927007296122611,
      -0.03310847282409668,
      -0.05858686938881874,
      -0.09040696918964386,
      0.11245954036712646,
      0.04817373678088188,
      -0.006458438467234373,
      -0.0553542822599411,
      -0.018204625695943832,
      0.06517118960618973,
      0.030399616807699203,
      -0.031154107302427292,
      0.032047949731349945,
      0.024999337270855904,
      0.00135769322514534,
      0.1170477345585823,
      -0.03218524530529976,
      0.043443288654088974,
      0.03802629932761192,
      -0.03357933834195137,
      0.050561606884002686,
      -0.03956101834774017,
      0.02974826842546463,
      0.027095142751932144,
      -0.03287527710199356,
      0.022058241069316864,
      0.007628378923982382,
      0.030306586995720863,
      -0.013833367265760899,
      -0.047853242605924606,
      0.0796997919678688,
      -0.027554426342248917,
      -0.0016295183449983597,
      0.00596659118309617,
      -0.07957807183265686,
      -0.010527588427066803,
      -0.030304286628961563,
      0.04641016200184822,
      0.000635817414149642,
      -0.017983440309762955,
      0.01424429938197136,
      -0.0070038847625255585,
      -0.043472036719322205,
      -0.005104124080389738,
      0.05772076174616814,
      0.00838044285774231,
      0.02607128396630287,
      0.09171749651432037,
      -0.005342075601220131,
      0.015536368824541569,
      -0.027196060866117477,
      -0.09227223694324493,
      0.06570545583963394,
      -0.1494361162185669,
      0.003962285816669464,
      0.032046470791101456,
      0.01651907153427601,
      0.025303417816758156,
      -0.03344405069947243,
      0.029503539204597473,
      -0.0027780202217400074,
      -0.0743827149271965,
      -0.05651726573705673,
      0.060987744480371475,
      0.0010924178641289473,
      0.05701642483472824,
      -0.005722342524677515,
      -0.08341512829065323,
      0.06715680658817291,
      -0.027111053466796875,
      -0.06867445260286331,
      -0.11205381155014038,
      -0.0284710843116045,
      -0.025486061349511147,
      0.024745067581534386,
      0.07129000872373581,
      -0.028082428500056267,
      0.04865836724638939,
      0.05826103687286377,
      -0.0577768012881279,
      -0.058073271065950394,
      -0.013180726207792759,
      0.007264002691954374,
      -0.04048304259777069,
      -0.04788839444518089,
      0.06210508197546005,
      0.04684728384017944,
      -0.06378001719713211,
      -0.01802035979926586,
      -0.026420069858431816,
      -0.015536178834736347,
      0.010650000534951687,
      0.07480728626251221,
      -0.06531863659620285,
      0.015946300700306892,
      0.06500636041164398,
      -0.04190574586391449,
      -0.03266645222902298,
      0.01303598377853632,
      -0.0012186130043119192,
      0.0017803662922233343,
      0.06930766254663467,
      0.04222710430622101,
      0.03199402242898941,
      -0.029396291822195053,
      -0.06577152013778687,
      -0.008355783298611641,
      -0.02969840168952942,
      -0.01756712980568409,
      0.02144925482571125,
      -0.01562521420419216,
      0.08858459442853928,
      0.004091383423656225,
      -0.05505867674946785,
      -0.016722766682505608,
      -0.0062886932864785194,
      -0.024791186675429344,
      0.059996020048856735,
      0.05018887668848038,
      -0.08801829814910889,
      -0.01835518702864647,
      0.05067261680960655,
      -0.03325147181749344,
      0.07858877629041672,
      -0.05291358754038811,
      0.03968676179647446,
      -0.015176531858742237,
      -0.0820583775639534,
      0.013126246631145477,
      0.0031364024616777897,
      0.12205271422863007,
      0.11449027806520462,
      -0.002782405586913228,
      0.008592669852077961,
      0.025678418576717377,
      0.01142355427145958,
      -0.015705259516835213,
      0.10008276253938675,
      -0.058621522039175034,
      0.05512707680463791,
      0.00827017705887556,
      -0.014557568356394768,
      -0.024686602875590324,
      0.010727079585194588,
      -0.0367610827088356,
      0.023960035294294357,
      -0.07051263004541397,
      -0.03961830958724022,
      0.06345293670892715,
      -0.018739720806479454,
      -0.051867108792066574,
      -0.02770494669675827,
      -0.026058100163936615,
      -0.07375018298625946,
      0.03554733097553253,
      -0.010288412682712078,
      0.047332171350717545,
      -0.009570914320647717,
      -0.06876116245985031,
      -0.06171628460288048,
      -0.08201257884502411,
      -0.017084447667002678,
      0.07150161266326904,
      0.03505725786089897,
      -0.038713641464710236,
      0.009811113588511944,
      0.04053041711449623,
      0.03158681467175484,
      0.007490369025617838,
      -0.0027464209124445915,
      -0.013957850635051727,
      -0.018986117094755173,
      0.0407065749168396,
      0.02498176135122776,
      0.009430701844394207,
      0.025644008070230484,
      -0.1165638342499733,
      -0.025814643129706383,
      -0.008741788566112518,
      -0.03976067155599594,
      0.026713361963629723,
      -0.053762078285217285,
      0.05591394007205963,
      0.05253015086054802,
      -0.044843778014183044,
      -0.01109976228326559,
      -0.005519329570233822,
      0.024382732808589935,
      -0.0392606295645237,
      0.032946642488241196,
      0.015310054644942284,
      0.05052841827273369,
      -0.0282733291387558,
      0.07272645831108093,
      0.04110803082585335,
      0.056264255195856094,
      0.012935380451381207,
      0.00536053441464901,
      -0.029026489704847336,
      -0.06876979023218155,
      -0.07003403455018997,
      -0.022255320101976395,
      0.06329403072595596,
      0.03891285881400108,
      0.04079806059598923,
      0.01254323124885559,
      0.02181745506823063,
      0.012669871561229229,
      -0.08146683871746063,
      -0.07782286405563354,
      -0.0014881693059578538,
      0.0015416009118780494,
      -0.004597162827849388,
      0.024559900164604187,
      -0.073944590985775,
      0.021023482084274292,
      0.09256777167320251,
      -0.013690272346138954,
      -0.026803158223628998,
      -0.0468379482626915,
      -0.022705717012286186,
      0.030135784298181534,
      0.043893638998270035,
      0.11421593278646469,
      0.05851801112294197,
      -0.02974468283355236,
      0.0521652027964592,
      -0.003279116004705429,
      -0.04863971099257469,
      0.059736914932727814,
      -0.03223147615790367,
      0.014425002038478851,
      -0.09606540203094482,
      -0.05158235505223274,
      0.0034899890888482332,
      -0.016231093555688858,
      -0.054374340921640396,
      0.04705514386296272,
      -0.04326664283871651,
      -0.013601734302937984,
      -0.005480807274580002,
      -0.02042676880955696,
      -0.029930230230093002,
      0.024910807609558105,
      0.024185603484511375,
      -0.01272417139261961,
      0.013876637443900108,
      0.0027205345686525106,
      0.07656502723693848,
      -0.11620094627141953,
      0.023611577227711678,
      0.01626673899590969,
      0.01653715781867504,
      -0.05131176486611366,
      -0.11360371857881546,
      -0.07599879056215286,
      0.029568590223789215,
      -0.019719162955880165,
      0.010327734984457493,
      0.03797629103064537,
      -0.03107566572725773,
      -0.03720179945230484,
      -0.13771691918373108,
      0.05620848014950752,
      0.08952506631612778,
      -0.03097160905599594,
      0.013895167969167233,
      0.03862853720784187,
      -0.019504280760884285,
      -0.061744075268507004,
      0.08119679242372513,
      -0.10101082921028137,
      0.007171747274696827,
      -0.04813264682888985,
      0.045507047325372696,
      0.012306568212807178,
      -0.04427260905504227,
      -0.030863527208566666,
      -0.0876835361123085,
      -0.03587253764271736,
      -0.08619508147239685,
      0.031615838408470154,
      -0.06389427930116653,
      0.05780629813671112,
      -0.013921781443059444,
      0.03850587084889412,
      0.007430288475006819,
      0.0023948715534061193,
      0.09224546700716019,
      0.019763197749853134,
      -0.02458224818110466,
      -0.003239728743210435,
      -0.04639839008450508,
      -0.0017915655625984073,
      -0.058445513248443604,
      -0.13280965387821198,
      0.003108313772827387,
      0.02761317975819111,
      -0.02505192905664444,
      0.055491868406534195,
      0.03669091314077377,
      -0.05878214165568352,
      0.06506343185901642,
      -0.04437088966369629,
      0.012409343384206295,
      -0.02066580392420292,
      -0.02258365787565708,
      -0.03407731652259827,
      0.007662005256861448,
      0.0216788649559021,
      0.002824773546308279,
      0.0059671346098184586,
      -0.0009936974383890629,
      0.007128104567527771,
      -0.026824546977877617,
      -0.013990731909871101,
      -0.048923175781965256,
      0.0133200753480196,
      -0.021893223747611046,
      0.05243837088346481,
      -0.09202882647514343,
      -0.007398963440209627,
      -0.006948729045689106,
      0.05716592073440552,
      0.006777866743505001,
      -0.0395531952381134,
      0.02239268831908703,
      0.022137494757771492,
      -0.0384044386446476,
      -0.061079371720552444,
      0.0031898843590170145,
      -0.012810253538191319,
      -0.01892107166349888,
      0.02359871007502079,
      0.012876486405730247,
      0.009669026359915733,
      0.0842692032456398,
      0.07610944658517838,
      -0.026548990979790688,
      -0.030462613329291344,
      -0.017375154420733452,
      0.0311149712651968,
      0.08194200694561005,
      0.07685485482215881,
      0.05618571117520332,
      0.03852161020040512,
      0.024928664788603783,
      0.02858760580420494,
      -0.06731119751930237,
      -0.006335162557661533,
      0.04812797158956528,
      -0.03753814101219177,
      0.07795023173093796,
      0.06852899491786957,
      -0.012957603670656681,
      0.0011563292937353253,
      0.09194404631853104,
      -0.03257356211543083,
      0.019434737041592598,
      0.05464920029044151,
      -0.022819137200713158,
      -0.011921958066523075,
      0.0160991083830595,
      -0.05008874461054802,
      -0.01264699175953865,
      -0.021468056365847588,
      -0.016115745529532433,
      0.08880061656236649,
      0.0010950821451842785,
      0.07298363745212555,
      -0.015736335888504982,
      -0.06381714344024658,
      -0.005780919920653105,
      -0.023883964866399765,
      0.015696236863732338,
      0.030528748407959938,
      -0.08720781654119492,
      -0.11669521778821945,
      -0.07449278235435486,
      0.012968414463102818,
      0.012621814385056496,
      -0.08492089062929153,
      0.01844448782503605,
      0.0092723798006773,
      0.009989508427679539,
      0.025189759209752083,
      -0.01223697979003191,
      -0.05817322060465813,
      -0.0009843016741797328,
      0.005832598078995943,
      -0.054722029715776443,
      -0.027972862124443054,
      0.05315752699971199,
      0.008280633017420769,
      -0.02990216389298439,
      0.0067133414559066296,
      -0.014760914258658886,
      -0.01703525520861149,
      -0.015920430421829224,
      -0.006569159682840109,
      0.03734063729643822,
      -0.034620657563209534,
      -0.015890246257185936,
      0.04402300715446472,
      -0.06100532039999962,
      -0.012766755186021328,
      0.027895085513591766,
      0.041081368923187256,
      0.03350704535841942,
      0.03104269877076149,
      0.0018773346673697233,
      0.03207893669605255,
      -0.03979529067873955,
      -0.008424962870776653,
      -0.013705065473914146,
      -0.054710499942302704,
      -0.043772511184215546,
      0.020845580846071243,
      0.06216806173324585,
      -0.05472075194120407,
      -0.07426197081804276,
      0.011385731399059296,
      0.08798868209123611,
      0.0483391135931015,
      0.04116474464535713,
      0.02507566288113594,
      0.010929582640528679,
      -0.06236446276307106,
      -0.01711244508624077,
      -0.06345383822917938,
      -0.03568422421813011,
      -0.0036378540098667145,
      -0.04971195012331009,
      0.04126410931348801,
      -0.1261184960603714,
      -0.09193073213100433,
      0.013415814377367496,
      0.05057123303413391,
      -0.017657911404967308,
      0.006342667154967785,
      -0.03196847438812256,
      0.00779555132612586,
      0.06241267919540405,
      -0.02159576490521431,
      -0.019809918478131294,
      0.04288947582244873,
      0.031539034098386765,
      -0.05018068104982376,
      -0.054470255970954895,
      0.02855178900063038,
      0.05761019513010979,
      0.009398605674505234,
      0.049840644001960754,
      -0.09214513748884201,
      -0.025628671050071716,
      0.013165213167667389,
      -0.09307882934808731,
      -0.06780684739351273,
      0.022494442760944366,
      -0.024903222918510437,
      0.07610677182674408,
      -0.15944890677928925,
      0.05361827835440636,
      -0.01526943314820528,
      0.0002793669409584254,
      0.045525386929512024,
      0.06503085792064667,
      0.03565669804811478,
      -0.013997890055179596,
      -3.680808367789723e-05
    ],
    [
      0.032281551510095596,
      0.008098447695374489,
      -0.06682982295751572,
      0.00222055078484118,
      -0.023510564118623734,
      0.0226405281573534,
      0.003264961764216423,
      -0.06471727788448334,
      -0.030933985486626625,
      0.051762569695711136,
      -0.007593883201479912,
      -0.036267854273319244,
      0.026260249316692352,
      -0.12925034761428833,
      -0.01937871053814888,
      0.09209030121564865,
      -0.008982623927295208,
      0.03889154642820358,
      0.017033008858561516,
      0.06883402168750763,
      -0.04148280248045921,
      -0.005995418876409531,
      -0.04018130898475647,
      0.03875778615474701,
      0.001774004427716136,
      0.0017884825356304646,
      0.04872497543692589,
      -0.012931307777762413,
      -0.029517827555537224,
      0.00012584785872604698,
      -0.03569765016436577,
      -0.008480492047965527,
      -0.07413496822118759,
      -0.049348775297403336,
      0.032181404531002045,
      0.05830574408173561,
      -0.05203740671277046,
      -0.02143075317144394,
      0.0352681502699852,
      0.056911781430244446,
      -0.025560710579156876,
      0.003353349631652236,
      0.05220320075750351,
      -0.05232812464237213,
      -0.07368973642587662,
      0.010582173243165016,
      0.12147598713636398,
      0.02873583510518074,
      -0.0351458378136158,
      -0.004520101007074118,
      -0.07470211386680603,
      -0.023074548691511154,
      0.03384106606245041,
      -0.055959202349185944,
      -0.00016764803149271756,
      0.024768568575382233,
      0.05503449589014053,
      -0.0124046104028821,
      0.006242631934583187,
      0.009868782944977283,
      -0.04918676242232323,
      0.021970612928271294,
      -0.013264811597764492,
      -0.09489407390356064,
      0.0442495234310627,
      0.12615904211997986,
      -0.04649186506867409,
      0.05920344591140747,
      0.062170933932065964,
      -0.020946960896253586,
      -0.025270067155361176,
      -0.02043696492910385,
      -0.028988245874643326,
      -0.03628898039460182,
      -0.023686598986387253,
      0.07300907373428345,
      0.03797314316034317,
      -0.014598616398870945,
      -0.05546685680747032,
      0.05945192649960518,
      0.09334035962820053,
      0.00655748974531889,
      0.017219487577676773,
      -0.055948056280612946,
      -0.006080462131649256,
      -0.019251804798841476,
      0.03223903849720955,
      -0.016040192916989326,
      -0.04040897265076637,
      0.04063853994011879,
      0.030897192656993866,
      -0.09974970668554306,
      -0.024766989052295685,
      0.06028424948453903,
      0.025865962728857994,
      -0.04752353951334953,
      -0.023453103378415108,
      -0.0028925519436597824,
      -0.0035272713284939528,
      0.10926302522420883,
      -0.05691198259592056,
      -0.01981852762401104,
      -0.009703493677079678,
      -0.026726331561803818,
      0.01569494791328907,
      0.10216577351093292,
      -0.024577252566814423,
      -0.01762991212308407,
      -0.019701113924384117,
      -0.00634021358564496,
      -0.03830397129058838,
      -0.012328388169407845,
      -0.029834331944584846,
      0.004547632299363613,
      0.010584655217826366,
      0.020001254975795746,
      -0.04871778190135956,
      -0.029171565547585487,
      0.12253288179636002,
      0.036969564855098724,
      -0.09367666393518448,
      -0.02701784484088421,
      0.028732765465974808,
      -0.06018044427037239,
      0.0457213893532753,
      -0.020097695291042328,
      0.009296795353293419,
      -0.09129324555397034,
      0.007234246004372835,
      -0.030213112011551857,
      -0.10215765237808228,
      0.006082420237362385,
      -0.043017588555812836,
      -0.01781410351395607,
      -0.02905772440135479,
      -0.03852376341819763,
      -0.04340416193008423,
      -0.02025534026324749,
      0.009625284001231194,
      -0.01296467985957861,
      -0.024645524099469185,
      0.03538772091269493,
      0.02156146429479122,
      -0.025397179648280144,
      -0.026582922786474228,
      -0.03574781119823456,
      -0.019385231658816338,
      -0.02299678698182106,
      0.016777709126472473,
      -0.03405880555510521,
      -0.03808929771184921,
      0.026972142979502678,
      -0.008016734384000301,
      0.07253148406744003,
      0.0763968825340271,
      0.009739079512655735,
      -0.06559134274721146,
      -0.04173770174384117,
      0.0035639218986034393,
      0.04238181188702583,
      0.08782105147838593,
      -0.053825270384550095,
      0.026487121358513832,
      0.01960463635623455,
      0.057399146258831024,
      -0.09202712774276733,
      -0.01684892177581787,
      0.011311261914670467,
      -0.004218250513076782,
      -0.0033940470311790705,
      -0.02973802760243416,
      -0.032396864145994186,
      0.03494737297296524,
      -0.017158420756459236,
      0.056630395352840424,
      -0.02295784279704094,
      -0.027744900435209274,
      0.03892382234334946,
      -0.02310868911445141,
      -0.09991654753684998,
      0.048867885023355484,
      0.040895793586969376,
      0.004219475667923689,
      -0.001932893879711628,
      -0.06922121345996857,
      0.03734965994954109,
      0.09500979632139206,
      -0.0474078506231308,
      -0.027928004041314125,
      0.0019657546654343605,
      -0.04024680703878403,
      -0.010616849176585674,
      0.008132928982377052,
      -0.04820144176483154,
      0.02242114394903183,
      0.017744824290275574,
      -0.024804512038826942,
      -0.029751259833574295,
      -0.02830020897090435,
      -0.017719564959406853,
      0.05827786773443222,
      0.011147670447826385,
      -0.038695357739925385,
      -0.010062227956950665,
      -0.002254089806228876,
      -0.003639286383986473,
      -0.06996142864227295,
      0.0074256048537790775,
      0.03238115459680557,
      -0.1272985339164734,
      0.0883454829454422,
      0.06706837564706802,
      -0.0759059265255928,
      -0.04655255004763603,
      -0.036632511764764786,
      -0.04122776538133621,
      -0.009737125597894192,
      -0.06545962393283844,
      -0.04326361045241356,
      -0.0337616428732872,
      0.05525791272521019,
      0.01623932458460331,
      -0.003195008262991905,
      -0.0211379062384367,
      0.008386430330574512,
      -0.0484926775097847,
      0.011735058389604092,
      0.028744254261255264,
      0.0764274001121521,
      0.03890801966190338,
      -0.08330327272415161,
      0.02276042103767395,
      -0.017841512337327003,
      0.0010819964809343219,
      -0.036540210247039795,
      0.0930996760725975,
      -0.05533522367477417,
      0.027796396985650063,
      0.027998503297567368,
      -0.00039872314664535224,
      0.026951028034090996,
      0.031532417982816696,
      -0.05138012021780014,
      0.0936872810125351,
      -0.02971704863011837,
      -0.058417364954948425,
      0.01763191819190979,
      -0.02092553675174713,
      -0.06433691829442978,
      0.06817509233951569,
      0.02466150000691414,
      -0.023010389879345894,
      0.02409881167113781,
      -0.03631380945444107,
      0.01711113564670086,
      0.0659351497888565,
      -0.019317973405122757,
      -0.045993391424417496,
      -0.02291601337492466,
      -0.014474760740995407,
      -0.019660314545035362,
      -0.004590010270476341,
      0.0013286814792081714,
      0.009449011646211147,
      0.0011884617852047086,
      -0.01978199928998947,
      -0.032112110406160355,
      0.03399040922522545,
      0.033404428511857986,
      -0.06257601827383041,
      0.040458403527736664,
      0.03238549083471298,
      0.0186898335814476,
      -0.027423975989222527,
      -0.007957044057548046,
      -0.006652813870459795,
      -0.024477466940879822,
      -0.044763218611478806,
      -0.03456481546163559,
      0.04131687059998512,
      -0.008462426252663136,
      0.0046268790028989315,
      -0.010877279564738274,
      0.029304809868335724,
      0.05480275675654411,
      -0.04709511250257492,
      -0.0012136956211179495,
      -0.13086917996406555,
      0.019205495715141296,
      0.019931338727474213,
      0.0026211508084088564,
      0.03494773060083389,
      -0.010850146412849426,
      -0.018834169954061508,
      -0.09167100489139557,
      -0.10032445937395096,
      0.05787220597267151,
      0.00278529548086226,
      -0.025794243440032005,
      0.002522082068026066,
      -0.003993806429207325,
      0.04815053939819336,
      0.007635714486241341,
      0.03654342144727707,
      0.05297725275158882,
      0.0457036979496479,
      -0.0018845712766051292,
      -0.006250796839594841,
      0.043316520750522614,
      0.06389153748750687,
      -0.033507589250802994,
      -0.08270715177059174,
      -0.023507772013545036,
      -0.05300610885024071,
      -0.005622056778520346,
      0.03535602614283562,
      5.31835357833188e-05,
      -0.005794270895421505,
      0.014858759939670563,
      -0.0044608390890061855,
      0.010422227904200554,
      0.024358635768294334,
      -0.008904088288545609,
      -0.0554177351295948,
      0.04501419886946678,
      0.05490163341164589,
      -0.028007876127958298,
      -0.07875905930995941,
      -0.015660980716347694,
      0.10249780863523483,
      0.08087839186191559,
      0.006043551955372095,
      0.033540286123752594,
      0.05431139096617699,
      -0.011591601185500622,
      -0.03577141463756561,
      -0.05304863303899765,
      0.08423344790935516,
      -0.003791964612901211,
      -0.024754904210567474,
      0.03185134381055832,
      0.012858801521360874,
      -0.02934974431991577,
      -0.01927924156188965,
      -0.07163893431425095,
      0.0415441170334816,
      -0.03596050664782524,
      -0.03997866436839104,
      0.03376465290784836,
      -0.04855075106024742,
      0.045233916491270065,
      -0.0651262030005455,
      0.02720997855067253,
      -0.06739021837711334,
      0.016422562301158905,
      0.010698486119508743,
      0.022594576701521873,
      0.03979196399450302,
      0.047168564051389694,
      -0.03042348474264145,
      -0.003970411606132984,
      -0.005418058019131422,
      0.029693275690078735,
      -0.06210712343454361,
      0.04574350640177727,
      0.05720578134059906,
      0.054093584418296814,
      0.05186696723103523,
      0.09549885243177414,
      0.027260733768343925,
      0.036182697862386703,
      0.01805148273706436,
      0.06320662796497345,
      -0.00842561386525631,
      -0.04044101759791374,
      0.0662560984492302,
      -0.04611818864941597,
      -0.02046768181025982,
      -0.021288683637976646,
      0.03385636955499649,
      -0.02645255997776985,
      0.08384077996015549,
      0.048841170966625214,
      0.12244971096515656,
      0.02604922093451023,
      -0.0514695979654789,
      -0.09756961464881897,
      -0.0026388796977698803,
      -0.01225989405065775,
      -0.03505663946270943,
      -0.01527986116707325,
      -0.036622460931539536,
      0.0180648360401392,
      0.03739684447646141,
      -0.09958236664533615,
      0.050370823591947556,
      0.03406785428524017,
      0.0021609182003885508,
      -0.038255222141742706,
      0.02131887711584568,
      -0.012950502336025238,
      -0.045067813247442245,
      0.05295413359999657,
      -0.01411259826272726,
      -0.029715165495872498,
      -0.07120341807603836,
      0.06138942390680313,
      0.01746402308344841,
      -0.08237157016992569,
      0.01902650110423565,
      0.011334121227264404,
      -0.06657304614782333,
      0.04251754656434059,
      -0.02322584204375744,
      0.0144417779520154,
      0.01819664053618908,
      -0.03242736682295799,
      -0.0851893499493599,
      0.054588571190834045,
      0.05021753907203674,
      -0.04472792148590088,
      -0.008421326987445354,
      -0.0450650155544281,
      -0.05369703471660614,
      -0.017763225361704826,
      -0.0334412083029747,
      0.025709830224514008,
      -0.004244169685989618,
      0.039729516953229904,
      0.006634569261223078,
      -0.021542174741625786,
      0.002671228488907218,
      0.039075542241334915,
      -0.04930414631962776,
      0.028034081682562828,
      -0.11097721010446548,
      -0.0961785838007927,
      -0.035812653601169586,
      0.09488530457019806,
      -0.04871349409222603,
      -0.07841666042804718,
      -0.03392118960618973,
      -0.045871760696172714,
      0.027234315872192383,
      -0.04545534774661064,
      0.04720098525285721,
      -0.012955648824572563,
      0.001597057213075459,
      0.025500815361738205,
      0.0337042510509491,
      0.024722805246710777,
      0.06254060566425323,
      -0.03463784605264664,
      0.08554547280073166,
      0.021518725901842117,
      -0.010737588629126549,
      0.053521778434515,
      -0.06845813244581223,
      -0.04422566294670105,
      0.039972495287656784,
      -0.0071271988563239574,
      0.05910373106598854,
      0.041143376380205154,
      0.06705530732870102,
      -0.0672527551651001,
      -0.046244870871305466,
      0.026392167434096336,
      -0.05805966258049011,
      -0.06996572762727737,
      0.010474294424057007,
      -0.039287466555833817,
      -0.018560418859124184,
      0.06549274176359177,
      0.029570646584033966,
      -0.04239090904593468,
      0.010328728705644608,
      0.08225242793560028,
      -0.004209673963487148,
      0.028185060247778893,
      0.08095049113035202,
      -0.02641902305185795,
      0.005744485650211573,
      -0.022771280258893967,
      0.11061584949493408,
      -0.03616771474480629,
      0.0626668706536293,
      -0.06728027015924454,
      -0.02215489372611046,
      0.08483202010393143,
      -0.04412151500582695,
      0.059760235249996185,
      0.004368265625089407,
      0.10495813935995102,
      0.005400609690696001,
      0.11193440854549408,
      0.05009641870856285,
      0.007332738488912582,
      -0.07257866114377975,
      0.04150281101465225,
      0.07711954414844513,
      0.03806639835238457,
      -0.013833937235176563,
      0.06895241141319275
    ],
    [
      0.007358540780842304,
      -0.002941377693787217,
      0.04305754601955414,
      0.019944079220294952,
      -0.0011172204976901412,
      -0.04263448715209961,
      0.0026470706798136234,
      0.055017534643411636,
      -0.009221422486007214,
      0.053069550544023514,
      -0.011524648405611515,
      -0.05371702089905739,
      0.03668225184082985,
      -0.026136666536331177,
      0.04047153517603874,
      -0.11114645004272461,
      -0.0006619028281420469,
      0.0005162970046512783,
      -0.04864878952503204,
      0.036785077303647995,
      0.034476954489946365,
      -0.025687091052532196,
      0.011769646778702736,
      0.05200694873929024,
      0.05230330303311348,
      0.09690812975168228,
      -0.0015695454785600305,
      0.011241558939218521,
      -0.06583744287490845,
      -0.03733065351843834,
      -0.04194072261452675,
      0.09402767568826675,
      -0.08912352472543716,
      0.057576313614845276,
      0.03210807591676712,
      0.007878197357058525,
      0.08245433866977692,
      0.0468844436109066,
      -0.013800404965877533,
      -0.015488160774111748,
      0.019712770357728004,
      -0.013757223263382912,
      -0.007271784357726574,
      -0.04496030509471893,
      -0.02667732909321785,
      0.008479717187583447,
      0.09450161457061768,
      0.044164370745420456,
      0.061823904514312744,
      0.05445452779531479,
      -0.001386611838825047,
      0.0038075619377195835,
      -0.017780698835849762,
      -0.04598895087838173,
      0.0618559829890728,
      0.011621488258242607,
      0.07738356292247772,
      -0.010866470634937286,
      0.008484569378197193,
      0.05029033496975899,
      0.08086656779050827,
      -0.09495247155427933,
      -0.054439298808574677,
      -0.06527455896139145,
      0.023186003789305687,
      -0.0960325300693512,
      0.03318241611123085,
      0.012707730755209923,
      -0.019764358177781105,
      -0.00010512441804166883,
      -0.041766680777072906,
      0.06071433052420616,
      0.018288148567080498,
      0.030393056571483612,
      -0.11355477571487427,
      0.08887644857168198,
      -0.05690578371286392,
      -0.054729122668504715,
      -0.01007088553160429,
      -0.02563677541911602,
      0.07308584451675415,
      6.799250695621595e-05,
      0.05788499116897583,
      -0.03733278810977936,
      -0.09776930510997772,
      0.014536081813275814,
      -0.036501627415418625,
      -0.01502230018377304,
      0.0027581530157476664,
      0.048532743006944656,
      0.13384568691253662,
      -0.020595671609044075,
      -0.02232576161623001,
      -0.06988251954317093,
      -0.020495399832725525,
      -0.021939881145954132,
      0.059157129377126694,
      0.03121098317205906,
      0.04031272605061531,
      -0.022590527310967445,
      -0.012425105087459087,
      -0.0743548795580864,
      0.013287289999425411,
      -0.1206064224243164,
      0.0006079000886529684,
      -0.01939781755208969,
      0.020000766962766647,
      0.017298461869359016,
      0.030741777271032333,
      -0.010894829407334328,
      -0.11442309617996216,
      -0.08086417615413666,
      0.056820906698703766,
      0.03916054591536522,
      0.05615676939487457,
      -0.022048525512218475,
      -0.032265082001686096,
      -0.0349605493247509,
      0.02456996776163578,
      -0.05420232564210892,
      0.010002612136304379,
      -0.07219219952821732,
      -0.014334016479551792,
      0.0039948709309101105,
      0.03710871934890747,
      0.08102252334356308,
      -0.01929834671318531,
      -0.05929814279079437,
      -0.0024930289946496487,
      -0.009222458116710186,
      0.042557235807180405,
      -0.020524699240922928,
      0.06709160655736923,
      -0.005921521224081516,
      -0.050770968198776245,
      0.037784747779369354,
      0.054334066808223724,
      -0.031525157392024994,
      0.0006173696601763368,
      0.05437425151467323,
      -0.011496396735310555,
      0.01972450129687786,
      0.09163200110197067,
      -0.027802877128124237,
      0.016024600714445114,
      -0.05130026489496231,
      -0.04626184329390526,
      0.03141273930668831,
      -0.07716357707977295,
      0.034630708396434784,
      0.05196668952703476,
      -0.03688705340027809,
      -0.009010902605950832,
      -0.008087953552603722,
      -0.044252075254917145,
      0.0012519211741164327,
      -0.03766772896051407,
      -0.04051687940955162,
      0.0005069158505648375,
      0.042743440717458725,
      0.07236244529485703,
      -0.047924675047397614,
      0.01971597410738468,
      0.04758933559060097,
      -0.05246557295322418,
      0.002137509174644947,
      -0.028250297531485558,
      -0.023876728489995003,
      0.011042826808989048,
      -0.026827525347471237,
      -0.023206736892461777,
      0.0024474114179611206,
      -0.130060613155365,
      -0.0060226512141525745,
      -0.043946728110313416,
      0.005173689220100641,
      0.026619328185915947,
      -0.06583546102046967,
      -0.005149995442479849,
      -0.01335974782705307,
      -0.060956764966249466,
      0.003317413851618767,
      -0.01714503951370716,
      -0.018098028376698494,
      0.01794010028243065,
      0.02846791408956051,
      -0.017542103305459023,
      0.04231168329715729,
      0.005079810507595539,
      -0.027788374572992325,
      0.05114857852458954,
      0.013317376375198364,
      0.05190961807966232,
      -0.020613908767700195,
      0.015365244820713997,
      0.011036491952836514,
      0.01986461691558361,
      -0.017964104190468788,
      0.02532954514026642,
      0.017581865191459656,
      -0.007097641937434673,
      -0.016512934118509293,
      -0.027204785495996475,
      0.05839325115084648,
      0.06569301337003708,
      -0.035557299852371216,
      -0.014371379278600216,
      -0.01668829657137394,
      0.022924939170479774,
      -0.05740843340754509,
      0.08152224123477936,
      0.0695086270570755,
      -0.10582239180803299,
      0.015608650632202625,
      -0.04751982539892197,
      -0.030075490474700928,
      -0.03526182845234871,
      -0.013429664075374603,
      -0.044316887855529785,
      -0.02903132326900959,
      -0.07333237677812576,
      -0.05222571641206741,
      -0.0745759978890419,
      -0.02948150970041752,
      0.003533564740791917,
      0.06195316091179848,
      -0.04262308403849602,
      0.05566545948386192,
      -0.06422027200460434,
      -0.10767961293458939,
      -0.018052395433187485,
      -0.062126196920871735,
      -0.021696651354432106,
      0.04526669904589653,
      0.0318005308508873,
      0.058894652873277664,
      0.01928742602467537,
      0.07787105441093445,
      0.058611247688531876,
      -0.0352340005338192,
      0.04432060196995735,
      -0.01752825640141964,
      -0.04229697957634926,
      -0.08591334521770477,
      0.08746033161878586,
      -0.07738445699214935,
      -0.03728834167122841,
      0.028340302407741547,
      0.03820893168449402,
      0.048855844885110855,
      0.028753750026226044,
      0.02450886368751526,
      -0.010127724148333073,
      -0.08749382942914963,
      0.08651658892631531,
      0.01346820592880249,
      0.044076867401599884,
      -0.03700247406959534,
      -0.020182188600301743,
      0.018680069595575333,
      -0.054011791944503784,
      0.0013805126072838902,
      0.0708254799246788,
      0.04210982844233513,
      -0.018617870286107063,
      0.06294696778059006,
      -0.009255118668079376,
      0.023305028676986694,
      -0.0192733071744442,
      -0.039248283952474594,
      0.03669063746929169,
      0.031152473762631416,
      -0.023805806413292885,
      0.0018457939149811864,
      -0.02544335462152958,
      0.008780238218605518,
      -0.019881635904312134,
      0.028393562883138657,
      -0.03339056670665741,
      0.03681017458438873,
      -0.14360679686069489,
      0.002695739269256592,
      -0.021597716957330704,
      -0.023077208548784256,
      -0.06762053072452545,
      0.0072600357234478,
      0.03272097930312157,
      -0.004951158072799444,
      -0.032089974731206894,
      0.07588883489370346,
      0.08201438188552856,
      0.02858126349747181,
      -0.08998989313840866,
      0.01575256697833538,
      0.010385711677372456,
      -0.06674358248710632,
      0.022983403876423836,
      0.0647667944431305,
      0.02286306396126747,
      0.07254763692617416,
      -0.023439114913344383,
      0.008180792443454266,
      0.013929728418588638,
      0.07884909212589264,
      -0.020261066034436226,
      -0.03879375383257866,
      -0.007574352901428938,
      0.0040945615619421005,
      -0.04807891324162483,
      0.0634029358625412,
      0.02664393000304699,
      -0.04793564975261688,
      -0.01531999558210373,
      0.06812537461519241,
      -0.01978999562561512,
      0.020025769248604774,
      0.05162201449275017,
      -0.05037540942430496,
      0.028630098327994347,
      0.01123549323529005,
      0.040387146174907684,
      0.03694918379187584,
      -0.043712932616472244,
      0.02913225255906582,
      0.07729228585958481,
      -0.036664530634880066,
      -0.052883733063936234,
      -0.021609628573060036,
      0.00882812775671482,
      0.030711010098457336,
      0.07165966182947159,
      -0.11926599591970444,
      0.07891284674406052,
      -0.006950361654162407,
      -0.03079773299396038,
      -0.05861090123653412,
      -0.009074958972632885,
      -0.03623171150684357,
      -0.017178788781166077,
      0.01258902344852686,
      0.0029678414575755596,
      -0.07017625123262405,
      0.0015471124788746238,
      0.030713345855474472,
      0.0008440084056928754,
      0.10531789064407349,
      0.007572455331683159,
      -0.03924882411956787,
      0.02313397452235222,
      0.019947189837694168,
      -0.02878458797931671,
      0.00023381912615150213,
      0.02721865475177765,
      0.026542924344539642,
      -0.03500939533114433,
      0.010490234941244125,
      -0.05013316869735718,
      -0.04733410105109215,
      -0.07912114262580872,
      0.07421555370092392,
      -0.01761692576110363,
      -0.04030705988407135,
      0.032304372638463974,
      0.044864438474178314,
      0.00018501654267311096,
      0.1068841889500618,
      -0.035314589738845825,
      -0.12149539589881897,
      -0.023602494969964027,
      0.02441043220460415,
      0.04576161876320839,
      0.030644407495856285,
      0.0174037404358387,
      0.030297409743070602,
      -0.042218782007694244,
      0.04420586675405502,
      -0.0015551993856206536,
      0.04083587974309921,
      -0.02103806473314762,
      0.013126430101692677,
      0.048845402896404266,
      -0.005798558238893747,
      0.015050561167299747,
      0.024438802152872086,
      0.05062940716743469,
      -0.012704079039394855,
      0.00888793170452118,
      -0.020122213289141655,
      0.029448620975017548,
      0.022170843556523323,
      0.0533466674387455,
      0.08510544151067734,
      0.03110574372112751,
      0.13645455241203308,
      0.06355377286672592,
      -0.08790747076272964,
      0.016647804528474808,
      0.0012498062569648027,
      0.03185652941465378,
      -0.08850108832120895,
      0.0002559221175033599,
      0.03222334384918213,
      0.045310329645872116,
      -0.05716269463300705,
      0.014654064550995827,
      -0.054145682603120804,
      -0.008981387130916119,
      0.012782922945916653,
      -0.060910362750291824,
      -0.005078842863440514,
      -0.09605928510427475,
      -0.043522633612155914,
      0.0904923677444458,
      -0.04038763418793678,
      0.036418404430150986,
      -0.022589635103940964,
      -0.018261417746543884,
      -0.011428759433329105,
      -0.018352095037698746,
      -0.09375457465648651,
      -0.01329063531011343,
      -0.04498639330267906,
      0.03938731551170349,
      0.02924123778939247,
      0.03396962583065033,
      -0.07713387161493301,
      0.12925319373607635,
      -0.09108669310808182,
      -0.0442063994705677,
      0.0667477399110794,
      -0.001566528924740851,
      0.022751104086637497,
      -0.037683773785829544,
      -0.0075117452070117,
      -0.07308479398488998,
      0.07767362892627716,
      0.06840942054986954,
      -0.06743152439594269,
      -0.02600756287574768,
      0.04793907329440117,
      0.041069306433200836,
      0.04973698779940605,
      -0.02141866274178028,
      -0.0009035606635734439,
      0.0195661261677742,
      -0.010340160690248013,
      -0.03692562133073807,
      -0.04110050946474075,
      0.0032130556646734476,
      -0.05193321406841278,
      -0.028333796188235283,
      0.00760319409891963,
      0.04669862985610962,
      -0.03669516742229462,
      0.056211963295936584,
      -0.06845603138208389,
      -0.038592077791690826,
      -0.10782662779092789,
      -0.03767374902963638,
      -0.018184028565883636,
      0.0550718791782856,
      0.04131939262151718,
      0.005847194232046604,
      -0.04489542543888092,
      0.029126137495040894,
      0.007795367855578661,
      0.03159559145569801,
      0.02111496590077877,
      -0.06171277537941933,
      -0.008760795928537846,
      0.038574278354644775,
      0.029694683849811554,
      -0.062392160296440125,
      0.03313981369137764,
      0.0578964501619339,
      -0.08587159216403961,
      -0.058873966336250305,
      0.05891408026218414,
      -0.07402141392230988,
      0.03279910981655121,
      -0.020050151273608208,
      0.008409974165260792,
      -0.010500174947082996,
      -0.04418246075510979,
      0.044727079570293427,
      -0.004501184448599815,
      -0.03512496128678322,
      -0.05823554843664169,
      0.011221501976251602,
      -0.03849714621901512,
      0.09051629900932312,
      -0.08005357533693314,
      0.019098488613963127,
      0.016734343022108078,
      0.03473434969782829,
      -0.017332544550299644,
      0.022528022527694702,
      -0.07003467530012131,
      -0.034865573048591614,
      -0.026054294779896736,
      -0.02980673871934414,
      0.04434040188789368,
      0.0577268972992897
    ],
    [
      0.0050339484587311745,
      0.04071027413010597,
      0.029443932697176933,
      -0.02555023320019245,
      0.14246539771556854,
      0.011877370066940784,
      -0.029117535799741745,
      -0.023871755227446556,
      -0.08582217991352081,
      0.04453694447875023,
      0.07932701706886292,
      0.038827866315841675,
      -0.08611630648374557,
      0.022420980036258698,
      0.02055537700653076,
      0.033516984432935715,
      0.009345333091914654,
      -0.08120355755090714,
      -0.12473343312740326,
      -0.04938134551048279,
      0.008419099263846874,
      -0.03675416111946106,
      0.041043248027563095,
      0.0312082190066576,
      0.10512302070856094,
      0.058916885405778885,
      -0.004125526640564203,
      0.010077900253236294,
      -0.01836576499044895,
      -0.03513278812170029,
      0.043414678424596786,
      0.016037797555327415,
      -0.03507586196064949,
      0.045983023941516876,
      -0.027666175737977028,
      -3.856914190691896e-05,
      -0.05411475524306297,
      -0.015161528252065182,
      0.06664776802062988,
      0.01970946602523327,
      -0.003979776985943317,
      -0.006774472072720528,
      0.10135604441165924,
      0.04294458031654358,
      -0.009522817097604275,
      -0.022736333310604095,
      0.07424172759056091,
      -0.06075061485171318,
      -0.005793797317892313,
      0.0004269016208127141,
      0.02043287083506584,
      -0.027714334428310394,
      0.029833819717168808,
      -0.04320352151989937,
      0.018570400774478912,
      -0.03145858645439148,
      0.21376103162765503,
      -0.03569976985454559,
      0.014830425381660461,
      0.03075873851776123,
      -0.07675063610076904,
      -0.05468612536787987,
      -0.06327777355909348,
      -0.05000150948762894,
      -0.014652146957814693,
      0.021388255059719086,
      -0.04580455645918846,
      0.08477554470300674,
      0.03355026990175247,
      0.08650077134370804,
      0.02369263954460621,
      -0.04837893694639206,
      7.338157593039796e-05,
      0.0011816786136478186,
      -0.028806759044528008,
      0.06621967256069183,
      0.0064050741493701935,
      -0.016898058354854584,
      -0.0006632920121774077,
      0.02377563901245594,
      -0.03547457978129387,
      0.031245552003383636,
      0.040781691670417786,
      -0.02956678904592991,
      0.042113665491342545,
      0.07552294433116913,
      -0.05839334428310394,
      -0.05484403669834137,
      0.0011768437689170241,
      0.09198066592216492,
      -0.003962332848459482,
      0.007030409760773182,
      -0.004097942262887955,
      -0.01197865605354309,
      -0.03391719236969948,
      0.07080560177564621,
      0.03253896161913872,
      0.08187269419431686,
      0.026461230590939522,
      0.049381569027900696,
      -0.06494690477848053,
      -0.020990051329135895,
      -0.02448195219039917,
      0.017217788845300674,
      0.0460185743868351,
      0.03649482503533363,
      0.05134335160255432,
      -0.02951028011739254,
      0.02848842367529869,
      0.02011502906680107,
      -0.08826521784067154,
      -0.01011685561388731,
      -0.06957698613405228,
      0.043731752783060074,
      0.04270857945084572,
      0.013056479394435883,
      0.008449657820165157,
      -0.009910435415804386,
      0.06430185586214066,
      -0.013026424683630466,
      0.0042493646033108234,
      -0.027453362941741943,
      -0.05635102838277817,
      0.024585941806435585,
      0.053429171442985535,
      -0.022276103496551514,
      0.029090454801917076,
      -0.007926777005195618,
      -0.01666969433426857,
      -0.011286478489637375,
      -0.010295676998794079,
      0.08228675276041031,
      0.034063056111335754,
      0.09511345624923706,
      -0.02367732673883438,
      0.019794447347521782,
      0.02449728548526764,
      0.020215386524796486,
      -0.04648894816637039,
      0.04537605866789818,
      0.02922159805893898,
      0.0020449927542358637,
      0.025402741506695747,
      0.0407581627368927,
      -0.056321222335100174,
      0.0062162019312381744,
      -0.022308213636279106,
      0.0003165283123962581,
      0.03442555293440819,
      0.03620317950844765,
      -0.010821236297488213,
      0.03871893510222435,
      -0.03152298554778099,
      -0.038912758231163025,
      0.05591559782624245,
      -0.00048577648703940213,
      -0.06522145867347717,
      0.038351498544216156,
      -0.052632611244916916,
      0.0632963702082634,
      0.008752313442528248,
      0.039922330528497696,
      -0.009124631993472576,
      0.006851183716207743,
      0.052857331931591034,
      -0.06133555248379707,
      -0.009265931323170662,
      -0.024742895737290382,
      -0.0014199322322383523,
      0.04305749014019966,
      -0.006924125365912914,
      0.035323116928339005,
      0.04473666474223137,
      0.06962435692548752,
      -0.03145348280668259,
      0.0035159261897206306,
      -0.057664696127176285,
      -0.05017728731036186,
      -0.018266819417476654,
      0.01231897808611393,
      -0.020746467635035515,
      -0.006717056035995483,
      -0.029508868232369423,
      0.010861750692129135,
      0.02332407608628273,
      -0.03795685991644859,
      0.05346124619245529,
      -0.056845393031835556,
      -0.025340445339679718,
      0.0541774220764637,
      0.02732907049357891,
      0.06366199254989624,
      0.03159206360578537,
      0.0023449393920600414,
      0.07546263188123703,
      -0.04962974414229393,
      0.05461205914616585,
      -0.011826150119304657,
      0.02471430040895939,
      -0.06677956879138947,
      0.05363403633236885,
      -0.06787221133708954,
      -0.03584142029285431,
      -0.06398226320743561,
      0.11044660955667496,
      0.09251854568719864,
      -0.06291788816452026,
      0.07364029437303543,
      0.04936130717396736,
      -0.022998323664069176,
      0.07846131175756454,
      0.03087257593870163,
      -0.0456736758351326,
      -0.1932852417230606,
      -0.042847465723752975,
      0.048872869461774826,
      0.07243458926677704,
      -0.04941750317811966,
      -0.05409855768084526,
      -0.07060954719781876,
      -0.008807658217847347,
      -0.045267194509506226,
      -0.031408797949552536,
      -0.040596187114715576,
      -0.012860001996159554,
      0.04038052260875702,
      -0.025988418608903885,
      -0.0027116951532661915,
      -0.04005804285407066,
      -0.020098526030778885,
      -0.01311282254755497,
      0.04632451385259628,
      -0.04855891317129135,
      -0.016865139827132225,
      -0.0054948776960372925,
      -0.04069707915186882,
      -0.03112601675093174,
      -0.10670706629753113,
      0.06041444092988968,
      -0.030395029112696648,
      -0.03650280833244324,
      0.057265933603048325,
      -0.007710966281592846,
      0.03695845231413841,
      0.09293422102928162,
      0.059911489486694336,
      0.01247386820614338,
      -0.012326385825872421,
      -0.012656040489673615,
      0.030281497165560722,
      0.07218429446220398,
      0.03710630536079407,
      -0.033499911427497864,
      -0.002793028252199292,
      0.049318134784698486,
      0.07631342858076096,
      -0.004572536796331406,
      0.040430840104818344,
      -0.04250886291265488,
      -0.05989103391766548,
      -0.053344860672950745,
      -0.019816134124994278,
      0.02606269158422947,
      0.02713613025844097,
      -0.04123924672603607,
      -0.044044140726327896,
      -0.03599800169467926,
      0.05958511680364609,
      0.005934033077210188,
      -0.06463226675987244,
      0.07183375209569931,
      0.06192753091454506,
      0.08159558475017548,
      0.0393221490085125,
      -0.03748355805873871,
      0.0006318672094494104,
      -0.06419573724269867,
      -0.006219802889972925,
      0.008409029804170132,
      -0.04682939499616623,
      0.014098276384174824,
      -0.05680873990058899,
      -0.06433632969856262,
      -0.01808885484933853,
      0.03895788639783859,
      0.023106064647436142,
      0.014675955288112164,
      -0.031171241775155067,
      -0.12271364778280258,
      0.0022927040699869394,
      0.037666354328393936,
      -0.06016624718904495,
      -0.0022275217343121767,
      -0.009329767897725105,
      -0.07198554277420044,
      0.031158901751041412,
      0.0006264661205932498,
      0.12099196761846542,
      0.040411874651908875,
      0.01559802982956171,
      -0.007108608726412058,
      -0.007993919774889946,
      -0.04182969406247139,
      0.071079321205616,
      0.017724471166729927,
      0.06376104801893234,
      -0.012300030328333378,
      -0.02842879667878151,
      -0.01954234391450882,
      0.0651688203215599,
      -0.016225459054112434,
      -0.11005603522062302,
      -0.05480191111564636,
      -0.07237904518842697,
      0.04894602298736572,
      -0.020795807242393494,
      -0.035497523844242096,
      0.1524580866098404,
      0.012654793448746204,
      -0.017516786232590675,
      0.030018921941518784,
      -0.0810026228427887,
      0.04805126413702965,
      -0.08416066318750381,
      0.0018830967601388693,
      0.01641860604286194,
      -0.017019176855683327,
      0.011961393058300018,
      0.004775481764227152,
      0.037287890911102295,
      0.028523294255137444,
      0.07390358299016953,
      0.03339388966560364,
      -0.005389816593378782,
      0.018944980576634407,
      0.028474096208810806,
      -0.024825524538755417,
      -0.003758931765332818,
      -0.0164909940212965,
      -0.027173949405550957,
      0.06610685586929321,
      -0.003975325264036655,
      -0.01059351209551096,
      -0.015514674596488476,
      0.013461306691169739,
      -0.0014633388491347432,
      -0.04920602962374687,
      -0.03880361095070839,
      -0.05853050947189331,
      -0.0018640911439433694,
      0.012511290609836578,
      -0.005577603355050087,
      -0.05472864583134651,
      -0.10823756456375122,
      0.012505418621003628,
      -0.018498791381716728,
      -0.010652977973222733,
      0.028933001682162285,
      -0.005749240983277559,
      0.003289502812549472,
      0.03874838724732399,
      -0.04089974984526634,
      0.01529001910239458,
      0.05238483473658562,
      0.013131971471011639,
      0.007829605601727962,
      -0.02882593870162964,
      -0.03663079813122749,
      -0.0983690470457077,
      0.030935905873775482,
      0.05849791690707207,
      0.047304265201091766,
      0.06769932806491852,
      -0.004902592394500971,
      -0.0069931368343532085,
      0.010369621217250824,
      0.05184522643685341,
      -0.017133524641394615,
      0.01584674045443535,
      0.024854738265275955,
      -0.0074398512952029705,
      -0.006591754034161568,
      0.0350470095872879,
      0.050439607352018356,
      -0.058266133069992065,
      -0.05721716210246086,
      0.02551196701824665,
      0.04836021736264229,
      -0.009349984116852283,
      -0.061461467295885086,
      0.03628987446427345,
      0.0899825394153595,
      0.007822990417480469,
      -0.0017121764831244946,
      0.021496927365660667,
      -0.006717441137880087,
      -0.012452303431928158,
      -0.019423220306634903,
      -0.024600014090538025,
      0.05670328810811043,
      0.0074258227832615376,
      0.06984585523605347,
      0.010561578907072544,
      -0.02004777081310749,
      0.0034761347342282534,
      -0.038597602397203445,
      -0.0064148493111133575,
      0.010464383289217949,
      0.00852030050009489,
      0.026979217305779457,
      0.004051200579851866,
      -0.054197560995817184,
      0.06161530688405037,
      -0.021452365443110466,
      0.04494410753250122,
      0.06658908724784851,
      0.012751135043799877,
      0.05110966041684151,
      0.048433296382427216,
      -0.06576009094715118,
      0.0014329329133033752,
      0.03314410522580147,
      -0.02881830930709839,
      -0.00923787709325552,
      -0.00963214784860611,
      -0.0757535994052887,
      -0.0036520955618470907,
      -0.06058371812105179,
      0.02161949686706066,
      0.013485095463693142,
      -0.06421781331300735,
      0.026157714426517487,
      -0.013526112772524357,
      -0.020785566419363022,
      -0.06658335030078888,
      0.0056466469541192055,
      0.0335613451898098,
      0.04574456438422203,
      0.0070198532193899155,
      -0.010736248455941677,
      -0.04537009820342064,
      0.16609323024749756,
      -0.0070046004839241505,
      -0.039396148175001144,
      -0.10871823877096176,
      0.031614381819963455,
      0.09537201374769211,
      -0.06323542445898056,
      -0.03860924020409584,
      -0.03171725198626518,
      -0.0036355520132929087,
      0.04709884896874428,
      0.019306490197777748,
      -0.08933109790086746,
      0.02050456590950489,
      -0.009930528700351715,
      -0.011208117008209229,
      0.04474152624607086,
      -0.02043413184583187,
      -0.10272388160228729,
      -0.051801905035972595,
      0.05822688713669777,
      -0.007884089834988117,
      0.0026998703833669424,
      -0.02130097709596157,
      -0.020170455798506737,
      -0.062052205204963684,
      0.03769147768616676,
      0.025866815820336342,
      0.07652989774942398,
      -0.10878782719373703,
      0.049506571143865585,
      -0.03207732364535332,
      -0.037604015320539474,
      0.038822341710329056,
      -0.0148785300552845,
      -0.025154586881399155,
      0.059135012328624725,
      0.05285485088825226,
      0.06055622175335884,
      -0.03573036193847656,
      0.009121188893914223,
      0.09454363584518433,
      -0.008580819703638554,
      0.045827314257621765,
      -0.030473465099930763,
      -0.0674082487821579,
      0.004823682364076376,
      -0.02616092562675476,
      0.05330060422420502,
      -0.015002557076513767,
      -0.06955253332853317,
      -0.010284148156642914,
      -0.02368713729083538,
      -0.05751102417707443,
      0.032288722693920135,
      -0.026863999664783478,
      -0.03533271700143814,
      -0.004172128159552813,
      -0.015747444704174995,
      0.019524360075592995,
      -0.06912019103765488,
      0.025221234187483788
    ],
    [
      0.006936127785593271,
      0.030244184657931328,
      0.024339869618415833,
      -0.03411703184247017,
      0.008706251159310341,
      -0.027205651625990868,
      -0.05776287242770195,
      0.053618043661117554,
      0.020308436825871468,
      -0.0005361211951822042,
      0.01572490856051445,
      0.013348862528800964,
      -0.02782748080790043,
      0.05003795027732849,
      0.02866262197494507,
      0.03613617643713951,
      -0.07776223868131638,
      0.014448323287069798,
      -0.010316640138626099,
      -0.029858170077204704,
      0.011504090391099453,
      -0.006927233189344406,
      0.041699618101119995,
      -0.07673695683479309,
      0.022797081619501114,
      0.007079282309859991,
      0.007348014973104,
      -0.006986120715737343,
      0.05973361060023308,
      0.0661914125084877,
      0.0332006998360157,
      -0.04053350165486336,
      0.013025901280343533,
      0.02473544143140316,
      0.04732610285282135,
      -0.000855283229611814,
      0.05278695374727249,
      -0.04719072952866554,
      -0.11450133472681046,
      0.006986367981880903,
      -0.03347887843847275,
      -0.021239303052425385,
      0.004913331009447575,
      -0.013789268210530281,
      -0.024947447702288628,
      0.03389570116996765,
      0.025981547310948372,
      0.026035379618406296,
      0.03250991180539131,
      -0.006820176728069782,
      0.027723511680960655,
      -0.09114907681941986,
      0.00035121440305374563,
      -0.05675489082932472,
      -0.029401563107967377,
      0.03636389225721359,
      0.07350265234708786,
      0.051452819257974625,
      0.07428977638483047,
      0.024549581110477448,
      -0.016909118741750717,
      0.037732355296611786,
      -0.012211618013679981,
      0.006677625235170126,
      -0.09627185761928558,
      -0.021485649049282074,
      -0.043290991336107254,
      0.007678989786654711,
      0.0007743529858998954,
      -0.04140939563512802,
      -0.022190839052200317,
      -0.0033258930779993534,
      0.006707345601171255,
      0.0036600648891180754,
      -0.03229092061519623,
      -0.03992448374629021,
      -0.01581634022295475,
      0.07081805914640427,
      -0.039872799068689346,
      0.008773884736001492,
      0.10883697122335434,
      0.12817975878715515,
      0.016670478507876396,
      -0.051756601780653,
      0.0037793961819261312,
      0.00040311162592843175,
      0.057618044316768646,
      0.0031591784209012985,
      0.10052889585494995,
      -0.015078897587954998,
      -0.017107026651501656,
      -0.044780947268009186,
      -0.06688787043094635,
      -0.05937684699892998,
      -0.008438722230494022,
      0.03795977309346199,
      0.038588009774684906,
      -0.010111834853887558,
      0.005128682125359774,
      -0.020396575331687927,
      -0.020345009863376617,
      -0.008305239491164684,
      -0.08981053531169891,
      -0.02869272790849209,
      -0.03887761011719704,
      -0.006726558320224285,
      -0.04378380998969078,
      0.009811981581151485,
      -0.01897106133401394,
      0.02147691510617733,
      0.05699651688337326,
      0.027304712682962418,
      0.013997327536344528,
      0.05509461462497711,
      -0.02357782982289791,
      -0.08661013096570969,
      0.04670807719230652,
      -0.03321013227105141,
      -0.017262648791074753,
      -0.04871375486254692,
      -0.011687184683978558,
      0.032195162028074265,
      0.04354282468557358,
      -0.041827984154224396,
      -0.003097758162766695,
      0.016752392053604126,
      0.0056479983031749725,
      0.08168142288923264,
      0.005321977660059929,
      -0.006821893621236086,
      -0.031840331852436066,
      -0.06729376316070557,
      -0.03111831657588482,
      -0.02031681500375271,
      -0.07188484072685242,
      -0.04152907058596611,
      0.050167281180620193,
      -0.031783197075128555,
      0.004051178228110075,
      0.011984746903181076,
      -0.040316421538591385,
      -0.04007810726761818,
      -0.03235405310988426,
      0.023519212380051613,
      -0.002097328193485737,
      0.0037591508589684963,
      -0.0746583566069603,
      -0.01733977161347866,
      0.018860632553696632,
      -0.00864729005843401,
      -0.013689255341887474,
      -0.02998550795018673,
      0.04655339941382408,
      -0.01357417181134224,
      -0.05997305363416672,
      0.06192397326231003,
      0.020762164145708084,
      -0.04884147271513939,
      -0.1029607504606247,
      0.05264507234096527,
      0.11547800153493881,
      -0.03207450360059738,
      -0.06821572780609131,
      0.056177105754613876,
      -0.06297705322504044,
      -0.028822893276810646,
      0.05767390877008438,
      0.040228478610515594,
      0.015713751316070557,
      0.08537730574607849,
      -0.028045516461133957,
      0.012212314642965794,
      -0.0073441374115645885,
      0.058554135262966156,
      0.012437005527317524,
      -0.04995489865541458,
      0.08643043786287308,
      -0.020084252581000328,
      -0.001330530154518783,
      -0.04243447631597519,
      -0.013614580035209656,
      0.018800368532538414,
      0.0378381684422493,
      -0.02211029827594757,
      0.050177186727523804,
      0.000609259121119976,
      0.0254888366907835,
      0.08495362848043442,
      -0.10115106403827667,
      -0.0199738722294569,
      -0.017957454547286034,
      -0.0016653122147545218,
      0.008723986335098743,
      0.03327501192688942,
      0.07464254647493362,
      0.06084791570901871,
      -0.0895734503865242,
      -0.027569470927119255,
      -0.011090120300650597,
      -0.031620826572179794,
      0.02535286545753479,
      -0.03642433136701584,
      -0.04321464151144028,
      0.07710924744606018,
      0.01635274663567543,
      -0.011081322096288204,
      -0.09007646143436432,
      0.02389787696301937,
      0.029776692390441895,
      -0.10833212733268738,
      -0.023094290867447853,
      0.021328154951334,
      -0.04442061111330986,
      -0.015875205397605896,
      -0.07368717342615128,
      0.04934713989496231,
      0.0004852807323914021,
      0.003463047556579113,
      -0.017236724495887756,
      0.013030297122895718,
      0.01188273448497057,
      0.0017235431587323546,
      -0.03355144336819649,
      -0.09204013645648956,
      0.00028081118944101036,
      0.04288177937269211,
      -0.011874224059283733,
      0.015491167083382607,
      0.02212667651474476,
      -0.07880406081676483,
      0.0380236841738224,
      -0.03633289784193039,
      -0.02287941239774227,
      0.0037721211556345224,
      0.05755966529250145,
      -0.010901323519647121,
      0.052100345492362976,
      -0.0023645213805139065,
      -0.0013142962707206607,
      -0.017376350238919258,
      0.03881107643246651,
      0.07299409061670303,
      -0.07796493172645569,
      -0.03641563653945923,
      0.08937068283557892,
      0.0804891362786293,
      -0.008452805690467358,
      0.02868463285267353,
      0.024291079491376877,
      0.003372411010786891,
      0.038356877863407135,
      0.13041457533836365,
      0.02641194313764572,
      -0.018869010731577873,
      0.06908367574214935,
      0.06651607900857925,
      -0.015581605955958366,
      0.06084047257900238,
      -0.050304803997278214,
      -0.02061324566602707,
      0.02665123902261257,
      0.03919520974159241,
      0.043457482010126114,
      -0.023441266268491745,
      0.03664457052946091,
      0.011870570480823517,
      0.006826342083513737,
      -0.08410557359457016,
      0.020777616649866104,
      -0.016897980123758316,
      -0.009979675523936749,
      0.03711741045117378,
      0.04507071152329445,
      0.06491413712501526,
      0.0022642016410827637,
      -0.007579987868666649,
      0.12520578503608704,
      0.10670828819274902,
      -0.026933936402201653,
      -0.03937133401632309,
      0.015429733321070671,
      -0.09042048454284668,
      0.04246000945568085,
      0.005314149893820286,
      -0.10206872224807739,
      0.0046898843720555305,
      0.032114312052726746,
      -0.03778557479381561,
      0.014991541393101215,
      -0.028892667964100838,
      0.028585145249962807,
      -0.05916549637913704,
      -0.03894786164164543,
      -0.08328469842672348,
      -0.028690271079540253,
      0.0017312192358076572,
      -0.026932280510663986,
      0.05794498324394226,
      -0.07123983651399612,
      -0.05107508599758148,
      -0.051489558070898056,
      0.054463863372802734,
      -0.040535103529691696,
      0.033723168075084686,
      -0.029593082144856453,
      -0.023273983970284462,
      -0.012771200388669968,
      -0.010967159643769264,
      -0.07960180938243866,
      0.03766251355409622,
      0.022231262177228928,
      -0.005902853794395924,
      0.05868105962872505,
      0.014750155620276928,
      0.013648412190377712,
      0.006472362671047449,
      0.03519724681973457,
      -0.00790125411003828,
      -0.024375218898057938,
      -0.013820378109812737,
      0.057557765394449234,
      -0.060744382441043854,
      0.06323324888944626,
      -0.1101023256778717,
      0.01724080555140972,
      -0.17523446679115295,
      -0.041928671300411224,
      -0.018822310492396355,
      -0.016756881028413773,
      -0.006607901304960251,
      0.10838659852743149,
      -0.014892571605741978,
      0.010477314703166485,
      0.007118056062608957,
      0.010971397161483765,
      -0.0698186382651329,
      0.01590781845152378,
      0.008827085606753826,
      0.07783229649066925,
      0.019528372213244438,
      0.025963885709643364,
      0.03311815485358238,
      0.02724660374224186,
      0.07841193675994873,
      0.048360537737607956,
      -0.02571939490735531,
      -0.07711879163980484,
      0.02793799340724945,
      0.052468668669462204,
      0.04875175654888153,
      0.008648564107716084,
      0.02711590565741062,
      -0.06950316578149796,
      0.05743713304400444,
      0.04518359526991844,
      -0.024245060980319977,
      0.011791740544140339,
      0.010481564328074455,
      -0.021751586347818375,
      -0.03259153664112091,
      0.047132886946201324,
      0.07179538160562515,
      -0.03544338047504425,
      0.04178045317530632,
      -0.09190819412469864,
      -0.056469760835170746,
      -0.055065516382455826,
      0.02133896015584469,
      -0.0510002076625824,
      0.06848453730344772,
      0.09897317737340927,
      0.013401597738265991,
      -0.005372301675379276,
      0.011291363276541233,
      0.04450203850865364,
      -0.010878889821469784,
      0.046838440001010895,
      0.037422940135002136,
      0.09222394227981567,
      -0.06341751664876938,
      -0.04841553792357445,
      0.024306533858180046,
      -0.03164676949381828,
      0.018672654405236244,
      0.06656456738710403,
      0.01288844645023346,
      -0.012767343781888485,
      0.06622979789972305,
      0.043773967772722244,
      0.059691812843084335,
      -0.018992098048329353,
      0.09329628944396973,
      0.06424546986818314,
      0.011709902435541153,
      0.057468023151159286,
      -0.010380563326179981,
      -0.029373470693826675,
      -0.08016102015972137,
      0.06693529337644577,
      -0.11355552822351456,
      0.013314667157828808,
      -0.05956706777215004,
      -0.04900943860411644,
      0.041302695870399475,
      0.052838586270809174,
      -0.03389381989836693,
      -0.006383017636835575,
      0.047698378562927246,
      -0.05202750861644745,
      0.07313550263643265,
      -0.033735282719135284,
      0.004971047397702932,
      0.013697389513254166,
      0.040239427238702774,
      -0.03760867938399315,
      0.03799433633685112,
      -0.006914799567312002,
      0.02643960528075695,
      -0.026126384735107422,
      -0.04273248091340065,
      0.0019626382272690535,
      0.05556366592645645,
      0.012805950827896595,
      -0.0024731033481657505,
      0.004972114227712154,
      -0.038960300385951996,
      0.03512553870677948,
      -0.036561090499162674,
      -0.02188032492995262,
      0.018179239705204964,
      -0.059888482093811035,
      -0.006867220159620047,
      0.024227330461144447,
      -0.043835222721099854,
      -0.05067867040634155,
      0.09048862010240555,
      0.06667464226484299,
      0.016949497163295746,
      0.050467848777770996,
      -0.0006136869778856635,
      0.007723388262093067,
      0.09345585852861404,
      -0.03396233916282654,
      -0.06448561698198318,
      0.018494300544261932,
      0.10221142321825027,
      0.008656637743115425,
      0.018826298415660858,
      -0.059113334864377975,
      0.02022745832800865,
      0.0267815962433815,
      0.037148311734199524,
      0.03391740098595619,
      -0.00023513063206337392,
      -0.007225388661026955,
      -0.02455824986100197,
      -0.09257812052965164,
      0.04092438891530037,
      -0.06540142744779587,
      0.02166149578988552,
      -0.0899154543876648,
      -0.013218343257904053,
      -0.04528363421559334,
      -0.007616111543029547,
      -0.04235253483057022,
      -0.035926807671785355,
      0.0057523902505636215,
      -0.05965115502476692,
      0.005502335727214813,
      0.03120860457420349,
      0.06719017028808594,
      -0.007622284349054098,
      0.014260013587772846,
      0.05170517414808273,
      -0.09963301569223404,
      -0.06440199911594391,
      -0.015765082091093063,
      -0.014598000794649124,
      -0.08192584663629532,
      0.03497197851538658,
      0.02134372480213642,
      -0.07161892205476761,
      0.00013839558232575655,
      0.030829131603240967,
      0.05009480193257332,
      -0.03247104212641716,
      -0.010719460435211658,
      -0.04438808560371399,
      -0.05232083052396774,
      -0.06296347081661224,
      0.02162480540573597,
      0.023482680320739746,
      -0.004037854727357626,
      -0.007895959541201591,
      -0.013784159906208515,
      -0.03236907348036766,
      0.03482230380177498,
      -0.003106708638370037,
      0.047337982803583145,
      0.04215424135327339,
      -0.014496324583888054,
      -0.011624090373516083,
      0.07461944967508316
    ],
    [
      0.11732625961303711,
      0.026531221345067024,
      0.02916507050395012,
      0.05259735882282257,
      0.08794070780277252,
      0.0054568517953157425,
      0.01416886504739523,
      -0.060796283185482025,
      -0.04541492834687233,
      0.027282090857625008,
      -0.044339217245578766,
      0.024592120200395584,
      -0.017813868820667267,
      -0.005870962981134653,
      0.08034942299127579,
      0.057548023760318756,
      0.03187347576022148,
      0.002864007605239749,
      -0.04302797093987465,
      -0.04941631853580475,
      -0.06735274940729141,
      -0.0073689124546945095,
      0.03129036724567413,
      0.003620431525632739,
      0.03651313856244087,
      0.0353153795003891,
      -0.05786396190524101,
      0.06579558551311493,
      0.05180547386407852,
      0.03138306736946106,
      0.011264611035585403,
      -0.013406768441200256,
      -0.004903286229819059,
      0.003756453050300479,
      -0.039702970534563065,
      0.0373682975769043,
      -0.03529858589172363,
      -0.01618102379143238,
      0.038250695914030075,
      0.0009786661248654127,
      -0.030660197138786316,
      -0.07771540433168411,
      -0.040301885455846786,
      -0.06965629011392593,
      -0.0039464435540139675,
      0.013379587791860104,
      0.09295857697725296,
      0.010430402122437954,
      0.06956220418214798,
      -0.047130145132541656,
      0.01872154138982296,
      -0.04231297969818115,
      -0.00980429444462061,
      0.03136894479393959,
      0.11043713986873627,
      0.02840031310915947,
      0.077192023396492,
      -0.12137836217880249,
      0.04289337620139122,
      0.024061528965830803,
      0.007403613533824682,
      0.002808172022923827,
      -0.012831405736505985,
      -0.03501773998141289,
      0.025331081822514534,
      -0.017210058867931366,
      -0.020067740231752396,
      -0.010142178274691105,
      -0.035008542239665985,
      -0.012715507298707962,
      -0.041045088320970535,
      -0.02584409900009632,
      -0.039258070290088654,
      0.03086300566792488,
      0.02118713967502117,
      -0.03914505988359451,
      -0.008240900933742523,
      -0.0015600131591781974,
      -0.10197564959526062,
      0.009403915144503117,
      0.02778349258005619,
      0.07701896876096725,
      -0.008889696560800076,
      -0.03362688049674034,
      0.0337006151676178,
      -0.052726421505212784,
      0.008871715515851974,
      0.0007013165741227567,
      0.014636628329753876,
      0.015171753242611885,
      0.06398876011371613,
      -0.05424655228853226,
      0.08125361800193787,
      -0.06151600554585457,
      -0.019496621564030647,
      0.00649689044803381,
      0.04481840878725052,
      0.04057435691356659,
      -0.02190990187227726,
      -0.016944995149970055,
      -0.01773298718035221,
      0.06826658546924591,
      -0.09594723582267761,
      0.003433891339227557,
      -0.009538481011986732,
      0.008033934980630875,
      0.026548445224761963,
      -0.0776563361287117,
      0.05002223327755928,
      0.009659362025558949,
      0.012603921815752983,
      0.033141572028398514,
      0.0005531709757633507,
      0.021284228190779686,
      0.005042374134063721,
      0.0017191532533615828,
      0.014089333824813366,
      -0.008894800208508968,
      -0.021011848002672195,
      0.08581867069005966,
      -0.048980873078107834,
      -0.04198513180017471,
      0.03839005529880524,
      0.09977937489748001,
      0.039798811078071594,
      0.09081341326236725,
      -0.008090357296168804,
      -0.0065091815777122974,
      -0.02332727611064911,
      0.020145822316408157,
      -0.12335912138223648,
      -0.07906050980091095,
      -0.020718788728117943,
      0.006409298162907362,
      -0.023338746279478073,
      0.026919037103652954,
      0.029775425791740417,
      -0.025075700134038925,
      -0.12063316255807877,
      0.08297297358512878,
      0.005565427243709564,
      0.06489880383014679,
      0.06504108756780624,
      0.035222724080085754,
      -0.11975354701280594,
      0.08047181367874146,
      -0.05548166483640671,
      0.07764590531587601,
      0.06594554334878922,
      0.023259706795215607,
      -0.0073426770977675915,
      0.010378413833677769,
      -0.051352329552173615,
      -0.054796382784843445,
      -0.00963215809315443,
      0.0034580014180392027,
      -0.01480365451425314,
      -0.015169255435466766,
      -0.005085533019155264,
      0.02028743177652359,
      -0.002047930611297488,
      -0.00025978870689868927,
      0.0036473970394581556,
      0.04513458162546158,
      -0.03018680214881897,
      -0.02714712731540203,
      -0.004333243239670992,
      -0.0885184109210968,
      -0.04598045349121094,
      0.0015947584761306643,
      0.0033777705393731594,
      -0.032098185271024704,
      -0.08405788242816925,
      0.02853437140583992,
      0.018599430099129677,
      -0.08546412736177444,
      -0.06239614263176918,
      -0.037559643387794495,
      0.06950277090072632,
      -0.1007826030254364,
      -0.011255363002419472,
      0.06588582694530487,
      -0.025068063288927078,
      -0.014853065833449364,
      -0.04003789275884628,
      -0.0114625608548522,
      -0.0032219693530350924,
      -0.08138196915388107,
      -0.01782945729792118,
      0.033091481775045395,
      -0.022572588175535202,
      0.01125239860266447,
      -0.05454571172595024,
      0.08959448337554932,
      -0.03369510918855667,
      -0.05247006565332413,
      0.015696441754698753,
      -0.018031015992164612,
      -0.00746846804395318,
      -0.13891315460205078,
      -0.040816523134708405,
      -0.057551123201847076,
      -0.08090882003307343,
      0.06602451950311661,
      -0.03338441997766495,
      -0.050135161727666855,
      0.04787822067737579,
      0.05214521661400795,
      0.01767767407000065,
      0.08124218881130219,
      0.019928239285945892,
      0.027715429663658142,
      -0.016322065144777298,
      -0.06243705004453659,
      -0.009696152992546558,
      0.04591367021203041,
      0.04498124495148659,
      0.01111224852502346,
      -0.11480429023504257,
      0.026065852493047714,
      -0.030928542837500572,
      -0.0005291146808303893,
      0.04055977985262871,
      -0.09173716604709625,
      0.04584950953722,
      0.07644928246736526,
      -0.08917297422885895,
      -0.01856747642159462,
      0.0015125669306144118,
      -0.00015742453979328275,
      -0.05131728947162628,
      -0.011819609440863132,
      0.015129998326301575,
      -0.015781190246343613,
      0.036844026297330856,
      -0.03447175770998001,
      0.06766556948423386,
      -0.009500036016106606,
      -0.007614250760525465,
      -0.008365198969841003,
      -0.04258736968040466,
      -0.09819289296865463,
      -0.09468558430671692,
      0.033880289644002914,
      0.02825569175183773,
      0.02910112962126732,
      0.012214276939630508,
      -0.006665501743555069,
      -0.05190708860754967,
      -0.009916799142956734,
      0.0019402280449867249,
      0.12397951632738113,
      -0.024885261431336403,
      0.038737133145332336,
      0.10120256245136261,
      0.04343026876449585,
      -0.11502626538276672,
      0.06495217233896255,
      0.05491204559803009,
      0.059785209596157074,
      -0.0796273872256279,
      0.030172938480973244,
      0.053796716034412384,
      0.06556487083435059,
      -0.030515236780047417,
      0.03752261400222778,
      0.025892699137330055,
      -0.03858673572540283,
      0.029123596847057343,
      -0.0003537219308782369,
      0.10709047317504883,
      -0.020942846313118935,
      0.05896604433655739,
      0.012371835298836231,
      0.003415798768401146,
      0.014995292760431767,
      -0.0230325385928154,
      0.05437290668487549,
      -0.05301116406917572,
      0.05999315530061722,
      0.017675066366791725,
      -0.053531959652900696,
      -0.026046207174658775,
      0.02366354875266552,
      -0.019546285271644592,
      0.012295819818973541,
      -0.007706881035119295,
      0.06345898658037186,
      -0.029730435460805893,
      0.13107682764530182,
      0.059982381761074066,
      -0.09775322675704956,
      0.012236136011779308,
      -0.004732774570584297,
      0.03542306274175644,
      -0.06570330262184143,
      0.097042977809906,
      -0.022918017581105232,
      0.04944582283496857,
      -0.02006053924560547,
      0.03118126466870308,
      -0.019730083644390106,
      -0.032615724951028824,
      -0.006838850677013397,
      0.007241227198392153,
      0.012668338604271412,
      -0.06209946796298027,
      -0.03723204508423805,
      0.008406592532992363,
      0.07366059720516205,
      0.04707495868206024,
      0.03866815194487572,
      0.029957979917526245,
      -0.07651877403259277,
      0.034440480172634125,
      0.027042316272854805,
      -0.013561672531068325,
      0.010247341357171535,
      -0.021667061373591423,
      -0.019027838483452797,
      0.0014736591838300228,
      -0.027874797582626343,
      0.03434918448328972,
      -0.02189463935792446,
      0.04627075046300888,
      -0.056607071310281754,
      -0.026556292548775673,
      0.09892985969781876,
      -0.03043285384774208,
      0.11275321245193481,
      -0.03905395418405533,
      -0.03234456479549408,
      -0.02182893455028534,
      -0.04840225353837013,
      -0.009138522669672966,
      -0.014893480576574802,
      -0.00242895376868546,
      0.06529112905263901,
      -0.05867226421833038,
      0.017809858545660973,
      -0.033168911933898926,
      0.008493689820170403,
      0.0053785983473062515,
      -0.012649842537939548,
      0.051618270576000214,
      -0.069338358938694,
      -0.07686075568199158,
      0.09708526730537415,
      -0.02268224209547043,
      0.06524861603975296,
      0.031576838344335556,
      -0.058296918869018555,
      -0.02838181145489216,
      0.02046707086265087,
      -0.06667137891054153,
      0.04544426128268242,
      0.004652432631701231,
      0.06450025737285614,
      -9.533612683298998e-06,
      -0.06674099713563919,
      0.036529790610075,
      0.04392959922552109,
      -0.09458671510219574,
      0.026371916756033897,
      -0.01448958832770586,
      -0.014171293005347252,
      0.04540063068270683,
      -0.019867248833179474,
      0.004680150654166937,
      -0.004918383900076151,
      -0.04120035842061043,
      -0.015613148920238018,
      0.04788047820329666,
      -0.05404108390212059,
      0.027169855311512947,
      0.054930392652750015,
      0.035001978278160095,
      -0.06412386894226074,
      -0.02060922048985958,
      0.03584175184369087,
      -0.04429938271641731,
      -0.004472815897315741,
      -0.004162628203630447,
      0.05163375660777092,
      0.00587261188775301,
      -0.011554180644452572,
      0.009716926142573357,
      -0.031659577041864395,
      0.0400208905339241,
      -0.017217041924595833,
      -0.08968079835176468,
      0.0020764018408954144,
      0.001263024052605033,
      -0.02274065650999546,
      -0.0016194514464586973,
      0.006067149806767702,
      -0.044928256422281265,
      0.02366473525762558,
      -0.03120010532438755,
      -0.08049006760120392,
      -0.0377352312207222,
      -0.015951264649629593,
      -0.00912771001458168,
      -0.04449818283319473,
      0.00020216306438669562,
      -0.07646022737026215,
      0.06136232614517212,
      -0.06472599506378174,
      0.05285888910293579,
      -0.02545396238565445,
      0.027636384591460228,
      -0.007315044291317463,
      -0.06403637677431107,
      0.0026355378795415163,
      -0.08562535792589188,
      -0.05697387456893921,
      0.08054715394973755,
      -0.04926767945289612,
      0.07242902368307114,
      0.01827266998589039,
      -0.014575115405023098,
      0.0015120599418878555,
      -0.05733686313033104,
      0.05197255313396454,
      0.06315543502569199,
      0.025951756164431572,
      0.02998725138604641,
      -0.009363112039864063,
      0.025831038132309914,
      0.0281300637871027,
      0.008616011589765549,
      -0.04463857039809227,
      -0.0054907724261283875,
      -0.06141301989555359,
      0.02841089479625225,
      0.005991154816001654,
      0.009022536687552929,
      -0.01054465863853693,
      -0.04874388501048088,
      0.0495709590613842,
      0.03473532944917679,
      0.016407571732997894,
      -0.05523918196558952,
      -0.013413858599960804,
      0.004065146669745445,
      0.08534397184848785,
      0.010731448419392109,
      -0.014982089400291443,
      -0.00010047371324617416,
      0.041868388652801514,
      0.024472998455166817,
      0.018836310133337975,
      -0.013108046725392342,
      -0.011485571041703224,
      -0.01171436719596386,
      0.037092436105012894,
      0.023471420630812645,
      0.019376017153263092,
      -0.020386721938848495,
      0.0430753156542778,
      -0.08738182485103607,
      0.11464148014783859,
      0.022530831396579742,
      0.0002174045075662434,
      0.03169216215610504,
      -0.024718353524804115,
      -0.002943073632195592,
      -0.03445473313331604,
      0.035774827003479004,
      0.014795401133596897,
      0.11816168576478958,
      0.03001532331109047,
      -0.0029295082204043865,
      -0.010638472624123096,
      0.0206837709993124,
      -0.006974043324589729,
      0.0528203509747982,
      -0.02123394049704075,
      -0.02824290655553341,
      0.04682310298085213,
      0.05201968178153038,
      -0.03844150900840759,
      -0.014733829535543919,
      0.026992371305823326,
      0.010496381670236588,
      0.01919610984623432,
      -0.004442693665623665,
      -0.09099220484495163,
      0.07236266881227493,
      -0.09077626466751099,
      0.0017641499871388078,
      0.007762413937598467,
      0.015517464838922024,
      0.0456463098526001,
      -0.05219295993447304,
      0.0018968724180012941,
      -0.05205469951033592,
      -0.05410990118980408,
      -0.045177266001701355,
      -0.010607108473777771,
      0.036473169922828674,
      0.06056693568825722,
      0.05670544505119324
    ],
    [
      -0.05532460659742355,
      0.0758427157998085,
      -0.02991803176701069,
      -0.0002458785893395543,
      0.027062537148594856,
      -0.03441339358687401,
      -0.011516901664435863,
      0.0006309132440946996,
      -0.06929844617843628,
      0.058921925723552704,
      -0.05321808159351349,
      -0.04777989909052849,
      -0.02650711126625538,
      0.02974514290690422,
      0.059980571269989014,
      0.0025835109408944845,
      -0.06776393204927444,
      0.07161546498537064,
      -0.03559960797429085,
      0.05313244089484215,
      0.025643862783908844,
      0.039597634226083755,
      0.021939462050795555,
      -0.07109473645687103,
      0.012048738077282906,
      0.011797123588621616,
      -0.010563431307673454,
      -0.044119346886873245,
      -0.02655097097158432,
      -0.11488301306962967,
      0.040750902146101,
      0.04223380610346794,
      0.022325070574879646,
      0.0307649914175272,
      -0.0006102494080550969,
      -0.017279693856835365,
      -0.09563188999891281,
      -0.05010261386632919,
      -0.01714446395635605,
      -0.00011872612230945379,
      -0.04628712311387062,
      -0.010939172469079494,
      0.027492720633745193,
      0.05681639537215233,
      -0.014135424979031086,
      -0.08413024991750717,
      0.1172977164387703,
      0.07545140385627747,
      -0.007646732032299042,
      -0.0017723579658195376,
      -0.07696936279535294,
      -0.00689668906852603,
      0.039664376527071,
      0.01799112930893898,
      0.04491068422794342,
      0.04753836989402771,
      0.2166741043329239,
      0.04938577860593796,
      -0.0704672783613205,
      0.05713115632534027,
      -0.05692629888653755,
      0.04938753694295883,
      -0.11732509732246399,
      -0.07535378634929657,
      -0.11694837361574173,
      -0.07581926882266998,
      -0.04711051657795906,
      0.004868316929787397,
      0.057640183717012405,
      -0.010568879544734955,
      -0.07780015468597412,
      -0.05470524728298187,
      -0.00087455176981166,
      4.407734013511799e-05,
      -0.03464324772357941,
      -0.09500826150178909,
      -0.008549011312425137,
      -0.04794447869062424,
      -0.0024191986303776503,
      -0.04476300999522209,
      0.05833927169442177,
      0.044674139469861984,
      -0.01811613328754902,
      0.0052417246624827385,
      0.0011916285147890449,
      -0.023420071229338646,
      0.0030115144327282906,
      -0.00672816252335906,
      0.006015961058437824,
      -0.05140924081206322,
      0.05989449471235275,
      -0.001587343867868185,
      -0.058642104268074036,
      -0.002940006321296096,
      -0.016787009313702583,
      -0.062249332666397095,
      0.05791489779949188,
      0.009979696944355965,
      0.006266212090849876,
      -0.028752636164426804,
      0.016576558351516724,
      0.032374586910009384,
      -0.04408208653330803,
      0.011096283793449402,
      -0.016264677047729492,
      0.05656427517533302,
      0.04888990521430969,
      0.01649719662964344,
      0.011789838783442974,
      -0.016062438488006592,
      0.06529533863067627,
      0.07299420237541199,
      0.05123075470328331,
      -0.02454177476465702,
      -0.0797465592622757,
      -0.0861036404967308,
      0.037331584841012955,
      0.045257069170475006,
      0.05901806801557541,
      0.031620897352695465,
      0.008703700266778469,
      -0.02807449735701084,
      -0.009287265129387379,
      -0.037351805716753006,
      0.04799573868513107,
      -0.021072400733828545,
      0.04922319948673248,
      -0.0012440206483006477,
      -0.023633556440472603,
      0.11013127118349075,
      0.022326545789837837,
      -0.02985185943543911,
      -0.01761011965572834,
      0.042543184012174606,
      -0.0067443749867379665,
      -0.007481566164642572,
      -0.040374353528022766,
      -0.0361638069152832,
      -0.03513343259692192,
      0.01600698195397854,
      0.06781717389822006,
      0.08458732068538666,
      0.0065108295530080795,
      -0.10833442211151123,
      0.07122094184160233,
      -0.027120240032672882,
      -0.06160661205649376,
      -0.025816872715950012,
      -0.04900583252310753,
      -0.05121476575732231,
      -0.018541229888796806,
      0.07898745685815811,
      -0.029729869216680527,
      -0.020471516996622086,
      -0.055487923324108124,
      -0.029152803122997284,
      -0.07947544753551483,
      0.0689573809504509,
      0.0035500209778547287,
      0.0018021687865257263,
      0.08890186250209808,
      -0.04162514582276344,
      0.06717214733362198,
      0.07998933643102646,
      -0.02270035818219185,
      0.00780857726931572,
      -0.06178820878267288,
      -0.04037293419241905,
      0.020784582942724228,
      0.00402012700214982,
      -0.01189899630844593,
      -0.0007922716904431581,
      0.05089636519551277,
      0.13744480907917023,
      -0.0007672779611311853,
      -0.058908239006996155,
      -0.027242787182331085,
      0.04312020167708397,
      -0.03553948551416397,
      0.022722920402884483,
      -0.03776058927178383,
      -0.04941324517130852,
      -0.012461518868803978,
      -0.0564662404358387,
      0.03850127384066582,
      0.08307251334190369,
      0.007997444830834866,
      0.043841324746608734,
      -0.03553929179906845,
      -0.04932291433215141,
      0.06941687315702438,
      0.04407382011413574,
      0.034390728920698166,
      -0.02575576677918434,
      -0.0807800367474556,
      -0.018807804211974144,
      0.01578180305659771,
      -0.011988772079348564,
      0.0011976741952821612,
      0.00031480187317356467,
      -0.04746668413281441,
      0.022055087611079216,
      -0.06495224684476852,
      0.06701239198446274,
      0.0414254404604435,
      -0.014822585508227348,
      -0.011099082417786121,
      0.013827386312186718,
      -0.012720898725092411,
      0.002375510288402438,
      0.07223159074783325,
      0.030733924359083176,
      -0.01367504708468914,
      0.009134510532021523,
      0.013647446408867836,
      -0.04194236174225807,
      -0.013458816334605217,
      0.03602677583694458,
      -0.06331422924995422,
      0.06087246537208557,
      -0.021668313071131706,
      0.037749506533145905,
      -0.04697692021727562,
      -0.11158230155706406,
      0.007312645670026541,
      -0.050855882465839386,
      0.012153641320765018,
      -0.048124760389328,
      0.049492862075567245,
      0.08795179426670074,
      -0.02279236912727356,
      0.04941841587424278,
      -0.09003738313913345,
      0.04866953566670418,
      0.0352741964161396,
      -0.04152991250157356,
      0.023756666108965874,
      0.0313972532749176,
      -0.016498586162924767,
      -0.010237127542495728,
      -0.10812102258205414,
      0.0037873100955039263,
      -0.04271472617983818,
      -0.001120617613196373,
      0.03435618802905083,
      0.01969921961426735,
      -0.0012091492535546422,
      0.044525448232889175,
      -0.03368933126330376,
      -0.0655435174703598,
      -0.002149734180420637,
      -0.009006714448332787,
      0.052909061312675476,
      -0.03533979877829552,
      0.021840190514922142,
      0.019765397533774376,
      -0.05057031661272049,
      -0.029577666893601418,
      -0.04610927775502205,
      -0.009653677232563496,
      0.00797028373926878,
      -0.0010899335611611605,
      -0.029661430045962334,
      -0.00764885451644659,
      0.07648465037345886,
      -0.10287819802761078,
      0.02262580581009388,
      -0.11799617856740952,
      -0.018846074119210243,
      0.021076688542962074,
      0.015208503231406212,
      0.015154472552239895,
      -0.020489240065217018,
      -0.026433240622282028,
      0.04239814728498459,
      -0.013798894360661507,
      0.06618837267160416,
      0.009741944260895252,
      -0.02430153265595436,
      0.11092329770326614,
      0.023513026535511017,
      -0.020842060446739197,
      -0.08779365569353104,
      0.021010609343647957,
      -0.01999589614570141,
      -0.007957166992127895,
      0.036233022809028625,
      -0.033549077808856964,
      -0.016352033242583275,
      0.03785483166575432,
      0.0048583317548036575,
      -0.01804850436747074,
      0.04156583547592163,
      0.035451389849185944,
      -0.013196374289691448,
      0.07416825741529465,
      0.015423553064465523,
      -0.025468364357948303,
      0.06450542062520981,
      -0.01163677591830492,
      0.026307810097932816,
      0.03811350092291832,
      -0.02968381531536579,
      0.02649785950779915,
      -0.020024361088871956,
      -0.056055959314107895,
      -0.06408275663852692,
      0.0027124949265271425,
      0.03265387564897537,
      0.06557218730449677,
      0.015478667803108692,
      -0.017182445153594017,
      -0.032791126519441605,
      0.017443686723709106,
      0.006232622545212507,
      -0.010934790596365929,
      0.030872050672769547,
      -0.012404927983880043,
      -0.043271102011203766,
      0.04239828512072563,
      0.041528452187776566,
      0.005286403000354767,
      -0.0005370096187107265,
      -0.09697664529085159,
      -0.06804259121417999,
      -0.009212002158164978,
      -0.06860680878162384,
      0.011549601331353188,
      0.04622780904173851,
      0.047469694167375565,
      0.010085241869091988,
      -0.031472381204366684,
      -0.09636203944683075,
      0.01883220113813877,
      -0.0328858345746994,
      0.01130953710526228,
      0.04541509971022606,
      0.04344156011939049,
      0.10217852890491486,
      -0.0154497642070055,
      -0.009497551247477531,
      0.0009923884645104408,
      -0.06321399658918381,
      -0.03185790777206421,
      -0.017601992934942245,
      -0.029474027454853058,
      -0.021139388903975487,
      0.04161575064063072,
      -0.10180501639842987,
      -0.06993771344423294,
      0.005309189669787884,
      0.006295720115303993,
      -0.03142160549759865,
      0.038924574851989746,
      -0.06377440690994263,
      -0.022032441571354866,
      -0.04075765237212181,
      0.05174360051751137,
      -0.06442544609308243,
      -0.044122736901044846,
      -0.04189999774098396,
      0.029116572812199593,
      -0.04287300258874893,
      0.024806002154946327,
      0.05353434756398201,
      0.08429033309221268,
      -0.05257507786154747,
      -0.02979523316025734,
      -0.00828535296022892,
      -3.1217961804941297e-05,
      -0.03368673101067543,
      0.009927933104336262,
      0.048251621425151825,
      -0.040805742144584656,
      0.033213816583156586,
      0.04574526846408844,
      0.03005474992096424,
      -0.009763541631400585,
      -0.03690827637910843,
      0.01544104516506195,
      0.027093715965747833,
      -0.024127820506691933,
      -0.013442527502775192,
      0.001480620470829308,
      -0.05401993170380592,
      -0.07656463235616684,
      -0.032119881361722946,
      -0.015088872984051704,
      -0.02356201596558094,
      0.03898049518465996,
      0.0886833593249321,
      0.06700960546731949,
      0.0349779911339283,
      0.046386394649744034,
      0.026515264064073563,
      -0.06279058009386063,
      0.04329274967312813,
      -0.015561680309474468,
      -0.025043388828635216,
      0.01085861586034298,
      -0.0004702395526692271,
      -0.04976031556725502,
      -0.01190414372831583,
      0.046171750873327255,
      -0.060058947652578354,
      -0.029699362814426422,
      -0.0399489589035511,
      0.07501183450222015,
      0.01724528707563877,
      -0.011971073225140572,
      0.0016019500326365232,
      0.0803554356098175,
      0.0637509822845459,
      -0.012689315713942051,
      -0.0016325601609423757,
      -0.005450297147035599,
      0.020808372646570206,
      -0.020960010588169098,
      -0.051262810826301575,
      0.04112482815980911,
      0.026080016046762466,
      -0.02932245470583439,
      0.06874427199363708,
      0.03307798504829407,
      0.008460847660899162,
      0.07125261425971985,
      -0.0154135562479496,
      -0.0652037113904953,
      -0.0029815593734383583,
      -0.08090800791978836,
      0.04653262346982956,
      0.111945740878582,
      -0.08640652894973755,
      -0.0312570258975029,
      -0.0774698331952095,
      -0.05070848390460014,
      0.03662506490945816,
      0.009823279455304146,
      0.017298858612775803,
      0.02290465123951435,
      0.01419562567025423,
      -0.0033159847371280193,
      -0.030892258509993553,
      0.00740845687687397,
      0.04267903417348862,
      0.07455120235681534,
      -0.0479053258895874,
      -0.026536457240581512,
      0.07969468086957932,
      0.011429443024098873,
      0.013359008356928825,
      -0.010931069031357765,
      0.02195795625448227,
      0.038191769272089005,
      -0.018373535946011543,
      -0.12244974821805954,
      0.07966011762619019,
      0.019732670858502388,
      -0.06778080761432648,
      0.045249082148075104,
      -0.022540396079421043,
      0.023193489760160446,
      0.015933893620967865,
      -0.04432402551174164,
      0.11659019440412521,
      0.010300436057150364,
      0.06903991848230362,
      -0.007886994630098343,
      -0.027643322944641113,
      -0.01403976883739233,
      -0.0067599681206047535,
      0.030188534408807755,
      0.016727810725569725,
      0.024813294410705566,
      -0.010865037329494953,
      -0.0488414540886879,
      0.06615637242794037,
      -0.021256204694509506,
      -0.04219407960772514,
      0.01912670210003853,
      -0.02692151628434658,
      0.0785219594836235,
      0.02883639745414257,
      -0.08904004096984863,
      0.06442337483167648,
      0.05338003486394882,
      -0.06048276275396347,
      -0.010145858861505985,
      0.04131060093641281,
      -0.006634970661252737,
      0.003381379647180438,
      0.0282856747508049,
      0.030278578400611877,
      -0.040738482028245926,
      -0.07910727709531784,
      -0.020984191447496414,
      0.03801437094807625,
      -0.01077889371663332,
      0.015604612417519093,
      -0.03453970327973366,
      0.07455665618181229,
      -0.009345673955976963,
      0.029660647734999657
    ],
    [
      0.00442865677177906,
      0.05011804774403572,
      0.060000475496053696,
      -0.04722871258854866,
      0.010066580027341843,
      -0.02248307690024376,
      -0.06393858790397644,
      0.04488350823521614,
      0.029777267947793007,
      0.035366516560316086,
      -0.02228374034166336,
      0.06633154302835464,
      -0.04715730622410774,
      0.005611584056168795,
      -0.08983231335878372,
      0.05144188553094864,
      0.023168936371803284,
      -0.005015562754124403,
      -0.009288002736866474,
      0.022166457027196884,
      0.0730336531996727,
      0.08691141754388809,
      -0.022795764729380608,
      -0.035340920090675354,
      -0.0072308792732656,
      -0.030539482831954956,
      0.01275413017719984,
      -0.004947932902723551,
      -0.0298458319157362,
      0.025870459154248238,
      0.029082519933581352,
      -0.07577329874038696,
      -0.047719359397888184,
      0.026006324216723442,
      -0.06906002759933472,
      -0.12030749022960663,
      0.07636355608701706,
      -0.031221330165863037,
      0.0632682740688324,
      0.03033514693379402,
      -0.09079216420650482,
      -0.017572948709130287,
      0.05200515314936638,
      0.03659074380993843,
      0.023939672857522964,
      0.025187449529767036,
      0.0911295935511589,
      -0.0010725978063419461,
      0.031289659440517426,
      0.009206278249621391,
      -0.023168180137872696,
      0.033952392637729645,
      -0.00911541935056448,
      -0.03794567659497261,
      0.01897183060646057,
      -0.012343683280050755,
      0.11728337407112122,
      0.0387701578438282,
      0.045406319200992584,
      -0.10392598062753677,
      -0.0032746545039117336,
      0.00852903164923191,
      -0.05032949522137642,
      0.000332439347403124,
      -0.03412078693509102,
      -0.009545627050101757,
      -0.02521081641316414,
      -0.0035626436583697796,
      -0.00016700004925951362,
      -0.06744362413883209,
      0.046861954033374786,
      0.020828556269407272,
      -0.04056800156831741,
      0.058544568717479706,
      0.06216428428888321,
      -0.01592281647026539,
      0.00594598101451993,
      0.06477361172437668,
      0.012143321335315704,
      0.00761969480663538,
      0.06354667246341705,
      0.052180733531713486,
      -0.08018161356449127,
      0.05751388147473335,
      0.020061681047081947,
      -0.01695065014064312,
      -0.017197445034980774,
      -0.005466578993946314,
      0.004918696824461222,
      -0.05889929085969925,
      -0.06407317519187927,
      -0.020824965089559555,
      0.013683541677892208,
      -0.015227274969220161,
      -0.004877985455095768,
      -0.06299667060375214,
      -0.02852453850209713,
      0.029890254139900208,
      -0.05884189158678055,
      -0.08611197769641876,
      0.08936961740255356,
      0.023358236998319626,
      0.04418278858065605,
      -0.023812437430024147,
      -0.011604883708059788,
      0.03836502134799957,
      0.10087066143751144,
      -0.03586675226688385,
      -0.044071633368730545,
      0.006374503951519728,
      -0.048041824251413345,
      0.12878534197807312,
      0.05014294385910034,
      -0.0476713590323925,
      -0.08494766056537628,
      -0.002303764456883073,
      0.021044107154011726,
      -0.020215407013893127,
      -0.09125906974077225,
      0.016689524054527283,
      -0.001740951556712389,
      -0.0580943338572979,
      0.019466226920485497,
      -0.050946786999702454,
      -0.019466761499643326,
      0.03661685809493065,
      -0.021141359582543373,
      0.09907995909452438,
      0.0032098283991217613,
      -0.04517848789691925,
      -0.07915696501731873,
      -0.009196227416396141,
      0.0030136413406580687,
      0.023844584822654724,
      -0.05752843990921974,
      0.015123503282666206,
      -0.008238996379077435,
      0.005579514894634485,
      -0.001749935676343739,
      0.007076271343976259,
      0.053308844566345215,
      -0.052288904786109924,
      -0.047531429678201675,
      -0.09131812304258347,
      0.041994575411081314,
      -0.0011648613726720214,
      -0.016920901834964752,
      -0.018951738253235817,
      -0.010335762985050678,
      0.05056728050112724,
      0.024093974381685257,
      0.04478423297405243,
      0.05447636544704437,
      -0.03704856336116791,
      0.059223465621471405,
      0.014396196231245995,
      -0.04915400594472885,
      0.028642931953072548,
      0.017842456698417664,
      -0.04329498112201691,
      0.028852004557847977,
      0.05418442189693451,
      0.00937302689999342,
      0.03551389276981354,
      -0.0075757731683552265,
      -0.028307044878602028,
      -0.03521370142698288,
      0.023587439209222794,
      -0.019794078543782234,
      -0.03320099785923958,
      -0.03060794621706009,
      -0.0709858164191246,
      -0.014503104612231255,
      0.07871664315462112,
      0.08014480024576187,
      0.053703341633081436,
      -0.019512919709086418,
      -0.03476399928331375,
      -0.018729934468865395,
      -0.02212059497833252,
      -0.037333302199840546,
      -0.06600084900856018,
      -0.011253939010202885,
      -0.05396212264895439,
      -0.015343002043664455,
      0.027319252490997314,
      -0.05321889743208885,
      0.0488862544298172,
      -0.060877878218889236,
      -0.014884554781019688,
      0.05101789906620979,
      0.045401133596897125,
      0.025540413334965706,
      -0.08407052606344223,
      -0.04152814298868179,
      -0.01043124683201313,
      0.03950607031583786,
      0.05766400694847107,
      -0.04737856611609459,
      -0.042099472135305405,
      -0.015064331702888012,
      0.045337215065956116,
      -0.018937312066555023,
      -0.029988259077072144,
      -0.05381021648645401,
      -0.0674203634262085,
      0.016259828582406044,
      0.00045190431410446763,
      0.10559286922216415,
      -0.04069617763161659,
      0.051151376217603683,
      0.03952727094292641,
      -0.03840510919690132,
      0.05122115835547447,
      -0.013106215745210648,
      -0.041540831327438354,
      -0.04632498696446419,
      0.047079768031835556,
      -0.001259222743101418,
      -0.0046749538742005825,
      -0.02062457986176014,
      0.006899039726704359,
      0.05352846533060074,
      -0.07814013212919235,
      -0.002346114255487919,
      -0.01210364792495966,
      -0.09396849572658539,
      -0.07965201139450073,
      0.013194561004638672,
      -0.01391037181019783,
      0.03257663920521736,
      -0.008439823985099792,
      -0.09920505434274673,
      0.02102397382259369,
      0.015406791120767593,
      0.06721895188093185,
      -0.023710649460554123,
      0.020612921565771103,
      -0.11195336282253265,
      0.020513853058218956,
      0.017356235533952713,
      -0.010312835685908794,
      -0.11974481493234634,
      0.053381506353616714,
      0.011352736502885818,
      0.050712212920188904,
      -0.025232233107089996,
      -0.08279839158058167,
      -0.014476409181952477,
      -0.010642425157129765,
      0.06531697511672974,
      -0.006658990867435932,
      0.01590564288198948,
      0.028066420927643776,
      0.004300025757402182,
      -0.03437411040067673,
      -0.048126015812158585,
      0.006714946590363979,
      0.0008155405521392822,
      -0.017790766432881355,
      -0.015428369864821434,
      -0.010197039693593979,
      -0.050518911331892014,
      -0.015468870289623737,
      0.0346621610224247,
      -0.04579198732972145,
      0.03120448626577854,
      0.061857253313064575,
      0.03837478160858154,
      -0.02367851883172989,
      0.03403373807668686,
      0.015110965818166733,
      0.033588044345378876,
      -0.01884443126618862,
      -0.019742799922823906,
      0.05884554982185364,
      0.06429140269756317,
      0.06525227427482605,
      -0.023740163072943687,
      0.00848621316254139,
      -0.071459099650383,
      -0.030200552195310593,
      0.03971094265580177,
      -0.028497662395238876,
      -0.006609756965190172,
      0.0011529445182532072,
      0.026762986555695534,
      0.04343324899673462,
      0.01048281416296959,
      -0.005366656929254532,
      -0.017244670540094376,
      -0.04667116701602936,
      -0.010966995730996132,
      0.02084340713918209,
      0.0187128484249115,
      -0.04391402006149292,
      -0.020960917696356773,
      0.00972726009786129,
      -0.03536944463849068,
      -0.005462782457470894,
      -0.05535988509654999,
      0.06914849579334259,
      0.029639899730682373,
      0.033513620495796204,
      -0.04820545017719269,
      -0.007568864617496729,
      0.14495447278022766,
      0.0683857798576355,
      0.018445780500769615,
      -0.03186839073896408,
      -0.024493882432579994,
      0.0034787810873240232,
      0.09852668642997742,
      0.006802320014685392,
      -0.024548986926674843,
      -0.011476939544081688,
      -0.0007529148715548217,
      -0.02032468095421791,
      0.03977157920598984,
      0.0012257453054189682,
      0.05988360196352005,
      -0.01983645185828209,
      0.03465975075960159,
      -0.00804420281201601,
      0.01769649237394333,
      -0.047504011541604996,
      0.005048918537795544,
      0.062265463173389435,
      0.09664801508188248,
      0.008275505155324936,
      -0.03174543008208275,
      0.0037424354813992977,
      0.04256841167807579,
      -0.028133630752563477,
      -0.004715961869806051,
      0.03761947527527809,
      -0.005145120434463024,
      -0.023245783522725105,
      0.013161425478756428,
      -0.07446184754371643,
      -0.024356506764888763,
      0.15795724093914032,
      -0.025157837197184563,
      0.07743220776319504,
      0.01649419777095318,
      0.016461824998259544,
      -0.013099273666739464,
      -0.048229824751615524,
      0.03410409390926361,
      0.06756164133548737,
      -0.019268343225121498,
      -0.019519338384270668,
      0.045930154621601105,
      -0.04691841080784798,
      -0.021785294637084007,
      0.028738612309098244,
      -0.036307740956544876,
      -0.03035440854728222,
      -0.008727310225367546,
      0.11326870322227478,
      -0.026190057396888733,
      -0.03497685119509697,
      0.0006854338571429253,
      0.01935078576207161,
      -0.03296397253870964,
      -0.0006276528583839536,
      0.016977820545434952,
      0.06871745735406876,
      -0.0003613120352383703,
      0.008004584349691868,
      0.0554782897233963,
      -0.029334781691432,
      0.0008710148395039141,
      -0.05126272886991501,
      0.006489675957709551,
      0.10882770270109177,
      0.005494306329637766,
      0.03613956645131111,
      0.0004496171895880252,
      0.049754802137613297,
      -0.03373422846198082,
      -0.03079916350543499,
      -0.0980117917060852,
      0.20792150497436523,
      0.05851072445511818,
      0.005313596688210964,
      0.023736469447612762,
      -0.0008657244616188109,
      -0.021056976169347763,
      -0.09346583485603333,
      0.006988561246544123,
      0.050867628306150436,
      0.01331563200801611,
      -0.05166025087237358,
      0.02143045701086521,
      -0.041313037276268005,
      -0.030003570020198822,
      -0.014455254189670086,
      -0.09135069698095322,
      0.037061017006635666,
      0.011897128075361252,
      -0.013019041158258915,
      -0.007487534545361996,
      -0.07841821759939194,
      -0.056292660534381866,
      0.012278234586119652,
      0.025093991309404373,
      0.06935962289571762,
      0.020778583362698555,
      0.01739603467285633,
      0.015997856855392456,
      0.009070279076695442,
      -0.049391794949769974,
      -0.025036543607711792,
      -0.0357624776661396,
      0.03173920512199402,
      0.054465316236019135,
      0.01354339811950922,
      0.04055490717291832,
      -0.04299274459481239,
      0.024903619661927223,
      0.03917233645915985,
      -0.05198553204536438,
      0.010426304303109646,
      -0.017422465607523918,
      0.022615959867835045,
      -0.030519170686602592,
      -0.10705187171697617,
      0.04570290818810463,
      0.06843718141317368,
      0.024470454081892967,
      0.0217642392963171,
      0.003985878545790911,
      -0.033800721168518066,
      -0.008193537592887878,
      -0.007837802171707153,
      0.0423610545694828,
      0.057242266833782196,
      0.004174233879894018,
      0.00650915177538991,
      -0.09024327248334885,
      0.06050870940089226,
      0.019234774634242058,
      0.058356866240501404,
      0.0033805209677666426,
      0.054166994988918304,
      0.04940001294016838,
      -0.047928109765052795,
      0.0046493662521243095,
      -0.09679989516735077,
      0.004372883588075638,
      0.017913533374667168,
      0.03796446695923805,
      0.036187998950481415,
      0.0003915092092938721,
      -0.09243247658014297,
      0.08095472306013107,
      -0.0796458050608635,
      -0.13856102526187897,
      0.041076529771089554,
      0.01358975749462843,
      -0.04015890881419182,
      0.0007852446287870407,
      -0.005987966433167458,
      0.008038793690502644,
      -0.0358109287917614,
      -0.01831904798746109,
      0.03281879797577858,
      0.042005494236946106,
      0.023749705404043198,
      0.003964993171393871,
      -0.003298328258097172,
      0.03830929845571518,
      -0.03208339214324951,
      -0.03094536066055298,
      0.05337616056203842,
      0.03803451359272003,
      -0.04047560319304466,
      0.009879442863166332,
      0.01287136971950531,
      -0.10278908163309097,
      0.051717739552259445,
      0.030424388125538826,
      0.03310874104499817,
      0.00047974829794839025,
      0.008795456029474735,
      0.0037861389573663473,
      0.014338284730911255,
      0.013810725882649422,
      -0.14203675091266632,
      -0.008098271675407887,
      0.035660166293382645,
      0.06204930320382118,
      -0.04481043666601181,
      0.021603349596261978,
      -0.06038704887032509,
      -0.019952872768044472,
      0.01143824402242899,
      0.036452002823352814,
      0.04156792536377907,
      0.018731029704213142,
      -0.02891937829554081,
      0.01719707064330578
    ],
    [
      -0.12819968163967133,
      0.07801686227321625,
      0.03707282245159149,
      0.0405561625957489,
      0.004803782794624567,
      -0.0400240533053875,
      -0.12173396348953247,
      0.030693747103214264,
      0.048559606075286865,
      0.08420393615961075,
      0.02902938984334469,
      -0.0965794175863266,
      -0.04474153369665146,
      0.02323085069656372,
      0.03098209761083126,
      0.0007716470281593502,
      -0.020967992022633553,
      0.004344886168837547,
      -0.02944278158247471,
      -0.05409139022231102,
      -0.061191625893116,
      0.013017987832427025,
      -0.03271773084998131,
      -0.003562775906175375,
      0.007152439560741186,
      0.005617817398160696,
      -0.05460953339934349,
      -0.007515491917729378,
      -0.041937455534935,
      0.002079487545415759,
      0.019553199410438538,
      0.006672323681414127,
      0.05985763669013977,
      -0.013131404295563698,
      0.04750226438045502,
      0.001853328663855791,
      -0.02217472717165947,
      -0.00480573670938611,
      -0.08116196095943451,
      0.004915026016533375,
      0.02639622427523136,
      -0.001768115907907486,
      0.06531155854463577,
      0.11547624319791794,
      0.011811818927526474,
      0.024860722944140434,
      -0.0011972683714702725,
      -0.03595896437764168,
      0.058619093149900436,
      -0.002470203209668398,
      0.06378300487995148,
      0.01155445072799921,
      -0.014396008104085922,
      0.02553534135222435,
      -0.04658012464642525,
      0.02300211973488331,
      0.05463916435837746,
      0.06580148637294769,
      -0.015838267281651497,
      0.009547892957925797,
      0.054487064480781555,
      -0.016640251502394676,
      -0.0380924753844738,
      0.02217905968427658,
      -0.017896607518196106,
      -0.00019005121430382133,
      0.0002653161936905235,
      0.021241681650280952,
      0.026143990457057953,
      0.014355408027768135,
      -0.04047926515340805,
      -0.04899203032255173,
      0.006081834901124239,
      -0.013926222920417786,
      -0.016225416213274002,
      -0.05179983004927635,
      -0.008572971448302269,
      0.009976716712117195,
      0.06130979582667351,
      0.03934543952345848,
      -0.07029963284730911,
      -0.003993702586740255,
      0.051241617649793625,
      0.04986511170864105,
      0.03579919412732124,
      -0.024512145668268204,
      0.013636886142194271,
      0.04860983416438103,
      0.012787450104951859,
      -0.10807449370622635,
      -0.04314551502466202,
      -0.006500870455056429,
      0.06758541613817215,
      0.016007067635655403,
      -0.02833395078778267,
      0.0626082643866539,
      -0.08041439950466156,
      -0.010589908808469772,
      0.01265318039804697,
      -0.043312884867191315,
      0.0208126213401556,
      -0.07318776845932007,
      -0.007033927831798792,
      0.022438563406467438,
      0.014478754252195358,
      0.06823915243148804,
      -0.03389827907085419,
      -0.024129902943968773,
      0.020305784419178963,
      0.04240843281149864,
      0.009903176687657833,
      -0.0627555102109909,
      0.026493895798921585,
      0.029277093708515167,
      -0.05125964805483818,
      -0.008529790677130222,
      0.049615807831287384,
      -0.07570597529411316,
      0.0451861210167408,
      0.029057780280709267,
      -0.0645323321223259,
      0.06400548666715622,
      0.025015676394104958,
      0.06146244332194328,
      0.052712418138980865,
      0.017544064670801163,
      -0.10165078938007355,
      0.039065126329660416,
      0.02355303429067135,
      0.006893483456224203,
      0.006685212254524231,
      0.040200985968112946,
      0.022686857730150223,
      0.009940041229128838,
      0.08009110391139984,
      -0.02232608012855053,
      -0.06904914975166321,
      0.0008971237693913281,
      -0.0410383976995945,
      0.01311702374368906,
      0.003012498375028372,
      -0.027315108105540276,
      -0.06784625351428986,
      0.08387422561645508,
      0.00356831937097013,
      0.04763167351484299,
      -0.03450347110629082,
      -0.03489425405859947,
      -0.003518792102113366,
      -0.07290095835924149,
      0.06051410362124443,
      0.09374034404754639,
      -0.024026622995734215,
      -0.06322957575321198,
      0.06752531230449677,
      -0.09103933721780777,
      0.03755725547671318,
      -0.0921924039721489,
      -0.021391713991761208,
      0.054017577320337296,
      0.022066539153456688,
      -0.02166929841041565,
      0.03501155599951744,
      -0.02690184861421585,
      0.070360466837883,
      -0.060500044375658035,
      0.038438353687524796,
      -0.03458060696721077,
      -0.07721804082393646,
      -0.06493888050317764,
      -0.013373884372413158,
      0.026126638054847717,
      -0.005405082367360592,
      0.052523184567689896,
      0.019847434014081955,
      -0.009931344538927078,
      -0.0030018198303878307,
      0.00791699718683958,
      -0.007365130353718996,
      0.04189610108733177,
      -0.003523788880556822,
      0.07994179427623749,
      -0.028711704537272453,
      0.07052876800298691,
      0.06350918114185333,
      0.0038732802495360374,
      0.11605885624885559,
      0.0023910030722618103,
      -0.03044694848358631,
      -0.04455596208572388,
      -0.015521314926445484,
      -0.005004527512937784,
      -0.08296652138233185,
      -0.0137303052470088,
      -0.01886117458343506,
      0.02255343832075596,
      -0.009046475403010845,
      0.0074740988202393055,
      0.06562402844429016,
      -0.13704904913902283,
      -0.017356717959046364,
      -0.04455080255866051,
      0.030661480501294136,
      0.028448117896914482,
      -0.00804388802498579,
      -0.002138917101547122,
      0.026325490325689316,
      -0.014228454791009426,
      0.020374231040477753,
      -0.05108846351504326,
      0.016073962673544884,
      -0.02611621469259262,
      -0.030558733269572258,
      -0.023153362795710564,
      0.06743690371513367,
      -0.06623448431491852,
      -0.06167998164892197,
      0.008128924295306206,
      0.012609793804585934,
      -0.0571068674325943,
      -0.0381820872426033,
      0.02948901243507862,
      0.05822112411260605,
      0.05267924442887306,
      -0.06929624825716019,
      -0.019360966980457306,
      -0.0013762469170615077,
      -0.010921871289610863,
      -0.05746100842952728,
      -0.02140696346759796,
      0.028954431414604187,
      -0.05465584248304367,
      0.05278380215167999,
      -0.019922465085983276,
      -0.0514221116900444,
      0.020508868619799614,
      -0.027883857488632202,
      -0.0030476204119622707,
      0.022825166583061218,
      -0.044273439794778824,
      -0.00728813698515296,
      0.011892629787325859,
      -0.010034714825451374,
      -0.008862415328621864,
      -0.103239506483078,
      0.05077969282865524,
      -0.003375656669959426,
      0.040557026863098145,
      0.04439840465784073,
      0.025495953857898712,
      0.052621617913246155,
      0.03808896988630295,
      -0.03234874829649925,
      -0.022971905767917633,
      0.08635406196117401,
      -0.011976533569395542,
      -0.04971277341246605,
      0.009455597959458828,
      -0.07406704872846603,
      -0.02006169967353344,
      -0.032208263874053955,
      0.0021289584692567587,
      0.005488258320838213,
      -0.01862110197544098,
      -0.03484019264578819,
      0.03210863471031189,
      -0.06133784353733063,
      -0.02359435334801674,
      -0.03781536594033241,
      0.012172033078968525,
      0.07666224241256714,
      -0.013586537912487984,
      -0.0037267194129526615,
      0.02817985974252224,
      0.0768534317612648,
      -0.03865605220198631,
      -0.053709350526332855,
      0.004637465346604586,
      -0.01200744230300188,
      0.06484320759773254,
      -0.01033752504736185,
      -0.027747947722673416,
      0.014075024984776974,
      0.0041067348793148994,
      0.008309886790812016,
      0.06988686323165894,
      0.05913955718278885,
      -0.07543671131134033,
      -0.03354679420590401,
      0.05205318704247475,
      0.13807684183120728,
      0.001873423927463591,
      -0.006231099367141724,
      0.05238449200987816,
      -0.04940050467848778,
      -0.030592087656259537,
      -0.03412191569805145,
      -0.05310266092419624,
      -0.004320011008530855,
      -0.06130428612232208,
      -0.04404112324118614,
      -0.053801119327545166,
      0.07089947164058685,
      0.04524953290820122,
      -0.0390925332903862,
      0.001315021188929677,
      -0.03375647962093353,
      0.035557013005018234,
      -0.11299958825111389,
      0.019683677703142166,
      -0.0020577406976372004,
      0.07186353951692581,
      0.09964822977781296,
      -0.0008661248721182346,
      0.07751501351594925,
      -0.055065304040908813,
      0.002981060417369008,
      -0.10181091725826263,
      0.0009321479592472315,
      0.040557846426963806,
      -0.02459944784641266,
      -0.005794132594019175,
      0.0541849210858345,
      -0.09575685858726501,
      0.02544410340487957,
      -0.001467673690058291,
      -0.05857882276177406,
      0.008362451568245888,
      0.003610745770856738,
      0.040588438510894775,
      0.018920231610536575,
      -0.05340391397476196,
      -0.08852783590555191,
      0.009407912380993366,
      -0.05811413377523422,
      -0.05766695365309715,
      0.1108919009566307,
      -0.07046433538198471,
      -0.014810420572757721,
      -0.020950807258486748,
      -0.03296515345573425,
      0.06924000382423401,
      0.03184473514556885,
      0.028174161911010742,
      0.009424975141882896,
      0.035000190138816833,
      -0.02112647145986557,
      0.06486451625823975,
      0.00612073065713048,
      -0.045170146971940994,
      0.0715799331665039,
      0.04317568242549896,
      0.019896959885954857,
      0.016974275931715965,
      0.010929304175078869,
      0.05966142192482948,
      -0.0022405548952519894,
      0.07683112472295761,
      -0.07196587324142456,
      0.017204979434609413,
      0.06852348148822784,
      -0.008601248264312744,
      -0.01539129950106144,
      0.058169856667518616,
      0.006418459117412567,
      0.058878276497125626,
      -0.022602789103984833,
      -0.012009509839117527,
      -0.0002459860988892615,
      -0.06346670538187027,
      -0.001825162791647017,
      0.0010943025117740035,
      -0.10763836652040482,
      -0.03694324940443039,
      -0.057064786553382874,
      0.10581313818693161,
      -0.022717585787177086,
      -0.039525456726551056,
      0.028761016204953194,
      0.042652029544115067,
      -0.03049103170633316,
      -0.009277469478547573,
      0.0400923416018486,
      0.08824556320905685,
      -0.005515190307050943,
      -0.015295267105102539,
      0.09420371055603027,
      0.00944012962281704,
      0.013457833789288998,
      -0.02204928547143936,
      0.03393193334341049,
      0.008252191357314587,
      0.022519031539559364,
      0.07709194719791412,
      0.04693707078695297,
      0.039467863738536835,
      0.03080649860203266,
      -0.08799828588962555,
      0.024927861988544464,
      0.028895024210214615,
      -0.06613625586032867,
      -0.0202691201120615,
      -0.015985330566763878,
      -0.05530038848519325,
      -0.07243452966213226,
      0.0008992222719825804,
      -0.010685518383979797,
      0.02969648875296116,
      -0.021308518946170807,
      -0.00748709961771965,
      -0.058314789086580276,
      -0.02229488082230091,
      0.05524450168013573,
      -0.047600314021110535,
      0.0025229400489479303,
      0.08897551149129868,
      -0.057030241936445236,
      -0.018181849271059036,
      -0.05251353234052658,
      0.025756102055311203,
      -0.018457768484950066,
      0.01300005428493023,
      0.00939208921045065,
      0.04455699026584625,
      -0.00988768320530653,
      -0.02923496812582016,
      -0.0666394829750061,
      -0.044432904571294785,
      -0.032538942992687225,
      -0.005379143636673689,
      0.021350495517253876,
      0.00608055992051959,
      0.014695161022245884,
      -0.004850050900131464,
      -0.06316780298948288,
      -0.0058199018239974976,
      0.06577908247709274,
      0.03492148220539093,
      0.0038158714305609465,
      0.014080433174967766,
      0.06708407402038574,
      0.013839174062013626,
      -0.01393564697355032,
      0.05078214779496193,
      -0.07499837875366211,
      0.03909324109554291,
      -0.0063247219659388065,
      0.023682095110416412,
      -0.07325910031795502,
      -0.03965696319937706,
      0.06835266947746277,
      0.04606004059314728,
      -0.04803518205881119,
      0.03239608183503151,
      -0.005175706464797258,
      -0.04181982949376106,
      -0.03582865744829178,
      0.014257417991757393,
      -0.0056594363413751125,
      -0.024942809715867043,
      -0.07556267827749252,
      -0.006841826252639294,
      0.029085710644721985,
      0.023608827963471413,
      -0.023550285026431084,
      -0.022436687722802162,
      -0.09604430198669434,
      -0.13396412134170532,
      -0.02347388304769993,
      0.016049273312091827,
      -0.1210719421505928,
      -0.09705376625061035,
      0.00975049752742052,
      0.058930929750204086,
      0.007537385448813438,
      0.0232021976262331,
      0.013181639835238457,
      0.018049586564302444,
      0.03290282562375069,
      0.05004036799073219,
      0.06420980393886566,
      -0.043795011937618256,
      -0.01912970468401909,
      -0.014384291134774685,
      -0.0025062833447009325,
      0.006416331045329571,
      0.0064651016145944595,
      -0.04413963854312897,
      0.06944583356380463,
      -0.08942093700170517,
      -0.019849956035614014,
      0.02471216581761837,
      0.008400268852710724,
      0.042607732117176056,
      0.0047604795545339584,
      -0.17099392414093018,
      -0.04254453256726265,
      -0.0016206380678340793,
      0.06984713673591614,
      0.028631607070565224,
      -0.02112695574760437,
      -0.0012221719371154904,
      0.012551805935800076
    ],
    [
      0.0021728137508034706,
      -0.023180000483989716,
      -0.010055924765765667,
      0.02669697441160679,
      0.07811377942562103,
      -0.025313450023531914,
      0.014530627988278866,
      -0.016906190663576126,
      0.014500297605991364,
      0.007244741544127464,
      0.005143173970282078,
      -0.08508877456188202,
      -0.017320381477475166,
      0.025140533223748207,
      -0.006978548597544432,
      0.05584239214658737,
      -0.023843536153435707,
      0.080237977206707,
      0.01101456955075264,
      0.10954990983009338,
      0.02248779684305191,
      0.030910274013876915,
      -0.056956611573696136,
      0.04071053862571716,
      0.02761133387684822,
      0.02428186498582363,
      0.016773086041212082,
      -0.10286896675825119,
      -0.10419929027557373,
      0.024954795837402344,
      0.06579197198152542,
      -0.024860404431819916,
      -0.01146367471665144,
      -0.06167204678058624,
      0.05445254594087601,
      -0.024487964808940887,
      -0.0773886889219284,
      0.00829702615737915,
      -0.03946114331483841,
      0.019144412130117416,
      -0.04855121672153473,
      -0.02430788241326809,
      0.0578942634165287,
      -0.029713008552789688,
      0.010110953822731972,
      0.02740364708006382,
      -0.02203494682908058,
      -0.066522017121315,
      0.019190335646271706,
      -0.020259125158190727,
      0.06560613960027695,
      -0.006960080936551094,
      -0.05658409371972084,
      -0.004973204806447029,
      0.021266335621476173,
      0.023935355246067047,
      -0.0012523120967671275,
      0.008620877750217915,
      0.09159842133522034,
      0.029463354498147964,
      0.008662472479045391,
      0.010062341578304768,
      -0.002109208144247532,
      0.0647522509098053,
      0.08849605917930603,
      -0.038776323199272156,
      0.011002796702086926,
      0.010512574575841427,
      0.03594332933425903,
      -0.007809118367731571,
      -0.0551428496837616,
      0.00044863420771434903,
      -0.007897335104644299,
      -0.02983006089925766,
      0.09741879254579544,
      -0.06725781410932541,
      0.01581498421728611,
      -0.04242337495088577,
      0.05350479111075401,
      0.02531903237104416,
      -0.02326018176972866,
      0.09238254278898239,
      0.02128312923014164,
      -0.0075384099036455154,
      0.04295063763856888,
      -0.06864924728870392,
      -0.016805848106741905,
      -0.007111967075616121,
      0.019333921372890472,
      -0.07307014614343643,
      0.00768123148009181,
      0.025752777233719826,
      0.01606205850839615,
      0.013458707369863987,
      -0.027109544724225998,
      -0.08087435364723206,
      0.017472267150878906,
      0.02107987180352211,
      0.01629900000989437,
      -0.04933835193514824,
      -0.0101590845733881,
      -0.008281529881060123,
      0.03722582757472992,
      -0.04729275032877922,
      0.026463432237505913,
      0.04777098074555397,
      0.03987390175461769,
      0.017831476405262947,
      -0.049650054425001144,
      -0.0258010420948267,
      0.058657120913267136,
      -0.03095969371497631,
      -0.024051479995250702,
      -0.04480123147368431,
      0.004831922706216574,
      -0.030750101432204247,
      0.004993321839720011,
      -0.049788158386945724,
      -0.023096071556210518,
      -0.00439790403470397,
      0.03952755033969879,
      0.027674442157149315,
      0.07448083162307739,
      0.004630016162991524,
      0.011832226999104023,
      0.07344961911439896,
      -0.03963987156748772,
      0.023458532989025116,
      0.030536746606230736,
      0.06784991919994354,
      0.03747238591313362,
      -0.07931165397167206,
      -0.03977275267243385,
      0.02365044876933098,
      0.015624430030584335,
      0.018953202292323112,
      -0.025854680687189102,
      0.03402329981327057,
      -0.01223735325038433,
      0.06950751692056656,
      0.014821975491940975,
      0.011019157245755196,
      0.00697356415912509,
      0.010914317332208157,
      0.09591897577047348,
      -0.07861723005771637,
      0.026274845004081726,
      0.006084256339818239,
      0.020725851878523827,
      -0.08910565078258514,
      -0.09043094515800476,
      0.022308215498924255,
      0.0375935435295105,
      -0.050560444593429565,
      -0.02188480831682682,
      0.041585419327020645,
      -0.016273971647024155,
      -0.058635011315345764,
      0.05034929886460304,
      -0.047201722860336304,
      0.03512178361415863,
      -0.01652500405907631,
      -0.021088743582367897,
      -0.014895451255142689,
      0.02206609584391117,
      -0.027999117970466614,
      0.04229804873466492,
      -0.024953551590442657,
      0.023391513153910637,
      -0.023007391020655632,
      0.05159532651305199,
      -0.04390273988246918,
      0.05439627170562744,
      0.010783828794956207,
      -0.046831242740154266,
      0.022391023114323616,
      -0.036442432552576065,
      0.04605509340763092,
      -0.07316853106021881,
      0.05505917966365814,
      -0.013148542493581772,
      0.02316134236752987,
      0.03270604833960533,
      -0.018411127850413322,
      0.08364155888557434,
      0.007725390139967203,
      0.011734617874026299,
      0.04038771241903305,
      0.05807957425713539,
      0.10846936702728271,
      0.02582755871117115,
      0.01597665436565876,
      0.016553549095988274,
      0.01673862896859646,
      -0.02596646547317505,
      0.0046623628586530685,
      -0.03975173458456993,
      0.021976735442876816,
      -0.03301209956407547,
      -0.05176014080643654,
      0.016612067818641663,
      0.036349933594465256,
      -0.0038447745610028505,
      -0.026559142395853996,
      0.03545073792338371,
      -0.037775442004203796,
      0.11133811622858047,
      -0.026205163449048996,
      0.08725257962942123,
      -0.059397824108600616,
      -0.03783498704433441,
      -0.009160332381725311,
      -0.00014587253099307418,
      -0.06373713910579681,
      -0.031844791024923325,
      -0.030807975679636,
      -0.0058362362906336784,
      0.05432486534118652,
      0.03203815594315529,
      0.04578226059675217,
      0.04477560147643089,
      -0.017475372180342674,
      0.10178373754024506,
      -0.010484997183084488,
      -0.049691688269376755,
      -0.006837439723312855,
      -0.03851747140288353,
      -0.05385994911193848,
      -0.026884572580456734,
      0.04719257727265358,
      -0.05855635926127434,
      -0.05712941288948059,
      -0.007633447181433439,
      0.01087306160479784,
      0.04604566842317581,
      0.013013584539294243,
      5.554555536946282e-05,
      0.07639732211828232,
      -0.009177695028483868,
      -0.08469940721988678,
      -0.017672918736934662,
      -0.03640495240688324,
      -0.004906660411506891,
      -0.002953630406409502,
      -0.0075852773152291775,
      0.06402048468589783,
      -0.06223307549953461,
      0.11158915609121323,
      0.12621155381202698,
      -0.03377363458275795,
      0.04033748805522919,
      0.0538204088807106,
      -0.027639927342534065,
      0.033983759582042694,
      0.04886462166905403,
      -0.00488323112949729,
      0.014167903922498226,
      -0.10433870553970337,
      0.038692303001880646,
      -0.035404473543167114,
      0.09269122034311295,
      0.02297728881239891,
      0.01316046342253685,
      0.0047907051630318165,
      0.0793595165014267,
      0.03438741713762283,
      -0.015524200163781643,
      0.015950947999954224,
      0.04449302330613136,
      0.0199624914675951,
      -0.025614485144615173,
      -0.016518600285053253,
      0.028578683733940125,
      -0.034234434366226196,
      0.013905190862715244,
      0.03706279769539833,
      0.05530955642461777,
      0.07600376754999161,
      0.024126814678311348,
      0.019101496785879135,
      0.07147441804409027,
      0.04440873861312866,
      -0.06197405606508255,
      -0.00675627775490284,
      0.04457126557826996,
      -0.01696687377989292,
      0.061363883316516876,
      -0.05701914429664612,
      -0.10835669189691544,
      -0.057217229157686234,
      0.007943344302475452,
      -0.003916748333722353,
      0.06302567571401596,
      -0.009906807914376259,
      0.028624534606933594,
      -0.0191178061068058,
      -0.0011864200932905078,
      0.08315281569957733,
      0.12140552699565887,
      0.01122575905174017,
      0.04072043299674988,
      0.05362104997038841,
      0.10326077789068222,
      0.054898157715797424,
      0.03690505027770996,
      0.016187693923711777,
      -0.05463270843029022,
      0.09703812003135681,
      -0.041315723210573196,
      0.03234712406992912,
      -0.022065117955207825,
      0.06691218912601471,
      -0.01622290350496769,
      -0.013242420740425587,
      0.05322396010160446,
      -0.025690287351608276,
      0.027933575212955475,
      0.0007935191388241947,
      -0.010320451110601425,
      -0.0698811337351799,
      -0.05960850045084953,
      0.01623738370835781,
      -0.022045226767659187,
      -0.014574535191059113,
      -0.05733809247612953,
      0.012895735912024975,
      0.02811625227332115,
      -0.0656898021697998,
      0.07963549345731735,
      0.03282894939184189,
      -0.04000920429825783,
      -0.06231292709708214,
      -0.01796625927090645,
      -0.01579221524298191,
      -0.06403983384370804,
      -0.031202983111143112,
      0.015375004149973392,
      0.014470257796347141,
      -0.0006085358909331262,
      -0.018192043527960777,
      0.02253190241754055,
      0.004541309084743261,
      -0.03479844331741333,
      -0.03403496742248535,
      -0.02526356466114521,
      -0.037010665982961655,
      0.04193199798464775,
      0.009620828554034233,
      0.0231988076120615,
      -0.05910409614443779,
      0.07257100194692612,
      -0.02040296606719494,
      0.003835164476186037,
      0.04459085315465927,
      -0.10876083374023438,
      -0.004675308708101511,
      0.031246690079569817,
      -0.023535575717687607,
      -0.05797595903277397,
      -0.005017133429646492,
      -0.04509202763438225,
      0.020621227100491524,
      0.09646153450012207,
      0.015667898580431938,
      -0.03820407763123512,
      -0.00296821934171021,
      -0.06168821081519127,
      0.08000965416431427,
      0.014440112747251987,
      0.07916340231895447,
      0.030657513067126274,
      -0.042310260236263275,
      -0.10266847908496857,
      -0.05595443770289421,
      -0.020470311865210533,
      0.019823266193270683,
      0.030314035713672638,
      -0.054388415068387985,
      0.026703301817178726,
      -0.049222182482481,
      -0.027415847405791283,
      0.06252307444810867,
      0.033429376780986786,
      -0.0359811894595623,
      0.008478501811623573,
      0.06280072033405304,
      -0.015075390227138996,
      0.023909257724881172,
      -0.053597431629896164,
      0.047379445284605026,
      -0.03311196342110634,
      -0.014511406421661377,
      0.0807272344827652,
      -0.05116705596446991,
      -0.034270431846380234,
      0.04822537675499916,
      0.052372321486473083,
      -0.01372497994452715,
      -0.029979629442095757,
      -0.013444819487631321,
      -0.09734159708023071,
      -0.011521166190505028,
      -0.054545141756534576,
      -0.046981342136859894,
      0.09134270250797272,
      -0.017226891592144966,
      -0.03963853418827057,
      0.04176543653011322,
      0.011444038711488247,
      -0.10842499136924744,
      0.017260108143091202,
      -0.007881239987909794,
      0.01910010166466236,
      0.046178728342056274,
      0.03871365636587143,
      -0.04123891144990921,
      0.014460389502346516,
      -0.03740394860506058,
      -0.034479301422834396,
      -0.0030163247138261795,
      0.007833963260054588,
      -0.06405548006296158,
      -0.02667057514190674,
      0.018623562529683113,
      -0.05237274616956711,
      0.04398442059755325,
      -0.008624766021966934,
      0.06810123473405838,
      0.06434489041566849,
      -0.06841647624969482,
      -0.006615553516894579,
      0.010348303243517876,
      0.025152314454317093,
      0.02373596839606762,
      -0.004210931248962879,
      0.054422833025455475,
      0.005508994683623314,
      -0.11156614124774933,
      0.04148999601602554,
      0.05228623002767563,
      0.045910947024822235,
      0.08172094076871872,
      -0.06353829801082611,
      0.0025079648476094007,
      0.05221042409539223,
      0.05987309291958809,
      -0.04527351260185242,
      0.07212023437023163,
      -0.011728869751095772,
      -0.015055160969495773,
      0.027839090675115585,
      -0.048703137785196304,
      0.04230803996324539,
      -0.04632172733545303,
      -0.059713736176490784,
      -0.0049079786986112595,
      -0.058731451630592346,
      -0.059536419808864594,
      0.011248230002820492,
      0.015851033851504326,
      0.034601543098688126,
      0.0034788898192346096,
      0.04396936297416687,
      0.014980458654463291,
      0.0062484429217875,
      0.02132924646139145,
      -0.028064170852303505,
      -0.017751723527908325,
      -0.0050567868165671825,
      0.028772909194231033,
      0.060499157756567,
      -0.009630022570490837,
      0.05198385566473007,
      -0.08422613888978958,
      -0.04569504037499428,
      0.051684681326150894,
      -0.023419788107275963,
      0.0019760695286095142,
      0.0679800808429718,
      0.06862281262874603,
      -0.02885972335934639,
      0.02949591539800167,
      -0.017272191122174263,
      0.031280022114515305,
      -0.02508455328643322,
      -0.06418870389461517,
      -0.015375349670648575,
      -0.02629796974360943,
      -0.03079039603471756,
      -0.12337864935398102,
      -0.028178641572594643,
      0.005304889287799597,
      -0.00922333262860775,
      0.07098228484392166,
      0.05772452801465988,
      -0.002417359733954072,
      -0.07054147869348526,
      0.013152983970940113,
      0.006011119112372398,
      -0.01478500571101904,
      0.022822009399533272,
      0.06871101260185242,
      -0.06754010915756226
    ],
    [
      -0.01287991926074028,
      -0.06880295276641846,
      -0.09850721806287766,
      0.010460962541401386,
      0.012470082379877567,
      0.028354013338685036,
      -0.026858976110816002,
      -0.00519210658967495,
      -0.05037249997258186,
      0.017786428332328796,
      -0.021734772250056267,
      0.05313010513782501,
      -0.0297335684299469,
      0.04210878536105156,
      -0.03750915080308914,
      0.07069370150566101,
      -0.030842868611216545,
      -0.04969318211078644,
      -0.017279500141739845,
      -0.032143060117959976,
      0.0023184597957879305,
      -0.02343219704926014,
      -0.037683699280023575,
      -0.024047693237662315,
      0.0036143010947853327,
      0.00791544746607542,
      0.015165815129876137,
      -0.004755879752337933,
      -0.03667368367314339,
      -0.055559054017066956,
      0.03531942889094353,
      0.03688481077551842,
      -0.0021266238763928413,
      0.008087892085313797,
      -0.011830305680632591,
      0.020419510081410408,
      -0.021582286804914474,
      -0.05526763200759888,
      0.032296717166900635,
      -0.023495960980653763,
      0.10024900734424591,
      0.006995341274887323,
      0.016505643725395203,
      0.042857274413108826,
      -0.04744710773229599,
      0.008914082311093807,
      0.10199066251516342,
      -0.041984252631664276,
      -0.0387827567756176,
      0.07859227806329727,
      0.022373760119080544,
      -0.04161791130900383,
      0.018620522692799568,
      -0.04799065738916397,
      0.0007836422882974148,
      0.03164437785744667,
      0.05244404450058937,
      -0.0010098074562847614,
      0.024800365790724754,
      -0.08145608752965927,
      0.09263070672750473,
      0.020825792104005814,
      -0.007308871019631624,
      0.01190823782235384,
      0.047375112771987915,
      0.040493328124284744,
      -0.003867930267006159,
      -0.01884523592889309,
      0.0016679094405844808,
      -0.06231561303138733,
      -0.07545893639326096,
      0.03644857928156853,
      -0.05288613215088844,
      -0.08064917474985123,
      0.0395784005522728,
      -0.03054743818938732,
      0.021251628175377846,
      -0.014015767723321915,
      0.0009717177599668503,
      -0.017132310196757317,
      -0.006151325535029173,
      -0.03694004565477371,
      0.04612776264548302,
      -0.023626139387488365,
      -0.05611428618431091,
      -0.027081795036792755,
      0.04979316517710686,
      -0.015310398302972317,
      0.08037801831960678,
      0.03785672411322594,
      0.026189574971795082,
      -0.012169964611530304,
      -0.007302515208721161,
      0.014870759099721909,
      -0.0270218625664711,
      0.013742945156991482,
      0.023748653009533882,
      -0.0035945726558566093,
      -0.0033517691772431135,
      0.03589160367846489,
      0.0762813463807106,
      -0.02980247512459755,
      -0.04627206549048424,
      0.06256368011236191,
      -0.02885984256863594,
      0.10762050002813339,
      -0.00023662811145186424,
      -0.02030000276863575,
      -0.1364080011844635,
      0.0192006416618824,
      0.09742800146341324,
      -0.03313023969531059,
      -0.06094792112708092,
      -0.00036604143679142,
      0.0010288635967299342,
      0.05588638037443161,
      0.041476283222436905,
      -0.060252491384744644,
      -0.009296854957938194,
      0.02439393289387226,
      0.027989016845822334,
      -0.06165432557463646,
      9.411195060238242e-05,
      0.013691063970327377,
      -0.0008341001812368631,
      0.1071426048874855,
      0.033885467797517776,
      0.039932575076818466,
      0.049551334232091904,
      0.01503199152648449,
      0.08415716886520386,
      0.0034790169447660446,
      -0.035997696220874786,
      0.053315017372369766,
      -0.04113442450761795,
      0.01921018399298191,
      -0.048940274864435196,
      0.0454888716340065,
      -0.02012890949845314,
      0.08075353503227234,
      0.006117719225585461,
      -0.0004029176488984376,
      -0.005651446059346199,
      -0.01789228990674019,
      0.06977058202028275,
      -0.08016558736562729,
      -0.06664075702428818,
      -0.013401377014815807,
      0.02830243483185768,
      -0.01231259386986494,
      0.011390933766961098,
      0.06006854027509689,
      -0.07610322535037994,
      -0.05608035624027252,
      -0.013961901888251305,
      0.08537574857473373,
      0.032386552542448044,
      -0.017930876463651657,
      0.015427783131599426,
      -0.06462850421667099,
      0.038013894110918045,
      0.01857607811689377,
      -0.042823925614356995,
      0.017270566895604134,
      0.02670295536518097,
      -0.022370265796780586,
      0.009004781022667885,
      0.03801129013299942,
      0.06125328689813614,
      -0.047202542424201965,
      -0.0055127739906311035,
      0.09628546237945557,
      -0.07858692109584808,
      0.018673526123166084,
      -0.05818423256278038,
      0.014675331301987171,
      0.038885172456502914,
      -0.012919782660901546,
      -0.060807205736637115,
      0.003240051446482539,
      -0.012323598377406597,
      0.024523140862584114,
      -0.0839635580778122,
      -0.052951373159885406,
      0.016926372423768044,
      0.006475864443928003,
      -0.009887468069791794,
      0.007728260476142168,
      -0.0672270804643631,
      0.0533631406724453,
      -0.00416626688092947,
      -0.009368084371089935,
      0.052977390587329865,
      0.0321446992456913,
      -0.0041621383279562,
      -0.01062876358628273,
      0.004854798782616854,
      -0.04339689388871193,
      0.04038389399647713,
      -0.0410580150783062,
      -0.01282389834523201,
      -0.043636810034513474,
      -0.000576973834540695,
      0.0538179911673069,
      -0.02029765211045742,
      -0.0694998949766159,
      -0.018754618242383003,
      -0.0555107556283474,
      0.02959328703582287,
      0.023354656994342804,
      0.012102573178708553,
      -0.027361799031496048,
      0.0325811542570591,
      -0.07913734018802643,
      -0.017761385068297386,
      0.045014213770627975,
      -0.09847672283649445,
      0.044201139360666275,
      -0.03252368047833443,
      0.051426373422145844,
      -0.02307238057255745,
      -0.038418110460042953,
      0.031582534313201904,
      0.0070269457064569,
      -0.016110947355628014,
      0.0362698957324028,
      0.0032036942429840565,
      -0.008178038522601128,
      -0.004988366737961769,
      -0.049256738275289536,
      -0.000881110958289355,
      -0.07885973155498505,
      -0.016937479376792908,
      0.041645701974630356,
      -0.08459240943193436,
      0.02289191074669361,
      -0.06780614703893661,
      -0.02269165776669979,
      -0.027391599491238594,
      -0.03830593824386597,
      -0.0327475368976593,
      0.03255068510770798,
      -0.07024384289979935,
      0.06274823099374771,
      0.010017354041337967,
      -0.013325738720595837,
      0.07343786209821701,
      0.009009508416056633,
      0.06126030907034874,
      -0.09253297001123428,
      0.014176945202052593,
      0.02367851883172989,
      0.016599271446466446,
      -0.010252139531075954,
      0.0014704676577821374,
      -0.01613347791135311,
      -0.035112034529447556,
      0.06324948370456696,
      0.013228987343609333,
      -0.0397673062980175,
      -0.040027644485235214,
      -0.014876147732138634,
      0.014005103148519993,
      -0.07263007014989853,
      -0.01010067667812109,
      0.1362902820110321,
      -0.001506215543486178,
      0.0212332122027874,
      -0.007261279039084911,
      -0.016801662743091583,
      0.08626114577054977,
      0.04719173535704613,
      -0.013853062875568867,
      0.01903214119374752,
      -0.015087041072547436,
      0.0488128736615181,
      0.07723921537399292,
      0.03636910393834114,
      -0.0023285469505935907,
      -0.004732206463813782,
      0.09560349583625793,
      -0.01966836117208004,
      0.03448808565735817,
      -0.0034869832452386618,
      0.07331287115812302,
      -0.0838865265250206,
      -0.09390552341938019,
      -0.012224520556628704,
      0.0457514263689518,
      -0.031199920922517776,
      -0.02655288204550743,
      0.06372150033712387,
      -0.026921333745121956,
      -0.05020797625184059,
      -0.022534308955073357,
      -0.007834640331566334,
      0.06867549568414688,
      0.024990973994135857,
      0.008833589032292366,
      -0.02622496709227562,
      -0.020860038697719574,
      -0.08834946155548096,
      -0.0397174209356308,
      0.03802848234772682,
      0.018790462985634804,
      -0.01261634286493063,
      0.06822020560503006,
      0.0609259195625782,
      -0.005378304980695248,
      0.0166395865380764,
      -0.08075092732906342,
      -0.01618957333266735,
      0.02260955609381199,
      -0.07917232811450958,
      0.03476770594716072,
      0.04229959473013878,
      0.054184284061193466,
      0.019254982471466064,
      0.014663937501609325,
      0.10647185891866684,
      0.013079589232802391,
      -0.02202400006353855,
      0.08681224286556244,
      -0.0497138574719429,
      -0.022436609491705894,
      -0.016326315701007843,
      0.10865721851587296,
      -0.002631699899211526,
      -0.10638151317834854,
      -0.021366707980632782,
      -0.024758892133831978,
      0.009191007353365421,
      0.07220884412527084,
      0.06504634022712708,
      0.014768868684768677,
      0.05621575564146042,
      -0.10902097821235657,
      0.007495593745261431,
      0.006456990726292133,
      -0.02339033968746662,
      -0.05202453210949898,
      0.04041515290737152,
      0.003770856186747551,
      0.08164697885513306,
      0.07471201568841934,
      -0.019051775336265564,
      -0.10726354271173477,
      0.08299368619918823,
      0.08599825948476791,
      -0.04352010786533356,
      0.05143054947257042,
      0.043863650411367416,
      0.0036464144941419363,
      0.014267226681113243,
      -0.03459624573588371,
      -0.02912832237780094,
      0.018632683902978897,
      0.019908133894205093,
      -0.0045821149833500385,
      0.08481019735336304,
      -0.01780584640800953,
      0.03115220181643963,
      0.06067608669400215,
      -0.0025720198173075914,
      -0.08863767981529236,
      0.02852702885866165,
      -0.03557877615094185,
      -0.0866343155503273,
      -0.0343073271214962,
      0.02056342549622059,
      -0.11029668152332306,
      -0.0503045879304409,
      0.03175724297761917,
      -0.012361387722194195,
      -0.011096181347966194,
      -0.012130052782595158,
      -0.048356011509895325,
      -0.028851142153143883,
      0.10693385452032089,
      0.009188888594508171,
      -0.05087816342711449,
      -0.02258068323135376,
      0.05664088577032089,
      0.048287540674209595,
      0.0065762801095843315,
      0.025874346494674683,
      -0.015502954833209515,
      -0.04852496087551117,
      -0.06742869317531586,
      0.041500043123960495,
      -0.01808185502886772,
      0.050644319504499435,
      -0.029362110421061516,
      -0.0287263672798872,
      0.05165458470582962,
      -0.07336293160915375,
      0.019892621785402298,
      -0.019861046224832535,
      0.015349485911428928,
      0.043973807245492935,
      0.07734444737434387,
      -0.06355554610490799,
      -0.045584846287965775,
      -0.07814819365739822,
      0.07308509945869446,
      -0.018673593178391457,
      -0.021570734679698944,
      -0.05123819783329964,
      0.021812038496136665,
      -0.05410677567124367,
      0.02394288033246994,
      0.00600432138890028,
      -0.09070176631212234,
      -0.0318579338490963,
      -0.08783750981092453,
      0.007488323375582695,
      0.006665073335170746,
      -0.02115301787853241,
      0.07731249183416367,
      0.020710306242108345,
      -0.054575953632593155,
      -0.015353729948401451,
      -0.03738817572593689,
      -0.113735631108284,
      -0.007066552061587572,
      0.030920036137104034,
      0.002574638230726123,
      -0.011455493047833443,
      -0.07938046008348465,
      0.007832991890609264,
      0.08082810044288635,
      0.025381527841091156,
      0.020008085295557976,
      0.0741548091173172,
      -0.02245822735130787,
      -0.004174421075731516,
      -0.03733353316783905,
      0.07661649584770203,
      -0.02527463249862194,
      -0.05018702894449234,
      -0.09357499331235886,
      -0.03473936766386032,
      0.01501779817044735,
      0.06424569338560104,
      0.032133180648088455,
      0.015349993482232094,
      0.05632021650671959,
      0.03448103740811348,
      0.0657048299908638,
      0.03389373794198036,
      -0.031135089695453644,
      0.06185914948582649,
      -0.01221948117017746,
      -0.031064212322235107,
      0.009938498958945274,
      0.014422539621591568,
      -0.006598139647394419,
      -0.0492095947265625,
      0.015108726918697357,
      0.008018160238862038,
      -0.011577374301850796,
      -0.024710513651371002,
      -0.030257554724812508,
      -0.0020735058933496475,
      -0.07466603070497513,
      -0.06622250378131866,
      -0.013990934006869793,
      -0.0423099622130394,
      0.04408425837755203,
      -0.063428595662117,
      -0.06205691024661064,
      -0.03638627380132675,
      0.02777017466723919,
      -0.05016059800982475,
      -0.08679062873125076,
      0.027626516297459602,
      7.457841275027022e-05,
      0.06855565309524536,
      0.0496562123298645,
      0.022218501195311546,
      0.07223495095968246,
      0.02903081849217415,
      -0.09552635997533798,
      0.08201049268245697,
      0.02136777713894844,
      -0.015334212221205235,
      -0.009359617717564106,
      -0.08374322205781937,
      0.06312204152345657,
      0.030166365206241608,
      -0.02200457453727722,
      -0.028914062306284904,
      -0.07237748056650162,
      0.03326822817325592,
      0.02104451134800911,
      0.0699915811419487,
      0.06240889057517052,
      -0.05581122636795044,
      0.03751993551850319,
      -0.0172088872641325,
      0.04121733456850052,
      0.023621026426553726,
      0.1048971563577652
    ],
    [
      0.1080176830291748,
      0.04197564348578453,
      -0.017527585849165916,
      0.04241684824228287,
      -0.17796704173088074,
      0.03298700600862503,
      -0.020370688289403915,
      0.006372994743287563,
      -0.028533345088362694,
      0.0013242157874628901,
      0.051171306520700455,
      0.017223099246621132,
      -0.020678147673606873,
      0.007224384229630232,
      0.030410708859562874,
      0.09312998503446579,
      0.04606318846344948,
      -0.001219992176629603,
      -0.006479768548160791,
      -0.0013646007282659411,
      -0.03400130942463875,
      -0.01155681349337101,
      -0.01968366838991642,
      0.002462108852341771,
      0.10271595418453217,
      0.05337291583418846,
      -0.003963672090321779,
      -0.12145721167325974,
      -0.0058188363909721375,
      -0.02499154396355152,
      0.02345004491508007,
      0.050834815949201584,
      0.0038122530095279217,
      0.011417101137340069,
      -0.00965201761573553,
      0.018769772723317146,
      -0.04870269075036049,
      -0.029739534482359886,
      -0.08422340452671051,
      -0.01448760088533163,
      -0.0232708603143692,
      -0.08850748836994171,
      -0.029642075300216675,
      -0.046031780540943146,
      0.03868437930941582,
      0.010970919393002987,
      0.033575389534235,
      0.043286509811878204,
      0.04535607248544693,
      -0.07988403737545013,
      -0.011990474537014961,
      0.07101424038410187,
      -0.04557529464364052,
      -0.0018121536122635007,
      0.014266669750213623,
      -0.021938381716609,
      -0.009262694045901299,
      -0.003520458471029997,
      0.05835956707596779,
      0.04289987310767174,
      0.06649822741746902,
      0.09279131889343262,
      0.05139832943677902,
      0.016474755480885506,
      -0.030687619000673294,
      -0.0819438025355339,
      -0.006826066877692938,
      0.0025053084827959538,
      -0.021922597661614418,
      -0.044711533933877945,
      -0.008596512489020824,
      -0.03974639251828194,
      -0.01388683170080185,
      0.09290163964033127,
      0.057340338826179504,
      0.013601685874164104,
      0.07064493745565414,
      0.056524988263845444,
      0.00023398431949317455,
      0.03627320006489754,
      -0.03365837410092354,
      0.01800895668566227,
      0.00500190956518054,
      0.05812333524227142,
      0.020357372239232063,
      -0.030548781156539917,
      -0.009486481547355652,
      -0.025367094203829765,
      0.03885230794548988,
      0.03227689117193222,
      -0.06962545216083527,
      -0.01435566321015358,
      0.047896888107061386,
      0.03546398878097534,
      -0.06926900893449783,
      -0.07239872962236404,
      0.011847278103232384,
      0.05000792071223259,
      0.05950243026018143,
      0.03477888181805611,
      0.015142234042286873,
      0.060704462230205536,
      0.02334480732679367,
      -0.01546630822122097,
      -0.03348403424024582,
      0.048127055168151855,
      0.06487009674310684,
      -0.08516328781843185,
      -0.0031967898830771446,
      -0.039334047585725784,
      0.030842792242765427,
      0.009435895830392838,
      -0.03176087886095047,
      0.018892863765358925,
      0.030712779611349106,
      -0.004998579155653715,
      0.0374702550470829,
      -0.049813542515039444,
      0.029670560732483864,
      -0.09322970360517502,
      -0.0019085691310465336,
      -0.03854546695947647,
      0.028889773413538933,
      0.07714176923036575,
      -0.006659870035946369,
      0.049503881484270096,
      0.038981810212135315,
      -0.028203744441270828,
      -0.015637224540114403,
      0.021642673760652542,
      0.07814536243677139,
      -0.05420297756791115,
      0.016512185335159302,
      -0.07667146623134613,
      0.04635879397392273,
      -0.027098797261714935,
      -0.04204433038830757,
      0.017140952870249748,
      0.0758676528930664,
      0.02519841678440571,
      0.03879271447658539,
      0.00470950361341238,
      -0.02170785516500473,
      0.04049936681985855,
      0.08385354280471802,
      0.021715495735406876,
      0.017951050773262978,
      -0.014068317599594593,
      0.028963711112737656,
      -0.028943968936800957,
      -0.08077176660299301,
      0.06545860320329666,
      0.030631376430392265,
      -0.024851582944393158,
      0.03862227872014046,
      0.01902729831635952,
      0.02116645872592926,
      -0.05222862586379051,
      0.04920855537056923,
      -0.02192392200231552,
      0.13380013406276703,
      0.03629124164581299,
      0.012559115886688232,
      0.0011160074500367045,
      0.04844830185174942,
      -0.0007864832878112793,
      -0.035693150013685226,
      -0.07434435933828354,
      0.02743757702410221,
      0.014436679892241955,
      0.017690150067210197,
      0.0137264933437109,
      -0.04578610509634018,
      0.04459979385137558,
      -0.01712547056376934,
      -0.0368182398378849,
      0.020707853138446808,
      0.06604354828596115,
      -0.021024461835622787,
      -0.04462781548500061,
      -0.05131644755601883,
      0.0648474395275116,
      0.06202360987663269,
      0.01415477879345417,
      0.05421331524848938,
      0.03461785614490509,
      0.004687929525971413,
      0.05258137360215187,
      -0.016180211678147316,
      0.0384674072265625,
      -0.03295379504561424,
      0.04800267890095711,
      0.059032686054706573,
      0.0050833444111049175,
      -0.04154801368713379,
      -0.052452392876148224,
      0.015918400138616562,
      0.05532796308398247,
      0.03867710754275322,
      -0.09678258746862411,
      -0.005013053305447102,
      -0.008927679620683193,
      -0.00935056060552597,
      0.00941610336303711,
      -0.0560358501970768,
      -0.03186191990971565,
      -0.011351640336215496,
      -0.07194254547357559,
      0.04128887504339218,
      -0.0459764264523983,
      -0.04429469257593155,
      -0.10403949022293091,
      0.0707976296544075,
      0.017048304900527,
      0.034149110317230225,
      0.019410179927945137,
      -0.003321841126307845,
      -0.06758617609739304,
      0.06856983155012131,
      -0.01609128899872303,
      0.05530603602528572,
      -0.04782642424106598,
      0.026084404438734055,
      -0.03584820032119751,
      -0.01789543218910694,
      -0.037544988095760345,
      -0.07503368705511093,
      0.02042365074157715,
      0.0024964704643934965,
      -0.04957544803619385,
      -0.059859808534383774,
      -0.09245680272579193,
      0.017284899950027466,
      0.02506633661687374,
      -0.007338592316955328,
      0.026434777304530144,
      -0.015820711851119995,
      -0.0013103133533149958,
      -0.00636167312040925,
      -0.05091940984129906,
      0.04707978293299675,
      -0.014941232278943062,
      -0.02642148919403553,
      -0.012092712335288525,
      -0.032904498279094696,
      -0.09752369672060013,
      0.05032656714320183,
      0.0945192500948906,
      -0.0169848520308733,
      0.024884246289730072,
      0.002740992698818445,
      -0.01859980635344982,
      -0.0031713489443063736,
      0.010805631056427956,
      0.030434759333729744,
      0.0459962897002697,
      -0.05524008721113205,
      -0.03506570681929588,
      0.08581246435642242,
      -0.03317530080676079,
      -0.0416632741689682,
      -0.03568880259990692,
      -0.024981923401355743,
      0.036829691380262375,
      -0.026293355971574783,
      -0.10903029143810272,
      -0.011835775338113308,
      0.03935512155294418,
      -0.06188160181045532,
      -0.00470427330583334,
      -0.005471273325383663,
      0.05382711440324783,
      0.008792702108621597,
      -0.02222425676882267,
      -0.009630691260099411,
      -0.0035578301176428795,
      0.03439480811357498,
      -0.03552915155887604,
      0.026693426072597504,
      -0.06172465533018112,
      -0.05039987340569496,
      0.0034139729104936123,
      -0.03332662954926491,
      0.02850182168185711,
      -0.01755513623356819,
      -0.05904054269194603,
      -0.06780633330345154,
      -0.010795419104397297,
      0.010282459668815136,
      -0.010087837465107441,
      -0.0003469446091912687,
      0.08623676002025604,
      0.05419386923313141,
      -0.08585154265165329,
      -0.021246731281280518,
      -0.10098680108785629,
      0.01727704517543316,
      0.039046574383974075,
      0.05954528972506523,
      -0.03102370724081993,
      -0.08112549781799316,
      0.02162388153374195,
      0.02951899729669094,
      -0.014701658859848976,
      -0.012009660713374615,
      -0.04722420871257782,
      -0.039823018014431,
      -0.03640791401267052,
      0.04463699832558632,
      0.01849295012652874,
      -0.04116475209593773,
      0.07113617658615112,
      0.02831157110631466,
      0.016267796978354454,
      -0.05146464332938194,
      0.08816548436880112,
      -0.05348294973373413,
      0.04460582509636879,
      0.08234276622533798,
      0.028222916647791862,
      0.029097966849803925,
      -0.07931589335203171,
      -0.038971755653619766,
      -0.021487876772880554,
      0.01004271861165762,
      0.029576027765870094,
      0.03830144181847572,
      -0.07195045799016953,
      -0.04126681014895439,
      -0.002647153800353408,
      0.02522863820195198,
      0.05132915824651718,
      -0.0923290029168129,
      -0.04453246295452118,
      -0.010286008939146996,
      -0.005575019400566816,
      -0.0625116303563118,
      -0.06795507669448853,
      -0.06986770778894424,
      0.07463469356298447,
      -0.0065171848982572556,
      -0.07294577360153198,
      -0.011516733095049858,
      -0.0853269174695015,
      0.0004947961424477398,
      0.038639165461063385,
      -0.016682405024766922,
      -0.04050043970346451,
      0.056205134838819504,
      0.06666145473718643,
      0.1098308339715004,
      -0.08360961079597473,
      0.005062529351562262,
      0.06730926781892776,
      -0.13821949064731598,
      0.08198495209217072,
      0.03737577795982361,
      -0.03076130710542202,
      -0.0766528770327568,
      0.013111232779920101,
      -0.034090399742126465,
      0.08342797309160233,
      0.009693417698144913,
      0.07655517011880875,
      -0.015309290960431099,
      -0.042641397565603256,
      0.046015579253435135,
      -0.0017133045475929976,
      -0.06156037002801895,
      0.06506370007991791,
      -0.08839745074510574,
      -0.05589578300714493,
      -0.014840565621852875,
      -0.01883184351027012,
      0.03725418820977211,
      0.05363020673394203,
      0.06326484680175781,
      0.04295819625258446,
      0.036261264234781265,
      -0.06725127249956131,
      -0.036925505846738815,
      -0.04816974326968193,
      -0.03325064107775688,
      -0.017849784344434738,
      0.09530343860387802,
      0.04496188089251518,
      -0.022219689562916756,
      -0.02973346970975399,
      0.010677851736545563,
      -0.11902164667844772,
      0.027578121051192284,
      0.08503097295761108,
      -0.01834920607507229,
      0.0027005935553461313,
      -0.002079516416415572,
      -0.01041694451123476,
      -0.03684306517243385,
      0.0015804648865014315,
      0.026745017617940903,
      -0.05238410457968712,
      0.010303678922355175,
      -0.10715070366859436,
      -0.04374774545431137,
      0.0029118566308170557,
      -0.010765261016786098,
      0.03557055443525314,
      0.030202925205230713,
      -0.024867964908480644,
      -0.023911459371447563,
      -0.0018182446947321296,
      0.028740020468831062,
      -0.06559691578149796,
      0.034444473683834076,
      -0.004405921790748835,
      0.05431680753827095,
      -0.007109388709068298,
      0.02669339068233967,
      0.007303755730390549,
      -0.05965082719922066,
      -0.011592142283916473,
      0.008328909985721111,
      0.04378899186849594,
      -0.015102320350706577,
      0.014203698374330997,
      -0.03707432001829147,
      -0.03743581473827362,
      -0.07355009764432907,
      0.013276276178658009,
      0.04441089183092117,
      0.07414785772562027,
      -0.002278175437822938,
      0.003748649265617132,
      0.08656949549913406,
      -0.06314913928508759,
      -0.003472710493952036,
      -0.007758811116218567,
      -0.027075571939349174,
      -0.021622031927108765,
      0.011378729715943336,
      0.1003238633275032,
      -0.003354427870362997,
      0.023153789341449738,
      0.02263699099421501,
      0.11832426488399506,
      -0.027538638561964035,
      0.01972481980919838,
      -0.028964471071958542,
      0.09104667603969574,
      0.044076770544052124,
      0.020614374428987503,
      0.006933519616723061,
      0.04059012979269028,
      0.0006369362818077207,
      -0.01609165221452713,
      0.05216839909553528,
      -0.00598869239911437,
      0.01029611099511385,
      0.0005460965912789106,
      0.007564533967524767,
      -0.0179541427642107,
      -0.034179091453552246,
      0.03775811940431595,
      0.009768043644726276,
      0.12032070755958557,
      -0.0315433032810688,
      0.03963124006986618,
      0.024075713008642197,
      -0.016246577724814415,
      0.026689380407333374,
      0.006223778240382671,
      0.020945142954587936,
      0.018264105543494225,
      -0.05051920562982559,
      0.052195195108652115,
      -0.01646421290934086,
      -0.010163495317101479,
      0.06327741593122482,
      0.04159644991159439,
      0.02038102224469185,
      0.06165618449449539,
      -0.040682680904865265,
      0.015959860756993294,
      0.007376648485660553,
      -0.060439206659793854,
      -0.005546146538108587,
      -0.007654734421521425,
      0.11546260118484497,
      -0.08526488393545151,
      -0.062399521470069885,
      -0.06922584027051926,
      0.031128941103816032,
      0.10468299686908722,
      -0.027494322508573532,
      0.06103447079658508,
      -0.010293906554579735,
      0.045951735228300095,
      0.011501764878630638,
      0.008015972562134266,
      -0.01676197536289692,
      -0.014288839884102345,
      -0.020303985103964806,
      0.04878191649913788,
      -0.06873684376478195
    ],
    [
      0.06131661683320999,
      -0.07626286894083023,
      -0.02832484431564808,
      0.07539798319339752,
      -0.0362359918653965,
      0.03451084345579147,
      0.08023355156183243,
      -0.0034356284886598587,
      -0.03885447978973389,
      0.020733460783958435,
      0.07882897555828094,
      -0.10037893801927567,
      0.00696599343791604,
      -0.08884517848491669,
      -0.0716533288359642,
      -0.022086435928940773,
      -0.07973219454288483,
      -0.017456408590078354,
      0.08489050716161728,
      0.002617636928334832,
      0.05685101076960564,
      0.0009146884549409151,
      -0.08124080300331116,
      -0.03821093589067459,
      0.046070002019405365,
      0.04780513793230057,
      -0.08636775612831116,
      -0.004836604930460453,
      -0.0783267691731453,
      -0.02389747090637684,
      -0.02111171931028366,
      0.03339231759309769,
      0.011723005212843418,
      0.01042764913290739,
      0.0001677893742453307,
      0.021120619028806686,
      0.036486733704805374,
      0.019683634862303734,
      -0.027170585468411446,
      0.0012229839339852333,
      -0.06051462143659592,
      -0.00981735810637474,
      0.0070562222972512245,
      0.032851360738277435,
      -0.08996447920799255,
      0.046238742768764496,
      0.027392368763685226,
      0.035792190581560135,
      0.03711775690317154,
      -0.0019969281274825335,
      0.07938744872808456,
      0.011185671202838421,
      0.045943185687065125,
      0.02391756698489189,
      0.025097724050283432,
      -0.11164544522762299,
      -0.005584110505878925,
      0.05816914141178131,
      -0.021986767649650574,
      -0.0051955715753138065,
      -0.09195975959300995,
      0.06308046728372574,
      -0.02800625190138817,
      0.03813689574599266,
      -0.08672778308391571,
      -0.07599971443414688,
      0.050610270351171494,
      -0.0318758487701416,
      0.005984256509691477,
      0.0047471122816205025,
      -0.011377865448594093,
      -0.02369880862534046,
      -0.06408978253602982,
      -0.05287971720099449,
      0.002253942657262087,
      -0.005025959108024836,
      -0.03726400062441826,
      0.015108208172023296,
      -0.05168946459889412,
      0.010798967443406582,
      -0.004526244476437569,
      -0.04017520695924759,
      -0.025804633274674416,
      -0.0292059313505888,
      0.0038041039370000362,
      0.08120755851268768,
      -0.04721328243613243,
      -0.034083783626556396,
      0.06963095813989639,
      -0.007009164895862341,
      -0.03707555681467056,
      0.0005301226628944278,
      0.03703753277659416,
      -0.0770881250500679,
      -0.150404691696167,
      -0.10207542032003403,
      -0.05304616317152977,
      0.0309181809425354,
      -0.01479526050388813,
      0.07935313880443573,
      -0.044431015849113464,
      0.019665353000164032,
      0.005370838567614555,
      0.06309570372104645,
      0.004500891547650099,
      0.03433121740818024,
      0.056921157985925674,
      0.03843298554420471,
      0.014045671559870243,
      -0.003007120219990611,
      0.044357914477586746,
      0.04331846162676811,
      0.044881679117679596,
      -0.02716309018433094,
      -0.07641459256410599,
      -0.06993822753429413,
      -0.04753643274307251,
      -0.019921226426959038,
      -0.014791872352361679,
      -0.0322101004421711,
      0.03580564633011818,
      -0.0557442232966423,
      0.05624821037054062,
      0.036994729191064835,
      -0.005913557950407267,
      -0.016036562621593475,
      -0.0035573174245655537,
      0.05770518258213997,
      0.0055856830440461636,
      0.004578093066811562,
      -0.04899708926677704,
      -0.029634583741426468,
      -0.04666117578744888,
      0.046412061899900436,
      0.019701475277543068,
      -0.014323191717267036,
      0.015345772728323936,
      -0.05789339542388916,
      -0.05372504144906998,
      -0.00724128307774663,
      -0.058815713971853256,
      0.011866441927850246,
      0.021013356745243073,
      -0.09637518227100372,
      0.08345992118120193,
      -0.002830721903592348,
      -0.04483306035399437,
      -0.052778322249650955,
      0.0003257608041167259,
      -0.03390897810459137,
      0.06672254949808121,
      0.05212916061282158,
      -0.016570962965488434,
      -0.004802808165550232,
      0.03422700613737106,
      0.014638709835708141,
      -0.027127234265208244,
      -0.10425996035337448,
      -0.034846775233745575,
      -0.06115223094820976,
      0.06593438237905502,
      0.09078232198953629,
      0.04055481404066086,
      0.08417990058660507,
      0.00655205687507987,
      -0.05438263714313507,
      -0.020018111914396286,
      -0.03642353415489197,
      0.04819467291235924,
      0.04650668427348137,
      0.011141403578221798,
      -0.07295098155736923,
      0.030270466580986977,
      0.03672676533460617,
      -0.05738199129700661,
      0.0033126878552138805,
      0.016404954716563225,
      0.0013402198674157262,
      -0.056109774857759476,
      -0.0907992497086525,
      -0.050877850502729416,
      0.048219431191682816,
      -0.009330038912594318,
      0.11584147810935974,
      0.020143061876296997,
      0.05498841404914856,
      0.01528046652674675,
      -0.024413859471678734,
      0.07664269208908081,
      0.02671767771244049,
      -0.027885746210813522,
      0.049301210790872574,
      -0.05418134853243828,
      0.014291117899119854,
      0.05558621883392334,
      0.07527286559343338,
      0.0501629076898098,
      -0.019825683906674385,
      -0.021416785195469856,
      0.01024114340543747,
      0.02475176565349102,
      0.015872903168201447,
      -0.08210323750972748,
      0.06425358355045319,
      -0.03489575535058975,
      0.040603041648864746,
      0.03940827399492264,
      0.015789519995450974,
      0.0629270002245903,
      -0.011126148514449596,
      0.05424449220299721,
      -0.017905060201883316,
      -0.051850054413080215,
      0.007904080674052238,
      -0.04267192631959915,
      -0.034735966473817825,
      0.006617381237447262,
      0.01788204349577427,
      -0.023103291168808937,
      0.0634656697511673,
      -0.007340314798057079,
      -0.015055714175105095,
      0.023218588903546333,
      -0.003728447249159217,
      0.010636302642524242,
      -0.08600708097219467,
      0.05580588057637215,
      0.058245494961738586,
      -0.05966250225901604,
      -0.006639799103140831,
      0.0567239411175251,
      -0.04697480797767639,
      0.046590857207775116,
      -0.039206214249134064,
      0.020412582904100418,
      0.021783243864774704,
      -0.03889930620789528,
      -0.015039247460663319,
      0.02542894333600998,
      -0.0013640824472531676,
      0.0034922328777611256,
      -0.003217445919290185,
      -0.0075095160864293575,
      0.09724214673042297,
      0.0302360150963068,
      0.010566950775682926,
      0.0715075209736824,
      0.05467517673969269,
      0.010030949488282204,
      -0.08219247311353683,
      7.292469672393054e-05,
      -0.018254857510328293,
      0.12264326959848404,
      0.03095702826976776,
      0.044026974588632584,
      -0.03464270755648613,
      0.013713427819311619,
      0.016909625381231308,
      0.040388260036706924,
      0.038897253572940826,
      -0.026668939739465714,
      0.008443213067948818,
      0.05350494012236595,
      0.04094783589243889,
      -0.09103808552026749,
      0.02465169131755829,
      -0.02426869608461857,
      0.09648385643959045,
      -0.008194164372980595,
      0.05663590878248215,
      0.017260855063796043,
      -0.072695292532444,
      -0.07515227049589157,
      0.0023339958861470222,
      0.015679415315389633,
      0.04123891890048981,
      0.021876659244298935,
      0.006244876887649298,
      -0.005078414920717478,
      0.006091137416660786,
      0.0006946427747607231,
      -0.06722663342952728,
      -0.017082374542951584,
      -0.058284781873226166,
      -0.03587307408452034,
      -0.08760605752468109,
      -0.045196283608675,
      -0.060722045600414276,
      -0.015490290708839893,
      0.04228028655052185,
      0.012113863602280617,
      -0.002966232830658555,
      0.044718045741319656,
      0.013819645158946514,
      0.039003510028123856,
      -0.021275421604514122,
      0.029689721763134003,
      0.03440838307142258,
      -0.013699945993721485,
      -0.054071687161922455,
      0.1282241940498352,
      0.002543211216107011,
      -0.06385040283203125,
      0.029809927567839622,
      0.03890150040388107,
      -0.022366348654031754,
      -0.03469487279653549,
      -0.027423225343227386,
      -0.01577880047261715,
      -0.040647707879543304,
      -0.06046322360634804,
      0.021453725174069405,
      0.06096867471933365,
      -0.06551486253738403,
      -0.002644334454089403,
      -0.005644103512167931,
      -0.013785552233457565,
      -0.02997058816254139,
      0.036041032522916794,
      -0.009910674765706062,
      -0.0398305244743824,
      0.002625431399792433,
      0.04443754628300667,
      -0.08638180047273636,
      -0.05119680240750313,
      -0.04569127410650253,
      0.061813414096832275,
      -0.02991298772394657,
      -0.07817867398262024,
      -0.04228935390710831,
      -0.008030516095459461,
      0.0010644809808582067,
      -0.0024676767643541098,
      -0.04443592578172684,
      0.028150513768196106,
      -0.002173281041905284,
      0.047087881714105606,
      0.037385232746601105,
      -0.09112253785133362,
      -0.02500120922923088,
      -0.042553577572107315,
      -0.023596329614520073,
      -0.010663075372576714,
      -0.010805445723235607,
      0.08532676845788956,
      -0.08362476527690887,
      0.00872357189655304,
      -0.013965637423098087,
      -0.0355382077395916,
      0.08179209381341934,
      0.04909343272447586,
      -0.023399420082569122,
      0.049722474068403244,
      0.04358383268117905,
      0.03814230114221573,
      0.11329979449510574,
      0.030301718041300774,
      0.042046207934617996,
      -0.02768493816256523,
      0.008831881918013096,
      -0.01603204570710659,
      -0.009697478264570236,
      0.05528140068054199,
      -0.056082043796777725,
      -0.022196613252162933,
      -0.02102615498006344,
      -0.002737421542406082,
      0.026370322331786156,
      -0.06749733537435532,
      0.052665598690509796,
      0.009706953540444374,
      -0.012105661444365978,
      -0.030167531222105026,
      0.0259475726634264,
      -0.037373460829257965,
      0.009798848070204258,
      -0.08511628210544586,
      0.006797520909458399,
      -0.025594094768166542,
      -0.02673148736357689,
      -0.00022559378703590482,
      -0.00837795715779066,
      0.0720512866973877,
      -0.049276240170001984,
      -0.04193995147943497,
      -0.0720415934920311,
      0.06471824645996094,
      0.033493366092443466,
      -0.06690256297588348,
      -0.07713086158037186,
      0.00210755318403244,
      -0.007329702842980623,
      0.023562435060739517,
      0.03103400580585003,
      0.09088389575481415,
      0.08223585784435272,
      -0.0018911146325990558,
      0.02749999612569809,
      -0.010202174074947834,
      -0.041333142668008804,
      0.07954263687133789,
      -0.06752040982246399,
      -0.05475764349102974,
      -0.01867622323334217,
      -0.03159693256020546,
      -0.035057537257671356,
      0.007264682091772556,
      -0.030904876068234444,
      0.002913495758548379,
      -0.024711312726140022,
      -0.037904564291238785,
      -0.04496007785201073,
      -0.024814115837216377,
      -0.03505925461649895,
      -0.08369192481040955,
      0.021111851558089256,
      0.0665302723646164,
      0.02662569098174572,
      -0.008124680258333683,
      -0.06448642909526825,
      0.002003599889576435,
      0.03876638412475586,
      -0.054052289575338364,
      0.014519126154482365,
      -0.0012714413460344076,
      -0.049900129437446594,
      0.03347305953502655,
      0.005796405952423811,
      0.05015621334314346,
      0.021019170060753822,
      -0.06097869575023651,
      0.001348691526800394,
      -0.04869282245635986,
      -0.019368723034858704,
      -0.054332032799720764,
      0.022444240748882294,
      -0.12008319795131683,
      0.040979091078042984,
      0.05732374265789986,
      -0.07153254002332687,
      -0.0012007153127342463,
      0.06388666480779648,
      0.04143156856298447,
      0.003354441374540329,
      0.07344549894332886,
      -0.025883007794618607,
      0.03110099583864212,
      0.031364042311906815,
      0.024395652115345,
      -0.013602986931800842,
      0.04288298264145851,
      -0.0223775003105402,
      -0.05063401535153389,
      0.05447488650679588,
      0.002206286881119013,
      -0.017753606662154198,
      0.02732984907925129,
      0.07169143110513687,
      0.01622982695698738,
      -0.003160814056172967,
      -0.019090235233306885,
      -0.002745280507951975,
      0.006649334914982319,
      -0.01320598740130663,
      0.04515344649553299,
      -0.03042394295334816,
      -0.019046109169721603,
      0.013271410018205643,
      0.020736055448651314,
      -0.022169748321175575,
      -0.06839391589164734,
      0.026389626786112785,
      0.0914100632071495,
      -0.05993599444627762,
      -0.014634782448410988,
      0.0188983716070652,
      0.044233135879039764,
      0.03824774548411369,
      0.025087712332606316,
      -0.041001494973897934,
      -0.05329057201743126,
      -0.010871201753616333,
      0.03154841810464859,
      -0.07567906379699707,
      0.02009391225874424,
      -0.027319788932800293,
      -0.005534389987587929,
      0.04269087687134743,
      -0.07774513959884644,
      0.030193092301487923,
      -0.026250645518302917,
      0.09248404949903488,
      -0.0345635712146759,
      -0.019853930920362473,
      -0.06307144463062286,
      -0.0237803366035223,
      -0.03396356850862503,
      -0.005310934968292713,
      -0.03875865042209625,
      0.019309625029563904,
      -0.015049937181174755,
      -0.06746718287467957,
      -0.055431708693504333
    ],
    [
      0.022580493241548538,
      0.03455653786659241,
      -0.0010575195774435997,
      0.04121583327651024,
      -0.06152613088488579,
      -0.04498733580112457,
      -0.01974409446120262,
      0.00979586411267519,
      -0.007190293166786432,
      0.05905299261212349,
      -0.062484800815582275,
      0.00015680505021009594,
      -0.059524741023778915,
      -0.04734108969569206,
      -0.01641293242573738,
      0.07604581862688065,
      -0.06311516463756561,
      -0.011950974352657795,
      -0.013805405236780643,
      -0.005276334937661886,
      -0.016882676631212234,
      0.005841751117259264,
      -0.024207454174757004,
      0.03453030809760094,
      0.06277412176132202,
      -0.04588255658745766,
      -0.020249849185347557,
      0.022655246779322624,
      0.03542511165142059,
      -0.012312781997025013,
      0.04172815382480621,
      -0.0033915438689291477,
      -0.011462309397757053,
      0.07333944737911224,
      0.05931638926267624,
      0.02104576863348484,
      0.02766687236726284,
      0.04953769966959953,
      -0.007103256415575743,
      0.020602980628609657,
      0.02656826749444008,
      -0.0065940204076468945,
      0.024578701704740524,
      0.01074419915676117,
      0.018105369061231613,
      0.05787026137113571,
      -0.04349028319120407,
      -0.05404229834675789,
      0.0006064524641260505,
      -0.0046493420377373695,
      -0.03193498030304909,
      -0.1251511573791504,
      -0.10098675638437271,
      -0.03204554319381714,
      0.08011512458324432,
      0.014067244715988636,
      -0.03606970235705376,
      -0.011104371398687363,
      -0.024249950423836708,
      0.0028151257429271936,
      0.09551388770341873,
      0.03216790407896042,
      -0.05569824203848839,
      -0.08703862130641937,
      0.004801169037818909,
      0.005798064172267914,
      0.03778877109289169,
      0.04255489259958267,
      0.0073344893753528595,
      0.0070405276492238045,
      -0.0018585767829790711,
      0.08327163755893707,
      -0.05048360675573349,
      -0.02290724590420723,
      0.06030649691820145,
      0.043807972222566605,
      -0.001755178440362215,
      -0.1095903292298317,
      -0.06897608935832977,
      0.029557431116700172,
      0.023098524659872055,
      -0.06230795010924339,
      -0.06711304187774658,
      0.0230536088347435,
      -0.06201837211847305,
      0.05419515073299408,
      -0.06808508187532425,
      0.024640940129756927,
      0.02630791813135147,
      -0.02628592774271965,
      -0.008178452029824257,
      0.026339279487729073,
      -0.009458756074309349,
      0.007773415185511112,
      0.00280405068770051,
      0.05994188413023949,
      -0.05848187953233719,
      -0.018213428556919098,
      -0.006171783432364464,
      0.05952325835824013,
      -0.046622175723314285,
      0.012627153657376766,
      0.03789275512099266,
      0.060875263065099716,
      -0.0027737338095903397,
      0.014967551454901695,
      0.020335309207439423,
      -0.034706104546785355,
      0.05111410468816757,
      0.04557597637176514,
      0.0360211506485939,
      -0.009948927909135818,
      0.026247043162584305,
      -0.07339891791343689,
      0.024198049679398537,
      -0.04301849752664566,
      -0.05133552476763725,
      0.027591248974204063,
      0.04548371210694313,
      0.06789632141590118,
      0.03930841386318207,
      -0.03534971922636032,
      -0.022387536242604256,
      0.13412350416183472,
      0.14162200689315796,
      0.0750817283987999,
      0.029245810583233833,
      -0.04065261781215668,
      0.007179529406130314,
      0.025735609233379364,
      0.05683630332350731,
      -0.03786081820726395,
      -0.011176304891705513,
      0.011417480185627937,
      0.042063985019922256,
      -0.047594863921403885,
      0.010892251506447792,
      -0.12896564602851868,
      0.03184276446700096,
      -0.017581671476364136,
      0.054617226123809814,
      0.09472322463989258,
      0.028068678453564644,
      0.05247751250863075,
      -0.0021281433291733265,
      -0.015343120321631432,
      -0.1352718621492386,
      0.07720739394426346,
      0.022262727841734886,
      -0.01811883971095085,
      -0.00747725460678339,
      0.04100393131375313,
      -0.049237266182899475,
      -0.06418497860431671,
      0.09044712036848068,
      -0.0291889775544405,
      0.09416601061820984,
      -0.03320293501019478,
      -0.016032224521040916,
      -0.012161157093942165,
      0.008947418071329594,
      -0.016594018787145615,
      -0.08685920387506485,
      0.06878103315830231,
      -0.08006151765584946,
      -0.056008197367191315,
      0.02326989360153675,
      -0.029149968177080154,
      -0.03320242837071419,
      -0.025705611333251,
      0.07060760259628296,
      0.04144422337412834,
      0.00407316442579031,
      -0.037434667348861694,
      -0.032802511006593704,
      0.02987487241625786,
      0.003440556349232793,
      0.047204937785863876,
      -0.05255598947405815,
      -0.07771069556474686,
      0.015382159501314163,
      -0.04711020365357399,
      0.018117887899279594,
      -0.017572253942489624,
      -0.05787581577897072,
      -0.013186201453208923,
      -0.04261782765388489,
      -0.017767466604709625,
      -0.05300062149763107,
      -0.030803533270955086,
      -0.06198333203792572,
      -0.043613310903310776,
      -0.04660782590508461,
      0.021627143025398254,
      -0.00937170721590519,
      -0.03568015247583389,
      0.008907762356102467,
      0.0008508911123499274,
      0.0477893203496933,
      0.004746683407574892,
      0.11904729157686234,
      0.10383138060569763,
      -0.012622942216694355,
      -0.12408621609210968,
      0.0883028581738472,
      -0.03779786452651024,
      0.14502830803394318,
      0.001338296220637858,
      0.04002906382083893,
      -0.04403327405452728,
      0.025450782850384712,
      -0.1019122451543808,
      -0.02317916601896286,
      0.053481169044971466,
      0.02675474062561989,
      0.07811427861452103,
      -0.07620776444673538,
      0.021898053586483,
      0.08306708186864853,
      -0.0063894642516970634,
      -0.02320602908730507,
      0.02200677990913391,
      0.06267198175191879,
      0.017063330858945847,
      0.01571410894393921,
      0.07468358427286148,
      0.023570165038108826,
      0.01139384601265192,
      -0.07759645581245422,
      -0.006329316180199385,
      0.04909639433026314,
      -0.03543366119265556,
      0.05090156942605972,
      -0.027204545214772224,
      -0.04832137003540993,
      -0.004794137552380562,
      -0.03792184218764305,
      0.04770778864622116,
      0.008740403689444065,
      -0.04757850989699364,
      0.035548385232686996,
      -0.013276957906782627,
      -0.04739997535943985,
      -0.014578706584870815,
      -0.003816358745098114,
      0.02599395625293255,
      -0.01946493238210678,
      0.06542813777923584,
      0.08900371938943863,
      0.018752649426460266,
      -0.009838291443884373,
      -0.04687190800905228,
      -0.009612400084733963,
      0.015463806688785553,
      0.0173542071133852,
      -0.019240954890847206,
      0.11270452290773392,
      -0.054843511432409286,
      0.0599617175757885,
      0.019249852746725082,
      0.007890000939369202,
      0.1252978891134262,
      0.05712142214179039,
      -0.026537878438830376,
      -0.004619406070560217,
      -0.037986818701028824,
      0.019625945016741753,
      0.020247148349881172,
      -0.02266255021095276,
      -0.029205365106463432,
      -0.0054543460719287395,
      -0.09874455630779266,
      -0.030227920040488243,
      -0.023807357996702194,
      0.06096993014216423,
      -0.0518152192234993,
      -0.03789796307682991,
      -0.03213251754641533,
      0.07197675108909607,
      -0.05259707197546959,
      0.023906830698251724,
      -0.0166277214884758,
      -0.04513769969344139,
      -0.0032059201039373875,
      0.08132991939783096,
      -0.10467652231454849,
      -0.008658066391944885,
      -0.06778187304735184,
      -0.03331249579787254,
      -0.0019544847309589386,
      -0.0729200690984726,
      0.043146055191755295,
      0.03718264028429985,
      0.03433568775653839,
      -0.03661666810512543,
      0.02805507741868496,
      -0.011854466050863266,
      0.06731399893760681,
      0.012066134251654148,
      0.02390940673649311,
      -0.08560872822999954,
      0.04731820523738861,
      0.029275085777044296,
      -0.01549011655151844,
      0.05038335174322128,
      0.005866981577128172,
      0.07830511033535004,
      0.02589491754770279,
      0.06943259388208389,
      0.04433973878622055,
      -0.0471036396920681,
      -0.05041516199707985,
      -0.021562665700912476,
      -0.0847502127289772,
      0.025681814178824425,
      0.05526789277791977,
      0.01154650840908289,
      -0.05390656366944313,
      0.02377581223845482,
      -0.011312278918921947,
      -0.007748039904981852,
      -0.030409878119826317,
      0.019406206905841827,
      -0.01793072372674942,
      -0.010413645766675472,
      0.15202093124389648,
      0.009389787912368774,
      -0.028650082647800446,
      -0.08735235780477524,
      0.008922291919589043,
      0.07657304406166077,
      0.05164140462875366,
      -0.018199117854237556,
      -0.028622115030884743,
      0.003437865525484085,
      -0.06025686487555504,
      -0.03899319842457771,
      -0.022149432450532913,
      -0.05670818313956261,
      -0.028433816507458687,
      0.041612789034843445,
      -0.03315484896302223,
      0.00935102067887783,
      0.04186373949050903,
      -0.06885027140378952,
      0.02388257160782814,
      0.048249952495098114,
      -0.004548490978777409,
      0.09288080781698227,
      0.08605078607797623,
      0.052676472812891006,
      -0.02581837587058544,
      0.02762654423713684,
      0.04699723422527313,
      -0.07680369913578033,
      0.0831570029258728,
      -0.0061716060154139996,
      -0.061000578105449677,
      -0.011149388737976551,
      0.057574253529310226,
      0.0580841526389122,
      0.020304610952734947,
      -0.0015070466324687004,
      0.07795683294534683,
      0.020419122651219368,
      0.02325308322906494,
      -0.07003755867481232,
      0.020042378455400467,
      0.040270447731018066,
      0.007073656190186739,
      -0.0716647058725357,
      -0.03505083546042442,
      -0.03853161633014679,
      -0.01090271957218647,
      0.06209394708275795,
      0.011955443769693375,
      -0.053086329251527786,
      0.038885634392499924,
      -0.022317631170153618,
      0.07624152302742004,
      0.005599304102361202,
      -0.010548773221671581,
      0.03840499371290207,
      -0.033332694321870804,
      0.02517138049006462,
      -0.015893006697297096,
      0.0022749071940779686,
      -0.02042648382484913,
      -0.0038415384478867054,
      -0.003982395865023136,
      -0.026433449238538742,
      -0.04538490250706673,
      -0.03619445860385895,
      -0.013738526031374931,
      0.07757304608821869,
      0.0020797529723495245,
      0.06901022791862488,
      0.026835253462195396,
      -0.03800806775689125,
      -0.009452152997255325,
      -0.06841572374105453,
      -0.07503663003444672,
      -0.05406565964221954,
      0.08201760053634644,
      0.023080090060830116,
      0.023814978078007698,
      -0.03834204003214836,
      0.025855952873826027,
      -0.04747523367404938,
      -0.11957543343305588,
      -0.03766857832670212,
      -0.026093997061252594,
      -0.011774812825024128,
      -0.014628555625677109,
      -0.017512813210487366,
      0.0002740020281635225,
      0.02391626313328743,
      -0.044279348105192184,
      0.05277593433856964,
      -0.0778496190905571,
      0.024632729589939117,
      0.018491750583052635,
      -0.0243711918592453,
      0.04250064492225647,
      0.010907632298767567,
      -0.016632279381155968,
      -0.06192437559366226,
      0.07436620444059372,
      -0.07115337997674942,
      -0.007620532065629959,
      -0.008133136667311192,
      -0.024560490623116493,
      -0.003513252828270197,
      -0.005065490957349539,
      0.03794333338737488,
      0.04955103248357773,
      -0.014556009322404861,
      -0.009406115859746933,
      0.006963936612010002,
      -0.0034564384259283543,
      -0.011150803416967392,
      0.019376792013645172,
      0.00293204328045249,
      -0.018330946564674377,
      -0.029986998066306114,
      0.004430004861205816,
      0.04087594524025917,
      -0.02003154717385769,
      0.02788926288485527,
      -0.04033293575048447,
      -0.03310440853238106,
      -0.0034875485580414534,
      -0.008255970664322376,
      -0.06656258553266525,
      -0.08052272349596024,
      0.03909710794687271,
      -0.00944235734641552,
      -0.0008054324425756931,
      -0.023306241258978844,
      0.054598886519670486,
      -0.06229100748896599,
      0.10050010681152344,
      -0.011456966400146484,
      0.051721733063459396,
      0.021527856588363647,
      0.041670721024274826,
      -0.0514320433139801,
      -0.011804668232798576,
      0.02516844868659973,
      0.016057029366493225,
      0.05160444974899292,
      -0.0503682941198349,
      0.02433791570365429,
      0.02714751847088337,
      0.018348753452301025,
      -0.08723245561122894,
      0.044293362647295,
      0.0010622075060382485,
      -0.032141804695129395,
      0.04553525894880295,
      0.09556648135185242,
      0.0138440802693367,
      -0.011128121055662632,
      0.005840340629220009,
      -0.0738072395324707,
      0.026142753660678864,
      0.05860353261232376,
      0.0393475778400898,
      0.031585849821567535,
      -0.050143152475357056,
      0.060872070491313934,
      -0.054191023111343384,
      0.002284536836668849,
      -0.028794661164283752,
      0.00836854986846447,
      -0.0028617309872061014,
      -0.051863525062799454,
      -0.048700857907533646,
      0.02885626070201397,
      0.033509667962789536,
      -0.0021960826124995947,
      -0.0167026836425066,
      -0.00030250949203036726
    ],
    [
      0.0024179741740226746,
      -0.031318455934524536,
      0.01022848766297102,
      0.03806663304567337,
      0.04727376252412796,
      -0.0026826183311641216,
      0.030001256614923477,
      0.019816527143120766,
      -0.0900675430893898,
      -0.0062634944915771484,
      -0.039035990834236145,
      -0.04959048330783844,
      0.008854142390191555,
      -0.0017497314838692546,
      -0.02073320746421814,
      -0.017017433419823647,
      -0.039408113807439804,
      -0.005601877812296152,
      -0.01773635484278202,
      -0.042895056307315826,
      0.017513509839773178,
      -0.004127331543713808,
      -0.10192357003688812,
      0.11467445641756058,
      0.007146661635488272,
      0.05103149637579918,
      -0.0729290246963501,
      0.003578620031476021,
      -0.06910008192062378,
      0.026630455628037453,
      0.051100291311740875,
      0.014364869333803654,
      -0.010102087631821632,
      0.06105253845453262,
      -0.008350742980837822,
      0.04035840928554535,
      -0.0049427105113863945,
      0.0016223174752667546,
      -0.05307214334607124,
      -0.04737650975584984,
      -0.02201761305332184,
      0.04039181396365166,
      -0.026127243414521217,
      -0.05124157667160034,
      0.007257047574967146,
      -0.032429084181785583,
      -0.010377759113907814,
      0.020344294607639313,
      0.02523389644920826,
      0.06673485785722733,
      0.023170389235019684,
      -0.03167276456952095,
      0.0241097342222929,
      0.03907572478055954,
      0.06551820039749146,
      -0.08100047707557678,
      0.023057173937559128,
      0.001953458646312356,
      -0.01201045885682106,
      0.05217956379055977,
      0.0518479123711586,
      0.0073023345321416855,
      0.006743418052792549,
      0.008157639764249325,
      -0.04477996379137039,
      -0.0008300006156787276,
      -0.005960800684988499,
      -0.016394861042499542,
      0.011924990452826023,
      -0.026252012699842453,
      0.005672230385243893,
      -0.09219273179769516,
      0.056685514748096466,
      0.0129463579505682,
      0.019874723628163338,
      -0.028009187430143356,
      0.042297959327697754,
      0.055117007344961166,
      -0.03236233815550804,
      0.007539241574704647,
      -0.016667664051055908,
      0.013149495236575603,
      0.023586375638842583,
      0.018490048125386238,
      0.010179692879319191,
      0.04486072435975075,
      0.08936811238527298,
      -0.03300997614860535,
      0.013770634308457375,
      0.030895717442035675,
      -0.047422461211681366,
      0.004547316115349531,
      0.004174238536506891,
      -0.006376694887876511,
      -0.055792178958654404,
      0.06490007042884827,
      0.018576761707663536,
      0.07232621312141418,
      0.03747826814651489,
      -0.0353352315723896,
      -0.001855342648923397,
      -0.002147755352780223,
      0.018648630008101463,
      0.0690327137708664,
      0.03263753652572632,
      0.04539736360311508,
      -0.018445705994963646,
      -0.02610142156481743,
      0.004469302482903004,
      -0.010889410972595215,
      -0.006096430588513613,
      -0.02637282758951187,
      0.010019299574196339,
      0.014349300414323807,
      -0.0730680376291275,
      0.023770557716488838,
      0.024934232234954834,
      0.07484088838100433,
      0.02725306712090969,
      -0.07357276231050491,
      0.03741012513637543,
      0.00877393875271082,
      0.009365164674818516,
      0.03597290813922882,
      0.07590420544147491,
      0.13252384960651398,
      -0.018535355105996132,
      0.027167147025465965,
      -0.008571899496018887,
      0.04244813323020935,
      -0.038765616714954376,
      -0.02684718370437622,
      -0.014097976498305798,
      -0.0032841437496244907,
      0.017753340303897858,
      0.023317035287618637,
      0.06651102751493454,
      0.01448751799762249,
      0.011483165435492992,
      -0.022248489782214165,
      0.005260053556412458,
      -0.0711107850074768,
      0.04342031106352806,
      0.02831820584833622,
      0.04047789052128792,
      0.03214086964726448,
      -0.014072317630052567,
      -0.012703195214271545,
      -0.06846414506435394,
      0.001984738279134035,
      -0.046688247472047806,
      0.03465157374739647,
      0.04684801399707794,
      0.03757503256201744,
      0.013026316650211811,
      0.05377846956253052,
      0.004269877448678017,
      -0.05667770653963089,
      0.039607033133506775,
      0.008984880521893501,
      -0.013521110638976097,
      -0.0385974682867527,
      0.12017878144979477,
      0.11703451722860336,
      0.06014050915837288,
      0.02981158159673214,
      -0.022273432463407516,
      -0.00870113167911768,
      0.04103868454694748,
      0.0146107766777277,
      -0.03947390988469124,
      -0.05397653952240944,
      0.09508509188890457,
      0.028459999710321426,
      0.03213338926434517,
      0.00712992250919342,
      0.020674604922533035,
      0.02202676795423031,
      0.1074817106127739,
      -0.05575266107916832,
      -0.09003788977861404,
      -0.0004945368855260313,
      0.03072771430015564,
      -0.056337907910346985,
      -0.0064210593700408936,
      -0.01921485736966133,
      0.08505488932132721,
      -0.06630528718233109,
      -0.09930721670389175,
      0.012688501738011837,
      0.031239090487360954,
      0.04893898218870163,
      -0.08063890039920807,
      -0.05652119219303131,
      -0.011065536178648472,
      -0.029896896332502365,
      0.0930948257446289,
      0.061103418469429016,
      -0.011386221274733543,
      0.0003185410751029849,
      0.08416076004505157,
      0.038039807230234146,
      -0.07020943611860275,
      -0.032307058572769165,
      -0.029652433469891548,
      0.002514825202524662,
      0.03913499042391777,
      0.023736532777547836,
      -0.03725435212254524,
      0.05016754940152168,
      0.010555066168308258,
      -0.0024769792798906565,
      -0.020458893850445747,
      -0.04415754973888397,
      8.97522404557094e-05,
      -0.04888861998915672,
      0.05178026482462883,
      -0.027816463261842728,
      0.10688558965921402,
      -0.008816523477435112,
      0.004157393239438534,
      -0.016631310805678368,
      -0.007682814262807369,
      -0.05958373844623566,
      -0.029693176969885826,
      0.014110910706222057,
      0.029994480311870575,
      -0.01874699629843235,
      -0.03210088610649109,
      0.054098956286907196,
      -0.03204707056283951,
      -0.014644457027316093,
      0.10157600045204163,
      0.0034480043686926365,
      -0.01966250315308571,
      0.03308487683534622,
      0.03656318038702011,
      -0.04248753562569618,
      -0.013184878043830395,
      -0.0694698691368103,
      -0.018705036491155624,
      0.06794270128011703,
      -0.06605378538370132,
      0.02464999444782734,
      0.008973300457000732,
      0.003038343507796526,
      -0.0721922293305397,
      0.05835554748773575,
      -0.002011012053117156,
      -0.004109134431928396,
      -0.04655786603689194,
      0.029757831245660782,
      0.050981778651475906,
      -0.028424864634871483,
      -0.03385429456830025,
      -0.01623273640871048,
      -0.06155695393681526,
      -0.07525788992643356,
      0.015218705870211124,
      0.02723313681781292,
      -0.012623662129044533,
      0.0577206015586853,
      0.034789733588695526,
      0.004543839488178492,
      0.039837006479501724,
      0.06625980883836746,
      -0.00023644532484468073,
      -0.014079488813877106,
      0.004079397767782211,
      -0.016853971406817436,
      -0.0029786217492073774,
      -0.05916409194469452,
      -0.08197075873613358,
      -0.04332977905869484,
      -0.053103040903806686,
      0.09278904646635056,
      -0.04680082947015762,
      -0.06250852346420288,
      0.05203188583254814,
      -0.014085996896028519,
      0.010588143952190876,
      -0.04147142171859741,
      -0.01737496256828308,
      0.010067031718790531,
      0.0494907908141613,
      0.0421220101416111,
      0.008166671730577946,
      -0.08292733132839203,
      -0.007750875782221556,
      -0.02721846103668213,
      -0.038251396268606186,
      0.05719057098031044,
      0.009762088768184185,
      0.014653126709163189,
      -0.006134540308266878,
      0.03336508199572563,
      0.09095693379640579,
      0.03411247581243515,
      0.04026375338435173,
      -0.0637836828827858,
      0.03902759775519371,
      0.026741061359643936,
      -0.11629407852888107,
      0.02307235077023506,
      0.0025193290784955025,
      -0.02301061898469925,
      0.046928469091653824,
      0.005363716743886471,
      -0.0035154533106833696,
      -0.034695155918598175,
      -0.02581251785159111,
      -0.04066983982920647,
      -0.02141462452709675,
      -0.005640851333737373,
      0.023737655952572823,
      0.007927890866994858,
      0.034382857382297516,
      0.05275331065058708,
      0.005173958837985992,
      0.03250635042786598,
      0.05529000610113144,
      0.045079831033945084,
      0.05295661836862564,
      0.017365137115120888,
      0.04933556169271469,
      0.05703774094581604,
      -0.025367267429828644,
      0.041898295283317566,
      0.048577986657619476,
      -0.059413082897663116,
      -0.017094025388360023,
      -0.03250452131032944,
      -0.01988315023481846,
      -0.023267457261681557,
      0.017988476902246475,
      0.0007815549033693969,
      0.02160494588315487,
      0.047492191195487976,
      0.0689113438129425,
      -0.05250446870923042,
      -0.06019613891839981,
      0.062095727771520615,
      0.017317915335297585,
      0.07935065031051636,
      -0.030641822144389153,
      0.0008676608558744192,
      0.011889382265508175,
      0.0300650242716074,
      -0.0036317273043096066,
      -0.017725784331560135,
      -0.05564595386385918,
      -0.051473330706357956,
      -0.08152800053358078,
      0.024463985115289688,
      -0.0002066521265078336,
      0.03179391846060753,
      -0.005867287050932646,
      -0.11605080962181091,
      -0.03378377482295036,
      -0.001312555163167417,
      -0.01949053443968296,
      -0.03294818103313446,
      0.055380746722221375,
      0.061478037387132645,
      -0.012496449053287506,
      0.010593867860734463,
      -0.03856900706887245,
      -0.05143004655838013,
      -0.006122182123363018,
      0.002507865661755204,
      -0.06967975199222565,
      0.01754126511514187,
      0.059729889035224915,
      -0.09342020004987717,
      -0.027630016207695007,
      -0.021796196699142456,
      -0.0022034121211618185,
      0.06024856120347977,
      0.05478934943675995,
      0.01758723333477974,
      0.03708348423242569,
      -0.026181189343333244,
      0.10285457223653793,
      -0.005615335423499346,
      -0.045748066157102585,
      -0.003131252247840166,
      -0.027983414009213448,
      -0.02998247742652893,
      -0.042691681534051895,
      -0.010220188647508621,
      0.03700754791498184,
      0.01481720246374607,
      0.032400745898485184,
      -0.0009506319183856249,
      0.028204048052430153,
      0.04567829892039299,
      -0.04751279577612877,
      -0.08988334983587265,
      0.06808590143918991,
      -0.035960517823696136,
      -0.04688297584652901,
      -0.017276065424084663,
      -0.07493317872285843,
      0.07807562500238419,
      -0.033132512122392654,
      -0.06390080600976944,
      -0.002318921498954296,
      -0.09188555181026459,
      -0.028317049145698547,
      -0.013587407767772675,
      -0.0670023262500763,
      -0.011678548529744148,
      0.015782281756401062,
      -0.012617426924407482,
      0.05565762519836426,
      0.01381232962012291,
      -0.04871358349919319,
      -0.06060178205370903,
      -0.03376541659235954,
      0.035988468676805496,
      0.10389721393585205,
      -0.0016446721274405718,
      -0.024535352364182472,
      -0.0739370808005333,
      0.011269713751971722,
      -0.06896090507507324,
      -0.07158822566270828,
      0.12101823836565018,
      0.07302182912826538,
      0.06502079963684082,
      0.06573224812746048,
      0.06936857104301453,
      -0.05344589427113533,
      -0.01964496448636055,
      -0.06502719223499298,
      0.0887947529554367,
      0.02992505393922329,
      -0.03414319083094597,
      -0.004458534996956587,
      0.005198552273213863,
      0.06799361854791641,
      0.032672617584466934,
      -0.02430623769760132,
      -0.05546349659562111,
      0.053871601819992065,
      -0.045539867132902145,
      0.03821432590484619,
      -0.007627787534147501,
      -0.07265494018793106,
      -0.048933543264865875,
      0.057920921593904495,
      0.032822322100400925,
      0.0036144114565104246,
      -0.0318630076944828,
      0.032670121639966965,
      -0.040576908737421036,
      -0.007510396186262369,
      0.021031897515058517,
      0.019276946783065796,
      0.026867825537919998,
      -0.02603987045586109,
      -0.05386786535382271,
      -0.08380214869976044,
      0.05275851488113403,
      -0.03184853121638298,
      -0.07424654811620712,
      0.023909352719783783,
      0.06705544888973236,
      0.021859662607312202,
      0.0179399736225605,
      0.010933012701570988,
      -0.0016706233145669103,
      -0.011222833767533302,
      -0.08386370539665222,
      -0.03182709217071533,
      -0.03934192284941673,
      0.027822274714708328,
      -0.030020058155059814,
      -0.08027605712413788,
      -0.08868653327226639,
      0.03973434492945671,
      -0.038090456277132034,
      0.07777735590934753,
      -0.04698115587234497,
      -0.08901391178369522,
      0.0050244415178895,
      0.05677557364106178,
      0.006704234983772039,
      -0.013034937903285027,
      0.06727685779333115,
      -0.02427782118320465,
      -0.023470718413591385,
      -0.10761565715074539,
      -0.01605474390089512,
      0.0486723855137825,
      -0.05333954840898514,
      -0.03749098628759384,
      0.016299419105052948,
      -0.03029981628060341,
      -0.024228885769844055,
      0.07167039066553116,
      -0.0028064658399671316,
      0.03568128123879433
    ],
    [
      0.058819063007831573,
      -0.021067600697278976,
      0.059108518064022064,
      -0.0335112102329731,
      -0.04716608673334122,
      -0.07208919525146484,
      -0.018072672188282013,
      0.045149628072977066,
      -0.014650532975792885,
      0.026510359719395638,
      -0.08503378182649612,
      -0.08859340846538544,
      -0.06276433169841766,
      0.09317214041948318,
      -0.0008699489990249276,
      -0.025542762130498886,
      -0.02589401602745056,
      -0.03524983674287796,
      0.024483317509293556,
      0.023943375796079636,
      -0.05773674324154854,
      -0.03229888901114464,
      0.0035289183724671602,
      -0.05035480856895447,
      -0.01865226775407791,
      -0.017730778083205223,
      -0.0275177750736475,
      -0.09457285702228546,
      -0.008145339787006378,
      -0.0021932742092758417,
      -0.06240525841712952,
      -0.03823673725128174,
      -0.06053727865219116,
      0.009211824275553226,
      0.07346384972333908,
      -0.08224959671497345,
      -0.04065626487135887,
      -0.014857808127999306,
      0.0306384414434433,
      0.05658862367272377,
      0.026488913223147392,
      -0.019684402272105217,
      0.039678044617176056,
      -0.016155404970049858,
      0.02406606264412403,
      0.045593034476041794,
      -0.012026439420878887,
      0.0022498106118291616,
      -0.014042755588889122,
      0.10312777012586594,
      -0.004112060181796551,
      -0.019520342350006104,
      0.052217137068510056,
      -0.01652766577899456,
      0.09613365679979324,
      -0.04863238334655762,
      0.02940203621983528,
      0.028366615995764732,
      -0.029962759464979172,
      0.010393895208835602,
      0.08663500100374222,
      -0.02207641862332821,
      0.01489963848143816,
      -0.03135392814874649,
      0.08905888348817825,
      0.009386584162712097,
      0.03288422152400017,
      0.059017620980739594,
      -0.04208321124315262,
      -0.07453694194555283,
      0.03772301226854324,
      0.02652563527226448,
      0.013990306295454502,
      -0.03751477226614952,
      -0.02220737747848034,
      -0.07306093722581863,
      0.0009444468887522817,
      -0.011459807865321636,
      -0.022060303017497063,
      -0.06866924464702606,
      0.014040328562259674,
      0.020782379433512688,
      -0.018562519922852516,
      0.07290395349264145,
      0.08067911118268967,
      -0.0063431295566260815,
      0.008240832015872002,
      0.012862361036241055,
      -0.022378718480467796,
      -0.02588978409767151,
      0.06578131020069122,
      0.02172720804810524,
      -0.06679978221654892,
      -0.061889246106147766,
      0.11849330365657806,
      0.013556611724197865,
      0.02842818945646286,
      -0.04852981120347977,
      -0.040540263056755066,
      -0.04621438682079315,
      0.039172302931547165,
      0.05246700346469879,
      0.007237862795591354,
      0.018947819247841835,
      -0.001964550232514739,
      0.0086106201633811,
      0.04284977912902832,
      -0.062229257076978683,
      0.033575549721717834,
      -0.06272140145301819,
      0.023481449112296104,
      -0.005502704996615648,
      0.03434954583644867,
      0.01824849285185337,
      -0.01435970887541771,
      0.060250505805015564,
      -0.07641755044460297,
      0.0534634031355381,
      -0.026987392455339432,
      0.0338548980653286,
      -0.05569310113787651,
      -0.06026756018400192,
      0.0073289149440824986,
      -0.01673332415521145,
      -0.004490511491894722,
      -0.008584914728999138,
      -0.03631136566400528,
      0.01226052176207304,
      0.018433241173624992,
      -0.023977700620889664,
      -0.004075643140822649,
      -0.019860556349158287,
      -0.01610015146434307,
      -0.013100318610668182,
      0.05104878917336464,
      -0.012525622732937336,
      -0.024333054199814796,
      -0.005135849118232727,
      0.012847484089434147,
      -0.06446384638547897,
      -0.02475050278007984,
      -0.018849115818738937,
      0.08988723903894424,
      0.009615255519747734,
      0.06209597736597061,
      -0.0028475529979914427,
      -0.0597095787525177,
      -0.039933983236551285,
      -0.010411093011498451,
      0.0018455222016200423,
      -0.030864015221595764,
      -0.005142494570463896,
      0.14055895805358887,
      -0.0015778063097968698,
      0.011441702023148537,
      -0.08809392154216766,
      -0.03698187693953514,
      -0.027161279693245888,
      -0.07016140967607498,
      -0.01462553534656763,
      0.06470180302858353,
      -0.0860230103135109,
      0.014283590950071812,
      -0.017456460744142532,
      0.02017553150653839,
      0.008672106079757214,
      0.04401189088821411,
      -0.06055722013115883,
      0.027358459308743477,
      -0.03366412967443466,
      -0.00048037891974672675,
      0.026966553181409836,
      -0.023490456864237785,
      0.007657439913600683,
      0.012806030921638012,
      0.05173584818840027,
      0.07432566583156586,
      -0.02255195751786232,
      0.031826503574848175,
      0.0465034618973732,
      -0.04787580668926239,
      0.012500271201133728,
      -0.020347774028778076,
      0.018721308559179306,
      0.056663304567337036,
      -0.08322244137525558,
      -0.06271395832300186,
      0.034444838762283325,
      0.042619574815034866,
      0.02655632607638836,
      0.008662422187626362,
      0.03783661499619484,
      0.0008765152306295931,
      -0.05227045342326164,
      -0.00613268930464983,
      -0.08134623616933823,
      0.02363329753279686,
      0.016276691108942032,
      0.057179488241672516,
      0.016983404755592346,
      0.014142940752208233,
      -0.04051041603088379,
      0.05222160741686821,
      0.09472709149122238,
      -0.05000663548707962,
      -0.02548048458993435,
      0.033872149884700775,
      0.08580128848552704,
      -0.03590387850999832,
      -0.045484621077775955,
      -0.024349123239517212,
      -0.0008753431029617786,
      -0.023184359073638916,
      0.03977515920996666,
      -0.029114674776792526,
      0.02968468889594078,
      0.003683729562908411,
      -0.009630612097680569,
      0.05376667529344559,
      0.10033351182937622,
      0.05140286311507225,
      -0.05406937375664711,
      0.07989732176065445,
      -0.03784853219985962,
      -0.026867715641856194,
      -0.049071986228227615,
      0.01097033079713583,
      -0.07456526905298233,
      0.07820677012205124,
      -0.006351216696202755,
      0.10454899072647095,
      -0.030702531337738037,
      0.012892959639430046,
      0.017329735681414604,
      0.008253948763012886,
      0.05000484362244606,
      -0.009627685882151127,
      -0.020405707880854607,
      -0.03389834240078926,
      -0.06145729124546051,
      0.040801215916872025,
      0.009161034598946571,
      0.029504776000976562,
      -0.01236462127417326,
      -0.05924350395798683,
      -0.006955158896744251,
      -0.06893573701381683,
      0.0583890862762928,
      -0.025474390015006065,
      -0.023588145151734352,
      0.004339656792581081,
      -0.02584327757358551,
      0.07103875279426575,
      -0.004090680740773678,
      0.00024399854009971023,
      0.03041856177151203,
      0.025529509410262108,
      -0.0016714156372472644,
      0.03306635841727257,
      0.03551211953163147,
      -0.008234013803303242,
      -0.0479130893945694,
      -0.004800769500434399,
      0.07627085596323013,
      0.03402409330010414,
      0.03184961900115013,
      0.03888589143753052,
      0.0465611070394516,
      0.1149233728647232,
      -0.03133848309516907,
      -0.004350773058831692,
      -0.019430773332715034,
      0.030704252421855927,
      -0.05358987674117088,
      0.0012846451718360186,
      0.008362545631825924,
      -0.028265878558158875,
      -0.06585348397493362,
      0.09338337182998657,
      -0.021801820024847984,
      -0.010168934240937233,
      0.059000786393880844,
      0.0534069687128067,
      -0.03492886200547218,
      0.0038233681116253138,
      -0.012291304767131805,
      -0.04009015113115311,
      -0.07267991453409195,
      -0.05286134406924248,
      -0.0631859302520752,
      -0.014087878167629242,
      0.08462052047252655,
      0.032816819846630096,
      0.016526728868484497,
      0.024422990158200264,
      0.08355389535427094,
      -0.04412095248699188,
      0.028690794482827187,
      -0.015414443798363209,
      -0.019096435979008675,
      -0.0005524834850803018,
      -0.011380149982869625,
      -0.013762764632701874,
      0.02255120314657688,
      -0.01267126202583313,
      0.01902751997113228,
      -0.05301312357187271,
      0.02643398754298687,
      -0.05713523179292679,
      0.000437857408542186,
      -0.003657291876152158,
      -0.01067845243960619,
      -0.005834926851093769,
      0.03690720349550247,
      -0.020444847643375397,
      -0.007628167979419231,
      0.03764675185084343,
      -0.03826654329895973,
      0.03816283494234085,
      -0.03125163912773132,
      0.014870191924273968,
      -0.03892022371292114,
      -0.038571521639823914,
      0.03593572601675987,
      0.04080270975828171,
      0.03830542415380478,
      0.030999215319752693,
      -0.06643498688936234,
      -0.030742423608899117,
      0.039273977279663086,
      0.04172539338469505,
      0.061482060700654984,
      -0.04542962834239006,
      0.02089889720082283,
      0.02744976431131363,
      0.06667117029428482,
      0.03422803431749344,
      -0.030410142615437508,
      0.0011564011219888926,
      0.055818118155002594,
      0.010587741620838642,
      -0.015510656870901585,
      0.026594987139105797,
      0.020924588665366173,
      0.0022987148258835077,
      -0.020953381434082985,
      0.013124902732670307,
      -0.08388742804527283,
      0.0105470335111022,
      -0.011959867551922798,
      0.0025755527894943953,
      0.024469058960676193,
      0.03568759188055992,
      0.04322264716029167,
      -0.018035003915429115,
      0.024053283035755157,
      0.01809190772473812,
      0.016569094732403755,
      0.045628562569618225,
      0.03654082119464874,
      -0.004061317536979914,
      -0.02894474007189274,
      -0.0560939759016037,
      -0.0644076019525528,
      -0.03031444177031517,
      0.005447557661682367,
      0.02678803913295269,
      -0.0603431835770607,
      -0.02336251176893711,
      0.021085310727357864,
      -0.03426332771778107,
      -0.025664901360869408,
      -0.020082253962755203,
      0.03344065696001053,
      0.035135019570589066,
      0.03936227411031723,
      0.019708050414919853,
      0.026264065876603127,
      -0.011554764583706856,
      -0.08072083443403244,
      -0.022817669436335564,
      0.02661694958806038,
      0.05592437461018562,
      -0.0228078905493021,
      -0.06727593392133713,
      -0.027233054861426353,
      -0.00996735505759716,
      -0.08270644396543503,
      -0.033322546631097794,
      0.014479662291705608,
      -0.04342887923121452,
      -0.04560180753469467,
      0.05040658265352249,
      -0.02219446748495102,
      0.08819664269685745,
      0.023841649293899536,
      -0.060906149446964264,
      -0.08515152335166931,
      0.0435585118830204,
      0.03027275577187538,
      -0.0012864376185461879,
      -0.04249516874551773,
      -0.01061679795384407,
      0.04370187222957611,
      -0.003526237327605486,
      -0.13498111069202423,
      0.009523389860987663,
      0.039567235857248306,
      0.036266013979911804,
      -0.027323849499225616,
      -0.013235063292086124,
      0.028836296871304512,
      -0.009000349789857864,
      -0.014855403453111649,
      0.058956507593393326,
      -0.06957545876502991,
      0.03468363359570503,
      0.01796717196702957,
      -0.010242919437587261,
      0.04171967878937721,
      0.018497008830308914,
      -0.06994803994894028,
      0.04752342402935028,
      -0.0025391592644155025,
      -0.04243408516049385,
      -0.05455963686108589,
      -0.08679866045713425,
      0.0004980203812010586,
      -0.08647933602333069,
      0.015674220398068428,
      0.024429012089967728,
      -0.034626152366399765,
      0.011470159515738487,
      -0.03194274753332138,
      -0.013128707185387611,
      0.053136419504880905,
      -0.05660000815987587,
      -0.0022120585199445486,
      -0.061877984553575516,
      -0.05032746493816376,
      -0.026637783274054527,
      -0.005960691254585981,
      0.04748401418328285,
      -0.007381907664239407,
      0.04893965646624565,
      0.05527285858988762,
      -0.09994598478078842,
      0.02369840070605278,
      -0.006191759370267391,
      -0.022597800940275192,
      0.012669837102293968,
      -0.0032396966125816107,
      0.0050288899801671505,
      0.018651343882083893,
      -0.05460396036505699,
      0.020093103870749474,
      0.03402934595942497,
      -0.03052976354956627,
      0.03170439228415489,
      -0.07616443932056427,
      0.013478097505867481,
      -0.04805683344602585,
      -0.0024034716188907623,
      0.039453476667404175,
      -0.04387667775154114,
      0.004301195498555899,
      -0.010494179092347622,
      -0.04453828185796738,
      0.08888038992881775,
      -0.026570778340101242,
      0.04125910624861717,
      -0.04630114138126373,
      0.03696691617369652,
      -0.01032550074160099,
      0.0839947983622551,
      -0.0676785483956337,
      0.051733825355768204,
      -0.0656912624835968,
      0.029381992295384407,
      -0.026706574484705925,
      -0.05967625603079796,
      0.03372124210000038,
      -0.04783269390463829,
      0.0028639566153287888,
      -0.010140348225831985,
      0.03613821044564247,
      0.03840580955147743,
      -0.026321226730942726,
      0.007048547733575106,
      0.06508961319923401,
      0.015457624569535255,
      0.017162969335913658,
      0.008952361531555653,
      -0.028081394731998444,
      0.01861109957098961,
      0.0021729590371251106,
      -0.03185509517788887,
      -0.020947502925992012,
      0.06085983291268349,
      -0.023772455751895905,
      0.07772643864154816,
      0.04538242146372795,
      -0.03584609553217888
    ],
    [
      0.04617224633693695,
      -0.019081445410847664,
      0.03701929375529289,
      0.017335012555122375,
      0.025350647047162056,
      -0.016610173508524895,
      0.017308704555034637,
      0.004172982182353735,
      -0.024101369082927704,
      -0.027624202892184258,
      0.07951508462429047,
      -0.05597619339823723,
      -0.01637287437915802,
      -0.006334392819553614,
      0.02973700873553753,
      0.04170893505215645,
      -0.043291062116622925,
      0.014772913418710232,
      0.019604086875915527,
      0.01560198049992323,
      -0.05549447238445282,
      -0.04249930381774902,
      0.01570231094956398,
      -0.06425940990447998,
      -0.03730423375964165,
      -0.04111025482416153,
      -0.014158402569591999,
      0.033510658890008926,
      0.08939968794584274,
      -0.001372877391986549,
      0.041259344667196274,
      0.03170212730765343,
      -0.016954444348812103,
      0.04818345606327057,
      0.008417749777436256,
      0.0038579581305384636,
      0.05453507974743843,
      0.0596732497215271,
      0.027288494631648064,
      -0.043073609471321106,
      0.006229138933122158,
      -0.025145919993519783,
      0.0018558679148554802,
      -0.012315630912780762,
      0.03921789303421974,
      -0.05445998162031174,
      -0.025562673807144165,
      0.01718323677778244,
      -0.031139956787228584,
      -0.04940276965498924,
      -0.031289417296648026,
      0.026294313371181488,
      0.06332294642925262,
      0.10718975961208344,
      0.011401151306927204,
      -0.044979024678468704,
      -0.04227345809340477,
      0.03358510509133339,
      0.029824327677488327,
      0.0034790243953466415,
      -0.07210743427276611,
      0.017429936677217484,
      -0.02746558003127575,
      0.04579662159085274,
      -0.027087649330496788,
      0.04637620598077774,
      0.03073452226817608,
      -0.05976175516843796,
      0.005335445981472731,
      0.05537158623337746,
      0.06447203457355499,
      -0.05436298996210098,
      -0.03866898640990257,
      -0.07832297682762146,
      0.08183805644512177,
      -0.056969985365867615,
      -0.061314910650253296,
      -0.02044917643070221,
      0.047320086508989334,
      0.03411271795630455,
      0.0523441806435585,
      0.0021039985585957766,
      0.019120512530207634,
      0.023619655519723892,
      -0.0503121018409729,
      -0.009000629186630249,
      0.06443070620298386,
      -0.021146582439541817,
      0.0021974791307002306,
      -0.0029636623803526163,
      -0.09150052815675735,
      -0.051291439682245255,
      -0.008269964717328548,
      0.09337357431650162,
      0.0024518489371985197,
      0.11993702501058578,
      0.06175382062792778,
      -0.00985111203044653,
      -0.06430896371603012,
      -0.03360289707779884,
      0.015313984826207161,
      -0.06361160427331924,
      0.036663323640823364,
      -0.0006204608944244683,
      -0.014136469922959805,
      -0.052562493830919266,
      -0.01619848795235157,
      -0.027821999043226242,
      0.009653993882238865,
      -0.11554015427827835,
      0.04182467237114906,
      -0.008821551688015461,
      0.0002759566705208272,
      0.06694930046796799,
      -0.05138076841831207,
      -0.012772242538630962,
      -0.034093696624040604,
      0.07668855786323547,
      0.03919032961130142,
      0.013291973620653152,
      -0.009280751459300518,
      0.046861231327056885,
      0.029203854501247406,
      -0.03663591295480728,
      -0.03669978678226471,
      -0.024543019011616707,
      -0.030194971710443497,
      0.018601877614855766,
      -0.0377139113843441,
      0.055747292935848236,
      -0.0049448865465819836,
      0.042898282408714294,
      0.014632935635745525,
      -0.011554342694580555,
      0.04872645437717438,
      0.039697013795375824,
      0.0848664939403534,
      -0.028443163260817528,
      0.006635536439716816,
      0.029398484155535698,
      0.14099882543087006,
      0.04472586140036583,
      -0.005573627073317766,
      -0.04357365518808365,
      0.018007392063736916,
      0.06878992170095444,
      0.024551572278141975,
      -0.010733296163380146,
      -0.013925855048000813,
      -0.009190144017338753,
      -0.0012973351404070854,
      -0.014084148220717907,
      0.0503600612282753,
      0.053849294781684875,
      -0.0031773492228239775,
      0.07024156302213669,
      -0.033485885709524155,
      0.0219179168343544,
      0.08607698231935501,
      0.05089506134390831,
      -0.004547827877104282,
      -0.05260616913437843,
      0.051808107644319534,
      0.013384857214987278,
      0.013404706493020058,
      -0.021854331716895103,
      0.03139914944767952,
      0.012528783641755581,
      0.019246548414230347,
      -0.006011027842760086,
      0.05300237983465195,
      0.004613575525581837,
      0.04919290170073509,
      -0.013508978299796581,
      -0.0016759802820160985,
      0.04254510998725891,
      0.023215362802147865,
      -0.03293495997786522,
      0.0009995964355766773,
      0.02438414841890335,
      0.006584725808352232,
      0.0674852505326271,
      -0.034275006502866745,
      0.001437501166947186,
      -0.027279812842607498,
      -0.06712749600410461,
      0.02658849023282528,
      -0.00020364932424854487,
      -0.002775307511910796,
      0.03309926018118858,
      0.00339955254457891,
      -0.028728747740387917,
      -0.009068435989320278,
      0.020197812467813492,
      0.08992444723844528,
      0.05844694748520851,
      0.008532627485692501,
      0.009310135617852211,
      0.08344153314828873,
      -0.024999454617500305,
      -0.00107329071033746,
      0.056975800544023514,
      0.06860706955194473,
      0.026525210589170456,
      -0.028540078550577164,
      0.048781685531139374,
      0.03712460398674011,
      -0.0354660302400589,
      0.039629194885492325,
      -0.022312261164188385,
      0.018055520951747894,
      -0.029714100062847137,
      -0.003807858796790242,
      0.0009005200117826462,
      -0.0027366967406123877,
      0.032809704542160034,
      -0.006536384578794241,
      -0.003000174416229129,
      0.02759999781847,
      0.09525100886821747,
      0.06436290591955185,
      -0.08585051447153091,
      0.034723177552223206,
      0.07072355598211288,
      0.039788927882909775,
      0.0033388417214155197,
      -0.03625473752617836,
      -0.07820454984903336,
      -0.0002549001364968717,
      0.048149168491363525,
      0.07724925130605698,
      -0.017706528306007385,
      -0.06629510968923569,
      0.017522450536489487,
      -0.04520098492503166,
      0.008687073364853859,
      -0.0064333039335906506,
      -0.047080256044864655,
      0.010194184258580208,
      0.0590025968849659,
      -0.026683367788791656,
      -0.024038344621658325,
      0.012290687300264835,
      0.015404939651489258,
      -0.008280089125037193,
      0.03057442046701908,
      0.018753279000520706,
      0.023957137018442154,
      0.09313327074050903,
      0.0650385245680809,
      -0.05370895937085152,
      0.02272031269967556,
      -0.03279174491763115,
      0.013682122342288494,
      -0.02104152925312519,
      0.055204372853040695,
      -0.07188680022954941,
      -0.03522622212767601,
      -0.008365990594029427,
      0.04387141019105911,
      0.01724802516400814,
      -0.004588478244841099,
      -0.059016816318035126,
      0.05105102062225342,
      0.07191687077283859,
      -0.004251870326697826,
      -0.043920811265707016,
      -0.041374463587999344,
      0.08143571764230728,
      0.014934001490473747,
      0.029455870389938354,
      -0.027031509205698967,
      -0.01575666479766369,
      -0.041498810052871704,
      0.044357214123010635,
      0.03966313600540161,
      0.08671323210000992,
      -0.005216970108449459,
      0.023906037211418152,
      -0.0863882377743721,
      -0.08432704210281372,
      -0.0014563541626557708,
      -0.056590791791677475,
      0.04630950465798378,
      -0.030660048127174377,
      -0.008785325102508068,
      -0.04713243991136551,
      -0.07680248469114304,
      0.0603315494954586,
      -0.0065314751118421555,
      -0.045365963131189346,
      -0.12324215471744537,
      0.06159733608365059,
      0.003882148303091526,
      0.004392929375171661,
      0.05555304139852524,
      -0.038449522107839584,
      0.0600418895483017,
      0.07772403210401535,
      0.07645535469055176,
      -0.042325928807258606,
      0.021574152633547783,
      -0.011641906574368477,
      -0.07717431336641312,
      -0.026722649112343788,
      0.024607215076684952,
      0.0002842186950147152,
      -0.03405264392495155,
      0.0418735146522522,
      -0.04395155981183052,
      -0.03491286560893059,
      0.035650257021188736,
      0.07737815380096436,
      0.040789615362882614,
      -0.03888815641403198,
      -0.04354877769947052,
      -0.014571549370884895,
      0.006476240232586861,
      0.035070646554231644,
      0.026962634176015854,
      -0.00155971001368016,
      0.06909962743520737,
      -0.047669339925050735,
      -0.0026813638396561146,
      -0.004832916427403688,
      -0.03660764545202255,
      -0.01811215467751026,
      -0.0431680828332901,
      -0.021877987310290337,
      0.018467769026756287,
      3.811276110354811e-05,
      -0.027303216978907585,
      0.01416596956551075,
      0.006469856481999159,
      0.07257694751024246,
      -0.06924721598625183,
      -0.017487619072198868,
      0.010195350274443626,
      -0.07016663998365402,
      0.012302965857088566,
      -0.02127717062830925,
      -0.03350086510181427,
      0.004993773996829987,
      0.03532060235738754,
      -0.011411781422793865,
      0.06141706183552742,
      0.01455047819763422,
      -0.022869279608130455,
      -0.009474084712564945,
      0.07517431676387787,
      0.05769497901201248,
      0.031079154461622238,
      -0.0017024663975462317,
      0.09791982918977737,
      -0.023137792944908142,
      0.04209774360060692,
      0.008558022789657116,
      0.008054099045693874,
      0.026890745386481285,
      0.008137342520058155,
      -0.028742624446749687,
      0.014432404190301895,
      -0.036216605454683304,
      -0.06501297652721405,
      -0.016318270936608315,
      -0.07873629033565521,
      -0.002848556265234947,
      0.09472914785146713,
      -0.026815565302968025,
      0.029308106750249863,
      0.03833889588713646,
      0.041876401752233505,
      -0.025706075131893158,
      -0.03778545930981636,
      -0.007090234663337469,
      0.038681529462337494,
      -0.032352011650800705,
      0.057503897696733475,
      0.025466162711381912,
      -0.05641082674264908,
      0.0007326570339500904,
      -0.0035686781629920006,
      0.003668248187750578,
      -0.06323439627885818,
      -0.02446075528860092,
      0.05714734271168709,
      -0.034013859927654266,
      0.053191687911748886,
      -0.07418924570083618,
      -0.05459390580654144,
      -0.008057685568928719,
      -0.04735298454761505,
      0.035412244498729706,
      -0.018281713128089905,
      -0.08600586652755737,
      -0.05576223507523537,
      0.013722937554121017,
      -0.04700843617320061,
      0.011371797882020473,
      -0.06170869618654251,
      0.015143893659114838,
      -0.024456126615405083,
      -0.017005009576678276,
      0.032455913722515106,
      0.030284669250249863,
      -0.012987576425075531,
      -0.05235835164785385,
      0.029069507494568825,
      0.006937234662473202,
      0.003242011182010174,
      -0.0460904985666275,
      0.02490314468741417,
      0.016657264903187752,
      -0.00697603402659297,
      -0.02209949865937233,
      0.04457002878189087,
      0.052878305315971375,
      0.011327126994729042,
      0.010216421447694302,
      -0.0012253584573045373,
      0.08511961996555328,
      0.086811363697052,
      0.009728513658046722,
      -0.03129957243800163,
      -0.02166728302836418,
      -0.009408330544829369,
      -0.04517992213368416,
      -0.03794476389884949,
      -0.03950793668627739,
      0.06099898740649223,
      0.027989739552140236,
      0.05711263790726662,
      -0.008057333528995514,
      0.00423614215105772,
      0.055704303085803986,
      0.07981248944997787,
      0.007453306578099728,
      -0.004456901922821999,
      -0.033586401492357254,
      -0.04231568053364754,
      0.01080898754298687,
      0.053173862397670746,
      0.012452417984604836,
      0.00551998196169734,
      0.08805422484874725,
      -0.03684883192181587,
      -0.0005552430520765483,
      -0.0072183506563305855,
      -0.07365541160106659,
      -0.060357462614774704,
      -0.045301537960767746,
      -0.012409641407430172,
      -0.031857773661613464,
      0.04821579158306122,
      0.0420420840382576,
      -0.036801792681217194,
      -0.007651634514331818,
      -0.03914211317896843,
      -0.05633530765771866,
      -0.013122315518558025,
      -0.0988970473408699,
      -0.05012398585677147,
      0.03818381950259209,
      -0.033304668962955475,
      -0.012200978584587574,
      0.0002002735564019531,
      0.0261427890509367,
      -0.01439411100000143,
      0.10114891827106476,
      0.07744082063436508,
      -0.029027242213487625,
      0.02276480942964554,
      0.07121187448501587,
      -0.061408739537000656,
      -0.020207669585943222,
      -0.003185846609994769,
      -0.006699803285300732,
      0.025814909487962723,
      -0.12359623610973358,
      -0.04093743488192558,
      0.0006242832751013339,
      0.030602043494582176,
      0.0012177200987935066,
      -0.04922845587134361,
      0.0017939837416633964,
      -0.047389138489961624,
      0.025049002841114998,
      -0.02032790333032608,
      -0.07119984179735184,
      0.04790334776043892,
      -0.02679300121963024,
      0.007124934811145067,
      0.008504116907715797,
      -0.04029171168804169,
      0.05451267957687378,
      -0.03310108557343483,
      -0.003571744542568922,
      0.023417118936777115,
      0.003185027977451682,
      -0.016510359942913055,
      -0.002491183113306761,
      -0.004605358000844717,
      0.05723698064684868
    ],
    [
      -0.016610560938715935,
      -0.011882081627845764,
      0.013478739187121391,
      0.049916476011276245,
      0.021771835163235664,
      0.020456640049815178,
      -0.03970498964190483,
      0.02345123514533043,
      0.03831365332007408,
      0.05467848479747772,
      -0.040077123790979385,
      0.013510314747691154,
      -0.062428202480077744,
      -0.08941715210676193,
      -0.025303030386567116,
      0.08257194608449936,
      0.024907080456614494,
      -0.025523550808429718,
      0.03419423848390579,
      0.0035224626772105694,
      -0.000308032613247633,
      0.03269386291503906,
      -0.09055422246456146,
      -0.0694919303059578,
      0.040295302867889404,
      -0.0566333532333374,
      0.004493573680520058,
      -0.05483469367027283,
      -0.0038158029783517122,
      0.04316984862089157,
      -0.07018160074949265,
      -0.08918801695108414,
      0.006671662908047438,
      -0.003676035674288869,
      -0.09052439779043198,
      0.06581610441207886,
      -0.05265522748231888,
      0.053274787962436676,
      -0.004343122243881226,
      -0.042264174669981,
      0.06191578134894371,
      0.011084549129009247,
      -0.01698833517730236,
      0.1146150678396225,
      -0.028163589537143707,
      0.060713350772857666,
      0.0487796887755394,
      0.08708266168832779,
      0.0598674900829792,
      -0.06593627482652664,
      0.022686097770929337,
      -0.07098179310560226,
      0.0057710763067007065,
      -0.04363173991441727,
      0.018145792186260223,
      -0.042441610246896744,
      0.0028260627295821905,
      0.002885212888941169,
      0.10022589564323425,
      -0.03251861035823822,
      -0.04974783584475517,
      -0.0005530541529878974,
      -0.02825043722987175,
      0.09842496365308762,
      0.008590970188379288,
      0.010353365913033485,
      0.018871089443564415,
      -0.0011392149608582258,
      -0.02387808822095394,
      0.009269109927117825,
      0.0020516240037977695,
      0.041132908314466476,
      0.01614377461373806,
      0.04040856659412384,
      -0.059100016951560974,
      0.003625225042924285,
      0.017888791859149933,
      -0.04546842724084854,
      0.009056893177330494,
      -0.033144447952508926,
      -0.014623929746448994,
      0.04207929968833923,
      0.032587021589279175,
      -0.03457081690430641,
      -0.01635591685771942,
      -0.0037088505923748016,
      -0.0028576068580150604,
      -0.035928696393966675,
      -0.11836972087621689,
      0.04425430670380592,
      0.028998330235481262,
      -0.040357235819101334,
      -0.009515265934169292,
      0.06900292634963989,
      -0.03709835186600685,
      0.02035089209675789,
      0.03258220851421356,
      0.006049902644008398,
      -0.022630518302321434,
      -0.03010258823633194,
      -0.07328061759471893,
      0.01788310334086418,
      -0.008379723876714706,
      0.04682314768433571,
      -0.020307905972003937,
      -0.0029840581119060516,
      0.0287206768989563,
      -0.03821858391165733,
      -0.0397866815328598,
      0.010097110643982887,
      -0.09753839671611786,
      -0.04963059723377228,
      -0.00566779775545001,
      0.011425374075770378,
      0.04550331085920334,
      0.061245113611221313,
      -0.046363331377506256,
      -0.0006820776034146547,
      -0.06811585277318954,
      -0.01680397056043148,
      0.038958918303251266,
      0.03307690843939781,
      -0.013326123356819153,
      -0.04086858779191971,
      -0.05432989448308945,
      -0.020426930859684944,
      -0.02100709266960621,
      0.017711304128170013,
      0.04811032488942146,
      -0.004500804003328085,
      0.0295780748128891,
      0.0022775859106332064,
      -0.02439524605870247,
      0.026859324425458908,
      -0.035298943519592285,
      0.001952196704223752,
      -0.020269624888896942,
      0.0010749216889962554,
      -0.11021336913108826,
      0.05027309060096741,
      0.0737227201461792,
      0.07879611849784851,
      0.025744115933775902,
      0.076186403632164,
      -0.0013722139410674572,
      0.03501346707344055,
      0.08070705831050873,
      0.009376224130392075,
      0.0013378350995481014,
      0.0028560874052345753,
      -0.04162858426570892,
      -0.002610463183373213,
      0.049944471567869186,
      -0.005970162805169821,
      -0.021861236542463303,
      0.05387208238244057,
      -0.013922322541475296,
      0.023737534880638123,
      0.056484125554561615,
      -0.03388353809714317,
      -0.03101237490773201,
      -0.04937870800495148,
      0.00486889760941267,
      -0.10445567220449448,
      0.0405481792986393,
      0.0253277774900198,
      -0.02606547623872757,
      -0.07668060809373856,
      -0.00398632325232029,
      0.006610711105167866,
      -0.015549825504422188,
      -0.06211480870842934,
      -0.00798829272389412,
      0.12098091840744019,
      -0.007565426640212536,
      -0.037827689200639725,
      0.018138431012630463,
      -0.020694628357887268,
      0.0781981572508812,
      -0.09345249086618423,
      0.01438002660870552,
      -0.013172074221074581,
      -0.024709872901439667,
      0.05323413759469986,
      0.053377147763967514,
      -0.013221717439591885,
      0.017332082614302635,
      0.034866973757743835,
      0.0022694915533065796,
      -0.005229210946708918,
      0.012849196791648865,
      0.027086475864052773,
      0.06219092011451721,
      -0.0199571680277586,
      -0.06305500119924545,
      -0.032467618584632874,
      -0.010843398049473763,
      -0.03225765749812126,
      0.15476638078689575,
      -0.01723785698413849,
      0.009728672914206982,
      -0.00754681508988142,
      0.054420143365859985,
      0.013052018359303474,
      -0.03355446085333824,
      0.0030391726177185774,
      0.050236329436302185,
      -0.025373592972755432,
      -0.013247418217360973,
      0.05715211480855942,
      -0.12368059158325195,
      -0.021712886169552803,
      0.009721285663545132,
      -0.025322534143924713,
      -0.023934945464134216,
      0.08934728056192398,
      -0.047381628304719925,
      -0.046829208731651306,
      0.0009734096238389611,
      0.10540790855884552,
      0.025506751611828804,
      -0.04056544974446297,
      0.02724432945251465,
      -0.051730457693338394,
      0.035313744097948074,
      0.007583040744066238,
      0.05399399623274803,
      -0.006929690483957529,
      0.015347877517342567,
      0.06414138525724411,
      -0.012604680843651295,
      -0.11390823125839233,
      0.020916277542710304,
      0.1281137615442276,
      -0.0202681552618742,
      0.008269562385976315,
      0.026819901540875435,
      0.021003108471632004,
      -0.029404645785689354,
      0.09006889909505844,
      0.05404478311538696,
      0.04493787884712219,
      -0.0077710747718811035,
      0.01104020420461893,
      -0.05614333972334862,
      0.02181609906256199,
      0.1488419622182846,
      0.008984583429992199,
      0.023931976407766342,
      -0.02761194109916687,
      -0.03723428398370743,
      -0.006176189985126257,
      -0.1209816262125969,
      -0.0915408581495285,
      -0.017585519701242447,
      -0.018998218700289726,
      0.007577602751553059,
      0.07444512099027634,
      0.01190973911434412,
      0.09740673005580902,
      0.0006290801684372127,
      0.05770197883248329,
      -0.06662755459547043,
      -0.016489623114466667,
      0.040781792253255844,
      0.059139735996723175,
      0.07588688284158707,
      0.058872729539871216,
      -0.0012454737443476915,
      0.05769345536828041,
      -0.00910821370780468,
      -0.03542942926287651,
      0.08898983895778656,
      -0.027972541749477386,
      0.03256729990243912,
      -0.05850978195667267,
      0.01581297069787979,
      0.01878272369503975,
      0.020383140072226524,
      -0.03372139856219292,
      -0.019771594554185867,
      -0.009289545938372612,
      -0.03243806213140488,
      0.021071646362543106,
      0.006509581580758095,
      -0.03057185932993889,
      -0.09214259684085846,
      -0.09689179807901382,
      0.040210139006376266,
      -0.04419725015759468,
      -0.028746891766786575,
      0.011710677295923233,
      0.028314286842942238,
      -0.05830659717321396,
      0.010230300016701221,
      -0.0010336254490539432,
      -0.01688469760119915,
      0.04703646898269653,
      -0.01997259072959423,
      0.016282791271805763,
      -0.05494778975844383,
      -0.030749570578336716,
      0.03544088453054428,
      0.03564831614494324,
      0.01946752332150936,
      -0.0409860759973526,
      -0.03769536688923836,
      0.028956150636076927,
      -0.0004600978863891214,
      -0.05711410194635391,
      -0.0396854504942894,
      -0.055662937462329865,
      0.030327655375003815,
      -0.007163267582654953,
      -0.03516898676753044,
      0.10452622175216675,
      -0.014430379495024681,
      -0.01989869214594364,
      -0.04584125056862831,
      0.019231775775551796,
      0.0373217910528183,
      0.013549906201660633,
      -0.06214340031147003,
      -0.004960351623594761,
      -0.049980804324150085,
      0.008383368141949177,
      0.06955885887145996,
      0.023571312427520752,
      -0.08069448918104172,
      0.003837813623249531,
      -0.0012604445219039917,
      0.021111242473125458,
      0.0022164101246744394,
      -0.018014267086982727,
      0.11890610307455063,
      -0.013943429104983807,
      -0.0517442524433136,
      0.0031260456889867783,
      -0.0004512887680903077,
      -0.06081148982048035,
      0.03986905515193939,
      0.05652985721826553,
      0.007664510048925877,
      0.013948727399110794,
      0.04687188193202019,
      0.023088356480002403,
      0.029146842658519745,
      0.022537516430020332,
      0.07018330693244934,
      0.03157466650009155,
      -0.020941970869898796,
      -0.02831834740936756,
      0.02329387702047825,
      -0.05968266725540161,
      0.04879579693078995,
      0.06323782354593277,
      -0.006342537701129913,
      -0.08874974399805069,
      -0.03184933960437775,
      0.019089095294475555,
      0.02285180427134037,
      0.07151240855455399,
      0.004979532677680254,
      -0.0896364226937294,
      0.014843136072158813,
      0.05999966710805893,
      0.023684397339820862,
      -0.01382515113800764,
      0.05302850902080536,
      0.032614901661872864,
      -0.0260067917406559,
      0.010909691452980042,
      -0.0032278895378112793,
      -0.014424917288124561,
      0.020563973113894463,
      0.0006099810125306249,
      0.012766686268150806,
      0.0291836466640234,
      -0.004242107272148132,
      0.02053982764482498,
      0.051675453782081604,
      0.022281305864453316,
      -0.030112868174910545,
      -0.04686207324266434,
      0.0006151552079245448,
      -0.012763538397848606,
      -0.0024369782768189907,
      0.015107988379895687,
      0.03289778158068657,
      0.07165630906820297,
      -0.00794580765068531,
      -0.017693333327770233,
      0.05322371423244476,
      -0.03360160440206528,
      -0.011709128506481647,
      -0.005580434575676918,
      2.0776738892891444e-05,
      0.004969257861375809,
      -0.006226205267012119,
      -0.023703839629888535,
      -0.011146952398121357,
      -0.02224787324666977,
      0.061208080500364304,
      -0.03259141743183136,
      0.03664841502904892,
      0.022272862493991852,
      0.010333629325032234,
      0.019259268417954445,
      -0.009361702017486095,
      0.007387863006442785,
      0.062126174569129944,
      0.02851773425936699,
      -0.06133544445037842,
      -0.026801608502864838,
      0.023550227284431458,
      -0.0023950820323079824,
      0.0010246129240840673,
      -0.02416449971497059,
      -0.006854403764009476,
      0.06471769511699677,
      0.022706888616085052,
      0.0605565682053566,
      -0.0870710238814354,
      -0.045391641557216644,
      -0.05047469586133957,
      -0.01654798910021782,
      0.008876221254467964,
      0.11550644785165787,
      0.008285421878099442,
      -0.016375655308365822,
      0.06420139968395233,
      -0.039562780410051346,
      -0.11676876991987228,
      -0.014033876359462738,
      -0.025240061804652214,
      0.022587811574339867,
      0.0040624020621180534,
      -0.0362604595720768,
      -0.057051610201597214,
      0.01635420322418213,
      0.008454149588942528,
      -0.07553455233573914,
      -0.005198306404054165,
      -0.022456564009189606,
      -0.0566292442381382,
      0.009343486279249191,
      0.020083218812942505,
      0.012304797768592834,
      -0.007787328213453293,
      -0.04082399606704712,
      0.02021278813481331,
      -0.01219372171908617,
      0.02031533792614937,
      -0.04550372064113617,
      0.020722994580864906,
      0.0334521122276783,
      -0.03225206583738327,
      -0.028397858142852783,
      -0.06421291083097458,
      -0.014368508011102676,
      0.06784182041883469,
      0.00972867850214243,
      0.09739979356527328,
      -0.09412169456481934,
      0.039658840745687485,
      -0.016071561723947525,
      0.0545000396668911,
      0.030580826103687286,
      0.07868777960538864,
      0.060339201241731644,
      -0.06620530039072037,
      -0.010147318243980408,
      -0.0955522358417511,
      -0.014916815795004368,
      -0.0007287789485417306,
      0.06560445576906204,
      0.0470600500702858,
      0.01973985880613327,
      -0.0281259436160326,
      0.013016750104725361,
      -0.022054128348827362,
      -0.019728608429431915,
      -0.006435638293623924,
      0.01571502722799778,
      -0.05417186766862869,
      -0.004893294535577297,
      0.015355207026004791,
      0.01966404728591442,
      -0.031469523906707764,
      -0.0035473210737109184,
      -0.009197895415127277,
      0.008216739632189274,
      0.05910949409008026,
      0.08474323898553848,
      0.11009567975997925,
      0.03193037211894989,
      0.04414518550038338,
      0.032672181725502014,
      0.05478634685277939,
      0.09466654807329178,
      0.04718932881951332,
      0.010285708121955395,
      0.048037316650152206
    ],
    [
      0.038990240544080734,
      -0.012186406180262566,
      -0.025159412994980812,
      0.09839560836553574,
      0.0016084815142676234,
      -0.043311405926942825,
      0.030655905604362488,
      0.012526372447609901,
      0.005662803538143635,
      -0.07187668234109879,
      0.04134012758731842,
      0.031171806156635284,
      -0.06798870116472244,
      -0.01941428892314434,
      -0.013123051263391972,
      -0.014117632061243057,
      0.040577907115221024,
      -0.018615132197737694,
      -0.04676751047372818,
      0.031930018216371536,
      -0.01528738159686327,
      0.04538993537425995,
      0.06339893490076065,
      0.07287593930959702,
      -0.04943440854549408,
      0.005506916902959347,
      0.02976481057703495,
      -0.019352810457348824,
      0.011465051211416721,
      -0.09240704029798508,
      0.05404696986079216,
      0.02085748314857483,
      0.005886388476938009,
      -0.0434330590069294,
      0.006206996738910675,
      0.06380617618560791,
      -0.0042191967368125916,
      0.03315749019384384,
      -0.05856466665863991,
      0.09305575489997864,
      0.00868932530283928,
      0.013803246431052685,
      0.007960380055010319,
      0.07641125470399857,
      -0.011808551847934723,
      0.03307706490159035,
      0.062327563762664795,
      -0.012794890441000462,
      -0.051657479256391525,
      -0.0017815657192841172,
      -0.07648016512393951,
      -0.01454318780452013,
      0.0010639309184625745,
      0.023244448006153107,
      0.05578113719820976,
      -0.018052969127893448,
      -0.07913593202829361,
      0.041609328240156174,
      -0.003741414286196232,
      -0.01619628816843033,
      -0.05576257407665253,
      0.005410852842032909,
      0.02933553420007229,
      0.021519675850868225,
      0.04115545004606247,
      0.010467981919646263,
      0.024460824206471443,
      -0.02223340980708599,
      0.010741038247942924,
      0.026762444525957108,
      -0.004962037783116102,
      -0.04558541998267174,
      -0.06662441045045853,
      0.010970311239361763,
      0.0806969404220581,
      -0.014180023223161697,
      0.07857350260019302,
      -0.03185934200882912,
      -0.010634152218699455,
      -0.06615591794252396,
      0.09960167855024338,
      -0.06535851955413818,
      -0.014652404934167862,
      -0.008456072770059109,
      -0.07605637609958649,
      0.050190337002277374,
      0.034622445702552795,
      -0.019967490807175636,
      -0.07923602312803268,
      -0.020398065447807312,
      -0.0037756692618131638,
      0.027582654729485512,
      0.033691130578517914,
      -0.06029217317700386,
      -0.04473883658647537,
      -0.010851819068193436,
      0.08183542639017105,
      0.025509130209684372,
      -0.11210682988166809,
      -0.009027726948261261,
      -0.007093035615980625,
      0.024990633130073547,
      0.008941997773945332,
      -0.08298458904027939,
      0.02262500673532486,
      -0.045922063291072845,
      0.09266603738069534,
      -0.046736352145671844,
      0.05096080154180527,
      -0.03429475426673889,
      -0.02897261269390583,
      -0.07095479965209961,
      -0.012944388203322887,
      0.10053367167711258,
      0.05313601717352867,
      -0.04929931461811066,
      -0.0003169251431245357,
      0.020654385909438133,
      0.01606886088848114,
      -0.023392299190163612,
      -0.001268134219571948,
      0.04700507968664169,
      0.08911742269992828,
      0.008814637549221516,
      0.009256577119231224,
      0.08842549473047256,
      -0.0437663197517395,
      0.007046448066830635,
      0.033764395862817764,
      0.02793397754430771,
      0.033263616263866425,
      0.00880452524870634,
      -0.036412447690963745,
      -0.045183636248111725,
      -0.10049190372228622,
      0.041763193905353546,
      0.003489810973405838,
      -0.021356500685214996,
      -0.05132126435637474,
      0.0033531016670167446,
      -0.05448682978749275,
      0.033818569034338,
      0.029609380289912224,
      -0.06842420995235443,
      -0.035331323742866516,
      -0.006911850068718195,
      0.0017393316375091672,
      0.03373252972960472,
      -0.01845904067158699,
      0.013775769621133804,
      -0.041545312851667404,
      0.0041979276575148106,
      0.04722696170210838,
      -0.06514199078083038,
      -0.04055966064333916,
      0.12999750673770905,
      0.05902385339140892,
      -0.025013403967022896,
      -0.07404199987649918,
      -0.029774317517876625,
      -0.06963877379894257,
      0.027241209521889687,
      -0.027742313221096992,
      0.06770447641611099,
      0.008058896288275719,
      0.0006686492124572396,
      -0.012981649488210678,
      -0.05791516602039337,
      -0.031457506120204926,
      0.06617464125156403,
      -0.09312786906957626,
      -0.027715248987078667,
      -0.0011272691190242767,
      -0.03852032870054245,
      0.034443698823451996,
      0.05307910963892937,
      0.05524963140487671,
      0.034571513533592224,
      -0.005209441762417555,
      -0.047329388558864594,
      -0.0670870915055275,
      0.0015417129034176469,
      -0.008946014568209648,
      0.06714052706956863,
      0.048496946692466736,
      0.04431202635169029,
      -0.09339632093906403,
      -0.03426690027117729,
      0.03547615930438042,
      -0.0008116542594507337,
      -0.05355053022503853,
      0.008179191499948502,
      -0.0960303321480751,
      0.038102887570858,
      -0.06822231411933899,
      0.0053672101348638535,
      0.01768411323428154,
      -0.037556614726781845,
      -0.05285855382680893,
      0.005263281520456076,
      0.07768639177083969,
      -0.027710940688848495,
      0.03233231231570244,
      -0.0035823804792016745,
      0.003876500064507127,
      -0.00046483942423947155,
      -0.05746563896536827,
      0.0597984604537487,
      -0.0345885306596756,
      0.10010802745819092,
      -0.03807099163532257,
      -0.026098905131220818,
      -0.06883981823921204,
      0.05974406376481056,
      0.02924949862062931,
      -0.0688556656241417,
      0.042525798082351685,
      -0.009069632738828659,
      0.0973084568977356,
      -0.020714396610856056,
      0.024072112515568733,
      -0.010847365483641624,
      -0.04972830414772034,
      0.06576532870531082,
      -0.0031109291594475508,
      0.031065072864294052,
      0.05321414768695831,
      0.025667153298854828,
      -0.015503033995628357,
      -0.0532856360077858,
      -0.004018358886241913,
      -0.01576848328113556,
      -0.003879364812746644,
      0.02303490974009037,
      0.011739599518477917,
      0.016436563804745674,
      -0.11696355044841766,
      -0.09794481843709946,
      0.08014192432165146,
      -0.08946595340967178,
      -0.06440255045890808,
      -0.025187913328409195,
      0.08216305822134018,
      -0.050917848944664,
      -0.010666177608072758,
      0.03433385118842125,
      -0.05022250860929489,
      0.006579376757144928,
      0.07169180363416672,
      -0.05553382635116577,
      0.039893195033073425,
      -0.013271696865558624,
      -0.02295823022723198,
      0.04144133999943733,
      -0.06976396590471268,
      0.0403285026550293,
      0.0367930568754673,
      -0.10244548320770264,
      0.034192442893981934,
      0.020335189998149872,
      -0.02710917964577675,
      -0.04878123104572296,
      0.055632881820201874,
      0.03181420639157295,
      0.0006607352406717837,
      -0.06263156980276108,
      -0.04697486385703087,
      0.030478408560156822,
      0.024715516716241837,
      -0.02129966951906681,
      0.04201092571020126,
      0.013098856434226036,
      0.04349912703037262,
      -0.01198825053870678,
      -0.041450973600149155,
      -0.043361227959394455,
      -0.08103974908590317,
      -0.05113682895898819,
      0.02562868408858776,
      0.04112823307514191,
      -0.003741354215890169,
      -0.06688247621059418,
      -0.003328609047457576,
      0.013683948665857315,
      -0.023367540910840034,
      0.001894330489449203,
      -0.06593524664640427,
      -0.016346095129847527,
      -0.0022172301542013884,
      0.017452318221330643,
      0.07288369536399841,
      -0.026134492829442024,
      0.048118188977241516,
      -0.014413854107260704,
      0.04737306758761406,
      -0.0007109922589734197,
      -0.04991794005036354,
      0.0035955405328422785,
      0.0031708485912531614,
      0.07583649456501007,
      0.0859418511390686,
      -0.006616855040192604,
      0.03306205943226814,
      -0.07239411026239395,
      -0.04041866958141327,
      -0.04972465708851814,
      0.04744000732898712,
      0.007830630987882614,
      0.09762419760227203,
      0.08524776995182037,
      0.10762468725442886,
      -0.024998687207698822,
      -0.019044790416955948,
      -0.0013397728325799108,
      -0.0005431091994978487,
      0.03333498165011406,
      -0.034787584096193314,
      -0.01750735566020012,
      0.0036882481072098017,
      0.016447028145194054,
      0.06362961232662201,
      0.04685742035508156,
      -0.055441856384277344,
      -0.01017731986939907,
      -0.005422818008810282,
      0.009516241028904915,
      0.039881665259599686,
      0.04310515522956848,
      0.08918378502130508,
      -0.0352652333676815,
      -0.005846529267728329,
      0.01814129389822483,
      0.05232365429401398,
      0.003022177843376994,
      -0.06580968201160431,
      -0.058099791407585144,
      0.02211211621761322,
      0.08695916086435318,
      0.014230417087674141,
      0.00840971153229475,
      0.06947745382785797,
      -0.05795415863394737,
      -0.03355604037642479,
      0.03915947303175926,
      0.07540170103311539,
      -0.032317955046892166,
      -0.023794684559106827,
      0.011610173620283604,
      0.0033928912598639727,
      0.024414561688899994,
      0.03356482833623886,
      -0.027706779539585114,
      -0.013377363793551922,
      -0.023956764489412308,
      0.024691103026270866,
      -0.05112040415406227,
      0.08060591667890549,
      -0.023191673681139946,
      -0.015802068635821342,
      0.07183796912431717,
      0.11686871200799942,
      -0.020493600517511368,
      -0.004071658011525869,
      -0.043924473226070404,
      0.007107684854418039,
      -6.932736869202927e-05,
      -0.02798471599817276,
      -0.003324330085888505,
      -0.02523214928805828,
      -0.02617965266108513,
      -0.02657821588218212,
      -0.05479273572564125,
      -0.03472993150353432,
      0.028681986033916473,
      0.04206005856394768,
      0.14185965061187744,
      -0.030681965872645378,
      -0.025704290717840195,
      0.02687666565179825,
      0.01181072648614645,
      -0.05064002797007561,
      -0.03495059162378311,
      -0.03872111067175865,
      -0.05869648978114128,
      -0.06416697800159454,
      -0.033491700887680054,
      -0.03312651440501213,
      0.010664301924407482,
      -0.008378064259886742,
      0.004654051270335913,
      -0.04889046028256416,
      -0.06555742025375366,
      0.09470754861831665,
      0.06928741186857224,
      -0.034761104732751846,
      -0.0030495249666273594,
      0.05132996663451195,
      0.08471837639808655,
      -0.014697069302201271,
      0.01206591259688139,
      -0.048866018652915955,
      0.036171287298202515,
      0.08719831705093384,
      -0.046721842139959335,
      0.07260570675134659,
      0.06574678421020508,
      0.006080723833292723,
      0.022783629596233368,
      -0.07614757865667343,
      -0.004105553962290287,
      0.003915540874004364,
      0.0010976835619658232,
      0.041274577379226685,
      0.05472336709499359,
      0.020847447216510773,
      -0.021587297320365906,
      -0.08375103026628494,
      -0.0645359680056572,
      -0.030174866318702698,
      0.0820506364107132,
      -0.0072273858822882175,
      0.08169636875391006,
      0.0130755715072155,
      -0.03895427659153938,
      0.033433396369218826,
      0.05362416058778763,
      0.0240580253303051,
      0.0606037937104702,
      -0.02000085636973381,
      -0.03046056441962719,
      -0.026875101029872894,
      -0.0008878176449798048,
      -0.019444629549980164,
      0.06228457763791084,
      0.0341574028134346,
      0.09085238724946976,
      -0.09507725387811661,
      -0.04588884487748146,
      0.008752101100981236,
      0.0638541430234909,
      -0.05035773292183876,
      -0.003981378395110369,
      0.02735261060297489,
      0.0951470285654068,
      0.031551606953144073,
      0.03984873369336128,
      -0.07171882688999176,
      0.00780835747718811,
      0.08786249160766602,
      0.0022913001012057066,
      -0.014308434911072254,
      -0.06227778270840645,
      -0.04773545265197754,
      0.024811886250972748,
      -0.024696627631783485,
      -0.0008031505276449025,
      -0.036782655864953995,
      0.02797829732298851,
      0.022215669974684715,
      -0.009779931977391243,
      0.014062687754631042,
      -0.05144058167934418,
      0.012089082039892673,
      0.061354104429483414,
      -0.010164465755224228,
      0.0605672262609005,
      0.06861471384763718,
      -0.04009903222322464,
      -0.019328245893120766,
      0.03623291850090027,
      -0.013532462529838085,
      0.0004168191517237574,
      -0.07282836735248566,
      0.04905015975236893,
      -0.09544414281845093,
      -0.007982010021805763,
      0.0011644720798358321,
      -0.01323194894939661,
      0.05474337562918663,
      -0.022670172154903412,
      -0.03094150312244892,
      0.018277525901794434,
      -0.07028637081384659,
      -0.03807518258690834,
      -0.0024991289246827364,
      0.03736007586121559,
      0.01749950647354126,
      -0.02768014743924141,
      0.017758892849087715,
      0.0709315836429596,
      0.05248212441802025,
      0.030980831012129784,
      0.026444902643561363,
      -0.008840055204927921,
      -0.052057500928640366,
      0.07301745563745499,
      0.020202944055199623,
      -0.0798598900437355,
      -0.028685251250863075,
      -0.045307986438274384,
      0.0064685833640396595,
      0.013310302048921585,
      0.06657887250185013
    ],
    [
      0.0639767125248909,
      0.006198873743414879,
      -0.06717561930418015,
      0.022796059027314186,
      0.06470560282468796,
      0.005904600955545902,
      -0.01972428523004055,
      -0.05780385434627533,
      0.027977874502539635,
      -0.03429845720529556,
      0.049409449100494385,
      -0.009200145490467548,
      0.053782932460308075,
      -0.03016875497996807,
      -0.0670185536146164,
      -0.021556222811341286,
      0.05624190345406532,
      -0.0448337085545063,
      -0.02203645557165146,
      -0.003834524191915989,
      0.03787483274936676,
      0.016481127589941025,
      -0.03776799514889717,
      -0.012022819370031357,
      0.006292759906500578,
      0.0047694542445242405,
      0.05432326719164848,
      -0.005598865449428558,
      -0.018486687913537025,
      0.05824491009116173,
      0.0175627414137125,
      0.0013884473592042923,
      -0.010726545937359333,
      0.011341278441250324,
      0.05201403796672821,
      0.006099395453929901,
      0.05650518089532852,
      0.00933036208152771,
      -0.011969855055212975,
      -0.013659327290952206,
      0.016001401469111443,
      0.09934300184249878,
      0.02965088002383709,
      -0.050373803824186325,
      -0.025041770190000534,
      -0.006778191775083542,
      0.0458497516810894,
      0.023310480639338493,
      -0.12557949125766754,
      0.013465980999171734,
      0.035859834402799606,
      -0.09556739777326584,
      -0.06310071051120758,
      0.013990222476422787,
      0.013116611167788506,
      -0.004278696607798338,
      0.051199961453676224,
      0.07393525540828705,
      0.10804449766874313,
      -0.06154501065611839,
      0.04523560777306557,
      -0.022307075560092926,
      -0.004920012783259153,
      -0.0030478681437671185,
      0.036057934165000916,
      -0.05593201890587807,
      -0.0020383005030453205,
      -0.013020227663218975,
      -0.011756901629269123,
      0.05173836648464203,
      0.07171935588121414,
      0.014648370444774628,
      -0.03938256576657295,
      0.002839892404153943,
      -0.041569799184799194,
      -0.055020883679389954,
      -0.024978866800665855,
      -0.010729744099080563,
      0.015324942767620087,
      0.03998972475528717,
      0.00454151863232255,
      -0.03576293960213661,
      -0.06017240136861801,
      -0.015180574730038643,
      -0.01466334518045187,
      0.08663544058799744,
      -0.026728445664048195,
      0.05007908493280411,
      0.03534159064292908,
      -0.029713209718465805,
      -0.04662925750017166,
      0.004107617307454348,
      0.004754172638058662,
      -0.012919111177325249,
      0.06979794055223465,
      0.007658882532268763,
      -0.035884659737348557,
      0.008359878323972225,
      -0.0074393246322870255,
      -0.03894353285431862,
      -0.03551304712891579,
      0.01410401426255703,
      0.03039143607020378,
      -0.020162412896752357,
      -0.0033385767601430416,
      0.03959058225154877,
      -0.06771670281887054,
      -0.013525642454624176,
      -0.021716682240366936,
      0.008581068366765976,
      0.009925893507897854,
      -0.026903510093688965,
      -0.1072595864534378,
      0.03008108027279377,
      -0.004563700873404741,
      0.005938577000051737,
      -0.04618094116449356,
      0.005801361054182053,
      0.011606044135987759,
      0.024191970005631447,
      -0.012183574959635735,
      -0.030919337645173073,
      -0.004600332584232092,
      0.007902857847511768,
      0.014597205445170403,
      0.04013030230998993,
      -0.035690002143383026,
      0.03859294578433037,
      0.06113791838288307,
      -0.07584141194820404,
      0.06761527806520462,
      -0.01041597593575716,
      -0.11337690055370331,
      0.0008979077683761716,
      0.014976561069488525,
      0.00789461936801672,
      0.026844454929232597,
      0.018997453153133392,
      -0.04779670387506485,
      0.04423651844263077,
      -0.0070350999012589455,
      -0.025289535522460938,
      -0.04435306787490845,
      -0.05267801508307457,
      -0.012936447747051716,
      -0.06199171394109726,
      -0.006358490325510502,
      -0.0035536508075892925,
      -0.00593292573466897,
      0.017069032415747643,
      0.041132502257823944,
      0.011690965853631496,
      -0.03704438731074333,
      0.04826300963759422,
      -0.012492152862250805,
      0.02447637729346752,
      0.038449935615062714,
      0.04470006003975868,
      0.016895350068807602,
      -0.006572697311639786,
      -0.007327200844883919,
      0.0157629307359457,
      -0.03628156706690788,
      0.034691471606492996,
      0.010269672609865665,
      0.010723736137151718,
      0.06562436372041702,
      -0.073938749730587,
      0.017352832481265068,
      -0.01542340312153101,
      0.0171433687210083,
      -0.015792150050401688,
      0.05277913436293602,
      -0.007194838020950556,
      -0.03737456724047661,
      0.07177139073610306,
      -0.06960653513669968,
      -0.05255858227610588,
      -0.009350367821753025,
      -0.07470877468585968,
      0.007993881590664387,
      -0.018048129975795746,
      0.04502105340361595,
      -0.03717704489827156,
      0.022150840610265732,
      0.020075928419828415,
      -0.049949344247579575,
      0.02023128606379032,
      0.08658947050571442,
      0.0015422925353050232,
      -0.008747836574912071,
      -0.0074578747153282166,
      0.041552089154720306,
      0.004863881506025791,
      -0.011141619645059109,
      0.005105773918330669,
      -0.08839661628007889,
      0.035927627235651016,
      -0.004610609728842974,
      -0.03403569757938385,
      -0.02561989240348339,
      -0.026724739000201225,
      -0.013480974361300468,
      0.04575240612030029,
      -0.026602964848279953,
      -0.0017610284266993403,
      0.06041866913437843,
      0.01696079596877098,
      -0.045313749462366104,
      -0.05412356182932854,
      0.03005026839673519,
      0.06883404403924942,
      -0.021344713866710663,
      -0.006822940427809954,
      0.005652281455695629,
      -0.02876271679997444,
      -0.033798616379499435,
      0.010287473909556866,
      -0.015084986574947834,
      -0.0007764880429022014,
      -0.004812686238437891,
      0.04548463597893715,
      -0.05525224655866623,
      -0.06410019844770432,
      -0.05005177855491638,
      0.018365446478128433,
      0.020571278408169746,
      -0.08620906621217728,
      0.04134264960885048,
      0.043283864855766296,
      0.056299302726984024,
      0.02097241021692753,
      0.013182554394006729,
      0.07230499386787415,
      0.012527928687632084,
      -0.09169641882181168,
      -0.002918459475040436,
      -0.01566256769001484,
      0.01876320317387581,
      0.021169543266296387,
      0.013150138780474663,
      0.022640438750386238,
      -0.027190547436475754,
      -0.012897837907075882,
      0.02440609037876129,
      -0.006447501014918089,
      0.04440722241997719,
      0.0034979446791112423,
      0.025110403075814247,
      0.0061766901053488255,
      -0.04460199549794197,
      0.002513150218874216,
      -0.04461748152971268,
      0.08674392104148865,
      -0.014963219873607159,
      -0.0295639056712389,
      0.006047849077731371,
      -0.06920789927244186,
      -0.010992467403411865,
      -0.023721400648355484,
      0.07549385726451874,
      -0.012238932773470879,
      -0.04458119347691536,
      -0.09989289939403534,
      0.020963605493307114,
      0.02816641889512539,
      -0.07636812329292297,
      0.033133529126644135,
      0.014536922797560692,
      -0.06937207281589508,
      0.010098036378622055,
      -0.05116565525531769,
      -0.0930054783821106,
      -0.024906503036618233,
      0.06907913833856583,
      0.0057721445336937904,
      0.09157679229974747,
      0.008976374752819538,
      -0.02760644443333149,
      0.019156571477651596,
      0.01687942072749138,
      -0.01541998703032732,
      -0.05844741314649582,
      -0.0525631420314312,
      -0.01311443094164133,
      0.04940120130777359,
      0.02514098584651947,
      -0.02184639871120453,
      0.00031277519883587956,
      0.0030100285075604916,
      0.06763999909162521,
      0.009852981194853783,
      0.07802864164113998,
      0.08730055391788483,
      0.03832978382706642,
      0.008674382232129574,
      -0.0552433580160141,
      0.004105445463210344,
      -0.027161456644535065,
      -0.06872381269931793,
      -0.09671229869127274,
      -0.0002664588682819158,
      0.032225340604782104,
      0.0647096335887909,
      0.02693968452513218,
      0.029286587610840797,
      0.0007091754232533276,
      -0.01959000714123249,
      0.014434047043323517,
      0.041435763239860535,
      0.0037023047916591167,
      0.0648505836725235,
      0.01347888633608818,
      0.09705136716365814,
      0.061860691756010056,
      0.0422619953751564,
      -0.001217412413097918,
      -0.05154861882328987,
      0.059790514409542084,
      -0.009880715049803257,
      -0.04484311491250992,
      -0.018833070993423462,
      -0.05511493608355522,
      -0.04131878912448883,
      -0.04927879199385643,
      -0.002959050238132477,
      -0.0724184438586235,
      -0.05850096419453621,
      -0.035972464829683304,
      0.03243294730782509,
      -0.04704829305410385,
      0.012580445036292076,
      0.034929309040308,
      -0.022801201790571213,
      0.040385350584983826,
      -0.054055456072092056,
      -0.03997825086116791,
      -0.04285021871328354,
      0.03345729783177376,
      -0.07322702556848526,
      -0.0353190116584301,
      0.024003608152270317,
      0.027196941897273064,
      0.03500539809465408,
      -0.09827203303575516,
      0.10584842413663864,
      -0.00015582813648507,
      0.02818605862557888,
      -0.08990726619958878,
      0.05954984575510025,
      -0.028698988258838654,
      0.027609694749116898,
      0.07958175241947174,
      0.041753776371479034,
      -0.03034493699669838,
      0.044304296374320984,
      0.05073400214314461,
      -0.04898417741060257,
      0.02730446308851242,
      0.07548736780881882,
      -0.034149374812841415,
      0.003494256641715765,
      -0.06312448531389236,
      0.07000020891427994,
      -0.0036827255971729755,
      -0.01683168299496174,
      0.002619394101202488,
      -0.0041370089165866375,
      0.10386616736650467,
      -0.04807379096746445,
      -0.008360322564840317,
      -0.0055735232308506966,
      -0.0383564792573452,
      -0.11246196180582047,
      0.04366086423397064,
      -0.02792252227663994,
      0.10065501183271408,
      0.0480455607175827,
      0.025502508506178856,
      0.029442792758345604,
      -0.03374090790748596,
      -0.0327678881585598,
      0.037815824151039124,
      -0.051420602947473526,
      0.0600273460149765,
      -0.048195429146289825,
      0.06963615119457245,
      0.0006400110432878137,
      0.02181805670261383,
      0.04245487228035927,
      -0.05645887553691864,
      0.08522848784923553,
      0.029625466093420982,
      0.05508247762918472,
      -0.012188072316348553,
      0.011387716978788376,
      -0.0017495990032330155,
      -0.010343885980546474,
      0.010305442847311497,
      0.07551557570695877,
      -0.10720594972372055,
      -0.013050924055278301,
      -0.03479386121034622,
      0.001000595511868596,
      -0.09776419401168823,
      -0.0374225378036499,
      -0.019615549594163895,
      0.02617599256336689,
      0.035616543143987656,
      0.0523996539413929,
      0.03835758566856384,
      -0.021809102967381477,
      -0.024869928136467934,
      -0.01553934346884489,
      0.0036428531166166067,
      -0.03307927027344704,
      -0.014427978545427322,
      -0.05091531574726105,
      -0.010958877392113209,
      -0.058345407247543335,
      0.07129549235105515,
      -0.03823556378483772,
      0.07099663466215134,
      -0.027694035321474075,
      0.022030765190720558,
      -0.006557181011885405,
      0.005178551189601421,
      0.022748127579689026,
      -0.040617991238832474,
      0.024624403566122055,
      -0.030590226873755455,
      0.00029008011915721,
      0.04237940534949303,
      0.042697906494140625,
      0.07996760308742523,
      -0.05272865295410156,
      -0.06171388924121857,
      -0.06043485924601555,
      0.018979260697960854,
      -0.007888542488217354,
      0.027892807498574257,
      0.009657885879278183,
      0.025523019954562187,
      0.00033498904667794704,
      -0.06716235727071762,
      0.09812863916158676,
      -0.00013159580703359097,
      -0.03265223652124405,
      -0.10239403694868088,
      -0.06696689873933792,
      0.005141229368746281,
      -0.03433569148182869,
      0.05577845498919487,
      0.048931706696748734,
      -0.016037752851843834,
      0.008805445395410061,
      0.00726626580581069,
      -0.08736631274223328,
      -0.024843357503414154,
      -0.06747909635305405,
      -0.03867335617542267,
      -0.02470577135682106,
      -0.035770975053310394,
      -0.053800661116838455,
      0.02968166209757328,
      -0.032285165041685104,
      -0.013326854445040226,
      0.0008613634272478521,
      0.04278789460659027,
      -0.06504342705011368,
      -0.03361484408378601,
      0.02149924449622631,
      0.004616360180079937,
      -0.006409073248505592,
      -0.03355824574828148,
      -0.08264126628637314,
      -0.06477158516645432,
      0.1343303620815277,
      -0.03690456226468086,
      0.005286368075758219,
      0.008520856499671936,
      -0.021406110376119614,
      0.05884949862957001,
      0.057660412043333054,
      -0.04118706285953522,
      -0.04550381749868393,
      0.031345076858997345,
      0.008078217506408691,
      -0.03518178313970566,
      -0.03136468678712845,
      0.10201925039291382,
      -0.015402776189148426,
      -0.04509994387626648,
      -0.024177493527531624,
      0.05133830010890961,
      0.08069541305303574,
      -0.05830729380249977,
      -0.046812232583761215,
      0.04754972830414772,
      -0.00915162730962038,
      0.0608694851398468,
      -0.018283363431692123,
      -0.06236734241247177
    ],
    [
      0.042253896594047546,
      0.02106712944805622,
      0.0031233609188348055,
      -0.04814086854457855,
      -0.028718706220388412,
      -0.03804544731974602,
      0.028381649404764175,
      -0.08759809285402298,
      -0.03697187826037407,
      0.01393404696136713,
      0.0143556147813797,
      -0.009363852441310883,
      0.05467040464282036,
      -0.06508924067020416,
      -0.04313521832227707,
      -0.001058351481333375,
      -0.010126253589987755,
      -0.004522556439042091,
      0.056343965232372284,
      0.07824400067329407,
      -0.04159571975469589,
      0.03720682114362717,
      -0.00636641401797533,
      -0.07356283068656921,
      0.0600116029381752,
      -0.03396770358085632,
      -0.0033254765439778566,
      0.03036162629723549,
      0.0812961608171463,
      -0.024633659049868584,
      0.09735552221536636,
      -0.04883075878024101,
      0.03418852388858795,
      0.054481618106365204,
      -0.0659145712852478,
      0.08323514461517334,
      -0.034529898315668106,
      0.013945872895419598,
      -0.022691568359732628,
      -0.024559900164604187,
      0.018916359171271324,
      -0.0011845558183267713,
      0.025271840393543243,
      0.007654568646103144,
      -0.014667713083326817,
      -0.04193473979830742,
      0.05066126212477684,
      -0.0152590312063694,
      -0.012816070578992367,
      0.007070005871355534,
      -0.019017891958355904,
      0.05723510682582855,
      0.05270235240459442,
      0.026037801057100296,
      -0.006787996273487806,
      -0.04601568356156349,
      0.028097430244088173,
      0.055184874683618546,
      -0.04081865772604942,
      -0.08113981038331985,
      0.028433693572878838,
      -0.007655330467969179,
      0.05881887674331665,
      0.02625436708331108,
      0.019634133204817772,
      -0.009679874405264854,
      0.045148417353630066,
      0.03059397265315056,
      0.0012908770004287362,
      -0.021065320819616318,
      0.03599631413817406,
      -0.07873104512691498,
      0.06450735032558441,
      -0.030124997720122337,
      -0.01969810761511326,
      -0.012957300059497356,
      0.061119936406612396,
      0.05342612788081169,
      0.06975094974040985,
      0.032197482883930206,
      -0.03331205993890762,
      0.017976924777030945,
      -0.017588429152965546,
      0.04853957146406174,
      -0.05999748408794403,
      0.016180796548724174,
      0.10422620177268982,
      0.06122236326336861,
      0.037636373192071915,
      0.03611317276954651,
      0.04813351109623909,
      -0.04720589518547058,
      -0.03800024092197418,
      -0.058009229600429535,
      -0.04000686854124069,
      0.006728023290634155,
      -0.0031666152644902468,
      0.023505045101046562,
      0.005029017571359873,
      0.044812850654125214,
      0.011444106698036194,
      0.07844171673059464,
      -0.031365036964416504,
      -0.01903284713625908,
      -0.15022370219230652,
      0.012595641426742077,
      -0.027478981763124466,
      -0.0478222630918026,
      0.0470430888235569,
      0.015267536975443363,
      -0.023693092167377472,
      0.05904163420200348,
      -0.03969939798116684,
      -0.055748019367456436,
      0.10637231171131134,
      0.04609091579914093,
      0.05130985379219055,
      0.00592517526820302,
      -0.07450506091117859,
      -0.023275503888726234,
      0.024607554078102112,
      0.030789226293563843,
      0.022792726755142212,
      0.0035613244399428368,
      0.03911963105201721,
      -0.0503508597612381,
      -0.03892236948013306,
      0.01575341261923313,
      -0.038587313145399094,
      -0.006638358347117901,
      0.004163098055869341,
      0.01954725570976734,
      -0.011973103508353233,
      -0.042593814432621,
      -0.0021535209380090237,
      0.0242451224476099,
      -0.02476111799478531,
      -0.00505605386570096,
      0.06161707639694214,
      -0.09495740383863449,
      -0.00794071052223444,
      -0.04178108647465706,
      0.03369956091046333,
      -0.046082690358161926,
      -0.0036128272768110037,
      -0.029787445440888405,
      0.06013944000005722,
      -0.027119511738419533,
      -0.025657959282398224,
      0.008104805834591389,
      0.02489692158997059,
      -0.03695986792445183,
      -0.0847889855504036,
      0.04309971258044243,
      0.0016123566310852766,
      -0.02378838323056698,
      -0.02177559956908226,
      0.045253269374370575,
      0.02418523095548153,
      -0.12111928313970566,
      0.06705385446548462,
      0.03748955950140953,
      -0.024032194167375565,
      -0.009911306202411652,
      0.024886108934879303,
      -0.015296432189643383,
      0.014118277467787266,
      0.07828263193368912,
      -0.004740934353321791,
      -0.029141729697585106,
      -0.09499260783195496,
      0.030229857191443443,
      -0.03523809090256691,
      0.06555505841970444,
      0.04342209920287132,
      0.03918510675430298,
      0.03023395873606205,
      0.04658035188913345,
      -0.005223357584327459,
      -0.11805931478738785,
      -0.030453478917479515,
      0.04912106692790985,
      -0.03187363222241402,
      0.00020487688016146421,
      0.08956435322761536,
      0.02537880279123783,
      0.020598767325282097,
      0.05744004622101784,
      -0.04983991011977196,
      -0.009286165237426758,
      -0.06340552866458893,
      -0.02263621613383293,
      -0.017959775403141975,
      0.009832052513957024,
      0.04395977780222893,
      -0.015125684440135956,
      -0.02710445411503315,
      -0.0033459991682320833,
      -0.06301158666610718,
      -0.05228514224290848,
      -0.09803704172372818,
      0.015540866181254387,
      -0.030932893976569176,
      0.009635936468839645,
      -0.013218150474131107,
      -0.02782881073653698,
      -0.010720694437623024,
      -0.01430082693696022,
      -0.019158242270350456,
      0.004699053708463907,
      -0.04463345184922218,
      0.05185869708657265,
      0.020303187891840935,
      0.04773643985390663,
      0.029815299436450005,
      -0.06121283769607544,
      0.023597856983542442,
      0.00016884900105651468,
      0.03449048474431038,
      0.0013498339103534818,
      0.06992459297180176,
      -0.019087065011262894,
      -0.017136717215180397,
      -0.05728394538164139,
      -0.08441182971000671,
      0.04294966533780098,
      0.035826731473207474,
      0.08385832607746124,
      -0.01315314881503582,
      -0.013699392788112164,
      0.011684675700962543,
      -0.030052626505494118,
      -0.05896442383527756,
      0.02995261363685131,
      -0.012737643904983997,
      0.010639766231179237,
      0.036013778299093246,
      -0.06196143478155136,
      -0.025288455188274384,
      -0.0016488772816956043,
      0.06710059195756912,
      -0.02314007841050625,
      0.10926009714603424,
      0.03652181476354599,
      -0.0008326171082444489,
      -0.0005803209496662021,
      0.13093818724155426,
      -0.029902717098593712,
      -0.033322662115097046,
      -0.05548301339149475,
      0.03470967337489128,
      0.014801503159105778,
      -0.05871734395623207,
      0.015381076373159885,
      -0.010458230040967464,
      0.015358880162239075,
      -0.01989985816180706,
      -0.08774222433567047,
      0.0009420097921974957,
      -0.01724974811077118,
      0.05694244056940079,
      0.0475132018327713,
      -0.03879033401608467,
      0.07277073711156845,
      0.0020370285492390394,
      -0.05584028363227844,
      -0.011244691908359528,
      -0.0869678482413292,
      -0.0007152819889597595,
      -0.04768197983503342,
      0.04342227429151535,
      -0.0851072371006012,
      -0.07427732646465302,
      -0.041117552667856216,
      -0.042205050587654114,
      0.02634202316403389,
      0.008627854287624359,
      0.00959115568548441,
      -0.0029910937882959843,
      0.05789758637547493,
      -0.013692865148186684,
      -0.07111547142267227,
      0.03021201863884926,
      -0.04023853689432144,
      0.05395922809839249,
      -0.0014435562770813704,
      -0.030531542375683784,
      -0.05955497920513153,
      -0.06921955198049545,
      -0.029031166806817055,
      0.10750466585159302,
      -0.014259248040616512,
      0.03959152102470398,
      -0.05746510997414589,
      -0.003685509320348501,
      -0.007866800762712955,
      0.021979451179504395,
      -0.020434191450476646,
      -0.052627887576818466,
      -0.02261662296950817,
      0.04424494877457619,
      -0.02339879609644413,
      0.020398057997226715,
      0.02422053925693035,
      -0.039096903055906296,
      0.05741075426340103,
      0.005803863983601332,
      -0.035876695066690445,
      0.0001549141015857458,
      -0.02741030603647232,
      0.016148574650287628,
      -0.047691598534584045,
      -0.0759379118680954,
      0.03994717076420784,
      0.023650767281651497,
      -0.028618518263101578,
      -0.04931141808629036,
      0.048232290893793106,
      0.012704282999038696,
      0.0005557021358981729,
      -0.016773540526628494,
      0.01791471429169178,
      0.02208804152905941,
      -0.0904327854514122,
      0.04505905508995056,
      -0.0646756961941719,
      0.011152843944728374,
      0.07495709508657455,
      -0.04376170039176941,
      0.07026493549346924,
      -0.006885621231049299,
      -0.00490972027182579,
      0.051871851086616516,
      0.04749557748436928,
      0.0456315316259861,
      -0.0005610368680208921,
      0.04731132835149765,
      -0.05426241457462311,
      -0.035810891538858414,
      0.026629433035850525,
      -0.016121087595820427,
      0.030244722962379456,
      0.027044348418712616,
      0.014857213012874126,
      0.040771789848804474,
      -0.005640930961817503,
      0.040856193751096725,
      0.06550849229097366,
      -0.058221008628606796,
      0.012262517586350441,
      0.09681201726198196,
      -0.002896400634199381,
      -0.061369407922029495,
      0.023968467488884926,
      0.04974135756492615,
      -0.039767101407051086,
      -0.02275087498128414,
      -0.061982426792383194,
      0.0015692421002313495,
      -0.10679177939891815,
      0.0067580402828752995,
      0.02827761322259903,
      0.021628521382808685,
      -0.006424791645258665,
      -0.04521571472287178,
      -0.023269254714250565,
      -0.018673334270715714,
      -0.07760876417160034,
      -0.009590151719748974,
      -0.019380293786525726,
      0.044832125306129456,
      0.03844210505485535,
      0.017000636085867882,
      0.005685115233063698,
      0.011009970679879189,
      0.014347800053656101,
      0.03503989055752754,
      -0.0015993199776858091,
      -0.01872188225388527,
      0.046302538365125656,
      -0.0077172983437776566,
      0.006996688432991505,
      -0.06596400588750839,
      0.06427455693483353,
      0.05991075187921524,
      0.009679172188043594,
      -0.0457511767745018,
      -0.007837546057999134,
      0.10170451551675797,
      -0.031274907290935516,
      -0.010293888859450817,
      -0.051847293972969055,
      0.023226702585816383,
      0.02322353608906269,
      0.03208275884389877,
      0.035208918154239655,
      0.007766506168991327,
      -0.06463061273097992,
      0.01789925992488861,
      -0.00918811559677124,
      0.07136361300945282,
      -0.0038767806254327297,
      0.029476400464773178,
      0.04967587813735008,
      -0.0017910428578034043,
      -0.021905766800045967,
      0.05168601870536804,
      0.016872119158506393,
      0.014582696370780468,
      -0.013821792788803577,
      -0.02318761497735977,
      -0.014682309702038765,
      0.010060891509056091,
      -0.015464283525943756,
      0.07964581251144409,
      0.06442654877901077,
      -0.033123474568128586,
      0.04673907533288002,
      -0.04553580284118652,
      0.009669211693108082,
      0.012164703570306301,
      0.041887231171131134,
      0.014565779827535152,
      0.03086082823574543,
      -0.07134947925806046,
      -0.08734877407550812,
      0.004929427523165941,
      0.0008387879352085292,
      -0.03474536910653114,
      -0.05505328252911568,
      0.02640478126704693,
      0.08394605666399002,
      -0.006703206337988377,
      0.015413169749081135,
      -0.04440247267484665,
      0.03543613478541374,
      -0.04212745651602745,
      0.031157908961176872,
      0.03409190475940704,
      0.031735360622406006,
      -0.018935615196824074,
      0.03607264906167984,
      0.01010389905422926,
      0.017855696380138397,
      0.007544599007815123,
      -0.08336226642131805,
      0.0044585708528757095,
      0.030041826888918877,
      -0.07793450355529785,
      0.0538485050201416,
      0.00847567617893219,
      0.10806793719530106,
      0.10983864217996597,
      -0.016078082844614983,
      -0.011371412314474583,
      0.03209145739674568,
      -0.0021744100376963615,
      -0.008334536105394363,
      0.06798112392425537,
      -0.06300938129425049,
      0.010367851704359055,
      0.12226122617721558,
      -0.0052169873379170895,
      0.003790332470089197,
      0.0013986265985295177,
      -0.009490441530942917,
      0.01149807684123516,
      0.0022756983526051044,
      0.03930636867880821,
      -0.02832149527966976,
      0.01855301484465599,
      0.027179008349776268,
      -0.03693685680627823,
      -0.021951401606202126,
      -0.051315031945705414,
      0.026036372408270836,
      0.02498369850218296,
      -0.0043475208804011345,
      -0.009410002268850803,
      -0.061601340770721436,
      0.021662836894392967,
      -0.033776041120290756,
      -0.05847829207777977,
      0.07890945672988892,
      0.02825251594185829,
      -0.04238159954547882,
      0.02265981398522854,
      0.052676960825920105,
      -0.09597901254892349,
      -0.02610403671860695,
      -0.03840673342347145,
      0.06719685345888138,
      -0.1440460979938507,
      -0.025178492069244385,
      -0.0041255340911448,
      -0.003685196628794074,
      0.0518459714949131,
      -0.08089949935674667,
      -0.03341696783900261,
      -0.02624458074569702,
      -0.048728182911872864,
      -0.04324351251125336,
      -0.054383497685194016
    ],
    [
      0.015739411115646362,
      0.022122688591480255,
      -0.02227889746427536,
      0.00758000323548913,
      0.06197443976998329,
      -0.01439723838120699,
      0.00607218686491251,
      0.012999951839447021,
      0.08759094774723053,
      -0.019227223470807076,
      -0.034205466508865356,
      -0.024118512868881226,
      0.038598574697971344,
      0.006211256608366966,
      -0.06124952808022499,
      0.006463306490331888,
      -0.054704684764146805,
      0.027125533670186996,
      -0.05484623461961746,
      -0.02683788537979126,
      -0.01057249866425991,
      5.839699588250369e-05,
      0.0035990288015455008,
      0.031213346868753433,
      0.03585908189415932,
      -0.022231299430131912,
      -0.03998135030269623,
      -0.02954157441854477,
      -0.07378175854682922,
      -0.017152385786175728,
      -0.003020688658580184,
      -0.09863180667161942,
      -0.04754185676574707,
      0.04052772372961044,
      0.035587336868047714,
      0.05398702248930931,
      -0.012683932669460773,
      -0.022416770458221436,
      0.07683143019676208,
      0.014871660619974136,
      -0.12501107156276703,
      0.029926875606179237,
      -0.03023778833448887,
      -0.005313662346452475,
      0.026325611397624016,
      0.020520728081464767,
      0.023119177669286728,
      -0.01586179807782173,
      -0.020189914852380753,
      0.031007975339889526,
      0.007332418579608202,
      0.07571160793304443,
      0.011627152562141418,
      -0.03329133614897728,
      0.038128457963466644,
      0.034439846873283386,
      0.020963115617632866,
      0.004452680703252554,
      0.04842173680663109,
      -0.00584117416292429,
      0.1424604058265686,
      -0.01109388843178749,
      0.012004750780761242,
      -0.023145586252212524,
      0.06457798182964325,
      -0.017520081251859665,
      0.03112665005028248,
      -0.005274563562124968,
      0.00010333361569792032,
      -0.01658538542687893,
      -0.0019490255508571863,
      0.028958547860383987,
      -0.07487057894468307,
      -0.10332789272069931,
      0.00345241604372859,
      -0.05253717303276062,
      0.002835548482835293,
      0.03393866494297981,
      0.07302636653184891,
      0.047103151679039,
      -0.03995075821876526,
      0.02452903799712658,
      0.013842718675732613,
      0.12188597023487091,
      0.0047876895405352116,
      0.013685670681297779,
      0.08676496148109436,
      -0.0828360840678215,
      -0.03775705024600029,
      -0.0008173220558091998,
      0.04892181232571602,
      -0.0627242773771286,
      -0.008138448931276798,
      -0.10723090171813965,
      -0.015211149118840694,
      0.07331672310829163,
      0.018664563074707985,
      0.0170857273042202,
      0.0029143006540834904,
      0.05296795815229416,
      0.02946506254374981,
      0.03043554350733757,
      0.010647544637322426,
      -0.05199907347559929,
      0.0032172983046621084,
      -0.052276574075222015,
      -0.04627210646867752,
      -0.03423888981342316,
      0.06040439382195473,
      -0.10484986007213593,
      -0.029901182278990746,
      0.08821208029985428,
      0.03819206729531288,
      0.05192326754331589,
      0.04731583595275879,
      -0.037093404680490494,
      0.03520047664642334,
      -0.043535176664590836,
      -0.029875658452510834,
      -0.04199320450425148,
      -0.08608876168727875,
      -0.030563900247216225,
      -0.042011868208646774,
      -0.008126907981932163,
      -0.03924326226115227,
      0.03622906655073166,
      0.0719577968120575,
      0.05017942562699318,
      0.06592195481061935,
      0.12145527452230453,
      0.03886381909251213,
      -0.07686497271060944,
      -0.003286210121586919,
      -0.08077818155288696,
      -0.016365302726626396,
      -0.021358300000429153,
      -0.0646970197558403,
      0.045490190386772156,
      -0.04413900524377823,
      0.08107541501522064,
      -0.0720578283071518,
      0.027990877628326416,
      0.03251279145479202,
      -0.007633475586771965,
      -0.04406345263123512,
      -0.04786141216754913,
      0.033408023416996,
      -0.11079806089401245,
      -0.06972777098417282,
      -0.02513963356614113,
      -0.01573014259338379,
      0.0014243044424802065,
      0.04518330469727516,
      -0.05675637722015381,
      0.04610160365700722,
      0.07587257772684097,
      0.0315161757171154,
      0.02514006569981575,
      0.04835385084152222,
      0.031603097915649414,
      0.03258948400616646,
      0.04390678554773331,
      -0.045140281319618225,
      -0.05822254344820976,
      0.004747606348246336,
      0.004839708562940359,
      0.049366772174835205,
      -0.005518581252545118,
      -0.011430269107222557,
      -0.03533601388335228,
      -0.06367996335029602,
      -0.029137318953871727,
      -0.036847468465566635,
      0.0270505603402853,
      -0.04899277538061142,
      -0.002021482912823558,
      0.002998875454068184,
      9.323612903244793e-05,
      -0.07857542484998703,
      0.03283124417066574,
      -0.03271845728158951,
      -0.030156493186950684,
      0.0040778047405183315,
      0.002593864919617772,
      0.0063761211931705475,
      -0.09100369364023209,
      0.05412212014198303,
      0.02235543355345726,
      -0.011944771744310856,
      -0.04563784971833229,
      0.057031869888305664,
      0.04089151322841644,
      0.08441353589296341,
      -0.019609082490205765,
      -0.004719383083283901,
      -0.02837292104959488,
      0.02522151544690132,
      0.008367277681827545,
      -0.0033993155229836702,
      -0.021719545125961304,
      0.0005158897256478667,
      -0.05090143159031868,
      -0.0006730080931447446,
      -0.017409300431609154,
      -0.00032180160633288324,
      -0.0013067529071122408,
      0.03338951617479324,
      -0.025335779413580894,
      0.011395013891160488,
      0.016025595366954803,
      0.006232903338968754,
      -0.10769879072904587,
      0.0025302842259407043,
      0.022389378398656845,
      0.048412200063467026,
      -0.041467808187007904,
      -0.0014013252221047878,
      -0.021990643814206123,
      0.0028129725251346827,
      0.040623173117637634,
      -0.06596469134092331,
      -0.013118194416165352,
      0.029501739889383316,
      -0.03689493611454964,
      0.06847774982452393,
      0.003987617790699005,
      0.09700214862823486,
      -0.019404176622629166,
      0.03524113819003105,
      -0.02237851358950138,
      -0.033947721123695374,
      0.021989071741700172,
      0.025317102670669556,
      0.018677236512303352,
      -0.055581193417310715,
      0.03166826069355011,
      -0.058648355305194855,
      -0.02291516214609146,
      0.01570797711610794,
      0.032023340463638306,
      -0.10997256636619568,
      -0.02140968292951584,
      -0.05331491678953171,
      0.07103832066059113,
      -0.023420462384819984,
      0.006579780485481024,
      0.020916709676384926,
      0.10072094947099686,
      -0.027949126437306404,
      0.017054293304681778,
      0.021108277142047882,
      -0.0009559290483593941,
      0.04417292773723602,
      0.09987115114927292,
      0.022094793617725372,
      -0.06471364200115204,
      0.021655121818184853,
      -0.0721636563539505,
      0.00017177406698465347,
      -0.01990380510687828,
      -0.051753509789705276,
      0.04434966295957565,
      -0.008885505609214306,
      -0.04633699357509613,
      -0.04177241399884224,
      0.004181118216365576,
      -0.03447316214442253,
      0.020205184817314148,
      0.03431221470236778,
      0.03882730379700661,
      0.04000328108668327,
      -0.0026559720281511545,
      -0.02011227048933506,
      0.01678134687244892,
      -0.011689446866512299,
      -0.04989439249038696,
      0.036358121782541275,
      -0.04588591679930687,
      -0.019859883934259415,
      0.04757453873753548,
      -0.009172646328806877,
      0.039593275636434555,
      0.07733099162578583,
      -0.02971452847123146,
      -0.005930701736360788,
      0.010825798846781254,
      0.01138591580092907,
      -0.02176099829375744,
      -0.0219755657017231,
      -0.01877044141292572,
      -0.004554875660687685,
      -0.01606001891195774,
      -0.00930623710155487,
      -0.042522817850112915,
      -0.015425133518874645,
      -0.0516311340034008,
      0.017229920253157616,
      0.007642403244972229,
      0.005508070345968008,
      0.007763773202896118,
      0.029218560084700584,
      -0.04863475263118744,
      0.08627396821975708,
      0.04504727944731712,
      -0.0018353450577706099,
      -0.058474935591220856,
      -0.10150334984064102,
      -0.06306061893701553,
      -0.02257748506963253,
      -0.0030029104091227055,
      -0.03609221801161766,
      -0.034502990543842316,
      -0.046852704137563705,
      0.0009783556452021003,
      -0.05448727309703827,
      0.028881875798106194,
      -0.026916835457086563,
      -0.04430331662297249,
      0.010208778083324432,
      -0.06286238878965378,
      -0.01830567978322506,
      -0.024024752900004387,
      0.05526671186089516,
      -0.0739891305565834,
      -0.018766336143016815,
      0.05041724443435669,
      -0.03429557383060455,
      -0.017221394926309586,
      0.03598653897643089,
      -0.03460325300693512,
      -0.007951322942972183,
      -0.004872284829616547,
      0.058246806263923645,
      0.043657686561346054,
      -0.016419710591435432,
      0.018610317260026932,
      -0.017144178971648216,
      -0.0809507966041565,
      -0.01388816349208355,
      0.008511320687830448,
      0.10502883791923523,
      -0.06552695482969284,
      0.07176327705383301,
      0.019666362553834915,
      0.005030361469835043,
      -0.023224569857120514,
      0.004523350391536951,
      0.003263827646151185,
      0.041438665241003036,
      -0.05851445347070694,
      0.01982414536178112,
      -0.05334100499749184,
      -0.035841356962919235,
      -0.03488887846469879,
      0.04468274489045143,
      0.0851634219288826,
      0.026014434173703194,
      0.07068132609128952,
      -0.01720079779624939,
      -0.006331144366413355,
      0.003581714117899537,
      0.07712312787771225,
      -0.030458027496933937,
      -0.08702380210161209,
      0.004567824769765139,
      -0.020343199372291565,
      0.06515738368034363,
      0.0479518286883831,
      -0.007461968809366226,
      -0.02844262309372425,
      -0.013682011514902115,
      -0.009343819692730904,
      -0.06777987629175186,
      -0.004258844535797834,
      -0.06361600011587143,
      -0.054880715906620026,
      0.03907223790884018,
      0.04549342021346092,
      0.08481261134147644,
      -0.07673715800046921,
      0.045245926827192307,
      0.024111749604344368,
      -0.03308124467730522,
      -0.040184877812862396,
      -0.1085626631975174,
      -0.048843640834093094,
      -0.05069002881646156,
      -0.030481919646263123,
      0.0014565159799531102,
      -0.018052751198410988,
      0.007284089922904968,
      -0.05961054190993309,
      -0.050055988132953644,
      0.026296764612197876,
      -0.018248287960886955,
      -0.030396174639463425,
      0.06359421461820602,
      -0.008807580918073654,
      -0.08315037935972214,
      0.03613525629043579,
      -0.027413472533226013,
      -0.028776012361049652,
      -0.056349702179431915,
      0.03256221488118172,
      0.06253734230995178,
      0.06439509242773056,
      0.021205801516771317,
      0.042628850787878036,
      -0.060713931918144226,
      0.08022027462720871,
      -0.13827502727508545,
      -0.04913872480392456,
      -0.04145071282982826,
      0.09566889703273773,
      -0.04768722504377365,
      -0.030014194548130035,
      0.02022138237953186,
      0.011568581685423851,
      0.06210409849882126,
      0.03551182150840759,
      -0.006026002578437328,
      -0.015989061444997787,
      0.05652810260653496,
      -0.03540419414639473,
      -0.06105007231235504,
      -0.02913282997906208,
      0.03681795671582222,
      -0.031073030084371567,
      0.009789756499230862,
      0.03825648874044418,
      0.02807219885289669,
      -0.01439760997891426,
      0.045028191059827805,
      -0.02329571358859539,
      0.04576985910534859,
      0.07213607430458069,
      -0.011319627985358238,
      -0.016851043328642845,
      -0.03884037211537361,
      -0.056880462914705276,
      -0.028931519016623497,
      0.06447834521532059,
      -0.006624820176512003,
      0.036031752824783325,
      0.002611336298286915,
      0.050939034670591354,
      -0.09947454929351807,
      0.0012540675234049559,
      0.021778399124741554,
      -0.007131216581910849,
      -0.01800720952451229,
      -0.09726009517908096,
      0.0017123505240306258,
      -0.059323180466890335,
      -0.015301424078643322,
      -0.00175693747587502,
      -0.04979850724339485,
      0.005658354144543409,
      0.013832339085638523,
      -0.002956027165055275,
      -0.019718412309885025,
      -0.016418177634477615,
      0.04222777485847473,
      0.02658805437386036,
      0.050698500126600266,
      0.00746954744681716,
      0.052427008748054504,
      0.011904371902346611,
      0.014503957703709602,
      -0.009410187602043152,
      -0.030974026769399643,
      -0.06950914114713669,
      -0.016205595806241035,
      -0.03476274758577347,
      -0.021293427795171738,
      0.04508218914270401,
      -0.018021270632743835,
      -0.058633849024772644,
      0.03889229893684387,
      -0.005625384394079447,
      -0.03339603543281555,
      0.006246879231184721,
      0.03143260255455971,
      -0.0003932280815206468,
      0.015224806033074856,
      0.031936321407556534,
      -0.03226121887564659,
      0.015480605885386467,
      0.006885195150971413,
      0.03761225938796997,
      0.06998728215694427,
      -0.02016935870051384,
      0.07151690125465393,
      -0.015932230278849602,
      -0.08542971312999725,
      -0.05005890876054764,
      -0.05692986398935318,
      -0.08391493558883667,
      -0.07628028839826584,
      -0.04261729121208191,
      0.047293081879615784,
      -0.006017574109137058,
      -0.07977037131786346
    ],
    [
      0.0547347329556942,
      0.0037071476690471172,
      0.012459688819944859,
      -0.018126390874385834,
      -0.0211164653301239,
      -0.025124846026301384,
      -0.06359383463859558,
      0.043770674616098404,
      -0.008731517009437084,
      0.0442005954682827,
      -0.07949437201023102,
      -0.03900407254695892,
      0.03635159134864807,
      0.08567079156637192,
      -0.034530751407146454,
      0.06772039085626602,
      -0.011281037703156471,
      -0.05849141627550125,
      0.04978761821985245,
      0.0105545399710536,
      -0.002596855629235506,
      -0.0046763294376432896,
      0.040659673511981964,
      -0.05277569964528084,
      0.004179207142442465,
      0.07640684396028519,
      0.03531401976943016,
      -0.10692049562931061,
      0.038026001304388046,
      0.00938503723591566,
      -0.019867712631821632,
      0.09091877192258835,
      -0.04731778800487518,
      -0.02894710935652256,
      0.1063491627573967,
      -0.0057284715585410595,
      0.014685445465147495,
      0.029971348121762276,
      0.028761299327015877,
      0.055870648473501205,
      -0.015834223479032516,
      0.08342280983924866,
      -0.14605677127838135,
      0.040163397789001465,
      0.044127728790044785,
      -0.05847086384892464,
      0.10048878937959671,
      -0.023386454209685326,
      0.09117552638053894,
      -0.01647466979920864,
      -0.02550646662712097,
      -0.08286270499229431,
      -0.048162441700696945,
      0.027437614277005196,
      -0.03312422335147858,
      -0.013038136065006256,
      -0.04197906702756882,
      -0.03701033815741539,
      0.03427731618285179,
      -0.004181820899248123,
      -0.00833093747496605,
      0.04153992235660553,
      0.04316180199384689,
      0.07455023378133774,
      0.044784169644117355,
      0.1232597827911377,
      -0.07241372764110565,
      -0.04879691079258919,
      0.05518712103366852,
      -0.0066024502739310265,
      0.042232196778059006,
      0.033883385360240936,
      0.048780083656311035,
      0.012496443465352058,
      -0.02052166685461998,
      -0.004242003429681063,
      -0.0033936933614313602,
      0.07465741783380508,
      -0.0380261093378067,
      -0.041434481739997864,
      0.020996354520320892,
      0.044653184711933136,
      0.023159291595220566,
      -0.016651403158903122,
      0.0741756334900856,
      -0.03849529102444649,
      -0.013731161132454872,
      -0.009016616269946098,
      -0.050542593002319336,
      -0.0009363099816255271,
      0.052404411137104034,
      0.011769851669669151,
      -0.07351642102003098,
      -0.021046144887804985,
      0.09592201560735703,
      0.03177013620734215,
      -0.010606919415295124,
      0.01605696976184845,
      0.00038567959563806653,
      0.0791347399353981,
      -0.011660991236567497,
      0.025584382936358452,
      -0.005352551117539406,
      0.0020732043776661158,
      -0.03918742761015892,
      -0.07215747982263565,
      -0.002789975143969059,
      0.07814149558544159,
      -0.018636513501405716,
      -0.11310063302516937,
      0.0198482945561409,
      -0.02106887847185135,
      -0.05092911422252655,
      0.015346934087574482,
      -0.008733735419809818,
      0.08753076940774918,
      0.03670638054609299,
      -0.018968962132930756,
      -0.06414375454187393,
      0.06431691348552704,
      -0.029925061389803886,
      -0.018663868308067322,
      -0.014232086017727852,
      0.008589793927967548,
      0.002171209082007408,
      0.024334847927093506,
      0.0003654792672023177,
      -0.02143663354218006,
      0.016769474372267723,
      -0.007203780580312014,
      0.04864148050546646,
      -0.040736276656389236,
      0.008295697160065174,
      -0.061303459107875824,
      -0.04009893536567688,
      -0.006042328663170338,
      0.04399340972304344,
      0.00728559447452426,
      -0.003992077428847551,
      0.011444750241935253,
      -0.05328512191772461,
      -0.00157022918574512,
      0.012273835949599743,
      0.04767949506640434,
      0.0476657971739769,
      -0.05792030692100525,
      0.02467934787273407,
      0.023820437490940094,
      -0.07009295374155045,
      -0.06042151898145676,
      -0.003290200838819146,
      0.019725404679775238,
      -0.03737117722630501,
      -0.034307654947042465,
      0.00020903078257106245,
      -0.006623546127229929,
      0.05184372887015343,
      -0.03046400472521782,
      -0.09336908161640167,
      0.013351289555430412,
      -0.02713179588317871,
      -0.06841254234313965,
      -0.03064093180000782,
      0.026145966723561287,
      0.005778962280601263,
      0.030439259484410286,
      0.05125357583165169,
      -0.039979174733161926,
      -0.04898155480623245,
      0.012625754810869694,
      0.006687069311738014,
      -0.01197525579482317,
      -0.0020559614058583975,
      0.015503865666687489,
      -0.06764843314886093,
      -0.05181219056248665,
      0.019771546125411987,
      0.013315021991729736,
      0.028784828260540962,
      0.037604693323373795,
      -0.04746481403708458,
      -0.08386727422475815,
      0.004526053089648485,
      0.02565859816968441,
      0.021313851699233055,
      0.01749386265873909,
      -0.05946032330393791,
      -0.11371752619743347,
      0.00939498096704483,
      0.014928220771253109,
      -0.06650854647159576,
      0.01177301723510027,
      -0.06774646043777466,
      0.034234847873449326,
      -0.08881699293851852,
      -0.09791989624500275,
      0.019389163702726364,
      -0.03128978610038757,
      0.06220586970448494,
      -0.04766014218330383,
      0.019810689613223076,
      0.018485132604837418,
      -0.013727872632443905,
      -0.0330875888466835,
      -0.04087525233626366,
      -0.015102996490895748,
      0.0073961191810667515,
      0.02182636596262455,
      0.04743494465947151,
      -0.01846875436604023,
      -0.03752757981419563,
      0.09582270681858063,
      0.001901672687381506,
      -0.009220478124916553,
      0.03549167513847351,
      0.09635942429304123,
      -0.0902610719203949,
      -0.006177040282636881,
      -0.01229016575962305,
      0.02352561429142952,
      -0.055094778537750244,
      0.003588953288272023,
      0.045793842524290085,
      -0.08891576528549194,
      0.01863093115389347,
      0.004446662031114101,
      0.002200381364673376,
      -0.02146279066801071,
      -0.013547566719353199,
      -0.028171969577670097,
      0.03795151039958,
      0.03145962581038475,
      0.013373644091188908,
      0.010994868353009224,
      -0.041745662689208984,
      -0.020577790215611458,
      -0.020425232127308846,
      -0.0065227085724473,
      0.06182023882865906,
      0.003695383667945862,
      0.013927158899605274,
      0.05446240305900574,
      0.06975631415843964,
      0.013674880377948284,
      -0.020143752917647362,
      0.038064081221818924,
      -0.04258144274353981,
      0.0039389594458043575,
      0.04793985188007355,
      0.006263670977205038,
      0.1646832674741745,
      -0.025762248784303665,
      0.032482508569955826,
      -0.07207799702882767,
      -0.10449174791574478,
      0.0022876523435115814,
      0.020252443850040436,
      0.1099836453795433,
      0.045998502522706985,
      0.05120426043868065,
      0.04043882340192795,
      0.051514387130737305,
      -0.006076553370803595,
      -0.03128981590270996,
      -0.10407686978578568,
      -0.024126490578055382,
      -0.008055522106587887,
      0.023123912513256073,
      -0.05025169625878334,
      0.017018452286720276,
      -0.06133905425667763,
      -0.07495620846748352,
      -0.0325787290930748,
      0.033116910606622696,
      0.024468136951327324,
      0.030939266085624695,
      0.02171386033296585,
      -0.028072942048311234,
      -0.07456262409687042,
      -0.015861909836530685,
      -0.0013901402708142996,
      -0.007349643390625715,
      0.014064811170101166,
      0.02299649454653263,
      0.08350247144699097,
      0.005553307943046093,
      0.027321765199303627,
      0.04276807978749275,
      -6.018777276040055e-05,
      0.03469349816441536,
      0.04506031423807144,
      0.031104201450943947,
      0.023715950548648834,
      -0.11859308928251266,
      -0.05966688320040703,
      0.0538434274494648,
      -0.14699885249137878,
      -0.010728554800152779,
      0.045159898698329926,
      0.02029612846672535,
      -0.04750453308224678,
      -0.04567810893058777,
      0.003070872975513339,
      0.028430571779608727,
      0.038028717041015625,
      -0.013455770909786224,
      0.003480734536424279,
      -0.012054570019245148,
      0.009204565547406673,
      -0.045614585280418396,
      -0.01187756098806858,
      -0.06273074448108673,
      -0.010377360507845879,
      -0.0462322011590004,
      0.045627862215042114,
      0.014081823639571667,
      0.12533646821975708,
      -0.046979259699583054,
      -0.07802755385637283,
      -0.03190033137798309,
      -0.024765245616436005,
      0.0390392504632473,
      -0.06745538860559464,
      -0.04925786703824997,
      0.017419643700122833,
      -0.052239444106817245,
      0.01943947933614254,
      -0.001963360933586955,
      -0.04779352992773056,
      0.011146299540996552,
      0.01985163800418377,
      0.057222552597522736,
      -0.01811007596552372,
      0.039539884775877,
      -0.026644166558980942,
      -0.0527171716094017,
      0.08735394477844238,
      -0.027763796970248222,
      0.06261223554611206,
      -0.022479327395558357,
      -0.019202932715415955,
      0.038737181574106216,
      -0.04771498218178749,
      0.049205344170331955,
      0.037872958928346634,
      0.024977626278996468,
      0.0003065718919970095,
      -0.02889816090464592,
      -0.058736227452754974,
      0.02032870054244995,
      0.014354273676872253,
      -0.01698032021522522,
      -0.037975069135427475,
      -0.026815736666321754,
      0.03487936034798622,
      -0.010714161209762096,
      -0.031965866684913635,
      0.03573322668671608,
      0.009856190532445908,
      -0.013363020494580269,
      0.020396538078784943,
      -0.014588502235710621,
      0.03555047884583473,
      -0.02884058654308319,
      0.00631625484675169,
      -0.04055936262011528,
      -0.019699664786458015,
      0.07556258887052536,
      -0.0476849228143692,
      0.029722018167376518,
      0.01769905909895897,
      -0.07275404781103134,
      -0.06519456207752228,
      0.04897366091609001,
      0.02468128129839897,
      0.06234835460782051,
      -0.0784001424908638,
      -0.046746715903282166,
      -0.0004918423946946859,
      -0.030354972928762436,
      -0.07840880751609802,
      0.03011525794863701,
      0.11185108870267868,
      -0.02681441232562065,
      -0.026913529261946678,
      -0.07873790711164474,
      0.02692151814699173,
      0.020952574908733368,
      0.07228688150644302,
      0.047002583742141724,
      -0.016187435016036034,
      0.02822643518447876,
      -0.008924783207476139,
      -0.009456571191549301,
      -0.014505132101476192,
      -0.03660205751657486,
      0.03418224677443504,
      0.03430398553609848,
      0.033226750791072845,
      -0.016207916662096977,
      0.016875017434358597,
      0.017688682302832603,
      0.018753085285425186,
      0.10843512415885925,
      0.05004232004284859,
      -0.023860745131969452,
      -0.05248326435685158,
      0.03234062343835831,
      -0.002981131197884679,
      -0.05589466542005539,
      0.08614059537649155,
      -0.021415606141090393,
      0.04910392314195633,
      -0.09354013204574585,
      -0.04530481994152069,
      -0.0490012988448143,
      0.05851137265563011,
      0.03413965925574303,
      0.012317437678575516,
      -0.025618933141231537,
      -0.015034342184662819,
      0.05243900790810585,
      -0.04776350408792496,
      0.007215567398816347,
      0.04040089622139931,
      -0.021762385964393616,
      0.05627848953008652,
      -0.04069964215159416,
      -0.09834384173154831,
      -0.05866498500108719,
      0.0012030249927192926,
      -0.01590823195874691,
      -0.03594017028808594,
      -0.02155238203704357,
      0.013598931021988392,
      -0.023659322410821915,
      0.07066193968057632,
      0.0710778683423996,
      0.005675676744431257,
      -0.028032362461090088,
      0.015750855207443237,
      0.0193213801831007,
      -0.0646997019648552,
      0.010710598900914192,
      -0.06889891624450684,
      -0.06384643167257309,
      -0.011208572424948215,
      -0.05018570274114609,
      0.04071803390979767,
      -0.013370318338274956,
      0.0038360271137207747,
      -0.00888868235051632,
      -0.10929065197706223,
      0.044722579419612885,
      0.0234901811927557,
      -0.008381303399801254,
      0.03995811939239502,
      -0.016626233235001564,
      -0.06473536789417267,
      -0.06408260762691498,
      -0.007483199704438448,
      -0.0478033684194088,
      -0.015348822809755802,
      0.06411481648683548,
      0.019778583198785782,
      -0.11759059131145477,
      0.027478542178869247,
      0.11119464784860611,
      -0.01926470920443535,
      -0.019858114421367645,
      -0.03202318400144577,
      0.06321672350168228,
      -0.08546848595142365,
      0.07311638444662094,
      0.015935862436890602,
      0.03064700961112976,
      0.056010615080595016,
      0.006355692632496357,
      -0.05559495836496353,
      -0.02747696451842785,
      0.028044722974300385,
      -0.07842988520860672,
      -0.03429149091243744,
      -0.004270033445209265,
      -0.009658921509981155,
      0.04228794202208519,
      -0.013862406834959984,
      0.09585456550121307,
      -0.023241108283400536,
      -0.0286877378821373,
      0.06520052254199982,
      0.03916960954666138,
      -0.032783765345811844,
      -0.025014827027916908,
      0.034197524189949036,
      0.0723680928349495,
      0.08822450041770935,
      0.037583980709314346,
      0.0700506716966629,
      0.012171543203294277,
      -0.0038222698494791985,
      0.021837890148162842,
      -0.030163196846842766
    ],
    [
      -0.059042226523160934,
      0.011387924663722515,
      -0.006504822056740522,
      0.020688297227025032,
      0.045630909502506256,
      0.020676158368587494,
      0.020082907751202583,
      0.030802549794316292,
      -0.018531231209635735,
      -0.03776901215314865,
      -0.12480460852384567,
      -0.02338322065770626,
      -0.03756275400519371,
      0.027964206412434578,
      0.007621094584465027,
      0.058056123554706573,
      -0.0628323182463646,
      0.04197424277663231,
      -0.021948453038930893,
      0.04042083024978638,
      -0.015040142461657524,
      0.04059388488531113,
      -0.06747923791408539,
      -0.11293604969978333,
      0.03992650285363197,
      0.005955221131443977,
      -0.004210572224110365,
      -0.008083004504442215,
      0.043190035969018936,
      0.05392417311668396,
      -0.012618259526789188,
      0.032297197729349136,
      0.008337462320923805,
      0.016434913501143456,
      0.005149449221789837,
      -0.08728016167879105,
      0.04060424119234085,
      0.00339892553165555,
      -0.0006188356201164424,
      0.06255998462438583,
      0.016380082815885544,
      -0.006989637389779091,
      -0.030987974256277084,
      -0.020789843052625656,
      0.00029948429437354207,
      0.007831534370779991,
      -0.02142459526658058,
      0.048986855894327164,
      -0.023827457800507545,
      0.001244421349838376,
      0.055729787796735764,
      0.06498043239116669,
      -0.06657654792070389,
      -0.05469496175646782,
      -0.04210261255502701,
      0.038534682244062424,
      -0.08495325595140457,
      0.048720575869083405,
      0.04485507681965828,
      -0.023308975622057915,
      0.057857006788253784,
      0.00533051835373044,
      0.03441653028130531,
      -0.03959254175424576,
      -0.010263994336128235,
      0.03759017214179039,
      -0.04286173731088638,
      0.046056099236011505,
      -0.009017753414809704,
      -0.014281801879405975,
      -0.032860688865184784,
      -0.02648802101612091,
      -0.0136595843359828,
      0.02156565710902214,
      -0.0010628780582919717,
      0.05427492409944534,
      0.033793579787015915,
      -0.04860874265432358,
      -0.0013563913526013494,
      -0.07842309027910233,
      0.017721936106681824,
      -0.022687682881951332,
      0.05921774357557297,
      0.010260814800858498,
      -0.028086714446544647,
      0.03770707920193672,
      0.08453535288572311,
      -0.028807751834392548,
      0.0076732998713850975,
      0.06658417731523514,
      -0.03509246185421944,
      -0.025148559361696243,
      -0.015756702050566673,
      -0.005899498704820871,
      0.04321428760886192,
      -0.030680619180202484,
      -0.04682229831814766,
      -0.05574151873588562,
      0.026603326201438904,
      -0.03072265163064003,
      -0.021435124799609184,
      -0.00941319391131401,
      0.033545173704624176,
      -0.02054411545395851,
      -0.024918625131249428,
      -0.03191490098834038,
      -0.013849129900336266,
      0.05643811821937561,
      -0.017546962946653366,
      -0.021813709288835526,
      -0.03145037218928337,
      -0.027860300615429878,
      0.11056851595640182,
      -0.004342199303209782,
      0.07632395625114441,
      -0.04585159569978714,
      -0.06477821618318558,
      0.014394215308129787,
      0.0700824186205864,
      0.027243390679359436,
      -0.10201139003038406,
      0.09036359190940857,
      0.02861126698553562,
      0.03128313645720482,
      0.0444893017411232,
      -0.05971544235944748,
      0.039479952305555344,
      -0.029105782508850098,
      0.009510008618235588,
      0.01985621079802513,
      0.06894827634096146,
      -0.030022449791431427,
      -0.05088498070836067,
      -0.0717724859714508,
      0.030494410544633865,
      -0.028119999915361404,
      -0.014256189577281475,
      0.003832749091088772,
      -0.019399650394916534,
      0.02811169996857643,
      -0.07800261676311493,
      -0.008742587640881538,
      0.017396152019500732,
      -0.06274542957544327,
      -0.05265706777572632,
      0.03754989802837372,
      0.0206553116440773,
      -0.009520296938717365,
      0.013270131312310696,
      -0.06413809210062027,
      0.09068663418292999,
      0.018738282844424248,
      0.05262191221117973,
      0.0068709938786923885,
      -0.041006900370121,
      0.07482653856277466,
      0.03581066429615021,
      0.058462582528591156,
      -0.009493576362729073,
      -0.04813922569155693,
      0.017488302662968636,
      -0.05271752178668976,
      -0.03367738798260689,
      0.028189394623041153,
      -0.016693221405148506,
      0.039632707834243774,
      0.053152039647102356,
      -0.05334710329771042,
      -0.03531354293227196,
      0.04185112938284874,
      -0.024655679240822792,
      0.059191059321165085,
      0.07822410762310028,
      0.004093960393220186,
      -0.029363049194216728,
      -0.028084227815270424,
      0.012844152748584747,
      -0.08943730592727661,
      -0.039791446179151535,
      0.042384881526231766,
      -0.02912122942507267,
      0.012178405188024044,
      0.00883617252111435,
      0.06296074390411377,
      0.014446496963500977,
      -0.08326897025108337,
      -0.004262951668351889,
      -0.029442643746733665,
      0.015117847360670567,
      0.005404752679169178,
      0.06835229694843292,
      0.05851542949676514,
      -0.013554733246564865,
      0.024369966238737106,
      0.04836849868297577,
      -0.04230528697371483,
      0.00034243467962369323,
      0.03277559205889702,
      -0.06840962916612625,
      -0.06077556684613228,
      -0.010269265621900558,
      -0.06459948420524597,
      -0.013050573877990246,
      -0.006838968489319086,
      0.0996820256114006,
      0.05972866341471672,
      0.017279017716646194,
      0.018600890412926674,
      0.041218094527721405,
      0.06308327615261078,
      -0.08197656273841858,
      -0.02964869886636734,
      -0.046060334891080856,
      -0.0016518747434020042,
      -0.07805466651916504,
      0.02182685025036335,
      0.019329946488142014,
      -0.07029147446155548,
      -0.013992510735988617,
      -0.04582543298602104,
      0.008753830567002296,
      -0.10571622848510742,
      0.011801663786172867,
      0.0057123880833387375,
      -0.004210942424833775,
      -0.06612967699766159,
      -0.012670806609094143,
      0.0056181903928518295,
      0.05565967410802841,
      -0.014305150136351585,
      -0.08949079364538193,
      -0.02201646752655506,
      0.0617891363799572,
      -0.04702218621969223,
      0.0308527871966362,
      0.055928293615579605,
      0.01118631660938263,
      -0.061288271099328995,
      -0.0009406317840330303,
      0.027103686705231667,
      0.05099969357252121,
      0.037804946303367615,
      -0.028158748522400856,
      -0.12973368167877197,
      0.028440801426768303,
      -0.01817132905125618,
      0.030335575342178345,
      0.019360890612006187,
      -0.010354457423090935,
      -0.0646297037601471,
      0.01634393073618412,
      -0.04227719083428383,
      0.027772216126322746,
      0.05667516216635704,
      0.08352699130773544,
      -0.02254856564104557,
      0.1029515415430069,
      -0.011009528301656246,
      0.03478186950087547,
      -0.04263249784708023,
      0.007764254231005907,
      0.050453342497348785,
      0.013066951185464859,
      -0.02737126499414444,
      -0.006383812986314297,
      0.01416365522891283,
      -0.04200812056660652,
      0.03322679176926613,
      -0.08376574516296387,
      0.01785529963672161,
      -0.013632264919579029,
      -0.007382701151072979,
      0.03402583301067352,
      0.0013694530352950096,
      0.012041877023875713,
      -0.03418228030204773,
      0.01921364665031433,
      0.0023961514234542847,
      -0.02259555272758007,
      0.03088134340941906,
      0.020891260355710983,
      -0.035242099314928055,
      0.03356542810797691,
      -0.011230707168579102,
      -0.053105466067790985,
      -0.03581767901778221,
      -0.05283679813146591,
      -0.007604614365845919,
      0.0459354929625988,
      0.029895933344960213,
      0.06770716607570648,
      -0.013307476416230202,
      0.001300726318731904,
      -0.037308018654584885,
      -0.016304468736052513,
      0.016545914113521576,
      -0.01568489335477352,
      0.022205589339137077,
      0.03159881383180618,
      -0.04401961341500282,
      -0.030179547145962715,
      -0.026108544319868088,
      0.10158009827136993,
      0.031041771173477173,
      0.01318011898547411,
      0.030649123713374138,
      -0.0165177620947361,
      -0.001083966577425599,
      -0.06488741934299469,
      0.01515012513846159,
      -0.03401150926947594,
      0.06605653464794159,
      0.038584280759096146,
      -0.031523462384939194,
      0.0069715906865894794,
      0.009874644689261913,
      0.04181235656142235,
      -0.07714743167161942,
      0.012753828428685665,
      0.02874346449971199,
      0.00883960910141468,
      0.054680049419403076,
      0.04281526803970337,
      -0.05336611717939377,
      0.03336988389492035,
      -0.008111023344099522,
      0.04361309856176376,
      0.03725646063685417,
      -0.05964205414056778,
      -0.0087014464661479,
      0.01380240824073553,
      -0.05128537490963936,
      -0.030262408778071404,
      0.049321334809064865,
      -0.04761212691664696,
      0.023352110758423805,
      -0.07531031221151352,
      0.06410400569438934,
      -0.036217592656612396,
      -0.07806887477636337,
      -0.003329313127323985,
      0.09046613425016403,
      -0.047288477420806885,
      0.07913486659526825,
      0.024016838520765305,
      -0.016543885692954063,
      0.11824055016040802,
      0.031966499984264374,
      0.04646626114845276,
      -0.013403081335127354,
      0.041617631912231445,
      0.02400987595319748,
      0.045533906668424606,
      -0.005397995468229055,
      -0.09914277493953705,
      0.018150493502616882,
      -0.053221870213747025,
      -0.01674635149538517,
      0.024767054244875908,
      0.09238939732313156,
      -0.07504730671644211,
      0.008309789933264256,
      0.03279087692499161,
      0.06002280116081238,
      -0.0177585668861866,
      -0.023361926898360252,
      0.06761559844017029,
      0.015939073637127876,
      -0.0522015281021595,
      0.04227440804243088,
      0.06807864457368851,
      0.05167699232697487,
      -0.05318232625722885,
      -0.030352940782904625,
      -0.07359393686056137,
      -0.0409843809902668,
      0.0400107204914093,
      0.010675822384655476,
      -0.05794114992022514,
      0.00920988991856575,
      0.0270446315407753,
      0.023376883938908577,
      -0.06196475774049759,
      -0.02441364899277687,
      -0.03049314394593239,
      0.031089914962649345,
      0.04779765382409096,
      -0.005263810511678457,
      0.008236192166805267,
      -0.04650312289595604,
      0.07481798529624939,
      -0.015181761234998703,
      0.014057408086955547,
      0.05357261374592781,
      -0.0979519933462143,
      -0.03889394551515579,
      -0.0133309755474329,
      -0.01697022095322609,
      0.032260533422231674,
      -0.03694409877061844,
      -0.010486013256013393,
      -0.07146967202425003,
      -0.004995641298592091,
      -0.02941153571009636,
      0.012061801739037037,
      -0.011571804992854595,
      -0.10163859277963638,
      0.010832196101546288,
      0.04007826745510101,
      -0.03559092432260513,
      -0.07346485555171967,
      0.01655157469213009,
      0.0053165671415627,
      -0.008198739029467106,
      -0.05539940297603607,
      0.0006615940947085619,
      0.027086183428764343,
      0.020740501582622528,
      -0.01779339835047722,
      0.03508222475647926,
      -0.02998264506459236,
      0.0019540349021553993,
      0.002411672379821539,
      0.016936879605054855,
      -0.01649351790547371,
      -0.02142888493835926,
      0.011599811725318432,
      -0.023132825270295143,
      -0.030561447143554688,
      0.0035583162680268288,
      -0.014876370318233967,
      -0.03285424783825874,
      0.013416233472526073,
      -0.003280468750745058,
      0.008611882105469704,
      -0.0655117854475975,
      0.05025529861450195,
      0.047006480395793915,
      0.03740407153964043,
      -0.0028263521380722523,
      0.04572669044137001,
      -0.06376419216394424,
      -0.06656786054372787,
      0.025286804884672165,
      -0.059617165476083755,
      -0.008708068169653416,
      -0.032822418957948685,
      0.06343841552734375,
      0.03932218253612518,
      -0.027199536561965942,
      0.03773035481572151,
      -0.03854856640100479,
      0.003110360587015748,
      -0.02815164066851139,
      -0.06699686497449875,
      -0.023126639425754547,
      0.04929489269852638,
      -0.0728553757071495,
      -0.031454119831323624,
      0.033759716898202896,
      0.031283143907785416,
      0.035412825644016266,
      -0.12210149317979813,
      0.09812632948160172,
      0.06809331476688385,
      -0.01206640712916851,
      0.005842932034283876,
      0.08147338777780533,
      0.02625216543674469,
      0.004846014082431793,
      -0.020377643406391144,
      0.072506383061409,
      -0.007793316151946783,
      0.012868741527199745,
      -0.04529305920004845,
      -0.0922975242137909,
      -0.009805764071643353,
      0.035895075649023056,
      0.068796306848526,
      0.020967720076441765,
      -0.0789872258901596,
      0.012508866377174854,
      -0.04112323373556137,
      0.09361652284860611,
      -0.042741987854242325,
      -0.02328505553305149,
      -0.008832043036818504,
      -0.013212093152105808,
      0.023890644311904907,
      0.06007075309753418,
      -0.022158212959766388,
      -0.0037941127084195614,
      -0.04109276086091995,
      -0.026473218575119972,
      -0.007091162726283073,
      -0.024607963860034943,
      0.03547351062297821,
      -0.08098070323467255,
      0.020467625930905342,
      -0.05022692307829857,
      0.0571519173681736,
      -0.031089138239622116,
      0.008219638839364052
    ],
    [
      -0.017739534378051758,
      0.06683653593063354,
      0.03180456534028053,
      -0.05645507946610451,
      0.005636494141072035,
      -0.013799723237752914,
      0.024732667952775955,
      0.005320381838828325,
      0.05092332512140274,
      -0.01386090274900198,
      -0.007583091035485268,
      0.0297558456659317,
      -0.09339916706085205,
      0.01663690246641636,
      0.007151137106120586,
      0.0032031305599957705,
      -0.017779655754566193,
      -0.008041796274483204,
      -0.014902595430612564,
      0.03942539542913437,
      -0.0657052993774414,
      -0.015949292108416557,
      -0.03989674896001816,
      -0.05773280933499336,
      0.014977428130805492,
      -0.05165116861462593,
      -0.0551866814494133,
      -0.046722911298274994,
      -0.05701042711734772,
      -0.0103770662099123,
      0.0718630701303482,
      -0.07441683858633041,
      0.028958439826965332,
      0.06974373757839203,
      -0.030675051733851433,
      -0.0698917806148529,
      0.02234170213341713,
      0.054349496960639954,
      0.010312071070075035,
      0.002798165427520871,
      -0.02824372425675392,
      -0.09213107079267502,
      -0.010859105736017227,
      0.014479607343673706,
      0.00018215522868558764,
      0.05598378926515579,
      -0.05182189121842384,
      -0.06318110227584839,
      0.025774886831641197,
      0.0145021453499794,
      0.05262871831655502,
      -0.019621962681412697,
      0.08295534551143646,
      -0.006805305369198322,
      0.010457622818648815,
      -0.042311783879995346,
      0.07697644829750061,
      -0.0037660724483430386,
      0.001784602995030582,
      0.06129470095038414,
      0.0482490248978138,
      0.056840114295482635,
      0.025197191163897514,
      0.034494057297706604,
      -0.002134572481736541,
      -0.017911115661263466,
      0.013285938650369644,
      -0.04263101890683174,
      -0.03732151910662651,
      -0.0444800965487957,
      0.07372934371232986,
      -0.06111995130777359,
      -0.010912646539509296,
      -0.11733747273683548,
      -0.04316089302301407,
      0.0019446740625426173,
      -0.010982983745634556,
      -0.01792108826339245,
      -0.00836070254445076,
      0.020606212317943573,
      0.04913605749607086,
      -0.002834784332662821,
      0.04431522637605667,
      -0.012696472927927971,
      0.027392849326133728,
      0.0037183209788054228,
      -0.010460332036018372,
      -0.002027967944741249,
      -0.007184650283306837,
      -0.06469358503818512,
      -0.004344843793660402,
      0.03065125085413456,
      -0.04536697641015053,
      0.02058669738471508,
      0.03317594155669212,
      -0.013438031077384949,
      0.029783131554722786,
      0.039086781442165375,
      -0.02986595593392849,
      0.004732358269393444,
      -0.009296082891523838,
      -0.07478857040405273,
      -0.0052637699991464615,
      -0.055342789739370346,
      -0.02755938470363617,
      -0.005671908147633076,
      -0.025949202477931976,
      0.05121908709406853,
      -0.003060657298192382,
      0.03014186955988407,
      0.0037465651985257864,
      0.015797754749655724,
      0.021787548437714577,
      -0.02257474884390831,
      -0.004751686938107014,
      0.008023267611861229,
      0.00505230063572526,
      -0.09458763152360916,
      -0.08722292631864548,
      0.01059811469167471,
      0.029600655660033226,
      0.009035193361341953,
      0.01719353348016739,
      0.08996380865573883,
      -0.01945606991648674,
      -0.024681001901626587,
      -0.025353549048304558,
      -0.06607579439878464,
      0.01669963262975216,
      0.11537671089172363,
      0.04378444328904152,
      -0.04462450370192528,
      -0.032162223011255264,
      0.04069862887263298,
      0.03368816152215004,
      0.017525164410471916,
      0.024637948721647263,
      -0.006244749296456575,
      0.04795832931995392,
      -0.02213655225932598,
      0.08471684157848358,
      -0.037040483206510544,
      -0.027584610506892204,
      0.11818750947713852,
      -0.04910188168287277,
      0.041800301522016525,
      -0.049215588718652725,
      -0.04352930560708046,
      0.01482145581394434,
      -0.008288071490824223,
      0.05803955718874931,
      -0.07256927341222763,
      0.03382917493581772,
      -0.02880777418613434,
      0.0001500673097325489,
      -0.054788462817668915,
      0.0045165191404521465,
      0.03953084349632263,
      -0.05510810390114784,
      -0.0018257156480103731,
      0.0005324395024217665,
      -0.03234490379691124,
      -0.011974034830927849,
      0.06798296421766281,
      -0.02915855683386326,
      -0.04213888943195343,
      0.005297515075653791,
      -0.02183939330279827,
      -0.12462632358074188,
      0.06867194920778275,
      -0.0805966928601265,
      0.07931967079639435,
      0.09065687656402588,
      -0.02423076517879963,
      0.032839223742485046,
      0.12434715032577515,
      0.03893444687128067,
      0.014971883036196232,
      -0.03195662423968315,
      0.004459077958017588,
      -0.07753412425518036,
      -0.005513033829629421,
      0.03562787175178528,
      0.018550362437963486,
      0.05128005892038345,
      -0.11862947046756744,
      -0.012727305293083191,
      -0.08394967764616013,
      -0.03823830932378769,
      -0.018387967720627785,
      0.03300395980477333,
      -0.06009780615568161,
      0.016992168501019478,
      0.01018484216183424,
      -0.04311174899339676,
      -0.03728481009602547,
      -0.024052197113633156,
      0.054546091705560684,
      0.036152854561805725,
      -0.0709599107503891,
      -0.05384087562561035,
      -0.04593022167682648,
      0.04732578992843628,
      0.017404772341251373,
      0.08930676430463791,
      0.08234382420778275,
      0.0007513316231779754,
      0.012218314222991467,
      -0.004543420393019915,
      -0.0010010572150349617,
      0.01292366348206997,
      -0.05141270160675049,
      -0.06868358701467514,
      -0.030633868649601936,
      0.042409878224134445,
      0.027354873716831207,
      0.032219983637332916,
      -0.0011159408604726195,
      0.029999563470482826,
      -0.0027565655764192343,
      -0.07207708060741425,
      0.1041569858789444,
      -0.0590774342417717,
      -0.03366433084011078,
      0.03529525175690651,
      0.002387834247201681,
      0.022611230611801147,
      0.03506677597761154,
      0.032432835549116135,
      0.038580797612667084,
      0.0377141572535038,
      0.06478406488895416,
      0.0012529672821983695,
      -0.018212921917438507,
      -0.06347796320915222,
      0.019375719130039215,
      0.010655444115400314,
      -0.03464844822883606,
      -0.025765910744667053,
      0.04339957609772682,
      -0.008277982473373413,
      0.0388520322740078,
      0.009382256306707859,
      0.03315574675798416,
      0.004785293713212013,
      -0.031395215541124344,
      0.03359512984752655,
      0.03995613753795624,
      0.014212407171726227,
      -0.01942463405430317,
      0.03651132807135582,
      -0.018499065190553665,
      0.048362791538238525,
      0.022268902510404587,
      0.06162802129983902,
      -0.06324780732393265,
      -0.00205731438472867,
      0.021305834874510765,
      -0.0061051142401993275,
      -0.03074326552450657,
      0.045141641050577164,
      0.008246418088674545,
      -0.02367418445646763,
      0.0025959282647818327,
      -0.015043686144053936,
      -0.045222457498311996,
      0.0007811679388396442,
      -0.04399241879582405,
      0.01097787730395794,
      0.003166391747072339,
      0.008943391032516956,
      0.021277805790305138,
      0.00852468702942133,
      0.017717523500323296,
      0.045413538813591,
      -0.0033336288761347532,
      -0.016698531806468964,
      0.04610526189208031,
      0.07292304933071136,
      -0.008102791383862495,
      0.020752571523189545,
      0.004035551100969315,
      0.03093339316546917,
      0.013957862742245197,
      0.07536473870277405,
      -0.0014749070396646857,
      -0.026211734861135483,
      -0.05655451864004135,
      -0.020981701090931892,
      -0.02105097845196724,
      -0.007015315815806389,
      -0.05832122638821602,
      0.03046690858900547,
      -0.05329560860991478,
      -0.03977302461862564,
      -0.1031639575958252,
      -0.07027541846036911,
      -0.043232496827840805,
      -0.02524646744132042,
      0.03325039893388748,
      -0.011410479433834553,
      0.011874127201735973,
      0.04813162237405777,
      -0.04230552166700363,
      0.034606412053108215,
      -0.012741255573928356,
      -0.03597927838563919,
      0.02507876232266426,
      0.03435712680220604,
      0.03351642191410065,
      -0.06692966818809509,
      -0.03714419901371002,
      0.06086638569831848,
      0.034434299916028976,
      0.007191776297986507,
      0.04249288886785507,
      0.025694264099001884,
      0.0816224068403244,
      -0.06733055412769318,
      -0.0024345996789634228,
      -0.12036451697349548,
      -0.08376732468605042,
      -0.0638873502612114,
      0.006409588269889355,
      0.013782045803964138,
      0.034383904188871384,
      0.0005988308694213629,
      0.07243756949901581,
      0.005174079444259405,
      -0.013474151492118835,
      0.04214866831898689,
      -0.016985757276415825,
      0.05034567043185234,
      -0.04967004433274269,
      0.035355281084775925,
      -0.01350350771099329,
      -0.0108669213950634,
      -0.040936242789030075,
      0.019379684701561928,
      0.014112981967628002,
      -0.005803799722343683,
      0.03890988603234291,
      -0.012997489422559738,
      0.03626493737101555,
      0.0253586508333683,
      0.09425539523363113,
      -0.03546596318483353,
      0.05841187387704849,
      0.023046307265758514,
      -0.016586720943450928,
      0.03429123014211655,
      -0.044683828949928284,
      0.05991511046886444,
      0.048137132078409195,
      -0.009826213121414185,
      -0.03169970586895943,
      -0.057650044560432434,
      0.008005308918654919,
      -0.053066354244947433,
      -0.008942820131778717,
      -0.01766773872077465,
      0.03193557634949684,
      0.002506221178919077,
      0.027806123718619347,
      0.05231381952762604,
      0.03577902168035507,
      -0.01260764803737402,
      -0.03136903792619705,
      0.011475970968604088,
      -0.08613824099302292,
      -0.04313557222485542,
      -0.03486883267760277,
      -0.02666689082980156,
      0.07029172033071518,
      0.05289411544799805,
      0.019671378657221794,
      0.015551302582025528,
      0.04930740222334862,
      0.08489906787872314,
      0.07700267434120178,
      0.06294053792953491,
      0.03584430366754532,
      0.05615529417991638,
      -0.00822620652616024,
      -0.05122384428977966,
      0.01104277465492487,
      0.0640575960278511,
      -0.012837739661335945,
      0.1181916818022728,
      0.006265245843678713,
      0.05950160324573517,
      0.05356253683567047,
      0.09016503393650055,
      -0.08226372301578522,
      0.029017619788646698,
      0.07449570298194885,
      -0.0015590194379910827,
      -0.002473660511896014,
      -0.0525793582201004,
      -0.0013923656661063433,
      0.05792766436934471,
      -0.022194989025592804,
      0.04040843993425369,
      -0.05393426492810249,
      0.0017805432435125113,
      -0.023207364603877068,
      -0.025680582970380783,
      -0.04681827500462532,
      -0.023390432819724083,
      0.001278071547858417,
      -0.035642433911561966,
      -0.13217584788799286,
      -0.01432472001761198,
      -0.020625712350010872,
      0.06283211708068848,
      -0.022695189341902733,
      -0.018212316557765007,
      0.019720396026968956,
      0.032365582883358,
      -0.004725273232907057,
      -0.06349595636129379,
      -0.05955904349684715,
      0.03571972995996475,
      0.004402870312333107,
      -0.05203930661082268,
      -0.04096537455916405,
      -0.03218371421098709,
      0.030241364613175392,
      0.02860109694302082,
      0.01505961176007986,
      0.05743730440735817,
      -0.0069763874635100365,
      -0.006638986524194479,
      0.058604851365089417,
      0.001627515535801649,
      0.011207533068954945,
      0.001564152305945754,
      0.042762186378240585,
      0.014781183563172817,
      -0.031934820115566254,
      -0.02987081930041313,
      -0.03096732124686241,
      -0.010614380240440369,
      -0.00866084173321724,
      0.035335712134838104,
      0.03578360751271248,
      -0.008834747597575188,
      0.060492247343063354,
      -0.029486743733286858,
      -0.05650778114795685,
      0.060276854783296585,
      0.019902322441339493,
      0.012101545929908752,
      -0.0329020731151104,
      0.06092289462685585,
      0.04642826318740845,
      -0.007156507112085819,
      0.0015429670456796885,
      0.07635585963726044,
      -0.07063540071249008,
      0.10354617238044739,
      0.008644811809062958,
      0.04557576775550842,
      0.0015810892218723893,
      0.07098416239023209,
      0.004324797540903091,
      0.09284050762653351,
      -0.03456921502947807,
      0.0016674199141561985,
      -0.12485798448324203,
      0.06498879194259644,
      0.0854012668132782,
      -0.043417222797870636,
      0.0633234977722168,
      0.027506640180945396,
      0.020437030121684074,
      0.04001971334218979,
      -0.04323277994990349,
      -0.01743258908390999,
      0.016775766387581825,
      -0.0524311289191246,
      0.015409788116812706,
      0.049812041223049164,
      -0.0261473897844553,
      0.010879884473979473,
      0.0430411621928215,
      -0.005367104429751635,
      0.09403370320796967,
      -0.02465914376080036,
      0.006282988470047712,
      -0.010620305314660072,
      0.09990823268890381,
      -0.022269994020462036,
      -0.03552785888314247,
      0.0632624477148056,
      0.04176004230976105,
      -0.03844638168811798,
      -0.02987360954284668,
      -0.048799414187669754,
      0.03548095375299454,
      -0.04188041761517525,
      0.0008004848496057093,
      0.00024902448058128357
    ],
    [
      0.03908836096525192,
      -0.010016379877924919,
      -0.021493151783943176,
      -0.0029646444600075483,
      0.01407531462609768,
      -0.04612308368086815,
      -0.07161536812782288,
      0.08196666836738586,
      -0.03471306711435318,
      0.0674830824136734,
      -0.027670469135046005,
      -0.021487154066562653,
      -0.050771668553352356,
      -0.08593624830245972,
      -0.0036950609646737576,
      0.03626629337668419,
      -0.04743517190217972,
      0.0056054843589663506,
      -0.07352086156606674,
      -0.042200781404972076,
      0.033755477517843246,
      -0.06128823757171631,
      0.06148400530219078,
      0.04215610772371292,
      -0.009035668335855007,
      -0.0055594127625226974,
      0.038226787000894547,
      -0.05615745857357979,
      -0.05536499619483948,
      -0.05408065766096115,
      -0.057612620294094086,
      0.03889165818691254,
      -0.02250554785132408,
      -0.055496785789728165,
      -0.052667874842882156,
      -0.04272232949733734,
      0.03610241040587425,
      0.05497916042804718,
      -0.022340301424264908,
      -0.004322662949562073,
      -0.005305958911776543,
      -0.018748141825199127,
      0.0186071265488863,
      -0.041034530848264694,
      0.01593363657593727,
      -0.031908150762319565,
      0.022414622828364372,
      -0.026438139379024506,
      0.012711729854345322,
      0.06664055585861206,
      -0.0020510992035269737,
      0.04699421674013138,
      -0.0008623433532193303,
      -0.03858891502022743,
      0.01337341871112585,
      0.04758448898792267,
      -0.012844731099903584,
      -0.010287764482200146,
      0.002746888203546405,
      0.015533401630818844,
      0.012435614131391048,
      0.11334306746721268,
      -0.06401292979717255,
      0.033747781068086624,
      0.04432780668139458,
      -0.026019485667347908,
      -0.01763134077191353,
      -0.03930295631289482,
      -0.06481124460697174,
      -0.03865579515695572,
      -0.003408009186387062,
      0.003151132958009839,
      0.040592871606349945,
      0.04910535737872124,
      -0.010781529359519482,
      0.04402565956115723,
      0.0302133746445179,
      0.049862366169691086,
      -0.015488916076719761,
      0.15765643119812012,
      -0.03139757364988327,
      -0.05138322338461876,
      -0.017720891162753105,
      0.0869123786687851,
      0.08404015749692917,
      0.022933311760425568,
      0.016467450186610222,
      0.007624783553183079,
      0.08895464241504669,
      -0.02087702415883541,
      -0.005953550338745117,
      0.09257042407989502,
      0.004590754397213459,
      -0.0873115286231041,
      -0.09068300575017929,
      -0.07433797419071198,
      -0.031329166144132614,
      0.04866351932287216,
      -0.04278614744544029,
      0.004141723737120628,
      -0.025947559624910355,
      -0.017872199416160583,
      -0.003208546433597803,
      -0.06415989249944687,
      0.00806658249348402,
      0.01435223501175642,
      0.04520457983016968,
      0.005292006768286228,
      -0.024297932162880898,
      0.04465791583061218,
      0.07796920835971832,
      -0.12049510329961777,
      -0.012960960157215595,
      0.009004490450024605,
      -0.0644882321357727,
      -0.040686000138521194,
      0.03000696189701557,
      -0.017389846965670586,
      -0.05117715522646904,
      -0.04353826865553856,
      -0.09548348933458328,
      0.025725295767188072,
      -0.02394529990851879,
      0.013303437270224094,
      0.06685313582420349,
      -0.013341003097593784,
      -0.027543624863028526,
      -0.02309158816933632,
      -0.028007542714476585,
      0.04486025124788284,
      0.05464261770248413,
      -0.022298946976661682,
      0.023317936807870865,
      -0.021167317405343056,
      0.06340646743774414,
      -0.06571602821350098,
      0.0576903373003006,
      0.003215709701180458,
      -0.04011724516749382,
      -0.018603559583425522,
      0.013086514547467232,
      -0.008316777646541595,
      -0.015830883756279945,
      0.0725354254245758,
      0.0024043661542236805,
      0.03564385697245598,
      -0.13862532377243042,
      0.07924462109804153,
      -0.018136916682124138,
      0.0066812410950660706,
      0.0578119270503521,
      0.03472953662276268,
      0.02711433358490467,
      -0.03164052218198776,
      0.0029754277784377337,
      -0.003910209983587265,
      -0.009593852795660496,
      0.059195321053266525,
      0.0076057640835642815,
      0.0024419028777629137,
      -0.028787339106202126,
      0.032214585691690445,
      -0.04278518259525299,
      -0.005785308312624693,
      -0.01058120559900999,
      0.014733131043612957,
      -0.018336407840251923,
      0.014515770599246025,
      0.026790615171194077,
      -0.028923556208610535,
      0.03663581237196922,
      0.008878975175321102,
      -0.07272195816040039,
      0.034215088933706284,
      -0.06056009605526924,
      0.0673176720738411,
      0.0021885184105485678,
      0.028062663972377777,
      0.03657107800245285,
      -0.031179329380393028,
      0.02060735411942005,
      0.02669290080666542,
      0.066982202231884,
      -0.00023809737467672676,
      0.013496758416295052,
      -0.10416369140148163,
      0.015925517305731773,
      0.004111761227250099,
      0.03834950551390648,
      -0.02028539590537548,
      0.024833327159285545,
      -0.018582582473754883,
      -0.034716926515102386,
      0.01928408443927765,
      0.053444284945726395,
      0.014893976040184498,
      0.06646183133125305,
      -0.015635203570127487,
      0.03596169501543045,
      -0.018182285130023956,
      -0.0605132095515728,
      -0.07320965826511383,
      0.006800238508731127,
      0.016236187890172005,
      -0.03558121249079704,
      0.07027465850114822,
      -0.029738781973719597,
      -0.04908197373151779,
      -0.015638019889593124,
      -0.013871744275093079,
      -0.0792396292090416,
      0.01718325912952423,
      -0.0072892215102910995,
      -0.04179397225379944,
      0.009104657918214798,
      -0.030656352639198303,
      -0.019478876143693924,
      -0.020077776163816452,
      0.027930844575166702,
      -0.026977522298693657,
      -0.033062104135751724,
      -0.010001575574278831,
      0.03956227004528046,
      -0.07518555968999863,
      0.05512240529060364,
      0.008940598927438259,
      -0.006591388490051031,
      0.029076077044010162,
      -0.0158819742500782,
      0.0008681134786456823,
      -0.0907803401350975,
      0.10865528136491776,
      -0.019338926300406456,
      0.022866223007440567,
      -0.013149191625416279,
      0.03300826624035835,
      0.03387710079550743,
      -0.041637204587459564,
      0.0258451197296381,
      -0.02674732357263565,
      0.09597824513912201,
      0.02868509478867054,
      -0.020560920238494873,
      -0.006604272872209549,
      -0.0013205005088821054,
      0.016746733337640762,
      0.057667460292577744,
      0.11846994608640671,
      -0.04578063637018204,
      0.04525751248002052,
      -0.04095420986413956,
      0.0017976128729060292,
      -0.049096863716840744,
      -0.0011177014093846083,
      0.026132522150874138,
      0.00608709966763854,
      -0.062354207038879395,
      0.0012965935748070478,
      -0.002875063568353653,
      -0.004332865588366985,
      0.00795475859194994,
      0.003477594116702676,
      0.017371023073792458,
      -0.03681646287441254,
      0.0887758880853653,
      0.03396184369921684,
      0.04593756049871445,
      -0.07882557064294815,
      0.07760988175868988,
      0.006917265243828297,
      0.08820684254169464,
      -0.04568832367658615,
      -0.051806576550006866,
      0.03978839889168739,
      0.020555218681693077,
      0.03707752376794815,
      0.011470642872154713,
      -0.03804335370659828,
      0.042984917759895325,
      0.049939583986997604,
      0.05746890977025032,
      -0.03583235293626785,
      0.037825435400009155,
      0.025706782937049866,
      0.07528422027826309,
      -0.015701185911893845,
      -0.005456215236335993,
      0.007734821643680334,
      0.05589701235294342,
      -0.07115238904953003,
      0.0031826943159103394,
      -0.04509104788303375,
      -0.04112256318330765,
      -0.004145170096307993,
      0.012248806655406952,
      -0.06254252791404724,
      -0.005763660650700331,
      -0.00828411616384983,
      0.025598783046007156,
      0.007633429951965809,
      0.032241228967905045,
      0.015547169372439384,
      0.023387866094708443,
      0.012321178801357746,
      0.054023221135139465,
      -0.017447510734200478,
      -0.03164205327630043,
      0.02907463349401951,
      0.09267312288284302,
      0.06867854297161102,
      -0.021440939977765083,
      -0.05411724001169205,
      0.01892595924437046,
      -0.0833514854311943,
      0.0379212349653244,
      0.020915301516652107,
      0.015416021458804607,
      -0.04148358851671219,
      0.021315069869160652,
      -0.008577813394367695,
      -0.039256345480680466,
      -0.06519161909818649,
      -0.018291566520929337,
      0.0286211259663105,
      0.06278233975172043,
      0.05476165935397148,
      0.0562303401529789,
      -0.005924368742853403,
      -0.07420176267623901,
      -0.0147821931168437,
      -0.018810367211699486,
      -0.04256173223257065,
      0.02705753967165947,
      -0.006500356364995241,
      -0.09423185884952545,
      0.01036136131733656,
      0.0598110668361187,
      0.0468582808971405,
      -0.031149517744779587,
      -0.0025667923036962748,
      0.014400511048734188,
      0.03331756219267845,
      -0.0383872389793396,
      -0.05290641263127327,
      0.03374752402305603,
      0.06845945119857788,
      -0.05294043943285942,
      0.008863860741257668,
      0.06737086921930313,
      -0.024289462715387344,
      0.027079422026872635,
      -0.07183922082185745,
      0.007221754174679518,
      -0.017926635220646858,
      0.002818421693518758,
      -0.005214522127062082,
      -0.0021372507326304913,
      0.052918605506420135,
      0.04588489606976509,
      0.020763393491506577,
      -0.012399577535688877,
      -0.047667793929576874,
      -0.06884408742189407,
      -0.012368247844278812,
      0.007404722739011049,
      -0.0056077223271131516,
      -0.019566822797060013,
      0.04630422964692116,
      0.02786307781934738,
      -0.049690112471580505,
      -0.045710328966379166,
      0.046147819608449936,
      -0.0027152623515576124,
      0.020602228119969368,
      0.007759306579828262,
      0.03099881112575531,
      0.07089553028345108,
      -0.020688001066446304,
      -0.044550180435180664,
      -0.0006306346622295678,
      -0.0785951241850853,
      -0.0417771115899086,
      0.07901424169540405,
      0.0005494068027473986,
      -0.0007912929868325591,
      0.011942358687520027,
      -0.03599227964878082,
      0.022610560059547424,
      -0.15078876912593842,
      0.024795368313789368,
      -0.0013131542364135385,
      0.007156741805374622,
      -0.006507209502160549,
      0.017048737034201622,
      -0.023130124434828758,
      0.040925972163677216,
      -0.04118615388870239,
      0.04093049839138985,
      0.00715612294152379,
      0.0519149973988533,
      -0.04301289841532707,
      -0.0692494660615921,
      -0.00800966378301382,
      0.03965394198894501,
      0.04318629577755928,
      -0.07960528880357742,
      -0.05374009534716606,
      -0.00020644049800466746,
      -0.05476752296090126,
      0.09684957563877106,
      0.08014145493507385,
      0.024099254980683327,
      0.06084439158439636,
      -0.03771759942173958,
      -0.021346572786569595,
      -0.03156132251024246,
      -0.019398504868149757,
      -0.019768157973885536,
      0.0014677157159894705,
      0.03655560687184334,
      -0.0328894667327404,
      -0.03434145078063011,
      0.04005428031086922,
      -0.015294114127755165,
      -0.01793491467833519,
      0.04993152990937233,
      0.050180695950984955,
      0.05878971889615059,
      0.0020552214700728655,
      -0.04222574084997177,
      -0.04789784178137779,
      -0.014299645088613033,
      0.11182283610105515,
      0.07755880802869797,
      0.035727255046367645,
      -0.004260513000190258,
      0.020133143290877342,
      -0.03974864259362221,
      -0.059692177921533585,
      -0.02747570350766182,
      -0.018955904990434647,
      -0.08002407848834991,
      0.05062738433480263,
      0.07363926619291306,
      0.08328203856945038,
      0.02676486037671566,
      0.012487262487411499,
      -0.01679115556180477,
      0.03829656168818474,
      -0.0002948258479591459,
      -0.0028984500095248222,
      -0.05461757257580757,
      -0.02950073778629303,
      0.04697646573185921,
      -0.037897948175668716,
      -0.013471287675201893,
      -0.001308220555074513,
      -0.06164638325572014,
      0.05049613490700722,
      0.017544467002153397,
      0.011997932568192482,
      0.006223703734576702,
      0.026014963164925575,
      -0.00749266566708684,
      0.014236506074666977,
      0.006957749370485544,
      -0.011624542064964771,
      -0.0704488605260849,
      -0.008154420182108879,
      0.005969041492789984,
      -0.0278879776597023,
      -0.0243049506098032,
      0.058877233415842056,
      0.04405973106622696,
      -0.008290791884064674,
      -1.4608389165005065e-06,
      -0.01721460558474064,
      0.006487274542450905,
      -0.008052465505897999,
      0.07476001977920532,
      0.009641439653933048,
      0.04359649121761322,
      0.009166513569653034,
      0.02096799574792385,
      -0.0461459718644619,
      -0.05526462942361832,
      -0.039057523012161255,
      0.07230238616466522,
      0.0032612085342407227,
      0.004699935205280781,
      -0.06076611578464508,
      -0.0004936091718263924,
      -0.02299468219280243,
      -0.03510328009724617,
      -0.05840904638171196,
      0.06806479394435883,
      -0.050353843718767166,
      0.008316915482282639,
      0.017102286219596863,
      0.006024273112416267,
      -0.01833849772810936,
      0.023746123537421227,
      0.012951314449310303
    ],
    [
      0.008070954121649265,
      0.017045563086867332,
      0.05497269332408905,
      -0.0282130129635334,
      0.049573492258787155,
      0.09662670642137527,
      0.003233532188460231,
      0.0023559690453112125,
      0.004628849681466818,
      -0.053288571536540985,
      0.02604784071445465,
      0.0172963235527277,
      -0.009046956896781921,
      0.04428601264953613,
      0.057674817740917206,
      -0.07160881906747818,
      -0.058590203523635864,
      0.019301485270261765,
      -0.01799849234521389,
      -0.08766226470470428,
      -0.010869541205465794,
      -0.06727597862482071,
      -0.0483994223177433,
      -0.026396360248327255,
      0.011252880096435547,
      0.08280682563781738,
      0.046883512288331985,
      -0.0423150509595871,
      0.0262923464179039,
      0.004388320259749889,
      0.012027867138385773,
      0.007611189968883991,
      -0.018804950639605522,
      0.015458428300917149,
      -0.03414881229400635,
      0.0531616173684597,
      -0.03762234002351761,
      -0.023730004206299782,
      0.08896119892597198,
      0.057417549192905426,
      -0.02666793204843998,
      -0.04943573474884033,
      -0.012614247389137745,
      0.05491989850997925,
      -0.047227054834365845,
      -0.06454113870859146,
      0.06278674304485321,
      -0.009487543255090714,
      0.005740960594266653,
      -0.0607457160949707,
      -0.012826181948184967,
      0.05164658650755882,
      -0.08505939692258835,
      -0.027164075523614883,
      -0.003273695707321167,
      0.04210967198014259,
      0.06893263012170792,
      -0.017589887604117393,
      0.07841631770133972,
      0.04049559310078621,
      -0.07310272008180618,
      0.08201960474252701,
      -0.010527090169489384,
      0.026899313554167747,
      0.024569302797317505,
      0.0481892004609108,
      -0.003013508627191186,
      0.07394352555274963,
      0.02324226126074791,
      -0.040159545838832855,
      0.04051753506064415,
      0.014379899948835373,
      0.03478480875492096,
      0.08871316909790039,
      -0.009494919329881668,
      -0.02719075419008732,
      -0.023918215185403824,
      -0.013748575001955032,
      0.011481168679893017,
      0.050790805369615555,
      0.038274381309747696,
      0.0013714473461732268,
      -0.08883972465991974,
      -0.007831242866814137,
      0.06015395373106003,
      0.025650065392255783,
      -0.020670806989073753,
      -0.03815751150250435,
      -0.003640509909018874,
      0.010936081409454346,
      -0.03302112966775894,
      -0.03652900829911232,
      -0.01303047314286232,
      0.08609918504953384,
      -0.08176111429929733,
      0.014878644607961178,
      0.06589505821466446,
      0.03412333503365517,
      0.17517830431461334,
      -0.037299465388059616,
      -0.014632083475589752,
      0.04453802853822708,
      -0.0018969500670209527,
      -0.029234349727630615,
      0.023816248401999474,
      -0.006770345848053694,
      0.0006757507217116654,
      -0.08346300572156906,
      0.022778719663619995,
      -0.04433588311076164,
      0.08138705790042877,
      0.008601843379437923,
      0.0024276215117424726,
      0.0334467887878418,
      -0.02966461330652237,
      -0.05325274169445038,
      0.006012758705765009,
      -0.04517052322626114,
      0.0894107073545456,
      -0.002704026410356164,
      -0.13852162659168243,
      -0.013417765498161316,
      0.021546242758631706,
      0.052492327988147736,
      -0.01858568750321865,
      0.09043898433446884,
      -0.03413192555308342,
      -0.023176558315753937,
      -0.0500650629401207,
      0.12220298498868942,
      0.002160794800147414,
      -0.05199449509382248,
      0.0512627474963665,
      -0.019887294620275497,
      0.005489746108651161,
      -0.01875615306198597,
      0.08140453696250916,
      0.015610219910740852,
      0.020974164828658104,
      0.03211979195475578,
      -0.023022441193461418,
      0.01379451248794794,
      -0.0031511024571955204,
      -0.009971405379474163,
      0.06786687672138214,
      -0.061867617070674896,
      0.03227166458964348,
      0.07154041528701782,
      -0.005781588610261679,
      -0.02087981626391411,
      -0.019697042182087898,
      -0.008965383283793926,
      -0.035878073424100876,
      0.0368829183280468,
      0.00938696414232254,
      -0.024725720286369324,
      -0.026985282078385353,
      0.009718750603497028,
      0.016057543456554413,
      0.015644092112779617,
      0.04706238582730293,
      -0.009134429506957531,
      0.00045581479207612574,
      -0.007266403175890446,
      0.004706922452896833,
      0.053380854427814484,
      -0.01125890389084816,
      -0.0038206882309168577,
      0.00252268323674798,
      -0.06440761685371399,
      0.0445282943546772,
      0.024867014959454536,
      -0.06443937867879868,
      0.012995103374123573,
      -0.006738781929016113,
      -0.053117070347070694,
      -0.05249408259987831,
      -0.017720824107527733,
      0.03185798600316048,
      -0.004442843142896891,
      0.045759618282318115,
      0.03472018614411354,
      0.0004400983452796936,
      0.02029632404446602,
      0.042774684727191925,
      -0.006263099145144224,
      0.06571876257658005,
      -0.025882339105010033,
      -0.022076888009905815,
      -0.06614695489406586,
      0.00016192242037504911,
      0.02880215272307396,
      -0.04654980078339577,
      0.01994413509964943,
      0.0011805107351392508,
      -0.0849216878414154,
      -0.08492837846279144,
      -0.004706298001110554,
      0.06494006514549255,
      -0.023547744378447533,
      -0.011973937042057514,
      0.032127171754837036,
      -0.03402946516871452,
      0.010991114191710949,
      0.03849877044558525,
      -0.011825690977275372,
      0.07687202095985413,
      -0.06568215787410736,
      -0.014658388681709766,
      -0.005678982939571142,
      -0.07848859578371048,
      0.029847562313079834,
      -0.03833135962486267,
      -0.01504217367619276,
      0.010695229284465313,
      0.007880604825913906,
      -0.0027516272384673357,
      -0.003309941617771983,
      -0.062056612223386765,
      -0.06451243907213211,
      0.006269212346524,
      -0.0015422559808939695,
      -0.018831325694918633,
      -0.04356043040752411,
      0.06748590618371964,
      -0.0220944806933403,
      -0.027208002284169197,
      -0.00018152523261960596,
      0.025061961263418198,
      0.005526276305317879,
      -0.03990210220217705,
      -0.059936199337244034,
      0.023640474304556847,
      0.05924548581242561,
      0.06490392237901688,
      0.023871948942542076,
      0.028941985219717026,
      0.009876066818833351,
      0.06619266420602798,
      -0.01967385597527027,
      -0.03171081095933914,
      0.05818146467208862,
      0.022401725873351097,
      -0.0033632349222898483,
      0.06636175513267517,
      0.04970201104879379,
      0.06422353535890579,
      0.062293361872434616,
      0.040728166699409485,
      -0.040844839066267014,
      0.04020899906754494,
      0.05986675247550011,
      0.024763381108641624,
      0.08702564984560013,
      0.07124621421098709,
      0.013898075558245182,
      -0.0384046770632267,
      0.018464332446455956,
      0.01590479165315628,
      0.029432306066155434,
      0.016470691189169884,
      -0.02596934698522091,
      0.0051003312692046165,
      0.03613142669200897,
      0.056422460824251175,
      0.013448012992739677,
      0.026382891461253166,
      -0.03260747343301773,
      -0.01063908264040947,
      0.00839078426361084,
      -0.09564794600009918,
      -0.03364495560526848,
      -0.09198988974094391,
      0.017005469650030136,
      -0.005001595243811607,
      -0.02924090251326561,
      -0.035974133759737015,
      0.011126472614705563,
      -0.04487975314259529,
      0.12250148504972458,
      0.07784782350063324,
      -0.04327569901943207,
      -0.0185549259185791,
      0.01465358305722475,
      0.023370962589979172,
      -0.04909698665142059,
      0.05674206092953682,
      -0.04016648977994919,
      -0.03830784931778908,
      -0.11743367463350296,
      0.01804054155945778,
      -0.010921919718384743,
      0.025851167738437653,
      0.09704304486513138,
      -0.03266727924346924,
      -0.07946591824293137,
      0.031342510133981705,
      0.018439725041389465,
      -0.035319939255714417,
      -0.020546499639749527,
      -0.0934128537774086,
      0.04442586377263069,
      0.0546039342880249,
      0.0641988068819046,
      0.06153422221541405,
      0.058610934764146805,
      -0.02718915417790413,
      -0.00593072734773159,
      0.01371018122881651,
      0.004611731506884098,
      -0.07516341656446457,
      -0.08057185262441635,
      -0.002054325072094798,
      -0.025673270225524902,
      0.06474475562572479,
      0.024429963901638985,
      0.018628980964422226,
      -0.0748714879155159,
      -0.010367526672780514,
      -0.058812085539102554,
      -0.017877960577607155,
      -0.06985095888376236,
      0.117435522377491,
      -0.07143405824899673,
      0.0053564743138849735,
      0.11434734612703323,
      0.05176977813243866,
      -0.015540127642452717,
      0.03760959580540657,
      -0.009459619410336018,
      0.03399832174181938,
      0.026772914454340935,
      -0.015946760773658752,
      -0.021839560940861702,
      0.004756802227348089,
      -0.07663081586360931,
      -0.0004964616964571178,
      0.014185817912220955,
      -0.09334470331668854,
      0.017571864649653435,
      -0.005537303164601326,
      -0.09949608892202377,
      0.02091853879392147,
      -0.026308560743927956,
      -0.012605932541191578,
      0.04016362875699997,
      0.10553542524576187,
      0.004606207367032766,
      0.06356523185968399,
      0.09388848394155502,
      -0.009510538540780544,
      -0.009605597704648972,
      -0.012751986272633076,
      0.023539593443274498,
      0.03675803169608116,
      -0.08376116305589676,
      0.03597424924373627,
      0.015242836438119411,
      -0.023063797503709793,
      -0.06227050721645355,
      -0.06940262764692307,
      -0.02683417871594429,
      0.03115810640156269,
      -0.04478298872709274,
      0.018141627311706543,
      0.008797884918749332,
      0.06912318617105484,
      -0.08296281099319458,
      0.027329253032803535,
      -0.04469361528754234,
      0.07270582020282745,
      0.01732298545539379,
      0.024150094017386436,
      0.06118431314826012,
      0.004116423428058624,
      0.058912575244903564,
      0.003603296121582389,
      0.08657553791999817,
      -0.07158143073320389,
      0.0222038421779871,
      0.0376206673681736,
      0.0300903283059597,
      -0.05355280637741089,
      0.0842934176325798,
      -0.020871799439191818,
      0.04762939363718033,
      -0.0063997358083724976,
      0.06977417320013046,
      0.008634362369775772,
      -0.01614285446703434,
      0.013130616396665573,
      0.008832372725009918,
      -0.08312623202800751,
      0.029961397871375084,
      -0.0029272218234837055,
      0.04865669831633568,
      -0.027567731216549873,
      0.04445650428533554,
      -0.03218619152903557,
      -0.008696689270436764,
      0.029073528945446014,
      -0.011783108115196228,
      0.016023240983486176,
      -0.026361780241131783,
      0.015036706812679768,
      -0.02434718981385231,
      0.024992497637867928,
      -0.04975041374564171,
      -0.015625808387994766,
      -0.07777759432792664,
      0.022027652710676193,
      -0.007294843904674053,
      -0.0053650992922484875,
      -0.048891693353652954,
      -0.011799116618931293,
      0.028516624122858047,
      0.0030713325832039118,
      0.015020116232335567,
      -0.012047301046550274,
      0.043628379702568054,
      0.1125263050198555,
      0.07289938628673553,
      0.007821076549589634,
      0.0033156629651784897,
      0.0489749014377594,
      0.0376710370182991,
      0.08649184554815292,
      -0.02920561656355858,
      0.030274730175733566,
      -0.0005949659389443696,
      0.004310585558414459,
      0.027848288416862488,
      0.050419699400663376,
      0.03072190284729004,
      -0.016768356785178185,
      0.05569542571902275,
      0.03205006197094917,
      0.01299277227371931,
      -0.007368669845163822,
      -0.03376663476228714,
      0.07547006756067276,
      -0.01944633200764656,
      0.04097694531083107,
      0.011440180242061615,
      0.05099335312843323,
      0.007721190806478262,
      -0.060224875807762146,
      -0.009179367683827877,
      0.01921328902244568,
      -0.021464811637997627,
      -0.012699226848781109,
      -0.0885392352938652,
      0.041680388152599335,
      -0.04673805087804794,
      -0.03906610235571861,
      -0.04136916995048523,
      -0.04345563426613808,
      0.04108674079179764,
      -0.01736057735979557,
      0.02269476279616356,
      0.050044238567352295,
      0.05468455329537392,
      0.039784930646419525,
      0.08460986614227295,
      0.0743311420083046,
      -0.01972431130707264,
      -0.029880793765187263,
      0.016505064442753792,
      -0.034369900822639465,
      -0.014669938012957573,
      0.0017976077506318688,
      0.041631054133176804,
      0.06875168532133102,
      -0.04516959935426712,
      0.01409333199262619,
      -0.01821223832666874,
      -0.032671861350536346,
      0.00442509213462472,
      0.009873622097074986,
      -0.043067097663879395,
      0.04627252742648125,
      -0.06848499923944473,
      -0.020920034497976303,
      0.06985297799110413,
      0.0105101577937603,
      -0.006582675501704216,
      -0.05685916915535927,
      -0.0212248582392931,
      -0.026073120534420013,
      0.009892300702631474,
      0.06349452584981918,
      -0.008580111898481846,
      0.04527530446648598,
      -0.007131080608814955,
      0.0022372868843376637,
      -0.02447112649679184,
      -0.07723343372344971,
      0.07470402866601944,
      0.005615349393337965,
      -0.012184107676148415,
      0.01690070517361164,
      -0.08353307843208313,
      -0.032019976526498795
    ],
    [
      -0.08444717526435852,
      -0.03381428122520447,
      -0.043985165655612946,
      -0.008793698623776436,
      0.04457269608974457,
      -0.06122586876153946,
      -0.06687529385089874,
      0.03593393787741661,
      -0.02272331528365612,
      -0.018079884350299835,
      -0.006496216636151075,
      -0.06807617843151093,
      0.04249567911028862,
      -0.007700735703110695,
      0.04956828057765961,
      0.08630287647247314,
      -0.025688832625746727,
      0.011107288300991058,
      0.05540822446346283,
      -0.01670748181641102,
      0.04997195675969124,
      0.054138049483299255,
      0.04915210232138634,
      0.013835543766617775,
      -0.029265867546200752,
      -0.07039395719766617,
      -0.040868207812309265,
      -0.010150469839572906,
      0.01984403282403946,
      -0.0319293849170208,
      0.0011766409734264016,
      0.012812302447855473,
      -0.004730173386633396,
      -0.004282569512724876,
      -0.04961947724223137,
      -0.05123366415500641,
      -0.03987428545951843,
      -0.017713312059640884,
      0.05097432807087898,
      0.12806503474712372,
      0.03713216260075569,
      0.06511902809143066,
      0.11226852983236313,
      0.03460182994604111,
      -0.13870418071746826,
      0.01382304634898901,
      0.009983539581298828,
      -0.028219157829880714,
      -0.018541911616921425,
      0.018762575462460518,
      0.09838390350341797,
      0.043872982263565063,
      -0.009621392004191875,
      -0.043437015265226364,
      0.036111023277044296,
      0.05269569531083107,
      0.008437265641987324,
      -0.023520804941654205,
      0.08526717871427536,
      0.04953232780098915,
      -0.07670039683580399,
      0.10711350291967392,
      -0.0756458267569542,
      0.0472949743270874,
      0.044406864792108536,
      -0.010915174148976803,
      -0.03531792014837265,
      -0.04488503187894821,
      -0.0175087358802557,
      0.02260325476527214,
      0.02991117164492607,
      0.0047308714129030704,
      -0.08118850737810135,
      -0.0012049282668158412,
      -0.03415004536509514,
      -0.04205230996012688,
      0.04080266132950783,
      0.005033395253121853,
      -0.052838802337646484,
      0.09597219526767731,
      -0.025149846449494362,
      0.00928849820047617,
      0.04297219589352608,
      0.06205001100897789,
      0.005100960377603769,
      0.04053531959652901,
      0.010709728114306927,
      0.006701934617012739,
      0.035624559968709946,
      0.04247545823454857,
      0.005094718188047409,
      0.05792079120874405,
      -0.05911725386977196,
      0.048824895173311234,
      -0.01577313244342804,
      -0.017093989998102188,
      -0.01629946008324623,
      0.04536530002951622,
      0.060913678258657455,
      0.045098256319761276,
      -0.008756554685533047,
      0.005399124696850777,
      -0.02284718118607998,
      -0.0638338252902031,
      0.08496514707803726,
      0.018675804138183594,
      0.005999611224979162,
      -0.083437979221344,
      0.12282628566026688,
      -0.1389494389295578,
      -0.024504847824573517,
      0.0017201981972903013,
      -0.008768969215452671,
      -0.07571889460086823,
      -0.005765177309513092,
      -0.01245646271854639,
      0.07954387366771698,
      -0.04222429171204567,
      -0.05194990709424019,
      0.045849740505218506,
      -0.09657952189445496,
      -0.0481504388153553,
      0.07790175080299377,
      -0.012596707791090012,
      -0.006025598384439945,
      -0.001023025717586279,
      0.014250093139708042,
      0.016196975484490395,
      0.04289599880576134,
      -0.03734448179602623,
      -0.03803849220275879,
      -0.07320932298898697,
      -0.11773078888654709,
      -0.02577088586986065,
      -0.07733236253261566,
      0.03881233185529709,
      -0.04519202187657356,
      0.025135328993201256,
      -0.07332716882228851,
      0.011729536578059196,
      -0.046881236135959625,
      -0.03400158882141113,
      0.03932691365480423,
      -0.009204295463860035,
      -0.019599728286266327,
      -0.050824083387851715,
      0.024662336334586143,
      0.03448057919740677,
      -0.0006471446831710637,
      0.0034102925565093756,
      -0.024460196495056152,
      0.07007303833961487,
      -0.059816326946020126,
      0.10465677827596664,
      0.14102014899253845,
      -0.07803412526845932,
      0.0019456655718386173,
      -0.03466744348406792,
      0.07855132222175598,
      -0.08654805272817612,
      0.07166740298271179,
      -0.0065100654028356075,
      -0.01229630783200264,
      0.03657186031341553,
      0.0682915598154068,
      -0.0147613026201725,
      0.020616043359041214,
      0.05242359638214111,
      -0.015324413776397705,
      -0.004240295849740505,
      -0.10321742296218872,
      -7.93633735156618e-05,
      -0.04216388612985611,
      -0.03724414482712746,
      -0.02633391134440899,
      -0.004988054279237986,
      -0.03307526186108589,
      0.0395788736641407,
      0.004825645126402378,
      -0.05090415105223656,
      0.01407451182603836,
      -0.05297471582889557,
      -0.07555271685123444,
      -0.0380597747862339,
      -0.044394977390766144,
      -0.07548103481531143,
      0.05520737171173096,
      -0.08887019753456116,
      -0.04848792776465416,
      -0.07320061326026917,
      -0.06121494993567467,
      0.004277545027434826,
      -0.09347285330295563,
      -0.027901174500584602,
      0.007353507913649082,
      -0.013941148295998573,
      -0.08473628759384155,
      0.0631132647395134,
      0.016108347102999687,
      -0.1043526902794838,
      -0.05382843688130379,
      0.05082295089960098,
      0.00736619159579277,
      -0.019844690337777138,
      -0.060293830931186676,
      0.08405653387308121,
      -0.07334351539611816,
      0.09802708774805069,
      0.0880456268787384,
      0.015817705541849136,
      -0.02855149656534195,
      0.050396330654621124,
      -0.07314394414424896,
      -0.00435418076813221,
      0.07654941082000732,
      0.044721491634845734,
      0.033879995346069336,
      0.058069922029972076,
      0.03073841892182827,
      0.016773324459791183,
      -0.01917383447289467,
      -0.024992378428578377,
      -0.04930160939693451,
      -0.051664017140865326,
      0.06950782984495163,
      -0.05974256619811058,
      0.08373473584651947,
      0.1229548379778862,
      0.034374870359897614,
      -0.05717611685395241,
      -0.026035234332084656,
      0.04822812229394913,
      -0.08175371587276459,
      -0.07702221721410751,
      0.008406923152506351,
      0.03585705906152725,
      0.03352523595094681,
      0.020630544051527977,
      0.022305482998490334,
      0.0781373679637909,
      0.09092289954423904,
      0.07738306373357773,
      -0.03779066726565361,
      0.0711236223578453,
      -0.06181379407644272,
      -0.020853929221630096,
      -0.04760340601205826,
      0.05218842998147011,
      0.01525211613625288,
      0.0027367076836526394,
      -0.0013006855733692646,
      0.07898291945457458,
      -0.005628560204058886,
      0.0635625571012497,
      0.1449543535709381,
      0.03837209567427635,
      0.007909167557954788,
      0.02719651162624359,
      0.024760499596595764,
      0.010170288383960724,
      -0.009261648170650005,
      -0.019192200154066086,
      -0.0671968087553978,
      -0.01729952171444893,
      -0.01583050936460495,
      -0.0038695624098181725,
      -0.0340532623231411,
      -0.013304959051311016,
      -0.0262722410261631,
      -0.028286153450608253,
      -0.032941192388534546,
      -0.04003925248980522,
      -0.033812735229730606,
      0.022339381277561188,
      -0.07214910537004471,
      -0.05958763509988785,
      -0.050581932067871094,
      -0.0664171501994133,
      -0.02766171284019947,
      -0.01467724796384573,
      0.01401356142014265,
      -0.027579672634601593,
      0.016008572652935982,
      0.02651883475482464,
      0.010476729832589626,
      -0.062193285673856735,
      -0.019472232088446617,
      -0.08908948302268982,
      -0.03341685235500336,
      0.0037476716097444296,
      -0.007299061398953199,
      0.04465843364596367,
      -0.04131769388914108,
      -0.03010641783475876,
      -0.010657226666808128,
      -0.06637254357337952,
      -0.04917217046022415,
      0.07926851511001587,
      -0.015811854973435402,
      -0.025268370285630226,
      0.013110823929309845,
      0.040620483458042145,
      0.020040886476635933,
      0.025574011728167534,
      0.03879978135228157,
      -0.025129497051239014,
      0.01731749437749386,
      -0.06493138521909714,
      0.059448808431625366,
      0.08253518491983414,
      -0.1135585829615593,
      0.015279538929462433,
      0.03574875369668007,
      -0.01779935695230961,
      0.026325030252337456,
      -0.043768953531980515,
      0.0795719176530838,
      -0.040673065930604935,
      -0.02538076974451542,
      0.006753165274858475,
      -0.014577213674783707,
      0.08239148557186127,
      -0.06518060714006424,
      0.04953952133655548,
      0.03396349772810936,
      -0.014567249454557896,
      0.03416178375482559,
      0.06607027351856232,
      0.08004175126552582,
      0.04226825758814812,
      -0.033999163657426834,
      -0.031039105728268623,
      0.014773800037801266,
      -0.009481646120548248,
      -0.02630964294075966,
      0.030889034271240234,
      -0.062275901436805725,
      -0.025851331651210785,
      0.0027703377418220043,
      0.08726997673511505,
      -0.0241510309278965,
      0.04498278722167015,
      0.0013032075949013233,
      -0.010871213860809803,
      -0.042226266115903854,
      -0.021234281361103058,
      -0.011371436528861523,
      -0.023094208911061287,
      -0.0641469955444336,
      0.06379707902669907,
      -0.030106818303465843,
      -0.005418495740741491,
      0.015926498919725418,
      -0.08688732236623764,
      0.04699767008423805,
      -0.013455926440656185,
      -3.485700290184468e-05,
      -0.04871669411659241,
      -0.015998030081391335,
      -0.09345334768295288,
      -0.054985228925943375,
      -0.06464002281427383,
      -0.0037487174849957228,
      0.03243985399603844,
      -0.04492194950580597,
      0.06685422360897064,
      0.0021656116005033255,
      -0.049067724496126175,
      0.010876844637095928,
      -0.003903336590155959,
      0.04960619658231735,
      0.09454626590013504,
      0.05993762984871864,
      -0.019382664933800697,
      0.08050449937582016,
      0.07287684828042984,
      0.01958097144961357,
      -0.03912447765469551,
      0.0002078865800285712,
      0.014471237547695637,
      0.019099975004792213,
      -0.010060618631541729,
      -0.024576950818300247,
      0.04353029653429985,
      -0.015403023920953274,
      0.05626825988292694,
      0.02034868113696575,
      -0.007761908229440451,
      -0.06567732244729996,
      0.05272302031517029,
      -0.00353297614492476,
      0.029689686372876167,
      0.04408539459109306,
      0.016195861622691154,
      -0.005804131273180246,
      0.01309333834797144,
      -0.04250475391745567,
      0.07024211436510086,
      0.02133357711136341,
      -0.0937059298157692,
      -0.006361002568155527,
      -0.0034034375566989183,
      0.08457501232624054,
      -0.045778244733810425,
      -0.05704648792743683,
      -0.0657423883676529,
      -0.02251090668141842,
      0.004866742994636297,
      0.05486002564430237,
      0.01423532422631979,
      -0.012428504414856434,
      0.04127013683319092,
      -0.006383269093930721,
      0.004885455127805471,
      -0.01438012346625328,
      -0.03230127692222595,
      -0.07792288810014725,
      -0.004695045296102762,
      0.025473181158304214,
      -0.034165121614933014,
      0.053669318556785583,
      0.03682814538478851,
      0.026786351576447487,
      0.03259933739900589,
      -0.011639252305030823,
      -0.03001422993838787,
      0.036230675876140594,
      -0.003313991706818342,
      0.04171266406774521,
      -0.03803567588329315,
      0.04811357334256172,
      -0.041070934385061264,
      0.02930043637752533,
      0.1002526804804802,
      0.009278682991862297,
      0.05814983695745468,
      -0.0032343887723982334,
      -0.05658977851271629,
      -0.005278842989355326,
      0.03573109582066536,
      -0.01649092137813568,
      0.018082566559314728,
      -0.013269001618027687,
      0.011770992539823055,
      -0.02314353734254837,
      -0.061877526342868805,
      -0.04378959536552429,
      0.03402159735560417,
      0.04753584414720535,
      -0.00024240351922344416,
      0.08745737373828888,
      0.035883985459804535,
      -0.021656356751918793,
      0.0618738979101181,
      0.03895464539527893,
      0.048421457409858704,
      -0.05713701993227005,
      -0.020501406863331795,
      -0.04291838780045509,
      -0.025053661316633224,
      0.03251687064766884,
      0.05634141340851784,
      -0.01818760298192501,
      0.06717803329229355,
      0.027552861720323563,
      0.002551184268668294,
      -0.010263491421937943,
      0.018704039976000786,
      -0.06422466039657593,
      -0.043535925447940826,
      0.016603171825408936,
      -0.027767950668931007,
      0.008194761350750923,
      0.003511575283482671,
      -0.050981100648641586,
      0.11999718099832535,
      -0.022260405123233795,
      0.000937380304094404,
      0.027958814054727554,
      0.010763285681605339,
      0.029276961460709572,
      0.008969806134700775,
      0.02882477268576622,
      0.07710886746644974,
      0.003885844722390175,
      0.035988807678222656,
      0.028934847563505173,
      0.014627890661358833,
      0.04135291650891304,
      0.028961317613720894,
      0.01689334213733673,
      -0.018606211990118027,
      -0.05453468859195709,
      -0.08136449754238129,
      -0.06837181001901627,
      -0.04844081774353981,
      0.008941982872784138,
      -0.024968823418021202,
      0.06774763762950897,
      0.0933859571814537,
      -0.01962037943303585,
      -0.02354257181286812,
      -0.03824827820062637
    ],
    [
      0.008007909171283245,
      -0.02227780967950821,
      0.06828136742115021,
      -0.017512284219264984,
      -0.06991283595561981,
      0.010295084677636623,
      -0.1572742909193039,
      0.06986015290021896,
      -0.02894304320216179,
      -0.019353270530700684,
      0.031959813088178635,
      -0.043528880923986435,
      0.05878866836428642,
      -0.042237866669893265,
      -0.04816871136426926,
      -0.013256099075078964,
      0.03509338200092316,
      0.028578680008649826,
      -0.043807923793792725,
      -0.053487379103899,
      -0.002537151798605919,
      0.0034231338649988174,
      0.031247010454535484,
      0.0167828556150198,
      0.05180572718381882,
      0.041381917893886566,
      0.015162301249802113,
      0.018867962062358856,
      -0.07066041231155396,
      0.03792900592088699,
      0.007382518611848354,
      0.006851566955447197,
      0.06609581410884857,
      -0.006345292087644339,
      0.025225410237908363,
      0.04525865241885185,
      -0.028403161093592644,
      0.009812011383473873,
      0.032392263412475586,
      -0.01253441907465458,
      -0.05704119801521301,
      0.029752686619758606,
      0.11716733127832413,
      0.03367997333407402,
      -0.012037972919642925,
      -0.0344059132039547,
      0.010863864794373512,
      0.06519673764705658,
      0.10056468844413757,
      0.03630782291293144,
      -0.033092062920331955,
      -0.012159080244600773,
      0.0007373426342383027,
      0.07832589000463486,
      0.04031655192375183,
      0.02668021246790886,
      0.14057931303977966,
      0.010398773476481438,
      -0.0850415900349617,
      -0.06789196282625198,
      0.027022628113627434,
      0.04225224256515503,
      0.013523072004318237,
      -0.05497739836573601,
      0.03694276884198189,
      0.044481974095106125,
      -0.04676083102822304,
      -0.020846111699938774,
      0.024165138602256775,
      -0.018673798069357872,
      0.012080993503332138,
      0.06993776559829712,
      0.03832182288169861,
      0.09361382573843002,
      0.007954963482916355,
      0.06528123468160629,
      -0.060509584844112396,
      -0.027954785153269768,
      0.03720194101333618,
      0.04342702403664589,
      0.04773789271712303,
      -0.0035271686501801014,
      0.02158602513372898,
      0.006351273041218519,
      0.04578336328268051,
      -0.026614580303430557,
      0.0005938652902841568,
      0.006958101410418749,
      -0.03301246091723442,
      -0.029524626210331917,
      0.004221586510539055,
      -0.021098369732499123,
      -0.058380477130413055,
      0.016125904396176338,
      0.0168602354824543,
      -0.05208900570869446,
      0.007444506045430899,
      -0.026500554755330086,
      0.04532509297132492,
      0.05387882888317108,
      0.003568050917237997,
      0.1052689403295517,
      -0.08787364512681961,
      0.004272405058145523,
      -0.011352294124662876,
      -0.026303397491574287,
      -0.017012037336826324,
      -0.015203254297375679,
      0.10060861706733704,
      -0.046969350427389145,
      0.11765122413635254,
      -0.02988990768790245,
      -0.02242337167263031,
      0.0189106035977602,
      0.02249312959611416,
      -0.02243703044950962,
      0.011714421212673187,
      -0.06558186560869217,
      -0.010381520725786686,
      0.07251843810081482,
      -0.03189614415168762,
      0.03173226863145828,
      0.010700557380914688,
      -0.09687488526105881,
      0.02105211466550827,
      -0.022913796827197075,
      -0.04723147302865982,
      0.030897250398993492,
      0.05677952244877815,
      -0.043819062411785126,
      0.04718780890107155,
      -0.08438017964363098,
      0.01778765209019184,
      0.022968117147684097,
      -0.04593214392662048,
      0.008547048084437847,
      -0.0010727507760748267,
      0.02910180576145649,
      0.012577921152114868,
      -0.024317873641848564,
      -0.06275715678930283,
      -0.050724491477012634,
      0.01530542690306902,
      0.06201949715614319,
      0.0553913377225399,
      0.03442010655999184,
      -0.024685334414243698,
      -0.042972393333911896,
      -0.03719458729028702,
      -0.06259871274232864,
      -0.012438852339982986,
      0.043230824172496796,
      -0.019564399495720863,
      0.05915498360991478,
      0.0063557992689311504,
      0.04111725464463234,
      0.053726594895124435,
      0.022107763215899467,
      -0.08812842518091202,
      0.04616624861955643,
      -0.05802270025014877,
      0.021906403824687004,
      -0.018989723175764084,
      -0.03928711265325546,
      -0.032346148043870926,
      0.05398251861333847,
      -0.006787688005715609,
      -0.06283724308013916,
      -0.01101380679756403,
      0.0190737247467041,
      0.086529940366745,
      -0.07999814301729202,
      0.0023601041175425053,
      -0.0032144570723176003,
      0.03791742026805878,
      0.014157740399241447,
      0.009725607931613922,
      -0.07161173224449158,
      -0.01150141004472971,
      -0.02076241560280323,
      0.0034085011575371027,
      -0.07989131659269333,
      0.08669272810220718,
      -0.004747518338263035,
      0.04922875761985779,
      -0.05137215554714203,
      0.024879345670342445,
      0.05039384961128235,
      -0.02558622881770134,
      -0.01405871007591486,
      0.03523588180541992,
      -0.02723955735564232,
      0.0458517000079155,
      -0.03936253488063812,
      0.06363435834646225,
      -0.03713434934616089,
      -0.024969808757305145,
      0.028437698259949684,
      0.01698094792664051,
      -0.04193124547600746,
      0.03703634813427925,
      0.01334680151194334,
      -0.04552094638347626,
      0.003238719655200839,
      0.006022827699780464,
      0.005078282672911882,
      -0.05283341184258461,
      -0.044022463262081146,
      0.05789198726415634,
      -0.04803524911403656,
      -0.0070333234034478664,
      0.11996632069349289,
      0.0016883041244000196,
      -0.02336740680038929,
      -0.02206077054142952,
      -0.10620690882205963,
      -0.03576771914958954,
      -0.0029271503444761038,
      -0.02108434960246086,
      0.009763619862496853,
      -0.03888624906539917,
      -0.00992496032267809,
      -0.03999568521976471,
      -0.019063521176576614,
      -0.023918665945529938,
      -0.019834790378808975,
      -0.008792630396783352,
      0.04429749399423599,
      -0.09594208747148514,
      0.011356079019606113,
      -0.10647284239530563,
      -0.029964471235871315,
      0.005730701144784689,
      -0.04288452863693237,
      0.03908785805106163,
      0.05648362636566162,
      -0.005242455285042524,
      0.0052343327552080154,
      0.06709185987710953,
      -0.07010199874639511,
      -0.0303143672645092,
      -0.021128224208950996,
      -0.008799746632575989,
      0.016530724242329597,
      0.03719799220561981,
      0.046153631061315536,
      0.010083169676363468,
      -0.006574108265340328,
      -0.04865327104926109,
      -0.01837720163166523,
      -0.05363442376255989,
      -0.030503129586577415,
      -0.044341422617435455,
      -0.028377484530210495,
      0.007326806429773569,
      0.020565465092658997,
      0.006678679957985878,
      0.01099358033388853,
      0.018387503921985626,
      -0.010564032010734081,
      0.006008811295032501,
      -0.048718176782131195,
      -0.017444130033254623,
      0.04104756563901901,
      0.010889668017625809,
      0.03087991289794445,
      0.03983387351036072,
      0.010218236595392227,
      -0.027787277474999428,
      -0.049077339470386505,
      -0.043782491236925125,
      -0.06652893126010895,
      -0.034266434609889984,
      -0.029103508219122887,
      0.04737767204642296,
      0.02306319959461689,
      0.008832552470266819,
      0.022924119606614113,
      -0.05487874150276184,
      0.06001589074730873,
      0.010030267760157585,
      0.014110478572547436,
      -0.06048433855175972,
      0.0028285847511142492,
      0.010777751915156841,
      -0.008647659793496132,
      0.03779164329171181,
      -0.10388880968093872,
      -0.0236524548381567,
      0.004043065942823887,
      0.025241561233997345,
      0.008634773083031178,
      -0.03396589308977127,
      -0.049917858093976974,
      -0.019478265196084976,
      -0.07529644668102264,
      -0.022433297708630562,
      0.10124430060386658,
      -0.012434202246367931,
      0.026603955775499344,
      0.003098811488598585,
      -0.06561434268951416,
      0.06113221496343613,
      0.03213513642549515,
      -0.024779165163636208,
      0.01637246645987034,
      -0.008824345655739307,
      0.07727769762277603,
      -0.044010985642671585,
      0.03777291998267174,
      0.006556123960763216,
      -0.06880167871713638,
      -0.02563588321208954,
      -0.042866483330726624,
      0.0434088371694088,
      0.015862800180912018,
      -0.07056750357151031,
      0.01508844643831253,
      -0.05221850052475929,
      -0.05823526531457901,
      0.01189638115465641,
      -0.05049239471554756,
      0.025606371462345123,
      -0.07698587328195572,
      0.05218568444252014,
      -0.002831636695191264,
      0.06848382949829102,
      0.01932109147310257,
      0.015171010047197342,
      0.04315875843167305,
      -0.0735272765159607,
      -0.01563967578113079,
      0.012931874953210354,
      -0.026038620620965958,
      0.0029825824312865734,
      0.003630424849689007,
      0.026435736566781998,
      0.04066374525427818,
      -0.022994818165898323,
      -0.023682476952672005,
      -0.001996633829548955,
      -0.03390852361917496,
      0.04535428807139397,
      0.005628539714962244,
      0.026539506390690804,
      0.019417613744735718,
      0.14022196829319,
      -0.0070303333923220634,
      -0.0015213307924568653,
      -0.021054422482848167,
      0.09061001986265182,
      -0.1468198299407959,
      0.025488700717687607,
      -0.02942124754190445,
      0.00563945434987545,
      -0.08884779363870621,
      -0.0022253405768424273,
      0.023448562249541283,
      0.004151606000959873,
      0.06286950409412384,
      -0.06349228322505951,
      0.016465652734041214,
      -0.059181783348321915,
      -0.00385286845266819,
      -0.0643482655286789,
      -0.0634189248085022,
      -0.01673605665564537,
      0.05549576133489609,
      0.09292007237672806,
      0.024804817512631416,
      0.08314868807792664,
      0.07336752861738205,
      -0.04296128824353218,
      -0.011422333307564259,
      0.011929933913052082,
      0.10642731189727783,
      -0.07167131453752518,
      -0.07199480384588242,
      0.0234170351177454,
      -0.08121360838413239,
      0.03304395079612732,
      0.03998775780200958,
      0.07317355275154114,
      0.08974204957485199,
      -0.12876085937023163,
      -0.0034864828921854496,
      0.07741601020097733,
      0.020954830572009087,
      0.02438414841890335,
      0.022218402475118637,
      -0.06714284420013428,
      0.09051835536956787,
      -0.0003254594630561769,
      -0.03979963809251785,
      -0.045055191963911057,
      -0.03374449908733368,
      0.01982012204825878,
      0.0002093974471790716,
      -0.08680953830480576,
      0.012344897724688053,
      0.0341302789747715,
      0.022682180628180504,
      0.05206324905157089,
      -0.012644167058169842,
      -0.08039581030607224,
      -0.006520588882267475,
      0.035844724625349045,
      -0.06281039863824844,
      0.0487169548869133,
      -0.004307449795305729,
      0.06945081800222397,
      -0.0850680023431778,
      0.008312779478728771,
      -0.06574218720197678,
      -0.04851366579532623,
      -0.03306425362825394,
      0.03775838762521744,
      -0.012513679452240467,
      -0.06393259763717651,
      -0.07988114655017853,
      -0.016254950314760208,
      0.0255722738802433,
      0.07758916169404984,
      0.07264112681150436,
      -0.0006596345920115709,
      0.09056828916072845,
      0.041823823004961014,
      -0.07094235718250275,
      -0.0706070065498352,
      -0.03849656879901886,
      -0.041408926248550415,
      -0.04257084056735039,
      -0.045599691569805145,
      0.05698203295469284,
      0.04496775567531586,
      -0.02453962154686451,
      0.048366885632276535,
      0.058437529951334,
      0.02295970916748047,
      0.021972697228193283,
      -0.028903640806674957,
      0.006975812371820211,
      -0.04789434373378754,
      -0.07383617758750916,
      -0.03547435253858566,
      0.06000833213329315,
      -0.01327384915202856,
      -0.020510314032435417,
      -0.005878935568034649,
      0.02922181971371174,
      -0.010904621332883835,
      0.014196718111634254,
      0.09366877377033234,
      -0.032582372426986694,
      0.08015795052051544,
      -0.021422045305371284,
      0.016222912818193436,
      0.018444649875164032,
      0.04733790457248688,
      0.031213704496622086,
      0.03172343224287033,
      0.11310075968503952,
      0.0686660036444664,
      0.035268958657979965,
      -0.08341635763645172,
      -0.003679138608276844,
      -0.018445424735546112,
      -0.02364644594490528,
      -0.0225754976272583,
      0.004258465021848679,
      0.04113849624991417,
      0.0319552943110466,
      0.10876084864139557,
      0.0836959183216095,
      -0.04962504655122757,
      0.024632010608911514,
      -0.10369230806827545,
      0.0027585746720433235,
      0.03831396996974945,
      -0.04255232587456703,
      -0.07888804376125336,
      -0.04644804820418358,
      0.01742476038634777,
      0.09018491953611374,
      -0.001985283102840185,
      -0.09310463815927505,
      0.003153296187520027,
      -0.041930146515369415,
      0.05536520853638649,
      0.010324805974960327,
      0.006578683853149414,
      -0.08115695416927338,
      -0.003003499936312437,
      -0.02804107964038849,
      0.04757789522409439,
      0.013115703128278255,
      0.0453687347471714,
      -0.02014605700969696,
      -0.03740418702363968,
      -0.034622710198163986,
      0.03964347392320633,
      0.00946877058595419,
      0.0047540427185595036
    ],
    [
      -0.0005767937982454896,
      0.048363540321588516,
      -0.05524137243628502,
      -0.11510487645864487,
      0.04430433735251427,
      0.01461977418512106,
      0.06258077174425125,
      -0.052723951637744904,
      -0.12765246629714966,
      -0.031924568116664886,
      0.06097372621297836,
      -0.006935294717550278,
      -0.00021086518245283514,
      0.05226027965545654,
      0.09464174509048462,
      0.009471390396356583,
      -0.01325346902012825,
      -0.02888776734471321,
      -0.014238266274333,
      0.0524589866399765,
      -0.012055182829499245,
      0.007621056400239468,
      0.010442899540066719,
      0.007168303243815899,
      0.07077810168266296,
      -0.03684714809060097,
      -0.03652443364262581,
      -0.01820508949458599,
      0.08535293489694595,
      0.04265435039997101,
      0.05038423836231232,
      -0.05992024764418602,
      -0.003239152953028679,
      -0.04008011892437935,
      -0.02435281313955784,
      0.028736000880599022,
      0.03202009201049805,
      -0.015375600196421146,
      -0.026720307767391205,
      -0.019210094586014748,
      -0.05050037428736687,
      -0.008179979398846626,
      0.05049484223127365,
      0.03717628866434097,
      -0.001981632085517049,
      -0.07109205424785614,
      0.04613047093153,
      -0.05331334099173546,
      -0.008045898750424385,
      -0.033256374299526215,
      0.04445653408765793,
      -0.010854318737983704,
      -0.04941266030073166,
      0.0017291824333369732,
      -0.007043357472866774,
      0.006641215644776821,
      0.08122483640909195,
      -0.03572312742471695,
      -0.02460809424519539,
      -0.0028327445033937693,
      -0.0628940612077713,
      0.09614021331071854,
      -0.06504832953214645,
      0.07472636550664902,
      -0.00425708619877696,
      0.08662759512662888,
      0.0067670526914298534,
      -0.024797648191452026,
      0.05472299084067345,
      0.07164891064167023,
      0.06714708358049393,
      -0.022214705124497414,
      0.1043536588549614,
      0.017685139551758766,
      -0.012672699987888336,
      -0.0006063624168746173,
      0.005094066262245178,
      0.03995781019330025,
      0.116835817694664,
      0.0191746074706316,
      0.030274273827672005,
      0.02087516151368618,
      -0.039202529937028885,
      -0.05050883814692497,
      -0.0704546794295311,
      0.07204261422157288,
      0.03912980854511261,
      0.04199817776679993,
      -0.010159388184547424,
      -0.04951651394367218,
      0.03841138258576393,
      0.057510681450366974,
      -0.07622339576482773,
      0.05773767828941345,
      -0.02217714674770832,
      -0.015991922467947006,
      -0.002148399828001857,
      -0.001343094278126955,
      0.06535864621400833,
      0.06689558178186417,
      -0.04906391724944115,
      -0.09774979203939438,
      0.014948580414056778,
      -0.0966067910194397,
      -0.06512220948934555,
      -0.0656614899635315,
      -0.018603648990392685,
      0.003118053777143359,
      0.06883175671100616,
      0.025782736018300056,
      -0.057303041219711304,
      -0.025480762124061584,
      0.01058946829289198,
      -0.018549911677837372,
      0.041672173887491226,
      -0.054299745708703995,
      -0.00902946013957262,
      -0.021511191502213478,
      -0.04163704812526703,
      -0.09710673242807388,
      -0.035813845694065094,
      0.0855698212981224,
      0.011549778282642365,
      -0.065907321870327,
      0.05698210000991821,
      0.03890599310398102,
      0.04562702029943466,
      0.02824360504746437,
      0.07375577837228775,
      0.053963612765073776,
      -0.12320408225059509,
      -0.08794588595628738,
      -0.019399870187044144,
      -0.017680859193205833,
      0.04003627970814705,
      -0.034755900502204895,
      -0.024465665221214294,
      0.023681525141000748,
      0.009407500736415386,
      0.05425216630101204,
      0.0029010463040322065,
      -0.008941583335399628,
      0.004607592709362507,
      0.0331125445663929,
      0.014358315616846085,
      -0.04352342709898949,
      0.025312699377536774,
      -0.009687473066151142,
      -0.004734570160508156,
      -0.001637467765249312,
      -0.003538247663527727,
      0.060014620423316956,
      -0.03327200189232826,
      -0.03117150254547596,
      0.00016214892093557864,
      -0.07714953273534775,
      0.04599027335643768,
      -0.11113664507865906,
      0.03291679173707962,
      0.04053203761577606,
      -0.0002254049468319863,
      -0.08073299378156662,
      -0.07038331776857376,
      -0.014475813135504723,
      0.020421914756298065,
      0.0022531587164849043,
      0.0006484760087914765,
      -0.02481168508529663,
      -0.022922921925783157,
      -0.009149198420345783,
      -0.09575877338647842,
      -0.0014506079023703933,
      -0.034874558448791504,
      0.018518440425395966,
      0.06680122762918472,
      0.05932457745075226,
      -0.005287211388349533,
      -0.03973032161593437,
      0.0563819520175457,
      -0.006060268729925156,
      -0.02861536480486393,
      -0.009885733015835285,
      0.052954744547605515,
      0.015172155573964119,
      -0.01994697004556656,
      0.0865388885140419,
      -0.0012798322131857276,
      0.012806745246052742,
      0.030639728531241417,
      -0.03240394964814186,
      0.047290902584791183,
      -0.0007834740681573749,
      -0.08406383544206619,
      -0.004142395220696926,
      -0.03880031034350395,
      0.020543836057186127,
      0.025548862293362617,
      0.04410387948155403,
      0.0353434793651104,
      0.020816124975681305,
      -0.03861551731824875,
      0.023298105224967003,
      0.0747152790427208,
      0.04774314537644386,
      0.0014629581710323691,
      0.07353156059980392,
      -0.045746296644210815,
      -0.0968131497502327,
      0.05478253215551376,
      -0.09515959769487381,
      0.007419928442686796,
      0.00900727603584528,
      -0.05356599763035774,
      -0.006716660689562559,
      0.04995136708021164,
      0.029165063053369522,
      -0.0028642849065363407,
      0.037786468863487244,
      0.016811596229672432,
      0.0008300742483697832,
      -0.010576575994491577,
      0.08855438232421875,
      0.026253309100866318,
      -0.03211389109492302,
      -0.008311553858220577,
      -0.015772394835948944,
      0.036034829914569855,
      0.09566992521286011,
      0.022448455914855003,
      0.020690826699137688,
      -0.015148990787565708,
      -0.011884997598826885,
      0.05961214751005173,
      -0.02826867811381817,
      -0.015136051923036575,
      -0.006658249069005251,
      -0.03173770010471344,
      -0.026459630578756332,
      0.008530988357961178,
      -0.05189518257975578,
      0.054770246148109436,
      -0.04162386804819107,
      0.01235587801784277,
      -0.0018361603142693639,
      0.011833420023322105,
      0.02471211925148964,
      0.03263603523373604,
      -0.016610635444521904,
      0.02110341750085354,
      0.039275284856557846,
      0.046531204134225845,
      -0.012858045287430286,
      -0.042555227875709534,
      -0.059164173901081085,
      0.061300378292798996,
      0.017996877431869507,
      0.087380550801754,
      0.022081643342971802,
      0.024349987506866455,
      0.03889480605721474,
      -0.0031298771500587463,
      -0.05413544923067093,
      -0.04338233917951584,
      -0.042502954602241516,
      -0.031799688935279846,
      -0.01724962890148163,
      -0.026420773938298225,
      0.0621957965195179,
      0.026966502889990807,
      -0.04494154825806618,
      -0.05287754908204079,
      -0.04035090655088425,
      -0.10751171410083771,
      0.010732948780059814,
      0.0026868083514273167,
      0.017841370776295662,
      -0.0450897254049778,
      0.005376309622079134,
      0.011943855322897434,
      -0.06702614575624466,
      0.01007495354861021,
      -0.05551479756832123,
      -0.031117137521505356,
      -0.003512411378324032,
      -0.05030427873134613,
      -0.07325559854507446,
      0.040842387825250626,
      -0.10183222591876984,
      -0.012410350143909454,
      0.011005537584424019,
      0.028572648763656616,
      -0.09220461547374725,
      0.09493429213762283,
      0.08722730726003647,
      -0.031532395631074905,
      -0.07879632711410522,
      0.10978327691555023,
      0.009234591387212276,
      -0.05097408965229988,
      -0.00781182199716568,
      0.01044918317347765,
      0.04860997200012207,
      0.011079438030719757,
      0.054596856236457825,
      -0.013716653920710087,
      -0.00663108890876174,
      0.006951615680009127,
      0.07011356204748154,
      0.030796105042099953,
      -0.0071370359510183334,
      0.047297701239585876,
      -0.024750879034399986,
      -0.0643644854426384,
      -0.02664163149893284,
      0.02985837496817112,
      0.01231361459940672,
      -0.0007894537411630154,
      -0.04899081215262413,
      -0.023358626291155815,
      -0.06307852268218994,
      0.07386524975299835,
      0.07489260286092758,
      -0.05702931433916092,
      0.0011090796906501055,
      0.07389144599437714,
      -0.07123300433158875,
      0.00789363868534565,
      0.04783499985933304,
      -0.04566669464111328,
      -0.0035067435819655657,
      0.038316190242767334,
      0.01114734262228012,
      -0.03357107937335968,
      -0.06668132543563843,
      0.03722653537988663,
      -0.01726958155632019,
      -0.01580948568880558,
      -0.037940673530101776,
      0.03789711371064186,
      -0.08817867934703827,
      0.044249631464481354,
      -0.029802003875374794,
      0.016876239329576492,
      -0.030075345188379288,
      0.02420024201273918,
      -0.006025053095072508,
      0.0003005338367074728,
      -0.035289086401462555,
      -0.01577339880168438,
      -0.0438992977142334,
      0.06494318693876266,
      -0.04413006454706192,
      0.026044294238090515,
      -0.10910113900899887,
      0.08211155980825424,
      -0.004676148761063814,
      0.013424316421151161,
      0.01330974604934454,
      0.07309968024492264,
      -0.10654062777757645,
      -0.0008742769132368267,
      0.04841956868767738,
      -0.007246613968163729,
      0.08982597291469574,
      -0.060480162501335144,
      -0.013814959675073624,
      0.010889804922044277,
      -0.07418549805879593,
      -0.013977767899632454,
      0.002975916024297476,
      0.007754829712212086,
      -0.006298378109931946,
      0.05860992148518562,
      0.0031310277990996838,
      0.021190619096159935,
      0.08343392610549927,
      -0.06337229162454605,
      -0.04194999113678932,
      -0.09345449507236481,
      0.07124876976013184,
      -0.035906482487916946,
      0.01482319924980402,
      -0.03115179017186165,
      0.035610564053058624,
      0.015210921876132488,
      0.0516924113035202,
      -0.10191597789525986,
      -0.02254282869398594,
      -0.058673467487096786,
      0.007463068701326847,
      0.06947792321443558,
      0.06919201463460922,
      -0.0002538349071983248,
      -0.002422222401946783,
      -0.02658936195075512,
      -0.0011776548344641924,
      -0.02193564549088478,
      0.04926544800400734,
      -0.06616044044494629,
      0.0838792473077774,
      0.011552051641047001,
      -0.03982233256101608,
      0.03890945762395859,
      -0.01957268826663494,
      -0.06658510118722916,
      -0.04778669774532318,
      -0.005000581964850426,
      -0.009422947652637959,
      0.06486480683088303,
      -0.03378831967711449,
      -0.011782251298427582,
      -0.02472236566245556,
      0.04407437518239021,
      -0.02451883815228939,
      0.05601188540458679,
      -0.05734986439347267,
      -0.05204575136303902,
      -0.0240783728659153,
      -0.013250859454274178,
      -0.02440616860985756,
      0.017579052597284317,
      0.1201368123292923,
      -0.03756658360362053,
      0.013658352196216583,
      -0.05137135460972786,
      -0.12223587930202484,
      0.019983995705842972,
      -0.11168719083070755,
      -0.06452511250972748,
      0.020145496353507042,
      0.06730940192937851,
      0.0608394518494606,
      -0.009484871290624142,
      0.0034977395553141832,
      0.028116483241319656,
      -0.021673396229743958,
      -0.045733772218227386,
      0.02112441137433052,
      0.025252968072891235,
      0.0293482206761837,
      0.008481110446155071,
      0.061348192393779755,
      -0.04354650899767876,
      0.004585149697959423,
      0.09270641952753067,
      -0.02653617411851883,
      -0.024624964222311974,
      0.014165465719997883,
      0.08253210037946701,
      -0.023318665102124214,
      0.05241318419575691,
      -0.09158150851726532,
      -0.020277466624975204,
      0.05095218867063522,
      0.0417996384203434,
      -0.09780755639076233,
      0.04513845220208168,
      0.05273297429084778,
      -0.04616443067789078,
      -0.033895689994096756,
      -0.0469832606613636,
      0.07465622574090958,
      0.04255767911672592,
      0.05701516568660736,
      0.010132401250302792,
      -0.08910282701253891,
      -0.004331924021244049,
      0.016765844076871872,
      -0.05312192812561989,
      0.012409358285367489,
      -0.007977266795933247,
      -0.027522560209035873,
      0.010084467940032482,
      0.037232428789138794,
      0.02988973632454872,
      0.06713065505027771,
      -0.017983900383114815,
      0.04452083259820938,
      -0.09346615523099899,
      -0.017335478216409683,
      -0.021044116467237473,
      -0.017826998606324196,
      -0.09354007244110107,
      -0.008699033409357071,
      -0.1067517027258873,
      0.021533051505684853,
      0.0022174569312483072,
      -0.00392975565046072,
      0.05141698941588402,
      -0.08121033757925034,
      -0.05350571498274803,
      -0.04817543923854828,
      0.03941696882247925,
      -0.05867899954319,
      0.0030747742857784033,
      0.01564142294228077,
      0.041793957352638245,
      0.014547095634043217,
      -0.11586044728755951,
      -0.03322768583893776,
      0.03371671214699745,
      0.016368962824344635,
      -0.04541929066181183
    ],
    [
      0.027103330940008163,
      0.04121338203549385,
      -0.0712326169013977,
      -0.03381030261516571,
      0.026096588000655174,
      0.00035069577279500663,
      -0.08193478733301163,
      -0.004248195793479681,
      -0.059903696179389954,
      -0.10783085972070694,
      -0.027168529108166695,
      -0.07150628417730331,
      0.018955577164888382,
      0.008703314699232578,
      0.008890636265277863,
      -0.03633659705519676,
      0.03773488849401474,
      0.005078666377812624,
      -0.04325767233967781,
      0.07846338301897049,
      0.006022563204169273,
      0.0039987689815461636,
      -0.0032719317823648453,
      0.000597153790295124,
      0.10920090228319168,
      -0.050697844475507736,
      0.05506234988570213,
      0.004317813087254763,
      -0.024016499519348145,
      -0.0157756470143795,
      0.05546458065509796,
      -0.06102894991636276,
      0.026850625872612,
      -0.07203079015016556,
      0.05322432890534401,
      -0.004263419657945633,
      0.00479371240362525,
      0.013123109936714172,
      -0.045782942324876785,
      0.012103407643735409,
      -0.09244777262210846,
      0.028756100684404373,
      0.0006345042493194342,
      -0.050255563110113144,
      0.02163238450884819,
      0.02821124531328678,
      0.04946927726268768,
      0.014586314558982849,
      -0.006748452316969633,
      -0.07485407590866089,
      0.0009212460136041045,
      -0.03662439063191414,
      0.005745436530560255,
      -0.07598210126161575,
      -0.015312318690121174,
      0.02681458741426468,
      0.06464450806379318,
      -0.03136948123574257,
      -0.048648279160261154,
      -0.023458166047930717,
      0.0355004221200943,
      -0.029764311388134956,
      -0.04770846664905548,
      0.03549893945455551,
      0.010851389728486538,
      0.015129992738366127,
      -0.01513847429305315,
      -0.026945151388645172,
      0.1004367545247078,
      -0.0311468243598938,
      0.06793932616710663,
      -0.00032142337295226753,
      0.03240633010864258,
      0.02021842822432518,
      -0.08095584064722061,
      -0.02764323726296425,
      0.0037591445725411177,
      0.010359047912061214,
      -0.06654760986566544,
      -0.030972391366958618,
      0.06062231585383415,
      0.04069853946566582,
      0.01570098102092743,
      0.01958855614066124,
      -0.02447122521698475,
      -0.004762815311551094,
      -0.05297562852501869,
      0.038406047970056534,
      -0.03275911509990692,
      0.009625137783586979,
      0.10459090769290924,
      -0.015764277428388596,
      -0.06051895394921303,
      -0.006399264559149742,
      -0.08517130464315414,
      -0.022792145609855652,
      -0.04158962890505791,
      0.07040385901927948,
      -0.036596834659576416,
      -0.04809590056538582,
      0.017418676987290382,
      0.050159990787506104,
      0.041212692856788635,
      -0.10282643139362335,
      0.0513620600104332,
      -0.04499320685863495,
      -0.017453012987971306,
      -0.04371051490306854,
      0.1488097608089447,
      0.027452975511550903,
      0.012104004621505737,
      -0.021923407912254333,
      0.06404067575931549,
      0.0029481202363967896,
      0.026136809960007668,
      0.05022472143173218,
      -0.030473681166768074,
      -0.08206106722354889,
      -0.07049231976270676,
      0.07849524915218353,
      -0.0009148282697424293,
      0.05539721995592117,
      0.039650026708841324,
      -0.04822901263833046,
      0.014597689732909203,
      -0.0024166363291442394,
      0.02723013609647751,
      -0.05402115359902382,
      -0.01789446733891964,
      -0.010693726129829884,
      -0.00592455081641674,
      -0.005708262324333191,
      0.038547784090042114,
      0.04179409518837929,
      0.02288747951388359,
      -0.04897040128707886,
      0.007401828188449144,
      -0.06719575822353363,
      -0.05743732675909996,
      0.04967864975333214,
      0.02653150074183941,
      0.002514743246138096,
      0.03566661849617958,
      0.010240786708891392,
      0.010877893306314945,
      0.008009139448404312,
      0.037838101387023926,
      0.03215264901518822,
      0.03159879148006439,
      0.03004874289035797,
      -0.008465440012514591,
      4.7383542550960556e-05,
      0.036625251173973083,
      -0.017114687711000443,
      0.031148850917816162,
      -0.07162842154502869,
      -0.02493729628622532,
      0.03400544822216034,
      0.016043612733483315,
      -0.038002196699380875,
      0.00788111425936222,
      -0.005781525745987892,
      0.0034392306115478277,
      0.06319256871938705,
      -0.02569486014544964,
      -0.011733937077224255,
      0.027925658971071243,
      0.021749135106801987,
      0.07117201387882233,
      -0.023445483297109604,
      -0.0805627629160881,
      -0.032333508133888245,
      -0.02312239073216915,
      0.04405813291668892,
      0.010396404191851616,
      -0.03956069424748421,
      -0.02154012769460678,
      -0.037644825875759125,
      -0.041357818990945816,
      -0.0007035672315396369,
      -0.03889819234609604,
      0.08574232459068298,
      0.0673309937119484,
      0.003727981122210622,
      -0.04177998751401901,
      -0.11564919352531433,
      -0.049885936081409454,
      0.011559555307030678,
      0.10093824565410614,
      -0.0416078045964241,
      -0.057033028453588486,
      -0.019310837611556053,
      -0.10877389460802078,
      -0.04673796892166138,
      0.005710427649319172,
      -0.08612474799156189,
      -0.035731635987758636,
      0.010400760918855667,
      -0.00539565971121192,
      -0.062033556401729584,
      -0.12586526572704315,
      0.0743674486875534,
      0.03851178660988808,
      0.021516501903533936,
      -0.039412327110767365,
      0.012871687300503254,
      0.016300763934850693,
      -0.06538207083940506,
      -0.06770557165145874,
      0.02185508795082569,
      0.014068689197301865,
      0.02286418154835701,
      0.013805697672069073,
      0.04871727153658867,
      -0.0553581602871418,
      0.005912677384912968,
      0.04258961230516434,
      -0.056204866617918015,
      0.025605764240026474,
      0.0127240726724267,
      -0.058153994381427765,
      0.008421345613896847,
      0.017243286594748497,
      -0.024383660405874252,
      0.016295600682497025,
      0.0001202662824653089,
      0.00391721073538065,
      0.04887555539608002,
      -0.07196912914514542,
      -0.016027003526687622,
      -0.027482662349939346,
      -0.07693136483430862,
      -0.004562887363135815,
      -0.016490202397108078,
      -0.03915230929851532,
      0.08019784837961197,
      -0.0447445772588253,
      0.06099490076303482,
      0.01650075986981392,
      0.00866142101585865,
      -0.01679857447743416,
      -0.03803195804357529,
      -0.043529268354177475,
      0.008146391250193119,
      0.03283551335334778,
      0.05900100618600845,
      -0.00690927030518651,
      -0.01419371459633112,
      0.04051252454519272,
      0.060642391443252563,
      0.029175106436014175,
      -0.012460313737392426,
      -0.03803423419594765,
      -0.035135213285684586,
      0.03534020483493805,
      0.016786420717835426,
      0.012731711380183697,
      -0.018922830000519753,
      0.02173745259642601,
      -0.045574139803647995,
      -0.08538082242012024,
      0.0017556136008352041,
      0.012597806751728058,
      0.013600209727883339,
      0.06264214962720871,
      0.03696957975625992,
      0.01916111819446087,
      0.05242111161351204,
      0.07872224599123001,
      0.002067391062155366,
      -0.02819492667913437,
      -0.028188444674015045,
      0.0901719182729721,
      0.04942220076918602,
      0.03954183682799339,
      0.032901860773563385,
      -0.014559564180672169,
      -0.07672601193189621,
      -0.021709516644477844,
      -0.040579866617918015,
      -0.008416375145316124,
      0.040020331740379333,
      0.018876193091273308,
      0.009361866861581802,
      -0.018077338114380836,
      -0.08244723081588745,
      -0.019047893583774567,
      -0.09524592757225037,
      0.01807575300335884,
      0.03185274824500084,
      0.07583995908498764,
      0.05802684277296066,
      -0.023149996995925903,
      -0.05169399827718735,
      -0.041794028133153915,
      -0.051287174224853516,
      -0.0336364209651947,
      0.006320847198367119,
      -2.6029474611277692e-05,
      0.006913297343999147,
      -0.04892618954181671,
      -0.017742494121193886,
      -0.023583369329571724,
      0.059140510857105255,
      0.06304304301738739,
      0.009473220445215702,
      0.06156319007277489,
      -0.022058852016925812,
      0.06063757464289665,
      -0.008031697012484074,
      -0.03479044511914253,
      -0.03615814819931984,
      -0.02226254530251026,
      -0.03848816081881523,
      -0.0017327539389953017,
      -0.03050728514790535,
      0.05539917200803757,
      0.057815536856651306,
      -0.09468931704759598,
      0.031194496899843216,
      0.10483027249574661,
      -0.029690368101000786,
      -0.05897976830601692,
      0.06524907797574997,
      0.08196724951267242,
      -0.046862710267305374,
      -0.03739845007658005,
      -0.046125270426273346,
      0.010124102234840393,
      0.029083454981446266,
      0.053046636283397675,
      0.08104345947504044,
      -0.02971699833869934,
      -0.0589250884950161,
      0.09636466205120087,
      0.019583921879529953,
      -0.07330841571092606,
      0.01763995923101902,
      0.018258066847920418,
      -0.005680645816028118,
      0.06806451827287674,
      -0.03777192905545235,
      0.06451990455389023,
      -0.043701525777578354,
      0.04884679615497589,
      0.0027058613486588,
      0.05960766226053238,
      -0.07400225102901459,
      -0.02814987674355507,
      -0.02445070445537567,
      0.0040507847443223,
      -0.04557200148701668,
      0.06155550852417946,
      -0.07002853602170944,
      -0.03641471266746521,
      -0.01759937033057213,
      -0.06499768048524857,
      0.06715718656778336,
      -0.024752920493483543,
      -0.000713737215846777,
      0.003787085646763444,
      -0.02666006237268448,
      -0.06199090555310249,
      0.04302510991692543,
      -0.04430917277932167,
      -0.004834652412682772,
      0.03885869309306145,
      -0.0242011658847332,
      -0.028057469055056572,
      0.06369753926992416,
      0.055421993136405945,
      0.029532872140407562,
      -0.051431115716695786,
      -0.04313119128346443,
      0.014131543226540089,
      0.08494211733341217,
      -0.06637921184301376,
      -0.04567790403962135,
      -0.023500418290495872,
      -0.045160867273807526,
      -0.05593251809477806,
      0.05865190550684929,
      -0.14359250664710999,
      0.0466199554502964,
      -0.060286346822977066,
      0.05154971405863762,
      -0.011379015631973743,
      -0.0215836763381958,
      -0.09249362349510193,
      0.09324116259813309,
      -0.010730041190981865,
      0.02811204083263874,
      0.041758790612220764,
      -0.0004986581625416875,
      0.05350213870406151,
      0.03201824054121971,
      0.030033020302653313,
      0.003296608105301857,
      -0.06797531992197037,
      -0.12134329974651337,
      -0.009690606966614723,
      0.0962774008512497,
      0.03322034701704979,
      -0.027881303802132607,
      0.003892769105732441,
      -0.059313733130693436,
      -0.028140543028712273,
      0.0043543376959860325,
      0.05078117176890373,
      -0.07466986030340195,
      -0.005953455343842506,
      0.060949377715587616,
      -0.004488964565098286,
      -0.004889363888651133,
      0.06484479457139969,
      -0.008109111338853836,
      0.005225293803960085,
      -0.0019143035169690847,
      0.025599155575037003,
      -0.15783460438251495,
      -0.021228250116109848,
      -0.01164907868951559,
      -0.003361519193276763,
      0.015198239125311375,
      0.004102127626538277,
      0.021824145689606667,
      0.044072721153497696,
      -0.08650345355272293,
      0.01873537339270115,
      0.10428651422262192,
      0.01163648720830679,
      0.1120682805776596,
      -0.01852896250784397,
      -0.06325146555900574,
      0.05264053866267204,
      0.01260521449148655,
      -0.07457663118839264,
      0.007761778775602579,
      -0.050239577889442444,
      -0.01958823762834072,
      -0.02758210152387619,
      -0.07676112651824951,
      -0.010218177922070026,
      0.04369781166315079,
      -0.002573980949819088,
      -0.01467218715697527,
      0.004181879106909037,
      -0.009612717665731907,
      0.014907230623066425,
      -0.0077042002230882645,
      0.059498537331819534,
      -0.031888172030448914,
      0.011955923400819302,
      -0.0314667783677578,
      0.02073928713798523,
      0.01800553873181343,
      0.04377862438559532,
      -0.03664782643318176,
      0.02167699858546257,
      -0.018918253481388092,
      0.006273182574659586,
      0.19276881217956543,
      -0.08914270997047424,
      -0.0035551986657083035,
      0.08957752585411072,
      -0.0015380652621388435,
      0.05044702813029289,
      0.0031820577569305897,
      -0.09320933371782303,
      -0.002550670877099037,
      0.08014962822198868,
      -0.028798244893550873,
      -0.02793695032596588,
      -0.023076962679624557,
      -0.033383119851350784,
      -0.0005338119226507843,
      -0.06496306508779526,
      -0.020517826080322266,
      -0.04849733039736748,
      -0.05501316860318184,
      -0.053707823157310486,
      -0.027144411578774452,
      0.049784451723098755,
      0.07833956182003021,
      -0.060119714587926865,
      -0.0688835084438324,
      0.016534466296434402,
      -0.07693392783403397,
      0.004416283220052719,
      -0.0014781978679820895,
      -0.04759174585342407,
      0.04321403056383133,
      0.008077018894255161,
      0.08825820684432983,
      -0.0832441970705986,
      0.07507581263780594,
      -0.012320725247263908,
      -0.05859196558594704,
      0.02539295144379139,
      0.015514563769102097,
      0.015748869627714157,
      -0.05036504939198494,
      -0.04741516336798668
    ],
    [
      -0.004994705319404602,
      0.0023054417688399553,
      -0.0015171689447015524,
      -0.026764068752527237,
      -0.042369697242975235,
      -0.032850272953510284,
      0.004819799680262804,
      -0.012037409469485283,
      0.002353876829147339,
      -0.07858432829380035,
      0.03424303978681564,
      -0.009641794487833977,
      0.006736621260643005,
      0.03494228795170784,
      0.06516248732805252,
      0.07866736501455307,
      0.010247722268104553,
      0.04087705537676811,
      -0.013884230516850948,
      0.0015458143316209316,
      0.11531306803226471,
      -0.11655791103839874,
      -0.037487681955099106,
      0.06811416149139404,
      0.07379499822854996,
      0.006788181606680155,
      -0.03979487717151642,
      0.0670396015048027,
      -0.03005996160209179,
      -0.07754219323396683,
      0.03644191846251488,
      -0.024883070960640907,
      0.00591219263151288,
      -0.019335877150297165,
      -0.008715318515896797,
      0.02517852559685707,
      0.05902573838829994,
      -0.025245310738682747,
      -0.01103509496897459,
      0.009905531071126461,
      -0.02706853114068508,
      0.0626642033457756,
      0.04002250358462334,
      -0.033614642918109894,
      -0.013201655820012093,
      0.02470458298921585,
      0.045313574373722076,
      -0.038441870361566544,
      -0.008978505618870258,
      -0.047343023121356964,
      0.007154629100114107,
      0.016596266999840736,
      -0.015423259697854519,
      -0.05061054974794388,
      0.020718099549412727,
      -0.04310990124940872,
      0.02778482250869274,
      0.035491447895765305,
      0.09943019598722458,
      0.022516446188092232,
      -0.05201844871044159,
      0.07728567719459534,
      0.007146872114390135,
      -0.042853593826293945,
      0.012161512859165668,
      -0.022676542401313782,
      0.056067436933517456,
      -0.05785766616463661,
      -0.009550080634653568,
      -0.03180425986647606,
      -0.0502154640853405,
      0.05403709411621094,
      0.017322663217782974,
      -0.002013196935877204,
      0.03472110629081726,
      0.00558084063231945,
      0.025425849482417107,
      0.028806643560528755,
      -0.026779398322105408,
      0.05186396464705467,
      0.0232686884701252,
      -0.026635486632585526,
      -0.08953513950109482,
      -0.09934812039136887,
      -0.03740091249346733,
      0.03689325973391533,
      0.007903648540377617,
      0.010705157183110714,
      -0.015894176438450813,
      0.013863902539014816,
      0.026053301990032196,
      -0.027040081098675728,
      0.004698070231825113,
      0.024412784725427628,
      -0.010829206556081772,
      0.0400066040456295,
      0.011044716462492943,
      -0.040499527007341385,
      0.05314146354794502,
      0.0054092081263661385,
      -0.05580609664320946,
      0.10157737880945206,
      0.10659245401620865,
      -0.10434591770172119,
      -0.07501354068517685,
      0.02261587791144848,
      0.045272551476955414,
      -0.009980314411222935,
      0.07361684739589691,
      -0.15436336398124695,
      -0.06103983893990517,
      0.016395123675465584,
      -0.023947622627019882,
      0.01784573495388031,
      0.05309000238776207,
      0.007275836076587439,
      -0.012778845615684986,
      -0.03482649847865105,
      -0.028079895302653313,
      -0.04265148192644119,
      -0.06580047309398651,
      0.0019221969414502382,
      0.02043081447482109,
      -0.04151105135679245,
      0.011479426175355911,
      -0.017785727977752686,
      -0.008541055023670197,
      -0.047847818583250046,
      0.013275467790663242,
      0.005992600228637457,
      0.04263148456811905,
      -0.024248357862234116,
      -0.0059055667370557785,
      0.007479374762624502,
      0.0197855606675148,
      0.04063543677330017,
      0.007259770762175322,
      -0.001630371087230742,
      -0.07154792547225952,
      -0.018959365785121918,
      -0.06676612049341202,
      -0.056008338928222656,
      0.013547671027481556,
      -0.024180375039577484,
      0.016468970105051994,
      -0.03719763830304146,
      0.041403722018003464,
      0.004468141123652458,
      -0.02718876674771309,
      0.009980249218642712,
      0.01957695558667183,
      -0.018885038793087006,
      0.029596395790576935,
      -0.039034418761730194,
      0.06797071546316147,
      -0.03037579543888569,
      0.07518308609724045,
      -0.017361177131533623,
      0.0495140478014946,
      0.041005998849868774,
      0.008318732492625713,
      0.04167245328426361,
      0.04439864680171013,
      0.028173066675662994,
      -0.01595909893512726,
      0.046714480966329575,
      0.002750836778432131,
      0.022416897118091583,
      -0.0005960329435765743,
      0.029476959258317947,
      0.027669139206409454,
      -0.009903485886752605,
      0.08463127911090851,
      0.047687940299510956,
      0.009503382258117199,
      0.03374568372964859,
      0.004724451340734959,
      0.02163173072040081,
      -0.004074768163263798,
      -0.00812084786593914,
      -0.022515609860420227,
      0.03193511441349983,
      -0.04336545988917351,
      0.00315206591039896,
      -0.0007438005995936692,
      0.02596844919025898,
      0.03291267156600952,
      -0.05586385726928711,
      -0.039435699582099915,
      -0.10056079179048538,
      -0.04288496822118759,
      -0.06788575649261475,
      -0.015032020397484303,
      -0.022528482601046562,
      0.013120275922119617,
      -0.005471840035170317,
      0.11773193627595901,
      -0.032272614538669586,
      0.05141228064894676,
      -0.002213513245806098,
      -0.07482364773750305,
      -0.030803445726633072,
      0.016034146770834923,
      -0.01309139933437109,
      0.01274937018752098,
      -0.004527016542851925,
      -0.02164331078529358,
      0.014317476190626621,
      0.02647305466234684,
      -0.023102136328816414,
      0.05307161062955856,
      0.016705820336937904,
      -0.07132349163293839,
      0.0019714045338332653,
      -0.027095474302768707,
      -0.0033497437834739685,
      -0.027280602604150772,
      -0.050308726727962494,
      -0.009829238057136536,
      -0.025806326419115067,
      0.07912013679742813,
      -0.01016685925424099,
      0.026712054386734962,
      -0.031901173293590546,
      -0.03545926511287689,
      -0.017510419711470604,
      -0.029316648840904236,
      0.09241708368062973,
      0.04468407481908798,
      -0.00792789552360773,
      -0.09468510746955872,
      0.00023362845240626484,
      -0.09645775705575943,
      -0.002245745388790965,
      -0.031333889812231064,
      -0.026578335091471672,
      0.06205982714891434,
      -0.0661911591887474,
      0.019152160733938217,
      -0.012191321700811386,
      -0.010756992734968662,
      -0.07757887244224548,
      -0.02965783327817917,
      -0.02391912415623665,
      0.01850811578333378,
      0.07338300347328186,
      0.03248574584722519,
      -0.010799376294016838,
      0.007140357978641987,
      -0.04407016932964325,
      -0.10275041311979294,
      0.05567571893334389,
      0.07295098155736923,
      -0.007390378508716822,
      0.031630877405405045,
      -0.00972441490739584,
      0.021461423486471176,
      -0.004286260809749365,
      0.06944436579942703,
      0.08249352872371674,
      -0.0188446082174778,
      -0.050131719559431076,
      0.023878736421465874,
      -0.00134574668481946,
      -0.03894792124629021,
      0.030048798769712448,
      -0.006066359579563141,
      -0.07496670633554459,
      -0.0644097551703453,
      0.044580135494470596,
      0.0030326859559863806,
      -0.08682806044816971,
      -0.052730560302734375,
      0.006750763393938541,
      -0.014530479907989502,
      -0.05923985689878464,
      -0.04980655759572983,
      -0.11152888089418411,
      -0.004945546854287386,
      -0.011147644370794296,
      0.024138549342751503,
      0.007219897583127022,
      -0.035058751702308655,
      0.0024421922862529755,
      -0.05489102005958557,
      -0.0709836557507515,
      -0.016889899969100952,
      -0.03341042622923851,
      -0.03355824202299118,
      0.011029768735170364,
      0.053356997668743134,
      0.02439967170357704,
      -0.042594391852617264,
      -0.008575697429478168,
      -0.03194759786128998,
      -0.027647674083709717,
      0.04878251999616623,
      0.017591223120689392,
      -0.02077573724091053,
      -0.03936932981014252,
      -0.033386003226041794,
      0.013235955499112606,
      -0.023256506770849228,
      0.01365684624761343,
      -0.004898614715784788,
      -0.05542419105768204,
      -0.02415311150252819,
      0.02639368176460266,
      -0.01860572211444378,
      0.05693281814455986,
      0.0162140354514122,
      -0.009459210559725761,
      -0.015357451513409615,
      0.04671039432287216,
      0.029382163658738136,
      -0.02153312973678112,
      0.037100717425346375,
      -0.01444766204804182,
      -0.09485471248626709,
      0.01354137808084488,
      0.036682937294244766,
      -0.0680629089474678,
      -0.06052640452980995,
      0.025266891345381737,
      -0.002934220479801297,
      -0.043517183512449265,
      0.05266181379556656,
      0.0015242563094943762,
      0.08934426307678223,
      -0.016130797564983368,
      0.034445758908987045,
      -0.009796950034797192,
      0.06439144909381866,
      -0.0560050830245018,
      -0.006189488340169191,
      0.1029086634516716,
      -0.03716495633125305,
      0.04927686229348183,
      -0.006013314239680767,
      -0.06081997975707054,
      -0.07669991999864578,
      0.015098054893314838,
      0.020066309720277786,
      -0.0020168242044746876,
      -0.013229049742221832,
      -0.014174291864037514,
      0.03633011505007744,
      -0.03930012509226799,
      0.08000656217336655,
      -0.04153267666697502,
      0.009915723465383053,
      -0.05262532830238342,
      -0.04248065501451492,
      -0.05391775071620941,
      0.023025192320346832,
      -0.10840673744678497,
      -0.0017895526252686977,
      0.06944973766803741,
      0.08666785806417465,
      -0.03650417551398277,
      0.0021885044407099485,
      0.018325036391615868,
      0.010672851465642452,
      -0.04019191861152649,
      0.00681400578469038,
      -0.007557549513876438,
      -0.03474445268511772,
      0.018065687268972397,
      -0.005503559019416571,
      -0.06959163397550583,
      0.005759994965046644,
      0.08460912853479385,
      -0.0071148741990327835,
      -0.06306669861078262,
      0.04110552370548248,
      0.000890731462277472,
      0.06448425352573395,
      0.023813406005501747,
      0.04814150556921959,
      0.02252928912639618,
      0.03648367151618004,
      0.05002276971936226,
      -0.003309638472273946,
      0.05320633947849274,
      -0.041918497532606125,
      0.0176909901201725,
      -0.04106815904378891,
      -0.0032219053246080875,
      -0.06919088214635849,
      -0.016289858147501945,
      -0.028260326012969017,
      -0.002450968837365508,
      0.03666948899626732,
      0.010940181091427803,
      0.030531765893101692,
      0.005159157328307629,
      0.039610493928194046,
      -0.05747482553124428,
      0.05486123636364937,
      0.03413761779665947,
      -0.0054563493467867374,
      0.035165008157491684,
      0.06450742483139038,
      -0.03413556143641472,
      -0.05403010547161102,
      -0.11110193282365799,
      -0.04899887368083,
      0.03650722652673721,
      -0.043502066284418106,
      0.09169229120016098,
      0.029636066406965256,
      -0.06804133206605911,
      -0.032437510788440704,
      0.022722119465470314,
      -0.03639976307749748,
      0.0682562068104744,
      -0.029267240315675735,
      0.06796997785568237,
      0.03127545490860939,
      0.05389885604381561,
      0.007837122306227684,
      0.029104378074407578,
      -0.03150387108325958,
      -0.05291244387626648,
      0.08447819948196411,
      0.07962734252214432,
      0.04931169003248215,
      -0.10475803166627884,
      -0.02882954478263855,
      0.016188770532608032,
      -0.03912796825170517,
      -0.1406956911087036,
      -0.02789638563990593,
      0.025570113211870193,
      0.03459387272596359,
      0.0519222766160965,
      -0.02856842614710331,
      -0.05632897838950157,
      0.03386610746383667,
      -0.09726493805646896,
      0.045230064541101456,
      0.036027513444423676,
      0.05215643346309662,
      -0.01710052601993084,
      0.05322655662894249,
      -0.04772278666496277,
      0.01991219073534012,
      0.002066564979031682,
      -0.04313422739505768,
      0.10359411686658859,
      0.037288859486579895,
      0.022193927317857742,
      -0.003985216841101646,
      -0.03406527265906334,
      0.07750549912452698,
      -0.05965679883956909,
      -0.018971022218465805,
      -0.06186458840966225,
      -0.07375255227088928,
      -0.023607000708580017,
      0.02187526412308216,
      0.014803705736994743,
      4.838861059397459e-05,
      0.07026664167642593,
      -0.0278001818805933,
      0.01960565894842148,
      -0.009078286588191986,
      -0.025155290961265564,
      -0.05805294215679169,
      0.05018167942762375,
      0.03240548446774483,
      0.10489639639854431,
      0.027008941397070885,
      -0.02201092801988125,
      -0.08951965719461441,
      -0.019676486030220985,
      -0.008637538179755211,
      0.031214512884616852,
      -0.01863744482398033,
      0.02827834151685238,
      0.026103921234607697,
      -0.04782792180776596,
      -0.006106972694396973,
      0.06098337471485138,
      -0.014575659297406673,
      0.044715553522109985,
      -0.03511660173535347,
      -0.09298539906740189,
      -0.030349189415574074,
      -0.03735945001244545,
      0.06708738952875137,
      -0.06642581522464752,
      0.031493958085775375,
      0.016740527004003525,
      0.03534690663218498,
      -0.013291037641465664,
      -0.03936221823096275,
      -0.012152052484452724,
      0.08346620202064514,
      -0.01243845745921135,
      -0.06163892522454262,
      -0.011502662673592567,
      -0.033882368355989456
    ],
    [
      0.04158551245927811,
      0.010829080827534199,
      -0.09490005671977997,
      -0.029258400201797485,
      0.02871035225689411,
      0.05838814377784729,
      0.06880803406238556,
      0.04005315899848938,
      -0.00018741916574072093,
      -0.05023381486535072,
      -0.020362291485071182,
      -0.0014545726589858532,
      0.009389743208885193,
      0.033749524503946304,
      -0.0089170066639781,
      -0.029973668977618217,
      0.038984738290309906,
      -0.05922793224453926,
      0.06828466802835464,
      -0.06027396395802498,
      0.059620846062898636,
      -0.07720433920621872,
      -0.038170915096998215,
      0.07264741510152817,
      0.11708548665046692,
      0.04115737974643707,
      -0.04374004527926445,
      -0.025500530377030373,
      0.026273351162672043,
      0.05688878521323204,
      0.004902560263872147,
      0.02956514246761799,
      -0.008160852827131748,
      -0.02579593099653721,
      -0.0405157171189785,
      -0.05958053842186928,
      -0.04666796326637268,
      0.0043502203188836575,
      -0.10455905646085739,
      0.023604243993759155,
      -0.09705812484025955,
      0.051393311470746994,
      0.006762051489204168,
      -0.001452161930501461,
      -0.05789456143975258,
      0.01292892824858427,
      0.03860543668270111,
      -0.023492826148867607,
      -0.01705441251397133,
      0.02456899732351303,
      0.04874809458851814,
      0.008349403738975525,
      -0.028748176991939545,
      -0.051430922001600266,
      -0.006185593083500862,
      0.02242017351090908,
      0.02215762250125408,
      -0.042322948575019836,
      0.07356159389019012,
      -0.01039288192987442,
      -0.004789236932992935,
      -0.005395431537181139,
      -0.07905101031064987,
      0.10554639995098114,
      0.008823066018521786,
      0.05971696227788925,
      0.05017659440636635,
      0.026954688131809235,
      -0.05525445193052292,
      0.09429807960987091,
      0.0037247564177960157,
      0.0038241944275796413,
      0.04295886307954788,
      -0.047896452248096466,
      0.05538211390376091,
      0.11331545561552048,
      0.04733995348215103,
      0.0718085989356041,
      0.026767326518893242,
      0.04616231843829155,
      0.01749631203711033,
      -0.039711833000183105,
      0.004263358656316996,
      0.032279256731271744,
      -0.04511790722608566,
      -0.03837790712714195,
      -0.0704149529337883,
      0.012942985631525517,
      -0.042495738714933395,
      -0.09398648142814636,
      0.0024382886476814747,
      -0.00631409976631403,
      -0.009163235314190388,
      0.06516896188259125,
      0.005683766677975655,
      -0.09076379239559174,
      0.03589065372943878,
      -0.03780083730816841,
      -0.036572329699993134,
      0.01174579095095396,
      -0.1039353609085083,
      -0.012798761017620564,
      -0.005073200445622206,
      -0.016650032252073288,
      0.016430389136075974,
      0.055169977247714996,
      0.03861227259039879,
      -0.055559538304805756,
      0.039793968200683594,
      -0.061121437698602676,
      -0.06489487737417221,
      -0.01567704603075981,
      0.037663642317056656,
      0.05530049651861191,
      -0.06595833599567413,
      0.028805261477828026,
      -0.03338131681084633,
      -0.027514373883605003,
      -0.015705861151218414,
      0.00028633023612201214,
      -0.05614572763442993,
      0.02180270478129387,
      -0.00896462146192789,
      -0.01594860851764679,
      -0.007743483409285545,
      -0.07043149322271347,
      -0.0761781558394432,
      -0.07096587866544724,
      0.04280930757522583,
      -0.007334528956562281,
      0.12954218685626984,
      0.008027765899896622,
      -0.04503575339913368,
      0.05740977078676224,
      -0.009726145304739475,
      0.02708628587424755,
      0.0006526176584884524,
      0.0470658615231514,
      0.03900204226374626,
      -0.03272085636854172,
      -0.020441366359591484,
      -0.026035543531179428,
      -0.01841104030609131,
      0.030677292495965958,
      0.015329512767493725,
      -0.028435373678803444,
      0.08111269026994705,
      0.0030023737344890833,
      -0.04524249956011772,
      -0.040583185851573944,
      0.021570924669504166,
      0.0606430359184742,
      -0.015566394664347172,
      -0.004510339815169573,
      -0.051697343587875366,
      -0.021816443651914597,
      0.07199390977621078,
      -0.048092424869537354,
      -0.0017886796267703176,
      0.037644971162080765,
      0.048977818340063095,
      0.007993568666279316,
      0.0361645445227623,
      0.013584250584244728,
      0.03473509103059769,
      0.04060439020395279,
      0.007960840128362179,
      -0.0013550191652029753,
      -0.017931098118424416,
      0.027035772800445557,
      -0.06403081119060516,
      0.05046743154525757,
      -0.06327416002750397,
      0.043069660663604736,
      -0.002568070078268647,
      -0.07432078570127487,
      -0.015564254485070705,
      -0.0020323763601481915,
      0.041770678013563156,
      0.060787320137023926,
      -0.08748746663331985,
      0.004710921086370945,
      0.0224830973893404,
      0.020912794396281242,
      -0.013725118711590767,
      0.0020287998486310244,
      0.04762649163603783,
      -0.013798266649246216,
      -0.07946465909481049,
      -0.11235926300287247,
      -0.0027856717351824045,
      -0.0678120031952858,
      -0.0641123428940773,
      0.0309159979224205,
      -0.0018957295687869191,
      0.01642284356057644,
      -0.06332692503929138,
      -0.024045823141932487,
      -0.04694389924407005,
      -0.06839093565940857,
      0.00716550275683403,
      -0.01658453233540058,
      -0.04206235334277153,
      -0.03028867021203041,
      0.039816468954086304,
      0.03849324584007263,
      -0.005966362543404102,
      0.07576854526996613,
      -0.06889022141695023,
      0.025145262479782104,
      -0.010748769156634808,
      -0.042604804039001465,
      0.0009856332326307893,
      0.00324835698120296,
      -0.021168960258364677,
      -0.03304900974035263,
      -0.0173842441290617,
      -0.048510100692510605,
      -0.044791799038648605,
      -0.07585939764976501,
      0.0026185482274740934,
      0.03563253581523895,
      -0.06542123854160309,
      0.007269114721566439,
      -0.14878299832344055,
      0.0003267987340223044,
      0.06130889803171158,
      0.08553580194711685,
      -0.02372995764017105,
      0.01453547552227974,
      -0.09892373532056808,
      0.005207991227507591,
      -0.07326123863458633,
      0.06306657940149307,
      -0.052447788417339325,
      -0.007895521819591522,
      -0.035038482397794724,
      -0.004906265996396542,
      -0.05850958079099655,
      0.04933793842792511,
      0.023325111716985703,
      0.022449763491749763,
      -0.011354985646903515,
      -0.049293871968984604,
      -0.009914910420775414,
      0.05876396223902702,
      0.036504048854112625,
      -0.03329544514417648,
      -0.012201815843582153,
      0.11672061681747437,
      0.012759952805936337,
      0.08568374067544937,
      0.055193595588207245,
      -0.0015841490821912885,
      0.027174167335033417,
      0.050313763320446014,
      0.029343256726861,
      -0.010862155817449093,
      0.09449120610952377,
      -0.0722794234752655,
      0.04857731610536575,
      -0.030198419466614723,
      0.051828037947416306,
      0.0647176131606102,
      -0.05592991039156914,
      -0.07800925523042679,
      0.032709233462810516,
      0.06539223343133926,
      -0.03682935982942581,
      0.06578934192657471,
      0.001848968560807407,
      -0.053387049585580826,
      0.008718823082745075,
      0.024259889498353004,
      -0.03738446161150932,
      0.0330047644674778,
      0.047132816165685654,
      -0.019146814942359924,
      -0.051800187677145004,
      0.10617413371801376,
      -0.06655069440603256,
      -0.02311663329601288,
      -0.04660138487815857,
      -0.09698446840047836,
      0.060882069170475006,
      0.041814498603343964,
      7.056505273794755e-05,
      -0.01503897923976183,
      -0.04126223176717758,
      -0.03248472511768341,
      0.08787010610103607,
      -0.008092999458312988,
      -0.012356600724160671,
      -0.008398511447012424,
      -0.03630812466144562,
      -0.042416270822286606,
      -0.01266100350767374,
      0.05990423634648323,
      -0.09090928733348846,
      0.005165548529475927,
      0.07359512150287628,
      -0.03778783604502678,
      -0.027490798383951187,
      0.013933342881500721,
      0.01552639901638031,
      -0.05283118039369583,
      0.09612026065587997,
      0.05059531331062317,
      0.03795712813735008,
      -0.034043364226818085,
      0.03875691071152687,
      0.014430426992475986,
      -0.06604304164648056,
      0.08058344572782516,
      -0.04050309583544731,
      -0.09339307248592377,
      -0.015981776639819145,
      -0.040075574070215225,
      -0.0267059113830328,
      0.08149318397045135,
      -0.05321422219276428,
      0.03829942271113396,
      -0.028996353968977928,
      -0.0244043730199337,
      0.09021829813718796,
      -0.04107639193534851,
      -0.00014592484512832016,
      -0.0015092772664502263,
      0.015043694525957108,
      -0.06508513540029526,
      0.021157898008823395,
      0.0023228100035339594,
      -0.0290326327085495,
      -0.03569021821022034,
      -0.006696090567857027,
      0.006698247976601124,
      0.017101727426052094,
      0.030898571014404297,
      0.01080792210996151,
      -0.04002245143055916,
      -0.011476190760731697,
      0.006551783997565508,
      -0.031221719458699226,
      -0.026925869286060333,
      -0.0712098702788353,
      -0.07099726051092148,
      -0.023743994534015656,
      -0.05052635073661804,
      -0.012402653694152832,
      0.046860143542289734,
      -0.04797836020588875,
      -0.10162393748760223,
      -0.037360429763793945,
      -0.1208176389336586,
      -0.0031261898111552,
      -0.05447530001401901,
      -0.02882406860589981,
      -0.021229123696684837,
      -0.03969838097691536,
      0.07159403711557388,
      -0.019164886325597763,
      0.08384629338979721,
      0.001850258675403893,
      -0.03465054929256439,
      -0.05874447897076607,
      -0.007479035295546055,
      0.008695618249475956,
      -0.049466829746961594,
      -0.07142330706119537,
      0.024598075076937675,
      0.08888114988803864,
      0.03633405640721321,
      0.02813982404768467,
      -0.05656851828098297,
      0.04211898148059845,
      0.05989914387464523,
      -0.020063968375325203,
      -0.08662452548742294,
      0.0060008205473423,
      -0.07111289352178574,
      -0.03198200464248657,
      -0.0391094833612442,
      -0.015589236281812191,
      -0.03807368874549866,
      -0.02909635752439499,
      -0.04520684480667114,
      -0.07154668867588043,
      0.03285340219736099,
      0.024428285658359528,
      0.007249439600855112,
      0.03965609148144722,
      0.02152945101261139,
      0.022985152900218964,
      0.15165358781814575,
      -0.012687974609434605,
      -0.023330094292759895,
      -0.02057909406721592,
      -0.0016109556891024113,
      -0.008793449960649014,
      -0.02595747821033001,
      0.07852520793676376,
      -0.04185391589999199,
      -0.0805814266204834,
      0.0028822990134358406,
      -0.09461592882871628,
      -0.06234448030591011,
      0.018481168895959854,
      0.014620454050600529,
      0.0013113942695781589,
      0.0077837808057665825,
      -0.0030958224087953568,
      -0.04958917573094368,
      -0.008921007625758648,
      0.005599421914666891,
      0.06088802218437195,
      -0.00209126528352499,
      -0.059808600693941116,
      0.02206510305404663,
      -0.00038938724901527166,
      -0.03726670891046524,
      0.08830296248197556,
      -0.01407337561249733,
      0.054134201258420944,
      -0.02866259403526783,
      -0.02999717928469181,
      0.030067047104239464,
      0.021421801298856735,
      -0.10857481509447098,
      -0.020307667553424835,
      -0.021569017320871353,
      -0.00515155540779233,
      0.03956960514187813,
      -0.019515614956617355,
      0.05057857185602188,
      -0.007044472265988588,
      -0.004691207315772772,
      0.03281600400805473,
      -0.000590511248447001,
      0.01325314398854971,
      -0.002448431681841612,
      0.013016045093536377,
      0.03524080663919449,
      0.029201475903391838,
      -0.03381502255797386,
      0.05473042279481888,
      -0.07823864370584488,
      -0.006151110399514437,
      -0.18238580226898193,
      0.020284295082092285,
      0.004531208425760269,
      0.06532178074121475,
      0.043795887380838394,
      0.025090478360652924,
      0.049186140298843384,
      0.03593343868851662,
      -0.05801858752965927,
      0.040174033492803574,
      -0.01010177657008171,
      -0.02929380163550377,
      -0.08665711432695389,
      0.023251155391335487,
      0.07313469052314758,
      0.0419798381626606,
      -0.03734389692544937,
      0.03404046222567558,
      -0.004847708623856306,
      0.06123288348317146,
      0.014672852121293545,
      0.03765449300408363,
      0.0471014529466629,
      0.025980504229664803,
      -0.010932174511253834,
      -0.07626461237668991,
      -0.052401866763830185,
      0.04204504191875458,
      -0.01867443323135376,
      -0.01920454390347004,
      -0.08109712600708008,
      0.0240598376840353,
      -0.08033271878957748,
      0.0214103814214468,
      0.08379785716533661,
      0.04393887147307396,
      0.0961822047829628,
      -0.008373594842851162,
      0.015150696970522404,
      0.000335786520736292,
      0.05942150205373764,
      0.009537988342344761,
      0.01026599109172821,
      0.046458031982183456,
      -0.05234123021364212,
      -0.012130830436944962,
      0.04043452814221382,
      -0.003980583511292934,
      0.0018363756826147437,
      -0.009839282371103764,
      -0.03020610846579075,
      -0.007945241406559944,
      -0.03428953140974045,
      -0.02356722019612789,
      -0.02859693393111229,
      0.05883711576461792
    ],
    [
      -0.016708575189113617,
      -0.014276858419179916,
      -0.023945050314068794,
      -0.05891810357570648,
      0.05051073431968689,
      -0.020057758316397667,
      0.029915019869804382,
      0.022197725251317024,
      0.012284094467759132,
      0.027683841064572334,
      -0.0006703936960548162,
      -0.028444534167647362,
      -0.03796224668622017,
      -0.01349632814526558,
      0.04882650077342987,
      0.0837438702583313,
      -0.04008921608328819,
      0.06510357558727264,
      0.05724870413541794,
      0.1089051365852356,
      -0.026384636759757996,
      -0.04155489429831505,
      -0.03233836591243744,
      0.048484280705451965,
      0.0007200692198239267,
      0.04461255669593811,
      -0.060931965708732605,
      -0.0076006692834198475,
      -0.01500436570495367,
      -0.003451073542237282,
      -0.031801290810108185,
      -0.0640711560845375,
      -0.033054597675800323,
      -0.03808116167783737,
      -0.06685151904821396,
      0.043015044182538986,
      -0.08752211183309555,
      -0.056856922805309296,
      0.03585442155599594,
      -0.021080179139971733,
      -0.021280935034155846,
      0.05402762070298195,
      0.030708536505699158,
      -0.042583368718624115,
      -0.040503233671188354,
      0.03936942666769028,
      0.034132715314626694,
      0.015199130401015282,
      0.007757952436804771,
      0.06684386730194092,
      0.039626773446798325,
      -0.04585663601756096,
      0.019115032628178596,
      -0.030087662860751152,
      0.0038980550598353148,
      0.05241713300347328,
      0.102268747985363,
      -0.05493279546499252,
      -0.001777637517079711,
      0.014774221926927567,
      -0.05666305497288704,
      0.01357817742973566,
      -0.01907183602452278,
      -0.05074450373649597,
      -0.060282152146101,
      0.03218681737780571,
      0.044982537627220154,
      0.0016616977518424392,
      -0.006028345320373774,
      0.04641435667872429,
      -0.018471790477633476,
      -0.003882309887558222,
      0.00997941941022873,
      0.03435603529214859,
      -0.010748271830379963,
      -0.045329414308071136,
      0.014889853075146675,
      0.054681383073329926,
      -0.02617548778653145,
      0.04848296195268631,
      -0.014101782813668251,
      0.017326906323432922,
      0.04787322133779526,
      -0.039028771221637726,
      0.025891568511724472,
      -0.015127550810575485,
      0.0018230278510600328,
      -0.0020837849006056786,
      -0.019372088834643364,
      0.017237259075045586,
      0.03570203483104706,
      0.04135425016283989,
      -0.044687751680612564,
      -0.020566733554005623,
      -0.026219667866826057,
      -0.002024443820118904,
      -0.0419720783829689,
      -0.04956018552184105,
      0.10651449859142303,
      -0.00878233928233385,
      -0.028341777622699738,
      0.05754740163683891,
      -0.054312869906425476,
      0.11624104529619217,
      -0.0361890085041523,
      0.0032248017378151417,
      0.048428647220134735,
      -0.03206760808825493,
      -0.003976775798946619,
      -0.05076171085238457,
      0.02786773443222046,
      0.016866402700543404,
      -0.03654884546995163,
      0.018955953419208527,
      0.04077151045203209,
      0.045051056891679764,
      -0.09086660295724869,
      -0.009575195610523224,
      -0.05255735665559769,
      -0.10563916712999344,
      -0.020387649536132812,
      -0.025909313932061195,
      0.02874596416950226,
      -0.0141880689188838,
      -0.028817595914006233,
      -0.05732056125998497,
      -0.008870149962604046,
      -0.008713201619684696,
      0.017950942739844322,
      -0.013944929465651512,
      0.011899912729859352,
      -0.06305234134197235,
      -0.012028301134705544,
      -0.006958057172596455,
      0.00011900231766048819,
      -0.048366814851760864,
      -0.027627062052488327,
      -0.0025693103671073914,
      -0.08613109588623047,
      -0.04333200305700302,
      -0.053718771785497665,
      -0.041364651173353195,
      -0.05073975771665573,
      -0.03459275886416435,
      0.01718805357813835,
      -0.001090288977138698,
      -0.0017386864637956023,
      -0.08117713034152985,
      0.039344578981399536,
      0.0428081750869751,
      0.02546289563179016,
      0.024500954896211624,
      0.007513695862144232,
      0.09930383414030075,
      -0.019012300297617912,
      0.07039863616228104,
      -9.076426067622378e-05,
      0.0002296632737852633,
      -0.028212951496243477,
      -0.00424319738522172,
      0.015780480578541756,
      -0.0043450999073684216,
      0.04963427409529686,
      -0.033418815582990646,
      -0.050252389162778854,
      0.010447154752910137,
      -0.02080770581960678,
      0.029750550165772438,
      -0.010880829766392708,
      0.06512800604104996,
      0.08104106783866882,
      0.0736229419708252,
      -0.06459064036607742,
      0.0089457081630826,
      -0.11099546402692795,
      0.022800447419285774,
      0.0363348051905632,
      -0.08523015677928925,
      0.05904775857925415,
      -0.03563011810183525,
      0.05042647942900658,
      0.03343015909194946,
      -0.022230835631489754,
      -0.045051008462905884,
      0.012483399361371994,
      0.021782901138067245,
      0.04422587901353836,
      0.006006424315273762,
      0.019627267494797707,
      -0.08744576573371887,
      0.021943259984254837,
      0.03141912817955017,
      -0.0014663180336356163,
      -0.04870527610182762,
      -0.02333458513021469,
      -0.039707817137241364,
      -0.059067677706480026,
      0.016485201194882393,
      -0.05636407434940338,
      0.005637656897306442,
      -0.10526669770479202,
      -0.012066359631717205,
      -0.02612459845840931,
      0.06264372915029526,
      0.04342588782310486,
      0.07568452507257462,
      -0.05994974449276924,
      -0.030101820826530457,
      0.032260771840810776,
      0.06193374842405319,
      0.0068327621556818485,
      0.06958304345607758,
      -0.032018523663282394,
      0.015130887739360332,
      -0.007625355385243893,
      -0.013196667656302452,
      0.01487045083194971,
      -0.0455036535859108,
      0.07021937519311905,
      -0.017583724111318588,
      -0.02528701163828373,
      0.06849907338619232,
      0.050819262862205505,
      -0.025083599612116814,
      0.020991778001189232,
      -0.02735055796802044,
      -0.00402423832565546,
      0.06776101887226105,
      -0.007548549212515354,
      0.004527137614786625,
      -0.027497241273522377,
      -0.05836724117398262,
      -0.02099466510117054,
      0.022791733965277672,
      -0.10894953459501266,
      0.00869664829224348,
      0.01671435683965683,
      -0.06244416534900665,
      -0.056481994688510895,
      -0.0455612950026989,
      0.0008868954610079527,
      -0.051543883979320526,
      -0.03168484568595886,
      -0.052747081965208054,
      0.04449592903256416,
      -0.008104916661977768,
      -0.0490424744784832,
      0.01979682594537735,
      -0.11006977409124374,
      0.026205245405435562,
      -0.03304252400994301,
      -0.030775707215070724,
      -0.024722425267100334,
      -0.06454018503427505,
      0.11128968000411987,
      -0.004606745205819607,
      0.0402216799557209,
      -0.04578708857297897,
      -0.07077569514513016,
      0.017403222620487213,
      -0.0981278046965599,
      -0.05586247518658638,
      -0.03730987384915352,
      0.00829396303743124,
      -0.014686872251331806,
      -0.038944657891988754,
      -0.003586764447391033,
      -0.00691475672647357,
      -0.09022241085767746,
      -0.02656976319849491,
      -0.03305976465344429,
      -0.007601379882544279,
      0.0022692917846143246,
      0.02070101909339428,
      0.04218839854001999,
      -0.03362308070063591,
      0.013107828795909882,
      -0.0025795078836381435,
      -0.001024232362397015,
      0.00591117050498724,
      0.08844975382089615,
      -0.02958720549941063,
      0.018874717876315117,
      -0.03818441182374954,
      0.006831965874880552,
      -0.012305092997848988,
      0.009882158599793911,
      -0.04514981806278229,
      0.03621756285429001,
      -0.016688629984855652,
      -0.029151463881134987,
      -0.0006087000947445631,
      0.017745573073625565,
      0.005361303221434355,
      -0.08089647442102432,
      0.023745324462652206,
      -0.11497732996940613,
      0.014703591354191303,
      0.041673172265291214,
      0.034439973533153534,
      0.005793314892798662,
      -0.05563110113143921,
      0.07741936296224594,
      0.07573419064283371,
      -0.00013498043699655682,
      0.021095704287290573,
      -0.035321030765771866,
      0.02879512682557106,
      0.01733122020959854,
      0.03100544959306717,
      0.03691038861870766,
      0.03097456321120262,
      -0.08140841126441956,
      0.03366849943995476,
      -0.0697292760014534,
      -0.014156417921185493,
      -0.01911068893969059,
      0.0017357157776132226,
      -0.05618990957736969,
      0.05708301067352295,
      -0.019401121884584427,
      0.02436361275613308,
      -0.0049529061652719975,
      0.05003200098872185,
      -0.04801074415445328,
      -0.02163701131939888,
      0.03572923690080643,
      0.007851121947169304,
      -0.050532978028059006,
      0.1034453958272934,
      -0.024002499878406525,
      -0.019429584965109825,
      -0.06180276349186897,
      0.008168947882950306,
      -0.01574326492846012,
      0.004378731362521648,
      -0.004364578984677792,
      -0.09941942989826202,
      -0.012676218524575233,
      -0.10244482010602951,
      0.05348121002316475,
      -0.015463537536561489,
      -0.0015037708217278123,
      -0.010960971005260944,
      -0.024571942165493965,
      -0.03352087363600731,
      -0.01571543514728546,
      0.004928838927298784,
      -0.04666481167078018,
      0.05327533930540085,
      0.048114702105522156,
      -0.024298276752233505,
      0.003980807028710842,
      -0.007975875400006771,
      -0.008472207002341747,
      0.06205136328935623,
      -0.015356254763901234,
      0.0033034407533705235,
      -0.028833452612161636,
      -0.00656937388703227,
      0.0417252853512764,
      -0.03341937065124512,
      -0.07757177203893661,
      0.053565915673971176,
      -0.012279864400625229,
      0.019202634692192078,
      0.029031556099653244,
      -0.05662088841199875,
      0.03818119317293167,
      0.017714031040668488,
      0.07933071255683899,
      0.03142160177230835,
      0.04109800234436989,
      -0.05323764681816101,
      -0.02101084031164646,
      0.03106137178838253,
      -0.0583270862698555,
      -0.06162550300359726,
      0.03363965079188347,
      -0.09921218454837799,
      -0.00631903950124979,
      0.025464249774813652,
      -0.05817142128944397,
      0.05498282611370087,
      -0.03996271267533302,
      -0.062198299914598465,
      0.02581300213932991,
      0.04584735259413719,
      -0.05345901846885681,
      0.014501414261758327,
      -0.015308965928852558,
      -0.013200457207858562,
      0.06273606419563293,
      0.0003675284096971154,
      0.020785683766007423,
      -0.019729124382138252,
      -0.005260674748569727,
      -0.029345380142331123,
      0.015989508479833603,
      -0.03756445646286011,
      0.012692879885435104,
      0.03689500689506531,
      -0.02252153307199478,
      -0.017584996297955513,
      0.008991236798465252,
      -0.10592962056398392,
      0.03619518503546715,
      -0.023557504639029503,
      -0.061568547040224075,
      0.04198627173900604,
      -0.011233409866690636,
      -0.008332121185958385,
      -0.015059989877045155,
      -0.01905035600066185,
      -0.00011492032353999093,
      0.03390511870384216,
      0.042839016765356064,
      0.014257842674851418,
      0.02607864886522293,
      0.010409832932054996,
      0.0046959491446614265,
      0.03722022846341133,
      0.08354278653860092,
      0.01115978229790926,
      -0.01630813628435135,
      -0.0638129785656929,
      0.00882700551301241,
      -0.047400880604982376,
      0.007092102896422148,
      -0.00015723693650215864,
      -0.003357944078743458,
      -0.014918449334800243,
      0.0543891005218029,
      -0.009520947001874447,
      0.0964302271604538,
      -0.01819770038127899,
      -0.009023037739098072,
      -0.042832110077142715,
      0.025825470685958862,
      0.02228735014796257,
      -0.03505614027380943,
      0.0981210470199585,
      0.0557425394654274,
      -0.017258282750844955,
      -0.020464103668928146,
      0.1238236352801323,
      0.020226942375302315,
      -0.0011024944251403213,
      0.03596848249435425,
      -0.02331254631280899,
      0.008542293682694435,
      0.04418332129716873,
      -0.01781640015542507,
      -0.01729205623269081,
      0.013669255189597607,
      0.005097229965031147,
      -0.00816945731639862,
      -0.025804530829191208,
      0.021539660170674324,
      -0.03595361486077309,
      0.08232631534337997,
      0.0214662104845047,
      0.007804329507052898,
      0.03513198718428612,
      -0.042315494269132614,
      -0.04644501581788063,
      -0.02191339060664177,
      0.014356659725308418,
      -0.0632510706782341,
      -0.012446128763258457,
      -0.014508245512843132,
      0.053588710725307465,
      0.033134669065475464,
      0.006700429134070873,
      0.06345343589782715,
      -0.03354910761117935,
      -0.03652256354689598,
      -0.02823268435895443,
      -0.036940593272447586,
      0.02874269336462021,
      0.03568265214562416,
      -0.024423668161034584,
      0.007400815840810537,
      0.17351123690605164,
      -0.062021881341934204,
      0.0394192710518837,
      -0.05990079045295715,
      0.0008946144953370094,
      -0.11114248633384705,
      0.04656065255403519,
      0.04986513778567314,
      -0.027263112366199493,
      -0.04077873006463051,
      -0.03033910132944584,
      -0.039500974118709564,
      -0.03270159661769867,
      -0.027124052867293358,
      0.014158537611365318,
      0.04091514274477959,
      -0.0011415914632380009,
      0.017760785296559334,
      -0.048071347177028656,
      -0.0123536242172122
    ],
    [
      0.06049264222383499,
      -0.04185519367456436,
      -0.05086066573858261,
      -0.05517848953604698,
      -0.02680961601436138,
      -0.03148205950856209,
      0.028086313977837563,
      -0.009625513106584549,
      -0.06828107684850693,
      0.04820616915822029,
      -0.0059444112703204155,
      -0.019047891721129417,
      -0.04739367589354515,
      0.015806108713150024,
      0.07309301942586899,
      0.020594412460923195,
      0.02703000046312809,
      0.05774446204304695,
      -0.03290180116891861,
      -0.023256810382008553,
      0.03685813024640083,
      0.10399496555328369,
      -0.04429500922560692,
      0.10280654579401016,
      0.058506932109594345,
      0.02446557767689228,
      -0.05920110642910004,
      0.024022351950407028,
      -0.0015163959469646215,
      0.04002003371715546,
      0.05081786587834358,
      -0.043441787362098694,
      -0.06590751558542252,
      -0.01877838745713234,
      -0.04027685895562172,
      0.038997583091259,
      0.04295660927891731,
      -6.095710887166206e-07,
      -0.08462641388177872,
      0.019725505262613297,
      -0.04144563525915146,
      -0.029942791908979416,
      0.036547914147377014,
      0.00419526407495141,
      -0.04653514176607132,
      0.0595984123647213,
      0.03442007675766945,
      0.015623154118657112,
      0.08250367641448975,
      0.01979733631014824,
      -0.019966499879956245,
      0.08032708615064621,
      -0.009104362688958645,
      0.04964352399110794,
      0.017911313101649284,
      0.11617636680603027,
      0.08169186115264893,
      -0.08940521627664566,
      0.054009243845939636,
      0.0939769372344017,
      0.016436927020549774,
      0.015768785029649734,
      -0.003481119405478239,
      0.005225697997957468,
      -0.0064364043064415455,
      -0.0350804477930069,
      -0.036029305309057236,
      -0.03859145939350128,
      0.0036455520894378424,
      0.02198086492717266,
      -0.09854227304458618,
      -0.022627336904406548,
      -0.044359732419252396,
      0.021975431591272354,
      -0.010563899762928486,
      -0.01335762906819582,
      -0.005129148252308369,
      0.05021047219634056,
      -0.013945026323199272,
      -0.03205820545554161,
      0.048926737159490585,
      0.08611919730901718,
      -0.03988474979996681,
      -0.033762697130441666,
      0.023423489183187485,
      -0.014032411389052868,
      -0.046845611184835434,
      -0.024834133684635162,
      -0.01674257591366768,
      0.041662897914648056,
      0.03132577985525131,
      0.011531200259923935,
      -0.024377785623073578,
      0.02344595082104206,
      -0.047895364463329315,
      0.004527585115283728,
      -0.019930774345993996,
      -0.029783423990011215,
      0.005403220187872648,
      -0.017102140933275223,
      0.02238205075263977,
      -0.020612521097064018,
      -0.05378182604908943,
      0.017628412693738937,
      -0.03911299258470535,
      -0.006082587875425816,
      -0.03981684893369675,
      0.0009767902083694935,
      0.03394314646720886,
      0.04473889619112015,
      -0.015358439646661282,
      -0.012397436425089836,
      -0.024682706221938133,
      -0.022134797647595406,
      0.04869690164923668,
      0.008982306346297264,
      -0.015733137726783752,
      -0.01946381665766239,
      -0.081104576587677,
      -0.04098619520664215,
      -0.07926119863986969,
      -0.005223351530730724,
      -0.030561691150069237,
      -0.044762302190065384,
      0.03206764906644821,
      -0.045367512851953506,
      -0.011413400992751122,
      -0.040677253156900406,
      0.0030521296430379152,
      -0.020786991342902184,
      0.03189406916499138,
      -0.0029794799629598856,
      0.060953859239816666,
      0.024253876879811287,
      -0.025508055463433266,
      -0.07564596086740494,
      0.04760998860001564,
      -0.03221609443426132,
      0.0041426257230341434,
      0.0018227328546345234,
      -0.0421888642013073,
      0.019260553643107414,
      0.04928886145353317,
      -0.009192085824906826,
      -0.01304546557366848,
      0.058941550552845,
      -0.04526439681649208,
      -0.03064640425145626,
      -0.058381177484989166,
      -0.014334995299577713,
      -0.06435970962047577,
      0.00823292788118124,
      0.053511280566453934,
      0.040012069046497345,
      0.002271372592076659,
      0.010852846316993237,
      0.014852490276098251,
      0.04942392185330391,
      -0.059414032846689224,
      -0.020543985068798065,
      0.07987143099308014,
      -0.031303081661462784,
      0.0024712258018553257,
      0.020421721041202545,
      -0.031177930533885956,
      -0.1421186327934265,
      0.0020961910486221313,
      -0.06100522726774216,
      -0.00832365732640028,
      0.04444633796811104,
      -0.003432176075875759,
      -0.03680441901087761,
      -0.023117385804653168,
      0.05160627141594887,
      -0.06803363561630249,
      -0.04355720430612564,
      0.04829385131597519,
      -0.017547473311424255,
      0.0857120156288147,
      -0.027015745639801025,
      -0.03695962578058243,
      -0.040098365396261215,
      -0.03496219962835312,
      -0.04575593024492264,
      0.029866967350244522,
      -0.002525517949834466,
      -0.02940591424703598,
      -0.07387394458055496,
      -0.005841352976858616,
      0.050760116428136826,
      0.029902933165431023,
      0.03507477790117264,
      -0.040692467242479324,
      0.007602259516716003,
      0.02900637313723564,
      0.03211916610598564,
      0.008504355326294899,
      -0.04826918989419937,
      0.07234495878219604,
      -0.04248839616775513,
      0.026557879522442818,
      -0.0629325658082962,
      0.02579660154879093,
      0.028109164908528328,
      0.011531226336956024,
      -0.026424646377563477,
      -0.03825734555721283,
      0.031192054972052574,
      0.028214626014232635,
      -0.11184670031070709,
      0.050504427403211594,
      0.02297457866370678,
      -0.013603117316961288,
      -0.014848945662379265,
      -0.01132233440876007,
      0.005747586488723755,
      0.022629421204328537,
      -0.07149253785610199,
      -0.03627746179699898,
      0.006507145706564188,
      -0.022957107052206993,
      -0.011232365854084492,
      0.026261499151587486,
      -0.023532696068286896,
      0.04240720719099045,
      -0.01275948528200388,
      -0.011028100736439228,
      0.06392927467823029,
      0.07382237911224365,
      0.038983915001153946,
      0.011662308126688004,
      -0.012950737960636616,
      -0.053612031042575836,
      -0.03944835811853409,
      0.006932742428034544,
      0.0131226209923625,
      0.059158965945243835,
      0.023137986660003662,
      -0.04711487516760826,
      -0.01172252930700779,
      0.05348248407244682,
      0.040736131370067596,
      -0.06450703740119934,
      0.039716847240924835,
      -0.022489400580525398,
      0.015406140126287937,
      -0.04231167584657669,
      -0.0175494197756052,
      0.005875985138118267,
      0.07712232321500778,
      -0.05705723911523819,
      0.022122016176581383,
      -0.02866450324654579,
      -0.0023733724374324083,
      0.002944646403193474,
      0.09094585478305817,
      -0.06081255152821541,
      -0.0034999570343643427,
      0.05103496089577675,
      -0.02207949385046959,
      -0.08320815861225128,
      -0.04124651476740837,
      0.006920475512742996,
      -0.13703866302967072,
      -0.06068377569317818,
      -0.04899057745933533,
      0.004125348292291164,
      -0.0006074967095628381,
      0.029531365260481834,
      0.015177316963672638,
      0.016053805127739906,
      0.0009614532464183867,
      -0.03963539004325867,
      -0.11484317481517792,
      -0.009891605004668236,
      -0.024870555847883224,
      -0.03564153239130974,
      -0.016420815140008926,
      -0.05982949212193489,
      -0.05615835636854172,
      0.02395228110253811,
      0.03067769855260849,
      -0.07280757278203964,
      0.005139497108757496,
      -0.03551488369703293,
      -0.023619873449206352,
      -0.07916965335607529,
      -0.05888482928276062,
      -0.007947627454996109,
      -0.008551347069442272,
      0.055259887129068375,
      -0.11914201080799103,
      0.031516581773757935,
      -0.02341550961136818,
      -0.03017442859709263,
      -0.010345072485506535,
      0.04057179391384125,
      0.05827653780579567,
      -0.06734530627727509,
      -0.04175180569291115,
      -0.03264357149600983,
      0.03676541894674301,
      0.027853133156895638,
      -0.05861032381653786,
      -0.011369027197360992,
      -0.06870211660861969,
      0.0267143826931715,
      -0.07853085547685623,
      -0.017110610380768776,
      0.02534007839858532,
      0.019438596442341805,
      -0.017233984544873238,
      -0.07393205910921097,
      -0.03167051076889038,
      0.06020937114953995,
      0.031003160402178764,
      -0.005105521064251661,
      -0.052500586956739426,
      -0.035049986094236374,
      -0.015259316191077232,
      0.0029918933287262917,
      0.029667561873793602,
      0.010988897643983364,
      -0.08538118749856949,
      0.07745763659477234,
      -0.03938313573598862,
      -0.01642609015107155,
      -0.009062391705811024,
      -0.005111938342452049,
      -0.005163706839084625,
      0.01581477001309395,
      0.08266735076904297,
      0.026186946779489517,
      -0.039191681891679764,
      -0.009672838263213634,
      0.008468912914395332,
      -0.034548014402389526,
      0.09505696594715118,
      0.029031364247202873,
      0.014119300059974194,
      -0.046589337289333344,
      0.026857974007725716,
      -0.12722161412239075,
      0.005823132116347551,
      0.01909610442817211,
      -0.022838009521365166,
      -0.017383050173521042,
      -0.017988985404372215,
      -0.0002865791320800781,
      -0.07029610127210617,
      0.02229710854589939,
      0.016102487221360207,
      0.03600933030247688,
      -0.04320861026644707,
      0.01037969533354044,
      -0.0391242578625679,
      -0.014153623953461647,
      -0.03900705650448799,
      0.0005550935165956616,
      -0.03583745285868645,
      -0.0629546269774437,
      -0.04739875718951225,
      -0.045576877892017365,
      -0.0009857824770733714,
      -0.0384797528386116,
      0.011033250950276852,
      -0.030507735908031464,
      0.1004854068160057,
      0.024318477138876915,
      0.010593168437480927,
      0.05783160775899887,
      -0.03102065436542034,
      -0.012827922590076923,
      -0.03311112895607948,
      0.025186477228999138,
      0.06291215121746063,
      0.013045348227024078,
      0.0305876936763525,
      0.052383750677108765,
      0.00713777681812644,
      -0.02333160489797592,
      0.121158167719841,
      0.04876701906323433,
      0.08622969686985016,
      0.04679360240697861,
      0.025882910937070847,
      0.02347596548497677,
      -0.030752701684832573,
      -0.013228061608970165,
      -0.02182486094534397,
      -0.06539848446846008,
      0.029954981058835983,
      -0.026557128876447678,
      -0.04236174374818802,
      -0.040964797139167786,
      0.014659689739346504,
      -0.02986386977136135,
      -0.05002361536026001,
      0.007495152298361063,
      -0.018197860568761826,
      -0.030955741181969643,
      0.019718393683433533,
      0.03793105110526085,
      -0.00334702106192708,
      0.016737524420022964,
      0.0200902558863163,
      0.029344681650400162,
      0.028914468362927437,
      -0.04299749433994293,
      -0.11365102976560593,
      0.06623721122741699,
      0.022562192752957344,
      -0.010278032161295414,
      0.08106249570846558,
      -0.0009133170242421329,
      -0.005788386333733797,
      0.012034826911985874,
      -0.08153611421585083,
      0.030799556523561478,
      -0.015412251465022564,
      0.05773458629846573,
      -0.01616283319890499,
      0.04354453459382057,
      0.02033434808254242,
      -0.05584757775068283,
      0.00548334838822484,
      0.008617767132818699,
      -0.020911620929837227,
      0.02315502054989338,
      0.031024502590298653,
      0.04379644617438316,
      0.0327930711209774,
      -0.022988902404904366,
      -0.006695443764328957,
      0.11473102867603302,
      -0.021727848798036575,
      -0.03997864946722984,
      -0.07342511415481567,
      0.020901590585708618,
      -0.037716154009103775,
      -0.051800623536109924,
      0.0342264361679554,
      -0.024645356461405754,
      -0.004729194566607475,
      -0.02924555167555809,
      0.07367819547653198,
      -0.08346075564622879,
      0.07323073595762253,
      -0.044750407338142395,
      -0.005244597792625427,
      -0.051531385630369186,
      -0.04559982568025589,
      -0.0004974368494004011,
      0.0756259560585022,
      0.048332810401916504,
      -0.045591142028570175,
      -0.0027525455225259066,
      -0.038693297654390335,
      -0.004261563997715712,
      0.0044619496911764145,
      0.011032700538635254,
      0.052202798426151276,
      0.03547576069831848,
      0.0747927725315094,
      -0.08409865200519562,
      -0.05797537416219711,
      -0.02324288710951805,
      -0.018979985266923904,
      0.0031875455752015114,
      0.07637691497802734,
      0.018938390538096428,
      0.018975505605340004,
      0.09729177504777908,
      -0.0250745490193367,
      0.03690848872065544,
      0.058374397456645966,
      -0.026791784912347794,
      0.013042362406849861,
      -0.06634526699781418,
      -0.027929825708270073,
      0.04653386399149895,
      0.03696500137448311,
      -0.03826083615422249,
      -0.028847413137555122,
      0.01436755619943142,
      -0.04242749512195587,
      -0.0022136191837489605,
      -0.04618176445364952,
      0.041982199996709824,
      0.03614442050457001,
      0.1381516456604004,
      -0.04643955081701279,
      -0.004772537387907505,
      -0.016626305878162384,
      -0.02990947663784027,
      0.05670107528567314,
      0.0024891444481909275,
      -0.1000458151102066,
      0.03885604441165924,
      0.04333961382508278,
      -0.08857621997594833,
      -0.07634188234806061,
      0.05875352397561073
    ],
    [
      -0.02893458679318428,
      0.08454462140798569,
      0.023677796125411987,
      -0.002798656467348337,
      -0.0035785632207989693,
      -0.10020102560520172,
      0.02728157863020897,
      0.03719615563750267,
      -0.08823677897453308,
      0.021691827103495598,
      0.04573730379343033,
      -0.0008984013111330569,
      0.04466642811894417,
      0.010300513356924057,
      0.10880501568317413,
      0.0010062902001664042,
      -0.010490288026630878,
      0.032476529479026794,
      0.01626073569059372,
      0.0148748978972435,
      0.12026698887348175,
      -0.02864972874522209,
      0.008412744849920273,
      0.005411368794739246,
      0.14716072380542755,
      -0.06114007160067558,
      -0.03482520207762718,
      0.024166693910956383,
      0.05124342814087868,
      -0.03537006303668022,
      -0.04097626730799675,
      0.03764588013291359,
      -0.055915556848049164,
      -0.06408361345529556,
      -0.06385573744773865,
      0.00207389029674232,
      0.08898214250802994,
      -0.07355163246393204,
      -0.025675788521766663,
      -0.06812748312950134,
      -0.077748142182827,
      0.06831781566143036,
      0.010810445994138718,
      -0.03055061399936676,
      -0.0009707061108201742,
      0.06613057851791382,
      0.05326524376869202,
      0.028400719165802002,
      -0.017372291535139084,
      0.02530231699347496,
      -0.0746363028883934,
      0.05727463215589523,
      -0.08085165172815323,
      0.07696140557527542,
      0.017310967668890953,
      -0.026317832991480827,
      0.03258338198065758,
      -0.011775090359151363,
      0.020967459306120872,
      0.01889396458864212,
      0.025360120460391045,
      -0.0693974420428276,
      0.05816684663295746,
      0.032876573503017426,
      0.005909530445933342,
      -0.003472164971753955,
      0.04192870110273361,
      0.029134169220924377,
      -0.011206644587218761,
      0.055853452533483505,
      0.034243401139974594,
      -0.01934477500617504,
      -0.026316052302718163,
      0.003952250815927982,
      -0.030536744743585587,
      0.09419690817594528,
      -0.0030858812388032675,
      0.022179236635565758,
      0.01474770251661539,
      0.0059507302939891815,
      0.034676626324653625,
      0.007086769677698612,
      0.0158110149204731,
      0.0001006383536150679,
      -0.06973561644554138,
      0.01933729276061058,
      -0.11815749108791351,
      0.06632855534553528,
      0.056832537055015564,
      0.03085317835211754,
      0.0032086987048387527,
      -0.050568368285894394,
      -0.05774744972586632,
      0.01858733966946602,
      0.005409779027104378,
      -0.10306849330663681,
      -0.07859534025192261,
      0.003135214326903224,
      -0.06295198947191238,
      -0.039666105061769485,
      -0.031555913388729095,
      -0.017726896330714226,
      -0.005171146243810654,
      -0.026503190398216248,
      -0.04220322147011757,
      0.05564845725893974,
      -0.03445306792855263,
      0.02980910986661911,
      -0.009567349217832088,
      0.015116111375391483,
      -0.010092944838106632,
      -0.06279753148555756,
      0.03040153533220291,
      0.06422340124845505,
      0.04148005694150925,
      0.058449577540159225,
      -0.0012696204939857125,
      0.0017798804910853505,
      -0.03396441414952278,
      -0.016353880986571312,
      -0.04270625859498978,
      0.029303375631570816,
      -0.029068652540445328,
      -0.032647546380758286,
      0.03956114873290062,
      0.02647092379629612,
      -0.015495861880481243,
      -0.011533819139003754,
      -0.0040610055439174175,
      0.02613244019448757,
      -0.06969667226076126,
      -0.07364536076784134,
      -0.030949316918849945,
      -0.005382410250604153,
      -0.06067381799221039,
      0.025733498856425285,
      0.016369733959436417,
      0.007943842560052872,
      0.019160879775881767,
      -0.0031619886867702007,
      -0.011198995634913445,
      -0.06234780326485634,
      0.019370952621102333,
      -0.013496954925358295,
      -0.0038789957761764526,
      0.0765204057097435,
      -0.06649622321128845,
      -0.0018215429736301303,
      0.06767361611127853,
      -0.006869673728942871,
      -0.0373331755399704,
      -0.005926136858761311,
      -0.044517889618873596,
      0.040680065751075745,
      -0.00034302426502108574,
      -0.056990690529346466,
      -0.002726784674450755,
      -0.054020773619413376,
      -0.01537693664431572,
      0.02540759928524494,
      0.015456470660865307,
      -0.004124991595745087,
      -0.04541276395320892,
      0.05261627584695816,
      0.03706580027937889,
      -0.08664928376674652,
      -0.049406498670578,
      -0.00987826008349657,
      0.008692078292369843,
      -0.042173683643341064,
      -0.017451148480176926,
      -0.06292345374822617,
      -0.051099639385938644,
      0.015764771029353142,
      0.09548076242208481,
      0.008184113539755344,
      -0.03633488714694977,
      -0.09261883795261383,
      0.018582211807370186,
      -0.04416629299521446,
      0.05720540136098862,
      -0.005145980976521969,
      -0.009906014427542686,
      0.0559954009950161,
      -0.04898408427834511,
      -0.03427388519048691,
      0.039769258350133896,
      -0.028809653595089912,
      0.020113257691264153,
      0.020399950444698334,
      0.007264523766934872,
      -0.011740163899958134,
      0.09448114782571793,
      -0.03308296576142311,
      -0.023717740550637245,
      -0.030267920345067978,
      -0.026283346116542816,
      0.044271163642406464,
      0.05078715831041336,
      0.042065881192684174,
      -0.041833728551864624,
      -0.003223584732040763,
      -0.11223642528057098,
      -0.07367397844791412,
      -0.015829533338546753,
      0.050169091671705246,
      0.05233652517199516,
      0.0018361181719228625,
      0.14499492943286896,
      0.02739521488547325,
      0.0070798820815980434,
      0.037251804023981094,
      -0.008925382047891617,
      0.05435570701956749,
      -0.03691897168755531,
      0.005884676706045866,
      -0.08003846555948257,
      0.0034159680362790823,
      -0.018329845741391182,
      0.048468217253685,
      0.0036974793765693903,
      -0.036943819373846054,
      0.027556613087654114,
      0.03683187812566757,
      -0.06902637332677841,
      -0.064321368932724,
      0.016754871234297752,
      0.008937147445976734,
      0.002173826564103365,
      -0.005680900067090988,
      0.08208779245615005,
      0.009120055474340916,
      -0.05656788870692253,
      0.05428623780608177,
      -0.030796267092227936,
      0.00473414221778512,
      -0.13978902995586395,
      -0.07015150785446167,
      -0.003523630788549781,
      0.07984088361263275,
      0.09186115115880966,
      0.013942413963377476,
      -0.08052556216716766,
      0.02783556655049324,
      -0.01830146089196205,
      0.02593703381717205,
      0.035915471613407135,
      0.10086546838283539,
      -0.021814100444316864,
      0.002120151650160551,
      0.05349420756101608,
      0.03854578733444214,
      0.04932970926165581,
      0.0543329082429409,
      0.05846294388175011,
      0.04997475817799568,
      0.0319904088973999,
      -0.028870759531855583,
      0.08023476600646973,
      0.021196676418185234,
      0.00949249416589737,
      0.018270811066031456,
      -0.055490490049123764,
      -0.09927459061145782,
      -0.00542374886572361,
      0.03060397133231163,
      0.047234002500772476,
      -0.011672652326524258,
      -0.08244860917329788,
      -0.0649123340845108,
      -0.002134164795279503,
      -0.002889664378017187,
      0.026723766699433327,
      0.014403801411390305,
      -0.020556479692459106,
      -0.005356930661946535,
      -0.07907502353191376,
      0.032529644668102264,
      0.006717261858284473,
      0.007992774248123169,
      0.019143974408507347,
      0.003896384732797742,
      0.039787184447050095,
      -0.023567669093608856,
      -0.03748214989900589,
      0.10441139340400696,
      -0.059087056666612625,
      -0.058888863772153854,
      -0.08737091720104218,
      -0.08520987629890442,
      0.06253477931022644,
      -0.035934071987867355,
      -0.06114957109093666,
      0.02825743518769741,
      0.036288388073444366,
      -0.02248002402484417,
      0.0652177706360817,
      0.02450959011912346,
      -0.05324289947748184,
      -0.022967450320720673,
      0.009622455574572086,
      -0.055466428399086,
      -0.14056426286697388,
      0.04467606171965599,
      -0.025517867878079414,
      -0.01707407832145691,
      -0.0555013008415699,
      -0.01833672635257244,
      -0.05459418147802353,
      0.014320073649287224,
      0.06658973544836044,
      0.028755372390151024,
      -0.016590865328907967,
      -0.0038167759776115417,
      -0.0961114838719368,
      -0.014619325287640095,
      -0.00783430878072977,
      -0.003721527522429824,
      0.034852534532547,
      -0.011920333839952946,
      0.024719595909118652,
      0.0011405583936721087,
      0.08968471735715866,
      0.043014489114284515,
      0.09558484703302383,
      -0.06493794173002243,
      0.022001206874847412,
      -0.03998974338173866,
      -0.05915604531764984,
      0.06321645528078079,
      0.026645680889487267,
      0.016961680725216866,
      0.004639807622879744,
      -0.041686832904815674,
      -0.011332934722304344,
      -0.011048288084566593,
      -0.029955483973026276,
      0.04062792286276817,
      0.03449109196662903,
      -0.032862626016139984,
      0.052937448024749756,
      -0.03936709091067314,
      -0.06847695261240005,
      -0.012544753961265087,
      0.018462376669049263,
      -0.05044606328010559,
      -0.06693089753389359,
      0.0014386316761374474,
      -0.012247818522155285,
      -0.04333803430199623,
      -0.018592754378914833,
      0.05459620803594589,
      -0.044403012841939926,
      -0.07325662672519684,
      0.026331111788749695,
      -0.008629684336483479,
      -0.043210603296756744,
      0.05794108286499977,
      -0.05555221810936928,
      -0.0541326142847538,
      -0.07838771492242813,
      0.060568422079086304,
      -0.10745333880186081,
      0.020704787224531174,
      -0.0435507670044899,
      0.00731496699154377,
      -0.033013150095939636,
      -0.00020586272876244038,
      0.04074584320187569,
      0.01940516009926796,
      0.008457226678729057,
      0.013786260038614273,
      0.0008220707532018423,
      -0.06920117884874344,
      -0.00610123248770833,
      0.009568419307470322,
      -0.04202665761113167,
      0.022007478401064873,
      -0.0037699039094150066,
      0.009846159256994724,
      -0.01907438598573208,
      -0.030475113540887833,
      -0.00893072597682476,
      -0.003187428927049041,
      0.026352988556027412,
      0.0993741899728775,
      0.0315605066716671,
      -0.040366556495428085,
      -0.004753390327095985,
      0.05025678873062134,
      -0.024109087884426117,
      -0.0334128774702549,
      0.03657561540603638,
      -0.03721451386809349,
      -0.05271634832024574,
      0.020423682406544685,
      -0.025869842618703842,
      0.0037507477682083845,
      0.03889264911413193,
      0.0006213400629349053,
      0.06443473696708679,
      0.09876848757266998,
      0.04827031120657921,
      0.03135237097740173,
      -0.0581233985722065,
      0.04983389005064964,
      0.0055472273379564285,
      -0.010427307337522507,
      -0.06816220283508301,
      0.012958262115716934,
      0.04054514691233635,
      -0.006210175342857838,
      -0.023953495547175407,
      -0.03546346351504326,
      -0.05700037255883217,
      0.03269152343273163,
      -0.00415656715631485,
      -0.03693641722202301,
      0.011801992543041706,
      -0.06686300784349442,
      0.004955352749675512,
      -0.010068867355585098,
      0.017570504918694496,
      0.09862220287322998,
      0.006493249908089638,
      0.08822420239448547,
      0.01859389990568161,
      -0.0025794347748160362,
      0.022929174825549126,
      -0.00048747070832177997,
      -0.05348421260714531,
      -0.03822218254208565,
      -0.043314166367053986,
      0.028115419670939445,
      -0.037390295416116714,
      -0.021279113367199898,
      0.04891186207532883,
      0.0673140436410904,
      -0.015021084807813168,
      0.04959258809685707,
      0.05141814053058624,
      0.059762824326753616,
      -0.03964986279606819,
      -0.007155785337090492,
      0.04699233919382095,
      -0.019239187240600586,
      -0.026539145037531853,
      0.06625401973724365,
      -0.060085661709308624,
      -0.04600052535533905,
      0.10347763448953629,
      -0.021155618131160736,
      -0.11710315942764282,
      0.021069353446364403,
      -0.022563723847270012,
      -0.007224960718303919,
      -0.014699897728860378,
      0.031063299626111984,
      -0.025544272735714912,
      0.007377911824733019,
      0.0022480348125100136,
      0.03198596462607384,
      0.008066796697676182,
      -0.0342131182551384,
      -0.009470087476074696,
      -0.07823323458433151,
      -0.05326154828071594,
      -0.04025033861398697,
      -0.028920216485857964,
      0.041786935180425644,
      0.05711793154478073,
      -0.04077456146478653,
      0.0025075003504753113,
      -0.030466720461845398,
      0.012560628354549408,
      0.010592741891741753,
      -0.10690601915121078,
      0.027348609641194344,
      -0.012720447033643723,
      -0.014912673272192478,
      0.02316093258559704,
      -0.02844407595694065,
      -0.014317905530333519,
      0.042217787355184555,
      -0.01745988242328167,
      -0.06064774468541145,
      -0.11389035731554031,
      -0.04386167600750923,
      -0.005057343281805515,
      0.010430097579956055,
      -0.004195487592369318,
      0.0020597076509147882,
      -0.0024882913567125797,
      -0.04289816692471504,
      0.0281283687800169,
      0.06279171258211136,
      0.03151359781622887,
      -0.01900377869606018,
      -0.05957832187414169,
      0.07251681387424469,
      -0.059683363884687424,
      -0.0753524973988533,
      -0.06022898852825165
    ],
    [
      0.039746541529893875,
      0.05044626444578171,
      -0.0034755293745547533,
      0.04887627065181732,
      0.00784388743340969,
      -0.000250592187512666,
      -0.028004586696624756,
      -0.02448064275085926,
      -0.0029548010788857937,
      0.05994734913110733,
      -0.059788499027490616,
      -0.013514939695596695,
      0.02673603780567646,
      0.023973515257239342,
      0.012999347411096096,
      0.03537729009985924,
      0.007715415675193071,
      -0.007374756038188934,
      0.052758775651454926,
      0.06237813085317612,
      0.037505511194467545,
      -0.041620027273893356,
      0.05073508992791176,
      0.0030001739505678415,
      0.11786600202322006,
      -0.07413297146558762,
      -0.08280598372220993,
      0.015167690813541412,
      -0.002696769079193473,
      -0.1067410334944725,
      0.03184095025062561,
      0.001494062365964055,
      -0.03835245221853256,
      0.03721003979444504,
      -0.0038350329268723726,
      0.015127931721508503,
      -0.01145217940211296,
      -0.01319072674959898,
      -0.0654660314321518,
      -0.01178687997162342,
      0.0070140487514436245,
      0.009208373725414276,
      -0.009142735041677952,
      -0.06566185504198074,
      -0.050199370831251144,
      -0.018604369834065437,
      0.06916781514883041,
      0.05084758251905441,
      -0.00609940430149436,
      0.01716458611190319,
      -0.0029923664405941963,
      0.007789915427565575,
      0.08849302679300308,
      -0.04688108712434769,
      -0.06065678596496582,
      0.0640849769115448,
      0.09340514987707138,
      -0.027823099866509438,
      -0.02901737205684185,
      -0.06279288977384567,
      -0.010012389160692692,
      -0.0552254319190979,
      -0.03808632120490074,
      -0.047725610435009,
      -0.04396693408489227,
      0.004302894696593285,
      -0.1232055202126503,
      0.05171491578221321,
      -0.015795322135090828,
      0.11082509905099869,
      -0.06955181807279587,
      0.021078215911984444,
      -0.014436871744692326,
      0.00044931203592568636,
      0.043415289372205734,
      0.10419925302267075,
      -0.006335475482046604,
      0.021538173779845238,
      -0.14390011131763458,
      0.04633855074644089,
      0.042496319860219955,
      -0.014315703883767128,
      0.03999583050608635,
      0.02859116531908512,
      -0.024298738688230515,
      0.04883742332458496,
      -0.06717474013566971,
      -0.014436853118240833,
      0.03156903386116028,
      -0.04531494900584221,
      -0.04871542379260063,
      -0.07530664652585983,
      0.09850422292947769,
      -0.017218520864844322,
      -0.01232798770070076,
      -0.07257871329784393,
      -0.02268865518271923,
      0.01408286765217781,
      0.011597187258303165,
      0.015325110405683517,
      0.011315301060676575,
      0.004199395887553692,
      0.016641486436128616,
      -0.040781132876873016,
      0.08332904428243637,
      -0.005706915631890297,
      -0.04466477409005165,
      0.034757815301418304,
      0.009721312671899796,
      0.020543595775961876,
      0.021369053050875664,
      -0.004335830453783274,
      -0.06119491159915924,
      0.06711575388908386,
      0.012269803322851658,
      0.04333507642149925,
      0.032184261828660965,
      -0.08122193068265915,
      -0.03335293009877205,
      0.010103259235620499,
      -0.033754944801330566,
      0.025694197043776512,
      -0.015553349629044533,
      -0.0713176280260086,
      0.015299365855753422,
      -0.08721213787794113,
      -0.03636225312948227,
      0.05973232910037041,
      0.03873756155371666,
      -0.03587040305137634,
      -0.035157784819602966,
      0.010439991019666195,
      -0.005508439149707556,
      -0.08895387500524521,
      -0.07780464738607407,
      0.005329993087798357,
      -0.02585800550878048,
      0.04394201561808586,
      -0.030608149245381355,
      0.009200444445014,
      -0.14153209328651428,
      -0.08600424975156784,
      -0.020165344700217247,
      -0.06587333232164383,
      0.014605685137212276,
      0.1345864087343216,
      0.021582840010523796,
      0.01528441533446312,
      -0.09180447459220886,
      0.048835307359695435,
      -0.04645489901304245,
      -0.0026731460820883512,
      0.029373550787568092,
      0.005158624146133661,
      -0.022059867158532143,
      -0.002549336291849613,
      -0.015591263771057129,
      0.0149492546916008,
      -0.05543120205402374,
      0.01135306991636753,
      0.04530270770192146,
      -0.03324658051133156,
      -0.04222507029771805,
      0.015120938420295715,
      0.053149715065956116,
      0.04067838937044144,
      0.06946925818920135,
      -0.028644712641835213,
      0.01409735344350338,
      0.037997834384441376,
      -0.06856261938810349,
      -0.07559409737586975,
      -0.00535530224442482,
      0.06828715652227402,
      -0.04597873613238335,
      -0.08257494121789932,
      0.01966181769967079,
      -0.01352925319224596,
      0.017321951687335968,
      -0.0002848510048352182,
      -0.04016001895070076,
      0.059124551713466644,
      -0.058960456401109695,
      -0.014363504946231842,
      0.027262141928076744,
      0.011531260795891285,
      0.005688775330781937,
      0.0579216443002224,
      0.0039258915930986404,
      -0.003473108634352684,
      0.009467164985835552,
      0.014504685066640377,
      0.04335957020521164,
      -0.03513982146978378,
      -0.046296726912260056,
      -0.07118842750787735,
      -0.006934535689651966,
      0.00808748509734869,
      -0.03543025255203247,
      0.017481422051787376,
      0.0018852242501452565,
      0.016591377556324005,
      -0.04266747832298279,
      -0.008494031615555286,
      -0.037256475538015366,
      0.0527290403842926,
      0.0233133677393198,
      -0.05205143988132477,
      0.07152257114648819,
      0.003196029458194971,
      0.026164159178733826,
      -0.009138679131865501,
      0.015432391315698624,
      -0.02919878251850605,
      0.05309954658150673,
      -0.0013787149218842387,
      -0.009583890438079834,
      0.012293987907469273,
      -0.05844755470752716,
      0.004869819153100252,
      0.014302839525043964,
      -0.02322622947394848,
      -0.03561866655945778,
      0.014776145108044147,
      -0.04553969204425812,
      -0.016469363123178482,
      -0.013143222779035568,
      0.014366148971021175,
      0.031327392905950546,
      -0.08283790946006775,
      0.02039996348321438,
      0.05874979868531227,
      0.013035246171057224,
      0.011614506132900715,
      0.021080994978547096,
      -0.04601283743977547,
      -0.017354769632220268,
      -0.015392492525279522,
      0.009347369894385338,
      0.11725298315286636,
      -0.07611449807882309,
      -0.02421489916741848,
      -0.04118062183260918,
      0.0069480109959840775,
      0.022925235331058502,
      0.029355786740779877,
      0.00613193865865469,
      0.009418058209121227,
      0.039167214184999466,
      -0.0039156232960522175,
      0.008259526453912258,
      0.04022285342216492,
      -0.04595007002353668,
      0.04780926927924156,
      0.0708540603518486,
      0.10207031667232513,
      -0.04002789780497551,
      -0.0038247965276241302,
      0.017690112814307213,
      0.060334112495183945,
      0.02363152615725994,
      0.0034695377107709646,
      -0.01411875057965517,
      -0.10783892124891281,
      -0.009619226679205894,
      0.0884014144539833,
      0.038600821048021317,
      0.026341594755649567,
      -0.017945803701877594,
      -0.053058870136737823,
      -0.023638777434825897,
      -0.03570514917373657,
      0.030864713713526726,
      -0.0053766570053994656,
      0.08020324259996414,
      0.04315432906150818,
      -0.08426322788000107,
      -0.03098142333328724,
      0.0021736915223300457,
      -0.05579337850213051,
      0.0027150162495672703,
      -0.0314582884311676,
      -0.03350583463907242,
      -0.04451247677206993,
      -0.026344235986471176,
      -0.007742918096482754,
      0.10399217158555984,
      -0.009813454933464527,
      -0.009011202491819859,
      -0.09961223602294922,
      0.06048073619604111,
      -0.01880657859146595,
      0.0069295484572649,
      -0.0404721200466156,
      0.04895411431789398,
      -0.010541880503296852,
      0.026176903396844864,
      0.09166871011257172,
      0.017770573496818542,
      0.003983875270932913,
      -0.07007215917110443,
      -0.022375918924808502,
      0.04726933315396309,
      0.029430333524942398,
      -0.02141456864774227,
      -0.047106437385082245,
      -0.004724039230495691,
      0.06648551672697067,
      -0.050344619899988174,
      0.08082307130098343,
      -0.020302418619394302,
      0.02937275916337967,
      -0.026469266042113304,
      -0.003457798855379224,
      0.01066605281084776,
      -0.04621124640107155,
      0.030634205788373947,
      0.006823020521551371,
      0.006049716379493475,
      -0.004762414842844009,
      0.07813186943531036,
      0.033338967710733414,
      -0.002686589490622282,
      0.0320257693529129,
      0.022302277386188507,
      -0.06666456907987595,
      0.07732770591974258,
      0.05650050565600395,
      -0.030298300087451935,
      0.06842818111181259,
      -0.051492635160684586,
      0.10141407698392868,
      -0.025240184739232063,
      0.0330045148730278,
      0.013734044507145882,
      -0.055972158908843994,
      0.02622891776263714,
      0.03156612068414688,
      -0.10566649585962296,
      -0.02605786919593811,
      0.03502102568745613,
      -0.10398013889789581,
      0.02017473429441452,
      -0.035471782088279724,
      -0.050112560391426086,
      0.023798314854502678,
      -0.043145325034856796,
      -0.026313364505767822,
      0.06080168858170509,
      0.05761623755097389,
      -0.06093654781579971,
      0.018841631710529327,
      -0.04759126156568527,
      -0.04625292867422104,
      -0.011617929674685001,
      -0.013897660188376904,
      -0.06132873520255089,
      0.0035987491719424725,
      -0.07542948424816132,
      -0.08942205458879471,
      0.01470360066741705,
      0.02639438398182392,
      -0.002437986433506012,
      -0.07341822981834412,
      -0.07174407690763474,
      -0.01007902529090643,
      0.01960376463830471,
      -0.010921712964773178,
      0.03649701550602913,
      0.05008384585380554,
      0.014178149402141571,
      0.050183024257421494,
      0.06959812343120575,
      -0.04927623271942139,
      -0.0035324664786458015,
      0.11040428280830383,
      -0.0065342094749212265,
      0.021260986104607582,
      0.007474536541849375,
      0.017052708193659782,
      -0.08851998299360275,
      0.020754670724272728,
      0.02923949435353279,
      -0.0034747272729873657,
      -0.002644831780344248,
      0.014794710092246532,
      0.04922839626669884,
      -0.03959942236542702,
      0.0014037853106856346,
      -0.05424647405743599,
      0.053544219583272934,
      -0.028823010623455048,
      0.007884183898568153,
      -0.026392638683319092,
      0.05203860253095627,
      -0.07141783833503723,
      0.021835418418049812,
      -0.035906508564949036,
      0.03879598155617714,
      0.011098312214016914,
      -0.06543654948472977,
      -0.08029107004404068,
      0.08217623084783554,
      0.01325053907930851,
      -0.06750275939702988,
      -0.005770794115960598,
      -0.030817246064543724,
      0.07990923523902893,
      0.04078792408108711,
      -0.06051017716526985,
      0.05179176479578018,
      0.013979361392557621,
      0.060843370854854584,
      -0.029622897505760193,
      0.017423976212739944,
      0.003658241592347622,
      -0.05145018547773361,
      -0.008519330993294716,
      -0.027408014982938766,
      -0.03446810692548752,
      -0.03308922052383423,
      -0.0062981960363686085,
      -0.026555199176073074,
      -0.12481953948736191,
      -0.042306046932935715,
      0.07476715743541718,
      0.06542565673589706,
      -0.019593169912695885,
      -0.038909222930669785,
      -0.02000901661813259,
      -0.02433699741959572,
      -0.0072913095355033875,
      0.05019914358854294,
      0.03454049676656723,
      -0.05351816117763519,
      0.017643611878156662,
      -0.0979444831609726,
      0.023529790341854095,
      0.004860569257289171,
      -0.07908473908901215,
      0.0012516569113358855,
      0.09733376652002335,
      -0.02379711903631687,
      0.007406978402286768,
      0.023812055587768555,
      0.0016560100484639406,
      0.009475664235651493,
      0.031144993379712105,
      0.009409799240529537,
      -0.029614930972456932,
      0.09428910166025162,
      0.0001760537998052314,
      -0.021592797711491585,
      0.0029939417727291584,
      -0.03446286544203758,
      0.03468884900212288,
      0.0011294452706351876,
      -0.013859456405043602,
      0.01445474848151207,
      0.057336971163749695,
      -0.014414692297577858,
      0.048195842653512955,
      -0.017719648778438568,
      -0.035490427166223526,
      -0.06114838644862175,
      0.03473406657576561,
      -0.021685734391212463,
      0.07509686797857285,
      -0.0019122048979625106,
      -0.03311442211270332,
      0.01671917364001274,
      0.03450417146086693,
      -0.015630513429641724,
      -0.05040863901376724,
      0.04857197403907776,
      0.021423572674393654,
      0.0455792210996151,
      0.0004765680059790611,
      0.006003317888826132,
      0.0332939438521862,
      0.020059097558259964,
      -0.014895357191562653,
      -0.030505329370498657,
      -0.05713344365358353,
      0.08384907990694046,
      -0.03642846271395683,
      0.045358676463365555,
      -0.02392309159040451,
      0.03409355878829956,
      0.03276975452899933,
      -0.07252911478281021,
      -0.0351971834897995,
      0.004953783471137285,
      -0.016959551721811295,
      -0.08883927017450333,
      0.004793110303580761,
      -0.07622724771499634,
      -0.09113243967294693,
      0.008732141926884651,
      0.0024143229238688946,
      0.03445782884955406,
      -0.03991296514868736,
      -0.01585739478468895
    ],
    [
      -0.0005437512299977243,
      0.013863411732017994,
      -0.04244442656636238,
      0.04314012825489044,
      -0.041166726499795914,
      -0.015257216058671474,
      -0.01872483268380165,
      0.008481829427182674,
      -0.0491168387234211,
      -0.0514962375164032,
      0.004105646163225174,
      0.02738582342863083,
      0.02628711797297001,
      -0.07890284061431885,
      0.060477565973997116,
      -0.01073938887566328,
      0.026583397760987282,
      -0.020221330225467682,
      -0.06807710230350494,
      -0.04425619915127754,
      -0.00735367089509964,
      0.05119499936699867,
      -0.029775822535157204,
      -0.041433706879615784,
      0.04988434165716171,
      -0.053158652037382126,
      -0.007019656710326672,
      -0.010812818072736263,
      0.005676801782101393,
      -0.061980485916137695,
      -0.016902830451726913,
      -0.03279468044638634,
      -0.010754366405308247,
      -0.07179088145494461,
      0.06490514427423477,
      -0.01708359643816948,
      0.050058864057064056,
      0.08556564897298813,
      -0.07559438794851303,
      -3.48763023794163e-05,
      -0.08553840219974518,
      0.0374528132379055,
      0.02036581188440323,
      -0.014259204268455505,
      0.0035347698722034693,
      0.005925947334617376,
      0.0693085789680481,
      0.03870883211493492,
      -0.05976638197898865,
      -0.026271512731909752,
      0.05410679429769516,
      0.005899712909013033,
      0.01308654434978962,
      0.04981543868780136,
      0.08625341951847076,
      -0.026047861203551292,
      0.11160527914762497,
      -0.049160998314619064,
      -0.01873820647597313,
      0.004105980508029461,
      0.07059107720851898,
      0.10402058064937592,
      -0.04904645308852196,
      0.039679184556007385,
      0.047289904206991196,
      0.03663836047053337,
      -0.06930867582559586,
      -0.014978911727666855,
      0.07805800437927246,
      0.015554051846265793,
      0.035748936235904694,
      -0.02624710649251938,
      -0.0016261737328022718,
      -0.005795438773930073,
      0.03435680270195007,
      0.011014741845428944,
      0.03286903724074364,
      0.024567920714616776,
      0.01666327379643917,
      0.028297850862145424,
      -0.0397251695394516,
      -0.05188494548201561,
      -0.027318336069583893,
      -0.012988654896616936,
      0.10155784338712692,
      -0.030963631346821785,
      -0.04117574915289879,
      0.025038739666342735,
      -0.029790369793772697,
      0.036174871027469635,
      0.06290057301521301,
      0.03484659641981125,
      -0.11720862239599228,
      0.008835889399051666,
      0.037915024906396866,
      -0.03871561959385872,
      0.017646532505750656,
      -0.02587633766233921,
      -0.006992214359343052,
      0.04338204860687256,
      0.0726131722331047,
      0.024856796488165855,
      0.014779191464185715,
      -0.040340978652238846,
      -0.008895576000213623,
      0.023706555366516113,
      -0.03739909827709198,
      -0.04409731179475784,
      -0.028557589277625084,
      0.04327467083930969,
      0.037474460899829865,
      -0.06309966742992401,
      0.006433217786252499,
      -0.04808080196380615,
      0.09306706488132477,
      0.014042663387954235,
      -0.055533137172460556,
      0.06970595568418503,
      0.03162447363138199,
      0.02495427243411541,
      -0.06182412803173065,
      0.05257384479045868,
      -0.021693404763936996,
      -0.0079501923173666,
      0.01157313771545887,
      0.0034715195652097464,
      0.011298911646008492,
      0.03381422162055969,
      0.0019736161921173334,
      -0.12498535215854645,
      -0.06196499243378639,
      0.001070960657671094,
      -0.019763227552175522,
      -0.06284630298614502,
      -0.053902171552181244,
      -0.0514102466404438,
      0.029989438131451607,
      -0.04189741611480713,
      0.09190189838409424,
      0.020771605893969536,
      0.010419981554150581,
      -0.04649797081947327,
      -0.011890722438693047,
      -0.1067333072423935,
      -0.0400245375931263,
      0.055013202130794525,
      0.002774947788566351,
      0.031454119831323624,
      -0.012062275782227516,
      -0.014759541489183903,
      0.07121431082487106,
      -0.009898044168949127,
      0.0025453551206737757,
      0.009537797421216965,
      -0.044847290962934494,
      0.04106526076793671,
      -0.10232362151145935,
      -0.029448170214891434,
      -0.012538370676338673,
      -0.013818234205245972,
      0.030254721641540527,
      -0.007592341396957636,
      0.03045598231256008,
      0.004994946997612715,
      -0.02716193161904812,
      0.009255901910364628,
      -0.08582700043916702,
      0.03876744210720062,
      -0.0622585192322731,
      -0.047342099249362946,
      0.013904772698879242,
      -0.032882217317819595,
      -0.020318692550063133,
      0.14599376916885376,
      0.007225139532238245,
      0.02158954180777073,
      0.1175464391708374,
      0.028899289667606354,
      -0.0007769346702843904,
      0.00043376130633987486,
      -0.01870366930961609,
      0.0922842025756836,
      -0.010150115936994553,
      0.028626032173633575,
      -0.026939531788229942,
      0.011148656718432903,
      -0.022251658141613007,
      -0.0684891939163208,
      -0.023860903456807137,
      -0.02703830413520336,
      -0.06306400895118713,
      0.00762374559417367,
      0.11311790347099304,
      -0.0007267009932547808,
      0.08514054119586945,
      0.04615171626210213,
      0.012599983252584934,
      -0.03947421535849571,
      0.0666041150689125,
      -0.010392201133072376,
      0.07492780685424805,
      0.03231554850935936,
      -0.06327451765537262,
      0.05589449405670166,
      0.03873233124613762,
      -0.004593476187437773,
      -0.0410599559545517,
      -0.04368113353848457,
      0.035262368619441986,
      -0.07962256669998169,
      0.09443183243274689,
      -0.08040834218263626,
      -0.03443045914173126,
      -0.10903923958539963,
      -0.008401653729379177,
      0.01992962695658207,
      0.010532105341553688,
      0.13070864975452423,
      -0.05426372215151787,
      -0.03075568564236164,
      0.0467815175652504,
      0.0077096824534237385,
      -0.02623661793768406,
      -0.024517249315977097,
      -0.05058697983622551,
      -0.04923849552869797,
      -0.1281575709581375,
      -0.031224999576807022,
      0.017326276749372482,
      0.03351452201604843,
      -0.010581402108073235,
      -0.0741138905286789,
      -0.019258981570601463,
      -0.0060506220906972885,
      0.02474362775683403,
      -0.07282456010580063,
      0.025659993290901184,
      -0.065672867000103,
      0.011415526270866394,
      0.06933598220348358,
      -0.0489681102335453,
      -0.04817218333482742,
      0.0027676564641296864,
      0.05993063375353813,
      -0.003129074117168784,
      0.04388170316815376,
      0.028270579874515533,
      0.00453859381377697,
      0.0506182499229908,
      -0.01080467738211155,
      0.03070644661784172,
      0.07649082690477371,
      0.04265290126204491,
      -0.0014792808797210455,
      0.01832696609199047,
      -0.01873917691409588,
      -0.013156374916434288,
      -0.057072341442108154,
      -0.08233851939439774,
      0.023485874757170677,
      0.014324028044939041,
      0.02769855037331581,
      0.010924709029495716,
      0.11471185088157654,
      -0.012076996266841888,
      0.06919129192829132,
      -0.0461774617433548,
      0.05984312295913696,
      -0.015538431704044342,
      -0.042849939316511154,
      0.06236254796385765,
      -0.00810820609331131,
      -0.031184300780296326,
      0.07044661045074463,
      0.04952145740389824,
      -0.04505630210042,
      -0.003093934152275324,
      0.07157078385353088,
      -0.022518524900078773,
      -0.009881851263344288,
      0.1353108286857605,
      -0.04845525696873665,
      -0.04291299730539322,
      0.03100215643644333,
      -0.004536476451903582,
      -0.015889156609773636,
      0.016786865890026093,
      -0.03342601656913757,
      0.00933834258466959,
      0.03465258702635765,
      -0.03991413488984108,
      0.05536660552024841,
      -0.022336754947900772,
      0.023205671459436417,
      0.007886682637035847,
      -0.04746226966381073,
      -0.05095616728067398,
      0.08516047894954681,
      -0.03931877762079239,
      -0.002618810860440135,
      0.0480324886739254,
      -0.05651620775461197,
      -0.040141794830560684,
      0.031635161489248276,
      -0.05085913464426994,
      0.09095575660467148,
      0.04164096713066101,
      -0.012892374768853188,
      0.006044308189302683,
      0.03483036160469055,
      0.0001916965120472014,
      -0.006438765674829483,
      0.08045361936092377,
      -0.03947783634066582,
      0.01370819378644228,
      -0.08314310014247894,
      -0.04417651146650314,
      -0.06762216240167618,
      -0.030590731650590897,
      0.04182521998882294,
      0.03441210463643074,
      0.037795282900333405,
      0.001086956006474793,
      -0.03473205119371414,
      -0.046026818454265594,
      -0.07867537438869476,
      -0.0017893894109874964,
      0.0817398875951767,
      0.008132816292345524,
      0.006176390685141087,
      -0.011631716042757034,
      -0.04357176646590233,
      0.0027010415215045214,
      0.009521408937871456,
      -0.013105825521051884,
      -0.04725154861807823,
      -0.017299599945545197,
      0.016424261033535004,
      -0.04786523059010506,
      0.015174760483205318,
      -0.05661863461136818,
      0.015866847708821297,
      0.03910241276025772,
      0.001877332222647965,
      -0.08319710940122604,
      -0.027801550924777985,
      -0.12389368563890457,
      0.06468399614095688,
      -0.0014955881051719189,
      -0.034533653408288956,
      0.040329188108444214,
      -0.004515067208558321,
      -0.009031955152750015,
      0.01552762184292078,
      0.0883312076330185,
      0.018335485830903053,
      -0.010066989809274673,
      -0.034852445125579834,
      -0.059150852262973785,
      -0.0013286651810631156,
      0.0473150834441185,
      -0.029104068875312805,
      -0.005356895737349987,
      0.03138715773820877,
      -0.10329344868659973,
      -0.05077100917696953,
      0.009895075112581253,
      -0.039235103875398636,
      -0.015636160969734192,
      -0.02296479232609272,
      0.05261584743857384,
      -0.059068791568279266,
      -0.04021169990301132,
      -0.006486133206635714,
      -0.011702470481395721,
      0.0503428652882576,
      0.02221505716443062,
      0.011849119327962399,
      -0.014623377472162247,
      0.04377118870615959,
      0.010379636660218239,
      0.03679216280579567,
      -0.007815851829946041,
      -0.028404342010617256,
      -0.04591012001037598,
      0.027129841968417168,
      -0.01989249885082245,
      -0.03258787468075752,
      0.03970085829496384,
      0.03434407338500023,
      0.014697684906423092,
      -0.019853761419653893,
      0.030561549589037895,
      0.09072645753622055,
      0.05242740735411644,
      -0.021637827157974243,
      -0.06324537098407745,
      0.05806942656636238,
      -0.01231527328491211,
      0.05700845643877983,
      -0.03359101340174675,
      -0.0363069623708725,
      0.011121428571641445,
      0.05169063061475754,
      0.028677336871623993,
      -0.00344857107847929,
      -0.0076204021461308,
      0.02689271979033947,
      0.012304369360208511,
      -0.08330105245113373,
      -0.05744639411568642,
      -0.1061384379863739,
      -0.0023297565057873726,
      0.000825744413305074,
      -0.06323950737714767,
      -0.0319252535700798,
      0.0560041144490242,
      -0.010167599655687809,
      -0.03415956348180771,
      -0.03634021803736687,
      -0.1164064034819603,
      0.03117820993065834,
      0.0008235143031924963,
      0.09643333405256271,
      0.06680117547512054,
      -0.044772177934646606,
      0.01362196821719408,
      0.024033555760979652,
      0.02559090219438076,
      0.037889543920755386,
      -0.08646943420171738,
      -0.0196389053016901,
      0.04913702234625816,
      -0.030505351722240448,
      0.05837883800268173,
      -0.07756626605987549,
      -0.0051933894865214825,
      0.11016664654016495,
      -0.004413444548845291,
      0.03665050119161606,
      -0.02747069112956524,
      0.10147840529680252,
      -0.031794603914022446,
      -0.017768578603863716,
      -0.007427973672747612,
      -2.24703017011052e-05,
      -0.022956563159823418,
      -0.016587959602475166,
      -0.00800758320838213,
      0.01840219274163246,
      0.1463734358549118,
      -0.051760029047727585,
      0.0037403053138405085,
      -0.008889173157513142,
      0.07024265825748444,
      0.036528196185827255,
      -0.056256718933582306,
      -0.09442951530218124,
      -0.04165806993842125,
      -0.0292818620800972,
      0.014638252556324005,
      0.0794156938791275,
      0.06595142185688019,
      0.002769480226561427,
      -0.03843936696648598,
      0.0028257821686565876,
      -0.07554102689027786,
      0.03994044288992882,
      -0.07249459624290466,
      -0.027149474248290062,
      0.007251612842082977,
      0.04094524309039116,
      0.023700108751654625,
      0.05906569957733154,
      0.033974021673202515,
      0.06151410564780235,
      -0.004106247331947088,
      -0.06823216378688812,
      0.03062955103814602,
      -0.040617890655994415,
      0.0035318913869559765,
      0.04300794005393982,
      -0.002555983839556575,
      0.010465724393725395,
      0.006390106398612261,
      0.002239994006231427,
      -0.05122440308332443,
      0.005330146290361881,
      0.04937038943171501,
      -0.10183343291282654,
      -0.02130828984081745,
      -0.033024393022060394,
      -0.08359334617853165,
      -0.11479347199201584,
      -0.005810849834233522,
      -0.006715068127959967,
      -0.06250578165054321,
      -0.02698240615427494,
      -0.01376346405595541,
      -0.010296893306076527,
      0.012504341080784798,
      -0.025468625128269196,
      0.06543973833322525
    ],
    [
      0.04454300180077553,
      -0.024489421397447586,
      -0.009709445759654045,
      0.08422929793596268,
      0.020492924377322197,
      0.011009760200977325,
      -0.05428360402584076,
      -0.07541833072900772,
      -0.0629885271191597,
      0.027078725397586823,
      0.022874007001519203,
      -0.05279240384697914,
      0.04354575648903847,
      -0.018922273069620132,
      0.03975512832403183,
      -0.006932415068149567,
      0.0024543830659240484,
      0.011962045915424824,
      0.10026092827320099,
      0.05554226040840149,
      0.03758658096194267,
      0.022756459191441536,
      0.08952794224023819,
      0.0013932731235399842,
      0.051564883440732956,
      0.03678673878312111,
      0.0004883712390437722,
      -0.022602679207921028,
      -0.04529596492648125,
      -0.09056177735328674,
      0.07455756515264511,
      0.022587832063436508,
      0.03369932994246483,
      0.009761170484125614,
      0.02305387519299984,
      -0.0056161535903811455,
      0.027233678847551346,
      0.09802138060331345,
      -0.022725442424416542,
      0.04445778951048851,
      -0.12036363035440445,
      0.00031135472818277776,
      0.03628525137901306,
      0.012661361135542393,
      0.005646963138133287,
      0.06203244999051094,
      0.030637703835964203,
      0.06156407669186592,
      0.027457181364297867,
      -0.013727514073252678,
      -0.09076672792434692,
      0.01306330319494009,
      -0.012978759594261646,
      0.00019695489027071744,
      0.02413795329630375,
      0.026720063760876656,
      0.05717131868004799,
      -0.01725582964718342,
      -0.04270597919821739,
      0.024662932381033897,
      0.0064703067764639854,
      0.09597893804311752,
      -0.06785117089748383,
      0.010435247793793678,
      0.029902569949626923,
      0.058206956833601,
      0.02157195657491684,
      -0.04706199839711189,
      0.027071574702858925,
      0.07830342650413513,
      -0.03322991728782654,
      -0.07722409814596176,
      0.054920267313718796,
      -0.04048747569322586,
      -0.024004051461815834,
      -0.038863297551870346,
      -0.007850571535527706,
      0.03861963003873825,
      -0.02592775970697403,
      0.1828717142343521,
      -0.025839542970061302,
      0.08372842520475388,
      -0.012967456132173538,
      -0.008330389857292175,
      0.07640961557626724,
      0.019464503973722458,
      -0.03941742703318596,
      -0.019293224439024925,
      0.023558657616376877,
      0.015502591617405415,
      0.016097482293844223,
      -0.05467982962727547,
      0.01838007941842079,
      -0.005561586003750563,
      -0.03313802182674408,
      0.01602395996451378,
      0.038363076746463776,
      0.008437884040176868,
      0.06891083717346191,
      0.002632766030728817,
      -0.024458756670355797,
      0.062593013048172,
      -0.019019266590476036,
      -0.011641643941402435,
      -0.00863361544907093,
      0.05902387574315071,
      -0.008217833004891872,
      0.038072600960731506,
      -0.010583031922578812,
      -0.013108489103615284,
      0.022450478747487068,
      -0.006468875799328089,
      -0.010170917958021164,
      -0.07286906987428665,
      -0.01687365025281906,
      0.05019732564687729,
      0.06481746584177017,
      -0.0956408679485321,
      0.05117707699537277,
      -0.06621972471475601,
      -0.02548077516257763,
      -0.0406305193901062,
      0.052213046699762344,
      -0.0037831489462405443,
      0.015194841660559177,
      0.03755985200405121,
      0.003396989544853568,
      0.07201533019542694,
      -0.050684764981269836,
      0.0709238201379776,
      -0.03624339774250984,
      0.04737013205885887,
      0.03636160120368004,
      -0.01951744221150875,
      0.05526360124349594,
      0.038999538868665695,
      -0.003142396453768015,
      -0.020844772458076477,
      0.05470459535717964,
      0.14276228845119476,
      0.030030423775315285,
      0.011857444420456886,
      0.05718149244785309,
      0.018237104639410973,
      -0.0007470425334759057,
      0.028678858652710915,
      -0.05784616991877556,
      0.031150411814451218,
      0.03537571802735329,
      0.03473391756415367,
      0.008997556753456593,
      0.050752028822898865,
      0.024380499497056007,
      0.023771189153194427,
      -0.017105480656027794,
      0.0606578104197979,
      -0.08951697498559952,
      0.030778227373957634,
      0.014455157332122326,
      -0.04488329589366913,
      0.046289511024951935,
      -0.034539561718702316,
      -0.016199974343180656,
      0.028679365292191505,
      0.022638659924268723,
      0.054736848920583725,
      -0.006430067587643862,
      -0.01732945814728737,
      0.038427188992500305,
      -0.04429883509874344,
      0.029609784483909607,
      0.08980704098939896,
      0.0005803365493193269,
      0.018982473760843277,
      -0.006278887391090393,
      -0.003272307338193059,
      0.03536068648099899,
      0.007689329329878092,
      0.022058341652154922,
      -0.030912058427929878,
      0.03429694101214409,
      -0.04233451560139656,
      0.017075302079319954,
      0.034859366714954376,
      0.020329581573605537,
      -0.1074117049574852,
      -0.0566687174141407,
      0.02521423064172268,
      -0.08606120198965073,
      -0.0076683382503688335,
      -0.038429755717515945,
      -0.005018195137381554,
      0.030764132738113403,
      -0.03473030775785446,
      -0.0038379684556275606,
      -0.07204347103834152,
      0.05985403433442116,
      -0.014204482547938824,
      -0.037889186292886734,
      -0.061747197061777115,
      -0.0457172729074955,
      0.007203359156847,
      -0.05894284322857857,
      -0.001484275097027421,
      -0.004888813477009535,
      -0.030472407117486,
      -0.02757111005485058,
      -0.02034827321767807,
      0.09969879686832428,
      -0.020568473264575005,
      -0.009709698148071766,
      -0.007199387066066265,
      0.03724730759859085,
      -0.09862813353538513,
      0.03716439753770828,
      0.005201707128435373,
      -0.07439696788787842,
      0.0010382956825196743,
      -0.04479149356484413,
      0.03844522684812546,
      -0.04416392743587494,
      -0.05695284903049469,
      0.03222040832042694,
      -0.014042961411178112,
      0.09982768446207047,
      -0.007279944606125355,
      -0.07022272795438766,
      -0.07635244727134705,
      -0.023855429142713547,
      -0.08973308652639389,
      0.03843486309051514,
      0.06469413638114929,
      -0.043062251061201096,
      -0.011714701540768147,
      0.015531038865447044,
      -0.01799784041941166,
      0.005210056435316801,
      0.01389321219176054,
      0.004465274512767792,
      0.0010598988737910986,
      -0.016315249726176262,
      0.025150425732135773,
      0.04700038209557533,
      -0.02112598717212677,
      -0.020300786942243576,
      0.08841684460639954,
      -0.04832197353243828,
      0.07026217132806778,
      -0.03145700320601463,
      -0.009005887433886528,
      -0.05974635109305382,
      0.008317542262375355,
      0.02566245198249817,
      0.02474963665008545,
      -0.02496202290058136,
      0.06457018107175827,
      -0.054583076387643814,
      -0.06157878786325455,
      0.10128986835479736,
      -0.025560801848769188,
      -0.07536294311285019,
      -0.03616854175925255,
      -0.040708284825086594,
      0.03917449340224266,
      0.1011803150177002,
      0.07745317369699478,
      -0.05927015095949173,
      -0.03308900073170662,
      0.026622217148542404,
      -0.017246874049305916,
      0.0009110737591981888,
      -0.0036276946775615215,
      0.041179265826940536,
      0.035280629992485046,
      -0.02352048084139824,
      -0.014856308698654175,
      -0.0005773428129032254,
      0.04975222796201706,
      0.05462116003036499,
      -0.007759435568004847,
      -0.0054619451984763145,
      -0.049156785011291504,
      0.027152298018336296,
      -0.002289542928338051,
      0.002279057167470455,
      -0.04860236123204231,
      -0.02973064035177231,
      -0.026923522353172302,
      -0.011056904681026936,
      -0.015409783460199833,
      0.008474870584905148,
      -0.05246007442474365,
      -0.021084286272525787,
      0.06244904175400734,
      -0.08102555572986603,
      0.062299974262714386,
      -0.020986828953027725,
      0.05290265753865242,
      0.11836171895265579,
      -0.050583917647600174,
      0.033878978341817856,
      0.039915524423122406,
      -0.04731842130422592,
      0.005191443022340536,
      -0.021676570177078247,
      0.041059937328100204,
      -0.04461102932691574,
      0.00774037791416049,
      -0.03863921016454697,
      0.00819452665746212,
      0.024720141664147377,
      -0.08677930384874344,
      0.02359037846326828,
      0.012365596368908882,
      0.03884728625416756,
      0.0034713030327111483,
      -0.10969404876232147,
      -0.08512838184833527,
      0.05115557461977005,
      0.021001437678933144,
      0.06071492284536362,
      0.025484992191195488,
      0.09392254054546356,
      0.009547136723995209,
      -0.010146677494049072,
      -0.04546542838215828,
      -0.03375885263085365,
      0.08399730920791626,
      -0.03346232324838638,
      0.05135788768529892,
      0.028716791421175003,
      0.0019363928586244583,
      -0.00233021704480052,
      -0.012839055620133877,
      -0.04551294445991516,
      0.043614622205495834,
      0.05225040018558502,
      0.04531322047114372,
      -0.05896990746259689,
      -0.047427948564291,
      -0.021623043343424797,
      0.03061036206781864,
      -0.03664908930659294,
      -0.041529763489961624,
      -0.04020068049430847,
      0.006288085598498583,
      -0.009725025855004787,
      -0.0026825075037777424,
      0.03694062680006027,
      -0.0011138293193653226,
      -0.11615882813930511,
      -0.029421748593449593,
      -0.017894325777888298,
      0.005031628999859095,
      0.02950846031308174,
      -0.01167760044336319,
      -0.022312181070446968,
      0.07243552803993225,
      -0.06494086235761642,
      0.002122714649885893,
      -0.05527939647436142,
      0.0005424240953288972,
      0.06272551417350769,
      -0.04599511995911598,
      -0.00503057986497879,
      0.095344178378582,
      -0.061376433819532394,
      0.1253415048122406,
      -0.00683824997395277,
      0.04211544618010521,
      -0.060511812567710876,
      0.05677071958780289,
      0.08643777668476105,
      -0.03524814173579216,
      -0.12613338232040405,
      0.07885687053203583,
      -0.03301043063402176,
      0.058106206357479095,
      0.005447084084153175,
      -0.014997528865933418,
      -0.0341067872941494,
      0.022831138223409653,
      0.006098575424402952,
      0.008150958456099033,
      0.00905794557183981,
      -0.004078625235706568,
      -0.057701658457517624,
      -0.027661720290780067,
      -0.053896013647317886,
      0.03145015984773636,
      -0.05813769996166229,
      0.07103075087070465,
      -0.0006247092969715595,
      -0.03233083337545395,
      0.07792060822248459,
      -0.12910166382789612,
      -0.07875679433345795,
      0.015236509032547474,
      -0.028779830783605576,
      0.03882962465286255,
      -0.03691660612821579,
      0.035236701369285583,
      -0.04782257601618767,
      0.004985507111996412,
      -0.01806126907467842,
      0.004285203292965889,
      -0.04948774352669716,
      0.03220551460981369,
      0.05004271864891052,
      -0.026935942471027374,
      -0.016672760248184204,
      -0.03341268002986908,
      -0.03209095075726509,
      0.0005465506110340357,
      0.029232675209641457,
      -0.08176468312740326,
      -0.07915598154067993,
      0.031161025166511536,
      0.05697333440184593,
      0.03562459722161293,
      0.012887317687273026,
      -0.007451746147125959,
      0.050684113055467606,
      -0.008217433467507362,
      -0.013215343467891216,
      0.021530918776988983,
      0.0010232387576252222,
      0.008683578111231327,
      0.011017007753252983,
      0.007582732010632753,
      -0.07348573952913284,
      0.017668263986706734,
      -0.008671192452311516,
      -0.03561679646372795,
      -0.032965242862701416,
      0.012425759807229042,
      0.019817421212792397,
      -0.024483278393745422,
      0.03660354018211365,
      -0.06590206921100616,
      0.04156450554728508,
      0.04104737937450409,
      -0.05653690546751022,
      -0.05153487250208855,
      -0.017096132040023804,
      0.0025560033973306417,
      -0.027492966502904892,
      -0.043320514261722565,
      0.03235672786831856,
      0.05897626280784607,
      0.024523964151740074,
      0.020901402458548546,
      0.06779713928699493,
      -0.10168799757957458,
      0.018515832722187042,
      -0.03854529932141304,
      0.030167486518621445,
      -0.05085207149386406,
      -0.00920941587537527,
      0.010819382034242153,
      0.027549464255571365,
      0.026792405173182487,
      0.006164771504700184,
      0.04108496010303497,
      0.08925536274909973,
      -0.032147154211997986,
      -0.026225892826914787,
      0.024216005578637123,
      -0.09654664993286133,
      0.007833987474441528,
      0.03353900834918022,
      0.07495204359292984,
      0.06375236809253693,
      -0.023063823580741882,
      0.025667015463113785,
      -0.024517053738236427,
      -0.007139009423553944,
      0.003960153087973595,
      -0.04451117664575577,
      0.07185374945402145,
      -0.026974352076649666,
      0.0322776734828949,
      0.02774304337799549,
      -0.012372376397252083,
      -0.010483208112418652,
      0.007681191433221102,
      0.0788196548819542,
      -0.05547270551323891,
      0.011422322131693363,
      -0.0784938707947731,
      0.01837429776787758,
      0.008543049916625023,
      0.026836629956960678,
      -0.04190322011709213,
      -0.06110755354166031,
      0.01318237092345953,
      0.042585331946611404,
      -0.06615413725376129,
      0.05930231139063835,
      -0.014330824837088585,
      0.040075816214084625,
      -0.006814558058977127,
      -0.044103506952524185
    ],
    [
      0.05248826742172241,
      0.04970415309071541,
      0.04760988429188728,
      0.008688156493008137,
      0.050893813371658325,
      -0.04242167994379997,
      0.023846006020903587,
      0.018624935299158096,
      0.010513356886804104,
      -0.003107430413365364,
      0.018513375893235207,
      -0.008760308846831322,
      0.07909996062517166,
      -0.037681229412555695,
      -0.031917084008455276,
      -0.02856474369764328,
      -0.028256038203835487,
      -0.0423671156167984,
      -0.079335056245327,
      -0.030380364507436752,
      0.07188494503498077,
      -0.0017088910099118948,
      -0.04837220534682274,
      0.011571764014661312,
      0.011388384737074375,
      0.09038122743368149,
      0.03617855906486511,
      -0.005491814110428095,
      0.0440787747502327,
      0.04989331215620041,
      -0.004823025781661272,
      -0.10352820158004761,
      -0.04514008387923241,
      -0.03822628781199455,
      -0.0703553855419159,
      -0.010067145340144634,
      0.009368618950247765,
      -0.016410555690526962,
      -0.03827898949384689,
      0.093230701982975,
      -0.07169263064861298,
      -0.0012938219588249922,
      -0.01161371823400259,
      -0.026212938129901886,
      -0.014558766968548298,
      -0.011861548759043217,
      0.008250478655099869,
      -0.03988604247570038,
      0.05239515006542206,
      -0.10866604000329971,
      0.09563907235860825,
      -0.01677536405622959,
      -0.03795386105775833,
      0.009096864610910416,
      0.04738540202379227,
      0.0017385201063007116,
      0.05128028243780136,
      -0.0698055773973465,
      0.0023823364172130823,
      0.009540367871522903,
      0.059270892292261124,
      0.008400596678256989,
      -0.04467450827360153,
      -0.030372053384780884,
      0.01294440496712923,
      -0.026308981701731682,
      -0.06765662133693695,
      0.09787501394748688,
      0.06379247456789017,
      0.009823578409850597,
      0.04055078700184822,
      0.0023224912583827972,
      -0.0044364952482283115,
      -0.01885935105383396,
      -0.01270938292145729,
      -0.0005977815017104149,
      0.019655629992485046,
      -0.03487694635987282,
      -0.025335317477583885,
      -0.022765005007386208,
      0.0017747896490618587,
      -0.005725175607949495,
      -0.017196733504533768,
      -0.01456580962985754,
      -0.09054560959339142,
      -0.04050960764288902,
      0.006821147631853819,
      0.02359594777226448,
      0.025900468230247498,
      0.009745889343321323,
      0.1256771832704544,
      -0.10677739232778549,
      -0.024145958945155144,
      -0.05193439871072769,
      0.014501884579658508,
      0.025416681542992592,
      0.05070525407791138,
      -0.03420313820242882,
      0.020525788888335228,
      -0.04603017494082451,
      -0.031887758523225784,
      -0.054250095039606094,
      0.0706525593996048,
      -0.0013424010248854756,
      -0.017819253727793694,
      -0.017357202246785164,
      -0.019618377089500427,
      0.027440523728728294,
      -0.010536002926528454,
      -0.0026275236159563065,
      0.022316187620162964,
      0.058031775057315826,
      -0.01873021572828293,
      -0.07578352093696594,
      0.05431269854307175,
      -0.07556135207414627,
      -0.01741677336394787,
      0.024179406464099884,
      0.04823821038007736,
      -0.017805570736527443,
      -0.059347063302993774,
      -0.005060313735157251,
      -0.03233013674616814,
      -0.04201819747686386,
      -0.021225064992904663,
      -0.040116917341947556,
      -0.04129326716065407,
      -0.04182907193899155,
      -0.08528763055801392,
      0.0538300983607769,
      -0.005048970691859722,
      0.07103296369314194,
      0.002339298604056239,
      0.010546793229877949,
      -0.051543787121772766,
      -0.07169128954410553,
      -0.08044860512018204,
      -0.021647384390234947,
      0.056625645607709885,
      -0.027108673006296158,
      0.0202430859208107,
      0.09510701894760132,
      0.020076896995306015,
      0.10215017199516296,
      0.04612471163272858,
      -0.0003350080514792353,
      -0.032058343291282654,
      0.03165588155388832,
      -0.04159609228372574,
      0.05954231321811676,
      0.0014900784008204937,
      0.07699289172887802,
      -0.002635751850903034,
      -0.007486778311431408,
      0.017326638102531433,
      0.019085558131337166,
      -0.02725650742650032,
      0.0158245787024498,
      -0.060205455869436264,
      0.009432762861251831,
      -0.004402701277285814,
      0.0415491946041584,
      -0.03598976880311966,
      0.021189909428358078,
      -0.0036681334022432566,
      -0.007546289823949337,
      -0.007141548208892345,
      -0.05164783075451851,
      -0.01656828634440899,
      -0.016260871663689613,
      0.04427626356482506,
      -0.051148850470781326,
      0.009545857086777687,
      -0.03876020759344101,
      -0.022196680307388306,
      -0.0372900553047657,
      -0.03539680689573288,
      0.08234217762947083,
      0.05627158656716347,
      0.046276286244392395,
      -0.030785849317908287,
      0.05214563012123108,
      0.045998524874448776,
      -0.04516894742846489,
      -0.05639093741774559,
      -0.027683474123477936,
      -0.03410303220152855,
      -0.05789441242814064,
      -0.039627160876989365,
      0.005816587712615728,
      -0.04415677487850189,
      -0.04594877362251282,
      -0.05144667625427246,
      -0.0049883462488651276,
      0.02773424983024597,
      -0.061441823840141296,
      0.017847174778580666,
      0.06130560487508774,
      -0.03195435553789139,
      -0.05556856468319893,
      -0.00932359416037798,
      -0.10102905333042145,
      -0.022668370977044106,
      -0.11203446239233017,
      0.018994824960827827,
      -0.023619644343852997,
      0.04302079975605011,
      0.018084321171045303,
      0.08670784533023834,
      0.001800088444724679,
      0.010420982725918293,
      0.029754849150776863,
      -0.07178015261888504,
      -0.04393542557954788,
      -0.005577957723289728,
      -0.03572821244597435,
      -0.007090720813721418,
      4.172742774244398e-05,
      -0.005507373251020908,
      -0.00726411584764719,
      -0.0704386755824089,
      -0.0804462656378746,
      0.0657740905880928,
      0.044838473200798035,
      0.014794421382248402,
      -0.007459046319127083,
      0.01343831792473793,
      -0.03577563911676407,
      0.030504725873470306,
      -0.0628613531589508,
      -0.12982386350631714,
      -0.031167445704340935,
      -0.005664814729243517,
      -0.08094453811645508,
      -0.05448399484157562,
      -0.02350780740380287,
      -0.05366344004869461,
      -0.07991386204957962,
      0.0319475382566452,
      -0.010076549835503101,
      -0.041159164160490036,
      0.022778814658522606,
      -0.05376284196972847,
      -0.02383507788181305,
      0.03686431422829628,
      0.0368734635412693,
      -0.003426659619435668,
      -0.011421146802604198,
      0.04700767621397972,
      0.0022062386851757765,
      0.007987834513187408,
      0.0698581412434578,
      -0.017017502337694168,
      0.011872949078679085,
      0.030998293310403824,
      0.03564038127660751,
      0.04346863552927971,
      0.004805692937225103,
      -0.006144493818283081,
      -0.0003130585828330368,
      -0.01977371610701084,
      -0.06706931442022324,
      -0.04460449516773224,
      0.028355026617646217,
      0.015239105559885502,
      0.04869262874126434,
      -0.025711022317409515,
      -0.0030248677358031273,
      -0.019375093281269073,
      -0.027974361553788185,
      0.04139375686645508,
      -0.012620627880096436,
      0.0017905216664075851,
      0.05359536036849022,
      0.03692523390054703,
      0.051634304225444794,
      -0.030018048360943794,
      -0.011037204414606094,
      0.003342092502862215,
      0.06302712112665176,
      -0.03925299271941185,
      0.017545564100146294,
      -0.06455130875110626,
      -0.07933825254440308,
      0.050890859216451645,
      -0.05094119533896446,
      0.04471082240343094,
      -0.05800484120845795,
      -0.049204785376787186,
      -0.054079338908195496,
      0.03808526694774628,
      0.014918538741767406,
      0.07455255091190338,
      -0.03676973283290863,
      0.047556355595588684,
      0.04772507771849632,
      0.05673457309603691,
      0.04148157685995102,
      0.004905662499368191,
      -0.023132827132940292,
      0.02637694776058197,
      0.02511623315513134,
      -0.03315228968858719,
      -0.008143212646245956,
      -0.012958982028067112,
      0.020384838804602623,
      0.011711463332176208,
      -0.02460961788892746,
      0.02642015367746353,
      0.03786032646894455,
      2.1390393158071674e-05,
      0.052636582404375076,
      0.006604851223528385,
      0.07241141051054001,
      0.022171467542648315,
      -0.05357925966382027,
      -0.14262625575065613,
      -0.07983502000570297,
      0.04402977600693703,
      -0.05032157152891159,
      0.034629274159669876,
      -0.038347382098436356,
      -0.06122298538684845,
      -0.0643746480345726,
      0.036829039454460144,
      -0.007081690710037947,
      0.010278688743710518,
      0.02585943043231964,
      -0.04501072317361832,
      -0.06591323763132095,
      0.0335012823343277,
      -0.003114506369456649,
      0.04850844666361809,
      0.04864456132054329,
      -0.04530278593301773,
      0.00762106291949749,
      0.02496432699263096,
      0.017147187143564224,
      -0.06321064382791519,
      -0.10870905965566635,
      0.09440148621797562,
      0.08124751597642899,
      0.04643160104751587,
      0.05025388300418854,
      0.05768046900629997,
      -0.011829396709799767,
      -0.0625964030623436,
      -0.02552044205367565,
      0.018515896052122116,
      -0.004370610695332289,
      0.09158138930797577,
      -0.0024417871609330177,
      -0.10038556158542633,
      0.016970636323094368,
      -0.014994953759014606,
      -0.008839140646159649,
      -0.03134223073720932,
      0.12420959770679474,
      -0.07687906175851822,
      -0.056364987045526505,
      -0.032781779766082764,
      -0.049578480422496796,
      -0.03170110285282135,
      -0.05881576985120773,
      -0.07399440556764603,
      -0.04878973215818405,
      -0.007573246490210295,
      0.13721616566181183,
      -0.020085381343960762,
      0.006373162381350994,
      -0.047294579446315765,
      -0.03596882149577141,
      -0.018431294709444046,
      -0.048436958342790604,
      0.05207500606775284,
      0.016027458012104034,
      0.03352595120668411,
      -0.0027818207163363695,
      0.049707815051078796,
      -0.045894723385572433,
      0.048624277114868164,
      0.018237365409731865,
      0.03433185815811157,
      0.05190393328666687,
      -0.049262721091508865,
      -0.043911900371313095,
      -0.0341692715883255,
      0.00048168920329771936,
      -0.08260784298181534,
      -0.0616118498146534,
      0.04015320912003517,
      -0.06280388683080673,
      -0.007731692399829626,
      0.019425852224230766,
      0.006975865922868252,
      0.014314962550997734,
      0.02425527200102806,
      0.05571537837386131,
      0.0893499106168747,
      0.083990678191185,
      0.019367776811122894,
      0.03658631816506386,
      0.010981937870383263,
      0.031191594898700714,
      -0.01437452808022499,
      -0.015212407335639,
      -0.034868113696575165,
      -0.03630788251757622,
      -0.058455854654312134,
      -0.014834598638117313,
      -0.007215897087007761,
      0.0005876126233488321,
      0.015087787061929703,
      -0.07435175776481628,
      -0.0455489307641983,
      -0.05716164782643318,
      -0.04355677217245102,
      0.025754481554031372,
      0.008547810837626457,
      -0.04453408345580101,
      -0.005871489644050598,
      -0.005029082763940096,
      -0.014452907256782055,
      -0.09699919074773788,
      -0.049080394208431244,
      -0.00733301043510437,
      -0.03874112293124199,
      -0.052424680441617966,
      -0.016234682872891426,
      -0.008103691972792149,
      0.08865085244178772,
      0.015210854820907116,
      -0.05514540895819664,
      0.02343929558992386,
      0.04275914281606674,
      -0.04539426043629646,
      -0.03937715291976929,
      0.0416361466050148,
      -0.0036433532368391752,
      -0.02171635441482067,
      -0.0664462000131607,
      0.004272239748388529,
      0.016836658120155334,
      -0.037186820060014725,
      0.038661301136016846,
      0.04147397354245186,
      -0.03366916626691818,
      -0.02074628509581089,
      -0.03661375492811203,
      0.03497355803847313,
      0.006292289588600397,
      0.01093099731951952,
      -0.06319712847471237,
      0.014249324798583984,
      0.001738864928483963,
      -0.028271660208702087,
      -0.06321774423122406,
      -0.02876097895205021,
      -0.023782679811120033,
      0.06050146371126175,
      0.0019505687523633242,
      0.06299804896116257,
      0.011125870048999786,
      0.016238752752542496,
      0.08908537030220032,
      0.03341995179653168,
      -0.00719432020559907,
      -0.020428204908967018,
      -0.004945085849612951,
      0.013912965543568134,
      0.04348430410027504,
      0.02586086094379425,
      0.08077424764633179,
      -0.07032977044582367,
      0.0020478591322898865,
      -0.08535120636224747,
      0.03413459286093712,
      -0.01857137680053711,
      -0.10192611813545227,
      0.09863588958978653,
      0.0038115172646939754,
      0.027305100113153458,
      0.0077302041463553905,
      -0.010673101991415024,
      -0.00761251850053668,
      -0.06806983798742294,
      -0.007658528629690409,
      -0.0689612403512001,
      -0.06028430908918381,
      -0.016859466210007668,
      -0.022335655987262726,
      -0.009324866347014904,
      0.05663875862956047,
      0.015485997311770916,
      0.003141359193250537,
      -0.0956088975071907,
      0.038874104619026184,
      -0.12284838408231735,
      0.030021045356988907,
      0.0274796299636364,
      0.09940330684185028,
      0.03216848522424698,
      0.016765791922807693
    ],
    [
      -0.038495566695928574,
      -0.05411600321531296,
      -0.026186389848589897,
      0.05849626287817955,
      0.0006822379655204713,
      -0.05231226235628128,
      -2.1340254534152336e-05,
      0.05467763915657997,
      -0.02069256827235222,
      -0.035903312265872955,
      -0.07782560586929321,
      -0.004584860056638718,
      -0.009045720100402832,
      0.044795598834753036,
      0.0393742211163044,
      -0.02675333060324192,
      0.025280730798840523,
      0.09104274213314056,
      -0.029275042936205864,
      -0.02215452492237091,
      0.055331964045763016,
      0.04929020628333092,
      0.04068658500909805,
      -0.01340469904243946,
      0.03647087886929512,
      -0.035753920674324036,
      0.0626671090722084,
      -0.014339146204292774,
      0.025050805881619453,
      -0.022756794467568398,
      0.10179433971643448,
      0.044363077729940414,
      0.03552521765232086,
      -0.050169866532087326,
      0.08628625422716141,
      0.012554879300296307,
      -0.039299581199884415,
      -0.035342417657375336,
      -0.05347919836640358,
      -0.021706903353333473,
      -0.002992034424096346,
      -0.000749897095374763,
      -0.08083708584308624,
      -0.0782712996006012,
      -0.06627308577299118,
      0.017008088529109955,
      0.06625884026288986,
      0.0008737501339055598,
      0.06766549497842789,
      -0.07517906278371811,
      0.009561536833643913,
      0.014272580854594707,
      0.0535871796309948,
      -0.035243093967437744,
      0.06335851550102234,
      0.0007061483338475227,
      0.07190767675638199,
      -0.03226301446557045,
      -0.07949359714984894,
      0.0022287399042397738,
      -0.048588141798973083,
      0.07470439374446869,
      -0.08194340765476227,
      0.0985763892531395,
      -0.09560167789459229,
      0.025094490498304367,
      0.037885382771492004,
      0.013514566235244274,
      -0.05568864941596985,
      0.02022775448858738,
      0.023917078971862793,
      -0.05145386978983879,
      -0.017023704946041107,
      0.011429592035710812,
      -0.0072659822180867195,
      -0.031310416758060455,
      -0.030934713780879974,
      -0.011395243927836418,
      0.041368626058101654,
      0.03388684242963791,
      -0.020519893616437912,
      0.04942682012915611,
      0.1144365444779396,
      0.0025886776857078075,
      -0.02167109027504921,
      -0.07691413909196854,
      -0.017300745472311974,
      0.033249206840991974,
      -0.011821400374174118,
      -0.022972824051976204,
      0.019177516922354698,
      -0.008760495111346245,
      -0.014895031228661537,
      -0.032299287617206573,
      -0.04456540569663048,
      -0.03743400052189827,
      -0.05291219800710678,
      -0.008985014632344246,
      0.014254205860197544,
      -0.012188129127025604,
      0.010717376135289669,
      -0.02111630327999592,
      -0.0179459098726511,
      -0.02583698369562626,
      -0.009994683787226677,
      0.0854133740067482,
      0.020396031439304352,
      -0.0646946132183075,
      -0.030471159145236015,
      0.0016119672218337655,
      0.026462409645318985,
      -0.0028843204490840435,
      -0.0038782896008342505,
      0.00537865050137043,
      0.007230372633785009,
      0.007825990207493305,
      -0.006704914383590221,
      0.04892599582672119,
      0.02425266243517399,
      -0.02965601719915867,
      -0.05095198377966881,
      -0.008232704363763332,
      0.021482743322849274,
      -0.004013343248516321,
      0.03519924357533455,
      0.07018362730741501,
      -0.0449979342520237,
      0.06322286278009415,
      -0.0067703756503760815,
      -0.007716388441622257,
      0.045431558042764664,
      -0.06982170045375824,
      -0.04892098531126976,
      -0.01885364204645157,
      -0.006171392276883125,
      -0.03959211707115173,
      0.012525378726422787,
      -0.005710623227059841,
      -0.013721802271902561,
      0.05736267566680908,
      0.026411674916744232,
      -0.028240222483873367,
      -0.02630401961505413,
      0.03519277274608612,
      0.11073415726423264,
      0.03206738457083702,
      -0.0781841054558754,
      -0.07631094753742218,
      -0.015430492348968983,
      -0.04336744174361229,
      0.03104482777416706,
      0.0370185561478138,
      -0.02159331738948822,
      0.006315734703093767,
      -0.03912443667650223,
      0.11308066546916962,
      0.028115827590227127,
      -0.04306606575846672,
      0.0037859631702303886,
      -0.03717350214719772,
      0.048177413642406464,
      0.04382696747779846,
      0.0030685854144394398,
      -0.01661398448050022,
      -0.002893975004553795,
      -0.08397608250379562,
      0.048528559505939484,
      0.006342137698084116,
      0.06055193021893501,
      0.02887457050383091,
      -0.01484382152557373,
      0.0916445404291153,
      -0.08287525922060013,
      -0.0008803422097116709,
      -0.038256462663412094,
      -0.09771415591239929,
      0.007923860102891922,
      -0.035691339522600174,
      -0.0026052535977214575,
      -0.04004836827516556,
      0.016708800569176674,
      0.007972239516675472,
      0.02072540484368801,
      -0.026421597227454185,
      -0.008004719391465187,
      -0.03050507791340351,
      0.010831971652805805,
      0.056110698729753494,
      -0.03566891700029373,
      -0.07461199909448624,
      0.02391083538532257,
      -0.03338370844721794,
      0.03870977833867073,
      0.015503623522818089,
      -0.024966150522232056,
      0.009097558446228504,
      0.03063153475522995,
      -0.05426210165023804,
      -0.06820318847894669,
      -0.024398203939199448,
      0.06346669048070908,
      -0.06952337920665741,
      0.08022390305995941,
      0.00351683865301311,
      -0.0526888482272625,
      -0.03721759468317032,
      -0.05540485307574272,
      0.022116923704743385,
      0.022274769842624664,
      -0.03151450306177139,
      0.07092907279729843,
      -0.010099358856678009,
      0.05107481777667999,
      -0.10188324004411697,
      0.047193389385938644,
      -0.004104431718587875,
      -0.03202382102608681,
      0.03283010423183441,
      -0.0038972364272922277,
      0.017178097739815712,
      0.030042769387364388,
      -0.023950764909386635,
      0.020558414980769157,
      -0.03197808191180229,
      0.01068064384162426,
      0.04895544424653053,
      0.014803959056735039,
      -0.0682159960269928,
      0.026095280423760414,
      -0.016748910769820213,
      0.019639085978269577,
      0.00749631691724062,
      -0.010446942411363125,
      -0.022682780399918556,
      -0.006718693766742945,
      -0.024895772337913513,
      -0.07895192503929138,
      0.031075414270162582,
      -0.0040842765010893345,
      -0.005789651069790125,
      -0.09138957411050797,
      0.03711570426821709,
      -0.04591859132051468,
      -0.01816546358168125,
      0.0059184543788433075,
      -0.011465870775282383,
      0.0016697457758709788,
      0.054228197783231735,
      -0.06049467995762825,
      0.004213610198348761,
      0.035715337842702866,
      0.017718838527798653,
      0.00415233988314867,
      -0.018039168789982796,
      -0.02258151024580002,
      0.015466743148863316,
      0.0003555161820258945,
      0.02230554260313511,
      0.005517725367099047,
      -0.062336649745702744,
      -0.04070790857076645,
      0.014994844794273376,
      0.013839401304721832,
      0.008259774185717106,
      0.04104894772171974,
      0.02041826769709587,
      -0.09467008709907532,
      0.0940704494714737,
      0.010628113523125648,
      0.013333911076188087,
      -0.02585642784833908,
      -0.020199330523610115,
      -0.06093304231762886,
      -0.018722020089626312,
      -0.004807142540812492,
      0.12363297492265701,
      0.05233311280608177,
      0.009367113932967186,
      -0.018840627744793892,
      -0.05795130133628845,
      -0.020057212561368942,
      0.022723376750946045,
      -0.043781593441963196,
      0.002745285164564848,
      0.017454085871577263,
      -0.04733852297067642,
      0.0005685482756234705,
      -0.017309360206127167,
      0.08458122611045837,
      0.002802526345476508,
      0.05858075991272926,
      0.01135749090462923,
      -0.021132083609700203,
      -0.006842636037617922,
      -0.09796025604009628,
      0.017420709133148193,
      -0.03641713410615921,
      0.08673687279224396,
      -0.006250846665352583,
      0.06262011080980301,
      -0.019323153421282768,
      0.05894307792186737,
      -0.021722204983234406,
      -0.010949736461043358,
      0.037077877670526505,
      0.08937816321849823,
      0.0023563778959214687,
      -0.022778136655688286,
      -0.022923385724425316,
      -0.04893237724900246,
      -0.07764536887407303,
      0.016862429678440094,
      0.013714534230530262,
      -0.03040962852537632,
      -0.04752831161022186,
      0.027686847373843193,
      -0.02841274067759514,
      0.0035530799068510532,
      -0.03284379839897156,
      -0.0441531240940094,
      -0.03837888315320015,
      -0.018528209999203682,
      0.08017048984766006,
      -0.038414906710386276,
      -0.006962046958506107,
      -0.02875419147312641,
      0.051923856139183044,
      -0.026206158101558685,
      -0.04673318564891815,
      0.05430428311228752,
      -0.03175254911184311,
      0.03960998356342316,
      0.08977572619915009,
      -0.05564560368657112,
      -0.007594032678753138,
      -0.0674000084400177,
      -0.04148425906896591,
      -0.08118165284395218,
      -0.013153152540326118,
      0.020459868013858795,
      0.0487847663462162,
      -0.03214354068040848,
      0.024853968992829323,
      0.07016485929489136,
      0.015866391360759735,
      -0.03419449180364609,
      -0.004467821214348078,
      0.021297192201018333,
      -0.005109916441142559,
      0.07120830565690994,
      -0.036136046051979065,
      0.035201553255319595,
      0.07942836731672287,
      0.011718057096004486,
      -0.035685282200574875,
      -0.05523533746600151,
      -0.0925619900226593,
      0.04700234532356262,
      -0.01128984335809946,
      -0.02570030838251114,
      0.05800503119826317,
      0.016877587884664536,
      -0.07573708146810532,
      -0.03333337977528572,
      -0.0040981885977089405,
      0.04296703264117241,
      0.030894529074430466,
      0.02278134971857071,
      0.07473918050527573,
      -0.03464466705918312,
      0.0024864808656275272,
      0.056316129863262177,
      -0.04235176742076874,
      -0.039057254791259766,
      0.05709688737988472,
      0.06765783578157425,
      0.05019151046872139,
      0.0036469793412834406,
      -0.004071640316396952,
      0.049545932561159134,
      -0.003876212751492858,
      -0.023192277178168297,
      -0.03486528992652893,
      0.005801120772957802,
      0.0537436418235302,
      0.03562993183732033,
      -0.08285055309534073,
      0.00034039243473671377,
      -0.0037265713326632977,
      -0.023823468014597893,
      -0.005501265171915293,
      0.015378898940980434,
      -0.061302121728658676,
      -0.00711580365896225,
      0.03368049114942551,
      0.018714969977736473,
      -0.015908215194940567,
      -0.06495463848114014,
      0.04553784802556038,
      -0.09813731163740158,
      0.0013508342672139406,
      -0.06715751439332962,
      -0.024602923542261124,
      0.06616279482841492,
      -0.017720846459269524,
      0.002881207736209035,
      -0.037754714488983154,
      -0.005276522599160671,
      -0.0018189764814451337,
      0.010387124493718147,
      -0.04284520819783211,
      0.03742052614688873,
      -0.00320153939537704,
      -0.006991909816861153,
      -0.04224758222699165,
      -0.038363274186849594,
      -0.010135816410183907,
      -0.06708851456642151,
      0.0015022211009636521,
      0.010593813844025135,
      0.011713746003806591,
      0.03174598887562752,
      -0.02363988570868969,
      0.03438685089349747,
      0.028129328042268753,
      -0.012762387283146381,
      -0.021271962672472,
      0.05909736081957817,
      -0.08520197868347168,
      -0.014091943390667439,
      0.006465431302785873,
      0.02618909254670143,
      -0.011239846237003803,
      -0.04770766198635101,
      0.09565446525812149,
      0.017108332365751266,
      0.025634989142417908,
      -0.010310913436114788,
      0.05278530716896057,
      0.06845071911811829,
      -0.050909679383039474,
      -0.039502836763858795,
      0.06895451247692108,
      -0.009699802845716476,
      0.00963575392961502,
      0.11478405445814133,
      -0.0637596920132637,
      0.027307216078042984,
      0.06185111403465271,
      0.005624322220683098,
      0.07155515998601913,
      -0.08334207534790039,
      -0.0019239387474954128,
      0.0388122983276844,
      0.09083873778581619,
      -0.0590069442987442,
      0.04012163355946541,
      -0.019882310181856155,
      -0.049500755965709686,
      0.013064105994999409,
      0.0073055606335401535,
      0.037976108491420746,
      -0.001498695113696158,
      0.008087516762316227,
      0.02420312911272049,
      -0.10457117855548859,
      0.031306225806474686,
      -0.039185311645269394,
      -0.07617226988077164,
      -0.022529935464262962,
      0.05576471611857414,
      0.031143728643655777,
      -0.05629388615489006,
      -0.005458894185721874,
      -0.08032795786857605,
      0.007207588758319616,
      0.049244076013565063,
      -0.01567012071609497,
      0.10914405435323715,
      -0.06005363166332245,
      0.006092785857617855,
      -0.025293780490756035,
      -0.0067238761112093925,
      -0.0609811507165432,
      -0.012256666086614132,
      0.0013921657809987664,
      -0.037207312881946564,
      -0.005436424631625414,
      -0.063524529337883,
      -0.09064410626888275,
      0.06613581627607346,
      0.05487523972988129,
      0.01247221976518631,
      0.029675347730517387,
      -0.0653546005487442,
      0.024210656061768532,
      -0.0356164276599884,
      -0.014852629043161869,
      0.01650857739150524,
      0.05395904555916786,
      0.03517008200287819,
      0.029554452747106552,
      0.031099984422326088
    ],
    [
      -0.01815728470683098,
      -0.023144233971834183,
      -0.07864490896463394,
      -0.06646761298179626,
      0.03373587131500244,
      0.006275830790400505,
      0.048550188541412354,
      0.0010813167318701744,
      -0.04975857213139534,
      0.10494557023048401,
      0.03672390803694725,
      -0.0021410968620330095,
      0.06275156140327454,
      -0.009412565268576145,
      0.04386496543884277,
      0.07324956357479095,
      -0.03985767439007759,
      0.03319556266069412,
      -0.01527430023998022,
      0.05938008427619934,
      -0.033512454479932785,
      0.012674534693360329,
      0.09161066263914108,
      0.05810791999101639,
      0.001401639310643077,
      0.03548317030072212,
      -0.03912576287984848,
      -0.031767409294843674,
      -0.05229268595576286,
      -0.04179893061518669,
      0.07056404650211334,
      0.05288226902484894,
      -0.012888309545814991,
      -0.02555139549076557,
      0.002578320447355509,
      0.020838726311922073,
      -0.029746651649475098,
      0.04510459303855896,
      0.0031351724173873663,
      -0.03766245394945145,
      -0.07673181593418121,
      -0.01677051931619644,
      -0.03944774717092514,
      -0.012528499588370323,
      -0.012133534997701645,
      -0.07238592952489853,
      -0.028734704479575157,
      0.020375434309244156,
      -0.021748384460806847,
      -0.003510640235617757,
      0.020886700600385666,
      0.0037113712169229984,
      -0.0673259049654007,
      0.03432876616716385,
      -0.023144783452153206,
      -0.0429263561964035,
      0.031492412090301514,
      -0.0010302806040272117,
      -0.016940269619226456,
      -0.038108084350824356,
      -0.020367654040455818,
      0.017484601587057114,
      0.049063440412282944,
      -0.036799654364585876,
      -0.027592936530709267,
      0.011310949921607971,
      0.015031220391392708,
      0.003639964386820793,
      0.015124292112886906,
      -0.03925119340419769,
      0.012570047751069069,
      -0.023144271224737167,
      0.05167375132441521,
      0.054428763687610626,
      -0.016300123184919357,
      -0.0025021801702678204,
      -0.06841695308685303,
      -0.07804284989833832,
      0.03927941620349884,
      0.011628832668066025,
      -0.0180713701993227,
      0.0369357168674469,
      -0.0005811569280922413,
      -0.014243099838495255,
      -0.05216386541724205,
      0.0032163283322006464,
      0.049048956483602524,
      -0.05862892046570778,
      -0.03607719764113426,
      -0.043173037469387054,
      -0.004626878537237644,
      -0.011652164161205292,
      -0.033581361174583435,
      0.004401667043566704,
      0.010883302427828312,
      -0.017265748232603073,
      -0.06328905373811722,
      0.03579139709472656,
      0.0024094916880130768,
      0.010321695357561111,
      0.058156002312898636,
      0.07863166928291321,
      0.052192769944667816,
      -0.05336692929267883,
      0.05501604452729225,
      -0.012464838102459908,
      0.017755677923560143,
      0.020970840007066727,
      -0.017199179157614708,
      -0.015217547304928303,
      0.09458254277706146,
      0.0350465290248394,
      0.05682345852255821,
      -0.08152834326028824,
      -0.017915058881044388,
      0.005842470098286867,
      0.0721132755279541,
      -0.051087576895952225,
      -0.033677682280540466,
      0.038061413913965225,
      -0.05570782348513603,
      0.004188414663076401,
      -0.03264625743031502,
      0.03229711204767227,
      -0.004961356986314058,
      0.03329410403966904,
      0.06036626547574997,
      -0.04494871944189072,
      0.020667411386966705,
      -0.05041937157511711,
      0.020745381712913513,
      -0.07264316827058792,
      -0.03955975919961929,
      -0.0038287790957838297,
      -0.04830849915742874,
      -0.04716742783784866,
      -0.020063910633325577,
      -0.0190731193870306,
      0.11572372168302536,
      -0.004425708204507828,
      -0.017767399549484253,
      0.0561080276966095,
      0.10436545312404633,
      0.0281987264752388,
      -0.003381170565262437,
      0.004510736558586359,
      -0.031159058213233948,
      0.030651746317744255,
      -0.03662655130028725,
      0.00997195765376091,
      0.04454156011343002,
      -0.020031433552503586,
      -0.028615055605769157,
      -0.027567513287067413,
      -0.09708945453166962,
      0.04867243766784668,
      0.025388680398464203,
      0.042450759559869766,
      0.07821141928434372,
      -0.011661089956760406,
      0.029735537245869637,
      -0.0003368169709574431,
      -0.0003609132254496217,
      -0.029969466850161552,
      -0.07341354340314865,
      0.026983048766851425,
      -0.0006467225612141192,
      0.024604476988315582,
      -0.08286051452159882,
      -0.013292178511619568,
      -0.025267591699957848,
      0.05193821340799332,
      0.000542934169061482,
      -0.03351259231567383,
      0.006396746262907982,
      0.006092464085668325,
      0.054225627332925797,
      0.002631118521094322,
      -0.006740414537489414,
      -0.02394169755280018,
      -0.015429489314556122,
      -0.008871903643012047,
      -0.04016900807619095,
      0.030496008694171906,
      -0.020936602726578712,
      -0.004996026400476694,
      -0.022692641243338585,
      0.05735765025019646,
      -0.04189920425415039,
      -0.059266746044158936,
      -0.036539264023303986,
      0.007935350760817528,
      0.02650364860892296,
      -0.02237337827682495,
      -0.05531610921025276,
      0.057997241616249084,
      0.04156549274921417,
      0.0015849866904318333,
      -0.0349990613758564,
      -0.02419384941458702,
      0.02323358878493309,
      -0.036688629537820816,
      0.03846709802746773,
      -0.06676208972930908,
      -0.05267508327960968,
      0.11049266159534454,
      0.027908340096473694,
      -0.027620820328593254,
      0.03267953172326088,
      0.009417248889803886,
      0.05401924252510071,
      -0.0211662445217371,
      0.03456413373351097,
      -0.00037695225910283625,
      0.015987690538167953,
      -0.018974745646119118,
      -0.05505193769931793,
      -0.012938043102622032,
      0.03704971820116043,
      0.0874612033367157,
      -0.004706914536654949,
      -0.043876778334379196,
      0.04674241691827774,
      0.032792165875434875,
      -0.009143387898802757,
      0.013032978400588036,
      -0.0040470571257174015,
      -0.07222092151641846,
      0.011282496154308319,
      -0.0030673358123749495,
      0.0010614474304020405,
      -0.020664116367697716,
      -0.06379926204681396,
      0.05028911679983139,
      -0.041156161576509476,
      0.0619162991642952,
      0.039311278611421585,
      -0.010333648882806301,
      0.10854627192020416,
      0.06557001918554306,
      -0.006680931895971298,
      0.011347114108502865,
      -0.059277214109897614,
      -0.013137546367943287,
      -0.013548307120800018,
      0.1279018521308899,
      0.05076182633638382,
      0.09287814050912857,
      -0.018991775810718536,
      0.019845180213451385,
      0.003006775164976716,
      0.015456687659025192,
      -0.03317968174815178,
      0.04006800428032875,
      0.044758643954992294,
      -0.014388817362487316,
      -0.031957436352968216,
      -0.046221353113651276,
      -0.035005729645490646,
      0.02020370215177536,
      -0.059123676270246506,
      -0.012313497252762318,
      0.054290901869535446,
      -0.02863396517932415,
      -0.04997812584042549,
      -0.0039273458532989025,
      0.07320190221071243,
      0.053993284702301025,
      -0.02731224149465561,
      0.014627148397266865,
      -0.0025871749967336655,
      -0.02352130599319935,
      -0.03063712641596794,
      0.11215415596961975,
      -0.011846090666949749,
      0.039562538266181946,
      0.016961121931672096,
      -0.06199870631098747,
      0.0027545499615371227,
      -0.05742432177066803,
      -0.06260591745376587,
      0.004252532962709665,
      0.025315456092357635,
      -0.0009908475913107395,
      -0.006468131206929684,
      -0.009157885797321796,
      -0.05580008402466774,
      -0.027795450761914253,
      -0.005883566569536924,
      0.0506213903427124,
      -0.027773704379796982,
      -0.06680354475975037,
      -0.07685153186321259,
      0.06832742691040039,
      0.025729797780513763,
      -0.016734004020690918,
      0.0331878624856472,
      -0.01176171749830246,
      0.04024481400847435,
      -0.05231533572077751,
      -0.04181623458862305,
      0.036572400480508804,
      0.06355148553848267,
      0.02296481840312481,
      0.07276517897844315,
      0.010637189261615276,
      0.09865052253007889,
      -0.004167299717664719,
      -0.013309468515217304,
      0.06914103776216507,
      -0.0056859469041228294,
      -0.07799281179904938,
      -0.024986209347844124,
      -0.08845361322164536,
      0.08161725103855133,
      0.011253442615270615,
      -0.014989531598985195,
      0.02573392353951931,
      0.005183709319680929,
      -0.044999562203884125,
      0.009519079700112343,
      0.02584141679108143,
      0.06558267772197723,
      -0.0015770797617733479,
      0.01343332789838314,
      0.03522849082946777,
      -0.06615906953811646,
      -0.031346146017313004,
      0.009359177201986313,
      -0.039336901158094406,
      0.006590024568140507,
      -0.007884498685598373,
      0.07288974523544312,
      -0.004180121701210737,
      -0.01250162348151207,
      -0.025445202365517616,
      -0.019755195826292038,
      0.021486511453986168,
      0.044120337814092636,
      -0.09004214406013489,
      -0.027430392801761627,
      0.027622686699032784,
      0.024766039103269577,
      0.00785372406244278,
      0.010726667940616608,
      0.014394352212548256,
      -0.020024921745061874,
      0.014010795392096043,
      0.011815765872597694,
      -0.013042863458395004,
      -0.0016558205243200064,
      -0.06840118020772934,
      0.013369047082960606,
      0.010885322466492653,
      0.02174132503569126,
      0.040712375193834305,
      0.007271504960954189,
      0.0868605524301529,
      -0.10907731205224991,
      -0.0236029252409935,
      0.020245736464858055,
      -0.05539322271943092,
      0.013009997084736824,
      -0.006398957688361406,
      -0.03205729275941849,
      -0.03963370621204376,
      -0.029106242582201958,
      0.059426695108413696,
      -0.036951251327991486,
      0.013388312421739101,
      0.08996380865573883,
      -0.010441853664815426,
      -0.07231481373310089,
      -0.009169685654342175,
      -0.00048006029101088643,
      0.06516952067613602,
      0.022079074755311012,
      -0.06472890824079514,
      0.009049739688634872,
      -0.01865144819021225,
      0.04114970564842224,
      0.07705066353082657,
      0.046215616166591644,
      0.013930847868323326,
      0.03626853972673416,
      0.03755994141101837,
      -0.016247207298874855,
      0.014609474688768387,
      -0.04643622040748596,
      -0.06759711354970932,
      -0.00616039801388979,
      0.0399869829416275,
      0.0017618004931136966,
      -0.013812238350510597,
      0.013934295624494553,
      -0.03513393551111221,
      0.14926522970199585,
      -0.02822522073984146,
      0.008587921038269997,
      0.015935705974698067,
      0.03589903935790062,
      -0.03729153797030449,
      0.012053986079990864,
      0.07876326888799667,
      -0.057059623301029205,
      -0.0031870545353740454,
      -0.014927329495549202,
      -0.03938037529587746,
      -0.010760555975139141,
      -0.008532289415597916,
      0.0676051527261734,
      0.01957285962998867,
      0.00459696352481842,
      -0.0579637810587883,
      0.0775521770119667,
      0.012140925973653793,
      -0.014449392445385456,
      0.051068857312202454,
      -0.04791725426912308,
      -0.03053966909646988,
      0.003990925382822752,
      0.09110129624605179,
      -0.011373216286301613,
      0.00745551660656929,
      0.025960756465792656,
      -0.04642392694950104,
      -0.019909316673874855,
      0.0727042630314827,
      0.012756725773215294,
      0.0464530885219574,
      -0.026452509686350822,
      0.0017821696819737554,
      0.033859360963106155,
      -0.021332981064915657,
      -0.015371143817901611,
      -0.004666305612772703,
      -0.04137272760272026,
      0.007741069421172142,
      0.045015957206487656,
      0.026925090700387955,
      0.07099868357181549,
      0.06633326411247253,
      -0.02853788435459137,
      -0.048568256199359894,
      0.024568775668740273,
      -0.03383656591176987,
      0.0929865762591362,
      0.015408435836434364,
      -0.008254889398813248,
      -0.025057828053832054,
      0.010572756640613079,
      -0.0261736698448658,
      -0.0604589506983757,
      -0.02267068810760975,
      -0.0045937346294522285,
      -0.00427818950265646,
      0.052138324826955795,
      -0.05048968642950058,
      0.013705666176974773,
      -0.04855085164308548,
      -0.04593057557940483,
      -0.03523578494787216,
      0.022976936772465706,
      0.03464037925004959,
      -0.02076149731874466,
      0.025222541764378548,
      0.002172664273530245,
      -0.02693382278084755,
      0.003797932993620634,
      0.008281273767352104,
      -0.010482970625162125,
      -0.11945335566997528,
      0.05337879806756973,
      -0.011072538793087006,
      0.06949678808450699,
      0.140605628490448,
      -0.04459967091679573,
      -0.054814305156469345,
      0.0829956904053688,
      0.03276492655277252,
      -0.046051427721977234,
      -0.02691437490284443,
      -0.05930691212415695,
      -0.03246370330452919,
      -0.03067898191511631,
      0.08449634164571762,
      0.09541510790586472,
      -0.0004333351971581578,
      0.011990804225206375,
      -0.07509186118841171,
      0.0030790413729846478,
      -0.057549357414245605,
      -0.025222759693861008,
      0.022749146446585655,
      -0.015017435885965824,
      0.06518995016813278,
      -0.029349451884627342,
      -0.0115607138723135,
      0.06775949895381927,
      -0.00819273293018341,
      -0.022094301879405975,
      0.007476480212062597,
      0.014076430350542068
    ],
    [
      -0.0038228905759751797,
      0.022059494629502296,
      -0.0003369303303770721,
      0.04391717165708542,
      0.0361534021794796,
      0.03188367560505867,
      0.00369510380551219,
      -0.09477006644010544,
      0.0196645800024271,
      0.025919029489159584,
      0.002787036122754216,
      0.0470961295068264,
      0.015915818512439728,
      0.017773201689124107,
      -0.005143830087035894,
      0.010830117389559746,
      0.001502597937360406,
      -0.006875862367451191,
      0.012415348552167416,
      -0.008466418832540512,
      -0.009529909119009972,
      -0.1247953325510025,
      0.0876341238617897,
      -0.016109874472022057,
      0.01929604820907116,
      0.018371781334280968,
      0.03284946829080582,
      -0.0464625358581543,
      0.02136537991464138,
      -0.023321596905589104,
      0.07543828338384628,
      -0.028827199712395668,
      -0.04523197188973427,
      4.5437365770339966e-05,
      0.0039103226736187935,
      -0.02135837823152542,
      0.019166426733136177,
      -0.048813872039318085,
      -0.07077060639858246,
      0.02098698355257511,
      0.001349645433947444,
      -0.06236550956964493,
      0.012866565026342869,
      0.005677016917616129,
      -0.06993915885686874,
      -0.019792156293988228,
      -0.004520496353507042,
      -0.024848660454154015,
      0.10480912029743195,
      0.006307551637291908,
      0.04320977255702019,
      0.006127888802438974,
      -0.04387044161558151,
      -0.042717911303043365,
      0.02401348575949669,
      -0.017006652429699898,
      0.01935424655675888,
      0.06077853590250015,
      0.010531162843108177,
      0.04291281849145889,
      0.02277436852455139,
      0.09132663905620575,
      -0.013293149881064892,
      0.058695290237665176,
      -0.03990260511636734,
      0.11477329581975937,
      0.0682699903845787,
      -0.0008301723864860833,
      -0.017437400296330452,
      -0.05602846294641495,
      0.032554857432842255,
      -0.024141378700733185,
      -0.0006217192276380956,
      -0.04794980585575104,
      0.0026690445374697447,
      -0.0007316151168197393,
      0.05337138846516609,
      -0.007466447539627552,
      -0.023698395118117332,
      -0.005841095931828022,
      0.03761828690767288,
      0.016497965902090073,
      -0.03778421878814697,
      0.030271993950009346,
      -0.05177649110555649,
      0.09600210189819336,
      -0.013287085108458996,
      -0.07268111407756805,
      0.07138389348983765,
      0.043888483196496964,
      -0.06143872067332268,
      0.025404054671525955,
      -0.048435237258672714,
      -0.0362212136387825,
      0.006592513062059879,
      -0.004710376262664795,
      -0.02459869347512722,
      -0.11144989728927612,
      -0.034076523035764694,
      0.041854798793792725,
      0.011430536396801472,
      -0.030812174081802368,
      0.002991648856550455,
      -0.030336454510688782,
      0.014595984481275082,
      0.037253279238939285,
      -0.00017692649271339178,
      0.009960918687283993,
      -0.043915655463933945,
      -0.01149109099060297,
      0.021966297179460526,
      0.09911185503005981,
      0.06827474385499954,
      0.06203686073422432,
      -0.06576520949602127,
      -0.04369540512561798,
      -0.006588146090507507,
      0.006855424493551254,
      0.014613344334065914,
      0.011264355853199959,
      0.032445717602968216,
      -0.030806634575128555,
      0.0464213527739048,
      -0.024230800569057465,
      -0.016980953514575958,
      0.020195184275507927,
      -0.004155591130256653,
      0.009533367119729519,
      -0.03512747585773468,
      -0.0568617582321167,
      -0.09602580219507217,
      0.01124025508761406,
      0.06363286077976227,
      0.019346268847584724,
      0.00023483084805775434,
      -0.013598560355603695,
      0.14932656288146973,
      -0.01093695592135191,
      -0.03273439407348633,
      -0.05225347727537155,
      0.04783330485224724,
      -0.012330630794167519,
      0.14076054096221924,
      -0.0005823597894050181,
      0.026809198781847954,
      0.052635557949543,
      -0.038420096039772034,
      -0.0008548113401047885,
      0.01536358892917633,
      -0.04699501022696495,
      -0.007699250243604183,
      0.09259434044361115,
      0.03612646460533142,
      -0.04383526369929314,
      -0.015393145382404327,
      0.04145674780011177,
      -0.05395780876278877,
      0.01699013076722622,
      0.0017892619362100959,
      -0.03504941612482071,
      -0.0556267648935318,
      -0.06191917881369591,
      -0.029346810653805733,
      -0.007879514247179031,
      0.013498486950993538,
      0.007211849093437195,
      0.0703846737742424,
      0.0014774706214666367,
      -0.005560181103646755,
      -0.06039958447217941,
      -0.025484837591648102,
      -0.05758877843618393,
      -0.009319269098341465,
      -0.029904237017035484,
      0.03274727612733841,
      -0.031738411635160446,
      -0.05617091804742813,
      -0.024306707084178925,
      0.05542229115962982,
      0.020355865359306335,
      0.03891146928071976,
      0.0429668202996254,
      0.011855604127049446,
      0.03374898061156273,
      0.026302944868803024,
      -0.05755691975355148,
      -0.0019153484608978033,
      0.022744525223970413,
      0.015416827984154224,
      -0.017941877245903015,
      0.08171923458576202,
      0.0062026516534388065,
      -0.016699284315109253,
      0.0701959952712059,
      -0.015723027288913727,
      -0.037130698561668396,
      0.029158947989344597,
      -0.050473637878894806,
      -0.03392850607633591,
      0.0034432532265782356,
      -0.00030392876942642033,
      0.04810090363025665,
      -0.06036911904811859,
      -0.049088168889284134,
      0.0834147036075592,
      -0.012166752479970455,
      0.07935310155153275,
      0.015160344541072845,
      0.06890325993299484,
      -0.06127656251192093,
      0.0660652220249176,
      0.029640398919582367,
      -0.02291106805205345,
      -0.01106027327477932,
      -0.02383733168244362,
      -0.061899036169052124,
      0.007086087483912706,
      -0.04940594732761383,
      0.030630173161625862,
      0.1233697384595871,
      0.09353204071521759,
      0.027437465265393257,
      0.003014996415004134,
      -0.013935272581875324,
      -0.005656648892909288,
      -0.04703615605831146,
      -0.0497976616024971,
      -0.032125670462846756,
      0.08769741654396057,
      -0.07835420221090317,
      0.053151294589042664,
      0.014945137314498425,
      0.054240304976701736,
      0.048979755491018295,
      0.02293945662677288,
      -0.0414588488638401,
      -0.022404680028557777,
      -0.019133402034640312,
      -0.014708349481225014,
      -0.019253015518188477,
      -0.09004462510347366,
      0.0434059202671051,
      -0.053909748792648315,
      0.11391375958919525,
      0.03428678959608078,
      0.021101640537381172,
      0.004719190299510956,
      0.01320494432002306,
      -0.030030833557248116,
      0.02954818122088909,
      0.06681148707866669,
      0.055650923401117325,
      -0.05089256539940834,
      0.022541187703609467,
      -0.08838986605405807,
      -0.0024427331518381834,
      -0.02194385416805744,
      -0.027089867740869522,
      -0.09038573503494263,
      -0.01749989204108715,
      0.002700050361454487,
      0.011183973401784897,
      0.06653349101543427,
      -0.021027572453022003,
      -0.0060537271201610565,
      -0.05533071234822273,
      -0.01032568421214819,
      0.0049163480289280415,
      -0.047341857105493546,
      -0.061386190354824066,
      -0.05841625854372978,
      -0.02286214381456375,
      -0.0024040795397013426,
      -0.0035321658942848444,
      0.08699404448270798,
      0.002829999662935734,
      -0.03608338534832001,
      0.0430850125849247,
      -0.029632586985826492,
      -0.0931805819272995,
      -0.029335130006074905,
      0.007462736684828997,
      -0.016234638169407845,
      -0.08761908113956451,
      -0.07621174305677414,
      -0.016581812873482704,
      0.030337736010551453,
      -0.08335980772972107,
      0.02768605202436447,
      0.030526459217071533,
      0.07444604486227036,
      0.02544715814292431,
      -0.05645410344004631,
      0.044078998267650604,
      -0.06734579801559448,
      0.002818336011841893,
      -0.009563243016600609,
      0.012199967168271542,
      0.037837449461221695,
      -0.029303284361958504,
      0.04758670926094055,
      0.006857618223875761,
      0.006162156350910664,
      0.00956769660115242,
      -0.04788128659129143,
      -0.06713420152664185,
      -0.01863335631787777,
      0.017365392297506332,
      0.017214996740221977,
      -0.0504426509141922,
      0.012264381162822247,
      0.07953039556741714,
      -0.029957344755530357,
      0.012915962375700474,
      0.0025810447987169027,
      0.01404472254216671,
      0.013652347959578037,
      -0.015602865256369114,
      0.12430126965045929,
      0.027927394956350327,
      0.051820434629917145,
      0.07257533073425293,
      0.024825653061270714,
      -0.04700399562716484,
      -0.06201982870697975,
      0.055996667593717575,
      0.0126432403922081,
      0.002117360010743141,
      -0.05652780458331108,
      0.040290746837854385,
      0.006894149351865053,
      0.018746066838502884,
      0.04472000524401665,
      0.05767037346959114,
      0.06586116552352905,
      0.03731541708111763,
      0.009355754591524601,
      0.019629716873168945,
      -0.02097507193684578,
      -0.0762120857834816,
      0.0783684253692627,
      -0.023634428158402443,
      0.019245781004428864,
      0.013461046852171421,
      -0.01059243269264698,
      -0.06506963074207306,
      0.10849486291408539,
      -0.03258310630917549,
      -0.04973704367876053,
      0.015371458604931831,
      0.03885076940059662,
      0.02708229050040245,
      -0.11839529126882553,
      -0.059378016740083694,
      -0.039490681141614914,
      -0.02072807215154171,
      0.05173459276556969,
      0.04881728067994118,
      0.06502209603786469,
      0.07040248066186905,
      0.007750418037176132,
      0.020486492663621902,
      0.05565417557954788,
      0.026099547743797302,
      -0.02299613133072853,
      9.352811503049452e-06,
      -0.04118705913424492,
      0.02983826771378517,
      -0.10076949745416641,
      0.1221405640244484,
      -0.01219317689538002,
      0.05160003527998924,
      0.005215936806052923,
      -0.009158137254416943,
      0.02905738167464733,
      0.0520382896065712,
      -0.04089962691068649,
      -0.08992475271224976,
      -0.025674598291516304,
      -0.0077428631484508514,
      -0.060855258256196976,
      0.005788785871118307,
      -0.019697627052664757,
      -0.017392873764038086,
      -0.0445825532078743,
      0.002040853025391698,
      -0.05582715570926666,
      0.02712181769311428,
      -0.07666139304637909,
      -0.01855381578207016,
      -0.02710701897740364,
      -0.026090292260050774,
      -0.10990890115499496,
      -0.01488534826785326,
      0.0076829916797578335,
      -0.04215797781944275,
      0.018374552950263023,
      -0.006890931632369757,
      0.008700046688318253,
      0.086863674223423,
      0.041462793946266174,
      -0.029358025640249252,
      -0.047118913382291794,
      -0.013278794474899769,
      -0.03245401382446289,
      -0.03857997804880142,
      0.018720388412475586,
      0.018647946417331696,
      -0.04482303932309151,
      0.01534514594823122,
      0.02283770777285099,
      0.0070618134923279285,
      0.10206957161426544,
      -0.01589631289243698,
      0.03514503687620163,
      -0.011996954679489136,
      0.04568975791335106,
      0.00015103413898032159,
      -0.038505684584379196,
      0.019531335681676865,
      -0.0022168790455907583,
      0.045173268765211105,
      0.02608443610370159,
      -0.077495276927948,
      -0.012793920934200287,
      0.00552916806191206,
      0.015590173192322254,
      -0.007766173221170902,
      -0.006877780891954899,
      -0.0035589749459177256,
      -0.02073398418724537,
      -0.01740972138941288,
      0.05744452401995659,
      -0.02016463689506054,
      0.015283131040632725,
      0.041411761194467545,
      0.029021183028817177,
      -0.05096929892897606,
      -0.0081930635496974,
      -0.026405734941363335,
      0.015506225638091564,
      0.025224437937140465,
      -0.02468847669661045,
      0.002535291248932481,
      0.03490956500172615,
      0.03975384682416916,
      0.06452420353889465,
      -0.01316255982965231,
      -0.008538533002138138,
      -0.026719115674495697,
      -0.08782123029232025,
      -0.08033505082130432,
      -0.0716298371553421,
      -0.023745059967041016,
      -0.014329152181744576,
      0.015278076753020287,
      -0.07044444978237152,
      -0.010105961933732033,
      -0.028567254543304443,
      0.031933896243572235,
      -0.1209508553147316,
      0.10889207571744919,
      -0.022755924612283707,
      -0.032412491738796234,
      0.08315630257129669,
      0.04504943639039993,
      -0.04137150198221207,
      0.0009963815100491047,
      0.018381446599960327,
      0.029856299981474876,
      -0.007543350104242563,
      -0.023521600291132927,
      0.03560241311788559,
      -0.09732835739850998,
      -0.04534526169300079,
      0.049543336033821106,
      0.0046539464965462685,
      0.0256888996809721,
      -0.006386294960975647,
      0.06080710515379906,
      0.03548581525683403,
      0.019029725342988968,
      -0.0360078401863575,
      0.06022097170352936,
      -0.026535460725426674,
      0.05192134529352188,
      -0.007929893210530281,
      -0.006810216698795557,
      -0.033678922802209854,
      -0.01211441121995449,
      0.020594323053956032,
      -0.006957475561648607,
      -0.06341381371021271,
      -0.04708727449178696,
      -0.06383731216192245,
      0.04567071422934532,
      0.03121713362634182,
      -0.02451195754110813,
      -0.044459760189056396,
      0.020033104345202446,
      -0.03391856700181961,
      0.03750082477927208,
      -0.05291713774204254
    ],
    [
      0.01197198685258627,
      0.03520337492227554,
      -0.004117536824196577,
      0.03534534573554993,
      -0.0644335076212883,
      0.027838610112667084,
      -0.013407440856099129,
      -0.051897499710321426,
      0.005804946180433035,
      0.0032371392007917166,
      0.022244172170758247,
      0.0010329135693609715,
      -0.009960144758224487,
      -0.0053017414174973965,
      0.015152287669479847,
      0.014042282477021217,
      0.007337294984608889,
      0.08375125378370285,
      -0.03734111413359642,
      -0.06477103382349014,
      0.07267958670854568,
      -0.019316619262099266,
      -0.08455489575862885,
      -0.04776092618703842,
      0.008907685056328773,
      -0.0322955884039402,
      -0.014755635522305965,
      -0.021327970549464226,
      0.0033724976237863302,
      0.03663095459342003,
      0.012697034515440464,
      0.001631750725209713,
      -0.02126631885766983,
      -0.010654054582118988,
      -0.03390071541070938,
      0.03031417541205883,
      0.06372050195932388,
      0.035729411989450455,
      0.04319450631737709,
      0.014889066107571125,
      -0.001908262725919485,
      0.020634116604924202,
      -0.032530009746551514,
      0.06807109713554382,
      0.06403820961713791,
      0.07164587825536728,
      0.1269068717956543,
      0.010993337258696556,
      -0.07777982950210571,
      -0.013264007866382599,
      0.061728961765766144,
      -0.03872890770435333,
      -0.028497790917754173,
      0.05420298129320145,
      -0.02211892046034336,
      -0.04806818068027496,
      -0.05238528177142143,
      -0.10549208521842957,
      -0.07922305911779404,
      0.0400981605052948,
      0.014172435738146305,
      -0.042095210403203964,
      0.03204383701086044,
      -0.04618459567427635,
      0.04911725968122482,
      0.020382873713970184,
      -0.029184509068727493,
      0.03113481216132641,
      -0.01954277791082859,
      0.06200655549764633,
      -0.04373214393854141,
      0.0038540647365152836,
      0.05364879593253136,
      0.026061994954943657,
      -0.043704234063625336,
      -0.020863255485892296,
      -0.06116742640733719,
      -0.032568950206041336,
      0.008074234239757061,
      -0.007537000812590122,
      -0.055321697145700455,
      0.009940816089510918,
      0.03852921724319458,
      0.154206782579422,
      -0.009355391375720501,
      -0.03936305269598961,
      -0.009139569476246834,
      0.032810330390930176,
      -0.007334554567933083,
      -0.022215284407138824,
      -0.034347813576459885,
      0.006245181895792484,
      0.0029041296802461147,
      -0.021874820813536644,
      -0.10876306146383286,
      -0.051494382321834564,
      0.02263951115310192,
      0.05507358908653259,
      -0.06759223341941833,
      0.09094302356243134,
      0.013956189155578613,
      -0.029652852565050125,
      0.042616263031959534,
      -0.0017279679886996746,
      -0.048150286078453064,
      0.008863882161676884,
      -0.0242051612585783,
      0.049670714884996414,
      0.0008639588486403227,
      0.02640196867287159,
      -0.02941729687154293,
      -0.02166387438774109,
      -0.0012240021023899317,
      -0.02425953559577465,
      0.009728682227432728,
      -0.08449196070432663,
      -0.02925686351954937,
      -0.013879937119781971,
      0.007849986664950848,
      -0.008319231681525707,
      0.006555688567459583,
      0.03327731415629387,
      0.06707386672496796,
      -0.13243529200553894,
      0.016703668981790543,
      0.024950452148914337,
      0.014536425471305847,
      0.00042570463847368956,
      0.08372575789690018,
      -0.0024293106980621815,
      0.03640737012028694,
      -0.06761103123426437,
      0.005434228107333183,
      0.040067240595817566,
      -0.033732328563928604,
      0.034762971103191376,
      0.081474170088768,
      -0.049467846751213074,
      -0.06964851915836334,
      -0.014534473419189453,
      -0.01204171683639288,
      -0.006211796775460243,
      0.057734690606594086,
      0.03946825489401817,
      0.09875357896089554,
      0.06477982550859451,
      0.023811209946870804,
      0.009081185795366764,
      -0.01601283811032772,
      -0.059368010610342026,
      0.02185036428272724,
      0.07863716036081314,
      -0.016138175502419472,
      -0.05417826026678085,
      0.03557012975215912,
      0.07176068425178528,
      0.0013255306985229254,
      -0.011271210387349129,
      0.014908211305737495,
      -0.05241690203547478,
      0.052131716161966324,
      0.015719909220933914,
      0.09752187132835388,
      0.018869897350668907,
      0.04756268113851547,
      -0.06617826968431473,
      -0.07063570618629456,
      -0.031375620514154434,
      0.03884047642350197,
      0.02154480293393135,
      0.053664758801460266,
      -0.08639104664325714,
      0.01056547649204731,
      -0.019448358565568924,
      -0.02979808673262596,
      -0.054573871195316315,
      -0.04712378606200218,
      0.0587267130613327,
      0.006417723838239908,
      -0.05278649181127548,
      -0.05886027589440346,
      0.07963746786117554,
      0.06218690797686577,
      -0.06331485509872437,
      0.01233899500221014,
      -0.023593805730342865,
      0.02374355122447014,
      -0.02776535600423813,
      -0.009402229450643063,
      -0.03738182410597801,
      0.0646306499838829,
      0.03940645977854729,
      0.03243783116340637,
      -0.09701644629240036,
      -0.009829957038164139,
      -0.06871519237756729,
      0.018411440774798393,
      -0.030503593385219574,
      0.017387131229043007,
      -0.03699656203389168,
      -0.009954123757779598,
      0.032705727964639664,
      0.021206872537732124,
      -0.043979816138744354,
      0.08131702989339828,
      -0.029906971380114555,
      -0.026387222111225128,
      -0.013903792947530746,
      -0.03080587647855282,
      0.0685715600848198,
      0.021857183426618576,
      0.0036888152826577425,
      -0.022138701751828194,
      -0.09142279624938965,
      0.008899522013962269,
      0.02854960784316063,
      0.039676353335380554,
      0.06474902480840683,
      -0.02491886541247368,
      -0.08295315504074097,
      -0.07148095220327377,
      0.022308088839054108,
      0.03050512820482254,
      -0.006957296282052994,
      0.06040351092815399,
      -0.01039471197873354,
      -0.006718491669744253,
      -0.027813879773020744,
      -0.05945365130901337,
      0.00387572031468153,
      -0.03634653240442276,
      0.057970814406871796,
      0.0484052412211895,
      -0.022500110790133476,
      0.01556495763361454,
      0.029096050187945366,
      -0.05245603993535042,
      0.009748801589012146,
      -0.04036521539092064,
      -0.07140607386827469,
      0.025865694507956505,
      -0.0013273946242406964,
      -0.027970880270004272,
      0.017079288139939308,
      -0.055780693888664246,
      -0.007926634512841702,
      0.017226604744791985,
      0.06402353942394257,
      0.05791949853301048,
      -0.0817655399441719,
      0.00020773195137735456,
      0.044333964586257935,
      0.002646583365276456,
      -0.06514427810907364,
      -0.04398030415177345,
      0.031968604773283005,
      0.0012880041031166911,
      -0.041910067200660706,
      -0.041228286921978,
      0.01247856393456459,
      0.0529446043074131,
      0.03782200068235397,
      0.06015167012810707,
      0.08821950852870941,
      -0.005186723079532385,
      -0.060861144214868546,
      0.036348242312669754,
      0.027137434110045433,
      0.03443951532244682,
      0.013386032544076443,
      0.06828369200229645,
      -0.05196863412857056,
      -0.03905653953552246,
      0.029172763228416443,
      0.014961193315684795,
      -0.0008280077599920332,
      -0.007875923067331314,
      0.024823376908898354,
      -0.03644395247101784,
      -0.005333643406629562,
      -0.03179416060447693,
      -0.02015819028019905,
      0.012058364227414131,
      0.03944334760308266,
      -0.10119776427745819,
      0.026120560243725777,
      0.011640832759439945,
      -0.05683144927024841,
      0.04556914046406746,
      0.032679133117198944,
      -0.012122804298996925,
      0.11501392722129822,
      -0.043287914246320724,
      -0.014741825871169567,
      -0.09283419698476791,
      0.015337374992668629,
      0.013305976055562496,
      0.10267394036054611,
      -0.02842879667878151,
      0.02250787243247032,
      0.012592644430696964,
      0.0102205416187644,
      0.018181640654802322,
      0.10218697041273117,
      -0.07877662032842636,
      0.013033276423811913,
      -0.06879012286663055,
      0.025747865438461304,
      0.06204972043633461,
      -0.02731497399508953,
      -0.05600748583674431,
      0.003539695404469967,
      0.018985265865921974,
      0.07644510269165039,
      0.024401338770985603,
      -0.006833714433014393,
      0.1321975439786911,
      -0.029753541573882103,
      -0.012425544671714306,
      -0.01915910840034485,
      0.02009458653628826,
      -0.04293875768780708,
      0.045130230486392975,
      0.029572196304798126,
      -0.06994779407978058,
      -0.022842690348625183,
      0.04233051836490631,
      0.005963893141597509,
      0.0011217158753424883,
      -0.057517968118190765,
      0.03419550880789757,
      -0.04553813859820366,
      -0.015992997214198112,
      -0.026395631954073906,
      -0.044895756989717484,
      -0.03273126855492592,
      -0.0012147383531555533,
      -0.04515581205487251,
      -0.006936332676559687,
      -0.015845732763409615,
      -0.03359373286366463,
      -0.03708893805742264,
      -0.05922127887606621,
      0.08387701213359833,
      -0.012854503467679024,
      -0.017983941361308098,
      -0.023920517414808273,
      -0.12054938822984695,
      0.052213266491889954,
      0.018459288403391838,
      -0.0695740282535553,
      0.056852713227272034,
      -0.08231372386217117,
      -0.018729396164417267,
      -0.0772479772567749,
      0.044260937720537186,
      0.015849482268095016,
      -0.007067217957228422,
      0.02284225821495056,
      -0.024773798882961273,
      0.023529665544629097,
      0.076744444668293,
      0.07326037436723709,
      0.01797422207891941,
      -0.03405563160777092,
      0.018090901896357536,
      -0.048041727393865585,
      -0.007851766422390938,
      -0.08154277503490448,
      0.021248800680041313,
      -0.02107365056872368,
      -0.040830355137586594,
      0.008581354282796383,
      -0.00019545205577742308,
      0.00015814704238437116,
      0.02607385814189911,
      -0.05966898053884506,
      -0.00946031790226698,
      0.013323613442480564,
      0.052354149520397186,
      0.022326454520225525,
      0.0068594724871218204,
      -0.06745849549770355,
      -0.06545061618089676,
      -0.05324370414018631,
      -0.007671064231544733,
      -0.04340100288391113,
      -0.0063994769006967545,
      0.00784655287861824,
      -0.00251460331492126,
      0.046710897237062454,
      -0.04244432598352432,
      0.04506154730916023,
      -0.025163378566503525,
      0.03335506096482277,
      0.05880184471607208,
      -0.016366638243198395,
      0.012881999835371971,
      -0.05624204874038696,
      -0.05946876108646393,
      -0.021164871752262115,
      0.005117109976708889,
      0.05367486551403999,
      -0.035385970026254654,
      0.004686241503804922,
      0.015364902094006538,
      -0.004789696540683508,
      0.01475430279970169,
      -0.013600160367786884,
      -0.04561968892812729,
      0.014444751664996147,
      -0.05481606349349022,
      0.012383909896016121,
      0.0428943932056427,
      -0.031003756448626518,
      -0.00999121181666851,
      0.0458935983479023,
      0.030464105308055878,
      -0.11651085317134857,
      -0.07238601893186569,
      0.033984825015068054,
      0.014203671365976334,
      0.013298503123223782,
      0.026114750653505325,
      0.004228809382766485,
      -0.0072580985724925995,
      0.04123927652835846,
      0.028706906363368034,
      0.02959601953625679,
      0.0009047549683600664,
      -0.05537492036819458,
      -0.04848911985754967,
      0.035521406680345535,
      -0.006720989476889372,
      -0.0217036884278059,
      0.017436429858207703,
      0.11794446408748627,
      0.07396865636110306,
      0.05499893054366112,
      -0.03369045630097389,
      -0.06058715283870697,
      -0.0743945837020874,
      -0.00311769125983119,
      -0.01171921193599701,
      0.043460097163915634,
      0.02242349274456501,
      -0.06985411047935486,
      -0.04312315210700035,
      0.006378573831170797,
      -0.09252512454986572,
      0.022176839411258698,
      0.02292451448738575,
      -0.028905881568789482,
      0.003349048551172018,
      0.0010561529779806733,
      0.0001227579195983708,
      -0.06671535968780518,
      -0.06458768248558044,
      -0.05338606610894203,
      0.02931230328977108,
      0.03287524729967117,
      0.001576761482283473,
      -0.012579867616295815,
      -0.007843809202313423,
      -0.05122984200716019,
      -0.001895227818749845,
      -0.030996331945061684,
      0.002514445222914219,
      -0.06990648806095123,
      0.09458091109991074,
      0.010141223669052124,
      -0.021943489089608192,
      0.061059173196554184,
      0.04005560278892517,
      0.043596990406513214,
      0.015886833891272545,
      -0.10517764836549759,
      0.075832299888134,
      -0.01204759906977415,
      0.0350278876721859,
      0.028875626623630524,
      -0.013655687682330608,
      -0.03022269904613495,
      0.015720760449767113,
      0.0952228456735611,
      0.004836377687752247,
      0.025306662544608116,
      0.11121124029159546,
      -0.0008951249765232205,
      0.024973366409540176,
      -0.002388926222920418,
      0.03914721682667732,
      -0.01363005768507719,
      0.04171594977378845,
      0.021541370078921318,
      0.013138857670128345,
      -0.06722602993249893,
      0.03795599564909935,
      0.004750875756144524,
      0.005926873534917831,
      0.04480807110667229,
      -0.021361220628023148,
      0.004319806583225727
    ],
    [
      0.01324138231575489,
      0.046721260994672775,
      0.00563805690035224,
      -0.043010927736759186,
      -0.006775672547519207,
      -0.011737169697880745,
      -0.05116400122642517,
      0.026832163333892822,
      -0.048223938792943954,
      0.038710176944732666,
      -0.06774649024009705,
      -0.045235153287649155,
      -0.05500718951225281,
      0.0511782169342041,
      -0.020422708243131638,
      -0.03075731359422207,
      0.00718205701559782,
      -0.031082822009921074,
      -0.009728538803756237,
      -0.07052407413721085,
      0.030624818056821823,
      0.006660648621618748,
      0.040852516889572144,
      0.008011003956198692,
      0.04786640405654907,
      -0.018660688772797585,
      0.0021302453242242336,
      -0.10807368159294128,
      0.032784417271614075,
      -0.015693698078393936,
      0.0344088114798069,
      -0.07903280109167099,
      -0.020737068727612495,
      -0.05368382856249809,
      -0.02263268269598484,
      0.03860391676425934,
      0.0017829472199082375,
      0.04602867364883423,
      0.025651277974247932,
      -0.06031421199440956,
      -0.01816721446812153,
      -0.028086716309189796,
      0.05612456053495407,
      0.005884487181901932,
      -0.03669167682528496,
      0.08545370399951935,
      0.014392970129847527,
      -0.04177939146757126,
      -0.05790110304951668,
      -0.05165781080722809,
      0.0008342794026248157,
      -0.02507944218814373,
      -0.05491405352950096,
      0.04792356491088867,
      -0.02813027612864971,
      0.03596150502562523,
      0.001536981319077313,
      -0.02419424057006836,
      0.01903066597878933,
      0.04967465624213219,
      0.03874622657895088,
      0.07534285634756088,
      -0.03700154274702072,
      0.0048624929040670395,
      0.02390589937567711,
      0.02756252884864807,
      -0.016165977343916893,
      -0.09587521851062775,
      0.014515968970954418,
      -0.08974431455135345,
      0.053461309522390366,
      0.03233231604099274,
      0.007993239909410477,
      -0.0019278917461633682,
      -0.022205770015716553,
      -0.03498053178191185,
      -0.0110781816765666,
      -0.037714727222919464,
      -0.08231035619974136,
      0.021642757579684258,
      0.025910750031471252,
      -0.020849885419011116,
      0.026156645268201828,
      -0.017369594424962997,
      0.047127798199653625,
      0.021959414705634117,
      -0.00822344608604908,
      -0.011604225262999535,
      -0.05943233519792557,
      -0.06439007818698883,
      -0.0025915252044796944,
      -0.04612010717391968,
      0.025219863280653954,
      0.04611131176352501,
      0.04883398860692978,
      0.08619080483913422,
      -0.07931279391050339,
      -0.016207285225391388,
      0.005182923749089241,
      0.027030080556869507,
      -0.0033351241145282984,
      0.019926562905311584,
      0.09071128070354462,
      0.02537154220044613,
      0.021049199625849724,
      -0.01633382774889469,
      0.06672140955924988,
      0.010090233758091927,
      -0.0489419661462307,
      -0.06102944537997246,
      0.011793951503932476,
      0.0028385992627590895,
      0.020663529634475708,
      0.03997894003987312,
      0.004162040073424578,
      0.016610996797680855,
      -0.012606874108314514,
      0.06999801099300385,
      -0.002211004728451371,
      -0.09456748515367508,
      -0.05034802854061127,
      0.026464223861694336,
      -0.00016132800374180079,
      -0.026497922837734222,
      0.0018073972314596176,
      0.037371862679719925,
      0.008726691827178001,
      -0.029046107083559036,
      0.0758545994758606,
      -0.07087016850709915,
      0.028055354952812195,
      0.027498191222548485,
      0.040796712040901184,
      -0.11532456427812576,
      0.007972774095833302,
      -0.03512567654252052,
      0.06064915657043457,
      0.025483960285782814,
      -0.013293790630996227,
      0.017750652506947517,
      0.08204089105129242,
      -0.024394165724515915,
      0.020727837458252907,
      0.0794667974114418,
      0.004481536336243153,
      -0.02157941646873951,
      0.006800879258662462,
      0.017560316249728203,
      0.03459436818957329,
      -0.10100606083869934,
      0.04467995837330818,
      -0.019464043900370598,
      -0.026572823524475098,
      -0.029068514704704285,
      -0.06814122200012207,
      -0.057684265077114105,
      -0.006023156922310591,
      -0.08929665386676788,
      -0.03375334292650223,
      -0.07853908091783524,
      -0.0774223655462265,
      -0.06239255145192146,
      0.048061057925224304,
      -0.0023413863964378834,
      0.014440508559346199,
      0.02376461774110794,
      0.009814098477363586,
      0.02349110133945942,
      -0.017162824049592018,
      0.0756865069270134,
      -0.0576653853058815,
      -0.002858718391507864,
      -0.03269806504249573,
      -0.003961749374866486,
      -0.029970252886414528,
      -0.11809204518795013,
      -0.021545669063925743,
      -0.024994514882564545,
      -0.03318668156862259,
      0.017416223883628845,
      -0.015840791165828705,
      0.024522287771105766,
      -0.008787858299911022,
      -0.02076726220548153,
      -0.04918316379189491,
      0.05373094603419304,
      0.06794489920139313,
      -0.0468037910759449,
      0.02140290103852749,
      -0.011217211373150349,
      0.01747334748506546,
      0.013261180371046066,
      -0.02176882140338421,
      0.08112292736768723,
      0.016210434958338737,
      -0.017032304778695107,
      -0.04780976101756096,
      0.03645673021674156,
      -0.048760488629341125,
      -0.016772760078310966,
      0.015401527285575867,
      -0.013630613684654236,
      -0.013261184096336365,
      0.023528899997472763,
      -0.03160461038351059,
      -0.0023648529313504696,
      0.060951799154281616,
      -0.023395970463752747,
      0.10316720604896545,
      -0.10831307619810104,
      0.021512940526008606,
      0.0016932885628193617,
      0.042568307369947433,
      -0.09901432693004608,
      0.0083998441696167,
      -0.057556748390197754,
      -0.018773885443806648,
      0.03494741767644882,
      -0.00999782420694828,
      0.01943170092999935,
      0.049914389848709106,
      0.018667498603463173,
      0.09112851321697235,
      0.006593417841941118,
      -0.040676653385162354,
      0.006631576921790838,
      0.014850333333015442,
      -0.02245175465941429,
      0.017451200634241104,
      -0.0460883304476738,
      0.05406444892287254,
      -0.03627803921699524,
      0.03002113848924637,
      0.035562608391046524,
      -0.020502513274550438,
      -0.047113604843616486,
      0.003692472353577614,
      0.08372937887907028,
      -0.057977333664894104,
      -0.005957959219813347,
      0.05280417203903198,
      0.026934055611491203,
      -0.002492886269465089,
      -0.0038749382365494967,
      0.021189065650105476,
      0.009538669139146805,
      0.005014922935515642,
      -0.016509607434272766,
      0.04730695113539696,
      -0.013277710415422916,
      -0.035863425582647324,
      -0.016609111800789833,
      0.03164537996053696,
      -0.07943011820316315,
      -0.05583243817090988,
      0.058901939541101456,
      -0.019594907760620117,
      0.09079096466302872,
      0.011305803433060646,
      0.05132110416889191,
      -0.06263013929128647,
      0.052299343049526215,
      -0.017912453040480614,
      -0.06054927408695221,
      -0.030230630189180374,
      -0.08083202689886093,
      -0.019635610282421112,
      0.14150218665599823,
      0.022523557767271996,
      0.07290955632925034,
      0.025104885920882225,
      -0.005524592474102974,
      -0.020643744617700577,
      0.029702305793762207,
      -0.04158468171954155,
      -0.027773788198828697,
      0.028193974867463112,
      -0.0266911331564188,
      0.01943882741034031,
      0.042000073939561844,
      -0.0599370114505291,
      0.006528680678457022,
      0.00012303028779570013,
      0.01806904561817646,
      0.03342307731509209,
      0.04138881713151932,
      0.03834330290555954,
      -0.0594823956489563,
      -0.003451175754889846,
      -0.0018335181521251798,
      -0.039400797337293625,
      0.025436008349061012,
      0.005929307080805302,
      0.0135071761906147,
      -0.0741419866681099,
      0.00919854361563921,
      -0.06305760890245438,
      -0.06309641897678375,
      0.1229032501578331,
      -0.01764986477792263,
      0.07735983282327652,
      0.054931290447711945,
      0.02504563517868519,
      0.05644117295742035,
      -0.04982244223356247,
      0.03593108803033829,
      -0.0374675951898098,
      0.012110546231269836,
      0.044937532395124435,
      0.09788572788238525,
      -0.05023258551955223,
      0.02282746508717537,
      -0.07152669131755829,
      0.00648497324436903,
      0.015981391072273254,
      -0.001290991436690092,
      0.003945059608668089,
      -0.011158586479723454,
      0.01559554785490036,
      -0.07050315290689468,
      0.005884887650609016,
      -0.03173943981528282,
      0.010831449180841446,
      -0.027009569108486176,
      0.0737321674823761,
      0.07801780849695206,
      0.031233912333846092,
      0.03083682246506214,
      -0.06251733005046844,
      -0.05400269478559494,
      -0.019926264882087708,
      0.03817123547196388,
      0.059243857860565186,
      0.024914057925343513,
      0.02872026525437832,
      0.013755724765360355,
      0.021536530926823616,
      -0.010449902154505253,
      -0.026349075138568878,
      0.013197832740843296,
      -0.04468461871147156,
      -0.02008514292538166,
      0.045723140239715576,
      0.09353616088628769,
      -0.03619927167892456,
      -0.013506293296813965,
      0.010007670149207115,
      -0.0698779821395874,
      0.035615816712379456,
      -0.028363194316625595,
      -0.026225853711366653,
      0.007985849864780903,
      0.014251821674406528,
      -0.013392901979386806,
      -0.04470067843794823,
      0.051917027682065964,
      -0.11516555398702621,
      0.027030697092413902,
      -0.01631527580320835,
      0.010156414471566677,
      0.0590292252600193,
      -0.017857255414128304,
      0.03433564305305481,
      0.013768539763987064,
      0.02709304541349411,
      0.06661449372768402,
      -0.011058458127081394,
      -0.006340951658785343,
      -0.03235020488500595,
      -0.04213874414563179,
      0.07320687174797058,
      -0.0035151070915162563,
      0.003809144254773855,
      -0.08444151282310486,
      -0.024339519441127777,
      0.05400129035115242,
      0.04974512383341789,
      -0.052576616406440735,
      0.06296061724424362,
      -0.026836734265089035,
      -0.03523508459329605,
      0.000994700356386602,
      0.0009650911670178175,
      -0.021143602207303047,
      -0.027660734951496124,
      0.0062951501458883286,
      0.01777169108390808,
      0.033464886248111725,
      -0.038089580833911896,
      -0.049625247716903687,
      -0.05016227439045906,
      -0.039161209017038345,
      0.038911864161491394,
      -0.04175509884953499,
      0.06668905913829803,
      -0.0004889056435786188,
      -0.01880691386759281,
      -0.1079307422041893,
      0.0006568513927049935,
      -0.0096928421407938,
      0.057764969766139984,
      0.07662702351808548,
      -0.02644043043255806,
      0.008849523030221462,
      0.010373303666710854,
      0.023229874670505524,
      0.006974441930651665,
      -0.032516878098249435,
      -0.03378451243042946,
      -0.06707997620105743,
      -0.034946613013744354,
      -0.040300507098436356,
      0.02064080722630024,
      0.029547514393925667,
      -0.09529862552881241,
      0.02200646698474884,
      -0.01652199774980545,
      -0.0542842298746109,
      0.02377188391983509,
      0.02806302160024643,
      0.06449741125106812,
      0.043640803545713425,
      -0.004859553650021553,
      0.05286985635757446,
      0.020239122211933136,
      -0.027805423364043236,
      -0.0726703405380249,
      -0.04232063889503479,
      -0.033214207738637924,
      0.012671815231442451,
      0.017439864575862885,
      0.011149168014526367,
      0.06779729574918747,
      -0.030790334567427635,
      0.001178184524178505,
      0.08537087589502335,
      -0.03985748440027237,
      -0.015533272176980972,
      -0.014508347027003765,
      -0.07352825999259949,
      -0.0036511626094579697,
      0.05829277262091637,
      0.0016667312011122704,
      -0.004328484646975994,
      -0.004730595741420984,
      0.01917550154030323,
      0.06285751610994339,
      -0.03250719979405403,
      -0.04909270256757736,
      0.006507270038127899,
      -0.021228386089205742,
      0.01460632961243391,
      -0.04087672010064125,
      -0.05577711760997772,
      0.012356356717646122,
      -0.012887675315141678,
      -0.028973722830414772,
      -0.04592633619904518,
      -0.02788267470896244,
      -0.05421369522809982,
      0.10074342787265778,
      -0.0511084720492363,
      -0.032166190445423126,
      0.007808140479028225,
      0.001339256763458252,
      0.01227706391364336,
      -0.05006313696503639,
      -0.0682070329785347,
      0.08883125334978104,
      0.10685299336910248,
      -0.053759876638650894,
      0.040482692420482635,
      0.04424409568309784,
      0.07419437170028687,
      0.011526506394147873,
      -0.028452077880501747,
      -0.017888151109218597,
      -0.033439408987760544,
      0.05165354534983635,
      0.07864950597286224,
      -0.01590121164917946,
      0.019011875614523888,
      0.020407363772392273,
      -0.023870786651968956,
      0.014177996665239334,
      0.03908199816942215,
      -0.04459613189101219,
      -0.044473595917224884,
      -0.06292269378900528,
      -0.0165302325040102,
      0.02137298882007599,
      -0.003962791059166193,
      0.06914635747671127,
      0.03472406789660454,
      0.10014165192842484,
      -0.04369381442666054,
      -0.011147590354084969,
      0.037130001932382584,
      0.04214269295334816,
      -0.051253851503133774,
      0.011438939720392227,
      -0.02989988401532173,
      0.010045019909739494
    ],
    [
      0.012133571319282055,
      -0.020079724490642548,
      -0.006238360423594713,
      0.060501016676425934,
      -0.029725542291998863,
      -0.09585051983594894,
      -0.007306851912289858,
      0.034742362797260284,
      -0.04085924103856087,
      0.006131533533334732,
      0.007687361445277929,
      -0.017383677884936333,
      0.0011524076107889414,
      0.06722240895032883,
      -0.06546659022569656,
      -0.03297322988510132,
      -0.01430803444236517,
      -0.021922124549746513,
      -0.04533488675951958,
      -0.028003424406051636,
      0.032850198447704315,
      -0.0685163289308548,
      -0.05841052532196045,
      0.07761794328689575,
      0.001093695405870676,
      -0.0890689492225647,
      0.03192415460944176,
      -0.019693152979016304,
      -0.0333172008395195,
      -0.046392906457185745,
      0.02815178781747818,
      -0.007818926125764847,
      0.04536186903715134,
      -0.03937620669603348,
      -0.0640142485499382,
      0.014754763804376125,
      0.08443357795476913,
      -0.01247736718505621,
      -0.04876989126205444,
      -0.06754577159881592,
      -0.036502402275800705,
      -0.023181989789009094,
      -0.014383108355104923,
      -0.06858931481838226,
      0.03186903893947601,
      0.02331983670592308,
      0.031144045293331146,
      -0.023729445412755013,
      -0.05234678462147713,
      0.052839793264865875,
      0.04159262403845787,
      -0.03747175261378288,
      0.018656857311725616,
      0.019660789519548416,
      0.035253848880529404,
      -0.05383024737238884,
      0.029485082253813744,
      0.11018382757902145,
      0.006063774228096008,
      -0.018981745466589928,
      0.0521247461438179,
      -0.020378269255161285,
      0.007658958435058594,
      -0.007397850044071674,
      -0.000567015609703958,
      -0.015166851691901684,
      0.012874209322035313,
      0.044612765312194824,
      0.042091257870197296,
      0.008654872886836529,
      0.006430516950786114,
      -0.00166499603074044,
      0.04700162261724472,
      -0.01820465549826622,
      0.003729055868461728,
      0.04010723531246185,
      0.022825947031378746,
      -0.054490551352500916,
      -0.04168863967061043,
      -0.024478690698742867,
      -0.0070041934959590435,
      -0.07892769575119019,
      0.03182065486907959,
      0.016426367685198784,
      0.02279168926179409,
      0.053732048720121384,
      0.043250907212495804,
      0.0721474438905716,
      -0.052219901233911514,
      -0.034247905015945435,
      0.1334855556488037,
      -0.013329983688890934,
      0.0039529260247945786,
      0.07159058749675751,
      -0.16242533922195435,
      0.028808126226067543,
      -0.011580199934542179,
      -0.027057792991399765,
      -0.04245075583457947,
      -0.03889629617333412,
      -0.0015725253615528345,
      0.059460535645484924,
      -0.01991315558552742,
      0.021266009658575058,
      0.02740330621600151,
      0.05407332256436348,
      -0.05705473944544792,
      -0.035456813871860504,
      -0.009570999071002007,
      0.03994247689843178,
      -0.018197152763605118,
      -0.03988483175635338,
      -0.11416134983301163,
      -0.013256481848657131,
      -0.07232025265693665,
      0.05750313401222229,
      -0.036004211753606796,
      -0.03581517934799194,
      -0.006091551389545202,
      0.022096525877714157,
      -5.195802259549964e-06,
      -0.09073416888713837,
      0.024243440479040146,
      -0.06235343590378761,
      0.013952150009572506,
      0.039671000093221664,
      0.01055472157895565,
      0.022299928590655327,
      0.10085556656122208,
      -0.011804347857832909,
      0.014920388348400593,
      0.01947217434644699,
      -0.12615805864334106,
      0.0324687734246254,
      0.0371386893093586,
      0.0266189556568861,
      0.05800754204392433,
      -0.011131812818348408,
      -0.016642335802316666,
      0.09688614308834076,
      0.030889444053173065,
      -0.050626613199710846,
      0.04491174966096878,
      -0.008843566291034222,
      0.03482704609632492,
      -0.03166908770799637,
      0.06358522176742554,
      0.03821118548512459,
      0.03068946674466133,
      0.04503554850816727,
      0.010058912448585033,
      0.0017515297513455153,
      -0.014318539761006832,
      0.09704862534999847,
      0.006788650993257761,
      0.0686362087726593,
      -0.01239863783121109,
      -0.00518756965175271,
      0.02321191132068634,
      -0.08743394911289215,
      0.07657603174448013,
      -0.017451124265789986,
      0.010394207201898098,
      -0.013741196133196354,
      -0.013919500634074211,
      -0.026714853942394257,
      -0.039001207798719406,
      0.03415416181087494,
      0.04288402199745178,
      -0.07076810300350189,
      -0.01654595136642456,
      -0.06509998440742493,
      -0.026047436520457268,
      -0.012947700917720795,
      -0.030963771045207977,
      -0.013174435123801231,
      -0.07126179337501526,
      0.04438052698969841,
      0.01906752400100231,
      -0.0561460517346859,
      0.08700419962406158,
      0.053627967834472656,
      -0.06856387853622437,
      0.019683105871081352,
      0.018694663420319557,
      0.014511185698211193,
      0.0446639358997345,
      0.012410074472427368,
      -0.010541563853621483,
      0.03879482299089432,
      0.0794687569141388,
      0.0392434187233448,
      0.009047252126038074,
      0.04305817931890488,
      -0.032031670212745667,
      0.07067310065031052,
      0.01359583530575037,
      -0.07980331033468246,
      0.055885281413793564,
      0.06169730797410011,
      0.026067256927490234,
      -0.037646252661943436,
      0.05589654669165611,
      -0.029551470652222633,
      0.018855124711990356,
      -0.035479702055454254,
      -0.028663303703069687,
      0.07387464493513107,
      0.012835889123380184,
      -0.01451344694942236,
      0.031025301665067673,
      -0.0009921144228428602,
      0.004668011795729399,
      -0.08454424887895584,
      0.037506528198719025,
      0.010975816287100315,
      -0.10176476836204529,
      -0.058621786534786224,
      -0.031359441578388214,
      0.06872481107711792,
      0.006639459636062384,
      0.030063098296523094,
      0.011232883669435978,
      0.00292831938713789,
      0.009198671206831932,
      -0.022839680314064026,
      0.05129041150212288,
      -0.0305857565253973,
      -0.06653787195682526,
      -0.04280299320816994,
      -0.04824262857437134,
      0.01944948546588421,
      0.085126593708992,
      -0.012979833409190178,
      0.015523731708526611,
      -0.04635012149810791,
      -0.052622146904468536,
      0.04292220249772072,
      0.011651567183434963,
      -0.03990910202264786,
      -0.0880696251988411,
      -0.012133643962442875,
      -0.04001259058713913,
      -0.03367429971694946,
      0.1021074578166008,
      -0.029273681342601776,
      0.06251086294651031,
      0.08958786725997925,
      -0.037452809512615204,
      -0.04259781539440155,
      0.029578885063529015,
      0.00783469993621111,
      -0.030639907345175743,
      -0.0400407575070858,
      0.006528354715555906,
      -0.01768443174660206,
      0.028460439294576645,
      0.0006584872025996447,
      -0.0023793959990143776,
      -0.07001729309558868,
      0.12964361906051636,
      0.013710841536521912,
      0.05332542583346367,
      -0.013633517548441887,
      -0.04493866488337517,
      -0.00884515792131424,
      -0.01674492098391056,
      -0.032686296850442886,
      -0.02395828254520893,
      0.018119025975465775,
      0.02015511319041252,
      -0.05392385274171829,
      0.017094384878873825,
      -0.014801234006881714,
      0.01903829164803028,
      -0.01975623518228531,
      0.024995889514684677,
      -0.07455704361200333,
      0.051676299422979355,
      -0.08211269229650497,
      -0.11779265850782394,
      0.03272164613008499,
      -0.02839411422610283,
      -0.020102817565202713,
      -0.02143094874918461,
      0.03906124830245972,
      -0.014864053577184677,
      -0.012114004231989384,
      -0.003954468294978142,
      -0.09911045432090759,
      0.0745023638010025,
      -0.0016060849884524941,
      -0.07622439414262772,
      0.07022877037525177,
      -0.032030265778303146,
      0.006957121193408966,
      -0.03595796599984169,
      0.016241716220974922,
      0.013122150674462318,
      -0.046733975410461426,
      -0.062956303358078,
      -0.04434853792190552,
      -0.019122259691357613,
      -0.02892962098121643,
      0.00731575395911932,
      -0.061354413628578186,
      -0.04821040853857994,
      -0.007280515506863594,
      0.004858491476625204,
      0.02213829569518566,
      -0.06276145577430725,
      0.04799395799636841,
      0.007630445063114166,
      0.013156112283468246,
      0.06758850067853928,
      -0.0072805085219442844,
      0.031135547906160355,
      0.011874212883412838,
      0.07918497920036316,
      0.030689969658851624,
      -0.0012723042163997889,
      -0.07732180505990982,
      -0.021562790498137474,
      -0.016518449410796165,
      0.03698405995965004,
      -0.002744541270658374,
      0.005562774371355772,
      -0.01899523288011551,
      -0.04894647002220154,
      0.015949392691254616,
      -0.01927373558282852,
      -0.043216004967689514,
      -0.012717566452920437,
      0.0720355361700058,
      0.031522370874881744,
      -0.029181698337197304,
      -0.013319021090865135,
      -0.01985795423388481,
      0.0063715288415551186,
      -0.01561747770756483,
      -0.0346771664917469,
      -0.08712179213762283,
      -0.01708238758146763,
      0.1296178102493286,
      0.001752376789227128,
      0.02618304453790188,
      0.001656644861213863,
      -0.0264467503875494,
      0.03769788146018982,
      -0.011969652958214283,
      -0.013671008870005608,
      -0.031002802774310112,
      -0.011283274739980698,
      -0.01629912294447422,
      -0.03129596635699272,
      0.05904546007514,
      0.07430986315011978,
      0.10303433984518051,
      0.09192222356796265,
      -0.04836064204573631,
      -0.009009518660604954,
      -0.030572205781936646,
      0.06277043372392654,
      -0.02364208735525608,
      -0.0643465593457222,
      0.0372328907251358,
      -0.04675673693418503,
      -0.08413469791412354,
      0.07165029644966125,
      -0.055116456001996994,
      -0.000968950567767024,
      -0.0018644422525539994,
      0.004785086028277874,
      0.00037037464790046215,
      0.032212309539318085,
      0.008511996828019619,
      0.015132964588701725,
      -0.06068385764956474,
      -0.06265716254711151,
      0.004538771230727434,
      -0.015625234693288803,
      0.03595275431871414,
      -0.0441054068505764,
      -0.08222523331642151,
      -0.010632584802806377,
      0.04475382715463638,
      0.03905037045478821,
      -0.060925453901290894,
      0.010947697795927525,
      0.08330614864826202,
      0.028421524912118912,
      0.027194758877158165,
      -0.05260862410068512,
      -0.025477159768342972,
      0.02164623700082302,
      -0.0718064233660698,
      0.058725904673337936,
      0.03259587287902832,
      -0.046935562044382095,
      -0.08425069600343704,
      0.04522494226694107,
      -0.05869388207793236,
      0.041056618094444275,
      -0.024123599752783775,
      0.0375901497900486,
      0.06828422844409943,
      -0.025563346222043037,
      -0.01683543249964714,
      0.03813476115465164,
      -0.0036537216510623693,
      -0.05642838776111603,
      -0.037961896508932114,
      -0.03859715536236763,
      0.016278192400932312,
      -0.015420700423419476,
      0.10850042849779129,
      -0.07444953173398972,
      -0.04099096357822418,
      -0.01992081291973591,
      0.029566524550318718,
      0.008323969319462776,
      0.04422585666179657,
      -0.04225306585431099,
      -0.0005584924947470427,
      -0.10621511191129684,
      -0.026559384539723396,
      0.028756162151694298,
      0.04263100028038025,
      0.02909594587981701,
      -0.07761277258396149,
      -0.012371242046356201,
      -0.03295685723423958,
      0.036875203251838684,
      -0.1030525416135788,
      -0.06593791395425797,
      0.05221833288669586,
      -0.03670257329940796,
      -0.08840181678533554,
      -0.0161434318870306,
      -0.03559315577149391,
      0.025233842432498932,
      -0.02866601012647152,
      -0.036024581640958786,
      0.003600438591092825,
      0.08153972029685974,
      -0.016971949487924576,
      0.009597758762538433,
      -0.01020804513245821,
      0.03146485984325409,
      0.060184162110090256,
      -0.025838110595941544,
      0.018047407269477844,
      -0.009026526473462582,
      -0.03188229352235794,
      -0.11970464885234833,
      -0.09696098417043686,
      -0.008150387555360794,
      0.023071186617016792,
      -0.045803215354681015,
      0.006517150439321995,
      -0.0039061289280653,
      0.0034695016220211983,
      -0.01738850586116314,
      0.01911758817732334,
      0.015387454070150852,
      -0.02503233030438423,
      -0.009267586283385754,
      0.04524952545762062,
      0.0007594435592181981,
      -0.12982842326164246,
      -0.06363943964242935,
      0.06655669212341309,
      0.005435593891888857,
      0.0029419073835015297,
      0.027677340433001518,
      0.025865299627184868,
      0.012718885205686092,
      0.03539843484759331,
      -0.0014405817491933703,
      -0.0009699956863187253,
      0.04716668650507927,
      -0.0019690326880663633,
      -0.02561831846833229,
      -0.038670070469379425,
      0.03138328343629837,
      -0.061360642313957214,
      0.04588332772254944,
      -0.11169978231191635,
      -0.03173116594552994,
      0.024675950407981873,
      0.04537086561322212,
      0.06282085180282593,
      -0.04500590264797211,
      -0.002763699274510145,
      0.04058545455336571,
      0.0458625890314579,
      0.018031811341643333,
      0.04789596423506737,
      0.005681169219315052,
      0.04218899458646774,
      -0.05151032283902168,
      0.03447047248482704,
      -0.005901682656258345
    ],
    [
      -0.01779995672404766,
      0.06357402354478836,
      -0.056815359741449356,
      0.03838488832116127,
      0.03442177549004555,
      0.048027053475379944,
      0.0050360290333628654,
      0.00909357238560915,
      0.010448643006384373,
      0.02124905399978161,
      0.015618755482137203,
      -0.0247588399797678,
      0.05424942448735237,
      0.02271096222102642,
      -0.07042287290096283,
      0.046495284885168076,
      0.013146383687853813,
      -0.02423693612217903,
      -0.018479522317647934,
      0.10548218339681625,
      -0.04057842493057251,
      -0.05783239006996155,
      0.04377398267388344,
      0.023136701434850693,
      -0.04239322617650032,
      -0.021948477253317833,
      0.0028035680297762156,
      -0.0748266726732254,
      0.003762332256883383,
      0.0863967016339302,
      -0.035586994141340256,
      0.09382463246583939,
      0.08204420655965805,
      0.00685799028724432,
      -0.03605882078409195,
      0.032733142375946045,
      -0.058436088263988495,
      0.011258888058364391,
      0.004144237842410803,
      -0.07695195078849792,
      -0.01920601911842823,
      0.040427569299936295,
      -0.012021043337881565,
      -0.03174389526247978,
      0.03209072723984718,
      0.0030089206993579865,
      -0.0034491419792175293,
      -0.020504245534539223,
      0.00039792919415049255,
      0.005596920382231474,
      0.001348740654066205,
      0.0014766626991331577,
      0.01831616461277008,
      -0.03118702955543995,
      0.013950962573289871,
      0.11563415825366974,
      0.04424364119768143,
      -0.011458434164524078,
      0.048588961362838745,
      0.007342623546719551,
      0.032420020550489426,
      -0.07778673619031906,
      -0.00176171213388443,
      0.010470326989889145,
      0.07431085407733917,
      0.010307800956070423,
      -0.014836971648037434,
      -0.016836754977703094,
      -0.014761321246623993,
      0.04226982966065407,
      -0.02901214361190796,
      0.028646977618336678,
      0.009080230258405209,
      -0.05301165580749512,
      0.01983794942498207,
      0.012899462133646011,
      0.0701468214392662,
      -0.02171018347144127,
      -0.010331213474273682,
      0.020972365513443947,
      0.06702218949794769,
      -0.04207506403326988,
      -0.061636559665203094,
      0.028784312307834625,
      -0.0915956124663353,
      -0.10985486209392548,
      4.74951921205502e-05,
      -0.0073147607035934925,
      -0.00955724436789751,
      -0.0382758229970932,
      -0.020776810124516487,
      0.001918594934977591,
      -0.0022864423226565123,
      0.022889282554388046,
      0.03180555999279022,
      -0.015451416373252869,
      0.034842733293771744,
      -0.021004190668463707,
      0.06630180031061172,
      0.04753616452217102,
      -0.03199104964733124,
      -0.008983531035482883,
      0.014463921077549458,
      0.09750107675790787,
      0.0509740374982357,
      -0.03818843513727188,
      -0.020238807424902916,
      0.01751766912639141,
      -0.030324067920446396,
      -0.030783988535404205,
      -0.011428273282945156,
      -0.03632974624633789,
      -0.02187972143292427,
      -0.008270126767456532,
      -0.029134567826986313,
      -0.046037960797548294,
      0.053101614117622375,
      -0.06866426020860672,
      -0.06916772574186325,
      -0.012719826772809029,
      -0.03304532915353775,
      -0.06726762652397156,
      -0.0037231689784675837,
      -0.04849911481142044,
      -0.01169462688267231,
      0.07372917979955673,
      0.02285202033817768,
      0.007959454320371151,
      -0.02603054977953434,
      0.026355981826782227,
      -0.061367325484752655,
      0.009244355373084545,
      -0.07633194327354431,
      -0.018841678276658058,
      -0.02928098849952221,
      0.035067275166511536,
      -0.00780810322612524,
      -0.016224058344960213,
      -0.07619431614875793,
      -0.028014259412884712,
      -0.07331778854131699,
      0.05192776024341583,
      -0.10276076942682266,
      -0.06404522806406021,
      -0.006050200667232275,
      0.022570809349417686,
      -0.037590090185403824,
      -0.023435579612851143,
      -0.03691218048334122,
      -0.06365980207920074,
      -0.037100475281476974,
      0.017155952751636505,
      0.016386840492486954,
      -0.005006665829569101,
      -0.007680550217628479,
      -0.001411557663232088,
      0.017948947846889496,
      -0.03710072860121727,
      0.0038005036767572165,
      -0.008386456407606602,
      0.020732568576931953,
      0.07458631694316864,
      -0.006431230343878269,
      0.04276838153600693,
      -0.000457844726042822,
      0.03355101868510246,
      0.010932334698736668,
      -0.002827051328495145,
      0.02620355412364006,
      0.06693752855062485,
      0.05956362932920456,
      0.05021928623318672,
      -0.033207006752491,
      0.056231286376714706,
      0.05050092190504074,
      0.03144333139061928,
      -0.10388555377721786,
      -0.03487599268555641,
      0.0003795974771492183,
      0.012292523868381977,
      -0.09713102132081985,
      -0.0622289665043354,
      0.015417943708598614,
      0.03454170748591423,
      0.03599364310503006,
      -0.06735735386610031,
      0.0242803655564785,
      -0.0068892547860741615,
      0.020577803254127502,
      -0.009903628379106522,
      0.0530523955821991,
      0.011119264177978039,
      -0.002156170317903161,
      -0.043288446962833405,
      -0.013518674299120903,
      0.07744202017784119,
      -0.026586880907416344,
      -0.018102645874023438,
      0.0070011005736887455,
      0.05404908210039139,
      0.017845306545495987,
      -0.002804563147947192,
      0.007891835644841194,
      -0.04846100136637688,
      -0.012938477098941803,
      0.11810912936925888,
      0.03395475074648857,
      -0.05660321190953255,
      0.026486162096261978,
      -0.010463331826031208,
      0.004502018913626671,
      -0.0036412582267075777,
      0.029656164348125458,
      -0.0013846952933818102,
      -0.0378938764333725,
      -0.04592644423246384,
      -0.07543670386075974,
      0.030640963464975357,
      -0.0039189825765788555,
      -0.020105862990021706,
      0.11060944199562073,
      -0.0043445127084851265,
      0.04490209370851517,
      -0.03701845929026604,
      0.011457848362624645,
      0.005606981925666332,
      0.09514585137367249,
      -0.06397489458322525,
      0.017535148188471794,
      -0.00443081883713603,
      -0.0111783342435956,
      -0.09065435826778412,
      0.034511856734752655,
      -0.03894166275858879,
      -0.016534149646759033,
      -0.01161658763885498,
      -0.0032313233241438866,
      -0.07030905783176422,
      -0.09106336534023285,
      0.018459852784872055,
      -0.07033395022153854,
      -0.01380656287074089,
      -0.05148535221815109,
      -0.015827033668756485,
      -0.0960405021905899,
      0.038914598524570465,
      0.01573086343705654,
      0.0008884214330464602,
      -0.020169131457805634,
      -0.01920398883521557,
      0.03972988575696945,
      0.07242103666067123,
      0.05127457156777382,
      0.027649862691760063,
      0.04420491307973862,
      0.03397955745458603,
      -0.07320310175418854,
      -0.08114861696958542,
      0.13997380435466766,
      -0.009110663086175919,
      0.023461436852812767,
      0.038257066160440445,
      0.008612108416855335,
      -0.042584262788295746,
      0.04391805827617645,
      0.054185111075639725,
      0.0037562695797532797,
      -0.0014485466526821256,
      0.02349161170423031,
      -0.04126712679862976,
      0.048108264803886414,
      -0.013896657153964043,
      0.059103794395923615,
      0.007631002459675074,
      0.0347818098962307,
      0.00829982478171587,
      -0.008910114876925945,
      -0.023697370663285255,
      0.07551707327365875,
      0.059313226491212845,
      0.03501318022608757,
      0.14076031744480133,
      -0.0315321646630764,
      0.03987930715084076,
      0.022111307829618454,
      0.0044026984833180904,
      -0.008290956728160381,
      -0.062100812792778015,
      0.021915212273597717,
      0.0072924853302538395,
      -0.02901357226073742,
      0.04506060853600502,
      -0.03076392039656639,
      -0.04535816237330437,
      0.04371519759297371,
      -0.013255969621241093,
      0.012057251296937466,
      -0.10682812333106995,
      -0.01440811064094305,
      -0.00674605555832386,
      -0.04438764601945877,
      0.046177349984645844,
      0.01598256081342697,
      0.008157499134540558,
      0.0429178811609745,
      0.025432538241147995,
      -0.024650761857628822,
      -0.03751048818230629,
      0.02935393527150154,
      0.05685614049434662,
      -0.033933378756046295,
      0.08755265921354294,
      -0.05872485041618347,
      -0.02187035046517849,
      -0.08054546266794205,
      0.0004580683307722211,
      -0.01871011219918728,
      0.1066407561302185,
      0.05513080209493637,
      -0.05935972183942795,
      -0.011308339424431324,
      0.07327864319086075,
      0.002225227653980255,
      -0.0360528938472271,
      -0.007530775386840105,
      0.09162291884422302,
      -0.03121405653655529,
      -0.030048727989196777,
      -0.04410303011536598,
      0.02543291449546814,
      0.03137912601232529,
      0.05605752393603325,
      0.020951027050614357,
      -0.033546753227710724,
      0.1062658503651619,
      0.047755178064107895,
      0.003958053886890411,
      -0.04790060967206955,
      -0.015757348388433456,
      -0.07645392417907715,
      -0.02780735492706299,
      -0.00896711740642786,
      -0.06401505321264267,
      -0.04121877998113632,
      0.03428413346409798,
      0.06985843926668167,
      -0.06688360124826431,
      -0.028474032878875732,
      -0.037110596895217896,
      0.0007912234286777675,
      -0.01855890452861786,
      -0.044756144285202026,
      0.009331246837973595,
      0.06440326571464539,
      -0.00759979197755456,
      0.07833684980869293,
      0.06726133078336716,
      0.009278487414121628,
      0.06167119741439819,
      -0.0016526025719940662,
      -0.05697010084986687,
      -0.06780673563480377,
      0.08449353277683258,
      -0.023135202005505562,
      0.04909108206629753,
      0.08083786815404892,
      -0.08224949240684509,
      0.042127128690481186,
      0.02274506725370884,
      -0.023359058424830437,
      -0.0504194013774395,
      0.021176595240831375,
      0.011450441554188728,
      -0.037503354251384735,
      -0.014226219616830349,
      0.029221275821328163,
      0.01403750665485859,
      0.04957425594329834,
      -0.08185192197561264,
      0.017019333317875862,
      -0.02887442149221897,
      0.09367033839225769,
      0.006711168214678764,
      0.008231575600802898,
      0.05274021252989769,
      -0.08159930258989334,
      0.05722983926534653,
      0.08767855912446976,
      -0.025017395615577698,
      -0.09005293250083923,
      0.01252489723265171,
      0.007489562500268221,
      -0.05757279321551323,
      -0.04855817183852196,
      0.03303610533475876,
      -0.05344127118587494,
      0.011034151539206505,
      0.006306981667876244,
      -0.03281216695904732,
      -0.023857470601797104,
      -0.02850295975804329,
      -0.030322128906846046,
      -0.0766017735004425,
      0.14468683302402496,
      0.03975558280944824,
      0.02258031815290451,
      0.029828013852238655,
      0.0700974240899086,
      0.03746147081255913,
      0.022631855681538582,
      -0.002874250989407301,
      -0.1049022451043129,
      -0.0017693095142021775,
      -0.07656513899564743,
      -0.025664880871772766,
      -0.008338617160916328,
      -0.0637151300907135,
      0.004545020870864391,
      0.026372438296675682,
      -0.0038043418899178505,
      0.05471576750278473,
      -0.0380757600069046,
      0.032307934015989304,
      0.003206152468919754,
      -0.01422057207673788,
      0.006716764532029629,
      -0.006488332990556955,
      0.016098525375127792,
      -0.0067849247716367245,
      -0.06172827631235123,
      0.015366279520094395,
      -0.008404163643717766,
      -0.01717320829629898,
      -0.09303304553031921,
      0.05229789391160011,
      -0.011331651359796524,
      0.019790658727288246,
      0.006046624388545752,
      -0.030445300042629242,
      0.057754240930080414,
      -0.03372317925095558,
      -0.06682295352220535,
      0.015921439975500107,
      -0.014514118432998657,
      0.04579993337392807,
      0.13106650114059448,
      0.02683868631720543,
      -0.015810556709766388,
      0.08216399699449539,
      -0.013017052784562111,
      -0.036011941730976105,
      0.012062246911227703,
      0.014365380629897118,
      -0.09425556659698486,
      0.061663541942834854,
      0.009356044232845306,
      -0.009298399090766907,
      0.03143507242202759,
      -0.007075874134898186,
      -0.07066456228494644,
      -0.018290072679519653,
      0.024566393345594406,
      -0.023773251101374626,
      -0.009738819673657417,
      0.057028479874134064,
      -0.02136017382144928,
      0.08120113611221313,
      0.0032892469316720963,
      0.10277969390153885,
      0.0059079863131046295,
      0.05470293387770653,
      -0.057295892387628555,
      -0.05856456235051155,
      0.018087035045027733,
      0.03689003735780716,
      -0.0010160465026274323,
      0.03971836715936661,
      -0.005302519537508488,
      0.02839219756424427,
      0.09193411469459534,
      -0.015420971438288689,
      0.026827163994312286,
      -0.03342141583561897,
      0.06330583244562149,
      -0.09362369030714035,
      -0.031372666358947754,
      0.007814387790858746,
      -0.023920632898807526,
      0.0013070721179246902,
      0.032911546528339386,
      -0.0006941948086023331,
      -0.04136926308274269,
      0.004855266306549311,
      -0.00609448179602623,
      0.0048536453396081924,
      0.007862049154937267,
      -0.04122568666934967,
      -0.019686659798026085,
      -0.04375636577606201,
      -0.020575998350977898,
      0.0069837626069784164,
      0.02880272828042507,
      -0.038566477596759796
    ],
    [
      -0.012474150396883488,
      0.023063041269779205,
      0.019602356478571892,
      0.03453531488776207,
      0.011095593683421612,
      -0.03016001731157303,
      0.08943525701761246,
      -0.016479868441820145,
      -0.017726365476846695,
      -0.00041971885366365314,
      0.1067175641655922,
      0.023849528282880783,
      0.018470782786607742,
      -0.006810239981859922,
      -0.0852552279829979,
      0.06346690654754639,
      0.026143811643123627,
      0.05561749264597893,
      -0.017380226403474808,
      0.02915194444358349,
      -0.028399784117937088,
      -0.06626273691654205,
      -0.011487094685435295,
      -0.022218117490410805,
      0.05842434614896774,
      -0.005463460460305214,
      0.021676544100046158,
      -0.10733933746814728,
      -0.07472803443670273,
      0.02510625682771206,
      -0.01854638010263443,
      -0.03437567502260208,
      0.01506036426872015,
      0.023574959486722946,
      0.0206875242292881,
      0.01789396069943905,
      0.07575225085020065,
      0.031329575926065445,
      0.021184341982007027,
      0.04882822185754776,
      0.022994831204414368,
      0.017419269308447838,
      0.019582929089665413,
      -0.028792433440685272,
      0.06635118275880814,
      0.11546927690505981,
      -0.03892131894826889,
      -0.0008595888502895832,
      -0.008551242761313915,
      -0.08719959110021591,
      -0.03315369784832001,
      0.015895459800958633,
      0.010709325782954693,
      -0.0007406393997371197,
      -0.08846615254878998,
      0.011183889582753181,
      0.0186470840126276,
      0.0047796498984098434,
      -0.01986018195748329,
      0.072468101978302,
      -0.004120626952499151,
      0.01153645385056734,
      -0.03176962584257126,
      0.03581419214606285,
      0.05746939405798912,
      0.054310042411088943,
      0.05290602147579193,
      0.014621074311435223,
      -0.004001311957836151,
      0.022572509944438934,
      0.045208919793367386,
      -0.028516152873635292,
      0.008685783483088017,
      -0.06560743600130081,
      -0.06042325124144554,
      -0.039315786212682724,
      -0.030741602182388306,
      -0.09482336044311523,
      0.03745259344577789,
      0.03304927051067352,
      -0.022671829909086227,
      -0.03128053992986679,
      -0.00217752275057137,
      0.039418723434209824,
      0.05274083837866783,
      0.028853710740804672,
      0.022316675633192062,
      0.014700750820338726,
      -0.012068635784089565,
      0.04156041145324707,
      0.05013978108763695,
      0.027037663385272026,
      -0.060551032423973083,
      0.005736954510211945,
      0.014544758945703506,
      0.028017167001962662,
      -0.024649448692798615,
      -0.036784376949071884,
      -0.021819423884153366,
      0.07342918962240219,
      0.014052570797502995,
      0.057067807763814926,
      0.06284487247467041,
      0.03154824674129486,
      -0.02066640369594097,
      -0.08766082674264908,
      0.019094271585345268,
      0.01124541088938713,
      -0.06602068990468979,
      -0.07225150614976883,
      -0.006569018587470055,
      0.04719924181699753,
      0.04851913079619408,
      0.010040550492703915,
      0.02462456375360489,
      -0.034204669296741486,
      0.041528258472681046,
      0.07324173301458359,
      0.029871029779314995,
      0.10404779762029648,
      0.07051197439432144,
      0.030003083869814873,
      -0.048290591686964035,
      -0.03160155937075615,
      -0.006298223044723272,
      -0.028774380683898926,
      -0.056313902139663696,
      0.029262416064739227,
      0.04175126925110817,
      0.052566200494766235,
      0.06488156318664551,
      0.010525708086788654,
      -0.006528805010020733,
      0.013128157705068588,
      -0.0022122706286609173,
      -0.09580966830253601,
      0.01798452064394951,
      0.09398490190505981,
      -0.057327598333358765,
      0.025067541748285294,
      -0.06104026362299919,
      -0.0056108636781573296,
      -0.04918836057186127,
      0.00022789592912886292,
      0.015243365429341793,
      -0.11407343298196793,
      -0.004850249737501144,
      -0.02689610980451107,
      -0.04572588577866554,
      0.026233473792672157,
      0.03762224316596985,
      -0.0021186424419283867,
      0.05180596560239792,
      -0.01931360922753811,
      -0.0724656879901886,
      -0.017180711030960083,
      0.00017465709242969751,
      -0.04333263263106346,
      0.0014463401166722178,
      -0.04633437469601631,
      0.15615995228290558,
      0.01251283660531044,
      0.01745242439210415,
      0.0572284460067749,
      0.04088645800948143,
      0.03859936073422432,
      0.0079808309674263,
      0.0016097233165055513,
      0.013169832527637482,
      0.059391800314188004,
      0.015384191647171974,
      0.05071267485618591,
      0.07858046144247055,
      -0.04575224965810776,
      -0.03351146727800369,
      -0.05548560246825218,
      -0.0085818562656641,
      0.055806491523981094,
      -0.06580056250095367,
      0.029699798673391342,
      -0.04886562377214432,
      -0.01145175751298666,
      0.026671919971704483,
      0.016633501276373863,
      0.03217313066124916,
      -0.06307584792375565,
      0.03857313096523285,
      0.03200996294617653,
      0.015330919064581394,
      0.04337450861930847,
      -0.04097554087638855,
      -0.07903110235929489,
      0.046755895018577576,
      0.03251979500055313,
      -0.0026478443760424852,
      -0.08640438318252563,
      0.014690818265080452,
      -0.026953212916851044,
      -0.0159256923943758,
      0.009454770013689995,
      0.09196729212999344,
      0.01708541437983513,
      -0.05200498178601265,
      -0.03721671551465988,
      -0.0061479914002120495,
      -0.019352447241544724,
      -0.018572188913822174,
      0.05473853647708893,
      0.02353745326399803,
      0.027557002380490303,
      -0.030995339155197144,
      -0.03210859373211861,
      0.01819862425327301,
      -0.007302682846784592,
      -0.030863337218761444,
      0.007098534610122442,
      0.014546269550919533,
      0.045758843421936035,
      0.03223690763115883,
      0.041124388575553894,
      -0.06372779607772827,
      -0.013065658509731293,
      -0.010251236148178577,
      0.05785074084997177,
      -0.0040790303610265255,
      0.07187926024198532,
      -0.026959819719195366,
      -0.020093373954296112,
      -0.02419351227581501,
      0.02878650091588497,
      -0.013726336881518364,
      -0.055038318037986755,
      0.03953966870903969,
      0.04568195715546608,
      -0.04033205285668373,
      -0.07398930937051773,
      -0.05249635502696037,
      0.005244392901659012,
      -0.07946247607469559,
      -0.046550728380680084,
      0.020811155438423157,
      0.022409044206142426,
      -0.03642385080456734,
      0.005283213220536709,
      0.014639549888670444,
      -0.0348440520465374,
      -0.038776740431785583,
      -0.0069419993087649345,
      0.01579059101641178,
      0.030910402536392212,
      -0.02162892557680607,
      -0.033324237912893295,
      0.003752050455659628,
      -0.012965282425284386,
      -0.05111348256468773,
      0.020404750481247902,
      -0.0942544937133789,
      -0.029201818630099297,
      -0.04227087274193764,
      0.001162738655693829,
      0.02758522890508175,
      -0.05605395510792732,
      0.038545675575733185,
      -0.03796958923339844,
      0.04392348974943161,
      -0.04820745438337326,
      -0.058502405881881714,
      -0.006021677982062101,
      0.03855449706315994,
      0.015208940021693707,
      0.0002452565240673721,
      -0.03392219543457031,
      -0.012133640237152576,
      -0.023088831454515457,
      -0.0002460973628330976,
      -0.015057769604027271,
      0.0028829199727624655,
      0.06973307579755783,
      -0.013140146620571613,
      -0.04097287356853485,
      -0.016244322061538696,
      -0.0440196730196476,
      -0.028850944712758064,
      -0.036425791680812836,
      -0.020144028589129448,
      -0.006826863624155521,
      0.030565712600946426,
      -0.02903386391699314,
      0.04405343160033226,
      -0.04789717122912407,
      0.033078331500291824,
      0.029869483783841133,
      -0.07886677235364914,
      -0.04775639995932579,
      -0.0019171772291883826,
      -0.024880249053239822,
      -0.017922746017575264,
      -0.037875257432460785,
      0.00894917268306017,
      -0.02013535425066948,
      0.02495647221803665,
      -0.04487520083785057,
      -0.07167637348175049,
      -0.004644839558750391,
      0.018579142168164253,
      -0.049429479986429214,
      0.011052963323891163,
      0.00326007348485291,
      -0.016691572964191437,
      0.07800722867250443,
      -0.035222865641117096,
      0.09071162343025208,
      -0.005101156421005726,
      0.026194270700216293,
      -0.13477198779582977,
      -0.009574171155691147,
      0.025687597692012787,
      0.0019836933352053165,
      0.019747653976082802,
      0.022000303491950035,
      0.037015724927186966,
      0.04443921893835068,
      0.05183873325586319,
      -0.005971609614789486,
      0.004912605509161949,
      0.07970444858074188,
      -0.028651393949985504,
      0.03852758929133415,
      0.04345284029841423,
      -0.019770482555031776,
      -0.028524737805128098,
      0.015941599383950233,
      -0.0778677836060524,
      0.02440067194402218,
      0.05260526016354561,
      0.029905973002314568,
      0.006778725888580084,
      -0.06531289964914322,
      0.017238596454262733,
      -0.024743996560573578,
      -0.050987403839826584,
      -0.010015536099672318,
      -0.060503896325826645,
      -0.05277571827173233,
      0.03783581405878067,
      0.06675154715776443,
      0.0602404810488224,
      -0.05580874904990196,
      0.05300161615014076,
      -0.05441723018884659,
      0.017661331221461296,
      0.04279074817895889,
      -0.07645326852798462,
      0.010735779069364071,
      -0.02716822177171707,
      0.06861857324838638,
      0.05499154329299927,
      0.05422332137823105,
      -0.014699072577059269,
      -0.06828948855400085,
      -0.01862981729209423,
      0.132428839802742,
      -0.0194958858191967,
      -0.01865483820438385,
      0.018773971125483513,
      -0.0011794224847108126,
      0.06002548709511757,
      -0.1052016019821167,
      0.0007038632757030427,
      -0.07147861272096634,
      0.0325697585940361,
      -0.10488227009773254,
      0.02411145530641079,
      0.09389655292034149,
      0.0008143418817780912,
      0.050648681819438934,
      0.018450558185577393,
      0.03629311919212341,
      -0.02617953158915043,
      -0.10023412108421326,
      0.06086675077676773,
      0.022201288491487503,
      0.07714638859033585,
      -0.02893937937915325,
      0.013700435869395733,
      -0.010789097286760807,
      -0.04534098505973816,
      0.04106675833463669,
      0.05156377702951431,
      0.034417107701301575,
      0.0294448621571064,
      0.04755053296685219,
      0.024073781445622444,
      0.039483025670051575,
      -0.01767854019999504,
      0.06916242092847824,
      -0.05851282179355621,
      0.0482933446764946,
      0.04877544194459915,
      -0.10355338454246521,
      -0.0503489226102829,
      0.042444050312042236,
      -0.052054550498723984,
      0.02871626615524292,
      -0.03479931876063347,
      0.034466736018657684,
      -0.019479256123304367,
      -0.01035580039024353,
      0.06242576986551285,
      -0.015103948302567005,
      0.05614174157381058,
      -0.004851364530622959,
      -0.037269413471221924,
      -0.004764740355312824,
      -0.02894197776913643,
      0.03242115676403046,
      -0.04380866512656212,
      -0.0018730356823652983,
      -0.045022111386060715,
      0.1470298022031784,
      0.0365121029317379,
      0.04455030709505081,
      -0.12971477210521698,
      0.0771663635969162,
      -0.08792800456285477,
      0.0008316480088979006,
      -0.051339730620384216,
      -0.008877512067556381,
      -0.02498411014676094,
      0.04469526931643486,
      -0.10931415855884552,
      -0.015610297210514545,
      -0.010406091809272766,
      -5.957645771559328e-05,
      0.015587085857987404,
      -0.06785720586776733,
      0.0747607946395874,
      0.018252231180667877,
      -0.010941007174551487,
      -0.026720060035586357,
      -0.08583769202232361,
      0.03631823509931564,
      -0.05435870587825775,
      0.003730113385245204,
      0.09653447568416595,
      0.04224245622754097,
      0.04307097941637039,
      0.05348958447575569,
      -0.04754503071308136,
      0.055373504757881165,
      -0.008093636482954025,
      -0.009087351150810719,
      -0.06914851814508438,
      -0.04186412692070007,
      0.05517728999257088,
      -0.0259352158755064,
      -0.0439847894012928,
      -0.006377016194164753,
      -0.007947702892124653,
      0.05401443690061569,
      0.0029080132953822613,
      -0.011855672113597393,
      0.019422035664319992,
      0.029714373871684074,
      0.06016748771071434,
      -0.00573927303776145,
      0.005477634258568287,
      0.09658066183328629,
      0.08320852369070053,
      0.02736232802271843,
      -0.026524212211370468,
      0.008942065760493279,
      -0.014725987799465656,
      0.025006677955389023,
      -0.01862761192023754,
      -0.1144416481256485,
      0.006325095426291227,
      -0.04356606304645538,
      0.01926707662642002,
      0.055127572268247604,
      0.006631128955632448,
      -0.059746842831373215,
      0.0994325578212738,
      0.03982863947749138,
      0.009846188127994537,
      0.020106973126530647,
      -0.04575716704130173,
      -0.019409311935305595,
      -0.04028940200805664,
      -0.03568179905414581,
      0.02068319544196129,
      0.00907833594828844,
      0.010311168618500233,
      -0.03462832793593407,
      -0.002771813655272126,
      0.06278437376022339,
      0.006368087604641914,
      0.039089005440473557,
      0.0002497136010788381,
      -0.020275849848985672,
      0.035817522555589676,
      -0.07781309634447098,
      0.0024314075708389282
    ],
    [
      0.02441692166030407,
      0.021998446434736252,
      0.0367635153234005,
      0.05904819816350937,
      0.07702364772558212,
      0.017970597371459007,
      0.03566429391503334,
      0.03048975206911564,
      -0.002218289067968726,
      0.04441509768366814,
      0.0031255739741027355,
      -0.0431661456823349,
      0.042038336396217346,
      0.02303411066532135,
      -0.012058798223733902,
      -0.005720097105950117,
      -0.046105917543172836,
      -0.020581895485520363,
      -0.011613600887358189,
      0.043410804122686386,
      0.03229289874434471,
      0.034556299448013306,
      0.031389087438583374,
      0.010902565903961658,
      0.017462370917201042,
      0.007681573741137981,
      -0.02158535085618496,
      -0.11159016191959381,
      -0.04594849422574043,
      0.08279844373464584,
      0.007770613767206669,
      -0.0033116729464381933,
      0.021337561309337616,
      0.03965526074171066,
      0.09979414939880371,
      -0.025240518152713776,
      0.015922080725431442,
      -0.05235951766371727,
      -0.02036505565047264,
      0.016550472006201744,
      -0.020341167226433754,
      0.01663585752248764,
      0.007985804229974747,
      -0.024994639679789543,
      -0.0038936042692512274,
      -0.00843319296836853,
      -0.017412623390555382,
      0.016569610685110092,
      0.05234028398990631,
      -0.04300086945295334,
      -0.09046881645917892,
      0.0013265886809676886,
      0.08937340974807739,
      0.04499721899628639,
      0.03874988108873367,
      -0.02077437937259674,
      0.010579004883766174,
      0.023201249539852142,
      -0.13409730792045593,
      -0.010292528197169304,
      -0.09242547303438187,
      0.01578005962073803,
      -0.05588194727897644,
      -0.03282808139920235,
      0.0018110527889803052,
      -0.05395940691232681,
      -0.041747767478227615,
      0.045173581689596176,
      0.09812051057815552,
      -0.03155513107776642,
      0.03325903043150902,
      0.08538828045129776,
      0.007598880212754011,
      -0.03219786658883095,
      -0.009924730286002159,
      0.03283792361617088,
      -0.11719122529029846,
      0.04719133302569389,
      0.03315959870815277,
      0.06825461238622665,
      -0.018477484583854675,
      -0.02568291872739792,
      0.030249398201704025,
      -0.05884743854403496,
      -0.03576704487204552,
      -0.03827952221035957,
      0.006907238624989986,
      0.06796321272850037,
      -0.020501254126429558,
      -0.04354095831513405,
      -0.007666704244911671,
      0.05589517578482628,
      0.03662372753024101,
      0.08756809681653976,
      0.01066394243389368,
      -0.013220075517892838,
      0.02623445726931095,
      0.06570148468017578,
      -0.017061209306120872,
      0.06100192293524742,
      -0.054277557879686356,
      0.011256774887442589,
      0.03982973471283913,
      0.04100145399570465,
      0.02926461584866047,
      -0.003865355160087347,
      -0.0005972726503387094,
      0.040809884667396545,
      -0.05224957689642906,
      -0.10219192504882812,
      0.03589906543493271,
      0.006650380324572325,
      -0.0677475780248642,
      -0.06120830401778221,
      0.07984202355146408,
      -0.045292627066373825,
      -0.12544196844100952,
      -0.025959474965929985,
      -0.006997192278504372,
      -0.025192538276314735,
      -0.022631356492638588,
      -0.04118690267205238,
      0.05716114863753319,
      -0.003919610753655434,
      0.04806401953101158,
      0.05295286327600479,
      0.039700042456388474,
      0.031021207571029663,
      -0.06634252518415451,
      0.09232745319604874,
      -0.08770175278186798,
      0.011450418271124363,
      0.00966821238398552,
      0.021941864863038063,
      0.04844215512275696,
      -0.0016466801753267646,
      0.07439985871315002,
      0.01664797030389309,
      0.0626460462808609,
      0.013453477062284946,
      -0.01608075387775898,
      0.0009730728343129158,
      6.184621452121064e-05,
      -0.015700319781899452,
      0.11109540611505508,
      -0.010258883237838745,
      0.06437321752309799,
      0.08683434873819351,
      -0.06026782467961311,
      0.015886973589658737,
      -0.03145550936460495,
      0.03583768755197525,
      0.04087851196527481,
      0.07550843805074692,
      -0.057060353457927704,
      0.01303133089095354,
      0.04717518761754036,
      -0.06483866274356842,
      -0.0010619157692417502,
      0.017803892493247986,
      0.010362377390265465,
      0.00991028267890215,
      0.002926941029727459,
      0.022615544497966766,
      -0.06122678145766258,
      -0.02570977248251438,
      0.08956348896026611,
      0.004528372082859278,
      -0.042168498039245605,
      0.007064020726829767,
      0.02417624741792679,
      0.02263314090669155,
      0.019747111946344376,
      0.026591196656227112,
      -0.03450968489050865,
      0.015198164619505405,
      -0.0036863223649561405,
      -0.027078432962298393,
      0.010323567315936089,
      -0.04547835886478424,
      0.004139482509344816,
      0.04979377239942551,
      -0.033327728509902954,
      0.01574292592704296,
      -0.031681645661592484,
      0.025545179843902588,
      -0.055916015058755875,
      -0.08836488425731659,
      0.019923003390431404,
      -0.07877833396196365,
      0.04832141101360321,
      -0.015454585663974285,
      0.033841054886579514,
      -0.047369737178087234,
      0.06452812254428864,
      0.009921837598085403,
      -0.02181973122060299,
      -0.03515506535768509,
      0.06527436524629593,
      -0.04304169863462448,
      -0.017203059047460556,
      -0.05717742070555687,
      0.09329493343830109,
      -0.029067687690258026,
      0.019377196207642555,
      0.03182458505034447,
      0.07844169437885284,
      -0.007608896121382713,
      0.05630294606089592,
      -0.0707896500825882,
      -0.05865216627717018,
      -0.053347304463386536,
      0.06878795474767685,
      -0.053707629442214966,
      0.03414764255285263,
      -0.02510247379541397,
      0.04464375600218773,
      0.00687864376232028,
      0.011093095876276493,
      0.04434879496693611,
      0.09648754447698593,
      0.017293045297265053,
      0.10361414402723312,
      0.054961588233709335,
      -0.002444774843752384,
      0.030159935355186462,
      0.03284618258476257,
      0.06160922721028328,
      0.013073114678263664,
      -0.040129274129867554,
      -0.06066860258579254,
      -0.12246996909379959,
      0.026885824277997017,
      0.01284570712596178,
      -0.03079083561897278,
      -0.03995189815759659,
      0.04116847366094589,
      -0.02126649022102356,
      -0.03997590020298958,
      -0.01901201158761978,
      0.05650283396244049,
      -0.01089254580438137,
      -0.020585181191563606,
      0.015907954424619675,
      -0.05412837117910385,
      -0.056193895637989044,
      -0.03892119973897934,
      0.02565203420817852,
      0.03036879561841488,
      -0.09246167540550232,
      0.004107596818357706,
      -0.04672599211335182,
      0.03914278745651245,
      0.04129823297262192,
      0.011539733037352562,
      -0.03579181432723999,
      -0.10627412050962448,
      0.0066600400023162365,
      -0.002393589820712805,
      0.048009999096393585,
      0.10114948451519012,
      0.033052124083042145,
      0.012707902118563652,
      0.04652014374732971,
      0.053913414478302,
      0.059428419917821884,
      0.04006768763065338,
      0.03595913574099541,
      0.032738182693719864,
      -0.0724962055683136,
      0.05798918753862381,
      -0.037254273891448975,
      0.07101534307003021,
      -0.0338670015335083,
      0.04011877626180649,
      -0.03906124085187912,
      0.012089669704437256,
      -0.019961761310696602,
      0.005950612481683493,
      0.07748235762119293,
      -0.09479328244924545,
      0.002701448742300272,
      0.05329633504152298,
      -0.061982929706573486,
      -0.005154979415237904,
      0.053856320679187775,
      -0.0460035465657711,
      -0.03813188895583153,
      0.008854016661643982,
      0.03476333990693092,
      -0.02283964864909649,
      0.02146882191300392,
      0.06108934059739113,
      -0.011830701492726803,
      -0.037838444113731384,
      -0.03539429232478142,
      -0.07509490102529526,
      -0.014740503393113613,
      -0.0213609766215086,
      -0.04448660835623741,
      0.03662433847784996,
      -0.03225160017609596,
      0.016930092126131058,
      -0.01843516156077385,
      0.07748810946941376,
      0.006913343444466591,
      -0.032382361590862274,
      0.0027705812826752663,
      0.052099499851465225,
      0.001800780650228262,
      -0.021607348695397377,
      0.03304895758628845,
      -0.028759930282831192,
      0.015248671174049377,
      0.005359916482120752,
      0.012274163775146008,
      0.0125401197001338,
      0.04109937325119972,
      -0.003981702495366335,
      -0.033924926072359085,
      0.012424224056303501,
      0.026964884251356125,
      -0.039984092116355896,
      -0.028459273278713226,
      -0.020655153319239616,
      0.07638430595397949,
      -0.12416919320821762,
      -0.11563490331172943,
      -0.0009138546301983297,
      -0.04072922468185425,
      0.07250100374221802,
      0.05875789001584053,
      -0.05373474210500717,
      0.07441843301057816,
      -0.01431021373718977,
      0.03342985734343529,
      0.012152680195868015,
      -0.017557304352521896,
      -0.053050968796014786,
      0.0002648438385222107,
      -0.024766307324171066,
      -0.03635803982615471,
      0.003714038757607341,
      0.04174894094467163,
      -0.05046643316745758,
      -0.006018541753292084,
      0.0043631321750581264,
      -0.044526513665914536,
      0.0301602054387331,
      0.004549374803900719,
      -0.09290872514247894,
      -0.03365891054272652,
      -0.00492516253143549,
      -0.007922939956188202,
      0.02342493087053299,
      0.0389896035194397,
      -0.009459166787564754,
      0.057227715849876404,
      0.03752980753779411,
      0.02375807613134384,
      -0.010553017258644104,
      0.03356270119547844,
      0.015175571665167809,
      -0.06282003968954086,
      -0.01001893263310194,
      0.1362421214580536,
      0.0042681642808020115,
      0.011648843996226788,
      0.03769577667117119,
      0.0292205810546875,
      -0.020030001178383827,
      0.030857983976602554,
      0.07792393863201141,
      0.04066044092178345,
      -0.025205299258232117,
      -0.07399963587522507,
      0.04050267115235329,
      -0.00777076929807663,
      0.04237180948257446,
      0.013224015943706036,
      0.025686079636216164,
      0.022839277982711792,
      -0.06425808370113373,
      0.04408275708556175,
      -0.048702172935009,
      -0.013959337957203388,
      -0.003605114296078682,
      0.0576397180557251,
      -0.04915117844939232,
      0.04796537384390831,
      0.0332811065018177,
      -0.04551519453525543,
      -0.021119456738233566,
      -0.008312788791954517,
      0.01629396341741085,
      0.03603505715727806,
      0.06585222482681274,
      0.0002318041806574911,
      0.06202298775315285,
      0.015684176236391068,
      0.013085826300084591,
      0.02099219337105751,
      -0.00813071709126234,
      0.05651519075036049,
      0.02369655668735504,
      0.04282989725470543,
      0.020161375403404236,
      -0.07889316976070404,
      -0.08702646195888519,
      -0.0020804419182240963,
      -0.058438148349523544,
      -0.012211618013679981,
      0.02242407761514187,
      0.014052335172891617,
      0.021542029455304146,
      0.0050726840272545815,
      -0.05463865026831627,
      0.018922099843621254,
      -0.04628707468509674,
      0.1440582424402237,
      -0.0021740440279245377,
      -0.018316157162189484,
      -0.028577977791428566,
      -0.041285980492830276,
      0.0396733358502388,
      0.0299678947776556,
      -0.0670265480875969,
      0.03233466297388077,
      -0.039368513971567154,
      0.03905707225203514,
      -0.08908792585134506,
      0.03214113041758537,
      0.02445983700454235,
      -0.053367748856544495,
      0.03466157987713814,
      0.016270006075501442,
      -0.006882656365633011,
      -0.07298599928617477,
      -0.018156742677092552,
      -0.010858715511858463,
      -0.08781959116458893,
      0.03402496874332428,
      -0.10645554959774017,
      0.04578787460923195,
      -0.018657952547073364,
      0.06234050542116165,
      0.020692145451903343,
      0.09704844653606415,
      -0.059738192707300186,
      0.05549296736717224,
      -0.045305490493774414,
      0.03518872708082199,
      -0.0012410633498802781,
      -0.005420369561761618,
      -0.03415536880493164,
      -0.04116366058588028,
      0.0009638975607231259,
      0.007004956714808941,
      0.03067164681851864,
      0.027589136734604836,
      -0.0017593158408999443,
      -0.004823962226510048,
      0.04381902888417244,
      -0.02878374606370926,
      -0.07755517959594727,
      -0.005479813553392887,
      -0.0776132121682167,
      0.022223427891731262,
      0.02070966362953186,
      0.005744617432355881,
      -0.07950956374406815,
      -0.07475390285253525,
      0.05308370292186737,
      0.0006438492564484477,
      -0.10127738863229752,
      0.033947937190532684,
      0.026201952248811722,
      0.03523457795381546,
      0.00014222375466488302,
      -0.024136334657669067,
      -0.06018313020467758,
      0.02411632426083088,
      -0.05158142000436783,
      0.05412016063928604,
      0.02273627370595932,
      -0.012954387813806534,
      0.027140211313962936,
      0.02048395574092865,
      -0.03194992616772652,
      0.05616835504770279,
      -0.033325713127851486,
      0.05957717075943947,
      -0.014070666395127773,
      -0.059396740049123764,
      0.017785411328077316,
      0.04216938838362694,
      0.004525655414909124,
      -0.024217639118433,
      0.004010477568954229,
      0.023664088919758797,
      0.037445489317178726,
      -0.047196030616760254,
      0.053233787417411804
    ],
    [
      0.033703532069921494,
      -0.027920125052332878,
      0.05877245217561722,
      -0.02265930362045765,
      0.015589014627039433,
      0.006288428790867329,
      -0.01123387273401022,
      -0.10397970676422119,
      0.047968313097953796,
      0.043617554008960724,
      0.0034875215496867895,
      0.07694382965564728,
      0.03499523550271988,
      0.0005983873270452023,
      0.02400481514632702,
      -0.04219914972782135,
      -0.014853294007480145,
      -0.009287881664931774,
      0.013238918967545033,
      -0.04230376332998276,
      -0.03792865201830864,
      0.026796935126185417,
      -0.01236274279654026,
      -0.08775097131729126,
      0.0077130673453211784,
      -0.001402396708726883,
      0.051601849496364594,
      0.05872563272714615,
      -0.02300700545310974,
      0.10875000804662704,
      0.007490040268748999,
      0.01164668332785368,
      0.0249768253415823,
      0.032693635672330856,
      -0.07566380500793457,
      0.06462914496660233,
      -0.020460115745663643,
      0.03179413080215454,
      0.05210331082344055,
      0.06131195276975632,
      -0.03276835381984711,
      0.012057985179126263,
      -0.016351822763681412,
      -0.042210906744003296,
      0.005891527980566025,
      -0.01105186901986599,
      -0.052580296993255615,
      0.007875860668718815,
      -0.027367714792490005,
      0.033186424523591995,
      0.004284311551600695,
      0.10802658647298813,
      0.006708427798002958,
      -0.0026959076058119535,
      -0.0448443628847599,
      -0.048642534762620926,
      0.045711398124694824,
      -0.012928595766425133,
      -0.030543847009539604,
      -0.006016543600708246,
      0.020564334467053413,
      -0.020175917074084282,
      0.0855114683508873,
      0.011132722720503807,
      -0.03603747487068176,
      -0.017517434433102608,
      0.02519037202000618,
      0.0021552820689976215,
      0.04780133441090584,
      -0.006818658672273159,
      -0.000788206874858588,
      0.0026825882960110903,
      0.05277895927429199,
      0.011816713027656078,
      -0.08569139242172241,
      0.05535293370485306,
      -0.044497035443782806,
      -0.043592408299446106,
      0.01940925046801567,
      0.025205612182617188,
      0.0165180005133152,
      -0.07114306092262268,
      -0.0006877148989588022,
      0.05337696895003319,
      0.020072897896170616,
      -0.04108504578471184,
      0.015128507278859615,
      0.07550957053899765,
      -0.048528723418712616,
      -0.0059930565766990185,
      0.022329973056912422,
      0.02449221722781658,
      0.011969340965151787,
      -0.026091212406754494,
      0.03408580645918846,
      0.005689648911356926,
      -0.08877822756767273,
      -0.04365358129143715,
      -0.037282414734363556,
      0.0027633828576654196,
      -0.025331443175673485,
      -0.02180788479745388,
      0.004032935947179794,
      0.09715809673070908,
      0.02698797732591629,
      0.00034950950066559017,
      0.004043460823595524,
      0.0004445000959094614,
      -0.012255377136170864,
      -0.022913387045264244,
      -0.042007409036159515,
      -0.06946197897195816,
      -0.06873344630002975,
      0.05053377524018288,
      0.011319846846163273,
      0.03816542774438858,
      -0.00100543478038162,
      0.006221052259206772,
      0.07046820968389511,
      0.00824067648500204,
      -0.012611350044608116,
      -0.008318107575178146,
      -0.018146056681871414,
      -0.04866922274231911,
      -0.013713052496314049,
      -0.0628604143857956,
      -0.021416280418634415,
      0.06398685276508331,
      0.10760968923568726,
      0.060205765068531036,
      -0.014726434834301472,
      -0.09606427699327469,
      0.05940987914800644,
      -0.010530258528888226,
      0.02806062437593937,
      0.009026754647493362,
      0.0186711847782135,
      -0.06453323364257812,
      -0.0352763757109642,
      -0.02778571844100952,
      -0.017510179430246353,
      -0.04410320520401001,
      0.003999220672994852,
      -0.021352672949433327,
      -0.05842537432909012,
      0.021272918209433556,
      -0.004075855016708374,
      0.014565419405698776,
      0.0003874051326420158,
      -0.008729575201869011,
      0.009327128529548645,
      -0.03851686045527458,
      0.07639644294977188,
      -0.027468392625451088,
      0.013127830810844898,
      0.017707806080579758,
      -0.039822615683078766,
      -0.02647443674504757,
      -0.03223578259348869,
      0.008005300536751747,
      -0.014404864981770515,
      0.05450180172920227,
      0.0184764564037323,
      0.03923500329256058,
      -0.01208665780723095,
      0.07425181567668915,
      -0.017030540853738785,
      -0.02807561308145523,
      0.07473208010196686,
      -0.013537649996578693,
      -0.003347820369526744,
      -0.0061004310846328735,
      0.06711973994970322,
      -0.0015485265757888556,
      0.0048875403590500355,
      0.058711279183626175,
      0.04555794969201088,
      0.08116331696510315,
      0.018857769668102264,
      0.04168714955449104,
      -0.08020084351301193,
      0.04771817848086357,
      0.022511815652251244,
      -0.020902445539832115,
      -0.08858594298362732,
      -0.00892074778676033,
      0.015267839655280113,
      -0.01694517210125923,
      0.06827142089605331,
      -0.05945323407649994,
      0.0063008470460772514,
      0.028736524283885956,
      0.04714753478765488,
      -0.0003447671770118177,
      0.04092862457036972,
      -0.03216243535280228,
      -0.06350311636924744,
      0.007984086871147156,
      0.02813176065683365,
      -0.01866936683654785,
      -0.002390329958871007,
      -0.013335129246115685,
      0.009773419238626957,
      0.023052724078297615,
      0.13903212547302246,
      0.038651444017887115,
      0.04328503832221031,
      0.09987356513738632,
      0.011062652803957462,
      -0.057271651923656464,
      -0.020462140440940857,
      -0.0278702974319458,
      0.004429475404322147,
      -0.023585926741361618,
      0.032856449484825134,
      0.08584153652191162,
      0.05524836853146553,
      0.060669999569654465,
      0.02881559170782566,
      0.0021854678634554148,
      0.050912436097860336,
      0.010214206762611866,
      -0.0043609170243144035,
      0.025782182812690735,
      0.013212386518716812,
      0.04019632562994957,
      -0.07686537504196167,
      -0.04813625290989876,
      0.04673788323998451,
      0.008459161967039108,
      0.036896947771310806,
      0.0175930168479681,
      0.01731218583881855,
      0.10877104103565216,
      0.011569365859031677,
      -0.032185111194849014,
      0.01426819246262312,
      -0.09598846733570099,
      -0.06613345444202423,
      0.02009357325732708,
      0.012712297961115837,
      0.004619342740625143,
      -0.016153080388903618,
      -0.0343189612030983,
      -0.013488970696926117,
      -0.012152319774031639,
      0.009754225611686707,
      0.0005274429568089545,
      -0.015308023430407047,
      0.05455503985285759,
      -0.03309345990419388,
      -0.02548169530928135,
      0.026973024010658264,
      -0.01924762688577175,
      0.029904350638389587,
      -0.07880976796150208,
      0.02193661965429783,
      0.07124756276607513,
      0.0339161679148674,
      -0.0108290771022439,
      -0.03847740218043327,
      0.054480865597724915,
      -0.005184182431548834,
      -0.03304662927985191,
      -0.010752502828836441,
      -0.0253516286611557,
      -0.012672504410147667,
      0.0018915666732937098,
      0.00022453299607150257,
      -0.011957109905779362,
      0.029999306425452232,
      -0.028369484469294548,
      0.033516641706228256,
      0.017019007354974747,
      -0.020976142957806587,
      0.020143667235970497,
      0.024531597271561623,
      -0.046266108751297,
      -0.03262176364660263,
      -0.05811972916126251,
      0.03868769481778145,
      -0.032024677842855453,
      0.007707861717790365,
      0.006786825135350227,
      0.005930835846811533,
      0.03414306044578552,
      -0.10410003364086151,
      -0.02084225043654442,
      -0.07140577584505081,
      0.01883021369576454,
      0.028375735506415367,
      -0.013533411547541618,
      -0.038180019706487656,
      -0.05596176162362099,
      0.07852683961391449,
      0.0479530431330204,
      0.04292582347989082,
      0.016221055760979652,
      -0.08426743000745773,
      -0.008673282340168953,
      0.03420139104127884,
      -0.08041290193796158,
      0.07237618416547775,
      -0.04267396777868271,
      0.03559093549847603,
      -0.028969712555408478,
      0.038703352212905884,
      -0.015743739902973175,
      -0.009152053855359554,
      -0.10158170014619827,
      -0.04822053387761116,
      -0.062100451439619064,
      -0.018959704786539078,
      -0.01740940660238266,
      0.03953888267278671,
      -0.032018356025218964,
      0.049107570201158524,
      0.03162831813097,
      0.043927960097789764,
      -0.023678630590438843,
      0.0547320730984211,
      0.10008703172206879,
      0.0003416691324673593,
      -0.04522274062037468,
      -0.041660796850919724,
      -0.0157847311347723,
      0.012967035174369812,
      -0.036096636205911636,
      -0.029566602781414986,
      -0.028414001688361168,
      -0.03288229927420616,
      0.012464716099202633,
      0.020946446806192398,
      -0.021556587889790535,
      0.0018864247249439359,
      0.016129393130540848,
      -0.0656750276684761,
      0.02989482320845127,
      -0.02253192476928234,
      -0.03213530033826828,
      0.03107750229537487,
      0.050326645374298096,
      -0.0700463056564331,
      0.0020341596100479364,
      0.09908726066350937,
      -0.05915156379342079,
      0.05307641252875328,
      0.022593751549720764,
      0.04147830605506897,
      0.060910794883966446,
      -0.025451848283410072,
      0.052628643810749054,
      0.011844857595860958,
      0.045541878789663315,
      -0.025942862033843994,
      0.0043296851217746735,
      0.11041567474603653,
      0.07029824703931808,
      0.06427677720785141,
      0.08955549448728561,
      0.02239214815199375,
      0.03457200899720192,
      -0.012843544594943523,
      0.02148415707051754,
      -0.03322885185480118,
      -0.040704384446144104,
      -0.03832436352968216,
      -0.02044248953461647,
      0.03343629837036133,
      -0.07674504071474075,
      -0.042621318250894547,
      0.0018410047050565481,
      -0.02444332465529442,
      0.03530578687787056,
      0.038294728845357895,
      0.0059442282654345036,
      0.07428285479545593,
      -0.009871001355350018,
      -0.005357352551072836,
      -0.038144178688526154,
      -0.0512862354516983,
      0.07256513833999634,
      -0.044066108763217926,
      -0.050523459911346436,
      0.0688963383436203,
      -0.03530207648873329,
      -0.009293248876929283,
      0.04493433237075806,
      0.03701963275671005,
      0.04894311726093292,
      -0.019934942945837975,
      -0.06130135431885719,
      -0.02642696723341942,
      0.049830362200737,
      0.01486632227897644,
      -0.06270667165517807,
      0.021956264972686768,
      0.0491948164999485,
      -0.011924211867153645,
      0.009163418784737587,
      -0.008265513926744461,
      -0.036979977041482925,
      0.01390241738408804,
      0.011112431064248085,
      0.009623863734304905,
      0.06849714368581772,
      0.017124993726611137,
      -0.01658792980015278,
      0.004109766334295273,
      0.002223714953288436,
      0.01729496568441391,
      0.06742652505636215,
      -0.02751079760491848,
      0.06837458163499832,
      0.07260308414697647,
      -0.007494121324270964,
      -0.03023122251033783,
      0.036948490887880325,
      -0.004066395573318005,
      0.035319242626428604,
      0.046085506677627563,
      -0.007549608126282692,
      -0.012743638828396797,
      -0.09101201593875885,
      0.03603024780750275,
      0.10268160700798035,
      0.0027378567028790712,
      -0.07509706169366837,
      0.005610293708741665,
      0.07216881215572357,
      0.08373367786407471,
      -0.01230093277990818,
      0.03642642870545387,
      -0.03679293766617775,
      -0.008774428628385067,
      -0.03311149403452873,
      -0.054708149284124374,
      0.044352252036333084,
      -0.04015074297785759,
      0.00028180755907669663,
      -0.023264450952410698,
      0.002275839913636446,
      -0.013814934529364109,
      0.05173048749566078,
      0.07014769315719604,
      0.0371268056333065,
      0.06688584387302399,
      0.02576451189815998,
      0.017938921228051186,
      0.0011599203571677208,
      -0.017487749457359314,
      -0.021447934210300446,
      0.0050909132696688175,
      0.017465954646468163,
      -0.009751098230481148,
      0.04547255486249924,
      0.030567625537514687,
      0.020325345918536186,
      0.004397974349558353,
      0.07174547016620636,
      0.034072648733854294,
      -0.034819766879081726,
      -0.02584271878004074,
      0.03897448256611824,
      0.05325881391763687,
      -0.06840477138757706,
      -0.03569094464182854,
      0.06319022923707962,
      0.008760914206504822,
      0.024271154776215553,
      -0.03294816613197327,
      -0.026683226227760315,
      -0.01641438901424408,
      -0.009447753429412842,
      0.10170706361532211,
      0.008322279900312424,
      0.04009873792529106,
      -0.0297680851072073,
      -0.01617640256881714,
      0.05037517845630646,
      -0.10196360945701599,
      -0.0020215504337102175,
      -0.040165625512599945,
      0.09466931968927383,
      -0.012348138727247715,
      -0.02576581761240959,
      -0.04393730312585831,
      0.03147827088832855,
      -0.06900201737880707,
      -0.019998500123620033,
      0.04071243852376938,
      0.02166670747101307,
      -0.07569782435894012,
      -0.08280395716428757,
      0.0016196378273889422,
      0.012758418917655945,
      -0.0011536640813574195,
      -0.032016266137361526,
      -0.07570987194776535,
      -0.0031758465338498354,
      0.018192768096923828,
      0.012916876934468746,
      -0.02365570142865181
    ],
    [
      -0.01754160225391388,
      0.05400926247239113,
      0.025433223694562912,
      0.07921168953180313,
      -0.036907680332660675,
      -0.010549974627792835,
      0.028191130608320236,
      -0.07794321328401566,
      0.025476597249507904,
      -0.010450659319758415,
      0.013211016543209553,
      0.1275949627161026,
      0.031552378088235855,
      -0.06805569678544998,
      -0.11776862293481827,
      -0.022239336743950844,
      0.03373970463871956,
      -0.0061551411636173725,
      0.017836492508649826,
      0.01671459525823593,
      0.030786287039518356,
      -0.00180869922041893,
      -0.025324838235974312,
      -0.00938386470079422,
      -0.07409841567277908,
      0.005022858735173941,
      0.024725783616304398,
      -0.029552340507507324,
      -0.09023440629243851,
      0.023604951798915863,
      0.007252785377204418,
      0.033639635890722275,
      0.06009100377559662,
      0.0093253618106246,
      0.034400440752506256,
      0.00039141051820479333,
      -0.06285295635461807,
      -0.06478215754032135,
      0.0023671698290854692,
      0.01703917793929577,
      -0.024567915126681328,
      0.0071890996769070625,
      0.0006228468846529722,
      -0.02307238057255745,
      0.005803073290735483,
      -0.03254792094230652,
      -0.03500911965966225,
      -0.053449906408786774,
      -0.021683022379875183,
      0.03289363905787468,
      -0.03273477032780647,
      -0.10859046876430511,
      0.029307959601283073,
      -0.017173243686556816,
      0.02450779266655445,
      -0.02636154368519783,
      0.08793644607067108,
      -0.0008683015475980937,
      0.0061861430294811726,
      0.06487488746643066,
      0.02665618248283863,
      0.020378993824124336,
      0.05534041300415993,
      -0.022541752085089684,
      0.018797269091010094,
      0.05161821469664574,
      -0.02287587709724903,
      -0.006451008841395378,
      -0.0376700721681118,
      0.018379315733909607,
      -0.024977130815386772,
      -0.024404456838965416,
      0.05093080922961235,
      -0.04022820666432381,
      -0.016246628016233444,
      0.017492730170488358,
      -0.07459433376789093,
      0.0942571759223938,
      0.06601076573133469,
      -0.09823844581842422,
      0.06433923542499542,
      0.07327292859554291,
      -0.03543359041213989,
      -0.033159103244543076,
      -0.061271604150533676,
      0.008276515640318394,
      0.021986236795783043,
      0.06181710213422775,
      -0.008792310021817684,
      0.017185963690280914,
      0.002605627989396453,
      0.029855046421289444,
      0.06817954778671265,
      -0.0018043887102976441,
      -0.04825293645262718,
      -0.04231645539402962,
      0.025880200788378716,
      0.03584769740700722,
      -0.07014156132936478,
      -0.03467449173331261,
      -0.037717677652835846,
      -0.048254769295454025,
      -0.0023365351371467113,
      -0.015249582007527351,
      0.024916965514421463,
      0.04588517174124718,
      0.000837267201859504,
      0.029376937076449394,
      -0.07234108448028564,
      -0.018010176718235016,
      0.013334070332348347,
      -0.009553642943501472,
      -0.07170473784208298,
      0.012183568440377712,
      -0.01241220161318779,
      -0.009042644873261452,
      -0.03751318156719208,
      0.037145644426345825,
      -0.1074262335896492,
      -0.0349448099732399,
      0.016930600628256798,
      0.008981586433947086,
      -0.016665199771523476,
      -0.014740446582436562,
      -0.026478538289666176,
      0.05049492046236992,
      0.0353381521999836,
      0.0390971340239048,
      -0.01030033826828003,
      -0.032477281987667084,
      0.010635677725076675,
      0.0390409491956234,
      0.01198933832347393,
      -9.983933705370873e-05,
      -0.010483658872544765,
      0.006717340089380741,
      -0.05134566128253937,
      -0.0435967780649662,
      0.024169446900486946,
      0.03273434564471245,
      -0.0509854257106781,
      -0.004686205182224512,
      0.013728402554988861,
      -0.016543159261345863,
      0.0072401138022542,
      -0.016768941655755043,
      0.013121068477630615,
      -0.060731787234544754,
      -0.010640199296176434,
      0.049945663660764694,
      0.010567008517682552,
      -0.06679467856884003,
      -0.016633082181215286,
      -0.026509348303079605,
      0.007621999830007553,
      -0.05046295002102852,
      0.04824000969529152,
      0.003024689620360732,
      0.03675564378499985,
      0.020793884992599487,
      0.04583776742219925,
      0.13180504739284515,
      0.08680964261293411,
      -0.011662274599075317,
      0.01710602268576622,
      0.03719210997223854,
      -0.04074309021234512,
      0.05071813240647316,
      0.022047828882932663,
      0.008506353944540024,
      -0.04768751934170723,
      -0.016364840790629387,
      -0.023074092343449593,
      0.03069823794066906,
      -0.016640350222587585,
      0.04730333387851715,
      0.03962015360593796,
      -0.006863758899271488,
      -0.010784387588500977,
      0.07331902533769608,
      0.02402465231716633,
      0.030347058549523354,
      0.09873927384614944,
      -0.024231377989053726,
      -0.022417139261960983,
      -0.007462541107088327,
      0.029226500540971756,
      -0.008235489949584007,
      0.0049903253093361855,
      -0.041267286986112595,
      0.006156392861157656,
      0.04971631243824959,
      0.034292273223400116,
      -0.04946211352944374,
      -0.06589318811893463,
      0.008990418165922165,
      -0.008594604209065437,
      -0.05828048288822174,
      0.035316821187734604,
      0.03377515822649002,
      -0.022105662152171135,
      0.04315858706831932,
      -0.0761895626783371,
      -0.008465365506708622,
      0.02189013361930847,
      0.03515220060944557,
      -0.047291334718465805,
      0.024006489664316177,
      -0.011070354841649532,
      -0.0347713939845562,
      0.008758890442550182,
      0.022994665428996086,
      0.07857522368431091,
      -0.029183855280280113,
      0.012666719034314156,
      -0.019938191398978233,
      -0.02627342939376831,
      -0.024185361340641975,
      -0.00399530865252018,
      0.017028100788593292,
      0.01897352933883667,
      0.04748319461941719,
      0.051504164934158325,
      0.03882084786891937,
      0.01678193174302578,
      -0.0039826673455536366,
      0.09248857945203781,
      0.001287310034967959,
      -0.06697440892457962,
      0.04082028195261955,
      -0.05232091620564461,
      -0.0007017735042609274,
      0.0567510724067688,
      0.011907268315553665,
      -0.019866101443767548,
      0.02811715006828308,
      0.06114768236875534,
      -0.02411911077797413,
      -0.005761114414781332,
      -0.06611396372318268,
      -0.05767300724983215,
      0.05899050459265709,
      -0.017788920551538467,
      -0.010905967094004154,
      0.0794328972697258,
      -0.017186513170599937,
      0.12013307213783264,
      0.042016129940748215,
      -0.021012520417571068,
      0.039024218916893005,
      0.01741648092865944,
      -0.015367218293249607,
      0.0038536780048161745,
      0.011805744841694832,
      0.025040602311491966,
      -0.029452558606863022,
      0.06240459159016609,
      0.02796691469848156,
      0.040129534900188446,
      -0.09040945768356323,
      0.008317106403410435,
      -0.05133020877838135,
      0.009204896166920662,
      -0.015588914975523949,
      -0.02650577574968338,
      -0.10946737229824066,
      0.011377858929336071,
      0.09852257370948792,
      0.03946368023753166,
      0.03897437825798988,
      0.03886223956942558,
      0.00980920810252428,
      0.0502079576253891,
      0.09518732875585556,
      0.012231508269906044,
      0.024935085326433182,
      0.09587937593460083,
      -0.03656962513923645,
      0.06002911180257797,
      0.013444606214761734,
      -0.06484686583280563,
      -0.0655633956193924,
      -0.04690355062484741,
      0.04975426197052002,
      -0.02359718829393387,
      0.0811958983540535,
      -0.07042934000492096,
      -0.03455795347690582,
      -0.033610548824071884,
      -0.01579739898443222,
      0.005315564572811127,
      -0.001591143780387938,
      -0.007712761405855417,
      0.01895092986524105,
      -0.01475970447063446,
      -0.011737857013940811,
      -0.009685186669230461,
      0.02102162130177021,
      0.04241710528731346,
      -0.026050563901662827,
      -0.10055001825094223,
      -0.06492511928081512,
      0.04341019690036774,
      -0.068701833486557,
      -0.030931223183870316,
      -0.003841691417619586,
      -0.056645262986421585,
      -0.03325093165040016,
      -0.0753343403339386,
      -0.07633332908153534,
      -0.08623112738132477,
      -0.005798757541924715,
      0.04205732420086861,
      0.010386480018496513,
      -0.010777955874800682,
      0.051486629992723465,
      -0.031907811760902405,
      -0.029159504920244217,
      -0.031191090121865273,
      0.037721794098615646,
      0.04868125170469284,
      -0.028502030298113823,
      -0.032088156789541245,
      0.07231047749519348,
      0.007704647723585367,
      0.07675593346357346,
      -0.03965594246983528,
      0.028558172285556793,
      0.0018647171091288328,
      -0.022552888840436935,
      -0.07676510512828827,
      -0.10840118676424026,
      0.045860905200242996,
      -0.05306636169552803,
      0.0813281461596489,
      -0.034054066985845566,
      -0.06638055294752121,
      -0.023698169738054276,
      -0.016434671357274055,
      0.07964327186346054,
      -0.02772829681634903,
      -0.0019905706867575645,
      0.005569769535213709,
      0.0025447101797908545,
      0.038854651153087616,
      -0.008564312942326069,
      -0.01995016634464264,
      0.015189590863883495,
      0.045505087822675705,
      0.01657693088054657,
      -0.044480256736278534,
      0.016059990972280502,
      -0.023591095581650734,
      0.014604911208152771,
      0.06121458858251572,
      0.09563829004764557,
      -0.03556748479604721,
      0.012920818291604519,
      0.06621256470680237,
      0.0011980913113802671,
      0.037032365798950195,
      -0.05075721815228462,
      -0.006285902578383684,
      -0.03391753137111664,
      0.004367399495095015,
      -0.0645420104265213,
      0.05344771221280098,
      0.08079642802476883,
      0.010674599558115005,
      0.007920262403786182,
      -0.03396425396203995,
      0.025219563394784927,
      -0.02636393904685974,
      0.038787975907325745,
      -0.06281658262014389,
      0.014416519552469254,
      -0.03259952738881111,
      0.04306786507368088,
      0.06140679121017456,
      0.07900712639093399,
      0.03837478533387184,
      -0.016397887840867043,
      0.05517512559890747,
      -0.03170393034815788,
      -0.07422415912151337,
      0.002553418045863509,
      -0.014499041251838207,
      -0.0566706620156765,
      0.03291095793247223,
      0.005738135892897844,
      0.053272489458322525,
      0.008787582628428936,
      -0.04778565838932991,
      -0.10714501142501831,
      0.0229008961468935,
      -0.038217976689338684,
      0.03591523692011833,
      -0.007407160475850105,
      -0.020752225071191788,
      -0.0034022005274891853,
      0.04398089274764061,
      -0.021552907302975655,
      0.019157404080033302,
      0.09114673733711243,
      0.03299783170223236,
      -0.03329891338944435,
      0.03594140335917473,
      -0.0016636381624266505,
      0.02952311560511589,
      -0.06665784120559692,
      -0.05391037464141846,
      -0.013739452697336674,
      -0.0008283749339170754,
      -0.05358392000198364,
      0.022970382124185562,
      0.007665964774787426,
      -0.020862577483057976,
      0.0810982957482338,
      -0.02818218246102333,
      -0.05910833179950714,
      0.08196479082107544,
      0.11787812411785126,
      0.059812627732753754,
      0.0198806244879961,
      0.05804077163338661,
      0.04076351225376129,
      -0.020438456907868385,
      -0.011414031498134136,
      -0.00820116139948368,
      0.0042556035332381725,
      -0.014907830394804478,
      0.020267244428396225,
      0.024452239274978638,
      0.017466265708208084,
      0.07654129713773727,
      -0.01171904243528843,
      -0.007508198730647564,
      -0.046989310532808304,
      0.05383631959557533,
      -0.008993420749902725,
      0.06322213262319565,
      -0.0322580561041832,
      0.009065032005310059,
      0.07624753564596176,
      -0.0606347993016243,
      4.136968345846981e-05,
      0.0880175530910492,
      0.0006762684206478298,
      0.008665671572089195,
      -0.05600767210125923,
      0.005541459657251835,
      0.005663173273205757,
      0.05461595579981804,
      -0.024875761941075325,
      -0.024370508268475533,
      -0.01329548005014658,
      -0.010844478383660316,
      -0.070463165640831,
      -0.025559239089488983,
      0.020640037953853607,
      -0.014906019903719425,
      -0.08208750933408737,
      -0.024905702099204063,
      -0.03216411545872688,
      -0.08074747771024704,
      0.005772692151367664,
      0.009200362488627434,
      -0.0254166591912508,
      0.10573157668113708,
      -0.02551495097577572,
      -0.015467050485312939,
      0.050937701016664505,
      0.02841303125023842,
      0.007118164096027613,
      0.006132933776825666,
      0.018564624711871147,
      -0.047316230833530426,
      -0.004292231984436512,
      -0.010505040176212788,
      0.09795761108398438,
      -0.017537586390972137,
      0.03139606490731239,
      -0.01529688946902752,
      0.020964283496141434,
      -0.005912959575653076,
      0.08355315774679184,
      0.05835690721869469,
      -0.03440301865339279,
      0.08402103930711746,
      -0.024183183908462524,
      -0.045293476432561874,
      0.023819930851459503,
      -0.05317583680152893,
      -0.041224852204322815,
      3.729552554432303e-05,
      0.012854172848165035,
      0.05140869691967964,
      0.036372147500514984,
      -0.05333178490400314,
      -0.017321830615401268,
      0.010022593662142754,
      0.03226201608777046,
      -0.018605003133416176
    ],
    [
      -0.02153359353542328,
      0.011095562018454075,
      0.004655115772038698,
      -0.013264396227896214,
      0.014491278678178787,
      -0.03508637472987175,
      0.02830178290605545,
      -0.011613929644227028,
      -0.007825961336493492,
      -0.11903594434261322,
      -0.03652963787317276,
      0.013261747546494007,
      -0.0165178794413805,
      -0.03600470721721649,
      0.040569379925727844,
      -0.012975719757378101,
      -0.011920785531401634,
      0.15160968899726868,
      0.0772472620010376,
      -0.0650385320186615,
      0.01688922382891178,
      -0.016046645119786263,
      0.04605621472001076,
      -0.02972300909459591,
      0.0685376301407814,
      0.02317821979522705,
      -0.008113076910376549,
      -0.005737682338804007,
      -0.024564726278185844,
      -0.09793262928724289,
      0.0347764827311039,
      0.03259959816932678,
      -0.10814858227968216,
      -0.009928126819431782,
      0.002884268993511796,
      -0.027884643524885178,
      -0.07393843680620193,
      0.07162556797266006,
      -0.012380734086036682,
      -0.048915013670921326,
      -0.019609985873103142,
      -0.04554951563477516,
      0.007914688438177109,
      -0.06487582623958588,
      0.032442327588796616,
      0.03664859011769295,
      0.03583923354744911,
      -0.008198121562600136,
      0.007777878548949957,
      0.04392389953136444,
      0.01843332313001156,
      0.051039308309555054,
      -0.05462781339883804,
      -0.03528914600610733,
      -0.010132069699466228,
      0.00900947954505682,
      -0.07062216103076935,
      -0.049189917743206024,
      -0.019358281046152115,
      0.04795126989483833,
      -0.06257233768701553,
      0.03283444419503212,
      -0.014398516155779362,
      0.0069921002723276615,
      -0.0014263911871239543,
      0.03348918259143829,
      0.011211331002414227,
      -0.05543474480509758,
      -0.06452728807926178,
      -0.012338639236986637,
      -0.0099101597443223,
      -0.04587931931018829,
      0.03596639260649681,
      0.05391127988696098,
      -0.0076576294377446175,
      0.0693211480975151,
      -0.08978284150362015,
      0.033903609961271286,
      0.011836474761366844,
      -0.01045450009405613,
      -0.011219835840165615,
      -0.012583048082888126,
      0.041057039052248,
      -0.042874448001384735,
      0.03318150341510773,
      -0.08931975066661835,
      0.024779003113508224,
      0.03946585953235626,
      0.04011933133006096,
      -0.046861015260219574,
      -0.017059126868844032,
      0.026806268841028214,
      0.04931449517607689,
      0.018598442897200584,
      -0.020777257159352303,
      -0.0579851008951664,
      -0.01678580790758133,
      0.01755325123667717,
      -0.014284666627645493,
      -0.03389931842684746,
      0.01566704362630844,
      0.04198872670531273,
      0.004027432296425104,
      0.06372212618589401,
      0.034850019961595535,
      -0.007469717413187027,
      0.001084884162992239,
      0.01476504746824503,
      0.0010397769510746002,
      0.01725361868739128,
      0.13222159445285797,
      0.020352646708488464,
      -0.021553749218583107,
      -0.057060666382312775,
      0.0638449564576149,
      -0.050896208733320236,
      0.05321012809872627,
      0.012037372216582298,
      -0.0653824433684349,
      -0.029390603303909302,
      0.02825956419110298,
      -0.07486391067504883,
      0.013585780747234821,
      -0.01834883727133274,
      -0.017236841842532158,
      0.03033987432718277,
      -0.05242649093270302,
      -0.07316092401742935,
      0.015933461487293243,
      0.0823732540011406,
      -0.04518066719174385,
      0.09186141192913055,
      -0.029299136251211166,
      -0.00034485661308281124,
      -0.026188988238573074,
      -0.04052206501364708,
      -0.012708179652690887,
      -0.08910176903009415,
      0.017448538914322853,
      0.032259002327919006,
      0.017994634807109833,
      -0.061469532549381256,
      -0.024011248722672462,
      -0.06009164825081825,
      0.06666864454746246,
      -0.04516269266605377,
      -0.015719739720225334,
      0.02017231099307537,
      -0.015814004465937614,
      0.07863101363182068,
      -0.01766277849674225,
      0.0006906951894052327,
      -0.011738589964807034,
      0.10754899680614471,
      0.03511796146631241,
      -0.015788601711392403,
      -0.01514291763305664,
      0.05288716033101082,
      0.032963674515485764,
      0.014918692409992218,
      -0.005980867426842451,
      0.0908774584531784,
      0.009826810099184513,
      0.02511652000248432,
      -0.02948748506605625,
      0.014238926582038403,
      0.025918947532773018,
      -0.05970456823706627,
      0.01671316847205162,
      -0.019716231152415276,
      -0.02875685505568981,
      0.012946628965437412,
      0.028309524059295654,
      -0.02649400383234024,
      0.00422620540484786,
      -0.01983923278748989,
      0.06491687148809433,
      0.09821532666683197,
      0.01373016182333231,
      -0.06526771932840347,
      -0.011121474206447601,
      -0.07641566544771194,
      0.003897865070030093,
      0.04214305803179741,
      -0.046173032373189926,
      0.10093193501234055,
      -0.07241801917552948,
      -0.04967382177710533,
      -0.017299143597483635,
      0.010088524781167507,
      -0.020593928173184395,
      -0.003938973415642977,
      -0.02152767777442932,
      0.034658484160900116,
      -0.028686469420790672,
      0.025674205273389816,
      0.04236695542931557,
      0.037339624017477036,
      -0.011086739599704742,
      -0.020127618685364723,
      -0.05164032801985741,
      -0.050257548689842224,
      -0.035393547266721725,
      0.011745218187570572,
      0.05161088705062866,
      -0.004954671487212181,
      0.02807932160794735,
      0.05408792570233345,
      0.03231746330857277,
      -0.03473455831408501,
      -0.07998612523078918,
      -0.030873769894242287,
      -0.012129652313888073,
      0.03685957193374634,
      0.05495566502213478,
      -0.01116159651428461,
      0.017256343737244606,
      0.03742291033267975,
      0.025951342657208443,
      0.12817470729351044,
      0.022974612191319466,
      -0.0639442503452301,
      -0.046435993164777756,
      -0.11372474581003189,
      -0.06987373530864716,
      0.020099224522709846,
      0.04247434809803963,
      0.09619851410388947,
      -0.02498396672308445,
      0.03695898503065109,
      0.013776677660644054,
      -0.06020712852478027,
      0.10642489790916443,
      0.026443390175700188,
      0.02142440155148506,
      -0.04354828596115112,
      -0.06173882260918617,
      -0.03699403628706932,
      0.009738948196172714,
      0.08442343771457672,
      0.03329959884285927,
      0.11135360598564148,
      0.0033443840220570564,
      -0.01192453596740961,
      0.0004660304693970829,
      0.05126883462071419,
      0.007356006186455488,
      0.045060232281684875,
      -0.016729602590203285,
      0.03825736790895462,
      0.009664400480687618,
      0.07000350952148438,
      -0.05674766004085541,
      -0.006778739858418703,
      0.05633799731731415,
      -0.010494883172214031,
      0.007804682943969965,
      0.007257679011672735,
      -0.01614205539226532,
      -0.05512072145938873,
      0.14924126863479614,
      -0.043424058705568314,
      0.05483287200331688,
      0.034146711230278015,
      0.06989026814699173,
      -0.009282686747610569,
      -0.034940872341394424,
      0.009018369019031525,
      0.04002688080072403,
      0.02049998566508293,
      0.07916685938835144,
      -0.07604337483644485,
      0.015549448318779469,
      0.0040558110922575,
      -0.02796737663447857,
      0.02518446370959282,
      0.012474516406655312,
      0.05813475698232651,
      0.020722810178995132,
      -0.011393197812139988,
      0.09260226786136627,
      -0.00034774455707520247,
      0.01692654751241207,
      0.061009347438812256,
      0.03454416245222092,
      -0.008774866349995136,
      -0.002183538395911455,
      0.018766991794109344,
      0.08357018977403641,
      -0.015855316072702408,
      0.012527303770184517,
      -0.0157109834253788,
      -0.07504827529191971,
      -0.0007304958417080343,
      -0.1193573996424675,
      0.07707938551902771,
      -0.05425404757261276,
      0.03360556811094284,
      -0.057823095470666885,
      0.09058567136526108,
      0.01382025983184576,
      0.00906990747898817,
      0.011518019251525402,
      -0.0036262432113289833,
      0.03164871782064438,
      -0.006661776918917894,
      -0.02538161538541317,
      -0.028362805023789406,
      0.12704512476921082,
      -0.006753926631063223,
      -0.022364288568496704,
      0.05903950706124306,
      0.09194943308830261,
      0.04616212472319603,
      -0.02934163436293602,
      -0.049575310200452805,
      0.04721854254603386,
      0.011455400846898556,
      0.009651127271354198,
      -0.06313252449035645,
      0.006558126769959927,
      -0.030594833195209503,
      0.011010918766260147,
      0.03944005072116852,
      -0.005637167952954769,
      -8.847207936923951e-05,
      -0.08548208326101303,
      -0.04289548471570015,
      0.01593787595629692,
      0.022854434326291084,
      -0.046811506152153015,
      0.04137998819351196,
      -0.01624424383044243,
      -0.008627460338175297,
      0.06031185761094093,
      0.0648513212800026,
      -0.05234226956963539,
      -0.021461162716150284,
      0.020205412060022354,
      0.02462484873831272,
      0.006465116981416941,
      0.0782465934753418,
      0.03993229940533638,
      -0.02460452914237976,
      0.11471588164567947,
      -0.012234619818627834,
      0.0336088202893734,
      0.005017420276999474,
      0.05135200917720795,
      0.09244555979967117,
      0.08109626173973083,
      0.032597675919532776,
      -0.04069267958402634,
      0.017584998160600662,
      0.02230670675635338,
      -0.004989816341549158,
      0.0531134195625782,
      -0.012502624653279781,
      -0.009005308151245117,
      0.10382545739412308,
      0.09283630549907684,
      -0.021973583847284317,
      -0.01633080281317234,
      -0.07272903621196747,
      0.004275061655789614,
      -0.08190805464982986,
      -0.03616061061620712,
      0.000447002676082775,
      0.0301970262080431,
      -0.06067381426692009,
      -0.004781544674187899,
      -0.06567510217428207,
      -0.06837285310029984,
      0.05137718841433525,
      0.05329511687159538,
      0.04349241778254509,
      -0.003193843411281705,
      0.0542292594909668,
      -0.05218987539410591,
      -0.0085706552490592,
      -0.03683514520525932,
      -0.02629915066063404,
      -0.025253331288695335,
      -0.04060998931527138,
      -0.02825886383652687,
      0.00584869971498847,
      -0.01224795263260603,
      -0.0456714928150177,
      0.05613080412149429,
      0.09341876953840256,
      -0.0492902435362339,
      0.02168765664100647,
      0.029386300593614578,
      0.043859146535396576,
      -0.04174337536096573,
      -0.024882029742002487,
      -0.025963211432099342,
      0.02241872437298298,
      0.008039606735110283,
      -0.06354031711816788,
      -0.05234389752149582,
      -0.04201967641711235,
      0.06712041050195694,
      -0.04189066216349602,
      -0.054921705275774,
      -0.042409107089042664,
      0.059395499527454376,
      0.006022087298333645,
      0.019067751243710518,
      -0.05451498180627823,
      0.008180798031389713,
      0.061855703592300415,
      0.01520627737045288,
      0.010077734477818012,
      -0.014551385305821896,
      0.03851111978292465,
      0.041038110852241516,
      0.015777554363012314,
      0.05670982971787453,
      -0.026563487946987152,
      -0.0039690216071903706,
      -0.017658259719610214,
      0.019356265664100647,
      0.03071936033666134,
      0.03585021570324898,
      -0.0551532506942749,
      -0.0021184629295021296,
      0.021199112758040428,
      -0.004376694560050964,
      -0.00723433680832386,
      -0.019030632451176643,
      4.5484073780244216e-05,
      -0.013121126219630241,
      0.03789130598306656,
      -0.02404739521443844,
      0.06534974277019501,
      -0.005377358756959438,
      -0.027449091896414757,
      0.03679138794541359,
      -0.051844097673892975,
      -0.012735692784190178,
      0.0008173634414561093,
      0.05933345854282379,
      -0.05642738565802574,
      -3.287204526714049e-05,
      -0.0317799337208271,
      -0.05989455804228783,
      -0.0038535399362444878,
      0.01541129034012556,
      -0.026066889986395836,
      -0.028768276795744896,
      0.014557612128555775,
      -0.08553919941186905,
      -0.012284663505852222,
      -0.0663137286901474,
      -0.019857237115502357,
      0.03242821246385574,
      0.018223896622657776,
      -0.012324197217822075,
      -0.033572372049093246,
      0.005744474474340677,
      -0.05979204177856445,
      -0.04656648635864258,
      0.04468570277094841,
      0.06153522804379463,
      0.07044999301433563,
      -0.0311440322548151,
      0.004040339961647987,
      0.029008733108639717,
      -0.04155701771378517,
      0.02520996890962124,
      -0.09119903296232224,
      -0.02606922946870327,
      0.004919515922665596,
      -0.09685277193784714,
      0.032236915081739426,
      0.035724837332963943,
      0.050928544253110886,
      0.033552385866642,
      0.05879176780581474,
      -0.010005883872509003,
      0.05135641247034073,
      0.026623738929629326,
      -0.0006629226845689118,
      0.04458356648683548,
      -0.041839201003313065,
      -0.0021930395159870386,
      0.043990608304739,
      -0.03799397125840187,
      -0.019172068685293198,
      0.03704645484685898,
      -0.04224614426493645,
      0.05455503612756729,
      0.017305802553892136,
      0.07612381130456924,
      -0.11441798508167267,
      0.022887418046593666,
      -0.028835581615567207,
      -0.06236966699361801,
      0.021694911643862724,
      0.009945198893547058,
      -0.07746291905641556,
      0.11370754987001419
    ],
    [
      0.013933409005403519,
      0.04164857417345047,
      0.008715573698282242,
      0.024075547233223915,
      -0.08647293597459793,
      -0.010335703380405903,
      0.01471389178186655,
      -0.03749644383788109,
      -0.033646360039711,
      -0.01599625125527382,
      0.03258538618683815,
      -0.04543112963438034,
      0.02365260012447834,
      -0.05347079783678055,
      -0.02395685948431492,
      -0.02401815913617611,
      0.06386154890060425,
      -0.016968710348010063,
      -0.02654215134680271,
      0.01322130300104618,
      0.060448482632637024,
      0.0077686370350420475,
      -0.006752742454409599,
      -0.024031635373830795,
      0.0026768692769110203,
      0.0028437713626772165,
      -0.007787100970745087,
      -0.011510050855576992,
      0.017483824864029884,
      -0.018465429544448853,
      -0.03250158578157425,
      -0.04280177503824234,
      -0.05542474985122681,
      -0.07985246926546097,
      0.09677022695541382,
      0.05381322652101517,
      -0.02176683023571968,
      -0.04002511873841286,
      0.032625965774059296,
      -0.024251572787761688,
      -0.03146365284919739,
      -0.0077385855838656425,
      0.04703998193144798,
      -0.008249042555689812,
      0.027912886813282967,
      0.07554647326469421,
      0.004023678135126829,
      -0.00457820575684309,
      0.021635813638567924,
      0.0025683585554361343,
      -0.01733807660639286,
      0.003863296238705516,
      -0.021151332184672356,
      -0.022358784452080727,
      0.004909402225166559,
      -0.05653538554906845,
      -0.06134690344333649,
      -0.01601848192512989,
      0.03623150661587715,
      0.006684772204607725,
      -0.050004784017801285,
      0.08991626650094986,
      -0.05469919741153717,
      -0.03691595792770386,
      0.0023438495118170977,
      0.032330919057130814,
      0.0032178759574890137,
      -0.02319767326116562,
      -0.04390564560890198,
      0.029790367931127548,
      0.00953263882547617,
      -0.037788402289152145,
      0.08651908487081528,
      -0.05360663682222366,
      -0.047661010175943375,
      -0.048397623002529144,
      -0.009058268740773201,
      -0.03868046775460243,
      -0.04760508984327316,
      -0.08164061605930328,
      -0.003103775903582573,
      0.03383796289563179,
      0.09731104224920273,
      -0.09563761949539185,
      -0.0009603443904779851,
      0.05707814171910286,
      -0.04790323227643967,
      0.017246808856725693,
      0.022745924070477486,
      0.04887833818793297,
      0.06625586748123169,
      -0.004553027451038361,
      -0.059830449521541595,
      0.10610941052436829,
      -0.0701376423239708,
      -0.09708981961011887,
      0.02333393134176731,
      -0.02326405607163906,
      0.010474220849573612,
      -0.013564364984631538,
      0.03393165394663811,
      0.019712017849087715,
      0.06804390996694565,
      -0.026464512571692467,
      -0.030174223706126213,
      -0.03336241468787193,
      -0.021640220656991005,
      0.06932766735553741,
      -0.0040688710287213326,
      -0.0014404953690245748,
      0.01957418955862522,
      -0.0032950188033282757,
      -0.018393054604530334,
      0.01340736448764801,
      -0.060534391552209854,
      0.0657469630241394,
      -0.02584017999470234,
      0.07821634411811829,
      -0.018467646092176437,
      0.05674294754862785,
      -0.03207671642303467,
      0.07409842312335968,
      0.012761552818119526,
      -0.03019152395427227,
      -0.010238819755613804,
      0.012563209980726242,
      -0.006553797982633114,
      -0.06478983163833618,
      0.08445698022842407,
      -0.06116862967610359,
      0.04078330099582672,
      0.07013042271137238,
      0.020842861384153366,
      -0.007646375335752964,
      0.032943855971097946,
      0.018711300566792488,
      -0.01186148077249527,
      0.06617805361747742,
      0.10279189050197601,
      0.003983336966484785,
      -0.028698788955807686,
      0.009599819779396057,
      0.034483686089515686,
      -0.0819433405995369,
      -0.02774607390165329,
      -0.03336815908551216,
      0.04566168040037155,
      0.08726579695940018,
      0.008083265274763107,
      -0.03432691842317581,
      0.07636864483356476,
      -0.04240525886416435,
      -0.07235489785671234,
      -0.04672880470752716,
      -0.07241222262382507,
      0.022119561210274696,
      -0.015499120578169823,
      -0.04184700548648834,
      -0.002862175926566124,
      0.07166743278503418,
      -0.004374986980110407,
      0.01986164227128029,
      -0.0070465621538460255,
      0.01879471354186535,
      -0.048326484858989716,
      -0.011885137297213078,
      -0.09347975999116898,
      -0.06476511806249619,
      -0.006452903151512146,
      -0.09120248258113861,
      -0.03590283915400505,
      0.1205548644065857,
      -0.01508265919983387,
      -0.011492177844047546,
      -0.04335043951869011,
      -0.013485449366271496,
      -0.16042937338352203,
      -0.0898125097155571,
      -0.07576850801706314,
      -0.015273738652467728,
      0.04179181903600693,
      -0.05197747424244881,
      -0.003775085788220167,
      -0.004954551346600056,
      0.027372512966394424,
      0.03921779617667198,
      0.0007618695381097496,
      0.0030596836004406214,
      0.04055924341082573,
      0.01846739836037159,
      -0.021451354026794434,
      -0.01779162511229515,
      -0.06608717888593674,
      -0.009973342530429363,
      0.11049070209264755,
      0.03250151872634888,
      -0.029167238622903824,
      -0.003914391156286001,
      -0.025100812315940857,
      0.007437217980623245,
      -0.0025753313675522804,
      -0.0458451546728611,
      0.056127969175577164,
      -0.0051971194334328175,
      -0.0030743260867893696,
      -0.07415027916431427,
      0.03971533477306366,
      -0.04926101490855217,
      -0.004152624402195215,
      0.023377027362585068,
      -0.15228861570358276,
      0.040509484708309174,
      -0.011442764662206173,
      0.014932614751160145,
      -0.004413133021444082,
      0.10201212763786316,
      -0.012350368313491344,
      -0.06263569742441177,
      0.005377671215683222,
      -0.009370220825076103,
      0.000984274665825069,
      0.05467623472213745,
      0.0425289086997509,
      -0.031605906784534454,
      0.013150626793503761,
      0.00797839742153883,
      -0.026311248540878296,
      -0.004342723172158003,
      0.021045146510004997,
      -0.06717955321073532,
      -0.006806490942835808,
      0.04135894402861595,
      0.041183918714523315,
      -0.022943539544939995,
      0.04231206327676773,
      -0.031923115253448486,
      -0.10359417647123337,
      0.012823838740587234,
      0.027523303404450417,
      -0.014524971134960651,
      -0.021079448983073235,
      0.1335260272026062,
      0.016874810680747032,
      0.01536286249756813,
      0.0448697954416275,
      0.02895040437579155,
      0.10407951474189758,
      0.00368020823225379,
      0.010395866818726063,
      0.013940881937742233,
      -0.031237300485372543,
      -0.050005171447992325,
      0.011163026094436646,
      0.05331188440322876,
      0.05246521160006523,
      0.011363595724105835,
      0.017262209206819534,
      0.006602264940738678,
      -0.11362498998641968,
      0.007240068633109331,
      0.017849411815404892,
      0.022437775507569313,
      0.013187960721552372,
      0.036089204251766205,
      -0.02649806998670101,
      0.03583798184990883,
      0.10457035899162292,
      -0.08416584134101868,
      -0.010231196880340576,
      0.07751435786485672,
      -0.07774411886930466,
      0.014017704874277115,
      -0.02663814090192318,
      0.07201457768678665,
      -0.005900528281927109,
      0.029063913971185684,
      0.006646538618952036,
      -0.00817626342177391,
      0.08313675969839096,
      -0.02053774520754814,
      -0.0837533250451088,
      -0.03387734666466713,
      0.06613057851791382,
      0.00047325046034529805,
      -0.029015129432082176,
      -0.08210444450378418,
      -0.00843912735581398,
      -0.05081925168633461,
      0.06595773249864578,
      -0.0020240123849362135,
      -0.006118034478276968,
      -0.08754532039165497,
      0.027664052322506905,
      -0.04528909549117088,
      -0.09060904383659363,
      -0.006067005451768637,
      -0.08075471967458725,
      -0.016334880143404007,
      0.01145491749048233,
      0.03564758971333504,
      -0.037640530616045,
      -0.008074983023107052,
      0.040362145751714706,
      0.0010178773663938046,
      0.018084902316331863,
      0.00037904141936451197,
      0.03905193880200386,
      -0.019463861361145973,
      0.008822300471365452,
      0.0011692624539136887,
      0.00720948027446866,
      0.014417356811463833,
      0.021071206778287888,
      0.04496588557958603,
      -0.018643800169229507,
      -0.014089303091168404,
      -0.0017828406998887658,
      -0.05412297695875168,
      -0.025993548333644867,
      -0.05924000218510628,
      -0.039432551711797714,
      -0.019179439172148705,
      0.05130089446902275,
      -0.059258751571178436,
      0.061146896332502365,
      0.06579607725143433,
      -0.021712923422455788,
      0.010371356271207333,
      0.017648350447416306,
      -0.01829678565263748,
      0.049042295664548874,
      -0.04276634007692337,
      -0.04340185970067978,
      -0.007566438522189856,
      0.029021115973591805,
      -0.013222569599747658,
      -0.04832427203655243,
      0.05109409987926483,
      0.012479557655751705,
      0.07955783605575562,
      0.023408791050314903,
      0.04368778318166733,
      -0.036379605531692505,
      -0.022050077095627785,
      0.02568730339407921,
      -0.007274095434695482,
      0.005352858453989029,
      0.022150149568915367,
      -0.008529105223715305,
      0.04079446196556091,
      0.004218479618430138,
      -0.015042072162032127,
      -0.05500366538763046,
      -0.01603175885975361,
      -0.03780989348888397,
      -0.018186364322900772,
      0.009761717170476913,
      0.015494548715651035,
      -0.015223589725792408,
      -0.03881536424160004,
      -0.01890227571129799,
      0.011306680738925934,
      0.04856007173657417,
      -0.0478006936609745,
      0.00528474198654294,
      -0.057466257363557816,
      -0.008928959257900715,
      0.06565108150243759,
      0.019102947786450386,
      0.006292670965194702,
      -0.008883467875421047,
      -0.005153597332537174,
      -0.0019308199407532811,
      0.029013382270932198,
      -0.013722515664994717,
      -0.07875749468803406,
      0.005013277288526297,
      -0.08071263879537582,
      -0.055901508778333664,
      -0.05689217150211334,
      -0.04288065433502197,
      -0.033136460930109024,
      -0.08163611590862274,
      0.06999949365854263,
      0.041124384850263596,
      0.01376393809914589,
      -0.006661904975771904,
      -0.03181568905711174,
      -0.0014278595335781574,
      -0.027591312304139137,
      0.007101277820765972,
      0.03273744881153107,
      0.04099249467253685,
      -0.0433167964220047,
      -0.05174991860985756,
      0.015083112753927708,
      -0.014900218695402145,
      -0.0475277416408062,
      -0.040412914007902145,
      -0.009512834250926971,
      -0.03136948123574257,
      -0.015390793792903423,
      -0.04371630772948265,
      -0.07804729789495468,
      -0.03986776992678642,
      -0.040541693568229675,
      0.020735327154397964,
      0.01667224057018757,
      -0.021780310198664665,
      0.0024807637091726065,
      -0.06014906242489815,
      0.04971973970532417,
      0.04883787781000137,
      -0.03688410669565201,
      -0.02473757416009903,
      -0.03328689560294151,
      -0.003846436971798539,
      -0.003935504239052534,
      -0.07828696817159653,
      0.014141666702926159,
      -0.018641535192728043,
      0.005746998358517885,
      -0.02551097422838211,
      -0.015436220914125443,
      -0.009346161037683487,
      -0.022521259263157845,
      0.0032886837143450975,
      0.06941422075033188,
      -0.012889984995126724,
      -0.023564066737890244,
      0.07981082797050476,
      0.01715722121298313,
      0.03066239319741726,
      0.04937630519270897,
      0.04996451362967491,
      -0.030622221529483795,
      -0.07694071531295776,
      -0.03767580911517143,
      0.03826781362295151,
      0.0010007640812546015,
      0.002272221492603421,
      -0.018090488389134407,
      0.010428481735289097,
      -0.0006949454545974731,
      -0.013656001538038254,
      0.006943035870790482,
      -0.06831196695566177,
      0.05303511768579483,
      -0.0006660877843387425,
      -0.01956060342490673,
      -0.027646785601973534,
      -0.05297096446156502,
      -0.0030337627977132797,
      -0.02978307381272316,
      0.03853719308972359,
      0.07434163987636566,
      -0.003468441078439355,
      0.033337149769067764,
      0.007315303664654493,
      0.017329435795545578,
      0.04753001406788826,
      -0.01052307989448309,
      -0.04320577159523964,
      0.009231477044522762,
      0.007838577963411808,
      -0.004790094215422869,
      0.015247785486280918,
      0.00686129555106163,
      -0.01665014959871769,
      -0.0021147020161151886,
      -0.039241667836904526,
      0.02298632450401783,
      0.011157366447150707,
      -0.0015681046061217785,
      -0.03900126367807388,
      -0.0008466013241559267,
      -0.01942862756550312,
      0.02900540456175804,
      0.07343317568302155,
      0.029005296528339386,
      0.018433088436722755,
      0.05354439467191696,
      -0.023152146488428116,
      0.06001116707921028,
      -0.03164137899875641,
      0.040497489273548126,
      0.016635635867714882,
      0.08111539483070374,
      0.016428381204605103,
      -0.05179214105010033,
      0.055592022836208344,
      0.008791922591626644,
      -0.032874517142772675,
      -0.04814521595835686,
      -0.04124545678496361,
      -0.08710519969463348,
      -0.015739819034934044,
      -0.002390012377873063,
      -0.02264830470085144,
      0.009562572464346886,
      -0.01736096292734146,
      -0.04359457641839981,
      -0.10597927123308182
    ],
    [
      0.086497001349926,
      -0.03333468362689018,
      0.006822859402745962,
      -0.045480161905288696,
      -0.0858302190899849,
      0.035665906965732574,
      -0.0422394797205925,
      -0.016554582864046097,
      -0.04318147897720337,
      -0.0381164513528347,
      -0.012204424478113651,
      -0.04297355189919472,
      -0.03873372822999954,
      0.01898304931819439,
      0.013562413863837719,
      0.045897226780653,
      0.03090822510421276,
      0.03259621560573578,
      0.022761227563023567,
      -0.025464700534939766,
      0.028461560606956482,
      0.05275397375226021,
      0.01110844500362873,
      0.06191375479102135,
      0.05981976166367531,
      -0.06559443473815918,
      -0.03164178505539894,
      -0.025416674092411995,
      -0.002076753182336688,
      0.0009425610187463462,
      0.03457602486014366,
      -0.022901063784956932,
      0.03744106739759445,
      -0.030964970588684082,
      -0.03994043543934822,
      -0.0010316306725144386,
      -0.031168585643172264,
      -0.02118964120745659,
      -0.008282546885311604,
      -0.08702008426189423,
      0.0025072034914046526,
      -0.07546143233776093,
      0.08686237782239914,
      -0.02146357111632824,
      0.005206918343901634,
      0.035992581397295,
      -0.07595660537481308,
      -0.03717153146862984,
      0.02021847292780876,
      0.022381756454706192,
      -0.08497670292854309,
      3.1910236430121586e-05,
      -0.056019607931375504,
      -0.01628287136554718,
      0.05675755441188812,
      -0.024247540161013603,
      -0.006817673332989216,
      -0.021250363439321518,
      -0.014742515981197357,
      -0.019640889018774033,
      -0.022968880832195282,
      -0.000306830886984244,
      0.01062242966145277,
      0.06125836819410324,
      0.021584829315543175,
      -0.017925813794136047,
      -0.01102454587817192,
      0.07581087946891785,
      -0.002931173425167799,
      0.059322290122509,
      0.03862643614411354,
      0.051198918372392654,
      -0.04102136194705963,
      -0.05339743569493294,
      0.01958267204463482,
      -0.025029722601175308,
      -0.03904137387871742,
      -0.02066182903945446,
      -0.06300783902406693,
      0.01913035660982132,
      0.04304477199912071,
      -0.020958831533789635,
      0.05396240949630737,
      0.04144861921668053,
      -0.05420353636145592,
      -0.030784761533141136,
      0.10603144764900208,
      0.039577119052410126,
      -0.0013410229003056884,
      0.0038398827891796827,
      0.08885896950960159,
      -0.0030145489145070314,
      -0.061025965958833694,
      -0.03733531013131142,
      0.0799611285328865,
      -0.06064245104789734,
      0.046014465391635895,
      -0.024540849030017853,
      0.0162376556545496,
      0.021267004311084747,
      -0.08846578747034073,
      -0.008597972802817822,
      0.00806211493909359,
      -0.07640506327152252,
      0.03732508793473244,
      0.0030754562467336655,
      0.0019692734349519014,
      0.021170927211642265,
      -0.021043140441179276,
      0.054937779903411865,
      0.059813566505908966,
      -0.04053376615047455,
      0.014361510053277016,
      -0.00708572193980217,
      0.0553877018392086,
      0.02560863085091114,
      -0.030517326667904854,
      -0.03370843455195427,
      0.06121005862951279,
      -0.025328969582915306,
      -0.006177671253681183,
      0.0898301899433136,
      0.03768377751111984,
      -0.09275185316801071,
      0.06803007423877716,
      0.04067489877343178,
      0.05852457508444786,
      -0.03549561649560928,
      -0.09934423118829727,
      0.05914868414402008,
      -0.06290939450263977,
      0.02627386525273323,
      0.07784320414066315,
      -0.04056618735194206,
      0.00635109655559063,
      -0.028787635266780853,
      -0.04983363673090935,
      -0.02024530991911888,
      -0.027141965925693512,
      -0.052852027118206024,
      0.008462149649858475,
      -0.012384179048240185,
      -0.04903555288910866,
      0.04852576181292534,
      0.06063973903656006,
      0.030553782358765602,
      -0.03986408933997154,
      -0.08043105900287628,
      -0.06124652177095413,
      0.04500272870063782,
      0.00045460634282790124,
      -0.03523589298129082,
      -0.03820661082863808,
      -0.05610261857509613,
      -0.012379572726786137,
      -0.085952527821064,
      -0.034750573337078094,
      -0.0369342640042305,
      -0.03272121399641037,
      -0.0014008424477651715,
      0.0021047317422926426,
      -0.06260082870721817,
      -0.009926091879606247,
      0.03266037255525589,
      0.021113459020853043,
      -0.02478405088186264,
      -0.0239810012280941,
      -0.03670741990208626,
      -0.02585609443485737,
      -0.0219198577105999,
      -0.007103584706783295,
      0.018996775150299072,
      0.06170143559575081,
      0.07357560843229294,
      0.014564291574060917,
      -0.007935337722301483,
      -0.09203767031431198,
      -0.05341111868619919,
      -0.041423898190259933,
      0.04118143767118454,
      0.0023524181451648474,
      -0.037881575524806976,
      -0.0729900673031807,
      0.013775145635008812,
      -0.032226692885160446,
      0.017130384221673012,
      0.06870140880346298,
      0.011838751845061779,
      0.002673256676644087,
      0.06416432559490204,
      0.008455495350062847,
      -0.05248004198074341,
      -0.02657128870487213,
      -0.030661744996905327,
      -0.04625888541340828,
      -0.03086823970079422,
      0.015293020755052567,
      -0.002990171080455184,
      0.035035695880651474,
      0.04845958948135376,
      0.00909840501844883,
      -0.012950863689184189,
      0.036056503653526306,
      -0.0013106103288009763,
      0.04479798674583435,
      -0.00380151835270226,
      0.019960688427090645,
      -0.02016867697238922,
      0.03046407178044319,
      0.02549271285533905,
      0.02128305472433567,
      0.059715721756219864,
      0.018053097650408745,
      -0.06288281083106995,
      -0.027186211198568344,
      0.0733710303902626,
      0.022043928503990173,
      -0.00947013683617115,
      0.0038971665780991316,
      0.025852521881461143,
      -0.027234511449933052,
      0.0026580996345728636,
      0.045546747744083405,
      0.010610741563141346,
      -0.010715817101299763,
      0.0019414901034906507,
      0.04298342019319534,
      0.03475438430905342,
      0.02139374241232872,
      -0.02574673481285572,
      0.01633615978062153,
      -0.07371412962675095,
      0.06775012612342834,
      -0.026723945513367653,
      0.05250519886612892,
      -0.06925881654024124,
      0.002519427565857768,
      -0.04366372153162956,
      0.04317564517259598,
      -0.05400494858622551,
      -0.015923036262392998,
      -0.03023243136703968,
      0.007706435862928629,
      -0.11792201548814774,
      -0.002302066655829549,
      0.00439833477139473,
      0.027304569259285927,
      -0.03006739728152752,
      0.043769218027591705,
      -0.011383149772882462,
      0.000871660013217479,
      -0.00743640772998333,
      -0.09703584760427475,
      -0.043235111981630325,
      -0.01233607903122902,
      0.028115009889006615,
      -0.01601295918226242,
      -0.017162110656499863,
      0.06694260239601135,
      0.01900121010839939,
      0.030495064333081245,
      0.07904410362243652,
      0.03178853541612625,
      0.0067946468479931355,
      0.053672779351472855,
      -0.06831067055463791,
      -0.010346787050366402,
      0.03463532775640488,
      0.07940442860126495,
      0.031230904161930084,
      -0.01732650212943554,
      0.008124866522848606,
      -0.016140736639499664,
      0.10121139883995056,
      -0.014430508948862553,
      0.05276121571660042,
      -0.03203288093209267,
      -0.02347898669540882,
      0.040137879550457,
      0.02589026466012001,
      -0.03393463417887688,
      0.0009156081359833479,
      0.030319977551698685,
      0.03792523592710495,
      -0.0023545518051832914,
      -0.06181608885526657,
      -0.06851480156183243,
      -0.030950525775551796,
      -0.015766369178891182,
      -0.011098487302660942,
      0.010665258392691612,
      0.03545044735074043,
      -0.002985428785905242,
      -0.017359469085931778,
      0.007392861880362034,
      0.01934676431119442,
      0.03617262467741966,
      0.05970937758684158,
      0.057312436401844025,
      0.09697569161653519,
      -0.020517433062195778,
      0.031055469065904617,
      -0.0034339195117354393,
      -0.0041278149001300335,
      0.04842835292220116,
      0.07194530963897705,
      0.048960305750370026,
      0.00089499686146155,
      0.03642004355788231,
      0.03190121427178383,
      -0.03417409211397171,
      -0.032128579914569855,
      -0.0023770155385136604,
      0.009275523945689201,
      0.035406384617090225,
      -0.012467854656279087,
      -0.004809289705008268,
      -0.05384703725576401,
      -0.035738587379455566,
      -0.01189715787768364,
      0.006185721140354872,
      0.03726213797926903,
      -0.03794962540268898,
      0.015795454382896423,
      -0.015548132359981537,
      0.04996684566140175,
      -0.018183249980211258,
      -0.033373359590768814,
      -0.04657129570841789,
      -0.023624923080205917,
      -0.008019738830626011,
      -0.0038217941764742136,
      0.0566815510392189,
      0.0393584780395031,
      -0.0013474898878484964,
      0.056394681334495544,
      0.038033947348594666,
      0.03383846580982208,
      -0.018545998260378838,
      0.007382083684206009,
      -0.035553812980651855,
      -0.04364490136504173,
      0.06269460916519165,
      -0.022883092984557152,
      -0.06069210171699524,
      -0.0016828596126288176,
      0.038001660257577896,
      -0.03517373651266098,
      -0.017339492216706276,
      0.03214483708143234,
      -0.06070684641599655,
      -0.021417630836367607,
      -0.0690348893404007,
      -0.04238729178905487,
      -0.019770506769418716,
      0.0715692788362503,
      0.006792844273149967,
      -0.016545027494430542,
      0.03069637343287468,
      -0.03007599152624607,
      -0.06255397200584412,
      -0.021363183856010437,
      0.013896433636546135,
      -0.050021007657051086,
      0.05529298260807991,
      0.04552452638745308,
      0.00983054842799902,
      0.042580075562000275,
      0.011089063249528408,
      -0.03981160745024681,
      0.014641393907368183,
      0.017362892627716064,
      0.016694050282239914,
      -0.03522755950689316,
      -0.0429592989385128,
      0.01974150538444519,
      0.006305250804871321,
      -0.07194183766841888,
      0.04954218491911888,
      -0.03141862526535988,
      -0.007943645119667053,
      -0.05142494663596153,
      0.04087495803833008,
      -0.09987003356218338,
      -0.01931118033826351,
      -0.0017043354455381632,
      -0.03675298020243645,
      0.03037290647625923,
      0.009217632934451103,
      0.06319398432970047,
      0.022483093664050102,
      -0.008335547521710396,
      0.02415435202419758,
      0.05331970378756523,
      0.05992040038108826,
      0.07331568747758865,
      0.07364244759082794,
      -0.030532050877809525,
      0.022718343883752823,
      0.018568072468042374,
      0.042238667607307434,
      -0.046840012073516846,
      -0.017165711149573326,
      0.0011273794807493687,
      0.0101418886333704,
      0.002576483180746436,
      -0.058310769498348236,
      0.012796650640666485,
      0.04850700870156288,
      0.002231195103377104,
      0.019074488431215286,
      0.08184797316789627,
      -0.016858244314789772,
      -0.07012850046157837,
      -0.05060617998242378,
      0.03461039438843727,
      -0.048269689083099365,
      0.07422997802495956,
      0.0111971041187644,
      -0.007754010614007711,
      0.013789856806397438,
      -0.059082068502902985,
      -0.04992084950208664,
      -0.025790998712182045,
      0.011464293114840984,
      -0.009543560445308685,
      0.015138094313442707,
      0.013295217417180538,
      0.052952833473682404,
      0.008583055809140205,
      0.07246620208024979,
      0.01674211025238037,
      0.03644159436225891,
      0.010088987648487091,
      0.03358406573534012,
      -0.03017260506749153,
      0.008995825424790382,
      0.002500859322026372,
      -0.02954009361565113,
      0.016030369326472282,
      -0.07758466899394989,
      0.01807245798408985,
      0.040118779987096786,
      0.06298321485519409,
      -0.0972106084227562,
      0.006272774655371904,
      0.08976255357265472,
      0.008843987248837948,
      0.014871489256620407,
      0.08023861050605774,
      -0.02512841671705246,
      0.08316502720117569,
      0.03619806841015816,
      -0.08891420066356659,
      0.031164050102233887,
      0.028037143871188164,
      0.0016073654405772686,
      -0.03599526360630989,
      0.07518228143453598,
      0.07937917113304138,
      0.05563914403319359,
      -0.029643582180142403,
      0.05858448147773743,
      -0.02164592780172825,
      0.012539771385490894,
      0.04065535590052605,
      -0.008530320599675179,
      -0.0003357988898642361,
      0.010208742693066597,
      0.008111865259706974,
      -0.0307739470154047,
      0.06856081634759903,
      -0.033462051302194595,
      -0.03910215198993683,
      0.030665811151266098,
      -0.015460947528481483,
      -0.021116452291607857,
      -0.03994787856936455,
      0.05826183408498764,
      0.08012045919895172,
      -0.05862633138895035,
      0.01195735577493906,
      -0.05071081221103668,
      -0.030117757618427277,
      0.01966552622616291,
      0.07243864983320236,
      0.05904722586274147,
      0.020754991099238396,
      0.03734176605939865,
      0.05349666252732277,
      -0.047265566885471344,
      -0.07814618200063705,
      0.056254398077726364,
      -0.0719836875796318,
      -0.050831008702516556,
      -0.018483366817235947,
      -0.06709597259759903,
      0.024080583825707436,
      0.041765209287405014,
      0.09497584402561188,
      -0.08522214740514755,
      0.04040191322565079,
      -0.03124053031206131
    ],
    [
      -0.016191719099879265,
      0.03818849101662636,
      0.02300877682864666,
      0.03970325365662575,
      -0.005943140015006065,
      0.004320601467043161,
      -0.05957890301942825,
      0.056342512369155884,
      -0.11958366632461548,
      0.07279235869646072,
      0.01346609741449356,
      -0.0017950877081602812,
      0.08490384370088577,
      0.04371139034628868,
      -0.08380268514156342,
      -0.06749565899372101,
      -0.07413192093372345,
      -0.03518971800804138,
      0.03316603973507881,
      0.017635885626077652,
      -0.02887817658483982,
      0.04199429601430893,
      -0.05381248518824577,
      -0.06676558405160904,
      -0.004497850779443979,
      0.049408722668886185,
      0.03725462779402733,
      0.07818575948476791,
      -0.027620309963822365,
      0.0361233614385128,
      -0.015155171044170856,
      0.015282895416021347,
      0.01101920660585165,
      0.03533583879470825,
      0.03173970431089401,
      -0.003742389613762498,
      -0.07912991940975189,
      0.06634903699159622,
      -0.011312734335660934,
      -0.027244314551353455,
      -0.05588545650243759,
      0.0145510109141469,
      -0.006139378063380718,
      -0.07081228494644165,
      -0.07752595096826553,
      0.021293696016073227,
      -0.006464689504355192,
      -0.0015509971417486668,
      -0.025577563792467117,
      -0.04547946900129318,
      -0.037383079528808594,
      -0.014688226394355297,
      0.03629349544644356,
      0.003641548566520214,
      -0.05803638696670532,
      -0.10850193351507187,
      0.0030526816844940186,
      -0.03749857470393181,
      -0.00205893162637949,
      -0.02262270450592041,
      -0.009897241368889809,
      -0.02868029661476612,
      -0.05619894713163376,
      -0.03330238536000252,
      0.02724720910191536,
      -0.030009504407644272,
      0.07751859724521637,
      -0.03231915459036827,
      0.032578181475400925,
      -0.03144337236881256,
      -0.006173993926495314,
      0.005409732460975647,
      -0.015781382098793983,
      -0.009048324078321457,
      0.06317500025033951,
      -0.0016219288809224963,
      0.04547486826777458,
      0.025629844516515732,
      0.007212519180029631,
      0.0380721390247345,
      -0.0232810340821743,
      -0.02590523473918438,
      0.03932718560099602,
      -0.08664441853761673,
      -0.03298148140311241,
      -0.039013780653476715,
      0.011601666919887066,
      0.058739159256219864,
      -0.03498710319399834,
      0.013848261907696724,
      0.06810929626226425,
      0.001500340411439538,
      -0.05629803612828255,
      0.0028671910986304283,
      0.050078753381967545,
      0.07910265028476715,
      0.03970120847225189,
      -0.024406911805272102,
      0.020797988399863243,
      0.036608319729566574,
      -0.05336553975939751,
      0.011207615956664085,
      0.05572415143251419,
      -0.011779812164604664,
      -0.03223646432161331,
      -0.018308570608496666,
      -0.04384436830878258,
      0.024508051574230194,
      0.015656251460313797,
      0.0047206394374370575,
      0.024987757205963135,
      0.07908866554498672,
      0.10226757824420929,
      0.06604883074760437,
      -0.00908797699958086,
      0.06253823637962341,
      0.0054428172297775745,
      0.02958269603550434,
      -0.024864912033081055,
      -0.006470731925219297,
      -0.05371767655014992,
      -0.024759916588664055,
      -0.04792729765176773,
      0.04587673023343086,
      0.037133004516363144,
      -0.0384843647480011,
      0.06006333604454994,
      0.04415343329310417,
      0.026977455243468285,
      0.08401402831077576,
      -0.01451457105576992,
      -0.028552351519465446,
      0.04027837514877319,
      0.04659136012196541,
      -0.05756590887904167,
      0.06362204998731613,
      -0.021427618339657784,
      -0.021248769015073776,
      0.02117270976305008,
      -0.06492720544338226,
      -0.05422656610608101,
      -0.024636773392558098,
      0.10110128670930862,
      0.017831923440098763,
      -0.015851633623242378,
      0.02088012360036373,
      0.06985240429639816,
      0.05131875351071358,
      -0.029689598828554153,
      0.025240417569875717,
      -0.029885629191994667,
      -0.02064839005470276,
      -0.011265388689935207,
      0.030552566051483154,
      -0.04356372728943825,
      0.004303219262510538,
      0.05740119889378548,
      -0.03557543084025383,
      0.08730544149875641,
      0.00850705150514841,
      0.011492578312754631,
      -0.07806246727705002,
      -0.011020556092262268,
      0.0076979068107903,
      -0.04136084020137787,
      -0.0522485189139843,
      0.014779817312955856,
      -0.013001322746276855,
      -0.020272942259907722,
      0.02229720540344715,
      -0.05952657759189606,
      -0.011446832679212093,
      0.027613921090960503,
      -0.0041475119069218636,
      -0.02551584132015705,
      0.02760918252170086,
      -0.026541009545326233,
      0.08870172500610352,
      -0.044885799288749695,
      -0.06101515516638756,
      -0.002150250831618905,
      -0.025972925126552582,
      -0.13304024934768677,
      0.09120134264230728,
      0.023997198790311813,
      -0.04013487696647644,
      0.0026453977916389704,
      0.03477267548441887,
      -0.03448658436536789,
      -0.009147695265710354,
      -0.07066331803798676,
      -0.017384886741638184,
      0.0060942829586565495,
      0.0017034150660037994,
      0.11054032295942307,
      0.02756761759519577,
      -0.06087736040353775,
      -0.024273233488202095,
      0.01662984862923622,
      -0.08099070191383362,
      -0.04492063447833061,
      -0.08712978661060333,
      0.009968695230782032,
      -0.016122981905937195,
      -0.01005926076322794,
      -0.08528175204992294,
      -0.014320982620120049,
      -0.0330326147377491,
      0.020125821232795715,
      0.042941439896821976,
      -0.08358684927225113,
      0.010559392161667347,
      -0.02471393346786499,
      -0.05790374428033829,
      0.06757554411888123,
      0.05003006383776665,
      0.05437232553958893,
      -0.005514226388186216,
      0.014337540604174137,
      -0.04270128160715103,
      -0.016327910125255585,
      0.10055333375930786,
      0.009208668023347855,
      0.00015197247557807714,
      -0.01492304727435112,
      -0.017550267279148102,
      0.039397161453962326,
      -0.021603962406516075,
      -0.012993169948458672,
      0.0009098027949221432,
      -0.037633366882801056,
      0.09548027068376541,
      -0.028853939846158028,
      0.011882691644132137,
      0.023770077154040337,
      0.028517670929431915,
      -0.03278045356273651,
      -0.02175535261631012,
      0.014127989299595356,
      0.07756531983613968,
      -0.023351257666945457,
      -0.01805211789906025,
      -0.021260635927319527,
      0.05184781551361084,
      -0.018544267863035202,
      -0.09624165296554565,
      0.0008084081346169114,
      0.012723956257104874,
      0.027037661522626877,
      0.018887056037783623,
      0.012831790372729301,
      -0.059101514518260956,
      0.004614296834915876,
      -0.010538898408412933,
      0.019816633313894272,
      0.056179411709308624,
      0.023608090355992317,
      -0.009726341813802719,
      -0.13283538818359375,
      0.05075877159833908,
      0.06089988723397255,
      -0.054307762533426285,
      0.008642260916531086,
      -0.04465998336672783,
      0.030759502202272415,
      -0.04810517281293869,
      0.07753539830446243,
      0.014678110368549824,
      0.05604023486375809,
      -0.010934993624687195,
      0.03073885478079319,
      0.057163409888744354,
      0.043248917907476425,
      -0.0027869991026818752,
      0.04773084074258804,
      0.02831403538584709,
      -0.035459358245134354,
      0.024791624397039413,
      -0.004379352554678917,
      0.10962159186601639,
      0.032007697969675064,
      -0.03474501147866249,
      -0.050134092569351196,
      0.035632167011499405,
      0.009313441812992096,
      -0.003025492886081338,
      -0.011369278654456139,
      0.013019390404224396,
      -0.08389915525913239,
      0.010219225659966469,
      0.01513372827321291,
      0.014881271868944168,
      -0.009043431840837002,
      0.01885189861059189,
      0.059712477028369904,
      -0.02013617940247059,
      0.035076040774583817,
      -0.04273861274123192,
      0.01615254394710064,
      -0.034245565533638,
      0.03481245040893555,
      0.03688435256481171,
      -0.03312930837273598,
      0.0159240011125803,
      0.04579884558916092,
      -0.029259389266371727,
      -0.04070475697517395,
      -0.05768166482448578,
      0.05539863184094429,
      -0.03801581263542175,
      0.0023222514428198338,
      0.06853374093770981,
      0.09896790981292725,
      -0.004111696034669876,
      -0.050853267312049866,
      0.022565416991710663,
      0.04598525911569595,
      0.08752147108316422,
      -0.0044427430257201195,
      0.06050409749150276,
      0.0014894040068611503,
      -0.046695027500391006,
      -0.02033393643796444,
      0.06616875529289246,
      -0.008175208233296871,
      0.043665237724781036,
      0.0340687595307827,
      -0.024245744571089745,
      0.03189266473054886,
      0.044651102274656296,
      -0.010945301502943039,
      0.015865003690123558,
      0.03961988538503647,
      -0.02491191402077675,
      -0.039399437606334686,
      -0.01617717184126377,
      -0.007768564857542515,
      0.003162229899317026,
      -0.026888029649853706,
      0.05794057250022888,
      -0.015027941204607487,
      -0.0077719613909721375,
      0.0067004673182964325,
      -0.012192580848932266,
      -0.02081024833023548,
      -0.009800860658288002,
      0.02587382309138775,
      0.032930273562669754,
      0.0535605326294899,
      -0.018525758758187294,
      0.04426843300461769,
      0.010922234505414963,
      0.023658715188503265,
      0.02921014279127121,
      0.02206236496567726,
      -0.03575625643134117,
      0.017265500500798225,
      -0.0037921154871582985,
      -0.0036998381838202477,
      0.011952843517065048,
      -0.025416787713766098,
      -0.003730779979377985,
      0.07134895026683807,
      0.005607132334262133,
      -0.0584951713681221,
      -0.028339402750134468,
      0.003090744838118553,
      -0.006584695540368557,
      0.006449629552662373,
      -0.1304149180650711,
      -0.0587800107896328,
      0.03979462385177612,
      0.02088208682835102,
      0.03782379627227783,
      0.0430309921503067,
      0.006111051421612501,
      -0.02104830928146839,
      -0.0485042929649353,
      0.025995373725891113,
      -0.06707045435905457,
      -0.0494726300239563,
      0.014965985901653767,
      0.059811610728502274,
      0.09327717870473862,
      -0.004483820870518684,
      0.016736794263124466,
      -0.004465611185878515,
      -0.05935141444206238,
      0.01243243832141161,
      -0.03981413692235947,
      0.026363693177700043,
      -0.05403107777237892,
      -0.024472491815686226,
      0.027207843959331512,
      0.07138947397470474,
      0.0360405333340168,
      -0.0010783271864056587,
      -0.01920781470835209,
      -0.016542108729481697,
      -0.056025851517915726,
      0.04281165823340416,
      -0.022941773757338524,
      -0.07652278989553452,
      -0.03470407426357269,
      -0.04566748067736626,
      -0.009431219659745693,
      -0.007843815721571445,
      0.065083809196949,
      0.04699061065912247,
      0.04628203436732292,
      0.06934859603643417,
      0.07132331281900406,
      -0.006159920711070299,
      -0.022291753441095352,
      -0.03912830352783203,
      -0.03597506508231163,
      0.002930178539827466,
      0.016746560111641884,
      -0.07083118706941605,
      0.09045282751321793,
      0.020380569621920586,
      0.015211273916065693,
      0.022019144147634506,
      0.018875427544116974,
      0.009989547543227673,
      0.0636594146490097,
      0.07089899480342865,
      -0.008788639679551125,
      -0.11162851750850677,
      -0.05979359894990921,
      0.04120881110429764,
      0.055288758128881454,
      0.02565440908074379,
      0.08478374779224396,
      -0.03670484572649002,
      -0.051010701805353165,
      -0.003029500599950552,
      0.002286139177158475,
      0.003880032105371356,
      0.03401283547282219,
      -0.04660201817750931,
      0.004374458454549313,
      0.002096716547384858,
      0.1120457872748375,
      0.009324148297309875,
      -0.005176113918423653,
      -0.026239274069666862,
      0.016949795186519623,
      -0.03882891684770584,
      -0.09268561750650406,
      -0.006038337480276823,
      0.08721396327018738,
      -0.060819387435913086,
      -0.02523043379187584,
      0.10437154024839401,
      0.009141041897237301,
      -0.012168264016509056,
      0.00812023226171732,
      0.016224496066570282,
      0.007833867333829403,
      -0.037410371005535126,
      0.07792449742555618,
      0.045069899410009384,
      -0.006352817639708519,
      -0.033589351922273636,
      -0.04321902617812157,
      -0.028996998444199562,
      -0.04500958323478699,
      -0.011229113675653934,
      -0.011743379756808281,
      0.05907522886991501,
      0.07487944513559341,
      0.010929583571851254,
      0.0160420723259449,
      0.07687932997941971,
      -0.017051538452506065,
      0.04754186049103737,
      0.015019572339951992,
      0.07659165561199188,
      -0.03364982083439827,
      -0.01917819119989872,
      0.01797027327120304,
      -0.020309634506702423,
      0.08688249439001083,
      0.04199070483446121,
      -0.03275439888238907,
      0.018714414909482002,
      0.05904348939657211,
      -0.004977166652679443,
      -0.050545576959848404,
      -0.12347611039876938,
      0.0875236988067627,
      -0.10757220536470413,
      -0.02135334350168705,
      -0.07440375536680222,
      0.0036334393080323935,
      0.04546362906694412,
      0.07078006118535995,
      0.008775603026151657,
      0.018648698925971985,
      0.06181671842932701,
      -0.06068883091211319,
      -0.012797459028661251
    ],
    [
      -0.08613167703151703,
      -0.022010628134012222,
      0.020504940301179886,
      -0.0226755253970623,
      -0.038528233766555786,
      -0.06793312728404999,
      -0.05692252516746521,
      0.08433150500059128,
      0.054460376501083374,
      0.06650019437074661,
      0.042174335569143295,
      0.019180674105882645,
      -0.05278211459517479,
      -0.01062103547155857,
      -0.05758608505129814,
      0.09058945626020432,
      0.022019103169441223,
      -0.010342537425458431,
      -0.016665376722812653,
      0.060584165155887604,
      0.06761442124843597,
      -0.020669294521212578,
      -0.06300213932991028,
      0.007707907352596521,
      0.0775580033659935,
      -0.021834492683410645,
      -0.015216086991131306,
      -0.05030757188796997,
      -0.014973059296607971,
      -0.019906960427761078,
      0.0820675641298294,
      0.015584287233650684,
      0.017196286469697952,
      0.0806209072470665,
      0.03051222302019596,
      0.0001281130244024098,
      -0.011755149811506271,
      0.024592075496912003,
      0.02101331762969494,
      0.11540919542312622,
      -0.03917728364467621,
      -0.05169909447431564,
      0.0475359670817852,
      0.06332415342330933,
      0.024944769218564034,
      -0.040083445608615875,
      0.0018396114464849234,
      -0.019535565748810768,
      -0.02770162932574749,
      0.03775614872574806,
      -0.016053715720772743,
      -0.03467182442545891,
      -0.024094000458717346,
      0.027637165039777756,
      0.029964111745357513,
      0.02634938433766365,
      -0.0208514966070652,
      0.03531208634376526,
      -0.012639193795621395,
      -0.03921256214380264,
      -0.00625297985970974,
      -0.08914835005998611,
      -0.032352644950151443,
      -0.0010050509590655565,
      0.050163380801677704,
      -0.03663076460361481,
      0.030531087890267372,
      -0.010574138723313808,
      -0.06206927075982094,
      0.015342360362410545,
      -0.0027419894468039274,
      0.008020108565688133,
      -0.10348153114318848,
      0.015965310856699944,
      -0.055606234818696976,
      0.006686777342110872,
      0.05961383506655693,
      -0.0568353608250618,
      -0.0907774269580841,
      -0.00281133851967752,
      0.015110145322978497,
      0.07965736091136932,
      -0.08113257586956024,
      -0.0036055201198905706,
      -0.005319868680089712,
      -0.0032769746612757444,
      -0.024943824857473373,
      -0.031436849385499954,
      0.04011327773332596,
      -0.03359373286366463,
      -0.04850757494568825,
      -0.017431506887078285,
      0.014991688542068005,
      -0.03268676623702049,
      0.02253532223403454,
      -0.03153970465064049,
      -0.008042172528803349,
      -0.08460143953561783,
      0.06222573667764664,
      -0.03782818466424942,
      0.0436515249311924,
      0.06520582735538483,
      -0.003109317272901535,
      -0.04194739833474159,
      0.12165668606758118,
      0.021023375913500786,
      -0.02510656975209713,
      0.011981036514043808,
      0.031663957983255386,
      -0.0755918100476265,
      0.07347358018159866,
      0.009257342666387558,
      -0.031397972255945206,
      -0.02390468493103981,
      -0.026903729885816574,
      0.01561752986162901,
      0.0012187834363430738,
      -0.05794518068432808,
      -0.045495081692934036,
      0.03247908875346184,
      -0.05110187456011772,
      -0.06692440062761307,
      0.07154978811740875,
      0.0818692147731781,
      0.03979761153459549,
      -0.020490707829594612,
      -0.06903281807899475,
      0.030565477907657623,
      0.018728384748101234,
      -0.037323594093322754,
      0.029913034290075302,
      -0.0777222141623497,
      0.004720333032310009,
      -0.03610682860016823,
      -0.005237011704593897,
      0.045744460076093674,
      0.04748326539993286,
      0.02994607388973236,
      -0.024704506620764732,
      0.029683127999305725,
      -0.013700278475880623,
      -0.07657390087842941,
      0.016880568116903305,
      0.03717309981584549,
      0.03445519879460335,
      -0.05076843500137329,
      0.0014816351467743516,
      -0.006006170064210892,
      -0.0008125831373035908,
      0.07463251054286957,
      -0.010027563199400902,
      -0.025310227647423744,
      -0.009364845231175423,
      -0.008423388935625553,
      -0.03807949647307396,
      0.036688823252916336,
      0.003922646399587393,
      0.08597438782453537,
      0.02260773256421089,
      -0.06676679104566574,
      -0.004616806283593178,
      -0.03758840262889862,
      0.01702493242919445,
      -0.03589126467704773,
      0.017284180968999863,
      -0.07775137573480606,
      -0.11070509999990463,
      -0.03696680814027786,
      0.04084303602576256,
      0.04465608298778534,
      0.007245246320962906,
      -0.04460614174604416,
      -0.051450394093990326,
      0.0010850968537852168,
      -0.016335681080818176,
      0.02042236365377903,
      -0.046632830053567886,
      0.00043466201168484986,
      0.03046153299510479,
      -0.015447954647243023,
      -0.031030062586069107,
      -0.030618516728281975,
      -0.020570889115333557,
      0.08368626981973648,
      -0.04142243042588234,
      0.0044122617691755295,
      0.021544432267546654,
      -0.007378250360488892,
      -0.005733570549637079,
      -0.033883120864629745,
      -0.031873855739831924,
      -0.0669020339846611,
      -0.00906312931329012,
      0.0009032082743942738,
      -0.035337094217538834,
      0.020298689603805542,
      -0.05726233124732971,
      0.04315213859081268,
      -0.058360688388347626,
      0.05388859659433365,
      0.020054616034030914,
      -0.010330633260309696,
      0.015042952261865139,
      -0.04669781029224396,
      0.03980453312397003,
      -0.0743614211678505,
      0.041520506143569946,
      0.028091510757803917,
      -0.01770288124680519,
      0.008897244930267334,
      0.007196988444775343,
      0.018221359699964523,
      -0.07830879092216492,
      -0.016452334821224213,
      -0.019214468076825142,
      -0.05239419639110565,
      0.03346390649676323,
      0.03504548594355583,
      0.03780297562479973,
      -0.05949368327856064,
      -0.010475912131369114,
      -0.0481918640434742,
      -0.013970415107905865,
      -0.06049976497888565,
      -0.012939601205289364,
      -0.011260820552706718,
      -0.001125456765294075,
      -0.01800357922911644,
      -0.0002555932733230293,
      -0.034342531114816666,
      -0.04207555204629898,
      -0.036272067576646805,
      0.04665421321988106,
      0.02001013606786728,
      0.0656161904335022,
      0.0062396470457315445,
      -0.017786042764782906,
      -0.015535523183643818,
      0.00024454889353364706,
      0.06613099575042725,
      0.055873606353998184,
      -0.04170897230505943,
      -0.012756436131894588,
      -0.028481991961598396,
      0.08704754710197449,
      0.006099565885961056,
      -0.009370345622301102,
      0.02345089800655842,
      0.02629944495856762,
      0.033326148986816406,
      -0.026397988200187683,
      -0.050309810787439346,
      0.013462292961776257,
      0.012503175996243954,
      -0.017270511016249657,
      0.011556991375982761,
      0.035985760390758514,
      0.004349600523710251,
      -0.09048629552125931,
      -0.0030152767430990934,
      0.05482183024287224,
      -0.046899743378162384,
      -0.0032548103481531143,
      0.012594183906912804,
      -0.05279470607638359,
      0.004797524772584438,
      0.08476480841636658,
      -0.015046579763293266,
      -0.019112596288323402,
      0.10014426708221436,
      -0.014715219847857952,
      -0.0745827704668045,
      -0.015580704435706139,
      0.015302728861570358,
      0.009726477786898613,
      -0.06876876950263977,
      -0.04820083826780319,
      -0.0036777211353182793,
      0.026611963286995888,
      0.03677675500512123,
      -0.005947573110461235,
      -0.045790303498506546,
      -0.032524459064006805,
      -0.013356358744204044,
      0.09924579411745071,
      0.009162466041743755,
      0.02231956087052822,
      0.04238608852028847,
      0.052340518683195114,
      -0.06036729738116264,
      -0.03314252570271492,
      0.04316849634051323,
      -0.023517463356256485,
      0.006685522850602865,
      0.016849858686327934,
      -0.026692422106862068,
      -0.018846560269594193,
      0.02199707180261612,
      0.07435490190982819,
      0.021310802549123764,
      -0.006556360982358456,
      0.03386342152953148,
      -0.08198481053113937,
      -0.03169737011194229,
      0.07272530347108841,
      -0.07029742002487183,
      -0.030398430302739143,
      -0.033443279564380646,
      0.07509858161211014,
      0.043564461171627045,
      -0.057435523718595505,
      -0.030795849859714508,
      -0.014400182291865349,
      0.03305559977889061,
      0.01992395706474781,
      0.0038397610187530518,
      0.045512959361076355,
      -0.06917975842952728,
      -0.05011577531695366,
      0.015344183892011642,
      -0.023912958800792694,
      -0.004916822072118521,
      0.029910942539572716,
      0.08358950912952423,
      -0.045858222991228104,
      0.045473407953977585,
      0.0202399343252182,
      -0.040781959891319275,
      -0.04538313299417496,
      -0.04117204248905182,
      -0.01641244813799858,
      0.02160666510462761,
      -0.026732346042990685,
      -0.02655973844230175,
      -0.020840521901845932,
      0.052221979945898056,
      -0.03110400214791298,
      -0.023783493787050247,
      0.03228871896862984,
      0.0008128214394673705,
      0.09026414155960083,
      -0.06668384373188019,
      0.031383760273456573,
      -0.030465129762887955,
      0.029175154864788055,
      -0.023036321625113487,
      0.07131007313728333,
      -0.056664202362298965,
      -0.03752944990992546,
      -0.014482212252914906,
      -0.027564486488699913,
      -0.01738598942756653,
      -0.01178679522126913,
      0.0020883295219391584,
      -0.04952843487262726,
      -0.07514673471450806,
      0.020513329654932022,
      -0.07378882169723511,
      0.034357696771621704,
      0.038722965866327286,
      0.03638976067304611,
      -0.0023168385960161686,
      -0.016480950638651848,
      0.07866796851158142,
      -0.019291957840323448,
      0.003938058856874704,
      0.03760469704866409,
      0.05446043238043785,
      -0.06362868845462799,
      0.015060171484947205,
      0.01000448502600193,
      0.013939781114459038,
      0.023868339136242867,
      0.04060801863670349,
      0.021290510892868042,
      -0.037749044597148895,
      -0.05119733884930611,
      0.04259606823325157,
      0.0637778490781784,
      0.012291987426578999,
      0.1407613754272461,
      -0.02343747206032276,
      -0.0024094886612147093,
      0.0798482671380043,
      -0.013987082988023758,
      0.10620010644197464,
      0.02132285200059414,
      0.03197338432073593,
      -0.06566871702671051,
      -0.057940687984228134,
      0.043513596057891846,
      0.10024431347846985,
      0.015514998696744442,
      0.013369696214795113,
      0.034234318882226944,
      0.07733821868896484,
      0.021506251767277718,
      -0.022050641477108,
      0.04759110510349274,
      0.0018174569122493267,
      0.04727533087134361,
      -0.03128264844417572,
      -0.01680733636021614,
      -0.05326380580663681,
      0.06945353746414185,
      -0.03736964613199234,
      -0.05449969321489334,
      -0.05328642204403877,
      0.057956844568252563,
      -0.040515586733818054,
      -0.06827443093061447,
      0.005002771504223347,
      0.026531223207712173,
      -0.0008243710617534816,
      0.027773747220635414,
      0.07686831057071686,
      0.057485371828079224,
      0.020369242876768112,
      -0.008270500227808952,
      0.025022149085998535,
      0.059551436454057693,
      0.059028755873441696,
      0.09493666142225266,
      -0.06084726005792618,
      -0.02995816059410572,
      -0.015806516632437706,
      0.052654098719358444,
      -0.01874784752726555,
      0.0019927101675421,
      -0.07150599360466003,
      -0.053566087037324905,
      -0.03305678814649582,
      0.10567693412303925,
      0.007793768774718046,
      0.008925368078052998,
      -0.004759213887155056,
      0.03272620961070061,
      0.033772435039281845,
      -0.015149850398302078,
      -0.020817678421735764,
      0.05454370751976967,
      0.025957288220524788,
      -0.06989815086126328,
      0.02531852014362812,
      -0.0328989177942276,
      0.028723089024424553,
      -0.04362814128398895,
      -0.013172479346394539,
      0.039036158472299576,
      0.056175347417593,
      -0.02057422697544098,
      0.019613469019532204,
      0.055156297981739044,
      -0.021546857431530952,
      -0.08133745938539505,
      0.07586754858493805,
      -0.02653677761554718,
      0.021325740963220596,
      0.02909328043460846,
      0.057082079350948334,
      -0.03364880010485649,
      -0.009784922003746033,
      0.016590941697359085,
      -0.03737039491534233,
      -0.0047196317464113235,
      -0.007735384628176689,
      -0.021283747628331184,
      -0.05279925465583801,
      0.004477278329432011,
      0.06838496029376984,
      0.04114019498229027,
      -0.02633192390203476,
      -0.003417939879000187,
      -0.05639796704053879,
      0.005459617357701063,
      0.01476187165826559,
      0.054671186953783035,
      -0.051012374460697174,
      0.04023480415344238,
      0.01570742391049862,
      0.08556685596704483,
      -0.06729993224143982,
      0.006631562951952219,
      -0.029982084408402443,
      -0.0014509145403280854,
      0.043800223618745804,
      0.06061992421746254,
      -0.010576797649264336,
      -0.0009329695021733642,
      0.018012387678027153,
      0.032085221260786057,
      -0.012462726794183254,
      0.06033337488770485,
      -0.015306715853512287,
      -0.03434824198484421,
      0.10099785029888153,
      0.06414478272199631,
      0.039793938398361206,
      -0.0434287004172802,
      -0.006256952881813049,
      -0.017765793949365616,
      0.012457957491278648,
      0.003209119662642479
    ],
    [
      -0.043690990656614304,
      0.03964383527636528,
      0.03593175858259201,
      0.012874448671936989,
      0.06349574029445648,
      -0.044035013765096664,
      0.06488041579723358,
      -0.04425020515918732,
      0.0679859146475792,
      -0.029676251113414764,
      0.01062212698161602,
      -0.036069225519895554,
      -0.0007898418698459864,
      0.028288574889302254,
      0.01573338359594345,
      -0.03584084287285805,
      -0.05889570340514183,
      -0.004374057520180941,
      -0.014746112748980522,
      -0.06010289117693901,
      -0.043247856199741364,
      -0.03956447169184685,
      -0.07012195140123367,
      0.05637772008776665,
      0.08506033569574356,
      0.000781469396315515,
      0.03006686642765999,
      -0.05094549059867859,
      -0.01541627012193203,
      -0.05238255858421326,
      0.08526832610368729,
      0.034414682537317276,
      -0.039386991411447525,
      -0.058706533163785934,
      0.012436788529157639,
      0.059787243604660034,
      -0.11085096746683121,
      0.02266867272555828,
      -0.049625214189291,
      -0.026297366246581078,
      -0.017943114042282104,
      -0.02185998298227787,
      0.02379143424332142,
      0.02369472198188305,
      -0.003816867945715785,
      0.04497644677758217,
      -0.03232826292514801,
      -0.022855142131447792,
      0.11627647280693054,
      -0.013675509952008724,
      0.04959584027528763,
      0.05777603015303612,
      0.009571950882673264,
      0.013036143966019154,
      -0.004425729624927044,
      0.04608800262212753,
      -0.04023967310786247,
      0.09169816970825195,
      0.038491327315568924,
      -0.06366628408432007,
      0.08426740765571594,
      0.045491982251405716,
      0.08321226388216019,
      -0.031969357281923294,
      -0.010114655829966068,
      -0.03147786483168602,
      -0.10130073130130768,
      -0.06433393061161041,
      -0.018881147727370262,
      -0.00860351137816906,
      0.01560211181640625,
      -0.07952219247817993,
      0.021871568635106087,
      0.05432291328907013,
      0.09519661962985992,
      -0.03933597728610039,
      -0.022329222410917282,
      -0.017637616023421288,
      -0.012752820737659931,
      0.08784040808677673,
      0.02617517299950123,
      -0.06012789160013199,
      -0.04156545177102089,
      0.015423613600432873,
      -0.011436627246439457,
      -0.016963865607976913,
      0.016773879528045654,
      0.018509013578295708,
      0.0529358796775341,
      0.0082305409014225,
      0.06520137190818787,
      -0.06000044196844101,
      0.009742183610796928,
      0.007415225729346275,
      0.08387360721826553,
      0.004922870080918074,
      -0.012775516137480736,
      -0.04753478616476059,
      0.0335172601044178,
      -0.026900850236415863,
      -0.004629491362720728,
      0.021634574979543686,
      0.02389814518392086,
      -0.04977748543024063,
      -0.04352211952209473,
      -0.031121114268898964,
      -0.033121731132268906,
      0.023016197606921196,
      0.04712704196572304,
      0.044830892235040665,
      -0.0015147710219025612,
      0.04415789619088173,
      0.033396221697330475,
      0.03552545607089996,
      -0.04774048179388046,
      -0.02122492343187332,
      0.01499142311513424,
      0.014740802347660065,
      -0.03870251774787903,
      -0.06543847173452377,
      -0.0033282930962741375,
      -0.0026427407283335924,
      0.06649700552225113,
      -0.006256760563701391,
      -0.000680697790812701,
      0.019285405054688454,
      -0.027367396280169487,
      0.05630769208073616,
      0.024081949144601822,
      -0.02679075300693512,
      -0.08190499991178513,
      -0.11674588173627853,
      -0.07977896183729172,
      0.011178191751241684,
      -0.003770605195313692,
      0.00751896807923913,
      0.027843164280056953,
      0.005071577150374651,
      0.0003328887396492064,
      0.05726344510912895,
      0.07280290871858597,
      -0.04300706088542938,
      0.04133014380931854,
      0.06105256453156471,
      -0.007222471293061972,
      0.015921087935566902,
      0.0012865124735981226,
      0.015756772831082344,
      -0.006228847894817591,
      -0.07198487967252731,
      0.01853812299668789,
      0.02412470430135727,
      0.07469087839126587,
      -0.013619758188724518,
      0.02437552623450756,
      -0.015658188611268997,
      0.025344589725136757,
      0.001395768136717379,
      -0.059743478894233704,
      -0.04525914415717125,
      -0.03786709904670715,
      -0.03218900039792061,
      -0.01734531670808792,
      0.07649516314268112,
      0.009623992256820202,
      0.04939190670847893,
      0.05709768086671829,
      -0.006258748471736908,
      -0.06612387299537659,
      -0.026612363755702972,
      0.032126009464263916,
      0.021507197991013527,
      -0.013265970163047314,
      0.05122167989611626,
      -0.048029810190200806,
      0.05126058682799339,
      -0.08125825971364975,
      -0.025346769019961357,
      0.05184905230998993,
      -0.012609049677848816,
      -0.023532550781965256,
      0.036050938069820404,
      0.08535569906234741,
      0.018410341814160347,
      0.047301750630140305,
      0.046788960695266724,
      -0.06557350605726242,
      -0.005750405136495829,
      -0.014385434798896313,
      0.0008079982362687588,
      -0.053289614617824554,
      -0.07662799209356308,
      -0.015968549996614456,
      0.08989664167165756,
      -0.0004206169687677175,
      0.0007696598186157644,
      0.006665403489023447,
      0.0018429001793265343,
      -0.03538011759519577,
      -0.05444643646478653,
      0.00043187476694583893,
      -0.016006752848625183,
      -0.01881115697324276,
      -0.03023827075958252,
      0.04701770097017288,
      -0.024080950766801834,
      -0.0660603940486908,
      0.012264030985534191,
      0.08122356235980988,
      0.025017939507961273,
      0.041446443647146225,
      -0.007019666023552418,
      -0.03297686204314232,
      -0.016316493973135948,
      -0.062120143324136734,
      -0.007955189794301987,
      0.08548922836780548,
      0.002795714419335127,
      -0.028528770431876183,
      -0.040548667311668396,
      0.005767626687884331,
      0.0457058921456337,
      -0.03404567763209343,
      0.013713154010474682,
      0.010188307613134384,
      -0.012558609247207642,
      0.005920101888477802,
      -0.017132017761468887,
      0.024928098544478416,
      0.01693710684776306,
      -0.11199257522821426,
      -0.027478426694869995,
      0.09025879204273224,
      -0.037218380719423294,
      -0.06441526859998703,
      -0.10085167735815048,
      0.04287101328372955,
      0.03234200179576874,
      -0.07693147659301758,
      -0.08173703402280807,
      0.017728164792060852,
      0.045362308621406555,
      -0.018291989341378212,
      -0.009152153506875038,
      0.011180330999195576,
      0.018735282123088837,
      -0.02274768054485321,
      0.043685153126716614,
      -0.026816517114639282,
      0.04018591716885567,
      0.012668018229305744,
      0.02044646255671978,
      -0.07575755566358566,
      0.03361942619085312,
      0.11296920478343964,
      0.00290671200491488,
      0.04129906743764877,
      0.033086083829402924,
      0.03650624305009842,
      -0.019437719136476517,
      -0.07559151202440262,
      0.04268644377589226,
      -0.1096527948975563,
      -0.03529039025306702,
      -0.02040201611816883,
      -0.005378067959100008,
      0.010183898732066154,
      0.03737419843673706,
      0.04137253388762474,
      -0.04772188141942024,
      -0.018536245450377464,
      0.05306470766663551,
      0.08943880349397659,
      0.03218555450439453,
      0.04239320009946823,
      -0.0021014439407736063,
      -0.04208492487668991,
      0.024110781028866768,
      -0.032568804919719696,
      0.030193250626325607,
      -0.00627543730661273,
      0.04679834470152855,
      -0.041373033076524734,
      0.03957943990826607,
      -0.02662642113864422,
      0.0011820647632703185,
      -0.0020971971098333597,
      -0.06036548689007759,
      -0.039992645382881165,
      -0.025553643703460693,
      0.03830605372786522,
      0.028706295415759087,
      0.006587241776287556,
      -0.02762979455292225,
      0.11121940612792969,
      -0.08925820887088776,
      -0.024871405214071274,
      0.06715595722198486,
      -0.03849974647164345,
      0.007108896505087614,
      -0.034494850784540176,
      -0.03921903669834137,
      0.05500832572579384,
      0.06496750563383102,
      -0.028309427201747894,
      -0.14489135146141052,
      0.05077484995126724,
      0.015470598824322224,
      0.03901908919215202,
      -0.004053157288581133,
      -0.1306934505701065,
      0.02603911980986595,
      0.04242526739835739,
      0.027961837127804756,
      0.010122442618012428,
      -0.01592670939862728,
      0.030643178150057793,
      0.01888676919043064,
      -0.0455494299530983,
      -0.03558506444096565,
      -0.00407224940136075,
      -0.0004900178755633533,
      -0.007169648073613644,
      0.0342005118727684,
      0.10343814641237259,
      0.003295458387583494,
      0.08082809299230576,
      -0.049826063215732574,
      -0.01416174229234457,
      0.024411560967564583,
      0.04220745339989662,
      0.048192981630563736,
      -0.0043307277373969555,
      0.029404491186141968,
      -0.028513407334685326,
      -0.056352097541093826,
      -0.039415888488292694,
      0.03478454053401947,
      -0.06215953454375267,
      0.05901743099093437,
      -0.026850398629903793,
      -0.07123635709285736,
      -0.15424653887748718,
      -0.0439487062394619,
      0.0476844348013401,
      -0.042776454240083694,
      -0.010491074994206429,
      0.018554888665676117,
      0.026543084532022476,
      -0.014776748605072498,
      -0.042770758271217346,
      0.03754601255059242,
      0.015920355916023254,
      -0.03726355358958244,
      -0.06562761217355728,
      0.06072210893034935,
      0.0019016111036762595,
      -0.042094551026821136,
      0.005929001607000828,
      0.007852237671613693,
      0.036011938005685806,
      0.007217565551400185,
      -0.02415228821337223,
      0.028282886371016502,
      -0.02441173605620861,
      0.02412758581340313,
      0.022417370229959488,
      -0.02142159827053547,
      0.016624726355075836,
      0.004869920667260885,
      -0.02646387740969658,
      0.044237151741981506,
      0.02582382597029209,
      0.02437961846590042,
      -0.0755433589220047,
      -0.011258185841143131,
      0.03644443303346634,
      -0.00031247021979652345,
      -0.003282132325693965,
      0.0123582249507308,
      -0.00960996001958847,
      -0.05656298249959946,
      0.03585738688707352,
      0.03427884355187416,
      0.062127601355314255,
      0.025477061048150063,
      -0.00045029466855339706,
      -0.01334022544324398,
      0.0008141660946421325,
      -0.007689994759857655,
      -0.011368709616363049,
      0.0981031283736229,
      0.02082478255033493,
      0.03259468451142311,
      0.0013315733522176743,
      -0.04422802850604057,
      0.012520337477326393,
      0.06654614210128784,
      -0.007237489800900221,
      0.005427251569926739,
      -0.021047597751021385,
      -0.0555574931204319,
      -0.04205067828297615,
      -0.04189252853393555,
      -0.05946001037955284,
      -0.02049233391880989,
      -0.05795217677950859,
      -0.009769745171070099,
      -0.057335976511240005,
      -0.052944473922252655,
      -0.04234474152326584,
      -0.04694068431854248,
      0.0017366005340591073,
      -0.07596687972545624,
      0.013615014962852001,
      -0.07869984209537506,
      0.03757254779338837,
      0.03802654147148132,
      0.004113069735467434,
      -0.02624361403286457,
      0.045284610241651535,
      0.052052173763513565,
      0.09834447503089905,
      -0.01720777153968811,
      -0.03536856546998024,
      0.0018604882061481476,
      0.021240433678030968,
      -0.019388841465115547,
      -0.026483511552214622,
      0.02249065600335598,
      -0.022832289338111877,
      0.005458461586385965,
      -0.06697943061590195,
      0.06124136596918106,
      0.023425115272402763,
      0.012347118929028511,
      -0.00516898836940527,
      -0.019810974597930908,
      -0.025059189647436142,
      0.0464150570333004,
      -0.04885610193014145,
      -0.052700936794281006,
      0.02458871342241764,
      0.019051063805818558,
      0.03291904181241989,
      0.06328918784856796,
      -0.11037541925907135,
      -0.015913764014840126,
      -0.08426018059253693,
      -0.030814798548817635,
      0.04327637702226639,
      -0.0709778219461441,
      -0.06383100897073746,
      0.07019350677728653,
      0.030596083030104637,
      -0.0681556910276413,
      -0.04872256889939308,
      -0.03456247225403786,
      0.06219484657049179,
      -0.04087873548269272,
      -0.0018209611298516393,
      0.046694815158843994,
      0.007599187549203634,
      -0.062154073268175125,
      0.012646752409636974,
      -0.08877565711736679,
      -2.2579020878765732e-05,
      0.021387355402112007,
      -0.025498341768980026,
      0.07510301470756531,
      0.020185070112347603,
      -0.022470958530902863,
      0.04228819161653519,
      -0.11348278075456619,
      0.017044585198163986,
      0.020259495824575424,
      0.09313975274562836,
      0.05714210495352745,
      -0.018283262848854065,
      0.04034246504306793,
      -0.020513534545898438,
      -0.07007545232772827,
      0.0008989313500933349,
      0.11496081948280334,
      0.041092678904533386,
      0.017909754067659378,
      0.048994280397892,
      0.08244079351425171,
      0.015650417655706406,
      -0.04945094510912895,
      0.01837412267923355,
      0.008321208879351616,
      -0.06093694642186165,
      -0.025802727788686752,
      -0.017401674762368202,
      0.02459254115819931,
      -0.0011714142747223377,
      -0.020216163247823715,
      0.05103230103850365,
      0.02857099659740925,
      -0.02517361380159855,
      0.1025758758187294,
      -0.09971383959054947
    ],
    [
      0.05729096382856369,
      -0.002431971952319145,
      -0.025433236733078957,
      0.010776362381875515,
      -0.03936314582824707,
      0.02485446073114872,
      0.012284277006983757,
      0.018083510920405388,
      0.017061734572052956,
      0.026609579101204872,
      0.021197466179728508,
      0.06723348051309586,
      -0.04713500663638115,
      -0.008411338552832603,
      0.007053673733025789,
      0.008992688730359077,
      0.008037141524255276,
      -0.05748666077852249,
      -0.0306727085262537,
      -0.04887349531054497,
      -0.10395965725183487,
      0.0736580416560173,
      -0.00013396872964221984,
      0.006385996006429195,
      0.04427801072597504,
      0.05448527634143829,
      -0.03980785980820656,
      0.016552597284317017,
      0.03160778433084488,
      -0.01936764270067215,
      0.08146356046199799,
      0.04313673451542854,
      0.05273456126451492,
      -0.002345805987715721,
      -0.020859304815530777,
      -0.0021914502140134573,
      -0.060605064034461975,
      -0.03479320555925369,
      0.029327522963285446,
      0.04457809403538704,
      -0.007965086959302425,
      -0.016339164227247238,
      -0.016343845054507256,
      -0.04314842075109482,
      0.030621372163295746,
      -0.0476997047662735,
      0.05502057448029518,
      -0.08259586244821548,
      0.01612098328769207,
      -0.1051853746175766,
      0.03364935889840126,
      -0.011733103543519974,
      0.01430755015462637,
      -0.055602576583623886,
      -0.02679888904094696,
      -0.04703887552022934,
      0.10477963835000992,
      -0.04446973279118538,
      -0.09572789818048477,
      -0.02401382103562355,
      -0.03404979780316353,
      0.019626503810286522,
      0.031634509563446045,
      -0.009300962090492249,
      -0.02233794704079628,
      -0.021763937547802925,
      -0.040775008499622345,
      -0.03155254200100899,
      -0.04120608791708946,
      0.04576945677399635,
      -0.06509838253259659,
      -0.039731934666633606,
      0.034801602363586426,
      -0.00012229209823999554,
      0.016074711456894875,
      0.045921243727207184,
      0.007381338160485029,
      -0.016286544501781464,
      -0.04563593119382858,
      0.0504472590982914,
      0.09519874304533005,
      0.030734587460756302,
      -0.049709804356098175,
      0.045220986008644104,
      -0.026938455179333687,
      0.007717994507402182,
      -0.017025362700223923,
      -0.04265478998422623,
      0.09997161477804184,
      0.009842605330049992,
      0.004093252122402191,
      -0.08121538907289505,
      0.01124365534633398,
      -0.007950609549880028,
      -0.01861279457807541,
      -0.0674881562590599,
      -0.03752171993255615,
      -0.03183751925826073,
      -0.02397838793694973,
      -0.11259360611438751,
      -0.026092763990163803,
      -0.05907867103815079,
      0.02133394032716751,
      -0.0559040792286396,
      -0.023848645389080048,
      -0.008131526410579681,
      0.09148335456848145,
      0.015315177850425243,
      -0.005221613682806492,
      -0.017965558916330338,
      0.0058120246976614,
      0.04355579987168312,
      0.03495153412222862,
      -0.042094770818948746,
      0.04542507603764534,
      -0.0013611391186714172,
      -0.04186611250042915,
      0.07165005058050156,
      -0.02300742268562317,
      0.014440414495766163,
      -0.024750107899308205,
      -0.018900001421570778,
      0.08883717656135559,
      0.010307163000106812,
      -0.006486059632152319,
      0.026608075946569443,
      -0.022588681429624557,
      0.026549557223916054,
      -0.08623016625642776,
      0.0748976394534111,
      0.012073546648025513,
      0.014362679794430733,
      0.034792210906744,
      -0.04673036187887192,
      0.00794813223183155,
      -0.001965148840099573,
      0.0330544151365757,
      0.022019587457180023,
      0.02455051615834236,
      0.010852834209799767,
      0.009989193640649319,
      -0.05362670496106148,
      0.01795227639377117,
      0.0032861139625310898,
      0.07627221196889877,
      -0.037722960114479065,
      -0.05705747753381729,
      0.0014836647314950824,
      -0.04569047689437866,
      -0.02754327468574047,
      0.05595696344971657,
      0.07182420045137405,
      0.03643643483519554,
      0.01924522966146469,
      0.06720521301031113,
      0.028021512553095818,
      0.01941496692597866,
      0.0029794571455568075,
      -0.07292689383029938,
      0.04473378136754036,
      0.04511864855885506,
      -0.0293441005051136,
      0.04128366708755493,
      -0.085893414914608,
      0.03905797749757767,
      0.009782791137695312,
      0.005017782561480999,
      -0.001480204751715064,
      -0.09606582671403885,
      -0.0391647107899189,
      0.05836529657244682,
      0.006827047560364008,
      -0.02110300399363041,
      0.05166815593838692,
      0.004715607967227697,
      0.013449786230921745,
      -0.009664238430559635,
      -0.04246625304222107,
      0.026175329461693764,
      0.040559086948633194,
      -0.03601568192243576,
      -0.05875798687338829,
      -0.002138653304427862,
      0.014587140642106533,
      0.01732582598924637,
      0.03972545638680458,
      0.003645532066002488,
      -0.017183499410748482,
      -0.01832522265613079,
      0.02510606311261654,
      -0.0018768925219774246,
      -0.0007572268950752914,
      -0.060159821063280106,
      -0.003950855694711208,
      0.11543522775173187,
      -0.058308836072683334,
      0.011960185132920742,
      0.01393235195428133,
      0.08417084813117981,
      -0.01655750535428524,
      0.025753837078809738,
      -0.049456506967544556,
      0.011301376856863499,
      0.015212316066026688,
      0.09728943556547165,
      -0.008578880690038204,
      -6.333240162348375e-05,
      -0.015788234770298004,
      0.05704030767083168,
      0.013958289287984371,
      -0.06372330337762833,
      -0.0115507822483778,
      -0.03218800947070122,
      -0.015314624644815922,
      -0.012173941358923912,
      0.055798448622226715,
      -0.06603546440601349,
      0.01620682142674923,
      -0.019090691581368446,
      -0.031542692333459854,
      -0.018685026094317436,
      -0.019572477787733078,
      0.019791828468441963,
      0.037581831216812134,
      -0.11791914701461792,
      0.02195715717971325,
      -0.03804401308298111,
      -0.020106090232729912,
      0.018613474443554878,
      -0.008027379401028156,
      -0.03827479109168053,
      0.034094471484422684,
      -0.011743318289518356,
      -0.004171266220510006,
      0.01313871145248413,
      0.024492444470524788,
      -0.020208699628710747,
      -0.005542963743209839,
      -0.00827088300138712,
      0.0007449012482538819,
      -0.08421305567026138,
      -0.030686335638165474,
      -0.07973256707191467,
      -0.001637511420994997,
      0.06399485468864441,
      0.009236684069037437,
      0.022112274542450905,
      0.030973218381404877,
      -0.05137808248400688,
      0.040718358010053635,
      -0.01444836426526308,
      0.06621894985437393,
      -0.03669268265366554,
      -0.004895160906016827,
      0.028039639815688133,
      0.013047309592366219,
      0.051062945276498795,
      0.019537243992090225,
      0.05181783437728882,
      -0.05220846086740494,
      0.016285203397274017,
      0.03867846727371216,
      -0.024164387956261635,
      -0.042262159287929535,
      0.08056457340717316,
      -0.039169590920209885,
      -0.012997123412787914,
      0.022460293024778366,
      -0.031520478427410126,
      -0.0544782318174839,
      0.02679390087723732,
      -0.018905319273471832,
      0.05689152330160141,
      -0.03173018991947174,
      0.019034715369343758,
      0.04266936704516411,
      0.03721832484006882,
      -0.03198739513754845,
      -0.01631629653275013,
      -0.0001562565885251388,
      0.045625727623701096,
      0.01572447456419468,
      0.0421113520860672,
      -0.04757547006011009,
      0.01785707287490368,
      0.033603422343730927,
      -0.06621279567480087,
      -0.11255235224962234,
      0.07587996870279312,
      -0.0015483598690479994,
      0.0023475480265915394,
      0.0075650326907634735,
      0.032995354384183884,
      0.017332417890429497,
      0.05092710629105568,
      -0.03075072169303894,
      0.006880876608192921,
      0.06242305412888527,
      0.09816054254770279,
      0.00389677076600492,
      0.025509726256132126,
      -0.0015419565606862307,
      0.09150104224681854,
      0.01417479943484068,
      -0.0024713673628866673,
      0.042110029608011246,
      -0.0998745709657669,
      0.04173252731561661,
      0.08093813806772232,
      -0.02246619015932083,
      -0.025854215025901794,
      0.01948721893131733,
      -0.05115599185228348,
      -0.0025873794220387936,
      0.010273930616676807,
      -0.06205487623810768,
      0.01686648651957512,
      0.07011247426271439,
      0.007284120656549931,
      0.020509442314505577,
      0.034135852009058,
      0.04435399919748306,
      -0.044543858617544174,
      0.07333627343177795,
      0.08637119084596634,
      0.06453098356723785,
      0.009167094714939594,
      0.121913842856884,
      -0.015239644795656204,
      -0.01864287070930004,
      0.04147608205676079,
      0.030253371223807335,
      -0.019918249920010567,
      0.011934362351894379,
      0.042446937412023544,
      0.05777382850646973,
      -0.026739707216620445,
      -0.048715054988861084,
      -0.03199073299765587,
      -0.04335642606019974,
      -0.07070442289113998,
      0.033950887620449066,
      0.08891057223081589,
      0.06524576246738434,
      0.07984453439712524,
      0.012838498689234257,
      0.019890576601028442,
      -0.09440947324037552,
      0.027073906734585762,
      0.07806342095136642,
      0.03879156336188316,
      -0.0507274866104126,
      -0.012747202068567276,
      0.02150135301053524,
      -0.09732186794281006,
      -0.040786027908325195,
      -0.09034901112318039,
      -0.024963630363345146,
      -0.0007805494824424386,
      0.030991634353995323,
      0.040491629391908646,
      0.01150569412857294,
      0.015638647601008415,
      0.030811984091997147,
      -0.047652047127485275,
      -0.06910518556833267,
      -0.056574396789073944,
      0.03060082532465458,
      0.05182429403066635,
      0.029711442068219185,
      -0.03100522607564926,
      0.05374988913536072,
      -0.03746893256902695,
      0.05794067680835724,
      0.007009034976363182,
      -0.07722941786050797,
      -0.062337979674339294,
      -0.01992284134030342,
      -0.01698259264230728,
      0.016442589461803436,
      0.052214115858078,
      -0.001683961134403944,
      0.019065698608756065,
      0.06681879609823227,
      0.06417150050401688,
      0.029300767928361893,
      0.006662679836153984,
      0.03499578312039375,
      -0.011242810636758804,
      -0.015548178926110268,
      -0.045435257256031036,
      0.016402678564190865,
      -0.016576459631323814,
      -0.07432907074689865,
      -0.005636195186525583,
      0.01637723669409752,
      -0.0003136010782327503,
      -0.10128035396337509,
      0.024306034669280052,
      -0.005341769196093082,
      0.018586020916700363,
      0.06880214810371399,
      0.06162618473172188,
      0.069402314722538,
      -0.021616153419017792,
      0.016747931018471718,
      0.010719053447246552,
      -0.07105334103107452,
      -0.016331424936652184,
      0.03529362007975578,
      -0.06386379897594452,
      -0.052207253873348236,
      -0.00539499381557107,
      -0.05644030123949051,
      0.03201306238770485,
      0.011732206679880619,
      -0.06138604134321213,
      -0.03572962060570717,
      0.07232514023780823,
      -0.00908183678984642,
      0.008156132884323597,
      0.07950040698051453,
      0.01853100210428238,
      -0.031004367396235466,
      -0.02926960587501526,
      -0.008399165235459805,
      0.031980689615011215,
      -0.023644523695111275,
      -0.05103384330868721,
      -0.10049469023942947,
      -0.011068821884691715,
      -0.018341628834605217,
      0.02098093181848526,
      -0.04120401293039322,
      0.01907302625477314,
      -0.016222232952713966,
      -0.08609630912542343,
      -0.021314170211553574,
      -0.021027877926826477,
      -0.013060074299573898,
      -0.05293035879731178,
      -0.005887758918106556,
      -0.07396182417869568,
      0.009937912225723267,
      0.10499856621026993,
      -0.04215610772371292,
      0.03525816276669502,
      0.07236868143081665,
      -0.016727805137634277,
      0.005342777818441391,
      -0.03625989705324173,
      -0.015878714621067047,
      0.029651755467057228,
      0.005564384162425995,
      -0.03931359201669693,
      -0.02908019721508026,
      0.038591399788856506,
      -0.04515540972352028,
      0.043576374650001526,
      -0.0014782502548769116,
      0.01779775135219097,
      0.03915238752961159,
      -0.09364218264818192,
      0.06547053158283234,
      -0.04937809333205223,
      0.005929984152317047,
      0.06632829457521439,
      0.05607934296131134,
      0.019321516156196594,
      -0.025317994877696037,
      0.061193469911813736,
      0.02406807243824005,
      0.013959802687168121,
      0.030074603855609894,
      -0.06592805683612823,
      -0.020144810900092125,
      -0.03376550227403641,
      -0.036546170711517334,
      0.03091079369187355,
      -0.025865469127893448,
      0.041660815477371216,
      -0.04247238487005234,
      -0.02445371448993683,
      0.05475684255361557,
      -0.07827190309762955,
      -0.010909629054367542,
      -0.12671887874603271,
      -0.05568023398518562,
      0.017608171328902245,
      0.04167851433157921,
      -0.015726996585726738,
      0.06093217805027962,
      0.03044874779880047,
      -0.06484002619981766,
      -0.011427996680140495,
      -0.0700891762971878,
      -0.011720477603375912,
      0.01881793886423111,
      -0.037766072899103165,
      -0.04651523754000664,
      -0.04001934453845024,
      0.045126836746931076,
      0.07029550522565842
    ],
    [
      -0.02512202598154545,
      4.978196375304833e-05,
      -0.052035510540008545,
      -0.051599033176898956,
      0.05947180092334747,
      -0.02377130649983883,
      0.022332848981022835,
      0.06022152304649353,
      -0.08121895045042038,
      -0.013651391491293907,
      -0.08339878171682358,
      -0.014020944014191628,
      0.039904382079839706,
      -0.08981607854366302,
      -0.03405974805355072,
      -0.00935946311801672,
      0.025322025641798973,
      -0.034661516547203064,
      0.01630495861172676,
      0.0046987589448690414,
      0.035023804754018784,
      -0.030650699511170387,
      -0.02778640203177929,
      0.026598069816827774,
      -0.06438246369361877,
      -0.03254258632659912,
      -0.04704864323139191,
      -0.001978573389351368,
      -0.07691852003335953,
      0.02928079105913639,
      0.04392767697572708,
      0.07034613937139511,
      -0.08276325464248657,
      0.0769900381565094,
      -0.046349842101335526,
      -0.012727717868983746,
      -0.05119248479604721,
      -0.0020458935759961605,
      -0.056354839354753494,
      0.008167656138539314,
      -0.039312735199928284,
      -0.028015613555908203,
      0.027879366651177406,
      -0.0016759264981374145,
      0.002828689757734537,
      0.014779235236346722,
      0.0024741676170378923,
      0.020255591720342636,
      0.06200462952256203,
      0.019138677045702934,
      0.033457428216934204,
      0.07597167044878006,
      0.025444023311138153,
      -0.047407116740942,
      0.02988169528543949,
      0.01069751288741827,
      -0.016188254579901695,
      0.031488288193941116,
      -0.02736791968345642,
      -0.05207914859056473,
      0.0652434304356575,
      0.033465705811977386,
      -0.012271801941096783,
      0.027912616729736328,
      0.033289242535829544,
      -0.07304786890745163,
      0.005613585002720356,
      0.07552420347929001,
      0.001361711765639484,
      0.05289718508720398,
      0.027028687298297882,
      0.038424961268901825,
      -0.1661781370639801,
      0.0414971299469471,
      -0.03386418893933296,
      0.02354894019663334,
      0.02764718420803547,
      0.030969364568591118,
      0.01067452784627676,
      0.11251343041658401,
      -0.011189543642103672,
      -0.03280026093125343,
      0.019061153754591942,
      -0.030773812904953957,
      0.00710513349622488,
      0.006813356652855873,
      -0.0019530679564923048,
      0.01409080158919096,
      0.0810215026140213,
      -0.04749160632491112,
      0.023935483768582344,
      0.039278898388147354,
      -0.03959806263446808,
      -0.0451027937233448,
      0.036295585334300995,
      0.015251937322318554,
      0.009942345321178436,
      0.002890748204663396,
      -0.04533977806568146,
      -0.0019854377023875713,
      0.010217665694653988,
      -0.013107487000524998,
      -0.04236011579632759,
      -0.034080348908901215,
      0.010869288817048073,
      -0.06556742638349533,
      0.008271615020930767,
      -0.02404416911303997,
      0.016057506203651428,
      0.023122692480683327,
      0.07821979373693466,
      0.035207606852054596,
      0.007323458790779114,
      -0.0012267236597836018,
      -0.06437388062477112,
      0.00024213246069848537,
      0.08579501509666443,
      0.07073239982128143,
      0.055385101586580276,
      0.09436135739088058,
      -0.0567215234041214,
      -0.013152976520359516,
      -0.022639041766524315,
      -0.008810261264443398,
      0.04501231759786606,
      0.0054522668942809105,
      -0.031649842858314514,
      0.0091411042958498,
      0.07281868904829025,
      -0.0024102411698549986,
      0.07463199645280838,
      -0.07796451449394226,
      -0.00873763207346201,
      0.005257216282188892,
      0.012547086924314499,
      -0.05866868793964386,
      -0.05786595493555069,
      -0.006098165642470121,
      -0.03126344457268715,
      0.017008058726787567,
      0.048511046916246414,
      -0.008645919151604176,
      -0.07059415429830551,
      0.03974783793091774,
      0.009087081998586655,
      0.03384346514940262,
      0.020310495048761368,
      -0.06525969505310059,
      -0.008341269567608833,
      -0.0015149988466873765,
      0.06251471489667892,
      -0.02161278761923313,
      0.004609659314155579,
      -0.04111015424132347,
      0.021472930908203125,
      0.0731550008058548,
      0.05452656373381615,
      0.0054509905166924,
      -0.09475728124380112,
      0.0078029935248196125,
      -0.02024928480386734,
      0.02366751804947853,
      0.0015422715805470943,
      0.042229361832141876,
      -0.022747181355953217,
      0.003958963323384523,
      0.009333379566669464,
      -0.027357859537005424,
      -0.06309617310762405,
      0.03546259552240372,
      0.024597445502877235,
      -0.007408233359456062,
      -0.03661075979471207,
      0.05031294748187065,
      -0.05169772356748581,
      -0.1027398556470871,
      0.012093394994735718,
      0.017806852236390114,
      0.0643356516957283,
      -0.05819796025753021,
      0.09705965965986252,
      0.05860574543476105,
      0.07404918968677521,
      0.0006103520281612873,
      0.009648670442402363,
      -0.04177703708410263,
      -0.010295666754245758,
      0.04899999871850014,
      -0.026566797867417336,
      -0.05798858404159546,
      -0.02946518361568451,
      -0.0447559729218483,
      -0.02886461280286312,
      0.005678785964846611,
      -0.06423525512218475,
      0.024591419845819473,
      0.05498657003045082,
      -0.02905714325606823,
      0.04079105332493782,
      -0.05460194870829582,
      -0.06744476407766342,
      0.07798552513122559,
      -0.013758789747953415,
      0.0007235537050291896,
      -0.034440889954566956,
      0.01906557008624077,
      -0.01213288027793169,
      0.0102076455950737,
      -0.008223005570471287,
      -0.045205257833004,
      -0.010335161350667477,
      -0.006769218947738409,
      -0.024104831740260124,
      -0.05082395300269127,
      -0.013416482135653496,
      -0.039792027324438095,
      -0.03680296242237091,
      0.005048240069299936,
      0.03893880546092987,
      0.04506423696875572,
      0.03858727589249611,
      0.0549362376332283,
      0.012316063046455383,
      -0.0278227049857378,
      -0.03129615634679794,
      -0.07468870282173157,
      -0.04184439033269882,
      0.014278850518167019,
      -0.049517493695020676,
      0.021446380764245987,
      -0.08960305154323578,
      0.009916785173118114,
      0.005268665961921215,
      -0.056850314140319824,
      -0.03184765577316284,
      -0.04283846914768219,
      0.014348265714943409,
      0.05647098645567894,
      -0.003068072721362114,
      -0.03478908911347389,
      0.007889722473919392,
      0.03208276629447937,
      -0.004717847798019648,
      -0.02693217061460018,
      -0.019308973103761673,
      0.0036498887930065393,
      0.0586821585893631,
      0.08740600943565369,
      0.011120202951133251,
      -0.0802435427904129,
      0.01401486899703741,
      -0.02879219688475132,
      0.06328921765089035,
      -0.005869570188224316,
      -0.012293681502342224,
      -0.01037001982331276,
      -0.07552114874124527,
      -0.015061366371810436,
      -0.0015621613711118698,
      0.010222758166491985,
      0.011142032220959663,
      0.018217019736766815,
      -0.03977657109498978,
      0.03662440925836563,
      -0.012123674154281616,
      -0.03660931810736656,
      -0.049978189170360565,
      0.007897445932030678,
      -0.07492896914482117,
      -0.0695992261171341,
      -0.014766464941203594,
      0.026340359821915627,
      0.09597452729940414,
      0.025083554908633232,
      -0.04781157523393631,
      -0.024711821228265762,
      0.0464850589632988,
      -0.04231756180524826,
      0.01295387465506792,
      0.01387912780046463,
      -0.04967285320162773,
      -0.10405579209327698,
      -0.07221736758947372,
      0.025057105347514153,
      0.0716734230518341,
      -0.004172653425484896,
      0.02939109317958355,
      -0.007866457104682922,
      -0.0057019805535674095,
      -0.0892299935221672,
      0.05401089787483215,
      0.032450150698423386,
      0.037138909101486206,
      0.026168767362833023,
      -0.00991503894329071,
      -0.09774242341518402,
      -0.04654044657945633,
      -0.06646867841482162,
      0.1353381872177124,
      -0.029277445748448372,
      -0.01705872267484665,
      -0.04995042458176613,
      -0.06576429307460785,
      0.08781417459249496,
      -0.03647631034255028,
      -0.02157967910170555,
      -0.025180116295814514,
      -0.008724476210772991,
      0.06705393642187119,
      0.032908402383327484,
      -0.026304058730602264,
      -0.04475131258368492,
      0.020439965650439262,
      0.047874387353658676,
      -0.01536193024367094,
      -0.10371218621730804,
      0.08639086782932281,
      0.00041028656414709985,
      -0.06162264943122864,
      -0.01957206428050995,
      0.03371710330247879,
      -0.0019489077385514975,
      0.007705529686063528,
      0.03899851068854332,
      0.023448288440704346,
      -0.08346324414014816,
      -0.015256601385772228,
      0.0519413985311985,
      0.012300251051783562,
      0.03156017139554024,
      -0.02925671823322773,
      -0.09121573716402054,
      0.024487819522619247,
      0.05709277465939522,
      0.047506097704172134,
      0.005975720938295126,
      -0.011633255518972874,
      0.026088902726769447,
      -0.003086363198235631,
      0.00016855161811690778,
      0.0035329959355294704,
      -0.09296977519989014,
      0.08282152563333511,
      0.025863489136099815,
      0.003478708676993847,
      -0.05515182018280029,
      0.10649945586919785,
      0.09427148103713989,
      0.06262964010238647,
      0.0030711127910763025,
      -0.005647869780659676,
      -0.04806678742170334,
      -0.05590914189815521,
      0.06555206328630447,
      -0.07387895882129669,
      0.0010451747803017497,
      -0.04725220426917076,
      -0.01814490184187889,
      -0.03353326395153999,
      -0.03506608307361603,
      -0.03313576057553291,
      -0.032653387635946274,
      -0.01782952807843685,
      -0.03952137008309364,
      0.026643967255949974,
      0.005626754369586706,
      0.018181806430220604,
      0.03753009811043739,
      0.018006281927227974,
      -0.028062785044312477,
      0.02013455517590046,
      -0.018541257828474045,
      -0.039115551859140396,
      0.006800257600843906,
      -0.027079498395323753,
      0.08891534060239792,
      -0.036994677037000656,
      0.00187423184979707,
      0.02086074836552143,
      -0.018013883382081985,
      -0.003624020144343376,
      -0.020052917301654816,
      -0.02419985458254814,
      0.008857524022459984,
      -0.03869646415114403,
      0.043313417583703995,
      0.010295214131474495,
      0.06535524129867554,
      0.07863675802946091,
      0.02844885364174843,
      -0.060506343841552734,
      0.04556194320321083,
      -0.02768511325120926,
      -0.023399021476507187,
      0.016091661527752876,
      -0.040757521986961365,
      -0.012683086097240448,
      0.036390576511621475,
      0.058596815913915634,
      -0.01900334656238556,
      0.08489052206277847,
      -0.051242657005786896,
      0.04712006449699402,
      -0.06048185005784035,
      -0.0323166586458683,
      -0.003544350154697895,
      0.05130038037896156,
      0.007414693012833595,
      0.005173381417989731,
      -0.056161679327487946,
      -0.02324838377535343,
      0.02374393306672573,
      -0.0024224920198321342,
      0.07146929204463959,
      0.02746579423546791,
      0.0064184218645095825,
      0.013235405087471008,
      -0.024214787408709526,
      0.02969653159379959,
      0.008773653768002987,
      0.011470143683254719,
      -0.06367290019989014,
      -0.0006150551489554346,
      -0.010602077469229698,
      -0.005398609675467014,
      0.05519196391105652,
      0.031868431717157364,
      -0.031109873205423355,
      0.014194775372743607,
      0.05131673067808151,
      -0.10408700257539749,
      -0.04804087430238724,
      0.03746822476387024,
      6.34883253951557e-05,
      0.11695714294910431,
      0.07265977561473846,
      -0.002431534230709076,
      -0.02947518229484558,
      0.03433539718389511,
      -0.06419820338487625,
      0.06929709017276764,
      0.044987838715314865,
      0.07421822100877762,
      0.024070043116807938,
      0.022179031744599342,
      0.07165385782718658,
      0.039336055517196655,
      0.027791820466518402,
      0.04586540162563324,
      0.027990439906716347,
      0.007850311696529388,
      -0.08331644535064697,
      -0.0267900712788105,
      0.014071696437895298,
      -0.010530101135373116,
      -0.0108141815289855,
      0.026846954599022865,
      0.006456297822296619,
      -0.0006716403877362609,
      -0.08726733177900314,
      0.02841886691749096,
      -0.04272058978676796,
      -0.03744567185640335,
      0.000838231120724231,
      0.04122381657361984,
      0.10975663363933563,
      0.0988030880689621,
      -0.026930244639515877,
      -0.1087476834654808,
      -0.03276727348566055,
      -0.05669061839580536,
      -0.024485116824507713,
      -0.0032497479114681482,
      -0.009200257249176502,
      -0.001657817861996591,
      -0.0017854213947430253,
      0.10986752808094025,
      0.03619430959224701,
      -0.010396361351013184,
      -0.02677447907626629,
      -0.006691008806228638,
      0.02779647521674633,
      0.08641897886991501,
      -0.0028306699823588133,
      0.07288745045661926,
      0.006282069254666567,
      0.0478680394589901,
      0.02776583656668663,
      0.04401727765798569,
      0.02580171450972557,
      -0.01254369132220745,
      -0.02271989732980728,
      -0.03864387050271034,
      -0.06480802595615387,
      -0.046307362616062164,
      -0.08113156259059906,
      0.051744818687438965,
      -0.09646058082580566,
      -0.055898137390613556,
      0.017046794295310974,
      -0.005723583046346903,
      -0.049548353999853134,
      -0.025236889719963074
    ],
    [
      0.07614877074956894,
      -0.02118971012532711,
      -0.09609372168779373,
      -0.09394671022891998,
      0.03967205807566643,
      0.03996793180704117,
      0.026476135477423668,
      -0.01051019225269556,
      -0.05142240971326828,
      0.0368550643324852,
      0.04289434850215912,
      -0.004125841427594423,
      0.0999598503112793,
      -0.003874135436490178,
      -0.009447534568607807,
      0.10270784050226212,
      0.001632611732929945,
      -0.06261499226093292,
      0.03818665072321892,
      -0.08261759579181671,
      0.046142615377902985,
      -0.04455773904919624,
      0.07319038361310959,
      -0.006882508285343647,
      -0.014970194548368454,
      0.05507998168468475,
      0.043269798159599304,
      0.0001565127313369885,
      0.013487151823937893,
      0.05596371740102768,
      -0.051800020039081573,
      0.0015246802940964699,
      -0.033495232462882996,
      -0.03236439824104309,
      0.045974962413311005,
      0.07533107697963715,
      0.011696222238242626,
      -0.062129586935043335,
      0.016153335571289062,
      -0.0524253249168396,
      -0.016653545200824738,
      -0.0021544513292610645,
      -0.024951143190264702,
      -0.04266355559229851,
      0.03381834179162979,
      0.06612635403871536,
      0.004622695501893759,
      0.00919615849852562,
      0.005225067026913166,
      -0.05679943040013313,
      -0.01728586107492447,
      -0.00553035456687212,
      -0.018480004742741585,
      0.022849462926387787,
      -0.06740963459014893,
      0.07096247375011444,
      0.016895491629838943,
      -0.010507815517485142,
      0.06241745129227638,
      -0.007450061850249767,
      0.025530854240059853,
      -0.03591671586036682,
      0.028559407219290733,
      -0.039525263011455536,
      -0.017164848744869232,
      -0.053824473172426224,
      -0.037639960646629333,
      -0.03186650574207306,
      -0.04479672759771347,
      0.02578377164900303,
      0.053650546818971634,
      0.003710794961079955,
      0.048290904611349106,
      0.02159958891570568,
      0.00359697500243783,
      0.00968434102833271,
      -0.005266673397272825,
      0.022112766280770302,
      -0.07473636418581009,
      0.016650261357426643,
      0.004845235962420702,
      -0.008233915083110332,
      0.06535714864730835,
      0.03675132244825363,
      -0.011872428469359875,
      -0.026492184028029442,
      0.01648559421300888,
      0.04430845007300377,
      -0.0004805206845048815,
      -0.00852550845593214,
      -0.016954293474555016,
      -0.08175493776798248,
      -0.06120813637971878,
      -0.0740646943449974,
      -0.037226490676403046,
      0.0047431630082428455,
      0.00586361950263381,
      0.03512544929981232,
      0.0013803215697407722,
      -0.06069120764732361,
      -0.025183795019984245,
      0.029726294800639153,
      -0.04123086482286453,
      -0.10204271972179413,
      0.013255557045340538,
      0.02370147593319416,
      -0.057344138622283936,
      0.05919777229428291,
      0.05987020954489708,
      0.12306533008813858,
      0.08852950483560562,
      -0.06575994938611984,
      -0.04049258306622505,
      -0.03916354477405548,
      0.10900157690048218,
      -0.0416378490626812,
      -0.024081328883767128,
      0.0035760265309363604,
      -0.010941498912870884,
      -0.021431971341371536,
      -0.08551571518182755,
      0.026743872091174126,
      0.010702179744839668,
      0.002885908354073763,
      0.020438754931092262,
      0.06875471025705338,
      -0.00964751373976469,
      0.027826376259326935,
      -0.004220376722514629,
      -0.015448915772140026,
      -0.013348507694900036,
      -0.054223038256168365,
      -0.020757289603352547,
      0.06312602758407593,
      -0.050299037247896194,
      0.010669655166566372,
      0.028176086023449898,
      0.041476067155599594,
      0.0167855154722929,
      0.13641756772994995,
      0.06385321170091629,
      0.044185783714056015,
      -0.061676256358623505,
      0.02692188136279583,
      -0.03654322773218155,
      -0.0316462367773056,
      0.13654035329818726,
      -0.007049429230391979,
      -0.05848921835422516,
      -0.03940508887171745,
      -0.014296004548668861,
      0.10585186630487442,
      -0.10088765621185303,
      -0.010635058395564556,
      0.03268320485949516,
      0.06449026614427567,
      0.0016498471377417445,
      0.024952443316578865,
      -0.04240287095308304,
      -0.010297993198037148,
      0.058191053569316864,
      0.036383260041475296,
      0.06195691227912903,
      0.0317208468914032,
      -0.02376646362245083,
      0.0180707648396492,
      0.04999219998717308,
      -0.004291160963475704,
      -0.023863201960921288,
      0.08672159910202026,
      -0.052360180765390396,
      0.08676952123641968,
      0.018772726878523827,
      0.015221720561385155,
      -0.01659955270588398,
      0.03578523173928261,
      -0.0015259624924510717,
      -0.006117858458310366,
      0.025925926864147186,
      0.016296757385134697,
      0.021490197628736496,
      -0.049372848123311996,
      -0.006396307609975338,
      -0.03579820320010185,
      0.014164828695356846,
      -0.0849900171160698,
      -0.027844127267599106,
      0.040249887853860855,
      -0.0032821837812662125,
      -0.017183493822813034,
      -0.01570127345621586,
      -0.00849184114485979,
      -0.046829503029584885,
      0.054398439824581146,
      0.01731754094362259,
      -0.08697957545518875,
      0.013144041411578655,
      0.01495397835969925,
      0.055397313088178635,
      0.005754027049988508,
      -0.017504792660474777,
      -0.049779344350099564,
      0.033330969512462616,
      0.040657367557287216,
      0.053139761090278625,
      0.01878514140844345,
      0.03362014517188072,
      0.001875129877589643,
      -0.025645915418863297,
      -0.04035476967692375,
      0.011422974057495594,
      0.06995266675949097,
      -0.011600993573665619,
      -0.046599555760622025,
      -0.021232176572084427,
      -0.00830631423741579,
      -0.009933309629559517,
      0.024878812953829765,
      0.003545326180756092,
      -0.029150404036045074,
      -0.04138524457812309,
      -0.03756672143936157,
      -0.010194587521255016,
      0.000884926994331181,
      -0.0021801816765218973,
      0.013148329220712185,
      0.03525082394480705,
      -0.03364930674433708,
      0.01867038384079933,
      -0.0921388491988182,
      -0.042338378727436066,
      0.004806258250027895,
      -0.08658120781183243,
      0.017035681754350662,
      -0.02723962813615799,
      0.05518965795636177,
      0.03387603908777237,
      -0.0787249505519867,
      -0.0026509445160627365,
      -0.012979942373931408,
      -0.02992713637650013,
      -0.024512387812137604,
      -0.024843325838446617,
      -0.04479746147990227,
      0.04665282368659973,
      0.025487754493951797,
      -0.1041606143116951,
      0.019013147801160812,
      0.061584796756505966,
      -0.014060948975384235,
      -0.008400250226259232,
      -0.08792397379875183,
      -0.09168808162212372,
      0.035630352795124054,
      0.041098203510046005,
      -0.00401834724470973,
      -0.037086617201566696,
      0.0005014591151848435,
      -0.06000281497836113,
      0.010692374780774117,
      -0.02096862904727459,
      0.014843855053186417,
      0.04929749295115471,
      0.04399735480546951,
      0.008983394131064415,
      0.04649243503808975,
      -0.028426706790924072,
      -0.04572789743542671,
      0.015000357292592525,
      -0.016327297315001488,
      -0.053896088153123856,
      -0.05425994470715523,
      -0.004214474931359291,
      -0.03203382343053818,
      -0.023127468302845955,
      0.041605617851018906,
      -0.06394834071397781,
      0.0530659444630146,
      0.04221029952168465,
      -0.03077143244445324,
      -0.014781197533011436,
      -0.07526907324790955,
      0.06963281333446503,
      -0.013799133710563183,
      0.03242538496851921,
      0.00042189907981082797,
      -0.031029419973492622,
      -0.009208879433572292,
      -0.011376475915312767,
      -0.05839253589510918,
      0.028780823573470116,
      0.0028396390844136477,
      -0.02955177053809166,
      -0.055314164608716965,
      -0.02838880382478237,
      -0.03768424317240715,
      -0.013750331476330757,
      0.04814218357205391,
      0.0516359880566597,
      -0.013435217551887035,
      0.01983799785375595,
      -0.03408785164356232,
      0.014642768539488316,
      0.0618269182741642,
      0.06281951069831848,
      -0.0023944037966430187,
      0.0068878717720508575,
      -0.0174199678003788,
      0.004309101030230522,
      0.039521317929029465,
      -0.026003245264291763,
      0.04067474231123924,
      -0.03837694227695465,
      -0.04769999533891678,
      -0.018997741863131523,
      -0.013408628292381763,
      0.08151523768901825,
      -0.0523359440267086,
      -0.054157938808202744,
      -0.02768663503229618,
      -0.018970772624015808,
      0.016689330339431763,
      0.015182273462414742,
      -0.024207381531596184,
      -0.005863529164344072,
      -0.019646624103188515,
      -0.03996821492910385,
      -0.03952043876051903,
      0.01507961843162775,
      -0.017012765631079674,
      -0.010504675097763538,
      -0.012146344408392906,
      0.0531473308801651,
      -0.07553579658269882,
      0.001407339470461011,
      0.06547553092241287,
      -0.007232504431158304,
      0.04071241244673729,
      0.0037790979258716106,
      -0.014256170950829983,
      -0.04175262898206711,
      0.05296427756547928,
      0.04028734192252159,
      0.02352084591984749,
      0.023186203092336655,
      -0.044088467955589294,
      -0.032735008746385574,
      0.060847215354442596,
      0.0011877755168825388,
      0.020655281841754913,
      -0.012876218184828758,
      -0.018543293699622154,
      -0.09492287784814835,
      -0.0008295619045384228,
      0.06658016890287399,
      -0.06267950683832169,
      -0.011319843120872974,
      0.003466562833636999,
      0.009052827954292297,
      0.027971627190709114,
      -0.017535602673888206,
      -0.028411006554961205,
      -0.012412168085575104,
      -0.003465297631919384,
      0.005150125361979008,
      0.05521849915385246,
      0.04114999249577522,
      -0.013890276663005352,
      0.08987405896186829,
      -0.05611848831176758,
      0.047571174800395966,
      0.02504878118634224,
      0.02336597815155983,
      0.06324288249015808,
      0.010281519964337349,
      -0.032874006778001785,
      0.03494055196642876,
      -0.006616700440645218,
      -0.03395899385213852,
      0.03676863759756088,
      0.019747193902730942,
      -0.05796103551983833,
      0.02411377802491188,
      0.00834509264677763,
      -0.004410105757415295,
      0.038098037242889404,
      -0.01548335887491703,
      -0.0013587126741185784,
      -0.05559181049466133,
      -0.0771757960319519,
      0.007992012426257133,
      0.06450216472148895,
      0.07443476468324661,
      -0.023924222216010094,
      0.08730509132146835,
      -0.02110806666314602,
      -0.006688855122774839,
      0.0914984941482544,
      -0.012121888808906078,
      -0.054811231791973114,
      -0.04112200811505318,
      0.038329243659973145,
      0.049794360995292664,
      0.057098329067230225,
      -0.04802627116441727,
      -0.0013024496147409081,
      0.04889918118715286,
      0.09134659171104431,
      0.011529900133609772,
      0.013726061210036278,
      0.058516889810562134,
      0.023592349141836166,
      0.010130672715604305,
      -0.011384392157196999,
      0.0211174339056015,
      0.007819290272891521,
      -0.008792242035269737,
      0.026902735233306885,
      -0.0289163775742054,
      -0.035669904202222824,
      -0.023108361288905144,
      -0.05691681429743767,
      0.006200969219207764,
      0.020598534494638443,
      -0.011908511631190777,
      0.05323101207613945,
      0.01036147866398096,
      -0.0022753251250833273,
      0.054644208401441574,
      0.03982248529791832,
      0.04034436121582985,
      -0.024136221036314964,
      0.04499301314353943,
      -0.10599040240049362,
      0.05836157128214836,
      0.0020647754427045584,
      -0.05645830184221268,
      -0.020418457686901093,
      -0.015267056412994862,
      -0.02028988115489483,
      -0.012255124747753143,
      -0.023191822692751884,
      -0.02332657016813755,
      0.013542942702770233,
      0.038437988609075546,
      0.05156581848859787,
      -0.11795539408922195,
      0.03944690153002739,
      -0.019073493778705597,
      -0.1576114296913147,
      0.01009872555732727,
      0.09494900703430176,
      0.15307487547397614,
      -0.08580081164836884,
      -0.03904714807868004,
      -0.04522474482655525,
      0.029314348474144936,
      -0.025840885937213898,
      -0.018454018980264664,
      0.018389664590358734,
      -0.02960561029613018,
      -0.023811958730220795,
      -0.04164088889956474,
      0.10554754734039307,
      -0.01812845468521118,
      -0.07732228189706802,
      0.07484514266252518,
      -0.00910312682390213,
      -0.05064975097775459,
      -0.007555910386145115,
      0.02327689342200756,
      0.0029234064277261496,
      -0.012869451195001602,
      -0.031039439141750336,
      -0.04086430370807648,
      -0.005308485124260187,
      0.08165088295936584,
      -0.04917365685105324,
      -0.01566341519355774,
      0.021765807643532753,
      0.029587801545858383,
      0.060698121786117554,
      -0.04102444276213646,
      0.013342849910259247,
      0.06229427456855774,
      -0.024024972692131996,
      0.05634221062064171,
      -0.012154454365372658,
      -0.05090093985199928,
      -0.02394603006541729,
      -0.013356911949813366,
      -0.03562093898653984,
      -0.03748280182480812,
      0.02688746154308319,
      -0.02142186649143696,
      0.011602291837334633,
      -0.00028622595709748566,
      0.09023996442556381,
      0.05335777997970581,
      0.04460852965712547,
      0.004310776013880968,
      0.01190988253802061,
      0.07281797379255295
    ],
    [
      0.014643501490354538,
      -0.014033357612788677,
      0.04258313775062561,
      -0.08695267885923386,
      0.007176308427006006,
      0.07256849110126495,
      -0.09894125908613205,
      -0.041770946234464645,
      0.026955286040902138,
      -0.03137989342212677,
      -0.008121234364807606,
      -0.032846301794052124,
      0.055468685925006866,
      -0.09506533294916153,
      0.018230760470032692,
      0.02135191112756729,
      -0.02923322468996048,
      -0.056115876883268356,
      -0.05408424884080887,
      0.011340156197547913,
      -0.024021269753575325,
      -0.03032875433564186,
      -0.027171483263373375,
      0.05189397558569908,
      -0.01067905779927969,
      0.02817358821630478,
      0.08191537857055664,
      -0.0011958092218264937,
      -0.04602954164147377,
      -0.01928560435771942,
      0.058523740619421005,
      -0.007569645531475544,
      0.02947411872446537,
      -0.06005676090717316,
      -0.14562933146953583,
      0.04517822340130806,
      0.1431789994239807,
      0.03063928335905075,
      0.02064436487853527,
      -0.024647638201713562,
      -0.01581617258489132,
      0.007816290482878685,
      0.049567677080631256,
      -0.05980103835463524,
      0.0008705414365977049,
      0.04095269739627838,
      -0.0032415022142231464,
      0.0583665631711483,
      -0.023142311722040176,
      -0.015078660100698471,
      -0.05618180334568024,
      0.02291695587337017,
      -0.016691366210579872,
      6.393690273398533e-05,
      0.01171908713877201,
      0.018302179872989655,
      -0.0018861116841435432,
      -0.041669588536024094,
      -0.026476237922906876,
      0.0008830932783894241,
      -0.06182437762618065,
      -0.032348837703466415,
      -0.01351192407310009,
      -0.00910562090575695,
      -0.00724396575242281,
      -0.04113040119409561,
      0.08861305564641953,
      -0.0020220810547471046,
      -0.001225678832270205,
      0.09669432044029236,
      -0.07524104416370392,
      0.03362971544265747,
      -0.05117647722363472,
      0.014655531384050846,
      0.004050882998853922,
      -0.03515513986349106,
      0.08443160355091095,
      0.02287549525499344,
      -0.08203598111867905,
      0.04700430482625961,
      0.09342280775308609,
      0.026041850447654724,
      0.02674596570432186,
      0.009181949310004711,
      -0.04745153337717056,
      0.015063184313476086,
      0.05076663941144943,
      -0.00616630120202899,
      -0.038897719234228134,
      -0.018524978309869766,
      -0.03865426033735275,
      0.08616865426301956,
      0.05305885896086693,
      0.0015565947396680713,
      0.06491034477949142,
      -0.05267556011676788,
      -0.03896759822964668,
      0.004623415879905224,
      0.03330420330166817,
      0.05014173686504364,
      -0.025046372786164284,
      -0.005385639611631632,
      -0.01812046207487583,
      -0.04189297929406166,
      -0.06016570329666138,
      0.035635728389024734,
      0.0178216602653265,
      -0.010599126107990742,
      -0.03235720098018646,
      -0.055582232773303986,
      0.06505554169416428,
      0.013806669041514397,
      -0.08485294133424759,
      0.015811866149306297,
      0.039074674248695374,
      0.0606757327914238,
      0.01464652456343174,
      0.04375682771205902,
      0.07599916309118271,
      -0.011337442323565483,
      -0.11894003301858902,
      0.02578052692115307,
      0.05593021959066391,
      -0.058047205209732056,
      -0.007543246261775494,
      0.007956353947520256,
      0.053592994809150696,
      -0.010304190218448639,
      -0.016947321593761444,
      -0.09492769092321396,
      -0.035194266587495804,
      -0.035943832248449326,
      -0.11920826882123947,
      0.019603846594691277,
      0.015899935737252235,
      -0.05437670275568962,
      0.024634521454572678,
      -0.011339501477777958,
      -0.03263723477721214,
      0.09480572491884232,
      0.005329891107976437,
      -0.03751307725906372,
      -0.03942374885082245,
      0.05760278180241585,
      0.011167130433022976,
      0.04899049550294876,
      0.033990923315286636,
      -0.02269432134926319,
      0.02753530442714691,
      0.013048334047198296,
      -0.12179700285196304,
      0.014026242308318615,
      -0.009074721485376358,
      -0.014810103923082352,
      -0.00022258968965616077,
      0.06683439761400223,
      0.01527788769453764,
      0.012160799466073513,
      0.051023535430431366,
      -0.001176153658889234,
      0.03279862552881241,
      0.020802024751901627,
      0.04365035519003868,
      0.028529126197099686,
      0.03358516842126846,
      0.004562930203974247,
      -0.06375034898519516,
      0.07286465913057327,
      0.04113493487238884,
      0.018954185768961906,
      0.002289190422743559,
      -0.08639498054981232,
      0.030065899714827538,
      0.012916660867631435,
      0.03693413734436035,
      -0.06952747702598572,
      0.02591986581683159,
      -0.0516631081700325,
      0.028052691370248795,
      -0.007672534324228764,
      -0.055653296411037445,
      -0.05439732223749161,
      -0.007794889155775309,
      -0.011794913560152054,
      -0.04689735546708107,
      0.009694594889879227,
      0.001635527703911066,
      -0.11285349726676941,
      0.03222262114286423,
      -0.03305571526288986,
      -0.021429548040032387,
      -0.007253578398376703,
      -0.05679380148649216,
      0.021817471832036972,
      -0.01110454648733139,
      -0.023405879735946655,
      -0.041850000619888306,
      -0.0094978716224432,
      0.12690657377243042,
      -0.03835000470280647,
      -0.035672131925821304,
      -0.012409643270075321,
      0.01565583050251007,
      -0.07226478308439255,
      0.026080649346113205,
      -0.15826305747032166,
      -0.004531854297965765,
      0.01025021355599165,
      -0.03703438490629196,
      -0.008507847785949707,
      0.03973068296909332,
      -0.06706258654594421,
      -0.12287353724241257,
      0.050434667617082596,
      0.012954864650964737,
      -0.08474589139223099,
      -0.03142644464969635,
      -0.03128562122583389,
      0.05904608592391014,
      0.03095228597521782,
      -0.03175139054656029,
      -0.031072743237018585,
      -0.03239654004573822,
      -0.10277431458234787,
      -0.029291406273841858,
      0.017878476530313492,
      -0.029870063066482544,
      -0.013994507491588593,
      -0.0005721950437873602,
      -0.07395105808973312,
      0.08296043425798416,
      0.10616691410541534,
      -0.05173368752002716,
      -0.07228156179189682,
      0.06891675293445587,
      -0.03429326042532921,
      -0.04722350090742111,
      0.03895306587219238,
      0.03948535397648811,
      0.07186108827590942,
      0.07707083225250244,
      0.01762297935783863,
      -0.11761841922998428,
      -0.0021169886458665133,
      -0.01756257563829422,
      0.048592787235975266,
      -0.06598079204559326,
      0.01677982695400715,
      -0.04593222960829735,
      0.00810396671295166,
      -0.0956663191318512,
      -0.14314427971839905,
      0.03229507803916931,
      0.02136111818253994,
      0.005347629077732563,
      0.022498158738017082,
      -0.046233389526605606,
      0.010868459939956665,
      -0.007442119065672159,
      -0.04546608403325081,
      -0.0465659499168396,
      -0.14012722671031952,
      0.023215943947434425,
      0.05450515076518059,
      -0.07048015296459198,
      -0.01019712071865797,
      -0.04119528830051422,
      -0.07463879883289337,
      0.002564305905252695,
      0.03619149327278137,
      -0.04476260766386986,
      -0.00881454348564148,
      -0.08389171957969666,
      -0.07920616865158081,
      -0.010003958828747272,
      0.022830622270703316,
      0.0339045450091362,
      0.03278175741434097,
      -0.017815804108977318,
      -0.023772498592734337,
      -0.07260624319314957,
      -0.007759465370327234,
      -0.019742604345083237,
      -0.05152251943945885,
      0.01781884953379631,
      0.011247692629694939,
      -0.01922227256000042,
      -0.05986965447664261,
      0.003329272847622633,
      0.01292250957340002,
      -0.08330359309911728,
      -0.035873379558324814,
      0.0023423070088028908,
      -0.03486539423465729,
      0.02562078647315502,
      0.04295714199542999,
      0.05378567799925804,
      0.03265087306499481,
      -0.01941199228167534,
      -0.04060441255569458,
      -0.05751825496554375,
      0.01016602385789156,
      -0.04978477582335472,
      0.05994736775755882,
      -0.10351289063692093,
      -0.025836726650595665,
      0.02372068725526333,
      0.0036255591548979282,
      0.0208218302577734,
      0.04881381615996361,
      -0.06052097678184509,
      -0.014285453595221043,
      0.04872183874249458,
      -0.10025954246520996,
      -0.006916243117302656,
      0.06817924231290817,
      0.046507321298122406,
      -0.03228863328695297,
      -0.024614013731479645,
      -0.007147751748561859,
      0.07199640572071075,
      -0.01101404707878828,
      0.004566018935292959,
      0.04965583607554436,
      0.027178864926099777,
      0.037024661898612976,
      -0.09094534069299698,
      -0.04205210506916046,
      0.03671249374747276,
      -0.0343826599419117,
      0.05572470650076866,
      -0.000617855170276016,
      -0.02957676164805889,
      0.07539333403110504,
      0.03896225988864899,
      0.012979581952095032,
      0.0694936066865921,
      0.07693302631378174,
      -0.014117171056568623,
      -0.02685835026204586,
      -0.03354813903570175,
      0.019409652799367905,
      -0.03307322412729263,
      -0.03221774473786354,
      0.002137509873136878,
      -0.03911980241537094,
      0.03873956575989723,
      -0.02749141864478588,
      0.056731779128313065,
      0.044370654970407486,
      -0.014102554880082607,
      0.05225589498877525,
      -0.022885914891958237,
      -0.0646626353263855,
      -0.025139054283499718,
      -0.03543388843536377,
      0.01617872156202793,
      0.051360346376895905,
      0.016143620014190674,
      0.07827842980623245,
      -0.029971837997436523,
      -0.05876387655735016,
      -0.049820125102996826,
      0.04562695324420929,
      -0.030951542779803276,
      0.04524599015712738,
      0.006455620750784874,
      -0.017768284305930138,
      0.0008875696221366525,
      0.01480000652372837,
      0.028357766568660736,
      -0.05324317142367363,
      0.0021992663387209177,
      0.036283329129219055,
      -0.07575520128011703,
      0.04438392072916031,
      0.08637378364801407,
      -0.04893605038523674,
      0.05559832230210304,
      0.02191660925745964,
      0.03097703494131565,
      0.003656806657090783,
      0.031546663492918015,
      -0.06892421841621399,
      -0.018094325438141823,
      0.09415409713983536,
      0.021266262978315353,
      0.028776289895176888,
      -0.009248718619346619,
      0.07257996499538422,
      0.004956414457410574,
      0.005452113226056099,
      0.05499785766005516,
      -0.0342303104698658,
      0.05339678376913071,
      -0.010098569095134735,
      0.00045129406498745084,
      0.012880226597189903,
      -0.11269798874855042,
      0.00329119386151433,
      0.01017659343779087,
      0.01666354201734066,
      0.03386401757597923,
      0.06838665157556534,
      -0.051091570407152176,
      -0.03773590549826622,
      -0.02323020249605179,
      0.04550887644290924,
      0.06902890652418137,
      0.020205123350024223,
      -0.003456680802628398,
      -0.03724730759859085,
      -0.06425533443689346,
      0.0005374066531658173,
      0.0612013004720211,
      -0.06751241534948349,
      -0.02379447966814041,
      0.03376035392284393,
      0.03404076397418976,
      0.0660797506570816,
      0.013795049861073494,
      0.03325560316443443,
      0.036473847925662994,
      -0.024593135342001915,
      -0.016738247126340866,
      -0.0043178643099963665,
      -0.08355110138654709,
      -0.03393767774105072,
      0.08166476339101791,
      0.05760530009865761,
      0.08882177621126175,
      0.023899434134364128,
      0.008368630893528461,
      -0.011133362539112568,
      -0.006178479641675949,
      0.00785685982555151,
      -0.06936436146497726,
      0.007959007285535336,
      -0.015932826325297356,
      0.032534003257751465,
      0.009933264926075935,
      0.031483396887779236,
      -0.0901721939444542,
      -0.07025567442178726,
      -0.030447673052549362,
      -0.00936442706733942,
      -0.039666250348091125,
      0.04363471642136574,
      -0.03275303915143013,
      0.03806726634502411,
      0.013063664548099041,
      -0.03963864967226982,
      -0.0034448835067451,
      0.05500262975692749,
      -0.008553936146199703,
      0.02013198658823967,
      -0.018358249217271805,
      -0.029498634859919548,
      -0.049513451755046844,
      -0.01631731539964676,
      -0.03811126574873924,
      -0.011199979111552238,
      0.024464648216962814,
      0.01987438090145588,
      -0.060148172080516815,
      0.027393564581871033,
      0.07943390309810638,
      0.022758346050977707,
      -0.04408043250441551,
      -0.04255736246705055,
      0.0012008697958663106,
      -0.07805415987968445,
      -0.01351273525506258,
      0.05689108744263649,
      0.013622986152768135,
      0.036226850003004074,
      -0.020189113914966583,
      0.0059392270632088184,
      -0.10644151270389557,
      0.07260257005691528,
      -0.01686893031001091,
      -0.055262595415115356,
      0.08404511958360672,
      0.018168268725275993,
      -0.0344817116856575,
      0.02016032487154007,
      0.00036102355807088315,
      0.0006156002054922283,
      -0.012758214958012104,
      0.06644934415817261,
      0.02164428122341633,
      -0.030896015465259552,
      -0.042051780968904495,
      -0.005794432479888201,
      0.03951652720570564,
      -0.04067360237240791,
      0.008704278618097305,
      -0.08886923640966415,
      -0.0037066976074129343,
      -0.024534620344638824,
      0.024498621001839638,
      0.03853846713900566,
      -0.029847348108887672
    ],
    [
      0.06145649403333664,
      0.003837522119283676,
      0.06762442737817764,
      -0.1027102991938591,
      0.059674330055713654,
      -0.0019339191494509578,
      -0.02363571710884571,
      0.010684234090149403,
      -0.08341412246227264,
      0.08344513177871704,
      -0.01166571956127882,
      -0.019913189113140106,
      -0.008624247275292873,
      0.06463725119829178,
      -0.002600058913230896,
      0.02140979841351509,
      -0.029069140553474426,
      -0.031176142394542694,
      -0.03805724158883095,
      0.038035597652196884,
      -0.03074372559785843,
      0.049076054245233536,
      -0.010694888420403004,
      -0.03542879968881607,
      0.02946009859442711,
      0.0038882785011082888,
      0.002652134047821164,
      0.0010607718722894788,
      -0.048130497336387634,
      -0.009961975738406181,
      -0.03886375576257706,
      -0.003784002037718892,
      0.05085808038711548,
      -0.03884422034025192,
      -0.05045977607369423,
      0.027994247153401375,
      -0.05660812556743622,
      0.01530641783028841,
      0.022189894691109657,
      0.007411832921206951,
      -0.02644239366054535,
      -0.017740311101078987,
      0.011068807914853096,
      -0.013316718861460686,
      0.011237263679504395,
      0.012352729216217995,
      0.04535750299692154,
      -0.0035138949751853943,
      0.044029638171195984,
      0.0018459578277543187,
      -0.030628062784671783,
      0.04975269362330437,
      0.03717245161533356,
      0.047691959887742996,
      0.03883158043026924,
      -0.0034771463833749294,
      -0.00963680911809206,
      0.02699790708720684,
      0.1301092952489853,
      -0.062007222324609756,
      0.043220940977334976,
      0.005108603276312351,
      -0.022571934387087822,
      0.043110162019729614,
      0.022469889372587204,
      0.013979103416204453,
      -0.027526965364813805,
      -0.007593180984258652,
      0.015928898006677628,
      0.01181628555059433,
      0.06038200855255127,
      -0.0745566114783287,
      -0.00725354952737689,
      -0.024906359612941742,
      0.03771795332431793,
      -0.04396964982151985,
      0.07886794954538345,
      0.03777890279889107,
      -0.05789671465754509,
      0.06378193199634552,
      0.014129463583230972,
      0.02228059247136116,
      -0.07137713581323624,
      -0.044735852628946304,
      -0.005164385307580233,
      -0.011427653953433037,
      0.027025049552321434,
      -0.04359743744134903,
      -0.07499745488166809,
      -0.00974674429744482,
      -0.08644198626279831,
      0.06652174144983292,
      0.03635585308074951,
      -0.07841753214597702,
      -0.06633558869361877,
      0.011518647894263268,
      0.030567592009902,
      0.015019867569208145,
      0.05112346261739731,
      0.018156111240386963,
      -0.09794427454471588,
      -0.07375771552324295,
      -0.04580885171890259,
      -0.04929400980472565,
      -0.05251225456595421,
      -0.04352390021085739,
      -0.03173897787928581,
      -0.0957534983754158,
      0.06282088160514832,
      -0.025648288428783417,
      0.12766706943511963,
      -0.012511719949543476,
      -0.00894031673669815,
      0.007602724712342024,
      -0.010441292077302933,
      0.03750742971897125,
      0.03486236184835434,
      -0.04588477313518524,
      0.011148589663207531,
      0.03146967664361,
      1.884291850728914e-05,
      -0.007766439113765955,
      -0.02240622602403164,
      -0.04362921044230461,
      -0.003276070114225149,
      -0.008267117664217949,
      0.0023476011119782925,
      0.022726841270923615,
      0.03953314200043678,
      -0.05203006789088249,
      -0.0028127445839345455,
      -0.03096848912537098,
      -0.06256814301013947,
      -0.023712124675512314,
      0.051461584866046906,
      0.024388346821069717,
      -0.08030299097299576,
      0.07396113127470016,
      -0.001610368024557829,
      0.02837349660694599,
      0.10705561935901642,
      -0.015484144911170006,
      -0.029259109869599342,
      0.03818533942103386,
      -0.039135612547397614,
      -0.03434477746486664,
      -0.06822547316551208,
      -0.01619771309196949,
      -0.06111261993646622,
      0.02072642557322979,
      -0.03874113783240318,
      0.048901502043008804,
      0.032722584903240204,
      0.06569208949804306,
      -0.023430023342370987,
      -0.05710151419043541,
      -0.026228249073028564,
      -0.004290945362299681,
      0.021871324628591537,
      -0.03955630213022232,
      0.012393632903695107,
      -0.02129056118428707,
      0.0979536697268486,
      0.014032754115760326,
      0.05488282069563866,
      0.024294015020132065,
      0.014135856181383133,
      -0.02836434170603752,
      0.002944414969533682,
      0.00395562406629324,
      -0.0013969101710245013,
      0.03145855665206909,
      -0.013231283985078335,
      -0.018583524972200394,
      0.061109866946935654,
      0.031006431207060814,
      0.03093593567609787,
      -0.06258909404277802,
      -0.012393949553370476,
      -0.025630570948123932,
      0.0108787314966321,
      -0.05233066529035568,
      0.04184286296367645,
      -0.028635242953896523,
      -0.061877451837062836,
      -0.011769955977797508,
      -0.016911594197154045,
      0.027654649689793587,
      0.01064013596624136,
      0.0059487223625183105,
      -0.006769934669137001,
      0.06953825056552887,
      -0.016462767496705055,
      0.04504924267530441,
      0.06578953564167023,
      -0.017148010432720184,
      -0.12148445844650269,
      0.03543237969279289,
      0.04472803324460983,
      -0.007729001808911562,
      0.01715731807053089,
      -0.03132941573858261,
      -0.05438462644815445,
      -0.03435423597693443,
      0.053808219730854034,
      -0.0011927892919629812,
      -0.01676982082426548,
      0.011712472885847092,
      0.05340208485722542,
      0.007172563578933477,
      -0.002147228457033634,
      0.00020199727441649884,
      -0.04471907392144203,
      -0.01203135959804058,
      0.027080023661255836,
      -0.01957293599843979,
      0.0366416871547699,
      -0.046233613044023514,
      0.0034562074579298496,
      0.05649176612496376,
      0.009940307587385178,
      -0.027179431170225143,
      0.01466440875083208,
      -0.016348859295248985,
      0.0036775984335690737,
      0.04668671265244484,
      0.06467735022306442,
      -0.01587681844830513,
      -0.03376451134681702,
      0.021232830360531807,
      0.03787601366639137,
      -0.014951452612876892,
      -0.05648515000939369,
      -0.10526671260595322,
      0.015931885689496994,
      -0.04437495768070221,
      0.028672022745013237,
      -0.016396401450037956,
      0.047463223338127136,
      0.03308272361755371,
      0.03430487588047981,
      -0.05522153154015541,
      0.017071915790438652,
      0.08394981920719147,
      0.0642656609416008,
      0.02148323319852352,
      -0.011583683080971241,
      0.0494784340262413,
      0.00803048349916935,
      -0.015582703985273838,
      -0.014737788587808609,
      0.007907425984740257,
      -0.0709160640835762,
      -0.002323227236047387,
      0.05444028228521347,
      0.03815064951777458,
      0.02033756487071514,
      0.0124749094247818,
      0.08175720274448395,
      -0.03082597814500332,
      -0.03928342089056969,
      -0.07481835782527924,
      0.02844844199717045,
      -0.07398930191993713,
      0.005550859030336142,
      0.04337901249527931,
      -0.005123271141201258,
      -0.008943865075707436,
      -0.01099808793514967,
      0.013387569226324558,
      -0.08136754482984543,
      -0.09939780831336975,
      0.012556362897157669,
      0.04064321890473366,
      -0.05179133638739586,
      0.026547882705926895,
      0.0632835254073143,
      -0.019500715658068657,
      -0.03838276490569115,
      0.04329284653067589,
      0.029374564066529274,
      -0.0006741019897162914,
      -0.05399953946471214,
      0.05933208018541336,
      0.08438932150602341,
      -0.04982730746269226,
      -0.00289905141107738,
      -0.05882427841424942,
      0.02330925129354,
      0.010872762650251389,
      0.0016988718416541815,
      0.03181469440460205,
      -0.01146399974822998,
      0.01550968736410141,
      0.09173887223005295,
      -0.008535808883607388,
      -0.041916776448488235,
      0.07722317427396774,
      -0.050570372492074966,
      0.06498114764690399,
      0.019861623644828796,
      -0.05614624544978142,
      -0.008045867085456848,
      0.0037084855139255524,
      -0.05243837460875511,
      0.00651260931044817,
      -0.008548105135560036,
      -0.06053066998720169,
      0.012540582567453384,
      -0.004454231821000576,
      -0.030787859112024307,
      0.01651267521083355,
      0.056646160781383514,
      -0.024180563166737556,
      -0.08857975155115128,
      0.0190461166203022,
      0.0009606807725504041,
      -0.0006122211343608797,
      0.0037242539692670107,
      -0.003759445156902075,
      -0.010112756863236427,
      0.02528351917862892,
      -0.005546917673200369,
      -0.023596137762069702,
      0.0384691096842289,
      0.06677067279815674,
      -0.04960928112268448,
      0.013505233451724052,
      0.012799911201000214,
      -0.02223159372806549,
      -0.01592070423066616,
      0.08143267035484314,
      -0.01824280060827732,
      0.025800440460443497,
      -0.03843022510409355,
      0.026612786576151848,
      0.007290720008313656,
      0.031682662665843964,
      -0.04615096002817154,
      -0.05826031044125557,
      -0.04539075493812561,
      0.05583493411540985,
      -0.003586218925192952,
      -0.052277710288763046,
      0.007201462052762508,
      -0.05643456056714058,
      0.07028157263994217,
      0.04350964352488518,
      -0.06331376731395721,
      -0.07991912215948105,
      -0.03271295875310898,
      -0.0998106449842453,
      -0.02209794521331787,
      0.029290931299328804,
      -0.06714477390050888,
      -0.02132350020110607,
      -0.06135992333292961,
      0.020143592730164528,
      -0.031106706708669662,
      -0.045963335782289505,
      0.006629451643675566,
      0.060903821140527725,
      0.031253620982170105,
      -0.020263897255063057,
      -0.054501816630363464,
      -0.00024681456852704287,
      -0.0039294627495110035,
      -0.012057865038514137,
      -0.023014230653643608,
      -0.041687365621328354,
      0.07989206165075302,
      0.010821130126714706,
      -0.05102197453379631,
      0.06880556792020798,
      -0.013143970631062984,
      0.0028736675158143044,
      0.007204768713563681,
      -0.02888050116598606,
      -0.10024852305650711,
      -0.07380014657974243,
      0.019231632351875305,
      -0.03983903303742409,
      0.0500948466360569,
      0.016804147511720657,
      0.004603679291903973,
      0.007102403324097395,
      0.032264988869428635,
      0.024204758927226067,
      0.08988631516695023,
      -0.0768701434135437,
      0.05288255959749222,
      -0.02705436572432518,
      -0.03289640322327614,
      0.004440195392817259,
      0.025739941745996475,
      -0.050073567777872086,
      -0.0544394813477993,
      -0.04737837612628937,
      0.018645089119672775,
      -0.028546057641506195,
      0.021725622937083244,
      0.021998949348926544,
      0.0408877469599247,
      0.06828770041465759,
      0.03926583752036095,
      -0.057033199816942215,
      0.002019694307819009,
      -0.02763681858778,
      0.024186542257666588,
      -0.03456026688218117,
      -0.024929825216531754,
      -0.04352445527911186,
      0.002149607054889202,
      0.003803493920713663,
      0.02963448315858841,
      0.006097943522036076,
      0.03422568738460541,
      -0.14734619855880737,
      -0.007686495780944824,
      -0.007941975258290768,
      -0.03768792375922203,
      -0.04969843849539757,
      -0.03368234634399414,
      -0.00466213608160615,
      -0.04888487979769707,
      -0.04511462152004242,
      0.12020657956600189,
      -0.1076488047838211,
      -0.050406116992235184,
      0.01116684265434742,
      -0.10747701674699783,
      0.013129443861544132,
      -0.022949839010834694,
      0.003324732882902026,
      0.06995218247175217,
      0.0289210993796587,
      0.022501928731799126,
      -0.015411452390253544,
      -0.02678484283387661,
      -0.014235764741897583,
      0.0018888066988438368,
      0.03554827719926834,
      0.06507305055856705,
      -0.0042224000208079815,
      -0.031786393374204636,
      -0.06034046784043312,
      0.010808112099766731,
      -0.004508788697421551,
      0.008737210184335709,
      0.04642811790108681,
      0.06320533901453018,
      0.04522475227713585,
      -0.0025460601318627596,
      0.00142539176158607,
      0.01349637471139431,
      0.0378119982779026,
      0.0025065660011023283,
      0.0006223732489161193,
      -0.04023440554738045,
      0.004189911764115095,
      -0.023401334881782532,
      0.08313121646642685,
      0.02255224995315075,
      0.003483196021988988,
      -0.038907092064619064,
      -0.021811723709106445,
      -0.01680259220302105,
      0.02402511239051819,
      -0.04877566918730736,
      -0.008626887574791908,
      0.10114121437072754,
      0.03239899128675461,
      -0.04973351210355759,
      -0.05409751087427139,
      0.004449863452464342,
      0.0366840623319149,
      -0.03582083433866501,
      -0.03891607001423836,
      0.009561188519001007,
      -0.004532787948846817,
      0.014434121549129486,
      0.03305208683013916,
      -0.016950812190771103,
      0.016507664695382118,
      0.004723182413727045,
      -0.04071880877017975,
      0.01605367474257946,
      -0.10499276220798492,
      -0.012009837664663792,
      -0.03728398680686951,
      -0.017963990569114685,
      -0.019831420853734016,
      -0.07258841395378113,
      -0.003517481265589595,
      -0.033034637570381165,
      0.012631899677217007,
      -0.06916102021932602,
      0.021244611591100693,
      -0.036505647003650665,
      0.019557850435376167,
      0.03677777573466301,
      0.029420999810099602,
      -0.040733229368925095
    ],
    [
      -0.05052552744746208,
      -0.04620766639709473,
      -0.023998547345399857,
      -0.022483594715595245,
      0.03480025753378868,
      -0.0181892029941082,
      0.0224361140280962,
      0.037940893322229385,
      -0.02167053148150444,
      0.01253074873238802,
      0.07334915548563004,
      -0.016537737101316452,
      -0.049382466822862625,
      0.08680181950330734,
      0.03922494128346443,
      -0.011723454110324383,
      0.0390092097222805,
      -0.016739344224333763,
      0.03642628341913223,
      -0.03962816670536995,
      -0.03167891502380371,
      0.013599280267953873,
      0.013531019911170006,
      0.019828936085104942,
      0.008145993575453758,
      0.008427605032920837,
      0.06740866601467133,
      0.050242770463228226,
      -0.021698273718357086,
      -0.037112779915332794,
      -0.024340959265828133,
      0.008603625930845737,
      -0.0444035604596138,
      0.03710046038031578,
      0.014236140996217728,
      0.0024940844159573317,
      -0.06672462821006775,
      0.03788454085588455,
      -0.01870753802359104,
      -0.012105440720915794,
      -0.023077774792909622,
      -0.02537749707698822,
      -0.0475790910422802,
      -0.09913557767868042,
      -0.0038416876923292875,
      0.004449448548257351,
      0.05546526238322258,
      0.06480789184570312,
      -0.049544066190719604,
      0.023378906771540642,
      -0.03053434193134308,
      -0.051315683871507645,
      -0.013423843309283257,
      -0.11426451057195663,
      -0.0769522488117218,
      -0.06469187140464783,
      0.05467449873685837,
      0.052549850195646286,
      0.035953864455223083,
      0.04419998824596405,
      -0.023076431825757027,
      0.03254733234643936,
      0.03090347722172737,
      -0.07053212076425552,
      0.052982207387685776,
      0.06150414049625397,
      -0.022563718259334564,
      -0.08413047343492508,
      -0.03536469116806984,
      -0.006054861005395651,
      0.009988384321331978,
      0.036258045583963394,
      0.03630184382200241,
      -0.04821910709142685,
      -0.040058501064777374,
      0.008455402217805386,
      -0.01925722137093544,
      -0.0057357484474778175,
      -0.03682548552751541,
      -0.03652863949537277,
      -0.044082045555114746,
      -0.03398260846734047,
      -0.052994441241025925,
      0.052915479987859726,
      -0.07241901010274887,
      0.009853465482592583,
      -0.06267990916967392,
      -0.021977495402097702,
      0.030653784051537514,
      0.07866077125072479,
      0.05857524275779724,
      0.016640646383166313,
      -0.00036647258093580604,
      -0.05758499726653099,
      -0.019116196781396866,
      -0.06552010029554367,
      0.01928214356303215,
      -0.06536616384983063,
      -0.00914943590760231,
      0.008243652060627937,
      -0.0017029353184625506,
      -0.012743273749947548,
      0.058840081095695496,
      -0.02238292060792446,
      -0.004887418821454048,
      -0.06157882139086723,
      0.005441549699753523,
      -0.14866670966148376,
      0.08465608209371567,
      0.008798503316938877,
      0.030820950865745544,
      -0.005203493405133486,
      0.036831680685281754,
      0.031060926616191864,
      -0.0453028567135334,
      0.060186319053173065,
      0.024156752973794937,
      0.013174275867640972,
      -0.02521047741174698,
      0.003904483513906598,
      -0.11458618938922882,
      0.00205092690885067,
      0.06962563842535019,
      -0.04462052881717682,
      0.029224753379821777,
      0.025995969772338867,
      0.058658670634031296,
      0.08225585520267487,
      0.04250682145357132,
      0.002758679911494255,
      0.029937131330370903,
      0.014461526647210121,
      0.015408103354275227,
      -0.0400521419942379,
      0.03941342607140541,
      -0.006172577850520611,
      -0.028020601719617844,
      -0.012792719528079033,
      -0.07164214551448822,
      0.01852850243449211,
      -0.012896222993731499,
      -0.009858082979917526,
      -0.019023194909095764,
      -0.006566056981682777,
      -0.030554460361599922,
      -0.009652391076087952,
      0.006483797915279865,
      -0.02943243831396103,
      -0.04193251207470894,
      -0.03444954752922058,
      0.017447877675294876,
      0.04228614270687103,
      -0.02670678310096264,
      0.03319324553012848,
      0.011150152422487736,
      -0.07977106422185898,
      0.004781131632626057,
      -0.04103172570466995,
      0.03178151696920395,
      -0.018574900925159454,
      0.02624279074370861,
      0.052666082978248596,
      -0.0036535030230879784,
      -0.007976961322128773,
      0.06516378372907639,
      -0.010105722583830357,
      0.11033684760332108,
      0.047124478965997696,
      0.0047780610620975494,
      0.015468396246433258,
      -0.10014930367469788,
      0.004062383435666561,
      0.0037121595814824104,
      0.008277489803731441,
      0.009099946357309818,
      -0.002136058174073696,
      0.011108826845884323,
      -0.016540873795747757,
      0.012018329463899136,
      -0.07701969891786575,
      0.09684227406978607,
      -0.0003497618017718196,
      -0.030020397156476974,
      0.10015491396188736,
      0.023753151297569275,
      -0.015309182927012444,
      0.03766610100865364,
      -0.008890248835086823,
      -0.10692869871854782,
      -0.011470896191895008,
      0.07245026528835297,
      5.805130786029622e-05,
      -0.03309418261051178,
      0.09821360558271408,
      0.019856570288538933,
      -0.05505378171801567,
      0.07845776528120041,
      -0.015677563846111298,
      0.020979564636945724,
      -0.042078692466020584,
      -0.07334004342556,
      0.03437892347574234,
      0.010622047819197178,
      -0.06318246573209763,
      -0.10600915551185608,
      0.03708675131201744,
      0.019981443881988525,
      0.018210088834166527,
      -0.010772397741675377,
      0.029975447803735733,
      0.026355234906077385,
      -0.006474311929196119,
      -0.04402559623122215,
      0.0131962476298213,
      0.05234752222895622,
      -0.029538989067077637,
      0.0028143832460045815,
      -0.07427789270877838,
      -0.07271594554185867,
      -0.03100072406232357,
      0.06361338496208191,
      -0.03040696308016777,
      0.029427535831928253,
      -0.009252642281353474,
      0.015035116113722324,
      -0.05766355246305466,
      0.12325011938810349,
      0.004280428402125835,
      -0.026710698381066322,
      0.03278348594903946,
      -0.04125436395406723,
      0.06577526777982712,
      -0.020055552944540977,
      -0.03058934211730957,
      0.031605686992406845,
      0.01907685212790966,
      -0.0695623978972435,
      0.013752591796219349,
      0.053691837936639786,
      -0.014699303545057774,
      -0.02143542841076851,
      -0.03893723338842392,
      -0.08451803773641586,
      -0.021762266755104065,
      -0.03208289295434952,
      0.026558374986052513,
      0.008304396644234657,
      0.04524998739361763,
      -0.04830961674451828,
      0.012530000880360603,
      -0.01632947102189064,
      0.00868348591029644,
      -0.02855220064520836,
      -0.017010018229484558,
      -0.00667403731495142,
      0.0239426139742136,
      -0.052685126662254333,
      -0.037518806755542755,
      -0.035957369953393936,
      0.03325241431593895,
      -0.0016149155562743545,
      0.0014146516332402825,
      -0.03244668245315552,
      -0.11511362344026566,
      -0.09032592922449112,
      -0.007373296655714512,
      -0.01919415034353733,
      0.05197116732597351,
      -0.029914742335677147,
      0.008663681335747242,
      0.030670763924717903,
      0.03553790599107742,
      0.0017253964906558394,
      -0.035837553441524506,
      0.03129386901855469,
      -0.010329690761864185,
      -0.0720110535621643,
      -0.04403343424201012,
      -0.0021987659856677055,
      0.035762447863817215,
      0.009577909484505653,
      -0.1379169374704361,
      0.010909585282206535,
      0.1090627908706665,
      -0.024279940873384476,
      -0.00870176125317812,
      0.06457534432411194,
      -0.09652301669120789,
      -0.061887022107839584,
      -0.06323566287755966,
      0.023728808388113976,
      0.004405524581670761,
      0.019787829369306564,
      0.06631448864936829,
      0.03921172767877579,
      -0.021086743101477623,
      0.06399812549352646,
      0.061103422194719315,
      0.024956313893198967,
      -0.020753100514411926,
      -0.030528897419571877,
      -0.015537521801888943,
      -0.08787352591753006,
      0.04683682695031166,
      0.034305717796087265,
      0.09601258486509323,
      -0.06422064453363419,
      -0.04210127890110016,
      0.025554843246936798,
      0.02566041611135006,
      0.04894853010773659,
      -0.02406584471464157,
      0.018312426283955574,
      0.049497779458761215,
      0.05206068605184555,
      -0.09820372611284256,
      0.056704603135585785,
      -0.005464223679155111,
      -0.020466813817620277,
      0.0734456330537796,
      0.02214372158050537,
      -0.060326822102069855,
      -0.008874286897480488,
      0.039449892938137054,
      0.03407730534672737,
      0.03426593169569969,
      -0.0062553174793720245,
      -0.07668567448854446,
      -0.08588127791881561,
      0.08441071212291718,
      0.0874987244606018,
      -0.013237029314041138,
      0.08882889151573181,
      0.05327696353197098,
      -0.027455730363726616,
      0.036349643021821976,
      0.06765269488096237,
      0.00015509562217630446,
      0.05728767439723015,
      -0.12933026254177094,
      -0.018406128510832787,
      0.042713019996881485,
      0.05629831925034523,
      0.02215011976659298,
      -0.06290476024150848,
      0.0015903774183243513,
      0.025159768760204315,
      -0.0685848817229271,
      0.02255507931113243,
      0.013291255570948124,
      -0.034804534167051315,
      -0.035478390753269196,
      0.022027291357517242,
      0.022950828075408936,
      0.03747930750250816,
      -0.04183181747794151,
      0.012912220321595669,
      0.10430827736854553,
      -0.0023738928139209747,
      -0.03283722698688507,
      -0.0036724666133522987,
      -0.008861285634338856,
      -0.025453224778175354,
      0.07217174023389816,
      -0.04287657141685486,
      -0.04384870082139969,
      0.02562905102968216,
      -0.036679379642009735,
      0.015394661575555801,
      0.04045644402503967,
      0.0717829242348671,
      -0.0156900342553854,
      -0.010971750132739544,
      0.12908577919006348,
      -0.07671181112527847,
      0.028120264410972595,
      0.06349079310894012,
      -0.08539890497922897,
      0.0026006021071225405,
      -0.034775663167238235,
      -0.06327112019062042,
      0.04476239159703255,
      0.025374500080943108,
      0.01408743392676115,
      0.05411730706691742,
      0.025908619165420532,
      -0.04264712333679199,
      -0.03976830467581749,
      0.011457781307399273,
      0.04986925050616264,
      -0.0047613829374313354,
      0.05268947035074234,
      -0.06454630941152573,
      0.003055573208257556,
      0.03811754286289215,
      -0.022219613194465637,
      -0.019385289400815964,
      0.014510436914861202,
      0.018824413418769836,
      -0.0061699505895376205,
      0.004775277338922024,
      0.009782408364117146,
      0.07065452635288239,
      -0.01689876616001129,
      -0.016984403133392334,
      -0.029789118096232414,
      0.05782999098300934,
      -0.015547498129308224,
      -0.03615109249949455,
      0.01068416703492403,
      0.06758887320756912,
      0.04998466745018959,
      -0.09718240052461624,
      0.020326785743236542,
      0.012868181802332401,
      0.04963742196559906,
      -0.011127634905278683,
      0.0019154684850946069,
      -0.00474943034350872,
      0.02609642967581749,
      0.02395319938659668,
      0.019493350759148598,
      0.02651134692132473,
      0.007502041291445494,
      0.023618364706635475,
      0.04165312647819519,
      0.007748087868094444,
      -0.012932107783854008,
      0.043094344437122345,
      0.07203801721334457,
      0.019948763772845268,
      -0.007011926267296076,
      0.008098920807242393,
      0.061830244958400726,
      0.016715219244360924,
      -0.02887074090540409,
      0.016762057319283485,
      0.09310813993215561,
      0.027787575498223305,
      -0.017355645075440407,
      -0.0055200872011482716,
      -0.06038764491677284,
      0.0469994880259037,
      -0.022200914099812508,
      -0.05137399211525917,
      -0.058368757367134094,
      0.021821122616529465,
      0.02140720933675766,
      -0.05384892597794533,
      -0.090883269906044,
      0.01234945748001337,
      0.03457852452993393,
      -0.07272791862487793,
      -0.04657962918281555,
      0.03146405890583992,
      -0.014733119867742062,
      -0.030461948364973068,
      -0.0407150574028492,
      -0.023219309747219086,
      -0.07430911064147949,
      0.0067825098522007465,
      -0.028273463249206543,
      -0.023049544543027878,
      -0.034535787999629974,
      0.03728238120675087,
      0.012886024080216885,
      0.017603877931833267,
      -0.032885223627090454,
      -0.088331438601017,
      0.004773041233420372,
      0.013184938579797745,
      0.022576769813895226,
      0.0021735639311373234,
      0.038815565407276154,
      -0.003096926724538207,
      0.042945075780153275,
      -0.06618460267782211,
      0.028330469503998756,
      -0.07702235132455826,
      0.009587422013282776,
      0.013596431352198124,
      0.048832472413778305,
      -0.055317480117082596,
      0.02470427192747593,
      0.1036941334605217,
      0.046330489218235016,
      -0.04189799726009369,
      -0.0541033037006855,
      -0.002492721891030669,
      -0.023923130705952644,
      -0.05312422662973404,
      -0.027107974514365196,
      -0.020988814532756805,
      -0.006442166864871979,
      -0.04787910729646683,
      -0.07625287771224976,
      0.00940479151904583,
      -0.0566285103559494,
      0.015752870589494705,
      -0.019498519599437714,
      -0.03474586829543114,
      0.05945218354463577,
      -0.013124986551702023
    ],
    [
      -0.017472581937909126,
      0.010818622075021267,
      -0.05135853588581085,
      0.042705193161964417,
      -0.027911249548196793,
      -0.024333234876394272,
      -0.04717361927032471,
      0.015778936445713043,
      -0.0685553029179573,
      -0.07258711010217667,
      -0.039553530514240265,
      0.002062058774754405,
      0.0022403241600841284,
      0.019425168633461,
      0.07891765981912613,
      -0.00578524312004447,
      -0.015870828181505203,
      0.014602254144847393,
      0.09746360778808594,
      0.013273383490741253,
      0.03997863829135895,
      -0.06406963616609573,
      0.020612340420484543,
      0.11610260605812073,
      0.1108391210436821,
      0.048721786588430405,
      -0.015087410807609558,
      0.029734205454587936,
      0.019275538623332977,
      0.031271010637283325,
      -0.04891226440668106,
      0.04235166311264038,
      -0.07611064612865448,
      0.04967475309967995,
      -0.04707038775086403,
      0.06175288185477257,
      0.04876577481627464,
      0.037923265248537064,
      -0.046547092497348785,
      0.03093721717596054,
      0.0023599769920110703,
      -0.017138732597231865,
      0.012158681638538837,
      -0.0009446845506317914,
      0.0203204657882452,
      0.05893595516681671,
      0.023220065981149673,
      -0.054850514978170395,
      0.10378873348236084,
      -0.05751322954893112,
      -0.005162590648978949,
      -0.00455508241429925,
      0.061927128583192825,
      -0.088438019156456,
      -0.04073736444115639,
      0.04861840978264809,
      0.023848887532949448,
      0.0674433708190918,
      0.00033579778391867876,
      0.04888426139950752,
      -0.004528953693807125,
      0.01773231290280819,
      0.015428892336785793,
      0.045190196484327316,
      0.03441159799695015,
      -0.009961159899830818,
      0.00943813193589449,
      0.06966951489448547,
      0.011211876757442951,
      0.024416157975792885,
      0.007440230809152126,
      0.0007822655024938285,
      -0.029004542157053947,
      -0.02003377489745617,
      -0.04833491891622543,
      0.025858096778392792,
      0.015789855271577835,
      0.0757775530219078,
      -0.013063125312328339,
      -0.030652128159999847,
      0.02695455215871334,
      0.05647638440132141,
      -0.04521920159459114,
      0.020640389993786812,
      -0.09711670130491257,
      -0.016289671882987022,
      -0.02070675976574421,
      -0.041837017983198166,
      0.04047243297100067,
      -0.07681912183761597,
      -0.03739751875400543,
      -0.012226995080709457,
      0.10521064698696136,
      0.02979888953268528,
      0.013482585549354553,
      -0.032824352383613586,
      0.009066996164619923,
      -0.07578793168067932,
      0.009689748287200928,
      0.13585542142391205,
      0.04936112090945244,
      0.06695824861526489,
      0.0642639696598053,
      -0.08400451391935349,
      -0.09562882035970688,
      0.02787698619067669,
      -0.020026369020342827,
      0.02147390879690647,
      0.05088379979133606,
      0.039767034351825714,
      0.028571229428052902,
      0.0071565029211342335,
      -0.026015853509306908,
      -0.01383240893483162,
      -0.013447169214487076,
      0.04856301099061966,
      0.05053402855992317,
      -0.034490425139665604,
      -0.011873804032802582,
      -0.019701912999153137,
      -0.0073457262478768826,
      0.009896098636090755,
      -0.0117720365524292,
      -0.04668240249156952,
      0.018865197896957397,
      0.01419624499976635,
      -0.03382067009806633,
      -0.04667644575238228,
      -0.03153711557388306,
      -0.033967308700084686,
      0.05643470585346222,
      -0.0308077409863472,
      -0.05883440747857094,
      -0.03834858536720276,
      -0.0162820965051651,
      -0.024836260825395584,
      -0.10188216716051102,
      0.009439758956432343,
      -0.014479641802608967,
      -0.02330227941274643,
      -0.013763491995632648,
      -0.017993958666920662,
      0.0408373698592186,
      0.040701910853385925,
      0.06845668703317642,
      -0.019370675086975098,
      0.041331835091114044,
      9.35616553761065e-05,
      -0.03609447181224823,
      -0.041673146188259125,
      -0.05514422431588173,
      0.04988758638501167,
      -0.04317003861069679,
      0.015452046878635883,
      -0.0245017372071743,
      -0.09153127670288086,
      0.07379741966724396,
      0.05293786898255348,
      0.0065869237296283245,
      -0.0010011938866227865,
      0.05837693065404892,
      0.007890168577432632,
      0.0854780301451683,
      0.008097880519926548,
      -0.06736317276954651,
      0.017038708552718163,
      -0.03626983240246773,
      -0.062134601175785065,
      -0.004439834039658308,
      -0.024113887920975685,
      -0.030854931101202965,
      0.0160107109695673,
      -0.010434186086058617,
      0.07814393937587738,
      0.03216543048620224,
      0.04057358577847481,
      0.00413762079551816,
      0.03139244765043259,
      0.016557464376091957,
      0.03783611208200455,
      0.015927519649267197,
      0.03180668130517006,
      0.009755310602486134,
      -0.05128095671534538,
      -0.10497954487800598,
      0.028653373941779137,
      -0.0015241769142448902,
      -0.010235262103378773,
      -0.04401338845491409,
      -0.07849093526601791,
      -0.030226701870560646,
      0.03419019654393196,
      0.011471554636955261,
      0.00882782880216837,
      -0.0006710751913487911,
      0.003162361914291978,
      -0.009895949624478817,
      0.0574532188475132,
      -0.03633050620555878,
      -0.07938092947006226,
      0.035105131566524506,
      -0.03965703770518303,
      0.07826375216245651,
      -0.05098314210772514,
      -0.016886692494153976,
      0.07090304046869278,
      -0.05171387270092964,
      0.007273604162037373,
      0.07270999997854233,
      -0.03922925144433975,
      0.038794972002506256,
      0.02477770671248436,
      -0.09500989317893982,
      0.037879347801208496,
      0.048150576651096344,
      -0.013507858850061893,
      -0.006784470751881599,
      -0.049414798617362976,
      -0.02418876625597477,
      -0.05184382200241089,
      0.024231281131505966,
      -0.018447326496243477,
      -0.003227368462830782,
      0.052266675978899,
      -0.002199897775426507,
      -0.003593273228034377,
      0.052366238087415695,
      0.04124603047966957,
      0.0446150079369545,
      0.06772907823324203,
      0.029757680371403694,
      0.0210545863956213,
      -0.05219554156064987,
      0.03450368344783783,
      0.0038369158282876015,
      0.09087858349084854,
      0.013412931933999062,
      0.032010629773139954,
      -0.026092693209648132,
      0.08813611418008804,
      -0.04594695568084717,
      -0.027371032163500786,
      0.03786446154117584,
      -0.007282480131834745,
      0.07649100571870804,
      0.04162079468369484,
      0.0038100203964859247,
      -0.04236186295747757,
      0.028412140905857086,
      0.023066623136401176,
      -0.03577204793691635,
      -0.0240513626486063,
      -0.056707821786403656,
      -0.03429616615176201,
      0.040082551538944244,
      -0.058466922491788864,
      0.08968325704336166,
      -0.014994744211435318,
      0.017139434814453125,
      -0.013858657330274582,
      -0.038939956575632095,
      0.007252831012010574,
      -0.028307544067502022,
      0.05169718340039253,
      -0.10667326301336288,
      -0.05294562876224518,
      -0.0015851045027375221,
      0.010976542718708515,
      0.008911564014852047,
      -0.007050022482872009,
      0.011028057895600796,
      0.011734356172382832,
      -0.03554508835077286,
      0.03924885019659996,
      -0.061005350202322006,
      -0.037526365369558334,
      0.0847092717885971,
      0.03870396688580513,
      -0.056933388113975525,
      0.0634959414601326,
      -0.027904102578759193,
      -0.1037524938583374,
      -0.04724464192986488,
      -0.02597111091017723,
      0.03620786964893341,
      -0.0035794349387288094,
      -0.011870058253407478,
      -0.09196577221155167,
      0.069862000644207,
      0.09932683408260345,
      0.04367370158433914,
      -0.08461784571409225,
      -0.004427145700901747,
      0.06967119872570038,
      -0.008659141138195992,
      0.00753362849354744,
      0.0020994835067540407,
      0.09264304488897324,
      0.05083834379911423,
      -0.05640368536114693,
      0.003110014833509922,
      -0.06366898119449615,
      0.05047160014510155,
      0.047145429998636246,
      0.0053511145524680614,
      -0.06158546358346939,
      -0.011108389124274254,
      -0.007022060453891754,
      0.014088436961174011,
      -0.0283723846077919,
      -0.02162935584783554,
      0.003838274860754609,
      -0.01898624747991562,
      0.05247572809457779,
      -0.04797976464033127,
      -0.05859389901161194,
      -0.013754078187048435,
      0.01592680811882019,
      -0.10847776383161545,
      -0.0551300011575222,
      0.016217326745390892,
      -0.06879587471485138,
      -0.011848104186356068,
      0.0256669819355011,
      0.012160559184849262,
      0.07565595954656601,
      0.03821953758597374,
      0.013579280115664005,
      -0.01236509159207344,
      0.007739797700196505,
      0.025144139304757118,
      0.0136288246139884,
      -0.01155564934015274,
      0.029291046783328056,
      -0.08502266556024551,
      -0.00012005698954453692,
      -0.053909774869680405,
      -0.015717072412371635,
      0.007595700677484274,
      -0.05397994816303253,
      0.043802130967378616,
      0.041032060980796814,
      -0.04774927720427513,
      -0.02975163422524929,
      -0.03784804791212082,
      -0.06335463374853134,
      0.008257996290922165,
      0.011486228555440903,
      0.021360399201512337,
      0.0900798887014389,
      0.017474284395575523,
      0.04164174571633339,
      0.025089379400014877,
      -0.07907842099666595,
      -0.05717788264155388,
      -0.038831233978271484,
      -0.07741736620664597,
      0.05224171653389931,
      -0.03302545100450516,
      0.028526298701763153,
      0.0014086688170209527,
      -0.035076651722192764,
      0.046085234731435776,
      -0.04829639196395874,
      -0.0649072676897049,
      0.10431952774524689,
      0.07497265189886093,
      -0.010624412447214127,
      0.0842558965086937,
      -0.0647679790854454,
      -0.004781937226653099,
      0.03426742181181908,
      0.03149501979351044,
      0.02316587045788765,
      0.008674699813127518,
      0.06932587176561356,
      0.00015732877363916487,
      0.03260805457830429,
      -0.0470811203122139,
      -0.024252360686659813,
      0.019798438996076584,
      0.05434579402208328,
      -0.029801059514284134,
      0.04704432934522629,
      0.0022265485022217035,
      0.009518254548311234,
      -0.07745138555765152,
      -0.07852328568696976,
      -0.02343534119427204,
      -0.014832496643066406,
      -0.006319854408502579,
      0.012020566500723362,
      -0.0037535778246819973,
      0.03423101827502251,
      -0.04645943641662598,
      -0.012705255299806595,
      -0.05322345718741417,
      -0.08789125084877014,
      -0.016388338059186935,
      -0.013814758509397507,
      -0.03479206934571266,
      0.04262165725231171,
      0.03612753748893738,
      0.026062477380037308,
      0.015572523698210716,
      -0.04913695156574249,
      -0.01850864849984646,
      0.03991702198982239,
      -0.00908199604600668,
      0.06767281144857407,
      0.027336621657013893,
      0.01135466992855072,
      -0.06653449684381485,
      -0.07488518953323364,
      0.07462111860513687,
      -0.005333715584129095,
      0.04288528114557266,
      -0.04613185301423073,
      0.002706750761717558,
      -0.03599509969353676,
      -0.04608763009309769,
      0.04527660831809044,
      0.0015875441022217274,
      -0.06901334226131439,
      -0.013691949658095837,
      -0.02285424806177616,
      -0.08321472257375717,
      -0.08501867204904556,
      -0.022411614656448364,
      -0.00994302611798048,
      0.032805170863866806,
      0.00339794484898448,
      -0.05552292987704277,
      0.06030308082699776,
      -0.0047298152931034565,
      -0.00037959302426315844,
      -0.06875332444906235,
      0.010817828588187695,
      0.05797407403588295,
      0.06042981520295143,
      -0.05439643934369087,
      0.04367782548069954,
      0.02386542409658432,
      0.031761135905981064,
      -0.0006529402453452349,
      0.013016247190535069,
      -0.036467671394348145,
      0.0014202430611476302,
      -0.06389733403921127,
      -0.021476728841662407,
      -0.03843056410551071,
      -0.08315841853618622,
      0.03703251853585243,
      -0.06710418313741684,
      -0.029506195336580276,
      -0.05162724107503891,
      0.022425120696425438,
      -0.06497807800769806,
      -0.08901480585336685,
      0.02789118140935898,
      -0.04342319443821907,
      -0.06401395797729492,
      0.06942297518253326,
      -0.00804927572607994,
      0.013806896284222603,
      -0.0537017360329628,
      -0.025834975764155388,
      0.02337578311562538,
      -0.09490437060594559,
      -0.026508986949920654,
      0.06027572229504585,
      -0.05049289017915726,
      0.022060729563236237,
      0.016202721744775772,
      0.03308200091123581,
      0.012990687973797321,
      -0.025855334475636482,
      0.028929278254508972,
      0.022167198359966278,
      -0.07067380845546722,
      0.004711492452770472,
      0.0071951416321098804,
      0.0028429795056581497,
      0.02119000256061554,
      -0.07105158269405365,
      0.026346854865550995,
      -0.011187287978827953,
      0.008780605159699917,
      -0.048112694174051285,
      0.03130802884697914,
      -0.017643600702285767,
      -0.004894880577921867,
      0.011623370461165905,
      0.0250583216547966,
      -0.021554026752710342,
      0.035137154161930084,
      0.04143209382891655,
      -0.027173306792974472,
      -0.04360001161694527,
      -0.02805020846426487,
      0.009372291155159473,
      -0.06716364622116089,
      -0.04108916223049164
    ],
    [
      0.09434139728546143,
      0.00596947455778718,
      0.0039518228732049465,
      0.020980700850486755,
      0.025799648836255074,
      0.015977835282683372,
      0.06093250960111618,
      0.016102628782391548,
      -0.04314549267292023,
      0.054366759955883026,
      -0.01978699304163456,
      0.02013235352933407,
      -0.008644447661936283,
      0.056504927575588226,
      -0.012841600924730301,
      -0.008065324276685715,
      0.01567712612450123,
      0.04858998954296112,
      0.006382836028933525,
      0.049643076956272125,
      0.03216982260346413,
      0.054247915744781494,
      -0.054328370839357376,
      0.07795622944831848,
      -0.005872282199561596,
      -0.00332928285934031,
      0.04432214796543121,
      -0.047914326190948486,
      -0.04367354139685631,
      -0.0853247120976448,
      -0.003281403100118041,
      0.02712688036262989,
      0.023139774799346924,
      0.025695346295833588,
      0.028584687039256096,
      -0.07921640574932098,
      0.07680793106555939,
      -0.06747493892908096,
      -0.029916632920503616,
      -0.13781411945819855,
      -0.03637026622891426,
      0.0021859658882021904,
      0.02126779966056347,
      0.0316237173974514,
      -0.002294206991791725,
      -0.05171440914273262,
      0.028824124485254288,
      0.02649233303964138,
      0.015568220056593418,
      -0.010407048277556896,
      0.0038330652751028538,
      0.07936672866344452,
      0.01572239398956299,
      -0.014168841764330864,
      0.022402038797736168,
      0.038682106882333755,
      0.03939533233642578,
      -0.05489424243569374,
      0.04276633635163307,
      -0.050672683864831924,
      0.06664455682039261,
      -0.017284663394093513,
      -0.021042386069893837,
      0.046845000237226486,
      0.03914161026477814,
      0.03402792289853096,
      -0.016163574531674385,
      -0.042450983077287674,
      -0.06301635503768921,
      -0.007649318780750036,
      0.07325074821710587,
      -0.10433394461870193,
      0.07161738723516464,
      -0.03174637630581856,
      -0.01647038385272026,
      -0.01069575548171997,
      0.08612573891878128,
      0.08068037033081055,
      -0.01695379428565502,
      0.05327067896723747,
      0.07378438115119934,
      0.006441335193812847,
      0.08122166246175766,
      0.02803846076130867,
      -0.00037893655826337636,
      0.08306138962507248,
      -0.0479254424571991,
      0.052053432911634445,
      -0.023287029936909676,
      -0.00010348157229600474,
      -0.07484170794487,
      0.00819638092070818,
      -0.032529935240745544,
      0.028632238507270813,
      0.04171963408589363,
      0.09252623468637466,
      -0.03474636748433113,
      -0.014249056577682495,
      0.04185238480567932,
      -0.01126033067703247,
      0.009593280963599682,
      -0.04724763706326485,
      0.04549679905176163,
      -0.10507262498140335,
      0.029136912897229195,
      0.014632012695074081,
      -0.10437343269586563,
      0.02624364197254181,
      0.042696017771959305,
      -0.019483165815472603,
      -0.054081838577985764,
      0.005568279419094324,
      -0.02330799587070942,
      0.04849771037697792,
      -0.015534989535808563,
      -0.0110477888956666,
      0.028910823166370392,
      -0.07980725914239883,
      -0.03693608567118645,
      0.07984670251607895,
      0.0011084660654887557,
      0.040782880038022995,
      -0.07093358784914017,
      -0.08554648607969284,
      0.08066735416650772,
      -0.04355393350124359,
      -0.07702329009771347,
      -0.043654002249240875,
      0.1253853589296341,
      -0.006926696747541428,
      0.04054107144474983,
      -0.025167444720864296,
      -0.015107831917703152,
      0.0007434078142978251,
      -0.043683093041181564,
      -0.009851119481027126,
      -0.03505874425172806,
      0.00724504329264164,
      0.025941450148820877,
      0.04041611775755882,
      -0.029587116092443466,
      -0.00876737292855978,
      -0.023732850328087807,
      -0.06360186636447906,
      -0.04897589609026909,
      -0.003913563676178455,
      0.07638047635555267,
      -0.00470741605386138,
      -0.06201626732945442,
      0.050541575998067856,
      -0.028187120333313942,
      0.040811896324157715,
      -0.027165891602635384,
      -0.015979066491127014,
      0.008914112113416195,
      0.03646872565150261,
      -0.0004149252490606159,
      0.006057837046682835,
      0.016378354281187057,
      0.018681153655052185,
      0.023963509127497673,
      -0.01987197995185852,
      0.05132674425840378,
      0.02399510331451893,
      -0.05444926396012306,
      -0.010450846515595913,
      0.07179902493953705,
      -0.013736294582486153,
      -0.06802903860807419,
      -0.016960376873612404,
      0.053168971091508865,
      0.023227814584970474,
      0.006787596270442009,
      0.04671293869614601,
      -0.033371031284332275,
      -0.07032536715269089,
      -0.02535116672515869,
      0.007670506834983826,
      0.06816413998603821,
      -0.02380780689418316,
      0.0034201336093246937,
      0.07643187046051025,
      -0.11982061713933945,
      -0.06671536713838577,
      0.061178430914878845,
      -0.041047353297472,
      -0.029947228729724884,
      0.0010770094813778996,
      -0.07910966128110886,
      -0.00010467858373885974,
      -0.0921730101108551,
      -0.0028240832034498453,
      0.0001937673077918589,
      0.029156191274523735,
      -0.034449562430381775,
      -0.05930602177977562,
      -0.045901764184236526,
      -0.03841739520430565,
      0.043641820549964905,
      0.020229581743478775,
      -0.03472669795155525,
      -0.01566876471042633,
      0.04363297298550606,
      -0.024692103266716003,
      -0.022756200283765793,
      0.05100515857338905,
      -0.05225546285510063,
      -0.006565780378878117,
      0.039491139352321625,
      0.023972854018211365,
      0.00952860713005066,
      -0.04249211773276329,
      0.004061026033014059,
      -0.03414022549986839,
      0.02826717309653759,
      -0.08523770421743393,
      -0.013634145259857178,
      -0.030927803367376328,
      0.0867360457777977,
      -0.004632401280105114,
      -0.034891482442617416,
      -0.029451346024870872,
      -0.059395212680101395,
      0.00345939164981246,
      -0.0034560749772936106,
      0.024657638743519783,
      0.03607708215713501,
      0.10140910744667053,
      -0.012481873854994774,
      -0.04558788985013962,
      -0.1258317232131958,
      0.03701078146696091,
      0.0040847985073924065,
      -0.056539386510849,
      -0.027143539860844612,
      -0.06674189120531082,
      -0.07380709797143936,
      -0.03388576954603195,
      -0.016445228829979897,
      0.023232318460941315,
      0.011598181910812855,
      0.05187694728374481,
      0.030908683314919472,
      0.007530460599809885,
      -0.015615545213222504,
      0.03701380267739296,
      0.04858238995075226,
      -0.005305913742631674,
      -0.025424906983971596,
      0.035963546484708786,
      -0.046154171228408813,
      0.06617958098649979,
      -0.023743245750665665,
      0.04937775433063507,
      0.044610943645238876,
      0.11544113606214523,
      0.06255088001489639,
      0.021557820960879326,
      0.025632396340370178,
      0.013050569221377373,
      0.034581225365400314,
      -0.026796313002705574,
      -0.07631674408912659,
      -0.04273362457752228,
      -0.0011831113370135427,
      0.011360827833414078,
      0.029469670727849007,
      -0.08222953975200653,
      0.020449407398700714,
      -0.0028124279342591763,
      -0.014612090773880482,
      -0.045169271528720856,
      0.07849514484405518,
      -0.025084197521209717,
      -0.005294036120176315,
      0.020827822387218475,
      -0.018820887431502342,
      -0.026639942079782486,
      0.006445133127272129,
      -0.07238883525133133,
      0.06773148477077484,
      -0.035656798630952835,
      -0.009792766533792019,
      0.09019844979047775,
      0.05719859525561333,
      -0.06595907360315323,
      0.026972375810146332,
      -0.10678882896900177,
      -0.09265801310539246,
      0.008739585056900978,
      -0.020302006974816322,
      0.09646237641572952,
      0.015215517953038216,
      -0.00032264908077195287,
      0.062477272003889084,
      -0.0027229266706854105,
      0.01869085431098938,
      -0.040737032890319824,
      0.06275937706232071,
      0.006882959045469761,
      0.01675068959593773,
      -0.09939487278461456,
      0.0025893768761307,
      0.04020242393016815,
      0.0327424593269825,
      0.07131268084049225,
      0.03180747851729393,
      0.020667504519224167,
      0.08576656132936478,
      -0.03740064799785614,
      -0.0036987741477787495,
      -0.012414813973009586,
      0.012525749392807484,
      -0.062459174543619156,
      0.07858072966337204,
      -0.018707767128944397,
      0.04840284213423729,
      0.021474232897162437,
      0.011591027490794659,
      0.032936934381723404,
      0.018185248598456383,
      -0.04331403225660324,
      0.0020684096962213516,
      0.026920421048998833,
      -0.025380980223417282,
      -0.0067345378920435905,
      -0.018651720136404037,
      0.004053066018968821,
      -0.012116345576941967,
      -0.030383700504899025,
      0.057740144431591034,
      0.009597246535122395,
      0.012631352990865707,
      0.031795430928468704,
      -0.053450651466846466,
      -0.029983606189489365,
      -0.0714762732386589,
      0.05816134437918663,
      -0.002982545178383589,
      0.005246771965175867,
      0.07335598766803741,
      0.011259571649134159,
      -0.11106700450181961,
      0.03373848274350166,
      8.625490590929985e-05,
      -0.017049366608262062,
      -0.027932854369282722,
      -0.08491776883602142,
      0.001221594400703907,
      0.0044130487367510796,
      0.046074412763118744,
      -0.0847063884139061,
      0.0004963409155607224,
      -0.10324771702289581,
      -0.05030234158039093,
      -0.07208475470542908,
      -0.008517405949532986,
      -0.022118812426924706,
      -0.018198437988758087,
      -0.027868324890732765,
      -0.023078670725226402,
      0.02206144668161869,
      0.027144400402903557,
      -0.008654328994452953,
      -0.0012358975363895297,
      -0.010740424506366253,
      -0.12250365316867828,
      0.000447586557129398,
      -0.0664566159248352,
      0.03779191896319389,
      0.0437856949865818,
      0.03186165913939476,
      -0.031214255839586258,
      -0.05431325361132622,
      -0.01527928002178669,
      0.013394935987889767,
      0.028356000781059265,
      -0.014422806911170483,
      0.033351607620716095,
      -0.0577847994863987,
      -0.040894318372011185,
      -0.008634423837065697,
      0.023724151775240898,
      0.024707822129130363,
      0.007383931428194046,
      0.0542684905230999,
      -0.023110544309020042,
      0.023258550092577934,
      0.02808111533522606,
      0.0041029187850654125,
      0.014201394282281399,
      0.04143666848540306,
      0.0227702297270298,
      0.03141143172979355,
      0.03025803342461586,
      -0.02426697313785553,
      0.02046043612062931,
      0.03698164224624634,
      0.015968844294548035,
      -0.06540932506322861,
      0.021172896027565002,
      0.15205547213554382,
      -0.03657805919647217,
      -0.001512282993644476,
      0.007203062530606985,
      -0.054558273404836655,
      -0.04772036895155907,
      0.06549988687038422,
      -0.013032683171331882,
      0.03352716192603111,
      0.003893813816830516,
      -0.061977170407772064,
      0.039027437567710876,
      0.0160504262894392,
      -0.05359159782528877,
      0.013227066025137901,
      0.01580529473721981,
      0.03127949312329292,
      0.04247048869729042,
      0.00031677173683419824,
      -0.0265959519892931,
      0.0415494479238987,
      0.013400710187852383,
      0.01670549437403679,
      0.04860867187380791,
      0.030209461227059364,
      -0.0030375770293176174,
      0.03625491261482239,
      0.06029808893799782,
      0.040559373795986176,
      -0.023226164281368256,
      0.06874758005142212,
      -0.0007329188520088792,
      0.029074441641569138,
      -0.009752136655151844,
      -0.03722883760929108,
      -0.0003217699413653463,
      -0.021134868264198303,
      -0.02982289530336857,
      -0.004919950384646654,
      -0.08031763881444931,
      0.0014412807067856193,
      -0.06592706590890884,
      -0.05257990583777428,
      -0.03655646741390228,
      -0.07624012976884842,
      -0.057187825441360474,
      -0.01040960755199194,
      -0.02564002200961113,
      -0.0018180818296968937,
      0.015278896316885948,
      -0.03828968107700348,
      -0.05555671826004982,
      -0.004994542803615332,
      0.019927503541111946,
      -0.10325868427753448,
      0.054628122597932816,
      -0.05650635436177254,
      0.031529687345027924,
      -0.053906314074993134,
      -0.03140698000788689,
      -0.05539106950163841,
      -0.03099563531577587,
      0.021439451724290848,
      0.0018051862716674805,
      -0.044869933277368546,
      -0.057379670441150665,
      -0.04776730760931969,
      0.02153944782912731,
      -0.048907797783613205,
      -0.032844919711351395,
      -0.004380992613732815,
      -0.013815744780004025,
      -0.009113107807934284,
      -0.0013787858188152313,
      -0.006253200117498636,
      -0.05507883429527283,
      0.038157325237989426,
      0.019273698329925537,
      -0.01639566570520401,
      -0.03228195384144783,
      -0.008313087746500969,
      0.03519580885767937,
      0.018933553248643875,
      0.05652772635221481,
      0.01862671785056591,
      -0.012124055996537209,
      0.013571602292358875,
      -0.03514119237661362,
      -0.004779604729264975,
      0.06637486815452576,
      0.07868413627147675,
      0.03106723167002201,
      0.006861044559627771,
      -0.0366554819047451,
      0.04098789021372795,
      -0.033707790076732635,
      0.04071981832385063,
      0.0111561119556427,
      0.012446203269064426,
      0.044084154069423676,
      0.02504592202603817,
      -0.0012053003301844
    ],
    [
      0.013075434602797031,
      -0.009958949871361256,
      0.031468745321035385,
      -0.019364386796951294,
      -0.08730413019657135,
      0.004803546238690615,
      0.046530455350875854,
      -0.029171466827392578,
      -0.06775777041912079,
      0.041972529143095016,
      0.0008569831843487918,
      -0.004831959493458271,
      0.01822689361870289,
      -0.07659213244915009,
      0.01915307529270649,
      -0.000502918497659266,
      -0.002734796842560172,
      -0.0641491562128067,
      0.022150466218590736,
      -0.023510942235589027,
      0.051452428102493286,
      0.033984359353780746,
      0.06532365083694458,
      -0.02583070658147335,
      0.01815597154200077,
      0.010390684008598328,
      0.042160555720329285,
      -0.03761494904756546,
      0.05368557572364807,
      -0.009677310474216938,
      0.11004642397165298,
      0.07252904772758484,
      0.04918613284826279,
      0.028859270736575127,
      -0.027767235413193703,
      -0.037842195481061935,
      -0.011949000880122185,
      -0.018888765946030617,
      0.036102328449487686,
      0.0714477077126503,
      -0.018337951973080635,
      0.07442633807659149,
      0.038159579038619995,
      -0.025178946554660797,
      0.02896745689213276,
      0.007813623175024986,
      0.031993262469768524,
      0.032328613102436066,
      -0.031326375901699066,
      -0.05208568647503853,
      0.003599003655835986,
      0.010986631736159325,
      0.04338603466749191,
      0.09916520863771439,
      -0.017267489805817604,
      0.00438879057765007,
      -0.0496659092605114,
      0.041157834231853485,
      0.09074261784553528,
      0.06870361417531967,
      -0.0064025460742414,
      0.012357574887573719,
      -0.047631073743104935,
      -0.0009343066485598683,
      0.030951689928770065,
      -0.06992774456739426,
      0.0013492901343852282,
      0.01123459730297327,
      0.05486881360411644,
      0.01673758774995804,
      0.029040871188044548,
      -0.04938177764415741,
      -0.08158550411462784,
      0.09192688763141632,
      -0.04096817225217819,
      0.0024299202486872673,
      0.10636734962463379,
      0.05905415490269661,
      0.037772174924612045,
      -0.07286335527896881,
      0.042926058173179626,
      -0.021633530035614967,
      -0.03400638327002525,
      -0.04742135480046272,
      -0.03063960000872612,
      0.019863925874233246,
      -0.12448213249444962,
      0.06718694418668747,
      -0.10278245061635971,
      0.000419990043155849,
      -0.000686018553096801,
      0.012514090165495872,
      0.014170479960739613,
      -0.034539878368377686,
      0.014222833327949047,
      -0.028719725087285042,
      0.0404740571975708,
      -0.0059823887422680855,
      0.01808018609881401,
      0.04718277230858803,
      -0.10279609262943268,
      -0.08453632146120071,
      0.010584692470729351,
      -0.05877611041069031,
      -0.024270271882414818,
      -0.03826265037059784,
      0.015858003869652748,
      -0.03758608177304268,
      -0.010422717779874802,
      -0.050248537212610245,
      0.09060217440128326,
      0.0001767338253557682,
      0.027007384225726128,
      0.024962743744254112,
      0.009869161061942577,
      -0.01365216076374054,
      -0.01991688646376133,
      -0.016402747482061386,
      0.010024769231677055,
      -0.06142793968319893,
      -0.016176406294107437,
      0.001577218179591,
      0.014808881096541882,
      -0.03567760810256004,
      -0.07779846340417862,
      0.001978908199816942,
      0.022559693083167076,
      -0.011605090461671352,
      0.042717788368463516,
      0.001858937437646091,
      -0.01488292496651411,
      -0.06112375482916832,
      0.04591759294271469,
      -0.030534617602825165,
      -0.014396514743566513,
      0.050174422562122345,
      0.017664417624473572,
      -0.049673181027173996,
      0.02993699721992016,
      0.05222965404391289,
      -0.029443437233567238,
      0.0017227347707375884,
      -0.02861510030925274,
      0.011615954339504242,
      -0.06333153694868088,
      0.09042657911777496,
      0.0374281108379364,
      -0.049169640988111496,
      -0.08848263323307037,
      0.06422462314367294,
      -0.010808397084474564,
      0.057002805173397064,
      -0.026441682130098343,
      0.016786891967058182,
      -0.02491241693496704,
      -0.024909358471632004,
      -0.05315305292606354,
      -0.013357453048229218,
      0.03535646200180054,
      -0.01917564682662487,
      -0.06388626247644424,
      -0.02224660851061344,
      0.017324628308415413,
      -0.006170675624161959,
      -0.05803925544023514,
      -0.010445205494761467,
      0.0028586164116859436,
      -0.057039178907871246,
      0.022950036451220512,
      0.012997140176594257,
      -0.029584765434265137,
      -0.06746453046798706,
      -0.0041628940962255,
      0.04272184520959854,
      0.02806270122528076,
      0.05436614155769348,
      -0.010929111391305923,
      -0.07648701220750809,
      0.012972562573850155,
      0.015206247568130493,
      0.010403313674032688,
      0.007034238427877426,
      -0.06406010687351227,
      -0.057124581187963486,
      -0.031659189611673355,
      0.02758581005036831,
      -0.03209005296230316,
      -0.018074853345751762,
      0.004288844298571348,
      -0.06383820623159409,
      -0.006668555550277233,
      0.05699644610285759,
      -0.046535030007362366,
      0.0020993168000131845,
      0.05842205509543419,
      -0.03481524810194969,
      -0.06252409517765045,
      0.025219501927495003,
      0.004040860570967197,
      0.010016266256570816,
      0.1334494799375534,
      0.004523040726780891,
      0.025033850222826004,
      -0.046410564333200455,
      0.02105758897960186,
      -0.018748410046100616,
      -0.057948511093854904,
      0.00867113284766674,
      0.03435783088207245,
      -0.006041875574737787,
      0.02010539174079895,
      0.010591155849397182,
      -0.04596351459622383,
      0.014981556683778763,
      -0.013446245342493057,
      0.100089430809021,
      0.030106108635663986,
      -0.071149542927742,
      0.060828797519207,
      0.009699702262878418,
      -0.07267285138368607,
      -0.043407950550317764,
      0.0548122338950634,
      0.004844005219638348,
      0.00058121292386204,
      -0.014548806473612785,
      -0.02767009288072586,
      0.09700285643339157,
      0.09311631321907043,
      -0.0005155520630069077,
      0.047867629677057266,
      0.07869180291891098,
      -0.06863878667354584,
      -0.04355708509683609,
      0.012029951438307762,
      0.026297274976968765,
      0.021268682554364204,
      0.04886867478489876,
      0.023901861160993576,
      0.020948467776179314,
      0.03923172503709793,
      0.006661148741841316,
      -0.004925485234707594,
      0.030257007107138634,
      0.03960040584206581,
      0.034983258694410324,
      -0.05425116419792175,
      -0.004408270586282015,
      -0.040682028979063034,
      0.025678059086203575,
      -0.02071516588330269,
      0.05711833015084267,
      0.06131528690457344,
      -0.04361959174275398,
      0.0640275627374649,
      0.07185149192810059,
      0.020811593160033226,
      -0.03537067398428917,
      0.05343703553080559,
      0.04819900542497635,
      -0.024961501359939575,
      0.015458140522241592,
      -0.021401938050985336,
      0.029054168611764908,
      -0.09575233608484268,
      -0.0372796356678009,
      -0.043372929096221924,
      0.017316022887825966,
      -0.015542387962341309,
      -0.05971219018101692,
      0.014930900186300278,
      -0.09158917516469955,
      -0.038931675255298615,
      -0.0742354467511177,
      -0.03697081282734871,
      -0.01874701865017414,
      0.030086856335401535,
      0.030977584421634674,
      0.05042226240038872,
      0.049129340797662735,
      0.007182757835835218,
      -0.016852961853146553,
      0.05683758482336998,
      -0.010239857248961926,
      -0.007875184528529644,
      0.02475498989224434,
      0.0234695952385664,
      -0.020333414897322655,
      0.019909266382455826,
      0.037043191492557526,
      0.02528349868953228,
      0.013931705616414547,
      -0.04751071333885193,
      0.0025589889846742153,
      -0.02382178232073784,
      -0.020952152088284492,
      -0.08130655437707901,
      0.026794617995619774,
      0.018793784081935883,
      -0.03520656004548073,
      -0.09499993175268173,
      -0.04572080075740814,
      0.015116462484002113,
      0.020916679874062538,
      -0.04517949000000954,
      -0.0033680906053632498,
      0.03477861359715462,
      0.01558557990938425,
      -0.01580278016626835,
      0.059328220784664154,
      0.09219907224178314,
      -0.006699433084577322,
      0.04681168869137764,
      0.0022359839640557766,
      0.023832954466342926,
      -0.010683760046958923,
      -0.04801924154162407,
      0.05795691907405853,
      0.08148744702339172,
      0.050919871777296066,
      -0.050964873284101486,
      -0.09465236961841583,
      0.06080028787255287,
      -0.09154729545116425,
      0.05879739299416542,
      -0.03502814471721649,
      0.052790671586990356,
      -0.001919931499287486,
      0.012154119089245796,
      0.04274946451187134,
      -0.03291960060596466,
      0.10175978392362595,
      -0.0010561647359281778,
      -0.042754460126161575,
      -0.008835555985569954,
      -0.021481867879629135,
      0.03996661677956581,
      0.043570294976234436,
      -0.038927048444747925,
      -0.0417601652443409,
      -0.0541398748755455,
      -0.03853999823331833,
      -0.014549383893609047,
      0.008170573972165585,
      -0.006537475157529116,
      -0.04427306726574898,
      0.04604833573102951,
      -0.052012838423252106,
      0.005273197777569294,
      -0.08458473533391953,
      -0.013401683419942856,
      0.04340801015496254,
      -0.044267117977142334,
      -0.009296259842813015,
      0.050411634147167206,
      0.0010585575364530087,
      0.02294852025806904,
      -0.04898383095860481,
      0.009818902239203453,
      -0.009444530121982098,
      -0.00046558791655115783,
      -0.03636375442147255,
      0.045068204402923584,
      -0.044114623218774796,
      0.016995392739772797,
      0.014221537858247757,
      -0.01690158247947693,
      -0.057411253452301025,
      0.0266399122774601,
      0.02728806436061859,
      -0.009627120569348335,
      0.08598888665437698,
      0.06989550590515137,
      -0.05167723447084427,
      -0.055447373539209366,
      -0.021066095679998398,
      0.11816010624170303,
      0.08630791306495667,
      -0.02046685479581356,
      0.022595610469579697,
      -0.021203942596912384,
      -0.029203206300735474,
      0.050656758248806,
      0.014091359451413155,
      -0.007951854728162289,
      -0.06364705413579941,
      0.0516921766102314,
      0.01193948369473219,
      0.006229980383068323,
      -0.013546507805585861,
      -0.033774808049201965,
      -0.04588514193892479,
      0.0013050999259576201,
      -0.009723804891109467,
      0.022048749029636383,
      0.01926143653690815,
      -0.07228390127420425,
      -0.006290254183113575,
      -0.09701579809188843,
      -0.007559045217931271,
      0.028865523636341095,
      -0.018932687118649483,
      -0.01827072724699974,
      -0.012910193763673306,
      0.043048687279224396,
      -0.019439276307821274,
      -0.023539241403341293,
      -0.04451550543308258,
      -0.02004304900765419,
      0.005881423130631447,
      0.1061338484287262,
      0.01617414876818657,
      0.006308335345238447,
      -0.05317159369587898,
      0.012798339128494263,
      -0.04221615195274353,
      -0.04091942682862282,
      -0.08771464228630066,
      0.014527378603816032,
      -0.02704143337905407,
      -0.10684231668710709,
      0.007334450259804726,
      -0.010989629663527012,
      0.005345596000552177,
      -0.01689390279352665,
      -0.11569950729608536,
      -0.020243868231773376,
      0.01816117763519287,
      -0.012837996706366539,
      -0.04406466335058212,
      -0.00798853486776352,
      -0.011356130242347717,
      0.009814729914069176,
      0.012892327271401882,
      -0.02236974984407425,
      -0.03270186483860016,
      -0.05959467217326164,
      -0.02813236229121685,
      -0.0005435993662104011,
      0.0015401282580569386,
      -0.0737898126244545,
      -0.0029230653308331966,
      -0.04945067688822746,
      -0.0018083382165059447,
      0.05246850475668907,
      0.026954390108585358,
      -0.023600075393915176,
      -0.04956059902906418,
      0.029927203431725502,
      -0.03806743398308754,
      0.034120313823223114,
      0.06291010975837708,
      -0.004160752519965172,
      -0.007086016237735748,
      0.02088790014386177,
      -0.02411176823079586,
      0.01402208674699068,
      -0.04133755713701248,
      -0.02826204150915146,
      -0.006168468855321407,
      0.06820809841156006,
      0.07147082686424255,
      -0.028609037399291992,
      0.042411092668771744,
      0.03853219747543335,
      -0.018060199916362762,
      0.03566388785839081,
      0.009281066246330738,
      -0.06250551342964172,
      0.055855341255664825,
      -0.007856485433876514,
      -0.05135978385806084,
      -0.06317593902349472,
      0.006411529146134853,
      0.012895200401544571,
      -0.004145348444581032,
      -0.06701364368200302,
      0.027369042858481407,
      -0.04672456160187721,
      -0.027842748910188675,
      -0.00514123123139143,
      0.007221152074635029,
      0.04674115777015686,
      0.020424727350473404,
      -0.02722734399139881,
      0.017506776377558708,
      0.035737503319978714,
      -0.01671779900789261,
      0.035759828984737396,
      0.03764200583100319,
      0.08052525669336319,
      -0.046797975897789,
      -0.0163619052618742,
      -0.11294318735599518,
      -0.057319141924381256,
      0.02734963968396187,
      0.106484554708004,
      -0.047336921095848083,
      0.010319788940250874,
      -0.004752777051180601,
      -0.011413884349167347,
      -0.03771606832742691,
      -0.0031370248179882765
    ],
    [
      0.05623936653137207,
      0.07011480629444122,
      0.041888654232025146,
      -0.005282284691929817,
      -0.04750411584973335,
      -0.0008409225847572088,
      -0.00048696048907004297,
      0.01306111179292202,
      -0.018426084890961647,
      -0.07170511782169342,
      -0.022885316982865334,
      0.018488729372620583,
      -0.06945523619651794,
      0.10058144479990005,
      -0.0385998860001564,
      -0.07187431305646896,
      -0.028873585164546967,
      -0.06461641192436218,
      -0.0030877401586622,
      -0.029400255531072617,
      0.03215078264474869,
      0.029755180701613426,
      -0.02217380329966545,
      -0.008114326745271683,
      0.04104125499725342,
      -0.033206429332494736,
      -0.012613313272595406,
      0.02602371759712696,
      0.03389255702495575,
      0.012626971118152142,
      0.03600362315773964,
      0.03964311257004738,
      -0.021510101854801178,
      -0.003002816578373313,
      0.06039145961403847,
      0.026241596788167953,
      0.006272380240261555,
      0.033608537167310715,
      0.0022171048913151026,
      -0.044383931905031204,
      -0.05426240712404251,
      0.03000195510685444,
      0.002082349034026265,
      0.009113164618611336,
      0.014857392758131027,
      -0.06851757317781448,
      0.029422316700220108,
      0.026659158989787102,
      0.05559523031115532,
      0.019198941066861153,
      0.03601640462875366,
      -0.057075828313827515,
      -0.007202921900898218,
      0.05308935046195984,
      -0.0719621479511261,
      -0.030431251972913742,
      0.10015866905450821,
      -0.028113478794693947,
      0.036414988338947296,
      -0.028779590502381325,
      0.013290775008499622,
      -0.09933100640773773,
      -0.08304019272327423,
      0.01955064758658409,
      -0.07997024059295654,
      -0.018194319680333138,
      0.028342261910438538,
      0.026141684502363205,
      -0.02889203652739525,
      0.05845867842435837,
      0.009840098209679127,
      0.04915484040975571,
      0.05220332369208336,
      -0.027138538658618927,
      -0.010783640667796135,
      0.01754814013838768,
      -0.002511443803086877,
      0.11323987692594528,
      -0.006541977170854807,
      -0.045646775513887405,
      -0.0037259464152157307,
      -0.03699294105172157,
      -0.0019570544827729464,
      -0.024147553369402885,
      0.07607113569974899,
      0.0007181148394010961,
      -0.06772437691688538,
      -0.024242961779236794,
      -0.04095608368515968,
      -0.031713541597127914,
      -0.03992674872279167,
      -0.07896652817726135,
      -0.06170438602566719,
      -0.019988663494586945,
      0.022580526769161224,
      -0.02622067555785179,
      -0.0023419521749019623,
      -0.037149541079998016,
      -0.012721662409603596,
      0.008053152821958065,
      -0.0232757106423378,
      -0.024886824190616608,
      0.06577009707689285,
      -0.013764738105237484,
      -0.06815570592880249,
      -0.032611772418022156,
      -0.026896240189671516,
      0.02725685015320778,
      0.05661368370056152,
      -0.04508666694164276,
      0.030328067019581795,
      0.020362965762615204,
      0.08528921008110046,
      0.011722377501428127,
      0.049143850803375244,
      -0.016760339960455894,
      0.01480744406580925,
      -0.05420704931020737,
      -0.0036011661868542433,
      -0.042999159544706345,
      0.0357029102742672,
      -0.0047829849645495415,
      0.033003583550453186,
      0.04943537712097168,
      -0.010062760673463345,
      -0.04254676401615143,
      -0.008796273730695248,
      -0.03281516954302788,
      0.03159686177968979,
      0.04201142489910126,
      -0.009280689060688019,
      -0.024064650759100914,
      -0.008317102678120136,
      -0.003610680578276515,
      -0.01824905164539814,
      -0.04436555877327919,
      -0.04495679959654808,
      -0.05334141105413437,
      0.04417620599269867,
      0.10137104988098145,
      -0.005312925670295954,
      -0.010130289010703564,
      0.04300602152943611,
      -0.02233787812292576,
      -0.005323419813066721,
      0.05710950493812561,
      -0.012396767735481262,
      -0.029848923906683922,
      -0.01919550821185112,
      -0.03744221106171608,
      -0.01181101519614458,
      0.03358105197548866,
      0.0218589436262846,
      0.012304997071623802,
      -0.06697525084018707,
      -0.038342006504535675,
      0.04113798961043358,
      0.045912180095911026,
      -0.005868913140147924,
      -0.014529285952448845,
      -0.11174891144037247,
      -0.07596631348133087,
      0.11046216636896133,
      0.07427076250314713,
      -0.01707438752055168,
      -0.08524869382381439,
      -0.1381499022245407,
      0.06510637700557709,
      -0.00013850886898580939,
      0.0027898915577679873,
      0.036501675844192505,
      0.020765839144587517,
      -0.0028525730594992638,
      0.0301650483161211,
      -0.039445001631975174,
      -0.025941068306565285,
      0.10265012830495834,
      0.012032371945679188,
      0.0544363372027874,
      -0.052695538848638535,
      -0.025776559486985207,
      0.0013002525083720684,
      0.025210149586200714,
      -0.048678088933229446,
      -0.07026331126689911,
      -0.056365881115198135,
      -0.029164621606469154,
      0.00648299278691411,
      -0.039914820343256,
      0.0013906413223594427,
      -0.07979241758584976,
      -0.027576345950365067,
      0.030483828857541084,
      -0.027682842686772346,
      0.0060910494066774845,
      0.042270224541425705,
      -0.013211952522397041,
      -0.0040500108152627945,
      0.034984901547431946,
      0.037749938666820526,
      -0.018802892416715622,
      -0.013317737728357315,
      -0.05606434866786003,
      0.11046405136585236,
      -0.019382674247026443,
      -0.03516136109828949,
      -0.036367017775774,
      -0.017750972881913185,
      0.033544544130563736,
      -0.0030114534310996532,
      -0.04235302656888962,
      0.04062879458069801,
      -0.014009255915880203,
      -0.0031178605277091265,
      -0.0003226129920221865,
      0.002176989335566759,
      -0.022406939417123795,
      0.02470720373094082,
      0.01542775146663189,
      0.0044874208979308605,
      -0.052082084119319916,
      -0.019262170419096947,
      0.0005817862693220377,
      -0.02525606006383896,
      -0.0011826377594843507,
      0.07647861540317535,
      0.03684955835342407,
      -0.03521216660737991,
      -0.023254552856087685,
      -0.01628810539841652,
      0.0030198011081665754,
      0.06991609930992126,
      -0.10083290189504623,
      0.06370504945516586,
      0.005925084464251995,
      -0.036137085407972336,
      0.15768511593341827,
      -0.0025051096454262733,
      0.025283003225922585,
      -0.020465342327952385,
      0.009680291637778282,
      0.09239102900028229,
      -0.1193273663520813,
      0.01690918393433094,
      0.02390759252011776,
      -0.02536102756857872,
      0.00195750268176198,
      -0.052091918885707855,
      0.014273910783231258,
      -0.08061143755912781,
      0.054501596838235855,
      0.044963136315345764,
      0.0018758757505565882,
      -0.009662019088864326,
      0.03799886628985405,
      -0.021662656217813492,
      -0.005769896786659956,
      -0.05566653609275818,
      0.021336155012249947,
      0.04483401030302048,
      -0.009664877317845821,
      0.09188748151063919,
      0.012303249910473824,
      0.007210452109575272,
      -0.0047214762307703495,
      0.03678483888506889,
      0.015026573091745377,
      -0.00639664800837636,
      0.034236010164022446,
      0.049567997455596924,
      -0.07027355581521988,
      -0.008719835430383682,
      -0.025045223534107208,
      0.018975043669342995,
      0.0326179601252079,
      0.0407228097319603,
      -0.060565218329429626,
      0.05607941374182701,
      -0.010292010381817818,
      -0.06717751920223236,
      -0.08906632661819458,
      0.03703467175364494,
      -0.02648378349840641,
      0.0035794090945273638,
      -0.07574555277824402,
      -0.008596246130764484,
      -0.08588913828134537,
      -0.05711912363767624,
      0.004806040320545435,
      -0.03869655728340149,
      -0.0003262734680902213,
      0.04593907669186592,
      0.062073711305856705,
      -0.022550681605935097,
      -0.004091467242687941,
      -0.021162305027246475,
      -0.044553253799676895,
      0.03546779975295067,
      -0.0002126868348568678,
      0.06304322928190231,
      0.049125876277685165,
      0.06992676109075546,
      0.03966139629483223,
      -0.001322735333815217,
      0.053086623549461365,
      0.018011104315519333,
      0.06337002664804459,
      0.04835953563451767,
      0.030412819236516953,
      0.026741692796349525,
      0.052595414221286774,
      -0.030568188056349754,
      -0.06732483953237534,
      -0.0039137895219028,
      0.021449347957968712,
      0.07581504434347153,
      0.037078216671943665,
      -0.060313187539577484,
      -0.07712125033140182,
      -0.07199078053236008,
      -0.03071436658501625,
      0.0026280530728399754,
      0.03747362270951271,
      0.03156980872154236,
      -0.04139184206724167,
      -0.008867246098816395,
      -0.010085592046380043,
      0.006483183708041906,
      -0.0164139773696661,
      -0.09399738162755966,
      0.010672114789485931,
      -0.04868851229548454,
      0.017398780211806297,
      0.030910911038517952,
      -0.08439705520868301,
      0.014764339663088322,
      -0.06040899083018303,
      -0.05467402562499046,
      0.006799337454140186,
      -0.00213234918192029,
      -0.059885259717702866,
      0.0049166325479745865,
      0.016825824975967407,
      0.00679635489359498,
      -0.04074423760175705,
      0.03906525298953056,
      -0.041537728160619736,
      -0.05739424377679825,
      0.021757623180747032,
      -0.002940793987363577,
      0.03586626797914505,
      -0.014089856296777725,
      -0.038725510239601135,
      -0.0505070686340332,
      0.0016391470562666655,
      -0.0018120917957276106,
      -0.032292596995830536,
      0.07637001574039459,
      0.018892064690589905,
      0.031185775995254517,
      0.006826377939432859,
      -0.033925797790288925,
      -0.03490239009261131,
      0.004873276222497225,
      -0.0705026388168335,
      0.04012531414628029,
      0.02360643818974495,
      0.10781577229499817,
      0.03733169659972191,
      -0.0012299907393753529,
      0.017741255462169647,
      0.07940520346164703,
      0.05239001289010048,
      0.04764577001333237,
      -0.0010585912968963385,
      0.018224043771624565,
      0.0118996137753129,
      -0.07397466152906418,
      0.04863329231739044,
      0.01749402843415737,
      0.00960758700966835,
      -0.02977154590189457,
      -0.037258636206388474,
      -0.005408680532127619,
      0.012609577737748623,
      -0.04177694022655487,
      0.008846569806337357,
      -0.025968093425035477,
      0.005917923059314489,
      0.0863773450255394,
      0.013882339000701904,
      -0.005526896100491285,
      0.0524674616754055,
      -0.05326664075255394,
      0.030409006401896477,
      -0.01219573151320219,
      0.04205663129687309,
      -0.013184214010834694,
      0.05778484791517258,
      -0.07584865391254425,
      -0.0516815185546875,
      0.04928142577409744,
      0.014080776832997799,
      0.022412560880184174,
      0.011603694409132004,
      -0.02062268555164337,
      0.03709986433386803,
      -0.03171471878886223,
      -0.03461712598800659,
      -0.014539996162056923,
      0.09332014620304108,
      -0.038808200508356094,
      -0.13638073205947876,
      0.03398942947387695,
      -0.0691976398229599,
      -0.0066979192197322845,
      -0.012875879183411598,
      -0.058305323123931885,
      0.01900160312652588,
      -0.09004027396440506,
      -0.03964507579803467,
      0.08707349002361298,
      -0.02784995175898075,
      -0.0024303782265633345,
      -0.08518100529909134,
      0.006331135053187609,
      0.07420340925455093,
      -0.07037168741226196,
      -0.016077512875199318,
      0.08668990433216095,
      0.01775490678846836,
      -0.013938450254499912,
      -0.07815022766590118,
      -0.031076714396476746,
      0.011597835458815098,
      0.10524461418390274,
      0.08426942676305771,
      -0.07890528440475464,
      0.017806554213166237,
      -0.01176761370152235,
      -0.004523764364421368,
      -0.0025695678777992725,
      -0.08457095921039581,
      -0.029691193252801895,
      0.03607099503278732,
      -0.01698840782046318,
      -0.0019057128811255097,
      -0.06727918237447739,
      -0.006152542307972908,
      0.006195917725563049,
      -0.026072176173329353,
      -0.0020103887654840946,
      0.0012479937868192792,
      0.08925244957208633,
      0.04782029241323471,
      -0.029427001252770424,
      -0.015085945837199688,
      -0.026972057297825813,
      0.03531888872385025,
      -0.00041127888835035264,
      0.05590054392814636,
      -0.03732261434197426,
      -0.027692601084709167,
      0.0005109043559059501,
      0.024064617231488228,
      -0.009901539422571659,
      -0.024332737550139427,
      -0.04903360456228256,
      -0.0640575960278511,
      -0.04639841616153717,
      -0.017615532502532005,
      0.02963821217417717,
      -0.034029409289360046,
      3.2049851142801344e-05,
      0.056667838245630264,
      0.020479854196310043,
      -0.013596643693745136,
      0.0009623577352613211,
      0.03187260404229164,
      -0.02185163088142872,
      0.039588045328855515,
      -0.023345131427049637,
      0.02045752853155136,
      0.06272842735052109,
      0.03642554208636284,
      -0.0378587432205677,
      -0.04531385377049446,
      -0.05189675837755203,
      -0.0627441480755806,
      -0.01014420110732317,
      -0.06689827144145966,
      0.06172401085495949,
      -0.0035307658836245537,
      0.07786504179239273,
      0.009482917375862598,
      0.0538947768509388,
      0.030470477417111397,
      -0.0163249708712101,
      -0.0467795766890049,
      0.02611738257110119,
      0.07305643707513809,
      0.01778276637196541,
      0.04495389759540558
    ],
    [
      0.012663731351494789,
      0.02230936661362648,
      0.06846945732831955,
      -0.0005465793656185269,
      -0.002895321696996689,
      0.08093871921300888,
      -0.02042183093726635,
      0.016579069197177887,
      -0.0583670400083065,
      0.0013017585733905435,
      0.04247989133000374,
      0.04995075985789299,
      -0.029036708176136017,
      -0.07228546589612961,
      0.11906631290912628,
      0.011334827169775963,
      -0.04359707608819008,
      0.05744191259145737,
      0.03487325832247734,
      -0.07426854968070984,
      0.03575466200709343,
      0.014859728515148163,
      -0.0014079795219004154,
      0.03529253974556923,
      0.04790274426341057,
      -0.010980791412293911,
      -0.03604748845100403,
      -0.00011120807903353125,
      -0.02692006155848503,
      -0.006460507400333881,
      -0.11706656217575073,
      0.07047223299741745,
      -0.03553224354982376,
      -0.025827666744589806,
      -0.037629470229148865,
      -0.010416340082883835,
      0.02457146905362606,
      -0.032157253473997116,
      -0.005516715347766876,
      0.019573451951146126,
      -0.0040968358516693115,
      0.02235104888677597,
      0.0035343642812222242,
      -0.0259732436388731,
      -0.04322735592722893,
      0.029253141954541206,
      0.08974695205688477,
      0.025255516171455383,
      0.007819472812116146,
      -0.023136408999562263,
      -0.05204031243920326,
      -0.03665457293391228,
      -0.03439926728606224,
      0.02293682098388672,
      -0.05193395912647247,
      0.08326447755098343,
      -0.006398394703865051,
      -0.026693085208535194,
      0.013220366090536118,
      0.020971249788999557,
      0.0012312927283346653,
      0.06708135455846786,
      -0.0202384814620018,
      0.06018499284982681,
      -0.08155833184719086,
      0.011326753534376621,
      0.009643442928791046,
      0.004016857594251633,
      0.0015531107783317566,
      0.018887050449848175,
      0.004627434071153402,
      -0.08382032811641693,
      0.002181786810979247,
      0.044279590249061584,
      0.031746361404657364,
      0.0037597434129565954,
      0.07187698781490326,
      0.06144298240542412,
      -0.0022424389608204365,
      0.037136077880859375,
      0.02733573317527771,
      -0.050079911947250366,
      0.0015785108553245664,
      -0.06237930431962013,
      -0.012272139079868793,
      0.00436513964086771,
      -0.005693361163139343,
      -0.03054392896592617,
      0.056564513593912125,
      0.054858967661857605,
      0.05129363015294075,
      -0.049383051693439484,
      -0.04450823366641998,
      0.008326110430061817,
      -0.11731895059347153,
      0.029766185209155083,
      0.04102613031864166,
      0.0008487474988214672,
      -0.017273804172873497,
      -0.008949203416705132,
      0.010468785651028156,
      0.01189215574413538,
      -0.017595812678337097,
      -0.02808554843068123,
      -0.02763376571238041,
      -0.050743576139211655,
      0.057445965707302094,
      0.03783933445811272,
      0.09272012114524841,
      0.0013853564159944654,
      -0.05126440152525902,
      -0.031563423573970795,
      0.039603326469659805,
      0.0036007631570100784,
      -0.019313713535666466,
      0.00957408081740141,
      -0.05786139518022537,
      0.02297978289425373,
      0.054319433867931366,
      0.003588398452848196,
      0.07015297561883926,
      -0.03242762014269829,
      -0.026055891066789627,
      -0.06573408097028732,
      0.08262821286916733,
      0.03919924795627594,
      -0.029054706916213036,
      0.008207188919186592,
      0.020715804770588875,
      0.0005279870238155127,
      0.03223823010921478,
      0.028044775128364563,
      -0.04825080931186676,
      -0.04129881411790848,
      -0.06256069242954254,
      0.028981337323784828,
      -0.042281318455934525,
      0.03970728814601898,
      0.10014709085226059,
      0.05230739340186119,
      0.006826466880738735,
      -0.006527333986014128,
      -0.04504050686955452,
      -0.08618154376745224,
      0.021260127425193787,
      -0.016214385628700256,
      -0.027128232643008232,
      0.05790689215064049,
      0.07109978049993515,
      0.07116175442934036,
      -0.02618040144443512,
      0.0781976506114006,
      0.020297661423683167,
      -0.02791866846382618,
      -0.0760161355137825,
      -0.04165680706501007,
      0.05666746199131012,
      0.023402642458677292,
      -0.009705807082355022,
      0.023848744109272957,
      0.06071707606315613,
      0.036189187318086624,
      -0.04920811206102371,
      0.03803187608718872,
      0.03442427143454552,
      0.007445535622537136,
      -0.04413536936044693,
      -0.011687777005136013,
      0.049886610358953476,
      0.022750919684767723,
      0.006442061625421047,
      -0.02676587551832199,
      -0.040673524141311646,
      0.048211559653282166,
      -0.02379366010427475,
      -0.019335808232426643,
      -0.058680009096860886,
      -0.02119802124798298,
      0.07097990065813065,
      -0.025860555469989777,
      0.03147924318909645,
      0.03999852389097214,
      -0.0529075488448143,
      -0.07240881025791168,
      0.07938630133867264,
      -0.0369635708630085,
      0.06007120758295059,
      0.0026375579182058573,
      0.007930334657430649,
      -0.03306284919381142,
      -0.027330448850989342,
      0.026016006246209145,
      0.0010450109839439392,
      0.00843864120543003,
      0.0181190837174654,
      0.03541121259331703,
      0.023354625329375267,
      0.0007840266334824264,
      -0.029868073761463165,
      -0.06450610607862473,
      0.030303480103611946,
      -0.01710844784975052,
      -0.01783253625035286,
      -0.06212450936436653,
      0.06085248291492462,
      -0.09740608185529709,
      -0.012001421302556992,
      -0.014749493449926376,
      0.05726134032011032,
      0.04254575073719025,
      -0.027455801144242287,
      0.03319510444998741,
      -0.07872845977544785,
      0.0009913385147228837,
      0.061897024512290955,
      -0.008680484257638454,
      -0.0704529732465744,
      -0.09671276807785034,
      -0.056034915149211884,
      0.016131747514009476,
      0.03018307127058506,
      -0.013551659882068634,
      -0.018457889556884766,
      -0.03486417606472969,
      0.036036401987075806,
      -0.03543654829263687,
      -0.037271615117788315,
      -0.032557111233472824,
      0.05378687009215355,
      -0.005428241100162268,
      0.06612218171358109,
      0.06909461319446564,
      0.016051631420850754,
      0.05058399215340614,
      -0.027368323877453804,
      -0.04178083315491676,
      -0.0010753268143162131,
      0.0489208959043026,
      -0.032952841371297836,
      0.06547294557094574,
      -0.01409924030303955,
      0.037769999355077744,
      -0.0005092196515761316,
      -0.04677583649754524,
      0.01928195357322693,
      -0.013362596742808819,
      -0.030748950317502022,
      0.02007191628217697,
      0.040292978286743164,
      0.04024491831660271,
      2.3783029973856173e-05,
      -0.005239369347691536,
      -0.012100047431886196,
      0.009668275713920593,
      -0.058699123561382294,
      -0.07390185445547104,
      -0.006745429243892431,
      0.04424971342086792,
      0.10943818092346191,
      -0.044616565108299255,
      0.041851989924907684,
      0.020333457738161087,
      0.031592920422554016,
      -0.00017460514209233224,
      -0.013351433910429478,
      0.03911188617348671,
      -0.03096158802509308,
      -0.07043538242578506,
      0.05322166904807091,
      -0.102826327085495,
      0.00022195771452970803,
      -0.0256908368319273,
      0.03092699684202671,
      -0.016561634838581085,
      -0.02498181164264679,
      0.033908143639564514,
      0.025485416874289513,
      -0.013953778892755508,
      0.03527328372001648,
      -0.03864794224500656,
      -0.04945768415927887,
      0.0014411220327019691,
      -0.021141979843378067,
      0.03732053190469742,
      -0.001681838883087039,
      -0.018452275544404984,
      -0.02275908552110195,
      -0.04217572137713432,
      0.04492630064487457,
      0.0313691683113575,
      0.04133545234799385,
      -0.06188321113586426,
      0.049343809485435486,
      0.0073159062303602695,
      -0.05711369588971138,
      -0.020181473344564438,
      -0.03660697489976883,
      0.00787357147783041,
      0.04849836230278015,
      0.04979385808110237,
      -0.01497543416917324,
      -0.05762355029582977,
      -0.009693961590528488,
      0.045234568417072296,
      0.016645953059196472,
      -0.04693761095404625,
      0.0621938481926918,
      -0.010720809921622276,
      -0.020110536366701126,
      0.05983511358499527,
      0.0017106711165979505,
      0.051222555339336395,
      0.0366368405520916,
      0.03029373660683632,
      0.052506476640701294,
      -0.03796127066016197,
      0.021667320281267166,
      -0.05749695748090744,
      -0.016864638775587082,
      -0.0007316401461139321,
      0.01681281067430973,
      -0.019208518788218498,
      0.02898671105504036,
      -0.05896244943141937,
      0.02179182879626751,
      -0.019500721246004105,
      0.0022403132170438766,
      -0.03508127108216286,
      -0.05154039338231087,
      -0.0040626199916005135,
      0.0462687723338604,
      -0.04068610817193985,
      -0.014205021783709526,
      -0.09469907730817795,
      -0.008531556464731693,
      -0.047254327684640884,
      -0.09965047240257263,
      0.040083348751068115,
      0.0019239234970882535,
      -0.008674564771354198,
      0.038510292768478394,
      0.028873508796095848,
      0.023580841720104218,
      -0.0037289338652044535,
      -0.01029644999653101,
      0.08321794867515564,
      -0.0671897903084755,
      -0.04024802893400192,
      -0.009091002866625786,
      -0.026906674727797508,
      0.02268523909151554,
      0.05210142210125923,
      0.016578305512666702,
      -0.06361965090036392,
      0.043364301323890686,
      -0.07737065106630325,
      0.02773103304207325,
      0.06438050419092178,
      -0.012730452232062817,
      -0.10789193958044052,
      -0.10314249247312546,
      -0.06777980923652649,
      0.0511368066072464,
      -0.01618148200213909,
      -0.08006378263235092,
      -0.0020733652636408806,
      -0.034751489758491516,
      -0.022288279607892036,
      0.024843387305736542,
      0.04271913692355156,
      0.03789116442203522,
      0.09192449599504471,
      0.006944816559553146,
      0.07529667019844055,
      0.059805940836668015,
      -0.007993883453309536,
      0.03536485508084297,
      0.07851623743772507,
      -0.04867884889245033,
      -0.03430373966693878,
      0.018202178180217743,
      -0.0027549301739782095,
      0.008507541380822659,
      0.1042933389544487,
      -0.0244565699249506,
      -0.0039810482412576675,
      0.033923715353012085,
      0.0011257297592237592,
      0.040566183626651764,
      -0.11164326220750809,
      0.004199292045086622,
      0.021821154281497,
      0.006168534979224205,
      0.06332474201917648,
      0.0770484134554863,
      0.01749277673661709,
      0.008403090760111809,
      -0.038200922310352325,
      -0.02352311462163925,
      0.021938979625701904,
      -0.0039948089979588985,
      -0.017016056925058365,
      0.03376435860991478,
      0.07099880278110504,
      0.05772337317466736,
      -0.021878128871321678,
      -0.00525916600599885,
      -0.010343682952225208,
      0.026512328535318375,
      0.047246579080820084,
      0.000325420027365908,
      -0.005143582355231047,
      0.0016856876900419593,
      0.07361272722482681,
      -0.04107905179262161,
      -0.024052534252405167,
      -0.02749626897275448,
      0.029510002583265305,
      0.015608780086040497,
      -0.032794684171676636,
      0.007233111187815666,
      -0.019452985376119614,
      0.052536740899086,
      0.10083285719156265,
      0.06507979333400726,
      -0.009541238658130169,
      0.020512372255325317,
      0.03162933140993118,
      0.028613196685910225,
      0.061410341411828995,
      -0.02481631562113762,
      0.051423579454422,
      0.04375181719660759,
      -0.03679971024394035,
      -0.01303694024682045,
      0.001747423317283392,
      -0.03179778531193733,
      0.035077691078186035,
      0.01159639097750187,
      -0.04460034891963005,
      0.06698410212993622,
      0.03368391469120979,
      -0.011204150505363941,
      0.021700480952858925,
      -0.027226442471146584,
      0.02181982435286045,
      -0.06821466982364655,
      0.04597272351384163,
      -0.0558711402118206,
      -0.044466450810432434,
      0.05040750652551651,
      0.011997723951935768,
      -0.039887070655822754,
      0.014194509014487267,
      0.12039102613925934,
      -0.06167253851890564,
      -0.06711607426404953,
      -0.04890962690114975,
      -0.011427545920014381,
      -0.03756963461637497,
      0.006006074137985706,
      0.04499366506934166,
      0.012404914014041424,
      0.005773015320301056,
      -0.0770065113902092,
      -0.017178557813167572,
      -0.02132456935942173,
      -0.019924961030483246,
      -0.09322353452444077,
      -0.03047826699912548,
      0.021520374342799187,
      -0.023989003151655197,
      0.010239116847515106,
      -0.02226036600768566,
      -0.031046081334352493,
      0.008743591606616974,
      -0.059974633157253265,
      -0.005333486013114452,
      0.0037851454690098763,
      0.012028965167701244,
      -0.0070164198987185955,
      -0.05429356172680855,
      0.021648941561579704,
      -0.060176461935043335,
      0.034628067165613174,
      -0.013228755444288254,
      -0.06251361966133118,
      0.057765573263168335,
      -0.08352143317461014,
      -0.14405187964439392,
      -0.06296755373477936,
      -0.014487268403172493,
      0.027603931725025177,
      -0.0002444786368869245,
      -0.0074002379551529884,
      0.0329681821167469,
      0.07930489629507065,
      0.020933501422405243,
      -0.008618349209427834,
      0.013303986750543118,
      0.02637801319360733,
      0.022691765800118446,
      0.0806795060634613
    ],
    [
      0.08829455822706223,
      0.01902771182358265,
      0.07119905203580856,
      0.031554289162158966,
      -0.0678897500038147,
      0.05049106106162071,
      0.007247991859912872,
      -0.02892591804265976,
      -0.02707182615995407,
      -0.010080202482640743,
      0.017814163118600845,
      0.03976483643054962,
      -0.048266422003507614,
      -0.042559992522001266,
      -0.052311066538095474,
      0.03327184543013573,
      0.018868139013648033,
      -0.011838377453386784,
      0.08643445372581482,
      0.009741900488734245,
      0.027555184438824654,
      0.050011422485113144,
      0.006386108696460724,
      0.0468301922082901,
      -0.001918018446303904,
      -0.04165440425276756,
      0.034925032407045364,
      -0.06337066739797592,
      -0.04818183556199074,
      -0.03581654280424118,
      0.05542387813329697,
      0.0009887064807116985,
      0.003264240687713027,
      -0.01677035167813301,
      0.04706325754523277,
      -0.03831532970070839,
      -0.02095651626586914,
      -0.016008595004677773,
      0.006687893066555262,
      0.020476272329688072,
      0.0033350312151014805,
      0.02809613198041916,
      0.07767236232757568,
      0.07571578770875931,
      -0.03561009094119072,
      0.0695091187953949,
      0.05490266904234886,
      -0.08017496019601822,
      -0.022635508328676224,
      0.022480929270386696,
      0.0461798831820488,
      0.009792139753699303,
      -0.05605389550328255,
      -0.01946110464632511,
      0.02509297989308834,
      0.04015824571251869,
      0.0849163755774498,
      -0.06566406786441803,
      -0.001987708732485771,
      0.04760096222162247,
      -0.03265058621764183,
      -0.06421545147895813,
      -0.0031776060350239277,
      0.07344259321689606,
      -0.06944181025028229,
      0.037755176424980164,
      0.12730646133422852,
      0.000346450658980757,
      0.011029526591300964,
      0.009543935768306255,
      0.07922818511724472,
      -0.052357058972120285,
      -0.058227162808179855,
      0.05859270319342613,
      0.03825437277555466,
      -0.0028941037598997355,
      -0.08088601380586624,
      0.019053656607866287,
      -0.010195697657763958,
      -0.06200949102640152,
      -0.03581523522734642,
      0.048281099647283554,
      -0.004396207630634308,
      -0.06697843968868256,
      -0.03217321261763573,
      0.0007581800455227494,
      0.01930697076022625,
      0.006085780914872885,
      0.03102816455066204,
      0.034041184931993484,
      -0.00028868354274891317,
      -0.03904228284955025,
      -0.009770479053258896,
      0.02096494846045971,
      -0.009046240709722042,
      0.02357134036719799,
      -0.013356399722397327,
      -0.035248734056949615,
      0.003818635130301118,
      0.013707255944609642,
      0.023517969995737076,
      -0.10188112407922745,
      -0.014250131323933601,
      -0.02015642262995243,
      0.003711711149662733,
      0.05702066421508789,
      0.04303116351366043,
      -0.03728624805808067,
      0.048315227031707764,
      -0.04539359360933304,
      -0.051558125764131546,
      -0.07229840755462646,
      0.03647162392735481,
      0.006593665573745966,
      -0.06746438890695572,
      -0.0182937141507864,
      0.05651332065463066,
      -0.022417845204472542,
      -0.03479909896850586,
      -0.033918555825948715,
      -0.02690288797020912,
      0.03065541945397854,
      0.07352004200220108,
      -0.07084772735834122,
      0.06860221922397614,
      0.01652209274470806,
      0.004144202917814255,
      -0.022729897871613503,
      -0.02551404759287834,
      -0.06947316974401474,
      0.016673875972628593,
      -0.033654071390628815,
      -0.03457222133874893,
      -0.002267541829496622,
      -0.007215831894427538,
      -0.017333054915070534,
      0.04425148293375969,
      0.022848844528198242,
      0.009533007629215717,
      -0.0024168286472558975,
      -0.015358869917690754,
      -0.002177909715101123,
      -0.0074503300711512566,
      -0.028117546811699867,
      0.023586902767419815,
      0.017905553802847862,
      -0.018598442897200584,
      0.012439454905688763,
      -0.02877674624323845,
      -0.015176487155258656,
      0.013224154710769653,
      0.02356010302901268,
      -0.021162590011954308,
      0.034867580980062485,
      -0.1485447734594345,
      0.003489595837891102,
      0.009084110148251057,
      -0.06336896866559982,
      -0.031442392617464066,
      0.052619606256484985,
      0.019902504980564117,
      -0.06622324138879776,
      -0.0051368288695812225,
      0.031870365142822266,
      0.057433679699897766,
      0.01784677244722843,
      0.006686706095933914,
      -0.003828141139820218,
      0.06354188919067383,
      0.019949134439229965,
      -0.022923825308680534,
      -0.07933339476585388,
      -0.11235865950584412,
      0.037539683282375336,
      0.0733828991651535,
      -0.13194327056407928,
      -0.009382465854287148,
      -0.020880863070487976,
      0.09112169593572617,
      0.010650284588336945,
      0.008875187486410141,
      -0.014800690114498138,
      0.025753438472747803,
      0.04869433119893074,
      0.010917017236351967,
      0.020666174590587616,
      0.1019129678606987,
      0.017613625153899193,
      0.0005654012784361839,
      0.032763831317424774,
      -0.011983981356024742,
      0.006663959473371506,
      0.01840171590447426,
      0.008962897583842278,
      0.06368672847747803,
      -0.08140864223241806,
      0.002565976930782199,
      0.0235286932438612,
      -0.1099325567483902,
      0.054797280579805374,
      -0.04983976110816002,
      0.06027602404356003,
      -0.04085208475589752,
      -0.07107272744178772,
      0.02793031558394432,
      0.004867554176598787,
      0.058452457189559937,
      -0.019964031875133514,
      0.07966551184654236,
      0.03589102625846863,
      -0.018738973885774612,
      -0.003876744071021676,
      -0.020389080047607422,
      0.06997524201869965,
      -0.008667794987559319,
      -0.08038919419050217,
      -0.03293740004301071,
      -0.06725343316793442,
      -0.058587271720170975,
      0.07058504223823547,
      -0.059895142912864685,
      0.04890473932027817,
      0.07838024944067001,
      0.01585562713444233,
      0.08187296986579895,
      0.012867198325693607,
      -0.06212592497467995,
      -0.027592159807682037,
      0.03762079402804375,
      -0.07473087310791016,
      0.03761542588472366,
      -0.0035449021961539984,
      0.011097935028374195,
      0.021857446059584618,
      -0.03675966337323189,
      0.03962436318397522,
      -0.043838344514369965,
      -0.037973616272211075,
      -0.005050718318670988,
      -0.006508013233542442,
      0.007981190457940102,
      -0.005020703189074993,
      -0.04987534135580063,
      -0.011564072221517563,
      0.006186824291944504,
      0.07986076176166534,
      0.0031871723476797342,
      0.08582184463739395,
      0.019142787903547287,
      0.015226176008582115,
      -0.06659173220396042,
      0.07475917786359787,
      -0.04538533464074135,
      -0.009897937066853046,
      -0.03288066387176514,
      0.06742532551288605,
      0.019730541855096817,
      0.004343761131167412,
      0.03419872745871544,
      0.01265608612447977,
      -0.028592903167009354,
      0.001925829565152526,
      -0.03786834329366684,
      1.395832350681303e-05,
      -0.0012504043988883495,
      -0.06735564768314362,
      0.06659648567438126,
      -0.010843530297279358,
      0.036760859191417694,
      0.031275879591703415,
      0.0174156054854393,
      -0.04277496412396431,
      0.052883658558130264,
      0.003827446373179555,
      0.011511977761983871,
      -0.011836813762784004,
      0.03899984806776047,
      0.03556343540549278,
      -0.006793233100324869,
      -0.011600980535149574,
      0.017964519560337067,
      -0.006778090726584196,
      0.024897022172808647,
      0.02273198775947094,
      -0.03265567496418953,
      0.08010262995958328,
      -0.059496425092220306,
      -0.0904771015048027,
      -0.018351133912801743,
      -0.009953404776751995,
      0.03805777058005333,
      0.028531881049275398,
      -0.02890714257955551,
      0.04155324399471283,
      0.030676143243908882,
      0.08929381519556046,
      0.010710643604397774,
      -0.030281396582722664,
      0.054603394120931625,
      -0.009167713113129139,
      0.09304668754339218,
      0.018218446522951126,
      -0.026277808472514153,
      0.01571347750723362,
      0.030817750841379166,
      -0.05164972320199013,
      -0.03962437063455582,
      -0.020840803161263466,
      -0.0495140478014946,
      0.012362939305603504,
      0.007382718380540609,
      -0.02861800231039524,
      -0.06548406928777695,
      0.012290531769394875,
      -0.09930310398340225,
      -0.04998193681240082,
      0.009719978086650372,
      0.0008378569036722183,
      -0.0333167240023613,
      -0.014673745259642601,
      -0.0038469843566417694,
      0.07361318171024323,
      0.06964821368455887,
      -0.07982166856527328,
      0.010995570570230484,
      -0.02715362422168255,
      -0.07695621997117996,
      0.11296170204877853,
      0.05794176831841469,
      0.016310375183820724,
      -0.060669127851724625,
      0.021927809342741966,
      -0.05400019884109497,
      -0.002806684235110879,
      -0.004498263355344534,
      -0.010082345455884933,
      -0.05454342067241669,
      -0.02436845749616623,
      -0.006628517061471939,
      0.048067908734083176,
      -0.03355162590742111,
      0.0017967744497582316,
      0.06086413562297821,
      -0.0018760954262688756,
      0.054395657032728195,
      0.061843618750572205,
      -0.006678085308521986,
      0.016132166609168053,
      -0.00410672789439559,
      0.010702572762966156,
      -0.00527889933437109,
      -0.03884001821279526,
      0.032872024923563004,
      -0.019528377801179886,
      -0.0015591580886393785,
      0.02203274331986904,
      -0.13901305198669434,
      0.053852930665016174,
      -0.09944610297679901,
      -0.12476598471403122,
      -0.02383280359208584,
      0.014681481756269932,
      0.01623215712606907,
      -0.07925358414649963,
      0.0253414548933506,
      -0.029217418283224106,
      -0.006036646664142609,
      0.10030995309352875,
      0.02728104218840599,
      0.02684212476015091,
      -0.05766521394252777,
      0.03690143674612045,
      0.05006306618452072,
      0.009779950603842735,
      0.01370075810700655,
      0.05060000345110893,
      -0.05680462345480919,
      -0.018048914149403572,
      -0.008989698253571987,
      0.04650542140007019,
      0.03983891010284424,
      0.0417194589972496,
      0.011894267052412033,
      0.11416169255971909,
      -0.02270796149969101,
      0.02118794247508049,
      -0.06893544644117355,
      -0.02198694460093975,
      -0.008324452675879002,
      0.019439056515693665,
      0.043284475803375244,
      -0.019541680812835693,
      0.007103823125362396,
      0.026992615312337875,
      0.028869492933154106,
      0.012882157228887081,
      -0.019026590511202812,
      -0.020216140896081924,
      0.0014685309724882245,
      -0.05024924874305725,
      -0.0023556267842650414,
      -0.03780804201960564,
      -0.032515719532966614,
      -0.0075937663204967976,
      -0.04288511723279953,
      -0.03741179406642914,
      -0.008376047946512699,
      -0.02693798765540123,
      0.07394886016845703,
      0.10316495597362518,
      0.06242091581225395,
      0.07841409742832184,
      0.04202433302998543,
      0.05842696502804756,
      -0.04730026051402092,
      0.016684243455529213,
      -0.03003869764506817,
      -0.10275425761938095,
      -0.013427471742033958,
      0.026989620178937912,
      0.05000298097729683,
      -0.046072185039520264,
      0.005131660494953394,
      -0.055685725063085556,
      -0.04899773374199867,
      -0.09689216315746307,
      0.08193320780992508,
      0.013389612548053265,
      -0.02127016708254814,
      -0.051299918442964554,
      0.00046750795445404947,
      0.07332540303468704,
      -0.00747666647657752,
      -0.05227445065975189,
      0.059673719108104706,
      0.03379155695438385,
      -0.012736492790281773,
      -0.06832586973905563,
      0.0355740524828434,
      0.03325974568724632,
      0.04558093473315239,
      0.05018427222967148,
      -0.01062526274472475,
      -0.048336561769247055,
      0.03405779227614403,
      0.003936038818210363,
      0.059940405189991,
      -0.07981206476688385,
      0.04359868913888931,
      -0.0003016384143847972,
      0.029780419543385506,
      -0.04290364310145378,
      -0.07948395609855652,
      0.06869488209486008,
      -0.0014254762791097164,
      -0.06045274809002876,
      0.037854041904211044,
      0.03223402053117752,
      0.043088193982839584,
      -0.037885501980781555,
      0.07971392571926117,
      0.03435933589935303,
      -0.03450307250022888,
      0.0022964105010032654,
      0.05801939591765404,
      0.026603126898407936,
      0.015520244836807251,
      -0.001235534087754786,
      -0.010785531252622604,
      -0.02214149944484234,
      -0.011982851661741734,
      0.04573380574584007,
      -0.036353178322315216,
      -0.036379843950271606,
      0.0183352492749691,
      -0.02403930388391018,
      0.018206583335995674,
      0.03797130286693573,
      0.0691104605793953,
      0.06303814053535461,
      0.0011127962497994304,
      -0.00588860921561718,
      0.012728787958621979,
      0.04284936562180519,
      -0.03563082218170166,
      0.009889335371553898,
      -0.04013552889227867,
      0.02110881730914116,
      -0.06615853309631348,
      -0.027303805574774742,
      -0.035819534212350845,
      -0.00548432907089591,
      0.025119133293628693,
      0.004831991158425808,
      -0.07062861323356628,
      -0.004810081794857979,
      -0.005526926834136248,
      -0.04191175848245621,
      -0.00453092809766531,
      -0.019602837041020393,
      0.08713100850582123,
      -0.05721699073910713,
      -0.06693780422210693
    ],
    [
      0.03645035997033119,
      0.01310819573700428,
      -0.0077875223942101,
      0.01229601539671421,
      0.005958369001746178,
      -0.06938210874795914,
      0.004846200812608004,
      -0.007385645527392626,
      -0.022056609392166138,
      -0.007218172773718834,
      -0.08555179089307785,
      -0.04150564968585968,
      0.005067488178610802,
      0.01986296847462654,
      -0.007357805501669645,
      -0.04024764895439148,
      0.040140315890312195,
      -0.0003660291258711368,
      0.05235427990555763,
      -0.10233687609434128,
      -0.04861462861299515,
      0.04938817024230957,
      0.0611480176448822,
      0.03732281178236008,
      -0.028231743723154068,
      0.025087524205446243,
      -0.05276480317115784,
      -0.020130248740315437,
      0.0293124970048666,
      0.04425560310482979,
      0.008734720759093761,
      -0.01428314670920372,
      0.047365326434373856,
      -0.012768210843205452,
      0.021030377596616745,
      -0.05533311516046524,
      0.020241694524884224,
      -0.058775730431079865,
      0.038234055042266846,
      0.007713199593126774,
      -0.026135340332984924,
      -0.0016720773419365287,
      0.06811851263046265,
      0.01479481440037489,
      -0.021600391715765,
      0.06833876669406891,
      0.03670211881399155,
      0.013804134912788868,
      0.013924796134233475,
      -0.015266521833837032,
      -0.021335814148187637,
      -0.010392416268587112,
      -0.05439748615026474,
      -0.023840978741645813,
      -0.10452044755220413,
      -0.009124134667217731,
      0.01295017171651125,
      0.03296088054776192,
      0.05118831619620323,
      -0.0576951839029789,
      0.021596835926175117,
      -0.045870739966630936,
      -0.006057375110685825,
      0.03797823563218117,
      0.01643693819642067,
      -0.006280332803726196,
      -0.06267783045768738,
      0.020608441904187202,
      0.01150000561028719,
      -0.07298249751329422,
      -0.04153097793459892,
      0.058433592319488525,
      -0.03679577261209488,
      -0.02128049172461033,
      0.06534477323293686,
      0.0038690792862325907,
      0.0014712285483255982,
      0.038495637476444244,
      -0.03755934536457062,
      -0.04628591984510422,
      -0.010160980746150017,
      0.04241565242409706,
      0.011668655090034008,
      0.017682122066617012,
      0.018689243122935295,
      0.07040974497795105,
      0.027771303430199623,
      0.004269044380635023,
      -0.03058842197060585,
      0.004430638160556555,
      -0.04064751788973808,
      -0.0073235975578427315,
      -0.01943741925060749,
      0.061482448130846024,
      -0.037449371069669724,
      -0.038631733506917953,
      -0.04605650156736374,
      0.06472425907850266,
      0.023871861398220062,
      -0.06309863924980164,
      0.06915931403636932,
      -0.044015780091285706,
      0.023089060559868813,
      -0.028658289462327957,
      -0.014477751217782497,
      -0.10102546960115433,
      -0.03164468705654144,
      0.011713691055774689,
      -0.08813061565160751,
      0.012744051404297352,
      -0.009269786067306995,
      -0.04740015044808388,
      -0.07004003971815109,
      0.02657563053071499,
      -0.0304560624063015,
      -0.029518544673919678,
      0.010421182960271835,
      -0.006652383599430323,
      -0.042457595467567444,
      0.04811546579003334,
      -0.012650769203901291,
      0.027921902015805244,
      -0.012414989992976189,
      -0.00458746450021863,
      -0.06295982748270035,
      0.0501600056886673,
      0.04317581281065941,
      0.02174208126962185,
      -0.07503978163003922,
      -0.03405012935400009,
      -0.0014178031124174595,
      -0.05839557945728302,
      -0.03394436091184616,
      -0.0457606203854084,
      -0.06949904561042786,
      -0.080325186252594,
      0.025548221543431282,
      0.00999996718019247,
      0.014299764297902584,
      0.02341565489768982,
      0.08095894008874893,
      0.0448310412466526,
      -0.010007329285144806,
      0.03786461055278778,
      -0.0037545436061918736,
      0.0374516025185585,
      -0.026897618547081947,
      -0.000752909283619374,
      0.012843978591263294,
      -0.011961453594267368,
      -0.0059737553820014,
      0.03411669656634331,
      -0.032362405210733414,
      0.01515025645494461,
      0.09205207228660583,
      0.011796134524047375,
      -0.02865712158381939,
      0.012827144004404545,
      -0.07422017306089401,
      0.03788899630308151,
      -0.03679869696497917,
      -0.04376158490777016,
      0.04386774078011513,
      0.012811711058020592,
      0.07215523719787598,
      -0.02668551355600357,
      0.11122916638851166,
      0.024753309786319733,
      -0.009393692947924137,
      0.019941728562116623,
      -0.00650069210678339,
      0.014357991516590118,
      -0.024034790694713593,
      0.10340401530265808,
      -0.05702846124768257,
      -0.04242929816246033,
      0.022305868566036224,
      0.044338781386613846,
      -0.026544542983174324,
      -0.01429154071956873,
      -0.02678077295422554,
      0.030303997918963432,
      -0.00655665947124362,
      -0.027214588597416878,
      -0.035744551569223404,
      -0.11001758277416229,
      0.039677560329437256,
      0.018274640664458275,
      0.028028469532728195,
      0.018147246912121773,
      -0.0360933281481266,
      0.03672243654727936,
      -0.0142715685069561,
      0.0026513177435845137,
      0.06988830864429474,
      -0.09873995929956436,
      0.018657207489013672,
      -0.005193009972572327,
      -0.0024315498303622007,
      -0.0625745877623558,
      -0.03358282893896103,
      -0.06161132827401161,
      0.046246353536844254,
      -0.06365560740232468,
      -0.007607394363731146,
      0.053034234791994095,
      -0.007942489348351955,
      -0.045735884457826614,
      0.05625690892338753,
      0.0014385173562914133,
      -0.020673682913184166,
      -0.0385589599609375,
      0.04194161295890808,
      -0.04906948655843735,
      0.0028052807319909334,
      -0.014138796366751194,
      0.06466815620660782,
      -0.05422283709049225,
      -0.019271716475486755,
      -0.0040148585103452206,
      -0.016006749123334885,
      0.01781483367085457,
      0.05967055261135101,
      -0.021478936076164246,
      -0.017284730449318886,
      -0.0642537996172905,
      0.007947517558932304,
      -0.030862560495734215,
      0.06200375780463219,
      -0.05089830607175827,
      -0.054729998111724854,
      -0.013646128587424755,
      -0.06806158274412155,
      0.01795649155974388,
      -0.006372571457177401,
      -0.014007814228534698,
      0.01564675383269787,
      -0.006154504604637623,
      0.08253725618124008,
      -0.04194964841008186,
      0.008913850411772728,
      0.07557431608438492,
      -0.018516622483730316,
      -0.03163830563426018,
      -0.036764901131391525,
      0.0580257885158062,
      0.017856527119874954,
      0.07962886989116669,
      0.027267316356301308,
      -0.0056876735761761665,
      -0.05661154165863991,
      -0.030102845281362534,
      0.021074868738651276,
      -0.0006457005511038005,
      0.09482130408287048,
      -0.049051541835069656,
      -0.023066842928528786,
      0.05176149681210518,
      0.07096849381923676,
      0.04678509756922722,
      0.056464772671461105,
      -0.011157244443893433,
      0.0023922245018184185,
      -0.07615519315004349,
      -0.0893184021115303,
      -0.005794131197035313,
      -0.07157240062952042,
      -0.022625921294093132,
      0.019487105309963226,
      -0.026787247508764267,
      0.0703972652554512,
      -0.08282369375228882,
      -0.06702538579702377,
      -0.019282806664705276,
      0.057612113654613495,
      0.04747316241264343,
      0.04467393085360527,
      0.044579632580280304,
      -0.05821334943175316,
      -0.010708731599152088,
      0.020475288853049278,
      0.037509262561798096,
      0.07459071278572083,
      -0.004226106218993664,
      -0.026090921834111214,
      -0.04492669552564621,
      -0.03806368634104729,
      -0.0646255761384964,
      -0.010957779362797737,
      0.015541866421699524,
      0.031052080914378166,
      -0.03466685488820076,
      -0.05502396076917648,
      0.009534369222819805,
      -0.005764018278568983,
      -0.0634765550494194,
      -0.002651942428201437,
      0.04839914292097092,
      -0.055703651160001755,
      -0.06411632895469666,
      0.026643628254532814,
      0.010333016514778137,
      -0.011841441504657269,
      0.013092425651848316,
      0.01993606984615326,
      -0.02803036756813526,
      -0.019397828727960587,
      0.005334610119462013,
      0.03141097351908684,
      0.07521854341030121,
      0.0818447694182396,
      0.007273450493812561,
      -0.029431648552417755,
      0.00899626687169075,
      0.012140089645981789,
      -0.0018051880178973079,
      0.001948208431713283,
      0.03874283283948898,
      0.07918380945920944,
      0.01606295071542263,
      -0.005604142788797617,
      0.005329384934157133,
      -0.003941862843930721,
      0.040628742426633835,
      0.03755103796720505,
      0.041889071464538574,
      0.038274262100458145,
      0.032054364681243896,
      -0.010619111359119415,
      -0.0032690027728676796,
      0.05712231993675232,
      -0.007463427260518074,
      -0.0020331996493041515,
      -0.03751448541879654,
      -0.000774618296418339,
      0.010349639691412449,
      -0.022397860884666443,
      0.018758749589323997,
      -0.01953829638659954,
      0.03199760615825653,
      0.02904609404504299,
      -0.09401611238718033,
      -0.03979627788066864,
      -0.07698580622673035,
      -0.04789711534976959,
      -0.026114271953701973,
      0.03245709091424942,
      0.06417574733495712,
      -0.031332723796367645,
      -0.01670723408460617,
      0.057232555001974106,
      -0.09964302182197571,
      -0.01159688737243414,
      0.07907643914222717,
      -0.07428061217069626,
      0.06547179818153381,
      -0.04945875331759453,
      -0.014400796964764595,
      -0.0520627498626709,
      -0.0246894434094429,
      -0.05423848330974579,
      -0.0711040049791336,
      0.010114329867064953,
      -0.008771639317274094,
      0.007797878701239824,
      0.047558899968862534,
      -0.06915652006864548,
      0.005502740852534771,
      0.03256693109869957,
      0.01564672589302063,
      0.07784930616617203,
      0.03618217259645462,
      -0.010575314983725548,
      -0.005128925666213036,
      0.07486013323068619,
      -0.02558574080467224,
      0.0761740580201149,
      0.004912361036986113,
      0.02367064170539379,
      -0.012262992560863495,
      -0.016002293676137924,
      0.03654133155941963,
      -0.015078493393957615,
      0.02837466076016426,
      -0.018598180264234543,
      -0.016129935160279274,
      -0.11429344117641449,
      -0.03168358653783798,
      -0.02344159409403801,
      0.06507524847984314,
      0.031397975981235504,
      -0.031241493299603462,
      -0.006280351430177689,
      0.006862703710794449,
      -0.007020259276032448,
      0.027025850489735603,
      0.00702289491891861,
      0.004162182100117207,
      0.007730716839432716,
      0.005784958600997925,
      -0.03984897583723068,
      -0.027030490338802338,
      -0.009818339720368385,
      -0.05157371982932091,
      0.008163882419466972,
      -0.0009664208628237247,
      -0.023316366598010063,
      0.0714719146490097,
      -0.015054292976856232,
      -0.008909261785447598,
      -0.08871539682149887,
      -0.015389745123684406,
      0.0317639596760273,
      -0.0008136089309118688,
      0.021349553018808365,
      0.038477782160043716,
      0.043029554188251495,
      0.008519413881003857,
      0.005452056881040335,
      -0.07102286070585251,
      -0.10742121189832687,
      0.02512822300195694,
      0.043727293610572815,
      0.025637414306402206,
      0.018967464566230774,
      0.042961183935403824,
      0.005921161267906427,
      -0.020959479734301567,
      0.05179542303085327,
      0.005529290065169334,
      -0.0010906067909672856,
      0.024836106225848198,
      -0.033115919679403305,
      -0.028530381619930267,
      0.05837153643369675,
      -0.03519430756568909,
      0.0033198592718690634,
      0.07246328890323639,
      -0.025111151859164238,
      0.020856507122516632,
      0.02197147347033024,
      0.09448884427547455,
      -0.033463042229413986,
      -0.10562208294868469,
      -0.0011347201652824879,
      -0.001264176331460476,
      -0.04843948781490326,
      0.07217660546302795,
      -0.010624170303344727,
      0.008016856387257576,
      0.008762815035879612,
      0.003566163592040539,
      -0.01934170350432396,
      -0.029273459687829018,
      0.014459001831710339,
      -0.01356466580182314,
      -0.028523540124297142,
      -0.05023429915308952,
      0.06215804070234299,
      0.04099239408969879,
      0.018923571333289146,
      0.06470681726932526,
      0.02561328560113907,
      -0.011962221935391426,
      0.05337437242269516,
      -0.09657645225524902,
      -0.04383380338549614,
      -0.03775434195995331,
      0.006222149357199669,
      0.012105727568268776,
      -0.06551138311624527,
      -0.006654469761997461,
      -0.049243129789829254,
      -0.048996709287166595,
      0.021654784679412842,
      -0.0033140871673822403,
      -0.03694973513484001,
      -0.034659117460250854,
      -0.02031354233622551,
      -0.05817209184169769,
      -0.010951063595712185,
      -0.06454742699861526,
      0.0161372609436512,
      -0.0615302249789238,
      0.061296794563531876,
      -0.0063723367638885975,
      -0.022159576416015625,
      0.02798827551305294,
      0.05469772592186928,
      0.12149836122989655,
      0.07283203303813934,
      -0.015771253034472466,
      -0.09283404797315598,
      0.054817311465740204,
      -0.05038616433739662,
      -0.017151309177279472,
      -0.07057409733533859,
      -0.07002144306898117,
      0.03466203063726425,
      0.006071641109883785,
      0.0572793148458004,
      0.014545074664056301,
      0.06193083897233009
    ],
    [
      0.006175634916871786,
      -0.045761413872241974,
      -0.03300318121910095,
      -0.032047025859355927,
      0.015972474589943886,
      -0.14262539148330688,
      -0.003993312828242779,
      -0.02143448032438755,
      0.06504836678504944,
      0.0016058939509093761,
      0.05493311583995819,
      0.021524596959352493,
      -0.015106740407645702,
      0.014721726067364216,
      -0.018789000809192657,
      -0.009773824363946915,
      0.009576470591127872,
      0.05594567954540253,
      0.04198188707232475,
      0.050352632999420166,
      0.004993915092200041,
      0.041116055101156235,
      0.02987002581357956,
      0.07906070351600647,
      0.02786261774599552,
      0.030544420704245567,
      -0.015703676268458366,
      -0.01795913279056549,
      0.06690676510334015,
      -0.020135480910539627,
      0.015869848430156708,
      0.008416308090090752,
      0.002276248997077346,
      0.08607187867164612,
      -0.037320736795663834,
      -0.022630415856838226,
      -0.0008300641202367842,
      -0.03450452536344528,
      -0.01079478207975626,
      0.04018508642911911,
      0.02180202677845955,
      0.04302068054676056,
      0.052124928683042526,
      0.09680123627185822,
      -0.0352373942732811,
      -0.09158219397068024,
      0.022850921377539635,
      -0.010198419913649559,
      0.07007773965597153,
      -0.10827035456895828,
      -0.0025773346424102783,
      -0.03616775944828987,
      0.09039156883955002,
      -0.05125630274415016,
      -0.026879655197262764,
      -0.03315305337309837,
      0.10143499821424484,
      -0.040483508259058,
      -0.03600170090794563,
      -0.030723383650183678,
      0.019802434369921684,
      0.015460486523807049,
      -0.005507888738065958,
      -0.03523268178105354,
      -0.04571184515953064,
      -0.03685615211725235,
      -0.013036485761404037,
      0.03358836844563484,
      0.07370830327272415,
      0.003237971104681492,
      0.008606333285570145,
      -0.06610788404941559,
      0.005759568884968758,
      -0.0613526813685894,
      -0.08120040595531464,
      0.008895917795598507,
      -0.03618709743022919,
      0.041858356446027756,
      -0.03246447071433067,
      -0.058016691356897354,
      0.03742346167564392,
      0.06395282596349716,
      -0.017523733898997307,
      0.019661186262965202,
      0.021126598119735718,
      0.0006012860685586929,
      -0.02793784625828266,
      0.050960663706064224,
      0.0921989306807518,
      -0.004837892949581146,
      -0.05703031271696091,
      -0.011050533503293991,
      0.10801538825035095,
      0.020558906719088554,
      -0.013408741913735867,
      0.04968873783946037,
      0.03319363668560982,
      -0.014110052026808262,
      -0.06680382043123245,
      0.027722369879484177,
      0.03670600801706314,
      0.002699629170820117,
      0.04950755462050438,
      0.008431577123701572,
      0.07394830137491226,
      0.07891671359539032,
      -0.019558582454919815,
      -0.09769605100154877,
      -0.02791948802769184,
      0.0756736472249031,
      0.018073152750730515,
      -0.0712033286690712,
      0.04877551645040512,
      0.0396229512989521,
      -0.005387400276958942,
      -0.002211046637967229,
      0.03358502686023712,
      0.001984150381758809,
      0.014391214586794376,
      -0.02748209610581398,
      -0.05523223429918289,
      0.06380879133939743,
      -0.040348976850509644,
      0.01978897675871849,
      0.002419837983325124,
      -0.02853275090456009,
      -0.052915677428245544,
      0.04701310768723488,
      -0.002570659387856722,
      -0.04078870639204979,
      -0.007822191342711449,
      -0.031017521396279335,
      -0.000594854005612433,
      -0.004256464075297117,
      -0.005797544959932566,
      -0.03174116089940071,
      0.017703937366604805,
      -0.019373256713151932,
      -0.044239502400159836,
      0.03876005858182907,
      0.05515678972005844,
      0.0666721984744072,
      0.04182208329439163,
      0.059038810431957245,
      -0.048083286732435226,
      -0.01617387868463993,
      0.01799081824719906,
      0.015376986004412174,
      -0.0398622527718544,
      -0.012850278057157993,
      0.04553806036710739,
      -0.009979065507650375,
      0.005289348307996988,
      0.019726665690541267,
      -0.032240550965070724,
      0.008079574443399906,
      -0.033032678067684174,
      -0.021931665018200874,
      -0.027120638638734818,
      -0.05140858516097069,
      -0.01740812323987484,
      0.03190116584300995,
      0.04883246496319771,
      0.0704665258526802,
      -0.03818575665354729,
      -0.004624905996024609,
      -0.0003931655373889953,
      -0.036388710141181946,
      0.017051799222826958,
      -0.013858115300536156,
      0.04563148319721222,
      -0.05064958333969116,
      -0.016932912170886993,
      0.036468371748924255,
      0.016069989651441574,
      -0.015126505866646767,
      -0.019462207332253456,
      -0.0773736909031868,
      0.054581593722105026,
      -0.020479446277022362,
      -0.0035769331734627485,
      0.08869151026010513,
      0.02188580110669136,
      0.011100612580776215,
      0.016790803521871567,
      0.008161730132997036,
      0.04965568333864212,
      0.0336647555232048,
      0.03666027635335922,
      -0.001453179051168263,
      0.03680897876620293,
      -0.05010499805212021,
      -0.030426397919654846,
      -0.03890480846166611,
      -0.08102378994226456,
      0.01502416841685772,
      -0.06803058087825775,
      0.005044189747422934,
      0.052916377782821655,
      0.032262492924928665,
      -0.009654906578361988,
      0.09071250259876251,
      0.040647733956575394,
      0.035250697284936905,
      -0.021554026752710342,
      -0.058168526738882065,
      0.010782419703900814,
      -0.03988794609904289,
      0.09480462223291397,
      0.027926376089453697,
      0.040643658488988876,
      0.0021831560879945755,
      0.046131864190101624,
      0.040725670754909515,
      -0.01229624729603529,
      0.03223775327205658,
      0.049975745379924774,
      -0.028946571052074432,
      -0.046094413846731186,
      0.10229676216840744,
      -0.049996666610240936,
      0.04480864480137825,
      0.0786890834569931,
      -0.025972146540880203,
      -0.028347665444016457,
      -0.03725379332900047,
      -0.06120062246918678,
      -0.04233851283788681,
      0.006893367972224951,
      0.008769228123128414,
      0.020403902977705002,
      -0.011404010467231274,
      -0.060685623437166214,
      0.03343949839472771,
      -0.07677838951349258,
      -0.0009871997172012925,
      -0.05131136626005173,
      -0.0018115295097231865,
      0.029194267466664314,
      0.03318016976118088,
      0.007067881058901548,
      0.042820800095796585,
      -0.08675679564476013,
      0.011166142299771309,
      -0.019347036257386208,
      -0.06247609853744507,
      -0.014983843080699444,
      0.033954352140426636,
      -0.024378126487135887,
      -0.015804622322320938,
      -0.06150306388735771,
      0.02561243250966072,
      -0.026871023699641228,
      0.037008438259363174,
      0.03520778939127922,
      0.010802023112773895,
      -0.06952543556690216,
      0.03346859663724899,
      0.040134262293577194,
      0.021003978326916695,
      -0.0014027946162968874,
      0.012912098318338394,
      0.013540121726691723,
      0.03999396041035652,
      0.02185741811990738,
      -0.00026724024792201817,
      -0.02542606182396412,
      -0.0025074181612581015,
      -0.02587619237601757,
      -0.036944322288036346,
      0.01691923290491104,
      0.03586147353053093,
      -0.08003051578998566,
      -0.08717729151248932,
      -0.009250245057046413,
      0.05677797645330429,
      0.010274762287735939,
      -0.014278327114880085,
      0.04444120451807976,
      0.024874689057469368,
      0.0007810063543729484,
      -0.02579091675579548,
      -0.03241854906082153,
      0.05199890956282616,
      0.043748676776885986,
      -0.10196686536073685,
      0.0028025677893310785,
      -0.04103013128042221,
      0.018954815343022346,
      -0.05053131654858589,
      0.020439067855477333,
      -0.03636598959565163,
      0.05432300642132759,
      -0.06907671689987183,
      -0.026066796854138374,
      -0.041685231029987335,
      -0.008451995439827442,
      0.06063776835799217,
      0.0744595006108284,
      0.029819471761584282,
      0.01605761982500553,
      -0.04475872218608856,
      -0.03177886828780174,
      0.09355464577674866,
      0.03222553804516792,
      -0.010198712348937988,
      -0.0541902594268322,
      -0.022719012573361397,
      -0.009714645333588123,
      0.05366158112883568,
      0.01045964565128088,
      -0.028279772028326988,
      0.060680896043777466,
      -0.02113286219537258,
      -0.017542503774166107,
      0.014780850149691105,
      -0.037194281816482544,
      0.0025670132599771023,
      0.06661343574523926,
      0.08252078294754028,
      0.05928805470466614,
      -0.041468940675258636,
      0.033696167171001434,
      0.06995892524719238,
      0.03403974696993828,
      0.07796035706996918,
      0.030215952545404434,
      0.011426291428506374,
      0.06391780823469162,
      0.05946271866559982,
      0.07851840555667877,
      -0.0025155339390039444,
      0.017590029165148735,
      -0.08203829079866409,
      -0.051858630031347275,
      0.028572294861078262,
      -0.013273552060127258,
      -0.0019261774141341448,
      0.02952086180448532,
      0.028929736465215683,
      0.054973453283309937,
      -0.037313830107450485,
      -0.04539233446121216,
      -0.06840832531452179,
      -0.048822853714227676,
      -0.009827769361436367,
      0.035390548408031464,
      0.05529048666357994,
      0.036025311797857285,
      0.038172390311956406,
      0.021051038056612015,
      -0.03042437508702278,
      0.010802456177771091,
      0.011966823600232601,
      0.027494998648762703,
      -0.022547315806150436,
      -0.036690112203359604,
      -0.019138172268867493,
      0.006121936719864607,
      -0.019283141940832138,
      -0.14587749540805817,
      -0.05496908724308014,
      0.023112326860427856,
      -0.012811679393053055,
      0.0058496370911598206,
      -0.017003187909722328,
      0.0074017527513206005,
      0.045537665486335754,
      0.05192190036177635,
      -0.026832550764083862,
      -0.05091720446944237,
      0.05203170329332352,
      -0.052971117198467255,
      -0.02246401086449623,
      0.02864758111536503,
      0.0007772556855343282,
      -0.019672762602567673,
      0.016242636367678642,
      0.08036705106496811,
      -0.05263198912143707,
      -0.02819116599857807,
      0.03433622047305107,
      -0.051686715334653854,
      0.022077210247516632,
      -0.06337341666221619,
      -0.08864714205265045,
      0.011845863424241543,
      0.02661825902760029,
      -0.08630797266960144,
      0.00048540550051257014,
      0.05283845216035843,
      0.042430415749549866,
      0.08374354988336563,
      -0.035677507519721985,
      -0.06663071364164352,
      0.014850033447146416,
      0.025137854740023613,
      -0.017360275611281395,
      0.007917716167867184,
      -0.05002017319202423,
      -0.03875359520316124,
      0.047131385654211044,
      -0.01780269853770733,
      0.028658542782068253,
      -0.05863737687468529,
      0.01744372770190239,
      0.03984644263982773,
      0.0390746183693409,
      -0.049712199717760086,
      0.0201950054615736,
      0.008028220385313034,
      -0.0069762663915753365,
      -0.027982482686638832,
      -0.030027706176042557,
      -0.110374815762043,
      -0.015850359573960304,
      -0.00629619974642992,
      0.09449242055416107,
      0.04951760545372963,
      -0.0029410510323941708,
      0.1378067135810852,
      -0.023733526468276978,
      -0.026724375784397125,
      0.023553214967250824,
      0.08741025626659393,
      -0.07691134512424469,
      -0.016451654955744743,
      0.03321148455142975,
      0.0671168863773346,
      0.04252450913190842,
      -0.07453072816133499,
      -0.058265719562768936,
      -0.03497421741485596,
      0.0010395949939265847,
      0.04337146878242493,
      -0.003406683448702097,
      0.03259258717298508,
      0.0502079539000988,
      0.04555370658636093,
      -0.05667181685566902,
      -0.07648123055696487,
      0.1162397637963295,
      -0.04068022593855858,
      -0.03692662715911865,
      0.01162702590227127,
      0.023774826899170876,
      0.007592522073537111,
      0.059996869415044785,
      0.029262704774737358,
      0.046598486602306366,
      -0.019546881318092346,
      0.066925548017025,
      0.10988355427980423,
      0.04802984744310379,
      -0.014249030500650406,
      -0.07433220744132996,
      -0.043181490153074265,
      -0.03578191250562668,
      0.028619496151804924,
      0.01816573180258274,
      0.022572286427021027,
      0.026498733088374138,
      0.045016221702098846,
      -0.02019543945789337,
      0.028298307210206985,
      -0.09856843203306198,
      -0.05079348385334015,
      0.053420670330524445,
      -0.003627162193879485,
      -0.03566594794392586,
      -0.0038508472498506308,
      0.010293954983353615,
      0.047053080052137375,
      -0.07432477176189423,
      -0.002754688961431384,
      0.03601394221186638,
      -0.010616718791425228,
      0.02693263813853264,
      0.02325267158448696,
      0.06571858376264572,
      -0.004763156641274691,
      0.05708034709095955,
      -0.05664146691560745,
      0.012054196558892727,
      -0.039695482701063156,
      0.017921553924679756,
      0.049859195947647095,
      -0.05821767821907997,
      -0.012278100475668907,
      -0.03503790870308876,
      -0.01328599825501442,
      -0.03409668803215027,
      -0.04198378324508667,
      0.05072823539376259,
      -0.0007381816394627094,
      -0.07410778850317001,
      0.05897067114710808,
      -0.1300995647907257,
      -0.06441247463226318,
      0.005016341805458069,
      -0.013752293772995472,
      -0.015126447193324566,
      0.058108989149332047
    ],
    [
      -0.044567953795194626,
      0.07292237132787704,
      0.020991124212741852,
      0.0033957588020712137,
      0.03839922323822975,
      0.02858193963766098,
      -0.028547417372465134,
      -0.014192898757755756,
      -0.06072304770350456,
      0.01836767978966236,
      0.0020339537877589464,
      0.01161127258092165,
      0.009516038931906223,
      0.011605926789343357,
      -0.04649704322218895,
      -0.01980271004140377,
      0.00409655412659049,
      0.048922743648290634,
      0.02901088446378708,
      -0.03928806260228157,
      -9.685794066172093e-05,
      0.05163690820336342,
      0.002648078603670001,
      0.020429344847798347,
      0.02602284587919712,
      0.024502374231815338,
      -0.04102606698870659,
      -0.017932873219251633,
      -0.03056022711098194,
      -0.012735367752611637,
      -0.03930852934718132,
      -0.004967881366610527,
      -0.03869226202368736,
      0.009487420320510864,
      0.008302167989313602,
      0.05518174171447754,
      0.06136253476142883,
      0.01068153977394104,
      0.016224095597863197,
      -0.04217710345983505,
      -0.020905040204524994,
      0.028329962864518166,
      0.04562927037477493,
      -0.0046701133251190186,
      0.006304113660007715,
      0.039863668382167816,
      0.008433004841208458,
      -0.0007224532309919596,
      0.06729015707969666,
      -0.00438599893823266,
      0.05467887222766876,
      0.09029825031757355,
      -0.033543430268764496,
      0.0004708660999312997,
      0.013852597214281559,
      0.007394211366772652,
      0.08378509432077408,
      -0.04175839573144913,
      -0.04589950293302536,
      0.13998395204544067,
      0.03160976618528366,
      -8.665959467180073e-05,
      -0.03261777386069298,
      -0.013163146562874317,
      -0.006498985458165407,
      -0.01625727117061615,
      -0.00048261595657095313,
      0.057357657700777054,
      0.04120825603604317,
      0.031141478568315506,
      0.022977879270911217,
      -0.06126486137509346,
      0.06888747960329056,
      0.020662803202867508,
      0.050008051097393036,
      0.009067242965102196,
      0.007720658089965582,
      0.009582639671862125,
      -0.022698456421494484,
      -0.03166407346725464,
      -0.0034817622508853674,
      0.09223662316799164,
      0.0028685033321380615,
      0.011077172122895718,
      -0.1159546971321106,
      0.05346798524260521,
      -0.08712225407361984,
      0.02753443829715252,
      -0.05791360139846802,
      0.029672086238861084,
      0.002260802546516061,
      -0.02510194480419159,
      -0.00711821997538209,
      -0.019505547359585762,
      -0.001597144640982151,
      -0.06936158239841461,
      0.022320164367556572,
      -0.010374565608799458,
      -0.021515367552638054,
      0.06169140711426735,
      0.05087222158908844,
      -0.02496880106627941,
      0.009704836644232273,
      -0.0783705785870552,
      0.011830035597085953,
      0.02182130701839924,
      0.0269399993121624,
      -0.09478573501110077,
      -0.030614487826824188,
      0.07040054351091385,
      0.010778716765344143,
      -0.07123100757598877,
      -0.018383191898465157,
      -0.03845604881644249,
      -0.04951796680688858,
      -0.06177802383899689,
      -0.03026888519525528,
      -0.07446462661027908,
      0.04498220607638359,
      0.02090616710484028,
      -0.047076042741537094,
      -0.07303550094366074,
      0.006677823606878519,
      -0.04647420346736908,
      -0.049473486840724945,
      -0.001275356742553413,
      -0.05305199697613716,
      -0.01651642844080925,
      0.008218678645789623,
      -0.057223718613386154,
      -0.04657071828842163,
      0.053736716508865356,
      -0.01598544418811798,
      0.015158332884311676,
      -0.09141211956739426,
      0.03017885610461235,
      -0.002933056326583028,
      0.054840609431266785,
      0.009698635898530483,
      -0.029926462098956108,
      0.012823422439396381,
      0.019726578146219254,
      0.04729761555790901,
      0.006968677509576082,
      0.025824494659900665,
      -0.031998831778764725,
      0.014717311598360538,
      0.008092624135315418,
      -0.04081222787499428,
      -0.0012823075521737337,
      -0.019269362092018127,
      -0.06602869182825089,
      0.04870348051190376,
      -0.033667027950286865,
      -0.02938338927924633,
      0.08186586201190948,
      0.01738729327917099,
      0.0023181845899671316,
      0.01922236569225788,
      0.0025069210678339005,
      0.09256020188331604,
      0.03770401328802109,
      0.045043859630823135,
      0.022547917440533638,
      0.0008213425753638148,
      0.08086266368627548,
      -0.008971367962658405,
      -0.057757794857025146,
      0.03987913951277733,
      0.009853987954556942,
      0.0337161123752594,
      0.021914593875408173,
      -0.04284225404262543,
      -0.009678266942501068,
      -0.007222019135951996,
      -0.04251517727971077,
      -0.062341343611478806,
      0.08207043260335922,
      0.0035795343574136496,
      -0.06921660900115967,
      0.013768687844276428,
      -0.010067081078886986,
      -0.06356806308031082,
      -0.0050087240524590015,
      -0.033742040395736694,
      -0.010413234122097492,
      -0.012787479907274246,
      0.000833227124530822,
      -0.0888785645365715,
      0.07570011913776398,
      0.03370416909456253,
      0.044803015887737274,
      0.02280670963227749,
      0.06687647104263306,
      -0.05843720957636833,
      -0.002318047685548663,
      -0.0384787879884243,
      -0.07021542638540268,
      -0.020837003365159035,
      0.010071459226310253,
      -0.06714259088039398,
      -0.025710025802254677,
      -0.04142006114125252,
      -0.07773678749799728,
      0.05111386626958847,
      0.06426170468330383,
      0.022260764613747597,
      -0.03599843755364418,
      0.06345691531896591,
      0.009023100137710571,
      0.01987718604505062,
      -0.050129279494285583,
      -0.00666997442021966,
      -0.012067273259162903,
      -0.08530756831169128,
      0.028111133724451065,
      -0.059437401592731476,
      0.03814065456390381,
      0.04438571259379387,
      0.07628414034843445,
      0.04294774308800697,
      -0.0006009844364598393,
      0.05366557091474533,
      -0.011346489191055298,
      0.0308584775775671,
      -0.004276608116924763,
      -0.0021459911949932575,
      -0.06501822173595428,
      -0.006376931443810463,
      0.032834120094776154,
      0.026258526369929314,
      0.007045842241495848,
      -0.056365251541137695,
      -0.009966322220861912,
      -0.011009867303073406,
      -0.0031544873490929604,
      -0.02088942565023899,
      0.028623782098293304,
      0.014046537689864635,
      -0.025391165167093277,
      -0.020422514528036118,
      0.07608860731124878,
      -0.03993174433708191,
      0.02953886054456234,
      -0.030863093212246895,
      0.0935211107134819,
      -0.04123445972800255,
      0.01029908936470747,
      0.09472054243087769,
      0.014343327842652798,
      -0.00020032498287037015,
      -0.024129804223775864,
      0.05787014961242676,
      0.031046133488416672,
      -0.03253769502043724,
      0.019572056829929352,
      -0.04581037908792496,
      0.007458851672708988,
      -0.020363643765449524,
      0.019558995962142944,
      0.10920882225036621,
      -0.003195355413481593,
      -0.048638325184583664,
      0.12909097969532013,
      -0.02874966897070408,
      -0.040562696754932404,
      0.05073884129524231,
      0.061961349099874496,
      0.04846999794244766,
      0.036555755883455276,
      0.008185860700905323,
      -0.029489383101463318,
      0.00048709099064581096,
      0.02494101971387863,
      0.07215923815965652,
      -0.02321111038327217,
      -0.018255701288580894,
      0.07961513102054596,
      -0.0351305790245533,
      -0.04308491200208664,
      0.006028373725712299,
      0.004905628506094217,
      -0.02402416430413723,
      -0.02216917835175991,
      -0.020972762256860733,
      -0.009936559945344925,
      0.018552131950855255,
      0.029140762984752655,
      0.012722250074148178,
      0.01623913086950779,
      -0.0609155148267746,
      0.017578845843672752,
      0.04548085853457451,
      -0.04499722644686699,
      0.07319001853466034,
      -0.02547050639986992,
      -0.03833151236176491,
      -0.010363612323999405,
      0.014109877869486809,
      -0.03995354473590851,
      0.023013539612293243,
      -0.011429337784647942,
      -0.06030730530619621,
      0.03369098901748657,
      -0.03775078430771828,
      0.018786214292049408,
      -0.05082017928361893,
      0.008567946031689644,
      0.07002854347229004,
      0.015098046511411667,
      -0.06897922605276108,
      0.022694742307066917,
      -0.012036729604005814,
      0.027022548019886017,
      0.09375433623790741,
      -0.03946027159690857,
      0.01226134318858385,
      -0.010374707169830799,
      -0.006779490038752556,
      0.027576202526688576,
      -0.030320821329951286,
      0.0006521859322674572,
      -0.05752256140112877,
      -0.05063492804765701,
      -0.036417555063962936,
      -0.03958022966980934,
      0.007274121977388859,
      0.04794386774301529,
      -0.03998512774705887,
      -0.019976329058408737,
      -0.05693874508142471,
      -0.00500855827704072,
      -0.03902214393019676,
      0.04670383408665657,
      0.00500911008566618,
      0.019366750493645668,
      0.008506223559379578,
      0.08165167272090912,
      0.04951745644211769,
      -0.013095319271087646,
      0.06553662568330765,
      -0.007965542376041412,
      0.13465546071529388,
      -0.00954729039222002,
      -0.04175439104437828,
      0.029735852032899857,
      -0.056969013065099716,
      0.061177708208560944,
      0.03230107203125954,
      0.042026158422231674,
      -0.011207079514861107,
      -0.004990780260413885,
      -0.011570406146347523,
      0.03140590339899063,
      -0.03540492430329323,
      -0.004209054168313742,
      0.022209638729691505,
      -0.00797996949404478,
      -0.10247250646352768,
      -0.006364870350807905,
      -0.07173473387956619,
      0.025038747116923332,
      0.03407159820199013,
      0.04691879823803902,
      0.0015313056064769626,
      -0.0019258378306403756,
      0.03627835959196091,
      0.08084606379270554,
      -0.002438596449792385,
      -0.02608472667634487,
      0.012399602681398392,
      -0.0010457539465278387,
      0.02179219014942646,
      0.012251671403646469,
      0.028215354308485985,
      -0.007664866745471954,
      -0.04506641998887062,
      0.05880890041589737,
      -0.02317631244659424,
      0.033718522638082504,
      -0.01812879927456379,
      0.052547745406627655,
      -0.004922819789499044,
      -0.012217060662806034,
      -0.020634882152080536,
      0.021200822666287422,
      0.0410006120800972,
      0.043556150048971176,
      0.0032574101351201534,
      -0.00956632662564516,
      0.011810209602117538,
      -0.06610028445720673,
      0.02927466109395027,
      -0.030532849952578545,
      -0.009093030355870724,
      -0.024873441085219383,
      0.04127134010195732,
      0.012013189494609833,
      0.01830482669174671,
      0.062235262244939804,
      0.03895184397697449,
      0.05301593244075775,
      -0.017126403748989105,
      -0.038300301879644394,
      0.02159479260444641,
      -0.023394370451569557,
      0.024443460628390312,
      0.027873266488313675,
      -0.00799419917166233,
      0.01690605841577053,
      0.02480226568877697,
      0.04596772417426109,
      -0.01469840481877327,
      0.046970684081315994,
      0.01262774970382452,
      -0.0026454501785337925,
      -0.04715661704540253,
      -0.07889781892299652,
      0.017966384068131447,
      -0.057381708174943924,
      -0.0077941822819411755,
      -0.04154425859451294,
      -0.006271798629313707,
      -0.016773082315921783,
      0.021759727969765663,
      0.06879422068595886,
      0.004977022763341665,
      -0.04130296781659126,
      0.024189667776226997,
      0.017298035323619843,
      -0.04870285838842392,
      -0.015004616230726242,
      -0.03303742781281471,
      0.033682528883218765,
      0.046232398599386215,
      0.006572396960109472,
      0.008412600494921207,
      0.03445978835225105,
      0.02840575948357582,
      -0.09424112737178802,
      -0.03179040551185608,
      0.0881052166223526,
      0.02027391828596592,
      -0.0014066467992961407,
      0.054148755967617035,
      -0.0006909572402946651,
      0.061388421803712845,
      0.007372284308075905,
      -0.05337386578321457,
      -0.05007772147655487,
      -0.025060422718524933,
      0.019252751022577286,
      -0.0019939111080020666,
      -0.03739607706665993,
      0.009883835911750793,
      -0.07559580355882645,
      0.07807889580726624,
      0.011507419869303703,
      0.11430027335882187,
      -0.020177733153104782,
      0.027127590030431747,
      0.05373205617070198,
      -0.020692260935902596,
      -0.04769878089427948,
      -0.06475059688091278,
      -0.07169872522354126,
      0.08058082312345505,
      -0.02726253867149353,
      -0.07412189990282059,
      -0.05404606834053993,
      -0.02908366173505783,
      0.026413820683956146,
      0.03817443922162056,
      0.019890882074832916,
      -0.004565006587654352,
      -0.015186398290097713,
      0.0428517609834671,
      -0.008352376520633698,
      0.026252802461385727,
      0.04274383932352066,
      -0.012143822386860847,
      0.031176229938864708,
      -0.032236430794000626,
      -0.06770898401737213,
      -0.020222602412104607,
      -0.04481662064790726,
      0.028505807742476463,
      0.024437829852104187,
      -0.03662880137562752,
      -0.031198197975754738,
      -0.12380554527044296,
      0.02475692890584469,
      -0.03592820093035698,
      -0.036794669926166534,
      -0.01583508774638176,
      -0.011473321355879307,
      0.022134818136692047,
      -0.01636071875691414,
      0.044618912041187286,
      -0.005756370723247528,
      0.06491115689277649,
      0.034521255642175674,
      0.021031156182289124
    ],
    [
      0.016020584851503372,
      0.022048456594347954,
      -0.03665117919445038,
      -0.06255856156349182,
      0.05433400720357895,
      -0.016415584832429886,
      -0.01775364577770233,
      -0.018362224102020264,
      -0.12413381040096283,
      -0.024166615679860115,
      0.026506705209612846,
      0.026645706966519356,
      -0.007442123256623745,
      -0.0013101284857839346,
      -0.00984260719269514,
      -0.004253542982041836,
      -0.029989728704094887,
      0.020432136952877045,
      0.03589548543095589,
      0.011837250553071499,
      0.029851527884602547,
      0.02669483982026577,
      0.03576380014419556,
      0.06383588910102844,
      -0.0709579661488533,
      -0.024034684523940086,
      -0.02532072179019451,
      -0.07972602546215057,
      0.04883883520960808,
      -0.05816204100847244,
      -0.02658005803823471,
      0.014446995221078396,
      -0.033666204661130905,
      0.0042475247755646706,
      -0.03972110152244568,
      0.001494073774665594,
      -0.02020135149359703,
      0.02719413861632347,
      0.043896522372961044,
      -0.012354707345366478,
      0.0441271997988224,
      -0.05102426931262016,
      0.01024753786623478,
      -0.0035331244580447674,
      -0.027112331241369247,
      0.02694954164326191,
      0.128000408411026,
      -0.04631318151950836,
      0.07279052585363388,
      0.021041184663772583,
      0.023381389677524567,
      0.05279708281159401,
      -0.022949742153286934,
      0.05657582730054855,
      -0.06564948707818985,
      0.0036159104201942682,
      0.0015359163517132401,
      0.031202323734760284,
      0.0005969653138890862,
      -0.0548538863658905,
      -0.0035142104607075453,
      -0.023648452013731003,
      -0.06170021742582321,
      -0.059001702815294266,
      -0.019419966265559196,
      0.01187289971858263,
      -0.02754930965602398,
      0.06145606189966202,
      0.06711799651384354,
      -0.02696208842098713,
      0.07589886337518692,
      0.009150671772658825,
      0.012053720653057098,
      -0.008086583577096462,
      -0.02786640077829361,
      0.012635717168450356,
      0.026715753600001335,
      -0.05010004714131355,
      0.0031653204932808876,
      0.019826162606477737,
      -0.005473072174936533,
      -0.024253319948911667,
      -0.003237472614273429,
      0.033427536487579346,
      0.08312603831291199,
      -0.03220425918698311,
      -0.02635921537876129,
      -0.0449245348572731,
      0.0929875522851944,
      0.01791260950267315,
      0.03981161490082741,
      -0.06663911789655685,
      -0.06226740777492523,
      0.07203339040279388,
      -0.05059851333498955,
      0.03604528680443764,
      0.01903025433421135,
      0.0522783137857914,
      -0.017561106011271477,
      -0.036493536084890366,
      -0.020233716815710068,
      -0.09863200038671494,
      0.04394448176026344,
      -0.03373711183667183,
      -0.0034890787210315466,
      -0.005542072467505932,
      -0.020955251529812813,
      -0.021133549511432648,
      0.019054336473345757,
      0.025323867797851562,
      0.009161664173007011,
      -0.020165275782346725,
      -0.07728353142738342,
      0.030270712450146675,
      -0.062165118753910065,
      0.1052272841334343,
      -0.02403874509036541,
      0.02745186910033226,
      -0.030821237713098526,
      0.03913888707756996,
      -0.05834377929568291,
      0.01116966176778078,
      0.03109532967209816,
      0.012628301046788692,
      -0.003983057104051113,
      -0.004585273563861847,
      0.01994580589234829,
      0.022597994655370712,
      -0.06682555377483368,
      -0.037673838436603546,
      0.03829909861087799,
      0.0009855995886027813,
      0.010683144442737103,
      -0.050355684012174606,
      -0.11247771233320236,
      -0.05591212585568428,
      -0.03715689852833748,
      -0.05514967814087868,
      -0.010446809232234955,
      -0.07205218821763992,
      0.04226050525903702,
      0.004866942763328552,
      0.017118625342845917,
      0.07121840119361877,
      0.06962738931179047,
      -0.07427968084812164,
      0.0343349315226078,
      -0.03805568069219589,
      0.05069355666637421,
      0.06317224353551865,
      0.03260457143187523,
      -0.023253824561834335,
      0.03673168271780014,
      0.043480608612298965,
      0.002535619307309389,
      -0.027623528614640236,
      0.020794041454792023,
      -0.05407899618148804,
      0.002815862651914358,
      0.044216398149728775,
      -0.07150929421186447,
      0.022493693977594376,
      0.026176078245043755,
      0.02735651098191738,
      0.04398582875728607,
      -0.018594443798065186,
      -0.05012305825948715,
      -0.04805758222937584,
      0.023008769378066063,
      0.02744852378964424,
      0.034937694668769836,
      0.017426328733563423,
      0.003452000906690955,
      0.06610126793384552,
      0.010061600245535374,
      0.025007562711834908,
      0.04122377932071686,
      -0.021869992837309837,
      0.03699662163853645,
      -0.036344680935144424,
      0.025051042437553406,
      0.03654231131076813,
      0.008257507346570492,
      0.026715852320194244,
      0.03649299219250679,
      0.03872493654489517,
      0.012054158374667168,
      -0.019584596157073975,
      -0.09020735323429108,
      -0.009548078291118145,
      -0.05422491580247879,
      -0.03219238296151161,
      0.036817606538534164,
      -0.011662871576845646,
      0.04785807803273201,
      0.013717583380639553,
      -0.010590449906885624,
      0.051916830241680145,
      -0.05778718367218971,
      -0.0645357146859169,
      -0.06632578372955322,
      0.07684405893087387,
      -0.04325561970472336,
      0.013813803903758526,
      -0.004506598226726055,
      0.0601663663983345,
      0.013874594122171402,
      -0.026099108159542084,
      0.02664729207754135,
      -0.03429006040096283,
      0.0500095933675766,
      -0.010670129209756851,
      -0.020992504432797432,
      0.01801111549139023,
      -0.0040572639554739,
      0.1132880225777626,
      -0.007539115846157074,
      0.014269672334194183,
      -0.02013637311756611,
      0.011404952965676785,
      -0.019667183980345726,
      0.030787168070673943,
      0.05188330262899399,
      0.04893311858177185,
      -0.043871257454156876,
      0.08922777324914932,
      -0.019616540521383286,
      -0.0439731739461422,
      -0.03375396504998207,
      0.05127336084842682,
      -0.0038223762530833483,
      -0.07800349593162537,
      -0.008688976056873798,
      -0.10388388484716415,
      0.01734120212495327,
      -0.004565344657748938,
      -0.06487856060266495,
      -0.03241916745901108,
      -0.028354894369840622,
      -0.018890738487243652,
      -0.07375761866569519,
      -0.01654350385069847,
      0.027926122769713402,
      0.06358029693365097,
      -0.02753540873527527,
      0.028295760974287987,
      0.03208976238965988,
      -0.011968841776251793,
      0.044859111309051514,
      -0.06355020403862,
      0.02636718563735485,
      -0.027838826179504395,
      0.09017006307840347,
      0.018185526132583618,
      -0.09994184225797653,
      0.004900955595076084,
      -0.0176375824958086,
      0.00033986649941653013,
      0.030492940917611122,
      -0.004435863345861435,
      -0.06445856392383575,
      -0.019593466073274612,
      -0.007132945582270622,
      0.0336226150393486,
      -0.032528433948755264,
      -0.05118750035762787,
      0.015103357844054699,
      0.04690049588680267,
      0.0063684615306556225,
      -0.03239557519555092,
      0.11632813513278961,
      -0.017956005409359932,
      -0.04405935853719711,
      0.01772315986454487,
      0.01895083673298359,
      0.09939251840114594,
      -0.0036492336075752974,
      0.004784556105732918,
      0.0037111875135451555,
      0.044835854321718216,
      -0.08044701814651489,
      0.0770157128572464,
      0.02685512788593769,
      0.06310710310935974,
      0.004293842241168022,
      0.029009176418185234,
      -0.0009589627734385431,
      -0.027338342741131783,
      -0.009515933692455292,
      -0.11436328291893005,
      -0.06770115345716476,
      -0.07731914520263672,
      -0.016928162425756454,
      0.024509020149707794,
      -0.05075211822986603,
      -0.04758259281516075,
      0.033438123762607574,
      0.010809261351823807,
      0.014948352240025997,
      0.022950632497668266,
      0.030512437224388123,
      -0.03546305000782013,
      0.0043105073273181915,
      -0.043836575001478195,
      -0.01521576102823019,
      0.001430872012861073,
      -0.0029264595359563828,
      0.08989813178777695,
      0.0188360083848238,
      -0.053897034376859665,
      0.0045236507430672646,
      -0.04348206892609596,
      0.06791840493679047,
      0.05346177518367767,
      0.02245958149433136,
      -0.03506363928318024,
      0.028102664276957512,
      0.03433997184038162,
      -0.030282950028777122,
      -0.0005525113665498793,
      0.03539933264255524,
      -0.00907642487436533,
      -0.0076119727455079556,
      -0.005087713245302439,
      0.0940459743142128,
      0.007284064311534166,
      -0.0636921375989914,
      0.034569792449474335,
      -0.01659088023006916,
      -0.05077680945396423,
      0.020271729677915573,
      -0.007160102482885122,
      0.046738289296627045,
      0.020655658096075058,
      0.06326073408126831,
      0.01929628662765026,
      -0.017469480633735657,
      -0.046001747250556946,
      0.01843211054801941,
      0.02202477492392063,
      0.028902050107717514,
      -0.03941255062818527,
      0.0021378309465944767,
      -0.051513079553842545,
      0.057172056287527084,
      -0.03257405385375023,
      0.047432299703359604,
      -0.018600884824991226,
      0.00922948308289051,
      -0.05339586362242699,
      0.03410710021853447,
      0.026214729994535446,
      -0.059897907078266144,
      -0.005974115338176489,
      0.04419219493865967,
      -0.09015724807977676,
      0.0605304017663002,
      -0.01574738509953022,
      -0.07018110156059265,
      -0.00860463734716177,
      -0.0700153186917305,
      0.024975834414362907,
      -0.0778665766119957,
      0.039739809930324554,
      0.10029637068510056,
      -0.06887993216514587,
      0.05911468341946602,
      0.041005924344062805,
      0.026394575834274292,
      0.034146443009376526,
      -0.027466746047139168,
      0.008907802402973175,
      0.011219245381653309,
      0.003227716078981757,
      0.001117213279940188,
      0.02055450715124607,
      -0.06656399369239807,
      -0.04559912160038948,
      -0.040275439620018005,
      -0.034932512789964676,
      0.008929884992539883,
      0.08214829862117767,
      -0.018275868147611618,
      -0.017827697098255157,
      0.05544194579124451,
      -0.029944265261292458,
      -0.03836360573768616,
      0.024686288088560104,
      -0.04562745615839958,
      0.05078384280204773,
      -0.0010735916439443827,
      0.06528037041425705,
      -0.015226325951516628,
      0.028068607673048973,
      -0.01772078312933445,
      0.05286039412021637,
      0.02394379861652851,
      -0.009290843270719051,
      -0.0605156235396862,
      -0.023121492937207222,
      0.11832164973020554,
      0.05804567039012909,
      -0.007521411869674921,
      -0.012452051974833012,
      -0.03762780502438545,
      0.0014769966946914792,
      -0.04035058990120888,
      0.0528484545648098,
      -0.043001700192689896,
      -0.03693168982863426,
      -0.0073006534948945045,
      -0.05151825770735741,
      0.030645126476883888,
      0.040758855640888214,
      -0.011402073316276073,
      0.016659662127494812,
      -0.0053456868045032024,
      0.058711469173431396,
      0.0025394381955266,
      0.05445430427789688,
      -0.004483392462134361,
      0.003083376446738839,
      0.013015727512538433,
      -0.05967654660344124,
      -0.010792749002575874,
      0.019367510452866554,
      -0.02364012598991394,
      0.00463448790833354,
      0.0020855090115219355,
      0.03366284444928169,
      -0.010933937504887581,
      0.03888764977455139,
      0.07903881371021271,
      0.011366991326212883,
      -0.02207016572356224,
      -0.08286487311124802,
      -0.05110875889658928,
      0.01310400478541851,
      -0.07556549459695816,
      -0.03494356572628021,
      0.0258463304489851,
      -0.050590548664331436,
      0.009276961907744408,
      0.01462392695248127,
      -0.031165339052677155,
      0.011382056400179863,
      -0.026806006208062172,
      0.016689885407686234,
      -0.033761654049158096,
      0.05194932967424393,
      0.030807318165898323,
      0.01925875060260296,
      0.05775400251150131,
      -0.009092808701097965,
      -0.017960945144295692,
      -0.006194871384650469,
      -0.04744488373398781,
      -0.09026046842336655,
      0.0032387881074100733,
      0.010736403986811638,
      0.0015570585383102298,
      0.05624482035636902,
      -0.037419818341732025,
      -0.0027336892671883106,
      0.07278341054916382,
      -0.010500630363821983,
      -0.005302235949784517,
      0.07599154859781265,
      -0.064165398478508,
      0.01754024066030979,
      0.05526493117213249,
      0.02480693720281124,
      0.030892856419086456,
      0.051352981477975845,
      -0.013747183606028557,
      -0.060412853956222534,
      -0.004680630750954151,
      -0.009515706449747086,
      -0.017868338152766228,
      -0.0204086285084486,
      -0.06067924574017525,
      -0.04831436648964882,
      0.006817066576331854,
      -0.0263309758156538,
      0.026902565732598305,
      -0.04242018237709999,
      0.04035220295190811,
      0.05935292690992355,
      0.0546710342168808,
      0.020040851086378098,
      0.007593382149934769,
      -0.010463177226483822,
      -0.0026385518722236156,
      -0.07299193739891052,
      0.01716701313853264,
      0.010593464598059654,
      -0.04164659604430199,
      -0.024030648171901703,
      -0.07016630470752716,
      0.026946045458316803,
      0.005771411117166281,
      0.04138881340622902,
      -0.01557766180485487
    ],
    [
      0.07182081043720245,
      -0.03300749137997627,
      0.024726174771785736,
      0.024959580972790718,
      -0.01703702099621296,
      -0.013298366218805313,
      -0.06821467727422714,
      0.004358341917395592,
      -0.014981466345489025,
      0.028670789673924446,
      0.06455826759338379,
      -0.01675216481089592,
      -0.029535796493291855,
      -0.010846626944839954,
      -0.04674316570162773,
      0.04299888759851456,
      0.008125657215714455,
      -0.0038878838531672955,
      -0.019990328699350357,
      0.0021573398262262344,
      -0.047659408301115036,
      0.007978158071637154,
      0.011576622724533081,
      -0.026620857417583466,
      0.0326199047267437,
      -0.021732352674007416,
      -0.06541241705417633,
      0.0244807917624712,
      -0.002626430941745639,
      0.029049307107925415,
      0.003196786856278777,
      0.07311506569385529,
      0.10969574749469757,
      0.033993467688560486,
      -0.01962132938206196,
      -0.0020166977774351835,
      -0.028121555224061012,
      -0.00994983222335577,
      0.05687674134969711,
      -0.023844296112656593,
      0.04361540824174881,
      0.050181370228528976,
      0.0023384185042232275,
      -0.025700712576508522,
      -0.0325690433382988,
      0.0428374819457531,
      0.029921257868409157,
      -0.00879740435630083,
      -0.043222084641456604,
      0.048950664699077606,
      -0.02408987656235695,
      0.010140890255570412,
      -0.0014298358000814915,
      -0.03433659300208092,
      -0.00975496880710125,
      -0.07526100426912308,
      0.008007590658962727,
      -0.03967882692813873,
      -0.13096623122692108,
      -0.03363695740699768,
      0.00395596120506525,
      -0.01917118951678276,
      0.040945518761873245,
      -0.02898661606013775,
      0.02839007042348385,
      0.008100926876068115,
      0.03225381299853325,
      -0.04148988425731659,
      -0.0009075082489289343,
      -0.028743410483002663,
      0.0028943903744220734,
      0.016701269894838333,
      0.08338378369808197,
      0.05121663585305214,
      -0.019977299496531487,
      0.05261889100074768,
      0.02894328534603119,
      -0.062428850680589676,
      -0.009675048291683197,
      -0.055167581886053085,
      0.026607288047671318,
      0.07530524581670761,
      -0.052880529314279556,
      0.036652300506830215,
      0.023602189496159554,
      0.008197981864213943,
      0.005560004618018866,
      -0.05029347166419029,
      0.009287316352128983,
      0.027698196470737457,
      0.01080881617963314,
      0.01444433443248272,
      -0.07156882435083389,
      0.03580106422305107,
      -0.12914249300956726,
      0.04291238263249397,
      0.08396727591753006,
      -0.044224344193935394,
      0.03938012942671776,
      -0.060011859983205795,
      0.059671368449926376,
      -0.02748575247824192,
      -0.07458189874887466,
      -0.023972000926733017,
      -0.011357423849403858,
      -0.023129409179091454,
      0.015002358704805374,
      0.029018767178058624,
      -0.045882243663072586,
      0.034060873091220856,
      0.005782850552350283,
      -0.009791113436222076,
      -0.04665092006325722,
      -0.040482133626937866,
      -0.014018304646015167,
      0.021830113604664803,
      0.019537847489118576,
      -0.022318292409181595,
      -0.0378108024597168,
      -0.051198605448007584,
      0.024712691083550453,
      0.011027023196220398,
      0.05428793281316757,
      0.10964886844158173,
      -0.01792564056813717,
      0.014258399605751038,
      0.019283007830381393,
      -0.07745950669050217,
      0.03628766909241676,
      0.04630734398961067,
      -0.04832528531551361,
      0.022093329578638077,
      0.001321934163570404,
      -0.017797455191612244,
      0.010852820239961147,
      -0.07584066689014435,
      -0.0014821580843999982,
      0.03817037492990494,
      0.04466696083545685,
      0.01754833199083805,
      -0.029896778985857964,
      0.033927734941244125,
      -0.10083116590976715,
      0.04374825954437256,
      -0.0472373403608799,
      -0.036486588418483734,
      -0.02630755491554737,
      0.03831782564520836,
      -2.0399582354002632e-05,
      -0.03748925030231476,
      0.0890711322426796,
      0.054270051419734955,
      -0.059558551758527756,
      0.009688020683825016,
      -0.0015549779636785388,
      -0.00126025197096169,
      0.053045667707920074,
      0.016263583675026894,
      0.039345331490039825,
      0.00044663975131697953,
      0.03835182264447212,
      -0.029569795355200768,
      -0.06382309645414352,
      -0.038421306759119034,
      -0.0180391613394022,
      0.0082344776019454,
      0.0040674819611012936,
      0.08349240571260452,
      0.043703511357307434,
      0.04539727792143822,
      0.013565301895141602,
      0.036142196506261826,
      0.04447915777564049,
      0.0831899419426918,
      0.04374711588025093,
      -7.07269791746512e-05,
      0.020256521180272102,
      0.041530974209308624,
      -0.043745480477809906,
      -0.0478239431977272,
      0.07174287736415863,
      -0.048289164900779724,
      0.10450663417577744,
      0.016111863777041435,
      0.03475867584347725,
      -0.029373977333307266,
      0.016568023711442947,
      -0.08845512568950653,
      0.03746877238154411,
      -0.056088339537382126,
      0.0648479089140892,
      0.039652638137340546,
      -0.03839222341775894,
      0.052288979291915894,
      -0.03275066614151001,
      -0.010304046794772148,
      -0.05354977399110794,
      0.018764421343803406,
      -0.033575743436813354,
      -0.05655461922287941,
      -0.0488569401204586,
      -0.008107833564281464,
      0.03990485891699791,
      0.01557956263422966,
      0.01155795343220234,
      0.03507843613624573,
      -0.029465362429618835,
      -0.06752052903175354,
      0.12524369359016418,
      0.0638078898191452,
      0.03661562129855156,
      -0.021709052845835686,
      -0.01979944482445717,
      -0.048359327018260956,
      -0.02822229079902172,
      -0.0564851351082325,
      -0.0602547712624073,
      0.02391134947538376,
      0.05383680388331413,
      0.08909416943788528,
      -0.02332727611064911,
      0.03280561417341232,
      0.09661118686199188,
      5.552919174078852e-05,
      0.008604582399129868,
      -0.073820099234581,
      -0.01396007277071476,
      -0.10872606933116913,
      -0.040196262300014496,
      0.004758233204483986,
      -0.049346763640642166,
      -0.00467269541695714,
      0.058409836143255234,
      -0.0026865466497838497,
      0.03336326405405998,
      -0.04028463736176491,
      -0.002176010049879551,
      0.002765979850664735,
      -0.03344294801354408,
      0.03528402000665665,
      0.018221283331513405,
      0.0402119979262352,
      -0.003718222491443157,
      0.0023646042682230473,
      0.03487103432416916,
      0.04681625962257385,
      -0.04282333701848984,
      -0.08727439492940903,
      0.038964804261922836,
      0.027417834848165512,
      -0.014192858710885048,
      0.03679746016860008,
      0.02382088452577591,
      -0.030950600281357765,
      -0.032483503222465515,
      0.09201838076114655,
      0.025959687307476997,
      0.05108495429158211,
      0.08869917690753937,
      0.0019245253643020988,
      0.018732836470007896,
      0.009134272113442421,
      0.03278615325689316,
      -0.06074301898479462,
      -0.0004538988578133285,
      0.01271134801208973,
      -0.006619900465011597,
      -0.02954000048339367,
      0.023029882460832596,
      0.03090880811214447,
      0.008209055289626122,
      0.02278643101453781,
      -0.014732953161001205,
      -0.05623957887291908,
      -0.07185737788677216,
      -0.023947369307279587,
      0.026783926412463188,
      -0.017451638355851173,
      0.015848860144615173,
      -0.0022812322713434696,
      -0.006188134662806988,
      -0.008328177034854889,
      -0.018253836780786514,
      0.01278006937354803,
      -0.03717716410756111,
      0.02202139049768448,
      -0.047649938613176346,
      -0.07257802039384842,
      0.03951762989163399,
      -0.01597588136792183,
      -0.007155486382544041,
      -0.002419454278424382,
      0.04942009970545769,
      -0.036207132041454315,
      -0.037619564682245255,
      -0.05784955993294716,
      0.0186754260212183,
      0.014937230385839939,
      -0.06340804696083069,
      0.019759949296712875,
      0.07306361943483353,
      0.04950892925262451,
      -0.002417490119114518,
      -0.027767209336161613,
      -0.051656510680913925,
      0.06192107871174812,
      -0.04672782123088837,
      -0.003845509374514222,
      -0.015738556161522865,
      0.010420864447951317,
      0.038654208183288574,
      0.05774121358990669,
      0.009745319373905659,
      0.025443583726882935,
      0.02303617261350155,
      0.035659532994031906,
      -4.726082624983974e-05,
      0.044688764959573746,
      -0.02344469353556633,
      -0.04429630935192108,
      0.06608699262142181,
      0.006483730394393206,
      0.019252603873610497,
      -0.04479363188147545,
      0.04289715737104416,
      0.03474391624331474,
      -0.08881751447916031,
      -0.04659572243690491,
      -0.035989388823509216,
      0.005128602497279644,
      -0.02200905978679657,
      -0.019102558493614197,
      0.012865041382610798,
      -0.05796026438474655,
      0.05981622636318207,
      0.019908547401428223,
      -0.06102323159575462,
      -0.00040039888699539006,
      0.010692869313061237,
      -0.06867628544569016,
      0.039582643657922745,
      0.01910846307873726,
      -0.02482357993721962,
      -0.0147708710283041,
      -0.0728670209646225,
      0.049573276191949844,
      0.023323290050029755,
      -0.051740821450948715,
      0.07479705661535263,
      0.014528072439134121,
      -0.0941760241985321,
      -0.0796949714422226,
      -0.0009951089741662145,
      0.04181613028049469,
      -0.07487528771162033,
      -0.012936423532664776,
      -0.048525236546993256,
      -0.007320718374103308,
      0.01957627385854721,
      -0.017947986721992493,
      -0.02563018538057804,
      -0.09594982862472534,
      0.059610240161418915,
      -0.014514042064547539,
      0.001729590236209333,
      0.010554670356214046,
      0.05117775499820709,
      -0.10737733542919159,
      -0.02475442923605442,
      -0.06439808011054993,
      -0.03210078552365303,
      0.07743982970714569,
      0.010625192895531654,
      0.015390176326036453,
      -0.040520790964365005,
      -0.009537088684737682,
      -0.016647791489958763,
      -0.050208717584609985,
      -0.011776009574532509,
      -0.029510580003261566,
      -0.07383300364017487,
      -0.032377760857343674,
      0.006482918281108141,
      0.0757540613412857,
      -0.07506293058395386,
      -0.028939304873347282,
      -0.02675844542682171,
      -0.04146154597401619,
      -0.03855044022202492,
      0.017919965088367462,
      0.034064821898937225,
      0.011343089863657951,
      0.008685524575412273,
      0.0254466962069273,
      -0.07541145384311676,
      0.07248090952634811,
      0.042189788073301315,
      0.06140163913369179,
      0.030663883313536644,
      -0.034733258187770844,
      0.08112841099500656,
      0.0706707164645195,
      0.0037233252078294754,
      0.02342546544969082,
      0.09015760570764542,
      -0.08647340536117554,
      -0.024023378267884254,
      -0.03633568435907364,
      0.03653670847415924,
      0.015672512352466583,
      -0.011576126329600811,
      -0.05138970911502838,
      -0.011110897175967693,
      -0.07519759237766266,
      0.03115260601043701,
      -0.014251045882701874,
      0.014291481114923954,
      -0.03636034205555916,
      -0.02106400579214096,
      -0.012480948120355606,
      -0.03631052002310753,
      -0.020483970642089844,
      0.07434907555580139,
      0.0269515048712492,
      -0.03530989587306976,
      -0.049526479095220566,
      -0.07293395698070526,
      0.038894377648830414,
      0.02499842643737793,
      0.014874190092086792,
      -0.05127868056297302,
      0.04003434255719185,
      0.013720192015171051,
      -0.024220801889896393,
      -0.017793912440538406,
      -0.05631324648857117,
      0.0064499699510633945,
      -0.050091929733753204,
      -0.021456152200698853,
      -0.06766549497842789,
      0.018859324976801872,
      0.02852179855108261,
      0.10908669233322144,
      -0.044152092188596725,
      0.020685743540525436,
      0.09754893183708191,
      -0.003287687199190259,
      -0.023095183074474335,
      0.05714457109570503,
      -0.06838058680295944,
      -0.008568978868424892,
      0.011518399231135845,
      0.005662210751324892,
      -0.006009866949170828,
      -0.011286413297057152,
      -0.03658401221036911,
      0.012294639833271503,
      -0.0018456411780789495,
      0.01597117818892002,
      -0.012861615978181362,
      -0.005552600137889385,
      -0.04497777298092842,
      0.001305953017435968,
      -0.020725250244140625,
      0.035249948501586914,
      0.01126855332404375,
      -0.015180062502622604,
      0.057237010449171066,
      0.011896240524947643,
      -0.03742384538054466,
      0.05474361777305603,
      -0.05371459573507309,
      0.01849246583878994,
      0.026270367205142975,
      0.025190643966197968,
      -0.041216783225536346,
      0.019765136763453484,
      0.026797879487276077,
      -0.027389151975512505,
      -0.08280549198389053,
      -0.016102384775877,
      -0.0702340230345726,
      -0.08455166220664978,
      0.07574741542339325,
      -0.05103252828121185,
      0.01620279625058174,
      0.013651828281581402,
      -0.10949530452489853,
      -0.10220179706811905,
      -0.015286137349903584,
      0.022075455635786057,
      -0.04185918718576431,
      0.05470215156674385,
      0.06763795018196106,
      -0.04985513910651207,
      -0.0588691271841526,
      0.01786714978516102,
      -0.006778148002922535,
      0.011596090160310268,
      -0.1077265664935112,
      0.015127078630030155,
      0.06738843768835068,
      -0.008225161582231522
    ],
    [
      -0.03444448485970497,
      0.015724726021289825,
      -0.04167990759015083,
      0.05219326540827751,
      -0.03710312396287918,
      -0.053463663905858994,
      0.00999979954212904,
      -0.0711326152086258,
      0.02403838373720646,
      0.03542967140674591,
      0.07274505496025085,
      -0.0007158262887969613,
      -0.0239271130412817,
      0.030250394716858864,
      -0.011111405678093433,
      -0.0047868387773633,
      0.01068507507443428,
      -0.02100752480328083,
      0.025376373901963234,
      -0.04704141989350319,
      0.022322025150060654,
      0.015318740159273148,
      -0.027420850470662117,
      0.10353046655654907,
      0.05404442548751831,
      0.009815908037126064,
      0.05486473813652992,
      -0.03258064389228821,
      -0.10007956624031067,
      0.002241246635094285,
      -0.03254852443933487,
      0.029790721833705902,
      0.03840387612581253,
      -0.02114425227046013,
      -0.01533871702849865,
      -0.11507591605186462,
      -0.003510280279442668,
      0.059226296842098236,
      -0.01864924654364586,
      -0.04505442827939987,
      -0.07446733117103577,
      -0.027132665738463402,
      0.0063568358309566975,
      0.03175681456923485,
      -0.02668173611164093,
      -0.017472337931394577,
      -0.04855898767709732,
      0.0452565997838974,
      -0.02727326937019825,
      0.034456025809049606,
      -0.0033992945682257414,
      0.02157161571085453,
      0.00884940754622221,
      0.02879079058766365,
      -0.021952325478196144,
      0.01935470476746559,
      -0.0045554144307971,
      0.04041058570146561,
      -0.008414995856583118,
      0.061583224684000015,
      0.041057050228118896,
      0.0311612319201231,
      -0.02586381882429123,
      -0.05939234048128128,
      -0.051282718777656555,
      -0.003170329611748457,
      0.02844373881816864,
      0.0549049936234951,
      -0.015096459537744522,
      -0.032137636095285416,
      0.012583943083882332,
      0.028450364246964455,
      -0.022630728781223297,
      -0.0433163195848465,
      -0.028054535388946533,
      0.0076307132840156555,
      -0.04025111347436905,
      0.09406410902738571,
      0.012715182267129421,
      -0.03375771641731262,
      0.0011884422274306417,
      0.030712516978383064,
      -0.04168419912457466,
      -0.0033138617873191833,
      -0.012461994774639606,
      -0.011482742615044117,
      0.04617205634713173,
      -0.01584884710609913,
      -0.00891279149800539,
      0.06640630215406418,
      0.007647189311683178,
      -0.059839047491550446,
      -0.08978540450334549,
      0.05522308498620987,
      -0.06445640325546265,
      -0.007532231044024229,
      -0.0025346081238240004,
      -0.02230135351419449,
      -0.0831586942076683,
      0.029620762914419174,
      -0.0008020233944989741,
      0.03351574018597603,
      0.008790557272732258,
      0.027427934110164642,
      0.031883999705314636,
      0.02010003849864006,
      0.016978904604911804,
      -0.017951834946870804,
      -0.03151647001504898,
      0.00463609816506505,
      0.020031802356243134,
      0.04241526126861572,
      0.015963979065418243,
      -0.007674152962863445,
      0.010471521876752377,
      -0.03713158890604973,
      -0.05689181387424469,
      -0.0349653996527195,
      -0.013220682740211487,
      0.0006187012768350542,
      0.006588546559214592,
      0.0028305049054324627,
      -0.00037442214670591056,
      -0.03253275901079178,
      0.01885080710053444,
      0.05234577879309654,
      0.0582776814699173,
      0.020713025704026222,
      -0.028996137902140617,
      -0.00525232357904315,
      -0.027915455400943756,
      0.011671253480017185,
      -0.0435091033577919,
      -0.04020751640200615,
      0.048347651958465576,
      -0.09307611733675003,
      0.0569218285381794,
      -0.011755779385566711,
      0.013824219815433025,
      -0.018464822322130203,
      0.0548124797642231,
      0.0037575617898255587,
      -0.04819757491350174,
      -0.05101320892572403,
      0.029434282332658768,
      -0.03970641642808914,
      0.02317623607814312,
      -0.016600627452135086,
      0.007341484539210796,
      0.06713193655014038,
      0.05322971194982529,
      0.018315035849809647,
      -0.09200683236122131,
      -0.05853857472538948,
      -0.0753418430685997,
      -0.03216296061873436,
      0.14900125563144684,
      0.029719164595007896,
      -0.0005289044929668307,
      0.04995032772421837,
      -0.07035724073648453,
      -0.021925926208496094,
      -0.03800066187977791,
      -0.051179155707359314,
      -0.025000637397170067,
      0.03845801204442978,
      -0.058807309716939926,
      -0.017361078411340714,
      -0.016911540180444717,
      -0.04545629024505615,
      0.06565523892641068,
      -0.05087710916996002,
      0.029640454798936844,
      0.02282906137406826,
      -0.004858878441154957,
      0.08839084953069687,
      -0.10849250108003616,
      0.015706144273281097,
      -0.14332924783229828,
      0.019619394093751907,
      -0.02666323632001877,
      0.018340954557061195,
      0.06366074830293655,
      -0.08045420795679092,
      0.014870707876980305,
      -0.0373292937874794,
      0.018258580937981606,
      -0.0056319148279726505,
      0.01752769760787487,
      -0.0685017928481102,
      0.03990199789404869,
      0.03532768413424492,
      -0.05764629691839218,
      0.004409095272421837,
      0.04843200370669365,
      -0.002886562142521143,
      -0.03191586956381798,
      -0.012304255738854408,
      0.0015331381000578403,
      0.02081647329032421,
      0.0028427366632968187,
      -0.002313783159479499,
      -0.009932063519954681,
      -0.04183948412537575,
      -0.07400719076395035,
      -0.012522725388407707,
      -0.0017843658570200205,
      -0.013609892688691616,
      0.11456846445798874,
      -0.09691822528839111,
      0.09937336295843124,
      -0.030138995498418808,
      -0.0286596417427063,
      -0.009658564813435078,
      0.06018830090761185,
      0.037888266146183014,
      0.010604657232761383,
      0.01636001467704773,
      0.06993630528450012,
      0.0075505562126636505,
      -0.11135873943567276,
      -0.005673964973539114,
      -0.018791666254401207,
      0.024130094796419144,
      0.004242555238306522,
      0.010497520677745342,
      0.05091410130262375,
      -0.01777644269168377,
      0.04882701858878136,
      0.0022955765016376972,
      0.13531558215618134,
      -0.04365857318043709,
      0.03453604504466057,
      -0.005869314540177584,
      0.016602953895926476,
      -0.08896166831254959,
      0.020026326179504395,
      -0.04529305547475815,
      -0.012885998003184795,
      -0.0035529490560293198,
      0.023928280919790268,
      -0.010742920450866222,
      -0.10189224034547806,
      -0.03198486566543579,
      0.036351364105939865,
      -0.013835440389811993,
      0.0008452798938378692,
      0.00230116443708539,
      -0.07830499112606049,
      -0.10658184438943863,
      -0.033377259969711304,
      0.010599542409181595,
      0.014715216122567654,
      0.05767327919602394,
      0.009457100182771683,
      0.030320238322019577,
      0.08371000736951828,
      -0.019839614629745483,
      -0.029735196381807327,
      -0.052187059074640274,
      -0.033817749470472336,
      -0.07545048743486404,
      -0.0014649218646809459,
      0.0886143147945404,
      -0.010965227149426937,
      -0.01575884409248829,
      -0.0762234628200531,
      0.03859109804034233,
      -0.03686024993658066,
      -0.033452920615673065,
      0.009253423660993576,
      -0.010054518468677998,
      0.029111750423908234,
      -0.011471766047179699,
      -0.004817811306566,
      0.004834117833524942,
      0.03315185382962227,
      0.028145017102360725,
      0.024719880893826485,
      0.016295315697789192,
      -0.027205973863601685,
      -0.016506800428032875,
      0.027362409979104996,
      -0.011025535874068737,
      0.026577822864055634,
      -0.032709795981645584,
      0.01729418709874153,
      -0.061119869351387024,
      0.006410624366253614,
      -0.03359973430633545,
      -0.02611851505935192,
      0.02850729413330555,
      -0.029103834182024002,
      0.008817768655717373,
      0.005381450988352299,
      -0.005064519587904215,
      0.05839191749691963,
      0.10570823401212692,
      -0.05556877329945564,
      -0.013939201831817627,
      0.025455432012677193,
      -0.0013527498813346028,
      0.010906970128417015,
      0.017964323982596397,
      6.553722050739452e-05,
      -0.05698436498641968,
      0.03926554322242737,
      -0.012965722009539604,
      0.05930357798933983,
      0.036065343767404556,
      -0.038272663950920105,
      0.08451291173696518,
      0.028611119836568832,
      -0.08382701128721237,
      -0.005799701903015375,
      0.007107713259756565,
      -0.036545343697071075,
      -0.03356471657752991,
      0.05586123839020729,
      -0.044682860374450684,
      -0.04496680945158005,
      0.021222224459052086,
      -0.025991341099143028,
      -0.03298051282763481,
      0.012327530421316624,
      -0.035494379699230194,
      0.017152972519397736,
      0.009197956882417202,
      -0.04902411252260208,
      -0.020756587386131287,
      -0.046646665781736374,
      -0.01602700538933277,
      0.0010554870823398232,
      0.03410566970705986,
      0.06910084933042526,
      -0.033351749181747437,
      -0.07689815014600754,
      -0.029042573645710945,
      0.0025211761239916086,
      -0.01814689114689827,
      -0.01144731231033802,
      -0.03388432413339615,
      -0.07070431858301163,
      -0.027462270110845566,
      0.033663660287857056,
      0.046470873057842255,
      -0.052018020302057266,
      -0.04948655888438225,
      -0.035037439316511154,
      -0.10527864843606949,
      0.003627388272434473,
      0.006074524484574795,
      0.024362273514270782,
      -0.08476180583238602,
      0.011236774735152721,
      -0.0700216069817543,
      -0.002768130972981453,
      0.08809218555688858,
      0.054528847336769104,
      -0.02272168919444084,
      -0.0338582880795002,
      -0.025611095130443573,
      -0.04171815887093544,
      0.08321736007928848,
      0.0656425952911377,
      0.04228769615292549,
      0.03569275140762329,
      -0.031935032457113266,
      -0.041624560952186584,
      0.029555482789874077,
      -0.0641770139336586,
      0.00208260677754879,
      0.011816052719950676,
      -0.019206667318940163,
      -0.03145114332437515,
      0.032193318009376526,
      -0.05349818244576454,
      -0.0029208045452833176,
      0.05162035673856735,
      -0.005556538235396147,
      0.03107488714158535,
      0.02805280312895775,
      -0.03270130231976509,
      0.04883318394422531,
      0.015982922166585922,
      -0.002953622955828905,
      -0.015184753574430943,
      -0.0201185904443264,
      -0.015711264684796333,
      0.02079368382692337,
      -0.02533552423119545,
      -0.08376144617795944,
      -0.036358412355184555,
      -0.029935268685221672,
      0.010461938567459583,
      -0.0003342304553370923,
      0.018617907539010048,
      0.00832450482994318,
      -0.1272238790988922,
      -0.02484259381890297,
      0.043947599828243256,
      0.04000277817249298,
      0.02061130292713642,
      0.06714894622564316,
      -0.06741537898778915,
      -0.04105813056230545,
      0.062204509973526,
      -0.010216646827757359,
      0.029324084520339966,
      0.005319749936461449,
      0.013475441373884678,
      -0.045826904475688934,
      0.009847252629697323,
      -0.011875481344759464,
      0.01855311542749405,
      -0.0862206220626831,
      0.017514266073703766,
      -0.0607181042432785,
      0.017836008220911026,
      -0.006871507968753576,
      0.04974805563688278,
      -0.04332868382334709,
      0.02134740725159645,
      0.010071036405861378,
      -0.07792097330093384,
      -0.08784569799900055,
      -0.004212970845401287,
      0.011916469782590866,
      0.017912067472934723,
      -0.007893549278378487,
      0.03134560212492943,
      -0.002697315765544772,
      -0.01406126283109188,
      0.08089271932840347,
      0.024071408435702324,
      0.0037247859872877598,
      0.07137008011341095,
      0.03514964506030083,
      0.024242447689175606,
      -0.0340978279709816,
      0.07993479073047638,
      0.04704277217388153,
      -0.04134491831064224,
      0.032014068216085434,
      0.04450230300426483,
      -0.007068679668009281,
      -0.04168561100959778,
      0.04271070286631584,
      -0.007199484389275312,
      0.0017434897599741817,
      0.05073254555463791,
      0.04420725256204605,
      -2.4212024072767235e-05,
      -0.018564486876130104,
      0.045572567731142044,
      -0.07417713850736618,
      -0.029038628563284874,
      0.01613112911581993,
      -0.042668990790843964,
      -0.0017443763790652156,
      0.0491420179605484,
      0.0226849764585495,
      0.03387562930583954,
      0.052882902324199677,
      -0.02346818894147873,
      -0.015018562786281109,
      0.004301509354263544,
      0.016050057485699654,
      -0.03245428949594498,
      -0.10488367080688477,
      0.09118504077196121,
      0.03306201472878456,
      -0.020472805947065353,
      0.0005050041945651174,
      0.00676337955519557,
      -0.08227068930864334,
      -0.007870190776884556,
      0.05585072189569473,
      0.023408198729157448,
      0.018249940127134323,
      0.040001437067985535,
      0.0506916344165802,
      0.019396428018808365,
      -0.039789337664842606,
      0.011575652286410332,
      0.08977515995502472,
      0.04339248314499855,
      0.020439131185412407,
      -0.15203134715557098,
      -0.0032334080897271633,
      0.015288298018276691,
      0.03112267330288887,
      -0.053279075771570206,
      0.02809176780283451,
      -0.027000727131962776,
      0.045277729630470276,
      0.01958834007382393,
      0.09921358525753021,
      -0.027840156108140945,
      0.046646859496831894,
      -0.009180912747979164,
      0.060527388006448746,
      -0.04935887083411217
    ],
    [
      -0.011337002739310265,
      0.034755490720272064,
      0.04326498135924339,
      -0.04248328134417534,
      0.03413567692041397,
      0.01029667817056179,
      -0.0742444321513176,
      -0.05515320599079132,
      0.07251758128404617,
      0.03080745041370392,
      0.029608579352498055,
      0.06345594674348831,
      0.06442127376794815,
      -0.04498741403222084,
      0.01080767810344696,
      0.04048815742135048,
      0.01626727543771267,
      0.043336182832717896,
      0.005796294193714857,
      -0.01093126181513071,
      -0.002442427910864353,
      -0.03850061446428299,
      0.034555546939373016,
      0.0530710443854332,
      0.08877278864383698,
      -0.022602399811148643,
      -0.019559422507882118,
      0.07400514930486679,
      0.0243762768805027,
      0.06100716069340706,
      -0.027757225558161736,
      0.007615681272000074,
      0.03342633694410324,
      0.008834494277834892,
      0.09636537730693817,
      0.0026508811861276627,
      -0.03658019378781319,
      0.1323361098766327,
      0.08222842216491699,
      0.041307877749204636,
      0.03010348416864872,
      0.03273671865463257,
      0.0560033954679966,
      -0.04672728478908539,
      0.027953488752245903,
      0.0051920171827077866,
      0.04716048762202263,
      0.04138419032096863,
      0.02852770686149597,
      0.057769957929849625,
      0.002546036848798394,
      0.036469362676143646,
      -0.0351749025285244,
      -0.030878214165568352,
      -0.04691257327795029,
      0.0053751468658447266,
      0.07398302108049393,
      -0.011219849810004234,
      0.06330228596925735,
      0.018968122079968452,
      -0.02171475626528263,
      0.06845713406801224,
      -0.035260338336229324,
      0.030650395900011063,
      -0.05884687602519989,
      -0.06928779929876328,
      0.03891797363758087,
      -0.02401575818657875,
      0.03385595604777336,
      0.010551459155976772,
      0.0631495788693428,
      0.016173578798770905,
      0.02840803749859333,
      -0.03113785944879055,
      -0.05626950040459633,
      -0.02928500436246395,
      -0.020404135808348656,
      -0.010457315482199192,
      -0.013530947268009186,
      -0.06853476166725159,
      -0.05993969738483429,
      -0.053546611219644547,
      -0.03158411383628845,
      -0.034847650676965714,
      0.028735876083374023,
      -0.06417515128850937,
      0.009228923358023167,
      -0.027766788378357887,
      0.0050384653732180595,
      -0.06533699482679367,
      -0.02486412785947323,
      0.11585419625043869,
      0.04219064861536026,
      -0.03643078729510307,
      0.021300528198480606,
      -0.002169477753341198,
      0.03835799917578697,
      0.03142251819372177,
      -0.06447939574718475,
      -0.026655288413167,
      -0.09318890422582626,
      -0.028147591277956963,
      -0.03668191283941269,
      0.09570330381393433,
      -0.07422538846731186,
      0.04655694589018822,
      -0.01102740503847599,
      -0.07430844753980637,
      -0.04521601274609566,
      0.04581807181239128,
      -0.0013700800482183695,
      0.013982188887894154,
      0.005450256168842316,
      0.09024660289287567,
      -0.00406282301992178,
      0.058740004897117615,
      -0.026513051241636276,
      -0.05926910415291786,
      -0.031215056777000427,
      -0.12935057282447815,
      -0.030401576310396194,
      0.07124156504869461,
      0.10042914748191833,
      0.045813385397195816,
      -0.02074212022125721,
      0.08144105970859528,
      -0.004439748823642731,
      0.00818744394928217,
      0.07564177364110947,
      -0.025623679161071777,
      0.020376570522785187,
      0.040655143558979034,
      -0.08494523912668228,
      0.018090013414621353,
      0.04779865965247154,
      0.012409863993525505,
      0.03188672289252281,
      -0.0713958814740181,
      -0.046568624675273895,
      0.0047723460011184216,
      -0.04800606146454811,
      0.0023142173886299133,
      0.09788375347852707,
      0.060060881078243256,
      0.025411047041416168,
      0.04718295857310295,
      -0.02635771594941616,
      0.018462859094142914,
      0.021179180592298508,
      0.018473055213689804,
      0.07112397998571396,
      0.07464233040809631,
      -0.03789624199271202,
      0.060341838747262955,
      -0.0022170650772750378,
      0.013929738663136959,
      -0.07517732679843903,
      -0.03840801119804382,
      -0.05435241758823395,
      0.0024135794956237078,
      -0.032415423542261124,
      0.025363290682435036,
      -0.02298887073993683,
      0.006717513781040907,
      0.030600493773818016,
      0.028062764555215836,
      0.029347267001867294,
      -0.010949849151074886,
      0.10410364717245102,
      -0.028848491609096527,
      -0.004836805164813995,
      -0.03778481483459473,
      0.027191849425435066,
      0.03306487202644348,
      -0.040079645812511444,
      0.00847354345023632,
      0.013045907951891422,
      -0.003623979166150093,
      -0.023474203422665596,
      -0.04274854063987732,
      -0.03783619776368141,
      -0.0015206721145659685,
      -0.034560676664114,
      0.06207539886236191,
      0.09212791919708252,
      0.029970243573188782,
      -0.029843507334589958,
      0.03170393034815788,
      0.012478172779083252,
      0.016305657103657722,
      0.027926068753004074,
      -0.011718321591615677,
      0.029341910034418106,
      -0.03634902089834213,
      0.006461181212216616,
      -0.035222962498664856,
      0.014721420593559742,
      -0.0017481468385085464,
      -0.04237404093146324,
      -0.012155325151979923,
      -0.045677199959754944,
      -0.003044719574972987,
      0.01986786536872387,
      0.033193960785865784,
      0.013852391391992569,
      -0.01956186257302761,
      0.03414095938205719,
      -0.019810350611805916,
      0.07067903131246567,
      -0.06078571826219559,
      0.09923068434000015,
      -0.0005400058580562472,
      0.07864179462194443,
      -0.02939857728779316,
      0.010084494948387146,
      0.014712703414261341,
      -0.09442238509654999,
      -0.04632803797721863,
      0.04944004490971565,
      0.009604891762137413,
      0.051016539335250854,
      -0.04704280570149422,
      -0.06135145202279091,
      0.0883789211511612,
      -0.03750281035900116,
      -0.01958659663796425,
      -0.024703528732061386,
      -0.03219006210565567,
      0.05935598537325859,
      0.12210491299629211,
      0.044288069009780884,
      0.05258934572339058,
      -0.02353879250586033,
      -0.024091927334666252,
      0.07194635272026062,
      0.004078506026417017,
      0.004214508458971977,
      -0.03680256009101868,
      -0.056661851704120636,
      0.01028274092823267,
      0.00334507436491549,
      -0.030009020119905472,
      -0.08994771540164948,
      0.0036734435707330704,
      0.04889259487390518,
      0.07836409658193588,
      -0.007595555391162634,
      -0.06322207301855087,
      -0.08238035440444946,
      -0.03888048604130745,
      -0.031095780432224274,
      -0.029602855443954468,
      -0.05340540036559105,
      0.0035813511349260807,
      0.010882318951189518,
      -0.007432152517139912,
      -0.019641349092125893,
      0.07068350166082382,
      0.04521283879876137,
      -0.07852999120950699,
      0.018179353326559067,
      -0.008454594761133194,
      0.036211851984262466,
      -0.0582449845969677,
      0.004293421749025583,
      -0.025427913293242455,
      0.06352549046278,
      -0.015795528888702393,
      0.06802704930305481,
      0.027638737112283707,
      0.03220829740166664,
      0.010217655450105667,
      0.047138892114162445,
      0.038788653910160065,
      0.016412092372775078,
      0.0037976873572915792,
      0.08960596472024918,
      -0.04226137697696686,
      -0.0034805391915142536,
      -0.07536774128675461,
      -0.053423091769218445,
      0.05995554104447365,
      -0.01962771639227867,
      0.005751181393861771,
      0.014777129516005516,
      -0.009836596436798573,
      -0.045884594321250916,
      -0.040031976997852325,
      0.09886397421360016,
      -0.012708231806755066,
      0.016489220783114433,
      0.055869389325380325,
      0.060771241784095764,
      0.022084638476371765,
      -0.0821688324213028,
      0.0007881164783611894,
      0.0198077205568552,
      0.05368217080831528,
      0.07614003121852875,
      0.037386953830718994,
      -0.025404151529073715,
      -0.003923384938389063,
      -0.04688090831041336,
      -0.04001610353589058,
      0.0917651355266571,
      -0.08743855357170105,
      -0.026884669438004494,
      0.022707263007760048,
      -0.011249631643295288,
      -0.06212795898318291,
      -0.018562888726592064,
      0.001285863108932972,
      0.007529337890446186,
      0.03155723214149475,
      -0.051876455545425415,
      0.014784645289182663,
      0.04645151272416115,
      0.02974393591284752,
      0.014866265468299389,
      -0.01452721655368805,
      0.06627252697944641,
      -0.0031503422651439905,
      0.005458109080791473,
      0.007164614275097847,
      -0.023976219817996025,
      -0.003913668915629387,
      -0.03738006204366684,
      -0.06476706266403198,
      0.04244592413306236,
      0.060837216675281525,
      0.01091354712843895,
      0.008944705128669739,
      -0.011996988207101822,
      -0.01082916371524334,
      0.029975516721606255,
      -0.029307186603546143,
      0.00947560928761959,
      0.047312937676906586,
      0.004797924775630236,
      0.03287995234131813,
      0.05313754454255104,
      -0.026805851608514786,
      0.0005849836743436754,
      -0.06139285862445831,
      -0.021263331174850464,
      0.034895963966846466,
      -0.013918744400143623,
      0.008703887462615967,
      -0.01869956962764263,
      -0.02035020850598812,
      -0.031013911589980125,
      -0.014952306635677814,
      0.0013331591617316008,
      -0.05967189371585846,
      -0.04405982047319412,
      -0.026062382385134697,
      -0.07274410128593445,
      0.02587040327489376,
      0.08719927072525024,
      0.021562961861491203,
      -0.013320174999535084,
      -0.025108439847826958,
      0.008210069499909878,
      0.007935580797493458,
      0.09887180477380753,
      0.00882711447775364,
      0.04599764198064804,
      0.008197087794542313,
      -0.019901886582374573,
      0.04842224717140198,
      -0.06904319673776627,
      -0.02420217916369438,
      -0.07621006667613983,
      -0.028910920023918152,
      0.07408181577920914,
      -0.020654799416661263,
      -0.030609868466854095,
      0.030001025646924973,
      -0.020080722868442535,
      -0.039184343069791794,
      -0.020585190504789352,
      0.005885440856218338,
      0.05566639080643654,
      -0.03870852291584015,
      0.03226715698838234,
      0.07720788568258286,
      0.0887046828866005,
      0.017229292541742325,
      -0.043393317610025406,
      0.020837493240833282,
      -0.06328161060810089,
      -0.03888612985610962,
      0.0338304303586483,
      0.041338980197906494,
      -0.02748134545981884,
      0.042059481143951416,
      0.008316176943480968,
      0.00021672178991138935,
      -0.0037211361341178417,
      0.022312916815280914,
      0.04419831559062004,
      0.007081314921379089,
      0.030408812686800957,
      -0.03159947320818901,
      -0.00471323961392045,
      -0.06337571144104004,
      -0.047693803906440735,
      -0.029285255819559097,
      -0.05377594754099846,
      0.009466624818742275,
      -0.05246422812342644,
      -0.04191625118255615,
      0.02918216586112976,
      -0.026494354009628296,
      0.017809197306632996,
      0.0004171065811533481,
      0.007376484572887421,
      -0.06062496453523636,
      0.028832044452428818,
      -0.07647737860679626,
      0.03181811049580574,
      0.04250338673591614,
      -0.07717888802289963,
      0.04654117673635483,
      0.08015947788953781,
      0.009316032752394676,
      -0.03573001176118851,
      -0.018987910822033882,
      0.0018784806597977877,
      0.04833579808473587,
      -0.03124072030186653,
      -0.001452581724151969,
      -0.009472211822867393,
      -0.030087485909461975,
      -0.027795270085334778,
      0.016679873690009117,
      -0.03042476996779442,
      -0.019311372190713882,
      -0.051764875650405884,
      0.06379919499158859,
      0.04482724890112877,
      -0.06396380811929703,
      0.014644584618508816,
      0.00934679713100195,
      0.06290818005800247,
      0.026255814358592033,
      0.03325696662068367,
      -0.016870569437742233,
      0.00556147238239646,
      -0.016237134113907814,
      0.03238089382648468,
      0.04282752051949501,
      -0.03882560133934021,
      0.0503220297396183,
      0.044686365872621536,
      -0.04450404644012451,
      0.036421116441488266,
      -0.042027547955513,
      -0.06614062190055847,
      0.0355244018137455,
      -0.044444914907217026,
      0.05832609906792641,
      -0.03717596456408501,
      0.08558117598295212,
      -0.03677813336253166,
      0.013751218095421791,
      0.050840940326452255,
      -0.010039650835096836,
      0.07577907294034958,
      0.019662780687212944,
      -0.01899762637913227,
      -0.03593672066926956,
      0.007904520258307457,
      -0.05705185979604721,
      0.03069772943854332,
      -0.026538489386439323,
      0.04833415523171425,
      -0.02069716900587082,
      0.07206180691719055,
      -0.006822995841503143,
      -0.01600177213549614,
      -0.022286847233772278,
      -0.04393111914396286,
      -0.09416072070598602,
      -0.03462022915482521,
      0.025131013244390488,
      -0.017554035410284996,
      -0.07755179703235626,
      0.0029559354297816753,
      0.015781747177243233,
      0.028639160096645355,
      -0.05376691743731499,
      -0.0728430300951004,
      0.040383074432611465,
      0.06530861556529999,
      -0.012448763474822044,
      0.011618359014391899,
      -0.06308451294898987,
      -0.04812122881412506,
      0.03290330246090889,
      -0.01697956770658493,
      -0.03970501199364662,
      -0.07286868989467621
    ],
    [
      0.05118342116475105,
      0.053637079894542694,
      0.006983395200222731,
      -0.04610275477170944,
      -0.12831421196460724,
      0.04888881370425224,
      0.026401693001389503,
      -0.0784083679318428,
      -0.021124567836523056,
      0.007514006923884153,
      -0.10148343443870544,
      -0.01211470179259777,
      0.02892150729894638,
      0.004658532794564962,
      -0.06588045507669449,
      0.027919694781303406,
      -0.035259172320365906,
      0.023462198674678802,
      0.06149677559733391,
      -0.026495574042201042,
      -0.05570181459188461,
      0.05262211710214615,
      -0.09869669377803802,
      0.004533567000180483,
      0.03346123546361923,
      -0.07724564522504807,
      -0.020176999270915985,
      -0.11918144673109055,
      -0.03627272695302963,
      -0.01888229325413704,
      0.033123359084129333,
      0.10897016525268555,
      -0.007275683805346489,
      0.03811291977763176,
      -0.06918350607156754,
      -0.019036689773201942,
      0.03213173896074295,
      -0.01676015555858612,
      0.01043563149869442,
      0.048495031893253326,
      -0.034486763179302216,
      -0.015020178630948067,
      0.13931025564670563,
      0.010901852510869503,
      -0.001454214216209948,
      -0.04733859375119209,
      -0.05750461667776108,
      0.027594631537795067,
      0.024582872167229652,
      0.006422887090593576,
      -0.04548834636807442,
      -0.02221127785742283,
      -0.020417286083102226,
      0.04643244296312332,
      0.10271394997835159,
      -0.08247389644384384,
      0.03102634847164154,
      -0.05372125282883644,
      0.0377562940120697,
      0.01439633034169674,
      0.06391984969377518,
      -0.0393998809158802,
      -0.05823497474193573,
      -0.024653324857354164,
      -0.016951793804764748,
      0.11101964116096497,
      0.01218026876449585,
      -0.0400393046438694,
      -0.0646396279335022,
      0.00980925653129816,
      -0.0118858078494668,
      0.11774327605962753,
      -0.012118091806769371,
      -0.03278186917304993,
      -0.031102634966373444,
      -0.06272146850824356,
      -0.058375000953674316,
      -0.01779610477387905,
      0.04434529319405556,
      -0.01847505196928978,
      0.03393138572573662,
      0.06087368354201317,
      0.022733965888619423,
      0.005551151931285858,
      0.04562956467270851,
      -0.12080387771129608,
      0.035362232476472855,
      0.02183562144637108,
      -0.036154527217149734,
      -0.04190149903297424,
      0.05151782184839249,
      0.11497943848371506,
      0.015767376869916916,
      0.08900594711303711,
      -0.005636588204652071,
      0.07328864932060242,
      0.09603609144687653,
      -0.02135397307574749,
      0.008752018213272095,
      -0.05616047978401184,
      0.03268284723162651,
      -0.02125738561153412,
      0.003087272634729743,
      -0.0016767659690231085,
      0.0375824011862278,
      0.045794807374477386,
      0.060244470834732056,
      -0.0580085813999176,
      -0.04638960212469101,
      -0.028485087677836418,
      -0.007265063002705574,
      0.03300304710865021,
      -0.008003920316696167,
      -0.021558910608291626,
      0.05006683990359306,
      -0.015613694675266743,
      -0.035668808966875076,
      -0.04820765182375908,
      -0.0362294502556324,
      -0.038003064692020416,
      -0.046457890421152115,
      0.03558427095413208,
      -0.03432166948914528,
      0.008091509342193604,
      0.05160956829786301,
      0.009235274977982044,
      -0.0033931981306523085,
      -0.008766813203692436,
      0.008434128016233444,
      0.02133980579674244,
      -0.0684390515089035,
      0.03142717480659485,
      -0.01569722220301628,
      -0.05164162069559097,
      -0.008557246997952461,
      -0.0401262603700161,
      0.03839918598532677,
      0.018412424251437187,
      -0.0011949705658480525,
      -0.06604120880365372,
      -0.03777780756354332,
      0.04023415222764015,
      -0.001696671242825687,
      -0.10578903555870056,
      0.04461701586842537,
      0.048046551644802094,
      0.01177628431469202,
      0.004885819740593433,
      0.0037434683181345463,
      -0.002495695138350129,
      0.024441447108983994,
      0.0020312510896474123,
      0.08817464858293533,
      -0.0072302985936403275,
      0.04967184364795685,
      0.03532795235514641,
      -0.003667596960440278,
      -0.053196243941783905,
      -0.011757399886846542,
      -0.059718724340200424,
      0.00830813031643629,
      0.006317044608294964,
      0.01561064925044775,
      -0.08771729469299316,
      0.011464377865195274,
      -0.050462111830711365,
      0.1225772276520729,
      -0.008625943213701248,
      0.004650141578167677,
      -0.013827488757669926,
      0.008379151113331318,
      0.03995112329721451,
      0.0646611750125885,
      0.03477121517062187,
      0.06992387771606445,
      0.0024857884272933006,
      0.034266434609889984,
      -0.03756033256649971,
      -0.020416075363755226,
      -0.0448654480278492,
      -0.04070596396923065,
      0.01829206384718418,
      0.023207738995552063,
      0.007303882390260696,
      0.07298960536718369,
      -0.00052207539556548,
      0.09220856428146362,
      0.06667939573526382,
      0.04058794677257538,
      0.04988417774438858,
      0.03752191364765167,
      -0.03934472054243088,
      -0.006924820132553577,
      0.00140314269810915,
      -0.0975160002708435,
      0.021506886929273605,
      0.0016162937972694635,
      0.021466681733727455,
      -0.04977141693234444,
      -0.03652507811784744,
      -0.03587676212191582,
      -0.004713316913694143,
      -0.00863625854253769,
      0.007535350508987904,
      -0.02553214691579342,
      -0.045336488634347916,
      -0.017033014446496964,
      0.010391549207270145,
      0.0031548677943646908,
      0.01871645823121071,
      0.0032921298407018185,
      0.006715103983879089,
      0.04942015931010246,
      0.02599773183465004,
      0.009452157653868198,
      0.03987160697579384,
      -0.020362375304102898,
      0.05519209802150726,
      0.017436793074011803,
      -0.03813272714614868,
      -0.047201428562402725,
      0.0394156351685524,
      -0.022309644147753716,
      -0.019859544932842255,
      -0.09767542034387589,
      -0.03879449516534805,
      -0.017678314819931984,
      -0.03881814330816269,
      0.011504833586513996,
      -0.038917023688554764,
      0.07874321937561035,
      -0.05767420679330826,
      0.05796898156404495,
      -0.04542280733585358,
      0.09166035801172256,
      -0.06177045777440071,
      0.10559569299221039,
      0.05430994927883148,
      0.04081226512789726,
      0.02187570556998253,
      -0.009485749527812004,
      -0.0698043629527092,
      0.02948603965342045,
      -0.0307760052382946,
      -0.1012401282787323,
      0.003800999838858843,
      0.07671134173870087,
      0.0008313709986396134,
      0.0075929779559373856,
      -0.026719609275460243,
      0.036292821168899536,
      0.017643751576542854,
      -0.03624643012881279,
      -0.04640687629580498,
      -0.004247407894581556,
      0.04979262128472328,
      -0.031183531507849693,
      -0.039534877985715866,
      0.0783674344420433,
      -0.01442794967442751,
      0.001803772640414536,
      0.07080662995576859,
      0.04731867462396622,
      0.004572763107717037,
      0.06568670272827148,
      0.003630958264693618,
      -0.04221334308385849,
      -0.047949474304914474,
      0.007997863925993443,
      -0.09874064475297928,
      0.04324469342827797,
      0.00873887911438942,
      -0.03203705698251724,
      -0.053098320960998535,
      -0.014647236093878746,
      -0.011094805784523487,
      -0.09584970027208328,
      0.00875048153102398,
      0.054918088018894196,
      -0.07640638947486877,
      0.03074832260608673,
      -0.0903211385011673,
      -0.02688515931367874,
      0.08571408689022064,
      -0.020277785137295723,
      -0.02945745177567005,
      0.011945337057113647,
      -0.01211091410368681,
      -0.10575961321592331,
      0.0011706361547112465,
      -0.015065007843077183,
      -0.007473025470972061,
      0.026529423892498016,
      -0.008496185764670372,
      -0.023327970877289772,
      -0.005068789701908827,
      0.028647784143686295,
      -0.02217252366244793,
      0.0035159143153578043,
      0.009513233788311481,
      0.032195597887039185,
      0.018323063850402832,
      -0.10468516498804092,
      0.03288597613573074,
      -0.028395090252161026,
      -0.013755706138908863,
      -0.020536936819553375,
      0.014080130495131016,
      0.03436680883169174,
      0.11714527010917664,
      -0.07324301451444626,
      -0.009640459902584553,
      0.02676566131412983,
      0.046199310570955276,
      -0.044954050332307816,
      0.027939707040786743,
      -0.027834240347146988,
      0.08051279187202454,
      0.02863210253417492,
      -0.024975523352622986,
      0.020807193592190742,
      -0.057332541793584824,
      0.052169784903526306,
      -0.04418238252401352,
      -0.04101201146841049,
      -0.0010394507553428411,
      0.0164317823946476,
      -0.032102327793836594,
      -0.026681959629058838,
      0.046151451766490936,
      0.02218198962509632,
      0.03636697307229042,
      0.03963163122534752,
      0.02775103971362114,
      0.043523624539375305,
      -0.011540796607732773,
      -0.012239153496921062,
      0.08003242313861847,
      0.027209332212805748,
      -0.05868488550186157,
      0.09399260580539703,
      0.03301128000020981,
      -0.024338562041521072,
      -0.006954214535653591,
      -0.004620175343006849,
      -0.035176265984773636,
      -0.09448545426130295,
      -0.016311103478074074,
      0.012478095479309559,
      0.003274957649409771,
      -0.03335360437631607,
      0.0416606143116951,
      -0.03616127371788025,
      -0.04213528707623482,
      -0.026022247970104218,
      0.029411599040031433,
      -0.01913425512611866,
      0.03156743198633194,
      0.11635642498731613,
      -0.009005013853311539,
      0.024945339187979698,
      -0.030593452975153923,
      -0.05457821860909462,
      0.006647602654993534,
      0.03696334734559059,
      -0.0398433580994606,
      0.010485698468983173,
      -0.026541756466031075,
      0.017317410558462143,
      0.027343343943357468,
      -0.016949301585555077,
      0.018604837357997894,
      0.0008295661536976695,
      -0.013273974880576134,
      -0.006293852347880602,
      0.007637867704033852,
      -0.01200056541711092,
      0.07079673558473587,
      -0.019269095733761787,
      0.012009893544018269,
      0.04342120885848999,
      0.009912989102303982,
      -0.04292847961187363,
      -0.011667500250041485,
      0.06789489835500717,
      0.008049125783145428,
      -0.009928923100233078,
      -0.05069046467542648,
      0.017227835953235626,
      -0.02043048106133938,
      -0.015575675293803215,
      0.0558740608394146,
      0.04226982593536377,
      0.08138498663902283,
      -0.013559024780988693,
      0.02577298879623413,
      -0.01166356261819601,
      -0.02030159905552864,
      -0.07664505392313004,
      0.021772073581814766,
      0.07274407148361206,
      0.08385328948497772,
      0.00992114469408989,
      0.019231459125876427,
      0.028086582198739052,
      -0.016143446788191795,
      0.07675160467624664,
      0.023098977282643318,
      0.04129420220851898,
      -0.017041243612766266,
      0.01973014324903488,
      -0.03166106343269348,
      -0.028016092255711555,
      -0.09843385964632034,
      0.0020604743622243404,
      0.024398496374487877,
      0.041109804064035416,
      -0.01983383484184742,
      0.014233081601560116,
      0.05105534940958023,
      0.043018609285354614,
      0.014783780090510845,
      -0.059852685779333115,
      -0.0026339509058743715,
      -0.04438339173793793,
      0.004442673642188311,
      0.0382239930331707,
      0.037907637655735016,
      -0.03917950019240379,
      0.006241094321012497,
      0.0006258301436901093,
      -0.017914501950144768,
      0.012669092044234276,
      0.009854743257164955,
      -0.005724277347326279,
      -0.022364478558301926,
      0.005597698502242565,
      -0.041760142892599106,
      -0.00421042600646615,
      -0.05917082726955414,
      0.00677475007250905,
      0.04615610092878342,
      -0.04150664433836937,
      0.004764927085489035,
      0.007630767300724983,
      0.002216336550191045,
      -0.009566164575517178,
      -0.0026284861378371716,
      0.011666099540889263,
      -0.06717110425233841,
      0.05762860178947449,
      -0.003146539442241192,
      -0.011785806156694889,
      0.050263501703739166,
      0.021318912506103516,
      0.037197552621364594,
      0.010824684053659439,
      -0.03371535986661911,
      0.04475637525320053,
      0.025442656129598618,
      -0.02796962670981884,
      -0.016093658283352852,
      0.032882168889045715,
      0.009065801277756691,
      -0.10289479047060013,
      0.005300239194184542,
      -0.019406408071517944,
      0.001686834846623242,
      0.07838264852762222,
      -0.016412926837801933,
      0.05070453882217407,
      0.03152058273553848,
      -0.016411270946264267,
      -0.029700446873903275,
      -0.012185906060039997,
      -0.028333690017461777,
      -0.0002299182233400643,
      0.03571843355894089,
      -0.06591161340475082,
      0.09162766486406326,
      0.05254266783595085,
      -0.016309941187500954,
      0.053875602781772614,
      0.07666733860969543,
      -0.016129909083247185,
      -0.014095988124608994,
      0.030232850462198257,
      -0.061890341341495514,
      -0.034347645938396454,
      0.009774177335202694,
      -0.050358518958091736,
      -0.03254956379532814,
      -0.005485884845256805,
      -0.07625026255846024,
      0.03940723463892937,
      0.005141344852745533,
      -0.0795036181807518,
      0.007723464164882898,
      -0.05224321037530899,
      0.027750888839364052,
      0.09559977054595947,
      0.012816797941923141,
      -0.034264110028743744
    ],
    [
      0.05335211381316185,
      0.03385685011744499,
      -0.028391258791089058,
      0.019182724878191948,
      0.03978406637907028,
      -0.023494254797697067,
      -0.0028279477264732122,
      -0.021612318232655525,
      -0.05313877761363983,
      0.0015771028120070696,
      -0.01049676164984703,
      0.04996733367443085,
      -0.05510837957262993,
      0.07544074952602386,
      0.03113001398742199,
      -0.008643589913845062,
      0.04399920627474785,
      0.07983392477035522,
      -0.003971462603658438,
      -0.008096671663224697,
      0.010404565371572971,
      -0.01457522064447403,
      -0.033895205706357956,
      0.006159096956253052,
      0.03239904344081879,
      0.035031553357839584,
      -0.02949272282421589,
      -0.08004815131425858,
      -0.02237982302904129,
      0.0175592303276062,
      -0.002696377458050847,
      -0.02732664905488491,
      -0.06779520213603973,
      -0.044664107263088226,
      -0.03650815784931183,
      -0.03194005787372589,
      -0.07499081641435623,
      0.008923221379518509,
      0.0030660168267786503,
      -0.06378593295812607,
      -0.03735378012061119,
      0.013204745016992092,
      0.009864309802651405,
      -0.006497014779597521,
      -0.08254456520080566,
      0.0035659156274050474,
      -0.06056736409664154,
      -0.08851911127567291,
      -0.006172592751681805,
      0.028113463893532753,
      -0.011652486398816109,
      0.07871083915233612,
      0.03127501532435417,
      -0.020988216623663902,
      0.02588609606027603,
      0.06488537043333054,
      0.019244058057665825,
      -0.03386412560939789,
      0.021418685093522072,
      0.05585896223783493,
      0.042923420667648315,
      0.025540316477417946,
      -0.02674420364201069,
      0.02718425542116165,
      0.01028792280703783,
      0.0353378988802433,
      -0.00413170363754034,
      0.061018336564302444,
      0.021603280678391457,
      0.009411401115357876,
      0.04221171513199806,
      -0.002767125377431512,
      0.07083183526992798,
      -0.01484578289091587,
      -0.05485565587878227,
      -0.06074080616235733,
      -0.010812305845320225,
      -0.02638707496225834,
      0.037674449384212494,
      0.027375873178243637,
      -0.023406585678458214,
      -0.032269638031721115,
      0.011153323575854301,
      -0.050635162740945816,
      0.07958078384399414,
      0.03291081264615059,
      -0.014165335334837437,
      -0.06074407324194908,
      -0.09223061054944992,
      -0.05712088569998741,
      -0.035485439002513885,
      -0.05216298997402191,
      -0.033682312816381454,
      -0.001393393031321466,
      -0.06705792993307114,
      -0.06104287505149841,
      0.026102816686034203,
      0.0282795038074255,
      0.015378568321466446,
      -0.017772328108549118,
      0.02393011562526226,
      0.027834642678499222,
      -0.031421538442373276,
      -0.02718649059534073,
      0.0034220621455460787,
      -0.06380193680524826,
      -0.004701089579612017,
      -0.07284311950206757,
      -0.029144467785954475,
      -0.07218112051486969,
      0.03487849980592728,
      0.007947087287902832,
      -0.010862305760383606,
      0.03148579224944115,
      -0.0494171679019928,
      -0.011152517981827259,
      -0.04024375602602959,
      -0.003036677837371826,
      0.027545055374503136,
      -0.020505767315626144,
      -0.010133241303265095,
      -0.049071360379457474,
      0.04638299718499184,
      0.0571802482008934,
      0.11618601530790329,
      0.00396013306453824,
      -0.04469621181488037,
      0.010716352611780167,
      -0.004579619038850069,
      0.07716473191976547,
      0.022465692833065987,
      -0.016133014112710953,
      -0.06226300820708275,
      0.0042562526650726795,
      0.06668701022863388,
      -0.026135539636015892,
      0.009030962362885475,
      0.024755170568823814,
      -0.07186738401651382,
      0.02218921296298504,
      -0.017220135778188705,
      0.05977386608719826,
      0.055967625230550766,
      -0.06988053023815155,
      -0.0013363159960135818,
      0.008997605182230473,
      0.0573868528008461,
      0.030052438378334045,
      0.028987500816583633,
      -0.0025985068641602993,
      0.00968677457422018,
      -0.04112666845321655,
      -0.0332522839307785,
      0.02310529723763466,
      0.03132827579975128,
      0.03018619678914547,
      0.015034817159175873,
      0.023909177631139755,
      0.040409769862890244,
      -0.046951644122600555,
      0.023569827899336815,
      -0.07017897814512253,
      -0.11589135974645615,
      0.036148641258478165,
      0.005481970030814409,
      -0.019862519577145576,
      -0.04968886449933052,
      0.0008672955445945263,
      -0.0024266340769827366,
      0.02366163395345211,
      -0.015313996002078056,
      0.012673200108110905,
      0.08483501523733139,
      0.010646332055330276,
      0.03450241684913635,
      0.012848135083913803,
      -0.0036497078835964203,
      0.05380283668637276,
      0.027605906128883362,
      -0.07351583242416382,
      -0.014658438973128796,
      0.15207505226135254,
      -0.008106056600809097,
      0.006270414683967829,
      -0.05951038375496864,
      0.044577449560165405,
      -0.00175943982321769,
      0.010776021517813206,
      0.09777330607175827,
      0.00021921019651927054,
      -0.07859805971384048,
      -0.021263638511300087,
      -0.036676399409770966,
      0.016229156404733658,
      0.04208473488688469,
      -0.02493496611714363,
      -0.03788384795188904,
      0.04972968250513077,
      -0.011680640280246735,
      0.029831165447831154,
      0.05620499327778816,
      -0.023385223001241684,
      0.030565649271011353,
      0.025390805676579475,
      -0.04952175170183182,
      -0.0252262894064188,
      -0.02116450108587742,
      0.017872197553515434,
      0.041317712515592575,
      0.036979254335165024,
      -0.025642840191721916,
      -0.016533035784959793,
      0.06274724006652832,
      0.009939038194715977,
      -0.009914809837937355,
      -0.09302178025245667,
      -0.07981786131858826,
      0.04061368107795715,
      -0.044941291213035583,
      0.05590439960360527,
      0.09086868911981583,
      0.07988015562295914,
      -0.010424035601317883,
      0.008878116495907307,
      -0.009537359699606895,
      0.04508958384394646,
      -0.10716421902179718,
      -0.016461053863167763,
      -0.009038131684064865,
      -0.05569121986627579,
      0.07965277135372162,
      -0.07217951118946075,
      0.003945410717278719,
      0.057841621339321136,
      -0.03102334588766098,
      -0.047580257058143616,
      -0.026153434067964554,
      -0.018004313111305237,
      0.02587416023015976,
      0.056511763483285904,
      0.01671093888580799,
      -0.010911500081419945,
      -0.001386404619552195,
      0.0429743267595768,
      -0.056752804666757584,
      0.006582869216799736,
      0.08801641315221786,
      -0.03904848173260689,
      -0.017569372430443764,
      0.05513918027281761,
      -0.012253353372216225,
      0.019434059038758278,
      -0.07336367666721344,
      -0.10945555567741394,
      0.016334740445017815,
      -0.01713147945702076,
      -0.043844543397426605,
      -0.07519358396530151,
      0.09991097450256348,
      -0.01372358575463295,
      -0.02005511336028576,
      0.06380649656057358,
      0.03012574277818203,
      -0.03212231025099754,
      0.0004296457045711577,
      -0.02168223261833191,
      0.00978814996778965,
      -0.04706993326544762,
      -0.06124299392104149,
      0.021314537152647972,
      0.05214006453752518,
      -0.0516233965754509,
      -0.020587768405675888,
      0.006081212777644396,
      -0.04924812912940979,
      -0.02192804589867592,
      -0.1423160284757614,
      -0.007179612759500742,
      -0.01504263374954462,
      -0.03767465800046921,
      -0.044009219855070114,
      0.0026101258117705584,
      -0.12724879384040833,
      -0.057400722056627274,
      0.03451569378376007,
      0.10330676287412643,
      0.08041291683912277,
      -0.1347426027059555,
      0.0689820796251297,
      -0.025688644498586655,
      -0.04400930181145668,
      -0.02523605152964592,
      -0.01762496680021286,
      0.014220662415027618,
      -0.004712204448878765,
      0.028183722868561745,
      0.0023680818267166615,
      -0.017170388251543045,
      0.05201881751418114,
      -0.038745980709791183,
      0.004453835543245077,
      -0.030266838148236275,
      -0.036775194108486176,
      0.0927865132689476,
      -0.03981015831232071,
      0.03130359575152397,
      0.06382244825363159,
      -0.009850699454545975,
      0.039454326033592224,
      0.06486772000789642,
      -0.0570344440639019,
      0.026380108669400215,
      -0.008140052668750286,
      -0.13345301151275635,
      -0.07526198774576187,
      -0.0551723912358284,
      -0.05218375474214554,
      0.000813566439319402,
      0.10997439175844193,
      0.04871004819869995,
      -0.026147527620196342,
      0.09778589010238647,
      0.026597758755087852,
      -0.060388848185539246,
      -0.06909944117069244,
      0.003336319699883461,
      -0.05634753778576851,
      -0.09347424656152725,
      -0.006851437035948038,
      -0.009518897160887718,
      0.05045464262366295,
      -0.09534364193677902,
      -0.04818996787071228,
      -0.010956105776131153,
      -0.00752489548176527,
      -0.008539553731679916,
      -0.04815467447042465,
      0.028803976252675056,
      -0.013624568469822407,
      -0.03874459117650986,
      0.009636093862354755,
      0.029818439856171608,
      0.08398542553186417,
      0.01825689524412155,
      0.003359431168064475,
      -0.022437987849116325,
      0.055518217384815216,
      0.022283820435404778,
      0.08445943146944046,
      0.005684180185198784,
      0.0736159011721611,
      0.0028174822218716145,
      -0.06856770813465118,
      0.008797132410109043,
      -0.004768103826791048,
      -0.03178159147500992,
      0.008524879813194275,
      -0.01146141067147255,
      -0.009537925943732262,
      0.037132952362298965,
      0.004439112264662981,
      -0.0411088801920414,
      0.06127811223268509,
      -0.00019996738410554826,
      0.0740048810839653,
      -0.052975282073020935,
      0.08278907835483551,
      0.012165519408881664,
      -0.03027164936065674,
      0.010401162318885326,
      -0.03291924670338631,
      0.03649371117353439,
      0.022928820922970772,
      0.03182874619960785,
      0.025599684566259384,
      0.054308079183101654,
      0.06804575026035309,
      0.0304449163377285,
      0.0046807206235826015,
      0.012019186280667782,
      0.007896151393651962,
      0.028523776680231094,
      0.002434896305203438,
      -0.011290613561868668,
      -0.021732505410909653,
      0.06957190483808517,
      -0.02474593184888363,
      0.007715305313467979,
      0.023684818297624588,
      -0.04971552640199661,
      0.038726091384887695,
      0.0413663424551487,
      0.018796931952238083,
      -0.026631629094481468,
      0.008538700640201569,
      0.023938218131661415,
      -0.009397115558385849,
      -0.04485857114195824,
      0.03312245011329651,
      -0.038868699222803116,
      -0.060242291539907455,
      -0.03111649863421917,
      -0.04124913737177849,
      -0.008607926778495312,
      0.04989948868751526,
      -0.014675521291792393,
      -0.01651613600552082,
      0.0265133585780859,
      -0.01492849737405777,
      0.012317908927798271,
      -0.04433254152536392,
      0.03753332421183586,
      0.02819700911641121,
      0.0008650098461657763,
      -0.09938731789588928,
      -0.005524575710296631,
      -0.07340947538614273,
      0.06098537892103195,
      0.0036566155031323433,
      0.07206476479768753,
      0.06999895721673965,
      -0.04507250338792801,
      0.09527799487113953,
      0.08582288026809692,
      0.04279996454715729,
      0.01248395536094904,
      0.026439031586050987,
      0.033937036991119385,
      -0.015530383214354515,
      0.07549457997083664,
      -0.0853683352470398,
      0.07963160425424576,
      -0.011068915948271751,
      0.036474209278821945,
      0.021306201815605164,
      0.08611082285642624,
      -0.006846347823739052,
      -0.03729167953133583,
      -0.020244326442480087,
      -0.025270424783229828,
      0.027468767017126083,
      0.034682631492614746,
      -0.003224188694730401,
      0.018109120428562164,
      -0.05229082331061363,
      0.03598582372069359,
      0.010376946069300175,
      0.012787926942110062,
      0.04069855436682701,
      0.017606893554329872,
      -0.021396538242697716,
      -0.029971156269311905,
      -0.07271795719861984,
      -0.06780692934989929,
      -0.026428701356053352,
      0.032933980226516724,
      0.04761321097612381,
      0.03929252550005913,
      -0.07776504755020142,
      -0.019840186461806297,
      -0.08064727485179901,
      -0.07185935974121094,
      -0.009122492745518684,
      0.09077903628349304,
      -0.03301525488495827,
      0.008645624853670597,
      0.04810831695795059,
      -0.001092535792849958,
      -0.04403739050030708,
      -0.013360870070755482,
      0.03177706152200699,
      -0.0012555510038509965,
      0.015431828796863556,
      0.07625404745340347,
      0.009110548533499241,
      0.051724035292863846,
      -0.051267147064208984,
      0.084161676466465,
      -0.03613333776593208,
      0.05200136825442314,
      -0.05930466949939728,
      -0.09149983525276184,
      -0.03316235542297363,
      0.030492329970002174,
      -0.019745875149965286,
      0.06484021991491318,
      -0.08820415288209915,
      0.008088535629212856,
      -0.03287341818213463,
      0.06790639460086823,
      -1.3455047337629367e-05,
      0.0464373417198658,
      0.06387295573949814,
      0.05120473727583885,
      0.03138431906700134,
      0.002925180597230792,
      -0.03806593269109726,
      0.016358040273189545,
      0.1110314279794693,
      0.02140447311103344,
      0.08700358867645264,
      0.011327984742820263,
      0.010314259678125381
    ],
    [
      0.06638415902853012,
      0.04989131540060043,
      -0.02176184020936489,
      -0.008136395364999771,
      0.012999181635677814,
      -0.01287067960947752,
      0.1020897850394249,
      0.0017413250170648098,
      -0.027775442227721214,
      0.00702704256400466,
      -0.014693842269480228,
      0.005314054898917675,
      -0.005352026782929897,
      0.0810142308473587,
      0.023094575852155685,
      -0.05073978379368782,
      -0.05186291038990021,
      -0.018896562978625298,
      0.02579168602824211,
      -0.011570919305086136,
      0.028093745931982994,
      -0.010281190276145935,
      0.005567869637161493,
      -0.01905960775911808,
      0.00471450574696064,
      -0.03915343061089516,
      -0.04605378210544586,
      -0.057114627212285995,
      0.0073832497000694275,
      -0.007003201171755791,
      0.031209638342261314,
      0.03997449204325676,
      0.08516351133584976,
      0.016755035147070885,
      -0.006870945915579796,
      0.06684854626655579,
      0.02093985304236412,
      -0.012834900990128517,
      -0.016077585518360138,
      -0.050919558852910995,
      -0.012891339138150215,
      -0.019222991541028023,
      -0.008371078409254551,
      -0.0009025967447087169,
      -0.06728893518447876,
      0.015513037331402302,
      -0.04177248850464821,
      0.0048017725348472595,
      -0.001814905321225524,
      -0.00845655519515276,
      0.008571316488087177,
      0.03759429603815079,
      -0.10113026946783066,
      0.055475104600191116,
      0.017993055284023285,
      0.06239083781838417,
      0.031116386875510216,
      -0.02757379598915577,
      -0.018313877284526825,
      -0.05528255179524422,
      0.07524333894252777,
      -0.043513134121894836,
      0.05139746144413948,
      -0.04817454144358635,
      -0.03537842631340027,
      -0.09600139409303665,
      -0.05812698230147362,
      -0.0680338591337204,
      -0.041548680514097214,
      -0.016833776608109474,
      -0.013803277164697647,
      0.09170065820217133,
      -0.04535842314362526,
      0.050550661981105804,
      0.0007583778351545334,
      -0.01623271405696869,
      -0.005731281358748674,
      -0.025282805785536766,
      0.004318277817219496,
      0.01084089931100607,
      -0.0295377429574728,
      -0.014454270713031292,
      -0.05362941697239876,
      0.06939384341239929,
      -0.020841719582676888,
      -0.07746758311986923,
      0.03444859758019447,
      -0.004518663510680199,
      -0.00207996042445302,
      0.07378048449754715,
      -0.014317534863948822,
      0.005099249072372913,
      -0.03785683214664459,
      -0.040701448917388916,
      0.003233390860259533,
      0.02825409732758999,
      -0.013180414214730263,
      0.032664258033037186,
      0.06359460204839706,
      -0.00045640169992111623,
      -0.03717029467225075,
      0.02395167388021946,
      -0.0595625601708889,
      0.076234832406044,
      0.03873850405216217,
      0.007566086947917938,
      -0.03640531003475189,
      -0.025697750970721245,
      -0.008276702836155891,
      -0.011808059178292751,
      -0.060064416378736496,
      0.06973637640476227,
      0.04702989012002945,
      0.0017142636934295297,
      0.027922634035348892,
      -0.06553374230861664,
      -0.010597342625260353,
      0.011290062218904495,
      0.017455914989113808,
      -4.602306944434531e-05,
      -0.0474206805229187,
      0.04701729118824005,
      -0.007863284088671207,
      -0.008788686245679855,
      0.08617289364337921,
      0.04577045887708664,
      -0.026284612715244293,
      -0.013533645309507847,
      0.11379973590373993,
      -0.043086107820272446,
      -0.007334316149353981,
      0.0023856074549257755,
      0.069504514336586,
      -0.02243839204311371,
      0.02211892232298851,
      0.03997546434402466,
      -0.02031029760837555,
      0.05674087256193161,
      -0.03595349192619324,
      0.00048100907588377595,
      -0.0035272177774459124,
      0.024879489094018936,
      -0.031126396730542183,
      0.019861767068505287,
      0.009314988739788532,
      -0.008983040228486061,
      -0.00505767110735178,
      -0.0540885291993618,
      -0.02269222028553486,
      -0.03981256112456322,
      0.0051264711655676365,
      0.014104058966040611,
      -0.026767952367663383,
      0.033699881285429,
      0.09899253398180008,
      0.06379172205924988,
      -0.05335235968232155,
      -0.11551178991794586,
      0.004493721295148134,
      -0.04819963872432709,
      0.043078985065221786,
      0.04051535576581955,
      -0.015218290500342846,
      0.011811488308012486,
      0.010161112993955612,
      0.004960766993463039,
      0.03223099932074547,
      -0.1389860212802887,
      0.04029522091150284,
      0.032677315175533295,
      0.0274539552628994,
      -0.010747506283223629,
      -0.030107084661722183,
      -0.02013043873012066,
      -0.06844960898160934,
      0.0065539018251001835,
      -0.05525153502821922,
      0.021319355815649033,
      0.03932340815663338,
      -0.09619549661874771,
      -0.0458335280418396,
      0.021486399695277214,
      -0.01985739730298519,
      -0.010164547711610794,
      0.018986204639077187,
      -0.004491573665291071,
      -0.01221766322851181,
      0.055875830352306366,
      0.04030013084411621,
      -0.03844558820128441,
      0.027647338807582855,
      -0.036857347935438156,
      0.02584676630795002,
      0.010203894227743149,
      -0.02019166760146618,
      -0.011315738782286644,
      -0.0483817458152771,
      -0.04924323409795761,
      0.09998201578855515,
      0.012885763309895992,
      0.010154981166124344,
      0.04585038125514984,
      0.034976642578840256,
      0.01885521598160267,
      -0.06588719040155411,
      0.10303614288568497,
      0.012937052175402641,
      0.013848423957824707,
      0.012730281800031662,
      0.0018062257440760732,
      -0.0005385745316743851,
      -0.07607798278331757,
      0.013965846970677376,
      -0.032847583293914795,
      0.044823888689279556,
      0.004849500022828579,
      -0.00912348460406065,
      -0.029280129820108414,
      0.11452335119247437,
      -0.0010417915182188153,
      0.009008944034576416,
      0.010349678806960583,
      -0.03787636011838913,
      0.04774001985788345,
      0.05263500660657883,
      -0.04660220816731453,
      0.029739823192358017,
      0.08975771814584732,
      -0.041148316115140915,
      -0.11990570276975632,
      0.0008661132305860519,
      -0.1426803022623062,
      0.03849130496382713,
      0.0229292344301939,
      -0.11980388313531876,
      -0.06852336972951889,
      -0.014252856373786926,
      -0.020729416981339455,
      0.00042270051199011505,
      -0.03639434278011322,
      -0.13950179517269135,
      0.0342787429690361,
      0.03094765916466713,
      0.03181668370962143,
      0.10603861510753632,
      0.0655195564031601,
      -0.001457384554669261,
      0.009917506948113441,
      0.05883133038878441,
      0.041804488748311996,
      -0.0031009293161332607,
      -0.008579832501709461,
      0.029601098969578743,
      0.007369025610387325,
      -0.03709711879491806,
      -0.14330559968948364,
      0.034006666392087936,
      -0.04549868404865265,
      0.004584300331771374,
      -0.013730820268392563,
      -0.022405119612812996,
      0.03168399631977081,
      0.008680357597768307,
      -0.050521932542324066,
      -0.01498273853212595,
      -0.0033754492178559303,
      0.0403088703751564,
      0.04244859516620636,
      0.012217828072607517,
      0.03225434944033623,
      0.06571491807699203,
      0.0016060059424489737,
      0.024327268823981285,
      -0.02759047970175743,
      0.04003426805138588,
      0.0026984233409166336,
      -0.017846880480647087,
      0.006341013126075268,
      0.04534965753555298,
      -0.0727340430021286,
      -0.018117493018507957,
      -0.02970406971871853,
      0.05227207392454147,
      -0.07041725516319275,
      0.06353920698165894,
      0.03141333535313606,
      0.02477259561419487,
      -0.016041334718465805,
      -0.02696828357875347,
      0.043538086116313934,
      -0.03192274644970894,
      0.07723627239465714,
      0.03107309713959694,
      -0.02519354037940502,
      0.06672179698944092,
      0.06367179751396179,
      -0.044589146971702576,
      -0.025790106505155563,
      -0.006520225200802088,
      -0.004302232526242733,
      -0.02089029736816883,
      0.060252007097005844,
      -0.0338653102517128,
      0.03345419093966484,
      0.061942119151353836,
      -0.027528129518032074,
      -0.08035694062709808,
      -0.058209143579006195,
      0.002376386895775795,
      -0.02557503804564476,
      -0.08530224114656448,
      -0.024184012785553932,
      -0.024628672748804092,
      0.023904072120785713,
      0.06629844754934311,
      0.0338171161711216,
      0.001078652567230165,
      0.0046816724352538586,
      0.02766072191298008,
      -0.029056139290332794,
      -0.06146663427352905,
      0.03889310359954834,
      -0.03626266494393349,
      -0.02546551078557968,
      -0.02527715265750885,
      -0.03966132923960686,
      0.0728989690542221,
      0.039212360978126526,
      -0.10445638746023178,
      -0.05101645737886429,
      0.025377744808793068,
      -0.08400745689868927,
      -0.024260135367512703,
      0.018473701551556587,
      0.04716765135526657,
      0.003830327419564128,
      -0.022032031789422035,
      -0.04235956445336342,
      -0.003123317379504442,
      0.048177801072597504,
      -0.033680934458971024,
      0.053479548543691635,
      -0.03163924813270569,
      -0.03911455348134041,
      -0.006883613299578428,
      -0.010887617245316505,
      -0.04159083217382431,
      -0.059490084648132324,
      0.03408551961183548,
      0.06661376357078552,
      -0.015225453302264214,
      -0.02834540419280529,
      -0.05111486464738846,
      -0.11481226235628128,
      0.02401822991669178,
      0.012144950218498707,
      0.003914186730980873,
      0.01454384345561266,
      0.12395022809505463,
      0.06159920617938042,
      -0.0267182644456625,
      0.03842945396900177,
      0.037764959037303925,
      -0.019191158935427666,
      0.03890594467520714,
      0.04313647001981735,
      -0.002507994882762432,
      0.00684760557487607,
      -0.04985440522432327,
      -0.043235063552856445,
      0.02323889546096325,
      0.04195167124271393,
      0.05567329004406929,
      0.0001295041001867503,
      -0.04104224219918251,
      0.01870250515639782,
      -0.023932544514536858,
      0.012158392928540707,
      0.04554333910346031,
      0.0066899703815579414,
      -0.007519892416894436,
      0.02582797221839428,
      -0.010432817041873932,
      0.06513410806655884,
      -0.01412029005587101,
      0.055605243891477585,
      0.0011590912472456694,
      0.08810678124427795,
      -0.02568892017006874,
      -0.058778390288352966,
      0.03363815322518349,
      0.043614283204078674,
      0.017506005242466927,
      -0.031920477747917175,
      0.06346379220485687,
      0.00724702188745141,
      0.10459649562835693,
      -0.07154271751642227,
      -0.03482254222035408,
      0.00715399906039238,
      -0.03557746112346649,
      -0.029932396486401558,
      -0.04045531153678894,
      -0.03357371687889099,
      0.00043696543434634805,
      0.00625259056687355,
      -0.011728827841579914,
      0.06718692928552628,
      -0.006879259832203388,
      -0.04270588979125023,
      0.09572446346282959,
      -0.02931508608162403,
      -0.044373393058776855,
      -0.033136773854494095,
      0.037697311490774155,
      0.026023631915450096,
      0.060772307217121124,
      0.12022761255502701,
      0.008226471021771431,
      0.1009598895907402,
      -0.03591230884194374,
      0.047429464757442474,
      -0.04498108848929405,
      -0.014285601675510406,
      0.004411465488374233,
      -0.03880489617586136,
      -0.005540536250919104,
      -0.007915480062365532,
      -0.08373785018920898,
      0.0190769974142313,
      0.04730015993118286,
      0.013593667186796665,
      0.0038670876529067755,
      -0.023936131969094276,
      -0.0628962367773056,
      -0.07034347206354141,
      0.029412010684609413,
      0.03216349706053734,
      -0.03189768269658089,
      0.06178798899054527,
      0.01851825602352619,
      0.04234135150909424,
      -0.10111330449581146,
      -0.007313764188438654,
      0.004630047362297773,
      -0.021542571485042572,
      -0.040870171040296555,
      0.05166507139801979,
      0.02561621367931366,
      -0.05491235852241516,
      0.052833929657936096,
      -0.06251567602157593,
      -0.08649903535842896,
      -0.039024677127599716,
      0.03155442699790001,
      -0.07239582389593124,
      0.04543277248740196,
      -0.06651338189840317,
      0.04167037084698677,
      0.028691383078694344,
      -0.025676250457763672,
      -0.025327181443572044,
      -0.07631294429302216,
      0.07573530822992325,
      -0.0055944123305380344,
      -0.03809892013669014,
      0.026505110785365105,
      0.11167287081480026,
      -0.04100871831178665,
      0.014229894615709782,
      0.00936487503349781,
      -0.003132611745968461,
      0.0007474288577213883,
      0.01937808468937874,
      -0.00036633593845181167,
      -0.05748307704925537,
      -0.030210023745894432,
      -0.04689745604991913,
      0.04966143146157265,
      -0.036067601293325424,
      0.04332559183239937,
      -0.027701091021299362,
      -0.04750094562768936,
      -0.08720436692237854,
      -0.03312648832798004,
      -0.09320113807916641,
      0.04956956207752228,
      0.003228091634809971,
      -0.0022208173759281635,
      -0.03229042515158653,
      -0.025554301217198372,
      0.05601026117801666,
      -0.052718352526426315,
      0.029808130115270615,
      0.0010902598733082414,
      0.003769675735384226,
      -0.035113293677568436,
      -0.010757040232419968,
      -0.005675218533724546,
      -0.0590820275247097,
      -0.06701744347810745,
      0.06640928983688354,
      0.04166257381439209
    ],
    [
      0.1047852486371994,
      -0.0871000736951828,
      -0.04619315639138222,
      0.038366857916116714,
      -0.01164534967392683,
      -0.059183280915021896,
      -0.007089977152645588,
      0.03282836079597473,
      -0.0031880950555205345,
      -0.008183085359632969,
      -0.020831875503063202,
      -0.0007149444427341223,
      -0.005018947646021843,
      0.0014371626311913133,
      -0.02923770435154438,
      0.05295891687273979,
      0.008243171498179436,
      0.013044995255768299,
      0.02338600903749466,
      0.0038801098708063364,
      -0.0006610259879380465,
      0.0029607219621539116,
      -0.01696980558335781,
      0.02205492928624153,
      0.06694667041301727,
      -0.037812553346157074,
      -0.008396385237574577,
      -0.01302166748791933,
      -0.0020201343577355146,
      -0.0010231039486825466,
      -0.019571596756577492,
      0.0704343318939209,
      -0.040033794939517975,
      0.0797436460852623,
      0.026918094605207443,
      -0.02358352579176426,
      -0.006947570946067572,
      -0.01445822138339281,
      -0.02706846594810486,
      0.015267983078956604,
      -0.08092391490936279,
      -0.051585737615823746,
      0.037473466247320175,
      0.02773987501859665,
      0.033733464777469635,
      -0.01653289422392845,
      -0.018978029489517212,
      0.05883249640464783,
      0.01902121677994728,
      0.029765354469418526,
      -0.008377022109925747,
      0.006080742459744215,
      -0.019399048760533333,
      0.0132058784365654,
      -0.025885187089443207,
      0.05651889741420746,
      -0.07369082421064377,
      0.010890589095652103,
      -0.05260320380330086,
      0.11863673478364944,
      0.024788152426481247,
      -0.020420856773853302,
      0.018156006932258606,
      0.008246531710028648,
      0.004249426536262035,
      -0.02900606393814087,
      -0.012963717803359032,
      0.0026527729351073503,
      0.029425689950585365,
      -0.03484923765063286,
      -0.0042933449149131775,
      -0.0035113932099193335,
      -0.004112016875296831,
      0.01829506829380989,
      0.026950504630804062,
      -0.04657524451613426,
      -0.012080888263881207,
      0.024121610447764397,
      0.0912693589925766,
      0.04731379821896553,
      -0.00828966312110424,
      0.027321020141243935,
      -0.023587295785546303,
      0.04597007855772972,
      0.024389782920479774,
      -0.04375329241156578,
      -0.003711552359163761,
      -0.011912121437489986,
      0.011397569440305233,
      0.04039384052157402,
      0.0197210181504488,
      0.09390942007303238,
      -0.008253325708210468,
      -0.06115918233990669,
      -0.026696637272834778,
      0.0892273336648941,
      0.04741406813263893,
      0.037231024354696274,
      -0.020759547129273415,
      0.03535332530736923,
      -0.07979406416416168,
      0.0656764879822731,
      0.00723282853141427,
      -0.01553773321211338,
      0.04506547003984451,
      -0.07534829527139664,
      -0.112978495657444,
      0.03354471176862717,
      0.040778543800115585,
      0.0006375228404067457,
      0.0029521998949348927,
      -0.0250380951911211,
      -0.06164828687906265,
      -0.005206732545047998,
      0.007028748281300068,
      0.05790631100535393,
      0.014624087139964104,
      0.0834835097193718,
      0.053144216537475586,
      0.03071538731455803,
      -0.06362148374319077,
      0.03806064650416374,
      -0.006023705471307039,
      -0.021285375580191612,
      1.9261811758042313e-05,
      -0.004024276975542307,
      0.02644968219101429,
      -0.028694281354546547,
      -0.06601422280073166,
      -0.025984324514865875,
      -0.0027680799830704927,
      -0.05374923720955849,
      0.004998175892978907,
      0.020470695570111275,
      0.03151150792837143,
      0.0006037557614035904,
      -0.0778411328792572,
      0.000179784678039141,
      0.04410933330655098,
      0.03364018723368645,
      -0.0050082337111234665,
      -0.039795469492673874,
      0.04893934354186058,
      0.021476279944181442,
      -0.05220148712396622,
      0.09436879307031631,
      0.0673486515879631,
      0.12492093443870544,
      -0.023182805627584457,
      -0.030509518459439278,
      0.15892155468463898,
      -0.04860961437225342,
      -0.06646552681922913,
      0.062275469303131104,
      0.028710518032312393,
      -0.03471090644598007,
      0.0010326383635401726,
      0.0005881134420633316,
      -0.03513862192630768,
      -0.038174696266651154,
      0.02185210958123207,
      0.01196291670203209,
      -0.008917278610169888,
      0.03946658596396446,
      0.013827111572027206,
      -0.00028934190049767494,
      -0.004090867005288601,
      -0.061179086565971375,
      -0.0015241957735270262,
      -0.04444248229265213,
      0.07753980904817581,
      -0.05925905704498291,
      -0.03186236321926117,
      0.0350986048579216,
      -0.0025167486164718866,
      0.06776535511016846,
      0.08791200071573257,
      -0.0373057946562767,
      -0.04664161056280136,
      -0.03195352479815483,
      -0.03670627623796463,
      -0.002719996962696314,
      0.07388347387313843,
      0.02072393149137497,
      0.010453765280544758,
      -0.019566379487514496,
      0.01588858850300312,
      -0.0017318519530817866,
      0.007652119267731905,
      0.004424349870532751,
      0.022307856008410454,
      0.039303574711084366,
      -0.0014585729222744703,
      -0.049899522215127945,
      -0.06604266911745071,
      -0.04495520889759064,
      0.009621613658964634,
      -0.06760217249393463,
      -0.0030315278563648462,
      0.030725453048944473,
      0.012345816008746624,
      -0.010054613463580608,
      -0.02228841371834278,
      6.916906568221748e-05,
      0.027225211262702942,
      -0.012226149439811707,
      0.02287556789815426,
      -0.026882478967308998,
      0.00910956785082817,
      0.03714355453848839,
      0.049228668212890625,
      -0.06061817333102226,
      0.024275459349155426,
      0.02064984105527401,
      -0.022620216012001038,
      0.008181609213352203,
      0.003586063627153635,
      -0.07833439856767654,
      0.06309089809656143,
      -0.05761120840907097,
      0.0245541799813509,
      0.06898913532495499,
      0.022184964269399643,
      0.04841677099466324,
      0.020726719871163368,
      -0.03263924643397331,
      -0.0069697219878435135,
      -0.006612518336623907,
      -0.04359876736998558,
      -0.004969194997102022,
      0.042893048375844955,
      -0.028486503288149834,
      0.06439973413944244,
      0.07095834612846375,
      -0.0004619125393219292,
      -0.007481764070689678,
      -0.01200985535979271,
      0.04071515053510666,
      0.026778366416692734,
      -0.03476211056113243,
      -0.08746326714754105,
      -0.0053929053246974945,
      0.11006172746419907,
      -0.05843236669898033,
      -0.06836159527301788,
      0.024725962430238724,
      0.006744773127138615,
      0.02449677884578705,
      -0.005684645846486092,
      -0.0040428820066154,
      -0.007203943096101284,
      -0.029601242393255234,
      0.011175592429935932,
      -0.038146644830703735,
      0.015585435554385185,
      -0.022726142778992653,
      -0.03587793558835983,
      -0.04960682988166809,
      0.0168758574873209,
      -0.013584979809820652,
      -0.05586881935596466,
      -0.006260248366743326,
      -0.08365713059902191,
      0.030931945890188217,
      0.03742027282714844,
      0.02796640619635582,
      0.010378827340900898,
      -0.034116748720407486,
      -0.016251955181360245,
      0.009630735963582993,
      -0.03158693388104439,
      0.00491610262542963,
      -0.04617927968502045,
      -0.04221561178565025,
      0.09893269836902618,
      -0.025815090164542198,
      0.03312045335769653,
      -0.03498424217104912,
      -0.04666830971837044,
      -0.04455545172095299,
      -0.023674149066209793,
      0.028092101216316223,
      -0.11765313148498535,
      -0.03790119290351868,
      0.050352923572063446,
      -0.003234042087569833,
      0.030106928199529648,
      -0.043006621301174164,
      0.09169752150774002,
      0.0009105727658607066,
      0.07265491038560867,
      -0.003510181326419115,
      0.0472775436937809,
      0.024062493816018105,
      -0.016500797122716904,
      0.08660757541656494,
      0.04922725632786751,
      -0.05940379574894905,
      0.030516209080815315,
      0.02096983976662159,
      -0.040064238011837006,
      -0.08921875804662704,
      0.006303400732576847,
      -0.026394139975309372,
      0.04873669892549515,
      0.03987785056233406,
      -0.024906130507588387,
      0.02155810408294201,
      -0.05158206447958946,
      0.029915813356637955,
      -0.09339931607246399,
      0.05474494397640228,
      -0.07049046456813812,
      -0.002436830196529627,
      -0.008835983462631702,
      0.003287726081907749,
      0.009419668465852737,
      0.024406343698501587,
      0.037763744592666626,
      0.016668690368533134,
      0.026837652549147606,
      -0.023897115141153336,
      0.040583375841379166,
      -0.01475450024008751,
      0.009669230319559574,
      0.008914226666092873,
      0.027525631710886955,
      -0.04543342813849449,
      0.007888763211667538,
      0.08608710765838623,
      -0.03710508346557617,
      -0.04424353316426277,
      -0.07105634361505508,
      -0.07788076996803284,
      -0.0014933012425899506,
      -0.04491768777370453,
      0.04714630916714668,
      0.004993288312107325,
      -0.05239644646644592,
      0.07949210703372955,
      -0.011949226260185242,
      0.04229303076863289,
      0.03505058214068413,
      0.058852050453424454,
      -0.01188424788415432,
      -0.04660734534263611,
      0.007553064730018377,
      -0.06564655154943466,
      -0.002514246152713895,
      0.05154329910874367,
      0.020356079563498497,
      0.0313778780400753,
      -0.023458771407604218,
      0.03214690089225769,
      0.022562123835086823,
      0.012408753857016563,
      -0.014029360376298428,
      0.029707331210374832,
      0.024455120787024498,
      0.05332057178020477,
      -0.03562456741929054,
      0.0247392226010561,
      -0.024113697931170464,
      -0.0004003295907750726,
      0.031211063265800476,
      -0.07967333495616913,
      -0.061154745519161224,
      0.006880376022309065,
      -0.0552758127450943,
      -0.028268195688724518,
      -0.05187241733074188,
      -0.09673716872930527,
      -0.05909593030810356,
      -0.05387207865715027,
      -0.019185662269592285,
      0.04740818217396736,
      -0.005764876026660204,
      0.011614625342190266,
      -0.022318998351693153,
      0.00497449142858386,
      -0.03386937454342842,
      0.009914997965097427,
      0.005976411979645491,
      0.021260617300868034,
      0.010534715838730335,
      -0.028769049793481827,
      -0.01605788990855217,
      -0.0032279749866575003,
      -0.03276646509766579,
      0.0751141756772995,
      -0.009061980992555618,
      0.014730721712112427,
      -0.011551223695278168,
      -0.02760355919599533,
      -0.013086577877402306,
      0.006986752152442932,
      -0.023243680596351624,
      -0.055701907724142075,
      0.04607221856713295,
      0.015540529042482376,
      0.0005253056297078729,
      -0.02201961539685726,
      0.05343298614025116,
      -0.0022452480625361204,
      0.0331624299287796,
      0.023237816989421844,
      0.0645868331193924,
      0.025774510577321053,
      -0.05610300227999687,
      -0.02569613792002201,
      0.0795968621969223,
      -0.050566416233778,
      0.02272706665098667,
      -0.027919260784983635,
      -0.05766857787966728,
      -0.01942768506705761,
      0.016825377941131592,
      0.026210125535726547,
      -0.03930903971195221,
      -0.05008118227124214,
      0.006419473327696323,
      -0.07125082612037659,
      -0.009701287373900414,
      0.03231710195541382,
      -0.01810791902244091,
      0.0184175968170166,
      -0.09688358753919601,
      0.09950989484786987,
      -0.07523899525403976,
      0.03882506489753723,
      0.08557146787643433,
      -0.009057697840034962,
      -0.007439034059643745,
      0.06916960328817368,
      -0.027405278757214546,
      0.03420865535736084,
      0.03498469665646553,
      -0.07330814749002457,
      -0.08041835576295853,
      -0.09064769744873047,
      -0.026102924719452858,
      -0.008912507444620132,
      0.0699097067117691,
      -0.010713471099734306,
      -0.0017981507116928697,
      -0.005543581210076809,
      0.013884501531720161,
      -0.022547146305441856,
      -0.024594862014055252,
      0.0327448844909668,
      -0.04039952531456947,
      0.038338419049978256,
      0.005368113052099943,
      -0.02072940766811371,
      0.02129538729786873,
      -0.006018195301294327,
      0.017898673191666603,
      0.03290066123008728,
      -0.033423781394958496,
      -0.09081242233514786,
      0.01808217726647854,
      0.04182443767786026,
      0.060826413333415985,
      0.02912319079041481,
      0.13614468276500702,
      0.06864887475967407,
      0.05070142447948456,
      0.010976854711771011,
      0.0776924267411232,
      -0.004875703249126673,
      0.057436857372522354,
      0.08824250102043152,
      -0.015964992344379425,
      -0.04512768238782883,
      -0.05966717749834061,
      -0.0309769194573164,
      0.04850628226995468,
      -0.029800569638609886,
      0.025169963017106056,
      -0.017677409574389458,
      -0.07937585562467575,
      0.01780722662806511,
      0.057184044271707535,
      0.04801975190639496,
      -0.010446584783494473,
      -0.006196271628141403,
      0.07004749774932861,
      0.10602577775716782,
      -0.00754125602543354,
      -0.04883489012718201,
      -0.01666971668601036,
      0.01702250726521015,
      -0.029509350657463074,
      -0.05025920271873474,
      0.03813346102833748,
      0.04774141311645508,
      0.042044248431921005,
      0.06274890899658203,
      0.01874452643096447,
      0.06074904650449753,
      0.11038681119680405,
      0.02618694119155407,
      0.00772504135966301
    ],
    [
      -0.010299318470060825,
      0.017244495451450348,
      -0.04605509340763092,
      -0.04734603688120842,
      -0.010436908341944218,
      -0.03971016779541969,
      -0.012247799895703793,
      -0.014651232399046421,
      -0.05229729041457176,
      0.011858480982482433,
      -0.036899492144584656,
      0.06433548033237457,
      -0.018878227099776268,
      -0.09530086070299149,
      0.06736080348491669,
      -0.03740479797124863,
      0.04046564921736717,
      -0.10185711085796356,
      0.012283790856599808,
      0.05433938279747963,
      -0.00300047779455781,
      -0.05526689812541008,
      -0.07798690348863602,
      0.001330166356638074,
      0.011597921140491962,
      -0.0995921716094017,
      0.007497220300137997,
      -0.019406093284487724,
      0.05296620726585388,
      0.010979852639138699,
      0.025433966889977455,
      -0.008059565909206867,
      -0.1033238098025322,
      -0.04456249624490738,
      -0.06832782924175262,
      -0.0016926639946177602,
      0.06695199757814407,
      -0.0016920214984565973,
      0.0729239210486412,
      -0.030560709536075592,
      -0.030315715819597244,
      0.029843134805560112,
      0.009633961133658886,
      -0.005371597595512867,
      0.07497454434633255,
      0.022023966535925865,
      -0.07467664033174515,
      -0.02609095349907875,
      -0.10765748471021652,
      -0.040439724922180176,
      0.06406665593385696,
      0.027127927169203758,
      0.04401194304227829,
      0.034221626818180084,
      0.07067499309778214,
      0.04303526505827904,
      -0.029454898089170456,
      0.0488702729344368,
      0.02642512507736683,
      -0.0015275359619408846,
      -0.004279200918972492,
      -0.09320983290672302,
      0.05407378822565079,
      0.010395621880888939,
      0.002676172647625208,
      0.0034990289714187384,
      -0.012829011306166649,
      0.03530888259410858,
      -0.0023962692357599735,
      0.00878572091460228,
      0.007358567789196968,
      0.07542803883552551,
      0.016230108216404915,
      0.04163817688822746,
      0.0536670982837677,
      0.034743692725896835,
      -0.07750749588012695,
      -0.06476381421089172,
      -0.008373802527785301,
      -0.0745648443698883,
      -0.05718867853283882,
      0.08353734016418457,
      -0.009451210498809814,
      0.012263195589184761,
      -0.0015512098325416446,
      0.06782327592372894,
      0.008924974128603935,
      0.09225068986415863,
      -0.05846048519015312,
      0.0005666226497851312,
      0.040000613778829575,
      0.02698735147714615,
      0.0013500734930858016,
      0.02167416736483574,
      -0.08690853416919708,
      0.017250966280698776,
      -0.0951215848326683,
      -0.1298997402191162,
      0.023867381736636162,
      -0.038313381373882294,
      -0.02683454379439354,
      0.06484901160001755,
      -0.0069328248500823975,
      0.02809731662273407,
      -0.03226179629564285,
      0.07495192438364029,
      -0.13164637982845306,
      -0.031972356140613556,
      0.09161952137947083,
      -0.022405482828617096,
      0.005469898693263531,
      -0.05961822345852852,
      -0.025952070951461792,
      0.12471714615821838,
      -0.09144585579633713,
      -0.05139083042740822,
      -0.034055836498737335,
      -0.10851447284221649,
      -0.04636155813932419,
      0.020387886092066765,
      0.04068636894226074,
      -0.04202454537153244,
      -0.03915152698755264,
      -0.01930006965994835,
      -0.019335897639393806,
      -0.024475188925862312,
      -0.040332648903131485,
      0.01275092177093029,
      -0.10905510932207108,
      0.05036855489015579,
      0.023306140676140785,
      -0.018734674900770187,
      -0.03372235968708992,
      -0.044474516063928604,
      0.014404719695448875,
      0.03691383823752403,
      -0.020354896783828735,
      0.09475236386060715,
      -0.050251904875040054,
      -0.011669092811644077,
      0.08277875185012817,
      0.01904216967523098,
      -0.04017488658428192,
      0.019981959834694862,
      0.038439467549324036,
      -0.017110776156187057,
      0.0202287957072258,
      0.11434769630432129,
      -0.024776751175522804,
      0.002418144838884473,
      -0.015329171903431416,
      0.06910661607980728,
      0.010015974752604961,
      0.04103351756930351,
      0.040501225739717484,
      -0.09398258477449417,
      -0.020766472443938255,
      -0.0187792107462883,
      0.017671341076493263,
      0.061565447598695755,
      0.015279567800462246,
      -0.06623097509145737,
      0.01850566454231739,
      0.028522424399852753,
      0.06465975940227509,
      -0.04743686318397522,
      0.07277972251176834,
      -0.06021378934383392,
      0.0342794731259346,
      0.026085609570145607,
      0.014459453523159027,
      0.04907193407416344,
      -0.1359269917011261,
      -0.009594800882041454,
      0.044627148658037186,
      0.048005424439907074,
      -0.0033098587300628424,
      -0.019048301503062248,
      0.04080786928534508,
      -0.06966398656368256,
      0.05775965750217438,
      0.01999756321310997,
      -0.022426465526223183,
      0.03703070804476738,
      -0.018820591270923615,
      0.006603349465876818,
      -0.06685394793748856,
      -0.060368530452251434,
      -0.02394653670489788,
      0.044390078634023666,
      0.03950335830450058,
      -0.0244562067091465,
      0.008709117770195007,
      0.028001103550195694,
      0.014336899854242802,
      0.04157290235161781,
      -0.03422575071454048,
      -0.0250975601375103,
      0.031906671822071075,
      0.03176696598529816,
      -0.016944371163845062,
      -0.06423478573560715,
      -0.010334806516766548,
      -0.009769684635102749,
      0.07364846765995026,
      -0.02472737617790699,
      0.02350476011633873,
      0.0761866495013237,
      0.08844193071126938,
      -0.0067445687018334866,
      -0.05031844228506088,
      -0.020621510222554207,
      -0.01479307934641838,
      0.06703203171491623,
      0.03179306909441948,
      -0.06003541499376297,
      -0.016640741378068924,
      -0.07674823701381683,
      -0.013446459546685219,
      0.04923335835337639,
      -0.015965795144438744,
      -0.05603574961423874,
      0.10842689871788025,
      -0.04150893911719322,
      0.0001848871324909851,
      0.007278697565197945,
      0.12833786010742188,
      0.031596727669239044,
      -0.006962957326322794,
      -0.0024371289182454348,
      0.05759068578481674,
      -0.02714492194354534,
      0.08836816996335983,
      -0.013896197080612183,
      -0.059002138674259186,
      0.01592266745865345,
      0.030230116099119186,
      -0.0625118687748909,
      -0.12378737330436707,
      -0.053305502980947495,
      -0.006655875593423843,
      0.030600225552916527,
      -0.06681295484304428,
      0.007532780058681965,
      -0.03925346955657005,
      -0.04202347248792648,
      -0.01559180673211813,
      -0.07197176665067673,
      -0.012613858096301556,
      0.05810261890292168,
      0.008176061324775219,
      -0.017230402678251266,
      0.060906048864126205,
      0.033910322934389114,
      -0.004232710227370262,
      0.016847526654601097,
      -0.038961127400398254,
      0.025424810126423836,
      0.039981137961149216,
      0.11597485840320587,
      0.04545970633625984,
      0.04255125671625137,
      0.04539916291832924,
      -0.04970806837081909,
      0.029912756755948067,
      -0.05548146739602089,
      -0.0345667339861393,
      0.025940418243408203,
      0.003184220287948847,
      0.01394957210868597,
      -0.05439511686563492,
      0.05265795812010765,
      0.03236735239624977,
      -0.03926073759794235,
      -0.03426815941929817,
      -0.05411010608077049,
      0.014760526828467846,
      0.014153341762721539,
      0.028467686846852303,
      -0.022601714357733727,
      -0.023514820262789726,
      -0.0022844066843390465,
      -0.04341848939657211,
      -0.004859812557697296,
      0.02267475798726082,
      0.04426516965031624,
      0.01611938327550888,
      -0.0019356691045686603,
      -0.026429787278175354,
      0.08827581256628036,
      0.0014983685687184334,
      0.03630635887384415,
      -0.023112615570425987,
      0.04024627432227135,
      0.023028219118714333,
      0.03561419993638992,
      0.07578083127737045,
      -0.030723432078957558,
      -0.009325427003204823,
      0.01704411953687668,
      -0.0005908527527935803,
      0.055275995284318924,
      0.0075767869129776955,
      0.03709079325199127,
      -0.009257701225578785,
      -0.027446230873465538,
      -0.0028001556638628244,
      -0.06259357184171677,
      -0.024186231195926666,
      -0.04671652242541313,
      -0.07590977847576141,
      0.00025028380332514644,
      -0.006269761826843023,
      -0.010355873964726925,
      0.012654842808842659,
      0.044193387031555176,
      0.06857901811599731,
      0.02515489049255848,
      -0.020768828690052032,
      0.046576518565416336,
      0.05792698636651039,
      -0.017461318522691727,
      0.014731557108461857,
      0.016499897465109825,
      -0.06292983144521713,
      0.1063970997929573,
      -0.013443505391478539,
      -0.018486136570572853,
      0.03249294310808182,
      -0.08196757733821869,
      0.05978996679186821,
      0.08527231961488724,
      -0.02169133722782135,
      -0.008953016251325607,
      -0.05784410610795021,
      -0.04761052876710892,
      -0.025533098727464676,
      -0.027385223656892776,
      0.10418642312288284,
      0.019883815199136734,
      0.03248072788119316,
      -0.054264359176158905,
      -0.09557656198740005,
      -0.04031548649072647,
      -0.016757464036345482,
      -0.08114919066429138,
      -0.05958811566233635,
      -0.10684315860271454,
      -0.01885424740612507,
      0.05098235234618187,
      0.0640726163983345,
      0.032582689076662064,
      -0.04195922613143921,
      0.05999584496021271,
      0.011301256716251373,
      -0.06770607829093933,
      -0.019742030650377274,
      0.020857134833931923,
      0.030829915776848793,
      -0.0051542045548558235,
      -0.03146964684128761,
      0.05939298868179321,
      0.007831469178199768,
      0.007485116366297007,
      0.01893698424100876,
      0.06528570502996445,
      0.019710250198841095,
      -8.047628944041207e-05,
      -0.026749325916171074,
      -0.033852383494377136,
      -0.03162626177072525,
      0.07557455450296402,
      0.025545859709382057,
      -0.052956223487854004,
      -0.015142069198191166,
      -0.011524808593094349,
      -0.014906651340425014,
      -0.04891371354460716,
      0.022242965176701546,
      0.04053962603211403,
      0.04270418360829353,
      -0.002811186946928501,
      0.006377979647368193,
      -0.04444964975118637,
      -0.02422223798930645,
      -0.01769964210689068,
      -0.034761857241392136,
      -0.019982943311333656,
      -0.017879115417599678,
      0.125931054353714,
      -0.0425935760140419,
      0.028433743864297867,
      0.002336028730496764,
      0.03439299762248993,
      0.029495758935809135,
      0.02908143401145935,
      0.05885691940784454,
      -0.012658237479627132,
      0.019846413284540176,
      -0.009606224484741688,
      -0.008420593105256557,
      -0.0070161581970751286,
      -0.007953276857733727,
      0.0040709045715630054,
      0.0961138904094696,
      -0.06511387974023819,
      0.04315846785902977,
      -0.04014020040631294,
      0.03776086866855621,
      0.049998342990875244,
      -0.0015337183140218258,
      0.03306032344698906,
      0.01881811022758484,
      -0.05704929307103157,
      -0.024535194039344788,
      0.0758838877081871,
      0.03417231887578964,
      0.040640003979206085,
      0.03555851802229881,
      0.01955992728471756,
      0.014633373357355595,
      -0.13287654519081116,
      0.006682116538286209,
      0.009123371914029121,
      0.010276038199663162,
      0.035799115896224976,
      -0.06293703615665436,
      -0.007168888580054045,
      -0.009246510453522205,
      -0.01679655909538269,
      -0.05047672614455223,
      0.010563365183770657,
      -0.05494369938969612,
      -0.057095956057310104,
      0.02132665179669857,
      -0.044912613928318024,
      -0.03417578712105751,
      -0.02085946872830391,
      -0.04973268881440163,
      0.020567426458001137,
      0.05881860479712486,
      -0.0401604063808918,
      -0.033611953258514404,
      -0.02209141105413437,
      0.0568838007748127,
      -0.013337935321033001,
      0.03831534460186958,
      -0.037368666380643845,
      0.012018277309834957,
      -0.06199299916625023,
      0.036444041877985,
      0.014943668618798256,
      0.03713804855942726,
      -0.04116036742925644,
      -0.017727665603160858,
      -0.03280205652117729,
      0.03197261318564415,
      -0.03724808990955353,
      -0.04049791768193245,
      0.008895331993699074,
      -0.04978115111589432,
      -0.014139086939394474,
      0.04914482310414314,
      0.08129449933767319,
      0.049025919288396835,
      -0.024021560326218605,
      -0.02681346796452999,
      0.05829270929098129,
      -0.0372835174202919,
      -0.01876523718237877,
      0.04763798043131828,
      0.023741159588098526,
      -0.02973020449280739,
      0.0025848273653537035,
      0.010046139359474182,
      0.040578752756118774,
      0.10628224909305573,
      0.01743355207145214,
      -0.031044483184814453,
      0.02503415383398533,
      0.013393952511250973,
      0.04751266539096832,
      -0.0723833218216896,
      -0.013939540833234787,
      -0.017226722091436386,
      0.04282548278570175,
      -0.029591623693704605,
      0.004128154367208481,
      -0.017159437760710716,
      0.013504721224308014,
      0.01562570594251156,
      -0.020563412457704544,
      0.06235424801707268,
      0.003212621668353677,
      0.03111170418560505,
      -0.00977307464927435,
      0.08310674130916595,
      0.006548827979713678,
      -0.05323187634348869,
      -0.04965516924858093,
      0.04462803900241852,
      0.021297860890626907,
      0.10633872449398041
    ],
    [
      0.050177231431007385,
      0.027615835890173912,
      -0.06252264976501465,
      0.06286093592643738,
      -0.0981978178024292,
      -0.020388808101415634,
      0.07092228531837463,
      -0.055813077837228775,
      0.13211092352867126,
      -0.07799071818590164,
      0.002463848330080509,
      -0.11484548449516296,
      -0.021663352847099304,
      -0.07830686867237091,
      0.0714908316731453,
      -0.012945191003382206,
      -0.005281426943838596,
      0.02478276938199997,
      0.028110062703490257,
      -0.058467380702495575,
      -0.01708459109067917,
      0.016888009384274483,
      0.026675179600715637,
      0.01743176579475403,
      0.04693335294723511,
      -0.07830927520990372,
      -0.03527291864156723,
      -0.016310393810272217,
      -0.03984355553984642,
      -0.028846126049757004,
      -0.03362428396940231,
      0.0470302440226078,
      -0.024265049025416374,
      0.14898335933685303,
      -0.038207653909921646,
      -0.027387216687202454,
      0.007553129456937313,
      0.01249695848673582,
      0.00963563285768032,
      0.005959128029644489,
      -0.04623110964894295,
      -0.01282330509275198,
      0.048267170786857605,
      0.08616947382688522,
      -0.013288585469126701,
      0.00375840300694108,
      -0.03531118482351303,
      0.03315974399447441,
      0.013564873486757278,
      0.012712507508695126,
      0.022837229073047638,
      0.04061122611165047,
      0.04476959630846977,
      0.02119113691151142,
      -0.025508681312203407,
      -0.07201576232910156,
      0.05258249491453171,
      -0.03174861893057823,
      -0.02443569339811802,
      0.012599073350429535,
      -0.06340047717094421,
      -0.06923628598451614,
      -0.01776079088449478,
      -0.019173892214894295,
      -0.026293722912669182,
      -0.03845217451453209,
      -0.07039205729961395,
      -0.002879614010453224,
      -0.040837209671735764,
      0.05309680476784706,
      0.026101186871528625,
      0.01071995496749878,
      0.05156248062849045,
      0.005788060370832682,
      -0.0650133341550827,
      0.0009141159825958312,
      -0.05251968279480934,
      -0.03716101869940758,
      0.14105607569217682,
      0.009602535516023636,
      -0.10242195427417755,
      -0.06529198586940765,
      -0.044996269047260284,
      -0.011300596408545971,
      -0.050496336072683334,
      0.05606546998023987,
      -0.0031344301532953978,
      -0.03373969718813896,
      -0.042264360934495926,
      0.0016509294509887695,
      -0.005171481519937515,
      -0.04058508202433586,
      0.015075206756591797,
      0.05779683217406273,
      -0.03035075217485428,
      0.11131099611520767,
      0.0013011492555961013,
      -0.017423143610358238,
      0.06238773837685585,
      0.031274858862161636,
      -0.029979845508933067,
      -0.03190464898943901,
      -0.003982994705438614,
      -0.005260880570858717,
      -0.016704043373465538,
      0.015523238107562065,
      -0.06350332498550415,
      0.05051971599459648,
      -0.0404231958091259,
      -0.04002811014652252,
      0.033862944692373276,
      -0.06212930008769035,
      -0.017998909577727318,
      0.0017755958251655102,
      0.014177286997437477,
      0.0049352883361279964,
      -0.02098647691309452,
      0.0966930240392685,
      -0.0009476927807554603,
      0.025567542761564255,
      0.048762038350105286,
      -0.021800916641950607,
      0.014380051754415035,
      -0.0012628426775336266,
      -0.017593828961253166,
      0.008612793870270252,
      -0.014009951613843441,
      0.020870931446552277,
      0.0005191602394916117,
      0.03064083307981491,
      0.010071922093629837,
      0.034005120396614075,
      -0.029263021424412727,
      0.03691258653998375,
      -0.07822610437870026,
      0.03584553301334381,
      0.012130610644817352,
      -0.008538465946912766,
      0.005981110036373138,
      0.020154470577836037,
      -0.021146444603800774,
      -0.04224946349859238,
      -0.020061470568180084,
      -0.02329098805785179,
      -0.0012849344639107585,
      -0.01744009368121624,
      0.01308104395866394,
      0.06961143016815186,
      -0.012646950781345367,
      0.06628251075744629,
      0.03545759618282318,
      -0.0504910983145237,
      0.06460926681756973,
      -0.03266202658414841,
      -0.027388231828808784,
      0.027347605675458908,
      -0.05437368527054787,
      0.005285939667373896,
      -0.03484562411904335,
      0.05270567908883095,
      -0.05128013342618942,
      0.03998102247714996,
      0.029998013749718666,
      0.016491414979100227,
      -0.0010505319805815816,
      0.021153340116143227,
      0.058379221707582474,
      -0.002108840737491846,
      0.05207217484712601,
      -0.01739203929901123,
      -0.022680917754769325,
      -0.008613486774265766,
      0.0051900302059948444,
      -0.022750668227672577,
      0.02855219505727291,
      0.02699882723391056,
      0.005806536413729191,
      0.05714917927980423,
      0.021124819293618202,
      -0.04781459644436836,
      0.08005810528993607,
      0.051075149327516556,
      0.055631235241889954,
      0.05361533164978027,
      -0.053044065833091736,
      0.025445498526096344,
      -0.0963999330997467,
      -0.006214653607457876,
      -0.018684322014451027,
      0.0027535329572856426,
      -0.06372754275798798,
      0.01625184714794159,
      0.01241910457611084,
      0.016190042719244957,
      0.0031573129817843437,
      -0.023991115391254425,
      0.0209673959761858,
      -0.07127132266759872,
      0.09414327889680862,
      -0.001697350642643869,
      0.0047886427491903305,
      0.007617837283760309,
      0.02599119208753109,
      0.03946588188409805,
      -0.07831083983182907,
      0.019958531484007835,
      0.04414474591612816,
      -0.003251448506489396,
      0.06443028897047043,
      -0.0450001023709774,
      -0.024205325171351433,
      -0.0021152778062969446,
      0.026971321552991867,
      0.036983177065849304,
      0.10634171217679977,
      -0.011479069478809834,
      -0.03396853432059288,
      -0.039113208651542664,
      0.05865993723273277,
      0.06669947504997253,
      0.04353964328765869,
      -0.08823063969612122,
      -0.025363311171531677,
      0.03414229676127434,
      -0.04647983983159065,
      -0.009108969941735268,
      -0.04666293039917946,
      -0.14774562418460846,
      0.06647780537605286,
      0.02309081330895424,
      0.08853870630264282,
      -0.04812697321176529,
      -0.007867371663451195,
      0.02120785228908062,
      -0.038118958473205566,
      -0.007899397052824497,
      -0.05108420550823212,
      0.07007846981287003,
      -0.042797718197107315,
      -0.07769293338060379,
      0.004602266009896994,
      0.06480860710144043,
      -0.02517891861498356,
      -0.000817290332634002,
      -0.02966684103012085,
      0.013879375532269478,
      0.06429043412208557,
      0.028659747913479805,
      0.0019995286129415035,
      -0.02570919319987297,
      0.017472945153713226,
      -0.05161653086543083,
      0.041683394461870193,
      0.02222132496535778,
      -0.015125749632716179,
      -0.02731890045106411,
      0.03263774886727333,
      -0.005265063140541315,
      -0.013290674425661564,
      0.025631790980696678,
      0.050728533416986465,
      0.02559640444815159,
      0.007474388461560011,
      0.02517790161073208,
      0.003479241393506527,
      -0.042918235063552856,
      -0.04812488704919815,
      -0.03847984969615936,
      -0.1274011880159378,
      -0.14735402166843414,
      0.017370570451021194,
      -0.012625599279999733,
      0.0032989054452627897,
      -0.02312437817454338,
      0.002353355288505554,
      0.08645123988389969,
      0.024007946252822876,
      0.00307095842435956,
      0.06294747442007065,
      0.0611053891479969,
      0.06473653763532639,
      -0.03969283029437065,
      0.014854812063276768,
      -0.17057153582572937,
      -0.04106835648417473,
      0.06673745810985565,
      0.044327326118946075,
      -0.0677311047911644,
      -0.048411738127470016,
      0.030151696875691414,
      0.013208934105932713,
      0.001322781783528626,
      -0.05580056458711624,
      -0.008977960795164108,
      -0.008897009305655956,
      0.07907132804393768,
      0.006228911690413952,
      0.015181057155132294,
      0.06278865784406662,
      -0.11801891028881073,
      0.07041528820991516,
      0.0013262092834338546,
      0.04881003499031067,
      -0.0554170124232769,
      -0.05013325437903404,
      0.04204297065734863,
      0.035670459270477295,
      0.02036464773118496,
      -0.0011343990918248892,
      -0.003885079175233841,
      0.025590578094124794,
      0.011100209318101406,
      -0.009640469215810299,
      -0.06384613364934921,
      -0.07774060219526291,
      0.008539222180843353,
      0.043581634759902954,
      0.0007227079477161169,
      -0.004090805072337389,
      -0.06519759446382523,
      0.02755606546998024,
      0.025866784155368805,
      0.039363276213407516,
      0.03680415078997612,
      0.047953374683856964,
      0.020615749061107635,
      0.04746184125542641,
      -0.035731978714466095,
      0.002798383589833975,
      0.0278096254914999,
      -0.0086628133431077,
      0.009090675041079521,
      0.03150730952620506,
      0.014074888080358505,
      -0.08390682935714722,
      0.038521211594343185,
      -0.07520107924938202,
      0.02098843641579151,
      -0.00793419685214758,
      0.011913521215319633,
      0.05713893845677376,
      0.0070512318052351475,
      0.03392893075942993,
      0.017664799466729164,
      0.00865019578486681,
      0.058485954999923706,
      -0.038406066596508026,
      -0.0020291556138545275,
      0.03003638982772827,
      -0.07605335861444473,
      -0.06053038313984871,
      0.008089793846011162,
      0.03194895014166832,
      0.08168534934520721,
      0.032436802983284,
      -0.07873107492923737,
      0.027607526630163193,
      -0.02105136588215828,
      0.03382613882422447,
      -0.010814555920660496,
      -0.015268820337951183,
      0.020536256954073906,
      -0.04228002950549126,
      -0.013137982226908207,
      -0.00962170958518982,
      -0.05160800740122795,
      -0.010690615512430668,
      0.06006842851638794,
      0.014274530112743378,
      -0.020159849897027016,
      0.08171585947275162,
      -0.03613065183162689,
      -0.033225640654563904,
      -0.05374923720955849,
      0.07944308966398239,
      0.008610280230641365,
      -0.0354832224547863,
      0.016158726066350937,
      0.030164124444127083,
      0.06461463123559952,
      0.0129134776070714,
      -0.019949808716773987,
      0.010260290466248989,
      -0.01776921935379505,
      -0.021846290677785873,
      -0.11009805649518967,
      -0.04243222624063492,
      -0.03271344304084778,
      0.010381048545241356,
      -0.049145378172397614,
      -0.02375304512679577,
      -0.08183620125055313,
      0.032357823103666306,
      -0.04759278520941734,
      0.03913232311606407,
      0.04781010374426842,
      0.05927994102239609,
      -0.07259692996740341,
      -0.0022006279323250055,
      -0.05647975578904152,
      -0.0002532632788643241,
      -0.04129185900092125,
      0.008631395176053047,
      -0.003507648827508092,
      0.09168937057256699,
      -0.025416528806090355,
      0.015176797285676003,
      -0.06057490408420563,
      0.02030300721526146,
      -0.05004897713661194,
      -0.033588193356990814,
      -0.012922296300530434,
      -0.026793744415044785,
      0.03325189650058746,
      -0.05552640184760094,
      -0.07419964671134949,
      0.03320644795894623,
      0.031054368242621422,
      -0.017966339364647865,
      0.05575653538107872,
      0.0549338161945343,
      -0.030146362259984016,
      0.05465840548276901,
      -0.04230261221528053,
      -0.01174886990338564,
      -0.030491428449749947,
      -0.03692258149385452,
      0.018703104928135872,
      0.05904453620314598,
      0.023451954126358032,
      -0.030614357441663742,
      -0.0368926040828228,
      -0.03994953632354736,
      -0.02771640196442604,
      -0.020487312227487564,
      0.07808033376932144,
      -0.04933498054742813,
      0.01715167425572872,
      0.027109667658805847,
      -0.016505781561136246,
      0.0038948371075093746,
      -0.05293847620487213,
      0.04526907205581665,
      0.04823668673634529,
      -0.041146859526634216,
      0.05066818371415138,
      -0.032120488584041595,
      0.004029673989862204,
      -0.05110102891921997,
      0.01194589864462614,
      -0.01236006524413824,
      0.009252785705029964,
      0.020026948302984238,
      -0.012408148497343063,
      -0.03479698672890663,
      -0.07675204426050186,
      0.010942034423351288,
      0.03700805455446243,
      0.0342843271791935,
      0.016412310302257538,
      -0.029051082208752632,
      0.023256992921233177,
      -0.03607013076543808,
      -0.060808390378952026,
      0.010404379107058048,
      -0.02064722776412964,
      0.02437819354236126,
      -0.015832018107175827,
      0.003820084035396576,
      -0.0011445449199527502,
      -0.05976876616477966,
      -0.01793162152171135,
      -0.026510488241910934,
      -0.02177697978913784,
      0.039438966661691666,
      -0.051420748233795166,
      0.06594249606132507,
      0.036034051328897476,
      -0.07584752142429352,
      -0.03720562160015106,
      -0.06723961979150772,
      0.007256326265633106,
      -0.02014559507369995,
      -0.00798491109162569,
      -0.08311665058135986,
      -0.06744828075170517,
      -0.029169606044888496,
      -0.008144144900143147,
      -0.09059976041316986,
      -0.028252027928829193,
      0.01261712796986103,
      -0.0621623657643795,
      -0.0679682269692421,
      -0.07077562808990479,
      -0.06120084598660469,
      -0.028214994817972183,
      -0.04637734964489937,
      0.042098164558410645,
      -0.0075704241171479225,
      0.08345978707075119,
      -0.0009854246163740754,
      -0.009960103780031204,
      0.024519486352801323
    ],
    [
      -0.023518899455666542,
      0.012921106070280075,
      -0.009578888304531574,
      -0.02207140251994133,
      -0.008543547242879868,
      0.10918327420949936,
      0.034472398459911346,
      -0.05884970352053642,
      9.652492735767737e-05,
      -0.008393854834139347,
      0.030114632099866867,
      0.00434537697583437,
      0.010540585964918137,
      0.02130817621946335,
      -0.0025459888856858015,
      -0.09156710654497147,
      0.02207816205918789,
      -0.005516020115464926,
      0.04177556186914444,
      0.020359260961413383,
      -0.08263210207223892,
      0.076499804854393,
      0.0011635238770395517,
      -0.06992318481206894,
      0.041022200137376785,
      -0.022497639060020447,
      -0.07757130265235901,
      -0.015376987867057323,
      -0.026162544265389442,
      -0.047000110149383545,
      -0.03878689929842949,
      -0.0003548757522366941,
      0.055868182331323624,
      -0.004498832859098911,
      -0.05118001997470856,
      0.07248950749635696,
      -0.08476313203573227,
      0.05497533455491066,
      0.019121726974844933,
      -0.05924325808882713,
      -0.0001909285201691091,
      0.03119131550192833,
      0.03826691582798958,
      -0.06271228194236755,
      0.022676028311252594,
      -0.04409182816743851,
      -0.010407276451587677,
      0.015308686532080173,
      -0.018318509683012962,
      -0.0026348710525780916,
      -0.09647437185049057,
      0.018887849524617195,
      -0.031496670097112656,
      0.004041858483105898,
      0.0008404611726291478,
      1.1885671483469196e-05,
      -0.08937394618988037,
      -0.01979781687259674,
      0.023364948108792305,
      -0.04736064374446869,
      0.02501031942665577,
      0.011270623654127121,
      0.008160388097167015,
      -0.06139574572443962,
      0.0006888162461109459,
      0.002899444429203868,
      -0.00031980453059077263,
      -0.04214804247021675,
      0.04642210900783539,
      0.010762969963252544,
      -0.06534289568662643,
      0.016957789659500122,
      -0.027589373290538788,
      0.005723685026168823,
      -0.023369478061795235,
      0.09917932003736496,
      0.04186655953526497,
      -0.09887690097093582,
      0.017981600016355515,
      -0.03450788930058479,
      0.023924872279167175,
      0.030668454244732857,
      -0.07202402502298355,
      0.029657023027539253,
      0.06179681792855263,
      -0.03458349406719208,
      0.00426785321906209,
      -0.04900309070944786,
      0.028817296028137207,
      0.054738786071538925,
      -0.016116786748170853,
      0.01423096377402544,
      0.05932091921567917,
      0.060823824256658554,
      -0.07284367829561234,
      0.04464183747768402,
      0.005706108640879393,
      0.013953838497400284,
      0.01685742475092411,
      -0.02168799377977848,
      -0.015034948475658894,
      0.011192312464118004,
      0.024614257737994194,
      0.0022053311113268137,
      0.03860797733068466,
      -0.05440438911318779,
      -0.050412122160196304,
      0.006454035639762878,
      -0.059107955545186996,
      -0.05507835000753403,
      -0.010454012081027031,
      0.008317438885569572,
      0.04317526891827583,
      -0.03429898992180824,
      0.013954194262623787,
      -0.05437806248664856,
      0.0669357180595398,
      -0.012606414034962654,
      0.06041942164301872,
      0.02010413073003292,
      0.0007245520828291774,
      -0.12064919620752335,
      0.05627307668328285,
      -0.0750812515616417,
      -0.03451943024992943,
      0.025787658989429474,
      0.015617763623595238,
      0.026598816737532616,
      0.047377653419971466,
      0.05506438761949539,
      0.03455325588583946,
      -0.056067679077386856,
      -0.09665971249341965,
      -0.06662547588348389,
      0.11001547425985336,
      0.0008511616615578532,
      -0.030724816024303436,
      -0.03002128377556801,
      0.010792729444801807,
      0.032867301255464554,
      0.011559871025383472,
      0.011729598976671696,
      -0.04803694412112236,
      -0.021347157657146454,
      0.025297174230217934,
      -0.03658771887421608,
      0.11980139464139938,
      0.008912577293813229,
      -0.013737184926867485,
      -0.023433180525898933,
      0.035318560898303986,
      -0.03569598123431206,
      0.057315219193696976,
      0.0022958647459745407,
      -0.018129292875528336,
      -0.017935002222657204,
      0.06464085727930069,
      0.04212494567036629,
      -0.020131664350628853,
      -0.0003744630375877023,
      0.08573537319898605,
      -0.035342633724212646,
      -0.01447359099984169,
      0.06472306698560715,
      -0.03237282857298851,
      -0.08060750365257263,
      -0.0020841555669903755,
      -0.028791632503271103,
      0.009473242796957493,
      -0.009830434806644917,
      0.020579012110829353,
      -0.019484728574752808,
      0.0971246063709259,
      -0.03373035416007042,
      0.07055311650037766,
      -0.013172188773751259,
      0.0037078072782605886,
      -0.011259486898779869,
      -0.02358114905655384,
      0.06801217049360275,
      -0.10439255833625793,
      -0.030102375894784927,
      0.03826066851615906,
      0.014901027083396912,
      0.016135435551404953,
      0.015371452085673809,
      0.025919131934642792,
      -0.015685414895415306,
      -0.008443796075880527,
      0.007798526901751757,
      0.02933240495622158,
      0.0036478438414633274,
      -0.03793998435139656,
      -0.01872461475431919,
      0.009870560839772224,
      0.01641886495053768,
      0.019200218841433525,
      0.02516040951013565,
      -0.010848265141248703,
      0.056003451347351074,
      0.06086679548025131,
      0.03304194658994675,
      0.01733558624982834,
      -0.05375486612319946,
      -0.014661583118140697,
      -0.01019553653895855,
      0.028916476294398308,
      0.023061327636241913,
      0.04006541892886162,
      -0.00706844124943018,
      -0.011033870279788971,
      0.029140865430235863,
      -0.03193417564034462,
      0.11643420159816742,
      0.08573195338249207,
      0.055405013263225555,
      -0.025423435494303703,
      0.05070224404335022,
      -0.019592052325606346,
      0.07448457926511765,
      0.012936804443597794,
      0.07961201667785645,
      0.02349218726158142,
      -0.015237555839121342,
      -0.04537060856819153,
      0.07838078588247299,
      -0.005378204397857189,
      -0.014432682655751705,
      0.0010416440200060606,
      0.03621821478009224,
      0.017056336626410484,
      -0.026044901460409164,
      -0.017984699457883835,
      0.009683656506240368,
      -0.0008935783989727497,
      0.008179382421076298,
      -0.06676224619150162,
      0.05558483302593231,
      0.019599290564656258,
      0.04431352764368057,
      0.0823005959391594,
      0.030518047511577606,
      -0.00027191886329092085,
      0.020999833941459656,
      0.004250179044902325,
      0.027464598417282104,
      -0.07025197148323059,
      0.0648714229464531,
      -0.08787000179290771,
      -0.0053594596683979034,
      0.01645340584218502,
      0.06141025945544243,
      0.007267551030963659,
      -0.010357326827943325,
      0.0071222553960978985,
      0.026436273008584976,
      0.01517658494412899,
      0.056699324399232864,
      -0.024042772129178047,
      -0.04418911039829254,
      -0.013743630610406399,
      0.07346703857183456,
      0.04867829009890556,
      0.07092569023370743,
      -0.007089840713888407,
      -0.004430209752172232,
      -0.022217532619833946,
      -0.012162474915385246,
      -0.021123360842466354,
      -0.019589876756072044,
      -0.05482250824570656,
      0.03859882056713104,
      -0.053027912974357605,
      0.02721555531024933,
      0.039938196539878845,
      0.028225407004356384,
      -0.024052143096923828,
      0.027032947167754173,
      0.02660730481147766,
      0.006357274018228054,
      -0.04974541813135147,
      -0.039872366935014725,
      0.021522361785173416,
      0.09033849835395813,
      -0.02235081046819687,
      0.009778055362403393,
      0.02859724685549736,
      -0.002773058135062456,
      -0.05091837793588638,
      0.012835622765123844,
      -0.03983921557664871,
      0.018456339836120605,
      -0.027044154703617096,
      0.049762848764657974,
      0.015869179740548134,
      0.01185326837003231,
      0.1049036830663681,
      0.03245386481285095,
      0.031521376222372055,
      -0.007245086599141359,
      0.0029363632202148438,
      0.02999819815158844,
      0.06880448013544083,
      0.019392866641283035,
      -0.022461600601673126,
      0.002661254955455661,
      0.008568836376070976,
      0.04806143790483475,
      0.01296278927475214,
      -0.010638752952218056,
      -0.035965047776699066,
      0.10143305361270905,
      0.020644506439566612,
      0.012125465087592602,
      -0.005298452917486429,
      -0.0288779865950346,
      -0.05157029628753662,
      -0.0423818975687027,
      0.016018187627196312,
      -0.0016818245640024543,
      0.027377840131521225,
      -0.04757415130734444,
      0.013414912857115269,
      -0.019182587042450905,
      0.015104192309081554,
      0.05578165873885155,
      0.017813969403505325,
      0.00027418334502726793,
      -0.041520651429891586,
      -0.039010774344205856,
      0.09494397789239883,
      -0.00416418444365263,
      -0.05835315212607384,
      -0.07056766003370285,
      -0.049373809248209,
      0.04723658040165901,
      0.021273870021104813,
      -0.018135618418455124,
      -0.07572630792856216,
      -0.03401556983590126,
      0.00013662100536748767,
      0.014261663891375065,
      -0.08130213618278503,
      0.03174369037151337,
      -0.003815280506387353,
      -0.013670519925653934,
      -0.06125446408987045,
      0.046605125069618225,
      -0.08562719076871872,
      0.05868082493543625,
      0.07460902631282806,
      0.0025731483474373817,
      -0.03223457559943199,
      0.023953231051564217,
      -0.019907640293240547,
      -0.018034694716334343,
      0.06592478603124619,
      0.006791224237531424,
      0.061345260590314865,
      0.07780779898166656,
      0.01004144735634327,
      -0.01070336252450943,
      -0.004613423720002174,
      -0.05137484893202782,
      0.03498036041855812,
      -0.07677904516458511,
      0.017587563022971153,
      -0.0415426567196846,
      -0.019674764946103096,
      0.025076236575841904,
      -0.02688533440232277,
      0.08211266249418259,
      -0.03143759444355965,
      -0.10207241028547287,
      -0.018738452345132828,
      0.03581193462014198,
      0.006324000656604767,
      0.04416142404079437,
      0.04014628380537033,
      -0.03608877584338188,
      0.01788712851703167,
      0.060439128428697586,
      0.017078256234526634,
      0.030557606369256973,
      -0.00014574835950043052,
      -0.03620171919465065,
      -0.02123269997537136,
      -0.07238147407770157,
      -0.01453451532870531,
      0.06317797303199768,
      -0.044151149690151215,
      -0.08769897371530533,
      0.04372827336192131,
      0.006245271768420935,
      0.03686610981822014,
      -0.0429871529340744,
      0.04099661111831665,
      -0.009016130119562149,
      0.059163399040699005,
      -0.049066174775362015,
      -0.06407509744167328,
      -0.05202283337712288,
      -0.05837428197264671,
      -0.015949754044413567,
      0.009844690561294556,
      -0.14161987602710724,
      0.054825689643621445,
      -0.0003445188340265304,
      -0.04197753965854645,
      0.06678168475627899,
      0.08276353031396866,
      -0.0017669342923909426,
      -0.09705289453268051,
      0.03489047661423683,
      -0.02980923093855381,
      0.007362572010606527,
      -0.005455926060676575,
      -0.02821059711277485,
      -0.011993114836513996,
      0.023551667109131813,
      0.03507910296320915,
      0.012203685939311981,
      0.05703792721033096,
      0.07564298808574677,
      -0.003986465744674206,
      0.02227381244301796,
      0.027510475367307663,
      -0.0011875324416905642,
      -0.009222042746841908,
      0.06199304014444351,
      0.03176393359899521,
      -0.004457113333046436,
      0.02375517599284649,
      -0.06004879251122475,
      0.003319809678941965,
      0.02383069507777691,
      0.057831767946481705,
      -0.012946615926921368,
      0.0342625230550766,
      -0.06547509133815765,
      -0.05127933993935585,
      -0.04507474601268768,
      -0.11364247649908066,
      0.020876387134194374,
      0.014936964958906174,
      0.06064692512154579,
      0.015325197950005531,
      0.03785349056124687,
      0.038222432136535645,
      -0.038920808583498,
      -0.03258208557963371,
      -0.0918353721499443,
      0.018598441034555435,
      0.049061164259910583,
      0.011081273667514324,
      0.035470083355903625,
      0.0764327272772789,
      0.03815946355462074,
      -0.02152152545750141,
      -0.08649078756570816,
      0.02024034969508648,
      -0.03425163775682449,
      -0.045637212693691254,
      -0.036767035722732544,
      -0.04910491034388542,
      0.002449055900797248,
      0.0791560634970665,
      0.06737516075372696,
      0.07935090363025665,
      0.031319115310907364,
      -0.008671597577631474,
      0.021051693707704544,
      0.07518872618675232,
      0.021813997998833656,
      0.00885769259184599,
      -0.03887030482292175,
      -0.01914580911397934,
      -0.014528816565871239,
      0.030392015352845192,
      0.023298511281609535,
      0.0026774508878588676,
      -0.01699749566614628,
      0.06941785663366318,
      0.013611945323646069,
      0.03488779440522194,
      -0.026876108720898628,
      -0.04739062488079071,
      0.07619263976812363,
      0.059509292244911194,
      0.01485332753509283,
      0.06295760720968246,
      0.02089160867035389,
      -0.036106742918491364,
      -0.03293345868587494,
      -0.01002090610563755,
      -0.022202683612704277,
      0.02230619452893734,
      -0.061570774763822556,
      -0.012984600849449635,
      0.005201379302889109,
      -0.018275495618581772,
      0.022565364837646484,
      0.005366052500903606
    ],
    [
      0.02134968340396881,
      -0.02479170449078083,
      0.02446923404932022,
      -0.0034676657523959875,
      0.021426742896437645,
      -0.00409567030146718,
      0.028617333620786667,
      -0.0803607851266861,
      -0.04731393977999687,
      -0.005017481278628111,
      -0.046214696019887924,
      0.011986141093075275,
      0.029730401933193207,
      -0.04549470543861389,
      -0.04787461459636688,
      -0.04776238277554512,
      -0.05380504950881004,
      -0.1149739995598793,
      -0.037288591265678406,
      -0.021360032260417938,
      0.0069663142785429955,
      0.09204360842704773,
      -0.011863581836223602,
      -0.043908435851335526,
      -0.0280019361525774,
      -0.07300779968500137,
      -0.025823924690485,
      0.0318155474960804,
      0.05557825788855553,
      0.055683307349681854,
      0.07775521278381348,
      -0.019751008599996567,
      -0.04689827188849449,
      0.012931639328598976,
      -0.011824133805930614,
      -0.011406769044697285,
      0.045268334448337555,
      0.03668992966413498,
      0.010762868449091911,
      0.004571742843836546,
      -0.03107195906341076,
      0.037065982818603516,
      0.006657199934124947,
      -0.08551450073719025,
      -0.043709635734558105,
      0.07053518295288086,
      0.06092650443315506,
      0.011616521514952183,
      -0.039636094123125076,
      -0.04984588176012039,
      -0.004202460870146751,
      0.10694445669651031,
      -0.03294201195240021,
      0.0013405530480667949,
      0.018345559015870094,
      -0.013690028339624405,
      0.03284565359354019,
      0.003486812114715576,
      -0.040348947048187256,
      -0.028437117114663124,
      -0.06890255212783813,
      -0.06200016662478447,
      0.024965180084109306,
      -0.006581935100257397,
      0.04782217741012573,
      -0.09009171277284622,
      -0.03233921527862549,
      -0.01780504174530506,
      0.017457274720072746,
      -0.00028260378167033195,
      0.04771970957517624,
      -0.028917495161294937,
      -0.13594207167625427,
      0.00825593899935484,
      0.01539924181997776,
      0.02231791242957115,
      -0.0033913101069629192,
      -0.002458884147927165,
      -0.008442149497568607,
      -0.07704722881317139,
      0.04950331524014473,
      -0.08471056818962097,
      -0.023300087079405785,
      0.07005045562982559,
      -0.010934525169432163,
      -0.09766530990600586,
      0.037882767617702484,
      0.07597203552722931,
      0.04004013538360596,
      -0.0019043516367673874,
      0.06779272109270096,
      -0.07651811838150024,
      -0.019757399335503578,
      -0.04394024610519409,
      -0.08922149240970612,
      0.04846001788973808,
      -0.03837064653635025,
      -0.025302954018115997,
      -0.044009093195199966,
      -0.00879169162362814,
      -0.0736575797200203,
      -0.006259309593588114,
      0.020124776288866997,
      -0.05068713799118996,
      0.015019610524177551,
      -0.05939270928502083,
      0.01831909827888012,
      -0.10180589556694031,
      -0.04992257431149483,
      -0.0516807958483696,
      0.018976757302880287,
      0.014554410241544247,
      0.01721031405031681,
      0.04730837419629097,
      0.024985887110233307,
      -0.07678443938493729,
      -0.04227973148226738,
      -0.023800082504749298,
      -0.017306426540017128,
      -0.0026465649716556072,
      -0.04551652818918228,
      -0.0587528832256794,
      -0.039035674184560776,
      0.011061805300414562,
      -0.04143958538770676,
      -0.014201314188539982,
      0.0611092709004879,
      -0.04869041591882706,
      -0.013832739554345608,
      -0.07932084053754807,
      -0.027750929817557335,
      -0.02479185163974762,
      0.0034905963111668825,
      0.022038403898477554,
      -0.009929027408361435,
      -0.05911951884627342,
      -0.013637964613735676,
      -0.07062950730323792,
      0.014535014517605305,
      0.007015063893049955,
      -0.024226339533925056,
      -0.037315040826797485,
      0.04544574394822121,
      0.04808710888028145,
      0.024105720221996307,
      0.1107829287648201,
      -0.05887969210743904,
      -0.055483486503362656,
      -0.018898306414484978,
      0.007954376749694347,
      0.05892796441912651,
      -0.03437814116477966,
      -0.07213074713945389,
      0.026331519708037376,
      0.01976262405514717,
      0.045448705554008484,
      -0.01912407949566841,
      -0.012852013111114502,
      -0.11182265728712082,
      -0.013428951613605022,
      0.0010436064330860972,
      -0.05286353826522827,
      -0.027090393006801605,
      0.03563566505908966,
      -0.011913483031094074,
      0.006236328277736902,
      -0.005972068291157484,
      -0.03115980699658394,
      0.05850566551089287,
      0.041566021740436554,
      -0.05964983254671097,
      0.03469134122133255,
      -0.06085033342242241,
      -0.03289065882563591,
      -0.0037742226850241423,
      0.0246919933706522,
      0.03255535289645195,
      0.012786987237632275,
      0.06506022810935974,
      -0.04840056970715523,
      -0.0129352742806077,
      -0.032722923904657364,
      0.03068407066166401,
      0.06681947410106659,
      -0.028217768296599388,
      -0.1318676620721817,
      -0.0597003772854805,
      -0.018738441169261932,
      0.09992526471614838,
      0.030760889872908592,
      -0.09121264517307281,
      0.021082762628793716,
      -0.0019840269815176725,
      -0.07691967487335205,
      -0.02779999002814293,
      0.004704472608864307,
      0.04872420057654381,
      -0.027432844042778015,
      -0.01026302482932806,
      0.015947598963975906,
      -0.016364822164177895,
      0.10804586112499237,
      -0.002455722773447633,
      -0.058140818029642105,
      0.051476046442985535,
      -0.023598380386829376,
      0.0008148862980306149,
      0.002053318079560995,
      0.027908628806471825,
      0.014924067072570324,
      -0.021195612847805023,
      -0.06954722106456757,
      0.02551676332950592,
      0.07125626504421234,
      0.013914261013269424,
      0.03643260896205902,
      0.006181264761835337,
      -0.08175761252641678,
      0.023306258022785187,
      0.03262125700712204,
      0.022234836593270302,
      0.06426992267370224,
      -0.016245009377598763,
      -0.002689990447834134,
      0.021319804713129997,
      0.023332173004746437,
      -0.002345499349758029,
      0.051621876657009125,
      -0.07729962468147278,
      0.06537626683712006,
      0.01184217818081379,
      -0.03726992756128311,
      0.09156151860952377,
      0.07140974700450897,
      -0.06139660254120827,
      -0.015542401000857353,
      0.001197002362459898,
      -0.006989510729908943,
      -0.06352797150611877,
      -0.02899964153766632,
      -0.00889267772436142,
      -0.022667797282338142,
      0.02117137238383293,
      -0.06992261111736298,
      -0.010129407048225403,
      -0.009560719132423401,
      -0.061080459505319595,
      0.028901206329464912,
      -0.006416287273168564,
      -0.00823061354458332,
      0.064072385430336,
      -0.0023773773573338985,
      0.046317487955093384,
      -0.05059264600276947,
      0.02488507330417633,
      -0.06359940022230148,
      0.03932638466358185,
      -0.00010746868065325543,
      0.004968785215169191,
      -0.03198592737317085,
      0.04171770066022873,
      0.03599964827299118,
      -0.06735721230506897,
      0.07524840533733368,
      -0.04569593816995621,
      -0.04934633523225784,
      0.0815766230225563,
      0.004217140376567841,
      -0.007437148597091436,
      0.02190251275897026,
      0.05830087885260582,
      -0.03736897185444832,
      0.07857849448919296,
      0.018732644617557526,
      0.013253808952867985,
      0.0263677965849638,
      -0.016464024782180786,
      -0.05109110474586487,
      0.00868293084204197,
      0.029891183599829674,
      0.024154385551810265,
      -0.05645732209086418,
      -0.0848652794957161,
      0.023216070607304573,
      0.004966800566762686,
      -0.019444188103079796,
      -0.01569150760769844,
      -0.047017790377140045,
      -0.06317849457263947,
      0.04096098989248276,
      -0.017223382368683815,
      -0.05275443568825722,
      0.030930813401937485,
      0.010543785989284515,
      -0.06713660061359406,
      0.00783451832830906,
      -0.02885935641825199,
      -0.005232017952948809,
      -0.0781504362821579,
      0.01273912750184536,
      0.05907368287444115,
      -0.03475920855998993,
      0.00647348677739501,
      0.03821977227926254,
      -0.0030651779379695654,
      -0.004020379390567541,
      -0.07063793390989304,
      -0.0483255535364151,
      0.09598895907402039,
      0.017035644501447678,
      0.009119938127696514,
      0.02185128442943096,
      0.06427358835935593,
      0.026971517130732536,
      -0.07784447818994522,
      0.03374750539660454,
      -0.0330314077436924,
      0.051203906536102295,
      -0.03286160156130791,
      -0.054671332240104675,
      -0.006165150087326765,
      0.09602615237236023,
      0.011949235573410988,
      0.06854318827390671,
      0.03310149535536766,
      0.0006428788183256984,
      -0.0438733845949173,
      0.07501240074634552,
      -0.006901630666106939,
      -0.015415710397064686,
      -0.004383767023682594,
      0.03046586737036705,
      0.01868380233645439,
      0.03216377645730972,
      -0.06463619321584702,
      -0.01973555237054825,
      -0.04970310628414154,
      -0.03362894058227539,
      0.020280269905924797,
      -0.012096263468265533,
      -0.037631530314683914,
      -0.033568769693374634,
      -0.047347161918878555,
      0.0479256846010685,
      -0.05573263764381409,
      -0.06886260211467743,
      0.05196642503142357,
      0.020997807383537292,
      0.03740762919187546,
      -0.039051756262779236,
      0.028126263990998268,
      0.010373467579483986,
      -0.03737964481115341,
      0.01900925301015377,
      -0.055206939578056335,
      -0.03604743629693985,
      -0.0040938579477369785,
      -0.013921410776674747,
      0.02531130611896515,
      -0.01423018891364336,
      -0.026962880045175552,
      -0.05784732475876808,
      -0.015155876986682415,
      0.0020862107630819082,
      -0.016862140968441963,
      -0.048128239810466766,
      0.059132594615221024,
      -0.010347520932555199,
      0.021697882562875748,
      -0.009489542804658413,
      0.021654028445482254,
      -0.006651052739471197,
      0.033463940024375916,
      -0.02113809995353222,
      0.010163270868360996,
      -0.02592410519719124,
      -0.005225347820669413,
      -0.036229025572538376,
      -0.026577576994895935,
      0.005603415425866842,
      0.017299093306064606,
      0.01425471343100071,
      0.0029414286836981773,
      -0.011333116330206394,
      0.03494108468294144,
      0.016829093918204308,
      -0.022439053282141685,
      -0.058835409581661224,
      0.027041835710406303,
      -0.009321319870650768,
      -0.05733354017138481,
      0.0006839459529146552,
      0.04827934876084328,
      -0.003999229054898024,
      0.02340526133775711,
      0.04388756677508354,
      -0.0909087136387825,
      0.046312201768159866,
      -0.10738644003868103,
      0.055059775710105896,
      -0.001693700673058629,
      -0.04761940985918045,
      0.013538118451833725,
      0.0663580447435379,
      -0.022979633882641792,
      -0.07920798659324646,
      -0.0515398345887661,
      0.054481390863657,
      0.025767341256141663,
      0.05300839617848396,
      0.05322638154029846,
      0.030052244663238525,
      -0.04913167655467987,
      -0.0866093561053276,
      0.015600129961967468,
      -0.0025973368901759386,
      -0.010629240423440933,
      0.01905456744134426,
      -0.03322821110486984,
      0.022144094109535217,
      -0.06329430639743805,
      0.039566997438669205,
      -0.004140264354646206,
      0.020105039700865746,
      0.019407285377383232,
      0.015000454150140285,
      -0.0005723339272662997,
      0.07664401084184647,
      -0.05361904948949814,
      -0.039620090276002884,
      0.06975743919610977,
      -0.020271675661206245,
      -0.014771096408367157,
      0.035890351980924606,
      -0.02847359888255596,
      0.07630213350057602,
      -0.020739786326885223,
      -0.04502216354012489,
      -0.02202911302447319,
      0.08093900233507156,
      -0.020558487623929977,
      0.075123630464077,
      -0.01698879711329937,
      -0.08471635729074478,
      -0.02955716662108898,
      0.025607861578464508,
      0.06387180089950562,
      0.009945495054125786,
      -0.010560750961303711,
      -0.060318488627672195,
      -0.05911260098218918,
      0.09921929985284805,
      -0.06917225569486618,
      -0.08377369493246078,
      0.0020199723076075315,
      0.029383700340986252,
      -0.052442483603954315,
      -0.024386713281273842,
      -0.09089269489049911,
      0.0025542844086885452,
      -0.013368729501962662,
      -0.04117819666862488,
      0.0048504783771932125,
      -0.0043799374252557755,
      -0.01816130429506302,
      0.100802481174469,
      0.05951268598437309,
      -0.035151366144418716,
      -0.015342526137828827,
      0.06708285957574844,
      -0.06433362513780594,
      0.006949304137378931,
      -0.015423128381371498,
      -0.014045899733901024,
      -0.05784737691283226,
      -0.04406328499317169,
      0.12706226110458374,
      -0.04475157707929611,
      0.028657468035817146,
      -0.05886054411530495,
      0.09442321211099625,
      0.07005918025970459,
      -0.05965660884976387,
      0.05194002389907837,
      0.043240729719400406,
      0.024777280166745186,
      0.009004354476928711,
      -0.0012080696178600192,
      -0.019832933321595192,
      0.04433610290288925,
      -0.0933942049741745,
      0.02435324713587761,
      0.02366318367421627,
      -0.05109495669603348,
      -0.03142309933900833,
      -0.017065392807126045,
      -0.024318071082234383,
      -0.039793699979782104,
      0.0392468124628067,
      -0.08379490673542023,
      0.0009604209335520864,
      0.026937656104564667,
      0.05708342790603638
    ],
    [
      -0.024112867191433907,
      0.025121647864580154,
      -0.030412115156650543,
      -0.037620265036821365,
      0.07263733446598053,
      0.0031674960628151894,
      -0.009581197053194046,
      0.0028321107383817434,
      0.039991170167922974,
      0.027030861005187035,
      -0.05154876038432121,
      -0.051668986678123474,
      0.005822448059916496,
      -0.00514184171333909,
      0.01659253053367138,
      0.06441763043403625,
      0.02080238051712513,
      -0.009873058646917343,
      0.018917551264166832,
      0.056295622140169144,
      0.019221436232328415,
      -0.06442473828792572,
      0.10179006308317184,
      0.03196125850081444,
      0.07505357265472412,
      0.06535385549068451,
      -0.0021189514081925154,
      -0.01701395958662033,
      0.0074709574691951275,
      -0.02486952394247055,
      -0.035550329834222794,
      0.030513226985931396,
      -0.06498344987630844,
      0.08213871717453003,
      0.030684340745210648,
      -0.0071385642513632774,
      0.011153804138302803,
      -0.00079219916369766,
      0.017667358741164207,
      0.030170859768986702,
      -0.021708084270358086,
      0.0528976134955883,
      -0.014885740354657173,
      -0.008059227839112282,
      0.028485020622611046,
      0.10472579300403595,
      0.03266339376568794,
      -0.021692616865038872,
      -0.0036373494658619165,
      -0.013959726318717003,
      -0.12795214354991913,
      -0.058659400790929794,
      0.04616541042923927,
      -0.004305724054574966,
      -0.047150034457445145,
      -0.07627328485250473,
      0.05820944160223007,
      -0.017585961148142815,
      0.02139473706483841,
      -0.013680563308298588,
      0.002468639751896262,
      -0.032304078340530396,
      -0.012148250825703144,
      0.018695129081606865,
      0.02433866448700428,
      -0.03441043198108673,
      0.0034961639903485775,
      -0.06746529787778854,
      0.0648004412651062,
      0.0017324957298114896,
      0.04847361519932747,
      0.029362019151449203,
      0.03193667158484459,
      -0.006447385530918837,
      -0.015869926661252975,
      -0.03871798515319824,
      -0.06845501065254211,
      0.09854351729154587,
      0.03640228137373924,
      -0.04657074064016342,
      0.0003287970321252942,
      0.000623806961812079,
      -0.04967746138572693,
      0.04520590230822563,
      -0.03818865865468979,
      0.044700149446725845,
      0.058859750628471375,
      0.05854501575231552,
      0.056351304054260254,
      0.09386872500181198,
      0.05365385115146637,
      0.008676429279148579,
      0.0335000641644001,
      0.028865385800600052,
      0.09356331825256348,
      -0.021486425772309303,
      -0.04801081866025925,
      0.04575999081134796,
      0.05759064108133316,
      0.010472951456904411,
      -0.03888250142335892,
      0.05201275646686554,
      0.02180572971701622,
      0.037495844066143036,
      -0.0006407549954019487,
      -0.05214864760637283,
      -0.008523182943463326,
      -0.0010914524318650365,
      0.011939192190766335,
      -0.00494815967977047,
      -0.042325347661972046,
      -0.02329050563275814,
      0.05861438065767288,
      -0.028559168800711632,
      0.05968103185296059,
      0.01269524171948433,
      -0.04832097142934799,
      -0.03687464818358421,
      0.06894551217556,
      -0.010251333005726337,
      -0.049489960074424744,
      0.001297124195843935,
      -0.06827706843614578,
      0.03839812055230141,
      0.00164563930593431,
      0.04385365545749664,
      -0.056416843086481094,
      -0.05606045573949814,
      -0.03597230464220047,
      0.0892966240644455,
      -0.0035535006318241358,
      -0.06554820388555527,
      -0.056359875947237015,
      -0.029007984325289726,
      0.10353588312864304,
      0.018805576488375664,
      0.01867816597223282,
      -0.010472888126969337,
      0.05955183133482933,
      0.01620180532336235,
      0.024591844528913498,
      0.019683891907334328,
      0.051500193774700165,
      -0.0020783108193427324,
      0.020162031054496765,
      -0.06773217767477036,
      0.04625396430492401,
      -0.028842734172940254,
      0.00905834510922432,
      -0.0046636685729026794,
      0.023457113653421402,
      0.011514714919030666,
      -0.053613755851984024,
      -0.027057785540819168,
      0.02650897577404976,
      -0.01250767707824707,
      0.03768521174788475,
      -0.036118362098932266,
      0.07206500321626663,
      -0.06473250687122345,
      -0.006529844831675291,
      0.11036425083875656,
      -0.06050029397010803,
      -0.020846152678132057,
      0.005512159317731857,
      -0.019338620826601982,
      -0.0692000761628151,
      -0.026544159278273582,
      0.021152859553694725,
      -0.02211748994886875,
      -0.00926488358527422,
      -0.04517549276351929,
      0.03456764295697212,
      0.010192018933594227,
      0.0015727565623819828,
      0.04925405606627464,
      0.04624180868268013,
      0.0536264069378376,
      -0.009116847068071365,
      -0.10186944901943207,
      -0.05978086590766907,
      0.0678388848900795,
      -0.06905750930309296,
      0.05038958415389061,
      -0.0145742641761899,
      0.0051834117621183395,
      0.00502056535333395,
      -0.007136139087378979,
      0.030250394716858864,
      -0.02239716425538063,
      -0.004668014589697123,
      0.011275185272097588,
      0.05770252272486687,
      0.03774227946996689,
      0.0055273473262786865,
      -0.034193605184555054,
      0.015099395997822285,
      -0.003108012257143855,
      0.02447904273867607,
      -0.028285812586545944,
      -0.0736808106303215,
      0.021600082516670227,
      0.013876162469387054,
      -0.01634952798485756,
      0.06050621345639229,
      -0.031599681824445724,
      -0.02180020697414875,
      -0.006419834215193987,
      -0.009933297522366047,
      -0.02804122492671013,
      -0.03541690856218338,
      -0.0249695535749197,
      0.015704134479165077,
      -0.03849419578909874,
      -0.002986110048368573,
      -0.06784553825855255,
      0.04177656024694443,
      0.05683783069252968,
      -0.014873838983476162,
      0.07112585753202438,
      0.016003336757421494,
      0.02963932603597641,
      0.07584475725889206,
      0.02558174915611744,
      -0.003792812582105398,
      -0.07705666124820709,
      -0.010817970149219036,
      0.0005090433987788856,
      0.004256332293152809,
      0.01879461668431759,
      0.019488414749503136,
      0.07133883982896805,
      0.015876423567533493,
      -0.03206108883023262,
      -0.10243944823741913,
      0.0002969228953588754,
      -0.005218942649662495,
      -0.009304089471697807,
      -0.05223996564745903,
      0.03626717999577522,
      0.03449748456478119,
      -0.020244300365447998,
      0.046839531511068344,
      -0.03373207524418831,
      -0.02051236666738987,
      -0.08390229195356369,
      0.03957065939903259,
      -0.025002136826515198,
      0.07408817112445831,
      -0.0020327947568148375,
      -0.04044896364212036,
      -0.03069179877638817,
      0.0010969925206154585,
      -0.02286531776189804,
      0.00412803515791893,
      -0.020233415067195892,
      0.05110136419534683,
      -0.0510113500058651,
      0.03353183716535568,
      -0.007194569334387779,
      0.02447786182165146,
      0.028588373214006424,
      -0.0033512674272060394,
      -0.04526312276721001,
      0.0355682447552681,
      -0.008511675521731377,
      0.08553095161914825,
      -0.045435450971126556,
      0.0783858522772789,
      0.04146493226289749,
      0.004520736169070005,
      -0.0035421764478087425,
      0.016653699800372124,
      0.05810488760471344,
      -0.011386586353182793,
      -0.020773956552147865,
      -0.03551759570837021,
      -0.014161838218569756,
      -0.005749649368226528,
      -0.0005417934153228998,
      -0.05409737676382065,
      -0.07491529732942581,
      0.027071092277765274,
      0.0018538635922595859,
      0.0066899629309773445,
      -0.022170837968587875,
      -0.023917585611343384,
      0.018657250329852104,
      -0.005147715099155903,
      0.006876207888126373,
      0.06153999641537666,
      0.07111688703298569,
      -0.0791625902056694,
      -0.06000728905200958,
      -0.018464140594005585,
      -0.020076408982276917,
      0.025293655693531036,
      -0.006321679335087538,
      -0.024095039814710617,
      -0.029978230595588684,
      0.08554501086473465,
      0.017014123499393463,
      -0.019735535606741905,
      -0.00035625576856546104,
      -0.015840696170926094,
      -0.06588909775018692,
      -0.07370311766862869,
      0.048347245901823044,
      -0.026763636618852615,
      0.003400965593755245,
      -0.04353785887360573,
      -0.014164326712489128,
      0.043854210525751114,
      0.06384529173374176,
      0.12949062883853912,
      -8.572929800720885e-06,
      0.06482018530368805,
      -0.015122365206480026,
      0.046327799558639526,
      -0.040694709867239,
      0.03578838333487511,
      -0.0018253966700285673,
      -0.030480772256851196,
      -0.015136961825191975,
      0.08990412950515747,
      0.052761174738407135,
      0.014141341671347618,
      0.05473695695400238,
      -0.04048975929617882,
      0.04711228236556053,
      -0.043886542320251465,
      -0.02960808575153351,
      0.00875537283718586,
      0.0021858487743884325,
      -0.07578855752944946,
      -0.04908284172415733,
      0.11133071035146713,
      0.023468423634767532,
      -0.027068594470620155,
      0.07169359922409058,
      0.029858611524105072,
      0.019649803638458252,
      -0.03691627085208893,
      -0.0297154001891613,
      0.019460106268525124,
      0.030189599841833115,
      -0.08353561162948608,
      0.12347106635570526,
      -0.06335523724555969,
      -0.005751945078372955,
      -0.04935530573129654,
      0.008461827412247658,
      -0.03480270504951477,
      -0.023792799562215805,
      0.0003430725773796439,
      0.035465970635414124,
      -0.04579583555459976,
      -0.05264213681221008,
      0.013895781710743904,
      0.0018070654477924109,
      -0.05768941342830658,
      0.009973863139748573,
      0.051700811833143234,
      0.02771887555718422,
      -0.027067597955465317,
      0.0023773224093019962,
      -0.015617434866726398,
      0.07711723446846008,
      0.008613409474492073,
      -0.040450140833854675,
      -0.029007092118263245,
      -0.09668602794408798,
      0.022373605519533157,
      0.012306718155741692,
      -0.01564965583384037,
      -0.04176502674818039,
      -0.0842839702963829,
      -0.008172329515218735,
      0.0621788427233696,
      -0.026402311399579048,
      -0.023576349020004272,
      -0.015520554035902023,
      -4.7268098569475114e-05,
      -0.042291175574064255,
      -0.0955798476934433,
      0.09627431631088257,
      -0.048273440450429916,
      0.06186286360025406,
      -0.01929429918527603,
      0.023410364985466003,
      -0.03057066909968853,
      -0.039606377482414246,
      0.07241452485322952,
      -0.04083188250660896,
      -0.033961206674575806,
      0.057916395366191864,
      0.01989540457725525,
      0.027381498366594315,
      0.05343616008758545,
      0.02414601668715477,
      0.03838452324271202,
      0.04030922055244446,
      0.039988644421100616,
      0.07120005786418915,
      0.025117304176092148,
      0.019255639985203743,
      0.023491524159908295,
      -0.08777240663766861,
      -0.009153958410024643,
      -0.02530551515519619,
      0.004706938285380602,
      0.007055704481899738,
      0.04881884902715683,
      0.03500337898731232,
      0.0015010660281404853,
      0.06914819777011871,
      0.10883767157793045,
      -0.08775201439857483,
      -0.06264530122280121,
      0.01111114863306284,
      0.0108671635389328,
      -0.005828846246004105,
      -0.06459987908601761,
      -0.016810623928904533,
      0.019541563466191292,
      0.02325618453323841,
      -0.03131638094782829,
      0.022221799939870834,
      0.08253639191389084,
      0.031304650008678436,
      0.011317744851112366,
      -0.057883813977241516,
      -0.04316934570670128,
      0.038252826780080795,
      -0.005783135071396828,
      0.06502021849155426,
      0.05125482380390167,
      0.11706734448671341,
      0.004293651785701513,
      -0.03477915748953819,
      0.02131740003824234,
      0.045211754739284515,
      -0.06123247742652893,
      0.027557194232940674,
      0.05695345252752304,
      0.009266144596040249,
      -0.11278077214956284,
      -0.009407704696059227,
      -0.013564771972596645,
      0.016287868842482567,
      0.11504873633384705,
      -0.04101897403597832,
      0.03138764202594757,
      -0.06460674107074738,
      0.002574019832536578,
      0.04684251546859741,
      -0.0417359285056591,
      -0.04985234886407852,
      -0.06663540005683899,
      0.015228993259370327,
      -0.03332601487636566,
      0.0777062475681305,
      0.027434304356575012,
      -0.021022692322731018,
      -0.010443943552672863,
      0.02863915078341961,
      0.08187207579612732,
      -0.057328905910253525,
      0.07921738922595978,
      0.010930730029940605,
      -0.016418414190411568,
      0.05359884351491928,
      0.04454865679144859,
      0.08606377989053726,
      -0.010692543350160122,
      0.044594746083021164,
      0.04272938519716263,
      -0.014508165419101715,
      -0.0358584001660347,
      0.01597151905298233,
      -0.0057403407990932465,
      0.014290381222963333,
      0.007061051204800606,
      -0.0009667858248576522,
      -0.013224408030509949,
      0.09081516414880753,
      0.0017911350587382913,
      0.05628314986824989,
      0.06769171357154846,
      0.03246844932436943,
      -0.015252620913088322,
      -0.02447124384343624,
      0.09541632235050201,
      -0.025057969614863396,
      0.008810090832412243,
      0.0743764266371727,
      -0.005470912437886,
      0.02132832631468773,
      0.0622984878718853,
      -0.0008613434038124979,
      0.04945065453648567,
      -0.03201426938176155,
      0.0661439523100853
    ],
    [
      0.05391482263803482,
      0.016598613932728767,
      0.0372280515730381,
      0.04025545343756676,
      -0.09173791110515594,
      0.022326745092868805,
      -0.01787622831761837,
      -0.025089403614401817,
      -0.061486370861530304,
      0.0024746463168412447,
      -0.007019017823040485,
      -0.03863561153411865,
      -0.02434556372463703,
      0.10864178091287613,
      -0.07257397472858429,
      -0.05044262111186981,
      -0.07670430839061737,
      -0.03046242520213127,
      -0.10535620152950287,
      -0.07254212349653244,
      -0.03447235748171806,
      0.08966521173715591,
      0.05297316238284111,
      0.05210602656006813,
      0.00971696525812149,
      -0.005302410572767258,
      -0.012008546851575375,
      -0.013410719111561775,
      0.004131455905735493,
      0.015152985230088234,
      0.054364513605833054,
      0.00564464321359992,
      0.013799628242850304,
      -0.09684580564498901,
      -0.025568071752786636,
      -0.0226494912058115,
      -0.024769354611635208,
      -0.04455181211233139,
      0.007308986037969589,
      0.03793753311038017,
      -0.0708889588713646,
      0.016675105318427086,
      -0.07251615077257156,
      -0.05525170639157295,
      -0.043185606598854065,
      0.028031475841999054,
      -0.005891696084290743,
      -0.022230539470911026,
      0.021140629425644875,
      -0.005157024133950472,
      -0.0016194432973861694,
      0.005123804789036512,
      0.0061545418575406075,
      -0.014221813529729843,
      -0.002777008805423975,
      -0.03610246255993843,
      0.021148616448044777,
      0.03801240399479866,
      -0.027692824602127075,
      -0.03617473319172859,
      -0.041516706347465515,
      -0.04735260456800461,
      -0.014910198748111725,
      0.04683370515704155,
      -0.007699973881244659,
      -0.05582072213292122,
      0.0861157476902008,
      0.013031680136919022,
      -0.05019960552453995,
      -0.023669598624110222,
      -0.06270847469568253,
      -0.012772921472787857,
      -0.023431718349456787,
      -0.02119276113808155,
      -0.0746406763792038,
      0.0360577255487442,
      -0.021164545789361,
      0.08854430913925171,
      -0.034174300730228424,
      0.08035055547952652,
      -0.05114322900772095,
      -0.0303986594080925,
      0.03767749294638634,
      0.025742514058947563,
      -0.06229237839579582,
      0.06680454313755035,
      0.0007213540375232697,
      -0.016780538484454155,
      -0.0313047394156456,
      0.046469323337078094,
      -0.03709636628627777,
      0.0349586084485054,
      0.08078135550022125,
      0.038323916494846344,
      0.0328194834291935,
      -0.0008238455047830939,
      0.03351502865552902,
      -0.014908086508512497,
      -0.020793849602341652,
      -0.011849832721054554,
      0.029569771140813828,
      0.003946592099964619,
      -0.029582148417830467,
      0.028787538409233093,
      -0.040819812566041946,
      -0.04469204321503639,
      0.01937078684568405,
      -0.05129905417561531,
      -0.04614768549799919,
      -0.041404079645872116,
      0.03222084045410156,
      0.04961458593606949,
      -0.0603543296456337,
      -0.0083573954179883,
      0.03432347998023033,
      -0.028448931872844696,
      -0.019594257697463036,
      -0.013355707749724388,
      -0.03396857529878616,
      -0.06002066656947136,
      0.11478788405656815,
      -0.051320452243089676,
      0.05582507699728012,
      -0.01552501693367958,
      0.027264803647994995,
      -0.007899205200374126,
      -0.021064015105366707,
      -0.042408302426338196,
      0.009655803442001343,
      0.03349072113633156,
      0.07359480857849121,
      -0.09329169243574142,
      -0.04925292730331421,
      0.06746532768011093,
      0.038355644792318344,
      -0.12573568522930145,
      0.027571335434913635,
      -0.053409725427627563,
      0.04965567588806152,
      -0.02667129784822464,
      -0.012538076378405094,
      -0.013415247201919556,
      0.08617933839559555,
      -0.023614605888724327,
      -0.076685830950737,
      0.012756266631186008,
      0.047140076756477356,
      -0.029375286772847176,
      -0.021078260615468025,
      -0.007702698465436697,
      0.04733406379818916,
      -0.08683986216783524,
      -0.06528709083795547,
      0.012574765831232071,
      0.07667618244886398,
      -0.03614984452724457,
      0.07520727068185806,
      -0.09332102537155151,
      -0.06889810413122177,
      0.007614129222929478,
      0.055897749960422516,
      0.0476781502366066,
      -0.07884865254163742,
      -0.0001985302660614252,
      0.03551361337304115,
      -0.02556711994111538,
      -0.018655331805348396,
      -0.06463604420423508,
      0.0353122316300869,
      0.04740671068429947,
      0.02878476120531559,
      0.07860148698091507,
      0.005361245013773441,
      -0.03483803570270538,
      -0.009943647310137749,
      -0.038092728704214096,
      0.008225743658840656,
      -0.03505237028002739,
      -0.014615524560213089,
      0.0949610024690628,
      0.009851955808699131,
      0.015603307634592056,
      -0.019290469586849213,
      0.04451221600174904,
      -0.09130306541919708,
      0.025888977572321892,
      -0.003632082138210535,
      0.09251676499843597,
      0.06384202837944031,
      0.09056109189987183,
      0.031003402546048164,
      0.02431119978427887,
      -0.04347814992070198,
      0.010347602888941765,
      0.026916448026895523,
      0.00751628028228879,
      0.03637919947504997,
      0.06302475929260254,
      0.03661031648516655,
      0.007544046733528376,
      0.013299795798957348,
      -0.02553468383848667,
      -0.04199397563934326,
      0.058035705238580704,
      0.0039979033172130585,
      -0.01595940999686718,
      -0.01684023067355156,
      -0.025469930842518806,
      0.02562188170850277,
      0.0073868404142558575,
      -0.06101996451616287,
      -0.0812903419137001,
      -0.05333700776100159,
      -0.05334770679473877,
      -0.028667721897363663,
      0.01853693090379238,
      -0.01671414263546467,
      0.09064160287380219,
      0.043675780296325684,
      0.03620859235525131,
      0.007080572657287121,
      0.034948673099279404,
      -0.015563637018203735,
      0.012468990869820118,
      -0.019472338259220123,
      0.030270522460341454,
      -0.02230934239923954,
      0.002956689102575183,
      0.006897477433085442,
      -0.01799478568136692,
      -0.07833915203809738,
      -0.02653433382511139,
      0.0740930438041687,
      0.04693567380309105,
      0.07121092826128006,
      -0.02083064429461956,
      0.031015610322356224,
      0.001862592063844204,
      0.05194668471813202,
      0.10608138889074326,
      -0.04268747940659523,
      -0.020487649366259575,
      0.010981649160385132,
      0.012180148623883724,
      0.048463817685842514,
      -0.014334836974740028,
      -0.010758569464087486,
      -0.026226865127682686,
      -0.01631081849336624,
      -0.07080399245023727,
      0.051195211708545685,
      -0.09159117192029953,
      0.05916567146778107,
      0.008390549570322037,
      -0.03913365676999092,
      0.06150898337364197,
      -0.04061233624815941,
      -0.000132287954329513,
      -0.029046442359685898,
      -0.03260807320475578,
      0.057093895971775055,
      -0.04688559100031853,
      0.029429102316498756,
      -0.0048201438039541245,
      0.0974169373512268,
      -0.09249315410852432,
      0.021393848583102226,
      0.049550749361515045,
      0.02783254161477089,
      0.04688902199268341,
      -0.03809531033039093,
      0.030681997537612915,
      0.024007773026823997,
      0.04408678412437439,
      0.027263816446065903,
      0.0731242224574089,
      0.03886760398745537,
      0.007911675609648228,
      0.035293105989694595,
      0.007862221449613571,
      -0.005526404827833176,
      -0.07030577957630157,
      0.0364222526550293,
      -0.07299178838729858,
      0.026871947571635246,
      0.0306429173797369,
      -0.026045776903629303,
      -0.08950287103652954,
      0.06638076901435852,
      -0.044953662902116776,
      -0.02137030102312565,
      0.07661940902471542,
      -0.033617351204156876,
      -0.03059505671262741,
      0.09985076636075974,
      -0.0007273669471032917,
      -0.00600411556661129,
      -0.029245968908071518,
      -0.05574682727456093,
      -0.006978634744882584,
      0.06397747993469238,
      -0.010860835202038288,
      -0.0019581629894673824,
      0.03371052443981171,
      0.016212044283747673,
      0.017216406762599945,
      -0.022718919441103935,
      0.02796471305191517,
      0.08559948205947876,
      -0.007038087584078312,
      -0.06793303787708282,
      -0.02405507117509842,
      -0.0379292368888855,
      -0.040158458054065704,
      -0.045133791863918304,
      -0.03393465653061867,
      -0.0028437201399356127,
      0.04242093861103058,
      -0.007342151366174221,
      -9.93080684565939e-05,
      -0.02381225675344467,
      -0.0767732635140419,
      0.04004691168665886,
      0.026862844824790955,
      0.019142024219036102,
      0.04670853540301323,
      0.0016692484496161342,
      0.02323535829782486,
      -0.016487905755639076,
      0.10456880182027817,
      -0.02916569821536541,
      -0.028035737574100494,
      0.008568569086492062,
      0.11184094846248627,
      -0.031208395957946777,
      -0.013428541831672192,
      -0.05791740119457245,
      0.04408763349056244,
      0.024424806237220764,
      -0.03637351840734482,
      -0.0741693302989006,
      0.024198761209845543,
      -0.04672002047300339,
      -0.005025752354413271,
      0.0389094278216362,
      0.020090902224183083,
      0.03667133301496506,
      0.003811177099123597,
      -0.028039585798978806,
      0.010250168852508068,
      0.016194917261600494,
      0.05277710407972336,
      0.05328356847167015,
      -0.06378122419118881,
      -0.032446589320898056,
      -0.008841295726597309,
      -0.04312620684504509,
      -0.03348027169704437,
      0.052934885025024414,
      0.06276052445173264,
      0.009210259653627872,
      0.03399534896016121,
      -0.05298909172415733,
      -0.0382721982896328,
      0.05583454295992851,
      -0.025923429057002068,
      -0.052913252264261246,
      0.01903088204562664,
      0.07097382098436356,
      -0.0064099072478711605,
      0.015284653753042221,
      0.02425975166261196,
      0.003817928023636341,
      -0.02834434062242508,
      -0.019855301827192307,
      0.047030068933963776,
      -0.00799962505698204,
      0.04950106516480446,
      0.0361345112323761,
      -0.030579257756471634,
      0.00019344460451975465,
      -0.03601420298218727,
      0.03939606249332428,
      0.002705339575186372,
      0.04008445888757706,
      0.015745218843221664,
      0.04595613852143288,
      -0.035867638885974884,
      0.027750302106142044,
      0.04794260114431381,
      0.04160255566239357,
      0.01829097419977188,
      0.005695933476090431,
      0.029576260596513748,
      0.059570103883743286,
      0.00538980308920145,
      0.03828556835651398,
      0.012315293774008751,
      -0.0929524302482605,
      0.028993140906095505,
      0.10601609200239182,
      0.016447877511382103,
      0.03146757930517197,
      0.02335524931550026,
      -0.0165258776396513,
      -0.049281664192676544,
      0.017414268106222153,
      -0.0783916562795639,
      0.029340563341975212,
      0.030296724289655685,
      0.037927962839603424,
      -0.04378952085971832,
      -0.0012903026072308421,
      -0.0874573364853859,
      -0.02046629786491394,
      -0.03127262741327286,
      -0.03228769823908806,
      -0.02262217365205288,
      0.006600936874747276,
      0.019098728895187378,
      -0.09429232031106949,
      -0.010669739916920662,
      0.04158927500247955,
      -0.034158531576395035,
      -0.023319441825151443,
      0.0038431796710938215,
      0.016081297770142555,
      -0.052079349756240845,
      0.06232602894306183,
      -0.03006638027727604,
      -0.030136071145534515,
      0.05577878654003143,
      0.026036707684397697,
      0.06107702478766441,
      0.0348648801445961,
      0.0779629796743393,
      -0.0868581011891365,
      0.054102737456560135,
      -0.03965041786432266,
      -0.014262459240853786,
      -0.027936480939388275,
      0.04713306948542595,
      0.030303381383419037,
      0.06808876991271973,
      -0.07086269557476044,
      0.09053394198417664,
      0.024970756843686104,
      0.056253574788570404,
      0.02775597758591175,
      -0.022300034761428833,
      0.03851771354675293,
      -0.02258501760661602,
      -0.03685648739337921,
      -0.04696604609489441,
      0.030393129214644432,
      -0.02500215917825699,
      0.0015182312345132232,
      0.020398475229740143,
      0.03349113091826439,
      0.038354888558387756,
      0.03631101921200752,
      0.07277263700962067,
      -0.04257757216691971,
      -0.0075943078845739365,
      0.027989758178591728,
      -0.017310723662376404,
      0.013664033263921738,
      0.03831319138407707,
      0.036940187215805054,
      -0.02909802459180355,
      -0.11404560506343842,
      -0.019549816846847534,
      -0.05244079977273941,
      -0.023735657334327698,
      0.04349504038691521,
      -0.08244312554597855,
      0.01216382347047329,
      -0.04447748139500618,
      -0.004040108993649483,
      -0.06040047109127045,
      0.06997764110565186,
      0.0416557751595974,
      0.010240069590508938,
      0.03939522057771683,
      0.0006458664429374039,
      0.05315844714641571,
      0.10581256449222565,
      0.010874087922275066,
      -0.07511928677558899,
      0.018047001212835312,
      0.074261374771595,
      -0.006801017560064793,
      -0.020484773442149162,
      0.048060622066259384,
      -0.009104629047214985,
      -0.03310491889715195,
      0.002914288081228733,
      -0.015318796969950199,
      -0.07788176834583282,
      -0.02089427039027214,
      -0.03689901903271675,
      -0.002482407260686159,
      0.02999401092529297
    ],
    [
      -0.008811857551336288,
      0.004092005547136068,
      -0.10705704987049103,
      -0.008857471868395805,
      -0.061466652899980545,
      -0.012896502390503883,
      -0.07005766034126282,
      0.050023119896650314,
      0.02931828610599041,
      -0.0019058478064835072,
      0.011152851395308971,
      -0.02343474142253399,
      -0.01840287819504738,
      0.011896760202944279,
      -0.038953736424446106,
      0.009964277036488056,
      -0.05964868143200874,
      0.06995096057653427,
      -0.0036667073145508766,
      -0.012183215469121933,
      -0.09720024466514587,
      -0.03403365984559059,
      -0.019665131345391273,
      -0.002303528366610408,
      0.05418549105525017,
      -0.03268495202064514,
      0.01809435896575451,
      -0.004022662527859211,
      0.03309399634599686,
      -0.02556677535176277,
      0.06542331725358963,
      -0.09527529031038284,
      0.06952723860740662,
      -0.011024031788110733,
      0.050646744668483734,
      0.01669294387102127,
      -0.003495843382552266,
      0.021537717431783676,
      0.04878133907914162,
      0.11268866807222366,
      -0.15637734532356262,
      0.057425130158662796,
      -0.027487797662615776,
      -0.02425995096564293,
      -0.01201754529029131,
      0.055746763944625854,
      -0.03582432121038437,
      -0.01832597330212593,
      -0.027020711451768875,
      0.018948184326291084,
      -0.044913966208696365,
      0.022982539609074593,
      -0.10386815667152405,
      -0.07671282440423965,
      0.027049321681261063,
      0.05136159807443619,
      -0.03434491530060768,
      0.007959341630339622,
      -0.01938188448548317,
      0.016706982627511024,
      0.0373406745493412,
      0.007231522351503372,
      0.016520826146006584,
      0.04619082063436508,
      0.03905534744262695,
      0.0017026339191943407,
      0.042487166821956635,
      0.013349725864827633,
      -0.02741662971675396,
      -0.014506482519209385,
      -0.04906231164932251,
      -0.043993543833494186,
      0.06537026166915894,
      -0.02801606059074402,
      -0.03548620268702507,
      0.04364959895610809,
      -0.03557164594531059,
      0.016687501221895218,
      -0.006893594283610582,
      -0.00569832744076848,
      -0.07638584822416306,
      -0.02493865042924881,
      0.030634384602308273,
      -0.0024153096601366997,
      0.026641059666872025,
      -0.0003272192261647433,
      -0.07208377867937088,
      0.042208604514598846,
      -0.0716477781534195,
      -0.04938025400042534,
      -0.05076557770371437,
      0.017784008756279945,
      -0.05654265359044075,
      -0.018589194864034653,
      0.025449881330132484,
      -0.008707118220627308,
      -0.028253106400370598,
      0.0016448303358629346,
      0.012303358875215054,
      -0.03819187730550766,
      -0.0072321901097893715,
      -0.08123992383480072,
      -0.0209279153496027,
      0.030415944755077362,
      -0.03833957761526108,
      0.022849066182971,
      0.019553162157535553,
      0.008641456253826618,
      -0.006237171124666929,
      -0.016749680042266846,
      -0.01977052539587021,
      -0.0183088555932045,
      0.04132508486509323,
      0.057491596788167953,
      -0.0038645844906568527,
      -0.019118687137961388,
      -0.06505980342626572,
      -0.016364602372050285,
      -0.05320535972714424,
      0.08576007187366486,
      -0.01965930312871933,
      0.028429774567484856,
      0.010591134428977966,
      -0.1307118833065033,
      0.02027004025876522,
      -0.045828718692064285,
      0.03130301833152771,
      -0.03426484391093254,
      0.03334782272577286,
      -0.06028975918889046,
      0.11458922922611237,
      -0.1055026575922966,
      -0.04114982858300209,
      -0.05084475502371788,
      -0.02799268811941147,
      -0.03178805112838745,
      -0.09240572899580002,
      0.04288353770971298,
      0.05340798571705818,
      0.04510048031806946,
      -0.009333020076155663,
      -0.026953650638461113,
      0.00983633566647768,
      0.04324454441666603,
      0.14160940051078796,
      0.0004232984210830182,
      -0.10466928035020828,
      -0.022389443591237068,
      -0.0024215462617576122,
      -0.03794446587562561,
      0.05974046513438225,
      -0.028934398666024208,
      -0.022752711549401283,
      -0.04328569024801254,
      -0.006500773597508669,
      0.05532059445977211,
      -0.011249396950006485,
      0.00011300785263301805,
      -0.06447543948888779,
      -0.023527206853032112,
      0.049596209079027176,
      -0.09183055907487869,
      -0.02547794207930565,
      -0.0017458213260397315,
      -0.02503989450633526,
      -0.0009829549817368388,
      0.03126730024814606,
      0.0025842743925750256,
      -0.005601051263511181,
      0.07264077663421631,
      -0.05197558179497719,
      0.023667817935347557,
      0.09561946243047714,
      0.0637909397482872,
      0.019720135256648064,
      -0.010567985475063324,
      0.044923435896635056,
      -0.09254128485918045,
      0.011833567172288895,
      -0.009509203024208546,
      0.022033529356122017,
      -0.016878800466656685,
      -0.031801737844944,
      -0.0007325538317672908,
      -0.06120678409934044,
      0.02025027386844158,
      -0.05975131317973137,
      -0.019399408251047134,
      -0.03991801291704178,
      0.05108857899904251,
      0.021966440603137016,
      -0.03697774559259415,
      -0.05044276639819145,
      0.01533962320536375,
      0.041964005678892136,
      0.028767429292201996,
      -0.08842530101537704,
      -0.0037934198044240475,
      -0.024201221764087677,
      0.04442200809717178,
      0.014870225451886654,
      -0.0346478633582592,
      -0.013097257353365421,
      -0.009037784300744534,
      0.03922000527381897,
      0.06038999557495117,
      -0.026307720690965652,
      0.005762696266174316,
      -0.02583872340619564,
      0.06169591099023819,
      -0.014532620087265968,
      -0.03924283757805824,
      0.023842524737119675,
      -0.05270743370056152,
      0.0335727334022522,
      0.05766545981168747,
      0.046611238270998,
      0.01887515000998974,
      0.048801910132169724,
      -0.057623740285634995,
      0.08479608595371246,
      0.02725195325911045,
      0.05860495939850807,
      0.06937967985868454,
      0.040026746690273285,
      -0.020761720836162567,
      -0.05802145600318909,
      0.06752582639455795,
      -0.0003632603620644659,
      0.009546136483550072,
      -0.031880103051662445,
      -0.006785755977034569,
      -0.00014921656111255288,
      -0.02218092978000641,
      0.047157563269138336,
      0.07197504490613937,
      -0.03122163563966751,
      -0.028064671903848648,
      -0.031364064663648605,
      -0.028878167271614075,
      0.0014406025875359774,
      0.020240647718310356,
      0.02478008344769478,
      0.039998773485422134,
      -0.022331008687615395,
      -0.012881794013082981,
      0.02586960606276989,
      -0.032662056386470795,
      -0.02829092927277088,
      -0.053531378507614136,
      -0.02130020596086979,
      0.026805106550455093,
      -0.024020059034228325,
      -0.10813529044389725,
      0.02468348667025566,
      -0.01748601347208023,
      -0.06471949070692062,
      0.03785219416022301,
      -0.06499632447957993,
      -0.020905204117298126,
      0.03160025179386139,
      -0.009697381407022476,
      0.061803799122571945,
      -0.07471396028995514,
      0.04771317541599274,
      -0.006126465741544962,
      0.03366576135158539,
      0.0340832956135273,
      -0.07244996726512909,
      -0.08142372965812683,
      -0.019525917246937752,
      -0.004311340395361185,
      0.02269279770553112,
      -0.04142148792743683,
      -0.06363622844219208,
      -0.036276936531066895,
      0.014774692244827747,
      0.012276167050004005,
      0.07210968434810638,
      0.002123165875673294,
      -0.01388699933886528,
      0.08734721690416336,
      0.008688311092555523,
      0.0006334579084068537,
      -0.023813510313630104,
      -0.023146888241171837,
      -0.029091758653521538,
      -0.015045898966491222,
      0.08288974314928055,
      0.03755702078342438,
      0.06912776082754135,
      -0.04195213317871094,
      -0.0761663168668747,
      -0.05387279763817787,
      -0.026229919865727425,
      -0.02010616660118103,
      -0.08488164842128754,
      0.018944650888442993,
      0.038915809243917465,
      -0.026453806087374687,
      0.021306276321411133,
      -0.052109479904174805,
      0.004827705677598715,
      0.03280262276530266,
      0.028017623350024223,
      -0.024877015501260757,
      0.044264715164899826,
      -0.0007143206894397736,
      -0.11101852357387543,
      1.6547037375858054e-05,
      -0.03489132225513458,
      -0.04109504446387291,
      -0.038111038506031036,
      1.9499895643093623e-05,
      0.05696781352162361,
      -0.03611164167523384,
      0.0303355660289526,
      0.05693071708083153,
      0.039083048701286316,
      0.022103751078248024,
      0.017797909677028656,
      0.0421772338449955,
      -0.008691288530826569,
      -0.028931422159075737,
      0.03748125955462456,
      -0.020974747836589813,
      -0.04535859078168869,
      -0.026000261306762695,
      -0.01080253068357706,
      -0.0260317400097847,
      -0.08875341713428497,
      -0.10768888890743256,
      -0.03516257926821709,
      -0.050909362733364105,
      -0.0038325136993080378,
      -0.020609386265277863,
      0.03402255102992058,
      -0.023526053875684738,
      -0.053243327885866165,
      0.014787334017455578,
      -0.03257688879966736,
      -0.04508841410279274,
      0.05160316452383995,
      -0.12222380191087723,
      0.10834675282239914,
      0.07305151224136353,
      0.028473790735006332,
      -0.017671069130301476,
      -0.020861860364675522,
      -0.015768228098750114,
      0.00652411300688982,
      0.04317036271095276,
      -0.06628002226352692,
      -0.03995339944958687,
      0.0014933135826140642,
      -0.08292510360479355,
      -0.09764644503593445,
      -0.03622828796505928,
      -0.07471708208322525,
      -0.09915384650230408,
      0.04258688911795616,
      -0.04582134261727333,
      -0.014101562090218067,
      -0.0756881833076477,
      0.11071551591157913,
      -0.05873996764421463,
      -0.06073008105158806,
      0.0008105165907181799,
      -0.018549496307969093,
      -0.11312428116798401,
      0.022494861856102943,
      0.027114542201161385,
      0.05246715992689133,
      -0.10368305444717407,
      0.006432084366679192,
      0.0555027574300766,
      -0.0231609009206295,
      0.02713869884610176,
      0.029255976900458336,
      -0.019552551209926605,
      0.06478242576122284,
      -0.025615626946091652,
      -0.012384267523884773,
      -0.04572341963648796,
      0.06126688793301582,
      0.020634371787309647,
      0.05275587737560272,
      0.07892493158578873,
      0.025731664150953293,
      -0.019831335172057152,
      0.013551103882491589,
      0.00653919717296958,
      -0.026390086859464645,
      0.044421207159757614,
      -0.035030726343393326,
      -0.036403361707925797,
      0.08957212418317795,
      -0.0500841960310936,
      -0.004109660163521767,
      0.043989960104227066,
      -0.053213492035865784,
      0.021634778007864952,
      0.01824549213051796,
      0.0068832263350486755,
      0.003943689167499542,
      -0.006006735377013683,
      -0.04380972683429718,
      0.03352602943778038,
      0.008350927382707596,
      0.006227719131857157,
      -0.005402314476668835,
      -0.04944644495844841,
      0.007361614145338535,
      0.0026130510959774256,
      0.053786493837833405,
      0.031030278652906418,
      0.025633135810494423,
      0.014612782746553421,
      0.03927874565124512,
      0.025403881445527077,
      -0.01725594885647297,
      -0.010517043061554432,
      -0.10813777148723602,
      -0.02635793760418892,
      -0.005958106834441423,
      -0.010797497816383839,
      -0.07159721106290817,
      0.07441949099302292,
      0.011821411550045013,
      -0.009245612658560276,
      0.0347071997821331,
      -0.07558564096689224,
      -0.0023717707954347134,
      0.017319893464446068,
      0.00025682977866381407,
      -0.01418295782059431,
      0.03332825005054474,
      0.008808879181742668,
      -0.031938761472702026,
      0.0033912239596247673,
      0.0033168024383485317,
      0.019757047295570374,
      -0.01797175593674183,
      -0.07967487722635269,
      0.05344550684094429,
      -0.008847642689943314,
      0.01861707493662834,
      0.00349519238807261,
      -0.02755158580839634,
      -0.037541404366493225,
      0.14915861189365387,
      -0.061668142676353455,
      0.05467776581645012,
      -0.03233370557427406,
      -0.07111350446939468,
      -0.0048394338227808475,
      -0.048260509967803955,
      0.0319199338555336,
      0.055366456508636475,
      0.0840686485171318,
      0.02466760203242302,
      0.05471270903944969,
      -0.03090788796544075,
      -0.062370508909225464,
      0.0706929862499237,
      0.028459206223487854,
      -0.015082459896802902,
      -0.005580794997513294,
      -0.03537040203809738,
      0.00894871260970831,
      -0.051562387496232986,
      0.021497618407011032,
      0.011377587914466858,
      0.032290998846292496,
      -0.07086443156003952,
      0.0005801371298730373,
      0.008688877336680889,
      -0.027061540633440018,
      0.04053233936429024,
      0.002064482541754842,
      -0.035621412098407745,
      -0.0861264169216156,
      0.029008343815803528,
      0.014837294816970825,
      -0.03059619665145874,
      0.0667981281876564,
      -0.00603295024484396,
      0.06993938982486725,
      -0.03300381451845169,
      0.023152004927396774,
      0.02791476808488369,
      -0.11244290322065353,
      -0.015171753242611885,
      -0.07359974086284637,
      -0.0032211109064519405,
      -0.02394188940525055,
      -0.02641495130956173,
      0.04845740646123886,
      -0.03655396029353142,
      0.03441142663359642,
      -0.0022398862056434155,
      0.02085326798260212,
      0.057936593890190125
    ],
    [
      0.03637047857046127,
      -0.011832067742943764,
      -0.04561791941523552,
      0.01335025392472744,
      0.025800704956054688,
      -0.007747419644147158,
      -0.054840534925460815,
      0.012091542594134808,
      -0.0440308079123497,
      -0.02967228926718235,
      -0.04865672066807747,
      -0.0677688717842102,
      -0.05228041857481003,
      -0.016738835722208023,
      -0.029046563431620598,
      0.047992680221796036,
      -0.026930585503578186,
      -0.06921663880348206,
      -0.05013379454612732,
      -0.06347943097352982,
      -0.02184106595814228,
      -0.04863760620355606,
      0.017616139724850655,
      -0.013090942986309528,
      0.021054770797491074,
      -0.014653225429356098,
      -0.04088825732469559,
      -0.09922181069850922,
      -0.04983796924352646,
      0.018902262672781944,
      -0.009913478046655655,
      0.000592048279941082,
      0.02736540324985981,
      0.03333423286676407,
      0.04498206824064255,
      0.02674662135541439,
      0.02759419195353985,
      0.002775501925498247,
      -0.05566747486591339,
      0.007150504272431135,
      -0.0966697484254837,
      0.019463934004306793,
      -0.03907615318894386,
      0.07759595662355423,
      0.024330927059054375,
      -0.06456135213375092,
      -0.009942165575921535,
      -0.054373037070035934,
      -0.028133898973464966,
      0.007317481562495232,
      -0.05130399763584137,
      0.04756996035575867,
      -0.048459023237228394,
      0.01755070686340332,
      0.015185996890068054,
      -0.010126288048923016,
      0.08389799296855927,
      0.02814047411084175,
      -0.07822227478027344,
      -0.0111587168648839,
      0.051120128482580185,
      -0.0602070689201355,
      -0.01434779167175293,
      -0.02240494266152382,
      0.06892301887273788,
      0.08660592883825302,
      0.046340640634298325,
      0.07471129298210144,
      0.09768562763929367,
      -0.013551801443099976,
      0.05740184709429741,
      0.015018951147794724,
      -0.0254938006401062,
      -0.003910257015377283,
      -0.020567260682582855,
      9.32700204430148e-05,
      -0.038406383246183395,
      0.08276807516813278,
      0.03680591285228729,
      -0.04895366355776787,
      -0.022372769191861153,
      0.034653205424547195,
      -0.019083399325609207,
      0.02064330317080021,
      -0.010757246986031532,
      0.03702456131577492,
      -0.0056391931138932705,
      0.06296563893556595,
      0.054002564400434494,
      -0.003499788697808981,
      0.0008145369356498122,
      0.03265254944562912,
      -0.050604529678821564,
      -0.005056410562247038,
      0.025837091729044914,
      -0.03308318927884102,
      0.01079399511218071,
      0.0009141259361058474,
      0.03265688195824623,
      0.02333255298435688,
      -0.09725193679332733,
      -0.02854343317449093,
      0.002022246830165386,
      -0.08204551041126251,
      -0.05352085828781128,
      0.0650324895977974,
      -0.02504654973745346,
      0.046037957072257996,
      -0.01861594431102276,
      -0.004207611549645662,
      0.015660692006349564,
      -0.07979435473680496,
      -0.00020189715723972768,
      0.04508044198155403,
      0.052257928997278214,
      -0.004067207686603069,
      0.0006086897337809205,
      -0.07725423574447632,
      0.02039118856191635,
      0.07681677490472794,
      0.05996188521385193,
      -0.007416113279759884,
      0.0921870619058609,
      -0.08640789985656738,
      0.01214055810123682,
      -0.09208187460899353,
      -0.05001436173915863,
      -0.03095870092511177,
      0.0708591639995575,
      0.10558688640594482,
      -0.02254152111709118,
      -0.05811679735779762,
      0.03536445274949074,
      -0.03189918026328087,
      0.029682764783501625,
      -0.012514056637883186,
      -0.005489599425345659,
      0.038752421736717224,
      0.010680891573429108,
      0.07134373486042023,
      0.0437454991042614,
      -0.061179470270872116,
      0.005520844832062721,
      0.0027607919182628393,
      0.06286375224590302,
      0.02073577046394348,
      0.03029327467083931,
      -0.03108574077486992,
      0.048867564648389816,
      -0.041116368025541306,
      0.04051036387681961,
      0.08239167183637619,
      -0.024306071922183037,
      -0.011952829547226429,
      0.0137793542817235,
      0.029598159715533257,
      0.02234194427728653,
      0.022813530638813972,
      0.0034881574101746082,
      -0.005377903115004301,
      0.042478758841753006,
      -0.03951963782310486,
      -0.06427005678415298,
      -0.0338774248957634,
      -0.062228184193372726,
      -0.03333764523267746,
      -0.004075166769325733,
      -0.1279429942369461,
      -0.02850303426384926,
      0.0309089757502079,
      0.04684370756149292,
      0.02710920013487339,
      0.01862526684999466,
      -0.0341942273080349,
      -0.004812987986952066,
      0.06013040617108345,
      -0.009660555981099606,
      -0.003309789812192321,
      -0.019030770286917686,
      -0.04250152036547661,
      0.006348076742142439,
      0.010664590634405613,
      -0.041528914123773575,
      -0.019339416176080704,
      0.045946989208459854,
      0.1193699762225151,
      -0.003224011277779937,
      0.020560892298817635,
      0.005135535262525082,
      0.043506473302841187,
      -0.003076553111895919,
      -0.0372772179543972,
      0.03717043623328209,
      -0.030800623819231987,
      -0.04337696731090546,
      0.04046186804771423,
      0.05304323509335518,
      -0.08307421952486038,
      -0.02098963037133217,
      0.010000518523156643,
      0.07387441396713257,
      0.007837438955903053,
      -0.035611703991889954,
      0.01908995397388935,
      0.030339840799570084,
      -0.02111681178212166,
      0.02733650803565979,
      -0.0027506446931511164,
      0.06451299041509628,
      0.042055584490299225,
      -0.05719611048698425,
      0.05863057076931,
      0.06457671523094177,
      0.06369045376777649,
      -0.06404218077659607,
      0.03334827721118927,
      0.03679783642292023,
      0.029288513585925102,
      0.045117706060409546,
      -0.026177868247032166,
      -0.03582262620329857,
      0.0009870568756014109,
      -0.010134325362741947,
      0.05778391286730766,
      0.028661752119660378,
      -0.006957351230084896,
      -0.0409473218023777,
      -0.04532850906252861,
      0.02372884191572666,
      -0.022837476804852486,
      -0.044156331568956375,
      0.02398463897407055,
      0.018077319487929344,
      -0.0428810715675354,
      0.12270758301019669,
      -0.0398368164896965,
      -0.06650391966104507,
      -0.05439327284693718,
      -0.019756130874156952,
      -0.009734141640365124,
      0.05358927324414253,
      -0.07821927219629288,
      -0.0602094866335392,
      -0.019534919410943985,
      -0.006821181625127792,
      0.03497081249952316,
      -0.043061062693595886,
      -0.01303432509303093,
      0.015456506051123142,
      -0.06193310394883156,
      -0.11580391973257065,
      -0.01994413137435913,
      -0.02894865907728672,
      -0.016468418762087822,
      0.11233510076999664,
      -0.05335365980863571,
      -0.012757247313857079,
      -0.04052194580435753,
      -0.018957873806357384,
      -0.01955975592136383,
      -0.021023238077759743,
      -0.026967596262693405,
      0.0015170802362263203,
      0.017383787781000137,
      -0.0003549237153492868,
      -0.004709306173026562,
      0.018174344673752785,
      0.017337147146463394,
      -0.011699525639414787,
      0.005184143781661987,
      0.014554860070347786,
      -0.01577785424888134,
      -0.038144633173942566,
      0.05592256039381027,
      0.02864103578031063,
      -0.10261222720146179,
      -0.03779633343219757,
      0.03370632231235504,
      -0.03111756406724453,
      -0.0399441197514534,
      0.05991504713892937,
      -0.0615253672003746,
      0.024416161701083183,
      -0.01755654439330101,
      -0.04846882447600365,
      -0.028081664815545082,
      -0.015098844654858112,
      0.0007335771806538105,
      -0.01692562736570835,
      0.05692172795534134,
      0.02140703611075878,
      -0.05808619409799576,
      0.10208635777235031,
      0.052401989698410034,
      -0.05385230481624603,
      -0.0010584313422441483,
      0.05249578505754471,
      -0.004703591577708721,
      -0.02926444634795189,
      -0.017971646040678024,
      -0.009424679912626743,
      -0.023810898885130882,
      -0.06642892956733704,
      0.06824315339326859,
      -0.016618968918919563,
      -0.004084708634763956,
      0.014264022000133991,
      -0.0849590077996254,
      0.019334277138113976,
      -0.006179242394864559,
      0.0063955713994801044,
      0.004544849041849375,
      0.0799158364534378,
      -0.008435288444161415,
      -0.003663173411041498,
      0.011118520051240921,
      -0.04486609995365143,
      -0.024192821234464645,
      0.00855895597487688,
      0.08639156818389893,
      0.020906098186969757,
      -0.03818424046039581,
      0.05930647626519203,
      0.036059632897377014,
      0.03512491658329964,
      -0.017790192738175392,
      0.012850544415414333,
      -0.015223320573568344,
      0.006931745447218418,
      -0.028333067893981934,
      0.05316445231437683,
      0.07257950305938721,
      0.051157254725694656,
      0.07167460769414902,
      -0.009797041304409504,
      0.0010765055194497108,
      0.0449942946434021,
      -0.010825293138623238,
      0.00495131453499198,
      0.045964885503053665,
      -0.05596515163779259,
      0.06486049294471741,
      -0.02187897451221943,
      0.00187210354488343,
      -0.04952143132686615,
      -0.05547109246253967,
      -0.01156397070735693,
      -0.014609770849347115,
      0.04455354064702988,
      -0.0023397619370371103,
      0.048766352236270905,
      -0.05867476388812065,
      0.07499448210000992,
      0.00461575435474515,
      0.12038934230804443,
      -0.03638504818081856,
      -0.028879500925540924,
      0.13865016400814056,
      0.018941741436719894,
      0.017420098185539246,
      -0.002306640148162842,
      0.03961022570729256,
      -0.043727174401283264,
      -0.03560904785990715,
      -0.014864985831081867,
      -0.06987106055021286,
      -0.030299551784992218,
      0.0648137703537941,
      -0.02654149942100048,
      0.019655587151646614,
      0.003965029492974281,
      -0.016725799068808556,
      -0.010266917757689953,
      -0.03103885054588318,
      -0.060867857187986374,
      0.03455527871847153,
      0.05839845538139343,
      0.07208684831857681,
      -0.04911070317029953,
      0.10814264416694641,
      0.08377494663000107,
      0.011667609214782715,
      0.018661972135305405,
      -0.029824692755937576,
      0.00955366250127554,
      0.02934272401034832,
      -0.050406571477651596,
      -0.04811796173453331,
      0.034669287502765656,
      0.04425153508782387,
      -0.024532319977879524,
      0.07447005063295364,
      0.0018696861807256937,
      -0.005560177844017744,
      -0.0111079765483737,
      -0.03014150634407997,
      -0.018347417935729027,
      -0.09777180105447769,
      0.02459259144961834,
      -0.002622694941237569,
      -0.06627162545919418,
      0.009812367148697376,
      0.0018325095297768712,
      -0.003032331122085452,
      -0.07447759807109833,
      -0.08893363177776337,
      0.11606764048337936,
      0.01258974801748991,
      0.029909461736679077,
      0.013007717207074165,
      0.04869415983557701,
      -0.0256880521774292,
      0.002603560918942094,
      -0.07094764709472656,
      0.021852873265743256,
      0.060168925672769547,
      0.01059752143919468,
      -0.04511302337050438,
      0.013127134181559086,
      0.06642049551010132,
      0.03002198226749897,
      -0.001321959076449275,
      -0.02336636185646057,
      -0.023732852190732956,
      -0.05694304034113884,
      0.023240413516759872,
      -0.0028633878100663424,
      0.07231002300977707,
      -0.012487550266087055,
      -0.0038492269814014435,
      -0.05494247376918793,
      0.012111997231841087,
      -0.017284318804740906,
      -0.13362570106983185,
      -0.05982652306556702,
      0.031880542635917664,
      0.06455443799495697,
      0.04076400399208069,
      -0.01290101371705532,
      -0.06269731372594833,
      0.023169584572315216,
      -0.02430994063615799,
      0.018126294016838074,
      0.027971532195806503,
      -0.04606170207262039,
      -0.006410062313079834,
      0.020536812022328377,
      -0.00442348001524806,
      0.07540564239025116,
      0.016598666086792946,
      -0.05790575221180916,
      -0.037071216851472855,
      -0.005156788043677807,
      0.03590927645564079,
      0.053014181554317474,
      -0.009477409534156322,
      -0.017722411081194878,
      -0.010831431485712528,
      -0.004662083927541971,
      0.061456989496946335,
      0.01617814041674137,
      0.04521465301513672,
      -0.03303897753357887,
      0.022968091070652008,
      -0.01738062873482704,
      0.029767202213406563,
      0.08207469433546066,
      -0.0017516750376671553,
      -0.03384403884410858,
      -0.04231482744216919,
      -0.05591600760817528,
      -0.03403132036328316,
      -0.041362643241882324,
      0.08222998678684235,
      0.060165539383888245,
      -0.01179328840225935,
      -0.02563159540295601,
      -0.014911562204360962,
      0.0576806403696537,
      -0.04285333305597305,
      0.011855602264404297,
      0.004081860650330782,
      0.021629100665450096,
      -0.000853849807754159,
      -0.011655380949378014,
      0.006455290596932173,
      -0.041992973536252975,
      0.002254180144518614,
      0.020715810358524323,
      -0.002788977464661002,
      -0.005899192299693823,
      0.05559429153800011,
      -0.0016100991051644087,
      -0.007835298776626587,
      0.010990247130393982,
      -0.030413424596190453,
      0.07750895619392395,
      -0.021160049363970757,
      0.05204581841826439,
      0.01192723773419857,
      0.027343032881617546,
      -0.0076380446553230286,
      -0.02535976842045784
    ],
    [
      -0.004261992406100035,
      -0.017930803820490837,
      -0.03831018880009651,
      0.014994882047176361,
      -0.0208799596875906,
      -0.04502170905470848,
      -0.08714142441749573,
      0.027910657227039337,
      -0.004015765618532896,
      0.00046423356980085373,
      -0.03544078394770622,
      0.003415503539144993,
      0.0015862383879721165,
      0.05528157204389572,
      0.04435938596725464,
      -0.059514518827199936,
      -0.041282717138528824,
      -0.03527804836630821,
      -0.02252553589642048,
      -0.04137203469872475,
      -0.002636885503306985,
      -0.04051613062620163,
      -0.04282573238015175,
      0.009196671657264233,
      -0.004232889041304588,
      -0.04034757614135742,
      -0.0004674817610066384,
      -0.08568603545427322,
      -0.05599318817257881,
      0.0681149810552597,
      -0.09544090181589127,
      -0.008943856693804264,
      -0.0031462297774851322,
      0.007784548681229353,
      0.05400491878390312,
      -0.002064504660665989,
      0.02711794711649418,
      -0.05398847535252571,
      0.09756936132907867,
      0.0937773734331131,
      0.05463704466819763,
      0.0019522258080542088,
      0.021461786702275276,
      0.031521353870630264,
      -0.044722188264131546,
      -0.037182681262493134,
      -0.042290400713682175,
      0.020656540989875793,
      0.0018635117448866367,
      0.025975482538342476,
      -0.005269279703497887,
      0.039859674870967865,
      -0.017242738977074623,
      0.030667556449770927,
      0.0016901235794648528,
      -0.038522616028785706,
      0.049524448812007904,
      -0.01464170590043068,
      -0.037421852350234985,
      0.032636843621730804,
      0.011529005132615566,
      0.023524755612015724,
      0.09033191204071045,
      -0.05427420884370804,
      0.039088331162929535,
      0.022135527804493904,
      0.11038094758987427,
      0.14261995255947113,
      -0.001095530460588634,
      0.0724044069647789,
      -0.11306779086589813,
      -0.014832320623099804,
      -0.03295738995075226,
      0.006024907808750868,
      -0.009967422112822533,
      -0.04465656355023384,
      0.03379298746585846,
      0.07020566612482071,
      0.07116831094026566,
      -0.018356498330831528,
      -0.01853453554213047,
      -0.04862982779741287,
      0.0031700176186859608,
      -0.025472264736890793,
      0.059736013412475586,
      -0.007937734946608543,
      0.004551997408270836,
      0.01692737266421318,
      -0.08277031779289246,
      0.04950669780373573,
      0.019114751368761063,
      -0.023301541805267334,
      0.0552329458296299,
      0.033375393599271774,
      0.0389067716896534,
      0.019003259018063545,
      0.031917426735162735,
      0.015792062506079674,
      0.07760366052389145,
      0.06937609612941742,
      0.015647824853658676,
      0.011126500554382801,
      -0.05238869786262512,
      -0.029621511697769165,
      -0.03839127719402313,
      0.04258843883872032,
      -0.033022020012140274,
      -0.009539834223687649,
      -0.03879324346780777,
      -0.020785318687558174,
      0.018129318952560425,
      -0.005273743532598019,
      -0.006448392290621996,
      0.04744039475917816,
      -0.03263712301850319,
      -0.054048385471105576,
      -0.019756117835640907,
      -0.0607929565012455,
      -0.04587208852171898,
      -0.0793401226401329,
      0.01258157379925251,
      -0.03807913511991501,
      0.053591325879096985,
      -0.006847096607089043,
      0.005167202092707157,
      -0.02824842371046543,
      -0.014140370301902294,
      0.025090660899877548,
      0.08122053742408752,
      -0.047043316066265106,
      -0.009139573201537132,
      -0.06201796978712082,
      0.02521246112883091,
      -0.00801822915673256,
      0.01613478548824787,
      -0.07774783670902252,
      0.004602756816893816,
      0.026781849563121796,
      -0.033669035881757736,
      -0.0980115607380867,
      -0.053690552711486816,
      -0.027608294039964676,
      -0.012989289127290249,
      -0.05267561599612236,
      -0.0207024198025465,
      0.06768417358398438,
      -0.009501456283032894,
      -0.0016858556773513556,
      -0.008333376608788967,
      -0.04831536486744881,
      -0.04001270979642868,
      -0.05035213381052017,
      0.0347273051738739,
      -0.00566417071968317,
      -0.009931361302733421,
      -0.09872305393218994,
      0.030453722923994064,
      -0.021893063560128212,
      0.024040836840867996,
      -0.040952734649181366,
      0.12779369950294495,
      -0.009799044579267502,
      -0.0017593252705410123,
      0.05759255588054657,
      -0.020487254485487938,
      0.017593933269381523,
      -0.000325472530676052,
      0.0024183224886655807,
      0.027158716693520546,
      0.03525620698928833,
      -0.01564282551407814,
      -0.09038767963647842,
      0.0741262286901474,
      0.0015624951338395476,
      0.04195600003004074,
      0.05745486915111542,
      0.004373165313154459,
      -0.009347883053123951,
      0.0283983051776886,
      -0.05690910294651985,
      -0.0222888495773077,
      0.08444765210151672,
      -0.1158980205655098,
      0.0438651479780674,
      0.0036147721111774445,
      -0.05906350165605545,
      0.006774366367608309,
      -0.03705544024705887,
      0.010684504173696041,
      0.034498609602451324,
      0.06940414011478424,
      -0.04720628634095192,
      0.06148114427924156,
      0.021184230223298073,
      -0.056129708886146545,
      0.02166440151631832,
      -0.04543747007846832,
      0.03829466179013252,
      0.009740198962390423,
      -0.042450278997421265,
      -0.00163162499666214,
      -0.02139998972415924,
      -0.06951883435249329,
      0.005435371771454811,
      0.05836833640933037,
      -0.06491458415985107,
      0.01132742315530777,
      0.012520956806838512,
      -0.06674221158027649,
      -0.03549869731068611,
      -0.01651703380048275,
      0.025592107325792313,
      -0.06277567148208618,
      -0.006811411119997501,
      -0.04225033149123192,
      -0.0276330653578043,
      -0.049282342195510864,
      0.025890516117215157,
      0.038345202803611755,
      0.0024681955110281706,
      -0.040830016136169434,
      0.07562185078859329,
      0.10516560822725296,
      0.053146179765462875,
      0.021576134487986565,
      -0.009785274975001812,
      0.06160673126578331,
      -0.01985887996852398,
      0.024437135085463524,
      0.029478931799530983,
      0.015685616061091423,
      -0.02031472511589527,
      0.010209625586867332,
      -0.020288338884711266,
      0.023046497255563736,
      -0.08022809773683548,
      -0.05271003395318985,
      -0.0583319291472435,
      -0.0655691921710968,
      0.003450124291703105,
      0.018561456352472305,
      0.028593702241778374,
      -0.0875769555568695,
      0.029679102823138237,
      0.045115843415260315,
      -0.012195614166557789,
      -0.00292838248424232,
      0.05593113973736763,
      -0.12014436721801758,
      -0.08357206732034683,
      -0.016510166227817535,
      0.009712869301438332,
      0.012518725357949734,
      -0.03532722592353821,
      0.07024736702442169,
      0.012808266095817089,
      0.006576867774128914,
      0.0008039075182750821,
      -0.09190648794174194,
      0.04774465039372444,
      0.04277331754565239,
      -0.018823307007551193,
      0.04796626418828964,
      0.06288699805736542,
      0.011512307450175285,
      -0.05409042537212372,
      -0.026754284277558327,
      0.013200060464441776,
      -0.10981528460979462,
      -0.0006921878666616976,
      0.006480169948190451,
      0.000746504170820117,
      0.05165835842490196,
      -0.029352739453315735,
      0.011162107810378075,
      0.014666647650301456,
      -0.011004045605659485,
      -0.03819498047232628,
      -0.023974712938070297,
      0.012322637252509594,
      0.030615322291851044,
      0.01689845696091652,
      -0.0380338691174984,
      0.0714101642370224,
      -0.07243961840867996,
      0.02966945618391037,
      -0.002442851196974516,
      -0.02903519943356514,
      0.007833311334252357,
      0.09412308782339096,
      0.09030326455831528,
      0.008058382198214531,
      0.028740843757987022,
      -0.003922451753169298,
      -0.03605491295456886,
      -0.03747345879673958,
      -0.029444484040141106,
      0.07801524549722672,
      -0.007779484149068594,
      -0.04926450550556183,
      0.0399508960545063,
      0.0573437362909317,
      0.0610877126455307,
      0.019578946754336357,
      -0.0010783850448206067,
      -0.051020748913288116,
      -0.04928029701113701,
      -0.029809053987264633,
      0.05674576014280319,
      -0.019597146660089493,
      -0.04033403471112251,
      -0.025359174236655235,
      -0.044470954686403275,
      0.018241705372929573,
      -0.023490846157073975,
      -0.019902639091014862,
      0.011270786635577679,
      -0.09775727242231369,
      -0.11322898417711258,
      -0.019269749522209167,
      -0.0009800257394090295,
      0.029754018411040306,
      -0.0899524837732315,
      0.019262459129095078,
      0.008200873620808125,
      -0.03768300637602806,
      0.03501855581998825,
      -0.02785610593855381,
      -0.054608054459095,
      -0.07967187464237213,
      -0.05367695540189743,
      0.07463343441486359,
      0.01631777361035347,
      -0.028810657560825348,
      0.04089772328734398,
      0.09307556599378586,
      -0.0789014995098114,
      -0.0674496665596962,
      -0.04508686438202858,
      -0.0761985182762146,
      -0.0072611854411661625,
      -0.04342389851808548,
      -0.007216382306069136,
      0.1156296357512474,
      0.02587246149778366,
      -0.06957828253507614,
      -0.003745798487216234,
      0.02168177254498005,
      -0.052426423877477646,
      0.0657365694642067,
      0.06264420598745346,
      -0.008857899345457554,
      -0.08226414024829865,
      -0.0241584200412035,
      -0.0767594650387764,
      0.05731058865785599,
      -0.0008540170965716243,
      -0.029157565906643867,
      0.10722209513187408,
      0.04568950831890106,
      -0.03741110488772392,
      -0.03090568631887436,
      0.029312189668416977,
      -0.009150096215307713,
      -0.03920196741819382,
      -0.0440656878054142,
      -0.0059728785417973995,
      0.02720036171376705,
      0.05399175360798836,
      -0.04237159714102745,
      -0.0862775519490242,
      -0.015076275914907455,
      0.06753183901309967,
      0.02358255721628666,
      -0.044895756989717484,
      -0.02973344922065735,
      0.03270511329174042,
      0.09909277409315109,
      -0.002174987457692623,
      0.07065051048994064,
      -0.05521662160754204,
      -0.009110874496400356,
      -0.026890335604548454,
      0.101061150431633,
      0.013658180832862854,
      0.04628363251686096,
      -0.01819847896695137,
      -0.04188591614365578,
      -0.019584277644753456,
      -0.02423691190779209,
      -0.03651796653866768,
      0.0167195126414299,
      -0.07464468479156494,
      -0.004795391578227282,
      0.02785556949675083,
      -0.05329917371273041,
      0.05954371765255928,
      -0.00965049397200346,
      -0.03167666494846344,
      -0.056200724095106125,
      -0.058314964175224304,
      -0.015468739904463291,
      0.10008356720209122,
      -0.0033652379643172026,
      0.034541722387075424,
      -0.032666340470314026,
      -0.012975000776350498,
      0.07141894847154617,
      -0.010230045765638351,
      0.0014784071827307343,
      -0.0027880908455699682,
      -0.011579793877899647,
      0.010798878036439419,
      0.013022569008171558,
      0.08419574797153473,
      0.04187138006091118,
      0.014383808709681034,
      0.05211522430181503,
      0.014916066080331802,
      -0.02536839246749878,
      0.01864529773592949,
      0.027057908475399017,
      -0.023910295218229294,
      -0.010803299956023693,
      0.021533343940973282,
      -0.03842718154191971,
      -0.043022047728300095,
      -0.02309584431350231,
      -0.03531597554683685,
      0.003434621263295412,
      0.004463865887373686,
      0.029655830934643745,
      0.0008761087665334344,
      0.01973932608962059,
      0.04671685770153999,
      -0.03970960155129433,
      0.04027404263615608,
      -0.052668437361717224,
      0.013667674735188484,
      -0.05950987711548805,
      0.07692328095436096,
      0.04239663854241371,
      -0.012834050692617893,
      0.05773083120584488,
      0.00838190782815218,
      0.027948971837759018,
      -0.028946436941623688,
      -0.07477447390556335,
      0.049850478768348694,
      -0.08292931318283081,
      0.03822760656476021,
      0.05184478685259819,
      -0.07970575243234634,
      0.11417745053768158,
      -0.028977932408452034,
      -0.017556490376591682,
      -0.05129851773381233,
      -0.004197854548692703,
      0.014913949184119701,
      -0.005410441197454929,
      -0.019165556877851486,
      0.039201196283102036,
      -0.01204268541187048,
      -0.06815655529499054,
      0.007845684885978699,
      -0.0691516175866127,
      0.005336209665983915,
      0.05160846561193466,
      -0.025683678686618805,
      0.07339344918727875,
      0.0010247888276353478,
      0.04248107969760895,
      0.007824622094631195,
      -0.026194550096988678,
      -0.05837060511112213,
      -0.028168989345431328,
      0.0841214656829834,
      -0.009252144023776054,
      -0.00013265275629237294,
      -0.032196708023548126,
      -0.014525938779115677,
      -0.00451500853523612,
      -0.063145212829113,
      -0.001911500352434814,
      0.08336134999990463,
      -0.03733435645699501,
      -0.01601177640259266,
      -0.05128943920135498,
      -0.006649744231253862,
      0.041629984974861145,
      -0.01431070826947689,
      0.017541762441396713,
      0.02857796661555767,
      -0.009390254504978657,
      -0.07055043429136276,
      -0.034247808158397675,
      -0.008602457121014595,
      0.04143350198864937,
      -0.005071315448731184,
      -0.03059607371687889,
      0.04827659949660301,
      0.0709974393248558,
      -0.06267363578081131
    ],
    [
      -0.04361175373196602,
      -0.045270953327417374,
      -0.03673598915338516,
      -0.0022616798523813486,
      -0.05633161589503288,
      0.07004307210445404,
      -0.05280907452106476,
      -0.02241966873407364,
      -0.11945097893476486,
      0.059736259281635284,
      -0.04847453162074089,
      -0.012922939844429493,
      0.0757131353020668,
      0.022031957283616066,
      0.022335797548294067,
      0.028010984882712364,
      -0.09401772171258926,
      0.050859484821558,
      0.019532358273863792,
      0.032294344156980515,
      0.010747941210865974,
      0.0038152325432747602,
      -0.014180608093738556,
      -0.005820688791573048,
      0.03303002193570137,
      0.04298306629061699,
      0.0001922553201438859,
      -0.13639704883098602,
      0.0280099306255579,
      0.03185177966952324,
      -0.03299574553966522,
      -0.029981259256601334,
      0.00988125428557396,
      -0.0191282220184803,
      0.050309449434280396,
      -0.015401504002511501,
      -0.032676566392183304,
      -0.02103140391409397,
      -0.04411771148443222,
      0.007471411023288965,
      -0.04596519097685814,
      0.0360187403857708,
      -0.07322417944669724,
      0.04772454872727394,
      -0.014537092298269272,
      -0.02492835931479931,
      0.08037368953227997,
      -0.00293735577724874,
      0.01729772612452507,
      0.03433983772993088,
      -0.028613470494747162,
      -0.05984579771757126,
      -0.0748758539557457,
      -0.03177627548575401,
      0.02409101091325283,
      0.001960210269317031,
      -0.03995678946375847,
      0.01324507500976324,
      -0.04566564783453941,
      0.04790294170379639,
      0.024673746898770332,
      -0.0006961475010029972,
      0.008731651119887829,
      0.020564699545502663,
      0.015696626156568527,
      0.05679505318403244,
      0.07327982038259506,
      -0.007622665259987116,
      -0.05527028813958168,
      0.036435194313526154,
      0.0012930978555232286,
      0.03819892555475235,
      -0.030173523351550102,
      -0.07481436431407928,
      -0.01393185742199421,
      0.026143399998545647,
      -0.0402606800198555,
      -0.02602485567331314,
      0.03493193909525871,
      -0.008586484007537365,
      -0.0037512630224227905,
      -0.04529745131731033,
      0.002631107345223427,
      0.09550768882036209,
      -0.09103624522686005,
      -0.029229989275336266,
      -0.01683449186384678,
      0.06667525321245193,
      -0.05095358565449715,
      -0.006927304435521364,
      0.002196594374254346,
      -0.06474507600069046,
      -0.01452944241464138,
      -0.014629671350121498,
      0.00545325456187129,
      -0.11718381196260452,
      0.006134250666946173,
      -0.00965559110045433,
      0.06193247437477112,
      -0.01518779806792736,
      -0.07481135427951813,
      0.014439131133258343,
      -0.05952977389097214,
      -0.10417153686285019,
      -0.04124986380338669,
      -0.02289230190217495,
      -0.08881623297929764,
      -0.030406618490815163,
      -0.02903776802122593,
      -0.015077721327543259,
      -0.022561514750123024,
      -0.07245680689811707,
      -0.10054586827754974,
      -0.057724952697753906,
      -0.02966010943055153,
      0.0002350321301491931,
      0.03736305236816406,
      -0.06994008272886276,
      -0.04864971339702606,
      0.022739186882972717,
      -0.1270078867673874,
      -0.046561069786548615,
      0.01908760517835617,
      -0.017835861071944237,
      -0.004768798593431711,
      -0.0010220916010439396,
      0.05379466712474823,
      0.03675615414977074,
      0.061027780175209045,
      -0.06707872450351715,
      0.019306691363453865,
      0.014480335637927055,
      -0.04600559175014496,
      0.017424853518605232,
      -0.001795461168512702,
      -0.04279887676239014,
      0.04676060006022453,
      -0.043190404772758484,
      -0.05077584460377693,
      -0.021729132160544395,
      -0.018842894583940506,
      -0.06283728033304214,
      -0.003975990228354931,
      0.05076585337519646,
      -0.048345260322093964,
      0.033017728477716446,
      0.018901944160461426,
      0.017702920362353325,
      0.030338697135448456,
      -0.043232426047325134,
      -0.14772281050682068,
      0.020087135955691338,
      0.09461243450641632,
      -0.05541211739182472,
      -0.008884820155799389,
      0.005315134301781654,
      0.03703855723142624,
      0.0237461868673563,
      -0.04142402112483978,
      -0.01826019398868084,
      0.008550573140382767,
      0.019348859786987305,
      -0.025998856872320175,
      0.060247279703617096,
      -0.0025817949790507555,
      0.04065253585577011,
      -0.07230420410633087,
      0.021048367023468018,
      -0.016501907259225845,
      -0.010108143091201782,
      0.0011299059260636568,
      0.008766429498791695,
      0.028521452099084854,
      -0.06578544527292252,
      0.05115971714258194,
      -0.004402208607643843,
      -0.061583012342453,
      -0.03336920216679573,
      0.003251497633755207,
      0.050233226269483566,
      0.004478036891669035,
      -0.03280257061123848,
      -0.028150469064712524,
      0.03468509390950203,
      0.057585351169109344,
      0.08702531456947327,
      0.002012476325035095,
      -0.03980868682265282,
      -0.041164983063936234,
      -0.12333106249570847,
      -0.017623037099838257,
      -0.0056099784560501575,
      0.02318899892270565,
      -0.00015216096653603017,
      0.008166485466063023,
      0.04426414892077446,
      -0.048096008598804474,
      0.023427428677678108,
      -0.021890604868531227,
      -0.06184850260615349,
      0.10741661489009857,
      0.05056452378630638,
      -0.013790086843073368,
      0.0353771448135376,
      0.032738856971263885,
      0.02454085648059845,
      -0.061015039682388306,
      -0.028590867295861244,
      0.013316495344042778,
      0.04310483857989311,
      0.0389283187687397,
      0.001263102749362588,
      -0.02672981284558773,
      -0.02646501176059246,
      0.0393681600689888,
      0.00724019156768918,
      -0.08185242861509323,
      0.08373726159334183,
      -0.0309581495821476,
      -0.04486799240112305,
      -0.04626932367682457,
      -0.016457563266158104,
      0.02529142051935196,
      0.014313539490103722,
      0.04637449234724045,
      -0.07853570580482483,
      -0.0027714914176613092,
      0.08352459222078323,
      0.006198203191161156,
      -0.011709938757121563,
      -0.07071340084075928,
      0.09113583713769913,
      0.011168735101819038,
      -0.08124406635761261,
      -0.019625499844551086,
      -0.04434803128242493,
      -0.0030598037410527468,
      -0.03859176114201546,
      0.02928469516336918,
      0.010482186451554298,
      0.0032273049000650644,
      -0.04961688071489334,
      -0.012208976782858372,
      0.003990558907389641,
      0.01651647686958313,
      -0.06602738052606583,
      0.03487048298120499,
      -0.09416540712118149,
      0.04344936087727547,
      -0.08020373433828354,
      -0.10278403013944626,
      0.05716479942202568,
      -0.010273195803165436,
      -0.02322930470108986,
      -0.08115801960229874,
      0.0464651882648468,
      0.034460872411727905,
      0.03609172999858856,
      -0.033656202256679535,
      -0.04190296679735184,
      0.06486088037490845,
      -0.02284669131040573,
      -0.005108432378619909,
      0.02552632987499237,
      -0.040444646030664444,
      0.03233904018998146,
      0.019724661484360695,
      -0.025790343061089516,
      -0.031012816354632378,
      -0.06580368429422379,
      0.012469025328755379,
      0.008844817988574505,
      -0.02945079654455185,
      0.09146314114332199,
      -0.03950289264321327,
      -0.08461231738328934,
      0.002356323879212141,
      -0.0072091068141162395,
      -0.07601599395275116,
      0.04557781293988228,
      0.041148968040943146,
      0.007921736687421799,
      0.04405926540493965,
      -0.04430568590760231,
      -0.01994413696229458,
      -0.028206808492541313,
      -0.06933566927909851,
      -0.052433256059885025,
      -0.033747635781764984,
      0.015427122823894024,
      0.005359744653105736,
      -0.04462476074695587,
      -0.018716249614953995,
      -0.04503435641527176,
      -0.011586704291403294,
      -0.0045334342867136,
      0.010825350880622864,
      -0.041043780744075775,
      -0.038242243230342865,
      -0.02714330144226551,
      -0.024799548089504242,
      -0.12817847728729248,
      0.044948164373636246,
      0.058446988463401794,
      0.019786231219768524,
      0.0651618093252182,
      0.01230211928486824,
      -0.018404657021164894,
      0.10526973009109497,
      -0.0015878680860623717,
      0.023211589083075523,
      -0.03997855633497238,
      0.043588995933532715,
      -0.022199707105755806,
      0.06243079900741577,
      -0.08377707749605179,
      -0.06363222748041153,
      -0.038043104112148285,
      -0.06715957075357437,
      0.03823525458574295,
      0.01881006360054016,
      0.035621099174022675,
      0.006957697682082653,
      -0.010193334892392159,
      0.12086427956819534,
      -0.012180555611848831,
      0.0960044339299202,
      -0.001723583322018385,
      0.025598665699362755,
      0.01951528899371624,
      -0.009896989911794662,
      -0.008598589338362217,
      -0.024963293224573135,
      0.06230836734175682,
      0.07093453407287598,
      0.0035654152743518353,
      0.059315118938684464,
      0.06940498948097229,
      -0.013050061650574207,
      -0.05157444253563881,
      0.01893913559615612,
      -0.0049089230597019196,
      -0.007181996013969183,
      0.03420558199286461,
      -0.015601280145347118,
      -0.027513345703482628,
      -0.06370630860328674,
      -0.08480679988861084,
      0.010466692969202995,
      0.046357084065675735,
      0.06930100917816162,
      -0.047406937927007675,
      -0.026862815022468567,
      -0.04109795391559601,
      0.05618293210864067,
      -0.007275427225977182,
      0.0056632100604474545,
      0.008500280790030956,
      0.056171298027038574,
      0.006927065085619688,
      -0.05554049834609032,
      -0.01636386476457119,
      -0.04374045506119728,
      0.006085527129471302,
      0.00886362511664629,
      -0.023462221026420593,
      0.0023948773741722107,
      0.047470010817050934,
      -0.03912578150629997,
      0.0015624143416061997,
      0.04346583038568497,
      -0.005277637392282486,
      -0.06457474827766418,
      -0.05659329146146774,
      -0.012513835914433002,
      0.06603746116161346,
      0.011396310292184353,
      0.005859018303453922,
      0.005283654201775789,
      -0.026859913021326065,
      -0.02464335225522518,
      -0.03911091759800911,
      -0.10310536623001099,
      0.06497923284769058,
      0.1544111967086792,
      0.02858126349747181,
      -0.05101880803704262,
      0.058777909725904465,
      -0.07562371343374252,
      -0.07348554581403732,
      -0.040254682302474976,
      0.013918230310082436,
      0.04871085658669472,
      -0.03299354761838913,
      0.0010747710475698113,
      0.028195783495903015,
      0.0036250536795705557,
      0.020202219486236572,
      0.06447824835777283,
      -0.014512226916849613,
      -0.03019997850060463,
      0.0004933155723847449,
      0.0012007486075162888,
      -0.00755212502554059,
      0.016103629022836685,
      0.012909775599837303,
      -0.03790050745010376,
      -0.012294700369238853,
      0.023297546431422234,
      -0.06435982882976532,
      -0.07214204221963882,
      -0.07052363455295563,
      -0.07277630269527435,
      -0.015614423900842667,
      -0.09430456161499023,
      0.05134059488773346,
      0.0722019225358963,
      -0.041768502444028854,
      0.03153334930539131,
      -4.6297085646074265e-05,
      0.03962500020861626,
      0.044038351625204086,
      -0.029660137370228767,
      -0.032832637429237366,
      0.013515145517885685,
      -0.04367408528923988,
      -0.0421268530189991,
      -0.006561345886439085,
      0.0014407477574422956,
      0.02784763090312481,
      0.05407547205686569,
      0.02296365052461624,
      -0.05484530329704285,
      0.024828501045703888,
      -0.07489188760519028,
      0.0006326176808215678,
      0.021637722849845886,
      -0.050364408642053604,
      0.026741422712802887,
      0.07547307014465332,
      0.05982464179396629,
      0.013264518231153488,
      0.07100732624530792,
      0.10929637402296066,
      -0.02175474353134632,
      -0.05393289402127266,
      0.0018079556757584214,
      0.03266509994864464,
      0.01888003759086132,
      -0.045072369277477264,
      -0.02276945859193802,
      -0.01748388633131981,
      -0.03846939653158188,
      0.04573668912053108,
      -0.007537935394793749,
      0.0035427208058536053,
      0.010217877104878426,
      -0.0019911264535039663,
      0.033580780029296875,
      -0.009639685042202473,
      0.05148199200630188,
      -0.044880788773298264,
      0.021053336560726166,
      0.022606883198022842,
      0.011845085769891739,
      -0.02231922745704651,
      -0.0003319566312711686,
      -0.004571767523884773,
      -0.030100321397185326,
      -0.061946235597133636,
      0.02025815285742283,
      0.07037804275751114,
      -0.039546385407447815,
      0.023585621267557144,
      -0.0216189194470644,
      -0.013320677913725376,
      -0.05721919611096382,
      -0.02425815723836422,
      -0.04987702891230583,
      -0.06257361173629761,
      0.055516790598630905,
      0.011963923461735249,
      -0.04538480564951897,
      -0.03490298613905907,
      0.01099216379225254,
      -0.0746164470911026,
      0.0008676360594108701,
      -0.04448826238512993,
      0.10730334371328354,
      -0.04253605008125305,
      -0.016406189650297165,
      0.002504655858501792,
      -0.13842029869556427,
      0.07294242829084396,
      -0.0674850195646286,
      -0.0747661367058754,
      0.007826197892427444,
      -0.013259493745863438,
      -0.0012133666314184666,
      -0.03861989080905914,
      -0.05049797147512436,
      -0.01103685237467289,
      0.023276351392269135
    ],
    [
      0.01153052132576704,
      0.07150814682245255,
      -0.04960373789072037,
      -0.031690437346696854,
      -0.0011433166218921542,
      -0.042502932250499725,
      -0.051772985607385635,
      0.0013111636508256197,
      -0.09222528338432312,
      0.03672678768634796,
      0.02672676555812359,
      -0.00344671169295907,
      -0.05439967289566994,
      -0.008961564861238003,
      -0.03584590181708336,
      -0.013223407790064812,
      0.01804850995540619,
      0.006855455692857504,
      0.06288488209247589,
      -0.0401555635035038,
      0.05993198975920677,
      -0.008736055344343185,
      -0.0014902878319844604,
      0.0545068085193634,
      0.08638090640306473,
      0.0423496775329113,
      -0.009070741012692451,
      -0.05603204295039177,
      -0.03561718389391899,
      0.04577675834298134,
      -0.03383975476026535,
      0.031075723469257355,
      0.06506866961717606,
      0.06311012804508209,
      0.04226871207356453,
      -0.02604871429502964,
      0.07072839140892029,
      -0.010176483541727066,
      -0.0545789897441864,
      -0.009660275653004646,
      0.026134241372346878,
      -0.04647265374660492,
      0.01975439116358757,
      -0.050562288612127304,
      0.00940091535449028,
      -0.05578893795609474,
      0.05234969034790993,
      0.03946838900446892,
      -0.013353941962122917,
      -0.0021498322021216154,
      -0.0675371065735817,
      0.015869708731770515,
      -0.07382678985595703,
      0.073316290974617,
      0.12659066915512085,
      0.0589793361723423,
      0.002197739901021123,
      0.03059096820652485,
      -0.021922197192907333,
      0.0878758430480957,
      -0.01627446338534355,
      -0.06222943589091301,
      -0.05703872814774513,
      0.02900962345302105,
      -0.03778965771198273,
      0.039281778037548065,
      -0.009460287168622017,
      0.03835611045360565,
      0.09681789577007294,
      -0.008292592130601406,
      0.04454319551587105,
      0.06180056184530258,
      -0.041322749108076096,
      -0.009233859367668629,
      -0.03286396339535713,
      0.01106980349868536,
      -0.014021222479641438,
      -0.02338193543255329,
      -0.005057245027273893,
      -0.042255137115716934,
      -0.019575444981455803,
      0.028669724240899086,
      0.05293900892138481,
      0.005968428682535887,
      -0.022038163617253304,
      -0.0053301528096199036,
      -0.03588679060339928,
      -0.021015718579292297,
      0.030813628807663918,
      0.0013493375154212117,
      -0.023452352732419968,
      0.008713162504136562,
      -0.021845532581210136,
      -0.023516803979873657,
      0.050043441355228424,
      0.0020335146691650152,
      -0.05269891023635864,
      0.05504860728979111,
      0.06644131988286972,
      -0.04616895318031311,
      -0.06682595610618591,
      -0.004452318884432316,
      -0.03129896521568298,
      -0.08432233333587646,
      -0.004846146330237389,
      0.029718853533267975,
      -0.02400893159210682,
      -0.008655894547700882,
      0.0625886470079422,
      -0.08565231412649155,
      0.0025806091725826263,
      0.04219592735171318,
      0.0030639113392680883,
      -0.02728288248181343,
      -0.045314040035009384,
      0.006030185613781214,
      -0.011037001386284828,
      -0.025177082046866417,
      0.06130470335483551,
      0.00047268031630665064,
      -0.015351226553320885,
      -0.08024577051401138,
      0.014662235975265503,
      -0.02067326381802559,
      0.02429654635488987,
      -0.03728079795837402,
      0.005846354179084301,
      -0.008103504776954651,
      0.03937219828367233,
      0.028128154575824738,
      0.012860107235610485,
      -0.06998931616544724,
      -0.03287355229258537,
      0.018723949790000916,
      -0.07324662059545517,
      -0.03452552109956741,
      -0.03272823244333267,
      0.0020246042404323816,
      0.0933912992477417,
      -0.003747830865904689,
      0.04148479923605919,
      -0.07031602412462234,
      0.03200950473546982,
      0.011108392849564552,
      0.028431961312890053,
      -0.017322557047009468,
      -0.002635757904499769,
      0.02494303695857525,
      0.009965714998543262,
      -0.037321027368307114,
      0.07169041037559509,
      0.05587828531861305,
      -0.03426992520689964,
      -0.095732681453228,
      -0.026270408183336258,
      0.011422334238886833,
      0.041338663548231125,
      -0.007260918617248535,
      0.04872242733836174,
      -0.011377879418432713,
      0.059526003897190094,
      0.005150801036506891,
      -0.04405468329787254,
      -0.008137543685734272,
      0.07716091722249985,
      0.014749780297279358,
      0.06535324454307556,
      0.0007744117174297571,
      -0.010507307015359402,
      -0.00957527570426464,
      0.07869000732898712,
      0.05314505472779274,
      0.0042291851714253426,
      0.0452052466571331,
      0.02454068697988987,
      0.02694704942405224,
      -0.009378851391375065,
      0.009900582954287529,
      -0.06184254214167595,
      -0.05437127500772476,
      -0.01678973250091076,
      -0.01794545352458954,
      1.0935676982626319e-05,
      -0.00511739170178771,
      -0.00893468875437975,
      -0.009605550207197666,
      -0.05275776982307434,
      0.0629059225320816,
      -0.031474824994802475,
      0.007889600470662117,
      0.014499513432383537,
      -0.07510045170783997,
      0.0051345727406442165,
      -0.032445814460515976,
      0.03678285703063011,
      -0.023051058873534203,
      0.013980290852487087,
      -0.02197403274476528,
      0.01654796488583088,
      -0.00806000828742981,
      0.036835841834545135,
      0.005116429179906845,
      0.01234402321279049,
      -0.03053172118961811,
      0.06696804612874985,
      -0.018183942884206772,
      -0.013499937951564789,
      0.013512433506548405,
      -0.0697539895772934,
      -0.05513991042971611,
      -0.01426722388714552,
      -0.03101004846394062,
      0.011988415382802486,
      0.025319911539554596,
      0.07887409627437592,
      -0.06406960636377335,
      -0.061095595359802246,
      0.016464605927467346,
      0.029942067340016365,
      0.018337789922952652,
      -0.07854268699884415,
      0.05630163475871086,
      -0.026237724348902702,
      -0.00278540444560349,
      0.01627163775265217,
      -0.06337682902812958,
      -0.0030877890530973673,
      -0.003911860287189484,
      0.06370299309492111,
      -0.005625218618661165,
      -0.07283637672662735,
      -0.03259900212287903,
      0.02321462519466877,
      -0.004179950803518295,
      0.05601729080080986,
      -0.010829879902303219,
      -0.0024516331031918526,
      -0.026937954127788544,
      -0.010126070119440556,
      -0.026507748290896416,
      0.08547752350568771,
      -0.035823918879032135,
      -0.06082410737872124,
      0.025226959958672523,
      0.048496346920728683,
      0.03711727634072304,
      0.07456735521554947,
      -0.018043236806988716,
      0.044010553508996964,
      0.031045323237776756,
      -0.012617426924407482,
      -0.0058455863036215305,
      -0.06028628349304199,
      0.04179838299751282,
      0.04643594101071358,
      0.03849174827337265,
      -0.001401083660311997,
      0.10332877188920975,
      0.023255204781889915,
      -0.0330800898373127,
      -0.01617959886789322,
      -0.11836498230695724,
      -0.0011705755023285747,
      -0.02924290858209133,
      0.05139748007059097,
      0.06197985261678696,
      -0.012760968878865242,
      -0.02755582518875599,
      -0.02937498688697815,
      -0.053133588284254074,
      -0.04220857843756676,
      0.03486526757478714,
      0.008022904396057129,
      -0.00608922028914094,
      -0.05266012251377106,
      -0.0008789481362327933,
      0.0069351219572126865,
      -0.026839518919587135,
      -0.07963737845420837,
      0.08724385499954224,
      0.043795231729745865,
      -0.005785247776657343,
      -0.05264412239193916,
      -0.005302739329636097,
      -0.0603315606713295,
      0.01626998372375965,
      -0.002743457444012165,
      -0.03499991446733475,
      -0.05206291377544403,
      -0.036428894847631454,
      0.07147873938083649,
      -0.016105439513921738,
      -0.02554555982351303,
      -0.013136260211467743,
      0.039597537368535995,
      -0.09999663382768631,
      -0.04311228170990944,
      -0.07157605141401291,
      -0.026254713535308838,
      -0.006332647521048784,
      -0.014507917687296867,
      3.9199549064505845e-05,
      0.006620505359023809,
      0.06283819675445557,
      0.003272894537076354,
      -0.03237375617027283,
      -0.03065464459359646,
      0.02020147629082203,
      -0.05099490284919739,
      0.10452103614807129,
      -0.12735000252723694,
      -0.07466737180948257,
      -0.05814376100897789,
      0.06696595996618271,
      0.011050164699554443,
      0.016243120655417442,
      -0.001166025409474969,
      -0.011805019341409206,
      -0.026937711983919144,
      0.06091417372226715,
      0.04363108426332474,
      -0.06803679466247559,
      0.02055737003684044,
      -0.04249675199389458,
      -0.08084258437156677,
      -0.020340684801340103,
      0.044099111109972,
      -0.029801765456795692,
      -0.02327323518693447,
      -0.02796074002981186,
      -0.05331670492887497,
      -0.03831635043025017,
      -0.055853430181741714,
      0.011661702767014503,
      0.0348295159637928,
      0.04307439178228378,
      0.04295429214835167,
      0.0009683453245088458,
      -0.03913524001836777,
      0.027249913662672043,
      -0.04226287454366684,
      0.06509631127119064,
      0.053254567086696625,
      -0.07260721921920776,
      -0.01582380384206772,
      0.011512391269207,
      -0.004446856211870909,
      0.0832945704460144,
      -0.004481250420212746,
      -0.06491583585739136,
      0.010798387229442596,
      0.038164589554071426,
      0.007082680705934763,
      -0.021013641729950905,
      0.0578204020857811,
      0.03076799027621746,
      0.0916823074221611,
      0.05589951574802399,
      -0.0584348663687706,
      0.048393044620752335,
      -0.015232080593705177,
      0.03795440495014191,
      -0.0022154042962938547,
      0.10037896782159805,
      -0.01076471246778965,
      -0.011478417553007603,
      -0.044475801289081573,
      -0.020253118127584457,
      -0.06752300262451172,
      0.06317096203565598,
      0.03506999835371971,
      -0.016757730394601822,
      0.06733816862106323,
      0.032363247126340866,
      -0.0387125089764595,
      -0.0718684121966362,
      0.017326999455690384,
      -0.02913392148911953,
      -0.021388940513134003,
      -0.031491804867982864,
      -0.030414005741477013,
      -0.04618750885128975,
      0.06198848783969879,
      -0.0734252855181694,
      -0.06056308001279831,
      -0.025957545265555382,
      -0.012161852791905403,
      0.019119970500469208,
      -0.021115833893418312,
      0.008133376017212868,
      -0.03456376492977142,
      0.015368573367595673,
      0.005051297135651112,
      -0.012812006287276745,
      -0.018857423216104507,
      -0.020180877298116684,
      0.06590930372476578,
      -0.09023164212703705,
      -0.000855848949868232,
      0.012379382736980915,
      0.055706705898046494,
      0.06459031254053116,
      0.051320601254701614,
      -0.045164380222558975,
      -0.0741957351565361,
      -0.020454220473766327,
      0.0461835078895092,
      0.056699737906455994,
      -0.05856630951166153,
      -0.028269097208976746,
      -0.01325156632810831,
      0.0007301538717001677,
      -0.013438322581350803,
      -0.027899863198399544,
      -0.017948660999536514,
      0.053093310445547104,
      0.031308144330978394,
      -0.04768746346235275,
      -0.0351395420730114,
      -0.008703160099685192,
      0.005736725404858589,
      0.017563723027706146,
      -0.018681105226278305,
      0.0007725850446149707,
      -0.0015400680713355541,
      0.05085902661085129,
      0.072565458714962,
      -0.07665498554706573,
      0.021091118454933167,
      0.03587556630373001,
      0.07258535176515579,
      0.028966695070266724,
      -0.06652325391769409,
      -0.02450045756995678,
      -0.04529024288058281,
      -0.056774865835905075,
      0.0883706584572792,
      0.0048823729157447815,
      0.05044771358370781,
      0.08621226996183395,
      0.020958226174116135,
      0.045399054884910583,
      0.16276057064533234,
      -0.005614391062408686,
      -0.005868653301149607,
      -0.018211934715509415,
      0.0498364083468914,
      -0.029383309185504913,
      -0.013274500146508217,
      -0.04742010310292244,
      -0.01205204427242279,
      0.022069890052080154,
      0.03334549814462662,
      -0.048990510404109955,
      -0.010648990049958229,
      -0.025959208607673645,
      0.06322964280843735,
      -0.015067268162965775,
      -0.07943260669708252,
      0.0012287665158510208,
      0.041218895465135574,
      0.0738772451877594,
      0.00950375571846962,
      0.01505876611918211,
      -0.029931772500276566,
      -0.02987612970173359,
      -0.03373381122946739,
      0.06663205474615097,
      -0.08053544908761978,
      -0.04912630841135979,
      0.0033267424441874027,
      -0.03237760439515114,
      0.019716709852218628,
      0.04367712885141373,
      -0.04438382387161255,
      -0.037359848618507385,
      0.0015762668335810304,
      0.07874976098537445,
      -0.05483483895659447,
      -0.09633973240852356,
      -0.028478147462010384,
      0.0621049664914608,
      0.08939334005117416,
      -0.01509789377450943,
      0.08953593671321869,
      -0.05075801908969879,
      0.04078539088368416,
      0.0008532079518772662,
      -0.02458317205309868,
      -0.07030446827411652,
      -0.011097465641796589,
      0.017059607431292534,
      0.03472447395324707,
      -0.018178530037403107,
      -0.0011981427669525146,
      0.07314904034137726,
      -0.004454581532627344,
      0.041570670902729034,
      0.0007897787145338953,
      -0.08148927241563797,
      -0.0750989243388176,
      0.024361737072467804,
      -0.06112063676118851
    ],
    [
      0.0458957701921463,
      0.00327502004802227,
      -0.05504544451832771,
      -0.03312220424413681,
      0.03588808327913284,
      -0.007257412187755108,
      -0.009218965657055378,
      0.03938999027013779,
      -0.014566944912075996,
      -0.03288206085562706,
      -0.004007152747362852,
      -0.030148886144161224,
      0.013094997964799404,
      -0.030219165608286858,
      -0.0436389222741127,
      -0.022356977686285973,
      -0.045815203338861465,
      0.026762910187244415,
      0.025466307997703552,
      0.07503007352352142,
      -0.054299768060445786,
      0.024491967633366585,
      0.01046211551874876,
      -0.010482626035809517,
      0.007582756690680981,
      0.030863525345921516,
      -0.044142670929431915,
      -0.10192050784826279,
      0.0688827782869339,
      -0.025309661403298378,
      0.02721763402223587,
      0.0832105427980423,
      -0.020341655239462852,
      -0.02961045503616333,
      0.026640333235263824,
      0.018724685534834862,
      -0.02259199321269989,
      -0.03697051852941513,
      0.07923774421215057,
      0.0493401363492012,
      0.05133993923664093,
      -0.09394283592700958,
      0.013301202096045017,
      0.08584286272525787,
      0.025819044560194016,
      -0.0021176007576286793,
      0.010636231862008572,
      0.009137002751231194,
      -0.00024346887948922813,
      -0.05177553370594978,
      -0.03668009489774704,
      0.02859017439186573,
      -0.016678664833307266,
      -0.01654043048620224,
      -0.027308641001582146,
      -0.030346300452947617,
      0.016017038375139236,
      0.0470670610666275,
      -0.03178706392645836,
      0.012356573715806007,
      0.07305216789245605,
      -0.04609604552388191,
      -0.1030866876244545,
      0.07382205873727798,
      0.07108389586210251,
      -0.007856796495616436,
      -0.04237234219908714,
      -0.005845766048878431,
      0.014868734404444695,
      0.06617746502161026,
      0.0027765589766204357,
      0.07165876030921936,
      0.009533879347145557,
      -0.022265225648880005,
      0.004039549268782139,
      -0.05299265682697296,
      0.007751833647489548,
      -0.09062311053276062,
      0.03084706701338291,
      0.04474429041147232,
      -0.027936266735196114,
      -0.0064895059913396835,
      -0.07984428852796555,
      0.07776527106761932,
      0.04112354665994644,
      -0.04990663006901741,
      0.027293305844068527,
      0.00830539595335722,
      -0.00024041961296461523,
      -0.0024235143791884184,
      0.053354211151599884,
      -0.0027869963087141514,
      -0.014302422292530537,
      0.095671646296978,
      -0.010819626040756702,
      0.013445966877043247,
      0.05236528813838959,
      -0.0019777659326791763,
      0.10642199963331223,
      -0.019587136805057526,
      -0.04657703638076782,
      -0.075722835958004,
      0.029876863583922386,
      -0.07453809678554535,
      -0.050289690494537354,
      0.0418042317032814,
      0.0011690794490277767,
      0.00878732930868864,
      0.05926792696118355,
      0.01533897127956152,
      -0.03539745509624481,
      -0.0982554480433464,
      0.013474672101438046,
      -0.01586311310529709,
      0.05309738218784332,
      0.030231760814785957,
      0.021522922441363335,
      0.05083116516470909,
      -0.052237432450056076,
      -0.04023144766688347,
      -0.022965233772993088,
      0.03663337230682373,
      0.05288335680961609,
      -0.0501096174120903,
      -0.006792447995394468,
      0.013333460316061974,
      0.022454051300883293,
      -0.0625479519367218,
      0.01199682243168354,
      0.02959943749010563,
      -0.015279294922947884,
      0.010378708131611347,
      0.0008957983809523284,
      0.055137526243925095,
      -0.011693963780999184,
      -0.0035879439674317837,
      0.00806814432144165,
      -0.006658511236310005,
      0.06623288989067078,
      0.06036757677793503,
      0.0015827476745471358,
      -0.017954126000404358,
      -0.001510953065007925,
      0.02974339760839939,
      -0.09856650233268738,
      -0.004063716623932123,
      0.07632739841938019,
      0.014828088693320751,
      -0.017263906076550484,
      0.07083770632743835,
      0.004127318039536476,
      0.04711249843239784,
      -0.04898402467370033,
      0.06239921227097511,
      -0.07713699340820312,
      0.11861889809370041,
      -0.00619493005797267,
      -0.004085405729711056,
      0.02764762006700039,
      -0.07602936029434204,
      0.04375496134161949,
      -0.01534871943295002,
      0.05781456083059311,
      0.017447005957365036,
      0.010021994821727276,
      0.02460073120892048,
      -0.047840412706136703,
      -0.02623702585697174,
      0.01050054281949997,
      0.012941144406795502,
      0.028751466423273087,
      0.005517430603504181,
      -0.006887584459036589,
      -0.05731530115008354,
      -0.012167799286544323,
      -0.06364783644676208,
      0.05447341129183769,
      -0.06797315180301666,
      -0.0022075059823691845,
      -0.015644675120711327,
      -0.020157115533947945,
      -0.038172632455825806,
      0.01513773761689663,
      -0.024991026148200035,
      -0.020608752965927124,
      0.03778569772839546,
      0.020952770486474037,
      0.01859556883573532,
      0.017377326264977455,
      0.09453429281711578,
      0.003525234293192625,
      0.0069188447669148445,
      0.030604129657149315,
      0.04462117329239845,
      -0.062194306403398514,
      0.03367191180586815,
      0.010772943496704102,
      -0.0028184796683490276,
      -0.07806959003210068,
      -0.02105860970914364,
      0.026853743940591812,
      -0.022773798555135727,
      -0.010537681169807911,
      0.01926850713789463,
      -0.010477620176970959,
      0.0823068767786026,
      0.023623622953891754,
      0.04768562689423561,
      0.02615424059331417,
      -0.035143863409757614,
      0.023234866559505463,
      -0.0707491934299469,
      -0.016645578667521477,
      -0.022671708837151527,
      0.0047104875557124615,
      -0.013195552863180637,
      -0.04047711193561554,
      -0.07244271039962769,
      -0.002783173928037286,
      -0.02636663056910038,
      -0.05454907938838005,
      -0.024510575458407402,
      -6.189684791024774e-05,
      -0.05940938740968704,
      0.12350191175937653,
      -0.04016616567969322,
      0.0010590152814984322,
      -0.007582452613860369,
      0.05371535196900368,
      -0.06748581677675247,
      0.01915930025279522,
      0.005792331416159868,
      -0.09736087918281555,
      0.010176952928304672,
      0.027662411332130432,
      -0.046924296766519547,
      -0.07086636871099472,
      0.04327806085348129,
      0.03803490102291107,
      0.005513378884643316,
      0.0916820615530014,
      0.011211845092475414,
      -0.04338119924068451,
      -0.08068540692329407,
      -0.022167837247252464,
      -0.07387819141149521,
      0.059333041310310364,
      0.0662786066532135,
      -0.01654609479010105,
      -0.046674713492393494,
      0.01873040571808815,
      0.04056635871529579,
      0.03568241372704506,
      0.07404693216085434,
      0.06946162134408951,
      0.050766561180353165,
      0.09274918586015701,
      -0.06129338592290878,
      0.02214301936328411,
      0.03079109452664852,
      0.017840208485722542,
      -0.081441730260849,
      0.07219083607196808,
      0.03232566639780998,
      0.013182450085878372,
      0.07368583977222443,
      -0.028401924297213554,
      -0.034517452120780945,
      0.013188754208385944,
      -0.0685802549123764,
      0.007969727739691734,
      0.012631264515221119,
      0.012483000755310059,
      -0.0455186553299427,
      -0.044346392154693604,
      0.023201443254947662,
      -0.03698574751615524,
      -0.07405246049165726,
      -0.044640056788921356,
      0.0029259799048304558,
      -0.05689474940299988,
      -0.02053842693567276,
      0.03265148401260376,
      -0.0380387119948864,
      -0.0034563750959932804,
      0.02596762590110302,
      -0.00016478198813274503,
      -0.13005822896957397,
      0.01055194716900587,
      -0.11184576898813248,
      -0.012898161076009274,
      -0.04675900563597679,
      -0.03755243867635727,
      -0.0010153889888897538,
      0.004198161885142326,
      -0.01938203163444996,
      -0.03511478751897812,
      -0.028470365330576897,
      -0.008747139014303684,
      -0.033761899918317795,
      0.0439993292093277,
      0.09629141539335251,
      -0.053251128643751144,
      0.06809044629335403,
      0.004854632541537285,
      -0.039447180926799774,
      -0.09065969288349152,
      -0.01481201034039259,
      -0.01840844191610813,
      -0.034045640379190445,
      0.008720637299120426,
      -0.030477939173579216,
      -0.031359508633613586,
      -0.12292858213186264,
      -0.032218556851148605,
      0.04922085255384445,
      0.0017085806466639042,
      -0.03252454474568367,
      0.03520253673195839,
      -0.04162536934018135,
      -0.023235516622662544,
      -0.05049984157085419,
      0.025820082053542137,
      -0.016478147357702255,
      -0.011072353459894657,
      0.031146420165896416,
      -0.03628268092870712,
      0.09789825975894928,
      0.01854981854557991,
      0.03528369963169098,
      0.05177395045757294,
      -0.029699817299842834,
      0.03008851408958435,
      -0.03236241638660431,
      0.08401176333427429,
      -0.04303857311606407,
      0.026568498462438583,
      -0.036790601909160614,
      -0.009122472256422043,
      -0.1421751081943512,
      -0.054218146950006485,
      -0.037004679441452026,
      -0.024426009505987167,
      -0.07078942656517029,
      -0.05026734620332718,
      -0.048705123364925385,
      -0.0004647868045140058,
      0.00545929279178381,
      0.01836048811674118,
      0.02670762501657009,
      0.08564034104347229,
      0.08066662400960922,
      0.009889953769743443,
      -0.03589364141225815,
      -0.00823860801756382,
      -0.023643193766474724,
      -0.043216582387685776,
      0.04028405249118805,
      0.050059083849191666,
      -0.11735789477825165,
      0.009872673079371452,
      -0.008769836276769638,
      0.03860750421881676,
      0.013896573334932327,
      -0.028093313798308372,
      -0.03991756960749626,
      0.03649912774562836,
      0.001321098068729043,
      0.015245741233229637,
      -0.04859388619661331,
      -0.06025779992341995,
      0.049327149987220764,
      0.004710808862000704,
      0.0274740532040596,
      -0.020811036229133606,
      0.005474599078297615,
      -0.006265160162001848,
      -0.04077120125293732,
      -0.029391197487711906,
      0.008983331732451916,
      0.020578263327479362,
      -0.008694163523614407,
      0.07569871842861176,
      -0.037089716643095016,
      0.015832476317882538,
      -0.02981366030871868,
      0.026161739602684975,
      0.081034354865551,
      0.006216617301106453,
      0.05907140299677849,
      -0.05660983547568321,
      0.05826632305979729,
      -0.10301674157381058,
      -0.061387550085783005,
      0.04190134257078171,
      0.018562249839305878,
      -0.01659930683672428,
      -0.05325913801789284,
      0.15826603770256042,
      0.05074608325958252,
      -0.005803827196359634,
      0.03093000315129757,
      -0.008839809335768223,
      -0.01708677038550377,
      -0.03900585696101189,
      -0.08249028772115707,
      -0.0325685515999794,
      0.05686643347144127,
      -0.05092569440603256,
      0.01505244616419077,
      0.05553693324327469,
      -0.08347249031066895,
      -0.052146948873996735,
      0.05113822966814041,
      0.012764636427164078,
      -0.02145354263484478,
      0.02396630123257637,
      -0.005453361198306084,
      0.01581769622862339,
      0.053802523761987686,
      -0.0003936013672500849,
      -0.01939474232494831,
      0.05573591589927673,
      0.030843930318951607,
      -0.051056645810604095,
      -0.03303643688559532,
      -0.03797018900513649,
      -0.04092429578304291,
      -0.044891152530908585,
      -0.018291771411895752,
      0.054538335651159286,
      -0.13665363192558289,
      0.0017369064735248685,
      -0.0014492226764559746,
      0.01108050812035799,
      0.0077646654099226,
      -0.06966143101453781,
      -0.024725815281271935,
      0.015769297257065773,
      0.02912520430982113,
      -0.01589263416826725,
      0.03464128449559212,
      0.01917169988155365,
      0.006067774724215269,
      -0.00807073712348938,
      -0.015895038843154907,
      0.0030713342130184174,
      -0.021052910014986992,
      -0.014844045974314213,
      -0.0491587296128273,
      0.05670164152979851,
      0.019081875681877136,
      0.0030388901941478252,
      0.022733574733138084,
      -0.037949904799461365,
      -0.0157935731112957,
      0.03081284835934639,
      -0.006405806168913841,
      -0.0898515060544014,
      0.015064002946019173,
      0.01843811757862568,
      0.0011517938692122698,
      0.013248170726001263,
      0.0069193453527987,
      0.09706377983093262,
      -0.060481347143650055,
      0.045008767396211624,
      0.010434204712510109,
      0.04129554331302643,
      -0.012373502366244793,
      0.03902817517518997,
      -0.023455161601305008,
      -0.05289863422513008,
      0.00880267284810543,
      -0.006430706940591335,
      -0.05872200429439545,
      0.012480460107326508,
      -0.012039863504469395,
      0.0017971777124330401,
      0.016031648963689804,
      0.04879999905824661,
      0.031817417591810226,
      -0.08014821261167526,
      0.009536832571029663,
      0.02188529074192047,
      0.0362309068441391,
      -0.027004694566130638,
      -0.06753424555063248,
      0.05005641281604767,
      -0.0424627959728241,
      -0.016526000574231148,
      0.011377708055078983,
      -0.05943799391388893,
      -0.09560982882976532,
      -0.08611559122800827,
      -0.05776871740818024,
      0.022450527176260948,
      -0.0032680570147931576,
      -0.0520983561873436,
      -0.032448843121528625,
      0.0026249054353684187,
      0.013766718097031116,
      -0.05190766975283623
    ],
    [
      -0.0686483085155487,
      -0.0338069312274456,
      -0.06144045665860176,
      0.0013775303959846497,
      -0.054085541516542435,
      -0.03697414696216583,
      0.009046158753335476,
      -0.09085828810930252,
      -0.014352450147271156,
      -0.06014758720993996,
      -0.0051622698083519936,
      -0.03624482452869415,
      -0.10224071145057678,
      -0.0054869758896529675,
      -0.0544247142970562,
      -0.03517980873584747,
      -0.06695570796728134,
      0.033644575625658035,
      0.045868225395679474,
      0.057913877069950104,
      -0.051062192767858505,
      -0.10012785345315933,
      0.013758099637925625,
      -0.004783668555319309,
      0.010373237542808056,
      0.05653500184416771,
      0.07144325226545334,
      0.041599154472351074,
      -0.052009668201208115,
      -0.027645772323012352,
      0.025632167235016823,
      0.11857268214225769,
      0.00045588580542244017,
      0.010077161714434624,
      0.0279510747641325,
      0.01696200482547283,
      -0.013965971767902374,
      0.06770770251750946,
      0.0740639865398407,
      -0.013522850349545479,
      0.00643457705155015,
      -0.05966942757368088,
      0.031027110293507576,
      -0.09344352036714554,
      -0.1008496955037117,
      0.034574393182992935,
      0.05527327582240105,
      -0.03424979746341705,
      -0.014746218919754028,
      0.015825608745217323,
      0.018468836322426796,
      -0.014130168594419956,
      -0.08703695982694626,
      0.010500556789338589,
      0.05550937354564667,
      0.07083962857723236,
      0.12409680336713791,
      0.004809292033314705,
      0.04220030829310417,
      -0.04130784794688225,
      0.05121153965592384,
      0.08196733146905899,
      0.01265325304120779,
      -0.04664631932973862,
      -0.05979698896408081,
      0.018321197479963303,
      -0.0318855419754982,
      -0.011504930444061756,
      -0.065217025578022,
      0.012748625129461288,
      -0.08079160749912262,
      0.07007855921983719,
      0.019326701760292053,
      -0.009675465524196625,
      -0.04296712949872017,
      0.0033240607008337975,
      0.08883015811443329,
      -0.001103848684579134,
      0.020489219576120377,
      -0.0005228288355283439,
      -0.013584830798208714,
      -0.04002399742603302,
      -0.015652712434530258,
      0.0630454495549202,
      0.038122352212667465,
      -0.03572572395205498,
      0.016737638041377068,
      -0.025677526369690895,
      -0.064362533390522,
      0.041431933641433716,
      -0.010421301238238811,
      -0.04633231833577156,
      -0.00687703350558877,
      -0.0074988240376114845,
      -0.010755820199847221,
      -0.08036729693412781,
      -0.03630140796303749,
      -0.026377350091934204,
      -0.05209213122725487,
      -0.024610135704278946,
      0.04193716496229172,
      -0.044684331864118576,
      0.004792280960828066,
      -0.017550526186823845,
      -0.0053963796235620975,
      -0.004724983591586351,
      0.049746811389923096,
      -0.03549746051430702,
      -0.04226277023553848,
      0.01739542931318283,
      -0.015633901581168175,
      0.04317283257842064,
      -0.03742039576172829,
      -0.004760673735290766,
      0.042099494487047195,
      -0.05330757796764374,
      -0.028349433094263077,
      0.0012571926927194,
      0.02097546122968197,
      -0.031177960336208344,
      0.009831181727349758,
      0.05071423202753067,
      -0.0052139488980174065,
      0.0073690046556293964,
      0.0408516451716423,
      0.13237662613391876,
      0.006181917153298855,
      0.0005052068154327571,
      0.04004785045981407,
      0.006471333559602499,
      -0.08732129633426666,
      -0.013975654728710651,
      0.09247159212827682,
      -0.08518406003713608,
      0.009322596713900566,
      0.03967556729912758,
      0.011996018700301647,
      0.028446275740861893,
      0.018473060801625252,
      0.04896746203303337,
      0.07644063234329224,
      -0.058774370700120926,
      0.10964147001504898,
      0.04715484008193016,
      -0.07148691266775131,
      -0.0363009013235569,
      0.029660189524292946,
      -0.013201480731368065,
      0.04829752817749977,
      0.0004862716014031321,
      0.022794431075453758,
      0.03329683467745781,
      0.025788335129618645,
      0.012390206567943096,
      0.019382916390895844,
      0.009250445291399956,
      -0.01452325563877821,
      -0.010896481573581696,
      -0.005774098448455334,
      -0.03171343728899956,
      -0.004138659220188856,
      0.01936279609799385,
      -0.01045545469969511,
      0.021096959710121155,
      0.012818189337849617,
      -0.005326977465301752,
      -0.08383490145206451,
      0.060283392667770386,
      0.004868593532592058,
      -0.051240473985672,
      0.09635886549949646,
      0.04533117637038231,
      -0.037071436643600464,
      -0.03394588083028793,
      0.004908343311399221,
      -0.024912649765610695,
      -0.05556878447532654,
      0.05970081686973572,
      -0.018311757594347,
      -0.01810767501592636,
      -0.006854558363556862,
      0.0004659606493078172,
      0.056750718504190445,
      -0.019373826682567596,
      -0.008258235640823841,
      -0.033019814640283585,
      0.03987168148159981,
      0.01204641442745924,
      0.029509974643588066,
      0.05854155495762825,
      0.022514842450618744,
      -0.036955803632736206,
      -0.07529196888208389,
      -0.07924818247556686,
      0.05837768316268921,
      -0.031402233988046646,
      -0.03239216282963753,
      -0.03431835025548935,
      -0.032254867255687714,
      -0.0033024419099092484,
      0.04230285808444023,
      0.011762710288167,
      -0.0341070182621479,
      0.0034575562458485365,
      0.022893957793712616,
      -0.05635363981127739,
      0.00410301610827446,
      0.01351248100399971,
      -0.020484814420342445,
      -0.09706500917673111,
      0.014231699518859386,
      -0.04970547929406166,
      0.0433841347694397,
      -0.05421459302306175,
      0.042881887406110764,
      -0.01754857413470745,
      -0.05340178683400154,
      -0.009941831231117249,
      -0.09088689088821411,
      -0.026528824120759964,
      -0.004395362921059132,
      0.016606079414486885,
      0.03248126059770584,
      0.04971620813012123,
      0.03858298435807228,
      0.09399693459272385,
      0.016388041898608208,
      -0.012905239127576351,
      0.025882067158818245,
      -0.024277979508042336,
      -0.07178103178739548,
      0.14356672763824463,
      0.0031446535140275955,
      -0.015341986902058125,
      0.0754515528678894,
      0.007709441240876913,
      -0.0403289757668972,
      0.0006459775613620877,
      -0.030682459473609924,
      0.016972608864307404,
      0.08480344712734222,
      -0.005773378536105156,
      0.009473840706050396,
      -0.0419856421649456,
      0.0700283944606781,
      -0.06409323215484619,
      0.026004096493124962,
      -0.0020808917470276356,
      0.007574091199785471,
      -0.050256092101335526,
      0.018889639526605606,
      0.012939173728227615,
      0.06063239276409149,
      -0.019616754725575447,
      0.03794494643807411,
      0.05217898264527321,
      -0.0639672800898552,
      -0.05344464257359505,
      0.022024068981409073,
      0.04595229774713516,
      -0.037798844277858734,
      0.015722522512078285,
      -0.013865281827747822,
      -0.017681622877717018,
      -0.04994940757751465,
      -0.019562924280762672,
      0.006400401704013348,
      0.02189778909087181,
      -0.010260671377182007,
      0.0344521589577198,
      -0.08941314369440079,
      -0.0386740006506443,
      0.005185035057365894,
      0.042007770389318466,
      -0.006069796159863472,
      -0.0184977725148201,
      0.00045664061326533556,
      0.03263353556394577,
      -0.00895452406257391,
      -0.035651665180921555,
      -0.045552808791399,
      -0.08793041855096817,
      -0.0479644238948822,
      0.049957022070884705,
      0.018912997096776962,
      -0.04505255073308945,
      -0.025523679330945015,
      -0.036992549896240234,
      0.0316443108022213,
      -0.03229113668203354,
      0.00897355005145073,
      -0.03079017624258995,
      -0.010825756005942822,
      -0.006466282531619072,
      -0.05309421941637993,
      -0.0928940400481224,
      0.03036089614033699,
      0.0011027693981304765,
      0.10085635632276535,
      0.012969651259481907,
      -0.013258002698421478,
      -0.02067234367132187,
      -0.03945305198431015,
      -0.030354082584381104,
      -0.04904443770647049,
      0.012093574739992619,
      -0.03793894499540329,
      0.020104924216866493,
      -0.05886246636509895,
      -0.02167000249028206,
      0.012497925199568272,
      -0.01450398564338684,
      0.011434687301516533,
      0.09367235004901886,
      0.032822273671627045,
      -0.014214402064681053,
      -0.011849915608763695,
      -0.07053817063570023,
      -0.00855335220694542,
      0.08103767037391663,
      -0.014831230975687504,
      0.07583913207054138,
      -0.03342301398515701,
      -0.020854176953434944,
      -0.004428344313055277,
      -0.03801868483424187,
      -0.03956647962331772,
      0.012218737974762917,
      0.02924582175910473,
      0.0037319802213460207,
      -0.02858670800924301,
      -0.006974858697503805,
      0.04119747132062912,
      0.05380403995513916,
      0.00022769813949707896,
      0.0746706947684288,
      0.03479751944541931,
      -0.030202366411685944,
      -0.01790670119225979,
      -0.026756448671221733,
      0.03407818824052811,
      0.05967393517494202,
      0.02378295361995697,
      0.0416252501308918,
      0.011371654458343983,
      0.046481408178806305,
      -0.09514003247022629,
      0.02174210362136364,
      0.012886996380984783,
      0.03181029111146927,
      0.013773756101727486,
      0.01334029994904995,
      0.009790255688130856,
      -0.0397648885846138,
      -0.01864517852663994,
      0.06784220784902573,
      -0.03657659515738487,
      -0.07150502502918243,
      0.03545445576310158,
      0.008939970284700394,
      -0.04010413587093353,
      0.017641110345721245,
      -0.04639367014169693,
      0.07536329329013824,
      -0.030232639983296394,
      -0.06434968113899231,
      -0.05463576316833496,
      -0.0011380233336240053,
      0.07771101593971252,
      -0.12732245028018951,
      0.028975406661629677,
      -0.007879947312176228,
      0.05983547866344452,
      0.022162683308124542,
      0.047918036580085754,
      0.03351011872291565,
      -0.00798053853213787,
      0.008601078763604164,
      -0.0277420487254858,
      0.03482098504900932,
      0.057622216641902924,
      -0.005618461407721043,
      0.03805840387940407,
      0.05929168686270714,
      -0.045714810490608215,
      -0.10127255320549011,
      -0.11963125318288803,
      -0.0073647466488182545,
      -0.035264864563941956,
      0.040964413434267044,
      0.09495706111192703,
      0.01186344213783741,
      0.028168031945824623,
      -0.05513612553477287,
      -0.015439142473042011,
      -0.034034814685583115,
      0.024461841210722923,
      0.03349237143993378,
      -0.02614346146583557,
      0.05342436954379082,
      0.014811214059591293,
      0.018386326730251312,
      -0.013388661667704582,
      0.05444100499153137,
      0.05866560712456703,
      -0.09733802080154419,
      0.04912598431110382,
      0.050166595727205276,
      -0.06720071285963058,
      -0.00278695416636765,
      0.011400071904063225,
      -0.07418397814035416,
      0.04382944479584694,
      0.00469061266630888,
      -0.012572088278830051,
      -0.03471558913588524,
      0.004706579726189375,
      0.08217061311006546,
      -0.022983549162745476,
      -0.007679687812924385,
      -0.04031467065215111,
      0.0439504012465477,
      -0.004162368830293417,
      -0.010871070437133312,
      -0.021808508783578873,
      0.006875589955598116,
      -0.005503841210156679,
      0.05136701092123985,
      0.019942305982112885,
      -0.02381894737482071,
      0.07519392669200897,
      0.0061319307424128056,
      -0.00292554241605103,
      0.044237419962882996,
      0.028090836480259895,
      0.002204906428232789,
      0.03876227140426636,
      -0.038940489292144775,
      0.018081780523061752,
      0.02487451769411564,
      -0.038447268307209015,
      -0.05156136676669121,
      -0.027940161526203156,
      0.003283003345131874,
      0.004903614055365324,
      0.07123583555221558,
      0.006639746483415365,
      -0.010913708247244358,
      0.043404776602983475,
      -0.010794631205499172,
      0.0045162467285990715,
      -0.003060081973671913,
      -0.044389884918928146,
      -0.0074098785407841206,
      0.016616035252809525,
      -0.030950386077165604,
      0.07823187857866287,
      -0.020322278141975403,
      -0.09850157052278519,
      0.022525999695062637,
      -0.0436575822532177,
      -0.0354803204536438,
      0.0006939322920516133,
      -0.06063675880432129,
      0.004684345796704292,
      -0.017497049644589424,
      0.012486780062317848,
      -0.018959373235702515,
      0.0064803194254636765,
      -0.013748237863183022,
      0.06230540946125984,
      0.022622335702180862,
      0.015900906175374985,
      0.06225549802184105,
      -0.004410018678754568,
      0.08450786769390106,
      -0.07303191721439362,
      -0.005503175780177116,
      0.003417077474296093,
      -0.0376911498606205,
      0.021057095378637314,
      0.0042513106018304825,
      0.02244354970753193,
      0.029816128313541412,
      0.04313621297478676,
      0.002613658783957362,
      -0.0003913651453331113,
      -0.021592140197753906,
      -0.05397962033748627,
      0.035442549735307693,
      0.028658458963036537,
      0.04662708565592766,
      -0.03310009092092514,
      0.03583113104104996,
      0.02795179933309555,
      -0.035557445138692856,
      -0.0345427580177784,
      0.018039334565401077,
      -0.006201146636158228,
      -0.04156007245182991,
      -0.03105899877846241,
      -0.05760756880044937,
      -0.02782570570707321
    ],
    [
      -0.055365901440382004,
      0.07373522967100143,
      -0.05983378738164902,
      -0.08267262578010559,
      -0.032220348715782166,
      0.0719590112566948,
      -0.0016916075255721807,
      0.05689985677599907,
      -0.04382621496915817,
      0.024780839681625366,
      -0.044569388031959534,
      0.0711694285273552,
      0.008112496696412563,
      -0.06520096212625504,
      0.03372624143958092,
      -0.004479567054659128,
      0.04147491976618767,
      -0.06611733883619308,
      -0.04914586991071701,
      0.024687042459845543,
      0.09566589444875717,
      0.011623406782746315,
      -0.019067712128162384,
      -0.11307807266712189,
      -0.006732276640832424,
      -0.06559696048498154,
      -0.027000948786735535,
      0.006762394681572914,
      -0.01928718574345112,
      -0.03458345681428909,
      0.012920146808028221,
      -0.065797820687294,
      -0.05093365162611008,
      0.0245654433965683,
      0.01693853922188282,
      -0.00325899850577116,
      -0.03970564529299736,
      0.08659347146749496,
      0.09570182859897614,
      0.07371383905410767,
      -0.037165384739637375,
      0.08640114217996597,
      0.03178928792476654,
      0.0026334410067647696,
      0.007678445894271135,
      -0.009224406443536282,
      0.014983650296926498,
      -0.009991551749408245,
      0.052380017936229706,
      -0.04885905981063843,
      0.0018360749818384647,
      -0.04144500568509102,
      -0.05801396816968918,
      -0.02428356185555458,
      0.015446207486093044,
      0.021721819415688515,
      0.012660670094192028,
      0.07590217143297195,
      -0.03396464139223099,
      -0.04453032836318016,
      0.03663599118590355,
      -0.05080171301960945,
      -0.01657767780125141,
      -0.0247331690043211,
      -0.010293502360582352,
      -0.008694736286997795,
      -0.061369214206933975,
      0.010561205446720123,
      -0.03618033230304718,
      0.03719896450638771,
      0.027392124757170677,
      -0.022020209580659866,
      0.07527215033769608,
      0.01527493353933096,
      -0.0850323736667633,
      0.04382748156785965,
      -0.005485876463353634,
      0.021055735647678375,
      0.014776183292269707,
      -0.011625920422375202,
      0.062320273369550705,
      0.0688692256808281,
      0.011999186128377914,
      0.004610887262970209,
      -0.02542061172425747,
      -0.03823912888765335,
      0.023505661636590958,
      -0.05189746618270874,
      -0.0527966283261776,
      -0.06544557213783264,
      -0.005771846976131201,
      -0.07569023221731186,
      -0.008613666519522667,
      -0.07306849956512451,
      0.08177364617586136,
      0.03525848686695099,
      -0.08175361901521683,
      -0.044185612350702286,
      -0.03709152713418007,
      -0.02329862304031849,
      -0.128940612077713,
      -0.060865242034196854,
      0.02388595975935459,
      -0.02931363135576248,
      -0.06189677491784096,
      0.029298977926373482,
      0.004770520608872175,
      -0.0049271476455032825,
      -0.025093790143728256,
      0.014570136554539204,
      -0.09252689778804779,
      -0.06463799625635147,
      -0.07346741855144501,
      -0.03237895295023918,
      0.07426277548074722,
      -0.0031224407721310854,
      0.04611107334494591,
      -0.00751096336171031,
      -0.01316800620406866,
      -0.04459001496434212,
      -0.059921618551015854,
      0.07915724813938141,
      -0.06080077588558197,
      -0.030357489362359047,
      -0.0014477800577878952,
      0.007885098457336426,
      -0.02498861588537693,
      0.016480019316077232,
      -0.04194386675953865,
      -0.032078973948955536,
      -0.017207099124789238,
      -0.07424930483102798,
      0.04621572047472,
      0.1146753653883934,
      0.034312210977077484,
      0.05645693466067314,
      0.03762584924697876,
      -0.012477608397603035,
      0.028926538303494453,
      -0.025020264089107513,
      -0.038760364055633545,
      -0.06994219869375229,
      -0.047766633331775665,
      0.07977689802646637,
      -0.024811668321490288,
      0.07725077867507935,
      0.02953391522169113,
      -0.015155021101236343,
      -0.041498105973005295,
      -0.02838543802499771,
      -0.053004518151283264,
      0.04091067984700203,
      -0.07065436244010925,
      -0.007944343611598015,
      0.03209558129310608,
      -0.07719991356134415,
      0.010269549675285816,
      0.05735105276107788,
      0.07154729217290878,
      0.029706532135605812,
      0.0573534332215786,
      -0.05695824697613716,
      0.03771746903657913,
      0.0771246999502182,
      -0.06031634286046028,
      -0.1082163080573082,
      0.04812971502542496,
      -0.01770852506160736,
      -0.02559938281774521,
      0.06797024607658386,
      -0.047391895204782486,
      0.006075793411582708,
      0.040605947375297546,
      0.09828238934278488,
      0.0004259097040630877,
      -0.0029680619481951,
      -0.024233924224972725,
      -0.003769923932850361,
      -0.06186570227146149,
      0.056170422583818436,
      -0.01824571192264557,
      -0.004076771903783083,
      0.0336468368768692,
      0.01268854271620512,
      -0.007139063440263271,
      0.008914242498576641,
      -0.024328408762812614,
      0.00657313596457243,
      -0.05251910164952278,
      0.02455504611134529,
      0.029064008966088295,
      0.0253010131418705,
      -0.0011003725230693817,
      0.01794656552374363,
      0.031157733872532845,
      0.03902904689311981,
      0.05653608962893486,
      -0.07626105099916458,
      -0.041073206812143326,
      -0.12605290114879608,
      0.058683767914772034,
      0.04377134516835213,
      0.023979775607585907,
      0.018589168787002563,
      -0.007303353399038315,
      0.06415565311908722,
      0.042812202125787735,
      0.057653799653053284,
      -0.03998062387108803,
      -0.004209060687571764,
      -0.07140304148197174,
      -0.08428732305765152,
      0.007931593805551529,
      -0.03288459777832031,
      -0.05632783845067024,
      -0.0484236516058445,
      -0.020741015672683716,
      -0.07597425580024719,
      0.000109450651507359,
      -0.06739843636751175,
      0.0023665258195251226,
      -0.07689271122217178,
      0.024125689640641212,
      0.004501710180193186,
      0.014784840866923332,
      -0.022551385685801506,
      -0.01719846948981285,
      -0.008108452893793583,
      0.008206560276448727,
      0.07274952530860901,
      -0.005925753619521856,
      0.004329825285822153,
      -0.032399747520685196,
      -0.009280538186430931,
      0.0168636292219162,
      -0.04370656982064247,
      0.02463839203119278,
      -0.0019442347111180425,
      0.05601748824119568,
      -0.02458757534623146,
      0.03148810938000679,
      0.02089245431125164,
      0.020106205716729164,
      -0.033728957176208496,
      -0.041768692433834076,
      0.062439803034067154,
      -0.06787941604852676,
      -0.05008601397275925,
      0.07367835938930511,
      -0.035311467945575714,
      0.08159977942705154,
      0.0423768125474453,
      0.025223514065146446,
      0.0732545480132103,
      0.050483740866184235,
      0.061655741184949875,
      0.021654604002833366,
      0.00822178553789854,
      0.022067483514547348,
      -0.01332714594900608,
      -0.06502586603164673,
      -0.008263247087597847,
      0.11406120657920837,
      0.05545828863978386,
      0.00877392292022705,
      0.013901867903769016,
      0.01864616759121418,
      -0.0751117542386055,
      0.04575808346271515,
      0.03265703469514847,
      -0.04087655246257782,
      0.011198846623301506,
      -0.0010454746661707759,
      -0.026396241039037704,
      0.012665154412388802,
      0.0055735972709953785,
      -0.036449432373046875,
      -0.046195946633815765,
      0.04017878696322441,
      0.017440874129533768,
      -0.02241470105946064,
      -0.03366822749376297,
      -0.03333991393446922,
      0.012989643029868603,
      -0.011509018018841743,
      0.02594681829214096,
      -0.0029265570919960737,
      -0.07819217443466187,
      -0.03028852306306362,
      0.021499905735254288,
      0.05404850095510483,
      -0.024149149656295776,
      0.041187409311532974,
      -0.022211749106645584,
      -0.05328505113720894,
      -0.08914704620838165,
      0.015435345470905304,
      -0.049960821866989136,
      0.06511121243238449,
      0.05072475224733353,
      0.023454485461115837,
      0.0055658528581261635,
      -0.03835683688521385,
      0.017386319115757942,
      -0.04569660499691963,
      0.0653286799788475,
      0.03527773544192314,
      -0.02075555920600891,
      0.012408974580466747,
      -0.0871778354048729,
      0.03035915642976761,
      0.030299698933959007,
      0.007181840017437935,
      -0.01698678359389305,
      0.03910968452692032,
      -0.071820929646492,
      -0.020262176170945168,
      0.014540625736117363,
      -0.012531058862805367,
      0.06972439587116241,
      0.009225753135979176,
      -0.04229394719004631,
      -0.03153660148382187,
      -0.06995711475610733,
      0.04399167373776436,
      0.03214636817574501,
      -0.04827293008565903,
      0.041708555072546005,
      -0.0029070181772112846,
      -0.074758380651474,
      0.01352208387106657,
      0.017001425847411156,
      0.021872105076909065,
      -0.013742656446993351,
      -0.04732433706521988,
      0.010006059892475605,
      -0.0337081179022789,
      -0.011013258248567581,
      0.04577356204390526,
      0.019111046567559242,
      -0.029111109673976898,
      0.05699008330702782,
      -0.014069454744458199,
      0.041071437299251556,
      0.06782395392656326,
      0.011369941756129265,
      -0.05902833864092827,
      -0.012694168835878372,
      -0.011269508861005306,
      -0.035443902015686035,
      -0.011280445381999016,
      -0.029925646260380745,
      0.04583149403333664,
      0.006759920157492161,
      0.056998111307621,
      0.02073873020708561,
      0.015994276851415634,
      -0.04037831351161003,
      0.013378292322158813,
      -0.009875188581645489,
      -0.034922197461128235,
      0.04608333855867386,
      0.07401857525110245,
      0.10946357250213623,
      -0.05074162781238556,
      0.008774889633059502,
      -0.024938786402344704,
      0.03435884788632393,
      -0.0016429303213953972,
      0.06452132761478424,
      0.05027618631720543,
      -0.007285005412995815,
      0.018349753692746162,
      -0.05854831635951996,
      0.03396160900592804,
      0.04443977400660515,
      0.029607586562633514,
      0.009566697292029858,
      0.011804122477769852,
      0.005577389150857925,
      0.05567009747028351,
      0.00104179244954139,
      0.06595034897327423,
      -0.008487856946885586,
      -0.14753808081150055,
      0.06313931196928024,
      0.03888433426618576,
      -0.034234169870615005,
      0.05330131575465202,
      0.055769603699445724,
      -0.06721612066030502,
      -0.012907391414046288,
      0.0038372541312128305,
      -0.0608566477894783,
      -0.00830177403986454,
      0.02255006693303585,
      0.006192557513713837,
      -0.0538363941013813,
      0.037415243685245514,
      0.027969859540462494,
      0.027283787727355957,
      0.011182423681020737,
      0.009792651981115341,
      -0.03358794376254082,
      -0.06611473113298416,
      -0.06101701036095619,
      -0.02015822008252144,
      0.03409254923462868,
      0.07263615727424622,
      0.014492442831397057,
      -0.03192504495382309,
      0.021104170009493828,
      0.06416774541139603,
      0.02535823918879032,
      -0.01177268661558628,
      0.03943425789475441,
      -0.058416638523340225,
      -0.03405037522315979,
      0.016896503046154976,
      0.03644781559705734,
      -0.02836618572473526,
      -0.04721928760409355,
      -0.006582681555300951,
      0.04017417132854462,
      -0.012206667102873325,
      0.03317412734031677,
      -0.012403246015310287,
      -0.05241766571998596,
      -0.08549603074789047,
      -0.01678270846605301,
      -0.004495328292250633,
      0.045476894825696945,
      -0.013759407214820385,
      0.08799823373556137,
      -0.03240177035331726,
      -0.034141577780246735,
      0.011285505257546902,
      -0.04171568900346756,
      -0.025390230119228363,
      0.02227923646569252,
      -0.031111693009734154,
      0.008970580995082855,
      -0.048683181405067444,
      -0.031761061400175095,
      0.013298550620675087,
      0.10565764456987381,
      0.01040739007294178,
      0.038401294499635696,
      0.037242259830236435,
      0.0020076462533324957,
      -0.03759908676147461,
      -0.029098991304636,
      -0.01462745200842619,
      0.024426648393273354,
      0.044469911605119705,
      0.013974889181554317,
      -0.011078448966145515,
      0.0024680173955857754,
      -0.002356292912736535,
      -0.019711121916770935,
      -0.07130055129528046,
      0.05913049355149269,
      0.019678480923175812,
      0.023747066035866737,
      0.07188607007265091,
      0.04087062180042267,
      -0.012063133530318737,
      0.008342552930116653,
      0.03355608880519867,
      0.04104626178741455,
      0.09538622200489044,
      -0.07270949333906174,
      -0.04831961169838905,
      0.02015252597630024,
      0.06096651032567024,
      -0.04167816415429115,
      -0.030025966465473175,
      0.07679710537195206,
      -0.0059685977175831795,
      0.02171362191438675,
      0.05899510532617569,
      0.030472593382000923,
      0.005258730612695217,
      0.014319652691483498,
      -0.044258639216423035,
      0.005678190384060144,
      -0.012499314732849598,
      -0.015690619125962257,
      -0.004511795938014984,
      -0.036006566137075424,
      0.024596979841589928,
      -0.033560916781425476,
      -0.01576382853090763,
      -0.039464931935071945,
      -0.022218132391572,
      -0.07092276215553284,
      0.026465261355042458,
      0.0800267681479454,
      -0.04981086403131485,
      0.007955072447657585,
      -0.03307474032044411,
      0.026256591081619263,
      -0.06863465905189514
    ],
    [
      0.05551901459693909,
      0.03329518809914589,
      0.003651840379461646,
      0.029623547568917274,
      -0.01813938468694687,
      0.041963495314121246,
      -0.07523466646671295,
      -0.04520249739289284,
      -0.0145872225984931,
      0.09682907909154892,
      0.010433370247483253,
      -0.031561654061079025,
      0.027240028604865074,
      -0.030816812068223953,
      -0.01580001413822174,
      0.011134948581457138,
      -0.04410351812839508,
      -0.0032718803267925978,
      -0.1267600655555725,
      0.01078347023576498,
      0.012532460503280163,
      -0.07655809074640274,
      0.026183046400547028,
      -0.05607209727168083,
      0.03175637871026993,
      0.032040346413850784,
      0.003765836590901017,
      -0.035229045897722244,
      -0.034795667976140976,
      0.010154511779546738,
      -0.04192512482404709,
      -0.04975823685526848,
      0.028475478291511536,
      -0.0212100762873888,
      -0.037571899592876434,
      0.004946836270391941,
      0.0560666061937809,
      -0.03898889571428299,
      0.022762199863791466,
      0.06356801837682724,
      0.033403027802705765,
      0.02555159106850624,
      -0.026374489068984985,
      0.023439332842826843,
      -0.03127546235918999,
      0.0661218985915184,
      0.13840873539447784,
      0.06287697702646255,
      -0.05945896357297897,
      0.042561642825603485,
      -0.025987472385168076,
      -0.01938018575310707,
      0.03269847109913826,
      -0.03279028460383415,
      -0.10973650217056274,
      0.07256346940994263,
      0.03822193667292595,
      -0.0076283342204988,
      0.009279116988182068,
      0.01756102219223976,
      0.06826184689998627,
      0.01764482446014881,
      0.027351977303624153,
      0.012667400762438774,
      0.01472161803394556,
      -0.07981260865926743,
      0.07735234498977661,
      0.011295204982161522,
      -0.046424753963947296,
      -0.07088350504636765,
      -0.05270634591579437,
      0.03148144856095314,
      -0.06727985292673111,
      0.044709160923957825,
      -0.051647529006004333,
      -0.031042220070958138,
      -0.012869812548160553,
      -0.08099967986345291,
      -0.03761817887425423,
      -0.0314091220498085,
      -0.026646865531802177,
      -0.033005163073539734,
      0.05420359969139099,
      -0.06638925522565842,
      0.01319703459739685,
      0.039039697498083115,
      -0.05613930523395538,
      -0.06902281194925308,
      0.036143843084573746,
      -0.0011614516843110323,
      0.08767632395029068,
      -0.01912827417254448,
      0.010809333994984627,
      -0.0014156735269352794,
      -0.0002744058147072792,
      -0.033304717391729355,
      0.05872403085231781,
      0.03219792991876602,
      -0.03622746840119362,
      0.06545543670654297,
      0.012002035975456238,
      -0.03943731635808945,
      0.015274322591722012,
      -0.04286613687872887,
      0.012598473578691483,
      -0.07166258990764618,
      -0.0030166383367031813,
      -0.02435234934091568,
      0.010926459915935993,
      0.021080555394291878,
      0.0024109678342938423,
      -0.013631075620651245,
      0.05670395493507385,
      0.060000550001859665,
      0.0047298516146838665,
      0.0121995834633708,
      0.05633550509810448,
      -0.06924348324537277,
      -0.05516071245074272,
      -0.035929225385189056,
      -0.04215797781944275,
      -0.04948952794075012,
      -0.1033390462398529,
      0.034595947712659836,
      0.06298436969518661,
      -0.07423827797174454,
      -0.049827221781015396,
      0.03123198077082634,
      0.08032430708408356,
      0.010984798893332481,
      -0.015248903073370457,
      -0.04919848591089249,
      0.02817201055586338,
      -0.055768802762031555,
      0.002895669313147664,
      -0.052775271236896515,
      -0.06964293122291565,
      0.07725104689598083,
      0.016670331358909607,
      0.013960834592580795,
      -0.029622022062540054,
      0.015282955020666122,
      0.002052058931440115,
      0.031498849391937256,
      -0.00539409602060914,
      -0.08361852914094925,
      -0.038395658135414124,
      0.031812090426683426,
      -0.03737827390432358,
      -0.012001085095107555,
      -0.0767664909362793,
      0.10597452521324158,
      -0.016989368945360184,
      -0.015061927028000355,
      0.04303336143493652,
      -0.06870870292186737,
      -0.05908937007188797,
      0.058300044387578964,
      0.000210630358196795,
      -0.033318083733320236,
      -0.03144005686044693,
      0.012144808657467365,
      -0.03050529956817627,
      0.05141057074069977,
      -0.0006530811660923064,
      0.08289948850870132,
      0.009643377736210823,
      0.053008902817964554,
      0.01064157485961914,
      -0.09150144457817078,
      -0.010219967924058437,
      0.0037076289299875498,
      -0.0020839713979512453,
      0.0695013701915741,
      0.029832439497113228,
      0.06001262366771698,
      0.06562652438879013,
      0.030304165557026863,
      0.016137201339006424,
      -0.007872205227613449,
      -0.005527759902179241,
      0.04789908602833748,
      0.059597890824079514,
      -0.02707420103251934,
      -0.005983450450003147,
      -0.025150896981358528,
      -0.014995094388723373,
      0.054512910544872284,
      -0.021443063393235207,
      -0.040354397147893906,
      -0.01370236650109291,
      -0.04054958373308182,
      0.040627092123031616,
      -0.053028617054224014,
      0.02972734346985817,
      0.03210434317588806,
      0.01666640304028988,
      -0.012782029807567596,
      0.02675369754433632,
      -0.012915698811411858,
      -0.06873312592506409,
      -0.10464045405387878,
      -0.021984471008181572,
      0.04958995431661606,
      0.08950545638799667,
      0.01240895688533783,
      -0.05300333350896835,
      0.02943158894777298,
      0.0008894059574231505,
      -0.014626757241785526,
      -0.04917192459106445,
      0.04192591831088066,
      -0.016368869692087173,
      -0.01643235608935356,
      -0.017938315868377686,
      0.05444787070155144,
      0.061351921409368515,
      0.00403280695900321,
      0.006116681266576052,
      0.0057472772896289825,
      -0.10933078825473785,
      -0.10140125453472137,
      -0.05242031067609787,
      -0.021444903686642647,
      0.019040415063500404,
      0.07939130812883377,
      -0.0043092756532132626,
      -0.03516768291592598,
      -0.023064197972416878,
      -0.043041009455919266,
      -0.07240878790616989,
      0.016687465831637383,
      -0.021260328590869904,
      -0.05901125445961952,
      0.11753106117248535,
      -0.014966660179197788,
      -0.046091221272945404,
      0.052288200706243515,
      -0.03974375128746033,
      -0.0728427916765213,
      -0.07939211279153824,
      -0.03839868679642677,
      -0.05731764808297157,
      -0.03452227637171745,
      0.0010248691542074084,
      -0.021772950887680054,
      -0.0048630256205797195,
      0.016401352360844612,
      0.023549240082502365,
      0.07858765870332718,
      -0.10310084372758865,
      -0.07203179597854614,
      -0.11749448627233505,
      0.004641704726964235,
      0.0060819778591394424,
      0.02949519269168377,
      -0.019497975707054138,
      0.04906706139445305,
      -0.06573063135147095,
      0.004021669737994671,
      -0.03983385115861893,
      -0.04600806161761284,
      0.023995019495487213,
      -0.07244669646024704,
      -0.0016471873968839645,
      -0.054777782410383224,
      -0.06947389990091324,
      0.08905462175607681,
      0.015049039386212826,
      0.04216736555099487,
      0.0064278640784323215,
      0.006245290860533714,
      0.06947369128465652,
      0.02796608954668045,
      -0.01878455840051174,
      -0.016335783526301384,
      0.04413047805428505,
      -0.011356054805219173,
      -0.04424336180090904,
      -0.015756171196699142,
      0.062334731221199036,
      0.011109113693237305,
      -0.0056550404988229275,
      -0.012462996877729893,
      0.06174569949507713,
      -0.009333723224699497,
      -0.06556244194507599,
      0.019307591021060944,
      0.012689721770584583,
      -0.05388713628053665,
      0.023110616952180862,
      -0.006327467039227486,
      0.04420847073197365,
      0.00030873750802129507,
      -0.06523965299129486,
      -0.0107039799913764,
      -0.0647176057100296,
      -0.040324993431568146,
      0.05141063779592514,
      0.02292015589773655,
      -0.016966067254543304,
      0.024650000035762787,
      -0.05456748232245445,
      0.0687800943851471,
      0.08057330548763275,
      0.07211334258317947,
      0.02920697256922722,
      -0.0641026720404625,
      -0.03339967876672745,
      0.00600031390786171,
      -0.005858648102730513,
      0.0024685042444616556,
      -0.04498426616191864,
      0.043424341827631,
      0.0008410544251091778,
      -0.04814167693257332,
      0.07765360176563263,
      -0.0018094689585268497,
      0.04103688895702362,
      -0.05331046134233475,
      -0.004903008230030537,
      0.028177443891763687,
      -0.03872611001133919,
      0.013063336722552776,
      0.0055188387632369995,
      0.027042528614401817,
      -0.08029482513666153,
      0.05345746874809265,
      -0.0396442711353302,
      -0.011223284527659416,
      0.011296437121927738,
      0.01196751743555069,
      -0.040158212184906006,
      0.024367840960621834,
      0.01703581027686596,
      -0.013586805202066898,
      0.034922920167446136,
      0.042015042155981064,
      0.10010655224323273,
      -0.03223605453968048,
      0.05289660394191742,
      -0.02653624303638935,
      0.11851242184638977,
      0.0113611975684762,
      -0.06538181751966476,
      -0.017529889941215515,
      0.0348658412694931,
      0.013936912640929222,
      -0.046620067209005356,
      0.041016243398189545,
      0.015666121616959572,
      0.0384497307240963,
      -0.00948622077703476,
      -0.08886964619159698,
      -0.002593348966911435,
      0.018646595999598503,
      0.005902584176510572,
      0.053518034517765045,
      0.006454043556004763,
      0.0799039974808693,
      0.06292061507701874,
      -0.028860462829470634,
      0.009208712726831436,
      -0.044638924300670624,
      -0.07072073221206665,
      -0.003970526624470949,
      -0.12466517090797424,
      0.0385759212076664,
      -0.04802414029836655,
      0.06794627010822296,
      0.012396588921546936,
      -0.006238602101802826,
      0.07567218691110611,
      0.05085297301411629,
      -0.01986970193684101,
      -0.010855146683752537,
      -0.015710683539509773,
      0.03194717317819595,
      0.07649572938680649,
      -0.053324852138757706,
      0.033592917025089264,
      -0.04205942526459694,
      0.08612825721502304,
      0.0336441807448864,
      0.04923021048307419,
      0.018717801198363304,
      0.02098076045513153,
      -0.08122293651103973,
      -0.01856052875518799,
      -0.009381914511322975,
      -0.027663707733154297,
      0.0314631313085556,
      -0.044576384127140045,
      0.02672027423977852,
      0.08160436898469925,
      -0.0897047221660614,
      0.055092599242925644,
      0.023641066625714302,
      -0.02429540827870369,
      0.03226085379719734,
      0.07129723578691483,
      0.039878856390714645,
      0.09117794781923294,
      0.01477830670773983,
      -0.08722032606601715,
      -0.032970864325761795,
      -0.02728390134871006,
      -0.032117195427417755,
      -0.012028261087834835,
      0.0032733704429119825,
      -0.04104861617088318,
      -0.08536065369844437,
      -0.032808613032102585,
      0.005486777983605862,
      0.038387756794691086,
      -0.022955676540732384,
      0.04436945170164108,
      0.04640013352036476,
      0.007507937960326672,
      0.025257423520088196,
      -0.03651249781250954,
      0.028288329020142555,
      -0.037648942321538925,
      0.008590969257056713,
      0.0014913483755663037,
      0.004995343741029501,
      0.002935258438810706,
      -0.06757725030183792,
      0.02967008575797081,
      -0.002596739213913679,
      0.02773912064731121,
      -0.019249269738793373,
      -0.03586357459425926,
      0.07825502008199692,
      0.03291289880871773,
      -0.060755059123039246,
      -0.00285791396163404,
      0.048179686069488525,
      0.0030408240854740143,
      -0.01945536583662033,
      0.08167647570371628,
      0.04058420658111572,
      -0.0974724069237709,
      -0.04176963493227959,
      -0.025502443313598633,
      0.016699716448783875,
      0.02078671008348465,
      -0.011913438327610493,
      -0.029577886685729027,
      -0.025797247886657715,
      0.03731105104088783,
      -0.03812820091843605,
      0.04352386295795441,
      0.07844201475381851,
      0.014974127523601055,
      -0.09837371855974197,
      0.008613653481006622,
      0.0027729205321520567,
      -0.050676748156547546,
      -0.036937665194272995,
      -0.010940554551780224,
      -0.041311219334602356,
      -0.027879800647497177,
      -0.024262327700853348,
      0.027304451912641525,
      0.0339377224445343,
      0.006366876885294914,
      0.06187816709280014,
      0.016545727849006653,
      -0.088899627327919,
      0.0017422644887119532,
      -0.09945238381624222,
      -0.02539062313735485,
      0.05295437574386597,
      0.0354623980820179,
      -0.007662354968488216,
      -0.07992665469646454,
      0.00759668555110693,
      0.008020663633942604,
      0.00013046684034634382,
      -0.018702128902077675,
      0.11910725384950638,
      -0.05405352637171745,
      0.01854419708251953,
      -0.03477633371949196,
      -0.04909796640276909,
      -0.005249799229204655,
      0.012329943478107452,
      0.022151997312903404,
      -0.005696179810911417,
      0.02774910256266594,
      -0.07463975995779037,
      0.031726788729429245,
      0.017166515812277794,
      -0.0028316110838204622,
      0.07162421196699142,
      -0.03906287997961044,
      -0.01135739590972662,
      -0.0297141894698143,
      -0.010335559956729412,
      0.016295839101076126,
      0.04802921041846275,
      0.07629019021987915
    ],
    [
      0.03629161790013313,
      -0.018562689423561096,
      0.00535572087392211,
      -0.018691040575504303,
      -0.005277666728943586,
      -0.04112754017114639,
      0.01476522721350193,
      -0.048004209995269775,
      -0.003289376385509968,
      0.04703420400619507,
      0.0428619459271431,
      -0.0038799839094281197,
      0.015685036778450012,
      0.04320291802287102,
      0.03229157254099846,
      -0.03379310667514801,
      0.005218584090471268,
      -0.04835973680019379,
      0.0511443130671978,
      0.026692267507314682,
      -0.004060972016304731,
      -0.0893060490489006,
      -0.017850825563073158,
      -0.05332912877202034,
      0.025466935709118843,
      0.0468282550573349,
      -0.06193803250789642,
      0.0020146213937550783,
      -0.017593203112483025,
      -0.025652624666690826,
      0.014842011034488678,
      0.019591940566897392,
      0.008024593815207481,
      -0.03930851072072983,
      0.05216626077890396,
      0.021854141727089882,
      -0.08755413442850113,
      -0.05064227059483528,
      -0.006361646577715874,
      0.01921026222407818,
      -0.02172331139445305,
      -0.06227229908108711,
      0.060377687215805054,
      -0.03430897742509842,
      -0.035337939858436584,
      0.004589918535202742,
      -0.07039383053779602,
      -0.003928052261471748,
      -0.035804156213998795,
      0.0005437162471935153,
      0.043789271265268326,
      0.016466539353132248,
      0.03945396840572357,
      -0.10339406877756119,
      0.01530586276203394,
      0.03441072255373001,
      0.07947729527950287,
      -0.03438905254006386,
      0.033217668533325195,
      0.03658508136868477,
      -0.08683109283447266,
      0.0332513302564621,
      -0.05722362920641899,
      -0.011084544472396374,
      -0.00736644072458148,
      0.03207193687558174,
      -0.013110601343214512,
      -0.04714209958910942,
      0.04703155905008316,
      0.04122700169682503,
      0.06488139927387238,
      -0.006638708990067244,
      0.0010899583576247096,
      -0.018615083768963814,
      -0.03024977445602417,
      -0.018291248008608818,
      -0.025668589398264885,
      0.0024034660309553146,
      -0.028657590970396996,
      0.05016064643859863,
      0.02684595063328743,
      0.026407163590192795,
      0.08827605843544006,
      0.05067814514040947,
      -0.01236161682754755,
      -0.028474334627389908,
      0.04456575959920883,
      0.0644170418381691,
      0.016952157020568848,
      0.015751441940665245,
      0.03358108922839165,
      0.005597117822617292,
      0.0020364404190331697,
      -0.022503113374114037,
      0.031101727858185768,
      -0.010318968445062637,
      -0.002746911020949483,
      -0.060383137315511703,
      -0.023753054440021515,
      0.0923805683851242,
      -0.03133270889520645,
      0.09309712797403336,
      -0.03717633709311485,
      -0.1292504221200943,
      -0.046157218515872955,
      -0.0716584101319313,
      0.06798157840967178,
      -0.01507339347153902,
      0.010499773547053337,
      0.007958806119859219,
      0.041812825947999954,
      -0.09033593535423279,
      -0.032522235065698624,
      -0.005109013058245182,
      -0.011798872612416744,
      -0.008380984887480736,
      0.025381937623023987,
      0.045421525835990906,
      0.0039457892999053,
      -0.07342126965522766,
      0.01954602263867855,
      -0.02449636161327362,
      0.06272754073143005,
      -0.004126057960093021,
      0.007582428865134716,
      0.027051353827118874,
      -0.040484730154275894,
      -0.025197088718414307,
      0.02520090714097023,
      0.03744278848171234,
      -0.07400855422019958,
      -0.003905598074197769,
      -0.01498357206583023,
      -0.024740826338529587,
      0.032539550215005875,
      -0.0051442901603877544,
      -0.002739328658208251,
      0.03553850203752518,
      -0.07573629170656204,
      0.028102906420826912,
      -0.060413844883441925,
      -0.1260589063167572,
      -0.008995316922664642,
      0.09787878394126892,
      -0.011634595692157745,
      0.05253256484866142,
      0.05311606824398041,
      0.09103956073522568,
      0.0460030622780323,
      -0.004808906465768814,
      -0.02450007200241089,
      0.09340748935937881,
      0.027768723666667938,
      -0.024357186630368233,
      -0.00486135995015502,
      -0.02667035162448883,
      0.04838808625936508,
      0.001678323489613831,
      -0.06027407571673393,
      -0.05049854889512062,
      -0.05095580220222473,
      0.08432421833276749,
      0.05402762070298195,
      0.0240388922393322,
      -0.024049896746873856,
      0.0558273084461689,
      0.022693904116749763,
      -0.04919189587235451,
      -0.0370306670665741,
      0.026750074699521065,
      -0.052602674812078476,
      0.11284969002008438,
      -0.08147619664669037,
      0.053843338042497635,
      0.037666067481040955,
      -0.027033310383558273,
      0.016413968056440353,
      0.02955394797027111,
      0.01631428860127926,
      0.03712564706802368,
      -0.006379872560501099,
      0.007997551001608372,
      -0.08201954513788223,
      0.0024478991981595755,
      -0.020108001306653023,
      0.012720233760774136,
      -0.014156406745314598,
      0.06892336905002594,
      -0.008535600267350674,
      -0.04957931488752365,
      0.0030464446172118187,
      0.02042093686759472,
      -0.1314864456653595,
      -0.0001352086546830833,
      0.034256160259246826,
      -0.002662717131897807,
      -0.02150268293917179,
      0.02782486379146576,
      0.020982589572668076,
      0.0097578139975667,
      0.04131157323718071,
      0.03394334390759468,
      0.05545388162136078,
      -0.04716511443257332,
      -0.026550138369202614,
      -0.008589113131165504,
      -0.020277781412005424,
      -0.03318623825907707,
      0.028695465996861458,
      0.014400545507669449,
      -0.006981472950428724,
      -0.032600171864032745,
      -0.04443724453449249,
      -0.025238733738660812,
      -0.07356943190097809,
      0.030959095805883408,
      -0.003229781985282898,
      0.04505554959177971,
      0.06916778534650803,
      -0.021133368834853172,
      -0.012682370841503143,
      -0.013645862229168415,
      -0.01745348982512951,
      0.012145750224590302,
      0.012420527637004852,
      -0.004786737263202667,
      -0.0006354976794682443,
      0.012292764149606228,
      0.01203614566475153,
      -0.08533688634634018,
      0.012379623018205166,
      -0.07027756422758102,
      -0.07375317811965942,
      0.018039537593722343,
      0.057866085320711136,
      0.010652376338839531,
      -0.0014455292839556932,
      0.017095506191253662,
      -0.07257331162691116,
      0.039875634014606476,
      0.02559782937169075,
      -0.016789522022008896,
      0.027547430247068405,
      0.03977476805448532,
      0.026795823127031326,
      -0.015313496813178062,
      0.03668054938316345,
      0.011829583905637264,
      0.0126265250146389,
      0.02862718142569065,
      0.008340959437191486,
      -0.02094525285065174,
      -0.0824560672044754,
      0.04809831082820892,
      0.035473138093948364,
      -0.01243238802999258,
      -0.03500811755657196,
      0.02742382511496544,
      0.013039381243288517,
      0.00068011658731848,
      0.009946232661604881,
      -0.044571299105882645,
      0.03834892809391022,
      0.00918921735137701,
      -0.06586334109306335,
      -0.01933859847486019,
      -0.030507098883390427,
      0.036701470613479614,
      0.03401830047369003,
      0.0476733036339283,
      -0.016748914495110512,
      -0.009455653838813305,
      -0.02750985696911812,
      0.047057393938302994,
      0.00681635458022356,
      -0.021670809015631676,
      -0.003800251055508852,
      -0.05549702048301697,
      -0.008912176825106144,
      -0.018383128568530083,
      0.01403068844228983,
      -0.06541736423969269,
      -0.016539309173822403,
      -0.019147563725709915,
      0.0602458193898201,
      0.019278118386864662,
      0.047911494970321655,
      0.03586844727396965,
      -0.0037205328699201345,
      0.0036442808341234922,
      0.006818826775997877,
      0.03331753984093666,
      -0.013696776703000069,
      -0.0504150316119194,
      0.000983527977950871,
      -0.008955237455666065,
      0.052630387246608734,
      0.004518939647823572,
      0.09478837251663208,
      -0.08237683027982712,
      -0.031130315735936165,
      0.022096244618296623,
      0.05647263675928116,
      -0.03570862486958504,
      -0.03790239244699478,
      -0.061903323978185654,
      -0.04323303699493408,
      -0.009143396280705929,
      -0.04110011085867882,
      0.0018724635010585189,
      0.024885980412364006,
      0.05197002738714218,
      -0.015550806187093258,
      -0.05213813856244087,
      0.02192096970975399,
      0.015172033570706844,
      -0.02363131381571293,
      0.01966278813779354,
      0.028896737843751907,
      0.03620796278119087,
      0.11973604559898376,
      -0.01933271996676922,
      0.009419560432434082,
      0.038019631057977676,
      0.09471248090267181,
      0.00952332466840744,
      -0.04333729296922684,
      -0.06369341164827347,
      -0.009387696161866188,
      -0.02404804527759552,
      -0.08250075578689575,
      -0.0027398865204304457,
      0.008255762979388237,
      -0.006747279316186905,
      0.01163219753652811,
      0.07066366821527481,
      -0.033252958208322525,
      0.008986053057014942,
      0.0031317139510065317,
      -0.05931498110294342,
      0.026643870398402214,
      0.010205810889601707,
      -0.020733686164021492,
      -0.02497304044663906,
      -0.0565425269305706,
      0.011587425135076046,
      0.006177929230034351,
      -0.0417417548596859,
      -0.05998677387833595,
      0.01203089114278555,
      0.022657521069049835,
      -0.05882012099027634,
      0.019404219463467598,
      0.026656750589609146,
      -0.01733681559562683,
      -0.03713246062397957,
      0.04923717677593231,
      -0.041129034012556076,
      0.005287613719701767,
      0.0023316771257668734,
      -0.049335356801748276,
      0.017647378146648407,
      -0.040411341935396194,
      0.02188584767282009,
      -0.10078547894954681,
      0.00678680557757616,
      0.07333515584468842,
      -0.01105198822915554,
      0.00030972526292316616,
      0.03240978345274925,
      -0.017252665013074875,
      0.11860445886850357,
      0.021797794848680496,
      -0.03136787563562393,
      0.0012496543349698186,
      0.018354542553424835,
      -0.0755242258310318,
      -0.02600666508078575,
      0.029496293514966965,
      0.02669450454413891,
      -0.05454881489276886,
      0.02642815373837948,
      -0.012209088541567326,
      -0.026086879894137383,
      -0.04624475911259651,
      -0.11078083515167236,
      -0.005723316688090563,
      -0.052241478115320206,
      0.03768669813871384,
      -0.031468771398067474,
      0.021924996748566628,
      0.03781570494174957,
      0.028110777959227562,
      0.026102988049387932,
      0.049107521772384644,
      -0.11456350982189178,
      -0.027008820325136185,
      0.03820133954286575,
      -0.04817197471857071,
      0.06351915001869202,
      0.004838462918996811,
      0.019572103396058083,
      0.057372383773326874,
      -0.01709751971065998,
      0.01842915453016758,
      0.08563586324453354,
      -0.012475423514842987,
      0.01790677197277546,
      0.09204867482185364,
      0.01041022501885891,
      0.025722522288560867,
      -0.009286184795200825,
      0.0008792142616584897,
      0.013487987220287323,
      -0.000691876164637506,
      -0.08342207223176956,
      -0.06429053097963333,
      -0.02166079357266426,
      -0.015198285691440105,
      0.029752396047115326,
      -0.002501498907804489,
      0.09433840215206146,
      0.04566202312707901,
      0.06685207784175873,
      0.0032898453064262867,
      -0.0642310306429863,
      0.04208104684948921,
      -0.014649104326963425,
      0.08114085346460342,
      0.029401803389191628,
      0.05496857315301895,
      0.02688850462436676,
      0.01632968708872795,
      0.02214561030268669,
      0.023591216653585434,
      0.00874332245439291,
      0.0625089704990387,
      0.04914506897330284,
      0.020578430965542793,
      -0.019429992884397507,
      -0.006158770062029362,
      0.06278709322214127,
      0.04377898946404457,
      0.10732963681221008,
      -0.06386791169643402,
      -0.05062219500541687,
      0.001077813794836402,
      0.034206073731184006,
      -0.007867345586419106,
      -0.009301476180553436,
      0.07151494175195694,
      -0.03621051833033562,
      0.05764484032988548,
      0.06756895780563354,
      0.027143631130456924,
      -0.031259339302778244,
      0.06798144429922104,
      0.011231130920350552,
      -0.06452645361423492,
      -0.038745149970054626,
      -0.10361803323030472,
      0.04868301749229431,
      0.014639520086348057,
      0.060403138399124146,
      -0.01828896626830101,
      -0.06329784542322159,
      -0.0049910335801541805,
      0.039383240044116974,
      0.003441963577643037,
      0.01763954944908619,
      0.005599098280072212,
      0.026446281000971794,
      0.02546466514468193,
      0.0017452139873057604,
      -0.03986089304089546,
      -0.09083273261785507,
      -0.004186457023024559,
      -0.02997593767940998,
      -0.01321001909673214,
      -0.011526428163051605,
      0.027340110391378403,
      0.05252915620803833,
      -0.05867766961455345,
      -0.009795045480132103,
      0.11367207765579224,
      0.06907840818166733,
      -0.05009904131293297,
      0.0061746020801365376,
      -0.06935203820466995,
      -0.03534762188792229,
      -0.027934279292821884,
      0.04654375836253166,
      -0.029533253982663155,
      -0.017543530091643333,
      -0.052335310727357864,
      0.03845061734318733,
      0.018432781100273132,
      -0.020687922835350037,
      -0.022271350026130676,
      0.013457369059324265,
      -0.06965464353561401,
      0.06590883433818817,
      0.0436352901160717
    ],
    [
      0.023933708667755127,
      0.015619376674294472,
      -0.06655819714069366,
      -0.025458453223109245,
      -0.01585114188492298,
      0.03753826022148132,
      0.06744006276130676,
      0.025100423023104668,
      -0.0186317041516304,
      0.028930727392435074,
      0.02936786785721779,
      -0.008320771157741547,
      0.04631127789616585,
      0.0338568277657032,
      0.0674484446644783,
      0.030780090019106865,
      -0.014689858071506023,
      -0.04184630513191223,
      -0.015476765111088753,
      0.025685062631964684,
      0.05693841353058815,
      0.014635689556598663,
      0.027191925793886185,
      0.004060082603245974,
      -0.04265984520316124,
      -0.02057868055999279,
      -0.04201781004667282,
      0.004103406332433224,
      -0.06136896461248398,
      -0.09593676775693893,
      -0.01802918314933777,
      0.07230895757675171,
      -0.015679508447647095,
      -0.023409558460116386,
      -0.01383749209344387,
      -0.04302665591239929,
      -0.020906949415802956,
      -0.029942568391561508,
      0.02557097002863884,
      0.0034142720978707075,
      -0.08304376900196075,
      0.027777181938290596,
      0.05577412620186806,
      -0.06773795187473297,
      -0.06050225719809532,
      0.07321800291538239,
      0.020457953214645386,
      0.006806069519370794,
      0.01623746007680893,
      0.009476118721067905,
      0.02417704649269581,
      0.07541555911302567,
      0.03579440712928772,
      0.014587349258363247,
      -0.03138495609164238,
      -0.016140703111886978,
      0.00746131082996726,
      -0.06790735572576523,
      0.04182862490415573,
      -0.002489604288712144,
      0.02019037865102291,
      -0.00408693915233016,
      -0.01580270193517208,
      0.031233971938490868,
      0.00876697525382042,
      -0.11375682055950165,
      -0.06474775820970535,
      -0.015825459733605385,
      0.05439077690243721,
      -0.04521574079990387,
      -0.06499399989843369,
      -0.07134709507226944,
      0.0019138623028993607,
      0.01835789903998375,
      0.005680553615093231,
      0.008353927172720432,
      0.02728835865855217,
      0.038670752197504044,
      0.05783606693148613,
      0.008940608240664005,
      0.050792671740055084,
      -0.08248233795166016,
      -0.011466975323855877,
      0.007237304467707872,
      0.060244131833314896,
      0.0011016824282705784,
      0.087862528860569,
      -0.029272038489580154,
      0.022235050797462463,
      -0.0541258230805397,
      0.11865953356027603,
      0.03342612087726593,
      0.09996546804904938,
      0.01900254562497139,
      -0.021803872659802437,
      0.013243239372968674,
      0.03236813098192215,
      -0.02202667109668255,
      -0.007210060488432646,
      -0.005864035338163376,
      -0.058106161653995514,
      0.013287506066262722,
      0.009564124047756195,
      -0.06545180827379227,
      0.011566081084311008,
      0.018069030717015266,
      -0.012323342263698578,
      0.06764137744903564,
      0.06105374917387962,
      -0.004021304193884134,
      -0.09147600829601288,
      -0.03750099614262581,
      0.007957466877996922,
      0.038561899214982986,
      -0.045906826853752136,
      -0.06871376186609268,
      0.09054691344499588,
      0.04034574702382088,
      -0.00846343208104372,
      -0.01396940741688013,
      0.0169262383133173,
      -0.07201232761144638,
      0.0033202716149389744,
      -0.0588863268494606,
      -0.030070748180150986,
      0.014346076175570488,
      -0.1091022789478302,
      -0.03192336857318878,
      0.08512713760137558,
      -0.036033786833286285,
      0.028286747634410858,
      -0.047419026494026184,
      -0.02338608168065548,
      0.0008538978290744126,
      -0.032006751745939255,
      0.0787573829293251,
      0.02329074591398239,
      -0.03193822503089905,
      -0.05264921858906746,
      0.036988139152526855,
      0.045688189566135406,
      -0.04365826025605202,
      -0.023249918594956398,
      -0.016903555020689964,
      0.10289540886878967,
      0.04536721855401993,
      -0.06098157912492752,
      0.014036575332283974,
      -0.026404062286019325,
      0.001485054031945765,
      0.0689912810921669,
      0.004927228204905987,
      0.041488371789455414,
      0.04252009838819504,
      -0.06733443588018417,
      -0.02315049059689045,
      0.09273165464401245,
      -0.0016281316056847572,
      0.03882346302270889,
      0.027855167165398598,
      -0.001029323902912438,
      -0.05270211771130562,
      0.019551487639546394,
      0.06877468526363373,
      0.1363207846879959,
      0.0720117911696434,
      -0.0544399730861187,
      0.02111782319843769,
      -0.023779982700943947,
      0.021703572943806648,
      0.04014226049184799,
      -0.001450111623853445,
      0.003979801200330257,
      0.02813841588795185,
      0.01933947391808033,
      -0.03971785306930542,
      0.000310554780298844,
      -0.04738733544945717,
      0.06426253914833069,
      0.033891282975673676,
      0.04541411250829697,
      0.03624499589204788,
      -0.03811616450548172,
      -0.0075888377614319324,
      0.017646856606006622,
      -0.006354544777423143,
      0.0021774880588054657,
      0.014424877241253853,
      0.04400844871997833,
      0.0224106777459383,
      -0.03263095021247864,
      0.0039875986985862255,
      -0.01850258931517601,
      -0.06593183428049088,
      -0.014765487052500248,
      -0.008475367911159992,
      0.03599868342280388,
      -0.021604107692837715,
      0.021163757890462875,
      -0.02110598050057888,
      -0.020957138389348984,
      0.04461820051074028,
      0.019431548193097115,
      -0.004934824071824551,
      0.010931916534900665,
      -0.006155158393085003,
      -0.031230002641677856,
      -0.04811093583703041,
      0.008867300115525723,
      0.018327290192246437,
      0.0221137423068285,
      -0.01077394001185894,
      -0.019581569358706474,
      0.028368940576910973,
      -0.040975093841552734,
      -0.0389021560549736,
      0.029651649296283722,
      -0.025367453694343567,
      0.04734323173761368,
      -0.031165532767772675,
      0.05327197536826134,
      -0.029907584190368652,
      -0.021908383816480637,
      -0.042908746749162674,
      -0.056901153177022934,
      0.01177512388676405,
      0.08075477927923203,
      -0.02788369357585907,
      0.047028981149196625,
      -0.15437515079975128,
      -0.01168560329824686,
      0.035021934658288956,
      -0.05211513489484787,
      -0.04379205033183098,
      -0.06453970074653625,
      0.04671907052397728,
      -0.02939612790942192,
      0.013348865322768688,
      -0.0014493874041363597,
      -0.058367565274238586,
      0.04226045683026314,
      0.051984380930662155,
      0.006063878536224365,
      -0.09123658388853073,
      -0.0355745330452919,
      -0.02098231203854084,
      -0.022467970848083496,
      -0.021994857117533684,
      -0.03140696510672569,
      0.02539646252989769,
      -0.0235210619866848,
      0.021199263632297516,
      -0.054728325456380844,
      -0.04803856834769249,
      0.07381940633058548,
      0.07930481433868408,
      0.05045634135603905,
      0.08934345841407776,
      0.0931641161441803,
      0.027320263907313347,
      0.08253974467515945,
      -0.023429740220308304,
      -0.0299919955432415,
      -0.033473458141088486,
      -0.009913048706948757,
      -0.021795323118567467,
      -0.036923784762620926,
      -0.02368220128118992,
      0.07646593451499939,
      -0.023418735712766647,
      -0.06641370058059692,
      -0.05362797901034355,
      0.05151607096195221,
      -0.00934247300028801,
      0.035709939897060394,
      -0.020532630383968353,
      -0.002009148010984063,
      0.007032984867691994,
      -0.04909152537584305,
      0.014628484845161438,
      0.07057445496320724,
      0.03921252489089966,
      0.09941735863685608,
      -0.0020196260884404182,
      -0.0442395955324173,
      -0.006188380531966686,
      0.07732797414064407,
      -0.1197081059217453,
      0.0032941673416644335,
      -0.021242983639240265,
      0.020549288019537926,
      -0.05109475553035736,
      0.08138346672058105,
      0.03193242475390434,
      0.06414441019296646,
      -0.08046469837427139,
      0.003471981966868043,
      -0.0015621418133378029,
      -0.028098808601498604,
      -0.007153969258069992,
      0.021867455914616585,
      0.1008189469575882,
      0.06706985086202621,
      0.016819395124912262,
      -0.014963340014219284,
      0.01167543325573206,
      -0.09612730890512466,
      -0.08642340451478958,
      -0.01327022910118103,
      0.043655045330524445,
      -0.001752904150635004,
      0.026153989136219025,
      -0.0238900575786829,
      -0.06762947142124176,
      -0.011109872721135616,
      0.017494186758995056,
      0.03572564572095871,
      -0.04036202281713486,
      0.046394601464271545,
      0.015174632892012596,
      0.05156261846423149,
      0.04625295475125313,
      0.04277607426047325,
      0.0061180065385997295,
      0.017740001901984215,
      -0.06066424772143364,
      -0.08918523788452148,
      -0.06584558635950089,
      0.030113723129034042,
      0.03461744263768196,
      -0.01978399232029915,
      -0.0021811805199831724,
      -0.020718712359666824,
      -0.006690728943794966,
      0.015097803436219692,
      0.07208527624607086,
      -0.06060648337006569,
      -0.008059418760240078,
      -0.07224034518003464,
      -0.08483678847551346,
      0.03153695538640022,
      0.043328769505023956,
      -0.006310100667178631,
      0.06617283076047897,
      -0.02703363075852394,
      -0.024181947112083435,
      -0.04935427010059357,
      -0.04063626006245613,
      0.0051675825379788876,
      0.015605838038027287,
      -0.045567385852336884,
      -0.01705974154174328,
      -0.008535142987966537,
      0.08455383032560349,
      0.06276379525661469,
      0.06413114815950394,
      -0.05320597440004349,
      0.016555048525333405,
      0.006821024231612682,
      -0.0017385047394782305,
      0.051620401442050934,
      0.07515092939138412,
      -0.03947897255420685,
      -0.042088981717824936,
      0.01068803295493126,
      0.007008983287960291,
      -0.047309376299381256,
      0.06311215460300446,
      0.018331769853830338,
      -0.07740270346403122,
      -0.03536885231733322,
      -0.02757704071700573,
      -0.008454997092485428,
      -0.04639375954866409,
      -0.01251761894673109,
      -0.02192193828523159,
      -0.0489216186106205,
      -0.04096869379281998,
      0.04115089029073715,
      0.005449169781059027,
      -0.03272586315870285,
      -0.022624943405389786,
      0.05760399252176285,
      0.06667669862508774,
      0.009123838506639004,
      0.025596126914024353,
      -0.015853488817811012,
      -0.0022159754298627377,
      0.0042549557983875275,
      -0.05548490956425667,
      0.017803100869059563,
      -0.02226513996720314,
      0.05877862498164177,
      -0.034651853144168854,
      -0.049424778670072556,
      0.01324751228094101,
      -0.013442864641547203,
      0.0661487877368927,
      -0.036132100969552994,
      0.02559085190296173,
      0.01491758693009615,
      -0.058954328298568726,
      0.0007121461676433682,
      -0.02122330851852894,
      0.05915631353855133,
      0.011516793631017208,
      0.027161724865436554,
      0.03058709017932415,
      0.038866862654685974,
      0.038099344819784164,
      -0.021581778302788734,
      0.019168198108673096,
      -0.0014498643577098846,
      0.00016200827667489648,
      0.045571669936180115,
      -0.041884854435920715,
      -0.05316683277487755,
      -0.044277340173721313,
      -0.02297424152493477,
      0.007661983370780945,
      -0.039281103760004044,
      0.018502380698919296,
      -0.020065415650606155,
      -0.010599667206406593,
      -0.01815679855644703,
      -0.023390989750623703,
      -0.0609501451253891,
      -0.012517188675701618,
      0.08255414664745331,
      0.033843282610177994,
      -0.03646992892026901,
      0.046511758118867874,
      -0.034885987639427185,
      -0.004761435557156801,
      -0.004911988042294979,
      -0.014932353980839252,
      -0.13921889662742615,
      -0.004847079049795866,
      -0.07492493838071823,
      -0.0026090266183018684,
      0.09203518182039261,
      0.044136740267276764,
      0.02481752634048462,
      0.07345114648342133,
      -0.017322637140750885,
      0.0634799376130104,
      0.026553846895694733,
      0.0469232015311718,
      0.012576358392834663,
      -0.008958213031291962,
      0.01500345952808857,
      -0.06625079363584518,
      0.07739679515361786,
      0.011180980131030083,
      -0.0901573970913887,
      -0.0679047480225563,
      0.006238226313143969,
      0.006986984051764011,
      0.027170367538928986,
      -0.04539833590388298,
      -0.016452472656965256,
      -0.039236485958099365,
      -0.03060843050479889,
      0.024197345599532127,
      -0.03556259348988533,
      0.013771696016192436,
      -0.0013455792795866728,
      0.043394021689891815,
      -0.010893270373344421,
      -0.08400049060583115,
      0.0967949703335762,
      -0.004625280853360891,
      -0.06473029404878616,
      0.0018309062579646707,
      -0.030259743332862854,
      -0.01850605569779873,
      0.07007478177547455,
      -0.020662497729063034,
      0.03165775537490845,
      0.08217478543519974,
      -0.03942551836371422,
      0.08326146006584167,
      -0.020497458055615425,
      0.05497119203209877,
      -0.02718180976808071,
      0.028789306059479713,
      -0.07028985768556595,
      -0.025909285992383957,
      -0.0334242582321167,
      0.009835709817707539,
      -0.020567648112773895,
      0.09499770402908325,
      -0.00508787389844656,
      0.007383472286164761,
      0.03539184108376503,
      -0.008701136335730553,
      -0.052386607974767685,
      0.014600479044020176,
      -0.013049032539129257,
      0.004268927033990622,
      0.042419031262397766,
      0.03780284896492958,
      -0.026707882061600685
    ],
    [
      0.05271456763148308,
      0.038541439920663834,
      -0.04670216143131256,
      0.08120805770158768,
      0.07547874748706818,
      0.005259771831333637,
      0.03383200243115425,
      0.001183351268991828,
      -0.06028159335255623,
      -0.0012225593673065305,
      -0.047815848141908646,
      0.013768392615020275,
      0.029874689877033234,
      -0.03712017834186554,
      0.07523681968450546,
      -0.038297683000564575,
      0.008709914982318878,
      -0.050817638635635376,
      -0.007709131110459566,
      0.03736696392297745,
      0.014157986268401146,
      -0.0008368762792088091,
      0.03239775076508522,
      -0.02156250923871994,
      0.049446024000644684,
      -0.0019907518289983273,
      0.011097785085439682,
      0.022733956575393677,
      0.10288038104772568,
      -0.07397612184286118,
      0.08428534120321274,
      -0.010596792213618755,
      -0.0003907839418388903,
      -0.02295524813234806,
      0.07783996313810349,
      -0.05738377198576927,
      0.06429208815097809,
      -0.062454938888549805,
      -0.008372261188924313,
      0.07166966795921326,
      0.05487555265426636,
      -0.06792689114809036,
      -0.06458450853824615,
      0.06466680765151978,
      -0.09311220049858093,
      0.10164860635995865,
      0.12334534525871277,
      0.025362784042954445,
      -0.00523787084966898,
      0.04095984995365143,
      0.02064570039510727,
      0.00861603394150734,
      -0.05042590945959091,
      0.044303517788648605,
      -0.08546808362007141,
      0.014537140727043152,
      0.09591826796531677,
      0.03801492601633072,
      0.05388079211115837,
      -0.021021191030740738,
      0.10909374058246613,
      0.03739836812019348,
      -0.05252547934651375,
      0.02697465755045414,
      -0.015469416975975037,
      -0.026154041290283203,
      0.028842328116297722,
      -0.056859683245420456,
      -0.00653739832341671,
      -0.0004833247803617269,
      -0.01635216921567917,
      0.03163285553455353,
      -0.019409196451306343,
      -0.009750920347869396,
      0.06962814927101135,
      0.018260614946484566,
      0.039793916046619415,
      0.07111605256795883,
      0.022957447916269302,
      -0.03526856750249863,
      -0.09672188758850098,
      -0.039374783635139465,
      -0.006106054875999689,
      0.05520462989807129,
      0.0465061292052269,
      0.09723499417304993,
      -0.03692267835140228,
      0.06988729536533356,
      -0.03577618673443794,
      -0.047040101140737534,
      0.014324789866805077,
      -0.0551447868347168,
      0.05413409695029259,
      -0.022233935073018074,
      -0.031415216624736786,
      0.010695106349885464,
      -0.025407951325178146,
      0.012695659883320332,
      0.03324281796813011,
      0.042682964354753494,
      0.00193734688218683,
      0.035784825682640076,
      -0.0455576591193676,
      -0.08273819833993912,
      -0.0735650509595871,
      -0.029704749584197998,
      0.04157266393303871,
      0.03949514031410217,
      0.09636799246072769,
      -0.03427453339099884,
      0.046887386590242386,
      -0.0002019920211751014,
      0.002138037234544754,
      0.022618187591433525,
      0.01834101788699627,
      -0.06387266516685486,
      0.07648548483848572,
      0.12612700462341309,
      0.09810585528612137,
      0.04764123260974884,
      -0.062085408717393875,
      0.015025035478174686,
      0.027459250763058662,
      -0.050694067031145096,
      0.02104928530752659,
      -0.011931880377233028,
      -0.04514084383845329,
      -0.07327266037464142,
      -0.0468263104557991,
      0.04729489982128143,
      0.007221627049148083,
      -0.02818877436220646,
      -0.016667861491441727,
      -0.015383522026240826,
      0.036852460354566574,
      -0.0734868124127388,
      -0.07709889858961105,
      -0.012912487611174583,
      -0.014960122294723988,
      -0.014474336989223957,
      0.005376062821596861,
      0.09944477677345276,
      0.014193406328558922,
      0.071684829890728,
      -0.045856866985559464,
      0.017994174733757973,
      0.014553762972354889,
      0.03080272302031517,
      -0.04632185399532318,
      0.04669477045536041,
      -0.01187756098806858,
      0.01481508556753397,
      -0.0013556246412917972,
      0.05927971377968788,
      -0.04348929598927498,
      -0.013263544999063015,
      0.013597018085420132,
      0.0009656012407504022,
      -0.057866521179676056,
      -0.02621900849044323,
      -0.093465156853199,
      0.031239455565810204,
      -0.0059930686838924885,
      0.05110935494303703,
      -0.020082147791981697,
      0.013718290254473686,
      0.031855300068855286,
      0.028592776507139206,
      0.03893810138106346,
      -0.06355245411396027,
      -0.012692439369857311,
      -0.025488706305623055,
      -0.016241157427430153,
      -0.012108305469155312,
      0.04436638206243515,
      -0.020507918670773506,
      0.046613533049821854,
      -0.003969003912061453,
      -0.040431126952171326,
      0.039914947003126144,
      0.024603599682450294,
      -0.015164152719080448,
      -0.06788233667612076,
      -0.07915368676185608,
      -0.03431645780801773,
      0.05924433469772339,
      0.010183792561292648,
      0.006714212242513895,
      0.025141531601548195,
      -0.03994404524564743,
      0.021669704467058182,
      0.014864526689052582,
      -0.05253224074840546,
      -0.022560756653547287,
      0.1421731561422348,
      -0.02542063780128956,
      -0.05248992145061493,
      -0.013259400613605976,
      0.00733440974727273,
      0.056481871753931046,
      0.08602099120616913,
      0.031563930213451385,
      -0.009712695144116879,
      -0.028031721711158752,
      0.0015958927106112242,
      -0.04506727680563927,
      -0.02436831220984459,
      -0.011381929740309715,
      0.05473132058978081,
      0.016248345375061035,
      0.03159067779779434,
      -0.043998900800943375,
      -0.017717860639095306,
      0.06379617005586624,
      -0.05088121071457863,
      0.0012323339469730854,
      -0.020920420065522194,
      -0.06392321735620499,
      -0.03365461900830269,
      0.0015320242382586002,
      0.018381305038928986,
      0.06357946246862411,
      0.07370620965957642,
      -0.02572960965335369,
      0.045436661690473557,
      -0.0771750658750534,
      0.03054157830774784,
      0.049351733177900314,
      0.05910501256585121,
      -0.021968409419059753,
      -0.025359507650136948,
      -0.022435087710618973,
      -0.004476496484130621,
      -0.0773627758026123,
      -0.038625556975603104,
      0.0015014931559562683,
      -0.0052160825580358505,
      0.013628954999148846,
      -0.03259548917412758,
      -0.021444223821163177,
      -0.051408801227808,
      0.015776170417666435,
      -0.0480535551905632,
      0.007468446623533964,
      -0.02129972167313099,
      0.0049119614996016026,
      -0.012928636744618416,
      0.0012617000611498952,
      0.06383867561817169,
      0.07967793196439743,
      0.031409915536642075,
      0.011863652616739273,
      -0.00615856796503067,
      0.020547132939100266,
      -0.03384311869740486,
      0.036607611924409866,
      -0.00916328839957714,
      0.049959346652030945,
      0.008329993113875389,
      0.012484013102948666,
      -0.01276526041328907,
      0.024928543716669083,
      0.06935287266969681,
      -0.020601511001586914,
      0.02499404177069664,
      0.03290528804063797,
      -0.0037986396346241236,
      0.006286515388637781,
      0.018270418047904968,
      0.008497446775436401,
      0.00015462991723325104,
      -0.04740740358829498,
      -0.006668573711067438,
      0.06392018496990204,
      -0.026241658255457878,
      -0.03182535991072655,
      0.05035894736647606,
      0.011175156570971012,
      0.0696234181523323,
      -0.008234224282205105,
      0.021424155682325363,
      -0.05923115089535713,
      0.02307921275496483,
      0.02066875249147415,
      -0.03190739080309868,
      -0.09842827916145325,
      -0.01212192140519619,
      -0.07510971277952194,
      -0.007589694578200579,
      -0.005351988598704338,
      -0.011036962270736694,
      -0.045515190809965134,
      -0.01492216344922781,
      0.057581059634685516,
      0.09261821955442429,
      -0.02172536589205265,
      -0.012178913690149784,
      -0.05551094189286232,
      -0.0060012065805494785,
      -0.11514938622713089,
      0.0420246459543705,
      -0.007881906814873219,
      0.0909615084528923,
      0.007050208747386932,
      0.002549191238358617,
      -0.0564529225230217,
      -0.04163504019379616,
      -0.029843078926205635,
      0.019793517887592316,
      -0.051153264939785004,
      0.012688077986240387,
      -0.03360820561647415,
      -0.010066520422697067,
      -0.08602388948202133,
      0.004007282201200724,
      -0.01006697304546833,
      -0.04768858477473259,
      0.01836269162595272,
      0.09089069068431854,
      -0.0011357949115335941,
      0.09570454061031342,
      0.014520023949444294,
      -0.006328993942588568,
      0.006930724252015352,
      -0.04147890582680702,
      0.0235883928835392,
      0.08344104140996933,
      0.0020675528794527054,
      -0.03596317768096924,
      0.0497986301779747,
      0.03263227269053459,
      0.06958409398794174,
      -0.00608535623177886,
      0.10420773923397064,
      -0.027721470221877098,
      -0.024780727922916412,
      -0.11538846045732498,
      -0.010391832329332829,
      0.09320256859064102,
      0.034525517374277115,
      -0.032164301723241806,
      0.015165108256042004,
      0.047485895454883575,
      -0.08343295007944107,
      -0.006607880350202322,
      -0.026097726076841354,
      0.02452140487730503,
      0.0029703001491725445,
      -0.0002618352882564068,
      -0.038117747753858566,
      -0.0024754609912633896,
      0.00380161521025002,
      -0.05462552607059479,
      -0.03126824274659157,
      -0.05687299370765686,
      0.051148708909749985,
      -0.09294124692678452,
      0.0037971537094563246,
      -0.03342698514461517,
      -0.00512545695528388,
      -0.08677274733781815,
      0.07574212551116943,
      -0.04702503979206085,
      0.006698815152049065,
      0.016148928552865982,
      0.04755237326025963,
      -0.061467673629522324,
      -0.02223089151084423,
      0.023526793345808983,
      -0.03384482115507126,
      0.026139268651604652,
      0.00537464814260602,
      -0.0019946948159486055,
      0.008330906741321087,
      -0.048185933381319046,
      0.02321464754641056,
      0.0231038685888052,
      0.030278412625193596,
      0.0022286083549261093,
      -0.01901155710220337,
      -0.06646180152893066,
      -0.04630675166845322,
      0.0020401007495820522,
      0.0032393562141805887,
      -0.07084742933511734,
      0.04132325202226639,
      -0.023681005463004112,
      0.01923432946205139,
      0.014968972653150558,
      0.035956792533397675,
      0.007934700697660446,
      -0.04387924447655678,
      -0.0060803573578596115,
      -0.00044241893920116127,
      -0.03656584769487381,
      0.011230604723095894,
      -0.05818605050444603,
      -0.008482441306114197,
      -0.09228911995887756,
      0.07895883172750473,
      0.05570963770151138,
      0.03229057416319847,
      0.011298616416752338,
      -0.04288244992494583,
      0.0001751678646542132,
      -0.01127464510500431,
      -0.0016528618289157748,
      0.03290969505906105,
      -0.012647382915019989,
      -0.025702128186821938,
      0.02560615912079811,
      -0.030959593132138252,
      0.054325755685567856,
      0.0330839566886425,
      -0.017508625984191895,
      -0.008278491906821728,
      0.049766186624765396,
      0.022442076355218887,
      -0.0106786098331213,
      -0.010794340632855892,
      0.05576688423752785,
      0.02959754690527916,
      0.025234486907720566,
      0.07003568112850189,
      -0.012142423540353775,
      -0.013051576912403107,
      0.02577809803187847,
      -0.0016320700524374843,
      0.016859551891684532,
      0.02307046391069889,
      -0.1085318997502327,
      0.016795499250292778,
      -0.0234975703060627,
      -0.007494754623621702,
      0.07169104367494583,
      0.031674351543188095,
      0.018452666699886322,
      0.044222816824913025,
      0.0732024759054184,
      0.008509538136422634,
      -0.05095217376947403,
      0.005600875709205866,
      -0.025854090228676796,
      0.09554423391819,
      -0.03978278860449791,
      -0.04151352867484093,
      0.049754802137613297,
      -0.009229222312569618,
      0.03241865336894989,
      0.01358846202492714,
      -0.05149880424141884,
      -0.04718311131000519,
      -0.0008927986491471529,
      -0.06801137328147888,
      0.0330343134701252,
      0.013470360077917576,
      -0.034261491149663925,
      0.029919996857643127,
      -0.018088586628437042,
      0.08411478996276855,
      -0.008992395363748074,
      -0.002921918872743845,
      -0.01370264869183302,
      0.05289582535624504,
      -0.021968897432088852,
      -0.017373615875840187,
      -0.05723557248711586,
      -0.06819146871566772,
      -0.06070913001894951,
      -0.004246375523507595,
      0.020854227244853973,
      -0.07103949785232544,
      0.010523762553930283,
      0.018332306295633316,
      0.0009924183832481503,
      -0.006697744131088257,
      -0.030446743592619896,
      0.01134004071354866,
      -0.09114892780780792,
      0.013679672963917255,
      0.044293634593486786,
      0.0046287463046610355,
      0.007035804912447929,
      0.062494587153196335,
      -0.038843221962451935,
      -0.03171096742153168,
      -0.009939375333487988,
      0.010584423318505287,
      0.05370117723941803,
      0.04728064313530922,
      -0.024601047858595848,
      0.05983337014913559,
      -0.051776692271232605,
      -0.039510536938905716,
      0.0031914557330310345,
      -0.04300360754132271,
      -0.002519076457247138,
      0.0220236424356699,
      0.019103603437542915,
      -0.01947108656167984,
      -0.02732938900589943,
      -0.025197571143507957,
      0.04667726159095764
    ],
    [
      0.0012170763220638037,
      -0.0418069027364254,
      0.024445971474051476,
      -0.000822079717181623,
      0.01941685564815998,
      0.023256409913301468,
      0.048739127814769745,
      -0.022536134347319603,
      -0.0703507661819458,
      0.011520633473992348,
      -0.05398816615343094,
      -0.026360368356108665,
      -0.011396187357604504,
      0.024428732693195343,
      0.05820942670106888,
      0.049266207963228226,
      0.09490817785263062,
      -0.07466364651918411,
      -0.1540450006723404,
      0.04485529288649559,
      -0.061703044921159744,
      -0.03966732323169708,
      0.03785030171275139,
      0.03237765654921532,
      0.01828903891146183,
      -0.05091569200158119,
      -0.060697752982378006,
      -0.026062531396746635,
      -0.01212981902062893,
      0.02359730377793312,
      0.02632261998951435,
      -0.014567040838301182,
      0.12500658631324768,
      0.02158377133309841,
      -0.08920875936746597,
      -0.02200772985816002,
      -0.01172630675137043,
      0.02202235721051693,
      -0.032358165830373764,
      0.05302511900663376,
      0.03483138605952263,
      0.04379419609904289,
      0.007160727400332689,
      0.06579529494047165,
      -0.01688511110842228,
      -0.011816962622106075,
      0.07199671119451523,
      -0.022282861173152924,
      0.027708033099770546,
      -0.02896413579583168,
      0.03948375955224037,
      -0.0008460092358291149,
      -0.052257779985666275,
      -0.005766283255070448,
      -0.008754304610192776,
      0.06772879511117935,
      -0.008627659641206264,
      0.016952401027083397,
      -0.04524964839220047,
      0.0032552173361182213,
      -0.008344017900526524,
      0.01797228492796421,
      -0.030484965071082115,
      -0.004795233719050884,
      -0.07565613836050034,
      -0.03016861341893673,
      0.06730373948812485,
      -0.0031466842629015446,
      -0.11123012751340866,
      0.05859426409006119,
      -0.027693811804056168,
      -0.047979358583688736,
      -0.006357453763484955,
      0.0036086204927414656,
      -0.06960832327604294,
      0.0371960811316967,
      0.001218227087520063,
      0.07850421965122223,
      0.02055457979440689,
      -0.044562436640262604,
      -0.016281133517622948,
      0.026780109852552414,
      0.0331011563539505,
      -0.057229138910770416,
      0.032359395176172256,
      -0.06261247396469116,
      -0.006261628586798906,
      -0.06145647168159485,
      -0.003941087517887354,
      0.057285796850919724,
      0.0467984639108181,
      0.09496049582958221,
      0.10137055814266205,
      -0.045385681092739105,
      0.10423937439918518,
      0.01367704663425684,
      -0.024802640080451965,
      -0.02904360555112362,
      0.03887741267681122,
      0.0440215989947319,
      0.03782746195793152,
      -0.06799591332674026,
      0.01669357717037201,
      -0.006173428148031235,
      0.033901095390319824,
      0.003471523290500045,
      0.02231433056294918,
      0.0029176748357713223,
      0.08544086664915085,
      -0.0220998115837574,
      0.03642500564455986,
      -0.02797665446996689,
      -0.014008070342242718,
      0.03011449985206127,
      -0.03925856202840805,
      0.07843470573425293,
      0.052978403866291046,
      0.010638871230185032,
      0.016121001914143562,
      -0.011324145831167698,
      -0.06703334301710129,
      -0.037758491933345795,
      0.011469987221062183,
      -0.021764544770121574,
      -0.009514853358268738,
      -0.04864441603422165,
      0.007095541805028915,
      -0.04259767755866051,
      0.05773679167032242,
      0.003048043930903077,
      0.033740364015102386,
      -0.06811238080263138,
      0.006347861606627703,
      -0.09490250051021576,
      -0.028014736250042915,
      -0.019690794870257378,
      0.022537413984537125,
      0.03396361693739891,
      -0.024983474984765053,
      0.003296770853921771,
      0.00568830780684948,
      -0.020919466391205788,
      0.02510274574160576,
      0.011300384066998959,
      -0.018684525042772293,
      0.021907657384872437,
      -0.00958955753594637,
      -0.06672829389572144,
      -0.06517990678548813,
      0.009022561833262444,
      -0.0482245609164238,
      -0.009357250295579433,
      -0.01386540662497282,
      0.042744606733322144,
      0.05325733870267868,
      -0.05791330337524414,
      -0.006219895556569099,
      0.0006674008327536285,
      0.028543777763843536,
      -0.018041882663965225,
      0.012524326331913471,
      -0.02370784804224968,
      0.04985235258936882,
      -0.004772083833813667,
      0.020535310730338097,
      0.03966283053159714,
      -0.04047755151987076,
      -0.08200237900018692,
      0.08661413937807083,
      0.04018205776810646,
      -0.008338597603142262,
      0.017296772450208664,
      0.01507431361824274,
      -0.03111988492310047,
      -0.0400356687605381,
      -0.005492360796779394,
      0.004915053490549326,
      0.06113368272781372,
      0.04476211220026016,
      -0.02528889663517475,
      0.024881035089492798,
      0.04494388401508331,
      -0.012713433243334293,
      -0.006511332932859659,
      0.029314914718270302,
      0.006068270653486252,
      -0.0118907131254673,
      0.013435440137982368,
      0.005285103339701891,
      0.0016530858119949698,
      -0.03004857711493969,
      0.048883236944675446,
      0.034892670810222626,
      -0.040050916373729706,
      0.03358018025755882,
      0.04322051256895065,
      -0.019953608512878418,
      -0.018842898309230804,
      0.00783690344542265,
      0.006922069471329451,
      -0.014969020150601864,
      0.05291612818837166,
      0.06443268060684204,
      -0.023335477337241173,
      0.06221947818994522,
      0.004916055593639612,
      0.014863674528896809,
      0.002444080775603652,
      0.12514443695545197,
      0.029974957928061485,
      0.06896532326936722,
      -0.06095002591609955,
      -0.04567423835396767,
      -0.007209915202111006,
      -0.010431462898850441,
      0.0910709798336029,
      -0.004650743678212166,
      -0.06152116507291794,
      0.009515938349068165,
      -0.026181265711784363,
      0.007995395921170712,
      -0.0340016707777977,
      -0.0345856137573719,
      0.009639279916882515,
      -0.023667041212320328,
      -0.03497215732932091,
      0.032949693500995636,
      0.02384066767990589,
      0.014136777259409428,
      -0.0020145403686910868,
      -0.00046855193795636296,
      -0.04832812026143074,
      -0.02113613300025463,
      0.013017036952078342,
      0.04708195477724075,
      -0.022578608244657516,
      -0.0672435387969017,
      -0.0011853011092171073,
      0.11583557724952698,
      0.025068262591958046,
      -0.021700352430343628,
      0.003968312405049801,
      0.025620833039283752,
      -0.009777151979506016,
      0.04978891462087631,
      0.08930657058954239,
      0.01348346471786499,
      -0.015516865998506546,
      0.016259776428341866,
      -0.03125589340925217,
      -0.02332572638988495,
      0.014047878794372082,
      -0.005663533229380846,
      0.016791870817542076,
      -0.0022805677726864815,
      0.04732802137732506,
      -0.10507989674806595,
      0.05759704113006592,
      0.003808639943599701,
      -0.0007699016714468598,
      -0.013636345975100994,
      -0.031890831887722015,
      -0.03466164693236351,
      -0.013367739506065845,
      -0.06456305831670761,
      -0.05164266377687454,
      0.04011719673871994,
      -0.040814660489559174,
      -0.017531339079141617,
      0.07472889870405197,
      0.007978104054927826,
      0.08887021243572235,
      -0.004232954233884811,
      -0.008902824483811855,
      -0.00046297753578983247,
      -0.06828945875167847,
      -0.021688727661967278,
      0.01491064764559269,
      -0.07097689062356949,
      -0.049246300011873245,
      0.027898427098989487,
      0.04756475239992142,
      -0.010951719246804714,
      -0.048925891518592834,
      -0.016027063131332397,
      0.023655828088521957,
      0.0024556235875934362,
      -0.03484824299812317,
      0.0011289695976302028,
      0.09293311834335327,
      -0.07190723717212677,
      0.1036991998553276,
      -0.03698478639125824,
      -0.061644814908504486,
      -0.06572289019823074,
      -0.04632022604346275,
      -0.01586141064763069,
      0.058682605624198914,
      -0.05156558379530907,
      0.032477784901857376,
      -0.05208076164126396,
      -0.025914443656802177,
      -0.0028484780341386795,
      0.05312503129243851,
      -0.01138544362038374,
      0.011704366654157639,
      0.02005324698984623,
      -0.020066749304533005,
      -0.06552338600158691,
      -0.0774616152048111,
      -0.037229984998703,
      0.002728130202740431,
      0.032343413680791855,
      0.05050879716873169,
      0.032336555421352386,
      -0.008949427865445614,
      0.0047353943809866905,
      -0.04420911520719528,
      0.04221755266189575,
      0.016969194635748863,
      0.05644196271896362,
      -0.020169392228126526,
      0.01797010935842991,
      0.050056036561727524,
      -0.013481497764587402,
      -0.0023036713246256113,
      -0.04065850004553795,
      -0.043424732983112335,
      -0.05897826328873634,
      0.05013478174805641,
      -0.022568656131625175,
      0.0020409489516168833,
      -0.025452537462115288,
      -0.013132553547620773,
      -0.032734617590904236,
      -0.0416494756937027,
      -0.0684824287891388,
      0.058106809854507446,
      -0.02974831499159336,
      0.02943575568497181,
      -0.00863106269389391,
      0.02535475417971611,
      -0.04057733342051506,
      0.029527144506573677,
      0.028688129037618637,
      -0.04904836788773537,
      0.020694909617304802,
      0.07625776529312134,
      -0.027641257271170616,
      -0.0014540284173563123,
      -0.04729773476719856,
      -0.07160819321870804,
      0.056907545775175095,
      0.019390439614653587,
      -0.028394347056746483,
      -0.06596066802740097,
      0.08439337462186813,
      0.013487128540873528,
      0.04954607039690018,
      0.016589103266596794,
      0.008048010058701038,
      -0.0452793687582016,
      0.009650077670812607,
      -0.09070958197116852,
      0.0028335158713161945,
      0.024750523269176483,
      0.05616451054811478,
      0.12337970733642578,
      -0.0074090128764510155,
      -0.027147255837917328,
      -0.012391299940645695,
      0.019579274579882622,
      -0.0821266621351242,
      0.04227554053068161,
      0.039249081164598465,
      0.04079565405845642,
      -0.0016228677704930305,
      0.04903528839349747,
      0.020856201648712158,
      -0.018232684582471848,
      -0.04305334389209747,
      -0.07793348282575607,
      0.07330866903066635,
      -0.00010482236393727362,
      -0.005656613502651453,
      0.03983481600880623,
      -0.030463524162769318,
      -0.03819235414266586,
      0.02984234131872654,
      -0.028796084225177765,
      0.04838995635509491,
      0.008360633626580238,
      -0.04377249255776405,
      0.05590038746595383,
      0.007879397831857204,
      -0.06536862254142761,
      0.037141747772693634,
      0.040191635489463806,
      0.023306632414460182,
      0.06474563479423523,
      -0.05901959165930748,
      0.04111775383353233,
      -0.04564642533659935,
      -0.02548256888985634,
      0.0419045016169548,
      -0.005457960534840822,
      0.02236444689333439,
      0.045165516436100006,
      -0.06500818580389023,
      0.014982447028160095,
      -0.00925829354673624,
      -0.03700801730155945,
      -0.006407455075532198,
      0.07060886174440384,
      0.046924956142902374,
      -0.022273141890764236,
      -0.005005508195608854,
      -0.0030266698449850082,
      0.016082288697361946,
      -0.06348276883363724,
      0.009679317474365234,
      -0.0026808707043528557,
      0.012054209597408772,
      0.06706999242305756,
      0.0345950610935688,
      -0.009505372494459152,
      0.030362499877810478,
      -0.06309936940670013,
      -0.032326873391866684,
      -0.03893786296248436,
      0.013372674584388733,
      0.02460593543946743,
      -0.01506105251610279,
      0.07325933128595352,
      -0.0606410950422287,
      -0.013306793756783009,
      0.01657314971089363,
      -0.00422265799716115,
      0.06637522578239441,
      -0.019004371017217636,
      -0.036868661642074585,
      -0.05827292799949646,
      -0.09091117978096008,
      -0.052458349615335464,
      0.006667191628366709,
      -0.018869437277317047,
      0.05554351210594177,
      0.08437807857990265,
      -0.026771539822220802,
      0.016828443855047226,
      0.001298887305893004,
      -0.0329558439552784,
      0.017531881108880043,
      0.11127414554357529,
      0.018352264538407326,
      -0.006876032333821058,
      -0.04300400987267494,
      0.04044342413544655,
      0.02071034535765648,
      -0.07440032064914703,
      0.025312917307019234,
      -0.04087337851524353,
      -0.07302386313676834,
      -0.03772139176726341,
      0.02137800306081772,
      -0.0185660719871521,
      -0.04272317513823509,
      0.0529060885310173,
      -0.05199315771460533,
      0.013477037660777569,
      0.006710098125040531,
      -0.019848939031362534,
      -0.10616297274827957,
      0.09017935395240784,
      0.04163476079702377,
      0.047383975237607956,
      0.0686568096280098,
      -0.028227444738149643,
      0.07928694784641266,
      0.05261949822306633,
      -0.031213859096169472,
      0.018912162631750107,
      3.54010917362757e-05,
      0.06870463490486145,
      0.036607272922992706,
      0.0605282299220562,
      -0.054804835468530655,
      0.026606624945998192,
      -0.0524040125310421,
      -0.02969418652355671,
      0.023930294439196587,
      0.03138146921992302,
      0.06485720723867416,
      0.018038678914308548,
      -0.002748608822003007,
      -0.0388982929289341,
      0.002899340819567442,
      -0.03917974233627319,
      -0.03516151010990143,
      -0.09890301525592804,
      0.044545382261276245,
      -0.0533587783575058,
      0.07891523092985153
    ],
    [
      -0.05534225329756737,
      -0.060030966997146606,
      -0.03212893754243851,
      -0.03146490827202797,
      0.016690567135810852,
      -0.005307159386575222,
      -0.014347980730235577,
      -0.07310257852077484,
      0.058523014187812805,
      0.0366218127310276,
      0.028324879705905914,
      -0.039118677377700806,
      0.02902538888156414,
      0.04616190865635872,
      0.024745509028434753,
      -0.09834511578083038,
      0.01906237192451954,
      0.014989998191595078,
      0.043284039944410324,
      0.02995210513472557,
      0.00043608920532278717,
      -0.0650365874171257,
      -0.05513345077633858,
      0.020630832761526108,
      0.017373500391840935,
      0.0027501636650413275,
      0.028191640973091125,
      0.013363969512283802,
      0.011367302387952805,
      0.007692685350775719,
      0.020963024348020554,
      -0.048558641225099564,
      0.003761606989428401,
      0.012112277559936047,
      0.015848416835069656,
      -0.07140178978443146,
      -0.010155066847801208,
      0.017469804733991623,
      0.00814154651015997,
      0.11931195855140686,
      -0.052111800760030746,
      0.06191880255937576,
      -0.0022470387630164623,
      -0.030976051464676857,
      -0.03469932824373245,
      -0.08432836830615997,
      0.08163059502840042,
      0.04069081321358681,
      -0.0025592802558094263,
      -0.01679222285747528,
      -0.019417455419898033,
      0.019258592277765274,
      -0.0166042298078537,
      0.08546200394630432,
      0.015863098204135895,
      -0.11650002002716064,
      0.023463645949959755,
      0.016886059194803238,
      0.04192247614264488,
      0.07412974536418915,
      -0.016600489616394043,
      0.07401970773935318,
      0.0406363382935524,
      -0.009311432018876076,
      0.00568810012191534,
      -0.0487217977643013,
      0.027605243027210236,
      0.06543024629354477,
      0.007416009437292814,
      0.015947304666042328,
      -0.07776909321546555,
      0.01539800874888897,
      0.021624205633997917,
      -0.07522190362215042,
      0.03517178073525429,
      0.011474925093352795,
      0.019944598898291588,
      -0.01590031385421753,
      0.07748118788003922,
      0.009305757470428944,
      -0.033120155334472656,
      0.03380873054265976,
      0.03294236212968826,
      0.0764436423778534,
      -0.052092380821704865,
      -0.017704151570796967,
      -0.029272932559251785,
      -0.020019086077809334,
      -0.07780151814222336,
      0.01687026582658291,
      -0.013908307068049908,
      0.06736258417367935,
      0.020214175805449486,
      0.04945232346653938,
      0.09712640196084976,
      -0.017993217334151268,
      0.05485381558537483,
      0.02664068713784218,
      -0.10714409500360489,
      -0.020711125805974007,
      0.025699825957417488,
      -0.040055811405181885,
      -0.02394273318350315,
      0.05345334857702255,
      0.04103252664208412,
      -0.014992200769484043,
      -0.03002738766372204,
      -0.012976507656276226,
      -0.028598222881555557,
      0.06735718250274658,
      -0.04833636060357094,
      -0.055097490549087524,
      0.0186122115701437,
      0.04681503027677536,
      0.058373890817165375,
      -0.036031220108270645,
      0.03993324562907219,
      -0.019559752196073532,
      0.00619041221216321,
      -0.006981498096138239,
      0.047681331634521484,
      -0.11525852233171463,
      -0.0137046342715621,
      0.034101855009794235,
      -0.09365154057741165,
      0.02271096780896187,
      0.08057066053152084,
      0.07447312772274017,
      0.004818118643015623,
      0.002731478540226817,
      -0.014439096674323082,
      -0.014657900668680668,
      0.0242692232131958,
      0.015574752353131771,
      -0.013998954556882381,
      -0.03964908421039581,
      -0.020233996212482452,
      -0.023493966087698936,
      -0.060401637107133865,
      -0.030146261677145958,
      -0.0014616784173995256,
      -0.01350877620279789,
      0.016263417899608612,
      0.04065417870879173,
      -0.04443871229887009,
      0.04551858454942703,
      -0.043215688318014145,
      -0.002768724923953414,
      -0.028143340721726418,
      0.11919576674699783,
      0.0055488357320427895,
      0.03603176027536392,
      0.07029228657484055,
      -0.010700558312237263,
      -0.1198258027434349,
      0.0896822065114975,
      -0.10699442028999329,
      0.029502185061573982,
      -0.05462288856506348,
      0.05194728448987007,
      0.007430873811244965,
      -0.026047110557556152,
      0.10654076188802719,
      -0.03959810361266136,
      -0.016567261889576912,
      -0.0009483960457146168,
      -0.0020837876945734024,
      -0.01056704856455326,
      0.0667719841003418,
      0.021479245275259018,
      0.0021271537989377975,
      -0.008497915230691433,
      0.014711997471749783,
      0.0701460987329483,
      0.037154581397771835,
      0.011318839155137539,
      -0.04908542335033417,
      -0.06264802813529968,
      0.12460071593523026,
      0.02084285393357277,
      -0.025789476931095123,
      0.033153995871543884,
      0.027335919439792633,
      0.03585173934698105,
      -0.03037875145673752,
      0.05057475343346596,
      -0.061932243406772614,
      0.0015329801244661212,
      0.06701506674289703,
      -0.011293883435428143,
      -0.03792208805680275,
      0.020541470497846603,
      0.019434042274951935,
      0.0677778348326683,
      -0.08215416967868805,
      0.033433858305215836,
      0.012944784015417099,
      -0.0027490160427987576,
      -0.023754660040140152,
      -0.0014091699849814177,
      -0.024010341614484787,
      -0.07023116201162338,
      -0.050279393792152405,
      0.0220604557543993,
      -0.0036186950746923685,
      0.060024164617061615,
      0.009393386542797089,
      0.04090617224574089,
      0.014400465413928032,
      0.007897990755736828,
      -0.020206209272146225,
      0.009283787570893764,
      -0.057620566338300705,
      0.06723079830408096,
      0.0558609738945961,
      0.02199794352054596,
      0.0008760981727391481,
      -0.007217911072075367,
      0.02281697653234005,
      -0.004221067298203707,
      -0.11302207410335541,
      0.009243268519639969,
      0.004663483705371618,
      -0.0058816177770495415,
      0.0067556193098425865,
      -0.0005760548519901931,
      0.03951076790690422,
      0.030398797243833542,
      0.013853414915502071,
      -0.002358614932745695,
      -0.026999766007065773,
      -0.009029814973473549,
      -0.0024005407467484474,
      -0.019066855311393738,
      0.07012858986854553,
      0.086002878844738,
      -0.006241071969270706,
      0.054468393325805664,
      -0.05950755998492241,
      0.03315762057900429,
      -0.08492834120988846,
      -0.08150258660316467,
      -0.04961571842432022,
      0.01849953457713127,
      -0.0028286215383559465,
      0.04483342170715332,
      -0.002060652943328023,
      -0.004360879305750132,
      -0.06782128661870956,
      -0.040369853377342224,
      0.05984094366431236,
      0.03436209633946419,
      0.020812388509511948,
      0.016782788559794426,
      0.05549110099673271,
      -0.05771505460143089,
      -0.021768027916550636,
      0.015016522258520126,
      0.023493966087698936,
      -0.0050058746710419655,
      -0.004310384392738342,
      -0.037564635276794434,
      -0.040668830275535583,
      -0.011162211187183857,
      -0.05536350980401039,
      -0.01889084093272686,
      0.02036544866859913,
      0.011636022478342056,
      0.05372853949666023,
      0.03856882452964783,
      -0.031215883791446686,
      -0.02769404463469982,
      0.024028651416301727,
      0.07769593596458435,
      -0.03798789903521538,
      0.054025858640670776,
      -0.06465590745210648,
      -0.012903619557619095,
      0.023686379194259644,
      -0.033564209938049316,
      -0.02916778065264225,
      0.016085617244243622,
      0.042264316231012344,
      -0.00558719364926219,
      -0.09749904274940491,
      0.04088594391942024,
      -0.0653223842382431,
      -0.03347144275903702,
      0.011865821667015553,
      -0.015105267986655235,
      -0.0009210379794239998,
      -0.002234372077509761,
      -0.012940037064254284,
      0.00473624374717474,
      -0.10976694524288177,
      0.01627015508711338,
      0.033977266401052475,
      -0.031209606677293777,
      -0.009748945012688637,
      -0.06367288529872894,
      0.028440123423933983,
      -0.0021313573233783245,
      0.012139515019953251,
      -0.02535875514149666,
      -0.09320038557052612,
      -0.020985936746001244,
      -0.014643319882452488,
      0.023591479286551476,
      -0.048329513520002365,
      0.07645542919635773,
      0.005994573701173067,
      0.0010369106894358993,
      -0.003706834279000759,
      -0.04688417539000511,
      0.06608826667070389,
      0.0709822028875351,
      0.012893692590296268,
      0.020049450919032097,
      0.029686912894248962,
      -0.0431504100561142,
      0.01573014445602894,
      0.016113270074129105,
      0.08846200257539749,
      -0.09400326013565063,
      0.0485791377723217,
      0.06923101842403412,
      0.01660839095711708,
      -0.023943254724144936,
      0.05042313411831856,
      0.0092582693323493,
      0.033110443502664566,
      -0.10175061970949173,
      0.0503913015127182,
      -0.07277579605579376,
      0.00227483082562685,
      -0.07089662551879883,
      -0.0018949169898405671,
      0.04990191012620926,
      -0.02494341880083084,
      0.017865201458334923,
      -0.009077353402972221,
      0.06071478873491287,
      -0.016583312302827835,
      0.09630821645259857,
      0.006154497619718313,
      0.021692555397748947,
      -0.04837794974446297,
      -0.0433235727250576,
      -0.009685701690614223,
      0.022849800065159798,
      0.0017509303288534284,
      0.011865327134728432,
      0.055864229798316956,
      -0.02216312848031521,
      0.005665040109306574,
      -0.062345728278160095,
      -0.05666292458772659,
      -0.015426237136125565,
      0.08263909071683884,
      -0.022861432284116745,
      0.04559887573122978,
      -0.008796175941824913,
      0.07942786067724228,
      0.021227659657597542,
      0.0403444841504097,
      0.02898152731359005,
      -0.0411209799349308,
      0.05617109313607216,
      -0.10096746683120728,
      -0.060301221907138824,
      0.04192116856575012,
      0.019089601933956146,
      0.08754035085439682,
      0.00368423736654222,
      -0.08422740548849106,
      0.02370872162282467,
      -0.08393032848834991,
      -0.041041020303964615,
      0.01702183485031128,
      0.015013852156698704,
      -0.05918337404727936,
      0.008962170220911503,
      0.008389018476009369,
      0.13312260806560516,
      -0.03001462295651436,
      -0.09803684800863266,
      -0.04887757822871208,
      -0.08159062266349792,
      -0.0019082925282418728,
      -0.030810493975877762,
      -5.4003412515157834e-05,
      -0.032346002757549286,
      -0.018473325297236443,
      0.043047841638326645,
      -0.07985996454954147,
      0.0097302021458745,
      0.021396171301603317,
      -0.034587547183036804,
      0.05794646590948105,
      0.028400670737028122,
      -0.022987091913819313,
      0.042260490357875824,
      -0.027484821155667305,
      -0.07353836297988892,
      -0.008987249806523323,
      -0.047004081308841705,
      -0.005183711182326078,
      -0.046191681176424026,
      0.09965820610523224,
      0.03245467692613602,
      -0.051063600927591324,
      -0.014479161240160465,
      0.031828396022319794,
      -0.012076826766133308,
      0.012902273796498775,
      -0.010717794299125671,
      -0.05206642299890518,
      -0.0039058185648173094,
      0.005463131237775087,
      -0.09162148833274841,
      -0.052374180406332016,
      0.009720422327518463,
      0.02539016492664814,
      0.013428599573671818,
      0.008816893212497234,
      -0.033143434673547745,
      0.0050439657643437386,
      0.024866551160812378,
      0.06264522671699524,
      -0.05710918828845024,
      -0.09172268211841583,
      0.0422377809882164,
      -0.048748355358839035,
      0.021901879459619522,
      -0.047358449548482895,
      0.013642345555126667,
      -0.039559606462717056,
      0.02300034835934639,
      -0.02014029771089554,
      0.05365002155303955,
      0.008985232561826706,
      0.021920448169112206,
      -0.04183134064078331,
      -0.08142115920782089,
      0.03618558123707771,
      -0.06790699064731598,
      -0.028277186676859856,
      -0.003742747474461794,
      -0.04788423329591751,
      0.017641393467783928,
      0.015650328248739243,
      0.01887045055627823,
      0.09042465686798096,
      -0.010516248643398285,
      0.04757542163133621,
      -0.010299578309059143,
      0.09139128774404526,
      -0.009006458334624767,
      0.03874771669507027,
      -0.011103698052465916,
      0.011001059785485268,
      0.052636098116636276,
      0.025268208235502243,
      -0.03476812317967415,
      0.02691086381673813,
      -0.08817661553621292,
      0.06456965953111649,
      0.008077853359282017,
      -0.0709659606218338,
      -0.012372419238090515,
      -0.011079441756010056,
      -0.02627062052488327,
      0.05453939735889435,
      0.06564333289861679,
      0.08724676817655563,
      0.032122641801834106,
      0.03885682672262192,
      0.009220197796821594,
      0.007168103009462357,
      0.015085539780557156,
      -0.004336424637585878,
      0.0756676122546196,
      0.13478049635887146,
      0.013864911161363125,
      -0.016612015664577484,
      0.037893202155828476,
      -0.0014501148834824562,
      0.058428410440683365,
      -0.00019966262334492058,
      0.02794186770915985,
      -0.007370929699391127,
      0.021351108327507973,
      -0.0039416090585291386,
      -0.01864125393331051,
      -0.044253863394260406,
      -0.10302606225013733,
      0.04993332177400589,
      -0.07739782333374023,
      0.00884160678833723,
      -0.028652578592300415,
      0.02798566035926342,
      0.04444111883640289,
      0.013572621159255505
    ],
    [
      0.00873224250972271,
      -0.03567956015467644,
      -0.04003474861383438,
      -0.019541306421160698,
      -0.023140063509345055,
      -0.10087965428829193,
      0.04722205922007561,
      0.02011416107416153,
      -0.005297485738992691,
      0.014735735952854156,
      -0.0015161422779783607,
      -0.00261307111941278,
      0.10320557653903961,
      -0.03179514408111572,
      0.1396268755197525,
      0.04508271440863609,
      -0.021080471575260162,
      -0.008483495563268661,
      -0.05549434572458267,
      0.044629961252212524,
      0.014062252826988697,
      -0.02637571655213833,
      0.010024462826550007,
      -0.029637854546308517,
      -0.020135145634412766,
      -0.02178504876792431,
      -0.0009881432633846998,
      0.057507432997226715,
      -0.06008128076791763,
      0.023706503212451935,
      0.046439457684755325,
      0.012688467279076576,
      -0.034201886504888535,
      -0.04993782192468643,
      -0.0021874685771763325,
      0.11199498176574707,
      -0.03392520174384117,
      0.06401913613080978,
      0.0593770369887352,
      0.024119241163134575,
      0.06617090851068497,
      -0.02630058489739895,
      0.024526474997401237,
      -0.017501572147011757,
      0.03432167321443558,
      -0.0856214165687561,
      0.03229324519634247,
      -0.06693179160356522,
      0.031503207981586456,
      0.062058743089437485,
      -0.01089737843722105,
      -0.06025596708059311,
      0.08987600356340408,
      -0.02439567632973194,
      -0.0015861744759604335,
      -0.04075939953327179,
      0.01415860466659069,
      0.02297689951956272,
      0.04908277466893196,
      -0.08008294552564621,
      0.05410148575901985,
      0.017212120816111565,
      -0.038541994988918304,
      -0.013157068751752377,
      -0.02952631562948227,
      -0.005153245758265257,
      0.07413408160209656,
      -0.05337047576904297,
      -0.007231306750327349,
      -0.03992938622832298,
      0.1012682095170021,
      -0.038643211126327515,
      0.043547652661800385,
      -0.05941803753376007,
      0.0014599313726648688,
      0.02735927514731884,
      -0.009076511487364769,
      0.0417814701795578,
      0.027836736291646957,
      -0.06464934349060059,
      0.04201819375157356,
      0.075212262570858,
      -0.01930699497461319,
      0.027736255899071693,
      0.016552291810512543,
      -0.03002062253654003,
      0.007076898589730263,
      0.031915366649627686,
      0.0016997870989143848,
      0.04362960532307625,
      0.04798055812716484,
      0.038404807448387146,
      -0.019201040267944336,
      0.061320092529058456,
      -0.03212098404765129,
      0.019747380167245865,
      -0.022926686331629753,
      -0.04571522772312164,
      0.016607094556093216,
      0.0007662685820832849,
      -0.02451419271528721,
      0.023343108594417572,
      -0.008820854127407074,
      -0.016779523342847824,
      0.007200724445283413,
      0.006867640186101198,
      -0.016290422528982162,
      0.0421082079410553,
      0.08208296447992325,
      -0.0035994250793009996,
      -0.018451832234859467,
      -0.02180095948278904,
      0.11593540757894516,
      0.03899199888110161,
      -0.010622180067002773,
      0.0004552663303911686,
      -0.07266751676797867,
      0.05604953691363335,
      0.007478013169020414,
      0.014371433295309544,
      0.0598469004034996,
      0.09467896074056625,
      0.07062217593193054,
      0.022983575239777565,
      0.02447071485221386,
      -0.04248243197798729,
      -0.11903120577335358,
      -0.039375778287649155,
      -0.01426787581294775,
      0.05371299758553505,
      0.051069434732198715,
      -0.04341081529855728,
      0.053227994590997696,
      0.03340347111225128,
      -0.027165487408638,
      -0.09343219548463821,
      0.06578080356121063,
      0.030127717182040215,
      -0.00694857444614172,
      -0.06800816208124161,
      -0.02579171024262905,
      0.0025765500031411648,
      -0.018184445798397064,
      0.009517861530184746,
      -0.013690488412976265,
      0.007330534514039755,
      -0.02922792360186577,
      -0.06639380007982254,
      0.020662665367126465,
      0.06603595614433289,
      -0.06715036183595657,
      0.01960231550037861,
      0.10869885236024857,
      -0.048479657620191574,
      0.006961127743124962,
      -0.008300238288939,
      -0.08357283473014832,
      -0.05887360870838165,
      -0.13126525282859802,
      0.018349289894104004,
      -0.05767316743731499,
      0.018343554809689522,
      -0.028019635006785393,
      0.028704646974802017,
      -0.05007699131965637,
      -0.0390913188457489,
      -0.054106082767248154,
      0.016554778441786766,
      0.003905340563505888,
      -0.06984863430261612,
      -0.01845020428299904,
      0.0849941074848175,
      -0.041305024176836014,
      0.03151906281709671,
      0.04132703319191933,
      -0.0625654086470604,
      -0.023928184062242508,
      0.04542164131999016,
      0.04091915115714073,
      0.059297021478414536,
      0.003733129007741809,
      0.07422930747270584,
      -0.023964321240782738,
      -0.018084848299622536,
      -0.07845387607812881,
      -0.032534822821617126,
      -0.03307938203215599,
      -0.04333998262882233,
      0.029832113534212112,
      -0.031060783192515373,
      -0.024565016850829124,
      -0.05673038586974144,
      0.021139465272426605,
      -0.0169454887509346,
      0.01682410016655922,
      -0.014708295464515686,
      0.01418244931846857,
      -0.028315888717770576,
      -0.021291673183441162,
      -0.07371227443218231,
      0.0120893819257617,
      0.07288656383752823,
      -0.0423392690718174,
      -0.049309078603982925,
      -0.0643354132771492,
      0.031197166070342064,
      0.014746276661753654,
      -0.009329002350568771,
      0.035071298480033875,
      -0.00840931199491024,
      0.08007287234067917,
      -0.005297412630170584,
      0.05788068845868111,
      -0.027772346511483192,
      -0.04792463034391403,
      -0.042333878576755524,
      0.013356226496398449,
      0.011635394766926765,
      0.07487581670284271,
      -0.01241025235503912,
      -0.0586697943508625,
      0.01737167499959469,
      0.008217462338507175,
      0.04381171613931656,
      -0.020419646054506302,
      -0.023671545088291168,
      -0.016432717442512512,
      0.007942366413772106,
      -0.06311757862567902,
      -0.00842971820384264,
      0.015796910971403122,
      -0.054314445704221725,
      -0.02374897710978985,
      0.028139211237430573,
      0.06103546917438507,
      -0.06907223165035248,
      0.1070433109998703,
      0.014870760962367058,
      -0.030385175719857216,
      -0.04908032715320587,
      0.002966252388432622,
      -0.016229350119829178,
      -0.04775809869170189,
      0.00047219794942066073,
      -0.04401068761944771,
      0.024631904438138008,
      -0.0010099289938807487,
      0.06462500244379044,
      -0.003472360083833337,
      -0.04063309356570244,
      0.0239105261862278,
      0.05702224373817444,
      -0.05958927050232887,
      0.062238942831754684,
      0.08117962628602982,
      0.012463262304663658,
      -0.03174317255616188,
      -0.007610206492245197,
      0.06121474876999855,
      -0.02611270546913147,
      -0.05923004448413849,
      0.06583952903747559,
      -0.0181510578840971,
      -0.0533134862780571,
      -0.04221860319375992,
      0.018056508153676987,
      -0.05776873975992203,
      -0.08532866090536118,
      -0.01647690124809742,
      -0.05398548021912575,
      0.012888665311038494,
      0.010806606151163578,
      0.03372769057750702,
      0.010666316375136375,
      0.06574616581201553,
      0.03490649163722992,
      0.08057687431573868,
      -0.019099382683634758,
      -0.022117026150226593,
      -0.000914219708647579,
      -0.010781125165522099,
      0.030427807942032814,
      -0.04393080249428749,
      -0.02346530370414257,
      0.017276519909501076,
      0.059447504580020905,
      -0.014000877737998962,
      -0.0812918096780777,
      -0.024921027943491936,
      -0.013204245828092098,
      -0.0020453042816370726,
      0.010579205118119717,
      -0.035732436925172806,
      0.022915102541446686,
      -0.019874950870871544,
      0.020016327500343323,
      -0.04033627361059189,
      0.061826374381780624,
      -0.0009485559421591461,
      0.044901758432388306,
      -0.01683581806719303,
      0.007594344671815634,
      -0.04383399710059166,
      0.004666983149945736,
      -0.006853275932371616,
      -0.016282031312584877,
      -0.04899841919541359,
      0.009685422293841839,
      0.06062689423561096,
      -0.044765789061784744,
      -0.062358926981687546,
      -0.005376290529966354,
      -0.04230334237217903,
      0.012520095333456993,
      0.0370851531624794,
      -0.042613252997398376,
      0.035364244133234024,
      0.011368844658136368,
      -0.00719530088827014,
      0.03607436269521713,
      -0.0035475678741931915,
      0.05030592530965805,
      -0.03972868248820305,
      -0.03829510137438774,
      -0.002161267912015319,
      0.0007786824717186391,
      -0.029863988980650902,
      0.0154857337474823,
      0.001178328413516283,
      -0.06552539020776749,
      0.11795500665903091,
      -0.011658680625259876,
      -0.022968171164393425,
      0.0370136983692646,
      -0.0362565703690052,
      -0.022303417325019836,
      0.004837693180888891,
      -0.033704571425914764,
      0.03876636549830437,
      -0.050841037184000015,
      -0.060682568699121475,
      -0.006873645819723606,
      0.004351765848696232,
      0.012229830957949162,
      -0.05488305538892746,
      -0.024552471935749054,
      0.017622150480747223,
      -0.02536420151591301,
      -0.03606158867478371,
      0.054086871445178986,
      -0.08212189376354218,
      0.0025789316277951,
      -0.004459508694708347,
      -0.01191230770200491,
      -0.03695701062679291,
      -0.022035416215658188,
      -0.017091182991862297,
      -0.0001845781080191955,
      -0.06731152534484863,
      -0.022628460079431534,
      -0.03351236507296562,
      -0.08984360098838806,
      0.06894591450691223,
      -0.08068513870239258,
      -0.05694253370165825,
      -0.030866120010614395,
      0.010471045039594173,
      -0.01661270670592785,
      -0.028425339609384537,
      0.05338938161730766,
      0.004005058202892542,
      0.022128159180283546,
      -0.037050146609544754,
      0.01822572574019432,
      0.0007698044064454734,
      -0.019085699692368507,
      0.055138323456048965,
      0.004967174027115107,
      -0.004025672096759081,
      0.008493799716234207,
      0.07019499689340591,
      0.025785991922020912,
      0.007005326449871063,
      0.08255058526992798,
      -0.025558890774846077,
      0.06089131534099579,
      -0.004717666655778885,
      0.00984999444335699,
      -0.0636477917432785,
      -0.002967691980302334,
      -0.08704688400030136,
      0.023383915424346924,
      -0.06362855434417725,
      0.021612830460071564,
      0.08602862805128098,
      -0.08143617957830429,
      0.04067125916481018,
      -0.04730876907706261,
      -0.0021627184469252825,
      0.05938317999243736,
      0.007598758675158024,
      -0.03629022836685181,
      0.024574168026447296,
      0.041919417679309845,
      0.030718674883246422,
      0.02227494865655899,
      -0.07009696960449219,
      -0.04641503468155861,
      0.10375776141881943,
      -0.03013467974960804,
      0.030410107225179672,
      -0.05518744885921478,
      -0.012109020724892616,
      0.025595704093575478,
      -0.0009665137622505426,
      0.045208945870399475,
      -0.011772863566875458,
      0.04751162603497505,
      -0.022579938173294067,
      0.030769821256399155,
      -0.0015447192126885056,
      -0.05944623798131943,
      7.038028707029298e-05,
      -0.0004986660787835717,
      0.06415046006441116,
      -0.05708298832178116,
      0.06346116214990616,
      -0.026618268340826035,
      0.04113201051950455,
      -0.017306676134467125,
      0.02129492722451687,
      0.012484628707170486,
      -0.010521634481847286,
      -0.03519143909215927,
      -0.022897807881236076,
      -0.032362598925828934,
      0.03198449686169624,
      -0.010034129023551941,
      0.008396754041314125,
      0.047084368765354156,
      -0.03218245133757591,
      0.02362445555627346,
      -0.04164355620741844,
      0.013773135840892792,
      0.00027850852347910404,
      0.03151805326342583,
      0.022322306409478188,
      -0.053236111998558044,
      0.0014091520570218563,
      0.02008954808115959,
      0.07050738483667374,
      -0.03107922337949276,
      -0.061331696808338165,
      -0.006204718723893166,
      0.000576669757720083,
      -0.0071273078210651875,
      0.033151689916849136,
      0.026626495644450188,
      0.06735119968652725,
      -0.04624011740088463,
      -0.09357290714979172,
      0.0644993931055069,
      0.029258904978632927,
      -0.011127924546599388,
      0.008667179383337498,
      -0.003483014414086938,
      0.017318401485681534,
      -0.06469216197729111,
      0.013071642257273197,
      0.025602217763662338,
      0.04197682440280914,
      -0.008577315136790276,
      0.0001322569587500766,
      -0.0030210360419005156,
      0.027299949899315834,
      0.0651109591126442,
      -0.05220245197415352,
      0.07607902586460114,
      -0.029073212295770645,
      0.016523297876119614,
      0.028821934014558792,
      0.05058005452156067,
      0.048122018575668335,
      0.01574317365884781,
      -0.004270640667527914,
      0.01685682125389576,
      0.027901478111743927,
      0.03409691900014877,
      0.025438331067562103,
      -0.0267067588865757,
      0.09972510486841202,
      0.053710028529167175,
      0.006399795413017273,
      0.00747966393828392,
      -0.0014498913660645485,
      -0.02040688879787922,
      -0.08503798395395279,
      -0.0828235074877739,
      -0.0038528835866600275,
      -0.0723438635468483,
      -0.013587547466158867,
      0.0074308887124061584,
      -0.013619694858789444
    ],
    [
      0.05658798664808273,
      -0.04153866320848465,
      -0.049840204417705536,
      0.07717415690422058,
      -0.06540457159280777,
      -0.009485012851655483,
      -0.09363159537315369,
      0.08959190547466278,
      0.01940973661839962,
      -0.030096055939793587,
      0.05064640939235687,
      -0.07077312469482422,
      -0.015163524076342583,
      0.02927098050713539,
      0.05375019833445549,
      -0.011799131520092487,
      0.06825178861618042,
      0.027111073955893517,
      0.003565087215974927,
      0.0022805342450737953,
      -0.06527378410100937,
      -0.00951212365180254,
      0.05214226618409157,
      -0.006416319403797388,
      0.017455892637372017,
      0.03730423003435135,
      -0.020571643486618996,
      -0.04198801517486572,
      -0.07129914313554764,
      -0.024449456483125687,
      0.0379146933555603,
      0.0252700112760067,
      -0.02339530922472477,
      -0.020143762230873108,
      0.042559485882520676,
      -0.007210697513073683,
      -0.0036984989419579506,
      0.04118845984339714,
      -0.04006623476743698,
      -0.03222177177667618,
      0.006794581655412912,
      -0.017904968932271004,
      0.05566824972629547,
      0.0032998041715472937,
      0.03896990418434143,
      0.06278642266988754,
      0.025936033576726913,
      -0.03635895997285843,
      0.017966771498322487,
      0.02212090790271759,
      0.056253623217344284,
      0.011887739412486553,
      -0.04088859632611275,
      -0.016683517023921013,
      0.0077970135025680065,
      -0.03643004223704338,
      -0.008414706215262413,
      -0.0446653887629509,
      0.024337802082300186,
      -0.03393075242638588,
      -0.08406251668930054,
      0.02780221775174141,
      -0.04233526811003685,
      0.012949232012033463,
      0.051550913602113724,
      0.009843080304563046,
      -0.007891382090747356,
      -0.014878815039992332,
      0.09125661104917526,
      -0.03189043700695038,
      -0.014976529404520988,
      -0.06690207868814468,
      0.010843039490282536,
      -0.102780282497406,
      -0.00763719342648983,
      -0.0129018509760499,
      0.05227037891745567,
      -0.004967251792550087,
      -0.02100486308336258,
      -0.02480892464518547,
      -0.007238009478896856,
      0.032369278371334076,
      -0.03669958561658859,
      0.010036773979663849,
      0.029193110764026642,
      -0.04194677621126175,
      -0.04184691235423088,
      0.013971224427223206,
      0.0029303422197699547,
      0.05836460366845131,
      0.01271685678511858,
      0.03299243003129959,
      0.01893354021012783,
      0.003151634009554982,
      0.002640116261318326,
      0.04454827681183815,
      0.052787553519010544,
      -0.02003800868988037,
      0.037021007388830185,
      -0.04342254623770714,
      -0.05046103894710541,
      0.05437977612018585,
      0.008985678665339947,
      0.055110447108745575,
      0.04041038081049919,
      -0.005648612044751644,
      0.05797374248504639,
      0.0369756706058979,
      0.028718333691358566,
      0.015120969153940678,
      -0.0007986904820427299,
      -0.03340514004230499,
      0.02920330874621868,
      0.011408491060137749,
      0.012905508279800415,
      -0.025729993358254433,
      0.05133086442947388,
      -0.022412361577153206,
      0.047190822660923004,
      0.013162530027329922,
      0.028586486354470253,
      0.03096424601972103,
      -0.025927960872650146,
      -0.01072459202259779,
      0.006260667461901903,
      -0.02645980939269066,
      -0.026550041511654854,
      -0.03799758851528168,
      0.05684376507997513,
      0.08867567032575607,
      0.04155927151441574,
      0.012453748844563961,
      0.04338019713759422,
      -0.02321171574294567,
      -0.019625211134552956,
      0.008102873340249062,
      -0.05075598135590553,
      0.06426507234573364,
      0.023263659328222275,
      0.06266245245933533,
      0.0030165137723088264,
      0.032343070954084396,
      -0.010987209156155586,
      0.06965688616037369,
      0.09381337463855743,
      0.035838622599840164,
      -0.017206553369760513,
      0.006434105336666107,
      0.03938828408718109,
      -0.14746901392936707,
      0.0843016728758812,
      0.04214753583073616,
      0.04591049253940582,
      -0.04448901489377022,
      0.025326013565063477,
      -0.0068534198217093945,
      -0.002805885626003146,
      0.012031530030071735,
      -0.01628461852669716,
      0.051947999745607376,
      0.03916395083069801,
      0.032035600394010544,
      0.02785884216427803,
      0.05402329936623573,
      -0.03969137743115425,
      0.05731113255023956,
      -0.013769179582595825,
      0.011204507201910019,
      0.04782634600996971,
      0.005186759401112795,
      -0.021224960684776306,
      0.07696743309497833,
      0.023700453341007233,
      0.060188449919223785,
      0.0357741117477417,
      -0.012878144159913063,
      0.005573085974901915,
      0.07297448068857193,
      0.029001465067267418,
      0.026456739753484726,
      0.030813565477728844,
      0.056948911398649216,
      -0.001176724093966186,
      0.034058935940265656,
      0.07554039359092712,
      -0.05943640321493149,
      0.05305792763829231,
      0.06880602985620499,
      0.04373643547296524,
      0.009211597964167595,
      -0.00632445327937603,
      -0.05092987045645714,
      0.020703455433249474,
      -0.03457175940275192,
      -0.03906272351741791,
      0.08535532653331757,
      0.06606348603963852,
      -0.1169811338186264,
      -0.11695513129234314,
      -0.10150118917226791,
      -0.039456453174352646,
      -0.057376060634851456,
      0.08176597952842712,
      -0.07546206563711166,
      -0.01461014710366726,
      -0.03577166050672531,
      -0.05861319601535797,
      0.04968023672699928,
      0.05179804190993309,
      -0.010160863399505615,
      -0.039848413318395615,
      -0.03420630842447281,
      0.09844917058944702,
      -0.021270617842674255,
      -0.008019995875656605,
      0.015500581823289394,
      -0.02276594378054142,
      0.032998520880937576,
      0.044189952313899994,
      -0.06480751186609268,
      -0.024976210668683052,
      0.00965852476656437,
      0.05431776121258736,
      -0.009022428654134274,
      0.05009325593709946,
      0.03501352295279503,
      -0.11399251222610474,
      -0.07422421127557755,
      -0.01003814022988081,
      0.02271135523915291,
      -0.005779367405921221,
      0.009567628614604473,
      -0.06368951499462128,
      0.021480148658156395,
      -0.05623176321387291,
      -0.009567005559802055,
      -0.018628865480422974,
      -0.020040884613990784,
      -0.03404169902205467,
      0.08646029978990555,
      -0.07316865772008896,
      0.022670937702059746,
      -0.03333551436662674,
      0.03985726460814476,
      0.09261178970336914,
      0.031775154173374176,
      0.06300376355648041,
      -0.04539449140429497,
      0.02534378692507744,
      -0.03236829489469528,
      0.029040833935141563,
      0.03346461057662964,
      0.01227924507111311,
      -0.007050262298434973,
      -0.010809468105435371,
      -0.018614327535033226,
      0.0053243315778672695,
      0.023013807833194733,
      -0.037135276943445206,
      0.10400383919477463,
      0.015262900851666927,
      0.02555358037352562,
      -0.08800842612981796,
      0.12445218116044998,
      0.029889561235904694,
      -0.017299171537160873,
      -0.05190083757042885,
      -0.006867751944810152,
      -0.008217773400247097,
      -0.04354618117213249,
      0.050234291702508926,
      0.026069067418575287,
      -0.0014425332192331553,
      -0.03663056716322899,
      -0.0014835877809673548,
      -0.012287547811865807,
      -0.10348746925592422,
      -0.027579307556152344,
      0.025777848437428474,
      -0.0033298376947641373,
      -0.047591112554073334,
      -0.07518526166677475,
      -0.028083300217986107,
      0.011594407260417938,
      -0.014059202745556831,
      0.0101909339427948,
      -0.0022618684452027082,
      -0.019255295395851135,
      -0.005128962453454733,
      -0.0953429788351059,
      -0.038341064006090164,
      0.0656200423836708,
      0.008291968144476414,
      0.03920470550656319,
      0.03514394536614418,
      -0.09566348046064377,
      0.017351249232888222,
      -0.058898817747831345,
      0.02246524952352047,
      -0.012129907496273518,
      -0.0938396006822586,
      -0.0612153522670269,
      -0.0019240864785388112,
      -0.05420829355716705,
      -0.059370167553424835,
      -0.015329857356846333,
      -0.059948962181806564,
      -0.060922522097826004,
      -0.013669608160853386,
      0.04745868220925331,
      0.01077677309513092,
      -0.03591306507587433,
      -0.03713231161236763,
      0.0103147616609931,
      0.03651940077543259,
      0.020191285759210587,
      0.005619490053504705,
      0.0033988154027611017,
      0.026728106662631035,
      -0.07176653295755386,
      -0.03895265981554985,
      0.07736676186323166,
      -0.057910557836294174,
      -0.0008504307479597628,
      0.09858476370573044,
      0.02469051070511341,
      -0.07596511393785477,
      -0.020105287432670593,
      -0.009567687287926674,
      0.027866048738360405,
      0.07889274507761002,
      0.011290577240288258,
      0.015899447724223137,
      0.046334680169820786,
      -0.04852290824055672,
      -0.0003831294598057866,
      -0.036623626947402954,
      -0.07322106510400772,
      -0.04960658401250839,
      -0.03617866709828377,
      0.014000462368130684,
      -0.003472893964499235,
      -0.003737781895324588,
      -0.01665729098021984,
      0.025894757360219955,
      -0.011840743012726307,
      0.052380889654159546,
      0.018631501123309135,
      0.046042248606681824,
      -0.03184010460972786,
      -0.015230175107717514,
      -0.03523191437125206,
      -0.040434084832668304,
      -0.022545205429196358,
      0.011982662603259087,
      0.07136963307857513,
      0.0681312084197998,
      0.013690676540136337,
      -0.055845703929662704,
      0.009062574245035648,
      -0.06323948502540588,
      0.05613214522600174,
      -0.010325814597308636,
      0.04813835769891739,
      0.04095904529094696,
      0.018044142052531242,
      -0.10002666711807251,
      0.013991223648190498,
      -0.06299831718206406,
      -0.01165070477873087,
      0.06583430618047714,
      0.020606638863682747,
      0.024800416082143784,
      0.03801856189966202,
      0.0420839786529541,
      0.03728501871228218,
      -0.02378116548061371,
      0.045204952359199524,
      -0.09185735881328583,
      0.07167234271764755,
      0.024124152958393097,
      0.020786063745617867,
      -0.024263232946395874,
      -0.05211297795176506,
      0.017008107155561447,
      0.019402988255023956,
      -0.06555924564599991,
      -0.021171104162931442,
      -0.010830089449882507,
      -0.029079683125019073,
      -0.005232999566942453,
      -0.04156213998794556,
      -0.012870404869318008,
      0.011664868332445621,
      0.07809802889823914,
      0.02383001148700714,
      0.0397157296538353,
      -0.08744644373655319,
      -0.019277211278676987,
      0.018852239474654198,
      0.010575134307146072,
      -0.012018685229122639,
      -0.050622060894966125,
      -0.043399982154369354,
      -0.060506924986839294,
      0.022201107814908028,
      -0.019693564623594284,
      0.01915261521935463,
      -0.0696626529097557,
      -0.07092434167861938,
      -0.006280721630901098,
      -0.0480843223631382,
      0.013755031861364841,
      -0.02279336005449295,
      0.054745741188526154,
      0.049178920686244965,
      -0.05091089382767677,
      0.011773278005421162,
      0.12072032690048218,
      0.03094468079507351,
      0.03764982149004936,
      0.045475833117961884,
      0.011234689503908157,
      -0.013698460534214973,
      -0.04343641921877861,
      0.039342377334833145,
      0.0013238901738077402,
      0.05052793771028519,
      0.0499628484249115,
      -0.029145436361432076,
      0.05750703811645508,
      -0.020645642653107643,
      0.0162296574562788,
      -0.0551716573536396,
      -0.035884153097867966,
      -0.033916790038347244,
      -0.018704671412706375,
      -0.06623910367488861,
      0.03839729726314545,
      0.002576543251052499,
      -0.017705228179693222,
      0.04980001971125603,
      -0.010878164321184158,
      -0.09353969246149063,
      -0.039645083248615265,
      -0.007424056529998779,
      -0.007972359657287598,
      0.04586055502295494,
      0.015696173533797264,
      0.0133816534653306,
      0.035016220062971115,
      0.06751257181167603,
      -0.07766211032867432,
      0.016414163634181023,
      -0.019054437056183815,
      -0.09009817987680435,
      0.07631543278694153,
      -0.013435056433081627,
      0.034679025411605835,
      -0.10370459407567978,
      -0.040770094841718674,
      -0.028561564162373543,
      -0.025083012878894806,
      0.03055790811777115,
      -0.006500335410237312,
      -0.0012047650525346398,
      0.07536791265010834,
      0.058054838329553604,
      0.006357403937727213,
      -0.04870004206895828,
      -0.006891819182783365,
      -0.005980838090181351,
      0.0148999048396945,
      -0.0697631761431694,
      -0.015788206830620766,
      0.04086761921644211,
      0.0034022380132228136,
      -0.0030955348629504442,
      0.07197342067956924,
      0.03333713114261627,
      0.01386464573442936,
      0.016346285119652748,
      0.04612565040588379,
      -0.017412004992365837,
      0.013454568572342396,
      0.013450808823108673,
      -0.07347835600376129,
      -0.054545171558856964,
      -0.026833875104784966,
      -0.02911309339106083,
      0.029976453632116318,
      0.021022241562604904,
      -0.05113615840673447,
      0.018211379647254944,
      0.07567065209150314,
      -0.06282775849103928,
      0.08379218727350235,
      0.02912314608693123,
      0.06012757495045662,
      0.03764652460813522,
      -0.029439114034175873,
      -0.045829664915800095,
      0.04846381023526192
    ],
    [
      -0.10527802258729935,
      0.037057168781757355,
      0.018760832026600838,
      0.0429798923432827,
      -0.012265246361494064,
      0.011033345945179462,
      0.053540125489234924,
      -0.030884046107530594,
      0.014864235185086727,
      -0.03256376087665558,
      0.033950019627809525,
      0.01660296879708767,
      -0.02606707252562046,
      0.043869748711586,
      -0.04659973084926605,
      -0.03510799631476402,
      -0.040500182658433914,
      0.04935256764292717,
      0.07602885365486145,
      0.05736828222870827,
      -0.025367695838212967,
      0.021895654499530792,
      -0.08973663300275803,
      -0.04197001829743385,
      -0.024645792320370674,
      0.04340633377432823,
      0.03363785520195961,
      0.0008683833293616772,
      0.043279461562633514,
      -0.022528504952788353,
      0.04799075424671173,
      0.05797242000699043,
      0.018022626638412476,
      0.021803351119160652,
      -0.04439358040690422,
      -0.028321802616119385,
      -0.015031439252197742,
      0.05775976553559303,
      -0.013999653980135918,
      0.06922881305217743,
      0.08334743976593018,
      -0.04009970277547836,
      -0.025485040619969368,
      -0.10985762625932693,
      -0.08082403242588043,
      -0.055139947682619095,
      0.029653126373887062,
      0.006576748099178076,
      0.009039640426635742,
      -0.0011978686088696122,
      0.0167189072817564,
      -0.020371953025460243,
      0.0464203841984272,
      -0.053312111645936966,
      -0.012076255865395069,
      0.008085635490715504,
      -0.028453191742300987,
      -0.05531970411539078,
      -0.06203329935669899,
      -0.16103872656822205,
      -0.04488537460565567,
      0.03767404332756996,
      -0.03024565801024437,
      -0.046916328370571136,
      0.006324725225567818,
      0.033330027014017105,
      0.013788134790956974,
      0.02745189517736435,
      0.018835021182894707,
      -0.0013001782353967428,
      -0.005987594835460186,
      0.031528834253549576,
      0.02092238888144493,
      0.02561713196337223,
      -0.01503403764218092,
      -0.018963279202580452,
      -0.0019911429844796658,
      -0.00969544518738985,
      -0.021649161353707314,
      -0.015842853114008904,
      -0.047377441078424454,
      0.04658132791519165,
      0.04459631070494652,
      0.04886738955974579,
      -0.008906555362045765,
      -0.020982123911380768,
      -0.04571504890918732,
      0.004902212414890528,
      0.04482046887278557,
      0.024714231491088867,
      -0.024402450770139694,
      -0.019106630235910416,
      -0.0592329166829586,
      0.07087624073028564,
      0.057136908173561096,
      -0.049451056867837906,
      -0.03549729287624359,
      -0.03249038755893707,
      -0.05545295402407646,
      -0.03634045645594597,
      0.01960819959640503,
      0.027268296107649803,
      0.03795550391077995,
      -0.00375143950805068,
      0.022563116624951363,
      0.020514486357569695,
      0.035530343651771545,
      0.03785622492432594,
      -0.06271060556173325,
      -0.020656529814004898,
      0.06655765324831009,
      0.008729747496545315,
      -0.005635406356304884,
      -0.018947182223200798,
      0.007286335341632366,
      -0.026204589754343033,
      0.025746984407305717,
      -0.02334582805633545,
      -0.09273125231266022,
      0.013674793764948845,
      -0.040352314710617065,
      -0.06522206962108612,
      -0.07433276623487473,
      -0.07050254940986633,
      -0.049059946089982986,
      -0.055649060755968094,
      -0.022058885544538498,
      0.0743347629904747,
      0.013401278294622898,
      -0.014629853889346123,
      0.061331961303949356,
      0.009280489757657051,
      0.02316867932677269,
      -0.01936356909573078,
      0.025352491065859795,
      0.04145549610257149,
      -0.04538828134536743,
      0.054578300565481186,
      -0.049638502299785614,
      0.008366381749510765,
      0.029906760901212692,
      0.04094427451491356,
      0.0915839672088623,
      -0.048206400126218796,
      0.019056234508752823,
      -0.012401209212839603,
      -0.09248507767915726,
      -0.0037976789753884077,
      0.008134518750011921,
      0.002229783684015274,
      0.035070691257715225,
      0.020930754020810127,
      -0.04387228563427925,
      0.012799336574971676,
      -0.02886713668704033,
      0.039490364491939545,
      0.03409891203045845,
      -0.003640148788690567,
      -0.009278453886508942,
      0.03022793121635914,
      0.025805233046412468,
      0.05013483762741089,
      0.009845461696386337,
      -0.07326263934373856,
      0.012865222059190273,
      -0.045878518372774124,
      0.05558124929666519,
      0.03295600786805153,
      -0.02512500435113907,
      -0.02563117817044258,
      -0.027286507189273834,
      0.038427770137786865,
      -0.011429977603256702,
      -0.06279207020998001,
      -0.10183149576187134,
      0.005974150262773037,
      -0.031470928341150284,
      0.054796528071165085,
      0.026512932032346725,
      0.11159612983465195,
      -0.044504132121801376,
      -0.006332459393888712,
      -0.04871765896677971,
      0.03711388260126114,
      0.0441165491938591,
      0.05232198163866997,
      -0.03692176192998886,
      0.039338111877441406,
      0.03501933068037033,
      -0.010105438530445099,
      0.050017815083265305,
      0.10396739095449448,
      -0.0028015286661684513,
      0.03551982343196869,
      0.03303489834070206,
      -0.06112322211265564,
      -0.01959523744881153,
      -0.024285361170768738,
      0.03607910871505737,
      -0.0062669068574905396,
      0.016575396060943604,
      -0.003347930032759905,
      0.06251634657382965,
      -0.07308129966259003,
      -0.018697604537010193,
      -0.011680010706186295,
      0.001882863463833928,
      0.004250579047948122,
      -0.04377242177724838,
      -0.006043406203389168,
      0.03469495475292206,
      2.01696439035004e-05,
      0.049227096140384674,
      -0.05275998264551163,
      -0.0051390365697443485,
      0.003777451114729047,
      0.08335460722446442,
      0.004420561250299215,
      0.0033108661882579327,
      0.02741190604865551,
      -0.08271507173776627,
      -0.025742659345269203,
      -0.018959028646349907,
      -0.04475121945142746,
      0.0141861941665411,
      0.02032911218702793,
      -0.05688885599374771,
      -0.05057761073112488,
      0.040832895785570145,
      0.05330876260995865,
      -0.037292469292879105,
      -0.014429787173867226,
      -0.0032834354788064957,
      0.01727554388344288,
      -0.01883011683821678,
      -0.0051711853593587875,
      0.023125983774662018,
      -0.02226596139371395,
      -0.08550064265727997,
      -0.06302480399608612,
      0.05696236714720726,
      -0.01896669715642929,
      -0.036473389714956284,
      0.007666866295039654,
      -0.04557223245501518,
      -0.014841252937912941,
      -0.06978484988212585,
      0.037307675927877426,
      -0.07255064696073532,
      0.045989375561475754,
      -0.11629495024681091,
      -0.01789083145558834,
      -0.00093455781461671,
      -0.0015621966449543834,
      0.03036300651729107,
      0.02283574640750885,
      -0.04497537389397621,
      0.06046053767204285,
      0.02396184578537941,
      -0.033868636935949326,
      0.021545812487602234,
      -0.0006754100904799998,
      0.03297047317028046,
      0.02858206070959568,
      0.04071977362036705,
      0.04002641886472702,
      -0.04581483453512192,
      -0.015826288610696793,
      0.048462726175785065,
      -0.03596131131052971,
      0.03318566456437111,
      0.09174186736345291,
      -0.04663100093603134,
      0.03320547938346863,
      0.043031420558691025,
      0.003821366233751178,
      -0.0011049517197534442,
      0.008475540205836296,
      -0.04146239161491394,
      0.007417809683829546,
      -0.037211399525403976,
      -0.01594480685889721,
      -0.02538580819964409,
      0.007063343655318022,
      0.008114815689623356,
      0.05332238972187042,
      -0.06846704334020615,
      -0.009672687388956547,
      0.10001309961080551,
      -0.04875583574175835,
      0.0448211170732975,
      0.050988901406526566,
      -0.0827566459774971,
      -0.017217526212334633,
      0.10813824832439423,
      0.012577404268085957,
      -0.062287651002407074,
      0.013405958190560341,
      -0.035469427704811096,
      -0.02073875069618225,
      -0.019601937383413315,
      -0.0772782638669014,
      -0.06752641499042511,
      -0.03702864795923233,
      -0.025781121104955673,
      -0.02722703479230404,
      0.017619559541344643,
      0.09299809485673904,
      -0.010271620936691761,
      -0.05724136903882027,
      -0.05078796297311783,
      -0.01827094703912735,
      0.038449548184871674,
      -0.037809014320373535,
      -0.009352796711027622,
      0.008141696453094482,
      0.0029740906320512295,
      0.0606745183467865,
      0.06047312170267105,
      -0.005218178033828735,
      0.06627432256937027,
      -0.03426095470786095,
      -0.04376491531729698,
      0.019715996459126472,
      0.10365815460681915,
      -0.03725222498178482,
      0.053948596119880676,
      -0.06466493755578995,
      -0.00025369401555508375,
      0.0978478416800499,
      -0.007725125644356012,
      -0.011045293882489204,
      -0.045107681304216385,
      0.06112978234887123,
      -0.10587070882320404,
      -0.07818040996789932,
      0.03934747353196144,
      -0.04108085483312607,
      -0.0209884624928236,
      0.020963814109563828,
      0.1231694296002388,
      -0.031049195677042007,
      -0.010531497187912464,
      -0.05979454889893532,
      0.033395666629076004,
      0.01943209208548069,
      -0.011842500418424606,
      0.004015900660306215,
      0.09237316250801086,
      -0.028110239654779434,
      0.06604409217834473,
      -0.02002648077905178,
      0.07924538850784302,
      -0.01446438767015934,
      -0.06051423400640488,
      0.04292645305395126,
      0.057142287492752075,
      0.018530506640672684,
      0.00348242511972785,
      5.1865927161998115e-06,
      -0.013156881555914879,
      0.045062415301799774,
      -0.0056015425361692905,
      -0.05631571263074875,
      -0.07816591113805771,
      -0.012839063070714474,
      -0.04262968525290489,
      -0.01291873399168253,
      -0.011011441238224506,
      0.025349721312522888,
      0.0016343181487172842,
      0.020610680803656578,
      -0.044687870889902115,
      -0.06284759938716888,
      0.02415940910577774,
      0.05490520969033241,
      -0.019303135573863983,
      0.011742517352104187,
      -0.001174222445115447,
      0.006263648625463247,
      0.04595823585987091,
      0.013439473696053028,
      -0.013119064271450043,
      -0.02886435016989708,
      0.07621408253908157,
      -0.058514151722192764,
      0.00428090151399374,
      -0.032007552683353424,
      -0.051788579672575,
      -0.03006751462817192,
      0.00901688914746046,
      0.030108904466032982,
      0.11255338042974472,
      -0.036420371383428574,
      -0.026028968393802643,
      -0.013580524362623692,
      -0.018792297691106796,
      0.01421718206256628,
      -0.0013478891924023628,
      0.031126851215958595,
      -0.003057974623516202,
      -0.0410589836537838,
      0.0601004995405674,
      -0.03793097659945488,
      0.02443956583738327,
      0.040875229984521866,
      -0.0022497158497571945,
      -0.07147543132305145,
      -0.07541503012180328,
      -0.038661178201436996,
      -0.013840530067682266,
      0.027615047991275787,
      -0.013089348562061787,
      0.008165965788066387,
      -0.020742399618029594,
      0.012921121902763844,
      0.07443501800298691,
      0.03284906968474388,
      0.02043338306248188,
      0.0002903657441493124,
      0.0004927411791868508,
      0.04755153879523277,
      0.04037495702505112,
      0.0014946144074201584,
      0.003444122849032283,
      0.02822721190750599,
      -0.05989142507314682,
      0.05270130932331085,
      -0.02995743602514267,
      -0.014385041780769825,
      0.0417366586625576,
      -0.02670336700975895,
      0.005871806759387255,
      0.015506801195442677,
      -0.0688345730304718,
      0.040486451238393784,
      -0.0075471107847988605,
      -0.08094674348831177,
      0.050801150500774384,
      0.04082242771983147,
      -0.02067362144589424,
      -0.013019069097936153,
      -0.14604192972183228,
      -0.021854877471923828,
      -0.02243202179670334,
      0.009383243508636951,
      -0.0030505331233143806,
      -0.004910230170935392,
      -0.0011371317086741328,
      -0.0326390415430069,
      0.10984290391206741,
      0.05400810390710831,
      -0.00045429044985212386,
      -0.05541657283902168,
      0.03163515776395798,
      0.02523326687514782,
      0.08271874487400055,
      -0.030078785493969917,
      -0.011367513798177242,
      -0.029994292184710503,
      -0.06369826942682266,
      0.011389627121388912,
      -0.01062555518001318,
      0.06846699863672256,
      0.006612760946154594,
      0.05220567062497139,
      -0.003238590434193611,
      0.0556582435965538,
      -0.03609359264373779,
      -0.025441152974963188,
      0.0005039036041125655,
      0.022781670093536377,
      -0.0001317427959293127,
      0.03861011937260628,
      -0.025473371148109436,
      -0.02741202525794506,
      0.01091047003865242,
      0.0400891974568367,
      0.050158314406871796,
      -0.006108360830694437,
      -0.013251212425529957,
      0.045532919466495514,
      -0.020039115101099014,
      0.02525615319609642,
      -0.01646902784705162,
      0.010507464408874512,
      -0.15031103789806366,
      0.010702582076191902,
      -0.048142578452825546,
      -0.05804511904716492,
      -0.011770685203373432,
      -0.0073122731409966946,
      -0.011752323247492313,
      -0.004993018694221973,
      -0.0048211985267698765,
      0.009013126604259014,
      -0.02146410569548607,
      0.010148453526198864,
      -0.02349935658276081,
      -0.03084595687687397,
      0.06010235473513603,
      0.01247481070458889,
      -0.022392960265278816
    ],
    [
      -0.0795653834939003,
      -0.06637503206729889,
      -0.09651700407266617,
      0.00986301340162754,
      -0.04665837064385414,
      0.024373842403292656,
      -0.0067766765132546425,
      0.007240843027830124,
      0.005208284128457308,
      0.02343195304274559,
      0.021817153319716454,
      -0.10467047244310379,
      0.04545408487319946,
      0.00014246738282963634,
      0.034418985247612,
      0.04086161032319069,
      -0.04403180629014969,
      -0.01043558306992054,
      -0.002664289204403758,
      0.054048094898462296,
      0.016046667471528053,
      0.034102343022823334,
      0.003197220852598548,
      0.0012159902835264802,
      -0.03338979557156563,
      -0.016336767002940178,
      -0.07782772183418274,
      0.044303830713033676,
      0.053786590695381165,
      0.0030297304037958384,
      -0.05690621957182884,
      0.03232641518115997,
      -0.026759089902043343,
      0.02326144278049469,
      0.005050575360655785,
      -0.030913572758436203,
      0.012563525699079037,
      -0.02014952525496483,
      0.0061264499090611935,
      0.060157474130392075,
      -0.0046198139898478985,
      -0.018357856199145317,
      -0.02024800330400467,
      -0.02094660885632038,
      -0.027388038113713264,
      -0.011972988955676556,
      -0.00851984228938818,
      0.04581676423549652,
      -0.04385768622159958,
      0.0011325329542160034,
      0.06722937524318695,
      0.007828527130186558,
      0.002947760745882988,
      0.07209683209657669,
      -0.047324370592832565,
      -0.010184673592448235,
      -0.03830108419060707,
      -0.03908897936344147,
      -0.004916022066026926,
      -0.09878138452768326,
      0.053482409566640854,
      0.02618759498000145,
      -0.06555765122175217,
      -0.07462621480226517,
      0.025837035849690437,
      -0.010514827445149422,
      0.007716547232121229,
      0.06096651032567024,
      0.03991442546248436,
      -0.007147179916501045,
      -0.07022170722484589,
      0.0179462730884552,
      -0.0486278161406517,
      -0.06398885697126389,
      0.001897833775728941,
      -0.10802190750837326,
      -0.030218584463000298,
      0.028790747746825218,
      -0.02967052161693573,
      -0.026341216638684273,
      -0.007462151814252138,
      0.033307500183582306,
      -0.057287123054265976,
      0.0427471287548542,
      0.026998529210686684,
      -0.06193486601114273,
      -0.08314166218042374,
      -0.005281440448015928,
      -0.005533906165510416,
      -0.03027084283530712,
      0.0470048263669014,
      -0.03048609383404255,
      0.019298311322927475,
      -0.05738493427634239,
      0.0009684946853667498,
      -0.036367062479257584,
      -0.06096317246556282,
      0.03711101785302162,
      -0.10920289158821106,
      0.08491610735654831,
      -0.003178767627105117,
      0.07590136677026749,
      -0.003216819604858756,
      -0.06871548295021057,
      -0.015378900803625584,
      -0.00880287867039442,
      0.0048967283219099045,
      -0.040096405893564224,
      -0.004822136368602514,
      0.06910515576601028,
      0.016509609296917915,
      -0.014036509208381176,
      0.012698708102107048,
      0.004911344964057207,
      0.031735677272081375,
      0.010400095954537392,
      0.02379092201590538,
      0.014635281637310982,
      0.0117453932762146,
      -0.043386347591876984,
      0.00011879060912178829,
      0.014754826202988625,
      0.027158750221133232,
      -0.060844387859106064,
      0.08997604995965958,
      -0.017958398908376694,
      0.030671430751681328,
      0.005650205537676811,
      0.03551055118441582,
      0.0017787560354918242,
      -0.028888681903481483,
      0.04579315334558487,
      -0.015992404893040657,
      -0.05253688991069794,
      -0.06520767509937286,
      -0.0424807034432888,
      -0.024876568466424942,
      0.0076496959663927555,
      0.01805872656404972,
      -0.010450613684952259,
      0.03745410591363907,
      0.0061433748342096806,
      -0.018447797745466232,
      0.0030420252587646246,
      0.07517517358064651,
      -0.0016284567536786199,
      -0.007186648435890675,
      0.0016567225102335215,
      -0.055106859654188156,
      -0.06799113750457764,
      0.00939155649393797,
      0.049123477190732956,
      0.026563342660665512,
      -0.011324985884130001,
      -0.042778246104717255,
      -0.011646511033177376,
      0.023610275238752365,
      0.049099475145339966,
      0.009611271321773529,
      0.08209013938903809,
      0.03186279907822609,
      0.057350073009729385,
      0.0013493295991793275,
      -0.03410277143120766,
      0.050095297396183014,
      0.05286647751927376,
      0.004711768124252558,
      -0.010807554237544537,
      -0.026513660326600075,
      -0.060254938900470734,
      0.008817547932267189,
      0.03049997054040432,
      0.08139479160308838,
      0.018622668460011482,
      -0.036363840103149414,
      0.045691248029470444,
      0.0798027291893959,
      0.05221918597817421,
      -0.017717158421874046,
      -0.01927781105041504,
      -0.07370595633983612,
      0.06389349699020386,
      0.024333670735359192,
      0.0046716369688510895,
      -0.023206394165754318,
      -0.015598501078784466,
      0.06225985288619995,
      0.02663893811404705,
      -0.08805441856384277,
      0.02046104706823826,
      -0.010596572421491146,
      0.017659377306699753,
      0.060102611780166626,
      0.01956133171916008,
      -0.026023568585515022,
      -0.0096430080011487,
      -0.018157964572310448,
      -0.019144032150506973,
      0.001596903777681291,
      0.03447618708014488,
      0.04322751238942146,
      -0.059327382594347,
      0.017740029841661453,
      -0.029060931876301765,
      -0.0544419027864933,
      -0.01575974002480507,
      0.024192482233047485,
      -0.017676593735814095,
      0.09206724166870117,
      -0.02792447805404663,
      -0.018724601715803146,
      -0.04031449556350708,
      -0.00697936350479722,
      0.021693993359804153,
      -0.04265182837843895,
      0.028370307758450508,
      0.054338037967681885,
      -0.054995451122522354,
      -0.04106047376990318,
      0.038062505424022675,
      -0.08971697837114334,
      -0.01895005814731121,
      -0.04555869847536087,
      -0.09625647217035294,
      0.02993752993643284,
      0.049161188304424286,
      -0.08985664695501328,
      -0.06472063064575195,
      -0.024203911423683167,
      0.022066166624426842,
      0.021479355171322823,
      -0.013376529328525066,
      -0.027956625446677208,
      0.00926364865154028,
      -0.027901798486709595,
      0.019677242264151573,
      0.014283895492553711,
      -0.04297899082303047,
      0.00416948739439249,
      0.05166839063167572,
      -0.04035817086696625,
      -0.051988791674375534,
      0.018352041020989418,
      0.033140551298856735,
      0.048245951533317566,
      0.06310421973466873,
      -0.03418545797467232,
      0.00830357987433672,
      0.04911072924733162,
      0.03069458343088627,
      -0.04846567288041115,
      -0.011748663149774075,
      -0.10500030964612961,
      -0.010529634542763233,
      0.0611223503947258,
      -0.025306083261966705,
      0.0037554404698312283,
      0.013056778348982334,
      -0.02237648144364357,
      0.021871160715818405,
      0.054852865636348724,
      0.056832797825336456,
      -0.03873872756958008,
      0.04907863587141037,
      -0.0004294241371098906,
      0.013151784427464008,
      -0.015338754281401634,
      0.03200694918632507,
      0.03211795911192894,
      -0.015320312231779099,
      0.04897375777363777,
      0.008236896246671677,
      -0.04027240723371506,
      -0.006257309578359127,
      0.05230988189578056,
      -0.006179522257298231,
      0.04449489340186119,
      0.015105237253010273,
      0.040572453290224075,
      -0.0946149006485939,
      0.02109849825501442,
      0.03972845524549484,
      0.04752384498715401,
      0.02073885314166546,
      0.0157501008361578,
      0.10670653730630875,
      -0.04253758490085602,
      -0.029504215344786644,
      0.00159152050036937,
      0.03221387788653374,
      -0.011600397527217865,
      -0.03113759122788906,
      0.030114496126770973,
      -0.026859743520617485,
      -0.030541490763425827,
      0.01996162161231041,
      0.05733470618724823,
      -0.015241245739161968,
      -0.08621864765882492,
      0.008579712361097336,
      0.024458948522806168,
      0.05421369522809982,
      0.04349958151578903,
      -0.0006396710523404181,
      -0.010274476371705532,
      0.03093567304313183,
      -0.02813871204853058,
      -0.006422268692404032,
      0.024442026391625404,
      -0.0009212986333295703,
      0.05251801013946533,
      0.03773882985115051,
      0.03250553831458092,
      -0.035994112491607666,
      -0.007893520407378674,
      0.01444108784198761,
      -0.03870200365781784,
      0.006526855751872063,
      0.0064191860146820545,
      -0.03464002534747124,
      -0.06414227187633514,
      0.0210904311388731,
      0.08835354447364807,
      -0.02706863358616829,
      -0.0664602518081665,
      -0.016199206933379173,
      -0.011964308097958565,
      0.011873179115355015,
      -0.08027122169733047,
      0.021781139075756073,
      0.05983760207891464,
      -0.05730320140719414,
      0.03544317185878754,
      0.011271185241639614,
      0.025834668427705765,
      0.027795113623142242,
      0.012701130472123623,
      0.008979625068604946,
      0.01704832911491394,
      0.04443005472421646,
      -0.04344334453344345,
      -0.02379455789923668,
      0.02250765450298786,
      -0.012689444236457348,
      -0.025227375328540802,
      0.06872838735580444,
      -0.057193487882614136,
      -0.06147472932934761,
      0.003591925371438265,
      0.0007290628855116665,
      -0.016795732080936432,
      -0.04092787578701973,
      0.009965784847736359,
      -0.056745827198028564,
      -0.026483938097953796,
      -0.006537117529660463,
      -0.013686231337487698,
      0.007029583677649498,
      0.03564757481217384,
      -0.01578189805150032,
      -0.02515353448688984,
      -0.01604164019227028,
      -0.012750791385769844,
      -0.0533122681081295,
      0.13596710562705994,
      0.008811689913272858,
      -0.039716821163892746,
      -0.04550287872552872,
      0.03895363584160805,
      -0.005624290555715561,
      -0.04837667942047119,
      0.026495331898331642,
      -0.024965817108750343,
      0.058778561651706696,
      0.026208050549030304,
      -0.011589359492063522,
      -0.024569155648350716,
      -0.04859543219208717,
      0.06830203533172607,
      -0.044758833944797516,
      0.02031809650361538,
      0.02758750133216381,
      -0.009743563830852509,
      -0.01879117824137211,
      0.022756600752472878,
      -0.01066386979073286,
      0.10735446959733963,
      0.021200038492679596,
      0.04845321923494339,
      0.06321027129888535,
      0.05334964394569397,
      -0.017299702391028404,
      -0.04194844514131546,
      0.0809963271021843,
      0.0598636195063591,
      -0.04004613682627678,
      -0.06261363625526428,
      -7.804844062775373e-05,
      -0.04699752479791641,
      0.05911463499069214,
      0.03354295715689659,
      -0.018754711374640465,
      -0.02020154520869255,
      -0.019630061462521553,
      0.03979652747511864,
      0.05629584193229675,
      -0.05032540112733841,
      0.054113779217004776,
      -0.07640642672777176,
      -0.07346165180206299,
      0.024814914911985397,
      -0.0046753608621656895,
      -0.035278137773275375,
      -0.005358558613806963,
      0.023048117756843567,
      0.015163645148277283,
      0.010194998234510422,
      0.035208363085985184,
      -0.010898277163505554,
      0.020100180059671402,
      -0.0633595809340477,
      -0.05578159913420677,
      -0.015185677446424961,
      0.06744552403688431,
      -0.002582930726930499,
      0.04834418371319771,
      -0.15796108543872833,
      -0.06228840351104736,
      -0.06700264662504196,
      0.02655249834060669,
      0.05056631192564964,
      0.08198105543851852,
      -0.010668200440704823,
      -0.002041653962805867,
      0.03708430752158165,
      -0.05949389189481735,
      0.0732642337679863,
      0.000738670933060348,
      0.04026982933282852,
      0.028144145384430885,
      0.036363646388053894,
      -0.020753521472215652,
      0.003174121957272291,
      0.005272310692816973,
      0.03393646329641342,
      -0.017815575003623962,
      -0.012323158793151379,
      0.03605423495173454,
      0.008502204902470112,
      0.09789969772100449,
      0.06561454385519028,
      -0.05393078923225403,
      0.043424151837825775,
      -0.007401110138744116,
      -0.032663553953170776,
      0.008785388432443142,
      0.09004104137420654,
      0.0350085087120533,
      0.032487910240888596,
      -0.0003657877678051591,
      -0.017524676397442818,
      0.039258576929569244,
      -0.007458223961293697,
      0.040426962077617645,
      0.014894627965986729,
      -0.03796762973070145,
      -0.0015344450948759913,
      -0.0019573085010051727,
      0.05986565724015236,
      -0.054541390389204025,
      -0.0657319501042366,
      -0.06465798616409302,
      -0.015578106977045536,
      0.006598588079214096,
      0.04450751468539238,
      0.022755460813641548,
      0.04079152271151543,
      0.05822204798460007,
      0.044164881110191345,
      -0.03980579227209091,
      0.04799025133252144,
      0.03610454499721527,
      0.01605827361345291,
      -0.007083339150995016,
      -0.0046562752686440945,
      7.58413370931521e-05,
      0.02905159629881382,
      0.01995239034295082,
      -0.005545502528548241,
      -0.01378674991428852,
      0.027982445433735847,
      0.04426700994372368,
      -0.025170989334583282,
      -0.04631446674466133,
      -0.016660338267683983,
      -0.005309857428073883,
      -0.015846310183405876,
      0.0002726954116951674,
      0.014151733368635178,
      0.11398493498563766,
      0.042505450546741486,
      -0.010340704582631588,
      0.03966628760099411
    ],
    [
      0.017049357295036316,
      -0.1114090234041214,
      -0.04651803895831108,
      0.006304265931248665,
      0.03346806764602661,
      -0.06253355741500854,
      -0.011758876033127308,
      0.035575512796640396,
      0.012714710086584091,
      -0.0394192710518837,
      -0.00958800408989191,
      0.034262366592884064,
      -0.012248299084603786,
      -0.08344630151987076,
      0.012608831748366356,
      0.021901383996009827,
      -0.04069926589727402,
      -0.02791409008204937,
      -0.033967453986406326,
      0.010443564504384995,
      0.046652767807245255,
      -0.0002794690662994981,
      -0.034878700971603394,
      0.019806746393442154,
      -0.024022316560149193,
      -0.06925288587808609,
      -0.028540441766381264,
      -0.004675674252212048,
      0.06105383485555649,
      0.0154414102435112,
      -0.03256899490952492,
      -0.06359270960092545,
      -0.06384967267513275,
      -0.024671699851751328,
      -0.06437444686889648,
      0.030036136507987976,
      0.07267211377620697,
      -0.005671472288668156,
      0.035969480872154236,
      0.06801210343837738,
      -0.06644298136234283,
      0.00038616740494035184,
      0.03251919522881508,
      0.022669946774840355,
      -0.05594531446695328,
      -0.006595911458134651,
      -0.026213806122541428,
      0.022372538223862648,
      0.010517486371099949,
      -0.0034858821891248226,
      0.018521562218666077,
      0.10194282978773117,
      -0.0718051940202713,
      -0.04031161218881607,
      0.04066915810108185,
      0.009512390941381454,
      -0.01759406551718712,
      0.004843126516789198,
      0.021301481872797012,
      -0.0024867255706340075,
      0.035702962428331375,
      0.010566229932010174,
      -0.04702089726924896,
      -0.0010035729501396418,
      -0.06971316784620285,
      -0.011618055403232574,
      0.030548151582479477,
      -0.000899774138815701,
      -0.009709094651043415,
      -0.019119301810860634,
      0.013238907791674137,
      0.052831195294857025,
      -0.0168006531894207,
      0.0280072670429945,
      0.0017235513078048825,
      -0.023520011454820633,
      0.0069160182029008865,
      0.0053456672467291355,
      -0.035297587513923645,
      0.04313594475388527,
      -0.019460761919617653,
      0.007038467098027468,
      -0.014676542952656746,
      0.05461060255765915,
      0.054215043783187866,
      -0.024548597633838654,
      -0.10118567943572998,
      -0.013121088966727257,
      0.04035644233226776,
      0.03181071951985359,
      -0.013966790400445461,
      -0.012353071011602879,
      0.06972527503967285,
      0.005449109245091677,
      -0.06194642186164856,
      -0.056569747626781464,
      0.03539939224720001,
      -0.06730343401432037,
      -0.0326613113284111,
      -0.054349400103092194,
      0.016553828492760658,
      -0.04063522443175316,
      -0.015272509306669235,
      -0.004969273693859577,
      0.06464416533708572,
      -0.003276580711826682,
      0.0055400896817445755,
      0.0324113555252552,
      -0.05917926877737045,
      -0.025546090677380562,
      0.010243107564747334,
      0.025109577924013138,
      -0.0182427316904068,
      -0.002761933719739318,
      -0.04774300754070282,
      -0.0036017310339957476,
      -0.04517979174852371,
      -0.12172450870275497,
      -0.013099399395287037,
      0.034421492367982864,
      0.013618863187730312,
      -0.05111711844801903,
      -0.03776809200644493,
      -0.033350687474012375,
      -0.018247555941343307,
      0.03125649318099022,
      -0.006845143157988787,
      0.018452998250722885,
      0.040348853915929794,
      0.024727722629904747,
      0.07128993421792984,
      0.0015018237754702568,
      0.019967321306467056,
      -0.028303591534495354,
      0.00931018777191639,
      -0.05104341357946396,
      0.01725509949028492,
      -0.03976733237504959,
      0.015732048079371452,
      0.054418861865997314,
      0.030678560957312584,
      0.08066666126251221,
      -0.023738406598567963,
      -0.05579742044210434,
      0.08711670339107513,
      -0.022029317915439606,
      -0.01208329014480114,
      0.022991850972175598,
      0.029185764491558075,
      -0.005992142949253321,
      -0.04201257601380348,
      0.031252261251211166,
      -0.006911802105605602,
      -0.05159270763397217,
      0.06754355877637863,
      -0.020801659673452377,
      -0.059322308748960495,
      -0.0699685737490654,
      -0.06792338192462921,
      -0.03150179237127304,
      0.030083928257226944,
      -0.04646725580096245,
      0.014074690639972687,
      0.03252513334155083,
      -0.018049834296107292,
      -0.08126068115234375,
      -0.048290640115737915,
      0.011138212867081165,
      0.09151190519332886,
      -0.05818486958742142,
      0.03599719703197479,
      -0.014376559294760227,
      0.02862967923283577,
      -0.007932694628834724,
      -0.058065202087163925,
      -0.0017648454522714019,
      -0.015207176096737385,
      0.03643282130360603,
      0.005067424848675728,
      0.01699494570493698,
      0.07654717564582825,
      0.04538727179169655,
      -0.010076818987727165,
      -0.07457659393548965,
      -0.003690247191116214,
      -0.0036655867006629705,
      -0.015590646304190159,
      0.006403704639524221,
      0.011850140057504177,
      0.0034997500479221344,
      -0.03378838673233986,
      0.06620780378580093,
      -0.033188074827194214,
      -0.071367546916008,
      0.0319572277367115,
      -0.008196430280804634,
      -0.03359201177954674,
      0.02640494704246521,
      0.04246986284852028,
      -0.03598852828145027,
      0.0018896217225119472,
      -0.03509896621108055,
      0.021623816341161728,
      -0.02473517134785652,
      0.0008915035286918283,
      -0.03168033808469772,
      -0.018786054104566574,
      -0.031262047588825226,
      0.02121163345873356,
      -0.019560493528842926,
      0.05024319887161255,
      0.06289468705654144,
      0.03598000109195709,
      0.010771336033940315,
      0.029949473217129707,
      0.017751066014170647,
      -0.007001530844718218,
      0.03330865502357483,
      -0.04600444808602333,
      0.022430742159485817,
      -0.013313591480255127,
      0.05355580523610115,
      -0.05042905732989311,
      -0.038100358098745346,
      -0.11158636212348938,
      0.02827370911836624,
      -0.05882227420806885,
      -0.0001955490733962506,
      -0.06747856736183167,
      0.015475742518901825,
      0.037740208208560944,
      -0.038726065307855606,
      -0.012308765202760696,
      0.018460312858223915,
      0.023789875209331512,
      0.03539609536528587,
      0.03083878569304943,
      -0.042070284485816956,
      -0.04842868447303772,
      0.038426123559474945,
      -0.05158806964755058,
      -0.01466474961489439,
      -0.030147841200232506,
      -0.0440463125705719,
      -0.02379143238067627,
      0.05410154163837433,
      -0.025134338065981865,
      -0.020970337092876434,
      -0.0347527451813221,
      -0.06791957467794418,
      -0.006007229443639517,
      -0.027075834572315216,
      0.018346406519412994,
      -0.07788454741239548,
      -0.04037589207291603,
      -0.03785372152924538,
      0.018598482012748718,
      -0.012719226069748402,
      0.08714767545461655,
      -0.013628796674311161,
      -0.017291318625211716,
      0.022091157734394073,
      -0.009692121297121048,
      0.01403017621487379,
      -0.01647457666695118,
      -0.01826820708811283,
      -0.014572389423847198,
      -0.03832051903009415,
      0.018688971176743507,
      -0.03336848318576813,
      0.052698053419589996,
      0.004219541791826487,
      0.004334162455052137,
      0.04795008525252342,
      0.06855729222297668,
      -0.08383802324533463,
      9.84467551461421e-05,
      0.04271923378109932,
      -0.05853734910488129,
      0.005392775405198336,
      -0.04965751990675926,
      -0.03542839363217354,
      0.0044267438352108,
      -0.004284748341888189,
      0.022127464413642883,
      0.05299399420619011,
      -0.021211208775639534,
      -0.04646177217364311,
      -0.005647736135870218,
      0.028782391920685768,
      0.04630507156252861,
      -0.033753473311662674,
      0.012813338078558445,
      0.01534371916204691,
      -0.06726058572530746,
      0.023622136563062668,
      0.03575873002409935,
      -0.043300747871398926,
      0.02924366481602192,
      -0.02094409056007862,
      -0.038585465401411057,
      0.006932761520147324,
      -0.021662550047039986,
      -0.00026843530940823257,
      0.013477209955453873,
      -0.039700932800769806,
      0.06126818060874939,
      0.049670957028865814,
      0.023259896785020828,
      -0.014991977252066135,
      -0.02536100335419178,
      -0.06233237311244011,
      0.03665754199028015,
      -0.08465766906738281,
      -0.07869236916303635,
      0.011490095406770706,
      -0.016600321978330612,
      0.013658352196216583,
      0.02210005186498165,
      0.011911180801689625,
      -0.020411355420947075,
      -0.021641746163368225,
      0.011582412756979465,
      -0.029607372358441353,
      0.024680694565176964,
      -0.03402503952383995,
      -0.03783241659402847,
      0.05065424367785454,
      -0.0052639031782746315,
      -0.01972300000488758,
      0.06701929867267609,
      -0.050336021929979324,
      0.02391391061246395,
      -0.004113263916224241,
      0.057882439345121384,
      -0.03658898174762726,
      0.006808378733694553,
      0.05847393721342087,
      0.015165251679718494,
      -0.061092499643564224,
      0.02420652098953724,
      0.04562906548380852,
      0.013662336394190788,
      0.001969366567209363,
      -0.06040361151099205,
      0.10796304047107697,
      0.004929722752422094,
      0.0034954010043293238,
      0.01993689127266407,
      -0.016046401113271713,
      0.0027992615941911936,
      -0.08124601095914841,
      -0.0031464954372495413,
      0.04542297124862671,
      0.027663186192512512,
      0.05725116655230522,
      -0.013303440064191818,
      0.019916905090212822,
      0.020770490169525146,
      -0.005451762583106756,
      -0.07230737805366516,
      -0.009676116518676281,
      -0.07384904474020004,
      0.0006172801950015128,
      0.06431371718645096,
      -0.046610716730356216,
      0.008328673429787159,
      -0.021740982308983803,
      0.0027892400976270437,
      0.022324971854686737,
      -0.011903917416930199,
      0.03296443819999695,
      0.0485263355076313,
      0.05323519557714462,
      0.055720310658216476,
      0.02670160122215748,
      0.007746030576527119,
      0.011551608331501484,
      -0.08057931065559387,
      -0.022291190922260284,
      -0.06491108983755112,
      0.05179864913225174,
      -0.05479832738637924,
      -0.010737979784607887,
      -0.06791990995407104,
      0.08228615671396255,
      0.0435679629445076,
      -0.06294707953929901,
      -0.061161864548921585,
      -0.014096332713961601,
      0.07552928477525711,
      0.05473056435585022,
      0.0011474872007966042,
      0.07848066091537476,
      -0.055274900048971176,
      -0.07221898436546326,
      0.02028392069041729,
      0.07061801850795746,
      0.038891080766916275,
      -0.06097730994224548,
      0.08884914219379425,
      0.02733139507472515,
      -0.00399162108078599,
      -0.03914806991815567,
      0.03334173187613487,
      -0.04932934045791626,
      -0.06406189501285553,
      -0.041652075946331024,
      -0.023044811561703682,
      0.002007552422583103,
      -0.021875949576497078,
      -0.03429233282804489,
      -0.0020178609993308783,
      -0.029974548146128654,
      -0.09429343789815903,
      -0.029700228944420815,
      0.00475446367636323,
      -0.008880538865923882,
      0.08733620494604111,
      0.03719624504446983,
      -0.03778846189379692,
      0.09964127093553543,
      -0.04103722423315048,
      0.0036273468285799026,
      -0.024323487654328346,
      -0.02043801359832287,
      0.0111839035525918,
      -0.017332810908555984,
      -0.05185951292514801,
      0.013141214847564697,
      -0.002599014900624752,
      -0.028524352237582207,
      0.015043285675346851,
      -0.0017041291575878859,
      -0.0048110163770616055,
      -0.017857983708381653,
      -0.01555891614407301,
      -0.042704928666353226,
      0.06228852644562721,
      0.03661114722490311,
      -0.030201483517885208,
      0.0869566947221756,
      -0.07071007043123245,
      -0.0013841837644577026,
      0.014021000824868679,
      -0.028202887624502182,
      -0.04862477257847786,
      -0.03611421585083008,
      -0.017042485997080803,
      -0.002037778962403536,
      -0.060813941061496735,
      0.013831193558871746,
      0.029676470905542374,
      0.02813882753252983,
      -0.09123431891202927,
      -0.022194942459464073,
      -0.061489783227443695,
      -0.03716399893164635,
      -0.06502927094697952,
      0.052759211510419846,
      0.0091391047462821,
      -0.0362592488527298,
      -0.05881497636437416,
      0.012600932270288467,
      0.044757623225450516,
      -0.046637751162052155,
      -0.023617545142769814,
      -0.04775914177298546,
      -0.009212431497871876,
      0.04163164272904396,
      -0.09520472586154938,
      -0.04583188518881798,
      -0.021989261731505394,
      -0.016545601189136505,
      0.0051023648120462894,
      0.0037928568199276924,
      -0.052434042096138,
      0.04186702147126198,
      -0.05279836058616638,
      0.04702453315258026,
      -0.05608154088258743,
      0.056346818804740906,
      -0.023181675001978874,
      -0.013510883785784245,
      -0.02960982546210289,
      -0.008791868574917316,
      7.553035538876429e-05,
      0.029008924961090088,
      -0.050458528101444244,
      0.012139344587922096,
      -0.021418875083327293,
      -0.0312696173787117,
      0.006197729613631964,
      0.02191937156021595,
      0.03416196629405022,
      0.0029925021808594465,
      0.048537760972976685,
      -0.012494191527366638,
      0.027890684083104134,
      -0.05430009216070175,
      0.04580314829945564,
      -0.04477759078145027,
      0.04414432495832443
    ],
    [
      -0.014499271288514137,
      0.04809332266449928,
      0.03304242715239525,
      0.03033716231584549,
      0.010184754617512226,
      -0.057217348366975784,
      -0.04242164269089699,
      0.032248981297016144,
      0.018972819671034813,
      0.033677730709314346,
      -0.0008084097062237561,
      0.05312482640147209,
      -0.04908103123307228,
      0.020773490890860558,
      -0.04068054258823395,
      0.0009145470103248954,
      0.03690524399280548,
      -0.01298751961439848,
      0.054155610501766205,
      -0.0033191030379384756,
      -0.022188875824213028,
      0.01612548716366291,
      0.012242424301803112,
      -0.07430527359247208,
      0.053671304136514664,
      -0.024511069059371948,
      0.015218157321214676,
      -0.015570268034934998,
      0.029375242069363594,
      0.03504367172718048,
      -0.00012721138773486018,
      -0.03621205314993858,
      0.028923889622092247,
      -0.014162836596369743,
      0.09771302342414856,
      0.04154904931783676,
      0.04442267119884491,
      -0.02367180958390236,
      -0.041603896766901016,
      0.037299517542123795,
      0.014695756137371063,
      0.005869477055966854,
      0.020958779379725456,
      -0.05874442681670189,
      -0.04020955041050911,
      -0.009170944802463055,
      -0.060725823044776917,
      0.030998658388853073,
      0.01427626982331276,
      0.057892557233572006,
      0.0505080446600914,
      0.03191405534744263,
      0.04042737931013107,
      0.0006228318670764565,
      0.07284193485975266,
      -0.006507257465273142,
      0.0075426604598760605,
      -0.0700925961136818,
      -0.07038626074790955,
      0.05415961891412735,
      -0.007294327020645142,
      -0.031008202582597733,
      -0.024126086384058,
      0.044657692313194275,
      -0.00478889187797904,
      0.028687654063105583,
      0.04880666732788086,
      -0.014716014266014099,
      0.0028951133135706186,
      -0.04336206614971161,
      -0.11663453280925751,
      -0.08708719164133072,
      -0.08135413378477097,
      -0.08893943578004837,
      0.05808831378817558,
      0.047400400042533875,
      0.05566330999135971,
      0.039318621158599854,
      0.050957731902599335,
      0.05254480615258217,
      -0.028921954333782196,
      0.07171061635017395,
      0.07074093073606491,
      0.04126058146357536,
      -0.032745566219091415,
      -0.03829406574368477,
      -0.01586811989545822,
      0.06740076839923859,
      0.018390903249382973,
      0.017555922269821167,
      -0.019741257652640343,
      0.0708647146821022,
      0.008380069397389889,
      -0.007140503264963627,
      0.025371985509991646,
      -0.012135044671595097,
      -0.02818349376320839,
      -0.012383371591567993,
      0.009005816653370857,
      0.053452156484127045,
      0.030052341520786285,
      -0.05054181069135666,
      -0.08593886345624924,
      -0.05132802203297615,
      -0.009464134462177753,
      -0.03222373127937317,
      0.025603031739592552,
      -0.0429212786257267,
      0.004377442877739668,
      0.027420371770858765,
      -0.04923490807414055,
      0.009241415187716484,
      -0.07835954427719116,
      0.02179202437400818,
      0.10972298681735992,
      0.043239887803792953,
      -0.00207217363640666,
      -0.06850344687700272,
      0.038877200335264206,
      0.014360952191054821,
      -0.07353771477937698,
      -0.0029740380123257637,
      -0.04750566557049751,
      -0.01687128283083439,
      0.019127219915390015,
      0.04592825472354889,
      -0.04650198295712471,
      0.004192243795841932,
      -0.06661445647478104,
      -0.0005354449385777116,
      -0.007797837257385254,
      -0.05780716612935066,
      -0.03962196409702301,
      -0.0006845881580375135,
      0.0514715351164341,
      0.040714651346206665,
      -0.02842956967651844,
      0.061269231140613556,
      -0.05424574017524719,
      -0.005142322275787592,
      0.06975541263818741,
      -0.014633450657129288,
      0.06955939531326294,
      0.010070422664284706,
      -0.01680244505405426,
      0.019250575453042984,
      -0.058152563869953156,
      -0.0062966737896203995,
      0.04498758539557457,
      0.01050950214266777,
      0.013770674355328083,
      0.0741274356842041,
      -0.002991966437548399,
      0.045948613435029984,
      0.04236375167965889,
      0.02510051615536213,
      0.04416278749704361,
      0.02576371654868126,
      0.01869627647101879,
      -0.07034747302532196,
      -0.008132821880280972,
      0.05550602078437805,
      0.035809725522994995,
      -0.08939513564109802,
      -0.014062588103115559,
      0.010804712772369385,
      0.008706774562597275,
      0.020283393561840057,
      0.0322548970580101,
      -0.0002698460884857923,
      0.052222076803445816,
      -0.01980990171432495,
      0.02465382218360901,
      0.026902997866272926,
      -0.020768573507666588,
      -0.007379454094916582,
      0.008370611816644669,
      -0.030564622953534126,
      0.019033677875995636,
      0.06571642309427261,
      0.04078047350049019,
      -0.007713823113590479,
      -0.02265399880707264,
      -0.03515913337469101,
      0.06638209521770477,
      -0.046662576496601105,
      -0.004302105866372585,
      -0.01611940748989582,
      -0.07616613060235977,
      -0.0370556116104126,
      0.03590664267539978,
      0.09832270443439484,
      0.039917849004268646,
      -0.03738909587264061,
      0.02011539600789547,
      -0.035949692130088806,
      0.031498465687036514,
      -0.043055444955825806,
      -0.07213736325502396,
      0.048349808901548386,
      -0.024464692920446396,
      -0.07966975122690201,
      -0.04590911045670509,
      -0.012465092353522778,
      -0.08245738595724106,
      -0.01992650330066681,
      -0.04814934730529785,
      -0.01083946693688631,
      0.05238180235028267,
      -0.024881627410650253,
      0.0090139489620924,
      0.01171957142651081,
      0.010280294343829155,
      0.016116729006171227,
      -0.019573654979467392,
      0.05614248663187027,
      -0.003382665803655982,
      -0.05246950313448906,
      0.03606570512056351,
      -0.0421563982963562,
      -0.048743173480033875,
      0.07614217698574066,
      0.05211784318089485,
      0.014523987658321857,
      -0.04902806505560875,
      -0.13629361987113953,
      -0.02874615602195263,
      -0.08969295769929886,
      -0.02098773792386055,
      0.014160044491291046,
      0.06019943207502365,
      0.003940145950764418,
      0.014250949025154114,
      0.030620519071817398,
      0.01364720519632101,
      0.0328720398247242,
      -0.0881408154964447,
      0.012397795915603638,
      0.06901174038648605,
      -0.06570730358362198,
      -0.019045913591980934,
      0.08256427943706512,
      -0.04480680450797081,
      0.031369201838970184,
      0.031227247789502144,
      0.0822579637169838,
      -0.0371008925139904,
      0.0038929602596908808,
      -0.04743024334311485,
      -0.050818849354982376,
      -0.03605003282427788,
      0.018966440111398697,
      0.005379271227866411,
      0.03393658250570297,
      -0.022590475156903267,
      0.06698373705148697,
      0.04770061373710632,
      0.031864359974861145,
      -0.01443878747522831,
      -0.09112537652254105,
      -0.04768548160791397,
      0.028341831639409065,
      -0.004024853929877281,
      -0.05142831802368164,
      0.030600126832723618,
      -0.041556693613529205,
      -0.027024701237678528,
      0.0004247442411724478,
      -0.08515539765357971,
      0.04589317739009857,
      0.08134889602661133,
      -0.015057943761348724,
      -0.013714810833334923,
      0.04522697627544403,
      0.06193961203098297,
      -0.01715153083205223,
      -0.002307359129190445,
      -0.0029545186553150415,
      0.03399702534079552,
      0.02772611379623413,
      0.01915462501347065,
      0.007668802514672279,
      0.07112231850624084,
      -0.07881652563810349,
      0.00999990850687027,
      0.05561819300055504,
      0.03386471047997475,
      -0.030366739258170128,
      0.03726613149046898,
      -0.0428185798227787,
      0.00903826393187046,
      0.050800006836652756,
      -0.10093209892511368,
      -0.013996691443026066,
      -0.04898042976856232,
      -0.0754302516579628,
      -0.089105524122715,
      -0.02369772270321846,
      0.045235589146614075,
      -0.010776689276099205,
      0.03760477900505066,
      -0.07560272514820099,
      -0.01861295849084854,
      -0.003639458678662777,
      -0.03774794936180115,
      -0.020687928423285484,
      -0.0826801061630249,
      0.02179723046720028,
      0.0769243836402893,
      0.11280457675457001,
      -0.07061213254928589,
      -0.005488162394613028,
      0.11071184277534485,
      -0.03680024668574333,
      -0.008334657177329063,
      -0.016967030242085457,
      0.021629171445965767,
      0.0460699088871479,
      -0.04120291769504547,
      -0.006624650675803423,
      0.043376680463552475,
      -0.03679386526346207,
      0.007575389463454485,
      -0.06505800038576126,
      -0.08217247575521469,
      -0.09906591475009918,
      0.004143985919654369,
      -0.0075798919424414635,
      -0.05136099085211754,
      -0.003905646502971649,
      -0.017757805064320564,
      -0.040417879819869995,
      0.07104950398206711,
      -0.006959583144634962,
      -0.02106829732656479,
      -0.002915731631219387,
      0.009874940849840641,
      0.034786123782396317,
      0.028171105310320854,
      -0.016577795147895813,
      0.021458599716424942,
      -0.06348443031311035,
      0.008199219591915607,
      -0.02710380032658577,
      0.02392282336950302,
      0.01405396219342947,
      0.05474289879202843,
      -0.03609756380319595,
      0.04499218985438347,
      -0.03950513154268265,
      0.02520790696144104,
      -0.11300458014011383,
      -0.020774047821760178,
      -0.06402499973773956,
      -0.08051356673240662,
      -0.021303242072463036,
      -0.016657007858157158,
      0.002521401271224022,
      0.026421383023262024,
      -0.05302722007036209,
      -0.009283396415412426,
      -0.029416635632514954,
      0.04203357920050621,
      0.07207202166318893,
      0.007131815422326326,
      -0.04264882206916809,
      0.04283522814512253,
      -0.011045918799936771,
      0.0555107481777668,
      0.11608081310987473,
      -0.012281911447644234,
      0.02154717966914177,
      0.08411180973052979,
      -0.03644822910428047,
      0.06815502047538757,
      0.018952278420329094,
      -0.1502433866262436,
      0.010533099994063377,
      0.05956369638442993,
      -0.02930959314107895,
      0.03979208320379257,
      -0.014615715481340885,
      0.008087498135864735,
      0.04076659306883812,
      0.013781297020614147,
      -0.04926798492670059,
      -0.12178037315607071,
      0.038782235234975815,
      -0.06999783217906952,
      -0.006792598403990269,
      -0.00794975459575653,
      0.05553823336958885,
      -0.07598599791526794,
      0.015085743740200996,
      0.04601295664906502,
      -0.029702233150601387,
      -0.07451504468917847,
      -0.023893708363175392,
      0.005284446757286787,
      -0.011341497302055359,
      0.04151281714439392,
      0.00817162822932005,
      0.03570178523659706,
      -0.043170709162950516,
      -0.021232811734080315,
      0.003151633543893695,
      0.005323649384081364,
      -0.008370495401322842,
      -0.03750232607126236,
      0.01431436836719513,
      -0.0655292421579361,
      -0.043338481336832047,
      0.025137705728411674,
      0.03669410198926926,
      0.003227483481168747,
      -0.06963630765676498,
      -0.029969455674290657,
      0.010659225285053253,
      -0.029022611677646637,
      -0.006176474504172802,
      -0.02830001339316368,
      0.03683888912200928,
      -0.026681073009967804,
      -0.04150018468499184,
      -0.028797997161746025,
      0.02792886272072792,
      0.021544840186834335,
      0.009815570898354053,
      -0.0015061304438859224,
      0.04309871047735214,
      -0.04933226853609085,
      0.02119573950767517,
      -0.03372740000486374,
      -0.023187346756458282,
      0.03545722737908363,
      0.004270775243639946,
      0.0974830761551857,
      -0.05748899281024933,
      -0.05578611418604851,
      -0.014804989099502563,
      -0.0609772764146328,
      -0.01119138766080141,
      0.018046405166387558,
      0.05567494034767151,
      0.028513994067907333,
      0.0907742977142334,
      0.09232214093208313,
      0.021677562966942787,
      -0.05125635117292404,
      -0.005817880388349295,
      0.10543055832386017,
      -0.07672860473394394,
      -0.08427349478006363,
      -0.056632932275533676,
      0.04912658408284187,
      -0.019042422994971275,
      0.031647346913814545,
      0.006530911661684513,
      -0.035580191761255264,
      -0.035973794758319855,
      0.02047904208302498,
      -0.0003711208119057119,
      0.01171865314245224,
      -0.01598001830279827,
      0.0037882565520703793,
      0.03513948619365692,
      -0.01281757652759552,
      -0.056222010403871536,
      0.03552519157528877,
      0.009659738279879093,
      -0.03921957314014435,
      0.034738171845674515,
      -0.045114774256944656,
      -0.014475757256150246,
      -0.008950969204306602,
      -0.08371539413928986,
      0.0779232382774353,
      -0.019261151552200317,
      0.028673123568296432,
      0.008952549658715725,
      0.04481879621744156,
      -0.08653250336647034,
      0.013513768091797829,
      -0.043184421956539154,
      -0.005893373861908913,
      0.012529049068689346,
      0.030034413561224937,
      -0.008306611329317093,
      0.04154830425977707,
      0.012175191193819046,
      -0.05297603830695152,
      0.00673455186188221,
      -0.08927473425865173,
      0.009655973874032497,
      -0.04798950254917145,
      -0.006891571916639805,
      -0.002061186358332634,
      0.030336303636431694,
      0.04061245173215866,
      0.09228112548589706,
      0.011348715052008629,
      -0.05052521079778671,
      0.019383791834115982
    ],
    [
      0.07636784017086029,
      0.04110005870461464,
      -0.026149241253733635,
      0.02775968611240387,
      -0.018133478239178658,
      0.029903898015618324,
      0.03968918323516846,
      0.06345487385988235,
      0.029278850182890892,
      -0.06108023598790169,
      -0.029363714158535004,
      -0.006805268581956625,
      0.06421743333339691,
      -0.028294622898101807,
      0.00033975852420553565,
      -0.07097794860601425,
      -0.0047059874050319195,
      0.015253003686666489,
      -0.018129533156752586,
      0.025493187829852104,
      0.015377501957118511,
      0.047591596841812134,
      0.0018448448972776532,
      0.03870142996311188,
      0.04177376255393028,
      0.020416071638464928,
      -0.04653119295835495,
      -0.07137848436832428,
      -0.020928774029016495,
      -0.04990686476230621,
      0.01715293526649475,
      -0.1097617894411087,
      -0.03057062067091465,
      -0.008034677244722843,
      -0.009543542750179768,
      0.0008050976903177798,
      0.02401934377849102,
      -0.014101912267506123,
      0.012859314680099487,
      0.011815590783953667,
      -0.04743672162294388,
      -0.0009094279957935214,
      0.012898038141429424,
      0.0019509573467075825,
      0.012478838674724102,
      -0.08378402143716812,
      -0.03848232701420784,
      -0.04611536115407944,
      0.020762287080287933,
      0.024762243032455444,
      0.0828576534986496,
      -0.06491819024085999,
      0.03545951098203659,
      0.05319885164499283,
      -0.12102166563272476,
      -0.013762730173766613,
      0.007884443737566471,
      -0.11550243198871613,
      0.011986153200268745,
      0.0696120336651802,
      0.07787900418043137,
      0.01426034327596426,
      -0.030401017516851425,
      -0.06282881647348404,
      -0.10394804924726486,
      0.025321414694190025,
      0.0005775585887022316,
      -0.016657093539834023,
      0.03934226557612419,
      0.06124891713261604,
      -0.047583285719156265,
      0.05255505442619324,
      0.0017842772649601102,
      -0.05750807374715805,
      -0.05993063375353813,
      -0.046300798654556274,
      0.03129355236887932,
      -0.02967923879623413,
      0.019581018015742302,
      -0.08112253248691559,
      -0.034984879195690155,
      0.04537001624703407,
      0.005380585789680481,
      -0.018665790557861328,
      0.036562729626894,
      0.06675279140472412,
      0.027390237897634506,
      -0.09576279670000076,
      -0.013187439180910587,
      -0.10352083295583725,
      0.010160228237509727,
      0.037264298647642136,
      -0.006994233001023531,
      0.057339396327733994,
      -0.013448088429868221,
      0.0027976057026535273,
      -0.03476565331220627,
      0.025336943566799164,
      0.049765460193157196,
      0.05716690048575401,
      -0.09826496243476868,
      -0.03447839617729187,
      -0.050712186843156815,
      0.03797563537955284,
      0.0025250983890146017,
      -0.01181828510016203,
      -0.023128045722842216,
      -0.03812519833445549,
      0.003440709551796317,
      -0.007086793426424265,
      0.029685037210583687,
      -0.053634125739336014,
      0.0036015515215694904,
      0.025016678497195244,
      -0.024807779118418694,
      -0.08424676209688187,
      -0.0033083283342421055,
      -0.0015837820246815681,
      0.01724630407989025,
      -0.013535660691559315,
      -0.03524244204163551,
      0.03864261507987976,
      -0.06298589706420898,
      -0.04513084515929222,
      -0.033162739127874374,
      -0.05163995921611786,
      -0.04846153035759926,
      0.0108296237885952,
      0.0439116396009922,
      -0.04671309515833855,
      0.0024983829353004694,
      -0.04704953730106354,
      -0.02863859198987484,
      -0.047401126474142075,
      -0.05889509618282318,
      0.016886241734027863,
      -0.02915022522211075,
      -0.006193080451339483,
      0.050224412232637405,
      0.06419108062982559,
      0.040011897683143616,
      0.0844336599111557,
      -0.026322530582547188,
      0.012368151918053627,
      -0.009903485886752605,
      0.040989525616168976,
      -0.024643098935484886,
      -0.05806292966008186,
      0.007626081816852093,
      -0.027173545211553574,
      -0.04951957240700722,
      0.01167679950594902,
      -0.01607866957783699,
      0.01341702975332737,
      -0.05602061375975609,
      0.02482409030199051,
      0.036484915763139725,
      0.007343766745179892,
      0.0255842637270689,
      0.0412592738866806,
      0.018030907958745956,
      -0.03252957761287689,
      -0.03391306474804878,
      0.0023914389312267303,
      0.08112235367298126,
      -0.05501987040042877,
      -0.06753949075937271,
      0.008885768242180347,
      0.013194916769862175,
      0.008295051753520966,
      -0.009946536272764206,
      -0.04280848801136017,
      -0.0512482188642025,
      0.03314931318163872,
      -0.005566530395299196,
      0.027044910937547684,
      -0.059428147971630096,
      0.0069696418941020966,
      -0.03294263780117035,
      0.003352995263412595,
      0.055385492742061615,
      0.04241025075316429,
      -0.001433971687220037,
      0.0562877431511879,
      -0.015456059016287327,
      -0.029979538172483444,
      -0.034261349588632584,
      0.026878591626882553,
      0.041262947022914886,
      -0.029171615839004517,
      -0.05817653238773346,
      0.05220821127295494,
      -0.017806008458137512,
      0.0960337221622467,
      -0.015213134698569775,
      0.07165087014436722,
      0.03192654252052307,
      -0.00023254337429534644,
      -0.10480915755033493,
      -0.08538446575403214,
      0.009306803345680237,
      0.03450198471546173,
      0.1343195140361786,
      0.08407501131296158,
      -0.04555516317486763,
      -0.01743192784488201,
      -0.03759699687361717,
      0.0069860476069152355,
      0.026292836293578148,
      0.0024692758452147245,
      0.048861972987651825,
      0.08598028868436813,
      0.03244130313396454,
      -0.05569038167595863,
      0.03110971674323082,
      -0.07635203003883362,
      -0.020426088944077492,
      0.006755707319825888,
      0.027493245899677277,
      0.049962449818849564,
      0.027120687067508698,
      0.02023281529545784,
      0.06351472437381744,
      -0.029479537159204483,
      0.005503528751432896,
      -0.021224092692136765,
      0.026023579761385918,
      0.014426465146243572,
      0.05163021385669708,
      0.08798408508300781,
      0.0380980521440506,
      0.004204527009278536,
      0.023998327553272247,
      -0.015369335189461708,
      -0.045014478266239166,
      -0.036409515887498856,
      -0.04687350615859032,
      0.08571723103523254,
      0.056799326092004776,
      0.0685114935040474,
      -0.031708262860774994,
      0.030307842418551445,
      0.09980873018503189,
      -0.07350042462348938,
      -0.008685770444571972,
      -0.005034039728343487,
      -0.057335272431373596,
      0.06926058977842331,
      0.06151382625102997,
      -0.04814615100622177,
      0.008594056591391563,
      0.03836668282747269,
      -0.020484237000346184,
      -0.031042039394378662,
      0.003663222072646022,
      0.04986479505896568,
      0.047406744211912155,
      0.0726449117064476,
      -0.008308104239404202,
      0.0024293255992233753,
      -0.06811100244522095,
      0.041920460760593414,
      0.026256920769810677,
      0.008299837820231915,
      -0.0124382758513093,
      -0.0003605944220907986,
      -0.014828810468316078,
      0.0015266516711562872,
      -0.07292788475751877,
      0.004037963692098856,
      0.044443778693675995,
      0.0035057594068348408,
      -0.09665120393037796,
      -0.003949128556996584,
      -0.03496350347995758,
      -0.035883571952581406,
      -0.05778602883219719,
      0.035088084638118744,
      0.046356938779354095,
      -0.06356336921453476,
      0.028650499880313873,
      -0.06272953003644943,
      -0.01675698347389698,
      -0.07874726504087448,
      0.011914011090993881,
      -0.030561814084649086,
      0.054003115743398666,
      -0.024220161139965057,
      -0.00899074599146843,
      -0.07832413911819458,
      0.028690632432699203,
      0.06571498513221741,
      -0.034702613949775696,
      0.012907292693853378,
      -0.019879050552845,
      0.05461709201335907,
      0.03298827260732651,
      -0.003400613320991397,
      0.0171549990773201,
      0.003234102390706539,
      0.09394178539514542,
      0.07526924461126328,
      -0.08979866653680801,
      0.07882732152938843,
      -0.011735470034182072,
      0.022120896726846695,
      -0.009207013063132763,
      -0.06913056969642639,
      0.06056991219520569,
      -0.03738512098789215,
      -0.04055429622530937,
      0.01705179363489151,
      -0.00139342132024467,
      0.01121470332145691,
      -0.1288139969110489,
      0.06959923356771469,
      0.01116849947720766,
      -0.0326605886220932,
      0.059380803257226944,
      0.014292788691818714,
      0.05819359049201012,
      -0.01853722333908081,
      0.01801973395049572,
      -0.07881446182727814,
      0.0441681444644928,
      -0.024963099509477615,
      0.012850094586610794,
      -0.007906260900199413,
      -0.07124605774879456,
      -0.005647037643939257,
      0.010496173985302448,
      -0.022884974256157875,
      -0.03626261278986931,
      0.04531443864107132,
      0.03365219011902809,
      0.020010292530059814,
      0.009168981574475765,
      0.046421267092227936,
      0.00889955647289753,
      0.008883755654096603,
      0.0428493358194828,
      0.01076714601367712,
      0.054253119975328445,
      0.03212688863277435,
      0.007459793705493212,
      -0.10956808179616928,
      0.0019591613672673702,
      -0.06484926491975784,
      -0.009392328560352325,
      -0.03843298554420471,
      0.002510820282623172,
      -0.009355239570140839,
      0.04227849468588829,
      0.010556102730333805,
      -0.090418241918087,
      -0.06211162358522415,
      0.029656587168574333,
      0.0044202180579304695,
      -0.023570209741592407,
      -0.05150509998202324,
      0.025125635787844658,
      0.0012881545117124915,
      0.02018631063401699,
      -0.053444597870111465,
      0.08475954085588455,
      0.03828828036785126,
      0.04675038531422615,
      0.03711229935288429,
      0.048453204333782196,
      0.017524342983961105,
      0.03321170434355736,
      -0.0149764409288764,
      -0.016179515048861504,
      -0.017544424161314964,
      0.0060690464451909065,
      0.10689960420131683,
      -0.01539534516632557,
      0.06702098250389099,
      -0.0027235897723585367,
      0.04601389542222023,
      -0.021202513948082924,
      0.03976910933852196,
      0.012604939751327038,
      0.031754326075315475,
      0.016692182049155235,
      -0.09093857556581497,
      -0.0030775596387684345,
      -0.012094520032405853,
      -0.1226811334490776,
      0.04178107902407646,
      -0.03316901624202728,
      0.0778547078371048,
      -0.02212419919669628,
      0.08598481863737106,
      -6.966458022361621e-05,
      -0.010184244252741337,
      0.022377336397767067,
      0.019811131060123444,
      -0.04108697921037674,
      -0.02084866538643837,
      0.03340499848127365,
      -0.03406218811869621,
      0.0195909570902586,
      -0.041567180305719376,
      -0.017886139452457428,
      -0.013554702512919903,
      0.042705655097961426,
      -0.012449179776012897,
      -0.04321704059839249,
      -0.033054791390895844,
      0.04123673588037491,
      -0.013474633917212486,
      -0.062008656561374664,
      -0.026202119886875153,
      -0.02425098977982998,
      0.027774786576628685,
      -0.06770307570695877,
      -0.041032575070858,
      0.011497069150209427,
      0.06297383457422256,
      -0.07316935062408447,
      0.04680928587913513,
      -0.03577777370810509,
      -0.01012758631259203,
      0.08395243436098099,
      -0.03593698516488075,
      0.041717663407325745,
      0.09048160910606384,
      -0.013462170027196407,
      -0.061687082052230835,
      0.03337275609374046,
      0.050641752779483795,
      -0.020954422652721405,
      0.00990444328635931,
      0.05514577776193619,
      -0.0027871904894709587,
      -0.0406334213912487,
      -0.08110850304365158,
      -0.019990263506770134,
      0.02291153371334076,
      0.05025293678045273,
      0.0386408306658268,
      -0.07822494208812714,
      0.00799610186368227,
      0.05437085032463074,
      -0.0651010274887085,
      -0.02750934287905693,
      -0.027576733380556107,
      -0.034327346831560135,
      0.004052671138197184,
      0.04012163355946541,
      -0.05316874012351036,
      -0.03410718962550163,
      -0.04205615445971489,
      0.007177993655204773,
      0.014699204824864864,
      0.02363470196723938,
      -0.012868845835328102,
      -0.05694956332445145,
      -0.049824707210063934,
      -0.05087675154209137,
      0.03302425518631935,
      0.011086219921708107,
      0.0061500659212470055,
      -0.03133845329284668,
      -0.0056698075495660305,
      0.04374905303120613,
      -0.043413206934928894,
      0.003630872583016753,
      -0.01778584159910679,
      0.0677296444773674,
      -0.05584803968667984,
      -0.055722687393426895,
      -0.05171313136816025,
      -0.08905873447656631,
      -0.0021418300457298756,
      -0.008096492849290371,
      -0.00682280957698822,
      0.09473004937171936,
      0.04542789235711098,
      -0.016229847446084023,
      -0.032941609621047974,
      -0.02373228594660759,
      0.033589962869882584,
      0.013009645976126194,
      -0.0729139894247055,
      0.01009288989007473,
      -0.0008367204573005438,
      -0.06959168612957001,
      0.016908036544919014,
      0.022608835250139236,
      0.04035385698080063,
      0.0427037812769413,
      -0.03613629937171936,
      -0.0042167119681835175,
      -0.017361704260110855,
      0.04748474806547165,
      -0.011744934134185314,
      -0.016428064554929733,
      0.004325468093156815,
      -0.023104697465896606,
      0.033252447843551636,
      0.02757893316447735
    ],
    [
      0.0351802334189415,
      0.022580254822969437,
      -0.029317831620573997,
      -0.00234383437782526,
      -0.011942318640649319,
      0.022155357524752617,
      0.017214328050613403,
      0.011417328380048275,
      0.020552990958094597,
      -0.04748356714844704,
      0.02238304913043976,
      0.02733071893453598,
      0.006255075801163912,
      -0.051051896065473557,
      -0.017347434535622597,
      0.012982180342078209,
      0.007003793027251959,
      -0.02257663570344448,
      0.051832593977451324,
      0.04068126901984215,
      0.010656964033842087,
      -0.03349853679537773,
      -0.01110314391553402,
      -0.025227436795830727,
      0.03784084692597389,
      -0.04197237268090248,
      -0.07969769090414047,
      -0.06686970591545105,
      -0.10881513357162476,
      -0.014965415000915527,
      -0.020966244861483574,
      0.10780961066484451,
      0.02110869064927101,
      0.036799635738134384,
      0.009398787282407284,
      0.004082945641130209,
      -0.00659857876598835,
      0.00037189340218901634,
      0.04196416959166527,
      -0.06338085234165192,
      -0.06743624806404114,
      0.03669729083776474,
      0.06576161086559296,
      -0.0007374502602033317,
      -0.02506653405725956,
      -0.03548376262187958,
      0.017018213868141174,
      -0.005473174620419741,
      -0.05092017725110054,
      -0.028647402301430702,
      -0.00779150053858757,
      -0.022508442401885986,
      0.11823263019323349,
      -0.048350367695093155,
      0.038387760519981384,
      -0.12187203019857407,
      -0.07085001468658447,
      -0.06385449320077896,
      0.05049777776002884,
      0.06070466339588165,
      0.00988929532468319,
      0.007040622644126415,
      0.006360562983900309,
      0.03794055059552193,
      0.10512367635965347,
      -0.021221589297056198,
      -0.041182324290275574,
      0.009177729487419128,
      0.051886431872844696,
      0.11034335941076279,
      0.03643587604165077,
      0.039160411804914474,
      0.005988461896777153,
      0.09355282783508301,
      0.010149001143872738,
      0.08294026553630829,
      -0.06991090625524521,
      -0.00540843466296792,
      0.021959654986858368,
      0.010702441446483135,
      0.03948696330189705,
      -0.017285412177443504,
      -0.02335866168141365,
      0.04538092762231827,
      -0.057735878974199295,
      -0.06621170043945312,
      -0.07249433547258377,
      -0.01967458799481392,
      0.026512764394283295,
      0.04428769275546074,
      0.025263138115406036,
      -0.029348134994506836,
      -0.002480556955561042,
      0.018633520230650902,
      0.05044858902692795,
      -0.08837132155895233,
      0.031069239601492882,
      -0.04584864154458046,
      -0.0555754229426384,
      0.08503730595111847,
      -0.05173979327082634,
      -0.04137549176812172,
      0.02515462413430214,
      -0.03677314147353172,
      0.08293613791465759,
      -0.003616218687966466,
      -0.017098883166909218,
      -0.018909230828285217,
      -0.051823247224092484,
      -0.03913267329335213,
      0.08753322064876556,
      -0.06046326830983162,
      0.018600869923830032,
      0.0075866165570914745,
      -0.04024805128574371,
      -0.05143746733665466,
      0.03732362762093544,
      -0.059868574142456055,
      -0.015035165473818779,
      -0.003245236352086067,
      0.006875365506857634,
      -0.040187545120716095,
      -0.12792034447193146,
      0.09984803199768066,
      -0.00713381078094244,
      -0.02297407016158104,
      -0.0661817267537117,
      -0.008714188821613789,
      -0.03413698077201843,
      0.05581843480467796,
      0.05190417915582657,
      -0.0333474799990654,
      -0.014083468355238438,
      -0.0210100170224905,
      0.0455312579870224,
      -0.014550593681633472,
      0.009890894405543804,
      0.026032397523522377,
      0.06276218593120575,
      0.031662389636039734,
      0.009383049793541431,
      0.03317737579345703,
      0.05629399046301842,
      -0.047641173005104065,
      0.09183202683925629,
      -0.030442288145422935,
      0.04661744832992554,
      0.02771337516605854,
      0.04597748443484306,
      -0.03641609847545624,
      -0.02076665684580803,
      -0.03382892534136772,
      0.008547943085432053,
      -0.01682521216571331,
      0.03560479357838631,
      0.020877640694379807,
      0.011905577965080738,
      0.005825090687721968,
      0.021669059991836548,
      0.04784747213125229,
      0.016111047938466072,
      -0.02331780642271042,
      0.07012020796537399,
      -0.027838414534926414,
      -0.015106981620192528,
      0.06714879721403122,
      0.015050932765007019,
      0.011969810351729393,
      -0.01181299053132534,
      0.027333328500390053,
      0.005394360050559044,
      -0.007445362396538258,
      0.007539523299783468,
      0.0832648053765297,
      0.018285997211933136,
      0.06736413389444351,
      0.09416034817695618,
      -0.006843633018434048,
      0.11424724757671356,
      -0.02015322633087635,
      -0.02884376235306263,
      0.018575338646769524,
      -0.016198184341192245,
      -0.015461036935448647,
      -0.044003214687108994,
      -0.0033882400020956993,
      0.04880994185805321,
      -0.0170576348900795,
      -0.08521794527769089,
      -0.02955448254942894,
      -0.028396179899573326,
      -0.10157839208841324,
      -0.049807097762823105,
      0.08416317403316498,
      0.032489705830812454,
      -0.05373629927635193,
      0.030533673241734505,
      -0.026902249082922935,
      0.044244974851608276,
      -0.054693855345249176,
      0.05496472865343094,
      0.014395925216376781,
      0.021941054612398148,
      -0.02593381144106388,
      0.06059280410408974,
      0.03119719587266445,
      0.00021178183669690043,
      -0.05930878594517708,
      -0.0026193063240498304,
      -0.042291123420000076,
      0.034195322543382645,
      0.03278474882245064,
      -0.07324990630149841,
      -0.02851465716958046,
      0.06220890209078789,
      -0.0019402941688895226,
      -0.04729944467544556,
      -0.014193112030625343,
      -0.001196029013954103,
      0.010597716085612774,
      0.043777063488960266,
      0.0541284903883934,
      -0.022486742585897446,
      -0.04891292750835419,
      0.005671662278473377,
      0.006930295843631029,
      -0.05557385832071304,
      0.02382243424654007,
      -0.06844090670347214,
      0.029597168788313866,
      0.11150781810283661,
      0.02654963731765747,
      0.0435587540268898,
      0.07313317805528641,
      -0.017679259181022644,
      -0.10253816097974777,
      -0.022587856277823448,
      0.020467329770326614,
      0.0811629518866539,
      0.01524366158992052,
      0.010943706147372723,
      0.05110599100589752,
      -0.041220031678676605,
      -0.01536569930613041,
      0.03585921972990036,
      -0.0461660698056221,
      0.008691050112247467,
      0.02739580161869526,
      0.03258536756038666,
      -0.004075595177710056,
      0.011868748813867569,
      0.01157436240464449,
      -0.002420591190457344,
      -0.08565112948417664,
      0.009931367821991444,
      -0.042866408824920654,
      0.0742998868227005,
      0.013711939565837383,
      0.0001592367043485865,
      0.010417558252811432,
      -0.02742019295692444,
      -0.05082166939973831,
      0.017996245995163918,
      -0.025268876925110817,
      0.04968678578734398,
      -0.020222749561071396,
      0.06444770842790604,
      0.022377092391252518,
      0.023317327722907066,
      -0.056746166199445724,
      0.05909726768732071,
      -0.022677689790725708,
      0.03098522312939167,
      -0.04645752161741257,
      0.027808869257569313,
      -0.0010572904720902443,
      -0.01004459336400032,
      0.01806604489684105,
      -0.028736460953950882,
      0.04599130153656006,
      0.004588855896145105,
      -0.04731553792953491,
      -0.05681617558002472,
      -0.06520067155361176,
      -0.008448071777820587,
      0.009345781058073044,
      0.025076860561966896,
      -0.07422469556331635,
      0.04782228544354439,
      0.012236743234097958,
      -0.043635956943035126,
      -0.09384140372276306,
      0.010572202503681183,
      0.047287408262491226,
      -0.027370283380150795,
      -0.05496279150247574,
      0.00420408695936203,
      0.07158762961626053,
      -0.08785499632358551,
      0.008469679392874241,
      0.025441069155931473,
      0.014588829129934311,
      0.07350368052721024,
      0.006869768723845482,
      -0.04831857234239578,
      -0.025769207626581192,
      0.05264750495553017,
      -0.03738328814506531,
      0.08022227138280869,
      0.057402655482292175,
      -0.07138707488775253,
      -0.004025942180305719,
      0.0542423315346241,
      0.0335976742208004,
      -0.02575691044330597,
      0.010574929416179657,
      -0.06238178536295891,
      -0.015092589892446995,
      0.08705686777830124,
      -0.07948682457208633,
      0.04344148188829422,
      -0.07194704562425613,
      -0.00211595487780869,
      -0.0025082838255912066,
      0.03175532817840576,
      -0.012670774012804031,
      0.014438827522099018,
      0.09357980638742447,
      -0.0012874709209427238,
      -0.020112736150622368,
      0.04593394324183464,
      -0.0036982877645641565,
      -0.005071834195405245,
      -0.05591880902647972,
      0.015081421472132206,
      -0.07481969147920609,
      -0.027136579155921936,
      -0.014413622207939625,
      0.034355755895376205,
      -0.020850947126746178,
      0.03565259277820587,
      -0.007517341524362564,
      -0.03172159567475319,
      -0.014931537210941315,
      0.027348577976226807,
      -0.035340096801519394,
      -0.03894920274615288,
      -0.028905771672725677,
      0.07176971435546875,
      0.011635797098279,
      -0.010131780058145523,
      -0.01418252382427454,
      -0.0022072438150644302,
      -0.019549479708075523,
      0.0703602135181427,
      -0.02999480813741684,
      0.06839171051979065,
      0.025428634136915207,
      0.05820285901427269,
      0.037252992391586304,
      -0.038174547255039215,
      -0.02758016809821129,
      -0.05689254775643349,
      0.013799771666526794,
      0.0225555207580328,
      0.027511874213814735,
      0.0004582954861689359,
      0.030828390270471573,
      0.013486023060977459,
      -0.041907891631126404,
      0.05511011555790901,
      -0.02447524107992649,
      0.014726023189723492,
      -0.07141604274511337,
      -0.06819013506174088,
      -0.06631583720445633,
      -0.012296317145228386,
      -0.018643518909811974,
      -0.02311469055712223,
      0.056657396256923676,
      0.008299192413687706,
      0.004868519492447376,
      0.0997515544295311,
      -0.021212609484791756,
      0.021007319912314415,
      -0.046804409474134445,
      -0.03723493218421936,
      -0.013663791120052338,
      0.04792370647192001,
      -0.046842023730278015,
      -0.03905147314071655,
      0.06872008740901947,
      -0.01682193949818611,
      0.03049704246222973,
      -0.038443826138973236,
      0.020157717168331146,
      -0.08504723757505417,
      -0.10310018062591553,
      -0.01748642884194851,
      -0.0036912155337631702,
      0.056630294770002365,
      -0.015519985929131508,
      -0.0058916532434523106,
      0.02680826559662819,
      0.02614723891019821,
      -0.03309767320752144,
      0.023587586358189583,
      -0.05907772481441498,
      0.0049888803623616695,
      0.0018717990024015307,
      0.026633840054273605,
      0.010845359414815903,
      -0.0818350687623024,
      -0.1062103658914566,
      0.02219221368432045,
      0.008748943917453289,
      0.05366351082921028,
      -0.06395256519317627,
      0.022169575095176697,
      -0.026612330228090286,
      0.03839153051376343,
      -0.06552565842866898,
      0.010908487252891064,
      0.0032953976187855005,
      0.010010813362896442,
      0.056164778769016266,
      0.024246636778116226,
      0.02580900676548481,
      -0.03686649352312088,
      0.031817637383937836,
      0.03866539150476456,
      0.034717265516519547,
      -0.0823773592710495,
      -0.05514441058039665,
      -0.051465146243572235,
      0.018560741096735,
      0.04338039830327034,
      0.028678977862000465,
      -0.022298593074083328,
      0.022954313084483147,
      0.014470764435827732,
      0.08215668797492981,
      0.04712047055363655,
      -0.029654229059815407,
      0.01542284619063139,
      -0.0531371533870697,
      -0.003013709792867303,
      0.002969863126054406,
      0.018946588039398193,
      0.04584101215004921,
      0.024745404720306396,
      -0.10609875619411469,
      -0.03249838203191757,
      -0.08917602151632309,
      0.0336960069835186,
      0.061923135071992874,
      -0.012491114437580109,
      0.04244548827409744,
      0.04049212858080864,
      0.02027161233127117,
      0.01661672443151474,
      -0.023708436638116837,
      0.04392310231924057,
      0.0759187713265419,
      -0.09150776267051697,
      -0.0076060425490140915,
      -0.022436613216996193,
      0.0017770676640793681,
      -0.032785043120384216,
      0.014693704433739185,
      0.0075801643542945385,
      0.03261366859078407,
      0.006063290871679783,
      0.06322230398654938,
      -9.115028660744429e-06,
      0.02826874330639839,
      0.0067095644772052765,
      -0.02127613127231598,
      -0.01389957033097744,
      0.015242411755025387,
      0.022051986306905746,
      0.036619462072849274,
      0.013189329765737057,
      -0.027212701737880707,
      -0.01137460209429264,
      -0.05914697051048279,
      0.03068387322127819,
      0.022776471450924873,
      -0.03300032764673233,
      0.014688611030578613,
      -0.1027458906173706,
      -0.10284391790628433,
      -0.044640928506851196,
      0.003578545991331339,
      0.032501958310604095,
      0.027821334078907967,
      -0.0026320028118789196,
      0.030915439128875732,
      0.04717656970024109,
      0.02066916786134243,
      -0.04183819517493248,
      -0.0032771483529359102,
      0.012606763280928135
    ],
    [
      0.016679110005497932,
      0.019853483885526657,
      -0.04357539117336273,
      -0.03576285019516945,
      -0.007930666208267212,
      -0.040471382439136505,
      0.014023253694176674,
      -0.02741890773177147,
      0.013312253169715405,
      0.09151516109704971,
      -0.044518936425447464,
      -0.1327497810125351,
      0.02645776979625225,
      -0.029847057536244392,
      -0.02512754499912262,
      -0.03410430997610092,
      0.05637534335255623,
      -0.04052574932575226,
      -0.004847741685807705,
      -0.06608504056930542,
      -0.034709155559539795,
      0.09855189919471741,
      0.04453098773956299,
      0.06755869090557098,
      -0.027780231088399887,
      -0.018692655488848686,
      -0.0031696052756160498,
      0.025770127773284912,
      -0.018438998609781265,
      0.02642609365284443,
      0.033285219222307205,
      0.007574252784252167,
      -0.056580111384391785,
      0.04366053268313408,
      0.06631646305322647,
      0.005258241668343544,
      0.01866019144654274,
      -0.0010685750748962164,
      0.05875440314412117,
      -0.0075243934988975525,
      -0.02867121435701847,
      -0.14399781823158264,
      0.022090988233685493,
      -0.04311785474419594,
      -0.04061858728528023,
      -0.10297318547964096,
      -0.024280129000544548,
      -0.04520362988114357,
      -0.0480058379471302,
      -0.0312986858189106,
      -0.052771586924791336,
      0.010081565007567406,
      -0.022899815812706947,
      -0.08070842176675797,
      -0.06466002017259598,
      -0.05105045065283775,
      -0.05604882165789604,
      -0.12655487656593323,
      0.010090864263474941,
      -0.02930638939142227,
      -0.0352306142449379,
      -0.04978635907173157,
      -0.015783539041876793,
      0.060551490634679794,
      0.06026977673172951,
      0.03210298717021942,
      0.0690622627735138,
      -0.08225114643573761,
      0.027232270687818527,
      -0.03626210242509842,
      0.09310293942689896,
      -0.015244411304593086,
      0.0018662664806470275,
      0.03673809766769409,
      -0.0002515903615858406,
      0.0674188882112503,
      0.025775669142603874,
      0.07971236854791641,
      0.049710363149642944,
      -0.048496853560209274,
      -0.010320763103663921,
      -0.0016439032042399049,
      -0.05266682431101799,
      0.04203830286860466,
      -0.02617133967578411,
      -0.01705111376941204,
      0.03162537142634392,
      -0.026541998609900475,
      -0.005095825996249914,
      0.05271486937999725,
      -0.012353208847343922,
      -0.010349676944315434,
      0.0068709105253219604,
      -0.022617122158408165,
      0.013553408905863762,
      -0.0012048052158206701,
      0.03126543387770653,
      -0.027677984908223152,
      0.11388526111841202,
      0.03879253566265106,
      0.06146921217441559,
      -0.05191619321703911,
      0.013525630347430706,
      0.07940366864204407,
      -0.03627363592386246,
      -0.006786249112337828,
      0.016380194574594498,
      0.0567370168864727,
      0.07440173625946045,
      0.014974962919950485,
      -0.04113069549202919,
      0.03780068829655647,
      0.015121322125196457,
      0.010979264043271542,
      -0.00773808965459466,
      -0.06066926568746567,
      -0.04646696150302887,
      0.016301274299621582,
      -0.0663677304983139,
      0.06935649365186691,
      -0.04265114665031433,
      0.0646437555551529,
      -0.01716533862054348,
      -0.07229983061552048,
      0.029118578881025314,
      -0.0207477156072855,
      -0.002465068129822612,
      0.061336699873209,
      0.05930856242775917,
      -0.09399683773517609,
      -0.004176062997430563,
      -0.051521867513656616,
      0.10689812153577805,
      0.021479178220033646,
      0.02259826473891735,
      0.019164104014635086,
      -0.053895894438028336,
      0.022219430655241013,
      0.02410353720188141,
      0.03234630823135376,
      0.07765673846006393,
      -0.019551897421479225,
      -0.007646231446415186,
      0.049282632768154144,
      0.06537185609340668,
      -0.03820260241627693,
      -0.053561512380838394,
      0.09004554152488708,
      -0.007981461472809315,
      -0.02017316408455372,
      0.04439808428287506,
      0.04352414608001709,
      -0.030106468126177788,
      -0.032552000135183334,
      0.04821668937802315,
      0.05873271822929382,
      -0.05134252458810806,
      -0.054381176829338074,
      -0.019654497504234314,
      0.004612175282090902,
      0.054787248373031616,
      0.0820244550704956,
      0.02060723304748535,
      0.059452276676893234,
      -0.014665456488728523,
      0.06619580090045929,
      0.013341095298528671,
      0.027419110760092735,
      0.01207655481994152,
      0.046623848378658295,
      -0.007525014691054821,
      -0.051819007843732834,
      0.010860210284590721,
      -0.0294675100594759,
      -0.01823723316192627,
      -0.07114865630865097,
      0.0448589026927948,
      0.023493018001317978,
      -0.013146424666047096,
      -0.01598336175084114,
      -0.01674157939851284,
      -0.09210053831338882,
      0.02331814356148243,
      -0.0740702673792839,
      -0.09710915386676788,
      -0.0014310168335214257,
      -0.0556897409260273,
      -0.04000895097851753,
      0.007124360650777817,
      -0.017494911327958107,
      0.02599627897143364,
      -0.11845249682664871,
      0.025230111554265022,
      0.05502459034323692,
      -0.08079247176647186,
      0.059017229825258255,
      -0.056380704045295715,
      -0.032260894775390625,
      0.03248518332839012,
      0.03808106109499931,
      0.06486441195011139,
      -0.03040708228945732,
      0.054443057626485825,
      -0.051899127662181854,
      0.04648450389504433,
      -0.007871938869357109,
      0.11728513240814209,
      0.038813672959804535,
      0.07118288427591324,
      -0.016208600252866745,
      0.012426579371094704,
      0.08323497325181961,
      -0.04322432354092598,
      -0.032670121639966965,
      0.08714018017053604,
      -0.011467581614851952,
      0.016983695328235626,
      -0.00867842510342598,
      -0.024217335507273674,
      0.10550719499588013,
      -0.052915483713150024,
      0.046430204063653946,
      -0.035414498299360275,
      -0.00696599530056119,
      0.09160467982292175,
      0.016553815454244614,
      0.015568564645946026,
      0.054462701082229614,
      -0.018183985725045204,
      -0.06688778847455978,
      -0.026850717142224312,
      0.00384318339638412,
      0.026150980964303017,
      0.08201993256807327,
      0.034169912338256836,
      0.047493308782577515,
      0.03332173824310303,
      0.09205000847578049,
      0.014818906784057617,
      -0.01370803639292717,
      -0.0038603355642408133,
      0.015220606699585915,
      -0.008091330528259277,
      -0.0010212946217507124,
      -0.0075463782995939255,
      0.001490762340836227,
      0.06282467395067215,
      -0.0054223500192165375,
      -0.061112139374017715,
      0.04920683056116104,
      -0.02047862485051155,
      -0.0017839769134297967,
      -0.04114902392029762,
      -0.046645645052194595,
      0.05840086191892624,
      -0.07694891840219498,
      0.00861458107829094,
      -0.0056381989270448685,
      -0.05388335511088371,
      0.037806056439876556,
      0.02517206035554409,
      0.10151255130767822,
      0.06450773775577545,
      0.015890473499894142,
      -0.03852064535021782,
      -0.061601221561431885,
      -0.057144079357385635,
      -0.051570773124694824,
      0.1338750272989273,
      -0.04143126681447029,
      0.03010389395058155,
      0.031410787254571915,
      -0.09811241179704666,
      0.03999752551317215,
      0.0068398513831198215,
      -0.043257713317871094,
      0.06009206175804138,
      -0.0037255026400089264,
      0.08861347287893295,
      -0.11507340520620346,
      -0.008085201494395733,
      0.0034835669212043285,
      -0.024330100044608116,
      -0.0065710037015378475,
      -0.09126902371644974,
      0.03697909414768219,
      -0.08778121322393417,
      -0.08437918871641159,
      -0.023357657715678215,
      0.02582559734582901,
      -0.01258845254778862,
      0.0014961339766159654,
      0.06421500444412231,
      0.07253309339284897,
      -0.027514750137925148,
      -0.07250166684389114,
      0.007411317899823189,
      0.056552987545728683,
      0.041551634669303894,
      -0.028603123500943184,
      0.07961522042751312,
      -0.08820334821939468,
      -0.0399344377219677,
      0.024780210107564926,
      -0.025748534128069878,
      0.04754289984703064,
      0.1277528703212738,
      0.041573889553546906,
      -0.03413224592804909,
      -0.1427077054977417,
      -0.07311637699604034,
      -0.008993956260383129,
      0.013154461048543453,
      -0.043782953172922134,
      0.04232339560985565,
      0.02321660704910755,
      0.010179636999964714,
      0.06819046288728714,
      0.035253725945949554,
      -1.9180333765689284e-05,
      -0.013013510033488274,
      0.01840067468583584,
      0.01726115681231022,
      -0.103111132979393,
      -0.02150271274149418,
      -0.06858674436807632,
      -0.03798710182309151,
      0.006746575236320496,
      -0.07774438709020615,
      0.02629433199763298,
      -0.03361574932932854,
      0.03955454006791115,
      -0.04094434529542923,
      -0.05671870708465576,
      0.002307665767148137,
      -0.06234067678451538,
      0.005176367238163948,
      0.035866670310497284,
      -0.023076221346855164,
      -0.12014343589544296,
      0.04046672210097313,
      0.11699474602937698,
      0.0158859696239233,
      -0.08269887417554855,
      0.04837818816304207,
      -0.0033590029925107956,
      -0.0181992556899786,
      -0.020183656364679337,
      0.08478403091430664,
      -0.04929036274552345,
      0.02933199517428875,
      -0.044382765889167786,
      0.019865693524479866,
      0.07405189424753189,
      -0.045561715960502625,
      0.07904516160488129,
      -0.02590135857462883,
      -0.022183993831276894,
      -0.013183954171836376,
      -0.06628163158893585,
      -0.01041427068412304,
      0.006195797584950924,
      0.06084544584155083,
      -0.010850409045815468,
      -0.030229222029447556,
      -0.008382624946534634,
      -0.07395601272583008,
      -0.045424025505781174,
      -0.004493107553571463,
      0.02795509248971939,
      -0.0425570122897625,
      0.018821382895112038,
      0.010946272872388363,
      -0.03522561863064766,
      0.031105849891901016,
      -0.006882806308567524,
      0.008562251925468445,
      0.07571135461330414,
      0.04154271259903908,
      -0.024571456015110016,
      0.0066897147335112095,
      -0.007541355676949024,
      -0.04496513679623604,
      -0.010707981884479523,
      0.006256568245589733,
      -0.04551435634493828,
      -0.015138112008571625,
      -0.016245437785983086,
      0.0004426016239449382,
      -0.014577525667846203,
      0.024287283420562744,
      0.04845035448670387,
      -0.015853319317102432,
      0.041018784046173096,
      0.004734668880701065,
      -0.006898632273077965,
      0.014594062231481075,
      0.06386730819940567,
      -0.023940855637192726,
      0.09536691009998322,
      -0.0035108549054712057,
      0.011947251856327057,
      0.0187281034886837,
      -0.002128591760993004,
      -0.0612335167825222,
      0.02272947132587433,
      0.08173207938671112,
      -0.04176356643438339,
      0.02160698175430298,
      0.07902321964502335,
      0.01676478609442711,
      -0.004641611594706774,
      -0.0429302416741848,
      0.0695866122841835,
      -0.019864870235323906,
      0.007165692746639252,
      -0.01882968842983246,
      0.006115530151873827,
      -0.031222017481923103,
      0.015113739296793938,
      0.03140527382493019,
      0.0019000740721821785,
      -0.04201353341341019,
      -0.04163265600800514,
      -0.008931510150432587,
      -0.00442859111353755,
      -0.02860720083117485,
      0.06430894136428833,
      -0.029433444142341614,
      -0.027804020792245865,
      0.07865478098392487,
      -0.017600415274500847,
      -0.04226500913500786,
      -0.012733534909784794,
      -0.043748486787080765,
      0.026232371106743813,
      0.04020458832383156,
      -0.06779048591852188,
      -0.012493074871599674,
      -0.09325043112039566,
      -0.0003832678194157779,
      0.01557445153594017,
      0.0337153822183609,
      0.04757240414619446,
      0.010663174092769623,
      -0.05378549546003342,
      0.00917508639395237,
      0.02583799883723259,
      0.003448386676609516,
      0.07109677046537399,
      -0.06013641133904457,
      0.032705921679735184,
      0.0033732468727976084,
      -0.04625484347343445,
      -0.05255696177482605,
      -0.019630813971161842,
      -0.03972584381699562,
      0.08163762092590332,
      0.013523420318961143,
      -0.009576549753546715,
      -0.02177250385284424,
      -0.01395261101424694,
      -0.05574500188231468,
      -0.019170774146914482,
      0.004498102702200413,
      0.0012567448429763317,
      0.08488315343856812,
      -0.012677705846726894,
      0.02556656301021576,
      -0.030462991446256638,
      0.08141221106052399,
      -0.027937667444348335,
      -0.021279865875840187,
      0.031659986823797226,
      0.013649080879986286,
      -0.02387314848601818,
      -0.008686668239533901,
      0.001384021365083754,
      -0.060339950025081635,
      -0.02457677572965622,
      -0.029865633696317673,
      0.01677652820944786,
      0.042752236127853394,
      0.007804214954376221,
      -0.01001299824565649,
      -0.07978292554616928,
      -0.04759315401315689,
      0.06746554374694824,
      0.07016806304454803,
      -0.00043335912050679326,
      0.032080743461847305,
      0.05199349671602249,
      0.06803678721189499,
      -0.05516309663653374,
      0.008885106071829796,
      0.07790768146514893,
      -0.05802272632718086,
      0.03205924481153488,
      -0.039234500378370285,
      -0.0015571207040920854,
      -0.11664897203445435,
      -0.08529536426067352,
      0.025916917249560356
    ],
    [
      -0.017312031239271164,
      -0.07927841693162918,
      0.01798316091299057,
      0.05346794053912163,
      -0.03160228580236435,
      -0.005091018509119749,
      -0.01382031012326479,
      0.025605127215385437,
      -0.004184093326330185,
      -0.014257002621889114,
      -0.042376015335321426,
      -0.0007653141510672867,
      -0.0065535991452634335,
      0.09783752262592316,
      0.016529418528079987,
      0.03965599834918976,
      0.047216739505529404,
      -0.05882471799850464,
      -0.018234094604849815,
      -0.016493896022439003,
      0.0009256872581318021,
      0.0023194707464426756,
      0.03355731815099716,
      -0.025810876861214638,
      0.031824685633182526,
      0.003676663851365447,
      -0.019834859296679497,
      -0.013148533180356026,
      0.027381671592593193,
      -0.049187321215867996,
      0.032550081610679626,
      0.020851897075772285,
      0.03284239023923874,
      0.018793202936649323,
      -0.004934480879455805,
      0.01963380165398121,
      -0.014643212780356407,
      -0.06335654854774475,
      -0.01707695610821247,
      0.0313008613884449,
      -0.04302504286170006,
      -0.06519395858049393,
      0.029868606477975845,
      0.05350182577967644,
      0.010584408417344093,
      0.013105767779052258,
      -0.06400257349014282,
      -0.018730252981185913,
      -0.05441918969154358,
      -0.04329589381814003,
      0.06116393581032753,
      0.021370084956288338,
      0.023799436166882515,
      -0.060361459851264954,
      0.057462215423583984,
      0.05434533953666687,
      -0.01599007286131382,
      -0.002543898532167077,
      -0.0006449947832152247,
      -0.028841407969594002,
      0.11293873935937881,
      -0.010676140896975994,
      -0.01902446709573269,
      0.0609981007874012,
      -0.008426644839346409,
      -0.07026802748441696,
      -0.012367283925414085,
      -0.00022495475423056632,
      0.04944325610995293,
      0.09589962661266327,
      -0.0377824604511261,
      0.04503960162401199,
      0.06859594583511353,
      -0.052498579025268555,
      -0.08377956598997116,
      -0.06464485824108124,
      0.04778055474162102,
      0.003475731937214732,
      0.028137318789958954,
      0.0011825188994407654,
      -0.016927897930145264,
      -0.018983840942382812,
      -0.05070928484201431,
      -0.019910650327801704,
      0.011619720607995987,
      -0.08415113389492035,
      0.06648283451795578,
      0.0014515049988403916,
      -0.04057667776942253,
      0.037193432450294495,
      -0.04577920585870743,
      -0.08180270344018936,
      0.038877181708812714,
      0.022224344313144684,
      0.005199754610657692,
      0.02951187640428543,
      0.05372712016105652,
      0.033964209258556366,
      0.007009020075201988,
      0.008112533949315548,
      -0.027713006362318993,
      -0.049907419830560684,
      0.011044750921428204,
      0.050739187747240067,
      0.012072482146322727,
      -0.0442151203751564,
      -0.015221024863421917,
      0.10912204533815384,
      0.03158412128686905,
      -0.0667325034737587,
      -0.054671142250299454,
      0.03863207995891571,
      0.018799060955643654,
      0.03697513788938522,
      0.024157393723726273,
      -0.04358891025185585,
      -0.029094332829117775,
      -0.06611200422048569,
      -0.011128160171210766,
      0.019381705671548843,
      0.018659036606550217,
      0.05651666969060898,
      -0.028985269367694855,
      0.04450097307562828,
      0.05438666790723801,
      0.00746317021548748,
      -0.03852572292089462,
      -0.01625949889421463,
      -0.00030373240588232875,
      0.03822452202439308,
      -0.06314785778522491,
      0.06607571989297867,
      -0.0009228896233253181,
      0.04922114685177803,
      0.013043936342000961,
      0.05186117812991142,
      0.019235728308558464,
      0.029411716386675835,
      -0.06785620748996735,
      0.04066762328147888,
      -0.03091507963836193,
      -0.01472789328545332,
      0.019063668325543404,
      -0.04345858097076416,
      -0.008826651610434055,
      0.03334721177816391,
      0.016092248260974884,
      -0.0746753141283989,
      0.012084792368113995,
      -0.022399403154850006,
      0.04037178307771683,
      0.03533250093460083,
      -0.02688518911600113,
      0.04906647652387619,
      -0.06148713454604149,
      -0.03922268748283386,
      0.053936224430799484,
      -0.023675447329878807,
      -0.06022080034017563,
      0.04653221368789673,
      0.02300163358449936,
      -0.08775726705789566,
      -0.020892592146992683,
      0.03407249227166176,
      0.03901683911681175,
      -0.011744850315153599,
      -0.07858347147703171,
      0.02209441550076008,
      0.03488413989543915,
      -0.053412970155477524,
      0.05610071122646332,
      -0.02878882922232151,
      -0.009987644851207733,
      0.011354992166161537,
      -0.0002155928814318031,
      0.005120533984154463,
      0.02538089081645012,
      -0.021253110840916634,
      -0.04484099894762039,
      0.006214688997715712,
      -0.0190693698823452,
      -0.025827590376138687,
      0.07054827362298965,
      -0.04103296250104904,
      0.027181081473827362,
      -0.03999613970518112,
      -0.07408715039491653,
      0.03779328987002373,
      -0.05842472240328789,
      0.020249802619218826,
      0.03375088423490524,
      0.05608813837170601,
      0.06947904825210571,
      0.030687734484672546,
      0.0014216882409527898,
      -0.050273504108190536,
      -0.022096451371908188,
      -0.08084841072559357,
      -0.06193653121590614,
      0.05481235682964325,
      0.022450631484389305,
      -0.059977464377880096,
      0.0037791382055729628,
      -0.008199036121368408,
      0.05172831937670708,
      -0.021406149491667747,
      -0.05150873586535454,
      -0.0021742372773587704,
      -0.11282237619161606,
      0.0403490774333477,
      -0.020876141265034676,
      0.012627284042537212,
      -0.018490811809897423,
      0.08783207833766937,
      0.08319222182035446,
      -0.0065930793061852455,
      -0.058828677982091904,
      -0.030123908072710037,
      0.0142957279458642,
      0.028551409021019936,
      -0.06341370195150375,
      -0.0007298780838027596,
      -0.009444867260754108,
      -0.00943116657435894,
      -0.027438988909125328,
      0.03526496887207031,
      -0.005571235902607441,
      0.0643945261836052,
      -0.004615266341716051,
      0.005875777453184128,
      -0.07360237091779709,
      0.004127411171793938,
      -0.0014712801203131676,
      0.03411652520298958,
      -0.06295269727706909,
      0.03293309360742569,
      -0.06480549275875092,
      -0.05011042207479477,
      0.01732959784567356,
      0.016590772196650505,
      0.09925509244203568,
      -0.05038589984178543,
      -0.012009251862764359,
      -0.004597914405167103,
      0.012297136709094048,
      0.03565355762839317,
      0.03803098201751709,
      -0.02241484820842743,
      -0.036880578845739365,
      -0.047870486974716187,
      -0.01717325672507286,
      -0.010198564268648624,
      -0.03708883374929428,
      0.09760259836912155,
      -0.07849059253931046,
      -0.017303932458162308,
      0.003489329479634762,
      -0.010926470160484314,
      0.02411920204758644,
      0.013742485083639622,
      0.03468279168009758,
      -0.04450578987598419,
      0.0381854772567749,
      -0.03632321208715439,
      -0.031243257224559784,
      0.006467374041676521,
      0.03875269368290901,
      0.007488362956792116,
      0.04861651360988617,
      -0.08308149129152298,
      -0.02802623063325882,
      0.011376185342669487,
      0.062444064766168594,
      0.0033802588004618883,
      -0.01794716902077198,
      0.013128682039678097,
      0.0011638541473075747,
      0.06111530587077141,
      0.011316973716020584,
      -0.04743940010666847,
      0.0692027136683464,
      -0.05868059769272804,
      0.025211136788129807,
      -0.04023999720811844,
      0.01625629886984825,
      -0.036468520760536194,
      0.026991520076990128,
      0.014496155083179474,
      -0.024720612913370132,
      -0.058217331767082214,
      0.029104264453053474,
      0.023310687392950058,
      -0.009616407565772533,
      0.04267141595482826,
      -0.0359845869243145,
      0.062269557267427444,
      -0.0300986859947443,
      -0.02570607326924801,
      -0.01580255664885044,
      -0.015086648054420948,
      -0.00550361443310976,
      -0.011532952077686787,
      0.03146921470761299,
      -0.013372885063290596,
      0.05945475026965141,
      -0.01625390164554119,
      0.03141557797789574,
      -0.011190528981387615,
      0.08275151252746582,
      0.009119950234889984,
      0.053585030138492584,
      0.041706785559654236,
      -0.033814407885074615,
      -0.06411293894052505,
      -0.06007635220885277,
      -0.01178457960486412,
      -0.04253867641091347,
      0.021694842725992203,
      0.02858789451420307,
      -0.0521501861512661,
      0.008069949224591255,
      0.04697345197200775,
      0.07001548260450363,
      0.0142440777271986,
      0.02499343827366829,
      0.03370578587055206,
      -0.09990905225276947,
      -0.046376779675483704,
      0.048920828849077225,
      0.020580576732754707,
      0.03211823105812073,
      0.03508424758911133,
      0.0889429897069931,
      0.02775675430893898,
      -0.03906465694308281,
      0.022572563961148262,
      -0.013451182283461094,
      -0.022086868062615395,
      0.009650361724197865,
      0.05079866200685501,
      0.05128508433699608,
      0.04766085743904114,
      -0.03196469321846962,
      0.07429024577140808,
      0.031136751174926758,
      -0.08451706916093826,
      0.007382267154753208,
      -0.010022458620369434,
      -0.06791432946920395,
      -0.004316841717809439,
      -0.07068590074777603,
      -0.024139290675520897,
      -0.007636942435055971,
      0.07488156110048294,
      -0.021059880033135414,
      0.029008187353610992,
      0.022113800048828125,
      -0.030901407822966576,
      0.014945315197110176,
      0.03988711163401604,
      -0.026917800307273865,
      0.03554290905594826,
      -0.01561469491571188,
      -0.008893005549907684,
      0.01019433606415987,
      -0.03389187157154083,
      0.05626705661416054,
      -0.10796121507883072,
      -0.022171949967741966,
      -0.09448003023862839,
      -0.0722675770521164,
      -0.08042499423027039,
      0.014624124392867088,
      0.022947845980525017,
      -0.0018271144945174456,
      0.07935746759176254,
      -0.030977362766861916,
      -0.035201333463191986,
      0.03261373192071915,
      -0.1017964631319046,
      -0.03946950286626816,
      0.016167305409908295,
      0.049951210618019104,
      0.009598794393241405,
      0.003720336128026247,
      -0.030644221231341362,
      0.09209248423576355,
      -0.012299091555178165,
      -0.005320712458342314,
      0.022569715976715088,
      0.022972065955400467,
      0.010104170069098473,
      0.039127644151449203,
      -0.09215474128723145,
      0.007959079928696156,
      0.05093292146921158,
      -0.06933090835809708,
      -0.03562569618225098,
      0.06003355234861374,
      0.00935410987585783,
      -0.0012760077370330691,
      -0.06210150569677353,
      0.07589901238679886,
      -0.07538650929927826,
      0.05330780893564224,
      0.04719255492091179,
      0.08084256947040558,
      0.017383920028805733,
      0.02655910700559616,
      -0.0335138738155365,
      -0.015586844645440578,
      -0.002564802998676896,
      0.0496959425508976,
      -0.00261322152800858,
      -0.0675557404756546,
      -0.024900101125240326,
      0.009113061241805553,
      0.049524784088134766,
      0.044332846999168396,
      -0.05349665880203247,
      0.03140394017100334,
      0.0158701092004776,
      0.061407092958688736,
      0.0013894715812057257,
      -0.05090547353029251,
      0.0006050692754797637,
      -0.05082939937710762,
      -0.01975747011601925,
      -0.02161841280758381,
      0.005383959040045738,
      -0.00986530538648367,
      0.013313913717865944,
      -0.012957900762557983,
      0.04940436780452728,
      -0.0675450935959816,
      -0.010262034833431244,
      0.06387102603912354,
      0.02689177356660366,
      0.01654437556862831,
      0.005709114018827677,
      0.04331611469388008,
      0.009279024787247181,
      0.060599613934755325,
      -0.01965946890413761,
      0.04845492169260979,
      -0.009216368198394775,
      -0.009038866497576237,
      -0.005937813315540552,
      -0.07167267054319382,
      -0.03629167005419731,
      0.010682874359190464,
      -0.0022206169087439775,
      -0.007694890256971121,
      0.052122145891189575,
      0.019329028204083443,
      0.010232634842395782,
      -0.021608857437968254,
      -0.04748225584626198,
      -0.027222251519560814,
      -0.08208473771810532,
      -0.07691411674022675,
      -0.002977089025080204,
      0.0973939374089241,
      -0.041934702545404434,
      0.005847812630236149,
      -0.1047590896487236,
      0.06649839878082275,
      0.0182137880474329,
      -0.04421648383140564,
      0.02976090833544731,
      -0.011425739154219627,
      -0.06040339544415474,
      0.12738312780857086,
      0.10058917105197906,
      0.06817444413900375,
      -0.0380500853061676,
      0.07157392799854279,
      0.08041112869977951,
      0.010561571456491947,
      -0.04429396614432335,
      0.020683424547314644,
      0.06166272237896919,
      0.00737361516803503,
      0.03305324539542198,
      0.012203393504023552,
      0.05109983682632446,
      0.03596020117402077,
      -0.02282956801354885,
      -0.060119420289993286,
      -0.0405370257794857,
      0.04790647327899933,
      -0.004510277882218361,
      -0.045263469219207764,
      0.004017488565295935,
      0.0015207347460091114,
      -0.04328786954283714,
      0.06175075098872185,
      0.04399441182613373,
      -0.06585174798965454,
      -0.03630111739039421,
      0.07481049746274948,
      -0.001398758846335113,
      -0.03185887634754181
    ],
    [
      0.016211653128266335,
      -0.05858926475048065,
      0.025808095932006836,
      -0.013602937571704388,
      -0.022719915956258774,
      -0.05658900365233421,
      -0.036016348749399185,
      -0.0042788186110556126,
      0.02395050600171089,
      -0.021664589643478394,
      0.0794605016708374,
      -0.08210649341344833,
      -0.02125134877860546,
      -0.014595464803278446,
      -0.008501563221216202,
      -0.0006526772049255669,
      -0.0011244507040828466,
      0.021519102156162262,
      -0.020153608173131943,
      0.061127349734306335,
      0.066622294485569,
      -0.004429396241903305,
      -0.058640167117118835,
      0.013716702349483967,
      0.022548668086528778,
      0.015677569434046745,
      -0.0029063676483929157,
      -0.0535985566675663,
      -0.012405882589519024,
      0.05455984175205231,
      -0.04438723251223564,
      0.034192848950624466,
      0.016401557251811028,
      -0.08549106866121292,
      0.06408683955669403,
      0.018122414126992226,
      0.015053956769406796,
      -0.03171959146857262,
      -0.07390198856592178,
      0.06014493480324745,
      -0.024535002186894417,
      -0.021926283836364746,
      0.09638988226652145,
      -0.005295303650200367,
      0.08304651081562042,
      0.051697395741939545,
      -0.05059156194329262,
      -0.0006400261772796512,
      -0.020886359736323357,
      0.017417285591363907,
      0.04704665392637253,
      0.08484593778848648,
      0.009179098531603813,
      -0.05823010206222534,
      -0.03511791676282883,
      -0.06846188008785248,
      -0.03047693707048893,
      0.012636968865990639,
      -0.004951117560267448,
      -0.07769525051116943,
      0.009037788026034832,
      0.0008649063529446721,
      -0.03765438124537468,
      -0.041768528521060944,
      0.07369536906480789,
      0.03137083724141121,
      -0.011653691530227661,
      0.021894210949540138,
      -0.022428123280405998,
      0.02405102550983429,
      0.06944352388381958,
      0.03404243662953377,
      -0.04645957052707672,
      0.03848149627447128,
      0.033895354717969894,
      0.038874734193086624,
      0.024765044450759888,
      0.03418669104576111,
      -0.004085503984242678,
      0.023811686784029007,
      -0.06881611794233322,
      0.10325518995523453,
      -0.012456526048481464,
      0.0302263256162405,
      -0.02068154513835907,
      -0.1047108843922615,
      0.058167532086372375,
      0.04752253368496895,
      -0.05073622241616249,
      0.03672634810209274,
      0.0028995336033403873,
      0.02905697375535965,
      -0.008857816457748413,
      -0.0017989752814173698,
      -0.027321049943566322,
      -0.002734648995101452,
      0.02289765141904354,
      0.052999723702669144,
      0.03439570218324661,
      0.007940747775137424,
      -0.022620413452386856,
      0.006877480540424585,
      -0.012041367590427399,
      0.010856883600354195,
      -0.0403347946703434,
      -0.004697993863373995,
      0.0012791301123797894,
      -0.012251029722392559,
      0.0016769951907917857,
      0.0031516500748693943,
      0.029431812465190887,
      0.0421188659965992,
      -0.04023337364196777,
      -0.08341526240110397,
      0.012929259799420834,
      -0.015275922603905201,
      0.04267602786421776,
      -0.033221859484910965,
      0.0318191796541214,
      -0.005293695256114006,
      -0.007589386776089668,
      -0.11778730899095535,
      -0.01926393434405327,
      -0.06403090059757233,
      -0.040358249098062515,
      -0.05603281036019325,
      0.016673849895596504,
      -0.06290531158447266,
      -0.047366440296173096,
      0.0275576114654541,
      0.020166289061307907,
      -0.017430761829018593,
      -0.07085660099983215,
      -0.07705232501029968,
      -0.009896216914057732,
      -0.04577306658029556,
      0.05252103880047798,
      -0.1335468590259552,
      0.0246269591152668,
      -0.02742491289973259,
      0.04986993968486786,
      -0.05879085510969162,
      0.02595522440969944,
      0.0013639441458508372,
      0.05485173687338829,
      -0.041538383811712265,
      -0.0002600770676508546,
      -0.04750008508563042,
      -0.021432554349303246,
      -0.045442257076501846,
      0.010268641635775566,
      0.06106487289071083,
      0.0510622039437294,
      0.06494904309511185,
      -0.007440527901053429,
      -0.03968048468232155,
      0.030585594475269318,
      -7.791454845573753e-05,
      0.03746909275650978,
      -0.04223628342151642,
      -0.008741036988794804,
      -0.015251856297254562,
      0.004558066837489605,
      -0.08511918783187866,
      0.018155470490455627,
      0.035193368792533875,
      0.0376511849462986,
      -0.026403650641441345,
      0.06374841928482056,
      -0.0357799232006073,
      -0.03134491667151451,
      -0.043111566454172134,
      -0.018549306318163872,
      -0.002349926857277751,
      -0.06328385323286057,
      -0.04924047365784645,
      0.007946791127324104,
      0.022553609684109688,
      0.01480427198112011,
      -0.014332094229757786,
      0.0011477208463475108,
      0.12093406170606613,
      -0.010705383494496346,
      0.027289172634482384,
      0.0037965027149766684,
      0.04504821076989174,
      -0.023856038227677345,
      0.006520056631416082,
      -0.029082253575325012,
      -0.013193425722420216,
      0.07379060238599777,
      0.05361861735582352,
      -0.053945984691381454,
      -0.001893340959213674,
      -0.06113573536276817,
      -0.01884969137609005,
      0.012134965509176254,
      -0.027403641492128372,
      -0.02305630035698414,
      -0.006746063940227032,
      0.06398160010576248,
      -0.005125822499394417,
      -0.08022857457399368,
      -0.029289549216628075,
      0.052717432379722595,
      -0.004407848697155714,
      -0.025806160643696785,
      0.04427527263760567,
      0.1030769795179367,
      -0.061170000582933426,
      0.032790325582027435,
      -0.08230249583721161,
      -0.06901900470256805,
      -0.04798019677400589,
      0.032828882336616516,
      0.07810810208320618,
      -0.0697457566857338,
      0.02450278028845787,
      0.08404678851366043,
      0.03351881727576256,
      0.0256009791046381,
      -0.0033856357913464308,
      -0.03399495780467987,
      -0.0015433221124112606,
      0.0025203528348356485,
      -0.002747527789324522,
      -0.05096949636936188,
      0.022695282474160194,
      -0.010642540641129017,
      0.028063207864761353,
      -0.06229613721370697,
      -0.025299275293946266,
      0.04070619121193886,
      0.044929903000593185,
      0.012168885208666325,
      -0.0407244935631752,
      0.047164708375930786,
      0.02252279780805111,
      -0.06539112329483032,
      0.020380910485982895,
      -0.02503943257033825,
      0.031020954251289368,
      -0.04356077313423157,
      -0.0034442420583218336,
      0.009752086363732815,
      0.0007350480300374329,
      0.05162952095270157,
      0.06718158721923828,
      -0.01932494156062603,
      0.027665942907333374,
      0.05403120443224907,
      -0.0056237829849123955,
      -0.02536824531853199,
      0.018236862495541573,
      0.002987935207784176,
      0.06290294975042343,
      -0.051488008350133896,
      -0.021903689950704575,
      -0.03676057606935501,
      -0.070537269115448,
      0.012944950722157955,
      -0.005655606742948294,
      -0.005873786751180887,
      0.03955736756324768,
      -0.053461454808712006,
      0.012819833122193813,
      -0.04100683704018593,
      0.07570693641901016,
      0.03850840404629707,
      -0.09567001461982727,
      0.06423967331647873,
      0.054922666400671005,
      0.03725843504071236,
      -0.0018378266831859946,
      0.019145838916301727,
      -0.02897891029715538,
      0.1497466117143631,
      -0.009625756181776524,
      -0.04206681624054909,
      0.026911072432994843,
      0.16740305721759796,
      0.08407345414161682,
      0.012818989343941212,
      0.07326352596282959,
      0.011901648715138435,
      0.05307328701019287,
      0.01981756091117859,
      -0.046808090060949326,
      0.043645985424518585,
      -0.004058708902448416,
      -0.0036265586968511343,
      0.015995807945728302,
      0.03731793537735939,
      0.0577780157327652,
      0.04047072306275368,
      0.07252367585897446,
      0.036429982632398605,
      -0.0022228893358260393,
      0.034632131457328796,
      -0.018468748778104782,
      -0.034879740327596664,
      0.06206166371703148,
      0.03759555146098137,
      -0.07150837779045105,
      0.04178236424922943,
      0.007076520938426256,
      -0.022445395588874817,
      -0.0429784394800663,
      0.007708386518061161,
      -0.001698036096058786,
      0.05159713700413704,
      0.012092097662389278,
      -0.044664956629276276,
      0.06378726661205292,
      0.05306246504187584,
      0.11780982464551926,
      -0.03272419050335884,
      -0.015324040316045284,
      0.05525048449635506,
      -0.01884683221578598,
      -0.01747194677591324,
      0.01980050466954708,
      -0.04239170253276825,
      -0.11589900404214859,
      -0.01910475827753544,
      -0.016813404858112335,
      -0.020909812301397324,
      0.009447561576962471,
      -0.03521750867366791,
      0.00407450832426548,
      -0.03524027764797211,
      0.049332212656736374,
      -0.023653971031308174,
      0.030754454433918,
      -0.05470447614789009,
      0.006866940297186375,
      -0.05748351290822029,
      0.05762942135334015,
      -0.02878112904727459,
      -0.0023082871921360493,
      -0.10847266763448715,
      -0.025046290829777718,
      -0.0318978913128376,
      -0.001975270453840494,
      0.03356342017650604,
      -0.012198494747281075,
      0.00992645788937807,
      -0.04265006631612778,
      -0.01909443363547325,
      -0.07996068894863129,
      0.07358918339014053,
      -0.014013764448463917,
      -0.032955560833215714,
      -0.002940179081633687,
      -0.026964133605360985,
      -0.018826594576239586,
      0.014732040464878082,
      -0.1144566461443901,
      -0.06379804760217667,
      -0.055603381246328354,
      0.0278666689991951,
      -0.026005495339632034,
      0.04044302552938461,
      -0.03430819883942604,
      -0.001584551646374166,
      -0.055486515164375305,
      0.01689741387963295,
      0.06698819994926453,
      0.022076738998293877,
      -0.007489821873605251,
      0.016147566959261894,
      -0.027365706861019135,
      0.06782896816730499,
      -0.035625942051410675,
      0.08172222226858139,
      -0.060098759829998016,
      0.013190850615501404,
      0.02464612014591694,
      0.0019349156646057963,
      0.00318205077201128,
      -0.1255781203508377,
      0.024779366329312325,
      0.03371750935912132,
      -0.05871081352233887,
      0.08037933707237244,
      -0.033126067370176315,
      -0.01362780760973692,
      0.08249939233064651,
      0.05250519514083862,
      -0.018855812028050423,
      0.00808403268456459,
      0.03080640733242035,
      0.08136242628097534,
      0.0006201780634000897,
      0.0717330202460289,
      -0.03583814576268196,
      0.00958758033812046,
      0.0032945536077022552,
      -0.029727095738053322,
      0.054989274591207504,
      -0.0283469520509243,
      -0.010404360480606556,
      0.07046397775411606,
      -0.06798314303159714,
      -0.07359214872121811,
      -0.029268650338053703,
      -0.0029904181137681007,
      -0.08695120364427567,
      -0.03455764055252075,
      0.016940096393227577,
      -0.01309460774064064,
      -0.05860523134469986,
      0.05050280690193176,
      -0.01777806133031845,
      0.033447686582803726,
      0.017094304785132408,
      0.02451133169233799,
      0.058408472687006,
      0.005090526305139065,
      0.026735691353678703,
      0.03496873751282692,
      0.04896382614970207,
      0.010578548535704613,
      0.025589115917682648,
      -0.04563796520233154,
      -0.010238148272037506,
      -0.010252059437334538,
      0.004060315899550915,
      0.033657506108284,
      0.009820753708481789,
      -0.031196191906929016,
      0.017082421109080315,
      -0.018684713169932365,
      -0.0542922243475914,
      -0.03748450055718422,
      -0.033716894686222076,
      0.009381737560033798,
      -0.017527036368846893,
      0.010003488510847092,
      0.019273869693279266,
      0.021479766815900803,
      0.19165882468223572,
      -0.05054401606321335,
      0.026676036417484283,
      0.11464954167604446,
      0.03679908812046051,
      0.07806163281202316,
      0.10611118376255035,
      0.02144613116979599,
      0.03750170022249222,
      -0.03170539066195488,
      -0.010425112210214138,
      0.08579326421022415,
      -0.03288324549794197,
      -0.005263300146907568,
      -0.03583884239196777,
      0.006380206439644098,
      0.03180938959121704,
      -0.002805541269481182,
      -0.0022484713699668646,
      -0.026558740064501762,
      0.02830437570810318,
      0.010877280496060848,
      0.07436387240886688,
      -0.02587185986340046,
      0.015570191666483879,
      0.10202111303806305,
      0.0014028913574293256,
      0.02087666094303131,
      -0.042087048292160034,
      -0.016308199614286423,
      -0.002010080963373184,
      0.06385762244462967,
      -0.12706230580806732,
      0.014320345595479012,
      -0.033138565719127655,
      0.02398812770843506,
      0.06854597479104996,
      0.010512453503906727,
      -0.030620550736784935,
      -0.06917024403810501,
      0.0006209044368006289,
      -0.011044815182685852,
      -0.03005777671933174,
      -0.045134276151657104,
      0.013584834523499012,
      0.02522478811442852,
      0.036459941416978836,
      -0.05935604125261307,
      0.000973003450781107,
      0.004163334611803293,
      0.01620941236615181,
      -0.015751291066408157,
      -0.05010778084397316,
      0.009518771432340145,
      -0.08670222759246826,
      0.013487613759934902,
      0.019788343459367752,
      -0.01248898170888424,
      -0.008202200755476952,
      -0.02563515678048134,
      0.07864581048488617,
      -0.008395311422646046
    ],
    [
      -0.006584884598851204,
      0.06591184437274933,
      0.03282523155212402,
      0.04666823521256447,
      -0.045090604573488235,
      -0.04017415642738342,
      0.040335752069950104,
      0.03852085769176483,
      0.016609294340014458,
      0.0815911516547203,
      -0.033048540353775024,
      0.10500375181436539,
      0.03319608047604561,
      0.06394246220588684,
      -0.03197665140032768,
      -0.06680388748645782,
      -0.06472747027873993,
      0.034417834132909775,
      0.04379013925790787,
      0.0679040402173996,
      -0.03560727462172508,
      0.011865193955600262,
      0.06720798462629318,
      -0.01700763776898384,
      0.0889926478266716,
      -0.06915627419948578,
      -0.014559876173734665,
      0.001271650311537087,
      -0.1140371710062027,
      -0.014260034076869488,
      0.02947615273296833,
      -0.09802602976560593,
      0.02458330988883972,
      -5.443839108920656e-05,
      0.001433303114026785,
      0.04243827611207962,
      0.017655067145824432,
      0.060282815247774124,
      -0.04575439170002937,
      -0.10692937672138214,
      -0.02763522043824196,
      0.014208965934813023,
      -0.004757873713970184,
      -0.060103822499513626,
      0.002792887156829238,
      0.008507604710757732,
      -0.0798368975520134,
      0.02833346650004387,
      0.0055585880763828754,
      0.034799348562955856,
      0.0382215790450573,
      0.04149464890360832,
      0.01029609888792038,
      0.06397812068462372,
      -0.05787216126918793,
      -0.01721508614718914,
      -0.04356008023023605,
      -0.010790478438138962,
      0.08991848677396774,
      0.09618885815143585,
      -0.02387119084596634,
      -0.04326549172401428,
      0.01785125397145748,
      -0.018852755427360535,
      0.03248212859034538,
      0.038856495171785355,
      0.08985176682472229,
      -0.02987496927380562,
      -0.03868267685174942,
      -0.01439986564218998,
      0.07749299705028534,
      0.051102813333272934,
      -0.020903848111629486,
      -0.030610399320721626,
      -0.07508181035518646,
      0.0022401479072868824,
      -0.03690706565976143,
      -0.05138574168086052,
      0.0004541108210105449,
      -0.05303280055522919,
      0.056542955338954926,
      -0.011826190166175365,
      -0.003737349295988679,
      -0.06084877625107765,
      -0.06601811945438385,
      -0.021627657115459442,
      0.042894843965768814,
      -0.03879125043749809,
      0.043207019567489624,
      0.00518133956938982,
      0.04070688784122467,
      0.052651308476924896,
      0.07851505279541016,
      -0.045104026794433594,
      -0.00556386960670352,
      -0.06501155346632004,
      0.04736640304327011,
      -0.0677182674407959,
      0.0189681313931942,
      -0.0001784533087629825,
      0.0027591234538704157,
      0.0133237699046731,
      -0.04290086403489113,
      0.019052021205425262,
      0.03964698314666748,
      -0.05998692288994789,
      -0.04611921310424805,
      0.007467616815119982,
      0.01685338094830513,
      0.010637043043971062,
      -0.07581222057342529,
      -0.035728175193071365,
      -0.06515217572450638,
      -0.02748241275548935,
      0.009646252728998661,
      0.020470431074500084,
      0.01277370098978281,
      0.0049757808446884155,
      0.024518495425581932,
      -0.11332038789987564,
      0.023381611332297325,
      0.0032607028260827065,
      0.027067285031080246,
      -0.021290848031640053,
      -0.020382381975650787,
      -0.011054354719817638,
      0.04730617254972458,
      -0.02386472374200821,
      -0.022380812093615532,
      0.016831204295158386,
      0.015726104378700256,
      0.040281157940626144,
      0.011489547789096832,
      -0.009825094603002071,
      0.04589499905705452,
      0.06705418974161148,
      0.024868948385119438,
      -0.05234125629067421,
      0.007917790673673153,
      -0.0172642320394516,
      -0.040002498775720596,
      0.039421405643224716,
      -0.04653642326593399,
      -0.007246142718940973,
      -0.04897823929786682,
      -0.02878556028008461,
      0.03708302602171898,
      -0.02593296766281128,
      0.06205037236213684,
      0.01935875415802002,
      -0.011899416334927082,
      -0.04404842481017113,
      0.022624395787715912,
      0.012907753698527813,
      -0.10376318544149399,
      -0.026482557877898216,
      0.02882225811481476,
      -0.036163829267024994,
      -0.06136297062039375,
      0.05886239558458328,
      0.08849603682756424,
      0.008776389062404633,
      0.0037491549737751484,
      -0.038766976445913315,
      -0.04053722694516182,
      0.07803639769554138,
      -0.010135934688150883,
      -0.0026821373030543327,
      0.01612023077905178,
      -0.01863297075033188,
      0.04683472961187363,
      0.008426148444414139,
      0.027174822986125946,
      0.014062643051147461,
      0.03557246923446655,
      0.02606501430273056,
      -0.01589265465736389,
      0.009362351149320602,
      0.023369628936052322,
      0.019412675872445107,
      0.057342302054166794,
      0.039911143481731415,
      -0.07449280470609665,
      -0.04808064177632332,
      0.08101675659418106,
      0.010642414912581444,
      0.026027051731944084,
      -0.047971926629543304,
      -0.06153101846575737,
      -0.06394226849079132,
      0.12688583135604858,
      -0.025246962904930115,
      0.0890820249915123,
      0.036354389041662216,
      0.03909240663051605,
      -0.053216107189655304,
      0.00320015219040215,
      -0.036147281527519226,
      -0.006966307293623686,
      -0.05076027289032936,
      0.013783829286694527,
      0.025957243517041206,
      0.0813480094075203,
      0.057441506534814835,
      -0.008874018676578999,
      -0.023691300302743912,
      0.032913342118263245,
      0.04059954732656479,
      -0.009441934525966644,
      -0.06140720471739769,
      -0.08481065183877945,
      0.0030524644535034895,
      -0.04730483889579773,
      0.06790953129529953,
      0.032662808895111084,
      -0.017723016440868378,
      -0.02989819459617138,
      -0.05862443149089813,
      0.006439941935241222,
      0.010789147578179836,
      0.06550091505050659,
      0.042737074196338654,
      0.07421930134296417,
      0.01100369356572628,
      0.022928671911358833,
      -0.040467165410518646,
      -0.017240231856703758,
      0.030923137441277504,
      -0.0010888228425756097,
      -0.05145861580967903,
      0.013595662079751492,
      -0.0595858097076416,
      0.016872085630893707,
      -0.0015172554412856698,
      0.04784683510661125,
      0.04287203028798103,
      0.05464325472712517,
      0.031039351597428322,
      -0.002434324938803911,
      0.02084730751812458,
      0.039805710315704346,
      0.007530013099312782,
      -0.010431009344756603,
      -0.07296176999807358,
      0.0013442019699141383,
      0.057634010910987854,
      0.028451474383473396,
      0.020443614572286606,
      0.024455081671476364,
      0.015292773023247719,
      -0.04663515463471413,
      -0.07690422981977463,
      -0.0024997389409691095,
      -0.034252557903528214,
      -0.0464683473110199,
      -0.028433268889784813,
      -0.029004355892539024,
      0.08955492824316025,
      0.058524519205093384,
      0.0378396175801754,
      0.03020833432674408,
      0.029945330694317818,
      0.0036449215840548277,
      -0.024559779092669487,
      0.04854647442698479,
      -0.06491885334253311,
      0.030366497114300728,
      0.009361976757645607,
      0.025519292801618576,
      -0.04706825315952301,
      -0.05964529886841774,
      -0.011248412542045116,
      0.01688653603196144,
      -0.11288774758577347,
      -0.004351821728050709,
      0.09484847635030746,
      -0.004745083395391703,
      0.04196040332317352,
      -0.02586190775036812,
      0.013414210639894009,
      -0.02898581326007843,
      0.0007287285407073796,
      -0.04075116291642189,
      -0.06557905673980713,
      -0.031429629772901535,
      0.03690806031227112,
      0.04268454760313034,
      -0.006466628517955542,
      -0.0034038443118333817,
      -0.04235924780368805,
      -0.06775284558534622,
      0.04881333187222481,
      0.04115956276655197,
      -0.054986827075481415,
      -0.011149937286973,
      0.01140438299626112,
      0.0059667290188372135,
      -0.011764018796384335,
      -0.09671559929847717,
      0.07942412048578262,
      -0.007745102979242802,
      -0.10654658824205399,
      0.05904291197657585,
      -0.05359487980604172,
      -0.030318960547447205,
      0.03819815814495087,
      -0.011624489910900593,
      -0.05167326703667641,
      0.060295723378658295,
      0.03352300450205803,
      -0.0625092014670372,
      -0.003061521565541625,
      0.07076624035835266,
      -0.03502399101853371,
      -0.04758696258068085,
      -0.01126516330987215,
      -0.013350574299693108,
      -0.08514528721570969,
      0.025049597024917603,
      0.04926450178027153,
      0.07203660160303116,
      0.01751507818698883,
      -0.027363721281290054,
      -0.02317584678530693,
      0.04770275950431824,
      -0.036411307752132416,
      0.0027574757114052773,
      0.044959891587495804,
      -0.03125559911131859,
      -0.07826773822307587,
      0.010498532094061375,
      -0.0034329646732658148,
      -0.02303989604115486,
      0.024451330304145813,
      0.025486184284090996,
      0.044465258717536926,
      0.005321362987160683,
      -0.012106946669518948,
      0.013034206815063953,
      0.012411629781126976,
      0.005857547745108604,
      -0.013637875206768513,
      0.07146480679512024,
      0.02308444306254387,
      -0.0085988137871027,
      -0.06883791834115982,
      0.08329182863235474,
      -0.025992536917328835,
      -0.047265566885471344,
      0.05392512306571007,
      -0.004206931218504906,
      -0.00990221556276083,
      0.017655208706855774,
      0.04242803901433945,
      -0.01888260245323181,
      -0.010886145755648613,
      -0.023792751133441925,
      -0.010417514480650425,
      0.013172503560781479,
      -0.03541664034128189,
      -0.0858616977930069,
      0.03198705613613129,
      0.022933727130293846,
      0.013267586007714272,
      0.03581948205828667,
      -0.029765089973807335,
      -0.0460970401763916,
      -0.050932466983795166,
      0.015049729496240616,
      0.042194217443466187,
      0.11781853437423706,
      -0.03942684456706047,
      0.02860371395945549,
      0.03817792609333992,
      0.03914942592382431,
      -0.013016073033213615,
      -0.02492966689169407,
      0.019622907042503357,
      0.015573419630527496,
      -0.02636781334877014,
      0.0038990392349660397,
      -0.0019451245898380876,
      0.025296522304415703,
      -0.043680958449840546,
      -0.05669141560792923,
      -0.10450927168130875,
      -0.05285966396331787,
      0.053061529994010925,
      -0.07425051927566528,
      0.05975741893053055,
      -0.0317622572183609,
      -0.042206257581710815,
      -0.002418621676042676,
      0.003264848841354251,
      -0.020389175042510033,
      0.04594612121582031,
      -0.037451714277267456,
      0.01767866499722004,
      0.012965627014636993,
      -0.05354608595371246,
      -0.0024552633985877037,
      -0.014959413558244705,
      0.011812698096036911,
      0.031643323600292206,
      0.012891811318695545,
      0.010574338026344776,
      0.01570744626224041,
      0.06075875833630562,
      0.011671444401144981,
      -0.034870609641075134,
      -0.032557692378759384,
      0.07869116216897964,
      0.02876090258359909,
      -0.07237599790096283,
      0.05248914286494255,
      -0.002858550986275077,
      0.06943987309932709,
      -0.07742929458618164,
      -0.014107292518019676,
      -0.021992379799485207,
      -0.018247108906507492,
      -0.010761997662484646,
      0.0005828829598613083,
      0.01960233598947525,
      0.06932626664638519,
      0.08566482365131378,
      -0.02806338109076023,
      0.00422996049746871,
      0.05331912264227867,
      0.0017277478473260999,
      -0.0018649848643690348,
      -0.02571786753833294,
      0.015376342460513115,
      0.005170690827071667,
      -0.01216809917241335,
      0.08232437074184418,
      0.011314982548356056,
      0.0403660349547863,
      0.08786936849355698,
      0.05605854466557503,
      0.022925706580281258,
      0.08693324029445648,
      -0.040838323533535004,
      -0.030356669798493385,
      0.017727969214320183,
      0.01825028285384178,
      -0.07925976812839508,
      0.01709275133907795,
      -0.05419798940420151,
      -0.034307077527046204,
      -0.019009239971637726,
      -0.01081648375838995,
      -0.04075075313448906,
      0.05518694594502449,
      0.016135837882757187,
      0.020242927595973015,
      -0.02441313862800598,
      0.0849718451499939,
      0.06947480142116547,
      0.017120014876127243,
      0.005697648972272873,
      0.05045019090175629,
      -0.044238150119781494,
      0.024085983633995056,
      0.0062042949721217155,
      -0.006981274578720331,
      -0.01716776192188263,
      0.048579417169094086,
      0.051451027393341064,
      -0.04305372014641762,
      0.0409076102077961,
      -0.023370638489723206,
      -0.03940940275788307,
      0.0014044713461771607,
      -0.008098447695374489,
      0.014814254827797413,
      0.04569173976778984,
      0.02455054596066475,
      -0.01963687501847744,
      -0.04147322475910187,
      0.017378490418195724,
      0.005028760060667992,
      0.022734258323907852,
      0.03682631254196167,
      0.01707480475306511,
      -0.0025085366796702147,
      0.03207625076174736,
      0.06983593851327896,
      -0.0026419339701533318,
      -0.08247879892587662,
      0.05215773731470108,
      0.1050090566277504,
      0.029761649668216705,
      -0.018969086930155754,
      0.006604891736060381,
      0.03328244760632515,
      0.009479321539402008,
      0.035216234624385834,
      0.020428212359547615,
      0.008745606057345867,
      -0.007747265510261059,
      -0.03171553090214729,
      -0.06420643627643585
    ],
    [
      -0.004631472285836935,
      -0.05230986699461937,
      -0.06827311962842941,
      0.048606157302856445,
      -0.012639120221138,
      -0.021270915865898132,
      -0.002021565567702055,
      -0.043746791779994965,
      0.015204344876110554,
      -0.046103741973638535,
      0.057753562927246094,
      -0.020303109660744667,
      -0.026118535548448563,
      0.0677637830376625,
      -0.02443842776119709,
      0.003260624362155795,
      0.0051172589883208275,
      0.005553914234042168,
      -0.04373793676495552,
      -0.014752297662198544,
      0.06057197228074074,
      0.07479820400476456,
      -0.035113733261823654,
      -0.0007606395520269871,
      -0.01502299029380083,
      -0.06679262220859528,
      -0.03824415057897568,
      -0.06072336062788963,
      -0.002149804262444377,
      0.02635589800775051,
      -0.012975665740668774,
      0.03802213445305824,
      -0.037031397223472595,
      0.09107639640569687,
      0.00018293569155503064,
      0.019115786999464035,
      -0.016885021701455116,
      0.0038080187514424324,
      0.03980332240462303,
      0.04772394523024559,
      -0.0600690096616745,
      -0.07263762503862381,
      0.022398535162210464,
      -0.015391473658382893,
      -0.027655791491270065,
      -0.007562565617263317,
      -0.041768066585063934,
      0.021692711859941483,
      -0.029275286942720413,
      -0.08668147027492523,
      -0.047860559076070786,
      0.07050468027591705,
      -0.03659706562757492,
      -0.02490801177918911,
      0.03148108348250389,
      0.035267069935798645,
      -0.05083976313471794,
      -0.08808759599924088,
      0.060390911996364594,
      -0.04919418692588806,
      -0.007039856631308794,
      -0.04875100031495094,
      0.062023501843214035,
      -0.03729717433452606,
      0.0283883735537529,
      0.015635531395673752,
      -0.012354286387562752,
      0.014258032664656639,
      -0.08504590392112732,
      -0.026051096618175507,
      -0.010307005606591702,
      -0.005659231450408697,
      0.013259539380669594,
      0.044407427310943604,
      0.022740134969353676,
      0.04262550175189972,
      -0.04047311842441559,
      0.013429711572825909,
      0.04928385838866234,
      0.13568635284900665,
      0.07208844274282455,
      0.06823816150426865,
      -0.053511228412389755,
      0.03312051296234131,
      0.061508890241384506,
      -0.022499138489365578,
      0.051696088165044785,
      0.07872270047664642,
      -0.04632600396871567,
      -0.048553742468357086,
      0.007185802329331636,
      0.011410009115934372,
      0.12157289683818817,
      0.007881267927587032,
      -0.07229265570640564,
      0.11314495652914047,
      0.014097086153924465,
      0.0027000578120350838,
      0.022152159363031387,
      -0.08841340243816376,
      -0.05247809737920761,
      0.031749263405799866,
      -0.03966696560382843,
      -0.041342031210660934,
      0.009731126949191093,
      0.022001393139362335,
      0.021008864045143127,
      -0.028286803513765335,
      -0.038269754499197006,
      0.0038351828698068857,
      -0.006663436535745859,
      0.04724985361099243,
      -0.007484257686883211,
      0.09684371203184128,
      0.0267289150506258,
      0.014185897074639797,
      -0.053233589977025986,
      0.0830000787973404,
      -0.10967173427343369,
      -0.022864995524287224,
      -0.028499165549874306,
      -0.02431981824338436,
      -0.019438477233052254,
      0.023527776822447777,
      0.02686055563390255,
      -0.022694598883390427,
      -0.011501625180244446,
      0.0698121041059494,
      -0.05202207714319229,
      0.04306217283010483,
      -0.01674952730536461,
      -0.06354068964719772,
      -0.06690143793821335,
      0.005770566873252392,
      0.008826231583952904,
      0.03322657197713852,
      -0.015506676398217678,
      -0.07701624929904938,
      -0.016888583078980446,
      -0.01133552473038435,
      -0.02960379049181938,
      0.1074458509683609,
      0.01270962506532669,
      -0.040689557790756226,
      0.09502235054969788,
      -0.014059338718652725,
      0.062420982867479324,
      -0.013822448439896107,
      0.0063027613796293736,
      0.014402230270206928,
      -0.04534127935767174,
      -0.011429172940552235,
      0.03741181641817093,
      -0.000354631309164688,
      -0.0457259826362133,
      -0.09303218126296997,
      -0.05977768823504448,
      0.04774320498108864,
      -0.06379958242177963,
      -0.0276541355997324,
      0.0335940457880497,
      -0.008794274181127548,
      0.002461301628500223,
      -0.0357084721326828,
      -0.017422514036297798,
      -0.07540495693683624,
      0.06413871049880981,
      -0.0011189128272235394,
      -0.05813060700893402,
      -0.031591128557920456,
      -0.01498627383261919,
      0.027738941833376884,
      0.03759271651506424,
      -0.011246738955378532,
      -0.0657711997628212,
      0.14010867476463318,
      0.0002923159918282181,
      -0.07249024510383606,
      -0.05068714916706085,
      0.007198624778538942,
      0.014752625487744808,
      0.11692457646131516,
      -0.028338491916656494,
      -0.023446038365364075,
      -0.023993659764528275,
      -0.05931242182850838,
      -0.16463400423526764,
      -0.01405099406838417,
      0.006195047404617071,
      -0.042275529354810715,
      0.0039720796048641205,
      -0.038156624883413315,
      0.0012160129845142365,
      0.047009825706481934,
      -0.010470223613083363,
      0.05184239521622658,
      -0.024321382865309715,
      0.0025761849246919155,
      0.008480840362608433,
      0.011776898056268692,
      0.006183242425322533,
      -0.03605394437909126,
      0.022693999111652374,
      -0.0631585344672203,
      -0.008951905183494091,
      0.00712819118052721,
      -0.012033305130898952,
      -0.029731284826993942,
      -0.009187987074255943,
      -0.0014869815204292536,
      0.016071537509560585,
      -0.027935609221458435,
      0.027143973857164383,
      0.013774842023849487,
      -0.007964754477143288,
      0.0004100331279914826,
      -0.06558581441640854,
      0.022944211959838867,
      -0.07755843549966812,
      0.00866294652223587,
      -0.0012977139558643103,
      0.05359635502099991,
      0.039021704345941544,
      -0.0021508238278329372,
      -0.06446253508329391,
      0.04785370081663132,
      0.01665583811700344,
      0.06736726313829422,
      0.04700532928109169,
      -0.03121946007013321,
      0.09627995640039444,
      -0.0396357886493206,
      0.036385368555784225,
      0.013773555867373943,
      -0.021970253437757492,
      0.06558959186077118,
      0.015150750987231731,
      -0.021372780203819275,
      0.03770200163125992,
      0.028552548959851265,
      -0.027048129588365555,
      0.04672478511929512,
      0.01434608455747366,
      0.04930898919701576,
      0.04832031950354576,
      0.047791291028261185,
      -0.04109044373035431,
      -0.03098617121577263,
      -0.008879616856575012,
      0.08417826145887375,
      0.03586842492222786,
      -0.08374520391225815,
      -0.021804627031087875,
      0.009791446849703789,
      -0.02784399315714836,
      -0.0235634483397007,
      -0.01452665962278843,
      0.032364681363105774,
      0.042050834745168686,
      0.01794034242630005,
      -0.021769506856799126,
      0.06800723820924759,
      -0.06207120418548584,
      0.011503412388265133,
      0.02734331600368023,
      0.0021515898406505585,
      -0.015056779608130455,
      -0.023115649819374084,
      0.08025678992271423,
      -0.00340666132979095,
      -0.04107256606221199,
      -0.016041934490203857,
      -0.10005701333284378,
      0.06180805712938309,
      -0.02952173352241516,
      0.0006122428458184004,
      -0.01870664767920971,
      0.002108132466673851,
      0.02279123291373253,
      0.07212463021278381,
      -0.03173148259520531,
      -0.06022822856903076,
      0.032045360654592514,
      -0.03862432762980461,
      0.023738466203212738,
      -0.04508453980088234,
      -0.053039710968732834,
      0.020198581740260124,
      0.009107813239097595,
      -0.01606590487062931,
      -0.05524727702140808,
      0.002657308941707015,
      -0.05171021446585655,
      0.06373277306556702,
      -0.046906109899282455,
      -0.02365449070930481,
      0.014195692725479603,
      -0.012675006873905659,
      0.034125637263059616,
      0.009824700653553009,
      0.003749123541638255,
      0.05762486532330513,
      -0.05311090499162674,
      0.0061971936374902725,
      0.09087742120027542,
      -0.012822277843952179,
      0.036860063672065735,
      0.06048193201422691,
      0.07279334217309952,
      0.003720684442669153,
      -0.017120029777288437,
      -0.02188594825565815,
      0.0373004674911499,
      0.058172453194856644,
      0.022847695276141167,
      -0.015534885227680206,
      0.0399458073079586,
      -0.027419954538345337,
      -0.07758675515651703,
      0.11225879192352295,
      0.046977605670690536,
      0.006306011695414782,
      -0.004258289933204651,
      -0.04724729061126709,
      0.011815776117146015,
      0.07980320602655411,
      -0.02551278844475746,
      -0.03555165231227875,
      0.056585777550935745,
      0.01805419661104679,
      0.04043794050812721,
      0.015396937727928162,
      -0.018727237358689308,
      -0.05060485005378723,
      -0.037196915596723557,
      0.04375424236059189,
      -0.014455991797149181,
      0.025214917957782745,
      0.007024796679615974,
      0.018884195014834404,
      -0.05722583830356598,
      0.010758036747574806,
      -0.06347128003835678,
      -0.04687241464853287,
      -0.012648027390241623,
      -0.09245368093252182,
      0.02281302958726883,
      -0.05521915480494499,
      0.004293389618396759,
      0.07049880176782608,
      0.06412392854690552,
      0.10097470879554749,
      0.0282751377671957,
      0.04310337081551552,
      0.009499532170593739,
      -0.036650896072387695,
      -0.016305794939398766,
      -0.03722658008337021,
      -0.012245775200426579,
      0.06088480353355408,
      0.010865891352295876,
      0.005561658646911383,
      -0.0006332196062430739,
      -0.035995859652757645,
      -0.005185412708669901,
      0.00015861084102652967,
      0.028066180646419525,
      0.037782665342092514,
      0.010448720306158066,
      0.03136907517910004,
      -0.015558913350105286,
      0.01053886953741312,
      -0.0184793658554554,
      0.03304338827729225,
      -0.02812105044722557,
      0.0415668822824955,
      0.008339166641235352,
      0.01179199106991291,
      -0.0338117852807045,
      -0.002484190044924617,
      0.039526671171188354,
      -0.008727257139980793,
      0.01978902332484722,
      -0.05466242507100105,
      -0.02471485733985901,
      -0.013150809332728386,
      -0.11755824089050293,
      -0.07639648765325546,
      -0.04246930032968521,
      0.019161362200975418,
      0.0029429984278976917,
      -0.0277705118060112,
      -0.007956281304359436,
      0.0615898035466671,
      -0.027209622785449028,
      -0.02413219027221203,
      -0.06197451427578926,
      0.04283669590950012,
      -0.0003805365995503962,
      -0.019710469990968704,
      0.03036859631538391,
      -0.0006521728355437517,
      -0.028295239433646202,
      0.08256690949201584,
      -0.01890355907380581,
      0.0426131933927536,
      0.05523459613323212,
      0.018807558342814445,
      -0.04345005005598068,
      -0.023340413346886635,
      0.025441378355026245,
      0.012452801689505577,
      0.006956144236028194,
      -0.03281817585229874,
      -0.04886234924197197,
      0.03878879174590111,
      -0.11469362676143646,
      0.03897269442677498,
      -0.08698496222496033,
      0.058213695883750916,
      -0.06429331004619598,
      0.01329288724809885,
      0.06928735971450806,
      0.050896212458610535,
      0.01054149679839611,
      0.0039613922126591206,
      -0.007787047419697046,
      0.018383173272013664,
      -0.044921234250068665,
      0.013849989511072636,
      0.11248176544904709,
      -0.012260559014976025,
      0.019723400473594666,
      0.020604416728019714,
      -0.04928962141275406,
      -0.038312871009111404,
      -0.014223809354007244,
      -0.06656602770090103,
      -0.012471645139157772,
      -0.06078949570655823,
      -0.02834899351000786,
      -0.039047688245773315,
      0.022290097549557686,
      -0.10794490575790405,
      0.03308049216866493,
      -0.0266877468675375,
      0.07564373314380646,
      0.10741441696882248,
      0.018240690231323242,
      -0.008020667359232903,
      -0.041463326662778854,
      -0.022292129695415497,
      0.01621145009994507,
      -0.04026087000966072,
      -0.03756919503211975,
      -0.026197711005806923,
      0.0012989351525902748,
      0.005323304329067469,
      -0.0027618377935141325,
      0.013961929827928543,
      -0.10065586864948273,
      -0.006672115530818701,
      0.012380823493003845,
      -0.008952423930168152,
      0.011185670271515846,
      -0.06548865884542465,
      -0.009088339284062386,
      0.03253071382641792,
      0.006212248001247644,
      -0.05946788936853409,
      -0.018257535994052887,
      0.0015206841053441167,
      -0.018517419695854187,
      0.018295390531420708,
      0.017024977132678032,
      0.07275683432817459,
      -0.026552647352218628,
      -0.0073272669687867165,
      -0.015548663213849068,
      0.07520706206560135,
      0.012681704014539719,
      -0.028961386531591415,
      0.019782360643148422,
      0.07904111593961716,
      0.007593817543238401,
      0.010329821147024632,
      0.08128712326288223,
      -0.04809325560927391,
      -0.035098619759082794,
      0.03563416004180908,
      -0.04144579544663429,
      -0.05257217586040497,
      -0.00932335015386343,
      0.06827841699123383,
      0.025815535336732864,
      0.014865481294691563,
      -0.04651937261223793,
      0.02041923999786377,
      0.01885426975786686,
      0.042486850172281265,
      0.04858079552650452,
      0.002504504518583417,
      -0.06078026071190834
    ],
    [
      0.03911370784044266,
      -0.05513603985309601,
      -0.047306180000305176,
      -0.006566816940903664,
      -0.05801989510655403,
      0.015235956758260727,
      0.052474942058324814,
      -0.08795100450515747,
      -0.004924602806568146,
      -0.02794632315635681,
      0.02532879076898098,
      0.03704064339399338,
      0.022317657247185707,
      0.023540206253528595,
      -0.019510751590132713,
      0.03360435739159584,
      -0.01245861779898405,
      0.03984663262963295,
      -0.0008666790090501308,
      -0.03879270330071449,
      -0.008273109793663025,
      -0.0008023083209991455,
      0.043778903782367706,
      -0.06357690691947937,
      -0.034007422626018524,
      0.07398092746734619,
      0.032933857291936874,
      -0.0198297668248415,
      -0.009363620541989803,
      0.06101932376623154,
      -0.012778077274560928,
      0.03326191380620003,
      -0.08322073519229889,
      0.1390281468629837,
      -0.03966290131211281,
      -0.04638439044356346,
      0.09126145392656326,
      0.11290688067674637,
      0.008949712850153446,
      0.03490782156586647,
      -0.05307896435260773,
      -0.06150452792644501,
      -0.07792946696281433,
      0.03457541763782501,
      0.01921495795249939,
      -0.06627296656370163,
      0.0180567167699337,
      0.004843348171561956,
      -0.12148275226354599,
      0.014644031412899494,
      -0.02365749143064022,
      -0.04012355953454971,
      -0.06476388871669769,
      -0.09239527583122253,
      -0.03847601264715195,
      -0.04182950034737587,
      -0.007742689922451973,
      0.05780964344739914,
      -0.05906600505113602,
      0.04682738333940506,
      -0.049250226467847824,
      -0.005715407431125641,
      0.04860638082027435,
      0.03911745920777321,
      -0.024452412500977516,
      0.01256297156214714,
      -0.029786299914121628,
      -0.04402440786361694,
      -0.033882442861795425,
      0.12379255890846252,
      -0.023440856486558914,
      0.0005021275137551129,
      0.01057843491435051,
      0.05471577122807503,
      0.0610748752951622,
      0.07698306441307068,
      0.027250999584794044,
      -0.007475009188055992,
      0.06982838362455368,
      -0.05874790996313095,
      0.07047824561595917,
      -0.0012771306792274117,
      0.0525515042245388,
      -0.041457150131464005,
      0.07465197145938873,
      -0.008594803512096405,
      -0.03101661056280136,
      -0.0540226548910141,
      0.009286997839808464,
      -0.0023602661676704884,
      0.0390457957983017,
      -0.00830799899995327,
      0.024146199226379395,
      0.08695603907108307,
      -0.010946681722998619,
      0.08385919034481049,
      0.015857864171266556,
      0.031432151794433594,
      -0.04800628870725632,
      0.0721568837761879,
      -0.1122216060757637,
      -0.003965951967984438,
      -0.01178731583058834,
      0.02993779443204403,
      -0.011248881928622723,
      0.0021394568029791117,
      -0.0957922488451004,
      -0.014652405865490437,
      -0.024653078988194466,
      0.08396204560995102,
      0.02026197500526905,
      0.014517063274979591,
      0.027853509411215782,
      0.009382686577737331,
      0.021874165162444115,
      0.04218343272805214,
      0.025764405727386475,
      -0.01773412898182869,
      -0.048340342938899994,
      0.006742275785654783,
      0.019268667325377464,
      -0.024104516953229904,
      -0.0614645853638649,
      0.027766045182943344,
      0.017254704609513283,
      -0.06404556334018707,
      -0.0003081883187405765,
      -0.05465183034539223,
      -0.08396432548761368,
      -0.05940926820039749,
      0.01367107778787613,
      -0.019703416153788567,
      -0.12330906093120575,
      0.0379975363612175,
      -0.004898398648947477,
      -0.02491442859172821,
      -0.013199467211961746,
      0.012690658681094646,
      -0.1122860386967659,
      -0.05307256430387497,
      0.02398914098739624,
      0.010748835280537605,
      -0.02625393308699131,
      -0.004404047969728708,
      -0.034898437559604645,
      0.03846800699830055,
      0.033116139471530914,
      -0.03858485445380211,
      -0.0348130539059639,
      0.0814831554889679,
      0.02355007827281952,
      0.03248594328761101,
      0.08293181657791138,
      0.04259591922163963,
      0.0344918891787529,
      0.00744959432631731,
      0.013376816175878048,
      0.032247018069028854,
      -0.0683973953127861,
      0.058478351682424545,
      -0.006176376715302467,
      -0.008575822226703167,
      0.041095711290836334,
      0.02038542553782463,
      0.004453806672245264,
      -0.07391668111085892,
      0.093446284532547,
      0.006785916164517403,
      0.006135919131338596,
      -0.05488076061010361,
      -0.01544414833188057,
      -0.005356559529900551,
      0.016670312732458115,
      0.0533023402094841,
      0.03331670165061951,
      0.009620601311326027,
      0.0839376449584961,
      0.038607191294431686,
      0.04050181806087494,
      -0.04992831125855446,
      0.013621408492326736,
      -0.03517518937587738,
      0.049794260412454605,
      -0.036894671618938446,
      -0.038617681711912155,
      0.01200586836785078,
      -0.05494082719087601,
      0.030574724078178406,
      -0.010917263105511665,
      -0.0102059505879879,
      -0.02500789798796177,
      -0.026782706379890442,
      -0.004324359819293022,
      0.06866982579231262,
      0.007533287163823843,
      0.005701280664652586,
      -0.01590743474662304,
      -0.05491192638874054,
      0.0545557476580143,
      0.008364690467715263,
      -0.08999234437942505,
      0.0007607864099554718,
      0.03550787270069122,
      0.06504666805267334,
      0.003441721200942993,
      -0.008141067810356617,
      0.05001369118690491,
      0.06652076542377472,
      0.029480861499905586,
      -0.008430520072579384,
      0.049105096608400345,
      -0.017858417704701424,
      0.02007003128528595,
      0.06505785137414932,
      0.0648011714220047,
      -0.05150800570845604,
      0.020415036007761955,
      -0.029177192598581314,
      0.12062204629182816,
      0.017582781612873077,
      -0.03222345560789108,
      -0.032544977962970734,
      0.04412353038787842,
      -0.019710902124643326,
      0.007107879035174847,
      0.032306838780641556,
      0.03119031712412834,
      0.06256130337715149,
      0.0002815848565660417,
      0.05092034861445427,
      0.017268363386392593,
      -0.06634952127933502,
      0.06983593851327896,
      -0.025527944788336754,
      -0.005578104872256517,
      -0.042985424399375916,
      0.012637240812182426,
      0.009845378808677197,
      0.016171591356396675,
      0.00900384970009327,
      0.056946445256471634,
      -0.06632710248231888,
      -0.03074878826737404,
      0.04374230280518532,
      -0.04835876449942589,
      0.0211451668292284,
      -0.01850758120417595,
      0.0496068149805069,
      0.05665452033281326,
      0.055989693850278854,
      -0.05739926919341087,
      -0.037144966423511505,
      -0.02827538549900055,
      0.03997541218996048,
      0.023312348872423172,
      -0.0396476574242115,
      0.04850156605243683,
      -0.017162565141916275,
      0.03981223702430725,
      -0.02854505553841591,
      -0.012777158059179783,
      0.010621966794133186,
      -0.07019289582967758,
      -0.0004791714600287378,
      0.05415375903248787,
      -0.023743832483887672,
      0.018188968300819397,
      0.05280517414212227,
      0.08191964775323868,
      -0.056501735001802444,
      0.007463715970516205,
      0.03880830854177475,
      0.04564753919839859,
      -0.025196989998221397,
      0.051281049847602844,
      -0.024053186178207397,
      0.016271891072392464,
      -0.08126293867826462,
      0.016765208914875984,
      -0.1713077276945114,
      -0.030871830880641937,
      -0.016202155500650406,
      -0.02778996340930462,
      0.008732516318559647,
      0.003096881089732051,
      -0.025994643568992615,
      -0.020702147856354713,
      0.006356719881296158,
      -0.010758073069155216,
      -0.07716308534145355,
      0.014606562443077564,
      0.028001978993415833,
      -0.0102847870439291,
      0.020543435588479042,
      0.032096538692712784,
      0.0219550933688879,
      0.11613483726978302,
      0.013367396779358387,
      0.0205377284437418,
      0.0039888713508844376,
      -0.0023468455765396357,
      -0.013890071772038937,
      -0.016072485595941544,
      0.04860977455973625,
      0.010109728202223778,
      -0.018247803673148155,
      0.05723607912659645,
      -0.026521341875195503,
      0.054259270429611206,
      0.038145046681165695,
      0.004381879232823849,
      -0.04843682795763016,
      0.0979827493429184,
      -0.03545326367020607,
      -0.062138818204402924,
      -0.025472745299339294,
      0.07858355343341827,
      -0.06924118101596832,
      0.04108644276857376,
      -0.03789287805557251,
      0.04779703915119171,
      0.02368117868900299,
      0.02760099619626999,
      -0.06343426555395126,
      0.10854475945234299,
      0.01495314296334982,
      0.0416223481297493,
      0.040691133588552475,
      0.0007029700791463256,
      0.0028659338131546974,
      0.033925384283065796,
      -0.02536207064986229,
      -0.02230503037571907,
      -0.06673208624124527,
      0.0037388158962130547,
      0.02382497675716877,
      0.04370516911149025,
      0.00773100508376956,
      0.0645531564950943,
      0.007680194452404976,
      0.012936289422214031,
      0.007801927160471678,
      -0.05230659246444702,
      0.03329518437385559,
      0.027039650827646255,
      0.017680279910564423,
      -0.004244285635650158,
      -0.06719665229320526,
      -0.004187854938209057,
      0.031252261251211166,
      -0.017723923549056053,
      -0.029597455635666847,
      -0.07019060105085373,
      0.04113922268152237,
      0.03868498653173447,
      -0.006206423044204712,
      0.01916169933974743,
      0.02759053371846676,
      0.019751692190766335,
      0.03768199682235718,
      0.04368223994970322,
      -0.05267113074660301,
      0.0686008408665657,
      -0.06160348653793335,
      0.011406750418245792,
      -0.04855508357286453,
      -0.011809165589511395,
      -0.010276690125465393,
      0.015007250010967255,
      0.0032231579534709454,
      0.0054175215773284435,
      0.10040099173784256,
      0.010381186380982399,
      0.05281100794672966,
      -0.041250403970479965,
      -0.05775248259305954,
      0.04879849776625633,
      0.04883645847439766,
      -0.0054147434420883656,
      -0.018029503524303436,
      -0.061629850417375565,
      0.04362271726131439,
      -0.02581043913960457,
      -0.035811226814985275,
      -0.016608402132987976,
      0.01692362129688263,
      0.007019417826086283,
      0.005915599409490824,
      0.07580499351024628,
      0.01501172874122858,
      -0.04544685781002045,
      -0.018705645576119423,
      -0.048488911241292953,
      0.021230515092611313,
      0.0456765815615654,
      0.06499876081943512,
      0.04085439816117287,
      -0.00855996273458004,
      0.008215607143938541,
      0.029413273558020592,
      0.04120216146111488,
      0.05667441710829735,
      -0.05046147108078003,
      0.033352840691804886,
      -0.0023954464122653008,
      -0.016197627410292625,
      0.07481035590171814,
      0.0677347332239151,
      0.029027368873357773,
      -0.06285876035690308,
      0.06905944645404816,
      -0.01894553005695343,
      0.007464796770364046,
      -0.0271287988871336,
      -0.05980187654495239,
      -0.01123340055346489,
      -0.08568407595157623,
      -0.038006097078323364,
      -0.02677025832235813,
      0.07719535380601883,
      0.08629653602838516,
      0.08978453278541565,
      -0.046841561794281006,
      0.020051710307598114,
      -0.009090562351047993,
      -0.010322443209588528,
      -0.0739169716835022,
      0.04156239330768585,
      -0.029674312099814415,
      -0.0018234362360090017,
      -0.07128483802080154,
      0.0008881640969775617,
      0.008524900302290916,
      0.0004047748225275427,
      0.02813214808702469,
      0.00865844450891018,
      -0.039363883435726166,
      0.033932045102119446,
      0.06506264209747314,
      0.029564011842012405,
      -0.011007056571543217,
      0.012812093831598759,
      0.031172791495919228,
      -0.03165464848279953,
      0.08041086047887802,
      0.07129450142383575,
      -0.0043081920593976974,
      -0.0798262357711792,
      -0.016350284218788147,
      -0.07355737686157227,
      0.03141171112656593,
      -0.012266748584806919,
      -0.03161893039941788,
      -0.08863940834999084,
      -0.03410721942782402,
      -0.10423925518989563,
      -0.04036435857415199,
      -0.012229451909661293,
      0.07232698053121567,
      0.03494061529636383,
      0.0024090707302093506,
      -0.01124468632042408,
      -0.07718595862388611,
      -0.048781294375658035,
      0.007196693681180477,
      -0.008296099491417408,
      0.08172744512557983,
      0.0014920816756784916,
      -0.04094497114419937,
      -0.05958490073680878,
      0.06585278362035751,
      -0.023192498832941055,
      -0.041404839605093,
      -0.035991642624139786,
      -0.12321336567401886,
      0.005800364539027214,
      0.02283521741628647,
      -0.024442698806524277,
      -0.040423933416604996,
      -0.02258789725601673,
      -0.03841986507177353,
      -0.00031516572926193476,
      -0.005654937122017145,
      -0.009819239377975464,
      0.05077492818236351,
      -0.04970092326402664,
      0.01411010418087244,
      0.007952909916639328,
      0.03767448291182518,
      -0.01903206668794155,
      0.012513541616499424,
      0.0737544521689415,
      -0.0561690591275692,
      0.027555039152503014,
      0.0527823343873024,
      0.0037131081335246563,
      0.011067154817283154,
      -0.010625136084854603,
      0.0069345771335065365,
      0.060477182269096375,
      -0.015895698219537735,
      0.02896280027925968,
      0.008963559754192829
    ],
    [
      -0.010193227790296078,
      0.020309630781412125,
      0.007717587053775787,
      0.02508283592760563,
      0.005663717165589333,
      -0.07398674637079239,
      -0.002394084120169282,
      -0.061644017696380615,
      -0.010919399559497833,
      0.02745090425014496,
      -0.02450026385486126,
      0.049017272889614105,
      -0.06484361737966537,
      -0.06605486571788788,
      0.033194608986377716,
      -0.0179960448294878,
      0.04858515039086342,
      0.00833237636834383,
      0.05801210552453995,
      0.08429282903671265,
      -0.10352243483066559,
      0.04141760617494583,
      -0.019718876108527184,
      -0.003469199640676379,
      -0.015274416655302048,
      0.03886258974671364,
      -0.06098269671201706,
      0.01587861217558384,
      0.04063589498400688,
      0.010521047748625278,
      0.0653541088104248,
      0.001083957962691784,
      -0.05002894252538681,
      -0.008263050578534603,
      0.07833626121282578,
      0.0030151281971484423,
      0.08835358172655106,
      -0.08560115098953247,
      0.011517809703946114,
      0.039055779576301575,
      -0.00544097600504756,
      0.04101301729679108,
      -0.012570418417453766,
      0.0801599770784378,
      0.06385932117700577,
      0.03178761526942253,
      -0.014842832460999489,
      -0.09998641908168793,
      -0.0557413212954998,
      0.056823860853910446,
      -0.01271852571517229,
      -0.0022447328083217144,
      -0.02058313600718975,
      0.037261560559272766,
      0.02066626027226448,
      0.03179927170276642,
      -0.055340807884931564,
      -0.029257483780384064,
      -0.034455616027116776,
      0.02801172062754631,
      -0.010170180350542068,
      0.054832104593515396,
      -0.03162281587719917,
      -0.0069343410432338715,
      -0.007071187254041433,
      -0.0415402315557003,
      -0.011228948831558228,
      -0.010120418854057789,
      0.05558103322982788,
      -0.039880719035863876,
      -0.02662586234509945,
      -0.013512691482901573,
      0.03612135723233223,
      0.016723062843084335,
      0.03495834395289421,
      -0.03187545761466026,
      0.017309537157416344,
      -0.15562283992767334,
      0.04694543406367302,
      0.02302251011133194,
      0.006365468725562096,
      -0.11845102906227112,
      -0.0838119313120842,
      0.015549098141491413,
      0.02987785078585148,
      -0.004555851221084595,
      0.05912144482135773,
      -0.02458399161696434,
      -0.04615325853228569,
      0.02987508475780487,
      -0.010394842363893986,
      -0.04534348100423813,
      -0.07283779233694077,
      -0.00671200267970562,
      0.03471429646015167,
      0.019589848816394806,
      0.0036958225537091494,
      0.0001224089355673641,
      0.013551868498325348,
      0.10847301036119461,
      0.07617900520563126,
      0.0124280396848917,
      -0.04669158533215523,
      0.00046167135587893426,
      -0.03367817401885986,
      -0.07164161652326584,
      -0.0029747297521680593,
      0.06055143475532532,
      0.10894575715065002,
      0.019561821594834328,
      -0.0017994443187490106,
      0.016490168869495392,
      -0.08348145335912704,
      0.05241647735238075,
      -0.06385233253240585,
      -0.006426970474421978,
      0.12421580404043198,
      -0.03458748757839203,
      -0.09390144050121307,
      -0.018113352358341217,
      0.04788744077086449,
      -0.033708956092596054,
      0.07736463099718094,
      -0.015051083639264107,
      -0.046823155134916306,
      0.023094026371836662,
      0.00831304956227541,
      -0.020132236182689667,
      0.008125369437038898,
      -0.11408502608537674,
      -0.008635620586574078,
      -0.06749896705150604,
      0.011605629697442055,
      -0.009641744196414948,
      0.07231058925390244,
      -0.05012073740363121,
      -0.1010194718837738,
      -0.030590854585170746,
      0.022170139476656914,
      -0.026618782430887222,
      0.009085417725145817,
      0.035782285034656525,
      -0.024099042639136314,
      -0.06226254254579544,
      0.02167380042374134,
      0.03406756743788719,
      -0.04797667637467384,
      0.07687374949455261,
      -0.010803697630763054,
      -0.020942242816090584,
      0.03597342222929001,
      -0.030274828895926476,
      0.05544556304812431,
      0.06141434982419014,
      -0.013608496636152267,
      0.06846805661916733,
      0.0061571430414915085,
      0.03473813086748123,
      0.06258200854063034,
      0.03748391568660736,
      -0.019519740715622902,
      0.00671227416023612,
      -0.09914446622133255,
      0.010541507974267006,
      0.010944114066660404,
      -0.024576790630817413,
      -0.05459297448396683,
      -0.00036572577664628625,
      0.06704411655664444,
      -0.07465442270040512,
      0.013783588074147701,
      -0.019746338948607445,
      -0.04257652908563614,
      -0.006227591540664434,
      0.0005149560165591538,
      0.02989252284169197,
      0.010922251269221306,
      -0.013145511969923973,
      0.013937500305473804,
      -0.034191738814115524,
      -0.062440913170576096,
      0.12067697197198868,
      -0.020782290026545525,
      -0.06189967319369316,
      0.01914910040795803,
      -0.01628343015909195,
      -0.05407382547855377,
      0.010985445231199265,
      -0.03828905150294304,
      -0.02921214886009693,
      -0.005074518267065287,
      -0.043351124972105026,
      0.06369245797395706,
      0.06291800737380981,
      -0.012861739844083786,
      -0.02364945039153099,
      0.00991277676075697,
      0.06307678669691086,
      0.02680240385234356,
      0.04104294255375862,
      0.0317564494907856,
      -0.0932309627532959,
      0.09103359282016754,
      0.05701492354273796,
      -0.07292550802230835,
      -0.014451549388468266,
      0.0001093035243684426,
      0.04654407501220703,
      -0.018307657912373543,
      -0.09590397775173187,
      -0.007413340732455254,
      -0.030948692932724953,
      -0.03868402540683746,
      -0.021601812914013863,
      -0.017621858045458794,
      0.03225643187761307,
      -0.00980370119214058,
      0.053137023001909256,
      -0.02379731647670269,
      0.08380789309740067,
      -0.017665937542915344,
      0.03581247106194496,
      0.06381715834140778,
      0.0011455058120191097,
      0.04834319278597832,
      -0.0051831710152328014,
      -0.028273850679397583,
      -0.004554584622383118,
      -0.09462901949882507,
      0.008321757428348064,
      0.07352487742900848,
      -0.03793762996792793,
      0.019818900153040886,
      -0.04112207144498825,
      -0.039903584867715836,
      -0.079383984208107,
      -0.010114512406289577,
      -0.07001828402280807,
      -0.039105985313653946,
      0.013798294588923454,
      -0.024732640013098717,
      -0.045547034591436386,
      0.06621528416872025,
      0.0004584352718666196,
      0.008120576851069927,
      0.01471279188990593,
      -0.009607214480638504,
      -0.07150819152593613,
      -0.020545845851302147,
      0.006871717516332865,
      -0.0017902468098327518,
      -0.015999164432287216,
      -0.0018820053664967418,
      -0.028828678652644157,
      0.01619027927517891,
      0.0007424408686347306,
      -0.06542056053876877,
      0.10370392352342606,
      0.11034084856510162,
      -0.012155606411397457,
      0.029329977929592133,
      0.0107761574909091,
      -0.006197557784616947,
      0.010017585009336472,
      0.004410823807120323,
      0.031215431168675423,
      0.011166214942932129,
      -0.03786046430468559,
      0.0091363824903965,
      -0.0345907136797905,
      -0.02073463425040245,
      0.007283818442374468,
      0.05690132454037666,
      0.012868329882621765,
      -0.07444599270820618,
      0.055326614528894424,
      -0.008862013928592205,
      -0.012590003199875355,
      0.021545054391026497,
      -0.028290510177612305,
      -0.06452761590480804,
      -0.05268234387040138,
      -0.041781190782785416,
      0.05576784908771515,
      -0.09131534397602081,
      0.09607990831136703,
      0.10048551857471466,
      -0.0416346937417984,
      0.06532128155231476,
      -0.050004541873931885,
      0.0539211742579937,
      0.037670064717531204,
      -0.004203139804303646,
      -0.07457905262708664,
      0.04170463979244232,
      0.04793720692396164,
      0.039474524557590485,
      -0.015534571371972561,
      -0.0038335351273417473,
      -0.04187016189098358,
      -0.05847404524683952,
      0.05850179120898247,
      -0.02824479341506958,
      -0.00694325240328908,
      -0.020840216428041458,
      0.015231302008032799,
      0.08444631099700928,
      -0.028423089534044266,
      -0.026608237996697426,
      0.002626298228278756,
      -0.04656331241130829,
      -0.02421741746366024,
      0.09872294217348099,
      0.03504851087927818,
      0.007827680557966232,
      0.10801839828491211,
      -0.005742424633353949,
      0.024923155084252357,
      0.03797077387571335,
      -0.026102839037775993,
      0.0025132219307124615,
      -0.01923045888543129,
      0.028017558157444,
      -0.05605711415410042,
      0.05306422710418701,
      -0.0027477480471134186,
      0.02223263680934906,
      -0.08674633502960205,
      -0.0018735307967290282,
      -0.022443862631917,
      0.08637665212154388,
      0.03777468204498291,
      -0.035176098346710205,
      0.008105823770165443,
      0.04754353687167168,
      -0.05120746046304703,
      0.04992855340242386,
      0.051582060754299164,
      -0.027367522940039635,
      0.04638205096125603,
      -0.03548896685242653,
      0.0011395459296181798,
      -0.03433618322014809,
      -0.06844140589237213,
      0.04144931584596634,
      0.021875975653529167,
      -0.0853661447763443,
      -0.02369716763496399,
      0.008667613379657269,
      0.03269599378108978,
      0.006829081103205681,
      -0.0490325428545475,
      -0.024208035320043564,
      0.0837070420384407,
      -0.00997177790850401,
      -0.016708875074982643,
      0.03841003403067589,
      -0.026622291654348373,
      -0.007192640099674463,
      0.07419003546237946,
      0.011341402307152748,
      -0.010172075591981411,
      0.019587328657507896,
      0.05038433149456978,
      -0.003184089669957757,
      0.07027538865804672,
      0.017420807853341103,
      0.0416812002658844,
      0.05524652823805809,
      0.029744554311037064,
      0.06422463804483414,
      -0.054821599274873734,
      -0.05382833257317543,
      -0.025134708732366562,
      -0.03633994609117508,
      0.005485516972839832,
      -0.0065936013124883175,
      -0.037482284009456635,
      -0.07197019457817078,
      -0.03217508643865585,
      0.027903219684958458,
      -0.020179806277155876,
      0.06765876710414886,
      -0.04500943049788475,
      0.019848020747303963,
      0.07612423598766327,
      0.016282491385936737,
      -0.01631999760866165,
      -0.006310984492301941,
      0.017856473103165627,
      -0.0016725232126191258,
      -0.04240114614367485,
      -0.004424447193741798,
      0.10237927734851837,
      -0.024185890331864357,
      -0.007677309215068817,
      0.019121887162327766,
      -0.025308506563305855,
      0.00016436124860774726,
      -0.026490556076169014,
      0.009207352995872498,
      0.0018803809070959687,
      0.0683126375079155,
      0.00946022942662239,
      0.06817260384559631,
      0.014437662437558174,
      0.04573458060622215,
      0.0404377207159996,
      -0.04907389357686043,
      0.008159607648849487,
      -0.05013308301568031,
      -0.07414328306913376,
      -0.04304858297109604,
      -0.007098187226802111,
      -0.10271703451871872,
      -0.044880930334329605,
      0.010404791682958603,
      0.060564007610082626,
      0.022588901221752167,
      0.050153475254774094,
      0.0006529610836878419,
      0.07222192734479904,
      0.12664364278316498,
      0.014628562144935131,
      0.031210098415613174,
      0.001999882748350501,
      0.007512022275477648,
      0.10916505008935928,
      -0.03747721016407013,
      0.051827672868967056,
      -0.019085850566625595,
      0.0021654251031577587,
      0.019597776234149933,
      -0.027926858514547348,
      0.038358286023139954,
      0.000501549628097564,
      0.015291082672774792,
      -0.030589381232857704,
      -0.028628617525100708,
      0.01834999956190586,
      -0.07813557237386703,
      -0.028583204373717308,
      0.03419962152838707,
      0.0291482824832201,
      -0.003014917252585292,
      0.037895601242780685,
      0.05238112434744835,
      -0.03741594776511192,
      0.029368311166763306,
      -0.005566350184381008,
      0.03194371983408928,
      -0.055409204214811325,
      0.032192692160606384,
      -0.13449878990650177,
      -0.04529540613293648,
      0.037506282329559326,
      0.005404746625572443,
      -0.05336585268378258,
      -0.007525034248828888,
      0.02164844609797001,
      -0.005234637763351202,
      -0.010340495966374874,
      0.0995309129357338,
      0.01731278747320175,
      -0.06517182290554047,
      0.05758193880319595,
      -0.03428417816758156,
      -0.030243972316384315,
      -0.055431000888347626,
      -0.05424223840236664,
      0.019504645839333534,
      -0.013180689886212349,
      -0.035482268780469894,
      0.054031290113925934,
      -0.02046084962785244,
      0.06805602461099625,
      0.0991273745894432,
      -0.009972832165658474,
      -0.02706863358616829,
      -0.013671455904841423,
      0.03278201445937157,
      0.09818221628665924,
      0.014681616798043251,
      0.06243053078651428,
      -0.06366339325904846,
      0.033409979194402695,
      0.04004855826497078,
      0.019280094653367996,
      -0.03143298998475075,
      -0.00026637775590643287,
      -0.04912106692790985,
      -0.008484844118356705,
      -0.025998486205935478,
      -0.04379164054989815,
      -0.07413371652364731,
      0.03864046186208725,
      -0.02511366829276085,
      0.029445573687553406,
      0.0003790850460063666,
      -0.048546258360147476,
      0.007637508679181337,
      -0.0160310547798872,
      -0.03789456933736801
    ],
    [
      0.043469708412885666,
      0.006785827688872814,
      0.04572565108537674,
      0.029245266690850258,
      -0.0650787279009819,
      0.02830439992249012,
      -0.03086368553340435,
      0.0578576885163784,
      -0.01958274282515049,
      -0.05149093642830849,
      -0.04606219008564949,
      -0.027851110324263573,
      0.018538514152169228,
      0.0088172797113657,
      -0.01560905296355486,
      -0.015115778893232346,
      0.012054061517119408,
      0.015939762815833092,
      0.06716808676719666,
      0.009008326567709446,
      -0.006823343690484762,
      -0.024528566747903824,
      -0.04170267656445503,
      -0.10732217133045197,
      -0.056514766067266464,
      0.005126679316163063,
      -0.009889326989650726,
      -0.030187884345650673,
      0.021380821242928505,
      0.044090818613767624,
      0.04515219107270241,
      0.0452750101685524,
      0.002908593276515603,
      -0.052622824907302856,
      0.00510408217087388,
      0.011700447648763657,
      0.004712138790637255,
      -0.0709279403090477,
      0.001572005799971521,
      -0.02808300405740738,
      -0.03108384646475315,
      0.012217510491609573,
      -0.027857858687639236,
      0.008725346066057682,
      0.0465669259428978,
      -0.030813906341791153,
      -0.013664950616657734,
      -0.001625041593797505,
      0.006877759005874395,
      -0.017952226102352142,
      0.03723899647593498,
      0.002350623020902276,
      -0.07853716611862183,
      0.05893665924668312,
      0.12562410533428192,
      -0.0016096268082037568,
      0.04399635270237923,
      0.10334307700395584,
      0.08043869584798813,
      -0.0824504867196083,
      -0.035124871879816055,
      0.02070073038339615,
      -0.0910431519150734,
      -0.04263123497366905,
      0.036498408764600754,
      -0.031178725883364677,
      -0.06064338982105255,
      -0.03550339490175247,
      0.018723415210843086,
      0.031661257147789,
      -0.00413240771740675,
      0.01943923719227314,
      0.05833487957715988,
      0.005703768692910671,
      -0.0663408562541008,
      -0.004179536830633879,
      0.02405245415866375,
      0.020036397501826286,
      -0.07116829603910446,
      -0.08799993991851807,
      -0.0177009180188179,
      -0.02119993045926094,
      -0.07828342914581299,
      0.040232427418231964,
      0.06884340941905975,
      -0.02744651772081852,
      -0.035459358245134354,
      -0.057856932282447815,
      0.04589148983359337,
      -0.050009358674287796,
      -0.008230150677263737,
      -0.054330069571733475,
      0.020220652222633362,
      -0.035705287009477615,
      -0.04220857098698616,
      0.03151621297001839,
      -0.027188202366232872,
      0.0052910177037119865,
      0.005059642717242241,
      -0.0033377530053257942,
      0.0008526012534275651,
      0.05025212839245796,
      0.006143826991319656,
      0.017517564818263054,
      0.045051392167806625,
      -0.06283924728631973,
      -0.06185632571578026,
      0.07384350895881653,
      0.002857013838365674,
      0.04214746505022049,
      -0.06650149822235107,
      -0.006413675844669342,
      -0.06703042984008789,
      0.04619606211781502,
      0.04894052445888519,
      -0.020757026970386505,
      -0.019404301419854164,
      0.025847051292657852,
      0.092644102871418,
      -0.0017141631105914712,
      -0.05268796533346176,
      -0.09967105835676193,
      -0.04935961961746216,
      0.029671037569642067,
      -0.03994535282254219,
      0.060350921005010605,
      -0.029397476464509964,
      -0.02332436293363571,
      0.010932388715445995,
      0.07113353163003922,
      0.02131846360862255,
      -0.07599818706512451,
      0.005427772644907236,
      0.04628084599971771,
      0.08499417454004288,
      -0.01734003983438015,
      -0.020702091977000237,
      0.005018551368266344,
      0.07268039882183075,
      -0.08465181291103363,
      -0.03610680252313614,
      -0.03450777381658554,
      0.009293249808251858,
      0.013177039101719856,
      -0.029398132115602493,
      0.001444576308131218,
      0.06880411505699158,
      0.022967269644141197,
      -0.008206346072256565,
      0.06151704862713814,
      -0.04629598930478096,
      0.03963388875126839,
      -0.015516999177634716,
      0.029534855857491493,
      0.06533872336149216,
      -0.05555017665028572,
      0.06830031424760818,
      -0.0309491865336895,
      -0.00942730437964201,
      -0.04504818096756935,
      -0.013745949603617191,
      -0.01849631406366825,
      -0.054109614342451096,
      0.07029222697019577,
      -0.027998922392725945,
      0.05438987910747528,
      -0.021920286118984222,
      -0.020140569657087326,
      0.043766140937805176,
      -0.029530519619584084,
      -0.022840501740574837,
      0.027386950328946114,
      -0.045837387442588806,
      0.07980579137802124,
      -0.030841302126646042,
      0.0685872957110405,
      0.011316484771668911,
      -0.028335509821772575,
      -0.013515627011656761,
      0.10629097372293472,
      -0.07481387257575989,
      -0.020895754918456078,
      0.047554172575473785,
      0.0007800672319717705,
      0.010253832675516605,
      -0.0012389152543619275,
      -0.011651970446109772,
      -0.013575268909335136,
      0.02555094100534916,
      -0.0020749142859131098,
      0.04362446442246437,
      0.06472120434045792,
      -0.01968212053179741,
      -0.034056104719638824,
      0.021875593811273575,
      0.02535269968211651,
      -0.025413472205400467,
      0.0013770470395684242,
      -0.02505761757493019,
      0.07641925662755966,
      0.040065210312604904,
      0.009278486482799053,
      -0.02103537507355213,
      -0.04472595080733299,
      -0.037640102207660675,
      -0.029634471982717514,
      -0.023111004382371902,
      -0.007997091859579086,
      0.06569691002368927,
      0.020098192617297173,
      -0.05904030799865723,
      -0.045322075486183167,
      0.019337624311447144,
      -0.06582514941692352,
      0.04347186163067818,
      -0.02092001959681511,
      -0.01674456149339676,
      0.0356346033513546,
      0.031794022768735886,
      -0.018275529146194458,
      -0.03308786451816559,
      -0.012743002735078335,
      0.07453395426273346,
      0.032653674483299255,
      -0.02967515029013157,
      0.05275939032435417,
      -0.036629222333431244,
      0.053606268018484116,
      0.03713815286755562,
      -0.023206079378724098,
      -0.07182814925909042,
      0.027114788070321083,
      -0.017181329429149628,
      -0.008889538235962391,
      -0.018199356272816658,
      0.009936066344380379,
      -0.030520083382725716,
      0.03887538984417915,
      0.06572982668876648,
      0.032112058252096176,
      -0.022501258179545403,
      0.012734564952552319,
      0.05595622956752777,
      -0.004274412523955107,
      -0.017825698480010033,
      0.08610913157463074,
      0.01777753233909607,
      -0.05200234428048134,
      0.015049567446112633,
      -0.03929902985692024,
      0.02584988623857498,
      0.00923812948167324,
      -0.053917355835437775,
      -0.06709344685077667,
      -0.037940461188554764,
      0.014973176643252373,
      0.03383389860391617,
      0.014158056117594242,
      -0.11988381296396255,
      -0.017430730164051056,
      0.038300659507513046,
      0.011319239623844624,
      0.02621772512793541,
      -0.0006725415005348623,
      -0.0313715860247612,
      -0.021611036732792854,
      -0.006594372913241386,
      -0.02113749086856842,
      -0.06366264075040817,
      -0.012914901599287987,
      -0.05288182199001312,
      0.014974205754697323,
      0.022208135575056076,
      -0.07100611180067062,
      -0.014893469400703907,
      0.04128599539399147,
      0.03659770265221596,
      -0.020401887595653534,
      -0.0009703717660158873,
      0.027581103146076202,
      0.014833346009254456,
      -0.044519178569316864,
      -0.012449346482753754,
      -0.0049070389941334724,
      0.0188142117112875,
      -0.039431262761354446,
      -0.014933978207409382,
      0.03599562495946884,
      0.02217046171426773,
      -0.03847050666809082,
      -0.0356389582157135,
      0.019844086840748787,
      0.06200172379612923,
      -0.015805738046765327,
      0.03157173469662666,
      -0.036297205835580826,
      -0.006494666449725628,
      0.008888248354196548,
      0.013095381669700146,
      -0.07610414177179337,
      0.008812512271106243,
      -0.030040638521313667,
      -0.050677262246608734,
      0.025347981601953506,
      0.024322593584656715,
      0.01319153793156147,
      0.05963088572025299,
      -0.03272717073559761,
      -0.006185527890920639,
      0.09058995544910431,
      -0.028598934412002563,
      0.02695920318365097,
      0.03027552366256714,
      -0.0024979787413030863,
      0.034561652690172195,
      -0.08729810267686844,
      0.041351158171892166,
      0.10068827867507935,
      -0.014088104479014874,
      -0.02091650664806366,
      -0.042231034487485886,
      -0.007065482437610626,
      0.028598371893167496,
      -0.005854761227965355,
      -0.0019737347029149532,
      0.08843135833740234,
      -0.013731061480939388,
      -0.016361676156520844,
      0.007543045561760664,
      0.006068625021725893,
      0.028540654107928276,
      -0.0035473951138556004,
      0.09780607372522354,
      -0.0887158215045929,
      -0.020528189837932587,
      0.012980952858924866,
      -0.0037349993363022804,
      0.0365164615213871,
      -0.03543888032436371,
      0.12138836085796356,
      0.06525527685880661,
      -0.03551992028951645,
      -0.002675175666809082,
      0.04894912615418434,
      -0.1018553078174591,
      -0.13941335678100586,
      -0.03504243120551109,
      -0.06642971932888031,
      -0.0051787723787128925,
      0.11757120490074158,
      -0.08205015957355499,
      -0.005103617906570435,
      -0.04368014261126518,
      0.060485318303108215,
      -0.05603533610701561,
      0.05977728217840195,
      0.07286974787712097,
      -0.04626403748989105,
      0.05288909748196602,
      0.1211528480052948,
      -0.03195921704173088,
      0.0032676588743925095,
      0.03898051753640175,
      -0.0013009028043597937,
      -0.08275464922189713,
      0.039782796055078506,
      0.011548290029168129,
      -0.04137146472930908,
      -0.001547808526083827,
      -0.07340783625841141,
      -0.02752910740673542,
      0.059185709804296494,
      -0.005728962831199169,
      0.06251204758882523,
      -0.0021089890506118536,
      -0.019867926836013794,
      0.062205132097005844,
      0.04248147830367088,
      0.025919657200574875,
      -0.04675789549946785,
      0.009956379421055317,
      0.039538826793432236,
      -0.03971436992287636,
      -0.0007952434243634343,
      0.00920476857572794,
      0.06227869540452957,
      0.03494991734623909,
      -0.013135861605405807,
      0.008192356675863266,
      0.0020810896530747414,
      -0.004246727097779512,
      -0.00747524993494153,
      0.04959986358880997,
      0.030392589047551155,
      -0.005511685274541378,
      0.011711063794791698,
      0.000765632838010788,
      -0.04075045883655548,
      0.022348718717694283,
      0.04793890565633774,
      -0.017173228785395622,
      -0.02015766315162182,
      0.061339523643255234,
      -0.007309969514608383,
      -0.007855532690882683,
      0.048652734607458115,
      0.03172779455780983,
      0.05273040756583214,
      0.12526798248291016,
      -0.034325726330280304,
      -0.03906013071537018,
      0.035054054111242294,
      -0.08383798599243164,
      -0.03991914913058281,
      0.011587701737880707,
      -0.06486556679010391,
      0.042413193732500076,
      0.007415131200104952,
      -0.07873684912919998,
      0.00679839588701725,
      -0.021262116730213165,
      0.06065469607710838,
      -0.02085697278380394,
      -0.018192149698734283,
      -0.058865100145339966,
      0.008397961035370827,
      -0.03160816803574562,
      -0.04531021788716316,
      -0.023488471284508705,
      0.0581347830593586,
      -9.213238809024915e-05,
      -0.008558317087590694,
      0.04695385321974754,
      -0.03250208497047424,
      -0.029521174728870392,
      -0.03361337631940842,
      -0.006222561467438936,
      0.06027083471417427,
      0.0560387521982193,
      -0.03590743616223335,
      0.049000900238752365,
      0.060496971011161804,
      -0.13127394020557404,
      0.04663742706179619,
      0.017684191465377808,
      -0.022120704874396324,
      -0.021350564435124397,
      0.05384423956274986,
      0.04967520758509636,
      -0.02307039499282837,
      0.017836350947618484,
      -0.06817155331373215,
      -0.02558073215186596,
      -0.009202354587614536,
      -0.06272345036268234,
      -0.00895695947110653,
      0.024186355993151665,
      -0.07319920510053635,
      -0.005299182143062353,
      0.0010827723890542984,
      0.06275998800992966,
      -0.003715979168191552,
      0.029868481680750847,
      0.06485651433467865,
      -0.03423826023936272,
      0.050359878689050674,
      -0.01466435194015503,
      0.04961374029517174,
      -0.011759988963603973,
      -0.04330930486321449,
      0.005408331751823425,
      0.006844043266028166,
      -0.025921709835529327,
      -0.0030338044743984938,
      0.004195844288915396,
      -0.030316032469272614,
      -0.003320361953228712,
      -0.005203911103308201,
      -0.008253972046077251,
      -0.008519964292645454,
      0.0005609390791505575,
      -0.0879228487610817,
      0.03270728513598442,
      0.07111896574497223,
      0.06626323610544205,
      0.02674764394760132,
      0.04175221547484398,
      0.07009817659854889,
      0.024158872663974762,
      0.018507743254303932,
      -0.008866378106176853,
      -0.10545133054256439,
      0.07043711841106415,
      -0.033907223492860794,
      0.024919232353568077,
      0.00039862829726189375,
      -0.010878344066441059,
      0.06955578178167343,
      -0.03668331727385521,
      -0.03300222009420395,
      0.07154542952775955,
      0.02911261096596718,
      0.06224111095070839
    ],
    [
      0.0034061612095683813,
      0.015867915004491806,
      0.02363906055688858,
      -0.00767885334789753,
      0.012156517244875431,
      0.04370333254337311,
      -0.07242823392152786,
      -0.07332763075828552,
      0.06808416545391083,
      0.017100444063544273,
      -0.007871272042393684,
      0.004356387536972761,
      0.09070571511983871,
      0.10736740380525589,
      0.04745692014694214,
      0.04066095128655434,
      -0.060085318982601166,
      0.04942018911242485,
      -0.046486109495162964,
      0.07307464629411697,
      0.02605213038623333,
      0.0015345972497016191,
      0.0444243960082531,
      0.03455417975783348,
      -0.048605311661958694,
      -0.006548881996423006,
      -0.03161051124334335,
      -0.01938815228641033,
      0.009301343932747841,
      -0.038738954812288284,
      -0.00146561861038208,
      -0.014105396345257759,
      -0.0044162762351334095,
      -0.025363871827721596,
      0.029102450236678123,
      0.03043414279818535,
      -0.01078968308866024,
      0.019601693376898766,
      0.0008779710624366999,
      0.030848423019051552,
      -0.05878255516290665,
      -0.030655425041913986,
      0.011974907480180264,
      -0.028675172477960587,
      -0.02452365681529045,
      0.01761099509894848,
      -0.02608434669673443,
      -0.0368545837700367,
      0.052952248603105545,
      0.058060433715581894,
      0.09045914560556412,
      0.03228569030761719,
      -0.00541419954970479,
      0.038347069174051285,
      0.05133654922246933,
      0.03218803182244301,
      -0.01680678129196167,
      -0.05007351189851761,
      0.06794006377458572,
      0.013004560023546219,
      0.014604899100959301,
      -0.010545636527240276,
      0.023175477981567383,
      0.05485861748456955,
      0.07132545858621597,
      -0.0036832131445407867,
      -0.004146710503846407,
      0.012863453477621078,
      -0.05128677189350128,
      0.02043650485575199,
      -0.055768370628356934,
      -0.014967989176511765,
      -0.023358607664704323,
      0.039289940148591995,
      -0.014653189107775688,
      -0.07798723876476288,
      0.006897143088281155,
      -0.031394388526678085,
      -0.01577211357653141,
      0.0642082467675209,
      -0.0028774214442819357,
      -0.04423724114894867,
      0.13236795365810394,
      -0.02811126410961151,
      -0.01231500506401062,
      -0.023769810795783997,
      -0.024609331041574478,
      -0.005343772936612368,
      -0.026900233700871468,
      0.04676229506731033,
      -0.033270299434661865,
      0.006379333790391684,
      0.04320145398378372,
      -0.03440604731440544,
      -0.018198659643530846,
      0.08532518148422241,
      -0.05714588612318039,
      -0.03414541855454445,
      0.013419194146990776,
      0.04256941378116608,
      -0.03416907787322998,
      0.023512862622737885,
      0.0036178522277623415,
      -0.033301424235105515,
      0.08238931745290756,
      0.055878691375255585,
      0.023309800773859024,
      0.01237552147358656,
      -0.06017415225505829,
      0.0040802848525345325,
      0.050696201622486115,
      -0.02903343364596367,
      -0.05310041457414627,
      -0.03705497458577156,
      -0.0359959714114666,
      0.00022729979536961764,
      0.039871763437986374,
      0.07711219042539597,
      -0.004900883883237839,
      0.03937928378582001,
      -0.02842465229332447,
      -0.022636761888861656,
      0.009454388171434402,
      0.07780607044696808,
      0.06164747104048729,
      0.016596343368291855,
      0.0023415968753397465,
      0.0019180458039045334,
      0.10432514548301697,
      0.13328765332698822,
      0.022239679470658302,
      -0.03676382452249527,
      0.0075143612921237946,
      -0.048162881284952164,
      -0.06236680969595909,
      0.0667320191860199,
      0.00806533545255661,
      -0.047865625470876694,
      -0.014962668530642986,
      0.043805189430713654,
      0.009179320186376572,
      0.0319102481007576,
      0.04666244238615036,
      0.03278837725520134,
      0.003508790396153927,
      -0.04414224624633789,
      -0.015895605087280273,
      0.05951531603932381,
      0.04079732671380043,
      -0.01833876222372055,
      0.022239195182919502,
      0.04426740109920502,
      0.057266656309366226,
      -0.08447517454624176,
      0.021549148485064507,
      -0.026230566203594208,
      -0.013032103888690472,
      -0.0650649443268776,
      0.02364453300833702,
      0.0018940932350233197,
      -0.014772482216358185,
      0.058867163956165314,
      0.040661901235580444,
      -0.04316093400120735,
      0.0791214257478714,
      -0.029715856537222862,
      -0.00031543156364932656,
      -0.036344848573207855,
      0.029508328065276146,
      -0.02488364651799202,
      -0.025525031611323357,
      0.0048995111137628555,
      -0.06200983375310898,
      0.04520140215754509,
      0.025544477626681328,
      -0.022926557809114456,
      -0.03651224821805954,
      0.0023119263350963593,
      0.03985965624451637,
      0.01549031026661396,
      -0.05151456594467163,
      0.03488263860344887,
      0.04925743490457535,
      0.047385573387145996,
      0.037247434258461,
      -0.014450127258896828,
      -0.030665673315525055,
      0.1171649619936943,
      0.061875902116298676,
      -0.008401326835155487,
      -0.029886825010180473,
      -0.032644033432006836,
      0.0054323417134583,
      -0.04095953330397606,
      0.03963123634457588,
      0.02589729242026806,
      -0.06758350133895874,
      -0.04102271422743797,
      -0.02384938858449459,
      -0.010411725379526615,
      0.024210818111896515,
      -0.014666284434497356,
      -0.004862143192440271,
      0.08892539143562317,
      -0.018527770414948463,
      0.03382250666618347,
      -0.036292798817157745,
      -0.003987236879765987,
      0.016834521666169167,
      0.012357489205896854,
      -0.054699189960956573,
      0.05255669355392456,
      -0.08145015686750412,
      -0.028035061433911324,
      -0.013150372542440891,
      -0.04893483966588974,
      -0.02014664188027382,
      -0.06269068270921707,
      0.05141857638955116,
      0.08870923519134521,
      -0.007777858059853315,
      0.0005252388073131442,
      -0.04045645147562027,
      -0.023326866328716278,
      0.028608031570911407,
      -0.04827661067247391,
      0.00025814108084887266,
      0.02829882688820362,
      0.030251355841755867,
      -0.003509311703965068,
      -0.03272717073559761,
      0.06202206015586853,
      0.027410855516791344,
      0.00237001059576869,
      -0.06949564814567566,
      -0.0034160197246819735,
      -0.07889380306005478,
      -0.033357154577970505,
      0.05400857329368591,
      -0.002524197567254305,
      -0.07963656634092331,
      0.009910685941576958,
      -0.06274693459272385,
      0.046034928411245346,
      -0.007963120006024837,
      0.019638268277049065,
      -0.04340871423482895,
      -0.08712803572416306,
      0.11148683726787567,
      0.03448619320988655,
      0.02118382602930069,
      -0.05834822729229927,
      -0.08172635734081268,
      -0.011768839322030544,
      0.03596043586730957,
      0.055671319365501404,
      -0.011410817503929138,
      0.004049086477607489,
      -0.023809881880879402,
      -0.01294241938740015,
      -0.027424367144703865,
      -0.07547220587730408,
      0.04760367050766945,
      0.07206276059150696,
      -0.04295938089489937,
      0.014339586719870567,
      0.0020912636537104845,
      0.006021552253514528,
      0.0037410210352391005,
      0.13133271038532257,
      -0.0434337854385376,
      -0.11027910560369492,
      0.08739769458770752,
      0.05171791836619377,
      -0.012315148487687111,
      -0.045639943331480026,
      0.0261011254042387,
      0.006378684192895889,
      0.03170027211308479,
      0.05093647167086601,
      -0.025215772911906242,
      0.0044105565175414085,
      0.030821507796645164,
      -0.03303316980600357,
      -0.053349487483501434,
      -0.05781777575612068,
      -0.007644311990588903,
      -0.02706560678780079,
      -0.04282493144273758,
      -0.02685527317225933,
      -0.023246442899107933,
      -0.02388129197061062,
      0.022878257557749748,
      0.021758679300546646,
      -0.018238695338368416,
      0.027793465182185173,
      0.05427301302552223,
      -0.016599126160144806,
      -0.012866612523794174,
      0.06530340760946274,
      0.024540072306990623,
      0.05725619196891785,
      -0.0691249668598175,
      -0.06000877544283867,
      0.05955635383725166,
      -0.047654349356889725,
      -0.0023125826846808195,
      -0.05201487988233566,
      -0.00448586093261838,
      0.010174521245062351,
      0.09151124209165573,
      -0.0667228251695633,
      0.08591383695602417,
      -0.054587606340646744,
      -0.039828646928071976,
      -0.017318205907940865,
      0.015331698581576347,
      -0.054359644651412964,
      -0.04017828777432442,
      0.00366516993381083,
      -0.027081649750471115,
      0.05391589179635048,
      0.05740358307957649,
      -0.012578113004565239,
      0.05279511958360672,
      -0.09729593992233276,
      0.034912023693323135,
      0.029785847291350365,
      -0.005890264641493559,
      -0.02603338286280632,
      0.06038415804505348,
      0.020979849621653557,
      0.09277307242155075,
      -0.019221624359488487,
      0.10086111724376678,
      0.07240768522024155,
      0.0017047252040356398,
      0.01425062958151102,
      0.08599947392940521,
      0.07120534032583237,
      0.025198228657245636,
      0.002142996760085225,
      -0.06594665348529816,
      -0.10081139206886292,
      -0.046019166707992554,
      0.03205687925219536,
      0.015555186197161674,
      -0.0059166294522583485,
      -0.01965567097067833,
      -0.007049757521599531,
      0.017448721453547478,
      -0.005348915234208107,
      0.09533674269914627,
      -0.03292928263545036,
      0.02835940569639206,
      0.030070221051573753,
      0.04490787163376808,
      0.051424961537122726,
      0.05362064018845558,
      -0.08586907386779785,
      -0.024639714509248734,
      0.021086275577545166,
      -0.06924394518136978,
      0.08555883169174194,
      0.06236381083726883,
      -0.07253678143024445,
      -0.051449865102767944,
      -0.035769592970609665,
      -0.08619177341461182,
      -0.0645320937037468,
      0.02447269670665264,
      0.08979523181915283,
      0.021865183487534523,
      0.021230267360806465,
      -0.025239625945687294,
      -0.029591914266347885,
      -0.04771150276064873,
      0.00046403222950175405,
      0.02080586366355419,
      -0.010864658281207085,
      0.017624394968152046,
      -0.043161939829587936,
      -0.06797843426465988,
      0.014634553343057632,
      0.09683451056480408,
      -0.0467691496014595,
      0.02956719882786274,
      0.06844594329595566,
      -0.029245393350720406,
      -0.009130190126597881,
      0.06124419718980789,
      -0.05594464763998985,
      -0.031475115567445755,
      0.002595098689198494,
      0.05236026644706726,
      0.14642120897769928,
      0.06808142364025116,
      0.04916080832481384,
      -0.009496941231191158,
      -0.05938408151268959,
      0.0001319868752034381,
      -0.0075851124711334705,
      -0.04766969382762909,
      0.06434822082519531,
      0.02625683695077896,
      0.036705322563648224,
      -0.01814490184187889,
      -0.04251578450202942,
      0.03679152578115463,
      0.024416901171207428,
      0.08607478439807892,
      0.07124099880456924,
      -0.04252098500728607,
      -0.05873817577958107,
      0.06868518143892288,
      0.05412384122610092,
      0.0015781087568029761,
      0.010201558470726013,
      -0.009946177713572979,
      0.07199311256408691,
      0.0464613176882267,
      -0.024118024855852127,
      -0.03961477056145668,
      -0.058975595980882645,
      -0.043140482157468796,
      0.015108052641153336,
      -0.06764119118452072,
      -0.04900779202580452,
      0.06410892307758331,
      -0.006039959378540516,
      -0.016718221828341484,
      -0.016696227714419365,
      -0.05186983197927475,
      -0.03314538300037384,
      -0.006905585061758757,
      0.04476039111614227,
      0.011500966735184193,
      -0.0024535281118005514,
      -0.006191825959831476,
      0.08518419414758682,
      -0.05613799765706062,
      -0.007076367270201445,
      -0.03136757016181946,
      0.011417956091463566,
      0.02841264009475708,
      -0.11163458228111267,
      -0.09419810026884079,
      -0.042780518531799316,
      0.0746820941567421,
      0.10925467312335968,
      0.010514950379729271,
      0.04414468631148338,
      0.022260578349232674,
      -0.12723901867866516,
      -0.031997740268707275,
      0.023183094337582588,
      -0.0051565105095505714,
      -0.06083868816494942,
      -0.05382530763745308,
      -0.015229740180075169,
      -0.0037738769315183163,
      0.06266690045595169,
      0.013614922761917114,
      -0.02504567615687847,
      -0.06588523834943771,
      -0.058526117354631424,
      0.00022248370805755258,
      -0.02724495530128479,
      0.010624470189213753,
      -0.045409660786390305,
      0.07883459329605103,
      -0.0016773657407611609,
      0.04385659471154213,
      -0.009409227408468723,
      0.07111851125955582,
      -0.05892984941601753,
      0.05784723162651062,
      0.028296304866671562,
      -0.024533327668905258,
      -0.04606420174241066,
      0.02300654537975788,
      -0.009853782132267952,
      0.0007473762962035835,
      -0.015634426847100258,
      -0.11109503358602524,
      -0.040980733931064606,
      0.03288250416517258,
      -0.05740765482187271,
      -0.0035364003852009773,
      -0.061024315655231476,
      -0.034100644290447235,
      -0.028263602405786514,
      -0.061895351856946945,
      0.04122273996472359,
      -0.0007440173649229109,
      0.06513764709234238,
      0.00886639952659607,
      -0.02834080159664154,
      0.040841177105903625,
      -0.005847265012562275,
      -0.05111970007419586,
      -0.017652394250035286,
      0.0482105016708374
    ],
    [
      0.0002618992584757507,
      0.05380646511912346,
      -0.05979045107960701,
      -0.07261613011360168,
      0.051170703023672104,
      -0.05714116618037224,
      -0.030456596985459328,
      0.07736728340387344,
      -0.0865110456943512,
      0.07605656236410141,
      -0.07217562198638916,
      0.025979110971093178,
      -0.04778479039669037,
      0.01255548745393753,
      -0.052361536771059036,
      0.04015205800533295,
      0.07999736070632935,
      -0.0020540887489914894,
      -0.08139001578092575,
      -0.05045200511813164,
      0.039071980863809586,
      -0.051654789596796036,
      0.02787143923342228,
      0.04672868177294731,
      -0.02282293699681759,
      -0.0012605858501046896,
      -0.04829440638422966,
      -0.07575000077486038,
      -0.029222112149000168,
      -0.02122519351541996,
      -0.006898440420627594,
      -0.06293661892414093,
      0.08051139861345291,
      -0.010630755685269833,
      0.033113397657871246,
      0.04404760152101517,
      -0.0545060820877552,
      0.04608987644314766,
      -0.0331934429705143,
      -0.011611333116889,
      -0.036156896501779556,
      -0.002535899868234992,
      -0.04095672070980072,
      -0.03807012364268303,
      -0.05283942073583603,
      0.013074034824967384,
      0.0018269032007083297,
      0.012056635692715645,
      -0.02724083699285984,
      0.014820197597146034,
      -0.008880109526216984,
      -0.046226322650909424,
      -0.018123798072338104,
      -0.02594013884663582,
      0.07993312180042267,
      0.00677078403532505,
      -0.02521761693060398,
      -0.07977502048015594,
      -0.02397959865629673,
      0.001026667538098991,
      -0.06564143300056458,
      0.028513897210359573,
      0.053469784557819366,
      0.0008403703686781228,
      -0.16174474358558655,
      -0.03671678900718689,
      -0.022018788382411003,
      -0.0865737721323967,
      -0.05698641389608383,
      -0.013558168895542622,
      0.04607044905424118,
      -0.03956020250916481,
      0.031484972685575485,
      -0.10113810747861862,
      0.006465240381658077,
      0.01730244606733322,
      0.02451823279261589,
      -0.03752130642533302,
      0.027296924963593483,
      0.05992106720805168,
      -0.006096117198467255,
      -0.0698535144329071,
      -0.022072233259677887,
      -0.013784123584628105,
      0.012373166158795357,
      0.07074154913425446,
      0.0072692581452429295,
      -0.014684182591736317,
      -0.053808633238077164,
      -0.07754704356193542,
      -0.06503672152757645,
      0.009309303015470505,
      -0.013098477385938168,
      0.08423025906085968,
      0.0236638393253088,
      0.07892563194036484,
      -0.05861423909664154,
      -0.005605816375464201,
      0.06898441165685654,
      -0.022596821188926697,
      -0.02100219950079918,
      0.024969011545181274,
      -0.02693294920027256,
      -0.05970633029937744,
      -0.03567751497030258,
      0.027306782081723213,
      0.004351913928985596,
      0.03740827739238739,
      0.0053003220818936825,
      -0.027761314064264297,
      0.05306704714894295,
      -0.02016615681350231,
      0.03722073882818222,
      -0.03923151642084122,
      0.017781641334295273,
      0.03427271172404289,
      -0.02864512801170349,
      -0.0605505108833313,
      -0.012238973751664162,
      -0.01903027482330799,
      0.007455239538103342,
      -0.04500332847237587,
      -0.03108099289238453,
      0.0074506052769720554,
      0.040245749056339264,
      -0.04426504671573639,
      0.026475537568330765,
      -0.04750913754105568,
      -0.09149497747421265,
      0.07588135451078415,
      -0.02885172888636589,
      0.02738076075911522,
      -0.10647853463888168,
      0.008461736142635345,
      -0.02150353230535984,
      -0.0800996646285057,
      0.004774999339133501,
      0.0430489219725132,
      0.0016597097273916006,
      0.04910438880324364,
      0.003027861239388585,
      0.0032001384533941746,
      0.03377951681613922,
      -0.019316183403134346,
      -0.011880716308951378,
      -0.028024611994624138,
      0.01950867660343647,
      -0.02716144174337387,
      0.04180561751127243,
      0.07037106901407242,
      0.056678906083106995,
      0.020785747095942497,
      0.07538340985774994,
      -0.0009103198535740376,
      0.03833666443824768,
      -0.07701744139194489,
      0.055842265486717224,
      -0.03634098917245865,
      -0.023404527455568314,
      -0.04612020403146744,
      0.03400132432579994,
      -0.06194721534848213,
      -0.037307124584913254,
      -0.0602906309068203,
      0.0513145737349987,
      0.07597777247428894,
      -0.057445261627435684,
      0.033697426319122314,
      0.012341685593128204,
      -0.04759730026125908,
      0.016679279506206512,
      0.012369722127914429,
      0.028913719579577446,
      0.03009002096951008,
      -0.015124076046049595,
      -0.0345962755382061,
      -0.030522648245096207,
      -0.024191804230213165,
      0.01774863339960575,
      0.04983650520443916,
      -0.046385329216718674,
      0.06173473969101906,
      0.00976677518337965,
      -0.012581824325025082,
      0.04292900487780571,
      0.041356638073921204,
      -0.10077415406703949,
      -0.0049377307295799255,
      0.0393444299697876,
      0.05030762031674385,
      -0.03808301314711571,
      -0.05484673008322716,
      0.0066873556934297085,
      0.07172580808401108,
      -0.02421596460044384,
      0.0014619177673012018,
      0.061087626963853836,
      -0.05406758189201355,
      -0.051366113126277924,
      -0.017881566658616066,
      -0.0035308743827044964,
      0.058579664677381516,
      0.008733500726521015,
      0.054740142077207565,
      -0.013710486702620983,
      -0.03796989098191261,
      0.03627145290374756,
      0.013843541964888573,
      -0.013582473620772362,
      -0.040481843054294586,
      -0.04424581304192543,
      -0.06873603165149689,
      -0.051768410950899124,
      -0.07979030162096024,
      0.003828221233561635,
      -0.02284819632768631,
      0.013344637118279934,
      -0.02133416198194027,
      0.04112480208277702,
      -0.029083777219057083,
      0.012608584947884083,
      0.015469897538423538,
      -0.05001545697450638,
      0.01801292970776558,
      0.0285236444324255,
      0.005386550910770893,
      -0.05108077451586723,
      0.14475825428962708,
      0.06170579418540001,
      -0.028984252363443375,
      0.0792127326130867,
      -0.04287772998213768,
      0.054196640849113464,
      0.019720813259482384,
      0.007003032602369785,
      0.054049547761678696,
      0.02323530986905098,
      -0.07288870215415955,
      0.0731542780995369,
      -0.026618478819727898,
      -0.0970873087644577,
      0.0065924907103180885,
      -0.02286727912724018,
      -0.0030754171311855316,
      0.06395493447780609,
      0.05491619557142258,
      -0.032778628170490265,
      -0.013225281611084938,
      0.01972571387887001,
      0.05020652338862419,
      0.0231231190264225,
      -0.02098311483860016,
      -0.03639344498515129,
      -0.05065217614173889,
      -0.025000697001814842,
      -0.05454570800065994,
      -0.015616943128407001,
      -0.07017816603183746,
      -0.05005946755409241,
      -0.02773611806333065,
      0.003873260924592614,
      0.05180862545967102,
      -0.0940975472331047,
      0.03485488519072533,
      0.01154639758169651,
      -0.014250424690544605,
      0.02374882623553276,
      -0.019664036110043526,
      -0.051114462316036224,
      -0.07085725665092468,
      -0.024745063856244087,
      0.055322322994470596,
      0.015616361051797867,
      0.04649734124541283,
      -0.1297055333852768,
      -0.005790592636913061,
      0.0500628724694252,
      -0.02319393865764141,
      -0.07904312014579773,
      0.02198224887251854,
      0.03914593905210495,
      -0.043841660022735596,
      0.05606434494256973,
      0.004678328521549702,
      0.13937337696552277,
      0.03179118409752846,
      -0.08465293794870377,
      -0.0014390501892194152,
      0.0012243323726579547,
      0.009440372698009014,
      -0.0044965315610170364,
      0.01553929504007101,
      0.025595301762223244,
      -0.04624852538108826,
      -0.03528691828250885,
      -0.06810986995697021,
      0.06068188324570656,
      0.010464186780154705,
      0.0773141086101532,
      0.1132323294878006,
      -0.039067476987838745,
      -0.0030051455833017826,
      0.12143365293741226,
      -0.023990819230675697,
      0.08207621425390244,
      -0.01546022854745388,
      0.09203369915485382,
      -0.021314110606908798,
      0.06912660598754883,
      -0.01573382504284382,
      -0.01840830221772194,
      -0.0232363510876894,
      -0.015009268186986446,
      0.036606889218091965,
      0.02421209029853344,
      0.04600472003221512,
      0.03327171877026558,
      0.026293888688087463,
      0.013480662368237972,
      0.058622777462005615,
      0.05710512399673462,
      -0.039563439786434174,
      -0.012417782098054886,
      -0.010374290868639946,
      0.058721985667943954,
      0.025728322565555573,
      0.003723992733284831,
      -0.01949792355298996,
      -0.028419939801096916,
      -0.009040802717208862,
      0.018247023224830627,
      -0.03435979783535004,
      -0.14110057055950165,
      -0.016543041914701462,
      0.02325846441090107,
      0.05193665623664856,
      -0.039230622351169586,
      -0.03134385123848915,
      -0.08226518332958221,
      0.015623115934431553,
      0.11566147208213806,
      -0.06520217657089233,
      0.03413677215576172,
      0.023983919993042946,
      -0.0039033375214785337,
      -0.08011464029550552,
      -0.0243533905595541,
      0.01400542538613081,
      -0.008547505363821983,
      -0.018926803022623062,
      -0.01926269382238388,
      -0.05308185890316963,
      -0.015583964064717293,
      -0.09878280013799667,
      -0.041146501898765564,
      0.014335863292217255,
      -0.02723749168217182,
      0.03314561769366264,
      0.0663008764386177,
      0.004818282090127468,
      0.08718149363994598,
      0.020845305174589157,
      -0.039224233478307724,
      -0.013848059810698032,
      -0.01313201803714037,
      0.014255748130381107,
      0.0437772311270237,
      0.022233283147215843,
      0.027900706976652145,
      0.03393692895770073,
      -0.03405637666583061,
      -0.04608532413840294,
      0.055622223764657974,
      -0.06480523198843002,
      -0.022461947053670883,
      0.0467502735555172,
      0.05959713086485863,
      -0.048910073935985565,
      -0.006700331810861826,
      -0.05233662202954292,
      -0.001875158166512847,
      -0.01755044236779213,
      0.013638755306601524,
      0.08468329906463623,
      -0.02390747331082821,
      -0.07374042272567749,
      -0.03777976706624031,
      -0.05007718503475189,
      -0.009825075045228004,
      0.04349132999777794,
      -0.004739528987556696,
      -0.02706546150147915,
      -0.0020970802288502455,
      -0.07909643650054932,
      -0.004849265795201063,
      0.013654215261340141,
      -0.03750535845756531,
      0.04571240022778511,
      -0.005730633158236742,
      -0.006606703624129295,
      -0.015228853560984135,
      -0.01779753342270851,
      0.0483148917555809,
      0.010104693472385406,
      -0.0015264381654560566,
      0.005311640910804272,
      0.020422611385583878,
      0.08825091272592545,
      0.022301562130451202,
      -0.054022207856178284,
      -0.00012329376477282494,
      -0.025475241243839264,
      0.03615308180451393,
      0.018915897235274315,
      -0.0022135514300316572,
      -0.026838378980755806,
      0.00920088216662407,
      -0.014526459388434887,
      -0.09995736181735992,
      0.04894286394119263,
      0.0035394704900681973,
      -0.017441703006625175,
      0.011671296320855618,
      0.053959526121616364,
      -0.010341363027691841,
      0.025851469486951828,
      0.00834883563220501,
      -0.031009944155812263,
      -0.04571066424250603,
      0.037831299006938934,
      -0.07392581552267075,
      -0.018107611685991287,
      -0.05149145796895027,
      0.04361512139439583,
      -0.0009618437616154552,
      -0.03782134875655174,
      0.031510863453149796,
      0.00970862153917551,
      -0.060185838490724564,
      -0.010594693943858147,
      -0.0627359077334404,
      0.06741737574338913,
      0.045230891555547714,
      0.08226113021373749,
      -0.03256354108452797,
      -0.01162673719227314,
      -0.045616261661052704,
      -0.03051549382507801,
      -0.03214763104915619,
      0.00338579248636961,
      -0.0020521299447864294,
      0.037637390196323395,
      0.04547283425927162,
      0.08214671164751053,
      -0.003601932432502508,
      -0.006206868682056665,
      -0.07418948411941528,
      0.005503806751221418,
      -0.03438946232199669,
      0.03255900740623474,
      0.0010528614511713386,
      -0.0806099995970726,
      0.0541582927107811,
      -0.038296423852443695,
      0.05101759359240532,
      0.08740077912807465,
      -0.030987581238150597,
      -0.0005530854687094688,
      0.029477493837475777,
      -0.07694730162620544,
      -0.023878376930952072,
      -0.007969765923917294,
      0.0003441941225901246,
      -0.07900658249855042,
      -0.002079416997730732,
      -0.03697420656681061,
      -0.026051361113786697,
      0.009048466570675373,
      0.07648206502199173,
      0.022296538576483727,
      -0.08964212983846664,
      0.05364914983510971,
      0.03549535572528839,
      -0.061751335859298706,
      -0.001229400746524334,
      0.031025798991322517,
      -0.022467240691184998,
      0.0669056847691536,
      0.0025175688788294792,
      0.011055580340325832,
      0.03211389109492302,
      -0.02653256617486477,
      -0.04524751007556915,
      0.06739179790019989,
      -0.033761098980903625,
      0.05410344898700714,
      -0.02431013435125351,
      -0.08568260818719864,
      -0.003314854111522436,
      0.06875156611204147,
      -0.01521932240575552,
      -0.010055456310510635,
      0.03596818819642067
    ],
    [
      0.07476148009300232,
      0.06433551013469696,
      -0.013269536197185516,
      -0.027358220890164375,
      -0.04523974657058716,
      0.05000697448849678,
      -0.08618655055761337,
      0.0012908023782074451,
      -0.050684187561273575,
      0.0680500715970993,
      -0.0246993750333786,
      -0.047675780951976776,
      -0.019286053255200386,
      -0.02884521521627903,
      -0.05351189523935318,
      -0.015107271261513233,
      0.08154506981372833,
      0.022616907954216003,
      -0.07050012797117233,
      0.019062886014580727,
      0.028200050815939903,
      0.038724109530448914,
      0.003197726793587208,
      -0.010339614003896713,
      0.02680460549890995,
      0.01534917950630188,
      -0.011273220181465149,
      -0.04368923231959343,
      0.028526434674859047,
      -0.005673137493431568,
      0.01398842129856348,
      0.027887772768735886,
      0.061648789793252945,
      0.08213667571544647,
      0.04319149628281593,
      -0.08664688467979431,
      -0.0045780036598443985,
      0.023173991590738297,
      0.047390859574079514,
      -0.01900969073176384,
      0.011770584620535374,
      0.03429841622710228,
      0.017525315284729004,
      0.025376787409186363,
      0.013232973404228687,
      -0.015328717418015003,
      -0.04339264705777168,
      0.020780688151717186,
      0.026625676080584526,
      -0.02968730963766575,
      -0.013903016224503517,
      0.07363515347242355,
      -0.028060307726264,
      0.12778149545192719,
      -0.026574479416012764,
      0.05261225998401642,
      -0.015691673383116722,
      0.03148489072918892,
      -0.06970497965812683,
      -0.033044200390577316,
      -0.014015087857842445,
      0.08239632844924927,
      0.06983166933059692,
      0.023970743641257286,
      0.08845460414886475,
      -0.00407362962141633,
      0.06923005729913712,
      0.07150933146476746,
      0.006893126294016838,
      0.07281135022640228,
      0.04188334941864014,
      0.0017696806462481618,
      -0.040171969681978226,
      -0.012934514321386814,
      0.004265286959707737,
      -0.023145945742726326,
      -0.03836085647344589,
      -0.049884133040905,
      -0.01765882410109043,
      -0.0500512458384037,
      -0.009677732363343239,
      -0.0327720083296299,
      -0.06408967822790146,
      -0.01680637337267399,
      -0.1319887638092041,
      -0.057685136795043945,
      0.013794200494885445,
      0.004972113762050867,
      0.01573534682393074,
      0.005590111017227173,
      -0.026706961914896965,
      0.03286762163043022,
      -0.04062492027878761,
      -0.010294602252542973,
      0.13350994884967804,
      -0.025078872218728065,
      0.058939963579177856,
      -0.040594615042209625,
      -0.005079278722405434,
      0.002932886127382517,
      -0.08729933202266693,
      0.04964437708258629,
      0.047296032309532166,
      -0.036759667098522186,
      0.0034059088211506605,
      -0.0006868258351460099,
      0.059428486973047256,
      -0.029546264559030533,
      -0.034022342413663864,
      -0.01466596033424139,
      0.030200662091374397,
      0.028138024732470512,
      -0.036223284900188446,
      -0.03847326710820198,
      -0.02406640537083149,
      -0.02391989529132843,
      -0.020452259108424187,
      0.004321664571762085,
      -0.03155778720974922,
      -0.013324507512152195,
      -0.05324370786547661,
      -0.03149377182126045,
      0.07454604655504227,
      -0.029904406517744064,
      -0.06704889237880707,
      -0.020409297198057175,
      0.009824282489717007,
      0.02354351058602333,
      0.013879422098398209,
      0.07054050266742706,
      0.053573109209537506,
      0.005276547744870186,
      -0.07458148151636124,
      -0.0012675588950514793,
      0.0362715944647789,
      0.04162587597966194,
      0.003842360805720091,
      0.05567488819360733,
      -0.01797613501548767,
      -0.016990138217806816,
      0.04172013700008392,
      0.003575084265321493,
      -0.1082940548658371,
      0.0295308418571949,
      0.04103413224220276,
      0.06031087413430214,
      -0.04058975726366043,
      0.04716073349118233,
      -0.020751632750034332,
      0.017541563138365746,
      0.00890311785042286,
      0.02346697822213173,
      -0.031859997659921646,
      -0.050807803869247437,
      -0.053227707743644714,
      -0.007606212515383959,
      0.018351778388023376,
      0.02956291288137436,
      0.021524952724575996,
      0.06941679120063782,
      0.048223864287137985,
      -0.03243184834718704,
      -0.051963526755571365,
      -0.0005729032563976943,
      -0.022413579747080803,
      0.0032829362899065018,
      -0.11289522051811218,
      0.0015194020234048367,
      0.033465053886175156,
      -0.09272397309541702,
      0.015944957733154297,
      0.011667104437947273,
      -0.08370820432901382,
      -0.05635249242186546,
      -0.02525206468999386,
      0.07113664597272873,
      0.05424996465444565,
      0.06719079613685608,
      0.0530196912586689,
      -0.02454965002834797,
      0.0015527234645560384,
      -0.03773025423288345,
      -0.003981864545494318,
      0.015021554194390774,
      0.012133290059864521,
      0.005004801321774721,
      -0.03136320412158966,
      -0.04958842694759369,
      0.015735996887087822,
      -0.013553504832088947,
      -0.03685697540640831,
      0.05112069100141525,
      0.0705631822347641,
      0.005157012026757002,
      0.026146331802010536,
      0.02146994136273861,
      0.09878221154212952,
      -0.029489461332559586,
      -0.037880439311265945,
      -0.020417209714651108,
      0.04586963355541229,
      -0.007310447283089161,
      0.024704739451408386,
      -0.02593883126974106,
      -0.049848977476358414,
      0.0006297264480963349,
      0.0436023585498333,
      0.01658649742603302,
      -0.01510078925639391,
      -0.06063999980688095,
      -0.07383384555578232,
      0.018014516681432724,
      0.008116478100419044,
      -0.009081271477043629,
      0.010511571541428566,
      -0.054483700543642044,
      -0.06339477747678757,
      0.06867107003927231,
      0.049687474966049194,
      2.1289883079589345e-05,
      0.05557677522301674,
      0.01492337603121996,
      0.042952992022037506,
      0.04334498569369316,
      0.03360540047287941,
      0.14163890480995178,
      0.06574857234954834,
      0.015271399170160294,
      0.03244127333164215,
      0.04914019629359245,
      0.059732865542173386,
      0.040136609226465225,
      0.07585439085960388,
      0.06092921644449234,
      0.02803657203912735,
      0.01694304123520851,
      0.01259568240493536,
      -0.004537434317171574,
      -0.029827119782567024,
      0.0101828183978796,
      0.037314433604478836,
      0.018405357375741005,
      -0.09685259312391281,
      -0.024485545232892036,
      -0.00010340155859012157,
      0.042137645184993744,
      -0.03790103644132614,
      -0.01987691968679428,
      0.02370350994169712,
      0.02740425430238247,
      0.015050110407173634,
      -0.0038286694325506687,
      -0.05947033688426018,
      -0.014642206951975822,
      0.005142692942172289,
      -0.08666189759969711,
      0.024532023817300797,
      -0.019052131101489067,
      -0.025087041780352592,
      0.035808078944683075,
      0.050368186086416245,
      -0.008376399986445904,
      -0.1130257248878479,
      -0.092838816344738,
      -0.03840598836541176,
      0.024888643994927406,
      0.08030377328395844,
      0.01129379402846098,
      0.0050573162734508514,
      -0.009005093015730381,
      -0.04652132838964462,
      -0.048842936754226685,
      -0.05270292982459068,
      -0.06537649780511856,
      0.016363585367798805,
      -0.018187692388892174,
      0.09820393472909927,
      -0.038408778607845306,
      -0.06476300954818726,
      -0.016382670029997826,
      0.03506157919764519,
      -0.05690757930278778,
      0.05908941477537155,
      0.043832533061504364,
      -0.0333113856613636,
      0.03156892955303192,
      0.021880606189370155,
      -0.02107055112719536,
      0.036047935485839844,
      -0.07459656894207001,
      0.01952948607504368,
      0.014449670910835266,
      0.025703078135848045,
      -0.01520001981407404,
      0.02143710106611252,
      0.04507719352841377,
      -0.03469555824995041,
      0.00823033507913351,
      -0.03174406290054321,
      -0.06755382567644119,
      0.0895390585064888,
      0.08277370780706406,
      0.011975795030593872,
      -0.008923478424549103,
      0.052092842757701874,
      -0.08048640936613083,
      0.04216304048895836,
      -0.026790855452418327,
      0.018057914450764656,
      -0.02685636095702648,
      -0.0012624308001250029,
      0.05373850837349892,
      -0.06918060034513474,
      0.1052711084485054,
      -0.04807624965906143,
      0.004165997263044119,
      -0.0241645947098732,
      0.02451658807694912,
      -0.05597887560725212,
      -0.08161734789609909,
      -0.05600650981068611,
      -0.0075817350298166275,
      0.012766854837536812,
      0.051646485924720764,
      0.06919949501752853,
      -0.04365965351462364,
      0.047232527285814285,
      -0.02665041573345661,
      -0.06611233949661255,
      -0.08780663460493088,
      0.00047952422755770385,
      0.04040319845080376,
      0.05297210067510605,
      -0.019135868176817894,
      0.001496184733696282,
      -0.05508086085319519,
      0.032578013837337494,
      0.0020313996355980635,
      0.013283896259963512,
      0.03276189789175987,
      0.004029081668704748,
      -0.027321068570017815,
      -0.01489601843059063,
      -0.08470979332923889,
      0.012100782245397568,
      0.06987470388412476,
      -0.022052939981222153,
      0.019765187054872513,
      -0.02636127360165119,
      -0.07423887401819229,
      -0.03286709636449814,
      0.037796251475811005,
      0.04361527040600777,
      -0.07144701480865479,
      0.0014425944536924362,
      -0.07338598370552063,
      0.04806021228432655,
      0.07235461473464966,
      -0.04574389010667801,
      -0.003511624177917838,
      0.030578281730413437,
      -0.02313220500946045,
      -0.040282897651195526,
      -0.04354742169380188,
      -0.014639275148510933,
      -0.01242485549300909,
      -0.0222649984061718,
      -0.015048290602862835,
      0.056298043578863144,
      -0.0001298733550356701,
      0.07866261154413223,
      0.061023421585559845,
      -0.07200837880373001,
      -0.06199512258172035,
      0.02147243358194828,
      0.006979798898100853,
      0.047927118837833405,
      0.08765257894992828,
      0.04005422070622444,
      0.005528303328901529,
      -0.003651602193713188,
      0.03832061588764191,
      0.0199459046125412,
      -0.0205508004873991,
      -0.06306397914886475,
      0.07952922582626343,
      0.04820664972066879,
      -0.01956494152545929,
      -0.016283191740512848,
      0.04567210003733635,
      -0.09979617595672607,
      -0.06285638362169266,
      0.09181009232997894,
      -0.048709217458963394,
      0.008174804039299488,
      0.0030624535866081715,
      -0.013342482037842274,
      -0.027632879093289375,
      0.0267013106495142,
      -0.07942920923233032,
      -0.033233530819416046,
      0.03698170185089111,
      -0.02463696151971817,
      0.026185471564531326,
      -0.09752131253480911,
      0.018406257033348083,
      -0.05284940078854561,
      0.03333524614572525,
      0.020399125292897224,
      0.021559659391641617,
      0.05521251633763313,
      0.002497520763427019,
      -0.01881278119981289,
      0.036246251314878464,
      -0.022617829963564873,
      0.10714635998010635,
      0.013579296879470348,
      -0.015158325433731079,
      0.07763291895389557,
      0.07890226691961288,
      0.02117208018898964,
      0.02705257013440132,
      0.024395111948251724,
      0.0151714151725173,
      -0.002345557091757655,
      0.020981300622224808,
      -0.00010949906572932377,
      -0.021091528236865997,
      -0.03047523833811283,
      0.029548902064561844,
      0.005933847744017839,
      0.05585094541311264,
      -0.020635617896914482,
      -0.05143386498093605,
      -0.005449464078992605,
      0.03373868390917778,
      0.004644868429750204,
      0.003249949309974909,
      0.06307553499937057,
      0.06178393214941025,
      0.05883520096540451,
      -0.016899527981877327,
      0.011904960498213768,
      -0.006063774693757296,
      0.0651213675737381,
      0.061311446130275726,
      -0.0753980427980423,
      -0.027156809344887733,
      -0.031570836901664734,
      0.040042176842689514,
      0.013906555250287056,
      -0.04217904061079025,
      -0.027860108762979507,
      -0.03761054575443268,
      -0.03226066380739212,
      0.05893314257264137,
      0.0032154496293514967,
      -0.0063394708558917046,
      -0.026689372956752777,
      0.00555380480363965,
      -0.11221704632043839,
      0.04194170981645584,
      0.005949043203145266,
      -0.023675523698329926,
      0.07651323080062866,
      0.07842465490102768,
      -0.012085428461432457,
      0.021530525758862495,
      -0.027081754058599472,
      -0.09007056057453156,
      0.011000804603099823,
      0.010097377933561802,
      0.024580903351306915,
      0.0007738778367638588,
      0.03191838413476944,
      0.011047173291444778,
      -0.014686922542750835,
      0.03942796215415001,
      -0.06463474780321121,
      0.06525596976280212,
      -0.0400022529065609,
      0.102619469165802,
      0.06503862142562866,
      0.10434897989034653,
      0.0651654452085495,
      -0.05295908451080322,
      -0.011737692169845104,
      0.16378554701805115,
      0.002977930009365082,
      -0.02112823911011219,
      -0.004551926627755165,
      0.017099928110837936,
      0.03674085810780525,
      0.017564019188284874,
      0.039012983441352844,
      -0.0012511694803833961,
      -0.01744847372174263,
      -0.010842697694897652,
      0.044205665588378906,
      0.024481000378727913,
      -0.023237839341163635,
      0.025851810351014137,
      -0.058427561074495316
    ],
    [
      0.032097164541482925,
      0.036548588424921036,
      -0.04342962056398392,
      -0.03567841649055481,
      -0.003008041763678193,
      0.032332081347703934,
      -0.07390877604484558,
      0.003992342855781317,
      0.04671816527843475,
      0.02828483283519745,
      0.12473799288272858,
      0.012776283547282219,
      0.08616694808006287,
      -0.051276955753564835,
      -0.009395421482622623,
      0.016008542850613594,
      0.02351028099656105,
      -0.045210547745227814,
      -0.079262375831604,
      -0.050293032079935074,
      -0.04535224661231041,
      -0.05646924301981926,
      0.000731837353669107,
      0.002123691840097308,
      -0.05516308546066284,
      0.023773932829499245,
      -0.050098076462745667,
      0.009514921344816685,
      -0.009305396117269993,
      0.03184977546334267,
      0.023116638883948326,
      -0.01497095450758934,
      -0.04421006888151169,
      -0.04810681939125061,
      0.03736364468932152,
      -0.04343840107321739,
      -0.03991617262363434,
      0.02252350002527237,
      0.07169802486896515,
      0.0589405819773674,
      0.07055563479661942,
      -0.00023261024034582078,
      -0.08940240740776062,
      0.052547600120306015,
      0.02179616317152977,
      -0.025290731340646744,
      -0.00803370587527752,
      -0.0576847605407238,
      0.04119620844721794,
      -0.06227194145321846,
      0.06884346902370453,
      -0.012572318315505981,
      0.0214688703417778,
      -0.07257203012704849,
      -0.005984093062579632,
      0.0041524083353579044,
      -0.04343021288514137,
      0.035163763910532,
      -0.028256939724087715,
      0.07447625696659088,
      -0.01515418291091919,
      0.0765330120921135,
      -0.07544704526662827,
      -0.057406626641750336,
      0.00545550137758255,
      -0.12213844805955887,
      -0.03516050800681114,
      0.03372839838266373,
      0.025658588856458664,
      0.03162068873643875,
      -0.01489381492137909,
      0.07090999186038971,
      -0.05148203670978546,
      0.025524970144033432,
      -0.028287187218666077,
      -0.02566811814904213,
      0.020575787872076035,
      0.0633571594953537,
      -0.05361638963222504,
      0.0095920879393816,
      0.05731949582695961,
      0.031184827908873558,
      0.07311587035655975,
      0.03062610886991024,
      -0.09699829667806625,
      0.027338987216353416,
      0.0008336618775501847,
      -0.06028740480542183,
      0.005057854112237692,
      0.06499284505844116,
      -0.01353175938129425,
      0.011433613486588001,
      -0.0030962000600993633,
      -0.003771369345486164,
      -0.06935298442840576,
      -0.004612077493220568,
      -0.046966131776571274,
      0.015539457090198994,
      -0.03873786702752113,
      0.03137409687042236,
      -0.010296186432242393,
      -0.02152932435274124,
      0.01584419421851635,
      -0.055220793932676315,
      -0.018507109954953194,
      -0.01704401895403862,
      -0.0842011496424675,
      0.031041894108057022,
      -0.01933681219816208,
      -0.04631146416068077,
      -0.021794188767671585,
      0.055092744529247284,
      -0.062112607061862946,
      -0.008200917392969131,
      -0.042535774409770966,
      0.04370494931936264,
      -0.052728936076164246,
      0.01367638073861599,
      0.04987701028585434,
      -0.013812432065606117,
      -0.061027321964502335,
      -0.006671521812677383,
      0.009247041307389736,
      0.037778619676828384,
      -0.01781325414776802,
      -0.011551681905984879,
      -0.03063066117465496,
      -0.09719805419445038,
      -0.07431703060865402,
      0.031502820551395416,
      0.015594293363392353,
      -0.027171915397047997,
      0.0028672057669609785,
      0.03918314725160599,
      0.02757161483168602,
      -0.04561322182416916,
      -0.040270477533340454,
      0.04802660271525383,
      -0.046412065625190735,
      -0.029773524031043053,
      0.04887903854250908,
      -0.09020144492387772,
      0.021477818489074707,
      0.0831993892788887,
      0.0434260256588459,
      0.014032182283699512,
      -0.025430575013160706,
      -0.03348930552601814,
      -0.035115279257297516,
      0.012726099230349064,
      -0.07813411951065063,
      0.012570761144161224,
      0.06577551364898682,
      0.020765407010912895,
      0.02557498775422573,
      -0.041586775332689285,
      0.03560354933142662,
      0.05528262257575989,
      -0.008078118786215782,
      0.0620809867978096,
      0.007085825782269239,
      -0.05212737247347832,
      0.029631687328219414,
      0.0658482015132904,
      0.09768539667129517,
      0.020697493106126785,
      -0.0997411385178566,
      0.06344230473041534,
      0.03453080356121063,
      0.034529123455286026,
      -0.07177967578172684,
      -0.012581377290189266,
      -0.05091448873281479,
      -0.006853742990642786,
      -0.02923939935863018,
      0.04469381645321846,
      0.10252896696329117,
      0.001988058676943183,
      -0.10105595737695694,
      -0.024805359542369843,
      -0.0393729992210865,
      0.022933712229132652,
      -0.012718413025140762,
      -0.04295407235622406,
      0.032141316682100296,
      0.020577572286128998,
      -0.142296701669693,
      0.03704622760415077,
      0.01596016250550747,
      -0.026569755747914314,
      0.03561494126915932,
      -0.050861962139606476,
      -0.10948304831981659,
      -0.024497028440237045,
      0.013396177440881729,
      0.00819135457277298,
      -0.032525595277547836,
      -0.058430466800928116,
      -0.0007092974265106022,
      -0.11746927350759506,
      0.0018288238206878304,
      0.005879535339772701,
      0.0211293026804924,
      0.07810713350772858,
      -0.0556161105632782,
      0.07869549840688705,
      0.05921774357557297,
      -0.019639287143945694,
      -0.059218671172857285,
      -0.013887614943087101,
      0.01574721559882164,
      -0.001043083262629807,
      -0.02060532197356224,
      -0.015287862159311771,
      -0.04542502760887146,
      0.03846991807222366,
      -0.07244536280632019,
      0.05216124653816223,
      0.018103372305631638,
      -0.03439760208129883,
      0.03300854563713074,
      0.029817046597599983,
      -0.08747632801532745,
      -0.07351741194725037,
      0.041083283722400665,
      -0.004803006537258625,
      -0.006399656180292368,
      -0.016190139576792717,
      0.07766585052013397,
      0.09736296534538269,
      0.0557127483189106,
      0.037813473492860794,
      0.01977584883570671,
      0.07359839975833893,
      -0.05739513412117958,
      -0.00814021099358797,
      0.06499070674180984,
      0.02348209172487259,
      -0.050834186375141144,
      0.004170391708612442,
      0.044652245938777924,
      0.015756510198116302,
      0.02756757289171219,
      -0.025795066729187965,
      -0.007909499108791351,
      -0.005352023523300886,
      -0.04957228899002075,
      0.039136409759521484,
      -0.05229910835623741,
      0.034374434500932693,
      -0.08279699832201004,
      -0.022454887628555298,
      -0.005282532889395952,
      -0.04862337186932564,
      0.03323790803551674,
      0.07796020805835724,
      0.04389737546443939,
      -0.009940662421286106,
      -0.005670856684446335,
      -0.003129040589556098,
      -0.0025576967746019363,
      0.0050728535279631615,
      0.018245425075292587,
      -0.014506912790238857,
      -0.05807486176490784,
      1.1188361895619892e-05,
      -0.04014194756746292,
      0.09463685005903244,
      -0.01645566336810589,
      -0.008641835302114487,
      -0.035041693598032,
      -0.08715958148241043,
      -0.012323404662311077,
      -0.053742483258247375,
      -0.004113658796995878,
      -0.027739563956856728,
      -0.0818699300289154,
      -0.03621218353509903,
      -0.04674559831619263,
      -0.02553512528538704,
      0.0010404877830296755,
      0.009517197497189045,
      0.09455843269824982,
      -0.03579685091972351,
      0.09484604001045227,
      -0.010908584110438824,
      0.056785691529512405,
      -0.11021079868078232,
      0.033367786556482315,
      -0.03791961073875427,
      -0.023838942870497704,
      0.10955014824867249,
      -0.05127204582095146,
      0.017494281753897667,
      -0.01783190667629242,
      -0.08487313240766525,
      0.008188820444047451,
      0.0379863977432251,
      -0.06054334715008736,
      0.02378460206091404,
      -0.013770317658782005,
      0.0399228036403656,
      0.06994495540857315,
      0.03158581256866455,
      -0.10158481448888779,
      0.01660272292792797,
      -0.006858740467578173,
      0.03724571317434311,
      0.012473830953240395,
      -0.006970489397644997,
      -0.08411029726266861,
      -0.0452553816139698,
      -0.05654061213135719,
      -0.07472294569015503,
      -0.012539763934910297,
      0.07713858783245087,
      -0.04204759746789932,
      0.018441639840602875,
      -0.007060205098241568,
      -0.05980154126882553,
      0.03717557340860367,
      0.026937320828437805,
      0.02383319102227688,
      -0.021943068131804466,
      0.0723564401268959,
      -0.060316476970911026,
      -0.015223168767988682,
      -0.09005533903837204,
      -0.031330011785030365,
      -0.04198254644870758,
      0.04046051576733589,
      -0.06417513638734818,
      -0.046144962310791016,
      0.05688069015741348,
      -0.07981806248426437,
      -0.03714408725500107,
      0.003088568104431033,
      -0.040233105421066284,
      -0.03787543997168541,
      0.03674514591693878,
      -0.05558278411626816,
      -0.03986962139606476,
      0.037801992148160934,
      -0.0008164501050487161,
      0.03937572240829468,
      0.05365994945168495,
      -0.04029034823179245,
      0.022763647139072418,
      0.03870783746242523,
      -0.06935460865497589,
      0.0020382748916745186,
      -0.041300710290670395,
      -0.04654628783464432,
      0.004303532652556896,
      0.019961444661021233,
      -0.019067775458097458,
      0.02424640953540802,
      0.12053723633289337,
      -0.0070749009028077126,
      -0.061995070427656174,
      -0.015027938410639763,
      0.05774445831775665,
      -0.005798544734716415,
      0.03130967915058136,
      0.032178014516830444,
      0.020294470712542534,
      -0.024194348603487015,
      -0.01712072640657425,
      -0.02609328366816044,
      -0.03622569888830185,
      -0.045010317116975784,
      0.03276119753718376,
      0.004918358754366636,
      0.025327526032924652,
      0.021797742694616318,
      -0.015064693056046963,
      0.05205722153186798,
      -0.03521043807268143,
      0.021391024813055992,
      0.027270076796412468,
      0.03927019238471985,
      -0.044676389545202255,
      -0.053529076278209686,
      0.0347750298678875,
      0.01992436684668064,
      -0.03795046731829643,
      -0.04757106676697731,
      -0.021391725167632103,
      -0.05096137896180153,
      -0.08414758741855621,
      -0.037172816693782806,
      0.026432329788804054,
      0.04162483662366867,
      0.01681404747068882,
      0.026250923052430153,
      0.022194162011146545,
      -0.07177095860242844,
      0.07515771687030792,
      -0.004369980655610561,
      0.016673991456627846,
      0.010178512893617153,
      0.011062640696763992,
      -0.015354407951235771,
      -0.014547198079526424,
      0.03356560319662094,
      -0.03627330809831619,
      -0.0035677419509738684,
      -0.06133069470524788,
      0.011890829540789127,
      0.007371554616838694,
      -0.05779449641704559,
      -0.023490965366363525,
      -0.004563298542052507,
      -0.0024799425154924393,
      -0.07301405817270279,
      0.023236099630594254,
      -0.010328744538128376,
      0.046080272644758224,
      -0.02467099390923977,
      0.059080056846141815,
      0.026107169687747955,
      0.04835454374551773,
      -0.010741742327809334,
      -0.02258683554828167,
      0.0018674751045182347,
      -0.03957032412290573,
      0.011060940101742744,
      0.0029288092628121376,
      -0.009511127136647701,
      -0.12221449613571167,
      -0.013147364370524883,
      -0.018698882311582565,
      0.02294347621500492,
      0.0029937729705125093,
      -0.054305534809827805,
      -0.044260162860155106,
      -0.016313772648572922,
      -0.0861523225903511,
      -0.023202087730169296,
      -0.03238401561975479,
      -0.005301190074533224,
      0.05876166746020317,
      -0.004771044477820396,
      -0.005011181812733412,
      0.007042348850518465,
      0.002425766084343195,
      -0.037634097039699554,
      -0.015215557999908924,
      -0.03162715584039688,
      -0.05380484461784363,
      -0.06273166835308075,
      0.028861986473202705,
      0.007601499557495117,
      0.06452356278896332,
      -0.008125833235681057,
      0.01587565615773201,
      0.036711301654577255,
      0.03275154531002045,
      0.03267666697502136,
      -0.006146376021206379,
      -0.10600196570158005,
      0.13100822269916534,
      -0.029249945655465126,
      -0.040168553590774536,
      -0.0007566390559077263,
      -0.04550949111580849,
      -0.06141987442970276,
      -0.04460329934954643,
      0.002726482693105936,
      -0.021753419190645218,
      -0.0017551964847370982,
      0.018372973427176476,
      -0.0764227956533432,
      -0.06577011197805405,
      -0.04793504625558853,
      0.04121454432606697,
      0.01891409233212471,
      -0.04930773004889488,
      -0.017192931845784187,
      0.020503975450992584,
      0.052760642021894455,
      -0.016645144671201706,
      0.050667282193899155,
      -0.07262881845235825,
      0.017199628055095673,
      -0.06012429669499397,
      0.03140687197446823,
      -0.016305765137076378,
      0.006797999143600464,
      0.07619961351156235,
      -0.043109845370054245,
      0.037617944180965424,
      -0.1117694303393364,
      -0.04085827246308327,
      0.03504583239555359,
      0.040281254798173904,
      0.016604110598564148,
      -0.06523384153842926,
      0.06122631952166557,
      0.09382517635822296,
      -0.009944264777004719,
      0.011106377467513084,
      -0.004561101086437702
    ],
    [
      -0.028504351153969765,
      -0.019426710903644562,
      0.030170246958732605,
      0.036201272159814835,
      -0.00955549068748951,
      -0.043199021369218826,
      -0.07841222733259201,
      0.0643983855843544,
      0.03673643246293068,
      -0.036976590752601624,
      0.017705315724015236,
      -0.03687283396720886,
      -0.009961875155568123,
      0.018288763239979744,
      0.014329588040709496,
      0.04623330011963844,
      0.006025119684636593,
      0.024009203538298607,
      -0.07063886523246765,
      0.07792279869318008,
      -0.06560028344392776,
      0.02205047570168972,
      0.09882257878780365,
      -0.010351001285016537,
      -0.023170432075858116,
      0.0758790373802185,
      0.03345894068479538,
      -0.037001077085733414,
      0.009814814664423466,
      -0.018492793664336205,
      -0.018661241978406906,
      0.03251606225967407,
      -0.055771004408597946,
      -0.05418042838573456,
      -0.012008363381028175,
      -0.08455944061279297,
      -0.008434870280325413,
      0.021201638504862785,
      -0.07303054630756378,
      0.032943833619356155,
      -0.02533312328159809,
      -0.04970052093267441,
      -0.0354006327688694,
      0.0031815022230148315,
      0.05474508926272392,
      -0.02259651944041252,
      -0.022607669234275818,
      -0.001822631573304534,
      -0.002927368273958564,
      -0.0034064750652760267,
      -0.09135954082012177,
      0.0635947659611702,
      -0.09547479450702667,
      0.008119693957269192,
      0.014404946006834507,
      0.02533203549683094,
      -0.011810180731117725,
      0.08712638169527054,
      0.008423276245594025,
      0.016362247988581657,
      0.005252906121313572,
      -0.022203223779797554,
      0.08427099883556366,
      0.015508738346397877,
      -0.009632733650505543,
      0.027070987969636917,
      -0.058331262320280075,
      -0.01673736236989498,
      0.07420588284730911,
      0.07640378922224045,
      -0.0029093637131154537,
      0.019217273220419884,
      -0.0015316666103899479,
      0.029511597007513046,
      -0.03340398520231247,
      0.004739674739539623,
      -0.06526884436607361,
      0.04537297785282135,
      -0.019801663234829903,
      -0.02945602871477604,
      -0.016109859570860863,
      -0.03709091618657112,
      -0.07079635560512543,
      -0.0011618775315582752,
      -0.0013505658134818077,
      0.020179562270641327,
      0.00479622557759285,
      -0.11270800977945328,
      0.02579728700220585,
      0.03359222039580345,
      -0.005564230028539896,
      0.05408868566155434,
      -0.022547893226146698,
      -0.023820068687200546,
      -0.0051920125260949135,
      0.05784262344241142,
      -0.06455762684345245,
      -0.0327770933508873,
      -0.017237024381756783,
      -0.031163642182946205,
      -0.04246291145682335,
      -0.0381174236536026,
      0.029206015169620514,
      -0.11943574994802475,
      0.03580564633011818,
      -0.007967050187289715,
      -0.1027727723121643,
      -0.05666143074631691,
      -0.04268103092908859,
      -0.013050707057118416,
      0.08116697520017624,
      0.05362527444958687,
      -0.10290219634771347,
      0.049608562141656876,
      0.0121317682787776,
      0.03796207904815674,
      -0.006632540374994278,
      0.030291981995105743,
      0.0029845070093870163,
      -0.014015830121934414,
      0.01659839041531086,
      -0.005717724561691284,
      -0.01667783595621586,
      -0.03662097081542015,
      0.03186267241835594,
      -0.021976104006171227,
      0.05891122668981552,
      -0.08137301355600357,
      0.033485736697912216,
      0.058832861483097076,
      -0.002943511586636305,
      -0.004826799035072327,
      0.048590440303087234,
      -0.0018665025709196925,
      -0.05789078399538994,
      0.00018141309556085616,
      -0.014123515225946903,
      -0.02495393715798855,
      -0.07954657822847366,
      0.0405648909509182,
      -0.015693683177232742,
      -0.08112328499555588,
      0.10019850730895996,
      -0.07078836858272552,
      0.04895847663283348,
      0.015578382648527622,
      -0.03680071234703064,
      0.1169910654425621,
      -0.023545775562524796,
      -0.054947130382061005,
      -0.03978471830487251,
      0.035204242914915085,
      0.021180443465709686,
      -0.02969939075410366,
      -0.02297247014939785,
      -0.04919518902897835,
      -0.03145072981715202,
      0.0029100743122398853,
      0.0716203823685646,
      0.017426230013370514,
      0.025765404105186462,
      -0.033158622682094574,
      -0.009690400213003159,
      -0.03367062658071518,
      0.03136821463704109,
      0.10618019849061966,
      -0.005051542539149523,
      0.08810962736606598,
      0.005101753398776054,
      0.027900271117687225,
      -0.017352599650621414,
      0.03449198231101036,
      -0.05779491364955902,
      -0.014429883100092411,
      0.02529231458902359,
      0.0730665922164917,
      0.018178420141339302,
      0.011123459786176682,
      -0.007791151292622089,
      0.04368090257048607,
      -0.01949300244450569,
      -0.04982754588127136,
      0.014087343588471413,
      0.02020391635596752,
      0.005696502514183521,
      -0.01361623965203762,
      0.00031903726630844176,
      -0.01631426066160202,
      -0.00012014228559564799,
      -0.0545085184276104,
      0.015663333237171173,
      0.0002889429742936045,
      0.06606286019086838,
      -0.08699245005846024,
      -0.0757233202457428,
      0.030474398285150528,
      0.015416133217513561,
      -0.0837821364402771,
      -0.006539479363709688,
      -0.0671258196234703,
      -0.004349851980805397,
      0.023832201957702637,
      -0.01931576617062092,
      0.08615005016326904,
      -0.013658454641699791,
      -0.017129888758063316,
      0.11013287305831909,
      0.053729113191366196,
      -0.0029580381233245134,
      0.042842697352170944,
      -0.02081269398331642,
      -0.008337539620697498,
      -0.08031295239925385,
      0.036904267966747284,
      0.06006813421845436,
      0.023032967001199722,
      0.01979268155992031,
      0.039482805877923965,
      0.09790488332509995,
      -0.007000157609581947,
      -0.09306071698665619,
      0.0054970490746200085,
      -0.014833120629191399,
      -0.030117467045783997,
      0.012702051550149918,
      -0.02090444043278694,
      0.03911840170621872,
      -0.0169171504676342,
      -0.0007460371125489473,
      0.07600107789039612,
      0.02085580863058567,
      0.0129574378952384,
      -0.026137206703424454,
      -0.007025111000984907,
      -0.061194729059934616,
      0.02920088730752468,
      -0.004835666157305241,
      0.0032581789419054985,
      -0.04863511770963669,
      0.03076762706041336,
      0.0006786510348320007,
      0.03316079080104828,
      0.03185819461941719,
      -0.024192966520786285,
      -0.04265191778540611,
      0.04665067046880722,
      -0.029129719361662865,
      -0.022323407232761383,
      -0.0017071758629754186,
      0.07007762789726257,
      0.016095178201794624,
      0.013924979604780674,
      -0.01185660157352686,
      0.005680155009031296,
      0.010933849029242992,
      -0.07136249542236328,
      -0.07775357365608215,
      0.0011132975341752172,
      -0.03412787988781929,
      -0.03735867515206337,
      -0.02304110862314701,
      0.025281306356191635,
      -0.03315228223800659,
      0.004210133105516434,
      -0.023979678750038147,
      -0.005699606612324715,
      -0.060953158885240555,
      0.10351516306400299,
      -0.07827775180339813,
      -0.04153968393802643,
      0.0030223673675209284,
      -0.0029295848216861486,
      0.047174252569675446,
      -0.04065380245447159,
      -0.010309410281479359,
      0.006419515237212181,
      -0.009872009977698326,
      0.002951094415038824,
      -0.003994880244135857,
      0.005821570288389921,
      -0.051162298768758774,
      0.029539640992879868,
      -0.016547679901123047,
      0.05149770528078079,
      0.03815091773867607,
      -0.027075134217739105,
      0.018564963713288307,
      -0.010074435733258724,
      0.059825796633958817,
      -0.0035710276570171118,
      0.03724285215139389,
      -0.07008683681488037,
      0.024423429742455482,
      -0.06187305971980095,
      -0.023133909329771996,
      -0.10128004848957062,
      0.0021108314394950867,
      0.029825503006577492,
      -0.023210112005472183,
      -0.047118186950683594,
      0.008171404711902142,
      -0.025424139574170113,
      0.006220104172825813,
      0.032265931367874146,
      -0.024490566924214363,
      -0.11922135949134827,
      0.03776305541396141,
      0.036399248987436295,
      0.033172301948070526,
      -0.04890759661793709,
      -0.01802138239145279,
      -0.016312362626194954,
      0.04644833505153656,
      -0.06981530040502548,
      -0.018672246485948563,
      0.02301756665110588,
      -0.04165268689393997,
      0.04503035172820091,
      -0.07350756227970123,
      0.0008724155486561358,
      0.05388245731592178,
      -0.01018857304006815,
      0.0724998489022255,
      0.031289294362068176,
      0.06844130903482437,
      -0.08031369000673294,
      0.04773969575762749,
      -0.01835102029144764,
      -0.0004191672196611762,
      -0.011709683574736118,
      0.055769093334674835,
      -0.012438002973794937,
      -0.07117386162281036,
      0.044000670313835144,
      -0.0022643483243882656,
      -0.012661588378250599,
      -0.0564911812543869,
      0.062420137226581573,
      -0.022328708320856094,
      -0.013934042304754257,
      -0.029108745977282524,
      -0.058051466941833496,
      -0.01962081901729107,
      -0.014836235903203487,
      0.05945999547839165,
      0.022417431697249413,
      -0.012032869271934032,
      -0.046399135142564774,
      0.06510252505540848,
      0.05784466490149498,
      -0.008115537464618683,
      0.05590330809354782,
      0.07762248069047928,
      -0.0007598836673423648,
      0.029353588819503784,
      0.04811510071158409,
      0.017309391871094704,
      0.03858885541558266,
      -0.07539670914411545,
      -0.003783915191888809,
      0.07120125740766525,
      -0.016733741387724876,
      0.024975407868623734,
      -0.00957455299794674,
      0.05045631155371666,
      0.017667915672063828,
      0.002642232459038496,
      0.05340735241770744,
      0.03574769198894501,
      0.012721958570182323,
      -0.006932016462087631,
      0.02092435210943222,
      0.06447421759366989,
      -0.009699302725493908,
      -0.0405450202524662,
      -0.05349148064851761,
      -0.02677561342716217,
      0.01916128396987915,
      0.030853215605020523,
      0.05334145203232765,
      0.04225184768438339,
      -0.034734394401311874,
      0.03247760236263275,
      0.021325377747416496,
      -0.024576367810368538,
      0.06312763690948486,
      0.0005607683560810983,
      0.03409823030233383,
      0.016369828954339027,
      0.017446748912334442,
      -0.03848696127533913,
      -0.059169042855501175,
      -0.006665178574621677,
      -0.036844030022621155,
      0.011972777545452118,
      0.013253399170935154,
      -0.016208160668611526,
      0.06813099980354309,
      0.022807737812399864,
      0.004249523393809795,
      0.027973681688308716,
      0.017579125240445137,
      -0.03840072453022003,
      -0.03759022429585457,
      -0.021180348470807076,
      -0.031225528568029404,
      -0.08303581923246384,
      0.008024057373404503,
      0.08975014090538025,
      -0.005881584249436855,
      -0.043324194848537445,
      0.0011928868480026722,
      0.04138824716210365,
      0.024736637249588966,
      0.021527951583266258,
      -0.0035270897205919027,
      0.005316479597240686,
      -0.07370367646217346,
      -0.05406824126839638,
      -0.03154308721423149,
      -0.03353406488895416,
      -0.04372439533472061,
      -0.051749035716056824,
      0.029972685500979424,
      -0.011598396115005016,
      0.003903430188074708,
      0.0026623839512467384,
      0.021565120667219162,
      -0.0923142209649086,
      -0.02313932403922081,
      -0.01856415905058384,
      0.009574138559401035,
      0.01693801023066044,
      -0.0358700156211853,
      0.07285609841346741,
      0.02429884299635887,
      -0.004324525594711304,
      0.0254304651170969,
      -0.02700023725628853,
      0.07506073266267776,
      0.028917605057358742,
      0.042985137552022934,
      0.0017137457616627216,
      0.02341451868414879,
      0.05090772733092308,
      -0.009622734971344471,
      0.013876433484256268,
      0.1190604493021965,
      -0.04808620363473892,
      -0.008863789960741997,
      0.05962299183011055,
      -0.03223434463143349,
      0.00456771207973361,
      1.2450386748241726e-05,
      -0.010973419062793255,
      0.0662105455994606,
      -0.03378940373659134,
      0.013546528294682503,
      -0.029293416067957878,
      0.01940838247537613,
      0.04515145346522331,
      0.09062705934047699,
      -0.002139539457857609,
      -0.01876850612461567,
      0.001235921517945826,
      -0.05016826465725899,
      -0.004022096283733845,
      -0.009201467968523502,
      -0.017254676669836044,
      0.0257570743560791,
      0.09393993765115738,
      -0.029892122372984886,
      -0.08063637465238571,
      -0.10286140441894531,
      -0.006528645288199186,
      -0.08050157129764557,
      -0.055909883230924606,
      -0.06776920706033707,
      -0.03815155103802681,
      0.04002448171377182,
      -0.07196411490440369,
      0.0384833924472332,
      0.00041982464608736336,
      0.04947548732161522,
      0.007627691607922316,
      0.032446905970573425,
      -0.005095827858895063,
      0.045136284083127975,
      0.023758631199598312,
      0.07885825634002686,
      0.025856243446469307,
      0.020009499043226242,
      0.012304826639592648,
      -0.012104974128305912,
      -0.03917994350194931,
      -0.04403486102819443,
      -0.025886744260787964,
      0.00700771901756525,
      0.0014338515466079116,
      0.030585987493395805,
      0.04284223914146423,
      0.08038350194692612,
      -0.07642511278390884
    ],
    [
      0.03888018801808357,
      -0.0015541744651272893,
      0.032208286225795746,
      -0.05922698974609375,
      0.030198510736227036,
      0.019827663898468018,
      0.054101645946502686,
      0.01679898053407669,
      -0.07178160548210144,
      0.0362396202981472,
      -0.06224515661597252,
      -0.03677569702267647,
      0.05395391210913658,
      0.055443257093429565,
      -0.061075665056705475,
      0.0479753240942955,
      0.04036431759595871,
      0.030064143240451813,
      -0.029398443177342415,
      -0.07198864221572876,
      -0.01889076456427574,
      0.006201518699526787,
      -0.01858210749924183,
      0.08389231562614441,
      0.019661545753479004,
      0.0029506890568882227,
      0.03155013546347618,
      0.024891097098588943,
      0.060252465307712555,
      -0.005492647178471088,
      0.05504292622208595,
      -0.04358622431755066,
      -0.04216623306274414,
      -0.003529738401994109,
      -0.04500066488981247,
      0.03356042876839638,
      0.07449307292699814,
      0.07278535515069962,
      0.02688974142074585,
      -0.030019154772162437,
      -0.01128280721604824,
      -0.002594839781522751,
      -0.05919370800256729,
      0.035852815955877304,
      -0.009054893627762794,
      -0.00010093716264236718,
      -0.043084319680929184,
      -0.017899902537465096,
      0.00038684101309627295,
      0.06567675620317459,
      -0.01818891428411007,
      -0.0665266364812851,
      0.060180168598890305,
      -0.02669326588511467,
      0.045303359627723694,
      -0.0004945394466631114,
      0.009916601702570915,
      -0.05018075183033943,
      0.005194277968257666,
      0.011909266002476215,
      0.015290660783648491,
      0.011347026564180851,
      -0.03525310754776001,
      0.01911456510424614,
      0.04454651474952698,
      0.08775568753480911,
      -0.01983974315226078,
      -0.011671577580273151,
      -0.0019816034473478794,
      -0.024825556203722954,
      -0.03260764107108116,
      0.09205999970436096,
      -0.00970275979489088,
      0.03917555883526802,
      0.022641614079475403,
      -0.05023885890841484,
      0.09035642445087433,
      0.03067043609917164,
      0.007131191901862621,
      0.06991305947303772,
      0.0035859926138073206,
      -0.032481804490089417,
      -0.05637414753437042,
      0.018699467182159424,
      0.03905976563692093,
      0.016640368849039078,
      0.0025245838332921267,
      -0.008244686760008335,
      0.0005514032091014087,
      0.007101572584360838,
      -0.014908778481185436,
      -0.030314749106764793,
      0.05713648721575737,
      0.0013309838250279427,
      0.01136438362300396,
      -0.06754918396472931,
      -0.05612260103225708,
      -0.023949619382619858,
      0.04607720673084259,
      0.032541800290346146,
      -0.06442751735448837,
      0.05196995660662651,
      0.01707467809319496,
      0.029773037880659103,
      -0.0073197041638195515,
      0.08392181247472763,
      -0.04751100763678551,
      -0.040495168417692184,
      0.06071682646870613,
      -0.025271842256188393,
      0.09494680166244507,
      -0.12150391191244125,
      0.05942421779036522,
      0.04418052360415459,
      0.05075429752469063,
      0.060889288783073425,
      0.052020516246557236,
      0.01835392601788044,
      -0.013007169589400291,
      0.027030766010284424,
      0.02767699770629406,
      -0.039867501705884933,
      0.022441023960709572,
      -0.026282528415322304,
      0.01347038708627224,
      0.01918439194560051,
      0.04154936596751213,
      0.006638769526034594,
      0.07223677635192871,
      0.004560584202408791,
      0.005918898619711399,
      -0.04997236281633377,
      0.04203557223081589,
      -0.04442688077688217,
      -0.008349917829036713,
      0.02096390910446644,
      -0.01628868281841278,
      -0.0009638128685764968,
      -0.07270251214504242,
      -0.005248292814940214,
      -0.024709682911634445,
      0.009267466142773628,
      0.0052445740438997746,
      0.09084304422140121,
      -0.08919660747051239,
      0.02162274718284607,
      -0.030896473675966263,
      0.003463206347078085,
      0.02018488198518753,
      0.02125403843820095,
      0.04944852367043495,
      -0.027568470686674118,
      -0.020303690806031227,
      0.047922246158123016,
      0.03525224328041077,
      0.07914594560861588,
      0.05966423079371452,
      0.138466939330101,
      -0.00148800911847502,
      -0.001871303073130548,
      0.09859071671962738,
      0.01107101608067751,
      0.0176257137209177,
      -0.03951297327876091,
      0.02055123820900917,
      -0.009299581870436668,
      -0.06680524349212646,
      0.06745242327451706,
      0.05972495302557945,
      -0.006143486127257347,
      0.12759242951869965,
      -0.017582666128873825,
      -0.026356268674135208,
      0.00045192608376964927,
      -0.008160560391843319,
      -0.04885692149400711,
      0.05247155576944351,
      -0.022699395194649696,
      0.05676025524735451,
      0.02114691585302353,
      0.0018090095836669207,
      -0.10719120502471924,
      -0.007849959656596184,
      0.03945387154817581,
      -0.02046911232173443,
      -0.022540345788002014,
      -0.009153078310191631,
      0.005411420948803425,
      -0.02553407847881317,
      -0.0285703893750906,
      0.02382192201912403,
      -0.01745918206870556,
      0.05079836770892143,
      0.0384022518992424,
      -0.04429486021399498,
      0.04943070933222771,
      0.047593940049409866,
      0.021866129711270332,
      0.0012787152081727982,
      -0.08297759294509888,
      -0.011146697215735912,
      -0.02927524596452713,
      -0.021284179762005806,
      0.0008629898657090962,
      0.010288356803357601,
      0.009948757477104664,
      0.034506555646657944,
      0.08766090869903564,
      -0.012429853901267052,
      0.06892518699169159,
      0.039416369050741196,
      -0.022659707814455032,
      -0.08085750043392181,
      -0.07763556391000748,
      0.04922100529074669,
      -0.004328165668994188,
      0.05795905366539955,
      -0.024019721895456314,
      0.035167016088962555,
      -0.0606229342520237,
      -0.06424848735332489,
      0.03467460349202156,
      -0.010151454247534275,
      0.057861506938934326,
      0.010387066751718521,
      0.06863138824701309,
      0.05833103507757187,
      0.028946029022336006,
      0.025905746966600418,
      0.02665664255619049,
      0.0005587512860074639,
      -0.012511820532381535,
      0.008336441591382027,
      -0.012812547385692596,
      0.032154571264982224,
      0.04441338777542114,
      0.019972024485468864,
      0.04666726663708687,
      -0.005453124642372131,
      0.09153405576944351,
      -0.06520754098892212,
      -0.01440513227134943,
      0.03655596822500229,
      0.0678696408867836,
      -0.016788020730018616,
      -0.0443563349545002,
      0.018585382029414177,
      -0.021563319489359856,
      0.021200895309448242,
      -0.010533585213124752,
      -0.04052872210741043,
      -0.023484930396080017,
      0.07601699233055115,
      -0.033509012311697006,
      0.0004291423538234085,
      0.018528379499912262,
      -0.025643998757004738,
      0.09382371604442596,
      -0.032347891479730606,
      -0.003239699173718691,
      -0.00782371312379837,
      -0.04128172993659973,
      -0.03843570873141289,
      0.008666443638503551,
      0.05394866690039635,
      0.006009834818542004,
      -0.004860627930611372,
      -4.505328251980245e-05,
      -0.029119472950696945,
      -0.011609346605837345,
      -0.0475216768682003,
      0.008973781950771809,
      -0.01012466847896576,
      0.07025650143623352,
      0.058397840708494186,
      0.030703453347086906,
      -0.10301858931779861,
      -0.04626847803592682,
      0.03449181467294693,
      0.028233934193849564,
      -0.025202304124832153,
      -0.02041885443031788,
      0.007556562777608633,
      0.05352937430143356,
      -0.004482455551624298,
      0.07373015582561493,
      -0.0077897910960018635,
      -0.07425544410943985,
      -0.0009656930342316628,
      -0.0024472170043736696,
      -0.002189287217333913,
      0.0023414550814777613,
      -0.05865514278411865,
      -0.04040370509028435,
      -0.012100371532142162,
      0.033539026975631714,
      0.045536354184150696,
      -0.060766082257032394,
      0.05753451958298683,
      -0.05229724943637848,
      -0.02897549606859684,
      -0.019951924681663513,
      0.0016537669580429792,
      0.06169886142015457,
      -0.061816468834877014,
      0.018782738596200943,
      -0.028205012902617455,
      0.048734549432992935,
      0.02977592870593071,
      -0.011591038666665554,
      -0.03341314569115639,
      -0.09794052690267563,
      -0.05402034893631935,
      0.025420622900128365,
      -0.044296883046627045,
      -0.10749988257884979,
      0.038188036531209946,
      0.0014567960752174258,
      0.09024706482887268,
      -0.022074436768889427,
      0.053178559988737106,
      -0.015239451080560684,
      -0.017247430980205536,
      0.10094574838876724,
      -0.05353677272796631,
      0.05306268855929375,
      0.015442050993442535,
      -0.00786611344665289,
      0.023688381537795067,
      0.011040586046874523,
      0.011472262442111969,
      0.0205474104732275,
      -0.03451203927397728,
      -0.013382041826844215,
      0.014876311644911766,
      -0.013788329437375069,
      -0.0050333100371062756,
      -0.05536621809005737,
      0.007687168661504984,
      0.025798233225941658,
      -0.06733012199401855,
      -0.00024190632393583655,
      -0.008065443485975266,
      0.05949145182967186,
      0.07732050120830536,
      0.06135326251387596,
      -0.007541141007095575,
      0.027035413309931755,
      -0.039283622056245804,
      0.0335703119635582,
      -0.035826150327920914,
      -0.027813049033284187,
      0.08217208832502365,
      0.03446372225880623,
      0.004594852682203054,
      0.028033500537276268,
      0.040893565863370895,
      -0.040483906865119934,
      0.05038075894117355,
      -0.05209575220942497,
      -0.0036011827178299427,
      0.040809448808431625,
      0.03741380572319031,
      0.04638982564210892,
      0.004153905902057886,
      -0.031346894800662994,
      0.024099597707390785,
      0.027975428849458694,
      0.03393026441335678,
      0.012399015948176384,
      -0.05712640658020973,
      0.012512041255831718,
      -0.03525347635149956,
      0.039296843111515045,
      -0.014917479828000069,
      0.02289699763059616,
      -0.009873990900814533,
      -0.039184264838695526,
      0.01708107255399227,
      -0.019569208845496178,
      -0.01747022569179535,
      -0.064395971596241,
      -0.07146453857421875,
      0.0029773800633847713,
      0.023720059543848038,
      0.0019984296523034573,
      -0.04064352437853813,
      -0.031654372811317444,
      0.024761676788330078,
      0.03967159986495972,
      -0.03434180095791817,
      -0.04530111700296402,
      0.026420388370752335,
      -0.03355928510427475,
      -0.014077539555728436,
      -0.02189253643155098,
      -0.020274030044674873,
      0.03408968448638916,
      0.0018730337033048272,
      -0.05925877392292023,
      0.0012746222782880068,
      -0.0055420882999897,
      0.13824011385440826,
      0.054251547902822495,
      -0.005355967674404383,
      0.026443462818861008,
      -0.07500653713941574,
      0.023158874362707138,
      0.03434515371918678,
      -0.035353560000658035,
      0.008930086158216,
      0.07573971152305603,
      -0.056698158383369446,
      0.013713574968278408,
      0.01830587536096573,
      -0.03872942924499512,
      0.03390166908502579,
      -0.04501115158200264,
      0.030297502875328064,
      0.016711631789803505,
      0.015436505898833275,
      0.06659465283155441,
      -0.01620597578585148,
      0.01749556139111519,
      -0.09328001737594604,
      0.004626678302884102,
      0.0041138664819300175,
      -0.05703454092144966,
      -0.038107000291347504,
      0.07340427488088608,
      -0.07944793999195099,
      0.003945516422390938,
      -0.006048108916729689,
      -0.0553472526371479,
      -0.006171774119138718,
      -0.03439629077911377,
      0.16463127732276917,
      -0.05302804335951805,
      0.05384666845202446,
      -0.025028951466083527,
      0.06082376092672348,
      0.010662744753062725,
      -0.0024218237958848476,
      -0.009784834459424019,
      0.06342988461256027,
      -0.02179347723722458,
      0.01148119755089283,
      0.00897364690899849,
      0.10911039263010025,
      -0.004654534626752138,
      0.041043613106012344,
      -0.061807967722415924,
      0.02063634619116783,
      -0.0748242661356926,
      -0.03255481272935867,
      0.016385670751333237,
      -0.002759397029876709,
      0.032258931547403336,
      0.014992540702223778,
      -0.07169520109891891,
      -0.03152124956250191,
      0.07163023203611374,
      -0.01595437154173851,
      -0.05572694167494774,
      0.03587473928928375,
      0.02517886646091938,
      -0.025644980370998383,
      0.012014219537377357,
      0.029919778928160667,
      0.01257089339196682,
      -0.023041753098368645,
      0.061610303819179535,
      0.00988562311977148,
      -0.11470277607440948,
      -0.04241735115647316,
      -0.008215384557843208,
      -0.03224539756774902,
      -0.03554438054561615,
      0.08173489570617676,
      0.024929774925112724,
      0.030879944562911987,
      -0.007673474028706551,
      -0.015445541590452194,
      -0.007274468429386616,
      0.0979086384177208,
      -0.043263886123895645,
      0.020039161667227745,
      0.04567212238907814,
      -0.047558464109897614,
      0.02362195774912834,
      0.06275036931037903,
      -0.043627843260765076,
      -0.027853937819600105,
      0.06065015494823456,
      7.9999168519862e-05,
      0.009147071279585361,
      0.042760733515024185,
      -0.05026879161596298,
      0.027251437306404114,
      2.710984699660912e-05,
      -0.006263375747948885,
      -0.030448349192738533,
      -0.05260329693555832
    ],
    [
      -0.03243198245763779,
      -0.0057143536396324635,
      -0.10238481312990189,
      0.012756723910570145,
      0.03995127975940704,
      0.07209058851003647,
      -0.04454570263624191,
      0.04933173209428787,
      -0.0959765613079071,
      0.05356357619166374,
      0.09824632853269577,
      0.011844469234347343,
      0.010877885855734348,
      0.0409889854490757,
      0.026843253523111343,
      -0.022963112220168114,
      -0.06299448758363724,
      -0.05459554120898247,
      -0.008272800594568253,
      -0.021283231675624847,
      -0.12016776949167252,
      0.035015176981687546,
      -0.012498653493821621,
      0.047144565731287,
      0.059423770755529404,
      -0.037754375487565994,
      0.04445735737681389,
      -0.058049142360687256,
      0.006536049302667379,
      -0.041211001574993134,
      -0.033108655363321304,
      -0.007698113098740578,
      -0.01633756048977375,
      0.07610203325748444,
      -0.04053556174039841,
      -0.023768192157149315,
      -0.020183108747005463,
      -0.023333963006734848,
      -0.04332360625267029,
      0.017685916274785995,
      0.018233217298984528,
      -0.036340877413749695,
      -0.02970113418996334,
      0.04039004072546959,
      0.04529952257871628,
      -0.043010447174310684,
      -0.030078468844294548,
      0.07012718915939331,
      -0.03946743160486221,
      -0.02132159098982811,
      0.003990866709500551,
      0.011463737115263939,
      0.043474435806274414,
      -0.02140704356133938,
      0.07318251579999924,
      0.016259225085377693,
      0.0379614494740963,
      -0.08767259865999222,
      0.014893176034092903,
      -0.01403776928782463,
      0.061512541025877,
      0.039633333683013916,
      0.016128385439515114,
      0.011002935469150543,
      0.002359995385631919,
      0.07661158591508865,
      0.044392798095941544,
      0.1233803927898407,
      0.001674726721830666,
      -0.008086071349680424,
      0.041222184896469116,
      -0.1171596497297287,
      -0.02220435068011284,
      0.03493116796016693,
      -0.018094493076205254,
      0.028426749631762505,
      -0.046946458518505096,
      -0.022718999534845352,
      0.009945915080606937,
      0.01726250723004341,
      0.08343420922756195,
      -0.07516010105609894,
      0.00516179483383894,
      0.04274679347872734,
      0.0016621043905615807,
      -0.034250371158123016,
      -0.07152078300714493,
      -0.03730986267328262,
      0.014610521495342255,
      -0.047783639281988144,
      -0.014052857644855976,
      0.011179937049746513,
      0.0035252764355391264,
      0.0557495579123497,
      0.004771225154399872,
      -0.010359433479607105,
      0.03818685933947563,
      0.02565627172589302,
      0.0005154539830982685,
      -0.00916440412402153,
      0.054443322122097015,
      0.050091423094272614,
      -0.026259204372763634,
      -0.06503959745168686,
      -0.025144679471850395,
      0.014586314558982849,
      0.027991829439997673,
      -0.08027320355176926,
      -0.023238712921738625,
      0.04466891661286354,
      -0.017890039831399918,
      -0.0016762797022238374,
      -0.01851438544690609,
      -0.019887307658791542,
      0.04125209525227547,
      -0.05965806171298027,
      0.025693167001008987,
      -0.02821389213204384,
      0.030030380934476852,
      -0.027109889313578606,
      -0.027619902044534683,
      -0.01512298546731472,
      -0.025710931047797203,
      0.03133941441774368,
      0.06085144728422165,
      -0.033089298754930496,
      -0.010470851324498653,
      -0.026200389489531517,
      0.07467985898256302,
      0.06537540256977081,
      0.06673624366521835,
      0.03218774124979973,
      -0.025023112073540688,
      0.03920736536383629,
      -0.052416346967220306,
      -0.023706704378128052,
      0.03707369416952133,
      0.0577002614736557,
      0.012140553444623947,
      0.010101767256855965,
      -0.05237612873315811,
      -0.033744994550943375,
      0.0001454737939639017,
      -0.015588264912366867,
      -0.00326210237108171,
      -0.058749619871377945,
      0.07010510563850403,
      -0.048206720501184464,
      -0.05434385687112808,
      -0.03826174512505531,
      -0.026475517079234123,
      -0.013650113716721535,
      -0.026592673733830452,
      -0.050668273121118546,
      0.04696331173181534,
      -0.10018391162157059,
      0.06866805255413055,
      0.03254222869873047,
      0.04357479140162468,
      -0.0394921638071537,
      0.08671341091394424,
      0.018885334953665733,
      0.11493455618619919,
      0.04451267793774605,
      -0.07994765788316727,
      -0.004007044248282909,
      -0.03174608573317528,
      -0.02997324988245964,
      0.10556858777999878,
      -0.062385737895965576,
      0.04893122613430023,
      -0.035583432763814926,
      -0.027242474257946014,
      0.05132850259542465,
      0.044210776686668396,
      0.04078923538327217,
      0.008598867803812027,
      0.010055324994027615,
      -0.07758795469999313,
      -0.07019110769033432,
      -0.010827974416315556,
      0.053878962993621826,
      -0.051910098642110825,
      0.09786832332611084,
      -0.04255416989326477,
      -0.028228996321558952,
      0.07832934707403183,
      0.03763468191027641,
      -2.115113238687627e-05,
      -0.10104209184646606,
      0.02217988669872284,
      0.11036758869886398,
      -0.04761496186256409,
      0.03305993229150772,
      -0.002772680949419737,
      -0.013013848103582859,
      0.04607390612363815,
      -0.06040271371603012,
      -0.07669056951999664,
      0.057161279022693634,
      -0.04733686521649361,
      -0.052278194576501846,
      -0.0034033663105219603,
      0.0566597655415535,
      -0.05241016671061516,
      -0.020505666732788086,
      -0.023620914667844772,
      -0.08425622433423996,
      0.010251479223370552,
      -0.002007200615480542,
      0.04792117327451706,
      0.0014779351186007261,
      -0.02805040404200554,
      -0.008751832880079746,
      0.0019303497392684221,
      0.008727279491722584,
      0.008217944763600826,
      -0.051511913537979126,
      -0.00984913483262062,
      0.04623471200466156,
      0.05082614719867706,
      -0.0805213525891304,
      0.10194072127342224,
      0.06676208972930908,
      -0.09254029393196106,
      0.04117758572101593,
      0.022148748859763145,
      0.005279177334159613,
      -0.057237569242715836,
      -0.058259088546037674,
      0.04444790259003639,
      0.11780155450105667,
      0.067866250872612,
      -0.028556181117892265,
      -0.08142191171646118,
      -0.008542478084564209,
      0.07396479696035385,
      0.025313565507531166,
      -0.011300204321742058,
      -0.015657396987080574,
      0.06545016914606094,
      0.026775537058711052,
      -0.002363216131925583,
      0.003602287033572793,
      -0.01855669915676117,
      -0.02327747829258442,
      -0.007477201521396637,
      -0.030264075845479965,
      0.02171126939356327,
      -0.05419531464576721,
      -0.02147519960999489,
      0.006367614027112722,
      -0.006336909718811512,
      -0.017664683982729912,
      -0.0024053275119513273,
      -0.010516251437366009,
      0.02543729357421398,
      -0.06698697060346603,
      -0.04197501391172409,
      0.05113839730620384,
      0.04353347048163414,
      -0.01488556619733572,
      0.0028666723519563675,
      0.009053030051290989,
      -0.04752523452043533,
      -0.04957308620214462,
      -0.017512472346425056,
      0.008817750960588455,
      0.014567320235073566,
      -0.03895486518740654,
      -0.07693914324045181,
      -0.009184617549180984,
      0.03987185284495354,
      0.02917572855949402,
      0.056326501071453094,
      0.08344832062721252,
      0.07098647207021713,
      -0.044354744255542755,
      -0.011515334248542786,
      0.04860446974635124,
      0.08258692175149918,
      -0.06988022476434708,
      0.006544130854308605,
      0.04010956734418869,
      0.010700328275561333,
      0.005432121455669403,
      -0.04268910735845566,
      0.058445777744054794,
      -0.05065147206187248,
      -0.07859952002763748,
      0.05597556009888649,
      -0.036185670644044876,
      -0.033874381333589554,
      -0.04323752596974373,
      0.014205141924321651,
      -0.029571935534477234,
      0.001935677952133119,
      0.07400870323181152,
      0.0281817689538002,
      0.04950277507305145,
      -0.03983285650610924,
      0.05628493055701256,
      -0.036585837602615356,
      0.02484915219247341,
      0.01825655996799469,
      0.0023162199649959803,
      0.020143628120422363,
      -0.05190939083695412,
      0.02119402401149273,
      -0.06617337465286255,
      -0.04208415746688843,
      0.03447926416993141,
      -0.030240554362535477,
      -0.008158110082149506,
      0.01771801896393299,
      0.09377620369195938,
      0.04496214538812637,
      0.02327095717191696,
      -0.05242498591542244,
      0.03840659186244011,
      0.05018908903002739,
      0.0028701326809823513,
      0.06549423933029175,
      -0.034081652760505676,
      0.10983185470104218,
      0.05158736929297447,
      -0.0027426541782915592,
      -0.053579144179821014,
      0.07904430478811264,
      0.0020718772429972887,
      0.013296063989400864,
      -0.03380117565393448,
      0.02065388858318329,
      -0.049951110035181046,
      0.01340689416974783,
      0.016312479972839355,
      -0.01813892275094986,
      -0.03675192967057228,
      0.03702593967318535,
      0.08519020676612854,
      -0.035783637315034866,
      -0.017715109512209892,
      0.07885470241308212,
      0.05501500889658928,
      -0.019768178462982178,
      -0.020512346178293228,
      0.04958101361989975,
      -0.021807650104165077,
      -0.037576284259557724,
      -0.025085140019655228,
      -0.021559491753578186,
      -0.00537964329123497,
      0.014956338331103325,
      0.03544699773192406,
      0.00043983582872897387,
      0.0038711419329047203,
      -0.056665416806936264,
      -0.05106395483016968,
      0.041416577994823456,
      0.009191679768264294,
      -0.008258072659373283,
      -0.007783091627061367,
      0.03684629872441292,
      0.007060911040753126,
      -0.001626540208235383,
      -0.07589904218912125,
      -0.08010530471801758,
      0.05190688744187355,
      -0.029786834493279457,
      -0.03536827862262726,
      -0.09079056978225708,
      -0.03942320868372917,
      0.0036773260217159986,
      0.013373621739447117,
      -0.05676264688372612,
      0.04693954810500145,
      -0.004282704088836908,
      -0.0027134346310049295,
      -0.003464045003056526,
      -0.0012969628442078829,
      -0.022626930847764015,
      -0.0644713044166565,
      -0.012325024232268333,
      0.05666361004114151,
      0.024630293250083923,
      0.08032006025314331,
      0.04568109288811684,
      0.0367264449596405,
      0.017084235325455666,
      -0.08022719621658325,
      -0.031037166714668274,
      0.02385435253381729,
      -0.05446876958012581,
      -0.019794218242168427,
      -0.09790259599685669,
      0.035073041915893555,
      0.06919733434915543,
      0.04297945648431778,
      0.0416320264339447,
      0.05041797086596489,
      0.00394953228533268,
      0.02738388255238533,
      -0.03994273394346237,
      -0.05105585977435112,
      0.050813108682632446,
      0.030685966834425926,
      0.08008168637752533,
      -0.011576070450246334,
      0.02839641273021698,
      -0.013790345750749111,
      -0.052117969840765,
      -0.03245532512664795,
      -0.04189120605587959,
      -0.03521709516644478,
      -0.014505830593407154,
      0.012137673795223236,
      -0.04321339353919029,
      -0.039920415729284286,
      -0.08409812301397324,
      -0.04575958847999573,
      -0.05118522793054581,
      0.03583837300539017,
      -0.01704278402030468,
      0.017264144495129585,
      0.02212907373905182,
      -0.013959301635622978,
      0.05827582627534866,
      -0.03542592376470566,
      -0.07479123026132584,
      -0.03503326326608658,
      -0.09160128235816956,
      0.011510992422699928,
      0.04468464478850365,
      -0.05180452764034271,
      0.04731692746281624,
      0.03656109422445297,
      0.046963173896074295,
      -0.055107004940509796,
      -0.04671598598361015,
      -0.0475902184844017,
      0.013214543461799622,
      0.030296193435788155,
      0.0345211960375309,
      0.1041400209069252,
      -0.016502035781741142,
      -0.02426823601126671,
      0.016598783433437347,
      0.07810808718204498,
      -0.03431565687060356,
      0.05336073786020279,
      0.008786029182374477,
      0.06694375723600388,
      -0.00317439460195601,
      0.005426244810223579,
      0.04174094647169113,
      -0.025721274316310883,
      0.025799496099352837,
      0.03860236704349518,
      -0.0114396708086133,
      0.058558523654937744,
      -0.015348851680755615,
      0.06890463083982468,
      -0.025206876918673515,
      0.02195151150226593,
      0.06323402374982834,
      -0.041699789464473724,
      -0.0071305930614471436,
      0.020899463444948196,
      -0.06517618149518967,
      -0.03626960888504982,
      -0.008239281363785267,
      0.04306910187005997,
      0.0480288527905941,
      0.026623031124472618,
      -0.01995689421892166,
      0.06183198094367981,
      0.0410316064953804,
      -0.05412582680583,
      -0.029004672542214394,
      0.0379704050719738,
      0.012117370963096619,
      0.0037249436136335135,
      0.03514053300023079,
      -0.08157320320606232,
      0.04633474349975586,
      0.04511179402470589,
      0.0002486754674464464,
      0.018080584704875946,
      0.013872123323380947,
      0.03506927564740181,
      -0.014517433941364288,
      0.08286745101213455,
      -0.03069223091006279,
      0.013781159184873104,
      -0.03632640838623047,
      0.025472145527601242,
      -0.021780187264084816,
      0.028117967769503593,
      0.04695030301809311,
      0.038075029850006104,
      -0.04330553486943245,
      0.01398448832333088,
      -0.09337275475263596
    ],
    [
      0.04625963419675827,
      -0.024594683200120926,
      0.05240318924188614,
      0.004542360082268715,
      0.06902922689914703,
      -0.025623058900237083,
      -0.021475642919540405,
      0.009248467162251472,
      0.01816011779010296,
      -0.08084636926651001,
      0.02817571721971035,
      -0.00186052022036165,
      0.03418051078915596,
      0.017711974680423737,
      -0.09798791259527206,
      -0.03796209394931793,
      -0.029885578900575638,
      0.05031827837228775,
      0.030055513605475426,
      -0.03534514829516411,
      0.015144535340368748,
      -0.01794336549937725,
      -0.04436095803976059,
      0.06538787484169006,
      -0.04612240567803383,
      0.0398336686193943,
      -0.024032138288021088,
      -0.013263065367937088,
      -0.029967302456498146,
      0.018697205930948257,
      0.017961885780096054,
      -0.009300986304879189,
      0.021446699276566505,
      -0.02095290832221508,
      -0.07896354049444199,
      -0.010077130049467087,
      -0.003427538787946105,
      -0.039283763617277145,
      0.026861542835831642,
      -0.027167636901140213,
      0.009672828018665314,
      0.022282132878899574,
      -0.02012702077627182,
      0.01704128459095955,
      0.020981336012482643,
      -0.031784720718860626,
      -0.044599469751119614,
      0.023222381249070168,
      -0.017228351905941963,
      0.03416880592703819,
      -0.014826773665845394,
      0.05958026647567749,
      0.031972210854291916,
      0.04568802937865257,
      -0.010573283769190311,
      -0.09230875223875046,
      0.03134215995669365,
      -0.12090921401977539,
      0.05778702348470688,
      -0.02243971824645996,
      0.01683218590915203,
      0.047169703990221024,
      -0.01767273247241974,
      0.013402249664068222,
      0.0005127909244038165,
      0.053961340337991714,
      0.024081142619252205,
      0.03564988076686859,
      0.017481841146945953,
      0.05561403930187225,
      0.006555268075317144,
      0.02028547041118145,
      0.05636537820100784,
      0.009486032649874687,
      -0.034592535346746445,
      0.03363412246108055,
      -0.027303412556648254,
      0.013105464167892933,
      0.027099426835775375,
      -0.008054924197494984,
      -0.006222617346793413,
      0.0035017512273043394,
      -0.05087973549962044,
      0.02156386710703373,
      0.029059918597340584,
      0.0500265471637249,
      0.01840445026755333,
      0.020150594413280487,
      -0.027502598240971565,
      -0.026411471888422966,
      0.07705933600664139,
      -0.01186610572040081,
      0.04359116777777672,
      0.010914284735918045,
      -0.028694400563836098,
      -0.03284215182065964,
      -0.024595627561211586,
      -0.04799633473157883,
      -0.027350256219506264,
      0.011020997539162636,
      -0.0900598019361496,
      0.01062010508030653,
      0.02894767001271248,
      -0.14931660890579224,
      -0.03330893814563751,
      0.07011698186397552,
      -0.013581916689872742,
      0.01293597649782896,
      0.029787415638566017,
      0.03013189695775509,
      -0.0602998323738575,
      0.06363796442747116,
      -0.05614910647273064,
      0.03841216489672661,
      -0.05407174676656723,
      0.10178406536579132,
      0.056953780353069305,
      0.029431181028485298,
      0.10270816087722778,
      -0.06699161231517792,
      0.0009471683879382908,
      -0.03481418266892433,
      0.04780592396855354,
      -0.04413308948278427,
      -0.03217793256044388,
      0.04346243664622307,
      -0.03318965435028076,
      0.018091939389705658,
      0.042434755712747574,
      -0.006504243705421686,
      -0.05162449926137924,
      -0.02797510474920273,
      0.010016722604632378,
      0.01699575036764145,
      0.019312512129545212,
      0.09510567784309387,
      0.06908999383449554,
      0.017484789714217186,
      0.03791605681180954,
      0.03571068495512009,
      -0.03555384278297424,
      -0.0819326788187027,
      0.046234071254730225,
      0.04675335809588432,
      -0.013714605011045933,
      0.02290453389286995,
      0.0036008828319609165,
      0.059935715049505234,
      -0.07358032464981079,
      -0.07448837906122208,
      0.009200811386108398,
      0.025280030444264412,
      -0.0800618976354599,
      0.00902906246483326,
      0.046282317489385605,
      -0.03735877573490143,
      0.036568477749824524,
      0.0009379506227560341,
      -0.03751381114125252,
      0.02362595871090889,
      0.005294296890497208,
      0.01099823322147131,
      0.0524970144033432,
      -0.011807233095169067,
      0.01688988506793976,
      0.01806354895234108,
      0.022629715502262115,
      -0.04653627797961235,
      0.02753293886780739,
      -0.03997263312339783,
      -0.006454086862504482,
      -0.02427380345761776,
      0.04725576564669609,
      0.01816611737012863,
      0.00045383491669781506,
      0.0008323109359480441,
      -0.016525432467460632,
      -0.04040880501270294,
      -0.02247455343604088,
      -0.013086041435599327,
      -0.04013880714774132,
      0.01185037475079298,
      0.019177118316292763,
      -0.0050942422822117805,
      0.041907794773578644,
      -0.06232598051428795,
      0.04011751338839531,
      -0.012570188380777836,
      -0.07432438433170319,
      0.04365548491477966,
      0.01151046808809042,
      0.04818551614880562,
      -0.01585766300559044,
      0.03466907888650894,
      0.024343224242329597,
      -0.008870973251760006,
      -0.018396491184830666,
      -0.030912058427929878,
      0.06158191338181496,
      0.008414141833782196,
      8.554827945772558e-05,
      0.028701594099402428,
      0.02314908616244793,
      -0.011692187748849392,
      -0.018475180491805077,
      -0.07323460280895233,
      -0.032137658447027206,
      0.0027565467171370983,
      0.04465402662754059,
      0.010338610969483852,
      -0.07991784065961838,
      -0.04850803688168526,
      0.03318412974476814,
      -0.10711105167865753,
      -0.0816318467259407,
      0.07301436364650726,
      -0.06051945313811302,
      -0.03148578852415085,
      -0.039348453283309937,
      -0.013644917868077755,
      -0.03000917285680771,
      0.07560990750789642,
      0.01821964606642723,
      -0.01340319775044918,
      0.04006364196538925,
      0.021845070645213127,
      -0.012476565316319466,
      -0.003280489705502987,
      0.00821517501026392,
      0.0013915766030550003,
      0.02914225310087204,
      -0.00613701855763793,
      0.02578161656856537,
      0.011462492868304253,
      -0.011000217869877815,
      0.014498568139970303,
      0.048768676817417145,
      -0.05967788025736809,
      0.018818549811840057,
      0.018263546749949455,
      -0.043571557849645615,
      0.015076159499585629,
      0.009021492674946785,
      -0.0007254297961480916,
      -0.010118769481778145,
      -0.010691809467971325,
      -0.02068527601659298,
      0.06951070576906204,
      0.01484685204923153,
      0.04167938977479935,
      0.03221094608306885,
      0.03381963446736336,
      0.0032886923290789127,
      0.01958233118057251,
      0.07959260046482086,
      0.09332035481929779,
      -0.020634926855564117,
      -0.014022218994796276,
      -0.04779801517724991,
      -0.05834534019231796,
      0.014452507719397545,
      -0.0012585113290697336,
      -0.01653771661221981,
      -0.01138278003782034,
      -0.02711442857980728,
      0.01865849830210209,
      0.09468357264995575,
      -0.08865770697593689,
      -0.01037919893860817,
      0.00151343853212893,
      0.0356786735355854,
      -0.013070599175989628,
      -0.027428535744547844,
      0.01416311226785183,
      -0.0051886835135519505,
      -0.01300101075321436,
      -0.022606993094086647,
      0.05987272784113884,
      0.0785188376903534,
      -0.06258183717727661,
      0.02119310200214386,
      -0.012268460355699062,
      -0.04399886354804039,
      0.07690107822418213,
      -0.01716543361544609,
      -0.0037325043231248856,
      -0.007781419437378645,
      -0.056556254625320435,
      0.028865791857242584,
      -0.07695221900939941,
      -0.012855357490479946,
      -0.05133768171072006,
      -0.031893689185380936,
      -0.031883448362350464,
      -0.008553877472877502,
      0.030189475044608116,
      -0.004305624403059483,
      0.07750140875577927,
      -0.03478553891181946,
      -0.032078251242637634,
      0.03246259316802025,
      -0.009410930797457695,
      0.014534440822899342,
      0.021864159032702446,
      -0.042292822152376175,
      -0.027603643015027046,
      -0.02807161584496498,
      -0.02057787962257862,
      -0.03510235622525215,
      0.011299550533294678,
      0.04127434641122818,
      0.021026453003287315,
      -0.014109717682003975,
      -0.0019573543686419725,
      -0.0019498295150697231,
      -0.07349575310945511,
      0.03678460791707039,
      0.01492766011506319,
      -0.11394645273685455,
      0.042092207819223404,
      -0.04862910881638527,
      -0.02304968610405922,
      0.033487409353256226,
      -0.016033722087740898,
      -0.022431453689932823,
      0.00728673255071044,
      0.023458410054445267,
      -0.015336352400481701,
      0.00582482386380434,
      0.001013101194985211,
      -0.029769347980618477,
      0.041917648166418076,
      -0.05301195755600929,
      -0.002716964576393366,
      0.07058008760213852,
      0.003227796172723174,
      -0.034931231290102005,
      0.017757736146450043,
      0.10340064764022827,
      0.06493758410215378,
      -0.0035998837556689978,
      0.011757747270166874,
      0.058743856847286224,
      0.04708594083786011,
      -0.0006776946829631925,
      -0.02083084173500538,
      -1.5092567991814576e-05,
      0.030014216899871826,
      0.013514410704374313,
      0.03941204398870468,
      -0.034260768443346024,
      0.03254234418272972,
      -0.03531140089035034,
      0.01403991598635912,
      0.02252374403178692,
      -0.05453114956617355,
      0.01818677969276905,
      0.04557090997695923,
      -0.03795536980032921,
      0.0010314261307939887,
      0.01989753171801567,
      -0.04386194795370102,
      -0.0009116192231886089,
      0.020644694566726685,
      -0.023238692432641983,
      0.011490585282444954,
      0.029432542622089386,
      0.03599609062075615,
      -0.0018129261443391442,
      0.04434479400515556,
      0.017515817657113075,
      -0.020879369229078293,
      -0.029743362218141556,
      0.06249275803565979,
      -0.024279272183775902,
      -0.051420506089925766,
      -0.010489730164408684,
      0.0007974902982823551,
      0.03295471891760826,
      0.07874434441328049,
      0.0030764550901949406,
      -0.014665573835372925,
      0.0014176208060234785,
      0.031813159584999084,
      -0.033974312245845795,
      0.022922374308109283,
      0.006950811017304659,
      -0.08297955989837646,
      -0.05550302192568779,
      0.0794394463300705,
      0.06720296293497086,
      0.06544830650091171,
      0.07024373859167099,
      -0.002227524761110544,
      0.05324757844209671,
      -0.027706153690814972,
      0.04395415633916855,
      -0.05767877772450447,
      0.07634144276380539,
      0.03707892820239067,
      0.019224897027015686,
      0.012611079961061478,
      0.06208621710538864,
      -0.02430885098874569,
      -0.022992344573140144,
      0.011183518916368484,
      -0.022971199825406075,
      0.047184720635414124,
      -0.0046662637032568455,
      0.020786337554454803,
      -0.025999976322054863,
      0.01339405495673418,
      -0.017810454592108727,
      -0.0001053169253282249,
      -0.025365101173520088,
      -0.020746001973748207,
      -0.014872695319354534,
      -0.02489602379500866,
      0.03325633332133293,
      0.01770075224339962,
      0.03682374581694603,
      0.06531787663698196,
      -0.029634060338139534,
      0.03717122972011566,
      -0.03410926088690758,
      0.05764206498861313,
      0.022286413237452507,
      -0.009091450832784176,
      -0.05985557287931442,
      -0.012546306475996971,
      -0.0037471293471753597,
      0.0041792127303779125,
      0.03522830456495285,
      0.04397027939558029,
      0.01871422491967678,
      0.03756607323884964,
      0.013441040180623531,
      0.004959931597113609,
      -0.05985379219055176,
      -0.02648182213306427,
      -0.00486752949655056,
      -0.011373521760106087,
      0.05524822697043419,
      -0.04262492433190346,
      0.02580157481133938,
      -0.028932709246873856,
      0.0441637858748436,
      -0.01750682294368744,
      -0.021122120320796967,
      -0.05767805501818657,
      0.019878916442394257,
      0.028147850185632706,
      -0.08064145594835281,
      -0.049905698746442795,
      0.02771194651722908,
      -0.0633159801363945,
      -0.058264490216970444,
      0.01448756456375122,
      -0.1018269881606102,
      0.02810007520020008,
      0.018729014322161674,
      0.0676802396774292,
      0.04088391736149788,
      0.008338171057403088,
      0.060479048639535904,
      -0.07478895783424377,
      0.04033995419740677,
      0.006745487917214632,
      -0.06407242268323898,
      -0.01628916896879673,
      0.055998630821704865,
      0.040563471615314484,
      -0.023473797366023064,
      -0.025628959760069847,
      -0.017377017065882683,
      0.04762476310133934,
      -0.041188135743141174,
      -0.013503924012184143,
      -0.035265207290649414,
      0.004604493733495474,
      -0.012514833360910416,
      -0.021334515884518623,
      0.009839197620749474,
      -0.017112096771597862,
      0.009063450619578362,
      0.03873452544212341,
      -0.009091868996620178,
      -0.03366098552942276,
      -0.00936115812510252,
      -0.06236104294657707,
      -0.07723541557788849,
      -0.08552806824445724,
      0.014962609857320786,
      -0.03572474420070648,
      0.055777307599782944,
      -0.027627067640423775,
      0.02792561613023281,
      0.013907154090702534,
      0.002481176285073161,
      -0.0546482615172863,
      0.0034366827458143234,
      -0.021448489278554916,
      -0.04818788170814514
    ],
    [
      -0.01086902804672718,
      -0.06205257028341293,
      -0.055245500057935715,
      0.030755098909139633,
      0.018515119329094887,
      -0.026592029258608818,
      0.021026164293289185,
      -0.030703479424118996,
      -0.0026885217521339655,
      0.06441063433885574,
      -0.032935578376054764,
      0.09135811030864716,
      -0.05156244710087776,
      0.04297134280204773,
      0.05259302631020546,
      -0.00024650400155223906,
      -0.029828565195202827,
      0.08175636827945709,
      -0.13679343461990356,
      -0.0036491653881967068,
      -0.04849555715918541,
      -0.052663471549749374,
      -0.030142512172460556,
      -0.004894909914582968,
      0.0938812866806984,
      -0.013519841246306896,
      0.004489339888095856,
      0.020198674872517586,
      -0.13394050300121307,
      0.008565033785998821,
      -0.05348840728402138,
      -0.11244721710681915,
      0.003274081274867058,
      -0.015390979126095772,
      0.017331931740045547,
      -0.04487255588173866,
      0.027827242389321327,
      0.018232010304927826,
      0.053805042058229446,
      -0.02284330129623413,
      0.05592874065041542,
      0.0505455881357193,
      0.06710787117481232,
      0.012347549200057983,
      -0.027263563126325607,
      -0.016001291573047638,
      0.07352301478385925,
      -0.025412756949663162,
      0.02290455996990204,
      -0.0017911690520122647,
      -0.0029373879078775644,
      0.021098291501402855,
      0.026828790083527565,
      0.06222880259156227,
      0.07088266313076019,
      -0.06953862309455872,
      0.14347654581069946,
      0.009475288912653923,
      -0.026278221979737282,
      -0.037688929587602615,
      -0.0066679250448942184,
      0.00034040294121950865,
      0.03259426727890968,
      0.001828033127821982,
      0.05617475137114525,
      -0.08263348042964935,
      0.06361427903175354,
      0.04978887364268303,
      0.00788922980427742,
      -0.014563377946615219,
      0.0038186616729944944,
      -0.0010871904669329524,
      -0.07344980537891388,
      -0.012843473814427853,
      -0.006851220969110727,
      -0.04661257565021515,
      -0.024952242150902748,
      0.020614132285118103,
      0.07968088239431381,
      0.03716655075550079,
      0.002489360747858882,
      0.04798806831240654,
      -0.0076951272785663605,
      0.07005572319030762,
      -0.042182959616184235,
      -0.025933634489774704,
      -0.09926304966211319,
      0.015966080129146576,
      0.09699627757072449,
      0.034740298986434937,
      0.020204106345772743,
      -0.00635412847623229,
      -0.02787899039685726,
      0.058280207216739655,
      0.08001307398080826,
      -0.06977592408657074,
      4.0258775698021054e-05,
      0.03860263526439667,
      0.005072123371064663,
      -0.02035481482744217,
      0.0594654455780983,
      -0.051565393805503845,
      -0.010112625546753407,
      -0.16044004261493683,
      0.03996862843632698,
      -0.05226554349064827,
      0.01231345720589161,
      -0.07827608287334442,
      0.03481762856245041,
      -0.013040443882346153,
      -0.05296272039413452,
      -0.010857592336833477,
      0.032683245837688446,
      0.03184277191758156,
      -0.016493313014507294,
      -0.011741011403501034,
      -0.09478086233139038,
      -0.048561230301856995,
      0.05188286677002907,
      0.029241668060421944,
      -0.04559407755732536,
      -0.059546541422605515,
      -0.020953407511115074,
      0.018805712461471558,
      0.04182065650820732,
      -0.02293473109602928,
      -0.029957978054881096,
      0.03059053234755993,
      -0.04210267961025238,
      -0.07484205067157745,
      -0.057859547436237335,
      -0.005541002843528986,
      -0.059201519936323166,
      0.04146326705813408,
      -0.043758220970630646,
      -0.023240631446242332,
      -0.020318711176514626,
      0.026543207466602325,
      -0.020883386954665184,
      -0.02726205810904503,
      -0.021884426474571228,
      -0.014654284343123436,
      0.10128019750118256,
      -0.022320212796330452,
      -0.03590390831232071,
      0.06565817445516586,
      -0.008881324902176857,
      -0.05447256937623024,
      0.06831113994121552,
      0.03449592739343643,
      0.023962415754795074,
      -0.028099164366722107,
      -0.03600028529763222,
      -0.05352754518389702,
      -0.002812016988173127,
      0.07475671172142029,
      0.03325807675719261,
      -0.01980363391339779,
      0.0071128723211586475,
      -0.07728185504674911,
      0.1143951267004013,
      0.023176195099949837,
      0.04984975978732109,
      0.0030278374906629324,
      -0.016479913145303726,
      0.027189811691641808,
      0.024595435708761215,
      0.04108048602938652,
      0.036826785653829575,
      -0.041266705840826035,
      -0.034165628254413605,
      0.056825585663318634,
      0.03890391066670418,
      0.10800998657941818,
      -0.012113668024539948,
      0.11215924471616745,
      0.021790042519569397,
      0.052618030458688736,
      -0.04152391105890274,
      0.0478324331343174,
      -0.025392448529601097,
      -0.042190875858068466,
      0.035392943769693375,
      0.007355714216828346,
      -0.013072752393782139,
      0.01773298904299736,
      -0.003289444139227271,
      -0.06154604256153107,
      -0.04347237944602966,
      -0.007003370206803083,
      -0.0033745747059583664,
      -0.005570506677031517,
      0.0715593621134758,
      0.03603947535157204,
      -0.03694171458482742,
      0.04717469587922096,
      0.01653357967734337,
      -0.09398402273654938,
      -0.03731871023774147,
      -0.012957470491528511,
      -0.010348940268158913,
      -0.030982566997408867,
      0.02212897315621376,
      -0.00759213836863637,
      0.03171815723180771,
      0.040721345692873,
      -0.06895029544830322,
      0.07715588808059692,
      0.025659063830971718,
      -0.05821685865521431,
      0.07526260614395142,
      0.026956085115671158,
      0.0059801689349114895,
      0.01498896349221468,
      0.001892737578600645,
      -0.046497054398059845,
      -0.050642840564250946,
      -0.05739283561706543,
      0.0034907537046819925,
      0.02212064154446125,
      -0.03331587836146355,
      -0.06261401623487473,
      -0.0505451038479805,
      -0.027702946215867996,
      0.011069761589169502,
      0.010119250975549221,
      0.08006084710359573,
      -0.04771093651652336,
      -0.06126755103468895,
      -0.007841129787266254,
      -0.027510425075888634,
      0.0035321104805916548,
      -0.06953410059213638,
      0.04410431534051895,
      0.0029925373382866383,
      0.02635485678911209,
      0.010692480020225048,
      -0.03863643854856491,
      0.0022478203754872084,
      0.03193116560578346,
      0.002879589796066284,
      -0.044988200068473816,
      -0.030137989670038223,
      0.047445639967918396,
      -0.052071712911129,
      -0.03376738727092743,
      -0.03197512403130531,
      -0.02114255726337433,
      0.02055206522345543,
      -0.1069740504026413,
      -0.047375429421663284,
      -0.06158192455768585,
      0.0558495856821537,
      -0.04268258064985275,
      0.019741451367735863,
      -0.010169854387640953,
      -0.020969955250620842,
      -0.016473067924380302,
      0.047068458050489426,
      0.00551820220425725,
      0.03214609995484352,
      -0.004731610417366028,
      -0.01880461908876896,
      -0.017340855672955513,
      0.0010849481914192438,
      -0.01937820017337799,
      0.06551065295934677,
      -0.0014879544032737613,
      -0.014611567370593548,
      0.10432080179452896,
      -0.0022431742399930954,
      -0.005940568633377552,
      0.011529260315001011,
      -0.03452369570732117,
      -0.0060841478407382965,
      0.018670670688152313,
      -0.0072979093529284,
      0.017276901751756668,
      -0.024133093655109406,
      0.02038760297000408,
      0.015781763941049576,
      0.024097565561532974,
      -0.01207173801958561,
      -0.04863849654793739,
      -0.006823467556387186,
      0.03248743340373039,
      -0.007632202934473753,
      -0.027838999405503273,
      0.02696049213409424,
      0.013964688405394554,
      0.03189118951559067,
      -0.14630088210105896,
      0.003963125869631767,
      -0.08236365020275116,
      0.028864769265055656,
      -0.0012437961995601654,
      0.018613619729876518,
      -0.009793419390916824,
      -0.01226574182510376,
      -0.07869420945644379,
      -0.019410917535424232,
      -0.0374532975256443,
      -0.014718247577548027,
      -0.012163983657956123,
      -0.021842803806066513,
      0.07463538646697998,
      -0.04295898601412773,
      0.022908257320523262,
      -0.011673158034682274,
      0.04063483700156212,
      -0.054462604224681854,
      0.00536577170714736,
      -0.017692264169454575,
      -0.012627582997083664,
      -0.0018403049325570464,
      0.02668147347867489,
      -0.01881907321512699,
      -0.07262734323740005,
      -0.010072317905724049,
      0.022394908592104912,
      -0.026917841285467148,
      -0.007014942355453968,
      0.018600955605506897,
      -0.04792268946766853,
      0.07938750833272934,
      0.05371794104576111,
      -0.013527666218578815,
      -0.05852300301194191,
      0.02807796001434326,
      0.053426411002874374,
      -0.01157215517014265,
      -0.07015565782785416,
      0.042516063898801804,
      -0.016048599034547806,
      -0.01825530454516411,
      0.05055201053619385,
      -0.06892485171556473,
      -0.02203729748725891,
      -0.01614854298532009,
      0.007861802354454994,
      -0.09428413957357407,
      0.016385728493332863,
      0.03828398510813713,
      -0.06360701471567154,
      0.047436825931072235,
      0.05948696285486221,
      -0.06001171097159386,
      0.0717884823679924,
      0.02082015573978424,
      0.013518907129764557,
      -0.0304655022919178,
      -0.04778754711151123,
      -0.05357520654797554,
      -0.049214307218790054,
      0.004116753116250038,
      -0.01961667090654373,
      0.003975440748035908,
      -0.008924249559640884,
      0.016164610162377357,
      -0.057419225573539734,
      -0.00840417668223381,
      -0.06503039598464966,
      -0.01753239892423153,
      -0.027012916281819344,
      0.004645496606826782,
      -0.00933804176747799,
      -0.04249095916748047,
      -0.010430984199047089,
      -0.05845825374126434,
      -0.015351051464676857,
      0.023778798058629036,
      0.023920612409710884,
      0.05878201499581337,
      -0.0061240484938025475,
      -0.004761749878525734,
      0.05877232551574707,
      0.01572773978114128,
      0.043373771011829376,
      -0.02118600159883499,
      -0.04093644767999649,
      0.059448570013046265,
      0.01452634297311306,
      0.030791347846388817,
      -0.013657573610544205,
      0.07467956840991974,
      0.026048000901937485,
      -0.0587586909532547,
      0.09747481346130371,
      -0.040585894137620926,
      -0.04497392848134041,
      -0.00839279592037201,
      0.00838469248265028,
      0.07384494692087173,
      -0.0362844243645668,
      -0.04604369401931763,
      0.03115961141884327,
      -0.02068314515054226,
      0.014165135100483894,
      -0.06116398051381111,
      0.11299697309732437,
      0.033392079174518585,
      -0.050285704433918,
      -0.0676608458161354,
      0.009230708703398705,
      -0.03711394593119621,
      -0.09626078605651855,
      -0.037428952753543854,
      -0.002170379040762782,
      -0.003492071060463786,
      0.00834741909056902,
      0.08138389885425568,
      -0.0527900867164135,
      -0.014235207810997963,
      0.0713978037238121,
      -0.11602044105529785,
      -0.09714855998754501,
      -0.029243160039186478,
      -0.0210758987814188,
      0.04566642642021179,
      -0.013583348132669926,
      0.08924556523561478,
      0.04138551279902458,
      -0.02395438589155674,
      0.010410803370177746,
      0.02832762524485588,
      -0.0846157819032669,
      0.05826845392584801,
      -0.07784925401210785,
      -0.03289197012782097,
      0.007137177512049675,
      0.02177412249147892,
      -0.021903323009610176,
      0.0001644863368710503,
      -0.001397552783600986,
      -0.004975793417543173,
      -0.05078652873635292,
      0.014246247708797455,
      0.014010920189321041,
      -0.018583646044135094,
      0.060770291835069656,
      -0.04334791377186775,
      0.05586932972073555,
      0.012233184650540352,
      0.09602472931146622,
      0.06760916858911514,
      -0.0478484146296978,
      0.015295704826712608,
      0.005072536412626505,
      0.05205054581165314,
      0.051203373819589615,
      0.002690852852538228,
      0.008013312704861164,
      -0.03321905434131622,
      0.03314916044473648,
      0.0026240467559546232,
      -0.0159914493560791,
      -0.03450363874435425,
      -0.040162697434425354,
      0.010822205804288387,
      -0.07424378395080566,
      -0.02696659043431282,
      0.0032735278364270926,
      -0.06800118833780289,
      0.012600754387676716,
      -0.007570798508822918,
      0.008345850743353367,
      0.05210888758301735,
      0.003994331695139408,
      -0.04005942866206169,
      0.026815522462129593,
      0.05184885114431381,
      -0.003549948101863265,
      -0.027892975136637688,
      -0.019844096153974533,
      -0.016590682789683342,
      0.02348487451672554,
      -0.0014912043698132038,
      -0.03889936953783035,
      -0.013160035945475101,
      -0.01582820899784565,
      0.026587340980768204,
      -0.0230853371322155,
      0.007131843827664852,
      0.009757882915437222,
      0.04231186956167221,
      0.0935375913977623,
      -0.002603250788524747,
      -0.08613378554582596,
      -0.02493642270565033,
      0.003732571844011545,
      0.07936101406812668,
      -0.08719975501298904,
      -0.041536685079336166,
      0.07385223358869553,
      0.037873975932598114,
      0.014216535724699497,
      -0.07658311724662781,
      -0.02473650872707367,
      -0.054996006190776825,
      -0.03841104358434677,
      -0.04261893779039383,
      -0.07911421358585358,
      -0.018872777000069618
    ],
    [
      0.017978671938180923,
      0.07839030772447586,
      0.060702309012413025,
      0.0036499209236353636,
      0.10406077653169632,
      0.05094795674085617,
      -0.0009861259022727609,
      0.014778115786612034,
      0.03744642809033394,
      -0.02021348662674427,
      0.06213852018117905,
      0.04151523485779762,
      0.010752664878964424,
      -0.025061115622520447,
      0.011947906576097012,
      0.04699892923235893,
      0.04083829000592232,
      0.07139024883508682,
      -0.0004395762807689607,
      -0.009250718168914318,
      -0.0412052683532238,
      -0.021966010332107544,
      0.0008280181791633368,
      0.12812726199626923,
      0.11905484646558762,
      0.0462055541574955,
      0.01577000506222248,
      -0.07609748095273972,
      -0.03420371562242508,
      -0.02588607557117939,
      0.11236637085676193,
      0.04882143810391426,
      0.006810272578150034,
      -0.04339609295129776,
      -0.03732726350426674,
      0.0453399121761322,
      -0.01358814351260662,
      -0.02321053296327591,
      -0.0050736479461193085,
      -0.0015729543520137668,
      -0.0798967033624649,
      -0.017349695786833763,
      0.01422200072556734,
      0.008889994584023952,
      -0.010496648028492928,
      -0.043672360479831696,
      -0.030743790790438652,
      0.020387813448905945,
      0.07452411949634552,
      -0.06183939427137375,
      0.017577119171619415,
      0.0286850668489933,
      0.0644974559545517,
      0.058564815670251846,
      0.03208643198013306,
      0.022396666929125786,
      -0.0006742270197719336,
      -0.03183929994702339,
      -0.0507240854203701,
      0.01980936899781227,
      0.06803744286298752,
      -0.000886745925527066,
      0.0067326463758945465,
      0.03635073080658913,
      0.06534899026155472,
      -0.053676702082157135,
      0.020816417410969734,
      0.01416683942079544,
      0.04758734256029129,
      -0.023300722241401672,
      -0.020104795694351196,
      -0.002138592302799225,
      0.01286601833999157,
      0.02490639127790928,
      -0.1279003769159317,
      -0.004009506665170193,
      0.0004187418380752206,
      -0.046316493302583694,
      -0.044150106608867645,
      0.005421758629381657,
      0.024366678670048714,
      -0.03560587391257286,
      -0.013870839960873127,
      -0.006114940624684095,
      0.0099567212164402,
      0.0022149309515953064,
      0.03547769412398338,
      -0.07843463867902756,
      0.001747311558574438,
      0.012704040855169296,
      0.007026282604783773,
      -0.0035476223565638065,
      -0.006642329506576061,
      -0.08603033423423767,
      -0.06336037069559097,
      0.09692776948213577,
      0.021396275609731674,
      0.003587339771911502,
      0.025055186823010445,
      0.05770406126976013,
      -0.041887808591127396,
      -0.09359903633594513,
      0.014348217286169529,
      -0.02360466681420803,
      0.03589590638875961,
      0.012430468574166298,
      -0.04484981298446655,
      -0.012678119353950024,
      0.008305701427161694,
      0.0547943040728569,
      0.0012933791149407625,
      -0.020299844443798065,
      0.03232254460453987,
      -0.017030514776706696,
      -0.06754548847675323,
      -0.03415004163980484,
      -0.06791657209396362,
      -0.04686403274536133,
      -0.055046502500772476,
      -0.05452698841691017,
      0.005007870029658079,
      0.07580353319644928,
      -0.023782361298799515,
      0.04537787288427353,
      0.011229841969907284,
      -0.008193191140890121,
      -0.011973202228546143,
      0.09464529156684875,
      0.011888227425515652,
      -0.02352900430560112,
      -0.08690528571605682,
      -0.03247703239321709,
      0.058671560138463974,
      0.020079219713807106,
      -0.026391007006168365,
      -0.026474036276340485,
      0.021757038310170174,
      -0.05172690004110336,
      -0.026765799149870872,
      0.024890558794140816,
      -0.009168624877929688,
      0.008490529842674732,
      -0.05253657326102257,
      0.09218323230743408,
      -0.03668908774852753,
      0.002492120023816824,
      -0.010963460430502892,
      -0.026019232347607613,
      0.011251484043896198,
      0.00022678269306197762,
      -0.008148838765919209,
      0.032863933593034744,
      -0.002883152337744832,
      0.05862854793667793,
      0.004795693326741457,
      0.03606024011969566,
      0.031798578798770905,
      0.039690591394901276,
      0.045828286558389664,
      0.06972113251686096,
      0.027332022786140442,
      0.07490326464176178,
      -0.03156458958983421,
      0.08796517550945282,
      0.07391637563705444,
      -0.05190970003604889,
      0.003638381604105234,
      0.011247765272855759,
      -0.00861579179763794,
      0.021286197006702423,
      0.047828495502471924,
      0.07244768738746643,
      -0.001364194555208087,
      0.0060304696671664715,
      -0.01616794243454933,
      -0.003238304052501917,
      0.00909271277487278,
      -0.0541253499686718,
      0.025060417130589485,
      -0.009384525939822197,
      -0.0028062784112989902,
      0.0292207058519125,
      0.020378295332193375,
      -0.01151213888078928,
      0.039444517344236374,
      -0.06923725455999374,
      0.0771157443523407,
      -0.01838262379169464,
      -0.032859303057193756,
      0.028816288337111473,
      0.06272904574871063,
      0.1231524869799614,
      -0.056422848254442215,
      -0.06735849380493164,
      -0.010305170901119709,
      0.03905533626675606,
      -0.048070672899484634,
      -0.0016117565101012588,
      0.03832051157951355,
      -0.06031505763530731,
      0.06796446442604065,
      0.053372666239738464,
      0.08968459814786911,
      0.012426932342350483,
      0.025147464126348495,
      -0.011176304891705513,
      0.05806946009397507,
      0.012812101282179356,
      0.014621010050177574,
      -0.0616484209895134,
      -0.0035019409842789173,
      -0.04459911584854126,
      0.0005029496969655156,
      -0.019107583910226822,
      -0.006483737379312515,
      0.004118811339139938,
      -0.06894107908010483,
      -0.027953630313277245,
      -0.03470353037118912,
      -0.08401655405759811,
      -0.10478171706199646,
      0.003902159631252289,
      0.041460420936346054,
      0.08167988806962967,
      0.004898867104202509,
      0.0027625244110822678,
      0.04293986037373543,
      0.047423314303159714,
      0.09302323311567307,
      0.00047457576147280633,
      -0.0026810639537870884,
      0.053013499826192856,
      -0.028375916182994843,
      0.006956227123737335,
      -0.031478993594646454,
      0.06781474500894547,
      -0.006521877367049456,
      -0.002006121212616563,
      0.07275179028511047,
      -0.0520731620490551,
      -0.04115131124854088,
      0.04856916889548302,
      0.010921156965196133,
      0.07997840642929077,
      0.012924396432936192,
      0.07514439523220062,
      -0.011621905490756035,
      -0.07455283403396606,
      0.040557824075222015,
      0.019051823765039444,
      -0.026688948273658752,
      -0.012414272874593735,
      0.037050873041152954,
      0.03610627353191376,
      0.09303595870733261,
      0.11215981096029282,
      0.014056539162993431,
      -0.09296825528144836,
      0.05104798823595047,
      -0.017063669860363007,
      0.03607230260968208,
      -0.09432078897953033,
      0.046806346625089645,
      0.03921819478273392,
      -0.07854229211807251,
      0.07131004333496094,
      0.0242768544703722,
      0.05728745833039284,
      -0.06138303875923157,
      -0.04531921446323395,
      0.08487255871295929,
      -0.0018317005597054958,
      -0.05858305096626282,
      -0.00497921509668231,
      0.05729996785521507,
      0.009896968491375446,
      0.020908549427986145,
      -0.013724335469305515,
      0.051493093371391296,
      -0.027748627588152885,
      -0.011789899319410324,
      0.03774365037679672,
      0.06173001602292061,
      0.0025894721038639545,
      0.025255680084228516,
      0.026478929445147514,
      0.01423684787005186,
      -0.038045890629291534,
      0.0010083200177177787,
      -0.030504662543535233,
      0.11702229082584381,
      -0.0069831255823373795,
      0.020804647356271744,
      0.01812010072171688,
      0.03673997148871422,
      0.02340208925306797,
      0.04689951613545418,
      -0.0019316233228892088,
      0.013665232807397842,
      0.05917826667428017,
      -0.0016586626879870892,
      -0.05326893553137779,
      -0.06559605896472931,
      -0.05946878716349602,
      -0.06824254989624023,
      -0.004801197908818722,
      0.00648199999704957,
      0.014449691399931908,
      -0.034335434436798096,
      0.06616494804620743,
      -0.07832544296979904,
      0.004424900282174349,
      -0.010011834092438221,
      -0.0190748181194067,
      0.010876594111323357,
      0.016110749915242195,
      0.013724207878112793,
      0.0020266587380319834,
      0.051235467195510864,
      0.011740216054022312,
      -0.048524223268032074,
      0.057015299797058105,
      0.03636623173952103,
      -0.04361644759774208,
      -0.030436847358942032,
      -0.004600233864039183,
      -0.037910401821136475,
      0.007620527874678373,
      -0.0705484002828598,
      0.030656510964035988,
      0.04631398990750313,
      0.0059160771779716015,
      -0.0087709566578269,
      -0.01201327983289957,
      0.018715789541602135,
      -0.012750713154673576,
      0.0023491643369197845,
      -0.04566698148846626,
      -0.021898364648222923,
      0.07083464413881302,
      0.02542702667415142,
      -0.006666574627161026,
      0.016212452203035355,
      0.0054720123298466206,
      0.017152465879917145,
      0.0014234541449695826,
      -0.017809836193919182,
      0.030288424342870712,
      0.017709584906697273,
      0.01735789328813553,
      0.06249207630753517,
      0.00620665168389678,
      0.011889593675732613,
      0.02957938425242901,
      -0.03025784343481064,
      -0.0292342621833086,
      0.015243392437696457,
      0.04107949510216713,
      0.005769059062004089,
      -0.023532649502158165,
      -0.06942985951900482,
      -0.012806804850697517,
      -0.02736341767013073,
      -0.024092037230730057,
      -0.005556148011237383,
      0.0009419366833753884,
      0.0848880335688591,
      0.02476571872830391,
      0.030845563858747482,
      0.053318362683057785,
      0.0367271713912487,
      0.025835372507572174,
      -0.059932563453912735,
      0.04887651652097702,
      -0.004846497438848019,
      -0.04432301223278046,
      -0.05347975343465805,
      0.00019692000932991505,
      -0.05407601594924927,
      -0.05126872658729553,
      -0.00822624284774065,
      0.03785351291298866,
      0.0509394109249115,
      0.0001997693907469511,
      0.03350406140089035,
      0.03418722376227379,
      -0.012177790515124798,
      -0.018041612580418587,
      0.02921312488615513,
      0.029906263574957848,
      -0.05954891815781593,
      0.024990849196910858,
      -0.003025247249752283,
      0.008813116699457169,
      -0.07418296486139297,
      -0.09201738983392715,
      -0.008319535292685032,
      0.00527070602402091,
      0.04033224657177925,
      0.005572306923568249,
      -0.02715075947344303,
      0.05287717655301094,
      0.03300648555159569,
      -0.0005272040725685656,
      0.07901347428560257,
      0.005409921053797007,
      0.02791094034910202,
      0.07541919499635696,
      -0.05063371732831001,
      -0.008691927418112755,
      -0.08283484727144241,
      0.01588498242199421,
      -0.06253606081008911,
      0.014281854964792728,
      0.004872859455645084,
      0.04009731486439705,
      -0.10578539222478867,
      -0.032188206911087036,
      -0.02466076798737049,
      -0.0626387894153595,
      0.030285373330116272,
      -0.009522905573248863,
      0.043586790561676025,
      0.009618853218853474,
      -0.010499066673219204,
      0.04370734840631485,
      0.018488392233848572,
      0.05185431241989136,
      -0.04739031940698624,
      -0.046910881996154785,
      -0.016133612021803856,
      -0.013334229588508606,
      0.025042476132512093,
      -0.02947487309575081,
      -0.09660104662179947,
      0.0030153559055179358,
      0.0003364576259627938,
      0.005022987723350525,
      0.036133766174316406,
      -0.012061943300068378,
      -0.03256788104772568,
      -0.03917057067155838,
      -0.0022075269371271133,
      0.017016686499118805,
      0.039864152669906616,
      0.012297585606575012,
      -0.022683996707201004,
      -0.0911838635802269,
      -0.0764305368065834,
      -0.03086007758975029,
      0.007421107962727547,
      0.04005759581923485,
      -0.012638892978429794,
      -0.002208521356806159,
      -0.027908628806471825,
      0.009597577154636383,
      0.0366910919547081,
      0.007453534752130508,
      -0.017506131902337074,
      0.023946184664964676,
      0.024472448974847794,
      0.106169193983078,
      0.0494832806289196,
      0.013370911590754986,
      0.06849285215139389,
      -0.02190629206597805,
      -0.004518048372119665,
      0.0031439783051609993,
      -0.04180174693465233,
      0.006000532768666744,
      -0.06873214244842529,
      0.05658818781375885,
      0.029430881142616272,
      -0.08111629635095596,
      -0.004135627765208483,
      0.029753588140010834,
      -0.07954473048448563,
      0.009497100487351418,
      0.11253973841667175,
      0.04870322719216347,
      -0.023151658475399017,
      0.05196424946188927,
      0.0722653716802597,
      -0.03313001990318298,
      0.0926288366317749,
      -0.0011237863218411803,
      0.009036430157721043,
      -0.08102922141551971,
      -0.02147507295012474,
      -0.03724019229412079,
      0.0001274890237255022,
      -0.039794519543647766,
      -0.03666792809963226,
      0.025462305173277855,
      -0.06321515887975693,
      -0.012300846166908741,
      -0.06251519173383713,
      0.022163568064570427,
      0.01707077957689762,
      0.01203378476202488,
      -0.048714905977249146,
      0.010762710124254227,
      0.00459635816514492
    ],
    [
      -0.022888265550136566,
      -0.026704758405685425,
      0.01051125954836607,
      -0.002362161176279187,
      -0.030627282336354256,
      0.0451626293361187,
      -0.014548264443874359,
      -0.055708300322294235,
      -0.012998084537684917,
      0.023884961381554604,
      -0.010369529016315937,
      0.012848049402236938,
      -0.010657522827386856,
      0.041174713522195816,
      -0.048598241060972214,
      0.0036321834195405245,
      -0.008655117824673653,
      0.04822373017668724,
      -0.018663767725229263,
      -0.038486409932374954,
      -0.02599634788930416,
      -0.01634817384183407,
      0.04880112037062645,
      0.0059595671482384205,
      -0.07845868170261383,
      -0.003638326423242688,
      0.029139842838048935,
      0.03534894064068794,
      0.024691153317689896,
      0.02338927797973156,
      -0.05471206083893776,
      0.07165630906820297,
      0.018363798037171364,
      0.030802171677350998,
      0.10941166430711746,
      0.04585801064968109,
      0.0029420098289847374,
      -0.10060624778270721,
      0.045593488961458206,
      -0.04539089649915695,
      -0.05490490049123764,
      0.017720293253660202,
      -0.009660123847424984,
      -0.0010559179354459047,
      0.027093173936009407,
      -0.0047205593436956406,
      -0.014530230313539505,
      0.08215748518705368,
      0.008647591806948185,
      -0.056424323469400406,
      0.06523463129997253,
      0.04402441903948784,
      0.07905060052871704,
      0.03881971910595894,
      0.024408627301454544,
      0.0005581307341344655,
      0.018564309924840927,
      0.03652292862534523,
      0.03583405166864395,
      -0.01386255118995905,
      0.012255982495844364,
      0.006224313285201788,
      0.004107729997485876,
      -0.031301792711019516,
      -0.07821222394704819,
      -0.09457939863204956,
      0.025940293446183205,
      0.012952466495335102,
      -0.015942728146910667,
      -0.01573694683611393,
      0.010763982310891151,
      0.018495755270123482,
      0.007717770058661699,
      0.01319288183003664,
      0.01047434564679861,
      0.01459452323615551,
      -0.06948522478342056,
      0.048985183238983154,
      0.08261240273714066,
      -0.04307746887207031,
      0.07932697981595993,
      0.0016855821013450623,
      -0.05656098201870918,
      0.0768042653799057,
      0.006690079811960459,
      0.0057325041852891445,
      -0.041941288858652115,
      -0.02294308692216873,
      -0.09442920237779617,
      0.009918930009007454,
      0.022150300443172455,
      -0.017192736268043518,
      -0.0629376694560051,
      0.03437760844826698,
      0.013519302941858768,
      0.024233171716332436,
      0.029707934707403183,
      -0.02622360922396183,
      0.0849556252360344,
      0.03654127195477486,
      -0.07306091487407684,
      0.0511179193854332,
      -0.07174727320671082,
      -0.07069414854049683,
      -0.0187442097812891,
      0.040744081139564514,
      -0.033998459577560425,
      -0.05082669481635094,
      0.015781819820404053,
      -0.0417758971452713,
      0.02338961698114872,
      0.0027799278032034636,
      -0.06316705048084259,
      -0.04207739606499672,
      0.027800990268588066,
      0.05179847031831741,
      0.03343049809336662,
      -0.04155464470386505,
      -0.06186885014176369,
      -0.024285053834319115,
      -0.08028918504714966,
      0.03734826669096947,
      0.025159819051623344,
      -0.027550093829631805,
      0.03657925873994827,
      0.05252033844590187,
      0.005685640964657068,
      -0.02188963256776333,
      0.06530918180942535,
      -0.004197986796498299,
      -0.05738884583115578,
      -0.0580587200820446,
      -0.04643671214580536,
      -0.03837436065077782,
      -0.0529434010386467,
      -0.03409358859062195,
      -0.019386449828743935,
      -0.019532261416316032,
      0.04424216225743294,
      0.0863419622182846,
      0.09792113304138184,
      0.015083694830536842,
      0.029559481889009476,
      0.01588613912463188,
      0.09644292294979095,
      0.00011984313459834084,
      0.06801009923219681,
      0.004491612780839205,
      0.06543295085430145,
      0.028557103127241135,
      -0.036443620920181274,
      -0.04429345205426216,
      0.012083332054316998,
      -0.0026262165047228336,
      -0.041454676538705826,
      -0.0044310977682471275,
      -0.014043771661818027,
      -0.014624698087573051,
      -0.06558941304683685,
      -0.07191700488328934,
      0.0016968940617516637,
      0.0036791549064219,
      0.043636504560709,
      -0.04736962914466858,
      0.047322794795036316,
      0.008554495871067047,
      -0.007880703546106815,
      -0.027047239243984222,
      0.007231319788843393,
      -0.03335389867424965,
      0.05907529219985008,
      -0.031465087085962296,
      -0.027447987347841263,
      -0.08075013756752014,
      -0.0373506173491478,
      -0.07783573120832443,
      -0.04003886505961418,
      0.03591297194361687,
      0.0042320373468101025,
      -0.07827862352132797,
      -0.07938732951879501,
      -0.059884168207645416,
      0.03736235573887825,
      0.003425084287300706,
      0.020697539672255516,
      -0.04584764316678047,
      -0.009907573461532593,
      0.020902356132864952,
      -0.04154675081372261,
      0.022405153140425682,
      0.013564619235694408,
      0.027094317600131035,
      0.06272536516189575,
      0.017555629834532738,
      0.0813475102186203,
      -0.049343470484018326,
      -0.0021609521936625242,
      0.10178382694721222,
      -0.03980295732617378,
      -0.049765750765800476,
      -0.0450945608317852,
      0.012304453179240227,
      -0.009219600819051266,
      0.002245084848254919,
      -0.038861822336912155,
      0.03829563409090042,
      -0.012876473367214203,
      0.044342998415231705,
      0.010881060734391212,
      0.010676545090973377,
      0.0051197391003370285,
      -0.026517294347286224,
      -0.007922271266579628,
      -0.0588320717215538,
      -0.038745176047086716,
      0.03616756573319435,
      -0.05561814829707146,
      -0.08208126574754715,
      0.012068361975252628,
      0.006604285910725594,
      -0.11309265345335007,
      0.05713629722595215,
      -0.06445150077342987,
      0.0236138217151165,
      -0.0691869780421257,
      -0.015289174392819405,
      -0.044233422726392746,
      -0.02910686656832695,
      0.011735019274055958,
      0.06293284147977829,
      -0.019031383097171783,
      -0.02796890400350094,
      0.007219386752694845,
      -0.021808713674545288,
      0.05681559443473816,
      0.017116039991378784,
      0.057484645396471024,
      -0.020842673256993294,
      0.05740960314869881,
      0.030508602038025856,
      -0.06077635660767555,
      -0.02787751704454422,
      0.051605816930532455,
      0.05254168435931206,
      0.06338707357645035,
      -0.0034165463875979185,
      0.04976941645145416,
      -0.013509821146726608,
      0.01809421367943287,
      -0.08809704333543777,
      0.014987315982580185,
      0.058419957756996155,
      -0.016067033633589745,
      -0.049408506602048874,
      -0.030414031818509102,
      0.02890852466225624,
      0.03939279168844223,
      -0.05037793517112732,
      0.0007955920300446451,
      -0.01369071938097477,
      -0.030607402324676514,
      -0.11436709016561508,
      -0.06569282710552216,
      0.07391282916069031,
      0.00277945795096457,
      -0.018377695232629776,
      0.040310390293598175,
      0.031505387276411057,
      -0.02216278575360775,
      0.030095268040895462,
      -0.012052339501678944,
      -0.04058575630187988,
      0.012480159290134907,
      -0.06849851459264755,
      -0.00034851249074563384,
      0.019681517034769058,
      -0.03478005900979042,
      -0.05375146120786667,
      0.01117204874753952,
      0.0933598056435585,
      0.07395663857460022,
      -0.07264359295368195,
      -0.0026027332060039043,
      0.010871830396354198,
      -0.012942260131239891,
      -0.038933902978897095,
      0.10286936163902283,
      -0.018962658941745758,
      -0.037037111818790436,
      0.033031340688467026,
      0.046803053468465805,
      -0.01899552159011364,
      0.035766854882240295,
      0.0004599207313731313,
      -0.04667707532644272,
      -0.0036329312715679407,
      0.04660326614975929,
      -0.03787323087453842,
      0.08020106703042984,
      -0.11352729797363281,
      0.008461808785796165,
      0.0022907000966370106,
      0.07290380448102951,
      -0.021490788087248802,
      0.016572630032896996,
      -0.100192129611969,
      0.037376902997493744,
      -0.04324033856391907,
      0.0025205628480762243,
      0.03295101597905159,
      0.028836460784077644,
      0.06118497624993324,
      -0.03676263242959976,
      0.03960637375712395,
      -0.0410802997648716,
      -0.052243053913116455,
      0.01006828062236309,
      -0.05533437803387642,
      -0.05758342146873474,
      0.08232610672712326,
      0.07264068722724915,
      -0.02884373813867569,
      0.010341737419366837,
      0.03492850437760353,
      -0.016393443569540977,
      -0.061160556972026825,
      0.04390890523791313,
      -0.02544393204152584,
      -0.0006071782554499805,
      -0.027829233556985855,
      -0.012390843592584133,
      -0.050415974110364914,
      0.03377395123243332,
      -0.0013657832751050591,
      -0.022509003058075905,
      0.018373671919107437,
      0.06273028999567032,
      -0.03243293985724449,
      0.08770231157541275,
      -0.04083513841032982,
      -0.0056143226101994514,
      -0.008364026434719563,
      -0.0958310067653656,
      0.02266114391386509,
      0.07807093858718872,
      -0.021807627752423286,
      0.013109575025737286,
      0.07801225781440735,
      -0.024842556565999985,
      0.0053495303727686405,
      -0.02959059737622738,
      -0.04430297017097473,
      0.036620479077100754,
      0.06955976039171219,
      -0.005833718925714493,
      0.06319001317024231,
      -0.07621177285909653,
      -0.003811354748904705,
      0.0075057088397443295,
      -0.04865796118974686,
      0.016512785106897354,
      -0.025762498378753662,
      0.04080476239323616,
      -0.032764334231615067,
      0.020628321915864944,
      -0.0764412060379982,
      0.020710943266749382,
      -0.023907994851469994,
      -0.0013078369665890932,
      0.07929979264736176,
      -0.037243738770484924,
      -0.004606922622770071,
      0.05133785307407379,
      0.029149556532502174,
      -0.010267429053783417,
      -0.056796152144670486,
      -0.014147844165563583,
      0.009819865226745605,
      0.020705696195364,
      0.07630845159292221,
      0.07131938636302948,
      -0.0014447473222389817,
      0.04409182816743851,
      -0.01786407269537449,
      -0.06018582358956337,
      -0.054565753787755966,
      -0.0022122301161289215,
      0.07192636281251907,
      -0.060067471116781235,
      -0.01357931736856699,
      0.010194666683673859,
      0.08049391210079193,
      0.13157877326011658,
      -0.03227183222770691,
      0.0009239407372660935,
      -0.00558454217389226,
      0.04702724516391754,
      0.006919861771166325,
      -0.016228463500738144,
      -0.039687156677246094,
      0.010511199943721294,
      0.061858199536800385,
      0.06064014136791229,
      -0.03577657416462898,
      -0.07244271785020828,
      0.011404169723391533,
      0.028599144890904427,
      -0.07278098911046982,
      -0.01724562793970108,
      0.0893387496471405,
      0.06301361322402954,
      0.05972374230623245,
      -0.021327130496501923,
      0.06222054362297058,
      -0.016981765627861023,
      -0.07371821254491806,
      0.000255499966442585,
      0.019887719303369522,
      0.03091595321893692,
      0.015339242294430733,
      -0.0386526919901371,
      0.0036805898416787386,
      0.021126490086317062,
      0.005405581556260586,
      0.022770222276449203,
      0.030872447416186333,
      0.04609014093875885,
      -0.04077225923538208,
      0.019101357087492943,
      -0.07417568564414978,
      0.06320390105247498,
      -0.018280647695064545,
      0.034935563802719116,
      -0.06685999780893326,
      -0.02145659178495407,
      0.010170575231313705,
      0.030660083517432213,
      -0.04131108149886131,
      0.010101383551955223,
      -0.07851850986480713,
      0.043410222977399826,
      -0.05229419097304344,
      -0.022082187235355377,
      -0.005211079493165016,
      -0.03972160816192627,
      -0.043379105627536774,
      0.035377249121665955,
      -0.020031196996569633,
      0.015628332272171974,
      -0.06205611303448677,
      -0.002221787581220269,
      -0.003779791994020343,
      -0.021574655547738075,
      -0.04430033266544342,
      0.03188195452094078,
      0.008398152887821198,
      -0.03621448203921318,
      0.018380537629127502,
      -0.0470719113945961,
      -0.014169863425195217,
      -0.02999262325465679,
      -0.05138309672474861,
      -0.03642525523900986,
      -0.007009320892393589,
      -0.01648746244609356,
      4.166948565398343e-05,
      -0.0664842501282692,
      -0.009269021451473236,
      -0.014903469942510128,
      0.019786519929766655,
      0.04539845511317253,
      0.04272044077515602,
      -0.0033862886484712362,
      -0.05868351459503174,
      0.012575838714838028,
      -0.10280687361955643,
      0.049195948988199234,
      0.030599597841501236,
      0.0011910430621355772,
      0.048056211322546005,
      -0.015230895020067692,
      -0.03747410699725151,
      -0.09348847717046738,
      -0.011933067813515663,
      -0.03407549113035202,
      0.04931806027889252,
      0.026188058778643608,
      0.01827131398022175,
      -0.0016004872741177678,
      0.04908682778477669,
      0.002871586475521326,
      -0.014162537641823292,
      -0.04893859848380089,
      -0.0758260041475296,
      0.03217471018433571,
      -0.09511119872331619,
      0.09318606555461884,
      -0.03224853426218033,
      0.01799481175839901,
      0.024885088205337524,
      0.045012347400188446,
      -0.03229045867919922,
      -0.019900858402252197
    ],
    [
      0.017272135242819786,
      -0.0037958489265292883,
      -0.02168089896440506,
      0.012893391773104668,
      -0.01287885196506977,
      -0.048207495361566544,
      -0.0654146820306778,
      0.016838129609823227,
      -0.04433085024356842,
      0.006842419039458036,
      -0.05762159079313278,
      0.009794361889362335,
      -0.020076381042599678,
      -0.030174434185028076,
      0.01105743832886219,
      0.035465843975543976,
      -0.07479994744062424,
      -0.01948622427880764,
      0.023971525952219963,
      -0.028408704325556755,
      -0.02106613852083683,
      -0.02863444574177265,
      0.027292976155877113,
      -0.027277003973722458,
      0.05350229889154434,
      0.004045270383358002,
      -0.016937583684921265,
      0.06207352504134178,
      0.09008058160543442,
      -0.015145564451813698,
      0.009862286038696766,
      0.044339701533317566,
      0.04275102913379669,
      0.005762419197708368,
      0.09078610688447952,
      -0.029050687327980995,
      0.06320909410715103,
      0.016722744330763817,
      -0.01899878866970539,
      0.03203561529517174,
      -0.06700138002634048,
      -0.015374632552266121,
      0.019182711839675903,
      -0.14919354021549225,
      -0.015541743487119675,
      -0.025838619098067284,
      0.0682760551571846,
      -0.0063863154500722885,
      -0.00462624104693532,
      -0.0074106366373598576,
      0.017572687938809395,
      0.0815463736653328,
      -0.04343249648809433,
      0.06009499728679657,
      -0.043426014482975006,
      0.04879876226186752,
      -0.04711338132619858,
      0.05473823845386505,
      0.09740374237298965,
      0.042463090270757675,
      0.01433271449059248,
      0.027997197583317757,
      0.032351914793252945,
      -0.01611543446779251,
      0.00892810057848692,
      0.0558149591088295,
      0.05188049376010895,
      0.010501359589397907,
      0.05099350959062576,
      0.053326331079006195,
      0.026517946273088455,
      0.05162592977285385,
      -0.024948136880993843,
      0.04416413605213165,
      -0.07149086892604828,
      0.0321752093732357,
      0.0411161370575428,
      0.041251376271247864,
      0.11321859061717987,
      -0.05607456713914871,
      0.031336668878793716,
      0.057918794453144073,
      0.08668624609708786,
      0.0004733398091048002,
      -0.003637677989900112,
      0.010644751600921154,
      -0.08495301753282547,
      -0.03456448018550873,
      -0.011158172972500324,
      -0.04079963639378548,
      -0.055327724665403366,
      -0.03089814819395542,
      -0.01319045852869749,
      0.12129084020853043,
      -0.0345192514359951,
      0.005895240232348442,
      0.010299102403223515,
      -0.013185135088860989,
      -0.019581791013479233,
      0.026317158713936806,
      0.04198071360588074,
      -0.013051806949079037,
      0.002961969468742609,
      0.08040764927864075,
      0.017010614275932312,
      -0.022718463093042374,
      -0.0007654651999473572,
      -0.028289375826716423,
      0.0029000677168369293,
      -0.059898339211940765,
      -0.081065334379673,
      -0.047815948724746704,
      -0.015822838991880417,
      0.02223663032054901,
      -0.07112598419189453,
      -0.06825334578752518,
      -0.012028190307319164,
      -0.012039816938340664,
      -0.010845533572137356,
      0.03768928721547127,
      -0.014395968057215214,
      -0.046745117753744125,
      0.04624304920434952,
      0.014931519515812397,
      -0.008229036815464497,
      -0.04652244225144386,
      -0.03218360245227814,
      0.055409759283065796,
      -0.03285283222794533,
      -0.020357858389616013,
      -0.03198656439781189,
      -0.024672681465744972,
      0.004812945611774921,
      0.008196969516575336,
      0.024300910532474518,
      -0.06836088746786118,
      -0.07868839800357819,
      0.0040300628170371056,
      -0.03390851616859436,
      -0.0063210600055754185,
      0.009248868562281132,
      0.023765742778778076,
      0.03659282997250557,
      0.10136217623949051,
      -0.0339176170527935,
      0.07426134496927261,
      -0.029383331537246704,
      -0.042784519493579865,
      -0.12114772200584412,
      0.015721432864665985,
      -0.06298300623893738,
      0.052347954362630844,
      0.008444668725132942,
      -0.04004320502281189,
      -0.0793321430683136,
      -0.06523812562227249,
      0.05043608322739601,
      0.01558088231831789,
      0.054966896772384644,
      0.00014512729831039906,
      0.03201751038432121,
      0.01175068411976099,
      0.034858137369155884,
      -0.011747154407203197,
      -0.005062458571046591,
      -0.045454241335392,
      -0.031495943665504456,
      -0.07707806676626205,
      0.023905500769615173,
      0.035410553216934204,
      -0.011030776426196098,
      0.036512501537799835,
      0.0536094568669796,
      -0.021597780287265778,
      -0.021459033712744713,
      -0.0419650599360466,
      0.036182552576065063,
      -0.0028652409091591835,
      0.03320840746164322,
      0.06304649263620377,
      0.007441199850291014,
      -0.00760895200073719,
      0.01300408411771059,
      0.008595515042543411,
      -0.002976739779114723,
      -0.12383556365966797,
      -0.008023441769182682,
      -0.02234642021358013,
      -0.15433505177497864,
      -0.019493775442242622,
      -0.009560033679008484,
      -0.003766947193071246,
      0.01073754858225584,
      -0.0065218559466302395,
      0.04091339558362961,
      -0.07674642652273178,
      -0.02349802851676941,
      -0.00943493191152811,
      0.09683101624250412,
      0.04352063313126564,
      -0.023641230538487434,
      0.018164891749620438,
      -0.0035300354938954115,
      -0.011649157851934433,
      0.02828899770975113,
      -0.06202375143766403,
      0.03279297798871994,
      -0.000511733116582036,
      0.07791637629270554,
      0.05591604858636856,
      -0.008405040018260479,
      -0.04774219170212746,
      0.08780929446220398,
      -0.032871734350919724,
      -0.007226338144391775,
      0.02565469965338707,
      -0.04793640971183777,
      0.015794381499290466,
      -0.08249995112419128,
      -0.06866724044084549,
      0.030837925150990486,
      0.07096952199935913,
      0.03731665760278702,
      -0.025907760486006737,
      -0.0511518195271492,
      0.02384691685438156,
      0.02179059013724327,
      -0.025952810421586037,
      -0.020320918411016464,
      0.010682951658964157,
      -0.030080243945121765,
      0.0605221688747406,
      -0.06795580685138702,
      -0.0491698756814003,
      -0.026402954012155533,
      -0.017878497019410133,
      0.0121799660846591,
      0.017558908089995384,
      0.03766615688800812,
      -0.016010981053113937,
      0.08620635420084,
      0.02033410780131817,
      -0.0005697694141417742,
      0.006021647248417139,
      -0.07768433541059494,
      0.026074443012475967,
      -0.04817305505275726,
      0.045960262417793274,
      0.034174900501966476,
      0.09239372611045837,
      0.027618609368801117,
      -0.018817665055394173,
      0.0236812774091959,
      -0.0011653080582618713,
      -0.03729940950870514,
      0.033560559153556824,
      -0.054392408579587936,
      0.02533883787691593,
      -0.015158621594309807,
      -0.06766777485609055,
      0.016450542956590652,
      -0.10599476099014282,
      0.04706704989075661,
      -0.017659462988376617,
      -0.00599988317117095,
      -0.014996670186519623,
      -0.06467121094465256,
      0.06619133800268173,
      0.0033504096791148186,
      0.009957748465240002,
      0.025381064042448997,
      0.022877678275108337,
      0.011732845567166805,
      0.03956172987818718,
      0.011243848130106926,
      0.02804618701338768,
      0.012653435580432415,
      -0.010167263448238373,
      -0.040027838200330734,
      -0.016030017286539078,
      0.04067561775445938,
      0.07217293232679367,
      -0.009586459025740623,
      -0.018968863412737846,
      -0.03857192024588585,
      0.07054957002401352,
      0.05054298788309097,
      -0.025461040437221527,
      -0.017596077173948288,
      -0.024573931470513344,
      0.025019409134984016,
      0.0332716628909111,
      0.006065486930310726,
      0.011558412574231625,
      0.028776876628398895,
      -0.04917864874005318,
      -0.06182555481791496,
      0.0012440646532922983,
      -0.007959435693919659,
      -0.034084707498550415,
      -0.04629366844892502,
      0.005598391406238079,
      -0.005947932600975037,
      0.00023433681053575128,
      -0.03527520224452019,
      -0.02832425944507122,
      -0.03248513117432594,
      0.009340210817754269,
      -0.022452309727668762,
      0.049880485981702805,
      -0.05753025412559509,
      0.04876139014959335,
      -0.02559402585029602,
      0.03254922106862068,
      -0.012572147883474827,
      -0.013077137991786003,
      0.058048803359270096,
      -0.015523561276495457,
      0.09226007759571075,
      0.014194569550454617,
      -0.01445220410823822,
      -0.02964404597878456,
      -0.011900589801371098,
      0.017381366342306137,
      0.09429430216550827,
      0.03109893389046192,
      -0.010140826925635338,
      0.04430326819419861,
      0.04098626598715782,
      -0.021229535341262817,
      0.06665786355733871,
      0.003291643224656582,
      0.029412562027573586,
      0.00782660860568285,
      0.006815245375037193,
      -0.025204669684171677,
      0.07489823549985886,
      0.00824714731425047,
      -0.04491473361849785,
      -0.006600645836442709,
      -0.005118104163557291,
      -0.0186325591057539,
      0.04052995890378952,
      -0.041165322065353394,
      0.014852083288133144,
      -0.03234340250492096,
      0.0009959597373381257,
      0.055349886417388916,
      0.03148278966546059,
      0.04233996197581291,
      -0.05090036243200302,
      -0.029645513743162155,
      -0.012478317134082317,
      0.02592364326119423,
      -0.07466398179531097,
      -0.03324190899729729,
      -0.007612159010022879,
      -0.016179615631699562,
      0.061883457005023956,
      -0.09744894504547119,
      -0.04705585539340973,
      -0.023455874994397163,
      0.03728976473212242,
      -0.0013242630520835519,
      0.004081228747963905,
      -0.06665460020303726,
      -0.07732973247766495,
      -0.07414170354604721,
      0.024241536855697632,
      0.02979457378387451,
      -0.05244893208146095,
      0.01192503236234188,
      0.005758471786975861,
      0.00019953712762799114,
      0.06963547319173813,
      -0.032155171036720276,
      -0.0067590405233204365,
      -0.07608001679182053,
      0.03784364461898804,
      -0.08411019295454025,
      0.011682464741170406,
      0.004231838043779135,
      0.012973842211067677,
      0.0205320306122303,
      0.012256736867129803,
      0.06967630982398987,
      -0.02976566180586815,
      -0.011804399080574512,
      -0.05255552753806114,
      -0.045693691819906235,
      0.06543131172657013,
      -0.07377827912569046,
      0.028011400252580643,
      -0.05496205389499664,
      -0.028984475880861282,
      0.04713648930191994,
      0.03353922441601753,
      -0.0753215029835701,
      -0.009261049330234528,
      -0.10442958772182465,
      -0.0059994300827383995,
      -0.008247417397797108,
      0.01234624907374382,
      0.01164215337485075,
      -0.11511688679456711,
      0.036474794149398804,
      -0.0898066982626915,
      -0.009343420155346394,
      -0.06684663146734238,
      -0.020750058814883232,
      0.01162914838641882,
      0.015400756150484085,
      0.044797383248806,
      0.004851749166846275,
      -0.0012656835606321692,
      0.02681955322623253,
      0.031346142292022705,
      0.006428163032978773,
      -0.039933014661073685,
      -0.03551275283098221,
      -0.06925302743911743,
      0.03674442693591118,
      -0.017338542267680168,
      -0.03102194331586361,
      0.002783632604405284,
      -0.00420086644589901,
      0.018692132085561752,
      0.002491873223334551,
      -0.003063794458284974,
      0.05358658358454704,
      0.09288350492715836,
      0.027161922305822372,
      -0.003301489632576704,
      0.020916825160384178,
      0.0029249514918774366,
      -0.018260685727000237,
      0.014886460267007351,
      0.02616695500910282,
      0.056391604244709015,
      -0.0105137899518013,
      -0.08733487129211426,
      0.015282346867024899,
      0.03292607516050339,
      -0.1528521180152893,
      0.04840725287795067,
      0.027927322313189507,
      -0.04738285765051842,
      0.01510609406977892,
      -0.026295030489563942,
      0.09870735555887222,
      0.02723470702767372,
      -0.008212100714445114,
      0.029312115162611008,
      -0.025153102353215218,
      -0.028874436393380165,
      0.009819133207201958,
      0.026652267202734947,
      -0.06902703642845154,
      -0.032465800642967224,
      -0.030397262424230576,
      -0.006462754216045141,
      0.125577911734581,
      -0.021809376776218414,
      0.017228886485099792,
      -0.011537718586623669,
      -0.01377742551267147,
      0.005222762003540993,
      0.09196546673774719,
      -0.02368140034377575,
      -0.05060650780797005,
      0.017461568117141724,
      0.0015968357911333442,
      0.04671816900372505,
      0.05129680037498474,
      -0.024246294051408768,
      0.03180428221821785,
      0.09908930957317352,
      -0.006454081274569035,
      -0.003104245522990823,
      -0.003573461901396513,
      0.015087952837347984,
      0.005180965643376112,
      -0.022035742178559303,
      0.02040029503405094,
      -0.022391963750123978,
      -0.05519309639930725,
      -0.02374817244708538,
      -0.022029215469956398,
      -0.044679660350084305,
      -0.006699797231703997,
      0.0008778380579315126,
      0.035496607422828674,
      0.060380857437849045,
      -0.05436209589242935,
      0.0451338030397892,
      0.08674338459968567,
      0.024167243391275406,
      -0.004503426607698202,
      0.05815728008747101,
      0.008048946969211102,
      -0.027618147432804108,
      0.003512513590976596,
      0.019410908222198486
    ],
    [
      -0.0252058207988739,
      -0.007790025789290667,
      -0.006305433809757233,
      -0.028467504307627678,
      -0.0391814224421978,
      0.040774568915367126,
      0.0027233166620135307,
      -0.028421826660633087,
      -0.02323988452553749,
      -0.04250036180019379,
      -0.017512010410428047,
      0.05519138276576996,
      -0.05240432545542717,
      0.06471153348684311,
      0.07459232211112976,
      -0.01660613901913166,
      0.03209598734974861,
      -0.011456589214503765,
      0.011274987831711769,
      -0.014982336200773716,
      -0.0086940573528409,
      0.028911292552947998,
      -0.0015027148183435202,
      -0.002278665080666542,
      -0.029628518968820572,
      -0.03768610581755638,
      -0.14224636554718018,
      -0.0012324933195486665,
      0.0798017680644989,
      -0.021338418126106262,
      -0.0207725390791893,
      0.07212012261152267,
      -0.013808890245854855,
      0.08495011925697327,
      0.055854395031929016,
      -0.07860282063484192,
      -0.0022309375926852226,
      -0.015460863709449768,
      -0.009104441851377487,
      0.04874299839138985,
      0.0023823175579309464,
      0.010109477676451206,
      -0.015531343407928944,
      0.035687196999788284,
      -0.0731835663318634,
      -0.043373946100473404,
      0.040584784001111984,
      0.007357868831604719,
      -0.07372859120368958,
      0.04002122953534126,
      -0.008076217025518417,
      -0.07579199969768524,
      0.01417494472116232,
      -0.0008171523222699761,
      -0.04818050563335419,
      0.013945750892162323,
      -0.009828050620853901,
      -0.04768458008766174,
      -0.03830036148428917,
      0.11371615529060364,
      0.06762497127056122,
      0.01284882053732872,
      0.04693089798092842,
      -0.0658050999045372,
      -0.0572856180369854,
      0.034366730600595474,
      -0.052473656833171844,
      -0.02737552858889103,
      0.009479648433625698,
      -0.039967719465494156,
      0.015663981437683105,
      0.06274726986885071,
      0.0761726051568985,
      0.07870955765247345,
      0.017923276871442795,
      -0.026978231966495514,
      0.043546851724386215,
      0.05918246507644653,
      -0.0006771123735234141,
      0.10478690266609192,
      0.034759003669023514,
      0.030989870429039,
      0.011594779789447784,
      -0.014891734346747398,
      -0.02264515496790409,
      -0.031678762286901474,
      -0.011882917024195194,
      -0.057202767580747604,
      -0.03182753548026085,
      2.7025231247534975e-05,
      -0.054603103548288345,
      0.04973124340176582,
      0.06559933722019196,
      0.04898042231798172,
      0.022525735199451447,
      -0.010278649628162384,
      0.02377895638346672,
      0.030975593253970146,
      -0.03877505660057068,
      0.06185055896639824,
      0.014777756296098232,
      -0.026295188814401627,
      0.09909576177597046,
      -0.00271134776994586,
      -0.034223806113004684,
      0.011338486336171627,
      -0.06590469181537628,
      0.0701797679066658,
      0.01724720560014248,
      0.030018024146556854,
      -0.03019367903470993,
      0.03531079366803169,
      0.01166463177651167,
      -0.05029558390378952,
      0.0060325246304273605,
      -0.051423318684101105,
      0.0030735137406736612,
      0.009734652005136013,
      -0.04896140471100807,
      -0.005541584454476833,
      0.022048428654670715,
      -0.045296311378479004,
      0.0014541245764121413,
      -0.03130582720041275,
      -0.08143822848796844,
      0.0691949874162674,
      -0.10385047644376755,
      -0.021924825385212898,
      0.06662379950284958,
      0.012690363451838493,
      -0.04119054228067398,
      -0.008616229519248009,
      0.051959481090307236,
      0.0010732929222285748,
      0.008309407159686089,
      0.037831541150808334,
      -0.0605977401137352,
      -0.0369701012969017,
      0.007648047059774399,
      0.021455952897667885,
      -0.015297788195312023,
      -0.023547276854515076,
      0.013764095492661,
      -0.06306396424770355,
      0.07238206267356873,
      0.022853748872876167,
      -0.02157222293317318,
      0.013599828816950321,
      -0.08264989405870438,
      -0.011571384035050869,
      0.06282053142786026,
      -0.010695542208850384,
      -0.027579644694924355,
      0.08375056833028793,
      -0.02459242008626461,
      -0.008305603638291359,
      -0.02313491888344288,
      0.10239149630069733,
      -0.05246049538254738,
      0.03773824870586395,
      -0.0027088550850749016,
      -0.022912297397851944,
      0.05353773757815361,
      -0.02909991890192032,
      0.07944488525390625,
      0.01756041869521141,
      -0.011545911431312561,
      0.04965411499142647,
      0.029715538024902344,
      0.01205528061836958,
      0.0006229850696399808,
      -0.030992351472377777,
      -0.015045924112200737,
      0.043586455285549164,
      -0.048570968210697174,
      0.0005939874099567533,
      -0.02215145342051983,
      -0.05853511765599251,
      -0.02398737519979477,
      0.0471181683242321,
      0.020354138687253,
      0.009784506633877754,
      -0.020675605162978172,
      0.03023112565279007,
      -0.03516857698559761,
      0.07464897632598877,
      0.00854261964559555,
      -0.03810614347457886,
      -0.02006223425269127,
      -0.03912675380706787,
      0.08157344162464142,
      0.002253861865028739,
      0.05981099605560303,
      0.06460456550121307,
      -0.010520393028855324,
      0.06623032689094543,
      0.025956839323043823,
      -0.06971515715122223,
      -0.00917030405253172,
      -0.020789863541722298,
      0.015288139693439007,
      0.11076347529888153,
      -0.012922091409564018,
      0.028642814606428146,
      -0.067290298640728,
      0.1488393247127533,
      -0.043268833309412,
      -0.03208611160516739,
      -0.009919174946844578,
      0.060274265706539154,
      0.031263355165719986,
      0.009379315190017223,
      0.0018379765097051859,
      -0.022390814498066902,
      -0.07613804191350937,
      0.00041037960909307003,
      -0.02391892299056053,
      -0.04175737872719765,
      -0.05445583537220955,
      0.025771968066692352,
      -0.029757359996438026,
      0.09357783943414688,
      0.032426271587610245,
      0.014892923645675182,
      0.04650646820664406,
      -0.040228866040706635,
      -0.005438663065433502,
      -0.0039050469640642405,
      -0.07897123694419861,
      0.03011707030236721,
      0.003070250153541565,
      -0.0045333486050367355,
      -0.052740518003702164,
      0.006833326071500778,
      0.04945216327905655,
      -0.05911528319120407,
      -0.049858786165714264,
      -0.04492999240756035,
      0.029987771064043045,
      -0.000975894508883357,
      -0.004138463642448187,
      -0.05201420933008194,
      -0.0103079192340374,
      0.02863609790802002,
      -0.014775261282920837,
      -0.02994583360850811,
      -0.03482433408498764,
      -0.10916396975517273,
      0.05207834020256996,
      0.018072187900543213,
      -0.007958817295730114,
      -0.0028371568769216537,
      -0.09355295449495316,
      -0.01952444761991501,
      0.11903616040945053,
      -0.009005539119243622,
      -0.0023805240634828806,
      0.005117701832205057,
      0.0945512056350708,
      0.06777242571115494,
      -0.004507287871092558,
      0.07556582987308502,
      0.025074632838368416,
      0.061643898487091064,
      -0.08353155106306076,
      0.03470644727349281,
      0.06265399605035782,
      0.010775248520076275,
      0.004292634781450033,
      0.0044456059113144875,
      -0.0511239729821682,
      -0.021452344954013824,
      -0.05462302640080452,
      -0.0011259056627750397,
      -0.00470281345769763,
      -0.02694745920598507,
      -0.0010653460631147027,
      -0.029952554032206535,
      0.035477977246046066,
      0.04249560087919235,
      0.03109462931752205,
      -0.003855746705085039,
      -0.09557487070560455,
      -0.001423437031917274,
      0.019432807341217995,
      0.030905455350875854,
      0.027543436735868454,
      0.015686022117733955,
      -0.020174259319901466,
      -0.04356140270829201,
      0.024200988933444023,
      0.1044141948223114,
      0.12263978272676468,
      0.0738515704870224,
      0.0023114681243896484,
      -0.13564032316207886,
      0.006766811944544315,
      -0.004697986412793398,
      0.024487998336553574,
      0.002114643342792988,
      0.038309406489133835,
      0.009105445817112923,
      -0.06537458300590515,
      0.029581371694803238,
      -0.050477538257837296,
      -0.013700267300009727,
      -0.006878219079226255,
      0.006772633176296949,
      -0.088044673204422,
      0.029135551303625107,
      -0.03885665535926819,
      -0.02463473379611969,
      -0.04215287044644356,
      -0.046741653233766556,
      -0.07870864123106003,
      -0.05780241638422012,
      -0.00687753688544035,
      -0.027473289519548416,
      -0.006902730092406273,
      -0.03552437573671341,
      0.004672828130424023,
      -0.04807143658399582,
      0.02623007632791996,
      -0.058434803038835526,
      -0.03729556128382683,
      -0.03835824877023697,
      0.1301332265138626,
      -0.0006500110612250865,
      0.07681061327457428,
      -0.06282758712768555,
      0.008373227901756763,
      -0.006418603006750345,
      -0.0411393865942955,
      -0.007702275644987822,
      0.02428530901670456,
      0.006337993778288364,
      0.05611134693026543,
      -0.06192520633339882,
      -0.03701213002204895,
      -0.058226048946380615,
      -0.07628123462200165,
      -0.06661903113126755,
      0.01711558923125267,
      -0.0723913237452507,
      -0.06320330500602722,
      0.0026786290109157562,
      0.002936555538326502,
      -0.012050752528011799,
      -0.0008628943469375372,
      0.02757352776825428,
      -0.030959993600845337,
      -0.015979444608092308,
      -0.06111207604408264,
      -0.023207174614071846,
      -0.07852789014577866,
      -0.0022664866410195827,
      0.007715144660323858,
      -0.024736177176237106,
      -0.046208105981349945,
      0.020823588594794273,
      0.007367243524640799,
      0.017881320789456367,
      0.019649971276521683,
      -0.06580067425966263,
      -0.004515074193477631,
      0.025000466033816338,
      0.023835059255361557,
      -0.005653232801705599,
      0.00726053211838007,
      -0.016399186104536057,
      0.021458828821778297,
      0.039629556238651276,
      0.014570691622793674,
      -0.05036144703626633,
      -0.03065158985555172,
      0.010486374609172344,
      -0.048814475536346436,
      -0.008661145344376564,
      -0.016663266345858574,
      0.020208561792969704,
      -0.0985269695520401,
      0.1201852336525917,
      0.008107085712254047,
      0.01510609034448862,
      -0.07069958001375198,
      -0.03575260937213898,
      -0.05085147172212601,
      0.0012862470466643572,
      -0.07533808052539825,
      -0.09438803046941757,
      -0.02174203097820282,
      -0.11965927481651306,
      -0.0523870550096035,
      -0.02867015078663826,
      -0.015229668468236923,
      -0.004126906860619783,
      0.04537326842546463,
      -0.07749874144792557,
      -0.057372450828552246,
      0.059920746833086014,
      0.027996329590678215,
      -0.0036846438888460398,
      0.04228171333670616,
      0.019569680094718933,
      0.032013729214668274,
      -0.04342798888683319,
      0.0776788666844368,
      -0.03176329657435417,
      0.038283295929431915,
      0.02218726836144924,
      -0.0022830432280898094,
      0.06961782276630402,
      -0.007164621725678444,
      0.0011565913446247578,
      0.050967488437891006,
      -0.05789528414607048,
      -0.021133504807949066,
      0.01638263463973999,
      -0.04211791604757309,
      -0.05269067361950874,
      0.02286416105926037,
      0.02159087546169758,
      0.0718148723244667,
      -0.029649505391716957,
      -0.07736147940158844,
      -0.016470689326524734,
      0.0038872105069458485,
      0.006059272680431604,
      0.02622273378074169,
      -0.0035537658259272575,
      -0.0386015810072422,
      0.04107282683253288,
      -0.021100174635648727,
      -0.035596709698438644,
      -4.3792606447823346e-05,
      -0.010869151912629604,
      0.03263820335268974,
      0.05608918517827988,
      0.017917053773999214,
      0.0391557440161705,
      0.041470494121313095,
      -0.037464167922735214,
      -0.009907959029078484,
      -0.03336825966835022,
      0.09857940673828125,
      0.03354370594024658,
      0.04525946453213692,
      0.014631138183176517,
      -0.053877659142017365,
      -0.03421187028288841,
      0.03388005867600441,
      0.022866712883114815,
      0.016897570341825485,
      0.024620573967695236,
      -0.02834828570485115,
      -0.12779328227043152,
      -0.04913722351193428,
      -0.017741205170750618,
      0.0236574225127697,
      -0.11789210885763168,
      -0.04177228361368179,
      0.09975608438253403,
      -0.012100346386432648,
      -0.059459514915943146,
      -0.029729792848229408,
      -0.029200894758105278,
      -0.06513168662786484,
      -0.0011340150376781821,
      0.05351035296916962,
      0.02122841402888298,
      -0.0597495511174202,
      0.01349344477057457,
      0.0497903898358345,
      -0.05248386412858963,
      0.039647992700338364,
      0.061047617346048355,
      0.012817191891372204,
      0.033691275864839554,
      -0.031072886660695076,
      -0.07537864148616791,
      -0.0008415972697548568,
      -0.038031261414289474,
      -0.0547189898788929,
      -0.002178714144974947,
      0.017376137897372246,
      0.061013225466012955,
      -0.07678532600402832,
      0.06886427849531174,
      -0.008373941294848919,
      0.0025506780948489904,
      -0.06795799732208252,
      0.0038313926197588444,
      -0.07072611898183823,
      -0.03437603637576103,
      0.006471730303019285,
      -0.019910937175154686,
      -0.006373205222189426,
      0.05727333575487137,
      -0.08729599416255951,
      0.0037031203974038363,
      -0.10411199927330017,
      -0.03899557515978813,
      0.04153387248516083
    ],
    [
      -0.027889054268598557,
      0.05540328845381737,
      0.016738684847950935,
      0.037484437227249146,
      -0.03527114912867546,
      0.05047137662768364,
      -0.01622636243700981,
      0.04721674695611,
      -0.08857253193855286,
      0.014471320435404778,
      -0.03039555810391903,
      -0.02427961677312851,
      -0.0034858384169638157,
      -0.025712979957461357,
      0.030180754140019417,
      -0.025276631116867065,
      -0.011222447268664837,
      0.004043810535222292,
      -0.026699019595980644,
      0.03815111517906189,
      0.07457444071769714,
      0.04907883331179619,
      -0.024494273588061333,
      0.012592395767569542,
      0.0020576263777911663,
      0.023408692330121994,
      0.03242924064397812,
      -0.044273439794778824,
      0.028799640014767647,
      0.0016405470669269562,
      0.04280584678053856,
      0.03061365894973278,
      0.029904555529356003,
      0.13669688999652863,
      -0.020710697397589684,
      -0.002632860327139497,
      0.016429893672466278,
      -0.020674576982855797,
      0.010042263194918633,
      -0.013062113896012306,
      -0.05214300751686096,
      -0.06150776520371437,
      -0.01814969629049301,
      0.08061207085847855,
      0.08357255905866623,
      -0.023496385663747787,
      0.08070004731416702,
      -0.03532575070858002,
      -0.03701690956950188,
      0.03166787326335907,
      -0.06044718995690346,
      0.013452768325805664,
      -0.0060881078243255615,
      0.04387225583195686,
      -0.02047819457948208,
      0.03475084900856018,
      -0.011995380744338036,
      -0.034610770642757416,
      0.006470439024269581,
      0.06149839982390404,
      -0.054796431213617325,
      0.0666016936302185,
      0.13900692760944366,
      -0.04312431067228317,
      0.0734688863158226,
      -0.02065664902329445,
      0.08076397329568863,
      0.04439239948987961,
      -0.019651561975479126,
      -0.024497589096426964,
      3.0234146834118292e-05,
      0.020718276500701904,
      -0.006895062513649464,
      0.018269430845975876,
      -0.10615021735429764,
      -0.033982522785663605,
      -0.045599061995744705,
      0.05114685371518135,
      0.14557336270809174,
      -0.05641322582960129,
      0.022215956822037697,
      -0.07432890683412552,
      -0.0074668992310762405,
      0.031023822724819183,
      0.04235967621207237,
      0.07869329303503036,
      -0.01667449064552784,
      -0.0039879558607935905,
      -0.09605099260807037,
      0.06516492366790771,
      0.02598528191447258,
      -0.08796439319849014,
      0.026828104630112648,
      0.05114644020795822,
      0.09791970998048782,
      -0.07562163472175598,
      -0.037694051861763,
      -0.030721990391612053,
      -0.024662697687745094,
      0.0004944312386214733,
      -0.003014265326783061,
      -0.002429236425086856,
      0.01599106751382351,
      0.005839925725013018,
      0.021622296422719955,
      -0.028948506340384483,
      0.07392998784780502,
      0.0037516437005251646,
      0.026248354464769363,
      -0.02159975841641426,
      -0.05420662835240364,
      -0.003493116470053792,
      0.05922598019242287,
      0.027725735679268837,
      -0.06079864129424095,
      -0.03576741740107536,
      0.011993153020739555,
      0.052121564745903015,
      0.01503344438970089,
      0.005566743668168783,
      -0.02434425987303257,
      -0.01359783485531807,
      0.026734502986073494,
      -0.0107881473377347,
      0.05488637834787369,
      0.03204352781176567,
      -0.0019610710442066193,
      0.0703582912683487,
      0.07856246083974838,
      0.002778824185952544,
      0.05601828172802925,
      -0.018650537356734276,
      0.015037380158901215,
      -0.12625810503959656,
      0.07233284413814545,
      -0.022999204695224762,
      0.048510827124118805,
      -0.02071244828402996,
      0.029241733253002167,
      0.10113313049077988,
      -0.010808381251990795,
      -0.005402499344199896,
      0.07577884197235107,
      -0.008990369737148285,
      0.026887517422437668,
      0.0184255950152874,
      -0.03272552043199539,
      0.05642274394631386,
      0.03435451164841652,
      -0.051336780190467834,
      -0.09681154787540436,
      0.1004452109336853,
      0.055041756480932236,
      -0.03156939893960953,
      -0.01987628825008869,
      0.019877851009368896,
      -0.018549052998423576,
      -0.028142206370830536,
      0.0875014141201973,
      -0.021639207378029823,
      -0.055405378341674805,
      0.02340998686850071,
      0.014955894090235233,
      -0.004878446459770203,
      -0.016906140372157097,
      -0.0456962063908577,
      0.019504133611917496,
      -0.03648832067847252,
      0.10272061079740524,
      0.02823302336037159,
      0.022331485524773598,
      -0.09099119901657104,
      -0.01412518322467804,
      -0.049779508262872696,
      0.008199457079172134,
      0.0497279018163681,
      0.04670378565788269,
      0.04113202542066574,
      -0.053549349308013916,
      0.023071760311722755,
      -0.01831878535449505,
      -0.005269133485853672,
      -0.012060226872563362,
      0.05314415693283081,
      -0.04034017026424408,
      -0.06555955111980438,
      0.06396473944187164,
      -0.039510343223810196,
      -0.021641921252012253,
      -0.039014242589473724,
      0.002790439873933792,
      -0.01681293360888958,
      0.03401355817914009,
      -0.04319126904010773,
      -0.009020177647471428,
      0.08554122596979141,
      -0.051575515419244766,
      0.04873476177453995,
      -0.049730151891708374,
      0.004578809253871441,
      -0.021743644028902054,
      -0.0429300032556057,
      0.04193245247006416,
      0.05057382211089134,
      -0.03176524490118027,
      0.003351725870743394,
      -0.049720246344804764,
      0.039198171347379684,
      -0.008460060693323612,
      -0.04440692439675331,
      0.007509327959269285,
      -0.03646303713321686,
      0.0131107522174716,
      0.022675497457385063,
      -0.014712325297296047,
      0.017673708498477936,
      -0.007643825840204954,
      -0.05542020872235298,
      -0.008905100636184216,
      -0.036927033215761185,
      -0.0641339048743248,
      0.021241508424282074,
      -0.02638722024857998,
      0.032133251428604126,
      -0.04249120503664017,
      -0.004915300756692886,
      0.015819957479834557,
      -0.05096631124615669,
      0.08675556629896164,
      0.03502235934138298,
      -0.029256688430905342,
      0.027847111225128174,
      -0.01446732971817255,
      -0.002315285848453641,
      0.0005019846721552312,
      0.03265194967389107,
      -0.0567207857966423,
      -0.03876586630940437,
      0.037521880120038986,
      0.047307487577199936,
      0.012150089256465435,
      -0.0050377328880131245,
      0.12795057892799377,
      -0.04489048197865486,
      0.024709654971957207,
      0.053488899022340775,
      0.024171004071831703,
      -0.007882882840931416,
      -0.024359414353966713,
      -0.08232469856739044,
      -0.0209964532405138,
      0.038186024874448776,
      0.026329321786761284,
      0.0005501165869645774,
      -0.049428101629018784,
      -0.008534418419003487,
      -0.007508506067097187,
      0.027578119188547134,
      0.09957139939069748,
      -0.03800583258271217,
      -0.0018365572905167937,
      -0.02440660074353218,
      -0.06196965277194977,
      -0.0017666509374976158,
      0.009446188807487488,
      0.04025345295667648,
      0.020814798772335052,
      -0.027004102244973183,
      0.051733244210481644,
      -0.009856960736215115,
      -0.06937698274850845,
      -0.037994809448719025,
      0.00415572477504611,
      0.002154799411073327,
      0.09162485599517822,
      -0.024523023515939713,
      -0.017798976972699165,
      0.011528359726071358,
      -0.07315707951784134,
      0.02078915946185589,
      -0.02081885188817978,
      -0.03658527135848999,
      0.01574167236685753,
      -0.03961791843175888,
      0.024794215336441994,
      0.08786947280168533,
      -0.019768789410591125,
      -0.02155914716422558,
      0.006801549810916185,
      -0.04924379661679268,
      0.020854035392403603,
      -0.004366695415228605,
      0.007013246417045593,
      0.0761399045586586,
      0.06091483682394028,
      -0.014259791001677513,
      -0.01458297111093998,
      -0.031452540308237076,
      -0.012573775835335255,
      0.024443933740258217,
      0.0562392957508564,
      0.017717987298965454,
      0.012566402554512024,
      -0.045309726148843765,
      -0.04691039398312569,
      0.01624852605164051,
      0.01639074832201004,
      0.03913524001836777,
      0.026088258251547813,
      0.0077622151002287865,
      -0.02182253822684288,
      -0.08380590379238129,
      0.018324458971619606,
      -0.005909384693950415,
      -0.05088701844215393,
      -0.04145410656929016,
      -0.04075995087623596,
      0.07781481742858887,
      -0.03225989639759064,
      0.06428559869527817,
      0.006034494377672672,
      -0.03232854604721069,
      0.0026030128356069326,
      -0.01631833054125309,
      0.002665748354047537,
      0.012506049126386642,
      0.01699855364859104,
      -0.014536206610500813,
      -0.07467743754386902,
      0.060830190777778625,
      0.024982016533613205,
      -0.035687610507011414,
      0.004990094806998968,
      0.07055126875638962,
      0.03351430967450142,
      -0.05660586431622505,
      0.026246462017297745,
      0.08293095231056213,
      -0.047760289162397385,
      -0.003664762247353792,
      0.0020782104693353176,
      -0.0898652970790863,
      0.0156866442412138,
      0.012174093164503574,
      0.08934489637613297,
      -0.06649435311555862,
      -0.020265480503439903,
      0.023755351081490517,
      0.05724621191620827,
      -0.028648018836975098,
      0.08127837628126144,
      0.041150301694869995,
      -0.05686858668923378,
      -0.00880137924104929,
      0.031712666153907776,
      0.06182486191391945,
      -0.025441855192184448,
      0.047246452420949936,
      -0.025563247501850128,
      -0.061251264065504074,
      -0.010411902330815792,
      0.022276975214481354,
      0.08158513903617859,
      -0.013731937855482101,
      -0.049847252666950226,
      -0.08233804255723953,
      0.004631042946130037,
      -0.03600580245256424,
      -0.025624969974160194,
      -0.03685184568166733,
      0.04701335355639458,
      0.05570295453071594,
      0.010284320451319218,
      0.042224567383527756,
      -0.05456762760877609,
      0.047161247581243515,
      0.020463697612285614,
      0.0035152174532413483,
      -0.04344957694411278,
      -0.0460425429046154,
      -0.06663157045841217,
      -0.04148982837796211,
      0.01286591961979866,
      -0.1202787384390831,
      -0.01068437471985817,
      0.003467186586931348,
      0.015014491975307465,
      0.03041175752878189,
      0.02245514839887619,
      -0.061511196196079254,
      -0.022314436733722687,
      0.012517750263214111,
      0.025162644684314728,
      0.049799516797065735,
      0.02428358420729637,
      -5.62515742785763e-05,
      -0.08483056724071503,
      0.015261177904903889,
      -0.012345944531261921,
      0.04785537347197533,
      0.0009603973594494164,
      0.01965981535613537,
      0.008538417518138885,
      0.06801926344633102,
      0.08264673501253128,
      -0.011745259165763855,
      0.04188179969787598,
      -0.06659166514873505,
      -0.0813736841082573,
      -0.04344094917178154,
      -0.012406471185386181,
      -0.0573333315551281,
      0.11810566484928131,
      0.012830034829676151,
      -0.043392397463321686,
      0.02611294761300087,
      -0.07493310421705246,
      -0.007321629207581282,
      -0.020902764052152634,
      -0.025387117639183998,
      0.05635254085063934,
      0.033044930547475815,
      0.0020380872301757336,
      0.0408337339758873,
      -0.006468393839895725,
      0.02674265392124653,
      -0.04161298647522926,
      -0.010786156170070171,
      0.04630767181515694,
      0.002231718972325325,
      0.003539262106642127,
      0.01759231649339199,
      -0.054744623601436615,
      0.008864626288414001,
      -0.009453448466956615,
      -0.06918273866176605,
      -0.01786123216152191,
      -0.023561393842101097,
      0.05597250908613205,
      -0.026555804535746574,
      0.05294012278318405,
      0.03479490056633949,
      0.04847896844148636,
      -0.00887229759246111,
      -0.017300253733992577,
      -0.05610772222280502,
      0.04482003673911095,
      0.024734873324632645,
      -0.07071532309055328,
      -0.013010515831410885,
      -0.04322962835431099,
      0.08035499602556229,
      0.020248694345355034,
      -0.09992590546607971,
      -0.10065238177776337,
      -0.017096316441893578,
      0.028279446065425873,
      -0.005935373716056347,
      -0.06518275290727615,
      0.0703381821513176,
      0.07570669800043106,
      0.05926594138145447,
      0.08434365689754486,
      0.05466344580054283,
      -0.0168278981000185,
      0.08081237971782684,
      0.028781995177268982,
      0.017621679231524467,
      -0.03757406026124954,
      -0.005769593641161919,
      -0.0033124806359410286,
      -0.06354732066392899,
      -0.027264652773737907,
      -0.0005900963442400098,
      -0.01343755517154932,
      -0.03399382904171944,
      -0.045063454657793045,
      -0.056638967245817184,
      -0.00015715532936155796,
      0.03562621772289276,
      -0.041741907596588135,
      -0.048028673976659775,
      0.07961590588092804,
      0.028410155326128006,
      0.012117655947804451,
      -0.0029388938564807177,
      -0.03874076157808304,
      0.01919013261795044,
      -0.0031280156690627337,
      -0.027804706245660782,
      -0.0026888775173574686,
      -0.03428524732589722,
      0.018832530826330185,
      -0.03698204830288887,
      0.00016522302757948637,
      -0.07728417962789536,
      0.039846014231443405,
      -0.027832014486193657,
      0.055284954607486725,
      -0.042316216975450516,
      -0.03278772160410881,
      0.07201683521270752,
      0.09465598315000534
    ],
    [
      -0.030584095045924187,
      -0.0032790708355605602,
      -0.04792714864015579,
      0.026692671701312065,
      0.060461293905973434,
      -0.045256108045578,
      0.012608642689883709,
      -0.03763860836625099,
      -0.02891002595424652,
      0.002492966828867793,
      -0.062425728887319565,
      -0.014093597419559956,
      0.03180086240172386,
      0.053442638367414474,
      0.05331093817949295,
      0.03286493942141533,
      0.1010032668709755,
      0.04924539476633072,
      -0.018979862332344055,
      -0.021031904965639114,
      -0.0023689905647188425,
      0.029886897653341293,
      -0.0003194983582943678,
      0.004790663253515959,
      0.049647241830825806,
      -0.0902954563498497,
      -0.0035295370034873486,
      0.006114961113780737,
      0.02632339671254158,
      -0.08679129183292389,
      0.10946476459503174,
      0.07069245725870132,
      0.0400703139603138,
      0.0009282752871513367,
      -0.01122556533664465,
      0.06327084451913834,
      -0.02951577492058277,
      0.07651913166046143,
      0.019807249307632446,
      -0.02954106777906418,
      0.028434468433260918,
      0.04156573861837387,
      -0.014168661087751389,
      -0.04838545247912407,
      0.0075956908985972404,
      -0.0704791247844696,
      0.06991875171661377,
      0.0033126736525446177,
      0.07597387582063675,
      0.028189923614263535,
      0.036710046231746674,
      -0.006137171294540167,
      -0.0054357596673071384,
      0.020245645195245743,
      0.0254864152520895,
      -0.0015402671415358782,
      -0.014761772006750107,
      0.020625300705432892,
      0.018172359094023705,
      -0.11471667885780334,
      0.002283601788803935,
      -0.02686038427054882,
      -0.012964196503162384,
      -0.103901207447052,
      0.05619175732135773,
      -0.06312493979930878,
      -5.064212018623948e-07,
      -0.031614795327186584,
      -0.012038471177220345,
      0.04328623041510582,
      -0.01773139089345932,
      -0.04734020307660103,
      0.012813134118914604,
      0.01426219567656517,
      0.07333047688007355,
      -0.1413421928882599,
      0.013437429443001747,
      -0.057594865560531616,
      -0.044261038303375244,
      -0.03288193792104721,
      0.026761319488286972,
      0.019642263650894165,
      -0.04548545181751251,
      0.03172483667731285,
      -0.00471901660785079,
      0.03349195793271065,
      0.04416672885417938,
      0.03898334875702858,
      0.002192661864683032,
      -0.00746567640453577,
      -0.021415086463093758,
      0.032076798379421234,
      0.08311434090137482,
      -0.0006391386850737035,
      0.07977544516324997,
      0.009891641326248646,
      0.012852177955210209,
      -0.02110349014401436,
      -0.02803097851574421,
      0.062267325818538666,
      0.07329414784908295,
      0.07692187279462814,
      0.027191994711756706,
      -0.01873648166656494,
      0.020025130361318588,
      0.007789335213601589,
      0.010621299967169762,
      -0.024126127362251282,
      -0.0026517321821302176,
      0.03383166715502739,
      -0.020676085725426674,
      -0.026232317090034485,
      0.0479046031832695,
      -0.03499368950724602,
      -0.043906453996896744,
      -0.010781383141875267,
      -0.0024811008479446173,
      0.026122018694877625,
      -0.030543066561222076,
      0.045828450471162796,
      -0.007088524755090475,
      -0.04898504912853241,
      -0.03446381539106369,
      -0.009566864930093288,
      0.008439494296908379,
      0.016566727310419083,
      0.039664193987846375,
      0.02343355305492878,
      -0.010856601409614086,
      -0.1446514129638672,
      0.03154127672314644,
      -0.05892292410135269,
      -0.03157295659184456,
      -0.024060262367129326,
      0.006123398430645466,
      0.04386241361498833,
      -0.038259729743003845,
      0.03740198165178299,
      0.003221775870770216,
      -0.06015930324792862,
      -0.04539129510521889,
      -0.016308030113577843,
      0.05536644533276558,
      0.012844568118453026,
      0.012301668524742126,
      -0.08127496391534805,
      -0.0193505696952343,
      0.03339152783155441,
      0.017980163916945457,
      -0.03447986766695976,
      -0.008508611470460892,
      -0.016471017152071,
      -0.02266230620443821,
      0.032879192382097244,
      -0.06993002444505692,
      0.08138235658407211,
      0.003280017524957657,
      -0.08129230886697769,
      -0.005578694399446249,
      0.06323982775211334,
      -0.06392867118120193,
      0.08909720927476883,
      0.08350039273500443,
      0.026463575661182404,
      0.019116636365652084,
      -0.046849049627780914,
      -0.028610466048121452,
      0.013738295063376427,
      -0.023832153528928757,
      -0.012713261879980564,
      -0.03795461729168892,
      0.025272436439990997,
      -0.017488088458776474,
      -0.036057088524103165,
      -0.06597549468278885,
      -0.006754284258931875,
      -0.013078759424388409,
      -0.01998014561831951,
      0.026619046926498413,
      0.0022933264262974262,
      -0.01279483176767826,
      0.04478227719664574,
      -0.07437115162611008,
      -0.028437627479434013,
      0.08653934299945831,
      -0.042830318212509155,
      0.02646004781126976,
      -0.002507350640371442,
      0.058278296142816544,
      -0.015161467716097832,
      -0.013079739175736904,
      0.03913099318742752,
      -0.035651154816150665,
      0.020161660388112068,
      0.002242498332634568,
      -0.019879210740327835,
      -0.04704511538147926,
      0.07416422665119171,
      0.11813250184059143,
      -0.004095559474080801,
      -0.0037790187634527683,
      0.032864537090063095,
      0.07705939561128616,
      0.014554258435964584,
      -0.01383775845170021,
      0.067313551902771,
      0.012473810464143753,
      0.041323427110910416,
      -0.006806074175983667,
      -0.018796071410179138,
      0.03927658498287201,
      -0.06491801142692566,
      -0.024133699014782906,
      -0.028183430433273315,
      0.029238523915410042,
      -0.02266606129705906,
      -0.018303044140338898,
      -0.09564872086048126,
      0.02101323753595352,
      -0.04334312677383423,
      0.00585718872025609,
      -0.045832559466362,
      -0.011277466081082821,
      0.0161326564848423,
      -0.03904720023274422,
      0.04527650773525238,
      -0.06779652088880539,
      -0.03356058895587921,
      -0.002512700157240033,
      -0.0032432740554213524,
      -0.012168815359473228,
      0.0636158213019371,
      -0.044378746300935745,
      -0.011319153942167759,
      -0.020245634019374847,
      0.0764109343290329,
      0.01743648387491703,
      0.03324587270617485,
      0.014368710108101368,
      0.006348602008074522,
      0.020441362634301186,
      -0.01191315520554781,
      -0.02535409666597843,
      0.0059501128271222115,
      -0.015939854085445404,
      -0.005678247660398483,
      0.011401595547795296,
      -0.04881148785352707,
      -0.025802385061979294,
      -0.04074852541089058,
      -0.01853918842971325,
      0.007932381704449654,
      -0.02877316251397133,
      0.09582021832466125,
      -0.00696806563064456,
      -0.04229465126991272,
      0.06464102119207382,
      -0.04234818369150162,
      0.04560421034693718,
      0.14237956702709198,
      -0.060973573476076126,
      -0.08749725669622421,
      0.023252056911587715,
      0.0056624202989041805,
      0.05596843734383583,
      -0.07348885387182236,
      0.03888856992125511,
      -0.006103773135691881,
      0.05284027382731438,
      -0.02577013336122036,
      0.04748211055994034,
      -0.008211677893996239,
      -0.0474809966981411,
      -0.03553392365574837,
      -0.12664076685905457,
      0.09415164589881897,
      0.08086106926202774,
      -0.03504100814461708,
      -0.033218275755643845,
      -0.043535903096199036,
      0.042375773191452026,
      -0.10874465107917786,
      -0.034990839660167694,
      0.017899248749017715,
      -0.05697055160999298,
      -0.024008333683013916,
      0.07123452425003052,
      -0.04052251949906349,
      0.01757589355111122,
      -0.047396011650562286,
      -0.032903000712394714,
      0.0190009493380785,
      0.051345035433769226,
      -0.013606625609099865,
      0.003249946516007185,
      0.007157572545111179,
      -0.04193882271647453,
      0.01765711046755314,
      -0.03818715736269951,
      -0.010074703954160213,
      0.01683974824845791,
      -0.03885986655950546,
      -0.026904374361038208,
      -0.008702583611011505,
      -0.011957342736423016,
      0.0875571221113205,
      0.03221972659230232,
      -0.016620665788650513,
      -0.017714524641633034,
      0.04268598183989525,
      -0.051030367612838745,
      -0.029919175431132317,
      -0.020543035119771957,
      -0.026047926396131516,
      -0.0006447182386182249,
      -0.008873086422681808,
      -0.009521129541099072,
      0.020539820194244385,
      0.029269272461533546,
      0.018636953085660934,
      -0.051349788904190063,
      0.05223564803600311,
      0.08383787423372269,
      -0.01749434880912304,
      0.023934554308652878,
      -0.024203069508075714,
      0.00040816207183524966,
      0.05073420703411102,
      0.002111268462613225,
      -0.008725490421056747,
      0.04947147145867348,
      0.023031853139400482,
      0.027502786368131638,
      -0.06277738511562347,
      -0.055614933371543884,
      0.04549626260995865,
      -0.12743794918060303,
      0.0632266253232956,
      -0.0413469560444355,
      0.07783350348472595,
      -0.03191686421632767,
      -0.03891117870807648,
      -0.029047876596450806,
      -0.03879443183541298,
      0.025799158960580826,
      -0.10159701853990555,
      0.02723080851137638,
      -0.0075119538232684135,
      -0.05229517072439194,
      0.015812816098332405,
      0.00560338469222188,
      -0.03903212025761604,
      -0.04356367141008377,
      0.019572604447603226,
      0.009459409862756729,
      0.03210204839706421,
      9.391696221427992e-05,
      0.01748664304614067,
      -0.03430375084280968,
      0.012864883989095688,
      0.06171339005231857,
      -0.02603984996676445,
      -0.04613639414310455,
      -0.028954405337572098,
      0.02974206767976284,
      0.039665065705776215,
      -0.06441263854503632,
      0.03127947449684143,
      -0.047783903777599335,
      0.0694446861743927,
      0.010998516343533993,
      0.0193159319460392,
      -0.06486167013645172,
      -0.10518426448106766,
      -0.026051726192235947,
      -0.012009917758405209,
      -0.016541140154004097,
      0.0005281456396915019,
      -0.07551808655261993,
      0.0369238443672657,
      0.06256122887134552,
      -0.013265321031212807,
      -0.01328083872795105,
      0.042956896126270294,
      0.04211774095892906,
      -0.02214124985039234,
      -0.005220096558332443,
      -0.05480389669537544,
      0.04580564796924591,
      0.018818210810422897,
      0.06520000845193863,
      0.003580954158678651,
      0.03929189220070839,
      0.08545809239149094,
      -0.042508747428655624,
      -0.051815327256917953,
      -0.030299236997961998,
      0.0014799186028540134,
      -0.00430065905675292,
      0.059905629605054855,
      0.004379022400826216,
      0.08270956575870514,
      -0.003942458424717188,
      -0.04182063788175583,
      -0.013844149187207222,
      -0.004079341888427734,
      -0.05172053724527359,
      0.023674320429563522,
      -0.011820700950920582,
      0.02575676515698433,
      -0.005056770518422127,
      -0.10728902369737625,
      0.006476446986198425,
      -0.12498802691698074,
      -0.07782267779111862,
      0.025374190881848335,
      0.028871633112430573,
      0.05114438757300377,
      -0.007536201737821102,
      -0.09131088852882385,
      0.010233554989099503,
      -0.0074064587242901325,
      0.016930000856518745,
      -0.012491080909967422,
      -0.02104322239756584,
      0.02211669646203518,
      -0.02265818603336811,
      -0.08482653647661209,
      -0.01690218783915043,
      0.13759440183639526,
      -0.028012026101350784,
      0.008199055679142475,
      -0.030677281320095062,
      0.005433698184788227,
      -0.04237404093146324,
      0.02428698167204857,
      0.03592308983206749,
      0.011183836497366428,
      -0.07767961919307709,
      -0.042748626321554184,
      0.041956715285778046,
      0.09084425121545792,
      0.09948962926864624,
      -0.011952952481806278,
      0.009983012452721596,
      0.07688748836517334,
      0.035119183361530304,
      -0.09657195955514908,
      0.018143150955438614,
      -0.026702500879764557,
      -0.04151840880513191,
      -0.05986882373690605,
      -0.002701078774407506,
      0.004014750476926565,
      -0.03993883728981018,
      -0.01916968822479248,
      -0.03165829926729202,
      -0.025853650644421577,
      0.06690172851085663,
      0.07190020382404327,
      -0.0035441217478364706,
      0.038423776626586914,
      0.04364360123872757,
      0.05877295136451721,
      -0.043417662382125854,
      0.08908763527870178,
      -0.0042909844778478146,
      0.01764833554625511,
      0.026721565052866936,
      0.052150074392557144,
      0.009746238589286804,
      0.029330050572752953,
      0.058350685983896255,
      0.04253799468278885,
      0.08041157573461533,
      -0.02297394536435604,
      0.07772238552570343,
      0.04954920709133148,
      -0.11958948522806168,
      0.01393697876483202,
      -0.008904140442609787,
      0.05124511197209358,
      0.029387256130576134,
      0.08660924434661865,
      0.055360496044158936,
      0.021230487152934074,
      0.0016483330400660634,
      -0.01666485145688057,
      -0.015758786350488663,
      0.015164104290306568,
      0.030752098187804222,
      0.0021335824858397245,
      -0.10240020602941513,
      0.0019633944611996412,
      0.06175100430846214,
      0.07232151180505753,
      0.018973343074321747,
      0.06933382153511047,
      0.03019845485687256,
      0.0062812454998493195,
      0.03846567124128342,
      0.026664787903428078,
      0.06578242033720016
    ],
    [
      -0.05472375825047493,
      -0.03464316949248314,
      0.08251666277647018,
      -0.0124180493876338,
      0.08409058302640915,
      0.0012964956695213914,
      0.10081100463867188,
      -0.03029060922563076,
      -0.08505279570817947,
      -0.07167298346757889,
      0.01615937240421772,
      0.00895897950977087,
      -0.04972277954220772,
      0.028144020587205887,
      -0.04910978302359581,
      -0.0016197666991502047,
      -0.025534212589263916,
      -0.0016334856627508998,
      -0.02745474874973297,
      0.0014612054219469428,
      0.035944245755672455,
      -0.004151406232267618,
      -0.023190772160887718,
      0.052039872854948044,
      -0.03745715692639351,
      -0.04774874448776245,
      -0.020998859778046608,
      3.3827773222583346e-06,
      0.012836229056119919,
      -0.07776612788438797,
      0.06133992597460747,
      -0.08381174504756927,
      -0.06053444370627403,
      0.0038127871230244637,
      0.07677823305130005,
      0.007447287440299988,
      -0.019930399954319,
      0.04300456494092941,
      -0.049858056008815765,
      -0.018332496285438538,
      -0.08507562428712845,
      -0.06773488223552704,
      -0.014826126396656036,
      0.04234153404831886,
      -0.04220489040017128,
      0.01766216568648815,
      0.05016390234231949,
      0.018157048150897026,
      -0.07913412153720856,
      -0.03225162625312805,
      0.05020612105727196,
      0.016373882070183754,
      0.01007075421512127,
      0.02632720023393631,
      0.05330551788210869,
      -0.0729566439986229,
      -0.025951562449336052,
      -0.00011539550177985802,
      0.1026809811592102,
      -0.01609703339636326,
      -0.011913234367966652,
      0.03835894912481308,
      -0.05896444618701935,
      0.04535789415240288,
      0.02512139454483986,
      -0.004442115314304829,
      0.02649340219795704,
      0.01502810139209032,
      0.030689606443047523,
      -0.04180705547332764,
      0.004785704426467419,
      0.06905924528837204,
      -0.013752969913184643,
      0.013474100269377232,
      0.06055951118469238,
      0.0030092597007751465,
      -0.0550527460873127,
      -0.0008981297141872346,
      -0.001812634291127324,
      -0.033046793192625046,
      -0.07386139780282974,
      -0.06738300621509552,
      -0.05248672142624855,
      0.0013035685988143086,
      0.0673447698354721,
      0.08905545622110367,
      0.06515149027109146,
      -0.0264272578060627,
      -0.019422175362706184,
      -0.048329900950193405,
      -0.038267072290182114,
      0.024584000930190086,
      0.008909943513572216,
      -0.020135536789894104,
      0.028219373896718025,
      0.05778125301003456,
      0.0004283214802853763,
      -0.05270989239215851,
      -0.05165281519293785,
      0.023647861555218697,
      -0.05943828821182251,
      -0.05068160966038704,
      0.09999675303697586,
      -0.017731456086039543,
      -0.02070777490735054,
      0.009274052456021309,
      0.07514646649360657,
      0.0790020301938057,
      0.019820239394903183,
      -0.03766930103302002,
      -0.03204404562711716,
      -0.051194656640291214,
      -0.042561162263154984,
      0.011675577610731125,
      -0.027759963646531105,
      0.06466663628816605,
      0.012739990837872028,
      0.011122295632958412,
      -0.004456127528101206,
      -0.02983458898961544,
      0.13574901223182678,
      -0.06655090302228928,
      -0.004449593368917704,
      -0.01693527027964592,
      -0.0049278708174824715,
      0.044359318912029266,
      0.029446957632899284,
      0.021234294399619102,
      0.030173590406775475,
      0.04487896338105202,
      -0.009967788122594357,
      0.001687813550233841,
      0.0291262436658144,
      -0.007915808819234371,
      0.001236672280356288,
      -0.02310127392411232,
      -0.0481133796274662,
      0.030294956639409065,
      0.036446817219257355,
      -0.039970189332962036,
      -0.0453776977956295,
      0.10289300233125687,
      0.0052413251250982285,
      0.026586631312966347,
      -8.071689080679789e-05,
      0.02557293511927128,
      0.03796445205807686,
      -0.021606847643852234,
      -0.01720922626554966,
      0.03265036642551422,
      0.0036817346699535847,
      -0.10652068257331848,
      -0.034751106053590775,
      0.001415148377418518,
      -0.01116560585796833,
      0.1556941568851471,
      -0.014124765992164612,
      -0.04646272584795952,
      0.12308166921138763,
      -0.009720582515001297,
      -0.028902709484100342,
      -0.1043417677283287,
      0.07764481753110886,
      0.042390234768390656,
      -0.021171309053897858,
      -0.033869873732328415,
      0.03057006374001503,
      0.04623795300722122,
      0.06603693962097168,
      -0.04969404265284538,
      0.07054422795772552,
      0.019407782703638077,
      -0.047754187136888504,
      0.011934814043343067,
      0.08228117972612381,
      0.030513837933540344,
      0.07609247416257858,
      0.010991808027029037,
      0.0016721260035410523,
      0.016601400449872017,
      -0.054248303174972534,
      -0.0222871545702219,
      -0.031567178666591644,
      0.06166097894310951,
      -0.0193325225263834,
      0.04793454334139824,
      0.058029018342494965,
      -0.10187191516160965,
      -0.007888445630669594,
      -0.06823060661554337,
      -0.016394048929214478,
      0.05249917134642601,
      -0.08433377742767334,
      0.04636072739958763,
      0.018550164997577667,
      -0.03926345333456993,
      0.040878091007471085,
      -0.07381441444158554,
      -0.007150287739932537,
      -0.005673232488334179,
      0.008297329768538475,
      -0.0172728318721056,
      -0.024109119549393654,
      -0.0001530742592876777,
      0.01586102694272995,
      -0.0039587970823049545,
      -0.07321890443563461,
      0.0470612533390522,
      0.013021272607147694,
      -0.034075409173965454,
      -0.055617887526750565,
      -0.02030566707253456,
      -0.009039998054504395,
      -0.01144091971218586,
      0.0673181414604187,
      0.04134253412485123,
      -0.006688467692583799,
      -0.04038948565721512,
      -0.04768247902393341,
      0.011226056143641472,
      0.011575661599636078,
      0.010640940628945827,
      0.024075794965028763,
      -0.07991920411586761,
      -0.029267365112900734,
      0.011649560183286667,
      -0.021307434886693954,
      0.012411648407578468,
      0.04338532313704491,
      0.09732350707054138,
      -0.0036425383295863867,
      0.01213024277240038,
      0.015038651414215565,
      0.07305075228214264,
      -0.07901374250650406,
      0.01806851476430893,
      0.052207913249731064,
      0.012395012192428112,
      -0.005851821508258581,
      -0.024390539154410362,
      0.0005859499797224998,
      -0.06843443959951401,
      0.01707794889807701,
      -0.02603708952665329,
      0.011732331477105618,
      0.0032059119548648596,
      0.03411073982715607,
      0.04798314720392227,
      0.02386864647269249,
      -0.08179907500743866,
      -0.01959528587758541,
      0.04268974810838699,
      -0.0650659054517746,
      0.012480215169489384,
      0.0017808176344260573,
      -0.03231656923890114,
      -0.07503756135702133,
      -0.006098195910453796,
      -0.014997386373579502,
      -0.0239424929022789,
      0.0612899586558342,
      -0.03389083966612816,
      0.003510901238769293,
      -0.07304026931524277,
      -0.03391024470329285,
      -0.02854381874203682,
      -0.069377601146698,
      -0.009718901477754116,
      0.022079067304730415,
      -0.058684904128313065,
      0.024327190592885017,
      -0.017737705260515213,
      0.04379825294017792,
      -0.06562080979347229,
      -0.027910428121685982,
      -0.03577699139714241,
      0.03005095012485981,
      0.021241171285510063,
      0.03366098552942276,
      0.016738001257181168,
      -0.11916941404342651,
      -0.0873943641781807,
      0.03888535499572754,
      0.00431283051148057,
      -0.028687190264463425,
      0.007626376114785671,
      0.03232448920607567,
      0.05892753228545189,
      0.006557638756930828,
      -0.0007428458775393665,
      0.02543376386165619,
      -0.05401347950100899,
      0.004106962122023106,
      -0.017604690045118332,
      0.11991100013256073,
      -0.07719654589891434,
      -0.01907194033265114,
      0.007721952628344297,
      -0.05552464723587036,
      -0.042922623455524445,
      0.034431930631399155,
      -0.01889209635555744,
      0.1273011565208435,
      -0.026706013828516006,
      -0.007070703431963921,
      0.03491556644439697,
      0.00034253211924806237,
      5.283653808874078e-05,
      -0.05616340786218643,
      -0.002857205457985401,
      -0.05425009876489639,
      -0.00977606512606144,
      -0.03199849650263786,
      -0.024195875972509384,
      -0.024187786504626274,
      -0.03816090151667595,
      0.049883682280778885,
      0.0043508317321538925,
      0.06352685391902924,
      0.01564939133822918,
      0.023958025500178337,
      -0.05528246611356735,
      0.0470278225839138,
      -0.013957266695797443,
      -0.0377497524023056,
      0.03238952159881592,
      -0.11134813725948334,
      -0.06105079874396324,
      0.010608818382024765,
      -0.07899577915668488,
      0.07270690053701401,
      0.010895795188844204,
      0.027160530909895897,
      0.007125611416995525,
      0.043695833534002304,
      -0.07818318903446198,
      -0.003750731935724616,
      0.010935166850686073,
      0.0019116713665425777,
      -0.042077139019966125,
      -0.0302792489528656,
      -0.008174183778464794,
      0.059066906571388245,
      0.12064444273710251,
      -0.057766709476709366,
      -0.023172728717327118,
      0.03924610838294029,
      -0.035707976669073105,
      -0.043274249881505966,
      0.009146641939878464,
      -0.024589335545897484,
      -0.04560539498925209,
      0.016833845525979996,
      -0.007842343300580978,
      -0.06446628272533417,
      0.02359725721180439,
      -0.018742159008979797,
      0.04681214690208435,
      -0.04461580887436867,
      -0.044062644243240356,
      0.07552290707826614,
      -0.051975350826978683,
      -0.005274343304336071,
      0.01469245832413435,
      0.024697238579392433,
      -0.04079463332891464,
      0.020650120452046394,
      0.053227510303258896,
      -0.05516057088971138,
      -0.02195119857788086,
      -0.031233740970492363,
      0.006118983030319214,
      -0.01592794619500637,
      0.006690674461424351,
      -0.14079894125461578,
      0.06119856983423233,
      -0.021254893392324448,
      -0.020350301638245583,
      -0.05305122584104538,
      -0.0012851690407842398,
      -0.03626977279782295,
      0.03900813311338425,
      0.00630649970844388,
      -0.06126216799020767,
      -0.048490848392248154,
      0.059825360774993896,
      0.01748412474989891,
      -0.01856890879571438,
      -0.036869775503873825,
      0.029064202681183815,
      0.005489738192409277,
      -0.02630818821489811,
      0.02080930769443512,
      0.03839470446109772,
      -0.01190382894128561,
      0.017453359439969063,
      -0.019565144553780556,
      -0.005234968848526478,
      -0.041509829461574554,
      0.000913232855964452,
      0.020488902926445007,
      0.06183365732431412,
      0.006911146454513073,
      0.04608013853430748,
      -0.052231334149837494,
      0.060488201677799225,
      -0.10118282586336136,
      -0.022348420694470406,
      -0.0013717275578528643,
      -0.029698077589273453,
      0.01798723079264164,
      0.02081218920648098,
      -0.03398573398590088,
      -0.024845287203788757,
      0.019203009083867073,
      0.013065683655440807,
      -0.01435647439211607,
      5.478271987158223e-07,
      -0.008087062276899815,
      -0.04108620807528496,
      0.10556615144014359,
      0.0034804274328052998,
      0.047246433794498444,
      -0.0392349436879158,
      -0.013867491856217384,
      -0.006688863504678011,
      0.06693028658628464,
      -0.04575004056096077,
      -0.06517218798398972,
      -0.05593420937657356,
      -0.027709245681762695,
      -0.09317664057016373,
      -0.014358229003846645,
      -0.0036208336241543293,
      0.03836340457201004,
      0.0659005269408226,
      0.08954981714487076,
      0.058954160660505295,
      0.049588702619075775,
      -0.018174733966588974,
      0.0015688601415604353,
      0.051591657102108,
      -0.04817412048578262,
      0.0981488823890686,
      -0.07449907064437866,
      -0.04561839625239372,
      -0.02682674303650856,
      -0.025140652433037758,
      -0.06841357797384262,
      -0.025979893282055855,
      0.06780624389648438,
      -0.028270794078707695,
      -0.09524061530828476,
      0.04511987045407295,
      0.01196275930851698,
      -0.024770600721240044,
      -0.03488008305430412,
      -0.016580525785684586,
      -0.02797679603099823,
      0.019651606678962708,
      0.05196782574057579,
      -0.00721656484529376,
      0.037924040108919144,
      0.0007951282314024866,
      0.0824190080165863,
      -0.042022768408060074,
      -0.039781227707862854,
      0.05241047963500023,
      0.036182913929224014,
      -0.03125656768679619,
      -0.0011053202906623483,
      -0.00203970936127007,
      0.023983804509043694,
      0.01383375283330679,
      0.025564245879650116,
      -0.011735265143215656,
      -0.0641409307718277,
      0.07868441194295883,
      -0.045781850814819336,
      0.006753025576472282,
      -0.01237664557993412,
      -0.04786510020494461,
      0.03395872190594673,
      0.00030911105568520725,
      0.021247124299407005,
      -0.027355795726180077,
      -0.0658990740776062,
      -0.009081977419555187,
      0.061922717839479446,
      0.0024199984036386013,
      0.011291085742413998,
      -0.012328794226050377,
      -0.05729728564620018,
      0.05692688003182411,
      -0.003675970248878002,
      0.03317137062549591,
      -0.0007173260091803968,
      0.01048051193356514,
      0.06724916398525238,
      0.006980206351727247,
      0.009612848982214928,
      -0.061451271176338196,
      0.006613437086343765
    ],
    [
      0.0010893448488786817,
      0.014853527769446373,
      0.01839807629585266,
      -0.003925003577023745,
      -0.04739384353160858,
      0.03361821919679642,
      -0.034758299589157104,
      -0.04016537591814995,
      -0.018642133101820946,
      0.01547209545969963,
      0.03578755259513855,
      -0.012811153195798397,
      0.008251899853348732,
      0.02778729610145092,
      -0.01864507980644703,
      0.0345848947763443,
      0.014662199653685093,
      0.029103506356477737,
      -0.058544524013996124,
      0.015080641023814678,
      0.038108207285404205,
      0.01946428045630455,
      -0.000482200994156301,
      0.0425431951880455,
      -0.0438033826649189,
      -0.006222078576683998,
      0.030835550278425217,
      -0.026466742157936096,
      0.07160530984401703,
      0.10308277606964111,
      0.06007508561015129,
      -0.005400038789957762,
      -0.07865381240844727,
      -0.006537053734064102,
      -0.017040805891156197,
      0.018694346770644188,
      -0.0052259694784879684,
      0.03623025491833687,
      -0.0067947739735245705,
      -0.07411501556634903,
      0.01941102184355259,
      0.06293077021837234,
      0.013531910255551338,
      -0.009856227785348892,
      -0.023301739245653152,
      -0.03185906261205673,
      0.08049653470516205,
      -0.02127266675233841,
      -0.08623242378234863,
      0.057766206562519073,
      0.003008130704984069,
      0.01905478723347187,
      -0.07876551151275635,
      0.04031282663345337,
      0.06341849267482758,
      -0.009443161077797413,
      0.002754820045083761,
      0.004313522484153509,
      0.08084716647863388,
      -0.040438245981931686,
      0.014013894833624363,
      0.046227384358644485,
      0.1130237728357315,
      0.05299029499292374,
      0.0431692972779274,
      -0.025524452328681946,
      0.042641542851924896,
      0.019976088777184486,
      0.08851443231105804,
      -0.012157030403614044,
      0.0532645620405674,
      0.010897046886384487,
      0.010533650405704975,
      0.024886522442102432,
      -0.08005118370056152,
      0.05352000892162323,
      -0.025348292663693428,
      -0.020902259275317192,
      0.007692166604101658,
      0.01302987802773714,
      0.017657537013292313,
      0.03860527649521828,
      0.018209753558039665,
      -0.033398114144802094,
      0.02897380106151104,
      -0.0346052385866642,
      -0.006600549910217524,
      -0.04580560326576233,
      -0.00587453693151474,
      -0.05923343449831009,
      -0.01764710806310177,
      -0.034794166684150696,
      0.06513477116823196,
      -0.015622473321855068,
      0.026594970375299454,
      -0.03316072002053261,
      0.0438721589744091,
      0.05741150677204132,
      -0.08674534410238266,
      0.0284448079764843,
      0.014230244792997837,
      -0.04328199848532677,
      0.026024285703897476,
      0.017009375616908073,
      0.003106390591710806,
      0.0076719545759260654,
      -0.030506309121847153,
      0.021223390474915504,
      -0.04616893455386162,
      0.007237003650516272,
      0.009930341504514217,
      -0.0709705799818039,
      0.014764983206987381,
      -0.07126226276159286,
      -0.031249837949872017,
      0.08758831024169922,
      0.018476657569408417,
      -0.02182628959417343,
      -0.001612683292478323,
      0.04426479712128639,
      -0.012046161107718945,
      -0.015214777551591396,
      0.011976397596299648,
      0.0055670044384896755,
      -0.08978540450334549,
      -0.08624786883592606,
      0.02168290875852108,
      -0.014644529670476913,
      0.03387171030044556,
      -0.005961931776255369,
      0.03146110847592354,
      -0.02419198676943779,
      0.05695144832134247,
      0.05782254412770271,
      -0.07619975507259369,
      -0.047685012221336365,
      -0.05588315427303314,
      -0.021036475896835327,
      -0.08610481768846512,
      0.03446664661169052,
      0.007530736271291971,
      0.05212264508008957,
      -0.04219016805291176,
      0.0156847033649683,
      -0.022470034658908844,
      0.013504731468856335,
      -0.03502330929040909,
      0.007918093353509903,
      0.08513841032981873,
      -0.0004156084614805877,
      0.03434550389647484,
      -0.047884516417980194,
      0.03467099368572235,
      -0.001334219821728766,
      -0.013467363081872463,
      -0.07268228381872177,
      -0.0017753158463165164,
      -0.04925064742565155,
      -0.03943508490920067,
      -0.04582376405596733,
      0.0596928596496582,
      0.01775982789695263,
      -0.06761138141155243,
      0.07472694665193558,
      -0.004879755899310112,
      0.1040089875459671,
      0.09211456775665283,
      0.01735478639602661,
      0.06839200854301453,
      0.04466228559613228,
      0.020866399630904198,
      0.11155160516500473,
      -0.035302817821502686,
      0.03371961787343025,
      0.009846172295510769,
      0.08294431120157242,
      0.04425898566842079,
      0.019656943157315254,
      0.019165780395269394,
      0.011708212085068226,
      0.04018356651067734,
      0.017917020246386528,
      0.09380491822957993,
      0.0437728688120842,
      0.012948605231940746,
      -0.008742613717913628,
      -0.0510568805038929,
      -0.005554994568228722,
      0.014086686074733734,
      -0.05275459215044975,
      0.007043692748993635,
      0.0033849843312054873,
      0.07034603506326675,
      -0.019003961235284805,
      -0.05296953395009041,
      -0.015399969182908535,
      -0.00955510139465332,
      0.007125314325094223,
      -0.049089301377534866,
      -0.0039559779688715935,
      0.000590653857216239,
      -0.04526476934552193,
      -0.007378318812698126,
      0.06647366285324097,
      -0.0736672505736351,
      0.010488666594028473,
      0.022287199273705482,
      0.005023428238928318,
      0.06786157190799713,
      0.08129381388425827,
      0.03901023417711258,
      0.024504832923412323,
      -0.031312912702560425,
      3.4481712646083906e-05,
      0.033797815442085266,
      0.02622012235224247,
      -0.027111602947115898,
      -0.04762081429362297,
      -0.038957539945840836,
      -0.017830440774559975,
      0.01746886409819126,
      -0.013908387161791325,
      0.0849452093243599,
      -0.04719448834657669,
      -0.023938603699207306,
      -0.05667417123913765,
      -0.13347074389457703,
      0.004883379675447941,
      0.028407149016857147,
      0.02774645760655403,
      0.017784910276532173,
      -0.10033155977725983,
      0.030037730932235718,
      0.0455649308860302,
      0.03241802006959915,
      0.05460752546787262,
      0.023948783054947853,
      -0.005603293888270855,
      -0.04530743882060051,
      0.06559984385967255,
      0.006321802735328674,
      -0.023165129125118256,
      -0.032989174127578735,
      0.02307075820863247,
      -0.0808233693242073,
      -0.0180556308478117,
      -0.09919702261686325,
      -0.02596099302172661,
      -0.05183640867471695,
      -0.02220843732357025,
      -0.022456394508481026,
      0.0489739365875721,
      0.0028304995503276587,
      -0.04569235444068909,
      -0.009368115104734898,
      -0.0382358692586422,
      0.00113375810906291,
      0.015330766327679157,
      -0.02134840562939644,
      -0.10672248154878616,
      0.08604467660188675,
      0.05557278171181679,
      -0.04817580804228783,
      0.006529294885694981,
      0.04568671062588692,
      -0.007246274501085281,
      0.056496839970350266,
      0.06996821612119675,
      -0.01500138733536005,
      0.021319400519132614,
      0.08145759254693985,
      -0.04383821412920952,
      0.010337283834815025,
      0.01514711044728756,
      -0.06051623076200485,
      -0.009984462521970272,
      -0.06858082115650177,
      -0.017747068777680397,
      0.03086945414543152,
      0.027313638478517532,
      -0.037870652973651886,
      0.04389412701129913,
      -0.0133931003510952,
      0.020225128158926964,
      -0.003164064371958375,
      0.007337857969105244,
      0.021237295120954514,
      0.0019810369703918695,
      -0.022668715566396713,
      -0.03737172856926918,
      0.06018749251961708,
      -0.014299402944743633,
      0.030184242874383926,
      8.518711183569394e-06,
      0.03585424646735191,
      0.04840601608157158,
      -0.00011405819532228634,
      -0.04061480239033699,
      0.07398585230112076,
      0.08854573965072632,
      0.02419895865023136,
      -0.04474974423646927,
      0.012202643789350986,
      -0.023940593004226685,
      0.032501962035894394,
      -0.05916644632816315,
      -0.020267365500330925,
      -0.02659883350133896,
      -0.043125398457050323,
      -0.030717680230736732,
      0.02674797736108303,
      -0.025922730565071106,
      0.007356591988354921,
      0.05712509900331497,
      0.03247176483273506,
      0.06324028968811035,
      -0.004710040055215359,
      0.05466467887163162,
      0.03062194585800171,
      -0.03663792461156845,
      0.07616902887821198,
      0.00515955314040184,
      0.03714199736714363,
      0.019030187278985977,
      -0.029472723603248596,
      -0.060987282544374466,
      -0.014941414818167686,
      0.028813820332288742,
      -0.05540357530117035,
      0.09580523520708084,
      0.01742926612496376,
      0.062138721346855164,
      0.008343521505594254,
      0.07113134115934372,
      -0.03335172310471535,
      -0.047704026103019714,
      0.014800931327044964,
      -0.04342013597488403,
      0.0008074413635767996,
      0.08320149779319763,
      -0.055842600762844086,
      -0.04136909916996956,
      -0.0611443892121315,
      -0.045424167066812515,
      0.016361631453037262,
      0.049197498708963394,
      0.024686794728040695,
      0.03418484330177307,
      0.017834840342402458,
      0.04251411184668541,
      -0.00587909622117877,
      -0.044055286794900894,
      -0.006689500529319048,
      -0.011061875149607658,
      -0.022352783009409904,
      -0.01453994121402502,
      -0.023528937250375748,
      0.02753063291311264,
      -0.036454275250434875,
      -0.006711568683385849,
      -0.01909996010363102,
      0.04321334511041641,
      0.051422297954559326,
      -0.025186613202095032,
      -0.055737290531396866,
      -0.047744229435920715,
      0.0078349020332098,
      -0.039022475481033325,
      -0.022752901539206505,
      0.015622654929757118,
      0.02721172384917736,
      0.04463677108287811,
      -0.04205178841948509,
      0.02395978942513466,
      0.037636399269104004,
      0.013724442571401596,
      -0.0015314824413508177,
      0.02202361635863781,
      -0.0171970222145319,
      -0.05800153315067291,
      -0.025951238349080086,
      0.07801163196563721,
      0.024158582091331482,
      0.01508879940956831,
      -0.0005176161066628993,
      -0.04197894036769867,
      -0.06373552232980728,
      -0.08119309693574905,
      0.008597507141530514,
      -0.0006761639378964901,
      -0.03076384961605072,
      -0.017010042443871498,
      0.024107271805405617,
      0.020454034209251404,
      0.07305954396724701,
      -0.0074854218401014805,
      0.010718518868088722,
      -0.13901087641716003,
      -0.0987047404050827,
      0.00881164614111185,
      0.053719617426395416,
      0.02520965412259102,
      -0.006798791699111462,
      -0.040290262550115585,
      0.0255348552018404,
      -0.0617084763944149,
      0.02398366667330265,
      -0.06640293449163437,
      -0.01940566673874855,
      0.0026768723037093878,
      0.039616867899894714,
      -0.01265304908156395,
      0.05243354290723801,
      0.013841914013028145,
      -0.007671091239899397,
      0.04877238720655441,
      0.006473146844655275,
      0.024473723024129868,
      0.0024858720134943724,
      -0.0872870683670044,
      0.00999924261122942,
      -0.04527442157268524,
      -0.0023295991122722626,
      0.03403601422905922,
      -0.027128683403134346,
      -0.001453662640415132,
      -0.007280267775058746,
      0.060991063714027405,
      0.0032975191716104746,
      0.06189892441034317,
      0.057848669588565826,
      -0.057617511600255966,
      0.0018635604064911604,
      0.010536699555814266,
      -0.025594744831323624,
      -0.033643938601017,
      0.06910897046327591,
      -0.07015281170606613,
      -0.003659765236079693,
      -0.00032796410960145295,
      2.3223690732265823e-05,
      -0.005208693444728851,
      0.03401685878634453,
      -0.041526198387145996,
      -0.04007599130272865,
      0.0676015093922615,
      -0.0023149156477302313,
      -0.05274966359138489,
      0.05683695897459984,
      -0.01694593019783497,
      0.002537027234211564,
      0.009959490969777107,
      -0.023308901116251945,
      -0.003437991952523589,
      0.03891893848776817,
      0.0891764760017395,
      -0.04645225405693054,
      -0.007811807096004486,
      0.05126402899622917,
      -0.0817723497748375,
      -0.0021077992860227823,
      0.04105958342552185,
      -0.0059355441480875015,
      -0.004235673695802689,
      0.021568389609456062,
      -0.01898891292512417,
      -0.08985645323991776,
      -0.008843276649713516,
      -0.03599332645535469,
      -0.02284318394958973,
      0.011083096265792847,
      0.010699520818889141,
      -0.02899264544248581,
      0.1160259023308754,
      -0.08819153159856796,
      -0.04675349220633507,
      -1.0590662895992864e-05,
      0.006180139258503914,
      -0.02479574643075466,
      0.025464696809649467,
      -0.014785954728722572,
      -0.02204100787639618,
      -0.07869123667478561,
      -0.012919262982904911,
      0.03783656656742096,
      -0.027540817856788635,
      -0.009409143589437008,
      0.009578609839081764,
      0.05756719410419464,
      -0.043339915573596954,
      -0.021014302968978882,
      -0.04649489372968674,
      -0.0285137090831995,
      -0.005228464491665363,
      -0.0475878044962883,
      0.06174863129854202,
      0.09816718846559525,
      -0.09829116612672806,
      -0.01858377829194069,
      0.004316960461437702,
      0.05119835212826729,
      0.029836619272828102,
      -0.024621572345495224
    ],
    [
      0.028614653274416924,
      -0.06762996315956116,
      -0.03102470189332962,
      -0.06393197178840637,
      0.02587946318089962,
      -0.018268033862113953,
      0.027061330154538155,
      0.05025234445929527,
      -0.08773532509803772,
      -0.021922854706645012,
      -0.02268598973751068,
      -0.037622567266225815,
      -0.020656194537878036,
      -0.007155172992497683,
      -0.0065484545193612576,
      -0.0032927277497947216,
      0.03688795492053032,
      -0.0015653627924621105,
      -0.11134052276611328,
      -0.08087306469678879,
      0.008096353150904179,
      -0.048289984464645386,
      0.02710152044892311,
      0.018817244097590446,
      -0.025992603972554207,
      -0.013269473798573017,
      -0.06501856446266174,
      0.03883780166506767,
      0.004340562038123608,
      -0.06775712221860886,
      0.0034073523711413145,
      0.06852227449417114,
      -0.059192877262830734,
      0.023368798196315765,
      0.07070531696081161,
      0.024356678128242493,
      0.04298951104283333,
      0.05716583505272865,
      -0.06910345703363419,
      -0.04066557064652443,
      -0.008488749153912067,
      -0.07221081107854843,
      -0.041484516113996506,
      0.05016365274786949,
      -0.03859277814626694,
      -0.07656440883874893,
      -0.00321615906432271,
      0.04930388554930687,
      0.06982304900884628,
      -0.00788218155503273,
      -0.012698444537818432,
      -0.03556748107075691,
      0.04709373414516449,
      0.005808158777654171,
      -0.03312647342681885,
      -0.002913025440648198,
      0.09672413021326065,
      -0.007985444739460945,
      -0.11966892331838608,
      0.06296024471521378,
      0.05413930490612984,
      1.4727922462043352e-05,
      0.02377709187567234,
      -0.07047572731971741,
      0.01896696351468563,
      0.013463331386446953,
      -0.00480310432612896,
      -0.05940082296729088,
      -0.05898449197411537,
      -0.02113295905292034,
      0.029172824695706367,
      0.08217304944992065,
      -0.06095106899738312,
      0.02759227156639099,
      0.04681514576077461,
      0.04615128040313721,
      0.03498557209968567,
      0.07143212854862213,
      0.11852909624576569,
      -0.01309956144541502,
      -0.11553584784269333,
      0.011577735655009747,
      -0.025379613041877747,
      -0.019662508741021156,
      0.0404718853533268,
      0.031975194811820984,
      0.047526948153972626,
      0.03527459502220154,
      -0.01633133739233017,
      0.07133791595697403,
      -0.04108789563179016,
      -0.05069649592041969,
      -0.05912146344780922,
      0.057485248893499374,
      0.06223760545253754,
      -0.07998496294021606,
      0.0032783583737909794,
      -0.0049141463823616505,
      -0.03837617114186287,
      -0.019621623679995537,
      0.0405920185148716,
      -0.06642337143421173,
      0.003941351547837257,
      -0.038403019309043884,
      0.07044212520122528,
      -0.04065542295575142,
      -0.005087639205157757,
      -0.02737800031900406,
      0.03847873583436012,
      0.006345141679048538,
      -0.03311659023165703,
      -0.04678850993514061,
      -0.020165037363767624,
      -0.016803035512566566,
      0.05492682009935379,
      -0.011468816548585892,
      0.02786504104733467,
      0.015101009979844093,
      -0.03575741499662399,
      0.010670103132724762,
      -0.0658491998910904,
      0.02200191468000412,
      -0.01951942779123783,
      0.015853647142648697,
      0.004374159500002861,
      -0.015791915357112885,
      -0.04027285426855087,
      -0.08255978673696518,
      0.00832504965364933,
      0.05092104524374008,
      0.044124193489551544,
      0.015185422264039516,
      -0.020964495837688446,
      -0.027571145445108414,
      0.038802288472652435,
      0.04514539986848831,
      -0.024746296927332878,
      0.021901600062847137,
      0.04036788269877434,
      -0.08189453929662704,
      0.00038814617437310517,
      0.08334905654191971,
      -0.015086831524968147,
      0.003992179408669472,
      0.05073050037026405,
      -0.002246738178655505,
      -0.10742245614528656,
      -0.0012252737069502473,
      -0.0032563931308686733,
      0.005218008067458868,
      -0.0027591746766120195,
      0.015667453408241272,
      0.0503329299390316,
      0.013604330830276012,
      -0.038282301276922226,
      -0.006869112607091665,
      0.07358811050653458,
      0.04253910109400749,
      0.005678798072040081,
      -0.02916986495256424,
      -0.025719180703163147,
      0.047579046338796616,
      0.08022671937942505,
      -0.016940055415034294,
      -0.021611591801047325,
      -0.06668810546398163,
      -0.018746206536889076,
      -0.07373348623514175,
      0.0057556768879294395,
      -0.014373844489455223,
      -0.038717713207006454,
      -0.0012556324945762753,
      0.05259846895933151,
      -0.009229402989149094,
      -0.10473521798849106,
      -0.10058550536632538,
      -0.021605759859085083,
      -0.015857350081205368,
      0.0012002235744148493,
      0.004415454342961311,
      0.1136045828461647,
      0.04520922526717186,
      0.05032689496874809,
      -0.06386925280094147,
      -0.006433156318962574,
      -0.014207501895725727,
      0.002366691594943404,
      -0.06553173810243607,
      -0.043295614421367645,
      -0.00624052993953228,
      -0.010281011462211609,
      0.04385526105761528,
      0.023677445948123932,
      -0.023480283096432686,
      -0.012177897617220879,
      0.030626347288489342,
      -0.0026183195877820253,
      -0.01920141838490963,
      -0.028559381142258644,
      -0.02014647051692009,
      -0.004574838560074568,
      -0.05059513449668884,
      -0.04948246479034424,
      -0.027979329228401184,
      -0.012164240702986717,
      -0.14179161190986633,
      -0.0036727064289152622,
      -0.024840859696269035,
      0.050884585827589035,
      0.017533045262098312,
      0.08230780810117722,
      0.006794224493205547,
      -0.07407251000404358,
      -0.02372543141245842,
      0.0735342800617218,
      -0.03619192913174629,
      -0.003293638816103339,
      -0.06477130204439163,
      0.018641382455825806,
      -0.01727115362882614,
      -0.01677878201007843,
      0.05486876145005226,
      0.05954032763838768,
      0.06392905861139297,
      -0.022797225043177605,
      -0.020930442959070206,
      0.09653345495462418,
      -0.07655719667673111,
      0.04896361380815506,
      -0.0328328013420105,
      0.083164282143116,
      -0.04620122164487839,
      0.053298354148864746,
      0.029327059164643288,
      -0.008700363337993622,
      0.053352028131484985,
      0.011954495683312416,
      0.03430302441120148,
      0.06844042986631393,
      -0.06595651805400848,
      -0.06785307079553604,
      0.0427076630294323,
      0.019638020545244217,
      0.033356908708810806,
      0.050750311464071274,
      0.027341898530721664,
      -0.04300776869058609,
      0.004985397215932608,
      -0.03271844983100891,
      0.00880827009677887,
      -0.06766168028116226,
      0.04339068755507469,
      -0.0196247398853302,
      -0.03885438293218613,
      0.03509432449936867,
      0.04834941402077675,
      0.0691181942820549,
      -0.08436515927314758,
      0.05577096715569496,
      0.03232745826244354,
      -0.05027981102466583,
      -0.015469297766685486,
      -0.016771137714385986,
      -0.005730258766561747,
      0.013611704111099243,
      -0.013738036155700684,
      0.038393136113882065,
      0.040130775421857834,
      -0.0005427526193670928,
      0.07417669147253036,
      0.07465431839227676,
      0.034947335720062256,
      0.026675138622522354,
      0.002258003456518054,
      0.02347933128476143,
      -0.05939071625471115,
      0.005855714902281761,
      0.027325265109539032,
      0.009195182472467422,
      -0.071343794465065,
      -0.01003708504140377,
      0.015029539354145527,
      0.05686674267053604,
      -0.002078120829537511,
      -0.004229210317134857,
      0.024226078763604164,
      0.02428119070827961,
      0.02586275525391102,
      -0.001874223118647933,
      -0.007974782958626747,
      0.030358944088220596,
      -0.09506602585315704,
      -0.03322404995560646,
      0.08056339621543884,
      -0.04791508987545967,
      -0.029808493331074715,
      -0.05892787501215935,
      0.004293745383620262,
      -0.09604695439338684,
      0.026508908718824387,
      0.024683654308319092,
      -0.006947749760001898,
      -0.011655474081635475,
      0.040871720761060715,
      -0.05052124336361885,
      -0.029013769701123238,
      0.005094514228403568,
      0.005721410736441612,
      0.019379258155822754,
      0.004020070191472769,
      0.03976612165570259,
      0.016105707734823227,
      0.06402315944433212,
      -0.046446751803159714,
      0.021914664655923843,
      -0.0011419417569413781,
      -0.043291449546813965,
      0.05497899278998375,
      0.011526533402502537,
      0.03018978424370289,
      0.06237217038869858,
      -0.005977257154881954,
      0.12400825321674347,
      -0.017698049545288086,
      0.06648942828178406,
      0.0345941036939621,
      -0.021762266755104065,
      0.07177403569221497,
      0.056894633919000626,
      -0.016063319519162178,
      0.04546789824962616,
      0.0711233913898468,
      0.011040395125746727,
      0.02612967975437641,
      -0.02788534201681614,
      0.05980595573782921,
      0.02348468266427517,
      0.02458108961582184,
      -0.046667780727148056,
      0.025802455842494965,
      0.04835822805762291,
      -0.12675027549266815,
      0.06692453473806381,
      -0.016392342746257782,
      -0.0067076655104756355,
      0.1017146110534668,
      -0.00309280538931489,
      0.09110575914382935,
      -0.02512679621577263,
      -0.04106464609503746,
      -0.027990827336907387,
      -0.05862561613321304,
      0.027336429804563522,
      -0.024659520015120506,
      -0.007696470245718956,
      0.03036230243742466,
      0.0028981065843254328,
      -0.03402400761842728,
      -0.0842396542429924,
      0.016358990222215652,
      0.006270488258451223,
      0.017881808802485466,
      0.09265369176864624,
      -0.019798574969172478,
      0.04000476375222206,
      -0.043797992169857025,
      0.00919586606323719,
      -0.02750883623957634,
      0.015769394114613533,
      -0.035757698118686676,
      0.0016496486496180296,
      0.004025302827358246,
      -0.0388667918741703,
      0.02319362759590149,
      0.004806983284652233,
      0.09095446020364761,
      -0.028646335005760193,
      -0.09681648015975952,
      0.01936868578195572,
      0.0033108475618064404,
      0.03451387584209442,
      0.000196655179024674,
      0.013072303496301174,
      -0.0028231891337782145,
      -0.12546388804912567,
      0.08471784740686417,
      -0.10667562484741211,
      0.07395289838314056,
      -0.0013057203032076359,
      -0.00416088430210948,
      -0.010989752598106861,
      -0.10897339135408401,
      0.024674108251929283,
      -0.06280650198459625,
      0.059211913496255875,
      0.06966210901737213,
      -0.005104556679725647,
      0.008752242662012577,
      -0.022637728601694107,
      -0.017627011984586716,
      -0.02771155536174774,
      -0.03681078180670738,
      -0.0683448538184166,
      0.006682679057121277,
      -0.011778585612773895,
      -0.015486820600926876,
      -0.05185624212026596,
      0.0017652091337367892,
      0.016096804291009903,
      -0.04139780253171921,
      0.023226352408528328,
      0.05568542703986168,
      -0.036717548966407776,
      0.009718560613691807,
      0.00011244055349379778,
      0.01625022105872631,
      -0.03326033800840378,
      -0.004967340733855963,
      0.035181835293769836,
      -0.0666239932179451,
      -0.058726876974105835,
      0.04649607092142105,
      0.03032570332288742,
      -0.02112552896142006,
      0.0145333306863904,
      0.009287920780479908,
      -0.039610493928194046,
      -0.05435790494084358,
      -0.06381408125162125,
      -0.01956850290298462,
      0.050450585782527924,
      -0.015772931277751923,
      0.03672708943486214,
      0.017512744292616844,
      -0.033881910145282745,
      -0.013995105400681496,
      -0.058270081877708435,
      0.0021433013025671244,
      0.06032437086105347,
      0.0004430102417245507,
      0.0011988270562142134,
      0.013789724558591843,
      -0.06591204553842545,
      -0.03200111910700798,
      0.022610915824770927,
      0.0915345698595047,
      -0.012048629112541676,
      -0.046790383756160736,
      0.05096544325351715,
      0.055993642657995224,
      0.05329294502735138,
      -0.0658964142203331,
      0.06431961804628372,
      0.03642588108778,
      -0.06783516705036163,
      0.024096857756376266,
      0.08759939670562744,
      -0.01820656843483448,
      -0.05304857715964317,
      -0.14696961641311646,
      0.026812398806214333,
      -0.040893975645303726,
      0.07368814200162888,
      0.009840075857937336,
      -0.042613547295331955,
      0.011948334984481335,
      -0.015051193535327911,
      0.0380190871655941,
      0.054694853723049164,
      -0.06835062056779861,
      0.04730513319373131,
      0.05810301750898361,
      -0.02471219375729561,
      0.005899525247514248,
      -0.04591457545757294,
      0.10346417129039764,
      0.06438899040222168,
      0.07165694236755371,
      -0.02529941126704216,
      0.0009336271905340254,
      0.06259313225746155,
      -0.03692401945590973,
      -0.0008150272769853473,
      0.014462163671851158,
      0.017321087419986725,
      0.01590261235833168,
      -0.05698314681649208,
      -0.051565635949373245,
      -0.004956530872732401,
      -0.05302305147051811,
      -0.02240237034857273,
      0.0308830663561821,
      -0.06772463768720627,
      -0.010910251177847385,
      -0.015437258407473564,
      -0.03494482487440109,
      0.013028498739004135,
      -0.051734451204538345,
      0.04070982336997986,
      0.044766489416360855,
      0.05044211447238922,
      0.028797976672649384,
      0.015692656859755516
    ],
    [
      0.0050242384895682335,
      -0.017165066674351692,
      -0.001157182501628995,
      0.01022638101130724,
      0.01944921165704727,
      0.01534451637417078,
      0.01937076635658741,
      -0.023979846388101578,
      -0.004538426175713539,
      -0.009171777404844761,
      0.033236484974622726,
      0.0022439744789153337,
      -0.0015785401919856668,
      -0.052646033465862274,
      0.026590391993522644,
      0.024041663855314255,
      -0.01899804174900055,
      0.00482725165784359,
      0.030935315415263176,
      -0.08994922041893005,
      -0.05289304628968239,
      -0.07845756411552429,
      0.022081997245550156,
      0.003138263477012515,
      0.0075392224825918674,
      -0.05042377486824989,
      -0.02550629712641239,
      0.020442195236682892,
      0.041549138724803925,
      -0.042954087257385254,
      -0.06981953978538513,
      -0.05302682891488075,
      -0.02871350571513176,
      0.0009963508928194642,
      0.014376354403793812,
      -0.023591745644807816,
      -0.03189881891012192,
      0.042093947529792786,
      -0.07067692279815674,
      -0.006728592328727245,
      -0.05523322895169258,
      -0.016573041677474976,
      0.08018701523542404,
      -0.035224780440330505,
      0.018858294934034348,
      0.02460971474647522,
      0.009081674739718437,
      -0.04131019487977028,
      -0.02948055788874626,
      -0.034746792167425156,
      -0.0006760568358004093,
      0.05789528787136078,
      -0.04463556036353111,
      -0.009012623690068722,
      0.075254425406456,
      0.02236122079193592,
      -0.03751217946410179,
      0.014751837588846684,
      -0.021819716319441795,
      -0.017290381714701653,
      -0.030139708891510963,
      0.0786384716629982,
      0.01064399816095829,
      -0.0024658546317368746,
      -0.020030245184898376,
      0.00013251983909867704,
      -0.005079047754406929,
      0.046675872057676315,
      0.0017826398834586143,
      -0.1113789975643158,
      -0.007670457474887371,
      -0.017623096704483032,
      -0.011079972609877586,
      -0.019794698804616928,
      0.008529845625162125,
      0.047811515629291534,
      -0.00468697352334857,
      -0.10483686625957489,
      -0.0260439645498991,
      -0.010522888042032719,
      -0.017148859798908234,
      0.0057027884759008884,
      0.04485751688480377,
      0.029561785981059074,
      -0.10684429854154587,
      -0.01520280260592699,
      0.06878039985895157,
      -0.020825380459427834,
      0.07051390409469604,
      0.02820776216685772,
      0.020592626184225082,
      0.04934864491224289,
      -0.002710511675104499,
      0.010547022335231304,
      -0.04392097890377045,
      -0.05085502937436104,
      0.031272824853658676,
      0.06812237948179245,
      -0.050759006291627884,
      0.05359994247555733,
      -0.046121928840875626,
      -0.009164029732346535,
      -0.010101186111569405,
      -0.04835882410407066,
      -0.025749171152710915,
      0.08028066903352737,
      0.034296222031116486,
      0.004473507404327393,
      -0.0064643677324056625,
      0.03326902166008949,
      -0.027243712916970253,
      0.0728311687707901,
      -0.032168254256248474,
      -0.0700509175658226,
      -0.07225871831178665,
      0.007253481075167656,
      0.01575089991092682,
      -0.010737418197095394,
      0.04448537901043892,
      0.024522416293621063,
      -0.13606475293636322,
      0.1127021536231041,
      0.0683622732758522,
      -0.059538938105106354,
      0.021002119407057762,
      -0.019840946421027184,
      -0.006752311252057552,
      -0.006078754551708698,
      -0.012926428578794003,
      0.06025884672999382,
      -0.0004262143629603088,
      0.006412404589354992,
      -0.007215272635221481,
      0.009144650772213936,
      0.03180517628788948,
      -0.018890555948019028,
      0.0698729082942009,
      -0.050117187201976776,
      -0.0028929172549396753,
      -0.045639608055353165,
      0.002964734099805355,
      0.03006346896290779,
      0.01565474458038807,
      0.011904057115316391,
      0.04393152520060539,
      0.02868417277932167,
      -0.07702354341745377,
      0.023024654015898705,
      0.027663767337799072,
      -0.011132746934890747,
      -0.0022292351350188255,
      -0.038333017379045486,
      0.07130567729473114,
      0.0019467101665213704,
      0.049039602279663086,
      0.016314806416630745,
      -0.0425533652305603,
      -0.023531757295131683,
      -0.04085483029484749,
      -0.008590464480221272,
      0.009542361833155155,
      -0.022368405014276505,
      0.044770557433366776,
      -0.08623985946178436,
      0.017473479732871056,
      -0.022071201354265213,
      0.05955589562654495,
      0.027400607243180275,
      0.10292116552591324,
      -0.03606276214122772,
      0.03733734413981438,
      0.043530192226171494,
      0.020474741235375404,
      -0.11731110513210297,
      -0.05880184471607208,
      -0.021950429305434227,
      1.4168615052767564e-05,
      0.0481710322201252,
      0.02941237948834896,
      0.04516247287392616,
      -0.0505249947309494,
      -0.030589710921049118,
      -0.05815212056040764,
      0.002017171820625663,
      -0.015603517182171345,
      -0.08084537088871002,
      0.03413606062531471,
      0.0027480116114020348,
      -0.013958396390080452,
      0.05954037979245186,
      0.006090941838920116,
      -0.008724028244614601,
      0.0014126165769994259,
      -0.11100003868341446,
      -0.013344698585569859,
      0.03715135157108307,
      0.05117415636777878,
      0.0211991798132658,
      0.12013416737318039,
      -0.00972645916044712,
      -0.026647746562957764,
      0.06404897570610046,
      0.005334516055881977,
      -0.01236604806035757,
      -0.09384792298078537,
      0.03855938836932182,
      0.005938957445323467,
      -0.011464138515293598,
      -0.011336601339280605,
      -0.03813057392835617,
      0.022590454667806625,
      -0.0033809146843850613,
      0.0291915163397789,
      -0.07830725610256195,
      -0.007227790541946888,
      0.005002519115805626,
      -0.002070599002763629,
      -0.02695770189166069,
      0.05495554953813553,
      -0.034205302596092224,
      -0.031164931133389473,
      -0.04241262003779411,
      0.06343376636505127,
      0.04503468796610832,
      0.047566305845975876,
      -0.038157869130373,
      0.024714183062314987,
      -0.0792996883392334,
      0.06350494176149368,
      0.013999734073877335,
      0.0093369260430336,
      -0.02715251035988331,
      -0.036875881254673004,
      0.09467413276433945,
      -0.0256218072026968,
      0.06214640289545059,
      0.017796803265810013,
      0.017854206264019012,
      0.0032370327971875668,
      -0.03235289454460144,
      0.06806442141532898,
      0.017901575192809105,
      -0.0070697530172765255,
      0.011268269270658493,
      -0.011463119648396969,
      -0.08081606775522232,
      -0.003675029845908284,
      0.0320708267390728,
      0.05286969244480133,
      -0.0027426353190094233,
      0.01748468726873398,
      -0.053037989884614944,
      -0.006699386052787304,
      -0.09820104390382767,
      0.04222177341580391,
      0.005452724173665047,
      -0.08999667316675186,
      -0.003735343459993601,
      -0.03359733894467354,
      -0.00010530163126531988,
      -0.06361070275306702,
      -0.09077896922826767,
      0.07339175045490265,
      0.05657004565000534,
      -0.0709369033575058,
      0.0008654878474771976,
      0.04289606586098671,
      0.05118832737207413,
      -0.01451858226209879,
      0.00047322394675575197,
      -0.007930242456495762,
      -0.03322824463248253,
      -0.019239917397499084,
      0.02078893780708313,
      -0.12170454114675522,
      -0.014255698770284653,
      -0.007212541066110134,
      -0.0010873738210648298,
      -0.07086973637342453,
      -0.09518761932849884,
      0.029560556635260582,
      0.0632757917046547,
      -0.09001283347606659,
      0.03279336914420128,
      0.06452325731515884,
      0.01037926971912384,
      -0.0198614951223135,
      -0.00021413697686512023,
      0.0056928787380456924,
      -0.016271483153104782,
      -0.020111123099923134,
      0.02564685046672821,
      0.07514075189828873,
      0.0016570249572396278,
      -0.03743822127580643,
      -0.0026902102399617434,
      0.024286560714244843,
      0.060914721339941025,
      0.04601551964879036,
      0.008222290314733982,
      -0.02038421295583248,
      -0.021391412243247032,
      0.008605723269283772,
      0.01786532811820507,
      0.03996226564049721,
      0.034237902611494064,
      -0.03594524413347244,
      -0.012864517979323864,
      -0.02603236958384514,
      0.04781466722488403,
      0.05504857748746872,
      -0.0400339737534523,
      -0.0308589655905962,
      -0.004864067770540714,
      -0.06643923372030258,
      0.05585449934005737,
      0.021332094445824623,
      -0.03732669726014137,
      -0.020658379420638084,
      0.002369036665186286,
      0.01976020261645317,
      -0.03975259140133858,
      0.011059676297008991,
      -0.07228917628526688,
      -0.03134334459900856,
      0.03048654831945896,
      0.016148710623383522,
      -0.02606320008635521,
      0.00669136131182313,
      0.019183265045285225,
      -0.020213844254612923,
      -0.029400428757071495,
      -0.08953763544559479,
      0.021088488399982452,
      0.005288726184517145,
      -0.025958292186260223,
      -0.05715062841773033,
      -0.027886241674423218,
      -0.07160941511392593,
      -0.02859230898320675,
      0.02821974642574787,
      -0.04285099729895592,
      0.016258765012025833,
      -0.04254359006881714,
      0.060995303094387054,
      -0.02622894011437893,
      0.02383810095489025,
      0.01584489271044731,
      0.027639521285891533,
      0.025661401450634003,
      -0.014800474978983402,
      0.014785009436309338,
      -0.012484967708587646,
      0.03153497725725174,
      -0.032005686312913895,
      0.012563406489789486,
      -0.06303825974464417,
      0.03479831665754318,
      -0.04055546596646309,
      -0.0748477503657341,
      0.0541812963783741,
      -0.004778157453984022,
      -0.05505414679646492,
      -0.022249752655625343,
      -0.035369873046875,
      0.09560829401016235,
      -0.006958088371902704,
      -0.02307826280593872,
      -0.051552098244428635,
      -0.04830290004611015,
      0.006964158266782761,
      0.01261604018509388,
      -0.05073118954896927,
      0.02145407535135746,
      -0.04666294530034065,
      -0.0763397291302681,
      0.007013493217527866,
      -0.015051810070872307,
      -0.052889514714479446,
      -0.027967970818281174,
      -0.03303202986717224,
      0.11566337198019028,
      0.0826447531580925,
      0.02655264176428318,
      -0.03771944344043732,
      -0.030753813683986664,
      0.007709313649684191,
      -0.03512212634086609,
      0.018047329038381577,
      0.0162352304905653,
      0.034515131264925,
      0.020256808027625084,
      -0.026048343628644943,
      0.03256233036518097,
      0.04135415703058243,
      -0.02896871790289879,
      0.03177381679415703,
      0.023080063983798027,
      -0.03894574195146561,
      0.08153463900089264,
      -0.01749238558113575,
      0.08593057841062546,
      0.07808510214090347,
      -0.07899568229913712,
      0.01968587003648281,
      -0.060126617550849915,
      0.022830937057733536,
      -0.03055887669324875,
      -0.054263658821582794,
      0.01525648683309555,
      0.0403742752969265,
      -0.07314037531614304,
      0.023892022669315338,
      0.08442697674036026,
      -0.03530719503760338,
      0.021087035536766052,
      -0.01206505112349987,
      -0.116553895175457,
      0.00817764364182949,
      -0.009506246075034142,
      0.042759306728839874,
      -0.01257894653826952,
      -0.07052457332611084,
      -0.04150666296482086,
      -0.02163824811577797,
      -0.050480518490076065,
      -0.04852202162146568,
      -0.029745502397418022,
      -0.03309641778469086,
      0.04404233396053314,
      -0.08958110213279724,
      0.015065974555909634,
      -0.08219743520021439,
      -0.05770225077867508,
      0.04764139652252197,
      -0.03284479305148125,
      -0.03704637661576271,
      -0.034874845296144485,
      -0.0344289131462574,
      -0.02169819362461567,
      -0.03053216263651848,
      0.015716146677732468,
      -0.028620580211281776,
      0.05401962250471115,
      -0.015597740188241005,
      0.09191515296697617,
      -0.03965149447321892,
      -0.003301250049844384,
      0.014325406402349472,
      -0.05410159006714821,
      0.038085855543613434,
      0.0511273592710495,
      -0.017262475565075874,
      0.014282826334238052,
      -0.025104066357016563,
      -0.05768470838665962,
      0.01078761089593172,
      -0.03191407397389412,
      -0.026620816439390182,
      0.006631060503423214,
      -0.0247640423476696,
      -0.09460043907165527,
      0.06164016202092171,
      0.07806507498025894,
      -0.05120863392949104,
      0.04650091752409935,
      -0.04469730332493782,
      -0.0041195121593773365,
      0.04475134238600731,
      0.014654768630862236,
      0.036640021950006485,
      0.029348265379667282,
      0.007274328265339136,
      -0.03405556455254555,
      -0.0015505716437473893,
      -0.014317342080175877,
      -0.03069162741303444,
      -0.08232656866312027,
      -0.03405704349279404,
      0.022081516683101654,
      -0.08652274310588837,
      0.022406762465834618,
      0.01425545196980238,
      0.1439768671989441,
      0.021551843732595444,
      0.0703628808259964,
      0.000288226903649047,
      0.03428075835108757,
      0.03656050190329552,
      -0.027471620589494705,
      -0.0489024817943573,
      -0.06145012378692627,
      -0.14051908254623413,
      -0.04677138850092888,
      0.027976810932159424,
      0.009037320502102375,
      0.040703143924474716,
      0.09841465204954147,
      -0.035620659589767456,
      0.039882343262434006,
      0.03959495201706886,
      0.026048393920063972,
      -0.02291191928088665
    ],
    [
      -0.01573730632662773,
      -0.00960603728890419,
      -0.03226323053240776,
      -0.02548510953783989,
      0.002661940874531865,
      0.016013560816645622,
      0.003598218783736229,
      0.050975169986486435,
      -0.0016656534280627966,
      0.10083215683698654,
      0.015970692038536072,
      -0.03548986464738846,
      -0.013189980760216713,
      -0.05386442691087723,
      -0.011177233420312405,
      -0.08992375433444977,
      0.01651507057249546,
      0.04304913431406021,
      -0.045477740466594696,
      0.07166862487792969,
      0.03238169103860855,
      -0.14024105668067932,
      -0.010931730270385742,
      0.14220750331878662,
      0.038911350071430206,
      -0.023682594299316406,
      0.05098087340593338,
      -0.07247604429721832,
      0.04516372084617615,
      0.06583890318870544,
      -0.06583407521247864,
      0.01813795045018196,
      -0.011099685914814472,
      0.010591061785817146,
      -0.006949376314878464,
      -0.015427549369633198,
      0.05928013473749161,
      -0.019636744633316994,
      -0.044087380170822144,
      -0.009311693720519543,
      -0.02211136184632778,
      -0.04085638001561165,
      0.029085727408528328,
      0.03408481925725937,
      -0.029936034232378006,
      -0.036535441875457764,
      0.038634538650512695,
      0.03097277693450451,
      -0.03968534991145134,
      0.07447653263807297,
      -0.03244929015636444,
      0.01718040369451046,
      0.06343932449817657,
      -0.03210844472050667,
      0.021970761939883232,
      0.006426780950278044,
      -0.06068122386932373,
      -0.025604069232940674,
      -0.013989390805363655,
      -0.0018710845615714788,
      0.014265669509768486,
      0.0749942734837532,
      -0.02923457883298397,
      0.012194974347949028,
      -0.007186933420598507,
      -0.009787079878151417,
      0.04138927161693573,
      -0.029002374038100243,
      -0.013002452440559864,
      -0.06973378360271454,
      -0.054118216037750244,
      -0.06251626461744308,
      0.033757343888282776,
      -0.03645680099725723,
      -0.06824257969856262,
      -0.015277808532118797,
      -0.030344229191541672,
      -0.013021172024309635,
      0.001395916217006743,
      -0.011771746911108494,
      -0.0055030155926942825,
      0.02580742910504341,
      0.08475041389465332,
      -0.0012801747070625424,
      -0.03609447181224823,
      -0.011753041297197342,
      -0.0423613116145134,
      0.04780996963381767,
      -0.04161763936281204,
      0.005262993276119232,
      -0.02734837681055069,
      -0.010908029973506927,
      0.0912049263715744,
      -0.045478977262973785,
      -0.002550440141931176,
      -0.04342459514737129,
      -0.010098581202328205,
      0.06878911703824997,
      -0.0013356258859857917,
      -0.031199563294649124,
      0.0061379787512123585,
      0.011834469623863697,
      0.05372631922364235,
      0.04149734228849411,
      0.04729394242167473,
      -0.036864664405584335,
      0.052729275077581406,
      0.020571086555719376,
      -0.031204989179968834,
      -0.03137136250734329,
      0.03687227889895439,
      -0.03999558463692665,
      -0.05923153832554817,
      0.02947719767689705,
      -0.0401364229619503,
      -0.017824189737439156,
      -0.043437410145998,
      -0.014178181067109108,
      0.07063166797161102,
      0.059079594910144806,
      -0.01782972179353237,
      -0.04320308938622475,
      -0.0456165112555027,
      0.02040027268230915,
      0.10507138073444366,
      -0.00651365052908659,
      -0.011443492956459522,
      -0.040373921394348145,
      -0.03724728897213936,
      0.07917581498622894,
      -0.01733253337442875,
      -0.05553894117474556,
      0.020195726305246353,
      -0.05225391685962677,
      0.07155545055866241,
      0.01563546434044838,
      -0.001252008369192481,
      0.06750693172216415,
      -0.01855095475912094,
      -0.0005864963750354946,
      -0.05153021588921547,
      -0.04359451308846474,
      0.04106775298714638,
      0.016771506518125534,
      -0.05385655164718628,
      -0.020870445296168327,
      -0.03027641773223877,
      -0.06038454920053482,
      0.025136355310678482,
      0.04101704806089401,
      0.034693147987127304,
      0.04701678454875946,
      0.06918062269687653,
      -0.01449048426002264,
      0.03180377557873726,
      -0.031571730971336365,
      -0.03694334253668785,
      0.08788600564002991,
      -0.008312013931572437,
      -0.051656126976013184,
      -0.006806905381381512,
      -0.012450654059648514,
      -0.00783705897629261,
      -0.0030214227735996246,
      0.011768571101129055,
      -0.009023112244904041,
      0.05533916875720024,
      0.004312258679419756,
      0.003990153782069683,
      -0.012134106829762459,
      -0.08348548412322998,
      -0.0030108897481113672,
      -0.08304674923419952,
      -0.04358167573809624,
      0.033157698810100555,
      0.07735389471054077,
      -0.041123274713754654,
      -0.033550359308719635,
      0.043662842363119125,
      0.024360083043575287,
      -0.070372074842453,
      0.0390627346932888,
      0.027601471170783043,
      -0.07163264602422714,
      -0.05180588737130165,
      -0.023338068276643753,
      0.02719131112098694,
      0.03882727399468422,
      -0.041999682784080505,
      0.048570666462183,
      0.040674783289432526,
      -0.013395425863564014,
      -0.019340429455041885,
      0.00975391548126936,
      0.05851401388645172,
      -0.048328179866075516,
      0.006493639666587114,
      0.02087741158902645,
      -0.043119776993989944,
      -0.011988282203674316,
      -0.004596578422933817,
      -0.009655850008130074,
      -0.03914346918463707,
      -0.08363979309797287,
      0.05265511944890022,
      0.007606790866702795,
      -0.027127647772431374,
      -0.023608660325407982,
      0.006724792066961527,
      -0.012203613296151161,
      -0.009879790246486664,
      -0.018058491870760918,
      0.05960202217102051,
      0.03508545458316803,
      -0.012961506843566895,
      -0.07570139318704605,
      -0.01419766154140234,
      0.021529557183384895,
      0.0744074136018753,
      -0.04326457530260086,
      -0.05691416561603546,
      -0.0014636364066973329,
      0.13093571364879608,
      0.00014512446068692952,
      -0.011283901520073414,
      0.05687471479177475,
      0.03379366546869278,
      0.032919857650995255,
      0.021493785083293915,
      -0.024812204763293266,
      -0.08983595669269562,
      -0.026209255680441856,
      0.017299596220254898,
      0.03681805729866028,
      -0.02845125086605549,
      -0.03347022086381912,
      0.08532349020242691,
      0.026552820578217506,
      0.0027907954063266516,
      -0.00834710244089365,
      0.07495851814746857,
      0.007544088643044233,
      -0.03839455917477608,
      0.03345414623618126,
      -0.004791472107172012,
      0.02814962901175022,
      0.04361862689256668,
      -0.021227899938821793,
      0.035881541669368744,
      0.02760937064886093,
      0.07380557060241699,
      0.039168745279312134,
      -0.04646175354719162,
      0.01890248991549015,
      0.08509690314531326,
      0.0540103018283844,
      -0.028632428497076035,
      0.06398668885231018,
      0.022295739501714706,
      0.038023605942726135,
      0.04738914966583252,
      0.030591221526265144,
      -0.1043953225016594,
      0.013845869340002537,
      0.0004412260605022311,
      0.020828476175665855,
      0.020436683669686317,
      0.024821501225233078,
      0.03697831556200981,
      -0.027278319001197815,
      0.022519426420331,
      0.07174098491668701,
      -0.059186503291130066,
      -0.017296692356467247,
      -0.01625720225274563,
      0.03916221112012863,
      -0.006557518616318703,
      -0.06259975582361221,
      0.027381399646401405,
      -0.05273319035768509,
      0.04567346349358559,
      -0.0653890073299408,
      0.02756027691066265,
      -0.024702414870262146,
      0.0027577641885727644,
      -0.0010228418977931142,
      0.0004701696161646396,
      -0.07221982628107071,
      -0.004225167445838451,
      -0.01149347797036171,
      0.03659043833613396,
      -0.004838751628994942,
      0.014571121893823147,
      -0.012054473161697388,
      -0.059868134558200836,
      0.0016414131969213486,
      -0.04272044450044632,
      -0.011627048254013062,
      0.024691900238394737,
      -0.006671923212707043,
      -0.05521385371685028,
      0.05594095587730408,
      -0.014583280310034752,
      -0.011102850548923016,
      -0.06582708656787872,
      -0.025606615468859673,
      0.02643372304737568,
      -0.04787826165556908,
      -0.007991201244294643,
      0.02461058832705021,
      -0.0177681315690279,
      0.029281815513968468,
      -0.0019935171585530043,
      -0.028308125212788582,
      0.010384968481957912,
      0.002954293042421341,
      -0.009576653130352497,
      -0.03361538425087929,
      -0.025980425998568535,
      -0.05218211188912392,
      -0.03061346709728241,
      0.033947862684726715,
      -0.014378841035068035,
      0.10748400539159775,
      0.07698042690753937,
      0.085842564702034,
      -0.012167677283287048,
      -0.05448266118764877,
      0.017649857327342033,
      -0.10079596191644669,
      -0.0032142852433025837,
      -0.04707786440849304,
      -0.006338471546769142,
      0.06812795251607895,
      0.022571340203285217,
      -0.05085121467709541,
      -0.09923442453145981,
      0.0671832337975502,
      0.0925346091389656,
      0.010284028016030788,
      0.022580189630389214,
      0.030033838003873825,
      -0.005813333671540022,
      0.015538349747657776,
      0.002426914870738983,
      -0.028694843873381615,
      0.026738744229078293,
      -0.005955178290605545,
      0.02830386906862259,
      -0.024202780798077583,
      -0.0302444938570261,
      0.08050979673862457,
      -0.03297289088368416,
      0.0024173264391720295,
      -0.0010555621702224016,
      0.042258258908987045,
      -0.015017343685030937,
      -0.002683736151084304,
      -0.014565763995051384,
      0.04276653379201889,
      0.032399922609329224,
      0.016538718715310097,
      -0.03262108936905861,
      -0.007739157881587744,
      0.07126325368881226,
      -0.028506504371762276,
      0.03796432167291641,
      0.031813278794288635,
      -0.012682025320827961,
      0.014246439561247826,
      0.013197969645261765,
      0.03968556970357895,
      -0.022567180916666985,
      -0.12030597776174545,
      0.012162048369646072,
      0.0053104739636182785,
      0.05065256357192993,
      0.013771908357739449,
      -0.03205176442861557,
      -0.001348620280623436,
      -0.03235869109630585,
      0.019619785249233246,
      -0.003920643124729395,
      0.04216514155268669,
      0.053465016186237335,
      -0.021131427958607674,
      -0.018916338682174683,
      -0.0716385766863823,
      -0.01700928434729576,
      -0.060156166553497314,
      0.007710039149969816,
      -0.06626399606466293,
      -0.10505016893148422,
      -0.03897378593683243,
      0.004884292837232351,
      -0.0015042040031403303,
      -0.05071448162198067,
      0.04136664420366287,
      0.05886721983551979,
      -0.04646735265851021,
      0.09614286571741104,
      0.023739328607916832,
      -0.040839068591594696,
      -0.03242408484220505,
      0.041975803673267365,
      -0.021934887394309044,
      0.018140854313969612,
      0.01913871243596077,
      -0.09042605012655258,
      0.018204910680651665,
      -0.029932642355561256,
      -0.06879543513059616,
      0.03292457014322281,
      -0.00023918617807794362,
      0.00942094624042511,
      0.05174993351101875,
      0.003804008476436138,
      -0.02001943066716194,
      -0.0043980032205581665,
      0.0051684873178601265,
      0.016646994277834892,
      0.015239530242979527,
      0.023829849436879158,
      -0.022945504635572433,
      -0.014126668684184551,
      -0.041165273636579514,
      -0.1483137458562851,
      -0.03887780383229256,
      -0.04251410812139511,
      0.03520670533180237,
      -0.022158944979310036,
      0.02959572710096836,
      -0.01139423530548811,
      -0.06275580078363419,
      0.056859686970710754,
      -0.0733051747083664,
      -0.009987940080463886,
      -0.05911800637841225,
      -0.0021298720967024565,
      -0.03842943534255028,
      -0.06357082724571228,
      -0.018923373892903328,
      -0.017818383872509003,
      0.05621258169412613,
      0.024288048967719078,
      0.06804090738296509,
      0.017107319086790085,
      0.0076041454449296,
      -0.03831479698419571,
      0.03338101878762245,
      -0.0272058118134737,
      0.01709621213376522,
      0.06674051284790039,
      0.046870745718479156,
      0.09708620607852936,
      0.10970214754343033,
      -0.0364503487944603,
      0.037228066474199295,
      0.027177786454558372,
      -0.047028373926877975,
      -0.0013195911888033152,
      0.03011389821767807,
      -0.040514566004276276,
      -0.006043001543730497,
      -0.012183730490505695,
      -0.03578617051243782,
      -0.056228067725896835,
      -0.0991218313574791,
      -0.1339317113161087,
      0.06131090968847275,
      0.09958190470933914,
      0.02912960760295391,
      -0.013141190633177757,
      0.0060343267396092415,
      -0.07669320702552795,
      -0.04767102375626564,
      -0.05976539105176926,
      -0.045800790190696716,
      -0.038609184324741364,
      -0.019946394488215446,
      -0.009443347342312336,
      -0.08698780089616776,
      -0.011230421252548695,
      -0.003657891182228923,
      0.016902845352888107,
      -0.05763882398605347,
      0.006675576791167259,
      -0.006008537951856852,
      -0.01719735935330391,
      -0.015672657638788223,
      -0.044037479907274246,
      -0.004089977126568556,
      -0.07206104695796967,
      0.0006835954263806343,
      -0.0041877953335642815,
      0.050579801201820374,
      -0.08224918693304062,
      -0.11633330583572388,
      0.0074729411862790585,
      -0.040607720613479614,
      -0.011527159251272678,
      -0.05248820036649704,
      0.06166987866163254
    ],
    [
      -0.016334958374500275,
      0.03174349665641785,
      0.0578870065510273,
      0.05202416330575943,
      0.06103578209877014,
      0.02298174984753132,
      0.027043137699365616,
      -0.01496873702853918,
      0.006762465462088585,
      0.049657344818115234,
      -0.09631893783807755,
      -0.01212735939770937,
      -0.0775524377822876,
      -0.040435634553432465,
      0.03005111590027809,
      -0.06545595824718475,
      0.07279971241950989,
      0.05766173452138901,
      -0.006598629057407379,
      0.009762722998857498,
      -0.08765774220228195,
      0.011220177635550499,
      -0.014676152728497982,
      0.02842382714152336,
      0.06396588683128357,
      -0.04245929792523384,
      -0.02449069358408451,
      -0.06583592295646667,
      0.014365187846124172,
      -0.023014843463897705,
      0.04186480492353439,
      -0.001078402274288237,
      0.03271336853504181,
      0.09362328797578812,
      0.0466613695025444,
      0.036844730377197266,
      -0.07455623894929886,
      0.04386793076992035,
      0.061779122799634933,
      -0.05025014653801918,
      -0.022898590192198753,
      0.02931678295135498,
      -0.009145310148596764,
      -0.06390837579965591,
      -0.014560814946889877,
      -0.02633831650018692,
      0.03552217409014702,
      0.01780560426414013,
      -0.06826253980398178,
      0.003191558411344886,
      0.09267514944076538,
      0.004457520321011543,
      -0.0028412111569195986,
      0.022317130118608475,
      -0.002658410696312785,
      -0.05441856384277344,
      0.012726119719445705,
      -0.055754244327545166,
      -0.0834197923541069,
      -0.031052103266119957,
      -0.006241594906896353,
      -0.0012094847625121474,
      0.04738246649503708,
      -0.030209286138415337,
      0.008790075778961182,
      0.0409054160118103,
      -0.05434940382838249,
      -0.020263317972421646,
      -0.0338999405503273,
      0.03496198728680611,
      -0.009764882735908031,
      -0.015176957473158836,
      -0.073519267141819,
      -0.0895916149020195,
      -0.014529915526509285,
      0.021426046267151833,
      0.014569271355867386,
      0.07615692168474197,
      -0.04060552269220352,
      0.012051823548972607,
      0.03264186903834343,
      0.02920132502913475,
      0.08629250526428223,
      0.052081793546676636,
      -0.03891005367040634,
      -0.03780430555343628,
      -0.031187953427433968,
      0.054901476949453354,
      -0.06808533519506454,
      -0.032562948763370514,
      -0.0056749796494841576,
      0.04974576458334923,
      -0.03885554522275925,
      0.0011526212329044938,
      -0.039403848350048065,
      0.0489066056907177,
      0.029696810990571976,
      -0.014596687629818916,
      -0.0285782627761364,
      -0.00851130299270153,
      -0.03205323964357376,
      0.02733241207897663,
      -0.036871813237667084,
      -0.025625715032219887,
      -0.03643137589097023,
      0.042887698858976364,
      -0.018810076639056206,
      -0.05128691345453262,
      -0.047034699469804764,
      0.026284176856279373,
      -0.05603717640042305,
      -0.023143421858549118,
      -0.012627621181309223,
      0.013660826720297337,
      0.0470498688519001,
      -0.054747190326452255,
      0.044294096529483795,
      0.03237070515751839,
      -0.01021407637745142,
      0.007684921380132437,
      -0.16494393348693848,
      -0.12318288534879684,
      0.023673510178923607,
      0.06116996705532074,
      -0.006827112752944231,
      -0.05116336792707443,
      -0.07951153814792633,
      -0.012163332663476467,
      -0.042824637144804,
      -0.015569427981972694,
      0.04122941941022873,
      0.02298804186284542,
      0.007669900078326464,
      0.03841247782111168,
      0.028530048206448555,
      0.04848058521747589,
      -0.047247495502233505,
      -0.0785764753818512,
      -0.005988944787532091,
      0.06849654018878937,
      -0.042115718126297,
      0.018726393580436707,
      0.0649176836013794,
      -0.001118804095312953,
      0.06916383653879166,
      0.015363496728241444,
      -0.04221295192837715,
      0.027577629312872887,
      -0.02478065714240074,
      -0.049636110663414,
      -0.002161020878702402,
      0.053166378289461136,
      0.04067816585302353,
      -0.08717380464076996,
      0.03291836008429527,
      0.03173661604523659,
      0.0027242733631283045,
      -0.027549175545573235,
      0.018225980922579765,
      0.011074163019657135,
      -0.0056264158338308334,
      0.09792803972959518,
      0.0007632647757418454,
      -0.04984809085726738,
      -0.05122014880180359,
      -0.05040973797440529,
      0.06909309327602386,
      -0.008208057843148708,
      -0.02773212641477585,
      -0.03175107389688492,
      -0.06783147156238556,
      0.013641739264130592,
      0.024059578776359558,
      -0.013295456767082214,
      -0.027267001569271088,
      -0.02412085421383381,
      0.04580283537507057,
      -0.01741773821413517,
      0.006321161054074764,
      -0.04551663622260094,
      -0.04319462552666664,
      -0.020532827824354172,
      0.07463327795267105,
      -0.0032232829835265875,
      -0.03186716139316559,
      0.04723283275961876,
      -0.026189232245087624,
      -0.014017597772181034,
      -0.0005991686484776437,
      -0.0932748019695282,
      -0.029561258852481842,
      0.02983066998422146,
      -0.024441467598080635,
      -0.02146228589117527,
      -0.025716885924339294,
      0.023754484951496124,
      0.005428002215921879,
      -0.018637508153915405,
      0.07793519645929337,
      0.006949427537620068,
      0.033981386572122574,
      0.02956012822687626,
      0.014881360344588757,
      -0.05442238226532936,
      -0.017113743349909782,
      -0.032352153211832047,
      0.06084788218140602,
      -0.033123552799224854,
      0.01132686622440815,
      -0.006136945448815823,
      0.018396489322185516,
      -0.0494520477950573,
      -0.062076669186353683,
      -0.02796843647956848,
      0.06662458926439285,
      0.10829073190689087,
      -0.011807577684521675,
      -0.04363272711634636,
      -0.004701028577983379,
      0.006970914546400309,
      0.004732330329716206,
      0.044804561883211136,
      0.04920761287212372,
      0.04995938390493393,
      0.025444502010941505,
      0.06413917988538742,
      -0.033412765711545944,
      -0.000992893590591848,
      0.06153671443462372,
      0.0847388282418251,
      0.031431253999471664,
      0.08627775311470032,
      -0.07365025579929352,
      -0.004309390671551228,
      -0.011404447257518768,
      0.013118063099682331,
      0.09303325414657593,
      -0.020947350189089775,
      0.057225923985242844,
      -0.0664592906832695,
      0.006640569772571325,
      -0.061433665454387665,
      -0.06968437880277634,
      0.010062457993626595,
      0.028927773237228394,
      -0.058516357094049454,
      -0.10335886478424072,
      -0.03733588755130768,
      -0.03558053448796272,
      0.057778071612119675,
      -0.03602466732263565,
      0.01843104138970375,
      0.041117943823337555,
      0.02972215786576271,
      -0.05157788470387459,
      0.007544245105236769,
      -0.048824556171894073,
      -0.03599202260375023,
      -0.06456173211336136,
      -0.0017242883332073689,
      -0.0011331544956192374,
      0.0807218998670578,
      0.013423466123640537,
      -0.021216068416833878,
      -0.04102328047156334,
      -0.02238421142101288,
      -0.016083691269159317,
      -0.0656156837940216,
      -0.006904845591634512,
      -0.05634346231818199,
      0.030004072934389114,
      -0.017045896500349045,
      -0.038187116384506226,
      -0.0014896736247465014,
      -0.035239748656749725,
      0.008618556894361973,
      0.03193184360861778,
      0.0037341066636145115,
      -0.02106347121298313,
      -0.054832663387060165,
      -0.005057135131210089,
      -0.036198899149894714,
      0.04605623334646225,
      -0.05086403712630272,
      0.05346236005425453,
      -0.036909110844135284,
      -0.04865236580371857,
      0.018144527450203896,
      -0.03897050768136978,
      -0.007412849925458431,
      0.01445518434047699,
      0.005531442351639271,
      -0.014189120382070541,
      -0.09780628979206085,
      -0.08722347766160965,
      -0.07820300757884979,
      -0.02737555280327797,
      0.011499148793518543,
      -0.010052335448563099,
      0.020921221002936363,
      -0.028458744287490845,
      -0.026154017075896263,
      -0.03175754100084305,
      0.06825236976146698,
      0.05141475424170494,
      -0.059645239263772964,
      0.020499471575021744,
      -0.05003600940108299,
      -0.06057858094573021,
      -0.008523786440491676,
      -0.01987183280289173,
      -0.008381862193346024,
      -0.02339228056371212,
      0.00973876379430294,
      -0.0039076125249266624,
      0.041024040430784225,
      0.005685349460691214,
      -0.0318620391190052,
      -0.0356321707367897,
      -0.019420379772782326,
      0.021125854924321175,
      0.006110096350312233,
      0.046252764761447906,
      -0.05176764726638794,
      -0.03423973172903061,
      0.09105154871940613,
      0.08581122756004333,
      -0.051610276103019714,
      -0.0018659625202417374,
      -0.037442658096551895,
      0.04019807651638985,
      0.0020223218016326427,
      0.0549243725836277,
      0.08773764222860336,
      -0.02381780557334423,
      0.0637824684381485,
      0.027952279895544052,
      0.030367732048034668,
      0.05554919317364693,
      0.04509996995329857,
      -0.03389374911785126,
      0.06327997148036957,
      0.02456727996468544,
      0.022441402077674866,
      -0.05622893571853638,
      0.03390764445066452,
      0.002294542035087943,
      0.04212206229567528,
      0.11216719448566437,
      0.012560252100229263,
      -0.022477302700281143,
      -0.06308282166719437,
      0.006620274856686592,
      0.04361661151051521,
      -0.01369000319391489,
      0.015628991648554802,
      0.020546160638332367,
      0.06419240683317184,
      0.04110705107450485,
      -0.07994142174720764,
      -0.02048599161207676,
      -0.040821585804224014,
      0.011469985358417034,
      0.040391646325588226,
      0.003222927451133728,
      -0.013934286311268806,
      -0.010130652226507664,
      -0.06984008103609085,
      -0.0137166827917099,
      -0.05864487960934639,
      0.03481099754571915,
      -0.0007578085060231388,
      0.025734009221196175,
      0.012322047725319862,
      -0.018567156046628952,
      -0.052484676241874695,
      0.05175195634365082,
      0.00872792024165392,
      -0.054849300533533096,
      0.11553441733121872,
      0.06264884769916534,
      -0.023962711915373802,
      -0.05625573545694351,
      -0.013090670108795166,
      -0.04183138534426689,
      0.09581397473812103,
      0.012140309438109398,
      0.018367983400821686,
      -0.08350741863250732,
      -0.0769270658493042,
      0.046924855560064316,
      -0.05914173275232315,
      -0.028069036081433296,
      0.000422299635829404,
      0.11510448157787323,
      0.06204741448163986,
      -0.019831320270895958,
      0.034264229238033295,
      0.04933493211865425,
      0.009481076151132584,
      0.008318114094436169,
      -0.001440615626052022,
      -0.06414543837308884,
      -0.05671223625540733,
      -0.016529161483049393,
      0.01697218418121338,
      0.09082171320915222,
      0.027574673295021057,
      -0.018875591456890106,
      -0.010527428239583969,
      0.009036743082106113,
      -0.039954300969839096,
      0.00975086074322462,
      0.06196758523583412,
      0.028090540319681168,
      -0.06016049534082413,
      -0.07718519121408463,
      0.020855063572525978,
      -0.008702781051397324,
      0.037870217114686966,
      -0.03210374340415001,
      -0.00025895462022162974,
      -0.00871912483125925,
      0.013242409564554691,
      0.018797922879457474,
      -0.007816874422132969,
      -0.0031750700436532497,
      0.04067211225628853,
      -0.017502471804618835,
      -0.03366194665431976,
      -0.005473421420902014,
      -0.041069887578487396,
      -0.0004650638729799539,
      0.004416354466229677,
      -0.025603119283914566,
      -0.038580186665058136,
      -0.011485630646348,
      -0.038325510919094086,
      0.02527020312845707,
      -0.026294369250535965,
      0.00894200336188078,
      0.031892064958810806,
      0.05946117267012596,
      -0.07455450296401978,
      0.03028554469347,
      -0.014008594676852226,
      0.03070918098092079,
      -0.019120972603559494,
      -0.008410640992224216,
      -0.012395988218486309,
      0.03520746901631355,
      -0.012600474059581757,
      0.003807144006714225,
      0.041391901671886444,
      0.11244375258684158,
      -0.03807925432920456,
      0.009892502799630165,
      0.01879563182592392,
      -0.03300607576966286,
      -0.024244321510195732,
      -0.08081380277872086,
      -0.02244141884148121,
      -0.009947347454726696,
      0.016753120347857475,
      0.03716251999139786,
      0.014905537478625774,
      -0.006071755662560463,
      -0.004364371299743652,
      0.023188021034002304,
      0.019408786669373512,
      0.039604783058166504,
      -0.027006682008504868,
      -0.12905967235565186,
      -0.07115419209003448,
      -0.03945223242044449,
      -0.01904766447842121,
      -0.09559018164873123,
      0.02902218885719776,
      0.001682570786215365,
      -0.015534918755292892,
      0.0421488918364048,
      -0.037819571793079376,
      -0.08895687013864517,
      -0.0475178025662899,
      0.01860858127474785,
      -0.026418551802635193,
      0.014829164370894432,
      -0.015583792701363564,
      -0.11579173803329468,
      0.07785799354314804,
      0.009830564260482788,
      0.08046925067901611,
      0.020284544676542282,
      0.03471842035651207,
      0.03753461688756943,
      -0.026259608566761017,
      -0.014622760936617851,
      0.06299705803394318,
      -0.06516927480697632,
      -0.019479980692267418,
      0.03761468082666397,
      -0.016637656837701797,
      0.0761243999004364
    ],
    [
      0.021632671356201172,
      0.0041591268964111805,
      -0.011753970757126808,
      0.010063565336167812,
      -0.05666020140051842,
      0.046652089804410934,
      0.03640270605683327,
      0.09037218987941742,
      0.04085925966501236,
      -0.0008543039439246058,
      -0.10706338286399841,
      -0.04059356078505516,
      0.11307471245527267,
      0.02579917572438717,
      0.015705367550253868,
      0.07664012163877487,
      0.011856603436172009,
      -0.07854782044887543,
      -0.06067650020122528,
      0.08533161133527756,
      -0.05639471858739853,
      0.033890366554260254,
      -0.03301600366830826,
      -0.0036129415966570377,
      0.0029211649671196938,
      -0.010547460988163948,
      0.012385955080389977,
      -0.07224751263856888,
      -0.04704940691590309,
      0.056916963309049606,
      -0.02736792527139187,
      -0.029716193675994873,
      0.10714104771614075,
      -0.05391136556863785,
      0.01074900384992361,
      -0.04360872879624367,
      -0.010626687668263912,
      -0.028528276830911636,
      -0.029177358373999596,
      0.030864546075463295,
      -0.04121699929237366,
      0.008778046816587448,
      0.06663859635591507,
      0.021249961107969284,
      -0.012158411554992199,
      -0.01993791200220585,
      0.011659987270832062,
      -0.027121342718601227,
      0.0022816064301878214,
      -0.01771027222275734,
      0.013950766064226627,
      0.018216131255030632,
      -0.0004342757456470281,
      -0.09589177370071411,
      0.06691162288188934,
      0.04544579237699509,
      -0.01815725676715374,
      -0.01698298007249832,
      0.01486935280263424,
      0.04107305407524109,
      -0.02055535838007927,
      -0.06306745857000351,
      -0.02574537880718708,
      -0.0007140552625060081,
      -0.06904922425746918,
      -0.03191154822707176,
      0.004680888261646032,
      0.010158918797969818,
      -0.02409808523952961,
      0.0006893660756759346,
      -0.01619512401521206,
      0.00541280210018158,
      -0.01950102113187313,
      0.017267869785428047,
      -0.08259981125593185,
      0.04577644541859627,
      -0.08661884814500809,
      0.00707274628803134,
      -0.022919263690710068,
      0.06083633378148079,
      -0.01036322582513094,
      0.009175512008368969,
      -0.05443422496318817,
      0.010470075532793999,
      0.00042803407995961607,
      0.022140465676784515,
      -0.022451546043157578,
      0.07634920626878738,
      -0.06199435517191887,
      -0.021284421905875206,
      -0.021481631323695183,
      -0.01457595732063055,
      -0.002116440562531352,
      0.03561633825302124,
      -0.07919759303331375,
      -0.021674681454896927,
      0.012305989861488342,
      -0.03553569316864014,
      0.016791539266705513,
      0.03838024660944939,
      0.05293869972229004,
      0.08093348890542984,
      -0.01927909441292286,
      0.005468349438160658,
      0.016427021473646164,
      -0.024750113487243652,
      0.031137807294726372,
      0.008169497363269329,
      0.06291161477565765,
      0.11203855276107788,
      -0.037952523678541183,
      0.028490344062447548,
      -0.050999853760004044,
      -0.005577711388468742,
      0.028483819216489792,
      0.023081311956048012,
      -0.06400145590305328,
      -0.04926321655511856,
      -0.017833596095442772,
      -0.07530173659324646,
      -0.04917698726058006,
      0.036796506494283676,
      -0.0681779533624649,
      -0.05223296582698822,
      0.015124978497624397,
      -0.03262016922235489,
      -0.037849970161914825,
      -0.03465035557746887,
      0.016618311405181885,
      -0.05430806800723076,
      -0.016192933544516563,
      -0.005955911241471767,
      -0.03871838375926018,
      0.0009274197509512305,
      -0.036458201706409454,
      -0.016554241999983788,
      -0.0028474628925323486,
      -0.0005328984698280692,
      0.009502774104475975,
      0.023087386041879654,
      -0.04720362275838852,
      0.01797298528254032,
      -0.027552319690585136,
      -0.10491541028022766,
      0.013087896630167961,
      -0.04819387570023537,
      0.027101900428533554,
      0.022916113957762718,
      0.03253977373242378,
      -0.04743555933237076,
      -0.049359239637851715,
      0.05151967704296112,
      -0.02965684048831463,
      0.0342535637319088,
      0.022494465112686157,
      -0.06143852323293686,
      0.009528913535177708,
      -0.011259120889008045,
      -0.009775339625775814,
      0.1124863475561142,
      0.05240803584456444,
      0.04932485148310661,
      0.012247415259480476,
      -0.02827649936079979,
      0.029559200629591942,
      -0.005884459707885981,
      -0.04159454628825188,
      0.02928200177848339,
      0.012420103885233402,
      0.00044654705561697483,
      0.056883540004491806,
      0.05057515576481819,
      -0.030301326885819435,
      -0.07741948217153549,
      -0.01051254104822874,
      -1.5169008293014485e-05,
      0.06215691938996315,
      0.07460495084524155,
      0.013375879265367985,
      -0.03967003896832466,
      0.04660270735621452,
      -0.02794945053756237,
      -0.027430033311247826,
      -0.0018301097443327308,
      -0.0494987890124321,
      -0.011096834205091,
      0.013036893680691719,
      0.006735370494425297,
      -0.04420185834169388,
      0.02771717682480812,
      0.018928298726677895,
      -0.09620030969381332,
      0.008326249197125435,
      -0.030719799920916557,
      -0.02827887423336506,
      0.0011708843521773815,
      -0.0005248269881121814,
      0.05522209033370018,
      0.05693786218762398,
      0.04422828555107117,
      -0.017525896430015564,
      -0.030834922567009926,
      0.0229053795337677,
      -0.0980818122625351,
      -0.012628474272787571,
      -0.03584832698106766,
      0.04517775774002075,
      -0.01902475208044052,
      -0.017858261242508888,
      0.03287661820650101,
      0.0076423101127147675,
      0.0338154062628746,
      -0.048067864030599594,
      0.009734180755913258,
      -0.027349120005965233,
      -0.01892196387052536,
      0.017139103263616562,
      -0.045922402292490005,
      0.005390596576035023,
      -0.008244803175330162,
      -0.04235009849071503,
      0.016158876940608025,
      -0.04742921143770218,
      -0.011671341024339199,
      0.023228773847222328,
      -0.024220675230026245,
      -0.06949420273303986,
      0.03140297904610634,
      -0.008563166484236717,
      -0.0313865952193737,
      -0.02390933595597744,
      -0.01210603304207325,
      -0.04104629158973694,
      0.029019327834248543,
      0.055579330772161484,
      -0.025312084704637527,
      -0.03936917707324028,
      -0.0014811401488259435,
      0.008835145272314548,
      0.015606827102601528,
      -0.012597747147083282,
      0.04819055274128914,
      0.02876846306025982,
      0.06763054430484772,
      -0.01474114041775465,
      0.03813497722148895,
      0.04731938615441322,
      0.05876460671424866,
      0.02502819336950779,
      -0.05495329201221466,
      -0.01935981772840023,
      0.01930989697575569,
      -0.010942335240542889,
      0.05922303721308708,
      -0.06071655824780464,
      -0.030976902693510056,
      0.09727713465690613,
      0.012385617941617966,
      0.09266161918640137,
      0.015162448398768902,
      -0.0034812423400580883,
      -0.015705786645412445,
      -0.08039217442274094,
      -0.02263994701206684,
      0.03488888218998909,
      -0.13870929181575775,
      0.03642662987112999,
      -0.003998020198196173,
      0.06847157329320908,
      0.02701352722942829,
      0.07241246849298477,
      -0.03841308131814003,
      0.006480747833848,
      -0.012212835252285004,
      -0.023474935442209244,
      -0.04092898964881897,
      0.07843383401632309,
      0.048241134732961655,
      -0.04058850556612015,
      0.024128686636686325,
      0.019473891705274582,
      0.0029439458157867193,
      0.009592652320861816,
      0.014001308009028435,
      0.04902283847332001,
      0.0009228989947587252,
      0.053901731967926025,
      -0.11221341043710709,
      -0.00026239402359351516,
      -0.02183561399579048,
      0.034442923963069916,
      0.06551487743854523,
      -0.10716995596885681,
      -0.012761875987052917,
      -0.01749451830983162,
      0.010285566560924053,
      -0.010645211674273014,
      0.023060908541083336,
      -0.012948266230523586,
      -0.08349499851465225,
      0.10483741760253906,
      0.019969239830970764,
      -0.005299178417772055,
      0.0001210206828545779,
      0.029965819790959358,
      -0.02192997932434082,
      0.0023628484923392534,
      0.0016232753405347466,
      -0.039702314883470535,
      -0.049612075090408325,
      0.037099216133356094,
      0.028788138180971146,
      0.023631839081645012,
      0.016345098614692688,
      -0.03866127133369446,
      -0.007910126820206642,
      0.011968141421675682,
      0.005981136579066515,
      0.039594151079654694,
      -0.012088158167898655,
      0.02769208326935768,
      0.04543871432542801,
      0.02349047362804413,
      -0.01952270418405533,
      -0.009215270169079304,
      0.05093923583626747,
      0.027062583714723587,
      -0.04601016640663147,
      -0.02546442113816738,
      0.02776430919766426,
      -0.044634267687797546,
      -0.03882402554154396,
      0.01882723532617092,
      -0.0338016152381897,
      -0.013687514699995518,
      0.02657197415828705,
      0.08193767815828323,
      0.05542266368865967,
      0.0018189586699008942,
      -0.05848686024546623,
      0.014467810280621052,
      -0.022791389375925064,
      -0.022458231076598167,
      -0.06425891071557999,
      -0.03506171703338623,
      -0.03962039574980736,
      -0.011734893545508385,
      0.05124787613749504,
      -0.017983121797442436,
      0.07812635600566864,
      -0.04087838530540466,
      0.02675320953130722,
      -0.02593897096812725,
      -0.01754436269402504,
      0.0884191244840622,
      0.043707188218832016,
      0.012204141356050968,
      -0.07454289495944977,
      -0.03718177601695061,
      0.01600322499871254,
      -0.00890961941331625,
      -0.020399384200572968,
      -0.011839107610285282,
      -0.0058667841367423534,
      0.026615379378199577,
      0.10842207819223404,
      -0.07294364273548126,
      0.057398006319999695,
      -0.10296868532896042,
      0.0025003559421747923,
      -0.0009374780929647386,
      0.06370962411165237,
      0.014288399368524551,
      0.01932552643120289,
      -0.00420778663828969,
      0.014823034405708313,
      0.013438286259770393,
      -0.033388711512088776,
      -0.09619364142417908,
      0.022589409723877907,
      -0.04242448881268501,
      0.05195274576544762,
      0.022060886025428772,
      0.003255225485190749,
      0.041301429271698,
      0.07031494379043579,
      0.013896206393837929,
      0.017745904624462128,
      -0.014879168942570686,
      0.029386529698967934,
      0.07433842867612839,
      -0.009487414732575417,
      0.0019836535211652517,
      0.025007380172610283,
      0.056175924837589264,
      0.039693981409072876,
      -0.049718357622623444,
      -0.012026106007397175,
      -0.100587859749794,
      -0.023357581347227097,
      -0.0478677898645401,
      0.03099604696035385,
      0.014954072423279285,
      -0.0337914414703846,
      0.047437671571969986,
      -0.0102312583476305,
      -0.04175985977053642,
      -0.014877457171678543,
      -0.02306123450398445,
      0.009695258922874928,
      0.03128601238131523,
      0.033887457102537155,
      0.059786923229694366,
      -0.017711598426103592,
      0.10056097060441971,
      -0.043727047741413116,
      -0.02000671811401844,
      -0.00904158502817154,
      -0.012075553648173809,
      0.021892670542001724,
      0.03386751934885979,
      -0.002057256642729044,
      -0.02690696530044079,
      0.005685714073479176,
      0.019263098016381264,
      -0.0019233654020354152,
      -0.009637883864343166,
      -0.018316613510251045,
      0.002233515027910471,
      0.030563056468963623,
      -0.02186877466738224,
      0.004336332902312279,
      0.03281421959400177,
      -0.055767256766557693,
      -0.05219660699367523,
      0.0017587710171937943,
      0.10203583538532257,
      -0.0002162012387998402,
      -0.04712635278701782,
      -0.04938573017716408,
      -0.04568621516227722,
      0.09251166135072708,
      0.09634791314601898,
      0.0352114699780941,
      0.07400745153427124,
      -0.027320338413119316,
      -0.050120092928409576,
      -0.03406539931893349,
      0.07119094580411911,
      -0.03606753796339035,
      0.05181887745857239,
      0.04947537183761597,
      0.05355631187558174,
      0.004763671662658453,
      0.006283005233854055,
      -0.022178277373313904,
      0.04183422774076462,
      -0.0422971248626709,
      0.010869566351175308,
      -0.09086363762617111,
      -0.018669117242097855,
      0.054278165102005005,
      0.015081259422004223,
      -0.04393847659230232,
      -0.06392709910869598,
      -0.08180037885904312,
      0.062075208872556686,
      0.07385299354791641,
      -0.031446438282728195,
      0.032904718071222305,
      0.0719539150595665,
      0.07115740329027176,
      0.02399984560906887,
      0.05613149330019951,
      0.013517932035028934,
      -0.0341809056699276,
      -0.022924529388546944,
      0.010396361351013184,
      0.07793998718261719,
      0.007805518805980682,
      0.07100389897823334,
      -0.08763071894645691,
      -0.015061561018228531,
      0.04896686226129532,
      -0.006435321178287268,
      -0.0425957627594471,
      0.038565054535865784,
      -0.028294973075389862,
      -0.02691323310136795,
      -0.06268586963415146,
      -0.004622253589332104,
      0.02266004867851734,
      0.04955216124653816,
      0.06319965422153473,
      0.0702999085187912,
      0.09593264013528824,
      0.03540834039449692,
      0.002957400633022189,
      0.004833401180803776,
      -0.08361129462718964,
      -0.03243059664964676,
      0.009368225000798702,
      0.016809234395623207
    ],
    [
      0.021383509039878845,
      -0.08056682348251343,
      -0.05994817242026329,
      0.0020605570171028376,
      0.023531118407845497,
      0.02805379033088684,
      0.03166455402970314,
      -0.00715101882815361,
      0.052977535873651505,
      0.0629768893122673,
      -0.07589204609394073,
      0.04950106889009476,
      -0.05372191593050957,
      -0.010575060732662678,
      0.05871669948101044,
      -0.03852006047964096,
      0.005828659515827894,
      0.006579073145985603,
      -0.01600295677781105,
      0.0681920275092125,
      0.04936029762029648,
      0.05789554864168167,
      -0.027977732941508293,
      -0.015592201612889767,
      0.039431750774383545,
      -0.016381390392780304,
      -0.01870856247842312,
      -0.007293704431504011,
      0.007017355877906084,
      -0.016236452385783195,
      -0.025838186964392662,
      -0.027206363156437874,
      -0.015508981421589851,
      -0.03226159140467644,
      0.004534055478870869,
      -0.009565480053424835,
      -0.016544844955205917,
      -0.002951756352558732,
      0.007870201952755451,
      0.01313262339681387,
      -0.03402728587388992,
      -0.012386765331029892,
      0.03531011939048767,
      0.031032543629407883,
      0.06906705349683762,
      -0.0549471415579319,
      -0.0345388762652874,
      0.02618471160531044,
      0.01882474310696125,
      -0.012672661803662777,
      0.014816509559750557,
      -0.04841482266783714,
      0.03221994638442993,
      -0.007811694405972958,
      0.02060844376683235,
      -0.024602027609944344,
      -0.016220062971115112,
      0.018798690289258957,
      -0.07351774722337723,
      0.04016336053609848,
      0.04274741932749748,
      0.013030795380473137,
      -0.04600537568330765,
      -0.006771585904061794,
      -0.08145006000995636,
      0.031437937170267105,
      -0.006384544540196657,
      -0.07489287853240967,
      0.03464949503540993,
      0.030147826299071312,
      0.01802080310881138,
      0.05040426552295685,
      0.009200806729495525,
      0.027636699378490448,
      0.01727449707686901,
      -0.07198287546634674,
      -0.06828819215297699,
      0.005934282671660185,
      0.0883001834154129,
      0.075379878282547,
      0.01606064662337303,
      0.05401822552084923,
      -0.004062038846313953,
      0.02662118896842003,
      -0.008533447980880737,
      0.02268548309803009,
      0.03588659688830376,
      0.019820686429739,
      0.02090011164546013,
      0.09072285145521164,
      0.028766091912984848,
      0.02685689553618431,
      0.00827101245522499,
      -0.011539562605321407,
      -0.09977336972951889,
      -0.04765854775905609,
      -0.03907245397567749,
      0.016585953533649445,
      -0.07862964272499084,
      0.05591399595141411,
      -0.03119811788201332,
      0.017079604789614677,
      -0.06490520387887955,
      -0.025254111737012863,
      -0.025732973590493202,
      -0.06619410216808319,
      0.030876535922288895,
      0.094913549721241,
      0.08405428379774094,
      -0.05569985508918762,
      0.006437745410948992,
      0.01388611737638712,
      -0.001812727190554142,
      0.004994058981537819,
      -0.0366048701107502,
      0.06828974187374115,
      -0.1191156879067421,
      0.04309730604290962,
      0.04445132985711098,
      -0.03874228894710541,
      -0.08561152219772339,
      -0.021729908883571625,
      -0.03858646750450134,
      -0.02634040080010891,
      0.02065185084939003,
      0.07413282990455627,
      0.011394299566745758,
      0.008879251778125763,
      0.01081785373389721,
      0.04150558263063431,
      0.035818956792354584,
      0.07332705706357956,
      -0.026517342776060104,
      -0.037287402898073196,
      -0.008836216293275356,
      0.0006142783095128834,
      0.03691548481583595,
      0.04487906023859978,
      -0.03425709530711174,
      0.023487862199544907,
      -0.02222023904323578,
      0.08123715221881866,
      -0.026105834171175957,
      -0.008075570687651634,
      -0.030438179150223732,
      -0.010007602162659168,
      -0.008238421753048897,
      -0.10634966939687729,
      0.006035992875695229,
      0.0074458965100348,
      -0.01759689673781395,
      0.0011028626468032598,
      -0.040774304419755936,
      0.09177939593791962,
      -0.07224773615598679,
      -0.028182996436953545,
      0.03132972866296768,
      0.009551756083965302,
      -0.01823783479630947,
      -0.06084958463907242,
      0.0498071052134037,
      0.04829227924346924,
      -0.036182016134262085,
      -0.05145609751343727,
      0.020022356882691383,
      -0.002023136243224144,
      0.015875859186053276,
      0.06463541090488434,
      -0.012903055176138878,
      0.056620631366968155,
      -0.0343441516160965,
      0.023182077333331108,
      -0.009197830222547054,
      -0.0010123316897079349,
      0.004283629357814789,
      0.0175944771617651,
      -0.013033458031713963,
      0.023980779573321342,
      0.010660480707883835,
      -0.02807649038732052,
      -0.006074973382055759,
      -0.01812128908932209,
      0.07587949931621552,
      -0.035707905888557434,
      0.025815144181251526,
      -0.030378200113773346,
      -0.015464694239199162,
      -0.0761212557554245,
      -0.003161020576953888,
      0.06046085059642792,
      0.002731513697654009,
      -0.07678348571062088,
      -0.03295038267970085,
      0.017509130761027336,
      -0.06049849092960358,
      -0.0019612519536167383,
      -0.06047314405441284,
      -0.026384927332401276,
      0.09863424301147461,
      0.0772400051355362,
      0.06539323180913925,
      -0.0014991138596087694,
      0.03198012337088585,
      0.017745718359947205,
      -0.02116616629064083,
      0.027898456901311874,
      -0.02135542780160904,
      -0.0017910945462062955,
      0.010836392641067505,
      -0.012178578414022923,
      0.04321113973855972,
      -0.019979827105998993,
      -0.03598526120185852,
      0.01453743502497673,
      -0.04973364248871803,
      0.0357295386493206,
      -0.005185443442314863,
      0.018709594383835793,
      -0.038608748465776443,
      -0.050844788551330566,
      -0.0009972896659746766,
      0.014754856005311012,
      0.04328500106930733,
      -0.01805589161813259,
      -0.016754301264882088,
      -0.0529622882604599,
      0.023194031789898872,
      0.024037513881921768,
      0.000336683529894799,
      0.026308143511414528,
      0.04915754497051239,
      0.04786886274814606,
      0.011591187678277493,
      -0.060199905186891556,
      0.048780929297208786,
      0.02610943280160427,
      0.037892501801252365,
      0.09138386696577072,
      -0.0022591501474380493,
      -0.04496097192168236,
      0.011803099885582924,
      -0.042110294103622437,
      -0.020510151982307434,
      -0.005735469050705433,
      0.02727608196437359,
      -0.06263221055269241,
      0.01059517078101635,
      -0.04530167579650879,
      0.09706616401672363,
      0.06703822314739227,
      0.07702040672302246,
      0.13148894906044006,
      0.06580059230327606,
      -0.012262835167348385,
      0.06903322786092758,
      -0.019184492528438568,
      -0.017547791823744774,
      0.035280998796224594,
      0.003146955044940114,
      -0.009344974532723427,
      0.036408182233572006,
      -0.02082672156393528,
      -0.02860589697957039,
      0.02593431994318962,
      -0.010403376072645187,
      -0.04600215330719948,
      0.005021962337195873,
      0.0257476307451725,
      0.004376389551907778,
      -0.10306262969970703,
      0.05490484461188316,
      -0.019888117909431458,
      0.021767960861325264,
      0.010074788704514503,
      0.027124952524900436,
      0.03489215672016144,
      -0.07516700029373169,
      0.05164187774062157,
      -0.010420636273920536,
      -0.010938899591565132,
      0.06962882727384567,
      0.08087007701396942,
      0.011763297952711582,
      -0.08943953365087509,
      -0.016595741733908653,
      -0.07835131138563156,
      -0.061151985079050064,
      -0.06473863124847412,
      -0.0032947566360235214,
      0.0012375239748507738,
      0.02854323387145996,
      -0.03213873878121376,
      0.04651270806789398,
      -0.02363240160048008,
      0.0401589535176754,
      0.049409568309783936,
      -0.060291096568107605,
      -0.012521540746092796,
      -0.07182800024747849,
      0.01878681778907776,
      -0.021117405965924263,
      -0.039911456406116486,
      0.020644372329115868,
      -0.025834474712610245,
      0.028349706903100014,
      0.024930572137236595,
      -0.017490867525339127,
      0.05233776196837425,
      -0.012608425691723824,
      0.005758634768426418,
      -0.03867484629154205,
      -0.04067796468734741,
      0.0530225932598114,
      0.03820756822824478,
      -0.016576750203967094,
      0.03921068459749222,
      0.016586188226938248,
      0.03924519568681717,
      -0.024502014741301537,
      -0.05377606302499771,
      0.07865649461746216,
      -0.04572703316807747,
      0.07912267744541168,
      -0.0395827479660511,
      0.08325272798538208,
      -0.03960422798991203,
      0.06720320135354996,
      -0.02994644269347191,
      -0.0010529890423640609,
      -0.08064115792512894,
      0.05046892911195755,
      -9.088760270969942e-05,
      -0.07305146753787994,
      -0.01768910512328148,
      0.01911357045173645,
      0.051247235387563705,
      -0.02672768011689186,
      0.024977652356028557,
      0.0342225581407547,
      0.057625144720077515,
      -0.032684437930583954,
      -0.022993452847003937,
      -0.04865017533302307,
      -0.005870991852134466,
      -0.021647678688168526,
      -0.06416807323694229,
      0.026359619572758675,
      0.010060056112706661,
      0.04528002068400383,
      -0.03735768049955368,
      -0.07741234451532364,
      -0.008324670605361462,
      0.07416665554046631,
      0.04943057894706726,
      -0.07375220209360123,
      0.004284907132387161,
      -0.010785078629851341,
      0.0006872693775221705,
      0.061604175716638565,
      0.000776623492129147,
      0.04194875806570053,
      0.03890746459364891,
      -0.024304337799549103,
      0.08034532517194748,
      0.03104814514517784,
      -0.0009309867746196687,
      0.017739739269018173,
      0.03702125698328018,
      -0.04832248017191887,
      -0.04494813084602356,
      -0.03014812432229519,
      0.04691464081406593,
      -0.07632596045732498,
      -0.046734217554330826,
      0.03791571781039238,
      0.0566701665520668,
      -0.08636204153299332,
      0.043988145887851715,
      0.03179195895791054,
      -0.04893257096409798,
      0.035070035606622696,
      0.008416916243731976,
      0.015966005623340607,
      -0.046918097883462906,
      -0.02155563049018383,
      -0.05250842496752739,
      0.02314867451786995,
      0.018109416589140892,
      0.00047772153629921377,
      0.030442431569099426,
      0.0015119826421141624,
      -0.07281506806612015,
      -0.020573031157255173,
      0.08610563725233078,
      0.06729066371917725,
      -0.009941833093762398,
      -0.008229401893913746,
      0.07603422552347183,
      -0.030481401830911636,
      0.08381251245737076,
      0.03639942780137062,
      0.052028339356184006,
      0.007767791394144297,
      0.010277846828103065,
      0.03114728070795536,
      -0.020936505869030952,
      -0.03859646990895271,
      0.03711468726396561,
      -0.08106625080108643,
      0.0397457554936409,
      -0.01819857582449913,
      -0.021238034591078758,
      0.013628373853862286,
      -0.08133185654878616,
      0.06243101507425308,
      -0.06372477859258652,
      0.0519806407392025,
      0.023490985855460167,
      -0.0028954078443348408,
      0.020116249099373817,
      0.003790046786889434,
      -1.6607611541985534e-05,
      0.00899807270616293,
      -0.04494057595729828,
      -0.08940738439559937,
      -0.09465868026018143,
      0.014299072325229645,
      0.035830941051244736,
      0.01715647615492344,
      -0.01987835206091404,
      -0.02337854541838169,
      -0.004221493378281593,
      0.061935652047395706,
      0.02399684302508831,
      -0.0234421007335186,
      -0.011152827180922031,
      0.035322632640600204,
      -0.026448072865605354,
      -0.0326153002679348,
      0.04179626703262329,
      -0.07173250615596771,
      -0.01727943867444992,
      -0.024091411381959915,
      0.058030273765325546,
      -0.01925794407725334,
      0.01929694041609764,
      0.020544739440083504,
      0.063006192445755,
      -0.061117108911275864,
      0.047023601830005646,
      -0.015024427324533463,
      0.015000459738075733,
      0.01599997468292713,
      -0.0029188846237957478,
      0.008201196789741516,
      -0.037871669977903366,
      0.003938780166208744,
      -0.05400143191218376,
      -0.05684388801455498,
      0.08770688623189926,
      0.0697295069694519,
      0.04990806058049202,
      0.053775690495967865,
      0.0873442143201828,
      0.05544804781675339,
      -0.025148387998342514,
      0.10698995739221573,
      0.09115827083587646,
      -0.0481741763651371,
      -0.00026918482035398483,
      -0.025687556713819504,
      -0.031327128410339355,
      -0.010377738624811172,
      0.016594255343079567,
      -0.0575714185833931,
      0.03369729593396187,
      -0.030506370589137077,
      0.009951569139957428,
      -0.01398965623229742,
      -0.00044298043940216303,
      0.02999763749539852,
      -0.0036589547526091337,
      0.05723237618803978,
      0.01896112598478794,
      -0.10097937285900116,
      -0.003763745538890362,
      0.010065170004963875,
      -0.007272135931998491,
      -0.03885984420776367,
      0.03773987293243408,
      -0.06555382162332535,
      -0.004257801920175552,
      -0.03336036577820778,
      -0.04888074845075607,
      -0.00044890641584061086,
      -0.04325825348496437,
      -0.08078497648239136,
      0.03363372012972832,
      0.050143688917160034,
      -0.04662025719881058,
      -0.05488525331020355,
      0.009634105488657951,
      0.061230141669511795
    ],
    [
      0.059379175305366516,
      0.048523277044296265,
      -0.03769325092434883,
      -0.007630534935742617,
      -0.07440710067749023,
      0.004343183245509863,
      0.019397001713514328,
      -0.020424993708729744,
      0.02127767913043499,
      -0.0013905668165534735,
      -0.024698859080672264,
      -0.01508744154125452,
      -0.049265217036008835,
      -0.018008511513471603,
      -0.022522594779729843,
      -0.008951693773269653,
      -0.07627338916063309,
      0.020994575694203377,
      -0.007688041776418686,
      -0.08043284714221954,
      -0.05809985473752022,
      0.016815459355711937,
      -0.011170006357133389,
      0.031552642583847046,
      0.0245199091732502,
      0.027586113661527634,
      0.0034698033705353737,
      0.00033791171154007316,
      0.044794827699661255,
      0.12483717501163483,
      -0.029992112889885902,
      0.023584628477692604,
      0.02088957093656063,
      -0.00886488612741232,
      -0.022664645686745644,
      -0.024156874045729637,
      -0.09213653206825256,
      -0.009530855342745781,
      0.0439927913248539,
      0.07474219799041748,
      0.0390479750931263,
      0.057354263961315155,
      0.1224905326962471,
      -0.0054632518440485,
      -0.08543599396944046,
      0.10516173392534256,
      -0.044466279447078705,
      -0.03402406722307205,
      -0.030299272388219833,
      -0.08982276916503906,
      0.05150997266173363,
      -0.03482111543416977,
      0.03473776951432228,
      -0.08593134582042694,
      0.06186004355549812,
      -0.05066394805908203,
      0.06784451007843018,
      -0.04473871365189552,
      0.021673250943422318,
      -0.0592074990272522,
      -0.02072090096771717,
      -0.021501366049051285,
      0.04828212037682533,
      0.044338878244161606,
      0.02776159718632698,
      0.0005547204054892063,
      -0.019096489995718002,
      0.05170206353068352,
      -0.05346198007464409,
      -0.00535003375262022,
      -0.01514282263815403,
      0.0033190848771482706,
      -0.13280628621578217,
      0.054207395762205124,
      0.04304032027721405,
      0.015935465693473816,
      0.01077647227793932,
      0.03754573315382004,
      0.021628363057971,
      -0.01657964661717415,
      0.023977436125278473,
      0.05128692835569382,
      0.05327889695763588,
      0.04417009651660919,
      0.023806534707546234,
      0.01959352381527424,
      0.1050504520535469,
      -0.008596988394856453,
      0.01022832840681076,
      0.00417886720970273,
      0.02054368518292904,
      -0.029381070286035538,
      -0.004872236866503954,
      0.013475785963237286,
      -0.008350168354809284,
      0.03675537183880806,
      -0.023719800636172295,
      0.008958714082837105,
      -0.10958503931760788,
      0.020092537626624107,
      -0.022732341662049294,
      0.03908386081457138,
      0.03070905990898609,
      -0.08493722230195999,
      0.033293645828962326,
      -0.02454821951687336,
      0.01819641701877117,
      0.04843799024820328,
      -0.013049033470451832,
      0.034775927662849426,
      0.05213962495326996,
      0.08967654407024384,
      0.018190165981650352,
      -0.029163295403122902,
      -0.12533557415008545,
      0.0069611649960279465,
      -0.044103994965553284,
      0.023075319826602936,
      -0.05860362574458122,
      0.019608624279499054,
      -0.10607681423425674,
      0.039608802646398544,
      0.02852780744433403,
      -0.07529863715171814,
      -0.008282595314085484,
      0.013206071220338345,
      -0.02006194181740284,
      0.03757453337311745,
      -0.021601155400276184,
      0.028158795088529587,
      0.060219842940568924,
      0.0019633856136351824,
      0.05274392291903496,
      -0.03067338466644287,
      -0.040496621280908585,
      0.009100453928112984,
      -0.0261863861232996,
      0.038681283593177795,
      0.03210628032684326,
      0.1505115032196045,
      0.002912872703745961,
      0.08530061691999435,
      -0.052126698195934296,
      0.04709705710411072,
      0.054267629981040955,
      0.0021479004062712193,
      0.007627434097230434,
      0.0022123411763459444,
      0.02446923963725567,
      0.05229545012116432,
      0.01477112341672182,
      -0.06611491739749908,
      -0.054676998406648636,
      -0.04657912626862526,
      -0.06584970653057098,
      -0.0033267654944211245,
      0.024158407002687454,
      -0.030399030074477196,
      0.025997700169682503,
      0.00732825044542551,
      0.05236068367958069,
      -0.08504141867160797,
      -0.004746386781334877,
      -0.07186809927225113,
      -0.01756426878273487,
      0.019172396510839462,
      -0.035079069435596466,
      0.060081787407398224,
      0.007416693028062582,
      -0.021277112886309624,
      0.039604902267456055,
      0.009968279860913754,
      -0.010608097538352013,
      -0.008791081607341766,
      -0.05506173148751259,
      0.055686041712760925,
      -0.03984048590064049,
      -0.023908525705337524,
      0.06651923060417175,
      -0.03084711544215679,
      0.06498068571090698,
      -0.05809620022773743,
      0.012728015892207623,
      -0.010553473606705666,
      -0.03056928515434265,
      0.021035784855484962,
      -0.05366363003849983,
      0.013476607389748096,
      -0.015194112434983253,
      0.0787535011768341,
      -0.011678697541356087,
      -0.015432332642376423,
      -0.004087058827280998,
      -0.041073743253946304,
      0.03356871008872986,
      0.03662427142262459,
      0.015528812073171139,
      0.004862750414758921,
      -0.017985807731747627,
      0.016034647822380066,
      0.024250300601124763,
      0.02397759258747101,
      0.0516260489821434,
      0.12429038435220718,
      0.06453908979892731,
      -0.02303345315158367,
      0.039784014225006104,
      -0.10100244730710983,
      0.08326608687639236,
      0.0224436204880476,
      -0.031936921179294586,
      0.02946750447154045,
      -0.08778849989175797,
      0.02213240973651409,
      -0.045417092740535736,
      0.03947168216109276,
      0.02599511295557022,
      0.009860247373580933,
      0.0540524497628212,
      0.09318920224905014,
      -0.015494724735617638,
      -0.029602013528347015,
      0.12937933206558228,
      -0.029449619352817535,
      0.043434470891952515,
      -0.006473986431956291,
      0.0084198834374547,
      0.01587393879890442,
      0.06772252172231674,
      -0.04296614229679108,
      -0.06146522983908653,
      0.08335700631141663,
      0.0253576822578907,
      -0.03238482028245926,
      -0.05230860412120819,
      0.004762414377182722,
      -0.01039558183401823,
      -0.0030476388055831194,
      0.10485271364450455,
      -0.09098582714796066,
      0.028672967106103897,
      -0.004630486946552992,
      -0.052573706954717636,
      -0.008457549847662449,
      0.04340912029147148,
      -0.07143273949623108,
      -0.022134283557534218,
      -0.027985846623778343,
      -0.03910035267472267,
      -0.005900041665881872,
      0.026103589683771133,
      0.02070579119026661,
      0.033589139580726624,
      0.03779315948486328,
      -0.020156726241111755,
      -0.014587569050490856,
      -0.09468276798725128,
      0.019821032881736755,
      0.00012773579510394484,
      -0.14047148823738098,
      0.017514511942863464,
      -0.0622679777443409,
      0.018366605043411255,
      -0.06377235800027847,
      0.02504011057317257,
      -0.059576503932476044,
      0.012178129516541958,
      0.03976297751069069,
      -0.002370171481743455,
      0.035932548344135284,
      0.07698944956064224,
      0.05799080431461334,
      -0.006974369287490845,
      -0.03307090327143669,
      -0.019441531971096992,
      0.02409753017127514,
      -0.018599076196551323,
      0.030875425785779953,
      0.0902327299118042,
      0.026716433465480804,
      0.04762154817581177,
      0.04637053981423378,
      -0.03567902743816376,
      -0.011089354753494263,
      -0.03511613607406616,
      0.011611527763307095,
      0.006112760864198208,
      -0.033802326768636703,
      0.0504995658993721,
      0.016997074708342552,
      0.05053839832544327,
      0.059314701706171036,
      0.06711487472057343,
      0.06455191969871521,
      0.06675110757350922,
      0.05508304014801979,
      -0.05679496005177498,
      -0.001868200721219182,
      -0.017254168167710304,
      -0.07863665372133255,
      0.019510680809617043,
      0.04971686005592346,
      -0.01321609877049923,
      0.03761054575443268,
      -0.05519096925854683,
      -0.05220832675695419,
      0.06999318301677704,
      0.06547679752111435,
      -0.0029457188211381435,
      -0.04415711760520935,
      0.018522514030337334,
      0.07451285421848297,
      0.08360452950000763,
      -0.06261271238327026,
      -0.024491919204592705,
      0.03519124910235405,
      -0.02180856466293335,
      -0.012621095404028893,
      -0.04810851067304611,
      -0.0023037977516651154,
      0.029784880578517914,
      -0.03814556822180748,
      0.07195193320512772,
      -0.03592809662222862,
      0.04212181642651558,
      0.027196142822504044,
      -0.11029700934886932,
      -0.01402532123029232,
      -0.047730810940265656,
      -0.009503393433988094,
      -0.03310703486204147,
      0.06469295918941498,
      0.03897279500961304,
      -0.02695302478969097,
      -0.0356840044260025,
      0.028289617970585823,
      -0.01216580905020237,
      -0.043027400970458984,
      -0.017682166770100594,
      0.0316678062081337,
      -0.03152967244386673,
      -0.02005118504166603,
      -0.019568711519241333,
      -0.05478793755173683,
      0.029144862666726112,
      0.007842033170163631,
      0.09380201995372772,
      0.02655087411403656,
      0.03066987171769142,
      -0.05058170109987259,
      -0.04248535633087158,
      0.02294483408331871,
      -0.0730656236410141,
      -0.055497750639915466,
      -0.015790218487381935,
      -0.0050467681139707565,
      -0.018289832398295403,
      -0.03493956848978996,
      0.06369514018297195,
      0.020594337955117226,
      -0.014242841862142086,
      -0.009623478166759014,
      0.008320915512740612,
      0.04755183309316635,
      -0.03722001612186432,
      0.02090218849480152,
      -0.003412041114643216,
      -0.09174929559230804,
      0.03526333346962929,
      -0.039416875690221786,
      -0.015559954568743706,
      0.06570450216531754,
      -0.057487852871418,
      -0.12752455472946167,
      0.0001697441766737029,
      0.006274436600506306,
      -0.02582659013569355,
      0.06301026046276093,
      0.01953074522316456,
      0.009480816312134266,
      0.10149070620536804,
      0.06175290420651436,
      -0.060778480023145676,
      0.0001609079190529883,
      -0.02600349858403206,
      0.03992374613881111,
      -0.07104609906673431,
      0.04801953583955765,
      0.011680410243570805,
      -0.006497242022305727,
      -0.03336795046925545,
      -0.045792173594236374,
      -0.01988254301249981,
      0.024915393441915512,
      -0.06382480263710022,
      0.04984131455421448,
      -0.006654685828834772,
      -0.052402596920728683,
      0.031066134572029114,
      0.06440550088882446,
      -0.06719878315925598,
      0.013114282861351967,
      -0.0145211610943079,
      -0.007293451577425003,
      0.020990271121263504,
      0.008906746283173561,
      0.09865334630012512,
      -0.016970397904515266,
      -0.005897280294448137,
      -0.01831955462694168,
      0.015632962808012962,
      0.004325344227254391,
      0.019325895234942436,
      0.009239292703568935,
      -0.0507003553211689,
      6.116463919170201e-05,
      -0.04235449060797691,
      0.03327334299683571,
      0.09542930871248245,
      -0.027798349037766457,
      -0.061741381883621216,
      -0.0011758365435525775,
      0.04994877800345421,
      -0.0672106221318245,
      0.023652683943510056,
      0.07861757278442383,
      -0.03642707318067551,
      -0.04544464126229286,
      0.0008624687907285988,
      -0.04582517594099045,
      0.025107769295573235,
      -0.046155497431755066,
      0.00406170729547739,
      -0.0992838516831398,
      0.057188209146261215,
      -0.0019799498841166496,
      -0.05007156729698181,
      -0.02270490862429142,
      0.03279006853699684,
      -0.06952867656946182,
      0.013770684599876404,
      0.06277413666248322,
      0.021299269050359726,
      -0.02088107354938984,
      -0.009972038678824902,
      -0.01093386858701706,
      0.011843441985547543,
      -0.017081409692764282,
      -0.07175841182470322,
      -0.1317145675420761,
      -0.009109641425311565,
      -0.06483639776706696,
      0.06148114427924156,
      -0.027921149507164955,
      0.0484195277094841,
      -0.002809761092066765,
      -0.012994756922125816,
      -0.03416823223233223,
      0.008653080090880394,
      0.007969032041728497,
      0.0020734670106321573,
      0.051358629018068314,
      -0.06280007213354111,
      -0.05107543244957924,
      0.0728425607085228,
      0.0057286047376692295,
      0.04445923492312431,
      0.0380878672003746,
      0.0029411357827484608,
      0.04381641745567322,
      0.015269256196916103,
      0.06622472405433655,
      0.04858798533678055,
      -0.044314611703157425,
      0.04020635783672333,
      0.023730870336294174,
      0.04692737013101578,
      -0.009733033366501331,
      0.05279924347996712,
      0.03395757079124451,
      -0.03449161350727081,
      -0.02574714832007885,
      -0.032982513308525085,
      0.04406541958451271,
      0.04456432908773422,
      -0.04629748687148094,
      0.005516579374670982,
      0.03630892187356949,
      0.013882345519959927,
      -0.05899178609251976,
      -0.006171762011945248,
      -0.01611107587814331,
      0.0014673308469355106,
      0.05230211466550827,
      0.026151064783334732,
      0.035306718200445175,
      0.11192052811384201,
      -0.06171262636780739,
      -0.02522488869726658,
      0.02281385473906994,
      0.06106972321867943,
      0.05754823610186577,
      -0.013588812202215195
    ],
    [
      0.03577329218387604,
      -0.05286553502082825,
      -0.08783642947673798,
      0.07938198000192642,
      -0.002067429944872856,
      0.0433465912938118,
      0.009545075707137585,
      0.06910645961761475,
      0.009707692079246044,
      0.015679890289902687,
      -0.04568071663379669,
      -0.04503289610147476,
      -0.005034937988966703,
      0.05353013426065445,
      -0.08254984766244888,
      0.005811537150293589,
      -0.00038426750688813627,
      -0.07513980567455292,
      0.002793235471472144,
      0.05403561517596245,
      0.033605847507715225,
      -0.000596763682551682,
      0.026753809303045273,
      -0.032254792749881744,
      0.05792267620563507,
      0.03643722087144852,
      0.07392924278974533,
      0.004063763190060854,
      -0.020097950473427773,
      -0.01144277025014162,
      -0.026889260858297348,
      -0.06692908704280853,
      -0.012942761182785034,
      0.013308354653418064,
      0.05218038707971573,
      0.034572020173072815,
      -0.09079016745090485,
      -0.010292071849107742,
      0.0025768771301954985,
      0.008767958730459213,
      0.016571227461099625,
      -0.044145017862319946,
      -0.03249584883451462,
      0.05167097970843315,
      -0.04139893874526024,
      0.022650230675935745,
      -0.05934528261423111,
      0.0842008888721466,
      -0.03711351379752159,
      -0.06441713869571686,
      -0.02501007728278637,
      0.027275729924440384,
      0.04929947480559349,
      0.053690105676651,
      0.04187260568141937,
      -0.041596293449401855,
      0.030892785638570786,
      -0.02151685766875744,
      -0.10087144374847412,
      0.022804019972682,
      0.02246800623834133,
      -0.05049103870987892,
      -0.015495712868869305,
      0.0487322099506855,
      0.03706931322813034,
      -0.02958688698709011,
      -0.015359909273684025,
      0.01143844984471798,
      -0.014995783567428589,
      0.02966948226094246,
      9.738859080243856e-05,
      -0.00040080214967019856,
      0.024532992392778397,
      -0.03160037845373154,
      -0.07177414745092392,
      -0.0170791894197464,
      0.07759159058332443,
      0.04650043323636055,
      -0.009564987383782864,
      0.0681053102016449,
      0.00014946071314625442,
      -0.13211311399936676,
      -0.07436508685350418,
      0.002464375225827098,
      0.04016166925430298,
      0.008171236142516136,
      0.005441936664283276,
      -0.00896979495882988,
      -0.05057830363512039,
      -0.08971523493528366,
      -0.005556944757699966,
      0.024203959852457047,
      -0.013186235912144184,
      0.030455391854047775,
      -0.06635729968547821,
      -0.013667003251612186,
      0.030630873516201973,
      0.0004798638983629644,
      -0.005281181540340185,
      0.025523342192173004,
      -0.04245232790708542,
      -0.059835486114025116,
      0.0012318941298872232,
      -0.04245186969637871,
      0.07657337933778763,
      -0.07484512031078339,
      -0.0920976996421814,
      -0.020279504358768463,
      0.009139684028923512,
      -0.016443558037281036,
      -0.01206941157579422,
      0.040037911385297775,
      0.07809334993362427,
      0.007711638696491718,
      -0.0421641543507576,
      0.03939681500196457,
      -0.05160520598292351,
      -0.06609847396612167,
      0.05232786014676094,
      0.10414126515388489,
      -0.03921888768672943,
      0.009749263525009155,
      0.005736845079809427,
      -0.0511772558093071,
      0.031557489186525345,
      0.06757238507270813,
      -0.03283747285604477,
      -0.0046474868431687355,
      -0.045003216713666916,
      -0.035474423319101334,
      0.053365450352430344,
      -0.02358517237007618,
      -0.019232507795095444,
      -0.04862026870250702,
      0.008069721981883049,
      -0.08513183146715164,
      -0.0029003669042140245,
      0.011359374970197678,
      -0.035377129912376404,
      0.009998282417654991,
      -0.0707874670624733,
      -0.04010464996099472,
      0.04189339652657509,
      -0.001713910256512463,
      -0.010556031949818134,
      0.07170101255178452,
      -0.08825603872537613,
      0.006146052852272987,
      0.01924758031964302,
      -0.009899752214550972,
      -0.08450553566217422,
      0.01581166870892048,
      -0.05393129959702492,
      -0.00264725461602211,
      0.02449127845466137,
      -0.009224268607795238,
      -0.02046658657491207,
      -0.029258107766509056,
      0.020969165489077568,
      0.02051841840147972,
      0.09101753681898117,
      -0.051775332540273666,
      0.012308239936828613,
      -0.09429492056369781,
      0.03592546284198761,
      -0.011750972829759121,
      -0.004250349942594767,
      0.01400409173220396,
      0.028587449342012405,
      0.04740024358034134,
      -0.00430277967825532,
      -0.07671669125556946,
      -0.006844937801361084,
      0.021230505779385567,
      -0.01565573737025261,
      -0.08450868725776672,
      -0.009175753220915794,
      -0.043787650763988495,
      0.04951687529683113,
      -0.11437179893255234,
      0.003144780406728387,
      0.018626010045409203,
      0.0051507046446204185,
      -0.07166662067174911,
      0.004439550451934338,
      0.0904172882437706,
      0.02266211062669754,
      0.02222251519560814,
      -0.004912381060421467,
      0.02680875174701214,
      0.034968093037605286,
      0.00708795664831996,
      -0.01637868955731392,
      0.0333588570356369,
      -0.015648918226361275,
      -0.016083596274256706,
      0.008895155042409897,
      0.051867417991161346,
      0.06479523330926895,
      0.015682529658079147,
      -0.08210425078868866,
      0.01572761870920658,
      -0.010969539172947407,
      -0.01169789582490921,
      -0.04824862629175186,
      -0.003983137663453817,
      0.031185589730739594,
      -0.02166394330561161,
      0.03751054406166077,
      -0.033417508006095886,
      -0.003408098593354225,
      0.02155238762497902,
      0.09619150310754776,
      -0.10256816446781158,
      0.09270668774843216,
      0.07404929399490356,
      -0.006992724724113941,
      -0.018856124952435493,
      -0.06645607203245163,
      -0.034489650279283524,
      -0.07491361349821091,
      -0.011284458450973034,
      0.05122159421443939,
      0.06480330973863602,
      0.09249450266361237,
      0.06592517346143723,
      -0.07263173162937164,
      -0.019121961668133736,
      0.08443354070186615,
      -0.005739944521337748,
      -0.011363472789525986,
      -0.03437400236725807,
      -0.0029705455526709557,
      0.06037639081478119,
      -0.02782597579061985,
      -0.026642804965376854,
      0.034227143973112106,
      0.022216133773326874,
      -0.03206886723637581,
      0.024188991636037827,
      -0.019845079630613327,
      -0.040070611983537674,
      -0.07536523044109344,
      0.07911987602710724,
      0.0755210742354393,
      -0.03497728705406189,
      0.04128258302807808,
      0.021135345101356506,
      0.047981128096580505,
      -0.006104114931076765,
      0.041106849908828735,
      -0.07880418747663498,
      0.0011175747495144606,
      0.01787632144987583,
      0.022817594930529594,
      0.041369106620550156,
      0.003686729585751891,
      -0.02949466183781624,
      -0.05013369396328926,
      0.04596053808927536,
      0.024763062596321106,
      -0.04408082365989685,
      -0.03943463787436485,
      -0.07274612039327621,
      -0.05690707266330719,
      -0.0635162964463234,
      0.04211331531405449,
      0.03989189490675926,
      -0.01038864441215992,
      -0.026823662221431732,
      0.00038292838144116104,
      -0.02959595061838627,
      -0.04911192134022713,
      0.02571001462638378,
      -0.024052871391177177,
      -0.036221448332071304,
      -0.040567316114902496,
      0.025563763454556465,
      0.01495904941111803,
      -0.053897395730018616,
      -0.03929479047656059,
      -0.0037063760682940483,
      0.04159814864397049,
      0.01165310014039278,
      0.045862581580877304,
      -0.05981229245662689,
      0.039593782275915146,
      -0.06070847809314728,
      0.053737759590148926,
      0.0923384502530098,
      -0.07787258177995682,
      -0.0008400226943194866,
      0.01078115776181221,
      -0.0014481304679065943,
      0.06478980928659439,
      -0.0008858831133693457,
      0.02532392367720604,
      -0.03361377492547035,
      -0.029826108366250992,
      -0.0021666879765689373,
      -0.05310285463929176,
      -0.04756207764148712,
      -0.05222613736987114,
      0.005946625955402851,
      -0.0005289680557325482,
      0.037806808948516846,
      0.06670080125331879,
      0.0286604892462492,
      0.034898508340120316,
      -0.019612809643149376,
      -0.03426719084382057,
      0.05405285209417343,
      0.015337251126766205,
      0.04426140338182449,
      0.15148325264453888,
      0.016858240589499474,
      -0.044732656329870224,
      0.018213078379631042,
      0.0009306109277531505,
      -0.02762635610997677,
      0.005833696573972702,
      -0.0023189058993011713,
      -0.003059406764805317,
      0.02154022827744484,
      -0.020101072266697884,
      -0.015273341909050941,
      -0.09621988981962204,
      -0.06166950240731239,
      0.03384704515337944,
      0.006326878909021616,
      0.0010388477239757776,
      -0.03822131082415581,
      0.0058777108788490295,
      0.051034875214099884,
      0.005698269233107567,
      -0.041744641959667206,
      0.006701406557112932,
      0.10727765411138535,
      0.020620260387659073,
      0.08296604454517365,
      0.008547300472855568,
      0.0072626275941729546,
      -0.08612576872110367,
      0.007795670535415411,
      -0.03157026320695877,
      0.006975488271564245,
      -0.01808074489235878,
      0.047393981367349625,
      0.05867314338684082,
      0.02411412075161934,
      -0.01726582832634449,
      0.01735859178006649,
      0.005736366845667362,
      -0.030268702656030655,
      -0.00530170276761055,
      0.029775843024253845,
      0.0189692173153162,
      -0.03467172011733055,
      0.04287439212203026,
      -0.008867859840393066,
      -0.025343269109725952,
      -0.06378807127475739,
      0.011288989335298538,
      -0.031211981549859047,
      0.00677511328831315,
      -0.01854708231985569,
      0.03357560187578201,
      -0.04550185799598694,
      -0.11898022890090942,
      -0.07006649672985077,
      -0.056714922189712524,
      0.0470687672495842,
      -0.01991240493953228,
      0.055714160203933716,
      0.021418439224362373,
      0.008151506073772907,
      0.024993741884827614,
      -0.007285079453140497,
      0.04260479658842087,
      0.009148388169705868,
      0.014798406511545181,
      0.06298206746578217,
      0.07235679030418396,
      -0.0008904371643438935,
      -0.06336915493011475,
      0.00898553803563118,
      -0.00822011474519968,
      0.0036101292353123426,
      -0.0923672467470169,
      0.09553468972444534,
      0.08214006572961807,
      -0.05027398094534874,
      0.01842380501329899,
      -0.0007019187323749065,
      0.017525004222989082,
      -0.03581102937459946,
      0.04173518344759941,
      -0.05378566309809685,
      0.01859244890511036,
      0.03564972057938576,
      -0.006502517499029636,
      0.08256814628839493,
      -0.04422346130013466,
      -0.06393492966890335,
      0.042454056441783905,
      -0.04357610270380974,
      -0.007380669005215168,
      -0.03261474147439003,
      0.0018239269265905023,
      0.045936308801174164,
      0.029873467981815338,
      -0.05398321524262428,
      -0.056300655007362366,
      -0.04339294508099556,
      -0.008650406263768673,
      -0.08871515095233917,
      0.010522158816456795,
      0.02937258593738079,
      0.0439000241458416,
      0.02920318767428398,
      -0.03267901763319969,
      -0.008939755149185658,
      -0.06340315192937851,
      -0.007007533684372902,
      -0.0009076631395146251,
      0.0050776684656739235,
      0.017759175971150398,
      0.03720954433083534,
      -0.03506729379296303,
      0.09076937288045883,
      0.03368081897497177,
      -0.089848592877388,
      0.011552050709724426,
      -0.0051214019767940044,
      0.03163788840174675,
      -0.04479915648698807,
      -0.05437314510345459,
      0.005238688085228205,
      -0.03955142945051193,
      -0.08061499893665314,
      0.06642455607652664,
      0.08414220064878464,
      -0.09015501290559769,
      -0.016474436968564987,
      0.0059640915133059025,
      0.09457820653915405,
      0.027019692584872246,
      0.021285006776452065,
      -0.02650686725974083,
      -0.1306740641593933,
      -0.01259002834558487,
      -0.05466094985604286,
      -0.011931966990232468,
      0.04314453899860382,
      -0.028309226036071777,
      -0.0005523357540369034,
      0.026740096509456635,
      -0.004429841414093971,
      0.04159356281161308,
      0.010931011289358139,
      -0.008729696273803711,
      -0.09056242555379868,
      0.062072303146123886,
      0.01569274440407753,
      -0.07326170057058334,
      -0.039167147129774094,
      -0.03551601991057396,
      0.000921497296076268,
      0.00029720240854658186,
      0.02421213500201702,
      -0.03809458389878273,
      -0.02584601752460003,
      0.028604688122868538,
      0.036206696182489395,
      0.005636162590235472,
      -0.019920535385608673,
      0.02326659858226776,
      -0.008921358734369278,
      -0.07753808051347733,
      -0.02135014720261097,
      -0.08546556532382965,
      -0.010174657218158245,
      -0.05715838074684143,
      -0.06712136417627335,
      -0.052879903465509415,
      -0.0446191281080246,
      0.047089993953704834,
      -0.04035758599638939,
      -0.06138521432876587,
      0.00815713033080101,
      -0.03712896630167961,
      -0.03968067839741707,
      0.006456418894231319,
      -0.08086486160755157,
      0.02321263775229454,
      0.049256905913352966,
      0.05108813941478729,
      -0.027865951880812645,
      -0.05832419544458389,
      -0.06728545576334,
      0.015373348258435726,
      0.006026020273566246,
      -0.003929125145077705
    ],
    [
      -0.031797703355550766,
      0.04706856980919838,
      0.0354515016078949,
      -0.035225868225097656,
      -0.0010695504024624825,
      -0.01714877225458622,
      0.022434178739786148,
      0.027980444952845573,
      0.034845296293497086,
      0.04279148578643799,
      -0.023358555510640144,
      -0.004650443326681852,
      0.05805499106645584,
      0.0691036581993103,
      -0.04846595600247383,
      -0.04890602082014084,
      0.011950510554015636,
      -0.09640911966562271,
      0.028206339105963707,
      -0.02957797795534134,
      -0.0006952264229767025,
      0.013798040337860584,
      -0.02188497968018055,
      -0.023936685174703598,
      0.005327816121280193,
      0.0030824088025838137,
      0.006108188070356846,
      -0.02227579988539219,
      -0.0771636888384819,
      0.06743824481964111,
      -0.054876651614904404,
      -0.0019085551612079144,
      -0.040995605289936066,
      0.04276266321539879,
      -0.0028489436954259872,
      -0.0462743379175663,
      -0.026867542415857315,
      0.006981667596846819,
      0.039168424904346466,
      0.06307915598154068,
      0.02610723301768303,
      -0.018067816272377968,
      -0.02220194973051548,
      -0.04274380952119827,
      0.016940472647547722,
      0.05144410952925682,
      7.186628499766812e-05,
      -0.015910577028989792,
      -0.006053268443793058,
      0.023607442155480385,
      -0.0011084838770329952,
      0.08796096593141556,
      -0.03816116601228714,
      -0.022489268332719803,
      -0.027456557378172874,
      0.0035967365838587284,
      0.02319377101957798,
      -0.1038038581609726,
      0.003992636222392321,
      -0.007194914855062962,
      -0.003876008791849017,
      0.036443136632442474,
      -0.05661194771528244,
      0.005821333732455969,
      0.04594344273209572,
      -0.022025935351848602,
      0.014902087859809399,
      0.07880829274654388,
      0.021546458825469017,
      -0.06395377218723297,
      0.003651042003184557,
      0.0034411544911563396,
      -0.03846316039562225,
      0.01556356716901064,
      -0.06866775453090668,
      -0.010165662504732609,
      0.014430140145123005,
      0.09823638200759888,
      0.07237079739570618,
      -0.03201746195554733,
      0.031921036541461945,
      -0.026049787178635597,
      0.08374485373497009,
      -0.07994712889194489,
      -0.030537882819771767,
      -0.01775449700653553,
      0.0603640191257,
      -0.024655044078826904,
      -0.042649444192647934,
      0.009235752746462822,
      0.01227073185145855,
      -0.03669994696974754,
      -0.014487316831946373,
      0.07579465210437775,
      -0.010489920154213905,
      0.01123624388128519,
      -0.04323036968708038,
      -0.01766268163919449,
      -0.1318628489971161,
      0.038054607808589935,
      -0.0035996425431221724,
      -0.01383484248071909,
      0.05650587007403374,
      -0.007202495820820332,
      0.012290965765714645,
      0.010685669258236885,
      -0.0007273407536558807,
      0.037605807185173035,
      -0.0442192517220974,
      -0.06104391813278198,
      0.026509052142500877,
      -0.03327348083257675,
      0.014742255210876465,
      0.07154395431280136,
      -0.09302813559770584,
      0.01981685310602188,
      -0.02265578880906105,
      0.02538718469440937,
      -0.04115757718682289,
      -0.049181412905454636,
      -0.06799385696649551,
      -0.03741257265210152,
      0.03618109971284866,
      -0.026805004104971886,
      0.05474551394581795,
      0.010935154743492603,
      -0.02531149797141552,
      -0.04808510094881058,
      0.0024256424512714148,
      -0.03095245361328125,
      0.09369849413633347,
      0.061566296964883804,
      -0.05646184831857681,
      -0.03612593561410904,
      0.07971486449241638,
      0.007453160360455513,
      0.030829275026917458,
      0.017856061458587646,
      -0.039138633757829666,
      -0.01570245996117592,
      -0.049909558147192,
      0.08821633458137512,
      0.013633167371153831,
      0.017424844205379486,
      0.03944152221083641,
      -0.009550229646265507,
      -0.07077872008085251,
      -0.049530308693647385,
      -0.045651696622371674,
      0.08425529301166534,
      -0.05337722972035408,
      -0.031758666038513184,
      0.023362064734101295,
      -0.03397521749138832,
      0.10549561679363251,
      -0.040599457919597626,
      -0.04689526557922363,
      0.03863268718123436,
      0.027470653876662254,
      -0.020023686811327934,
      -0.009322824887931347,
      -0.017388131469488144,
      0.09738531708717346,
      0.0021584152709692717,
      0.031487755477428436,
      0.026487987488508224,
      -0.0074810609221458435,
      0.012308124452829361,
      0.002963808597996831,
      0.060456350445747375,
      -0.0008382544037885964,
      -0.062216319143772125,
      0.004914902150630951,
      -0.010817131027579308,
      -0.041432518512010574,
      0.023057326674461365,
      0.00982673279941082,
      2.0944682546542026e-05,
      -0.01647464744746685,
      -0.07451526820659637,
      -0.13103921711444855,
      -0.06814698129892349,
      -0.04141248017549515,
      -0.005439248401671648,
      0.06456656754016876,
      -0.08288789540529251,
      0.01213891338557005,
      -0.030015936121344566,
      0.029953042045235634,
      -0.010738474316895008,
      0.001570646301843226,
      -0.1635609120130539,
      -0.02930099330842495,
      -0.032889869064092636,
      -0.02737097628414631,
      0.058396972715854645,
      0.004677082411944866,
      0.06677493453025818,
      -0.06253881752490997,
      0.034456364810466766,
      0.038197703659534454,
      0.024738121777772903,
      0.02707337960600853,
      0.02168918028473854,
      0.06090420112013817,
      0.024054063484072685,
      0.09204045683145523,
      -0.01402214914560318,
      0.09655158966779709,
      -0.015461773611605167,
      -0.037164513021707535,
      -0.04171575978398323,
      0.004252019338309765,
      0.009727532044053078,
      0.050525642931461334,
      -0.08987930417060852,
      -0.01672697439789772,
      0.04594091698527336,
      -0.005344435106962919,
      0.008170213550329208,
      -0.05346810072660446,
      0.09167718142271042,
      0.018477870151400566,
      -0.05101601406931877,
      0.005255107767879963,
      0.018770046532154083,
      -0.06273676455020905,
      -0.0046252417378127575,
      -0.01684766449034214,
      -0.009094296023249626,
      0.02748417668044567,
      -0.09511661529541016,
      -0.024159735068678856,
      0.008296533487737179,
      -0.03238491714000702,
      -0.037201736122369766,
      -0.05689330771565437,
      0.014556221663951874,
      0.029428116977214813,
      -0.0011669767554849386,
      0.05427983030676842,
      -0.01543763279914856,
      -0.03061761148273945,
      -0.021715417504310608,
      0.03854305297136307,
      -0.013714181259274483,
      0.011164669878780842,
      0.0009928024373948574,
      0.023776128888130188,
      0.023956671357154846,
      -0.07570898532867432,
      0.016956884413957596,
      -0.015011231414973736,
      -0.004955659620463848,
      -0.013445925898849964,
      0.037756238132715225,
      0.04502499848604202,
      0.012454715557396412,
      -0.01014607772231102,
      -0.10555960237979889,
      -0.0017659040167927742,
      -0.06561628729104996,
      0.046417005360126495,
      -0.012046909891068935,
      -0.04661084711551666,
      0.012500056996941566,
      -0.022666843608021736,
      0.029429128393530846,
      -0.030554918572306633,
      -0.02894938364624977,
      0.023883283138275146,
      -0.03200745955109596,
      -0.005996244493871927,
      0.007045784033834934,
      0.01894592121243477,
      0.04765304550528526,
      0.04349163547158241,
      0.049127303063869476,
      0.015459018759429455,
      0.008018625900149345,
      -0.05719074606895447,
      -0.05252477154135704,
      -0.043396152555942535,
      -0.03202394023537636,
      -0.0603773333132267,
      -0.02007872425019741,
      -0.027639904990792274,
      0.02879270911216736,
      0.06920932978391647,
      -0.03861476480960846,
      0.014252039603888988,
      -0.022535432130098343,
      0.013091509230434895,
      -0.08076240122318268,
      0.036463696509599686,
      -0.12561865150928497,
      -0.022334547713398933,
      -0.048989761620759964,
      0.014143392443656921,
      0.04152759537100792,
      -0.010123747400939465,
      -0.017717041075229645,
      -0.07584483176469803,
      -0.0052111526019871235,
      0.017749609425663948,
      -0.038625191897153854,
      -0.007089415565133095,
      0.01978903077542782,
      0.03858458250761032,
      -0.019290633499622345,
      0.07118206471204758,
      -0.02268088422715664,
      -0.00325165968388319,
      0.07232330739498138,
      -0.03435113653540611,
      0.0019951940048485994,
      0.09002412855625153,
      -0.010233819484710693,
      -0.07570318877696991,
      -0.06604105234146118,
      -0.03171392157673836,
      -0.07163692265748978,
      -0.02083229459822178,
      -0.029832253232598305,
      0.005308505147695541,
      -0.05247762054204941,
      -0.02198154479265213,
      0.035163335502147675,
      -0.00714643020182848,
      -0.012542449869215488,
      0.020655646920204163,
      -0.024950308725237846,
      0.06192917376756668,
      0.06958936899900436,
      0.06006383150815964,
      0.00014131766511127353,
      -0.06396012008190155,
      0.025467349216341972,
      -0.006258040200918913,
      -0.052345819771289825,
      0.008234751410782337,
      0.047208525240421295,
      0.034574881196022034,
      -0.013461672700941563,
      -0.03013148531317711,
      -0.014054549857974052,
      0.04819250851869583,
      0.03334147110581398,
      0.006105399690568447,
      0.030264804139733315,
      0.03922015056014061,
      0.04051333665847778,
      -0.04411209002137184,
      0.03567206859588623,
      0.047163866460323334,
      0.01358042936772108,
      -0.052137743681669235,
      0.04123126342892647,
      -0.03698224574327469,
      0.020024243742227554,
      0.05864495784044266,
      0.11264549940824509,
      -0.008013107813894749,
      0.053615763783454895,
      -0.033050134778022766,
      -0.02225334942340851,
      -0.010696555487811565,
      -0.009972002357244492,
      0.014543791301548481,
      -0.060126353055238724,
      -0.03128658980131149,
      -0.035451579838991165,
      -0.0466434471309185,
      -0.005827982444316149,
      -0.046380698680877686,
      0.04761359468102455,
      -0.00639595789834857,
      0.02961547300219536,
      0.01580709032714367,
      -0.025328021496534348,
      -0.026584632694721222,
      0.053117524832487106,
      0.022332224994897842,
      -0.013823461718857288,
      -0.010621858760714531,
      -0.00711151584982872,
      -0.08973608165979385,
      -0.024727920070290565,
      -0.06736260652542114,
      0.03631538152694702,
      -0.04903685301542282,
      -0.03330707922577858,
      -0.0336495004594326,
      -0.020839372649788857,
      -0.017596596851944923,
      0.06170187145471573,
      -0.012548954226076603,
      0.024674348533153534,
      -0.010763726197183132,
      0.04809170588850975,
      0.0031905481591820717,
      0.003956750500947237,
      0.051799848675727844,
      -0.003294357331469655,
      0.08406326174736023,
      -0.020313644781708717,
      -0.008408810943365097,
      0.0035443706437945366,
      0.030008647590875626,
      0.050318289548158646,
      0.048822492361068726,
      -0.036628663539886475,
      -0.04965382069349289,
      -0.04371795803308487,
      0.08986321091651917,
      -0.023799341171979904,
      0.09558288753032684,
      -0.05429598316550255,
      0.020444873720407486,
      0.009169531986117363,
      0.06535866856575012,
      0.06669247150421143,
      -0.028045019134879112,
      -0.024452855810523033,
      0.004051328636705875,
      0.01763279177248478,
      -0.0165438000112772,
      0.019018877297639847,
      0.022525938227772713,
      -0.03949272260069847,
      -0.09564732760190964,
      0.011349518783390522,
      0.010427405126392841,
      0.003791811177507043,
      -0.014107593335211277,
      -0.008185561746358871,
      0.03622343763709068,
      0.0561785027384758,
      -0.021327858790755272,
      -0.02674001455307007,
      0.045662231743335724,
      0.036923930048942566,
      0.022286921739578247,
      -0.010829951614141464,
      0.022602606564760208,
      0.037451885640621185,
      -0.05992015823721886,
      0.051400575786828995,
      0.05795293673872948,
      -0.025092830881476402,
      0.0732201337814331,
      0.023004679009318352,
      0.03100007027387619,
      -0.012069749645888805,
      -0.022952869534492493,
      -0.023724619299173355,
      0.010444928891956806,
      -0.01026163436472416,
      -0.0399247370660305,
      -0.015769733116030693,
      0.05872571840882301,
      -0.02836884744465351,
      0.0802072286605835,
      0.026156261563301086,
      -0.0246027372777462,
      0.028223102912306786,
      0.020861580967903137,
      -0.011261691339313984,
      -0.006408066023141146,
      -0.11425644904375076,
      0.0366673469543457,
      0.14861664175987244,
      0.024042895063757896,
      0.023493032902479172,
      0.028787750750780106,
      0.021922150626778603,
      0.04163361340761185,
      -0.057293035089969635,
      0.008782768622040749,
      0.04769138991832733,
      0.04154958575963974,
      0.04703981429338455,
      0.008850879967212677,
      -0.02857155166566372,
      -0.05340145528316498,
      -0.04500146582722664,
      0.02940519154071808,
      0.11462241411209106,
      0.06692588329315186,
      -0.024429555982351303,
      0.10352720320224762,
      -0.044252533465623856,
      -0.041620027273893356,
      0.002879680600017309,
      0.05322779342532158,
      0.016865089535713196,
      0.01597682386636734,
      0.13655902445316315,
      -0.05951513350009918,
      0.06253442913293839,
      0.0011672223918139935,
      0.08451198786497116,
      -0.011558983474969864
    ],
    [
      -0.03150835260748863,
      0.013814673759043217,
      0.028954224660992622,
      0.06062909960746765,
      0.09835892915725708,
      0.004041065461933613,
      0.0321199856698513,
      -0.010159746743738651,
      0.023020559921860695,
      0.05092797800898552,
      0.013744593597948551,
      0.019215241074562073,
      0.09352172911167145,
      0.038154080510139465,
      -0.06021800637245178,
      -0.01467576902359724,
      0.050697632133960724,
      -0.05851037800312042,
      0.012952710501849651,
      0.005496813450008631,
      -0.05174741521477699,
      0.037378519773483276,
      -0.04590267688035965,
      0.01870831847190857,
      -0.03313438221812248,
      -0.06563539057970047,
      -0.032573312520980835,
      0.02165483683347702,
      0.0018323271069675684,
      0.04670895263552666,
      -0.025702139362692833,
      0.07264775782823563,
      0.03307231143116951,
      -0.040846869349479675,
      0.011074969545006752,
      -0.06006626412272453,
      0.0692719966173172,
      -0.01048498135060072,
      0.026749273762106895,
      -0.03414561599493027,
      -0.004641329869627953,
      -0.10927954316139221,
      -0.07740749418735504,
      0.05825187265872955,
      0.035168521106243134,
      0.02638702094554901,
      0.013472471386194229,
      0.021796362474560738,
      -0.09673898667097092,
      -0.026541078463196754,
      -0.08137135952711105,
      0.018755685538053513,
      0.03554687649011612,
      0.04680813476443291,
      -0.05583572760224342,
      -0.03514824062585831,
      -0.022122804075479507,
      0.03241749480366707,
      0.11286500096321106,
      0.00010702272993512452,
      -0.02980327419936657,
      0.05023961141705513,
      0.023799950256943703,
      -0.02189536951482296,
      -0.032949358224868774,
      0.01883787102997303,
      0.09685960412025452,
      0.03810471296310425,
      0.051056329160928726,
      0.04277663305401802,
      -0.020480744540691376,
      -0.0129396952688694,
      0.015078860335052013,
      0.03571678698062897,
      0.01169256865978241,
      -0.0629456490278244,
      -0.02052595466375351,
      -0.00526138860732317,
      -0.05774443969130516,
      -0.04160977900028229,
      -0.009729856625199318,
      -0.004602646920830011,
      -0.056497588753700256,
      0.02144663967192173,
      -0.004056781530380249,
      0.014163671992719173,
      0.01066376082599163,
      0.021412285044789314,
      -0.08629544079303741,
      0.05961274728178978,
      0.001781870611011982,
      -0.01489547360688448,
      -0.03685852512717247,
      -0.006251498591154814,
      0.05218815058469772,
      0.038392990827560425,
      0.045636724680662155,
      0.01003856211900711,
      -0.07671729475259781,
      -0.00023852458980400115,
      -0.0394623689353466,
      -0.019397076219320297,
      -0.05805087462067604,
      -0.0046706730499863625,
      0.04645869508385658,
      -0.06861492246389389,
      -0.0157850943505764,
      0.050993241369724274,
      -0.006237007677555084,
      -0.027027074247598648,
      -0.006084607448428869,
      0.005661502480506897,
      0.02686959318816662,
      0.0004881283384747803,
      -0.06772533059120178,
      -0.0008584187016822398,
      -0.06349287182092667,
      -0.03050089441239834,
      0.040604833513498306,
      -0.004128354601562023,
      -0.04953968524932861,
      0.005880963522940874,
      0.09689979255199432,
      -0.012261180207133293,
      -0.03234907239675522,
      0.02974068932235241,
      0.020770126953721046,
      0.05347539111971855,
      -0.003015033435076475,
      0.05407150089740753,
      0.033511362969875336,
      -0.029335929080843925,
      -0.06248658522963524,
      -0.013051359914243221,
      0.0493684820830822,
      0.016704903915524483,
      0.04679575935006142,
      -0.024996168911457062,
      -0.01103726401925087,
      -0.021839216351509094,
      0.07090172916650772,
      0.0646229162812233,
      0.1013941764831543,
      -0.020324112847447395,
      0.031225955113768578,
      -0.04121696576476097,
      -0.009560491889715195,
      -0.04243481531739235,
      -0.05465454235672951,
      -0.02361235022544861,
      -0.060032475739717484,
      -0.031380634754896164,
      -0.03917833790183067,
      0.007500065956264734,
      -0.039821065962314606,
      -0.023445656523108482,
      0.0041354806162416935,
      0.03857135772705078,
      0.004435642622411251,
      0.04020407795906067,
      0.0084486473351717,
      -0.005052580963820219,
      0.04677021503448486,
      0.03530503064393997,
      0.057401031255722046,
      -0.005183291155844927,
      0.029180942103266716,
      0.017220763489603996,
      -0.0502309687435627,
      0.05073811486363411,
      0.011523380875587463,
      -0.05507621914148331,
      -0.0402698889374733,
      0.00016754573152866215,
      -0.03245009481906891,
      -0.05736309289932251,
      0.01565464586019516,
      -0.014880141243338585,
      -0.06979116052389145,
      -0.04894302412867546,
      -0.0002802207600325346,
      0.03503343462944031,
      0.021457955241203308,
      0.08047224581241608,
      -0.017171939834952354,
      0.03671683743596077,
      0.01033246610313654,
      -0.07058548927307129,
      0.04724059998989105,
      -0.05547342076897621,
      -0.027591386809945107,
      -0.034998226910829544,
      0.060008157044649124,
      -0.007756273727864027,
      0.08896192908287048,
      0.031992390751838684,
      -0.032629627734422684,
      0.0014964655274525285,
      0.02804279327392578,
      0.03317311778664589,
      0.017176996916532516,
      -0.021873703226447105,
      0.06054701656103134,
      -0.07854931801557541,
      -0.005883841775357723,
      -0.006241277325898409,
      0.01912708580493927,
      -0.014905745163559914,
      -0.07950060069561005,
      -0.026248468086123466,
      0.06152418255805969,
      -0.0038805799558758736,
      0.02428640052676201,
      -0.018915623426437378,
      0.009846010245382786,
      0.0065603312104940414,
      -0.00028758079861290753,
      0.011366131715476513,
      0.06467092037200928,
      -0.08743751049041748,
      0.04360967501997948,
      0.057206399738788605,
      -0.03403135761618614,
      -0.03988384082913399,
      -0.00791835691779852,
      0.010610316880047321,
      0.007346795406192541,
      0.05271287262439728,
      0.010208765976130962,
      -0.03628508746623993,
      0.01852775178849697,
      -0.01005113497376442,
      -0.01014705840498209,
      -0.01889840140938759,
      -0.004448425490409136,
      0.041431672871112823,
      -0.030543550848960876,
      -0.08187782019376755,
      -0.02726154588162899,
      -0.05217592045664787,
      0.0021068674977868795,
      0.011179544031620026,
      0.0042372955940663815,
      -0.01628895103931427,
      -0.07049638032913208,
      -0.016958821564912796,
      0.029155025258660316,
      0.07568877190351486,
      -0.01035067718476057,
      -0.007688445970416069,
      0.012664035893976688,
      -0.020883893594145775,
      0.045720554888248444,
      -0.03957440331578255,
      0.05182596296072006,
      -0.04183948412537575,
      -0.00871479045599699,
      -0.0046287109144032,
      0.03830748423933983,
      -0.0021844354923814535,
      -0.00022443906345870346,
      -0.03723739832639694,
      -0.04933006316423416,
      0.003154725767672062,
      -0.02894691564142704,
      0.058111414313316345,
      0.010943569242954254,
      0.01746363937854767,
      -0.06280931830406189,
      -0.015990324318408966,
      0.082648865878582,
      -0.058333273977041245,
      0.07117875665426254,
      -0.009706948883831501,
      0.0275549478828907,
      -0.020087100565433502,
      0.04828786849975586,
      -0.03468276560306549,
      0.06537551432847977,
      0.04617132991552353,
      -0.04174257814884186,
      -0.035212431102991104,
      -0.021882925182580948,
      -0.061016764491796494,
      0.021150385960936546,
      0.025097601115703583,
      0.0008163301390595734,
      -0.04314778372645378,
      -0.003937781322747469,
      -0.04897360876202583,
      0.01320126187056303,
      0.07633268088102341,
      0.08439867943525314,
      0.024558423087000847,
      -0.028035812079906464,
      -0.04664912074804306,
      0.029083140194416046,
      -0.006757377181202173,
      -0.04319749027490616,
      0.029329432174563408,
      -0.009016729891300201,
      0.0004951266455464065,
      -0.03234303742647171,
      -0.011996565386652946,
      0.08559078723192215,
      -0.01822744309902191,
      0.0706021711230278,
      0.01658960059285164,
      0.011744724586606026,
      0.006017307285219431,
      -0.006356013007462025,
      0.012717426754534245,
      0.0930999144911766,
      0.01698232814669609,
      0.04144418612122536,
      -0.047377631068229675,
      0.030891215428709984,
      0.014603974297642708,
      0.02718849666416645,
      -0.048651330173015594,
      0.052886709570884705,
      -0.007173978257924318,
      -0.02748396247625351,
      -0.045372769236564636,
      -0.03888674080371857,
      0.0552375465631485,
      0.008549251593649387,
      0.013232467696070671,
      -0.01809115894138813,
      -0.07051724195480347,
      0.10258416086435318,
      0.03753194585442543,
      -0.05241735652089119,
      -0.006593937054276466,
      -0.00022632318723481148,
      -0.043551549315452576,
      0.04422983527183533,
      -0.007601331453770399,
      0.019740598276257515,
      -0.014396121725440025,
      0.005907885730266571,
      0.028350936248898506,
      0.01378163043409586,
      0.02171269804239273,
      -0.06303343921899796,
      -0.038612790405750275,
      -0.008833576925098896,
      0.02406107448041439,
      0.012493166141211987,
      -0.009804739616811275,
      -0.09529731422662735,
      0.017555594444274902,
      0.059969548135995865,
      0.04821913689374924,
      -0.00047006283421069384,
      -0.010377785190939903,
      -0.05238717421889305,
      0.03351479023694992,
      0.05698602646589279,
      -0.011174149811267853,
      0.001097165048122406,
      0.10585610568523407,
      -0.05923120304942131,
      -0.03569066524505615,
      0.10900189727544785,
      0.04402029141783714,
      -0.07908613234758377,
      0.01724858768284321,
      -0.01047473680227995,
      -0.04628843441605568,
      -0.043300993740558624,
      0.09407726675271988,
      0.04413865879178047,
      -0.10504180938005447,
      0.03343259170651436,
      0.03757917508482933,
      -0.008555689826607704,
      0.050908662378787994,
      -0.019124360755085945,
      0.02055126428604126,
      -0.031618669629096985,
      0.0057006399147212505,
      0.0418415330350399,
      0.014784853905439377,
      -0.03532325476408005,
      0.06764520704746246,
      0.01923207938671112,
      0.006621436681598425,
      -0.04678310453891754,
      0.014992066659033298,
      -0.06558002531528473,
      0.033859893679618835,
      0.10638880729675293,
      -0.02381332404911518,
      0.016177892684936523,
      0.030053555965423584,
      -0.029632912948727608,
      -0.020407864823937416,
      -0.030625078827142715,
      -0.01354831550270319,
      0.04080798849463463,
      -0.025489719584584236,
      0.03396293520927429,
      0.03350906819105148,
      -0.0013490229612216353,
      -0.033866655081510544,
      0.04056772217154503,
      -0.011153427883982658,
      0.011590590700507164,
      0.06109125539660454,
      0.010331859812140465,
      -0.04602990671992302,
      -0.02977433241903782,
      0.00369437993504107,
      0.030176563188433647,
      -0.03971593454480171,
      0.014739399775862694,
      -0.030641701072454453,
      0.03726508095860481,
      0.006574335042387247,
      0.07713880389928818,
      0.005191031377762556,
      0.01279977522790432,
      -0.03284632787108421,
      0.027625899761915207,
      -0.001049423823133111,
      0.02567601017653942,
      0.029946070164442062,
      -0.08353514969348907,
      -0.03771212324500084,
      0.03945135325193405,
      -0.041768237948417664,
      -0.013799653388559818,
      0.015701452270150185,
      0.01597929187119007,
      -0.06684530526399612,
      -0.020029515027999878,
      0.013916675932705402,
      0.05174408480525017,
      -0.03966110944747925,
      -0.061317965388298035,
      -0.05270828679203987,
      -0.013360516168177128,
      0.01071351021528244,
      0.016627822071313858,
      -0.009177884086966515,
      -0.05395292118191719,
      0.05167026445269585,
      0.018483716994524002,
      -0.06589607149362564,
      -0.014214323833584785,
      0.041126225143671036,
      0.07700229436159134,
      -0.031111670657992363,
      -0.024467557668685913,
      -0.008177910931408405,
      -0.027039799839258194,
      0.026769336313009262,
      0.031173869967460632,
      0.03843848407268524,
      0.04154924675822258,
      0.054711464792490005,
      0.0035757669247686863,
      0.06836708635091782,
      -0.04112343117594719,
      0.07033512741327286,
      -0.039408840239048004,
      0.025205576792359352,
      0.03604255989193916,
      -0.09451131522655487,
      -0.047367289662361145,
      -0.05462251231074333,
      -0.01340937614440918,
      0.08385016024112701,
      -0.09264924377202988,
      0.020978951826691628,
      0.0569569356739521,
      -0.05633563920855522,
      0.03562343120574951,
      0.02036319114267826,
      -0.06887946277856827,
      0.02792363427579403,
      -0.0043498496524989605,
      -0.04066387936472893,
      0.10011366009712219,
      0.07272615283727646,
      0.02245631255209446,
      -0.008270629681646824,
      -0.004345957655459642,
      -0.04118199646472931,
      0.03716450184583664,
      0.06077151373028755,
      0.06776830554008484,
      0.04280001297593117,
      0.04891352728009224,
      0.03144943341612816,
      0.018789522349834442,
      -0.10299354791641235,
      -0.04816215857863426,
      -0.0684012770652771,
      0.04027969390153885,
      0.015177804045379162,
      0.019646162167191505
    ],
    [
      0.05389677733182907,
      -0.001031216699630022,
      0.05463472753763199,
      0.06998136639595032,
      -0.04312102124094963,
      -0.005495449528098106,
      -0.004568476229906082,
      0.03972657024860382,
      -0.0462070107460022,
      0.026233986020088196,
      -0.01610659621655941,
      0.05062824860215187,
      0.031423285603523254,
      0.03504519537091255,
      -0.05152645707130432,
      -0.020769348368048668,
      0.026629064232110977,
      -0.0021395396906882524,
      -0.06404191255569458,
      -0.003735640784725547,
      0.011714858934283257,
      0.012236840091645718,
      0.027769237756729126,
      0.043867077678442,
      -0.06572915613651276,
      0.08601365983486176,
      0.06887584924697876,
      -0.012819470837712288,
      -0.06248921528458595,
      -0.020081644877791405,
      0.033851638436317444,
      0.05078858882188797,
      0.040319185703992844,
      0.030346255749464035,
      0.009135139174759388,
      -0.054388828575611115,
      -0.11149251461029053,
      0.018800416961312294,
      -0.004179738461971283,
      0.008897246792912483,
      0.016845328733325005,
      -0.011989866383373737,
      0.027263574302196503,
      0.006205392070114613,
      -0.08460170030593872,
      -0.03205570578575134,
      0.005412484519183636,
      -0.011361521668732166,
      -0.04906128719449043,
      0.006076749414205551,
      0.033056240528821945,
      -0.0308931116014719,
      -0.0253900196403265,
      -0.006373715121299028,
      0.022046109661459923,
      -0.08518680930137634,
      -0.07668822258710861,
      0.02324772998690605,
      -0.0049199811182916164,
      -0.06871698051691055,
      0.03321627154946327,
      0.00568037573248148,
      -0.049706198275089264,
      0.03827333822846413,
      0.004578261636197567,
      0.011267642490565777,
      0.06754440069198608,
      -0.016781248152256012,
      0.022162625566124916,
      0.10702530294656754,
      0.052450668066740036,
      0.01987433433532715,
      0.021158361807465553,
      0.05177978798747063,
      -0.050827186554670334,
      -0.01944500207901001,
      0.006337030790746212,
      0.03204744681715965,
      0.08935345709323883,
      0.03190264478325844,
      0.054361727088689804,
      0.09600569307804108,
      -0.03369073569774628,
      0.006330237723886967,
      0.006062429863959551,
      0.04016491770744324,
      0.03364873304963112,
      0.08339213579893112,
      -0.022284995764493942,
      0.009013749659061432,
      0.04331624135375023,
      -0.042328909039497375,
      -0.07879818975925446,
      -0.0630127564072609,
      -0.026131294667720795,
      -0.049664922058582306,
      0.0018137405859306455,
      0.008815226145088673,
      0.0017239084700122476,
      -0.036509592086076736,
      -0.06184239685535431,
      -0.015691140666604042,
      -0.06495707482099533,
      0.07876837998628616,
      0.037410251796245575,
      -0.07715119421482086,
      -0.0038496851921081543,
      -0.012674023397266865,
      0.025231534615159035,
      0.025992128998041153,
      -0.015051792375743389,
      0.03056294471025467,
      0.04493198171257973,
      -0.012108531780540943,
      0.05229383334517479,
      -0.007494194433093071,
      0.01996881328523159,
      -0.08078791946172714,
      -0.01902153715491295,
      0.00352700287476182,
      0.04257789999246597,
      0.037077102810144424,
      -0.07199620455503464,
      0.042239416390657425,
      -0.004398963879793882,
      -0.0376138836145401,
      0.05874943733215332,
      -0.11506084352731705,
      -0.022618141025304794,
      0.05422581359744072,
      -0.003530892776325345,
      0.0004387098306324333,
      0.02512497641146183,
      -0.059707846492528915,
      0.1353750079870224,
      0.05778989940881729,
      -0.021275753155350685,
      0.03721483796834946,
      0.0009840518468990922,
      -0.005183316767215729,
      0.04041604697704315,
      0.0504566989839077,
      -0.036477744579315186,
      0.03123779408633709,
      0.03098323568701744,
      -0.0005988504271954298,
      0.02622995153069496,
      -0.030462363734841347,
      -0.02882019430398941,
      -0.028683295473456383,
      -0.07873097062110901,
      0.006121765822172165,
      0.0008638343424536288,
      0.012164060957729816,
      0.015227609314024448,
      -0.04115740954875946,
      0.004158614203333855,
      0.028992047533392906,
      0.016468118876218796,
      0.10929731279611588,
      -0.04337344318628311,
      -0.05796266719698906,
      0.014954869635403156,
      0.007191505748778582,
      -0.0180769395083189,
      -0.038088005036115646,
      -0.020938018336892128,
      -0.009023777209222317,
      -0.03042459301650524,
      -0.04161975905299187,
      0.01913202926516533,
      -0.0105235381051898,
      -0.012313556857407093,
      0.015508267097175121,
      0.016397420316934586,
      0.060408372431993484,
      -0.017789287492632866,
      -0.03697340935468674,
      -0.038850296288728714,
      0.04093291237950325,
      0.06551630049943924,
      -0.028404034674167633,
      0.049479756504297256,
      -0.04428265243768692,
      -0.012099782936275005,
      -0.006672322284430265,
      -0.002248855773359537,
      -0.05224527046084404,
      0.09545283019542694,
      -0.004478804767131805,
      0.024242237210273743,
      -0.05655708163976669,
      0.0018469577189534903,
      0.017849408090114594,
      0.011071412824094296,
      -0.015678467229008675,
      0.012973478995263577,
      0.05632166564464569,
      -0.06869091093540192,
      0.0030803035479038954,
      0.08243536204099655,
      -0.08581408858299255,
      -0.02635047398507595,
      0.02183816395699978,
      0.021182479336857796,
      0.029485372826457024,
      -0.04524201154708862,
      0.009681295603513718,
      0.003570220433175564,
      0.062411095947027206,
      -0.08080142736434937,
      -0.04988972842693329,
      0.11906951665878296,
      0.03990386426448822,
      -0.054125189781188965,
      0.01566838100552559,
      -0.014587351121008396,
      -0.025320328772068024,
      -0.0037941359914839268,
      -0.04301093518733978,
      -0.055866632610559464,
      0.018081793561577797,
      0.0710071325302124,
      -0.05699145793914795,
      -0.02534668892621994,
      0.002282561734318733,
      0.03624969348311424,
      0.00929334294050932,
      -0.03978220373392105,
      0.09782421588897705,
      -0.02006363868713379,
      -0.006266395561397076,
      -0.013726684264838696,
      -0.01812439039349556,
      0.04782061651349068,
      0.037520695477724075,
      -0.0009353294735774398,
      -0.007197552360594273,
      0.00640656566247344,
      -0.0028122460935264826,
      0.021567579358816147,
      -0.02980862185359001,
      -0.007920213975012302,
      -0.01779659278690815,
      0.0018137360457330942,
      -0.05665178596973419,
      0.02570534497499466,
      -0.004952470306307077,
      0.06555945426225662,
      0.12331260740756989,
      0.009083697572350502,
      -0.03071128949522972,
      -0.013627310283482075,
      -0.07204951345920563,
      -0.011390991508960724,
      0.04505501314997673,
      0.05640767887234688,
      0.05825849622488022,
      -0.03961394727230072,
      -0.002123971004039049,
      -0.02582148276269436,
      -0.0028280329424887896,
      -0.04732424020767212,
      0.02411944977939129,
      -0.001143981353379786,
      -0.09183156490325928,
      -0.017498545348644257,
      -0.035058144479990005,
      0.028999023139476776,
      -0.03449452295899391,
      -0.02210913971066475,
      0.007156564388424158,
      -0.008885042741894722,
      0.05862423777580261,
      -0.033474747091531754,
      0.0334295891225338,
      -0.05736970901489258,
      0.0459207259118557,
      0.02337632328271866,
      -0.020926807075738907,
      0.04211476072669029,
      -0.09740804135799408,
      0.015910647809505463,
      -0.05148157849907875,
      -0.02998085878789425,
      0.06419406831264496,
      0.05083078891038895,
      0.008627580478787422,
      -0.050441160798072815,
      -0.0033713404554873705,
      -0.017580503597855568,
      -0.02028055675327778,
      -0.024721061810851097,
      -0.08779823035001755,
      -0.026807330548763275,
      0.03338024765253067,
      0.0001515429758001119,
      -0.028453204780817032,
      0.03874402120709419,
      0.02746761403977871,
      -0.046126462519168854,
      -0.02141536772251129,
      -0.04410230368375778,
      -0.009121355600655079,
      -0.005279533099383116,
      -0.009335427545011044,
      0.01776670105755329,
      0.025840891525149345,
      -0.06621434539556503,
      -0.10734467953443527,
      -0.005387480836361647,
      -0.03791828081011772,
      0.0616651251912117,
      -0.06725166738033295,
      -0.024088291451334953,
      0.018299177289009094,
      -0.024943670257925987,
      0.004496467765420675,
      -0.011521406471729279,
      0.022222718223929405,
      0.01928121969103813,
      0.03743892163038254,
      -0.01945660077035427,
      -0.006564774550497532,
      0.01476871408522129,
      0.05142251029610634,
      -0.05207592248916626,
      -0.016259673982858658,
      0.12540826201438904,
      -0.0041618961840868,
      -0.03820888698101044,
      0.0013560984516516328,
      0.004709424450993538,
      -0.042433060705661774,
      0.05203845724463463,
      -0.04848434776067734,
      -0.08939884603023529,
      -0.03154568374156952,
      -0.011259419843554497,
      0.0200008787214756,
      -0.02243422344326973,
      -0.004577999468892813,
      0.027661597356200218,
      -0.034985750913619995,
      0.026768291369080544,
      -0.014067201875150204,
      0.05588551238179207,
      -0.0452963188290596,
      -0.028233226388692856,
      -0.04538257420063019,
      0.03332636132836342,
      -0.008129274472594261,
      -0.025970052927732468,
      -0.0365290604531765,
      -0.11364097893238068,
      0.0775364488363266,
      -0.024673063308000565,
      0.009958997368812561,
      -0.0021328746806830168,
      -0.032492879778146744,
      -0.0037196881603449583,
      -0.022532260045409203,
      0.014798784628510475,
      -0.00491851894184947,
      0.06321354955434799,
      0.0332021526992321,
      -0.0636487752199173,
      0.060750722885131836,
      0.025763733312487602,
      0.009381539188325405,
      -0.06964641064405441,
      0.059339940547943115,
      -0.0996595174074173,
      -0.07410293817520142,
      0.032496917992830276,
      0.007367505691945553,
      -0.07439159601926804,
      0.07544736564159393,
      0.06558500230312347,
      -0.07424142211675644,
      -0.03728954866528511,
      0.045255500823259354,
      -0.034324973821640015,
      0.06173032149672508,
      -0.013257374987006187,
      0.024165937677025795,
      0.0676942765712738,
      -0.006684864405542612,
      -0.04509897157549858,
      -0.02675851248204708,
      0.060432206839323044,
      0.09331529587507248,
      -0.049284979701042175,
      0.07933569699525833,
      -0.0004903681692667305,
      0.047405775636434555,
      0.012982480227947235,
      -0.03960379585623741,
      -0.030495868995785713,
      0.033862486481666565,
      0.052092209458351135,
      0.02776928059756756,
      0.03430561348795891,
      -0.0646083727478981,
      -0.018422169610857964,
      -0.034858591854572296,
      -0.0011570231290534139,
      -0.01777934469282627,
      -0.07654319703578949,
      -0.021867947652935982,
      0.07224526256322861,
      -0.013771247118711472,
      -0.07653136551380157,
      0.00891227275133133,
      -0.09062016010284424,
      0.0009686553967185318,
      -0.06117241084575653,
      0.0337517149746418,
      0.03130984306335449,
      -0.006589152850210667,
      -0.03911173343658447,
      0.0408225953578949,
      0.027736591175198555,
      0.027593092992901802,
      0.03836983069777489,
      0.06527085602283478,
      0.012141534127295017,
      0.01659621112048626,
      0.03687209635972977,
      0.05952632054686546,
      -0.04441659525036812,
      0.07588130235671997,
      -0.07713369280099869,
      0.013802268542349339,
      -0.023051826283335686,
      -0.039650291204452515,
      -0.041341640055179596,
      -0.07176928967237473,
      -0.05247093364596367,
      -0.016704628244042397,
      -0.0010775644332170486,
      0.012984427623450756,
      -0.019942723214626312,
      0.03406444191932678,
      0.08802177011966705,
      -0.014079603366553783,
      0.021482881158590317,
      0.05262710526585579,
      0.007387634366750717,
      -0.018065840005874634,
      0.02666429989039898,
      0.0526907816529274,
      0.1017569899559021,
      0.06193913519382477,
      0.10067632049322128,
      -0.02087661437690258,
      -0.0493018738925457,
      -0.08300740271806717,
      -0.06153970956802368,
      -0.06601139158010483,
      -0.005879190284758806,
      -0.04139862209558487,
      0.03624558076262474,
      0.061625875532627106,
      -0.0290082897990942,
      0.039620231837034225,
      0.006893008481711149,
      0.018650833517313004,
      -0.02772485464811325,
      0.04885515570640564,
      0.01160637941211462,
      -0.028148964047431946,
      -0.06555511802434921,
      -0.003181582549586892,
      0.03478863090276718,
      -0.03595835715532303,
      -0.021221596747636795,
      -0.05444527789950371,
      0.0017036700155586004,
      0.031374476850032806,
      0.00039996657869778574,
      0.009790637530386448,
      0.011442581191658974,
      0.03962695598602295,
      -0.03396570309996605,
      -0.03553259000182152,
      0.045365702360868454,
      0.01734858565032482,
      -0.059044692665338516,
      0.05700619891285896,
      -0.019113287329673767,
      0.03933461010456085,
      0.037395115941762924,
      0.08936607092618942,
      -0.02306199073791504,
      -0.013540992513298988,
      0.007618808187544346,
      0.03630645200610161,
      0.03162463754415512,
      0.06888280808925629,
      -0.05408407375216484,
      -0.01072502788156271,
      0.004631311632692814
    ],
    [
      -0.03325673192739487,
      0.006864778231829405,
      -0.02479102648794651,
      0.008080240339040756,
      -0.018552398309111595,
      -0.01755700446665287,
      0.03892512619495392,
      0.03841618075966835,
      -0.04711069166660309,
      -0.0025625943671911955,
      0.057383205741643906,
      -0.017022274434566498,
      0.03388048708438873,
      -0.003023262368515134,
      0.02164081111550331,
      -0.03200925141572952,
      -0.0380125567317009,
      -0.08062205463647842,
      -0.03863918036222458,
      0.07798401266336441,
      0.027596700936555862,
      0.04321729764342308,
      -0.027057545259594917,
      -0.0612744502723217,
      0.05239343270659447,
      0.005339699797332287,
      -0.0005911645712330937,
      -0.0663648322224617,
      0.0136558897793293,
      0.002112424233928323,
      -0.021649116650223732,
      0.0363764651119709,
      -0.03071104921400547,
      0.01680239662528038,
      0.062467802315950394,
      -0.002791879465803504,
      -0.025731265544891357,
      0.015845492482185364,
      -0.0717538595199585,
      -0.024456582963466644,
      -0.004921684041619301,
      -0.05925368145108223,
      -0.044149890542030334,
      -0.016029631718993187,
      -0.05739155411720276,
      -0.0020702513866126537,
      -0.1269657462835312,
      -0.00863758847117424,
      -0.024074725806713104,
      -0.08616714924573898,
      0.033845774829387665,
      0.02236698940396309,
      0.047405242919921875,
      -0.011930687353014946,
      0.02253773622214794,
      -0.0484510213136673,
      0.0863022431731224,
      -0.0010374983539804816,
      0.03856832534074783,
      0.04137417674064636,
      0.002091138856485486,
      -0.07672124356031418,
      0.03490566834807396,
      -0.03777538612484932,
      -0.006653007585555315,
      -0.021534208208322525,
      0.05509183555841446,
      -0.027286678552627563,
      0.009460368193686008,
      0.08234024792909622,
      0.04649048298597336,
      0.06561009585857391,
      -0.016861524432897568,
      -0.028627073392271996,
      0.008556121960282326,
      0.013648392632603645,
      -0.07441974431276321,
      0.04693779721856117,
      -0.012094802223145962,
      0.0026894935872405767,
      0.08381452411413193,
      -0.018991727381944656,
      0.01403742004185915,
      0.016016948968172073,
      -0.0017700177850201726,
      -0.022613106295466423,
      0.04712460935115814,
      0.02773493528366089,
      0.03961733728647232,
      -0.00740766292437911,
      0.06760627776384354,
      0.018928689882159233,
      0.08161376416683197,
      0.004338016267865896,
      0.12097204476594925,
      -0.0021271745208650827,
      -0.0011644982732832432,
      0.01460376475006342,
      -0.04582059010863304,
      0.02307797409594059,
      0.02343771420419216,
      -0.038630321621894836,
      -0.04128110036253929,
      0.05374244228005409,
      -0.08679600805044174,
      -0.030530940741300583,
      -0.016418365761637688,
      0.01128778699785471,
      -0.053622256964445114,
      -0.06440523266792297,
      -0.014610097743570805,
      0.011159013025462627,
      -0.006052360404282808,
      0.0010433392599225044,
      0.07148014008998871,
      0.03156914934515953,
      0.040587928146123886,
      0.032039761543273926,
      0.06503183394670486,
      -0.009580244310200214,
      0.011658813804388046,
      -0.05287424847483635,
      -0.03388607129454613,
      0.04882659763097763,
      0.0011218336876481771,
      -0.013682338409125805,
      0.0820847824215889,
      0.011217113584280014,
      -0.05098912864923477,
      0.04097824543714523,
      0.008628742769360542,
      -0.061593327671289444,
      0.07725480943918228,
      -0.07388179749250412,
      0.05950603634119034,
      -0.010632997378706932,
      0.008258291520178318,
      0.04614969715476036,
      0.011654265224933624,
      0.03134753182530403,
      -0.040158629417419434,
      0.06528620421886444,
      0.004096413496881723,
      -0.03709140047430992,
      -0.02636365406215191,
      0.04072732478380203,
      0.007681958377361298,
      0.01946491189301014,
      -0.012267181649804115,
      0.030967017635703087,
      -0.056713249534368515,
      -0.01512299943715334,
      0.03913114592432976,
      -0.002880399115383625,
      0.022507786750793457,
      -0.036319583654403687,
      -0.03862733393907547,
      -0.10356003046035767,
      0.018028583377599716,
      -0.03616579994559288,
      0.029684115201234818,
      0.034246932715177536,
      0.0449087992310524,
      0.03779088333249092,
      0.0741051733493805,
      0.027417756617069244,
      0.021422311663627625,
      0.015918651595711708,
      -0.04427526146173477,
      -0.03738773986697197,
      -0.02920634299516678,
      0.01668676920235157,
      -0.03434357792139053,
      -0.0914742648601532,
      -0.03668312728404999,
      0.003279429394751787,
      0.023558253422379494,
      -0.009151523001492023,
      -0.028697842732071877,
      -0.0333930179476738,
      -0.014318948611617088,
      -0.02098148502409458,
      0.04264761507511139,
      -0.08066297322511673,
      -0.045783866196870804,
      0.022169191390275955,
      -0.09090929478406906,
      0.0013155927881598473,
      0.02011774480342865,
      0.0005439132801257074,
      -0.02411298267543316,
      0.019218366593122482,
      -0.06250643730163574,
      -0.007124568335711956,
      0.03882676735520363,
      0.04742398485541344,
      -0.02824932336807251,
      -0.03915523737668991,
      0.023494353517889977,
      0.04334135353565216,
      -0.04254280775785446,
      -0.00524482736364007,
      -0.021332552656531334,
      0.029294922947883606,
      -0.007066723890602589,
      0.02727891132235527,
      -0.051485996693372726,
      -0.00876567605882883,
      0.07675381004810333,
      0.04676275700330734,
      0.09152361005544662,
      -0.06599154323339462,
      -0.012639107182621956,
      -0.03485826030373573,
      -0.02515817992389202,
      -0.061890169978141785,
      -0.026272665709257126,
      -0.0567769855260849,
      -0.008030111901462078,
      0.005802277475595474,
      0.02890525944530964,
      -0.00024311010201927274,
      -0.02528856135904789,
      0.0323483906686306,
      0.06909158080816269,
      -0.039384886622428894,
      0.002195167588070035,
      0.059272874146699905,
      -0.01027202419936657,
      -0.08072888851165771,
      0.07800731062889099,
      -0.07775354385375977,
      -0.0016923679504543543,
      0.008698041550815105,
      0.07647829502820969,
      0.01844906248152256,
      -0.06509489566087723,
      -0.0476456955075264,
      0.019475184381008148,
      0.08636504411697388,
      0.013416463509202003,
      0.0894077941775322,
      -0.04030720144510269,
      0.002512520644813776,
      0.04620645195245743,
      0.018912656232714653,
      -0.02736601233482361,
      -0.05759414657950401,
      0.004004461225122213,
      0.0431741327047348,
      0.04334885627031326,
      -0.01816817931830883,
      0.03443477675318718,
      -0.03088579885661602,
      0.011112351901829243,
      -0.09673713892698288,
      -0.010545236989855766,
      0.0278372410684824,
      0.030399594455957413,
      -0.019723812118172646,
      0.01559616532176733,
      0.03604710474610329,
      -0.020848549902439117,
      0.03857047110795975,
      -0.026851914823055267,
      -0.013341739773750305,
      -0.03899150341749191,
      -0.030463580042123795,
      -0.004975512158125639,
      -0.0435737743973732,
      0.03831459954380989,
      -0.038694556802511215,
      -0.01319042593240738,
      0.0005875960923731327,
      0.001903605880215764,
      0.01087999064475298,
      0.04067328944802284,
      0.0753445029258728,
      0.023198207840323448,
      -0.0388055182993412,
      -0.012558510527014732,
      -0.0012118996819481254,
      0.03627684712409973,
      -0.0003435272956266999,
      0.022754555568099022,
      0.028311414644122124,
      -0.10705730319023132,
      0.007239377126097679,
      -0.025336572900414467,
      -0.0011152825318276882,
      -0.06268642842769623,
      -0.03720662742853165,
      0.0022285468876361847,
      0.04551837593317032,
      -0.03199421241879463,
      0.05606047436594963,
      -0.019185462966561317,
      -0.021041130647063255,
      -0.04516881704330444,
      0.041359350085258484,
      -0.03282653167843819,
      0.0028090530540794134,
      0.016578974202275276,
      -0.01934107206761837,
      0.05173312872648239,
      0.05637359991669655,
      0.01784302107989788,
      0.02126348577439785,
      0.11523854732513428,
      -0.007537448313087225,
      -0.01924869976937771,
      0.07027959823608398,
      -0.017268089577555656,
      -0.04875590652227402,
      -0.0008048557792790234,
      0.03373086079955101,
      0.08729933947324753,
      0.0022202800028026104,
      0.08988804370164871,
      -0.08167976140975952,
      0.07066287845373154,
      -0.09075285494327545,
      0.04124949499964714,
      -0.017914513126015663,
      0.03434639424085617,
      0.01993478834629059,
      0.003849840722978115,
      -0.06411746144294739,
      0.028428537771105766,
      -0.032655566930770874,
      0.09702853858470917,
      -0.00027874039369635284,
      0.008712508715689182,
      0.013088739477097988,
      -0.051452867686748505,
      -0.03368440270423889,
      -0.05813457444310188,
      0.1041921004652977,
      -0.03547075763344765,
      0.03613191470503807,
      -0.0011557252146303654,
      -0.09107115864753723,
      0.03750394284725189,
      -0.0601162314414978,
      -0.018556587398052216,
      0.06497795134782791,
      -0.06630655378103256,
      0.06191392242908478,
      0.05176898092031479,
      -0.0643567368388176,
      -0.010288624092936516,
      0.050908543169498444,
      0.02672211267054081,
      0.02984008938074112,
      -0.00595872662961483,
      0.012439742684364319,
      -0.009944484569132328,
      0.011475341394543648,
      -0.0038840635679662228,
      0.09324578940868378,
      0.026764441281557083,
      -0.056749049574136734,
      -0.02515990100800991,
      0.0396665595471859,
      0.024406561627984047,
      0.022438015788793564,
      -0.017758294939994812,
      0.033956706523895264,
      0.03692192584276199,
      0.065980926156044,
      -0.029208242893218994,
      -0.021709313616156578,
      0.013213714584708214,
      0.035505909472703934,
      0.007624730002135038,
      -0.021065257489681244,
      -0.12680965662002563,
      0.014829051680862904,
      -0.03954418748617172,
      -0.018496733158826828,
      -0.08323301374912262,
      -0.012823231518268585,
      -0.028644945472478867,
      0.009915698319673538,
      -0.019335387274622917,
      -0.0073782168328762054,
      0.005075365770608187,
      0.02183062583208084,
      -0.017356079071760178,
      0.06478767096996307,
      -0.0016855288995429873,
      0.08721351623535156,
      0.03678913414478302,
      -0.020169846713542938,
      -0.022298133000731468,
      -7.022038334980607e-05,
      -0.06759858876466751,
      0.048597581684589386,
      0.010603448376059532,
      -0.0066146100871264935,
      0.004163675010204315,
      -0.011933756992220879,
      -0.009036545641720295,
      0.0505521185696125,
      -0.06980592012405396,
      -0.04116453975439072,
      -0.0041726697236299515,
      -0.048031266778707504,
      -0.09894544631242752,
      0.03174624592065811,
      -0.022023959085345268,
      -0.047230228781700134,
      0.026171287521719933,
      0.03883690759539604,
      -0.007822861894965172,
      -0.05842065066099167,
      0.03498121723532677,
      0.04423394799232483,
      0.024620965123176575,
      -0.0001636602246435359,
      0.03995033726096153,
      -0.010923165827989578,
      -0.015873441472649574,
      0.007011502515524626,
      0.06040153279900551,
      -0.0269422996789217,
      0.02331659011542797,
      0.04835247993469238,
      -0.0060104262083768845,
      -0.07607553154230118,
      0.06717286258935928,
      -0.0730353370308876,
      -0.018685469403862953,
      -0.07202531397342682,
      -0.07301080226898193,
      0.03062000311911106,
      -0.024440746754407883,
      0.021621711552143097,
      -0.06065044179558754,
      -0.02757260389626026,
      -0.00013727592886425555,
      0.03301341459155083,
      -0.03367093205451965,
      0.0870802253484726,
      0.034909915179014206,
      -0.004267700482159853,
      -0.018373016268014908,
      0.0698522999882698,
      -0.015696683898568153,
      -0.04274000972509384,
      0.046484801918268204,
      -0.030781075358390808,
      -0.015294069424271584,
      -0.03556031361222267,
      0.004391923081129789,
      0.043910447508096695,
      -0.015193992294371128,
      -0.020073696970939636,
      0.026162026450037956,
      0.007879766635596752,
      0.048789046704769135,
      0.032182756811380386,
      0.02378144860267639,
      0.06258244067430496,
      0.010078748688101768,
      0.0101400725543499,
      0.003199001308530569,
      -0.009431430138647556,
      0.10506433248519897,
      0.023761248216032982,
      -0.03907060623168945,
      0.06745806336402893,
      0.05907772481441498,
      0.04740716516971588,
      -0.03391497582197189,
      -0.05618177726864815,
      0.010481682606041431,
      -0.03616712987422943,
      -0.08547118306159973,
      0.057181671261787415,
      -0.031105006113648415,
      0.018779126927256584,
      -0.0244948398321867,
      0.05826085805892944,
      -0.03488598391413689,
      -0.02620094269514084,
      0.04905236139893532,
      -0.02220093458890915,
      0.002826061099767685,
      0.0038549317978322506,
      -0.0750708058476448,
      -0.018723193556070328,
      -0.045419201254844666,
      0.05755353346467018,
      -0.033210527151823044,
      0.0073491912335157394,
      -0.020302733406424522,
      -0.024493226781487465,
      0.003846211591735482,
      0.05343291163444519,
      -0.016792917624115944,
      0.007521339692175388,
      -0.06452171504497528
    ],
    [
      -0.0622856542468071,
      0.006926617585122585,
      0.09754455089569092,
      0.004761165007948875,
      0.009033914655447006,
      0.023282768204808235,
      -0.07557002454996109,
      0.028433147817850113,
      -0.003495950950309634,
      0.008753166534006596,
      -0.043728675693273544,
      0.02631036378443241,
      0.054658204317092896,
      0.033128123730421066,
      0.09595420956611633,
      -0.04099486768245697,
      0.006221850402653217,
      0.015005209483206272,
      -0.03677404671907425,
      -0.03010019101202488,
      0.06609783321619034,
      0.06007389351725578,
      -0.04344865679740906,
      -0.05266111344099045,
      0.05135008320212364,
      -0.03948395326733589,
      -0.009018887765705585,
      0.03300986438989639,
      -0.02424425445497036,
      0.05953250452876091,
      0.0007661751005798578,
      0.028213225305080414,
      0.07276800274848938,
      0.005403085611760616,
      -0.06130156293511391,
      0.061086300760507584,
      -0.04360879585146904,
      -0.025075888261198997,
      0.01242662500590086,
      0.04214463755488396,
      -0.05556934326887131,
      -0.0557132288813591,
      0.03977546468377113,
      0.06968123465776443,
      0.03164498880505562,
      0.007481455337256193,
      -0.004329741932451725,
      -0.0432860441505909,
      -0.05395427718758583,
      0.011171620339155197,
      0.029045753180980682,
      0.02182525396347046,
      0.037553709000349045,
      0.015312802977859974,
      0.062293827533721924,
      0.037816014140844345,
      0.0031981810461729765,
      0.08831379562616348,
      -0.031776051968336105,
      -0.02428692951798439,
      0.06548226624727249,
      0.06903821974992752,
      -0.06040526553988457,
      0.007255986332893372,
      -0.030554136261343956,
      0.07065042108297348,
      -0.026269087567925453,
      -0.042264267802238464,
      0.11967411637306213,
      0.018652742728590965,
      -0.009228517301380634,
      0.053767479956150055,
      -0.007809831295162439,
      0.03609070926904678,
      0.027406960725784302,
      -0.03732797130942345,
      -0.02759082429111004,
      -0.0194447860121727,
      0.014717087149620056,
      0.02333776094019413,
      -0.07740488648414612,
      -0.020540175959467888,
      0.05375511199235916,
      -0.03387568145990372,
      0.09180409461259842,
      -0.043832723051309586,
      0.011774180456995964,
      -0.019988862797617912,
      -0.032578736543655396,
      0.02272009663283825,
      -0.019511375576257706,
      0.03977177292108536,
      0.05332401767373085,
      0.05592356249690056,
      -0.012631542049348354,
      -0.02087746188044548,
      0.0035995987709611654,
      0.06098368763923645,
      0.045302487909793854,
      0.02451348304748535,
      -0.11364036053419113,
      -0.021191323176026344,
      -0.0060833231545984745,
      0.026253413408994675,
      -0.019896211102604866,
      -0.009838772006332874,
      -0.08453541994094849,
      0.013938233256340027,
      0.008456282317638397,
      -0.09142407029867172,
      0.05042041093111038,
      0.053658805787563324,
      -0.012865419499576092,
      0.009078467264771461,
      0.08866703510284424,
      -0.010943579487502575,
      -0.0021543223410844803,
      0.0008920994005165994,
      0.02787577174603939,
      -0.04783628135919571,
      0.013255489058792591,
      -0.005834043491631746,
      0.03600478172302246,
      -0.024447578936815262,
      -0.03813106194138527,
      -0.023383496329188347,
      0.08962924033403397,
      -0.14859220385551453,
      0.03081902675330639,
      -0.031018653884530067,
      0.04199066013097763,
      -0.03593337908387184,
      -0.0680537223815918,
      -0.0017684658523648977,
      0.0378912054002285,
      0.07839526981115341,
      -0.0010208244202658534,
      0.0335548035800457,
      0.005090712103992701,
      -0.002054926473647356,
      -0.037656527012586594,
      -0.003982373978942633,
      0.011299547739326954,
      -0.08491064608097076,
      -0.00019851121760439128,
      -0.03738102316856384,
      -0.003255779854953289,
      -0.032619912177324295,
      -0.011779537424445152,
      0.0209792647510767,
      -0.03727387264370918,
      -0.02584204450249672,
      0.01757117733359337,
      0.016527222469449043,
      -0.020572682842612267,
      0.06183833256363869,
      -0.11676418781280518,
      0.030363358557224274,
      0.058611445128917694,
      0.03295838460326195,
      0.052824098616838455,
      0.017880601808428764,
      0.03973015025258064,
      0.02229287102818489,
      0.07754103094339371,
      -0.04135869815945625,
      0.05725620314478874,
      0.044399578124284744,
      0.058975700289011,
      0.05954640358686447,
      -0.03317774087190628,
      0.008892068639397621,
      -0.02409988082945347,
      -0.045601654797792435,
      -0.01476976927369833,
      -0.03399999439716339,
      0.04044879227876663,
      0.05373341590166092,
      -0.07904479652643204,
      -0.030735153704881668,
      0.036550845950841904,
      -0.025635622441768646,
      0.01976957358419895,
      0.04080195724964142,
      0.020842110738158226,
      -0.0119828125461936,
      0.0004594186320900917,
      -0.03743116557598114,
      -0.020646532997488976,
      -0.08874952793121338,
      0.05360616743564606,
      -0.004766369704157114,
      -0.05761311948299408,
      0.004085386171936989,
      -0.021624162793159485,
      -0.009472216479480267,
      0.0802057757973671,
      0.08880843222141266,
      0.04354323446750641,
      0.0004618205421138555,
      0.04853644594550133,
      0.009648032486438751,
      0.014370541088283062,
      0.0901789665222168,
      0.026255439966917038,
      0.03824719041585922,
      -0.08563602715730667,
      0.06146390363574028,
      0.056993518024683,
      0.08164747804403305,
      0.012998144142329693,
      -0.03133312240242958,
      -0.0714031532406807,
      0.037627846002578735,
      -0.01588459685444832,
      0.018547849729657173,
      -0.004514362663030624,
      0.027588363736867905,
      -0.010464811697602272,
      -0.08518220484256744,
      0.015597215853631496,
      0.00020557591051328927,
      0.02095026895403862,
      0.023165499791502953,
      -0.05014337599277496,
      0.06430515646934509,
      -0.03129597380757332,
      -0.02014254406094551,
      0.001932591781951487,
      0.09050535410642624,
      0.0392642579972744,
      -0.05959458649158478,
      0.02809819020330906,
      0.06749105453491211,
      -0.018981317058205605,
      -0.07222466170787811,
      -0.06679707020521164,
      -0.045758459717035294,
      -0.0022338111884891987,
      0.02864566631615162,
      0.036508724093437195,
      0.014796143397688866,
      0.028416670858860016,
      0.008915398269891739,
      0.015830960124731064,
      -0.014388431794941425,
      0.0030345371924340725,
      0.07313516736030579,
      -0.07625383883714676,
      -0.014367866329848766,
      -0.016656385734677315,
      -0.04318314790725708,
      0.037945110350847244,
      0.006410739850252867,
      -0.03549017757177353,
      0.016909461468458176,
      -0.06263413280248642,
      0.0698467418551445,
      0.002434645313769579,
      0.03723166882991791,
      -0.022632712498307228,
      -0.03960513323545456,
      0.04401089996099472,
      0.023753073066473007,
      -0.011828666552901268,
      -0.027797561138868332,
      -0.021617017686367035,
      0.0008999850251711905,
      0.0077111078426241875,
      -0.016941772773861885,
      -0.007277396973222494,
      0.03581240773200989,
      0.06487663835287094,
      0.023481979966163635,
      -0.07659236341714859,
      0.0438876636326313,
      -0.10604885965585709,
      0.09804756939411163,
      -0.024366406723856926,
      0.019104694947600365,
      -0.005508690606802702,
      -0.013743001036345959,
      -0.05169062688946724,
      -0.11540853977203369,
      -0.05889853835105896,
      -0.06342755258083344,
      0.021807871758937836,
      -0.07937322556972504,
      0.006448864005506039,
      -0.06865594536066055,
      -0.04506402462720871,
      0.0906747505068779,
      -0.10105016082525253,
      -0.012103168293833733,
      -0.025510873645544052,
      0.039454154670238495,
      -0.05451187491416931,
      -0.0008887299918569624,
      0.044473372399806976,
      0.011067215353250504,
      -0.012925236485898495,
      0.06487631797790527,
      -0.02868599258363247,
      0.04406135156750679,
      0.037572287023067474,
      -0.02461763098835945,
      0.014718043617904186,
      -0.04777420684695244,
      -0.043092031031847,
      -0.023681407794356346,
      0.0709068551659584,
      0.04052930325269699,
      -0.014516784809529781,
      -0.08719126880168915,
      -0.0010127645218744874,
      -0.03837370499968529,
      -0.0393868163228035,
      0.015924572944641113,
      -0.050690583884716034,
      -0.02581831067800522,
      -0.026549387723207474,
      0.0337463840842247,
      0.02936267852783203,
      -0.030014919117093086,
      -0.04308810085058212,
      0.09238046407699585,
      -0.08285826444625854,
      0.0513165183365345,
      0.04515751823782921,
      0.016376353800296783,
      0.106043241918087,
      0.007059013471007347,
      -0.03399002179503441,
      0.010331464931368828,
      0.05048499256372452,
      -0.1073448657989502,
      0.037001803517341614,
      -0.010363767854869366,
      -0.041208259761333466,
      0.04422762617468834,
      -0.05458743870258331,
      -0.027704937383532524,
      0.016796782612800598,
      0.0014514110516756773,
      -0.02125431038439274,
      -0.014420979656279087,
      -0.002420286647975445,
      -0.0137453842908144,
      0.018422674387693405,
      0.03132007271051407,
      0.035723064094781876,
      -0.026126248762011528,
      0.04581685736775398,
      -0.02311248704791069,
      -0.026229245588183403,
      0.015574019402265549,
      0.030100004747509956,
      0.04932251572608948,
      0.03860219195485115,
      -0.013745452277362347,
      0.01954542100429535,
      0.009911531582474709,
      -0.02701209858059883,
      -0.054283201694488525,
      0.01039053499698639,
      -0.03252792730927467,
      0.0292177964001894,
      0.012906316667795181,
      -0.02421717531979084,
      0.055145226418972015,
      -0.06942329555749893,
      0.027542512863874435,
      0.03125534579157829,
      0.03291260823607445,
      -0.07265914976596832,
      0.016439400613307953,
      0.09545065462589264,
      0.006431326270103455,
      0.04103642702102661,
      -0.025280382484197617,
      -0.0016962372465059161,
      -0.07460213452577591,
      0.00436087790876627,
      -0.011169478297233582,
      -0.029004449024796486,
      -0.03604485094547272,
      -0.03875177726149559,
      0.09810318797826767,
      0.024712251499295235,
      0.09187708050012589,
      0.004976736847311258,
      0.04340079054236412,
      0.014796772040426731,
      0.016955144703388214,
      0.0015977466246113181,
      0.004235338419675827,
      -0.009572378359735012,
      -0.08282923698425293,
      0.005864053964614868,
      -0.009165115654468536,
      -0.03782496601343155,
      0.038745585829019547,
      0.040737830102443695,
      0.0182263795286417,
      0.02408653497695923,
      0.0024429585319012403,
      0.05566425994038582,
      -0.07425801455974579,
      -0.022127250209450722,
      0.031657494604587555,
      0.028098462149500847,
      -0.10173483937978745,
      0.028259482234716415,
      0.07059727609157562,
      -0.01816379278898239,
      0.08587758988142014,
      0.05693404749035835,
      -0.05116019770503044,
      0.05494122579693794,
      0.09909393638372421,
      0.00838581845164299,
      0.06680411845445633,
      0.04097787290811539,
      0.07122740149497986,
      0.02588299848139286,
      0.0008655330166220665,
      0.010703854262828827,
      0.01967647671699524,
      0.008697232231497765,
      0.021106930449604988,
      0.029446842148900032,
      -0.05122858285903931,
      0.03932639956474304,
      -0.04335591197013855,
      0.008543940261006355,
      0.053783174604177475,
      -0.004418026655912399,
      -0.032901883125305176,
      -0.012261167168617249,
      -0.060183197259902954,
      0.04512929916381836,
      -0.08914560824632645,
      0.06456948816776276,
      0.06019025668501854,
      -0.04041595011949539,
      -0.0005539933335967362,
      -0.024388615041971207,
      0.05420771241188049,
      0.07790906727313995,
      -0.05225366726517677,
      -0.023147735744714737,
      0.002013762481510639,
      0.01514050830155611,
      0.059633176773786545,
      0.03957873955368996,
      0.020159896463155746,
      0.008606321178376675,
      0.06538023054599762,
      0.030059808865189552,
      0.07391468435525894,
      -0.09720220416784286,
      -0.06885700672864914,
      0.01081653032451868,
      0.015833968296647072,
      0.040200695395469666,
      0.024411195889115334,
      -0.09805094450712204,
      -0.011192677542567253,
      0.04177802801132202,
      -0.018706832081079483,
      -0.04915768653154373,
      -0.0008284414652734995,
      0.017582980915904045,
      -0.101648710668087,
      0.028704065829515457,
      0.02131372131407261,
      0.05851443484425545,
      0.013105465099215508,
      -0.028493467718362808,
      -0.033338770270347595,
      -0.02737264707684517,
      -0.017950614914298058,
      0.017312470823526382,
      0.012886998243629932,
      0.020678706467151642,
      0.0233721062541008,
      -0.03116215579211712,
      0.026349980384111404,
      -0.04801296442747116,
      0.012448031455278397,
      0.044308051466941833,
      0.10771404206752777,
      0.04237377271056175,
      -0.040574636310338974,
      -0.011524543166160583,
      -0.009684007614850998,
      0.04935251176357269,
      0.097523994743824,
      -0.0455765463411808,
      -0.003090345300734043,
      -0.017997637391090393,
      -0.009118893183767796,
      -0.05013374984264374
    ],
    [
      0.08457402884960175,
      0.012310374528169632,
      -9.356544615002349e-05,
      0.04626269266009331,
      -0.07441716641187668,
      0.023732975125312805,
      -0.004070769529789686,
      0.06750130653381348,
      -0.012011297047138214,
      0.003908841870725155,
      -0.017509283497929573,
      -0.01110793836414814,
      -0.0010475515155121684,
      -0.028648361563682556,
      0.02241295948624611,
      0.042650870978832245,
      0.042445018887519836,
      -0.06729841977357864,
      0.030201079323887825,
      -0.008018006570637226,
      0.010606690309941769,
      0.022231973707675934,
      0.0400063581764698,
      0.04996128007769585,
      0.005269140470772982,
      -0.09065790474414825,
      0.011771767400205135,
      -0.03071053884923458,
      -0.03803694248199463,
      -0.0115848109126091,
      -0.042259227484464645,
      0.0015615992015227675,
      0.04402046650648117,
      -0.045585278421640396,
      -0.00720386253669858,
      -0.01811395399272442,
      -0.0207029040902853,
      0.0035477487836033106,
      -0.04078998789191246,
      -0.021182620897889137,
      0.005375389009714127,
      -0.03595332056283951,
      0.012673087418079376,
      0.09019089490175247,
      -0.018168402835726738,
      -0.0008559125708416104,
      0.06349793076515198,
      -0.05565069988369942,
      -0.037122152745723724,
      -0.03426981344819069,
      -0.0750647559762001,
      -0.007187503390014172,
      0.054506510496139526,
      -0.011886527761816978,
      0.05026312172412872,
      0.0741346925497055,
      -0.06852175295352936,
      -0.03561163693666458,
      0.06736698001623154,
      0.03496107459068298,
      0.03955996409058571,
      0.005630979780107737,
      0.0076256971806287766,
      -0.15109939873218536,
      0.027851121500134468,
      0.01376961451023817,
      0.03995116800069809,
      -0.012317163869738579,
      -0.04175901785492897,
      0.04162784293293953,
      0.009599707089364529,
      0.07783958315849304,
      -0.04564112052321434,
      0.01191010419279337,
      0.08029516786336899,
      0.003977473359555006,
      0.004777539987117052,
      0.01284770667552948,
      0.039788857102394104,
      -0.021924903616309166,
      0.01693303883075714,
      -0.009439913555979729,
      0.053721386939287186,
      -0.02788851410150528,
      -0.04282212629914284,
      -0.04378245770931244,
      0.05500887334346771,
      0.04534912109375,
      0.046465516090393066,
      0.02414497546851635,
      0.04553467035293579,
      0.030219051986932755,
      0.02165735699236393,
      -0.017814641818404198,
      -0.016586946323513985,
      -0.014442525804042816,
      0.008610145188868046,
      -0.029396573081612587,
      -0.03520882874727249,
      0.03577004745602608,
      0.01497409027069807,
      0.07055878639221191,
      -0.05572666600346565,
      0.027162563055753708,
      0.06891146302223206,
      -0.05151195451617241,
      0.0033018982503563166,
      -0.003255776595324278,
      0.009851882234215736,
      -0.09929606318473816,
      0.021695801988244057,
      -0.04238107055425644,
      0.025094959884881973,
      -0.022031547501683235,
      0.008611985482275486,
      -0.01307922787964344,
      0.0037488285452127457,
      -0.005294098053127527,
      -0.12771663069725037,
      -0.01506048534065485,
      0.003959291614592075,
      0.0034100073389708996,
      -0.012618403881788254,
      0.004424697253853083,
      0.008968115784227848,
      -0.00927263218909502,
      -0.04766932874917984,
      -0.003999627195298672,
      -0.0017732423730194569,
      -0.0148005411028862,
      0.08731483668088913,
      0.05910370498895645,
      -0.052231982350349426,
      -0.024943668395280838,
      0.0730920284986496,
      0.019723840057849884,
      0.04309057071805,
      -0.053047288209199905,
      0.023403184488415718,
      -0.047457505017519,
      0.03331397846341133,
      0.012598102912306786,
      0.040693338960409164,
      0.006118190474808216,
      0.04775873199105263,
      -0.028680771589279175,
      -0.03698262944817543,
      -0.0018460055580362678,
      0.07756224274635315,
      0.025140639394521713,
      -0.05624855309724808,
      0.03693237528204918,
      0.06667963415384293,
      0.09300271421670914,
      0.06745939701795578,
      0.003359454218298197,
      -0.003408735617995262,
      -0.05547149106860161,
      0.05886107310652733,
      0.08238507807254791,
      -0.051480554044246674,
      0.010141140781342983,
      -0.01975134201347828,
      0.0327337309718132,
      0.021395348012447357,
      -0.040612805634737015,
      0.0391501784324646,
      0.03882904350757599,
      0.035039160400629044,
      0.059579990804195404,
      -0.030914081260561943,
      0.013778978027403355,
      -0.0007007633685134351,
      0.050511062145233154,
      -0.03635937720537186,
      0.018572838976979256,
      0.015296789817512035,
      -0.027502894401550293,
      0.02222002111375332,
      0.020758362486958504,
      -0.02794128656387329,
      -0.02159704826772213,
      -0.04595991596579552,
      0.018692202866077423,
      0.024651043117046356,
      -0.06508422642946243,
      -0.008458953350782394,
      -0.029470814391970634,
      -0.06380433589220047,
      0.050071995705366135,
      -0.023957159370183945,
      -0.04191724583506584,
      0.021512774750590324,
      0.03150058537721634,
      0.0708564892411232,
      -0.034465301781892776,
      -0.0022059031762182713,
      0.04047999903559685,
      0.03434905782341957,
      0.018248766660690308,
      0.052359044551849365,
      0.02976319007575512,
      -0.04953984543681145,
      -0.007714347448199987,
      -0.10934944450855255,
      0.016130542382597923,
      -0.007919957861304283,
      -0.0035524191334843636,
      0.0048187593929469585,
      0.04405651614069939,
      -0.01809312403202057,
      -0.040227923542261124,
      0.055031612515449524,
      0.022800136357545853,
      0.04803032428026199,
      0.027232088148593903,
      0.06321188807487488,
      -0.027844538912177086,
      0.07479076087474823,
      0.0534074567258358,
      -0.01059031579643488,
      0.03779163211584091,
      -0.02618909813463688,
      0.08000142872333527,
      0.019408665597438812,
      -0.020793691277503967,
      -0.013060753233730793,
      0.017438167706131935,
      -0.0270195584744215,
      0.0015368291642516851,
      0.04325667768716812,
      -0.07304959744215012,
      0.09148568660020828,
      0.029372207820415497,
      -0.05597899109125137,
      -0.02732628583908081,
      -0.028408687561750412,
      -0.017489444464445114,
      -0.07630036771297455,
      0.021755345165729523,
      -0.0622742734849453,
      0.02997749112546444,
      0.010043034330010414,
      0.015563469380140305,
      0.01952904462814331,
      -0.04087015613913536,
      0.03409036993980408,
      0.014989351853728294,
      0.01963672786951065,
      -0.03973391652107239,
      0.07123766839504242,
      -0.003368134843185544,
      0.011182351969182491,
      0.0017716481816023588,
      0.0029402500949800014,
      -0.00816718116402626,
      0.08417223393917084,
      -0.01570582203567028,
      -0.048044003546237946,
      -0.024780482053756714,
      0.03448611497879028,
      0.0008227009675465524,
      0.07238245010375977,
      0.009196769446134567,
      0.02157655730843544,
      0.04105953872203827,
      -0.02178225666284561,
      -0.062432173639535904,
      -0.018687773495912552,
      0.004117934964597225,
      0.013853231444954872,
      0.033683788031339645,
      0.02500353381037712,
      0.0003322676639072597,
      -0.008876865729689598,
      0.01136715803295374,
      -0.022188331931829453,
      -0.06134633719921112,
      -0.031807709485292435,
      -0.026825299486517906,
      -0.05323323979973793,
      0.09062414616346359,
      -0.053867705166339874,
      -0.029455795884132385,
      0.015097570605576038,
      -0.010538282804191113,
      0.07399140298366547,
      0.048388876020908356,
      0.07011518627405167,
      -0.01360364444553852,
      -0.053436074405908585,
      0.036484647542238235,
      0.034860171377658844,
      0.05587076023221016,
      0.01563330367207527,
      0.010497568175196648,
      -0.04107622429728508,
      -0.020541207864880562,
      0.01115232240408659,
      -0.025445614010095596,
      -0.022478684782981873,
      0.04141155257821083,
      -0.015101006254553795,
      -0.06439850479364395,
      -0.0242982879281044,
      -0.03796552121639252,
      -0.01276861596852541,
      -0.04425102472305298,
      0.09020135551691055,
      -0.005227079149335623,
      0.08004555106163025,
      0.008705776184797287,
      0.017007922753691673,
      -0.0020722048357129097,
      -0.05733519792556763,
      -0.08530064672231674,
      -0.021006539463996887,
      -0.033390551805496216,
      0.026178978383541107,
      0.05571448802947998,
      0.016535986214876175,
      -0.028699487447738647,
      -0.01705618016421795,
      -0.019889047369360924,
      0.06413888186216354,
      -0.03498570993542671,
      0.026870032772421837,
      0.030101971700787544,
      -0.06162727624177933,
      0.021802565082907677,
      0.0645889863371849,
      -0.01544488687068224,
      0.009871743619441986,
      0.0359213724732399,
      0.049641650170087814,
      -0.07726097106933594,
      0.07346980273723602,
      -0.015926890075206757,
      0.04540042206645012,
      -0.02010314352810383,
      -0.05954619497060776,
      -0.09047062695026398,
      0.017996594309806824,
      -0.011849785223603249,
      -0.07890530675649643,
      -0.03437554091215134,
      -0.0189843587577343,
      0.002037286991253495,
      0.024608921259641647,
      -0.054440002888441086,
      -0.036489564925432205,
      -0.06839347630739212,
      -0.03983885794878006,
      0.07947753369808197,
      -0.014062667265534401,
      -0.03279750794172287,
      0.028749708086252213,
      -0.00012488327047321945,
      0.0421532467007637,
      0.020748291164636612,
      0.0387219674885273,
      -0.0059860204346477985,
      0.09406660497188568,
      0.01891265995800495,
      0.007027425803244114,
      0.015589764341711998,
      -0.04277186840772629,
      0.012018072418868542,
      0.012250015512108803,
      0.07762165367603302,
      -0.03878268226981163,
      0.03755662590265274,
      0.07378454506397247,
      -0.06415311247110367,
      0.020532796159386635,
      -0.007273878436535597,
      0.12922236323356628,
      0.08651569485664368,
      -0.061417076736688614,
      -0.009515658020973206,
      -0.0057120127603411674,
      0.09782832860946655,
      -0.02626095525920391,
      -0.10269745439291,
      -0.03401386737823486,
      -0.006625388283282518,
      -0.04269392043352127,
      0.05057945474982262,
      0.008362904191017151,
      -0.0014460203237831593,
      0.0100075863301754,
      -0.09078390151262283,
      0.05626044049859047,
      -0.07558223605155945,
      0.04488101974129677,
      -0.007857504300773144,
      0.007279377430677414,
      -0.008418986573815346,
      0.04895874485373497,
      -0.09737259894609451,
      0.031876396387815475,
      -0.009422055445611477,
      -0.02748267352581024,
      0.010146047919988632,
      0.0494479276239872,
      -0.08675143867731094,
      -0.04477320611476898,
      -0.0014324074145406485,
      -0.043421097099781036,
      0.008815187960863113,
      0.04826347529888153,
      -0.027730999514460564,
      -0.01936696469783783,
      0.03427927941083908,
      -0.05026066303253174,
      -0.0639999732375145,
      0.003985806833952665,
      -0.009518727660179138,
      0.10786587744951248,
      0.06849807500839233,
      0.014813872054219246,
      -0.05486203730106354,
      0.017132999375462532,
      -0.016800010576844215,
      0.06928318738937378,
      0.004800517577677965,
      -0.009945062920451164,
      -0.06806019693613052,
      -0.014536532573401928,
      -0.021449165418744087,
      -0.027379024773836136,
      0.028016217052936554,
      0.02650417387485504,
      0.06234816834330559,
      -0.00012552426778711379,
      0.01651761122047901,
      0.0009707835270091891,
      -0.05970250070095062,
      0.05391470342874527,
      0.039259523153305054,
      -0.06626703590154648,
      -0.0034031555987894535,
      0.030714184045791626,
      -0.008150134235620499,
      -0.022211359813809395,
      0.03470506891608238,
      0.001364262541756034,
      0.032506994903087616,
      -0.025262314826250076,
      -0.016306614503264427,
      0.11346866190433502,
      0.033617354929447174,
      -0.03897842764854431,
      -0.04357317090034485,
      -0.012253896333277225,
      -0.01303325966000557,
      -0.005018932279199362,
      0.011600729078054428,
      0.02442338690161705,
      0.03087363764643669,
      0.034997887909412384,
      -0.038853999227285385,
      0.02589174546301365,
      -0.0542309544980526,
      -0.028151459991931915,
      0.013185756281018257,
      -0.030140811577439308,
      -0.010997684672474861,
      0.05179726332426071,
      -0.008729378692805767,
      0.020353468134999275,
      -0.04718268662691116,
      -0.0154551537707448,
      -0.014986090362071991,
      -0.009488762356340885,
      0.003948813769966364,
      0.11576277762651443,
      0.052151624113321304,
      0.003513351548463106,
      -0.061773527413606644,
      -0.009786468930542469,
      -0.010423093102872372,
      -0.007687694858759642,
      -0.02680884674191475,
      0.018621813505887985,
      -0.07768341898918152,
      0.05011953413486481,
      0.10975416749715805,
      -0.03508060425519943,
      -0.01944664865732193,
      -0.0013480284251272678,
      0.01250119786709547,
      -0.029842374846339226,
      0.05304247885942459,
      0.07404979318380356,
      -0.009228436276316643,
      0.023750493302941322,
      0.029352452605962753,
      -0.007131938356906176,
      0.10284600406885147,
      -0.021961253136396408,
      -0.052716679871082306,
      -0.012955429963767529
    ],
    [
      0.03228271007537842,
      -0.010327959433197975,
      0.06859587132930756,
      0.0395849384367466,
      -0.10216279327869415,
      -0.006934151519089937,
      -0.05547508969902992,
      -0.07697883248329163,
      -0.054042138159275055,
      0.008991651237010956,
      0.0293565783649683,
      -0.06687244027853012,
      -0.0402790829539299,
      0.008441698737442493,
      -0.039845652878284454,
      -0.04797866567969322,
      -0.05801207572221756,
      -0.02187347039580345,
      -0.03423196077346802,
      -0.09046723693609238,
      0.021649470552802086,
      -0.05357849597930908,
      0.028930988162755966,
      0.01594485342502594,
      -0.02151247300207615,
      0.03790068253874779,
      0.04754715412855148,
      0.0336291678249836,
      0.10310888290405273,
      0.0038375912699848413,
      -0.0004942361847497523,
      0.029561396688222885,
      -0.0120340371504426,
      -0.007644737139344215,
      0.004265374504029751,
      0.034826550632715225,
      0.10116690397262573,
      -6.309223681455478e-05,
      -0.06736792623996735,
      -0.011097969487309456,
      0.0728851780295372,
      0.03095915913581848,
      0.019251154735684395,
      -0.027478329837322235,
      0.02015518955886364,
      0.008561964146792889,
      0.01412244699895382,
      0.013243282213807106,
      0.05894774943590164,
      -0.04729469493031502,
      0.024754129350185394,
      -0.05261941999197006,
      0.010164624080061913,
      0.0364527702331543,
      0.03441565856337547,
      -0.038690172135829926,
      -0.04219933599233627,
      0.03179076686501503,
      0.060281384736299515,
      0.019115790724754333,
      0.0838671326637268,
      -0.0772688239812851,
      -0.0345953144133091,
      -0.010984501801431179,
      -0.016870712861418724,
      0.015395358204841614,
      0.007277371361851692,
      -0.0283135287463665,
      -0.04012634605169296,
      0.016175352036952972,
      -0.0003339315589983016,
      0.0019407018553465605,
      -0.13233749568462372,
      0.0347343310713768,
      -0.01202488224953413,
      0.04861072450876236,
      -0.10426140576601028,
      0.054194677621126175,
      0.0070154983550310135,
      -0.008701151236891747,
      -0.06581816077232361,
      -0.05976579710841179,
      -0.04333879053592682,
      -0.03710855171084404,
      -0.06506859511137009,
      -0.11272026598453522,
      0.01572655700147152,
      0.004925591871142387,
      0.008130253292620182,
      0.02335226722061634,
      -0.00621075788512826,
      0.06969659775495529,
      0.028645090758800507,
      -0.0205940380692482,
      -0.07174934446811676,
      0.022945621982216835,
      0.014612368308007717,
      0.005134044215083122,
      0.055031903088092804,
      -0.058434225618839264,
      -0.03388907387852669,
      0.06238087639212608,
      0.04783174395561218,
      -0.0577298067510128,
      0.06634844094514847,
      0.05238720402121544,
      0.028334783390164375,
      -0.01575307734310627,
      0.021929478272795677,
      -0.005904673133045435,
      0.0020006445702165365,
      0.01672929711639881,
      -0.02320643700659275,
      0.1016116738319397,
      0.026612339541316032,
      -0.039889149367809296,
      0.025239141657948494,
      -0.07787070423364639,
      -0.1668119877576828,
      -0.04182679206132889,
      0.04319678619503975,
      -0.07617299258708954,
      0.0031232316978275776,
      0.003272001864388585,
      -0.003541691694408655,
      0.04842580109834671,
      0.0569099597632885,
      0.005228068213909864,
      0.02457314543426037,
      -0.03882155567407608,
      0.11198966950178146,
      0.038590166717767715,
      0.0339055098593235,
      -0.08759745210409164,
      0.047932516783475876,
      0.009622867219150066,
      -0.03496158495545387,
      0.06119981408119202,
      -0.04350003972649574,
      0.01782594621181488,
      -0.05828108638525009,
      -0.002598154591396451,
      -0.0824119970202446,
      -0.03576430305838585,
      0.023277003318071365,
      -0.0457000732421875,
      -0.016527676954865456,
      -0.03102266788482666,
      0.0627824068069458,
      -0.02830185927450657,
      -0.04852733388543129,
      -0.041817232966423035,
      -0.026613494381308556,
      -0.01719927228987217,
      -0.04725628346204758,
      0.05350719019770622,
      0.13012680411338806,
      -0.010336436331272125,
      0.06005065515637398,
      0.04527325928211212,
      -0.005608467385172844,
      -0.052271146327257156,
      0.03397181257605553,
      -0.0010057151084765792,
      -0.04894661158323288,
      -0.013064274564385414,
      -0.06459818035364151,
      -0.050102271139621735,
      0.05929162725806236,
      -0.0420738160610199,
      -0.014210368506610394,
      -0.020567378029227257,
      -0.01366653572767973,
      0.03398548811674118,
      0.016764787957072258,
      0.010703284293413162,
      -0.061698246747255325,
      -0.06994588673114777,
      0.022169318050146103,
      -0.05830869451165199,
      -0.006674324162304401,
      -0.07094551622867584,
      -0.04039132222533226,
      0.007368858437985182,
      0.1139281615614891,
      0.033495523035526276,
      0.010850388556718826,
      -0.03730928152799606,
      -0.05603643134236336,
      0.0627080500125885,
      -0.0037196711637079716,
      -0.037817321717739105,
      -0.008589494973421097,
      -0.02341829240322113,
      0.024942515417933464,
      -0.038767702877521515,
      -0.006895831320434809,
      -0.059927791357040405,
      -0.026040060445666313,
      0.08014042675495148,
      -0.07687465846538544,
      -0.06472271680831909,
      0.017668558284640312,
      0.023937445133924484,
      -0.01011618785560131,
      -0.030510127544403076,
      -0.03462078422307968,
      0.044770337641239166,
      -0.016978172585368156,
      -0.0708562433719635,
      -0.017813749611377716,
      -0.03541961684823036,
      -0.0015598962781950831,
      0.06784787029027939,
      0.009500772692263126,
      0.014362449757754803,
      -0.028408801183104515,
      0.04363211989402771,
      0.03671003133058548,
      -0.013774601742625237,
      -0.035622116178274155,
      -0.008199190720915794,
      -0.008121097460389137,
      0.010419019497931004,
      0.0037192213349044323,
      0.05171436443924904,
      0.007235787808895111,
      -0.021162448450922966,
      -0.0324883796274662,
      -0.06722422689199448,
      0.004135411698371172,
      0.05890090391039848,
      0.07253282517194748,
      -0.018338371068239212,
      0.058884523808956146,
      -0.027859339490532875,
      0.009447860531508923,
      0.08798912167549133,
      -0.04017342999577522,
      0.08460691571235657,
      0.023704828694462776,
      -0.05298256129026413,
      0.07135830074548721,
      -0.009622665122151375,
      0.06737203896045685,
      0.011529169976711273,
      0.09971737116575241,
      -0.00673579890280962,
      0.01806250773370266,
      -0.016263825818896294,
      0.024732962250709534,
      -0.03232932835817337,
      0.0030872332863509655,
      0.019511565566062927,
      0.08583777397871017,
      -0.01050442736595869,
      0.007157127372920513,
      0.02110171504318714,
      0.00044160024845041335,
      -0.03122119791805744,
      0.005005531013011932,
      -0.01672268845140934,
      -0.0794311985373497,
      -0.0369202084839344,
      -0.045358553528785706,
      -0.04123993590474129,
      -0.01458023302257061,
      -0.075533427298069,
      -0.02918122336268425,
      -0.01325188111513853,
      -0.03312737122178078,
      -0.09653788805007935,
      0.02684302069246769,
      0.03706178069114685,
      0.056372445076704025,
      0.031664010137319565,
      0.018464304506778717,
      -0.008854015730321407,
      -0.0013711985666304827,
      0.04742271825671196,
      -0.06299370527267456,
      0.1123366579413414,
      -0.05748105049133301,
      -0.0469105988740921,
      -0.06525059044361115,
      0.0026134385261684656,
      0.054409027099609375,
      -0.029268328100442886,
      0.03785523399710655,
      -0.02561766281723976,
      0.05901813879609108,
      -0.03556765243411064,
      -0.019357360899448395,
      -0.028361624106764793,
      -0.021797748282551765,
      0.05245338752865791,
      -0.005541556980460882,
      0.06594925373792648,
      0.029280848801136017,
      0.009703198447823524,
      -0.06029118597507477,
      -0.05962638556957245,
      -0.05229844152927399,
      0.07564467191696167,
      -0.04101845249533653,
      -0.07294726371765137,
      -0.058912280946969986,
      -0.010182865895330906,
      0.05510491505265236,
      -0.05214902013540268,
      -0.03274145722389221,
      -0.003457708051428199,
      0.02862817607820034,
      -0.043107226490974426,
      0.08118816465139389,
      -0.03530077263712883,
      -9.949794184649363e-05,
      0.07490216195583344,
      0.018164562061429024,
      0.015888335183262825,
      -0.007402666378766298,
      0.0206375140696764,
      0.01994965970516205,
      -0.0872747004032135,
      0.07383714616298676,
      0.026455888524651527,
      0.004817519802600145,
      -0.007296540774405003,
      0.05174371600151062,
      0.07020559906959534,
      0.04825853928923607,
      -0.00415297644212842,
      -0.00952055025845766,
      -0.026004569604992867,
      0.009652750566601753,
      -0.013157704845070839,
      -0.03440596163272858,
      -0.03494276478886604,
      -0.08179750293493271,
      0.03070402704179287,
      0.016949405893683434,
      -0.07120270282030106,
      -0.006859403103590012,
      0.01170327514410019,
      0.002619057195261121,
      0.0684032067656517,
      0.0720880925655365,
      -0.027148481458425522,
      0.016128240153193474,
      -0.06573096662759781,
      0.05151575431227684,
      -0.03982384502887726,
      -0.007262044586241245,
      0.06368963420391083,
      0.004459900315850973,
      0.027563629671931267,
      -0.0029178690165281296,
      -0.11612126231193542,
      0.003125882474705577,
      0.008792263455688953,
      -0.007205064874142408,
      -0.02147716097533703,
      0.05483711510896683,
      -0.031476520001888275,
      0.019381685182452202,
      0.04998594522476196,
      -0.010911630466580391,
      -0.0008418802754022181,
      0.10322099924087524,
      0.023897629231214523,
      0.024226749315857887,
      0.07680697739124298,
      -0.019522717222571373,
      0.007475805934518576,
      0.038351986557245255,
      -0.021897539496421814,
      -0.027762817218899727,
      -0.004333700984716415,
      -0.052993420511484146,
      -0.01048348844051361,
      0.09353163838386536,
      0.038706861436367035,
      -0.04467884078621864,
      0.05588170140981674,
      0.05265744403004646,
      -0.018257109448313713,
      -0.0031744297593832016,
      0.023627519607543945,
      0.009964399971067905,
      0.07172929495573044,
      -0.07433029264211655,
      -0.006594657897949219,
      0.015807099640369415,
      -0.036458663642406464,
      -0.006384722422808409,
      0.006522602867335081,
      -0.01721365563571453,
      0.027699150145053864,
      -0.04087523743510246,
      -0.05744174122810364,
      -0.030605625361204147,
      -0.0019141556695103645,
      -0.06843212246894836,
      -0.004988942760974169,
      -0.002273174934089184,
      0.018961627036333084,
      -0.005260446108877659,
      -0.04171900078654289,
      -0.04495308920741081,
      -0.1217387467622757,
      -0.00033066634205169976,
      0.08958636969327927,
      0.004396428354084492,
      0.023054763674736023,
      -0.09517671912908554,
      0.07081782072782516,
      -0.030193211510777473,
      0.06434229761362076,
      0.00011186935444129631,
      0.09858255833387375,
      0.04173913970589638,
      0.08073402941226959,
      -0.060643766075372696,
      0.05595232918858528,
      -0.035274118185043335,
      0.058264121413230896,
      -0.06438129395246506,
      0.08044864982366562,
      0.004764581564813852,
      -0.04407242685556412,
      -0.017793601378798485,
      0.014265667647123337,
      -0.09005274623632431,
      -0.022295311093330383,
      0.058738261461257935,
      0.03510509803891182,
      0.035759542137384415,
      0.02228487841784954,
      0.017510950565338135,
      -0.03686698153614998,
      -0.03475490212440491,
      -0.09230604767799377,
      -0.01657101698219776,
      0.05412517860531807,
      -0.03772996738553047,
      0.04252783954143524,
      0.009011499583721161,
      -0.0013135764747858047,
      -0.05473468825221062,
      -0.010062908753752708,
      -0.025259843096137047,
      -0.0418359600007534,
      0.011499064043164253,
      0.04158604517579079,
      0.0249871127307415,
      -0.024407977238297462,
      0.03337815776467323,
      -0.06314956396818161,
      0.037206586450338364,
      0.016478538513183594,
      -0.025429217144846916,
      0.008053984493017197,
      0.0027116367127746344,
      -0.019794216379523277,
      0.06415269523859024,
      0.06589572131633759,
      0.005787610076367855,
      -0.0021385883446782827,
      -0.01265866681933403,
      0.006342705339193344,
      -0.09706076234579086,
      -0.0055577862076461315,
      -0.018452070653438568,
      0.045977529138326645,
      -0.01510791014879942,
      0.009457805193960667,
      0.04972747340798378,
      -0.00823929999023676,
      0.012168374843895435,
      0.004874263424426317,
      0.012395203113555908,
      -0.028000066056847572,
      -0.050891902297735214,
      0.032073743641376495,
      0.05987820774316788,
      -0.0006882915622554719,
      0.01649842970073223,
      -0.03922737389802933,
      0.010237229987978935,
      0.01611235737800598,
      0.05340173840522766,
      0.07408684492111206,
      -0.017335092648863792,
      0.006477407645434141,
      -0.0574236698448658,
      0.009404409676790237,
      -0.05021675303578377,
      -0.04582332819700241,
      0.03653428331017494,
      0.009429605677723885,
      -0.023284606635570526,
      -0.04804873466491699,
      0.05411287769675255
    ],
    [
      -0.060791030526161194,
      -0.03493684157729149,
      -0.05156366527080536,
      0.0053936694748699665,
      -0.06730838119983673,
      0.014171653427183628,
      -0.008198701776564121,
      0.0864943116903305,
      -0.0017579958075657487,
      -0.005015845410525799,
      0.04503779113292694,
      0.037573304027318954,
      0.1585898995399475,
      0.02062578871846199,
      -0.03633818030357361,
      -0.01557200774550438,
      -0.020856650546193123,
      0.0009851506911218166,
      -0.011560274288058281,
      0.04365349933505058,
      -0.09996464848518372,
      0.01640394888818264,
      0.018960772082209587,
      -0.0012685302644968033,
      0.004360659513622522,
      -0.032731469720602036,
      -0.007525881286710501,
      -0.03447746858000755,
      0.03901393711566925,
      0.010464100167155266,
      -0.016131887212395668,
      -0.011605610139667988,
      -0.0785539522767067,
      -0.010245220735669136,
      -0.051881175488233566,
      0.10161922127008438,
      -0.02926955372095108,
      0.04731327295303345,
      0.015611743554472923,
      0.07611246407032013,
      -0.03765454515814781,
      -0.03936951234936714,
      -0.06226036325097084,
      -0.040753673762083054,
      -0.0013002061750739813,
      -0.03422510623931885,
      -0.013458133675158024,
      0.07217293977737427,
      -0.04106892645359039,
      0.03854747116565704,
      -0.0431581474840641,
      0.06337408721446991,
      0.0011321448255330324,
      -0.051352087408304214,
      0.055355511605739594,
      -0.032934535294771194,
      0.015101070515811443,
      0.06571710109710693,
      -0.09028839319944382,
      -0.01397766824811697,
      0.02899889275431633,
      0.05343593657016754,
      -0.006282813381403685,
      -0.0003131981357000768,
      -0.08146189898252487,
      0.02070681005716324,
      0.053500350564718246,
      -0.027366500347852707,
      -0.04399314895272255,
      -0.005212940741330385,
      -0.017762377858161926,
      -0.028942208737134933,
      0.010080795735120773,
      0.027192292734980583,
      -0.0404396615922451,
      0.05303121358156204,
      -0.03647135570645332,
      -0.007794087287038565,
      -0.07629723846912384,
      0.05471876263618469,
      0.08064153045415878,
      -0.06342907249927521,
      0.0571279339492321,
      0.06522155553102493,
      -0.046741146594285965,
      0.002115979790687561,
      0.031167693436145782,
      -0.020013978704810143,
      0.10720229893922806,
      0.03878108039498329,
      0.010010292753577232,
      -0.023841455578804016,
      0.04644261300563812,
      -0.015538476407527924,
      -0.02433457039296627,
      0.05935369431972504,
      -0.05195092782378197,
      -0.0062864855863153934,
      0.0017171638319268823,
      0.042973462492227554,
      -0.03786648437380791,
      0.001079982379451394,
      -0.06563621759414673,
      0.06235206127166748,
      0.04789373651146889,
      -0.01123461127281189,
      -0.12991875410079956,
      0.06957163661718369,
      0.020197799429297447,
      0.019596582278609276,
      0.0605025440454483,
      0.023818664252758026,
      -0.04024991765618324,
      -0.06899060308933258,
      0.0190969780087471,
      0.028913656249642372,
      -0.0149380536749959,
      -0.026547232642769814,
      0.028250573202967644,
      0.029570264741778374,
      -0.03179273009300232,
      -0.027649182826280594,
      -0.03002280369400978,
      -0.010716157034039497,
      0.06664998829364777,
      0.04076950624585152,
      -0.03544433042407036,
      -0.019526511430740356,
      -0.0030702706426382065,
      0.022522222250699997,
      -0.03390829265117645,
      -0.021202463656663895,
      -0.05414034426212311,
      -0.04021162912249565,
      -0.04687009006738663,
      -0.05999580770730972,
      0.06045820191502571,
      0.015830323100090027,
      -0.06614263355731964,
      0.0036457597743719816,
      0.05898427590727806,
      -0.014085879549384117,
      0.0567590557038784,
      -0.022732682526111603,
      -0.004148375242948532,
      0.06215674802660942,
      0.006420642137527466,
      0.059301476925611496,
      0.0041146716102957726,
      -0.0747569277882576,
      -0.004491494968533516,
      -0.0418328158557415,
      -0.0107709476724267,
      0.02085675299167633,
      -0.003775807097554207,
      0.0017777468310669065,
      0.0028286089655011892,
      0.0003824490704573691,
      -0.05291081219911575,
      0.004956522956490517,
      0.08171774446964264,
      0.10399812459945679,
      -0.13213320076465607,
      -0.0908355787396431,
      0.03363936394453049,
      0.004878731444478035,
      -0.02598644606769085,
      -0.006616945378482342,
      -0.03784799575805664,
      0.012517900206148624,
      -0.03003505989909172,
      0.01641504280269146,
      -0.08678912371397018,
      0.04143980145454407,
      0.06533893197774887,
      -0.008655646815896034,
      0.010777837596833706,
      -0.003187602385878563,
      -0.020361682400107384,
      -0.0025534958112984896,
      -0.0438024140894413,
      -0.03457609564065933,
      -0.010141666978597641,
      -0.03450145199894905,
      -0.04157739132642746,
      0.027274642139673233,
      0.01663232035934925,
      -0.0555465891957283,
      0.009570023976266384,
      0.0011217909632250667,
      -0.013685574755072594,
      0.03550251945853233,
      0.010020207613706589,
      0.07644211500883102,
      0.07386577874422073,
      0.03235848248004913,
      -0.09175808727741241,
      -0.13352085649967194,
      -0.019418615847826004,
      0.05302312597632408,
      0.026142418384552002,
      0.01100955531001091,
      0.00702452939003706,
      0.022068630903959274,
      -0.014675038866698742,
      0.0442381277680397,
      -0.10691536962985992,
      0.05985044687986374,
      0.023205196484923363,
      -0.035351816564798355,
      -0.02446349523961544,
      0.032382793724536896,
      0.0730471983551979,
      0.058869749307632446,
      -0.03533613309264183,
      0.01878347620368004,
      -0.014125483110547066,
      0.006211668718606234,
      0.12903140485286713,
      -0.03561147674918175,
      0.0731828510761261,
      0.0088875163346529,
      0.045034393668174744,
      0.01784786768257618,
      0.07437128573656082,
      0.03164651617407799,
      0.030734412372112274,
      -0.03337075561285019,
      0.044402893632650375,
      -0.04939228296279907,
      -0.0027426949236541986,
      0.05479758232831955,
      0.020979657769203186,
      0.07426725327968597,
      0.0051455688662827015,
      0.015045844949781895,
      -0.1076483204960823,
      0.03478454053401947,
      -0.036353059113025665,
      0.027084458619356155,
      0.010395001620054245,
      0.047285981476306915,
      -0.011962211690843105,
      -0.018361033871769905,
      0.054519303143024445,
      -0.004317348822951317,
      -0.03749917075037956,
      -0.025130324065685272,
      0.005431773141026497,
      -0.009561857208609581,
      -0.011775690130889416,
      -0.04610567167401314,
      0.061539698392152786,
      -0.014986551366746426,
      0.0010227211751043797,
      0.02814212441444397,
      0.01676228828728199,
      -0.024894554167985916,
      0.05556010827422142,
      -0.05232226848602295,
      -0.035569239407777786,
      0.038838282227516174,
      0.0441361628472805,
      0.06243601068854332,
      0.022939587011933327,
      -0.004352383315563202,
      -0.00906453188508749,
      0.02800237573683262,
      0.029218869283795357,
      0.07118124514818192,
      0.05045752599835396,
      0.09138461947441101,
      -0.04522423818707466,
      0.025544585660099983,
      0.05691038817167282,
      -0.011181861162185669,
      0.0779808759689331,
      0.04210413992404938,
      0.009470446035265923,
      -0.012834393419325352,
      -0.016642408445477486,
      -0.020075924694538116,
      0.03580319508910179,
      0.07047112286090851,
      -0.015200449153780937,
      -0.0311408881098032,
      -0.024356381967663765,
      -0.05966635420918465,
      0.06454185396432877,
      -0.0547785758972168,
      0.019892258569598198,
      0.06480690091848373,
      -0.0009133540443144739,
      0.008315468207001686,
      0.003930200356990099,
      0.02925696037709713,
      -0.038584962487220764,
      0.03943654149770737,
      -0.03159041702747345,
      -0.03227601572871208,
      -0.025468384847044945,
      -0.01860523782670498,
      0.058188874274492264,
      0.03988845646381378,
      -0.03483295440673828,
      -0.015604342333972454,
      -0.07986772805452347,
      -0.09130699187517166,
      0.04775010794401169,
      -0.07295433431863785,
      0.010662826709449291,
      0.018712246790528297,
      -0.04166708514094353,
      0.0459216870367527,
      0.026026522740721703,
      -0.025562332943081856,
      -0.010759259574115276,
      0.013876515440642834,
      0.025742148980498314,
      -0.003861019155010581,
      -0.0008617653511464596,
      0.03534902259707451,
      -0.0237649567425251,
      -0.034218717366456985,
      0.0268256813287735,
      -0.06286796182394028,
      -0.07234834879636765,
      -0.03718043863773346,
      -0.014132585376501083,
      -0.006273383274674416,
      0.0787951871752739,
      -0.035273030400276184,
      0.005316607188433409,
      -0.018197108060121536,
      0.012020393274724483,
      -0.02483230270445347,
      0.04728153347969055,
      0.0367235392332077,
      0.01251477375626564,
      0.030086088925600052,
      -0.02269623428583145,
      -0.010478213429450989,
      0.058399464935064316,
      0.03021916002035141,
      0.009587263688445091,
      0.021326500922441483,
      0.0007633070927113295,
      -0.008516636677086353,
      0.033314671367406845,
      0.03373037651181221,
      0.015472568571567535,
      -0.04958857595920563,
      -0.05377407371997833,
      -0.01771334744989872,
      -0.01007029414176941,
      -0.01861400529742241,
      -0.05019354075193405,
      -0.007364967837929726,
      -0.03604213893413544,
      0.05609899014234543,
      0.03884383291006088,
      -0.005489007104188204,
      -0.08342094719409943,
      -0.011524907313287258,
      -0.06031230092048645,
      0.0551050528883934,
      -0.08327610045671463,
      0.034529946744441986,
      -0.029451245442032814,
      -0.043956417590379715,
      0.03863537311553955,
      -0.01488752756267786,
      -0.017328273504972458,
      0.01717875897884369,
      0.023463860154151917,
      0.044074323028326035,
      -0.0322696715593338,
      0.054487407207489014,
      0.0076757012866437435,
      -0.011220483109354973,
      0.007022509817034006,
      0.051861681044101715,
      -0.003214932279661298,
      0.08859352022409439,
      -0.06483719497919083,
      -0.006637547630816698,
      0.05532115697860718,
      0.03720478713512421,
      -0.037471991032361984,
      0.08825782686471939,
      0.01652216911315918,
      -0.004261519759893417,
      0.007717471569776535,
      -0.010867362841963768,
      0.06629734486341476,
      0.09397788345813751,
      -0.04569268599152565,
      0.0007068065460771322,
      -0.02295072376728058,
      -0.022200020030140877,
      -0.005461201537400484,
      0.003956835716962814,
      0.04623580724000931,
      0.0583348423242569,
      -0.10659091919660568,
      0.016461046412587166,
      -0.02538098767399788,
      0.008429283276200294,
      -0.00653845863416791,
      -0.09899655729532242,
      0.013478964567184448,
      -0.002130502834916115,
      0.015224145725369453,
      -0.022056208923459053,
      0.038245636969804764,
      0.020565327256917953,
      0.026560788974165916,
      -0.08636382222175598,
      -0.028862355276942253,
      0.02314617671072483,
      0.08261807262897491,
      0.048701703548431396,
      0.0702555850148201,
      0.028648411855101585,
      -0.05746758356690407,
      0.045672908425331116,
      -0.00538676418364048,
      -0.045977555215358734,
      -0.0495307594537735,
      0.027608372271060944,
      -0.01262879092246294,
      0.051720526069402695,
      -0.006977941375225782,
      0.03491305559873581,
      0.019317612051963806,
      0.015710869804024696,
      0.07943455129861832,
      0.02655475214123726,
      0.03974556550383568,
      -0.051412612199783325,
      -0.05532696470618248,
      0.015427627600729465,
      0.03365020081400871,
      -0.012776183895766735,
      0.031971484422683716,
      -0.004100856836885214,
      0.005411603022366762,
      0.005006719846278429,
      0.0029468503780663013,
      -0.02580788917839527,
      -0.06805749237537384,
      0.048100534826517105,
      0.040345240384340286,
      -0.043789587914943695,
      0.005988153163343668,
      0.032075200229883194,
      0.008716678246855736,
      -0.11164427548646927,
      -0.007438650820404291,
      0.03446277230978012,
      -0.0278325192630291,
      -0.010971974581480026,
      -0.021775605157017708,
      0.11977837979793549,
      -0.06595429033041,
      0.01796634867787361,
      0.06046316400170326,
      -0.028968678787350655,
      0.05881631001830101,
      -0.06436105817556381,
      -0.04840957000851631,
      -0.007192237768322229,
      0.021102961152791977,
      0.03385992348194122,
      -0.03207377716898918,
      -0.11776191741228104,
      -0.011635607108473778,
      0.003219733014702797,
      -0.013938992284238338,
      0.025191448628902435,
      -0.04082126542925835,
      -0.026304299011826515,
      -0.02925572358071804,
      -0.016085729002952576,
      0.0253058522939682,
      -0.06493646651506424,
      0.046373795717954636,
      -0.015842463821172714,
      0.012646281160414219,
      -0.00656075868755579,
      0.09314695000648499,
      0.09988914430141449,
      -0.01855533756315708,
      0.018615923821926117,
      -0.0360000804066658,
      0.03646489232778549,
      0.05114287883043289,
      -0.019236667081713676,
      0.03094233013689518,
      0.01087158266454935,
      0.004529554862529039,
      0.009677388705313206,
      -0.004358493722975254
    ],
    [
      -0.10204501450061798,
      0.05724212899804115,
      -0.07519789785146713,
      0.054099030792713165,
      -0.01275648083537817,
      0.02672090008854866,
      0.011967645026743412,
      0.024332497268915176,
      -0.011848804540932178,
      -0.023618318140506744,
      -0.004693321418017149,
      0.05750085040926933,
      -0.002610839670524001,
      -0.009191968478262424,
      -0.014371425844728947,
      0.008235407061874866,
      0.0685967281460762,
      0.004347176291048527,
      -0.000888423528522253,
      -0.00568596925586462,
      0.1146126240491867,
      0.001283474499359727,
      0.0029645028989762068,
      -0.05846982076764107,
      0.033952586352825165,
      0.006123413797467947,
      0.013373265042901039,
      -0.07139617949724197,
      -0.0002806429984048009,
      0.06574802845716476,
      0.06666718423366547,
      0.06264958530664444,
      -0.028603309765458107,
      -0.008218642324209213,
      0.027829177677631378,
      -0.02921166829764843,
      -0.04538356140255928,
      -0.013127649202942848,
      0.0028604513499885798,
      0.05169657990336418,
      0.018201150000095367,
      0.0513153076171875,
      0.05240719020366669,
      0.038668349385261536,
      0.0038983027916401625,
      0.04903645068407059,
      0.006357695907354355,
      0.031777966767549515,
      -0.005116579122841358,
      -0.015965448692440987,
      -0.04310860484838486,
      -0.03265373781323433,
      -0.05238347873091698,
      0.010283377021551132,
      -0.01829403266310692,
      -0.02108411118388176,
      0.0032186482567340136,
      0.07764881104230881,
      0.07692594826221466,
      -0.038961827754974365,
      0.010515059344470501,
      -0.01670936867594719,
      -0.011195392347872257,
      0.030006231740117073,
      0.10583144426345825,
      -0.013999253511428833,
      -0.046315278857946396,
      -0.05148984491825104,
      -0.02638397365808487,
      0.04402662813663483,
      0.06706441193819046,
      0.03239431232213974,
      0.0915168821811676,
      0.05902738496661186,
      -0.029454389587044716,
      0.01752513088285923,
      -0.046725206077098846,
      -0.10833022743463516,
      -0.11411389708518982,
      0.013807916082441807,
      0.0548061840236187,
      0.07883168756961823,
      -0.027057921513915062,
      -0.035407911986112595,
      0.032362308353185654,
      -0.018916068598628044,
      0.08050619810819626,
      0.047461505979299545,
      0.015278175473213196,
      -0.0125665795058012,
      0.02839290350675583,
      -0.030826104804873466,
      -0.05872293561697006,
      -0.04313497617840767,
      0.007975024171173573,
      0.0655810534954071,
      0.11923832446336746,
      0.02074962481856346,
      0.016631612554192543,
      0.022223275154829025,
      -0.016966696828603745,
      0.02325163595378399,
      0.02416813187301159,
      0.016559738665819168,
      0.015952546149492264,
      0.035830944776535034,
      -0.06409963965415955,
      -0.029978523030877113,
      -0.013233923353254795,
      -0.046383295208215714,
      0.041803695261478424,
      -0.03595567122101784,
      -0.07697952538728714,
      -0.011868812143802643,
      -0.01912425272166729,
      -0.04742293432354927,
      0.07592377066612244,
      0.004821340553462505,
      0.039374444633722305,
      0.031343717128038406,
      -0.07526009529829025,
      -0.022476760670542717,
      0.04353401064872742,
      -0.09181257337331772,
      0.03611082211136818,
      -0.028491074219346046,
      0.049684394150972366,
      -0.001473220414482057,
      0.056768354028463364,
      -0.03515719994902611,
      0.045562513172626495,
      0.035643596202135086,
      0.019441921263933182,
      0.020743217319250107,
      -0.052067529410123825,
      0.10217911005020142,
      0.03239129111170769,
      0.004980064928531647,
      0.028572028502821922,
      -0.022295314818620682,
      -0.0067364308051764965,
      -0.061704106628894806,
      0.05156762897968292,
      -0.052434809505939484,
      -0.05145854502916336,
      -0.05691508203744888,
      -0.046979669481515884,
      -0.06260661035776138,
      0.08523336052894592,
      0.014416183345019817,
      0.026953453198075294,
      -0.007972507737576962,
      -0.03589938208460808,
      -0.025838347151875496,
      0.0022850167006254196,
      0.06456738710403442,
      0.0448073111474514,
      -0.07344584912061691,
      0.009781341068446636,
      -0.03023880161345005,
      0.06087007373571396,
      0.019386745989322662,
      0.024926524609327316,
      0.0395926833152771,
      0.05694284662604332,
      -0.015040763653814793,
      0.025006726384162903,
      0.018232082948088646,
      -9.53606104303617e-07,
      0.03834974765777588,
      -0.001641441835090518,
      0.05294663459062576,
      0.04355505108833313,
      0.07465238869190216,
      0.07120164483785629,
      0.023826060816645622,
      -0.07476775348186493,
      -0.030740369111299515,
      -0.0191954392939806,
      -0.0068715703673660755,
      -0.049092937260866165,
      -0.023528944700956345,
      0.03150735795497894,
      -0.0005808166461065412,
      0.001703370944596827,
      0.00011436210479587317,
      -0.02473667450249195,
      0.05366160720586777,
      -0.04599842429161072,
      -0.014539717696607113,
      0.07479570060968399,
      -0.08583410829305649,
      -0.08316965401172638,
      0.10581772029399872,
      -0.05896317958831787,
      0.0034859119914472103,
      -0.1042921245098114,
      -0.021251752972602844,
      -0.029357288032770157,
      -0.007744083181023598,
      0.0722845047712326,
      -0.10677457600831985,
      0.058514125645160675,
      -0.0185890793800354,
      0.08012647181749344,
      -0.014792528003454208,
      -0.05188487097620964,
      0.05228597670793533,
      -0.006408862303942442,
      -0.026307247579097748,
      -0.0023691807873547077,
      -0.03088383935391903,
      -0.018005404621362686,
      0.02230634167790413,
      0.048125553876161575,
      -0.07290119677782059,
      0.05897727236151695,
      -0.021579282358288765,
      0.04225021228194237,
      -0.022697946056723595,
      0.04242891073226929,
      0.04695122316479683,
      -0.038251642137765884,
      -0.01910567656159401,
      -0.02850651554763317,
      -0.04108937829732895,
      0.008263795636594296,
      -0.07036370038986206,
      -0.02990420162677765,
      0.0891679897904396,
      0.03035086952149868,
      0.05201397463679314,
      -0.033836327493190765,
      -0.0017406874103471637,
      0.014455100521445274,
      -0.011567529290914536,
      -0.01112047303467989,
      0.05284768342971802,
      -0.015941482037305832,
      -0.0021675443276762962,
      -0.06280422955751419,
      -0.013940844684839249,
      2.539019988034852e-05,
      0.0124727888032794,
      0.01334187388420105,
      -0.04923040792346001,
      -0.088995061814785,
      0.05053369700908661,
      0.023809505626559258,
      0.003121983492746949,
      -0.028421921655535698,
      -0.0541498064994812,
      0.05159613490104675,
      -0.03230101242661476,
      0.03396235406398773,
      -0.043196287006139755,
      -0.054506272077560425,
      0.07893874496221542,
      0.05164608731865883,
      -0.017732245847582817,
      0.0013082379009574652,
      -0.004819286055862904,
      0.04352138936519623,
      0.11733686178922653,
      -0.12647788226604462,
      -0.02597634494304657,
      0.07245473563671112,
      0.0049867890775203705,
      -0.06529849022626877,
      -0.009612240828573704,
      0.035046081990003586,
      -0.003664591582491994,
      0.09895304590463638,
      0.02943805605173111,
      -0.026916248723864555,
      -0.07675271481275558,
      -0.07924532890319824,
      0.06630489230155945,
      -0.05341476574540138,
      -0.02123631164431572,
      0.05059297755360603,
      -0.0389479361474514,
      -0.007371025159955025,
      0.006993439979851246,
      0.05230407416820526,
      0.07590406388044357,
      0.08271486312150955,
      -0.01802561990916729,
      0.01714015193283558,
      0.02102673612535,
      0.0192677304148674,
      -0.0787590816617012,
      -0.07856450229883194,
      -0.03017324209213257,
      0.03410002589225769,
      0.009110957384109497,
      0.01301030907779932,
      -0.10296347737312317,
      -0.008172442205250263,
      0.014227351173758507,
      -0.008923755958676338,
      -0.004192294552922249,
      0.018489671871066093,
      0.03383426368236542,
      0.07265788316726685,
      0.024464460089802742,
      0.05883077532052994,
      -0.020344479009509087,
      -0.06963925808668137,
      -0.030769022181630135,
      -0.044751983135938644,
      -0.012573283165693283,
      -0.04823938012123108,
      -0.06851793825626373,
      0.09215674549341202,
      -0.0022846043575555086,
      -0.040439628064632416,
      0.0036916504614055157,
      0.0535125769674778,
      0.004116682335734367,
      0.09264912456274033,
      0.013111434876918793,
      -0.03210928663611412,
      0.05670619010925293,
      -0.02822953090071678,
      -0.03310701251029968,
      0.07298558205366135,
      -0.00978777464479208,
      -0.016516461968421936,
      -0.06364958733320236,
      -0.03559558838605881,
      -0.02209056168794632,
      -0.012705409899353981,
      -0.011114525608718395,
      -0.10968125611543655,
      0.013285178691148758,
      -0.029724083840847015,
      -0.01589597389101982,
      0.016070788726210594,
      -0.0304146409034729,
      -0.09718533605337143,
      -0.05779164656996727,
      -0.0023299858439713717,
      0.054754067212343216,
      0.026995893567800522,
      0.09627380967140198,
      -0.027750559151172638,
      0.0038931514136493206,
      0.011309321969747543,
      0.028902577236294746,
      0.01796187087893486,
      -0.0444374606013298,
      -0.013185950927436352,
      0.014292421750724316,
      -0.014401900582015514,
      0.013767546974122524,
      -0.046119026839733124,
      0.006102829240262508,
      0.006077959667891264,
      -0.0019936999306082726,
      -0.048945438116788864,
      0.07290878146886826,
      0.03185633569955826,
      0.02822072245180607,
      -0.0352056622505188,
      0.020929723978042603,
      0.07597315311431885,
      -0.0564049556851387,
      0.029922066256403923,
      0.034571535885334015,
      0.014419618993997574,
      -0.030874725431203842,
      0.060355328023433685,
      -0.012837576679885387,
      0.05980558693408966,
      -0.004889246542006731,
      0.02494700439274311,
      -0.024656740948557854,
      0.00773006584495306,
      -0.023436784744262695,
      -0.07773924618959427,
      -0.025497615337371826,
      0.014772012829780579,
      -0.0385943241417408,
      0.0005622285534627736,
      0.012419775128364563,
      0.026403451338410378,
      0.022802229970693588,
      0.015288515016436577,
      0.059409987181425095,
      -0.0356530137360096,
      -0.009745136834681034,
      0.045182693749666214,
      -0.026463735848665237,
      0.04330528900027275,
      0.031993817538022995,
      -0.04911920800805092,
      -0.05824871361255646,
      -0.018762746825814247,
      -0.060914117842912674,
      0.020261311903595924,
      0.037475716322660446,
      0.12711721658706665,
      0.04349760338664055,
      -0.023249126970767975,
      0.04105392098426819,
      0.007555363234132528,
      -0.03819088637828827,
      -0.03970423713326454,
      -0.0527893491089344,
      0.00638761930167675,
      -0.09922722727060318,
      -0.016377374529838562,
      -0.019419560208916664,
      -0.053622592240571976,
      0.06484933942556381,
      -0.0036893493961542845,
      -0.09899932146072388,
      -0.05785418674349785,
      0.025431383401155472,
      -0.043616168200969696,
      0.011023947969079018,
      0.11575296521186829,
      -0.01403123140335083,
      0.0657290443778038,
      -0.07270251214504242,
      -0.0007023657090030611,
      -0.012940853834152222,
      0.013477245345711708,
      0.06372705847024918,
      0.032631631940603256,
      0.030123084783554077,
      0.03977707400918007,
      0.04485439509153366,
      -0.009366488084197044,
      -0.06819618493318558,
      0.03899512439966202,
      0.0017629037611186504,
      -0.04496170952916145,
      0.04488397017121315,
      0.06339380145072937,
      -0.05988994985818863,
      -0.042686428874731064,
      -0.0178251750767231,
      -0.03203319385647774,
      -0.042781271040439606,
      0.013076254166662693,
      -0.02433604560792446,
      -0.0289003886282444,
      -0.10188988596200943,
      0.04106574133038521,
      0.009371251799166203,
      0.007887151092290878,
      -0.03959037736058235,
      0.01821078546345234,
      -0.012764920480549335,
      0.045739445835351944,
      -0.010296194814145565,
      -0.05459153279662132,
      0.04146303981542587,
      0.07046203315258026,
      0.06422160565853119,
      0.01990864798426628,
      -0.0027695633471012115,
      0.002011671429499984,
      -0.05024588480591774,
      0.1373678594827652,
      0.04001809284090996,
      -0.008826743811368942,
      0.054910238832235336,
      0.03224550932645798,
      -0.01543342974036932,
      0.037677451968193054,
      0.029260974377393723,
      0.02957790531218052,
      -0.014309698715806007,
      0.06112322211265564,
      -0.05105196312069893,
      -0.08126501739025116,
      0.003748853923752904,
      -0.04101989045739174,
      -0.038486190140247345,
      -0.02403840981423855,
      0.11198890209197998,
      0.016957808285951614,
      0.09949193149805069,
      -0.0031601747032254934,
      0.08206748962402344,
      0.02430684305727482,
      -0.06608735024929047,
      0.04893368110060692,
      0.01680685579776764,
      0.031326740980148315,
      -0.01462477445602417,
      -0.017084117978811264,
      -0.047592341899871826,
      -0.037160202860832214,
      -0.06924601644277573,
      -0.05298089608550072,
      -0.04212108999490738,
      0.09491055458784103,
      0.012768677435815334,
      0.025384755805134773
    ],
    [
      0.06568431109189987,
      -0.023695386946201324,
      0.07123464345932007,
      -0.050840262323617935,
      0.06135719269514084,
      0.006603182293474674,
      -0.10839242488145828,
      0.036420438438653946,
      0.052613623440265656,
      0.049526363611221313,
      -0.021801427006721497,
      -0.06509993970394135,
      0.028023019433021545,
      0.03398910537362099,
      -0.12619565427303314,
      -0.06559769809246063,
      -0.024088110774755478,
      0.08413949608802795,
      0.025437572970986366,
      -0.03908522054553032,
      -0.0700237825512886,
      0.03305581957101822,
      0.03604365140199661,
      0.038908615708351135,
      -0.03453512117266655,
      0.022564806044101715,
      0.03676643595099449,
      -0.06858320534229279,
      -0.02739471010863781,
      0.026316288858652115,
      0.051712360233068466,
      -0.017617106437683105,
      0.0857938826084137,
      -0.0038132895715534687,
      -0.030211452394723892,
      0.011238452047109604,
      0.03133649379014969,
      0.03929635137319565,
      -0.03482288122177124,
      0.006324457004666328,
      0.01228706631809473,
      0.024296345189213753,
      -0.05609402433037758,
      -0.05477440729737282,
      0.015828436240553856,
      0.03964013606309891,
      0.09764325618743896,
      0.009664011187851429,
      0.030739882960915565,
      0.01475429069250822,
      0.031514495611190796,
      -0.013665632344782352,
      0.006720270030200481,
      0.07953592389822006,
      0.0638248473405838,
      -0.004302431829273701,
      0.03285495564341545,
      0.029288753867149353,
      -0.025323696434497833,
      0.09340920299291611,
      0.044669259339571,
      0.03789805620908737,
      0.0009475448750890791,
      -0.04521825537085533,
      0.018361704424023628,
      -0.040226493030786514,
      0.00020932688494212925,
      0.037894271314144135,
      0.014275830239057541,
      0.007836580276489258,
      -0.00410020537674427,
      -0.04304599016904831,
      -0.05574733391404152,
      -0.006905114743858576,
      0.03524569049477577,
      -0.05964066460728645,
      -0.014730999246239662,
      -0.02567823976278305,
      -0.04913799837231636,
      0.04041128233075142,
      0.01364358700811863,
      -0.026428669691085815,
      -0.028458433225750923,
      -0.037611495703458786,
      0.01718124747276306,
      0.003726901253685355,
      -0.007573861163109541,
      -0.006042620167136192,
      -0.0022917352616786957,
      0.03962007537484169,
      -0.00608065165579319,
      -0.0068677254021167755,
      -0.036518920212984085,
      -0.028879430145025253,
      0.030338168144226074,
      -0.05548259988427162,
      0.0035687973722815514,
      0.07024546712636948,
      0.1079632043838501,
      -0.04700916260480881,
      -0.025509489700198174,
      -0.003359041176736355,
      -0.013276362791657448,
      -0.005684465169906616,
      -0.040560826659202576,
      -0.034027617424726486,
      0.05346578359603882,
      0.04114498943090439,
      -0.07495157420635223,
      -0.08270129561424255,
      -0.018555276095867157,
      -0.10886702686548233,
      0.049126043915748596,
      -0.055177800357341766,
      0.004925466142594814,
      0.042755771428346634,
      0.02411782182753086,
      -0.018736429512500763,
      0.030147096142172813,
      -0.004246610216796398,
      -0.010551142506301403,
      -0.05938785523176193,
      0.031515493988990784,
      0.03966904431581497,
      0.09595394879579544,
      0.019927099347114563,
      0.03436937928199768,
      -0.0019036774756386876,
      0.037017710506916046,
      -0.04654412344098091,
      -0.012195668183267117,
      0.02313719131052494,
      0.012065118178725243,
      0.09581121057271957,
      0.0051851519383490086,
      -0.061203572899103165,
      -0.140519380569458,
      0.014117264188826084,
      -0.0865103155374527,
      -0.041932396590709686,
      -0.020570624619722366,
      -0.08350634574890137,
      -0.008761413395404816,
      0.026317672803997993,
      0.0221509151160717,
      -0.007849259302020073,
      -0.027113260701298714,
      -0.07691476494073868,
      -0.03572297841310501,
      -0.025156691670417786,
      -0.013413087464869022,
      0.007134671323001385,
      0.028886765241622925,
      -0.06862436234951019,
      -0.06561683863401413,
      -0.04392977058887482,
      0.12604650855064392,
      0.01473641861230135,
      0.01578642427921295,
      0.06897740811109543,
      0.005833604373037815,
      0.006642181891947985,
      -0.03295522928237915,
      0.10499323159456253,
      -0.008808446116745472,
      -0.0005955789820291102,
      -0.02432836778461933,
      0.0688428059220314,
      -0.05852479860186577,
      -0.04030395671725273,
      -0.0201997272670269,
      -0.03696323558688164,
      -0.013530218042433262,
      -0.046910930424928665,
      -0.010998657904565334,
      0.09411581605672836,
      -0.029578976333141327,
      -0.043725937604904175,
      0.021258223801851273,
      0.02296694926917553,
      -0.07998739928007126,
      0.036457888782024384,
      0.05741409212350845,
      0.06605756282806396,
      -0.0028536461759358644,
      0.060668423771858215,
      0.010986551642417908,
      0.009237119928002357,
      0.07964713126420975,
      0.02894924394786358,
      0.035130809992551804,
      -0.014044961892068386,
      0.011067287065088749,
      0.009669799357652664,
      0.003510568058118224,
      -0.039740245789289474,
      0.007633189205080271,
      0.0315428264439106,
      0.06438753008842468,
      -0.04564434289932251,
      0.009784904308617115,
      0.010722397826611996,
      -0.007656733505427837,
      0.05039149895310402,
      0.0101320780813694,
      -0.014823686331510544,
      0.014528710395097733,
      0.0010735722025856376,
      0.04854046180844307,
      0.03389953449368477,
      -0.0014466351130977273,
      0.10663586109876633,
      0.023442955687642097,
      0.06845340132713318,
      0.07807950675487518,
      0.023968590423464775,
      -0.0616091713309288,
      0.046498533338308334,
      0.048511337488889694,
      -0.013622808270156384,
      0.07997806370258331,
      0.04084532707929611,
      0.038062985986471176,
      0.04619358107447624,
      -0.030263343825936317,
      0.05091489478945732,
      0.023288093507289886,
      -0.020358353853225708,
      0.053730420768260956,
      -0.08724608272314072,
      -0.08134113252162933,
      0.11836261302232742,
      -0.028401687741279602,
      0.04564344137907028,
      0.039807189255952835,
      0.057380836457014084,
      -0.032243795692920685,
      -0.021617466583848,
      0.019819842651486397,
      0.004858326632529497,
      0.007069660350680351,
      0.08002122491598129,
      -0.006703644059598446,
      -0.030817847698926926,
      -0.027451802045106888,
      0.00322216865606606,
      -0.06626686453819275,
      0.0003922759206034243,
      -0.026831666007637978,
      -0.02818625047802925,
      0.03782035410404205,
      0.09429854899644852,
      0.02744666486978531,
      0.02350565791130066,
      0.0733141079545021,
      0.01979736052453518,
      -0.04047318175435066,
      -0.02664196491241455,
      -0.018150918185710907,
      0.012656896375119686,
      0.10311577469110489,
      0.05811847001314163,
      0.02445375733077526,
      -0.06317935883998871,
      -0.028273578733205795,
      0.024677248671650887,
      -0.045697711408138275,
      -0.06079022213816643,
      -0.010781198740005493,
      -0.01366553083062172,
      0.0039749545976519585,
      -0.06706061959266663,
      -0.03680778294801712,
      -0.0009629390551708639,
      -0.005429134238511324,
      -0.02358921989798546,
      0.06253496557474136,
      -0.0001479552302043885,
      0.011146636679768562,
      -0.038702789694070816,
      -0.03128413110971451,
      -0.011749926023185253,
      -0.01561592984944582,
      0.11143475770950317,
      0.04333389177918434,
      0.08610270917415619,
      0.02488643117249012,
      -0.015283554792404175,
      0.02241729199886322,
      -0.06290192157030106,
      0.0018972348188981414,
      0.03215688094496727,
      -0.011044664308428764,
      -0.041425418108701706,
      0.006990968249738216,
      -0.0486782006919384,
      -0.14180496335029602,
      0.03414048254489899,
      -0.028722362592816353,
      -0.06307658553123474,
      0.020809991285204887,
      -0.02744542993605137,
      -0.04059939458966255,
      -0.010650120675563812,
      -0.03787795826792717,
      0.08054324984550476,
      0.044013191014528275,
      0.05096429958939552,
      -0.018179381266236305,
      0.03614633530378342,
      -0.05668266862630844,
      -0.007240808103233576,
      0.045326847583055496,
      -0.036833759397268295,
      -0.000812463229522109,
      -0.04193868860602379,
      0.02462204359471798,
      -0.0727299228310585,
      0.016250962391495705,
      -0.010848971083760262,
      -0.016741447150707245,
      0.12404928356409073,
      0.09404746443033218,
      -0.03911126032471657,
      -0.02482481673359871,
      0.06173541024327278,
      -0.01159489993005991,
      0.04572710767388344,
      -0.02368753030896187,
      -0.007767127826809883,
      0.10474110394716263,
      -0.02753785066306591,
      -0.012139013968408108,
      -0.030737916007637978,
      0.04323098435997963,
      -0.020175278186798096,
      0.04341912642121315,
      0.10660737007856369,
      0.018489878624677658,
      -0.063877634704113,
      -0.04568854346871376,
      -0.06050141528248787,
      -0.04499080032110214,
      -0.019269054755568504,
      -0.009355511516332626,
      -0.05294108763337135,
      -0.04974847286939621,
      -0.02061639353632927,
      -0.06623800098896027,
      0.040719110518693924,
      0.034493088722229004,
      0.012034418992698193,
      0.01963520050048828,
      0.07165346294641495,
      0.03176937997341156,
      -0.09993937611579895,
      0.01121470332145691,
      -0.013380009680986404,
      0.019235048443078995,
      -0.014452986419200897,
      0.07650071382522583,
      -0.02094205841422081,
      -0.04929685965180397,
      -0.051552724093198776,
      -0.025293201208114624,
      0.0076173753477633,
      -0.020720403641462326,
      0.051828157156705856,
      -0.03162194415926933,
      -0.04749735817313194,
      0.02012542448937893,
      -0.011718416586518288,
      0.025219643488526344,
      -0.08269068598747253,
      -0.019733911380171776,
      -0.0024545358028262854,
      0.1018480435013771,
      -0.021180566400289536,
      0.03311911225318909,
      0.03168885409832001,
      -0.0024926881305873394,
      0.013310528360307217,
      -0.005472097545862198,
      0.023499367758631706,
      -0.017350858077406883,
      -0.09629090875387192,
      -0.03129639849066734,
      0.03817977011203766,
      0.01159612461924553,
      -0.027545925229787827,
      -0.08623185008764267,
      -0.005878911353647709,
      0.03881170228123665,
      -0.02495579794049263,
      -0.021630991250276566,
      -0.06019041687250137,
      0.06523808091878891,
      0.027759304270148277,
      -0.011709371581673622,
      -0.06854068487882614,
      0.03740576654672623,
      -0.09008464962244034,
      0.0017134541412815452,
      -0.04650020971894264,
      0.013611814007163048,
      -0.028318744152784348,
      0.04528238996863365,
      0.013425401411950588,
      -0.06172444298863411,
      0.08835224062204361,
      0.07082218676805496,
      -0.027060935273766518,
      -0.059233617037534714,
      -0.03655529394745827,
      0.004951701499521732,
      0.006755116395652294,
      -0.011571877636015415,
      0.0204666368663311,
      -0.10367650538682938,
      0.023782828822731972,
      -0.0031666592694818974,
      0.049582589417696,
      -0.05171655863523483,
      -0.03585531935095787,
      -0.013854826800525188,
      -0.0734332948923111,
      -0.027332806959748268,
      0.012114126235246658,
      -0.07835184782743454,
      -0.10765513777732849,
      -0.003835175884887576,
      -0.06089117005467415,
      -0.06137281656265259,
      -0.03216845542192459,
      0.010930494405329227,
      0.021143414080142975,
      -0.03165699169039726,
      -0.010682947002351284,
      -0.031694553792476654,
      0.0438988022506237,
      -0.004188023041933775,
      0.04307964816689491,
      0.02481662482023239,
      -0.0006291357567533851,
      0.012368583120405674,
      -0.01705782301723957,
      0.012685581110417843,
      0.0025854543782770634,
      -0.08587663620710373,
      -0.011163588613271713,
      -0.07171653211116791,
      0.003964871168136597,
      -0.011735471896827221,
      -0.008182560093700886,
      -0.03321642056107521,
      -0.017394622787833214,
      -0.014560106210410595,
      -0.0484248548746109,
      0.05720863491296768,
      0.07199537009000778,
      0.026427337899804115,
      0.04057928919792175,
      -0.031951580196619034,
      0.0012907438213005662,
      -0.041266851127147675,
      0.07783976942300797,
      0.07273819297552109,
      0.043206632137298584,
      0.0028179094661027193,
      0.009038055315613747,
      0.02437683939933777,
      0.009501302614808083,
      -0.03273549675941467,
      -0.04666135460138321,
      0.034579336643218994,
      -0.009613716043531895,
      0.021917663514614105,
      0.02202962338924408,
      0.024218136444687843,
      0.003579556941986084,
      -0.06894714385271072,
      0.002599106403067708,
      0.06925758719444275,
      0.02403924986720085,
      -0.024992050603032112,
      -0.025458086282014847,
      0.04297415539622307,
      0.05044598877429962,
      -0.021943021565675735,
      -0.12238076329231262,
      -0.09591461718082428,
      -0.01782294549047947,
      -0.0644778236746788,
      -0.03837738558650017,
      -0.017723463475704193,
      -0.04134056717157364,
      -0.005610405467450619,
      -0.0869484543800354,
      0.037877850234508514,
      0.05898735299706459,
      -0.0035911977756768465,
      0.008166354149580002,
      -0.08139296621084213
    ],
    [
      -0.041935257613658905,
      -0.04079722240567207,
      0.07430438697338104,
      -0.049168720841407776,
      -0.034414973109960556,
      -0.027676327154040337,
      0.011445053853094578,
      0.0036283573135733604,
      0.014425883069634438,
      -0.002038278616964817,
      0.07545295357704163,
      0.049751169979572296,
      -0.010163242928683758,
      -0.01689697802066803,
      -0.005020248703658581,
      0.001114963088184595,
      -0.06857693940401077,
      0.020179234445095062,
      0.07296144217252731,
      -0.0710587128996849,
      0.05561613664031029,
      -0.03810950741171837,
      -0.013877655379474163,
      -0.04210720583796501,
      0.0673665702342987,
      0.09891635179519653,
      -0.006350915413349867,
      -0.01740189455449581,
      0.06537657231092453,
      0.01729752868413925,
      -0.10832071304321289,
      -0.0012616557069122791,
      -0.03622044622898102,
      0.02029305137693882,
      0.06034301966428757,
      -0.035815246403217316,
      -0.014017803594470024,
      0.00704829441383481,
      -0.03462299704551697,
      0.03844723850488663,
      -0.0756840631365776,
      -0.013768698088824749,
      -0.045340076088905334,
      0.012779375538229942,
      -0.07957042753696442,
      -0.014972695149481297,
      0.0008630270604044199,
      0.014038143679499626,
      -0.043948352336883545,
      0.012168117798864841,
      0.00987218413501978,
      -0.024547135457396507,
      0.005148699041455984,
      0.03219323605298996,
      0.002990284701809287,
      -0.07147522270679474,
      0.043878018856048584,
      -0.02012270875275135,
      -0.07759633660316467,
      -0.0898871123790741,
      -0.05145783722400665,
      -0.023228516802191734,
      0.05455390363931656,
      0.029923874884843826,
      0.006798025220632553,
      -0.0020977240055799484,
      0.042829643934965134,
      0.06402072310447693,
      0.0001438771578250453,
      0.020660461857914925,
      -0.07095552235841751,
      -0.017019188031554222,
      0.005870080552995205,
      0.001540583441965282,
      -0.05156612768769264,
      0.021597256883978844,
      0.058051690459251404,
      -0.0156362596899271,
      0.018164409324526787,
      -0.002782356459647417,
      0.04258372262120247,
      0.08290832489728928,
      -0.01159944199025631,
      0.004329771734774113,
      0.05239403620362282,
      0.09927936643362045,
      -0.011972387321293354,
      0.06956984102725983,
      0.14586244523525238,
      -0.03238756209611893,
      -0.025017160922288895,
      -0.03732277452945709,
      -0.046327460557222366,
      -0.029899587854743004,
      -0.005404673051089048,
      0.004933737218379974,
      -0.02016264945268631,
      0.009799180552363396,
      0.04557595029473305,
      -0.020155517384409904,
      -0.004037241451442242,
      0.06050277501344681,
      -0.019384760409593582,
      -0.03805982694029808,
      -0.0021028630435466766,
      -0.010112720541656017,
      -0.06275949627161026,
      0.015920989215373993,
      -0.01810847781598568,
      0.026709964498877525,
      0.06797076016664505,
      0.052827928215265274,
      -0.03905105218291283,
      -0.07738503068685532,
      -0.02613191306591034,
      0.03301762789487839,
      0.09484969079494476,
      0.025332586839795113,
      -0.036317043006420135,
      0.1344538927078247,
      0.058570072054862976,
      0.000660253397654742,
      0.07182615995407104,
      0.03863218426704407,
      -0.03598053753376007,
      -0.030689412727952003,
      0.016604112461209297,
      -0.008414452895522118,
      0.07737381011247635,
      -0.01912110112607479,
      -0.02729889377951622,
      -0.03129280358552933,
      0.04586341232061386,
      -0.017827535048127174,
      -0.04591689258813858,
      0.0039034062065184116,
      0.017717070877552032,
      0.011594239622354507,
      0.0674111396074295,
      -0.029391109943389893,
      0.021121796220541,
      -0.0013905576197430491,
      -0.03121323697268963,
      -0.04145626723766327,
      0.019564060494303703,
      0.059901196509599686,
      -0.022951383143663406,
      0.02414899505674839,
      -0.040237680077552795,
      0.01765064336359501,
      -0.017196692526340485,
      -3.026678677997552e-05,
      0.029332194477319717,
      -0.01761164516210556,
      0.030332736670970917,
      -0.04239095374941826,
      0.07531990110874176,
      -0.04255344718694687,
      -0.009797339327633381,
      -0.028702780604362488,
      -0.019010992720723152,
      -0.022818882018327713,
      -0.09707223623991013,
      0.0007672770880162716,
      -0.06099478527903557,
      0.12050256133079529,
      -0.006509385071694851,
      0.017061302438378334,
      -0.020192382857203484,
      0.049730170518159866,
      0.0023630568757653236,
      -0.04050841182470322,
      -0.01760258711874485,
      0.03219248354434967,
      -0.030806541442871094,
      0.027128489688038826,
      0.022945355623960495,
      0.02321762777864933,
      -0.036671191453933716,
      0.0076041268184781075,
      -0.02243220992386341,
      0.010909276083111763,
      0.00564940320327878,
      0.006844323594123125,
      0.002013747813180089,
      -0.0758025124669075,
      0.011951006948947906,
      -0.05842311307787895,
      0.040016695857048035,
      0.020055891945958138,
      0.007492062170058489,
      0.031658437103033066,
      0.011387031525373459,
      0.09294996410608292,
      0.013259501196444035,
      0.016467181965708733,
      -0.03465671464800835,
      -0.023571349680423737,
      0.032421860843896866,
      -0.038641851395368576,
      0.020978376269340515,
      0.011889619752764702,
      0.04266592487692833,
      -0.00592793570831418,
      -0.04720025509595871,
      0.050083957612514496,
      -0.061456792056560516,
      -0.04102354496717453,
      0.03702480345964432,
      0.02732795476913452,
      0.06647878140211105,
      -0.03555263578891754,
      0.019912950694561005,
      -0.00544744124636054,
      -0.03265853598713875,
      0.0839671716094017,
      0.0016459731850773096,
      -0.018175488337874413,
      0.017056819051504135,
      -0.05009652301669121,
      0.06426264345645905,
      0.00045420596143230796,
      -0.0897885262966156,
      0.13210126757621765,
      -0.0058034928515553474,
      -0.02663435786962509,
      0.004342981148511171,
      0.008447140455245972,
      0.06075209006667137,
      -0.04586746171116829,
      -0.06460428982973099,
      0.08512155711650848,
      0.0014181676087900996,
      -0.017776627093553543,
      0.0312650240957737,
      0.06405768543481827,
      0.0468757338821888,
      -0.025216665118932724,
      -0.018558437004685402,
      0.06771936267614365,
      -0.044693708419799805,
      0.006042870227247477,
      -0.06813351809978485,
      -0.07806556671857834,
      0.027978649362921715,
      0.024746663868427277,
      -0.02447676844894886,
      0.01785343885421753,
      -0.009222428314387798,
      0.04006495699286461,
      0.0004694592207670212,
      -0.02649066224694252,
      -0.013193158432841301,
      -0.03606311231851578,
      0.01996760256588459,
      -0.06829962134361267,
      0.062099359929561615,
      -0.014468710869550705,
      0.0010871827835217118,
      0.03204070404171944,
      0.05147765949368477,
      -0.07695390284061432,
      0.058911655098199844,
      -0.00731617072597146,
      -0.011963775381445885,
      0.0041315993294119835,
      -0.02208913490176201,
      -0.07135096937417984,
      0.03364945203065872,
      0.01980310119688511,
      -0.03798794746398926,
      0.012394184246659279,
      -0.02325608767569065,
      0.03147940710186958,
      0.07626623660326004,
      -0.038800399750471115,
      -0.030245495960116386,
      0.007157968822866678,
      -0.05235861614346504,
      0.09394007176160812,
      -0.0005296796443872154,
      -0.10155633836984634,
      -0.006265658885240555,
      -0.07199755311012268,
      -0.023533660918474197,
      -0.015332543291151524,
      -0.028381170704960823,
      -0.03132537752389908,
      -0.04024701192975044,
      0.02117556892335415,
      0.07482552528381348,
      0.02964809350669384,
      -0.03264297917485237,
      -0.06810835748910904,
      -0.0066236224956810474,
      -0.016801321879029274,
      0.017665470018982887,
      0.02072462998330593,
      0.06754293292760849,
      -0.019018249586224556,
      0.05078617110848427,
      -0.006441950332373381,
      0.07496393471956253,
      0.00310314423404634,
      0.0011378505732864141,
      -0.030291687697172165,
      -0.04194522276520729,
      -0.0018574086716398597,
      0.0037037681322544813,
      0.03839801624417305,
      0.03108569048345089,
      -0.046775560826063156,
      -0.02690167725086212,
      -0.037018612027168274,
      -0.005430964753031731,
      0.008050098083913326,
      0.05370213836431503,
      0.06431135535240173,
      -0.03223443776369095,
      0.07553447037935257,
      0.04236079007387161,
      -0.0058775367215275764,
      0.04009044170379639,
      0.007949071936309338,
      0.0034114550799131393,
      -0.009348394349217415,
      -0.0316697359085083,
      -0.03523899242281914,
      0.004889029543846846,
      -0.07277559489011765,
      -0.005939428228884935,
      0.01683807745575905,
      -0.007832524366676807,
      -0.11142551898956299,
      -0.0015651864232495427,
      -0.04790741205215454,
      -0.03491292893886566,
      0.05531734973192215,
      -0.03249622881412506,
      0.11320481449365616,
      0.005299799144268036,
      0.021832462400197983,
      -0.04264141991734505,
      -0.020480729639530182,
      0.06775964796543121,
      -0.061917733401060104,
      -0.07412408292293549,
      0.042505502700805664,
      0.10454011708498001,
      -0.06775251775979996,
      -0.027923015877604485,
      -0.01457240991294384,
      -0.02916795015335083,
      0.022365957498550415,
      0.015352879650890827,
      -0.0789913460612297,
      -0.03568994253873825,
      0.003943664021790028,
      0.022062577307224274,
      -0.06483141332864761,
      -0.057871825993061066,
      -0.020120326429605484,
      0.05247174948453903,
      -0.06034907326102257,
      -0.03784669190645218,
      0.03755858168005943,
      -0.006625349633395672,
      0.013588902540504932,
      0.04825286194682121,
      -0.001858968404121697,
      0.04541050270199776,
      -0.03736618533730507,
      -0.028729194775223732,
      0.05016746744513512,
      -0.0020369745325297117,
      0.047702208161354065,
      -0.0022572544403374195,
      0.003876975504681468,
      0.01334446296095848,
      -0.014500953257083893,
      -0.01761672832071781,
      -0.011680250987410545,
      0.04034857079386711,
      -0.02259209379553795,
      -0.03132027015089989,
      0.022472018375992775,
      -0.09612937271595001,
      -0.01970355398952961,
      -0.058254990726709366,
      -0.006363311782479286,
      0.033037103712558746,
      0.03895741328597069,
      -0.06283418089151382,
      0.041374050080776215,
      -0.08187197148799896,
      0.08642467111349106,
      -0.011988263577222824,
      -0.044328562915325165,
      0.06620356440544128,
      -0.030783604830503464,
      0.03218020126223564,
      -0.008393142372369766,
      0.023101327940821648,
      0.013206031173467636,
      0.0368797592818737,
      0.011209392920136452,
      0.05559257045388222,
      0.009171141311526299,
      0.02764192409813404,
      -0.04748137295246124,
      -0.024783223867416382,
      -0.056435924023389816,
      -0.030460670590400696,
      0.036293528974056244,
      0.0163918174803257,
      0.027754824608564377,
      -0.013168875128030777,
      -0.033391475677490234,
      0.021868472918868065,
      -0.04430999234318733,
      0.012659043073654175,
      0.043385908007621765,
      0.046440474689006805,
      -0.10669277608394623,
      0.07135123759508133,
      -0.000659893499687314,
      0.020434444770216942,
      -0.05516616255044937,
      -0.022014934569597244,
      0.054050788283348083,
      0.03833349049091339,
      -0.0057561369612813,
      0.010363039560616016,
      0.06167512387037277,
      -0.006243069190531969,
      0.018466223031282425,
      0.030301030725240707,
      -0.0032754484564065933,
      -0.008403361774981022,
      -0.03080863133072853,
      -0.01449150312691927,
      -0.0032887891866266727,
      -0.002810137113556266,
      0.030411668121814728,
      0.08418343216180801,
      0.02474396862089634,
      -0.05410574749112129,
      -0.07599253952503204,
      -0.0666578933596611,
      0.023328043520450592,
      -0.011975329369306564,
      -0.03336978703737259,
      0.005055694375187159,
      -0.045197419822216034,
      0.01840384677052498,
      0.043595295399427414,
      0.030749160796403885,
      0.02399885468184948,
      0.020166197791695595,
      -0.06218529865145683,
      -0.004171845968812704,
      0.06728080660104752,
      -0.027121800929307938,
      0.014963188208639622,
      -0.00023440373479388654,
      -0.03434055298566818,
      0.035297032445669174,
      -0.0562690831720829,
      0.010283365845680237,
      0.04293511435389519,
      0.07076317816972733,
      -0.02384798787534237,
      0.005639038048684597,
      -0.05341926962137222,
      -0.043722428381443024,
      -0.07241848856210709,
      0.02310006693005562,
      0.024834154173731804,
      -0.04189170524477959,
      0.007834311574697495,
      -0.0030141943134367466,
      0.028554921969771385,
      -0.0038559718523174524,
      0.023761166259646416,
      -0.07647980749607086,
      0.03546399995684624,
      0.001846203813329339,
      -0.044232435524463654,
      0.04073575511574745,
      -0.09736236929893494,
      0.027882982045412064,
      0.06711548566818237,
      -0.05246589332818985,
      0.0486503429710865,
      -0.058134254068136215,
      -0.012026157230138779,
      0.038505274802446365,
      -0.1045791506767273,
      0.060970284044742584,
      -0.012349257245659828,
      -0.06840872019529343,
      0.005399888847023249,
      0.01089140772819519
    ],
    [
      0.029971648007631302,
      -0.06422187387943268,
      0.027340494096279144,
      -0.0336446613073349,
      0.06568234413862228,
      -0.01978716440498829,
      -0.041700806468725204,
      -0.04451828449964523,
      0.010302680544555187,
      -0.07132786512374878,
      -0.07183728367090225,
      -0.003049298655241728,
      -0.01223774254322052,
      -0.0354720875620842,
      0.0034388036001473665,
      0.042103417217731476,
      -0.04413478076457977,
      0.001192850642837584,
      -0.05134238675236702,
      -0.004365304950624704,
      -0.06443963199853897,
      -0.03648548573255539,
      -0.03596779704093933,
      0.07474833726882935,
      0.05426769331097603,
      0.05233166739344597,
      -0.028185604140162468,
      0.041334208101034164,
      -0.003973179031163454,
      0.018580662086606026,
      0.02623484842479229,
      0.08549156785011292,
      -0.025121549144387245,
      0.01880037970840931,
      -0.05684187263250351,
      -0.0019907052628695965,
      -0.014410600997507572,
      0.01518730353564024,
      0.06898429244756699,
      0.02944117970764637,
      -0.0135022709146142,
      -0.0783701241016388,
      0.027895933017134666,
      -0.0025427297223359346,
      -0.022003376856446266,
      0.003956010099500418,
      0.04117577522993088,
      0.041023869067430496,
      0.03291136026382446,
      -0.01861637830734253,
      0.08571353554725647,
      0.010463014245033264,
      -0.005636908113956451,
      0.011294513940811157,
      0.013192199170589447,
      -0.008550736121833324,
      0.05261010676622391,
      -0.022172246128320694,
      -0.01128591038286686,
      0.028424318879842758,
      0.014296765439212322,
      -0.012866822071373463,
      0.01442564744502306,
      -0.05563671141862869,
      0.05591403320431709,
      -0.017896296456456184,
      0.04735462740063667,
      -0.05556783452630043,
      -0.0018954658880829811,
      0.005435934290289879,
      -0.011579911224544048,
      -0.005111623555421829,
      0.022929076105356216,
      0.02237020619213581,
      0.002255059778690338,
      -0.05579672008752823,
      -0.07515285164117813,
      -0.03335050120949745,
      0.05564247444272041,
      0.021206079050898552,
      -0.015592310577630997,
      -0.020151499658823013,
      0.055917639285326004,
      0.01956646889448166,
      0.00640554865822196,
      -0.09044358134269714,
      -0.02101716212928295,
      0.013417859561741352,
      -0.0381004624068737,
      0.028913630172610283,
      0.00978099275380373,
      0.016912542283535004,
      -0.053897466510534286,
      -0.006069180089980364,
      0.03220874443650246,
      0.026655256748199463,
      -0.0011870034504681826,
      0.011249355040490627,
      -0.08683361113071442,
      0.06521091610193253,
      -0.019014086574316025,
      0.02102895826101303,
      -0.034977711737155914,
      -0.019612044095993042,
      -0.004241003189235926,
      0.004130859859287739,
      0.11279506236314774,
      -0.10446377843618393,
      -0.01940826326608658,
      0.016025399789214134,
      -0.06077702343463898,
      0.008617525920271873,
      0.0006859341519884765,
      -0.07686128467321396,
      0.007256114389747381,
      0.04679041728377342,
      0.05002607777714729,
      -0.04438621178269386,
      0.09378619492053986,
      0.0109706437215209,
      -0.04981328919529915,
      0.022745192050933838,
      0.05429687350988388,
      0.051812298595905304,
      0.01807667687535286,
      0.024635881185531616,
      0.02341724932193756,
      0.0018238964257761836,
      0.06900399923324585,
      0.05326142534613609,
      -0.018325865268707275,
      -0.07347095757722855,
      -0.018927888944745064,
      -0.055322788655757904,
      0.06419651210308075,
      -0.0007987331482581794,
      -0.0214270930737257,
      -0.009970746003091335,
      -0.03445432707667351,
      -0.027335843071341515,
      -0.10356488823890686,
      0.016967525705695152,
      -0.0285098385065794,
      0.03871763497591019,
      -0.04969244450330734,
      0.05895424261689186,
      0.029492583125829697,
      -0.09560181945562363,
      -0.06013569235801697,
      -0.05553249642252922,
      -0.01103023998439312,
      0.002249437849968672,
      0.008923690766096115,
      -0.06617473810911179,
      -0.015944743528962135,
      0.022221945226192474,
      0.0352332629263401,
      0.023432657122612,
      -0.012649132870137691,
      -0.0651179626584053,
      -0.007906231097877026,
      0.09543055295944214,
      0.018305612727999687,
      0.007379067596048117,
      -0.021405760198831558,
      -0.07014845311641693,
      0.025354329496622086,
      -0.02854306809604168,
      -0.02922309748828411,
      0.05659991130232811,
      0.010992529802024364,
      -0.017712950706481934,
      0.015467938035726547,
      0.04807755723595619,
      -0.06655008345842361,
      -0.014230750501155853,
      -0.01979682594537735,
      -0.05715666711330414,
      0.0856504887342453,
      0.008730496279895306,
      -0.005056274123489857,
      0.058799706399440765,
      0.036718957126140594,
      0.05522799864411354,
      -0.0009699822403490543,
      0.0010491162538528442,
      0.0308757945895195,
      0.036639489233493805,
      -0.04580403491854668,
      -0.08476739376783371,
      -0.0006278233486227691,
      -0.05034591630101204,
      -0.05004142224788666,
      -0.027756722643971443,
      -0.022163784131407738,
      0.006608998402953148,
      0.0165767353028059,
      -0.11418184638023376,
      0.0037118536420166492,
      0.03156157210469246,
      -0.0602206289768219,
      0.04342405125498772,
      0.05359780788421631,
      0.046354636549949646,
      0.0003602291108109057,
      0.015178538858890533,
      0.0131641561165452,
      -0.06827955693006516,
      -0.053747132420539856,
      -0.00437167938798666,
      -0.05786038190126419,
      0.02610529400408268,
      0.0025079387705773115,
      -0.0916815921664238,
      0.04620879143476486,
      0.00998502317816019,
      -0.05044564604759216,
      0.041256945580244064,
      0.06065737456083298,
      0.05067921802401543,
      -0.047097355127334595,
      -0.10566602647304535,
      -0.060455165803432465,
      0.01406343188136816,
      0.044459935277700424,
      0.07115120440721512,
      0.005897419061511755,
      -0.018672969192266464,
      0.001214360585436225,
      0.0004282790469005704,
      -0.08628932386636734,
      0.09144384413957596,
      0.1453581154346466,
      -0.01971108838915825,
      0.0012674119789153337,
      -0.05093234404921532,
      -0.010753320530056953,
      -0.04286044090986252,
      -0.01128270011395216,
      0.06409520655870438,
      -0.014101934619247913,
      0.00290955463424325,
      -0.07181663066148758,
      -0.1227845773100853,
      0.016981851309537888,
      -0.026435988023877144,
      -0.003261455101892352,
      -0.03608148172497749,
      0.004395824857056141,
      -0.01858573965728283,
      0.017498554661870003,
      -0.018232865259051323,
      -0.021435342729091644,
      -0.028370805084705353,
      0.10034215450286865,
      -0.03296215832233429,
      0.017732510343194008,
      -0.07488343119621277,
      0.023811399936676025,
      -0.039736177772283554,
      0.04929182678461075,
      -0.008694483898580074,
      -0.04374421387910843,
      0.024972708895802498,
      0.05211818218231201,
      -0.01131755206733942,
      0.07074718177318573,
      0.04444422945380211,
      0.00522017665207386,
      0.06030683219432831,
      -0.00218199472874403,
      -0.0060028014704585075,
      0.013288167305290699,
      -0.07994607090950012,
      -0.007197392173111439,
      -0.03210420161485672,
      0.007809904403984547,
      -0.03249242529273033,
      0.022110626101493835,
      0.033397648483514786,
      0.05945425480604172,
      -0.007274773437529802,
      -0.0072312927804887295,
      -0.017249492928385735,
      -0.07424379885196686,
      0.031925495713949203,
      0.05435730144381523,
      -0.05140911042690277,
      0.08061244338750839,
      0.031242579221725464,
      0.016955839470028877,
      0.034785497933626175,
      -0.09534081816673279,
      -0.10176379978656769,
      0.037058815360069275,
      -0.04071033373475075,
      0.05950993299484253,
      0.04425778239965439,
      -0.025560205802321434,
      -0.004452290013432503,
      0.018059778958559036,
      0.08777285367250443,
      -0.017392512410879135,
      0.06806985288858414,
      -0.030945464968681335,
      0.058639246970415115,
      0.02695958875119686,
      -0.043345753103494644,
      0.050671983510255814,
      0.011215565726161003,
      -0.044394563883543015,
      -0.015345660969614983,
      -0.08300699293613434,
      -0.050896234810352325,
      -0.02361343987286091,
      0.04507698863744736,
      0.016030671074986458,
      -0.007942047901451588,
      0.008916687220335007,
      0.018672842532396317,
      -0.08441200107336044,
      0.03418254107236862,
      0.022007988765835762,
      0.04544069245457649,
      0.09585461765527725,
      0.05177628621459007,
      -0.025459550321102142,
      0.0319884717464447,
      0.023716194555163383,
      -0.07323656976222992,
      0.032194267958402634,
      -0.05088541656732559,
      0.035259705036878586,
      0.009720733389258385,
      -0.03663470596075058,
      0.0027343309484422207,
      0.02940014936029911,
      -0.011087696067988873,
      0.03017283044755459,
      -0.03701090067625046,
      0.05272301658987999,
      0.011760879307985306,
      0.07116461545228958,
      0.0002915499499067664,
      0.07259070873260498,
      0.022214654833078384,
      -0.003314610105007887,
      -0.040955446660518646,
      -0.04508637264370918,
      0.04956166073679924,
      0.03347642719745636,
      0.0017613566014915705,
      0.03592309355735779,
      0.016056176275014877,
      -0.0039179883897304535,
      -0.052406005561351776,
      -0.03702189028263092,
      -0.0005064012948423624,
      0.0404491201043129,
      -0.11967117339372635,
      0.025186248123645782,
      0.018857987597584724,
      -0.042115468531847,
      -0.007883219979703426,
      0.04567037895321846,
      -0.017435899004340172,
      -0.006280419882386923,
      0.015099582262337208,
      0.025747232139110565,
      -0.041511282324790955,
      0.014788295142352581,
      -0.015811074525117874,
      0.016435202211141586,
      0.030085181817412376,
      -0.01549866609275341,
      0.035084787756204605,
      -0.03080820105969906,
      -0.03404822200536728,
      -0.01744116097688675,
      -0.0058657824993133545,
      -0.011435710825026035,
      -0.02286663092672825,
      -0.04750363901257515,
      -0.008497132919728756,
      -0.001921441056765616,
      -0.0723099559545517,
      -0.00010600466339383274,
      -0.015550884418189526,
      0.02492615394294262,
      0.04029717296361923,
      -0.04795629903674126,
      0.02357208915054798,
      0.13522452116012573,
      0.014339663088321686,
      -0.003508982714265585,
      0.14700020849704742,
      0.09370147436857224,
      0.0209194328635931,
      -0.06279730796813965,
      -0.05933918058872223,
      0.014339514076709747,
      0.09980756044387817,
      -0.016428900882601738,
      0.014722764492034912,
      -0.009309965185821056,
      -0.08364712446928024,
      -0.06970447301864624,
      0.036857448518276215,
      0.07304870337247849,
      -0.0944865420460701,
      0.05858995020389557,
      -0.014472553506493568,
      0.054603222757577896,
      -0.006570359691977501,
      -0.0007104810792952776,
      0.05358651652932167,
      0.04659652337431908,
      -0.04845770075917244,
      -0.026047993451356888,
      -0.016125336289405823,
      0.010715565644204617,
      -0.04094194620847702,
      -0.09793134033679962,
      -0.006096863187849522,
      -0.0038732150569558144,
      -0.09140659123659134,
      0.029634902253746986,
      -0.03753562644124031,
      -0.057496488094329834,
      0.012482530437409878,
      -0.015107834711670876,
      0.05244051665067673,
      -0.033939164131879807,
      -0.017123742029070854,
      0.03343408182263374,
      -0.008917062543332577,
      0.03751828894019127,
      -0.044267602264881134,
      -0.013906197622418404,
      -0.001747487927787006,
      -0.01142748910933733,
      0.009419463574886322,
      -0.025845687836408615,
      0.05270254611968994,
      -0.01112337689846754,
      -0.06759414821863174,
      -0.03808802366256714,
      -0.0031570568680763245,
      0.0604880191385746,
      0.020250702276825905,
      -0.020337823778390884,
      0.03253447636961937,
      -0.019016826525330544,
      -0.053069088608026505,
      -0.0019142086384817958,
      0.012039296329021454,
      0.09068971872329712,
      -0.06778567284345627,
      -0.035004399716854095,
      -0.015245662070810795,
      0.014271125197410583,
      0.023483820259571075,
      -0.05749347805976868,
      0.006960931699723005,
      0.04308615252375603,
      0.011905363760888577,
      0.032806701958179474,
      -0.06708147376775742,
      -2.5418190489290282e-05,
      -0.021759474650025368,
      0.03431227430701256,
      0.034789878875017166,
      0.07916681468486786,
      -0.04389646276831627,
      -0.07601350545883179,
      0.028555704280734062,
      0.008485621772706509,
      -0.01564452238380909,
      -0.0032290311064571142,
      0.025694463402032852,
      0.016803748905658722,
      -0.0382239930331707,
      0.07615313678979874,
      0.006430836860090494,
      0.019674569368362427,
      0.07394243031740189,
      -0.09293511509895325,
      0.0044143470004200935,
      0.021110890433192253,
      0.01793130300939083,
      0.021454960107803345,
      0.032769825309515,
      -0.03252050280570984,
      0.03088776208460331,
      0.020677367225289345,
      -0.008332946337759495,
      -0.0029648926574736834,
      -0.0903257504105568,
      -0.05155940353870392,
      0.05947680026292801,
      0.028775913640856743,
      -0.05541147664189339,
      -0.008154217153787613
    ],
    [
      -0.025504078716039658,
      0.0722026377916336,
      -0.05715697258710861,
      -0.07576412707567215,
      0.025307167321443558,
      -0.050228722393512726,
      -0.048311203718185425,
      0.005653277039527893,
      -0.050925277173519135,
      -0.10739455372095108,
      -0.09000864624977112,
      0.027621589601039886,
      0.05887573957443237,
      0.003312188433483243,
      0.02507157064974308,
      0.04471831023693085,
      0.013642512261867523,
      -0.0637359693646431,
      -0.005011302884668112,
      0.05911620706319809,
      -0.017375530675053596,
      -0.017257289960980415,
      0.012871388345956802,
      0.059067502617836,
      -0.014534767717123032,
      0.074542336165905,
      0.04167598485946655,
      -0.04755329713225365,
      0.00015298736980184913,
      -0.020030463114380836,
      0.005319864023476839,
      -0.02376680262386799,
      -0.007746533956378698,
      -0.04785677790641785,
      -0.03048199787735939,
      0.021915508434176445,
      -0.050798606127500534,
      -0.044692572206258774,
      -0.008589754812419415,
      0.057169679552316666,
      -0.03350106626749039,
      0.048183880746364594,
      -0.10483090579509735,
      0.03552550449967384,
      -0.07028494775295258,
      -0.04868743568658829,
      -0.07933001220226288,
      -0.07806552946567535,
      -0.0785631462931633,
      0.04871280863881111,
      0.0789116621017456,
      -0.0009431926882825792,
      -0.07487933337688446,
      -0.012251507490873337,
      0.0751105397939682,
      -0.024755463004112244,
      0.015246222727000713,
      -0.037453800439834595,
      -0.03214503079652786,
      0.013207710348069668,
      -0.05545080825686455,
      0.06578897684812546,
      0.014493477530777454,
      0.03372398391366005,
      -0.13506324589252472,
      -0.07716555148363113,
      -0.0393950529396534,
      0.012838805094361305,
      0.07997306436300278,
      0.02734995260834694,
      -0.08216814696788788,
      0.026252157986164093,
      -0.053878095000982285,
      -0.08868634700775146,
      -0.039480507373809814,
      -0.00854093860834837,
      0.015896357595920563,
      0.04881953448057175,
      -0.08924785256385803,
      -0.003534916555508971,
      0.025076361373066902,
      0.07566256076097488,
      0.051919106394052505,
      -0.004325673915445805,
      -0.03903739154338837,
      0.03792458027601242,
      0.02726415917277336,
      -0.02804945968091488,
      0.05999784171581268,
      -0.009472348727285862,
      0.0664530023932457,
      -0.08008535206317902,
      -0.013398947194218636,
      0.04001081734895706,
      -0.08209098130464554,
      -0.10062288492918015,
      -0.019648196175694466,
      0.05435725301504135,
      0.05942707136273384,
      -0.027010982856154442,
      0.08836299926042557,
      -0.06638338416814804,
      -0.07666394114494324,
      0.0394086018204689,
      -0.04939497262239456,
      -0.0664554014801979,
      -0.030838927254080772,
      -0.07644040137529373,
      -0.014660601504147053,
      -0.021294930949807167,
      -0.012986256740987301,
      0.0025236373767256737,
      -0.039186783134937286,
      -0.06142323464155197,
      -0.009571396745741367,
      -0.002008433686569333,
      -0.05166352167725563,
      -0.002031829906627536,
      -0.00517713837325573,
      0.005298730451613665,
      -0.013730255886912346,
      0.005887940991669893,
      -0.06790343672037125,
      0.023167507722973824,
      0.10313094407320023,
      0.0036017997190356255,
      -0.07076840847730637,
      0.018564732745289803,
      0.10799849778413773,
      -0.007451192941516638,
      0.021986868232488632,
      0.014426241628825665,
      -0.07552213221788406,
      -0.007788556627929211,
      0.07622259110212326,
      -0.03620913252234459,
      -0.06642118841409683,
      -0.06118568778038025,
      -0.0977216586470604,
      -0.024860301986336708,
      -0.03030463121831417,
      -0.07061269134283066,
      0.07192627340555191,
      0.034801267087459564,
      0.022247320041060448,
      -0.012722688727080822,
      0.02826670929789543,
      0.01853257603943348,
      -0.10152996331453323,
      0.02374306134879589,
      -0.06463120877742767,
      -0.029698600992560387,
      -0.02340717241168022,
      -0.05970180407166481,
      0.04940655082464218,
      0.04066568240523338,
      0.0356423445045948,
      -0.056206826120615005,
      -0.02392585389316082,
      -0.0013096542097628117,
      0.09824898838996887,
      0.014434153214097023,
      0.013188064098358154,
      0.10731537640094757,
      -0.04605851322412491,
      -0.03389950469136238,
      0.00921704899519682,
      -0.0014992853393778205,
      -0.0165206678211689,
      0.03416351228952408,
      0.03700286149978638,
      0.04201817885041237,
      -0.01614290662109852,
      0.03624820336699486,
      -0.08179131150245667,
      0.030933324247598648,
      -0.02199581451714039,
      0.047826267778873444,
      -0.08897227048873901,
      0.07729930430650711,
      0.016480417922139168,
      0.01515495590865612,
      0.07214454561471939,
      0.006116750184446573,
      0.08917225152254105,
      -0.009080948308110237,
      0.007955172099173069,
      0.051362503319978714,
      -0.04405849426984787,
      -0.006775354500859976,
      0.04143299162387848,
      -0.042011063545942307,
      -0.08422762155532837,
      -0.007458928506821394,
      -0.014208627864718437,
      0.002845524810254574,
      0.08461756259202957,
      0.018775254487991333,
      0.013028180226683617,
      -0.006689971778541803,
      0.034332524985075,
      0.029217291623353958,
      0.06864020973443985,
      0.0487682968378067,
      -0.07315341383218765,
      0.03386487439274788,
      -0.04407303407788277,
      -0.021998712792992592,
      0.035428598523139954,
      0.016695810481905937,
      0.026534292846918106,
      0.0674016922712326,
      -0.01315770298242569,
      0.018312033265829086,
      0.03231964632868767,
      0.01934981346130371,
      -0.047664884477853775,
      0.05588224157691002,
      0.019584624096751213,
      -0.05117300525307655,
      -0.027633678168058395,
      -0.019373849034309387,
      0.023394225165247917,
      -0.049271196126937866,
      0.02315489761531353,
      0.02378346212208271,
      -0.07459603250026703,
      0.038388412445783615,
      -0.01397764589637518,
      -0.0769023671746254,
      -0.046670425683259964,
      0.041706934571266174,
      -0.024437449872493744,
      -0.056705135852098465,
      -0.015213844366371632,
      -0.07990045845508575,
      -0.06977923959493637,
      -0.04765601456165314,
      -0.06911198049783707,
      0.04203150421380997,
      -0.03463078662753105,
      -0.011809784919023514,
      -0.10084182024002075,
      0.015918800607323647,
      0.07075266540050507,
      0.04834520071744919,
      -0.016533125191926956,
      -0.004223998170346022,
      -0.029739659279584885,
      -0.035768043249845505,
      0.043484799563884735,
      0.047091204673051834,
      -0.024266056716442108,
      0.01722533069550991,
      0.019888274371623993,
      -0.08871281147003174,
      -0.001208192901685834,
      0.003051002277061343,
      -0.1133396103978157,
      0.009043620899319649,
      0.07485644519329071,
      -0.007819876074790955,
      0.04526703432202339,
      -0.025427527725696564,
      -0.032370422035455704,
      -0.04564952477812767,
      -0.05009245499968529,
      0.01891237124800682,
      0.04681062325835228,
      -0.07588396966457367,
      0.0256104227155447,
      -0.031858861446380615,
      0.04407094046473503,
      0.009468001313507557,
      0.0008424315019510686,
      0.09529061615467072,
      -0.005870839115232229,
      -0.03853997960686684,
      0.040943581610918045,
      0.04050102457404137,
      0.02978535369038582,
      -0.02179020456969738,
      -0.004267728887498379,
      -0.0017558404942974448,
      -0.06112971901893616,
      -0.10291770100593567,
      -0.003772618481889367,
      -0.07017683982849121,
      0.06346505135297775,
      -0.0006455930997617543,
      -0.017813021317124367,
      -0.0061646695248782635,
      0.05037634074687958,
      0.031705643981695175,
      -0.020715704187750816,
      -0.03327348828315735,
      -0.007854016497731209,
      0.02094270847737789,
      0.05178810656070709,
      0.013338185846805573,
      -0.044397685676813126,
      0.09974189847707748,
      0.10812553018331528,
      -0.031522173434495926,
      0.00808141939342022,
      0.08056249469518661,
      0.03209718316793442,
      0.06774929910898209,
      0.019243499264121056,
      -0.007329454179853201,
      -0.055681366473436356,
      0.04811546206474304,
      -0.031028345227241516,
      0.002356414683163166,
      0.07979342341423035,
      -0.062281619757413864,
      0.01133307907730341,
      -0.023532504215836525,
      -0.015485875308513641,
      0.020576465874910355,
      0.0690433531999588,
      0.006269832607358694,
      0.051807060837745667,
      -0.07961029559373856,
      0.1092291921377182,
      -0.05179120972752571,
      0.036971110850572586,
      0.03418244048953056,
      0.0036388314329087734,
      -0.03833431378006935,
      0.05900469049811363,
      -0.09288868308067322,
      -0.00034462736221030354,
      -0.03625952824950218,
      0.050120480358600616,
      -0.008430462330579758,
      0.10481133311986923,
      -0.014269629493355751,
      -0.004795031156390905,
      -0.011621005833148956,
      0.010813654400408268,
      -0.044188641011714935,
      -0.011907103471457958,
      0.0205362681299448,
      0.025771457701921463,
      0.0028566494584083557,
      0.004379297140985727,
      -0.01795135997235775,
      0.011991815641522408,
      0.04740317910909653,
      -0.0751023143529892,
      0.03844581916928291,
      -0.02921900525689125,
      -0.12922415137290955,
      -0.008997251279652119,
      0.03576594591140747,
      0.011704845353960991,
      -0.03927916660904884,
      0.01860296167433262,
      -0.018048355355858803,
      0.0373346246778965,
      0.018201595172286034,
      0.021330757066607475,
      0.05892416462302208,
      -0.007909181527793407,
      0.0600302517414093,
      0.023091396316885948,
      0.04758095368742943,
      0.0443962924182415,
      -0.021819312125444412,
      0.00891214981675148,
      -0.026704994961619377,
      0.014346570707857609,
      -0.029289625585079193,
      -0.04514819011092186,
      0.034963324666023254,
      -0.015502497553825378,
      -0.00533969746902585,
      -0.030999889597296715,
      -0.007148726377636194,
      -0.07800650596618652,
      -0.010832056403160095,
      -0.02655971609055996,
      -0.009847868233919144,
      -0.018436266109347343,
      -0.05172106251120567,
      0.044166527688503265,
      0.05100248381495476,
      0.009871595539152622,
      -0.017176732420921326,
      0.021036308258771896,
      -0.00781556498259306,
      -0.07229936122894287,
      -0.03907916322350502,
      -0.0462944433093071,
      -0.05602618306875229,
      0.04034966975450516,
      -0.11399322003126144,
      -0.010270930826663971,
      0.01782292313873768,
      0.05155494809150696,
      -0.007011790759861469,
      0.01979449763894081,
      0.001588589628227055,
      0.013131923973560333,
      0.0015746897552162409,
      -0.06510354578495026,
      -0.003160352585837245,
      0.02977003902196884,
      0.01458047516644001,
      0.029428334906697273,
      0.06445129960775375,
      -0.027318641543388367,
      -0.025753220543265343,
      0.016871880739927292,
      -0.012001678347587585,
      -0.0005346069810912013,
      -0.030409088358283043,
      0.0651119202375412,
      0.02793281339108944,
      0.006457047071307898,
      0.0682775154709816,
      -0.03325455263257027,
      0.01683231070637703,
      -0.08398479968309402,
      0.07833380997180939,
      0.01329336129128933,
      0.0065943836234509945,
      -0.040516581386327744,
      -0.04940473660826683,
      -0.08440800756216049,
      0.0038253956008702517,
      -0.06381102651357651,
      0.01993704028427601,
      0.08655543625354767,
      0.0188241358846426,
      -0.004686121828854084,
      0.08965909481048584,
      -0.004650201182812452,
      0.0034084299113601446,
      -0.01877649873495102,
      0.11517010629177094,
      -0.06207667663693428,
      0.0046673305332660675,
      -0.007512067444622517,
      0.04004804417490959,
      -0.04725503921508789,
      -0.02390495501458645,
      0.012483474798500538,
      -0.002602304331958294,
      -0.046759068965911865,
      -0.07764293253421783,
      -0.022807959467172623,
      0.04589026793837547,
      0.09864992648363113,
      -0.02911684475839138,
      0.0802941545844078,
      0.07373743504285812,
      -0.005916381720453501,
      0.0509561225771904,
      -0.050131358206272125,
      0.04017283022403717,
      0.003606918267905712,
      -0.034377571195364,
      0.07618405669927597,
      0.0032829244155436754,
      -0.017169425264000893,
      -0.04312210902571678,
      -0.01239035278558731,
      0.05352295562624931,
      0.009090556763112545,
      0.024076174944639206,
      0.004419435281306505,
      0.10050155222415924,
      -0.011001908220350742,
      0.06050027161836624,
      -0.045625969767570496,
      0.04050079733133316,
      -0.024548383429646492,
      0.05383611470460892,
      0.008996364660561085,
      0.030778147280216217,
      -0.0391661562025547,
      -0.026671262457966805,
      -0.021556412801146507,
      -0.032646600157022476,
      -0.06968802958726883,
      0.03487994894385338,
      -0.014360676519572735,
      -0.10621244460344315,
      0.00900636613368988,
      -0.03473199903964996,
      -0.011857958510518074,
      0.019335977733135223,
      -0.024732327088713646,
      -0.09916364401578903,
      0.11097397655248642,
      0.0030051942449063063,
      -0.02471340261399746,
      0.009348186664283276,
      -0.002863141708076,
      0.03044680692255497,
      0.011811934411525726
    ],
    [
      0.017718685790896416,
      0.04691002145409584,
      0.058983296155929565,
      -0.09052874892950058,
      0.02767791785299778,
      -0.018560128286480904,
      -0.10886029154062271,
      -0.009772912599146366,
      0.009089142084121704,
      0.021458670496940613,
      -0.028255589306354523,
      -0.007162475027143955,
      -0.012307361699640751,
      0.00463507417589426,
      0.019124869257211685,
      0.05252782627940178,
      -0.06443458795547485,
      0.02152278460562229,
      -0.02875981107354164,
      -0.04486773908138275,
      0.03857892006635666,
      0.012224973179399967,
      -0.13425606489181519,
      0.01191945094615221,
      0.01005440391600132,
      -0.004939132370054722,
      0.051360148936510086,
      -0.013819937594234943,
      -0.005027598235756159,
      0.09105900675058365,
      0.036856554448604584,
      0.007882547564804554,
      0.0453106127679348,
      0.03526686877012253,
      -0.020556854084134102,
      0.04994453489780426,
      -0.015926633030176163,
      0.05176559090614319,
      0.029380638152360916,
      -0.022356148809194565,
      0.02556742914021015,
      -0.028659436851739883,
      -0.08430669456720352,
      0.0032932439353317022,
      0.07649333029985428,
      -0.08939740806818008,
      -0.010288510471582413,
      0.04332146793603897,
      0.01166714821010828,
      0.03021620772778988,
      -0.014867905527353287,
      0.019837142899632454,
      -0.012067495845258236,
      0.01955023780465126,
      -0.008109661750495434,
      -0.0367608517408371,
      0.012324316427111626,
      -0.0010583694092929363,
      -0.01946442387998104,
      -0.10858795791864395,
      0.015218350104987621,
      -0.023784998804330826,
      -0.08437936007976532,
      -0.0010224494617432356,
      0.01746748387813568,
      0.009257548488676548,
      0.034505538642406464,
      0.017377324402332306,
      0.0034367567859590054,
      0.0068822153843939304,
      0.06353684514760971,
      -0.03241969272494316,
      0.03316175565123558,
      -0.025203267112374306,
      0.042834069579839706,
      0.12438295036554337,
      0.043353863060474396,
      -0.05458856374025345,
      -0.006772864144295454,
      0.03479951247572899,
      0.03285164013504982,
      0.03416166082024574,
      -0.017085444182157516,
      0.03528285399079323,
      -0.010960600338876247,
      0.024118028581142426,
      0.05203305929899216,
      -0.06446706503629684,
      -0.04024818167090416,
      0.003974044229835272,
      0.003609161591157317,
      0.05034318193793297,
      -0.03345416113734245,
      -0.042455680668354034,
      0.0510718934237957,
      0.0005256771692074835,
      0.04520441219210625,
      0.012038446962833405,
      0.06043694540858269,
      0.0908275917172432,
      -0.03948942944407463,
      0.012278283946216106,
      0.005209539085626602,
      -0.056119419634342194,
      0.021438896656036377,
      0.043738022446632385,
      0.0462786927819252,
      -0.06023949384689331,
      -0.05073985829949379,
      -0.05593128874897957,
      -0.0018500305013731122,
      -0.004234270192682743,
      0.00019783481548074633,
      0.02892368845641613,
      -0.032152656465768814,
      -0.05880697816610336,
      0.053754065185785294,
      -0.036192748695611954,
      0.0572781041264534,
      -0.09618887305259705,
      0.005708813667297363,
      -0.030112208798527718,
      -0.007325534243136644,
      -0.01228762324899435,
      -0.031162582337856293,
      -0.022050095722079277,
      0.005742707289755344,
      0.026149513199925423,
      0.03843787685036659,
      0.036268219351768494,
      0.05278492718935013,
      -0.06285911798477173,
      0.026039879769086838,
      -0.038202837109565735,
      -0.06523430347442627,
      -0.05157402902841568,
      -0.02664940431714058,
      -0.02282489277422428,
      0.07831019908189774,
      0.0006320658139884472,
      0.06923997402191162,
      0.003813092363998294,
      0.05208583548665047,
      0.021579304710030556,
      0.04652338847517967,
      0.04092651978135109,
      -0.027320468798279762,
      -0.0414653904736042,
      0.020041033625602722,
      0.03260815516114235,
      -0.016876570880413055,
      0.04920483008027077,
      -0.05654989182949066,
      -0.023212194442749023,
      -0.04195816069841385,
      -0.0612669363617897,
      0.05553685128688812,
      -0.049351152032613754,
      0.05413629487156868,
      -0.07055949419736862,
      -0.002926405519247055,
      -0.03824431449174881,
      -0.07539479434490204,
      -0.008600816130638123,
      0.039162132889032364,
      0.019549790769815445,
      0.061552904546260834,
      0.007705616764724255,
      -0.012844899669289589,
      -0.08475454896688461,
      -0.011738058179616928,
      0.008884761482477188,
      0.012095306068658829,
      0.07195516675710678,
      -0.018369160592556,
      0.001569641288369894,
      -0.007632361259311438,
      -0.005880537908524275,
      -0.00430003646761179,
      -0.029772954061627388,
      -0.015704646706581116,
      -0.08115514367818832,
      -0.027810197323560715,
      0.09941563010215759,
      -0.1047762855887413,
      -0.007368813268840313,
      -0.06149465963244438,
      0.035297147929668427,
      0.0008781767683103681,
      0.006508686114102602,
      -0.012531745247542858,
      0.025731321424245834,
      -0.07145409286022186,
      0.08294104784727097,
      0.05100593715906143,
      -0.047917649149894714,
      -0.009415099397301674,
      -0.06435132771730423,
      -0.05754319950938225,
      0.028550254181027412,
      0.005431801080703735,
      -0.012072470039129257,
      0.008687113411724567,
      -0.009700710885226727,
      0.02991785667836666,
      0.05000218376517296,
      -0.041363198310136795,
      -0.02045028656721115,
      -0.01261404063552618,
      -0.0533110611140728,
      0.03820382431149483,
      0.03963311389088631,
      0.003418936626985669,
      -0.02967858873307705,
      -0.0036858695093542337,
      0.011153108440339565,
      -0.008490814827382565,
      -0.0220179483294487,
      -0.040114160627126694,
      0.048687055706977844,
      -0.022598644718527794,
      -0.023449217900633812,
      -0.022811897099018097,
      0.05208105966448784,
      0.01916208490729332,
      -0.05647279694676399,
      0.012021612375974655,
      -0.005373599007725716,
      0.07991303503513336,
      -0.04227824881672859,
      0.006035692058503628,
      0.07977207005023956,
      0.008986938744783401,
      -0.055347345769405365,
      0.017473820596933365,
      -0.011278956197202206,
      0.02214685082435608,
      0.04873735085129738,
      0.00681642908602953,
      -0.05804143473505974,
      0.025875350460410118,
      0.04381875321269035,
      -0.01215385552495718,
      0.0720575824379921,
      0.020401006564497948,
      0.007270118687301874,
      0.01872333697974682,
      0.07429603487253189,
      0.0008759897318668664,
      -0.01717018336057663,
      0.014879151247441769,
      0.04216407239437103,
      0.07867027819156647,
      -0.056137099862098694,
      0.01341130118817091,
      0.02744959481060505,
      -0.02306162565946579,
      -0.05722280591726303,
      -0.06574595719575882,
      -0.007502174936234951,
      0.056192900985479355,
      0.03745429962873459,
      0.053613997995853424,
      -0.009864917024970055,
      0.11548954993486404,
      0.04260656237602234,
      0.018005549907684326,
      0.022516822442412376,
      0.034401264041662216,
      -0.03790844976902008,
      -0.0131796570494771,
      -0.043766312301158905,
      -0.058671705424785614,
      -0.023838497698307037,
      0.03748881444334984,
      0.02131763845682144,
      -0.04613853618502617,
      0.028184635564684868,
      0.0164408627897501,
      -0.004974103067070246,
      -0.02667498029768467,
      0.043538983911275864,
      0.013384606689214706,
      -0.022376559674739838,
      -0.0025645571295171976,
      -0.0207296721637249,
      -0.019346807152032852,
      -0.06818518787622452,
      -0.0284599456936121,
      -0.05248509347438812,
      0.01660756953060627,
      -0.01577046513557434,
      0.04393965005874634,
      -0.022242628037929535,
      -0.026431893929839134,
      0.037742260843515396,
      0.08149988204240799,
      -0.029504062607884407,
      -0.04343754053115845,
      0.005148922558873892,
      0.041196178644895554,
      0.10379715263843536,
      -0.06672947108745575,
      0.047083452343940735,
      0.05518028140068054,
      -0.027251536026597023,
      0.008066680282354355,
      0.022281117737293243,
      0.03424637019634247,
      -0.004644474480301142,
      0.03751838952302933,
      -0.019626349210739136,
      -0.023799357935786247,
      -0.03860398009419441,
      -0.006617297418415546,
      -0.07177818566560745,
      0.006238776724785566,
      -0.04293178766965866,
      -0.049890127032995224,
      -0.011889589950442314,
      -0.022220086306333542,
      -0.08595265448093414,
      -0.027535567060112953,
      0.07984018325805664,
      0.013081480748951435,
      -0.05872607231140137,
      0.020490527153015137,
      -0.09104646742343903,
      0.03605533018708229,
      0.01003409642726183,
      0.05371152237057686,
      -0.02403080277144909,
      -0.11407100409269333,
      0.03439180180430412,
      0.02045956254005432,
      0.002181916031986475,
      -0.06241744011640549,
      0.017795685678720474,
      0.08485046029090881,
      0.024618731811642647,
      -0.020523332059383392,
      0.03751487284898758,
      0.007500408682972193,
      0.03913850709795952,
      0.06312468647956848,
      0.015718797221779823,
      -0.072591632604599,
      -0.06927802413702011,
      -0.004827412776648998,
      -0.059300292283296585,
      0.03177044540643692,
      -0.02133200690150261,
      -0.013699525967240334,
      0.07826954871416092,
      0.01885283552110195,
      -0.08113348484039307,
      -0.03081418387591839,
      -0.0839216336607933,
      -0.007864490151405334,
      0.006881583947688341,
      -0.017083439975976944,
      -0.014003540389239788,
      -0.08222274482250214,
      0.07231102138757706,
      -0.027567414566874504,
      0.07590429484844208,
      0.030560297891497612,
      0.07408057898283005,
      0.014869289472699165,
      0.05686165764927864,
      0.032885316759347916,
      0.0130274947732687,
      -0.1064806655049324,
      -0.10191252082586288,
      0.04504987597465515,
      0.02229369431734085,
      -0.011938819661736488,
      -0.05899335816502571,
      0.02017909847199917,
      0.05789296701550484,
      0.08420238643884659,
      -0.07239042222499847,
      -0.058027736842632294,
      -0.0007333216490224004,
      -0.0179078821092844,
      0.02266818843781948,
      0.03319826349616051,
      0.030025355517864227,
      0.04354414343833923,
      0.07476557791233063,
      0.0029189062770456076,
      0.043337613344192505,
      0.008833156898617744,
      -0.003451698925346136,
      0.031316034495830536,
      -0.05098411813378334,
      -0.005017975345253944,
      -0.037162065505981445,
      0.013122628442943096,
      -0.0051909335888922215,
      0.08241494745016098,
      -0.0035220165736973286,
      0.051230449229478836,
      0.020418008789420128,
      -0.07782036811113358,
      -0.023146389052271843,
      -0.05504217743873596,
      -0.0006790877669118345,
      0.01927756331861019,
      0.023680828511714935,
      -0.03771292418241501,
      -0.056844715029001236,
      -0.0018373002531006932,
      -0.02004939503967762,
      0.06040014699101448,
      0.039270851761102676,
      -0.036019258201122284,
      -0.0030002978164702654,
      0.012779755517840385,
      0.02416323311626911,
      0.02844451554119587,
      -0.012740088626742363,
      -0.12957867980003357,
      -0.027319924905896187,
      0.05325186252593994,
      -0.029703691601753235,
      -0.054119087755680084,
      0.03413751721382141,
      -0.09825819730758667,
      -0.08763381838798523,
      0.050971392542123795,
      0.022775989025831223,
      0.01850225031375885,
      -0.003976424690335989,
      -0.055842071771621704,
      -0.1544627994298935,
      -0.0572204627096653,
      0.03703504428267479,
      0.016997845843434334,
      -0.05787334218621254,
      -0.051011230796575546,
      0.030123988166451454,
      0.043081555515527725,
      -0.017406072467565536,
      0.05458592250943184,
      -0.0013764456380158663,
      -0.004191819578409195,
      0.010377028025686741,
      0.02157915011048317,
      0.006458741147071123,
      -0.0031093889847397804,
      0.020968640223145485,
      -0.06981735676527023,
      0.042144257575273514,
      0.06801800429821014,
      -0.041961632668972015,
      0.020522454753518105,
      0.020899394527077675,
      0.04210842400789261,
      0.02418706752359867,
      0.02680133655667305,
      0.11647030711174011,
      0.04707137495279312,
      0.01608160324394703,
      -0.08027469366788864,
      0.07273468375205994,
      -0.008814680390059948,
      -0.00376210012473166,
      -0.021204523742198944,
      -0.015579026192426682,
      0.07245923578739166,
      0.02469947561621666,
      -0.03639885038137436,
      -0.05667325109243393,
      -0.028750551864504814,
      -0.028338011354207993,
      0.05476002395153046,
      0.015362300910055637,
      -0.007942800410091877,
      0.05710640177130699,
      -0.06663113087415695,
      0.013261113315820694,
      0.04966166988015175,
      0.06150609254837036,
      0.02526748552918434,
      0.014103656634688377,
      0.023468129336833954,
      0.04097072780132294,
      -0.029470695182681084,
      0.07816188782453537,
      -0.07265324890613556,
      -0.05372864380478859,
      -0.03922400251030922,
      0.009243027307093143,
      0.005437679588794708,
      -0.08738573640584946,
      -0.1103711947798729,
      -0.006463864352554083,
      -0.008302120491862297,
      0.05845046415925026,
      -0.04485911875963211,
      -0.01225089281797409,
      0.0030648852698504925
    ],
    [
      0.015347938984632492,
      0.07706452161073685,
      -0.00015515083214268088,
      -0.020595580339431763,
      0.06780366599559784,
      -0.03246685117483139,
      -0.013251418247818947,
      -0.00045887898886576295,
      0.04142823442816734,
      -0.005467347800731659,
      0.0006189363775774837,
      0.06373727321624756,
      -0.008312984369695187,
      0.021065479144454002,
      0.016964446753263474,
      -0.03819691762328148,
      -0.06671331822872162,
      0.0009590643458068371,
      -0.06857577711343765,
      -0.04730134829878807,
      -0.012889650650322437,
      -0.013814176432788372,
      -0.02649100497364998,
      6.571699486812577e-05,
      -0.04051143676042557,
      -0.0658285990357399,
      0.0557556077837944,
      -0.022689616307616234,
      0.019422143697738647,
      -0.03836829587817192,
      0.08401569724082947,
      0.06624328345060349,
      -0.009440766647458076,
      0.0038237040862441063,
      0.03839987516403198,
      0.06006178632378578,
      -0.008270472288131714,
      -0.05595806986093521,
      0.0029560462571680546,
      -0.013639782555401325,
      -0.08605069667100906,
      0.023626960813999176,
      0.03071749024093151,
      -0.014162501320242882,
      0.03737558797001839,
      0.03601996228098869,
      -0.014260029420256615,
      -0.03645113483071327,
      -0.034031014889478683,
      -0.0015977683942764997,
      -0.06925114244222641,
      -0.03598366305232048,
      -0.04875468090176582,
      0.09115194529294968,
      -0.04145045205950737,
      0.002410885179415345,
      0.02705000340938568,
      -0.04169290512800217,
      -0.026417391374707222,
      0.07891387492418289,
      0.04024205729365349,
      0.027987662702798843,
      0.01141886506229639,
      0.027941005304455757,
      0.008272823877632618,
      0.0017242534086108208,
      -0.002148259663954377,
      0.041222020983695984,
      -0.03017083741724491,
      0.07614954560995102,
      0.061764948070049286,
      0.03153415024280548,
      0.01611565612256527,
      -0.03789595887064934,
      -0.03193563222885132,
      0.03981141000986099,
      0.027149271219968796,
      -0.0431349016726017,
      0.02462199702858925,
      -0.05782837048172951,
      0.02722729928791523,
      0.03131522238254547,
      -0.028578050434589386,
      0.05271276459097862,
      0.02901102788746357,
      0.03896138817071915,
      -0.02757694385945797,
      -0.005187610629945993,
      0.015868544578552246,
      0.015317752957344055,
      -0.016436774283647537,
      -0.1153322085738182,
      0.026534894481301308,
      0.027910612523555756,
      -0.0061398702673614025,
      0.09651630371809006,
      0.040337830781936646,
      0.0003455351397860795,
      0.02506914548575878,
      0.03638648986816406,
      -0.019224563613533974,
      -0.030153177678585052,
      -0.017390340566635132,
      -0.04290449991822243,
      0.04132618010044098,
      0.058072738349437714,
      -0.09444091469049454,
      -0.02189093455672264,
      -0.03336746618151665,
      0.04605148360133171,
      -0.03077927976846695,
      0.0043395464308559895,
      0.009947432205080986,
      0.05192742869257927,
      0.019191738218069077,
      -0.03498789668083191,
      0.023720137774944305,
      -0.11677292734384537,
      0.08039095997810364,
      -0.015156636945903301,
      -0.01440397184342146,
      -0.01332872174680233,
      0.02124212309718132,
      0.011553379707038403,
      0.031389594078063965,
      0.04228505864739418,
      0.020952299237251282,
      -0.003124052193015814,
      0.06732199341058731,
      0.1019434854388237,
      -0.049578726291656494,
      -0.03556692227721214,
      -0.048231352120637894,
      0.03711497038602829,
      0.009788818657398224,
      0.052423328161239624,
      0.01662348210811615,
      -0.0046586086973547935,
      -0.07830534130334854,
      -0.04856861010193825,
      0.05639171972870827,
      -0.012400606647133827,
      0.047383420169353485,
      -0.007651886437088251,
      0.06417743861675262,
      0.029113756492733955,
      -0.01655346155166626,
      -0.05399315804243088,
      -0.04356088116765022,
      -0.059246864169836044,
      0.0542471669614315,
      -0.014865037985146046,
      -0.060239192098379135,
      -0.046563342213630676,
      -0.020632000640034676,
      -0.03094816952943802,
      -0.05429274961352348,
      -0.03804011270403862,
      0.07118721306324005,
      0.041175726801157,
      0.03645685315132141,
      -0.00424659950658679,
      0.0030072105582803488,
      0.03596820682287216,
      -0.022283505648374557,
      0.01967500150203705,
      -0.009391671977937222,
      -0.07035607099533081,
      -0.03915517404675484,
      0.11524859070777893,
      -0.05199698358774185,
      0.04556594043970108,
      0.018084991723299026,
      -0.015329080633819103,
      0.023636160418391228,
      -0.07737928628921509,
      -0.02350417710840702,
      -0.07285301387310028,
      -0.030162665992975235,
      0.05060955509543419,
      0.025402933359146118,
      -0.0250871479511261,
      0.028603261336684227,
      0.033278241753578186,
      -0.0512152723968029,
      0.015730485320091248,
      0.04013855755329132,
      -0.039716243743896484,
      -0.037680547684431076,
      -0.05920397490262985,
      0.013370363973081112,
      -0.006039089523255825,
      0.07209696620702744,
      0.03580727055668831,
      -0.06951335072517395,
      -0.031569551676511765,
      -0.010382932610809803,
      0.0587027445435524,
      -0.047583166509866714,
      0.023790599778294563,
      -0.031062310561537743,
      -0.0066854446195065975,
      -0.037167586386203766,
      -0.00742931617423892,
      0.021287446841597557,
      -0.02049526944756508,
      -0.014283913187682629,
      -0.04535318911075592,
      -0.0358891524374485,
      0.007177285850048065,
      0.02550751343369484,
      0.014397607184946537,
      0.08410459756851196,
      0.06834437698125839,
      -0.030009059235453606,
      -0.033813271671533585,
      -0.005860028322786093,
      0.01641908846795559,
      0.03752971068024635,
      0.025239599868655205,
      -0.02137668989598751,
      -0.011139389127492905,
      -0.04999561235308647,
      0.033918265253305435,
      0.005933479405939579,
      0.033786267042160034,
      -0.038873665034770966,
      0.12945456802845,
      0.051723796874284744,
      -0.033576998859643936,
      -0.016599660739302635,
      -0.02984514832496643,
      0.03180995583534241,
      0.07348507642745972,
      0.08051647245883942,
      0.023212384432554245,
      0.010416754521429539,
      -0.058481547981500626,
      0.0033766990527510643,
      -0.034793369472026825,
      -0.00992491189390421,
      -0.01441184338182211,
      -0.08363497257232666,
      0.005762254353612661,
      0.04048052057623863,
      0.06407589465379715,
      0.0092375623062253,
      0.055107392370700836,
      -0.029518287628889084,
      0.03179624676704407,
      0.002526621101424098,
      0.008496885187923908,
      0.06070595234632492,
      -0.025649823248386383,
      0.032986413687467575,
      0.038579221814870834,
      -0.008971763774752617,
      -0.05335620418190956,
      -0.06071551889181137,
      0.019982337951660156,
      -0.004098245408385992,
      0.03153882175683975,
      0.003087627701461315,
      -0.06544183194637299,
      -0.02050166204571724,
      -0.025242477655410767,
      0.008401053957641125,
      -0.037613216787576675,
      -0.03420202434062958,
      0.0025307636242359877,
      0.0006391906645148993,
      -0.0026959769893437624,
      0.006614432670176029,
      0.10770925134420395,
      0.03335317596793175,
      -0.02133883722126484,
      -0.02959020435810089,
      0.013900277204811573,
      -0.009837825782597065,
      -0.0284592118114233,
      0.03786459192633629,
      0.013010352849960327,
      -0.017460161820054054,
      0.07004078477621078,
      0.021517951041460037,
      -0.06992607563734055,
      -0.02828618697822094,
      0.07271713763475418,
      0.05200981721282005,
      0.03931484371423721,
      0.051118239760398865,
      -0.032958049327135086,
      -0.009350001811981201,
      0.009005172178149223,
      0.07039619237184525,
      0.033737726509571075,
      -0.02073817513883114,
      0.09767495095729828,
      -0.06187811121344566,
      -0.020861485973000526,
      0.03208448365330696,
      -0.00578423822298646,
      0.028579002246260643,
      -0.01962864212691784,
      0.04055795073509216,
      -0.00480792997404933,
      -0.017772436141967773,
      0.014112580567598343,
      0.01139918901026249,
      0.06900213658809662,
      -0.04362709820270538,
      0.002116420306265354,
      -0.10533181577920914,
      -0.055434294044971466,
      0.0028463450726121664,
      -0.03429356962442398,
      0.03687582537531853,
      0.019914060831069946,
      -0.0921948254108429,
      0.01671075075864792,
      0.0854434072971344,
      -0.005730513483285904,
      0.06353780627250671,
      -0.021086592227220535,
      -0.06720418483018875,
      0.013449142687022686,
      -0.003697348991408944,
      0.015983719378709793,
      0.03842659667134285,
      -0.015617910772562027,
      -0.03834168612957001,
      0.0598139613866806,
      0.05688958242535591,
      -0.012288316152989864,
      -0.03339521586894989,
      0.012650351040065289,
      0.0643720030784607,
      0.06742898374795914,
      0.011531895957887173,
      -0.01361067220568657,
      0.044385649263858795,
      0.022608811035752296,
      0.01795154996216297,
      -0.023889746516942978,
      0.06770123541355133,
      -0.018655724823474884,
      0.062074024230241776,
      0.04182173311710358,
      0.04385943338274956,
      0.0718822181224823,
      -0.01818094216287136,
      -0.04546640068292618,
      0.006678460165858269,
      0.02264058217406273,
      0.07243963330984116,
      -0.10832199454307556,
      0.031935274600982666,
      0.030868709087371826,
      -0.048938851803541183,
      -0.03750156983733177,
      0.005506191402673721,
      0.008485116995871067,
      -0.057555776089429855,
      -0.01535852812230587,
      0.009008307009935379,
      0.0321928933262825,
      0.044293615967035294,
      -0.008302498608827591,
      0.0412215031683445,
      -0.003149676136672497,
      -0.025501009076833725,
      0.034975357353687286,
      0.020274536684155464,
      0.06738612800836563,
      -0.005047345068305731,
      0.006488863378763199,
      0.07050390541553497,
      0.06629128009080887,
      0.0715845450758934,
      0.030125083401799202,
      -0.0008876703213900328,
      0.006696010008454323,
      0.010938235558569431,
      -0.0110420947894454,
      0.031402237713336945,
      -0.025222565978765488,
      -0.03272128850221634,
      -0.06045739725232124,
      0.0010023284703493118,
      -0.02166447602212429,
      0.05199702829122543,
      -0.03497224301099777,
      0.03088477998971939,
      -0.041489724069833755,
      0.016878778114914894,
      0.07726538181304932,
      -0.024112949147820473,
      0.06836586445569992,
      -0.027289515361189842,
      0.04424728453159332,
      0.0484134741127491,
      -0.006649742368608713,
      -0.014677193015813828,
      -0.006187670398503542,
      -0.0033985062036663294,
      -0.018762163817882538,
      0.04205162078142166,
      -0.026240510866045952,
      0.01839728280901909,
      -0.08215946704149246,
      -0.01264160592108965,
      -0.07601504772901535,
      0.0010311165824532509,
      0.031024381518363953,
      -0.03893785923719406,
      -0.006403057370334864,
      -0.014581559225916862,
      0.053941600024700165,
      -0.11313483119010925,
      0.026505937799811363,
      -0.03802584484219551,
      0.0573178306221962,
      0.00024387467419728637,
      -0.016219494864344597,
      -0.038720980286598206,
      0.020214488729834557,
      -0.06850118935108185,
      0.02990935742855072,
      0.02016775868833065,
      -0.01764249987900257,
      0.040382105857133865,
      0.05610818415880203,
      -0.051488619297742844,
      -0.05356310307979584,
      0.033974725753068924,
      0.06049831956624985,
      0.015937505289912224,
      -0.0026810660492628813,
      0.016025368124246597,
      -0.03388319909572601,
      0.10009587556123734,
      -0.0009606181411072612,
      0.02204263024032116,
      0.025853551924228668,
      0.04477694630622864,
      0.049654193222522736,
      0.03763650730252266,
      0.05877896025776863,
      -0.06462786346673965,
      -0.006850020494312048,
      0.07591691613197327,
      0.031918965280056,
      -0.054930876940488815,
      -0.011378373019397259,
      0.01678192801773548,
      -0.03557903692126274,
      -0.029012849554419518,
      0.0339847169816494,
      -0.03193385526537895,
      -0.019410399720072746,
      0.051748987287282944,
      0.01397918164730072,
      0.058920521289110184,
      0.029801713302731514,
      0.04737589508295059,
      0.05340651050209999,
      0.011550058610737324,
      -0.05236783251166344,
      0.01155856717377901,
      -0.011739883571863174,
      0.007688975427299738,
      0.039437633007764816,
      0.07543119788169861,
      0.037086039781570435,
      0.1272488385438919,
      0.026242589578032494,
      -0.09793297946453094,
      0.03218713030219078,
      0.057712748646736145,
      -0.022937774658203125,
      -0.020051607862114906,
      0.04630225896835327,
      -0.04825012385845184,
      -0.057656630873680115,
      0.0444476418197155,
      -0.030077943578362465,
      -0.0074531761929392815,
      -0.022885052487254143,
      -0.004182855598628521,
      -0.03912480175495148,
      -0.0011140332790091634,
      0.01221181359142065,
      0.0030978010036051273,
      -0.0657636970281601,
      0.052879925817251205,
      -0.025305338203907013,
      -0.04176846519112587,
      -0.029056116938591003,
      0.0636136531829834,
      -0.035699158906936646,
      -0.04738617315888405,
      -0.01794801652431488,
      -0.02255198545753956
    ],
    [
      0.02943107858300209,
      0.050106827169656754,
      0.001741346437484026,
      -0.02111353725194931,
      0.07890302687883377,
      -0.019006358459591866,
      0.0752054750919342,
      -0.003782410640269518,
      -0.026519374921917915,
      -0.10020407289266586,
      -0.01118369773030281,
      0.018833797425031662,
      -0.019359633326530457,
      -0.043338336050510406,
      0.00032289299997501075,
      -0.027801156044006348,
      -0.01884652115404606,
      -0.006721269804984331,
      -0.08482466638088226,
      -0.020245546475052834,
      -0.011755573563277721,
      -0.025595007464289665,
      -0.04861099272966385,
      0.032475609332323074,
      0.019938087090849876,
      0.012957477942109108,
      0.022147992625832558,
      -0.09959444403648376,
      0.08939468860626221,
      -0.007809710223227739,
      -0.011365484446287155,
      -0.04633034020662308,
      0.020787052810192108,
      0.021271543577313423,
      0.0698452740907669,
      -0.06404796242713928,
      -0.010070382617413998,
      -0.03623516112565994,
      0.03507886454463005,
      -0.020993322134017944,
      0.027144290506839752,
      -0.06366851925849915,
      0.03174581378698349,
      0.0006912749377079308,
      -0.07096841931343079,
      -0.06911976635456085,
      0.0350673608481884,
      -0.03592656925320625,
      -0.006638366729021072,
      -0.0378669835627079,
      -0.05975804477930069,
      -0.049219898879528046,
      0.0013464634539559484,
      -0.08997803181409836,
      0.023080794140696526,
      -0.009571962989866734,
      0.014712737873196602,
      0.05221471190452576,
      -0.08099012821912766,
      -0.09842213243246078,
      -0.045109886676073074,
      0.0162168201059103,
      -0.07749516516923904,
      -0.022925833240151405,
      -0.06047581881284714,
      0.036987632513046265,
      -0.04359397292137146,
      -0.019938141107559204,
      0.019194507971405983,
      -0.06077052652835846,
      0.021093718707561493,
      0.011025415733456612,
      -0.03172609582543373,
      0.08477544039487839,
      0.030809372663497925,
      -0.046642135828733444,
      0.025228923186659813,
      0.001379344379529357,
      -0.03635939210653305,
      0.005231435876339674,
      0.03908557444810867,
      0.0403609424829483,
      -0.01690708100795746,
      0.036246877163648605,
      0.020257484167814255,
      -0.027273979038000107,
      -0.022785454988479614,
      -0.012276572175323963,
      -0.04018709436058998,
      0.014248008839786053,
      -0.005730109754949808,
      0.042567893862724304,
      -0.00276559148915112,
      -0.024513188749551773,
      -0.0015365604776889086,
      0.05176548287272453,
      -0.021080030128359795,
      0.024486403912305832,
      0.07151142507791519,
      -0.0909680649638176,
      0.010212679393589497,
      0.025949427857995033,
      -0.05780667066574097,
      -0.046985212713479996,
      0.050727639347314835,
      -0.023719068616628647,
      -0.03079371713101864,
      -0.03303094580769539,
      -0.031807612627744675,
      -0.020534543320536613,
      -0.06739291548728943,
      -0.0359768383204937,
      0.03976083919405937,
      0.022332102060317993,
      0.026343371719121933,
      0.0015045094769448042,
      -0.04257635027170181,
      0.0011811202857643366,
      0.024427248165011406,
      0.024837760254740715,
      -0.01811186969280243,
      0.03201887011528015,
      -0.0682370588183403,
      0.018658872693777084,
      0.0037188755813986063,
      0.04910368099808693,
      0.013342471793293953,
      -0.031027302145957947,
      0.0016029520193114877,
      -0.018219035118818283,
      -0.05904624983668327,
      0.03783230111002922,
      -0.054656729102134705,
      -0.09658033400774002,
      -0.03188788890838623,
      -0.005633378401398659,
      -0.018019653856754303,
      -0.02024824731051922,
      0.07879681885242462,
      -0.018105382099747658,
      -0.0027524426113814116,
      -0.03170745447278023,
      -0.040351834148168564,
      0.0050539495423436165,
      -0.0033565224148333073,
      0.008697371929883957,
      -0.06462892144918442,
      -0.07252313941717148,
      0.04371223598718643,
      -0.019009999930858612,
      0.00983873475342989,
      0.035609155893325806,
      -0.05165570601820946,
      0.037101421505212784,
      -0.03327958658337593,
      0.034461840987205505,
      -9.891791705740616e-05,
      -0.003888975363224745,
      0.0607425794005394,
      -0.09658725559711456,
      0.026202954351902008,
      0.06889412552118301,
      0.020977530628442764,
      0.009365417994558811,
      0.051646504551172256,
      0.013805672526359558,
      0.06436485797166824,
      0.08629976958036423,
      -0.03829403594136238,
      -0.01537403091788292,
      -0.017818551510572433,
      -0.05830971151590347,
      -0.01623542606830597,
      0.036008358001708984,
      0.06514232605695724,
      0.007484914734959602,
      -0.061943989247083664,
      0.048084281384944916,
      -0.016155008226633072,
      0.031195921823382378,
      0.02325965277850628,
      -0.0314774252474308,
      -0.02382986806333065,
      -0.002046839101240039,
      0.017838703468441963,
      -0.07894346863031387,
      0.019323017448186874,
      -0.04625470191240311,
      0.019070172682404518,
      0.018625831231474876,
      0.037819791585206985,
      -0.07175424695014954,
      0.008561075665056705,
      -0.04886050894856453,
      -0.03966876491904259,
      0.021355872973799706,
      0.001847564009949565,
      0.014622240327298641,
      0.003706009592860937,
      -0.018822358921170235,
      -0.059872522950172424,
      0.002648531226441264,
      -0.05135852470993996,
      0.08988924324512482,
      -0.07179161161184311,
      -0.024433117359876633,
      -0.02160252444446087,
      0.0838225856423378,
      -0.07008097320795059,
      0.03158408775925636,
      -0.010831660591065884,
      -0.03801823779940605,
      0.03083033673465252,
      -0.04745033383369446,
      -0.024260539561510086,
      -0.007519589737057686,
      -0.017086103558540344,
      -0.06383667886257172,
      0.06569612771272659,
      0.04773726314306259,
      -0.006837547291070223,
      0.13520637154579163,
      -0.06803710013628006,
      -0.0858343094587326,
      0.03730938583612442,
      0.03759603947401047,
      0.006602812092751265,
      -0.03527900204062462,
      0.019867638126015663,
      -0.0559307225048542,
      -0.019846368581056595,
      0.033744968473911285,
      -0.01703442633152008,
      -0.012024584226310253,
      0.004096474498510361,
      -0.10678108036518097,
      0.032733794301748276,
      -0.02341793105006218,
      0.00012760837853420526,
      -0.03278368338942528,
      0.00463145412504673,
      0.11568289995193481,
      0.00413578050211072,
      -0.0051693543791770935,
      0.05337725207209587,
      -0.063115693628788,
      0.06939912587404251,
      0.016861869022250175,
      0.001063866657204926,
      0.016512062400579453,
      -0.08837457001209259,
      0.02789967693388462,
      -0.017269862815737724,
      0.07976850867271423,
      0.047315556555986404,
      -0.027525151148438454,
      0.04245711863040924,
      0.02825831063091755,
      -0.016733042895793915,
      0.0010718810372054577,
      -0.01488367561250925,
      -0.07069198042154312,
      0.018974389880895615,
      -0.031202565878629684,
      -0.06548425555229187,
      -0.05573378503322601,
      0.05947204679250717,
      -0.09621740877628326,
      -0.051775019615888596,
      0.0024356208741664886,
      0.01305265724658966,
      -0.012705078348517418,
      0.04527522623538971,
      0.06159333884716034,
      -0.023773321881890297,
      -0.05335821956396103,
      0.04741201177239418,
      -0.027937034144997597,
      -0.0701817125082016,
      -0.03356391191482544,
      -0.0007237616810016334,
      0.025526180863380432,
      0.03674245998263359,
      -0.050184063613414764,
      -0.038059454411268234,
      -0.06308240443468094,
      0.007670441642403603,
      0.05543297156691551,
      -0.014406141825020313,
      -0.0036803195253014565,
      0.06770569086074829,
      -0.0940857008099556,
      0.022500673308968544,
      -0.10204261541366577,
      0.021960332989692688,
      -0.046034540981054306,
      -0.07866409420967102,
      0.01717616617679596,
      -0.04518862068653107,
      0.00017852464225143194,
      0.016884149983525276,
      0.008959340862929821,
      0.0660804882645607,
      0.006755753420293331,
      0.04797399789094925,
      -0.041521456092596054,
      0.04604092985391617,
      0.04972637817263603,
      -0.03203491121530533,
      -0.050009895116090775,
      -0.06191073730587959,
      -0.04022175073623657,
      -0.046294718980789185,
      0.03848808631300926,
      -0.00998402014374733,
      0.027477318421006203,
      0.005555515643209219,
      0.05879686027765274,
      0.014403942041099072,
      0.020990680903196335,
      0.013325875625014305,
      -0.03975696116685867,
      0.0329597108066082,
      -0.019569899886846542,
      0.05906291306018829,
      0.038095418363809586,
      -0.029421567916870117,
      -0.0014183225575834513,
      -0.03712138906121254,
      -0.056385185569524765,
      -0.041096072643995285,
      -0.07752889394760132,
      0.05074270814657211,
      0.032814133912324905,
      -0.0064421347342431545,
      0.027984708547592163,
      -0.05073489621281624,
      -0.0010473554721102118,
      -0.02965911291539669,
      -0.00027963853790424764,
      0.015740925446152687,
      0.08256556838750839,
      0.040214311331510544,
      -0.009728999808430672,
      0.07247398048639297,
      0.03666548803448677,
      -0.06101914495229721,
      0.01019475981593132,
      0.014717604964971542,
      0.010661914013326168,
      -0.020140336826443672,
      -0.022891556844115257,
      -0.010746740736067295,
      -0.015418455936014652,
      0.003931317012757063,
      -0.0376872681081295,
      -0.033937208354473114,
      0.01483780425041914,
      -0.023294877260923386,
      0.0023179424460977316,
      0.02777998521924019,
      -0.03955325484275818,
      0.0037600467912852764,
      -0.06280865520238876,
      0.02507506124675274,
      0.012754001654684544,
      -0.0257845651358366,
      0.049663037061691284,
      -0.04742101579904556,
      -0.07498443126678467,
      0.03491094335913658,
      -0.0084154661744833,
      -0.04613981395959854,
      0.02222254127264023,
      -0.04923715442419052,
      -0.0226569976657629,
      0.0761767029762268,
      -0.03723452612757683,
      -0.09122716635465622,
      -0.03440198302268982,
      0.04878787323832512,
      -0.03234430029988289,
      0.024706875905394554,
      0.03449079394340515,
      -0.012646593153476715,
      -0.014232728630304337,
      -0.003090230282396078,
      -0.016236979514360428,
      -0.04333518445491791,
      0.0069997780956327915,
      -0.022864338010549545,
      -0.0057301996275782585,
      0.014206254854798317,
      0.015073424205183983,
      0.012215732596814632,
      0.0569395013153553,
      -0.03545104339718819,
      -0.03113483265042305,
      0.010495660826563835,
      0.041128162294626236,
      0.05504533275961876,
      -0.00869485829025507,
      0.004188073333352804,
      0.004626856185495853,
      -0.0065259481780231,
      -0.037957437336444855,
      -0.011717041954398155,
      0.055123306810855865,
      0.05033145844936371,
      0.010446066968142986,
      -0.026807570829987526,
      -0.029343219473958015,
      0.014452684670686722,
      -0.02805841900408268,
      -0.03071700781583786,
      -0.018325749784708023,
      0.031043216586112976,
      0.02523723430931568,
      0.0917828306555748,
      0.018177131190896034,
      -0.0830237939953804,
      0.03168995678424835,
      -0.07306300103664398,
      -0.0416424386203289,
      -0.006373383104801178,
      -0.050842493772506714,
      0.013397346250712872,
      0.018357031047344208,
      0.032763224095106125,
      0.0010342675959691405,
      0.001746748574078083,
      -0.04221317172050476,
      -0.03262066841125488,
      0.008666324429214,
      -0.03068159893155098,
      -0.02930373139679432,
      -0.04378468543291092,
      -0.019200706854462624,
      -0.014279846101999283,
      -0.05975663661956787,
      0.01955220475792885,
      -0.059213872998952866,
      0.0025281966663897038,
      0.007652282249182463,
      -0.0021903114393353462,
      -0.006150178145617247,
      -0.01204356737434864,
      -0.027972564101219177,
      -0.018143653869628906,
      0.04964124783873558,
      0.061131544411182404,
      0.04247147962450981,
      -0.03144906833767891,
      0.05720576271414757,
      0.02555464394390583,
      -0.059719789773225784,
      0.024681702256202698,
      0.030452916398644447,
      -0.006753741297870874,
      -0.015717502683401108,
      -0.010158381424844265,
      0.04923832044005394,
      0.004609390627592802,
      -0.07481434941291809,
      -0.061058271676301956,
      0.008342105895280838,
      0.07344413548707962,
      -0.014513541013002396,
      0.05091157555580139,
      -0.004046138841658831,
      0.05629131197929382,
      -0.03444085270166397,
      0.004950481466948986,
      0.005503423977643251,
      0.08361715078353882,
      0.020219579339027405,
      -0.07219835370779037,
      -0.026804357767105103,
      0.03924284502863884,
      -0.007209525443613529,
      -0.04716985300183296,
      0.016396187245845795,
      0.049420248717069626,
      0.004750495310872793,
      0.06293901056051254,
      0.00834294967353344,
      -0.06082054227590561,
      0.017328158020973206,
      -0.020524201914668083,
      -0.028831910341978073,
      0.03422005474567413,
      -0.04761239141225815,
      0.017185598611831665,
      -0.04730981960892677,
      -0.049180541187524796,
      -0.018539391458034515,
      0.01781316287815571,
      -0.02050749771296978,
      0.02332666888833046,
      0.001609612489119172,
      -0.049665678292512894,
      0.01241664681583643,
      -0.05503411591053009
    ],
    [
      0.018779927864670753,
      -0.018802616745233536,
      -0.007288000080734491,
      -0.03378228098154068,
      0.10198994725942612,
      0.05617344379425049,
      -0.019118724390864372,
      0.005922520533204079,
      -0.010737989097833633,
      0.06132419779896736,
      0.03341946005821228,
      0.037311453372240067,
      0.0044934977777302265,
      -0.008627131581306458,
      0.047043588012456894,
      -0.03883849456906319,
      -0.006552374921739101,
      0.03966939076781273,
      -0.03999217599630356,
      0.015457239001989365,
      0.018011052161455154,
      0.03857921436429024,
      0.01382422260940075,
      -0.06535418331623077,
      -0.008382401429116726,
      0.06833153963088989,
      -0.11364635825157166,
      0.03559032082557678,
      0.06913599371910095,
      -0.005285532213747501,
      -0.008578922599554062,
      -0.019498778507113457,
      -0.004686914384365082,
      0.04022280126810074,
      0.04012734442949295,
      0.014570222236216068,
      0.02284589409828186,
      0.044659607112407684,
      0.04825087636709213,
      0.03969976678490639,
      0.0924488827586174,
      -0.04770379513502121,
      -0.015976566821336746,
      -0.04144192114472389,
      0.03504620119929314,
      -0.02031276747584343,
      0.10116260498762131,
      0.034769266843795776,
      0.011515120044350624,
      -0.023438865318894386,
      0.09156438708305359,
      -0.07246648520231247,
      0.019220639020204544,
      -0.02197767049074173,
      0.039280738681554794,
      0.011726763099431992,
      0.01652432233095169,
      0.020889073610305786,
      0.03715735673904419,
      0.023428821936249733,
      -0.05958826094865799,
      0.10018306225538254,
      0.02504017949104309,
      -0.001088846824131906,
      -0.03879871964454651,
      0.030943291261792183,
      0.020099129527807236,
      -0.05803097411990166,
      0.04208729416131973,
      -0.031422208994627,
      0.009692833758890629,
      -0.0037606817204505205,
      -0.024795563891530037,
      -0.05445513129234314,
      -0.004615648649632931,
      0.01987423375248909,
      0.0016202119877561927,
      -0.00304235122166574,
      -0.06688271462917328,
      -0.03369148448109627,
      0.009690250270068645,
      0.01462910883128643,
      -0.013509399257600307,
      0.08834420144557953,
      -0.009837474673986435,
      0.07730691879987717,
      0.02822810783982277,
      -0.07562241703271866,
      0.00605784822255373,
      0.08923190832138062,
      0.006022954359650612,
      -0.0019779461435973644,
      -0.10940612107515335,
      0.0014955260558053851,
      -0.020060690119862556,
      0.028331227600574493,
      -0.005779875908046961,
      -0.09219294041395187,
      -0.036933813244104385,
      -0.055867165327072144,
      -0.04222838580608368,
      0.10738807916641235,
      -0.06981943547725677,
      -0.0041389428079128265,
      -0.033856190741062164,
      -0.010302931070327759,
      0.0319971926510334,
      0.03791501000523567,
      -0.007817727513611317,
      -0.042706914246082306,
      -0.07627538591623306,
      0.06206447258591652,
      0.011315169744193554,
      -0.053122568875551224,
      -0.025385620072484016,
      -0.06409267336130142,
      -0.017146429046988487,
      0.0358528196811676,
      -0.046004220843315125,
      0.019358936697244644,
      0.03769974038004875,
      0.002537537831813097,
      -0.024950623512268066,
      0.05353783816099167,
      0.048233333975076675,
      -0.0004539813962765038,
      0.12756748497486115,
      -0.029139667749404907,
      0.061602454632520676,
      -0.11346855014562607,
      -0.028465120121836662,
      -0.03580530732870102,
      -0.004122369457036257,
      0.017531679943203926,
      -0.04967880994081497,
      -0.06556415557861328,
      0.010553593747317791,
      0.04177580401301384,
      0.038182806223630905,
      0.008811311796307564,
      0.0018649555277079344,
      -0.019811367616057396,
      -0.05976225435733795,
      -0.013458354398608208,
      0.07142990082502365,
      0.030951296910643578,
      -0.02061047963798046,
      0.05207827314734459,
      -0.01871468685567379,
      -0.031057745218276978,
      0.008702043443918228,
      0.001489865011535585,
      0.051305413246154785,
      -0.0341857448220253,
      -0.06525126844644547,
      0.050482332706451416,
      0.03374795243144035,
      -0.07874706387519836,
      -0.06073582172393799,
      -0.08260850608348846,
      -0.023391012102365494,
      -0.04744211211800575,
      0.04685639590024948,
      -0.002960307989269495,
      -0.05213725566864014,
      -0.044791072607040405,
      0.033923838287591934,
      0.001271779416128993,
      0.013631335459649563,
      0.009476397186517715,
      0.026957251131534576,
      -0.021981967613101006,
      0.08458779752254486,
      0.012608797289431095,
      0.003340757917612791,
      0.07849579304456711,
      0.07182618230581284,
      -0.0950104221701622,
      0.03482871875166893,
      -0.012798763811588287,
      0.027866180986166,
      -0.001190357026644051,
      0.038694996386766434,
      0.0431048683822155,
      -0.05219624936580658,
      0.021741146221756935,
      -0.000406798004405573,
      0.013952458277344704,
      0.01546577736735344,
      -0.003928888589143753,
      0.04613787308335304,
      -0.05884481593966484,
      0.04389423877000809,
      -0.004585948772728443,
      -0.09217962622642517,
      -0.03289147838950157,
      0.046140749007463455,
      0.007202346343547106,
      -0.037018608301877975,
      -0.0534348264336586,
      0.08454132825136185,
      -0.00797925516963005,
      -0.027191797271370888,
      0.08146947622299194,
      -0.013358411379158497,
      -0.07652893662452698,
      -0.007519486825913191,
      -0.03483835607767105,
      0.04462960362434387,
      -0.015875879675149918,
      0.0006602024659514427,
      -0.07622280716896057,
      -0.036851171404123306,
      -0.031234506517648697,
      0.05017441138625145,
      -0.06829684972763062,
      0.017556384205818176,
      0.033018894493579865,
      0.07286398112773895,
      -0.02135911025106907,
      -0.035586994141340256,
      0.0063191307708621025,
      0.04682459682226181,
      0.020859016105532646,
      -0.017890730872750282,
      0.06953289359807968,
      0.0010401223553344607,
      0.05614275485277176,
      -0.020319059491157532,
      -0.04775020480155945,
      0.03010907769203186,
      -0.02470441162586212,
      0.05259435996413231,
      0.0006816452369093895,
      -0.08828296512365341,
      -0.06589587032794952,
      0.02529093250632286,
      -0.01555195078253746,
      -0.057351578027009964,
      -0.019185952842235565,
      0.01608707197010517,
      0.02640276961028576,
      0.023738732561469078,
      -0.053242575377225876,
      0.026330694556236267,
      0.03047662228345871,
      -0.015293498523533344,
      -0.0017229501390829682,
      0.020641669631004333,
      -0.019699977710843086,
      -0.10378479957580566,
      0.004349442198872566,
      -0.010482012294232845,
      0.027850283309817314,
      -0.0656869187951088,
      0.0598912388086319,
      -0.04115142673254013,
      0.016266942024230957,
      0.02223597839474678,
      0.021988218650221825,
      -0.012633726000785828,
      0.05485158786177635,
      -0.009047291241586208,
      -0.0578887015581131,
      0.053514111787080765,
      -0.026125090196728706,
      -0.01001859549432993,
      -0.0009223214583471417,
      0.031762395054101944,
      0.038940634578466415,
      -0.014122791588306427,
      -0.04704772308468819,
      0.036426205188035965,
      -0.021809246391057968,
      0.007108950521796942,
      -0.004732635337859392,
      -0.037910494953393936,
      -0.06574416160583496,
      -0.016032369807362556,
      -0.02952088601887226,
      0.09380143880844116,
      -0.06942111998796463,
      0.05503038689494133,
      0.03012348897755146,
      0.0707283541560173,
      -0.019572105258703232,
      -0.019085593521595,
      -0.030301589518785477,
      -0.04957744479179382,
      0.03399105370044708,
      -0.0015389702748507261,
      -0.11707277595996857,
      -0.009037388488650322,
      -0.033497050404548645,
      0.014763708226382732,
      0.02431553229689598,
      0.03757316619157791,
      0.07918393611907959,
      -0.03988058120012283,
      -0.002761255018413067,
      0.02619933895766735,
      0.023923274129629135,
      0.0019690245389938354,
      0.016281791031360626,
      0.04138415306806564,
      -0.03006824292242527,
      -0.003044593147933483,
      0.03837096691131592,
      -0.018695663660764694,
      0.008119892328977585,
      -0.022524354979395866,
      0.01554547157138586,
      -0.0034656880889087915,
      0.014400741085410118,
      0.011020517908036709,
      -0.028709130361676216,
      0.07813531905412674,
      0.035408779978752136,
      -0.015581267885863781,
      -0.02466551773250103,
      0.07359583675861359,
      -0.005457413848489523,
      0.03639296442270279,
      -0.037366751581430435,
      -0.007507259491831064,
      -0.014576144516468048,
      0.0029619522392749786,
      0.02384716272354126,
      0.01351417787373066,
      0.07721766084432602,
      -0.0016630846075713634,
      -0.04469634220004082,
      0.019769692793488503,
      0.0144297881051898,
      -0.025991305708885193,
      0.004199551418423653,
      0.06284481287002563,
      0.055010341107845306,
      0.021791717037558556,
      0.00770104443654418,
      -0.00156310829333961,
      0.0617854967713356,
      0.03052498772740364,
      -0.047708094120025635,
      -0.05944666266441345,
      -0.04333421587944031,
      -0.018619336187839508,
      0.11851143091917038,
      0.014252777211368084,
      -0.01712142489850521,
      -0.016088327392935753,
      -0.02030172199010849,
      -0.05495471507310867,
      0.12932266294956207,
      0.003454364137724042,
      0.005798404570668936,
      -0.03393091633915901,
      0.009351670742034912,
      0.08374972641468048,
      -0.04072432592511177,
      0.011727776378393173,
      -0.020340174436569214,
      -0.03268099203705788,
      -0.05328977108001709,
      0.007509808521717787,
      0.06092211604118347,
      -0.008678724989295006,
      -0.034728601574897766,
      -0.031848106533288956,
      -0.007406568620353937,
      -0.004581219516694546,
      0.016137154772877693,
      -0.07062224298715591,
      0.058987807482481,
      0.04330141842365265,
      0.041638266295194626,
      0.06169140711426735,
      0.07713154703378677,
      0.026477552950382233,
      0.10468008369207382,
      -0.07952536642551422,
      0.0162479430437088,
      -0.03268227353692055,
      -0.04503561928868294,
      0.021299226209521294,
      0.10305633395910263,
      -0.014550688676536083,
      0.04293583706021309,
      -0.021157363429665565,
      0.014557168819010258,
      -0.07096372544765472,
      0.000970908731687814,
      0.031375277787446976,
      -0.030831338837742805,
      0.017553718760609627,
      -0.029085038229823112,
      -0.04048781841993332,
      0.03359629958868027,
      0.07050047814846039,
      -0.01047444622963667,
      0.022657636553049088,
      0.0934242233633995,
      -0.11541284620761871,
      0.015795426443219185,
      0.0004045171954203397,
      0.004525350406765938,
      -0.05453895032405853,
      0.06041952967643738,
      0.049809712916612625,
      -0.08701896667480469,
      0.041646458208560944,
      -0.016623686999082565,
      -0.01952866092324257,
      0.04072045162320137,
      0.11291879415512085,
      -0.02821229211986065,
      0.06291712820529938,
      -0.06415113061666489,
      -0.034028276801109314,
      0.04915402829647064,
      -0.004148666746914387,
      0.025076739490032196,
      0.04773696884512901,
      -0.015372884459793568,
      -0.013323836959898472,
      0.019683072343468666,
      0.007641178090125322,
      -0.06876309216022491,
      -0.04583268612623215,
      -0.0623483881354332,
      -0.04282020032405853,
      -0.06133194640278816,
      0.0850927010178566,
      -0.0010286429896950722,
      -0.03839170187711716,
      -0.016491105780005455,
      0.06043747439980507,
      -0.0360725037753582,
      -0.026219645515084267,
      0.008545693010091782,
      -0.026114366948604584,
      0.015591837465763092,
      -0.00500953895971179,
      0.004739963915199041,
      0.01449490711092949,
      0.003906701225787401,
      -0.02785143442451954,
      -0.08798600733280182,
      0.018814435228705406,
      0.04212431609630585,
      0.051477402448654175,
      0.023096006363630295,
      0.04888610914349556,
      0.001915495959110558,
      0.04907460883259773,
      0.03404010459780693,
      0.01850035786628723,
      0.013956189155578613,
      -0.039248235523700714,
      -0.003312381450086832,
      0.020430445671081543,
      0.05490977689623833,
      -0.039789143949747086,
      0.028546646237373352,
      0.014531990513205528,
      0.020954785868525505,
      0.05940660089254379,
      -0.049810636788606644,
      0.014459095895290375,
      0.0528942234814167,
      0.05545761063694954,
      -0.014693071134388447,
      0.013416951522231102,
      -0.04556983336806297,
      -0.0021734617184847593,
      0.012109789066016674,
      0.052314937114715576,
      -0.0035239586140960455,
      -0.003925178665667772,
      -0.034389201551675797,
      0.054355695843696594,
      -0.025718415156006813,
      0.00011494476348161697,
      -0.014572086744010448,
      0.1256835013628006,
      0.0012237327173352242,
      -0.015170256607234478,
      -0.019936248660087585,
      -0.0010899610351771116,
      0.006255443207919598,
      -0.1162642389535904,
      0.04916601628065109,
      -0.0534881129860878,
      -0.039172254502773285,
      0.00017712028056848794,
      0.033782992511987686,
      0.023047005757689476,
      -0.11171473562717438,
      0.0015401259297505021,
      0.03167278692126274,
      0.013147727586328983,
      0.05291623994708061,
      0.04406673461198807
    ],
    [
      0.07001672685146332,
      -0.02786143496632576,
      0.052245575934648514,
      0.03543118014931679,
      -0.029176799580454826,
      0.004026231821626425,
      -0.06193844601511955,
      -0.06659489125013351,
      0.01595277525484562,
      0.06373902410268784,
      -0.04095211997628212,
      -0.018453937023878098,
      0.004520338028669357,
      -0.022289356216788292,
      -0.005731638055294752,
      0.017624804750084877,
      -0.021874673664569855,
      0.06094804033637047,
      -0.07166468352079391,
      0.06796340644359589,
      -0.03735733404755592,
      0.02809740975499153,
      -0.05706742778420448,
      0.06516870856285095,
      -0.011938908137381077,
      0.05609293282032013,
      -0.01745513081550598,
      0.007579492870718241,
      0.0008057812810875475,
      -0.02386229857802391,
      0.039398908615112305,
      0.033875301480293274,
      -0.06680435687303543,
      -0.048530127853155136,
      0.09015529602766037,
      0.07323263585567474,
      0.042350687086582184,
      -0.008809376507997513,
      0.051378484815359116,
      0.014607748948037624,
      -0.02929086796939373,
      0.07307081669569016,
      0.03425811976194382,
      -0.06420145183801651,
      -0.0602659247815609,
      -0.00387071561999619,
      0.005118396133184433,
      -0.00438687251880765,
      -0.016043229028582573,
      -0.05046943202614784,
      -0.046111367642879486,
      0.01480565033853054,
      -0.06283118575811386,
      0.013809011317789555,
      -0.01982368342578411,
      0.08508467674255371,
      -0.00567439803853631,
      0.0725952610373497,
      -0.046372395008802414,
      0.01326956506818533,
      0.017722252756357193,
      -0.008584486320614815,
      -0.02490309439599514,
      -0.0978187546133995,
      -0.07976259291172028,
      0.04454629868268967,
      0.052471309900283813,
      0.04717923328280449,
      0.007767883129417896,
      0.018344935029745102,
      -0.036429185420274734,
      0.08960671722888947,
      -0.0760190412402153,
      -0.005740291438996792,
      0.0017162770964205265,
      0.05692888796329498,
      0.028225021436810493,
      0.028767824172973633,
      0.10120978951454163,
      -0.03245122358202934,
      0.041871655732393265,
      0.03879018500447273,
      0.034578412771224976,
      -0.04810672253370285,
      0.023334231227636337,
      -0.039389580488204956,
      0.0002323898661416024,
      -0.04736601933836937,
      -0.03173211216926575,
      0.021443873643875122,
      0.01995656080543995,
      -0.006892287638038397,
      -0.015393071807920933,
      -0.01659250073134899,
      0.03190044313669205,
      0.03141533210873604,
      -0.03776253014802933,
      -0.015678929165005684,
      0.09823401272296906,
      -0.06480220705270767,
      -0.02338816411793232,
      -0.0317961759865284,
      0.021580781787633896,
      -0.003197828307747841,
      -0.010615737177431583,
      0.041805852204561234,
      -0.09726761281490326,
      -0.05295875295996666,
      -0.02652660943567753,
      0.09124608337879181,
      0.057513948529958725,
      0.014614185318350792,
      -0.013510027900338173,
      -0.008525076322257519,
      -0.051477186381816864,
      -0.01725141890347004,
      -0.03651193156838417,
      -0.14409339427947998,
      -0.002943656872957945,
      -0.040925852954387665,
      -0.024403192102909088,
      0.07080234587192535,
      0.05237263813614845,
      0.03519907966256142,
      0.0259904395788908,
      -0.08408307284116745,
      -0.03257513791322708,
      0.06706682592630386,
      0.03451141342520714,
      -0.04168752580881119,
      -0.020774882286787033,
      -0.0032767264638096094,
      0.013941953890025616,
      -0.009040388278663158,
      0.024886498227715492,
      -0.00630176579579711,
      -0.1088365837931633,
      -0.0015331202885136008,
      0.026313960552215576,
      0.0046801455318927765,
      0.07416369765996933,
      -0.0072909025475382805,
      -0.0231665950268507,
      0.02314075268805027,
      -0.06499432027339935,
      0.04923892766237259,
      -0.007326007820665836,
      -0.021705683320760727,
      0.020422374829649925,
      -0.08988119661808014,
      0.029486829414963722,
      0.030272193253040314,
      -0.11852533370256424,
      -0.01212309394031763,
      0.03217528760433197,
      0.03999299556016922,
      -0.010089823044836521,
      -0.01067094225436449,
      0.06204688549041748,
      -0.01797865889966488,
      -0.04411165416240692,
      0.02856997586786747,
      -0.017085490748286247,
      0.03522346913814545,
      0.014139717444777489,
      -0.05304085090756416,
      -0.03893132507801056,
      0.09253370761871338,
      -0.03704197704792023,
      -0.019088558852672577,
      0.038282595574855804,
      0.00981735996901989,
      -0.0077022407203912735,
      0.03444774076342583,
      -0.027382202446460724,
      0.035371795296669006,
      0.007906222715973854,
      0.002690825378522277,
      -0.011387426406145096,
      0.034048546105623245,
      0.019493477419018745,
      -0.04620001092553139,
      -0.015637459233403206,
      -0.0007257087272591889,
      -0.02862931787967682,
      0.018337950110435486,
      0.005362814292311668,
      -0.02426954358816147,
      0.060314759612083435,
      0.01009809598326683,
      -0.10034376382827759,
      -0.019175782799720764,
      0.007103458512574434,
      -0.02563219889998436,
      0.04482874274253845,
      0.04735185578465462,
      0.03468068316578865,
      0.05899053439497948,
      0.017447879537940025,
      -0.05130786821246147,
      0.011437314562499523,
      -0.09234835207462311,
      -0.06156894937157631,
      0.005041818600147963,
      0.01575597934424877,
      0.027634024620056152,
      -0.0356181375682354,
      0.019201988354325294,
      0.05026668682694435,
      0.03934837132692337,
      0.006364304106682539,
      0.029711775481700897,
      0.04501930996775627,
      0.012441805563867092,
      0.008576855063438416,
      -0.08173985034227371,
      -0.02136526256799698,
      -0.0568997748196125,
      -0.060947638005018234,
      0.032914020121097565,
      0.017072439193725586,
      0.009317844174802303,
      0.009819470345973969,
      -0.01831047609448433,
      0.049460917711257935,
      -0.012153487652540207,
      0.032224904745817184,
      0.016362536698579788,
      0.04598689079284668,
      0.014840683899819851,
      -0.05789701268076897,
      0.03445382043719292,
      -0.03715195879340172,
      0.04824158176779747,
      0.033644091337919235,
      0.03556327149271965,
      -0.015672387555241585,
      0.015133647248148918,
      0.04428691416978836,
      0.031027168035507202,
      0.06262020766735077,
      -0.00014870605082251132,
      0.01795733906328678,
      -0.034672729671001434,
      0.004421666264533997,
      -0.08198097348213196,
      -0.05984005704522133,
      -0.019045358523726463,
      0.027891412377357483,
      -0.07859905809164047,
      0.0056582242250442505,
      0.05741675943136215,
      0.027602091431617737,
      -0.045877739787101746,
      0.01572273299098015,
      -0.0270805936306715,
      0.010579098016023636,
      -0.01244407705962658,
      -0.001654367079026997,
      -0.05198203772306442,
      0.05596430227160454,
      -0.037250738590955734,
      0.014752569608390331,
      -0.07020777463912964,
      0.0203184112906456,
      0.004040808416903019,
      -0.07040244340896606,
      -0.08789858967065811,
      0.006575341336429119,
      -0.05968346819281578,
      0.013562680222094059,
      0.09813752770423889,
      0.0946035161614418,
      0.016314422711730003,
      0.0022848625667393208,
      0.05418046563863754,
      0.0029777181334793568,
      0.02721407823264599,
      0.012775714509189129,
      -0.0061713214963674545,
      0.03374537453055382,
      0.026758847758173943,
      0.01842935010790825,
      -0.033010080456733704,
      -0.028405051678419113,
      -0.05859582498669624,
      -0.04556906968355179,
      -0.007236249279230833,
      0.03060447983443737,
      0.04772501811385155,
      0.01254623755812645,
      -0.0012767090229317546,
      0.009663686156272888,
      0.023935213685035706,
      0.049617789685726166,
      0.00392133928835392,
      0.004135457798838615,
      0.09468749165534973,
      -0.07909147441387177,
      -0.052794791758060455,
      0.03105042316019535,
      0.00439171027392149,
      -0.11412861943244934,
      0.06579391658306122,
      -0.025194076821208,
      0.014846875332295895,
      -0.023611094802618027,
      0.02186053991317749,
      -0.012280208058655262,
      -0.007815713062882423,
      0.05709641054272652,
      0.026526562869548798,
      -0.009893536567687988,
      0.007510066032409668,
      -0.019178612157702446,
      0.054724108427762985,
      -0.043121181428432465,
      -0.04548681899905205,
      -0.03512348607182503,
      0.017017891630530357,
      0.014034003019332886,
      -0.0758863314986229,
      0.09885019809007645,
      -0.03386610373854637,
      -0.045250508934259415,
      0.01698257401585579,
      0.04844815656542778,
      0.01378222368657589,
      0.11276562511920929,
      0.047151051461696625,
      -0.004057532642036676,
      -0.01493655052036047,
      0.08658558875322342,
      0.002119501819834113,
      0.0322708934545517,
      -0.08423648774623871,
      -0.014382226392626762,
      -0.0010414660209789872,
      -0.005591828376054764,
      -0.01258443295955658,
      -0.0839395523071289,
      0.00499633839353919,
      -0.01572410576045513,
      -0.0014153291704133153,
      -0.048829980194568634,
      -0.0002162426826544106,
      0.10338748246431351,
      -0.054463453590869904,
      0.001136726001277566,
      0.005909949075430632,
      0.033293046057224274,
      0.04402045160531998,
      -0.01981346681714058,
      -0.021575171500444412,
      0.07348036020994186,
      0.0030294875614345074,
      -0.0839466005563736,
      0.01805327646434307,
      -0.030828706920146942,
      0.03821101784706116,
      -0.005301615223288536,
      0.004208566155284643,
      0.016149429604411125,
      0.01338871754705906,
      0.04276672750711441,
      0.006660081911832094,
      0.011922603473067284,
      0.021650006994605064,
      -0.0022725884336978197,
      -0.04092897102236748,
      0.022621123120188713,
      0.027353519573807716,
      0.042273327708244324,
      -0.05867665633559227,
      -0.00033211347181349993,
      0.0029133495409041643,
      -0.013430815190076828,
      -0.04604983702301979,
      -0.06959948688745499,
      -0.012492041103541851,
      0.03598036989569664,
      0.02145770937204361,
      -0.02103552222251892,
      0.07285550981760025,
      0.07988142967224121,
      -0.029929526150226593,
      -0.004313745070248842,
      -0.052714359015226364,
      0.0047380635514855385,
      0.025151968002319336,
      -0.02330128848552704,
      -0.05131028592586517,
      -0.05187710374593735,
      0.004468545783311129,
      -0.019305570051074028,
      0.03879312053322792,
      -0.0046122814528644085,
      0.030969927087426186,
      -0.015681734308600426,
      0.007979724556207657,
      0.005743134301155806,
      0.06288162618875504,
      -0.06862356513738632,
      0.02877115271985531,
      0.1316363364458084,
      0.002592990640550852,
      -0.008493196219205856,
      0.10608533024787903,
      -0.0778556764125824,
      0.002803513780236244,
      -0.03361625596880913,
      -0.005529613234102726,
      -0.05049080029129982,
      -0.0394328273832798,
      -0.02033628523349762,
      0.00960171315819025,
      -0.005685974378138781,
      -0.05926420912146568,
      0.03717602789402008,
      -0.023402227088809013,
      -0.0366816371679306,
      -0.07618570327758789,
      0.05063127726316452,
      -0.10727700591087341,
      0.0251578688621521,
      0.04027976840734482,
      0.03305580094456673,
      -0.03864876180887222,
      -0.05769013985991478,
      0.004882688168436289,
      0.028400173410773277,
      -0.0065410141833126545,
      -0.01633933000266552,
      -0.023006165400147438,
      -0.03762440383434296,
      0.0011536950478330255,
      0.05199344828724861,
      -0.04493652656674385,
      0.05799808353185654,
      0.016200928017497063,
      0.010709678754210472,
      0.01015652809292078,
      -0.015431931242346764,
      -0.05012153834104538,
      -0.08977127075195312,
      -0.08125357329845428,
      0.018948251381516457,
      -0.02371951937675476,
      0.028442632406949997,
      -0.029284954071044922,
      -0.03993995487689972,
      -0.0496191680431366,
      0.010889328084886074,
      0.07249978184700012,
      -0.056873422116041183,
      -0.025589995086193085,
      0.07196251302957535,
      0.006196807138621807,
      0.03675636649131775,
      -0.03111385367810726,
      0.019815079867839813,
      0.04112820327281952,
      0.03291003033518791,
      -0.02190610021352768,
      -0.027439257130026817,
      -0.06575538963079453,
      -0.08235056698322296,
      0.029538020491600037,
      0.04487118124961853,
      0.08022533357143402,
      0.052217837423086166,
      -0.05910557135939598,
      -0.046292394399642944,
      0.005153296049684286,
      -0.03318767249584198,
      0.026603341102600098,
      -0.010524511337280273,
      -0.08100531250238419,
      -0.06673108041286469,
      0.054473768919706345,
      0.056522756814956665,
      -0.07490289211273193,
      -0.051753606647253036,
      -0.02000206895172596,
      -0.013239612802863121,
      -0.035254236310720444,
      -0.05035680904984474,
      0.050304073840379715,
      0.02050621248781681,
      0.02859787456691265,
      -0.020079556852579117,
      0.01771385967731476,
      -0.004025457892566919,
      -0.0042729685083031654,
      -0.031102262437343597,
      -0.031827010214328766,
      -0.01156756654381752,
      0.006168837193399668,
      0.03410298749804497,
      0.020539939403533936,
      0.05116313695907593,
      0.00848561804741621,
      -0.029123909771442413
    ],
    [
      -1.3199407476349734e-05,
      -0.10947196185588837,
      0.11203211545944214,
      0.0204264298081398,
      0.02903098054230213,
      -0.021091477945446968,
      0.04426814615726471,
      0.06794367730617523,
      -0.006690957583487034,
      0.004500886425375938,
      -0.059631869196891785,
      0.002912655472755432,
      0.03441062197089195,
      0.043248679488897324,
      0.034388910979032516,
      0.012921860441565514,
      -0.007240127306431532,
      -0.0034570663701742887,
      0.027610167860984802,
      0.0013076947070658207,
      -0.05261266231536865,
      -0.005735797341912985,
      -0.08963560312986374,
      0.03278886154294014,
      0.06404799968004227,
      -0.01185691636055708,
      0.009126602672040462,
      0.11149020493030548,
      0.06277652084827423,
      0.010683046653866768,
      0.06033467873930931,
      0.05370317026972771,
      0.041063643991947174,
      -0.030567487701773643,
      -0.07125992327928543,
      0.005754407029598951,
      0.07312565296888351,
      0.07594559341669083,
      0.017366457730531693,
      0.11153590679168701,
      0.027719322592020035,
      -0.0066816722974181175,
      -0.015137441456317902,
      0.03229421377182007,
      -0.011151792481541634,
      0.026277760043740273,
      -0.04672742262482643,
      -0.03861701861023903,
      -0.06428337097167969,
      -0.03831150755286217,
      0.07233408093452454,
      0.04717494919896126,
      -0.018265344202518463,
      0.0004523183743003756,
      0.04434738680720329,
      -0.005762237589806318,
      0.025924133136868477,
      0.1061709001660347,
      -0.0590393953025341,
      -0.005958653055131435,
      -0.001513153431005776,
      0.025453578680753708,
      -0.05763654410839081,
      -0.019853584468364716,
      -0.011102297343313694,
      -0.012509237974882126,
      -0.049027543514966965,
      0.09365613758563995,
      -0.03062666580080986,
      0.0031328783370554447,
      0.00614146376028657,
      -0.020012615248560905,
      0.023926379159092903,
      0.07010483741760254,
      0.043251607567071915,
      0.020899182185530663,
      0.07621118426322937,
      -0.07922052592039108,
      -0.03202575817704201,
      0.04823979735374451,
      -0.04652048274874687,
      -0.025335120037198067,
      -0.028536463156342506,
      -0.04531373083591461,
      0.00105136982165277,
      -0.04794729873538017,
      0.05368009954690933,
      -0.03974604606628418,
      -0.012936628423631191,
      0.022272033616900444,
      -0.03365708515048027,
      0.06499521434307098,
      0.04014752805233002,
      0.0471709780395031,
      -0.02815539948642254,
      -0.025445375591516495,
      0.02023324742913246,
      0.026751132681965828,
      0.015538127161562443,
      -0.00766869867220521,
      -0.023240089416503906,
      0.05444135144352913,
      0.008477429859340191,
      0.022673681378364563,
      -0.05539648234844208,
      -0.030501529574394226,
      -0.018142396584153175,
      0.04218637943267822,
      -0.051939286291599274,
      0.001221092534251511,
      0.06012492999434471,
      -0.02281193807721138,
      -0.026777122169733047,
      0.0012296945787966251,
      0.005592671222984791,
      0.0552448146045208,
      0.011625034734606743,
      -0.016250571236014366,
      -0.06869414448738098,
      -0.05554291978478432,
      0.007171051111072302,
      0.0992666557431221,
      -0.007800310384482145,
      0.015677472576498985,
      -0.02237391658127308,
      -0.026958398520946503,
      -0.06318254768848419,
      -0.0073312255553901196,
      -0.006059463135898113,
      -0.006227499805390835,
      0.004824654199182987,
      0.05012648552656174,
      -0.04026617109775543,
      0.07041330635547638,
      0.009826106950640678,
      -0.02541474439203739,
      0.01000948715955019,
      -0.015840621665120125,
      -0.03472378849983215,
      0.0456504188477993,
      0.028438718989491463,
      -0.004806266166269779,
      -3.257372372900136e-05,
      0.04940233752131462,
      0.05347910895943642,
      0.032599613070487976,
      0.004677563905715942,
      0.004232028499245644,
      0.019150685518980026,
      -0.02471117675304413,
      -0.08037735521793365,
      -0.02386126108467579,
      -0.008893019519746304,
      -0.04132060706615448,
      0.06138153374195099,
      0.019639816135168076,
      0.025454619899392128,
      -0.11372166872024536,
      -0.020198624581098557,
      0.011829107068479061,
      0.00014077435480430722,
      -0.016555095091462135,
      -0.006688879802823067,
      -0.011038512922823429,
      -0.015765514224767685,
      -0.01696707308292389,
      -0.016193971037864685,
      -0.026750968769192696,
      -0.008834274485707283,
      0.05001454800367355,
      0.014850455336272717,
      -0.04359646886587143,
      0.028179263696074486,
      0.07122904807329178,
      0.026781314983963966,
      0.13757558166980743,
      -0.04073258116841316,
      -0.04227449372410774,
      -0.021520253270864487,
      0.02313055656850338,
      -0.0374438539147377,
      -0.03615054115653038,
      0.022212928161025047,
      0.04830755293369293,
      -0.08817967772483826,
      0.0194365456700325,
      0.029086336493492126,
      -0.030414169654250145,
      -0.035023123025894165,
      -0.03694272041320801,
      -0.023275604471564293,
      0.0019217244116589427,
      -0.0733501985669136,
      -0.0008584500174038112,
      0.035699788480997086,
      0.020607659593224525,
      0.03935762122273445,
      -0.026198280975222588,
      -0.032307617366313934,
      -0.021674668416380882,
      -0.0027299849316477776,
      -0.013336063362658024,
      -0.05704217404127121,
      -0.028137044981122017,
      -0.020194966346025467,
      -0.03149740397930145,
      -0.05550514534115791,
      -0.019153162837028503,
      0.014429853297770023,
      0.00981950107961893,
      0.11511427164077759,
      -0.08038517087697983,
      0.017483143135905266,
      -0.025242948904633522,
      -0.029829103499650955,
      0.021276766434311867,
      0.01829880103468895,
      -0.005772370845079422,
      -0.025769606232643127,
      -0.011677663773298264,
      -0.01927335560321808,
      -0.07528632879257202,
      -0.011521256528794765,
      0.047551073133945465,
      -0.021484846249222755,
      -0.012564102187752724,
      -0.06021185219287872,
      -0.019220858812332153,
      -0.033121105283498764,
      -0.04776725545525551,
      -0.0668022558093071,
      0.010362396016716957,
      0.03476882725954056,
      -0.005416004918515682,
      -0.014555612578988075,
      -0.010775072500109673,
      -0.05220349505543709,
      -0.009374585002660751,
      -0.043693505227565765,
      -0.06459177285432816,
      0.03435702621936798,
      0.0236338023096323,
      -0.06007114052772522,
      -0.04260239005088806,
      0.055829863995313644,
      0.0697408989071846,
      0.025792796164751053,
      0.027663368731737137,
      0.02684078924357891,
      0.025589246302843094,
      0.05555960536003113,
      0.020096605643630028,
      -0.00508979894220829,
      0.024984071031212807,
      0.03467913717031479,
      0.030936403200030327,
      0.004527480807155371,
      0.011915390379726887,
      0.0718432366847992,
      -0.06159810721874237,
      0.058727942407131195,
      0.015430225059390068,
      0.017645884305238724,
      0.07842440158128738,
      0.01109657995402813,
      -0.006914789788424969,
      0.09499498456716537,
      0.008162134326994419,
      -0.09658531099557877,
      0.022602839395403862,
      0.035562362521886826,
      -0.02584882639348507,
      0.0697668269276619,
      0.008799944072961807,
      0.024972036480903625,
      0.020714256912469864,
      0.019148943945765495,
      -0.00036031025229021907,
      0.014796354807913303,
      -0.016011811792850494,
      -0.04434271156787872,
      0.013386938720941544,
      -0.016693979501724243,
      -0.07305298745632172,
      -0.07636894285678864,
      0.09772392362356186,
      0.0010661573614925146,
      -0.021760903298854828,
      0.0007340343436226249,
      0.06282348185777664,
      -0.00564197963103652,
      0.0165814608335495,
      0.0515434704720974,
      -0.039403777569532394,
      -0.00846264511346817,
      0.07624369859695435,
      0.025998232886195183,
      -0.10065330564975739,
      -0.00436625350266695,
      -0.012291386723518372,
      -0.048053350299596786,
      -0.009984184987843037,
      0.06638021022081375,
      -0.0431952029466629,
      -0.009364113211631775,
      -0.005916785914450884,
      -0.01942010223865509,
      0.058232229202985764,
      -0.10762039572000504,
      0.02037990279495716,
      -0.035096216946840286,
      -0.01322324387729168,
      0.027365587651729584,
      -0.013354269787669182,
      -0.019437525421380997,
      -0.0442391000688076,
      0.01678720861673355,
      0.013570385985076427,
      -0.036878861486911774,
      -0.031324997544288635,
      0.023330962285399437,
      0.06659047305583954,
      0.07972698658704758,
      0.03407328203320503,
      -0.04994532838463783,
      0.04105211794376373,
      0.07303991168737411,
      -0.00474198255687952,
      0.04694218561053276,
      -0.04936981201171875,
      -0.005674650426954031,
      0.024305276572704315,
      0.05577858164906502,
      -0.004154238849878311,
      -0.04496314376592636,
      -0.07458367198705673,
      -0.0023464795667678118,
      -0.04283295199275017,
      -0.03848063945770264,
      0.029462439939379692,
      0.03611462935805321,
      -0.022899528965353966,
      -0.021176502108573914,
      -0.016756772994995117,
      0.02418394573032856,
      -0.040539052337408066,
      -0.01595158874988556,
      0.0373845137655735,
      -0.01927717588841915,
      -0.04270794615149498,
      -0.006643442437052727,
      0.06601981073617935,
      0.026169104501605034,
      -0.12993021309375763,
      -0.0011168933706358075,
      -0.017681648954749107,
      0.0060786474496126175,
      0.008927746675908566,
      -0.027641788125038147,
      -0.09066849201917648,
      0.004164449870586395,
      -0.005584916565567255,
      -0.01348323654383421,
      0.02431485243141651,
      -0.017244204878807068,
      0.009866811335086823,
      -0.04770686849951744,
      0.04738960787653923,
      0.07769132405519485,
      -0.03127622231841087,
      -0.02463456615805626,
      0.047288231551647186,
      -0.01700921542942524,
      0.025724483653903008,
      -0.07775679975748062,
      -0.05255705863237381,
      0.12855872511863708,
      0.019688943400979042,
      0.03975311666727066,
      -0.06940067559480667,
      0.04521439969539642,
      0.03567320480942726,
      -0.028407609090209007,
      -0.025299249216914177,
      -0.0036301715299487114,
      -0.04822290316224098,
      -0.004669573158025742,
      -0.10205283761024475,
      0.008279978297650814,
      -0.05932227894663811,
      0.04767898842692375,
      -0.04109635949134827,
      -0.08009976148605347,
      0.0779724270105362,
      0.09077031910419464,
      -0.0572391115128994,
      -0.06418414413928986,
      0.0007740548462606966,
      -0.0293201245367527,
      -0.028315039351582527,
      0.016559714451432228,
      -0.05237724632024765,
      0.054485950618982315,
      0.02721022255718708,
      -0.041089873760938644,
      -0.006020999979227781,
      -0.03295489400625229,
      -0.06724443286657333,
      0.017500555142760277,
      0.022587204352021217,
      0.025055117905139923,
      -0.021656250581145287,
      -0.005500008352100849,
      0.04909602180123329,
      -0.06973869353532791,
      0.0021266681142151356,
      -0.04317609593272209,
      0.04770990088582039,
      0.05635812133550644,
      0.03356476500630379,
      -0.00759110227227211,
      0.026237502694129944,
      -0.025833718478679657,
      -0.009942752309143543,
      -0.03305087611079216,
      0.04950746148824692,
      0.016109691932797432,
      0.012469705194234848,
      -0.07489125430583954,
      0.09521976858377457,
      -0.008784055709838867,
      0.007882613688707352,
      -0.005591140128672123,
      0.015841783955693245,
      -0.03929585963487625,
      -0.059393931180238724,
      0.04304497316479683,
      -0.04975774139165878,
      0.014927385374903679,
      0.05547589436173439,
      -0.04605500027537346,
      -0.02099490351974964,
      -0.05339673534035683,
      -0.04697761312127113,
      0.041035864502191544,
      -0.05047830566763878,
      0.015534394420683384,
      0.02398337610065937,
      0.03247411549091339,
      0.024792233482003212,
      0.03783734515309334,
      -0.018856503069400787,
      0.035304151475429535,
      0.04284302890300751,
      0.004342370200902224,
      -0.056279320269823074,
      0.09325416386127472,
      -0.02751993015408516,
      -0.12446358054876328,
      0.05933914706110954,
      0.006728639360517263,
      0.07056403160095215,
      0.034534256905317307,
      0.0068963137455284595,
      0.019963489845395088,
      -0.0589929036796093,
      -0.05341847240924835,
      0.040777064859867096,
      -0.08582596480846405,
      -0.033554624766111374,
      0.027294883504509926,
      0.05354594811797142,
      0.013179679401218891,
      0.06340910494327545,
      -0.01899373158812523,
      0.0389275997877121,
      -0.0015006627654656768,
      -0.056772585958242416,
      0.03983230143785477,
      -0.05971754714846611,
      0.0012148364912718534,
      -0.023757444694638252,
      0.08612653613090515,
      0.059774938970804214,
      0.014719203114509583,
      0.006012024357914925,
      0.013567379675805569,
      0.011948485858738422,
      0.031180718913674355,
      0.026073968037962914,
      0.0484025813639164,
      0.033781297504901886,
      -0.00706727197393775,
      -0.06662637740373611,
      0.05168856680393219,
      0.048230379819869995,
      0.017867013812065125,
      0.03556343540549278,
      -0.03243584185838699,
      -0.013505238108336926,
      0.04498155042529106,
      -0.03228701651096344,
      0.04772193357348442
    ],
    [
      -0.02635863982141018,
      0.008813242428004742,
      -0.09027296304702759,
      -0.009234743192791939,
      -0.051701970398426056,
      -0.002375558251515031,
      0.02264818735420704,
      0.0059931012801826,
      -0.0018359250389039516,
      0.01301292609423399,
      -0.009113741107285023,
      -0.013484934344887733,
      -0.07395412027835846,
      0.03849608823657036,
      0.02864757925271988,
      -0.065493643283844,
      0.10090874135494232,
      2.9703198379138485e-05,
      -0.013871990144252777,
      0.004776683636009693,
      0.06214604899287224,
      -0.10722379386425018,
      0.05281560495495796,
      -0.07708406448364258,
      -0.05076451972126961,
      -0.00365163991227746,
      -0.005746857728809118,
      -0.03803075850009918,
      -0.013498026877641678,
      0.03636442869901657,
      0.007891467772424221,
      0.042473044246435165,
      -0.03592255711555481,
      -0.055105190724134445,
      0.005212031304836273,
      0.019934119656682014,
      0.07303640991449356,
      0.00461205281317234,
      5.1066646847175434e-05,
      -0.004271138925105333,
      0.0261087566614151,
      -0.0008827713318169117,
      0.04677125811576843,
      0.012969901785254478,
      -0.03846019506454468,
      0.05624004825949669,
      0.06132229417562485,
      -0.005011227447539568,
      0.062488850206136703,
      0.019949810579419136,
      -0.061371780931949615,
      -0.053911447525024414,
      -0.027159687131643295,
      -0.01298739854246378,
      -0.0006180414347909391,
      -0.0347910076379776,
      -0.09535960108041763,
      -0.04210922494530678,
      0.009379527531564236,
      0.08375184237957001,
      -0.01093366090208292,
      0.06344498693943024,
      0.016847921535372734,
      0.02688845619559288,
      -0.07171197980642319,
      -0.027382101863622665,
      0.03061998076736927,
      0.0036512890364974737,
      0.013433299027383327,
      0.06522343307733536,
      -0.017532596364617348,
      0.037332434207201004,
      0.034843601286411285,
      -0.028642568737268448,
      -0.06464967876672745,
      -0.0002562931622378528,
      0.004011066164821386,
      0.049054764211177826,
      0.005071569699794054,
      0.026207704097032547,
      -0.003037398448213935,
      0.08654884248971939,
      -0.041114188730716705,
      0.025120913982391357,
      -0.053737055510282516,
      0.0012575277360156178,
      0.08029728382825851,
      -0.02774360030889511,
      0.021141724660992622,
      0.12193756550550461,
      -0.023692313581705093,
      -0.010291814804077148,
      -0.0496835857629776,
      0.030099347233772278,
      0.002465000143274665,
      -0.05275777354836464,
      -0.09081923961639404,
      0.022060930728912354,
      0.015656927600502968,
      -0.09264430403709412,
      -0.0422329306602478,
      0.06092273071408272,
      -0.0026749747339636087,
      0.020925743505358696,
      0.03527884930372238,
      0.11050371080636978,
      0.013364334590733051,
      0.047444868832826614,
      0.07357525080442429,
      -0.014181316830217838,
      0.0018984037451446056,
      0.0009483899921178818,
      0.03401608765125275,
      -0.02156282588839531,
      -0.024466775357723236,
      -0.053946930915117264,
      0.016492735594511032,
      0.0035227653570473194,
      0.0014552762731909752,
      -0.012875057756900787,
      -0.029446711763739586,
      0.0406770296394825,
      -0.035776831209659576,
      0.0023231294471770525,
      -0.005246455315500498,
      0.09796398878097534,
      0.023084595799446106,
      0.04767310991883278,
      0.047177255153656006,
      -0.04553999751806259,
      0.01479465700685978,
      -0.02579561434686184,
      0.02850569225847721,
      -0.021238526329398155,
      0.03778187558054924,
      -0.030900433659553528,
      -0.06207600608468056,
      0.035569075495004654,
      0.01825876720249653,
      0.08139529079198837,
      0.021706199273467064,
      0.016565149649977684,
      0.009510591626167297,
      -0.04759076237678528,
      0.03232909366488457,
      -0.02146606333553791,
      -0.02274739183485508,
      0.04497216269373894,
      -0.03664403781294823,
      -0.03750958293676376,
      -0.05660400912165642,
      0.01388674508780241,
      -0.0068024201318621635,
      0.016659583896398544,
      0.029941681772470474,
      -0.03227003291249275,
      0.023441189900040627,
      -0.051300447434186935,
      -0.04751230776309967,
      -0.05473720282316208,
      0.020463556051254272,
      0.04434247314929962,
      0.05154124274849892,
      0.03319516405463219,
      0.03622857481241226,
      0.014703596942126751,
      0.0013443658826872706,
      -0.007332074921578169,
      0.016928015276789665,
      0.08746134489774704,
      0.025725482031702995,
      0.016854742541909218,
      0.07702530920505524,
      -0.024265604093670845,
      -0.045980870723724365,
      0.0721660628914833,
      0.029532205313444138,
      -0.04627106711268425,
      0.02854442223906517,
      0.06877535581588745,
      -0.04848358780145645,
      0.02020847424864769,
      0.01786227896809578,
      0.035995617508888245,
      0.02812817692756653,
      -0.008640185929834843,
      0.030781352892518044,
      0.024515394121408463,
      -0.004387870896607637,
      0.02128821425139904,
      0.014628589153289795,
      -0.01947469636797905,
      0.020964084193110466,
      -0.04363980144262314,
      -0.07396267354488373,
      -0.032725606113672256,
      0.011370346881449223,
      -0.01231002900749445,
      0.02780299261212349,
      -0.04241747409105301,
      -0.0221511609852314,
      -0.011308033019304276,
      0.0124324019998312,
      -0.05457227677106857,
      0.03334464877843857,
      0.06021610647439957,
      0.10553096979856491,
      -0.08552423119544983,
      0.06901003420352936,
      0.04374628886580467,
      0.05641455575823784,
      0.012950966134667397,
      0.006597898434847593,
      -0.047300755977630615,
      -0.04820432513952255,
      0.06221820414066315,
      -0.08429403603076935,
      -0.0796969085931778,
      -0.06041133776307106,
      -0.01336659211665392,
      -0.02941102348268032,
      -0.07220641523599625,
      0.026457499712705612,
      -0.01605534926056862,
      -0.024721477180719376,
      0.059651535004377365,
      0.06671106815338135,
      0.00024361355463042855,
      -0.08911935985088348,
      0.0017311073606833816,
      -0.03570715710520744,
      0.02039523236453533,
      -0.04047833010554314,
      0.0007365432102233171,
      -0.0636301189661026,
      -0.007925388403236866,
      0.03563225641846657,
      -0.05243416503071785,
      0.012477261945605278,
      0.0799659788608551,
      0.06433074921369553,
      0.023052792996168137,
      -0.02824404090642929,
      0.0248145442456007,
      0.021674973890185356,
      -0.06571517884731293,
      0.003300625365227461,
      0.07441792637109756,
      -0.015420600771903992,
      0.06163356453180313,
      0.0053119114600121975,
      0.006171379238367081,
      -0.0014805468963459134,
      -0.008285940624773502,
      -0.03875838592648506,
      0.020421771332621574,
      -0.00317348912358284,
      -0.018089694902300835,
      -0.05469770357012749,
      -0.03168247640132904,
      0.029525699093937874,
      0.013319320976734161,
      -0.047998733818531036,
      -0.07828563451766968,
      -0.04257047921419144,
      -0.01500096544623375,
      0.014714915305376053,
      0.011595776304602623,
      -0.07455344498157501,
      0.030808255076408386,
      0.011319651268422604,
      -0.01171321514993906,
      0.05516866594552994,
      0.030680498108267784,
      -0.05455946922302246,
      0.03358852490782738,
      0.02447240613400936,
      -0.004115553107112646,
      0.003911738283932209,
      0.021286381408572197,
      -0.009300618432462215,
      0.009740467183291912,
      0.02383708581328392,
      0.05215532332658768,
      -0.022671891376376152,
      0.03130041062831879,
      0.010941468179225922,
      -0.019451173022389412,
      -0.0012554437853395939,
      0.015583482570946217,
      -0.04621993750333786,
      0.010312659665942192,
      -0.02009219117462635,
      0.008431710302829742,
      0.023856401443481445,
      -0.005968316923826933,
      0.08272788673639297,
      0.018446261063218117,
      0.02376387268304825,
      0.003538999008014798,
      0.020541364327073097,
      0.017413360998034477,
      0.019936108961701393,
      0.009475773200392723,
      -0.008462420664727688,
      0.00444643571972847,
      0.011637402698397636,
      0.016222849488258362,
      0.05222342535853386,
      0.09976129978895187,
      0.040501851588487625,
      -0.025751834735274315,
      -0.10059969127178192,
      0.02471616491675377,
      0.035403404384851456,
      -0.006362004205584526,
      -0.052068617194890976,
      -0.1336805373430252,
      0.003733724122866988,
      0.09585649520158768,
      0.06723476201295853,
      -0.059565816074609756,
      0.04630076512694359,
      -0.008508872240781784,
      0.02953789010643959,
      -0.07783742249011993,
      -0.03684348240494728,
      0.017405349761247635,
      -0.02149883285164833,
      0.10160569101572037,
      0.04520843178033829,
      0.010398682206869125,
      0.09519106894731522,
      0.04736310616135597,
      0.05217665806412697,
      -0.032986439764499664,
      0.0853915736079216,
      0.07166402786970139,
      0.053358715027570724,
      0.031048618257045746,
      -0.00015744900156278163,
      -0.17084263265132904,
      0.07333720475435257,
      -0.04807165265083313,
      0.03987851366400719,
      0.019522950053215027,
      0.03541729971766472,
      -0.043955668807029724,
      -0.03742626681923866,
      -0.06190292537212372,
      -0.004058660473674536,
      -0.08407577872276306,
      -0.027613556012511253,
      0.043171461671590805,
      -0.04699819162487984,
      0.003254616167396307,
      -0.01357695460319519,
      -0.11381653696298599,
      0.030630599707365036,
      -0.05837497115135193,
      -0.03434515371918678,
      0.07172710448503494,
      -0.040688060224056244,
      -0.06737902015447617,
      0.0183173306286335,
      -0.016750618815422058,
      0.07917395979166031,
      -0.02809920348227024,
      0.05516159534454346,
      -0.014669409021735191,
      0.08199772238731384,
      -0.0116016436368227,
      0.002526998519897461,
      0.07258383929729462,
      0.03240147978067398,
      0.06313928216695786,
      0.009433621540665627,
      0.012131457217037678,
      0.026602046564221382,
      0.08528991788625717,
      -0.005574647802859545,
      -0.016676386818289757,
      0.032717514783144,
      0.03738811984658241,
      0.01910434290766716,
      -0.07150457054376602,
      0.003364273812621832,
      0.03357415273785591,
      0.02905968204140663,
      -0.050373636186122894,
      0.0017029520822688937,
      -0.05430678650736809,
      -0.024840552359819412,
      -0.07347913086414337,
      -0.018772028386592865,
      -0.0352935791015625,
      -0.012696589343249798,
      0.01557280495762825,
      -0.0019941050559282303,
      0.018639735877513885,
      0.016312865540385246,
      0.07230616360902786,
      -0.0003726363938767463,
      0.021355638280510902,
      0.0019377607386559248,
      -0.017264649271965027,
      -0.023621240630745888,
      -0.01849457435309887,
      -0.146029993891716,
      0.06536809355020523,
      -0.013271226547658443,
      -0.00957468617707491,
      0.05011756718158722,
      0.030590755864977837,
      -0.032656360417604446,
      0.04273392632603645,
      0.008655957877635956,
      -0.06378223747015,
      -0.015252067707479,
      -0.09003837406635284,
      -0.022803226485848427,
      -0.021989962086081505,
      -0.029515471309423447,
      0.01921735145151615,
      -0.07560138404369354,
      0.08108960837125778,
      0.06305604428052902,
      0.045488592237234116,
      0.020081983879208565,
      0.023863021284341812,
      -0.02403903566300869,
      0.04787324741482735,
      0.07123548537492752,
      0.041921645402908325,
      0.054731834679841995,
      0.010177604854106903,
      -0.08186858147382736,
      -0.04584968835115433,
      0.040449775755405426,
      0.004672669339925051,
      -0.03341110050678253,
      -0.03670617938041687,
      0.10151060670614243,
      -0.0031058064196258783,
      0.006481678690761328,
      0.029157208278775215,
      -0.06487898528575897,
      -0.031003829091787338,
      -0.046725332736968994,
      0.03261818364262581,
      -0.011727645061910152,
      -0.06493844836950302,
      0.0468793548643589,
      -0.018421422690153122,
      -0.10230623185634613,
      -0.03046290948987007,
      -0.04878813028335571,
      -0.033262938261032104,
      0.007742532063275576,
      0.0006476189009845257,
      -0.03212197497487068,
      0.046543095260858536,
      0.0014046973083168268,
      -0.012137033976614475,
      0.08470045030117035,
      0.03250201791524887,
      0.1316068023443222,
      -0.021241113543510437,
      -0.01045972853899002,
      -0.013034299947321415,
      0.0017242493340745568,
      -0.053967274725437164,
      0.005546161904931068,
      -0.009033598937094212,
      0.019544918090105057,
      -0.02451154589653015,
      -0.015367090702056885,
      0.035357728600502014,
      -0.04908491298556328,
      0.06229289993643761,
      0.033661264926195145,
      -0.026105491444468498,
      -0.01072713453322649,
      0.03607812523841858,
      0.033619362860918045,
      -0.06435646861791611,
      -0.00036037201061844826,
      0.0006602240027859807,
      -0.034268517047166824,
      -0.002303630579262972,
      -0.0634714812040329,
      -0.0390978567302227,
      0.035833582282066345,
      0.03809472173452377,
      0.014496422372758389,
      -0.03911247476935387,
      0.000324067659676075,
      -0.0642319843173027,
      0.024471541866660118,
      -0.1227322667837143,
      -0.037641920149326324,
      -0.01828724704682827,
      0.09558207541704178
    ],
    [
      0.031894199550151825,
      0.07324356585741043,
      -0.08002910017967224,
      0.07469994574785233,
      -0.020186010748147964,
      0.0918005108833313,
      -0.004576553590595722,
      0.004022989887744188,
      -0.0006398974219337106,
      -0.0461156964302063,
      -0.0940590351819992,
      -0.0023329691030085087,
      0.06237241253256798,
      0.02033066190779209,
      -0.019083548337221146,
      0.025981716811656952,
      0.04144287109375,
      0.09636392444372177,
      -0.07855810225009918,
      0.03938615694642067,
      0.04122549295425415,
      -0.016985880210995674,
      0.008594133891165257,
      0.08661435544490814,
      -0.03462588042020798,
      -0.009204786270856857,
      -0.014889739453792572,
      0.028102567419409752,
      0.020426591858267784,
      0.059468042105436325,
      -0.052111685276031494,
      -0.03214435651898384,
      -0.05785560607910156,
      0.06502682715654373,
      0.03640202432870865,
      -0.01753268763422966,
      0.08793236315250397,
      0.10701209306716919,
      -0.04818760231137276,
      -0.03623194247484207,
      0.023957079276442528,
      -0.017352677881717682,
      0.03761521726846695,
      0.014527042396366596,
      -0.025192731991410255,
      -0.009474409744143486,
      -0.0137465326115489,
      -0.013791440986096859,
      -0.013553012162446976,
      0.012969058938324451,
      0.02460828423500061,
      0.09442886710166931,
      -0.014611906372010708,
      -0.06945587694644928,
      -0.024125836789608,
      0.028887657448649406,
      -0.04271501675248146,
      0.01624593883752823,
      -0.0022620530799031258,
      0.0021951557137072086,
      -0.009942427277565002,
      0.02103334292769432,
      -0.02181381732225418,
      -0.04393326863646507,
      -0.029570307582616806,
      0.02664753422141075,
      -0.014919978566467762,
      -0.0666082352399826,
      0.07426220923662186,
      0.002755997469648719,
      0.06493259966373444,
      -0.01614496111869812,
      -0.01312352903187275,
      -0.012502656318247318,
      0.07293723523616791,
      0.0832611694931984,
      -0.020818576216697693,
      -0.02175397239625454,
      -0.0024227264802902937,
      0.06020795553922653,
      0.06095597892999649,
      -0.02308984100818634,
      0.03726451098918915,
      0.04144410789012909,
      0.06915830820798874,
      0.0904078409075737,
      0.07673825323581696,
      0.015620157122612,
      -0.009627494029700756,
      -0.022409172728657722,
      0.030342750251293182,
      -0.0480741411447525,
      -0.018478961661458015,
      0.08237297832965851,
      0.05036388337612152,
      -0.01732417196035385,
      0.08022715896368027,
      -0.05183038488030434,
      -0.03793832287192345,
      0.11333119869232178,
      -0.04166267067193985,
      0.030699322000145912,
      -0.006114146206527948,
      0.05903290584683418,
      0.00931435078382492,
      -0.03563688322901726,
      0.012080739252269268,
      0.059054456651210785,
      0.04862019419670105,
      -0.03516509756445885,
      0.0038337968289852142,
      -0.02156689204275608,
      -0.049104902893304825,
      -0.039914533495903015,
      0.0191811453551054,
      -0.004637886770069599,
      0.0010072258301079273,
      0.08935066312551498,
      -0.04953876882791519,
      -0.012136650271713734,
      0.005872264038771391,
      -0.020232265815138817,
      -0.014777347445487976,
      -0.040131114423274994,
      -0.07588329911231995,
      0.0982416570186615,
      0.041321221739053726,
      -0.009031507186591625,
      0.008945950306952,
      0.005557106342166662,
      0.08271415531635284,
      -0.044247012585401535,
      -0.06221820414066315,
      -0.040858689695596695,
      -0.08381056785583496,
      -0.00033315434120595455,
      -0.01766454055905342,
      0.004555544350296259,
      -0.012495477683842182,
      0.05708670988678932,
      -0.06273920834064484,
      -0.06417588889598846,
      -0.023507703095674515,
      0.03215815871953964,
      0.008324497379362583,
      0.013561607338488102,
      0.018085669726133347,
      -0.07331852614879608,
      -0.01057402417063713,
      -0.011523084715008736,
      -0.010448826476931572,
      -0.008100435137748718,
      0.05500008538365364,
      0.06942404806613922,
      0.021430116146802902,
      0.051136333495378494,
      0.006119543220847845,
      0.011831054463982582,
      0.026577671989798546,
      0.03776278719305992,
      -0.01796581968665123,
      -0.02595219388604164,
      0.040642209351062775,
      0.07786132395267487,
      0.03653491660952568,
      0.06106269732117653,
      0.0038054934702813625,
      0.01083521731197834,
      -0.02664533257484436,
      -0.049036625772714615,
      0.02576785534620285,
      0.024228397756814957,
      -0.02236141823232174,
      0.008358871564269066,
      0.0006299820961430669,
      0.0022953066509217024,
      0.000688835745677352,
      -0.0674641951918602,
      0.025680027902126312,
      0.11964412033557892,
      0.013130826875567436,
      0.0033351194579154253,
      -0.01010297704488039,
      -0.059348005801439285,
      -0.013172591105103493,
      -0.008942468091845512,
      -0.0691101998090744,
      0.06271710246801376,
      0.06345649808645248,
      0.010960275307297707,
      0.017915358766913414,
      -0.04082956537604332,
      0.026276124641299248,
      0.06350856274366379,
      0.00451731076464057,
      -0.05716853588819504,
      0.010029497556388378,
      0.060913365334272385,
      -0.028822729364037514,
      -0.03299478441476822,
      0.00032064857077784836,
      -0.05753374472260475,
      0.003990868106484413,
      0.043184544891119,
      0.011266140267252922,
      -0.05953235551714897,
      -0.08026118576526642,
      -0.06960994005203247,
      -0.0059113712050020695,
      0.13314832746982574,
      0.08526571840047836,
      -0.03602732717990875,
      0.005785489920526743,
      0.04364285245537758,
      -0.07296283543109894,
      -0.005516259931027889,
      -0.055851489305496216,
      0.012552636675536633,
      -0.04184672236442566,
      -0.057530395686626434,
      0.02551909163594246,
      -0.05445944145321846,
      0.007507819216698408,
      0.04220804572105408,
      0.03343021124601364,
      -0.007886646315455437,
      0.07295920699834824,
      -0.08472215384244919,
      0.0013059178600087762,
      -0.10728392750024796,
      -0.038484323769807816,
      -0.054279692471027374,
      -0.00014371168799698353,
      0.003657256718724966,
      -0.001091226120479405,
      -0.03385964035987854,
      0.05869648978114128,
      0.0034388729836791754,
      -0.021629171445965767,
      0.019542334601283073,
      -0.023322269320487976,
      -0.01725202240049839,
      0.009393622167408466,
      0.048490703105926514,
      0.02295277640223503,
      -0.028266653418540955,
      0.013047745451331139,
      -0.03902426362037659,
      -0.014271503314375877,
      0.06456294655799866,
      0.012638291344046593,
      -0.031293727457523346,
      0.0034708448220044374,
      0.0758233517408371,
      0.05731089413166046,
      -0.0042742770165205,
      0.07195854187011719,
      0.03114783763885498,
      -0.017719244584441185,
      0.01654769852757454,
      0.015769112855196,
      0.006836166605353355,
      0.0018044268945232034,
      -0.03767034038901329,
      -0.027367476373910904,
      -0.002730627777054906,
      0.08390707522630692,
      0.01905999705195427,
      -0.039379630237817764,
      -0.09553924947977066,
      -0.007072085049003363,
      -0.043683625757694244,
      0.018659338355064392,
      -0.025193573907017708,
      -0.013115656562149525,
      -0.10230118036270142,
      -0.04314768314361572,
      0.07476764917373657,
      -0.030850686132907867,
      0.037143100053071976,
      0.011833984404802322,
      -0.08080814778804779,
      0.012550931423902512,
      0.007470154203474522,
      0.012235932052135468,
      0.008479710668325424,
      0.014756977558135986,
      0.014197413809597492,
      0.01600375771522522,
      0.028384359553456306,
      -0.02101917192339897,
      0.019595498219132423,
      -0.0011242724722251296,
      -0.008427740074694157,
      0.020356789231300354,
      -0.0424620546400547,
      -0.023803507909178734,
      -0.09783346205949783,
      0.029688101261854172,
      0.028140921145677567,
      0.03770669549703598,
      0.036919157952070236,
      -0.01601073332130909,
      0.03885449469089508,
      -0.042440254241228104,
      -0.01823280192911625,
      -0.07632657885551453,
      -0.0028561789076775312,
      -0.04499875009059906,
      0.047051284462213516,
      0.05298492684960365,
      0.0615193173289299,
      -0.00901616271585226,
      -0.042105939239263535,
      -0.060276515781879425,
      0.025476673617959023,
      -0.0228619072586298,
      -0.03916003555059433,
      0.0801418200135231,
      0.024640917778015137,
      0.05328524485230446,
      -0.02198302373290062,
      0.03133419528603554,
      -0.015765748918056488,
      -0.06300215423107147,
      -0.09181470423936844,
      -0.004216503351926804,
      -0.07334040850400925,
      -0.06061077490448952,
      -0.005070441868156195,
      0.07676039636135101,
      -0.02695082314312458,
      -0.04148012027144432,
      0.04323757067322731,
      0.07716171443462372,
      0.03674617409706116,
      -0.016406286507844925,
      0.03780343756079674,
      0.0376468189060688,
      -0.07708016782999039,
      0.060903314501047134,
      0.00046497787116095424,
      -0.11927492916584015,
      0.0387892872095108,
      -0.0043480293825268745,
      0.0024883693549782038,
      -0.031475044786930084,
      -0.057057831436395645,
      -0.05959402024745941,
      -0.02971004508435726,
      0.04666680097579956,
      0.01320844329893589,
      0.046736329793930054,
      0.016644451767206192,
      0.05464533343911171,
      -0.027043912559747696,
      -0.05373170226812363,
      -0.005008805077522993,
      0.03402147814631462,
      -0.09215353429317474,
      -0.09077102690935135,
      -0.004375485237687826,
      -0.006900116801261902,
      -0.11057686805725098,
      -0.05694814398884773,
      0.046499792486429214,
      0.024230169132351875,
      -0.06932230293750763,
      0.025738125666975975,
      0.02726096101105213,
      0.02872193418443203,
      0.04055109992623329,
      0.052487365901470184,
      0.06542055308818817,
      0.034403376281261444,
      -0.03331555798649788,
      0.028541168197989464,
      -0.1041170209646225,
      0.016668157652020454,
      0.05616962909698486,
      0.06374217569828033,
      -0.053449660539627075,
      0.011422255076467991,
      -0.04037844389677048,
      0.10530101507902145,
      -0.028568902984261513,
      -0.038398195058107376,
      -0.026430778205394745,
      0.0031309821642935276,
      -0.06381294131278992,
      0.09016970545053482,
      -0.005062764044851065,
      -0.017113665118813515,
      0.053895991295576096,
      -0.04534607380628586,
      0.05801399424672127,
      0.01666097715497017,
      -0.029083864763379097,
      -0.03935857489705086,
      -0.008323718793690205,
      0.008913408033549786,
      0.005130129866302013,
      0.03563137724995613,
      -0.06087011843919754,
      0.05065663903951645,
      -0.04852824658155441,
      -0.033277761191129684,
      0.018985126167535782,
      0.006429226137697697,
      -0.06346148997545242,
      0.010599923320114613,
      0.013866093009710312,
      0.03082350268959999,
      0.10483039170503616,
      0.04021753743290901,
      -0.028277453035116196,
      -0.05318553000688553,
      0.0816945806145668,
      -0.08806915581226349,
      0.032893985509872437,
      0.07990292459726334,
      0.031567834317684174,
      0.05427638068795204,
      -0.012451053597033024,
      0.007005538325756788,
      0.009977804496884346,
      0.08534260094165802,
      -0.05130435898900032,
      -0.055968768894672394,
      0.0191936232149601,
      0.010429860092699528,
      0.006028551142662764,
      -0.037900254130363464,
      0.03981516882777214,
      -0.00160928291734308,
      -0.06272337585687637,
      -0.04036935418844223,
      -0.013364209793508053,
      -0.06078345701098442,
      -0.035898998379707336,
      -0.07952631264925003,
      -0.028270594775676727,
      0.04809575527906418,
      -0.013764905743300915,
      0.03066258877515793,
      -0.0006672077579423785,
      0.015801066532731056,
      0.0336332805454731,
      -0.057983990758657455,
      0.004459380637854338,
      -0.007093882653862238,
      -8.11354402685538e-06,
      0.08913663774728775,
      0.06310378760099411,
      0.0023768222890794277,
      -0.0072667766362428665,
      -0.031147656962275505,
      -0.009098092094063759,
      0.047303929924964905,
      -0.01924063079059124,
      -0.0031415303237736225,
      -0.08466733247041702,
      0.007060199975967407,
      0.014291645959019661,
      -0.013648653402924538,
      -0.05213755741715431,
      -0.015554455108940601,
      -0.02665775828063488,
      -0.037675850093364716,
      -0.07660166174173355,
      0.09007880836725235,
      -0.03169093281030655,
      -0.07360374927520752,
      0.05208732932806015,
      -0.026731545105576515,
      0.0002753244189079851,
      0.029111310839653015,
      -0.004307062830775976,
      0.012343242764472961,
      -0.047648556530475616,
      0.008998117409646511,
      -0.010072927922010422,
      -0.0007458164473064244,
      0.05015219748020172,
      0.04983062297105789,
      0.04294700175523758,
      -0.06589413434267044,
      -0.0020950771868228912,
      -0.03208887204527855,
      -0.03060709685087204,
      -0.06297332793474197,
      0.08440817892551422,
      -0.06558682024478912,
      0.044940583407878876,
      -0.03163740411400795,
      0.01574057526886463,
      0.011979659087955952,
      -0.007802148815244436,
      -0.026071714237332344,
      0.03707980737090111,
      -0.06032746657729149,
      -0.029773270711302757,
      -0.012064434587955475
    ],
    [
      0.05191948637366295,
      -0.005770173389464617,
      -0.0016984684625640512,
      0.020705116912722588,
      -0.02912764437496662,
      -0.014344815164804459,
      0.010260681621730328,
      -0.0016949053388088942,
      0.03292901813983917,
      0.028933975845575333,
      0.017431065440177917,
      -0.07453902810811996,
      -0.010027477517724037,
      -0.026895325630903244,
      0.053823038935661316,
      -0.009812102653086185,
      0.005443165544420481,
      0.02797580324113369,
      -0.009487289004027843,
      -0.07679097354412079,
      -0.042333319783210754,
      -0.04392164200544357,
      0.009341335855424404,
      -0.03671771287918091,
      -0.05005760118365288,
      -0.04616618901491165,
      0.032217178493738174,
      -0.016128379851579666,
      -0.02414751797914505,
      0.05295853689312935,
      -0.07283487170934677,
      -0.0319000780582428,
      -0.03440694883465767,
      0.03080691024661064,
      -0.034070659428834915,
      0.02170737460255623,
      0.02271263673901558,
      -0.028552010655403137,
      -0.04277695715427399,
      -0.02255430817604065,
      0.07229287177324295,
      -0.035009682178497314,
      0.0070066433399915695,
      -0.0013229093747213483,
      -0.033869437873363495,
      -0.026698468253016472,
      0.04066314548254013,
      0.01209618803113699,
      -0.013114947825670242,
      0.03612372651696205,
      0.05084993317723274,
      0.0009228388080373406,
      -0.02453918755054474,
      -0.02479138784110546,
      -0.036526113748550415,
      0.011275265365839005,
      0.030054938048124313,
      -0.05723480135202408,
      0.03361339494585991,
      0.04459057003259659,
      0.046014994382858276,
      -0.048037685453891754,
      0.03197760507464409,
      0.006602959707379341,
      0.006947431713342667,
      0.05678973346948624,
      -0.03386935219168663,
      0.010912989266216755,
      -0.020016547292470932,
      -0.02839364856481552,
      0.05002591013908386,
      -0.01718471199274063,
      0.025663573294878006,
      0.022923551499843597,
      0.030748793855309486,
      0.07345002144575119,
      0.12762032449245453,
      0.039651576429605484,
      0.008848704397678375,
      -0.0114622563123703,
      -0.015801062807440758,
      -0.08600504696369171,
      -0.0016207894077524543,
      -0.01701805554330349,
      0.0646004006266594,
      0.003954130690544844,
      0.04011351615190506,
      -0.01203911006450653,
      -0.08586208522319794,
      0.05690800026059151,
      0.05059060454368591,
      -0.03014061041176319,
      0.015008865855634212,
      -0.04879658669233322,
      0.06287835538387299,
      -0.026868674904108047,
      0.0537663958966732,
      -0.09284302592277527,
      0.013011316768825054,
      -0.03168521076440811,
      0.00760038523003459,
      0.03302896395325661,
      -0.005147778894752264,
      0.05465751141309738,
      0.09847236424684525,
      -0.11454862356185913,
      0.06815090030431747,
      0.057911887764930725,
      -0.01818389631807804,
      0.0008568946504965425,
      0.0010910689597949386,
      0.02004994824528694,
      -0.004709981381893158,
      0.002698848955333233,
      0.05108373239636421,
      -0.01878483034670353,
      0.08362039178609848,
      -0.006114596035331488,
      -0.06528523564338684,
      0.10274223238229752,
      0.022818511351943016,
      -0.002059627091512084,
      -0.044845134019851685,
      -0.06408047676086426,
      0.01445530354976654,
      -0.038889992982149124,
      -0.06450583040714264,
      -0.012233298271894455,
      0.04963313043117523,
      0.06759581714868546,
      -0.040802549570798874,
      -0.042624250054359436,
      -0.006840286776423454,
      0.009589999914169312,
      0.02620880864560604,
      -0.06632885336875916,
      0.051444560289382935,
      0.0610639788210392,
      0.028945935890078545,
      0.053427837789058685,
      -0.001005205325782299,
      0.03704323619604111,
      -0.00014483016275335103,
      -0.01975238136947155,
      -0.04944659397006035,
      -0.04376411437988281,
      0.05888625234365463,
      -0.0783529132604599,
      0.013266320340335369,
      -0.05736090987920761,
      -0.007354640867561102,
      0.05466432869434357,
      -0.02826930209994316,
      -0.020045464858412743,
      0.02552378736436367,
      -0.04854981228709221,
      0.004143904894590378,
      0.03828500211238861,
      0.04051598533987999,
      0.02716018445789814,
      0.030505552887916565,
      -0.036654140800237656,
      0.013054026290774345,
      0.08075309544801712,
      0.010257244110107422,
      -0.02408508211374283,
      0.010204451158642769,
      0.11741045862436295,
      0.0902496874332428,
      0.03904595226049423,
      0.06566532701253891,
      0.09076535701751709,
      0.005662079434841871,
      -0.005817986559122801,
      0.008954345248639584,
      -0.013480347581207752,
      0.034582946449518204,
      0.008727386593818665,
      0.01550734881311655,
      0.09472835808992386,
      0.013122277334332466,
      -0.03483283147215843,
      -0.054571107029914856,
      0.005022019147872925,
      -0.06196201220154762,
      -0.032092075794935226,
      -0.07555591315031052,
      -0.03731124475598335,
      0.048913195729255676,
      0.003961613867431879,
      0.011369634419679642,
      0.05192234367132187,
      -0.06004519760608673,
      -0.021856622770428658,
      -0.0391397625207901,
      0.008627191185951233,
      0.011981229297816753,
      -0.08448528498411179,
      -0.002761536743491888,
      0.01653767004609108,
      -0.06533501297235489,
      0.0591607429087162,
      -0.019518369808793068,
      -0.0187345203012228,
      0.07961646467447281,
      -0.02075502648949623,
      -0.041972823441028595,
      -0.009728686884045601,
      -0.08309812098741531,
      0.022691288962960243,
      0.04384329915046692,
      0.048461612313985825,
      -0.007158530876040459,
      0.03119709901511669,
      0.003994937986135483,
      0.002014232100918889,
      -0.036235056817531586,
      -0.05734724923968315,
      -0.005839178804308176,
      0.04198051989078522,
      -0.05352407693862915,
      -0.020802589133381844,
      0.05109112709760666,
      0.03276441618800163,
      0.008726148866117,
      0.03160902112722397,
      0.029215993359684944,
      0.0707414448261261,
      -0.06942317634820938,
      0.00827549397945404,
      -0.007955272682011127,
      -0.03428645431995392,
      -0.0885617658495903,
      -0.04734724014997482,
      -0.001284037483856082,
      0.026530874893069267,
      -0.059203360229730606,
      -0.08920657634735107,
      -0.04396382346749306,
      0.050678763538599014,
      0.08906009048223495,
      0.019231455400586128,
      -0.007353525608778,
      -0.06768492609262466,
      0.025651266798377037,
      0.027855735272169113,
      0.0022325250320136547,
      -0.04884986951947212,
      0.011054858565330505,
      -0.04561527073383331,
      -0.006130206398665905,
      -0.03374316915869713,
      -0.05069329962134361,
      0.01690899208188057,
      -0.02948552370071411,
      -0.02974877692759037,
      -0.048587217926979065,
      0.009824108332395554,
      0.017786774784326553,
      0.056615982204675674,
      0.05414580553770065,
      0.009543422609567642,
      0.019373688846826553,
      0.05350128933787346,
      -0.044151537120342255,
      -0.03235023096203804,
      0.03337699547410011,
      0.014205933548510075,
      0.007668359205126762,
      0.00936836563050747,
      0.0012387222377583385,
      -0.02150840312242508,
      0.001742816180922091,
      0.037690576165914536,
      0.06239154189825058,
      0.056875795125961304,
      0.017454855144023895,
      -0.01645345240831375,
      -0.049166489392519,
      0.02355106547474861,
      0.024391600862145424,
      -0.024249332025647163,
      0.09460195153951645,
      -0.0029088661540299654,
      0.008274693042039871,
      0.03781605511903763,
      0.021120578050613403,
      -0.07093647122383118,
      -0.022983353585004807,
      -0.06066407635807991,
      0.07319396734237671,
      0.06996142864227295,
      0.04392107576131821,
      0.02084406279027462,
      -0.06037474051117897,
      0.06528858095407486,
      0.10413601994514465,
      0.02437993511557579,
      0.04702039062976837,
      0.08113125711679459,
      -0.008064042776823044,
      -0.07592181861400604,
      0.041502971202135086,
      -0.03640813007950783,
      0.02662407048046589,
      0.02653510309755802,
      0.032300908118486404,
      0.021273810416460037,
      -0.03429810702800751,
      0.011158007197082043,
      0.05567330867052078,
      -0.03727198764681816,
      -0.015838485211133957,
      0.0719781145453453,
      0.05179508402943611,
      0.051406171172857285,
      0.04552529379725456,
      -0.0736917033791542,
      -0.05246487632393837,
      -0.06254757195711136,
      -0.010819239541888237,
      0.004451007582247257,
      0.0801662728190422,
      0.037714309990406036,
      -0.025824381038546562,
      0.07423242926597595,
      -0.02662227861583233,
      0.03455043584108353,
      -0.14432978630065918,
      -0.0005215828423388302,
      0.01988610439002514,
      0.018255282193422318,
      -0.0015609102556481957,
      0.042800385504961014,
      -0.013651479035615921,
      0.05745425820350647,
      -0.02638031169772148,
      0.024493325501680374,
      0.0474475733935833,
      -0.08510826528072357,
      0.005965142976492643,
      -0.04362831637263298,
      -0.004450659733265638,
      0.03332721069455147,
      -0.023389149457216263,
      -0.023035036399960518,
      -0.06902023404836655,
      0.0157023835927248,
      -0.07111290097236633,
      -0.02449651062488556,
      -0.011670440435409546,
      -0.03845343738794327,
      -0.02923150546848774,
      0.022797178477048874,
      -0.08133957535028458,
      -0.004918740596622229,
      0.008194955997169018,
      0.02283194288611412,
      0.001404342008754611,
      0.006655092816799879,
      -0.06821335852146149,
      -0.08514779061079025,
      -0.06223457306623459,
      -0.07152625918388367,
      -0.012243625707924366,
      -0.02893100678920746,
      -0.00011665314377751201,
      -0.05719572305679321,
      -0.06207318231463432,
      0.03267326578497887,
      0.02667304128408432,
      0.05011017620563507,
      -0.016193604096770287,
      0.003444697242230177,
      -0.04652142524719238,
      -0.05002418905496597,
      0.02758163958787918,
      -0.03344108536839485,
      0.003687175689265132,
      -0.007471755612641573,
      0.008374669589102268,
      -0.058603573590517044,
      0.017996886745095253,
      -0.010301981121301651,
      0.024434616789221764,
      0.027660368010401726,
      0.06292504817247391,
      0.03265751153230667,
      -0.009788185358047485,
      0.04249021038413048,
      0.049392689019441605,
      -0.03618801012635231,
      -0.026597466319799423,
      0.06002850830554962,
      -0.06173955276608467,
      0.030818453058600426,
      0.008994925767183304,
      0.004423576407134533,
      0.008500791154801846,
      -0.0068060909397900105,
      -0.07504816353321075,
      -0.04247617721557617,
      0.05925283953547478,
      -0.06676412373781204,
      -0.05764434114098549,
      -0.047899894416332245,
      -0.01021201629191637,
      -0.01834583654999733,
      -0.016690874472260475,
      0.017634958028793335,
      -0.00814357865601778,
      -0.03986050933599472,
      0.015970071777701378,
      0.10116057842969894,
      0.03243371471762657,
      -0.004829283803701401,
      -0.06742513179779053,
      0.06138632446527481,
      -0.05368338152766228,
      -0.023646557703614235,
      0.0805453434586525,
      0.015920521691441536,
      -0.02113933488726616,
      -0.03454724699258804,
      -0.009173373691737652,
      -0.040115728974342346,
      0.03206419572234154,
      -0.042133864015340805,
      -0.053079575300216675,
      -0.0909280776977539,
      -0.07798498868942261,
      -0.07134100794792175,
      -0.06661231815814972,
      0.09020977467298508,
      -0.05861939117312431,
      -0.02027374878525734,
      -0.060425858944654465,
      0.0920049250125885,
      0.04327568784356117,
      0.017151309177279472,
      0.029792729765176773,
      0.04636098071932793,
      0.04361386597156525,
      0.03217291459441185,
      0.09550502151250839,
      0.011404105462133884,
      -0.0503193624317646,
      -0.011140006594359875,
      0.039333637803792953,
      0.08197897672653198,
      -0.009916507638990879,
      0.04145057871937752,
      -0.019369125366210938,
      -0.058432646095752716,
      -0.032068975269794464,
      -0.04986509308218956,
      -0.008313877508044243,
      -0.026784416288137436,
      -0.06757927685976028,
      0.003328902879729867,
      0.03751874715089798,
      -0.07780278474092484,
      0.08126453310251236,
      -0.00757987704128027,
      0.04121695086359978,
      0.00913698785007,
      -0.05912022665143013,
      0.027327967807650566,
      0.011733010411262512,
      -0.01992889493703842,
      -0.09465155005455017,
      -0.04068520665168762,
      0.08177277445793152,
      -0.0028442477341741323,
      -0.07545691728591919,
      -0.05049566179513931,
      0.04678433761000633,
      0.0037261934485286474,
      0.009906177408993244,
      -0.01643032766878605,
      -0.06567550450563431,
      0.05424422025680542,
      0.05781591311097145,
      -0.06576596945524216,
      -0.03669150918722153,
      0.038031190633773804,
      0.03846875950694084,
      0.014578735455870628,
      0.05230977013707161,
      -0.027170684188604355,
      -0.08006918430328369,
      -0.026971252635121346,
      -0.0026950498577207327,
      0.05409219115972519,
      -0.013358069583773613,
      -0.012032787315547466,
      -0.05025628209114075,
      0.018658572807908058,
      -0.03504807874560356,
      0.0012046280317008495,
      -0.013712362386286259,
      -0.02377171814441681,
      0.056734368205070496
    ],
    [
      -0.042138852179050446,
      0.07737486064434052,
      -0.05130400136113167,
      -0.006278296932578087,
      0.012174101546406746,
      -0.04763102903962135,
      0.05617889389395714,
      -0.024069229140877724,
      -0.05326429754495621,
      -0.007999882102012634,
      -0.018255887553095818,
      0.047697704285383224,
      -0.010448229499161243,
      0.03500792384147644,
      0.018066413700580597,
      0.022618398070335388,
      -0.08046572655439377,
      0.0019291725475341082,
      0.004303305875509977,
      -0.04917395859956741,
      0.023192148655653,
      -0.028699813410639763,
      0.0019515183521434665,
      -0.10328684747219086,
      -0.012773414142429829,
      0.04101957380771637,
      0.03793125972151756,
      0.08300075680017471,
      0.013833007775247097,
      -0.038391098380088806,
      -0.05957723408937454,
      -0.03549160435795784,
      -0.01993965543806553,
      0.04663873091340065,
      -0.03079170547425747,
      -0.04877784103155136,
      0.03514840453863144,
      -0.04223395884037018,
      -0.023310529068112373,
      -0.006253134459257126,
      0.04216446727514267,
      -0.036327045410871506,
      0.005021169316023588,
      -0.03262178227305412,
      -0.021119052544236183,
      -0.013895731419324875,
      -0.062212634831666946,
      0.02418525330722332,
      -0.02972574532032013,
      -0.060968440026044846,
      -0.004665870685130358,
      0.012495092116296291,
      -0.008708888664841652,
      0.08825096487998962,
      -0.021888339892029762,
      -0.01902657561004162,
      0.0017025083070620894,
      -0.03617886081337929,
      -0.03482905030250549,
      0.011507605202496052,
      -0.03330383449792862,
      -0.043160416185855865,
      0.12467910349369049,
      0.0005646280478686094,
      0.03287610784173012,
      -0.021772220730781555,
      0.035800524055957794,
      -0.01067809946835041,
      -0.06092621386051178,
      0.05723576247692108,
      -0.13450008630752563,
      0.02126382291316986,
      -0.07082115113735199,
      0.10485534369945526,
      -0.06816236674785614,
      -0.029950324445962906,
      -0.011433483101427555,
      0.08692456781864166,
      -0.006642318796366453,
      0.04873146489262581,
      -0.03799273818731308,
      0.018712854012846947,
      -0.033278901129961014,
      -0.051780443638563156,
      -0.10852968692779541,
      -0.05085831508040428,
      0.08300329744815826,
      0.01596515253186226,
      -0.05640704929828644,
      -0.07931511104106903,
      -0.023191707208752632,
      0.011835112236440182,
      -0.046404555439949036,
      -0.02983652614057064,
      0.036707907915115356,
      -0.00898972898721695,
      0.06064692884683609,
      0.1183546707034111,
      -0.032016705721616745,
      -0.03000747039914131,
      -0.024435723200440407,
      -0.04863865673542023,
      -0.011198740452528,
      0.02456163801252842,
      -0.06156601756811142,
      0.0016883735079318285,
      -0.1008172333240509,
      -0.03879990801215172,
      0.043245136737823486,
      -0.027997707948088646,
      -0.0356408953666687,
      -0.05155772343277931,
      0.036470793187618256,
      -0.03566540405154228,
      0.07357029616832733,
      0.048470593988895416,
      -0.04265788570046425,
      0.07907907664775848,
      -0.03212994709610939,
      -0.03198350965976715,
      0.03951558098196983,
      -0.013268535025417805,
      -0.0030302752275019884,
      -0.03723843768239021,
      0.05288511887192726,
      -0.0192091204226017,
      -0.011369667947292328,
      0.03457676246762276,
      0.09634297341108322,
      0.008503741584718227,
      0.042234573513269424,
      0.05444318428635597,
      0.01219647191464901,
      -0.010086219757795334,
      -0.030698783695697784,
      -0.03179386258125305,
      -0.07603056728839874,
      0.0675123929977417,
      0.04459709674119949,
      -0.1073324903845787,
      -0.011537185870110989,
      0.04987897723913193,
      -0.010935085825622082,
      -0.027600951492786407,
      0.014403397217392921,
      0.006986513268202543,
      0.07646460086107254,
      0.013117773458361626,
      -0.022695688530802727,
      -0.06469043344259262,
      -0.0017549386247992516,
      0.03154841437935829,
      -0.0031330916099250317,
      0.010846947319805622,
      -0.0267347265034914,
      0.010036077350378036,
      -0.09313897043466568,
      0.02659217081964016,
      0.040759406983852386,
      0.0010848938254639506,
      -0.08427968621253967,
      0.0025159709621220827,
      0.006780492607504129,
      -0.012937882915139198,
      0.04343307390809059,
      0.0437583364546299,
      -0.03257002308964729,
      0.06749521195888519,
      0.01712566427886486,
      0.0007205820875242352,
      0.021333932876586914,
      0.033090852200984955,
      0.03215475380420685,
      -0.029033007100224495,
      -0.07800178974866867,
      0.04896664246916771,
      -0.026460763067007065,
      0.022177601233124733,
      0.05338038131594658,
      0.03407062217593193,
      -0.0008393461466766894,
      0.02848895825445652,
      -0.03318137675523758,
      -0.02333274856209755,
      0.017586082220077515,
      0.039489611983299255,
      -0.001368096680380404,
      0.03185323253273964,
      -0.017473232001066208,
      0.0537228062748909,
      -0.011536598205566406,
      -0.027013622224330902,
      0.041922472417354584,
      -0.06439170986413956,
      0.009114312008023262,
      -0.04699263721704483,
      -0.059320930391550064,
      0.008630274794995785,
      0.03603235259652138,
      -0.0796317532658577,
      0.04174090176820755,
      0.013273796066641808,
      -0.017074668779969215,
      0.045893099159002304,
      0.03030141443014145,
      0.13183008134365082,
      0.007072562351822853,
      0.029041437432169914,
      0.07066729664802551,
      -0.02098776213824749,
      0.07487252354621887,
      -0.05037432163953781,
      0.018012147396802902,
      0.05011541396379471,
      0.034375712275505066,
      -0.040056515485048294,
      -0.045918721705675125,
      0.03316081315279007,
      -0.0694461464881897,
      -0.010330401360988617,
      0.015458597801625729,
      0.0488772876560688,
      -0.015013519674539566,
      -0.10560522228479385,
      0.051414743065834045,
      -0.0256224162876606,
      0.024052022024989128,
      -0.09008058905601501,
      0.07925906032323837,
      0.05423145368695259,
      -0.0783594623208046,
      0.03941188380122185,
      0.008535778149962425,
      0.006046320777386427,
      0.03715366870164871,
      0.024734079837799072,
      0.016976673156023026,
      -0.05329062044620514,
      0.05103163793683052,
      -0.0047850338742136955,
      -0.09991405159235,
      -0.0022781449370086193,
      0.06173930689692497,
      -0.019555555656552315,
      -0.029353555291891098,
      0.0055556464940309525,
      -0.008436857722699642,
      -0.023700645193457603,
      0.05103267356753349,
      0.0853722095489502,
      -0.02487330324947834,
      -0.0007992270402610302,
      -0.02253751829266548,
      0.07650628685951233,
      -0.04111364856362343,
      -0.013345211744308472,
      0.012798111885786057,
      0.05108633637428284,
      -0.026447946205735207,
      -0.04230699688196182,
      0.04589545354247093,
      -0.011246484704315662,
      0.007462579756975174,
      0.004454776179045439,
      0.017824379727244377,
      -0.055335529148578644,
      -0.008326796814799309,
      0.027330433949828148,
      0.040852490812540054,
      -0.02040231227874756,
      0.0037443761248141527,
      0.0020577318500727415,
      -0.021633964031934738,
      -0.05811320245265961,
      -0.006398300640285015,
      -0.0018608614336699247,
      -0.0035747967194765806,
      -0.0038266645278781652,
      0.035283688455820084,
      0.02459108456969261,
      -0.02245909534394741,
      -0.032395102083683014,
      0.0499272458255291,
      0.03906513378024101,
      0.07735072821378708,
      0.018923277035355568,
      -0.0057319412007927895,
      -0.04816676676273346,
      0.029421664774417877,
      -0.04686034470796585,
      0.017640452831983566,
      0.048916853964328766,
      0.060200002044439316,
      0.0251717921346426,
      -0.012149090878665447,
      -0.0690070167183876,
      0.0192732997238636,
      0.010171142406761646,
      -0.01381956972181797,
      0.026843469589948654,
      0.06381732225418091,
      0.028134582564234734,
      0.0012206106912344694,
      0.06574391573667526,
      0.002977296244353056,
      -0.07105851173400879,
      -0.030794456601142883,
      -0.10984799265861511,
      -0.01665934920310974,
      0.05116281285881996,
      -0.049579765647649765,
      -0.03793257474899292,
      0.025215771049261093,
      -0.07414094358682632,
      -0.004737583454698324,
      0.02163717709481716,
      -0.08605198562145233,
      -0.033050891011953354,
      -0.02063390053808689,
      -0.06622827798128128,
      0.01724228262901306,
      0.018196161836385727,
      -0.013425893150269985,
      -0.00635805819183588,
      -0.030834214761853218,
      0.02085125260055065,
      -0.05942316725850105,
      -0.0705816000699997,
      -0.08033621311187744,
      0.04145723953843117,
      0.012892232276499271,
      -0.07567699253559113,
      -0.04171958193182945,
      0.02513911947607994,
      0.06331417709589005,
      0.06456799805164337,
      0.01719658449292183,
      0.042248744517564774,
      0.0006334775243885815,
      0.05677354708313942,
      0.01017234567552805,
      -0.0021127706859260798,
      -0.012522079050540924,
      0.010400792583823204,
      0.006983880419284105,
      0.013582205399870872,
      0.046233877539634705,
      0.05590168014168739,
      0.027461647987365723,
      0.04095304012298584,
      0.022114329040050507,
      -0.01713547296822071,
      0.0028470202814787626,
      0.0943361446261406,
      -0.039792634546756744,
      0.027671776711940765,
      -0.05885092169046402,
      -0.047966644167900085,
      -0.020547086372971535,
      0.008212356828153133,
      -0.026275141164660454,
      0.06795243173837662,
      -0.09961336106061935,
      0.07903748750686646,
      0.006555083207786083,
      -0.00890092458575964,
      -0.04201360419392586,
      -0.05437329038977623,
      0.012369900941848755,
      0.07048923522233963,
      0.07500264048576355,
      -0.011311457492411137,
      0.06327659636735916,
      0.040519971400499344,
      -0.05430544912815094,
      0.003365004900842905,
      -0.03158225864171982,
      -0.012230117805302143,
      0.07440847903490067,
      0.0276936162263155,
      0.07308093458414078,
      -0.019514847546815872,
      0.02371629700064659,
      -0.010246168822050095,
      -0.013184193521738052,
      -0.023775829002261162,
      0.032253626734018326,
      -0.021294597536325455,
      0.0503004714846611,
      -0.016819605603814125,
      0.05085698142647743,
      -0.0702105239033699,
      0.01068065781146288,
      -0.025679072365164757,
      0.06773855537176132,
      0.007787398062646389,
      0.04006190970540047,
      -0.04102086275815964,
      0.03301343321800232,
      -0.021384764462709427,
      -0.07507438212633133,
      0.025317443534731865,
      0.02855403907597065,
      -0.03761462867259979,
      0.06306303292512894,
      0.031365811824798584,
      -0.02658740244805813,
      -0.04976595193147659,
      0.06116444990038872,
      -0.0703030675649643,
      -0.031027432531118393,
      -0.050019778311252594,
      0.010148925706744194,
      0.012856264598667622,
      0.005630250554531813,
      -0.05705609172582626,
      -0.0249472726136446,
      0.03895178437232971,
      -0.0192789938300848,
      0.027822939679026604,
      0.05028975009918213,
      0.005713421851396561,
      0.033900368958711624,
      -0.04068939760327339,
      -0.0206315740942955,
      0.020641734823584557,
      -0.008508897386491299,
      -0.03977636620402336,
      0.0076973456889390945,
      -0.032979100942611694,
      -0.014001494273543358,
      0.018203366547822952,
      -0.046210672706365585,
      -0.022261643782258034,
      -0.02591804601252079,
      -0.00297927251085639,
      -0.028650959953665733,
      -0.013633864931762218,
      0.0027620999608188868,
      0.03190352022647858,
      0.007017785683274269,
      -0.017975693568587303,
      -0.02887049876153469,
      0.017068563029170036,
      -0.06433292478322983,
      0.028339684009552002,
      -0.0045823282562196255,
      -0.020849687978625298,
      -0.038949497044086456,
      -0.04809538647532463,
      -0.0032725378405302763,
      -0.008585304021835327,
      0.044376030564308167,
      0.08502466976642609,
      0.033254191279411316,
      0.010882912203669548,
      -0.0021349554881453514,
      0.011687050573527813,
      0.09875143319368362,
      -0.007043220102787018,
      0.06858807802200317,
      0.005540733225643635,
      0.021868832409381866,
      0.013902696780860424,
      0.0686127170920372,
      0.0815887451171875,
      -0.10135110467672348,
      0.06851394474506378,
      0.04620115086436272,
      0.004554026760160923,
      -0.034379273653030396,
      0.015452848747372627,
      -0.016351860016584396,
      0.025414571166038513,
      0.06074466556310654,
      0.007722381968051195,
      0.046848196536302567,
      -0.008367037400603294,
      -0.1321669965982437,
      0.055278513580560684,
      0.05665527284145355,
      0.009906960651278496,
      -0.02940348908305168,
      -0.0015777219086885452,
      -0.0629412829875946,
      -0.030259601771831512,
      -0.030114278197288513,
      0.04804932326078415,
      0.02006828598678112,
      0.0054763564839959145,
      0.006801410112529993,
      -0.05956282094120979,
      0.03621763736009598,
      0.007989584468305111,
      0.04470106586813927,
      0.012606466189026833,
      -0.07402531057596207,
      -0.08517878502607346,
      -0.039155952632427216,
      0.026422420516610146,
      0.031126437708735466,
      0.08014525473117828,
      0.024008601903915405
    ],
    [
      0.0266535934060812,
      0.038738057017326355,
      -0.07876796275377274,
      0.10829468816518784,
      0.01635042391717434,
      0.025905631482601166,
      0.01710977964103222,
      0.024470539763569832,
      -0.11063405126333237,
      -0.009177559986710548,
      0.01086062379181385,
      -0.09316936880350113,
      0.006688777823001146,
      -0.01866438053548336,
      0.03160323575139046,
      -0.028529275208711624,
      0.029084308072924614,
      -0.02322409115731716,
      -0.062190089374780655,
      0.05120297148823738,
      0.02470547892153263,
      0.0602804571390152,
      0.04781932756304741,
      0.025601165369153023,
      0.018362252041697502,
      -0.0012810054467990994,
      -0.05387365072965622,
      0.019695473834872246,
      -0.04244377464056015,
      -0.07893026620149612,
      -0.0018557616276666522,
      -0.04611458629369736,
      -0.0676334798336029,
      0.011749151162803173,
      -0.01179483812302351,
      0.08175701647996902,
      0.06782496720552444,
      -0.02813880331814289,
      -0.006916997954249382,
      -0.01926581561565399,
      -0.04826575145125389,
      -0.03563079237937927,
      0.026976188644766808,
      0.0869784876704216,
      0.005843974184244871,
      -0.001261850935406983,
      0.05452984198927879,
      -0.053939517587423325,
      -0.061162952333688736,
      0.013123176991939545,
      0.03938043490052223,
      0.034201379865407944,
      0.004981116391718388,
      0.04766307398676872,
      0.020143605768680573,
      0.03176962956786156,
      -0.012317069806158543,
      -0.04662899672985077,
      0.062169574201107025,
      0.013359065167605877,
      -0.011641832999885082,
      0.1007847785949707,
      0.036872826516628265,
      -0.05770108848810196,
      -0.05063684284687042,
      -0.01871386170387268,
      0.015359006822109222,
      0.018848223611712456,
      0.014527379535138607,
      0.03935825079679489,
      0.0856594368815422,
      -0.09569378942251205,
      -0.08009263128042221,
      -0.027863161638379097,
      -0.01514537911862135,
      -0.0877220407128334,
      -0.04629712924361229,
      0.04471192508935928,
      -0.0007278760313056409,
      -0.010398217476904392,
      0.043984126299619675,
      -0.04220782592892647,
      -0.01882138103246689,
      -0.084786057472229,
      -0.005162418819963932,
      -0.05172053724527359,
      -0.048963841050863266,
      -0.10954789817333221,
      0.01355905644595623,
      0.03239801526069641,
      0.008452522568404675,
      -0.03395865485072136,
      0.005323471035808325,
      -0.013052023015916348,
      -0.05012023076415062,
      -0.002196814399212599,
      0.014285719953477383,
      -0.04896260425448418,
      -0.03999020531773567,
      0.017749615013599396,
      -0.0028017419390380383,
      0.00339917722158134,
      -0.08905017375946045,
      0.04919368401169777,
      0.014520423486828804,
      -0.03482256829738617,
      -0.013670154847204685,
      0.017029739916324615,
      0.019212594255805016,
      0.025869950652122498,
      0.06618138402700424,
      -0.006840395741164684,
      0.041968971490859985,
      0.019263282418251038,
      -0.14711247384548187,
      -0.02344706282019615,
      0.08597952872514725,
      -0.026339329779148102,
      -0.03506886214017868,
      -0.016819292679429054,
      0.0030543229077011347,
      0.012787196785211563,
      -0.009706391021609306,
      -0.017574230208992958,
      0.01751129887998104,
      -0.04477683827280998,
      0.01761719584465027,
      0.005084967240691185,
      -0.036642782390117645,
      0.0864381417632103,
      0.0559951514005661,
      0.0058311219327151775,
      -0.03628455474972725,
      -0.016469886526465416,
      -0.02204125002026558,
      -0.040354691445827484,
      -0.07794737815856934,
      0.1045173779129982,
      0.007622876670211554,
      0.04335460811853409,
      0.044992219656705856,
      0.0058852070942521095,
      0.018768351525068283,
      -0.04054594412446022,
      -0.0015860144048929214,
      -0.027040041983127594,
      -0.012462139129638672,
      0.019832240417599678,
      -0.039753202348947525,
      -0.07706406712532043,
      -0.026215411722660065,
      0.03798355534672737,
      -0.08260782063007355,
      0.04948980361223221,
      0.003859022632241249,
      -0.06309881061315536,
      -0.008909402415156364,
      -0.006959319114685059,
      0.045017074793577194,
      0.029805023223161697,
      0.06083102524280548,
      0.08345435559749603,
      0.03951336443424225,
      0.013258452527225018,
      0.040906332433223724,
      -0.018468203023076057,
      -0.021015336737036705,
      0.043745581060647964,
      0.02022646553814411,
      -0.009699632413685322,
      -0.0032192659564316273,
      0.07821353524923325,
      -0.03397221118211746,
      0.016380807384848595,
      0.06635340303182602,
      0.04187618941068649,
      -0.06860614567995071,
      -0.02853194624185562,
      -0.08391845226287842,
      0.04647912085056305,
      -0.015294707380235195,
      0.025923047214746475,
      -0.015815159305930138,
      -0.10992709547281265,
      -0.05437731742858887,
      0.0036196375731378794,
      -0.07218566536903381,
      -0.014522220939397812,
      -0.006323563866317272,
      0.03641020134091377,
      -0.001523183542303741,
      -0.02722175046801567,
      0.027819061651825905,
      0.04491543024778366,
      0.05617048963904381,
      -0.10865490138530731,
      -0.04029278829693794,
      0.04128726199269295,
      -0.12234747409820557,
      -0.026961246505379677,
      -0.012734767980873585,
      0.0031888033263385296,
      -0.03090537153184414,
      0.004288549069315195,
      0.038681432604789734,
      0.027619045227766037,
      0.03396168351173401,
      -0.10829022526741028,
      0.024730859324336052,
      0.0627036914229393,
      0.008304382674396038,
      -0.016730906441807747,
      -0.032677359879016876,
      -0.011404803954064846,
      -0.07619031518697739,
      0.07042612135410309,
      -0.06387075036764145,
      -0.05924220755696297,
      -0.05401793122291565,
      0.019611109048128128,
      0.03558095172047615,
      -0.05079779401421547,
      -0.035352203994989395,
      0.04050994664430618,
      -0.01207027304917574,
      0.06866037845611572,
      -0.09014566987752914,
      -0.12456356734037399,
      -0.012045478448271751,
      -0.004275719169527292,
      -0.025736752897500992,
      0.05731261521577835,
      -0.01205556932836771,
      0.03775608912110329,
      -0.05208820477128029,
      0.0038025439716875553,
      0.05411650612950325,
      -0.05938876420259476,
      0.024064090102910995,
      0.04369283840060234,
      -0.04553264006972313,
      -0.009147582575678825,
      0.10536295175552368,
      -0.046660613268613815,
      0.012815972790122032,
      0.0437895841896534,
      0.07061941921710968,
      -0.031441088765859604,
      -0.011541829444468021,
      0.0401911586523056,
      0.031851377338171005,
      -0.03673037141561508,
      0.017215749248862267,
      -0.007759100757539272,
      0.06071646884083748,
      -0.013936538249254227,
      -0.047907765954732895,
      -0.03857073560357094,
      0.10052913427352905,
      0.006717117037624121,
      -0.04300579056143761,
      0.04255982115864754,
      -0.025420386344194412,
      -0.0047791157849133015,
      -0.036873236298561096,
      -0.05763233080506325,
      -0.0007224688888527453,
      0.06812494993209839,
      -0.04118453711271286,
      0.01959986984729767,
      -0.14217858016490936,
      0.05867181345820427,
      -0.08736390620470047,
      -0.07560913264751434,
      4.7915666073095053e-05,
      0.06039449945092201,
      -0.04681576043367386,
      0.058681607246398926,
      -0.06844288855791092,
      -0.041906729340553284,
      0.008904024958610535,
      -0.024882249534130096,
      0.029679404571652412,
      -0.06574703752994537,
      -0.0034520640037953854,
      0.0199570469558239,
      0.0012787763262167573,
      0.05661962181329727,
      -0.04949823021888733,
      -0.04639873653650284,
      0.03526642546057701,
      0.028287988156080246,
      -0.05677548423409462,
      -0.013380687683820724,
      0.0037781749852001667,
      -0.04352284222841263,
      0.042278796434402466,
      -0.12529492378234863,
      0.021113740280270576,
      -0.011887571774423122,
      0.02942480705678463,
      0.010082913562655449,
      -0.04358385130763054,
      0.02685738168656826,
      0.07189662754535675,
      -0.028741266578435898,
      -0.044358786195516586,
      -0.0053023952059447765,
      -0.0603162907063961,
      -0.044570162892341614,
      0.07419673353433609,
      0.03981725126504898,
      -0.05632838234305382,
      0.028716783970594406,
      -0.023666713386774063,
      0.017359625548124313,
      0.0327124148607254,
      -0.06876710802316666,
      -0.0329611599445343,
      0.10771769285202026,
      0.025406213477253914,
      0.038475412875413895,
      0.017634453251957893,
      0.035906750708818436,
      -0.06239277124404907,
      0.08483393490314484,
      -0.029743386432528496,
      -0.03345100209116936,
      0.008103717118501663,
      -0.0069095236249268055,
      0.06256907433271408,
      -0.020546546205878258,
      -0.03892477601766586,
      -0.019413599744439125,
      0.02967524342238903,
      0.05603519827127457,
      -0.02746085450053215,
      -0.017252692952752113,
      -0.023514823988080025,
      -0.05011118948459625,
      0.016719231382012367,
      0.01687014102935791,
      0.09993939101696014,
      -0.05245494842529297,
      -0.0332350917160511,
      -0.05098907649517059,
      0.07861054688692093,
      -0.029610158875584602,
      0.0041207559406757355,
      -0.025941597297787666,
      -0.01704399101436138,
      0.03509117290377617,
      -0.03126508370041847,
      0.05545942112803459,
      -0.01379922591149807,
      -0.03211956471204758,
      -0.10554509609937668,
      -0.04987527057528496,
      0.011391525156795979,
      -0.02574976533651352,
      0.043699294328689575,
      0.010306524112820625,
      0.02090439200401306,
      0.033053383231163025,
      0.06224321201443672,
      -0.03532136604189873,
      -0.009786381386220455,
      0.0617995411157608,
      -0.02077743597328663,
      0.04892861470580101,
      0.0483119823038578,
      0.011600915342569351,
      0.03798335790634155,
      0.0010211012559011579,
      -0.10534074157476425,
      0.06318938732147217,
      -0.008946177549660206,
      0.014118245802819729,
      0.010803953744471073,
      -0.04145989194512367,
      0.059315275400877,
      -0.01915252022445202,
      0.014070260338485241,
      0.06546081602573395,
      0.0037924875505268574,
      -0.04571337625384331,
      0.01926739141345024,
      0.015170007012784481,
      -0.007398257963359356,
      -0.03222370892763138,
      -0.08667192608118057,
      -0.06270085275173187,
      0.029392622411251068,
      0.029634989798069,
      0.04586813598871231,
      0.0007915016612969339,
      -0.05968170613050461,
      -0.0013168019941076636,
      -0.040388770401477814,
      -0.004520368296653032,
      0.008979583159089088,
      0.0419267863035202,
      0.061372093856334686,
      0.0025494336150586605,
      -0.01758730597794056,
      0.004357998725026846,
      -0.051572781056165695,
      -0.042086027562618256,
      -0.08912628144025803,
      -0.005504012107849121,
      -0.048813346773386,
      0.01206980925053358,
      0.022617964074015617,
      0.020089590921998024,
      -0.019523324444890022,
      0.0030898097902536392,
      -0.05616491660475731,
      0.006703703664243221,
      -0.02259056456387043,
      0.03317148983478546,
      0.001185164786875248,
      0.007215592078864574,
      0.00561155192553997,
      -0.008942567743360996,
      0.038540661334991455,
      -0.07579362392425537,
      -0.047032326459884644,
      -0.04589923843741417,
      0.07683993130922318,
      -0.013651753775775433,
      -0.03360484540462494,
      0.0630095899105072,
      -0.044223614037036896,
      0.012028004974126816,
      -0.03867524862289429,
      -0.001972838072106242,
      -0.016107041388750076,
      0.019176697358489037,
      -0.05106276273727417,
      -0.002793429419398308,
      -0.05622647702693939,
      0.024548104032874107,
      -0.012474234215915203,
      -0.018431205302476883,
      0.003998700529336929,
      0.0266389362514019,
      -0.040854889899492264,
      -0.037187762558460236,
      -0.045635100454092026,
      0.04577811062335968,
      0.023863602429628372,
      -0.08644982427358627,
      0.01876615546643734,
      0.02748667448759079,
      0.09233817458152771,
      -0.010867311619222164,
      0.009499434381723404,
      0.05675092339515686,
      -0.08088812977075577,
      -0.021587928757071495,
      -0.022539936006069183,
      0.003324310528114438,
      0.005805376451462507,
      -0.04506738856434822,
      -0.029155969619750977,
      0.005951864179223776,
      0.02456306479871273,
      0.01293233223259449,
      -0.009932032786309719,
      -0.026319976896047592,
      0.005984350573271513,
      -0.0023343011271208525,
      0.022591840475797653,
      -0.004500492941588163,
      -0.0034089824184775352,
      0.024611586704850197,
      0.07646629214286804,
      -0.03210457041859627,
      -0.006563279777765274,
      -0.005581900477409363,
      -0.06811653822660446,
      0.007630191743373871,
      -0.006941646803170443,
      0.02145642414689064,
      0.04133203998208046,
      -0.0056084818206727505,
      0.009634716436266899,
      0.00395569484680891,
      0.0036489306949079037,
      -0.013653124682605267,
      0.050025589764118195,
      0.13082079589366913,
      0.009065371006727219,
      0.004462846554815769,
      -0.000175477922311984,
      0.009531521238386631,
      0.012290965765714645,
      -0.028974352404475212,
      -0.011525142937898636,
      0.04858174920082092,
      0.00961177609860897,
      -0.02493906393647194,
      0.0458703376352787
    ],
    [
      0.004483399447053671,
      -0.024103999137878418,
      -0.04117891564965248,
      0.0021365624852478504,
      0.030487222597002983,
      -0.05276204273104668,
      0.002353406511247158,
      0.00047095579793676734,
      -0.08039704710245132,
      -0.016015030443668365,
      -0.023306742310523987,
      0.05154573544859886,
      0.02781999111175537,
      0.051856525242328644,
      -0.0024865493178367615,
      -0.00827914196997881,
      -0.021987108513712883,
      0.00789516232907772,
      0.013315451331436634,
      0.04499737173318863,
      0.023297986015677452,
      0.007320225238800049,
      -0.020176801830530167,
      0.014804864302277565,
      -0.025325199589133263,
      0.004742505494505167,
      -0.10187704116106033,
      0.021771689876914024,
      -0.07533321529626846,
      -0.017961641773581505,
      -0.007791230455040932,
      0.015596306882798672,
      -0.040492404252290726,
      0.023735301569104195,
      0.04667988419532776,
      0.0439114011824131,
      0.005349066574126482,
      0.03629085794091225,
      0.008777475915849209,
      -0.005041684024035931,
      -0.04584038630127907,
      0.017712438479065895,
      0.0631786584854126,
      -0.07315686345100403,
      -0.06534375250339508,
      -0.03284698724746704,
      0.03575250878930092,
      0.022624120116233826,
      -0.009400591254234314,
      0.03200496360659599,
      0.00422943290323019,
      -0.019875995814800262,
      -0.004547964781522751,
      -0.02857435867190361,
      -0.06902407854795456,
      0.04132093861699104,
      0.006790500599890947,
      -0.061116091907024384,
      -0.030405713245272636,
      -0.07527846097946167,
      0.012951593846082687,
      0.006530700251460075,
      0.016112618148326874,
      0.06216856464743614,
      -0.030140886083245277,
      0.03673525154590607,
      0.09547512233257294,
      0.02987704612314701,
      0.00951645988970995,
      -0.07012852281332016,
      -0.009626728482544422,
      0.016657071188092232,
      0.034844670444726944,
      -0.003526238026097417,
      0.001989976270124316,
      -0.016592973843216896,
      0.02454555034637451,
      0.020226795226335526,
      -0.015502233058214188,
      -0.049087557941675186,
      -0.08564876765012741,
      -0.051511574536561966,
      -0.04570485278964043,
      0.03937376290559769,
      0.007288503460586071,
      -0.02330963872373104,
      -0.04645269736647606,
      0.014452450908720493,
      0.05000007152557373,
      -0.004816821776330471,
      0.09003342688083649,
      -0.034757841378450394,
      0.011405330151319504,
      0.013101629912853241,
      0.04521471634507179,
      -0.05802149698138237,
      0.05520814657211304,
      -0.022957611829042435,
      -0.07832510024309158,
      0.1392364501953125,
      -0.005822602193802595,
      0.0707126259803772,
      0.06959820538759232,
      0.07400676608085632,
      -0.0009299065568484366,
      0.006045989226549864,
      -0.06459474563598633,
      -0.039808690547943115,
      -0.06336813420057297,
      0.02328156679868698,
      0.025217920541763306,
      -0.001703956164419651,
      -0.015069314278662205,
      -0.10129505395889282,
      -0.040451545268297195,
      0.025390489026904106,
      -0.020306790247559547,
      -0.023656336590647697,
      -0.02500808984041214,
      -0.00970381684601307,
      0.048066847026348114,
      0.04761579632759094,
      -0.01295119896531105,
      0.027940617874264717,
      -0.056534796953201294,
      0.008545567281544209,
      -0.05572532117366791,
      0.021834250539541245,
      0.057965245097875595,
      0.04297703132033348,
      0.04818231984972954,
      0.02098524197936058,
      0.06103000044822693,
      0.027402304112911224,
      0.052458591759204865,
      0.007246649358421564,
      0.017904866486787796,
      0.03492141515016556,
      -0.011113443411886692,
      0.04283374175429344,
      0.0821060836315155,
      0.05495239049196243,
      -0.08027692884206772,
      0.021585676819086075,
      -0.0013073900481685996,
      0.040657199919223785,
      0.0014112739590927958,
      -0.044928111135959625,
      0.003996598534286022,
      -0.03898539021611214,
      -0.0019878807943314314,
      0.03141406551003456,
      -0.037068258970975876,
      0.016160011291503906,
      0.01099954079836607,
      0.009852111339569092,
      0.006719279568642378,
      0.03686431795358658,
      0.05906467139720917,
      0.04220231994986534,
      -0.005610390566289425,
      0.02628377452492714,
      0.004077108111232519,
      -0.052742552012205124,
      -0.0454196073114872,
      -0.016668330878019333,
      -0.07015081495046616,
      0.003794838907197118,
      -0.028018547222018242,
      0.01578417420387268,
      0.01577185094356537,
      0.06275349855422974,
      0.05972078815102577,
      0.05056256800889969,
      0.08809667080640793,
      0.008722519502043724,
      0.008886064402759075,
      -0.03125068545341492,
      0.051906827837228775,
      -0.010288269259035587,
      0.013278057798743248,
      0.0012116945581510663,
      -0.054131880402565,
      -0.010568474419414997,
      -0.04515496641397476,
      0.030326543375849724,
      0.001129092532210052,
      -0.004160062875598669,
      -0.04568253830075264,
      -0.009839765727519989,
      -0.04866691306233406,
      -0.0023369761183857918,
      -0.06425964087247849,
      -0.11714176088571548,
      0.04642722010612488,
      0.0023941125255078077,
      -0.0012044970644637942,
      0.030734270811080933,
      -0.021109474822878838,
      0.02399013750255108,
      -0.03522110357880592,
      -0.015125680714845657,
      -0.0025331038050353527,
      -0.046268247067928314,
      -0.023698557168245316,
      0.0039956169202923775,
      -0.023719821125268936,
      -0.029977848753333092,
      0.038198404014110565,
      0.05163580924272537,
      -0.02558957226574421,
      0.05397197976708412,
      -0.017340799793601036,
      -0.0588018000125885,
      0.04886578768491745,
      -0.004709362983703613,
      0.049907390028238297,
      0.022424371913075447,
      -0.06763051450252533,
      0.007665728684514761,
      -0.014186115935444832,
      -0.027762752026319504,
      0.00729802530258894,
      -0.017286380752921104,
      -0.02096627466380596,
      0.03727511689066887,
      -0.009640393778681755,
      0.05470340698957443,
      0.07278166711330414,
      -0.053516846150159836,
      -0.010306991636753082,
      -0.016477838158607483,
      0.018847661092877388,
      -0.0010114619508385658,
      0.008007491938769817,
      -0.01575828529894352,
      -0.03255963698029518,
      -0.06190899387001991,
      0.10812818259000778,
      0.03242125362157822,
      -0.10957901179790497,
      0.013301331549882889,
      0.004797674715518951,
      -0.0045156520791351795,
      0.003667385783046484,
      0.018136100843548775,
      -0.0014460511738434434,
      -0.0016008804086595774,
      -0.01592141203582287,
      0.03779623284935951,
      0.009783503599464893,
      0.06171313300728798,
      0.017604568973183632,
      -0.022418715059757233,
      -0.01434848178178072,
      0.010349342599511147,
      -0.03924281522631645,
      -0.04079638049006462,
      -0.0027817701920866966,
      0.05536595731973648,
      -0.006180902011692524,
      -0.039375610649585724,
      0.07156018912792206,
      0.07637080550193787,
      -0.022800931707024574,
      0.024033647030591965,
      -0.0014100344851613045,
      0.009761929512023926,
      -0.016994846984744072,
      0.028939997777342796,
      0.020152542740106583,
      -0.07499184459447861,
      -0.012209217995405197,
      0.03946588933467865,
      0.037695836275815964,
      -0.020189480856060982,
      -0.03506924957036972,
      -0.002526558469980955,
      -0.04220789670944214,
      -0.04953846335411072,
      0.03088933229446411,
      0.04920874536037445,
      0.022586870938539505,
      -0.03232983872294426,
      0.024604937061667442,
      -0.04996436461806297,
      -0.0336594320833683,
      0.002937169512733817,
      0.01071755401790142,
      -0.024209039285779,
      0.03174852207303047,
      0.03632068261504173,
      -0.04484632611274719,
      -0.03916311264038086,
      0.060200199484825134,
      -0.02038888819515705,
      -0.02603169158101082,
      0.00955958291888237,
      -0.04478739947080612,
      0.053410474210977554,
      0.04086502641439438,
      -0.01036524586379528,
      -0.054168928414583206,
      -0.10068245232105255,
      0.06531387567520142,
      -0.010554967448115349,
      0.024596942588686943,
      0.05432905629277229,
      0.008549658581614494,
      -0.07802432775497437,
      0.020191237330436707,
      -0.019227994605898857,
      -0.05893814563751221,
      -0.04633449390530586,
      0.06019476056098938,
      -0.07932056486606598,
      -0.036276355385780334,
      0.02247341349720955,
      -0.007577504031360149,
      0.015219878405332565,
      0.03170653432607651,
      0.06581287086009979,
      0.05465840920805931,
      0.07724873721599579,
      -0.013913045637309551,
      0.06208236142992973,
      -0.061095044016838074,
      -0.11643735319375992,
      -0.029231728985905647,
      -0.043913427740335464,
      0.050171539187431335,
      0.01289855781942606,
      -0.02636638842523098,
      -0.03069435991346836,
      0.009066205471754074,
      0.02160188928246498,
      -0.05213520675897598,
      0.027029994875192642,
      -0.04929690435528755,
      -0.04765379801392555,
      0.03614015877246857,
      -0.04459299147129059,
      -0.02792256511747837,
      -0.020309360697865486,
      0.08136474341154099,
      -0.07120221853256226,
      0.04164329916238785,
      0.04210103675723076,
      0.013864103704690933,
      -0.009288736619055271,
      -0.07438961416482925,
      0.024019772186875343,
      -0.055903200060129166,
      -0.006778838112950325,
      0.03777581825852394,
      -0.0035712101962417364,
      -0.09468774497509003,
      0.04644366353750229,
      0.03955938294529915,
      -0.018322208896279335,
      0.025050055235624313,
      -0.011981308460235596,
      -0.11821873486042023,
      -0.04784833639860153,
      -0.003232868853956461,
      -0.08559929579496384,
      -0.0005406170967034996,
      0.03257451578974724,
      0.08797692507505417,
      0.07294479757547379,
      -0.08763569593429565,
      0.0713176280260086,
      -0.02964049205183983,
      0.058127839118242264,
      -0.004146250430494547,
      -0.0536191426217556,
      -0.03972040116786957,
      0.010635944083333015,
      0.038790684193372726,
      -0.025310330092906952,
      0.030088866129517555,
      0.07405268400907516,
      0.04021136090159416,
      0.06535596400499344,
      0.056722428649663925,
      -0.06448104232549667,
      -0.01968660019338131,
      -0.05523431673645973,
      0.05010083690285683,
      -0.019185498356819153,
      0.04723818227648735,
      0.008211830630898476,
      -0.04606166481971741,
      0.036661021411418915,
      0.07446359843015671,
      0.07930327206850052,
      -0.02555248700082302,
      -0.07663285732269287,
      -0.034011196345090866,
      0.005505239591002464,
      -0.010661845095455647,
      -0.015087924897670746,
      -0.028119847178459167,
      0.032108403742313385,
      -0.05857756361365318,
      -0.0017087690066546202,
      -0.002958330325782299,
      -0.04403240233659744,
      -0.023465340957045555,
      0.09286165237426758,
      -0.014293989166617393,
      -0.025273185223340988,
      0.006773170083761215,
      -0.10002317279577255,
      0.08851885050535202,
      0.0029613496735692024,
      -0.050201673060655594,
      -0.024868125095963478,
      0.05190243944525719,
      -0.04939365014433861,
      -0.03946639224886894,
      0.02909758873283863,
      -0.09108588844537735,
      -0.06487027555704117,
      -0.07093146443367004,
      -0.02928290329873562,
      0.04950171336531639,
      -0.05356916785240173,
      0.04736460745334625,
      0.03524025157094002,
      -0.11735497415065765,
      0.026283275336027145,
      -0.02312229759991169,
      -0.0714445635676384,
      -0.06992553174495697,
      -0.05132945626974106,
      0.049984145909547806,
      -0.07813168317079544,
      -0.05254950001835823,
      0.03181253746151924,
      0.013100598007440567,
      0.045028358697891235,
      -0.0030396056827157736,
      0.017131181433796883,
      -0.048270631581544876,
      0.15150314569473267,
      -0.025562474504113197,
      0.0871155858039856,
      0.02777957171201706,
      0.008260269649326801,
      -0.01486967969685793,
      0.013088571839034557,
      0.049037933349609375,
      -0.028521424159407616,
      0.11194812506437302,
      0.009128723293542862,
      -0.013986053876578808,
      0.0013336328556761146,
      0.02022578939795494,
      0.024030277505517006,
      0.013135258108377457,
      -0.0347004234790802,
      0.00939772929996252,
      -0.05238375812768936,
      0.034803036600351334,
      -0.030666939914226532,
      0.06328925490379333,
      0.0032292127143591642,
      0.03171693533658981,
      -0.06836812198162079,
      0.014536326751112938,
      0.019747687503695488,
      0.02745814062654972,
      0.09325443208217621,
      0.02215156890451908,
      -0.05353061854839325,
      0.022661898285150528,
      0.020799625664949417,
      -0.011186942458152771,
      -0.06668857485055923,
      0.01819833181798458,
      0.0581689216196537,
      -0.0017707925289869308,
      0.036949653178453445,
      0.05458950251340866,
      0.029630934819579124,
      -0.029775142669677734,
      0.024325115606188774,
      -0.03272880241274834,
      0.07782160490751266,
      -0.0183402132242918,
      0.01721286214888096,
      -0.0837034061551094,
      0.09304653853178024,
      -0.014631335623562336,
      -0.030278921127319336,
      0.0359194353222847,
      -0.01987745426595211,
      0.03972841054201126,
      -0.03838169947266579,
      0.06565280258655548,
      -0.00824244599789381,
      -0.009384731762111187
    ],
    [
      0.06072157248854637,
      -0.008084717206656933,
      0.03182889521121979,
      0.05472193658351898,
      -0.029673084616661072,
      -0.0006704085390083492,
      -0.008611241355538368,
      -0.038823507726192474,
      -0.04044972360134125,
      -0.04868343472480774,
      -0.009172613732516766,
      -0.06631859391927719,
      -0.023089101538062096,
      -0.05945807695388794,
      -0.06254393607378006,
      -0.00946012418717146,
      0.012474722228944302,
      -0.01858661323785782,
      0.019426332786679268,
      -0.0004630794283002615,
      -0.03102334402501583,
      -0.047200970351696014,
      -0.05975586548447609,
      -0.0038259983994066715,
      0.004420903977006674,
      -0.10122432559728622,
      -0.006899649742990732,
      0.017645979300141335,
      0.019000329077243805,
      0.07538555562496185,
      -0.0406717024743557,
      -0.048869069665670395,
      -0.02597232349216938,
      0.012645279057323933,
      -0.018341070041060448,
      0.025302940979599953,
      -0.043236926198005676,
      -0.00425251480191946,
      -0.06173783913254738,
      -0.02837185002863407,
      -0.03344997763633728,
      -0.0014264397323131561,
      -0.009662874974310398,
      0.021301716566085815,
      0.00911613181233406,
      -0.022426120936870575,
      0.013142792508006096,
      0.09730744361877441,
      0.002958060707896948,
      0.004505023825913668,
      0.005144026130437851,
      0.029979528859257698,
      0.06667478382587433,
      0.05058182775974274,
      -0.020284775644540787,
      0.11489621549844742,
      -0.005137809552252293,
      0.04878530278801918,
      0.13180027902126312,
      -0.024421047419309616,
      -0.004074226599186659,
      -0.00476578576490283,
      -0.017851045355200768,
      -0.007569534238427877,
      -0.08176475018262863,
      -0.007564840838313103,
      0.05994425341486931,
      -0.03454882279038429,
      -0.025500567629933357,
      0.0026170227210968733,
      -0.011707666330039501,
      0.05110972374677658,
      -0.027002941817045212,
      -0.03945682942867279,
      0.0032383440993726254,
      -0.06054754555225372,
      -0.016842518001794815,
      -0.04115823656320572,
      -0.05099416524171829,
      0.030594946816563606,
      0.02238960564136505,
      0.008356207050383091,
      -0.01414599921554327,
      -0.024735720828175545,
      -0.022425921633839607,
      -0.011309618130326271,
      0.042665205895900726,
      0.024507999420166016,
      0.025636740028858185,
      -0.06768413633108139,
      -0.08092588186264038,
      -0.02966373600065708,
      0.010910806246101856,
      0.028535179793834686,
      0.018891697749495506,
      -0.006682360544800758,
      0.050503768026828766,
      0.02319289743900299,
      0.01199759915471077,
      0.054126206785440445,
      -0.009831946343183517,
      -0.007352390326559544,
      -0.015201990492641926,
      -0.054030485451221466,
      -0.018954111263155937,
      0.039841681718826294,
      -0.044294197112321854,
      0.01826833188533783,
      -0.07555437833070755,
      0.012668300420045853,
      -0.07318361848592758,
      0.015345080755650997,
      -0.05253547430038452,
      0.0600903183221817,
      -0.03530661761760712,
      0.03657658398151398,
      -0.055613260716199875,
      0.00633002957329154,
      0.06951764971017838,
      -0.005476424470543861,
      -0.04215921834111214,
      -0.004717263393104076,
      0.0471966490149498,
      -0.03273361548781395,
      -0.07851392030715942,
      0.03651724383234978,
      -0.028027065098285675,
      0.10554099828004837,
      0.004178809467703104,
      -0.03473362699151039,
      0.005252458155155182,
      -0.01058686338365078,
      0.003010797081515193,
      -0.0128173828125,
      -0.0009229858405888081,
      -0.012667490169405937,
      -0.021208200603723526,
      0.04657934233546257,
      -0.0047158352099359035,
      0.014907924458384514,
      0.025610987097024918,
      0.15946950018405914,
      -0.029044650495052338,
      -0.014189304783940315,
      0.029311850666999817,
      -0.06125398352742195,
      -0.00040243263356387615,
      -0.06194916367530823,
      0.07929227501153946,
      -0.09166152775287628,
      0.0564676970243454,
      0.024206342175602913,
      0.019652806222438812,
      -0.0689220204949379,
      -0.07549089938402176,
      0.013302627019584179,
      0.039321839809417725,
      -0.00584834860637784,
      -0.025347311049699783,
      0.030681278556585312,
      0.060888275504112244,
      0.024422796443104744,
      0.13497933745384216,
      -0.006735062226653099,
      -0.023714378476142883,
      -0.0023170169442892075,
      0.006965788081288338,
      -0.06855901330709457,
      0.016848506405949593,
      0.03386368975043297,
      -0.01013889443129301,
      0.0032565093133598566,
      0.01027558371424675,
      -0.012834207154810429,
      -0.0740145891904831,
      -0.016948221251368523,
      0.023176023736596107,
      0.001666942611336708,
      -0.024192295968532562,
      0.0020225488115102053,
      -0.03816518932580948,
      0.025971079245209694,
      -0.016298016533255577,
      0.0069732991978526115,
      0.02810300514101982,
      -0.02070263773202896,
      -0.052665770053863525,
      0.036559075117111206,
      0.019095715135335922,
      -0.035020019859075546,
      -0.05460591986775398,
      -0.01902192458510399,
      0.021833978593349457,
      0.05717483162879944,
      -0.0727425366640091,
      -8.9761677372735e-05,
      -0.006266308482736349,
      -0.003878162708133459,
      0.0564747154712677,
      -0.03608474135398865,
      -0.001387712312862277,
      -0.1373952180147171,
      -0.04620851203799248,
      0.01583482138812542,
      0.008533123880624771,
      0.03147179260849953,
      0.04521181806921959,
      -0.013493450358510017,
      0.03203814849257469,
      -0.0033589277882128954,
      0.04266135022044182,
      -0.029128573834896088,
      0.03198875114321709,
      -0.007208685856312513,
      0.05242471769452095,
      -0.021464277058839798,
      -0.020219072699546814,
      0.03262120857834816,
      0.018766190856695175,
      -0.07422291487455368,
      -0.023251593112945557,
      0.026509827002882957,
      -0.04551776871085167,
      -0.006833992432802916,
      -0.04373094439506531,
      0.017262527719140053,
      -0.009207332506775856,
      0.08995171636343002,
      -0.031572673469781876,
      -0.025195829570293427,
      0.04297474026679993,
      -0.002982140053063631,
      -0.051465362310409546,
      -0.05878964066505432,
      0.00365733215585351,
      0.017338037490844727,
      0.028507770970463753,
      -0.11494910717010498,
      -0.10270138829946518,
      -0.01995110884308815,
      -0.033832669258117676,
      0.00438866438344121,
      -0.034418169409036636,
      -0.041387658566236496,
      0.03223440423607826,
      -0.021400174126029015,
      0.0018372833728790283,
      -0.049277227371931076,
      -0.04032647982239723,
      0.03061559610068798,
      0.041292909532785416,
      -0.025113075971603394,
      -0.07047978043556213,
      0.10026602447032928,
      -0.03920585289597511,
      0.04494933784008026,
      -0.02521386742591858,
      0.0035733103286474943,
      -0.036970388144254684,
      0.01596815139055252,
      0.000658569682855159,
      -0.0028126684483140707,
      0.04060724005103111,
      -0.021395938470959663,
      -0.01590484194457531,
      0.06990088522434235,
      0.04368291050195694,
      -0.040851496160030365,
      0.0610797293484211,
      -0.06411300599575043,
      -0.04604816436767578,
      -0.03933320939540863,
      -0.025601647794246674,
      0.046826086938381195,
      -0.05045687407255173,
      0.016200397163629532,
      -0.02099800296127796,
      -0.007775445468723774,
      -0.011390816420316696,
      0.019090373069047928,
      0.05997665971517563,
      0.02921740710735321,
      0.009459661319851875,
      -0.03759228065609932,
      -0.02794053964316845,
      -0.0019229261670261621,
      -0.038992494344711304,
      -0.06269889324903488,
      -0.009129546582698822,
      -0.03551635891199112,
      -0.11716929823160172,
      0.013502879068255424,
      0.03103754110634327,
      0.0315011590719223,
      -0.015610137023031712,
      -0.01997857727110386,
      0.0501924529671669,
      0.013763709925115108,
      0.014613797888159752,
      -0.07177813351154327,
      -0.006264419760555029,
      -0.020531294867396355,
      -0.029836082831025124,
      -0.04908931627869606,
      -0.007679045666009188,
      -0.06260692328214645,
      -0.035624902695417404,
      0.026957323774695396,
      -0.05530958250164986,
      0.03658181428909302,
      -0.10697390884160995,
      -0.0005467974115163088,
      -0.08999846130609512,
      0.047756582498550415,
      -0.03390970453619957,
      -0.013344836421310902,
      0.010122470557689667,
      0.08433409780263901,
      0.006688002496957779,
      0.07976818829774857,
      0.03194233775138855,
      0.027194341644644737,
      -0.03164674714207649,
      -0.004456920083612204,
      -0.018966158851981163,
      0.03414474055171013,
      -0.03859802708029747,
      0.029590371996164322,
      -0.03673018142580986,
      0.004617687314748764,
      -0.024134507402777672,
      -0.016948292031884193,
      -0.021933861076831818,
      -0.014031090773642063,
      -0.04491801559925079,
      -0.04891987890005112,
      -0.03513330593705177,
      -0.008342746645212173,
      -0.030160628259181976,
      -0.014851890504360199,
      0.010273636318743229,
      -0.004009654745459557,
      0.016803955659270287,
      0.0367925763130188,
      -0.09125661104917526,
      0.052110567688941956,
      0.04233448952436447,
      0.01583809405565262,
      -0.046164724975824356,
      -0.04668622836470604,
      -0.01987520419061184,
      -0.06288272887468338,
      0.028610778972506523,
      -0.01416193787008524,
      -0.061460815370082855,
      0.008678929880261421,
      -0.03694864735007286,
      0.0006206677062436938,
      -0.03691130504012108,
      0.07681086659431458,
      -0.013803169131278992,
      -0.026781808584928513,
      0.02888699434697628,
      0.04149607941508293,
      0.038796085864305496,
      0.07962368428707123,
      0.00673449644818902,
      0.021785931661725044,
      0.021046215668320656,
      -0.0004760810115840286,
      -0.013741515576839447,
      0.0073688640259206295,
      0.08129972219467163,
      0.05063841491937637,
      -0.05822361633181572,
      -0.015154717490077019,
      -0.06487429141998291,
      -0.0006231942097656429,
      0.05254733934998512,
      0.004068026784807444,
      -0.07779668271541595,
      0.08462780714035034,
      0.08687397092580795,
      -0.05036654695868492,
      0.0007461989880539477,
      -0.04413468763232231,
      -0.0731491670012474,
      0.006335895042866468,
      -0.018054651096463203,
      -0.0024080071598291397,
      0.04490736499428749,
      0.09268155694007874,
      0.028762778267264366,
      0.02000506967306137,
      0.019789207726716995,
      -0.06070644408464432,
      0.049395278096199036,
      -0.10055774450302124,
      -0.02782820537686348,
      0.0004952349117957056,
      -0.049127399921417236,
      -0.019433895125985146,
      -0.09345564246177673,
      -0.03599519655108452,
      0.056317493319511414,
      -0.010971048846840858,
      -0.022062117233872414,
      -0.026982910931110382,
      -0.028225082904100418,
      0.01952223666012287,
      0.02476915344595909,
      -0.060572605580091476,
      -0.03112012892961502,
      0.04927984997630119,
      -0.02308361604809761,
      -0.027895789593458176,
      0.09897299855947495,
      0.029911193996667862,
      -0.013932912610471249,
      -0.0669308453798294,
      0.08053650707006454,
      0.0746069848537445,
      -0.002645673230290413,
      -0.015918565914034843,
      -0.042436014860868454,
      -0.049963776022195816,
      -0.007452007383108139,
      0.005686535034328699,
      -0.08144993335008621,
      -0.04649345204234123,
      -0.0873657613992691,
      0.021291939541697502,
      -0.06667495518922806,
      -0.003676237305626273,
      0.008701643906533718,
      -0.03914209082722664,
      0.035553112626075745,
      0.005649125669151545,
      0.04692813381552696,
      -0.09549104422330856,
      0.014910760335624218,
      -0.050999004393815994,
      0.014909550547599792,
      0.08045622706413269,
      0.04111666977405548,
      0.02785286121070385,
      0.03326717019081116,
      0.050891026854515076,
      -0.019442280754446983,
      -0.015078014694154263,
      -0.028201047331094742,
      0.03914070874452591,
      -0.04726605489850044,
      -0.046490203589200974,
      0.02626708149909973,
      0.03271070867776871,
      0.024831317365169525,
      0.06315380334854126,
      -0.06424465775489807,
      -0.06333117932081223,
      -0.07879193872213364,
      -0.025790518149733543,
      -0.11857639253139496,
      0.015779683366417885,
      0.030323660001158714,
      -0.022495336830615997,
      0.01195480115711689,
      -0.08643506467342377,
      -0.014619798399508,
      -0.000658868404570967,
      -0.002783233067020774,
      0.06431718170642853,
      0.02065078727900982,
      -0.04269791767001152,
      -0.052021998912096024,
      0.01973635144531727,
      0.032081201672554016,
      0.015040785074234009,
      0.010326240211725235,
      0.029991518706083298,
      0.057332560420036316,
      -0.003491253824904561,
      -0.08591273427009583,
      0.026705633848905563,
      0.002611954230815172,
      -0.028659123927354813,
      0.0322575680911541,
      -0.028268994763493538,
      0.010508190840482712,
      0.027739189565181732,
      0.03755657747387886,
      -0.058539364486932755,
      0.009424300864338875,
      0.019231615588068962,
      0.015994805842638016,
      0.015817617997527122,
      0.006774147041141987,
      -0.022304333746433258,
      0.02162928320467472,
      0.004618945065885782,
      -0.032919369637966156,
      -0.05026867240667343,
      0.04188653081655502
    ],
    [
      0.0397082194685936,
      0.03850783035159111,
      0.001415952225215733,
      0.052938248962163925,
      0.05893774330615997,
      0.023465771228075027,
      0.06745754182338715,
      0.026800721883773804,
      -0.02926281839609146,
      0.03441851958632469,
      -0.03844189643859863,
      0.043687012046575546,
      -0.07367423921823502,
      -0.045616839081048965,
      -0.03013692796230316,
      0.00683750631287694,
      0.12341492623090744,
      0.12831024825572968,
      -0.005849501118063927,
      0.03178812563419342,
      0.04966796562075615,
      0.11410161852836609,
      0.06435388326644897,
      -0.03278886154294014,
      0.07119238376617432,
      -0.040315449237823486,
      0.008120116777718067,
      0.07589095830917358,
      -0.012034989893436432,
      -0.023998957127332687,
      0.0020698115695267916,
      0.031189171597361565,
      -0.06239745393395424,
      0.03442733362317085,
      -0.014611938036978245,
      -0.0221431665122509,
      -0.05960489809513092,
      -0.0654597282409668,
      0.045502737164497375,
      -0.03113139234483242,
      -0.017507830634713173,
      0.008087162859737873,
      0.015421304851770401,
      0.014074073173105717,
      -0.031822334975004196,
      0.10740072280168533,
      0.03952939435839653,
      0.001843684702180326,
      0.04556963965296745,
      0.04567622020840645,
      0.010332085192203522,
      -0.007060775998979807,
      0.03135312348604202,
      0.0008123533334583044,
      0.016893506050109863,
      -0.03179509937763214,
      -0.06109732761979103,
      -0.055408623069524765,
      0.04131519794464111,
      -0.029985586181282997,
      -0.007924076169729233,
      -0.02265636995434761,
      -0.027402672916650772,
      0.06917843967676163,
      -0.02243032492697239,
      0.020581359043717384,
      -0.008840520866215229,
      0.019994275644421577,
      -0.01214646827429533,
      -0.04820528253912926,
      0.07952747493982315,
      -0.056666236370801926,
      0.0002399352815700695,
      0.0006676618941128254,
      -0.07024864852428436,
      0.05484829097986221,
      -0.0028121767099946737,
      -0.0319230742752552,
      -0.01757887378334999,
      -0.007974226959049702,
      0.019319383427500725,
      0.03151880204677582,
      -0.08927075564861298,
      0.009993847459554672,
      0.06128725782036781,
      0.010475142858922482,
      -0.07460618019104004,
      -0.015139030292630196,
      -0.07313250750303268,
      -0.04792094603180885,
      -0.025387514382600784,
      0.011468828655779362,
      0.026493074372410774,
      0.034215886145830154,
      -0.016659382730722427,
      -0.0033480862621217966,
      -0.009458143264055252,
      0.05221182480454445,
      -0.05942268297076225,
      0.039740826934576035,
      -0.026632776483893394,
      0.05149683728814125,
      -0.0023901821114122868,
      -0.07215035706758499,
      -0.00854392908513546,
      -0.06820375472307205,
      -0.025257356464862823,
      -0.006150415632873774,
      0.026185477152466774,
      0.00851737055927515,
      -0.04253646358847618,
      0.022458963096141815,
      0.027359599247574806,
      0.023269761353731155,
      -0.03344140201807022,
      -0.0003455670375842601,
      0.02080542966723442,
      -0.023353703320026398,
      -0.019903523847460747,
      0.052223607897758484,
      -0.0667346715927124,
      -0.007298636715859175,
      -0.06081046536564827,
      0.030923396348953247,
      0.00782082974910736,
      -0.06616891175508499,
      0.026692846789956093,
      0.020035235211253166,
      -0.004037623293697834,
      -0.01749185286462307,
      -0.02673712745308876,
      -0.03681724891066551,
      0.0026175742968916893,
      0.08575446158647537,
      -0.003029275918379426,
      0.04120999947190285,
      0.03530307114124298,
      -0.002069793874397874,
      0.07674038410186768,
      -0.0012695154873654246,
      -0.0128327626734972,
      -0.019699402153491974,
      -0.043224431574344635,
      -0.07627788186073303,
      -0.003646288998425007,
      0.07035192102193832,
      0.016189247369766235,
      -0.02089952677488327,
      0.08180409669876099,
      0.013045528903603554,
      0.050334952771663666,
      -0.05500718206167221,
      -0.044325295835733414,
      -0.06819242984056473,
      0.03601991385221481,
      -0.04420243576169014,
      -0.04930851608514786,
      -0.0008226113859564066,
      -0.012656635604798794,
      0.026726888492703438,
      -0.00185105309356004,
      0.024770142510533333,
      0.06468740850687027,
      0.023641478270292282,
      0.06291985511779785,
      0.012106059119105339,
      0.002433117013424635,
      -0.05825189873576164,
      0.024311481043696404,
      0.06475118547677994,
      0.09388935565948486,
      -0.0068423328921198845,
      -0.028739651665091515,
      -0.013973577879369259,
      0.0027323272079229355,
      0.04471782222390175,
      -0.014741571620106697,
      -0.02961360663175583,
      0.11257043480873108,
      0.08128494024276733,
      -0.025113407522439957,
      0.048961639404296875,
      0.03216642141342163,
      0.011719629168510437,
      0.034941937774419785,
      0.05502314120531082,
      -0.0015415105735883117,
      0.053853798657655716,
      0.051516998559236526,
      -0.012465580366551876,
      0.044745057821273804,
      -0.0536491796374321,
      -0.07110096514225006,
      0.024043601006269455,
      0.0413094237446785,
      0.01975168101489544,
      -0.029596369713544846,
      0.0807051882147789,
      0.03291722759604454,
      -0.0458257831633091,
      -0.054267242550849915,
      -0.008050860837101936,
      -0.01404611486941576,
      -0.014829026535153389,
      -0.009074694477021694,
      -0.0014699449529871345,
      0.03416406363248825,
      0.025671355426311493,
      0.048314157873392105,
      0.08713804185390472,
      -0.012337596155703068,
      -0.011260413564741611,
      0.013126352801918983,
      -0.008587395772337914,
      -0.0027745284605771303,
      0.12140674144029617,
      -0.05196194350719452,
      -0.011809241026639938,
      -0.05883200839161873,
      0.016946732997894287,
      -0.04314211755990982,
      -0.0368337519466877,
      -0.017784208059310913,
      -0.04261346906423569,
      -0.05007809400558472,
      0.09488817304372787,
      -0.024332160130143166,
      -0.016785338521003723,
      0.033857569098472595,
      -0.012280572205781937,
      -0.03384256735444069,
      0.027068182826042175,
      -0.0312790684401989,
      -0.003713147481903434,
      -0.08600934594869614,
      0.07093573361635208,
      0.00803198479115963,
      0.022803895175457,
      0.0037341106217354536,
      0.014966323971748352,
      -0.07557777315378189,
      0.0014678087318316102,
      -0.08529295772314072,
      0.03571270778775215,
      0.032831959426403046,
      0.029068276286125183,
      -0.019496364519000053,
      0.004353167489171028,
      0.05848303809762001,
      -0.014666677452623844,
      0.013674852438271046,
      -0.018124403432011604,
      -0.04112226888537407,
      -0.006179041229188442,
      -0.054692234843969345,
      -0.03773095831274986,
      0.04223277419805527,
      0.005664352793246508,
      -0.013129462487995625,
      0.026412587612867355,
      0.0412185862660408,
      -0.03222488984465599,
      0.016928890720009804,
      -0.010717269033193588,
      0.07855608314275742,
      0.002645600587129593,
      0.04716948792338371,
      0.008664978668093681,
      -0.10394090414047241,
      -0.02383512258529663,
      0.021360240876674652,
      0.008293748833239079,
      -0.05081656947731972,
      -0.04651566222310066,
      -0.00502818776294589,
      -0.031273458153009415,
      -0.02819228358566761,
      -0.013724510557949543,
      -0.08910032361745834,
      -0.09190552681684494,
      0.020075563341379166,
      -0.009630073793232441,
      0.02083980292081833,
      -0.04446312412619591,
      0.007833266630768776,
      0.06948183476924896,
      0.03229854628443718,
      -0.06148121505975723,
      -0.04788363352417946,
      -0.050799962133169174,
      -0.010456731542944908,
      0.017146814614534378,
      0.05626063048839569,
      0.01710999198257923,
      0.012319209054112434,
      0.0521886944770813,
      0.030077291652560234,
      -0.06633003056049347,
      0.014980974607169628,
      0.011956393718719482,
      0.017966926097869873,
      -0.009577469900250435,
      0.006515410263091326,
      -0.04888341575860977,
      0.008832857944071293,
      0.05553127080202103,
      0.07168474048376083,
      0.0829898789525032,
      0.0312744602560997,
      -0.03809482604265213,
      0.02288205921649933,
      -0.04970838129520416,
      0.004388411529362202,
      0.03847620636224747,
      0.0072865476831793785,
      -0.017352629452943802,
      -0.022807205095887184,
      0.03366529941558838,
      -0.023243963718414307,
      0.03631572425365448,
      0.02827187441289425,
      -0.03337160497903824,
      -0.012140816077589989,
      -0.010775423608720303,
      -0.003283698344603181,
      0.07229864597320557,
      -0.0034656934440135956,
      -0.04122579097747803,
      0.00845257192850113,
      0.0363735556602478,
      -0.0590524859726429,
      -0.03274917975068092,
      0.07262086123228073,
      0.01961400732398033,
      0.015386760234832764,
      -0.037056293338537216,
      -0.013016240671277046,
      0.013499065302312374,
      0.03235110640525818,
      -0.0467979721724987,
      0.04384713992476463,
      -0.05897190794348717,
      -0.04680202528834343,
      -0.025784842669963837,
      0.07722552865743637,
      -0.0800527036190033,
      -0.05259941145777702,
      -0.02853746898472309,
      0.02804144285619259,
      0.01955520734190941,
      0.025770360603928566,
      0.03940698876976967,
      0.08902650326490402,
      -0.01686362363398075,
      0.0031940198969095945,
      -0.010863916017115116,
      -0.0022174357436597347,
      0.013928433880209923,
      0.03339279443025589,
      -0.01458857674151659,
      0.037863072007894516,
      0.024349240586161613,
      0.04604344069957733,
      -0.023602232336997986,
      0.04911581799387932,
      -0.007993047125637531,
      -0.1296612173318863,
      -0.07526577264070511,
      -0.11640504747629166,
      0.06733214110136032,
      0.06631392985582352,
      0.0006051342934370041,
      -0.03382353484630585,
      -0.009724728763103485,
      0.0625409483909607,
      -0.07160016894340515,
      0.028272993862628937,
      -0.0234382227063179,
      0.008551704697310925,
      0.0025327662006020546,
      0.03813238814473152,
      0.0406218096613884,
      0.025546375662088394,
      -0.04145762696862221,
      -0.05429774895310402,
      -0.05621038004755974,
      0.011854825541377068,
      0.03036310151219368,
      -0.03428272530436516,
      0.06756988912820816,
      0.009010937064886093,
      0.018253816291689873,
      0.013038832694292068,
      0.06639666855335236,
      -0.01528320275247097,
      -0.04987131059169769,
      0.04458729177713394,
      -0.011249211616814137,
      0.006488927640020847,
      0.05783994495868683,
      0.0039421021938323975,
      0.08003505319356918,
      0.03331438824534416,
      0.016964547336101532,
      -0.08241836726665497,
      -0.05685427039861679,
      -0.010111700743436813,
      0.019393783062696457,
      0.02233831025660038,
      0.016346987336874008,
      0.02772505395114422,
      0.03658556565642357,
      -0.07163085788488388,
      0.007051432505249977,
      0.0018979778978973627,
      0.00630903197452426,
      -0.009490289725363255,
      0.01907687820494175,
      0.0596458725631237,
      0.0899900272488594,
      -0.04726410657167435,
      -0.052843645215034485,
      0.008149354718625546,
      -0.014821083284914494,
      -0.04249662533402443,
      0.02532329224050045,
      -0.06120048090815544,
      0.04517948627471924,
      -0.026327701285481453,
      0.011334038339555264,
      0.000590465497225523,
      0.03228197991847992,
      -0.05601810663938522,
      -0.03626278415322304,
      -0.0015207469696179032,
      0.004389300476759672,
      0.05730956420302391,
      0.007449507713317871,
      -0.04666343331336975,
      -0.052116889506578445,
      -0.02883923053741455,
      0.06133975461125374,
      0.004164416808634996,
      0.09059274941682816,
      -0.02328409254550934,
      -0.04325070604681969,
      -0.029706591740250587,
      0.061626266688108444,
      -0.027524778619408607,
      0.060159165412187576,
      0.036249805241823196,
      -0.015050999820232391,
      0.018296556547284126,
      -0.06677113473415375,
      0.026648811995983124,
      0.03376968950033188,
      -0.043143171817064285,
      0.008226401172578335,
      0.07535471767187119,
      -0.07132243365049362,
      0.017105063423514366,
      0.004801859147846699,
      0.035953741520643234,
      -0.031958404928445816,
      -0.062153495848178864,
      0.00709488894790411,
      0.0636235773563385,
      0.02519938163459301,
      -0.024864641949534416,
      0.012005161494016647,
      -0.045860979706048965,
      0.02315216325223446,
      -0.06041362136602402,
      0.08948322385549545,
      -0.0041864956729114056,
      -0.0032686206977814436,
      -0.020108845084905624,
      -0.016046572476625443,
      -0.037696655839681625,
      -0.018516497686505318,
      0.015241547487676144,
      0.04233022779226303,
      0.004174856003373861,
      -0.03878291696310043,
      0.017952021211385727,
      0.0178051944822073,
      -0.02913113869726658,
      -0.008372909389436245,
      0.0808844268321991,
      0.0011700401082634926,
      -0.008760282769799232,
      -0.02107565850019455,
      0.054221123456954956,
      -0.04725595936179161,
      0.02655792608857155,
      -0.018638884648680687,
      0.030718209221959114,
      0.011720005422830582,
      -0.0635790154337883,
      0.0433596707880497,
      -0.005840248428285122,
      0.01238145213574171,
      0.022526293992996216
    ],
    [
      -0.02187504991889,
      0.051720328629016876,
      -0.018904970958828926,
      -0.044185005128383636,
      -0.017740845680236816,
      0.006335791200399399,
      0.038078054785728455,
      -0.02941400185227394,
      0.018824348226189613,
      0.03295185789465904,
      -0.005626881495118141,
      0.01251919660717249,
      0.05007043853402138,
      -0.05803394317626953,
      -0.04937160387635231,
      0.04808347299695015,
      0.06352166086435318,
      0.05794110521674156,
      0.0559554249048233,
      -0.019357901066541672,
      -0.01646987721323967,
      -0.026544239372015,
      0.03488154336810112,
      0.03438124060630798,
      0.06603477895259857,
      0.033734485507011414,
      -0.03928551450371742,
      -0.01566947065293789,
      0.022851726040244102,
      0.04252368584275246,
      -0.06600449979305267,
      -0.09474034607410431,
      -0.07895401120185852,
      0.013634093105793,
      -0.026224123314023018,
      -0.008390132337808609,
      -0.0240792166441679,
      0.06596454232931137,
      -0.013001888990402222,
      -0.011238384991884232,
      0.007137521170079708,
      -0.03447633236646652,
      0.07471394538879395,
      -0.044647663831710815,
      -0.05059373378753662,
      -0.0017231834353879094,
      0.0341935008764267,
      -0.06426889449357986,
      -0.10347867012023926,
      0.056336116045713425,
      0.057400260120630264,
      0.006720251869410276,
      -0.010711065493524075,
      -0.07756421715021133,
      0.06675537675619125,
      0.021265419200062752,
      0.10882625728845596,
      0.001852013636380434,
      -0.05040864646434784,
      -0.022459199652075768,
      -0.03024381399154663,
      0.02293267659842968,
      -0.09527026116847992,
      -0.059983689337968826,
      -0.020299840718507767,
      -0.01344368513673544,
      0.00890466757118702,
      -0.030209746211767197,
      -0.044752854853868484,
      -0.0024361710529774427,
      -0.012361149303615093,
      0.014976650476455688,
      -0.017774131149053574,
      -0.013082287274301052,
      0.011476431041955948,
      0.05892769992351532,
      -0.03311058506369591,
      0.09163794666528702,
      -0.004142232239246368,
      -0.02319112792611122,
      0.0031588682904839516,
      -0.030888117849826813,
      0.0639563575387001,
      0.03531831502914429,
      -0.020272154361009598,
      0.028931498527526855,
      -0.004145623184740543,
      -0.03806713968515396,
      -0.051615625619888306,
      0.024443548172712326,
      0.0335761159658432,
      0.009932897053658962,
      0.008248788304626942,
      0.0367882065474987,
      0.03006797283887863,
      -0.07534582167863846,
      0.02572181634604931,
      0.029661577194929123,
      0.06503623723983765,
      -0.039985768496990204,
      -0.019501807168126106,
      0.06915952265262604,
      -0.010799520649015903,
      -0.008125925436615944,
      0.04409755766391754,
      -0.07212477177381516,
      0.015665560960769653,
      0.0033572453539818525,
      -0.04356564208865166,
      0.019231721758842468,
      0.032456014305353165,
      0.01634387858211994,
      0.006896789185702801,
      -0.10189618170261383,
      -0.01903107389807701,
      0.018312864005565643,
      -0.0591491162776947,
      -0.09699996560811996,
      -0.020288147032260895,
      -0.0895702913403511,
      -0.03245153650641441,
      0.0218497384339571,
      -0.037505097687244415,
      0.0012084668269380927,
      0.045705389231443405,
      -0.07216471433639526,
      -0.03297291323542595,
      -0.005127853248268366,
      0.04683057591319084,
      0.014530157670378685,
      0.01710362732410431,
      0.04061296954751015,
      -0.007801660802215338,
      -0.03296436369419098,
      0.06067579984664917,
      0.0031254664063453674,
      -0.01975884661078453,
      0.012636041268706322,
      -0.05748402327299118,
      0.00992451049387455,
      0.06096058338880539,
      -0.00524302339181304,
      -0.05160492658615112,
      -0.03244451433420181,
      0.007558115758001804,
      0.05370214208960533,
      -0.07437162101268768,
      -0.010140589438378811,
      0.02021351456642151,
      -0.0475393682718277,
      -0.009052668698132038,
      0.06075786426663399,
      0.02776055596768856,
      0.003198756370693445,
      -0.008349892683327198,
      -0.010093734599649906,
      0.03913410007953644,
      -0.012508900836110115,
      -0.03974150866270065,
      -0.03406693413853645,
      0.004686363972723484,
      -0.015068923123180866,
      -0.008843136951327324,
      0.047259218990802765,
      -0.010048856027424335,
      0.028714127838611603,
      0.0020096907392144203,
      0.0010791465174406767,
      -0.015387526713311672,
      0.06589927524328232,
      -0.033493541181087494,
      0.021892432123422623,
      -0.024989673867821693,
      -0.04959319531917572,
      -0.008295324631035328,
      -0.05201949179172516,
      -0.030931543558835983,
      -0.03612877428531647,
      -0.041287682950496674,
      0.040933623909950256,
      -0.029793327674269676,
      -0.06620796024799347,
      -0.027809636667370796,
      -0.0036139606963843107,
      0.009134159423410892,
      0.039644740521907806,
      -0.051823221147060394,
      0.07744558900594711,
      0.008205176331102848,
      0.09448514878749847,
      0.01765873096883297,
      -0.018641110509634018,
      -0.046106528490781784,
      0.025444718077778816,
      0.04971104487776756,
      0.031905148178339005,
      -0.04827973246574402,
      0.04077715799212456,
      -0.1033887192606926,
      0.06252657622098923,
      -0.016176709905266762,
      -0.0743766501545906,
      -0.024579061195254326,
      0.017277535051107407,
      0.07924173772335052,
      -0.020837780088186264,
      -0.025638209655880928,
      -0.06466936320066452,
      0.08293462544679642,
      0.04236059635877609,
      0.04715694487094879,
      0.030121859163045883,
      0.032605454325675964,
      0.04261741787195206,
      -0.04224183410406113,
      0.04206946864724159,
      -0.010816850699484348,
      -0.0009884177707135677,
      0.011699787341058254,
      0.08405662328004837,
      0.04258427768945694,
      -0.05868013575673103,
      0.038360390812158585,
      0.031119782477617264,
      0.04613696411252022,
      0.005482536740601063,
      -0.0354931466281414,
      -0.053814880549907684,
      -0.0009008495835587382,
      -0.03249610587954521,
      -0.005020136944949627,
      -0.026170015335083008,
      -0.0789853185415268,
      -0.061149340122938156,
      -0.001288493862375617,
      -0.010247885249555111,
      0.107607401907444,
      0.02016345039010048,
      -0.006449898239225149,
      -0.005030461121350527,
      0.04212792217731476,
      -0.01201831828802824,
      -0.030451202765107155,
      0.005116995424032211,
      -0.022417820990085602,
      -0.01321717631071806,
      -0.10465297847986221,
      0.034559573978185654,
      -0.1085648387670517,
      0.009589127264916897,
      -0.029748981818556786,
      -0.005369564052671194,
      0.03821897506713867,
      0.04781078174710274,
      0.035398054867982864,
      -0.022613584995269775,
      0.019045615568757057,
      0.023097485303878784,
      -0.004177183378487825,
      0.03389955684542656,
      0.015818309038877487,
      0.024162650108337402,
      0.022507673129439354,
      -0.024738939478993416,
      0.039934542030096054,
      -0.01753246784210205,
      -0.03215190768241882,
      -0.0545312762260437,
      -0.0005179221625439823,
      -0.017276285216212273,
      0.011865847744047642,
      -0.0027844926808029413,
      -0.0665634348988533,
      -0.0333261676132679,
      0.04450519010424614,
      0.047070834785699844,
      0.012495942413806915,
      -0.06182635948061943,
      0.04272990673780441,
      -0.0388311930000782,
      0.04286915808916092,
      -0.004211797844618559,
      0.03016018122434616,
      -0.004226141609251499,
      0.07074374705553055,
      -0.008965902030467987,
      -0.05228071287274361,
      0.056430406868457794,
      0.02122240886092186,
      -0.03581263870000839,
      -0.011068298481404781,
      0.009416146203875542,
      -0.016878638416528702,
      0.03425776585936546,
      -0.04297179356217384,
      0.039440784603357315,
      0.038836415857076645,
      0.008005928248167038,
      -0.03854333981871605,
      -0.007627215702086687,
      -0.012865322642028332,
      -0.01494516059756279,
      0.016930116340517998,
      -0.0016633939230814576,
      -0.009948882274329662,
      -0.02928381972014904,
      0.016764670610427856,
      -0.024295637384057045,
      -0.020742978900671005,
      -0.051473066210746765,
      -0.02457457035779953,
      -0.01934739761054516,
      0.07806117087602615,
      -0.046254537999629974,
      0.014049327000975609,
      -0.027325259521603584,
      0.09349723905324936,
      0.03240969777107239,
      -0.05598434433341026,
      -0.07753104716539383,
      0.015562081709504128,
      0.01566445454955101,
      -0.02382148988544941,
      0.023940788581967354,
      0.028336798772215843,
      0.04768981412053108,
      0.010771509259939194,
      -0.018511293455958366,
      -0.10123541206121445,
      -0.05875588208436966,
      0.020487284287810326,
      -0.047196973115205765,
      0.06916385889053345,
      -0.01615726947784424,
      0.07746927440166473,
      -0.009736398234963417,
      -0.015142061747610569,
      0.07234311103820801,
      -0.05255292356014252,
      -0.030803075060248375,
      0.013193524442613125,
      -0.11175064742565155,
      -0.017598342150449753,
      -0.028793562203645706,
      0.029298488050699234,
      -0.03691809996962547,
      0.06548244506120682,
      0.03167244419455528,
      -0.028664162382483482,
      -0.013181560672819614,
      0.027584092691540718,
      -0.045160923153162,
      0.10823411494493484,
      0.014471434988081455,
      -0.0022004086058586836,
      0.004023609217256308,
      -0.045797619968652725,
      0.047663480043411255,
      0.007868972606956959,
      0.09452622383832932,
      -0.010135912336409092,
      0.08171463012695312,
      0.011083651334047318,
      -0.007409520447254181,
      -0.05676871910691261,
      -0.015532935038208961,
      0.0393134281039238,
      -0.04130470007658005,
      -0.005805229768157005,
      0.018605411052703857,
      0.025143230333924294,
      0.06375990808010101,
      0.06799988448619843,
      -0.04279359430074692,
      -0.037086572498083115,
      -0.08041683584451675,
      -0.04727783054113388,
      0.028887949883937836,
      0.022301513701677322,
      -0.002980809425935149,
      0.03304894641041756,
      -0.011769764125347137,
      -0.0013690455816686153,
      0.011278215795755386,
      -0.010799514129757881,
      0.009736785665154457,
      -0.003054823260754347,
      -0.05921078845858574,
      0.06651677936315536,
      0.03977513313293457,
      0.04345332458615303,
      -0.020034512504935265,
      -0.00011013960465788841,
      -0.03397899866104126,
      0.0528334304690361,
      0.015539837069809437,
      0.015594491735100746,
      0.006245555821806192,
      0.017236828804016113,
      0.027430271729826927,
      -0.044505275785923004,
      0.055184945464134216,
      -0.03223036974668503,
      -0.02421640418469906,
      -0.024215955287218094,
      -0.03222702071070671,
      0.0044648125767707825,
      -0.016179107129573822,
      -0.06528938561677933,
      -0.04470616951584816,
      0.046519678086042404,
      0.01761133410036564,
      -0.025897271931171417,
      0.060630977153778076,
      -0.010968131944537163,
      -0.0621345229446888,
      0.053627580404281616,
      0.05500905588269234,
      -0.027207592502236366,
      -0.02268185466527939,
      -0.023770518600940704,
      0.09728309512138367,
      0.005928334780037403,
      -0.006042622495442629,
      -0.01022492628544569,
      -0.004361680708825588,
      0.03328947722911835,
      0.030000997707247734,
      -0.035771049559116364,
      -0.01129565667361021,
      0.00022691737103741616,
      0.012186272069811821,
      -0.03565321862697601,
      -0.008144055493175983,
      0.05036608502268791,
      -0.015813833102583885,
      0.03363998606801033,
      0.05170736089348793,
      -0.13073480129241943,
      -0.02685883268713951,
      -0.06802099198102951,
      0.038889288902282715,
      -0.013007577508687973,
      0.008550518192350864,
      -0.07742659002542496,
      0.017474062740802765,
      0.05545278638601303,
      0.01327614113688469,
      0.006539208348840475,
      0.014772606082260609,
      0.004165284335613251,
      -0.014204884879291058,
      -0.04713916778564453,
      -0.004122469574213028,
      0.08432557433843613,
      -0.014744246378540993,
      -0.025778040289878845,
      0.03607076406478882,
      -0.08818453550338745,
      0.015954017639160156,
      0.0008494541980326176,
      0.061902862042188644,
      -0.0613226443529129,
      0.034508731216192245,
      0.0801791399717331,
      -0.03388911113142967,
      0.009617568925023079,
      -0.026132531464099884,
      0.027403755113482475,
      -0.02795425057411194,
      -0.0010820472380146384,
      -0.018823785707354546,
      -0.022775735706090927,
      -0.06096974387764931,
      -0.07920242846012115,
      -0.035087380558252335,
      0.0348363034427166,
      -0.04183751344680786,
      0.004305210895836353,
      0.12023775279521942,
      -0.00042524890159256756,
      0.01854504644870758,
      -0.009806527756154537,
      0.0034396611154079437,
      -0.006349062081426382,
      0.01598021760582924,
      0.051976364105939865,
      0.0556977204978466,
      0.0006819402915425599,
      0.023854056373238564,
      -0.07697402685880661,
      0.02459729090332985,
      -0.04619906470179558,
      0.001602787640877068,
      -0.0004817261069547385,
      0.03679570555686951,
      0.0077122789807617664,
      0.04750201478600502,
      0.029119471088051796,
      -0.03534553572535515,
      -0.05042080208659172,
      0.08971484750509262,
      0.025779729709029198
    ],
    [
      0.01625145599246025,
      0.05069996789097786,
      0.08273091912269592,
      -0.00742006953805685,
      0.043035537004470825,
      0.004583433270454407,
      0.05803724378347397,
      0.04048160836100578,
      -0.026136016473174095,
      0.03449410945177078,
      -0.028902314603328705,
      0.00364540982991457,
      -0.001781534869223833,
      -9.750544268172234e-05,
      -0.014016194269061089,
      0.007978752255439758,
      -0.015018843114376068,
      -0.06272243708372116,
      0.0035899821668863297,
      0.010431456379592419,
      0.05785875394940376,
      -0.008929679170250893,
      0.014303019270300865,
      0.05595354363322258,
      0.050630711019039154,
      0.03485233709216118,
      -0.01654929481446743,
      0.04448667913675308,
      0.06854502111673355,
      -0.0064725917764008045,
      0.04259208217263222,
      -0.0552917942404747,
      -0.06427169591188431,
      0.003612648928537965,
      0.07822997868061066,
      0.03326395899057388,
      -0.008536620065569878,
      -0.05766496807336807,
      -0.010428224690258503,
      0.03646207973361015,
      0.018293514847755432,
      0.008800927549600601,
      -0.08227870613336563,
      -0.0005130698555149138,
      -0.08311063796281815,
      0.09714751690626144,
      -0.007526040077209473,
      0.07194794714450836,
      0.0824301689863205,
      -0.006785759702324867,
      -0.038805313408374786,
      0.032348643988370895,
      -0.024544531479477882,
      -0.03542424738407135,
      -0.018480997532606125,
      -0.011049547232687473,
      -0.059592898935079575,
      -0.0834352970123291,
      -0.019964618608355522,
      -0.07774785906076431,
      0.011636916548013687,
      -0.09089162200689316,
      0.025982746854424477,
      0.000501345784869045,
      0.031460538506507874,
      -0.00485269958153367,
      0.025454038754105568,
      -0.047568876296281815,
      -0.14655491709709167,
      0.040145765990018845,
      -0.05538959801197052,
      0.11787333339452744,
      0.06719668209552765,
      0.0445939339697361,
      -0.03771727532148361,
      0.0026181931607425213,
      0.0012133462587371469,
      0.06316202133893967,
      -0.025473203510046005,
      0.003942390438169241,
      0.09225384891033173,
      0.1283310353755951,
      0.024362388998270035,
      -0.04814229533076286,
      -0.01641250029206276,
      0.013161938637495041,
      0.03148014843463898,
      0.028727544471621513,
      0.020834906026721,
      -0.03210190311074257,
      -0.023848317563533783,
      -0.024315422400832176,
      0.04262635111808777,
      0.06536625325679779,
      0.0020511357579380274,
      0.024733297526836395,
      0.023113947361707687,
      0.003301646327599883,
      0.1042119562625885,
      0.07863489538431168,
      0.05642766132950783,
      -0.056791502982378006,
      -0.02596946246922016,
      -0.06752683967351913,
      0.019855398684740067,
      -0.044811367988586426,
      0.010302184149622917,
      0.06830981373786926,
      0.044690873473882675,
      0.03958161175251007,
      0.036450356245040894,
      0.07172324508428574,
      -0.03935426101088524,
      -0.04148252308368683,
      0.026354661211371422,
      0.0019501163624227047,
      -0.04266544058918953,
      -0.019199293106794357,
      -0.017458319664001465,
      0.023737726733088493,
      -0.06456369906663895,
      0.06999203562736511,
      0.01890929974615574,
      -0.0044427290558815,
      -0.04467708617448807,
      -0.04792726784944534,
      -0.010139078833162785,
      0.02504630945622921,
      -0.010745705105364323,
      0.11376220732927322,
      0.026276253163814545,
      0.05091365426778793,
      -0.05347132310271263,
      0.05443170666694641,
      0.0231053177267313,
      0.08402803540229797,
      0.034877464175224304,
      0.04374952241778374,
      -0.0327787771821022,
      0.013506865128874779,
      -0.06571929901838303,
      0.002305870410054922,
      -0.02180412784218788,
      -0.05088833346962929,
      0.04885634034872055,
      -0.05856676399707794,
      0.0156092569231987,
      0.01726505719125271,
      0.03128162398934364,
      0.0022453265264630318,
      -0.014735588803887367,
      0.051695480942726135,
      -0.0780588835477829,
      0.009193338453769684,
      -0.07631327956914902,
      0.025485660880804062,
      -0.011010185815393925,
      0.014099636115133762,
      -0.029398808255791664,
      0.007038846146315336,
      0.043370321393013,
      -0.08431977778673172,
      0.057969022542238235,
      0.0012736712815240026,
      0.09328624606132507,
      0.0010836427100002766,
      -0.010604871436953545,
      0.017685269936919212,
      -0.006609787233173847,
      0.08776726573705673,
      0.07204088568687439,
      -0.002271094126626849,
      -0.039354123175144196,
      0.02367221936583519,
      -0.03459982946515083,
      -0.10229300707578659,
      0.08716750144958496,
      -0.04370071738958359,
      0.016857028007507324,
      -0.001252050045877695,
      -0.09599559009075165,
      -0.11023547500371933,
      -0.04763355851173401,
      0.00440097926184535,
      0.011624780483543873,
      -0.021266164258122444,
      0.0034514281433075666,
      -0.029747147113084793,
      -0.05258992314338684,
      -0.036188140511512756,
      -0.020439526066184044,
      0.01621232181787491,
      -0.014416014775633812,
      -0.029239119961857796,
      0.06429795920848846,
      0.037821777164936066,
      0.004073683172464371,
      0.0025380305014550686,
      0.02480868250131607,
      0.011471870355308056,
      0.06492310762405396,
      -0.0036207842640578747,
      -0.04894113540649414,
      0.0012097525177523494,
      0.01040983758866787,
      -0.004727108404040337,
      0.012972601689398289,
      0.022991839796304703,
      0.05078325420618057,
      0.04567401856184006,
      -0.05832082778215408,
      0.03716247156262398,
      -0.008217326365411282,
      -0.022176140919327736,
      -0.0058653699234128,
      0.06722622364759445,
      -0.11119309067726135,
      -0.0007587329600937665,
      0.0512571819126606,
      0.05500379949808121,
      -0.06432247906923294,
      0.0820799171924591,
      0.025576448068022728,
      0.02161315083503723,
      0.05556393042206764,
      -0.05808543041348457,
      0.10691987723112106,
      -0.03005857579410076,
      0.03918151929974556,
      -0.02554371766746044,
      0.06735043227672577,
      -0.019975675269961357,
      0.04299655184149742,
      -0.012447074055671692,
      0.04956521838903427,
      -0.02862718515098095,
      0.03927966207265854,
      0.05887816473841667,
      0.028955116868019104,
      0.0019010354299098253,
      -0.021392453461885452,
      -0.044995322823524475,
      -0.02742418274283409,
      -0.0030100601725280285,
      -0.007550188340246677,
      -0.021974315866827965,
      0.012979294173419476,
      0.03150559216737747,
      0.003118577878922224,
      0.08369602262973785,
      0.05905088409781456,
      0.04692770913243294,
      0.08623805642127991,
      -0.04280416667461395,
      0.042753223329782486,
      0.038706086575984955,
      -0.0323307029902935,
      0.03182145953178406,
      -0.003915315493941307,
      -0.014301754534244537,
      -0.08585461974143982,
      0.0762314647436142,
      0.029446642845869064,
      -0.010116723366081715,
      -0.021039346233010292,
      0.021379483863711357,
      0.026831256225705147,
      -0.02012871578335762,
      -0.0012694699689745903,
      -0.0388459786772728,
      -0.03212820738554001,
      0.03487333282828331,
      -0.03221631050109863,
      -0.0030810367316007614,
      0.020671101287007332,
      0.03024076670408249,
      0.06053510680794716,
      0.05785607546567917,
      -0.024380085989832878,
      -0.0893290564417839,
      0.035340357571840286,
      0.015692617744207382,
      -0.10040159523487091,
      -0.09336446970701218,
      0.11677887290716171,
      0.080867700278759,
      -0.07136280089616776,
      -0.07454994320869446,
      0.05950643867254257,
      -0.02587229385972023,
      0.06764088571071625,
      -0.01792311482131481,
      -0.05667397752404213,
      -0.0590062290430069,
      -0.005463660228997469,
      0.004884814843535423,
      0.026331419125199318,
      -0.05817713588476181,
      -0.008632445707917213,
      0.0071015735156834126,
      0.053051769733428955,
      0.022124050185084343,
      -0.047443415969610214,
      0.03598616272211075,
      0.0017202652525156736,
      -0.053905144333839417,
      0.022799590602517128,
      0.0612357072532177,
      0.06773209571838379,
      0.04077322408556938,
      -1.555674862174783e-05,
      -0.008910762146115303,
      -0.013695449568331242,
      -0.014758476056158543,
      -0.07093293964862823,
      -0.06741220504045486,
      -0.04130348190665245,
      -0.04239529371261597,
      -0.0026187647599726915,
      0.041876982897520065,
      -0.01727207563817501,
      0.056102558970451355,
      0.04339296743273735,
      0.008476929739117622,
      0.06651470810174942,
      -0.029898997396230698,
      -0.005603814963251352,
      -0.07099368423223495,
      -0.03038005903363228,
      -0.005571036133915186,
      -0.03863569721579552,
      -0.0017272616969421506,
      -0.06969514489173889,
      -0.017659801989793777,
      0.10352078825235367,
      -0.07127396017313004,
      0.08424996584653854,
      -0.12430277466773987,
      0.042481984943151474,
      0.021484462544322014,
      0.022733764722943306,
      -0.01576623134315014,
      0.024156836792826653,
      -0.01874731481075287,
      -0.11508087068796158,
      0.06444191187620163,
      0.027444161474704742,
      0.10417540371417999,
      0.05561468377709389,
      -0.010524003766477108,
      -0.020034506916999817,
      -0.07351598888635635,
      0.007741308771073818,
      -0.0002456477959640324,
      0.030896835029125214,
      0.04882800206542015,
      -0.014832893386483192,
      -0.012452850118279457,
      0.014159384183585644,
      0.019671538844704628,
      0.025478200986981392,
      0.06233477219939232,
      -0.05338522419333458,
      0.03559958562254906,
      0.04853682965040207,
      -0.02702338434755802,
      -0.014786715619266033,
      0.020705455914139748,
      0.09822242707014084,
      -0.021450607106089592,
      -0.02737973816692829,
      0.03218365088105202,
      0.055230796337127686,
      0.04038601368665695,
      -0.0010832305997610092,
      -0.07604431360960007,
      0.0031138102058321238,
      -0.02429078333079815,
      -0.03146665170788765,
      0.07993458956480026,
      -0.046414971351623535,
      -0.0021044116001576185,
      -0.04133107513189316,
      0.019320692867040634,
      0.05283765122294426,
      -0.042539145797491074,
      -0.008334100246429443,
      -0.07151656597852707,
      0.00217703846283257,
      0.05027393996715546,
      -0.07008042186498642,
      -0.0168113112449646,
      -0.06367930769920349,
      -0.0022436173167079687,
      -0.049205709248781204,
      0.02228081226348877,
      0.037712354212999344,
      -0.0068412781693041325,
      -0.006405585445463657,
      -0.10741327702999115,
      0.038230180740356445,
      -0.011209129355847836,
      -0.013538305647671223,
      -0.003824004903435707,
      0.027263520285487175,
      -0.09024802595376968,
      -0.012668939307332039,
      0.044460833072662354,
      -0.008750657550990582,
      -0.012524477206170559,
      -0.061057526618242264,
      0.042342476546764374,
      -0.014639382250607014,
      -0.03985552117228508,
      0.01435688603669405,
      -0.015676286071538925,
      -0.02480967342853546,
      0.048874106258153915,
      -0.018237436190247536,
      0.009721333160996437,
      -0.011811092495918274,
      -0.013656632043421268,
      0.012719690799713135,
      0.031307511031627655,
      0.006433150731027126,
      0.028652895241975784,
      0.02416800707578659,
      0.044128477573394775,
      0.005270647816359997,
      -0.08626623451709747,
      -0.007264911662787199,
      0.005939413793385029,
      0.05443182215094566,
      -0.010321397334337234,
      -0.062032993882894516,
      0.029545366764068604,
      -0.05235480144619942,
      0.0539376437664032,
      -0.05044900253415108,
      -0.020423561334609985,
      -0.0252864807844162,
      -0.022849656641483307,
      -0.07640398293733597,
      -0.07275384664535522,
      0.04616859182715416,
      0.046300169080495834,
      -0.029231060296297073,
      -0.03712921217083931,
      -0.0025283140130341053,
      0.06502991914749146,
      0.02734576351940632,
      0.10155817866325378,
      0.08061771839857101,
      0.041895996779203415,
      0.06613761931657791,
      0.08627729117870331,
      0.09871190041303635,
      0.042223330587148666,
      -0.06798998266458511,
      0.06669843941926956,
      0.07755453139543533,
      0.06505175679922104,
      -0.05070436745882034,
      -0.0014625850599259138,
      -0.04072774201631546,
      0.034792110323905945,
      -0.06912843137979507,
      -0.02653607726097107,
      -0.041356027126312256,
      0.08643126487731934,
      -0.047524578869342804,
      -0.01414158008992672,
      -0.03096259944140911,
      0.016204316169023514,
      0.057438574731349945,
      -0.04962098225951195,
      -0.0054055480286479,
      0.018537791445851326,
      -0.009883321821689606,
      -0.0657157301902771,
      0.03132593259215355,
      -0.047337379306554794,
      0.03378872945904732,
      0.02685428597033024,
      -0.09300752729177475,
      -0.0028442346956580877,
      0.0998978465795517,
      0.011816260404884815,
      -0.04382464289665222,
      0.01987389102578163,
      -0.04620768874883652,
      0.034798916429281235,
      -0.052480652928352356,
      0.008324780501425266,
      -0.025324514135718346,
      0.056915126740932465,
      -0.053146179765462875,
      0.07406627386808395,
      -0.07155150175094604,
      0.011767588555812836,
      -0.023540396243333817,
      0.022686179727315903,
      -0.015014583244919777
    ],
    [
      0.01278600562363863,
      0.0300788301974535,
      0.0419270358979702,
      0.026241162791848183,
      0.061489760875701904,
      -0.06598574668169022,
      0.012014887295663357,
      0.08910073339939117,
      -0.06326501816511154,
      0.024680113419890404,
      0.028808215633034706,
      0.09314598888158798,
      -0.023078816011548042,
      -0.013623245060443878,
      -0.030752815306186676,
      0.054216157644987106,
      0.0830957293510437,
      0.07481299340724945,
      0.08933223783969879,
      -0.09794530272483826,
      -0.0412895567715168,
      0.007678768131881952,
      0.06062746047973633,
      0.05746127665042877,
      -0.015302630141377449,
      -0.05559726059436798,
      0.08732437342405319,
      0.04573572799563408,
      0.027253787964582443,
      -0.0024508433416485786,
      0.044167596846818924,
      -0.05954228341579437,
      0.041869062930345535,
      -0.01976143941283226,
      0.010072818957269192,
      -0.07592488080263138,
      -0.029701869934797287,
      -0.021219488233327866,
      0.034505508840084076,
      -0.07918623089790344,
      -0.008823280222713947,
      0.05380699038505554,
      -0.04889373481273651,
      0.029238680377602577,
      -0.02790706418454647,
      0.037384551018476486,
      -0.028905218467116356,
      0.03817522153258324,
      -0.010019303299486637,
      0.014642507769167423,
      0.026661884039640427,
      0.10780145972967148,
      0.04279288649559021,
      -0.019720643758773804,
      -0.030734552070498466,
      -0.03803388401865959,
      0.055851180106401443,
      -0.027949722483754158,
      0.029426880180835724,
      0.04622587189078331,
      -0.007769409101456404,
      -0.013570006936788559,
      -0.022663647308945656,
      -0.03767240047454834,
      -0.018103988841176033,
      -0.023184433579444885,
      0.01443504448980093,
      -0.04050710052251816,
      -0.046520017087459564,
      0.03848407417535782,
      -0.01146719604730606,
      0.0486382432281971,
      -0.0695035383105278,
      0.009619689546525478,
      -0.05736938863992691,
      0.08343939483165741,
      -0.018238315358757973,
      -0.010488638654351234,
      0.08872059732675552,
      -0.023452656343579292,
      0.08518645167350769,
      0.028297340497374535,
      -0.002680371515452862,
      -0.015491892583668232,
      0.02539507858455181,
      -0.03738853707909584,
      0.030178993940353394,
      -0.04618341475725174,
      0.031388211995363235,
      0.07394663989543915,
      0.014593692496418953,
      -0.03372545540332794,
      -0.024950049817562103,
      0.01868557743728161,
      -0.010418654419481754,
      -0.0163489431142807,
      0.006303746718913317,
      -0.061602238565683365,
      0.017306605353951454,
      -0.05852238088846207,
      0.031035760417580605,
      -0.040066562592983246,
      -0.020943038165569305,
      -0.08649558573961258,
      -0.01955525577068329,
      0.026279620826244354,
      0.010089974850416183,
      0.03388025984168053,
      0.016035664826631546,
      0.02916046977043152,
      -0.06484344601631165,
      -0.030469825491309166,
      0.027740566059947014,
      -0.039454419165849686,
      0.014560164883732796,
      0.0036792713217437267,
      -0.024114379659295082,
      0.021973278373479843,
      -0.06047273799777031,
      0.052378155291080475,
      -0.060340359807014465,
      0.09126558154821396,
      -0.05119603872299194,
      -0.06832444667816162,
      0.039778534322977066,
      -0.10112716257572174,
      -0.07617402076721191,
      -0.006954860407859087,
      -0.06909380108118057,
      -0.01132530439645052,
      -0.06512770056724548,
      -0.0763176754117012,
      0.013421720825135708,
      -0.049579326063394547,
      0.09348206967115402,
      -0.013687082566320896,
      0.06997454166412354,
      -0.036526937037706375,
      0.027653655037283897,
      -0.030844908207654953,
      0.0052670808508992195,
      0.03406691923737526,
      0.053657859563827515,
      -0.00794969778507948,
      -0.00046925581409595907,
      -0.020441943779587746,
      0.0014284999342635274,
      0.025501200929284096,
      -0.04259929433465004,
      -0.03003714233636856,
      -0.0024697582703083754,
      0.05070546641945839,
      -0.018766801804304123,
      -0.05960778146982193,
      -0.01655418798327446,
      0.11938299238681793,
      0.0500374436378479,
      -0.0320422425866127,
      -0.0023730455432087183,
      -0.059472519904375076,
      0.051852598786354065,
      -0.0327097587287426,
      -0.030503302812576294,
      -0.004331419710069895,
      0.06193702667951584,
      -0.03791104629635811,
      -0.01659204624593258,
      0.040617551654577255,
      0.03715910017490387,
      0.058952298015356064,
      0.04861663281917572,
      0.0005613644025288522,
      0.03816275671124458,
      0.06824915111064911,
      -0.04545818641781807,
      0.039966508746147156,
      0.02231031097471714,
      0.04345577955245972,
      0.0032737967558205128,
      -0.031753621995449066,
      -0.07309010624885559,
      -0.05488293990492821,
      -0.03875233232975006,
      0.02651877887547016,
      0.053255725651979446,
      -0.04269973188638687,
      -0.02779909409582615,
      -0.029821857810020447,
      0.00633685477077961,
      -0.029869522899389267,
      -0.010032068938016891,
      0.0450313463807106,
      0.008839013986289501,
      0.0706053152680397,
      0.005099141038954258,
      -0.008870924822986126,
      0.018824221566319466,
      0.003041676012799144,
      -0.006411191541701555,
      -0.007644405122846365,
      0.02952725626528263,
      0.00944729894399643,
      0.02112186886370182,
      -0.010596048086881638,
      0.034638963639736176,
      0.015505821444094181,
      -0.05062466114759445,
      0.037487633526325226,
      -0.048891831189394,
      0.045371923595666885,
      -0.0008577356929890811,
      -0.04241276904940605,
      -0.022127926349639893,
      0.024348244071006775,
      -0.010321286506950855,
      0.04363133758306503,
      -0.06013587489724159,
      0.04564725235104561,
      -0.04412264749407768,
      0.0546012781560421,
      -0.012510711327195168,
      0.028235221281647682,
      0.07853089272975922,
      0.01766950823366642,
      -0.0035811702255159616,
      -0.0058821444399654865,
      -0.0061270794831216335,
      0.08803658187389374,
      -0.044769808650016785,
      0.025965582579374313,
      -0.02635776437819004,
      0.03448551893234253,
      -0.088352732360363,
      0.0028530312702059746,
      0.03954138606786728,
      0.034777235239744186,
      -0.007235344033688307,
      -0.01759173907339573,
      -0.05662883073091507,
      0.05275484919548035,
      0.04658248648047447,
      -0.08618472516536713,
      0.0026160981506109238,
      -0.00650088069960475,
      -0.07059766352176666,
      0.059570539742708206,
      -0.03196924924850464,
      0.03954097628593445,
      0.08585100620985031,
      0.03998633101582527,
      -0.061500184237957,
      0.028338879346847534,
      -0.05327583849430084,
      -0.003194016870111227,
      -0.048593919724226,
      0.01938629150390625,
      -0.08350446075201035,
      -0.004287224728614092,
      -0.083860382437706,
      0.002335452474653721,
      -0.010155212134122849,
      0.04992208257317543,
      -0.03443080931901932,
      -0.07823862880468369,
      -0.01025936845690012,
      0.000529996061231941,
      -0.03832646459341049,
      -0.06218050420284271,
      0.04396280646324158,
      -0.05752559006214142,
      -0.028107158839702606,
      -0.022823572158813477,
      0.10826330631971359,
      0.01726408302783966,
      0.005134976468980312,
      -0.013783427886664867,
      -0.08061553537845612,
      -0.011044583283364773,
      0.07035131752490997,
      0.0760982409119606,
      -0.005784614942967892,
      0.05314327031373978,
      -0.05356750264763832,
      0.010450055822730064,
      0.00517195463180542,
      0.02965272217988968,
      -0.0428103469312191,
      0.013628415763378143,
      -0.11337214708328247,
      -0.00594370486214757,
      -0.003662609262391925,
      -0.026969417929649353,
      0.03224395960569382,
      0.0873500406742096,
      0.06684447079896927,
      -0.0771450623869896,
      0.09479620307683945,
      0.05695326626300812,
      -0.046418845653533936,
      0.0203159898519516,
      0.05832350254058838,
      -0.04807138442993164,
      0.0008081260602921247,
      0.013611444272100925,
      0.04342794045805931,
      0.01982368342578411,
      0.060499485582113266,
      -0.05313236638903618,
      0.004342974163591862,
      -0.06576908379793167,
      -0.055858876556158066,
      0.05714764446020126,
      -0.01518506184220314,
      0.061096176505088806,
      0.02337442710995674,
      -0.036402229219675064,
      -0.02759646438062191,
      0.0003332460473757237,
      0.08972832560539246,
      -0.04077921807765961,
      0.05390992760658264,
      0.02240811660885811,
      0.05160670727491379,
      0.0006816291133873165,
      0.03335346654057503,
      -0.025661097839474678,
      0.008013943210244179,
      0.012107529677450657,
      -0.06319744884967804,
      0.022120986133813858,
      -0.0001483440719312057,
      -0.08405172824859619,
      -0.03890154883265495,
      0.04671759530901909,
      -0.04451564699411392,
      0.008382855914533138,
      0.06757106631994247,
      -0.017916522920131683,
      -0.06343624740839005,
      -0.10162129253149033,
      -0.08078867197036743,
      -0.04414532706141472,
      0.01707763783633709,
      -0.09033634513616562,
      -0.004881915170699358,
      -0.04336102306842804,
      -0.02295190654695034,
      -0.04456954821944237,
      0.041638072580099106,
      -0.024063996970653534,
      0.03374038636684418,
      0.037998490035533905,
      0.13731029629707336,
      0.048433005809783936,
      0.006327372044324875,
      -0.010840386152267456,
      0.047144170850515366,
      -0.07530248165130615,
      -0.03134942427277565,
      0.01505077350884676,
      -0.005705647170543671,
      0.008208083920180798,
      0.05682563781738281,
      -0.02688860148191452,
      0.044229503720998764,
      0.07220359891653061,
      -0.02524201199412346,
      0.020238718017935753,
      -0.06861778348684311,
      -0.01926690898835659,
      -0.0656292587518692,
      0.013750724494457245,
      0.0813242718577385,
      0.11238209903240204,
      -0.017834916710853577,
      -0.09025559574365616,
      -0.028675755485892296,
      0.010848624631762505,
      -0.01686399057507515,
      0.056362710893154144,
      0.006717654410749674,
      0.011664751917123795,
      0.03906342387199402,
      -0.08888692408800125,
      0.03889185190200806,
      0.012112751603126526,
      -0.08440155535936356,
      -0.03238144516944885,
      0.09824571758508682,
      -0.0017852517776191235,
      -0.03047780878841877,
      0.04136691242456436,
      0.031095139682292938,
      0.10146139562129974,
      -0.0021703278180211782,
      0.027592075988650322,
      -0.07058031111955643,
      -0.07810463756322861,
      0.10222452878952026,
      -0.09885940700769424,
      0.060179680585861206,
      -0.05844832956790924,
      -0.04180224612355232,
      -0.07376180589199066,
      0.04274469241499901,
      -0.06398109346628189,
      0.027859224006533623,
      -0.05807534605264664,
      -0.05735986307263374,
      -0.003045581514015794,
      0.04056047648191452,
      -0.03240656107664108,
      0.06387300789356232,
      -0.023884037509560585,
      -0.037449631839990616,
      0.05632342770695686,
      -0.06352083384990692,
      -0.004345053341239691,
      -0.009138107299804688,
      -0.012390520423650742,
      0.008632972836494446,
      -0.004840658977627754,
      -0.021149244159460068,
      0.05852020904421806,
      -0.01780209131538868,
      0.03368847817182541,
      -0.04868750646710396,
      0.015066520310938358,
      -0.025962499901652336,
      -0.08048934489488602,
      0.021418767049908638,
      -0.07123099267482758,
      -0.008298399858176708,
      0.0540059469640255,
      -0.011337636038661003,
      0.0575120635330677,
      -0.012411787174642086,
      0.05154409259557724,
      -0.00019118646741844714,
      -0.03782317787408829,
      0.0913901999592781,
      0.04259451851248741,
      0.0023615548852831125,
      -0.0502081960439682,
      0.008261483162641525,
      0.023018566891551018,
      0.03121277317404747,
      0.11630929261445999,
      -0.06598653644323349,
      0.10981125384569168,
      -0.07129274308681488,
      -0.06302738934755325,
      0.004121632315218449,
      0.0198839008808136,
      0.001880609430372715,
      -0.026804743334650993,
      0.06284098327159882,
      -0.030716370791196823,
      -0.03227417543530464,
      0.038204435259103775,
      0.030456898733973503,
      -0.03424181044101715,
      0.009060765616595745,
      0.11584378033876419,
      -0.021374769508838654,
      0.07528362423181534,
      -0.01170230284333229,
      0.034248270094394684,
      0.07139727473258972,
      0.035917866975069046,
      0.09532703459262848,
      -0.011365666054189205,
      0.01769518107175827,
      0.003558230120688677,
      -0.057315148413181305,
      0.0018023912562057376,
      0.02184690162539482,
      -0.11379668116569519,
      -0.001550833461806178,
      5.478636012412608e-05,
      -0.05171533674001694,
      -0.10478988289833069,
      0.02914002165198326,
      0.08620360493659973,
      -0.017160069197416306,
      -0.05489764362573624,
      0.03198510408401489,
      -0.029991690069437027,
      0.03950086608529091,
      -0.007614844013005495,
      -0.06257419288158417,
      -0.01692548766732216,
      -0.03877395763993263,
      -0.10260048508644104,
      -0.041106462478637695,
      -0.027758534997701645,
      -0.01739170402288437,
      -0.008004155941307545,
      -0.06938966363668442,
      -0.00678187794983387,
      -0.027059467509388924,
      -0.09825587272644043,
      0.02478073351085186
    ],
    [
      0.04271619766950607,
      -0.03423767909407616,
      -0.037119604647159576,
      -0.03778199851512909,
      -0.02546052262187004,
      -0.014360823668539524,
      0.07765237987041473,
      0.11524823307991028,
      0.02693099156022072,
      -0.11022982001304626,
      -0.00976706575602293,
      -0.0324738472700119,
      0.003460125532001257,
      0.022826561704277992,
      -0.01856616698205471,
      -0.010899431072175503,
      0.05305463820695877,
      0.049823928624391556,
      0.05329757556319237,
      0.004854632541537285,
      -0.021201232448220253,
      0.05996757745742798,
      0.09047257155179977,
      0.007377957925200462,
      0.05066272243857384,
      -0.05095753073692322,
      0.009014532901346684,
      0.021603163331747055,
      0.001765076071023941,
      -0.09438871592283249,
      0.0477774441242218,
      -0.03415615111589432,
      0.04416682943701744,
      -0.09036986529827118,
      0.014727486297488213,
      -0.00842844508588314,
      -0.0039736432954669,
      0.017676297575235367,
      0.06479844450950623,
      -0.05787397548556328,
      0.08608885854482651,
      0.0721636563539505,
      -0.014103363268077374,
      0.006111156661063433,
      -0.016631800681352615,
      0.0032479811925441027,
      -0.039864905178546906,
      -0.04290669411420822,
      -0.05788829177618027,
      0.012702764011919498,
      -0.031037822365760803,
      -0.018942318856716156,
      0.07218638807535172,
      -0.04355846717953682,
      0.017881205305457115,
      0.027522310614585876,
      -0.0004719720163848251,
      -0.03571731224656105,
      0.027391398325562477,
      0.030626876279711723,
      0.01681879162788391,
      -0.04537612572312355,
      -0.045688241720199585,
      -0.03417234867811203,
      -0.05919753760099411,
      0.004503450822085142,
      0.049926504492759705,
      0.04013189673423767,
      0.005100857932120562,
      0.01938125304877758,
      0.03738659620285034,
      -0.024605954065918922,
      -0.06739646196365356,
      0.05280530825257301,
      0.061529356986284256,
      0.028252873569726944,
      -0.030506817623972893,
      -0.030838048085570335,
      0.04249615967273712,
      0.0053868708200752735,
      -0.03396495059132576,
      0.032854512333869934,
      0.012967763468623161,
      0.007790234871208668,
      0.03653183579444885,
      -0.043995026499032974,
      -0.010506357997655869,
      -0.007303593214601278,
      -0.031216420233249664,
      -0.045938871800899506,
      0.0643177255988121,
      0.02784978412091732,
      0.042319610714912415,
      -0.02493472583591938,
      -0.08849470317363739,
      0.07246359437704086,
      0.024500375613570213,
      0.0433664508163929,
      0.033265721052885056,
      -0.03251064941287041,
      -0.02627113088965416,
      0.009531262330710888,
      -0.007189769763499498,
      0.034039206802845,
      -0.00546075776219368,
      0.044937897473573685,
      -0.06299681216478348,
      -0.02829737216234207,
      0.0064590247347950935,
      0.005281527526676655,
      -0.06250309944152832,
      -0.0016195932403206825,
      0.051746025681495667,
      0.03059454634785652,
      -0.02146962843835354,
      0.004854533821344376,
      0.01350377220660448,
      -0.04983124881982803,
      -0.022917568683624268,
      0.07982494682073593,
      -0.13119646906852722,
      -0.1047641858458519,
      -0.0408019982278347,
      -0.018252097070217133,
      -0.03914203122258186,
      -0.05838673934340477,
      -0.023881904780864716,
      0.0035278568975627422,
      -0.07215805351734161,
      -0.02142738550901413,
      0.03220309317111969,
      0.07225783914327621,
      -0.046470757573843,
      0.061069272458553314,
      0.07047378271818161,
      -0.02552413009107113,
      -0.04751376807689667,
      0.07796727120876312,
      0.006103814579546452,
      0.06287727504968643,
      0.048798125237226486,
      0.06735739856958389,
      -0.02216857299208641,
      -0.019735317677259445,
      -0.01714925281703472,
      0.013127489015460014,
      0.048661988228559494,
      0.033045221120119095,
      0.02542428858578205,
      -0.007128567434847355,
      -0.04595143347978592,
      0.009840423241257668,
      -0.04551197960972786,
      -0.046973809599876404,
      -0.041246917098760605,
      -0.0675392597913742,
      0.022640304639935493,
      0.0269814170897007,
      0.07252442836761475,
      -0.07266588509082794,
      0.032444823533296585,
      0.027260083705186844,
      -0.006105632055550814,
      -0.059844113886356354,
      -0.008095706813037395,
      -0.003026854246854782,
      0.011534829623997211,
      0.0333821102976799,
      0.015866611152887344,
      0.08060112595558167,
      0.03757500275969505,
      0.02489468641579151,
      -0.008272913284599781,
      0.08959876745939255,
      -0.020735275000333786,
      -0.046630095690488815,
      -0.09491721540689468,
      0.025464633479714394,
      0.00613416638225317,
      -0.07295817136764526,
      -0.03732243552803993,
      -0.027687782421708107,
      -0.09489543735980988,
      0.04591725394129753,
      -0.04637698456645012,
      -0.011161187663674355,
      -0.04141519218683243,
      -0.06219445914030075,
      0.009647587314248085,
      0.01668192632496357,
      -0.028646865859627724,
      0.059383876621723175,
      -0.10662896931171417,
      0.00561015959829092,
      -0.012430880218744278,
      -4.0827682823874056e-05,
      0.08643591403961182,
      0.057270411401987076,
      0.02490149810910225,
      0.03598887473344803,
      0.09459354728460312,
      -0.13399770855903625,
      0.0023751750122755766,
      0.09512688219547272,
      -0.009196796454489231,
      0.04966267943382263,
      -0.05080331489443779,
      -0.012215996161103249,
      -0.04100262001156807,
      -0.030999986454844475,
      0.08852887898683548,
      0.03969607129693031,
      -0.057824280112981796,
      -0.06236764043569565,
      0.04909875616431236,
      0.03931402415037155,
      -0.034433651715517044,
      -0.019216658547520638,
      -0.07049860805273056,
      -0.02203662134706974,
      0.001069700694642961,
      -0.034713588654994965,
      0.047221291810274124,
      0.010965338908135891,
      -0.06662651896476746,
      -0.04712343215942383,
      0.0042114281095564365,
      0.006251804064959288,
      -0.006610520649701357,
      0.11631684005260468,
      -0.009068835526704788,
      -0.02529544197022915,
      -0.024499865248799324,
      0.048037007451057434,
      0.037278663367033005,
      0.002991714747622609,
      0.034691475331783295,
      0.039353061467409134,
      -0.04674160107970238,
      0.07929171621799469,
      0.10538620501756668,
      0.016346344724297523,
      0.06614658236503601,
      -0.030741961672902107,
      0.048010651022195816,
      0.006282040849328041,
      -0.06669709086418152,
      0.05532637611031532,
      0.024723678827285767,
      0.00930000003427267,
      -0.007235479075461626,
      -0.00392893748357892,
      0.02585684135556221,
      -0.06548137217760086,
      -0.019258085638284683,
      0.0805785059928894,
      -0.04013194143772125,
      -0.05258111655712128,
      -0.061699338257312775,
      -0.07309454679489136,
      -0.009291469119489193,
      0.13204847276210785,
      -0.06947937607765198,
      0.02822505682706833,
      -0.04509507864713669,
      -0.05287829786539078,
      -0.06420069187879562,
      0.02298487350344658,
      -0.04155877232551575,
      -0.04626870155334473,
      0.04676087200641632,
      0.006895235273987055,
      -0.018693987280130386,
      0.006919311825186014,
      0.07385586202144623,
      0.15312229096889496,
      0.0028744302690029144,
      -0.012874508276581764,
      -0.024576205760240555,
      0.12696902453899384,
      0.06631453335285187,
      -0.048568952828645706,
      -0.045271389186382294,
      0.03702642768621445,
      -0.0516507551074028,
      -0.0017534333746880293,
      -0.004188922233879566,
      -0.021871913224458694,
      -0.06814876198768616,
      -0.07769817858934402,
      -0.06515221297740936,
      -0.00904993899166584,
      0.01579524204134941,
      -0.005697974469512701,
      -0.013471831567585468,
      -0.08715473860502243,
      0.05369843915104866,
      0.03326655924320221,
      0.050637852400541306,
      0.04319053888320923,
      0.015197306871414185,
      -0.04971568286418915,
      0.014071566052734852,
      0.027874410152435303,
      -0.09255734831094742,
      0.028804292902350426,
      0.03969204053282738,
      -0.04458088055253029,
      0.017838027328252792,
      -0.005213306751102209,
      -0.023170216009020805,
      0.06290160119533539,
      0.03221293166279793,
      -0.0414293073117733,
      -0.0232392568141222,
      0.040107376873493195,
      -0.025680294260382652,
      -0.07022911310195923,
      -0.06353098154067993,
      -0.04778437688946724,
      0.022490274161100388,
      0.020204808562994003,
      0.07076993584632874,
      -0.037539899349212646,
      -0.00498546427115798,
      -0.012691590003669262,
      0.028311315923929214,
      -0.008799909614026546,
      0.08136297762393951,
      -0.056475501507520676,
      0.08882453292608261,
      0.005570149980485439,
      0.031220171600580215,
      0.036322835832834244,
      0.027971241623163223,
      -0.03948711231350899,
      0.03195522725582123,
      -0.06009310111403465,
      -0.07386018335819244,
      -0.03243608772754669,
      0.022954154759645462,
      0.041021425276994705,
      -0.011883470229804516,
      -0.12476114928722382,
      -0.02549055963754654,
      -0.02773391082882881,
      -0.0038418541662395,
      -0.017633933573961258,
      0.03406548127532005,
      0.027500564232468605,
      -0.047443024814128876,
      -0.030824381858110428,
      -0.01263509877026081,
      0.05104970186948776,
      -0.08506102114915848,
      -0.000765714852605015,
      0.02594538778066635,
      0.01059005782008171,
      -0.0029493197798728943,
      0.02783360332250595,
      -0.040977843105793,
      -0.00813936349004507,
      0.03157327324151993,
      0.011813399381935596,
      0.01067565567791462,
      -0.03528129309415817,
      0.06151652708649635,
      0.01878879964351654,
      0.05209783837199211,
      0.0014104682486504316,
      -0.0005130383069626987,
      -0.013477114960551262,
      -0.059612877666950226,
      -0.003723487723618746,
      -0.004413900431245565,
      0.09327497333288193,
      0.008391838520765305,
      0.10449349135160446,
      -0.024663126096129417,
      -0.01939164474606514,
      -0.05351779982447624,
      0.008675700053572655,
      -0.03987346962094307,
      0.013716879300773144,
      -0.041666556149721146,
      -0.005792574491351843,
      -0.049950212240219116,
      -0.02733658254146576,
      -0.07037157565355301,
      -0.003987227566540241,
      -0.014185678213834763,
      -0.03236862272024155,
      0.031122982501983643,
      -0.03859022259712219,
      -0.015662062913179398,
      0.060606151819229126,
      0.013016924262046814,
      -0.0016180435195565224,
      0.011666138656437397,
      0.07881121337413788,
      0.012971799820661545,
      0.016387972980737686,
      -0.027803227305412292,
      0.020608704537153244,
      -0.008261523209512234,
      0.03361289203166962,
      0.034432459622621536,
      0.06533104926347733,
      -0.030531153082847595,
      -0.010552555322647095,
      0.12136004865169525,
      -0.06637876480817795,
      0.0740504190325737,
      -0.04561736434698105,
      0.06253372132778168,
      0.013861840590834618,
      -0.040304262191057205,
      -0.008742258884012699,
      0.02349044196307659,
      0.0677449181675911,
      -0.0464499369263649,
      -0.007558726239949465,
      0.04463658481836319,
      0.005572128109633923,
      -0.09325078874826431,
      -0.015150399878621101,
      -0.007185028865933418,
      -0.030591677874326706,
      -0.03723854199051857,
      -0.07691239565610886,
      0.030346883460879326,
      0.007967663928866386,
      0.011872845701873302,
      0.10156695544719696,
      -0.060391031205654144,
      -0.036199137568473816,
      -0.020457090809941292,
      -0.0199650377035141,
      -0.03642469272017479,
      0.04323338344693184,
      -0.026456372812390327,
      0.1123918890953064,
      -0.06987499445676804,
      0.049238450825214386,
      -0.005514562129974365,
      0.015847017988562584,
      -0.03869025409221649,
      0.10474952310323715,
      -0.032287124544382095,
      -0.06829439103603363,
      0.017066901549696922,
      -0.004493765067309141,
      -0.00235611735843122,
      -0.0035450549330562353,
      0.021692641079425812,
      0.02365751378238201,
      -0.030007047578692436,
      0.019814949482679367,
      -0.07302616536617279,
      -0.038031525909900665,
      -0.0019701814744621515,
      -0.027973273769021034,
      0.00041147629963234067,
      0.034810274839401245,
      -0.037893328815698624,
      -0.004301644861698151,
      0.04246039688587189,
      0.02128644287586212,
      -0.04092688113451004,
      -0.11485906690359116,
      0.07974453270435333,
      -0.028360845521092415,
      0.018575362861156464,
      -0.08480123430490494,
      -0.00803595595061779,
      0.04964747652411461,
      0.10829312354326248,
      -0.00036301519139669836,
      0.05370360240340233,
      0.08987469226121902,
      0.016092786565423012,
      -0.09520715475082397,
      0.022018874064087868,
      -0.01760374940931797,
      -0.0789562314748764,
      -0.00787358544766903,
      0.0025028043892234564,
      -0.044686779379844666,
      0.004225555807352066,
      -0.03201459348201752,
      0.05959448590874672,
      -0.08725195378065109,
      0.07168113440275192,
      0.07022582739591599,
      -0.0016879355534911156,
      -0.029623232781887054,
      -0.13435614109039307,
      0.002181267598643899,
      0.03464151546359062,
      -0.005547060631215572,
      0.04035508632659912,
      -0.010020965710282326,
      -0.012887380085885525
    ],
    [
      -0.05491139739751816,
      -0.04160849750041962,
      0.026524104177951813,
      -0.004522986244410276,
      0.04492034390568733,
      -0.008661918342113495,
      0.00511461216956377,
      -0.04254326596856117,
      0.022091615945100784,
      -0.006211746949702501,
      -0.05872134491801262,
      0.01826147548854351,
      0.05550248175859451,
      0.0029471341986209154,
      0.08225357532501221,
      0.014320770278573036,
      -0.012537319213151932,
      -0.010884399525821209,
      -0.028139056637883186,
      -0.0010585050331428647,
      0.07110502570867538,
      0.02385271154344082,
      0.017381327226758003,
      0.037560805678367615,
      0.09144419431686401,
      0.0121531430631876,
      -0.02881385013461113,
      0.037311382591724396,
      -0.041805051267147064,
      0.038748905062675476,
      0.03175938129425049,
      -0.0172308050096035,
      0.033756084740161896,
      -0.0009230268187820911,
      -0.03987012058496475,
      0.019985489547252655,
      -0.008789406158030033,
      0.03067185916006565,
      0.024376340210437775,
      -0.0029785516671836376,
      -0.0333373099565506,
      0.034564271569252014,
      0.009507590904831886,
      0.00123107573017478,
      -0.033943455666303635,
      -0.015478171408176422,
      0.07637789100408554,
      -0.01765216514468193,
      0.015491966158151627,
      -0.012426734901964664,
      -0.059610795229673386,
      0.06991817057132721,
      -0.017816253006458282,
      0.013911183923482895,
      -0.02469770424067974,
      -0.020418809726834297,
      -0.08879175037145615,
      0.01878724806010723,
      -0.058435115963220596,
      -0.09350458532571793,
      0.03742269426584244,
      -0.0695219337940216,
      0.013244599103927612,
      -0.061717282980680466,
      0.012082715518772602,
      0.029496043920516968,
      -0.02741890214383602,
      -0.08397016674280167,
      -0.012711599469184875,
      -0.04092330485582352,
      -0.010626129806041718,
      0.017556698992848396,
      -0.0428655743598938,
      -0.009698623791337013,
      0.02935292385518551,
      -0.025328390300273895,
      0.04993373155593872,
      0.021481197327375412,
      0.022950824350118637,
      0.00040682952385395765,
      0.02071347087621689,
      0.002607672708109021,
      0.00962562020868063,
      -0.015841348096728325,
      -0.03358330577611923,
      -0.042299121618270874,
      -0.04380073398351669,
      -0.10429656505584717,
      0.029023122042417526,
      0.0158704724162817,
      0.008470125496387482,
      -0.0002473096246831119,
      0.01009503472596407,
      -0.020105941221117973,
      -0.0845651775598526,
      -0.09310376644134521,
      -0.10989119112491608,
      -0.029528208076953888,
      -0.03841809183359146,
      0.027317458763718605,
      -0.0689525157213211,
      -0.10509078204631805,
      -0.06459015607833862,
      -0.030062036588788033,
      0.03142769634723663,
      0.014835696667432785,
      0.007812268100678921,
      0.026803653687238693,
      0.02448405511677265,
      0.0346795879304409,
      0.05714058503508568,
      -0.011671507731080055,
      0.033415231853723526,
      -0.0009872287046164274,
      -0.028027979657053947,
      -0.0449242927134037,
      0.023292630910873413,
      0.008039392530918121,
      -0.08875756710767746,
      -0.04387081786990166,
      -0.00542673422023654,
      -0.10122453421354294,
      -0.004713539034128189,
      -0.026308618485927582,
      -0.07508165389299393,
      -0.02638576738536358,
      0.00039254591683857143,
      -0.023487793281674385,
      0.04886702075600624,
      0.045667339116334915,
      0.04028072580695152,
      -0.05914359167218208,
      -0.010694403201341629,
      0.0901058092713356,
      0.018376193940639496,
      0.09151702374219894,
      0.008980237878859043,
      0.019281325861811638,
      0.018330495804548264,
      0.017549172043800354,
      0.0280759260058403,
      0.05465973913669586,
      -0.025112196803092957,
      0.0752292275428772,
      -0.05103515461087227,
      0.01919734477996826,
      -0.04584309831261635,
      -0.042787063866853714,
      -0.022630542516708374,
      -0.018973950296640396,
      0.002398964948952198,
      0.01985711045563221,
      0.06613681465387344,
      0.06732948124408722,
      -0.04243716597557068,
      -0.0028602087404578924,
      -0.00274058454670012,
      0.026589225977659225,
      0.0711856260895729,
      0.054744649678468704,
      0.03159299120306969,
      -0.04235178232192993,
      0.0010565007105469704,
      -0.09818612784147263,
      0.10141518712043762,
      -0.018010003492236137,
      -0.050215188413858414,
      -0.027156058698892593,
      0.0473373718559742,
      -0.0014088934985920787,
      0.014838982373476028,
      0.05118244141340256,
      -0.029754523187875748,
      -0.019170118495821953,
      0.013523097150027752,
      0.03672165051102638,
      -0.008860758505761623,
      -0.07905671000480652,
      -0.07364466786384583,
      -0.008657719008624554,
      -0.030828477814793587,
      -0.039601024240255356,
      0.01638835109770298,
      0.0345030277967453,
      -0.028109755367040634,
      0.0550478994846344,
      -0.031397584825754166,
      0.0098653519526124,
      -0.014048149809241295,
      -0.011085274629294872,
      -0.007699048146605492,
      -0.0730748400092125,
      -0.019852139055728912,
      0.04058973118662834,
      0.015520118176937103,
      -0.0012910997029393911,
      0.014332444407045841,
      -0.050815463066101074,
      -0.073603056371212,
      0.07352633029222488,
      -0.035429053008556366,
      -0.03423478826880455,
      0.053759727627038956,
      0.097386434674263,
      -0.03325305134057999,
      -0.039443377405405045,
      0.024432245641946793,
      0.03797803074121475,
      0.018138978630304337,
      -0.01635197177529335,
      0.0033830434549599886,
      0.06131353974342346,
      -0.03677356243133545,
      -0.08135270327329636,
      -0.02088993228971958,
      -0.010244890116155148,
      -0.042150795459747314,
      0.05546912923455238,
      0.05272122099995613,
      -0.05070018395781517,
      -0.08123405277729034,
      -0.0752076804637909,
      0.013127380050718784,
      0.006281211040914059,
      0.037458136677742004,
      0.07223571836948395,
      -0.07748794555664062,
      0.05486906319856644,
      -0.033807262778282166,
      0.04751739278435707,
      0.007897843606770039,
      0.017528219148516655,
      -0.03137904033064842,
      -0.03870408982038498,
      0.018726389855146408,
      -0.014591454528272152,
      -0.02852734923362732,
      -0.012486365623772144,
      -0.007886071689426899,
      0.026224656030535698,
      -0.052571360021829605,
      0.07017577439546585,
      -0.045642755925655365,
      -3.544494757079519e-05,
      -0.0541808046400547,
      -0.05765759199857712,
      -0.004053057637065649,
      -0.06532365083694458,
      -0.050289299339056015,
      -0.014772224240005016,
      -0.0006724030827172101,
      0.02156246453523636,
      -0.0040152971632778645,
      0.013737237080931664,
      0.0009378826944157481,
      0.08971668779850006,
      -0.05195736140012741,
      0.05393344908952713,
      -0.027821427211165428,
      -0.062203045934438705,
      0.004169863648712635,
      0.02434716932475567,
      0.02340852841734886,
      0.05926871299743652,
      -0.0633193776011467,
      0.007239168975502253,
      -0.1373024880886078,
      -0.07147076725959778,
      0.015864761546254158,
      -0.0466928631067276,
      -0.035419952124357224,
      -0.03833194822072983,
      0.04713989794254303,
      0.05657749995589256,
      0.02217833697795868,
      0.006025741342455149,
      0.08234597742557526,
      -0.023643361404538155,
      -0.06195785105228424,
      -0.09274671971797943,
      0.010184124112129211,
      0.05147763341665268,
      0.026624580845236778,
      0.016896869987249374,
      0.00802849791944027,
      0.03768279775977135,
      -0.016564084216952324,
      0.027768496423959732,
      -0.013880482874810696,
      -0.00786620657891035,
      0.00373621704056859,
      0.12078706175088882,
      0.018236849457025528,
      0.008203590288758278,
      0.047006767243146896,
      0.041059039533138275,
      0.023241275921463966,
      0.032951485365629196,
      0.03296928480267525,
      -0.02608426660299301,
      0.009372787550091743,
      -0.04301644489169121,
      -0.005530244670808315,
      0.09917546808719635,
      0.01627540960907936,
      0.016270186752080917,
      -0.00878826528787613,
      0.026843151077628136,
      -0.04047299548983574,
      -0.028340591117739677,
      -0.07804624736309052,
      -0.010347670875489712,
      -0.06548464298248291,
      -0.057107262313365936,
      0.055790334939956665,
      0.034965697675943375,
      -0.008159839548170567,
      0.022022223100066185,
      0.02867070771753788,
      -0.044637780636548996,
      0.08096823841333389,
      -0.016662221401929855,
      -0.0007271947106346488,
      -0.03378305584192276,
      -0.017155086621642113,
      -0.07153613120317459,
      -0.04220162704586983,
      0.007915756665170193,
      -0.06795166432857513,
      -0.021549463272094727,
      0.00043569208355620503,
      -0.014177546836435795,
      -0.028315847739577293,
      0.04660717025399208,
      -0.044007398188114166,
      0.01614690199494362,
      -0.005631221923977137,
      -0.021246129646897316,
      0.04702043533325195,
      0.014640429988503456,
      -0.010799732059240341,
      0.0832415446639061,
      -0.023559214547276497,
      0.00403475109487772,
      -0.05160634592175484,
      0.017825337126851082,
      0.08700471371412277,
      -0.018773464486002922,
      0.0557723306119442,
      -0.007322221994400024,
      -0.036327775567770004,
      0.05768882483243942,
      0.01097971759736538,
      0.04289311543107033,
      -0.020580848678946495,
      -0.005653698928654194,
      -0.04403744265437126,
      -0.043487805873155594,
      0.041537441313266754,
      -0.0015576690202578902,
      -0.08878252655267715,
      -0.08900051563978195,
      0.016695206984877586,
      -0.03584863245487213,
      -0.07902736216783524,
      0.09781727939844131,
      -0.026566939428448677,
      0.008546041324734688,
      0.07422993332147598,
      0.015126892365515232,
      -0.014205009676516056,
      0.02084801159799099,
      0.05372736603021622,
      -0.035792652517557144,
      0.009956306777894497,
      0.00852083507925272,
      0.010822897776961327,
      -0.010385646484792233,
      0.1076093539595604,
      -0.0659957230091095,
      0.07631486654281616,
      0.04269436001777649,
      -0.004186421167105436,
      -0.01899563893675804,
      0.02551611326634884,
      0.010626259259879589,
      0.019635280594229698,
      -0.018291886895895004,
      -0.010811759158968925,
      -0.02798307314515114,
      0.034569401293992996,
      -0.02409525029361248,
      -0.009973730891942978,
      -0.041435517370700836,
      -0.021226245909929276,
      -0.04659288749098778,
      -0.045521028339862823,
      0.02288827672600746,
      0.05392042174935341,
      0.009132036939263344,
      -0.03712225332856178,
      0.07889147102832794,
      0.026168962940573692,
      -0.049747250974178314,
      0.03169776126742363,
      0.0036510860081762075,
      -0.0029443285893648863,
      -0.013477947562932968,
      -0.0031442854087799788,
      -0.001001211698167026,
      0.04680806025862694,
      -0.06949397176504135,
      0.05362473800778389,
      -0.04485602676868439,
      -0.020643770694732666,
      -0.07097146660089493,
      -0.10079913586378098,
      -0.05912751331925392,
      -0.018055247142910957,
      -0.005378788337111473,
      0.019539711996912956,
      -0.07405745983123779,
      -0.042084697633981705,
      -0.057148125022649765,
      -0.026574967429041862,
      0.10262956470251083,
      -0.057497113943099976,
      0.015529172495007515,
      0.08133494853973389,
      -0.016766564920544624,
      0.003552923910319805,
      0.03804408386349678,
      -0.03409742936491966,
      0.005009689833968878,
      0.015126880258321762,
      -0.05073409155011177,
      -0.06101387366652489,
      0.045050207525491714,
      0.05646761134266853,
      0.011938493698835373,
      -0.029932789504528046,
      0.04982532933354378,
      0.03849279507994652,
      -0.017222054302692413,
      -0.018190855160355568,
      0.06307686120271683,
      -0.00013174235937185585,
      -0.001793753239326179,
      0.025793159380555153,
      -0.0830337405204773,
      -0.06339520215988159,
      0.11860758811235428,
      -0.00971585139632225,
      0.038561299443244934,
      -0.010466605424880981,
      0.011020127683877945,
      0.022130990400910378,
      -0.1003740057349205,
      -0.0018271961016580462,
      -0.07579268515110016,
      0.009574215859174728,
      -0.06618380546569824,
      -0.03192365542054176,
      0.059587690979242325,
      -0.05342280492186546,
      -0.05421452224254608,
      0.09084390848875046,
      0.020330529659986496,
      -0.007821387611329556,
      0.06586094945669174,
      -0.030550407245755196,
      -0.00019541045185178518,
      0.0379883237183094,
      0.08473383635282516,
      -0.11189007759094238,
      -0.021591974422335625,
      -0.034890804439783096,
      -0.028713641688227654,
      -0.03092338889837265,
      -0.02239443175494671,
      0.07128594815731049,
      -0.036021746695041656,
      0.005568643566220999,
      0.07798296958208084,
      -0.014151991344988346,
      0.03896414861083031,
      0.004394307266920805,
      -0.07322351634502411,
      0.028250157833099365,
      0.009159213863313198,
      -0.017164453864097595,
      0.07129079103469849,
      0.007927635684609413,
      -0.018509119749069214,
      -0.036108825355768204,
      0.009612777270376682,
      -0.009155741892755032,
      -0.018608763813972473,
      0.03895949572324753,
      -0.031413089483976364,
      -0.014607906341552734,
      0.014184492640197277,
      -0.036771707236766815
    ],
    [
      0.034945979714393616,
      0.029863281175494194,
      0.0659492239356041,
      0.030463771894574165,
      -0.03239266201853752,
      -0.08890369534492493,
      0.021764226257801056,
      -0.012893994338810444,
      0.00618623336777091,
      -0.015594656579196453,
      0.009417230263352394,
      -0.10670983046293259,
      0.017411336302757263,
      0.012243891134858131,
      -0.0608440525829792,
      0.043671898543834686,
      0.02560308203101158,
      -0.0056636701337993145,
      -0.023364774882793427,
      -0.03710613027215004,
      0.056526344269514084,
      -0.02712956629693508,
      -0.0005736325983889401,
      0.06201792135834694,
      -0.008272995240986347,
      -0.0749489963054657,
      0.03226775676012039,
      -0.006677396595478058,
      -0.05904233455657959,
      -0.01755264587700367,
      -0.05350807309150696,
      0.007673508487641811,
      -0.003677670145407319,
      0.10591977834701538,
      0.049647487699985504,
      0.008981170132756233,
      -0.06341489404439926,
      0.013593977317214012,
      -0.09719319641590118,
      -0.02574579417705536,
      0.04104536771774292,
      0.0180611964315176,
      -0.04755173251032829,
      0.02490226738154888,
      0.06036743149161339,
      -0.021240590140223503,
      -0.03168988972902298,
      0.07169432193040848,
      -0.04403046891093254,
      -0.00240917201153934,
      0.13243040442466736,
      -0.05109202116727829,
      0.07674945145845413,
      -0.032374244183301926,
      -0.057266369462013245,
      0.023748669773340225,
      0.004317241720855236,
      -0.06133062392473221,
      -0.014101441018283367,
      0.03419014811515808,
      -0.0347498394548893,
      0.04350779205560684,
      0.03977354243397713,
      -0.05756478011608124,
      -0.04383671656250954,
      -0.03813982009887695,
      0.03194950148463249,
      -0.06776377558708191,
      0.0712362676858902,
      -0.0178021639585495,
      0.03817390650510788,
      0.04420269653201103,
      -0.06912427395582199,
      0.051637202501297,
      0.046327099204063416,
      0.07378916442394257,
      -0.017615340650081635,
      0.06202980503439903,
      -0.011142733506858349,
      0.08531767874956131,
      -0.04120728000998497,
      0.0016405818751081824,
      0.01181357353925705,
      0.09548457711935043,
      0.01501356903463602,
      -0.0359501875936985,
      0.04275524616241455,
      -0.012339749373495579,
      -0.03227388113737106,
      -0.02078096568584442,
      0.010485135018825531,
      -0.029512370005249977,
      0.037200380116701126,
      0.007034718990325928,
      -0.06418010592460632,
      0.010055780410766602,
      0.05513427406549454,
      0.018293695524334908,
      -0.003061105264350772,
      0.10469000786542892,
      -0.03393714502453804,
      -0.004855433013290167,
      -0.0507223904132843,
      -0.02631687931716442,
      0.10498049110174179,
      0.03500273823738098,
      0.041686974465847015,
      0.018838388845324516,
      0.008931219577789307,
      0.03281555324792862,
      -0.022940734401345253,
      0.01412369403988123,
      0.02271709404885769,
      0.012103314511477947,
      0.07361138612031937,
      -0.01939273439347744,
      -0.0030984103213995695,
      -0.0048387302085757256,
      -0.08302510529756546,
      0.013247957453131676,
      -0.007399916648864746,
      -0.01883569546043873,
      -0.051135458052158356,
      0.030859267339110374,
      -0.06271862983703613,
      0.01206868328154087,
      0.008089513517916203,
      0.04285386949777603,
      0.07484050840139389,
      0.09654046595096588,
      0.021217627450823784,
      0.026504572480916977,
      -0.010643132030963898,
      0.050492119044065475,
      -0.03041490726172924,
      -0.03885193169116974,
      -0.009384021162986755,
      0.0009232453303411603,
      0.02484707348048687,
      0.0074189226143062115,
      -0.015718266367912292,
      -0.03567860275506973,
      0.0565284788608551,
      -0.003313429420813918,
      -0.08902624249458313,
      0.00677128043025732,
      -0.1045302152633667,
      -0.036333125084638596,
      0.00853560771793127,
      -5.851718015037477e-05,
      0.0809895396232605,
      0.003150483127683401,
      -0.003897183807566762,
      -0.012600132264196873,
      -0.0021217274479568005,
      0.05471228435635567,
      -0.027151459828019142,
      -0.026260152459144592,
      -0.021929878741502762,
      -0.002336230594664812,
      0.022791028022766113,
      -0.11530600488185883,
      0.00416199816390872,
      -0.08064529299736023,
      -0.029473718255758286,
      0.030295314267277718,
      -0.02083783969283104,
      -0.0305304117500782,
      0.07915733754634857,
      0.013920365832746029,
      -0.013437849469482899,
      -0.022821499034762383,
      -0.03463708981871605,
      0.04888409748673439,
      0.0023056757636368275,
      -0.07844941318035126,
      -0.001926071010529995,
      0.0027060890570282936,
      -0.052625712007284164,
      0.009026743471622467,
      -0.027585677802562714,
      -0.020498784258961678,
      -0.029095033183693886,
      0.04348975047469139,
      0.04028875753283501,
      0.048662032932043076,
      -0.038418833166360855,
      0.055132485926151276,
      0.004722416400909424,
      0.022683843970298767,
      0.008598612621426582,
      0.004106932785362005,
      0.026912663131952286,
      -0.08334910869598389,
      -0.06007087975740433,
      -0.02788066864013672,
      -0.023476656526327133,
      -0.0025986959226429462,
      -0.01931694522500038,
      -0.019376510754227638,
      0.062619149684906,
      0.047375742346048355,
      -0.024988677352666855,
      -0.015237506479024887,
      0.014827839098870754,
      -0.05853084847331047,
      -0.021241087466478348,
      0.0003052172251045704,
      -0.026888994500041008,
      -0.03597290441393852,
      -0.019697632640600204,
      0.032647185027599335,
      -0.046402279287576675,
      0.03269090875983238,
      -0.076973557472229,
      0.07910524308681488,
      0.011010149493813515,
      0.004749310668557882,
      -0.011881262995302677,
      0.017413271591067314,
      0.012127088382840157,
      0.020595090463757515,
      0.00408558314666152,
      0.0080593042075634,
      0.055975332856178284,
      -0.016228321939706802,
      -0.03010978363454342,
      -0.01729598268866539,
      0.12060464173555374,
      -0.1120377629995346,
      0.003239364828914404,
      0.03842809796333313,
      -0.020380206406116486,
      -0.02146446891129017,
      0.024364681914448738,
      0.04176236689090729,
      -0.029292646795511246,
      0.010867349803447723,
      0.06175007298588753,
      0.07345058768987656,
      0.048336561769247055,
      -0.03376786410808563,
      -0.0068159159272909164,
      -0.043860264122486115,
      -0.008608349598944187,
      -0.02431006357073784,
      -0.0030682184733450413,
      -0.06407889723777771,
      0.032088503241539,
      0.044337198138237,
      0.00663880817592144,
      0.009065106511116028,
      -0.016708582639694214,
      0.0668153166770935,
      -0.0015091609675437212,
      -0.007613905239850283,
      0.01545719150453806,
      0.05518317595124245,
      -0.022052306681871414,
      0.007908525876700878,
      0.02129070833325386,
      -0.020491164177656174,
      -0.15553896129131317,
      0.10694698989391327,
      0.019402416422963142,
      -0.0944330245256424,
      -0.03158046677708626,
      0.03836878761649132,
      0.011831195093691349,
      0.028368612751364708,
      0.006461121141910553,
      -0.033592723309993744,
      -0.02886326052248478,
      0.0034449328668415546,
      -0.04081299901008606,
      0.023386791348457336,
      -0.013320702128112316,
      -0.015849292278289795,
      -0.024545233696699142,
      -0.1165146455168724,
      -0.05368023365736008,
      -0.027241503819823265,
      0.001475373632274568,
      -0.023261278867721558,
      -0.01443207822740078,
      0.053673889487981796,
      0.020073454827070236,
      -0.016390619799494743,
      -0.02510511875152588,
      0.03749557584524155,
      -0.0028921691700816154,
      0.008921356871724129,
      0.03639369457960129,
      0.004646908026188612,
      -0.02884606085717678,
      -0.04846547916531563,
      -0.021083861589431763,
      0.02413378283381462,
      -0.03543635085225105,
      -0.03657486289739609,
      -0.03906245902180672,
      0.002499949187040329,
      -0.027245771139860153,
      0.015382446348667145,
      0.017781684175133705,
      0.0006162213394418359,
      0.07865352928638458,
      -0.015561123378574848,
      0.09046753495931625,
      -0.006470247637480497,
      -0.027096746489405632,
      0.01271500252187252,
      -0.014807608909904957,
      0.013720662333071232,
      -0.03165091946721077,
      0.008102131076157093,
      -0.021847842261195183,
      0.03413790836930275,
      0.03648480400443077,
      0.015222558751702309,
      0.06939315795898438,
      0.06861290335655212,
      0.05129162222146988,
      -0.0997118279337883,
      -0.002793603576719761,
      0.010091753676533699,
      0.037013497203588486,
      0.017191974446177483,
      -0.05477999150753021,
      -0.04654024913907051,
      -0.004680404905229807,
      -0.014328993856906891,
      -0.10918262600898743,
      -0.05953049659729004,
      0.02596350573003292,
      0.0368422232568264,
      0.047061361372470856,
      0.10440657287836075,
      -0.025235222652554512,
      -0.0787212997674942,
      0.06064881011843681,
      -0.039120908826589584,
      -0.06447041034698486,
      0.043657731264829636,
      0.005030304659157991,
      -0.0672980397939682,
      0.013361869379878044,
      -0.06966112554073334,
      -0.01077254768460989,
      -0.03136000782251358,
      0.017386334016919136,
      0.05615328624844551,
      0.061042893677949905,
      0.050215236842632294,
      0.013772636651992798,
      0.10918381810188293,
      -0.05068620294332504,
      0.01718849129974842,
      0.03677796572446823,
      -0.0281764417886734,
      -0.003881355980411172,
      -0.02557479590177536,
      0.09842315316200256,
      0.033143121749162674,
      0.00046101227053441107,
      -0.0018821164267137647,
      -0.016973869875073433,
      0.027124959975481033,
      -0.035641495138406754,
      0.009694049134850502,
      -0.02869817428290844,
      -0.025348693132400513,
      -0.07028181850910187,
      0.05706963315606117,
      0.038854192942380905,
      0.011360174044966698,
      -0.048486143350601196,
      -0.012937015853822231,
      -0.002364204963669181,
      0.031238581985235214,
      0.0791134387254715,
      0.047298967838287354,
      -0.024150989949703217,
      0.002684882143512368,
      -0.05925941839814186,
      -0.03419511020183563,
      0.05492724850773811,
      -0.03356865793466568,
      0.08543425053358078,
      0.023899652063846588,
      0.04439434036612511,
      -0.007761940360069275,
      0.029087519273161888,
      0.04471756890416145,
      0.04935704544186592,
      -0.022731252014636993,
      0.04328477010130882,
      -0.06721872091293335,
      -0.005156311206519604,
      -0.012641767971217632,
      -0.06661350280046463,
      0.07292015105485916,
      0.01944076642394066,
      0.05286102369427681,
      -0.047066301107406616,
      -0.14163552224636078,
      0.04591983184218407,
      0.023062484338879585,
      0.02511732280254364,
      -0.00010642567940521985,
      0.026011044159531593,
      -0.009326399303972721,
      -0.009118393063545227,
      0.00592093775048852,
      0.0020654054824262857,
      -0.05757187306880951,
      -0.003953772597014904,
      0.036245234310626984,
      -0.06482229381799698,
      -0.07990003377199173,
      0.06119920685887337,
      -0.012997866608202457,
      -0.009393319487571716,
      -0.017825597897171974,
      -0.0372893325984478,
      0.006952616851776838,
      -0.003979155328124762,
      0.006720378994941711,
      -0.04662417247891426,
      -0.060593970119953156,
      0.02266239933669567,
      0.05363171920180321,
      -0.07027675211429596,
      -0.04742395877838135,
      -0.06721094250679016,
      0.002750422339886427,
      -0.03686610981822014,
      0.09450260549783707,
      -0.0011490972246974707,
      0.001756040845066309,
      -0.011305676773190498,
      0.03392774984240532,
      0.07005082070827484,
      0.005836267955601215,
      0.014498966746032238,
      -0.03008108027279377,
      -0.02854568511247635,
      0.006034926977008581,
      -0.007047279737889767,
      0.006540071219205856,
      0.05169564485549927,
      -0.014212135225534439,
      0.07261168211698532,
      -0.011300665326416492,
      0.0007076089968904853,
      0.02390483394265175,
      0.06350993365049362,
      -0.04175542667508125,
      -0.04689742624759674,
      0.0013101311633363366,
      0.02650579810142517,
      0.025638563558459282,
      -0.028085574507713318,
      0.010163214057683945,
      -0.04196692258119583,
      0.03347038850188255,
      0.00336386333219707,
      0.0104543287307024,
      0.02206508256494999,
      0.05622011795639992,
      0.01266300305724144,
      0.021531779319047928,
      -0.023906152695417404,
      -0.07387877255678177,
      0.01631156913936138,
      -0.024337105453014374,
      -0.012547488324344158,
      0.027950450778007507,
      -0.04794967547059059,
      0.06921851634979248,
      0.001425646711140871,
      0.04466460645198822,
      -0.006944786757230759,
      0.11473188549280167,
      0.01017027162015438,
      0.016743920743465424,
      -0.011434230022132397,
      0.00368035607971251,
      0.06946513801813126,
      0.015675732865929604,
      -0.018921490758657455,
      0.0015838413964956999,
      -0.00493254279717803,
      -0.0008537050453014672,
      0.06924533098936081,
      0.004701927769929171,
      -0.01155052985996008,
      0.04112622141838074,
      0.051537808030843735,
      0.04918712377548218,
      0.10900603979825974,
      -0.007799618411809206,
      -0.016600212082266808
    ],
    [
      0.026375966146588326,
      -0.005084515083581209,
      0.023820506408810616,
      0.04115050286054611,
      0.056271955370903015,
      -0.06184712424874306,
      -0.03883791342377663,
      -0.03176337853074074,
      0.003320554504171014,
      -0.025414757430553436,
      -0.026903124526143074,
      -0.006005843635648489,
      0.07559448480606079,
      0.011577300727367401,
      0.06474599987268448,
      -0.057110562920570374,
      0.013876885175704956,
      0.019098462536931038,
      -0.03594658896327019,
      -0.04776902496814728,
      0.03345488756895065,
      0.051365118473768234,
      0.11249896883964539,
      -0.06488841772079468,
      0.039787568151950836,
      -0.053692638874053955,
      0.07066362351179123,
      0.01783190481364727,
      -0.12050851434469223,
      -0.06282703578472137,
      0.00939249899238348,
      0.011146938428282738,
      0.05890136957168579,
      0.040177006274461746,
      0.003636743174865842,
      0.00826207920908928,
      0.015552571043372154,
      -0.02888234332203865,
      -0.06945245712995529,
      -0.014104712754487991,
      0.03937039151787758,
      -0.013110197149217129,
      0.095717653632164,
      0.010255242697894573,
      -0.08176400512456894,
      -0.002951384987682104,
      0.007878457196056843,
      0.0167277492582798,
      -0.015272433869540691,
      -0.012668716721236706,
      -0.00937346089631319,
      -0.05145640671253204,
      0.004796776920557022,
      0.03029831312596798,
      0.05044736713171005,
      0.00670669786632061,
      -0.016627134755253792,
      0.01433585025370121,
      -0.004394096788018942,
      0.006637603044509888,
      -0.04223848879337311,
      0.04019417613744736,
      -0.0745277851819992,
      -0.03473823145031929,
      -0.009309581480920315,
      0.012595582753419876,
      0.01715216599404812,
      0.061303429305553436,
      -0.013160311616957188,
      0.05249156430363655,
      -0.030234117060899734,
      -0.035575784742832184,
      0.026815282180905342,
      -0.04327411204576492,
      -0.00996401347219944,
      -0.06800243258476257,
      0.011058780364692211,
      0.023056775331497192,
      -0.044307075440883636,
      0.06748751550912857,
      0.05239962786436081,
      -0.00010668392496882007,
      -0.053703829646110535,
      -0.011272055096924305,
      0.010695384815335274,
      -0.07179094851016998,
      -0.016399139538407326,
      -0.038385435938835144,
      0.02629552222788334,
      -0.029396982863545418,
      -0.04108934476971626,
      0.025238467380404472,
      0.06121685728430748,
      -0.012947671115398407,
      -0.011885510757565498,
      -0.04423265904188156,
      0.127412348985672,
      0.060290731489658356,
      0.053290121257305145,
      0.0673767626285553,
      -0.02532196044921875,
      -0.015705334022641182,
      0.005076449364423752,
      -0.013748527504503727,
      -0.019819816574454308,
      0.08604804426431656,
      -0.04297827184200287,
      0.04016163572669029,
      0.004144452977925539,
      -0.018454253673553467,
      -0.016564207151532173,
      -0.045275863260030746,
      -0.027045154944062233,
      -0.04366664960980415,
      -0.01727697066962719,
      -0.035937339067459106,
      0.07279209792613983,
      -0.026445426046848297,
      0.0366816371679306,
      -0.018863100558519363,
      -0.05803361535072327,
      0.014765620231628418,
      -0.023724790662527084,
      -0.017182767391204834,
      0.06040673330426216,
      0.05556924268603325,
      0.06539198011159897,
      -0.012228449806571007,
      0.017503295093774796,
      -0.0004926943802274764,
      0.00434210617095232,
      -0.02142331190407276,
      0.020563412457704544,
      -0.030254090204834938,
      0.02815137803554535,
      0.06705360859632492,
      -0.033354319632053375,
      0.0035487592685967684,
      -0.041538018733263016,
      -0.10159143805503845,
      -0.01580788940191269,
      -0.00583133939653635,
      -0.04541140794754028,
      -0.049367956817150116,
      0.0490490160882473,
      0.026911819353699684,
      0.023142045363783836,
      0.04031680151820183,
      -0.09170444309711456,
      0.003151643555611372,
      -0.0035588680766522884,
      -0.03676607459783554,
      0.03189008682966232,
      -0.007526511326432228,
      0.06253911554813385,
      0.08678233623504639,
      -0.010303722694516182,
      -0.008097931742668152,
      0.03693651035428047,
      -0.07197842001914978,
      -0.010676191188395023,
      0.01284851785749197,
      0.031109539791941643,
      -0.005475855432450771,
      0.016809305176138878,
      0.019623128697276115,
      -0.06192707642912865,
      0.04715156927704811,
      -0.020625434815883636,
      0.0854414626955986,
      -0.040573254227638245,
      0.0115815419703722,
      -0.014003094285726547,
      0.10262919962406158,
      0.040346499532461166,
      0.06851085275411606,
      -0.03920416161417961,
      0.04205884411931038,
      -0.004612262826412916,
      -0.006654311437159777,
      0.017263857647776604,
      0.06446792185306549,
      -0.06506330519914627,
      -0.0692962184548378,
      0.0537174716591835,
      -0.0529482327401638,
      -0.06853505223989487,
      0.04865774139761925,
      -0.0027067535556852818,
      -0.06559840589761734,
      -0.02425539307296276,
      -0.029040152207016945,
      0.03189937025308609,
      -0.002444472862407565,
      0.0029861635994166136,
      -0.004620130639523268,
      0.035796746611595154,
      0.012272619642317295,
      0.021322118118405342,
      -0.03558729588985443,
      -0.08520624041557312,
      0.041700080037117004,
      0.09654434025287628,
      0.023436563089489937,
      -0.033950865268707275,
      0.07743024080991745,
      0.025865154340863228,
      -0.026446295902132988,
      -0.017396308481693268,
      0.01074287947267294,
      0.012855292297899723,
      -0.002929826732724905,
      -0.007488889619708061,
      0.016795244067907333,
      0.011574042029678822,
      -0.055286705493927,
      -0.06629355251789093,
      -0.009696505032479763,
      0.031002754345536232,
      -0.03182559832930565,
      0.012501650489866734,
      -0.0028199402149766684,
      0.023545021191239357,
      0.04041057825088501,
      0.02465241216123104,
      -0.01718772202730179,
      -0.006786811165511608,
      -0.03770323097705841,
      -0.006565385032445192,
      0.03802620992064476,
      0.040003322064876556,
      -0.04815547913312912,
      0.014159488491714,
      0.0385429672896862,
      0.0013782590394839644,
      -0.04915261268615723,
      0.06858822703361511,
      0.016286829486489296,
      0.04578744247555733,
      0.011112090200185776,
      0.0057556177489459515,
      -0.008416795171797276,
      -0.009406860917806625,
      0.03887017443776131,
      0.030686622485518456,
      0.011640314944088459,
      -0.0028820058796554804,
      0.0460214763879776,
      0.04546072706580162,
      -0.03523212671279907,
      -0.03638365864753723,
      0.02536751702427864,
      0.004637682344764471,
      -0.014474410563707352,
      0.02205582521855831,
      0.020360194146633148,
      -0.04304391145706177,
      0.07516343146562576,
      0.04723940044641495,
      -0.043080177158117294,
      -0.04061220958828926,
      -0.002908305963501334,
      0.00044447314576245844,
      -0.042968325316905975,
      -0.026930848136544228,
      -0.01623375527560711,
      0.03259840980172157,
      -0.029343854635953903,
      0.023094542324543,
      -0.031868379563093185,
      0.06853139400482178,
      -0.06852748245000839,
      0.004858082626014948,
      -0.058774836361408234,
      0.03425353765487671,
      -0.005318196024745703,
      -0.010552022606134415,
      -0.018847279250621796,
      -0.04766499251127243,
      -0.0029921233654022217,
      0.020973915234208107,
      -0.05763619393110275,
      -0.022361157462000847,
      -0.014541533775627613,
      -0.07010838389396667,
      0.026898661628365517,
      -0.015713585540652275,
      -0.054865818470716476,
      -0.001223029219545424,
      0.03931635990738869,
      -0.0027905546594411135,
      0.00536578381434083,
      0.03950824588537216,
      -0.01524887140840292,
      0.010796764865517616,
      -0.02536286786198616,
      -0.0320601761341095,
      -0.005453845486044884,
      -0.03311457112431526,
      0.04075716435909271,
      -0.021694565191864967,
      0.012714357115328312,
      0.0249435156583786,
      -0.09742425382137299,
      0.0807109922170639,
      -0.08207692950963974,
      0.023467207327485085,
      0.0299247894436121,
      -0.013902087695896626,
      0.03773476555943489,
      -0.033195365220308304,
      0.023636605590581894,
      -0.015294238924980164,
      -0.0031905327923595905,
      0.012003064155578613,
      0.04058319330215454,
      0.01043130923062563,
      0.08691377192735672,
      -0.005127887241542339,
      -0.03189655393362045,
      -0.06709064543247223,
      0.029164789244532585,
      0.05401220917701721,
      0.07489458471536636,
      -0.039758361876010895,
      -0.04682674631476402,
      0.0003413654922042042,
      -0.006298810709267855,
      -0.015807705000042915,
      -0.02176119200885296,
      -0.020970337092876434,
      -0.009164617396891117,
      -0.027195552363991737,
      -0.09615353494882584,
      0.02903137542307377,
      -0.005229559261351824,
      0.017311932519078255,
      0.031656548380851746,
      -0.012732327915728092,
      -0.036997824907302856,
      0.03833242133259773,
      0.05449315533041954,
      -0.10146524757146835,
      -0.03623524308204651,
      0.015298934653401375,
      0.021845653653144836,
      0.04259633645415306,
      0.016391582787036896,
      -0.011331952176988125,
      0.015411531552672386,
      0.029776418581604958,
      -0.013041959144175053,
      0.003175732446834445,
      0.03174397349357605,
      -0.05053899809718132,
      -0.02679535560309887,
      0.0205047819763422,
      0.04849445819854736,
      0.09073152393102646,
      0.012525065802037716,
      -0.05614752322435379,
      -0.06892906874418259,
      0.04219149425625801,
      -0.0024976145941764116,
      0.07182295620441437,
      0.015003778971731663,
      -0.03741736710071564,
      0.01979452185332775,
      -0.040315572172403336,
      -0.05934929847717285,
      0.015900572761893272,
      0.03718564659357071,
      -0.024767475202679634,
      0.01090563740581274,
      -0.06703615188598633,
      -0.058275364339351654,
      0.021246710792183876,
      0.08337680250406265,
      -0.04297954961657524,
      -0.05415676161646843,
      0.05671726539731026,
      0.03013535402715206,
      0.08707596361637115,
      -0.03234999626874924,
      -0.03324420750141144,
      -0.039978135377168655,
      -0.014223229140043259,
      0.018331222236156464,
      -0.030847061425447464,
      -0.0007034930749796331,
      -0.008783801458775997,
      -0.0739373192191124,
      0.01914481818675995,
      0.05006284639239311,
      -0.03299904987215996,
      0.08672010153532028,
      -0.0584351047873497,
      0.03142555430531502,
      -0.006167717278003693,
      -0.038130585104227066,
      -0.006997270975261927,
      -0.02941734902560711,
      -0.014537397772073746,
      0.03540236875414848,
      0.018005013465881348,
      0.0132598876953125,
      0.05429937690496445,
      -0.020464111119508743,
      -0.12111714482307434,
      -0.038376159965991974,
      0.007597502786666155,
      -0.07698745280504227,
      0.04030687361955643,
      0.026362888514995575,
      0.05203799530863762,
      0.004908245522528887,
      -0.04916193336248398,
      0.0027562975883483887,
      -0.01963016763329506,
      0.026072213426232338,
      0.025129016488790512,
      0.044810399413108826,
      -0.013214158825576305,
      -0.018020469695329666,
      -0.04669865965843201,
      0.057292740792036057,
      -0.04270246624946594,
      0.016580861061811447,
      0.0016145282424986362,
      0.03693539649248123,
      0.047468628734350204,
      0.028436822816729546,
      0.02681216225028038,
      0.010308344848453999,
      0.045030154287815094,
      -0.03192741051316261,
      -0.033047907054424286,
      0.018064657226204872,
      0.03791411966085434,
      -0.08459030836820602,
      -0.056592557579278946,
      0.03982825577259064,
      -0.06298942118883133,
      0.029705431312322617,
      0.034951213747262955,
      -0.01954146847128868,
      -0.029503559693694115,
      -0.008925825357437134,
      -0.006722091697156429,
      -0.04660298302769661,
      -0.0360703207552433,
      0.019608257338404655,
      -0.006113976240158081,
      -0.0427134707570076,
      -0.011019802652299404,
      -0.03663894534111023,
      -0.038280025124549866,
      -0.02674112655222416,
      -0.016270583495497704,
      -0.018101338297128677,
      0.06029278039932251,
      -0.018279029056429863,
      -0.03489144518971443,
      -0.00871727429330349,
      -0.03204537555575371,
      0.02385755069553852,
      0.0208527110517025,
      -0.0679352730512619,
      -0.00538147846236825,
      0.06728407740592957,
      0.04390500485897064,
      -0.022884361445903778,
      0.04952187463641167,
      -0.07643811404705048,
      0.014270532876253128,
      -0.012142797000706196,
      -0.041643526405096054,
      0.02592531591653824,
      -0.06742933392524719,
      -0.014445807784795761,
      0.0009477377170696855,
      -0.004400226753205061,
      -0.027416367083787918,
      0.05552097037434578,
      0.07832688093185425,
      0.09330906718969345,
      -0.014943878166377544,
      0.009634672664105892,
      -0.05520619824528694,
      0.029451357200741768,
      -0.04160279035568237,
      -0.06061486527323723,
      0.014596618711948395,
      0.018618682399392128,
      -0.03468857705593109,
      0.05685524642467499,
      -0.08079738169908524,
      -0.032532140612602234,
      0.009502725675702095,
      0.006985812447965145,
      -0.054455600678920746,
      0.03507990017533302,
      -0.003294115187600255
    ],
    [
      0.00601498456671834,
      -0.04449649900197983,
      0.06766191124916077,
      0.027105700224637985,
      0.018946075811982155,
      -0.0004978149081580341,
      0.08125974237918854,
      -0.026683315634727478,
      0.0090870950371027,
      -0.016046103090047836,
      -0.04775126650929451,
      -0.02558179385960102,
      -0.009328754618763924,
      -0.03627333417534828,
      0.03657099977135658,
      -0.04411228373646736,
      0.032626908272504807,
      0.03712700679898262,
      0.06594774127006531,
      -0.049586184322834015,
      0.04318222030997276,
      0.087588831782341,
      -0.01046558003872633,
      -0.05538216605782509,
      0.009485077112913132,
      -0.05659598112106323,
      0.04530937969684601,
      0.0031864193733781576,
      -0.009363839402794838,
      0.03279891610145569,
      0.005206892732530832,
      -0.04736071079969406,
      -0.028728783130645752,
      -0.009420297108590603,
      -0.0115432757884264,
      0.05002095550298691,
      -0.07026920467615128,
      0.02077135443687439,
      0.0475899800658226,
      0.03858073800802231,
      -0.020793400704860687,
      -0.043920859694480896,
      0.014118210412561893,
      0.022009089589118958,
      0.011921723373234272,
      0.04763501510024071,
      -0.12578967213630676,
      0.0026876055635511875,
      0.026509743183851242,
      -0.012804055586457253,
      -0.040526099503040314,
      -0.04614795744419098,
      -0.008268721401691437,
      -0.009725043550133705,
      0.03421391174197197,
      -0.1076284795999527,
      -0.014928334392607212,
      0.025902053341269493,
      0.04723755642771721,
      -0.02446920983493328,
      0.022388756275177002,
      -0.021531395614147186,
      -0.07776854932308197,
      -0.07195253670215607,
      -0.10692169517278671,
      -0.007957693189382553,
      -0.0008721083286218345,
      -0.01628183387219906,
      -0.008976058103144169,
      0.09214407950639725,
      -0.009090646170079708,
      -0.006424052640795708,
      0.010557596571743488,
      0.060987528413534164,
      -0.019740626215934753,
      0.01934584230184555,
      0.03729797154664993,
      0.06745417416095734,
      0.006881470791995525,
      0.0329279899597168,
      0.049398522824048996,
      -0.005379802081733942,
      -0.0008828550926409662,
      0.05056591331958771,
      0.005744729191064835,
      0.017373783513903618,
      -0.002713930094614625,
      0.0460064597427845,
      -0.04919296130537987,
      -0.010390409268438816,
      0.008065823465585709,
      0.0279837716370821,
      -0.0051858206279575825,
      0.0619201585650444,
      -0.0030899031553417444,
      -0.0009494937839917839,
      -0.06235885247588158,
      0.0017899973317980766,
      -0.027463993057608604,
      0.01588120497763157,
      -0.05149742215871811,
      0.017636902630329132,
      0.00625052722170949,
      0.052630338817834854,
      -0.0055542923510074615,
      -0.053963419049978256,
      0.02046680450439453,
      0.03426312282681465,
      -0.0873042419552803,
      -0.005067441146820784,
      0.02295367792248726,
      0.006949175149202347,
      -0.022150954231619835,
      0.06262877583503723,
      0.01411081850528717,
      0.01753665693104267,
      -0.002104002283886075,
      0.029743095859885216,
      -0.006350718904286623,
      0.09156341850757599,
      -0.04948925971984863,
      0.05324897542595863,
      -0.05786305293440819,
      -0.03294889256358147,
      0.021099533885717392,
      0.014304897747933865,
      0.0001198281897814013,
      -0.09922542423009872,
      0.020972073078155518,
      -0.05300167575478554,
      -0.12520767748355865,
      0.05628432705998421,
      -0.07352548092603683,
      -0.06016377732157707,
      0.02028750441968441,
      -0.025406934320926666,
      -0.029635151848196983,
      -0.041877176612615585,
      0.03473089262843132,
      0.007731746882200241,
      -0.031176555901765823,
      0.0026342927012592554,
      0.06994935870170593,
      -0.0025190291926264763,
      0.06137190759181976,
      0.08735641837120056,
      -0.08092819899320602,
      -0.03047233447432518,
      0.0479750856757164,
      -0.06893313676118851,
      0.034476228058338165,
      -0.05596250295639038,
      0.05061476677656174,
      0.022975735366344452,
      0.025328071787953377,
      0.010727657936513424,
      -0.04986628517508507,
      0.03665866702795029,
      0.004469227511435747,
      -0.13289180397987366,
      0.009678076021373272,
      -0.02569594420492649,
      -0.08103147894144058,
      0.03945004194974899,
      -0.0216596070677042,
      -0.06616595387458801,
      -0.023966921493411064,
      -0.05958002433180809,
      -0.05260438472032547,
      -0.03842190280556679,
      0.0006708081346005201,
      -0.059298932552337646,
      -0.021626196801662445,
      0.07022637873888016,
      -0.06978417187929153,
      -0.09445203095674515,
      0.033471908420324326,
      0.02996581792831421,
      0.03067932464182377,
      -0.1106795072555542,
      -0.013816757127642632,
      0.01767401024699211,
      -0.00529016088694334,
      -0.02426721714437008,
      0.017352761700749397,
      0.009279665537178516,
      -0.04674337059259415,
      0.06153363734483719,
      0.020975399762392044,
      -0.04420391470193863,
      0.10368921607732773,
      -0.19606104493141174,
      0.023420970886945724,
      -0.13377293944358826,
      0.06523214280605316,
      0.006113772746175528,
      0.02131430245935917,
      0.0030827834270894527,
      0.020341603085398674,
      0.052395451813936234,
      0.0029082365799695253,
      0.08608093857765198,
      -0.06610095500946045,
      -0.0349949412047863,
      0.002416543662548065,
      -0.05296554043889046,
      0.07641921937465668,
      0.009356909431517124,
      0.044597432017326355,
      -0.020550314337015152,
      -0.017440281808376312,
      -0.0066973380744457245,
      -0.020397571846842766,
      -0.06702331453561783,
      0.012882624752819538,
      -0.0014090165495872498,
      -0.049743834882974625,
      -0.032858964055776596,
      -0.008849580772221088,
      0.055649515241384506,
      0.013567019253969193,
      -0.012661531567573547,
      0.06031157076358795,
      -0.005449076183140278,
      0.05673225224018097,
      0.05193406343460083,
      -0.010200165212154388,
      0.0010197688825428486,
      0.00892615970224142,
      -0.014913138002157211,
      -0.057893626391887665,
      -0.02496238239109516,
      0.01985790766775608,
      0.00012526713544502854,
      0.05493343994021416,
      0.04943470656871796,
      0.026203477755188942,
      -0.02519262582063675,
      -0.013741042464971542,
      0.0306696817278862,
      0.02431609109044075,
      -0.04745563492178917,
      0.05707769840955734,
      -0.028548019006848335,
      -0.04168727993965149,
      0.05079973489046097,
      -0.052190810441970825,
      -0.021669233217835426,
      0.05951199680566788,
      -0.028046121820807457,
      -0.03259146958589554,
      0.06705261766910553,
      -0.007262077648192644,
      -0.034251950681209564,
      0.026512136682868004,
      -0.08617100119590759,
      0.06387222558259964,
      -0.06810563802719116,
      0.09710607677698135,
      -0.020655017346143723,
      -0.0055747488513588905,
      -0.04599437490105629,
      0.054841380566358566,
      0.033902768045663834,
      -0.035540107637643814,
      -0.0015942573081701994,
      0.032809093594551086,
      0.03091905638575554,
      -0.0494513101875782,
      0.03255699947476387,
      0.07064312696456909,
      -0.05119640380144119,
      0.05523688346147537,
      0.0037619825452566147,
      -0.012475566938519478,
      -0.036550771445035934,
      -0.06898264586925507,
      -0.009479782544076443,
      0.001365036703646183,
      0.04899083077907562,
      0.04446394741535187,
      0.037845440208911896,
      -0.004558336455374956,
      0.01753166876733303,
      -0.048739414662122726,
      -0.018178647384047508,
      -0.012546295300126076,
      -0.008105739951133728,
      0.03287763148546219,
      0.006402303930372,
      -0.07500553876161575,
      -0.07813677936792374,
      -0.05773648992180824,
      0.012287892401218414,
      0.018283365294337273,
      0.024147065356373787,
      0.013836394995450974,
      -0.04168228060007095,
      0.03964856266975403,
      -0.003392911283299327,
      -0.01609821803867817,
      0.0793907642364502,
      0.03170834109187126,
      0.041583724319934845,
      -0.007632342167198658,
      0.03518427163362503,
      0.004417231306433678,
      0.039867520332336426,
      -0.015863824635744095,
      0.11511120200157166,
      0.0788518562912941,
      -0.07048585265874863,
      0.027715571224689484,
      -0.035651177167892456,
      -0.038278933614492416,
      0.048390649259090424,
      0.009075299836695194,
      -0.0009102259064093232,
      -0.033739399164915085,
      -0.010648652911186218,
      0.028984347358345985,
      0.027840012684464455,
      -0.023296648636460304,
      0.0074423388577997684,
      0.05887378752231598,
      -0.0035772989504039288,
      0.042480986565351486,
      -0.10231578350067139,
      0.017105063423514366,
      0.02866240218281746,
      -0.022773755714297295,
      0.06115114688873291,
      -0.026404667645692825,
      -0.05218253284692764,
      0.0320289246737957,
      -0.06554969400167465,
      0.04807543009519577,
      0.008130098693072796,
      -0.08220230042934418,
      0.012058625929057598,
      0.04693238064646721,
      -0.017777718603610992,
      -0.0743412896990776,
      0.03278512880206108,
      0.03207531198859215,
      0.009264669381082058,
      0.05672687292098999,
      0.05826724320650101,
      0.08460953086614609,
      -0.004918104503303766,
      -0.07126768678426743,
      0.053143907338380814,
      -0.035625919699668884,
      -0.01871286705136299,
      -0.04405359923839569,
      -0.029296420514583588,
      0.015591579489409924,
      -0.04005137085914612,
      -0.07782135903835297,
      0.010935312137007713,
      -0.027948660776019096,
      0.04760661721229553,
      -0.059460755437612534,
      -0.009978586807847023,
      -0.023216312751173973,
      -0.004738528281450272,
      0.00017048502922989428,
      -0.06603372097015381,
      -0.024724002927541733,
      -0.04551909118890762,
      0.047920361161231995,
      0.027286790311336517,
      -0.03574927896261215,
      -0.04772734269499779,
      0.029292143881320953,
      0.005704098381102085,
      -0.03983602300286293,
      0.012690828181803226,
      -0.026924487203359604,
      0.09465906769037247,
      -0.03968202322721481,
      -0.049444302916526794,
      0.009508238174021244,
      -0.027134591713547707,
      0.013338925316929817,
      0.003924407996237278,
      -0.047620225697755814,
      0.0058901505544781685,
      0.004947763867676258,
      -0.01784140057861805,
      -0.0010040872730314732,
      0.034623172134160995,
      -0.033977922052145004,
      0.03013070859014988,
      0.0500030592083931,
      -0.012015732005238533,
      -0.02007954567670822,
      0.019103270024061203,
      0.0018436687532812357,
      -0.00878098513931036,
      -0.008463941514492035,
      0.08542051166296005,
      0.030350498855113983,
      -0.01871722750365734,
      -0.007973672822117805,
      0.0837181806564331,
      0.07911242544651031,
      0.06751421838998795,
      -0.050682954490184784,
      -0.03409441187977791,
      0.0008954671793617308,
      -0.08625496178865433,
      -0.07338016480207443,
      -0.05661341920495033,
      -0.07223685830831528,
      -0.05273710936307907,
      0.002833722159266472,
      0.023517586290836334,
      -0.022001678124070168,
      0.002934184158220887,
      0.0292267594486475,
      0.02996494248509407,
      -0.00186064001172781,
      0.04704270139336586,
      -0.04278063774108887,
      -0.0177448783069849,
      0.05508042499423027,
      -0.03205392509698868,
      -0.11130339652299881,
      0.0022629061713814735,
      0.022661395370960236,
      -0.03316415846347809,
      -0.04973222687840462,
      0.07158224284648895,
      -0.043218374252319336,
      -0.010859609581530094,
      -0.013933720998466015,
      -0.027824493125081062,
      0.059942785650491714,
      0.042536988854408264,
      0.07329490035772324,
      0.03489488363265991,
      -0.030859176069498062,
      0.009678235277533531,
      -0.033082153648138046,
      -0.007877950556576252,
      -0.06037984788417816,
      -0.008227180689573288,
      0.04016941413283348,
      -0.04134722054004669,
      0.02350670099258423,
      0.08533604443073273,
      0.023203250020742416,
      0.007065641228109598,
      0.04642275720834732,
      0.005723398644477129,
      0.030877741053700447,
      -0.012570353224873543,
      0.07143646478652954,
      -0.009353703819215298,
      -0.016493020579218864,
      0.053474556654691696,
      -0.08529514819383621,
      -0.03373904153704643,
      0.037506356835365295,
      0.040174342691898346,
      0.016475772485136986,
      -0.04293650761246681,
      0.09322793781757355,
      0.012080342508852482,
      0.02706272341310978,
      0.015332404524087906,
      0.07338789850473404,
      0.005136775318533182,
      -0.031824082136154175,
      0.031496766954660416,
      -0.02908506616950035,
      0.007138511631637812,
      -0.043790269643068314,
      -0.0008302637143060565,
      0.028242791071534157,
      0.03696482628583908,
      0.02483610063791275,
      -0.04319274052977562,
      -0.01510678045451641,
      0.05565427243709564,
      -0.010777609422802925,
      -0.010087528266012669,
      -0.061619970947504044,
      -0.06535384804010391,
      -0.014497033320367336,
      0.0005246850778348744,
      -0.0136129604652524,
      0.012726531364023685,
      -0.00690846424549818,
      0.06208649277687073,
      0.049253541976213455,
      0.0230861846357584,
      0.053608257323503494,
      0.02216281183063984,
      -0.007204227149486542,
      0.017475321888923645,
      0.01590176299214363
    ],
    [
      0.03183794394135475,
      -0.007925056852400303,
      0.024732019752264023,
      0.07618331909179688,
      -0.021632086485624313,
      0.040734268724918365,
      -0.03549249842762947,
      -0.006025649607181549,
      0.09503554552793503,
      0.01602761819958687,
      -0.031661130487918854,
      0.05842287465929985,
      0.030594874173402786,
      0.0557825043797493,
      0.016329053789377213,
      0.014311471953988075,
      -0.025867097079753876,
      0.0010499259224161506,
      0.01867818459868431,
      -0.0317314937710762,
      -0.07394389063119888,
      0.010862143710255623,
      -0.019959090277552605,
      0.04248568043112755,
      0.06881598383188248,
      -0.04413736239075661,
      0.017223596572875977,
      -0.019736789166927338,
      0.029559675604104996,
      -0.01886892132461071,
      -0.004327314905822277,
      -0.011225915513932705,
      0.03789072483778,
      0.013487801887094975,
      -0.010481754317879677,
      -0.021284524351358414,
      -0.05141790211200714,
      0.11367637664079666,
      0.0607861690223217,
      -0.03877472132444382,
      0.012024921365082264,
      0.0659477710723877,
      -0.030206620693206787,
      0.06829775124788284,
      -0.052429430186748505,
      0.06582179665565491,
      -0.04048959165811539,
      -0.044189151376485825,
      0.005341041833162308,
      -0.0334671325981617,
      -0.005940211471170187,
      -0.02049170434474945,
      0.016932988539338112,
      -0.038027338683605194,
      -0.04927404969930649,
      0.04369629919528961,
      -0.022370336577296257,
      0.04606251046061516,
      -0.023785367608070374,
      0.06910035759210587,
      0.09263183176517487,
      -0.06235812231898308,
      -0.002692613983526826,
      0.006681337021291256,
      0.01828930154442787,
      -0.02492758259177208,
      0.06691201031208038,
      0.028450697660446167,
      -0.00719092134386301,
      0.024221275001764297,
      -0.0031504931394010782,
      0.04359254240989685,
      -0.0873265415430069,
      0.012252693995833397,
      -0.08144649118185043,
      -0.04373673349618912,
      -0.01670215092599392,
      0.026673538610339165,
      0.013655686751008034,
      0.033409979194402695,
      0.060719169676303864,
      0.030429309234023094,
      -0.031248752027750015,
      -0.03340301662683487,
      -0.04015505313873291,
      0.041775282472372055,
      0.07271990180015564,
      0.033858925104141235,
      0.05959032475948334,
      -0.008326336741447449,
      -0.0036287226248532534,
      -0.0531369149684906,
      0.0053834738209843636,
      -0.020393740385770798,
      0.020529568195343018,
      0.03019905276596546,
      0.010354461148381233,
      -0.02509990893304348,
      -0.044462934136390686,
      -0.0022205887362360954,
      0.003497228492051363,
      -0.004024886526167393,
      0.04329530522227287,
      0.040119122713804245,
      -0.0017794837476685643,
      0.05050073564052582,
      0.07119385153055191,
      0.09683500230312347,
      -0.022637682035565376,
      -0.008325740694999695,
      -0.012090088799595833,
      -0.018390001729130745,
      -0.008651390671730042,
      -0.04222773015499115,
      0.019302142783999443,
      0.06701366603374481,
      0.008731354027986526,
      -0.08621665090322495,
      0.10118047147989273,
      -0.002894308650866151,
      0.005169504787772894,
      -0.0686778575181961,
      -5.888158193556592e-05,
      0.003943032119423151,
      0.03245497867465019,
      -0.037999123334884644,
      -0.01914842799305916,
      -0.011970853433012962,
      0.0020654432009905577,
      0.07033028453588486,
      -0.02178829163312912,
      -0.004555996507406235,
      -0.005513027310371399,
      -0.04487072676420212,
      0.03927076235413551,
      -0.04292932152748108,
      0.020559050142765045,
      -0.058162059634923935,
      0.09688171744346619,
      -0.029683109372854233,
      0.012088720686733723,
      -0.030033478513360023,
      -0.09620732814073563,
      0.04133927822113037,
      0.04936353489756584,
      0.01665530912578106,
      0.012007614597678185,
      0.04231150820851326,
      -0.020181946456432343,
      -0.003726306837052107,
      -0.11049328744411469,
      0.039353467524051666,
      -0.0442810021340847,
      -0.011593031696975231,
      -0.07108525186777115,
      -0.07571890205144882,
      -0.01772167719900608,
      -0.03297584503889084,
      -0.040289200842380524,
      0.04921897128224373,
      -0.018531447276473045,
      0.003847065381705761,
      -0.014713247306644917,
      0.050174739211797714,
      0.0050376299768686295,
      -0.00017043187108356506,
      -0.06187787279486656,
      -0.13216356933116913,
      -0.004181394819170237,
      0.031599078327417374,
      0.019426262006163597,
      0.0024622660130262375,
      -0.012670493684709072,
      -0.02243782766163349,
      0.017535150051116943,
      -0.03568781539797783,
      -0.0032799243927001953,
      0.10512715578079224,
      0.025779403746128082,
      -0.05198590084910393,
      0.06052380055189133,
      -0.012256145477294922,
      0.029762422665953636,
      -0.03294077888131142,
      -0.003660342423245311,
      -0.008952777832746506,
      0.07642106711864471,
      0.02974625490605831,
      0.06582408398389816,
      0.019695304334163666,
      0.033654868602752686,
      -0.03720313683152199,
      0.020545508712530136,
      0.0531439408659935,
      0.019227396696805954,
      0.05909722298383713,
      0.04186725243926048,
      0.017503462731838226,
      -0.03971993178129196,
      -0.048492204397916794,
      0.05309905856847763,
      -0.024383293464779854,
      -0.030878063291311264,
      -0.05407845973968506,
      0.002763468073680997,
      0.024243474006652832,
      -0.06645424664020538,
      0.05481727048754692,
      -0.0017519979737699032,
      0.017183927819132805,
      -0.013402735814452171,
      -0.0072523257695138454,
      0.04099346697330475,
      -0.001937691355124116,
      0.01372606586664915,
      0.03323043882846832,
      0.08147984743118286,
      0.07532437145709991,
      -0.014306595548987389,
      0.13197985291481018,
      -0.07843702286481857,
      -0.043369948863983154,
      -0.009225703775882721,
      0.05978570505976677,
      -0.09192228317260742,
      -0.05195877328515053,
      0.07170059531927109,
      -0.019768163561820984,
      -0.049147624522447586,
      -0.03842703998088837,
      0.011976360343396664,
      -0.02225320413708687,
      0.027301693335175514,
      0.014894172549247742,
      0.04133997857570648,
      0.07181024551391602,
      0.06679114699363708,
      0.0016059302724897861,
      -0.040194179862737656,
      -0.08250246196985245,
      -0.01687786541879177,
      0.024862270802259445,
      -0.03055105172097683,
      0.04099312052130699,
      0.03707752749323845,
      0.0016603270778432488,
      -0.049686234444379807,
      -0.0032275135163217783,
      0.03141801804304123,
      0.017673831433057785,
      0.04884279519319534,
      -0.032415665686130524,
      0.01473523024469614,
      0.03797950968146324,
      -0.06538613140583038,
      -0.008486994542181492,
      0.02779681421816349,
      0.054622337222099304,
      -0.01649158075451851,
      -0.003973851911723614,
      -0.03639506921172142,
      -0.056187208741903305,
      -0.008067854680120945,
      0.09036130458116531,
      -0.013355374336242676,
      0.04954640194773674,
      0.0032348651438951492,
      0.051768530160188675,
      0.037147343158721924,
      -0.0029070337768644094,
      -0.006757484283298254,
      0.007387286983430386,
      -0.06085439771413803,
      -0.009320059791207314,
      -0.07687284052371979,
      0.10768241435289383,
      0.010967281647026539,
      -0.002250887919217348,
      -0.025531740859150887,
      -0.07040097564458847,
      -0.01579560711979866,
      -0.02934427745640278,
      -0.007534089498221874,
      -0.00607250677421689,
      -0.015324913896620274,
      0.010082452557981014,
      0.05042438954114914,
      -0.004354103934019804,
      0.024754296988248825,
      -0.05841454118490219,
      -0.032372765243053436,
      0.01646738499403,
      -0.005293926689773798,
      0.04473979398608208,
      0.06784959137439728,
      0.08148039877414703,
      0.02214597910642624,
      -0.03360654041171074,
      -0.02170477621257305,
      0.01567782647907734,
      -0.052482567727565765,
      0.06479920446872711,
      -0.018074821680784225,
      -0.04220717400312424,
      0.1301725208759308,
      0.06982707977294922,
      -0.04758554697036743,
      -0.01177202258259058,
      0.0707945004105568,
      -0.05647989362478256,
      -0.051158566027879715,
      0.030240830034017563,
      0.01864444650709629,
      0.023326460272073746,
      -0.08599849045276642,
      -0.07843834161758423,
      0.004915637895464897,
      0.07012706249952316,
      0.06563609838485718,
      0.016171757131814957,
      -0.014978067949414253,
      -0.02859039045870304,
      0.0555013045668602,
      0.0040900991298258305,
      -0.06363246589899063,
      -0.033851053565740585,
      -0.04721126705408096,
      -0.09584153443574905,
      -0.004323905799537897,
      0.008123868145048618,
      0.027987554669380188,
      -0.015000127255916595,
      -0.05668960139155388,
      -0.02301095798611641,
      0.047578033059835434,
      0.03699394688010216,
      -0.028405074030160904,
      -0.10876419395208359,
      -0.09338738024234772,
      0.0012125774519518018,
      0.03520893305540085,
      -0.037184543907642365,
      -0.018131839111447334,
      0.03528604283928871,
      0.022119052708148956,
      0.07463223487138748,
      -0.04600559175014496,
      0.008980515412986279,
      -0.005353617016226053,
      0.031009508296847343,
      -0.041077833622694016,
      0.04525408893823624,
      -0.024850202724337578,
      0.08684153109788895,
      -0.06676077842712402,
      -0.014987886883318424,
      0.041424136608839035,
      -0.03864961862564087,
      0.03363550081849098,
      -0.0015625529922544956,
      -0.09122415632009506,
      -0.05635117366909981,
      -0.004360158462077379,
      0.07842177897691727,
      -0.04418147727847099,
      0.029473599046468735,
      -0.020623981952667236,
      0.03706502169370651,
      -0.03592950850725174,
      -0.0872969701886177,
      0.004709244705736637,
      -0.06404738873243332,
      0.004504739306867123,
      0.02339933253824711,
      -0.004304736852645874,
      0.033538028597831726,
      0.02057194709777832,
      0.03866024315357208,
      0.04199289157986641,
      0.02400987036526203,
      0.045781608670949936,
      0.07092524319887161,
      0.007579227443784475,
      0.05245786905288696,
      0.0457034669816494,
      -0.027350515127182007,
      -0.02131611667573452,
      0.041240859776735306,
      -0.013219034299254417,
      0.004615940153598785,
      -0.04401335120201111,
      0.07273419946432114,
      -0.15132153034210205,
      -0.003986468072980642,
      0.08184536546468735,
      -0.0008097090758383274,
      -0.056495580822229385,
      -0.009870367124676704,
      0.06448259949684143,
      0.058823250234127045,
      0.0035637549590319395,
      -0.0183271411806345,
      -0.036050017923116684,
      -0.05403377115726471,
      -0.06393906474113464,
      -0.023483527824282646,
      -0.008411450311541557,
      0.03816753625869751,
      0.04328378289937973,
      -0.029457928612828255,
      -0.008706902153789997,
      0.01369389146566391,
      -0.00037537148455157876,
      -0.03025713749229908,
      -0.017097091302275658,
      -0.03519924357533455,
      -0.042742785066366196,
      0.03746279701590538,
      0.050344258546829224,
      0.014797009527683258,
      0.029913272708654404,
      0.10603319108486176,
      0.033979713916778564,
      0.10844920575618744,
      -0.0655188113451004,
      0.009179551154375076,
      -0.009116069413721561,
      0.07148309797048569,
      -0.009586025960743427,
      -0.02990870364010334,
      -0.01845451258122921,
      0.03499586880207062,
      -0.03841952979564667,
      -0.012127947062253952,
      -0.015205591917037964,
      0.02133735828101635,
      -0.0487220399081707,
      -0.1342129409313202,
      0.012293512932956219,
      -0.0039013149216771126,
      -0.052008748054504395,
      0.030993711203336716,
      -0.03251327574253082,
      0.03494935482740402,
      -0.02192714996635914,
      0.04106404259800911,
      0.03327108919620514,
      -0.0023616994731128216,
      0.0019924789667129517,
      0.04484260082244873,
      0.0067492397502064705,
      -0.052360519766807556,
      0.01566709764301777,
      0.09105342626571655,
      0.1323270946741104,
      0.015853364020586014,
      -0.006300836335867643,
      -0.033869292587041855,
      0.0033212113194167614,
      0.039955850690603256,
      0.06901006400585175,
      -0.036215294152498245,
      -0.020338205620646477,
      0.030850403010845184,
      0.03189516440033913,
      0.019174780696630478,
      0.012642505578696728,
      -0.034164104610681534,
      0.030065355822443962,
      0.037207942456007004,
      0.032283443957567215,
      0.04997648671269417,
      -0.005060672760009766,
      0.04510672017931938,
      -0.045889899134635925,
      -0.010678144171833992,
      0.08978994190692902,
      -0.0005697049200534821,
      -0.04276987910270691,
      0.017022721469402313,
      -0.019179444760084152,
      -0.06775619089603424,
      0.013867603614926338,
      -0.013021830469369888,
      -0.035230156034231186,
      -0.04035700485110283,
      -0.01652463525533676,
      -0.04911267012357712,
      0.009491407312452793,
      0.06655148416757584,
      0.029104098677635193,
      0.01730487309396267,
      -0.005872803274542093,
      -0.028858615085482597,
      0.07257801294326782,
      0.03324069827795029,
      -0.06524176150560379,
      0.04483871906995773,
      -0.011609460227191448,
      0.014428490772843361,
      0.037000350654125214,
      -0.013362768106162548,
      -0.01604425720870495
    ],
    [
      0.09605412930250168,
      -0.007222115993499756,
      0.08103884011507034,
      0.08100393414497375,
      0.036737602204084396,
      -0.019250888377428055,
      0.015922097489237785,
      -0.0358949676156044,
      -0.03260495141148567,
      -0.0039842999540269375,
      0.025066427886486053,
      0.044236231595277786,
      0.01845133677124977,
      -0.025242574512958527,
      0.024499930441379547,
      0.03425173833966255,
      -0.018557550385594368,
      0.04046565666794777,
      -0.030457548797130585,
      -0.005316882394254208,
      -0.00014803875819779932,
      -0.04678953066468239,
      0.03651142492890358,
      0.05697721615433693,
      0.013726850971579552,
      0.045048825442790985,
      -0.014283891767263412,
      0.024946406483650208,
      -0.01201265212148428,
      0.021533219143748283,
      0.06045348569750786,
      0.02501307800412178,
      0.048826951533555984,
      0.009817052632570267,
      -0.0642794817686081,
      0.0095366844907403,
      0.0233414676040411,
      0.08794288337230682,
      0.02671482041478157,
      0.050167638808488846,
      0.0858260840177536,
      0.041289813816547394,
      -0.0016647286247462034,
      -0.009034869261085987,
      -0.04941118136048317,
      -0.038626715540885925,
      0.022211192175745964,
      0.02635633572936058,
      -0.06350109726190567,
      0.012058786116540432,
      0.06918831914663315,
      0.026885250583291054,
      -0.017529277130961418,
      -0.0196977611631155,
      0.08039193600416183,
      -0.009684635326266289,
      -0.0718478262424469,
      -0.0051824720576405525,
      0.028430722653865814,
      -0.024176929146051407,
      -0.017951129004359245,
      -0.046613384038209915,
      0.08250921964645386,
      -0.031485963612794876,
      0.015634162351489067,
      -0.0467936210334301,
      0.004327996633946896,
      -0.07235167920589447,
      0.01862814649939537,
      0.01065560057759285,
      -0.03995198756456375,
      -0.009886400774121284,
      0.017575612291693687,
      0.012978996150195599,
      -0.027882516384124756,
      -0.034279316663742065,
      0.013458325527608395,
      -0.03777274489402771,
      0.0212569423019886,
      -0.06467658281326294,
      0.08228302001953125,
      0.08935464918613434,
      0.046404704451560974,
      -0.04695647954940796,
      -0.07918454706668854,
      0.011760038323700428,
      0.00702348118647933,
      0.06941460072994232,
      -0.08205941319465637,
      -0.02908669039607048,
      0.05175372213125229,
      -0.05577225610613823,
      0.017715057358145714,
      -0.03501801937818527,
      -0.02108611725270748,
      0.018544703722000122,
      0.049851179122924805,
      0.055428534746170044,
      0.017321035265922546,
      -0.0014901509275659919,
      0.03262356296181679,
      -0.02098398655653,
      -0.027034621685743332,
      0.010067104361951351,
      -0.00453143147751689,
      -0.05906665325164795,
      0.03133460506796837,
      0.012627401389181614,
      0.03184690326452255,
      -0.011199829168617725,
      -0.06754963845014572,
      0.0003739288658834994,
      -0.056578438729047775,
      -0.05393986776471138,
      0.07747790962457657,
      0.028312645852565765,
      0.042746834456920624,
      0.013031752780079842,
      -0.01998312398791313,
      -0.0512758269906044,
      -0.041985850781202316,
      -0.035184282809495926,
      0.04567008092999458,
      -0.09467531740665436,
      0.017892388626933098,
      0.041628092527389526,
      -0.00988812930881977,
      0.047727346420288086,
      0.07140133529901505,
      -0.01623360440135002,
      0.020082518458366394,
      -0.019064536318182945,
      0.00020790164126083255,
      0.04075067862868309,
      -0.007536514196544886,
      0.013197842985391617,
      0.031176839023828506,
      -0.05300311744213104,
      -0.06485190987586975,
      0.005312499590218067,
      -0.030651453882455826,
      0.026981081813573837,
      -0.026601919904351234,
      0.014519491232931614,
      -0.011962119489908218,
      0.08050493896007538,
      0.04185118526220322,
      -0.09351152181625366,
      0.027608629316091537,
      -0.031885161995887756,
      0.012413626536726952,
      0.05230497941374779,
      -0.005598933901637793,
      0.10104604810476303,
      -0.09882257133722305,
      0.03580750152468681,
      0.047812364995479584,
      -0.08176261186599731,
      -0.011040890589356422,
      -0.05493580922484398,
      -0.010120281018316746,
      -0.025107642635703087,
      -0.040588993579149246,
      -0.06942394375801086,
      0.053200189024209976,
      -0.04121581092476845,
      0.018698163330554962,
      -0.030074436217546463,
      0.022384483367204666,
      -0.08422189205884933,
      0.023198358714580536,
      0.022682886570692062,
      0.05553238093852997,
      0.028491396456956863,
      -0.0444214791059494,
      -0.006029520649462938,
      -0.03379266336560249,
      -0.05134976655244827,
      0.02709457091987133,
      0.027999168261885643,
      0.0005489015020430088,
      0.010524601675570011,
      -0.004686648026108742,
      -0.049527574330568314,
      0.059271808713674545,
      0.029628420248627663,
      0.0019992338493466377,
      0.08949771523475647,
      0.061104316264390945,
      0.08217988908290863,
      -0.015122704207897186,
      -0.030196283012628555,
      0.022157631814479828,
      -0.007705839816480875,
      -0.03919052705168724,
      -0.0019444763893261552,
      -0.008799339644610882,
      0.007011001463979483,
      -0.0478343665599823,
      -0.010753637179732323,
      0.03771785646677017,
      -0.05344555526971817,
      -0.015014558099210262,
      -0.055610157549381256,
      0.08210644870996475,
      -0.033063389360904694,
      0.057578232139348984,
      0.09130008518695831,
      -0.0074635641649365425,
      0.0613594651222229,
      -0.03628385812044144,
      -0.03217988833785057,
      0.039341408759355545,
      0.004930834285914898,
      0.010952822864055634,
      -0.09414208680391312,
      0.06997927278280258,
      -0.08085284382104874,
      0.01083652675151825,
      0.08985166251659393,
      0.07321839779615402,
      0.04002312943339348,
      0.10784173756837845,
      -0.05210019648075104,
      0.034967951476573944,
      -0.009423707611858845,
      0.0753500908613205,
      -0.07319775968790054,
      -0.02456490322947502,
      -0.05482979863882065,
      0.05611616000533104,
      0.0021685599349439144,
      -0.009569855406880379,
      0.04348205402493477,
      0.008870882913470268,
      -0.013977422378957272,
      0.02994665317237377,
      0.05678880587220192,
      -0.011637466959655285,
      0.06981004774570465,
      -0.05141032859683037,
      0.004478133749216795,
      -0.059127744287252426,
      -0.022402148693799973,
      0.023769140243530273,
      0.01534726470708847,
      -0.05144836753606796,
      -0.018943309783935547,
      -0.11055456846952438,
      0.009531877003610134,
      0.05567038431763649,
      -0.01737646572291851,
      0.020428825169801712,
      -0.024321338161826134,
      0.03981305658817291,
      -0.0811786875128746,
      0.051350802183151245,
      0.03728315979242325,
      -0.012805419974029064,
      -0.0592021718621254,
      -0.04463772103190422,
      0.04072157293558121,
      0.008839250542223454,
      0.01657263934612274,
      0.008745425380766392,
      0.015765883028507233,
      0.00030091896769590676,
      -0.030818404629826546,
      -0.04650380089879036,
      -0.008462142199277878,
      0.08636083453893661,
      -0.03659989684820175,
      -0.010177772492170334,
      -0.01600690744817257,
      -0.017932670190930367,
      -0.05453849211335182,
      -0.03941776230931282,
      -0.018281497061252594,
      0.02604423649609089,
      -0.00643722852692008,
      -0.06388864666223526,
      0.019928546622395515,
      -0.030338263139128685,
      -0.06064409390091896,
      0.0326332226395607,
      0.06697874516248703,
      -0.018465464934706688,
      -0.0899718776345253,
      -0.037373967468738556,
      -0.10478642582893372,
      0.004757389891892672,
      0.14492306113243103,
      -0.0017901426181197166,
      0.007086065132170916,
      0.06260039657354355,
      0.0048605939373373985,
      0.09089720249176025,
      0.10200022906064987,
      0.04520006105303764,
      -0.017892364412546158,
      0.021393518894910812,
      -0.01364190224558115,
      -0.03313352167606354,
      0.011243094690144062,
      0.0241741593927145,
      0.044342704117298126,
      -0.07349343597888947,
      0.045315779745578766,
      0.04590047523379326,
      0.004941472318023443,
      -0.02165585570037365,
      0.08539170771837234,
      0.03808223083615303,
      -0.08251986652612686,
      0.004804150201380253,
      0.011604484170675278,
      0.02319583296775818,
      0.0839090421795845,
      0.11382173001766205,
      0.012036391533911228,
      0.027780665084719658,
      0.06417287141084671,
      -0.04415253922343254,
      0.042120929807424545,
      -0.0009316966170445085,
      -0.06060934066772461,
      -0.09314871579408646,
      -0.033472537994384766,
      0.029772043228149414,
      0.02788110263645649,
      -0.021517541259527206,
      0.057291749864816666,
      0.11885067075490952,
      0.046094972640275955,
      0.04869719222187996,
      -0.06281191855669022,
      -0.044149111956357956,
      0.01294920314103365,
      0.08527172356843948,
      -0.03193968906998634,
      0.08418725430965424,
      -0.032505832612514496,
      -0.022951701655983925,
      0.007584775798022747,
      0.029385065659880638,
      0.010257044807076454,
      -0.04224642366170883,
      0.052868060767650604,
      -0.0004093600728083402,
      -0.023911934345960617,
      0.03682321682572365,
      -0.045477550476789474,
      -0.05588197335600853,
      0.05535079538822174,
      0.00822457391768694,
      0.07357220351696014,
      0.036121465265750885,
      0.09856882691383362,
      -0.04455452784895897,
      0.0016158652724698186,
      -0.07677862048149109,
      -0.027656985446810722,
      0.026333244517445564,
      -0.03974071145057678,
      -0.07212332636117935,
      -0.030974777415394783,
      0.10997741669416428,
      0.008091958239674568,
      -0.020549848675727844,
      0.021487468853592873,
      -0.11220858246088028,
      -0.07695882767438889,
      -0.01656854711472988,
      -0.012977393344044685,
      0.04705577716231346,
      -0.021957168355584145,
      0.06590055674314499,
      0.013916309922933578,
      -0.06850911676883698,
      -0.03004121594130993,
      -0.01416054368019104,
      0.12460936605930328,
      -0.03571782261133194,
      -0.04822690412402153,
      0.028921350836753845,
      -0.050456833094358444,
      0.042211782187223434,
      0.008150204084813595,
      0.0618637390434742,
      0.001979976426810026,
      -0.05673323944211006,
      -0.05435223504900932,
      0.015153990127146244,
      0.01102378685027361,
      0.06227089464664459,
      0.02507224678993225,
      -0.01194528117775917,
      -0.055664993822574615,
      -0.059949930757284164,
      0.033535782247781754,
      -0.007090883329510689,
      -0.07074829190969467,
      -0.028849497437477112,
      0.039996180683374405,
      0.014331537298858166,
      -0.041226860135793686,
      0.042263127863407135,
      -0.00017177252448163927,
      -0.030609823763370514,
      -0.0060422103852033615,
      0.02558177150785923,
      0.014745797030627728,
      -0.058705270290374756,
      -0.003914881497621536,
      -0.07889064401388168,
      0.026091163977980614,
      0.03682428598403931,
      0.004854414612054825,
      0.005435137543827295,
      -0.08708064258098602,
      0.13195227086544037,
      0.1304977685213089,
      0.09809186309576035,
      0.008421968668699265,
      0.020134007558226585,
      0.006153819616883993,
      -0.019215241074562073,
      -0.1188427284359932,
      -0.05055578425526619,
      0.03194161131978035,
      -0.06768540292978287,
      -0.0559573769569397,
      -0.008597682230174541,
      -0.013161621987819672,
      -0.00788420345634222,
      -0.0187398511916399,
      -0.016577009111642838,
      -0.046632878482341766,
      -0.039930958300828934,
      0.023997319862246513,
      -0.03895903006196022,
      0.03132743760943413,
      0.037080712616443634,
      0.07185970991849899,
      0.014758823439478874,
      0.12813858687877655,
      -0.0153474360704422,
      0.011809677816927433,
      0.03140071779489517,
      0.01700734719634056,
      -0.020679086446762085,
      -0.0030320389196276665,
      -0.02768733538687229,
      0.0008178896969184279,
      -0.003435498336330056,
      -0.06832222640514374,
      0.08236514031887054,
      0.005947011057287455,
      0.0008143489831127226,
      -0.0901273861527443,
      -0.014259988442063332,
      -0.022435422986745834,
      0.06923361122608185,
      0.028210962191224098,
      0.029615120962262154,
      0.023266363888978958,
      -0.027252765372395515,
      -0.04932131618261337,
      0.02508794330060482,
      -0.06226067617535591,
      0.005997947417199612,
      0.005936282686889172,
      0.01463411096483469,
      0.009004554711282253,
      -0.03066951222717762,
      -0.03983835503458977,
      -0.0418362095952034,
      -0.01993723399937153,
      0.05156261846423149,
      0.05082011595368385,
      -0.06597830355167389,
      -0.02643742226064205,
      0.07412649691104889,
      0.031153179705142975,
      -0.002369887428358197,
      -0.031200511381030083,
      0.06926774978637695,
      -0.05098196119070053,
      -0.026473086327314377,
      0.020878911018371582,
      0.016060365363955498,
      0.0358547642827034,
      -0.06205720826983452,
      0.016393113881349564,
      0.12725117802619934,
      -0.06126896291971207,
      -0.014364676550030708,
      -0.037847597151994705,
      -0.05729454755783081,
      0.06064894422888756,
      0.044182319194078445,
      -0.0178044606000185,
      -0.027005286887288094
    ],
    [
      -0.016131654381752014,
      -0.039797570556402206,
      -0.07858508825302124,
      0.11592668294906616,
      -0.00946678128093481,
      -0.053146842867136,
      0.02482943795621395,
      0.014206117019057274,
      -0.026703890413045883,
      0.015743475407361984,
      -0.0009847007459029555,
      -0.010771948844194412,
      -0.02420695498585701,
      0.11262334883213043,
      0.04572853446006775,
      -0.08921464532613754,
      -0.01591162197291851,
      0.0030068152118474245,
      0.0033570146188139915,
      0.0700673907995224,
      0.01535600796341896,
      -0.03193916752934456,
      -0.049568433314561844,
      0.056818168610334396,
      -0.04240427166223526,
      -0.005290159489959478,
      0.03600749000906944,
      0.0038968026638031006,
      -0.04129411280155182,
      0.014719365164637566,
      -0.048906683921813965,
      -0.02665705233812332,
      0.060327205806970596,
      0.09116356074810028,
      0.015407447703182697,
      -0.002698255004361272,
      0.04680686444044113,
      -0.004704826045781374,
      0.016216525807976723,
      0.049743056297302246,
      -0.05438877269625664,
      0.033051010221242905,
      -0.05229659378528595,
      0.03226079046726227,
      -0.05901752784848213,
      0.020083755254745483,
      0.07606806606054306,
      -0.019965695217251778,
      0.019830817356705666,
      -0.08157360553741455,
      -0.010037635453045368,
      0.005507082678377628,
      0.03646841645240784,
      -0.01846299320459366,
      -0.10049998760223389,
      -0.013029716908931732,
      -0.0585104338824749,
      0.02602562867105007,
      0.07286834716796875,
      -0.06677955389022827,
      0.009902788326144218,
      -0.005169284995645285,
      0.0008816635236144066,
      0.035728827118873596,
      0.002159362193197012,
      -0.060168806463479996,
      0.03444686532020569,
      0.01794414594769478,
      0.09903672337532043,
      0.010669196024537086,
      -0.019748393446207047,
      0.08318228274583817,
      -0.04677234962582588,
      -0.0003838138363789767,
      0.028625665232539177,
      0.027435313910245895,
      0.024867931380867958,
      0.00040095485746860504,
      0.019641801714897156,
      0.001479238853789866,
      0.030054721981287003,
      -0.05155521631240845,
      -0.012357284314930439,
      -0.0884481891989708,
      -0.002662801882252097,
      0.004139931872487068,
      0.05136268585920334,
      0.02232900634407997,
      0.09983783960342407,
      0.020550977438688278,
      0.08229831606149673,
      -0.0325101874768734,
      0.0005305917584337294,
      -0.02069060131907463,
      -0.023997168987989426,
      -0.04887909069657326,
      0.042471207678318024,
      -0.009993929415941238,
      0.050494082272052765,
      0.016403628513216972,
      -0.09413900971412659,
      -0.06420326977968216,
      0.0038268622010946274,
      0.00807588454335928,
      -0.03795735538005829,
      -0.006009885109961033,
      -0.010905374772846699,
      0.030506767332553864,
      -0.06413519382476807,
      -0.06215113028883934,
      0.013896199874579906,
      0.08503089100122452,
      0.02217806689441204,
      -0.0656660795211792,
      -0.01743522845208645,
      -0.00981478113681078,
      0.010707552544772625,
      0.052150022238492966,
      0.024953076615929604,
      -0.005120762158185244,
      -0.10890145599842072,
      -0.06717997789382935,
      -0.0025302718859165907,
      0.035671912133693695,
      -0.04086881875991821,
      -0.011924877762794495,
      -0.02210938185453415,
      0.09438008069992065,
      0.06529032438993454,
      0.02756284363567829,
      0.010354899801313877,
      -0.028346341103315353,
      0.0366569422185421,
      0.057844776660203934,
      -0.02990688756108284,
      0.023114513605833054,
      0.051659099757671356,
      -0.048393141478300095,
      -0.09696945548057556,
      0.057499174028635025,
      -0.02658253349363804,
      -0.1342354714870453,
      0.08164899051189423,
      0.06438643485307693,
      0.019823240116238594,
      0.010650836862623692,
      0.06828061491250992,
      -0.06414679437875748,
      -0.011257071048021317,
      -0.074558325111866,
      -0.0014661781024187803,
      -0.08487148582935333,
      0.024377426132559776,
      -0.056290119886398315,
      0.05268404632806778,
      -0.0642915889620781,
      -0.051111504435539246,
      0.07050486654043198,
      -0.024006014689803123,
      -0.013078688643872738,
      0.0047817835584282875,
      -0.08091771602630615,
      -0.008124059066176414,
      0.04203224554657936,
      0.02782907523214817,
      0.0577898733317852,
      0.00759859848767519,
      -0.027980266138911247,
      -0.011660262942314148,
      -0.01687379740178585,
      -0.05086101219058037,
      -0.04276980832219124,
      -0.05223630368709564,
      0.07989215105772018,
      0.013104697689414024,
      0.025324475020170212,
      -0.005965753924101591,
      0.08182249963283539,
      0.09539380669593811,
      -0.007999897003173828,
      0.04768865182995796,
      -0.029734229668974876,
      0.013662652112543583,
      0.003592359134927392,
      -0.0008027360890991986,
      0.003886423073709011,
      -0.1149604320526123,
      -0.06839417666196823,
      0.03807352855801582,
      0.024898916482925415,
      0.016109926626086235,
      -0.060711998492479324,
      -0.039128225296735764,
      -0.014311160892248154,
      0.0010008359095081687,
      0.03198715299367905,
      0.024539319798350334,
      0.029713477939367294,
      -0.12878170609474182,
      0.014437775127589703,
      0.021587563678622246,
      -0.0230886097997427,
      0.025390269234776497,
      0.020093876868486404,
      -0.027097297832369804,
      -0.056358616799116135,
      0.012657283805310726,
      0.010306126438081264,
      0.04592973366379738,
      -0.02013956755399704,
      0.08864139020442963,
      -0.024613426998257637,
      -0.014815017580986023,
      0.03213394433259964,
      0.03667201101779938,
      0.04536295682191849,
      -0.03141309320926666,
      -0.027912365272641182,
      0.037224411964416504,
      0.07556574046611786,
      0.032660018652677536,
      0.06714443862438202,
      -0.002595561556518078,
      0.033177852630615234,
      -0.03931431844830513,
      0.04796367511153221,
      0.07625520974397659,
      -0.034704212099313736,
      0.026658112183213234,
      0.017034010961651802,
      0.08485722541809082,
      -0.023318367078900337,
      -0.06737583875656128,
      -0.005547892302274704,
      0.013670755550265312,
      -0.033332858234643936,
      -0.035633668303489685,
      0.05375756323337555,
      0.024557774886488914,
      0.017862726002931595,
      0.003179573453962803,
      -0.010371378622949123,
      0.08397124707698822,
      -0.015407555736601353,
      0.012519454583525658,
      -0.0701371505856514,
      -0.0011071753688156605,
      0.0037190630100667477,
      0.03323100879788399,
      0.08174961060285568,
      0.0022168648429214954,
      -0.07884781807661057,
      0.060109470039606094,
      0.0276785921305418,
      0.013850721530616283,
      -0.021615292876958847,
      -0.03346659615635872,
      0.006620239466428757,
      0.07091986387968063,
      0.043161116540431976,
      -0.0838918387889862,
      -0.05520132556557655,
      -0.00038555904757231474,
      -0.07641952484846115,
      0.05610991269350052,
      -0.028835617005825043,
      0.014059947803616524,
      -0.04310163855552673,
      0.020222125574946404,
      0.006312239449471235,
      0.03624310716986656,
      -0.029512926936149597,
      0.016561567783355713,
      -0.07296482473611832,
      -0.028643770143389702,
      0.04097490757703781,
      0.06645228713750839,
      0.01227828860282898,
      0.008892796002328396,
      0.021827133372426033,
      0.04112628847360611,
      0.026859382167458534,
      -0.02684818208217621,
      -0.005802251864224672,
      -0.018483132123947144,
      -0.023292070254683495,
      -0.03652258589863777,
      -0.02603418380022049,
      -0.04710066318511963,
      -0.014984935522079468,
      0.008419480174779892,
      0.005309130065143108,
      -0.041783593595027924,
      -0.018115274608135223,
      -0.04444507136940956,
      0.04544743523001671,
      -0.01951969973742962,
      0.0018973062979057431,
      -0.027070999145507812,
      -0.004016578197479248,
      0.03893140330910683,
      0.00869225338101387,
      0.015593264251947403,
      0.0088581508025527,
      0.011938835494220257,
      0.08250546455383301,
      0.054934557527303696,
      -0.02439389005303383,
      0.09076444059610367,
      -0.04727146029472351,
      -0.02230486460030079,
      0.02520536631345749,
      0.014048831537365913,
      0.015676233917474747,
      -0.025240305811166763,
      0.01684844121336937,
      0.023428423330187798,
      0.029056446626782417,
      0.0011493341298773885,
      -0.01914457604289055,
      0.0023978096432983875,
      -0.060719236731529236,
      0.0026564253494143486,
      0.07690000534057617,
      -0.03721794858574867,
      0.024033259600400925,
      -0.01996574178338051,
      -0.004796931985765696,
      -0.06933272629976273,
      -0.039565522223711014,
      0.007159327156841755,
      -0.05533069372177124,
      0.08301901072263718,
      0.07620585709810257,
      -0.020372722297906876,
      0.04565924033522606,
      -0.02357468195259571,
      -0.04901538044214249,
      -0.037786271423101425,
      -0.010394121520221233,
      -0.018809635192155838,
      0.007291256915777922,
      0.0494953952729702,
      0.0024969482328742743,
      0.021124104037880898,
      0.0738144963979721,
      -0.017346356064081192,
      -0.02301429770886898,
      -0.04714873060584068,
      -0.06724122166633606,
      -0.03340105712413788,
      -0.025423496961593628,
      0.033304136246442795,
      -0.005841808393597603,
      0.006633351556956768,
      -0.03111264668405056,
      -0.06812018156051636,
      -0.01923208124935627,
      -0.11112506687641144,
      0.11078784614801407,
      -0.044197987765073776,
      -0.022563183680176735,
      0.044719770550727844,
      0.018009476363658905,
      0.03946145996451378,
      0.01386183500289917,
      -0.007968545891344547,
      0.0013997803907841444,
      0.00760604627430439,
      0.03034181334078312,
      0.005180894862860441,
      -0.018153486773371696,
      0.018207762390375137,
      0.05630401894450188,
      -0.03597977012395859,
      -0.05725425109267235,
      -0.01142771914601326,
      -0.01234449353069067,
      0.022998247295618057,
      -0.020341070368885994,
      -0.03369817137718201,
      0.06287359446287155,
      -0.06201517954468727,
      0.019970864057540894,
      -0.11745867878198624,
      0.0840703547000885,
      -0.06177830323576927,
      -0.017554422840476036,
      0.038619816303253174,
      0.07742771506309509,
      0.012268919497728348,
      -0.006945713423192501,
      0.031174760311841965,
      0.09483160823583603,
      0.004651433788239956,
      -0.0032674861140549183,
      0.09309699386358261,
      -0.032580696046352386,
      0.019898300990462303,
      0.040761131793260574,
      -0.0029558618552982807,
      -0.06726143509149551,
      -0.015931731089949608,
      0.0017856234917417169,
      -0.010941864922642708,
      -0.0846492126584053,
      -0.000923438579775393,
      -0.005124290473759174,
      0.04420609399676323,
      -0.0245044007897377,
      -0.04983072355389595,
      -0.05544053390622139,
      0.0020342811476439238,
      -0.03125797584652901,
      -0.07468844205141068,
      -0.055965643376111984,
      -0.03918420150876045,
      -0.05445236712694168,
      0.013759545981884003,
      0.0021488249767571688,
      -0.011568177491426468,
      0.007783208042383194,
      0.010686403140425682,
      0.02954830229282379,
      -0.07396286725997925,
      0.010905866511166096,
      -0.03143931180238724,
      0.06480173021554947,
      -0.01248614676296711,
      -0.049797479063272476,
      0.016740508377552032,
      -0.05382339656352997,
      0.05604155361652374,
      -0.001818434800952673,
      -0.08040978759527206,
      0.008938143029808998,
      0.002474878216162324,
      0.04063159599900246,
      0.013193242251873016,
      -0.022480634972453117,
      0.016682296991348267,
      0.005162009038031101,
      -0.005855692084878683,
      0.04259761795401573,
      -0.07970905303955078,
      0.024915453046560287,
      -0.0019043548963963985,
      0.024111585691571236,
      0.011858975514769554,
      0.007890782319009304,
      -0.010849826037883759,
      -0.0010849864920601249,
      -0.0133902532979846,
      -0.08930980414152145,
      -0.0341087244451046,
      -0.08277435600757599,
      0.0009760435204952955,
      0.06482622027397156,
      -0.03178303688764572,
      0.0352180078625679,
      -0.03596070781350136,
      0.0234508216381073,
      0.015541362576186657,
      -0.012494546361267567,
      -0.019367381930351257,
      0.07371000945568085,
      0.05455086752772331,
      0.021303655579686165,
      0.016207529231905937,
      -0.055858999490737915,
      -0.008732686750590801,
      -0.019131751731038094,
      -0.004851473029702902,
      -0.03760863095521927,
      0.11722342669963837,
      -0.02393922582268715,
      -0.010030380450189114,
      -0.007399674039334059,
      0.030979426577687263,
      0.0596836693584919,
      -0.004320220556110144,
      -0.018106501549482346,
      -0.032841410487890244,
      0.01792001910507679,
      -0.036613475531339645,
      -0.020478593185544014,
      -0.04061245173215866,
      -0.06620625406503677,
      -0.04815640673041344,
      -0.03905400261282921,
      0.025828726589679718,
      0.05335303023457527,
      -0.0051943110302090645,
      0.02020101435482502,
      0.05217272788286209,
      -0.061440035700798035,
      -0.06881636381149292,
      0.09646598249673843,
      -0.03309852257370949,
      0.014966367743909359,
      -0.028462111949920654,
      0.037988513708114624,
      0.0037400908768177032
    ],
    [
      -0.048324499279260635,
      0.01987767592072487,
      -0.09203009307384491,
      -0.011648321524262428,
      -0.08927309513092041,
      -0.03520270437002182,
      -0.07028649002313614,
      0.057832930237054825,
      0.0012346301227807999,
      -0.04020475223660469,
      -0.033496446907520294,
      0.03342786431312561,
      -0.04468568414449692,
      -0.0008327996474690735,
      0.02768472209572792,
      -0.014592750929296017,
      -0.08304706960916519,
      0.023686187341809273,
      -0.008443732745945454,
      0.00027155797579325736,
      0.0702347680926323,
      -0.0362040139734745,
      0.018517786636948586,
      -0.016843454912304878,
      0.08715645223855972,
      -0.09685420244932175,
      0.0022440177854150534,
      -0.06447947770357132,
      -0.01610957644879818,
      0.046260930597782135,
      -0.03665321320295334,
      -0.025356676429510117,
      0.006890370976179838,
      0.07389254122972488,
      -0.0376368910074234,
      0.026616893708705902,
      -0.0023238465655595064,
      -0.024775534868240356,
      0.04886337369680405,
      0.029369307681918144,
      -0.031195994466543198,
      -0.11088591068983078,
      -0.03627936169505119,
      0.055059827864170074,
      0.08041580766439438,
      0.010209217667579651,
      0.08819347620010376,
      0.09640098363161087,
      0.04234728962182999,
      -0.006470506079494953,
      0.08197274059057236,
      0.024778874590992928,
      -0.0022632635664194822,
      -0.061781905591487885,
      -0.022935805842280388,
      -0.09509198367595673,
      0.015824582427740097,
      0.0029186743777245283,
      -0.0886608362197876,
      -0.061304666101932526,
      -0.025956057012081146,
      -0.07158028334379196,
      -0.06639420986175537,
      -0.047264985740184784,
      -0.006091163028031588,
      -0.017108477652072906,
      -0.022352205589413643,
      0.02461128868162632,
      -0.00693378085270524,
      -0.0020133431535214186,
      0.060562945902347565,
      -0.09359261393547058,
      0.0642043873667717,
      0.03784673660993576,
      -0.040920455008745193,
      -0.07404396682977676,
      0.04607609659433365,
      -0.07723047584295273,
      0.018481086939573288,
      0.01103158388286829,
      -0.01726365275681019,
      0.05805716663599014,
      -0.022371971979737282,
      -0.08487431704998016,
      -0.0249885693192482,
      0.03520124405622482,
      0.03199993446469307,
      -0.034887392073869705,
      0.01667132042348385,
      -0.00026040017837658525,
      -0.005762680433690548,
      0.009831510484218597,
      0.005969671066850424,
      -0.012500513345003128,
      0.019319843500852585,
      0.01013181358575821,
      0.04244886338710785,
      -0.09586253762245178,
      -0.03464682400226593,
      -0.02731422707438469,
      -0.033295638859272,
      0.07898475229740143,
      -0.012281864881515503,
      0.014041312038898468,
      0.05235789343714714,
      0.07143504917621613,
      -0.02988283336162567,
      0.02920544520020485,
      -0.014349227771162987,
      -0.03496764227747917,
      0.06281714141368866,
      0.03429603576660156,
      -0.07587423175573349,
      -0.012567130848765373,
      -0.041008803993463516,
      0.017160754650831223,
      0.028625445440411568,
      0.027013711631298065,
      0.008693854324519634,
      -0.011874386109411716,
      -0.05405477061867714,
      -0.02191091701388359,
      -0.030193530023097992,
      0.05548437684774399,
      -0.034294892102479935,
      0.03240791708230972,
      0.004182642791420221,
      -0.036269139498472214,
      -0.032726891338825226,
      0.048618149012327194,
      0.047169946134090424,
      -0.005768771283328533,
      0.07002019882202148,
      0.04576690122485161,
      -0.005201598163694143,
      0.031735584139823914,
      0.004758627153933048,
      -0.02087140828371048,
      -0.011145679280161858,
      -0.06121209263801575,
      -0.02942747436463833,
      -0.041818056255578995,
      0.1385670304298401,
      0.0018002763390541077,
      -0.03157757967710495,
      -0.0026504681445658207,
      0.016746314242482185,
      0.014864807948470116,
      -0.06447261571884155,
      0.1112041249871254,
      0.06978405267000198,
      0.034904468804597855,
      -0.03103085234761238,
      0.08443813771009445,
      -0.02056615613400936,
      -0.013875325210392475,
      -0.03852251172065735,
      -0.05062257498502731,
      0.09241446852684021,
      -0.027507495135068893,
      -0.043319035321474075,
      -0.019558565691113472,
      -0.088101327419281,
      0.026462914422154427,
      -0.09187941998243332,
      -0.0010338580468669534,
      -0.042141493409872055,
      -0.042117487639188766,
      0.04014221578836441,
      0.02963569015264511,
      0.06975726783275604,
      -0.03430945798754692,
      -0.03219955787062645,
      -0.03834986686706543,
      0.022726289927959442,
      0.009663965553045273,
      0.09656404703855515,
      -0.028526559472084045,
      0.014618285931646824,
      0.003398994682356715,
      -0.008404655382037163,
      -0.07054708898067474,
      0.04000630974769592,
      -0.015305060893297195,
      0.03515240550041199,
      0.017072632908821106,
      0.10751140862703323,
      -0.012754056602716446,
      0.04038195312023163,
      0.02381187118589878,
      -0.019101399928331375,
      -0.04174245148897171,
      -0.014328169636428356,
      0.004064076114445925,
      -0.06051664799451828,
      0.006738763302564621,
      0.031377989798784256,
      -0.017978930845856667,
      0.022390976548194885,
      -0.06218653917312622,
      0.023300709202885628,
      -0.08371266722679138,
      -0.03276350721716881,
      -0.02713247388601303,
      0.04451336711645126,
      0.008939707651734352,
      0.06473901122808456,
      -0.08648151904344559,
      -0.005019080359488726,
      0.09997943788766861,
      -0.028905443847179413,
      0.020158948376774788,
      -0.029393907636404037,
      -0.044848352670669556,
      0.07850360125303268,
      -0.033801645040512085,
      -0.015820154920220375,
      0.021458620205521584,
      0.020007139071822166,
      0.05642589181661606,
      0.008218140341341496,
      -0.0002551068610046059,
      -0.03651560842990875,
      0.039727549999952316,
      -0.04748021811246872,
      0.008132820948958397,
      -0.059501320123672485,
      -0.00952945277094841,
      0.039210788905620575,
      -0.02126319706439972,
      -0.04070208594202995,
      0.04497947543859482,
      0.06079607829451561,
      0.024559803307056427,
      0.051834143698215485,
      0.010610081255435944,
      -0.054889991879463196,
      -0.017795581370592117,
      -0.03478679805994034,
      -0.008785865269601345,
      0.02219787985086441,
      0.0016129424329847097,
      0.07017101347446442,
      -0.01041808258742094,
      -0.005506820511072874,
      -0.002443068427965045,
      0.03280799835920334,
      -0.0022447488736361265,
      0.020695308223366737,
      -0.027501672506332397,
      0.006028728559613228,
      -0.005480825901031494,
      -0.00787899550050497,
      -0.03302370384335518,
      -0.050056714564561844,
      0.003941258881241083,
      0.07237491756677628,
      0.019009971991181374,
      0.06210768595337868,
      -0.040087368339300156,
      0.005065171979367733,
      -0.02349737472832203,
      -0.009388335049152374,
      -0.03318743035197258,
      0.0606229193508625,
      -0.07127680629491806,
      -0.05848872661590576,
      0.032145678997039795,
      -0.10827599465847015,
      0.014198670163750648,
      -0.05659293010830879,
      0.01326011586934328,
      -0.028193529695272446,
      -0.029108062386512756,
      -0.005946343764662743,
      0.018033001571893692,
      0.00580632546916604,
      0.006157595198601484,
      0.03549852594733238,
      -0.03616013005375862,
      -0.014752737246453762,
      0.03488972410559654,
      0.0742274671792984,
      -0.10688591748476028,
      0.043715424835681915,
      -0.031546078622341156,
      0.02250143699347973,
      0.05614577233791351,
      -0.039434343576431274,
      -0.027898307889699936,
      0.055041197687387466,
      -0.002243333263322711,
      0.009598258882761002,
      -0.01645110733807087,
      -0.07686245441436768,
      -0.03278087079524994,
      -0.07632580399513245,
      -0.02196158654987812,
      -0.08155045658349991,
      0.0044455574825406075,
      0.025250228121876717,
      0.025296414270997047,
      0.03743238374590874,
      0.0696757584810257,
      0.01771237514913082,
      -0.07160216569900513,
      -0.0372796431183815,
      -0.004450603853911161,
      0.03308167681097984,
      -0.005288020242005587,
      -0.041219159960746765,
      -0.015734583139419556,
      -0.05881112068891525,
      -0.12092183530330658,
      0.07054658234119415,
      0.006036535836756229,
      -0.035884957760572433,
      0.0076345643028616905,
      0.00262350938282907,
      0.03284962847828865,
      -0.040561605244874954,
      -0.07094885408878326,
      0.016318654641509056,
      -0.0008678631274960935,
      0.03722624108195305,
      -0.06342769414186478,
      0.09365025162696838,
      -0.01638551987707615,
      0.021651385352015495,
      -0.056459929794073105,
      0.031112024560570717,
      -0.07304274290800095,
      0.016198061406612396,
      0.010937231592833996,
      0.044107403606176376,
      0.029727153480052948,
      0.02725902572274208,
      -0.00753075210377574,
      0.04524637386202812,
      0.012533440254628658,
      -0.048180315643548965,
      0.028381986543536186,
      -0.032259922474622726,
      0.09990618377923965,
      0.005583725869655609,
      0.0072926743887364864,
      -0.08509630709886551,
      -0.06107190623879433,
      0.03138470649719238,
      0.004680718295276165,
      -0.05463440343737602,
      0.03385528549551964,
      -0.07513196766376495,
      -0.01124362088739872,
      0.024630747735500336,
      0.038872964680194855,
      0.04638396576046944,
      -0.060234520584344864,
      0.008607334457337856,
      -0.034425653517246246,
      0.0817468911409378,
      -0.016859067603945732,
      -0.06226878985762596,
      -0.04308610036969185,
      -0.008487516082823277,
      0.035366058349609375,
      -0.08186907321214676,
      -0.003470339812338352,
      -0.10405297577381134,
      0.1039653792977333,
      0.10134413838386536,
      -0.023180143907666206,
      -0.05416012927889824,
      0.02131189964711666,
      0.024934254586696625,
      -0.07088302820920944,
      0.06889878958463669,
      -0.09314481914043427,
      -0.043070290237665176,
      -0.04878261685371399,
      -0.011123149655759335,
      0.009492147713899612,
      0.04267914593219757,
      0.1114220842719078,
      -0.053008947521448135,
      -0.020121142268180847,
      0.06713422387838364,
      -0.0006167056853882968,
      -0.017212899401783943,
      -0.04848143085837364,
      -0.043867770582437515,
      0.007338027469813824,
      0.0880841314792633,
      -0.07735669612884521,
      0.09367960691452026,
      -0.08184026181697845,
      0.03154051676392555,
      0.021784678101539612,
      -0.0421009324491024,
      0.07061966508626938,
      0.011193384416401386,
      -0.006345082074403763,
      0.06093501299619675,
      0.04483763128519058,
      -0.012142786756157875,
      -0.017010487616062164,
      0.006640483625233173,
      0.05766668543219566,
      -0.031172549352049828,
      -0.0008837876375764608,
      -0.03061974048614502,
      -0.02483237162232399,
      -0.019781045615673065,
      0.01765245385468006,
      0.02133955806493759,
      -0.05838404968380928,
      -0.057403095066547394,
      0.02297842688858509,
      0.009871234185993671,
      -0.033292658627033234,
      0.02102234959602356,
      0.1351265013217926,
      0.005781908519566059,
      -0.08896225690841675,
      0.10297837108373642,
      0.046844594180583954,
      0.1294395923614502,
      -0.030824322253465652,
      -0.0006590894772671163,
      -0.0033627604134380817,
      -0.00497199734672904,
      0.005126633681356907,
      0.014448798261582851,
      -0.03690842166543007,
      0.019567593932151794,
      -0.006445030681788921,
      0.027357036247849464,
      0.012814520858228207,
      0.07192545384168625,
      -0.03201304376125336,
      0.007902049459517002,
      -0.025914359837770462,
      0.07049847394227982,
      0.01866746135056019,
      0.008462570607662201,
      -0.02859903872013092,
      -0.013449499383568764,
      -0.03668677806854248,
      0.021269554272294044,
      0.005236749537289143,
      0.0873940959572792,
      0.02531493827700615,
      0.04538822919130325,
      0.00154904427472502,
      -0.05124140530824661,
      0.06181534752249718,
      -0.05067194625735283,
      -0.025038767606019974,
      -0.04996601864695549,
      0.024168869480490685,
      -0.0006681787199340761,
      0.03558196499943733,
      0.010994264855980873,
      0.01821274496614933,
      0.06045464798808098,
      0.12641066312789917,
      0.05125842243432999,
      -0.05242524668574333,
      0.028022218495607376,
      9.822657011682168e-05,
      0.05787406861782074,
      -0.022755447775125504,
      0.016091758385300636,
      0.03947979211807251,
      0.008088693022727966,
      -0.0012690294533967972,
      -0.015351657755672932,
      -0.000831695506349206,
      0.07587036490440369,
      -0.09604671597480774,
      0.02146081253886223,
      -0.012345977127552032,
      0.0030765007250010967,
      0.016152217984199524,
      -0.0006992396665737033,
      -0.03245749697089195,
      0.08579002320766449,
      -0.008616381324827671,
      -0.00187152239959687,
      -0.0013870459515601397,
      -0.015354190021753311,
      0.023498203605413437,
      0.0364602729678154,
      0.005177102982997894,
      -0.00640207389369607,
      -0.028666716068983078,
      -0.025719517841935158,
      0.00966519583016634,
      0.04143095761537552,
      -0.10066000372171402,
      0.08303910493850708,
      -0.004901448264718056,
      0.028293829411268234
    ],
    [
      0.04907921701669693,
      0.091816745698452,
      -0.018166648223996162,
      0.07041479647159576,
      -0.0438569150865078,
      0.040249016135931015,
      0.05806005001068115,
      0.07478863000869751,
      0.035836853086948395,
      0.08788565546274185,
      -0.008841313421726227,
      0.05327017977833748,
      0.007577413693070412,
      -0.028472373262047768,
      0.05104535073041916,
      -0.028737429529428482,
      0.03744293376803398,
      -0.0692795142531395,
      -0.0050183190032839775,
      0.061791665852069855,
      0.023142458871006966,
      -0.03486429154872894,
      -0.009489210322499275,
      -0.003999566193670034,
      -0.001129487412981689,
      0.06526879966259003,
      -0.034401170909404755,
      -0.04905254766345024,
      -0.04282890632748604,
      -0.023660851642489433,
      0.06343573331832886,
      0.014685806818306446,
      -0.04905611649155617,
      -0.05089505761861801,
      -0.10983783006668091,
      -0.05475208908319473,
      0.027736030519008636,
      -0.03513171151280403,
      -0.0012193286092951894,
      0.029492441564798355,
      -0.0116551099345088,
      -0.020922880619764328,
      -0.062063299119472504,
      -0.02842777967453003,
      -0.03128592669963837,
      0.01773708686232567,
      -0.0015865728491917253,
      -0.06422112137079239,
      -0.04133523628115654,
      -0.04906832426786423,
      -0.05249401181936264,
      0.02476624585688114,
      0.013023916631937027,
      -0.011414230801165104,
      0.031139569357037544,
      0.024104304611682892,
      0.08716569095849991,
      -0.04155007749795914,
      -0.027912558987736702,
      -0.10112477838993073,
      0.041773926466703415,
      0.03263765573501587,
      -0.005282990168780088,
      0.026689056307077408,
      0.09325253963470459,
      -0.08443577587604523,
      -0.03471279516816139,
      0.005942204501479864,
      -0.039005350321531296,
      -0.06808815151453018,
      0.14122411608695984,
      0.02641264721751213,
      0.05534309893846512,
      0.10536801069974899,
      -0.011894511990249157,
      0.03508644923567772,
      0.01400821004062891,
      0.050102345645427704,
      0.07496299594640732,
      -0.03454018384218216,
      -0.03732527047395706,
      0.04323693737387657,
      -0.03878355398774147,
      0.02983270399272442,
      -0.0017238118452951312,
      -0.05135272443294525,
      -0.001071103848516941,
      -0.021385980769991875,
      -0.03748480603098869,
      -0.020718907937407494,
      -0.02652374468743801,
      0.049949776381254196,
      -0.012583031319081783,
      0.03731369227170944,
      0.016297530382871628,
      -0.032852549105882645,
      0.03744477778673172,
      0.1026611253619194,
      0.02773049660027027,
      -0.02614619769155979,
      -0.041878558695316315,
      0.0424005500972271,
      0.017425429075956345,
      0.03219422698020935,
      0.007982450537383556,
      -0.0096583878621459,
      0.0140923373401165,
      -0.0021892294753342867,
      0.030323466286063194,
      -0.01252052839845419,
      0.04700221121311188,
      0.07675710320472717,
      0.03326838091015816,
      0.06432347744703293,
      0.0743471160531044,
      0.03967764973640442,
      -0.06745512783527374,
      -0.002501382026821375,
      0.0059691863134503365,
      0.00509291747584939,
      0.0017245033523067832,
      0.025003191083669662,
      0.03156889230012894,
      0.08029399812221527,
      -0.0037361057475209236,
      0.007049670908600092,
      -0.02907077968120575,
      0.0413832813501358,
      -0.03387344256043434,
      0.03814178332686424,
      -0.03626224026083946,
      0.048924483358860016,
      0.06091117113828659,
      -0.018977636471390724,
      0.02465733513236046,
      0.0007533685420639813,
      0.008563155308365822,
      -0.008264506235718727,
      -0.06615212559700012,
      -0.05915815755724907,
      0.10980406403541565,
      -0.03398191183805466,
      -0.07855024188756943,
      0.048416201025247574,
      -0.04022517800331116,
      0.03832940757274628,
      -0.013517066836357117,
      -0.02457338199019432,
      0.012715790420770645,
      -0.07714138180017471,
      0.05179488658905029,
      0.015994969755411148,
      0.05926061421632767,
      0.01646469160914421,
      -0.07833736389875412,
      0.0252629853785038,
      -0.0007678867550566792,
      -0.0728108361363411,
      0.004606270231306553,
      -0.022498827427625656,
      -0.005573290400207043,
      -0.14823858439922333,
      -0.009048020467162132,
      -0.018225325271487236,
      -0.007714156527072191,
      0.01084255613386631,
      0.022787680849432945,
      0.05998736247420311,
      -0.06394332647323608,
      -0.02514784224331379,
      0.049637321382761,
      0.03640901669859886,
      -0.05362148582935333,
      -0.07837250083684921,
      -0.007690867409110069,
      0.05760746821761131,
      0.05910808593034744,
      0.03718675300478935,
      0.04011337831616402,
      0.0009102331823669374,
      -0.03993670269846916,
      -0.03379320725798607,
      -0.022946171462535858,
      0.02632593922317028,
      0.0022127532865852118,
      -0.0404529832303524,
      0.02635345235466957,
      -0.037746794521808624,
      0.03368023782968521,
      -0.1285497397184372,
      0.09503594785928726,
      -0.01834583841264248,
      0.00018829047621693462,
      0.052116163074970245,
      0.013778064399957657,
      0.047601111233234406,
      -0.01646234467625618,
      0.019925929605960846,
      -0.0053459941409528255,
      0.028933800756931305,
      -0.06048630550503731,
      0.06047847494482994,
      -0.04624715819954872,
      0.04042579233646393,
      0.025693144649267197,
      -0.007804657332599163,
      -0.028285352513194084,
      -0.06488949060440063,
      0.042244262993335724,
      0.014582115225493908,
      -0.03119630552828312,
      -0.031205542385578156,
      -0.0820719450712204,
      -0.049293018877506256,
      0.045832693576812744,
      0.016812095418572426,
      -0.044509146362543106,
      0.04460599273443222,
      0.06774549186229706,
      -0.03889896348118782,
      0.03242361918091774,
      0.07573629915714264,
      0.023958679288625717,
      -0.0543849878013134,
      0.018688246607780457,
      -0.0006584460497833788,
      0.13451142609119415,
      -0.011825276538729668,
      -0.05736220255494118,
      0.04933234676718712,
      -0.011479748412966728,
      -0.0687006264925003,
      -0.08246856927871704,
      0.12445563077926636,
      -0.027201851829886436,
      -0.0349554643034935,
      -0.025937262922525406,
      -0.05916134640574455,
      -0.009595482610166073,
      0.010619119741022587,
      -0.058225490152835846,
      -0.05019963160157204,
      -0.06390289962291718,
      -0.02648046426475048,
      -0.06380967050790787,
      0.09750070422887802,
      -0.03634442761540413,
      -0.012982405722141266,
      0.06556419283151627,
      0.0253240205347538,
      0.0396895632147789,
      -0.035595737397670746,
      0.01270802691578865,
      -0.03575382009148598,
      0.0072332993149757385,
      -0.0140879200771451,
      -0.032806117087602615,
      -0.016866516321897507,
      0.04083501920104027,
      -0.00718613900244236,
      0.03509322553873062,
      0.05998443812131882,
      -0.08456654846668243,
      0.02640029601752758,
      0.005241065751761198,
      -0.004308984614908695,
      0.017780443653464317,
      0.04860688000917435,
      0.0006158515461720526,
      0.025221392512321472,
      -0.04292275756597519,
      -0.04038074612617493,
      0.006231451872736216,
      0.014610369689762592,
      -0.0191948339343071,
      0.07974553853273392,
      -0.015276269987225533,
      0.030530238524079323,
      0.046450693160295486,
      -0.02092203125357628,
      -0.01110756304115057,
      -0.04226475954055786,
      0.06099102646112442,
      -0.0063722156919538975,
      -0.0594610869884491,
      0.000852643686812371,
      -0.0015469621866941452,
      0.0324445478618145,
      0.017934998497366905,
      0.016978276893496513,
      0.08286676555871964,
      -0.0717281699180603,
      -0.04166091978549957,
      -0.04860904812812805,
      -0.016663573682308197,
      0.01200556755065918,
      -0.0058844019658863544,
      -0.015952937304973602,
      -0.01257629320025444,
      0.012525632046163082,
      0.022338099777698517,
      0.060503557324409485,
      -0.02264837920665741,
      0.0584719181060791,
      -0.0024059521965682507,
      0.012487426400184631,
      -0.05299634486436844,
      -0.022101599723100662,
      -0.0056330254301428795,
      -0.029784275218844414,
      -0.037898555397987366,
      -0.06522141396999359,
      0.04526154696941376,
      -0.08768590539693832,
      -0.016320504248142242,
      0.003080254653468728,
      -0.024781178683042526,
      0.015922214835882187,
      0.02314806915819645,
      -0.0777520164847374,
      -0.016282904893159866,
      -0.07304363697767258,
      -0.04816148430109024,
      0.0005353508167900145,
      0.016864683479070663,
      -0.05539783090353012,
      0.016648277640342712,
      -0.015572022646665573,
      -0.03835779428482056,
      0.0036733567249029875,
      -0.01649431884288788,
      0.06868305057287216,
      0.014723175205290318,
      -0.0008907439187169075,
      0.03259463235735893,
      0.022522514685988426,
      -0.01762506179511547,
      0.11091608554124832,
      0.09076046198606491,
      0.020715130493044853,
      -0.017675302922725677,
      0.05717896670103073,
      0.01821683906018734,
      -0.005755600985139608,
      0.013690677471458912,
      0.02484513819217682,
      0.009992076084017754,
      0.06053561344742775,
      0.04283517599105835,
      0.004258675035089254,
      0.032563962042331696,
      0.03010834939777851,
      0.0006707957945764065,
      -0.026730164885520935,
      -0.0009776732185855508,
      -0.024014541879296303,
      0.03515039384365082,
      -0.06144837662577629,
      0.01440584659576416,
      -0.033029716461896896,
      -0.05047185719013214,
      0.005101763643324375,
      -0.061648447066545486,
      -0.048853375017642975,
      -0.052216969430446625,
      0.03388998657464981,
      0.07485950738191605,
      0.048689134418964386,
      0.03194442763924599,
      0.06380848586559296,
      0.05016441270709038,
      0.0024243509396910667,
      -0.09272167831659317,
      0.025140829384326935,
      -0.029054434970021248,
      0.004551095888018608,
      -0.011950326152145863,
      -0.0451304130256176,
      0.01146665308624506,
      0.13170768320560455,
      0.005883892998099327,
      0.08826584368944168,
      0.035812921822071075,
      0.008107985369861126,
      0.003167038084939122,
      0.043576788157224655,
      -0.08137653768062592,
      -0.007456765044480562,
      0.009770656004548073,
      -0.042959559708833694,
      -0.05113309621810913,
      -0.022767096757888794,
      0.04972509667277336,
      0.04047931730747223,
      0.05705242604017258,
      -0.07729092985391617,
      0.014168892987072468,
      -0.020503170788288116,
      0.02274574525654316,
      0.02489972487092018,
      -0.05819679796695709,
      0.045539867132902145,
      -0.034878797829151154,
      -0.08950728178024292,
      0.030142849311232567,
      -0.044956229627132416,
      0.04774598032236099,
      -0.003247283399105072,
      0.018364233896136284,
      -0.052964143455028534,
      0.012179494835436344,
      -0.0432579480111599,
      0.0031482232734560966,
      0.06820199638605118,
      0.00412842957302928,
      -0.01543241087347269,
      -0.044729121029376984,
      -0.024820592254400253,
      0.04989492893218994,
      0.046729881316423416,
      0.031484656035900116,
      0.07179725170135498,
      -0.018717115744948387,
      -0.008579781278967857,
      -0.04190117493271828,
      0.01984938234090805,
      -0.03275308385491371,
      0.028779717162251472,
      -0.033745501190423965,
      0.029957221820950508,
      -0.10706435889005661,
      -0.07862749695777893,
      0.033317066729068756,
      -0.06276927143335342,
      -0.07656201720237732,
      -0.0401715449988842,
      0.024333959445357323,
      0.0069983527064323425,
      -0.013393214903771877,
      -0.05692750960588455,
      0.04019400104880333,
      -0.017251623794436455,
      -0.034859586507081985,
      -0.04145791009068489,
      -0.043630633503198624,
      0.06456559151411057,
      -0.01371896080672741,
      -0.010323192924261093,
      -0.004490349907428026,
      -0.024855703115463257,
      0.0347597599029541,
      -0.013294833712279797,
      -0.030961934477090836,
      0.07752478122711182,
      0.01515817642211914,
      0.012381172738969326,
      -0.020391741767525673,
      -0.09156375378370285,
      -0.0035402721259742975,
      0.026516737416386604,
      0.04228954762220383,
      -0.007797405123710632,
      0.005202170927077532,
      -0.054772261530160904,
      0.05144340917468071,
      -0.000996061717160046,
      -0.04919220134615898,
      0.07229635864496231,
      0.03971836343407631,
      0.020063621923327446,
      0.019189808517694473,
      0.0026299632154405117,
      0.031245766207575798,
      0.0714980810880661,
      0.009909054264426231,
      -0.027538182213902473,
      -0.01653623767197132,
      0.05331577733159065,
      -0.05949432775378227,
      0.013893967494368553,
      0.003639367176219821,
      0.004007245879620314,
      -0.0539228580892086,
      0.015980582684278488,
      -0.01959030143916607,
      -0.03554832935333252,
      0.05035743489861488,
      0.02766125649213791,
      -0.04020562022924423,
      0.0018948098877444863,
      -0.02623964659869671,
      0.0828184112906456,
      -0.008414294570684433,
      -0.02641499601304531,
      -0.015607113018631935,
      -0.02253320813179016,
      0.054952312260866165,
      0.021535180509090424,
      0.012587456032633781,
      0.00322940805926919,
      0.01484152302145958,
      0.028396213427186012,
      -0.002050952520221472
    ],
    [
      0.05257805064320564,
      -0.044086333364248276,
      0.016781790181994438,
      0.012660403735935688,
      -0.0040572225116193295,
      0.023972144350409508,
      0.021990204229950905,
      0.033836204558610916,
      -0.03245116025209427,
      -0.0462958887219429,
      0.01394242886453867,
      -0.034045007079839706,
      -0.04107443243265152,
      0.041112639009952545,
      -0.005802985746413469,
      0.027381397783756256,
      0.005348111502826214,
      -0.07172363996505737,
      -0.023991864174604416,
      0.03284384682774544,
      -0.056214094161987305,
      -0.10360992699861526,
      0.0763094425201416,
      -0.002426943276077509,
      -0.05495629459619522,
      0.12352918088436127,
      0.004278479143977165,
      0.006141336169093847,
      -0.008313889615237713,
      0.06111588701605797,
      0.023158371448516846,
      0.027033008635044098,
      -0.01931924745440483,
      0.0028342611622065306,
      -0.020633909851312637,
      -0.05353449285030365,
      -0.0769396498799324,
      0.03881512209773064,
      -0.04343805089592934,
      0.038160283118486404,
      -0.009149385616183281,
      0.03895319998264313,
      0.029046671465039253,
      -0.008895831182599068,
      -0.018919968977570534,
      0.04273710772395134,
      0.012745711021125317,
      0.019524842500686646,
      -0.06368717551231384,
      0.027305111289024353,
      0.011949778534471989,
      0.00692028971388936,
      0.0464848130941391,
      -0.005304212216287851,
      -0.03223218023777008,
      -0.10944550484418869,
      -0.08681041747331619,
      -0.02050224505364895,
      -0.031422074884176254,
      0.02759748138487339,
      0.046618055552244186,
      0.08871418982744217,
      0.0447879321873188,
      -0.01998167112469673,
      -0.013509955257177353,
      -0.05048085004091263,
      0.04406493529677391,
      0.03722964972257614,
      -0.09086345881223679,
      0.016654595732688904,
      0.018465634435415268,
      -0.07047981768846512,
      -0.005568681750446558,
      0.007960550487041473,
      -0.11591053009033203,
      0.025466706603765488,
      -0.0891897901892662,
      0.08716429024934769,
      -0.04684761166572571,
      -0.08638262003660202,
      0.04535175487399101,
      -0.026691852137446404,
      -0.050431977957487106,
      0.05338258296251297,
      0.01812230609357357,
      -0.04125314578413963,
      0.11344479024410248,
      0.010032588616013527,
      -0.0027857886161655188,
      -0.07751723378896713,
      0.027819696813821793,
      0.014341252855956554,
      0.022457553073763847,
      0.023211508989334106,
      0.03846652805805206,
      -0.027553938329219818,
      0.06140564754605293,
      -0.05118386819958687,
      0.05579667165875435,
      -0.031223798170685768,
      -0.03469794988632202,
      -0.01261909119784832,
      -0.05733800306916237,
      0.020468518137931824,
      -0.040742550045251846,
      0.12081612646579742,
      -0.01930604688823223,
      0.014313925988972187,
      -0.05926467850804329,
      -0.018938886001706123,
      0.06823918223381042,
      0.048359815031290054,
      -0.030311215668916702,
      -0.012437566183507442,
      -0.0452914759516716,
      -0.0483187735080719,
      0.061102937906980515,
      -0.03947024792432785,
      0.020198874175548553,
      0.03631593659520149,
      -0.03977125510573387,
      0.06435434520244598,
      -0.04260217770934105,
      -0.010302861221134663,
      0.01806030422449112,
      0.031144190579652786,
      0.03294297307729721,
      0.03219565004110336,
      0.04710780456662178,
      0.07229083776473999,
      0.04209895431995392,
      -0.004954447504132986,
      0.11927834153175354,
      -0.031830403953790665,
      -0.040856994688510895,
      0.041618943214416504,
      0.04047446325421333,
      -0.0099114291369915,
      0.019965840503573418,
      0.050146277993917465,
      -0.014188737608492374,
      -0.057812631130218506,
      0.02663424052298069,
      0.0374162457883358,
      -0.022454621270298958,
      0.08147776126861572,
      -0.03367777168750763,
      -0.04987515136599541,
      -0.0014563826844096184,
      0.023265080526471138,
      0.024585021659731865,
      0.054106224328279495,
      0.028031915426254272,
      -0.013128731399774551,
      0.0224522203207016,
      0.02634422294795513,
      -0.02736341394484043,
      -0.020325303077697754,
      -0.004482613410800695,
      0.06534795463085175,
      0.03239445015788078,
      -0.013975812122225761,
      -0.0016140289371833205,
      -0.015955781564116478,
      0.07109176367521286,
      -0.12255499511957169,
      0.03183835744857788,
      0.0883956179022789,
      -0.007550237234681845,
      -0.01948811113834381,
      -0.10268981754779816,
      -0.12043885141611099,
      -0.020383715629577637,
      0.004351938143372536,
      0.03884345293045044,
      -0.039061740040779114,
      -0.0773363932967186,
      0.015024079941213131,
      0.04209672659635544,
      -0.0018754396587610245,
      -0.06581152230501175,
      -0.06405571103096008,
      -0.010036507621407509,
      0.0038282403256744146,
      -0.027358543127775192,
      -0.03191973268985748,
      -0.035935238003730774,
      0.015373095870018005,
      0.0406302735209465,
      -0.036197733134031296,
      -0.03264842554926872,
      -0.043184030801057816,
      -0.01199242565780878,
      -0.074628546833992,
      0.009384898468852043,
      -0.05850101634860039,
      -0.018855269998311996,
      0.008643927983939648,
      -0.06914056092500687,
      0.02917388826608658,
      0.016815613955259323,
      0.009119491092860699,
      -0.04248504340648651,
      0.09978614747524261,
      0.031794898211956024,
      0.038417473435401917,
      -0.05773136392235756,
      -0.039091721177101135,
      -0.01895959861576557,
      -0.06385981291532516,
      -0.033452652394771576,
      -0.009083140641450882,
      -0.02945520356297493,
      -0.045899633318185806,
      0.04070672020316124,
      -0.0429774634540081,
      0.03765367344021797,
      -0.020943352952599525,
      0.01177131850272417,
      -0.03757024183869362,
      0.03884898126125336,
      0.0819891020655632,
      -0.07645324617624283,
      0.08166623115539551,
      0.0035085557028651237,
      0.05821816995739937,
      -0.003133638296276331,
      0.041564375162124634,
      -0.08093535155057907,
      -0.010696036741137505,
      -0.0036860525142401457,
      0.0369395948946476,
      0.0456821508705616,
      -0.005532768089324236,
      -0.022469641640782356,
      -0.016231486573815346,
      -0.018641676753759384,
      0.04186622425913811,
      0.01665351912379265,
      -0.015691613778471947,
      -0.00391096156090498,
      -0.0479472354054451,
      -0.051099516451358795,
      -0.03619590401649475,
      0.028335366398096085,
      0.03466670215129852,
      0.02558484487235546,
      0.0351957343518734,
      -0.02547900564968586,
      -0.007164183538407087,
      0.04533880949020386,
      0.0034257913939654827,
      -0.016758227720856667,
      0.03239619731903076,
      0.07807924598455429,
      -0.05965835601091385,
      0.03564005717635155,
      0.004650956019759178,
      0.0723288282752037,
      0.03907744958996773,
      -0.044716041535139084,
      0.02858428657054901,
      -0.0292962659150362,
      0.04010118544101715,
      -0.009579835459589958,
      -0.03308046981692314,
      -0.0002481242408975959,
      -0.0041964249685406685,
      0.0752858892083168,
      0.04323336482048035,
      0.04578731581568718,
      -0.015804292634129524,
      -0.017508482560515404,
      -0.0039412337355315685,
      -0.05035479739308357,
      0.0012561763869598508,
      0.02521647699177265,
      -0.031232289969921112,
      -0.004059584811329842,
      0.04519883915781975,
      0.0034167927224189043,
      -0.03788251429796219,
      -0.06368738412857056,
      0.004831815138459206,
      0.03371737152338028,
      0.07219629734754562,
      -0.00044136904762126505,
      0.023590262979269028,
      -0.026273639872670174,
      -0.07769829779863358,
      0.06939037889242172,
      -0.06258025765419006,
      -0.06767619401216507,
      0.0073650432750582695,
      0.026736408472061157,
      -0.03694089129567146,
      0.06174265965819359,
      0.06413759291172028,
      0.058512281626462936,
      0.027592143043875694,
      0.010995850898325443,
      0.014292693696916103,
      -0.019186485558748245,
      0.0018425446469336748,
      0.03347377851605415,
      0.022042151540517807,
      0.0016605559503659606,
      0.023405317217111588,
      0.09379860758781433,
      -0.000840073567815125,
      -0.038756344467401505,
      -0.04243769869208336,
      0.002991294953972101,
      -0.1174810379743576,
      -0.03625064343214035,
      0.09804809838533401,
      0.019163094460964203,
      0.06812957674264908,
      0.005698507186025381,
      0.021775871515274048,
      -0.037437569350004196,
      -0.0036361191887408495,
      -0.04868525639176369,
      0.03546779230237007,
      -0.08015014976263046,
      0.0032634874805808067,
      0.06618452072143555,
      -0.06045200675725937,
      0.010900729335844517,
      0.033063992857933044,
      -0.0016676206141710281,
      -0.0010305247269570827,
      0.02345382422208786,
      0.025931965559720993,
      0.042365048080682755,
      0.019972534850239754,
      -0.011699794791638851,
      0.05166812613606453,
      -0.04999392107129097,
      0.08541978150606155,
      0.03845957666635513,
      0.045089103281497955,
      0.020047128200531006,
      -0.053171247243881226,
      -0.011244872584939003,
      0.06777886301279068,
      -0.04846398904919624,
      0.028435587882995605,
      -0.052092015743255615,
      0.022144360467791557,
      0.05138041451573372,
      0.07006791234016418,
      0.013554314151406288,
      -0.0406036451458931,
      0.0456027053296566,
      0.024893974885344505,
      -0.018445555120706558,
      -0.015946995466947556,
      0.04818718135356903,
      -0.0054369475692510605,
      -0.05765751004219055,
      0.05342743173241615,
      -0.04764142632484436,
      -0.14779984951019287,
      0.07899937033653259,
      0.003141608787700534,
      0.000408573163440451,
      -0.0658247172832489,
      0.049976129084825516,
      0.012728510424494743,
      -0.0039009947795420885,
      0.03785385191440582,
      0.04011058062314987,
      -0.01561079639941454,
      -0.04541245102882385,
      0.0071213217452168465,
      -0.020419057458639145,
      -0.07206862419843674,
      -0.029878506436944008,
      0.02959411032497883,
      0.0454423613846302,
      0.057395320385694504,
      -0.007033380679786205,
      0.031271979212760925,
      -0.017901984974741936,
      -0.008239606395363808,
      0.056939683854579926,
      -0.06959004700183868,
      -0.018117330968379974,
      -0.020550135523080826,
      -0.01032174937427044,
      -0.028615597635507584,
      0.07192676514387131,
      -0.02140486240386963,
      -0.034969206899404526,
      0.02218201383948326,
      -0.04580044746398926,
      -0.016143694519996643,
      -0.026931319385766983,
      0.013725032098591328,
      0.03967483714222908,
      -0.05747021362185478,
      -0.05711449682712555,
      0.03482760488986969,
      -0.024375686421990395,
      0.010166898369789124,
      0.03748215362429619,
      -0.05024659261107445,
      0.009069017134606838,
      -0.05860021710395813,
      -0.0015292681055143476,
      -0.001786990207619965,
      -0.039629071950912476,
      0.02809259667992592,
      0.020614253357052803,
      -0.026233067736029625,
      0.017716486006975174,
      0.058726780116558075,
      0.026329895481467247,
      -0.014605620875954628,
      0.058371808379888535,
      -0.028649307787418365,
      0.009652132168412209,
      -0.018771164119243622,
      0.03448114171624184,
      -0.049597758799791336,
      -0.028172574937343597,
      -0.08322454988956451,
      -0.0032065766863524914,
      -0.006874945946037769,
      -0.03328670561313629,
      0.00751328794285655,
      -0.04866655915975571,
      -0.019093947485089302,
      -0.03802793473005295,
      0.0027898792177438736,
      0.03856309875845909,
      -0.019002357497811317,
      0.0025242555420845747,
      0.007519958075135946,
      -0.027326498180627823,
      -0.024515587836503983,
      0.03630158305168152,
      0.037674155086278915,
      0.005603098776191473,
      0.024181334301829338,
      0.03915011137723923,
      -0.004129335284233093,
      -0.055287282913923264,
      -0.06356393545866013,
      -0.08805228769779205,
      0.03700372949242592,
      -0.06430546939373016,
      0.03894544020295143,
      -0.02762841247022152,
      -0.03331921249628067,
      0.021064866334199905,
      -0.013308080844581127,
      0.05202121660113335,
      -0.06779646873474121,
      -0.012345572002232075,
      0.054160960018634796,
      -0.04483787715435028,
      0.03325371444225311,
      -0.05486363172531128,
      -0.004722392186522484,
      -0.03437871113419533,
      0.014752312563359737,
      -0.05776538327336311,
      -0.02107042260468006,
      0.04399825632572174,
      -0.009721776470541954,
      -0.03458801284432411,
      0.00015687174163758755,
      -0.03515825793147087,
      -0.013070188462734222,
      0.0730968564748764,
      0.054576095193624496,
      -0.050739750266075134,
      0.04416486993432045,
      0.015209663659334183,
      0.0017989779589697719,
      -0.0305237527936697,
      0.030662527307868004,
      -0.005663211457431316,
      0.04485807567834854,
      -0.05486201122403145,
      -0.12012829631567001,
      0.02914953976869583,
      0.012822221033275127,
      0.02690191939473152,
      0.02746984362602234,
      0.0069717117585241795,
      0.0149649353697896,
      0.0370757170021534,
      -0.016851291060447693,
      -0.08377502858638763,
      0.011082078330218792,
      0.027046753093600273,
      0.04441923275589943,
      0.017552979290485382,
      0.035518109798431396,
      0.05544909089803696
    ],
    [
      0.1048102155327797,
      -0.010620463639497757,
      0.07258760929107666,
      0.016754472628235817,
      0.027741916477680206,
      0.009690550155937672,
      -0.007601435761898756,
      0.03117823414504528,
      -0.062146011739969254,
      -0.07695894688367844,
      0.028773494064807892,
      -0.02046487294137478,
      -0.018795769661664963,
      -0.020417077466845512,
      -0.02992527186870575,
      -0.026177970692515373,
      0.027357958257198334,
      -0.02562999539077282,
      -0.10094242542982101,
      -0.031902849674224854,
      0.04667846858501434,
      -0.11553677916526794,
      -0.028964262455701828,
      0.02795245125889778,
      0.05637948960065842,
      0.03731924667954445,
      0.07557117193937302,
      0.01748461090028286,
      -0.028703950345516205,
      -0.018221218138933182,
      -0.0011279676109552383,
      -0.0010582891991361976,
      -0.02220332808792591,
      -0.0047440133057534695,
      -0.004247742239385843,
      0.03692743927240372,
      -0.08471480011940002,
      -0.032155316323041916,
      -0.004311172291636467,
      -0.06002405658364296,
      0.02220228686928749,
      -0.11179855465888977,
      -0.10752145946025848,
      -0.040665946900844574,
      0.01882557012140751,
      0.09136104583740234,
      -0.022764800116419792,
      0.009575719945132732,
      0.010534321889281273,
      0.05384829267859459,
      -0.04926339164376259,
      -0.08677075803279877,
      0.007577348500490189,
      0.04848414659500122,
      0.059796955436468124,
      -0.019522614777088165,
      -0.025038808584213257,
      -0.011636600829660892,
      0.03237413987517357,
      0.04543211683630943,
      0.034576695412397385,
      -0.0458785779774189,
      -0.0060424585826694965,
      0.05094154179096222,
      0.05535169318318367,
      -0.021454449743032455,
      0.04091900214552879,
      -0.007399571128189564,
      0.033043935894966125,
      -0.003347508842125535,
      -0.05531499162316322,
      -0.01798415370285511,
      -0.014013858512043953,
      -0.028101671487092972,
      -0.014235586859285831,
      -0.037790387868881226,
      -0.01795758679509163,
      -0.03496379777789116,
      -0.024946577847003937,
      0.02091224119067192,
      -0.05327282473444939,
      0.04811882972717285,
      -0.046026941388845444,
      -0.03835495561361313,
      -0.052260883152484894,
      -0.09268544614315033,
      -0.02422983944416046,
      0.009536282159388065,
      -0.02568098157644272,
      -0.009127119556069374,
      -0.07659401744604111,
      -0.05351750925183296,
      0.06277411431074142,
      -0.0582202784717083,
      0.030147241428494453,
      -0.03077745996415615,
      -0.03457850590348244,
      -0.0912625640630722,
      0.04400636628270149,
      0.02514076605439186,
      0.06339076161384583,
      -0.06987795233726501,
      0.009532873518764973,
      -0.04184740409255028,
      0.07008282840251923,
      0.01293984241783619,
      0.05150784179568291,
      -0.01731385476887226,
      0.09735091775655746,
      -0.010510295629501343,
      0.04898282513022423,
      -0.003252011025324464,
      -0.025833357125520706,
      0.04087742790579796,
      0.02488650381565094,
      0.033449213951826096,
      0.025932446122169495,
      0.018751632422208786,
      -0.0136720547452569,
      0.005334265995770693,
      0.03516995906829834,
      -0.07418884336948395,
      -0.024319332093000412,
      0.007456320803612471,
      -0.03489771485328674,
      -0.018985027447342873,
      0.07994350790977478,
      -0.0070163169875741005,
      0.06519077718257904,
      -0.014812764711678028,
      -0.06256425380706787,
      0.04227745160460472,
      0.06954633444547653,
      -0.022819770500063896,
      0.007620959542691708,
      -0.06220449507236481,
      -0.08226875215768814,
      0.045679908245801926,
      0.04141686484217644,
      0.033406637609004974,
      -0.057874470949172974,
      -0.04896070063114166,
      0.0907803326845169,
      0.06668178737163544,
      0.029906615614891052,
      0.06785347312688828,
      -0.07346745580434799,
      0.05529758706688881,
      -0.007329891435801983,
      0.04154646396636963,
      0.054960597306489944,
      -0.02025112323462963,
      0.008351153694093227,
      -0.06410200148820877,
      -0.000280188542092219,
      0.05935340002179146,
      0.06917473673820496,
      -0.16302801668643951,
      0.04140738025307655,
      0.06901245564222336,
      0.036345332860946655,
      0.1070297583937645,
      0.011340295895934105,
      -0.11788693815469742,
      -0.028006140142679214,
      0.010809252969920635,
      0.0388781875371933,
      -0.011333824135363102,
      0.03872203826904297,
      0.00015359184180852026,
      0.113247349858284,
      -0.032738059759140015,
      0.02811446227133274,
      0.0013185030547901988,
      0.03619660437107086,
      0.05878697335720062,
      0.012230395339429379,
      -0.0020478530786931515,
      0.029233688488602638,
      -0.03116723708808422,
      -0.014333825558423996,
      -0.10304617881774902,
      0.033614013344049454,
      -0.01640949584543705,
      0.10405073314905167,
      0.024602513760328293,
      0.02412041276693344,
      -0.04564887285232544,
      -0.003798461752012372,
      -0.0002034766657743603,
      0.025637853890657425,
      -0.027212604880332947,
      0.012913131155073643,
      0.07915013283491135,
      0.033016085624694824,
      -0.037250567227602005,
      -0.15261800587177277,
      -0.04013223573565483,
      0.020783718675374985,
      -0.020189300179481506,
      -0.03341030701994896,
      0.001036556321196258,
      -0.03155483677983284,
      -0.048971280455589294,
      -0.016041621565818787,
      0.02765621431171894,
      -0.08819268643856049,
      0.009427637793123722,
      0.02696988917887211,
      -0.034186117351055145,
      -0.09148741513490677,
      -0.04447771608829498,
      0.10587376356124878,
      -0.02252768725156784,
      0.10176451504230499,
      0.031781017780303955,
      -0.04819754883646965,
      0.016784805804491043,
      0.02944735251367092,
      -0.0009854340460151434,
      -0.03522859141230583,
      0.03734017163515091,
      -0.037528008222579956,
      -0.02605881541967392,
      0.018266994506120682,
      -0.060017138719558716,
      -0.01034048292785883,
      0.0028601435478776693,
      -0.04023929685354233,
      -0.04672237113118172,
      0.020071586593985558,
      -0.05917540565133095,
      0.08813127875328064,
      0.018936246633529663,
      -0.019233720377087593,
      -0.01534739788621664,
      0.012134580872952938,
      -0.0018332665786147118,
      -0.038864199072122574,
      0.08311571180820465,
      0.0008217477006837726,
      -0.006192383356392384,
      0.034958697855472565,
      0.05738624930381775,
      0.020848240703344345,
      -0.009296814911067486,
      0.0017863657558336854,
      0.016530519351363182,
      -0.012936442159116268,
      0.005227959249168634,
      0.0357489138841629,
      -0.05731833353638649,
      -0.06416598707437515,
      -0.04058884084224701,
      0.00027421725098975003,
      0.06747549027204514,
      -0.017436329275369644,
      0.11280069500207901,
      0.03256260231137276,
      0.0640074759721756,
      -0.02381543628871441,
      0.0011376864276826382,
      -0.04759543389081955,
      -0.009768580086529255,
      0.035299308598041534,
      0.012132130563259125,
      -0.01982562243938446,
      0.013074464164674282,
      0.08673800528049469,
      -0.01623329147696495,
      -0.09471438080072403,
      -0.031941212713718414,
      0.04743042215704918,
      -0.04248535633087158,
      -0.0707625076174736,
      -0.010316350497305393,
      -0.009079394862055779,
      0.051463399082422256,
      -0.07331115007400513,
      0.08284729719161987,
      -0.003166476497426629,
      -0.04819235950708389,
      -0.011238206177949905,
      -0.020320244133472443,
      -0.008383420296013355,
      -0.03559011220932007,
      -0.027932720258831978,
      0.018990416079759598,
      -0.0553506538271904,
      -0.007530395407229662,
      0.006655238568782806,
      -0.07001639902591705,
      0.04115800932049751,
      -0.06556553393602371,
      -0.07671981304883957,
      0.007606708910316229,
      -0.05044316500425339,
      0.136213481426239,
      -0.003594496753066778,
      -0.020044179633259773,
      0.04069402068853378,
      -0.06292515248060226,
      0.018267016857862473,
      0.008050918579101562,
      0.0036919040139764547,
      0.00023353428696282208,
      -0.024635929614305496,
      -0.08241105079650879,
      0.044414035975933075,
      -0.04843932390213013,
      -0.02629856951534748,
      -0.009220119565725327,
      0.06318660825490952,
      -0.025327030569314957,
      0.044686198234558105,
      0.014482831582427025,
      -0.009513438679277897,
      -0.022112522274255753,
      0.034726664423942566,
      0.010306321084499359,
      -0.0009515649289824069,
      0.01710178703069687,
      -0.032269347459077835,
      0.005110399331897497,
      0.1278844177722931,
      -0.01593080908060074,
      0.08817378431558609,
      0.02157577872276306,
      0.044236283749341965,
      0.0401715524494648,
      0.026960160583257675,
      0.024203134700655937,
      0.010020081885159016,
      0.004843487404286861,
      0.030019156634807587,
      0.060787200927734375,
      0.010097449645400047,
      0.04712110012769699,
      0.02503378875553608,
      0.05891578271985054,
      0.04715421050786972,
      -0.015422037802636623,
      0.0630795881152153,
      0.07742609083652496,
      -0.05779282748699188,
      0.002987270476296544,
      -0.03484433889389038,
      -0.007606698200106621,
      0.03308328613638878,
      -0.0456620529294014,
      0.08930348604917526,
      -0.04391705244779587,
      0.04349149018526077,
      -0.03672521561384201,
      0.02580399438738823,
      -0.022602731361985207,
      0.017178351059556007,
      -0.014644361101090908,
      0.026037096977233887,
      -0.07431254535913467,
      -0.0947447121143341,
      0.05819874256849289,
      0.0029449451249092817,
      -0.007680313196033239,
      -0.07278613746166229,
      -0.00784670002758503,
      0.04853923246264458,
      0.057200562208890915,
      -0.007490320131182671,
      0.03249537944793701,
      -0.00243765558116138,
      -0.07516530901193619,
      0.02250431664288044,
      -0.056341808289289474,
      0.03248555585741997,
      0.05929834395647049,
      0.054504331201314926,
      -0.00041844008956104517,
      0.08677078038454056,
      -0.00011909906606888399,
      0.023071778938174248,
      -0.0014570964267477393,
      0.014479351229965687,
      0.01994587481021881,
      -0.050343822687864304,
      0.010702071711421013,
      -0.01836111769080162,
      0.009715563617646694,
      0.009796963073313236,
      0.0002064346190309152,
      -0.03507312759757042,
      -0.011945079080760479,
      -0.04404701665043831,
      0.008244254626333714,
      -0.026624733582139015,
      0.0531608946621418,
      -0.011461179703474045,
      -0.03842127323150635,
      -0.06413773447275162,
      -0.006133554503321648,
      0.07124625891447067,
      0.04090825095772743,
      0.03250780329108238,
      0.021033713594079018,
      -0.039371564984321594,
      -0.06121355667710304,
      -0.04285956546664238,
      0.07259039580821991,
      -0.0189692210406065,
      0.010139817371964455,
      -0.011431369930505753,
      0.017104707658290863,
      -0.003591870656237006,
      -0.0009647214901633561,
      -0.014891993254423141,
      0.021068651229143143,
      0.005570107139647007,
      0.025424012914299965,
      0.025429697707295418,
      0.01740885153412819,
      0.0030192870181053877,
      0.013630416244268417,
      0.055465299636125565,
      0.05826551467180252,
      -0.0020424434915184975,
      0.02940288372337818,
      0.0014351175632327795,
      0.005879554897546768,
      -0.05438212677836418,
      0.0036864306312054396,
      -0.04138880595564842,
      -0.04423919692635536,
      0.017221733927726746,
      -0.007812727242708206,
      0.06221400573849678,
      -0.13118161261081696,
      -0.04877853766083717,
      0.03170478716492653,
      -0.0014727158704772592,
      -0.046073317527770996,
      0.024885613471269608,
      -0.03076441027224064,
      0.008958178572356701,
      0.061224330216646194,
      0.00454955268651247,
      0.030644338577985764,
      -0.1400132030248642,
      0.032182611525058746,
      -0.07867852598428726,
      0.04458433762192726,
      -0.018145203590393066,
      0.031003963202238083,
      0.04889823868870735,
      0.012192854657769203,
      0.087863489985466,
      0.021159837022423744,
      -0.012960603460669518,
      0.02178110182285309,
      -0.04408520087599754,
      0.08830274641513824,
      0.01817803829908371,
      0.0791158452630043,
      -0.051363468170166016,
      0.001500524114817381,
      -0.018310770392417908,
      -0.024388717487454414,
      -0.030461199581623077,
      0.002629293594509363,
      -0.058889277279376984,
      -0.08380920439958572,
      -0.03271237388253212,
      0.03113863430917263,
      -0.04846467822790146,
      0.027857521548867226,
      0.045005615800619125,
      -0.07593511790037155,
      0.03886584937572479,
      -0.03576912358403206,
      -0.039902422577142715,
      -0.027801791206002235,
      0.0129726966843009,
      -0.025387654080986977,
      0.05422133952379227,
      -0.00898689217865467,
      0.010462458245456219,
      0.009622091427445412,
      0.054737888276576996,
      -0.04044770076870918,
      -0.014924156479537487,
      0.019524743780493736,
      -0.01983136497437954,
      0.0040817116387188435,
      0.002340402454137802,
      -0.029031902551651,
      -0.0003935421700589359,
      -0.004460831172764301,
      -0.041709281504154205,
      0.017041359096765518,
      0.06100188195705414,
      -0.07002165913581848,
      -0.015131611377000809,
      -0.04275595024228096,
      0.016989799216389656
    ],
    [
      0.06184959411621094,
      -0.00019331196381244808,
      0.00609203614294529,
      0.0005837942590005696,
      0.08117849379777908,
      0.021191991865634918,
      0.033344075083732605,
      0.024540824815630913,
      -0.05141168832778931,
      -0.029712341725826263,
      0.009432366117835045,
      0.07281895726919174,
      -0.018933596089482307,
      0.005644237622618675,
      0.035637274384498596,
      0.006136429030448198,
      0.04384220764040947,
      0.018100323155522346,
      -0.023834645748138428,
      0.006202811375260353,
      -0.03692051023244858,
      -0.016071029007434845,
      -0.02524677850306034,
      -0.10062425583600998,
      0.021813424304127693,
      0.037529315799474716,
      -0.05386422947049141,
      -0.08345046639442444,
      0.039401184767484665,
      0.024194275960326195,
      -0.00045922177378088236,
      0.06303700804710388,
      -0.01312980242073536,
      0.062115393579006195,
      0.0698951855301857,
      0.04584990814328194,
      -0.021132921800017357,
      -0.04422501474618912,
      0.009449487552046776,
      -0.04890400916337967,
      -0.012922052294015884,
      0.01969611831009388,
      -0.07889778167009354,
      -0.061018381267786026,
      0.03837675601243973,
      0.03980366513133049,
      -0.016535041853785515,
      0.03107881173491478,
      0.012264617718756199,
      -0.1194605827331543,
      -0.03573282063007355,
      0.0389535054564476,
      -0.025294313207268715,
      -0.10458562523126602,
      -0.04004038870334625,
      -0.01709136553108692,
      -0.009343018755316734,
      -0.0011157557601109147,
      0.010992847383022308,
      -0.001981280744075775,
      -0.0210258848965168,
      0.009491842240095139,
      0.025305310264229774,
      -0.004819376394152641,
      -0.010510940104722977,
      0.011182889342308044,
      0.069590725004673,
      0.00907117873430252,
      0.06380508840084076,
      -0.029203588142991066,
      -0.04680382087826729,
      0.015070123597979546,
      -0.04792720824480057,
      -0.05592769384384155,
      0.05236507207155228,
      -0.025610169395804405,
      -0.02049895189702511,
      0.023669714108109474,
      -0.043248943984508514,
      0.023718342185020447,
      0.011633411981165409,
      -0.037797704339027405,
      -0.005940692964941263,
      -0.0490034855902195,
      -0.021734943613409996,
      0.035818763077259064,
      0.047255776822566986,
      -0.0201411172747612,
      0.11818358302116394,
      -0.02237250655889511,
      -0.028223607689142227,
      -0.026657551527023315,
      -0.011524525471031666,
      0.03926050290465355,
      -0.020023861899971962,
      0.05438876152038574,
      -0.013819841668009758,
      -0.015838654711842537,
      0.08046609163284302,
      0.048259858042001724,
      0.016687490046024323,
      -0.10374171286821365,
      0.05425483360886574,
      0.013168485835194588,
      -0.006661019753664732,
      0.016983680427074432,
      0.025409862399101257,
      -0.05376914143562317,
      0.002667935099452734,
      -0.019192546606063843,
      0.06342791020870209,
      -0.007441224996000528,
      -0.02897152118384838,
      -0.07251030951738358,
      -0.09742134064435959,
      -0.02383626252412796,
      0.02041892521083355,
      -0.025171183049678802,
      0.001474487129598856,
      -0.015509035438299179,
      -0.0033187835942953825,
      -0.03735063970088959,
      0.05426046997308731,
      0.03144356608390808,
      0.03721980005502701,
      0.019636372104287148,
      0.002253975486382842,
      -0.0053975535556674,
      0.05035017058253288,
      -0.002738397801294923,
      0.017397677525877953,
      -0.0431346669793129,
      -0.008674097247421741,
      0.02493339776992798,
      0.04446684196591377,
      0.012919025495648384,
      0.02861521951854229,
      0.04284349083900452,
      -0.039299484342336655,
      -0.009326886385679245,
      -0.04067688435316086,
      -0.06998854130506516,
      -0.017179567366838455,
      -0.03407249227166176,
      -0.043508414179086685,
      -0.019113857299089432,
      0.01427791640162468,
      -0.033701132982969284,
      0.025998856872320175,
      -0.03331451490521431,
      -0.009459774009883404,
      0.03477536886930466,
      0.005052725784480572,
      0.0072638122364878654,
      0.017324700951576233,
      0.05185466259717941,
      0.03743533045053482,
      -0.048240501433610916,
      0.057241518050432205,
      -0.03490976244211197,
      -0.0053293826058506966,
      -0.05610469728708267,
      -0.006204960402101278,
      0.016916126012802124,
      0.025081513449549675,
      0.0637003481388092,
      -0.03191337734460831,
      -0.06999145448207855,
      0.02289286069571972,
      0.04134618490934372,
      -0.025415150448679924,
      -0.02595292031764984,
      -0.04552853852510452,
      -0.0027288312558084726,
      -0.02285560593008995,
      0.006215557921677828,
      -0.006444723345339298,
      -0.014441204257309437,
      -0.09634263068437576,
      -0.01754477247595787,
      0.01611335575580597,
      -0.09792347252368927,
      -0.04560632258653641,
      0.06293131411075592,
      -0.02724764682352543,
      -0.005298460368067026,
      -0.03977891802787781,
      -0.03558732569217682,
      0.03189079090952873,
      -0.014782395213842392,
      0.0023814185988157988,
      -0.020745841786265373,
      -0.029587397351861,
      -0.052045319229364395,
      -0.07875169813632965,
      0.07603657990694046,
      -0.03486047685146332,
      0.009974508546292782,
      0.019096659496426582,
      -0.094784677028656,
      -0.05136852711439133,
      -0.007682252209633589,
      -0.053811125457286835,
      -0.03246157243847847,
      0.03328051418066025,
      0.058628108352422714,
      0.01938934437930584,
      -0.021747253835201263,
      -0.02634657733142376,
      -0.10088041424751282,
      0.03611011058092117,
      -0.03995839133858681,
      0.02557270973920822,
      -0.020848941057920456,
      -0.011193672195076942,
      -0.039532098919153214,
      -0.027847895398736,
      0.023070184513926506,
      -0.07995925098657608,
      0.08381181210279465,
      0.016110148280858994,
      0.03326292708516121,
      -0.11499928683042526,
      -0.0006959985475987196,
      -0.051282864063978195,
      0.013727977871894836,
      0.03147335350513458,
      0.007065522018820047,
      0.02203948237001896,
      0.00011187492054887116,
      -0.033486559987068176,
      0.06679283082485199,
      0.07391054928302765,
      -0.010510611347854137,
      -0.04299158602952957,
      0.04536665976047516,
      -0.08945847302675247,
      0.03045925498008728,
      0.0024609798565506935,
      0.005279529374092817,
      -0.01769045926630497,
      -0.017058396711945534,
      -0.05516647547483444,
      -0.006957286037504673,
      0.036420781165361404,
      0.028505638241767883,
      -0.14427757263183594,
      0.052712615579366684,
      -0.01633351854979992,
      0.010972650721669197,
      0.08829639852046967,
      -0.03410172462463379,
      -0.05994356796145439,
      0.008195060305297375,
      -0.0026471922174096107,
      0.02103613130748272,
      0.04763708636164665,
      0.013215893879532814,
      -0.009505048394203186,
      0.013703654520213604,
      -0.06135260686278343,
      0.03401632606983185,
      0.03937937319278717,
      0.04357115924358368,
      0.003986806143075228,
      0.01743658073246479,
      0.0733199417591095,
      0.024783847853541374,
      -0.04172728210687637,
      0.06334006786346436,
      0.03161080554127693,
      -0.11992792785167694,
      0.03744027391076088,
      -0.08085042238235474,
      -0.007969243451952934,
      0.04257185757160187,
      -0.06745364516973495,
      -0.04175538197159767,
      0.070838063955307,
      0.017960233613848686,
      0.063368059694767,
      0.011475468054413795,
      0.08776406943798065,
      -0.050197940319776535,
      -0.038429901003837585,
      -0.030344463884830475,
      0.05240943282842636,
      -0.025064388290047646,
      0.007564560044556856,
      -0.07340908795595169,
      0.05373987555503845,
      0.015052616596221924,
      0.11328010261058807,
      -0.04362685978412628,
      -0.026245571672916412,
      0.01770378276705742,
      0.01321612112224102,
      -0.014099030755460262,
      -0.02455434948205948,
      0.01685474067926407,
      -0.027816370129585266,
      -0.05807708948850632,
      0.03782709315419197,
      -0.050426360219717026,
      0.05226343497633934,
      -0.012759418226778507,
      0.04483445733785629,
      -0.03113800473511219,
      -0.03736109286546707,
      -0.04724288359284401,
      -0.08379758149385452,
      -0.02354002557694912,
      -0.06647301465272903,
      0.01835734024643898,
      0.002371154958382249,
      0.03940233960747719,
      -0.07421597838401794,
      0.003104421542957425,
      -0.008581017144024372,
      0.04574156180024147,
      0.0028320257551968098,
      0.0689152404665947,
      0.01759038306772709,
      0.016594387590885162,
      0.06241727992892265,
      -0.0037930209655314684,
      -0.010466405190527439,
      0.0015630113193765283,
      -0.010866092517971992,
      -0.012800500728189945,
      0.021257825195789337,
      -0.06991466134786606,
      0.021594535559415817,
      0.0051563940942287445,
      0.037269227206707,
      0.029409125447273254,
      -0.00225746538490057,
      0.0507325604557991,
      0.009062831290066242,
      0.04390323534607887,
      -0.08976180851459503,
      -0.00581112178042531,
      -0.0009579096804372966,
      0.03327736631035805,
      -0.03671601042151451,
      0.015659920871257782,
      -0.04364926740527153,
      0.023016497492790222,
      0.013655709102749825,
      -0.04298790916800499,
      0.009695645421743393,
      -0.04365180805325508,
      0.08867628127336502,
      0.021746495738625526,
      -0.014092840254306793,
      -0.03929295018315315,
      -0.05492996424436569,
      -0.08261477202177048,
      -0.009757976047694683,
      -0.040380485355854034,
      0.06643423438072205,
      0.02654918096959591,
      -0.004888070281594992,
      0.07063587754964828,
      -0.018049700185656548,
      0.011404432356357574,
      -0.01641787216067314,
      0.008735662326216698,
      0.021817905828356743,
      -0.023189561441540718,
      -0.024993261322379112,
      0.037603460252285004,
      0.04845663532614708,
      -0.07333534955978394,
      0.020899314433336258,
      0.007665533572435379,
      -0.01795169524848461,
      0.030957484617829323,
      0.05745977908372879,
      -0.09015915542840958,
      0.03456173464655876,
      0.02166423387825489,
      0.057458389550447464,
      -0.020153779536485672,
      -0.0683547779917717,
      0.0129421167075634,
      -0.0015539643354713917,
      0.07144541293382645,
      -0.0034525911323726177,
      -0.04964960366487503,
      -0.023842796683311462,
      -0.03541414812207222,
      0.037202220410108566,
      -0.027641896158456802,
      -0.02221209555864334,
      0.07226362824440002,
      -0.06901779770851135,
      0.06209371238946915,
      0.04546531289815903,
      0.001686926931142807,
      -0.030471520498394966,
      0.027105383574962616,
      0.041782163083553314,
      -0.011144168674945831,
      -0.055612362921237946,
      -0.03441097214818001,
      0.0012776372022926807,
      0.01883099041879177,
      0.08192068338394165,
      -0.030216673389077187,
      -0.013729948550462723,
      -0.044364482164382935,
      0.025373371317982674,
      0.01670227386057377,
      0.014429911971092224,
      -0.040242597460746765,
      -0.03279861807823181,
      -0.022771267220377922,
      -0.013348895125091076,
      -0.05787736549973488,
      0.015868529677391052,
      0.0623030811548233,
      -0.02067464217543602,
      -0.027203476056456566,
      0.013873422518372536,
      0.060861703008413315,
      0.08753999322652817,
      0.033084601163864136,
      0.012338059023022652,
      -0.02041746862232685,
      0.07993350178003311,
      -0.030504364520311356,
      0.07673612982034683,
      -0.048369549214839935,
      -0.04287394508719444,
      -0.10600414127111435,
      0.008662545122206211,
      0.0028839532751590014,
      -0.01754503883421421,
      -0.025606052950024605,
      0.008814200758934021,
      0.04870269447565079,
      -0.0030929690692573786,
      0.03583553060889244,
      0.01661963015794754,
      0.018560416996479034,
      0.004992122296243906,
      0.012174605391919613,
      0.014840198680758476,
      0.03505558893084526,
      0.01853540912270546,
      -0.0727241188287735,
      -0.00622167345136404,
      0.004690190311521292,
      -0.06646865606307983,
      0.026584196835756302,
      0.02236197143793106,
      0.03977997973561287,
      -0.0067788343876600266,
      0.07032749801874161,
      -0.0593014620244503,
      0.07258930057287216,
      0.001940668560564518,
      -0.051316168159246445,
      -0.0015271671582013369,
      0.0021466310136020184,
      -0.09439842402935028,
      -0.010280340909957886,
      0.006356005556881428,
      0.0673586055636406,
      0.03296368941664696,
      0.0270297322422266,
      0.052697792649269104,
      0.032632213085889816,
      0.08125241100788116,
      0.0429353341460228,
      -0.05575336515903473,
      -0.05036783963441849,
      0.004806597717106342,
      -0.06904775649309158,
      0.029487835243344307,
      -0.05609679967164993,
      -0.05226651579141617,
      -0.00816246960312128,
      0.038582295179367065,
      -0.06572815030813217,
      0.035940416157245636,
      0.056255463510751724,
      -0.07990655303001404,
      -0.04949890449643135,
      -0.022867897525429726,
      -0.0664258673787117,
      0.012021892704069614,
      -0.043119993060827255,
      0.06880210340023041,
      -0.03511064499616623,
      0.043607693165540695,
      0.009790487587451935,
      -0.023994045332074165,
      -0.025357482954859734,
      -0.05479118227958679,
      0.0069059329107403755,
      0.054960306733846664
    ],
    [
      -0.015630507841706276,
      0.017201952636241913,
      0.019356748089194298,
      -0.057240117341279984,
      0.022239945828914642,
      0.061254460364580154,
      -0.08534575998783112,
      -0.004933021496981382,
      -0.012610784731805325,
      0.0036580339074134827,
      -0.012747427448630333,
      0.03618348389863968,
      0.027132831513881683,
      -0.020132631063461304,
      -0.015383698977530003,
      -0.025017252191901207,
      -0.04826359450817108,
      -0.050969209522008896,
      -0.013637768104672432,
      0.036596719175577164,
      -0.10969041287899017,
      0.07409476488828659,
      0.0617084875702858,
      0.0377008430659771,
      0.026495400816202164,
      -0.004585231188684702,
      -0.022443652153015137,
      -0.04740113392472267,
      -0.04534713178873062,
      -0.025938063859939575,
      -0.023182440549135208,
      0.02760552242398262,
      -0.002886079251766205,
      -0.02280292473733425,
      -0.025038786232471466,
      -0.05769302695989609,
      0.0063317688181996346,
      -0.09805157780647278,
      0.01637335866689682,
      0.06456615030765533,
      0.0792030543088913,
      -0.04949280992150307,
      -0.03723956272006035,
      0.019170429557561874,
      0.013438221998512745,
      0.036438874900341034,
      0.05531750246882439,
      -0.0528988279402256,
      -0.025162547826766968,
      0.035490263253450394,
      0.012211338616907597,
      0.08539656549692154,
      -0.03803032264113426,
      -0.03386829048395157,
      0.03674693778157234,
      -0.003482010681182146,
      0.04296276345849037,
      -0.006384550593793392,
      0.04287363961338997,
      0.04593580216169357,
      -0.10111693292856216,
      0.02865491434931755,
      -0.06845664232969284,
      0.011225695721805096,
      0.0024617589078843594,
      -0.03494569659233093,
      -0.02010900154709816,
      0.001990812597796321,
      0.07108556479215622,
      -0.05525818467140198,
      -0.08710881322622299,
      -0.037944547832012177,
      0.016561485826969147,
      0.051720600575208664,
      -0.08579494059085846,
      -0.04453282430768013,
      0.045472852885723114,
      -0.0017346071545034647,
      -0.008268235251307487,
      0.008357565850019455,
      -0.007828125730156898,
      0.005529590416699648,
      -0.02312931790947914,
      -0.027715306729078293,
      -0.034714289009571075,
      -0.017461808398365974,
      0.028127988800406456,
      0.03324545919895172,
      -0.00921909511089325,
      -0.027424897998571396,
      -0.07586700469255447,
      0.02654716745018959,
      0.019173474982380867,
      0.0899815484881401,
      0.07092831283807755,
      -0.059196483343839645,
      -0.018524792045354843,
      -0.0077775041572749615,
      0.01449211873114109,
      0.06604007631540298,
      0.03393197059631348,
      -0.0153992073610425,
      -0.00043747483869083226,
      -0.042047034949064255,
      -0.06517469882965088,
      -0.0040979874320328236,
      -0.028467126190662384,
      0.05160098522901535,
      -0.056130215525627136,
      -0.034198861569166183,
      -0.03423241898417473,
      0.006980392150580883,
      -0.0523359514772892,
      -0.03797561302781105,
      0.030457664281129837,
      -0.05601292476058006,
      -0.06706364452838898,
      -0.07545190304517746,
      0.002604971406981349,
      0.017552196979522705,
      0.0039049384649842978,
      0.04969077184796333,
      0.0003441590233705938,
      -0.027720296755433083,
      -0.0466928705573082,
      0.009520750492811203,
      0.018978675827383995,
      0.04782487824559212,
      0.07262309640645981,
      -0.08220002055168152,
      0.026623019948601723,
      -0.0364636555314064,
      -0.018743718042969704,
      -0.06462743133306503,
      0.008331202901899815,
      0.10131365805864334,
      -0.019312473013997078,
      -0.07698661834001541,
      -0.0584721714258194,
      0.05276477709412575,
      -0.06698698550462723,
      -0.04202377796173096,
      0.03339929133653641,
      -0.07062236964702606,
      0.04711633175611496,
      -0.025150282308459282,
      -0.03566455468535423,
      0.058921102434396744,
      -0.043093763291835785,
      -0.07491638511419296,
      0.08662167191505432,
      -0.03202377259731293,
      -0.03342731297016144,
      0.008104709908366203,
      0.020220564678311348,
      0.03163004666566849,
      -0.08548367768526077,
      0.04433893784880638,
      -0.019829275086522102,
      0.05393574759364128,
      0.010058213956654072,
      0.0006121773621998727,
      -0.034063488245010376,
      -0.09008970111608505,
      0.017390187829732895,
      0.03552783280611038,
      0.007765939459204674,
      0.028008148074150085,
      -0.019238237291574478,
      -0.10544826090335846,
      0.017213400453329086,
      -0.019251205027103424,
      0.06007600575685501,
      0.041592687368392944,
      0.07073205709457397,
      0.07135489583015442,
      -0.01522903610020876,
      -0.02127838134765625,
      0.04500816389918327,
      -0.028707347810268402,
      0.03298039734363556,
      0.027532627806067467,
      0.03769318014383316,
      -0.011459575034677982,
      -0.017572889104485512,
      0.02672051638364792,
      -0.03876585513353348,
      0.08678140491247177,
      0.005547206848859787,
      0.0031384876929223537,
      0.06177527830004692,
      0.018823258578777313,
      0.0004916468169540167,
      -0.04949871078133583,
      -0.0476946085691452,
      -0.014296879991889,
      -0.06523413211107254,
      -0.03914089500904083,
      -0.012333458289504051,
      -0.05714588239789009,
      -0.07518555968999863,
      -0.006093153730034828,
      -0.08862004429101944,
      0.01768895424902439,
      -0.02473551221191883,
      -0.01231367327272892,
      0.08708932995796204,
      -0.011420625261962414,
      0.06952529400587082,
      0.024981867522001266,
      0.04437393322587013,
      -0.036267779767513275,
      -0.02764996699988842,
      0.03673025220632553,
      0.02735494077205658,
      -0.028965622186660767,
      0.003326976438984275,
      -0.08661813288927078,
      -0.08063308894634247,
      -0.01677248813211918,
      0.015558933839201927,
      -0.10239150375127792,
      -0.08001063764095306,
      0.030123690143227577,
      0.04256445914506912,
      0.027188146486878395,
      0.007812723517417908,
      0.04753364622592926,
      -0.10443122684955597,
      -0.043770115822553635,
      -0.030622541904449463,
      -0.05308743566274643,
      0.04736297205090523,
      -0.04567541182041168,
      0.0008247991208918393,
      -0.011640660464763641,
      -0.042701445519924164,
      0.13667447865009308,
      0.07363196462392807,
      -0.016350533813238144,
      0.0221610888838768,
      0.02071375586092472,
      -0.034851375967264175,
      0.0040555852465331554,
      0.03274470195174217,
      0.017743738368153572,
      0.010012920014560223,
      0.036886297166347504,
      -0.03611123561859131,
      0.0009262702660635114,
      -0.05115384981036186,
      -0.0114689776673913,
      -0.01799265667796135,
      0.0059785726480185986,
      0.034159012138843536,
      0.020728787407279015,
      -0.019448593258857727,
      -0.04640441760420799,
      -0.05084484443068504,
      -0.012348501943051815,
      -0.012050080113112926,
      -0.04447761923074722,
      0.00638133380562067,
      -0.06806673854589462,
      0.032606229186058044,
      0.01575491949915886,
      -0.0066207037307322025,
      0.022046593949198723,
      0.032302193343639374,
      -0.02627752348780632,
      -0.03517806529998779,
      -0.056567318737506866,
      -0.1161302775144577,
      -0.03523524850606918,
      -0.0320645272731781,
      -0.022704847157001495,
      0.03585142269730568,
      0.037043940275907516,
      0.025182204321026802,
      -0.024281304329633713,
      -0.04072261601686478,
      -0.0058685895055532455,
      0.01839061826467514,
      0.026171784847974777,
      -0.03906141221523285,
      -0.0821051150560379,
      -0.018121344968676567,
      0.03445226326584816,
      -0.08379059284925461,
      0.016813213005661964,
      -0.0410684198141098,
      -0.02238626778125763,
      0.018791694194078445,
      -0.03044806979596615,
      0.048427484929561615,
      0.059513386338949203,
      0.06093503162264824,
      0.03914237767457962,
      0.0633036270737648,
      -0.07257720082998276,
      -0.07352378964424133,
      -0.026958797127008438,
      -0.03528474271297455,
      0.04796997457742691,
      0.0007008418324403465,
      -0.012972969561815262,
      -0.01941835694015026,
      -0.000563989335205406,
      -0.005710042547434568,
      0.066701740026474,
      0.009294845163822174,
      0.0828394964337349,
      0.05616221949458122,
      -0.059308089315891266,
      0.004291171208024025,
      0.016356388106942177,
      0.018944527953863144,
      -0.03973072022199631,
      0.0037018100265413523,
      0.044750042259693146,
      0.10383554548025131,
      -0.060627326369285583,
      -0.0686655193567276,
      0.054714009165763855,
      0.0025304972659796476,
      0.033498816192150116,
      0.009387947618961334,
      -0.02798779308795929,
      0.015415473841130733,
      -0.011173450388014317,
      0.053083088248968124,
      0.055104613304138184,
      -0.034038081765174866,
      -0.042878810316324234,
      -0.10131096094846725,
      0.014613785780966282,
      -0.08555774390697479,
      0.04591479152441025,
      0.003964900504797697,
      0.012456346303224564,
      -0.03245868533849716,
      0.03595072776079178,
      0.01747419871389866,
      -0.016605528071522713,
      0.049138665199279785,
      0.057667188346385956,
      -0.033285096287727356,
      0.007457862142473459,
      0.07910671830177307,
      -0.03112407587468624,
      0.01676713116466999,
      0.04531208425760269,
      0.005603770725429058,
      0.06442826986312866,
      -0.024510517716407776,
      0.069115549325943,
      0.03783226013183594,
      -0.05203203111886978,
      0.050952039659023285,
      0.03518463298678398,
      -0.020392244681715965,
      -0.01322112139314413,
      -0.044604405760765076,
      0.005405457690358162,
      -0.0906735360622406,
      0.0012484895996749401,
      -0.022926069796085358,
      0.026723353192210197,
      0.016592763364315033,
      0.003499693237245083,
      0.08866575360298157,
      -0.06533265113830566,
      0.028621681034564972,
      0.007782013155519962,
      -0.031585562974214554,
      -0.02591422013938427,
      -0.05325990915298462,
      0.024880127981305122,
      -0.009668021462857723,
      0.11018496006727219,
      0.028193647041916847,
      -0.030323542654514313,
      -0.02506381645798683,
      0.10799943655729294,
      -0.08192069083452225,
      -0.010378704406321049,
      -0.03257187828421593,
      0.03876662626862526,
      -0.044771015644073486,
      -0.06910189241170883,
      0.03749774396419525,
      -0.0006729603628627956,
      0.11860673874616623,
      0.014592943713068962,
      0.017987554892897606,
      0.06570382416248322,
      -0.02867811731994152,
      -0.03131522238254547,
      -0.006941332947462797,
      -0.042704951018095016,
      0.03258642554283142,
      -0.006183165591210127,
      0.03608168661594391,
      -0.00994851440191269,
      0.01568830944597721,
      -0.012795897200703621,
      0.018709400668740273,
      0.0076950471848249435,
      0.06979499012231827,
      -0.005821310915052891,
      -0.03444206714630127,
      -0.027139045298099518,
      -0.010680757462978363,
      0.06571611016988754,
      0.059183377772569656,
      0.09141948074102402,
      0.01282328087836504,
      0.013218843378126621,
      -0.020852575078606606,
      0.029813570901751518,
      0.041549310088157654,
      -0.003014440881088376,
      0.029738374054431915,
      -0.08449359238147736,
      0.0035581307020038366,
      -0.03503750264644623,
      -0.05039608106017113,
      0.03481972590088844,
      -0.016259385272860527,
      -0.04029971361160278,
      0.022610167041420937,
      -0.016623741015791893,
      -0.05567945912480354,
      -0.018290210515260696,
      -0.02231021039187908,
      -0.014113259501755238,
      -0.013859033584594727,
      -0.04329410567879677,
      0.04150969907641411,
      -0.03175235539674759,
      -0.005548851098865271,
      0.0009774666978046298,
      0.05932435020804405,
      -0.021928155794739723,
      0.004389570560306311,
      -0.011726968921720982,
      -0.03492378070950508,
      -0.0039233616553246975,
      -0.018940899521112442,
      -0.015637023374438286,
      0.01771101914346218,
      0.026608005166053772,
      0.05781647935509682,
      -0.021828021854162216,
      0.06732785701751709,
      0.02836422808468342,
      0.002854152349755168,
      -0.035395022481679916,
      -0.07533656060695648,
      -0.0232256930321455,
      -0.026363251730799675,
      -0.01658039540052414,
      -0.08278848230838776,
      0.04796752333641052,
      0.015173863619565964,
      -0.0011368815321475267,
      0.08586923778057098,
      0.015418673865497112,
      -0.07306751608848572,
      0.09545338898897171,
      0.05264049023389816,
      0.015813102945685387,
      -0.014449851587414742,
      0.06098662689328194,
      -0.07411723583936691,
      0.0957668274641037,
      -0.03946186602115631,
      0.006138795055449009,
      0.01778784766793251,
      0.034942056983709335,
      -0.07072438299655914,
      -0.0026053860783576965,
      0.09364064037799835,
      -0.005371971987187862,
      -0.07770658284425735,
      0.03610100597143173,
      -0.0009879542049020529,
      -0.035992108285427094,
      0.0029495549388229847,
      -0.019754372537136078,
      -0.041271012276411057,
      -0.056334588676691055,
      0.06766260415315628,
      -0.062139030545949936,
      -0.03517742455005646,
      0.02026381343603134,
      -0.03065647929906845,
      0.05326062813401222,
      -0.01940479315817356,
      0.03126410022377968,
      -0.0629257932305336,
      0.06600110232830048,
      0.023686014115810394
    ],
    [
      0.04369259253144264,
      0.06006421521306038,
      0.05314892157912254,
      0.009330295957624912,
      0.05410999804735184,
      0.007094549480825663,
      -0.006703462451696396,
      0.04784484580159187,
      -0.018679998815059662,
      -0.019901972264051437,
      -0.06874611973762512,
      -0.03502551093697548,
      0.04626091197133064,
      -0.04659047722816467,
      -0.10270688682794571,
      0.012030098587274551,
      -0.057819731533527374,
      0.004952988587319851,
      0.03258168324828148,
      -0.020434090867638588,
      0.02700205333530903,
      0.04779800400137901,
      0.03980647027492523,
      -0.021575545892119408,
      0.06519047915935516,
      0.00538410572335124,
      -0.02206142619252205,
      0.07913916558027267,
      -0.002673076931387186,
      0.10658563673496246,
      0.06943633407354355,
      -0.0007389101665467024,
      0.02557360753417015,
      0.13680630922317505,
      -0.08604469150304794,
      -0.0024044865276664495,
      -0.08185600489377975,
      -0.02798708900809288,
      0.0023733179550617933,
      -0.07275541126728058,
      -0.042949821799993515,
      0.04755251854658127,
      0.004817349836230278,
      0.05570093169808388,
      -0.06209225580096245,
      -0.0437605082988739,
      0.0538097508251667,
      -0.06690559536218643,
      0.022830046713352203,
      -0.08113223314285278,
      -0.005403541028499603,
      0.0640357956290245,
      0.04046868905425072,
      0.015035240910947323,
      0.018536487594246864,
      0.14708426594734192,
      0.040598075836896896,
      0.000144873236422427,
      -0.03169295936822891,
      0.03373477980494499,
      -0.04598909988999367,
      0.042704980820417404,
      0.07618892937898636,
      -0.03952653333544731,
      0.09606313705444336,
      -0.036024853587150574,
      -0.07890190929174423,
      -0.040852539241313934,
      -0.021796587854623795,
      -0.009952835738658905,
      -0.013843157328665257,
      -0.00819473434239626,
      0.07092919200658798,
      0.092250756919384,
      0.1160576194524765,
      0.03662408888339996,
      0.025711853057146072,
      0.06556355208158493,
      0.05245474725961685,
      0.021736616268754005,
      0.032118190079927444,
      0.03499229997396469,
      -0.016248298808932304,
      -0.00914967805147171,
      0.019532175734639168,
      0.007959793321788311,
      0.06405946612358093,
      0.0026162040885537863,
      0.049711212515830994,
      -0.012978400103747845,
      -0.04776839166879654,
      0.05734091252088547,
      -0.00629388727247715,
      0.05247656628489494,
      0.05545243248343468,
      -0.010652453638613224,
      -0.06247226148843765,
      0.002675696974620223,
      -0.01129951048642397,
      0.03357771039009094,
      0.05626322701573372,
      0.026845958083868027,
      0.02925785630941391,
      -0.023453352972865105,
      0.01575751043856144,
      0.04923824220895767,
      -0.0036013962235301733,
      -0.1135173812508583,
      0.01964159496128559,
      0.027357397601008415,
      -0.028470223769545555,
      -0.06554807722568512,
      -0.02450157329440117,
      -0.02147042378783226,
      -0.015024552121758461,
      -0.05102765932679176,
      0.03114381432533264,
      -0.010592357255518436,
      0.06522434949874878,
      -0.004999213386327028,
      -0.015870390459895134,
      -0.049949292093515396,
      0.0500558465719223,
      0.0740681067109108,
      0.006815896835178137,
      0.044300250709056854,
      0.03275078535079956,
      0.049772147089242935,
      0.011380722746253014,
      -0.030654480680823326,
      -0.01075501088052988,
      -0.007556638680398464,
      0.02097509615123272,
      -0.0725327730178833,
      0.012616201303899288,
      0.05653366819024086,
      0.0026713383849710226,
      0.0032549912575632334,
      -0.09546922892332077,
      -0.06580209732055664,
      0.06058153137564659,
      -0.04404192790389061,
      0.048482559621334076,
      -0.022253522649407387,
      0.022754883393645287,
      0.059583961963653564,
      0.0250442773103714,
      -0.01538115181028843,
      -0.025288905948400497,
      -0.04319370910525322,
      -0.001583268865942955,
      0.04289599880576134,
      -0.06434766948223114,
      0.0016489895060658455,
      0.02804153971374035,
      -0.011420750990509987,
      -0.07160911709070206,
      -0.07740835100412369,
      0.0603988915681839,
      0.03244747966527939,
      0.072142094373703,
      0.0032970174215734005,
      0.020933499559760094,
      0.027765290811657906,
      0.06393582373857498,
      -0.02290463261306286,
      -0.059742849320173264,
      -0.04604141414165497,
      -0.008040325716137886,
      -0.019930338487029076,
      0.0022700384724885225,
      0.07608671486377716,
      0.030554627999663353,
      0.0008088909089565277,
      -0.01243392564356327,
      0.07627484947443008,
      0.0917801484465599,
      0.05175010859966278,
      -0.02671431005001068,
      -0.01743275672197342,
      -0.07543560117483139,
      0.020511969923973083,
      0.03603944554924965,
      -0.008773491717875004,
      0.0310994740575552,
      -0.017272042110562325,
      0.05104215815663338,
      0.010135411284863949,
      0.030336489900946617,
      -0.020463520660996437,
      0.03108486346900463,
      -0.07482465356588364,
      0.015318520367145538,
      -0.016954554244875908,
      -0.053733330219984055,
      0.029843749478459358,
      -0.045508842915296555,
      0.002644368913024664,
      0.06347262114286423,
      -0.054910849779844284,
      -0.037607591599226,
      -0.008218398317694664,
      0.025943217799067497,
      0.03702649474143982,
      -0.01628788746893406,
      -0.028689440339803696,
      -0.05235452950000763,
      -0.02696908637881279,
      -0.06973256915807724,
      -0.06608953326940536,
      -0.016807544976472855,
      0.0018195175798609853,
      0.025762297213077545,
      -0.015172837302088737,
      0.012635831721127033,
      0.01179384533315897,
      -0.0001346660137642175,
      0.12918154895305634,
      -0.03000597655773163,
      -0.05850522965192795,
      0.026826966553926468,
      0.011116791516542435,
      0.02005043253302574,
      -0.0009484228794462979,
      0.09687573462724686,
      0.030317768454551697,
      -0.00491517037153244,
      0.010938487015664577,
      0.029238179326057434,
      8.71647207532078e-05,
      -0.01091434434056282,
      0.025401638820767403,
      0.07730422168970108,
      -0.0024022317957133055,
      -0.10326613485813141,
      -0.03259231150150299,
      0.011809629388153553,
      0.08952472358942032,
      0.034770265221595764,
      -0.02393116056919098,
      -0.08381082117557526,
      0.055398665368556976,
      0.05686775967478752,
      -0.014970818534493446,
      0.10040170699357986,
      -0.014223076403141022,
      0.050384003669023514,
      0.016384577378630638,
      0.027347618713974953,
      -0.017239928245544434,
      0.04528091475367546,
      -0.009861425496637821,
      0.007778754457831383,
      -0.043280910700559616,
      0.03520464524626732,
      0.03701513633131981,
      -0.0576779730618,
      -0.03785112500190735,
      -0.021255385130643845,
      -0.000893449760042131,
      -0.009691147133708,
      0.03378303349018097,
      0.02677307091653347,
      -0.027136515825986862,
      -0.002109257038682699,
      -0.016101118177175522,
      0.03914450854063034,
      0.018069975078105927,
      0.05833710730075836,
      -0.06716396659612656,
      -0.017068633809685707,
      0.013125122524797916,
      -0.037947047501802444,
      0.017734473571181297,
      0.035838011652231216,
      -0.012829571962356567,
      0.0071550398133695126,
      -0.03331762179732323,
      -0.04641704261302948,
      -0.00834889430552721,
      0.06370941549539566,
      0.04330289736390114,
      -0.020815543830394745,
      -0.07720088213682175,
      0.1065010204911232,
      -0.0010134545154869556,
      0.022312594577670097,
      -0.015858301892876625,
      0.023985575884580612,
      0.036913614720106125,
      -0.02427348680794239,
      0.007963196374475956,
      0.017077364027500153,
      0.01656840182840824,
      0.05178695172071457,
      -0.03589022159576416,
      0.008270889520645142,
      0.04812899976968765,
      -0.005672771483659744,
      -0.022415773943066597,
      -0.026712113991379738,
      -0.10206905007362366,
      -0.050806570798158646,
      0.08164966851472855,
      0.029873719438910484,
      -0.014864007942378521,
      -0.035855621099472046,
      -0.030600467696785927,
      -0.0775642842054367,
      0.04039498046040535,
      -0.058911364525556564,
      0.01985861547291279,
      -0.009802339598536491,
      -0.09280125051736832,
      -0.017599692568182945,
      0.03018053248524666,
      -0.01642814837396145,
      -0.02650328539311886,
      -0.07011017203330994,
      -0.05542810633778572,
      0.04001740738749504,
      -0.11254051327705383,
      0.006505303550511599,
      -0.05000576749444008,
      0.01860451139509678,
      -0.08872892707586288,
      0.031405482441186905,
      0.06678349524736404,
      0.04940676689147949,
      -0.04638827592134476,
      0.03271569311618805,
      0.03917625918984413,
      -0.02239076793193817,
      0.08626562356948853,
      -0.015097442083060741,
      -0.08349096029996872,
      0.028583426028490067,
      -0.06434646248817444,
      0.04216910898685455,
      0.007161052897572517,
      -0.06086353957653046,
      -0.029545122757554054,
      0.025206150487065315,
      0.019706739112734795,
      0.10470609366893768,
      0.029518408700823784,
      -0.004422759171575308,
      0.012010626494884491,
      0.04013950377702713,
      -0.02453966625034809,
      -0.0458039790391922,
      0.07017667591571808,
      -0.017494790256023407,
      0.0016872761771082878,
      -0.033176857978105545,
      0.0009871982038021088,
      0.027404267340898514,
      0.01460246741771698,
      -0.011187830939888954,
      -0.05895015969872475,
      0.0050763883627951145,
      0.02572164125740528,
      0.01180957816541195,
      -0.007404522504657507,
      -0.07034559547901154,
      -0.006406922359019518,
      -0.02421892061829567,
      -0.017810102552175522,
      0.021832775324583054,
      0.03802992030978203,
      0.033130571246147156,
      0.045520007610321045,
      0.016091439872980118,
      -0.03123195841908455,
      -0.00419529527425766,
      0.04763932526111603,
      0.047610197216272354,
      -0.02741813100874424,
      -0.0638362318277359,
      0.03199351578950882,
      -0.033081840723752975,
      0.00034207775024697185,
      -0.05879564955830574,
      0.07696031779050827,
      -0.050839975476264954,
      0.06725948303937912,
      0.04278042912483215,
      0.016938721761107445,
      0.06812305003404617,
      0.02769480273127556,
      -0.043828338384628296,
      -0.02281259186565876,
      0.01570337824523449,
      -0.0037532804999500513,
      0.03456711396574974,
      -0.10949835181236267,
      -0.10729900747537613,
      -0.0269051231443882,
      0.05052989721298218,
      0.03215317055583,
      -0.009268982335925102,
      0.025651585310697556,
      0.04564565792679787,
      -0.04215596616268158,
      -0.014780047349631786,
      -0.06872670352458954,
      0.03414132818579674,
      0.08554044365882874,
      -0.006979323923587799,
      0.04545685648918152,
      0.07292237132787704,
      -0.04858364909887314,
      0.03692449629306793,
      -0.008853817358613014,
      0.026669593527913094,
      0.03355885669589043,
      -0.034411218017339706,
      0.02091740258038044,
      -0.02838832326233387,
      0.10051632672548294,
      -0.019647259265184402,
      -0.03885289281606674,
      0.006709195673465729,
      0.02721489407122135,
      -0.015410827472805977,
      -0.1033756360411644,
      -0.019155003130435944,
      0.009620970115065575,
      -0.008381148800253868,
      -0.020085018128156662,
      0.031182030215859413,
      0.021458012983202934,
      0.0018314133631065488,
      -0.049016229808330536,
      -0.05088008567690849,
      -0.09338860213756561,
      0.04538119584321976,
      -5.605521801044233e-05,
      0.0506940558552742,
      -0.0994253158569336,
      0.03568543121218681,
      0.06298043578863144,
      -0.06634087860584259,
      0.07315877079963684,
      0.021156035363674164,
      -0.00949944369494915,
      0.04061630740761757,
      0.03285066410899162,
      -0.021130576729774475,
      -0.11780954152345657,
      -0.04602598398923874,
      0.010570136830210686,
      -0.017574450001120567,
      0.012281877920031548,
      0.01817208342254162,
      -0.015540448017418385,
      -0.009559369646012783,
      0.06630057841539383,
      0.021113865077495575,
      0.0538337379693985,
      0.03558814898133278,
      0.07622014731168747,
      -0.03428536653518677,
      0.03586263582110405,
      0.03008362092077732,
      0.013076878152787685,
      0.056944750249385834,
      -0.12583644688129425,
      -0.07750926166772842,
      -0.013870708644390106,
      0.06669031083583832,
      0.00025218608789145947,
      -0.015186584554612637,
      -0.006434714887291193,
      -0.007198913022875786,
      0.04988982900977135,
      -0.0450180359184742,
      -0.027166225016117096,
      -0.018992479890584946,
      0.02041628584265709,
      -0.11162479966878891,
      -0.026550816372036934,
      0.037266071885824203,
      0.04742494598031044,
      0.010472591035068035,
      -0.006819624919444323,
      -0.1301625519990921,
      -0.014881812036037445,
      -0.026904111728072166,
      -0.023749683052301407,
      -0.005420069210231304,
      0.016063759103417397,
      -0.03719444200396538,
      -0.0031631062738597393,
      -0.010874043218791485,
      0.019363965839147568,
      -0.012231399305164814,
      -0.025546425953507423,
      -0.09522964805364609,
      -0.0005036850925534964,
      -0.1029244214296341,
      -0.013932768255472183,
      0.058633580803871155
    ],
    [
      0.013297287747263908,
      -0.0022989572025835514,
      -0.029056988656520844,
      -0.062125690281391144,
      0.060636404901742935,
      -0.0078196432441473,
      -0.07939472794532776,
      0.02503008022904396,
      0.057193804532289505,
      -0.024861549958586693,
      0.047530245035886765,
      -0.0010589559096843004,
      0.01618725247681141,
      -0.00736267538741231,
      0.04269552603363991,
      -0.020285597071051598,
      -0.004203098360449076,
      0.008501224219799042,
      -0.07020771503448486,
      -0.002025613561272621,
      0.011288139037787914,
      -0.07841718196868896,
      -0.062392644584178925,
      0.08563622087240219,
      0.018800076097249985,
      -0.00960705615580082,
      0.02833176963031292,
      -0.006658569909632206,
      -0.07407540827989578,
      -0.0009129122481681406,
      -0.021836858242750168,
      -0.09553540498018265,
      -0.0008676279685460031,
      0.05621720105409622,
      -0.013646982610225677,
      0.02167493663728237,
      -0.09987527877092361,
      0.03439604490995407,
      -0.006462774705141783,
      0.05247706174850464,
      -0.049777913838624954,
      0.01696307398378849,
      -0.03591076284646988,
      -0.04114806279540062,
      -0.0663132295012474,
      -0.017986256629228592,
      -0.017353789880871773,
      0.060723673552274704,
      -0.033256806433200836,
      0.015234200283885002,
      0.030547264963388443,
      0.009834200143814087,
      0.015113644301891327,
      -0.03905969113111496,
      0.018620690330863,
      0.04902420938014984,
      0.05305112525820732,
      0.021305961534380913,
      -0.04440600797533989,
      -0.019533947110176086,
      -0.04714323207736015,
      0.021333059296011925,
      -0.054231077432632446,
      -0.030983222648501396,
      0.01668456569314003,
      -0.015259268693625927,
      -0.016950557008385658,
      -0.01527770422399044,
      0.018569426611065865,
      0.008650344796478748,
      0.02860875241458416,
      -0.07917936146259308,
      0.024793971329927444,
      0.04700223356485367,
      -0.050349682569503784,
      -0.004768331069499254,
      0.1340237259864807,
      -0.023768067359924316,
      -0.13150620460510254,
      0.019312549382448196,
      0.026270385831594467,
      0.07136795669794083,
      0.0031454600393772125,
      0.05540347099304199,
      0.03268244490027428,
      0.036159198731184006,
      -0.05914456769824028,
      0.014000455848872662,
      0.0357179157435894,
      0.011392715387046337,
      0.02936890348792076,
      0.112461157143116,
      -0.024224037304520607,
      0.12104200571775436,
      0.016666190698742867,
      0.030003992840647697,
      -0.03403053805232048,
      -0.03387587144970894,
      0.03546056151390076,
      0.04307898133993149,
      0.006814013700932264,
      -0.009341264143586159,
      0.029742440208792686,
      -0.01215252373367548,
      -0.04403221979737282,
      0.018274245783686638,
      -0.030784787610173225,
      -0.04704701155424118,
      -0.07143718004226685,
      -0.027461718767881393,
      0.042136602103710175,
      -0.04550525173544884,
      -0.024361828342080116,
      0.013771947473287582,
      -0.030620422214269638,
      0.006570050958544016,
      -0.0017382847145199776,
      -0.028177084401249886,
      0.03610730171203613,
      0.0043502249754965305,
      0.020249269902706146,
      0.039995089173316956,
      0.00024063032469712198,
      0.07845446467399597,
      -0.034839559346437454,
      0.027651863172650337,
      0.030876290053129196,
      0.08843301236629486,
      0.06493427604436874,
      -0.0020087221637368202,
      -0.05234626308083534,
      -0.13015198707580566,
      -0.013874072581529617,
      -0.01739262416958809,
      -0.00403935369104147,
      0.04663216322660446,
      -0.10974368453025818,
      -0.02774820476770401,
      0.02084190584719181,
      0.0008759851334616542,
      0.05378451570868492,
      -0.036618273705244064,
      0.053813863545656204,
      0.0281900092959404,
      0.03720606490969658,
      0.059964876621961594,
      -0.002526715863496065,
      -0.006093237549066544,
      0.03851902857422829,
      -0.051254794001579285,
      -0.0035133000928908587,
      0.026826949790120125,
      -0.002772410400211811,
      -0.04118657112121582,
      0.05568232387304306,
      0.015984654426574707,
      -0.02719026245176792,
      0.0016835235292091966,
      0.04138066619634628,
      -0.0029846946708858013,
      0.0925067812204361,
      0.0031179392244666815,
      0.02507608011364937,
      -0.03560639172792435,
      -0.04141603037714958,
      -0.039832886308431625,
      -0.10350196808576584,
      0.025877097621560097,
      0.013232951983809471,
      0.02045314759016037,
      0.07242181152105331,
      0.030349403619766235,
      -0.01522657461464405,
      -0.0159225482493639,
      0.000701800687238574,
      -0.03037777915596962,
      -0.000193853018572554,
      -0.02563312277197838,
      -0.07941786199808121,
      0.011276038363575935,
      -0.04113350808620453,
      -0.07909570634365082,
      -0.020391881465911865,
      0.0417109839618206,
      0.03650957718491554,
      -0.046516042202711105,
      -0.09453201293945312,
      -0.02077309414744377,
      -0.043999478220939636,
      0.048378221690654755,
      0.01999017223715782,
      -0.07478493452072144,
      0.025721559301018715,
      0.058924127370119095,
      -0.011484035290777683,
      0.0038115656934678555,
      0.07376433908939362,
      0.013299336656928062,
      -0.03533758223056793,
      -0.06760353595018387,
      0.04810609668493271,
      0.052848294377326965,
      -0.0012350903125479817,
      0.04698725417256355,
      -0.04227243363857269,
      -0.04248795658349991,
      0.02612275816500187,
      -0.034193843603134155,
      -0.07674963772296906,
      -0.04832696169614792,
      -0.02459678053855896,
      -0.0017584874294698238,
      0.031043631955981255,
      -0.05203297734260559,
      -0.04503244906663895,
      -0.04984055832028389,
      -0.032479431480169296,
      0.019691022112965584,
      -0.0565393790602684,
      -0.02826095186173916,
      0.0276926402002573,
      -0.03782457485795021,
      -0.06585446000099182,
      -0.001617037458345294,
      -0.08132026344537735,
      -0.08948853611946106,
      -0.08483049273490906,
      0.04690263792872429,
      -0.009935665875673294,
      0.019951483234763145,
      -0.01970185711979866,
      0.03321576863527298,
      0.05180584639310837,
      -0.07994883507490158,
      -0.007983651012182236,
      -0.03176311403512955,
      0.062276970595121384,
      -0.025609644129872322,
      0.030963430181145668,
      0.10913591831922531,
      -0.035889651626348495,
      0.07084029167890549,
      0.046241771429777145,
      -0.0674668624997139,
      0.04406682774424553,
      0.04018038138747215,
      -0.014164810068905354,
      -0.014299372211098671,
      -0.09622669219970703,
      0.007606524508446455,
      0.039923712611198425,
      0.0017037258949130774,
      0.03995615616440773,
      0.07423371076583862,
      0.08690734207630157,
      0.0533895306289196,
      -0.05980478599667549,
      0.018724028021097183,
      0.07900137454271317,
      0.006902695633471012,
      -0.04212730750441551,
      -0.008624103851616383,
      -0.1392422318458557,
      -0.06136297807097435,
      -0.028315242379903793,
      -0.016733810305595398,
      -0.05165523663163185,
      -0.03051641397178173,
      0.0002400006342213601,
      -0.014235978946089745,
      0.014191037975251675,
      -0.028381189331412315,
      0.0329868458211422,
      0.09488248825073242,
      0.0031058716122061014,
      -0.03522456809878349,
      0.002017382765188813,
      0.0028243123088032007,
      -0.029916254803538322,
      0.0802341178059578,
      0.018788915127515793,
      -0.04827935993671417,
      0.040510378777980804,
      0.02041757106781006,
      -0.012495508417487144,
      0.05701190605759621,
      -0.01629614271223545,
      -0.02460073120892048,
      0.07769028097391129,
      0.04005904868245125,
      -0.002951194765046239,
      0.0227801613509655,
      -0.05737857520580292,
      0.03331059217453003,
      0.0031043721828609705,
      0.028401127085089684,
      -0.08047610521316528,
      -0.037476204335689545,
      -0.031079670414328575,
      0.0948464423418045,
      -0.08413967490196228,
      -0.02591058611869812,
      -0.008253131061792374,
      -0.036117877811193466,
      -0.0672842487692833,
      0.04635167121887207,
      0.010393350385129452,
      -0.05463235080242157,
      0.019187116995453835,
      0.0030126990750432014,
      -0.0548165887594223,
      0.03681594878435135,
      0.01065089926123619,
      0.0719720795750618,
      -0.015118613839149475,
      0.027464156970381737,
      -0.015509337186813354,
      0.025762993842363358,
      -0.07494429498910904,
      0.0012094303965568542,
      0.054826024919748306,
      -0.004584075883030891,
      -0.050691425800323486,
      0.06543092429637909,
      -0.021128984168171883,
      -0.056659407913684845,
      0.05522624030709267,
      0.009878020733594894,
      -0.02374063804745674,
      0.02284972369670868,
      -0.01960444636642933,
      0.01937260664999485,
      -0.04494902119040489,
      0.05421197786927223,
      -0.03868963569402695,
      -0.04746312275528908,
      -0.017264684662222862,
      0.04116296023130417,
      0.00410708412528038,
      0.03183763474225998,
      0.033559106290340424,
      -0.03442120924592018,
      -0.031659916043281555,
      0.008323059417307377,
      0.037945594638586044,
      -0.0006459091673605144,
      -0.03853824362158775,
      0.10238999873399734,
      0.03846820071339607,
      0.03496043011546135,
      0.044334907084703445,
      -0.07425080239772797,
      0.0629202276468277,
      -0.044092535972595215,
      0.01340162567794323,
      -0.021072737872600555,
      0.016361664980649948,
      -0.0837583914399147,
      -0.044537514448165894,
      0.030409496277570724,
      0.031134534627199173,
      -0.03677183762192726,
      -0.04771270602941513,
      0.01781471073627472,
      -0.07330767065286636,
      0.013157189823687077,
      0.011277197860181332,
      0.06349212676286697,
      0.04925698786973953,
      0.020634982734918594,
      0.05423123389482498,
      -0.0994216576218605,
      -0.007752883713692427,
      0.033551622182130814,
      0.0049467976205050945,
      0.02152246981859207,
      0.03850908949971199,
      -0.03970348462462425,
      0.005542426370084286,
      -0.05588504299521446,
      0.00010808535444084555,
      0.021474875509738922,
      0.013543520122766495,
      0.022349342703819275,
      0.003809154499322176,
      -0.014130004681646824,
      0.07964712381362915,
      0.005936299450695515,
      0.031051456928253174,
      0.0292036309838295,
      -0.042704708874225616,
      -0.020821670070290565,
      0.045600999146699905,
      0.10694051533937454,
      -0.08038371056318283,
      0.024528712034225464,
      -0.03124811314046383,
      0.0012749499874189496,
      0.06382160633802414,
      -0.073236845433712,
      0.027554471045732498,
      0.004784876015037298,
      -0.02267814241349697,
      -0.10072088241577148,
      0.0662521943449974,
      0.020149996504187584,
      -0.0831914097070694,
      -0.05212589353322983,
      0.07158071547746658,
      -0.02130766212940216,
      0.0676991268992424,
      0.020075002685189247,
      0.009747928008437157,
      -0.04472074285149574,
      0.026442211121320724,
      0.08684922009706497,
      0.021474475041031837,
      -0.0635150894522667,
      0.023739701136946678,
      0.03608418256044388,
      0.03183650225400925,
      -0.02487383596599102,
      0.04963180422782898,
      0.03535095229744911,
      0.018483614549040794,
      0.009303642436861992,
      -0.05836382135748863,
      -0.012994806282222271,
      -0.0669744610786438,
      0.04234779626131058,
      -0.009141120128333569,
      0.00590904476121068,
      0.0663423240184784,
      -0.0224987231194973,
      -0.02248222380876541,
      0.02475646883249283,
      0.059110790491104126,
      -0.03815976530313492,
      -0.06048814207315445,
      -0.06124551594257355,
      0.04673515260219574,
      0.008076390251517296,
      -0.015027309767901897,
      0.043534088879823685,
      -0.02128830924630165,
      -0.03842923790216446,
      -0.014030049555003643,
      0.08316779881715775,
      0.008553852327167988,
      -0.05094008147716522,
      -0.02541232481598854,
      0.030180102214217186,
      -0.027472905814647675,
      -0.0027791543398052454,
      0.031128469854593277,
      -0.018575793132185936,
      -0.06853150576353073,
      0.021335693076252937,
      0.04138123616576195,
      0.02699478715658188,
      0.11176448315382004,
      0.033965665847063065,
      0.04599183052778244,
      0.05997110903263092,
      -0.010518163442611694,
      0.08446995168924332,
      0.059752363711595535,
      -0.05213852599263191,
      -0.10311610996723175,
      -0.017437690868973732,
      -0.03612411394715309,
      -0.08393654227256775,
      -0.01091750804334879,
      0.019367465749382973,
      -0.06013602018356323,
      -0.05603037402033806,
      0.09752708673477173,
      -0.017823055386543274,
      0.026626581326127052,
      0.023929566144943237,
      0.006810197606682777,
      0.02643292210996151,
      -0.040723759680986404,
      0.07629519701004028,
      0.005060259718447924,
      -0.04819268360733986,
      -0.07955386489629745,
      0.02892572060227394,
      -0.11231616139411926,
      -0.03867540508508682,
      0.0014505357248708606,
      -0.032165538519620895,
      0.06072267144918442,
      -0.03393949195742607,
      0.001067908713594079,
      0.007274053059518337,
      -0.041940368711948395,
      -0.038933757692575455,
      -0.02500244788825512,
      0.04421772435307503,
      -0.0009473513346165419,
      -0.015476249158382416,
      0.016321828588843346
    ],
    [
      0.09757956862449646,
      0.03428592532873154,
      0.02997731789946556,
      0.006532012484967709,
      0.05291561782360077,
      0.0882653072476387,
      -0.00676797004416585,
      0.02131042256951332,
      -0.0009684283868409693,
      0.05456765741109848,
      -0.018023362383246422,
      0.008873282931745052,
      0.030002979561686516,
      0.029849475249648094,
      0.05111926794052124,
      -0.03350787237286568,
      -0.029686978086829185,
      0.051724351942539215,
      -0.022808421403169632,
      -0.05515912175178528,
      -0.010119563899934292,
      -0.01263152714818716,
      -0.01036661583930254,
      -0.006159479729831219,
      -0.03284834325313568,
      0.02870440110564232,
      0.0349854901432991,
      -0.008552633225917816,
      0.07276877760887146,
      0.05491671711206436,
      -0.004726937040686607,
      -0.008234629407525063,
      -0.009406032040715218,
      0.00016434663848485798,
      0.03290626406669617,
      -0.008555661886930466,
      -0.027599800378084183,
      0.012011443264782429,
      -0.01985379122197628,
      -0.05461222305893898,
      -0.0007592383772134781,
      -0.011294068768620491,
      -0.02964373491704464,
      -0.049425158649683,
      0.07399272173643112,
      0.07297544926404953,
      -0.03646651282906532,
      0.09992347657680511,
      -0.0765376165509224,
      -0.008794685825705528,
      -0.019136855378746986,
      -0.046967457979917526,
      -0.0166560597717762,
      -0.01182997040450573,
      0.010671177878975868,
      0.06483571976423264,
      -0.0029440789949148893,
      0.04922180622816086,
      0.004623815882951021,
      0.024629874154925346,
      0.05463137850165367,
      0.05752458795905113,
      -0.1093309074640274,
      -0.0372331365942955,
      -0.0632178857922554,
      -0.04676690697669983,
      -0.010682133957743645,
      0.029527591541409492,
      -0.060104645788669586,
      0.01009134016931057,
      -0.07058096677064896,
      0.023672694340348244,
      -0.030826510861516,
      0.05674980208277702,
      -0.040743570774793625,
      -0.015342742204666138,
      0.03204558417201042,
      -0.009458803571760654,
      -0.011006839573383331,
      0.041211482137441635,
      0.029671085998415947,
      0.026877671480178833,
      0.047565389424562454,
      0.008312477730214596,
      -0.07690144330263138,
      0.04674841836094856,
      -0.019190048798918724,
      0.06859864294528961,
      -0.012413282878696918,
      0.05053935945034027,
      0.00986414309591055,
      0.011124225333333015,
      0.056751374155282974,
      -0.013984056189656258,
      -0.01759755238890648,
      -0.01701209507882595,
      -0.06132901832461357,
      0.03563624247908592,
      0.06450774520635605,
      0.06052364408969879,
      0.013262201100587845,
      -0.025285711511969566,
      0.05997416377067566,
      -0.06284137815237045,
      -0.08620050549507141,
      0.07003343850374222,
      -0.041625600308179855,
      0.09375756978988647,
      -0.08917633444070816,
      0.054356273263692856,
      0.04247428849339485,
      0.042561888694763184,
      0.01370658166706562,
      -0.05204182490706444,
      0.10002876818180084,
      -0.0227810125797987,
      -0.05772189795970917,
      0.029854370281100273,
      0.06980117410421371,
      -0.011977517046034336,
      -0.015143133699893951,
      -0.05147707089781761,
      0.03829671069979668,
      0.01910935714840889,
      0.08746949583292007,
      -0.0651375949382782,
      -0.05415060743689537,
      0.025349417701363564,
      0.042930491268634796,
      0.12963245809078217,
      -0.015977559611201286,
      0.011758724227547646,
      -0.03341328352689743,
      -0.0005499953986145556,
      0.013316492550075054,
      -0.043611567467451096,
      -0.003229901660233736,
      -0.014118901453912258,
      -0.0860244557261467,
      -0.002522236667573452,
      -0.017583560198545456,
      0.05013713240623474,
      0.05530792102217674,
      0.10631715506315231,
      0.005419349297881126,
      -0.03466165438294411,
      -0.03653746470808983,
      0.04732171818614006,
      -0.025297539308667183,
      -0.04681035131216049,
      0.08252289891242981,
      -0.03643316403031349,
      -0.005417144391685724,
      -0.021493257954716682,
      -0.001681968686170876,
      0.026359567418694496,
      -0.02584606036543846,
      0.04923015087842941,
      0.03487902879714966,
      -0.047373540699481964,
      -0.011057036928832531,
      -0.09698712825775146,
      0.02270728535950184,
      -0.039362214505672455,
      -0.07696916908025742,
      0.07903783768415451,
      -0.028888830915093422,
      0.0017565778689458966,
      0.05438854917883873,
      0.0025848071090877056,
      0.028819946572184563,
      -0.00041096823406405747,
      0.05999574437737465,
      0.0007829433889128268,
      -0.016285717487335205,
      0.024235371500253677,
      -0.012685621157288551,
      0.005167644005268812,
      -0.023697173222899437,
      0.007538460195064545,
      -0.0264767836779356,
      -0.028521068394184113,
      0.025808632373809814,
      0.10813920944929123,
      -0.07705433666706085,
      -0.03800112381577492,
      0.013054708018898964,
      -0.0025854469276964664,
      0.017401698976755142,
      -0.0442999005317688,
      -0.07745763659477234,
      0.04418100044131279,
      -0.05201727896928787,
      -0.03429902717471123,
      -0.05281893163919449,
      -0.10959777981042862,
      -0.04391422122716904,
      0.0051411716267466545,
      -0.05135291814804077,
      -0.08244235068559647,
      -0.08745907247066498,
      0.025173410773277283,
      -0.03874540701508522,
      0.025170009583234787,
      0.0128292515873909,
      -0.02458464726805687,
      0.03175896778702736,
      -0.026411952450871468,
      0.0037115367595106363,
      0.050345905125141144,
      0.020136697217822075,
      0.06085571274161339,
      -0.01591210439801216,
      0.042653538286685944,
      -0.029653381556272507,
      0.08516398817300797,
      -0.026368821039795876,
      -0.002527004573494196,
      0.0025032891426235437,
      -0.08255372196435928,
      -0.0717269778251648,
      0.019572420045733452,
      -0.019565239548683167,
      -0.01330328918993473,
      -0.00723801925778389,
      0.0054811448790133,
      0.03392770141363144,
      -0.019610827788710594,
      -0.029361916705965996,
      -0.019181307405233383,
      -0.001663252362050116,
      -0.03158000856637955,
      -0.002774071181192994,
      -0.007715566083788872,
      0.05650841072201729,
      -0.06328429281711578,
      0.04745475947856903,
      0.047609586268663406,
      -0.0019351548980921507,
      -0.01390253845602274,
      -0.06374183297157288,
      -0.02209862507879734,
      0.04650437831878662,
      -0.015993332490324974,
      -0.012141621671617031,
      0.020798835903406143,
      -0.18202191591262817,
      0.013467060402035713,
      -0.030830666422843933,
      0.0010053007863461971,
      -0.020556800067424774,
      0.005369239486753941,
      -0.002744190162047744,
      0.10151404142379761,
      0.01072108093649149,
      0.00629229424521327,
      0.05503735691308975,
      -0.035264402627944946,
      -0.00795748271048069,
      -0.05478767305612564,
      -0.026019658893346786,
      -0.07549344748258591,
      -0.04685169458389282,
      0.0818164050579071,
      -0.00823714304715395,
      0.009916037321090698,
      0.031565841287374496,
      -0.023886920884251595,
      0.026175502687692642,
      -0.01660398207604885,
      -0.02221822738647461,
      -0.020592354238033295,
      0.0029386512469500303,
      0.04008842259645462,
      -0.057083915919065475,
      -0.04037141054868698,
      -0.017818009480834007,
      0.08695682138204575,
      0.001417520921677351,
      -0.0008663658518344164,
      0.07701258361339569,
      0.10837182402610779,
      -0.060949794948101044,
      0.031777311116456985,
      -0.011447380296885967,
      -0.005618719384074211,
      -0.003920257557183504,
      -0.01738169603049755,
      0.009708206169307232,
      -0.008357631042599678,
      0.004996563773602247,
      -0.0066393702290952206,
      0.02998790703713894,
      0.04396793991327286,
      -0.004168596584349871,
      -0.0711301863193512,
      -0.06997204571962357,
      0.045408450067043304,
      0.0033809426240622997,
      -0.05653383582830429,
      -0.05059336498379707,
      -0.0018170980038121343,
      0.008229211904108524,
      -0.004268873482942581,
      0.0028976607136428356,
      0.0567309744656086,
      -0.018389198929071426,
      -0.019144179299473763,
      -0.023878060281276703,
      -0.014506075531244278,
      -0.008322383277118206,
      0.09344393014907837,
      0.01715368963778019,
      0.036260999739170074,
      0.0133593138307333,
      -0.04824680835008621,
      -0.026647349819540977,
      -0.044195786118507385,
      0.015374381095170975,
      -0.020410709083080292,
      0.07652267813682556,
      -0.039520263671875,
      0.028817277401685715,
      0.025767020881175995,
      0.009544909931719303,
      -0.0014001904055476189,
      -0.06630107760429382,
      0.08199058473110199,
      -0.03530782461166382,
      -0.011535882949829102,
      -0.05157743766903877,
      -0.015505785122513771,
      0.07457505911588669,
      0.06890832632780075,
      -0.011255716904997826,
      0.014817195944488049,
      0.05172860622406006,
      0.007218422368168831,
      0.05802852660417557,
      -0.004511052276939154,
      -0.020770682021975517,
      0.004817625507712364,
      0.01699252426624298,
      0.055988963693380356,
      0.058557309210300446,
      0.04536212608218193,
      0.028376316651701927,
      0.049733154475688934,
      -0.04079999774694443,
      -0.03978553041815758,
      -0.007210063748061657,
      -0.03244290500879288,
      0.03225930035114288,
      -0.01950731873512268,
      0.07178765535354614,
      0.007341699209064245,
      0.03625985234975815,
      0.008510719984769821,
      0.08637045323848724,
      0.03705911710858345,
      0.0407809242606163,
      0.08587063103914261,
      0.08440060913562775,
      0.024808593094348907,
      -0.006263151299208403,
      0.07721585780382156,
      -0.0653308853507042,
      -0.023392396047711372,
      -0.07522117346525192,
      0.10434745252132416,
      0.03760988265275955,
      0.005956338252872229,
      -0.026421885937452316,
      0.07083727419376373,
      -0.00576883926987648,
      0.04994899407029152,
      0.07029081135988235,
      0.02569562755525112,
      0.02290813811123371,
      0.004547609481960535,
      -0.030173778533935547,
      -0.04368883743882179,
      -0.03223428502678871,
      -0.02254650741815567,
      -0.05600377544760704,
      0.046057719737291336,
      -0.0473315492272377,
      -0.06522160023450851,
      -0.04744472727179527,
      -0.014438607729971409,
      -0.010609484277665615,
      0.006485399324446917,
      0.04126767814159393,
      0.011698419228196144,
      0.06714050471782684,
      -0.03164088726043701,
      0.03148513287305832,
      0.013995649293065071,
      0.06745880097150803,
      0.05012516304850578,
      0.03880913928151131,
      0.026444336399435997,
      -0.010509977117180824,
      -0.04351162537932396,
      -0.028895171359181404,
      -0.07658255100250244,
      -0.048278629779815674,
      0.06004328280687332,
      0.02766914665699005,
      0.0010203187121078372,
      0.04429536685347557,
      -0.020996196195483208,
      0.05869192257523537,
      -0.03706514462828636,
      -0.002420786302536726,
      0.07097181677818298,
      -0.02229408174753189,
      0.044356148689985275,
      -0.050972625613212585,
      -0.023010343313217163,
      -0.03409934043884277,
      -0.018147610127925873,
      -0.001375101855956018,
      0.06258705258369446,
      -0.041660893708467484,
      0.01729639619588852,
      0.08277859538793564,
      0.03495890274643898,
      -0.10175775736570358,
      0.002586487215012312,
      -0.01648273691534996,
      0.0421065054833889,
      -0.06817176938056946,
      -0.001151809934526682,
      0.032623693346977234,
      0.02675122581422329,
      0.031508803367614746,
      0.010317112319171429,
      -0.031990714371204376,
      0.03387346863746643,
      -0.009476368315517902,
      0.07387909293174744,
      -0.03289583697915077,
      -0.06248317286372185,
      0.004235254600644112,
      0.04196406900882721,
      -0.07806982100009918,
      0.024153653532266617,
      0.024316884577274323,
      -0.018861817196011543,
      0.08366597443819046,
      -0.006522212643176317,
      0.050420068204402924,
      -0.053410835564136505,
      -0.009806336835026741,
      0.03172982856631279,
      -0.049986582249403,
      0.05216450244188309,
      0.03592964634299278,
      0.055287376046180725,
      0.0711619034409523,
      0.06383345276117325,
      -0.022444268688559532,
      -0.06263891607522964,
      0.03390130028128624,
      0.05882803723216057,
      -0.054232146590948105,
      0.0931423231959343,
      0.0005556830437853932,
      -0.05827204883098602,
      -0.004270074889063835,
      -0.0389523059129715,
      -0.026919029653072357,
      0.013412661850452423,
      -0.06133059412240982,
      0.008824803866446018,
      0.010255911387503147,
      -0.008049415424466133,
      0.012898573651909828,
      -0.05315796658396721,
      0.04693959280848503,
      0.007303346414119005,
      -0.040206823498010635,
      -0.054162345826625824,
      0.0037700894754379988,
      0.03313208743929863,
      -0.034176819026470184,
      -0.0711333304643631,
      -0.018112745136022568,
      0.06091168895363808,
      0.052217233926057816,
      -0.038771577179431915,
      0.057772424072027206,
      -0.03852943703532219,
      0.01240034680813551,
      0.08745542168617249,
      -0.000668090651743114,
      -0.01815708540380001,
      0.05704757943749428,
      0.031199943274259567,
      0.0240219347178936,
      -0.03774602711200714,
      0.02825668826699257
    ],
    [
      -0.028757216408848763,
      -0.017342032864689827,
      -0.017494620755314827,
      -0.035695161670446396,
      0.0001937935157911852,
      -0.0005960423150099814,
      -0.10667309165000916,
      -0.07604289799928665,
      -0.012479515746235847,
      0.0072759236209094524,
      -0.06220018491148949,
      0.0017709729727357626,
      -0.06581379473209381,
      0.02225562185049057,
      0.04366597160696983,
      -0.07855221629142761,
      -0.0014857974601909518,
      -0.02234495058655739,
      -0.03206857666373253,
      0.04888654872775078,
      0.07763287425041199,
      -0.04550605267286301,
      -0.017931869253516197,
      -0.021096277981996536,
      0.06303581595420837,
      -0.0668177381157875,
      0.02802993729710579,
      -0.0044242627918720245,
      0.02039221301674843,
      -0.0117011284455657,
      0.028101889416575432,
      0.010347872041165829,
      0.016662031412124634,
      -0.05018666386604309,
      -0.05304078757762909,
      -0.021700017154216766,
      -0.02269003540277481,
      -0.04676898196339607,
      0.017994698137044907,
      -0.017508134245872498,
      0.017255162820219994,
      -0.036187414079904556,
      -0.05533744394779205,
      0.04366875812411308,
      0.0458957739174366,
      0.01219301950186491,
      -0.021810142323374748,
      -0.11012724786996841,
      -0.017382025718688965,
      -0.05620303750038147,
      -0.0019511058926582336,
      0.03951430693268776,
      0.03231970593333244,
      0.01147795096039772,
      4.219033871777356e-05,
      -0.014603416435420513,
      -0.035610977560281754,
      0.11649196594953537,
      0.006609707605093718,
      -0.023117700591683388,
      -0.0601472407579422,
      0.024140039458870888,
      -0.005079885944724083,
      0.034077927470207214,
      0.04211391881108284,
      -0.022676847875118256,
      0.03931998088955879,
      -0.045426271855831146,
      0.00030462551512755454,
      -0.005731511395424604,
      0.012051570229232311,
      0.009404673241078854,
      -0.016486428678035736,
      -0.015870844945311546,
      0.03687632083892822,
      0.0152228819206357,
      -0.030297987163066864,
      0.025736458599567413,
      -0.056612443178892136,
      -0.07391202449798584,
      0.0041428059339523315,
      -0.013316965661942959,
      -0.02608640305697918,
      0.046492766588926315,
      -0.07131873816251755,
      -0.028652815148234367,
      0.025209371000528336,
      -0.08143699169158936,
      0.04551064595580101,
      0.014639259316027164,
      -0.09718094021081924,
      -0.03505090996623039,
      0.03112782910466194,
      0.013837478123605251,
      -0.01974421553313732,
      0.040681157261133194,
      -0.054655417799949646,
      0.011810561642050743,
      0.03519066050648689,
      0.032425154000520706,
      -0.09208936989307404,
      -0.02900807559490204,
      -0.019826216623187065,
      -0.06809403747320175,
      0.12371670454740524,
      -0.042761098593473434,
      -0.011613121256232262,
      -0.0515017956495285,
      0.012570963241159916,
      -0.08409135043621063,
      0.07339867949485779,
      0.0023445303086191416,
      0.004385527689009905,
      0.047789283096790314,
      -0.09155892580747604,
      -0.05311799421906471,
      -0.030864279717206955,
      -0.04560849815607071,
      0.040313199162483215,
      0.05031174421310425,
      0.00555446045473218,
      -0.08527109771966934,
      0.03568016365170479,
      0.09547283500432968,
      -0.043135255575180054,
      -0.05903619900345802,
      0.01504866499453783,
      0.018959803506731987,
      -0.010207384824752808,
      -0.0038795368745923042,
      -0.0453399233520031,
      0.0023259848821908236,
      -0.03697442635893822,
      -0.02025936357676983,
      -0.019243311136960983,
      -0.03635155037045479,
      -0.021855870261788368,
      -0.027097955346107483,
      0.036993369460105896,
      -0.010177535004913807,
      -0.10351833701133728,
      0.058952055871486664,
      0.12769651412963867,
      0.04201924800872803,
      -0.0793314054608345,
      -0.02705557271838188,
      -0.04645461589097977,
      0.03875571861863136,
      0.06672541052103043,
      -0.019890235736966133,
      0.008658545091748238,
      -0.03434973582625389,
      -0.01683666557073593,
      -0.033222850412130356,
      -0.042704299092292786,
      -0.11942391097545624,
      0.020911995321512222,
      0.04195600003004074,
      0.04335089027881622,
      0.04267256334424019,
      0.03210090473294258,
      -0.0036504711024463177,
      0.007193568628281355,
      -0.04741663485765457,
      0.05098444223403931,
      -0.013124112039804459,
      0.014332293532788754,
      -0.011653038673102856,
      0.07166049629449844,
      -0.0427459254860878,
      0.025616653263568878,
      -0.02916584722697735,
      0.0745006874203682,
      0.025287916883826256,
      -0.027623608708381653,
      0.0371132418513298,
      0.027149073779582977,
      -0.022159965708851814,
      0.023342056199908257,
      0.04120650887489319,
      0.030249634757637978,
      0.03536611050367355,
      0.026434022933244705,
      -0.027019429951906204,
      -0.04333047568798065,
      0.057156503200531006,
      -0.01646665669977665,
      0.024025317281484604,
      0.03347787633538246,
      0.07934415340423584,
      0.07043134421110153,
      -0.0007563765393570065,
      0.04019424691796303,
      -0.010176587849855423,
      0.04221660643815994,
      0.018587887287139893,
      0.037754010409116745,
      -0.029982389882206917,
      0.0256833303719759,
      -0.02691076509654522,
      0.05562977492809296,
      0.009967084042727947,
      -0.08258567750453949,
      0.06956588476896286,
      0.012441067025065422,
      0.04228612780570984,
      0.0061296122148633,
      0.0011501179542392492,
      0.05372701585292816,
      0.005667735822498798,
      0.009779106825590134,
      0.031087534502148628,
      -0.06484803557395935,
      0.04860563576221466,
      -0.0026821414940059185,
      0.05461829528212547,
      0.016301214694976807,
      0.005737051833420992,
      -0.01886124536395073,
      -0.041371800005435944,
      -0.06954531371593475,
      0.021895108744502068,
      -0.0873321145772934,
      0.025622058659791946,
      0.0627734512090683,
      -0.040611255913972855,
      -0.02090335823595524,
      -0.01781945675611496,
      0.020875945687294006,
      -0.028398588299751282,
      -0.03276955708861351,
      0.054720595479011536,
      0.004204506520181894,
      0.029820088297128677,
      -0.07049159705638885,
      0.0032161884009838104,
      -0.03301374614238739,
      0.0252798181027174,
      0.017499463632702827,
      0.004277572967112064,
      -0.07140842080116272,
      0.027649017050862312,
      0.04742590710520744,
      0.04255238547921181,
      -0.10066661983728409,
      0.03473953157663345,
      -0.02788175828754902,
      -0.031638335436582565,
      -0.03067811019718647,
      -0.021548926830291748,
      0.031413886696100235,
      -0.024115288630127907,
      0.0382811464369297,
      0.025650547817349434,
      0.01714559644460678,
      0.03665988892316818,
      0.03350115567445755,
      0.006761325057595968,
      0.04675687476992607,
      -0.0503191202878952,
      -0.07349003106355667,
      0.007545124739408493,
      0.035981979221105576,
      0.02233022451400757,
      0.008900250308215618,
      -0.05992251634597778,
      -0.014366010203957558,
      -0.10729844868183136,
      0.07788190990686417,
      -0.06313598901033401,
      0.040666911751031876,
      0.04970242828130722,
      0.04874907433986664,
      -0.054236654192209244,
      -0.016336891800165176,
      -0.009128578007221222,
      0.04011903330683708,
      0.05766192451119423,
      0.002690799767151475,
      0.04161562770605087,
      0.022880328819155693,
      -0.060609541833400726,
      0.025178048759698868,
      0.16611675918102264,
      -0.06976734846830368,
      0.03099951706826687,
      0.02655279077589512,
      0.07144615054130554,
      0.03430153802037239,
      -0.002536892192438245,
      -0.03631342947483063,
      0.02791925147175789,
      0.04485360532999039,
      0.010461557656526566,
      0.07745635509490967,
      0.04022933542728424,
      0.022402798756957054,
      0.04431137815117836,
      0.044757429510354996,
      0.0077427406795322895,
      -0.02433199994266033,
      0.05813303217291832,
      -0.10110960900783539,
      -0.0006560978363268077,
      -0.08596261590719223,
      -0.039807967841625214,
      -0.005745384842157364,
      -0.02098485827445984,
      0.03254403918981552,
      -0.03556056320667267,
      -0.036452170461416245,
      -0.10600055009126663,
      -0.08140897750854492,
      -0.061603471636772156,
      0.022607430815696716,
      -0.0437614768743515,
      -0.02460532821714878,
      -0.05192352086305618,
      -0.03368908166885376,
      -0.057886794209480286,
      0.05029312148690224,
      0.011437947861850262,
      0.01588960736989975,
      -0.009523154236376286,
      -0.04409263655543327,
      0.0020882836543023586,
      0.009070305153727531,
      -0.03641533479094505,
      -0.020435268059372902,
      -0.12361601740121841,
      -0.005494444631040096,
      -0.0353928841650486,
      0.06946219503879547,
      0.04830700904130936,
      -0.014466164633631706,
      -0.051948316395282745,
      -0.007611245382577181,
      0.04567607119679451,
      0.02675050124526024,
      -0.012751431204378605,
      -0.007434065453708172,
      -0.026185467839241028,
      0.03915725275874138,
      0.052978891879320145,
      -0.041506826877593994,
      -0.07039184868335724,
      0.00023605355818290263,
      0.02803388610482216,
      0.020635530352592468,
      0.015159462578594685,
      0.009398601949214935,
      -0.03480410575866699,
      -0.005497412756085396,
      0.017847556620836258,
      0.008149761706590652,
      0.053770627826452255,
      0.057632993906736374,
      0.009480142965912819,
      -0.021882828325033188,
      -0.11797244101762772,
      0.002904432825744152,
      -0.058674801141023636,
      -0.034019194543361664,
      -0.022866128012537956,
      -0.01652875915169716,
      -0.06408960372209549,
      -0.05138514190912247,
      0.12988106906414032,
      0.10489612072706223,
      0.035566750913858414,
      -0.036786362528800964,
      0.021560484543442726,
      0.020504405722022057,
      -0.05133730173110962,
      0.05835585296154022,
      -0.03915281593799591,
      0.042607035487890244,
      -0.012673106044530869,
      -0.06014016643166542,
      -0.007324896287173033,
      0.0312051884829998,
      0.029904725030064583,
      -0.035687532275915146,
      0.05351461470127106,
      0.1119142696261406,
      -0.03148171305656433,
      -0.017837394028902054,
      0.05325629189610481,
      0.051224235445261,
      -0.015823395922780037,
      -0.06184912845492363,
      -0.01364168431609869,
      0.04390638694167137,
      0.006798032205551863,
      -0.015041164122521877,
      0.035673242062330246,
      -0.08163653314113617,
      0.04232025519013405,
      0.02084987796843052,
      0.008202404715120792,
      0.01484203152358532,
      0.06628204882144928,
      0.033906206488609314,
      0.029449056833982468,
      -0.034558117389678955,
      0.011163594201207161,
      0.0448375828564167,
      0.11363798379898071,
      -0.03484159708023071,
      0.04254758730530739,
      -0.0012941337190568447,
      -0.040190692991018295,
      0.0272340327501297,
      -0.04482192546129227,
      -0.04671263322234154,
      -0.08365590125322342,
      -0.015797805041074753,
      -0.014952754601836205,
      0.10540306568145752,
      -0.016641143709421158,
      0.05685179680585861,
      0.03477402403950691,
      0.022657645866274834,
      0.008401773869991302,
      0.09196829050779343,
      0.0024452917277812958,
      -0.03557819500565529,
      0.06885512918233871,
      -0.0965523049235344,
      0.03372008353471756,
      0.12268414348363876,
      0.05712965503334999,
      0.027549663558602333,
      -0.022844919934868813,
      -0.04412852227687836,
      0.03333425521850586,
      0.057014334946870804,
      -0.011311975307762623,
      0.007743710186332464,
      0.028877360746264458,
      0.07669670879840851,
      -0.03285713121294975,
      0.03606371209025383,
      -0.005823865532875061,
      0.049504075199365616,
      -0.023121505975723267,
      0.03787945955991745,
      0.01382677722722292,
      0.008432503789663315,
      -0.03632622957229614,
      -0.006527699064463377,
      0.01728823222219944,
      0.007211066782474518,
      0.09835692495107651,
      -0.08826737105846405,
      -0.026904869824647903,
      0.03584367781877518,
      -0.02397354505956173,
      0.04101799428462982,
      -0.047317296266555786,
      0.022448286414146423,
      0.029313044622540474,
      0.009564192965626717,
      -0.09487331658601761,
      0.015936359763145447,
      0.006166447885334492,
      0.04477894306182861,
      -0.052636440843343735,
      -0.02321360819041729,
      0.03796425089240074,
      0.04335051029920578,
      -0.0002919756807386875,
      0.01909368485212326,
      0.05668963864445686,
      -0.03588812053203583,
      -0.008966481313109398,
      -0.0026931334286928177,
      -0.06561678647994995,
      0.01900404691696167,
      -0.009576723910868168,
      0.05867286026477814,
      0.050011876970529556,
      0.052513666450977325,
      -0.08134409040212631,
      -0.03465123474597931,
      -0.001277427189052105,
      -0.03764835372567177,
      0.009379295632243156,
      -0.044188372790813446,
      0.048318732529878616,
      0.006792853586375713,
      0.10057006776332855,
      -0.06151357293128967,
      0.009440799243748188,
      0.044871870428323746,
      -0.015087193809449673,
      -0.02016262523829937,
      -0.03520749509334564,
      -0.05505476891994476,
      0.0073540834710001945,
      0.00816657580435276,
      -0.034853119403123856,
      -0.024282971397042274
    ],
    [
      -0.0022624738048762083,
      0.02684735506772995,
      0.07773496210575104,
      -0.05654255300760269,
      0.012250409461557865,
      -0.04633113741874695,
      0.027747048065066338,
      0.019429512321949005,
      -0.09784694015979767,
      0.04389128461480141,
      -0.02773653157055378,
      -0.026525447145104408,
      -0.0206131674349308,
      -0.01617451012134552,
      -0.031776927411556244,
      0.062225669622421265,
      0.005220621824264526,
      0.03857938200235367,
      -0.00905473344027996,
      -0.05015701800584793,
      0.039856646209955215,
      0.034880030900239944,
      -0.03415422514081001,
      0.021447988227009773,
      -0.07552498579025269,
      0.026949089020490646,
      -0.040492184460163116,
      -0.003203802742063999,
      0.00015464187890756875,
      0.00044191168854013085,
      -0.006159653887152672,
      -0.015796536579728127,
      -0.11283138394355774,
      0.001839107135310769,
      0.08909408003091812,
      -0.022102585062384605,
      0.0030639285687357187,
      -0.010787207633256912,
      0.021762985736131668,
      -0.02152847871184349,
      -0.026209421455860138,
      -0.03823539987206459,
      0.024554269388318062,
      0.0392574742436409,
      0.022412879392504692,
      -0.03576557710766792,
      0.024858396500349045,
      0.03212849423289299,
      0.0367891862988472,
      0.007283797021955252,
      -0.062292758375406265,
      -0.04000834748148918,
      0.019411737099289894,
      -0.01754789799451828,
      0.09610285609960556,
      -0.08294019848108292,
      0.038659438490867615,
      -0.04981866851449013,
      0.012620792724192142,
      0.08879189193248749,
      0.0117197809740901,
      0.033341795206069946,
      0.011802112683653831,
      0.042146336287260056,
      -0.03206188976764679,
      -0.020567888393998146,
      -0.03438439592719078,
      0.04360957071185112,
      0.05462835356593132,
      0.15196678042411804,
      -0.025671513751149178,
      0.031241798773407936,
      -0.03567763417959213,
      0.00036952548543922603,
      0.01761920005083084,
      -0.04746873676776886,
      0.02325034700334072,
      0.07748747617006302,
      -0.0911613255739212,
      0.00980677641928196,
      -0.0023223874159157276,
      0.07925014942884445,
      0.025400057435035706,
      0.001201020902954042,
      0.0005822789971716702,
      0.009356169030070305,
      0.008947237394750118,
      -0.0760515108704567,
      -0.026444213464856148,
      -0.025620320811867714,
      0.013421005569398403,
      -0.014151698909699917,
      0.0020184556487947702,
      -0.07997612655162811,
      0.016641639173030853,
      0.10589531809091568,
      -0.024778680875897408,
      -0.08289328217506409,
      -0.01756739243865013,
      -0.08385943621397018,
      0.05632972717285156,
      -0.004403522703796625,
      -0.035117413848638535,
      -0.02446693368256092,
      0.06706250458955765,
      0.0005226366338320076,
      0.04281298816204071,
      0.03485382720828056,
      -0.007247581146657467,
      -0.06603420525789261,
      -0.02797061949968338,
      0.06481785327196121,
      0.028779082000255585,
      0.020699407905340195,
      -0.0702558234333992,
      0.043945904821157455,
      0.05323595926165581,
      -0.0034731319174170494,
      0.010214109905064106,
      -0.045697882771492004,
      -0.010620476678013802,
      0.0409824401140213,
      -0.07336661964654922,
      -0.0020242086611688137,
      -0.016817616298794746,
      -0.04166875034570694,
      -0.009310955181717873,
      0.030359040945768356,
      0.08071557432413101,
      0.08070287108421326,
      -0.051547177135944366,
      -0.0006173522560857236,
      0.05104939267039299,
      -0.02549879252910614,
      -0.001662260270677507,
      -0.04716779291629791,
      -0.06589151173830032,
      -0.03538068011403084,
      0.013165052980184555,
      -0.02655905857682228,
      -0.001344035379588604,
      0.0006011450896039605,
      -0.010718257166445255,
      -0.05407105013728142,
      -0.04717608913779259,
      -0.06527011841535568,
      -0.0502721443772316,
      -0.05304335430264473,
      -0.007691843900829554,
      -0.03348143771290779,
      0.06196426972746849,
      -0.02065015584230423,
      0.0703703910112381,
      0.019770940765738487,
      -0.03364010900259018,
      -0.0080639086663723,
      0.015356435440480709,
      0.0009810318006202579,
      -0.055217109620571136,
      0.04097814857959747,
      0.04359328746795654,
      -0.04560403525829315,
      0.05474244803190231,
      -0.02978745475411415,
      -0.0271132979542017,
      -0.02100510522723198,
      0.0872282162308693,
      -0.024162277579307556,
      -0.0398312509059906,
      0.03039189800620079,
      -0.012543899938464165,
      0.004152724519371986,
      0.06527211517095566,
      0.031449731439352036,
      0.014994907192885876,
      -0.004165377933532,
      0.05286242067813873,
      -0.026156898587942123,
      -0.03848771005868912,
      0.005317797884345055,
      -0.015346186235547066,
      -0.029455283656716347,
      0.04316406324505806,
      -0.05548543855547905,
      -0.05349373072385788,
      0.029758179560303688,
      0.044949971139431,
      -0.02003517560660839,
      -0.021878153085708618,
      -0.021890845149755478,
      0.051510319113731384,
      0.009171774610877037,
      -0.00836759340018034,
      0.02101326920092106,
      0.02348666824400425,
      -0.07283623516559601,
      0.05552233010530472,
      -0.06204654648900032,
      0.025783812627196312,
      -0.07444099336862564,
      -0.02769131027162075,
      -0.02490890957415104,
      -0.0008784266537986696,
      0.04327624291181564,
      0.00027555073029361665,
      0.060570985078811646,
      -0.03850110247731209,
      -0.018189920112490654,
      0.006262833718210459,
      0.0119024021551013,
      0.054296642541885376,
      -0.0239261481910944,
      0.03933883085846901,
      0.03680209442973137,
      -0.04674813151359558,
      0.0038458663038909435,
      0.032296713441610336,
      0.0486355759203434,
      0.05667613819241524,
      -0.0385923758149147,
      -0.11693743616342545,
      0.06646610051393509,
      -0.061696723103523254,
      -0.016612404957413673,
      0.029575716704130173,
      -0.016352057456970215,
      0.029112668707966805,
      0.031451515853405,
      0.02977493219077587,
      -0.012471506372094154,
      -0.04576476290822029,
      0.0679749846458435,
      -0.03597434610128403,
      0.07702885568141937,
      -0.07379916310310364,
      -0.023314103484153748,
      -0.03643421828746796,
      0.002201976953074336,
      -0.06905364245176315,
      -0.012698836624622345,
      -0.03381768614053726,
      0.0665825605392456,
      0.01454716082662344,
      -0.01059071533381939,
      0.0713839903473854,
      0.01514018326997757,
      0.027459774166345596,
      0.031186353415250778,
      0.049757860600948334,
      0.022012360394001007,
      -0.0011668005026876926,
      0.004704898223280907,
      0.07430502027273178,
      0.044074755162000656,
      -0.016248716041445732,
      -0.030209796503186226,
      0.07676228135824203,
      -0.004786086268723011,
      -0.01360130775719881,
      -0.024850670248270035,
      0.04088420048356056,
      0.00749283516779542,
      0.09055124968290329,
      0.02661093883216381,
      -0.06631594151258469,
      -0.009448806755244732,
      0.01407532300800085,
      0.04814353957772255,
      0.029826903715729713,
      0.08913292735815048,
      0.046379897743463516,
      0.025381261482834816,
      -0.04999437928199768,
      0.012442345730960369,
      -0.023939266800880432,
      0.025359107181429863,
      -0.017770787701010704,
      0.0494203083217144,
      0.019180281087756157,
      0.03358137235045433,
      0.035434626042842865,
      0.005164163652807474,
      0.002800561720505357,
      -0.036117203533649445,
      -0.030927160754799843,
      -0.002170746913179755,
      0.11395000666379929,
      0.02656356245279312,
      -0.08173952251672745,
      -0.04782405123114586,
      -0.05010079964995384,
      0.03327086567878723,
      0.029162827879190445,
      0.044693607836961746,
      0.02697893977165222,
      0.006276346743106842,
      -0.0937674343585968,
      -0.05095864087343216,
      0.04378199204802513,
      -0.03264686465263367,
      0.012951813638210297,
      0.016033727675676346,
      -0.06663358956575394,
      0.01661468669772148,
      -0.0234194565564394,
      -0.025102825835347176,
      -0.03600388020277023,
      0.0011989810736849904,
      -0.0007049259729683399,
      0.01238463819026947,
      -0.08636850118637085,
      0.008633957244455814,
      0.05222045257687569,
      0.040784794837236404,
      0.00923113338649273,
      -0.05482969060540199,
      0.03197099640965462,
      -0.03213084861636162,
      0.011695804074406624,
      0.012491433881223202,
      -0.002881418215110898,
      0.053106363862752914,
      0.04735222086310387,
      -0.06739713251590729,
      -0.017369166016578674,
      -0.041562922298908234,
      -0.0014357682084664702,
      -0.036676518619060516,
      0.06074317544698715,
      -0.04969458654522896,
      -0.018584487959742546,
      0.012179914861917496,
      -0.02884530834853649,
      0.001459736842662096,
      -0.009954878129065037,
      -0.0006800612318329513,
      0.06658049672842026,
      -0.06520877033472061,
      -0.019410772249102592,
      0.05060603469610214,
      0.03985433652997017,
      0.06064743921160698,
      -0.01070079393684864,
      -0.04122626781463623,
      0.004294727463275194,
      -0.019648652523756027,
      -0.03124641813337803,
      0.07976357638835907,
      0.05899018421769142,
      -0.010299867950379848,
      0.02806376852095127,
      0.032621607184410095,
      0.01746118813753128,
      -0.042309992015361786,
      0.07367115467786789,
      0.013397268019616604,
      0.07004809379577637,
      -0.01120078843086958,
      0.1195417195558548,
      -0.02990933693945408,
      0.02916472591459751,
      0.02332376502454281,
      -0.0727364793419838,
      0.05702424794435501,
      -0.026594318449497223,
      -0.06279896199703217,
      -0.033001724630594254,
      0.0030711430590599775,
      -0.006119986064732075,
      -0.0536777600646019,
      0.005439132452011108,
      -0.05474952235817909,
      0.055862005800008774,
      0.0028213371988385916,
      0.012551737017929554,
      0.014162584207952023,
      -0.044747110456228256,
      -0.05429384484887123,
      -0.01898728311061859,
      0.0008084760629571974,
      0.12791509926319122,
      0.03862318396568298,
      0.03516456112265587,
      0.03579479083418846,
      -0.0072627016343176365,
      -0.09284423291683197,
      0.02492769993841648,
      -0.011034716852009296,
      -0.002796132117509842,
      0.07949402928352356,
      -0.04935379698872566,
      0.05529669672250748,
      0.09958604723215103,
      -0.03610185906291008,
      0.025327246636152267,
      -0.025484982877969742,
      -0.04678093269467354,
      -0.0019632321782410145,
      -0.02899397350847721,
      0.011036932468414307,
      0.11176469922065735,
      0.10172969847917557,
      0.07424258440732956,
      -0.05350944399833679,
      -0.012342819944024086,
      -0.013893497176468372,
      -0.018743641674518585,
      0.05027389898896217,
      -0.04878382384777069,
      -0.04450920969247818,
      -0.09068699181079865,
      -0.01939135417342186,
      0.11757375299930573,
      -0.011333998292684555,
      0.00825810432434082,
      0.04687216505408287,
      0.0679808035492897,
      -0.07761743664741516,
      -0.06918632984161377,
      -0.06504584103822708,
      -0.07323396950960159,
      0.03819741681218147,
      -0.015600187703967094,
      0.013079523108899593,
      0.020555969327688217,
      0.03970714658498764,
      0.003115179715678096,
      0.105674609541893,
      0.041244130581617355,
      -0.08025270700454712,
      -0.02733694389462471,
      0.039699800312519073,
      -0.05548245459794998,
      -0.04800335690379143,
      -0.018407979980111122,
      0.07774457335472107,
      -0.021999536082148552,
      -0.035961754620075226,
      -0.02778315544128418,
      0.0617920458316803,
      0.014471485279500484,
      0.03226267546415329,
      0.03715634346008301,
      0.04557691141963005,
      0.07233929634094238,
      0.05859377235174179,
      -0.011935338377952576,
      0.0849052146077156,
      -0.01697605662047863,
      0.06765393912792206,
      -0.004347628448158503,
      0.08629124611616135,
      -0.06791288405656815,
      0.03192579001188278,
      -0.03995804116129875,
      -0.06945910304784775,
      0.05118868127465248,
      0.03533557802438736,
      -0.009617486968636513,
      0.03258112445473671,
      0.09182083606719971,
      0.022260058671236038,
      0.022224804386496544,
      0.01811351627111435,
      -0.017708944156765938,
      -0.03832927346229553,
      0.06475484371185303,
      0.012884012423455715,
      -0.11067689955234528,
      -0.06335292011499405,
      0.061262521892786026,
      -0.03865796700119972,
      0.0246927198022604,
      -0.05460925027728081,
      0.04350971058011055,
      0.053232379257678986,
      -0.022482020780444145,
      -0.04096516594290733,
      -0.036684293299913406,
      0.03540545329451561,
      0.12323463708162308,
      -0.012089434079825878,
      0.02615855261683464,
      0.042600858956575394,
      0.03645642474293709,
      0.0725441426038742,
      -0.026911186054348946,
      -0.01987486332654953,
      -0.0607716403901577,
      -0.08188842236995697,
      0.025662096217274666,
      0.06139460578560829,
      -0.04011912643909454,
      -0.048203371465206146,
      0.0739511102437973,
      0.05763613432645798,
      0.007249411661177874,
      -0.028899190947413445,
      -0.025829320773482323,
      -0.02590429224073887,
      -0.09818366169929504,
      0.042266011238098145,
      0.04246225208044052
    ],
    [
      -0.06466856598854065,
      -0.046834614127874374,
      0.020014449954032898,
      -0.008833030238747597,
      0.06387553364038467,
      -0.024874143302440643,
      -0.06741229444742203,
      0.021982450038194656,
      0.006076586898416281,
      -0.016508923843503,
      0.017521899193525314,
      0.02808225154876709,
      -0.024302277714014053,
      -0.03641889989376068,
      -0.005444308277219534,
      -0.0530882366001606,
      0.05006987974047661,
      0.02795216254889965,
      0.05435682833194733,
      0.0065014963038265705,
      0.016421228647232056,
      -0.019483555108308792,
      0.021297568455338478,
      0.03527464717626572,
      0.0043701957911252975,
      -0.0035999121610075235,
      -0.027701249346137047,
      -0.028693044558167458,
      -0.04191519320011139,
      -0.03022039867937565,
      -0.0695786401629448,
      0.04066953808069229,
      -0.04107179120182991,
      -0.009224668145179749,
      -0.0755913183093071,
      0.043531633913517,
      0.01743922382593155,
      -0.09510532021522522,
      -0.01623695157468319,
      -0.00010400891915196553,
      0.04067303240299225,
      -0.04602140188217163,
      0.07984883338212967,
      0.11860209703445435,
      -0.03143833950161934,
      0.05604580417275429,
      -0.03995238617062569,
      -0.036940909922122955,
      -0.04868244379758835,
      -0.1023150235414505,
      -0.006730229593813419,
      0.03447045013308525,
      -0.021677788347005844,
      0.033410005271434784,
      -0.013926059938967228,
      -0.06314098089933395,
      0.026536233723163605,
      -0.07404759526252747,
      -0.07956120371818542,
      -0.05564066022634506,
      0.02079559490084648,
      0.040285561233758926,
      -0.021238967776298523,
      -0.09995293617248535,
      0.015880072489380836,
      0.03428099304437637,
      0.10049382597208023,
      -0.03563671559095383,
      -0.03065379150211811,
      0.06661457568407059,
      -0.04028531163930893,
      -0.03901885822415352,
      0.061153676360845566,
      -0.05950956791639328,
      -0.04098106175661087,
      0.026700036600232124,
      -0.0028168540447950363,
      0.0032260583247989416,
      0.023037180304527283,
      -0.009835468605160713,
      -0.018030043691396713,
      -0.012328122742474079,
      -0.025445949286222458,
      0.024945860728621483,
      -0.009711048565804958,
      0.05985167250037193,
      0.047389835119247437,
      -0.07106049358844757,
      -0.0800178274512291,
      -0.012461245059967041,
      -0.053911130875349045,
      0.06787391752004623,
      -0.02846517600119114,
      -0.010385524481534958,
      0.03224408999085426,
      0.06288758665323257,
      0.003394924569875002,
      -0.026996640488505363,
      0.001281578792259097,
      0.012823138386011124,
      0.04146289825439453,
      -0.034370776265859604,
      0.00532899284735322,
      -0.015228388831019402,
      0.07462186366319656,
      -0.008123614825308323,
      -0.09379363805055618,
      -0.06590797007083893,
      0.0474364273250103,
      0.010103970766067505,
      -0.03971222788095474,
      0.017252065241336823,
      0.0900149866938591,
      0.0032887496054172516,
      -0.03903060033917427,
      0.05554686114192009,
      -0.08014357089996338,
      0.0005600186996161938,
      0.022522522136569023,
      -0.040225546807050705,
      0.05192408710718155,
      0.0038756937719881535,
      0.04679810628294945,
      0.04873928800225258,
      -0.0021773320622742176,
      0.0030724278185516596,
      0.015039517544209957,
      0.03237232193350792,
      -0.01802821457386017,
      -0.033788051456213,
      0.043272022157907486,
      -0.03190372511744499,
      -0.006937780883163214,
      -0.06447725743055344,
      -0.019736334681510925,
      0.005512504372745752,
      0.046059854328632355,
      0.01919257827103138,
      -0.07308368384838104,
      0.02242489717900753,
      -0.06424205750226974,
      -0.0010462970240041614,
      0.046419646590948105,
      0.04575683921575546,
      0.015750907361507416,
      -0.0330418199300766,
      -0.03235906362533569,
      -0.05177927389740944,
      0.003550943685695529,
      -0.08296272158622742,
      -0.021758319810032845,
      -0.005956011824309826,
      0.008147112093865871,
      -0.04409179836511612,
      -0.04434654489159584,
      -0.018595824018120766,
      0.014558062888681889,
      0.019525395706295967,
      -0.011889259330928326,
      -0.0011823331005871296,
      0.005403549410402775,
      0.023564377799630165,
      0.12063627690076828,
      0.10214333981275558,
      0.04174201190471649,
      0.01781313307583332,
      -0.0950191393494606,
      -0.014905272051692009,
      -0.046688977628946304,
      0.03684831038117409,
      0.02235497161746025,
      0.0530499592423439,
      -0.0011273802956566215,
      0.019443536177277565,
      0.02703896164894104,
      0.03815263509750366,
      -0.02615770697593689,
      -0.025664519518613815,
      0.013291917741298676,
      -0.043115291744470596,
      0.006751480046659708,
      0.0009561061160638928,
      0.026277529075741768,
      0.031680744141340256,
      -0.06912309676408768,
      0.09822020679712296,
      -0.05595066025853157,
      -0.01722976751625538,
      -0.05484689027070999,
      -0.004479807801544666,
      -0.08683174848556519,
      0.004826434887945652,
      -0.024488672614097595,
      -0.0540422648191452,
      -0.0307771023362875,
      -0.008699590340256691,
      -0.08982666581869125,
      0.019301312044262886,
      0.16263632476329803,
      -0.04424849525094032,
      0.0615965835750103,
      0.023239262402057648,
      -0.09575009346008301,
      -0.02004796266555786,
      -0.013700636103749275,
      -0.005310209933668375,
      -0.028777500614523888,
      0.04181516543030739,
      0.06075723469257355,
      -0.018426410853862762,
      -0.09388817846775055,
      0.04366955906152725,
      -0.06615427136421204,
      -0.04736831411719322,
      0.06635788828134537,
      -0.07294267416000366,
      -0.043125275522470474,
      -0.026713836938142776,
      0.01692952960729599,
      0.0061818999238312244,
      -0.014510801061987877,
      0.022486981004476547,
      -0.03752390667796135,
      0.12253611534833908,
      -0.04015661031007767,
      0.022738143801689148,
      0.010120382532477379,
      -0.00750137772411108,
      -0.06849606335163116,
      0.024114692583680153,
      0.036727745085954666,
      0.04462231695652008,
      -0.0022982992231845856,
      0.09295421838760376,
      0.03404644504189491,
      0.0001790770038496703,
      0.04294459521770477,
      -0.03892814368009567,
      0.049420081079006195,
      -0.02772112563252449,
      0.06368265300989151,
      -0.025939615443348885,
      -0.022624660283327103,
      0.023990314453840256,
      0.029703328385949135,
      -0.00986433681100607,
      0.005922156851738691,
      0.044487252831459045,
      -0.005782474763691425,
      -0.03563620150089264,
      0.0313992016017437,
      0.016026902943849564,
      0.07484892755746841,
      -0.048740189522504807,
      0.06971398741006851,
      0.011690356768667698,
      -0.06518981605768204,
      0.03081086091697216,
      0.00993133895099163,
      0.03331184387207031,
      0.05238322168588638,
      -0.022607346996665,
      -0.0004019402840640396,
      -0.02106732875108719,
      0.015841804444789886,
      0.008360300213098526,
      -0.010484143160283566,
      -0.023110676556825638,
      0.0020487927831709385,
      -0.0052183521911501884,
      0.0981326773762703,
      -0.032751116901636124,
      -0.09135482460260391,
      -0.007649417035281658,
      -0.0675441324710846,
      -0.09515484422445297,
      0.0012142423074692488,
      -0.02090325951576233,
      0.015100054442882538,
      -0.02847183495759964,
      0.04106598719954491,
      -0.03179633989930153,
      -0.11556686460971832,
      -0.05783245712518692,
      0.050960246473550797,
      0.08552569895982742,
      0.03001721203327179,
      -0.04285169020295143,
      0.02879176288843155,
      -0.025357723236083984,
      2.0381788999657147e-05,
      0.07137865573167801,
      -0.06612832099199295,
      0.029730796813964844,
      0.0765303522348404,
      0.07241076231002808,
      0.04769925773143768,
      0.05954524874687195,
      -0.05747116357088089,
      0.042340073734521866,
      -0.03828267380595207,
      -0.023981085047125816,
      0.0043863570317626,
      -0.03813571482896805,
      -0.015022831968963146,
      -0.03439059108495712,
      -0.06724119186401367,
      -0.03495286405086517,
      0.009551573544740677,
      0.0409950353205204,
      0.08625602722167969,
      0.021999765187501907,
      -0.020220842212438583,
      -0.1417456716299057,
      0.004794681910425425,
      -0.028218425810337067,
      0.04541249945759773,
      0.05891678109765053,
      -0.03209521248936653,
      0.017903463914990425,
      -0.025339754298329353,
      -0.024406788870692253,
      0.07069113105535507,
      0.010359135456383228,
      0.07655344903469086,
      -0.06625773012638092,
      -0.0389447882771492,
      0.041421275585889816,
      0.03802156075835228,
      -0.03984014317393303,
      -0.058261968195438385,
      -0.025986194610595703,
      -0.032490018755197525,
      -0.013688119128346443,
      0.07400032132863998,
      0.04213165119290352,
      0.01065998524427414,
      -0.057190172374248505,
      -0.020805954933166504,
      -0.044866468757390976,
      0.021620169281959534,
      -0.020446309819817543,
      -0.002555646002292633,
      -0.05721447244286537,
      0.08524106442928314,
      -0.0208833459764719,
      -0.023129336535930634,
      0.029003312811255455,
      -0.014906288124620914,
      -0.07625123113393784,
      -0.04057052358984947,
      -0.027731111273169518,
      0.015257303602993488,
      -0.03263348340988159,
      0.05988243222236633,
      -0.010461107827723026,
      0.015450107865035534,
      0.03680034726858139,
      0.01490446925163269,
      -0.04077168554067612,
      0.036198895424604416,
      0.049531131982803345,
      -0.018396548926830292,
      -0.027573509141802788,
      0.04725061357021332,
      -0.03349822387099266,
      -0.0012571054976433516,
      -0.012127297930419445,
      0.07203853130340576,
      0.030148450285196304,
      -0.047612495720386505,
      -0.038000475615262985,
      0.042717862874269485,
      0.02472775988280773,
      0.031321097165346146,
      0.0035829474218189716,
      0.00928920041769743,
      0.019354859367012978,
      0.005327390041202307,
      0.04101978987455368,
      -0.0588628426194191,
      0.005373555235564709,
      -0.02822301723062992,
      0.04752591624855995,
      -0.03775428608059883,
      -0.006818093359470367,
      -0.03618043288588524,
      0.04763716831803322,
      -0.020207898691296577,
      0.009639505296945572,
      -0.007298782933503389,
      -0.09084298461675644,
      0.09381973743438721,
      0.02194179780781269,
      0.052951645106077194,
      -0.020990073680877686,
      -0.029446827247738838,
      0.007155588828027248,
      -0.13965682685375214,
      -0.04387816786766052,
      0.06261581182479858,
      0.04872094839811325,
      -0.011655778624117374,
      0.04547588527202606,
      -0.029835740104317665,
      -0.03453010320663452,
      0.08201050013303757,
      -0.03568577021360397,
      0.002165933372452855,
      0.053021952509880066,
      -0.009756791405379772,
      -0.059818368405103683,
      0.05213722959160805,
      -0.019618945196270943,
      -0.06674087047576904,
      -0.006095296237617731,
      -0.01384841650724411,
      -0.03862527757883072,
      0.01896863803267479,
      0.03306116908788681,
      0.06442078948020935,
      0.07149861007928848,
      -0.033261775970458984,
      -0.032875314354896545,
      -0.041788991540670395,
      0.051521509885787964,
      -0.02844073809683323,
      0.04757699370384216,
      0.03814928978681564,
      -0.08425549417734146,
      0.06270573288202286,
      0.0168615709990263,
      -0.0008487632730975747,
      -0.05649060010910034,
      0.022489070892333984,
      0.01250011008232832,
      0.016354991123080254,
      0.03282012417912483,
      0.07906617969274521,
      -0.026143385097384453,
      0.024109363555908203,
      0.038417719304561615,
      0.02275978960096836,
      -0.004146556369960308,
      0.013948854990303516,
      -0.03954380750656128,
      0.004400218371301889,
      0.024720577523112297,
      0.007740182802081108,
      -0.03150346875190735,
      0.06722383946180344,
      -0.09889419376850128,
      0.01248130202293396,
      -0.02529120445251465,
      -0.006140019278973341,
      0.027783598750829697,
      -0.05736558139324188,
      0.05930154025554657,
      0.06567888706922531,
      0.005167407914996147,
      0.022283125668764114,
      0.004323358181864023,
      -0.02303370088338852,
      0.027773093432188034,
      0.010271042585372925,
      0.018202632665634155,
      0.01699936017394066,
      -0.029706671833992004,
      -0.0056952498853206635,
      -0.04234788939356804,
      0.056539811193943024,
      0.02431701123714447,
      -0.00038307931390590966,
      -0.001792008988559246,
      0.0423189140856266,
      -0.06608714163303375,
      -0.053118396550416946,
      -0.04196104779839516,
      0.04986393824219704,
      0.06056378409266472,
      0.015466294251382351,
      0.0926528051495552,
      0.006390298716723919,
      -0.007892107591032982,
      0.04905439540743828,
      0.09619579464197159,
      -0.09968811273574829,
      0.10709398984909058,
      -0.01973016932606697,
      -0.008498366922140121,
      -0.0027693307492882013,
      -0.018961012363433838,
      -0.055623434484004974,
      0.08578227460384369,
      0.03242524713277817,
      -0.03197512775659561,
      -0.014072796329855919,
      0.02381814271211624,
      0.07604943215847015,
      -0.027279820293188095,
      -0.041581325232982635,
      -0.03231673687696457,
      -0.000976631767116487
    ],
    [
      -0.014399770647287369,
      0.12260092794895172,
      -0.028313450515270233,
      -0.04725595563650131,
      -0.008912486024200916,
      0.014370569959282875,
      -0.012279554270207882,
      0.05455242469906807,
      0.044995829463005066,
      0.010140174068510532,
      -0.01962263323366642,
      -0.028440237045288086,
      -0.017156722024083138,
      0.058243636041879654,
      -0.09480081498622894,
      -0.02956591174006462,
      0.03892240300774574,
      0.0548275001347065,
      0.003269971814006567,
      0.021949052810668945,
      -0.06370123475790024,
      -0.015541504137217999,
      0.10388262569904327,
      -0.014734072610735893,
      0.01932242512702942,
      -0.04128227382898331,
      0.043630409985780716,
      0.050524406135082245,
      -0.010639259591698647,
      0.01740557886660099,
      -0.0442262627184391,
      0.029721450060606003,
      -0.026802409440279007,
      0.03253471478819847,
      0.014401535503566265,
      -0.04058832302689552,
      -0.0037109358236193657,
      0.06088029220700264,
      0.07235874235630035,
      -0.09077582508325577,
      0.010240976698696613,
      -0.021478872746229172,
      0.050585269927978516,
      -0.09545615315437317,
      -0.009663932956755161,
      -0.0005253925919532776,
      0.03506132587790489,
      -0.014028206467628479,
      -0.0068282741121947765,
      -0.07485472410917282,
      -0.003495725803077221,
      0.06364304572343826,
      0.04081326723098755,
      -0.002454174682497978,
      -0.0315091498196125,
      -0.0008383741369470954,
      0.004218078684061766,
      -0.053109344094991684,
      0.008055097423493862,
      -0.010090414434671402,
      0.047858044505119324,
      0.019349325448274612,
      -0.042501065880060196,
      -0.025699323043227196,
      0.04551640897989273,
      -0.032190095633268356,
      -0.0004865427908953279,
      0.01480860821902752,
      -0.039377275854349136,
      -0.056788649410009384,
      0.07938431948423386,
      -0.026858298107981682,
      0.035046130418777466,
      0.04765115678310394,
      -0.03901761770248413,
      0.04985339939594269,
      -0.004385527689009905,
      0.04295915365219116,
      -0.03571377322077751,
      -0.08754587918519974,
      0.08846645057201385,
      0.01649424619972706,
      0.07083098590373993,
      0.05051936209201813,
      0.001325154909864068,
      -0.03628525882959366,
      -0.09282571822404861,
      0.013849367387592793,
      -0.08030223846435547,
      0.0687790960073471,
      0.10592084378004074,
      0.0028923533391207457,
      0.08666692674160004,
      -0.0012246049009263515,
      -0.02621309831738472,
      0.10931452363729477,
      -0.013962335884571075,
      -0.00033038659603334963,
      -0.016685349866747856,
      -0.05458419397473335,
      -0.004036347847431898,
      0.075611412525177,
      0.07802355289459229,
      0.011016512289643288,
      0.09058785438537598,
      0.07015471905469894,
      -0.04953543096780777,
      0.022381113842129707,
      -0.04986058548092842,
      0.10259812325239182,
      -0.02745470590889454,
      0.04439055547118187,
      -0.02904880978167057,
      0.011307152919471264,
      0.04957384243607521,
      -0.012104895897209644,
      -0.0634891539812088,
      0.014464679174125195,
      -0.03042932040989399,
      -0.058188822120428085,
      -0.0012492769164964557,
      -0.032645903527736664,
      0.004167931620031595,
      0.019194237887859344,
      0.003552026581019163,
      -0.029698261991143227,
      -0.03361227735877037,
      -0.004559188615530729,
      0.034060217440128326,
      0.046138886362314224,
      0.014895481988787651,
      0.06703805178403854,
      0.01581559143960476,
      -0.02253684587776661,
      0.012214078567922115,
      0.09088683128356934,
      -0.02764863334596157,
      0.018129948526620865,
      -0.03515015169978142,
      0.08342966437339783,
      -0.0963355153799057,
      -0.01880079135298729,
      0.06557368487119675,
      0.0025545714888721704,
      -0.07835066318511963,
      -0.03247697651386261,
      -0.006531244609504938,
      0.13291388750076294,
      0.002817513421177864,
      0.001614327891729772,
      -0.03033275529742241,
      -0.007613695692270994,
      -0.04858776926994324,
      -0.010100516490638256,
      -0.022827716544270515,
      0.08876344561576843,
      0.03500940278172493,
      -0.004193004686385393,
      0.02506970427930355,
      -0.005725990515202284,
      -0.03905520960688591,
      0.021141789853572845,
      -0.02812281623482704,
      0.04390619695186615,
      -0.11294522881507874,
      -0.010152721777558327,
      -0.09160573780536652,
      0.053561367094516754,
      -0.014125755056738853,
      0.04465996101498604,
      -0.04675636813044548,
      0.05341866984963417,
      0.06474322825670242,
      0.010705177672207355,
      -0.03476528823375702,
      0.035637807101011276,
      -0.009464675560593605,
      0.031886350363492966,
      0.024549487978219986,
      0.10938047617673874,
      0.003330681938678026,
      0.0465906485915184,
      -0.0161192137748003,
      0.036352965980768204,
      -0.022381413727998734,
      -0.04892686381936073,
      0.0374310128390789,
      -0.05204378813505173,
      0.08712409436702728,
      -0.03149783983826637,
      -0.04221966490149498,
      0.005032581277191639,
      0.07080501317977905,
      -0.053561076521873474,
      0.024831214919686317,
      -0.003149546217173338,
      -0.004776989575475454,
      -0.10269204527139664,
      0.029637010768055916,
      -0.0290051456540823,
      0.072357676923275,
      0.027560602873563766,
      -0.032830700278282166,
      0.006077258847653866,
      0.04583382606506348,
      0.028339356184005737,
      0.06874426454305649,
      0.0674896091222763,
      -0.002373497700318694,
      -0.04878196492791176,
      -0.003188744653016329,
      0.05412959307432175,
      0.021445510908961296,
      0.03536330163478851,
      0.021389741450548172,
      0.12485074251890182,
      -0.03073793835937977,
      -0.016569994390010834,
      -0.027732206508517265,
      0.020703338086605072,
      0.01628207042813301,
      0.007684290874749422,
      0.0549808107316494,
      0.04135691002011299,
      -0.012527463026344776,
      -0.009828408248722553,
      0.0019369376823306084,
      -0.022360648959875107,
      -0.011085102334618568,
      0.030708149075508118,
      -0.07573781907558441,
      -0.05666443705558777,
      0.03350779786705971,
      0.010773925110697746,
      -0.009215002879500389,
      -0.022184744477272034,
      0.06593044102191925,
      -0.07779229432344437,
      0.02202054299414158,
      0.018203387036919594,
      -0.023719171062111855,
      0.033775489777326584,
      -0.012466536834836006,
      -0.024996191263198853,
      -0.016633180901408195,
      -0.010745049454271793,
      0.04310605674982071,
      -0.05344082787632942,
      -0.033630043268203735,
      0.022627459838986397,
      -0.050038937479257584,
      0.01085447147488594,
      -0.023352783173322678,
      -0.02563401311635971,
      -0.012585015967488289,
      -0.028068318963050842,
      0.04557965323328972,
      -0.04056435823440552,
      0.06683830171823502,
      0.01438786182552576,
      0.032242219895124435,
      -0.0007774218101985753,
      0.08170554041862488,
      -0.05131801590323448,
      -0.01725240983068943,
      -0.031766995787620544,
      -0.06580695509910583,
      0.0187948327511549,
      -0.019266540184617043,
      5.561024590861052e-05,
      0.06622658669948578,
      -0.048056479543447495,
      -0.06480471044778824,
      -0.013931742869317532,
      -0.023964041844010353,
      -0.0550677515566349,
      0.056672852486371994,
      0.006754134315997362,
      -0.013716636225581169,
      -0.0002740971394814551,
      -0.06604047119617462,
      -0.02105841413140297,
      -0.016008740290999413,
      -0.01458805799484253,
      -0.01299475971609354,
      -0.03866632282733917,
      0.02687698043882847,
      -0.07316125184297562,
      -0.05993429571390152,
      0.04301854595541954,
      -0.021509801968932152,
      -0.030005622655153275,
      0.06423306465148926,
      -0.045995790511369705,
      0.0429818332195282,
      0.008290726691484451,
      -0.001798291108570993,
      0.024797771126031876,
      -0.01419359352439642,
      -0.04959167540073395,
      0.016544722020626068,
      -0.06920205056667328,
      0.033003952354192734,
      -0.05330932140350342,
      0.05930669233202934,
      -0.11459722369909286,
      0.09567467123270035,
      -0.013180096633732319,
      -0.022959832102060318,
      0.0005037540104240179,
      -0.01777857542037964,
      0.004234133753925562,
      0.03901894390583038,
      -0.005223765037953854,
      0.1483668088912964,
      -0.01868250034749508,
      0.013678910210728645,
      0.02294621244072914,
      -0.032468006014823914,
      0.03780956566333771,
      0.048975806683301926,
      -0.08985217660665512,
      0.05634098872542381,
      0.010984240099787712,
      0.013254345394670963,
      0.04950043186545372,
      0.008938332088291645,
      0.04065057635307312,
      0.04191133752465248,
      -0.026207096874713898,
      -0.02111847884953022,
      0.015473099425435066,
      0.006030865479260683,
      -0.004681982100009918,
      0.01306008268147707,
      -0.06405767053365707,
      0.019522663205862045,
      0.03501325473189354,
      0.0026493114419281483,
      -0.01763210818171501,
      -0.04073651507496834,
      0.04543960094451904,
      -0.010761325247585773,
      -0.043070919811725616,
      0.007547959219664335,
      0.014792797155678272,
      -0.05236925184726715,
      0.027870094403624535,
      0.03756026551127434,
      -0.008504915982484818,
      -0.014381089247763157,
      -0.09452518075704575,
      0.018001869320869446,
      0.05751929432153702,
      -0.057469841092824936,
      -0.02487320452928543,
      -0.05892809480428696,
      -0.002750824671238661,
      0.02726692520081997,
      -0.049349769949913025,
      0.0037784671876579523,
      0.020489351823925972,
      0.06080496311187744,
      -0.024507401511073112,
      0.01982366293668747,
      -0.028279952704906464,
      -0.023547157645225525,
      -0.03474510833621025,
      -0.02029499039053917,
      -0.05671689659357071,
      0.0385318323969841,
      0.03727864846587181,
      0.05425508692860603,
      -0.036967791616916656,
      -0.06702717393636703,
      0.06525035202503204,
      0.037617020308971405,
      -0.009139586240053177,
      -0.061171527951955795,
      -0.001856188871897757,
      0.04557773470878601,
      -0.009127553552389145,
      -0.04818960651755333,
      0.010512860491871834,
      0.0076911430805921555,
      -0.00669489661231637,
      -0.01480069663375616,
      -0.05997967720031738,
      -0.014224355109035969,
      -0.0135947959497571,
      0.0150443771854043,
      -0.04405168443918228,
      -0.016309453174471855,
      -0.03238915279507637,
      -4.285542672732845e-05,
      -0.00477776350453496,
      0.029343295842409134,
      -0.07026269286870956,
      0.03508347272872925,
      0.026271222159266472,
      -0.011352861300110817,
      0.03724748268723488,
      -0.008750938810408115,
      -0.11636939644813538,
      0.020400961861014366,
      -0.006197324953973293,
      0.028209306299686432,
      0.041757650673389435,
      -0.1360635757446289,
      0.023340338841080666,
      -0.06517428159713745,
      0.03638486936688423,
      -0.02332543209195137,
      0.001184438238851726,
      -0.021480409428477287,
      -0.04749005287885666,
      0.0205239150673151,
      -0.07507603615522385,
      -0.02667045220732689,
      -0.011088132858276367,
      -0.039064519107341766,
      -0.012025243602693081,
      0.0162741057574749,
      0.04922603443264961,
      -0.02475505694746971,
      0.009165417402982712,
      0.09996218234300613,
      -0.009559576399624348,
      0.0019002483459189534,
      -0.02963065728545189,
      0.02935393713414669,
      0.004702350590378046,
      0.05090676620602608,
      -0.0003471807576715946,
      0.04282791540026665,
      -0.0012334325583651662,
      0.033634066581726074,
      0.007851677015423775,
      -0.04330069199204445,
      0.07779324054718018,
      0.04745780676603317,
      -0.04846407100558281,
      0.07988619059324265,
      0.0638226568698883,
      -0.0454058013856411,
      -0.027770237997174263,
      -0.03742702677845955,
      -0.03494947403669357,
      0.030754491686820984,
      -0.08627132326364517,
      0.06876340508460999,
      0.016734126955270767,
      -0.08201564103364944,
      0.030017849057912827,
      0.0441817045211792,
      0.054560936987400055,
      0.06546727567911148,
      -0.02403196133673191,
      -0.0818583071231842,
      -0.03522352874279022,
      0.03378508985042572,
      0.013281339779496193,
      -0.0429689921438694,
      -0.006167278159409761,
      -0.05903511494398117,
      -0.02236323244869709,
      0.03552607446908951,
      0.07043063640594482,
      -0.025352252647280693,
      0.01400136947631836,
      0.01936875842511654,
      0.004029167350381613,
      0.014038421213626862,
      0.037705715745687485,
      -0.02285987325012684,
      0.002551010809838772,
      0.05283014476299286,
      -0.06263404339551926,
      0.054126426577568054,
      0.04065323993563652,
      -0.08800692111253738,
      0.04326038435101509,
      -0.030621297657489777,
      -0.04122762382030487,
      -0.047601938247680664,
      -0.044861141592264175,
      0.028945697471499443,
      -0.025774076581001282,
      0.013321612030267715,
      0.05073235556483269,
      0.023744940757751465,
      -0.11156171560287476,
      -0.03190619498491287,
      -0.060563310980796814,
      -0.08076786994934082,
      -0.01106833852827549,
      0.047747306525707245,
      -0.051042910665273666,
      -0.00019278982654213905,
      0.014566752128303051,
      -0.0023002501111477613,
      0.04077483341097832,
      -0.005266535561531782
    ],
    [
      -0.09484006464481354,
      0.010401519946753979,
      -0.017714379355311394,
      -0.000825843308120966,
      -0.021462246775627136,
      -0.0006789649487473071,
      0.065897636115551,
      -0.008348487317562103,
      0.011218175292015076,
      0.03820229321718216,
      0.01647774502635002,
      0.11038274317979813,
      0.012114183977246284,
      0.0071684448048472404,
      -0.023631194606423378,
      0.00712080392986536,
      0.007499407511204481,
      0.00814708974212408,
      -0.03182799741625786,
      -0.015022046864032745,
      0.05120088905096054,
      0.003003596095368266,
      0.01692989096045494,
      0.059238117188215256,
      -0.061280757188797,
      0.0357045903801918,
      -0.03276623785495758,
      0.06541803479194641,
      0.066935233771801,
      -0.051835235208272934,
      -0.017482968047261238,
      -0.0056837149895727634,
      0.06207774952054024,
      0.0060074604116380215,
      0.00021657782781403512,
      -0.03023747354745865,
      -0.018028317019343376,
      0.028195824474096298,
      -0.05300665274262428,
      0.05724519118666649,
      0.022039076313376427,
      -0.010288259945809841,
      0.0023619732819497585,
      0.011967407539486885,
      0.05369869992136955,
      0.04090495780110359,
      -0.001309058046899736,
      -0.02661821059882641,
      0.04438767582178116,
      -0.039097681641578674,
      -0.001457499573007226,
      0.04787814989686012,
      -0.014095275662839413,
      0.04219462722539902,
      0.018335631117224693,
      0.03800619766116142,
      0.0031933763530105352,
      -0.09032955765724182,
      0.05629527196288109,
      -0.07443997263908386,
      0.021524259820580482,
      0.04191112890839577,
      -0.010445884428918362,
      0.05814894661307335,
      -0.0008437759825028479,
      -0.10014209151268005,
      -0.023960132151842117,
      -0.0019553203601390123,
      -0.0405949167907238,
      -0.02111899107694626,
      -0.012440087273716927,
      0.06013542786240578,
      -0.006266844924539328,
      -0.0384514257311821,
      -0.012580369599163532,
      -0.0393134243786335,
      -0.04070300608873367,
      -0.048043154180049896,
      -9.030526416609064e-05,
      -0.01311839371919632,
      -0.03486964851617813,
      0.025285517796874046,
      0.017001723870635033,
      -0.06206270307302475,
      -0.03708215802907944,
      -0.011993099935352802,
      -0.03272809088230133,
      0.017321599647402763,
      -0.024394970387220383,
      0.006564671639353037,
      -0.07797230035066605,
      0.07573657482862473,
      0.013699290342628956,
      -0.009958241134881973,
      0.09263037145137787,
      -0.03317227214574814,
      0.05721340700984001,
      -0.05351253226399422,
      0.01710396632552147,
      0.035583220422267914,
      -0.06298381090164185,
      -0.032828591763973236,
      0.06505225598812103,
      0.019187167286872864,
      -0.0024689934216439724,
      0.0005975793465040624,
      0.02481798455119133,
      0.031519390642642975,
      -0.09369174391031265,
      0.03601185604929924,
      -0.05395246297121048,
      -0.09316093474626541,
      0.0196862630546093,
      0.001398958032950759,
      0.030061857774853706,
      -0.02800763212144375,
      0.07997819781303406,
      0.0255449078977108,
      -0.04788820818066597,
      -0.023944323882460594,
      0.03356408700346947,
      -0.0855242982506752,
      0.053674519062042236,
      0.04202777147293091,
      0.010613837279379368,
      0.009533995762467384,
      0.04962832108139992,
      -0.043480269610881805,
      -0.010431792587041855,
      -0.003426824463531375,
      -0.023996083065867424,
      -0.0177849642932415,
      -0.012985100038349628,
      0.03499196469783783,
      0.0367865227162838,
      0.018542204052209854,
      -0.056875284761190414,
      -0.11077242344617844,
      -0.06869024783372879,
      -0.0015557600418105721,
      -0.04871474206447601,
      0.02104685641825199,
      -0.04753413796424866,
      -0.0765991136431694,
      0.03484107926487923,
      0.10275794565677643,
      -0.023985480889678,
      0.0017529177712276578,
      -0.09367126226425171,
      0.07497590780258179,
      -0.08884213864803314,
      0.0707276463508606,
      0.0338687002658844,
      -0.005120342597365379,
      -0.04383184760808945,
      -0.052462927997112274,
      -0.03269767761230469,
      -0.03549915552139282,
      -0.011674344539642334,
      -0.0038857487961649895,
      -0.07374691963195801,
      0.05464548617601395,
      0.06613694131374359,
      0.047327179461717606,
      0.0021552396938204765,
      -0.11631442606449127,
      0.0024039309937506914,
      -0.04755629971623421,
      0.055959731340408325,
      0.07191115617752075,
      -0.013994935899972916,
      0.022401876747608185,
      -0.01263667456805706,
      0.010760747827589512,
      0.017802979797124863,
      0.031361088156700134,
      0.0050359186716377735,
      -0.022229710593819618,
      -0.0416671559214592,
      0.02661832608282566,
      -0.10326585918664932,
      0.011015324853360653,
      0.0016091670840978622,
      -0.03361821547150612,
      -0.03759380057454109,
      0.028952693566679955,
      0.012517950497567654,
      -0.020272478461265564,
      0.020093761384487152,
      -0.034530069679021835,
      -0.06671711057424545,
      -0.009117593988776207,
      0.04380616173148155,
      -0.05061797425150871,
      -0.032767537981271744,
      -0.017272843047976494,
      -0.03425946831703186,
      0.026208044961094856,
      0.043388232588768005,
      -0.017613084986805916,
      -0.024227023124694824,
      0.031208284199237823,
      -0.07181888818740845,
      0.011287438683211803,
      -0.019819453358650208,
      -0.06632603704929352,
      -0.018789013847708702,
      0.036075979471206665,
      0.02439526841044426,
      0.026704609394073486,
      -0.0018675962928682566,
      -0.02653641067445278,
      -0.11045569181442261,
      -0.047352783381938934,
      0.0577266626060009,
      0.01739434339106083,
      0.0007073489832691848,
      0.0623149648308754,
      -0.09260981529951096,
      0.0017337747849524021,
      -0.05517463758587837,
      0.03810172155499458,
      -0.12311806529760361,
      -0.06261613965034485,
      0.021316809579730034,
      0.027699818834662437,
      -0.0358804315328598,
      -0.03491891175508499,
      0.005740910302847624,
      -0.03399737551808357,
      -0.0007946540135890245,
      -0.0006103668711148202,
      -0.07717688381671906,
      0.0008514871005900204,
      -0.023990247398614883,
      -0.051052987575531006,
      -0.03630464896559715,
      -0.007610841654241085,
      0.043049655854701996,
      0.06446488201618195,
      0.025967299938201904,
      -0.03977089002728462,
      0.0018741764361038804,
      0.048314258456230164,
      0.007097726222127676,
      0.018689647316932678,
      -0.04063217341899872,
      -0.004980888683348894,
      -0.06033189967274666,
      0.0382295660674572,
      0.041449885815382004,
      -0.029705272987484932,
      -0.07424727827310562,
      0.03034469485282898,
      -0.02807089127600193,
      -0.02890853025019169,
      0.03616037964820862,
      -0.08133266121149063,
      0.005843272898346186,
      0.009103497490286827,
      0.036785662174224854,
      -0.03771926835179329,
      0.00818807352334261,
      0.011851686052978039,
      -0.014123447239398956,
      -0.02759273163974285,
      -0.026534633710980415,
      -0.040997546166181564,
      0.057168975472450256,
      -0.018758567050099373,
      -0.008062231354415417,
      -0.10293934494256973,
      -0.056285105645656586,
      0.03460456058382988,
      0.040975287556648254,
      -0.03655477240681648,
      0.009016965515911579,
      0.019232509657740593,
      -0.0032217048574239016,
      -0.022714639082551003,
      0.028341766446828842,
      -0.010756930336356163,
      -0.030880164355039597,
      -0.018338432535529137,
      0.01546559389680624,
      0.011985776014626026,
      -0.011719327419996262,
      0.023797402158379555,
      -0.06392152607440948,
      0.005720542743802071,
      -0.0005662445910274982,
      0.022896133363246918,
      -0.028678640723228455,
      0.08322609961032867,
      -0.083249032497406,
      0.008341865614056587,
      0.043842848390340805,
      0.030072519555687904,
      0.058414675295352936,
      0.10453364253044128,
      -0.02189967967569828,
      -0.05521093308925629,
      0.05665462464094162,
      -0.03962937369942665,
      -0.03870772570371628,
      -0.0359078012406826,
      -0.011494929902255535,
      -0.048388246446847916,
      -0.039032984524965286,
      0.011289193294942379,
      -0.05304500833153725,
      0.0679067000746727,
      -0.022061821073293686,
      0.016106218099594116,
      -0.007801086641848087,
      0.060247085988521576,
      0.043611250817775726,
      0.025019267573952675,
      0.013451499864459038,
      -0.028708796948194504,
      -0.0030711370054632425,
      0.026295866817235947,
      0.024056326597929,
      -0.09606797993183136,
      -0.033042062073946,
      0.05435072258114815,
      0.04569540172815323,
      0.009672346524894238,
      -0.025310788303613663,
      0.10480727255344391,
      0.014149492606520653,
      -0.058650098741054535,
      -0.01596962660551071,
      0.011431544087827206,
      0.033741872757673264,
      -0.019635586068034172,
      -0.02255251817405224,
      0.04730621352791786,
      -0.014898340217769146,
      -0.06486985832452774,
      0.001184511580504477,
      0.03721197322010994,
      0.04089146479964256,
      0.04659994691610336,
      -0.03771508112549782,
      -0.0485694520175457,
      -0.04955145716667175,
      -0.034024275839328766,
      -0.020409787073731422,
      -0.03916318714618683,
      0.07023505121469498,
      -0.004227933939546347,
      -0.048912882804870605,
      -0.023954220116138458,
      -0.0011778350453823805,
      -0.006602905225008726,
      -0.023312995210289955,
      -0.03280381113290787,
      0.06243177875876427,
      0.04684510454535484,
      0.02462496981024742,
      0.03618103638291359,
      -0.03047468699514866,
      -0.023643547669053078,
      0.007901600562036037,
      -0.09889321774244308,
      -0.02241579256951809,
      -0.0024954082909971476,
      0.060276471078395844,
      -0.017828622832894325,
      -0.02523953840136528,
      0.021029895171523094,
      0.08064520359039307,
      0.02419154718518257,
      -0.004668849520385265,
      0.037319205701351166,
      -0.004557440057396889,
      0.040156446397304535,
      0.011157099157571793,
      0.0024066807236522436,
      0.009151371195912361,
      0.10618223994970322,
      -0.07692958414554596,
      0.05051548406481743,
      -0.02907605469226837,
      -0.0657273381948471,
      0.03089694306254387,
      0.0340091846883297,
      0.06467665731906891,
      -0.03219899535179138,
      0.03595376014709473,
      -0.015238734893500805,
      -0.0037811112124472857,
      0.08086749911308289,
      -0.07624563574790955,
      -0.03181193023920059,
      0.00281533133238554,
      0.010660828091204166,
      0.004196306690573692,
      0.0708918496966362,
      -0.06845201551914215,
      -0.02469390071928501,
      0.010156053118407726,
      0.05708856135606766,
      -0.006904532667249441,
      -0.03604879975318909,
      -0.03960082307457924,
      -0.1035316064953804,
      0.0323491245508194,
      -0.08895786851644516,
      0.004193704575300217,
      -0.010667889378964901,
      0.007788907270878553,
      0.0454811230301857,
      -0.042456310242414474,
      -0.060090839862823486,
      -0.06526140868663788,
      -0.06466112285852432,
      -0.04949719458818436,
      -0.00409432640299201,
      0.06545399129390717,
      -0.0003752592019736767,
      -0.028520764783024788,
      0.05103931576013565,
      0.07634280622005463,
      -0.03126317262649536,
      0.03455774486064911,
      -0.058375243097543716,
      0.04790622740983963,
      -0.013703064061701298,
      0.10915335267782211,
      0.017886875197291374,
      -0.04653533175587654,
      -0.07641882449388504,
      -0.06792808324098587,
      0.037922147661447525,
      -0.029255438596010208,
      -0.024818988516926765,
      -0.005326846148818731,
      0.07791561633348465,
      -0.060195207595825195,
      -0.01210106909275055,
      0.10900744795799255,
      0.006119707133620977,
      0.003712538629770279,
      -0.038994599133729935,
      -0.04948122054338455,
      0.0017480396199971437,
      0.02158237434923649,
      0.007359265815466642,
      0.019899141043424606,
      0.08001423627138138,
      -0.059666551649570465,
      0.010917238891124725,
      -0.011555390432476997,
      0.07433553785085678,
      -0.026709219440817833,
      0.05649814382195473,
      -0.04373040795326233,
      -0.022548966109752655,
      -0.004348091781139374,
      -0.014724967069923878,
      -0.020510448142886162,
      -0.014078274369239807,
      -0.03639769181609154,
      -0.01659558340907097,
      -0.03307581692934036,
      -0.018478374928236008,
      0.029537193477153778,
      -0.08470110595226288,
      -0.006957657635211945,
      0.007341588847339153,
      0.05190141499042511,
      0.06186840683221817,
      0.06545371562242508,
      -0.07001529633998871,
      0.043860770761966705,
      -0.029369182884693146,
      -0.017183633521199226,
      -0.023041142150759697,
      -0.04856811463832855,
      0.010329979471862316,
      0.035553619265556335,
      -0.05400392413139343,
      0.02648540772497654,
      0.00779758021235466,
      -0.05326959118247032,
      0.010342245921492577,
      -0.0067912619560956955,
      0.030906662344932556,
      0.014610614627599716,
      0.11995643377304077,
      -0.05118227377533913,
      0.023691723123192787,
      0.12022490054368973,
      0.05595262721180916,
      0.06781232357025146,
      -0.0639638751745224,
      0.032793037593364716,
      0.04572505131363869,
      0.04670998454093933,
      -0.011102030985057354,
      -0.018094494938850403
    ],
    [
      -0.060410816222429276,
      0.0158934835344553,
      0.007193829398602247,
      -0.011007175780832767,
      0.027211852371692657,
      -0.041171371936798096,
      0.026065919548273087,
      0.02066907286643982,
      -0.07666878402233124,
      0.06834790855646133,
      -0.04759072884917259,
      -0.019706966355443,
      -0.025215286761522293,
      0.06054426729679108,
      -0.016108836978673935,
      0.028284218162298203,
      0.0006618004408665001,
      -0.011018428020179272,
      0.020702093839645386,
      -0.024306094273924828,
      0.08842486888170242,
      0.08577567338943481,
      0.001170364674180746,
      -0.014858491718769073,
      -0.0680491179227829,
      -0.0006292658508755267,
      0.05436903238296509,
      0.020554890856146812,
      -0.08039484173059464,
      0.020793363451957703,
      -0.021664192900061607,
      -0.09874744713306427,
      -0.007512656040489674,
      0.005281251855194569,
      -0.008052384480834007,
      0.014688470400869846,
      0.002218834822997451,
      -0.019910389557480812,
      0.02732253260910511,
      -0.027144258841872215,
      0.03931061178445816,
      -0.057341620326042175,
      -0.03563578799366951,
      -0.048319313675165176,
      0.017283014953136444,
      0.0014993647346273065,
      0.054333001375198364,
      -0.043448615819215775,
      -0.05953633040189743,
      -0.017502177506685257,
      0.08199877291917801,
      0.10083875060081482,
      0.05662316456437111,
      0.047323502600193024,
      0.0020225944463163614,
      -0.0006652675801888108,
      0.08694714307785034,
      -0.02605866827070713,
      0.09226292371749878,
      -0.04453262686729431,
      0.043592147529125214,
      0.06289092451334,
      0.0002751596039161086,
      0.01316286250948906,
      0.06251295655965805,
      0.057648032903671265,
      -0.030154330655932426,
      -0.027281971648335457,
      -0.02637583203613758,
      -0.033042728900909424,
      0.0027598636224865913,
      -0.03191503882408142,
      -0.03116719424724579,
      0.014714359305799007,
      -0.051415394991636276,
      0.11990231275558472,
      -0.07933483272790909,
      -0.06867317855358124,
      -0.0024241239298135042,
      0.01867140270769596,
      -0.02865181863307953,
      0.07136337459087372,
      -0.019189197570085526,
      0.004735383205115795,
      -0.0292174331843853,
      -0.11462444067001343,
      -0.008029908873140812,
      -0.03928712382912636,
      0.017377542331814766,
      -0.0010513627203181386,
      -0.045533183962106705,
      -0.06500235199928284,
      0.0036029883194714785,
      0.012523128651082516,
      0.08894139528274536,
      -0.016767295077443123,
      -0.03784267604351044,
      0.04913334548473358,
      -0.02023022249341011,
      0.06347756087779999,
      0.01745603233575821,
      0.01111579779535532,
      -0.023067966103553772,
      0.10394608229398727,
      0.0027432532515376806,
      -0.061802711337804794,
      0.045319851487874985,
      -0.01966615952551365,
      -0.034420136362314224,
      -0.03839286416769028,
      0.03693508356809616,
      0.012188209220767021,
      0.06456571817398071,
      -0.02015026845037937,
      0.041712794452905655,
      -0.03214393928647041,
      -0.08665486425161362,
      0.03525610268115997,
      -0.08437809348106384,
      -0.016209328547120094,
      -0.03697456046938896,
      -0.003502922598272562,
      -0.045955464243888855,
      -0.11851532012224197,
      -0.030333558097481728,
      0.009165095165371895,
      -0.06347885727882385,
      0.020429065451025963,
      -0.01792045123875141,
      -0.021086249500513077,
      0.04955536127090454,
      -0.02934170328080654,
      -0.0021518883295357227,
      -0.01118490006774664,
      0.01936313323676586,
      -0.0032109322492033243,
      -0.05574654042720795,
      0.041902199387550354,
      -0.03282328322529793,
      -0.060780126601457596,
      -0.045696623623371124,
      0.013393637724220753,
      0.013754618354141712,
      0.02316618524491787,
      0.05078938230872154,
      0.033572353422641754,
      -0.037411291152238846,
      0.02358633652329445,
      -0.028182346373796463,
      -0.029167408123612404,
      -0.011494182050228119,
      0.060077767819166183,
      0.11015640944242477,
      0.04466478154063225,
      0.023063020780682564,
      -0.030262228101491928,
      -0.03677499294281006,
      0.033516526222229004,
      -0.014036042615771294,
      -0.02119699865579605,
      0.026600465178489685,
      0.011623001657426357,
      0.06437261402606964,
      0.04621076583862305,
      -0.03878599405288696,
      0.05239237844944,
      0.001361578470095992,
      -0.03645491227507591,
      0.047980908304452896,
      0.04517775774002075,
      0.019709156826138496,
      0.07019257545471191,
      -0.004523465409874916,
      0.009988011792302132,
      -0.06889352202415466,
      0.09687027335166931,
      -0.04544038325548172,
      0.0430469885468483,
      -0.05945852771401405,
      0.08047600835561752,
      -0.04645109549164772,
      -0.0732232853770256,
      -0.05438792333006859,
      -0.040522824972867966,
      0.010883217677474022,
      0.015285586006939411,
      -0.06156666576862335,
      -0.024813318625092506,
      0.05410332605242729,
      -0.014264965429902077,
      -0.017622923478484154,
      0.05655749887228012,
      -0.017694098874926567,
      -0.016867242753505707,
      -0.06856675446033478,
      0.017632994800806046,
      -0.06664982438087463,
      -0.022470694035291672,
      -0.10269345343112946,
      -0.037618014961481094,
      0.024086540564894676,
      0.012649191543459892,
      0.02390092797577381,
      -0.06460755318403244,
      0.045339424163103104,
      -0.01468652579933405,
      0.027509067207574844,
      0.02581893280148506,
      -0.0147207360714674,
      0.07196740806102753,
      -0.010380587540566921,
      -0.03323480486869812,
      -0.02235857956111431,
      -0.036478910595178604,
      0.040687814354896545,
      0.020093459635972977,
      -0.004720853641629219,
      0.004435302689671516,
      0.010578651912510395,
      -0.06251968443393707,
      -0.005759248975664377,
      -0.02629968151450157,
      -0.03906955197453499,
      0.03304765745997429,
      -0.04499848186969757,
      0.011729775927960873,
      -0.03788002207875252,
      -0.006013744976371527,
      -0.04530433565378189,
      -0.012179110199213028,
      0.04298538714647293,
      0.03762354701757431,
      0.09514123201370239,
      -0.05168644338846207,
      0.06775400042533875,
      -0.07612893730401993,
      0.004008614923804998,
      0.045992475003004074,
      0.011432569473981857,
      -0.03308797627687454,
      0.02097584679722786,
      0.029891766607761383,
      0.05734972655773163,
      -0.007405966520309448,
      -0.032732654362916946,
      0.028284529224038124,
      0.0651099905371666,
      -0.11624155193567276,
      0.012230489403009415,
      0.03310989961028099,
      -0.00742366723716259,
      -0.04552829638123512,
      0.016702473163604736,
      -0.0009485259070061147,
      0.04095495864748955,
      -0.01274790894240141,
      -0.034796103835105896,
      -0.009295755997300148,
      -0.006270232610404491,
      -0.026653863489627838,
      0.006456573028117418,
      0.026638921350240707,
      0.010730382055044174,
      0.032461609691381454,
      -0.009547397494316101,
      -0.007849612273275852,
      0.029090380296111107,
      -0.028050418943166733,
      0.02919020690023899,
      0.00788844283670187,
      -0.03124132566154003,
      0.061891037970781326,
      0.018401479348540306,
      0.025955330580472946,
      -0.022118963301181793,
      -0.021257979795336723,
      0.0024154309649020433,
      -0.05216227471828461,
      -0.0006429270142689347,
      0.01103525422513485,
      0.011449676007032394,
      -0.016200324520468712,
      -0.0434899777173996,
      0.05514634773135185,
      0.0036846320144832134,
      -0.02789110690355301,
      0.006339973770081997,
      -0.048708025366067886,
      -0.09636469930410385,
      -0.022613557055592537,
      -0.03598907217383385,
      -0.0030537755228579044,
      0.002337579382583499,
      -0.09962421655654907,
      0.031025787815451622,
      0.06836732476949692,
      0.05400580167770386,
      -0.022648822516202927,
      -0.06150509789586067,
      -0.039806533604860306,
      -0.048952747136354446,
      0.025851264595985413,
      0.05556458234786987,
      -0.047309085726737976,
      -0.0077971601858735085,
      0.014723370783030987,
      0.005132515449076891,
      0.06910335272550583,
      -0.0804532989859581,
      0.05583081394433975,
      0.06987151503562927,
      0.04280395433306694,
      -0.022869033738970757,
      -0.02062910795211792,
      0.05104798078536987,
      -0.07029426097869873,
      0.08407782018184662,
      0.03219500929117203,
      -0.016775546595454216,
      0.06410050392150879,
      0.036363568156957626,
      -0.1056637391448021,
      -0.01798752136528492,
      0.03324439004063606,
      0.08724790066480637,
      -0.07345769554376602,
      0.03607916831970215,
      0.0168207585811615,
      -0.016905000433325768,
      0.002923625521361828,
      0.003725652815774083,
      0.05793473497033119,
      -0.001986357383430004,
      -0.024695612490177155,
      0.03215668722987175,
      0.011784233152866364,
      -0.06766730546951294,
      0.015848679468035698,
      -0.02211691252887249,
      0.018958738073706627,
      0.0173322856426239,
      0.06226799264550209,
      0.006042876746505499,
      -0.017724590376019478,
      -0.014859066344797611,
      0.014460566453635693,
      0.00934678129851818,
      -0.0034000365994870663,
      0.0198134146630764,
      0.015558325685560703,
      -0.04179423674941063,
      -0.022424422204494476,
      -0.06627190858125687,
      -0.02855813130736351,
      0.030537113547325134,
      -0.06677199900150299,
      0.08212903887033463,
      -0.02927413210272789,
      -0.07402560859918594,
      0.034597620368003845,
      -0.007536056451499462,
      -0.012481153942644596,
      -0.04990719258785248,
      0.019913045689463615,
      0.07851818203926086,
      0.017665650695562363,
      -0.08624368160963058,
      -0.03240791708230972,
      0.01517824549227953,
      0.0003837230906356126,
      0.039578769356012344,
      -0.07481782883405685,
      -0.012271519750356674,
      -0.009067692793905735,
      -0.0067588272504508495,
      -0.030787253752350807,
      -0.020607173442840576,
      0.0447230227291584,
      0.027544507756829262,
      0.002735758200287819,
      -0.054422806948423386,
      -0.036423757672309875,
      0.06547635048627853,
      0.022651121020317078,
      -0.012175711803138256,
      0.07518621534109116,
      0.0045170532539486885,
      0.038494110107421875,
      0.04824662208557129,
      -0.05972595885396004,
      -0.08636068552732468,
      0.0003307632287032902,
      -0.003621824784204364,
      0.0827702209353447,
      0.044532664120197296,
      -0.007776795420795679,
      -0.01214110478758812,
      -0.020329520106315613,
      -0.09497062861919403,
      0.013937883079051971,
      4.699438431998715e-05,
      0.03523775190114975,
      0.02351509779691696,
      -0.03313514217734337,
      -0.06712281703948975,
      0.025667062029242516,
      -0.04084094613790512,
      0.0025420591700822115,
      0.003593896050006151,
      0.007158992346376181,
      0.04277721792459488,
      0.03506020829081535,
      -0.003220360493287444,
      -0.046583130955696106,
      0.04308316484093666,
      -0.07495424151420593,
      0.0172464307397604,
      0.006646926049143076,
      0.01739809475839138,
      -0.04362378269433975,
      -0.013720270246267319,
      0.11292216181755066,
      0.01839819736778736,
      0.02754346840083599,
      -0.007523035630583763,
      0.06688360869884491,
      -0.07777133584022522,
      0.08014078438282013,
      0.009126022458076477,
      0.019413461908698082,
      -0.015762614086270332,
      -0.010306427255272865,
      -0.007294746115803719,
      -0.01121298223733902,
      0.018617892637848854,
      -0.05488043278455734,
      -0.01702909544110298,
      -0.008330482989549637,
      0.03907839581370354,
      0.002056534169241786,
      -0.11136173456907272,
      0.004032688215374947,
      0.07226952910423279,
      -0.04903953894972801,
      -0.08964130282402039,
      0.01007573027163744,
      0.00846353080123663,
      0.05515092611312866,
      0.014379089698195457,
      0.05329326167702675,
      -0.07467694580554962,
      -0.07253441214561462,
      0.00567812193185091,
      -0.042849257588386536,
      -0.02875097095966339,
      -0.08324676007032394,
      -0.012857090681791306,
      0.10870464891195297,
      0.053849007934331894,
      0.0589730441570282,
      0.024752026423811913,
      -0.04673891142010689,
      0.05083673074841499,
      0.04761861637234688,
      0.053683146834373474,
      -0.029939308762550354,
      0.03662726655602455,
      -0.06006912142038345,
      -0.02945398911833763,
      0.03549946844577789,
      -0.021779699251055717,
      0.04487338289618492,
      -0.007842120714485645,
      0.036314208060503006,
      0.013468931429088116,
      -0.030935008078813553,
      0.03099338337779045,
      0.021524062380194664,
      -0.051194366067647934,
      0.036503590643405914,
      0.03443453460931778,
      0.05259859561920166,
      0.002882643835619092,
      0.03494934365153313,
      -0.011505830101668835,
      -0.005181293934583664,
      -0.0487598218023777,
      0.009993334300816059,
      0.020763332024216652,
      0.0417143851518631,
      0.08712692558765411,
      -0.004816838074475527,
      0.012792730703949928,
      0.01989312656223774,
      -0.0053100972436368465,
      -0.07908257097005844,
      -0.10288653522729874,
      -0.018272949382662773,
      0.005782219581305981,
      0.019556116312742233,
      -0.043332818895578384,
      0.050912391394376755,
      0.14715522527694702
    ],
    [
      -0.07070007175207138,
      0.01445788238197565,
      0.07747817784547806,
      -0.027897875756025314,
      -0.031842395663261414,
      0.02724757045507431,
      0.041743334382772446,
      -0.06477360427379608,
      -0.05580192431807518,
      0.013309367932379246,
      0.022615769878029823,
      -0.08463669568300247,
      -0.09004724770784378,
      0.02029811404645443,
      0.01672627031803131,
      -0.007624167017638683,
      0.006036614067852497,
      0.055559609085321426,
      -0.03287779167294502,
      -0.00986344926059246,
      -0.019648192450404167,
      0.0588025338947773,
      0.006502172444015741,
      -0.012740825302898884,
      -0.13563218712806702,
      -0.08533211052417755,
      0.018065134063363075,
      -0.0602884516119957,
      0.0169801265001297,
      0.06061222776770592,
      -0.02457267791032791,
      -0.01535472646355629,
      -0.00034357074764557183,
      0.05050154775381088,
      -0.026359448209404945,
      0.0512939877808094,
      -0.031022870913147926,
      0.008025330491364002,
      -0.028904689475893974,
      0.006861039903014898,
      0.010731981135904789,
      0.0015123315388336778,
      0.017334382981061935,
      0.045564550906419754,
      0.04053835943341255,
      -0.007969158701598644,
      -0.006986348424106836,
      0.003319996874779463,
      -0.10077111423015594,
      -0.034129947423934937,
      0.0381009615957737,
      0.029886018484830856,
      -0.002391734393313527,
      0.09269379079341888,
      -0.03500634431838989,
      0.014451945200562477,
      0.09139259904623032,
      0.05081542953848839,
      -0.031254395842552185,
      0.03691820800304413,
      -0.01815617084503174,
      -0.059497490525245667,
      0.010175814852118492,
      -0.0075945076532661915,
      -0.003002580488100648,
      -0.06483910977840424,
      -0.00988735444843769,
      -0.13005560636520386,
      -0.06805223226547241,
      0.018194226548075676,
      0.05961519852280617,
      -0.008160027675330639,
      -0.025721462443470955,
      0.07856554538011551,
      0.06570929288864136,
      0.06058346480131149,
      0.0640951618552208,
      0.0009952245745807886,
      -0.007494574412703514,
      -0.07450782507658005,
      0.012572976760566235,
      0.009732328355312347,
      -0.03201177343726158,
      -0.03423856571316719,
      -0.08836208283901215,
      0.10958132147789001,
      -0.06208352372050285,
      0.09398164600133896,
      -0.03305383399128914,
      -0.03614400327205658,
      0.03182121738791466,
      0.012960671447217464,
      0.06795011460781097,
      -0.018680458888411522,
      0.08924075961112976,
      0.03923361003398895,
      -0.03328278288245201,
      -0.014891348779201508,
      -0.010375894606113434,
      0.0415675975382328,
      -0.0745176151394844,
      0.03631795570254326,
      -0.014242193661630154,
      -0.040712352842092514,
      0.024036049842834473,
      -0.0011570628266781569,
      -0.1114204153418541,
      -0.04531801864504814,
      0.018110038712620735,
      0.03954198211431503,
      -0.012943191453814507,
      0.08775931596755981,
      -0.026479199528694153,
      -0.011235279962420464,
      0.019940057769417763,
      0.0016175302444025874,
      -0.05400408059358597,
      -0.012668732553720474,
      0.04592375457286835,
      0.02215992659330368,
      0.0014936035731807351,
      0.023924237117171288,
      0.010930867865681648,
      -0.01600147783756256,
      0.0018286252161487937,
      -0.021897586062550545,
      0.048476967960596085,
      0.047577086836099625,
      -0.02254743129014969,
      -0.05028687044978142,
      0.05150598660111427,
      0.08821024745702744,
      0.07797375321388245,
      -0.011909105814993382,
      -0.009387767873704433,
      0.09759335219860077,
      0.020185615867376328,
      -0.005248240660876036,
      -0.08226532489061356,
      0.05123068764805794,
      -0.024605408310890198,
      0.019282514229416847,
      0.03070911578834057,
      0.04889489337801933,
      0.010866173543035984,
      -0.010372056625783443,
      -0.0002549518540035933,
      0.0326857753098011,
      -0.02195904403924942,
      0.04685399308800697,
      0.015634192153811455,
      0.05091528967022896,
      -0.023119652643799782,
      -0.11616469919681549,
      -0.028178194537758827,
      -0.013121107593178749,
      -0.07149703055620193,
      0.024632176384329796,
      0.015922022983431816,
      -0.004624198190867901,
      -0.06929410994052887,
      -0.003585569327697158,
      0.0011377388145774603,
      0.012210971675813198,
      0.06361318379640579,
      -0.030093004927039146,
      -0.021371277049183846,
      0.020758869126439095,
      0.07717519253492355,
      -0.10150955617427826,
      0.047134071588516235,
      0.0321061871945858,
      -0.035794589668512344,
      -0.038671672344207764,
      0.011605351231992245,
      0.07272332906723022,
      -0.02921144850552082,
      0.033383190631866455,
      0.01830522157251835,
      0.09873882681131363,
      0.016812115907669067,
      0.07502178847789764,
      0.015706226229667664,
      0.07325352728366852,
      -0.07286849617958069,
      -0.04191914573311806,
      0.04860684275627136,
      0.0687829777598381,
      0.0003086027572862804,
      -0.02098161354660988,
      -0.012261196970939636,
      -0.00809503998607397,
      -0.043495696038007736,
      0.056347668170928955,
      0.020283805206418037,
      -0.01762242615222931,
      0.05625532567501068,
      0.032326873391866684,
      -0.01880633272230625,
      -0.013283326290547848,
      -0.04074382036924362,
      -0.02844214253127575,
      -0.013867458328604698,
      0.04424064978957176,
      -0.0189826600253582,
      0.014400129206478596,
      -0.025168078020215034,
      0.0013697630492970347,
      -0.04404682293534279,
      0.09256668388843536,
      -0.0025848245713859797,
      0.07714437693357468,
      0.015107616782188416,
      0.01457687746733427,
      -0.011115076020359993,
      -0.02380743809044361,
      -0.06878799945116043,
      -0.07554799318313599,
      0.026345381513237953,
      0.0598856583237648,
      0.07589263468980789,
      -0.012852367013692856,
      -0.05534373223781586,
      -0.08809364587068558,
      0.023327574133872986,
      0.02408876270055771,
      0.040950216352939606,
      -0.008878079243004322,
      -0.05738457292318344,
      0.07293830066919327,
      -0.015521642751991749,
      -0.03680747374892235,
      -0.03665563091635704,
      -0.0032857568003237247,
      0.05682189390063286,
      -0.038052115589380264,
      -0.05654093250632286,
      0.03704475238919258,
      -0.012946669943630695,
      -0.03658049553632736,
      0.03329816088080406,
      -0.011947711929678917,
      0.03405897691845894,
      -0.029793737456202507,
      0.08204182237386703,
      -0.03371773287653923,
      -0.07489752024412155,
      0.001392022124491632,
      0.014840452931821346,
      0.01269866805523634,
      0.003124928567558527,
      -0.020715104416012764,
      -0.017240654677152634,
      -0.06376098096370697,
      0.010456450283527374,
      0.011035307310521603,
      -0.09380242228507996,
      -0.01733589917421341,
      0.003283359808847308,
      -0.007293798960745335,
      0.020322732627391815,
      0.04705827683210373,
      0.042216427624225616,
      0.0696241557598114,
      0.04776481166481972,
      -0.03409210219979286,
      -0.013588500209152699,
      -0.0036033170763403177,
      -0.03160565719008446,
      0.03851081430912018,
      -0.028693225234746933,
      0.04397300258278847,
      0.045584022998809814,
      0.03795412927865982,
      0.021236203610897064,
      0.02797170728445053,
      -0.003827014472335577,
      0.0274577084928751,
      -0.03662201389670372,
      0.019458644092082977,
      0.0084669915959239,
      0.06812293827533722,
      0.01318800263106823,
      -0.029242021963000298,
      -0.0015434289816766977,
      -0.0006167690735310316,
      -0.0181747954338789,
      0.05522724241018295,
      0.012587440200150013,
      0.016771182417869568,
      -0.037952970713377,
      -0.031074056401848793,
      0.025511179119348526,
      -0.03873418644070625,
      -0.00782811176031828,
      0.013829998672008514,
      0.01323796808719635,
      -0.0007944910903461277,
      0.012607640586793423,
      0.05468576401472092,
      0.046173159033060074,
      0.019027721136808395,
      0.07503774017095566,
      -0.007393761072307825,
      -0.026228265836834908,
      0.057896122336387634,
      -0.031219586730003357,
      0.0036920818965882063,
      0.012241827324032784,
      -0.053929850459098816,
      0.08944042772054672,
      0.04451961815357208,
      0.019886618480086327,
      0.0009551416733302176,
      -0.06317750364542007,
      0.0828385055065155,
      0.03880902752280235,
      -0.06140171363949776,
      0.07455554604530334,
      0.013240785337984562,
      -9.80341064860113e-05,
      -0.04795242100954056,
      0.03988826274871826,
      -0.0389234721660614,
      0.04918910935521126,
      0.05346972867846489,
      0.05187282711267471,
      0.01702781207859516,
      0.0009716609492897987,
      0.06685994565486908,
      0.017286155372858047,
      -0.022529177367687225,
      -0.05743682011961937,
      0.06963784992694855,
      0.0802493765950203,
      -0.014176948927342892,
      -0.026858024299144745,
      0.03299370035529137,
      0.014246413484215736,
      0.04498183727264404,
      -0.05795414000749588,
      0.055329203605651855,
      -0.004364078864455223,
      0.06253085285425186,
      -0.03522411733865738,
      -0.019816268235445023,
      0.04965531453490257,
      0.04701049625873566,
      -0.02621615119278431,
      -0.000988649670034647,
      -0.022315196692943573,
      -0.07437252253293991,
      0.019390512257814407,
      0.057728663086891174,
      -0.04768247902393341,
      0.06315620243549347,
      0.040391214191913605,
      0.0468994565308094,
      -0.004511341918259859,
      -0.051276251673698425,
      -0.08397248387336731,
      0.09025952965021133,
      -0.11714164912700653,
      -0.042629390954971313,
      -0.03511765971779823,
      -0.05821499601006508,
      0.0440128892660141,
      0.03805337846279144,
      0.026202131062746048,
      -0.038972679525613785,
      -0.04969853535294533,
      0.038226787000894547,
      -0.000620004313532263,
      0.017215222120285034,
      0.058528248220682144,
      -0.016325660049915314,
      0.04061014577746391,
      0.0497388057410717,
      0.0008341259672306478,
      -0.00035603641299530864,
      -0.044871456921100616,
      0.028142323717474937,
      0.02935921400785446,
      0.06747625768184662,
      0.017677444964647293,
      0.01962844282388687,
      -0.05904591828584671,
      0.041693802922964096,
      0.07518941164016724,
      0.029213659465312958,
      -0.011502066627144814,
      -0.06445450335741043,
      0.033832352608442307,
      0.0050673335790634155,
      0.06624497473239899,
      -0.02061176486313343,
      -0.017308760434389114,
      -0.02193208783864975,
      0.005671355873346329,
      -0.05613775551319122,
      0.014767455868422985,
      0.04000474885106087,
      -0.0009613632573746145,
      0.008918402716517448,
      -0.009878700599074364,
      0.07744874805212021,
      -0.0004551747697405517,
      0.07981924712657928,
      0.0009241363732144237,
      0.02980724349617958,
      0.040865540504455566,
      -0.04791038855910301,
      0.0042163776233792305,
      -0.09910023212432861,
      -0.056059375405311584,
      0.000254772457992658,
      -0.04406393691897392,
      -0.016246113926172256,
      -0.06742436438798904,
      -0.10323731601238251,
      0.04434707760810852,
      -0.08299564570188522,
      0.037033822387456894,
      0.03115778975188732,
      0.03510008007287979,
      -0.03518221527338028,
      0.022710362449288368,
      0.05081181228160858,
      0.06429246068000793,
      -0.021946558728814125,
      0.03262835368514061,
      -0.04776950553059578,
      -0.10551007837057114,
      -0.04389085993170738,
      -0.02584238536655903,
      -0.0022896043956279755,
      0.030035268515348434,
      0.01752428524196148,
      -0.0106317438185215,
      -0.03784295544028282,
      -0.0007196437218226492,
      -0.002711490262299776,
      -0.07746050506830215,
      0.05337388813495636,
      -0.04617324098944664,
      -0.0008843772229738533,
      0.06913250684738159,
      -0.0065382798202335835,
      -0.02514646016061306,
      -0.06074654310941696,
      -0.03300727531313896,
      0.03205833584070206,
      0.013176729902625084,
      -0.08299953490495682,
      0.07577766478061676,
      -0.03947196900844574,
      0.005861205980181694,
      0.0353400819003582,
      0.029986955225467682,
      -0.026600049808621407,
      -0.04790002107620239,
      -0.014893989078700542,
      -0.03483271226286888,
      0.11751548200845718,
      -0.03339089825749397,
      0.030232436954975128,
      -0.04777078330516815,
      -0.04805484041571617,
      0.056486576795578,
      0.08170213550329208,
      -0.01517427247017622,
      -0.10932616889476776,
      -0.010606531985104084,
      0.04487413913011551,
      -0.015231715515255928,
      0.038488101214170456,
      -0.053836531937122345,
      0.034025054425001144,
      -0.011117842048406601,
      -0.0524844266474247,
      -0.05146550014615059,
      -0.02490616962313652,
      -0.011767071671783924,
      -0.019137505441904068,
      0.030723189935088158,
      0.015999287366867065,
      -0.0023408918641507626,
      -0.058978721499443054,
      0.04615359008312225,
      -0.061109818518161774,
      -0.053166452795267105,
      0.03073486126959324,
      -0.045854099094867706,
      -0.034279726445674896,
      0.026105312630534172,
      0.00016391575627494603,
      0.052715715020895004,
      -0.03955691307783127,
      -0.02387096919119358,
      0.024891814216971397,
      0.033330462872982025,
      -0.019471552222967148,
      0.018133709207177162
    ],
    [
      0.025765735656023026,
      -0.019504504278302193,
      0.07675396651029587,
      -0.012198785319924355,
      -0.004377360921353102,
      0.011615526862442493,
      0.04897170141339302,
      -0.06960870325565338,
      0.03131125494837761,
      0.01980174519121647,
      -0.009815879166126251,
      0.003362940391525626,
      -0.08464285731315613,
      -0.03230437636375427,
      -0.021205507218837738,
      -0.05922812223434448,
      0.0185135155916214,
      -0.0390312597155571,
      -0.0027796525973826647,
      -0.01733412593603134,
      -0.018818529322743416,
      0.05045995116233826,
      0.0029039476066827774,
      -0.0963292270898819,
      -0.01429261825978756,
      0.0014528250321745872,
      0.03595596179366112,
      0.008619374595582485,
      -0.001121031236834824,
      0.03603444993495941,
      0.01296365074813366,
      -0.019225865602493286,
      0.0032905975822359324,
      0.053702548146247864,
      -0.018716221675276756,
      -0.005790266674011946,
      -0.0388764850795269,
      0.034268736839294434,
      0.06472750008106232,
      0.014952591620385647,
      0.0580873042345047,
      0.002470460021868348,
      -0.011307056993246078,
      0.05934077873826027,
      -0.031303390860557556,
      0.02379419282078743,
      -0.011418133974075317,
      -0.017222153022885323,
      -0.04522288590669632,
      0.022984104230999947,
      0.11914633959531784,
      0.03263440728187561,
      0.040809791535139084,
      0.07217304408550262,
      -0.007933683693408966,
      -0.16526564955711365,
      -0.08330681174993515,
      -0.0713653713464737,
      0.015607912093400955,
      -0.0093654440715909,
      0.00432549137622118,
      0.05630370229482651,
      -0.06476779282093048,
      -0.020887600257992744,
      -0.006661260966211557,
      0.05972888320684433,
      0.05075109377503395,
      -0.058029867708683014,
      0.0038494549226015806,
      0.08088099211454391,
      -0.01340436190366745,
      -0.0029664516914635897,
      0.0356123149394989,
      0.04634517431259155,
      0.047368064522743225,
      -0.06808196008205414,
      0.0663653016090393,
      0.06491988152265549,
      -0.045530661940574646,
      0.0058184717781841755,
      0.020342284813523293,
      0.0058949957601726055,
      -0.021650906652212143,
      -0.04101629927754402,
      0.013759828172624111,
      0.004715648014098406,
      0.012319298461079597,
      -0.011594272218644619,
      -0.024506069719791412,
      -0.07497160136699677,
      -0.04958672821521759,
      -0.0877927914261818,
      0.042457807809114456,
      0.042393073439598083,
      0.005016375798732042,
      0.009404990822076797,
      0.023613562807440758,
      0.009571732953190804,
      -0.004351742099970579,
      0.04010087996721268,
      0.016642723232507706,
      -0.02601650357246399,
      0.09926724433898926,
      -0.015284283086657524,
      -0.07513047754764557,
      -0.031492091715335846,
      -0.05005573108792305,
      0.09838356077671051,
      0.05460372567176819,
      0.03844024986028671,
      -0.06755413860082626,
      -0.010908533819019794,
      -0.012595552951097488,
      0.03470278158783913,
      -0.070792056620121,
      0.056794412434101105,
      0.04605463147163391,
      0.03156069666147232,
      0.019765496253967285,
      -0.05573323741555214,
      -0.012738224118947983,
      0.001908938749693334,
      -0.022614365443587303,
      -0.1323140561580658,
      -0.0015876865945756435,
      0.055522140115499496,
      -0.04773729294538498,
      -0.0012962634209543467,
      0.0014243245823308825,
      0.006818902213126421,
      0.008026459254324436,
      -0.06035769358277321,
      0.05058582127094269,
      0.03190694749355316,
      -0.02602981962263584,
      0.06891194730997086,
      0.0064422087743878365,
      -0.08073041588068008,
      -0.009325861930847168,
      0.021039359271526337,
      -0.021000489592552185,
      -0.040914274752140045,
      -0.03705396503210068,
      -0.015985596925020218,
      0.036407314240932465,
      0.030651412904262543,
      0.004508077632635832,
      -0.018808210268616676,
      -0.015594023279845715,
      0.06715348362922668,
      -0.0320487804710865,
      -0.029254263266921043,
      -0.033263690769672394,
      -0.04370838776230812,
      0.0007318437565118074,
      -0.026997797191143036,
      -0.03995613753795624,
      0.015493618324398994,
      0.04430222138762474,
      0.010510156862437725,
      -0.05753009766340256,
      0.06709107756614685,
      -0.042458754032850266,
      -0.020962907001376152,
      0.028310246765613556,
      -0.0058854082599282265,
      -0.018829412758350372,
      0.12576033174991608,
      0.006736226845532656,
      -0.03686486557126045,
      -0.06160655990242958,
      -0.011648022569715977,
      -0.05030747503042221,
      0.01061166450381279,
      0.028463829308748245,
      -0.0027635644655674696,
      -0.0347786471247673,
      -0.026460818946361542,
      0.04937388747930527,
      0.07903480529785156,
      -0.026220884174108505,
      0.06746881455183029,
      -0.013715798035264015,
      -0.02839290164411068,
      0.032582834362983704,
      -0.08041028678417206,
      -0.05048676207661629,
      0.036122459918260574,
      0.03964996337890625,
      -0.002923364983871579,
      -0.024774011224508286,
      -0.0024510917719453573,
      -0.013843693770468235,
      0.033504702150821686,
      -0.019365018233656883,
      0.05322042107582092,
      -0.01729619689285755,
      0.00562736950814724,
      0.004884390626102686,
      -0.053777728229761124,
      -0.07472054660320282,
      -0.01199856773018837,
      0.005795903503894806,
      0.07857866585254669,
      0.04371462017297745,
      -0.04469902440905571,
      -0.05048133432865143,
      -0.06394418329000473,
      0.0900345891714096,
      0.004980518016964197,
      -0.019670827314257622,
      0.0013776733539998531,
      -0.035679493099451065,
      -0.02579176239669323,
      -0.0047554029151797295,
      -0.014931597746908665,
      -0.06644149124622345,
      -0.07881449908018112,
      -0.004062953870743513,
      0.06607230007648468,
      0.04445775970816612,
      0.04280535504221916,
      0.03837073966860771,
      -0.015412991866469383,
      -0.046580687165260315,
      0.01963663659989834,
      0.008205019868910313,
      0.07395093888044357,
      -0.022343313321471214,
      -0.03551509976387024,
      0.02910994179546833,
      -0.017241457477211952,
      0.07434500008821487,
      -0.004401383921504021,
      0.11034943163394928,
      -0.00964425876736641,
      -0.043182533234357834,
      0.05954312905669212,
      0.012784372083842754,
      -0.03553251177072525,
      0.021263448521494865,
      0.07200811803340912,
      0.03635723143815994,
      0.027863161638379097,
      0.005539809353649616,
      -0.006143707316368818,
      -0.0228111632168293,
      -0.12404580414295197,
      0.03854895755648613,
      -0.045422568917274475,
      -0.05071065574884415,
      0.03537657856941223,
      0.0184648334980011,
      -0.007539372891187668,
      -0.007180552463978529,
      -0.08103538304567337,
      0.04132445901632309,
      -0.013961605727672577,
      -0.020981725305318832,
      0.046038392931222916,
      -0.011430765502154827,
      0.04334074631333351,
      -0.10024106502532959,
      -0.016850849613547325,
      -0.000884648528881371,
      0.006996496580541134,
      0.015010733157396317,
      -0.0038137566298246384,
      -0.039174363017082214,
      0.02305474504828453,
      0.0030434043146669865,
      -0.010299636982381344,
      -0.052749987691640854,
      0.08213964104652405,
      0.03190864250063896,
      -0.007605507504194975,
      0.06666997820138931,
      -0.044721297919750214,
      0.1081208661198616,
      -0.0028436596039682627,
      0.07806426286697388,
      -0.01801161840558052,
      0.08270160853862762,
      -0.05744461715221405,
      -0.03633001446723938,
      -0.019739337265491486,
      0.09905750304460526,
      -0.0038998189847916365,
      -0.015866493806242943,
      0.00856589525938034,
      -0.010669615119695663,
      -0.04023509472608566,
      0.006921222433447838,
      -0.040831781923770905,
      -0.004218603018671274,
      -0.016135189682245255,
      -0.06477656215429306,
      -0.004899810533970594,
      -0.02362539805471897,
      -0.006523521151393652,
      0.0008838745998218656,
      -0.06918080896139145,
      0.08533497899770737,
      -0.03594617918133736,
      -0.0910395011305809,
      -0.04508524015545845,
      0.03977583348751068,
      0.018218008801341057,
      0.07918678224086761,
      -0.07870028913021088,
      0.00044207231258042157,
      0.059393905103206635,
      0.048227861523628235,
      -0.04470773786306381,
      -0.05147445946931839,
      -0.005468476098030806,
      -0.006034654099494219,
      0.06407248228788376,
      0.044025760143995285,
      0.03876161202788353,
      0.04338974505662918,
      -0.06014607846736908,
      -0.012662739492952824,
      -0.03653233125805855,
      0.04907579347491264,
      0.001336369663476944,
      -0.008476360701024532,
      -0.04641157388687134,
      0.019394291564822197,
      0.0003767736488953233,
      0.02148475870490074,
      -0.016980890184640884,
      0.030759450048208237,
      -0.13047455251216888,
      -0.08053494989871979,
      0.029850676655769348,
      0.05307507514953613,
      0.013072174973785877,
      -0.03633737564086914,
      -0.046998120844364166,
      -0.01712373085319996,
      0.0176869947463274,
      -0.0133772948756814,
      -0.05330923572182655,
      0.007668869569897652,
      -0.02350400947034359,
      -0.07493294775485992,
      -0.018472759053111076,
      -0.03593454509973526,
      -0.03840508684515953,
      0.05261965095996857,
      0.010223864577710629,
      0.001833903486840427,
      0.023899082094430923,
      0.06585212796926498,
      -0.01102520152926445,
      -0.012596395798027515,
      0.07406984269618988,
      -0.045402754098176956,
      -0.048982247710227966,
      -0.040500443428754807,
      -0.012494351714849472,
      0.019852058961987495,
      -0.0031058036256581545,
      0.05255279690027237,
      0.011209717951714993,
      -0.06419781595468521,
      0.04230344668030739,
      -0.020401719957590103,
      0.0651455745100975,
      0.0011437757639214396,
      -0.015218012034893036,
      0.012629076838493347,
      0.0031997677870094776,
      -0.026873484253883362,
      -0.1109921783208847,
      -0.012759706005454063,
      0.008466033264994621,
      -0.025540003553032875,
      -0.02674570120871067,
      0.03956788033246994,
      -0.0090808579698205,
      -0.03727284073829651,
      0.017138149589300156,
      0.04690690338611603,
      -0.05511314049363136,
      -0.012524261139333248,
      -0.009812123142182827,
      0.016933279111981392,
      0.03855285048484802,
      -0.0031623190734535456,
      -0.016308847814798355,
      -0.13268990814685822,
      0.04057496786117554,
      -0.01371845230460167,
      0.058185216039419174,
      0.03960533067584038,
      -0.06423896551132202,
      -0.01985830068588257,
      -0.03425610810518265,
      -0.045810241252183914,
      -0.04434410482645035,
      -0.010094864293932915,
      -0.04986398667097092,
      0.027869045734405518,
      0.06837176531553268,
      -0.1069221943616867,
      0.01289382018148899,
      0.0049475389532744884,
      -0.0556749626994133,
      0.0052647716365754604,
      -0.08182857185602188,
      -0.03529217466711998,
      0.020484983921051025,
      0.025242701172828674,
      0.06180546432733536,
      -0.05924054980278015,
      -0.021900905296206474,
      0.056474704295396805,
      0.027067409828305244,
      -0.026401035487651825,
      0.04439103230834007,
      0.05612790212035179,
      -0.003324362449347973,
      -0.09417450428009033,
      0.03155376389622688,
      0.0006222936208359897,
      -0.052420876920223236,
      0.011958158574998379,
      -0.0015390081098303199,
      0.11626933515071869,
      -0.001166798989288509,
      -0.019663255661725998,
      0.015113862231373787,
      -0.05532956123352051,
      -0.005783440079540014,
      0.00821254774928093,
      0.0033889322075992823,
      0.018886618316173553,
      0.01976654678583145,
      0.07692314684391022,
      0.00353806558996439,
      -0.0038628773763775826,
      -0.004015945829451084,
      -0.03779667615890503,
      0.0004723714664578438,
      0.032860804349184036,
      -0.016849281266331673,
      -0.007334650494158268,
      -0.03348772972822189,
      0.029202884063124657,
      0.009665082208812237,
      0.02607659623026848,
      0.004786289297044277,
      -0.05891518294811249,
      -0.06428401172161102,
      0.02154245600104332,
      -0.026484696194529533,
      0.039997365325689316,
      0.02176765538752079,
      0.03571875020861626,
      -0.050270602107048035,
      0.030415715649724007,
      0.044969528913497925,
      -0.02910650335252285,
      -0.061042848974466324,
      -0.13097278773784637,
      0.02533668838441372,
      0.042725421488285065,
      -0.04001426324248314,
      -0.019033657386898994,
      -0.028276070952415466,
      0.0018047037301585078,
      0.0427839420735836,
      0.04765734449028969,
      0.008892375975847244,
      0.015015056356787682,
      -0.002804333111271262,
      0.018371976912021637,
      -0.026686489582061768,
      -0.016305766999721527,
      0.004437841475009918,
      -0.008791104890406132,
      0.010729645378887653,
      -0.05001179873943329,
      0.06350077688694,
      0.016576839610934258,
      0.043291300535202026,
      0.036900315433740616,
      -0.019655413925647736,
      -0.015057585202157497,
      0.05833562836050987,
      -0.03222030773758888,
      0.06398304551839828,
      0.06704925000667572,
      -0.017606163397431374,
      -0.014874720014631748,
      -0.04452132806181908,
      0.058851223438978195,
      -0.00868549756705761,
      0.06918703019618988,
      -0.03699713200330734,
      0.005861629266291857
    ],
    [
      0.03499789908528328,
      -0.08804485946893692,
      0.033090416342020035,
      -0.008859986439347267,
      -0.006124421954154968,
      -0.041723597794771194,
      0.021943314000964165,
      0.024669472128152847,
      -0.07455246895551682,
      -0.02092948742210865,
      -0.02607085555791855,
      0.010639604181051254,
      -0.020617304369807243,
      -0.021148115396499634,
      0.004766900558024645,
      -0.1234600767493248,
      0.016020411625504494,
      0.05368112400174141,
      -0.03012467548251152,
      -0.03825249895453453,
      0.036091726273298264,
      0.10044343024492264,
      0.04262099415063858,
      0.06931792944669724,
      -0.08882521837949753,
      -0.03836439549922943,
      -0.029664257541298866,
      0.037679947912693024,
      0.09623738378286362,
      -0.011588218621909618,
      -0.03055388666689396,
      -0.049775656312704086,
      -0.07811355590820312,
      0.09107618778944016,
      0.019303642213344574,
      -0.04484587162733078,
      0.004744083154946566,
      0.018129823729395866,
      0.05177917331457138,
      0.03877807408571243,
      -0.049006786197423935,
      0.03379977121949196,
      0.006963047198951244,
      -0.11241381615400314,
      0.04989120736718178,
      0.12566763162612915,
      -0.003159813117235899,
      0.031023530289530754,
      -0.08733876049518585,
      0.015833234414458275,
      0.07861711829900742,
      0.06777232140302658,
      0.0009712196188047528,
      -0.05102260038256645,
      -0.030812038108706474,
      0.07236465811729431,
      0.03869549557566643,
      -0.04695918411016464,
      -0.027981707826256752,
      0.05965776741504669,
      0.009731428697705269,
      0.026086030527949333,
      -0.023123400285840034,
      -0.01699788309633732,
      -0.023335803300142288,
      0.013606935739517212,
      0.034003764390945435,
      0.020355161279439926,
      -0.021324479952454567,
      0.06178469583392143,
      -0.05324818566441536,
      0.0003434240643400699,
      -0.024498460814356804,
      -0.0062508173286914825,
      -0.040654271841049194,
      -0.048291485756635666,
      -0.043723899871110916,
      -0.007780220825225115,
      0.007324470207095146,
      0.007645064033567905,
      -0.030671188607811928,
      0.0038825529627501965,
      -0.0870736613869667,
      -0.039140403270721436,
      0.029461506754159927,
      -0.035985011607408524,
      0.02092483825981617,
      0.017017317935824394,
      0.02371622622013092,
      -0.0009768683230504394,
      -0.03294157236814499,
      0.021809857338666916,
      0.03211693838238716,
      0.02023860439658165,
      0.05851089581847191,
      -0.053261857479810715,
      -0.008166893385350704,
      0.045834895223379135,
      -0.058940865099430084,
      0.02723524160683155,
      -0.10680735856294632,
      -0.010869232006371021,
      0.0332455188035965,
      0.022311409935355186,
      -0.03294087573885918,
      -0.07350648194551468,
      -0.020482711493968964,
      -0.03219175711274147,
      0.11092254519462585,
      -0.006082538980990648,
      -0.06355610489845276,
      0.04751375690102577,
      -0.024806968867778778,
      0.03762303665280342,
      -0.026255924254655838,
      0.10940902680158615,
      0.07816943526268005,
      0.14575403928756714,
      -0.06416861712932587,
      -0.05732269585132599,
      0.019022557884454727,
      0.03775230795145035,
      -0.08077359944581985,
      -0.022100510075688362,
      0.049046725034713745,
      -0.02760501764714718,
      -0.045741334557533264,
      -0.00558856874704361,
      0.0385923758149147,
      0.00771695002913475,
      -0.029019435867667198,
      0.0476195253431797,
      -0.015877166762948036,
      -0.020981887355446815,
      -0.018994851037859917,
      -0.030072635039687157,
      -0.045487962663173676,
      0.06407824903726578,
      0.11495436728000641,
      -0.024990448728203773,
      0.02267676591873169,
      0.003846685402095318,
      -0.09494415670633316,
      0.027533799409866333,
      -0.01696106605231762,
      0.0019890412222594023,
      0.052386801689863205,
      -0.017370076850056648,
      0.028931833803653717,
      0.06724376976490021,
      -0.04382913559675217,
      -0.05360935255885124,
      0.06130649894475937,
      -0.026244089007377625,
      0.07378792762756348,
      0.0033051723148673773,
      0.07914236932992935,
      -0.031007854267954826,
      -0.03623406961560249,
      0.016481034457683563,
      0.028300080448389053,
      0.030746275559067726,
      -0.056504104286432266,
      0.028691094368696213,
      0.056430473923683167,
      -0.009008122608065605,
      0.04965238645672798,
      0.029346562922000885,
      0.03633355721831322,
      0.008797395043075085,
      -0.005226094275712967,
      0.03856075555086136,
      0.02791776694357395,
      -0.0483851283788681,
      0.010963127948343754,
      -0.053532276302576065,
      -0.05942603200674057,
      -0.0021866196766495705,
      0.03893023729324341,
      -0.000998336705379188,
      -0.005822384729981422,
      0.007083056028932333,
      -0.07924821227788925,
      -0.0934990793466568,
      -0.00863185990601778,
      0.010187295265495777,
      0.01930040493607521,
      0.011281179264187813,
      0.11233039945363998,
      0.05860119313001633,
      0.0802643895149231,
      -0.029039207845926285,
      -0.011040963232517242,
      -0.019788745790719986,
      0.04768013209104538,
      -0.026971271261572838,
      0.02099020592868328,
      -0.03092392534017563,
      0.0026168127078562975,
      0.016153326258063316,
      0.009839307516813278,
      -0.054307445883750916,
      0.021364470943808556,
      -0.026289058849215508,
      -0.057781368494033813,
      0.02319732867181301,
      -0.029085274785757065,
      0.012971635907888412,
      -0.03222469612956047,
      -0.003568148473277688,
      0.01612042635679245,
      -0.05517375469207764,
      -0.000548477575648576,
      0.021601127460598946,
      -0.005423040594905615,
      -0.04908968135714531,
      -0.002475462853908539,
      -0.052015677094459534,
      0.10343807190656662,
      0.04042275622487068,
      0.04835289716720581,
      -0.028017332777380943,
      0.002004440175369382,
      0.00011708926467690617,
      -0.0009206516551785171,
      -0.034891173243522644,
      -0.02883189544081688,
      0.016489500179886818,
      -0.022694669663906097,
      0.03161444142460823,
      -0.0383782684803009,
      -0.08736222982406616,
      0.07909491658210754,
      -0.05472496524453163,
      -0.04240081459283829,
      0.038005929440259933,
      -0.09815084934234619,
      -0.04190542548894882,
      0.032717593014240265,
      0.041136134415864944,
      -0.022996753454208374,
      0.03146424889564514,
      -0.03427739813923836,
      0.08341081440448761,
      0.033306948840618134,
      0.02243814244866371,
      -0.020711038261651993,
      0.02877272106707096,
      -0.014627061784267426,
      -0.033516645431518555,
      -0.005803409963846207,
      0.04019312560558319,
      -0.011300098150968552,
      0.01680944859981537,
      0.04415913298726082,
      0.0023264905903488398,
      0.027016853913664818,
      0.03889300301671028,
      -0.011400922201573849,
      -0.0006385884480550885,
      0.017574435099959373,
      0.041541796177625656,
      0.004272713791579008,
      -0.013895665295422077,
      -0.0026703968178480864,
      0.006165817845612764,
      -0.04846453294157982,
      0.03159813582897186,
      0.06345980614423752,
      -0.04538942873477936,
      0.008377280086278915,
      0.00283676921389997,
      -0.09472887963056564,
      0.04320738464593887,
      0.029612336307764053,
      -0.006158891133964062,
      0.017422180622816086,
      0.018229681998491287,
      0.011864062398672104,
      -0.004723283462226391,
      -0.002153481123968959,
      0.02305424213409424,
      -0.04320260509848595,
      0.13564972579479218,
      0.021608931943774223,
      0.011986657045781612,
      -0.04017290100455284,
      0.026239784434437752,
      -0.05058300122618675,
      0.01976310834288597,
      0.024873942136764526,
      0.01110558770596981,
      0.06033093482255936,
      0.02976245805621147,
      0.009102319367229939,
      0.022494148463010788,
      0.048432882875204086,
      0.001892378437332809,
      0.047139722853899,
      -0.018549317494034767,
      -0.03520125523209572,
      -0.025108685716986656,
      -0.0015850798226892948,
      -0.00548208924010396,
      0.07156195491552353,
      -0.022981377318501472,
      0.0055399308912456036,
      -0.056652624160051346,
      0.04124942049384117,
      0.04511070251464844,
      0.021817989647388458,
      0.0245592650026083,
      -0.0392807200551033,
      -0.05864224582910538,
      0.04111137613654137,
      0.005506439134478569,
      -0.031226759776473045,
      0.00547412084415555,
      0.012097014114260674,
      -0.03363984078168869,
      0.009080667980015278,
      0.005278887692838907,
      -0.025966601446270943,
      0.015610603615641594,
      -0.07217059284448624,
      0.026053499430418015,
      -0.023547746241092682,
      0.04063620790839195,
      0.008077755570411682,
      0.01490603294223547,
      0.010156596079468727,
      0.01028013601899147,
      0.06293273717164993,
      0.017348039895296097,
      -0.025691987946629524,
      0.027086256071925163,
      -0.008707570843398571,
      -0.0003031311498489231,
      0.0368170291185379,
      0.037925075739622116,
      0.036293305456638336,
      0.016881005838513374,
      -0.022070541977882385,
      -0.015879707410931587,
      0.038160573691129684,
      -0.033765751868486404,
      -0.024617932736873627,
      -0.0280761756002903,
      -0.021183809265494347,
      -0.0003673933679237962,
      0.011297662742435932,
      -0.036775656044483185,
      0.09723453968763351,
      0.08458184450864792,
      0.00014673665282316506,
      -0.012392319738864899,
      -0.12996897101402283,
      -0.004998167045414448,
      -0.062301408499479294,
      -0.02164088562130928,
      -0.006717567332088947,
      -0.01426661666482687,
      -0.12133786827325821,
      -0.02590845339000225,
      0.03965119644999504,
      0.06608543545007706,
      -0.006287184078246355,
      -0.01558606419712305,
      0.05311328545212746,
      0.009455631487071514,
      -0.0047732251696288586,
      0.018620040267705917,
      0.04633836820721626,
      -0.10208126902580261,
      0.0064743696711957455,
      -0.008454963564872742,
      -8.752808935241774e-05,
      -0.024070942774415016,
      -0.023410199210047722,
      -0.0017030545277521014,
      -0.03100144863128662,
      0.02549915574491024,
      0.08892393112182617,
      -0.08193085342645645,
      -0.04514429718255997,
      -0.09016671776771545,
      0.02373627759516239,
      -0.01293987687677145,
      0.012085865251719952,
      -0.04416867345571518,
      -0.01572706364095211,
      0.03641558438539505,
      0.04760992154479027,
      -0.005266253370791674,
      0.03459319472312927,
      0.06855408102273941,
      -0.05180032551288605,
      0.07813157886266708,
      -0.050788506865501404,
      0.07577330619096756,
      0.05397995188832283,
      0.005972479470074177,
      0.037577226758003235,
      0.058440931141376495,
      0.0019536828622221947,
      0.07884765416383743,
      -0.018105342984199524,
      -0.05073333904147148,
      -0.02854558639228344,
      -0.03473996743559837,
      -0.07906268537044525,
      -0.019970329478383064,
      0.006920726038515568,
      -0.035990484058856964,
      0.03492853417992592,
      -0.05488577112555504,
      -0.0737290009856224,
      0.05233193561434746,
      -0.021107640117406845,
      -0.004801474045962095,
      -0.023067954927682877,
      0.030004341155290604,
      -0.047146834433078766,
      -0.0016717909602448344,
      -0.07962381839752197,
      0.03931599482893944,
      0.07032550871372223,
      -0.03599945455789566,
      -0.0794440284371376,
      -0.07534828782081604,
      -0.014937671832740307,
      -0.0354292169213295,
      0.007468491327017546,
      0.0039639584720134735,
      0.028677338734269142,
      -0.055775415152311325,
      0.048200756311416626,
      0.005787148606032133,
      -0.016347626224160194,
      0.03468933701515198,
      0.041739609092473984,
      0.00663259532302618,
      0.05266733467578888,
      -0.012410742230713367,
      -0.06855709850788116,
      0.06436225771903992,
      0.047449126839637756,
      0.03903527185320854,
      0.02621098794043064,
      -0.03342290595173836,
      0.016021596267819405,
      0.044399846345186234,
      -0.02873736433684826,
      -0.0026990598998963833,
      0.07897020876407623,
      0.02447577938437462,
      0.04275922104716301,
      -0.044757742434740067,
      0.07122191041707993,
      -0.034026093780994415,
      0.038116756826639175,
      -0.05383600667119026,
      0.060168858617544174,
      -0.038760311901569366,
      0.027224693447351456,
      0.028066523373126984,
      0.06238275766372681,
      -0.06379886716604233,
      -0.030400248244404793,
      -0.07603085041046143,
      -0.08314719051122665,
      -0.10719356685876846,
      0.05726025626063347,
      0.017979644238948822,
      -0.05879845842719078,
      -0.01314263604581356,
      -0.026105979457497597,
      -0.0035120833199471235,
      0.08202919363975525,
      0.015373307280242443,
      -0.029065245762467384,
      0.00027610777760855854,
      0.10228198766708374,
      -0.05821283161640167,
      -0.03274967893958092,
      -0.004316137637943029,
      -0.005656226072460413,
      0.04509663209319115,
      -0.05522638186812401,
      0.022054452449083328,
      -0.008047378621995449,
      -0.04568205401301384,
      0.03444845229387283,
      0.06828504055738449,
      -0.03370799869298935,
      -0.0863344594836235,
      0.050380855798721313,
      -0.024853534996509552,
      -0.009067258797585964,
      0.029350891709327698,
      0.020444249734282494,
      0.05242731422185898,
      0.08930152654647827
    ],
    [
      -0.059640850871801376,
      0.012911857105791569,
      0.0037975097075104713,
      0.08112985640764236,
      0.028140679001808167,
      -0.036860328167676926,
      0.08196141570806503,
      0.008458440192043781,
      -0.03597847744822502,
      -0.03567737340927124,
      0.027932776138186455,
      0.07582079619169235,
      -0.06700398027896881,
      -0.011840863153338432,
      0.026967430487275124,
      -0.05644139274954796,
      -0.04923107102513313,
      0.03469735011458397,
      -0.07024216651916504,
      0.0019209888996556401,
      0.01702050119638443,
      0.03310811147093773,
      -0.07768234610557556,
      -0.04573363438248634,
      0.02206674963235855,
      -0.06656525284051895,
      0.01372432243078947,
      0.02330866828560829,
      0.08965225517749786,
      0.08603008091449738,
      -0.0013678714167326689,
      0.04111528396606445,
      0.08772597461938858,
      0.014084172435104847,
      0.05088808014988899,
      -0.03318236395716667,
      -0.0011143278097733855,
      -0.014732133597135544,
      -0.054116860032081604,
      -0.004981351550668478,
      -0.009258672595024109,
      -0.023318588733673096,
      0.093998983502388,
      0.007804999127984047,
      0.04373723268508911,
      -0.027394423261284828,
      0.00987162720412016,
      0.002425710903480649,
      0.02680761180818081,
      0.02074582129716873,
      0.01170082576572895,
      -0.003937133587896824,
      0.013483629561960697,
      -0.06136453524231911,
      0.04319712519645691,
      0.0030652983114123344,
      0.05404214933514595,
      -0.039942122995853424,
      0.005762834567576647,
      -0.0076013230718672276,
      -0.036849211901426315,
      0.04126262664794922,
      0.007574770599603653,
      -0.03474797308444977,
      -0.05851688235998154,
      -0.014832870103418827,
      0.05978184565901756,
      0.0129843233153224,
      -0.028830669820308685,
      -0.027215726673603058,
      -0.023380914703011513,
      0.001263330690562725,
      0.024102075025439262,
      -0.032108403742313385,
      -0.032371025532484055,
      0.11705141514539719,
      0.019543064758181572,
      0.07136432081460953,
      0.017897358164191246,
      -0.05315670743584633,
      0.04444243013858795,
      -0.038811903446912766,
      -0.011061170138418674,
      -0.006784387398511171,
      0.050714366137981415,
      -0.005028944928199053,
      0.001813763752579689,
      0.046620674431324005,
      -0.010216577909886837,
      -0.03763975203037262,
      -0.023711996152997017,
      -0.04878207668662071,
      0.05434688925743103,
      -0.05397646501660347,
      0.013834790326654911,
      -0.004073879215866327,
      -0.09659263491630554,
      -0.0029150228947401047,
      -0.07144701480865479,
      -0.0014870156301185489,
      0.03727345913648605,
      0.024450376629829407,
      -0.0735335573554039,
      -0.03317074850201607,
      -0.02984204702079296,
      -0.009456751868128777,
      0.031688667833805084,
      -0.013647382147610188,
      0.0683627724647522,
      0.020614517852663994,
      0.03293027728796005,
      0.005020177457481623,
      0.01862182840704918,
      -0.007626834791153669,
      0.07731275260448456,
      0.06340662389993668,
      -0.04100944101810455,
      -0.0066275265999138355,
      0.015152551233768463,
      0.0049138241447508335,
      -0.06003183126449585,
      0.00968140922486782,
      0.03175506368279457,
      -0.06350710242986679,
      -0.04412223771214485,
      0.013195387087762356,
      -0.0013048505643382668,
      -0.04191114008426666,
      -0.03372638672590256,
      -0.005739524960517883,
      -0.02485847845673561,
      -0.04643956199288368,
      0.008661014959216118,
      -0.03152129426598549,
      0.021084759384393692,
      -0.039612747728824615,
      -0.061920709908008575,
      0.033245522528886795,
      0.022693166509270668,
      -0.08116558194160461,
      -0.013533053919672966,
      -0.054669469594955444,
      0.007950037717819214,
      0.003363338066264987,
      -0.044984180480241776,
      -0.02602454088628292,
      0.025549087673425674,
      0.006295616272836924,
      0.00168227415997535,
      -0.07548059523105621,
      -0.05406241491436958,
      -0.002992721274495125,
      0.04454123228788376,
      -0.06293825060129166,
      0.0350467786192894,
      -0.002756290603429079,
      -0.004356638994067907,
      -0.01663714274764061,
      -0.0018595578148961067,
      -0.09055006504058838,
      0.056176505982875824,
      0.01461148913949728,
      -0.0008551133214496076,
      -0.0724191665649414,
      -0.03920520842075348,
      0.03433562070131302,
      0.0014275810681283474,
      0.04745860770344734,
      0.034752216190099716,
      -0.0047223689034581184,
      -0.006814224179834127,
      0.1053282618522644,
      0.047986067831516266,
      -0.03299826383590698,
      0.023641865700483322,
      -0.08283352106809616,
      -0.022286508232355118,
      0.048933301120996475,
      -0.010058030486106873,
      -0.00463165482506156,
      0.008181648328900337,
      0.062497805804014206,
      -0.007937431335449219,
      0.012513287365436554,
      0.05421788617968559,
      0.017466463148593903,
      0.00442857388406992,
      0.01902475580573082,
      0.031081559136509895,
      0.04918694123625755,
      -0.019817017018795013,
      -0.05932592228055,
      0.0013012816198170185,
      0.0020041191019117832,
      -0.014088092371821404,
      -0.03116515837609768,
      0.008643885143101215,
      -0.009885711595416069,
      0.010934201069176197,
      0.07176830619573593,
      0.060223691165447235,
      -0.01625232957303524,
      -0.05627942830324173,
      0.038446951657533646,
      -0.041361019015312195,
      -0.06870661675930023,
      -0.047205593436956406,
      -0.009242067113518715,
      -0.008442869409918785,
      -0.0505489744246006,
      0.006676918361335993,
      0.02379171922802925,
      0.018696393817663193,
      -0.021583860740065575,
      0.04286300018429756,
      0.021251142024993896,
      0.011371648870408535,
      0.014634252525866032,
      0.0351000651717186,
      -0.02318897657096386,
      0.007063645403832197,
      -0.02073131501674652,
      0.0894363597035408,
      0.005113658029586077,
      -0.06396527588367462,
      0.043971698731184006,
      -0.03158515319228172,
      0.08205060660839081,
      -0.028385821729898453,
      0.020671717822551727,
      -0.014431925490498543,
      0.0176354069262743,
      -0.01829148456454277,
      0.047326989471912384,
      -0.009228219278156757,
      0.12025077641010284,
      0.0768352597951889,
      -0.02561195008456707,
      0.055216699838638306,
      0.01539376750588417,
      0.050373464822769165,
      0.021515896543860435,
      -0.022073691710829735,
      0.09586949646472931,
      -0.005085459910333157,
      0.0476011298596859,
      0.017453936859965324,
      -0.10753050446510315,
      -0.013452688232064247,
      0.011278304271399975,
      -0.03511018678545952,
      -0.002085053129121661,
      0.0003441347798798233,
      0.03512479364871979,
      -0.01822284609079361,
      -0.019367791712284088,
      0.025011803954839706,
      -0.08495069295167923,
      0.06307883560657501,
      0.03644144535064697,
      -0.07421499490737915,
      -0.04175369814038277,
      -0.03968053683638573,
      -0.020810309797525406,
      -0.09515054523944855,
      0.030148040503263474,
      0.02581128664314747,
      -0.00289814081043005,
      -0.007555167190730572,
      0.0009059690637513995,
      0.08432988822460175,
      0.03993239253759384,
      0.028505872935056686,
      0.009845392778515816,
      0.09110063314437866,
      -0.044320277869701385,
      0.008247598074376583,
      -0.006585821509361267,
      0.02003026381134987,
      -0.01700276881456375,
      -0.05020402744412422,
      -0.08290163427591324,
      -0.031244495883584023,
      0.019053436815738678,
      -0.036309514194726944,
      0.013359610922634602,
      0.03876953199505806,
      0.009026340208947659,
      -0.019586393609642982,
      0.004460325930267572,
      -0.008023534901440144,
      0.011865929700434208,
      0.08379638940095901,
      0.02835461124777794,
      0.0718575045466423,
      -0.12108706682920456,
      -0.02205163985490799,
      -0.047134172171354294,
      -0.020677780732512474,
      -0.03631196171045303,
      -0.061150044202804565,
      0.018053574487566948,
      -0.08387644588947296,
      -0.024930311366915703,
      -0.02299717627465725,
      -0.059923239052295685,
      0.014360540546476841,
      0.053282078355550766,
      -0.018926890566945076,
      0.008018015883862972,
      0.032747022807598114,
      -0.0017982079880312085,
      -0.03082388825714588,
      -0.010273946449160576,
      0.014485429041087627,
      0.03551572561264038,
      0.0928846150636673,
      0.057137854397296906,
      -0.03642912209033966,
      0.04775017499923706,
      0.005988910794258118,
      -0.022858040407299995,
      0.07459703832864761,
      0.03861627355217934,
      0.06644854694604874,
      0.03631289675831795,
      -0.031150368973612785,
      -0.05737483873963356,
      0.0606682188808918,
      -0.0014779031043872237,
      0.012384315952658653,
      0.025532914325594902,
      -0.045750249177217484,
      -0.0691458061337471,
      -0.039326753467321396,
      -0.04311695322394371,
      -0.006323996465653181,
      -0.016182174906134605,
      0.007285359315574169,
      0.05892014503479004,
      0.06845182925462723,
      -0.03322099149227142,
      0.005092570558190346,
      0.030586302280426025,
      0.04711564630270004,
      0.037610605359077454,
      -0.059790849685668945,
      0.007759931962937117,
      -0.017704728990793228,
      -0.01669541373848915,
      -0.0846625417470932,
      0.010985524393618107,
      0.0554565005004406,
      -0.0281935166567564,
      0.018162257969379425,
      0.05278034135699272,
      -0.013799392618238926,
      -0.045343685895204544,
      -0.02886996604502201,
      0.022782888263463974,
      -0.04951348528265953,
      0.07085096091032028,
      0.06852247565984726,
      -0.04692237451672554,
      0.028240492567420006,
      0.019390400499105453,
      -0.013514515943825245,
      -0.006416227202862501,
      -0.01803804747760296,
      0.05047345533967018,
      -0.06767706573009491,
      0.03226608410477638,
      -0.00971911009401083,
      -0.03463050350546837,
      0.06433552503585815,
      -0.08332517743110657,
      -0.04928207024931908,
      0.07308360189199448,
      0.008813994936645031,
      0.10302121192216873,
      0.07710286974906921,
      0.10476717352867126,
      0.03364938497543335,
      0.005911726970225573,
      -0.0165413748472929,
      0.04112701490521431,
      -0.011230284348130226,
      -0.06154311075806618,
      0.07590916007757187,
      0.09851782023906708,
      -0.024696245789527893,
      -0.029503220692276955,
      -0.07577478140592575,
      0.0031856531277298927,
      0.16537579894065857,
      0.03793312981724739,
      -0.04143442213535309,
      -0.03203282505273819,
      -0.0784655287861824,
      0.011538341641426086,
      -0.006820798385888338,
      -0.02155875787138939,
      0.05614899843931198,
      0.014673315919935703,
      0.04083200544118881,
      -0.0227376576513052,
      0.06621220707893372,
      -0.09123101085424423,
      -0.062129296362400055,
      0.028414061293005943,
      -0.037351079285144806,
      0.0489991120994091,
      -0.03796463459730148,
      0.0857783854007721,
      -0.014145098626613617,
      0.08571529388427734,
      0.008863693103194237,
      0.03462442755699158,
      0.022103186696767807,
      0.10390499979257584,
      0.043161336332559586,
      -0.013422621414065361,
      0.030628234148025513,
      -0.09380579739809036,
      -0.06668203324079514,
      0.0784846618771553,
      0.01915099285542965,
      0.005163022316992283,
      -0.02079281397163868,
      0.04973674938082695,
      -0.02430225908756256,
      -0.038622062653303146,
      0.017466148361563683,
      -0.034390151500701904,
      0.030828680843114853,
      -0.01669059880077839,
      -0.010529816150665283,
      0.030027715489268303,
      -0.07014316320419312,
      -0.05099881440401077,
      0.024750612676143646,
      -0.01427999697625637,
      -0.050481755286455154,
      0.016510067507624626,
      -0.0371016226708889,
      0.01193301286548376,
      0.035394199192523956,
      -0.033689890056848526,
      -0.039042845368385315,
      -0.0654463917016983,
      0.017776206135749817,
      -0.11436451226472855,
      0.12568965554237366,
      0.04404907673597336,
      0.04687773808836937,
      0.07430483400821686,
      -0.03840600699186325,
      -0.025425460189580917,
      -0.032668836414813995,
      0.07147916406393051,
      0.05958264693617821,
      -0.011382190510630608,
      -0.0019549066200852394,
      -0.02401508018374443,
      -0.04878684878349304,
      -0.024625930935144424,
      0.046805065125226974,
      0.11968868225812912,
      -0.012726237997412682,
      0.004022850655019283,
      0.02667546644806862,
      0.06191257759928703,
      -0.004884373862296343,
      0.07064758986234665,
      0.0011333710281178355,
      0.04283631220459938,
      -0.022809408605098724,
      -0.054206281900405884,
      -0.010461303405463696,
      -0.04725655913352966,
      -0.06810294836759567,
      0.005465337540954351,
      -0.028186483308672905,
      -0.04270437732338905,
      -0.022804968059062958,
      0.06088803708553314,
      -0.0011668328661471605,
      -0.007164228707551956,
      -0.05701446905732155,
      0.00106984528247267,
      0.02771650068461895,
      0.012885469943284988,
      0.010539665818214417,
      0.024605469778180122,
      0.01862303912639618,
      0.02299448475241661,
      0.07292504608631134,
      0.010994510725140572,
      -0.06629311293363571,
      -0.036644622683525085,
      0.005045769270509481,
      0.0848497673869133,
      -0.08167321979999542
    ],
    [
      0.01087835244834423,
      0.05175192281603813,
      0.02517729066312313,
      0.08729688078165054,
      -0.05476398393511772,
      0.05868024006485939,
      0.03748993203043938,
      0.00527236657217145,
      -0.020615337416529655,
      -0.05268654227256775,
      0.03436693176627159,
      0.012632030993700027,
      0.06728732585906982,
      0.035847123712301254,
      -0.038575466722249985,
      0.009007159620523453,
      -0.0028111489955335855,
      -0.027866994962096214,
      0.00034777980181388557,
      -0.026564601808786392,
      0.00534290075302124,
      0.046122342348098755,
      -0.011097145266830921,
      -0.014818653464317322,
      -0.018518930301070213,
      0.0839809998869896,
      0.09651786088943481,
      -0.0319594070315361,
      0.054606471210718155,
      -0.003180756699293852,
      0.05357767269015312,
      0.04816640540957451,
      0.009586957283318043,
      -0.01722853258252144,
      0.017694517970085144,
      -0.01942528784275055,
      0.04757041484117508,
      0.12159587442874908,
      0.03517890349030495,
      0.0034564242232590914,
      0.009883199818432331,
      -0.053665582090616226,
      0.016606861725449562,
      -0.03542023524641991,
      -0.09190014004707336,
      -0.035831600427627563,
      -0.0031937668099999428,
      0.03759174793958664,
      0.014655878767371178,
      -0.027699211612343788,
      -0.021367497742176056,
      -0.05985584855079651,
      0.008592990227043629,
      -0.03352971002459526,
      0.021625887602567673,
      -0.03985745459794998,
      -0.0677439421415329,
      -0.02325255610048771,
      0.03981558233499527,
      0.008000865578651428,
      0.005249312147498131,
      -0.02636811137199402,
      -0.003587875282391906,
      0.016776250675320625,
      0.013994461856782436,
      -0.01185514871031046,
      -0.031809959560632706,
      0.03168318793177605,
      0.06486918032169342,
      -0.0413975864648819,
      0.0010072404984384775,
      -0.01970869116485119,
      -0.028818288818001747,
      0.04981698468327522,
      0.04355865344405174,
      0.055138371884822845,
      0.07647982239723206,
      -0.015135926194489002,
      0.011988523416221142,
      -0.10468418896198273,
      0.029301326721906662,
      0.07501928508281708,
      -0.07391198724508286,
      -0.006407305132597685,
      -0.012315811589360237,
      0.009063073433935642,
      -0.030788220465183258,
      -0.07288511097431183,
      -0.1025349423289299,
      -0.11087308824062347,
      0.014512836933135986,
      -0.0496508814394474,
      -0.07647522538900375,
      -0.00917312316596508,
      -0.04448111727833748,
      0.011068389751017094,
      -0.05736953765153885,
      -0.08609335124492645,
      -0.061345018446445465,
      0.0201601292937994,
      -0.06396840512752533,
      -0.02297641709446907,
      0.0023462697863578796,
      0.01540062204003334,
      -0.021227706223726273,
      -0.04303288832306862,
      0.023853572085499763,
      -0.054992299526929855,
      -0.031623724848032,
      -0.02840210311114788,
      0.048684850335121155,
      -0.05072681978344917,
      0.06039111316204071,
      -0.018704751506447792,
      -0.08165035396814346,
      -0.027114976197481155,
      -0.07398688793182373,
      -0.0020330327097326517,
      -0.04016447067260742,
      -0.017821650952100754,
      0.04582483321428299,
      0.06354088336229324,
      -0.023436041548848152,
      -0.023922931402921677,
      -0.010608695447444916,
      -0.027722440659999847,
      0.008407669141888618,
      0.004820526111871004,
      0.040585957467556,
      -0.004169867839664221,
      0.04741040617227554,
      -0.018893832340836525,
      -0.0007960560033097863,
      -0.10099891573190689,
      0.011752398684620857,
      0.07291869074106216,
      -0.0027764581609517336,
      0.03831883519887924,
      -0.03178119286894798,
      0.04393880441784859,
      0.021376939490437508,
      -0.02091798186302185,
      0.013544423505663872,
      -0.02674109674990177,
      -0.013515260070562363,
      -0.009319862350821495,
      0.030931664630770683,
      -0.07907408475875854,
      -0.025613179430365562,
      0.008298015221953392,
      0.04599681869149208,
      -0.032950758934020996,
      0.01519749779254198,
      0.03670536354184151,
      -0.015638677403330803,
      -0.03180268779397011,
      0.06507586687803268,
      -0.0005254410207271576,
      -0.01072647888213396,
      0.022358141839504242,
      -0.053073715418577194,
      -0.030943619087338448,
      -0.04915693774819374,
      -0.08820205181837082,
      -0.01573954150080681,
      0.09614511579275131,
      0.05955829098820686,
      0.02080662176012993,
      -0.013467628508806229,
      -0.02666785940527916,
      -0.05700142681598663,
      0.07201684266328812,
      -0.01628154143691063,
      0.06575356423854828,
      -0.05141225829720497,
      0.013403448276221752,
      -0.022637179121375084,
      0.002193020423874259,
      0.022180194035172462,
      -0.006637771148234606,
      -0.03194049745798111,
      0.0038388539105653763,
      -0.006340948864817619,
      -0.02235782891511917,
      -0.019087444990873337,
      0.03719564154744148,
      0.003175959223881364,
      0.01969178207218647,
      -0.0686774030327797,
      -0.0043490673415362835,
      0.011507820338010788,
      -0.07561852782964706,
      -0.026800379157066345,
      -0.012061470188200474,
      0.024953708052635193,
      -0.011192032136023045,
      -0.02289545349776745,
      0.022003790363669395,
      -0.12615133821964264,
      -0.071534663438797,
      0.0030856241937726736,
      -0.06675299257040024,
      -0.037510499358177185,
      0.029154863208532333,
      0.08443542569875717,
      0.006047695875167847,
      0.0396258719265461,
      -0.10554967820644379,
      0.03404614329338074,
      0.07864593714475632,
      0.0014324915828183293,
      0.0505678728222847,
      -0.0359383262693882,
      0.008082982152700424,
      -0.02455305866897106,
      -0.042672283947467804,
      -0.05025726929306984,
      -0.023837730288505554,
      -0.007332072127610445,
      -0.019509142264723778,
      -0.07239841669797897,
      0.03441791236400604,
      0.049918804317712784,
      0.0953456312417984,
      0.01516459509730339,
      0.02841205894947052,
      0.015167731791734695,
      0.002082901308313012,
      0.008110052905976772,
      -0.03606560453772545,
      0.039781805127859116,
      -0.016299040988087654,
      -0.06357798725366592,
      0.04555864632129669,
      -0.036715369671583176,
      -0.026877297088503838,
      0.06182481721043587,
      -0.023122156038880348,
      0.04854041710495949,
      0.026427078992128372,
      0.012588310986757278,
      -0.04154323786497116,
      -0.04138024151325226,
      -0.008684189058840275,
      0.012969034723937511,
      -0.05063801631331444,
      0.008635679259896278,
      -0.009332031011581421,
      0.010694367811083794,
      0.037633735686540604,
      -0.030951814725995064,
      0.06954736262559891,
      0.03788404539227486,
      -0.05680379271507263,
      5.481133484863676e-05,
      0.02750348299741745,
      0.025038454681634903,
      -0.0079844044521451,
      0.012841558083891869,
      -0.05662946030497551,
      -0.007872747257351875,
      -0.0571988970041275,
      -0.0020595733076334,
      0.0004032231809105724,
      0.017844559624791145,
      -0.05209307000041008,
      0.06943970918655396,
      -0.002574723679572344,
      -0.01314543280750513,
      0.007472740951925516,
      0.047126952558755875,
      -0.039357323199510574,
      -0.04523835331201553,
      0.033131711184978485,
      0.07310047000646591,
      -0.0429069921374321,
      -0.004242309834808111,
      0.013994618318974972,
      -0.01606500893831253,
      -0.007759612984955311,
      -0.0012350196484476328,
      -0.018006809055805206,
      -0.024466460570693016,
      0.017804477363824844,
      -0.023608755320310593,
      -0.004861940164119005,
      0.01882537268102169,
      -0.04949411004781723,
      -0.043760910630226135,
      0.0056465077213943005,
      -0.04726531729102135,
      0.07921477407217026,
      0.025917375460267067,
      0.03831733018159866,
      -0.058645639568567276,
      0.0793733224272728,
      0.007120797410607338,
      0.0032768244855105877,
      0.051142968237400055,
      -0.015279964543879032,
      0.04815537855029106,
      -0.08673680573701859,
      0.054063864052295685,
      -0.03189762309193611,
      0.06470292061567307,
      -0.006369746755808592,
      -0.005754589103162289,
      -0.025867952033877373,
      0.05084148794412613,
      -0.04044274613261223,
      -0.026621824130415916,
      0.019482823088765144,
      -0.04833744093775749,
      -0.09580221772193909,
      -0.00023171886277850717,
      -0.019985206425189972,
      0.044169168919324875,
      0.07914433628320694,
      0.03188205510377884,
      -0.03806132823228836,
      0.03649812564253807,
      0.02659272961318493,
      -0.04428044706583023,
      0.00011345236271154135,
      0.0003722434339579195,
      -0.07767052948474884,
      -0.031234728172421455,
      -0.00566995982080698,
      -0.08035864681005478,
      -0.01766226813197136,
      -0.04129806533455849,
      -0.06553322821855545,
      -0.028183303773403168,
      0.03324388340115547,
      -0.022237248718738556,
      0.07212396711111069,
      0.0031481978949159384,
      0.008186723105609417,
      -0.032463014125823975,
      -0.040709853172302246,
      0.0037370435893535614,
      0.021677695214748383,
      0.04240571707487106,
      -0.028468241915106773,
      -0.06113745644688606,
      0.039457861334085464,
      -0.020194461569190025,
      0.009996294043958187,
      0.013216675259172916,
      -0.008935459889471531,
      -0.008128901943564415,
      0.043306637555360794,
      0.02076498419046402,
      0.06240119785070419,
      -0.0479188896715641,
      0.006193076726049185,
      -0.0397895984351635,
      -0.07326796650886536,
      0.012008068151772022,
      0.06822177767753601,
      -0.02882707491517067,
      -0.041483841836452484,
      0.008714785799384117,
      -0.05888303741812706,
      0.06609034538269043,
      0.05892449617385864,
      -0.02217790298163891,
      0.00821862742304802,
      -0.06891581416130066,
      -0.007481626234948635,
      0.01635756716132164,
      -0.11567338556051254,
      -0.056159645318984985,
      -0.025692081078886986,
      0.0012910241493955255,
      -0.026044124737381935,
      0.03224688768386841,
      0.06313515454530716,
      -0.07253462821245193,
      0.02836282178759575,
      -0.06746789067983627,
      -0.014207790605723858,
      -0.03331180289387703,
      -0.01406820584088564,
      -0.03679024800658226,
      -0.019173208624124527,
      -0.0036420575343072414,
      0.04105547070503235,
      0.014824576675891876,
      -0.010059088468551636,
      -0.021091671660542488,
      0.0835786908864975,
      -0.07372496277093887,
      -0.02703598141670227,
      -0.023652728646993637,
      -0.014801359735429287,
      -0.012431650422513485,
      0.0504818856716156,
      -0.048418886959552765,
      0.07982298731803894,
      0.04434535652399063,
      0.0217194352298975,
      0.05332021787762642,
      0.018422681838274002,
      -0.021342258900403976,
      -0.042908478528261185,
      -0.060589637607336044,
      0.1270146667957306,
      0.029728993773460388,
      0.06931981444358826,
      0.03223726525902748,
      0.0461210161447525,
      0.0137703288346529,
      0.00587143748998642,
      0.004935901146382093,
      -0.02296821027994156,
      -0.07528339326381683,
      0.0462353453040123,
      -0.004812805447727442,
      0.0039429147727787495,
      -0.061335742473602295,
      -0.03937128558754921,
      -0.09042628854513168,
      -0.014823134988546371,
      -0.08230801671743393,
      -0.0440668947994709,
      0.08533084392547607,
      0.08195717632770538,
      0.027836808934807777,
      -0.03973592072725296,
      0.06874684244394302,
      0.027305040508508682,
      -0.000485399883473292,
      -0.02084851823747158,
      -0.0742918998003006,
      -0.08850384503602982,
      -0.020403046160936356,
      -0.030835503712296486,
      -0.11329270154237747,
      -0.016032394021749496,
      0.04428356885910034,
      0.1274474710226059,
      -0.010450637899339199,
      0.08325174450874329,
      -0.045583829283714294,
      -0.00815256405621767,
      -0.010161277838051319,
      0.031069641932845116,
      0.010591870173811913,
      -0.041251689195632935,
      -0.0832923948764801,
      -0.0038646189495921135,
      0.012360709719359875,
      -0.0202690027654171,
      -0.016735902056097984,
      0.042141981422901154,
      0.08480585366487503,
      0.08458858728408813,
      -0.030714605003595352,
      -0.021035976707935333,
      -0.0018068726640194654,
      -0.027841022238135338,
      0.015534534119069576,
      0.019505634903907776,
      -0.000504113151691854,
      -0.05013250187039375,
      0.01175261102616787,
      -0.0668376088142395,
      -0.05093316361308098,
      0.08821330219507217,
      0.07999391108751297,
      0.061882469803094864,
      0.04648600518703461,
      0.02177862450480461,
      -0.0029327196534723043,
      -0.07708462327718735,
      -0.04136304929852486,
      -0.008583142422139645,
      -0.05490269884467125,
      -0.017453687265515327,
      0.039530199021101,
      0.022417761385440826,
      0.03145074099302292,
      0.025057796388864517,
      -0.02836901694536209,
      0.01412158366292715,
      -0.008957790210843086,
      0.026647886261343956,
      0.01910572499036789,
      0.02392772026360035,
      0.035795655101537704,
      0.059069883078336716,
      -0.020886803045868874,
      0.1017211526632309,
      0.056783974170684814,
      0.06603783369064331,
      0.04754069820046425,
      -0.008919275365769863,
      -0.0893242359161377,
      0.0033921082504093647,
      0.027896638959646225,
      -0.05051641911268234,
      0.004741483833640814
    ],
    [
      -0.06289070844650269,
      0.004435339476913214,
      0.01766723394393921,
      -0.009794979356229305,
      -0.04619419947266579,
      0.016256550326943398,
      0.0174592062830925,
      -0.06195279210805893,
      -0.06777142733335495,
      -0.0663091167807579,
      0.04596130549907684,
      0.01678316667675972,
      0.01723375730216503,
      -0.07756763696670532,
      0.015069727785885334,
      0.007875741459429264,
      0.015355420298874378,
      0.028685567900538445,
      -0.012545683421194553,
      -0.12908944487571716,
      -0.053165923804044724,
      0.005051365587860346,
      -0.022305749356746674,
      0.03554752096533775,
      -0.02460865117609501,
      -0.04207412898540497,
      -0.01274021528661251,
      0.03925921767950058,
      -0.0006826312746852636,
      0.025161433964967728,
      -0.12358245998620987,
      0.012272126041352749,
      0.004055034834891558,
      -0.0612955242395401,
      0.04766828939318657,
      0.027628079056739807,
      0.0347469225525856,
      0.032227903604507446,
      -0.11862174421548843,
      0.016583265736699104,
      0.0009526183130219579,
      -0.0364791639149189,
      0.0578753687441349,
      -0.0006427692715078592,
      -0.03838006407022476,
      0.060121968388557434,
      -0.06142475828528404,
      -0.04406305402517319,
      -0.0905337780714035,
      0.02668219991028309,
      0.016902316361665726,
      -0.0466194674372673,
      0.028499295935034752,
      0.04964001849293709,
      -0.08278479427099228,
      0.025968879461288452,
      0.018022334203124046,
      -0.030812589451670647,
      0.01649024523794651,
      -0.044151559472084045,
      0.0018651983700692654,
      -0.026462096720933914,
      0.11669804155826569,
      -0.0301914494484663,
      0.031002994626760483,
      0.007740975823253393,
      -0.03331740200519562,
      -0.025622829794883728,
      0.06011952832341194,
      0.030125942081212997,
      0.023795373737812042,
      0.0066982158459723,
      0.015757925808429718,
      -0.00735442154109478,
      0.01374441385269165,
      -0.019599713385105133,
      0.0008975877426564693,
      0.020147796720266342,
      0.06209130957722664,
      0.015816112980246544,
      0.07058479636907578,
      -0.019231149926781654,
      0.04371010512113571,
      0.06690238416194916,
      -0.003939752001315355,
      0.08545166254043579,
      0.0228592362254858,
      -0.033463381230831146,
      -0.028296783566474915,
      0.01471131481230259,
      0.10371971130371094,
      0.023139741271734238,
      -0.0025225598365068436,
      0.026688577607274055,
      -0.020732682198286057,
      -0.005108702462166548,
      -0.09096534550189972,
      0.029813189059495926,
      0.044046126306056976,
      0.06151437759399414,
      0.01251932978630066,
      0.056397344917058945,
      -0.058733392506837845,
      -0.025805925950407982,
      0.09083637595176697,
      -0.013863339088857174,
      -0.04920085519552231,
      -0.017939042299985886,
      0.05030819773674011,
      -0.06663256883621216,
      0.0166718028485775,
      -0.03947979956865311,
      0.004849935416132212,
      0.03231661394238472,
      -0.03790628910064697,
      -0.004001079127192497,
      0.02892192453145981,
      0.02650085650384426,
      -0.03262419253587723,
      -0.07298333197832108,
      0.011066250503063202,
      0.07517851144075394,
      -0.10955996066331863,
      0.005163492169231176,
      0.0758945494890213,
      -0.09043877571821213,
      -0.07300666719675064,
      0.055517978966236115,
      -0.026629827916622162,
      0.011925691738724709,
      -0.0402199849486351,
      -0.021274931728839874,
      0.03639477118849754,
      0.0052308752201497555,
      0.052138835191726685,
      0.04718241095542908,
      -0.10950326174497604,
      -0.06452260166406631,
      -0.017349986359477043,
      0.0038552212063223124,
      -0.008339518681168556,
      -0.010842344723641872,
      -0.051965825259685516,
      -0.03668547421693802,
      -0.01771143265068531,
      0.03252170607447624,
      -0.03235292062163353,
      0.012248230166733265,
      -0.006910829804837704,
      0.10950498282909393,
      0.06493446975946426,
      -0.03361637890338898,
      -0.05038351193070412,
      0.07599130272865295,
      -0.0845409482717514,
      0.008308958262205124,
      -0.012693182565271854,
      0.023469695821404457,
      -0.04447207599878311,
      -0.033035434782505035,
      -0.017239652574062347,
      -0.09219551086425781,
      -0.04167674854397774,
      -0.025432951748371124,
      0.07556189596652985,
      -0.0007065345998853445,
      0.07272578775882721,
      -0.03931267559528351,
      0.0554046630859375,
      0.03384628891944885,
      -0.013722632080316544,
      0.022382961586117744,
      -0.04415999352931976,
      0.03666199371218681,
      -0.0007200633408501744,
      -0.052857231348752975,
      -0.019240546971559525,
      0.00023296471044886857,
      -0.0309612900018692,
      0.02372070401906967,
      -0.017114657908678055,
      -0.010938416235148907,
      0.009134292602539062,
      0.05277077108621597,
      0.07553372532129288,
      0.019734608009457588,
      -0.02065981552004814,
      0.016299189999699593,
      -0.046569280326366425,
      -0.03905481472611427,
      0.03169930353760719,
      0.008191362954676151,
      0.02502141334116459,
      0.0632401704788208,
      -0.006901300512254238,
      0.11339124292135239,
      -0.012014866806566715,
      0.0028856853023171425,
      0.0739058181643486,
      0.08598755300045013,
      -0.10338600724935532,
      0.0036728507839143276,
      0.030134670436382294,
      -0.046095773577690125,
      0.06855914741754532,
      0.07733940333127975,
      0.05169549584388733,
      0.03400755301117897,
      0.08337850868701935,
      -0.019576843827962875,
      0.03796352073550224,
      -0.0010335271945223212,
      -0.08201752603054047,
      0.049349185079336166,
      0.02756146527826786,
      -0.046927448362112045,
      0.02097480371594429,
      -0.052286043763160706,
      0.013907554559409618,
      0.062356170266866684,
      0.012276588939130306,
      0.060735370963811874,
      -0.0507829487323761,
      -0.01667034812271595,
      0.009925066493451595,
      -0.002971877343952656,
      -0.027444450184702873,
      -0.010517239570617676,
      0.07498084008693695,
      -0.0063250805251300335,
      0.018723852932453156,
      0.039860598742961884,
      0.0249903816729784,
      -0.011208114214241505,
      -0.003905537072569132,
      -0.12755170464515686,
      -0.09761302918195724,
      0.008089758455753326,
      0.05642075091600418,
      0.044693317264318466,
      0.044856324791908264,
      0.03345981612801552,
      -0.06448402255773544,
      -0.0013386399950832129,
      -0.0012789355823770165,
      0.04824042320251465,
      -0.033115923404693604,
      0.08800162374973297,
      -0.0798235535621643,
      -0.01904083788394928,
      -0.041918445378541946,
      0.057984430342912674,
      0.053310979157686234,
      0.07374780625104904,
      0.08741717785596848,
      -0.011306163854897022,
      0.008706524036824703,
      -0.002800809685140848,
      -0.05288514122366905,
      0.028546728193759918,
      0.010209368541836739,
      -0.018186120316386223,
      -0.012841819785535336,
      -0.03205879405140877,
      -0.013144925236701965,
      0.08198576420545578,
      0.07796578109264374,
      -0.017794592306017876,
      0.006242023315280676,
      0.0022450117394328117,
      0.05479687079787254,
      -0.00822521187365055,
      -0.009622847661376,
      -0.055569395422935486,
      0.05533834919333458,
      -0.052570320665836334,
      -0.03191438317298889,
      0.041967034339904785,
      0.13935698568820953,
      -0.10419979691505432,
      0.013233588077127934,
      -0.05224413052201271,
      0.03575384244322777,
      -0.049944017082452774,
      -0.07148770987987518,
      0.0746612623333931,
      0.03932467848062515,
      0.07481655478477478,
      0.03723009675741196,
      0.010919828899204731,
      0.015238890424370766,
      -0.07547695189714432,
      -0.04904128611087799,
      0.008610348217189312,
      -0.0023821592330932617,
      -0.023077337071299553,
      -0.03414693474769592,
      0.03392671421170235,
      0.022216346114873886,
      0.005969569552689791,
      0.05693069100379944,
      0.028670325875282288,
      -0.10227633267641068,
      0.012806101702153683,
      0.006748779211193323,
      -0.07441244274377823,
      -0.03156605362892151,
      -0.022413788363337517,
      -0.0006610623677261174,
      0.000837592117022723,
      -0.05267258360981941,
      -0.04807192087173462,
      0.016404345631599426,
      0.05553055182099342,
      0.038620539009571075,
      -0.01081875804811716,
      0.023909451439976692,
      0.00820231344550848,
      0.06673476845026016,
      -0.01633111573755741,
      0.09171179682016373,
      -0.03260743245482445,
      0.025135628879070282,
      0.02866995520889759,
      0.06478624790906906,
      -0.045564379543066025,
      -0.07105579227209091,
      -0.03689809888601303,
      -0.0027954953256994486,
      -0.02908259816467762,
      0.030978035181760788,
      -0.02154521457850933,
      0.041756823658943176,
      0.05663128197193146,
      0.06691429764032364,
      -0.10119060426950455,
      0.03009141981601715,
      -0.10699035972356796,
      -0.0060952468775212765,
      -0.018826695159077644,
      -0.033318616449832916,
      0.03312215954065323,
      0.01651577278971672,
      0.07753320783376694,
      -0.02661631815135479,
      -0.0059371027164161205,
      -0.02213033102452755,
      -0.02631540410220623,
      0.035263869911432266,
      0.012514723464846611,
      0.02349258027970791,
      -0.09537360817193985,
      0.04259759560227394,
      -0.04249414801597595,
      0.008513632230460644,
      0.03551457077264786,
      0.037741661071777344,
      0.007891831919550896,
      0.044792283326387405,
      -0.05734460800886154,
      0.029576405882835388,
      -0.036529116332530975,
      -0.030946912243962288,
      0.058920715004205704,
      0.05767164006829262,
      0.07445526123046875,
      -0.02451680600643158,
      0.04289303719997406,
      -0.020390724763274193,
      0.04625872150063515,
      0.051773566752672195,
      0.017568642273545265,
      0.05191516876220703,
      0.04404153302311897,
      -0.023005662485957146,
      -0.12192100286483765,
      -0.038443755358457565,
      -0.002994709648191929,
      -0.08379723131656647,
      -0.0337708443403244,
      0.008898069150745869,
      -0.0052164774388074875,
      -0.05435057356953621,
      0.009404282085597515,
      -0.049934398382902145,
      -0.005425772164016962,
      -0.028335459530353546,
      0.06237906590104103,
      -0.05542920157313347,
      0.1348981410264969,
      -0.011305110529065132,
      -0.016388284042477608,
      0.0217957254499197,
      -0.049902718514204025,
      0.06463825702667236,
      -0.04586702585220337,
      0.0038691649679094553,
      -0.030971964821219444,
      -0.05943726375699043,
      0.05496387183666229,
      -0.03450747951865196,
      -0.041079845279455185,
      0.03854590281844139,
      -0.07385078072547913,
      -0.004305610433220863,
      -5.68111427128315e-05,
      0.019720595329999924,
      0.017451832070946693,
      0.043280571699142456,
      -0.008639659732580185,
      -0.011338287964463234,
      -0.09823788702487946,
      -0.019563307985663414,
      -0.0005689235986210406,
      -0.018380487337708473,
      0.031167959794402122,
      0.010653968900442123,
      0.031090104952454567,
      0.0330008827149868,
      -0.03013339824974537,
      -0.07950861006975174,
      -0.011237995699048042,
      0.07385919243097305,
      0.009793180972337723,
      -0.004006160423159599,
      -0.04461400955915451,
      0.012961889617145061,
      0.03577257692813873,
      0.022063540294766426,
      0.023068135604262352,
      0.00020326396042946726,
      -0.03398251533508301,
      0.03658891096711159,
      0.03696661815047264,
      -0.10542111098766327,
      0.05322438105940819,
      0.004430109169334173,
      0.007249087560921907,
      0.01930105686187744,
      -0.020806599408388138,
      0.0320487841963768,
      0.07277292758226395,
      -0.03971564397215843,
      -0.023525558412075043,
      0.059586264193058014,
      0.04501109942793846,
      -0.010286707431077957,
      0.03346149995923042,
      0.07008123397827148,
      0.004007385112345219,
      0.04584645479917526,
      -0.004111906513571739,
      0.05651579052209854,
      0.08986276388168335,
      0.053605739027261734,
      0.07204541563987732,
      -0.023666001856327057,
      -0.01274592150002718,
      -0.01665220968425274,
      -0.020094584673643112,
      -0.00024810785544104874,
      0.017568431794643402,
      -0.019162656739354134,
      0.040352221578359604,
      0.05846915394067764,
      0.01661858521401882,
      0.07369785755872726,
      0.05154984071850777,
      0.028575729578733444,
      -0.01807330921292305,
      -0.02782897837460041,
      -0.0019195961067453027,
      -0.017568346112966537,
      0.053640056401491165,
      0.010331423953175545,
      0.04677315801382065,
      -0.01229360606521368,
      -0.09998451173305511,
      0.0073776571080088615,
      0.052832722663879395,
      0.0014268014347180724,
      -0.022944865748286247,
      0.027911517769098282,
      -0.055094748735427856,
      -0.0598578043282032,
      0.036256711930036545,
      0.014608180150389671,
      -0.00799986906349659,
      -0.023668887093663216,
      0.04118518531322479,
      -0.013620919547975063,
      0.01025467924773693,
      0.04901072382926941,
      0.0533059723675251,
      -0.06172296777367592,
      0.012732166796922684,
      0.022083669900894165,
      -0.045423585921525955,
      0.03291931003332138,
      0.04145579785108566,
      0.027123207226395607,
      0.02341710776090622,
      -0.0023671798408031464
    ],
    [
      -0.029023637995123863,
      -0.028895989060401917,
      -0.033097874373197556,
      0.010198156349360943,
      0.06334665417671204,
      -0.039064422249794006,
      0.06861471384763718,
      -0.0989304780960083,
      -0.0944269597530365,
      -0.05673431605100632,
      0.01985757425427437,
      -0.025249645113945007,
      -0.00318499025888741,
      -0.01338534988462925,
      0.03288070857524872,
      -0.03697514906525612,
      0.012742549180984497,
      -0.06312023848295212,
      -0.01634874939918518,
      0.048998065292835236,
      -0.030383868142962456,
      0.004305240232497454,
      -0.05306915193796158,
      0.03606740012764931,
      0.07617955654859543,
      -0.004105771891772747,
      -0.033405669033527374,
      -0.06296031922101974,
      0.03847693279385567,
      0.0055273245088756084,
      0.00601478386670351,
      0.015534141100943089,
      -0.0485992357134819,
      -0.05035913363099098,
      -0.07953980565071106,
      -0.038899410516023636,
      0.011187045834958553,
      0.022549590095877647,
      -0.0002500534465070814,
      0.0033841324038803577,
      0.08667415380477905,
      0.04644200950860977,
      0.014874243177473545,
      0.05221852660179138,
      -0.028504177927970886,
      0.03888559341430664,
      0.09216112643480301,
      -0.03702298179268837,
      -0.004053538199514151,
      -0.014765271916985512,
      0.04866521805524826,
      -0.006170433480292559,
      0.009700726717710495,
      0.050120726227760315,
      -0.13105382025241852,
      0.006304736714810133,
      -0.008614237420260906,
      0.014146651141345501,
      -0.02567257545888424,
      0.06680771708488464,
      0.016842372715473175,
      -0.0829487144947052,
      0.03588903322815895,
      -0.06392217427492142,
      -0.0056585813872516155,
      -0.02476363629102707,
      0.03924562409520149,
      0.06754827499389648,
      -0.006380417384207249,
      0.02482783794403076,
      -0.059685297310352325,
      -0.08485649526119232,
      -0.0040432158857584,
      0.04922058805823326,
      0.016440806910395622,
      -0.016398059204220772,
      0.044040217995643616,
      0.010998876765370369,
      -0.06778086721897125,
      -0.0804135799407959,
      0.05631273612380028,
      0.08789005130529404,
      -0.03735208138823509,
      -0.05895821750164032,
      0.0163787379860878,
      0.06789067387580872,
      -0.008366535417735577,
      0.03275825455784798,
      -0.05535171926021576,
      -0.05394366756081581,
      0.021549420431256294,
      0.002269973512738943,
      0.04030561074614525,
      0.028080755844712257,
      0.05150185897946358,
      -0.025628671050071716,
      0.08598356693983078,
      -0.04043601080775261,
      0.052008695900440216,
      -0.01660514622926712,
      -0.0023424855899065733,
      -0.043482113629579544,
      0.03843529894948006,
      -0.08542551845312119,
      0.07077598571777344,
      0.010178946889936924,
      0.05261761695146561,
      -0.01887688599526882,
      0.05655388534069061,
      0.00363058689981699,
      -0.0999392420053482,
      0.06423315405845642,
      0.02894006483256817,
      0.05190468207001686,
      0.004632242489606142,
      -0.0272796880453825,
      0.02950768731534481,
      0.05731583014130592,
      -0.09072133898735046,
      -0.11631225049495697,
      -0.04549765959382057,
      0.0004711553337983787,
      -0.0044533489271998405,
      0.013965284451842308,
      0.018643159419298172,
      -0.11613422632217407,
      0.04650133103132248,
      0.10068437457084656,
      -0.005457303021103144,
      -0.025685172528028488,
      -0.03761472553014755,
      -0.0003937140863854438,
      0.0157537329941988,
      -0.13176199793815613,
      -0.02822878584265709,
      -0.05154643952846527,
      -0.12648242712020874,
      0.01440769899636507,
      -0.014595399610698223,
      -0.04833938926458359,
      0.025601575151085854,
      0.10655570775270462,
      -0.02534867450594902,
      0.03222303465008736,
      -0.020502740517258644,
      0.009536626748740673,
      0.008378605358302593,
      -0.01957842707633972,
      -0.06739876419305801,
      0.04235962778329849,
      0.08078748732805252,
      0.0661269873380661,
      0.017306027933955193,
      0.04661710187792778,
      0.03283775970339775,
      -0.015443025156855583,
      -0.0056725493632256985,
      0.037379804998636246,
      0.007470719050616026,
      0.07943366467952728,
      0.00531460577622056,
      -0.02235930971801281,
      0.008905492722988129,
      0.05254056677222252,
      -0.07964582741260529,
      0.04624878987669945,
      0.10827029496431351,
      0.05273223668336868,
      0.02118542417883873,
      0.005012216977775097,
      -0.022794248536229134,
      0.024609725922346115,
      0.07199247926473618,
      -0.044392675161361694,
      0.034409623593091965,
      -0.031310323625802994,
      -0.02995668165385723,
      0.02580070123076439,
      -0.06603019684553146,
      -0.053414300084114075,
      -0.008736435323953629,
      -0.01716403104364872,
      -0.038632117211818695,
      0.04683614894747734,
      0.014093783684074879,
      -0.02777373045682907,
      -0.06469611078500748,
      -0.0117855379357934,
      -0.04783061891794205,
      0.0037590384017676115,
      -0.015600788407027721,
      -0.012825487181544304,
      0.03857409581542015,
      0.02980223298072815,
      -0.028369130566716194,
      -0.01708759181201458,
      0.021887021139264107,
      0.04083434119820595,
      -0.04304688796401024,
      0.0004228934703860432,
      0.07819776237010956,
      0.00952126458287239,
      0.08962282538414001,
      -0.034505076706409454,
      -0.0495254248380661,
      0.07680381834506989,
      0.021857304498553276,
      0.041168004274368286,
      0.03616127744317055,
      0.06702999770641327,
      -0.02463972009718418,
      -0.01749367266893387,
      -0.05866848677396774,
      0.0334346778690815,
      -0.07929640263319016,
      -0.02950436621904373,
      -0.01045215968042612,
      -0.031976521015167236,
      -0.018384898081421852,
      -0.05729122459888458,
      -0.014154627919197083,
      0.09841607511043549,
      -0.006290377117693424,
      0.06925977766513824,
      0.05480608344078064,
      0.11841042339801788,
      0.08844610303640366,
      -0.07606425136327744,
      -0.03533034399151802,
      -0.024322789162397385,
      -0.042779576033353806,
      0.029726320877671242,
      -0.06472461670637131,
      0.045009639114141464,
      0.009093459695577621,
      -0.04941466078162193,
      -0.055721476674079895,
      -0.026954060420393944,
      0.043028779327869415,
      -0.0011967141181230545,
      0.002162075834348798,
      -0.011788266710937023,
      0.041837986558675766,
      0.005743966903537512,
      0.022425241768360138,
      -0.02096550539135933,
      -0.05129197612404823,
      0.07938071340322495,
      -0.022773025557398796,
      0.03415856137871742,
      -0.00784887745976448,
      0.03384898975491524,
      -0.027584411203861237,
      0.0050400663167238235,
      -0.021445980295538902,
      0.03636282682418823,
      -0.08595981448888779,
      0.010519427247345448,
      0.02952255681157112,
      0.037852656096220016,
      0.02912461943924427,
      -0.0670689269900322,
      -0.0335317887365818,
      -0.014460699632763863,
      -0.008396738208830357,
      0.005362044088542461,
      0.027572186663746834,
      0.0035894676111638546,
      -0.021227924153208733,
      0.03328091651201248,
      0.011191503144800663,
      -0.005233239848166704,
      0.028213871642947197,
      0.05015532672405243,
      0.09942818433046341,
      -0.03473033010959625,
      -0.03973297402262688,
      0.01644120365381241,
      -0.08851601183414459,
      -0.05419342964887619,
      0.0013470671838149428,
      -0.012804937548935413,
      0.01924346759915352,
      -0.02674383483827114,
      -0.0923038199543953,
      0.0248795747756958,
      0.017754901200532913,
      0.04634551703929901,
      0.011716621927917004,
      -0.012584147043526173,
      0.016137002035975456,
      -0.0030810919124633074,
      0.0059182182885706425,
      -0.001228992477990687,
      0.03287293389439583,
      -0.0027378236409276724,
      -0.008293363265693188,
      -0.029995139688253403,
      -0.06827836483716965,
      -0.020511027425527573,
      -0.032116420567035675,
      -0.04967290908098221,
      -0.01654212549328804,
      -0.0256955586373806,
      0.014394262805581093,
      0.03906937316060066,
      0.025461416691541672,
      0.02244710549712181,
      0.04672916606068611,
      -0.06945633143186569,
      0.02458389848470688,
      -0.033317845314741135,
      0.010488606058061123,
      0.012736935168504715,
      -0.0268643070012331,
      0.0034797678235918283,
      -0.03942624852061272,
      0.011746463365852833,
      0.02095966786146164,
      0.005297643132507801,
      0.08124250918626785,
      -0.06706297397613525,
      0.04714661464095116,
      0.015876498073339462,
      0.013858607038855553,
      0.005211219657212496,
      -0.07853966951370239,
      -0.015068775974214077,
      0.06635333597660065,
      0.05244230106472969,
      -0.03657228872179985,
      0.08750835061073303,
      -0.014900723472237587,
      -0.02077658288180828,
      0.0186803936958313,
      -0.013519642874598503,
      -0.02533409744501114,
      0.015794500708580017,
      -0.03443560749292374,
      0.0677730143070221,
      0.07043386995792389,
      0.018879149109125137,
      -0.0009643887751735747,
      0.0064499941654503345,
      0.13075067102909088,
      -0.00108307262416929,
      0.004736386239528656,
      -0.03381117433309555,
      -0.043218210339546204,
      0.012964666821062565,
      -0.08137483149766922,
      -0.015637334436178207,
      0.058561522513628006,
      0.09930803626775742,
      -0.02180097997188568,
      0.028134796768426895,
      0.001538153737783432,
      0.003610228653997183,
      0.049846190959215164,
      0.08254192769527435,
      0.06313896924257278,
      0.09782340377569199,
      0.008239689283072948,
      0.0028886760119348764,
      0.027360258623957634,
      -0.03255528211593628,
      0.04051107540726662,
      0.027120579034090042,
      -0.006971999071538448,
      0.09326783567667007,
      0.0314156711101532,
      -0.03175610303878784,
      0.05086367204785347,
      -0.005855799186974764,
      -0.054726794362068176,
      -0.000769699108786881,
      0.007115657441318035,
      0.08377531170845032,
      0.04948057979345322,
      -0.007448401767760515,
      0.06827352941036224,
      0.007412067148834467,
      -0.05513980984687805,
      -0.005609175190329552,
      0.045502156019210815,
      -0.013081066310405731,
      -0.08035751432180405,
      0.03781784325838089,
      -0.14306610822677612,
      -0.024304533377289772,
      -0.03207504004240036,
      -0.0709686279296875,
      -0.021593410521745682,
      0.044085677713155746,
      -0.0006685814005322754,
      -0.015171264298260212,
      0.051114827394485474,
      0.015321752056479454,
      0.019693585112690926,
      0.018822548910975456,
      0.03511799871921539,
      -0.03404917195439339,
      -0.006594667676836252,
      -0.06012933328747749,
      0.046270933002233505,
      -0.0405130498111248,
      0.012880561873316765,
      0.001994556747376919,
      -0.06279289722442627,
      0.03396693244576454,
      -0.06166819483041763,
      -0.03554418310523033,
      -0.024711234495043755,
      -0.04788467288017273,
      -0.010730760172009468,
      -0.03262706473469734,
      0.06484253704547882,
      0.002889371244236827,
      -0.05797479674220085,
      0.05757897347211838,
      0.012138526886701584,
      0.049462031573057175,
      0.017974067479372025,
      0.06650892645120621,
      -0.04203415662050247,
      -0.050486449152231216,
      -0.0057366034016013145,
      -0.001922702882438898,
      0.006406052969396114,
      -0.05068846046924591,
      0.011375189758837223,
      -0.049023374915122986,
      0.06714556366205215,
      0.07314328104257584,
      0.03424777463078499,
      0.05726522207260132,
      -0.03720272704958916,
      0.017996158450841904,
      -0.01387710589915514,
      -0.03292223438620567,
      -0.05783558636903763,
      0.03237901255488396,
      -0.0864194855093956,
      0.029750848188996315,
      -0.013811107724905014,
      0.002296320628374815,
      0.021473679691553116,
      -0.00041440114728175104,
      0.02937556989490986,
      -0.014275181107223034,
      0.06956921517848969,
      0.013471176847815514,
      0.08389090746641159,
      -0.007380771916359663,
      0.024693813174962997,
      0.0380423404276371,
      -0.08129046857357025,
      0.06483440846204758,
      -0.11689013242721558,
      0.02233443409204483,
      -0.020248044282197952,
      -0.03974287584424019,
      0.0009336840594187379,
      0.0015127270016819239,
      -0.018006885424256325,
      -0.0815763920545578,
      0.05665583536028862,
      0.024378577247262,
      -0.0242645014077425,
      -0.030599527060985565,
      -0.011582781560719013,
      -0.02209322527050972,
      -0.018917426466941833,
      0.033544328063726425,
      0.03990442678332329,
      0.030545448884367943,
      -0.016456497833132744,
      -0.01928165927529335,
      0.11174685508012772,
      -0.028703857213258743,
      0.030724717304110527,
      -0.06298180669546127,
      0.06289351731538773,
      -0.008704851381480694,
      0.008942666463553905,
      -0.02806059643626213,
      0.02933569811284542,
      0.014045179821550846,
      -0.009152127429842949,
      0.051664989441633224,
      -0.022944193333387375,
      0.023021165281534195,
      -0.024562373757362366,
      0.07909061014652252,
      -0.017080673947930336,
      -0.03530026599764824,
      -0.07768562436103821,
      0.022921571508049965,
      -0.01628720387816429,
      -0.011361495591700077,
      0.04362204670906067,
      0.04497167840600014,
      0.09999088943004608
    ],
    [
      -0.018372295424342155,
      0.017321951687335968,
      0.08621346950531006,
      0.01069677621126175,
      -0.00042777147609740496,
      -0.03251276910305023,
      0.06918565928936005,
      -0.011623912490904331,
      -0.021845338866114616,
      0.03076982870697975,
      -0.07127021253108978,
      0.06894379109144211,
      -0.013654913753271103,
      0.05006776750087738,
      0.021813364699482918,
      -0.043554190546274185,
      0.010525373741984367,
      -0.08540358394384384,
      -0.03876615688204765,
      0.040317680686712265,
      -0.017665298655629158,
      0.003007190302014351,
      -0.03485890105366707,
      -0.0012705815024673939,
      0.022752484306693077,
      -0.0659046322107315,
      0.006132871378213167,
      0.07075158506631851,
      0.013822784647345543,
      -0.01852698065340519,
      -0.024045566096901894,
      -0.04646815359592438,
      -0.0022204844281077385,
      -0.04130730777978897,
      0.025673117488622665,
      0.03799983859062195,
      0.04758046194911003,
      0.013765516690909863,
      -0.05415787175297737,
      0.008613985031843185,
      0.00954441074281931,
      0.08785465359687805,
      0.06200026348233223,
      -0.0003613931476138532,
      0.02490849420428276,
      0.008347336202859879,
      -0.06263528764247894,
      0.04354679584503174,
      0.031744323670864105,
      -0.08569768816232681,
      -0.04561552405357361,
      0.04466943442821503,
      -0.031006231904029846,
      0.0441226027905941,
      -0.019582001492381096,
      0.03681959956884384,
      0.026776377111673355,
      0.010069145821034908,
      -0.07545031607151031,
      -0.1309509128332138,
      0.05894352123141289,
      -0.09601039439439774,
      0.04457949846982956,
      0.03927525505423546,
      0.06812259554862976,
      -0.004966764245182276,
      0.017826596274971962,
      -0.05196819081902504,
      0.00015428900951519608,
      -0.012856883928179741,
      -0.007361072581261396,
      -0.05043267458677292,
      0.000872623233590275,
      0.01996724121272564,
      -0.03879976645112038,
      0.021505095064640045,
      -0.03991496562957764,
      0.010592464357614517,
      -0.009219174273312092,
      0.029546493664383888,
      0.05005154013633728,
      0.06076233088970184,
      0.06862955540418625,
      -0.03603284806013107,
      -0.02997850440442562,
      -0.05003869906067848,
      0.02199874445796013,
      -0.04738427698612213,
      -0.01954009383916855,
      0.019199835136532784,
      0.022254478186368942,
      0.006030398420989513,
      0.037536196410655975,
      -0.007827204652130604,
      -0.045764148235321045,
      0.009408657439053059,
      -0.013081833720207214,
      0.10802013427019119,
      -0.05695554241538048,
      -0.019500089809298515,
      0.02313290536403656,
      -0.041755300015211105,
      -0.06562136113643646,
      0.02048376016318798,
      0.005410561803728342,
      -0.05623994767665863,
      -0.03373619168996811,
      -0.02095595933496952,
      -0.002584044821560383,
      0.04134126380085945,
      -0.1020333543419838,
      0.04847491905093193,
      0.03624584153294563,
      -0.03606387972831726,
      -0.03023320436477661,
      -0.02940760925412178,
      -0.0188247412443161,
      0.05236634612083435,
      0.08040356636047363,
      0.0832061916589737,
      -0.02589334174990654,
      -0.07924444228410721,
      -0.02769743651151657,
      0.07650177925825119,
      -0.012241593562066555,
      -0.07821435481309891,
      0.03364802524447441,
      -0.06047055125236511,
      -0.019625015556812286,
      -0.020057857036590576,
      0.08058855682611465,
      0.10702101141214371,
      -0.005105216521769762,
      -0.05734338238835335,
      0.04856278374791145,
      0.01642456278204918,
      -0.014248322695493698,
      -0.03713871166110039,
      0.062092673033475876,
      0.0012212482979521155,
      -0.06663040816783905,
      0.06140328198671341,
      -0.04292811080813408,
      -0.026872511953115463,
      0.03223862871527672,
      -0.009789064526557922,
      0.007829904556274414,
      0.031144289299845695,
      -0.0538422055542469,
      -0.021673260256648064,
      -0.012667528353631496,
      -0.016106614843010902,
      -0.03851323947310448,
      0.01932341232895851,
      0.042114123702049255,
      -0.033206772059202194,
      -0.018521469086408615,
      0.015042012557387352,
      -0.00226557906717062,
      0.034490976482629776,
      0.1371602565050125,
      -0.05671050772070885,
      -0.012164101004600525,
      0.023512043058872223,
      -0.04481857642531395,
      -0.006354734301567078,
      -0.046948064118623734,
      0.023385757580399513,
      0.0059372191317379475,
      0.0052955495193600655,
      0.051431313157081604,
      -0.008909896947443485,
      -0.06997205317020416,
      0.009944818913936615,
      -0.0416441448032856,
      0.06326128542423248,
      0.045117586851119995,
      -0.025793088600039482,
      0.0245358906686306,
      0.01766146346926689,
      -0.0754852369427681,
      -0.00758958887308836,
      -0.018130626529455185,
      0.05762934312224388,
      -0.015502991154789925,
      0.04105466604232788,
      -0.017671605572104454,
      0.12120763212442398,
      0.03601888194680214,
      -0.0441572442650795,
      -0.023172734305262566,
      0.03467164188623428,
      -0.041240062564611435,
      0.06881836801767349,
      0.048134103417396545,
      -0.03070797771215439,
      -0.04341179132461548,
      -0.059526775032281876,
      0.0011744532966986299,
      0.032752227038145065,
      0.002571278950199485,
      -0.0015597277088090777,
      -0.03347167372703552,
      -0.023341545835137367,
      -0.021977335214614868,
      -0.06120588630437851,
      0.034559156745672226,
      0.027781305834650993,
      0.05813627317547798,
      -0.021494433283805847,
      -0.03686322271823883,
      0.013557597063481808,
      -0.01602514274418354,
      -0.11251272261142731,
      -0.02779068984091282,
      -0.015800079330801964,
      0.07626207917928696,
      0.052221305668354034,
      -0.028778422623872757,
      0.06755784898996353,
      -0.05827131122350693,
      0.04622766003012657,
      0.017095990478992462,
      0.01785416714847088,
      -0.012511721812188625,
      0.017340295016765594,
      -0.004255857318639755,
      -0.010998879559338093,
      0.07366133481264114,
      -0.010699048638343811,
      0.03470008447766304,
      0.004846582654863596,
      -0.06062513589859009,
      -0.03230505809187889,
      -0.05064413696527481,
      -0.028019927442073822,
      0.001968295080587268,
      -0.027361484244465828,
      -0.06446933001279831,
      0.02081288956105709,
      0.04434441030025482,
      -0.08386699110269547,
      -0.009731773287057877,
      0.09047439694404602,
      0.008764860220253468,
      -0.0071390955708920956,
      -0.056738559156656265,
      0.03661695495247841,
      -0.0671154260635376,
      0.046881020069122314,
      0.037602297961711884,
      -0.02808053232729435,
      -0.00377793051302433,
      -0.05633671209216118,
      0.05124160274863243,
      -0.06738398969173431,
      -0.006991413421928883,
      0.05103198066353798,
      -0.005939112044870853,
      0.005042213015258312,
      0.04991505295038223,
      -0.02933819778263569,
      -0.11174336820840836,
      0.0001031680076266639,
      0.024083543568849564,
      0.08095715939998627,
      0.00751277944073081,
      0.00884851161390543,
      -0.09388447552919388,
      -0.05152624845504761,
      -0.054916057735681534,
      0.06292113661766052,
      -0.027654556557536125,
      -0.0950627401471138,
      -0.0026052261237055063,
      -0.03552353009581566,
      -0.09378761798143387,
      0.05384648218750954,
      -0.0034053525887429714,
      -0.01804516464471817,
      0.06721428036689758,
      0.02068728394806385,
      -0.05517908185720444,
      0.03838172182440758,
      0.06134146451950073,
      0.029263881966471672,
      -0.00928686186671257,
      -0.019917529076337814,
      0.06800160557031631,
      0.01119350828230381,
      -0.007409671787172556,
      0.05065017566084862,
      0.003845705185085535,
      0.06424199789762497,
      0.0469338595867157,
      0.013331967405974865,
      -0.053391631692647934,
      0.05557873472571373,
      -0.05744088441133499,
      -0.014846793375909328,
      -0.04892011359333992,
      -0.011247183196246624,
      0.09665534645318985,
      -0.01254615280777216,
      -0.004319377709180117,
      -0.0659971684217453,
      -0.0571618527173996,
      0.027675515040755272,
      -0.06475625187158585,
      -0.0009951728861778975,
      -0.0014276420697569847,
      0.054355375468730927,
      -0.02335892617702484,
      -0.0583791583776474,
      0.031785618513822556,
      -0.03332369774580002,
      -0.02328663505613804,
      0.02056165784597397,
      0.10210359841585159,
      -0.03574410825967789,
      0.09836386144161224,
      -0.002795126987621188,
      0.09650452435016632,
      0.05933277681469917,
      0.015294171869754791,
      -0.05544653907418251,
      -0.04150570556521416,
      0.021492673084139824,
      -0.010852215811610222,
      0.08692639321088791,
      0.058258213102817535,
      -0.0025371306110173464,
      -0.040554773062467575,
      -0.02898324280977249,
      -0.07253524661064148,
      -0.007783208973705769,
      0.006810420658439398,
      0.03975612297654152,
      0.02410115674138069,
      -0.04340355843305588,
      -0.03359951078891754,
      -0.003588029183447361,
      -0.04090031608939171,
      -0.08307680487632751,
      -0.053743742406368256,
      -0.0467059426009655,
      0.03678460046648979,
      -0.03810843452811241,
      0.07937953621149063,
      0.0839296206831932,
      -0.035544149577617645,
      -0.06111319735646248,
      0.005318384151905775,
      -0.08100974559783936,
      0.009550544433295727,
      0.03877464681863785,
      -0.05928696319460869,
      -0.08308960497379303,
      0.010310503654181957,
      0.08473435044288635,
      0.020974112674593925,
      0.009876452386379242,
      0.08766429871320724,
      0.03396608307957649,
      -0.03241567313671112,
      0.005861591547727585,
      0.02296186238527298,
      -0.01093125157058239,
      -0.06504109501838684,
      -0.07618115842342377,
      0.018451379612088203,
      -0.03175492212176323,
      -0.05973999574780464,
      0.008918541483581066,
      -0.011726980097591877,
      0.09197074919939041,
      0.01275420468300581,
      -0.05210788920521736,
      -0.08299504220485687,
      -0.012975059449672699,
      0.017507193610072136,
      -0.05953284725546837,
      -0.06281685829162598,
      -0.019580183550715446,
      -0.025189783424139023,
      -0.0012232655426487327,
      0.046085748821496964,
      -0.0012728460133075714,
      -0.03034983016550541,
      0.022389722988009453,
      0.05815725773572922,
      -0.071205273270607,
      0.001119939493946731,
      -0.04258096590638161,
      0.06384353339672089,
      -0.0063139889389276505,
      0.027863968163728714,
      0.058357350528240204,
      -0.05913431569933891,
      0.01131179928779602,
      0.0244529340416193,
      0.07028272747993469,
      0.047318000346422195,
      0.03560326620936394,
      -0.07018401473760605,
      -0.022360125556588173,
      0.03483257070183754,
      0.059037160128355026,
      -0.07123460620641708,
      -0.011416543275117874,
      -0.032426368445158005,
      -0.023855585604906082,
      -0.01847808063030243,
      0.03944956138730049,
      0.011177386157214642,
      -0.027785111218690872,
      -0.024974951520562172,
      0.03731537610292435,
      -0.05691373348236084,
      -0.0343422070145607,
      -0.002844069618731737,
      0.022244736552238464,
      0.051732368767261505,
      -0.016623083502054214,
      -0.013451242819428444,
      0.001625158591195941,
      -0.013219812884926796,
      -0.049628034234046936,
      -0.10822368413209915,
      -0.042407963424921036,
      -0.0030631464906036854,
      -0.021374769508838654,
      0.06378597766160965,
      -0.051941122859716415,
      -0.013664866797626019,
      -0.014647891744971275,
      0.007966039702296257,
      0.004406407009810209,
      -0.005939007271081209,
      0.0829552561044693,
      0.059596817940473557,
      -0.019997689872980118,
      -0.058354366570711136,
      0.005379732232540846,
      0.08813999593257904,
      -0.010843344032764435,
      0.0037931548431515694,
      -0.05588393658399582,
      -0.03128010407090187,
      0.04062575101852417,
      -0.06655792146921158,
      -0.004457622766494751,
      0.007693031802773476,
      0.036345649510622025,
      -0.011933608911931515,
      -0.0037745980080217123,
      -0.010803444311022758,
      -0.012213594280183315,
      0.02277274616062641,
      0.0028992267325520515,
      -0.014865463599562645,
      0.019582461565732956,
      0.02533455565571785,
      0.008851486258208752,
      -0.010929837822914124,
      -0.062200501561164856,
      0.0755700170993805,
      -0.01690915785729885,
      -0.08860672265291214,
      0.06635261327028275,
      0.025965003296732903,
      0.006644205190241337,
      0.01841317117214203,
      -0.05413110926747322,
      0.025614188984036446,
      0.025380538776516914,
      0.008237532339990139,
      -0.006510375067591667,
      0.08450859040021896,
      0.0171201154589653,
      0.02632134221494198,
      -0.011013602837920189,
      -0.038221146911382675,
      0.03627488389611244,
      0.08983542770147324,
      -0.03757112845778465,
      -0.0590638592839241,
      -0.03805902227759361,
      -0.00939677283167839,
      -0.02471093460917473,
      -0.0004935165052302182,
      -0.061081863939762115,
      -0.06291233003139496,
      -0.022818315774202347,
      0.02548316679894924,
      -0.02703658677637577,
      -0.015062686987221241,
      -0.02510254457592964,
      0.015947062522172928,
      -0.024376709014177322,
      0.07745316624641418,
      -0.033033858984708786,
      0.009164072573184967
    ],
    [
      0.012976417317986488,
      -0.03344695642590523,
      0.008847272023558617,
      -0.06201969087123871,
      -0.12923549115657806,
      0.08703799545764923,
      -0.016036536544561386,
      0.010207626968622208,
      -0.034058235585689545,
      0.14608244597911835,
      -0.03671948239207268,
      -0.03395354747772217,
      0.04106789454817772,
      0.023144500330090523,
      -0.11820412427186966,
      0.03968839719891548,
      -0.02624773047864437,
      0.029048381373286247,
      0.027137691155076027,
      0.09597300738096237,
      -0.04316325858235359,
      0.006963064894080162,
      0.039796069264411926,
      0.10049594938755035,
      -0.014475084841251373,
      0.0047537111677229404,
      -0.016268743202090263,
      0.06991996616125107,
      0.11166362464427948,
      -0.020109225064516068,
      -0.019375739619135857,
      -0.024655576795339584,
      0.02430729754269123,
      0.06627431511878967,
      -0.017145076766610146,
      0.029946373775601387,
      0.01992669329047203,
      0.031721081584692,
      -0.014307205565273762,
      0.06955894082784653,
      -0.044668879359960556,
      -0.022917447611689568,
      -0.007317285053431988,
      0.0010807595681399107,
      -0.019188327714800835,
      -0.06221669167280197,
      0.01521220151335001,
      0.05865464732050896,
      0.040711354464292526,
      0.03162521868944168,
      -0.028501594439148903,
      0.020914748311042786,
      0.06503608077764511,
      -0.0038800209295004606,
      0.05598330870270729,
      -0.05324660986661911,
      0.015088388696312904,
      0.010048695839941502,
      -0.007769497577100992,
      0.009442892856895924,
      0.045197151601314545,
      0.0019930959679186344,
      -0.0031381561420857906,
      -0.041570425033569336,
      -0.059178683906793594,
      0.011505122296512127,
      -0.012631101533770561,
      -0.08947332948446274,
      0.021708577871322632,
      0.023624515160918236,
      -0.06615336239337921,
      0.055889710783958435,
      -0.00912133976817131,
      0.03287031874060631,
      -0.03803341090679169,
      0.009791702032089233,
      0.05951627716422081,
      0.00656008068472147,
      0.09537994861602783,
      -0.049830444157123566,
      0.06527060270309448,
      0.008099013939499855,
      0.045264095067977905,
      0.018690425902605057,
      0.023929188027977943,
      0.04168814420700073,
      0.09194948524236679,
      0.0201007891446352,
      0.0017643326427787542,
      -0.028394123539328575,
      0.009729376062750816,
      -0.021232757717370987,
      0.03772128373384476,
      -0.005870486609637737,
      -0.05986575782299042,
      -0.07101443409919739,
      -0.050065260380506516,
      -0.021622514352202415,
      -0.03562325984239578,
      0.05610749125480652,
      0.053810637444257736,
      -0.02412847988307476,
      0.0037013795226812363,
      -0.13655813038349152,
      0.09789441525936127,
      0.0038820868358016014,
      -0.12630628049373627,
      -0.00260565266944468,
      0.030174994841217995,
      -0.07355799525976181,
      -0.047818198800086975,
      0.01957586407661438,
      -0.01043503824621439,
      0.03039541281759739,
      0.08222346752882004,
      0.03323341906070709,
      0.038097597658634186,
      -0.03038715198636055,
      0.00591233279556036,
      0.028944669291377068,
      0.024710286408662796,
      -0.008875371888279915,
      -0.03336622193455696,
      -0.01759982854127884,
      0.06486061215400696,
      -0.00878755934536457,
      0.040760625153779984,
      -0.054904282093048096,
      0.056132614612579346,
      -0.032892145216464996,
      -0.006041768006980419,
      -0.041505809873342514,
      -0.06824708729982376,
      -0.00548246456310153,
      -0.015066388994455338,
      -0.0067145079374313354,
      0.0031713503412902355,
      0.03215976431965828,
      0.001707795076072216,
      -0.07263009250164032,
      -0.008939962834119797,
      -0.0029907217249274254,
      0.08675555884838104,
      -0.00010304399620508775,
      -0.07037468254566193,
      -0.06102173402905464,
      -0.05950099974870682,
      -0.046361904591321945,
      0.008197498507797718,
      -0.052335020154714584,
      -0.018278885632753372,
      0.002830317011103034,
      0.019138777628540993,
      0.021741801872849464,
      -0.04858709126710892,
      -0.037602122873067856,
      -0.03322669491171837,
      0.008383219130337238,
      -0.022380808368325233,
      -0.05832885950803757,
      0.10102101415395737,
      -0.00970511045306921,
      0.049413613975048065,
      0.01601123996078968,
      0.008576985448598862,
      -0.01505258958786726,
      0.010546373203396797,
      -0.028317240998148918,
      0.05127476528286934,
      -0.013395789079368114,
      0.008278942666947842,
      0.04132949933409691,
      -0.020857010036706924,
      0.014811542816460133,
      0.019136890769004822,
      0.02264527417719364,
      -0.048185717314481735,
      0.0017450747545808554,
      0.04769735038280487,
      -0.017727836966514587,
      -0.029464688152074814,
      -0.041914816945791245,
      -0.10772334784269333,
      -0.016190622001886368,
      -0.06609799712896347,
      0.022337021306157112,
      -0.036184944212436676,
      -0.0021142743062227964,
      0.07032255083322525,
      -0.02609744481742382,
      0.008977159857749939,
      -0.061998602002859116,
      -0.03251263126730919,
      0.055406324565410614,
      0.07977022975683212,
      0.01243448257446289,
      -0.007307735271751881,
      -0.04528990015387535,
      -0.005288923624902964,
      0.07600247859954834,
      -0.009706954471766949,
      -0.028575651347637177,
      0.0638207271695137,
      0.02353753335773945,
      0.01941729709506035,
      0.013031872920691967,
      0.03436356782913208,
      0.0006900770822539926,
      -0.06014792621135712,
      0.02465173788368702,
      0.07711700350046158,
      0.04087664932012558,
      0.06922661513090134,
      -0.04325537011027336,
      0.011060117743909359,
      -0.0032259763684123755,
      0.03046330064535141,
      0.06486697494983673,
      -0.026363639160990715,
      0.027441147714853287,
      0.032825663685798645,
      0.006526337005198002,
      -0.009888097643852234,
      -0.00021605628717225045,
      0.00926694180816412,
      -0.03521019592881203,
      0.015908250585198402,
      -0.09375303238630295,
      0.03777246177196503,
      0.05701009929180145,
      0.01856984943151474,
      0.03923894837498665,
      -0.05770343914628029,
      -0.04180886968970299,
      -0.05475703626871109,
      -0.009035761468112469,
      -0.02001885697245598,
      0.000567429990042001,
      0.07265810668468475,
      0.041531145572662354,
      0.08719181269407272,
      -0.045857202261686325,
      -0.019885092973709106,
      -0.06982200592756271,
      0.04653944820165634,
      0.023975951597094536,
      -0.04508602246642113,
      0.029802320525050163,
      0.016503117978572845,
      -0.005953064654022455,
      -0.07239075750112534,
      0.030008656904101372,
      0.009991993196308613,
      -0.012576611712574959,
      0.033704973757267,
      -0.04876334220170975,
      0.039363011717796326,
      -0.03538992255926132,
      -0.026380548253655434,
      -0.047007955610752106,
      -0.0008286506636068225,
      0.05605344846844673,
      0.01146999467164278,
      -0.004538255278021097,
      -0.0018115550046786666,
      0.014261225238442421,
      -0.09254033863544464,
      0.017589302733540535,
      -0.06300288438796997,
      0.08254089206457138,
      -0.03661315143108368,
      0.006336828228086233,
      0.04240090027451515,
      0.05057907849550247,
      0.05549205094575882,
      0.008330685086548328,
      0.03319549560546875,
      0.08022574335336685,
      -0.04998963326215744,
      -0.031638775020837784,
      0.023000119253993034,
      -0.09112121909856796,
      0.05031484365463257,
      -0.015680579468607903,
      0.11142657697200775,
      0.04417983442544937,
      -0.08977263420820236,
      -0.02680625580251217,
      0.025377267971634865,
      0.027169950306415558,
      -0.006124925334006548,
      0.028723247349262238,
      0.015253961086273193,
      -0.012703855521976948,
      -0.04318828135728836,
      0.10105929523706436,
      -0.03744253143668175,
      -0.10519567877054214,
      -0.08460631221532822,
      -0.045722924172878265,
      0.06132588908076286,
      -0.005307449959218502,
      0.0120783019810915,
      0.014652950689196587,
      -3.337707312311977e-05,
      -0.03959128260612488,
      -0.047982603311538696,
      -0.00447881082072854,
      0.018803153187036514,
      0.006071889773011208,
      0.07217031717300415,
      -0.05941198766231537,
      0.051122087985277176,
      0.051929961889982224,
      0.015660107135772705,
      -0.020310303196310997,
      -0.03482583165168762,
      0.07732466608285904,
      0.0062134540639817715,
      0.05024451017379761,
      0.04070601612329483,
      0.048071086406707764,
      0.0299199428409338,
      -0.013019141741096973,
      -0.046577807515859604,
      -0.033305201679468155,
      0.013235569931566715,
      0.027142299339175224,
      0.002990245819091797,
      0.017352335155010223,
      -0.07871183753013611,
      -0.04589497670531273,
      -0.037449780851602554,
      -0.01933283917605877,
      -0.031480394303798676,
      0.006150616332888603,
      -0.0671381950378418,
      -0.004393353592604399,
      -0.07312857359647751,
      -0.001448212657123804,
      0.06337907910346985,
      0.018984926864504814,
      0.0068894559517502785,
      0.012056799605488777,
      -0.0036955021787434816,
      -0.07467098534107208,
      -0.045940037816762924,
      -0.04575465992093086,
      0.06606286019086838,
      -0.06430360674858093,
      -0.10627002269029617,
      0.05148894339799881,
      -0.0032244243193417788,
      0.005759994499385357,
      -0.00018350275058764964,
      0.02569606713950634,
      0.07403937727212906,
      -0.010745651088654995,
      0.06909654289484024,
      0.00011801407526945695,
      -0.014023023657500744,
      0.03214918449521065,
      -0.05512285977602005,
      0.017343081533908844,
      0.023829560726881027,
      -0.07933119684457779,
      -0.016689607873558998,
      -0.06838085502386093,
      -0.0707363411784172,
      0.06550393998622894,
      -0.007312024012207985,
      -0.010558166541159153,
      0.027955392375588417,
      -0.05648734048008919,
      -0.06806684285402298,
      -0.02948497235774994,
      -0.03970813751220703,
      0.004718915093690157,
      0.035733167082071304,
      0.07158700376749039,
      -0.020282989367842674,
      0.03145943582057953,
      0.0089260870590806,
      0.08678117394447327,
      0.045909684151411057,
      -0.06851167231798172,
      -0.00902620516717434,
      0.005452415440231562,
      -0.02339457906782627,
      -0.0004984533879905939,
      -0.06616943329572678,
      0.08156935125589371,
      0.031407952308654785,
      0.00395573116838932,
      0.0620637983083725,
      -0.11022067815065384,
      0.0074543519876897335,
      -0.005075137130916119,
      0.026230281218886375,
      -0.04589993134140968,
      -0.0018160580657422543,
      -0.039718449115753174,
      -0.007699360605329275,
      0.018486306071281433,
      -0.0028277721721678972,
      -0.05492126941680908,
      -0.04003534093499184,
      -0.009972487576305866,
      -0.05536939948797226,
      -0.04089963436126709,
      0.06434135138988495,
      -0.055415019392967224,
      -0.026835424825549126,
      -0.0010810024105012417,
      -0.013932934030890465,
      0.03853335976600647,
      0.032361991703510284,
      0.02850368618965149,
      -0.011700768023729324,
      -0.009384576231241226,
      0.011085127480328083,
      0.0228736512362957,
      -0.028674133121967316,
      0.0013100376818329096,
      0.013589390553534031,
      0.06903848797082901,
      -0.02349039725959301,
      0.010883187875151634,
      0.004046555608510971,
      -0.012519141659140587,
      -0.03250721096992493,
      -0.035263944417238235,
      -0.037270814180374146,
      -0.020220624282956123,
      0.05490792170166969,
      0.0065278587862849236,
      0.044578004628419876,
      -0.07232309877872467,
      0.011015783064067364,
      -0.021103588864207268,
      -0.07002873718738556,
      0.04850604757666588,
      -0.03020346909761429,
      -0.03551085293292999,
      0.04642514884471893,
      0.056608472019433975,
      0.00700142839923501,
      0.026358608156442642,
      -0.06566378474235535,
      -0.020594777539372444,
      -0.00024344862322323024,
      -0.02432474121451378,
      0.04035385325551033,
      0.005647375248372555,
      -0.010267895646393299,
      0.07914413511753082,
      -0.09080130606889725,
      0.007664454635232687,
      -0.10832929611206055,
      0.0617888867855072,
      0.07202336192131042,
      -0.04485723748803139,
      0.05298867076635361,
      -0.051008440554142,
      -0.023942647501826286,
      -0.08099239319562912,
      0.03821286931633949,
      0.028868770226836205,
      0.025850029662251472,
      -0.026512736454606056,
      -0.05253161862492561,
      -0.04936126247048378,
      -0.020515330135822296,
      -0.0061704241670668125,
      -0.000857727078255266,
      -0.013678297400474548,
      0.004422886297106743,
      0.030881835147738457,
      -0.013418076559901237,
      0.020785456523299217,
      0.022355206310749054,
      -0.0058376844972372055,
      -0.026383301243185997,
      0.04884243756532669,
      0.03864634782075882,
      -0.07120651751756668,
      -0.010652081109583378,
      -0.0396924689412117,
      -0.005520070903003216,
      -0.04163111001253128,
      0.044301122426986694,
      0.012962738052010536,
      0.05155389383435249,
      -0.05978691577911377,
      -0.05794091895222664,
      0.04768751189112663,
      0.01893879659473896,
      0.08362364023923874,
      -0.07858753204345703,
      -0.002864215523004532,
      -0.012762827798724174,
      -0.00036997441202402115,
      0.0033247414976358414
    ],
    [
      0.002658375073224306,
      -0.04024447128176689,
      -0.05871518328785896,
      -0.025453265756368637,
      -0.009492501616477966,
      0.01283292006701231,
      0.08316586911678314,
      0.060481321066617966,
      0.043605461716651917,
      -0.02565683238208294,
      -0.048216674476861954,
      0.07708540558815002,
      0.07249350845813751,
      -0.05208495631814003,
      -0.05835026875138283,
      0.029724271968007088,
      0.05239216238260269,
      0.004370538517832756,
      -0.06795063614845276,
      -0.04300578683614731,
      0.05794186145067215,
      -0.020831556990742683,
      -0.048338282853364944,
      -0.019124045968055725,
      0.05082001909613609,
      0.0004891410353593528,
      -0.05733554810285568,
      -0.07438944280147552,
      0.03842867538332939,
      -0.01787334866821766,
      0.0761764794588089,
      0.02028638869524002,
      0.038717519491910934,
      -0.035392045974731445,
      -0.025145038962364197,
      0.029846331104636192,
      0.015072407200932503,
      0.04577383026480675,
      0.036403436213731766,
      0.05492164567112923,
      -0.006764475721865892,
      -0.018099991604685783,
      -0.07524411380290985,
      0.043646425008773804,
      -0.07371094077825546,
      -0.018836364150047302,
      -0.04861992225050926,
      0.02784625254571438,
      -0.007684515323489904,
      0.00362299638800323,
      0.01910964399576187,
      0.005173077341169119,
      0.066661536693573,
      0.08765092492103577,
      -0.008420251309871674,
      -0.03157128766179085,
      -0.024069078266620636,
      -0.019611753523349762,
      0.03448661416769028,
      0.022578654810786247,
      -0.027008969336748123,
      -0.05265900120139122,
      0.011235854588449001,
      -0.038803957402706146,
      0.0159896407276392,
      -0.04274730756878853,
      0.044366102665662766,
      0.02400972694158554,
      -0.02900594472885132,
      0.04823882877826691,
      0.03776572272181511,
      -0.008757075294852257,
      0.09709955006837845,
      0.06330464780330658,
      -0.033119238913059235,
      0.016132116317749023,
      0.07229077070951462,
      -0.019799059256911278,
      0.01058021280914545,
      -0.03725355491042137,
      0.01308595109730959,
      0.027611197903752327,
      0.03475426137447357,
      -0.04629678651690483,
      0.028966354206204414,
      0.013314909301698208,
      0.0028033473063260317,
      -0.03296564891934395,
      -0.03163092955946922,
      0.046591777354478836,
      -0.03937804698944092,
      0.08802961558103561,
      0.03307468816637993,
      -0.011734508909285069,
      -0.07889467477798462,
      -0.01209195051342249,
      -0.08826499432325363,
      -0.02072611078619957,
      0.0627862885594368,
      -0.12257745862007141,
      -0.033660922199487686,
      0.020231211557984352,
      0.07776451855897903,
      -0.011412372812628746,
      -0.003080641385167837,
      0.05624080076813698,
      0.06827334314584732,
      -0.0005526798777282238,
      -0.03134822100400925,
      -0.09008440375328064,
      0.014926771633327007,
      -0.05924750491976738,
      0.03218644857406616,
      0.05147251859307289,
      0.009059682488441467,
      -0.0375141017138958,
      -0.027775343507528305,
      0.03686540573835373,
      0.06142580881714821,
      -0.028020726516842842,
      -0.0013942825607955456,
      0.01452045701444149,
      0.023958299309015274,
      -0.047015588730573654,
      0.02426016889512539,
      0.12399685382843018,
      0.023266008123755455,
      -0.034220099449157715,
      0.02112814225256443,
      0.09813182801008224,
      -0.01964992843568325,
      -0.005466053262352943,
      -0.04634120315313339,
      0.154332235455513,
      -0.01883802004158497,
      0.009044602513313293,
      0.05791277065873146,
      -0.02441895380616188,
      -0.10800429433584213,
      -0.06275469064712524,
      0.0005375533946789801,
      0.02861628495156765,
      -0.0038999291136860847,
      0.019405465573072433,
      -0.04661249369382858,
      0.058544259518384933,
      0.0348426029086113,
      -0.030257252976298332,
      0.01218485739082098,
      0.06799432635307312,
      -0.07282489538192749,
      0.055288881063461304,
      0.03566920757293701,
      0.07077979296445847,
      -0.0086548812687397,
      0.058821264654397964,
      -0.028063837438821793,
      -0.009409690275788307,
      0.07060868293046951,
      0.07597775012254715,
      -0.043740060180425644,
      0.01754925400018692,
      0.008469371125102043,
      0.0062211849726736546,
      0.12735526263713837,
      -0.010121159255504608,
      -0.10206475108861923,
      0.02895655669271946,
      -0.006980177015066147,
      0.011218912899494171,
      -0.04400603473186493,
      -0.0631338581442833,
      0.04368545860052109,
      -0.012384778819978237,
      -0.018796078860759735,
      -0.02926603890955448,
      0.09384535253047943,
      -0.04403815418481827,
      0.042711541056632996,
      0.023487085476517677,
      -0.03772875294089317,
      -0.017362894490361214,
      -0.014612843282520771,
      -0.015321612358093262,
      0.055906422436237335,
      0.026088308542966843,
      0.037141453474760056,
      -0.0602075457572937,
      0.031714946031570435,
      0.0065958392806351185,
      -0.0349893793463707,
      0.02509317360818386,
      0.04553283751010895,
      -0.0004658332618419081,
      -0.02850029617547989,
      -0.037529684603214264,
      0.05602933466434479,
      0.028900714591145515,
      -0.003752964548766613,
      0.032454244792461395,
      0.054109085351228714,
      0.014101721346378326,
      -0.008123248815536499,
      0.04628000035881996,
      -0.028905685991048813,
      -0.08906934410333633,
      0.039171986281871796,
      0.016841275617480278,
      0.07764659076929092,
      -0.025555964559316635,
      0.08485376089811325,
      -0.010351012460887432,
      -0.03996532782912254,
      -0.08570316433906555,
      0.013514557853341103,
      0.014140870422124863,
      0.010783404111862183,
      0.06021624803543091,
      -0.04933382570743561,
      0.03122463822364807,
      -0.03405828773975372,
      -0.012341772206127644,
      0.020947890356183052,
      0.02132689207792282,
      0.031769800931215286,
      -0.012275760993361473,
      0.011774484999477863,
      -0.028865838423371315,
      0.04941827058792114,
      -0.05677753686904907,
      -0.0050162216648459435,
      0.03898247703909874,
      -0.002640022663399577,
      0.08960714936256409,
      0.02534647099673748,
      -0.0571901835501194,
      -0.019798578694462776,
      -0.007849125191569328,
      -0.02168983593583107,
      0.0021098048891872168,
      -0.016052182763814926,
      0.017940277233719826,
      -0.045303765684366226,
      -0.011023415252566338,
      0.042630232870578766,
      -0.055414386093616486,
      -0.07150691002607346,
      -0.008107778616249561,
      -0.00904405489563942,
      0.044720232486724854,
      -0.07002417743206024,
      0.021387875080108643,
      0.051900215446949005,
      -0.03534765541553497,
      0.11860354244709015,
      0.005968419834971428,
      0.016735194250941277,
      0.009180908091366291,
      0.03402954339981079,
      -0.07319847494363785,
      -0.06691332906484604,
      0.020503010600805283,
      0.055237580090761185,
      -0.0401863157749176,
      -0.0035348059609532356,
      -0.0031278410460799932,
      0.042567234486341476,
      0.04293227940797806,
      0.0049920291639864445,
      -0.08192246407270432,
      0.07593239098787308,
      0.0033519479911774397,
      0.07060768455266953,
      -0.024991750717163086,
      -0.04048774763941765,
      0.024205561727285385,
      0.07904592156410217,
      0.0023321928456425667,
      0.0421903170645237,
      0.03996284306049347,
      -0.020251872017979622,
      0.01981361024081707,
      0.022235313430428505,
      -0.031090425327420235,
      -0.02394956350326538,
      0.043112851679325104,
      0.08580923825502396,
      -0.020741475746035576,
      0.04485146701335907,
      -0.026633527129888535,
      -0.0020961889531463385,
      0.01315438374876976,
      0.023595120757818222,
      -0.025821741670370102,
      0.0020859604701399803,
      0.0010135299526154995,
      -0.044687796384096146,
      0.048793233931064606,
      -0.052097052335739136,
      -0.01958109624683857,
      0.008842258714139462,
      0.03409459814429283,
      0.006409402471035719,
      -0.07249689102172852,
      0.06157231330871582,
      -0.033648982644081116,
      0.044700056314468384,
      0.010751819238066673,
      -0.0027494870591908693,
      0.008701004087924957,
      0.0170251727104187,
      -0.004293220583349466,
      0.028291907161474228,
      0.004603771027177572,
      0.002015845850110054,
      0.018797559663653374,
      -0.011932946741580963,
      -0.019847003743052483,
      -0.05849536880850792,
      0.02122277207672596,
      -0.019821492955088615,
      0.014297197572886944,
      -0.067845918238163,
      0.041438233107328415,
      -0.035671718418598175,
      -0.07497064769268036,
      0.020957093685865402,
      -0.026256831362843513,
      -0.031726982444524765,
      0.009694446809589863,
      0.08715590834617615,
      -0.002079717116430402,
      -0.0797274261713028,
      0.05436721444129944,
      0.026696307584643364,
      -0.010201672092080116,
      0.06661231070756912,
      0.012251938693225384,
      0.003918805625289679,
      -0.07315080612897873,
      0.02441384457051754,
      0.003699453314766288,
      -0.07018806040287018,
      -0.03513166680932045,
      0.003696141531690955,
      -0.04715033620595932,
      -0.03212861716747284,
      -0.05365220084786415,
      0.07556036859750748,
      -0.02665693685412407,
      -0.009932204149663448,
      -0.05843689292669296,
      -0.06217094138264656,
      0.041153255850076675,
      -0.026220299303531647,
      0.08796515315771103,
      0.006532878614962101,
      0.051267098635435104,
      -0.02426151931285858,
      -0.005046049598604441,
      0.03496604040265083,
      0.03399928659200668,
      -0.03130853921175003,
      -0.010828660801053047,
      -0.004661787301301956,
      -0.04685068503022194,
      -0.01079381350427866,
      -0.11979053169488907,
      -0.005987364798784256,
      0.11089111864566803,
      -0.008203347213566303,
      -0.04896371811628342,
      0.023920059204101562,
      -0.06201937422156334,
      -0.04907979071140289,
      -0.04293582960963249,
      -0.022128699347376823,
      0.019072769209742546,
      0.004139453172683716,
      -0.011000239290297031,
      0.003947763703763485,
      -0.018151063472032547,
      0.0784948468208313,
      -0.04105818644165993,
      -0.0032359492033720016,
      -0.022731821984052658,
      -0.018201686441898346,
      0.05249422416090965,
      -0.04739503934979439,
      0.022729959338903427,
      0.10094121843576431,
      0.02533961832523346,
      -0.12211614102125168,
      0.007506536319851875,
      0.05478128045797348,
      -0.06417915970087051,
      -0.031266044825315475,
      0.02846723049879074,
      -0.0239939633756876,
      -0.018229303881525993,
      0.010838175192475319,
      0.06209072843194008,
      0.026427321135997772,
      0.014495059847831726,
      0.03618033975362778,
      0.0027135582640767097,
      -0.059057481586933136,
      -0.09364575147628784,
      -0.07920368015766144,
      -0.051809366792440414,
      0.06990056484937668,
      -0.021181149408221245,
      0.004962183069437742,
      -0.018126126378774643,
      -0.0003664940595626831,
      0.04973548650741577,
      0.010989192873239517,
      -0.09848818182945251,
      -0.0765950009226799,
      -0.03623161464929581,
      -0.03126543387770653,
      0.02225733920931816,
      0.0154836131259799,
      0.011560318060219288,
      0.0023797384928911924,
      -0.013571493327617645,
      -0.03586876019835472,
      -0.029888566583395004,
      0.0038953491020947695,
      -0.03690437972545624,
      -0.06362263113260269,
      0.029450513422489166,
      -0.004403499886393547,
      -0.05264138802886009,
      0.009557757526636124,
      -0.10025443881750107,
      0.015546084381639957,
      0.038351260125637054,
      -0.03339272737503052,
      -0.04634324833750725,
      -0.03321996331214905,
      -0.00503222830593586,
      -0.09187699854373932,
      -0.01207816507667303,
      -0.03618088737130165,
      0.02919032797217369,
      -0.10476931929588318,
      0.04601326957345009,
      0.010135874152183533,
      0.06450505554676056,
      -0.0272838044911623,
      0.025984540581703186,
      0.03185950964689255,
      -0.008529329672455788,
      0.08658305555582047,
      0.019196322187781334,
      0.07233249396085739,
      0.004514093045145273,
      0.039275556802749634,
      -0.0009128988021984696,
      -0.018042169511318207,
      -0.03144320845603943,
      0.013213804922997952,
      -0.022870223969221115,
      0.07977829873561859,
      0.006168368738144636,
      -0.03818569704890251,
      -0.04191375896334648,
      0.04905761405825615,
      0.006028866395354271,
      0.008986697532236576,
      0.032341841608285904,
      -0.015677446499466896,
      -0.046005573123693466,
      -0.007218558806926012,
      0.01788553036749363,
      -0.010824873112142086,
      0.03873112425208092,
      -0.07819285988807678,
      0.06120213866233826,
      -0.006277993321418762,
      0.07140500843524933,
      -0.04799910634756088,
      0.03540975973010063,
      0.032947491854429245,
      -0.026367662474513054,
      0.0012539755553007126,
      -0.03465679660439491,
      -0.025035180151462555,
      0.0959462821483612,
      0.01661056838929653,
      0.005616011563688517,
      -0.015741324052214622,
      -0.08925631642341614,
      -0.01788182556629181,
      0.03434398025274277,
      -0.013246350921690464,
      -0.01880384422838688,
      0.08693057298660278,
      -0.03606286272406578,
      -0.05511772632598877,
      -0.07558158785104752,
      -0.054198142141103745
    ],
    [
      0.06880712509155273,
      -0.0541609488427639,
      0.01981428451836109,
      0.0022937199100852013,
      0.030299561098217964,
      0.042581893503665924,
      0.09489061683416367,
      -0.0226473156362772,
      0.023015201091766357,
      -0.062298037111759186,
      -0.04235178604722023,
      -0.010354184545576572,
      0.022982507944107056,
      -0.039136387407779694,
      -0.031060270965099335,
      -0.04296467453241348,
      -0.041986629366874695,
      0.08618776500225067,
      0.02683396451175213,
      0.007922395132482052,
      0.05432755872607231,
      -0.06511828303337097,
      0.009475784376263618,
      -0.05662001669406891,
      0.034812647849321365,
      -0.06730848550796509,
      -0.06443363428115845,
      -0.1280997097492218,
      -0.027467230334877968,
      0.006557025481015444,
      0.021176164969801903,
      -0.017883703112602234,
      0.007551473565399647,
      -0.08224339038133621,
      0.036125775426626205,
      0.003813787130638957,
      0.13380186259746552,
      0.09997551143169403,
      -0.0680592879652977,
      0.061427805572748184,
      -0.029904795810580254,
      -0.042400017380714417,
      0.08511337637901306,
      0.03210652992129326,
      -0.0891418382525444,
      0.07189614325761795,
      -0.024660171940922737,
      0.01709042489528656,
      -0.07710269093513489,
      0.029323825612664223,
      0.06723223626613617,
      0.040486425161361694,
      0.02820095606148243,
      0.07570100575685501,
      -0.1076606959104538,
      -0.0010334921535104513,
      -0.021005230024456978,
      -0.04292210191488266,
      -0.005739926360547543,
      -0.016491763293743134,
      0.007883316837251186,
      0.0027077498380094767,
      0.05705716088414192,
      0.011126204393804073,
      -0.03557552024722099,
      -0.010257509537041187,
      -0.03390754386782646,
      0.07826768606901169,
      0.010782350786030293,
      0.015568390488624573,
      -0.016794847324490547,
      0.0032019244972616434,
      -0.019763978198170662,
      -0.004949543625116348,
      0.08807424455881119,
      0.03681306913495064,
      -0.021562863141298294,
      -0.00303331739269197,
      -0.025544673204421997,
      0.01350520271807909,
      0.04213019832968712,
      0.03223104402422905,
      0.00651415903121233,
      0.06056910753250122,
      0.02140595018863678,
      -0.03406218811869621,
      0.06609580665826797,
      0.0925193578004837,
      -0.016120797023177147,
      0.03712880611419678,
      0.012944730930030346,
      0.01698446087539196,
      -0.008641025982797146,
      0.0017700602766126394,
      0.010394041426479816,
      -0.07964777201414108,
      -0.0554448701441288,
      0.022323956713080406,
      -0.016558311879634857,
      0.04215644672513008,
      -0.0022009615786373615,
      0.03855172172188759,
      -0.006713080685585737,
      0.0063833738677203655,
      0.06966164708137512,
      -0.09332893043756485,
      -0.018497657030820847,
      0.06395141780376434,
      -0.05779241770505905,
      0.0345948226749897,
      -0.06532935798168182,
      -0.049711793661117554,
      0.019657431170344353,
      -0.02212955430150032,
      0.04978153854608536,
      0.000266630930127576,
      -0.006861619185656309,
      -0.07654422521591187,
      -0.03218621015548706,
      -0.020328713580965996,
      -0.0635940209031105,
      -0.0022852891124784946,
      -0.07007754594087601,
      -0.03951650112867355,
      0.055730581283569336,
      0.051583439111709595,
      0.00020782057254109532,
      0.011749893426895142,
      -0.019529355689883232,
      0.045594941824674606,
      -0.006772567052394152,
      0.007429520133882761,
      -0.04260040074586868,
      -0.024868302047252655,
      0.010263172909617424,
      -0.026458395645022392,
      -0.060200463980436325,
      -0.03144140541553497,
      0.02689901366829872,
      -0.004828938748687506,
      0.12698020040988922,
      -0.037966083735227585,
      -0.023334261029958725,
      -0.010830034501850605,
      0.019023915752768517,
      -0.02699652872979641,
      -0.03010919876396656,
      -0.04311348870396614,
      0.0925881564617157,
      -0.04074663296341896,
      -0.019773835316300392,
      -0.037165869027376175,
      0.014674236066639423,
      -0.03947845473885536,
      0.006566912867128849,
      0.03745539113879204,
      -0.01978900097310543,
      -0.0646473690867424,
      -0.006125716492533684,
      -0.10237714648246765,
      0.10160424560308456,
      -0.08755675703287125,
      0.02670571394264698,
      0.037980664521455765,
      -0.042009253054857254,
      -0.05346457287669182,
      -0.039184898138046265,
      0.010768134146928787,
      0.012043881230056286,
      0.03276488557457924,
      0.0352388434112072,
      -0.00789500493556261,
      0.006428226828575134,
      0.023556523025035858,
      0.04145478829741478,
      -0.10155849158763885,
      -0.01597902737557888,
      -0.03681383654475212,
      0.018264874815940857,
      0.024006683379411697,
      -0.02128072828054428,
      0.006703807972371578,
      -0.023608427494764328,
      -0.006396008655428886,
      -0.0030287595000118017,
      0.04396963492035866,
      0.0765928402543068,
      0.06189895048737526,
      -0.008148943074047565,
      -0.029894066974520683,
      -0.011310381814837456,
      -0.05366674065589905,
      -0.07720852643251419,
      -0.012192659080028534,
      -0.03539055585861206,
      0.00017165218014270067,
      0.05866555497050285,
      0.012237176299095154,
      -0.023014603182673454,
      -0.02851513773202896,
      -0.10721973329782486,
      -0.020152926445007324,
      0.000508745783008635,
      0.05160362645983696,
      0.02938012406229973,
      0.024738803505897522,
      -0.023160263895988464,
      0.06940066814422607,
      -0.006688592489808798,
      -0.019956769421696663,
      0.02132538892328739,
      0.0020141275599598885,
      -0.06954338401556015,
      -0.045955363661050797,
      0.04143422469496727,
      -0.05232970416545868,
      -0.08604320883750916,
      -0.0952465832233429,
      0.03318960592150688,
      0.032256416976451874,
      0.08187791705131531,
      0.011807740665972233,
      0.057100556790828705,
      -0.001535311108455062,
      0.052041660994291306,
      -0.0037391968071460724,
      -0.02873927727341652,
      0.03453799709677696,
      0.08106835186481476,
      0.02996189147233963,
      -0.033222801983356476,
      0.01702157035470009,
      -0.0447145514190197,
      -0.004843264352530241,
      -0.03244347870349884,
      -0.020936395972967148,
      0.005231135059148073,
      0.027355313301086426,
      -0.05821437016129494,
      0.04154138267040253,
      -5.945322118350305e-05,
      0.04005154222249985,
      0.009209055453538895,
      -0.07596725970506668,
      0.05655409023165703,
      -0.026232335716485977,
      0.062474869191646576,
      -0.04483997821807861,
      0.002541176974773407,
      -0.0532970130443573,
      0.0007000936311669648,
      -0.019292954355478287,
      -0.08085796236991882,
      0.048548806458711624,
      0.017413686960935593,
      0.026966726407408714,
      0.09016693383455276,
      -0.12793026864528656,
      0.017669718712568283,
      -0.06666725128889084,
      -0.007603077683597803,
      0.056808579713106155,
      0.02311723306775093,
      -0.035566672682762146,
      0.01441812701523304,
      -0.019136149436235428,
      0.002604430541396141,
      0.022829396650195122,
      -0.051104720681905746,
      0.0015201186761260033,
      0.00888706836849451,
      -0.0036007659509778023,
      0.06315238773822784,
      -0.10331544280052185,
      -0.007856642827391624,
      -0.015023933723568916,
      0.026488494127988815,
      -0.01878281682729721,
      -0.0053650988265872,
      -0.01420800294727087,
      0.006259122863411903,
      -0.04584920406341553,
      -0.08433130383491516,
      0.054263461381196976,
      -0.06674914062023163,
      0.00973182450979948,
      0.04304860904812813,
      -0.000935970398131758,
      -0.0004197254602331668,
      0.05990084260702133,
      0.022380661219358444,
      0.011740634217858315,
      0.08111414313316345,
      0.0015115798451006413,
      0.03621258586645126,
      0.09805916249752045,
      0.02730671875178814,
      0.022343510761857033,
      -0.000216374930460006,
      -0.01722676493227482,
      0.04064038023352623,
      0.03323791176080704,
      0.050952669233083725,
      0.0038413868751376867,
      0.04199326038360596,
      -0.11252546310424805,
      -0.04228230193257332,
      -0.04657799378037453,
      -0.03473268076777458,
      -0.03801882639527321,
      0.013646011240780354,
      0.08058664202690125,
      0.048756688833236694,
      -0.069670669734478,
      0.08917678147554398,
      0.011629240587353706,
      0.06501513719558716,
      0.08535309135913849,
      -0.01696428470313549,
      0.061491064727306366,
      -0.05532880127429962,
      -0.003097627777606249,
      0.01889270544052124,
      0.006462556775659323,
      0.03379049152135849,
      -0.010540594346821308,
      0.005290586035698652,
      0.029343562200665474,
      -0.05955793336033821,
      0.04437881335616112,
      0.048881642520427704,
      -0.07122935354709625,
      0.007659778464585543,
      -0.06451056897640228,
      0.010941826738417149,
      0.012869666330516338,
      0.012715551070868969,
      0.00938694179058075,
      -0.017803333699703217,
      0.0075516472570598125,
      0.02462927997112274,
      0.04596163332462311,
      0.06294780969619751,
      0.01101308036595583,
      -0.10390836000442505,
      0.0486067570745945,
      0.04223160073161125,
      -0.01248109806329012,
      0.04067561775445938,
      0.015203473158180714,
      -0.13082289695739746,
      -0.02609088085591793,
      -0.03376725688576698,
      -0.03552618250250816,
      -0.033166345208883286,
      -0.011821545660495758,
      0.014814132824540138,
      -0.03123219683766365,
      0.05927063152194023,
      -0.016322579234838486,
      -0.024809658527374268,
      0.025225628167390823,
      -0.0008549001649953425,
      0.013087544590234756,
      0.0416632816195488,
      0.011948719620704651,
      0.027368325740098953,
      0.08996385335922241,
      0.09639076143503189,
      -0.06063247099518776,
      -0.009553483687341213,
      0.10067170113325119,
      0.10225780308246613,
      0.0343545600771904,
      -0.033857014030218124,
      -0.03169141709804535,
      0.03970462456345558,
      0.0473756268620491,
      0.008800978772342205,
      0.1307072639465332,
      -0.049435026943683624,
      0.017184128984808922,
      -0.05195825546979904,
      -0.04311290755867958,
      -0.030397597700357437,
      0.04824422299861908,
      -0.006493487861007452,
      -0.07138165086507797,
      0.10148131102323532,
      0.0624602735042572,
      -0.008111819624900818,
      0.05804526433348656,
      -0.07638916373252869,
      -0.045628488063812256,
      0.011137712746858597,
      -0.0005670356331393123,
      0.010425172746181488,
      -0.02296205423772335,
      0.01612367480993271,
      -0.09369468688964844,
      -0.026550378650426865,
      0.0049912636168301105,
      0.053416475653648376,
      -0.08737868815660477,
      -0.05765692517161369,
      -0.03329872339963913,
      0.06932327896356583,
      0.0067937918938696384,
      -0.09648071229457855,
      0.1072680801153183,
      -0.07553622126579285,
      0.017093755304813385,
      -0.03408621624112129,
      0.0018336097709834576,
      0.021248022094368935,
      0.033290352672338486,
      -0.03136330470442772,
      -0.024478960782289505,
      0.042712852358818054,
      -0.002524674404412508,
      -0.09994910657405853,
      0.027802344411611557,
      0.018055731430649757,
      -0.04946427419781685,
      -0.02820800244808197,
      -0.03824610263109207,
      0.011011880822479725,
      -0.00470018433406949,
      -0.015016725286841393,
      0.11410749703645706,
      -0.025215156376361847,
      -0.0058641983196139336,
      0.013332903385162354,
      -0.06556744128465652,
      -0.08492527902126312,
      -0.11886068433523178,
      0.005850065965205431,
      0.019539419561624527,
      -0.0413634367287159,
      -0.029919497668743134,
      0.008305666036903858,
      -0.054489556699991226,
      0.02174895443022251,
      -0.040094297379255295,
      -0.00026106328004971147,
      0.0730748325586319,
      0.04139714315533638,
      0.04522984102368355,
      -0.016793547198176384,
      -0.08170165121555328,
      0.01767892576754093,
      0.02060442417860031,
      -0.021138694137334824,
      -0.07147616147994995,
      0.03934682533144951,
      -0.022449664771556854,
      0.010173247195780277,
      -0.003727708011865616,
      0.003918852191418409,
      -0.03416840359568596,
      -0.0466606579720974,
      -0.01954364776611328,
      0.12755784392356873,
      -0.021645672619342804,
      -0.046271078288555145,
      0.011468767188489437,
      0.054173052310943604,
      0.11456850171089172,
      0.011876305565237999,
      -0.05531267449259758,
      0.060906652361154556,
      0.034985899925231934,
      -0.004614413250237703,
      -0.09183741360902786,
      0.027786655351519585,
      -0.007346899714320898,
      -0.015046708285808563,
      -0.015387495048344135,
      -0.058711785823106766,
      0.02552216686308384,
      0.02867456153035164,
      -0.11324068903923035,
      -0.005116840824484825,
      -0.12134385108947754,
      0.03934251889586449,
      0.06188444793224335,
      0.011918982490897179,
      -0.05858854576945305,
      -0.02495441772043705,
      0.041715435683727264,
      0.04031487926840782,
      -0.06026420742273331,
      0.019846472889184952,
      0.071983203291893,
      -0.05596297234296799,
      0.05453982576727867,
      -0.0073755281046032906,
      0.013273101300001144,
      0.020720485597848892,
      -0.010981506668031216,
      0.13845312595367432,
      0.01448758877813816,
      0.019462047144770622
    ],
    [
      0.03137419745326042,
      -0.00888856966048479,
      0.014396271668374538,
      0.05156126990914345,
      -0.019363125786185265,
      -0.02356518805027008,
      0.06512951105833054,
      0.020353276282548904,
      -0.01590457186102867,
      0.04680157080292702,
      -0.012108863331377506,
      0.035677846521139145,
      0.05966436490416527,
      0.044765766710042953,
      -0.005121678579598665,
      0.057562410831451416,
      0.05190052092075348,
      0.012213652022182941,
      0.05720745027065277,
      -0.05258260667324066,
      0.009185944683849812,
      0.005661579314619303,
      0.06106587126851082,
      0.03496928513050079,
      0.047760605812072754,
      -0.05269409716129303,
      0.007151748985052109,
      -0.026176653802394867,
      0.04189309850335121,
      0.01659163273870945,
      -0.032456185668706894,
      0.07949687540531158,
      0.05424276366829872,
      0.07533680647611618,
      -0.00434905244037509,
      0.0027808966115117073,
      -0.030499910935759544,
      -0.03758453205227852,
      0.004476144909858704,
      0.05097556486725807,
      -0.001500418409705162,
      0.05039631947875023,
      0.059946462512016296,
      -0.00033935351530089974,
      -0.0602203793823719,
      0.029315011575818062,
      0.06025352701544762,
      0.008600397035479546,
      0.04827570915222168,
      0.02187378704547882,
      -0.027800975367426872,
      0.0017053796909749508,
      -0.014158959500491619,
      0.011958392336964607,
      -0.01512798573821783,
      -0.010291345417499542,
      -0.018907198682427406,
      0.02460820972919464,
      -0.07777050137519836,
      -0.017318464815616608,
      -0.02642272412776947,
      -0.024315230548381805,
      0.0013384846970438957,
      -0.04225128889083862,
      0.04879084974527359,
      -0.01156570203602314,
      0.049753587692976,
      -0.09304364025592804,
      -0.035404857248067856,
      0.03167340159416199,
      -0.045961398631334305,
      -0.053878623992204666,
      -0.005834822077304125,
      -0.015959132462739944,
      -0.018134884536266327,
      0.06459677964448929,
      -0.002212617779150605,
      0.052377454936504364,
      0.06952716410160065,
      0.10031938552856445,
      0.02855359949171543,
      -0.008839904330670834,
      -0.03344227373600006,
      -0.08883742988109589,
      0.03715365380048752,
      -0.043684232980012894,
      -0.03956828638911247,
      -0.0072457510977983475,
      -0.027585508301854134,
      0.01393653079867363,
      -0.016306279227137566,
      0.03577551245689392,
      -0.021766768768429756,
      0.03896115720272064,
      0.04765813797712326,
      -0.03919657692313194,
      -0.04026726260781288,
      0.004724455066025257,
      0.026743192225694656,
      0.010998357087373734,
      0.04112830013036728,
      -0.05201255530118942,
      0.01770120859146118,
      0.05230576545000076,
      -0.03469636291265488,
      0.00561897037550807,
      -0.04393727332353592,
      0.026164162904024124,
      -0.0019246048759669065,
      -0.05286490544676781,
      -0.05424065515398979,
      0.04132187366485596,
      -0.011292636394500732,
      0.02593998610973358,
      -0.05174731835722923,
      -0.04617220163345337,
      0.08762984722852707,
      -0.03121863678097725,
      0.008437943644821644,
      0.05232367292046547,
      -0.0195680670440197,
      0.007119824178516865,
      0.019461994990706444,
      0.042257245630025864,
      -0.07240898162126541,
      -0.03930402547121048,
      -0.057163018733263016,
      -0.08683857321739197,
      0.0376596674323082,
      0.022145776078104973,
      0.013443507254123688,
      0.06010185182094574,
      0.009599159471690655,
      -0.02975081466138363,
      0.04872807487845421,
      0.026669511571526527,
      0.08021632581949234,
      -0.03388127684593201,
      -0.02198907732963562,
      -0.05350274592638016,
      -0.0034993025474250317,
      -0.003392468672245741,
      -0.060024138540029526,
      -0.053729236125946045,
      -0.03011619672179222,
      -0.06247619166970253,
      -0.05583751201629639,
      0.012967274524271488,
      0.10369037091732025,
      0.04540308564901352,
      0.04426907002925873,
      -0.07630536705255508,
      -0.017154494300484657,
      0.027569809928536415,
      -0.06028430908918381,
      -0.004329764284193516,
      0.005893418565392494,
      -0.03379272297024727,
      -0.012092136777937412,
      -0.045117687433958054,
      0.0021961575839668512,
      -0.0940234512090683,
      0.016117392107844353,
      -0.01658749207854271,
      -0.006902041845023632,
      -0.02099958062171936,
      0.07500052452087402,
      -0.009266034699976444,
      -0.009398702532052994,
      0.0037875857669860125,
      -0.010841467417776585,
      -0.009703841991722584,
      0.024749908596277237,
      0.03297200798988342,
      -0.008889849297702312,
      -0.07398729026317596,
      0.008602173998951912,
      0.057783033698797226,
      -0.029307695105671883,
      -0.00020009709987789392,
      -0.03823155164718628,
      0.028796395286917686,
      -0.06142742559313774,
      0.01996690407395363,
      0.01803014613687992,
      -0.025042498484253883,
      0.003262906800955534,
      0.043413639068603516,
      0.007290510926395655,
      0.05529148131608963,
      -0.06370042264461517,
      0.039114970713853836,
      -0.0717632845044136,
      0.010984682478010654,
      -0.04278599098324776,
      -0.026601115241646767,
      -0.06270093470811844,
      -0.02349874936044216,
      0.0387900210916996,
      0.03686559200286865,
      -0.09986303746700287,
      -0.02133394218981266,
      0.0329432375729084,
      -0.03228658437728882,
      0.022519122809171677,
      -0.016711823642253876,
      -0.009377972222864628,
      0.05463249981403351,
      0.014825836755335331,
      0.036788083612918854,
      -0.027846600860357285,
      0.03047475405037403,
      -0.049328796565532684,
      -0.022487949579954147,
      -0.001048894366249442,
      -0.03400646150112152,
      -0.0012661387445405126,
      0.057428259402513504,
      0.007392067462205887,
      -0.02060440182685852,
      -0.017350660637021065,
      0.025557860732078552,
      0.05929390341043472,
      -0.005833623465150595,
      -0.04274474456906319,
      -0.048719167709350586,
      0.00870169885456562,
      0.023610759526491165,
      -0.017665334045886993,
      0.056104086339473724,
      0.08467479050159454,
      -0.05758826434612274,
      0.010754144750535488,
      0.07121486216783524,
      -0.018816886469721794,
      0.03417329490184784,
      -0.0005718330503441393,
      0.033825673162937164,
      -0.012873051688075066,
      0.019059618934988976,
      -0.014116472564637661,
      0.01319966185837984,
      -0.05259791761636734,
      -0.0013453626306727529,
      0.018154224380850792,
      0.042974118143320084,
      -0.1414848119020462,
      0.03391873463988304,
      0.011426394805312157,
      0.045772574841976166,
      0.038476310670375824,
      -0.06833400577306747,
      -0.031071282923221588,
      0.0009059364092536271,
      -0.08240082859992981,
      0.027392331510782242,
      -0.04495811462402344,
      -0.04151780903339386,
      0.08308815211057663,
      0.042356882244348526,
      -0.01996830850839615,
      0.0053073749877512455,
      0.018306627869606018,
      -0.003204734530299902,
      -0.031859833747148514,
      -0.09715057909488678,
      -0.010605817660689354,
      -0.0031050376128405333,
      -0.0030507026240229607,
      0.003311881795525551,
      0.0909564346075058,
      0.09496043622493744,
      0.05473603680729866,
      0.00010493340960238129,
      -0.02412625215947628,
      -0.01836427114903927,
      0.050833724439144135,
      -0.038773760199546814,
      -0.0011991963256150484,
      -0.021352754905819893,
      0.013966381549835205,
      -0.04018056020140648,
      0.06886227428913116,
      -0.003872582223266363,
      0.0492420569062233,
      -0.0026856630574911833,
      0.02052653580904007,
      0.002226568991318345,
      -0.018450720235705376,
      -0.10706768184900284,
      -0.08603759855031967,
      -0.013273865915834904,
      0.0021206599194556475,
      0.07382135093212128,
      -0.008700906299054623,
      -0.017220988869667053,
      0.007051076274365187,
      -0.0056283376179635525,
      -0.009237609803676605,
      0.004960482940077782,
      0.010764595121145248,
      0.09313040971755981,
      -0.08321597427129745,
      -0.011060303077101707,
      0.020883575081825256,
      -0.008939969353377819,
      -0.03461243584752083,
      -0.01785126142203808,
      0.035687435418367386,
      -0.07275265455245972,
      0.019366668537259102,
      -0.0266259778290987,
      -0.006775958463549614,
      0.0014865874545648694,
      -0.012289916165173054,
      0.0364956334233284,
      0.008385608904063702,
      0.05041639506816864,
      0.08330328017473221,
      -0.08719542622566223,
      -0.01755998283624649,
      0.030542856082320213,
      0.010053266771137714,
      -0.022809680551290512,
      0.013145075179636478,
      -0.07262193411588669,
      0.009326823987066746,
      0.02382184937596321,
      0.015451263636350632,
      0.0023534006904810667,
      0.069549560546875,
      -0.019756216555833817,
      -0.0010394450509920716,
      -0.032654136419296265,
      -0.04163620248436928,
      0.0017317140009254217,
      -0.014969473704695702,
      -0.03707704693078995,
      0.027640553191304207,
      -0.055839575827121735,
      0.030388005077838898,
      -0.034382957965135574,
      -0.001216726959683001,
      -0.12391921132802963,
      0.04270319268107414,
      0.0390959233045578,
      -0.08551754802465439,
      0.07109825313091278,
      -0.02552078478038311,
      -0.0020526652224361897,
      0.002015454461798072,
      -0.006042684428393841,
      -0.06707026064395905,
      -0.01351332850754261,
      0.027709338814020157,
      -0.025631440803408623,
      -0.03281531482934952,
      -0.010456024669110775,
      0.0722334086894989,
      -0.019751450046896935,
      0.08708079159259796,
      0.05759338662028313,
      0.07193376868963242,
      0.04153046756982803,
      0.04166795313358307,
      0.02710713818669319,
      0.023012854158878326,
      -0.03261616453528404,
      -0.016254229471087456,
      0.05095886066555977,
      -0.011098167859017849,
      0.054790206253528595,
      -0.02268621139228344,
      0.014312030747532845,
      -0.03593656048178673,
      -0.022905554622411728,
      0.048037271946668625,
      -0.05008893832564354,
      0.15673168003559113,
      -0.07449384033679962,
      -0.01680215634405613,
      0.05471217632293701,
      0.004822985734790564,
      0.001580720767378807,
      -0.0038020629435777664,
      0.022094661369919777,
      -0.04015506058931351,
      -0.006342461798340082,
      -0.0501842275261879,
      0.050448816269636154,
      -0.1142166256904602,
      -0.011704660020768642,
      -0.01012573391199112,
      0.01612628437578678,
      0.01949317380785942,
      0.060306813567876816,
      -0.032519254833459854,
      0.009285365231335163,
      -0.013135028071701527,
      0.03412945196032524,
      -0.05263726785778999,
      0.035759445279836655,
      -0.09089656174182892,
      0.001948583172634244,
      0.026360154151916504,
      0.008414073847234249,
      -0.02715885080397129,
      0.03667384013533592,
      -0.0037862625904381275,
      0.03923435136675835,
      0.025380512699484825,
      0.007060606498271227,
      0.007977879606187344,
      0.05765126273036003,
      0.08496866375207901,
      -0.04830922186374664,
      0.005444700364023447,
      -0.0012969783274456859,
      0.003020112169906497,
      -0.054725129157304764,
      0.0032965412829071283,
      -0.007808092515915632,
      -0.005388841964304447,
      0.044223908334970474,
      -0.013967415317893028,
      0.01965045928955078,
      -0.01990656927227974,
      0.03866348788142204,
      0.035841699689626694,
      -0.024173591285943985,
      0.10839561372995377,
      -0.007390236482024193,
      -0.028968634083867073,
      -0.01979800872504711,
      -0.023240137845277786,
      -0.03322545066475868,
      -0.11277923732995987,
      0.06303906440734863,
      0.020799804478883743,
      0.08764670789241791,
      0.004881452769041061,
      -0.000891424308065325,
      -0.05119064077734947,
      0.04300781339406967,
      -0.06470464169979095,
      0.05906515568494797,
      0.05669647082686424,
      0.02421790175139904,
      -0.004454983863979578,
      -0.0841166079044342,
      -0.0332597978413105,
      -0.08246471732854843,
      0.04631870239973068,
      -0.10037694126367569,
      -0.028738006949424744,
      0.023929037153720856,
      0.004113868344575167,
      -0.0013354987604543567,
      -0.03587551787495613,
      -0.04868003726005554,
      0.03538241982460022,
      -0.019060617312788963,
      0.025089696049690247,
      0.06336649507284164,
      0.07472162693738937,
      -0.02068386599421501,
      0.04401297867298126,
      0.046975258737802505,
      0.0027946007903665304,
      0.01253786962479353,
      0.03787994384765625,
      0.0031991146970540285,
      0.04766038432717323,
      0.0829235389828682,
      0.03246810659766197,
      -0.0006069387309253216,
      0.03526322916150093,
      0.016502154991030693,
      0.027989134192466736,
      0.0501737967133522,
      -0.016202738508582115,
      0.014409812167286873,
      0.1128622442483902,
      0.07946078479290009,
      -0.023658595979213715,
      -0.06339004635810852,
      -0.008840000256896019,
      0.026981910690665245,
      -0.010273906402289867,
      -0.03991831839084625,
      -0.028061755001544952,
      0.006068735383450985,
      -0.02292638085782528,
      -0.06262801587581635,
      -0.0379146970808506,
      -0.02662038616836071,
      -0.027554349973797798,
      -0.001988121774047613,
      0.013896893709897995,
      -0.03850913420319557,
      -0.07361063361167908,
      -0.012346137315034866,
      0.06064600497484207
    ],
    [
      -0.023396801203489304,
      0.02602706104516983,
      0.018188366666436195,
      0.04630681499838829,
      -0.07505104690790176,
      -0.02089584432542324,
      -0.013555030338466167,
      0.05605240538716316,
      -0.05018632858991623,
      0.05133393406867981,
      0.07256622612476349,
      0.01769646629691124,
      0.0002356071927351877,
      0.011384116485714912,
      0.042184844613075256,
      0.06513931602239609,
      -0.08851005882024765,
      -0.1051207035779953,
      -0.00780630623921752,
      0.028249511495232582,
      0.029962662607431412,
      0.02477581799030304,
      0.03320879116654396,
      -0.017490681260824203,
      0.061296332627534866,
      0.05032072216272354,
      -0.031586967408657074,
      0.06271886080503464,
      0.019599702209234238,
      -0.010599501430988312,
      -0.027844445779919624,
      -0.013283050619065762,
      -0.01549042109400034,
      -0.016339527443051338,
      -0.013395896181464195,
      0.034493885934352875,
      -0.018421443179249763,
      0.04168076068162918,
      0.03385501727461815,
      0.040667012333869934,
      -0.0032512652687728405,
      -0.029459411278367043,
      0.014406540431082249,
      0.006264206022024155,
      0.02665662206709385,
      -0.01758897304534912,
      -0.04052983224391937,
      -0.018924908712506294,
      0.0025931361597031355,
      -0.03490188717842102,
      0.03381424397230148,
      0.02862834371626377,
      0.09898944944143295,
      0.07436911016702652,
      -0.002810481935739517,
      -0.07515160739421844,
      0.04503338783979416,
      -0.056355081498622894,
      0.03714945539832115,
      0.031766559928655624,
      0.05318199470639229,
      -0.045601699501276016,
      -0.05127473175525665,
      0.012723262421786785,
      0.022803544998168945,
      -0.06671381741762161,
      0.016032343730330467,
      0.027251070365309715,
      0.0048497398383915424,
      0.08079329133033752,
      -0.03339335322380066,
      0.039475686848163605,
      -0.04926813021302223,
      -0.0032321081962436438,
      0.030543724074959755,
      0.01547921635210514,
      -0.0116697633638978,
      -0.054788120090961456,
      0.00657914811745286,
      0.03305405005812645,
      0.043054837733507156,
      -0.02289924956858158,
      0.017002414911985397,
      -0.03547088801860809,
      -0.08797207474708557,
      -0.053769584745168686,
      0.040362369269132614,
      -0.02867780439555645,
      -0.05422920361161232,
      -0.013985419645905495,
      0.030121348798274994,
      0.07861415296792984,
      0.06831560283899307,
      0.0005200241575948894,
      0.039536189287900925,
      0.055294256657361984,
      0.04944194108247757,
      0.0353105403482914,
      0.06039450317621231,
      -0.06090710312128067,
      -0.041448820382356644,
      -0.037238359451293945,
      -0.03273175284266472,
      -0.08277704566717148,
      0.020541926845908165,
      0.02862665243446827,
      -0.08668338507413864,
      0.05252949148416519,
      0.010171614587306976,
      -0.0793248638510704,
      -0.03333120420575142,
      0.005871115252375603,
      -0.03213164210319519,
      0.041805729269981384,
      0.07962557673454285,
      -0.022660529240965843,
      -0.06467646360397339,
      -0.04259325936436653,
      -0.10669111460447311,
      -0.006073684431612492,
      -0.029929080978035927,
      -0.01402911078184843,
      -0.03872130811214447,
      -0.05727944150567055,
      -0.028398217633366585,
      -0.08613189309835434,
      -0.004038631450384855,
      0.04909999296069145,
      0.005704196635633707,
      0.08878771215677261,
      -0.019888270646333694,
      0.041498374193906784,
      0.07491055876016617,
      0.013778524473309517,
      0.014252213761210442,
      -0.031325262039899826,
      0.009740770794451237,
      0.012858780100941658,
      -0.03721940889954567,
      -0.018222227692604065,
      0.06449295580387115,
      -0.13290153443813324,
      -0.03560623154044151,
      -0.00921306200325489,
      0.008732674643397331,
      -0.05134642496705055,
      0.04173636436462402,
      -0.05663014575839043,
      0.08344325423240662,
      -0.040472060441970825,
      -0.07691216468811035,
      0.05065750703215599,
      0.0684187039732933,
      -0.05690844729542732,
      0.057450518012046814,
      0.06596413254737854,
      0.015392730012536049,
      -0.06888804584741592,
      0.010899967513978481,
      -0.02578144147992134,
      -0.03530997037887573,
      0.0768890231847763,
      -0.06021760776638985,
      0.03507889807224274,
      0.0006780268740840256,
      0.02376270666718483,
      0.06273061782121658,
      0.04541745036840439,
      0.02717393822968006,
      -0.05573434755206108,
      -0.03309601917862892,
      -0.03644144535064697,
      -0.050902485847473145,
      0.013021041639149189,
      0.051801975816488266,
      -0.08089186996221542,
      0.03878585249185562,
      0.007884456776082516,
      0.03858951851725578,
      0.010549884289503098,
      0.018560197204351425,
      0.02493935264647007,
      0.10342175513505936,
      -0.08862384408712387,
      -0.06751476228237152,
      -0.019809141755104065,
      0.01753772795200348,
      -0.027869218960404396,
      0.06345434486865997,
      -0.041036393493413925,
      -0.04270749166607857,
      -0.0038889178540557623,
      0.03837151825428009,
      0.011443535797297955,
      0.009912105277180672,
      0.014345926232635975,
      -0.05333148315548897,
      0.023431481793522835,
      0.01043874491006136,
      -0.0377192348241806,
      0.001310746418312192,
      -0.008109147660434246,
      -0.02781505510210991,
      -0.07318887859582901,
      -0.008348731324076653,
      -0.05010316148400307,
      -0.0013690796913579106,
      0.025336923077702522,
      -0.034921132028102875,
      0.04925765469670296,
      0.011490274220705032,
      0.01184186153113842,
      0.0032234026584774256,
      0.029972847551107407,
      0.037010375410318375,
      -0.14993494749069214,
      -0.04949282482266426,
      -0.025607164949178696,
      -0.010600889101624489,
      0.0509890578687191,
      0.008925397880375385,
      0.010008503682911396,
      0.0013108892599120736,
      -0.018690800294280052,
      -0.048192448914051056,
      -0.026695851236581802,
      0.054437872022390366,
      -0.05515159294009209,
      -0.05813736096024513,
      0.022782253101468086,
      -1.9850667740684003e-05,
      -0.022282347083091736,
      -0.04136499762535095,
      -0.12422057241201401,
      0.06254847347736359,
      -0.04979808256030083,
      -0.04670146107673645,
      0.015638258308172226,
      -0.026003709062933922,
      -0.02199852652847767,
      -0.011115549132227898,
      0.07282023131847382,
      -0.039688244462013245,
      -0.052460554987192154,
      0.10115644335746765,
      0.00014077895320951939,
      0.029411163181066513,
      -0.014453088864684105,
      0.034523118287324905,
      0.013648255728185177,
      0.03906219080090523,
      0.06138503924012184,
      0.03048645704984665,
      -0.07675358653068542,
      0.0752810686826706,
      0.06679662317037582,
      -0.07221352308988571,
      0.004381817299872637,
      0.01597025617957115,
      -0.029659774154424667,
      -0.0005951229250058532,
      0.057151343673467636,
      -0.010893058963119984,
      0.029810849577188492,
      -0.05334842577576637,
      0.021843144670128822,
      0.005932464264333248,
      0.04506944119930267,
      -0.003222463186830282,
      0.030940255150198936,
      0.06138173118233681,
      -0.011869569309055805,
      0.03381742909550667,
      -0.02541055902838707,
      0.03816775977611542,
      0.027833690866827965,
      -0.03330475091934204,
      -0.0548214316368103,
      0.00011393873865017667,
      0.11053622514009476,
      0.003937381785362959,
      0.030482329428195953,
      -0.03157025948166847,
      -0.03029661439359188,
      0.02809356153011322,
      0.052784599363803864,
      0.07463277131319046,
      -0.05047668144106865,
      -0.0289682075381279,
      0.029039548709988594,
      0.01009666733443737,
      0.017268599942326546,
      -0.01455260906368494,
      0.024188809096813202,
      0.018595846369862556,
      0.011896361596882343,
      -0.01778305321931839,
      -0.0008514670189470053,
      -0.046814143657684326,
      0.10085540264844894,
      0.011785990558564663,
      0.06959635019302368,
      0.0748683363199234,
      0.06963280588388443,
      0.01022352185100317,
      0.008754009380936623,
      0.014173401519656181,
      -0.021160747855901718,
      0.07040439546108246,
      0.007432957179844379,
      0.0018147077644243836,
      -0.043004244565963745,
      -0.02736636810004711,
      -0.029619092121720314,
      -0.04855519160628319,
      -0.012020785361528397,
      -0.007646438665688038,
      0.018915528431534767,
      0.0006860863650217652,
      0.010272307321429253,
      0.040141139179468155,
      -0.055554457008838654,
      0.07578182220458984,
      -0.01622009463608265,
      0.093421071767807,
      0.011501941829919815,
      -0.0342702642083168,
      0.026892121881246567,
      0.016027940437197685,
      0.016018768772482872,
      -0.06544799357652664,
      0.021247277036309242,
      0.06418879330158234,
      0.012666404247283936,
      0.04681451618671417,
      -0.06753907352685928,
      0.05227970704436302,
      -0.042866554111242294,
      -0.014019341208040714,
      -0.004305991344153881,
      0.0796949490904808,
      0.03684333339333534,
      0.0070530869998037815,
      0.031435638666152954,
      0.04456932097673416,
      0.021852705627679825,
      0.09338530898094177,
      -0.04926363378763199,
      0.07910169661045074,
      -0.05194879323244095,
      0.008313706144690514,
      0.014946125447750092,
      0.01177793089300394,
      -0.04114503040909767,
      -0.013813159428536892,
      0.015917059034109116,
      -0.008908453397452831,
      0.022532394155859947,
      -0.044678330421447754,
      0.04196905344724655,
      -0.0037926167715340853,
      -0.04693785309791565,
      0.07805352658033371,
      -0.03879469260573387,
      0.021422764286398888,
      -0.03043057769536972,
      -0.026708338409662247,
      0.054725512862205505,
      0.03465885668992996,
      0.031873103231191635,
      -0.062440939247608185,
      0.01688280887901783,
      0.05953941121697426,
      0.012060103006660938,
      -0.034205567091703415,
      -0.004531070590019226,
      -0.0809616819024086,
      0.02700330875813961,
      -0.04664360731840134,
      0.058354660868644714,
      -0.013018930330872536,
      0.04342053458094597,
      0.01588432490825653,
      0.01723892241716385,
      0.003749067895114422,
      0.01975468173623085,
      0.014963926747441292,
      -0.02455264888703823,
      -0.022852083668112755,
      0.05475340038537979,
      -0.0355863943696022,
      0.007286773528903723,
      -0.0017886142013594508,
      -0.06496551632881165,
      -0.009014591574668884,
      0.0614486001431942,
      -0.1064457893371582,
      0.03667105734348297,
      -0.02712603099644184,
      -0.036382731050252914,
      -0.03741046041250229,
      -0.05014248192310333,
      0.053691282868385315,
      0.043573047965765,
      0.0823616161942482,
      0.04428248479962349,
      -0.03463556244969368,
      0.004253449849784374,
      -0.05101500079035759,
      0.04115317016839981,
      -0.007312069647014141,
      0.005123265087604523,
      0.021151171997189522,
      0.01707923784852028,
      0.06893450766801834,
      -0.026053017005324364,
      -0.028768278658390045,
      -0.03614121675491333,
      0.039306432008743286,
      0.018388880416750908,
      0.02276458963751793,
      -0.011527801863849163,
      -0.011241097003221512,
      -0.0580301471054554,
      -0.07745689898729324,
      0.09032102674245834,
      -0.011109032668173313,
      -0.009502911940217018,
      0.012586344964802265,
      -0.06445993483066559,
      -0.09450898319482803,
      0.03351259604096413,
      -0.06802759319543839,
      0.02222745679318905,
      0.012648696079850197,
      -0.10438762605190277,
      -0.06534449011087418,
      -0.04260452836751938,
      -0.06901418417692184,
      0.09752312302589417,
      -0.0216749906539917,
      -0.012058494612574577,
      0.03894848749041557,
      0.07646049559116364,
      -0.04543619230389595,
      0.024720728397369385,
      0.0717131495475769,
      0.07918726652860641,
      0.022854365408420563,
      -0.014398657716810703,
      -0.03886483609676361,
      0.01644343137741089,
      -0.058136921375989914,
      0.09026387333869934,
      -0.0717635452747345,
      0.015872357413172722,
      0.05886894091963768,
      0.03546186909079552,
      0.04528585076332092,
      -0.03573815897107124,
      0.028557486832141876,
      0.002396080642938614,
      -0.011934017762541771,
      0.012087133713066578,
      -0.006711442489176989,
      0.02964049205183983,
      -0.011083667166531086,
      0.021115660667419434,
      0.00864709448069334,
      0.004367274697870016,
      -0.03282376751303673,
      -0.07463040202856064,
      0.02406945638358593,
      -0.050992369651794434,
      -0.07210937142372131,
      -0.010201881639659405,
      -0.02849603444337845,
      -0.00784059427678585,
      -0.04968520253896713,
      -0.07935968786478043,
      0.004715584684163332,
      -0.015314160846173763,
      -0.031719960272312164,
      -0.05602699890732765,
      0.027823591604828835,
      -0.006049300543963909,
      0.03437492623925209,
      -0.008364046923816204,
      -0.05502913519740105,
      -0.016908762976527214,
      -0.02722797356545925,
      0.03591445833444595,
      -0.09553628414869308,
      -0.025546574965119362,
      -0.001374288578517735,
      -0.04913587495684624,
      0.052626315504312515,
      0.04673543944954872,
      -0.0186774842441082,
      0.029143813997507095,
      -0.01269588340073824,
      0.019261492416262627,
      0.017189765349030495
    ],
    [
      0.010908344760537148,
      0.01639474369585514,
      -0.017580341547727585,
      0.013516772538423538,
      -0.062171678990125656,
      0.039283331483602524,
      -0.005092519335448742,
      -0.025676751509308815,
      -0.028263401240110397,
      -0.038011111319065094,
      0.02163391001522541,
      0.0424656979739666,
      -0.020348375663161278,
      0.04281623288989067,
      -0.06326957046985626,
      0.060523442924022675,
      0.03682805597782135,
      0.027383798733353615,
      -0.017198501154780388,
      -0.06615322828292847,
      0.014230434782803059,
      0.03125949203968048,
      0.006325795315206051,
      0.014463233761489391,
      -0.06098770722746849,
      0.014374923892319202,
      -0.02642936073243618,
      0.01478347834199667,
      -0.03071131557226181,
      -0.027838299050927162,
      0.023276666179299355,
      -0.03440578281879425,
      0.031116994097828865,
      0.011158891022205353,
      0.06494366377592087,
      -0.029143808409571648,
      -0.0091536995023489,
      -0.020033283159136772,
      0.032344698905944824,
      0.12889814376831055,
      -0.07398883998394012,
      0.07992767542600632,
      0.05539952218532562,
      -0.011193403042852879,
      -0.043771471828222275,
      -0.004856080282479525,
      0.015090862289071083,
      0.05832315981388092,
      0.04165984317660332,
      -0.007289770059287548,
      -7.627314334968105e-05,
      0.011402620933949947,
      0.003856985829770565,
      0.01030770968645811,
      0.012193731963634491,
      0.02280842699110508,
      -0.09244506061077118,
      -0.010171890258789062,
      0.006882728077471256,
      -0.020072251558303833,
      0.06106428802013397,
      0.010792414657771587,
      0.06701120734214783,
      -0.02432655170559883,
      -0.060095831751823425,
      0.010083560831844807,
      -0.022475365549325943,
      0.047373224049806595,
      0.02413897030055523,
      0.011690436862409115,
      0.003604204161092639,
      -0.05658211186528206,
      -0.053145743906497955,
      0.01570187136530876,
      0.05341627821326256,
      -0.009771239012479782,
      -0.09269098192453384,
      0.02143729105591774,
      0.013842830434441566,
      0.028258968144655228,
      0.061419423669576645,
      0.031102720648050308,
      0.11902401596307755,
      -0.07177769392728806,
      0.006366060581058264,
      -0.058059897273778915,
      0.10199559479951859,
      -0.006939273327589035,
      -0.11255820095539093,
      -0.08492808043956757,
      0.02179856412112713,
      0.021218109875917435,
      0.028338251635432243,
      0.07268652319908142,
      -0.0395989790558815,
      -0.051890771836042404,
      -0.024060865864157677,
      0.010883000679314137,
      0.003569347783923149,
      -0.0003525535576045513,
      -0.008976134471595287,
      0.07302331924438477,
      -0.05559069663286209,
      0.03393016755580902,
      0.017165128141641617,
      -0.09095603227615356,
      0.0009492910467088223,
      0.01696254312992096,
      0.010683831758797169,
      -0.05759603530168533,
      -0.08179176598787308,
      -0.0033232583664357662,
      0.005224504973739386,
      -0.03840026259422302,
      -0.021033551543951035,
      0.03375019133090973,
      0.07400409132242203,
      0.05231009051203728,
      0.026242800056934357,
      -0.05134914815425873,
      -0.0629122406244278,
      -0.023707378655672073,
      -0.026616154238581657,
      0.017863130196928978,
      0.004197276663035154,
      -0.0689692571759224,
      -0.010484506376087666,
      -0.015803832560777664,
      -0.021513579413294792,
      0.003713941900059581,
      0.05204514041543007,
      0.05291290953755379,
      0.0002232927508885041,
      0.04271046444773674,
      -0.0032504978589713573,
      0.023900149390101433,
      0.02189009264111519,
      0.03414443880319595,
      0.08067917823791504,
      0.06815236061811447,
      -0.0360078439116478,
      -0.0678505077958107,
      0.09652380645275116,
      0.05431027710437775,
      0.03847651928663254,
      0.0008856189670041203,
      0.01492556743323803,
      -0.020259777083992958,
      0.010097905062139034,
      -0.005336355417966843,
      -0.004279169254004955,
      -0.06730044633150101,
      -0.020270036533474922,
      0.02538919262588024,
      0.004104561172425747,
      0.026831775903701782,
      0.002717699157074094,
      -0.09489385783672333,
      -0.0619400255382061,
      0.04615012928843498,
      0.0784594938158989,
      0.0135730542242527,
      -0.034358151257038116,
      0.06943084299564362,
      -0.0003604322555474937,
      -0.012476082891225815,
      0.0447160042822361,
      -0.02394913323223591,
      -0.06637165695428848,
      -0.009451458230614662,
      0.030657777562737465,
      0.02126578986644745,
      0.00983432773500681,
      0.10248586535453796,
      0.06702037900686264,
      0.023170320317149162,
      0.04736059159040451,
      -0.03928972780704498,
      0.04388759285211563,
      -0.04063327610492706,
      -0.0197085402905941,
      0.03055781126022339,
      -0.04565650224685669,
      -0.021505868062376976,
      0.08761375397443771,
      -0.0474785678088665,
      -0.04417502507567406,
      -0.0265907421708107,
      0.032519832253456116,
      0.004816408734768629,
      0.02267964370548725,
      -0.05304742231965065,
      -0.03817982226610184,
      0.07231061160564423,
      0.018844889476895332,
      -0.005433407612144947,
      -0.10683378577232361,
      -0.08340401947498322,
      0.02657448500394821,
      0.02078305371105671,
      -0.021300479769706726,
      0.04120296984910965,
      0.0057297819294035435,
      -0.07073806971311569,
      0.03632771223783493,
      0.0760338082909584,
      0.02174084447324276,
      -0.002924108412116766,
      0.056269269436597824,
      -0.028125392273068428,
      -0.06172575801610947,
      -0.05810663104057312,
      -0.1347375512123108,
      -0.04572994261980057,
      -0.009850197471678257,
      0.04222602769732475,
      0.010015733540058136,
      -0.037296541035175323,
      -0.003140118205919862,
      0.026321733370423317,
      0.007998578250408173,
      -0.028167853131890297,
      -0.025173109024763107,
      -0.0232858806848526,
      -0.059461742639541626,
      0.05875461548566818,
      0.028298309072852135,
      0.05502049997448921,
      0.05104867368936539,
      -0.016250476241111755,
      -0.021285902708768845,
      -0.08702409267425537,
      0.04790054261684418,
      -0.11999069899320602,
      -0.05296012759208679,
      0.019866380840539932,
      -0.12835411727428436,
      -0.05493671074509621,
      0.014919239096343517,
      0.04780853912234306,
      -0.026700126007199287,
      0.044279925525188446,
      -0.05798538401722908,
      0.01848912425339222,
      -0.034117843955755234,
      -0.0654851496219635,
      -0.02549869753420353,
      -0.028742404654622078,
      -0.10915523767471313,
      0.08886515349149704,
      0.039119429886341095,
      0.004020419903099537,
      0.02914152294397354,
      0.025721345096826553,
      -0.05628518387675285,
      -0.060135368257761,
      0.036049362272024155,
      -0.04745029658079147,
      0.03620586916804314,
      0.02342960238456726,
      -0.03909660503268242,
      0.009444154798984528,
      0.01564192771911621,
      -0.003933606203645468,
      -0.04426812380552292,
      -0.03543360158801079,
      0.015996402129530907,
      -0.07774623483419418,
      0.0008880420937202871,
      0.013075998052954674,
      0.056870196014642715,
      -0.017362425103783607,
      0.001610879902727902,
      -0.02142186649143696,
      0.009060966782271862,
      0.007329894229769707,
      -0.03937216103076935,
      -0.0032244804315268993,
      -0.02566862851381302,
      -0.05847508832812309,
      0.009666929952800274,
      -0.09508032351732254,
      -0.021600846201181412,
      -0.03522084653377533,
      -0.002249757293611765,
      0.05866534635424614,
      -0.06053804233670235,
      0.048566367477178574,
      -0.08760520815849304,
      -0.011631980538368225,
      -0.07090626657009125,
      0.04610598832368851,
      0.017159689217805862,
      0.02787371352314949,
      -0.03336126357316971,
      0.03196023032069206,
      -0.0025939077604562044,
      -0.07264376431703568,
      -0.04130952060222626,
      -0.04568243399262428,
      0.043245986104011536,
      0.0254974402487278,
      -0.02548198215663433,
      0.021870989352464676,
      0.038059744983911514,
      0.08237016946077347,
      -0.0281668808311224,
      -0.01653992012143135,
      -0.05769684165716171,
      0.03726145625114441,
      0.04101111739873886,
      0.04219592362642288,
      -0.039568014442920685,
      0.026086561381816864,
      0.014884096570312977,
      -0.0020123361609876156,
      -0.02535264939069748,
      0.0012096123537048697,
      -0.05048058554530144,
      -0.016572754830121994,
      0.03731235861778259,
      -0.052836883813142776,
      0.030345292761921883,
      -0.059882014989852905,
      -0.0555337518453598,
      0.029274946078658104,
      -0.015113631263375282,
      -0.030013570562005043,
      -0.04199598357081413,
      -0.0756424218416214,
      -0.03841834142804146,
      -0.03893256187438965,
      -0.07339996099472046,
      -0.03613895922899246,
      -0.03318563476204872,
      -0.08478246629238129,
      -0.0009268200374208391,
      0.035086825489997864,
      -0.06148795038461685,
      0.05616644769906998,
      0.015483411028981209,
      0.05189765989780426,
      0.0797245055437088,
      -0.005290512461215258,
      -0.014468039385974407,
      0.09738928824663162,
      -0.03602195531129837,
      -0.07400847226381302,
      0.048867564648389816,
      0.0996299758553505,
      0.04962870106101036,
      -0.010626714676618576,
      -0.004349069204181433,
      0.029029088094830513,
      0.018102988600730896,
      0.046889420598745346,
      -0.05625034123659134,
      -0.012577321380376816,
      0.036151424050331116,
      0.022132912650704384,
      0.02807449735701084,
      -0.006906857714056969,
      0.0169235710054636,
      0.019190199673175812,
      -0.0045019714161753654,
      0.09141648560762405,
      0.0031173801980912685,
      -0.018973777070641518,
      0.028712855651974678,
      -0.09948918223381042,
      0.026213252916932106,
      -0.04056788608431816,
      0.045087311416864395,
      0.015262528322637081,
      -0.01707390695810318,
      -0.00021661324717570096,
      0.0035333174746483564,
      0.004887816961854696,
      0.015489058569073677,
      -0.02884691208600998,
      0.04477730020880699,
      0.05264568701386452,
      -0.03223705291748047,
      0.034653883427381516,
      -0.08966945111751556,
      -0.061570849269628525,
      -0.016413671895861626,
      -0.014126732014119625,
      0.051059309393167496,
      0.01874121092259884,
      0.03600769490003586,
      -0.001212483737617731,
      0.0046947249211370945,
      0.009868266992270947,
      0.0312822051346302,
      0.0628378763794899,
      0.008356235921382904,
      -0.047280602157115936,
      -0.01967509463429451,
      0.07695575803518295,
      -0.009613721631467342,
      0.03179518133401871,
      0.022866161540150642,
      0.09225767850875854,
      0.10107117146253586,
      0.0200120210647583,
      0.014878385700285435,
      -0.0041160574182868,
      -0.010685483925044537,
      0.014741397462785244,
      0.015032128430902958,
      -0.010161953046917915,
      -0.02920129895210266,
      0.04172429069876671,
      -0.03443577513098717,
      -0.04973988234996796,
      0.018478503450751305,
      0.07733865082263947,
      0.0013525757240131497,
      0.07511758804321289,
      0.05140380188822746,
      0.07575003802776337,
      -0.04386444017291069,
      0.09734370559453964,
      -0.0484958179295063,
      0.027152996510267258,
      0.018794603645801544,
      0.01983233354985714,
      0.0077029005624353886,
      0.03893977776169777,
      -0.0583789125084877,
      0.06051672250032425,
      0.013680247589945793,
      0.01965991221368313,
      0.015326488763093948,
      0.010932891629636288,
      -0.02821435034275055,
      0.050849009305238724,
      0.055187877267599106,
      -0.022287700325250626,
      -0.07534065842628479,
      0.048235081136226654,
      -0.005452714394778013,
      -0.03708658367395401,
      -0.0724838525056839,
      -0.03127074986696243,
      -0.02816869504749775,
      -0.045033738017082214,
      -0.045318298041820526,
      -0.022211721166968346,
      -0.02089640125632286,
      -0.0674910619854927,
      -0.07014187425374985,
      -0.01431675162166357,
      0.04212042689323425,
      0.014529136009514332,
      0.012309889309108257,
      -0.02265356481075287,
      0.019934389740228653,
      0.0054947505705058575,
      -0.036878325045108795,
      0.04883309453725815,
      -0.019798574969172478,
      -0.10037112981081009,
      0.03404191508889198,
      -0.014348705299198627,
      0.04671187326312065,
      -0.04381311684846878,
      0.0906369686126709,
      0.03431573137640953,
      -0.023389967158436775,
      -0.008813212625682354,
      0.03118741512298584,
      0.0008523819269612432,
      0.013408372178673744,
      0.13519197702407837,
      -0.044180501252412796,
      0.06055491045117378,
      -0.06144048646092415,
      0.08036290109157562,
      0.12332252413034439,
      0.04759110137820244,
      0.056655026972293854,
      0.06174058839678764,
      0.09402091056108475,
      -0.0896536111831665,
      0.03062853403389454,
      -0.0433892086148262,
      -0.03185155615210533,
      0.0858466774225235,
      0.005763731896877289,
      0.028738075867295265,
      0.006954135838896036,
      0.11582205444574356,
      -0.015206646174192429,
      0.07592238485813141,
      -0.05238407850265503,
      -0.01401597261428833,
      -0.03802066296339035,
      0.037388209253549576,
      -0.024922244250774384,
      0.05768977478146553,
      0.04604814574122429
    ],
    [
      0.025392718613147736,
      -0.03830420970916748,
      -0.007684584707021713,
      -0.007980367168784142,
      -0.010625259019434452,
      -0.012870305217802525,
      0.018149910494685173,
      0.02806520275771618,
      0.05984758958220482,
      -0.023575762286782265,
      -0.024236071854829788,
      -0.011937186121940613,
      -0.02381870336830616,
      -0.0300140380859375,
      -0.01923203282058239,
      -0.004896824713796377,
      0.1462988406419754,
      -0.036899879574775696,
      0.11986926943063736,
      0.02546674571931362,
      0.0862012580037117,
      0.045245036482810974,
      -0.02791285328567028,
      0.03037165477871895,
      0.053803037852048874,
      -0.015311836265027523,
      -0.04973539337515831,
      -0.024002430960536003,
      0.10737980157136917,
      0.09024503827095032,
      -0.05947403982281685,
      -0.0878259614109993,
      -0.016255049034953117,
      0.02369752898812294,
      0.05829978734254837,
      0.02583068422973156,
      -0.021921208128333092,
      -0.005107690580189228,
      -0.019646452739834785,
      0.1066872850060463,
      0.014621268026530743,
      0.03528217971324921,
      0.07172495126724243,
      0.004049696493893862,
      0.0618603453040123,
      -0.048196665942668915,
      -0.05281118303537369,
      -0.01047561690211296,
      -0.04241034388542175,
      -0.02440500259399414,
      0.06122167035937309,
      -0.09815924614667892,
      -0.015150371938943863,
      -0.0493457093834877,
      0.04191737622022629,
      0.00136274890974164,
      0.030352246016263962,
      -0.03687543794512749,
      0.04156697168946266,
      0.07742349058389664,
      0.005984772462397814,
      -0.00353678734973073,
      -0.005350489169359207,
      -0.08070587366819382,
      0.039907097816467285,
      0.051143791526556015,
      -0.004112760070711374,
      0.01666218973696232,
      0.009812247939407825,
      0.037346433848142624,
      -0.045501165091991425,
      -0.034634560346603394,
      0.012371264398097992,
      -0.0015089471125975251,
      0.01580866612493992,
      -0.0940505713224411,
      0.0029437707271426916,
      0.027674268931150436,
      0.035954803228378296,
      -0.08346577733755112,
      -0.02726743556559086,
      0.06305211037397385,
      -0.004130569752305746,
      0.09317875653505325,
      0.047711946070194244,
      0.0665736049413681,
      0.006064749788492918,
      0.053839169442653656,
      0.0057499040849506855,
      -0.05872485786676407,
      -0.018282869830727577,
      -0.04325226694345474,
      0.049168508499860764,
      -0.041484586894512177,
      -0.049979496747255325,
      -0.03484480082988739,
      0.08294469863176346,
      0.017440440133213997,
      -0.02695479802787304,
      0.05041570961475372,
      -0.02271699719130993,
      0.0048424056731164455,
      0.0018440894782543182,
      0.003018259536474943,
      0.03802033141255379,
      -0.04470942169427872,
      -0.026390142738819122,
      0.11228108406066895,
      -0.0065052928403019905,
      0.00018502619059290737,
      0.028389981016516685,
      -0.048190657049417496,
      0.011362581513822079,
      -0.01901821419596672,
      -0.01919291540980339,
      0.07869768887758255,
      0.04945782944560051,
      0.03583158180117607,
      0.01650887541472912,
      0.011416609399020672,
      0.029299339279532433,
      0.005388833116739988,
      -0.004931690637022257,
      0.009399909526109695,
      -0.07479257881641388,
      -0.05238061025738716,
      -0.014717240817844868,
      -0.05839964374899864,
      0.025012565776705742,
      0.02829720824956894,
      0.031111538410186768,
      0.05248972401022911,
      -0.007590244058519602,
      0.0972810909152031,
      0.009168139658868313,
      0.022036293521523476,
      0.052013497799634933,
      -0.02513924427330494,
      -0.06749828904867172,
      0.0006004827446304262,
      0.02361895702779293,
      -0.03278766945004463,
      -0.003801758401095867,
      0.07234267145395279,
      -0.022891059517860413,
      0.019381606951355934,
      -0.02898697927594185,
      0.042441096156835556,
      -0.020510630682110786,
      0.03541179001331329,
      0.06029176339507103,
      -0.050701793283224106,
      -0.07933075726032257,
      -0.005106835626065731,
      0.011884364299476147,
      -0.050343792885541916,
      0.015534261241555214,
      -0.07457435876131058,
      -0.01424158550798893,
      -0.0584627203643322,
      0.06650367379188538,
      -0.02346898429095745,
      -0.01932280696928501,
      0.07617877423763275,
      -0.07166829705238342,
      0.017810583114624023,
      -0.08473504334688187,
      -0.023889247328042984,
      0.015274818986654282,
      0.11774254590272903,
      -0.0653642937541008,
      0.009817470796406269,
      0.055988021194934845,
      0.07720552384853363,
      0.061517778784036636,
      0.11285428702831268,
      0.03536370396614075,
      0.004657051991671324,
      0.000747061800211668,
      0.10120310634374619,
      0.05455973371863365,
      0.029597120359539986,
      0.042318254709243774,
      0.0017156160902231932,
      0.05266271531581879,
      0.054947782307863235,
      0.027756109833717346,
      -0.03172522038221359,
      -0.023194342851638794,
      -0.015168890357017517,
      -0.02202220819890499,
      -0.04062224179506302,
      -0.014054391533136368,
      -0.026504794135689735,
      -0.06961755454540253,
      -0.002169643994420767,
      -0.039099790155887604,
      -0.014926910400390625,
      -0.008442197926342487,
      0.08044064044952393,
      0.08628383278846741,
      -0.012652161531150341,
      -0.05423686280846596,
      0.05752960592508316,
      0.015676096081733704,
      -0.0031869709491729736,
      0.055800922214984894,
      -0.05802424997091293,
      0.03484297916293144,
      -0.09627053141593933,
      -0.05823984742164612,
      -0.022361135110259056,
      0.051382455974817276,
      -0.004110537935048342,
      -0.019154667854309082,
      -0.02861131727695465,
      0.05737856775522232,
      -0.04445401579141617,
      0.03588853031396866,
      0.057527437806129456,
      0.012640719301998615,
      -0.012155147269368172,
      0.05979318171739578,
      0.035833001136779785,
      0.027587560936808586,
      0.017440566793084145,
      -0.06899560242891312,
      -0.01187729462981224,
      0.016637520864605904,
      0.03364904597401619,
      -0.007533663418143988,
      -0.05147634074091911,
      0.04284023120999336,
      -0.08473899215459824,
      -0.011910391971468925,
      -0.022778814658522606,
      -0.05009903758764267,
      -0.011842732317745686,
      0.046844568103551865,
      -0.09724694490432739,
      0.03446519002318382,
      -0.06477456539869308,
      0.056644950062036514,
      -0.04603642597794533,
      -0.040948037058115005,
      0.04767328128218651,
      -0.00013518222840502858,
      0.056684453040361404,
      -0.012039624154567719,
      0.05459453910589218,
      0.015262759290635586,
      -0.014127367176115513,
      -0.0710451528429985,
      -0.0022097090259194374,
      0.04883419722318649,
      -0.03797174617648125,
      0.02103462815284729,
      -0.0037311327178031206,
      0.013894256204366684,
      0.00039998203283175826,
      0.007219023071229458,
      0.03005143254995346,
      0.01769833080470562,
      0.008348795585334301,
      0.07017818093299866,
      -0.002071068389341235,
      -0.03665411099791527,
      0.011200858280062675,
      0.07511109113693237,
      0.043467264622449875,
      -0.05213378369808197,
      -0.02600247412919998,
      -0.029582448303699493,
      0.0012510117376223207,
      0.07126567512750626,
      0.04762181639671326,
      -0.00011637216084636748,
      -0.045136820524930954,
      0.029887359589338303,
      0.04575655609369278,
      0.0348023846745491,
      -0.06911785155534744,
      -0.037963785231113434,
      0.028989199548959732,
      -0.06675205379724503,
      0.06094497814774513,
      0.015476452186703682,
      -0.001240430516190827,
      -0.00048119554412551224,
      0.011214172467589378,
      -0.02810853160917759,
      -0.0052228583954274654,
      -0.02129228226840496,
      0.019205493852496147,
      -0.0653318539261818,
      -0.03571372479200363,
      0.011621935293078423,
      0.01918724738061428,
      0.009481048211455345,
      -0.03221236914396286,
      0.02525145933032036,
      0.020816998556256294,
      0.027333613485097885,
      0.01363981980830431,
      0.051506344228982925,
      -0.007004727143794298,
      0.01922723278403282,
      0.03506643697619438,
      -0.09904159605503082,
      0.02172902412712574,
      -0.0021938392892479897,
      0.08338820934295654,
      -0.006646339315921068,
      0.042481668293476105,
      -0.04210974648594856,
      -0.05956118926405907,
      0.03794035315513611,
      0.11745595932006836,
      0.07658836245536804,
      -0.02692308835685253,
      -0.06762602180242538,
      -0.01933949440717697,
      -0.014728061854839325,
      -0.008855879306793213,
      0.067769855260849,
      0.05969969555735588,
      -0.0014184733154252172,
      -0.0464179627597332,
      0.055947445333004,
      -0.03694293275475502,
      -0.02635970711708069,
      0.010634996928274632,
      0.06046757102012634,
      -0.03383577615022659,
      -0.06488566845655441,
      0.035621341317892075,
      0.10373029112815857,
      0.015008524991571903,
      -0.026613706722855568,
      -0.006413337308913469,
      -0.023688554763793945,
      0.04399757832288742,
      -0.041877299547195435,
      0.053214751183986664,
      0.031641893088817596,
      -0.037761203944683075,
      -0.05346338823437691,
      -0.007889635860919952,
      -0.04069143906235695,
      0.0033408752642571926,
      -0.04073047265410423,
      -0.03189811855554581,
      -0.012372777797281742,
      0.03240691125392914,
      -0.025279004126787186,
      0.019856033846735954,
      0.05363762378692627,
      -0.009641203097999096,
      -0.014350930228829384,
      -0.07281646877527237,
      0.0040628667920827866,
      0.003470613155514002,
      -0.01923416182398796,
      0.04193400591611862,
      0.026060905307531357,
      0.025147434324026108,
      -0.02011464163661003,
      0.0019106486579403281,
      -0.04810880124568939,
      -0.020283810794353485,
      0.03131890669465065,
      -0.02915978990495205,
      0.00648100022226572,
      -0.0068397303111851215,
      -0.04359019920229912,
      0.02921067178249359,
      0.06478169560432434,
      0.05167786404490471,
      0.005296415649354458,
      -0.02846529334783554,
      -0.0336972251534462,
      -0.0015859140548855066,
      0.09424607455730438,
      0.03769315779209137,
      0.01580066792666912,
      0.014491084031760693,
      0.03267558291554451,
      -0.06735370308160782,
      -0.008778324350714684,
      0.06009652838110924,
      0.020981570705771446,
      0.07257404178380966,
      -0.004784659948199987,
      0.031167365610599518,
      0.01886437088251114,
      0.010661094449460506,
      -0.011641104705631733,
      0.01575411483645439,
      0.03838959336280823,
      0.01346902921795845,
      -0.05795031413435936,
      0.026186348870396614,
      -0.06335071474313736,
      0.06157339736819267,
      -0.014207486994564533,
      0.004838157445192337,
      -0.008703282102942467,
      0.0643840879201889,
      -0.029834963381290436,
      0.009436060674488544,
      -0.002685420447960496,
      0.03297767415642738,
      0.046154748648405075,
      0.041681792587041855,
      0.034853849560022354,
      -0.02760355733335018,
      0.1012643426656723,
      0.019993172958493233,
      -0.0032241519074887037,
      -0.028707332909107208,
      0.004009130410850048,
      0.13912253081798553,
      -0.02734827995300293,
      -0.07070125639438629,
      -0.03729609027504921,
      0.04193693771958351,
      -0.01474063377827406,
      0.061829064041376114,
      0.006798379588872194,
      0.0012013566447421908,
      -0.033858105540275574,
      -0.018603568896651268,
      0.027838395908474922,
      -0.025570228695869446,
      -0.005360228009521961,
      0.02553042769432068,
      0.03535853698849678,
      -0.005466758739203215,
      -0.01704358495771885,
      0.03892974928021431,
      0.03803371265530586,
      -0.0186358280479908,
      -0.022087812423706055,
      -0.009563961066305637,
      0.008054439909756184,
      0.025501905009150505,
      0.057534109801054,
      -0.04918068274855614,
      -0.07106675952672958,
      -0.00010571242455625907,
      0.0035215558018535376,
      0.02397039160132408,
      0.02134326659142971,
      0.04059857875108719,
      0.005704639945179224,
      -0.03601136431097984,
      0.014410240575671196,
      -0.04599517956376076,
      0.04924098402261734,
      -0.04388483241200447,
      0.1019795835018158,
      0.01408077497035265,
      0.0630078986287117,
      0.08725127577781677,
      0.018318291753530502,
      0.029251782223582268,
      0.06619898974895477,
      0.004770820494741201,
      -0.018313441425561905,
      -0.026291389018297195,
      0.046042829751968384,
      -0.045257557183504105,
      -0.02162959985435009,
      0.01607886143028736,
      -0.0037866418715566397,
      0.023687954992055893,
      0.013262639753520489,
      0.019427699968218803,
      0.020534483715891838,
      -0.01057527493685484,
      0.012094957754015923,
      0.015253894031047821,
      0.010519692674279213,
      -0.021065525710582733,
      0.0007227009627968073,
      -0.012653229758143425,
      -0.0016082040965557098,
      0.0332939438521862,
      -0.026073405519127846,
      -0.07794748991727829,
      0.07616407424211502,
      0.022871779277920723,
      -0.06399334967136383,
      -0.004960316698998213,
      0.10113168507814407,
      0.03427102416753769,
      0.08236604928970337,
      0.0640312060713768,
      -0.06411716341972351,
      0.006116508040577173,
      0.008783684112131596,
      -0.04453286528587341,
      0.019948218017816544
    ],
    [
      -0.013758807443082333,
      -0.014802650548517704,
      0.0334073081612587,
      -0.05463607981801033,
      -0.02081788331270218,
      -0.01577220857143402,
      0.003232793416827917,
      -0.022703716531395912,
      -0.0831981748342514,
      -0.010842755436897278,
      0.030610838904976845,
      0.036875102669000626,
      -0.024947287514805794,
      0.05640033259987831,
      -0.005195717327296734,
      -0.03540724143385887,
      0.027361631393432617,
      0.043324824422597885,
      -0.03882385790348053,
      0.02287733554840088,
      0.039144642651081085,
      -0.015746263787150383,
      0.01290243212133646,
      -0.03712240606546402,
      -0.011605129577219486,
      -0.06280730664730072,
      -0.007651189342141151,
      -0.026210568845272064,
      -0.01977337710559368,
      0.02441215328872204,
      -0.0068978676572442055,
      0.05254372954368591,
      -0.01117698010057211,
      0.041918687522411346,
      0.07072173058986664,
      0.013001170009374619,
      -0.037845343351364136,
      0.00043963617645204067,
      -0.06909322738647461,
      -0.0077950796112418175,
      0.006558953784406185,
      0.03901466354727745,
      0.06755390763282776,
      0.038770269602537155,
      -0.0027245860546827316,
      0.06352332979440689,
      -0.019225236028432846,
      -0.06428862363100052,
      0.042729396373033524,
      0.01606529764831066,
      -0.021254759281873703,
      0.0006436014082282782,
      0.016720285639166832,
      -0.04207080975174904,
      -0.02590872347354889,
      0.033664360642433167,
      -0.002943409141153097,
      -0.002699150936678052,
      0.021104903891682625,
      0.06375966966152191,
      -0.004269593860954046,
      0.016509417444467545,
      0.014476960524916649,
      -0.11239254474639893,
      -0.011809141375124454,
      0.08175048232078552,
      0.03663032874464989,
      -0.003538844408467412,
      -0.04536931961774826,
      -0.020405711606144905,
      -0.001456987694837153,
      -0.022105533629655838,
      -0.03453192487359047,
      -0.04988718032836914,
      0.03918011859059334,
      -0.016083329916000366,
      -0.011526818387210369,
      -0.014079251326620579,
      -0.0014072126941755414,
      0.06192394718527794,
      -0.0058996668085455894,
      0.004776606801897287,
      -0.0024432700593024492,
      0.015702739357948303,
      0.005969453603029251,
      -0.04627719521522522,
      -0.048626042902469635,
      0.06595964729785919,
      -0.03480178862810135,
      -0.04057100787758827,
      0.03894464299082756,
      -0.02059580758213997,
      0.007302731741219759,
      -0.017567384988069534,
      0.017350951209664345,
      0.003031598636880517,
      0.08053752779960632,
      0.003533279988914728,
      0.050770532339811325,
      -0.045850057154893875,
      0.008156941272318363,
      -0.04276644438505173,
      -0.04981861636042595,
      0.06894659250974655,
      0.014980079606175423,
      0.06695011258125305,
      0.10633089393377304,
      0.03552931174635887,
      0.019779011607170105,
      0.07384097576141357,
      -0.07185538113117218,
      0.055887121707201004,
      0.05870739743113518,
      0.048679791390895844,
      0.009021596051752567,
      0.06057889387011528,
      0.03252364322543144,
      -0.023888669908046722,
      -0.043801672756671906,
      0.013959364034235477,
      -0.018784336745738983,
      0.0358305461704731,
      -0.049935732036828995,
      0.018832577392458916,
      0.036447104066610336,
      0.020531808957457542,
      0.007621163967996836,
      0.0042107077315449715,
      0.06474241614341736,
      -0.022098150104284286,
      0.016696397215127945,
      -0.1034068688750267,
      0.008704581297934055,
      -0.004934995900839567,
      0.016118129715323448,
      -0.07425587624311447,
      0.0450432263314724,
      -0.045241452753543854,
      0.022385962307453156,
      -0.033438876271247864,
      -0.0006263868999667466,
      0.010194813832640648,
      0.02848159149289131,
      0.06437477469444275,
      -0.01567259058356285,
      0.016879748553037643,
      -0.021560929715633392,
      -0.022146305069327354,
      0.027074292302131653,
      -0.0098769785836339,
      0.035346876829862595,
      0.00915462151169777,
      -0.004410193767398596,
      -0.01502880360931158,
      0.06474847346544266,
      -0.10502832382917404,
      0.03513728827238083,
      0.08598531037569046,
      0.042504869401454926,
      0.07805396616458893,
      -0.003570790169760585,
      -0.10098341107368469,
      0.01284243818372488,
      0.009174692444503307,
      0.0045037465170025826,
      0.02263009361922741,
      0.030078627169132233,
      0.017032472416758537,
      0.01996953971683979,
      0.045161694288253784,
      -0.04850701242685318,
      -0.02374585159122944,
      -0.01934335194528103,
      -0.0013221089029684663,
      0.04879212751984596,
      -0.06635277718305588,
      -0.03271018713712692,
      -0.01719905436038971,
      0.08988004177808762,
      -0.025806307792663574,
      0.029235361143946648,
      0.03629292920231819,
      0.0012641303474083543,
      0.024710513651371002,
      -0.029478222131729126,
      -0.026017939671874046,
      -0.05202551186084747,
      0.058892522007226944,
      0.05901786684989929,
      0.027634255588054657,
      -0.051057588309049606,
      0.03810933604836464,
      -0.015712786465883255,
      0.05837245285511017,
      -0.03883732110261917,
      0.01973966509103775,
      0.009301544167101383,
      0.03339936211705208,
      -0.015126299113035202,
      -0.032863032072782516,
      0.02565663680434227,
      -0.0417354553937912,
      0.02030857838690281,
      0.05755336582660675,
      -0.08369158208370209,
      -0.05458256974816322,
      0.007013778202235699,
      -0.05397568270564079,
      0.07433116436004639,
      0.037050552666187286,
      -0.0568164624273777,
      0.0033725579269230366,
      -0.03212612122297287,
      0.0870116800069809,
      -0.0170705895870924,
      -0.03550735116004944,
      0.041732337325811386,
      0.006286188028752804,
      -0.0776127353310585,
      -0.018080951645970345,
      -0.07809125632047653,
      0.007705829106271267,
      0.03760524466633797,
      0.03363659977912903,
      -0.030277924612164497,
      -0.06304815411567688,
      -0.04571499302983284,
      -0.0012386002345010638,
      -0.02013571746647358,
      0.0332285575568676,
      0.02871309220790863,
      0.03057965077459812,
      0.0488206148147583,
      -0.03555716201663017,
      0.0859956368803978,
      -0.0393158420920372,
      -0.038050003349781036,
      0.050899967551231384,
      0.0296014491468668,
      0.04519040882587433,
      0.00955990795046091,
      -0.015808412805199623,
      -0.030186064541339874,
      -0.042260803282260895,
      0.024948865175247192,
      0.009495657868683338,
      0.05470127612352371,
      0.007504841312766075,
      0.03227139264345169,
      0.011286750435829163,
      0.02941768243908882,
      -0.006053168326616287,
      0.008522888645529747,
      -0.020570235326886177,
      -0.03728635981678963,
      0.0033361203968524933,
      0.07874447852373123,
      0.10433711111545563,
      -0.031066080555319786,
      0.02543252892792225,
      -0.04250640049576759,
      0.012733335606753826,
      -0.021603021770715714,
      0.054682351648807526,
      0.0029163348954170942,
      0.013587520457804203,
      0.023777605965733528,
      -0.07398013770580292,
      -0.025488218292593956,
      -0.006321300752460957,
      0.04241161420941353,
      0.015565063804388046,
      0.015064027160406113,
      -0.06203930079936981,
      0.009853364899754524,
      -0.02277897484600544,
      -0.01843860372900963,
      -0.03975983336567879,
      0.018249277025461197,
      0.006880848668515682,
      0.006707095541059971,
      -0.0071497284807264805,
      -0.04270680248737335,
      0.010429026558995247,
      0.03281102702021599,
      0.025597622618079185,
      0.003931917250156403,
      -0.06548072397708893,
      0.04501332715153694,
      -0.04221261665225029,
      -0.06670942902565002,
      0.023429024964571,
      -0.010587548837065697,
      0.04068073630332947,
      -0.01146864052861929,
      -0.06129255145788193,
      -0.0020025831181555986,
      -0.04751134663820267,
      -0.006224878132343292,
      -0.09193529188632965,
      0.015510416589677334,
      0.07673180103302002,
      -0.013099945150315762,
      -0.009125934913754463,
      -0.04191657528281212,
      -0.007422694005072117,
      -0.0401018001139164,
      0.006645382381975651,
      -0.02588651329278946,
      -0.018612926825881004,
      -0.008313960395753384,
      -0.03939410299062729,
      0.010830906219780445,
      -0.052802689373493195,
      0.10144954174757004,
      -0.02198134735226631,
      0.03895992413163185,
      0.004922409076243639,
      -0.02729264833033085,
      -0.03286829963326454,
      0.02919372357428074,
      0.0035656574182212353,
      -0.021929200738668442,
      0.02191084437072277,
      0.015340425074100494,
      -0.0362263023853302,
      -0.01747443713247776,
      -0.013401256874203682,
      -0.04473135620355606,
      0.008257979527115822,
      0.03430260345339775,
      -0.0027011672500520945,
      -0.020385747775435448,
      0.06260663270950317,
      -0.04806957021355629,
      0.06539890170097351,
      -0.07317786663770676,
      0.005000457167625427,
      0.04505409672856331,
      0.03298281878232956,
      -0.032839637249708176,
      -0.09428808093070984,
      0.013245542533695698,
      -0.07506616413593292,
      0.01825944148004055,
      -0.007302725687623024,
      0.05093701556324959,
      -0.04589017480611801,
      -0.046402707695961,
      -0.061095353215932846,
      0.030879106372594833,
      0.001303460099734366,
      0.048790425062179565,
      -0.05106116831302643,
      0.01953822560608387,
      0.0928584411740303,
      -0.014559379778802395,
      0.03150967136025429,
      -0.005771906580775976,
      -0.07024068385362625,
      0.011013315990567207,
      0.0001015255184029229,
      0.002232368802651763,
      -0.039353836327791214,
      -0.056186094880104065,
      -0.008849887177348137,
      -0.003966571297496557,
      0.021450674161314964,
      -0.013223417103290558,
      0.03002423420548439,
      -0.03736307844519615,
      -0.0003784489817917347,
      0.06161751598119736,
      0.025411268696188927,
      0.01314538437873125,
      0.03340839594602585,
      -0.00764372618868947,
      -0.052741434425115585,
      -0.054176319390535355,
      0.023829156532883644,
      -0.03417405113577843,
      -0.08223457634449005,
      -0.04079122096300125,
      -0.053847506642341614,
      -0.020758699625730515,
      -0.013216276653110981,
      -0.011455600149929523,
      0.08660570532083511,
      0.06142685189843178,
      0.021688630804419518,
      0.019764751195907593,
      -0.012712259776890278,
      0.022573862224817276,
      -0.02174527384340763,
      0.033493492752313614,
      0.06111865118145943,
      0.05142837390303612,
      -0.08633603900671005,
      -0.053034458309412,
      0.01148588303476572,
      -0.008789285086095333,
      -0.009708292782306671,
      0.09873083978891373,
      0.009823577478528023,
      0.0363197922706604,
      0.011171732097864151,
      -0.010893439874053001,
      0.03654859587550163,
      -0.03299852833151817,
      -0.016118180006742477,
      -0.017458321526646614,
      0.057084985077381134,
      0.03814835473895073,
      0.04411858692765236,
      -0.007601520977914333,
      -0.030089683830738068,
      0.055651839822530746,
      -0.05689803883433342,
      0.02635037899017334,
      0.05367707833647728,
      0.006900877226144075,
      0.018528791144490242,
      0.008352523669600487,
      -0.03384627401828766,
      0.009437058120965958,
      -0.03354095295071602,
      0.08537663519382477,
      -0.028396567329764366,
      -0.012870813719928265,
      0.0023637276608496904,
      -0.05127199739217758,
      -0.054855912923812866,
      0.07099888473749161,
      -0.06914634257555008,
      -0.011337396688759327,
      0.08414962142705917,
      -0.009465746581554413,
      -0.033609818667173386,
      -0.03721948713064194,
      -0.017840851098299026,
      0.013874320313334465,
      -0.006368869449943304,
      0.040351495146751404,
      -0.09853494167327881,
      0.0169654730707407,
      0.031416017562150955,
      0.03219743072986603,
      0.007506484165787697,
      -0.04283367097377777,
      -0.021374711766839027,
      0.009649449028074741,
      0.004681616555899382,
      0.03177648037672043,
      -0.09290970116853714,
      -0.035004012286663055,
      0.016127675771713257,
      -0.06436467170715332,
      -0.02802419662475586,
      0.07845591753721237,
      -0.019892128184437752,
      0.01238980796188116,
      -0.0517929308116436,
      0.042503710836172104,
      0.008460750803351402,
      0.01661001518368721,
      0.04463512822985649,
      -0.030091341584920883,
      0.11233644932508469,
      0.040527693927288055,
      0.09129639714956284,
      -0.06801695376634598,
      0.02596309781074524,
      -0.08936557918787003,
      0.04667733609676361,
      -0.02792634814977646,
      0.045694876462221146,
      0.004725280217826366,
      0.018862556666135788,
      -0.03195803984999657,
      0.052191633731126785,
      0.0055494788102805614,
      0.008261491544544697,
      0.010714948177337646,
      0.003476132173091173,
      0.02873503603041172,
      -0.005480465013533831,
      0.05182608962059021,
      -0.11571817100048065,
      -0.025630876421928406,
      0.0428830087184906,
      -0.001400606008246541,
      -0.07567381113767624,
      0.0035929353907704353,
      0.06422961503267288,
      -0.01653585582971573,
      -0.05856994166970253,
      0.036627769470214844,
      -0.048559948801994324,
      -0.034480005502700806,
      0.018151085823774338,
      0.08706918358802795,
      0.024064473807811737,
      -0.009102357551455498
    ],
    [
      -0.011946325190365314,
      -0.011279307305812836,
      -0.005137802567332983,
      0.01834488846361637,
      -0.01450184267014265,
      0.005926982033997774,
      0.03314337879419327,
      0.040932878851890564,
      -0.012457916513085365,
      0.08185092359781265,
      0.10783892869949341,
      -0.022350193932652473,
      -0.055963415652513504,
      -0.02638235129415989,
      0.07908859103918076,
      -0.00038024436798878014,
      0.008076942525804043,
      -0.018855560570955276,
      -0.00653007160872221,
      -0.04890210181474686,
      0.016756052151322365,
      -0.04662330076098442,
      0.03210494667291641,
      -0.026652086526155472,
      0.052975576370954514,
      0.11804372817277908,
      0.018858669325709343,
      -0.01676187850534916,
      -0.0013768121134489775,
      0.08415458351373672,
      -0.04609058424830437,
      0.02417541667819023,
      -0.03777376562356949,
      0.03906045854091644,
      -0.01213911920785904,
      0.057908687740564346,
      0.0056747072376310825,
      -0.08822201192378998,
      0.06264089792966843,
      -0.007610626053065062,
      -0.018643902614712715,
      0.05238790437579155,
      0.044950857758522034,
      -0.003837757045403123,
      -0.02248755842447281,
      -0.11261352151632309,
      0.01940322481095791,
      -0.09238465875387192,
      -0.022360647097229958,
      -0.0023425037506967783,
      -0.02998485416173935,
      0.0925491452217102,
      -0.10372120887041092,
      -0.058743514120578766,
      0.02738255448639393,
      -0.04805169254541397,
      -0.02310335449874401,
      -0.043963722884655,
      0.08189833909273148,
      0.028240693733096123,
      0.10423693805932999,
      0.02261902205646038,
      0.00791092962026596,
      -0.05811929702758789,
      0.02378329634666443,
      -0.05341234803199768,
      0.010682038962841034,
      -0.03661242127418518,
      0.058156538754701614,
      0.057083889842033386,
      0.020388387143611908,
      -0.007719103712588549,
      0.04037586972117424,
      -0.0304787065833807,
      -0.006224450189620256,
      -0.03433733433485031,
      -0.011393309570848942,
      0.005744050722569227,
      -0.006122248712927103,
      0.05911785736680031,
      0.07599037140607834,
      -0.012029659934341908,
      -0.03182205557823181,
      0.014363403432071209,
      0.018387867137789726,
      0.0782831534743309,
      -0.04121891036629677,
      0.018541820347309113,
      -0.0262735728174448,
      -0.008956154808402061,
      -0.06000518053770065,
      0.06626743823289871,
      0.021080363541841507,
      0.02815423160791397,
      -0.06192367151379585,
      0.024406900629401207,
      -0.0027120874729007483,
      0.006433159112930298,
      -0.06745707243680954,
      0.04368457570672035,
      -0.013129467144608498,
      -0.014529268257319927,
      -0.026674337685108185,
      0.033981986343860626,
      -0.01926301419734955,
      -0.0512651763856411,
      0.009089404717087746,
      0.09650083631277084,
      -0.018672501668334007,
      -0.006526174955070019,
      -0.0021097001153975725,
      0.027039188891649246,
      0.05107452720403671,
      -0.036220911890268326,
      -0.032766081392765045,
      -0.007274962961673737,
      -0.022695692256093025,
      -0.04695683717727661,
      0.02159586362540722,
      -0.050513774156570435,
      0.02826162986457348,
      0.015803609043359756,
      -0.007281052879989147,
      0.028442248702049255,
      0.024863259866833687,
      0.004448361694812775,
      -0.003550424939021468,
      -0.019512705504894257,
      -0.009030958637595177,
      0.06559514999389648,
      0.028347769752144814,
      -0.06830132007598877,
      -0.022765040397644043,
      -0.02758559212088585,
      -0.027176158502697945,
      -0.0713663324713707,
      0.008598384447395802,
      -0.009190204553306103,
      -0.004642941057682037,
      -0.0074157072231173515,
      -0.013704965822398663,
      -0.06559400260448456,
      0.03261822462081909,
      0.06788806617259979,
      0.034095849841833115,
      -0.0640358254313469,
      -0.04747233912348747,
      0.027934936806559563,
      -0.09751047939062119,
      -0.04779903218150139,
      -0.08177150785923004,
      0.03356567397713661,
      0.0546332485973835,
      0.04771870747208595,
      -0.002762899734079838,
      -0.0559530071914196,
      -0.043980203568935394,
      -0.01224429439753294,
      -0.015286793000996113,
      -0.017450524494051933,
      -0.035616543143987656,
      0.00795037392526865,
      -0.029194138944149017,
      0.013227862305939198,
      0.0654299408197403,
      0.07794255763292313,
      -0.0868394672870636,
      0.015565206296741962,
      0.07579599320888519,
      -0.0467439629137516,
      0.017377274110913277,
      -0.03640010207891464,
      -0.023559320718050003,
      -0.011567831039428711,
      0.014070862904191017,
      -0.055920667946338654,
      -0.04843465983867645,
      -0.008976799435913563,
      0.01919057033956051,
      -0.09780879318714142,
      0.022051990032196045,
      -0.009704957716166973,
      0.060894109308719635,
      0.015598982572555542,
      0.05464107170701027,
      -0.051002874970436096,
      -0.030243737623095512,
      -0.0012739083031192422,
      0.0587947815656662,
      -0.009098198264837265,
      0.07944149523973465,
      0.03159063681960106,
      -0.052083879709243774,
      -0.0029881459195166826,
      0.0682857558131218,
      -0.007151284720748663,
      -0.027887096628546715,
      0.014008404687047005,
      0.03215282782912254,
      -0.052145276218652725,
      0.059768352657556534,
      -0.00955285970121622,
      -0.03075523115694523,
      0.03738740086555481,
      -0.004719902761280537,
      0.07565656304359436,
      -0.01300750207155943,
      0.006457535084336996,
      -0.05866196006536484,
      0.04062175750732422,
      0.001607223181053996,
      -0.06377492845058441,
      -0.04891183599829674,
      -0.06416063755750656,
      0.04758307710289955,
      0.027279099449515343,
      -0.01673414558172226,
      0.014437403529882431,
      0.09472084045410156,
      0.024424459785223007,
      -0.0408579558134079,
      0.0024949919898062944,
      0.1306440234184265,
      -0.03212627023458481,
      -0.06210329011082649,
      0.06808565557003021,
      -0.005920855328440666,
      0.013045781292021275,
      0.06212109327316284,
      -0.040952544659376144,
      0.0031324136070907116,
      -0.04973045736551285,
      -0.008453878574073315,
      0.049988314509391785,
      0.04450181871652603,
      -0.006338074803352356,
      0.018265843391418457,
      0.008910265751183033,
      -0.023040393367409706,
      -0.11319749057292938,
      0.015102654695510864,
      0.008998481556773186,
      0.05239453539252281,
      -0.01482785027474165,
      -0.018448779359459877,
      -0.04082957282662392,
      0.048658788204193115,
      -0.0025027343071997166,
      -0.02684846892952919,
      0.047563586384058,
      -0.020568357780575752,
      -0.0633828192949295,
      0.013644368387758732,
      0.05298706144094467,
      0.007250339724123478,
      -0.08021230250597,
      0.05889827758073807,
      0.04034137725830078,
      0.04516783729195595,
      0.01033367682248354,
      0.008651609532535076,
      -0.03266265243291855,
      -0.022394997999072075,
      -0.059982750564813614,
      -0.06943420320749283,
      0.029452180489897728,
      -0.06706580519676208,
      -0.047957032918930054,
      0.001475425437092781,
      -0.05214083194732666,
      0.056830715388059616,
      0.00750361243262887,
      0.021104492247104645,
      0.09100054204463959,
      -0.018888121470808983,
      0.05484766885638237,
      0.058166902512311935,
      -0.05820057541131973,
      0.01040179468691349,
      -0.04793476685881615,
      -0.024747604504227638,
      -0.01968366838991642,
      0.00465050246566534,
      0.02610275149345398,
      0.10552243143320084,
      0.05794182047247887,
      0.0956183597445488,
      0.00012765034625772387,
      -0.061741869896650314,
      0.007342533208429813,
      0.09968718141317368,
      -0.03749104589223862,
      -0.06287093460559845,
      -0.04594697058200836,
      -0.10625742375850677,
      0.07137017697095871,
      -0.05491742119193077,
      0.037200745195150375,
      0.06097090244293213,
      0.029001183807849884,
      -0.06253761053085327,
      0.010871308855712414,
      0.05890681594610214,
      0.027201393619179726,
      -0.017619986087083817,
      0.058941975235939026,
      -0.06545393913984299,
      -0.08888036012649536,
      0.025236940011382103,
      -0.0009007118060253561,
      -0.0666789785027504,
      0.07875345647335052,
      -0.013980763964354992,
      0.026680821552872658,
      -0.018597634509205818,
      -0.048169732093811035,
      0.06208626553416252,
      -0.04930615797638893,
      -0.001251186360605061,
      0.0093451589345932,
      0.03778376057744026,
      0.022516997531056404,
      0.02938818372786045,
      0.07386834174394608,
      -0.06811387836933136,
      0.034006234258413315,
      0.011553310789167881,
      -0.014661754481494427,
      -0.0450003445148468,
      0.0695066750049591,
      0.04847574606537819,
      0.034306518733501434,
      -0.04117146506905556,
      -0.027992630377411842,
      -0.05720258504152298,
      -0.028875725343823433,
      0.014974119141697884,
      0.06315134465694427,
      0.037698034197092056,
      -0.029567699879407883,
      0.007317212410271168,
      0.05422452837228775,
      0.0338451974093914,
      -0.011933399364352226,
      -0.009672455489635468,
      0.04737338796257973,
      0.03804849088191986,
      0.03690451756119728,
      -0.0408763661980629,
      -0.005179399624466896,
      0.028263013809919357,
      0.024516964331269264,
      -0.0470871739089489,
      0.04927186667919159,
      0.03529936447739601,
      0.023005718365311623,
      -0.036263417452573776,
      0.016229458153247833,
      0.08151207864284515,
      0.0347188301384449,
      -0.061353303492069244,
      -0.03706967085599899,
      0.020168153569102287,
      0.012724403291940689,
      -0.09075009822845459,
      0.04715669900178909,
      0.02012515813112259,
      0.008397112600505352,
      -0.09465146064758301,
      0.027350392192602158,
      -0.009878803975880146,
      0.05771590396761894,
      -0.012176907621324062,
      0.019511234015226364,
      0.012535628862679005,
      0.09003205597400665,
      0.020928572863340378,
      -0.0422353632748127,
      0.0030359725933521986,
      0.05933297798037529,
      0.0020244575571268797,
      0.0014553923392668366,
      0.09262355417013168,
      0.0008101160055957735,
      0.04340716451406479,
      -0.02367081120610237,
      0.10496953874826431,
      0.023847326636314392,
      -0.08983414620161057,
      0.08111663162708282,
      0.06803677976131439,
      -0.03207745775580406,
      0.01469789445400238,
      0.030796708539128304,
      -0.04211052879691124,
      -0.12533795833587646,
      -0.012310287915170193,
      0.031955379992723465,
      -0.04237387329339981,
      0.12087997049093246,
      0.011903118342161179,
      -0.0795256644487381,
      -0.024371273815631866,
      0.028262941166758537,
      0.02527715265750885,
      0.03657501935958862,
      -0.07714121788740158,
      0.017151694744825363,
      0.014584523625671864,
      0.05988333374261856,
      0.010095382109284401,
      0.02404789999127388,
      -0.0357607901096344,
      0.037383902817964554,
      -0.044853679835796356,
      0.0005926484009250998,
      -0.01508936658501625,
      -0.009182807989418507,
      0.014071527868509293,
      0.014360282570123672,
      0.01993114873766899,
      0.019522640854120255,
      -0.08053670823574066,
      0.0183864776045084,
      -0.01884167455136776,
      0.045969851315021515,
      0.02267208881676197,
      0.06444727629423141,
      -0.01015302911400795,
      -0.037812065333127975,
      -0.061678867787122726,
      0.0022131733130663633,
      -0.05525428056716919,
      0.05529464781284332,
      -0.021998586133122444,
      0.03498348593711853,
      0.03233304247260094,
      0.0211490411311388,
      -0.01158082578331232,
      0.02018316648900509,
      0.08666297048330307,
      0.0016804211772978306,
      -0.007706305477768183,
      0.03394760191440582,
      -0.012608272023499012,
      0.008432497270405293,
      0.0036491116043180227,
      -0.06315681338310242,
      -0.008027667179703712,
      0.0018598277820274234,
      0.028030486777424812,
      -0.0219589751213789,
      0.027808504179120064,
      0.01817169040441513,
      -0.05462069809436798,
      -0.05713801085948944,
      0.025353938341140747,
      -0.09102354198694229,
      0.03138527274131775,
      -0.010953865945339203,
      -0.010506239719688892,
      0.017092444002628326,
      0.03779781237244606,
      0.025661692023277283,
      0.037989888340234756,
      -0.011788908392190933,
      0.06318270415067673,
      -0.006741880439221859,
      -0.0016349229263141751,
      -0.028218060731887817,
      -0.02512715384364128,
      -0.04788209870457649,
      -0.017171673476696014,
      0.02270306833088398,
      0.033321186900138855,
      0.009140526875853539,
      0.028044329956173897,
      -0.02196475677192211,
      -0.07286617159843445,
      0.04748866707086563,
      -0.02377687580883503,
      -0.009335099719464779,
      0.04894537851214409,
      -0.046296626329422,
      -0.0003686320851556957,
      -0.04278107360005379,
      -0.052087198942899704,
      0.00025710376212373376,
      -0.0388764813542366,
      0.007657545153051615,
      -0.02512243390083313,
      0.013985642232000828,
      -0.027753980830311775,
      0.0003030678490176797,
      0.004059316124767065,
      -0.03548833355307579,
      0.006863317918032408,
      -0.006052324082702398,
      -0.02744171768426895,
      -0.006542911287397146,
      -0.010365098714828491,
      -0.11884317547082901,
      -0.03576032817363739
    ],
    [
      0.039683472365140915,
      0.04820090904831886,
      -0.04271429404616356,
      -0.010530390776693821,
      0.035591285675764084,
      0.01879083178937435,
      -0.04671522602438927,
      -0.029243851080536842,
      0.0011568338377401233,
      -0.11116649210453033,
      0.042611610144376755,
      0.05124139040708542,
      0.04018929973244667,
      -0.03765794262290001,
      -0.00625540129840374,
      -0.006392464507371187,
      -0.024100638926029205,
      0.07992861419916153,
      0.0017235688865184784,
      0.062282100319862366,
      -0.056047700345516205,
      0.008285900577902794,
      -0.019721735268831253,
      0.05083635821938515,
      -0.0004211397608742118,
      0.020921705290675163,
      -0.021845253184437752,
      -0.018951093778014183,
      0.020376363769173622,
      -0.024803780019283295,
      -0.00916979182511568,
      0.06723363697528839,
      -0.02833402529358864,
      0.06593912839889526,
      -0.033661119639873505,
      0.07152789831161499,
      -0.05088485777378082,
      -0.03783651813864708,
      0.005715090781450272,
      -0.05364541336894035,
      -0.031963419169187546,
      0.03352250158786774,
      0.06370964646339417,
      -0.058990757912397385,
      0.004246650729328394,
      -0.03366539254784584,
      0.012903907336294651,
      -0.06077325716614723,
      -0.07841544598340988,
      -0.005663800518959761,
      0.009797083213925362,
      0.003421575529500842,
      -0.04129818454384804,
      -0.05769505724310875,
      -0.017587320879101753,
      -0.03208910673856735,
      0.008944380097091198,
      -0.023888075724244118,
      -0.02576586790382862,
      0.010183574631810188,
      -0.01863246224820614,
      -0.012857917696237564,
      0.01842408999800682,
      -0.02284427545964718,
      0.00028969586128368974,
      -0.011689173988997936,
      0.03749413788318634,
      0.03546068072319031,
      0.00039440664113499224,
      0.04835336655378342,
      0.050537109375,
      0.009245802648365498,
      0.018165510147809982,
      -0.04542624577879906,
      -0.10732977092266083,
      -0.0504780039191246,
      -0.06086263433098793,
      -0.07304474711418152,
      0.05241066589951515,
      -0.013476265594363213,
      -0.039835672825574875,
      -0.02881767600774765,
      -0.030851036310195923,
      0.04612502455711365,
      0.0240587517619133,
      -0.04718596860766411,
      0.1096436157822609,
      -0.012569817714393139,
      -0.04873959720134735,
      0.05629463493824005,
      0.025704385712742805,
      0.06213127076625824,
      -0.013369869440793991,
      -0.049425240606069565,
      0.0663151666522026,
      -0.04888616502285004,
      -0.10269912332296371,
      -0.0024801671970635653,
      -0.03992978110909462,
      0.04115569591522217,
      -0.06285154819488525,
      0.014323013834655285,
      0.07531097531318665,
      0.012118547223508358,
      0.03979578614234924,
      0.0728510394692421,
      -0.00021561980247497559,
      0.010783462785184383,
      -0.054006438702344894,
      -0.11197200417518616,
      0.027312329038977623,
      -0.0010544615797698498,
      0.04719187319278717,
      0.0260661318898201,
      0.019350513815879822,
      0.07206584513187408,
      0.0012551943073049188,
      -0.03508434817194939,
      0.05694687366485596,
      -0.017032351344823837,
      -0.07069532573223114,
      -0.012682671658694744,
      0.028723789379000664,
      0.016333719715476036,
      0.007200879976153374,
      0.07942342758178711,
      0.0012993378331884742,
      -0.0002553009253460914,
      0.0026697427965700626,
      0.05782977491617203,
      0.03367127850651741,
      -0.016436845064163208,
      -0.045545052736997604,
      0.02003145031630993,
      -0.07964058965444565,
      -0.05740254744887352,
      0.009980576112866402,
      -0.00176731136161834,
      0.04204261675477028,
      0.006799125578254461,
      -0.046930983662605286,
      -0.029315654188394547,
      -0.014059496112167835,
      0.061071962118148804,
      -0.04548739641904831,
      -0.07548952102661133,
      0.031610578298568726,
      0.013774358667433262,
      -0.01781325973570347,
      0.027991846203804016,
      -0.046077318489551544,
      -0.04932897165417671,
      -0.09955551475286484,
      -0.005273693706840277,
      -0.0041127922013401985,
      0.01572842337191105,
      0.039193104952573776,
      0.06257399916648865,
      0.01430230401456356,
      -0.032558877021074295,
      0.01623530685901642,
      0.029192203655838966,
      0.014765473082661629,
      -0.10114256292581558,
      -0.027490701526403427,
      -0.02197280153632164,
      -0.021377908065915108,
      0.047911401838064194,
      -0.048111461102962494,
      -0.015930356457829475,
      -0.003284238977357745,
      -0.024191036820411682,
      0.020123735070228577,
      0.08907562494277954,
      -0.04862191155552864,
      -0.022954706102609634,
      -0.0011177873238921165,
      -0.05992385745048523,
      -0.0194415133446455,
      -0.0024109105579555035,
      -0.0027907865587621927,
      -0.03989948704838753,
      -0.006752314977347851,
      0.017461180686950684,
      -0.05058196187019348,
      -0.01923891343176365,
      -0.007854766212403774,
      -0.0433092936873436,
      -0.03844653442502022,
      0.01663541980087757,
      -0.005515547934919596,
      -0.055739112198352814,
      -0.014885667711496353,
      -0.053658802062273026,
      -0.005822946783155203,
      -0.055646661669015884,
      0.05027111619710922,
      -0.03895096480846405,
      0.08622561395168304,
      -0.0552077554166317,
      0.04180411994457245,
      0.009832757525146008,
      0.03142944350838661,
      -0.027178725227713585,
      0.03420937433838844,
      0.008359091356396675,
      0.0068431636318564415,
      -0.0038744560442864895,
      -0.007654729299247265,
      0.10179811716079712,
      -0.036602143198251724,
      0.04210487753152847,
      -0.01577502302825451,
      -0.07809317857027054,
      0.0008109784102998674,
      0.023166565224528313,
      0.024861404672265053,
      -0.05713682994246483,
      -0.0020200288854539394,
      -0.06484965234994888,
      0.04762379452586174,
      -0.019247833639383316,
      -0.039560724049806595,
      -0.03721444308757782,
      -0.026075026020407677,
      -0.057927120476961136,
      0.06187497824430466,
      0.011874137446284294,
      -0.07941244542598724,
      -0.012809563428163528,
      -0.028823181986808777,
      0.03742925077676773,
      0.03661748766899109,
      -0.013670368120074272,
      -0.015917425975203514,
      0.035517219454050064,
      -0.02813316509127617,
      -0.03499642014503479,
      0.046906888484954834,
      -0.024725912138819695,
      0.019745007157325745,
      -0.025918912142515182,
      0.004065952729433775,
      0.01687343791127205,
      -0.004660143982619047,
      0.06071435660123825,
      0.08699767291545868,
      -0.029895180836319923,
      -0.014413539320230484,
      -0.008928985334932804,
      0.020403802394866943,
      0.05186086520552635,
      -0.0327778086066246,
      -0.037443287670612335,
      0.04922576621174812,
      -0.010603928938508034,
      0.006184356287121773,
      0.01905008591711521,
      -0.004533346742391586,
      -0.13862158358097076,
      -0.015814825892448425,
      0.027047568932175636,
      0.0546061210334301,
      -0.014220915734767914,
      -0.06050283834338188,
      -0.024282369762659073,
      -0.01939237490296364,
      -0.021149376407265663,
      0.02698357217013836,
      0.02900853380560875,
      -0.011290572583675385,
      0.009166535921394825,
      -0.037247661501169205,
      -0.0434609092772007,
      0.03781178966164589,
      -0.06335869431495667,
      0.03180859982967377,
      0.026098668575286865,
      0.01720515452325344,
      -0.02484453283250332,
      0.041968800127506256,
      -0.019518960267305374,
      -0.006989987101405859,
      -0.023071186617016792,
      0.006125017534941435,
      0.018716981634497643,
      -0.018210304901003838,
      0.011769703589379787,
      0.04954589903354645,
      -0.023914137855172157,
      -0.006518762093037367,
      0.0804842859506607,
      0.027081221342086792,
      -0.02553533762693405,
      -0.0019122473895549774,
      -0.0668589398264885,
      0.02075881138443947,
      0.0725846067070961,
      -0.011415375396609306,
      -0.02631847932934761,
      -0.01516374945640564,
      0.037350524216890335,
      0.05968598276376724,
      0.0582936629652977,
      -0.00689765065908432,
      0.05375894531607628,
      0.033207375556230545,
      -0.10314048826694489,
      0.03782409802079201,
      -0.005560286808758974,
      -0.07772379368543625,
      -0.006945692468434572,
      0.042900558561086655,
      -0.010819237679243088,
      0.029566137120127678,
      0.03638744726777077,
      -0.00844912976026535,
      -0.03532516956329346,
      0.005698989611119032,
      0.08328818529844284,
      -0.04952576383948326,
      0.020490922033786774,
      -0.03275872766971588,
      -0.03765888512134552,
      0.008974682539701462,
      -0.03142036870121956,
      0.05135583505034447,
      -0.020125504583120346,
      -0.05315230414271355,
      -0.038290005177259445,
      0.030482055619359016,
      -0.0019822916947305202,
      0.0019319800194352865,
      0.009464822709560394,
      -0.00035954199847765267,
      0.01768486760556698,
      -0.08395674079656601,
      -0.026486823335289955,
      -0.06576475501060486,
      0.05070527270436287,
      0.03681044280529022,
      0.06369252502918243,
      0.13384923338890076,
      -0.04213744029402733,
      0.05769546329975128,
      0.005711344536393881,
      -0.038088008761405945,
      0.03496425598859787,
      0.03271980211138725,
      -0.0018457400146871805,
      -0.023338384926319122,
      -0.01455001626163721,
      -0.003705922979861498,
      0.028664417564868927,
      -0.09047157317399979,
      -0.07198438793420792,
      0.014167174696922302,
      -0.047349922358989716,
      -0.004689967259764671,
      0.0006216553156264126,
      0.024792106822133064,
      -0.06872936338186264,
      -0.0049298605881631374,
      0.016108011826872826,
      0.01721750572323799,
      0.002606358379125595,
      -0.15520453453063965,
      0.05529098957777023,
      -0.028103739023208618,
      -0.07487580925226212,
      -0.019716739654541016,
      -0.044414859265089035,
      -0.027647608891129494,
      -0.047701820731163025,
      -0.00942317582666874,
      0.037152525037527084,
      -0.009009091183543205,
      -0.010773036628961563,
      -0.0854673683643341,
      -0.005576275754719973,
      -0.037527184933423996,
      -0.0033726992551237345,
      -0.015397222712635994,
      0.13138347864151,
      -0.01204205583781004,
      -0.029076077044010162,
      0.06750362366437912,
      -0.03096596896648407,
      -0.014512689784169197,
      -0.08442050963640213,
      -0.025858407840132713,
      0.07821807265281677,
      0.0504174530506134,
      0.0813676118850708,
      0.023770077154040337,
      -0.062247950583696365,
      -0.019535688683390617,
      0.038120318204164505,
      0.08137399703264236,
      -0.040181126445531845,
      -0.07228047400712967,
      -0.050435636192560196,
      -0.029622862115502357,
      -0.04079986363649368,
      -0.06286357343196869,
      0.008738985285162926,
      -0.029871227219700813,
      0.018611252307891846,
      0.022672051563858986,
      0.052758168429136276,
      -0.025914927944540977,
      0.010725992731750011,
      0.033751580864191055,
      0.06283055990934372,
      -0.039372749626636505,
      0.036993611603975296,
      0.022072460502386093,
      0.03692212700843811,
      0.01293689850717783,
      -0.019002890214323997,
      -0.0005239629535935819,
      -0.013978144153952599,
      -0.010205348022282124,
      0.03771207109093666,
      -0.023969443514943123,
      -0.029982391744852066,
      0.015566281974315643,
      0.05629471316933632,
      -0.1365395039319992,
      0.07420843094587326,
      -0.023116400465369225,
      0.06372389942407608,
      0.048257976770401,
      -0.004648146219551563,
      0.07943373918533325,
      0.005942003335803747,
      -0.027480052784085274,
      0.05911124125123024,
      -0.002007793402299285,
      0.05622566118836403,
      0.04415767267346382,
      0.04549059271812439,
      0.06506889313459396,
      0.0294916070997715,
      0.04188365489244461,
      0.027543840929865837,
      -0.0052042556926608086,
      0.042963117361068726,
      0.0041387504898011684,
      -0.07720382511615753,
      -0.12668605148792267,
      0.10333498567342758,
      -0.008512778207659721,
      0.06066602095961571,
      -0.04964158684015274,
      0.017066750675439835,
      0.02595899999141693,
      0.0031030778773128986,
      0.048804741352796555,
      -0.017514370381832123,
      -0.0032339096069335938,
      0.14792251586914062,
      -0.06643524765968323,
      0.0661630779504776,
      -0.007027949206531048,
      -0.06348897516727448,
      0.04503309354186058,
      0.019007403403520584,
      0.000988628133200109,
      0.0038757321890443563,
      -0.031897660344839096,
      0.0031093419529497623,
      0.05956507846713066,
      -0.025629686191678047,
      -0.04330030456185341,
      -0.045174721628427505,
      0.0166840348392725,
      -0.031593624502420425,
      0.035538963973522186,
      -0.07848937809467316,
      0.031995292752981186,
      -0.057754501700401306,
      -0.007689863443374634,
      -0.051292359828948975,
      -0.03839508816599846,
      -0.039190564304590225,
      -0.016767926514148712,
      0.05395763739943504,
      -0.024083497002720833,
      0.050596099346876144,
      0.0168947521597147,
      0.03494064509868622,
      0.003365499433130026,
      -0.01574466936290264,
      -0.006103191990405321,
      0.04102719947695732,
      -0.02124388888478279,
      0.005831756163388491,
      -0.10549432784318924,
      0.010116606019437313,
      0.008973000571131706,
      -0.0004330854571890086,
      -0.014790738932788372
    ],
    [
      0.00294813746586442,
      0.0499640591442585,
      -0.005571576301008463,
      -0.056212663650512695,
      0.017706310376524925,
      0.10039860010147095,
      0.021535100415349007,
      -0.027669059112668037,
      -0.03677697479724884,
      0.014339608140289783,
      -0.0617838017642498,
      0.052037712186574936,
      0.01421815063804388,
      0.12306094914674759,
      0.011378701776266098,
      -0.015191626735031605,
      -0.014091908000409603,
      0.07524862885475159,
      -0.023157624527812004,
      -0.06551652401685715,
      -0.036065127700567245,
      0.03815548121929169,
      0.011845202185213566,
      -0.017669769003987312,
      0.01978113502264023,
      0.030382189899683,
      0.007291917689144611,
      -0.031054295599460602,
      -0.04717995971441269,
      0.027773063629865646,
      -0.08365264534950256,
      0.012516946531832218,
      0.04633945971727371,
      -0.044744059443473816,
      0.045556966215372086,
      -0.054251737892627716,
      0.032701339572668076,
      -0.04888530448079109,
      -0.007410005200654268,
      0.08388643711805344,
      -0.016531366854906082,
      -0.07779025286436081,
      -0.0394904799759388,
      -0.025310246273875237,
      0.020596250891685486,
      0.0684405192732811,
      0.02701362781226635,
      0.05002330616116524,
      -0.035654716193675995,
      0.039136964827775955,
      -0.07419469952583313,
      0.07944417744874954,
      0.012433556839823723,
      0.05606924369931221,
      0.0041654412634670734,
      -0.044743798673152924,
      0.013918268494307995,
      -0.01805754005908966,
      -0.04421659931540489,
      0.023764021694660187,
      0.06441809237003326,
      0.02310803346335888,
      -0.004050399176776409,
      0.04265135899186134,
      0.0008929208270274103,
      -0.024709949269890785,
      -0.02610357664525509,
      0.033711545169353485,
      -0.06457878649234772,
      0.014005963690578938,
      0.05056982487440109,
      0.04646722972393036,
      -0.02164512127637863,
      -0.008126317523419857,
      -0.023517325520515442,
      0.009673885069787502,
      0.007705505471676588,
      0.002114367438480258,
      -0.06641881167888641,
      0.05484087020158768,
      0.0486222505569458,
      -0.09973332285881042,
      0.06214182823896408,
      -0.01309976726770401,
      -0.010299130342900753,
      0.0009153860155493021,
      0.09371344745159149,
      0.07263313978910446,
      -0.09484017640352249,
      -0.02703273482620716,
      0.0040536983869969845,
      -0.007108351215720177,
      -0.0726737380027771,
      -0.02330397255718708,
      -0.024252930656075478,
      -0.017369596287608147,
      0.050984594970941544,
      0.012270557694137096,
      -0.03710247203707695,
      -0.02588152512907982,
      -0.07342211902141571,
      -0.016433119773864746,
      0.02581735886633396,
      0.018618740141391754,
      -0.01974971778690815,
      0.026243427768349648,
      -0.013638528995215893,
      0.04992249608039856,
      0.030489953234791756,
      -0.013967622071504593,
      0.10022836923599243,
      -0.00867456290870905,
      -0.014236629009246826,
      0.08340389281511307,
      0.00048153765965253115,
      0.016474874690175056,
      0.05756320059299469,
      0.03693811222910881,
      0.03456960991024971,
      0.018119899556040764,
      -0.0529276542365551,
      -0.05699688568711281,
      0.07470128685235977,
      0.004651972092688084,
      0.06701517105102539,
      0.0013618506491184235,
      -0.0332779735326767,
      -0.008337218314409256,
      0.016637742519378662,
      -0.00397819047793746,
      -0.015620782040059566,
      -0.05030332878232002,
      0.03207600116729736,
      0.024888891726732254,
      0.007925364188849926,
      0.0334048867225647,
      0.0322742685675621,
      0.014851804822683334,
      0.01663055829703808,
      -0.026017045602202415,
      -0.011092767119407654,
      0.0357542522251606,
      -0.012280749157071114,
      0.05878467857837677,
      0.002184612676501274,
      0.04020919278264046,
      0.006005682051181793,
      0.04041466861963272,
      0.01008619461208582,
      0.06424891948699951,
      -0.030158568173646927,
      -0.032222144305706024,
      -0.01473973412066698,
      -0.02991168573498726,
      -0.013711192645132542,
      -0.00259673735126853,
      0.04523641988635063,
      0.025362186133861542,
      0.017120910808444023,
      0.0001996275968849659,
      0.018375927582383156,
      0.009184757247567177,
      -0.004529482685029507,
      -0.010801092721521854,
      0.0062528252601623535,
      0.037867799401283264,
      0.0686713233590126,
      -0.02600073255598545,
      0.02621723897755146,
      -0.012937507592141628,
      -0.06692121177911758,
      0.007601228076964617,
      -0.027456942945718765,
      0.02636735700070858,
      0.0536625050008297,
      0.003374055726453662,
      -0.081105075776577,
      -0.15203320980072021,
      -0.011397960595786572,
      -0.006615920457988977,
      0.003967576660215855,
      -0.029965301975607872,
      0.01666182279586792,
      0.04343356937170029,
      -0.03392753005027771,
      0.03895353525876999,
      -0.03231118619441986,
      -0.08921048045158386,
      0.13544435799121857,
      0.03198866546154022,
      -0.006710655055940151,
      -0.080906942486763,
      0.05608735233545303,
      0.06323438137769699,
      -0.017829935997724533,
      0.03559662029147148,
      -0.034468378871679306,
      -0.07006644457578659,
      0.008180063217878342,
      0.008075534366071224,
      -0.009049413725733757,
      -0.025313690304756165,
      -0.08138594031333923,
      -0.006820801179856062,
      -0.06518220901489258,
      -0.015198493376374245,
      0.02768648788332939,
      0.008143415674567223,
      0.04443623125553131,
      0.017935296520590782,
      0.037323351949453354,
      -0.006808826234191656,
      -0.052815549075603485,
      -0.019497137516736984,
      0.03011763095855713,
      0.01990293338894844,
      0.04124283418059349,
      0.018766917288303375,
      0.007617721799761057,
      0.06854082643985748,
      0.038449592888355255,
      -0.0026638249401003122,
      -0.05672413110733032,
      -0.02086654305458069,
      -0.029731683433055878,
      0.02939968928694725,
      -0.012679609470069408,
      -0.0331999696791172,
      0.01539916917681694,
      0.00322269881144166,
      -0.022140147164463997,
      0.028874186798930168,
      -0.013515088707208633,
      -0.019039494916796684,
      -0.009333889931440353,
      -0.03759187087416649,
      -0.006447398103773594,
      0.06559861451387405,
      0.020211774855852127,
      -0.022010870277881622,
      -0.010411162860691547,
      -0.008429099805653095,
      -0.019565077498555183,
      0.006823670119047165,
      0.07350864261388779,
      -0.051851190626621246,
      -0.03194580227136612,
      0.023785583674907684,
      0.0780787318944931,
      -0.030311904847621918,
      -0.015622238628566265,
      0.04209901764988899,
      0.020276319235563278,
      -0.022801360115408897,
      0.07170789688825607,
      -0.050764478743076324,
      0.025193918496370316,
      0.01766069233417511,
      -0.029254715889692307,
      0.0516461618244648,
      0.019565975293517113,
      0.07303762435913086,
      0.10129987448453903,
      0.02656307816505432,
      -0.04950489103794098,
      -0.03481132537126541,
      -0.044396355748176575,
      -0.07859019190073013,
      0.01808920130133629,
      0.05470358952879906,
      0.044743649661540985,
      0.027580147609114647,
      0.06007010489702225,
      -0.0365363247692585,
      -0.0029490734450519085,
      -0.02864890918135643,
      0.07724996656179428,
      -0.00486211571842432,
      -0.02724905125796795,
      -0.008065522648394108,
      0.013070757500827312,
      0.012119093909859657,
      0.04516281560063362,
      0.07374031841754913,
      0.04727647453546524,
      -0.011650067754089832,
      -0.08709708601236343,
      -0.07671410590410233,
      -0.024575458839535713,
      -0.051855336874723434,
      -0.034232646226882935,
      -0.0026373914442956448,
      0.0032896986231207848,
      0.06984356790781021,
      -0.04048457369208336,
      -0.002217288129031658,
      -0.013061725534498692,
      -0.05607789382338524,
      -0.013990956358611584,
      0.00651567755267024,
      -0.020167183130979538,
      0.034443702548742294,
      0.023189101368188858,
      0.007101570256054401,
      -0.06589246541261673,
      0.014956103637814522,
      0.05229673162102699,
      -0.009328564628958702,
      -0.013213446363806725,
      -0.004689069930464029,
      0.015487800352275372,
      -0.0749712809920311,
      -0.06470848619937897,
      0.08658689260482788,
      0.0008887743460945785,
      -0.018851470202207565,
      -0.061001382768154144,
      -0.027201950550079346,
      -0.0011873989133164287,
      -0.03469202294945717,
      0.04770336300134659,
      -0.12789222598075867,
      0.04961858689785004,
      0.062473125755786896,
      0.07207809388637543,
      -0.030599119141697884,
      -0.01200081966817379,
      0.009053705260157585,
      -0.009182612411677837,
      0.007265038788318634,
      0.07275509834289551,
      -0.01210304070264101,
      -0.006610027980059385,
      0.02012946642935276,
      -0.054037924855947495,
      0.07602901756763458,
      0.018215373158454895,
      -0.025847923010587692,
      0.007152160629630089,
      0.03358059749007225,
      0.00898435153067112,
      0.0021052288357168436,
      -0.020543696358799934,
      -0.01197795756161213,
      -0.0038950059097260237,
      0.023820914328098297,
      -0.05050690472126007,
      0.08440269529819489,
      0.001812053844332695,
      0.05195500701665878,
      -0.00549103319644928,
      -0.029772093519568443,
      -0.05825391039252281,
      0.06040559709072113,
      -0.09511130303144455,
      0.03599848598241806,
      0.0017554601654410362,
      -0.02909572795033455,
      0.0731026828289032,
      0.001219738507643342,
      0.028388846665620804,
      -0.043171972036361694,
      0.03322175145149231,
      -0.061592500656843185,
      0.03470870107412338,
      0.0806417465209961,
      0.07436510920524597,
      -0.0065863169729709625,
      -0.04499247297644615,
      -0.14521756768226624,
      0.0030995330307632685,
      0.030337926000356674,
      -0.13414326310157776,
      0.023831093683838844,
      -0.0032514561899006367,
      0.008349037729203701,
      -0.06365218013525009,
      0.05673646181821823,
      -0.04798964411020279,
      -0.09341875463724136,
      0.02468026615679264,
      0.01583794318139553,
      -0.012587586417794228,
      0.025116639211773872,
      0.039968397468328476,
      0.03392152488231659,
      -0.0081637529656291,
      -0.04123846814036369,
      0.0479092076420784,
      0.01354970969259739,
      0.0312300194054842,
      -0.036016568541526794,
      0.020809605717658997,
      -0.06877519190311432,
      0.025045819580554962,
      -0.02988554537296295,
      -0.03992895409464836,
      -0.012519427575170994,
      0.08672372996807098,
      0.019627494737505913,
      -0.021732602268457413,
      -0.006184902973473072,
      0.06353481113910675,
      -0.02663184329867363,
      -0.0764155238866806,
      0.04048456996679306,
      0.016068490222096443,
      -0.02549041248857975,
      -0.0906534492969513,
      -0.01680193655192852,
      -0.022454118356108665,
      0.04576811566948891,
      0.019407454878091812,
      -0.017074689269065857,
      0.047765739262104034,
      0.01551190298050642,
      -0.03404926508665085,
      0.04580003768205643,
      -0.03750278800725937,
      0.02710845321416855,
      0.022868944332003593,
      -0.014952505007386208,
      0.046659938991069794,
      0.03320774435997009,
      -0.0014347047545015812,
      0.04783355072140694,
      0.031473997980356216,
      -0.07504468411207199,
      -0.0010254340013489127,
      -0.04657883569598198,
      -0.006167743820697069,
      -0.058753933757543564,
      -0.03219185024499893,
      0.06576193124055862,
      0.0704416036605835,
      -0.018519561737775803,
      0.04117512330412865,
      0.02831549383699894,
      0.06524030864238739,
      0.06634432077407837,
      -0.005001479759812355,
      0.09229564666748047,
      -0.025359557941555977,
      -0.013807328417897224,
      -0.0214612428098917,
      -0.007607088424265385,
      -0.09562453627586365,
      -0.05259593948721886,
      -0.006596889812499285,
      0.048103492707014084,
      -0.0038608936592936516,
      0.013709580525755882,
      0.022439124062657356,
      -0.09020251780748367,
      0.02330460585653782,
      -0.03420001268386841,
      0.10653292387723923,
      -0.01619020290672779,
      -0.04204739257693291,
      0.0522887222468853,
      -0.009940166026353836,
      0.08163896203041077,
      0.019727956503629684,
      0.07545783370733261,
      0.06866620481014252,
      0.004558261949568987,
      -0.060467708855867386,
      -0.06114714592695236,
      0.06375019997358322,
      -0.0456935353577137,
      0.052606407552957535,
      0.058727413415908813,
      0.02563031204044819,
      -0.04336798936128616,
      0.037180665880441666,
      -0.026434654369950294,
      -0.06852870434522629,
      0.016511008143424988,
      0.04697982966899872,
      0.04940923675894737,
      0.029555032029747963,
      0.047628819942474365,
      0.01877252198755741,
      0.00674311351031065,
      0.022724978625774384,
      0.0607459619641304,
      0.008163975551724434,
      0.038128986954689026,
      0.008525801822543144,
      0.00041312104440294206,
      0.03551124781370163,
      0.0354686938226223,
      0.11637462675571442,
      0.02797739766538143,
      -0.015688138082623482,
      -0.021739643067121506,
      -0.12648826837539673,
      -0.007741637993603945,
      -0.03678876534104347,
      0.04578784480690956,
      -0.016632206737995148,
      0.03584448620676994,
      0.04083114117383957
    ],
    [
      -0.007645476143807173,
      -0.016098856925964355,
      -0.03175264969468117,
      0.0751146599650383,
      -0.02724112756550312,
      -0.09424834698438644,
      -0.042368289083242416,
      0.0763227641582489,
      0.03597596660256386,
      -0.07510629296302795,
      0.03216847777366638,
      0.041832584887742996,
      -0.0024530068039894104,
      0.010300740599632263,
      0.030184553936123848,
      0.014130707830190659,
      -0.038883622735738754,
      -0.0606711246073246,
      -0.12300411611795425,
      0.004613191820681095,
      -0.04587605223059654,
      -0.07348277419805527,
      -0.08922813087701797,
      0.053323063999414444,
      0.023701243102550507,
      -0.04645512253046036,
      -0.09250634908676147,
      0.016691263765096664,
      0.037687040865421295,
      -0.03127029538154602,
      0.009320273995399475,
      -0.02526724524796009,
      0.044000331312417984,
      -0.021725263446569443,
      0.023667853325605392,
      -0.047234732657670975,
      -0.03444259613752365,
      0.047592081129550934,
      -0.05150897800922394,
      0.04265482351183891,
      -0.06077742576599121,
      0.04381028935313225,
      0.018839865922927856,
      0.049947284162044525,
      0.037166107445955276,
      0.03447568044066429,
      0.004272506572306156,
      -0.004989513196051121,
      -0.06501597911119461,
      0.03746333718299866,
      0.03946150466799736,
      -0.020386362448334694,
      -0.0308292955160141,
      0.013146363198757172,
      -0.025844300165772438,
      -0.002535975305363536,
      0.07413175702095032,
      0.051458850502967834,
      0.005709026474505663,
      -0.003750024363398552,
      -0.05627008527517319,
      0.02147234044969082,
      0.03397652879357338,
      0.053727079182863235,
      -0.009929509833455086,
      0.017612209543585777,
      0.011035976931452751,
      -0.01677856780588627,
      0.030623747035861015,
      -0.016077622771263123,
      -0.05470475181937218,
      0.040143903344869614,
      -0.01118863932788372,
      0.05105151608586311,
      0.034378018230199814,
      0.03247040882706642,
      -0.049429602921009064,
      0.01689624972641468,
      -0.06257955729961395,
      -0.009653751738369465,
      -0.0053711081854999065,
      0.025428038090467453,
      0.031675998121500015,
      0.04670390114188194,
      0.031057795509696007,
      0.08395922183990479,
      -0.04631057009100914,
      -0.006221590097993612,
      0.046146124601364136,
      -0.022263988852500916,
      0.04263295978307724,
      0.03390363231301308,
      -0.03962905704975128,
      -0.03146231546998024,
      0.10701903700828552,
      0.010074881836771965,
      -0.016682438552379608,
      0.041754428297281265,
      0.058204010128974915,
      0.022810401394963264,
      -0.026723822578787804,
      0.0074453274719417095,
      0.08248491585254669,
      0.020831812173128128,
      -0.04802834615111351,
      0.031201742589473724,
      -0.08078230917453766,
      0.08902598172426224,
      0.06260994076728821,
      0.037852853536605835,
      0.010115396231412888,
      -0.05953037738800049,
      -0.07742298394441605,
      -0.02747546136379242,
      -0.0574072003364563,
      -0.020668767392635345,
      0.007411155384033918,
      -0.048071231693029404,
      -0.06633912771940231,
      0.05796905234456062,
      0.004842086229473352,
      0.022737186402082443,
      -0.03255059942603111,
      -0.006757235620170832,
      0.009526929818093777,
      0.06483997404575348,
      0.03662848845124245,
      -0.02450876124203205,
      0.006968456786125898,
      0.02880961447954178,
      0.027694422751665115,
      -0.03854532539844513,
      -0.024013709276914597,
      -0.07031171768903732,
      0.03677292913198471,
      -0.0037223296239972115,
      0.010880223475396633,
      -0.024352602660655975,
      0.008309457451105118,
      -0.024615610018372536,
      -0.07234818488359451,
      -0.033147651702165604,
      -0.10131656378507614,
      -0.04362568259239197,
      -0.02457672730088234,
      -0.0019518035696819425,
      0.07557675987482071,
      -0.0036995841655880213,
      -0.06891591846942902,
      0.046194642782211304,
      -0.005614417139440775,
      -0.015999823808670044,
      -0.019715432077646255,
      -0.09338080137968063,
      -0.0028414675034582615,
      -0.06429718434810638,
      -0.02133597619831562,
      -0.04484514519572258,
      -0.06539195030927658,
      0.08541395515203476,
      0.09709113091230392,
      -0.007710583042353392,
      0.011764462105929852,
      -0.020287897437810898,
      0.059415291994810104,
      -0.02793169394135475,
      -0.007833322510123253,
      0.01117642316967249,
      0.06787464022636414,
      0.06732571870088577,
      -0.03446522727608681,
      -0.019909195601940155,
      -0.05938335880637169,
      0.05505335330963135,
      -0.043711643666028976,
      0.05590477213263512,
      0.050963062793016434,
      0.09104209393262863,
      -0.06643345206975937,
      -0.03530200943350792,
      -0.03010874055325985,
      -0.01570342853665352,
      0.06207341328263283,
      0.036491747945547104,
      -0.006823222618550062,
      0.009726795367896557,
      -0.041304998099803925,
      -0.055815938860177994,
      -0.015285264700651169,
      -0.04673352837562561,
      0.042348749935626984,
      0.027785200625658035,
      0.02482757717370987,
      0.01625927910208702,
      -0.05611957982182503,
      -0.07754810154438019,
      -0.00935322791337967,
      0.0487661249935627,
      -0.002818933455273509,
      0.03453020006418228,
      -0.045738957822322845,
      0.03685944899916649,
      0.04811901971697807,
      -0.03222969174385071,
      0.04748856648802757,
      0.04907006025314331,
      0.049542009830474854,
      -0.011104547418653965,
      0.024730680510401726,
      -0.010907241143286228,
      -0.08477960526943207,
      0.02774316817522049,
      -0.018769189715385437,
      0.01984022557735443,
      -0.01881898008286953,
      -0.008757516741752625,
      0.013479460030794144,
      -0.015957240015268326,
      -0.05909068509936333,
      -0.07106947898864746,
      -0.01814703829586506,
      -0.13853497803211212,
      0.008841468021273613,
      0.09399228543043137,
      -0.001350546721369028,
      -0.12940742075443268,
      0.025107495486736298,
      0.003863880643621087,
      -0.011414011009037495,
      -0.011801312677562237,
      -0.024166297167539597,
      0.06281691789627075,
      0.042989492416381836,
      -0.033878810703754425,
      0.02418690174818039,
      0.03221716359257698,
      0.021142790094017982,
      -0.029381290078163147,
      0.05963335931301117,
      0.06662320345640182,
      0.00603847112506628,
      0.032505493611097336,
      -0.024909203872084618,
      -0.011491012759506702,
      -0.045710619539022446,
      0.019898084923624992,
      -0.020801646634936333,
      0.005818195175379515,
      0.039665039628744125,
      -0.09007234126329422,
      -0.04446696862578392,
      0.009839857928454876,
      -0.018750907853245735,
      -0.056021276861429214,
      -0.02483079582452774,
      0.0284026600420475,
      0.1424177885055542,
      0.004939415957778692,
      -0.04911267012357712,
      0.01568700186908245,
      -0.008842656388878822,
      0.0038188626058399677,
      -0.026210159063339233,
      -0.0373767651617527,
      0.04568977653980255,
      -0.06462845206260681,
      -0.11101893335580826,
      0.013315467163920403,
      -0.0376533605158329,
      0.053314607590436935,
      -0.03587593138217926,
      -0.06292540580034256,
      0.002992208581417799,
      0.05170043557882309,
      0.01409216970205307,
      -0.008534717373549938,
      0.03282879292964935,
      -0.053627580404281616,
      0.12940658628940582,
      -0.013770275749266148,
      -0.011234183795750141,
      0.011965515092015266,
      0.048473481088876724,
      0.0926746055483818,
      -0.027003517374396324,
      0.023170070722699165,
      -0.003242629347369075,
      -0.06968829035758972,
      -0.039231278002262115,
      0.023146823048591614,
      -0.04776092618703842,
      0.001690319157205522,
      -0.027120156213641167,
      0.09420766681432724,
      0.033074844628572464,
      -0.10698484629392624,
      0.027966933324933052,
      0.016540076583623886,
      0.13827994465827942,
      0.02730613760650158,
      -0.0330997072160244,
      0.03233468160033226,
      0.01529351994395256,
      0.06789010763168335,
      -0.002058259444311261,
      -0.0753912404179573,
      0.038188349455595016,
      0.07550007849931717,
      0.00786543358117342,
      -0.056542761623859406,
      -0.08837459981441498,
      -0.03527790680527687,
      0.07106602191925049,
      0.006051299627870321,
      0.021207554265856743,
      -0.04035386070609093,
      0.009390289895236492,
      -0.023086095228791237,
      0.041641175746917725,
      0.08021237701177597,
      -0.013404414989054203,
      -0.0306765865534544,
      0.03878101333975792,
      -0.04756717383861542,
      0.0361526682972908,
      0.00919290166348219,
      -0.10433570295572281,
      0.07258359342813492,
      0.06807076185941696,
      -0.08935679495334625,
      0.030951563268899918,
      0.04917505383491516,
      0.08651647716760635,
      0.01548126433044672,
      -0.0012387941824272275,
      0.0006008800119161606,
      0.012648448348045349,
      -0.007155062165111303,
      0.023979244753718376,
      -0.015457984991371632,
      0.07004573196172714,
      -0.07152615487575531,
      -0.014397496357560158,
      0.080858014523983,
      0.010985986329615116,
      -0.01838204264640808,
      0.028514154255390167,
      -0.013163261115550995,
      0.06961511075496674,
      0.049023810774087906,
      -0.021631747484207153,
      0.11419883370399475,
      0.007589627522975206,
      -0.03431778401136398,
      0.0036373098846524954,
      0.02556426450610161,
      0.01097705215215683,
      -0.024205071851611137,
      0.09246276319026947,
      0.020342636853456497,
      0.02266566827893257,
      -0.022036295384168625,
      -0.01700606942176819,
      -0.09077627211809158,
      -0.025465907528996468,
      0.027386866509914398,
      -0.11137255281209946,
      -0.04656049981713295,
      0.01764252968132496,
      -0.008781993761658669,
      -0.013044103980064392,
      0.012759204022586346,
      0.008871442638337612,
      -0.037150390446186066,
      -0.01711556315422058,
      0.06809628009796143,
      0.025462910532951355,
      -0.004897415637969971,
      0.02369380183517933,
      0.07121875882148743,
      0.028398435562849045,
      0.02260875515639782,
      0.040226005017757416,
      0.0794512927532196,
      0.00960717536509037,
      -0.03380504623055458,
      -0.10873148590326309,
      -0.09944895654916763,
      -0.06285097450017929,
      0.04397561773657799,
      0.05664345249533653,
      -0.09981752187013626,
      0.05509563162922859,
      0.015375371091067791,
      -0.030129430815577507,
      0.012038758024573326,
      -0.07695663720369339,
      0.000673948263283819,
      -0.0317264124751091,
      -0.040712159126996994,
      -0.06435931473970413,
      -0.08142808824777603,
      0.041660115122795105,
      -0.06579305976629257,
      0.0023209063801914454,
      0.01236675027757883,
      0.068791963160038,
      -0.004885528236627579,
      0.051835041493177414,
      -0.0012118410086259246,
      0.03017139807343483,
      -0.028611766174435616,
      -0.04578796401619911,
      0.06618110835552216,
      -0.025813952088356018,
      0.0627467930316925,
      -0.022386418655514717,
      0.02422376349568367,
      -0.006942892447113991,
      0.02138344757258892,
      0.10520289838314056,
      0.03821519762277603,
      -0.005792743060737848,
      -0.00875831488519907,
      -0.00383621733635664,
      0.03546864911913872,
      -0.025353319942951202,
      -0.028639523312449455,
      -0.0314483679831028,
      -0.10221263766288757,
      -0.06407063454389572,
      0.10381165891885757,
      0.019720926880836487,
      0.05455736815929413,
      -0.03091374598443508,
      -0.004955017939209938,
      0.024562617763876915,
      0.032226476818323135,
      0.013634821400046349,
      0.02244257740676403,
      -0.011199724860489368,
      0.022361168637871742,
      0.12373308092355728,
      0.033460114151239395,
      0.005642252042889595,
      -0.02815381810069084,
      -0.04191787540912628,
      -0.010041655041277409,
      0.027966387569904327,
      0.04356358200311661,
      0.055389732122421265,
      -0.003289335872977972,
      0.05779605358839035,
      0.040772631764411926,
      -0.07098053395748138,
      -0.008410431444644928,
      0.01699523814022541,
      0.007247637957334518,
      -0.006165379658341408,
      -0.022512517869472504,
      -0.03537123277783394,
      0.09438227862119675,
      -0.030306342989206314,
      -0.040524765849113464,
      0.04361129552125931,
      -0.04036867246031761,
      -0.018706219270825386,
      -0.013393952511250973,
      0.0014089375035837293,
      0.01592511124908924,
      -0.024837234988808632,
      -0.06605060398578644,
      0.01810411736369133,
      0.055639948695898056,
      -0.018687844276428223,
      0.0028518245089799166,
      0.03622708469629288,
      -0.029449963942170143,
      0.06880326569080353,
      0.04605730623006821,
      0.018688326701521873,
      -0.03379445523023605,
      -0.02185741253197193,
      0.07072611153125763,
      0.06366315484046936,
      -0.08512404561042786,
      -0.02843872271478176,
      -0.020256025716662407,
      0.007979257963597775,
      -0.013276749290525913,
      0.00451212003827095,
      -0.007890118286013603,
      0.06397412717342377,
      0.06140340119600296,
      -0.0013824502239003778,
      -0.005206182133406401,
      0.022079644724726677,
      0.016805443912744522,
      -0.028762219473719597,
      -0.0642634704709053,
      0.028822213411331177,
      -0.014905141666531563,
      -0.022282633930444717
    ],
    [
      0.004422033205628395,
      -0.006746150087565184,
      -0.03347863256931305,
      -0.02013927325606346,
      0.011244191788136959,
      0.031701069325208664,
      -0.05704702064394951,
      0.01874232478439808,
      -0.055337708443403244,
      0.0026100799441337585,
      0.05474477633833885,
      -0.034646157175302505,
      0.04543367028236389,
      -0.008784242905676365,
      -0.07396387308835983,
      -0.023386985063552856,
      0.034426864236593246,
      -0.03503589332103729,
      0.005949227139353752,
      -0.11833882331848145,
      0.0252025555819273,
      -0.024694593623280525,
      -0.08298877626657486,
      0.00043339014519006014,
      0.004285596311092377,
      0.1312888264656067,
      0.08193498104810715,
      -0.03650851547718048,
      -0.010238371789455414,
      -0.04818061739206314,
      0.0587654672563076,
      -0.029566027224063873,
      -0.018901534378528595,
      0.013574257493019104,
      0.06667304784059525,
      0.09166638553142548,
      0.016267545521259308,
      -0.03217833861708641,
      0.03834858536720276,
      0.07495797425508499,
      0.006537549663335085,
      0.04409784823656082,
      0.037502024322748184,
      0.011909754015505314,
      -0.005691859871149063,
      -0.04698272794485092,
      0.01579897850751877,
      -0.04127281531691551,
      0.008755226619541645,
      -0.05544184893369675,
      -0.06759313493967056,
      0.029575135558843613,
      0.02368379943072796,
      0.007199306506663561,
      0.01886756718158722,
      -0.014551928266882896,
      -0.07203486561775208,
      0.043064676225185394,
      0.06799006462097168,
      -0.020628584548830986,
      -0.009343049488961697,
      0.039169322699308395,
      0.045415572822093964,
      -0.0787634551525116,
      -0.019419439136981964,
      -0.04316677898168564,
      0.10577816516160965,
      0.04913105070590973,
      -0.04799933359026909,
      0.00608785729855299,
      0.08472534269094467,
      -0.008602515794336796,
      0.026403870433568954,
      -0.015140552073717117,
      0.004398965742439032,
      0.03046816773712635,
      0.017941396683454514,
      0.031400036066770554,
      0.03703713044524193,
      0.03521144390106201,
      -0.023088321089744568,
      0.001312988460995257,
      -0.011394004337489605,
      -0.005638818256556988,
      0.036053650081157684,
      0.023516952991485596,
      0.0327899269759655,
      0.008888255804777145,
      -0.04243295267224312,
      -0.019524330273270607,
      0.0008684344356879592,
      -0.07729565352201462,
      0.05191674828529358,
      -0.04504688084125519,
      -0.0072684758342802525,
      -0.03650056570768356,
      0.012460323981940746,
      -0.023843588307499886,
      -0.005466129165142775,
      -0.01560454536229372,
      -0.016601761803030968,
      -0.013320338912308216,
      0.06451235711574554,
      0.0033920069690793753,
      0.034672416746616364,
      0.04353484511375427,
      -0.05654121935367584,
      0.013496248051524162,
      -0.00839246530085802,
      -0.023304997012019157,
      0.010069508105516434,
      0.021822704002261162,
      0.007982770912349224,
      -0.05982368811964989,
      -0.020584627985954285,
      0.060142967849969864,
      0.04344848915934563,
      -0.01164759136736393,
      0.02511872723698616,
      -0.03545055910944939,
      -0.08661425858736038,
      0.010619266889989376,
      0.02178291045129299,
      0.07210598886013031,
      0.056469980627298355,
      -0.06332981586456299,
      -0.07915078103542328,
      0.009907562285661697,
      0.030779307708144188,
      0.02956780418753624,
      -0.015595012344419956,
      -0.14505091309547424,
      -0.05028701201081276,
      0.030507046729326248,
      -0.013578993268311024,
      0.07589107006788254,
      0.02221568487584591,
      0.0004141635727137327,
      -0.0059643215499818325,
      -0.024033254012465477,
      -0.021910062059760094,
      -0.09955548495054245,
      0.009633744135499,
      -0.016377868130803108,
      0.027093349024653435,
      0.02347756363451481,
      -0.03862862288951874,
      -0.04070494696497917,
      -0.06463804841041565,
      -0.06659185886383057,
      0.04776635393500328,
      0.0010267378529533744,
      0.05719376355409622,
      0.03689756616950035,
      -0.07795195281505585,
      -0.011754786595702171,
      0.01930166222155094,
      0.009473767131567001,
      0.04629858210682869,
      0.06688320636749268,
      0.013068373315036297,
      0.020331598818302155,
      0.001795176649466157,
      -0.007946294732391834,
      -0.13753892481327057,
      0.008276800625026226,
      -0.03738943859934807,
      0.09520982950925827,
      -0.011455997824668884,
      -0.08638454973697662,
      -0.008732994087040424,
      0.03360830247402191,
      -0.030990682542324066,
      0.002036271383985877,
      0.016545629128813744,
      0.10934685915708542,
      0.06704819947481155,
      0.02005327306687832,
      -0.02965281903743744,
      -0.0647207498550415,
      0.08539144694805145,
      -0.029020601883530617,
      0.003275158815085888,
      0.016037877649068832,
      0.03246039152145386,
      0.0178128220140934,
      0.01982778310775757,
      -0.042748626321554184,
      0.08018489181995392,
      -0.05077425390481949,
      -0.03961944580078125,
      0.010634474456310272,
      -0.07520907372236252,
      0.004814836662262678,
      -0.005398083478212357,
      0.07673236727714539,
      0.06506894528865814,
      -0.06687846034765244,
      -0.04080783948302269,
      -0.02681841515004635,
      0.05752887204289436,
      -0.020461587235331535,
      0.01550514716655016,
      0.034713368862867355,
      0.006185279227793217,
      -0.034688688814640045,
      0.03917788341641426,
      0.0033096359111368656,
      -0.02249065414071083,
      0.03046860359609127,
      0.07431048899888992,
      0.03588945418596268,
      0.0024333258625119925,
      0.014518334530293941,
      0.07620806992053986,
      0.040381237864494324,
      -0.015433755703270435,
      0.10033174604177475,
      0.13474969565868378,
      0.018736733123660088,
      0.050335995852947235,
      -0.012171146459877491,
      0.03492442145943642,
      0.026756800711154938,
      -0.017435330897569656,
      0.018740322440862656,
      -0.025013938546180725,
      0.0435500405728817,
      -0.05852162465453148,
      -0.023024633526802063,
      0.046156592667102814,
      0.05585683509707451,
      -0.0206892192363739,
      0.0969991534948349,
      0.07486697286367416,
      -0.044776517897844315,
      -0.014851526357233524,
      -0.014827930368483067,
      -0.0732727199792862,
      0.07596386224031448,
      -0.02250281348824501,
      -0.0064508188515901566,
      0.0030361225362867117,
      -0.019633792340755463,
      0.0005812758463434875,
      -0.015744958072900772,
      0.06155989319086075,
      0.009725648909807205,
      0.03559766337275505,
      0.06211194768548012,
      0.05173446238040924,
      -0.04684026166796684,
      0.004305974580347538,
      -0.019582444801926613,
      -0.04137389734387398,
      -0.021500900387763977,
      -0.021932289004325867,
      0.04837646335363388,
      0.009295713156461716,
      -0.01958858221769333,
      0.02323000691831112,
      -0.05582328513264656,
      0.043026793748140335,
      0.05080889165401459,
      0.0345769077539444,
      0.037673357874155045,
      0.03094319812953472,
      0.06623773276805878,
      0.07571601867675781,
      0.04105861857533455,
      -0.042239706963300705,
      -0.06769225746393204,
      0.025968970730900764,
      0.01874816231429577,
      -0.03611280769109726,
      -0.06331842392683029,
      0.1107809767127037,
      -0.03233274444937706,
      0.014923340640962124,
      -0.05858166143298149,
      0.07269740849733353,
      -0.0868072360754013,
      0.014742273837327957,
      0.014152482151985168,
      -0.018508072942495346,
      0.019131876528263092,
      -0.029779357835650444,
      0.08572423458099365,
      0.06763564050197601,
      0.024714753031730652,
      0.03838088735938072,
      0.04778977110981941,
      0.013408293947577477,
      0.004197454545646906,
      -0.06106295436620712,
      -0.04950297623872757,
      0.035825274884700775,
      -0.06861616671085358,
      -0.03602074086666107,
      0.017562858760356903,
      0.0435834601521492,
      -0.017472470179200172,
      -0.03725339099764824,
      0.007870402187108994,
      -0.0670744851231575,
      0.014271026477217674,
      0.015796387568116188,
      -0.02110423892736435,
      -0.06553163379430771,
      0.07397513091564178,
      -0.04039035737514496,
      0.08771095424890518,
      -0.032774120569229126,
      -0.03801633417606354,
      -0.01112971268594265,
      0.023203590884804726,
      -0.06273102015256882,
      0.014808758161962032,
      -0.07549646496772766,
      -0.022281426936388016,
      0.05159981921315193,
      -0.049926917999982834,
      -0.03387375921010971,
      -0.008418193086981773,
      0.03410816565155983,
      0.011185850016772747,
      -0.09500527381896973,
      0.023169225081801414,
      0.05474872887134552,
      0.021641399711370468,
      0.04350760206580162,
      -0.048353277146816254,
      0.01423786859959364,
      -0.011517064645886421,
      0.05621623247861862,
      -0.027641940861940384,
      0.07147804647684097,
      -0.0035492973402142525,
      0.09535630792379379,
      -0.02333725243806839,
      -0.08870452642440796,
      -0.06964462995529175,
      0.05635727941989899,
      0.00868851039558649,
      0.03798985853791237,
      -0.0037536276504397392,
      0.04008353129029274,
      -0.011472076177597046,
      -0.06321845948696136,
      0.021982979029417038,
      0.0045644487254321575,
      0.006354592274874449,
      0.015940094366669655,
      0.057002004235982895,
      -0.009827292524278164,
      -0.03551730513572693,
      0.05424725264310837,
      -0.033121902495622635,
      0.03870966285467148,
      -0.020115962252020836,
      -0.04000559449195862,
      0.027950532734394073,
      -0.03261413797736168,
      -0.04352738335728645,
      -0.023438937962055206,
      -0.025723859667778015,
      0.06952343881130219,
      0.08490181714296341,
      -0.046492259949445724,
      0.00017498615488875657,
      -0.025771643966436386,
      0.00750230485573411,
      -0.006998096592724323,
      -0.03845267370343208,
      0.06370866298675537,
      -0.04200289025902748,
      0.06120114400982857,
      0.01570044457912445,
      0.06407500803470612,
      -0.02284901589155197,
      -0.03697580471634865,
      -0.03692670539021492,
      0.0542193278670311,
      -0.018578460440039635,
      -0.09508264064788818,
      -0.018678540363907814,
      -0.04332616552710533,
      0.018803060054779053,
      0.0628383681178093,
      0.06264135986566544,
      -0.06887327134609222,
      -0.05607610195875168,
      0.02260819636285305,
      0.01946452632546425,
      0.03407665714621544,
      -0.06860463321208954,
      0.045333702117204666,
      -0.08282863348722458,
      -0.0325123593211174,
      -0.009994355961680412,
      0.025232799351215363,
      0.044724661856889725,
      0.044158052653074265,
      0.057352837175130844,
      -0.06905321776866913,
      -0.004259035456925631,
      0.02731928415596485,
      0.021861569955945015,
      0.04145050048828125,
      -0.0545237697660923,
      -0.03197565674781799,
      0.009964125230908394,
      0.014870749786496162,
      -0.11569023132324219,
      -0.021965941414237022,
      0.04255475848913193,
      -0.03716055676341057,
      0.05557641014456749,
      -0.06749361008405685,
      0.0829375758767128,
      -0.028062598779797554,
      -0.01503240317106247,
      0.023613901808857918,
      -0.0354732871055603,
      -0.02990245819091797,
      -0.014795022085309029,
      -0.031408049166202545,
      -0.01948751136660576,
      -0.033647798001766205,
      0.010739841498434544,
      -0.029199205338954926,
      0.07035202533006668,
      0.02099069580435753,
      -0.03679094836115837,
      0.0678987130522728,
      0.06163760647177696,
      -0.03788107633590698,
      0.020062487572431564,
      -0.024665916338562965,
      0.06301459670066833,
      -0.025084523484110832,
      -0.02049669809639454,
      0.02803787961602211,
      -0.03393706679344177,
      -0.057451654225587845,
      0.04967199265956879,
      -0.013839326798915863,
      0.0104698920622468,
      0.03230736032128334,
      0.0069776056334376335,
      -0.034251078963279724,
      0.030325734987854958,
      0.016493577510118484,
      -0.047343142330646515,
      -0.01799651049077511,
      -0.004373449832201004,
      -0.06617400795221329,
      0.015145755372941494,
      0.024448459967970848,
      -0.05724680796265602,
      0.00923048797994852,
      0.06644150614738464,
      -0.04761992767453194,
      -0.041488777846097946,
      0.010847237892448902,
      0.012097536586225033,
      0.06309826672077179,
      -0.03926420584321022,
      -0.015970949083566666,
      -0.02090773731470108,
      0.03415849059820175,
      -0.03096887841820717,
      0.004407876171171665,
      -0.06215706095099449,
      0.042507104575634,
      -0.010590842925012112,
      -0.06290318816900253,
      -0.09396450966596603,
      0.10321745276451111,
      0.03984564170241356,
      0.022723451256752014,
      -0.0472940094769001,
      0.008785964921116829,
      0.008654208853840828,
      -0.014035155065357685,
      0.07181185483932495,
      0.084679014980793,
      0.03317749872803688,
      0.03722783923149109,
      0.04092472791671753,
      0.04714374616742134,
      -0.014821702614426613,
      0.004544128198176622,
      0.0076773506589233875,
      0.04593207687139511,
      0.024801306426525116,
      -0.004414187744259834,
      0.027181338518857956,
      0.03830317407846451,
      0.070854552090168,
      0.004560056608170271,
      -0.011697493493556976,
      -0.00380495167337358
    ],
    [
      0.044231630861759186,
      0.07067080587148666,
      0.0048096440732479095,
      -0.056026384234428406,
      -0.053917549550533295,
      -0.0511997789144516,
      0.04775362089276314,
      -0.06068236753344536,
      -0.030568236485123634,
      -0.06961856037378311,
      0.04477311298251152,
      -0.0384383350610733,
      0.024661960080266,
      0.02111206203699112,
      0.06558104604482651,
      0.0076680863276124,
      -0.05329260602593422,
      0.03939434513449669,
      -0.06381157785654068,
      0.0007807337096892297,
      0.00966310128569603,
      0.01044508907943964,
      -0.04513082280755043,
      -0.0358966700732708,
      0.0697394460439682,
      0.14312972128391266,
      -0.01465520728379488,
      -0.05212050676345825,
      -0.017827367410063744,
      -0.012382116168737411,
      0.02995588257908821,
      -0.0040037319995462894,
      -0.04757857322692871,
      0.026661410927772522,
      -0.05119539797306061,
      -0.00957607664167881,
      -0.10186716914176941,
      -0.09427013248205185,
      -0.032130397856235504,
      0.022859081625938416,
      -0.05992335081100464,
      0.02580970712006092,
      0.10545551031827927,
      0.04804527759552002,
      -0.01498544029891491,
      0.003313965629786253,
      -0.010538389906287193,
      -0.06371554732322693,
      0.0007721714209765196,
      0.023161858320236206,
      -0.08737760037183762,
      -0.003058074973523617,
      0.028747787699103355,
      0.06903719156980515,
      0.02692735381424427,
      0.10238144546747208,
      0.026637673377990723,
      -0.013928432017564774,
      -0.018116207793354988,
      -0.004844235721975565,
      -0.05291812866926193,
      -0.0033510918729007244,
      -0.04902347922325134,
      0.02099960297346115,
      0.02160157822072506,
      -0.006991710979491472,
      0.03683507442474365,
      -0.014238795265555382,
      0.009393625892698765,
      -0.0030099786818027496,
      -0.009310675784945488,
      -0.013934864662587643,
      -0.03734211623668671,
      -0.016797415912151337,
      -0.015049354173243046,
      0.014549062587320805,
      0.012855703011155128,
      -0.011083055287599564,
      -0.036662064492702484,
      0.0490272082388401,
      -0.05872791260480881,
      -0.00947157945483923,
      -0.02525516413152218,
      -0.01822856068611145,
      0.030525583773851395,
      0.001259690965525806,
      -0.012936333194375038,
      0.014774100854992867,
      0.0032399229239672422,
      -0.011146296747028828,
      0.057530589401721954,
      0.0030883175786584616,
      0.03788726031780243,
      -0.07047535479068756,
      0.062361329793930054,
      -0.05708981677889824,
      0.023466236889362335,
      -0.07013705372810364,
      0.007443442940711975,
      0.045862503349781036,
      -0.007031397894024849,
      0.01848265528678894,
      0.061973270028829575,
      0.023815516382455826,
      -0.05053826421499252,
      0.030769087374210358,
      0.008265865966677666,
      -0.012611395679414272,
      0.011622652411460876,
      -0.05358387157320976,
      0.010815034620463848,
      0.02211487479507923,
      0.07515981048345566,
      0.03559868782758713,
      0.025353873148560524,
      -0.019683165475726128,
      -0.03575741872191429,
      -0.05782468244433403,
      0.05002656579017639,
      -0.007039998192340136,
      -0.0044595105573534966,
      0.03502441570162773,
      -0.027372071519494057,
      -0.03370976448059082,
      -0.03983841463923454,
      0.014463606290519238,
      0.056216802448034286,
      -0.0006149408873170614,
      0.0072706518694758415,
      0.06528288125991821,
      0.0706632062792778,
      -0.02242126502096653,
      -0.05140556022524834,
      0.086969755589962,
      0.01886269636452198,
      -0.023805569857358932,
      0.08795903623104095,
      0.026982666924595833,
      0.0376693531870842,
      0.034829337149858475,
      0.0732569471001625,
      -0.02006261795759201,
      0.11452063918113708,
      0.08605076372623444,
      0.0339890792965889,
      -0.02115427516400814,
      -0.08940679579973221,
      0.015290326438844204,
      -0.018279027193784714,
      -0.05365123599767685,
      0.0223194919526577,
      -0.00045422391849569976,
      -0.02025802619755268,
      0.09997092932462692,
      0.04465764760971069,
      -0.01912793517112732,
      0.08626867085695267,
      -0.08861172199249268,
      -0.05603759363293648,
      0.011172940954566002,
      -0.022471031174063683,
      -0.01956735923886299,
      -0.03887460008263588,
      0.007116445805877447,
      -0.06419187784194946,
      0.05907434597611427,
      -0.027079664170742035,
      0.024543682113289833,
      -0.04460446164011955,
      0.006651302799582481,
      -0.038266971707344055,
      0.003551938571035862,
      -0.055653300136327744,
      -0.028042040765285492,
      0.014938072301447392,
      0.0001968505239346996,
      0.04631026089191437,
      -0.0255047045648098,
      0.028165491297841072,
      -0.07884122431278229,
      0.0335821732878685,
      -0.0286137405782938,
      0.05362853780388832,
      0.0605723038315773,
      0.05787808075547218,
      0.06903591006994247,
      -0.019674714654684067,
      -0.04614530876278877,
      0.02109418623149395,
      0.004997581709176302,
      -0.007774361874908209,
      0.05357830226421356,
      -0.0941704660654068,
      0.034862346947193146,
      0.011244204826653004,
      -0.003990793600678444,
      0.017452582716941833,
      0.05789310857653618,
      -0.03214931860566139,
      -0.031343407928943634,
      0.030573297291994095,
      0.033367231488227844,
      -0.055932629853487015,
      -0.014523857273161411,
      -0.02224809117615223,
      0.017914151772856712,
      -0.06023649498820305,
      0.02224508300423622,
      -0.03542441874742508,
      -0.0722278505563736,
      0.024554967880249023,
      0.033739123493433,
      -0.011874434538185596,
      -0.010669057257473469,
      0.03629891201853752,
      -0.05955987051129341,
      -0.07571250945329666,
      -0.09583401679992676,
      -0.018148010596632957,
      -0.01748732104897499,
      0.03888900205492973,
      -0.02133527584373951,
      -0.1011330708861351,
      0.025713102892041206,
      -0.005145796108990908,
      -0.07137683779001236,
      0.008143176324665546,
      0.017968472093343735,
      -0.036219142377376556,
      -0.06055418401956558,
      0.011989312246441841,
      0.0007770780357532203,
      0.0548468716442585,
      -0.022855497896671295,
      -0.01897009089589119,
      -0.022540202364325523,
      -0.01946951635181904,
      0.039795659482479095,
      0.013861870393157005,
      -0.03792485222220421,
      -0.0017843111418187618,
      0.0099911168217659,
      -0.0022116652689874172,
      0.02438279241323471,
      -0.017310066148638725,
      0.04149240627884865,
      0.016999321058392525,
      0.08988434821367264,
      0.007461299654096365,
      0.0022063986398279667,
      -0.024381980299949646,
      -0.029224837198853493,
      -0.02831689827144146,
      0.01303619984537363,
      0.06149350479245186,
      0.05535505712032318,
      -0.06174110248684883,
      -0.05500229820609093,
      0.044761594384908676,
      0.0003223838866688311,
      0.009123400785028934,
      0.046343252062797546,
      0.0025457467418164015,
      -0.013182098045945168,
      0.009700045920908451,
      0.018627021461725235,
      -0.06664743274450302,
      0.013723816722631454,
      0.0112124839797616,
      -0.0629066601395607,
      0.03656001761555672,
      -0.09783454984426498,
      -0.020019792020320892,
      0.0009720703819766641,
      0.030472619459033012,
      -0.05644854158163071,
      -0.004988218657672405,
      0.004053151234984398,
      -0.07316930592060089,
      -0.003661246970295906,
      0.01854282058775425,
      -0.05029373615980148,
      -0.057254642248153687,
      0.02979704737663269,
      -0.0142075689509511,
      -0.011298331432044506,
      -0.0015626163221895695,
      0.03783268481492996,
      0.04979913681745529,
      -0.07136368751525879,
      0.023530928418040276,
      0.01531756017357111,
      -0.03443833440542221,
      -0.07940331101417542,
      -0.12164092808961868,
      0.02683544158935547,
      -0.03639562427997589,
      0.031189700588583946,
      0.04023463651537895,
      -0.03267112001776695,
      0.006856548599898815,
      -0.0421004518866539,
      -0.029822107404470444,
      -0.019098082557320595,
      -0.03917913883924484,
      -0.01891903579235077,
      0.005826652515679598,
      -0.0027136553544551134,
      0.010247473604977131,
      -0.03727894648909569,
      0.043472252786159515,
      -0.0033387921284884214,
      -0.03669817000627518,
      -0.02788122184574604,
      -0.09152232855558395,
      0.012744219973683357,
      -0.045552872121334076,
      0.03575079143047333,
      -0.027370337396860123,
      0.05979858338832855,
      0.03272445127367973,
      -0.009889962151646614,
      0.013828624039888382,
      0.054841868579387665,
      -0.015025468543171883,
      -0.02131408266723156,
      0.05126207694411278,
      -0.03764113411307335,
      -0.05531292036175728,
      -0.043543532490730286,
      -0.043994806706905365,
      0.0009078230941668153,
      0.03337804228067398,
      -0.036843057721853256,
      -0.07610177993774414,
      0.023667192086577415,
      0.06824907660484314,
      -0.04785614833235741,
      0.01676277071237564,
      0.011414659209549427,
      -0.03643619269132614,
      -0.009511137381196022,
      -0.04608820378780365,
      0.0037035730201750994,
      0.025053899735212326,
      0.010238989256322384,
      0.00247597717680037,
      0.12710873782634735,
      -0.0023118730168789625,
      -0.057393964380025864,
      -0.07714997231960297,
      -0.04990183934569359,
      0.060637619346380234,
      0.002990659326314926,
      -0.044662609696388245,
      0.015569447539746761,
      0.042205143719911575,
      -0.005570598877966404,
      0.02322424203157425,
      0.02508964203298092,
      0.05361150577664375,
      0.007300868164747953,
      -0.03472292423248291,
      -0.027931123971939087,
      0.01520979218184948,
      -0.06389601528644562,
      0.007655472960323095,
      -0.024398718029260635,
      -0.03892827033996582,
      0.059038709849119186,
      -0.007024356164038181,
      -0.029205115512013435,
      0.04830610379576683,
      0.08088164031505585,
      0.06639144569635391,
      -0.07406937330961227,
      -0.03027249313890934,
      0.01885414682328701,
      0.022683272138237953,
      0.08255241811275482,
      -0.025279782712459564,
      -0.08333096653223038,
      -0.022520842030644417,
      -0.018680619075894356,
      0.007911034859716892,
      0.009564395993947983,
      0.021971924230456352,
      0.056810565292835236,
      0.02246825024485588,
      -0.028832806274294853,
      -0.022112330421805382,
      0.07036256045103073,
      -0.005247667897492647,
      0.021854549646377563,
      -0.08411090821027756,
      0.07487650215625763,
      0.02107800915837288,
      0.052060872316360474,
      -0.06661409884691238,
      -0.0026912984903901815,
      0.07970549166202545,
      -0.03885725140571594,
      0.05220883712172508,
      0.015589352697134018,
      0.045455969870090485,
      -0.05067107081413269,
      -0.014051923528313637,
      -0.0030297732446342707,
      0.022867539897561073,
      0.006614810787141323,
      0.03254907205700874,
      0.035883575677871704,
      -0.046524032950401306,
      -0.009839925915002823,
      -0.047027911990880966,
      -0.04961059242486954,
      0.0297348964959383,
      -0.004271704703569412,
      0.02568223886191845,
      0.01039266400039196,
      0.03346123918890953,
      0.030337657779455185,
      0.05571405589580536,
      0.019226549193263054,
      -0.013734759762883186,
      -0.04292242228984833,
      0.03295530378818512,
      -0.034068744629621506,
      -0.05128280818462372,
      -0.08321031183004379,
      0.03199407458305359,
      -0.06626207381486893,
      -0.0026851072907447815,
      -0.02035006321966648,
      -0.04740714281797409,
      0.014402422122657299,
      -0.036241836845874786,
      0.03830130770802498,
      0.033163588494062424,
      -0.058739278465509415,
      -0.0016086541581898928,
      0.009603878483176231,
      -0.031229011714458466,
      -0.03286878019571304,
      -0.012337262742221355,
      0.03660966455936432,
      0.056210506707429886,
      0.06658734381198883,
      0.03663726523518562,
      -0.1031896322965622,
      0.03198251128196716,
      -0.018754860386252403,
      -0.051071472465991974,
      -0.047497205436229706,
      0.00320278387516737,
      0.037513572722673416,
      -0.015252589248120785,
      -0.040875647217035294,
      -0.018929429352283478,
      0.0891939327120781,
      -0.019297346472740173,
      0.03476116806268692,
      -0.0016760703874751925,
      -0.035415977239608765,
      -0.002871564356610179,
      -0.02559877745807171,
      0.0006722926045767963,
      -0.08651993423700333,
      -0.05649959668517113,
      -0.0222912710160017,
      0.06371459364891052,
      0.040033359080553055,
      0.018275205045938492,
      -0.017130009829998016,
      0.0023553844075649977,
      0.013455848209559917,
      -0.08445248007774353,
      -0.019450156018137932,
      0.09605851769447327,
      0.013287846930325031,
      -0.035282548516988754,
      0.0012678313069045544,
      -0.017090795561671257,
      0.005390152335166931,
      -0.014452455565333366,
      -0.03975663334131241,
      -0.04549054056406021,
      0.04486149549484253,
      0.010846978053450584,
      -0.009923063218593597,
      0.003920287359505892,
      -0.007764438167214394,
      -0.01699524000287056,
      -0.04229358956217766,
      0.012154730036854744,
      0.025044076144695282,
      -0.02836979366838932,
      -0.009959823451936245,
      0.007136427331715822,
      0.005063961260020733,
      -0.059398964047431946,
      0.004116222262382507,
      -0.03202757239341736
    ],
    [
      0.05370241776108742,
      -0.08164701610803604,
      0.04651479795575142,
      -0.013533458113670349,
      0.05119314789772034,
      -0.011622271500527859,
      -0.02034931816160679,
      -0.008506063371896744,
      0.024367740377783775,
      0.05291648954153061,
      -0.028493791818618774,
      0.052466534078121185,
      -0.057421792298555374,
      0.06169882416725159,
      -0.004088880494236946,
      -0.049905624240636826,
      0.0024708914570510387,
      0.07956373691558838,
      0.017621472477912903,
      -0.0367470383644104,
      -0.10611628741025925,
      0.05612712353467941,
      0.03619396686553955,
      -0.028850924223661423,
      -0.00331099983304739,
      0.06801579147577286,
      0.043666139245033264,
      -0.0012276011984795332,
      -0.07456418871879578,
      -0.006983012892305851,
      0.022331712767481804,
      -0.026366742327809334,
      0.0034267150331288576,
      0.011134450323879719,
      0.0026075432542711496,
      -0.02675441838800907,
      -0.012039263732731342,
      -0.025027699768543243,
      -0.06822926551103592,
      0.043747637420892715,
      -0.01690022088587284,
      0.031813617795705795,
      0.010977490805089474,
      -0.00801403634250164,
      0.03547440469264984,
      0.02931881509721279,
      -0.005291065201163292,
      -0.0002271448029205203,
      0.009255440905690193,
      0.11825200915336609,
      -0.04218975082039833,
      -0.002784106181934476,
      -0.03203870728611946,
      0.006767326965928078,
      0.09281667321920395,
      -0.0448136180639267,
      0.013259035535156727,
      -0.02935364842414856,
      -0.05502043291926384,
      -0.02164689637720585,
      0.05139441788196564,
      0.011737358756363392,
      0.004704364109784365,
      0.028424955904483795,
      0.0244852676987648,
      -0.04174014925956726,
      -0.09621474146842957,
      -0.01603916473686695,
      0.09476916491985321,
      0.006650324910879135,
      -0.0685935914516449,
      0.02257532812654972,
      0.011141500435769558,
      -0.0657946914434433,
      -0.021521711722016335,
      0.03178245574235916,
      -0.018557801842689514,
      -0.036456428468227386,
      0.029110372066497803,
      0.005273069255053997,
      -0.0013334996765479445,
      -0.015571922063827515,
      -0.17538507282733917,
      0.048623207956552505,
      -0.024253590032458305,
      0.0076471357606351376,
      0.005749037489295006,
      -0.04338483884930611,
      -0.01678793877363205,
      -0.02739335224032402,
      -0.029394203796982765,
      0.04028603062033653,
      0.01173987053334713,
      -0.01620587520301342,
      0.07949303090572357,
      -0.0051147714257240295,
      -0.005169716197997332,
      -0.014387506060302258,
      0.03577563166618347,
      -0.0028345256578177214,
      -0.034393247216939926,
      -0.03324902802705765,
      0.012247537262737751,
      -0.00971862394362688,
      -0.022855645045638084,
      0.024865463376045227,
      -0.03079921379685402,
      0.13523820042610168,
      0.06274361163377762,
      -0.05132025107741356,
      0.016408678144216537,
      -0.020190637558698654,
      0.028074245899915695,
      -0.016141558066010475,
      0.0415215790271759,
      -0.04278523102402687,
      0.0036755106411874294,
      0.0026772061828523874,
      0.07724878191947937,
      -0.039870403707027435,
      -0.04298345744609833,
      0.01746125891804695,
      0.034997716546058655,
      -0.030741769820451736,
      0.01468518003821373,
      0.0197929497808218,
      -0.0650344267487526,
      0.0002885525464080274,
      0.025600846856832504,
      -0.1297404170036316,
      -0.06362895667552948,
      -0.04907570779323578,
      -0.015494324266910553,
      0.048700351268053055,
      -0.021604489535093307,
      -0.1185351014137268,
      -0.028612108901143074,
      0.01362914964556694,
      -0.0286041721701622,
      -0.027760740369558334,
      0.014426267705857754,
      -0.028105290606617928,
      -0.026951391249895096,
      0.01786426268517971,
      -0.04413274675607681,
      0.022849436849355698,
      0.023815834894776344,
      -0.025052176788449287,
      -0.007590985391288996,
      0.01971893571317196,
      0.03194907680153847,
      0.0017272811383008957,
      0.05673403665423393,
      0.0103003503754735,
      -0.04255552217364311,
      -0.02182437665760517,
      0.002735003363341093,
      0.03948049247264862,
      0.028299367055296898,
      -0.07344857603311539,
      0.005581461358815432,
      0.009930267930030823,
      0.041465144604444504,
      0.0013605033745989203,
      -0.06823478639125824,
      -0.05893666669726372,
      -0.12527087330818176,
      -0.02664058282971382,
      -0.017213767394423485,
      -0.060336045920848846,
      0.007485050242394209,
      -0.017637303099036217,
      0.019463015720248222,
      -0.03325793519616127,
      0.10653384774923325,
      0.03523414954543114,
      0.028050072491168976,
      0.006177385337650776,
      0.04998211935162544,
      -0.0035070048179477453,
      -0.04221168905496597,
      -0.020085616037249565,
      -0.04581741988658905,
      -0.010750925168395042,
      0.052516717463731766,
      0.056028734892606735,
      0.009268596768379211,
      0.02299588732421398,
      -0.008275074884295464,
      -0.07449468225240707,
      0.05148804932832718,
      -0.031576309353113174,
      0.027481909841299057,
      -0.0261957049369812,
      -0.0417633093893528,
      -0.03446241840720177,
      -0.03252287581562996,
      -0.017862854525446892,
      -0.03124968521296978,
      -0.033950433135032654,
      -0.039400774985551834,
      0.034536320716142654,
      0.05447683483362198,
      0.07842540740966797,
      -0.10307729989290237,
      -1.88987105502747e-05,
      -0.011837968602776527,
      -0.029703449457883835,
      0.0038945539854466915,
      -0.024385755881667137,
      -0.08373407274484634,
      -0.06706757098436356,
      -0.03191254660487175,
      0.018027396872639656,
      -0.04661955311894417,
      -0.0679795891046524,
      -0.03194497898221016,
      0.035170841962099075,
      0.005559728480875492,
      -0.047970764338970184,
      0.01994330994784832,
      -0.00541209289804101,
      -0.01619764231145382,
      0.02158815972507,
      -0.01941552199423313,
      -0.019158314913511276,
      -0.03946056589484215,
      0.03045417182147503,
      -0.04567745327949524,
      -0.06906000524759293,
      -0.028704596683382988,
      0.08489757776260376,
      0.060155969113111496,
      0.0012193217407912016,
      0.0013729792553931475,
      0.011596490629017353,
      -0.0012371609918773174,
      -0.014002199284732342,
      0.004038653336465359,
      0.02056775987148285,
      -0.020264025777578354,
      0.02818147838115692,
      -0.05432303622364998,
      -0.02705450914800167,
      0.055495649576187134,
      0.03541039675474167,
      0.03737476468086243,
      0.003165603382512927,
      0.011410776525735855,
      -0.030872143805027008,
      0.02879292517900467,
      0.057125527411699295,
      0.09503043442964554,
      0.010243022814393044,
      0.05116503685712814,
      0.02298199199140072,
      -0.021668139845132828,
      -0.08844562619924545,
      0.03540397435426712,
      0.049160346388816833,
      0.0013454539002850652,
      -0.03248455002903938,
      -0.10916168987751007,
      -0.056847333908081055,
      -0.024678559973835945,
      -0.030477168038487434,
      -0.008428561501204967,
      -0.021702177822589874,
      -0.03025161102414131,
      -0.10273174196481705,
      0.012314880266785622,
      -0.081714928150177,
      -0.06073298305273056,
      0.01031410787254572,
      0.07706235349178314,
      0.014986583031713963,
      -0.05580537021160126,
      -0.009797353297472,
      -0.006377871613949537,
      0.03222252428531647,
      -0.042879991233348846,
      0.04802299290895462,
      -0.02922292798757553,
      -0.020108969882130623,
      0.035806283354759216,
      -0.04308227077126503,
      0.0027855648659169674,
      0.030844353139400482,
      -0.06501885503530502,
      -0.06699494272470474,
      0.020040925592184067,
      -0.020966393873095512,
      -0.05903829634189606,
      -0.02558695711195469,
      0.012122548185288906,
      0.0595393069088459,
      0.05050890892744064,
      -0.01044914685189724,
      -0.02152581885457039,
      -0.05210738256573677,
      -0.06172039732336998,
      0.0021265638060867786,
      -0.04959290847182274,
      0.05399639531970024,
      0.018657704815268517,
      -0.020756108686327934,
      -0.0042315199971199036,
      -0.045616477727890015,
      -0.016282932832837105,
      -0.07157394289970398,
      0.001855321228504181,
      0.022074447944760323,
      -0.05044412985444069,
      0.024468615651130676,
      0.09282951802015305,
      -0.03268825262784958,
      -0.03682221472263336,
      -0.04034154489636421,
      -0.03370112180709839,
      -0.010260172188282013,
      0.0818214863538742,
      -0.0331098735332489,
      -0.016467895358800888,
      0.013354577124118805,
      -0.0016201857943087816,
      0.011438585817813873,
      -0.051419347524642944,
      0.06250860542058945,
      -0.02581002004444599,
      -0.023707158863544464,
      -0.008661608211696148,
      0.036314669996500015,
      -0.009637698531150818,
      0.09191833436489105,
      -0.007046245504170656,
      -0.004388618748635054,
      0.020507927983999252,
      0.02260746993124485,
      -0.02956041879951954,
      0.028043672442436218,
      0.04256787896156311,
      -0.06747254729270935,
      0.03848215937614441,
      -0.0108755212277174,
      0.08930828422307968,
      0.06120244786143303,
      0.03354751318693161,
      -0.06032733619213104,
      -0.06254244595766068,
      0.010869273915886879,
      0.009180489927530289,
      0.05928618088364601,
      -0.07663238048553467,
      -0.007613315712660551,
      0.029931822791695595,
      -0.005420125089585781,
      0.043532662093639374,
      -0.014059986919164658,
      0.01629326306283474,
      -0.02407403476536274,
      -0.04408983141183853,
      0.052691277116537094,
      -0.008018082939088345,
      0.03593410924077034,
      0.057965971529483795,
      0.014213573187589645,
      -0.010320723056793213,
      0.013190406374633312,
      -0.011147063225507736,
      -0.038080040365457535,
      -0.01651708222925663,
      -0.028441624715924263,
      -0.062489237636327744,
      -0.00032278665457852185,
      0.05515117198228836,
      -0.10634228587150574,
      0.0980147272348404,
      -0.05045018717646599,
      0.041748370975255966,
      0.02727188915014267,
      0.049922388046979904,
      0.015002911910414696,
      -0.028258945792913437,
      -0.04594331234693527,
      -0.022012105211615562,
      -0.025128081440925598,
      0.04083117097616196,
      -0.11529018729925156,
      0.05492163822054863,
      0.02856382355093956,
      -0.006994873285293579,
      -0.06987929344177246,
      0.10156888514757156,
      0.12948474287986755,
      -0.03950650244951248,
      0.04203585162758827,
      0.016657022759318352,
      -0.02002926915884018,
      -0.012960311956703663,
      -0.000245450297370553,
      0.07322420924901962,
      -0.054490286856889725,
      0.032135553658008575,
      0.03177890554070473,
      -0.025929972529411316,
      0.05007414147257805,
      0.05953669175505638,
      -0.02006114460527897,
      0.009872079826891422,
      0.019236531108617783,
      0.027336684986948967,
      0.045749831944704056,
      -0.008048228919506073,
      0.10419373959302902,
      0.06769368797540665,
      -0.060821447521448135,
      0.030567554756999016,
      -0.023663466796278954,
      0.04360561817884445,
      -0.018902629613876343,
      0.026899371296167374,
      0.07278777658939362,
      -0.057553380727767944,
      -0.005861438345164061,
      0.04761269688606262,
      -0.036681585013866425,
      -0.02702941559255123,
      0.002417644253000617,
      -0.014943494461476803,
      -0.017639756202697754,
      0.03231699392199516,
      -0.010827388614416122,
      -0.007452072575688362,
      -0.023187901824712753,
      -0.043197423219680786,
      -0.002224741270765662,
      0.027559438720345497,
      -0.10479629784822464,
      -0.0029948391020298004,
      0.05231107026338577,
      0.05094943940639496,
      -0.025859039276838303,
      0.03241059184074402,
      0.07443366199731827,
      0.06835989654064178,
      0.0025886702351272106,
      0.013081169687211514,
      0.04366503283381462,
      0.02064179629087448,
      -0.024540923535823822,
      0.04729289188981056,
      -0.018264492973685265,
      0.025604024529457092,
      -0.10600333660840988,
      -0.05264309421181679,
      -0.021324926987290382,
      0.019605670124292374,
      0.043386973440647125,
      -0.07908768206834793,
      0.03111100196838379,
      0.03323192149400711,
      0.14198468625545502,
      0.036857254803180695,
      0.05777204781770706,
      0.05267193168401718,
      0.04266282171010971,
      0.10732090473175049,
      -0.05941532924771309,
      0.00581665663048625,
      -0.007751444820314646,
      0.06574761867523193,
      -0.040727999061346054,
      0.00795222818851471,
      -0.0693986788392067,
      -0.021136866882443428,
      0.07269719243049622,
      -0.024227332323789597,
      0.0171583853662014,
      -0.01696852222084999,
      0.002474802313372493,
      -0.06740516424179077,
      -0.09946068376302719,
      -0.05753312259912491,
      -0.0946263000369072,
      -0.003696076339110732,
      0.0048774187453091145,
      0.03180018067359924,
      0.011594273149967194,
      -0.0074690645560622215,
      -0.05184387415647507,
      -0.0027677330654114485,
      0.022869130596518517,
      -0.006976135540753603,
      -0.0186636820435524,
      -0.09046602994203568,
      -0.008512958884239197,
      -0.021084291860461235,
      -0.03494632989168167,
      -0.054494526237249374,
      -0.01563546434044838,
      -0.0007938530761748552,
      -0.055374033749103546,
      0.003044104902073741
    ],
    [
      0.08625902235507965,
      -0.01315828412771225,
      0.05079720541834831,
      -0.030508244410157204,
      0.051456935703754425,
      0.0056656659580767155,
      -0.0674484595656395,
      -0.03747508302330971,
      0.032860904932022095,
      -0.047412969172000885,
      0.04455671086907387,
      0.05249074846506119,
      -0.04288344085216522,
      -0.05451352521777153,
      -0.010961707681417465,
      -2.1970785383018665e-05,
      -0.08470747619867325,
      -0.05115729570388794,
      0.016374872997403145,
      -0.09126359224319458,
      -0.05334967374801636,
      -0.019742095842957497,
      -0.12114489078521729,
      0.03227685019373894,
      0.03679753467440605,
      0.013917895965278149,
      -0.024452848359942436,
      -0.023859668523073196,
      -0.04681006073951721,
      -0.08444831520318985,
      -0.047812554985284805,
      -0.00509670889005065,
      -0.0952409952878952,
      -0.07098686695098877,
      -0.07259011268615723,
      0.02089838869869709,
      -0.03810269385576248,
      0.052827030420303345,
      0.03996606543660164,
      0.007189796771854162,
      -0.03644431009888649,
      -0.03144384175539017,
      -0.036151859909296036,
      -0.029527129605412483,
      -0.05080145224928856,
      -0.010720249265432358,
      0.015364499762654305,
      -0.015268302522599697,
      -0.07731810957193375,
      -0.0006902196910232306,
      -0.0692063421010971,
      0.06710832566022873,
      0.004018255043774843,
      -0.023409513756632805,
      0.0013294910313561559,
      0.042391326278448105,
      0.014305523596704006,
      -0.000986604019999504,
      -0.0080759571865201,
      0.012504396960139275,
      -0.053497135639190674,
      0.059554148465394974,
      -0.0995650589466095,
      0.034287236630916595,
      0.020290784537792206,
      -0.007315665949136019,
      -0.07994464784860611,
      0.016139516606926918,
      -0.01698540896177292,
      0.13822636008262634,
      0.0030489307828247547,
      0.01782195270061493,
      -0.06278151273727417,
      -0.004879506770521402,
      -0.01387312076985836,
      0.014375073835253716,
      -0.06398452073335648,
      -0.03448044881224632,
      -0.004865637049078941,
      0.007572433911263943,
      0.0054405746050179005,
      0.0004439543408807367,
      -0.027241181582212448,
      -0.018275491893291473,
      0.036157116293907166,
      -0.007907431572675705,
      -0.06885130703449249,
      0.0008612278616055846,
      0.049530453979969025,
      -0.02243608608841896,
      0.05118528753519058,
      0.03619673103094101,
      0.02400539256632328,
      0.04028386250138283,
      0.08062876760959625,
      0.0053274319507181644,
      0.005061727482825518,
      -0.011583579704165459,
      0.04426080361008644,
      -0.030623093247413635,
      0.020975373685359955,
      -0.0038536852225661278,
      -0.009388851933181286,
      -0.06418657302856445,
      0.07561735808849335,
      -0.02953418903052807,
      -0.008874528110027313,
      -0.027873646467924118,
      0.013360218144953251,
      0.013122263364493847,
      0.0661725178360939,
      0.024432947859168053,
      0.00011111490312032402,
      -0.04341857507824898,
      -0.0014487478183582425,
      -0.010574057698249817,
      -0.0436420701444149,
      -0.012087414972484112,
      0.07070092111825943,
      0.0022171600721776485,
      0.018067730590701103,
      0.049166638404130936,
      0.06191062927246094,
      -0.048787008970975876,
      -0.006519882474094629,
      0.010380671359598637,
      0.02061426267027855,
      -0.04662323743104935,
      0.07949262857437134,
      0.04435534402728081,
      0.005381153430789709,
      -0.07256908714771271,
      0.036920078098773956,
      -0.0009894078830257058,
      0.04410726949572563,
      0.07832365483045578,
      0.030578745529055595,
      0.04128632694482803,
      -0.01043636817485094,
      -0.005709775723516941,
      0.014322128146886826,
      -0.03806745260953903,
      0.026329437270760536,
      0.013561395928263664,
      0.02570633962750435,
      0.008488409221172333,
      0.0030179875902831554,
      0.057248007506132126,
      -0.07781174778938293,
      -0.02439422346651554,
      -0.05033319443464279,
      0.06662087887525558,
      -0.008395751938223839,
      -0.002196301007643342,
      -0.0696459487080574,
      -0.0364995002746582,
      0.006451030727475882,
      -0.032567065209150314,
      -0.047480348497629166,
      0.006995257921516895,
      0.03611259534955025,
      0.013980095274746418,
      0.01581662893295288,
      0.032508790493011475,
      0.017404085025191307,
      -0.05526407063007355,
      -0.02418641746044159,
      -0.018206359818577766,
      0.02680601365864277,
      -0.019652532413601875,
      0.06306272745132446,
      -0.006139742210507393,
      0.0019399753073230386,
      0.09637060761451721,
      0.008773806504905224,
      0.1515858918428421,
      -0.054504502564668655,
      -0.02198903076350689,
      -0.07812979072332382,
      0.06444382667541504,
      0.011225505731999874,
      -0.05603814125061035,
      -0.006582873873412609,
      0.048941489309072495,
      0.057753417640924454,
      0.07346761226654053,
      0.03871806710958481,
      0.016780130565166473,
      0.0017544380389153957,
      0.012854782864451408,
      -0.02081090211868286,
      -0.05521354451775551,
      -0.03888485208153725,
      -0.0077313571237027645,
      -0.00818784348666668,
      0.02920479141175747,
      -0.022300103679299355,
      -0.06466346234083176,
      0.02398691512644291,
      -0.04140273854136467,
      -0.012981916777789593,
      -0.021182583644986153,
      -0.0434989258646965,
      0.07270899415016174,
      -0.05677098035812378,
      0.12278812378644943,
      0.07032942771911621,
      -0.08636962622404099,
      0.07191194593906403,
      0.01091831736266613,
      -0.010005734860897064,
      -0.0206376351416111,
      -0.011927163228392601,
      -0.05357066169381142,
      -0.0007253031944856048,
      -0.05221141129732132,
      0.004312114790081978,
      0.017446203157305717,
      -0.09701705724000931,
      -0.05207717791199684,
      0.001251627691090107,
      0.04103738069534302,
      0.01858264021575451,
      0.02019490860402584,
      -0.0007433235296048224,
      0.027528993785381317,
      -0.0037326759193092585,
      -0.05941137671470642,
      -0.12001833319664001,
      0.06438514590263367,
      0.007915172725915909,
      0.009254854172468185,
      0.05326652526855469,
      -0.03494161739945412,
      0.04989759996533394,
      -0.04154752194881439,
      -0.008569132536649704,
      -0.00883485097438097,
      -0.013677896000444889,
      -0.007960917428135872,
      0.040934979915618896,
      -0.033820293843746185,
      0.03491770848631859,
      -0.008333825506269932,
      0.035702794790267944,
      0.012335271574556828,
      -0.04302123561501503,
      0.006369167473167181,
      0.05687582865357399,
      -0.019014474004507065,
      0.04493226855993271,
      -0.04470304399728775,
      0.06542845815420151,
      0.020432062447071075,
      -0.051138732582330704,
      0.01669660210609436,
      0.047616783529520035,
      -0.10472399741411209,
      -0.020884640514850616,
      0.021898796781897545,
      0.011365581303834915,
      -0.09786036610603333,
      -0.021880032494664192,
      0.018248751759529114,
      0.049589648842811584,
      0.0028733517974615097,
      -0.017186056822538376,
      -0.0357290580868721,
      -0.0013472364516928792,
      -0.008959800004959106,
      -0.07342105358839035,
      -0.012043669819831848,
      -0.024521011859178543,
      -0.007700019050389528,
      -0.0304359532892704,
      -0.015294824726879597,
      0.008648909628391266,
      -0.06063330918550491,
      -0.019817933440208435,
      -0.08784922957420349,
      -0.018844082951545715,
      0.019390342757105827,
      -0.021343866363167763,
      0.042121466249227524,
      0.0013184819836169481,
      -0.025798173621296883,
      -0.04288658872246742,
      -0.019154053181409836,
      0.0013810136588290334,
      0.0073781381361186504,
      -0.02034836634993553,
      -0.009452699683606625,
      0.033659741282463074,
      -0.005124958697706461,
      -0.05616346374154091,
      0.0035263276658952236,
      -0.015002284198999405,
      0.0406917966902256,
      0.03895951062440872,
      -0.07205763459205627,
      -0.02744876965880394,
      0.05874689668416977,
      0.06685863435268402,
      -0.007833401672542095,
      -0.10559394210577011,
      0.07898340374231339,
      0.041905827820301056,
      -0.006984122563153505,
      -0.01616564765572548,
      -0.00918379332870245,
      -0.03173704817891121,
      -0.0377940833568573,
      0.05241122469305992,
      -0.012047265656292439,
      -0.05661856383085251,
      7.651925989193842e-05,
      -0.03988193720579147,
      -0.06132408231496811,
      -0.010205274447798729,
      0.06584268808364868,
      0.007088621146976948,
      0.0009402558789588511,
      0.074274942278862,
      -0.013784949667751789,
      0.023558812215924263,
      -0.017935696989297867,
      0.004351407289505005,
      0.04926678538322449,
      -0.05328016355633736,
      0.002381078200414777,
      0.04482894390821457,
      0.03596349433064461,
      0.07352211326360703,
      0.010865652933716774,
      0.050735507160425186,
      0.019729308784008026,
      0.0426308810710907,
      0.017047259956598282,
      -0.03494248911738396,
      -0.04231029376387596,
      -0.020246559754014015,
      -0.013849649578332901,
      0.03342606872320175,
      0.04053492844104767,
      0.07043740898370743,
      0.044978927820920944,
      0.02727908454835415,
      -0.04161694273352623,
      0.045270178467035294,
      -0.011457784101366997,
      0.021855037659406662,
      0.0023047165013849735,
      -0.029334086924791336,
      0.00207593385130167,
      -0.0009569249232299626,
      0.014115872792899609,
      0.028908012434840202,
      -0.0047391075640916824,
      -0.006188740488141775,
      -0.03252805024385452,
      -0.06674373149871826,
      0.037645746022462845,
      0.043237973004579544,
      0.03850005939602852,
      -0.056449323892593384,
      -0.0064499396830797195,
      0.012591364793479443,
      0.07469248026609421,
      0.055450860410928726,
      -0.04675423353910446,
      -0.05666226148605347,
      0.023274095728993416,
      -0.06339630484580994,
      -0.02119811251759529,
      -0.0393977090716362,
      0.03458360955119133,
      -0.00021598066086880863,
      -0.00021637178724631667,
      -0.036324355751276016,
      0.06291668117046356,
      0.038009773939847946,
      -0.014512544497847557,
      0.08849696069955826,
      0.06050851568579674,
      -0.04810204729437828,
      -0.06297266483306885,
      0.0293682049959898,
      -0.026462452486157417,
      -0.003251360496506095,
      -0.08501476049423218,
      0.03252239152789116,
      -0.017414439469575882,
      0.03147897496819496,
      -0.043369829654693604,
      -0.052579037845134735,
      0.013370922766625881,
      -0.0013766010524705052,
      -0.06970483064651489,
      -0.02175791747868061,
      0.009988467209041119,
      0.08573589473962784,
      0.02332521229982376,
      0.08769591152667999,
      -0.08260565251111984,
      -0.03412045165896416,
      -0.040191881358623505,
      0.10644108057022095,
      -0.04589000716805458,
      0.027478301897644997,
      0.022945700213313103,
      -0.02184232696890831,
      0.014055226929485798,
      -0.04695998504757881,
      -0.03224332258105278,
      -0.03042127937078476,
      0.059177301824092865,
      0.011128224432468414,
      -0.0999632328748703,
      -0.06779508292675018,
      0.005640385206788778,
      -0.09093334525823593,
      0.06613202393054962,
      -0.024504950270056725,
      0.023319078609347343,
      -0.030725210905075073,
      0.043461594730615616,
      -0.10410653799772263,
      0.017038805410265923,
      -0.0007777526625432074,
      -0.0488981269299984,
      0.028468212112784386,
      -0.034598324447870255,
      -0.02586846612393856,
      0.013237339444458485,
      -0.018517274409532547,
      0.06847954541444778,
      0.06895292550325394,
      -0.022198906168341637,
      0.004234233405441046,
      -0.07116246968507767,
      0.025909902527928352,
      -0.034875452518463135,
      -0.030525265261530876,
      0.0010078017367050052,
      -0.008258306421339512,
      0.07571937143802643,
      0.03162824362516403,
      0.06886855512857437,
      -0.011788600124418736,
      0.012742782942950726,
      0.009523333050310612,
      -0.06404295563697815,
      0.04074598103761673,
      -0.0019771999213844538,
      -0.003102520015090704,
      0.056085459887981415,
      -0.08049223572015762,
      0.03899281844496727,
      -0.09291323274374008,
      0.008049916476011276,
      0.021400870755314827,
      0.0828428864479065,
      -0.02574971318244934,
      0.025114724412560463,
      -0.06894208490848541,
      -0.010390044189989567,
      0.10095628350973129,
      -0.0188400037586689,
      -0.05872015282511711,
      -0.013137328438460827,
      0.04908933490514755,
      0.036386217921972275,
      -0.04276944696903229,
      -0.052799027413129807,
      0.018666012212634087,
      0.002578960033133626,
      0.05889622122049332,
      -0.0739632174372673,
      0.06555768847465515,
      0.07582699507474899,
      -0.0637706071138382,
      -0.09361010789871216,
      -0.009017582051455975,
      -0.031058985739946365,
      0.08694751560688019,
      -0.034771982580423355,
      0.007032046094536781,
      -0.033008210361003876,
      -0.036067940294742584,
      0.020086605101823807,
      -0.0207191314548254,
      0.017327025532722473,
      0.06150045990943909,
      0.006811704020947218,
      -0.07597528398036957,
      -0.06810569763183594,
      -0.03210245072841644,
      0.011968635953962803,
      -0.10372409224510193,
      0.012936960905790329,
      -0.0328819677233696,
      0.05043434724211693,
      -0.02553250454366207
    ],
    [
      0.0037739956751465797,
      -0.036919739097356796,
      -0.06660668551921844,
      -0.0010083684464916587,
      0.017510980367660522,
      0.01785622350871563,
      -0.058065030723810196,
      0.02561751939356327,
      -0.04535025358200073,
      -0.009919628500938416,
      0.01931234449148178,
      -0.016747629269957542,
      0.012353281490504742,
      0.0618119016289711,
      0.036776650696992874,
      -0.012668862007558346,
      0.011669342406094074,
      -0.03791726753115654,
      0.013140613213181496,
      0.041300076991319656,
      0.0415007509291172,
      -0.014452490024268627,
      -0.04234748333692551,
      -0.005285696126520634,
      0.0029958621598780155,
      0.08626609295606613,
      -0.060514386743307114,
      -0.0206511989235878,
      0.046549901366233826,
      -0.016631171107292175,
      -0.01403500884771347,
      -0.05421293154358864,
      0.052624937146902084,
      -0.07796730846166611,
      0.025262657552957535,
      0.02606472559273243,
      -0.003035249188542366,
      -0.02814526855945587,
      0.04483255743980408,
      0.042246729135513306,
      -0.019209083169698715,
      0.03422574698925018,
      -0.1269107162952423,
      0.025466831400990486,
      0.08221669495105743,
      -0.028130903840065002,
      -0.037874478846788406,
      0.05432591587305069,
      0.005827069748193026,
      0.008598918095231056,
      0.01769963651895523,
      0.005261254496872425,
      -0.0007772042299620807,
      0.01029687188565731,
      -0.0022701520938426256,
      -0.016779419034719467,
      -0.015522987581789494,
      0.012775356881320477,
      -0.02266344241797924,
      0.004903988912701607,
      -0.02383296564221382,
      0.0360037125647068,
      -0.08144932240247726,
      -0.07022638618946075,
      0.026533745229244232,
      -0.03496484458446503,
      0.024429047480225563,
      0.022671334445476532,
      0.01766585186123848,
      0.011250756680965424,
      0.022807732224464417,
      0.06945493072271347,
      0.016114646568894386,
      0.051686178892850876,
      0.04184398800134659,
      0.15169653296470642,
      0.004959370009601116,
      -0.02692103385925293,
      0.023969225585460663,
      0.04309136047959328,
      0.045240700244903564,
      0.036452945321798325,
      0.01571100391447544,
      -0.07711440324783325,
      0.08369867503643036,
      0.047037769109010696,
      0.004952284973114729,
      -0.05220140516757965,
      0.027123834937810898,
      0.10973065346479416,
      -0.05745308846235275,
      0.019879715517163277,
      -0.0005720526096411049,
      -0.05988980084657669,
      -0.030718740075826645,
      0.008775770664215088,
      -0.03581831231713295,
      0.041730109602212906,
      0.0076562631875276566,
      -0.017355013638734818,
      0.038430292159318924,
      0.008260328322649002,
      0.02333737723529339,
      0.03851114213466644,
      0.007822642102837563,
      0.007622252218425274,
      -0.017872804775834084,
      0.07036057859659195,
      -0.028399962931871414,
      -0.020531846210360527,
      -0.024532519280910492,
      0.03272321820259094,
      0.0009407471516169608,
      -0.06452887505292892,
      -0.0016374792903661728,
      -0.03487159684300423,
      0.0028368274215608835,
      -0.06713750213384628,
      0.0411616787314415,
      0.011075086891651154,
      -0.03510665148496628,
      0.003351694205775857,
      0.09357216209173203,
      0.07796760648488998,
      -0.04140608757734299,
      0.043402522802352905,
      0.030467413365840912,
      0.055102307349443436,
      0.07522747665643692,
      -0.0428941436111927,
      -0.06618496030569077,
      -0.06402188539505005,
      -0.026740839704871178,
      -0.07848817855119705,
      0.018396534025669098,
      0.0052490150555968285,
      -0.004057623445987701,
      0.05588560551404953,
      -0.10951916128396988,
      -0.10108748078346252,
      0.008276699110865593,
      0.06763295084238052,
      0.03530999645590782,
      0.018069075420498848,
      0.02364763431251049,
      -0.04921356216073036,
      -0.0796281024813652,
      -0.0395643450319767,
      -0.029077034443616867,
      -0.06524091958999634,
      0.014043375849723816,
      0.01616205833852291,
      -0.055878471583127975,
      -0.039532262831926346,
      -0.024623163044452667,
      -0.007421289570629597,
      0.11243472248315811,
      -0.04735995829105377,
      0.015207486227154732,
      0.004822052549570799,
      0.012093034572899342,
      -0.030117055401206017,
      0.050615038722753525,
      -0.08944032341241837,
      -0.030486417934298515,
      -0.07025977224111557,
      0.01356505136936903,
      -0.018930558115243912,
      -0.02512536384165287,
      0.08215603232383728,
      0.060669973492622375,
      0.07966732233762741,
      -0.08064953237771988,
      0.01922541670501232,
      0.028049129992723465,
      0.04629644379019737,
      0.06610280275344849,
      -0.02474917285144329,
      -0.08720514178276062,
      -0.02791541814804077,
      0.0476367212831974,
      -0.01910758763551712,
      -0.06475408375263214,
      -0.008024412207305431,
      0.03991718217730522,
      0.05600148066878319,
      -0.02060628868639469,
      -0.017360109835863113,
      0.002218537498265505,
      0.05591854080557823,
      -0.018439171835780144,
      0.07956918329000473,
      -0.02547430619597435,
      0.021567028015851974,
      0.03330887109041214,
      0.07735861092805862,
      0.02385317161679268,
      0.04114854708313942,
      0.0018302210373803973,
      0.005548242013901472,
      0.029427582398056984,
      -0.09621956944465637,
      0.009480231441557407,
      0.04117004945874214,
      -0.03515634313225746,
      -0.023559479042887688,
      0.020143786445260048,
      0.03270332142710686,
      0.032332587987184525,
      0.01452825777232647,
      0.027401471510529518,
      0.04636048525571823,
      -0.05764762684702873,
      -0.008881524205207825,
      -0.08631743490695953,
      0.0299847349524498,
      0.013990717008709908,
      0.08335986733436584,
      -0.05115986987948418,
      0.03534113988280296,
      -0.008041707798838615,
      0.015451708808541298,
      -0.04387826472520828,
      0.0068624685518443584,
      0.0050095971673727036,
      0.0027159086894243956,
      0.05088694021105766,
      0.0385199598968029,
      -0.005622553173452616,
      0.0044555701315402985,
      0.007915886119008064,
      -0.011042339727282524,
      -0.007876393385231495,
      0.04830574244260788,
      0.02484634332358837,
      0.015709910541772842,
      -0.04670102521777153,
      0.03838689997792244,
      -0.01863701082766056,
      0.08454035222530365,
      -0.03858575597405434,
      0.024343231692910194,
      -0.011412782594561577,
      0.09477940201759338,
      -0.02994726598262787,
      0.045215409249067307,
      0.049569010734558105,
      0.004141348879784346,
      0.033140916377305984,
      0.06423433125019073,
      -0.013605544343590736,
      0.007457417901605368,
      0.06455932557582855,
      -0.05120641738176346,
      0.043832190334796906,
      0.01763741858303547,
      -0.008352807722985744,
      0.0035093221813440323,
      0.04625057429075241,
      0.005138499662280083,
      0.027838870882987976,
      -0.045506060123443604,
      0.028988420963287354,
      -0.02425268292427063,
      0.06727992743253708,
      -0.006124502047896385,
      -0.08445042371749878,
      -0.005640835501253605,
      0.09173156321048737,
      0.02732679806649685,
      0.0481802374124527,
      -0.02244020253419876,
      -0.04040065407752991,
      0.03463354706764221,
      -0.07401514053344727,
      0.06438818573951721,
      -0.012546026147902012,
      -0.022850848734378815,
      -0.0033463488798588514,
      -0.010098372586071491,
      0.00035143550485372543,
      -0.04112822562456131,
      -0.01739574782550335,
      -0.010977569036185741,
      0.01267310231924057,
      -0.0031341384164988995,
      0.00027182503254152834,
      -0.06288450956344604,
      -0.0158395916223526,
      -0.020324425771832466,
      0.00396896805614233,
      0.03092939779162407,
      -0.04638782888650894,
      0.01021128986030817,
      0.017908241599798203,
      0.04830070585012436,
      -0.04033495858311653,
      0.024394195526838303,
      -0.01123414933681488,
      -0.006047246977686882,
      0.020713847130537033,
      -0.006402963772416115,
      -0.002327137626707554,
      -0.026697704568505287,
      0.01314820721745491,
      -0.007373305037617683,
      0.10887698829174042,
      0.023566193878650665,
      -0.0038575660437345505,
      -0.038029994815588,
      -0.08740037679672241,
      -0.017163880169391632,
      0.02663947083055973,
      0.016230734065175056,
      0.00745152123272419,
      0.046854689717292786,
      -0.04225869104266167,
      -0.02084154635667801,
      -0.04121821001172066,
      0.022450411692261696,
      0.010941226035356522,
      -0.0023423435632139444,
      -0.047368742525577545,
      -0.01875344291329384,
      -0.015966499224305153,
      -0.022843530401587486,
      0.03161196783185005,
      0.09989557415246964,
      -0.020005173981189728,
      0.05185248702764511,
      -0.07335659861564636,
      0.07550268620252609,
      -0.0013571289600804448,
      -0.05239593982696533,
      0.00037353733205236495,
      -0.10544487088918686,
      0.04798441752791405,
      -0.033251430839300156,
      -0.007876154966652393,
      -0.016104470938444138,
      0.00836865697056055,
      0.04901954531669617,
      -0.06196453049778938,
      -0.004963371437042952,
      0.01388790737837553,
      -0.038270704448223114,
      0.031070169061422348,
      0.05963658541440964,
      -0.06772585213184357,
      -0.04439729452133179,
      0.04319106042385101,
      0.05173890292644501,
      0.059107497334480286,
      0.06023029237985611,
      -0.003278696909546852,
      -0.03657495230436325,
      -0.04342931509017944,
      -0.10092639178037643,
      -0.03631483390927315,
      -0.03416496515274048,
      -0.052015889436006546,
      0.0741405114531517,
      -0.00835129152983427,
      -0.030553605407476425,
      -0.030496904626488686,
      0.015322675928473473,
      -0.03807622566819191,
      -0.016181567683815956,
      -0.0642600953578949,
      0.007445810828357935,
      0.03428575396537781,
      -0.05068027228116989,
      -0.05377564579248428,
      -0.00047752264072187245,
      0.04353686049580574,
      0.07176218926906586,
      -0.025996455922722816,
      0.021346082910895348,
      -0.06616140156984329,
      -0.05413048714399338,
      0.0009350867476314306,
      -0.010272378101944923,
      -0.008476924151182175,
      0.017383022233843803,
      0.031002523377537727,
      0.002975896466523409,
      0.0460410974919796,
      -0.064230777323246,
      -0.0770520269870758,
      -0.06847862899303436,
      -0.04021059721708298,
      0.06885278970003128,
      0.04142582789063454,
      0.009342939592897892,
      0.02276160567998886,
      -0.05665010213851929,
      -0.026000292971730232,
      0.0260063037276268,
      0.03351421654224396,
      0.0020293889101594687,
      0.014546392485499382,
      0.02811780758202076,
      0.06287316977977753,
      0.018042022362351418,
      -0.03631037846207619,
      -0.05313900485634804,
      0.0812661275267601,
      -0.08126452565193176,
      0.0397956520318985,
      -0.02058098278939724,
      -0.02428555302321911,
      0.0024264706298708916,
      -0.03679972141981125,
      0.047005120664834976,
      0.012060818262398243,
      -0.020968960598111153,
      -0.01021172571927309,
      0.024217167869210243,
      -0.03384645655751228,
      0.009763667359948158,
      -0.04537724331021309,
      -0.009754814207553864,
      -0.01009728480130434,
      0.008940430358052254,
      0.005870488006621599,
      0.03563503175973892,
      0.07018371671438217,
      -0.03594265133142471,
      -0.023218821734189987,
      0.08294704556465149,
      0.11461278051137924,
      -0.04734405502676964,
      -0.036107514053583145,
      -0.04524010047316551,
      -0.032973289489746094,
      0.0409521646797657,
      0.005606702994555235,
      0.009711000137031078,
      0.06344537436962128,
      -0.01940186321735382,
      0.07017581164836884,
      -0.00034468434751033783,
      0.010676195845007896,
      0.013742503710091114,
      0.054171860218048096,
      0.012672397308051586,
      -0.005896523129194975,
      0.0726252868771553,
      -0.03295166417956352,
      0.013594983145594597,
      -0.0342562161386013,
      -0.06193967163562775,
      0.06746979802846909,
      -0.023350460454821587,
      -0.08147499710321426,
      -0.002448564162477851,
      -0.003254209877923131,
      -0.01277836598455906,
      0.01987895555794239,
      -0.008924990892410278,
      0.026963908225297928,
      0.06760116666555405,
      0.007662581745535135,
      0.026399079710245132,
      0.022557545453310013,
      0.058374978601932526,
      0.08112236112356186,
      -0.025874268263578415,
      -0.008316582068800926,
      0.044736918061971664,
      0.05588247627019882,
      -0.0430992916226387,
      0.002567694056779146,
      -0.06785748153924942,
      0.00807937327772379,
      -0.013410129584372044,
      0.03624342754483223,
      0.003139448817819357,
      0.12535178661346436,
      -0.058521974831819534,
      0.0603397861123085,
      -0.046909820288419724,
      -0.002515976084396243,
      -0.0074736992828547955,
      0.0384022481739521,
      0.060300473123788834,
      -0.014945857226848602,
      0.05766191706061363,
      -0.046128056943416595,
      0.02748415619134903,
      -0.04031774029135704,
      0.008650535717606544,
      -0.08428329229354858,
      -0.03194102272391319,
      -0.02564370445907116,
      0.047056134790182114,
      -0.10968108475208282,
      0.0028677103109657764,
      0.059790752828121185,
      0.19453531503677368,
      0.0379292368888855,
      -0.050950709730386734,
      -0.03328700736165047
    ],
    [
      -0.0007864307845011353,
      0.026508266106247902,
      0.022290697321295738,
      -0.04453643783926964,
      0.016562582924962044,
      0.029167497530579567,
      0.00858211051672697,
      -0.03473633900284767,
      -0.002562121255323291,
      0.026079798117280006,
      -0.023024091497063637,
      0.06244385242462158,
      -0.023824414238333702,
      0.01051942165941,
      0.024974482133984566,
      0.07876363396644592,
      -0.006464273203164339,
      -0.03111223876476288,
      0.025163305923342705,
      -0.05162505805492401,
      -0.1143970936536789,
      0.013451009057462215,
      0.07123811542987823,
      -0.0109226880595088,
      -0.019606390967965126,
      0.01697900891304016,
      -0.004015261307358742,
      -0.09840009361505508,
      -0.045162949711084366,
      0.0014867095742374659,
      0.0005504274740815163,
      0.0007207311573438346,
      0.009421241469681263,
      0.1032574400305748,
      0.01930643431842327,
      -0.015958746895194054,
      -0.04552886635065079,
      0.0585477240383625,
      -0.015121675096452236,
      -0.016971493139863014,
      -0.049831315875053406,
      0.09397467970848083,
      0.035654857754707336,
      0.03739624097943306,
      0.06883007287979126,
      -0.011262357234954834,
      -0.01135996263474226,
      0.02419145405292511,
      -0.01119432132691145,
      -0.07724189013242722,
      -0.0031844577752053738,
      0.010590405203402042,
      0.025001872330904007,
      -0.02447700873017311,
      0.05113520845770836,
      0.040223702788352966,
      -0.022968774661421776,
      -0.017467137426137924,
      -0.02312369830906391,
      0.08800302445888519,
      0.035223595798015594,
      0.03548403084278107,
      -0.0031813334207981825,
      0.07973451912403107,
      -0.0024853714276105165,
      -0.03398594632744789,
      -0.03531836345791817,
      -0.08976577967405319,
      -0.051992785185575485,
      -0.031067976728081703,
      -0.03868325799703598,
      0.07133728265762329,
      0.01699240691959858,
      0.0007006184896454215,
      0.04567795619368553,
      -0.0365399494767189,
      0.02342848852276802,
      -0.025557920336723328,
      -0.03387301042675972,
      0.003190278774127364,
      -0.05224163085222244,
      0.04791950806975365,
      -0.04338526725769043,
      0.03830449655652046,
      -0.023787634447216988,
      -0.05081091821193695,
      0.06424527615308762,
      -0.12263121455907822,
      -0.01977851241827011,
      -0.01328116562217474,
      -0.0039826384745538235,
      0.03651532530784607,
      0.01861049421131611,
      0.0009523552726022899,
      0.06344961374998093,
      -0.007948656566441059,
      -0.0021994775161147118,
      -0.045459359884262085,
      -0.04283665865659714,
      0.04725947976112366,
      -0.06849789619445801,
      0.036307018250226974,
      0.03710838407278061,
      -0.029135888442397118,
      0.009863478131592274,
      -0.06385316699743271,
      -0.0065174284391105175,
      0.09284720569849014,
      -0.001683516544289887,
      0.016517294570803642,
      0.009807190857827663,
      -0.025613602250814438,
      0.09143269807100296,
      -0.025370284914970398,
      -0.03714664280414581,
      -0.01981712318956852,
      0.03602834790945053,
      0.04189596697688103,
      -0.05663446709513664,
      0.07439790666103363,
      0.04488014429807663,
      0.026463370770215988,
      -0.0055602979846298695,
      -0.024395503103733063,
      0.0060764504596591,
      0.019848410040140152,
      -0.051265303045511246,
      0.044022899121046066,
      -0.009516529738903046,
      0.007317882962524891,
      -0.11570048332214355,
      0.036342278122901917,
      -0.03364323824644089,
      -0.05753973498940468,
      0.0061597637832164764,
      -0.011837329715490341,
      -0.04174462705850601,
      0.03568294271826744,
      0.0443694181740284,
      0.11441314220428467,
      0.0073196906596422195,
      0.02542230300605297,
      -0.0008820225484669209,
      0.018830759450793266,
      -0.05308857932686806,
      0.034905098378658295,
      0.0347621887922287,
      -0.01838565431535244,
      -0.03143896162509918,
      -0.022451724857091904,
      -0.015733128413558006,
      0.005416657775640488,
      -0.04680733010172844,
      -0.07796990871429443,
      -0.0027718732599169016,
      0.0696534663438797,
      0.07362004369497299,
      0.03757867589592934,
      0.006166206207126379,
      -0.04954204708337784,
      0.06232444569468498,
      0.03163822367787361,
      0.05714996159076691,
      0.047210704535245895,
      0.08962679654359818,
      0.030339263379573822,
      -0.0504184253513813,
      0.0017421685624867678,
      0.06393272429704666,
      -0.019823115319013596,
      -0.010970662347972393,
      0.056830618530511856,
      -0.013925132341682911,
      0.0009942497126758099,
      0.007572455331683159,
      -0.023866111412644386,
      -0.014568918384611607,
      0.02371249720454216,
      -0.06930798292160034,
      -0.03260594233870506,
      0.0074227177537977695,
      -0.07202667742967606,
      0.02748415432870388,
      -0.014776840806007385,
      0.06981685757637024,
      0.006061821710318327,
      0.028607314452528954,
      -0.018445421010255814,
      -0.1498735100030899,
      0.015045520849525928,
      0.015073920600116253,
      -0.03190552070736885,
      0.04675257205963135,
      0.0541805736720562,
      0.019743114709854126,
      0.03079451248049736,
      0.019453898072242737,
      -0.02257423847913742,
      0.026787538081407547,
      -0.018913565203547478,
      -0.04115859046578407,
      0.019813640043139458,
      -0.013796024024486542,
      0.03142101690173149,
      -0.010038323700428009,
      -0.00387830869294703,
      0.06075444445014,
      0.0037841026205569506,
      0.08168760687112808,
      0.04105222225189209,
      -0.04593212530016899,
      -0.0610995776951313,
      0.0420677624642849,
      0.012400981038808823,
      -0.0026442226953804493,
      -0.01897457055747509,
      -0.030028359964489937,
      -0.003195101860910654,
      0.01916145160794258,
      -0.046737778931856155,
      -0.012076828628778458,
      -0.0015880836872383952,
      -0.04044555127620697,
      -0.09782667458057404,
      -0.010576660744845867,
      -0.01962505653500557,
      -0.0201405081897974,
      -0.0880475714802742,
      -0.10649405419826508,
      -0.004738214425742626,
      0.011739700101315975,
      0.01663670502603054,
      -0.009977862238883972,
      0.07384257018566132,
      -0.013870272785425186,
      0.009507177397608757,
      -0.01253108773380518,
      -0.01036447286605835,
      0.05535475164651871,
      -0.0019909776747226715,
      0.004028898663818836,
      0.007468825206160545,
      -0.016933653503656387,
      0.008493646048009396,
      0.0023201957810670137,
      0.04562090337276459,
      0.028895607218146324,
      -0.012265298515558243,
      0.002377795986831188,
      -0.07203543186187744,
      -0.030311981216073036,
      0.015541160479187965,
      0.021124308928847313,
      -0.09695271402597427,
      0.0031192153692245483,
      0.012196875177323818,
      -0.026925284415483475,
      0.008595491759479046,
      -0.028461787849664688,
      -0.0007196501828730106,
      -0.03391828015446663,
      -0.10356469452381134,
      -0.07014267146587372,
      0.009064075537025928,
      -0.1018448993563652,
      -0.05869175121188164,
      0.05279460549354553,
      0.023142315447330475,
      0.0023483880795538425,
      -0.05770700424909592,
      0.021625714376568794,
      0.0015954808332026005,
      -0.00481041194871068,
      0.014472292736172676,
      0.04143151268362999,
      -0.08810333907604218,
      0.01650827005505562,
      -0.007461618632078171,
      0.01095960009843111,
      0.09766405075788498,
      -0.007992079481482506,
      5.9364134358474985e-05,
      -0.07519514113664627,
      0.03339507430791855,
      -0.007593730464577675,
      -0.07091899961233139,
      -0.028731252998113632,
      0.002484334632754326,
      -0.00925147719681263,
      0.051890578120946884,
      0.030472954735159874,
      0.037264250218868256,
      0.014617119915783405,
      -0.029709190130233765,
      0.03238726779818535,
      0.018110055476427078,
      0.005206411704421043,
      -0.011873406358063221,
      -0.018263814970850945,
      0.012275215238332748,
      -0.005642822943627834,
      0.025247847661376,
      0.025949817150831223,
      -0.0017866770504042506,
      0.012731608934700489,
      -0.05609162151813507,
      -0.05944088473916054,
      -0.035402897745370865,
      0.03018735907971859,
      -0.0233561173081398,
      -0.012065454386174679,
      -0.04047612473368645,
      -0.03987988829612732,
      -0.005039088428020477,
      -0.046461038291454315,
      -0.07690100371837616,
      -0.03947480395436287,
      -0.05777674540877342,
      -0.015772197395563126,
      0.014425867237150669,
      0.02645139954984188,
      -0.03205644339323044,
      0.026455756276845932,
      0.03910103812813759,
      0.014665457420051098,
      0.00642746314406395,
      -0.03105362504720688,
      0.025493113324046135,
      -0.002271877136081457,
      -0.0013206381117925048,
      0.04599246010184288,
      0.060370393097400665,
      -0.05774591863155365,
      -0.08640352636575699,
      0.016289032995700836,
      0.05985552817583084,
      -0.048769090324640274,
      0.012464337982237339,
      -0.045019540935754776,
      0.027707058936357498,
      0.017945734784007072,
      -0.048902757465839386,
      0.01807163655757904,
      0.0018833331996574998,
      0.08546844124794006,
      0.0015774187631905079,
      -0.021271612495183945,
      -0.03162264823913574,
      -0.004505950957536697,
      0.015120159834623337,
      0.03640325367450714,
      -0.006208678707480431,
      -0.04656080901622772,
      -0.023664405569434166,
      -0.06921454519033432,
      0.00386054627597332,
      0.0546327605843544,
      -0.018179990351200104,
      -0.012872218154370785,
      0.0019733160734176636,
      0.00435340590775013,
      -0.039967168122529984,
      0.03858058527112007,
      0.01950535550713539,
      -0.02474885992705822,
      -0.024145007133483887,
      0.04069000855088234,
      -0.09834298491477966,
      -0.02764677256345749,
      -0.0650026798248291,
      -0.001701435074210167,
      -0.026471689343452454,
      -0.03376111015677452,
      -0.01914270408451557,
      0.007947414182126522,
      0.012329451739788055,
      -0.038244083523750305,
      0.07662796974182129,
      -0.010176579467952251,
      0.05119781941175461,
      -0.06467185169458389,
      0.015121241100132465,
      -0.03989187628030777,
      -0.04176072031259537,
      0.0092378631234169,
      0.061059240251779556,
      -0.05355743691325188,
      -0.009548535570502281,
      -0.05015718191862106,
      -0.0010455182055011392,
      0.019483055919408798,
      -0.032624147832393646,
      -0.0010086307302117348,
      0.08963187783956528,
      -0.06369783729314804,
      -0.028919288888573647,
      -0.02253105863928795,
      0.008518390357494354,
      0.0815758928656578,
      0.010499711148440838,
      0.0230632945895195,
      -0.04626575857400894,
      0.0069146170280873775,
      0.03597255423665047,
      -0.029035240411758423,
      0.065432608127594,
      0.002641102997586131,
      0.02724650502204895,
      0.004295553546398878,
      -0.030885014683008194,
      -0.051846034824848175,
      0.04359694942831993,
      -0.03590468317270279,
      0.036485929042100906,
      0.006498780567198992,
      0.05857117846608162,
      0.006905895192176104,
      -0.017442893236875534,
      0.09575718641281128,
      0.002937364624813199,
      0.00444790069013834,
      0.08176859468221664,
      0.006883212365210056,
      -0.02739792875945568,
      -0.06588093936443329,
      0.019149649888277054,
      0.03540962561964989,
      0.03675488010048866,
      -0.06659083068370819,
      -0.01468361634761095,
      -0.013983144424855709,
      -0.04542066901922226,
      0.01992318592965603,
      0.054839879274368286,
      -0.03176455572247505,
      -0.024582987651228905,
      0.021196210756897926,
      0.002136311959475279,
      -0.020702166482806206,
      -0.0006545575452037156,
      -0.018434038385748863,
      0.011187266558408737,
      -0.07313767820596695,
      -0.015067062340676785,
      0.06362756341695786,
      -0.008548814803361893,
      0.033242832869291306,
      0.020920846611261368,
      0.08637909591197968,
      0.008718324825167656,
      0.017777947708964348,
      -0.016095459461212158,
      0.060701921582221985,
      -0.09809692203998566,
      0.03673258423805237,
      -0.03742434084415436,
      0.020420007407665253,
      0.004271341487765312,
      0.03697521612048149,
      -0.04211202263832092,
      0.037464842200279236,
      0.023268621414899826,
      0.013304851949214935,
      0.053370751440525055,
      -0.030597716569900513,
      -0.004530667793005705,
      -0.006121874786913395,
      -0.025999585166573524,
      -0.04318875074386597,
      0.047422364354133606,
      0.12372181564569473,
      -0.03502364829182625,
      -0.04114669933915138,
      0.055109571665525436,
      -0.04288751631975174,
      0.039155442267656326,
      0.0024788319133222103,
      0.019007297232747078,
      0.06595209985971451,
      0.056354258209466934,
      0.002238317159935832,
      -0.010180589742958546,
      -0.07358411699533463,
      0.034965597093105316,
      0.018541773781180382,
      -0.004520096816122532,
      -0.1391783356666565,
      0.0016891451086848974,
      -0.0731244683265686,
      -0.009559622034430504,
      0.0006997354794293642,
      -0.0007290774956345558,
      -0.05242137610912323,
      0.06357770413160324,
      -0.005059732124209404,
      -0.010640379972755909,
      0.015023906715214252,
      -0.022216932848095894,
      -0.08194515109062195,
      -0.015120035037398338,
      0.03061319701373577,
      0.018573593348264694,
      0.027630187571048737
    ],
    [
      -0.01765759475529194,
      0.032228004187345505,
      -0.05687515810132027,
      -0.03780927136540413,
      0.07142626494169235,
      0.037444718182086945,
      0.05613303184509277,
      -0.005162875633686781,
      -0.03838980942964554,
      -0.06389705836772919,
      -0.05755399912595749,
      0.06251301616430283,
      -0.09060479700565338,
      0.07270586490631104,
      0.03886745497584343,
      -0.0006418659468181431,
      -0.04440687969326973,
      0.004367833957076073,
      -0.024836087599396706,
      0.05887753516435623,
      0.032780926674604416,
      -0.08563366532325745,
      0.03522403910756111,
      0.019612105563282967,
      -0.017832886427640915,
      0.03937302902340889,
      -0.06457541882991791,
      -0.027408791705965996,
      0.01732770912349224,
      0.030233316123485565,
      0.019465524703264236,
      0.024367403239011765,
      -0.03464428335428238,
      -0.000851969700306654,
      0.005820623133331537,
      0.050505273044109344,
      0.019438158720731735,
      0.016337547451257706,
      0.00217051082290709,
      0.012531835585832596,
      -0.004189284984022379,
      0.07018610835075378,
      0.04869319126009941,
      -0.012103335931897163,
      0.04005279764533043,
      -0.010062724351882935,
      0.00366561789996922,
      -0.019178882241249084,
      -0.0418691411614418,
      0.03369016572833061,
      -0.054205700755119324,
      -0.0024400746915489435,
      0.028559071943163872,
      0.0369890071451664,
      0.09023083001375198,
      0.00842377357184887,
      0.003969512414187193,
      0.07627580314874649,
      -0.047002118080854416,
      0.0032819481566548347,
      -0.0029195600654929876,
      0.028322428464889526,
      -0.03829881548881531,
      -0.06124010682106018,
      0.05695164576172829,
      0.014176820404827595,
      -0.05274003744125366,
      0.05046037212014198,
      -0.012433279305696487,
      -0.016848253086209297,
      -0.007328044157475233,
      0.02202390879392624,
      -0.008359159342944622,
      0.01646542176604271,
      -0.04067903384566307,
      0.01632680743932724,
      0.06093984842300415,
      0.05313761532306671,
      -0.046485017985105515,
      0.03881177306175232,
      -0.05723271146416664,
      0.052282530814409256,
      -0.030450405552983284,
      0.037092261016368866,
      -0.006784798111766577,
      0.06660307198762894,
      -0.031505610793828964,
      -0.03174692019820213,
      0.029658259823918343,
      0.018734605982899666,
      0.008944002911448479,
      -0.004671894479542971,
      -0.06953434646129608,
      -0.0021473143715411425,
      -0.02152613177895546,
      -0.009518741630017757,
      0.03720872476696968,
      0.024119026958942413,
      0.03370092064142227,
      -0.032552216202020645,
      0.010657303035259247,
      -0.01986015774309635,
      0.006446024402976036,
      0.011323596350848675,
      0.0460546538233757,
      -0.014727378264069557,
      -0.03726121783256531,
      -0.011152179911732674,
      0.07590442895889282,
      -0.02858220413327217,
      0.008438773453235626,
      0.014477547258138657,
      -0.01314784400165081,
      0.02850187011063099,
      -0.02654368244111538,
      -0.06266681104898453,
      0.024505654349923134,
      0.01322332862764597,
      -0.01910552941262722,
      -0.04092612490057945,
      -0.06016238033771515,
      -0.0017376175383105874,
      -0.03900966793298721,
      0.026191068813204765,
      -0.05529196932911873,
      0.06072672829031944,
      0.01493236143141985,
      0.015950128436088562,
      -0.03209654614329338,
      0.03368932753801346,
      -0.03290167078375816,
      -0.045200858265161514,
      0.10974042117595673,
      0.002570879179984331,
      -0.0017617892008274794,
      0.04716901481151581,
      0.05542442947626114,
      -0.03432155400514603,
      0.04723850637674332,
      0.0714292898774147,
      -0.007149961777031422,
      -0.04836687818169594,
      0.019167913123965263,
      -0.00894349068403244,
      -0.037601303309202194,
      -0.04812182858586311,
      -0.01918509230017662,
      0.01831437461078167,
      0.030817214399576187,
      -0.01078932173550129,
      0.014015772379934788,
      0.09924303740262985,
      -0.054481759667396545,
      -0.05897673964500427,
      -0.044949617236852646,
      0.007319603115320206,
      -0.005270537920296192,
      -0.033655185252428055,
      0.06467565149068832,
      -0.039621781557798386,
      0.07173096388578415,
      0.02183447778224945,
      -0.020302554592490196,
      0.04217148199677467,
      -0.049652986228466034,
      0.01910416968166828,
      -0.04091170057654381,
      0.03370785713195801,
      -0.023256918415427208,
      -0.03870765119791031,
      0.02234766073524952,
      0.058171648532152176,
      0.023094255477190018,
      0.07489198446273804,
      -0.02924947626888752,
      0.021209433674812317,
      -0.017805634066462517,
      -0.0367075651884079,
      -0.0825270414352417,
      0.02603111043572426,
      -0.04397783428430557,
      0.01710943691432476,
      0.005318125244230032,
      -0.012173179537057877,
      -0.02005367912352085,
      -0.02754710242152214,
      0.075330950319767,
      0.013678562827408314,
      -0.027388853952288628,
      -0.036951154470443726,
      0.022614631801843643,
      -0.009901071898639202,
      -0.010563298128545284,
      0.017711052671074867,
      -0.039598483592271805,
      0.030165497213602066,
      -0.04807315766811371,
      0.01705213636159897,
      0.02450750581920147,
      -0.08178071677684784,
      0.04136161506175995,
      -0.014922048896551132,
      -0.031571533530950546,
      0.07321297377347946,
      -0.08595362305641174,
      -0.06960716098546982,
      0.04134378954768181,
      0.0828869491815567,
      0.08107417821884155,
      0.046338003128767014,
      -0.038480352610349655,
      0.10424654185771942,
      0.010223808698356152,
      -0.08455781638622284,
      0.06009669229388237,
      0.00018825105507858098,
      0.02567087486386299,
      -0.015058939345180988,
      -0.02027573063969612,
      0.024427764117717743,
      -0.03436178341507912,
      -0.029604705050587654,
      -0.08912904560565948,
      -0.04768069088459015,
      -0.08382317423820496,
      -0.017138181254267693,
      0.030524205416440964,
      -0.029034875333309174,
      -0.048099126666784286,
      0.00606016768142581,
      0.013217784464359283,
      0.021172549575567245,
      0.034814007580280304,
      0.01823546178638935,
      -0.0068540130741894245,
      -0.019427403807640076,
      -0.08611150830984116,
      0.01367387268692255,
      -0.022038012742996216,
      0.001134732854552567,
      -0.002113869646564126,
      -0.0778447762131691,
      -0.057291433215141296,
      0.025273336097598076,
      0.08440829813480377,
      0.02504448965191841,
      -0.04769625514745712,
      0.05824105069041252,
      -0.023757150396704674,
      -0.011305338703095913,
      0.01894073747098446,
      -0.02242647297680378,
      -0.02313562110066414,
      0.03966343402862549,
      0.018697533756494522,
      -0.03765544295310974,
      0.011498354375362396,
      -0.08136963844299316,
      -0.03933224454522133,
      -0.035561371594667435,
      -0.014652443118393421,
      0.01239626295864582,
      -0.07960706949234009,
      0.04900164529681206,
      0.026553727686405182,
      -0.014358318410813808,
      -0.010769199579954147,
      -0.009701350703835487,
      -0.03325500711798668,
      -0.03309354931116104,
      0.01937631145119667,
      0.023199601098895073,
      0.10156846046447754,
      -0.05684690922498703,
      -0.06504753977060318,
      -0.018986715003848076,
      -0.026333969086408615,
      0.048861369490623474,
      0.022865718230605125,
      0.055627401918172836,
      -0.043201398104429245,
      -0.0027600997127592564,
      0.08611325174570084,
      0.0522475466132164,
      -0.007833055220544338,
      0.08427229523658752,
      0.014417805708944798,
      -0.001145318616181612,
      0.014601333066821098,
      -0.06791846454143524,
      -0.05442383140325546,
      -0.05162269249558449,
      -0.020250806584954262,
      -0.02803598716855049,
      0.0007671049097552896,
      0.01207722071558237,
      0.018561944365501404,
      0.04034591093659401,
      0.031065620481967926,
      -0.036345839500427246,
      0.005371218081563711,
      -0.044076576828956604,
      -0.014560844749212265,
      -0.03866071254014969,
      -0.00220142537727952,
      0.00247396738268435,
      -0.061345260590314865,
      0.05139755830168724,
      0.028123600408434868,
      -0.02271532267332077,
      -0.05733228847384453,
      0.005431385710835457,
      -0.0005726425442844629,
      -0.045289844274520874,
      -0.044870827347040176,
      -0.02461317926645279,
      0.00988577026873827,
      -0.031255193054676056,
      0.05231213569641113,
      0.14525067806243896,
      -0.015175233595073223,
      -0.0047378093004226685,
      0.08398646861314774,
      -0.0051125152967870235,
      -0.01029258780181408,
      0.006780880969017744,
      0.06906925141811371,
      0.0315077006816864,
      0.08006462454795837,
      -0.13428686559200287,
      0.02476167492568493,
      -0.061042606830596924,
      0.05535773187875748,
      -0.050280097872018814,
      0.043730638921260834,
      0.010052361525595188,
      0.02168077789247036,
      -0.11706876754760742,
      0.04379848763346672,
      0.03206373378634453,
      -0.0719357505440712,
      0.009853922761976719,
      -0.030306406319141388,
      -0.015355897136032581,
      0.10093551874160767,
      0.05927666649222374,
      -0.06077263504266739,
      -0.01765710860490799,
      0.010148349218070507,
      -0.08204855769872665,
      0.05237315222620964,
      -0.046172499656677246,
      -0.048497848212718964,
      -0.02718256041407585,
      0.02016523852944374,
      -0.018524229526519775,
      -0.05722488462924957,
      -0.06629107892513275,
      0.0346769355237484,
      0.06653965264558792,
      0.04837178811430931,
      -0.10727393627166748,
      -0.04403316602110863,
      0.014326679520308971,
      0.004066998139023781,
      -0.005110464990139008,
      0.0002359501231694594,
      0.007482901215553284,
      0.04011278226971626,
      -0.02000393718481064,
      0.019947700202465057,
      -0.08123601227998734,
      0.004751313012093306,
      0.049671269953250885,
      0.011846349574625492,
      -0.07606181502342224,
      0.07474390417337418,
      0.03284398466348648,
      -0.010340753942728043,
      -0.008004422299563885,
      -0.02550521492958069,
      -0.0244106687605381,
      -0.06645239889621735,
      -0.05710632726550102,
      -0.06039155647158623,
      0.01314160879701376,
      -0.05779861658811569,
      0.040217675268650055,
      -0.007238694932311773,
      0.03147689625620842,
      -0.10961061716079712,
      -0.03303401172161102,
      -0.07293196022510529,
      -0.04862317070364952,
      0.0023815203458070755,
      -0.015670279040932655,
      0.011209814809262753,
      -0.014692130498588085,
      0.021260157227516174,
      -0.021601006388664246,
      0.0018643464427441359,
      0.048521216958761215,
      0.0144656328484416,
      -0.0003493849071674049,
      -0.06778381019830704,
      -0.08173971623182297,
      -0.025221223011612892,
      0.015460573136806488,
      0.030244367197155952,
      0.015107466839253902,
      -0.012914531864225864,
      -0.04324673116207123,
      0.006172012537717819,
      0.04398287460207939,
      -0.07827038317918777,
      -0.037547316402196884,
      -0.02101517841219902,
      0.008498265407979488,
      0.03968154266476631,
      -0.03239775821566582,
      0.0029411076102405787,
      0.003544726176187396,
      -0.015901632606983185,
      0.01462650578469038,
      -0.06574229896068573,
      -0.018254239112138748,
      -0.008139431476593018,
      0.06038616970181465,
      0.042371854186058044,
      -0.08987287431955338,
      -0.057430826127529144,
      -0.006964172702282667,
      -0.035057324916124344,
      0.03550775721669197,
      -0.019318003207445145,
      -0.04644285514950752,
      0.0820150077342987,
      -0.05840887129306793,
      0.06368041038513184,
      0.11240449547767639,
      0.01776825077831745,
      -0.023979099467396736,
      -0.03194037079811096,
      0.03135313838720322,
      -0.04331133887171745,
      -0.09197212010622025,
      0.05389399453997612,
      0.1239800751209259,
      0.04946041479706764,
      -0.029734596610069275,
      -0.06277760863304138,
      0.03926941007375717,
      0.012586570344865322,
      -0.018087035045027733,
      -0.013401173055171967,
      0.03966919705271721,
      -0.03218083456158638,
      0.03802188113331795,
      0.056302838027477264,
      0.028603872284293175,
      -0.053477659821510315,
      0.04123508557677269,
      0.06761427223682404,
      -0.031318631023168564,
      -0.022602174431085587,
      -0.015353250317275524,
      0.04518650472164154,
      -0.08311700075864792,
      -0.07899674028158188,
      0.002620565937831998,
      -0.04837261885404587,
      -0.012631170451641083,
      -0.02640633098781109,
      -0.038402386009693146,
      0.0391717292368412,
      0.047402311116456985,
      -0.1029687225818634,
      -0.026880614459514618,
      0.08086222410202026,
      -0.03922582045197487,
      0.06754397600889206,
      -0.015087994746863842,
      -0.056849006563425064,
      -0.020031770691275597,
      -0.011222617700695992,
      0.0066057536751031876,
      -0.018099600449204445,
      -0.005595690570771694,
      0.033420540392398834,
      0.014938842505216599,
      -0.039204709231853485,
      -0.06688521057367325,
      0.07798063009977341,
      0.016781169921159744,
      -0.018811073154211044,
      0.060704659670591354,
      0.01119227521121502,
      0.04349622130393982,
      0.036969881504774094,
      0.08170074224472046,
      0.011565160006284714,
      0.013026733882725239,
      -0.08260241150856018
    ],
    [
      0.10778098553419113,
      -0.02848617546260357,
      -0.08459728956222534,
      0.015551768243312836,
      0.016381284222006798,
      -0.002013921504840255,
      0.005022451281547546,
      0.017131982371211052,
      0.06660329550504684,
      -0.0267239511013031,
      0.00667992141097784,
      -0.018853893503546715,
      0.012798653915524483,
      -0.027120832353830338,
      0.04786425456404686,
      -0.004801928997039795,
      0.0060757827013731,
      0.11490970104932785,
      0.08284357935190201,
      0.01372535154223442,
      -0.04332035034894943,
      0.01741897128522396,
      -0.017337797209620476,
      0.02917673997581005,
      -0.11704997718334198,
      0.027544287964701653,
      -0.009008488617837429,
      0.0005594085669144988,
      -0.02229558490216732,
      -0.03756099194288254,
      -0.042398445308208466,
      0.007858526892960072,
      -0.042668845504522324,
      0.021641133353114128,
      0.06337227672338486,
      -0.04272552579641342,
      0.04060305655002594,
      0.02484182082116604,
      -0.0020606473553925753,
      -0.04409007728099823,
      -0.10084156692028046,
      0.047258295118808746,
      -0.04081052169203758,
      -0.004497326444834471,
      -0.05637259781360626,
      0.05565737560391426,
      0.011065100319683552,
      0.03378574550151825,
      0.044305264949798584,
      -0.012877282686531544,
      -0.07430288940668106,
      -0.08276692777872086,
      0.05449488386511803,
      -0.01586565561592579,
      0.01914694905281067,
      -0.1054781898856163,
      -0.042032789438962936,
      0.03187943622469902,
      0.005999404471367598,
      -0.021423988044261932,
      0.01088444609194994,
      -0.004558729473501444,
      0.05959189310669899,
      0.07209073752164841,
      -0.05185021087527275,
      0.006040499545633793,
      0.040990762412548065,
      0.07627114653587341,
      -0.017179209738969803,
      0.01823895052075386,
      0.06627555191516876,
      -0.019421987235546112,
      -0.036107756197452545,
      0.0231917891651392,
      0.018196480348706245,
      -0.05383206903934479,
      -0.01837008260190487,
      -0.00677367951720953,
      0.038203366100788116,
      -0.03953773155808449,
      0.009652592241764069,
      0.07611394673585892,
      0.0024016511160880327,
      0.035534463822841644,
      0.061786942183971405,
      -0.02223769575357437,
      0.08734416961669922,
      -0.08822660893201828,
      -0.007848517969250679,
      -0.08518867194652557,
      0.020302359014749527,
      0.003117368323728442,
      -0.06441196799278259,
      -0.056189779192209244,
      0.05312392860651016,
      -0.024663684889674187,
      0.05420791357755661,
      -0.04625174030661583,
      0.06197430193424225,
      -0.010738265700638294,
      0.017837194725871086,
      -0.05790727585554123,
      -0.012421821244060993,
      -0.060649748891592026,
      0.024928970262408257,
      -0.025308340787887573,
      -0.02992788329720497,
      -0.017790470272302628,
      0.03747567906975746,
      0.066818006336689,
      -0.010253632441163063,
      0.0461617112159729,
      0.027155408635735512,
      -0.0035642513539642096,
      -0.06776183098554611,
      0.050385721027851105,
      0.017713205888867378,
      -0.054173048585653305,
      0.09576477855443954,
      0.06447058916091919,
      0.024083061143755913,
      -0.02236691676080227,
      -0.0375615730881691,
      -0.013722852803766727,
      0.0516357496380806,
      0.017936434596776962,
      0.03747417405247688,
      0.02715899981558323,
      0.05883101373910904,
      0.03336063027381897,
      -0.02957887575030327,
      -0.022539498284459114,
      -0.10495266318321228,
      0.08286894112825394,
      -0.009779755026102066,
      -0.0921536386013031,
      -0.03969969227910042,
      0.01902022399008274,
      0.010262428782880306,
      0.03765029087662697,
      -0.01927022635936737,
      0.02599206380546093,
      -0.022042816504836082,
      -0.02947082743048668,
      0.0338759608566761,
      -0.015900922939181328,
      -0.09190153330564499,
      -0.06562703102827072,
      0.06138245016336441,
      -0.019409945234656334,
      0.0465024895966053,
      0.06913921236991882,
      -0.041885919868946075,
      -0.00770185049623251,
      -0.04427576810121536,
      -0.021359341219067574,
      -0.046710748225450516,
      -0.009044298902153969,
      -0.0740988701581955,
      0.10663937777280807,
      -0.013613133691251278,
      -0.08350333571434021,
      -0.007412318140268326,
      -0.010338832624256611,
      0.015889203175902367,
      0.08171496540307999,
      -0.03294616937637329,
      0.06898283958435059,
      -0.0037862660828977823,
      0.0010571677703410387,
      0.05565296858549118,
      0.004740165080875158,
      0.0007678177789784968,
      0.035618361085653305,
      0.016279416158795357,
      0.0037609904538840055,
      0.045856572687625885,
      -0.06847162544727325,
      -0.0001153389093815349,
      0.034291885793209076,
      -0.023719344288110733,
      -0.06760760396718979,
      0.026106026023626328,
      0.09180641919374466,
      -0.05498000234365463,
      -0.038666315376758575,
      -0.07834848761558533,
      0.04163321852684021,
      0.03968585655093193,
      0.07705337554216385,
      0.10735954344272614,
      0.03617154434323311,
      -0.012483261525630951,
      0.01577584445476532,
      -0.051089752465486526,
      -0.08829743415117264,
      -0.012380930595099926,
      0.028679704293608665,
      -0.0075684101320803165,
      -0.05501573160290718,
      0.015011276118457317,
      0.01015524659305811,
      -0.01347216684371233,
      0.07536637037992477,
      -0.02192377857863903,
      0.0026178655680269003,
      0.08023570477962494,
      0.02555130235850811,
      0.04940656200051308,
      -0.04256049171090126,
      -0.043234385550022125,
      0.021397432312369347,
      -0.10921918600797653,
      -0.0974913239479065,
      -0.02036322094500065,
      -0.04322504624724388,
      0.01642289012670517,
      0.0347084142267704,
      -0.010696817189455032,
      -0.06700964272022247,
      -0.04624110087752342,
      -0.0032439855858683586,
      -0.006313191261142492,
      -0.021516429260373116,
      0.028520602732896805,
      -0.021726736798882484,
      -0.045647431164979935,
      -0.007910206913948059,
      0.029292723163962364,
      -0.03003063052892685,
      -0.008607934229075909,
      0.012668454088270664,
      0.04968925938010216,
      0.0951123908162117,
      0.00717747351154685,
      0.002383431186899543,
      0.011933908797800541,
      0.0233277827501297,
      -0.01389866229146719,
      0.052465394139289856,
      0.02538505382835865,
      -0.08431903272867203,
      0.01359711866825819,
      -0.03621141240000725,
      0.028627853840589523,
      -0.03399021923542023,
      -0.011227671056985855,
      0.015351683832705021,
      0.06318619847297668,
      0.03266817331314087,
      0.009061351418495178,
      0.008711942471563816,
      -0.10337793081998825,
      -0.049605369567871094,
      0.036119841039180756,
      0.005800402257591486,
      0.015159444883465767,
      -0.017669476568698883,
      -0.011402499862015247,
      -0.0021499067079275846,
      0.011805444024503231,
      0.0175306536257267,
      0.06420498341321945,
      0.035921208560466766,
      -0.01490772981196642,
      -0.010960699059069157,
      -0.026791641488671303,
      0.010241016745567322,
      0.028564993292093277,
      -0.07737179845571518,
      -0.007227233145385981,
      -0.06263240426778793,
      -0.11474044620990753,
      -0.01939472369849682,
      -0.029315631836652756,
      -0.052347682416439056,
      -0.048570845276117325,
      0.05275683104991913,
      -0.029109643772244453,
      -0.012074172496795654,
      0.004254736937582493,
      -0.07588853687047958,
      0.025591522455215454,
      0.0943794772028923,
      0.036452438682317734,
      -0.02890804596245289,
      0.002168511739000678,
      0.033740751445293427,
      0.013176160864531994,
      -0.08613894879817963,
      0.009897732175886631,
      -0.03245926275849342,
      0.019459659233689308,
      -0.0023549417965114117,
      0.019309120252728462,
      0.0057641444727778435,
      0.07192970812320709,
      -0.03586031496524811,
      0.07362084090709686,
      -0.007713833823800087,
      0.03323408216238022,
      0.02925005927681923,
      0.01774435304105282,
      -0.08612967282533646,
      0.04371137544512749,
      -0.004459287039935589,
      -0.02228732593357563,
      -0.04178186133503914,
      -0.02613234706223011,
      -0.03604904189705849,
      0.013395173475146294,
      -0.024403031915426254,
      0.04308833181858063,
      0.015518615953624249,
      -0.045867081731557846,
      0.0756109356880188,
      -0.0659882202744484,
      0.015448393300175667,
      -0.02286176197230816,
      0.03136683255434036,
      0.05064595863223076,
      -0.07840985804796219,
      0.06311652064323425,
      0.027667095884680748,
      0.035074230283498764,
      -0.043836865574121475,
      0.0369853600859642,
      0.05176185816526413,
      -0.10661546140909195,
      -0.00442163459956646,
      -0.06276866048574448,
      -0.04246029257774353,
      -0.03126341849565506,
      -0.09424112737178802,
      -0.03976089879870415,
      0.013727179728448391,
      0.02594665065407753,
      0.021181421354413033,
      0.09804244339466095,
      0.021975478157401085,
      -0.004197224508970976,
      -0.004208411555737257,
      0.046763382852077484,
      -0.04136652126908302,
      0.0852934867143631,
      -0.04753875359892845,
      -0.01588943786919117,
      0.029320569708943367,
      0.02959834598004818,
      -0.012107607908546925,
      0.04006987810134888,
      -0.017022976651787758,
      0.029452726244926453,
      0.08614534139633179,
      0.008238943293690681,
      0.023936443030834198,
      -0.07016018778085709,
      -0.01635955460369587,
      -0.03970908373594284,
      -0.03200023993849754,
      0.05454864725470543,
      -0.01600794866681099,
      0.054653555154800415,
      0.015842603519558907,
      -0.02084944024682045,
      0.03939494118094444,
      0.03217541426420212,
      -0.021625611931085587,
      0.06955697387456894,
      0.02909894473850727,
      -0.012200131081044674,
      -0.02439340204000473,
      -0.013974531553685665,
      0.06332387775182724,
      -0.02963166870176792,
      -0.017066212370991707,
      0.026962587609887123,
      0.028856731951236725,
      -0.026921894401311874,
      0.000669404398649931,
      -0.04684871435165405,
      -0.06459469348192215,
      -0.0042747631669044495,
      -0.022744527086615562,
      0.0668894499540329,
      -0.07353908568620682,
      -0.015416963025927544,
      0.020638586953282356,
      0.03692460060119629,
      0.018941553309559822,
      0.019578270614147186,
      -0.03993946313858032,
      -0.0012195681920275092,
      -0.002781394636258483,
      0.03850821033120155,
      0.025643661618232727,
      -0.04909423738718033,
      -0.015251274220645428,
      -0.038830459117889404,
      -0.03907202184200287,
      0.07433001697063446,
      0.015552923083305359,
      -0.008480513468384743,
      0.05421082675457001,
      0.05575396120548248,
      -0.05107632279396057,
      0.009826689958572388,
      0.02519996091723442,
      0.04405143857002258,
      0.05312766507267952,
      -0.07500497996807098,
      -0.008308331482112408,
      0.03550943732261658,
      0.032590024173259735,
      0.02584112249314785,
      0.03852539882063866,
      0.045644693076610565,
      0.024370789527893066,
      -0.04736940562725067,
      0.004837349988520145,
      0.0015976008726283908,
      -0.014911944977939129,
      0.05314171686768532,
      0.003474797587841749,
      0.014219071716070175,
      0.03512716665863991,
      0.028678711503744125,
      0.03442786633968353,
      0.01020768377929926,
      -0.03428710624575615,
      -0.0533360056579113,
      -0.06509370356798172,
      0.040156442672014236,
      0.07000923156738281,
      0.04947039112448692,
      -0.07560499012470245,
      -0.009137501940131187,
      0.038376957178115845,
      0.07256019860506058,
      0.037445101886987686,
      -0.012259858660399914,
      0.001381064997985959,
      0.06264181435108185,
      -0.023466292768716812,
      -0.0006418356206268072,
      -0.019316159188747406,
      0.01870000548660755,
      0.038752615451812744,
      -0.06176793947815895,
      0.015519321896135807,
      0.003921578638255596,
      -0.0015852149808779359,
      0.005188853945583105,
      0.014155595563352108,
      -0.023038571700453758,
      -0.00922182947397232,
      -0.05154798552393913,
      0.10716947168111801,
      0.05166473984718323,
      -0.13297945261001587,
      0.034959230571985245,
      -0.03812307491898537,
      -0.030029602348804474,
      -0.01761198788881302,
      -0.05404960364103317,
      0.03844255208969116,
      0.031840015202760696,
      0.013750365935266018,
      -0.04447925463318825,
      0.059131916612386703,
      0.06800542026758194,
      -0.023048635572195053,
      -0.06718265265226364,
      0.029097698628902435,
      -0.06267464905977249,
      -0.02074483595788479,
      0.04438657686114311,
      0.01934022083878517,
      0.10477063059806824,
      0.007295941933989525,
      -0.023518888279795647,
      0.025724314153194427,
      -0.026875196024775505,
      0.015080882236361504,
      0.05036606267094612,
      0.002189458580687642,
      -0.10620244592428207,
      -0.02170676179230213,
      0.027081385254859924,
      -0.030987363308668137,
      -0.017366914078593254,
      -0.02818395383656025,
      -0.04553414136171341,
      -0.02811342105269432,
      0.05158698186278343,
      -0.0018835690570995212,
      0.05073932930827141,
      -0.08918284624814987,
      -0.02560201659798622,
      0.01130136288702488,
      -0.02271944284439087,
      0.005463547073304653,
      0.016789071261882782
    ],
    [
      -0.015172147192060947,
      -0.034499187022447586,
      -0.02609599567949772,
      0.016916170716285706,
      0.06451982259750366,
      0.00876316986978054,
      0.034522704780101776,
      -0.0530255101621151,
      -0.008773155510425568,
      0.1034134179353714,
      -0.008387173525989056,
      -0.05001630261540413,
      -0.02585596591234207,
      0.004719371907413006,
      0.0808035284280777,
      0.0050624520517885685,
      0.000639115460216999,
      -0.07042579352855682,
      0.03703220188617706,
      -0.0019884491339325905,
      0.02405175007879734,
      0.06071610748767853,
      -0.04444687068462372,
      -0.03466009348630905,
      -0.056112147867679596,
      0.018226036801934242,
      0.04284388944506645,
      0.01761293038725853,
      -0.015438798815011978,
      -0.02066999301314354,
      0.08553837984800339,
      -0.07464448362588882,
      0.009477796033024788,
      0.08272600919008255,
      -0.008935357443988323,
      0.027062613517045975,
      0.00036296629696153104,
      0.0074984608218073845,
      -0.0584704652428627,
      -0.02682081051170826,
      -0.044882919639348984,
      0.044581394642591476,
      -0.025433940812945366,
      0.024587906897068024,
      -0.08912001550197601,
      0.03493190556764603,
      -0.010111759416759014,
      -0.052248865365982056,
      -0.12584249675273895,
      0.07119884341955185,
      -0.016685716807842255,
      0.015288791619241238,
      -0.03814148157835007,
      -0.0036879610270261765,
      0.0206819549202919,
      -0.0709308609366417,
      0.0014584941091015935,
      -0.0017057007644325495,
      -0.021456599235534668,
      -0.05436834692955017,
      0.013669313862919807,
      0.04798036441206932,
      -0.07517880946397781,
      -0.07651008665561676,
      -0.03611132502555847,
      -0.01660970039665699,
      -0.03549374267458916,
      -0.06494612991809845,
      0.024216948077082634,
      -0.038211286067962646,
      0.029536090791225433,
      -0.024568775668740273,
      -0.023278096690773964,
      0.0064890384674072266,
      0.012494932860136032,
      -0.024639705196022987,
      0.07182378321886063,
      -0.012662976048886776,
      0.03498184680938721,
      -0.047631219029426575,
      0.04814822971820831,
      -0.014051849022507668,
      -0.07267121225595474,
      0.03793128952383995,
      -0.0074098119512200356,
      -0.008772949688136578,
      0.0017453118925914168,
      0.07530922442674637,
      0.1340576410293579,
      0.0048911613412201405,
      0.020450057461857796,
      -0.003991231322288513,
      -0.04921972006559372,
      -0.031621262431144714,
      0.013838372193276882,
      0.014250798150897026,
      -0.03176737204194069,
      0.049656543880701065,
      -0.011238448321819305,
      -0.00514607410877943,
      -0.006454841233789921,
      -0.0003965571813751012,
      -0.05582708120346069,
      -0.010545335710048676,
      0.05468008294701576,
      0.042157500982284546,
      0.06825791299343109,
      0.09682764858007431,
      -0.09019981324672699,
      0.05740119516849518,
      0.02071218565106392,
      0.018525125458836555,
      -0.01824234239757061,
      -0.035411443561315536,
      0.06068658083677292,
      0.059760838747024536,
      0.01792709156870842,
      -0.036328963935375214,
      -0.03208572417497635,
      0.03451470285654068,
      -0.0031461070757359266,
      -0.015784574672579765,
      -0.04063553735613823,
      -0.030839407816529274,
      -0.032444700598716736,
      -0.002288501476868987,
      -0.05259255692362785,
      0.08787714689970016,
      0.10409893840551376,
      -0.0320868156850338,
      -0.05645035207271576,
      0.04916074872016907,
      0.003219922073185444,
      -0.017442619428038597,
      -0.06950554251670837,
      -0.03667081147432327,
      -0.0003184028319083154,
      0.02323225513100624,
      -0.009616454131901264,
      -0.05130377784371376,
      -0.04403931275010109,
      -0.010303759016096592,
      0.009375440888106823,
      0.04892604425549507,
      0.032085616141557693,
      -0.0010435010772198439,
      -0.020908037200570107,
      -0.018180260434746742,
      -0.012044757604598999,
      0.03004850633442402,
      -0.039510469883680344,
      0.025435011833906174,
      -0.0009175572195090353,
      0.02692132629454136,
      0.005897502414882183,
      -0.10693435370922089,
      -0.03924313932657242,
      0.028272733092308044,
      0.0573154054582119,
      0.023467641323804855,
      -0.00430692732334137,
      -0.047867391258478165,
      0.005785499233752489,
      -0.02869013324379921,
      0.04137440770864487,
      0.08636097609996796,
      0.032103281468153,
      0.013106608763337135,
      -0.039047881960868835,
      0.02727028727531433,
      -0.01601654477417469,
      -0.032721519470214844,
      0.0632549524307251,
      -0.01365712471306324,
      0.023551341146230698,
      -0.10449264198541641,
      0.023370616137981415,
      -0.07759947329759598,
      -0.07999402284622192,
      0.03838386386632919,
      -0.009083956480026245,
      -0.031802672892808914,
      0.08196761459112167,
      -0.03231142833828926,
      0.07663843035697937,
      0.07381467521190643,
      -0.006515556015074253,
      0.02797040529549122,
      -0.07095862925052643,
      -0.01622154377400875,
      0.0725330337882042,
      -0.01311560533940792,
      0.04348800703883171,
      -0.05919681861996651,
      -0.008762781508266926,
      -0.018364576622843742,
      0.027062900364398956,
      0.03340832144021988,
      0.060108672827482224,
      -0.02569974586367607,
      -0.05553670600056648,
      -0.00976148247718811,
      0.040552712976932526,
      0.056047070771455765,
      -0.0037401344161480665,
      0.023557255044579506,
      0.09042392671108246,
      0.02878456562757492,
      0.01296545285731554,
      0.029294589534401894,
      -0.06021210551261902,
      0.049604564905166626,
      0.03662794828414917,
      -0.004696022719144821,
      -0.07610098272562027,
      -0.007459430955350399,
      -0.049816761165857315,
      0.018195727840065956,
      -0.05128166824579239,
      0.017438696697354317,
      -0.06891337782144547,
      0.013187677599489689,
      0.007576793897897005,
      -0.015002992004156113,
      -0.00044953805627301335,
      -0.047841876745224,
      -0.02330360747873783,
      -0.00776340626180172,
      -0.04195624962449074,
      -0.06407719850540161,
      -0.0463191382586956,
      -0.003953639883548021,
      -0.004584051202982664,
      0.022389424964785576,
      -0.046576693654060364,
      0.0484030582010746,
      0.09253092110157013,
      -0.04061035439372063,
      -0.034043680876493454,
      0.05038158968091011,
      0.018455687910318375,
      -0.061257120221853256,
      -0.06468723714351654,
      0.0074361166916787624,
      0.10719064623117447,
      -0.02995401993393898,
      0.019108379259705544,
      0.027825865894556046,
      -0.018410595133900642,
      -0.06807900220155716,
      -0.057347215712070465,
      0.05559692904353142,
      0.021226927638053894,
      0.0660676583647728,
      -0.036058101803064346,
      -0.03671779856085777,
      -0.04988809674978256,
      0.058073002845048904,
      0.059904661029577255,
      -0.0009219814091920853,
      -0.041752975434064865,
      0.07419004291296005,
      -0.04161274433135986,
      -0.023835929110646248,
      0.0030322298407554626,
      -0.032176535576581955,
      -0.005148126278072596,
      -0.015910854563117027,
      0.017326246947050095,
      -0.07198077440261841,
      0.01307572703808546,
      -0.06477220356464386,
      0.060684844851493835,
      0.058408401906490326,
      0.014835746958851814,
      0.0292743481695652,
      -0.0015026285545900464,
      -0.046471208333969116,
      -0.05866299197077751,
      0.08455893397331238,
      0.05521414056420326,
      0.049490734934806824,
      -0.031600672751665115,
      0.05904325097799301,
      -0.01652229018509388,
      -0.019033318385481834,
      -0.08442182093858719,
      0.12686742842197418,
      0.061961524188518524,
      0.013614252209663391,
      -0.015392391011118889,
      -0.009473687037825584,
      0.05349849537014961,
      -0.029781434684991837,
      -0.0018125001806765795,
      -0.030319610610604286,
      -0.06653569638729095,
      0.041462525725364685,
      0.002368332352489233,
      0.014158500358462334,
      0.08042510598897934,
      0.03415748104453087,
      0.012310424819588661,
      -0.10172451287508011,
      0.02412598952651024,
      0.03785335272550583,
      -0.013925779610872269,
      -0.037941183894872665,
      -0.053719695657491684,
      0.02748784050345421,
      -0.04070355370640755,
      0.041366152465343475,
      -0.001357774599455297,
      -0.009632150642573833,
      -0.031168021261692047,
      0.009310288354754448,
      0.03509243205189705,
      0.07824312150478363,
      0.04688110947608948,
      0.0016838795272633433,
      0.004900551401078701,
      0.04443514719605446,
      -0.04926664009690285,
      -0.026448015123605728,
      0.024367444217205048,
      0.062155790627002716,
      -0.08413244783878326,
      -0.08451340347528458,
      -0.04597567766904831,
      -0.005329614970833063,
      0.07894651591777802,
      0.006143399514257908,
      0.05468427389860153,
      -0.027323592454195023,
      0.05624246597290039,
      -0.018400147557258606,
      0.012119026854634285,
      -0.009271582588553429,
      0.0012574495049193501,
      0.00994075182825327,
      -0.014947247691452503,
      -0.027837391942739487,
      -0.004411101341247559,
      -0.0431453101336956,
      0.024748114868998528,
      -0.025226512923836708,
      0.018460726365447044,
      -0.06342820823192596,
      0.047407880425453186,
      0.03523334115743637,
      -0.059348899871110916,
      0.023768173530697823,
      -0.0012893551029264927,
      0.006708422675728798,
      0.06842773407697678,
      -0.01284945011138916,
      0.07016219198703766,
      -0.04187686741352081,
      0.05516959726810455,
      -0.004802139941602945,
      0.012041999958455563,
      0.002617810619994998,
      0.05298551172018051,
      0.10466919094324112,
      -0.03278765827417374,
      -0.06448546051979065,
      -0.06743665784597397,
      0.035737376660108566,
      0.051265571266412735,
      -0.041357722133398056,
      -0.01994735561311245,
      0.0021787870209664106,
      0.03295818716287613,
      -0.05107365548610687,
      -0.00752086890861392,
      0.08816120773553848,
      0.07662886381149292,
      -0.0480993315577507,
      -0.012918995693325996,
      0.041019659489393234,
      0.08610506355762482,
      -0.016341006383299828,
      0.13722354173660278,
      0.05941441282629967,
      -0.05041806772351265,
      0.05262360721826553,
      -0.04104774817824364,
      0.01865672506392002,
      -0.09735539555549622,
      -0.03175464645028114,
      -0.01516713760793209,
      -0.030196528881788254,
      0.07401992380619049,
      0.0711451843380928,
      -0.030539831146597862,
      -0.025937577709555626,
      -0.019764369353652,
      0.05144352838397026,
      0.028931766748428345,
      0.059261634945869446,
      -0.06184028834104538,
      0.02820371836423874,
      0.09296172112226486,
      0.04656501114368439,
      -0.05468175187706947,
      0.023717718198895454,
      -0.04618700221180916,
      -0.006987861357629299,
      -0.060639794915914536,
      0.044331274926662445,
      0.04336419701576233,
      -0.003114821622148156,
      -0.007631753571331501,
      0.016578219830989838,
      0.04056765139102936,
      0.02745015360414982,
      -0.04043193161487579,
      0.02419092133641243,
      -0.005395958200097084,
      -0.01082061231136322,
      -0.14942122995853424,
      0.10734444856643677,
      -0.057679541409015656,
      0.005563666578382254,
      0.025105368345975876,
      0.04146657884120941,
      -0.032957788556814194,
      -0.050293829292058945,
      -0.03318377584218979,
      -0.043654654175043106,
      -0.01687655970454216,
      -0.012971227057278156,
      -0.01868533156812191,
      -0.03147592023015022,
      0.024594273418188095,
      -0.11127830296754837,
      -0.024228759109973907,
      -0.04843945801258087,
      -0.008561561815440655,
      -0.00022317531693261117,
      0.08886034041643143,
      0.044776588678359985,
      -0.027659136801958084,
      -0.019848022609949112,
      0.07433230429887772,
      0.010594657622277737,
      -0.018881503492593765,
      -0.022635001689195633,
      -0.006340342108160257,
      0.04008112847805023,
      -0.006983652710914612,
      -0.027447370812296867,
      -0.04207649827003479,
      -0.008206840604543686,
      0.06493783742189407,
      0.00974312238395214,
      0.038362741470336914,
      0.06253846734762192,
      0.012392839416861534,
      0.0685574859380722,
      -0.030286407098174095,
      0.007157128304243088,
      0.027021290734410286,
      -0.015466106124222279,
      0.0030194963328540325,
      0.008380443789064884,
      -0.056146081537008286,
      0.06566374748945236,
      0.00758311478421092,
      0.017452921718358994,
      0.0032775725703686476,
      -0.060436997562646866,
      -0.024694131687283516,
      0.026691537350416183,
      0.03909708559513092,
      0.02877800166606903,
      -0.02239110693335533,
      -0.034616872668266296,
      -0.07432436943054199,
      -0.009541680105030537,
      0.003074612934142351,
      0.09815293550491333,
      -0.009098771959543228,
      -0.05725182592868805,
      -0.024821119382977486,
      -0.05567445233464241,
      -0.039956189692020416,
      0.024983353912830353,
      -0.01568238064646721,
      -0.021584942936897278,
      0.042240727692842484,
      0.08048184961080551,
      -0.04044218733906746,
      0.03476947918534279,
      -0.08060222119092941,
      0.0004956751363351941,
      -0.045716170221567154,
      0.015307730063796043,
      0.06239373981952667,
      -0.04109149053692818,
      0.06441126018762589,
      0.008249635808169842,
      -0.05153192952275276
    ],
    [
      0.043156176805496216,
      -0.04078515246510506,
      -0.007237072568386793,
      -0.10089804232120514,
      0.030391180887818336,
      0.055224400013685226,
      0.07815779000520706,
      -0.0769740417599678,
      0.0535665787756443,
      0.0663241297006607,
      0.039800312370061874,
      -0.01964927837252617,
      0.010382382199168205,
      -0.03354250639677048,
      -0.03741614148020744,
      -0.011612312868237495,
      0.06021628901362419,
      0.0042762551456689835,
      -0.06346940994262695,
      0.05517015606164932,
      0.005599247291684151,
      -0.060031987726688385,
      -0.07648035138845444,
      0.042054228484630585,
      0.04465863108634949,
      0.04934585466980934,
      -0.017322013154625893,
      -0.025297462940216064,
      -0.004837691318243742,
      -0.041837599128484726,
      -0.03502732515335083,
      0.012026013806462288,
      -0.017959199845790863,
      0.08103413879871368,
      -0.0468650721013546,
      -0.02877051755785942,
      -0.01736672967672348,
      -0.10083936899900436,
      0.15039844810962677,
      0.09494877606630325,
      0.018737787380814552,
      -0.05595783144235611,
      -0.021583370864391327,
      -0.011194703169167042,
      0.012049109674990177,
      -0.001204317668452859,
      -0.0014020259259268641,
      -0.0686621442437172,
      0.020193058997392654,
      0.029876237735152245,
      -0.0360148549079895,
      0.05354537069797516,
      -0.025899430736899376,
      -0.02617255225777626,
      -0.04977135732769966,
      -0.05374867096543312,
      -0.027564307674765587,
      -0.010571264661848545,
      0.012450856156647205,
      -0.04068037122488022,
      -0.02493176981806755,
      0.04521258920431137,
      0.10008146613836288,
      -0.025259720161557198,
      -0.031667791306972504,
      -0.048636164516210556,
      0.07408897578716278,
      -0.10001031309366226,
      -0.0018855280941352248,
      -0.02002682350575924,
      -0.008190772496163845,
      -0.02083701267838478,
      -0.02063135616481304,
      0.0024548990186303854,
      0.03573449328541756,
      0.011216404847800732,
      0.09949941188097,
      -0.023359650745987892,
      0.037155989557504654,
      -0.07916833460330963,
      -0.006622405722737312,
      0.04236062988638878,
      -0.009477144107222557,
      -0.0002993086527567357,
      0.017763569951057434,
      -0.011910632252693176,
      0.016187500208616257,
      -0.06641940772533417,
      0.07885869592428207,
      -0.0881543755531311,
      -0.0139509541913867,
      -0.07379091531038284,
      0.000753205269575119,
      -0.06717328727245331,
      0.001030638231895864,
      0.04139188304543495,
      -0.03192969411611557,
      0.026412028819322586,
      0.04447987303137779,
      -0.07331214100122452,
      0.044186387211084366,
      0.00033425696892663836,
      -0.10412286967039108,
      -0.09275833517313004,
      0.003352845087647438,
      -0.001427504699677229,
      -0.07587996125221252,
      -0.02673848159611225,
      0.0418437160551548,
      -0.006545533426105976,
      0.0015546870417892933,
      0.05832614749670029,
      0.0009893141686916351,
      0.046867500990629196,
      -0.06909507513046265,
      -0.052977487444877625,
      0.04944215714931488,
      0.011177773587405682,
      -0.0026706766802817583,
      -0.017225760966539383,
      0.03822863847017288,
      -0.01981430873274803,
      0.07461278885602951,
      0.030592579394578934,
      0.0318865142762661,
      -0.06825734674930573,
      0.03107614256441593,
      0.08813264220952988,
      0.07532601058483124,
      -0.03186078369617462,
      -0.018691901117563248,
      -0.038325488567352295,
      0.051156189292669296,
      0.006410510279238224,
      0.0321829579770565,
      0.026058277115225792,
      0.016648931428790092,
      0.008066304959356785,
      0.06624571233987808,
      0.0021954402327537537,
      0.006590868812054396,
      0.01694687269628048,
      0.039743974804878235,
      -0.024610063061118126,
      0.02640475332736969,
      -0.023746486753225327,
      -0.07850800454616547,
      0.06391496956348419,
      0.04085158184170723,
      0.0027781117241829634,
      0.022711262106895447,
      -0.03656924143433571,
      0.012121211737394333,
      -0.04878668859601021,
      -0.10953793674707413,
      0.0008771466091275215,
      -0.07244429737329483,
      0.02448667772114277,
      -0.013188003562390804,
      0.003166673704981804,
      0.06601883471012115,
      -0.0614815317094326,
      0.048312168568372726,
      -0.042472075670957565,
      0.03862090781331062,
      -0.012027326971292496,
      0.06448899954557419,
      0.029415471479296684,
      0.02247210033237934,
      -0.03853975608944893,
      0.036061011254787445,
      -0.02455984242260456,
      0.06081318482756615,
      -0.020868930965662003,
      0.02708894945681095,
      -0.02184789441525936,
      0.019749699160456657,
      0.03719909116625786,
      -0.0241642314940691,
      -0.06653029471635818,
      -0.06774578243494034,
      0.01605292409658432,
      0.04410579055547714,
      0.05467363819479942,
      0.019838329404592514,
      -0.0775066539645195,
      -0.030854247510433197,
      0.02038632147014141,
      -0.0682821273803711,
      -0.011151885613799095,
      0.013454564847052097,
      -0.13852570950984955,
      0.026171231642365456,
      0.08075729012489319,
      -0.018581131473183632,
      0.03755620867013931,
      0.03248084709048271,
      0.03415362536907196,
      -0.05319706350564957,
      -0.01531521137803793,
      -0.022844647988677025,
      -0.04428602755069733,
      0.04385587200522423,
      0.03875591233372688,
      -0.030637552961707115,
      0.054076872766017914,
      -0.0063781035132706165,
      -0.0041144778952002525,
      0.0012267337879166007,
      -0.026972176507115364,
      0.010139028541743755,
      -0.00681905634701252,
      0.022299164906144142,
      0.013972807675600052,
      -0.03588935732841492,
      0.03135377913713455,
      -0.012978346087038517,
      -0.046202436089515686,
      -0.10202708095312119,
      0.023424465209245682,
      -0.11650747805833817,
      0.015014667063951492,
      -0.003999742213636637,
      0.07317492365837097,
      0.013242230750620365,
      0.059201497584581375,
      -0.021969346329569817,
      0.07034710049629211,
      -0.10031676292419434,
      -0.08332422375679016,
      0.05487331748008728,
      -0.04218243062496185,
      -0.08274149149656296,
      0.07901839166879654,
      -0.007263028994202614,
      -0.06018726900219917,
      -0.028203461319208145,
      -0.0401604026556015,
      -0.040779031813144684,
      0.029521074146032333,
      0.0220461618155241,
      0.07671188563108444,
      -0.04510967805981636,
      -0.032920099794864655,
      0.04978819563984871,
      0.05193672329187393,
      0.03166121244430542,
      -0.041138019412755966,
      0.025326546281576157,
      0.029737429693341255,
      -0.09754742681980133,
      -0.07271493226289749,
      0.04762839525938034,
      0.019955137744545937,
      -0.028692767024040222,
      0.038961149752140045,
      0.02039763331413269,
      0.032451558858156204,
      -0.04731900617480278,
      0.026491845026612282,
      0.059513773769140244,
      0.027551908046007156,
      0.02028144709765911,
      0.05056220293045044,
      0.08848623186349869,
      0.034328509122133255,
      0.023472795262932777,
      -0.02623620256781578,
      -0.010396314784884453,
      -0.05108937993645668,
      0.055073101073503494,
      -0.0014059464447200298,
      0.07554494589567184,
      -0.010006188414990902,
      -0.06319107115268707,
      -0.005250992719084024,
      0.029905909672379494,
      0.06951043754816055,
      -0.013283085078001022,
      -0.05920463055372238,
      -0.03446875885128975,
      0.06580977886915207,
      -0.010165667161345482,
      -0.020472990348935127,
      0.03571939468383789,
      0.03182423859834671,
      0.03874342888593674,
      0.020213276147842407,
      -0.0322384387254715,
      -0.0489138625562191,
      -0.04942811280488968,
      -0.025520866736769676,
      -0.006644124630838633,
      0.008086847141385078,
      3.7356580833147746e-06,
      0.07449638843536377,
      0.0011515645310282707,
      -0.008329490199685097,
      0.03539523109793663,
      0.05940429866313934,
      0.010443953797221184,
      0.026215577498078346,
      -0.024265535175800323,
      -0.06440198421478271,
      0.02328123152256012,
      0.06556658446788788,
      -0.007568757049739361,
      0.031124748289585114,
      0.07556356489658356,
      0.03979547694325447,
      0.07737619429826736,
      -0.006510088220238686,
      0.03705480694770813,
      -0.02009008824825287,
      0.13935309648513794,
      -0.0927010104060173,
      -0.018105797469615936,
      -0.02953362464904785,
      -0.06497645378112793,
      0.039421841502189636,
      0.028718464076519012,
      0.019610192626714706,
      0.0638689249753952,
      -0.004469791427254677,
      0.012998195365071297,
      -0.008631163276731968,
      -0.00919883232563734,
      0.030596548691391945,
      -0.018502751365303993,
      -0.03436371684074402,
      -0.04035457968711853,
      -0.0010537637863308191,
      0.0654802918434143,
      0.0044504497200250626,
      -0.08420630544424057,
      -0.06303167343139648,
      0.03321823477745056,
      0.02481212466955185,
      -0.0004469958657864481,
      0.011167903430759907,
      0.03858445584774017,
      -0.03337070345878601,
      -0.026499008759856224,
      0.019874393939971924,
      0.007333025336265564,
      0.08851984143257141,
      -0.046142153441905975,
      -0.06507612019777298,
      -0.06185368448495865,
      0.011879214085638523,
      0.030355241149663925,
      0.07641152292490005,
      0.05059570446610451,
      0.052045613527297974,
      0.03077234886586666,
      0.03618654981255531,
      0.10947670042514801,
      -0.09115707874298096,
      0.05807960778474808,
      0.03970538452267647,
      -0.04493332281708717,
      0.12031718343496323,
      -0.03091306984424591,
      -0.021188028156757355,
      -0.0072499169036746025,
      0.05183877795934677,
      0.03199975565075874,
      0.1316368579864502,
      -0.0996493548154831,
      0.004972281865775585,
      -0.020982924848794937,
      -0.010921445675194263,
      -0.055448077619075775,
      0.06498811393976212,
      -0.036274418234825134,
      -0.030733933672308922,
      0.007433059625327587,
      -0.03129648044705391,
      0.022623995319008827,
      -0.01947537437081337,
      -0.034387003630399704,
      0.0123049421235919,
      -0.04228251799941063,
      -0.02267589047551155,
      0.013766871765255928,
      0.02862914651632309,
      -0.09741067886352539,
      0.03916984423995018,
      0.06703274697065353,
      -0.02426912635564804,
      0.013012205250561237,
      -0.015564724802970886,
      0.004551077261567116,
      -0.029557332396507263,
      0.018666446208953857,
      -0.039453912526369095,
      -0.045682862401008606,
      -0.02321767434477806,
      0.05286107212305069,
      -0.016034075990319252,
      0.004659631289541721,
      0.0215586107224226,
      0.05222999304533005,
      0.04003671184182167,
      0.06403197348117828,
      0.03222784772515297,
      0.048918284475803375,
      0.0026606556493788958,
      0.01566884107887745,
      0.009804987348616123,
      -0.03311503306031227,
      -0.017569273710250854,
      0.031078534200787544,
      -0.004540078807622194,
      0.0268267635256052,
      -0.01660100370645523,
      0.06036163121461868,
      -0.0231915395706892,
      0.07197712361812592,
      0.029453320428729057,
      0.08307900279760361,
      -0.020843852311372757,
      -0.05686325207352638,
      -0.014588819816708565,
      0.011908861808478832,
      -0.008155150339007378,
      0.03141647204756737,
      0.027783988043665886,
      -0.0528968945145607,
      -0.03285950422286987,
      0.020790714770555496,
      0.07168684154748917,
      -0.030919725075364113,
      0.008112582378089428,
      0.027403011918067932,
      0.009481568820774555,
      0.038735516369342804,
      0.0019014544086530805,
      0.031917475163936615,
      -0.057646069675683975,
      0.0005769351846538484,
      -0.007163952570408583,
      -0.00838481355458498,
      -0.02472793497145176,
      0.029634566977620125,
      -0.03649253770709038,
      -0.03130596876144409,
      0.09893374145030975,
      0.053499624133110046,
      -0.010234453715384007,
      0.09868253767490387,
      -0.04703165218234062,
      -0.06373089551925659,
      -0.06756752729415894,
      -0.005138202104717493,
      0.021836545318365097,
      -0.0022593620233237743,
      0.036950286477804184,
      -0.03590964153409004,
      -0.07140212506055832,
      0.11921074241399765,
      -0.06142141669988632,
      -0.03661515936255455,
      -0.04903987795114517,
      0.016345253214240074,
      -0.05083003640174866,
      0.05199355632066727,
      0.06218782812356949,
      -0.03885209932923317,
      0.04850258678197861,
      0.005186581052839756,
      -0.026327772065997124,
      0.02543243207037449,
      0.06548426300287247,
      0.07377930730581284,
      0.06289485841989517,
      0.032019950449466705,
      -0.0009674112661741674,
      -0.07305477559566498,
      -0.004343404900282621,
      -0.0801267921924591,
      0.006224639248102903,
      -0.03417184203863144,
      -0.007937255315482616,
      -0.03384970873594284,
      0.04669685661792755,
      -0.0673445388674736,
      0.07653984427452087,
      0.028452295809984207,
      -0.030907051637768745,
      -0.01605832390487194,
      0.008982379920780659,
      -0.02972489222884178,
      0.05121443048119545,
      -0.013002745807170868,
      -0.012200013734400272,
      -0.005334954708814621,
      0.007530247792601585,
      -0.07036945223808289,
      -0.02716166526079178,
      0.05072784796357155,
      0.03587120771408081,
      -0.0064094411209225655
    ],
    [
      -0.04143785312771797,
      0.022265931591391563,
      0.006908100564032793,
      -0.023663848638534546,
      0.05938716232776642,
      0.03773076459765434,
      0.04196108132600784,
      0.057760413736104965,
      0.0002814838371705264,
      0.029765313491225243,
      -0.0060568866319954395,
      0.03388800844550133,
      -0.020747428759932518,
      -0.01984141767024994,
      0.021345769986510277,
      0.0020430933218449354,
      0.03140311315655708,
      0.015871161594986916,
      0.03594233840703964,
      0.027745017781853676,
      0.010535313747823238,
      -0.027106205001473427,
      -0.011016168631613255,
      0.10807304084300995,
      -0.01637139916419983,
      0.02873873896896839,
      0.004109098110347986,
      -0.005393164698034525,
      0.021393289789557457,
      0.053479790687561035,
      0.016211990267038345,
      -0.06859665364027023,
      0.03380721062421799,
      -0.03584110736846924,
      -0.002428497187793255,
      -0.06892910599708557,
      0.0212856475263834,
      -0.059157226234674454,
      -0.009704500436782837,
      0.03775278106331825,
      -0.0009638768970035017,
      -0.04348223656415939,
      -0.07332636415958405,
      -0.05574619024991989,
      -0.028105484321713448,
      0.03786834701895714,
      0.05257154256105423,
      0.0546632744371891,
      0.03330045938491821,
      0.06205129623413086,
      -0.013358809985220432,
      0.034651514142751694,
      -0.030044764280319214,
      -0.049785103648900986,
      -0.07109922170639038,
      -0.002515468280762434,
      0.01180422306060791,
      0.06078551337122917,
      0.016647785902023315,
      -0.020562130957841873,
      0.0427321121096611,
      -0.11959166824817657,
      0.07857895642518997,
      -0.01194695569574833,
      -0.010984912514686584,
      -0.024503065273165703,
      0.052862875163555145,
      -0.07454248517751694,
      -0.02484183758497238,
      -0.08946110308170319,
      0.07245565205812454,
      0.03419898450374603,
      0.082395039498806,
      0.11051524430513382,
      0.025015393272042274,
      -0.052396584302186966,
      -0.07916297763586044,
      -0.0032997210510075092,
      0.05909476801753044,
      -0.08558820933103561,
      0.04817329719662666,
      0.02892194502055645,
      0.04094424471259117,
      -0.008649563416838646,
      0.045772161334753036,
      -0.02254495769739151,
      0.03082440420985222,
      0.030712025240063667,
      0.03852546587586403,
      0.033257391303777695,
      0.00029745505889877677,
      0.08081129938364029,
      0.01987932063639164,
      0.0363338366150856,
      0.02923976629972458,
      -0.05142951011657715,
      0.0211600661277771,
      -0.05377204716205597,
      -0.012601005844771862,
      0.0433618389070034,
      -0.026780566200613976,
      0.02663782425224781,
      0.003514547599479556,
      -0.03797144442796707,
      0.05025949329137802,
      -0.004031721968203783,
      -0.06472980976104736,
      0.006025639828294516,
      -0.019518187269568443,
      0.03430184721946716,
      0.027006173506379128,
      0.00698715727776289,
      -0.01769784279167652,
      0.08371832221746445,
      -0.061307184398174286,
      0.0047481609508395195,
      -0.00976169016212225,
      0.04894319176673889,
      -0.000409749016398564,
      0.019677918404340744,
      -0.02440905198454857,
      -0.04108402132987976,
      0.09201183170080185,
      0.08374770730733871,
      0.05080105736851692,
      -0.040807921439409256,
      -0.05131163448095322,
      -0.09487596154212952,
      0.05484667047858238,
      0.07680284231901169,
      0.0005501927225850523,
      -0.03999347612261772,
      0.037803471088409424,
      -0.061282679438591,
      -0.03887138515710831,
      0.07968385517597198,
      -0.07565564662218094,
      0.01181412860751152,
      -0.054142698645591736,
      0.0031566903926432133,
      -0.02001529559493065,
      -0.04752817377448082,
      -0.048655182123184204,
      -0.07806003838777542,
      0.081852488219738,
      -0.061972346156835556,
      -0.08481675386428833,
      -0.0002824659750331193,
      -0.006752474699169397,
      -0.023152267560362816,
      0.011588258668780327,
      -0.049032967537641525,
      0.024347150698304176,
      -0.02283298783004284,
      -0.0815664753317833,
      -0.10601713508367538,
      0.023710327222943306,
      -0.05940750986337662,
      0.012594121508300304,
      -0.005242639686912298,
      -0.049739718437194824,
      0.15613684058189392,
      0.024059250950813293,
      0.04521629214286804,
      -0.0877048447728157,
      0.002142192330211401,
      0.015603997744619846,
      -0.0072140381671488285,
      -0.004891628865152597,
      0.04900706186890602,
      0.03806417062878609,
      -0.05585422366857529,
      0.06032584235072136,
      0.00854684878140688,
      0.03682221472263336,
      0.108018659055233,
      -0.03945744410157204,
      -0.012710045091807842,
      -0.07089927047491074,
      0.0463666208088398,
      -0.027946213260293007,
      -0.019350780174136162,
      0.03812931105494499,
      0.03733113035559654,
      0.031986985355615616,
      0.062046077102422714,
      0.03471601381897926,
      0.00025064541841857135,
      -0.026639673858880997,
      0.02313910238444805,
      0.020004000514745712,
      0.022020261734724045,
      0.0003347234451211989,
      -0.03398671746253967,
      -0.032966069877147675,
      -0.04259173572063446,
      -0.050077494233846664,
      0.028965020552277565,
      0.023997057229280472,
      -0.04914645478129387,
      -0.01282433606684208,
      0.03824799880385399,
      0.03991682827472687,
      0.05806126818060875,
      -0.09675620496273041,
      -0.11025340110063553,
      -0.022467972710728645,
      0.05633893609046936,
      -0.029316289350390434,
      0.07113249599933624,
      0.041855718940496445,
      -0.05551604554057121,
      0.0242252629250288,
      0.000762331357691437,
      -0.08706841617822647,
      0.08701176941394806,
      -0.056543998420238495,
      -0.06479893624782562,
      0.052251048386096954,
      0.05263003334403038,
      -0.050420407205820084,
      0.04622289538383484,
      0.008211874403059483,
      -0.05907218158245087,
      0.019990995526313782,
      0.0122330691665411,
      0.06653127074241638,
      0.008250134065747261,
      -0.03906318545341492,
      -0.13310657441616058,
      0.09023432433605194,
      -0.008959395810961723,
      -0.03288853168487549,
      -0.03179508447647095,
      0.01606556586921215,
      -0.012681747786700726,
      -0.015271032229065895,
      -0.015761543065309525,
      0.06578349322080612,
      0.0669962540268898,
      -0.13933221995830536,
      -0.06428483873605728,
      -0.05139867588877678,
      0.04074457660317421,
      -0.028831901028752327,
      -0.018354466184973717,
      -0.04806685075163841,
      0.018481088802218437,
      -0.020249439403414726,
      -0.07858175784349442,
      -0.05936809629201889,
      0.01462260726839304,
      0.06927815824747086,
      -0.014784260652959347,
      -0.019664999097585678,
      -0.0022109581623226404,
      -0.07782670855522156,
      0.013206194154918194,
      0.015469031408429146,
      0.0987088680267334,
      0.06531448662281036,
      -0.01834496483206749,
      0.003842633916065097,
      0.04225853458046913,
      0.05468054115772247,
      0.001842930563725531,
      -0.021079884842038155,
      0.06628202646970749,
      -0.11893070489168167,
      0.06898749619722366,
      -0.03516470640897751,
      -0.01865064725279808,
      0.014622692950069904,
      0.006136335898190737,
      0.06283687055110931,
      2.781269358820282e-05,
      0.013403795659542084,
      -0.05338013917207718,
      0.00471425661817193,
      -0.032365623861551285,
      0.02432899922132492,
      0.09917814284563065,
      -0.010631869547069073,
      0.0744810402393341,
      -0.06250457465648651,
      0.08126586675643921,
      -0.01463999878615141,
      0.022669190540909767,
      -0.027248861268162727,
      0.02420535683631897,
      -0.0016078002518042922,
      0.057323623448610306,
      -0.07327765971422195,
      0.028413323685526848,
      -0.037015583366155624,
      0.08805069327354431,
      -0.03242703899741173,
      -0.013587289489805698,
      -0.011577676981687546,
      0.018560539931058884,
      -0.020883122459053993,
      -0.006327915471047163,
      0.014216520823538303,
      -0.011205875314772129,
      -0.041916150599718094,
      0.012706182897090912,
      0.00702340342104435,
      0.009857024066150188,
      -0.0328524075448513,
      -0.036462944000959396,
      0.060198865830898285,
      -0.08079714328050613,
      0.01323393452912569,
      -0.06147632375359535,
      -0.020304212346673012,
      0.023433737456798553,
      -0.0018700474174693227,
      0.0965396985411644,
      0.07099327445030212,
      0.07135036587715149,
      -0.00595092074945569,
      -0.011351251043379307,
      -0.006575435400009155,
      -0.03116229735314846,
      -0.04216954857110977,
      0.08256702125072479,
      -0.02911379560828209,
      -0.06027327850461006,
      -0.04374819993972778,
      -0.048472460359334946,
      0.08724386990070343,
      -0.03199315071105957,
      0.07314342260360718,
      -0.019100762903690338,
      0.047439515590667725,
      0.04800089821219444,
      -0.05972779169678688,
      0.06714914739131927,
      -0.05942150577902794,
      -0.01908895932137966,
      0.06734880059957504,
      -0.04518212378025055,
      -0.07690274715423584,
      -0.06486736238002777,
      0.02065958082675934,
      -0.05390492454171181,
      -0.019667498767375946,
      -0.08688981086015701,
      -0.045843493193387985,
      0.014162556268274784,
      -0.0635884553194046,
      0.014791304245591164,
      0.06184638664126396,
      -0.04697703197598457,
      0.014320396818220615,
      0.050464749336242676,
      -0.02013738825917244,
      -0.09006572514772415,
      -0.011419296264648438,
      0.007413757033646107,
      -0.03687851130962372,
      0.03797704353928566,
      -0.005940255243331194,
      -0.0715695172548294,
      -0.03969358652830124,
      -0.050488829612731934,
      -0.02310522459447384,
      -0.03166349232196808,
      -0.032311711460351944,
      -0.07958965748548508,
      0.019105106592178345,
      0.043961744755506516,
      0.014258087612688541,
      -0.03291890397667885,
      -0.04624829441308975,
      0.08545978367328644,
      0.05785997584462166,
      0.028066938742995262,
      0.019998639822006226,
      0.11606025695800781,
      0.019942037761211395,
      -0.006547039374709129,
      0.045755721628665924,
      0.036869075149297714,
      -0.027598990127444267,
      0.018998701125383377,
      -0.00971087347716093,
      -0.0476575642824173,
      -0.036507491022348404,
      -0.044588420540094376,
      -0.01939290016889572,
      -0.002393695991486311,
      0.0034006054047495127,
      0.0041199070401489735,
      -0.007044535595923662,
      0.053774964064359665,
      0.06556488573551178,
      -0.0017000746447592974,
      0.020623372867703438,
      0.007621284574270248,
      -0.13249459862709045,
      0.017330756410956383,
      0.0384860523045063,
      0.02291719615459442,
      0.07080985605716705,
      0.012809520587325096,
      0.011130166240036488,
      -0.00047380500473082066,
      0.007441126741468906,
      -0.004584434907883406,
      -0.01062805112451315,
      0.058971818536520004,
      0.07889889180660248,
      -0.03520221635699272,
      0.0014628978678956628,
      0.036900099366903305,
      -0.055345866829156876,
      -0.06618044525384903,
      0.002550841309130192,
      0.04891008883714676,
      0.04957148805260658,
      -0.011198675259947777,
      0.034030646085739136,
      0.08962312340736389,
      0.0835120901465416,
      -0.006487141363322735,
      -0.0312955416738987,
      0.03152715787291527,
      -0.06424999982118607,
      -0.05864109471440315,
      0.009812360629439354,
      -0.016348131000995636,
      -0.05299552157521248,
      0.025400500744581223,
      0.10099349170923233,
      -0.006958178244531155,
      -0.0170160923153162,
      0.0272270105779171,
      -0.013651179149746895,
      -0.031848080456256866,
      0.019970176741480827,
      -0.016955969855189323,
      -0.06345531344413757,
      0.06171555817127228,
      0.03549954667687416,
      -0.015370400622487068,
      0.026423897594213486,
      0.025267021730542183,
      0.017043694853782654,
      0.06099915876984596,
      -0.039300963282585144,
      0.000496895401738584,
      0.035110991448163986,
      0.06725703924894333,
      0.03404629975557327,
      -0.07816905528306961,
      -0.009414699859917164,
      0.04025692120194435,
      -0.002191178034991026,
      -0.029972873628139496,
      0.02555864118039608,
      0.043778154999017715,
      0.010349221527576447,
      -0.037303466349840164,
      -0.08354425430297852,
      -0.06460798531770706,
      -0.03264698386192322,
      -0.03043815866112709,
      -0.00944636669009924,
      0.011154166422784328,
      0.01581026054918766,
      -0.02063070237636566,
      0.08185802400112152,
      -0.027709079906344414,
      -0.002367981942370534,
      -0.08881831169128418,
      -0.03314042091369629,
      0.02604087069630623,
      -0.016028091311454773,
      0.03895419463515282,
      0.02359754964709282,
      0.011432494036853313,
      -0.10674403607845306,
      -0.0072846924886107445,
      0.035283878445625305,
      -0.03902701288461685,
      0.0370580330491066,
      -0.05678023770451546,
      0.017949674278497696,
      -0.02853314019739628,
      0.039272867143154144,
      -0.0030236111488193274,
      0.012120099738240242,
      -0.049158725887537,
      0.038462016731500626,
      -0.08641642332077026,
      -0.035522352904081345,
      0.01377370860427618,
      0.001499401987530291,
      0.07816460728645325,
      -0.03700137138366699,
      -0.027487222105264664,
      0.06727667897939682
    ],
    [
      -0.041363675147295,
      -0.009723701514303684,
      0.008970070630311966,
      0.0491068959236145,
      -0.0020821832586079836,
      -0.054438017308712006,
      0.02191113494336605,
      0.041965097188949585,
      0.03305356204509735,
      -0.06384162604808807,
      -0.012754918076097965,
      0.026741109788417816,
      -0.03764212876558304,
      -0.010656687431037426,
      -0.018175065517425537,
      -0.018534373492002487,
      -0.0033094524405896664,
      0.10613664239645004,
      0.028025250881910324,
      0.001101704896427691,
      -0.02966434508562088,
      0.019093960523605347,
      0.008634265512228012,
      -0.017093494534492493,
      -0.01190193835645914,
      0.0575542151927948,
      0.04636922478675842,
      0.0031803639139980078,
      -0.06626264750957489,
      0.03944928199052811,
      0.04030546173453331,
      0.027193523943424225,
      0.08214366436004639,
      0.023703763261437416,
      0.03769417107105255,
      -0.0007332762470468879,
      -0.032351527363061905,
      0.08214089274406433,
      0.011435040272772312,
      0.042358558624982834,
      0.007410846184939146,
      0.022462308406829834,
      -0.05969987437129021,
      0.00606498634442687,
      -0.0006324389833025634,
      -0.0007398552843369544,
      0.05752921476960182,
      0.021223479881882668,
      0.022575290873646736,
      -0.09549536556005478,
      -0.020086269825696945,
      -0.00725936284288764,
      -0.058714453130960464,
      0.01712602935731411,
      0.03194354847073555,
      0.05219949781894684,
      0.09609749913215637,
      0.00883639045059681,
      -0.049892935901880264,
      0.045124731957912445,
      -0.07050687819719315,
      -0.014496447518467903,
      -0.04133906215429306,
      0.07162895053625107,
      0.04303640127182007,
      -0.04491427168250084,
      0.014720720238983631,
      -0.018048429861664772,
      -0.006477986462414265,
      0.01619768515229225,
      0.0030331925954669714,
      0.04310768097639084,
      -0.019870059564709663,
      0.008789096027612686,
      -0.00021315838966984302,
      -0.058489616960287094,
      0.0037974624428898096,
      -0.0032397028990089893,
      0.07675296813249588,
      -0.07801973074674606,
      -0.07413232326507568,
      0.054761990904808044,
      -0.00988729577511549,
      -0.013033770024776459,
      -0.02953801490366459,
      -0.016820697113871574,
      0.003712557489052415,
      0.00021931756054982543,
      -0.031421344727277756,
      -0.0928545817732811,
      -0.029536854475736618,
      0.0703548714518547,
      0.07979007065296173,
      0.043520618230104446,
      0.01490106526762247,
      -0.10081543773412704,
      0.027829885482788086,
      -0.02810998447239399,
      -0.040362801402807236,
      -0.04791196808218956,
      -0.004118740558624268,
      0.03174291178584099,
      0.006427271291613579,
      0.02463572658598423,
      -0.09687618911266327,
      -0.1003369390964508,
      0.04613284021615982,
      -0.06940945237874985,
      -0.06918986141681671,
      -0.08679064363241196,
      -0.0691053569316864,
      -0.02017551101744175,
      0.021429697051644325,
      -0.04476163908839226,
      0.03997405245900154,
      -0.054374780505895615,
      0.027973368763923645,
      0.09164181351661682,
      -0.051889337599277496,
      -0.05430826544761658,
      0.04720798507332802,
      0.020901227369904518,
      0.04421036317944527,
      -0.06893684715032578,
      0.015822097659111023,
      -0.05391101911664009,
      0.015235574916005135,
      0.07402753084897995,
      0.05218052864074707,
      0.017509976401925087,
      -0.004997145384550095,
      0.010645482689142227,
      -0.04468829184770584,
      -0.032264016568660736,
      -0.006146530155092478,
      -0.0537576824426651,
      0.05032580345869064,
      0.048107363283634186,
      -0.016407033428549767,
      0.00795048289000988,
      -0.06966156512498856,
      -0.028097938746213913,
      -0.015076283365488052,
      0.01910039409995079,
      0.05041557550430298,
      -0.06197887659072876,
      0.012276888824999332,
      0.016664408147335052,
      0.10618945211172104,
      0.02532544545829296,
      0.05514991655945778,
      0.0470484122633934,
      0.027043376117944717,
      0.0623188279569149,
      0.01160258799791336,
      -0.06883075088262558,
      -0.019740521907806396,
      0.057592425495386124,
      0.01726750284433365,
      0.01183351967483759,
      -0.011641334742307663,
      -0.0573369562625885,
      -0.0052114552818238735,
      0.03116004914045334,
      -0.0012381321284919977,
      -0.014309863559901714,
      -0.05608682334423065,
      -0.028208164498209953,
      -0.0170962605625391,
      0.01792379841208458,
      0.04578696936368942,
      -0.042878881096839905,
      -0.03947099298238754,
      0.07574941217899323,
      -0.031650494784116745,
      0.052325617522001266,
      0.05873667076230049,
      0.08637776225805283,
      -0.04073834419250488,
      0.02394268475472927,
      -0.0022547575645148754,
      0.08136926591396332,
      0.005993746221065521,
      -0.08266408741474152,
      0.005010213237255812,
      0.03310279920697212,
      -0.0013192136539146304,
      0.06935551762580872,
      0.03999172896146774,
      -0.02825799398124218,
      0.013752665370702744,
      -0.0657215490937233,
      -0.01878291182219982,
      -0.06451431661844254,
      -0.021006673574447632,
      -0.015383953228592873,
      -0.01762676052749157,
      -0.027190817520022392,
      -0.01753358170390129,
      -0.006408111657947302,
      0.025008466094732285,
      0.0012315732892602682,
      0.014170065522193909,
      0.05660016089677811,
      -0.06278812885284424,
      -0.036600686609745026,
      0.014270507730543613,
      0.03541896864771843,
      -0.020869355648756027,
      0.0318063385784626,
      -0.041863054037094116,
      -0.006263076793402433,
      0.058001983910799026,
      0.03408220410346985,
      0.04346725344657898,
      0.008378874510526657,
      0.02788184955716133,
      0.0346837192773819,
      0.05819227546453476,
      -0.013424402102828026,
      -0.03755977004766464,
      0.025413552299141884,
      0.047740817070007324,
      -0.001773314201273024,
      0.02816600725054741,
      -0.08621233701705933,
      0.005498950369656086,
      -0.019121333956718445,
      0.06841963529586792,
      0.07350905239582062,
      0.005236844997853041,
      0.0077832723036408424,
      0.0027462218422442675,
      0.010749422945082188,
      -0.02357477694749832,
      0.02774764411151409,
      -0.02717887982726097,
      0.05726171284914017,
      0.07762550562620163,
      -0.10520868748426437,
      0.011958302929997444,
      -0.010885617695748806,
      -0.013660809025168419,
      0.010684072971343994,
      0.05317850783467293,
      -0.004177010152488947,
      0.10837344825267792,
      0.07869488000869751,
      0.04569296911358833,
      -0.06456290185451508,
      -0.007794532459229231,
      -0.0760948434472084,
      0.041585568338632584,
      -0.006902688182890415,
      -0.026555996388196945,
      -0.0504501536488533,
      0.07705722004175186,
      -0.020853327587246895,
      0.01560196466743946,
      -0.03296796232461929,
      -0.013173195533454418,
      0.03282992169260979,
      0.04198483005166054,
      -0.03918638452887535,
      -0.04536787420511246,
      -0.051854535937309265,
      0.058895111083984375,
      -0.02596437558531761,
      -0.030112074688076973,
      0.07551176846027374,
      0.03927823156118393,
      0.0008420301019214094,
      0.004704102408140898,
      -0.04940961301326752,
      0.05308143049478531,
      0.014050603844225407,
      -0.0814739465713501,
      0.006335381418466568,
      -0.02869829721748829,
      -0.03359721228480339,
      -0.026166608557105064,
      0.01827419362962246,
      -0.025032637640833855,
      -0.046249616891145706,
      -0.034203171730041504,
      0.07644306123256683,
      -0.023053321987390518,
      -0.018979528918862343,
      -0.021028002724051476,
      0.0007325641345232725,
      -0.04478314518928528,
      -0.008022968657314777,
      -0.014084430411458015,
      0.10643909126520157,
      -0.03349900245666504,
      0.009551896713674068,
      -0.026484107598662376,
      -0.05517183616757393,
      -0.015298198908567429,
      -0.023985283449292183,
      0.03482489287853241,
      0.014317229390144348,
      0.0018232049187645316,
      -0.0027853981591761112,
      0.02276325412094593,
      0.01857670582830906,
      0.00406620791181922,
      -0.01777976006269455,
      -0.0739876925945282,
      0.1150498315691948,
      0.053567368537187576,
      -0.010546848177909851,
      -0.04587041586637497,
      -0.04774807021021843,
      -0.007446953561156988,
      0.0028625736013054848,
      0.0325971320271492,
      -0.052925556898117065,
      -0.005150247365236282,
      -0.001968811731785536,
      -0.018807586282491684,
      -0.00965875107795,
      -0.04721834138035774,
      -0.00816862378269434,
      0.09176986664533615,
      0.08326787501573563,
      -0.04582953453063965,
      0.030440878123044968,
      -0.03870905935764313,
      -0.02999667264521122,
      0.032383229583501816,
      -0.039097532629966736,
      -0.0005016294308006763,
      0.02901851385831833,
      -0.005516332108527422,
      -0.037192437797784805,
      -0.0022789095528423786,
      -0.020086975768208504,
      -0.02759360335767269,
      0.006477136164903641,
      0.017527364194393158,
      0.01795760542154312,
      -0.01570957712829113,
      0.08114739507436752,
      0.022858766838908195,
      -0.038607802242040634,
      -0.010804779827594757,
      0.06364290416240692,
      0.0006513606058433652,
      0.018441710621118546,
      -0.009541559033095837,
      -0.008261177688837051,
      -0.04962094873189926,
      0.0007811067043803632,
      0.0044058458879590034,
      0.024370765313506126,
      -0.013940067030489445,
      0.011343749240040779,
      -0.04149143770337105,
      -0.030171053484082222,
      0.013265181332826614,
      -0.013852832838892937,
      0.08120322227478027,
      0.020083388313651085,
      0.03774091973900795,
      0.00941816158592701,
      0.0048361606895923615,
      -0.018675362691283226,
      0.02933073602616787,
      0.03940515220165253,
      0.009349511004984379,
      -0.016903331503272057,
      0.022235870361328125,
      0.07709012180566788,
      -0.06652972847223282,
      -0.07934574782848358,
      0.010751592926681042,
      0.011681604199111462,
      0.024853305891156197,
      -0.004255129024386406,
      -0.02397124283015728,
      0.007988878525793552,
      0.03942076489329338,
      -0.058810487389564514,
      -0.04638580232858658,
      0.006346074398607016,
      0.0023307970259338617,
      -0.047582436352968216,
      -0.10857442021369934,
      0.06420234590768814,
      -0.004860018379986286,
      -0.08239877969026566,
      -0.06269674003124237,
      -0.008131117559969425,
      0.0970262885093689,
      0.012938328087329865,
      -0.05177881568670273,
      -0.0091053182259202,
      -0.04700624570250511,
      -0.011326140724122524,
      -0.027330467477440834,
      0.04108237102627754,
      0.038703612983226776,
      -0.006193521432578564,
      -0.03790770843625069,
      0.004175481386482716,
      -0.036361031234264374,
      0.05341670289635658,
      -0.0639958307147026,
      0.027368003502488136,
      0.03612223640084267,
      -0.1347731649875641,
      0.01862911507487297,
      -0.015311353839933872,
      0.07145752012729645,
      0.018852664157748222,
      0.017757240682840347,
      -0.004049643408507109,
      -0.05383964627981186,
      0.08190342783927917,
      -0.04329950734972954,
      0.005920999217778444,
      -0.0004929792485199869,
      0.01378118060529232,
      -0.04150941222906113,
      -0.008364129811525345,
      -0.017163075506687164,
      -0.04907806217670441,
      -0.019235309213399887,
      -0.018770091235637665,
      -0.0092187924310565,
      0.013678077608346939,
      -0.001331328647211194,
      0.06164773926138878,
      0.004133213311433792,
      -0.04307277873158455,
      -0.07256225496530533,
      -0.09874153882265091,
      -0.0748763233423233,
      -0.04981502890586853,
      0.032383427023887634,
      0.005665296223014593,
      0.025469612330198288,
      -0.04252362996339798,
      -0.05219997838139534,
      -0.06914305686950684,
      -0.008477503433823586,
      0.021107938140630722,
      0.06320644915103912,
      -0.048438701778650284,
      0.0005171701777726412,
      -0.07538242638111115,
      -0.04183622822165489,
      0.043409477919340134,
      -0.00911670457571745,
      -0.06052117422223091,
      -0.040181756019592285,
      -0.01394718699157238,
      -0.017611505463719368,
      -0.0028524163644760847,
      0.11314718425273895,
      -0.12547637522220612,
      -0.023464472964406013,
      0.05046728625893593,
      0.046455275267362595,
      0.03257829323410988,
      0.09398223459720612,
      -0.0449141189455986,
      -0.008046760223805904,
      0.01330413855612278,
      0.04467121139168739,
      -0.032625146210193634,
      0.009870351292192936,
      -0.009243047796189785,
      -0.0609939843416214,
      0.024843459948897362,
      0.03380349650979042,
      -0.07490584254264832,
      0.012269144877791405,
      0.08906234055757523,
      -0.0076241036877036095,
      0.05421365052461624,
      -0.0754847601056099,
      0.02039436623454094,
      0.016292180866003036,
      0.00042559957364574075,
      -0.02044195681810379,
      -0.04527658969163895,
      -0.010258450172841549,
      -0.03909250721335411,
      0.0590335838496685,
      0.005392215680330992,
      0.012770773842930794,
      0.06111624091863632,
      -0.01538451761007309,
      -0.014335880987346172,
      -0.0054450873285532,
      -0.036470912396907806,
      0.03814561665058136,
      -0.06166066974401474,
      -0.04604121297597885,
      0.014133664779365063,
      0.046423230320215225
    ],
    [
      0.03143104165792465,
      -0.03210783749818802,
      0.048045482486486435,
      -0.09661480784416199,
      -0.035734083503484726,
      -0.043061211705207825,
      0.07887274026870728,
      -0.02901547960937023,
      0.021299054846167564,
      0.08885931968688965,
      -0.07206173986196518,
      -0.008489742875099182,
      0.004538075067102909,
      0.032544203102588654,
      0.06308601796627045,
      0.0237286314368248,
      -0.03603421524167061,
      0.02351825311779976,
      -0.02655985951423645,
      -0.012400604784488678,
      -0.021505065262317657,
      0.003679171670228243,
      0.012926424853503704,
      0.017365381121635437,
      0.0381876640021801,
      -0.01815236732363701,
      -0.003165313508361578,
      0.03185240179300308,
      0.0199588555842638,
      0.06355492025613785,
      -0.05052327737212181,
      0.04195737838745117,
      0.05016772821545601,
      -0.012549041770398617,
      0.05539507418870926,
      -0.016530368477106094,
      -0.00575641542673111,
      -0.026352694258093834,
      0.08649946749210358,
      0.06891254335641861,
      -0.03831803426146507,
      -0.03206990659236908,
      0.01918455772101879,
      0.04848984628915787,
      -0.04688350856304169,
      -0.07174932211637497,
      -0.02222118154168129,
      0.03764541819691658,
      -0.0353696383535862,
      0.07522495090961456,
      -0.04055067151784897,
      -0.02508634701371193,
      0.0636933371424675,
      0.04998994991183281,
      0.11336903274059296,
      -0.018809495493769646,
      0.020080506801605225,
      -0.03660251572728157,
      0.07262498885393143,
      -0.006879153195768595,
      -0.060506612062454224,
      -0.02514146827161312,
      -0.07760640978813171,
      0.004735560156404972,
      0.026690835133194923,
      0.02039826475083828,
      0.11448390781879425,
      0.015496497973799706,
      -0.0514814630150795,
      -0.07249955087900162,
      0.03259039670228958,
      0.014734743162989616,
      -0.02489486336708069,
      -0.02749735303223133,
      0.05395546928048134,
      -0.035977791994810104,
      -0.031617868691682816,
      -0.03570491820573807,
      -0.04276106879115105,
      -0.07613254338502884,
      -0.01877039484679699,
      0.04214499518275261,
      0.005533738061785698,
      0.003796647535637021,
      -0.02129363641142845,
      -0.013554767705500126,
      -0.015788212418556213,
      -0.019110560417175293,
      0.010982474312186241,
      -0.058546409010887146,
      0.09389922022819519,
      0.06019201874732971,
      0.031781911849975586,
      0.06188146024942398,
      0.029504697769880295,
      0.007544736377894878,
      -0.018175702542066574,
      0.034852221608161926,
      -0.04474005848169327,
      0.0315055213868618,
      0.014357204549014568,
      0.04068562015891075,
      0.015382313169538975,
      0.08826454728841782,
      -0.0663958489894867,
      -0.004285978619009256,
      0.047247327864170074,
      -0.031131001189351082,
      0.0029604469891637564,
      -0.04025210812687874,
      -0.02666309103369713,
      0.0053657288663089275,
      0.0676826611161232,
      -0.02581782452762127,
      -0.08045651763677597,
      0.0015673886518925428,
      -0.05998854339122772,
      0.03208879008889198,
      0.042914535850286484,
      -0.03861166536808014,
      0.0564839243888855,
      0.07923775166273117,
      -0.027857095003128052,
      -0.017397265881299973,
      -0.032860368490219116,
      0.02841237746179104,
      0.044320039451122284,
      -0.007023090496659279,
      -0.019924722611904144,
      0.007232356816530228,
      0.012011229991912842,
      -0.06572902947664261,
      -0.0010967052076011896,
      0.01105569675564766,
      -0.09391330927610397,
      0.02616475708782673,
      -0.028610406443476677,
      -0.005503229331225157,
      0.03131399303674698,
      -0.10108455270528793,
      -0.07717812061309814,
      0.015463164076209068,
      0.015434641391038895,
      -0.01861809566617012,
      -0.007974155247211456,
      0.004822597838938236,
      0.012628760188817978,
      -0.05003300681710243,
      0.006879282183945179,
      -0.011129274033010006,
      -0.10957324504852295,
      0.05146375671029091,
      0.10762642323970795,
      0.10602923482656479,
      -0.022039338946342468,
      -0.03199474886059761,
      -0.015859784558415413,
      -0.023948034271597862,
      0.07065819203853607,
      0.0071166884154081345,
      0.004755315370857716,
      -0.036825746297836304,
      0.011284125037491322,
      -0.03276767581701279,
      0.005338482558727264,
      -0.00955033116042614,
      0.06626439839601517,
      0.04999006912112236,
      0.04055589437484741,
      0.07034602761268616,
      0.0936800166964531,
      -0.010987542569637299,
      0.02800910174846649,
      0.043588023632764816,
      0.00686096353456378,
      -0.03275104612112045,
      -0.059463173151016235,
      0.005238389130681753,
      0.04829944670200348,
      0.05026881769299507,
      0.0041937995702028275,
      0.04113633558154106,
      0.026937250047922134,
      -0.016592860221862793,
      -0.020034687593579292,
      -0.014138244092464447,
      0.047617506235837936,
      0.018895911052823067,
      0.05373760312795639,
      0.017064953222870827,
      0.05088711902499199,
      0.02642916887998581,
      0.015253434889018536,
      0.024739574640989304,
      -0.03554774820804596,
      0.038794856518507004,
      0.047174643725156784,
      0.04781170189380646,
      0.03114129975438118,
      -0.06896395981311798,
      0.03651722893118858,
      -0.04298490285873413,
      -0.022792572155594826,
      -0.05363739654421806,
      0.0019035767763853073,
      -0.07943538576364517,
      -0.054218925535678864,
      0.02435258775949478,
      0.0003384416631888598,
      0.045590147376060486,
      0.09331116825342178,
      0.006900356616824865,
      -0.0016002884367480874,
      -0.02361406199634075,
      -0.01713750511407852,
      0.01281383540481329,
      -0.020538533106446266,
      -0.0796826034784317,
      -0.02425132505595684,
      0.08456980437040329,
      0.005373457912355661,
      0.09233200550079346,
      -0.03498377278447151,
      0.05490037426352501,
      -0.01351673062890768,
      0.012974917888641357,
      -0.03432722017168999,
      0.09050390124320984,
      0.01616162434220314,
      -0.009951124899089336,
      -0.0120784230530262,
      0.037725646048784256,
      0.04920145869255066,
      -0.003331737359985709,
      -0.02479982189834118,
      -0.018091483041644096,
      -0.040527235716581345,
      -0.01240139827132225,
      -0.01645699329674244,
      -0.10173173248767853,
      0.003938527312129736,
      -0.03058166615664959,
      0.03713493421673775,
      0.015888536348938942,
      0.04041462019085884,
      0.0516146756708622,
      0.015500003471970558,
      -0.009229333139955997,
      0.04497624188661575,
      -0.005790725816041231,
      -0.008948608301579952,
      0.024343423545360565,
      -0.03695479780435562,
      -0.0405702218413353,
      -0.01467361580580473,
      0.03174416348338127,
      -0.0038476004265248775,
      -0.010195155628025532,
      -0.03496744483709335,
      -0.04995018616318703,
      0.04528151452541351,
      0.06971094757318497,
      0.07231242209672928,
      -0.06157803535461426,
      -0.047572407871484756,
      -0.029896829277276993,
      0.034835439175367355,
      0.037689998745918274,
      0.0031984266825020313,
      -0.046707235276699066,
      -0.06344784796237946,
      -0.03873696178197861,
      -0.0373014360666275,
      -0.12880270183086395,
      -0.0120201101526618,
      0.028557905927300453,
      -0.03151320293545723,
      0.03586084023118019,
      -0.008668486960232258,
      -0.05566231906414032,
      -0.060570452362298965,
      -0.0470484122633934,
      0.036427091807127,
      -0.08348154276609421,
      -0.03760390356183052,
      0.07960685342550278,
      -0.004451976623386145,
      0.04855506867170334,
      -0.03994302079081535,
      0.035308875143527985,
      -0.015421539545059204,
      -0.02389524318277836,
      0.046150099486112595,
      -0.07044225931167603,
      -0.013457730412483215,
      -0.001521787140518427,
      -0.017367344349622726,
      -0.04033348709344864,
      0.03411511704325676,
      0.01592688448727131,
      0.11385361850261688,
      -0.026709362864494324,
      0.041359733790159225,
      -0.039579909294843674,
      0.017739837989211082,
      0.013141212984919548,
      -0.027972325682640076,
      -0.035790298134088516,
      -0.04289702698588371,
      0.042412616312503815,
      0.04673481732606888,
      -0.02553153596818447,
      -0.029255663976073265,
      -0.04458804056048393,
      0.027482837438583374,
      0.01484106108546257,
      -0.04947835952043533,
      0.023487620055675507,
      -0.11727197468280792,
      -0.02602582611143589,
      0.04094264656305313,
      -0.0652577131986618,
      -0.0382753387093544,
      0.044277090579271317,
      0.0574701726436615,
      -0.0014713000273332,
      0.0340832881629467,
      -0.02605869248509407,
      0.0681394711136818,
      0.02342664822936058,
      0.059931881725788116,
      0.050700027495622635,
      0.002362869679927826,
      -0.052727170288562775,
      0.05636700242757797,
      -0.02165795862674713,
      -0.023849207907915115,
      0.07221988588571548,
      -0.055455923080444336,
      0.04369761794805527,
      -0.012767608277499676,
      0.052720557898283005,
      -0.0359472893178463,
      -0.04114961996674538,
      -0.011634298600256443,
      0.011650905013084412,
      0.0395379438996315,
      -0.06559404730796814,
      -0.00248691882006824,
      -0.022305507212877274,
      0.02168956957757473,
      -0.09775526821613312,
      -0.07422547787427902,
      -0.00550943985581398,
      -0.04262509196996689,
      0.04294898360967636,
      0.07060476392507553,
      0.009065902791917324,
      0.04133749380707741,
      0.06275393068790436,
      -0.06405336409807205,
      -0.08150766789913177,
      0.016206789761781693,
      -0.056223608553409576,
      -0.03193365037441254,
      0.03582817316055298,
      -0.04362422600388527,
      -0.017190977931022644,
      -0.055329374969005585,
      -0.011160888709127903,
      0.03717819228768349,
      -0.013972089625895023,
      -0.026005635038018227,
      0.04521409049630165,
      -0.006732871290296316,
      0.04696030914783478,
      0.019389847293496132,
      0.01790514960885048,
      -0.03685098513960838,
      -0.039663657546043396,
      -0.047757793217897415,
      0.028486624360084534,
      -0.03255799040198326,
      -0.038046352565288544,
      0.02073035202920437,
      -0.07064278423786163,
      0.031252529472112656,
      -0.013022022321820259,
      0.022594695910811424,
      -0.015552982687950134,
      -0.03024425357580185,
      -0.07845437526702881,
      0.08165783435106277,
      0.01512063853442669,
      0.037435147911310196,
      0.026680387556552887,
      -0.015798799693584442,
      0.003660050220787525,
      0.006436144467443228,
      -0.06508582830429077,
      0.10704237222671509,
      -0.008312469348311424,
      0.03283967822790146,
      -0.04231483116745949,
      -0.004463464953005314,
      -0.03360946848988533,
      -0.047907955944538116,
      0.0005551264039240777,
      -0.013517656363546848,
      -0.07080914080142975,
      -0.018994858488440514,
      -0.11765369772911072,
      -0.041927192360162735,
      -0.0248093418776989,
      0.0669146403670311,
      -0.03739320486783981,
      -0.029478371143341064,
      0.051237449049949646,
      0.07522298395633698,
      0.03208477422595024,
      0.024180548265576363,
      0.0545102022588253,
      -0.0205661840736866,
      -0.02534220740199089,
      0.05040410906076431,
      0.0500267818570137,
      0.0009249305003322661,
      0.07228285819292068,
      -0.0075693088583648205,
      -0.06367961317300797,
      0.0070710089057683945,
      0.026717813685536385,
      0.01270773820579052,
      -0.036166202276945114,
      -0.054271988570690155,
      0.015322205610573292,
      0.0065047708339989185,
      0.03333966061472893,
      -0.012746112421154976,
      -0.04621558263897896,
      0.005570245906710625,
      0.0052100894972682,
      -0.047092948108911514,
      0.022103574126958847,
      -0.05232885107398033,
      0.016596566885709763,
      -0.02309410646557808,
      0.05236523598432541,
      0.010951847769320011,
      -0.069338858127594,
      0.021830907091498375,
      -0.08605023473501205,
      -0.021503547206521034,
      0.07348102331161499,
      -0.02538841776549816,
      -0.03331653028726578,
      -0.005338395945727825,
      0.014826633967459202,
      0.00709416251629591,
      0.015900028869509697,
      0.0036250376142561436,
      -0.02687026746571064,
      0.001726535614579916,
      0.011677791364490986,
      0.04433988407254219,
      0.011818324215710163,
      -0.036870624870061874,
      0.028225643560290337,
      -0.010385875590145588,
      -0.015454379841685295,
      0.0012727418215945363,
      0.05606459081172943,
      -0.034621477127075195,
      -0.027817241847515106,
      -0.054048873484134674,
      -0.030175235122442245,
      0.006347078830003738,
      -0.010426832363009453,
      -0.029052188619971275,
      0.030042322352528572,
      -0.07202836871147156,
      0.03755345568060875,
      0.02503293938934803,
      -0.003178609535098076,
      0.05861537158489227,
      0.011672355234622955,
      -0.017703862860798836,
      -0.0440654456615448,
      -0.02445971593260765,
      0.07005729526281357,
      -0.0715082585811615,
      0.03224608674645424,
      0.026888519525527954,
      0.008598429150879383,
      0.04748471826314926,
      -0.05865536257624626,
      -0.06471969932317734,
      0.06064338609576225,
      -0.06296578049659729,
      -0.022808026522397995,
      0.0025256858207285404,
      0.02495916374027729,
      -0.008719579316675663
    ],
    [
      0.01235544215887785,
      0.020173469558358192,
      -0.016543183475732803,
      0.05024416744709015,
      -0.0019862980116158724,
      -0.028186526149511337,
      0.03934924677014351,
      0.009550326503813267,
      0.04388744384050369,
      0.046875953674316406,
      0.04408818855881691,
      -0.0067019592970609665,
      0.0022697956301271915,
      0.050247713923454285,
      -0.003653425257652998,
      0.0031359917484223843,
      0.049605969339609146,
      0.04248439893126488,
      -0.0050755697302520275,
      -0.03195245936512947,
      -0.05342445150017738,
      0.032385874539613724,
      0.008988255634903908,
      -0.008157102391123772,
      0.03439323604106903,
      -0.021658238023519516,
      -0.008784784004092216,
      0.07018323987722397,
      -0.049499522894620895,
      -0.02901042252779007,
      -0.03812713548541069,
      0.008157811127603054,
      -0.0010336944833397865,
      -0.07785039395093918,
      0.02342710644006729,
      -0.006159037351608276,
      0.0507306233048439,
      0.01817094162106514,
      0.026291638612747192,
      0.014453158713877201,
      0.008968938142061234,
      -0.008807502686977386,
      0.034024424850940704,
      -0.025031007826328278,
      -0.021859755739569664,
      -0.00423852726817131,
      0.022985780611634254,
      -0.046481743454933167,
      0.0319007970392704,
      -0.02837185747921467,
      -0.04416947066783905,
      0.01705009862780571,
      -0.02659100666642189,
      0.0260341577231884,
      -0.05216167867183685,
      0.034377992153167725,
      0.07299355417490005,
      -0.05605702847242355,
      -0.030204588547348976,
      0.0797659158706665,
      -0.0294476468116045,
      -0.0087051410228014,
      0.047246795147657394,
      0.08081354945898056,
      0.013815273530781269,
      0.07894857227802277,
      -0.05207216739654541,
      0.0035361829213798046,
      0.04804294556379318,
      0.02154703624546528,
      -0.029000233858823776,
      -0.024526523426175117,
      0.009550899267196655,
      -0.035168107599020004,
      0.004144252743571997,
      0.004735804628580809,
      -0.009058319963514805,
      0.018206652253866196,
      -0.028830761089920998,
      -0.041382670402526855,
      0.03333141654729843,
      -0.005095642525702715,
      0.055665452033281326,
      -0.03852251172065735,
      0.05654722824692726,
      0.04123285040259361,
      0.03827768564224243,
      -0.007210083305835724,
      -0.005501506384462118,
      -0.0626545399427414,
      0.0870688259601593,
      0.00908651389181614,
      -0.03246840462088585,
      -0.012439517304301262,
      0.005923527292907238,
      0.005616192240267992,
      -0.01414590422064066,
      -0.03258875012397766,
      -0.1267164647579193,
      0.09336837381124496,
      0.04895000159740448,
      0.014420390129089355,
      -0.0013010140974074602,
      0.007446545176208019,
      0.0038462127558887005,
      -0.03313704952597618,
      0.01812553033232689,
      -0.06559646874666214,
      0.005785792134702206,
      0.035627711564302444,
      0.021049723029136658,
      -0.03085443191230297,
      -0.06362740695476532,
      -0.050142478197813034,
      0.05518733710050583,
      -0.01935555227100849,
      0.03908974304795265,
      0.01432439498603344,
      -0.06551914662122726,
      0.013414434157311916,
      -0.02496214024722576,
      0.015547838993370533,
      -0.02179259993135929,
      -0.02003692090511322,
      -0.028668588027358055,
      0.0028062581550329924,
      -0.003720258828252554,
      0.006437673699110746,
      0.06657832115888596,
      0.025489412248134613,
      0.01255213376134634,
      -0.007312302943319082,
      -0.006689996924251318,
      -0.03701586648821831,
      -0.03605848550796509,
      -0.04436350241303444,
      -0.015346076339483261,
      0.05871749296784401,
      0.05773940309882164,
      -0.05942588672041893,
      0.07618781179189682,
      -0.02279355190694332,
      0.052778493613004684,
      -0.012791774235665798,
      -0.009539258666336536,
      -0.01990177482366562,
      0.012414231896400452,
      0.0738704577088356,
      0.04839405417442322,
      0.016526954248547554,
      -0.018188869580626488,
      0.011919070035219193,
      0.0038043251261115074,
      0.0436246432363987,
      -0.015491923317313194,
      0.037209682166576385,
      -0.0036907438188791275,
      -0.0490582101047039,
      -0.0010272548533976078,
      -0.03604899346828461,
      0.004125675186514854,
      0.01011422835290432,
      0.003464265028014779,
      0.04769131913781166,
      -0.0049856603145599365,
      -0.06288578361272812,
      -0.04694777727127075,
      0.025946058332920074,
      -0.036782439798116684,
      -0.05779552087187767,
      0.02814059890806675,
      0.028241828083992004,
      -0.06129913404583931,
      0.05151168256998062,
      -0.07102087140083313,
      0.03518820181488991,
      -0.05318853631615639,
      -0.05617988482117653,
      0.08937042206525803,
      0.05073609948158264,
      -0.02008683793246746,
      -0.010367647744715214,
      -0.0025920418556779623,
      -0.05315977334976196,
      -0.06093796342611313,
      -0.03560788184404373,
      -0.03487025946378708,
      -0.023930538445711136,
      0.03205249831080437,
      0.006954302079975605,
      -0.034500543028116226,
      -0.02620604820549488,
      -0.010285303927958012,
      -0.00242047430947423,
      0.07750217616558075,
      -0.02126356028020382,
      0.034355051815509796,
      0.05460566654801369,
      -0.060393162071704865,
      0.014825466088950634,
      0.05168481171131134,
      0.013496892526745796,
      -0.01113466639071703,
      -0.003677638713270426,
      0.0607432946562767,
      0.07426031678915024,
      -0.008871649391949177,
      0.00011234856356168166,
      -0.03426229581236839,
      0.06639821082353592,
      0.047170490026474,
      0.007448357064276934,
      -0.050984833389520645,
      0.017795007675886154,
      0.01701117493212223,
      -0.09253309667110443,
      0.024317052215337753,
      -0.03316400945186615,
      0.004111497197300196,
      -0.01681779883801937,
      0.02839992195367813,
      -0.01672305166721344,
      -0.016044380143284798,
      0.003313039895147085,
      0.08326826244592667,
      0.0046507990919053555,
      -0.04268157109618187,
      -0.01333522330969572,
      0.05038296803832054,
      -0.007123470772057772,
      -0.027376331388950348,
      -0.0813693031668663,
      -0.03226140886545181,
      -0.05745739862322807,
      0.0725712850689888,
      0.02372528798878193,
      0.01581091620028019,
      0.03499412164092064,
      -0.03689423203468323,
      -0.010433402843773365,
      0.008886151015758514,
      -0.001965270610526204,
      0.08154077082872391,
      0.06491909176111221,
      -0.07580605149269104,
      0.006210746709257364,
      0.015514375641942024,
      0.027095289900898933,
      -0.008126462809741497,
      -0.012934248894453049,
      -0.0022347827907651663,
      -0.12361682206392288,
      -0.024210404604673386,
      0.02262163534760475,
      -0.016544191166758537,
      -0.12042165547609329,
      -0.02784675918519497,
      -0.0274595245718956,
      0.022209255024790764,
      -0.010245555080473423,
      0.0030084196478128433,
      0.009294362738728523,
      -0.07089564204216003,
      0.001277538831345737,
      -0.012374531477689743,
      -0.1107097938656807,
      0.00867476873099804,
      0.005106670781970024,
      0.03446348384022713,
      0.008241533301770687,
      -0.005782292224466801,
      0.05608310177922249,
      0.015681929886341095,
      0.03672661632299423,
      -0.036294274032115936,
      0.09224206209182739,
      0.0034282924607396126,
      -0.011697676964104176,
      0.008863751776516438,
      -0.06928257644176483,
      0.01473275013267994,
      -0.032923854887485504,
      -0.02900674007833004,
      0.04741429165005684,
      0.028510309755802155,
      0.08042244613170624,
      0.052054207772016525,
      -0.059067919850349426,
      -0.021671993657946587,
      -0.11979415267705917,
      0.05723223462700844,
      -0.06678510457277298,
      -0.03950846195220947,
      0.061819616705179214,
      0.018841302022337914,
      0.03453970327973366,
      0.053468797355890274,
      -0.03397981449961662,
      -0.07135668396949768,
      0.07791599631309509,
      0.010848375968635082,
      0.06997428089380264,
      -0.0012548406375572085,
      -0.07778167724609375,
      0.019698085263371468,
      -0.024952704086899757,
      0.04466943442821503,
      4.605096546583809e-05,
      0.0005293105496093631,
      0.012277751229703426,
      0.004566894378513098,
      -0.017850736156105995,
      -0.038868218660354614,
      -0.05539228022098541,
      0.0025314725935459137,
      -0.015089920721948147,
      0.023548943921923637,
      0.007851673290133476,
      0.033442579209804535,
      0.01587890461087227,
      -0.052387189120054245,
      -0.011471440084278584,
      0.052694328129291534,
      0.0206232201308012,
      0.019924329593777657,
      -0.005982436705380678,
      0.030884776264429092,
      0.014051438309252262,
      -0.045815568417310715,
      0.018066085875034332,
      -0.01136967446655035,
      0.002555972896516323,
      -0.02120235189795494,
      0.04737897217273712,
      -0.0015034914249554276,
      -0.07049617916345596,
      0.05255453288555145,
      -0.03706680238246918,
      -0.02571040950715542,
      -0.004074462223798037,
      -0.03774531930685043,
      0.025185491889715195,
      -0.0009446269832551479,
      -0.0008149993373081088,
      0.016048738732933998,
      0.005810352507978678,
      -0.026303892955183983,
      -0.028798431158065796,
      -0.08559343218803406,
      0.010939040221273899,
      -0.07139738649129868,
      -0.03992416337132454,
      -0.03138686716556549,
      0.044201213866472244,
      0.013979648239910603,
      -0.034638628363609314,
      0.038373515009880066,
      0.0461406409740448,
      -0.05762530490756035,
      0.0005899319075979292,
      0.0408066026866436,
      -0.0434001162648201,
      0.009145162999629974,
      -0.015888428315520287,
      -0.005751392338424921,
      -0.007200778927654028,
      -0.005397793371230364,
      -0.029032934457063675,
      -0.06180628016591072,
      0.012745341286063194,
      0.002924927743151784,
      0.030889563262462616,
      0.04823329299688339,
      0.011430780403316021,
      0.0076941074803471565,
      -0.013127916492521763,
      -0.01896492950618267,
      0.04851251095533371,
      -0.03887733817100525,
      0.031929127871990204,
      0.007956434972584248,
      -0.024806451052427292,
      0.026047365739941597,
      -0.08944083750247955,
      0.07515306025743484,
      0.03139988332986832,
      -0.0020307605154812336,
      -0.033033352345228195,
      0.06406085938215256,
      0.021333610638976097,
      -0.07397043704986572,
      0.017989732325077057,
      -0.04806217923760414,
      0.11477430164813995,
      0.01128021627664566,
      0.04007750377058983,
      -0.02258019521832466,
      -0.0014858468202874064,
      0.030723396688699722,
      0.03543265163898468,
      -0.01657598838210106,
      0.027520881965756416,
      -0.01993001438677311,
      0.0845121294260025,
      0.027042916044592857,
      0.004695983603596687,
      0.02420576475560665,
      0.0007252127979882061,
      0.004641053266823292,
      -0.01007844228297472,
      0.02883722446858883,
      -0.06227610260248184,
      -0.07507337629795074,
      0.0450839139521122,
      0.04956569895148277,
      0.005925533827394247,
      -0.08018044382333755,
      0.0174102820456028,
      -0.02637530490756035,
      -0.00849032774567604,
      -0.03686554729938507,
      -0.04643207788467407,
      -0.019820015877485275,
      -0.023678241297602654,
      -0.021724071353673935,
      -0.027349703013896942,
      0.10108554363250732,
      -0.010678599588572979,
      0.004605679307132959,
      0.010213904082775116,
      0.06825580447912216,
      -0.03336754068732262,
      -0.014166825450956821,
      -0.007053032983094454,
      0.010152569971978664,
      -0.0006111591937951744,
      0.05126214399933815,
      -0.09254205226898193,
      0.07102562487125397,
      0.09836948662996292,
      -0.01351888570934534,
      -0.05581720173358917,
      0.047494664788246155,
      0.028074810281395912,
      0.017504025250673294,
      -0.03402552008628845,
      0.005573758389800787,
      0.023454470559954643,
      0.054831113666296005,
      -0.045077480375766754,
      0.0012250582221895456,
      -0.032304100692272186,
      0.007938998751342297,
      0.0007934827590361238,
      -0.096709243953228,
      0.003718340303748846,
      0.02901514805853367,
      -0.05940509960055351,
      0.09779641032218933,
      0.036893587559461594,
      0.03301237151026726,
      -0.035331930965185165,
      -0.035505879670381546,
      -0.02307942695915699,
      -0.06369802355766296,
      0.022634493187069893,
      -0.03762106969952583,
      -0.033382534980773926,
      -0.0036744442768394947,
      -0.016068922355771065,
      0.035859473049640656,
      0.022984115406870842,
      -0.13927152752876282,
      -0.02483266405761242,
      -0.05042045935988426,
      0.07258612662553787,
      0.0679452121257782,
      -0.0068750702776014805,
      -0.008050723932683468,
      -0.08822405338287354,
      -0.05015558749437332,
      -0.049069106578826904,
      0.01037518959492445,
      -0.024642279371619225,
      0.06210411712527275,
      -0.04728229343891144,
      -0.04377231374382973,
      0.04356219619512558,
      0.043971333652734756,
      0.09107068926095963,
      0.03505402058362961,
      -0.13916179537773132,
      0.0422271266579628,
      -0.022505080327391624,
      -0.01582380384206772,
      0.0263533852994442,
      -0.021058695390820503,
      -0.06695318967103958,
      -0.039762504398822784,
      -0.009324729442596436,
      -0.01278948225080967,
      0.027937887236475945,
      0.05267331376671791
    ],
    [
      0.006761670112609863,
      0.036814965307712555,
      0.032966092228889465,
      -0.04802851006388664,
      0.04223889112472534,
      0.0465799979865551,
      -0.001385401701554656,
      0.039870452135801315,
      -0.03710309416055679,
      -0.033691246062517166,
      0.007184242829680443,
      0.05232959985733032,
      0.04219900071620941,
      0.06638380885124207,
      0.013879275880753994,
      -0.009556670673191547,
      -0.010505053214728832,
      -0.018435105681419373,
      -0.03558715805411339,
      -0.02276802249252796,
      0.025707924738526344,
      -0.017092004418373108,
      -0.04118531942367554,
      0.0056880139745771885,
      -0.0013157714856788516,
      -0.08604198694229126,
      -0.02305634692311287,
      0.013535815291106701,
      0.08072175085544586,
      -0.003963252995163202,
      0.08552766591310501,
      0.020450888201594353,
      0.008111522532999516,
      -0.11042745411396027,
      0.010538773611187935,
      0.0008369130664505064,
      -0.018314337357878685,
      0.0724472627043724,
      0.023970335721969604,
      0.14454516768455505,
      -0.04667896404862404,
      -0.01571645215153694,
      -0.0556381531059742,
      0.015575402416288853,
      -0.03815354406833649,
      0.002626305678859353,
      -0.011536647565662861,
      0.05714334920048714,
      -0.037731461226940155,
      0.011327708140015602,
      -0.001162127242423594,
      0.10408206284046173,
      0.04795001447200775,
      -0.05831793323159218,
      -0.03996194526553154,
      0.16647841036319733,
      -0.027765940874814987,
      -0.04412819445133209,
      -0.02343190275132656,
      -0.007249315269291401,
      -0.006401204038411379,
      -0.03055289015173912,
      -0.059432193636894226,
      -0.005230057518929243,
      -0.08674408495426178,
      0.06035589799284935,
      0.01027577742934227,
      -0.0024181536864489317,
      0.05822841450572014,
      0.025036361068487167,
      -0.08438064903020859,
      -0.09791586548089981,
      -0.06345560401678085,
      0.002446607453748584,
      0.05572934448719025,
      0.07319363206624985,
      0.02958259917795658,
      0.07675044238567352,
      0.019482696428894997,
      0.0009583394275978208,
      0.032838862389326096,
      -0.02925952523946762,
      0.0015022592851892114,
      -0.0029745104257017374,
      -0.03851550072431564,
      -0.051340486854314804,
      0.019140135496854782,
      0.07048220932483673,
      0.010648436844348907,
      -0.003213797230273485,
      -0.02182069979608059,
      0.034164320677518845,
      -0.016836555674672127,
      -0.12485494464635849,
      -0.003265707055106759,
      -0.02176680974662304,
      -0.03746674209833145,
      0.04937722906470299,
      -0.033016618341207504,
      0.004829362966120243,
      -0.03255501389503479,
      0.05817974731326103,
      -0.044903527945280075,
      0.018195271492004395,
      -0.02463621087372303,
      -0.03500008583068848,
      0.030376479029655457,
      -0.036671172827482224,
      -0.03227101266384125,
      0.010663066059350967,
      0.10419010370969772,
      0.018660657107830048,
      0.033185604959726334,
      -0.028660807758569717,
      -0.03999367728829384,
      -0.015526684932410717,
      0.039668913930654526,
      0.062392544001340866,
      -0.022264009341597557,
      0.024357745423913002,
      -0.04228494316339493,
      0.04603688418865204,
      -0.04960956424474716,
      -0.016424234956502914,
      -0.05889873206615448,
      -0.1097567155957222,
      -0.06801975518465042,
      0.042236972600221634,
      -0.02491173706948757,
      -0.020687958225607872,
      -0.034040775150060654,
      0.026665056124329567,
      -0.027625661343336105,
      -0.047971345484256744,
      -0.024351816624403,
      -0.0197261031717062,
      -0.06606382131576538,
      0.024378018453717232,
      0.04038681834936142,
      -0.00254781823605299,
      -0.0423600934445858,
      -0.0135352136567235,
      -0.013948444277048111,
      -0.008503483608365059,
      0.011246010661125183,
      0.01285184733569622,
      0.038124050945043564,
      -0.0383591391146183,
      -0.028448600322008133,
      -0.07483810186386108,
      -0.028336813673377037,
      0.047300949692726135,
      -0.053177304565906525,
      -0.01319214515388012,
      0.008361153304576874,
      0.017327161505818367,
      -0.05907686427235603,
      -0.017108868807554245,
      -0.0415862537920475,
      -0.022831043228507042,
      -0.08599165827035904,
      -0.023047374561429024,
      -0.0011115452507510781,
      0.016544822603464127,
      0.019269712269306183,
      0.012694929726421833,
      -0.06741920858621597,
      -0.03267227113246918,
      0.03576600179076195,
      0.05444403365254402,
      0.1172662302851677,
      -0.11284362524747849,
      -0.019426094368100166,
      0.016172342002391815,
      -0.05728038027882576,
      0.005465068854391575,
      -0.036588527262210846,
      -0.005907527171075344,
      -0.03956279531121254,
      0.12705202400684357,
      0.07654212415218353,
      -0.024871069937944412,
      0.04685197025537491,
      -0.009773881174623966,
      0.038541510701179504,
      0.024471547454595566,
      0.003799558850005269,
      0.10093848407268524,
      -0.06259582191705704,
      0.04313163086771965,
      0.05400628224015236,
      -0.044329218566417694,
      0.04796119034290314,
      0.03526652604341507,
      0.031328510493040085,
      -0.06119271367788315,
      -0.0026811722200363874,
      -0.022900447249412537,
      0.05701252818107605,
      0.04817574843764305,
      0.01893591322004795,
      -0.0048281727358698845,
      -0.0011146868346258998,
      0.031802669167518616,
      -0.010693702846765518,
      0.01527760922908783,
      -0.05017736926674843,
      -0.028775611892342567,
      -0.020429570227861404,
      0.005588081199675798,
      0.06306321173906326,
      0.00981074757874012,
      -0.06815706938505173,
      0.051041074097156525,
      0.047442030161619186,
      -0.004467888735234737,
      0.03873179107904434,
      0.01258870679885149,
      -0.024926478043198586,
      -0.04370187222957611,
      0.07869012653827667,
      0.02286441996693611,
      -0.08134615421295166,
      0.0931561142206192,
      0.017934799194335938,
      0.012019574642181396,
      0.03386155143380165,
      -0.03730740398168564,
      -0.012459315359592438,
      0.03540392220020294,
      -0.0041402955539524555,
      -0.05014973133802414,
      0.01003827154636383,
      -0.006000641733407974,
      0.008922325447201729,
      -0.03063821606338024,
      -0.01145237497985363,
      0.1096256673336029,
      0.05949760228395462,
      -0.03889117389917374,
      0.032571904361248016,
      0.020816825330257416,
      0.07093977928161621,
      0.043757423758506775,
      -0.018296457827091217,
      0.04505201801657677,
      0.010989336296916008,
      0.05054076388478279,
      -0.02208126336336136,
      -0.03829261660575867,
      0.02817952074110508,
      0.08515747636556625,
      0.03473110496997833,
      -0.0395820252597332,
      -0.0361461266875267,
      -0.0732303038239479,
      -0.0281645730137825,
      -0.03500167652964592,
      0.0021096873097121716,
      0.03407251089811325,
      0.059897180646657944,
      0.016332603991031647,
      -0.047316621989011765,
      0.005907275248318911,
      0.038206811994314194,
      0.016594765707850456,
      0.04876011610031128,
      0.00349197699688375,
      0.035317908972501755,
      0.008800205774605274,
      -0.012707344256341457,
      0.03243712708353996,
      -0.04020534083247185,
      0.008945727720856667,
      0.018463974818587303,
      -0.008631207048892975,
      0.0028097587637603283,
      -0.0454692542552948,
      0.052698731422424316,
      0.054948803037405014,
      -0.011368757113814354,
      0.030282773077487946,
      -0.0216022040694952,
      -0.05221673846244812,
      0.04574375972151756,
      -0.01505341287702322,
      0.02770311012864113,
      -0.0010408487869426608,
      0.037510234862565994,
      -0.0031230300664901733,
      -0.0030583117622882128,
      0.0028258671518415213,
      0.009887694381177425,
      0.039835892617702484,
      0.003741184715181589,
      0.045317210257053375,
      -0.07965762168169022,
      -0.007161441259086132,
      0.05959917604923248,
      0.00952310673892498,
      0.04291844740509987,
      -0.04062854126095772,
      -0.0911012813448906,
      0.03671632707118988,
      -0.03494786471128464,
      0.023011406883597374,
      0.012109586969017982,
      0.07536610960960388,
      -0.014068077318370342,
      -0.010566024109721184,
      0.017462899908423424,
      0.009542788378894329,
      -0.07945595681667328,
      -0.02220827527344227,
      -0.03729298710823059,
      0.010243921540677547,
      0.0897676944732666,
      -0.010473486967384815,
      0.020584654062986374,
      -0.042193230241537094,
      0.14066927134990692,
      -0.03561133146286011,
      0.08486151695251465,
      0.002370221074670553,
      0.027237938717007637,
      -0.02431022934615612,
      0.023268243297934532,
      -0.03116239421069622,
      -0.0034924843348562717,
      0.009472421370446682,
      0.022601690143346786,
      -0.037065815180540085,
      -0.006756405811756849,
      0.01259466353803873,
      -0.04427988827228546,
      0.059037309139966965,
      -0.07276294380426407,
      0.03129700571298599,
      -0.044892534613609314,
      0.04147924482822418,
      -0.026467179879546165,
      0.07454398274421692,
      -0.038675352931022644,
      -0.0021543949842453003,
      0.028258243575692177,
      -0.02008110098540783,
      0.042401306331157684,
      -0.030811838805675507,
      0.023673683404922485,
      -0.00777118606492877,
      0.02680779993534088,
      -0.05914875864982605,
      -0.009598789736628532,
      0.0198548324406147,
      0.07542354613542557,
      0.0073923938907682896,
      0.07027598470449448,
      -0.04518464580178261,
      0.000911668932531029,
      0.035794444382190704,
      -0.07757674157619476,
      -0.06931081414222717,
      -0.03873448818922043,
      0.022890688851475716,
      -0.012206487357616425,
      -0.041621606796979904,
      0.050762373954057693,
      -0.004639816004782915,
      0.10749292373657227,
      0.042465582489967346,
      0.02602587640285492,
      0.0815354734659195,
      0.0405874140560627,
      -0.03630760684609413,
      0.07712811976671219,
      -0.002321349224075675,
      -0.0156656913459301,
      0.03982536494731903,
      0.025523921474814415,
      0.03302288427948952,
      -0.04007492586970329,
      0.05129454657435417,
      0.06440473347902298,
      0.023151788860559464,
      -0.045351747423410416,
      0.016023939475417137,
      0.0889158695936203,
      -0.05335685610771179,
      -0.004775512032210827,
      0.061581842601299286,
      -0.018242863938212395,
      -0.03955438733100891,
      -0.030615419149398804,
      0.039641913026571274,
      -0.034203048795461655,
      0.008167057298123837,
      0.04118674248456955,
      -0.11719829589128494,
      0.0545523501932621,
      -0.020490631461143494,
      0.020278645679354668,
      -0.04498821869492531,
      0.00892393197864294,
      -0.08156812191009521,
      -0.05606929585337639,
      0.019571010023355484,
      0.0500064380466938,
      0.02201787196099758,
      -0.011252909898757935,
      0.024742301553487778,
      -0.04443618655204773,
      -0.020167753100395203,
      0.04065452143549919,
      -0.07066302746534348,
      0.02493252418935299,
      -0.05661506950855255,
      0.026520587503910065,
      -0.00035588699392974377,
      -0.13643136620521545,
      0.06446188688278198,
      0.0049500418826937675,
      -0.005383483599871397,
      0.02611633948981762,
      -0.015224795788526535,
      0.04584835097193718,
      0.006354784592986107,
      -0.031162701547145844,
      0.0555800199508667,
      -0.03197643533349037,
      0.03646344691514969,
      -0.04533034935593605,
      -0.011255383491516113,
      -0.0030984629411250353,
      -0.08630356192588806,
      -0.031195273622870445,
      0.05853969603776932,
      0.012647357769310474,
      0.017305675894021988,
      0.030275333672761917,
      0.04217319190502167,
      0.029932204633951187,
      -0.02344236522912979,
      -0.04362329840660095,
      -0.012544392608106136,
      -0.04470941051840782,
      -0.020188739523291588,
      -0.060423851013183594,
      0.028793109580874443,
      0.07985400408506393,
      -0.026845211163163185,
      -0.042367760092020035,
      -0.023240316659212112,
      -0.02933964505791664,
      0.06364039331674576,
      -0.015566011890769005,
      -0.03574123606085777,
      0.014792630448937416,
      -0.06937101483345032,
      0.0031101650092750788,
      0.03128407895565033,
      -0.015395778231322765,
      -0.07442218065261841,
      -0.08262846618890762,
      -0.03345909342169762,
      -0.02881346456706524,
      -0.03592018783092499,
      0.0235893614590168,
      0.025927932932972908,
      -0.05086009204387665,
      0.02971726283431053,
      0.000325491011608392,
      0.03572402521967888,
      0.05795392766594887,
      -0.0036361704114824533,
      0.08791181445121765,
      -0.028566673398017883,
      -0.01378708053380251,
      0.05528805032372475,
      -0.01593073643743992,
      -0.02667505294084549,
      -0.021134724840521812,
      0.05712443217635155,
      0.0044379038736224174,
      0.018503153696656227,
      -0.11221174895763397,
      0.0219461377710104,
      0.033939559012651443,
      0.10911396890878677,
      0.06971879303455353,
      -0.054265715181827545,
      -0.011630566790699959,
      0.07919780910015106,
      -0.016041478142142296,
      0.025141723453998566,
      0.10931617766618729,
      -0.0894598737359047,
      -0.022832116112113,
      0.023804200813174248,
      0.0009299350203946233,
      0.04403579980134964,
      -0.06729687750339508,
      0.027936728671193123,
      -0.02786288782954216
    ],
    [
      0.01423824392259121,
      -0.017062557861208916,
      0.04435313120484352,
      0.03244086354970932,
      -0.009614135138690472,
      0.0741327628493309,
      0.10374487936496735,
      -0.11116945743560791,
      -0.019892340525984764,
      -0.0011279317550361156,
      0.030139977112412453,
      -0.016971077769994736,
      -0.02648715302348137,
      -0.002506237244233489,
      -0.012428113259375095,
      -0.011964828707277775,
      0.0010989438742399216,
      0.010885389521718025,
      -0.05743918567895889,
      0.004766876809298992,
      0.06102292984724045,
      0.0017758079338818789,
      -0.047265876084566116,
      0.06364993751049042,
      0.019580144435167313,
      -0.012478949502110481,
      -0.046220507472753525,
      0.034890878945589066,
      -0.006223258562386036,
      0.0062398044392466545,
      -0.09364661574363708,
      0.11441629379987717,
      0.012725592590868473,
      -0.07081149518489838,
      -0.09355884045362473,
      0.05522651597857475,
      0.0036894928198307753,
      -0.024690700694918633,
      0.09635737538337708,
      0.06913572549819946,
      -0.02513127774000168,
      0.10274559259414673,
      0.0093007143586874,
      -0.028259187936782837,
      0.03457099199295044,
      -0.028783435001969337,
      -0.005878389347344637,
      -0.09903521090745926,
      -0.041382208466529846,
      0.04981941729784012,
      -0.03443608060479164,
      0.015423941425979137,
      -0.005706076510250568,
      -0.0067907338961958885,
      -0.026967711746692657,
      0.022468214854598045,
      0.05483253300189972,
      -0.033899690955877304,
      -0.04372916743159294,
      -0.0355842150747776,
      -0.02663419581949711,
      -0.006357528269290924,
      -0.019031092524528503,
      -0.04680750519037247,
      0.0865536481142044,
      -0.038335077464580536,
      0.04353228211402893,
      0.011962808668613434,
      -0.0026084326673299074,
      -0.09914173930883408,
      0.03557747229933739,
      0.050038814544677734,
      -0.004739917349070311,
      -0.029281921684741974,
      -0.0010855711298063397,
      0.08705601096153259,
      -0.058520205318927765,
      0.00696773873642087,
      -0.014353509061038494,
      0.020658433437347412,
      -0.0783652514219284,
      -0.006677508819848299,
      -0.012679996900260448,
      0.07043107599020004,
      0.09879717975854874,
      0.02313368022441864,
      -0.007033060304820538,
      -0.021981295198202133,
      -0.06878354400396347,
      -0.06316110491752625,
      -0.038323163986206055,
      -0.05617490038275719,
      0.040790099650621414,
      0.04919002950191498,
      -0.021017679944634438,
      -0.008304646238684654,
      -0.0579926073551178,
      -0.00895064789801836,
      -0.016664067283272743,
      0.012549923732876778,
      0.027413155883550644,
      -0.05000845342874527,
      -0.03166975453495979,
      0.08527923375368118,
      -0.02651628851890564,
      -0.0018153187120333314,
      -0.021496200934052467,
      -0.04824193939566612,
      0.004460189491510391,
      -0.01192396692931652,
      -0.021325521171092987,
      -0.06755581498146057,
      0.005048633553087711,
      -0.01938454620540142,
      -0.006491764448583126,
      -0.014665072783827782,
      -0.01296565867960453,
      0.10565496236085892,
      -0.023040268570184708,
      -0.028793543577194214,
      -0.048252616077661514,
      -0.02410307712852955,
      0.04715404286980629,
      0.06484591960906982,
      0.0022021238692104816,
      0.019625166431069374,
      -0.11504796892404556,
      -0.06552919000387192,
      0.03466419875621796,
      0.07161427289247513,
      -0.006599687039852142,
      0.04946524649858475,
      0.0881284847855568,
      0.030940311029553413,
      -0.03558884933590889,
      -0.05557028204202652,
      -0.02718890644609928,
      -0.011253801174461842,
      0.07487869262695312,
      -0.016035549342632294,
      0.09873392432928085,
      -0.07642700523138046,
      0.029259871691465378,
      -0.07890105247497559,
      0.026508811861276627,
      0.06575951725244522,
      -0.02555924281477928,
      -0.016973979771137238,
      0.05847678706049919,
      -0.04171551391482353,
      -0.03295908495783806,
      0.02246997505426407,
      0.04926201328635216,
      0.03916912153363228,
      0.09785081446170807,
      -0.12174571305513382,
      -0.03075391799211502,
      0.040997736155986786,
      -0.04147254303097725,
      0.035821299999952316,
      -0.07672268897294998,
      -0.02872392348945141,
      0.033470138907432556,
      0.007822001352906227,
      0.07453713566064835,
      -0.03568989038467407,
      -0.03373408317565918,
      0.0093090133741498,
      -0.01930474489927292,
      0.057147908955812454,
      -0.01798936352133751,
      0.10973409563302994,
      0.07430815696716309,
      -0.012840049341320992,
      0.05335364490747452,
      -0.049225594848394394,
      -0.010851923376321793,
      0.0007099336362443864,
      -0.0520382858812809,
      -0.06527979671955109,
      -0.07527673244476318,
      -0.05430491641163826,
      -0.0006071749958209693,
      -0.06428153812885284,
      0.04581907391548157,
      -0.09224905073642731,
      -0.005099545698612928,
      -0.040231019258499146,
      -0.07319561392068863,
      -0.0022720585111528635,
      -0.001771022449247539,
      0.06466357409954071,
      -0.027145707979798317,
      0.019823789596557617,
      -0.04997880011796951,
      0.04106176272034645,
      -0.035170041024684906,
      0.02010488137602806,
      -0.013975166715681553,
      -0.05735257267951965,
      -0.004250208847224712,
      -0.08652053028345108,
      -0.007079221773892641,
      -0.020219791680574417,
      0.04046747460961342,
      -0.10611739754676819,
      0.00919587817043066,
      0.008722071535885334,
      -0.12381288409233093,
      -0.01810920424759388,
      0.05057678371667862,
      0.08278638869524002,
      0.008420975878834724,
      0.028498930856585503,
      0.024445876479148865,
      0.022663094103336334,
      -0.06814771890640259,
      0.03391670435667038,
      0.12565065920352936,
      0.016491487622261047,
      0.05080297589302063,
      0.09034481644630432,
      0.015904458239674568,
      -0.02770502120256424,
      0.014239927753806114,
      -0.02639828622341156,
      0.09370049834251404,
      -0.06982853263616562,
      -0.023970525711774826,
      -0.046907201409339905,
      -0.05282874405384064,
      -0.006390304304659367,
      -0.03625117614865303,
      -0.04288404807448387,
      -0.01900281012058258,
      -0.05397737771272659,
      -0.048937488347291946,
      0.004755681846290827,
      -0.008534102700650692,
      -0.02146621234714985,
      0.01873808726668358,
      -0.033387161791324615,
      -0.04062722995877266,
      0.005216930061578751,
      0.05498534068465233,
      0.024863597005605698,
      -0.042801082134246826,
      0.05547283589839935,
      -0.0024176714941859245,
      0.018148669973015785,
      0.039327532052993774,
      -0.042790286242961884,
      -0.05831574276089668,
      -0.0681833028793335,
      -0.08827368170022964,
      0.019676655530929565,
      0.01594064012169838,
      -0.006727093365043402,
      -0.025278760120272636,
      0.0159013569355011,
      0.03102124109864235,
      -0.00413176603615284,
      0.031062068417668343,
      -0.009252333082258701,
      0.006731862667948008,
      0.05675027146935463,
      0.020126305520534515,
      -0.007513817399740219,
      0.033141158521175385,
      0.007465184200555086,
      -0.010041923262178898,
      0.001120135304518044,
      -0.02127496898174286,
      -0.006824999116361141,
      -0.03130717948079109,
      -0.004094229079782963,
      -0.0451371893286705,
      -0.07006381452083588,
      -0.041822973638772964,
      0.0098696518689394,
      0.044837724417448044,
      0.026536526158452034,
      -0.08391250669956207,
      0.07338262349367142,
      0.05025805905461311,
      -0.0014283759519457817,
      0.002932983450591564,
      -0.047816671431064606,
      0.01886913552880287,
      0.04853759706020355,
      -0.10414652526378632,
      0.09775825589895248,
      0.04495299234986305,
      0.01322532445192337,
      -0.045624975115060806,
      0.04377887770533562,
      0.06119911000132561,
      -0.10563810914754868,
      -0.023270895704627037,
      -0.028184758499264717,
      -0.012915905565023422,
      0.017142679542303085,
      -0.0315239243209362,
      0.0031055076979100704,
      -0.003255916526541114,
      -0.012771908193826675,
      0.02132650464773178,
      -0.028952719643712044,
      0.06327325850725174,
      0.024731861427426338,
      0.06146889552474022,
      0.04421789571642876,
      -0.025143343955278397,
      0.00680120475590229,
      -0.003818690311163664,
      -0.021306917071342468,
      -0.004088365472853184,
      -0.006712628062814474,
      0.000136380476760678,
      -0.04680120199918747,
      -0.021647615358233452,
      -0.0362200029194355,
      0.00047582012484781444,
      0.06961451470851898,
      0.01657196320593357,
      0.023437971249222755,
      -0.019051458686590195,
      -0.04273815080523491,
      0.020076343789696693,
      -0.05486932024359703,
      0.009072473272681236,
      0.00034970344859175384,
      0.03249606490135193,
      0.010824188590049744,
      0.0074930209666490555,
      -0.05775216221809387,
      0.00173542695119977,
      -0.002825553296133876,
      -0.04999781772494316,
      0.009825845248997211,
      0.011252222582697868,
      0.027206523343920708,
      -0.050902582705020905,
      -0.03137493506073952,
      -0.0048570153303444386,
      -0.09935420751571655,
      0.0011735757580026984,
      0.03867534175515175,
      -0.000779301393777132,
      0.0007284587481990457,
      0.03187572956085205,
      0.02102494426071644,
      0.05944398418068886,
      -0.019355908036231995,
      -0.03413265198469162,
      -0.06699161231517792,
      -0.05897145718336105,
      0.03561624512076378,
      0.06178535893559456,
      -0.05872497335076332,
      0.06142684444785118,
      0.027553638443350792,
      0.027043042704463005,
      0.09942314773797989,
      -0.033806368708610535,
      0.012885757721960545,
      0.006793943699449301,
      -0.012506688944995403,
      -0.005181894637644291,
      -0.03883395344018936,
      -0.020684778690338135,
      0.009549849666655064,
      0.02304656431078911,
      0.026279689744114876,
      0.06368236243724823,
      -0.05752941966056824,
      0.0077225822024047375,
      0.049893658608198166,
      -0.030738789588212967,
      -0.023387424647808075,
      0.012723710387945175,
      -0.03218229487538338,
      -0.01777883805334568,
      -0.052875589579343796,
      -0.01599845662713051,
      0.03140175715088844,
      0.03808705508708954,
      -0.06027884781360626,
      0.02699984423816204,
      -0.031255967915058136,
      0.029003238305449486,
      -0.0253747645765543,
      0.03890867531299591,
      0.00232303561642766,
      0.0014901782851666212,
      -0.034913141280412674,
      -0.06770674884319305,
      0.0392240434885025,
      0.01570003852248192,
      -0.05277596414089203,
      -0.017522990703582764,
      0.06121953949332237,
      0.052570100873708725,
      0.06396228075027466,
      0.07087448239326477,
      0.012758304364979267,
      0.004006493370980024,
      -0.033682115375995636,
      0.05455513298511505,
      0.024423951283097267,
      0.06062791869044304,
      -0.0019329629139974713,
      -0.06401590257883072,
      -0.0034762315917760134,
      -0.03933924809098244,
      -0.056581538170576096,
      0.020853495225310326,
      0.051310788840055466,
      -0.06385761499404907,
      0.03450475633144379,
      0.08951281011104584,
      0.02233007922768593,
      -0.025167396292090416,
      -0.11712629348039627,
      0.10593803972005844,
      0.006779554300010204,
      0.03497648239135742,
      0.05362064391374588,
      -0.006902826018631458,
      0.12371185421943665,
      0.04752731695771217,
      -0.07500994205474854,
      -0.04510347172617912,
      0.005017350427806377,
      0.04089657962322235,
      0.017242468893527985,
      -0.017265168949961662,
      0.021137099713087082,
      0.05109748989343643,
      0.004858057945966721,
      0.053134456276893616,
      0.01590730994939804,
      0.02431561052799225,
      -0.022097544744610786,
      -0.02734326757490635,
      0.023307466879487038,
      -0.06389164179563522,
      -0.049405019730329514,
      -0.006820648908615112,
      -0.029044004157185555,
      -0.00889705028384924,
      0.012217951938509941,
      0.03789918124675751,
      -0.0722920224070549,
      0.042414259165525436,
      0.00664898008108139,
      -0.10008186846971512,
      -0.014608729630708694,
      0.04332457855343819,
      -0.020326726138591766,
      -0.05113375186920166,
      -0.013446100987493992,
      0.006664644461125135,
      0.08771398663520813,
      -0.04327799752354622,
      -0.013343178667128086,
      0.02259615994989872,
      -0.011516526341438293,
      -0.015749718993902206,
      0.0104463379830122,
      -0.02802867256104946,
      0.07383614033460617,
      -0.03223440796136856,
      -0.04217013716697693,
      0.04529007524251938,
      -0.022121869027614594,
      0.07012250274419785,
      0.03443916514515877,
      -0.019656019285321236,
      0.0014639920555055141,
      0.048200659453868866,
      0.06427767872810364,
      -0.01981581375002861,
      -0.07506871223449707,
      0.028977053239941597,
      0.00521961972117424,
      0.04482175037264824,
      0.03174642473459244,
      0.009947465732693672,
      -0.032763637602329254,
      -0.0027048145420849323,
      0.06078340858221054,
      -0.044096432626247406,
      0.024158423766493797,
      -0.01811380870640278,
      -0.004146786406636238,
      -0.023667655885219574,
      -0.021428201347589493,
      -0.017375996336340904,
      0.017794543877243996,
      0.05498780310153961,
      -0.021330781280994415,
      0.06336603313684464
    ],
    [
      -0.011461963877081871,
      -0.03789791092276573,
      -0.0511840358376503,
      0.03886311501264572,
      -0.011875541880726814,
      -0.01115667074918747,
      0.015140730887651443,
      -0.028908463194966316,
      -0.005475898738950491,
      -0.01632632315158844,
      -0.04523581638932228,
      -0.020513338968157768,
      0.0362650565803051,
      0.014673683792352676,
      -0.07841821759939194,
      0.0017011555610224605,
      -0.013652274385094643,
      0.036618053913116455,
      0.005085791926831007,
      0.08174442499876022,
      0.04252079874277115,
      -0.03791181370615959,
      -0.004758486989885569,
      0.02773747779428959,
      -0.05499013513326645,
      -0.030836550518870354,
      0.06515077501535416,
      -0.057301852852106094,
      0.06748232245445251,
      0.105133555829525,
      0.020597955211997032,
      0.02927735261619091,
      -0.044739559292793274,
      0.022603096440434456,
      -0.03574832156300545,
      0.02590676210820675,
      0.03201204538345337,
      0.029894785955548286,
      -0.0006524889613501728,
      0.06514472514390945,
      -0.030187666416168213,
      -0.004868455231189728,
      -0.015489444136619568,
      0.02242838405072689,
      -0.09826112538576126,
      -0.10049327462911606,
      0.008209620602428913,
      0.016510680317878723,
      -0.05482814460992813,
      -0.04371119290590286,
      -0.015275574289262295,
      -0.0017707615625113249,
      0.05622382089495659,
      -0.12709446251392365,
      -0.08213751018047333,
      0.03256800398230553,
      0.002942474093288183,
      -0.10162962973117828,
      -0.04895729571580887,
      0.008375977165997028,
      -0.07197383046150208,
      -0.04334983229637146,
      -0.019757382571697235,
      -0.04064713418483734,
      -0.017771640792489052,
      0.04616105929017067,
      0.08183593302965164,
      -0.03786560893058777,
      0.07159901410341263,
      0.027455365285277367,
      0.0996759831905365,
      -0.11466986685991287,
      -0.0016483197687193751,
      0.04049404710531235,
      -0.03803364560008049,
      0.000956449774093926,
      -0.05785617604851723,
      0.007777052465826273,
      -0.015346430242061615,
      -0.06697146594524384,
      0.009007536806166172,
      -0.03490366041660309,
      0.01617472618818283,
      -0.1013229489326477,
      0.0499923974275589,
      0.030225161463022232,
      0.06636077165603638,
      0.012727344408631325,
      -0.03593739494681358,
      -0.08031781762838364,
      0.007951229810714722,
      -0.013285208493471146,
      -0.07814931124448776,
      -0.009282508864998817,
      0.07900580763816833,
      0.0026655839756131172,
      0.025810470804572105,
      0.027872562408447266,
      0.020113784819841385,
      0.09997397661209106,
      -0.0041914875619113445,
      0.048740874975919724,
      0.016030045226216316,
      -0.006125086918473244,
      0.037838246673345566,
      -0.021209849044680595,
      -0.022946780547499657,
      0.04497233033180237,
      0.01804177090525627,
      -0.046614326536655426,
      0.0503808856010437,
      0.008014394901692867,
      -0.02210891805589199,
      0.0840475782752037,
      -0.01505826786160469,
      -0.02542775124311447,
      -0.03657414764165878,
      0.006266087293624878,
      -0.02562038041651249,
      -0.08736058324575424,
      -0.056293100118637085,
      0.035679928958415985,
      -0.039481356739997864,
      0.0340786948800087,
      -0.05845259875059128,
      -0.04835247993469238,
      0.054304249584674835,
      -0.055367361754179,
      -0.018993906676769257,
      0.05493667349219322,
      -0.027923190966248512,
      0.013827712275087833,
      0.0421278178691864,
      -0.044965434819459915,
      0.009694305248558521,
      0.031118379905819893,
      -0.014100737869739532,
      -0.007405642885714769,
      0.01953868195414543,
      -0.019328845664858818,
      -0.03213147819042206,
      -0.012863481417298317,
      -0.06716655194759369,
      0.014353028498589993,
      0.011509204283356667,
      -0.012021392583847046,
      0.046308912336826324,
      0.05157070234417915,
      0.04091252014040947,
      0.060843247920274734,
      -0.05208804830908775,
      -0.018910031765699387,
      0.03449469804763794,
      -0.07182831317186356,
      0.015784693881869316,
      -0.05835087224841118,
      0.0050474051386117935,
      0.05006694048643112,
      -0.11027882993221283,
      -0.025431789457798004,
      0.032282400876283646,
      -0.06058769300580025,
      -0.008868055418133736,
      0.01271292194724083,
      0.06811963766813278,
      0.04168173670768738,
      0.04563063010573387,
      0.07532928884029388,
      0.022382717579603195,
      -0.06731865555047989,
      0.030009165406227112,
      0.029401907697319984,
      0.027927929535508156,
      -0.005645269062370062,
      -0.12619556486606598,
      -0.007648790255188942,
      0.02165117673575878,
      -0.011987291276454926,
      0.01898466795682907,
      0.03533974662423134,
      -0.033586062490940094,
      -0.06071631610393524,
      0.09503933042287827,
      -0.03203672170639038,
      0.009751181118190289,
      -0.018868757411837578,
      0.017915157601237297,
      0.009456530213356018,
      0.039790570735931396,
      0.029543502256274223,
      0.011905833147466183,
      -0.06201352924108505,
      -0.0007025490631349385,
      -0.07328503578901291,
      -0.014144116081297398,
      0.009437557309865952,
      0.016697218641638756,
      -0.024038439616560936,
      0.08213813602924347,
      -0.018295643851161003,
      -0.0359816774725914,
      0.03288106247782707,
      0.009062279015779495,
      -0.06320592015981674,
      0.07791099697351456,
      -0.050129953771829605,
      -0.03174349665641785,
      -0.011597514152526855,
      0.07626000046730042,
      -0.004751740489155054,
      0.04077300801873207,
      0.026571350172162056,
      0.03069327399134636,
      0.1329929530620575,
      0.029163504019379616,
      0.10736706852912903,
      -0.0507805198431015,
      0.01381125021725893,
      -0.016269566491246223,
      -0.051421135663986206,
      0.05467024818062782,
      -0.015865853056311607,
      -0.011618630960583687,
      0.022004788741469383,
      -0.028099697083234787,
      -0.07301011681556702,
      0.046390652656555176,
      -0.025916706770658493,
      0.027194568887352943,
      0.01859881728887558,
      0.041832175105810165,
      -0.008725535124540329,
      -0.03307029604911804,
      -0.019408943131566048,
      -0.022232500836253166,
      0.03238969296216965,
      -0.051234886050224304,
      -0.018831469118595123,
      -0.010713398456573486,
      0.06559562683105469,
      -0.04953886568546295,
      -0.004946744069457054,
      0.07587511837482452,
      0.0370766744017601,
      0.02559521608054638,
      0.034651678055524826,
      0.0610237792134285,
      0.05115019157528877,
      -0.012105577625334263,
      0.043375007808208466,
      -0.013423393480479717,
      -0.009557727724313736,
      -0.017799120396375656,
      0.015162223018705845,
      -0.03141125291585922,
      0.01487897802144289,
      0.01589454710483551,
      0.008086062967777252,
      0.007353317458182573,
      0.026923073455691338,
      -0.03509548678994179,
      0.03958682715892792,
      0.018763341009616852,
      0.010003635659813881,
      0.029606269672513008,
      0.056017760187387466,
      -0.11547288298606873,
      0.016838794574141502,
      -0.006416827905923128,
      -0.0051137907430529594,
      -0.07019085437059402,
      0.045206837356090546,
      -0.04732699319720268,
      0.036062322556972504,
      -0.028833352029323578,
      0.041261717677116394,
      0.08329414576292038,
      0.07890630513429642,
      0.02985151670873165,
      0.05288877338171005,
      0.02546391636133194,
      0.0346672460436821,
      -0.0011449664598330855,
      -0.02755933254957199,
      -0.032174598425626755,
      0.002963852835819125,
      0.02325679361820221,
      -0.02002137340605259,
      0.05008149892091751,
      -0.05339241400361061,
      0.0016726388130337,
      -0.04413539171218872,
      0.0025533551815897226,
      0.006159662269055843,
      0.0921022817492485,
      0.09017199277877808,
      -0.003574102185666561,
      0.016652923077344894,
      0.010636338964104652,
      0.006303031463176012,
      -0.0314163938164711,
      0.018001055344939232,
      0.07546941190958023,
      0.06743237376213074,
      0.0044253235682845116,
      0.07382792979478836,
      0.009706424549221992,
      -0.08951763808727264,
      0.02414941042661667,
      -0.0006888721254654229,
      0.0728687047958374,
      -0.02493344061076641,
      -0.03765810281038284,
      -0.004202533047646284,
      -8.264544885605574e-05,
      -0.08745407313108444,
      0.09464848786592484,
      0.018108578398823738,
      0.01793987676501274,
      -0.012321031652390957,
      0.022244920954108238,
      -0.03270433843135834,
      0.0032864375971257687,
      -0.03555874526500702,
      -0.030150895938277245,
      0.02182074449956417,
      -0.009217282757163048,
      -0.05822262167930603,
      0.009156468324363232,
      -0.008360938169062138,
      -0.048095762729644775,
      0.05313644930720329,
      -0.02429518848657608,
      0.039802197366952896,
      -0.10190217196941376,
      0.04316563531756401,
      -0.04436512663960457,
      0.0215330608189106,
      0.04461982846260071,
      -0.037203822284936905,
      0.07535942643880844,
      -0.06664083898067474,
      -0.0021985252387821674,
      0.04896853119134903,
      0.0355156846344471,
      0.01749122329056263,
      -0.03650008887052536,
      0.008829536847770214,
      0.020949939265847206,
      -0.018351607024669647,
      0.05454553663730621,
      0.014190394431352615,
      -0.024628831073641777,
      -0.0712416023015976,
      0.026425393298268318,
      -0.03863042593002319,
      -0.01044416893273592,
      -0.003562120022252202,
      0.09220685064792633,
      -0.051363877952098846,
      -0.031758490949869156,
      0.0160453412681818,
      0.0017270877724513412,
      0.06398963928222656,
      0.02211148850619793,
      -0.057750534266233444,
      0.053604744374752045,
      0.05674920603632927,
      -0.08366601914167404,
      0.09428305178880692,
      0.002597420709207654,
      0.024586275219917297,
      -0.05926312133669853,
      -0.000706334481947124,
      -0.049495548009872437,
      -0.01583484746515751,
      0.003122891765087843,
      0.013007212430238724,
      0.006719053722918034,
      0.009079844690859318,
      -0.016739951446652412,
      0.03868521749973297,
      -0.05384451895952225,
      -0.025626003742218018,
      -0.00824564229696989,
      -0.03877713531255722,
      -0.054914847016334534,
      0.010379323735833168,
      0.04099070653319359,
      -0.04527316242456436,
      0.028810717165470123,
      -0.014859943650662899,
      -0.04857445880770683,
      -0.016027113422751427,
      -0.03255953639745712,
      -0.004969124216586351,
      0.002681882819160819,
      -0.06837694346904755,
      -0.04857131466269493,
      0.038688693195581436,
      -0.00043439315049909055,
      0.059673700481653214,
      0.03131391108036041,
      -0.06087071821093559,
      0.01662210002541542,
      -0.03775094449520111,
      0.024695245549082756,
      -0.015637580305337906,
      -0.0357639417052269,
      -0.045901767909526825,
      -0.03388875722885132,
      -0.1039009839296341,
      0.023763783276081085,
      0.05523190647363663,
      -0.049909450113773346,
      -0.028027372434735298,
      -0.035613782703876495,
      0.07232316583395004,
      0.07268442958593369,
      0.07918284088373184,
      0.03280767425894737,
      0.012384784407913685,
      -0.02879212237894535,
      0.020029086619615555,
      -0.06412891298532486,
      -0.019558588042855263,
      -0.004223581403493881,
      0.054222773760557175,
      -0.06244489923119545,
      0.03617009148001671,
      -0.023647336289286613,
      0.00444050645455718,
      0.043818097561597824,
      -0.04007149115204811,
      -0.03985485062003136,
      -0.04200848191976547,
      -0.004777491558343172,
      0.036868609488010406,
      0.024185853078961372,
      0.007807999849319458,
      -0.09692658483982086,
      -0.02482573501765728,
      -0.09730198979377747,
      -0.03805423900485039,
      -0.020642686635255814,
      0.04597349837422371,
      0.08869293332099915,
      -0.06036391854286194,
      0.01269672904163599,
      0.012280718423426151,
      -0.090593121945858,
      0.012034259736537933,
      0.09595207124948502,
      -0.03316807746887207,
      0.03174217790365219,
      0.08459999412298203,
      0.03519006446003914,
      -0.0012963938061147928,
      -0.04883755370974541,
      0.043143678456544876,
      -0.025923602283000946,
      0.017215078696608543,
      -0.03549008443951607,
      -0.0043008760549128056,
      0.04674617946147919,
      -0.038891203701496124,
      0.0729384645819664,
      0.09267093986272812,
      -0.028784053400158882,
      0.040859404951334,
      0.034354835748672485,
      -0.014449739828705788,
      -0.006579297594726086,
      -0.04963398352265358,
      -0.01768285036087036,
      0.024373261258006096,
      0.0319698266685009,
      -0.0031394849065691233,
      0.010863134637475014,
      0.07779253274202347,
      -0.02745099551975727,
      0.0353451706469059,
      -0.028326937928795815,
      -0.053242556750774384,
      0.05375123769044876,
      -0.013723945245146751,
      -0.013664163649082184,
      -0.04160946607589722,
      0.009524033404886723,
      0.06689494103193283,
      0.026964427903294563,
      0.0058766319416463375,
      -0.018557215109467506,
      -0.022498711943626404,
      -0.03934668004512787,
      -0.05080021917819977,
      0.07102283835411072,
      0.07678341865539551,
      0.04528988152742386,
      0.07475940138101578,
      0.01562713272869587,
      -0.022043509408831596,
      0.04300447180867195
    ],
    [
      -0.005372153595089912,
      -0.0999336987733841,
      -0.03367273136973381,
      0.027149302884936333,
      0.02714449167251587,
      0.04726039245724678,
      -0.011497952975332737,
      -0.06925284117460251,
      -0.04623712599277496,
      0.0548490472137928,
      0.012195554561913013,
      0.0126589834690094,
      -0.04216906055808067,
      0.017966317012906075,
      -0.022746963426470757,
      -0.006489704363048077,
      0.023778365924954414,
      0.09884149581193924,
      -0.019454022869467735,
      0.012521637603640556,
      -0.05839183181524277,
      0.01155867613852024,
      -0.03031136281788349,
      0.04073570296168327,
      0.1250428855419159,
      -0.014600331895053387,
      -0.019164565950632095,
      -0.021377110853791237,
      0.01983162946999073,
      -0.13487932085990906,
      0.005097875837236643,
      -0.02229001186788082,
      -0.012947430834174156,
      -0.014713911339640617,
      0.053091663867235184,
      -0.05389251932501793,
      -0.04633387550711632,
      0.00886901281774044,
      0.018049519509077072,
      -0.031689707189798355,
      0.02824787236750126,
      0.002039091195911169,
      -0.0008995056268759072,
      0.005696720909327269,
      -0.02381589263677597,
      -0.03686690703034401,
      0.0074611566960811615,
      0.015221887268126011,
      -0.06338602304458618,
      -0.02734563872218132,
      0.0435335636138916,
      -0.05254453793168068,
      0.04688599333167076,
      -0.028471022844314575,
      -0.05218435078859329,
      -0.03719213232398033,
      -0.0422414094209671,
      0.019866185262799263,
      -0.018157068639993668,
      -0.040916990488767624,
      0.048012249171733856,
      0.0019919397309422493,
      0.014798397198319435,
      -0.020560164004564285,
      -0.054173387587070465,
      -0.004703531041741371,
      0.06983187049627304,
      0.019432399421930313,
      0.0008975749369710684,
      0.00885112676769495,
      0.03891916573047638,
      -0.03269621729850769,
      0.017914526164531708,
      -0.03257964178919792,
      -0.023844337090849876,
      -0.0018186315428465605,
      0.003962147049605846,
      -0.06041577085852623,
      -0.0088516129180789,
      0.0025392593815922737,
      0.05941006913781166,
      0.060699619352817535,
      0.02351444400846958,
      -0.04064549133181572,
      0.056647203862667084,
      0.04888280853629112,
      -0.07012221217155457,
      0.014816396869719028,
      -0.08668608963489532,
      -0.017030561342835426,
      -0.05984693393111229,
      -0.06748344749212265,
      0.05354247987270355,
      0.09217844158411026,
      0.04841066151857376,
      0.09123362600803375,
      0.07372421771287918,
      0.048481862992048264,
      -0.014088841155171394,
      0.011145958676934242,
      0.005751675460487604,
      -0.044554147869348526,
      -0.025238478556275368,
      -0.06420046836137772,
      0.062374334782361984,
      -0.08085037022829056,
      0.06632968783378601,
      0.02236655168235302,
      0.03231869265437126,
      0.0893152728676796,
      -0.033776190131902695,
      -0.0674319639801979,
      -0.0238833986222744,
      -0.020813651382923126,
      0.022089043632149696,
      -0.05804004147648811,
      0.04236737638711929,
      -0.0633661225438118,
      -0.0019315080717206001,
      -0.05755024775862694,
      -0.061929430812597275,
      -0.03485287353396416,
      0.014210863970220089,
      0.025869663804769516,
      -0.02016066014766693,
      0.03845588117837906,
      -0.016545414924621582,
      -0.061887215822935104,
      -0.06242219731211662,
      0.06559993326663971,
      -0.02434113249182701,
      0.0218329057097435,
      -0.013365131802856922,
      -0.03095034509897232,
      0.07840576767921448,
      0.024992192164063454,
      -0.048940371721982956,
      0.01941726729273796,
      -0.031351782381534576,
      -0.00038516244967468083,
      -0.017336664721369743,
      -0.07451427727937698,
      0.0226565171033144,
      -0.05805739760398865,
      -0.05433060973882675,
      -0.0473681204020977,
      0.08103054016828537,
      0.062491703778505325,
      0.0017560598207637668,
      0.03495297580957413,
      -0.09370224177837372,
      0.06646627187728882,
      0.027333665639162064,
      -0.0012549939565360546,
      -0.009272058494389057,
      -0.05444154515862465,
      -0.022295434027910233,
      0.015193209052085876,
      0.01283613033592701,
      -0.05922020226716995,
      -0.06504281610250473,
      -0.0035968490410596132,
      0.09276535362005234,
      -0.04577494040131569,
      -0.0039717648178339005,
      -0.005624738987535238,
      0.020530862733721733,
      0.02204645797610283,
      0.12958525121212006,
      -0.0567917563021183,
      -0.025438765063881874,
      0.04456488788127899,
      -0.030913250520825386,
      0.032505981624126434,
      0.020955350250005722,
      0.01500557642430067,
      0.06260599195957184,
      -0.04318275675177574,
      -0.07255633920431137,
      0.07126913219690323,
      -0.042154282331466675,
      0.10608342289924622,
      -0.01896623708307743,
      0.002538066590204835,
      -0.02496062032878399,
      0.09822659194469452,
      -0.015585825778543949,
      -0.01328652910888195,
      -0.05681942030787468,
      0.05940752848982811,
      0.11235589534044266,
      -0.031230207532644272,
      -0.00926152803003788,
      0.0007632761844433844,
      0.03886259347200394,
      -0.0647566020488739,
      0.06287280470132828,
      -0.02294306270778179,
      -0.06228095665574074,
      -0.04346945136785507,
      0.030431324616074562,
      -0.04640872776508331,
      -0.0773010328412056,
      0.03283030912280083,
      0.06826836615800858,
      0.038297176361083984,
      -0.008655783720314503,
      -0.028657475486397743,
      -0.053207531571388245,
      -0.026231002062559128,
      -0.050194013863801956,
      0.09036098420619965,
      -0.01985318586230278,
      0.025544213131070137,
      0.03378122299909592,
      -0.012222609482705593,
      0.02506549283862114,
      0.020084822550415993,
      0.033192671835422516,
      0.0519903302192688,
      0.01889994367957115,
      0.03348422795534134,
      0.006147430278360844,
      -0.03573630377650261,
      0.01879127509891987,
      -0.01102940458804369,
      -0.028412505984306335,
      0.06930419057607651,
      -0.03234279155731201,
      0.05944900959730148,
      0.0044148946180939674,
      -0.016384873539209366,
      0.0509389191865921,
      -0.05056493356823921,
      -0.07832136005163193,
      -0.007486989255994558,
      -0.05612538009881973,
      -0.015097127296030521,
      0.04607939347624779,
      -0.039103586226701736,
      -0.06370335072278976,
      0.01747211068868637,
      0.08090821653604507,
      0.1138211265206337,
      -0.0225426834076643,
      -0.028235463425517082,
      -0.039444468915462494,
      -0.053575705736875534,
      -0.02032020501792431,
      0.022889647632837296,
      -0.07781659066677094,
      -0.016511574387550354,
      0.08781774342060089,
      -0.04666411504149437,
      0.006316682789474726,
      0.0731508731842041,
      -0.04248303174972534,
      0.018893808126449585,
      0.07813374698162079,
      0.02559727244079113,
      -0.0005983669543638825,
      -0.014535963535308838,
      -0.062113236635923386,
      -0.0926298126578331,
      -0.047647856175899506,
      0.02787998877465725,
      -0.04247261956334114,
      -0.025355909019708633,
      -0.03591795265674591,
      -0.02329474873840809,
      0.05433699116110802,
      0.024157648906111717,
      -0.018360795453190804,
      -0.016563307493925095,
      0.02025318518280983,
      0.047883257269859314,
      -0.04140102118253708,
      -0.04462326318025589,
      -0.05904156342148781,
      0.028032489120960236,
      0.01413201168179512,
      -0.014750837348401546,
      -0.052745845168828964,
      0.01426937710493803,
      0.034726012498140335,
      -0.059266965836286545,
      0.04713447764515877,
      -0.019625311717391014,
      -0.04127819463610649,
      -0.012408113107085228,
      -0.009389870800077915,
      0.13496316969394684,
      -0.05854077264666557,
      -0.0440719872713089,
      0.03773903474211693,
      0.054533958435058594,
      -0.012666674330830574,
      0.014448244124650955,
      -0.013223709538578987,
      -0.01127543393522501,
      -0.003032094333320856,
      -0.045291610062122345,
      0.08150181174278259,
      0.00561865046620369,
      0.02436927706003189,
      -0.004277318716049194,
      0.0592503659427166,
      -0.021055525168776512,
      -0.08980873227119446,
      0.06353147327899933,
      0.002247750060632825,
      0.03200225904583931,
      0.0322253592312336,
      0.008978781290352345,
      0.030045855790376663,
      0.029452811926603317,
      -0.0877952054142952,
      -0.018207527697086334,
      -0.08042963594198227,
      0.04893554002046585,
      0.07889027893543243,
      -0.08525414764881134,
      0.09947545826435089,
      0.07020817697048187,
      -0.06006566807627678,
      -0.03418213501572609,
      -0.0006368162576109171,
      -0.07450573891401291,
      -0.0376460961997509,
      0.04441969096660614,
      -0.12084772437810898,
      0.008478146977722645,
      0.015498739667236805,
      -0.07756618410348892,
      -0.014485868625342846,
      0.07959649711847305,
      0.06701867282390594,
      0.013674283400177956,
      -0.03799567371606827,
      0.02509957179427147,
      -0.011644323356449604,
      -0.03676195070147514,
      -0.0394870787858963,
      -0.03853233903646469,
      0.017207400873303413,
      -0.004331874195486307,
      -0.03257008269429207,
      -0.05739571526646614,
      0.07424028217792511,
      -0.03590135648846626,
      0.033409036695957184,
      0.019643034785985947,
      0.030974090099334717,
      -0.015495900996029377,
      0.041548509150743484,
      -0.030936749652028084,
      -0.02852693386375904,
      -0.01751520484685898,
      0.055489443242549896,
      0.06861546635627747,
      -0.018192525953054428,
      -0.07635097205638885,
      -0.005566313862800598,
      0.004857943393290043,
      0.03281684219837189,
      -0.028726080432534218,
      0.02042958326637745,
      0.0069372146390378475,
      -0.029596885666251183,
      -0.02279140055179596,
      0.06272920966148376,
      -0.00783764198422432,
      -0.0057040113024413586,
      -0.015199915505945683,
      0.004753916058689356,
      -0.1128157377243042,
      0.05257648974657059,
      0.04729390889406204,
      0.014488501474261284,
      0.07110952585935593,
      -0.02066810615360737,
      0.0175635889172554,
      0.014788392931222916,
      0.04819805175065994,
      0.0076844231225550175,
      -0.03406038507819176,
      -0.0019071653950959444,
      -0.024314196780323982,
      0.027511510998010635,
      0.0034157962072640657,
      0.030982447788119316,
      -0.010521664284169674,
      -0.01152811385691166,
      0.05649919807910919,
      -0.03353425860404968,
      -0.009250196628272533,
      -0.046608082950115204,
      0.02227696403861046,
      0.04675798863172531,
      0.0125427832826972,
      -0.03125370666384697,
      0.050456155091524124,
      0.05854012444615364,
      0.0027749540749937296,
      0.017098749056458473,
      0.0223566684871912,
      -0.028925511986017227,
      0.02019849419593811,
      0.06800351291894913,
      0.06765289604663849,
      -0.00972199346870184,
      0.0018446119502186775,
      0.04254142940044403,
      -0.0009056709823198617,
      0.012101203203201294,
      0.0519668310880661,
      0.07108305394649506,
      0.06352084875106812,
      0.018008191138505936,
      0.023765547201037407,
      0.0009790267795324326,
      0.05707436427474022,
      0.019107084721326828,
      -0.02918168716132641,
      -0.06442984193563461,
      0.0062447283416986465,
      -0.004859370645135641,
      0.03900359570980072,
      -0.10772184282541275,
      -0.0009408543701283634,
      0.08953499794006348,
      0.10050615668296814,
      0.017246531322598457,
      -0.011325242929160595,
      -0.055810291320085526,
      0.05781520903110504,
      0.045714862644672394,
      -0.02946770377457142,
      -0.04336141049861908,
      0.01766338013112545,
      -0.0036173479165881872,
      0.03034151718020439,
      0.04703301191329956,
      -0.06554281711578369,
      -0.029888788238167763,
      -0.01708495058119297,
      -0.05599048733711243,
      -0.018781786784529686,
      -0.048743173480033875,
      -0.06345170736312866,
      0.06407295912504196,
      0.019162220880389214,
      0.03876389563083649,
      -0.05742309242486954,
      0.00549986120313406,
      0.004425526596605778,
      -0.06310638040304184,
      -0.006738769821822643,
      0.02467433363199234,
      0.09974495321512222,
      0.014774587005376816,
      -0.03565685451030731,
      -0.008494148962199688,
      -0.03370920568704605,
      0.026763927191495895,
      -0.037069253623485565,
      -0.021047404035925865,
      0.07666385918855667,
      -0.0167218130081892,
      -0.0042527541518211365,
      -0.057489629834890366,
      0.052699677646160126,
      0.019704094156622887,
      0.01956338807940483,
      -0.02979312464594841,
      0.04226841777563095,
      -0.03104083053767681,
      -0.05732385441660881,
      0.021000921726226807,
      -0.00015168814570643008,
      -0.073161780834198,
      0.009502657689154148,
      -0.018807461485266685,
      -0.0816720724105835,
      -0.023249568417668343,
      -0.03509579598903656,
      -0.0007225903100334108,
      0.04467019438743591,
      0.050047360360622406,
      0.01483367569744587,
      0.02679239772260189,
      0.05939115956425667,
      0.008406181819736958,
      0.078801728785038,
      0.028056016191840172,
      0.009473110549151897,
      -0.0314963199198246,
      0.01716148480772972,
      0.023574991151690483,
      0.013921404257416725,
      0.019331008195877075,
      0.005248835310339928,
      0.020077012479305267
    ],
    [
      -0.008910372853279114,
      -0.08304829150438309,
      -0.11527564376592636,
      -0.014683367684483528,
      -0.05572811886668205,
      0.01197190023958683,
      0.044769302010536194,
      -0.03688344359397888,
      0.01663960888981819,
      -0.125191330909729,
      -0.028613165020942688,
      0.039435211569070816,
      -0.04892902448773384,
      -0.023814352229237556,
      -0.012429389171302319,
      -0.06906001269817352,
      0.0049953763373196125,
      0.030473873019218445,
      -0.02026616409420967,
      -0.030768468976020813,
      -0.013176817446947098,
      0.019094424322247505,
      -0.005318961571902037,
      -0.006500517018139362,
      -0.04886555299162865,
      -0.007952176965773106,
      -0.05924130976200104,
      -0.012934078462421894,
      -0.07438664883375168,
      0.03698426112532616,
      -0.12788425385951996,
      0.07333092391490936,
      0.023847337812185287,
      -0.09058766067028046,
      0.07633350044488907,
      -0.0928216502070427,
      -0.05769673362374306,
      0.004529260098934174,
      -0.01847132295370102,
      0.048237722367048264,
      -0.018133828416466713,
      0.05802004039287567,
      -0.008719473145902157,
      0.04852740466594696,
      -0.011208788491785526,
      -0.029761552810668945,
      0.03439655900001526,
      0.0019011739641427994,
      -0.1238885298371315,
      -0.009458238258957863,
      -0.06766144186258316,
      0.043031707406044006,
      0.06619380414485931,
      -0.037411998957395554,
      -0.051767028868198395,
      -0.07698330283164978,
      -0.051596302539110184,
      -0.10762473195791245,
      0.107151560485363,
      0.0015088024083524942,
      -0.057554204016923904,
      0.017775312066078186,
      -0.03878355398774147,
      0.018410300835967064,
      -0.008937742561101913,
      0.01910220831632614,
      -0.006473762448877096,
      0.06875435262918472,
      0.07297428697347641,
      0.025190725922584534,
      -0.02617713436484337,
      -0.025059551000595093,
      0.015323356725275517,
      0.015544249676167965,
      -0.033813636749982834,
      0.035234469920396805,
      -0.051736459136009216,
      0.07598276436328888,
      -0.02946356311440468,
      0.0473744198679924,
      0.017995400354266167,
      0.024877501651644707,
      -0.07199160754680634,
      -0.019815001636743546,
      -0.003157347207888961,
      0.008900509215891361,
      -0.06344392150640488,
      0.0662091076374054,
      -0.09880878776311874,
      0.02411256730556488,
      -0.055003777146339417,
      0.043255746364593506,
      -0.06629908829927444,
      0.11350059509277344,
      -0.015877503901720047,
      -0.04262677580118179,
      0.04925569146871567,
      -0.040906865149736404,
      -0.03687573969364166,
      0.047416627407073975,
      -0.07691283524036407,
      -0.027146294713020325,
      -0.07643858343362808,
      -0.05295002460479736,
      0.014277285896241665,
      -0.010935022495687008,
      -0.06109771504998207,
      0.008410904556512833,
      0.07346673309803009,
      0.1647404283285141,
      -0.02119327150285244,
      0.04641808941960335,
      0.023250896483659744,
      -0.07594605535268784,
      0.06396128237247467,
      0.01564943976700306,
      -0.03882806748151779,
      -0.03362333029508591,
      -0.008731572888791561,
      0.023585351184010506,
      0.07652075588703156,
      0.056101731956005096,
      0.040095701813697815,
      -0.06195398420095444,
      0.08766624331474304,
      -0.07103700190782547,
      0.005625103134661913,
      -0.06867393106222153,
      -0.014344907365739346,
      0.13136205077171326,
      -0.07132549583911896,
      -0.004980727564543486,
      -0.06627365201711655,
      -0.015817835927009583,
      -0.03271494060754776,
      -0.04696546122431755,
      -0.04317339509725571,
      0.011978930793702602,
      -0.026961710304021835,
      -0.06165621429681778,
      0.02170393615961075,
      -0.015969574451446533,
      -0.0030486995819956064,
      -0.02314257249236107,
      0.07501287013292313,
      -0.03564077988266945,
      0.048856157809495926,
      -0.018672429025173187,
      -0.060710176825523376,
      0.04897182807326317,
      -0.07939596474170685,
      -0.03854333981871605,
      -0.014132559299468994,
      0.04903414100408554,
      0.04605979472398758,
      0.005829516798257828,
      0.026858173310756683,
      -0.011475436389446259,
      -0.013255025260150433,
      0.06677987426519394,
      -0.01740160398185253,
      0.018015621230006218,
      0.03508241847157478,
      0.020434865728020668,
      -0.008045424707233906,
      -0.011891874484717846,
      -0.0029748326633125544,
      0.05484917014837265,
      -0.051161255687475204,
      0.0057148742489516735,
      0.0329875722527504,
      -0.02231823466718197,
      0.01751573011279106,
      0.030756130814552307,
      -0.01579928584396839,
      -0.0007165565039031208,
      0.07190124690532684,
      -0.004442876670509577,
      -0.032703276723623276,
      -0.04198523238301277,
      -0.046385541558265686,
      0.008054654113948345,
      0.04648207128047943,
      0.013358045369386673,
      -0.030787911266088486,
      -0.01696104370057583,
      -0.005981383845210075,
      0.030535707250237465,
      0.04623778164386749,
      0.004877527244389057,
      -0.020415257662534714,
      -0.0038531695026904345,
      0.047390230000019073,
      0.009745893999934196,
      0.04966375604271889,
      0.03878862410783768,
      -0.012811130844056606,
      0.02776595950126648,
      0.007071604486554861,
      0.08639370650053024,
      -0.020279066637158394,
      0.023981094360351562,
      0.05476467311382294,
      -0.01120841596275568,
      0.04091304540634155,
      0.006550776306539774,
      -0.007273106370121241,
      0.009925348684191704,
      0.01578274928033352,
      -0.006380296312272549,
      0.05144413933157921,
      0.01892007887363434,
      0.003477724501863122,
      0.07112281769514084,
      -0.04356388375163078,
      0.007650444284081459,
      -0.018614819273352623,
      -0.04187272489070892,
      -0.009682496078312397,
      -0.011385868303477764,
      -0.08636169880628586,
      -0.008357035927474499,
      0.028661716729402542,
      0.025476694107055664,
      0.07202357798814774,
      -0.019047439098358154,
      0.01320144347846508,
      0.03375064209103584,
      0.038633957505226135,
      0.08381252735853195,
      0.036756157875061035,
      0.04930433630943298,
      -0.024663982912898064,
      0.007522826083004475,
      0.08346486836671829,
      -0.11618933081626892,
      0.03176133334636688,
      -0.015509285032749176,
      -0.055189281702041626,
      0.020977158099412918,
      0.0013750629732385278,
      0.03188435733318329,
      -0.11732715368270874,
      0.06717455387115479,
      0.12247034162282944,
      -0.020053382962942123,
      -0.02440010756254196,
      0.04012458398938179,
      0.09817364066839218,
      0.0035728244110941887,
      0.011421432718634605,
      0.0023530281614512205,
      0.056702230125665665,
      0.029680956155061722,
      -0.024297187104821205,
      0.03683633729815483,
      0.042680490761995316,
      0.053938526660203934,
      -0.028775028884410858,
      0.03735368698835373,
      -0.05134161189198494,
      -0.026429612189531326,
      -0.020422495901584625,
      -0.025538498535752296,
      0.007229930721223354,
      0.013927550055086613,
      0.06997115164995193,
      -0.007856544107198715,
      -0.03695511445403099,
      0.025105640292167664,
      0.02048720419406891,
      -0.019987452775239944,
      -0.03605782985687256,
      0.000869369599968195,
      -0.012127945199608803,
      -0.015271339565515518,
      0.02435348927974701,
      -0.071628138422966,
      0.03100712224841118,
      0.04559674859046936,
      0.04992866516113281,
      0.008947241120040417,
      0.02747570164501667,
      0.01969902776181698,
      -0.05151205509901047,
      0.03510343283414841,
      -0.01865825429558754,
      0.02894539386034012,
      0.011616818606853485,
      -0.063799187541008,
      -0.00569933233782649,
      0.039861999452114105,
      -0.016364600509405136,
      0.006238606292754412,
      -0.03973142057657242,
      -0.01439775712788105,
      0.0320541076362133,
      -0.03050971031188965,
      0.006757647730410099,
      -0.03895904868841171,
      0.03910210356116295,
      -0.022709082812070847,
      0.014880982227623463,
      -0.05921672284603119,
      0.015212018974125385,
      0.03924983739852905,
      -0.0774325281381607,
      -0.05425005406141281,
      0.057936277240514755,
      -0.008427688851952553,
      0.12105314433574677,
      -0.04138866811990738,
      0.004866666626185179,
      -0.055217254906892776,
      -0.01874162256717682,
      -0.04820305481553078,
      -0.06893688440322876,
      -0.06626034528017044,
      -0.013657446950674057,
      0.01582249440252781,
      0.01737784408032894,
      -0.02972380444407463,
      0.05444331094622612,
      -0.06792207062244415,
      -0.015591834671795368,
      -0.062072452157735825,
      -0.05343284085392952,
      -0.053862739354372025,
      -0.02209014818072319,
      0.013813489116728306,
      0.040027253329753876,
      -0.015131655149161816,
      -0.031323544681072235,
      0.052727021276950836,
      0.042308509349823,
      -0.012136588804423809,
      -0.009315588511526585,
      0.033561449497938156,
      0.005811826325953007,
      -0.021979505196213722,
      -0.006584049668163061,
      -0.06872928887605667,
      0.016826270148158073,
      -0.06406308710575104,
      -0.0015236944891512394,
      -0.041781146079301834,
      -0.025074319913983345,
      0.05828288942575455,
      0.018070537596940994,
      -0.07552391290664673,
      0.047961097210645676,
      -0.0477103665471077,
      0.04425523430109024,
      -0.040057092905044556,
      0.08768600970506668,
      0.08265575766563416,
      0.018215496093034744,
      -0.039785899221897125,
      0.03586798906326294,
      0.022719606757164,
      -0.028849275782704353,
      -0.04552767798304558,
      -0.06375923752784729,
      0.022672824561595917,
      -0.006092692259699106,
      0.002041721949353814,
      -0.04898763447999954,
      -0.013351342640817165,
      0.005200651939958334,
      0.02592511847615242,
      0.06933051347732544,
      -0.0032264795154333115,
      -0.04407744109630585,
      -0.04291195422410965,
      -0.05939006432890892,
      -0.0775613784790039,
      0.003571224631741643,
      0.01721712201833725,
      -0.006765397265553474,
      0.009602956473827362,
      -0.06160449981689453,
      0.031414952129125595,
      -0.043613120913505554,
      -0.07883990556001663,
      -0.010135486721992493,
      -0.00180908537004143,
      0.07052822411060333,
      0.006156763527542353,
      -0.015706917271018028,
      0.07950862497091293,
      0.018457992002367973,
      0.035163432359695435,
      -0.05630701035261154,
      0.004322003107517958,
      0.03775382786989212,
      0.13470779359340668,
      0.0669156089425087,
      -0.035378046333789825,
      -0.014951840043067932,
      0.05086884647607803,
      -0.04071966931223869,
      0.09486158937215805,
      0.0430268868803978,
      0.013718068599700928,
      -0.010337541811168194,
      -0.04339633882045746,
      -0.0265376977622509,
      0.00391473388299346,
      -0.016685813665390015,
      -0.025260724127292633,
      -0.04115895554423332,
      0.057632509618997574,
      0.019800135865807533,
      0.019158659502863884,
      -0.05386361852288246,
      0.014871693216264248,
      0.023312147706747055,
      0.0819966048002243,
      0.07394366711378098,
      0.08810268342494965,
      0.022076407447457314,
      -0.002313379431143403,
      0.026734840124845505,
      -0.06022724136710167,
      -0.004626750014722347,
      -0.027124527841806412,
      0.07954834401607513,
      -0.007290554232895374,
      -0.04699668660759926,
      -0.04986703023314476,
      -0.0059927962720394135,
      -0.0553181990981102,
      -0.07359182834625244,
      -0.020860567688941956,
      0.007692290470004082,
      0.06524217873811722,
      -0.01213724073022604,
      0.04972222074866295,
      0.019081540405750275,
      -0.021182211115956306,
      -0.005315928719937801,
      -0.0699433758854866,
      -0.0030039241537451744,
      -0.0372946672141552,
      0.006379747297614813,
      0.02899859845638275,
      0.04000790789723396,
      0.020245909690856934,
      -0.04412945732474327,
      0.0761025995016098,
      0.053451310843229294,
      0.08056620508432388,
      -0.045494094491004944,
      -0.03221927210688591,
      0.10250776261091232,
      0.06500694155693054,
      -0.06843387335538864,
      0.026532839983701706,
      -0.05346903204917908,
      0.0014521133853122592,
      -0.039556749165058136,
      -0.015165028162300587,
      -0.049660056829452515,
      0.06876231729984283,
      0.011975019238889217,
      0.035516221076250076,
      -0.0652211457490921,
      -0.021875858306884766,
      -0.057087790220975876,
      0.054026514291763306,
      0.03180878981947899,
      -0.016731204465031624,
      0.03056882694363594,
      0.02721867710351944,
      0.02180667780339718,
      0.008542473427951336,
      0.03788026049733162,
      0.039184730499982834,
      0.0024234093725681305,
      0.09922821074724197,
      -0.01884687878191471,
      0.011127754114568233,
      0.07501990348100662,
      0.011984075419604778,
      -0.034932691603899,
      0.03972632810473442,
      -2.3580567358294502e-05,
      0.009144122712314129,
      0.00664387084543705,
      0.016764698550105095,
      -0.04559052735567093,
      0.008526650257408619,
      -0.04514533653855324,
      -0.030481886118650436,
      0.014925161376595497,
      -0.034763313829898834,
      0.011011466383934021,
      -0.02945655770599842,
      -0.049852922558784485,
      -0.00010455631127115339,
      -0.03739207983016968,
      0.016113221645355225,
      -0.03716408461332321
    ],
    [
      0.06540019810199738,
      0.05487542599439621,
      -0.005518631543964148,
      -0.035115137696266174,
      0.002729736501350999,
      -0.026959765702486038,
      0.0005593946552835405,
      0.03910757601261139,
      0.0678471103310585,
      -0.08672907948493958,
      -0.02134091593325138,
      0.047738365828990936,
      -0.024974917992949486,
      0.024252546951174736,
      -0.08512796461582184,
      -0.03736375644803047,
      -0.021866710856556892,
      -0.00611590500921011,
      -0.01300243753939867,
      0.04721861332654953,
      -0.02760469540953636,
      0.06571658700704575,
      -0.002320133848115802,
      0.0018314983462914824,
      -0.029461391270160675,
      0.08679604530334473,
      0.027029071003198624,
      0.008884305134415627,
      -0.035684820264577866,
      -0.05130867287516594,
      -0.06812084466218948,
      0.06466037780046463,
      -0.035137057304382324,
      -0.019141891971230507,
      -0.014819382689893246,
      -0.022902261465787888,
      0.05374877154827118,
      -0.014659452252089977,
      0.030963998287916183,
      0.07621574401855469,
      -0.01719033159315586,
      0.02296997420489788,
      -0.08236826956272125,
      0.005712701939046383,
      -0.0020772675052285194,
      -0.051516927778720856,
      -0.07073866575956345,
      0.04254831373691559,
      0.0004950880538672209,
      0.018236074596643448,
      -0.009141496382653713,
      0.06723832339048386,
      -0.0801411122083664,
      -0.030267247930169106,
      0.006706864573061466,
      0.025626275688409805,
      0.05348945036530495,
      0.05334194004535675,
      -0.014042454771697521,
      -0.007362065836787224,
      0.03575573116540909,
      -0.05931346118450165,
      0.006368698552250862,
      0.04023817926645279,
      0.032413460314273834,
      -0.023924250155687332,
      -0.006198864430189133,
      -0.04766617342829704,
      0.0753433108329773,
      0.05841277539730072,
      -0.07828116416931152,
      -0.037401482462882996,
      -0.07290949672460556,
      0.024708731099963188,
      0.05415182560682297,
      -0.0004964680992998183,
      0.05623041093349457,
      0.004189472645521164,
      0.016864677891135216,
      -0.014040145091712475,
      -0.019797595217823982,
      -0.01870652846992016,
      -0.016186032444238663,
      -0.01655644364655018,
      -0.008899751119315624,
      -0.009742725640535355,
      0.07780542224645615,
      0.07783618569374084,
      0.022994929924607277,
      -0.01387021690607071,
      0.002784724347293377,
      0.005814684554934502,
      0.034989211708307266,
      0.019558843225240707,
      0.07043260335922241,
      -0.050580017268657684,
      -0.007202482316643,
      0.018620675429701805,
      -0.022604476660490036,
      0.02698998898267746,
      0.013099812902510166,
      0.010447841137647629,
      -0.04586397111415863,
      -0.025090891867876053,
      -0.07465159147977829,
      -0.07667563855648041,
      -0.0731448084115982,
      0.027739308774471283,
      -0.012757663615047932,
      0.003954186104238033,
      -0.005197369493544102,
      0.08412390202283859,
      -0.023616651073098183,
      0.009279526770114899,
      0.09904342889785767,
      -0.023180272430181503,
      -0.000501486356370151,
      0.029978176578879356,
      -0.019810611382126808,
      -0.010303999297320843,
      -0.08144846558570862,
      -0.08627240359783173,
      0.020495489239692688,
      -0.03301186114549637,
      0.011194104328751564,
      0.03563113510608673,
      -0.06085745990276337,
      -0.009199335239827633,
      0.005295439623296261,
      0.035392194986343384,
      -0.021198146045207977,
      0.007650204002857208,
      0.011733935214579105,
      0.050890881568193436,
      0.074760802090168,
      -0.029444977641105652,
      -0.03946588933467865,
      -0.03091246448457241,
      0.01146829966455698,
      0.013167154975235462,
      0.01975330337882042,
      0.0006011234945617616,
      0.04892318323254585,
      0.011013456620275974,
      0.030860748142004013,
      -0.01694617047905922,
      0.06839011609554291,
      0.04963461682200432,
      -0.005904775578528643,
      -0.04600520804524422,
      0.05758187547326088,
      -0.057541266083717346,
      0.011788460426032543,
      -0.06539938598871231,
      -0.03615666180849075,
      0.07612558454275131,
      -0.06608469784259796,
      -0.06410209089517593,
      -0.038752783089876175,
      0.08727586269378662,
      -0.030617160722613335,
      0.013033999130129814,
      0.03370353579521179,
      0.055151570588350296,
      -0.028403913602232933,
      -0.009687956422567368,
      0.03636125475168228,
      -0.040518615394830704,
      0.054922331124544144,
      0.04682497680187225,
      0.08286944031715393,
      0.05887182429432869,
      -0.005349884741008282,
      0.07615554332733154,
      -0.03807377815246582,
      0.004331833217293024,
      -0.03974613919854164,
      0.03921027109026909,
      0.08961068838834763,
      0.003380280453711748,
      -0.05431521311402321,
      0.01229289174079895,
      0.027063468471169472,
      -0.0821085050702095,
      0.008853932842612267,
      -0.02413422055542469,
      0.013794981874525547,
      -0.07234672456979752,
      -0.10413254797458649,
      -0.026818977668881416,
      0.013006072491407394,
      0.024384072050452232,
      -0.009346935898065567,
      -0.05751962214708328,
      -0.07452362030744553,
      -0.05097736418247223,
      0.03683342784643173,
      0.018474534153938293,
      -0.05638137832283974,
      0.0828201100230217,
      -0.03413531184196472,
      -0.05219584330916405,
      -0.03909913823008537,
      0.021850377321243286,
      -0.026664085686206818,
      -0.04899773746728897,
      0.012534156441688538,
      0.03522782400250435,
      0.014601370319724083,
      0.03206455335021019,
      -0.04230354353785515,
      0.06273473054170609,
      0.0014405051479116082,
      -0.025934670120477676,
      0.06343313306570053,
      0.0721772313117981,
      -0.020677637308835983,
      0.004136744886636734,
      0.0401824526488781,
      0.04401451349258423,
      0.03966141119599342,
      -0.013194303959608078,
      0.0013476561289280653,
      -0.058351073414087296,
      -0.007048849016427994,
      0.025141123682260513,
      -0.01799372024834156,
      -0.011078936979174614,
      0.010708019137382507,
      0.05251413211226463,
      0.09572811424732208,
      -0.05034118518233299,
      -0.04486924037337303,
      0.0003320731339044869,
      0.020336342975497246,
      -0.057711414992809296,
      0.02833990566432476,
      0.07898540049791336,
      -0.05873320251703262,
      0.05315667763352394,
      0.023376917466521263,
      -0.03744283318519592,
      0.016885504126548767,
      0.07474774867296219,
      0.08349086344242096,
      -0.0253877155482769,
      0.018163863569498062,
      0.0025559684727340937,
      0.01420030277222395,
      0.02597736194729805,
      0.04794187843799591,
      0.04357880726456642,
      -0.05820806697010994,
      0.08567477762699127,
      0.026797957718372345,
      0.00397730665281415,
      0.019671209156513214,
      -0.01923445425927639,
      0.017165709286928177,
      0.023200981318950653,
      0.029356300830841064,
      0.00629824586212635,
      0.0211277287453413,
      0.0023721533361822367,
      0.0053258719854056835,
      0.004960473161190748,
      0.051366645842790604,
      -0.054192088544368744,
      0.0007999152294360101,
      0.030252531170845032,
      0.04760364443063736,
      0.00911493506282568,
      0.07420092821121216,
      -0.022756321355700493,
      0.0509595163166523,
      -0.03343592956662178,
      -0.01901080831885338,
      -0.008232038468122482,
      0.05698227509856224,
      0.033172450959682465,
      0.009077131748199463,
      -0.041438955813646317,
      0.026762517169117928,
      0.06920960545539856,
      -0.018785713240504265,
      0.029541147872805595,
      -0.021973682567477226,
      -0.04986085742712021,
      -0.01648641750216484,
      0.010937255807220936,
      -0.042406536638736725,
      -0.016153154894709587,
      0.0012025348842144012,
      0.038160569965839386,
      -0.06894148141145706,
      -0.015385140664875507,
      -0.008871088735759258,
      0.08225028961896896,
      0.019374970346689224,
      -0.05161083862185478,
      -0.015471394173800945,
      0.008546696044504642,
      -0.023309890180826187,
      0.08941339701414108,
      -0.03760838881134987,
      -0.016147753223776817,
      0.012928025797009468,
      0.08266354352235794,
      0.05074017494916916,
      0.03883349150419235,
      0.03491158038377762,
      0.06344258785247803,
      0.02400840073823929,
      -0.018174882978200912,
      -0.03994063660502434,
      -0.034117862582206726,
      -0.03881017491221428,
      0.01691841334104538,
      0.02825177274644375,
      -0.004357201512902975,
      0.061496857553720474,
      0.10534580051898956,
      0.06544946134090424,
      0.012015684507787228,
      -0.10524609684944153,
      0.03173895925283432,
      -0.029362380504608154,
      -0.061242904514074326,
      0.09484676271677017,
      -0.017846571281552315,
      -0.008976251818239689,
      -0.05553214251995087,
      -0.07866917550563812,
      0.02001827210187912,
      0.05709375441074371,
      0.05958418548107147,
      0.042158663272857666,
      0.006101517006754875,
      -0.007773136720061302,
      -0.038584813475608826,
      0.01856009103357792,
      -0.025848282501101494,
      -0.015029535628855228,
      0.003711204044520855,
      0.0013313682284206152,
      -0.01981734298169613,
      -0.03790711611509323,
      -0.044798530638217926,
      -0.01102015096694231,
      -0.05923304334282875,
      -0.024408351629972458,
      -0.06198039650917053,
      0.028969915583729744,
      0.05447772145271301,
      -0.029651803895831108,
      -0.006873022299259901,
      0.001370381098240614,
      -0.05027827247977257,
      -0.009153641760349274,
      0.033985596150159836,
      0.07233308255672455,
      -0.02421518974006176,
      -0.03294536471366882,
      0.04864125698804855,
      -0.07913782447576523,
      -0.0709957629442215,
      0.036348868161439896,
      0.010640211403369904,
      0.044732026755809784,
      -0.0358593575656414,
      -0.08072318136692047,
      -0.0158950574696064,
      -0.05860881507396698,
      -0.05751345679163933,
      0.023141127079725266,
      -0.020642951130867004,
      -0.04136456921696663,
      0.07034412026405334,
      -0.059880707412958145,
      0.030145205557346344,
      -0.0014351465506479144,
      0.00032914854818955064,
      0.029203202575445175,
      0.04250606894493103,
      -0.06039264053106308,
      0.1259707361459732,
      -0.007092044688761234,
      -0.038057565689086914,
      -0.09555371105670929,
      0.06621886044740677,
      0.01330568827688694,
      -0.028287477791309357,
      -0.016948584467172623,
      0.01686285436153412,
      0.03432862460613251,
      0.002491026883944869,
      -0.0008833646425046027,
      0.045732077211141586,
      0.026481224223971367,
      0.0372307226061821,
      0.004825670272111893,
      0.017231181263923645,
      -0.01891852170228958,
      -0.07672025263309479,
      -0.06910229474306107,
      0.05558279901742935,
      -0.06073734536767006,
      -0.03682379052042961,
      -0.02678312361240387,
      -0.04290904477238655,
      -0.0609496645629406,
      -0.008921836502850056,
      -0.03378972411155701,
      0.04726211726665497,
      -0.021743129938840866,
      0.013045329600572586,
      -0.020682750269770622,
      -0.03416008874773979,
      -0.015687907114624977,
      0.044862158596515656,
      0.08559215813875198,
      -0.02175237610936165,
      0.043829821050167084,
      -0.008300512097775936,
      0.01649603061378002,
      -0.009683836251497269,
      -0.033919934183359146,
      -0.05921557918190956,
      0.008974977768957615,
      0.010731859132647514,
      0.03195146843791008,
      0.049436308443546295,
      -0.062455397099256516,
      0.06817280501127243,
      0.03937313333153725,
      0.01602734439074993,
      -0.07562430202960968,
      0.08756043016910553,
      -0.018653014674782753,
      0.027980588376522064,
      0.03315060958266258,
      -0.04456732049584389,
      0.05206215754151344,
      0.017985764890909195,
      -0.10683441907167435,
      0.10944262892007828,
      -0.0010757161071524024,
      0.023731013759970665,
      -0.07229790836572647,
      -0.009087684564292431,
      0.028085142374038696,
      -0.08467378467321396,
      0.03771981969475746,
      -0.06807415932416916,
      -0.025659820064902306,
      0.01228912454098463,
      -0.14788320660591125,
      -0.02916719764471054,
      0.023932915180921555,
      0.024833623319864273,
      -0.03355053812265396,
      0.024847669526934624,
      -0.02774116024374962,
      -0.009741107933223248,
      0.016850106418132782,
      0.017968805506825447,
      0.029023967683315277,
      0.07661557197570801,
      -0.05521951615810394,
      0.03303312510251999,
      0.022857269272208214,
      -0.0014987607719376683,
      0.03456920385360718,
      0.039307475090026855,
      0.02079004794359207,
      0.05925317108631134,
      0.0322614423930645,
      0.03910699114203453,
      -0.012454434297978878,
      0.02244110219180584,
      0.017048461362719536,
      0.005983500275760889,
      0.0009800116531550884,
      0.05910052731633186,
      0.06472747027873993,
      -0.004012802615761757,
      -0.10479383170604706,
      -0.05844784900546074,
      0.023420315235853195,
      -0.07979456335306168,
      0.0017383802914991975,
      -0.010713738389313221,
      -0.005147494841367006,
      -0.017137601971626282,
      -0.0012617718894034624,
      -0.050135452300310135,
      -0.022230645641684532,
      0.0030314219184219837,
      0.020022623240947723,
      0.025804441422224045,
      0.03418443724513054,
      -0.026611758396029472,
      -0.02838904783129692
    ],
    [
      0.023109925910830498,
      -0.02243012562394142,
      -0.09431376308202744,
      0.017413804307579994,
      0.010536094196140766,
      0.04478779807686806,
      -0.012961464002728462,
      0.04416564479470253,
      -0.0014585732715204358,
      -0.04846348986029625,
      -0.0439327247440815,
      -0.032162465155124664,
      -0.02103465050458908,
      -0.014715074561536312,
      -0.03175891190767288,
      0.0030762595124542713,
      0.013584226369857788,
      0.04474690929055214,
      0.014160755090415478,
      0.07782444357872009,
      -0.05404115840792656,
      0.04374364763498306,
      0.05504531413316727,
      -0.06564553081989288,
      0.0543709434568882,
      -0.02095220796763897,
      0.0381627231836319,
      0.006168554536998272,
      0.03498702868819237,
      -0.01306274812668562,
      -0.06862092763185501,
      0.054704949259757996,
      0.04110557213425636,
      0.0018053832463920116,
      -0.034166667610406876,
      0.050494223833084106,
      0.005476734600961208,
      0.02390681393444538,
      0.08731742948293686,
      0.04637064039707184,
      -0.0058782524429261684,
      0.09833402931690216,
      -0.00020760804181918502,
      0.03853913024067879,
      -0.06291535496711731,
      -0.03655582666397095,
      0.023781893774867058,
      0.030378954485058784,
      0.0424225814640522,
      -0.007467865012586117,
      -0.007117604371160269,
      0.042497873306274414,
      0.014698930084705353,
      -0.020389234647154808,
      -0.012813657522201538,
      0.076896533370018,
      -0.026298057287931442,
      -0.05859580636024475,
      0.05707092583179474,
      0.007560344412922859,
      0.03415171802043915,
      0.01923690363764763,
      -0.02386508136987686,
      -0.035545285791158676,
      0.08077576011419296,
      0.020074350759387016,
      -0.048097189515829086,
      -0.0013618696248158813,
      -0.022516928613185883,
      -0.07903103530406952,
      -0.015160397626459599,
      0.07192808389663696,
      -0.017169291153550148,
      0.048075299710035324,
      0.057237476110458374,
      -0.1016908511519432,
      0.04808660224080086,
      0.059316135942935944,
      0.010134207084774971,
      0.028539003804326057,
      -0.009576587937772274,
      0.02333120070397854,
      0.014050000347197056,
      -0.02432549186050892,
      -0.02356092259287834,
      0.03414885699748993,
      0.01024860329926014,
      -0.09149983525276184,
      -0.01139993965625763,
      0.041038017719984055,
      0.014575482346117496,
      -0.008600817061960697,
      -0.0284343883395195,
      -0.014897031709551811,
      0.03180062398314476,
      -0.005851482041180134,
      0.01703898049890995,
      -0.007237430661916733,
      0.031081991270184517,
      0.04821474850177765,
      0.04271728917956352,
      -0.06791920959949493,
      -0.04603850841522217,
      -0.05959784612059593,
      -0.011804956942796707,
      0.008123626001179218,
      0.043038517236709595,
      -0.041028521955013275,
      -0.02359684556722641,
      -0.026033177971839905,
      0.010448561981320381,
      -0.12841729819774628,
      0.04440934956073761,
      0.007964837364852428,
      0.020905617624521255,
      0.04553971812129021,
      0.06702965497970581,
      0.05369380861520767,
      -0.018065907061100006,
      0.061507001519203186,
      0.06861767917871475,
      -0.01560808252543211,
      -0.06720922887325287,
      -0.036193639039993286,
      -0.037902310490608215,
      0.0554514154791832,
      0.07736567407846451,
      -0.03299945965409279,
      0.002020881976932287,
      0.009758619591593742,
      -0.025601772591471672,
      -0.001536547439172864,
      -0.010806362144649029,
      0.09475578367710114,
      -0.06375598162412643,
      0.02127814292907715,
      0.0067932805977761745,
      0.03236669674515724,
      0.02854657918214798,
      0.005619969684630632,
      0.012102271430194378,
      -0.006542098708450794,
      -0.04868055507540703,
      0.10295309871435165,
      0.021154804155230522,
      0.048374541103839874,
      0.056562695652246475,
      0.0019099373603239655,
      0.06659851968288422,
      0.12874749302864075,
      -0.045760080218315125,
      -0.08281221985816956,
      0.01718750409781933,
      -0.037314705550670624,
      0.08148615807294846,
      0.022682566195726395,
      -0.021572744473814964,
      -0.06831452250480652,
      -0.04555286467075348,
      0.03280339017510414,
      0.06817856431007385,
      0.019675543531775475,
      0.06627649068832397,
      0.01632126420736313,
      -0.06705537438392639,
      -0.001548089785501361,
      -0.056928299367427826,
      0.03241446986794472,
      -0.13768835365772247,
      -0.012127171270549297,
      -0.060866326093673706,
      -0.03025340661406517,
      0.034943126142024994,
      -0.05151478201150894,
      0.016634058207273483,
      -0.033860139548778534,
      0.04883730039000511,
      -0.03540925309062004,
      -0.05435991659760475,
      0.049214694648981094,
      -0.015014348551630974,
      -0.030282698571681976,
      0.05207230523228645,
      0.010908544063568115,
      0.021644581109285355,
      -0.00423728721216321,
      -0.002852030098438263,
      0.03705572336912155,
      -0.04231636971235275,
      0.11480946093797684,
      -0.014515130780637264,
      -0.00497331703081727,
      0.03860921412706375,
      0.015325169079005718,
      -0.10744658857584,
      -0.004240349400788546,
      0.01934322528541088,
      0.07899321615695953,
      -0.06357773393392563,
      0.06653053313493729,
      -0.030452532693743706,
      0.014593016356229782,
      0.04618444666266441,
      0.0004814519197680056,
      -0.038624245673418045,
      -0.0039935121312737465,
      0.12378925085067749,
      -0.0322645902633667,
      0.05970672890543938,
      0.005784239619970322,
      -0.001480540493503213,
      -0.01481618732213974,
      0.073764868080616,
      -0.0074462853372097015,
      -0.018960054963827133,
      0.10272301733493805,
      -0.09751563519239426,
      0.034171245992183685,
      -0.0635111927986145,
      0.05086596682667732,
      -0.054287925362586975,
      -0.013479032553732395,
      -0.06001727283000946,
      0.039484601467847824,
      0.050569579005241394,
      -0.04236838221549988,
      -0.06272715330123901,
      0.08903922885656357,
      0.09386785328388214,
      -0.09548814594745636,
      0.06976605951786041,
      -0.07192857563495636,
      0.0497358962893486,
      0.022273408249020576,
      0.11596491187810898,
      -0.02315158024430275,
      0.03502051532268524,
      -0.00040644966065883636,
      -0.04190012440085411,
      0.02832462079823017,
      0.03332289308309555,
      -0.002091025933623314,
      -0.021718163043260574,
      0.05468028783798218,
      -0.0032025997061282396,
      0.026506682857871056,
      -0.007095266133546829,
      -0.0004606005095411092,
      -0.05500143766403198,
      -0.04019370675086975,
      0.0449637696146965,
      -0.05023647099733353,
      0.0015967750223353505,
      0.042740434408187866,
      0.07119538635015488,
      0.08561877906322479,
      0.04210566729307175,
      0.011172228492796421,
      0.04626531898975372,
      0.002539203269407153,
      -0.03317669406533241,
      0.07178200781345367,
      -0.00868255365639925,
      -0.05017582327127457,
      0.00982590764760971,
      -0.072020024061203,
      0.014802498742938042,
      -0.018408924341201782,
      0.028121070936322212,
      0.0003167819231748581,
      -0.02506989799439907,
      0.020528072491288185,
      -0.0721915140748024,
      -0.019006967544555664,
      -0.06268932670354843,
      0.03156096488237381,
      0.06293657422065735,
      -0.013196376152336597,
      0.005409700330346823,
      -0.019908910617232323,
      -0.05252063646912575,
      0.025414980947971344,
      0.019379636272788048,
      0.06306417286396027,
      0.01066235825419426,
      0.05316019803285599,
      0.0038685740437358618,
      -0.0026422704104334116,
      0.024396631866693497,
      -0.012287726625800133,
      -0.06285141408443451,
      0.04049034044146538,
      -0.009391755796968937,
      -0.045666519552469254,
      -0.023723507300019264,
      0.03942181542515755,
      0.08268877863883972,
      -0.08248496055603027,
      -0.008316169492900372,
      0.0013726049801334739,
      0.01598670333623886,
      -0.017616083845496178,
      -0.00044182021520100534,
      -0.07257884740829468,
      0.0635836198925972,
      -0.06703997403383255,
      0.008638340048491955,
      -0.047127969563007355,
      0.06585273891687393,
      -0.044855955988168716,
      0.009573962539434433,
      0.009802491404116154,
      -0.020010018721222878,
      0.00177167565561831,
      -0.1418161243200302,
      -0.03688619285821915,
      -0.0633942112326622,
      -0.012521889060735703,
      -0.01858549378812313,
      -0.10288872569799423,
      0.055374566465616226,
      0.05613198131322861,
      0.02207145094871521,
      0.019553424790501595,
      -0.054537612944841385,
      0.025845270603895187,
      -7.37076552468352e-05,
      -0.0941975936293602,
      -0.011442561633884907,
      0.057925838977098465,
      0.0006372260395437479,
      -0.014612515456974506,
      0.10568097233772278,
      0.02219117246568203,
      -0.03667733445763588,
      -0.0061861504800617695,
      0.0019450809340924025,
      -0.020352952182292938,
      0.04381762817502022,
      -0.04255101457238197,
      0.035721711814403534,
      0.04442207142710686,
      -0.021679244935512543,
      0.012089597061276436,
      -0.11135496944189072,
      -0.10828472673892975,
      0.039222076535224915,
      0.05872783809900284,
      0.034297749400138855,
      0.02916831523180008,
      -0.01721157878637314,
      -0.0019606021232903004,
      0.011776152066886425,
      -0.009676034562289715,
      0.005822430830448866,
      -0.05565374717116356,
      -0.017565302550792694,
      -0.032039541751146317,
      -0.006250701379030943,
      -0.03222867473959923,
      -0.031113475561141968,
      0.023577142506837845,
      0.06209152191877365,
      0.0570017546415329,
      -0.017611660063266754,
      -0.01004407275468111,
      0.006239514332264662,
      0.0199827179312706,
      0.06802207976579666,
      -0.0019864444620907307,
      -0.05427194759249687,
      -0.09051407128572464,
      0.04651607945561409,
      -0.013368037529289722,
      0.02212858386337757,
      -0.039123378694057465,
      0.05711158737540245,
      0.03463388979434967,
      0.013581128790974617,
      0.07039568573236465,
      -0.031798459589481354,
      0.11936981976032257,
      0.026287131011486053,
      0.05553967505693436,
      0.027121946215629578,
      -0.0038382746279239655,
      -0.08753643929958344,
      -0.027866406366229057,
      -0.021336982026696205,
      0.05706276372075081,
      0.08842666447162628,
      0.056537844240665436,
      -0.0567818246781826,
      0.05112549662590027,
      0.011397589929401875,
      0.029846813529729843,
      0.00945032387971878,
      0.06381719559431076,
      0.015320146456360817,
      0.040077079087495804,
      -0.06593465059995651,
      0.0018389089964330196,
      0.00536741130053997,
      0.08381384611129761,
      0.04100238159298897,
      0.024962911382317543,
      -0.0036176687572151423,
      0.004370266571640968,
      0.029376715421676636,
      -0.019846433773636818,
      0.022891294211149216,
      -0.07537682354450226,
      0.04109158739447594,
      -0.05143845081329346,
      0.03566822037100792,
      -0.016062932088971138,
      -0.006401675287634134,
      0.096380315721035,
      0.07986953109502792,
      0.014997531659901142,
      0.08364535123109818,
      -0.008467772044241428,
      -0.00353850401006639,
      -0.02129649929702282,
      0.03731580078601837,
      -0.05458420142531395,
      -0.021815454587340355,
      -0.0033336554188281298,
      0.002505557844415307,
      0.002644710708409548,
      0.013819975778460503,
      0.010881688445806503,
      -0.03122047334909439,
      0.011905446648597717,
      0.03212437778711319,
      0.04908369109034538,
      0.04639307036995888,
      -0.018401699140667915,
      -0.0824558436870575,
      -0.07390469312667847,
      0.021838409826159477,
      0.06544358283281326,
      0.03957005590200424,
      -0.005746208596974611,
      0.042183052748441696,
      -0.01375796552747488,
      0.034487102180719376,
      0.03557600453495979,
      0.027776803821325302,
      0.04084634780883789,
      0.022266581654548645,
      -0.07647585868835449,
      0.013651140034198761,
      -0.052686333656311035,
      -0.0472024604678154,
      -0.027998557314276695,
      -0.002866155467927456,
      0.03554780036211014,
      -0.05927343666553497,
      0.003581149270758033,
      -0.0015046487096697092,
      -0.038288507610559464,
      0.02586306817829609,
      0.021251143887639046,
      -0.0905381292104721,
      -0.03208522871136665,
      0.050539419054985046,
      7.437844760715961e-05,
      -0.046514276415109634,
      0.027259966358542442,
      -0.017872871831059456,
      -0.030764011666178703,
      -0.01579694077372551,
      -0.04011852294206619,
      0.0287613682448864,
      -0.012469363398849964,
      0.01974528841674328,
      0.027755586430430412,
      -0.008154269307851791,
      0.020667435601353645,
      0.019442060962319374,
      -0.08202651143074036,
      0.07022321969270706,
      -0.006267987657338381,
      -0.0043764421716332436,
      -0.021193383261561394,
      0.03722298517823219,
      -0.02481667883694172,
      -0.020258620381355286,
      0.0066479784436523914,
      -0.06303871423006058,
      -0.05177316442131996,
      -0.028315972536802292,
      -0.043890200555324554,
      -0.063450887799263,
      -0.031119659543037415,
      -0.04162467271089554,
      0.005734538659453392,
      -0.08395975083112717,
      0.028351468965411186,
      -0.024119747802615166,
      0.08058639615774155,
      -0.04195549711585045
    ],
    [
      -0.07478348910808563,
      0.002857458544895053,
      -0.002821299945935607,
      0.08980290591716766,
      0.002556158695369959,
      0.0065777720883488655,
      -0.04557163640856743,
      -0.13326478004455566,
      -0.011859983205795288,
      -0.011813283897936344,
      0.011273864656686783,
      -0.003685413859784603,
      0.02729586511850357,
      0.027981599792838097,
      0.009425886906683445,
      0.02662711590528488,
      -0.047792885452508926,
      0.003997619263827801,
      0.07904911041259766,
      -0.09301912784576416,
      -0.06640500575304031,
      -0.03808015212416649,
      0.00240096403285861,
      0.029888823628425598,
      0.078955739736557,
      -0.017616014927625656,
      0.13754300773143768,
      0.01942424476146698,
      -0.04915507882833481,
      -0.0050681233406066895,
      -0.04025258868932724,
      0.06049012765288353,
      -0.035233959555625916,
      0.01467717532068491,
      0.050225917249917984,
      -0.0015306028071790934,
      -0.007347054313868284,
      -0.028262555599212646,
      0.028824862092733383,
      0.022682344540953636,
      -0.04624437540769577,
      -0.015736926347017288,
      -0.06181196868419647,
      -0.02620091661810875,
      0.029759280383586884,
      -0.0477999784052372,
      -0.0023165640886873007,
      0.020350782200694084,
      0.003241322934627533,
      -0.007553107105195522,
      -0.0166629645973444,
      0.000829979544505477,
      0.006239644717425108,
      -0.03628678247332573,
      -0.023814473301172256,
      0.08796433359384537,
      0.04576782509684563,
      -0.012203500606119633,
      0.015445194207131863,
      -0.039233557879924774,
      -0.05545266717672348,
      0.006258824840188026,
      -0.08067820966243744,
      -0.004697816446423531,
      -0.0031774945091456175,
      -0.03824542462825775,
      0.06993037462234497,
      -0.08732334524393082,
      0.03654917702078819,
      -0.09239102900028229,
      0.010534384287893772,
      -0.056098103523254395,
      -0.02552011050283909,
      -0.03059736266732216,
      0.012776924297213554,
      -0.020316585898399353,
      -0.04757460951805115,
      0.05858459323644638,
      0.06409239023923874,
      0.007686902303248644,
      0.03356533497571945,
      0.029911236837506294,
      -0.01446519885212183,
      0.05778059363365173,
      0.05775135010480881,
      -0.06781966984272003,
      0.08075591921806335,
      -0.03461621329188347,
      -0.03300770744681358,
      0.035173200070858,
      -0.0769202783703804,
      -0.01915137469768524,
      0.07111013680696487,
      -0.011739294975996017,
      0.015495643950998783,
      -0.010763785801827908,
      -0.04421815648674965,
      -0.013821840286254883,
      0.009971140883862972,
      0.0112448800355196,
      0.011215431615710258,
      0.02306315489113331,
      -0.04813043773174286,
      -0.042102962732315063,
      -0.05780203267931938,
      -0.02381735108792782,
      0.010156217031180859,
      -0.07581950724124908,
      0.038666021078825,
      -0.04552173987030983,
      0.015639619901776314,
      -0.03169330954551697,
      -0.04190584272146225,
      -0.04719305410981178,
      -0.003578769275918603,
      0.001984048867598176,
      -0.015087665058672428,
      0.11301891505718231,
      0.037489794194698334,
      -0.01184816099703312,
      -0.05784241110086441,
      0.004323041997849941,
      -0.06328354775905609,
      0.044461414217948914,
      0.02722836658358574,
      -0.11030263453722,
      0.007440998684614897,
      0.052180033177137375,
      -0.026596911251544952,
      0.0439085029065609,
      -0.016732655465602875,
      -0.022408226504921913,
      -0.03412224352359772,
      -0.007131060119718313,
      -0.012352083809673786,
      0.052707936614751816,
      0.0804370790719986,
      0.05369982868432999,
      -0.031193196773529053,
      -0.06740099936723709,
      0.04534795507788658,
      0.009175850078463554,
      0.06971405446529388,
      0.0004328089707996696,
      0.018952859565615654,
      -0.0201491117477417,
      -0.01821034587919712,
      -0.047018345445394516,
      -0.040930118411779404,
      0.03198244050145149,
      -0.0038948492147028446,
      0.02754317596554756,
      -0.007018410600721836,
      -0.06030207872390747,
      -0.06174207106232643,
      -0.012432996183633804,
      -0.01321717631071806,
      0.0005053269560448825,
      0.006185011472553015,
      -0.03357015922665596,
      0.045776933431625366,
      0.007425094489008188,
      0.031770966947078705,
      0.015512453392148018,
      -0.004815757740288973,
      0.03953396528959274,
      -0.0937865599989891,
      -0.02032737247645855,
      0.014992336742579937,
      0.015010141767561436,
      0.00584300234913826,
      0.02696961537003517,
      -0.010613102465867996,
      -0.015252167358994484,
      -0.0742463693022728,
      -0.01644699089229107,
      0.05717307701706886,
      0.10061391443014145,
      0.001020266325213015,
      -0.005890135653316975,
      -0.0600816011428833,
      -0.012979887425899506,
      0.002397227566689253,
      -0.05746787413954735,
      -0.02284262329339981,
      -0.08120741695165634,
      -0.006814774591475725,
      -0.062428612262010574,
      0.044724658131599426,
      -0.03968818113207817,
      0.0072518279775977135,
      0.07286005467176437,
      -0.005747073329985142,
      0.047298613935709,
      -0.09179961681365967,
      0.022044792771339417,
      -0.001549753942526877,
      0.021175121888518333,
      0.018905650824308395,
      -0.04050813987851143,
      -0.0034437866415828466,
      -0.0425521656870842,
      -0.12389317899942398,
      -0.03244712948799133,
      -0.008837353438138962,
      0.09313724935054779,
      0.059961892664432526,
      0.055224306881427765,
      0.06336881220340729,
      0.023045536130666733,
      -0.02179005555808544,
      0.07816494256258011,
      -0.010782401077449322,
      -0.003674838226288557,
      -0.04628603905439377,
      -0.0057848854921758175,
      -0.045443467795848846,
      -0.021317902952432632,
      -0.0545264407992363,
      -0.014349215663969517,
      0.06734172254800797,
      0.04076949134469032,
      -0.04753861203789711,
      -0.01734437420964241,
      -0.022901330143213272,
      0.05290482938289642,
      -0.029823940247297287,
      0.08470460027456284,
      0.010024403221905231,
      -0.024093927815556526,
      0.0051147351041436195,
      -0.0580238476395607,
      -0.0679929107427597,
      0.09295044094324112,
      -0.026675380766391754,
      0.01969212107360363,
      -0.07762996107339859,
      0.033088766038417816,
      -0.026404961943626404,
      0.03223486617207527,
      0.0075231315568089485,
      -0.007030443754047155,
      -0.05520126596093178,
      -0.03922441974282265,
      0.05153104290366173,
      -0.007387195713818073,
      0.015142683871090412,
      -0.06102019548416138,
      0.007599181029945612,
      0.031273968517780304,
      -0.0716887041926384,
      -0.0024371163453906775,
      0.031559720635414124,
      0.0174273494631052,
      -0.04453970864415169,
      0.009184306487441063,
      0.07010696828365326,
      -0.08488766103982925,
      0.03553428500890732,
      0.00647618155926466,
      0.010694203898310661,
      0.03886289522051811,
      -0.0252293162047863,
      0.06650222092866898,
      -0.020202379673719406,
      0.014797894284129143,
      -0.07118330150842667,
      0.030213141813874245,
      0.020961083471775055,
      0.020161302760243416,
      0.010746840387582779,
      -0.004235291853547096,
      -0.07332193106412888,
      0.00815101433545351,
      0.008701774291694164,
      0.09344073385000229,
      0.025198353454470634,
      -0.023821961134672165,
      -0.003658160800114274,
      0.008800109848380089,
      0.054957132786512375,
      -0.003407335840165615,
      -0.013256681151688099,
      0.046801090240478516,
      -0.06915844231843948,
      0.014753229916095734,
      -0.005838930141180754,
      -0.04190174490213394,
      -0.037876397371292114,
      0.12554113566875458,
      0.033491745591163635,
      0.015842698514461517,
      -0.04209922254085541,
      -0.06110915169119835,
      -0.023597590625286102,
      -0.03653666749596596,
      -0.0027076040860265493,
      0.04470207914710045,
      -0.004266785923391581,
      -0.014533049426972866,
      -0.0047828094102442265,
      -0.023127293214201927,
      -0.10275013744831085,
      -0.042694661766290665,
      0.017422117292881012,
      0.048338551074266434,
      -0.060986313968896866,
      0.02785402722656727,
      -0.06953425705432892,
      0.012247598730027676,
      0.002500386442989111,
      -0.0019416409777477384,
      0.04918217286467552,
      0.010118874721229076,
      -0.00951781403273344,
      -0.02874615229666233,
      0.03407341614365578,
      -0.01064413320273161,
      -0.040605805814266205,
      -0.003458700142800808,
      0.024596843868494034,
      -0.1051490381360054,
      0.035374902188777924,
      0.030543608590960503,
      -0.02692599967122078,
      -0.0335296131670475,
      0.06135420873761177,
      0.025152942165732384,
      -0.05059150233864784,
      0.03587973117828369,
      -0.019218921661376953,
      -0.03405311331152916,
      -0.014670328237116337,
      -0.040353864431381226,
      0.027990015223622322,
      0.022642074152827263,
      -0.019897421821951866,
      0.06634095311164856,
      0.0657101571559906,
      -0.029639553278684616,
      -0.01740599237382412,
      0.018087517470121384,
      0.02307249791920185,
      -0.05789729207754135,
      -0.030301610007882118,
      -0.050956085324287415,
      0.018776243552565575,
      -0.040969036519527435,
      -0.05870445817708969,
      -0.0480751097202301,
      0.027385910972952843,
      -0.028089893981814384,
      0.045500289648771286,
      0.03756488859653473,
      -0.07353483140468597,
      0.004978794604539871,
      -0.022835036739706993,
      -0.003558115568011999,
      0.10475944727659225,
      0.03711618110537529,
      0.00612509623169899,
      -0.020573468878865242,
      0.02110970951616764,
      0.03564238175749779,
      0.06222643330693245,
      -0.019137082621455193,
      0.06554527580738068,
      0.02767549641430378,
      -0.012504872865974903,
      -0.025030961260199547,
      0.011179734021425247,
      0.07048346102237701,
      -0.006923894863575697,
      0.05531391128897667,
      0.03401203826069832,
      -0.07060664147138596,
      0.0032055573537945747,
      -0.0013645583530887961,
      0.019317708909511566,
      0.0008316939347423613,
      -0.011856806464493275,
      -0.056056998670101166,
      0.005374897737056017,
      -0.01279748510569334,
      -0.032518066465854645,
      0.022781796753406525,
      0.001051337574608624,
      0.003268314292654395,
      -0.06708220392465591,
      0.011938532814383507,
      -0.046270422637462616,
      0.02269803173840046,
      0.011623067781329155,
      -0.02936657890677452,
      0.08246096968650818,
      0.012865464203059673,
      -0.05791657418012619,
      -0.000452539068646729,
      -0.00830397754907608,
      0.09760211408138275,
      0.06304983049631119,
      0.03396562114357948,
      -0.024942420423030853,
      -0.08278466016054153,
      0.006946641951799393,
      0.01495488453656435,
      0.009377777576446533,
      0.09141763299703598,
      -0.02488359995186329,
      -0.0037524134386330843,
      0.0878632590174675,
      0.016816498711705208,
      0.020691847428679466,
      0.005939285270869732,
      0.04501323029398918,
      -0.004475409165024757,
      -0.0074209910817444324,
      -0.030821330845355988,
      -0.047281235456466675,
      -0.01686444878578186,
      -0.05819486826658249,
      0.039230234920978546,
      -0.07218858599662781,
      -0.015826821327209473,
      0.053559791296720505,
      -0.009056933224201202,
      0.10181839019060135,
      -0.030904078856110573,
      -0.10025504231452942,
      0.11203201860189438,
      0.0060397181659936905,
      0.018213998526334763,
      -0.07849682867527008,
      0.013932159170508385,
      -0.023978540673851967,
      -0.0011528953909873962,
      0.021577967330813408,
      -0.009168163873255253,
      0.023048564791679382,
      0.0726456418633461,
      -0.007805706933140755,
      0.07657092064619064,
      0.0646674707531929,
      0.05213345214724541,
      0.016178281977772713,
      0.04226316511631012,
      -0.04512350633740425,
      -0.005276289768517017,
      -0.039118506014347076,
      -0.0045279692858457565,
      0.03506070375442505,
      0.005386381410062313,
      0.07924975454807281,
      -0.02566824108362198,
      -0.016469407826662064,
      0.010187670588493347,
      0.014532026834785938,
      -0.06412046402692795,
      0.0016807096544653177,
      -0.07187078893184662,
      0.04706604778766632,
      -0.019347021356225014,
      0.06548278778791428,
      0.0018433139193803072,
      -0.046509068459272385,
      0.025562915951013565,
      -0.023889821022748947,
      0.019707778468728065,
      0.04543284699320793,
      -0.024755191057920456,
      -0.011989042162895203,
      0.03350144624710083,
      0.006536707282066345,
      0.02513214945793152,
      0.03544185310602188,
      -0.045074742287397385,
      0.03703084588050842,
      0.021319113671779633,
      0.05719627067446709,
      0.028525542467832565,
      -0.007123156450688839,
      0.08187573403120041,
      -0.03379474952816963,
      0.017480911687016487,
      -0.04778817668557167,
      -0.04345649480819702,
      0.009327067993581295,
      0.04137258231639862,
      -0.1374015212059021,
      0.04107667878270149,
      -0.020263569429516792,
      0.0061819907277822495,
      -0.06043477728962898,
      0.044700950384140015,
      -0.03047429956495762,
      -0.01664992980659008,
      0.06777068227529526,
      0.034222327172756195,
      0.013960162177681923,
      -0.033549562096595764,
      -0.0552501380443573,
      -0.017656909301877022,
      0.0014896239154040813
    ],
    [
      0.020117558538913727,
      -0.07223489135503769,
      -0.02009662427008152,
      0.06905727833509445,
      -0.05005341395735741,
      0.02304062619805336,
      0.011275248602032661,
      -0.030721651390194893,
      0.025410113856196404,
      -0.024051282554864883,
      -0.0010927146067842841,
      -0.021688003093004227,
      -0.02983146160840988,
      0.09473106265068054,
      -0.10180448740720749,
      -0.027090078219771385,
      -0.012351828627288342,
      0.010600280947983265,
      -0.03121125139296055,
      0.005898261908441782,
      -0.025611577555537224,
      -0.05795389041304588,
      -0.005813580937683582,
      -0.04928791522979736,
      -0.006852460093796253,
      0.015135341323912144,
      -0.053723134100437164,
      0.04483688995242119,
      0.030751148238778114,
      0.002795653883367777,
      -0.06253095716238022,
      -0.0796513631939888,
      0.03522425889968872,
      0.024961082264780998,
      0.031817030161619186,
      -0.03008880466222763,
      -0.0036184871569275856,
      0.10909532755613327,
      -0.05676211416721344,
      0.0029586448799818754,
      0.009597384370863438,
      -0.028182271867990494,
      0.09359607100486755,
      -0.1044619008898735,
      0.02913426235318184,
      0.023867936804890633,
      0.03195197507739067,
      0.0028741091955453157,
      0.03641867637634277,
      0.0061643049120903015,
      0.06503920257091522,
      -0.08565286546945572,
      -0.02119889296591282,
      -0.02518174611032009,
      -0.09906520694494247,
      0.025183450430631638,
      -0.005786772817373276,
      0.00011651086242636666,
      0.02305595576763153,
      0.041392795741558075,
      0.004138017073273659,
      -0.0899447351694107,
      0.07420369982719421,
      -0.027458112686872482,
      -0.002076217671856284,
      0.0009456126135773957,
      -0.006620209664106369,
      -0.047444406896829605,
      -0.004526395816355944,
      0.014020645059645176,
      -0.0488017238676548,
      -0.14543147385120392,
      -0.002323467517271638,
      -0.02675902284681797,
      0.06805254518985748,
      -0.01059907115995884,
      0.012975037097930908,
      0.0032441660296171904,
      0.06019573286175728,
      0.005290082655847073,
      0.00014616995758842677,
      0.037371449172496796,
      0.05715658888220787,
      0.06477893888950348,
      0.06894776225090027,
      0.006897256709635258,
      -0.0012686171103268862,
      0.068146251142025,
      -0.09394924342632294,
      -0.05022946372628212,
      -0.013646213337779045,
      -0.01402993779629469,
      0.03394965082406998,
      0.0242479108273983,
      0.0033686512615531683,
      -0.03728253394365311,
      -0.04903462901711464,
      0.014695066958665848,
      -0.043244220316410065,
      -0.03976886346936226,
      0.00816270150244236,
      0.028948243707418442,
      -0.01505336258560419,
      -0.05117727443575859,
      0.035672783851623535,
      0.02426104061305523,
      -0.020284079015254974,
      0.014586744830012321,
      0.05939384177327156,
      0.024577870965003967,
      -0.028420355170965195,
      0.031114280223846436,
      0.045229118317365646,
      0.05554267019033432,
      -0.02258322387933731,
      -0.05676506459712982,
      0.04160969704389572,
      0.015718866139650345,
      0.039861973375082016,
      -0.004574227146804333,
      -0.12290620803833008,
      0.012524477206170559,
      0.03769681975245476,
      0.055138010531663895,
      0.0744967833161354,
      0.0020727163646370173,
      -0.054694075137376785,
      -0.058120451867580414,
      0.02370522916316986,
      0.08506636321544647,
      -0.04712614417076111,
      -0.009852170012891293,
      0.01711542159318924,
      0.0218568816781044,
      -0.006692052353173494,
      0.014686175622045994,
      -0.04803036153316498,
      -0.0068085831589996815,
      -0.055089909583330154,
      0.07812725752592087,
      0.02916068211197853,
      0.021543661132454872,
      -0.010816915892064571,
      0.03421905264258385,
      -0.0023519888054579496,
      0.027335450053215027,
      0.0005706102238036692,
      -0.031979452818632126,
      0.028358740732073784,
      -0.018860990181565285,
      0.0187660101801157,
      0.007077336777001619,
      0.042306557297706604,
      0.0081344498321414,
      0.01885877549648285,
      -0.027159791439771652,
      -0.044875387102365494,
      -0.024892326444387436,
      0.026400867849588394,
      0.04980812594294548,
      -0.03850061073899269,
      0.0347382053732872,
      -0.03814670071005821,
      -0.05724843963980675,
      0.03394593298435211,
      -0.03303610906004906,
      -0.0025897794403135777,
      0.03455539047718048,
      0.018973147496581078,
      0.0058860029093921185,
      -0.061556171625852585,
      0.04688179865479469,
      0.04606586694717407,
      -0.006224967539310455,
      0.034784503281116486,
      0.02152000367641449,
      0.04270187020301819,
      0.004027398768812418,
      0.06487833708524704,
      0.03067370131611824,
      0.01390819065272808,
      -0.05038304626941681,
      -0.08953604847192764,
      0.014938238076865673,
      0.0025264553260058165,
      -0.08411647379398346,
      0.05416487529873848,
      -0.01225525513291359,
      -0.011736362241208553,
      0.005724834278225899,
      -0.09107010066509247,
      -0.02635568007826805,
      0.008317762054502964,
      0.1665855348110199,
      -0.09819919615983963,
      0.02334284409880638,
      0.015070858411490917,
      -0.03481755778193474,
      -0.028247885406017303,
      0.08649922162294388,
      -0.010490900836884975,
      -0.025934789329767227,
      0.04076061025261879,
      0.06637072563171387,
      -0.005607851315289736,
      -0.03135596588253975,
      0.011800128035247326,
      0.026029326021671295,
      0.015628881752490997,
      0.013987269252538681,
      0.06953513622283936,
      0.07763408124446869,
      -0.026890384033322334,
      0.021148543804883957,
      -0.07903003692626953,
      -0.012737990356981754,
      -0.06446745246648788,
      0.03574228659272194,
      -0.0013043081853538752,
      0.09121403098106384,
      0.03481981158256531,
      -0.005074492190033197,
      -0.019531095400452614,
      -0.04039609432220459,
      0.060688167810440063,
      0.02389959804713726,
      -0.07114902138710022,
      -0.011712184175848961,
      0.02967662364244461,
      -0.059092748910188675,
      0.009090007282793522,
      -0.050915732979774475,
      -0.0805368572473526,
      0.056787677109241486,
      0.06180274486541748,
      -0.05595667287707329,
      -0.024227378889918327,
      -0.07267780601978302,
      -0.02104301005601883,
      -0.046473901718854904,
      0.02652445249259472,
      -0.010617690160870552,
      -0.02610933594405651,
      -0.04570222273468971,
      -0.017864087596535683,
      -0.001998110441491008,
      0.035608626902103424,
      0.023956261575222015,
      -0.010776436887681484,
      0.008132250048220158,
      0.0516979917883873,
      -0.025837017223238945,
      0.018515082076191902,
      0.03057900071144104,
      0.0035023491363972425,
      -0.0397234745323658,
      -0.039181217551231384,
      -0.043808501213788986,
      0.03973476588726044,
      -0.0013597822980955243,
      0.016383225098252296,
      -0.03679552674293518,
      -0.050495196133852005,
      0.00711482809856534,
      -0.060882553458213806,
      0.007363054435700178,
      0.07160039991140366,
      -0.00542609766125679,
      0.041280597448349,
      -0.006089517846703529,
      0.06826832890510559,
      0.02900673821568489,
      -0.02799426205456257,
      -0.015250877477228642,
      -0.03837316483259201,
      0.04560084268450737,
      0.037077344954013824,
      -0.008177133277058601,
      -0.10063217580318451,
      -0.022506272420287132,
      -0.02647571451961994,
      0.0034459989983588457,
      -0.0054199364967644215,
      -0.0012282434618100524,
      -0.07790505141019821,
      -0.0038141056429594755,
      0.03943851217627525,
      -0.034955017268657684,
      0.011877703480422497,
      -0.04004994407296181,
      -0.050722885876894,
      -0.023612897843122482,
      -0.04406123235821724,
      0.009650882333517075,
      0.0444490872323513,
      -0.08938685804605484,
      0.0018359950045123696,
      0.01024428941309452,
      -0.12326060980558395,
      -0.012474443763494492,
      -0.07909239083528519,
      0.00497135054320097,
      0.04647107422351837,
      -0.054225627332925797,
      -0.04271192476153374,
      0.050150465220212936,
      0.012217894196510315,
      0.037994034588336945,
      -0.023840012028813362,
      0.042242180556058884,
      -0.025412501767277718,
      -0.014616116881370544,
      0.014638222754001617,
      -0.028958335518836975,
      -0.005232584197074175,
      -0.028454096987843513,
      -0.011460857465863228,
      0.09270576387643814,
      0.03282969072461128,
      -0.08200830221176147,
      0.003182746469974518,
      -0.08196364343166351,
      -0.018966855481266975,
      -0.040885597467422485,
      -0.006571962498128414,
      0.04798131808638573,
      0.013437888585031033,
      0.07097994536161423,
      -0.015950025990605354,
      -0.034578967839479446,
      0.02343851327896118,
      -0.041167326271533966,
      -0.08780866861343384,
      -0.03439474105834961,
      -0.06711738556623459,
      0.03247542679309845,
      -0.07946205884218216,
      -0.04429275915026665,
      -0.05197655037045479,
      -0.012877928093075752,
      0.007853205315768719,
      -0.05936378240585327,
      -0.12642788887023926,
      -0.014822494238615036,
      0.049920424818992615,
      -0.05353882536292076,
      -0.03607082739472389,
      -0.023467859253287315,
      0.11380456387996674,
      0.0033518073614686728,
      -0.06825149804353714,
      0.029420234262943268,
      -0.055884476751089096,
      -0.02368750423192978,
      -0.0710509642958641,
      -0.08780359476804733,
      0.010312465950846672,
      -0.019082818180322647,
      -0.005770149640738964,
      -0.03141647204756737,
      0.0254821814596653,
      0.005922587122768164,
      -0.022641442716121674,
      -0.054058417677879333,
      -0.09298667311668396,
      0.047381505370140076,
      -0.05990581214427948,
      -0.11052758246660233,
      0.027893895283341408,
      0.018250441178679466,
      0.04097475856542587,
      -0.0008790763677097857,
      -0.06612487137317657,
      0.03607464209198952,
      -0.03011157549917698,
      -0.017743026837706566,
      -0.005571160465478897,
      0.01080092042684555,
      0.05290639027953148,
      -0.07720833271741867,
      -0.07546038180589676,
      -0.011352479457855225,
      -0.00042805998236872256,
      -0.005466733127832413,
      -0.07083013653755188,
      0.03224492445588112,
      0.018977316096425056,
      0.01709907315671444,
      0.008796347305178642,
      -0.010023651644587517,
      -0.030080990865826607,
      -0.03844891116023064,
      -0.0038200721610337496,
      0.024726763367652893,
      0.012268026359379292,
      -0.0014520682161673903,
      0.05099544674158096,
      -0.06262095272541046,
      -0.017076842486858368,
      -0.020759165287017822,
      0.0549258291721344,
      0.11846704035997391,
      0.03141801804304123,
      -0.03326205536723137,
      -0.041741374880075455,
      -0.07398252934217453,
      0.04742441698908806,
      0.022958314046263695,
      -0.019632145762443542,
      -0.00028228238807059824,
      0.08240097761154175,
      0.0201416052877903,
      -0.0784553810954094,
      0.07789431512355804,
      0.01559894997626543,
      -0.07771364599466324,
      0.0006222753436304629,
      -0.031239017844200134,
      -0.0013215139042586088,
      -0.02537294663488865,
      0.024550631642341614,
      -0.03642011806368828,
      -0.05063509941101074,
      0.08916337043046951,
      0.0028668439481407404,
      -0.014487548731267452,
      -0.05131398141384125,
      -0.06767535954713821,
      -0.028651567175984383,
      0.05160022899508476,
      0.012746496126055717,
      0.058637261390686035,
      -0.057831645011901855,
      -0.02231917902827263,
      -0.05830364674329758,
      0.03654066100716591,
      -0.0202723890542984,
      -0.06847945600748062,
      -0.026059040799736977,
      0.07339747995138168,
      0.09868156909942627,
      0.10472014546394348,
      -0.029418736696243286,
      -0.016647042706608772,
      0.006415769923478365,
      0.09806650876998901,
      0.0033396719954907894,
      -0.0362127311527729,
      0.00835252832621336,
      -0.06035904213786125,
      0.041763000190258026,
      -0.0031783846206963062,
      -0.0798705667257309,
      0.0038659374695271254,
      0.05841793119907379,
      -0.05587273836135864,
      0.05555300787091255,
      0.020668717101216316,
      0.0049210358411073685,
      0.07839696854352951,
      -0.08204274624586105,
      0.019019393250346184,
      -0.023423926904797554,
      -0.043725136667490005,
      0.014394978061318398,
      0.11222775280475616,
      0.006905261892825365,
      0.11705871671438217,
      0.004863541107624769,
      -0.045918058604002,
      0.07459260523319244,
      0.002955113537609577,
      -0.044885605573654175,
      0.08616458624601364,
      -0.034641217440366745,
      0.00041005422826856375,
      -0.010538865812122822,
      -0.07671960443258286,
      0.002926947083324194,
      -0.040038999170064926,
      -0.06782464683055878,
      -0.05136297643184662,
      0.06313545256853104,
      0.020505011081695557,
      -0.014503666199743748,
      -0.04189746454358101,
      0.02555694431066513,
      0.019325392320752144,
      -0.04843883588910103,
      -0.014654652215540409,
      -0.05119727551937103,
      -0.056523647159338,
      -0.047383785247802734,
      -0.024319425225257874,
      0.0003010129148606211,
      0.03885817527770996,
      0.00782378576695919,
      0.0020482500549405813,
      -0.02108338475227356,
      0.03134604170918465,
      -0.027588849887251854,
      0.0006013169186189771,
      0.09156037867069244
    ],
    [
      -0.004901790991425514,
      -0.009944312274456024,
      0.07929873466491699,
      -0.0012469325447455049,
      0.01191702764481306,
      -0.010179105214774609,
      -0.01456624548882246,
      -0.007668669801205397,
      -0.03398482874035835,
      -0.000392389134503901,
      0.014843961223959923,
      0.04926816374063492,
      -0.03551788255572319,
      0.026747949421405792,
      -0.017268003895878792,
      0.04388052225112915,
      -0.016533439978957176,
      -0.006384825799614191,
      -0.01608668640255928,
      0.03801880031824112,
      0.09104671329259872,
      0.09375260025262833,
      -0.0199685450643301,
      0.0451965294778347,
      -0.03457201272249222,
      -0.012934316881000996,
      0.043633319437503815,
      0.010175120085477829,
      0.004852482117712498,
      -0.004734214395284653,
      -0.017180267721414566,
      -0.06439860165119171,
      -0.010131264105439186,
      0.03897445648908615,
      0.031201282516121864,
      0.030663689598441124,
      0.057481903582811356,
      -0.0017451090971007943,
      -0.037106581032276154,
      0.03470195084810257,
      -0.011777793057262897,
      0.02041178196668625,
      0.02708238549530506,
      -0.028588803485035896,
      -0.03945642337203026,
      -0.030423928052186966,
      0.04936714097857475,
      -0.023230373859405518,
      -0.06812051683664322,
      -0.02941417135298252,
      -0.05309457331895828,
      -0.030153019353747368,
      -0.09792780131101608,
      -0.07493137568235397,
      0.07925009727478027,
      -0.028813229873776436,
      0.022542277351021767,
      -0.02112290821969509,
      0.02363278530538082,
      -0.023912979289889336,
      -0.01610811986029148,
      -0.09530776739120483,
      -0.02567015402019024,
      -0.0062802378088235855,
      -0.010421089828014374,
      -0.003546459600329399,
      0.011015836149454117,
      0.019157936796545982,
      0.049133796244859695,
      -0.08996867388486862,
      0.03201384097337723,
      -0.0436009019613266,
      -0.0030060887802392244,
      0.060642682015895844,
      -0.03432466834783554,
      0.02154872566461563,
      0.011390988714993,
      0.03719748556613922,
      0.03782285749912262,
      0.023045457899570465,
      -0.010558969341218472,
      -0.025691330432891846,
      0.0150753827765584,
      0.01598304882645607,
      0.08036958426237106,
      0.02818395011126995,
      0.08110808581113815,
      -0.017878973856568336,
      -0.06378950923681259,
      -0.009551207534968853,
      0.053931061178445816,
      0.03709923103451729,
      0.029333993792533875,
      0.010985417291522026,
      -0.02165188454091549,
      0.09618444740772247,
      -0.10940344631671906,
      0.04284226894378662,
      0.016079088672995567,
      -0.007423839531838894,
      -0.02710997685790062,
      0.07054514437913895,
      -0.011813731864094734,
      -0.03980325907468796,
      0.05999927967786789,
      -0.04880569130182266,
      0.03451475128531456,
      -0.04813719913363457,
      0.06794930249452591,
      0.001823290716856718,
      0.013907704502344131,
      0.009433047845959663,
      -0.028957713395357132,
      -0.026063594967126846,
      -0.019024670124053955,
      0.048219095915555954,
      0.018223272636532784,
      0.052334122359752655,
      0.008465034887194633,
      -0.12090563774108887,
      -0.09832817316055298,
      -0.034625135362148285,
      0.02645292319357395,
      -0.027267688885331154,
      0.06477392464876175,
      -0.09004399925470352,
      -0.039894115179777145,
      -0.04570677503943443,
      0.023168129846453667,
      -0.012597215361893177,
      0.10488949716091156,
      -0.013315319083631039,
      0.03941693902015686,
      -0.016924681141972542,
      0.05068861320614815,
      0.01666347123682499,
      -0.03552878275513649,
      -0.031916335225105286,
      0.01613094098865986,
      -0.015583562664687634,
      0.0363549143075943,
      0.010195661336183548,
      -0.021375639364123344,
      0.015421461313962936,
      0.12132750451564789,
      -0.029302967712283134,
      -0.04690280929207802,
      -0.02653874084353447,
      -0.006955862045288086,
      -0.015826132148504257,
      0.004190834239125252,
      0.019436083734035492,
      -0.02165973372757435,
      0.017253747209906578,
      -0.014258470386266708,
      -0.005891129374504089,
      0.022573793306946754,
      0.01436858344823122,
      -0.02984510362148285,
      -0.04185549542307854,
      -0.054915521293878555,
      -0.022712834179401398,
      0.028568901121616364,
      0.07734013348817825,
      -0.028992077335715294,
      -0.0467393584549427,
      0.00020363593648653477,
      0.017288314178586006,
      -0.010363208130002022,
      -0.04631110280752182,
      0.04958408698439598,
      0.11332537233829498,
      0.053722232580184937,
      0.04171110317111015,
      -0.055868133902549744,
      -0.023677702993154526,
      0.06716269254684448,
      0.05772603675723076,
      0.012562291696667671,
      -0.00799480453133583,
      0.012920510955154896,
      0.007220140658318996,
      -0.0053815413266420364,
      -0.014306893572211266,
      -0.03998697176575661,
      0.018854379653930664,
      -0.0926976278424263,
      -0.007603189907968044,
      -0.022778378799557686,
      -0.03303402289748192,
      0.01845015585422516,
      -0.02611485682427883,
      -0.01374297495931387,
      0.0021680656354874372,
      -0.03829013183712959,
      -0.007547382265329361,
      0.008128630928695202,
      0.056378673762083054,
      0.004957959055900574,
      -0.03184495121240616,
      -0.011892189271748066,
      -0.017589028924703598,
      0.03359585255384445,
      0.07517693936824799,
      -0.009129602462053299,
      0.009289348497986794,
      -0.033657509833574295,
      -0.04266357421875,
      -0.04053288325667381,
      -0.04071927070617676,
      0.030141083523631096,
      0.018374169245362282,
      -0.03823034092783928,
      0.04028007760643959,
      -0.10297971218824387,
      -0.07485128194093704,
      -0.07247304916381836,
      0.002033855766057968,
      0.02031988836824894,
      0.021045217290520668,
      -0.024762960150837898,
      -0.02572803758084774,
      0.08451566100120544,
      0.052558623254299164,
      -0.09907855093479156,
      0.026805147528648376,
      -0.09229016304016113,
      -0.038708511739969254,
      -0.048262301832437515,
      0.04230879247188568,
      0.011243605986237526,
      -0.02043330669403076,
      -0.030233480036258698,
      -0.03581971675157547,
      -0.01035351026803255,
      -0.04919332638382912,
      -0.02636846899986267,
      -0.05741532891988754,
      0.010299880057573318,
      0.015227842144668102,
      0.025815334171056747,
      0.027038393542170525,
      0.029820911586284637,
      -0.07990849763154984,
      0.009172123856842518,
      -0.043546561151742935,
      -0.008971937000751495,
      0.05858064815402031,
      0.03377695009112358,
      0.029642367735505104,
      0.0012701915111392736,
      -0.007927775382995605,
      0.00902536977082491,
      -0.03188486397266388,
      -0.044930584728717804,
      -0.01911148801445961,
      0.05194517597556114,
      -0.029835106804966927,
      0.0008807184058241546,
      -0.022698137909173965,
      0.03000371716916561,
      0.06700359284877777,
      -0.007816067896783352,
      -0.02031962387263775,
      0.011206267401576042,
      -0.05293368548154831,
      -0.06105048954486847,
      0.0709531381726265,
      0.07553501427173615,
      0.028828240931034088,
      -0.023892022669315338,
      -0.057207219302654266,
      0.030962498858571053,
      0.056474700570106506,
      -0.07106375694274902,
      0.011284771375358105,
      0.08653852343559265,
      -0.015003131702542305,
      -0.04816151410341263,
      -0.00897009763866663,
      0.06228223443031311,
      -0.04968266561627388,
      0.05625848099589348,
      0.026647139340639114,
      0.05578167364001274,
      -0.030626045539975166,
      0.036057248711586,
      -0.006931222043931484,
      0.016416210681200027,
      0.011289635673165321,
      -0.04038630798459053,
      0.014744028449058533,
      0.027968205511569977,
      -0.0027228593826293945,
      -0.018463466316461563,
      0.05629490315914154,
      0.024930082261562347,
      0.08590663224458694,
      0.008662240579724312,
      0.009535501711070538,
      -0.01589948683977127,
      0.07914268225431442,
      -0.013063594698905945,
      -0.04103294759988785,
      -0.0982455387711525,
      0.012989360839128494,
      -0.013651584275066853,
      0.040979206562042236,
      0.04384555667638779,
      -0.013553357683122158,
      -0.008520049042999744,
      -0.01305035687983036,
      -0.03029186651110649,
      -0.10178408771753311,
      -0.04537711665034294,
      0.07868094742298126,
      -0.01821102760732174,
      0.039005767554044724,
      -0.008786151185631752,
      0.040816426277160645,
      0.00365838292054832,
      0.03796284645795822,
      0.028622649610042572,
      0.055664509534835815,
      -0.0992693230509758,
      0.0024101228918880224,
      -0.02003648690879345,
      0.011728384532034397,
      -0.05594509094953537,
      -0.009307041764259338,
      0.0008678887970745564,
      0.016928181052207947,
      0.06655840575695038,
      -0.018177708610892296,
      0.009981655515730381,
      -0.022209951654076576,
      0.046824052929878235,
      0.01801927760243416,
      -0.06314045935869217,
      -0.004346215166151524,
      -0.017834633588790894,
      0.028424659743905067,
      0.014326372183859348,
      -0.07009714841842651,
      0.018605927005410194,
      0.05718616023659706,
      -0.018805362284183502,
      -0.03319321572780609,
      0.0387360043823719,
      0.042107801884412766,
      0.01593436673283577,
      0.08013638854026794,
      0.06803656369447708,
      0.03152567893266678,
      -0.027578415349125862,
      0.04691794142127037,
      0.019998474046587944,
      0.04929962381720543,
      -0.024308139458298683,
      -0.012149331159889698,
      -0.015202052891254425,
      -0.018889032304286957,
      0.03291194885969162,
      -0.017521504312753677,
      0.029156126081943512,
      -0.1449049711227417,
      0.08990486711263657,
      -0.015349837951362133,
      0.01199523638933897,
      0.0016385457711294293,
      -0.09976877272129059,
      -0.013364485464990139,
      -0.03254889324307442,
      0.033544473350048065,
      -0.026286916807293892,
      -0.04848479479551315,
      -0.00688264612108469,
      0.05310190096497536,
      -0.07796964794397354,
      -0.01704086922109127,
      -0.012465713545680046,
      -0.10774966329336166,
      -0.0045210435055196285,
      -0.06448039412498474,
      -0.004490023944526911,
      0.03393996134400368,
      -0.009191658347845078,
      -0.050308115780353546,
      -0.03744041919708252,
      0.005912791006267071,
      0.04458983242511749,
      -0.007892987690865993,
      -0.01627291738986969,
      -0.04502495750784874,
      0.02584400400519371,
      0.02671962045133114,
      0.00380242639221251,
      -0.03525394946336746,
      -0.047166381031274796,
      0.10390288382768631,
      0.01760486699640751,
      0.03356606140732765,
      -0.027645159512758255,
      0.02003195509314537,
      0.028225935995578766,
      -0.02961413748562336,
      0.0423349030315876,
      0.07444091886281967,
      0.006274495739489794,
      -0.05695762857794762,
      -0.05867138132452965,
      0.024311747401952744,
      -0.042027875781059265,
      -0.01673983223736286,
      -0.0030638216994702816,
      -0.061794862151145935,
      -0.06400100886821747,
      0.0037954554427415133,
      -0.06730327010154724,
      -0.007213492412120104,
      0.03420625999569893,
      0.0813131034374237,
      0.04763510078191757,
      0.011301306076347828,
      -0.019828427582979202,
      -0.005589377600699663,
      0.0031555569730699062,
      0.028132565319538116,
      -0.03133976832032204,
      -8.18073604023084e-05,
      0.0027411554474383593,
      0.003901840653270483,
      -0.05933835357427597,
      -0.02097359113395214,
      -0.024627266451716423,
      0.05814400315284729,
      -0.020493624731898308,
      0.10041829198598862,
      0.014981070533394814,
      0.009046138264238834,
      -0.09550689160823822,
      -0.008790207095444202,
      -0.03889777511358261,
      0.0026805181987583637,
      0.01804807223379612,
      -0.00613268930464983,
      0.04276029393076897,
      -0.041337449103593826,
      -0.008810972794890404,
      -0.07603538036346436,
      0.05760136619210243,
      -0.008303732611238956,
      0.007007248234003782,
      0.02115979790687561,
      -0.052704956382513046,
      -0.05935507267713547,
      0.06987416744232178,
      -0.02457601949572563,
      0.045180000364780426,
      0.06500307470560074,
      0.01024564728140831,
      -0.11256587505340576,
      -0.06534192711114883,
      0.008229470811784267,
      -0.017095142975449562,
      0.017370954155921936,
      0.06043729558587074,
      -0.09992760419845581,
      -0.003097404260188341,
      -0.026351913809776306,
      0.04453498497605324,
      -0.015696106478571892,
      -0.04048987105488777,
      0.012010056525468826,
      -0.0076422784477472305,
      -0.05861597880721092,
      0.03329606354236603,
      0.0033845920115709305,
      0.02532671019434929,
      -0.08822033554315567,
      0.008734935894608498,
      -0.061033423990011215,
      0.013842419721186161,
      0.031221160665154457,
      -0.005467040464282036,
      -0.035885460674762726,
      -0.11946805566549301,
      -0.015739556401968002,
      0.049875274300575256,
      -0.02238944172859192,
      0.051842816174030304,
      -0.10104367882013321,
      0.0258239284157753,
      -0.06123574078083038,
      -0.07704541087150574,
      0.003449527081102133,
      -0.08081582933664322,
      -0.007479705382138491,
      0.03111371025443077,
      0.05233331769704819,
      -0.003927597310394049,
      0.04177515581250191,
      0.03516785055398941
    ],
    [
      -0.061763644218444824,
      0.07015489041805267,
      -0.0246345866471529,
      0.02376210317015648,
      0.05785016715526581,
      0.06391303241252899,
      0.014033716171979904,
      0.018538249656558037,
      0.02254340425133705,
      0.05404317006468773,
      0.0205677542835474,
      0.019250046461820602,
      -0.011871101334691048,
      -0.06362839788198471,
      -0.09321126341819763,
      0.033131517469882965,
      -0.013585885986685753,
      0.04057575762271881,
      0.014465258456766605,
      0.029390066862106323,
      0.09922821074724197,
      -0.021111635491251945,
      -0.1334853172302246,
      -0.075358547270298,
      -0.036857932806015015,
      0.04125010594725609,
      0.02373388782143593,
      0.032653577625751495,
      0.01135798916220665,
      0.03582580387592316,
      -0.012806586921215057,
      -0.0031155014876276255,
      -0.05066942796111107,
      -0.00908927246928215,
      -0.07149381190538406,
      0.06835733354091644,
      -0.045481082051992416,
      -0.05740758404135704,
      0.011254400946199894,
      -0.04639175906777382,
      0.08628158271312714,
      -0.03772854059934616,
      0.003790294285863638,
      0.010526716709136963,
      -0.0643753707408905,
      0.06352510303258896,
      0.06784386187791824,
      -0.027279898524284363,
      -0.004755436442792416,
      0.05124162510037422,
      -0.056108128279447556,
      0.01051764003932476,
      -0.04823103919625282,
      0.058239229023456573,
      0.03009944222867489,
      -0.00587316881865263,
      -0.02069227769970894,
      -0.01787998341023922,
      0.015280756168067455,
      -0.004644355271011591,
      -0.06906519830226898,
      -0.014225105755031109,
      -0.046251676976680756,
      -0.09293870627880096,
      0.026280542835593224,
      -0.015834655612707138,
      -0.014674454927444458,
      -0.0015788692981004715,
      -0.09480014443397522,
      -0.001461879233829677,
      -0.06165195256471634,
      -0.003598061390221119,
      0.05619041621685028,
      -0.07953643053770065,
      0.05079067870974541,
      0.014276940375566483,
      -0.055628951638936996,
      -0.009532787837088108,
      0.032078761607408524,
      -0.00869440846145153,
      -0.04303452745079994,
      0.019365128129720688,
      -0.021648505702614784,
      0.03735825791954994,
      0.07446476817131042,
      -0.018177689984440804,
      0.01967150904238224,
      0.0012553195701912045,
      -0.051687516272068024,
      -0.0018710674485191703,
      -0.05163879320025444,
      0.03969866782426834,
      0.031126637011766434,
      0.058951325714588165,
      -0.03812406212091446,
      0.06120095029473305,
      0.009352575987577438,
      -0.01083853468298912,
      2.4650224077049643e-05,
      -0.044639647006988525,
      -0.03724182769656181,
      0.00045156272244639695,
      -0.031093096360564232,
      0.008664552122354507,
      0.04464373737573624,
      -0.015644343569874763,
      -0.052787695080041885,
      0.011987547390162945,
      -0.0022723176516592503,
      -0.046135060489177704,
      0.036582909524440765,
      0.03632713109254837,
      -0.017337651923298836,
      -0.0009696194902062416,
      0.010696407407522202,
      0.05230579897761345,
      0.028952663764357567,
      0.023236175999045372,
      0.03536617383360863,
      0.01938808336853981,
      0.016783395782113075,
      0.015614546835422516,
      0.016929544508457184,
      0.0063138981349766254,
      -0.03032517246901989,
      -0.05822131782770157,
      0.020371826365590096,
      0.052827563136816025,
      0.04316871240735054,
      0.005036545917391777,
      -0.04388204589486122,
      -0.04669387266039848,
      -0.058342982083559036,
      0.00647010700777173,
      0.025783181190490723,
      -0.009423306211829185,
      0.008046572096645832,
      0.014273946173489094,
      -0.06110283359885216,
      0.05320952460169792,
      -0.014047089032828808,
      -0.029703553766012192,
      0.03479096293449402,
      -0.06303645670413971,
      0.014222830533981323,
      -0.028048336505889893,
      0.03276842087507248,
      0.027666671201586723,
      0.018189819529652596,
      0.046206410974264145,
      0.021949805319309235,
      -0.01579519547522068,
      0.0018004943849518895,
      0.10078713297843933,
      0.04195445403456688,
      -0.009029892273247242,
      0.07647167891263962,
      0.04870228469371796,
      0.03313024714589119,
      -0.042233675718307495,
      0.058579035103321075,
      -0.04482025280594826,
      -0.021808994933962822,
      0.009629501961171627,
      -0.09752576798200607,
      0.1183808222413063,
      0.038501158356666565,
      -0.04951106011867523,
      0.03165997192263603,
      -0.04425665736198425,
      0.030503030866384506,
      -0.042219728231430054,
      -0.005642629228532314,
      0.021538270637392998,
      -0.022610526531934738,
      -0.03527849540114403,
      0.1479710042476654,
      -0.017504857853055,
      -0.041901424527168274,
      -0.027436118572950363,
      -0.03677525371313095,
      -0.0135162603110075,
      -0.031781185418367386,
      -0.027323970571160316,
      0.0295958761125803,
      0.096980981528759,
      -0.027680927887558937,
      0.0182292852550745,
      0.013756196945905685,
      -0.03763449564576149,
      -0.04358295723795891,
      0.07150762528181076,
      0.04757528379559517,
      0.041960883885622025,
      -0.021916460245847702,
      -0.02791759930551052,
      0.01608298532664776,
      0.04155275225639343,
      0.09191355109214783,
      -0.009717969223856926,
      -0.09154998511075974,
      0.03244561329483986,
      0.016525495797395706,
      -0.03770410642027855,
      0.05567166954278946,
      -0.0960482507944107,
      -0.1315869837999344,
      -0.00668397918343544,
      -0.03910115733742714,
      -0.03902998939156532,
      0.05055331066250801,
      0.04188571870326996,
      0.07337604463100433,
      -0.038854118436574936,
      0.029337137937545776,
      -0.054875049740076065,
      0.015657205134630203,
      0.020710088312625885,
      -0.009843789972364902,
      0.02142007276415825,
      0.0534006729722023,
      0.025811539962887764,
      0.07489653676748276,
      -0.05449123680591583,
      -0.015287771821022034,
      0.03838995099067688,
      -0.06949879974126816,
      -0.019350705668330193,
      -0.03637947142124176,
      0.05623949319124222,
      0.10449332743883133,
      0.012272629886865616,
      -0.0810280293226242,
      -0.00986967608332634,
      -0.05556945502758026,
      -0.067868672311306,
      -0.007444792427122593,
      0.05421269312500954,
      -0.041090723127126694,
      0.028462905436754227,
      0.024524608626961708,
      -0.030637139454483986,
      -0.001098361099138856,
      0.0027548838406801224,
      -0.030231142416596413,
      -0.020451907068490982,
      -0.06305136531591415,
      -0.006750388070940971,
      -0.05521551892161369,
      0.00020020823285449296,
      0.016401024535298347,
      -0.006002336740493774,
      -0.008089961484074593,
      0.013786430470645428,
      -0.01382631715387106,
      -0.004779526963829994,
      -0.0008717596065253019,
      0.001920983660966158,
      -0.029381535947322845,
      -0.11270531266927719,
      -0.004758626688271761,
      -0.025400064885616302,
      0.012883396819233894,
      -0.05615203082561493,
      0.035003114491701126,
      -0.030263731256127357,
      0.0002904034627135843,
      0.008861844427883625,
      0.06517080962657928,
      0.04422615095973015,
      -0.020787980407476425,
      -0.0942487120628357,
      0.0019463954959064722,
      -0.051175422966480255,
      0.03110905922949314,
      -0.017176706343889236,
      -0.023646170273423195,
      0.04264320060610771,
      0.032469116151332855,
      -0.06065782532095909,
      -0.034922853112220764,
      0.0031400478910654783,
      -0.03436552733182907,
      0.09526675939559937,
      -0.014163707382977009,
      -0.031060129404067993,
      0.011190230958163738,
      -0.0335933081805706,
      -0.026485424488782883,
      -0.03016522526741028,
      -0.001255928073078394,
      -0.04113860800862312,
      0.0003675933403428644,
      0.07382453233003616,
      0.018957315012812614,
      -0.05373696982860565,
      0.07492984086275101,
      -0.03558586910367012,
      0.07695407420396805,
      -0.02888791635632515,
      -0.09184912592172623,
      -0.02512490749359131,
      -0.024522244930267334,
      0.014091519638895988,
      -0.01454424299299717,
      0.06647130846977234,
      0.09276753664016724,
      -0.03273282200098038,
      -0.003241622122004628,
      -0.0073301768861711025,
      0.002695562317967415,
      0.022359607741236687,
      0.022186366841197014,
      0.025489240884780884,
      -0.039755456149578094,
      0.003444424131885171,
      -0.03958042338490486,
      0.04408402368426323,
      0.007386464159935713,
      -0.028505142778158188,
      0.028190622106194496,
      -0.023791152983903885,
      0.03919029235839844,
      -0.0975501537322998,
      -0.051921457052230835,
      -0.06838510930538177,
      0.03639877960085869,
      0.019131405279040337,
      0.015938395634293556,
      -0.016746971756219864,
      0.025967219844460487,
      -0.00847981870174408,
      0.0003686815616674721,
      -0.031039219349622726,
      -0.04453388601541519,
      -0.05290243774652481,
      0.04642399027943611,
      0.007106977980583906,
      0.07540950924158096,
      -0.0017750245751813054,
      0.02542010135948658,
      -0.054261352866888046,
      0.02738180197775364,
      0.042455993592739105,
      -0.0911405086517334,
      0.026359092444181442,
      -0.009505764581263065,
      -0.03308353200554848,
      -0.08378154039382935,
      0.0427277497947216,
      -0.013110934756696224,
      0.01068112626671791,
      0.016621362417936325,
      0.007632969878613949,
      -0.04415867477655411,
      0.03271421417593956,
      -0.028941072523593903,
      0.04920850321650505,
      -0.004999390337616205,
      0.059927649796009064,
      0.04602385312318802,
      -0.044874511659145355,
      0.03440719470381737,
      -0.03172999247908592,
      0.03261730819940567,
      -0.04540645703673363,
      -0.05482660233974457,
      0.0078112222254276276,
      -0.004336438607424498,
      0.059177447110414505,
      -0.017430802807211876,
      -0.005334287881851196,
      0.018872857093811035,
      0.06703728437423706,
      -0.01618817076086998,
      -0.05642372742295265,
      -0.003932712599635124,
      0.09956814348697662,
      0.04072035849094391,
      0.017420804128050804,
      -0.008163155056536198,
      0.08184698969125748,
      -0.0307613555341959,
      0.05843999981880188,
      -0.03534969687461853,
      -0.04703032597899437,
      0.04974319413304329,
      -0.015307425521314144,
      0.029319411143660545,
      0.05894368514418602,
      0.05295226722955704,
      -0.03678251430392265,
      0.007708739023655653,
      -0.0015829770127311349,
      -0.0045665232464671135,
      0.021696394309401512,
      0.044775884598493576,
      0.0015101631870493293,
      0.08964691311120987,
      0.01667691394686699,
      -0.06468900293111801,
      -0.004612998105585575,
      -0.012868250720202923,
      0.010956423357129097,
      -0.039018820971250534,
      -0.028736619278788567,
      -0.059181977063417435,
      -0.01733553595840931,
      -0.0597984604537487,
      -0.04259881377220154,
      0.04586948826909065,
      -0.022167129442095757,
      -0.022108985111117363,
      -0.06112944707274437,
      0.04653032496571541,
      -0.07483643293380737,
      -0.028200285509228706,
      0.040962591767311096,
      0.04034193605184555,
      0.036163218319416046,
      0.03189661353826523,
      -0.02162897400557995,
      -0.04133855924010277,
      -0.059439435601234436,
      -0.035910941660404205,
      0.005302821286022663,
      0.058529388159513474,
      -0.002519303932785988,
      -0.009465168230235577,
      -0.007256370037794113,
      -0.04909579083323479,
      0.04262179136276245,
      0.009383211843669415,
      -0.08446250855922699,
      0.0452188216149807,
      -0.003563858335837722,
      -0.025164373219013214,
      0.009574740193784237,
      -0.03509889915585518,
      -0.02468182146549225,
      -0.027342909947037697,
      -0.005182997789233923,
      -0.040039870887994766,
      -0.0664447546005249,
      0.01412170473486185,
      0.0469222329556942,
      0.0485859178006649,
      -0.014359459280967712,
      -0.04021995887160301,
      0.0077063702046871185,
      -0.0389229916036129,
      0.09154029190540314,
      0.020587481558322906,
      0.07738471776247025,
      0.002122018951922655,
      0.028317391872406006,
      0.026044702157378197,
      0.05247531831264496,
      -0.039556168019771576,
      0.04892570525407791,
      -0.09068797528743744,
      -0.04169020429253578,
      0.07388231158256531,
      0.039123110473155975,
      -0.0006953937700018287,
      -0.010125680826604366,
      0.02782966010272503,
      -0.023910971358418465,
      -0.026298418641090393,
      0.07429725676774979,
      -0.06584051996469498,
      -0.07828957587480545,
      -0.040066756308078766,
      -0.047339580953121185,
      0.023916658014059067,
      -0.01866389624774456,
      -0.04381627216935158,
      0.013822199776768684,
      0.0065764146856963634,
      0.11087232828140259,
      -0.016092173755168915,
      0.013940493576228619,
      0.06740310043096542,
      -0.05036136507987976,
      -0.0051556238904595375,
      -0.041083257645368576,
      -0.050326116383075714,
      -0.012971796095371246,
      0.038683801889419556,
      0.012349477969110012,
      0.013741730712354183,
      -0.07626719027757645,
      0.04469985514879227,
      0.039126232266426086,
      0.00585147412493825,
      0.04788704961538315,
      0.04558458551764488,
      -0.004648332018405199,
      -0.03454694151878357,
      -0.03756023198366165,
      -0.04678581655025482,
      -0.03728422150015831,
      -0.059226274490356445
    ],
    [
      -0.023796474561095238,
      -0.11423391103744507,
      -0.15122830867767334,
      0.07911645621061325,
      0.019629202783107758,
      -0.004875307437032461,
      -0.06232716515660286,
      -0.00292711122892797,
      0.047774117439985275,
      0.0171262975782156,
      0.040418222546577454,
      0.057243216782808304,
      0.046082574874162674,
      -0.09128332883119583,
      0.03321988880634308,
      0.017548341304063797,
      -0.011747989803552628,
      -0.05508921295404434,
      0.1300777792930603,
      -0.020113855600357056,
      0.03909853845834732,
      -0.013999631628394127,
      -0.04001043736934662,
      -0.05830280855298042,
      0.0356229729950428,
      -0.06883598119020462,
      0.08214592933654785,
      -0.03711499273777008,
      -0.048178546130657196,
      0.0018748310394585133,
      -0.13900357484817505,
      -0.11156544834375381,
      0.05130606144666672,
      -0.010571067221462727,
      -0.0018864494049921632,
      -0.013415087945759296,
      0.03950329124927521,
      -0.03753279522061348,
      0.014348790980875492,
      -0.03404555842280388,
      0.03962533175945282,
      0.10848471522331238,
      0.10319308191537857,
      0.016626819968223572,
      -0.030351493507623672,
      0.08631192147731781,
      0.009682119823992252,
      -0.07283782958984375,
      -0.053297482430934906,
      0.0070679038763046265,
      0.0025822727475315332,
      0.002785551128908992,
      -0.041649267077445984,
      -0.09946770966053009,
      -0.059255652129650116,
      -0.08724493533372879,
      -0.12341923266649246,
      -0.010817035101354122,
      -0.04615071788430214,
      0.03409513831138611,
      -0.013702614232897758,
      0.00633422564715147,
      -0.03249933943152428,
      -0.058688677847385406,
      0.006130249239504337,
      0.0018367837183177471,
      -0.036331649869680405,
      0.03190630301833153,
      0.030800025910139084,
      0.0731675773859024,
      -0.08599938452243805,
      0.07362665981054306,
      0.024086495861411095,
      -0.04868951812386513,
      -0.0374031737446785,
      -0.001738097402267158,
      0.005095364060252905,
      0.008886835537850857,
      -0.009666411206126213,
      0.028132038190960884,
      0.058300841599702835,
      -0.02815595455467701,
      -0.03314436972141266,
      -0.013979255221784115,
      -0.010433768853545189,
      -0.0752132385969162,
      -0.018278129398822784,
      0.06289830803871155,
      -0.03554343432188034,
      -0.11209789663553238,
      -0.0364212840795517,
      -0.010664564557373524,
      -0.011492256075143814,
      0.003108093747869134,
      0.04789822921156883,
      -0.038992300629615784,
      -0.02874726615846157,
      0.031063826754689217,
      0.0028204729314893484,
      0.00033136826823465526,
      -0.031586892902851105,
      0.006465984508395195,
      0.006159517448395491,
      -0.059709951281547546,
      -0.008919746614992619,
      -0.013190989382565022,
      -0.009915106929838657,
      -0.04235635697841644,
      0.023133542388677597,
      0.011583443731069565,
      -0.1239522248506546,
      -0.043473511934280396,
      -0.07943248748779297,
      0.04364541172981262,
      -0.030892888084053993,
      -0.020679742097854614,
      -0.04367760568857193,
      0.036529041826725006,
      -0.01516664121299982,
      -0.02203246019780636,
      -0.029986320063471794,
      0.0720406249165535,
      0.01630406454205513,
      0.14566278457641602,
      0.031823866069316864,
      0.02278999425470829,
      0.0002854493213817477,
      0.051367368549108505,
      0.014961721375584602,
      0.06247597560286522,
      0.05798549950122833,
      0.0032358618918806314,
      0.07604178786277771,
      0.09972663223743439,
      -0.004242952447384596,
      0.07668281346559525,
      0.03598490729928017,
      0.029956016689538956,
      0.08387244492769241,
      -0.016144417226314545,
      0.08874721825122833,
      -0.014474103227257729,
      0.022000938653945923,
      0.024117719382047653,
      0.062095679342746735,
      -0.021495021879673004,
      -0.017341947183012962,
      -0.03395075723528862,
      -0.0177452489733696,
      0.06363360583782196,
      0.06110462546348572,
      0.04111389070749283,
      0.03190016746520996,
      -0.02839825116097927,
      -0.019780417904257774,
      0.10314730554819107,
      0.03282599896192551,
      -0.041149500757455826,
      -0.11939624696969986,
      0.08663360029459,
      -0.010469447821378708,
      -0.02918768860399723,
      -0.0016377881402149796,
      0.01270414050668478,
      0.07126490026712418,
      -0.00863717682659626,
      -0.06883503496646881,
      -0.011189861223101616,
      -0.046374961733818054,
      -0.030461840331554413,
      0.0005740332999266684,
      -0.021020546555519104,
      -0.04399546608328819,
      0.0038715649861842394,
      -0.04526650905609131,
      -0.09781249612569809,
      -0.05408601462841034,
      -0.02436438761651516,
      -0.018364690244197845,
      -0.005286044906824827,
      0.0011854118201881647,
      -0.022411026060581207,
      -0.08774138987064362,
      0.012909308075904846,
      -0.010280651040375233,
      -0.060108501464128494,
      0.004533350933343172,
      0.0015327715082094073,
      0.03139793127775192,
      0.06749365478754044,
      0.002296761842444539,
      0.048824187368154526,
      -0.06321010738611221,
      -0.005094989202916622,
      -0.07824952900409698,
      0.00046779646072536707,
      0.020251642912626266,
      0.06321606040000916,
      -0.0782122015953064,
      0.007339776027947664,
      0.013933590613305569,
      -0.044191110879182816,
      0.14238306879997253,
      0.03160510212182999,
      0.00038516352651640773,
      0.045790813863277435,
      0.00578047102317214,
      -0.03038586489856243,
      0.02321365475654602,
      0.027613267302513123,
      -0.012424170970916748,
      0.010089193470776081,
      -0.010755753144621849,
      -0.03064659610390663,
      -0.037111397832632065,
      -0.03020107001066208,
      0.023842670023441315,
      -0.031272824853658676,
      0.019895760342478752,
      -0.011956853792071342,
      0.002253962215036154,
      -0.04590034857392311,
      0.030849270522594452,
      0.00019071614951826632,
      0.027063360437750816,
      0.019562818109989166,
      0.09618833661079407,
      0.06119980290532112,
      0.02104831486940384,
      -0.001956008141860366,
      0.016842393204569817,
      -0.07845041900873184,
      -0.046022359281778336,
      -0.008936249651014805,
      0.06679745763540268,
      0.03635602071881294,
      0.07027323544025421,
      0.053862690925598145,
      0.020537160336971283,
      0.0307957511395216,
      0.051876433193683624,
      -0.044490210711956024,
      0.03107558563351631,
      0.018482299521565437,
      0.02760740928351879,
      0.02678477019071579,
      0.04874233156442642,
      -0.008920194581151009,
      0.01657705381512642,
      0.006712203845381737,
      -0.02204616367816925,
      0.013715610839426517,
      -0.04942585527896881,
      0.017158668488264084,
      -0.0562971830368042,
      -0.00015688112762290984,
      0.009923762641847134,
      0.014341930858790874,
      0.035157375037670135,
      0.005011250730603933,
      -0.030407225713133812,
      0.03540961444377899,
      -0.037174537777900696,
      -0.027152488008141518,
      0.023111090064048767,
      -0.0246856939047575,
      0.021898217499256134,
      0.008514613844454288,
      -0.026544101536273956,
      -0.038669098168611526,
      0.014525307342410088,
      -0.0244771558791399,
      -0.0665784627199173,
      0.006141562946140766,
      0.014275777153670788,
      -0.010755099356174469,
      -0.010211312212049961,
      0.013077781535685062,
      -0.0058275493793189526,
      0.1012393981218338,
      0.06181317940354347,
      -0.005458334926515818,
      -0.03443719446659088,
      -0.05068725720047951,
      0.02488311007618904,
      0.024798287078738213,
      0.0006899447762407362,
      -0.0357811376452446,
      -0.027806991711258888,
      0.017593229189515114,
      -0.03857256844639778,
      0.027513086795806885,
      -0.004153199959546328,
      -0.022000448778271675,
      -0.0157597828656435,
      0.0031677838414907455,
      -0.05822140723466873,
      0.031982213258743286,
      -0.07636290043592453,
      -0.0051242634654045105,
      0.010824299417436123,
      -0.023498153313994408,
      -0.0016436942387372255,
      0.03198809549212456,
      0.07213246822357178,
      -0.004696906078606844,
      -0.04967731237411499,
      0.0073467749170959,
      0.014076088555157185,
      0.03802312910556793,
      0.047606732696294785,
      0.030615532770752907,
      0.054861899465322495,
      -0.02442231960594654,
      0.01153331808745861,
      0.0049075717106461525,
      0.03411151096224785,
      -0.014381458051502705,
      0.012389433570206165,
      -0.0005639560404233634,
      0.00868174061179161,
      -0.02177564613521099,
      0.04046507924795151,
      -0.06638278067111969,
      0.07145491987466812,
      0.03176165372133255,
      0.023024138063192368,
      -0.06573020666837692,
      -0.06895951181650162,
      0.014093700796365738,
      0.035518281161785126,
      -0.03673936426639557,
      0.01786705292761326,
      0.049714695662260056,
      -0.03544040396809578,
      0.040233511477708817,
      -0.029087262228131294,
      0.07099246978759766,
      -0.12915244698524475,
      0.04259789362549782,
      -0.02116520330309868,
      0.030965425074100494,
      0.006941999774426222,
      0.006802637595683336,
      0.09076938033103943,
      -0.002175015863031149,
      -0.023253308609128,
      0.05447433143854141,
      -0.04725942388176918,
      0.006033769808709621,
      0.08248382806777954,
      0.01966383308172226,
      -0.07345307618379593,
      -0.04802472144365311,
      0.09232261031866074,
      -0.02404741570353508,
      -0.011712986044585705,
      0.0016356128035113215,
      -0.09719179570674896,
      0.022448638454079628,
      0.00718642259016633,
      -0.052665241062641144,
      -0.06646749377250671,
      -0.0020836261101067066,
      0.022361891344189644,
      0.020018020644783974,
      0.09369337558746338,
      -0.048212483525276184,
      0.03839454799890518,
      0.10094624012708664,
      0.03466600179672241,
      0.08728887140750885,
      0.026730772107839584,
      -0.01340536680072546,
      -0.04801210016012192,
      0.0613655261695385,
      -0.01219400018453598,
      -0.005187986418604851,
      0.06402109563350677,
      0.02609912119805813,
      -0.034957434982061386,
      0.03342452272772789,
      -0.03855842351913452,
      -0.019327357411384583,
      -0.10509136319160461,
      0.058312319219112396,
      0.049227915704250336,
      0.0004538294451776892,
      -0.05565047264099121,
      -0.05717983469367027,
      -0.0007034340524114668,
      0.0055647981353104115,
      0.03889342024922371,
      -0.02456013858318329,
      0.01065472699701786,
      -0.004697294905781746,
      0.055348265916109085,
      -0.006228585261851549,
      0.008677366189658642,
      -0.03306666016578674,
      0.004098160658031702,
      0.013890406116843224,
      -0.0296435859054327,
      0.02125594951212406,
      -0.028970398008823395,
      0.026947978883981705,
      -0.012207060120999813,
      -0.022468868643045425,
      0.018081961199641228,
      -0.018746403977274895,
      -0.014058555476367474,
      0.05639953166246414,
      -0.09552674740552902,
      0.07089823484420776,
      -0.027050523087382317,
      0.007089861202985048,
      0.03464623540639877,
      0.05128072202205658,
      -0.05784333124756813,
      -0.013165623880922794,
      0.0063674552366137505,
      -0.01291426457464695,
      -0.020835217088460922,
      0.09511889517307281,
      0.011580493301153183,
      0.05384019389748573,
      -0.01728960871696472,
      0.04578794911503792,
      0.04281031712889671,
      -0.055406343191862106,
      -0.04336603358387947,
      0.07450760900974274,
      -0.061905305832624435,
      -0.009450986050069332,
      -0.007620434742420912,
      0.12272129952907562,
      0.03368533030152321,
      -0.05725971981883049,
      -0.07582370936870575,
      0.01929025538265705,
      -0.02631000429391861,
      -0.10647072643041611,
      0.006376089062541723,
      0.0035722292959690094,
      0.04443352296948433,
      0.012069612741470337,
      -0.06289948523044586,
      -0.02560839056968689,
      -0.004935786593705416,
      -0.054939307272434235,
      -0.01667332462966442,
      -0.04450686648488045,
      0.05742461606860161,
      -0.06898349523544312,
      0.05564567446708679,
      -0.05227811262011528,
      0.045465756207704544,
      0.003328186459839344,
      -0.0579557828605175,
      0.08163608610630035,
      0.042483918368816376,
      -0.010553347878158092,
      -0.017601240426301956,
      -0.010381683707237244,
      0.08876803517341614,
      -0.034943416714668274,
      0.11136778444051743,
      -0.05169615522027016,
      0.04729656130075455,
      -0.006835143081843853,
      0.011104324832558632,
      -0.004473004024475813,
      0.042331814765930176,
      -0.06827247142791748,
      -0.044560082256793976,
      -0.02738049253821373,
      0.08706643432378769,
      -0.0010190904140472412,
      -0.03691486269235611,
      -0.03829789534211159,
      -0.04871075227856636,
      -0.06145688146352768,
      0.00538351247087121,
      -0.01171008963137865,
      -0.002124706283211708,
      -0.0687265396118164,
      -0.045271553099155426,
      -0.09766574949026108,
      -0.026361605152487755,
      -0.04179541766643524,
      -0.1073855608701706,
      0.08904799073934555,
      -0.06977517902851105,
      0.02102588303387165,
      0.03682496398687363,
      -0.01709628663957119,
      -0.01449181605130434,
      0.028743667528033257,
      0.07383430004119873,
      0.048506006598472595,
      -0.015712443739175797,
      0.010675241239368916,
      0.012022220529615879
    ],
    [
      0.0992288663983345,
      -0.033177949488162994,
      -0.04667843133211136,
      0.0211967583745718,
      -0.0541238896548748,
      -0.0011224718764424324,
      0.09991420805454254,
      -0.020614348351955414,
      -0.02314307726919651,
      -0.039116084575653076,
      -0.0594688355922699,
      -0.0157930925488472,
      -0.06125442683696747,
      -0.05011372268199921,
      -0.06272156536579132,
      0.022075247019529343,
      0.008617797866463661,
      -0.09056088328361511,
      0.07718639075756073,
      -0.02821465954184532,
      0.051921240985393524,
      0.006272077094763517,
      -0.0517231822013855,
      -0.017972419038414955,
      -0.02448570914566517,
      -0.0828530415892601,
      -0.018080221489071846,
      0.028604907914996147,
      0.029856285080313683,
      0.003323235781863332,
      -0.09601377695798874,
      0.018095210194587708,
      -0.051523733884096146,
      -0.03625316545367241,
      -0.0069433655589818954,
      0.015891218557953835,
      -0.03484389930963516,
      -0.10282894968986511,
      0.03631013259291649,
      -0.04217886924743652,
      0.06348389387130737,
      -0.02484724298119545,
      -0.014991996809840202,
      0.01624404825270176,
      -0.05908315256237984,
      -0.002210845472291112,
      -0.05336715653538704,
      0.001890028128400445,
      0.06035173684358597,
      -0.008608527481555939,
      0.03110773302614689,
      -0.0006017032428644598,
      0.04547348991036415,
      0.059885039925575256,
      -0.01837215945124626,
      0.0242424588650465,
      0.010404327884316444,
      0.02799145318567753,
      0.01280756201595068,
      -0.01693139038980007,
      -0.009791013784706593,
      0.06102844700217247,
      0.06412233412265778,
      -0.04260293021798134,
      -0.018757030367851257,
      -0.007735591381788254,
      0.02822749689221382,
      0.0483376570045948,
      -0.04860297590494156,
      0.04316013678908348,
      0.06545830518007278,
      0.03258007392287254,
      0.02678884193301201,
      0.0536031499505043,
      -0.05758495256304741,
      -0.03135359287261963,
      -0.016057750210165977,
      0.015454201027750969,
      0.01666315086185932,
      0.11879934370517731,
      -0.01618325151503086,
      -0.05177909880876541,
      0.008416002616286278,
      -0.03152918815612793,
      0.007042113691568375,
      -0.0342249721288681,
      -0.025190498679876328,
      0.040891315788030624,
      0.03555363789200783,
      -0.07883638143539429,
      0.0011696682777255774,
      -0.002709666034206748,
      -0.02012374997138977,
      0.0869102030992508,
      0.08759666979312897,
      0.06303434818983078,
      -0.0634523555636406,
      -0.05238655209541321,
      0.016510358080267906,
      0.043807562440633774,
      0.007077970542013645,
      0.11325272172689438,
      -0.06580047309398651,
      0.00641214195638895,
      0.012681170366704464,
      -0.052683617919683456,
      -0.0036077871918678284,
      0.03205741196870804,
      0.025380024686455727,
      -0.015965597704052925,
      0.02368919737637043,
      -0.002615965437144041,
      0.03862140700221062,
      -0.04692528024315834,
      -0.06776056438684464,
      0.05125095322728157,
      0.011448887176811695,
      -0.019462130963802338,
      -0.006890521850436926,
      0.00354175828397274,
      -0.03602178394794464,
      -0.05058268830180168,
      -0.067085400223732,
      0.012781673111021519,
      -0.007559332996606827,
      0.03120438940823078,
      -0.04726897552609444,
      0.011876597069203854,
      0.021532291546463966,
      0.04664520174264908,
      -0.010673141106963158,
      -0.004269232973456383,
      -0.06885608285665512,
      0.018079601228237152,
      -0.017108187079429626,
      0.07886888831853867,
      0.05423973873257637,
      -0.009073098190128803,
      -0.01632404886186123,
      -0.02986457198858261,
      0.00423788744956255,
      0.008547657169401646,
      -0.05175194516777992,
      -0.04264499992132187,
      0.0937713012099266,
      -0.00210769847035408,
      0.014711679890751839,
      -0.01233273558318615,
      -0.054350610822439194,
      0.04292987659573555,
      0.0494145005941391,
      -0.011587951332330704,
      -0.07716654241085052,
      0.009899075143039227,
      0.041446246206760406,
      0.058860938996076584,
      0.0168528500944376,
      -0.024318473413586617,
      0.08534295856952667,
      0.059132497757673264,
      0.005392406601458788,
      0.0337979681789875,
      0.06890929490327835,
      0.0006549321115016937,
      -0.022336607798933983,
      0.026861155405640602,
      -0.010428151115775108,
      -0.04741470888257027,
      0.05902545154094696,
      0.07268787175416946,
      -0.06639489531517029,
      -0.012319762259721756,
      -0.07878798246383667,
      -0.037997711449861526,
      -0.0035056041087955236,
      -0.0025727658066898584,
      0.029453763738274574,
      0.018818477168679237,
      -0.0565696656703949,
      0.04998036473989487,
      -0.05295761302113533,
      -0.030665721744298935,
      0.0663895308971405,
      0.01670640893280506,
      -0.004547433461993933,
      -0.0250567514449358,
      -0.00276199821382761,
      0.011179380118846893,
      0.008151128888130188,
      -0.05958558991551399,
      0.012646837159991264,
      0.01459119375795126,
      0.026986850425601006,
      -0.04872212931513786,
      0.01988993026316166,
      0.014229421503841877,
      0.041431132704019547,
      -0.014387747272849083,
      0.06990204751491547,
      0.05412625893950462,
      0.06891308724880219,
      -0.00614209333434701,
      -0.02581791579723358,
      0.06456954032182693,
      -0.017739014700055122,
      0.013592341914772987,
      0.06393884867429733,
      -0.05288498476147652,
      -0.007464670576155186,
      0.05480856075882912,
      -0.0842011570930481,
      -0.1285923719406128,
      0.004643714986741543,
      0.03531637042760849,
      -0.019380858168005943,
      0.06561874598264694,
      0.05692164972424507,
      -0.07245740294456482,
      -0.026175029575824738,
      -0.06544215232133865,
      0.08588345348834991,
      0.027155861258506775,
      0.07442968338727951,
      -0.03694593533873558,
      -0.08576350659132004,
      -0.04871639236807823,
      0.01114879734814167,
      -0.025346241891384125,
      0.02458510361611843,
      -0.016483282670378685,
      0.042157191783189774,
      0.013088874518871307,
      -0.035361792892217636,
      -0.01783391460776329,
      0.025130771100521088,
      0.055906228721141815,
      0.0783710703253746,
      0.07766398787498474,
      -0.03662455454468727,
      0.04739346727728844,
      -0.007103273645043373,
      0.05378761887550354,
      -0.06122229993343353,
      -0.013671165332198143,
      0.0619095116853714,
      -0.006070425268262625,
      0.003161446889862418,
      0.06894088536500931,
      -0.03747418522834778,
      -0.011438199318945408,
      -0.05322251468896866,
      -0.05479055643081665,
      0.03050251677632332,
      -0.017101803794503212,
      -0.011543539352715015,
      0.023895392194390297,
      0.017842240631580353,
      0.03553682938218117,
      -0.004797465167939663,
      0.04141834378242493,
      -0.022747691720724106,
      0.09624770283699036,
      0.0187662485986948,
      0.06662343442440033,
      0.058238063007593155,
      -0.02049052156507969,
      -0.03973618522286415,
      -0.027102217078208923,
      -0.07447634637355804,
      -0.028579339385032654,
      0.04477490857243538,
      -0.018916722387075424,
      -0.012570226565003395,
      -0.02436206117272377,
      0.004103625658899546,
      -0.03324505314230919,
      0.04262327030301094,
      0.059392884373664856,
      -0.0737171396613121,
      -0.0428018756210804,
      0.12150643020868301,
      0.04422779008746147,
      -0.02038702741265297,
      -0.05838985741138458,
      -0.04478912055492401,
      0.013970225118100643,
      0.016381995752453804,
      -0.03436434641480446,
      -0.029730338603258133,
      -0.050048019737005234,
      -0.022589996457099915,
      -0.045640915632247925,
      -0.03487071394920349,
      0.02164437063038349,
      -0.06649462133646011,
      -0.09094146639108658,
      0.0351729653775692,
      -0.024717802181839943,
      0.052282851189374924,
      -0.029188135638833046,
      -0.034006517380476,
      -0.05569618567824364,
      0.15426404774188995,
      -0.027724800631403923,
      -0.06409759819507599,
      0.008281653746962547,
      -0.03932856768369675,
      -0.009832095354795456,
      0.005278481636196375,
      0.020652078092098236,
      -0.0232092197984457,
      -0.0027058154810220003,
      0.028202231973409653,
      0.002590636257082224,
      -0.06093984469771385,
      0.06202126294374466,
      -0.07684789597988129,
      -0.08275947719812393,
      0.05930338054895401,
      0.09767312556505203,
      -0.02377181686460972,
      -0.04053894057869911,
      -0.05142204836010933,
      -0.06201997026801109,
      -0.005204638931900263,
      -0.024173717945814133,
      0.04422498121857643,
      -0.019853340461850166,
      0.05188315734267235,
      -0.05013637617230415,
      -0.0026355981826782227,
      0.017991160973906517,
      0.012476020492613316,
      0.06026190519332886,
      -0.053449563682079315,
      0.052104245871305466,
      -0.015070858411490917,
      0.009663714095950127,
      -0.054750438779592514,
      0.015635928139090538,
      -0.020790403708815575,
      0.07967349141836166,
      -0.0512792244553566,
      0.05906660854816437,
      -0.010463790036737919,
      -0.024016838520765305,
      0.041921596974134445,
      -0.010872640646994114,
      -0.09698336571455002,
      -0.010969669558107853,
      -0.07680979371070862,
      -0.041797444224357605,
      0.018600311130285263,
      -0.022899262607097626,
      0.0273341853171587,
      0.009075649082660675,
      0.05935407057404518,
      -0.03821302577853203,
      0.01351509615778923,
      -0.06440813094377518,
      0.0506182499229908,
      0.03860960900783539,
      -0.011685539968311787,
      -0.04421958327293396,
      0.01991422474384308,
      0.02138119749724865,
      -0.05566556379199028,
      0.02255810610949993,
      -0.012418247759342194,
      0.01987183280289173,
      0.006859926041215658,
      -0.02182689867913723,
      0.05717996507883072,
      0.062315355986356735,
      -0.020165439695119858,
      0.004395908210426569,
      0.0012931630481034517,
      0.0656992718577385,
      0.030976969748735428,
      -0.008786140941083431,
      -0.05737825483083725,
      0.0005793471937067807,
      -0.046295009553432465,
      -0.01412156131118536,
      0.0040902066975831985,
      -0.02560490369796753,
      -0.059122778475284576,
      0.03601561114192009,
      -0.037229739129543304,
      0.036252714693546295,
      -0.03436289727687836,
      -0.023520290851593018,
      -0.05955905467271805,
      0.05087696760892868,
      0.07447241991758347,
      0.06618952751159668,
      -0.004861918278038502,
      0.04549859091639519,
      -0.03124798834323883,
      0.02669147215783596,
      0.009300515986979008,
      0.013089893385767937,
      -0.012031011283397675,
      -0.05774807929992676,
      -0.0015282768290489912,
      0.04609162360429764,
      0.01631249114871025,
      -0.0011971561471000314,
      0.04355400800704956,
      0.02225378341972828,
      0.07880686968564987,
      -0.03262808546423912,
      0.04857378825545311,
      -0.05727691948413849,
      0.03181437775492668,
      -0.020484428852796555,
      -0.024305710569024086,
      -0.014023073948919773,
      0.140639528632164,
      0.02101798728108406,
      0.05638614296913147,
      0.016806412488222122,
      -0.03352859243750572,
      -0.010781563818454742,
      0.0662769228219986,
      -0.022117482498288155,
      -0.023057274520397186,
      -0.026227260008454323,
      0.03197552636265755,
      -0.06341129541397095,
      0.017420314252376556,
      0.0552954375743866,
      -0.0020061610266566277,
      -0.00746699096634984,
      0.014753484167158604,
      -0.0059349313378334045,
      -0.05249319225549698,
      -0.000600853469222784,
      0.07038790732622147,
      -0.05242794007062912,
      0.06406554579734802,
      -0.06366419047117233,
      -0.023718539625406265,
      -0.007068886887282133,
      -0.0005090923514217138,
      -0.0023694843985140324,
      -0.010881264694035053,
      -0.00031641198438592255,
      -0.016072668135166168,
      -0.043434467166662216,
      0.016104845330119133,
      0.04739702492952347,
      0.08126531541347504,
      -0.036496903747320175,
      -0.026056667789816856,
      -0.07835245132446289,
      0.06156742572784424,
      -0.04320907965302467,
      0.04423163831233978,
      -0.029305491596460342,
      0.03648483008146286,
      -0.043232597410678864,
      0.08068730682134628,
      0.012134955264627934,
      0.026447195559740067,
      -0.01544791180640459,
      -0.0037941501941531897,
      -0.042558472603559494,
      0.05726366117596626,
      -0.06582300364971161,
      0.031529366970062256,
      0.03804875165224075,
      -0.1217958927154541,
      -0.03816891089081764,
      0.0040767439641058445,
      0.00011570489732548594,
      0.0183973740786314,
      0.009458516724407673,
      -0.04429866746068001,
      0.028743809089064598,
      0.0920715406537056,
      0.003582481062039733,
      -0.023011941462755203,
      -0.02589470148086548,
      0.03467302769422531,
      -0.009401545859873295,
      -0.00027863436844199896,
      -0.09022267907857895,
      -0.049825455993413925,
      -0.014511557295918465,
      -0.020322559401392937,
      -0.026422612369060516,
      0.029650921002030373,
      -0.0462910421192646,
      0.03993847221136093,
      -0.002018250059336424,
      0.0803728774189949,
      0.06593499332666397,
      0.0035469636786729097,
      -0.029328132048249245,
      0.012022317387163639,
      -0.10093116015195847,
      -0.1082383319735527
    ],
    [
      0.022026434540748596,
      -0.012061032466590405,
      0.04531870037317276,
      0.03139340877532959,
      -0.014919925481081009,
      0.05822983384132385,
      0.026130182668566704,
      0.05665986239910126,
      -0.13104833662509918,
      0.015270781703293324,
      0.03463655710220337,
      -0.030600694939494133,
      -0.05973442271351814,
      0.009459586814045906,
      0.031295061111450195,
      0.00020376523025333881,
      -0.054125286638736725,
      0.021729860454797745,
      -0.033680785447359085,
      -0.050904784351587296,
      0.03550216928124428,
      0.039018020033836365,
      0.024230200797319412,
      0.03616772219538689,
      0.0007218193495646119,
      -0.020651934668421745,
      0.05554341897368431,
      -0.06451398134231567,
      -0.0698077529668808,
      -0.02304917387664318,
      0.0018945833435282111,
      0.08088964968919754,
      -0.0001983038237085566,
      -0.023473696783185005,
      0.0012143014464527369,
      0.040694523602724075,
      0.05699484050273895,
      0.0027005141600966454,
      -0.0265984907746315,
      -0.005498362705111504,
      0.019965162500739098,
      0.06150690093636513,
      -0.047958776354789734,
      -0.023424260318279266,
      -0.043839480727910995,
      0.03220255300402641,
      -0.02085110731422901,
      -0.029908640310168266,
      0.05980798229575157,
      -0.05693536624312401,
      -0.056882914155721664,
      -0.06776200979948044,
      0.0072185746394097805,
      -0.06434560567140579,
      0.05088669806718826,
      -0.05499718710780144,
      -0.04198499396443367,
      -0.04019122198224068,
      0.0029824997764080763,
      0.03177196532487869,
      -0.05826755240559578,
      0.02615988440811634,
      0.08763057738542557,
      0.05139728635549545,
      -0.00825860258191824,
      -0.06775431334972382,
      0.029018713161349297,
      0.008159120567142963,
      0.039793066680431366,
      -0.008378537371754646,
      -0.016094332560896873,
      -0.005662281531840563,
      0.005927308928221464,
      -0.06552700698375702,
      -0.0012915786355733871,
      -0.03052297979593277,
      0.000840145570691675,
      -0.0058441441506147385,
      0.03471483290195465,
      -0.012593449093401432,
      0.08289511501789093,
      -0.03420708328485489,
      -0.03306601196527481,
      -0.014466337859630585,
      -0.030644338577985764,
      -0.03475562483072281,
      -0.023086391389369965,
      -0.004717603325843811,
      -0.05088191106915474,
      -0.010803516022861004,
      -0.012931796722114086,
      -0.042397283017635345,
      0.04160076752305031,
      -0.029682232066988945,
      0.04455287382006645,
      0.026118619367480278,
      -0.0227613914757967,
      -0.06324496120214462,
      -0.005903518758714199,
      0.005779892671853304,
      -0.04690464586019516,
      -0.003086439101025462,
      -0.015177772380411625,
      0.041458357125520706,
      -0.050181951373815536,
      -0.06527446210384369,
      0.065970778465271,
      -0.042537834495306015,
      0.022278161719441414,
      0.03346235305070877,
      0.04729650542140007,
      0.010191814042627811,
      0.02360687591135502,
      -0.010084864683449268,
      -0.07662417739629745,
      -0.006323990412056446,
      0.02841223031282425,
      -0.06606504321098328,
      -0.007978633977472782,
      0.003744587069377303,
      -0.011683153919875622,
      -0.0014574916567653418,
      -0.04137739911675453,
      -0.004736878909170628,
      -0.02931450679898262,
      0.04058601334691048,
      0.052587490528821945,
      -0.02407226152718067,
      0.06310466676950455,
      0.07583916187286377,
      -0.0034476404543966055,
      0.0300337765365839,
      0.024497544392943382,
      0.08148564398288727,
      0.02887321077287197,
      0.11109007894992828,
      0.06545604765415192,
      0.0288394745439291,
      -0.05536555498838425,
      -0.06038783863186836,
      0.03640948608517647,
      0.0005086966557428241,
      -0.059141386300325394,
      -0.00437947129830718,
      -0.013917182572185993,
      0.0012174182338640094,
      0.05617319419980049,
      0.0103184524923563,
      -0.035040855407714844,
      0.06828512996435165,
      0.07646213471889496,
      -0.053585462272167206,
      -0.004728194326162338,
      0.033696845173835754,
      -0.059851888567209244,
      -0.005464581772685051,
      -0.018026720732450485,
      -0.0610818937420845,
      -0.05070287361741066,
      0.0347585454583168,
      0.03150501474738121,
      0.03312733769416809,
      -0.01367399375885725,
      0.05854795128107071,
      0.03265182301402092,
      0.05196951702237129,
      0.049485743045806885,
      0.04907684773206711,
      -0.0003691874735523015,
      0.009921508841216564,
      -0.061109911650419235,
      0.008086166344583035,
      -0.0741858258843422,
      0.04857789725065231,
      0.005488571245223284,
      -0.057628288865089417,
      0.002689483342692256,
      -0.11958668380975723,
      0.03408791869878769,
      -0.010726078413426876,
      -0.07856225967407227,
      0.030438754707574844,
      -0.0468929149210453,
      0.03083045966923237,
      0.04763362556695938,
      0.07633726298809052,
      -0.012201990932226181,
      -0.036931224167346954,
      0.02162332646548748,
      0.014613199047744274,
      0.06554216146469116,
      -0.0014848283026367426,
      -0.035858072340488434,
      -0.007381841074675322,
      0.0051924241706728935,
      0.14030878245830536,
      0.03183310106396675,
      -0.006787458900362253,
      0.020435262471437454,
      -0.018298331648111343,
      -0.0171962920576334,
      -0.0699797198176384,
      -0.007362985052168369,
      0.05518775433301926,
      0.03411165997385979,
      -0.022749600932002068,
      0.05577893182635307,
      0.06354932487010956,
      0.030274933204054832,
      0.025884050875902176,
      -0.002907893154770136,
      0.010089836083352566,
      0.02925855666399002,
      0.06314953416585922,
      0.04769424721598625,
      0.010900837369263172,
      0.0073112198151648045,
      0.012605918571352959,
      0.024565409868955612,
      0.01970919966697693,
      -0.040234267711639404,
      0.04789291322231293,
      0.006543758325278759,
      -0.1296655237674713,
      0.03440658748149872,
      0.10202553868293762,
      -0.04959499090909958,
      0.0034695654176175594,
      0.06133204698562622,
      -0.029515402391552925,
      -0.02877536229789257,
      0.09344959259033203,
      0.00496365362778306,
      0.049468252807855606,
      0.0041654170490801334,
      -0.022738300263881683,
      -0.06598888337612152,
      0.023396868258714676,
      0.024607080966234207,
      0.01981130987405777,
      0.03126601129770279,
      0.052080463618040085,
      -0.044367458671331406,
      -0.01314887497574091,
      0.03984194993972778,
      -0.023006565868854523,
      0.03020063228905201,
      -0.011536900885403156,
      0.0918678343296051,
      -0.0188121497631073,
      0.08864577859640121,
      -0.09285040199756622,
      -0.012994171120226383,
      0.04499244689941406,
      -0.013572918251156807,
      0.017451880499720573,
      -0.041038878262043,
      0.010317151434719563,
      0.020766491070389748,
      -0.045390550047159195,
      0.01710490882396698,
      -0.058453526347875595,
      -0.0017465836135670543,
      -0.04743831232190132,
      0.02365858480334282,
      0.01870444044470787,
      0.022996656596660614,
      0.039404574781656265,
      0.03324888274073601,
      -0.005206832196563482,
      0.026927068829536438,
      0.03993798792362213,
      -0.022509722039103508,
      -0.1061185970902443,
      -0.03855004534125328,
      0.048162367194890976,
      0.009401071816682816,
      -0.04125493764877319,
      0.03054066002368927,
      0.04630759358406067,
      0.0023615604732185602,
      0.0017440983792766929,
      0.017279690131545067,
      -0.046253085136413574,
      -0.0074853175319731236,
      -0.008612782694399357,
      0.07727137953042984,
      -0.005708697717636824,
      -0.0031244205310940742,
      0.013225632719695568,
      0.029784325510263443,
      0.008103568106889725,
      -0.045409515500068665,
      -0.03393549099564552,
      -0.025709547102451324,
      0.05776156857609749,
      0.031189264729619026,
      0.0008897881489247084,
      -0.0037255818024277687,
      0.036373380571603775,
      0.007989008910953999,
      -0.030855681747198105,
      -0.04433843493461609,
      -0.02164909988641739,
      0.004816120956093073,
      0.008341987617313862,
      -0.027039026841521263,
      -0.0040237270295619965,
      -0.036216817796230316,
      -0.005225926637649536,
      0.10842665284872055,
      0.023107852786779404,
      -0.06902968138456345,
      0.034827254712581635,
      0.06177147477865219,
      0.008711153641343117,
      0.020120671018958092,
      -0.0070509398356080055,
      -0.0020544715225696564,
      -0.01273684948682785,
      -0.0895451083779335,
      0.03785167261958122,
      -0.016264399513602257,
      -0.029574939981102943,
      0.1354147344827652,
      -0.06285591423511505,
      0.016648847609758377,
      -0.018119079992175102,
      0.016091598197817802,
      -0.05554870888590813,
      0.052595846354961395,
      0.0022802266757935286,
      -0.030936527997255325,
      0.026394600048661232,
      -0.035537708550691605,
      0.0023214302491396666,
      -0.025754151865839958,
      -0.03390125930309296,
      -0.01024313922971487,
      -0.02532440796494484,
      -0.008109251968562603,
      0.0019220889080315828,
      -0.036025986075401306,
      0.03216150775551796,
      -0.06596756726503372,
      0.007202364969998598,
      0.008571591228246689,
      -0.05558274686336517,
      0.07706978917121887,
      0.10422731190919876,
      -0.025738857686519623,
      -0.11999247968196869,
      -0.002536902902647853,
      -0.13285709917545319,
      0.01924911141395569,
      0.00801465380936861,
      -0.04866545647382736,
      -0.004094450268894434,
      -0.04862761124968529,
      -0.08947266638278961,
      0.020272094756364822,
      0.04512154683470726,
      0.03425244987010956,
      -0.04783276468515396,
      0.00019135251932311803,
      0.03241167217493057,
      -0.03493699058890343,
      -0.03751305490732193,
      -0.05741509795188904,
      -0.026581134647130966,
      -0.013871069997549057,
      -0.017866112291812897,
      0.011201590299606323,
      -0.03649359941482544,
      -0.0586218386888504,
      -0.014069115743041039,
      -0.04088358208537102,
      0.015309986658394337,
      -0.09014517813920975,
      -0.05331080034375191,
      0.020507289096713066,
      0.046566762030124664,
      0.1256551891565323,
      -0.03505764901638031,
      0.0081206439062953,
      -0.010398446582257748,
      -0.03215637430548668,
      0.012139917351305485,
      0.049756232649087906,
      -0.006477516144514084,
      0.0435764342546463,
      -0.06729348003864288,
      0.00791192427277565,
      0.005048234015703201,
      0.08633124828338623,
      -0.08773448318243027,
      -0.04695320874452591,
      -0.02851412631571293,
      0.06787768751382828,
      0.056435905396938324,
      0.06216207146644592,
      0.004517612513154745,
      0.03618113324046135,
      -0.034044425934553146,
      -0.035966187715530396,
      0.04888506233692169,
      -0.07474113255739212,
      -0.023506129160523415,
      -0.026926496997475624,
      -0.016243472695350647,
      -0.024586042389273643,
      0.017741302028298378,
      -0.0060079460963606834,
      0.027714628726243973,
      0.027666939422488213,
      -0.031073305755853653,
      0.029014725238084793,
      0.03282807022333145,
      0.04765057563781738,
      0.008421281352639198,
      0.06300870329141617,
      0.022500071674585342,
      -0.03271186724305153,
      -0.05951114371418953,
      0.013363761827349663,
      0.0006708464352414012,
      0.012061475776135921,
      0.06884410977363586,
      0.007373777683824301,
      -0.005226220935583115,
      -0.036342862993478775,
      -0.026268476620316505,
      -0.0060843355022370815,
      0.004911343101412058,
      0.021210137754678726,
      -0.036893606185913086,
      0.10398546606302261,
      -0.04788465425372124,
      0.007029630709439516,
      0.008469930849969387,
      -0.07375260442495346,
      0.022641805931925774,
      -0.02681596577167511,
      -0.01190699078142643,
      0.023919513449072838,
      -0.013094620779156685,
      -0.004736203700304031,
      0.06509241461753845,
      -0.010169532150030136,
      0.0010429994435980916,
      0.04665485396981239,
      0.010458488948643208,
      -0.09469270706176758,
      -0.06618808209896088,
      0.07771864533424377,
      0.006412000861018896,
      0.01586887240409851,
      -0.011172735132277012,
      -0.03240244835615158,
      -0.0375688411295414,
      -0.07392793893814087,
      -0.024942416697740555,
      -0.0044396244920790195,
      0.10511928051710129,
      0.025876525789499283,
      0.050534043461084366,
      0.047222066670656204,
      0.0065136440098285675,
      0.09709222614765167,
      0.04232272133231163,
      0.038082223385572433,
      0.03637394681572914,
      0.05536554008722305,
      0.008291679434478283,
      0.05557212606072426,
      0.01602349430322647,
      -0.00901094265282154,
      -0.0163421668112278,
      0.03550616279244423,
      0.10811083018779755,
      0.001854748115874827,
      0.03459363430738449,
      -0.03685363382101059,
      0.06500517576932907,
      0.013371401466429234,
      -0.012886100448668003,
      0.007143718656152487,
      0.030013173818588257,
      -0.06233277916908264,
      0.01648225262761116,
      -0.051505740731954575,
      -0.06532207876443863,
      -0.045503463596105576,
      -0.009919530712068081,
      -0.03499515727162361,
      -0.016079779714345932,
      -0.08315282315015793,
      0.005722620990127325,
      0.03702407330274582,
      0.06969019770622253,
      0.019580772146582603,
      0.04735288396477699,
      -0.04560153931379318
    ],
    [
      -0.006771065294742584,
      -0.0025012472178786993,
      0.02085038833320141,
      -0.00038123936974443495,
      0.013901246711611748,
      0.02653263695538044,
      0.059381548315286636,
      0.001998189138248563,
      -0.002960953628644347,
      0.00482588168233633,
      -0.00821474939584732,
      0.011832416988909245,
      -0.03263844922184944,
      0.04050253704190254,
      -0.03648892790079117,
      0.0011647578794509172,
      0.04270639643073082,
      0.045306649059057236,
      0.0751960501074791,
      0.03657104820013046,
      -0.015145349316298962,
      0.0006492206593975425,
      0.008012587204575539,
      -0.040622878819704056,
      0.08192269504070282,
      -0.00910816341638565,
      -0.0006929856608621776,
      -0.01941291242837906,
      0.06073855608701706,
      0.04469671472907066,
      -0.0010833310661837459,
      0.10645471513271332,
      0.07309869676828384,
      -0.10042870789766312,
      0.031955961138010025,
      0.05437484383583069,
      -0.04650595784187317,
      -0.05286895111203194,
      -0.07700146734714508,
      -0.004962055012583733,
      -0.017449945211410522,
      0.006786399520933628,
      -0.054375819861888885,
      -0.03229992836713791,
      0.0429520308971405,
      -0.06158082187175751,
      -0.0006070835515856743,
      0.10255834460258484,
      0.08345372974872589,
      0.03404057398438454,
      0.0031176453921943903,
      -0.04882577434182167,
      0.04321914166212082,
      0.005616312380880117,
      0.00736677460372448,
      -0.01464821957051754,
      -0.04711513593792915,
      -0.02406683936715126,
      0.011606897227466106,
      -0.01305402535945177,
      0.04587442800402641,
      0.03289126977324486,
      0.0673283040523529,
      -0.003501670202240348,
      -0.01967122033238411,
      -0.0026224052999168634,
      0.020681116729974747,
      0.06170085445046425,
      0.06400354206562042,
      -0.006031243596225977,
      0.028942184522747993,
      0.0009978674352169037,
      0.056889813393354416,
      0.037532057613134384,
      -0.02424474246799946,
      0.0026814332231879234,
      -0.019576802849769592,
      0.013662940822541714,
      0.020217284560203552,
      0.037105727940797806,
      0.051706913858652115,
      -0.01301624346524477,
      0.00047756286221556365,
      -0.06095798686146736,
      0.051483117043972015,
      -0.004126702900975943,
      0.04353049769997597,
      0.0028205786366015673,
      0.01988987997174263,
      0.0317363403737545,
      -0.0011949320323765278,
      -0.011760763823986053,
      0.010163397528231144,
      -0.01852422207593918,
      -0.07614079862833023,
      -0.013892322778701782,
      0.04386860877275467,
      0.03421337157487869,
      -0.051297880709171295,
      -0.009092762134969234,
      0.0528811477124691,
      -0.018396904692053795,
      -0.0017303820932283998,
      -0.009869560599327087,
      0.05696091800928116,
      -0.0864374190568924,
      -0.004118602257221937,
      0.017331279814243317,
      -0.10291831940412521,
      -0.01434436533600092,
      -0.002789984690025449,
      0.017041409388184547,
      0.003484277054667473,
      0.02874862775206566,
      0.0288454070687294,
      0.016943400725722313,
      0.037323322147130966,
      -0.019798556342720985,
      0.0010705689201131463,
      0.04725398123264313,
      0.026248296722769737,
      -0.07241339236497879,
      0.0033045115415006876,
      0.026156079024076462,
      -0.006977231707423925,
      -0.02276529185473919,
      -0.01555743720382452,
      0.014178885146975517,
      -0.022428233176469803,
      0.05353058874607086,
      -0.011773633770644665,
      -0.09947144985198975,
      0.032788295298814774,
      -0.047204192727804184,
      0.035561248660087585,
      -0.004779767710715532,
      -0.0587417297065258,
      -0.04327545315027237,
      0.021235158666968346,
      -0.03949747979640961,
      0.05372020974755287,
      0.0836738869547844,
      0.07145904004573822,
      0.008964101783931255,
      -0.0009850409114733338,
      0.01269921101629734,
      0.03259189426898956,
      -0.009468711912631989,
      -0.0076502407900989056,
      -0.009806564077734947,
      -0.003178621642291546,
      -0.04780038446187973,
      -0.05888282135128975,
      0.01998901553452015,
      -0.011329704895615578,
      0.00033484469167888165,
      -0.020748833194375038,
      -0.05135728791356087,
      -0.005760886240750551,
      -0.01468575093895197,
      -0.08003256469964981,
      0.05265192314982414,
      0.005495174787938595,
      0.07457096874713898,
      0.025121761485934258,
      -0.027166886255145073,
      0.07323699444532394,
      -0.013632784597575665,
      -0.017738934606313705,
      -0.014124752953648567,
      -0.06772788614034653,
      0.037558663636446,
      -0.06076480820775032,
      0.14261747896671295,
      -0.006235355045646429,
      0.036334555596113205,
      0.04421481490135193,
      -0.012269728817045689,
      0.026983240619301796,
      -0.07166528701782227,
      0.016804000362753868,
      -0.0032107927836477757,
      -0.007853738032281399,
      -0.04386487975716591,
      0.09025045484304428,
      -0.0011347747640684247,
      -0.031029075384140015,
      0.09073532372713089,
      0.04030582308769226,
      0.002441397635266185,
      -0.03244532272219658,
      0.06060454994440079,
      -0.020929068326950073,
      0.027450857684016228,
      -0.0913856253027916,
      0.060381434857845306,
      -0.038400352001190186,
      0.029341189190745354,
      0.0142657570540905,
      0.04645506292581558,
      -0.07785994559526443,
      0.005958765745162964,
      -0.11180923879146576,
      0.027323473244905472,
      -0.010744553990662098,
      -0.01210789941251278,
      -0.047399040311574936,
      -0.05967075750231743,
      0.0004165604477748275,
      -0.024370934814214706,
      0.010844291187822819,
      -0.02309962920844555,
      0.008745213970541954,
      -0.0011045251740142703,
      0.08737805485725403,
      -0.019939320161938667,
      0.0714772492647171,
      -0.029328716918826103,
      -0.003431106684729457,
      0.05361267179250717,
      0.038784731179475784,
      -0.015164701268076897,
      -0.02870313636958599,
      0.03803832456469536,
      0.021767709404230118,
      -0.017607057467103004,
      -0.019060127437114716,
      -0.011481369845569134,
      0.0586225688457489,
      0.005779393948614597,
      0.005643969867378473,
      -0.025828475132584572,
      0.046280838549137115,
      -0.07604242861270905,
      -0.02569984644651413,
      0.039381228387355804,
      -0.002724581630900502,
      -0.03925026208162308,
      -0.009477349929511547,
      0.05772899463772774,
      -0.01909726858139038,
      -0.006769943982362747,
      0.0010114334290847182,
      -0.06092293933033943,
      -0.0607692115008831,
      0.01970626972615719,
      -0.0007352152024395764,
      -0.013141241855919361,
      -0.01753576658666134,
      -0.04155325889587402,
      -0.02372383140027523,
      0.01440451480448246,
      0.019687557592988014,
      -0.0868862196803093,
      -0.036440324038267136,
      -0.029656769707798958,
      0.06359999626874924,
      -0.044546741992235184,
      -0.06801741570234299,
      -0.057139720767736435,
      0.010258647613227367,
      -0.013909800909459591,
      -0.04076620563864708,
      -0.0108862379565835,
      0.05179271101951599,
      0.018075713887810707,
      -0.014062867499887943,
      0.055611737072467804,
      -0.02312644012272358,
      0.017995789647102356,
      0.05754584074020386,
      0.024305932223796844,
      0.00044855146552436054,
      -0.04529637470841408,
      0.05143235623836517,
      -0.03274838253855705,
      -0.029594196006655693,
      -0.014874300919473171,
      0.06134432181715965,
      0.01911630667746067,
      -0.11277233809232712,
      0.022440893575549126,
      -0.020350446924567223,
      -0.002302116248756647,
      0.04204922914505005,
      -0.045684315264225006,
      -0.007382651790976524,
      -0.03153877332806587,
      0.00956758577376604,
      0.0029886215925216675,
      -0.020578458905220032,
      0.014668685384094715,
      0.08438112586736679,
      -0.053639162331819534,
      0.08481036126613617,
      -0.0058362167328596115,
      -0.08636422455310822,
      -0.0020547043532133102,
      -0.0188111811876297,
      -0.0047204941511154175,
      -0.01837613806128502,
      0.028781363740563393,
      0.02327626198530197,
      -0.08434958010911942,
      0.0071312966756522655,
      -0.035824649035930634,
      -0.05919114500284195,
      0.012822031043469906,
      0.03148367628455162,
      0.009657615795731544,
      -0.033153094351291656,
      -0.02213665284216404,
      -0.01681751012802124,
      0.03710051625967026,
      0.004712644498795271,
      -0.004277005326002836,
      0.04673106595873833,
      -0.03174209222197533,
      -0.039232052862644196,
      0.05964181572198868,
      0.008099716156721115,
      0.023384103551506996,
      -0.01924736425280571,
      0.009258289821445942,
      -0.01977379247546196,
      0.021565835922956467,
      0.017276911064982414,
      0.009600047022104263,
      0.030897609889507294,
      -0.09237947314977646,
      0.08644445985555649,
      -0.027548950165510178,
      0.008061203174293041,
      -0.01608503982424736,
      0.018020061776041985,
      0.021611247211694717,
      -0.058281585574150085,
      0.050527174025774,
      -0.0038580815307796,
      0.017506683245301247,
      0.02168615534901619,
      0.04072434455156326,
      0.043174244463443756,
      0.003273569978773594,
      -0.07886581122875214,
      0.02303818054497242,
      -0.0030870309565216303,
      -0.023747839033603668,
      -0.04627449810504913,
      -0.059823766350746155,
      0.02116786502301693,
      -0.030497310683131218,
      0.00223156763240695,
      -0.015314474701881409,
      -0.014367285184562206,
      0.016479121521115303,
      -0.054110053926706314,
      -0.01211096066981554,
      -0.00038328536902554333,
      0.035263076424598694,
      -0.008460220880806446,
      0.05925426259636879,
      0.02060215175151825,
      -0.015430118888616562,
      0.03458220139145851,
      -0.038516536355018616,
      0.01112673431634903,
      0.029399044811725616,
      -0.006312022916972637,
      0.05204136297106743,
      0.09204856306314468,
      0.027090279385447502,
      -0.04953990504145622,
      -0.023895271122455597,
      -0.048631131649017334,
      0.02572433277964592,
      0.012104162946343422,
      0.07083804905414581,
      -0.049534834921360016,
      0.0012700071092694998,
      0.0165350753813982,
      0.029420871287584305,
      -0.007365543860942125,
      0.0528017021715641,
      0.015301124192774296,
      0.053280025720596313,
      0.012698886916041374,
      -0.00855296477675438,
      0.03728323057293892,
      0.04340577870607376,
      -0.010393991135060787,
      -0.024964282289147377,
      -0.012159484438598156,
      0.008471705950796604,
      -0.021649671718478203,
      0.030089467763900757,
      -0.0009031461086124182,
      -0.0051546101458370686,
      0.05538886412978172,
      -0.00833047740161419,
      -0.043540943413972855,
      0.017800014466047287,
      0.005829302594065666,
      0.006991668604314327,
      -0.05919526517391205,
      0.017076531425118446,
      0.032882433384656906,
      0.016539083793759346,
      0.04687417671084404,
      -0.00025890461984090507,
      -0.06557957828044891,
      -0.04667121171951294,
      -0.044323671609163284,
      0.03257426247000694,
      -0.03207067772746086,
      0.023357732221484184,
      -0.061354879289865494,
      0.03130071982741356,
      0.009841240011155605,
      -0.013443084433674812,
      0.05284538120031357,
      0.005404247902333736,
      -0.025997115299105644,
      0.07812327891588211,
      0.024115778505802155,
      0.024869753047823906,
      -0.02311057224869728,
      0.007698095869272947,
      0.03782837837934494,
      -0.11512751132249832,
      -0.014175022020936012,
      0.0145148616284132,
      -0.0007885214872658253,
      -0.01798776537179947,
      0.015445779077708721,
      0.03750724717974663,
      -0.01682138629257679,
      -0.01618533581495285,
      0.073361836373806,
      -0.09172803163528442,
      0.06918983161449432,
      -0.008157383650541306,
      -0.05441444739699364,
      -0.030107660219073296,
      -0.009769209660589695,
      -0.03172426298260689,
      0.025346221402287483,
      0.05993717163801193,
      0.02063230238854885,
      -0.0191277377307415,
      0.045767828822135925,
      -0.04039033502340317,
      0.010614821687340736,
      -0.025792185217142105,
      -0.007461939938366413,
      0.02330615557730198,
      -0.018808307126164436,
      0.04959847033023834,
      0.026209013536572456,
      -0.07196030765771866,
      -0.08284525573253632,
      -0.028452947735786438,
      0.0011081283446401358,
      -0.018582463264465332,
      0.038645967841148376,
      0.11389157176017761,
      0.019693521782755852,
      0.03548673540353775,
      -0.0032511872705072165,
      0.035168666392564774,
      0.069931760430336,
      0.061562176793813705,
      -0.03302594646811485,
      -0.008404617197811604,
      -0.06327974796295166,
      0.024166250601410866,
      -0.008148107677698135,
      0.06580778956413269,
      -0.0720967948436737,
      -0.0727333351969719,
      -0.038616131991147995,
      0.04151144623756409,
      -0.01898733526468277,
      -0.05147918313741684,
      0.04733523353934288,
      0.0005741002969443798,
      -0.05908973887562752,
      -0.056085433810949326,
      -0.07953935861587524,
      -0.05623427778482437,
      0.012417095713317394,
      0.06540186703205109,
      -0.002626631408929825,
      -0.03254604712128639,
      -0.023427721112966537,
      -0.004164476413279772,
      0.041872087866067886,
      -0.002310869749635458,
      -0.015054929070174694,
      -0.023007050156593323,
      0.03979266807436943,
      -0.0066741458140313625,
      0.03537193685770035
    ],
    [
      -0.005020987708121538,
      -0.010738181881606579,
      -0.041365597397089005,
      0.004743202123790979,
      -0.0756460577249527,
      0.03794616460800171,
      -0.013616349548101425,
      0.011146065779030323,
      -0.0176463034003973,
      0.024076279252767563,
      -0.012546048499643803,
      0.0014617079868912697,
      0.016224853694438934,
      -0.022010773420333862,
      0.04770493879914284,
      0.021539419889450073,
      -0.03141665831208229,
      -0.02322387509047985,
      -0.09362568706274033,
      -0.02748291566967964,
      -0.010804854333400726,
      -0.006561070214956999,
      0.008321127854287624,
      0.017857398837804794,
      0.02746770717203617,
      0.04775075986981392,
      -0.026936190202832222,
      0.054450683295726776,
      0.01886996626853943,
      -0.024442441761493683,
      0.04864681512117386,
      -0.08315051347017288,
      -0.024276908487081528,
      0.004649011418223381,
      0.06913328170776367,
      -0.0007254777592606843,
      0.06800401210784912,
      -0.014304009266197681,
      -0.032188791781663895,
      0.015491224825382233,
      0.0033241091296076775,
      0.025397535413503647,
      -0.10893213748931885,
      0.05173706263303757,
      0.08777765184640884,
      0.030007632449269295,
      0.0525214746594429,
      0.025756122544407845,
      0.02116270549595356,
      0.00982041098177433,
      0.0477461963891983,
      0.04835398122668266,
      0.0020580540876835585,
      0.007678420282900333,
      -0.012072041630744934,
      -0.10616189241409302,
      0.0580456480383873,
      0.1309967339038849,
      0.01683233305811882,
      -0.027263175696134567,
      0.03569187968969345,
      0.06734122335910797,
      0.04256891459226608,
      0.0356995090842247,
      -0.0692695751786232,
      -0.017704114317893982,
      0.09556722640991211,
      0.019144102931022644,
      -0.0039019810501486063,
      0.006151730194687843,
      0.020462987944483757,
      -0.04930959641933441,
      0.019663667306303978,
      0.06074778363108635,
      -0.011147771961987019,
      0.06436150521039963,
      -0.0135100819170475,
      -0.006426798179745674,
      -0.006546243093907833,
      0.015537508763372898,
      0.023611407727003098,
      -0.015775900334119797,
      -0.03853712975978851,
      0.088981032371521,
      0.04601962864398956,
      0.10701510310173035,
      0.008715013042092323,
      0.0157721396535635,
      0.013967480510473251,
      0.0004165039863437414,
      -0.0658484399318695,
      -0.027184126898646355,
      0.05276307836174965,
      -0.016362043097615242,
      -0.029350431635975838,
      -0.013684574514627457,
      0.014064323157072067,
      -0.11460433900356293,
      0.06900098919868469,
      0.045273955911397934,
      0.016906745731830597,
      -0.0501103475689888,
      0.07416985929012299,
      0.014692544937133789,
      0.08792364597320557,
      -0.015586987137794495,
      -0.01642809808254242,
      0.04125401750206947,
      -0.022652899846434593,
      -0.04834431782364845,
      0.015945328399538994,
      -0.055980850011110306,
      -0.0717054232954979,
      0.018826765939593315,
      -0.0038243213202804327,
      -0.023172827437520027,
      0.057326409965753555,
      0.04085761681199074,
      -0.04251141846179962,
      0.003317179623991251,
      -0.02439962700009346,
      0.030117029324173927,
      -0.03573393076658249,
      -0.015515695326030254,
      0.046021442860364914,
      -0.049525778740644455,
      0.042283207178115845,
      -0.037437554448843,
      0.07576673477888107,
      0.04367712512612343,
      0.040758125483989716,
      -0.0945659950375557,
      -0.024583321064710617,
      -0.013501008041203022,
      0.04457204043865204,
      -0.05101688206195831,
      0.007738448213785887,
      -0.02680250257253647,
      -0.028829118236899376,
      0.027251556515693665,
      -0.09826990962028503,
      -0.052902016788721085,
      0.009792510420084,
      -0.029557932168245316,
      -0.06776712089776993,
      0.1315051168203354,
      -0.015038028359413147,
      -0.04308182746171951,
      0.0063160499557852745,
      -0.053290098905563354,
      0.03396023064851761,
      0.0014752259012311697,
      -0.07179166376590729,
      -0.044413626194000244,
      -0.010017366148531437,
      -0.0688849613070488,
      0.11374632269144058,
      0.03258160129189491,
      -0.058454133570194244,
      0.011116228066384792,
      0.03451796993613243,
      0.03467664495110512,
      -0.05138826742768288,
      0.051276519894599915,
      -0.005391789600253105,
      -0.07716772705316544,
      -0.019061850383877754,
      0.03546961024403572,
      0.038072649389505386,
      0.017880816012620926,
      0.04404051601886749,
      0.008412274532020092,
      -0.03857550024986267,
      0.07850859314203262,
      -0.053259871900081635,
      -0.030503056943416595,
      -0.023337634280323982,
      -0.05052601546049118,
      0.08547063171863556,
      -0.03550969436764717,
      -0.018628472462296486,
      -0.010209699161350727,
      -0.02107476070523262,
      -0.06343324482440948,
      -0.020188432186841965,
      0.01873660460114479,
      0.036679647862911224,
      -0.03463347256183624,
      0.019704081118106842,
      -0.014401094987988472,
      0.005686795338988304,
      -0.03251020982861519,
      0.014908678829669952,
      -0.004432845860719681,
      0.011036427691578865,
      -0.01156945526599884,
      0.05779406800866127,
      -0.003859718330204487,
      -0.04899238049983978,
      -0.029268616810441017,
      0.0016189933521673083,
      0.05532468110322952,
      -0.03200725093483925,
      0.14637887477874756,
      0.020590418949723244,
      -0.048040762543678284,
      0.02671118453145027,
      0.008851729333400726,
      0.06102687865495682,
      -0.05755798518657684,
      0.020430369302630424,
      0.039696525782346725,
      -0.028019916266202927,
      0.050183866173028946,
      0.014347086660563946,
      -0.012676551938056946,
      -0.06891369819641113,
      -0.05918875336647034,
      0.019146298989653587,
      0.002827902091667056,
      -0.0584675632417202,
      3.492983159958385e-05,
      -0.028256544843316078,
      -0.03382183983922005,
      0.043069761246442795,
      -0.008080326952040195,
      -0.005962172523140907,
      0.004147613886743784,
      -0.06096652150154114,
      0.09885367751121521,
      0.024659419432282448,
      -0.030870499089360237,
      -0.01973968930542469,
      0.04838877171278,
      -0.0507955402135849,
      0.07436094433069229,
      0.020945429801940918,
      -0.040801409631967545,
      0.07527519762516022,
      0.0668918639421463,
      -0.0346478633582592,
      -0.03317045047879219,
      -0.018750697374343872,
      0.002226976677775383,
      0.012007958255708218,
      -0.0045389533042907715,
      -0.015678009018301964,
      0.031049931421875954,
      -0.05746681988239288,
      -0.030711518600583076,
      0.0007682816358283162,
      0.011234447360038757,
      0.07620514929294586,
      -0.07016951590776443,
      0.03139939531683922,
      -0.06820981949567795,
      0.023599926382303238,
      0.027538573369383812,
      -0.11393304169178009,
      -0.03461961820721626,
      -0.0074744513258337975,
      0.0005958252586424351,
      0.054256439208984375,
      0.01605435274541378,
      -0.03199668228626251,
      0.022349150851368904,
      0.016779650002717972,
      0.04986211657524109,
      0.0004029987321700901,
      0.028494730591773987,
      -0.0215162243694067,
      -0.02785036712884903,
      -0.004032617900520563,
      -0.026150578632950783,
      -0.005654979031533003,
      -0.09118897467851639,
      0.01899314485490322,
      -0.017427897080779076,
      0.02830900438129902,
      -0.038690850138664246,
      0.03830515593290329,
      0.022953243926167488,
      0.061401378363370895,
      -0.013733990490436554,
      -0.021754266694188118,
      -0.08065925538539886,
      -0.08885384351015091,
      0.04743911698460579,
      -0.018017640337347984,
      0.035468392074108124,
      -0.009272510185837746,
      -0.009626341983675957,
      -0.05934358015656471,
      -0.02970953844487667,
      0.01436147466301918,
      -0.06370514631271362,
      0.0419534370303154,
      -0.041088931262493134,
      0.06770138442516327,
      0.036166347563266754,
      -0.03259695693850517,
      -0.025413671508431435,
      -0.03412206470966339,
      -0.044460225850343704,
      -0.003819048870354891,
      0.0302013847976923,
      -0.000907338282559067,
      -0.08188744634389877,
      0.02598675526678562,
      0.01154437568038702,
      -0.024994375184178352,
      -0.0006777103990316391,
      -0.0373091846704483,
      -0.0040288763120770454,
      -0.07219302654266357,
      -0.006857340689748526,
      0.01458591315895319,
      0.052179157733917236,
      0.017333148047327995,
      -0.043919820338487625,
      -0.049728747457265854,
      0.08765854686498642,
      0.06726028025150299,
      -0.042965322732925415,
      0.08936326950788498,
      -0.1050444021821022,
      -0.028996404260396957,
      0.036489710211753845,
      0.08623852580785751,
      -0.04920574650168419,
      0.04233139008283615,
      0.037028487771749496,
      0.06203388795256615,
      -0.14601612091064453,
      0.0005705936346203089,
      -0.002638146746903658,
      0.007119317539036274,
      -0.014982687309384346,
      -0.025511600077152252,
      -0.028864026069641113,
      0.03193670138716698,
      0.05977541580796242,
      -0.06565388292074203,
      0.03683270514011383,
      -0.0064512742683291435,
      -0.02394603192806244,
      -0.0009338958188891411,
      0.016050847247242928,
      0.01267916802316904,
      -0.004172931890934706,
      -0.00966804102063179,
      0.005691718775779009,
      -0.05569180101156235,
      -0.015167232602834702,
      0.007621851284056902,
      0.03386567160487175,
      0.006982774939388037,
      0.027363246306777,
      0.0982462614774704,
      -0.012154598720371723,
      0.029280655086040497,
      -0.014198425225913525,
      0.07322180271148682,
      -0.011994270607829094,
      -0.045789819210767746,
      0.04872039705514908,
      0.04600437358021736,
      -0.05917474254965782,
      -0.04269128665328026,
      0.02297094278037548,
      0.06926637142896652,
      -0.07094524800777435,
      0.01244380883872509,
      0.021967189386487007,
      -0.06472945958375931,
      0.11602892726659775,
      -0.08438032865524292,
      0.02618102915585041,
      0.04526927322149277,
      -0.019016219303011894,
      -0.05830882489681244,
      0.07766152918338776,
      0.0342615582048893,
      0.08514053374528885,
      0.09760376811027527,
      -0.007923922501504421,
      -0.010213171131908894,
      0.042856745421886444,
      0.014151090756058693,
      -0.01670539379119873,
      0.0036256301682442427,
      0.05961959809064865,
      0.12600047886371613,
      -0.021046297624707222,
      -0.013182351365685463,
      -0.0737006738781929,
      -0.011854417622089386,
      0.08554750680923462,
      7.072028529364616e-05,
      0.023098023608326912,
      -0.04717845097184181,
      0.03273686021566391,
      0.049384020268917084,
      -0.028372790664434433,
      -0.004907635506242514,
      -0.01905835047364235,
      0.014436010271310806,
      0.02415851689875126,
      0.0031550123821944,
      -0.03601188585162163,
      -0.0279900711029768,
      -0.02045792154967785,
      -0.03458486869931221,
      -0.03828747943043709,
      -0.07825429737567902,
      0.03803586959838867,
      -0.0703841894865036,
      -0.021358130499720573,
      0.020947976037859917,
      0.07321109622716904,
      0.033248286694288254,
      0.034862928092479706,
      -0.020374028012156487,
      0.027980968356132507,
      0.047473661601543427,
      -0.038459982722997665,
      0.03798873722553253,
      0.09473399072885513,
      0.00455835834145546,
      -0.0034275951329618692,
      0.07341910898685455,
      -0.02307741530239582,
      0.011832084506750107,
      -0.024004992097616196,
      0.037016093730926514,
      -0.05658349022269249,
      -0.06559354811906815,
      0.03872763365507126,
      0.022246548905968666,
      0.007335105445235968,
      0.03417012840509415,
      0.04152240231633186,
      0.05134446546435356,
      -0.011641028337180614,
      0.030929015949368477,
      -0.03432444483041763,
      0.01206811610609293,
      0.05872553586959839,
      -0.029043592512607574,
      -0.036538176238536835,
      0.018636927008628845,
      -0.0015994260320439935,
      0.02663995698094368,
      -0.02624862641096115,
      -0.0018333079060539603,
      0.012798131443560123,
      0.0023650946095585823,
      -0.033134207129478455,
      0.05181657150387764,
      -0.09303773194551468,
      -0.04201989620923996,
      0.055545300245285034,
      0.04045774042606354,
      -0.00956445187330246,
      0.07082565873861313,
      0.05297687277197838,
      -0.015796244144439697,
      -0.1047336533665657,
      0.04498303681612015,
      0.0501185767352581,
      -0.07065875828266144,
      0.028793713077902794,
      -0.07332579791545868,
      -0.006888213101774454,
      0.06601213663816452,
      -0.07499916851520538,
      0.07359049469232559,
      -0.09171906113624573,
      -0.005059868097305298,
      -0.05642721429467201,
      0.004548441153019667,
      -0.037507034838199615,
      0.022477949038147926,
      0.008966660127043724,
      -0.01940963603556156,
      0.008612888865172863,
      0.04422938823699951,
      0.11119450628757477,
      -0.07608392834663391,
      0.022319044917821884,
      -0.005727116949856281,
      -0.00453443918377161,
      0.032545093446969986,
      0.024722937494516373,
      0.014413968659937382,
      -0.0842742994427681,
      -0.0657031238079071,
      -0.042555004358291626,
      0.036707159131765366,
      0.006337788887321949,
      0.04794149473309517,
      -0.009243408218026161,
      0.030410034582018852
    ],
    [
      0.03301451355218887,
      -0.059417661279439926,
      0.015132945962250233,
      0.015815529972314835,
      -0.011456013657152653,
      0.014913282357156277,
      -0.06682103127241135,
      -0.01061028242111206,
      -0.027270762249827385,
      0.04825850948691368,
      -0.019180448725819588,
      -0.01703762449324131,
      0.02065579779446125,
      -0.012478131800889969,
      -0.0829080119729042,
      -0.047385960817337036,
      -0.02285155840218067,
      0.06954319030046463,
      0.01140064001083374,
      -0.03982658311724663,
      -0.019002899527549744,
      0.028344666585326195,
      -0.020125869661569595,
      -0.03669794648885727,
      0.03927580267190933,
      -0.026993701234459877,
      0.00169003801420331,
      -0.03372538834810257,
      0.01774582266807556,
      0.017197245731949806,
      0.026110434904694557,
      -0.020634356886148453,
      0.01882510632276535,
      0.002228466561064124,
      0.042819805443286896,
      -0.0009678896167315543,
      0.017797745764255524,
      0.00037610792787745595,
      0.04920570179820061,
      -0.07625245302915573,
      -0.03631770610809326,
      -0.05111429840326309,
      0.054594963788986206,
      -0.004818156827241182,
      0.014281896874308586,
      0.05778980255126953,
      0.02623758837580681,
      -0.013594948686659336,
      -0.02437025122344494,
      0.030998950824141502,
      -0.059222690761089325,
      0.051044121384620667,
      0.017096122726798058,
      -0.04770173504948616,
      0.027240511029958725,
      0.02221190370619297,
      0.05225619301199913,
      0.07246650755405426,
      0.014603836461901665,
      -0.13050717115402222,
      0.05126539617776871,
      0.037656307220458984,
      -0.04422843083739281,
      0.02877979911863804,
      0.08026228100061417,
      -0.01681692525744438,
      -0.07694295793771744,
      -0.040550753474235535,
      0.005463173612952232,
      -0.010438519529998302,
      -0.004504689946770668,
      0.008717854507267475,
      0.035619575530290604,
      -0.0534697063267231,
      0.06119393929839134,
      -0.04152804985642433,
      0.03568388521671295,
      -0.013497413136065006,
      -0.050346389412879944,
      -0.0024763892870396376,
      0.02075313590466976,
      -0.036818377673625946,
      -0.021142031997442245,
      -0.00043834076495841146,
      -0.1120344027876854,
      0.0004210775950923562,
      -0.004495259374380112,
      0.03552492335438728,
      0.11524145305156708,
      0.012783504091203213,
      0.05286717042326927,
      0.055242713540792465,
      -0.026583587750792503,
      -0.09603428095579147,
      -0.03619274124503136,
      0.03265940397977829,
      0.033625662326812744,
      0.04465058445930481,
      0.040158435702323914,
      0.043767303228378296,
      -0.05781848356127739,
      -0.08367858082056046,
      0.051231931895017624,
      0.0006823104340583086,
      -0.011074214242398739,
      -0.07447962462902069,
      -0.0038024031091481447,
      0.01704011671245098,
      0.030764317139983177,
      -0.012817871756851673,
      0.12298202514648438,
      -0.08973979204893112,
      0.0240804310888052,
      -0.05940387398004532,
      0.08664140850305557,
      0.057869844138622284,
      0.04129133000969887,
      0.0010956352343782783,
      0.04298742115497589,
      0.03561887517571449,
      -0.03607002645730972,
      -0.07632362842559814,
      -0.03285425528883934,
      0.041577473282814026,
      0.06215263530611992,
      0.01705171726644039,
      0.058389805257320404,
      -0.018217815086245537,
      -0.02536812610924244,
      0.007640285417437553,
      0.009270934388041496,
      0.011995912529528141,
      -0.03220275416970253,
      -0.021467268466949463,
      -0.0491790734231472,
      0.044245053082704544,
      -0.017994437366724014,
      -0.09908070415258408,
      0.005881178192794323,
      -0.008554194122552872,
      0.029727978631854057,
      -0.03872745484113693,
      -0.012027429416775703,
      -0.05094084516167641,
      0.040715377777814865,
      0.1005682572722435,
      0.022387366741895676,
      -0.03302158787846565,
      -0.0026098694652318954,
      -0.04370103403925896,
      0.04201807826757431,
      -0.01857808604836464,
      -0.018957972526550293,
      0.028949197381734848,
      -0.0011795005993917584,
      -0.023639719933271408,
      0.008914499543607235,
      0.05358600243926048,
      0.00423623900860548,
      -0.0024239341728389263,
      0.004386541433632374,
      0.03767097368836403,
      -0.025894874706864357,
      0.04258003458380699,
      0.025723081082105637,
      -0.04044833406805992,
      -0.017222359776496887,
      0.005445404909551144,
      -0.02768518030643463,
      -0.020457686856389046,
      -0.003044041572138667,
      0.0022361245937645435,
      0.09141126275062561,
      0.05296822637319565,
      0.04305081069469452,
      0.01215848233550787,
      0.04392048716545105,
      0.030617007985711098,
      -0.0051332926377654076,
      -0.08406537026166916,
      -0.08930334448814392,
      0.009694650769233704,
      0.10194604098796844,
      -0.03946796804666519,
      -0.052822746336460114,
      0.0191398523747921,
      -0.12584011256694794,
      -0.025555109605193138,
      0.002771581057459116,
      0.014904758892953396,
      0.022193044424057007,
      0.04897501692175865,
      0.039908040314912796,
      0.03660103678703308,
      0.05145689472556114,
      0.007233214098960161,
      0.027136649936437607,
      0.0035920923110097647,
      -0.07870744913816452,
      0.004815461579710245,
      -0.07122288644313812,
      -0.0005380252841860056,
      -0.030118118971586227,
      0.11400715261697769,
      0.014437633566558361,
      -0.061580996960401535,
      0.0507943257689476,
      0.001490135444328189,
      -0.027399258688092232,
      -0.008517676033079624,
      -0.06364227831363678,
      -0.04429100453853607,
      -0.05339417606592178,
      -0.04149770364165306,
      -0.07298098504543304,
      0.04836075380444527,
      -0.03325866535305977,
      0.005361251067370176,
      0.06245243176817894,
      -0.05938020721077919,
      -0.010832129046320915,
      0.019441567361354828,
      -0.01111565064638853,
      -0.011870935559272766,
      -0.015579973347485065,
      0.04633600264787674,
      -0.0038813077844679356,
      -0.02158218063414097,
      -0.05989494174718857,
      0.01775258406996727,
      -0.0012785119470208883,
      0.057502616196870804,
      -0.0726507380604744,
      0.028986047953367233,
      -0.014590732753276825,
      -0.0284525528550148,
      -0.016965586692094803,
      -0.007745562121272087,
      -0.04830782860517502,
      0.015081617049872875,
      0.05370907112956047,
      -0.020825712010264397,
      0.028860488906502724,
      0.03418177366256714,
      0.003975485451519489,
      0.010546496137976646,
      0.046721477061510086,
      0.05061628296971321,
      -0.004192239139229059,
      -0.010748009197413921,
      0.06415709108114243,
      -0.036912448704242706,
      -0.06550715863704681,
      -0.026318464428186417,
      -0.04520578309893608,
      0.06383484601974487,
      0.03381870687007904,
      0.0052546970546245575,
      -0.018775787204504013,
      0.015506865456700325,
      0.06103216111660004,
      -0.030712302774190903,
      0.02721657231450081,
      -0.026397384703159332,
      -0.009918895550072193,
      0.04487808421254158,
      0.02438189834356308,
      0.06743556261062622,
      0.024955984205007553,
      -0.019627686589956284,
      -0.002159479772672057,
      0.03173896297812462,
      0.014181729406118393,
      -0.072646863758564,
      0.052481506019830704,
      -0.023557458072900772,
      -0.05311717092990875,
      0.08390142768621445,
      0.03542628511786461,
      0.05748037248849869,
      0.006519939284771681,
      0.04448171332478523,
      -0.05871712416410446,
      -0.04601506516337395,
      -0.009664264507591724,
      0.06799638271331787,
      -0.0440324991941452,
      -0.04210340231657028,
      0.04413452744483948,
      0.018032783642411232,
      0.0577448233962059,
      0.06337304413318634,
      0.06767253577709198,
      0.00015587244706694037,
      0.005109415855258703,
      -0.03394150361418724,
      -0.04288370534777641,
      0.009303026832640171,
      0.04804820567369461,
      -0.04674737900495529,
      0.00979976449161768,
      0.05675089731812477,
      0.09035946428775787,
      -0.022530866786837578,
      -0.0491696335375309,
      0.03195700794458389,
      -0.012689704075455666,
      -0.019396444782614708,
      -0.003793824929744005,
      -0.0210102666169405,
      -0.012086093425750732,
      -0.02789861522614956,
      -0.06095179170370102,
      0.07895392924547195,
      0.01504393108189106,
      0.0010717721888795495,
      -0.056125182658433914,
      -0.005727965384721756,
      -0.005041867960244417,
      0.018909502774477005,
      -0.04736853018403053,
      0.07018297165632248,
      0.04205315560102463,
      0.02213801071047783,
      0.1049189567565918,
      0.04238929599523544,
      0.0022118904162198305,
      -0.036195848137140274,
      0.010418745689094067,
      -0.0059572733007371426,
      -0.07058457285165787,
      0.02339228056371212,
      0.007690258789807558,
      -0.0023256794083863497,
      -0.05972442030906677,
      0.0016563966637477279,
      0.011692940257489681,
      0.013040567748248577,
      -0.02189718931913376,
      0.04576185718178749,
      0.033447545021772385,
      0.016507456079125404,
      -0.04853883385658264,
      0.006714812014251947,
      -0.0014389593852683902,
      -0.03429142385721207,
      -0.0065162996761500835,
      -0.04640639200806618,
      -0.03037482127547264,
      -0.05731157213449478,
      0.03795117512345314,
      -0.07275314629077911,
      -0.08344683051109314,
      -0.053352437913417816,
      0.00011277536395937204,
      -0.021695343777537346,
      -0.06482637673616409,
      -0.08310694992542267,
      -0.04770027846097946,
      -0.04912981390953064,
      -0.040081072598695755,
      0.0015023441519588232,
      0.12484689801931381,
      -0.00505702244117856,
      -0.025730567052960396,
      0.00643742922693491,
      0.015475106425583363,
      0.04458414390683174,
      -0.06299716979265213,
      0.0841953232884407,
      0.03057706169784069,
      0.0165875181555748,
      -0.021279161795973778,
      -0.04560794681310654,
      0.02273869328200817,
      0.059016983956098557,
      0.0383024737238884,
      0.02313343808054924,
      -0.05271080881357193,
      -0.030042573809623718,
      -0.030343178659677505,
      0.03914031386375427,
      0.0020743052009493113,
      0.0028193253092467785,
      0.0723741427063942,
      -0.014949426986277103,
      -0.02353815920650959,
      0.0258718803524971,
      0.05913585424423218,
      -0.05816702917218208,
      -0.05918094515800476,
      0.02244219183921814,
      0.028784018009901047,
      -0.0032271360978484154,
      -0.008264893665909767,
      0.02766559086740017,
      -0.04958060011267662,
      0.02793072536587715,
      0.02140148915350437,
      -0.02062731236219406,
      -0.05988698452711105,
      0.035578276962041855,
      0.07516541332006454,
      -0.006078173406422138,
      0.022369487211108208,
      -0.030170971527695656,
      0.07145638763904572,
      0.03390692546963692,
      0.024101028218865395,
      -0.03583739325404167,
      -0.021353119984269142,
      0.03211067244410515,
      -0.0924311950802803,
      -0.08074631541967392,
      0.008575621992349625,
      -0.10499981045722961,
      0.053196169435977936,
      -0.008911361917853355,
      0.023789728060364723,
      -0.007048472296446562,
      -0.044186633080244064,
      0.0271706972271204,
      -0.013137045316398144,
      0.002747343620285392,
      -0.03824906423687935,
      -0.033777981996536255,
      0.09302949905395508,
      -0.06720083951950073,
      -0.024750966578722,
      -0.007459575310349464,
      0.04208585247397423,
      0.03375451639294624,
      0.004076076205819845,
      -0.03689725324511528,
      0.07379893213510513,
      -0.0058699194341897964,
      0.06786441057920456,
      0.1083335280418396,
      0.036866459995508194,
      -0.008840857073664665,
      0.019734280183911324,
      -0.0852615237236023,
      0.0849299356341362,
      0.025370951741933823,
      0.03484818711876869,
      -0.05400044843554497,
      0.026628149673342705,
      -0.011696658097207546,
      -0.020579995587468147,
      0.0064588612876832485,
      0.011307829059660435,
      -0.059253670275211334,
      0.06715168803930283,
      0.02879617176949978,
      -0.1293172389268875,
      0.02909153327345848,
      -0.03483965992927551,
      -0.03010309487581253,
      -0.016343869268894196,
      -0.039185769855976105,
      0.013041234575212002,
      0.08418070524930954,
      -0.001830845489166677,
      -0.02351783588528633,
      0.01978878304362297,
      -0.02976587601006031,
      0.043293170630931854,
      -0.03952236473560333,
      0.0202366691082716,
      0.00039775774348527193,
      -0.016192693263292313,
      0.05436408892273903,
      -0.030628429725766182,
      -0.026202816516160965,
      -0.055713314563035965,
      0.0027476653922349215,
      0.06502894312143326,
      0.0897546112537384,
      0.008384010754525661,
      0.06453808397054672,
      0.013607197441160679,
      -0.04022800549864769,
      -0.04339274391531944,
      0.023296916857361794,
      0.010292756371200085,
      -0.009197740815579891,
      0.0926547423005104,
      -0.005609591491520405,
      0.05106575787067413,
      0.062252677977085114,
      -0.022864261642098427,
      -0.04586618021130562,
      0.026151496917009354,
      0.001533536589704454,
      0.06268984079360962,
      -0.04009086266160011,
      -0.02998415008187294,
      -0.08330385386943817,
      0.030715461820364,
      -0.04982157051563263,
      0.03756827116012573,
      -0.009930563159286976,
      -0.03568536788225174
    ],
    [
      0.0049481517635285854,
      -0.0389518178999424,
      0.060714490711688995,
      -0.027193035930395126,
      -0.0256939884275198,
      -0.040241848677396774,
      -0.01838582009077072,
      0.07143931090831757,
      -0.0052548181265592575,
      0.0036284462548792362,
      -0.00809638574719429,
      -0.01360313594341278,
      -0.026924610137939453,
      -0.004760993644595146,
      -0.060327913612127304,
      -0.03773069381713867,
      -0.050451453775167465,
      -0.05593210831284523,
      0.06652387231588364,
      0.06286515295505524,
      -0.024834956973791122,
      0.006869794800877571,
      0.023457592353224754,
      -0.019498758018016815,
      -0.0017009081784635782,
      -0.00801651831716299,
      0.06874093413352966,
      -0.01702411286532879,
      -0.06759034097194672,
      0.022500300779938698,
      0.024549011141061783,
      0.05692768841981888,
      -0.08555515855550766,
      0.04852177947759628,
      -0.0420544408261776,
      0.07557453215122223,
      0.08941425383090973,
      -0.043877992779016495,
      -0.007225880865007639,
      -0.011463087052106857,
      -0.059106580913066864,
      -0.01514551043510437,
      -0.03597266972064972,
      0.014035980217158794,
      -0.019700365141034126,
      0.01031199749559164,
      0.02459130808711052,
      -0.05642951652407646,
      0.01933516003191471,
      -0.03945786878466606,
      -0.16130532324314117,
      0.07636028528213501,
      -0.04246395826339722,
      -0.014751475304365158,
      0.043997347354888916,
      -0.0009697938803583384,
      0.02519357018172741,
      0.008305837400257587,
      0.004059072118252516,
      0.034925758838653564,
      0.0542043000459671,
      0.04806111752986908,
      0.10305758565664291,
      0.022447187453508377,
      0.03481146693229675,
      0.05208414047956467,
      0.05514494329690933,
      0.07824214547872543,
      -0.031191563233733177,
      -0.06334167718887329,
      0.0219946950674057,
      -0.08740784227848053,
      -0.032958947122097015,
      0.0486668199300766,
      -0.03650129586458206,
      -0.04120377078652382,
      0.021962497383356094,
      -0.028666116297245026,
      -0.03595373034477234,
      -0.038577452301979065,
      -0.029071835801005363,
      -0.054492633789777756,
      -0.00862145610153675,
      -0.034317027777433395,
      -0.07371164858341217,
      -0.011778661049902439,
      0.0437997430562973,
      0.052459437400102615,
      0.02606172487139702,
      0.007232324220240116,
      -0.03213146701455116,
      0.011394696310162544,
      0.09855517745018005,
      0.04831699654459953,
      0.03974813222885132,
      -0.0013937753392383456,
      -0.021397463977336884,
      0.08906996250152588,
      -0.010529451072216034,
      -0.0547010637819767,
      -0.048111796379089355,
      0.06065966188907623,
      0.04974761977791786,
      0.025165880098938942,
      -0.03982226178050041,
      0.05217153951525688,
      -0.012861629948019981,
      -0.05438997596502304,
      0.014762384817004204,
      -0.009598162025213242,
      0.08348097652196884,
      -0.017246605828404427,
      0.014619966968894005,
      -0.04868721589446068,
      0.041550491005182266,
      0.10620638728141785,
      0.007037953473627567,
      -0.04892733693122864,
      -0.027853747829794884,
      0.05855816230177879,
      0.04103078320622444,
      0.02401740290224552,
      0.030626527965068817,
      -0.0020242868922650814,
      -0.005669471807777882,
      0.011861318722367287,
      0.08347146958112717,
      -0.0023605518508702517,
      0.03724737465381622,
      0.0476335734128952,
      0.026964319869875908,
      -0.056393813341856,
      -0.032429974526166916,
      -0.05848236009478569,
      -0.054078612476587296,
      0.07305414974689484,
      0.03606894984841347,
      -0.0017931999173015356,
      -0.008168010041117668,
      -0.026347441598773003,
      0.018619967624545097,
      0.07818584889173508,
      -0.02920280396938324,
      -0.03758421912789345,
      -0.011251804418861866,
      0.0230172760784626,
      -0.056464627385139465,
      -0.0974670946598053,
      -0.06031274423003197,
      0.04164592921733856,
      -0.01692475751042366,
      -0.06999757140874863,
      -0.04866397753357887,
      -0.009873872622847557,
      -0.04762813076376915,
      0.0023415975738316774,
      0.03964454308152199,
      0.008352402597665787,
      0.06337759643793106,
      0.014733017422258854,
      -0.047180335968732834,
      -0.011338264681398869,
      0.06800523400306702,
      -0.006868588272482157,
      0.010328681208193302,
      0.0256916806101799,
      0.02316121757030487,
      0.025166137143969536,
      -0.048293337225914,
      -0.04374413564801216,
      0.05546208471059799,
      -0.08915425837039948,
      -0.018323145806789398,
      -0.04920998588204384,
      0.0924481749534607,
      -0.002673286246135831,
      0.014134906232357025,
      0.10253358632326126,
      0.04024915397167206,
      -0.01802065223455429,
      -0.05291585251688957,
      -2.6570449335849844e-05,
      0.05407201126217842,
      0.05405066907405853,
      0.01290095504373312,
      -0.04891243949532509,
      -0.004204986616969109,
      -0.035029228776693344,
      0.008321298286318779,
      0.0038466406986117363,
      0.03558008372783661,
      0.06268902868032455,
      -0.0021947487257421017,
      0.06746542453765869,
      -0.02714444324374199,
      -0.08951327949762344,
      -0.020234055817127228,
      -0.007895736023783684,
      -0.07694072276353836,
      0.05768311396241188,
      -0.08776318281888962,
      0.0351143553853035,
      -0.0736936703324318,
      0.030325064435601234,
      0.009696060791611671,
      0.02056553028523922,
      0.007034313399344683,
      -0.056609854102134705,
      0.014259198680520058,
      0.012600746937096119,
      -0.04896169528365135,
      -0.01156358141452074,
      -0.12363748252391815,
      0.058464713394641876,
      0.028847413137555122,
      -0.00640479102730751,
      0.010944112204015255,
      -0.025791805237531662,
      0.05161157622933388,
      -0.008512650616466999,
      0.007056138943880796,
      0.059718817472457886,
      -0.014629761688411236,
      0.05270318314433098,
      0.054182831197977066,
      0.024455435574054718,
      0.051857899874448776,
      -0.002568858675658703,
      0.06485100090503693,
      0.057948965579271317,
      -0.02321762405335903,
      -0.013206247240304947,
      -0.04829860478639603,
      -0.01690828986465931,
      -0.06542958319187164,
      0.010646593756973743,
      -0.04132303223013878,
      -0.008583095856010914,
      -0.04474470764398575,
      0.038765326142311096,
      0.017864637076854706,
      -0.018815193325281143,
      -0.03802969679236412,
      0.03432086110115051,
      0.08669617772102356,
      0.06351260840892792,
      0.06144309788942337,
      0.015098663978278637,
      0.03797059506177902,
      0.06893888860940933,
      -0.010631809942424297,
      -0.044252779334783554,
      0.04885442927479744,
      0.039430949836969376,
      0.05833662301301956,
      0.0435602068901062,
      -0.07183505594730377,
      -0.007210101000964642,
      -0.06179043650627136,
      -0.030764849856495857,
      -0.0026136573869735003,
      0.03974516689777374,
      -0.06505345553159714,
      0.010141770355403423,
      0.008817780762910843,
      -0.018719244748353958,
      -0.01273584458976984,
      0.004187334794551134,
      0.00662884721532464,
      -0.08461995422840118,
      -0.0736793801188469,
      0.011751959100365639,
      -0.02314586192369461,
      0.04564506188035011,
      0.052944015711545944,
      -0.042755141854286194,
      -0.010492988862097263,
      0.051165394484996796,
      -0.1351034939289093,
      -0.03343600407242775,
      -0.013859428465366364,
      -0.06033109501004219,
      0.027812454849481583,
      -0.0546448789536953,
      -0.0846903920173645,
      0.00991055928170681,
      -0.01964450627565384,
      0.061218731105327606,
      -0.07739555835723877,
      0.10175821930170059,
      0.02147689089179039,
      0.0239512138068676,
      0.07171693444252014,
      0.019810844212770462,
      -0.046334438025951385,
      -0.018459873273968697,
      -0.0656493678689003,
      -0.00888614822179079,
      -0.01020639855414629,
      -0.007426360156387091,
      -0.01789013296365738,
      0.0012188369873911142,
      0.011891765519976616,
      0.014440297149121761,
      -0.04361223056912422,
      0.014342200011014938,
      0.04519835487008095,
      0.009698331356048584,
      0.03888595849275589,
      -0.01156615000218153,
      -0.04943302646279335,
      -0.0064000412821769714,
      0.06072669476270676,
      0.08040095120668411,
      -0.014452312141656876,
      -0.0621446892619133,
      -0.012671375647187233,
      -0.014047234319150448,
      -0.042454835027456284,
      0.03931088745594025,
      -0.0652807354927063,
      -0.029054133221507072,
      0.025836046785116196,
      -0.02120150625705719,
      -0.03449458256363869,
      0.00043508008820936084,
      0.003596174530684948,
      0.01490895077586174,
      -0.03698597475886345,
      -0.03394909203052521,
      -0.06673305481672287,
      0.06811369210481644,
      -0.0075742099434137344,
      -0.04129410907626152,
      0.010024900548160076,
      -0.043142613023519516,
      0.10251471400260925,
      0.04676862061023712,
      -0.004620102699846029,
      0.059258922934532166,
      -0.01655561290681362,
      0.1407015025615692,
      0.047271642833948135,
      -0.017281539738178253,
      -0.11876408755779266,
      0.005128160584717989,
      -0.0034186949487775564,
      0.01788126677274704,
      -0.04824585095047951,
      0.013909514993429184,
      0.021572446450591087,
      -0.020499655976891518,
      0.04790176451206207,
      0.040733180940151215,
      0.03984428569674492,
      0.0391712486743927,
      0.011538619175553322,
      -0.0628267452120781,
      0.061566438525915146,
      0.019833441823720932,
      -0.03287498280405998,
      0.025423118844628334,
      0.06795474886894226,
      -0.10828863829374313,
      -0.007179412990808487,
      -0.02150064706802368,
      -0.07194218039512634,
      0.039424482733011246,
      -0.03293623402714729,
      0.06403693556785583,
      -0.06720653176307678,
      -0.060353852808475494,
      -0.04291868209838867,
      -0.025951897725462914,
      0.05543508380651474,
      0.010966336354613304,
      0.014305376447737217,
      -0.03943504020571709,
      0.019013339653611183,
      -0.0059458413161337376,
      -0.009145849384367466,
      -0.03492436930537224,
      -0.0477120541036129,
      0.020357457920908928,
      0.035471197217702866,
      -0.0002456839429214597,
      -0.05699661374092102,
      -0.0008454286144115031,
      -0.08163733035326004,
      0.061317700892686844,
      -0.029018612578511238,
      -0.025470873340964317,
      -0.021602746099233627,
      0.0020527031738311052,
      -0.0624709352850914,
      -0.00658932700753212,
      -0.017641516402363777,
      0.008125174790620804,
      0.07973438501358032,
      0.04892810434103012,
      0.03693666309118271,
      -0.06629742681980133,
      0.042111530900001526,
      0.02343420498073101,
      -0.045446787029504776,
      0.03393720090389252,
      0.042571261525154114,
      0.02550335042178631,
      -0.07515615224838257,
      -0.04131825268268585,
      -0.03553054481744766,
      0.09852809458971024,
      -0.07849661260843277,
      0.04057453200221062,
      0.02844632975757122,
      -0.027919335290789604,
      -0.005087072495371103,
      -0.05144375190138817,
      0.05613653361797333,
      -0.058751124888658524,
      0.026148701086640358,
      0.058026138693094254,
      -0.014546588994562626,
      0.01612645760178566,
      0.04565243422985077,
      -0.0024235870223492384,
      0.013066365383565426,
      -0.003289343323558569,
      0.028053659945726395,
      -0.005405413918197155,
      -0.037370406091213226,
      0.05133529379963875,
      -0.10924388468265533,
      0.04046306014060974,
      0.034273404628038406,
      0.015678323805332184,
      0.08560875058174133,
      0.010996684432029724,
      0.04555878788232803,
      0.010932213626801968,
      -0.0679427981376648,
      0.028783174231648445,
      0.03821269050240517,
      0.04985710233449936,
      0.05650408938527107,
      -0.03849515691399574,
      -0.05237682908773422,
      -0.03855268657207489,
      -0.036092765629291534,
      -0.06692411750555038,
      0.04827294498682022,
      0.039194267243146896,
      -0.09922150522470474,
      0.0355350524187088,
      -0.06570898741483688,
      0.05072057247161865,
      0.053292255848646164,
      0.004287943709641695,
      0.06776873767375946,
      -0.10882240533828735,
      0.038255807012319565,
      0.011284216307103634,
      0.0016044577350839972,
      0.053005702793598175,
      -0.06282307952642441,
      -0.061587441712617874,
      -0.02031911350786686,
      -0.00167779007460922,
      -0.05814654380083084,
      0.016833579167723656,
      -0.06538230925798416,
      0.003371106693521142,
      -0.06181240826845169,
      -0.02779526449739933,
      0.01078369002789259,
      0.009370707906782627,
      -0.1073073223233223,
      -0.009727607481181622,
      -0.06185491010546684,
      0.024423405528068542,
      0.07630900293588638,
      -0.03104054369032383,
      0.004075142089277506,
      0.03410729765892029,
      -0.03269384056329727,
      0.058779921382665634,
      0.10991242527961731,
      0.02183889038860798,
      0.07901985198259354,
      0.01316467672586441,
      -0.011114939115941525,
      -0.07617226243019104,
      0.016377486288547516,
      0.03748592734336853,
      0.020379623398184776,
      0.025207312777638435,
      0.02808469720184803,
      -0.06423753499984741,
      0.01766730658710003,
      -0.025235168635845184,
      0.09097398817539215,
      0.024028021842241287,
      -0.10445930063724518
    ],
    [
      -0.022520629689097404,
      -0.018195586279034615,
      -0.00954230222851038,
      -0.016050027683377266,
      0.0466129444539547,
      -0.01188158243894577,
      -0.052423834800720215,
      -0.04753238707780838,
      -0.05941031873226166,
      0.062272362411022186,
      -0.039521876722574234,
      0.08396771550178528,
      -0.012612055987119675,
      0.11545959860086441,
      -0.002135804621502757,
      -0.035616204142570496,
      -0.008640016429126263,
      -0.024786019697785378,
      0.0572940967977047,
      0.045532699674367905,
      0.031008483842015266,
      -0.07578562945127487,
      0.04048984497785568,
      -0.0053290412761271,
      0.01806659996509552,
      0.15853898227214813,
      -0.0667654350399971,
      -0.055133625864982605,
      -0.057016536593437195,
      -0.0011834297329187393,
      -0.10051171481609344,
      0.06053582578897476,
      0.027758633717894554,
      -0.003128380049020052,
      0.05834469199180603,
      0.02357959933578968,
      0.02804144285619259,
      0.030197948217391968,
      -0.04149187356233597,
      0.01756531000137329,
      -0.0326370969414711,
      -0.019860949367284775,
      0.059752900153398514,
      0.026865912601351738,
      0.08145198225975037,
      -0.07439716160297394,
      0.026175417006015778,
      -0.007698152679949999,
      0.047891102731227875,
      -0.0014725897926837206,
      -0.03728390112519264,
      0.04214809089899063,
      -0.06414160132408142,
      0.09288746118545532,
      -0.015445064753293991,
      -0.04593782499432564,
      0.09534162282943726,
      -0.011874951422214508,
      -0.019207041710615158,
      0.02669914998114109,
      0.04559651017189026,
      0.010101591236889362,
      0.042758915573358536,
      -0.004563207272440195,
      -0.010448123328387737,
      -0.02100168727338314,
      -0.003998060245066881,
      0.01638462394475937,
      0.08170457929372787,
      -0.015249544754624367,
      -0.02131069451570511,
      0.0017298092134296894,
      -0.014624460600316525,
      -0.013814607635140419,
      -0.06723715364933014,
      -0.061224449425935745,
      0.04413144290447235,
      0.005180663429200649,
      -0.08317685127258301,
      -0.02040208876132965,
      0.00034425154444761574,
      -0.02146557904779911,
      0.007748800329864025,
      0.0035996546503156424,
      0.01181909441947937,
      -0.03740563616156578,
      -0.002574449870735407,
      -0.08901727199554443,
      -0.011330652050673962,
      -0.04735860973596573,
      0.052805691957473755,
      0.052815139293670654,
      0.09156588464975357,
      -0.05893190950155258,
      -0.0022257030941545963,
      0.05547620356082916,
      -0.01296970620751381,
      -0.04901625216007233,
      -0.021525859832763672,
      -0.006233566906303167,
      0.06368646770715714,
      -0.07197251915931702,
      -0.024553591385483742,
      -0.03307314217090607,
      0.07278613746166229,
      0.012903789058327675,
      -0.03156670182943344,
      0.04171847552061081,
      -0.001213874900713563,
      -0.07006064802408218,
      0.055585987865924835,
      -0.04843294993042946,
      0.015114830806851387,
      -0.09465134888887405,
      0.015550147742033005,
      0.01350170187652111,
      0.04241129010915756,
      -0.000994288013316691,
      0.00715705007314682,
      0.018785549327731133,
      -0.04107441380620003,
      -0.04195696860551834,
      -0.01969684660434723,
      0.028120344504714012,
      0.029385343194007874,
      -0.03569911792874336,
      -0.04524699226021767,
      0.03793984651565552,
      0.06799795478582382,
      0.044530753046274185,
      -0.030001763254404068,
      0.06252612918615341,
      -0.04329837113618851,
      0.08771173655986786,
      0.01824807934463024,
      -0.08386462926864624,
      0.0055889249779284,
      -0.009715219028294086,
      -0.008842130191624165,
      0.10500168055295944,
      -0.022390300408005714,
      0.03272494673728943,
      -0.0125680360943079,
      0.04067754000425339,
      0.023203933611512184,
      0.013487796299159527,
      -0.03293801099061966,
      -0.011979945935308933,
      -0.043532002717256546,
      -0.03830036520957947,
      -0.07686702162027359,
      -0.029462900012731552,
      -0.004555812571197748,
      -0.028352050110697746,
      0.0710407942533493,
      -0.04681628569960594,
      0.06996328383684158,
      -0.03028145432472229,
      0.04396076127886772,
      -0.0006718002259731293,
      -0.033667486160993576,
      -0.062211181968450546,
      0.050669413059949875,
      0.01972176320850849,
      0.022720232605934143,
      0.003368833102285862,
      0.010150923393666744,
      0.03598051890730858,
      -0.04643509164452553,
      0.02421569637954235,
      -0.04559750854969025,
      -0.03150792419910431,
      0.004716981202363968,
      -0.06578467786312103,
      -0.06115471571683884,
      0.0054078190587460995,
      0.04147530719637871,
      -0.07327967882156372,
      -0.025812508538365364,
      -0.014398785308003426,
      -0.008920456282794476,
      -0.10645132511854172,
      0.016311058774590492,
      0.04674382880330086,
      0.010475645773112774,
      -0.00794523861259222,
      -0.005330064799636602,
      -0.053224023431539536,
      -0.09406764060258865,
      -0.0021284730173647404,
      0.01670524850487709,
      -0.048320021480321884,
      -0.037080805748701096,
      0.14682328701019287,
      -0.0349535271525383,
      -0.0075684888288378716,
      0.02749454975128174,
      -0.008214068599045277,
      -0.008133606053888798,
      -0.04406265914440155,
      -0.032426536083221436,
      -0.033357035368680954,
      -0.008336661383509636,
      0.07291736453771591,
      0.02017452009022236,
      0.021488167345523834,
      0.04555245116353035,
      -0.050721049308776855,
      0.01805107668042183,
      0.01682998426258564,
      -0.09602520614862442,
      -0.015729838982224464,
      -0.11254748702049255,
      0.0499943308532238,
      0.03596825897693634,
      -0.11383840441703796,
      0.007732992991805077,
      -0.0748634934425354,
      0.06296580284833908,
      0.030534688383340836,
      -0.01782246306538582,
      0.015834292396903038,
      -0.08537779003381729,
      -0.027668878436088562,
      -0.01665733940899372,
      -0.047313373535871506,
      0.04031192883849144,
      -0.011981853283941746,
      -0.024485891684889793,
      -0.055278148502111435,
      0.009387776255607605,
      0.007865844294428825,
      0.004250473342835903,
      0.025067593902349472,
      0.03498822823166847,
      0.02475077658891678,
      -0.052195191383361816,
      -0.06629776209592819,
      -0.014871237799525261,
      -0.011393864639103413,
      0.02405630052089691,
      -0.034137774258852005,
      -0.08984389901161194,
      0.006321421358734369,
      0.014365511946380138,
      -0.01743254065513611,
      -0.013171112164855003,
      0.033597182482481,
      -0.05942153558135033,
      0.04643874987959862,
      0.013693288899958134,
      -4.382440238259733e-05,
      0.05342881381511688,
      0.0002512194332666695,
      0.052344877272844315,
      -0.03509308397769928,
      0.05809039622545242,
      -0.02436968870460987,
      -0.030351463705301285,
      0.13110144436359406,
      0.003751602256670594,
      -0.02365298941731453,
      -0.04926000535488129,
      -0.018594438210129738,
      -0.050350505858659744,
      0.027781281620264053,
      -0.017645636573433876,
      -0.02742338925600052,
      0.01048795972019434,
      -0.03301890194416046,
      -0.007174871861934662,
      -0.0016948037082329392,
      -0.07906154543161392,
      -0.07291550934314728,
      -0.011008086614310741,
      -0.011303942650556564,
      0.019746331498026848,
      0.044034939259290695,
      -0.009082406759262085,
      0.03445425629615784,
      0.05890137329697609,
      0.06473534554243088,
      0.02264811471104622,
      0.0755760669708252,
      0.03779451921582222,
      -0.05970710143446922,
      -0.06751401722431183,
      -0.06871297210454941,
      0.03922511637210846,
      0.07609784603118896,
      -0.037443388253450394,
      0.01322812121361494,
      -0.02807670831680298,
      0.07380560040473938,
      0.029489804059267044,
      -0.08545517921447754,
      0.06260998547077179,
      0.0008673033444210887,
      -0.02309921197593212,
      -0.03689530864357948,
      0.009449582546949387,
      0.0701255202293396,
      0.10631885379552841,
      0.008962029591202736,
      0.020706264302134514,
      0.012986864894628525,
      0.03159598633646965,
      0.024022381752729416,
      -0.07403580844402313,
      0.006424441002309322,
      -0.02341022901237011,
      0.006040550768375397,
      0.037048399448394775,
      0.002387558575719595,
      -0.009154555387794971,
      0.03419999033212662,
      0.013866035267710686,
      0.045295484364032745,
      0.07430247217416763,
      0.022592004388570786,
      -0.010787065140902996,
      -0.006256429478526115,
      -0.018904706463217735,
      0.029086604714393616,
      0.03929651901125908,
      0.018927738070487976,
      -0.05970219150185585,
      -0.015282073989510536,
      0.044472526758909225,
      0.00448817340657115,
      -0.05207115411758423,
      0.01654033735394478,
      -0.03791026398539543,
      -0.0384829007089138,
      0.0683216080069542,
      -0.00730590894818306,
      0.0001484065141994506,
      -0.09879686683416367,
      -0.047818705439567566,
      -0.04291706159710884,
      -0.0403992235660553,
      -0.06343009322881699,
      0.02292723022401333,
      -0.008991101756691933,
      0.0021522515453398228,
      -0.07203591614961624,
      -0.04349216818809509,
      -0.05925357714295387,
      0.020346755161881447,
      -0.014292724430561066,
      0.028272481635212898,
      -0.026696214452385902,
      -0.004471136722713709,
      0.01283267792314291,
      0.029396476224064827,
      0.15238721668720245,
      -0.0858091413974762,
      -0.0311761274933815,
      0.0992579236626625,
      -0.03195369243621826,
      0.01547188125550747,
      -0.0040625655092298985,
      0.098206527531147,
      -0.09485124051570892,
      -0.0675620585680008,
      -0.026665324345231056,
      0.013412818312644958,
      0.017842937260866165,
      0.05365845933556557,
      -0.002549269935116172,
      -0.0037441570311784744,
      -0.12048742175102234,
      -0.03633026033639908,
      0.021535251289606094,
      0.057806346565485,
      -0.05181775614619255,
      -0.014418890699744225,
      -0.024064578115940094,
      -0.040193796157836914,
      -0.029386525973677635,
      -0.023619169369339943,
      -0.07983972877264023,
      0.045809075236320496,
      0.007621020078659058,
      -0.02107330411672592,
      0.0580960176885128,
      0.021071169525384903,
      -0.018782492727041245,
      0.05144057050347328,
      -0.048004165291786194,
      0.008848782628774643,
      0.034369710832834244,
      -0.03263532370328903,
      0.024311918765306473,
      0.003075133543461561,
      -0.0032949624583125114,
      -0.02841472439467907,
      -0.03990456461906433,
      -0.001530586974695325,
      0.018029112368822098,
      -0.025362467393279076,
      0.0055808331817388535,
      0.053897175937891006,
      0.0860866904258728,
      -0.008355893194675446,
      0.060578618198633194,
      -0.07688871771097183,
      -0.034776024520397186,
      -0.015230970457196236,
      -0.026667408645153046,
      -0.045682650059461594,
      0.031654439866542816,
      -0.11680730432271957,
      0.035736363381147385,
      -0.05273047834634781,
      0.08595368266105652,
      0.012457608245313168,
      -0.004888876806944609,
      -0.002093689516186714,
      0.0898999348282814,
      -0.03340541943907738,
      0.03768299147486687,
      -0.11076264083385468,
      2.2398297005565837e-05,
      -0.023956626653671265,
      -0.006788312923163176,
      -0.042194996029138565,
      -0.08789727836847305,
      -0.07831503450870514,
      -0.058175791054964066,
      0.10879714787006378,
      0.01892743445932865,
      0.0734798014163971,
      0.03169602155685425,
      -0.028112169355154037,
      0.0501088909804821,
      -0.004003407433629036,
      0.021036727353930473,
      0.03820555657148361,
      0.045710332691669464,
      -0.020795093849301338,
      -0.006264121737331152,
      -0.01712833344936371,
      -0.02154313400387764,
      -0.024877188727259636,
      -0.10041442513465881,
      -0.04894896596670151,
      0.0013952214503660798,
      -0.09779893606901169,
      -0.09201817214488983,
      0.0075041851960122585,
      -0.027727242559194565,
      0.00636714743450284,
      -0.004484610166400671,
      0.012171433307230473,
      0.04354151338338852,
      0.04309883341193199,
      -0.05531977862119675,
      0.04197380691766739,
      0.05798010528087616,
      0.003394064260646701,
      0.0010729010682553053,
      -0.026179179549217224,
      0.037004485726356506,
      0.0480695478618145,
      -0.052051104605197906,
      -0.08931165188550949,
      -0.0633397027850151,
      -0.030036570504307747,
      -0.036305658519268036,
      -0.017749298363924026,
      0.058325380086898804,
      -0.022753696888685226,
      0.055543627589941025,
      -0.0025553321465849876,
      0.01989208161830902,
      -0.00994886178523302,
      -0.019841400906443596,
      -0.07486294209957123,
      -0.0533614307641983,
      0.036643169820308685,
      0.025767123326659203,
      -0.03088202327489853,
      0.028676388785243034,
      -0.09839674830436707,
      -0.005254595540463924,
      0.030313046649098396,
      0.018498167395591736,
      0.04870658367872238,
      -0.005167185328900814,
      -0.001638856716454029,
      0.02271021530032158,
      0.02594349905848503,
      -0.04950468987226486,
      0.024733470752835274,
      -0.03224656730890274,
      -0.008754212409257889,
      -0.024399733170866966,
      0.0637132003903389,
      -0.010622086934745312,
      0.06617201864719391,
      0.0988498404622078,
      -0.00023704262275714427
    ],
    [
      -0.018495239317417145,
      -0.04273855686187744,
      0.025311598554253578,
      0.010872360318899155,
      -0.00297296023927629,
      -0.03782195970416069,
      -0.034642480313777924,
      -0.021824413910508156,
      -0.020777465775609016,
      0.01282949186861515,
      0.06076546758413315,
      -0.028541669249534607,
      0.039009734988212585,
      0.03088955208659172,
      -0.02694673277437687,
      0.029400784522294998,
      -0.002690482884645462,
      0.02998535707592964,
      -0.08053424209356308,
      0.012843701057136059,
      0.013853068463504314,
      0.045406851917505264,
      -0.014861751347780228,
      0.027303338050842285,
      0.04860816150903702,
      -0.00321809365414083,
      0.0068672820925712585,
      -0.08478064835071564,
      -0.06598220020532608,
      0.04608633741736412,
      -0.038654036819934845,
      0.013051280751824379,
      -0.04581796005368233,
      0.028224289417266846,
      0.016672682017087936,
      0.04069838672876358,
      -0.05198095738887787,
      -0.06342779844999313,
      0.029926607385277748,
      -0.005793193355202675,
      0.005711940582841635,
      -0.026418223977088928,
      -0.07841726392507553,
      -0.04765014722943306,
      -0.0481821671128273,
      0.058830007910728455,
      -0.015702206641435623,
      0.024933546781539917,
      0.08330385386943817,
      0.09924206882715225,
      -0.036782409995794296,
      0.022468332201242447,
      -0.10105063021183014,
      0.017945334315299988,
      -0.03039313480257988,
      -0.07028613984584808,
      -0.01942874863743782,
      -0.012362164445221424,
      0.05007043108344078,
      0.03740113973617554,
      0.03282511234283447,
      0.06592868268489838,
      0.04883306100964546,
      0.00013218772073742002,
      -0.03818045184016228,
      0.026480643078684807,
      -0.022457944229245186,
      -0.006065984722226858,
      -0.03235167637467384,
      -0.025700239464640617,
      -0.00722899567335844,
      -0.00201191077940166,
      0.001898359041661024,
      0.022902967408299446,
      -0.03242794796824455,
      -0.0038010275457054377,
      -0.00880243070423603,
      0.02826859802007675,
      -0.01776980794966221,
      0.03161323443055153,
      0.025612633675336838,
      0.03141775354743004,
      0.027119021862745285,
      0.08062312752008438,
      -0.07863221317529678,
      0.05728632211685181,
      -0.013901359401643276,
      -0.06711086630821228,
      0.03582439944148064,
      0.10051851719617844,
      -0.004606153815984726,
      0.025950759649276733,
      0.06208328530192375,
      0.0031290282495319843,
      0.0397065095603466,
      -0.013088583014905453,
      0.011605840176343918,
      0.022078199312090874,
      0.009497065097093582,
      0.0400826632976532,
      0.04971984773874283,
      -0.04097072035074234,
      -0.0812487006187439,
      0.015344684012234211,
      -0.054053738713264465,
      0.026046400889754295,
      -0.018608558923006058,
      0.0033270649146288633,
      0.08025424927473068,
      0.029279494658112526,
      0.019990071654319763,
      0.052383676171302795,
      0.03236844018101692,
      0.022788064554333687,
      0.025627493858337402,
      -0.03231723606586456,
      0.10476892441511154,
      -0.06282107532024384,
      0.05891376733779907,
      0.10806483030319214,
      0.06777043640613556,
      0.04119156673550606,
      0.07310227304697037,
      -0.0109120924025774,
      -0.005378130357712507,
      0.008360235951840878,
      -0.050372082740068436,
      -0.04163241758942604,
      -0.08236055076122284,
      0.08225145936012268,
      -0.06065993383526802,
      -0.04079476743936539,
      -0.017288729548454285,
      -0.06268766522407532,
      -0.027281513437628746,
      0.005000876262784004,
      0.011685715056955814,
      0.0423298217356205,
      0.010481507517397404,
      -0.0318819098174572,
      -0.05837138369679451,
      -0.062494486570358276,
      0.008782793767750263,
      0.09220369160175323,
      0.02375701442360878,
      0.04993784427642822,
      -0.005562943406403065,
      0.024904120713472366,
      0.03259740397334099,
      -0.01930559054017067,
      0.015073472633957863,
      -0.014009182341396809,
      -0.034626517444849014,
      -0.023261064663529396,
      -0.017935920506715775,
      0.0419369675219059,
      0.002341659739613533,
      0.025138217955827713,
      0.01947578601539135,
      -0.012038804590702057,
      0.028172729536890984,
      0.029002593830227852,
      0.03222071751952171,
      0.020573565736413002,
      -0.013368779793381691,
      -0.051571425050497055,
      0.07167184352874756,
      0.013792337849736214,
      0.04409449175000191,
      0.016974810510873795,
      -0.04949585348367691,
      0.10332877188920975,
      -0.019594408571720123,
      0.014834506437182426,
      -0.09871792048215866,
      -0.04084094613790512,
      0.016426758840680122,
      0.016888422891497612,
      -0.04603024572134018,
      -0.06278597563505173,
      0.029049091041088104,
      -0.008747799322009087,
      -0.01095518283545971,
      0.03602776303887367,
      0.036531563848257065,
      0.019485298544168472,
      -0.0016186194261536002,
      -0.04088057577610016,
      -0.014796612784266472,
      -0.06287695467472076,
      0.002951710019260645,
      -0.019523674622178078,
      -0.060436446219682693,
      -0.0598021037876606,
      0.06088528782129288,
      -0.011570041999220848,
      0.10338161885738373,
      -0.0001080550646292977,
      -0.08232169598340988,
      -0.1417166292667389,
      0.08054538071155548,
      0.017339468002319336,
      -0.047931376844644547,
      0.03563635051250458,
      0.0738237276673317,
      0.03164051100611687,
      0.007850168272852898,
      0.004432993475347757,
      0.06554534286260605,
      -0.0503166988492012,
      0.06069912016391754,
      -0.02533850632607937,
      -0.0015652526635676622,
      0.008939363062381744,
      -0.04912130907177925,
      -0.07820317894220352,
      0.06950151175260544,
      0.0854794904589653,
      0.015646493062376976,
      0.016891876235604286,
      0.023824401199817657,
      0.13087357580661774,
      -0.01637784019112587,
      -0.043423376977443695,
      -0.006787176709622145,
      -0.057275138795375824,
      0.03782640025019646,
      0.00638907914981246,
      0.1001456007361412,
      -0.027013087645173073,
      0.062027398496866226,
      -0.0045644561760127544,
      -0.005120018031448126,
      0.016740629449486732,
      0.010301012545824051,
      0.07452388852834702,
      0.021007642149925232,
      0.005421536508947611,
      -0.006066780537366867,
      -0.022963544353842735,
      0.009137776680290699,
      0.00976532232016325,
      0.01432312186807394,
      -0.0008333010482601821,
      0.09006454795598984,
      -0.0019722492434084415,
      0.008260689675807953,
      0.0037804394960403442,
      -0.06384915113449097,
      -0.06706677377223969,
      -0.008749989792704582,
      0.04660072177648544,
      0.04312700778245926,
      0.02826206386089325,
      -0.01318114623427391,
      0.11466823518276215,
      -0.025946427136659622,
      -0.031159263104200363,
      -0.02748659811913967,
      0.03880591690540314,
      0.0221908800303936,
      -0.01680482178926468,
      -0.023405490443110466,
      -0.04226402938365936,
      -0.03761059418320656,
      0.03709540143609047,
      -0.026369832456111908,
      -0.025737343356013298,
      0.03719594329595566,
      -0.0057990411296486855,
      -0.009611662477254868,
      -0.003491703886538744,
      0.015947017818689346,
      -0.004100879188627005,
      0.023476023226976395,
      0.038863688707351685,
      0.04756053164601326,
      0.015112478286027908,
      0.02209603041410446,
      0.011307509616017342,
      0.06353692710399628,
      0.051593635231256485,
      -0.005811219569295645,
      -0.03853195160627365,
      0.010187237523496151,
      0.03105299361050129,
      -0.03670923039317131,
      0.0193843562155962,
      0.03823643550276756,
      -0.03970231115818024,
      -0.0057317037135362625,
      0.02216312848031521,
      0.04333074018359184,
      -0.036131810396909714,
      -0.07003606855869293,
      0.04486212134361267,
      -0.017344478517770767,
      0.025257160887122154,
      0.04923766478896141,
      0.00765320286154747,
      0.023016279563307762,
      0.0303339846432209,
      -0.022686360403895378,
      0.04680117964744568,
      0.019468098878860474,
      -0.017465289682149887,
      0.03262198716402054,
      0.00720994733273983,
      -0.06363372504711151,
      0.06496067345142365,
      -0.03095586784183979,
      -0.015188181772828102,
      -0.006251995917409658,
      -0.06222883611917496,
      0.018342848867177963,
      0.06239183992147446,
      0.0042129200883209705,
      -0.012810118496418,
      -0.06544186919927597,
      -0.00012051791418343782,
      0.04997869208455086,
      0.012784698978066444,
      0.0101217832416296,
      0.020652951672673225,
      0.07098788022994995,
      0.02433951571583748,
      -0.021111629903316498,
      0.10762637853622437,
      -0.05024660378694534,
      -0.046451136469841,
      -0.06346916407346725,
      -0.03254503756761551,
      -0.00967668741941452,
      -0.040237780660390854,
      -0.05824331194162369,
      0.06397171318531036,
      -0.003010859014466405,
      -0.0481841117143631,
      0.04517960548400879,
      -0.02326802909374237,
      -0.04971379414200783,
      -0.01979946345090866,
      0.0032228108029812574,
      0.040340736508369446,
      -0.024385714903473854,
      -0.02582220919430256,
      0.015074699185788631,
      -0.002512536710128188,
      -0.0454338863492012,
      -0.02089146338403225,
      -0.06184696406126022,
      0.0720210000872612,
      0.06278620660305023,
      0.06892828643321991,
      -0.016999633982777596,
      -0.002781746443361044,
      -0.04721369221806526,
      -0.04673143848776817,
      -0.047381386160850525,
      0.06068507581949234,
      0.031954191625118256,
      0.0016191380564123392,
      0.07372723519802094,
      0.006570860743522644,
      -0.1104707196354866,
      -0.022419853135943413,
      0.08428645879030228,
      -0.0009366321028210223,
      0.030525853857398033,
      -0.033111270517110825,
      0.0824756845831871,
      0.0008294584695249796,
      0.04580753296613693,
      -0.04589148238301277,
      0.01609089784324169,
      -0.04570990055799484,
      0.04597027227282524,
      -0.061630163341760635,
      0.07181045413017273,
      0.07847277075052261,
      -0.011896874755620956,
      -0.03362083062529564,
      0.03797003626823425,
      -0.00550162885338068,
      -0.0497942753136158,
      0.10394657403230667,
      0.04636833444237709,
      -0.01860770955681801,
      -0.05274825915694237,
      0.006307302042841911,
      0.049357820302248,
      0.02122281678020954,
      0.08358725160360336,
      -0.012651968747377396,
      -0.05506478250026703,
      -0.01141165941953659,
      0.021027475595474243,
      -0.02088904194533825,
      0.021925894543528557,
      0.009377773851156235,
      0.046160660684108734,
      0.06036042794585228,
      0.0015002371510490775,
      0.045712802559137344,
      0.018351975828409195,
      0.04126138240098953,
      0.038147781044244766,
      0.09806790202856064,
      0.000497488712426275,
      0.038258638232946396,
      0.005900059826672077,
      -0.021329760551452637,
      -0.04756318777799606,
      0.050795186311006546,
      -0.0356956422328949,
      -0.0017445121193304658,
      -0.018882907927036285,
      0.09671459347009659,
      -0.015698665753006935,
      -0.014494569040834904,
      -0.042101748287677765,
      0.060290951281785965,
      -0.05051345378160477,
      -0.07972005009651184,
      0.02379116415977478,
      -0.032042939215898514,
      0.007194601930677891,
      0.016437359154224396,
      -0.004662937484681606,
      -0.011943761259317398,
      0.0019487955141812563,
      0.011120716109871864,
      0.05846364051103592,
      0.048197563737630844,
      0.0052007404156029224,
      -0.06477416306734085,
      -0.0632784441113472,
      -0.0033521836157888174,
      0.04224615916609764,
      -0.01642763800919056,
      0.037108927965164185,
      0.05355915054678917,
      -0.030543560162186623,
      0.0660238191485405,
      -0.0015207482501864433,
      -0.037327297031879425,
      -0.003061353461816907,
      0.013555205427110195,
      0.007489344570785761,
      -0.047299884259700775,
      0.06630463898181915,
      0.00156024016905576,
      -0.015165251679718494,
      0.03851771354675293,
      -0.008782899007201195,
      0.0406714528799057,
      0.05209774896502495,
      0.04948920011520386,
      0.03253836929798126,
      -0.014919638633728027,
      0.014429336413741112,
      -0.015910528600215912,
      0.025314349681138992,
      0.06331804394721985,
      0.02941533923149109,
      -0.05233723670244217,
      0.03446526080369949,
      0.020569274201989174,
      -0.11515132337808609,
      0.006962259765714407,
      0.07961363345384598,
      -0.0035012762527912855,
      0.03287563845515251,
      -0.0372762605547905,
      -0.03615613654255867,
      -0.05749163404107094,
      0.059405408799648285,
      0.009545059874653816,
      0.0008154874667525291,
      -0.032913368195295334,
      -0.008101669140160084,
      -0.020434947684407234,
      0.04375031590461731,
      -0.016489235684275627,
      0.03318074345588684,
      -0.028881916776299477,
      -0.04965551197528839,
      0.05526162311434746,
      -0.11229222267866135,
      0.0035463476087898016,
      0.015194577164947987,
      -0.01965535432100296,
      0.011937904171645641,
      0.0672604888677597,
      0.008457010611891747,
      -0.020154766738414764,
      -0.0370238833129406,
      0.021060975268483162,
      0.006874825339764357,
      -0.03385954350233078,
      -0.017257971689105034,
      0.0457693450152874,
      0.029458731412887573
    ],
    [
      -0.0718538910150528,
      -0.00646771676838398,
      0.011678474955260754,
      0.04516788199543953,
      0.060396961867809296,
      -0.04277504235506058,
      -0.09549301862716675,
      -0.08960069715976715,
      0.05355352535843849,
      0.052359309047460556,
      -0.05003494396805763,
      -0.0560745894908905,
      -0.00656229117885232,
      0.07029956579208374,
      0.04812334477901459,
      -0.0731583684682846,
      -0.03251960501074791,
      -0.0312200840562582,
      0.09209848195314407,
      -0.0037936123553663492,
      -0.05164070054888725,
      -0.03882919251918793,
      0.06664769351482391,
      -0.005782952532172203,
      0.009008209221065044,
      0.07428264617919922,
      -0.09385789185762405,
      -0.029409481212496758,
      0.03332670405507088,
      -0.02480015903711319,
      0.08171798288822174,
      0.06828410178422928,
      0.11863232403993607,
      0.00268191983923316,
      0.00653676176443696,
      0.02995332144200802,
      0.01790686696767807,
      -0.03152799606323242,
      0.009261061437427998,
      -0.04592393711209297,
      0.019874487072229385,
      -0.008238133043050766,
      -0.023314839228987694,
      -0.005450531840324402,
      0.10179560631513596,
      -0.10369314253330231,
      0.0553186759352684,
      0.04933052137494087,
      0.062142789363861084,
      -0.048034120351076126,
      -0.05047696456313133,
      0.02460273914039135,
      0.033726420253515244,
      -0.08553404361009598,
      0.040543101727962494,
      -0.04317515715956688,
      0.0055484045296907425,
      -0.04911218211054802,
      -0.0021608921233564615,
      -0.02076209895312786,
      0.00370611110702157,
      0.02195427007973194,
      -0.03445940464735031,
      -0.03018340654671192,
      -0.051398877054452896,
      0.0014596248511224985,
      -0.00607724953442812,
      0.0289602130651474,
      -0.02391943149268627,
      -0.050861939787864685,
      0.02252035029232502,
      0.03490137308835983,
      0.00386400381103158,
      -0.0008035581558942795,
      -0.08245931565761566,
      -0.041665975004434586,
      -0.0927678793668747,
      0.025933384895324707,
      0.05180763825774193,
      -0.010425232350826263,
      0.03575878217816353,
      -0.008477145805954933,
      -0.011846968904137611,
      -0.018932227045297623,
      -0.02856563776731491,
      -0.010308008641004562,
      0.02341996692121029,
      0.057417307049036026,
      0.0489778034389019,
      -0.022655988112092018,
      -0.009788555093109608,
      0.0527881495654583,
      -0.0022991125006228685,
      0.013902727514505386,
      0.04865245893597603,
      -0.02122175134718418,
      -0.027335427701473236,
      0.10835389047861099,
      -0.07066568732261658,
      0.009158446453511715,
      0.04836397245526314,
      -0.04119086265563965,
      -0.038047246634960175,
      0.07026328146457672,
      -0.029853662475943565,
      0.06121639534831047,
      0.02894868329167366,
      -0.08164481073617935,
      -0.005870599765330553,
      0.005045829340815544,
      -0.030083371326327324,
      -0.007462221197783947,
      -0.027890972793102264,
      0.03268542140722275,
      0.04655100777745247,
      0.07711507380008698,
      -0.024746833369135857,
      -0.08533991128206253,
      0.09016738831996918,
      -0.0667281523346901,
      -0.013507290743291378,
      0.046468716114759445,
      0.08892574161291122,
      0.023390615358948708,
      0.05411190912127495,
      0.04926162585616112,
      0.04695910960435867,
      0.1334436684846878,
      0.04893210530281067,
      -0.07977286726236343,
      -0.034416474401950836,
      0.008459915407001972,
      -0.02086472511291504,
      -0.014198007062077522,
      0.018114712089300156,
      -0.04489535093307495,
      -0.005689327139407396,
      0.0476643443107605,
      -0.098599873483181,
      0.05053938925266266,
      -0.004549754783511162,
      -0.06865084916353226,
      0.014994785189628601,
      0.02850896678864956,
      0.004415993113070726,
      0.028174275532364845,
      -0.033559054136276245,
      -0.010985545814037323,
      0.024289734661579132,
      -0.006492977496236563,
      0.06665682047605515,
      0.005507152061909437,
      0.015105420723557472,
      -0.02094174176454544,
      0.04881506413221359,
      0.01753433793783188,
      -0.04467811807990074,
      -0.07203403860330582,
      -0.017170870676636696,
      0.07159285247325897,
      0.07007519155740738,
      0.010701085440814495,
      0.024951711297035217,
      0.09824610501527786,
      -0.0024140882305800915,
      -0.0187540240585804,
      -0.028861144557595253,
      -0.04308009892702103,
      0.015178823843598366,
      0.014317241497337818,
      -0.031303390860557556,
      -0.0343078151345253,
      -0.007607803680002689,
      0.03346582129597664,
      0.054456233978271484,
      -0.04615548253059387,
      -0.038332220166921616,
      -0.050781507045030594,
      -0.04318126291036606,
      0.018698561936616898,
      -0.04752284660935402,
      -0.10387501120567322,
      -0.07510297000408173,
      0.07437224686145782,
      -0.030032020062208176,
      -0.11025580763816833,
      -0.004602717235684395,
      -0.06449498236179352,
      -0.08800256252288818,
      0.06322028487920761,
      0.024659214541316032,
      -0.003904309356585145,
      0.0685819536447525,
      -0.024515412747859955,
      0.05451015755534172,
      -0.015580917708575726,
      0.03078606352210045,
      -0.09228932112455368,
      0.024579443037509918,
      -0.027066022157669067,
      -0.007170950528234243,
      -0.0669352263212204,
      0.0031383640598505735,
      -0.0410495400428772,
      0.034021370112895966,
      0.02890697866678238,
      0.007056931033730507,
      -0.03939412906765938,
      -0.025741957128047943,
      -0.012860220856964588,
      -0.07152561098337173,
      -0.06008736789226532,
      -0.04501686990261078,
      0.012849710881710052,
      0.05934895575046539,
      -0.038067013025283813,
      -0.061473727226257324,
      0.02763865329325199,
      0.10699401795864105,
      0.006726090796291828,
      0.015097388066351414,
      0.06388703733682632,
      -0.027427924796938896,
      0.04951205477118492,
      -0.01195347961038351,
      -0.04062519967556,
      -0.0688774585723877,
      0.010290981270372868,
      0.0880098044872284,
      -0.057343125343322754,
      0.012717235833406448,
      0.04365788772702217,
      -0.018829310312867165,
      -0.002637079218402505,
      0.035597968846559525,
      -0.024785852059721947,
      0.047150250524282455,
      -0.0018711199518293142,
      0.025449933484196663,
      -0.046143077313899994,
      0.0006769608007743955,
      0.07125936448574066,
      0.03476409241557121,
      -0.036994025111198425,
      0.08236312866210938,
      0.05975223705172539,
      -0.05011878162622452,
      0.04019175097346306,
      -0.07149925827980042,
      -0.07432999461889267,
      -0.061263300478458405,
      -0.05823759734630585,
      -0.026771914213895798,
      -0.0218997523188591,
      0.057447392493486404,
      -0.0003714626363944262,
      -0.022157255560159683,
      -0.052852388471364975,
      -0.016515206545591354,
      0.05596798285841942,
      -0.005549988243728876,
      -0.04782078415155411,
      -0.05271146446466446,
      0.03449128940701485,
      0.04935416951775551,
      0.006202506832778454,
      -0.05227198451757431,
      -0.03193167224526405,
      0.06840118020772934,
      -0.10543803125619888,
      -0.045096009969711304,
      -0.01684427633881569,
      0.048471927642822266,
      0.022173039615154266,
      0.01725665293633938,
      -0.0048257592134177685,
      0.09212896227836609,
      0.006211568601429462,
      0.013010946102440357,
      -0.03231208398938179,
      -0.08228591829538345,
      -0.0186480600386858,
      0.026962371543049812,
      -0.03253389894962311,
      -0.03784405067563057,
      -0.019969414919614792,
      0.07014292478561401,
      -0.005147215910255909,
      -0.030155200511217117,
      -0.07941468805074692,
      0.08871980756521225,
      -0.02355119027197361,
      0.041466861963272095,
      -0.01676427386701107,
      -0.032448310405015945,
      -0.02428407408297062,
      -0.0732070580124855,
      0.008967633359134197,
      0.024411708116531372,
      0.03719385340809822,
      0.03751952201128006,
      0.048586852848529816,
      -0.028038648888468742,
      -0.009448157623410225,
      0.0008096498786471784,
      0.02709958329796791,
      0.02813337929546833,
      -0.01011504977941513,
      0.045186541974544525,
      -0.029541168361902237,
      -0.0003049915540032089,
      -0.06410723179578781,
      -0.08702167868614197,
      -0.008354364894330502,
      -0.04761435091495514,
      0.022932233288884163,
      0.02743235044181347,
      -0.025370893999934196,
      0.014212280511856079,
      -0.0009671181323938072,
      0.012521648779511452,
      -0.06414488703012466,
      0.010539938695728779,
      -0.014821085147559643,
      -0.00797003973275423,
      0.009205659851431847,
      0.019306231290102005,
      -0.041967373341321945,
      -0.0441415049135685,
      -0.060882195830345154,
      0.028130806982517242,
      -0.001107765594497323,
      -0.0736720860004425,
      -0.0365193635225296,
      -0.027388572692871094,
      -0.018380355089902878,
      0.08832911401987076,
      0.06641785800457001,
      0.07873295992612839,
      0.08948136866092682,
      0.029798850417137146,
      0.051099762320518494,
      -0.0645676776766777,
      0.05484030395746231,
      0.05965567007660866,
      0.01553135085850954,
      -0.022825559601187706,
      0.03769790008664131,
      0.018068766221404076,
      -0.010586374439299107,
      -0.014086391776800156,
      0.0008617599960416555,
      -0.03949957340955734,
      -0.10133086889982224,
      -0.023285524919629097,
      0.024410801008343697,
      0.025906916707754135,
      0.022718386724591255,
      0.05727106332778931,
      0.014157997444272041,
      -0.03989456593990326,
      -0.014084204100072384,
      -0.04715576395392418,
      -0.08272869884967804,
      -0.040115028619766235,
      -0.010115306824445724,
      0.04087249934673309,
      -0.08169087767601013,
      0.05412167310714722,
      0.0021215793676674366,
      -0.03177618980407715,
      0.00010025256779044867,
      -0.013346117921173573,
      0.05868108198046684,
      0.04243921861052513,
      -0.019834429025650024,
      -0.023058710619807243,
      0.061819396913051605,
      0.0331544503569603,
      0.012808338738977909,
      -0.018020423129200935,
      -0.014359604567289352,
      0.048951078206300735,
      0.026525484398007393,
      0.014099693857133389,
      0.003775627352297306,
      -0.09134668856859207,
      -0.06006055325269699,
      0.009661857970058918,
      -0.00022188496950548142,
      0.04665163531899452,
      0.04619969427585602,
      0.0051172343082726,
      -0.007714082021266222,
      -0.026825111359357834,
      0.02433159202337265,
      0.015297087840735912,
      0.00947557482868433,
      -0.024117793887853622,
      -0.03371457755565643,
      0.026424933224916458,
      0.08966443687677383,
      0.052236128598451614,
      0.1236429288983345,
      -0.003213830990716815,
      0.02356959879398346,
      0.06559313833713531,
      0.03320185840129852,
      0.10692422837018967,
      0.02375265210866928,
      -0.02111927978694439,
      0.08648679405450821,
      -0.05853654444217682,
      -0.027740657329559326,
      -0.05406883731484413,
      -0.03660306707024574,
      0.025265919044613838,
      -0.05351913720369339,
      -0.009473450481891632,
      -0.07101085782051086,
      0.009898140095174313,
      0.06797296553850174,
      0.062073636800050735,
      -0.020092761144042015,
      -0.04080989956855774,
      -0.09921558201313019,
      -0.027915330603718758,
      -0.004121111705899239,
      -0.0674050971865654,
      0.0167246051132679,
      0.11445753276348114,
      -0.08129362016916275,
      -0.07673387974500656,
      0.04118892177939415,
      -0.0037071455735713243,
      0.039261672645807266,
      0.0277909766882658,
      0.07174528390169144,
      0.07094744592905045,
      -0.07236131280660629,
      0.0031573849264532328,
      0.038496602326631546,
      -0.06717194616794586,
      0.026763934642076492,
      -0.04569700360298157,
      -0.014339818619191647,
      0.09691786020994186,
      -0.06288839876651764,
      -0.057459987699985504,
      -0.06056315451860428,
      0.03135921433568001,
      -0.03901508077979088,
      0.03602249175310135,
      -0.005693664308637381,
      0.008516937494277954,
      0.0329933799803257,
      0.05203573405742645,
      -0.04804467782378197,
      -0.06214902177453041,
      -0.003978582564741373,
      0.04751277342438698,
      0.005070885177701712,
      -0.026694871485233307,
      -0.054900530725717545,
      0.012973721139132977,
      -0.06981408596038818,
      0.10447029024362564,
      0.011876256205141544,
      -0.020883183926343918,
      -0.013393722474575043,
      -0.02288879081606865,
      -0.017510393634438515,
      0.012631520628929138,
      -0.01228666864335537,
      -0.02735690213739872,
      -0.020097320899367332,
      -0.0004164343699812889,
      -0.044342562556266785,
      0.004027858842164278,
      0.07811342924833298,
      -0.0249165091663599,
      0.091908298432827,
      -0.012316652573645115,
      -0.01435060054063797,
      0.0282609723508358,
      0.019883975386619568,
      -0.0039879088290035725,
      -0.0450873039662838,
      -0.040394362062215805,
      0.03894389420747757,
      -0.0171642005443573,
      0.016780182719230652,
      0.011152563616633415,
      -0.07923600822687149,
      0.052260104566812515,
      -0.11508104205131531,
      0.028235143050551414,
      0.0069167097099125385,
      0.06746659427881241,
      0.057862088084220886,
      0.03652404248714447,
      -0.002264012349769473,
      0.004229189828038216
    ],
    [
      -0.0353974848985672,
      0.07197930663824081,
      0.0208082664757967,
      -0.05118841677904129,
      0.008095364086329937,
      -0.050783153623342514,
      -0.04037535935640335,
      -0.007232278119772673,
      0.005291251465678215,
      -0.031819898635149,
      -0.05973311886191368,
      0.04804852232336998,
      0.004344521556049585,
      0.01707683876156807,
      0.03819574788212776,
      0.008993152529001236,
      -0.006766052450984716,
      0.06612665206193924,
      0.06068183481693268,
      -0.020754557102918625,
      -0.008268661797046661,
      0.030028613284230232,
      -0.08003027737140656,
      0.11049429327249527,
      -0.004500672686845064,
      0.13029745221138,
      0.08419696986675262,
      0.054482601583004,
      -0.028903307393193245,
      -0.0008045902941375971,
      0.0366956889629364,
      -0.08283545821905136,
      0.00291357166133821,
      -0.014224772341549397,
      0.09076680988073349,
      -0.005183765199035406,
      -0.011631571687757969,
      -0.023782167583703995,
      0.04789314791560173,
      0.12272530794143677,
      -0.0029094538185745478,
      0.02258911356329918,
      -0.06038838252425194,
      -0.03791011497378349,
      0.002733888104557991,
      -0.03280779346823692,
      -0.01351199485361576,
      0.12478045374155045,
      0.1462419331073761,
      0.005130623932927847,
      -0.007023981306701899,
      -0.017996497452259064,
      0.022547485306859016,
      0.05486282706260681,
      0.06752080470323563,
      0.005142458248883486,
      0.009424622170627117,
      0.03755875676870346,
      0.03711780160665512,
      0.039099570363759995,
      0.07076021283864975,
      0.019040856510400772,
      0.10826649516820908,
      -0.08060646057128906,
      0.03183860704302788,
      0.012480886653065681,
      -0.023663409054279327,
      -0.07514698058366776,
      0.0038813832215964794,
      0.0007115753251127899,
      -0.0942821204662323,
      0.04618021473288536,
      0.002005119575187564,
      -0.059893377125263214,
      0.04574811831116676,
      -0.018505848944187164,
      0.02248540334403515,
      0.024658281356096268,
      0.024184077978134155,
      -0.033524882048368454,
      0.019014788791537285,
      0.029494542628526688,
      -0.03733137622475624,
      0.028428468853235245,
      -0.004449355415999889,
      -0.006566979922354221,
      -0.003633255371823907,
      -0.008508408442139626,
      0.04469713941216469,
      0.00947293546050787,
      0.03152001276612282,
      0.004945315886288881,
      0.01778598502278328,
      0.01668575406074524,
      0.09234723448753357,
      0.036015741527080536,
      -0.04204833135008812,
      -0.03160049393773079,
      -0.008462293073534966,
      -0.0025395634584128857,
      0.0608116090297699,
      -0.009520773775875568,
      -0.0009547226363793015,
      0.14064179360866547,
      -0.0592564158141613,
      0.05050774663686752,
      0.041722528636455536,
      0.03951958566904068,
      0.02660754695534706,
      0.043089523911476135,
      -0.01028354186564684,
      0.050162192434072495,
      -0.04972758889198303,
      0.03477002680301666,
      0.03604428097605705,
      0.008242746815085411,
      -0.06463869661092758,
      -0.040913600474596024,
      -0.003242994425818324,
      -0.061482153832912445,
      0.05306214839220047,
      0.018604137003421783,
      -0.0548255629837513,
      0.035900212824344635,
      0.007130509242415428,
      -0.018669648095965385,
      0.06396680325269699,
      0.018603911623358727,
      0.06161117181181908,
      -0.001279318006709218,
      -0.038070518523454666,
      -0.03812846168875694,
      -0.0988810658454895,
      0.0010636997176334262,
      0.014278889633715153,
      0.004181981552392244,
      -0.004144689533859491,
      -0.03528055548667908,
      -0.06371200084686279,
      -0.02325495518743992,
      0.04163798689842224,
      -0.0243512149900198,
      0.03624783456325531,
      -0.004297647159546614,
      0.027632147073745728,
      -0.08881878852844238,
      0.0644935593008995,
      0.04626849293708801,
      0.010856758803129196,
      -0.06290900707244873,
      0.03812728822231293,
      0.06992560625076294,
      0.007202747743576765,
      -0.07024750858545303,
      -0.09159059822559357,
      0.03998851031064987,
      0.06409084796905518,
      -0.01669575273990631,
      -0.008706927299499512,
      0.0018659260822460055,
      0.0001503792591392994,
      -0.027464302256703377,
      0.045200951397418976,
      0.024294313043355942,
      0.03304676339030266,
      0.029765397310256958,
      0.02135487087070942,
      -0.0906558632850647,
      -0.002055823802947998,
      -0.01320758555084467,
      0.048829786479473114,
      0.011824415065348148,
      0.01025006826967001,
      -0.055484771728515625,
      0.058136627078056335,
      0.0709189623594284,
      0.04543783888220787,
      -0.05283917114138603,
      -0.03579080477356911,
      0.0459478460252285,
      0.061998460441827774,
      0.007781020365655422,
      0.023291489109396935,
      0.0675494447350502,
      -0.066329725086689,
      -0.05495882034301758,
      -0.07138043642044067,
      0.089903324842453,
      -0.026338893920183182,
      0.008785930462181568,
      -0.0186916533857584,
      0.03916526958346367,
      0.03542008623480797,
      -0.012632877565920353,
      -0.0997316762804985,
      -0.021155623719096184,
      -0.005364134907722473,
      0.014316311106085777,
      0.022968966513872147,
      0.0056914472952485085,
      0.04904493689537048,
      -0.025816844776272774,
      -0.02210037223994732,
      -0.046586185693740845,
      -0.05255814641714096,
      -0.02335023321211338,
      -0.005943308584392071,
      -0.04240553826093674,
      0.007321526762098074,
      -0.02983279339969158,
      -0.02862938866019249,
      -0.08772549778223038,
      0.002961698453873396,
      -0.046228643506765366,
      0.02273651398718357,
      -0.06460537761449814,
      -0.038411982357501984,
      -0.04195919260382652,
      0.03989445045590401,
      -0.03586850315332413,
      0.030215105041861534,
      -0.0204098392277956,
      0.021183064207434654,
      0.02594647742807865,
      -0.018471647053956985,
      -0.0023606542963534594,
      0.01878291182219982,
      0.05609352886676788,
      -0.026214299723505974,
      -0.03843941539525986,
      0.025760037824511528,
      0.0368722565472126,
      0.046179741621017456,
      -0.053592246025800705,
      -0.005220775026828051,
      -0.043352335691452026,
      0.042823780328035355,
      0.06500004231929779,
      -0.028646057471632957,
      0.013242476619780064,
      -0.060434333980083466,
      0.01418209820985794,
      0.07878975570201874,
      0.008690924383699894,
      0.0681486651301384,
      -0.02083015814423561,
      0.04760507121682167,
      0.09280261397361755,
      -0.0044372896663844585,
      -0.010933056473731995,
      -0.009745322167873383,
      0.008670320734381676,
      0.03826340660452843,
      -0.025151953101158142,
      0.045074690133333206,
      0.05807812884449959,
      0.0010777672287076712,
      0.0017257464351132512,
      -0.11185836791992188,
      0.04601303115487099,
      0.039029691368341446,
      0.06965997070074081,
      0.07387226819992065,
      0.005894942674785852,
      0.04444942995905876,
      -0.011986322700977325,
      -0.006750426720827818,
      0.01838836632668972,
      -0.010906459763646126,
      -0.06495469808578491,
      0.061696551740169525,
      -0.03718947619199753,
      0.004869556054472923,
      0.05130203440785408,
      -0.01599445939064026,
      0.1286841630935669,
      0.08222009986639023,
      -0.07289326190948486,
      -0.05718309059739113,
      -0.05337980389595032,
      0.0448811836540699,
      1.459449777030386e-06,
      0.042698949575424194,
      0.025525325909256935,
      0.02105623669922352,
      0.002895011566579342,
      0.008973020128905773,
      0.015639416873455048,
      0.007493847981095314,
      -0.00660675298422575,
      0.0011347699910402298,
      -0.02515336312353611,
      0.007930010557174683,
      -0.04028240218758583,
      -0.05525524169206619,
      -0.02880084700882435,
      0.06088520586490631,
      -0.05053630843758583,
      0.04964457079768181,
      0.003045672085136175,
      -0.011536989361047745,
      -0.043729063123464584,
      -0.02089642360806465,
      0.06000642105937004,
      0.013890260830521584,
      0.09982366859912872,
      0.018854716792702675,
      0.05554573982954025,
      -0.05924787372350693,
      0.005671520717442036,
      -0.04692574962973595,
      -7.092641317285597e-05,
      -0.06897846609354019,
      0.04903976619243622,
      0.08436685800552368,
      -0.003909865859895945,
      0.0895344614982605,
      -0.07845159620046616,
      0.01397042814642191,
      -0.0437270849943161,
      0.030006250366568565,
      -0.06007905304431915,
      0.06975533068180084,
      -0.053872741758823395,
      -0.043132487684488297,
      0.0074340179562568665,
      -0.04678403213620186,
      0.08864304423332214,
      -0.007927739061415195,
      -0.029109222814440727,
      0.004792497027665377,
      -0.03996816277503967,
      -0.04334158077836037,
      -0.06297392398118973,
      0.05595747008919716,
      -0.01454921718686819,
      0.09610282629728317,
      -0.004450493957847357,
      -0.1101141944527626,
      0.004777777940034866,
      0.020984753966331482,
      -0.02538539096713066,
      -0.03579046204686165,
      -0.04754575341939926,
      0.026408042758703232,
      -0.019891805946826935,
      -0.0028356665279716253,
      -0.019508546218276024,
      0.0029088438022881746,
      0.01725819706916809,
      0.05376426503062248,
      -0.056344203650951385,
      0.047858815640211105,
      -0.10097998380661011,
      0.021458154544234276,
      -0.015971079468727112,
      0.01770816184580326,
      -0.042266715317964554,
      -0.014482803642749786,
      -0.036475230008363724,
      0.025617917999625206,
      0.052518416196107864,
      0.05860235169529915,
      -0.04553256928920746,
      -0.015877464786171913,
      -0.00029658558196388185,
      0.01764887571334839,
      -0.032886847853660583,
      0.013331952504813671,
      0.05646331235766411,
      0.05739768221974373,
      -0.007831144146621227,
      -0.1637009084224701,
      0.05474179983139038,
      0.029341137036681175,
      0.02165086567401886,
      0.015703393146395683,
      0.0018601366318762302,
      0.06569305062294006,
      0.05257325991988182,
      0.027953000739216805,
      0.012839490547776222,
      -0.016253162175416946,
      0.055269669741392136,
      0.0014652585377916694,
      0.025990420952439308,
      0.0022068284451961517,
      -0.05699487775564194,
      -0.001360985916107893,
      0.09005717933177948,
      -0.006365286186337471,
      -0.08326245099306107,
      -0.05840638652443886,
      0.023994889110326767,
      0.022350434213876724,
      0.0562216192483902,
      -0.020413396880030632,
      0.020878374576568604,
      -0.025764353573322296,
      -0.00988102238625288,
      0.09635384380817413,
      0.03407803177833557,
      -0.002878874773159623,
      -0.005923175718635321,
      -0.006954794749617577,
      0.004311729688197374,
      0.11833975464105606,
      -0.011126534081995487,
      0.021338609978556633,
      -0.05868060886859894,
      0.04669170826673508,
      -0.032102786004543304,
      0.039205968379974365,
      0.07181298732757568,
      -0.06493375450372696,
      0.0041478415951132774,
      0.07507012784481049,
      0.00010227555321762338,
      0.01939074508845806,
      -0.0008005403215065598,
      0.03547755628824234,
      0.027913473546504974,
      -0.01933402009308338,
      0.044134899973869324,
      0.02985095977783203,
      -0.008681952022016048,
      0.05143493786454201,
      0.010588880628347397,
      -0.06524389237165451,
      -0.10337679088115692,
      0.019563429057598114,
      -0.07989834994077682,
      -0.013027660548686981,
      0.04701074957847595,
      0.06080552190542221,
      -0.054308004677295685,
      0.010655206628143787,
      0.014271737076342106,
      -0.03791527822613716,
      -0.051961444318294525,
      0.03300643712282181,
      -0.057755421847105026,
      0.04880169779062271,
      -0.017734481021761894,
      0.03731724992394447,
      0.05773266404867172,
      -0.008280727081000805,
      0.012544159777462482,
      -0.043455593287944794,
      -0.055587247014045715,
      -0.03338557109236717,
      -0.019221600145101547,
      -0.011031090281903744,
      -0.04932679235935211,
      -0.057410743087530136,
      -0.04184712469577789,
      0.07500810921192169,
      0.0427420474588871,
      -0.06246976554393768,
      -0.0678999200463295,
      0.07408283650875092,
      0.07532091438770294,
      -0.0444483757019043,
      0.0836089700460434,
      -0.030772089958190918,
      -0.0030736832413822412,
      0.04066990315914154,
      -0.006725844461470842,
      0.003797318320721388,
      -0.021971669048070908,
      0.023479511961340904,
      -0.03798268362879753,
      -0.0010527503909543157,
      0.027013881132006645,
      -0.012297569774091244,
      -0.04429549723863602,
      -0.013535024598240852,
      0.04543433338403702,
      -0.046124186366796494,
      0.01949560083448887,
      -0.019412778317928314,
      0.02349824830889702,
      -0.030428200960159302,
      -0.05953558161854744,
      -0.047319889068603516,
      -0.06162333860993385,
      -0.040340978652238846,
      0.018193678930401802,
      -0.02626192569732666,
      0.03714543581008911,
      -0.030401326715946198,
      0.02356254681944847,
      -0.00018317859212402254,
      0.06175956130027771,
      -0.010221350938081741,
      0.07668004930019379,
      0.023894013836979866,
      -0.03556045517325401,
      0.05009458214044571,
      0.10662137717008591,
      0.028700193390250206,
      -0.03401925042271614,
      0.02729840576648712
    ],
    [
      -0.052222225815057755,
      0.018401028588414192,
      0.012211974710226059,
      -0.06846678256988525,
      -0.0036298995837569237,
      0.06382755190134048,
      -0.040920332074165344,
      0.007712543476372957,
      0.003989356569945812,
      -0.011991274543106556,
      -0.08636796474456787,
      -0.021705985069274902,
      -0.0021884096786379814,
      0.0395871177315712,
      0.002036541700363159,
      -0.03498255833983421,
      0.033169496804475784,
      0.019997529685497284,
      0.0022524504456669092,
      0.007391272112727165,
      -0.08889812231063843,
      0.007529801689088345,
      0.014298331923782825,
      -0.011858934536576271,
      0.04723258316516876,
      0.0875922441482544,
      -0.03761542588472366,
      0.04213380813598633,
      -0.002053308067843318,
      0.01768619753420353,
      0.034933000802993774,
      0.03143000230193138,
      0.004763596225529909,
      0.020411547273397446,
      0.03522619977593422,
      -0.03021545335650444,
      -0.028186645358800888,
      -0.05636310949921608,
      -0.062100812792778015,
      0.053526390343904495,
      0.07628125697374344,
      -0.01682364009320736,
      -0.023513102903962135,
      -0.06004887446761131,
      0.048016391694545746,
      -0.0014493431663140655,
      0.04856424033641815,
      0.026899410411715508,
      -0.0014939785469323397,
      0.03706740587949753,
      -0.03791535645723343,
      0.017465956509113312,
      -0.028461148962378502,
      -0.01954369992017746,
      -0.010468462482094765,
      -0.06815362721681595,
      -0.023316005244851112,
      0.017789868637919426,
      0.04075797647237778,
      -0.1044451892375946,
      -0.051946189254522324,
      0.02446850948035717,
      -0.06657890230417252,
      -0.017656540498137474,
      -0.05176745727658272,
      -0.02141636796295643,
      -0.030881743878126144,
      -0.00939219631254673,
      0.0161479152739048,
      0.09492321312427521,
      0.034748759120702744,
      -0.004059091210365295,
      0.02927122823894024,
      -0.02788453921675682,
      0.019249966368079185,
      0.08300060778856277,
      -0.023936746641993523,
      -0.009338180534541607,
      0.0529974065721035,
      0.05472560599446297,
      -0.003003002842888236,
      0.0013465011725202203,
      -0.011091540567576885,
      -0.03460362181067467,
      -0.07546219229698181,
      0.042604174464941025,
      -0.008114073425531387,
      -0.027076764032244682,
      0.06655385345220566,
      -0.005464449059218168,
      -0.05489020049571991,
      -0.04154886305332184,
      -0.0016750595532357693,
      0.017344873398542404,
      -0.012579289264976978,
      0.05486750230193138,
      0.006474215537309647,
      0.023114822804927826,
      0.093340665102005,
      0.029942205175757408,
      -0.004453296773135662,
      0.02023770473897457,
      -0.07093147188425064,
      0.03229215741157532,
      0.0005367582780309021,
      0.06266555190086365,
      0.020854735746979713,
      -0.0030837333761155605,
      -0.016583124175667763,
      0.041944749653339386,
      0.057847801595926285,
      0.06434141099452972,
      -0.001413149875588715,
      -0.017389332875609398,
      -0.0031052948907017708,
      -0.018934426829218864,
      0.014023902826011181,
      -0.03614678233861923,
      0.022389765828847885,
      -0.02218945510685444,
      0.04872250556945801,
      0.005387393292039633,
      0.02012122981250286,
      0.038196444511413574,
      -0.021871397271752357,
      0.044321853667497635,
      0.030163800343871117,
      -0.10025128722190857,
      0.017715798690915108,
      -0.015201345086097717,
      -0.04021267220377922,
      -0.038698501884937286,
      -0.04244872182607651,
      -0.008437476120889187,
      0.05775691941380501,
      -0.008170878514647484,
      0.08253122866153717,
      -0.03467240929603577,
      0.0038759526796638966,
      -0.0502111092209816,
      0.030005719512701035,
      0.06684166193008423,
      -0.042390137910842896,
      -0.04635252431035042,
      0.0697457566857338,
      0.069265216588974,
      0.015509188175201416,
      0.07057535648345947,
      -0.03055260144174099,
      0.027032656595110893,
      0.013568037189543247,
      0.01872359961271286,
      0.006201507523655891,
      -0.09712432324886322,
      0.033958666026592255,
      -0.02581806667149067,
      0.03274524211883545,
      0.019071660935878754,
      0.03878076747059822,
      -0.05857469514012337,
      0.005989252123981714,
      -0.004738390911370516,
      -0.08611202985048294,
      -0.029103897511959076,
      0.007931100204586983,
      -0.04033433273434639,
      -0.024587368592619896,
      0.0043076034635305405,
      -0.006649456452578306,
      -0.041230857372283936,
      0.051364410668611526,
      0.0023539066314697266,
      0.004765435121953487,
      -0.03987106308341026,
      0.02312409318983555,
      0.006719880737364292,
      -0.03953033685684204,
      -0.009375974535942078,
      0.05500732362270355,
      0.025090111419558525,
      -0.02377026341855526,
      0.02232220768928528,
      -0.0684296116232872,
      0.014556290581822395,
      0.03852320834994316,
      0.03964716196060181,
      0.05785268917679787,
      -0.0205366350710392,
      -0.015736375004053116,
      -0.04979093372821808,
      0.06681278347969055,
      0.054800596088171005,
      -0.026257075369358063,
      -0.07698863744735718,
      -0.11005284637212753,
      0.016933077946305275,
      -0.013395965099334717,
      0.004236295819282532,
      0.0074867322109639645,
      -0.04662628099322319,
      0.0033599352464079857,
      -0.06914325803518295,
      0.05659133940935135,
      0.06401659548282623,
      0.014586844481527805,
      -0.044730979949235916,
      0.04163723438978195,
      -0.028078457340598106,
      0.007289578206837177,
      -0.0034376622643321753,
      0.07651587575674057,
      -0.030446887016296387,
      -0.05438452959060669,
      0.007849187590181828,
      -0.0271269753575325,
      0.007892114110291004,
      0.041086457669734955,
      -0.037835754454135895,
      -0.013716167770326138,
      -0.011382194235920906,
      -0.02997785620391369,
      0.032390955835580826,
      0.09941419959068298,
      0.020861340686678886,
      -0.06440872699022293,
      0.1057581752538681,
      0.007426049094647169,
      0.0053279646672308445,
      -0.055841803550720215,
      -0.02755264937877655,
      -0.02943090721964836,
      -0.013908905908465385,
      0.0917486846446991,
      -0.01666284166276455,
      -0.026384206488728523,
      0.011856344528496265,
      -0.003428822383284569,
      -0.12385408580303192,
      0.030587973073124886,
      -0.024502217769622803,
      0.03125281259417534,
      -0.08025834709405899,
      -0.037538621574640274,
      -0.007810857612639666,
      0.08270151913166046,
      -0.038167569786310196,
      0.0655144602060318,
      0.034095656126737595,
      0.0202455073595047,
      0.035872932523489,
      -0.12075240910053253,
      -0.026187343522906303,
      0.032912977039813995,
      0.08825583755970001,
      0.0373976044356823,
      0.07449372857809067,
      -0.033824000507593155,
      -0.04867643490433693,
      0.07970988005399704,
      0.02459278143942356,
      0.052093569189310074,
      -0.0030467829201370478,
      0.03746503219008446,
      -0.006817923858761787,
      0.00955711118876934,
      0.09762413054704666,
      -0.047320783138275146,
      0.018950214609503746,
      -0.006935285869985819,
      -0.061620574444532394,
      0.08549970388412476,
      -0.06653465330600739,
      0.07145926356315613,
      -0.006058276630938053,
      -0.06753632426261902,
      -0.03732804208993912,
      -0.0664076879620552,
      0.09339386224746704,
      -0.07230810075998306,
      0.06983727961778641,
      0.008016608655452728,
      -0.05450177937746048,
      0.010460574179887772,
      0.063027024269104,
      0.005416915286332369,
      0.04388093575835228,
      -0.03261559084057808,
      0.059365395456552505,
      -0.023773444816470146,
      -0.013669926673173904,
      0.04304637759923935,
      0.028006944805383682,
      0.020662816241383553,
      -0.04474997520446777,
      -0.07122661918401718,
      -0.05890281870961189,
      -0.03842884674668312,
      -0.015966730192303658,
      0.03801436349749565,
      0.03647618740797043,
      0.11184309422969818,
      -0.040092673152685165,
      -0.049641381949186325,
      -0.04867972806096077,
      0.0184780266135931,
      0.04011836275458336,
      0.07257212698459625,
      0.004201178904622793,
      0.0360107459127903,
      0.019324498251080513,
      -0.05215523764491081,
      -0.04976106807589531,
      -0.04180479422211647,
      0.043194111436605453,
      -0.042908795177936554,
      0.006141745951026678,
      -0.01635107584297657,
      0.044748686254024506,
      -0.04093910753726959,
      -0.009086047299206257,
      0.026138585060834885,
      -0.014595174230635166,
      0.0866561010479927,
      -0.06150723621249199,
      -0.01294220332056284,
      -0.0217675119638443,
      0.014137383550405502,
      -0.029391368851065636,
      0.04717037454247475,
      0.04963894933462143,
      0.05129343643784523,
      -0.07772745192050934,
      -0.0077823372557759285,
      -0.05752665922045708,
      -0.04306561127305031,
      0.049915604293346405,
      0.0030643048230558634,
      0.0015992316184565425,
      -0.0818401351571083,
      0.008164740167558193,
      -0.045746345072984695,
      -0.025377335026860237,
      0.03864626958966255,
      -0.032527774572372437,
      -0.03006480261683464,
      -0.006302444729954004,
      -0.021386733278632164,
      0.008567149750888348,
      0.015413268469274044,
      -0.004130263812839985,
      -0.009326864965260029,
      0.026577260345220566,
      0.023248346522450447,
      0.05527806282043457,
      0.014304385520517826,
      -0.019190125167369843,
      0.04013032838702202,
      -0.05162239447236061,
      0.009813488461077213,
      -0.05942465737462044,
      0.07259529829025269,
      0.016300756484270096,
      0.03326869383454323,
      0.00023518037050962448,
      -0.0479845367372036,
      0.010608837008476257,
      0.040060896426439285,
      0.009893570095300674,
      -0.04467329382896423,
      0.034812308847904205,
      -0.012260157614946365,
      0.08539088070392609,
      -0.037893146276474,
      -0.015582438558340073,
      -0.018704943358898163,
      0.012179396115243435,
      0.048906028270721436,
      0.010246632620692253,
      0.004870397504419088,
      -0.00019793518004007638,
      0.03288499265909195,
      -0.024011870846152306,
      -0.013278892263770103,
      0.01804853416979313,
      0.056546542793512344,
      -0.023060480132699013,
      -0.02630351483821869,
      -0.008922627195715904,
      0.011982465162873268,
      -0.09510572254657745,
      0.09480597078800201,
      -0.041467003524303436,
      -0.030435817316174507,
      0.0590440072119236,
      -0.07739130407571793,
      0.04422178491950035,
      0.07973350584506989,
      0.04374251142144203,
      0.018774494528770447,
      -0.10252707451581955,
      0.08602430671453476,
      0.0704914927482605,
      0.020856812596321106,
      0.04774722084403038,
      -0.0027065793983638287,
      -0.03082284703850746,
      0.009554672986268997,
      -0.02357572317123413,
      0.011277563869953156,
      -0.004483578260987997,
      -0.030094044283032417,
      0.0007878392934799194,
      0.03952031955122948,
      -0.00480431504547596,
      0.017631972208619118,
      0.010788178071379662,
      -0.07477065175771713,
      -0.07027816772460938,
      0.03306422382593155,
      0.09360240399837494,
      -0.029412562027573586,
      0.042325444519519806,
      -0.04442107304930687,
      0.053016677498817444,
      0.0032174340449273586,
      -0.009438260458409786,
      0.003153548575937748,
      -0.0530826672911644,
      -0.00687210401520133,
      -0.04518786072731018,
      -0.036312926560640335,
      -0.06425002217292786,
      -0.011629637330770493,
      -0.06123211979866028,
      -0.04784059152007103,
      0.038846056908369064,
      -0.02509283274412155,
      -0.016383176669478416,
      -0.029249712824821472,
      0.05388035625219345,
      -0.012290658429265022,
      0.047327909618616104,
      0.04395119100809097,
      0.0656035766005516,
      0.027869660407304764,
      0.030219588428735733,
      -0.04071550816297531,
      -0.014127898029983044,
      -0.004188507795333862,
      0.02698592096567154,
      0.03073367103934288,
      -0.04738328605890274,
      -0.012057242915034294,
      -0.06995616108179092,
      -0.045922815799713135,
      0.050488974899053574,
      0.04506906494498253,
      0.09799253940582275,
      0.026789801195263863,
      -0.050522711127996445,
      0.04009810835123062,
      0.03432305157184601,
      -0.01708534173667431,
      0.052937887609004974,
      -0.027520380914211273,
      -0.05416790023446083,
      0.027969956398010254,
      0.05852678790688515,
      -0.06917735934257507,
      -0.04066230356693268,
      0.07745078206062317,
      0.006837780121713877,
      0.08960866928100586,
      0.02601242996752262,
      -0.012999927625060081,
      0.05291254445910454,
      -0.06841742247343063,
      -0.02477308362722397,
      0.002053270349279046,
      0.0012666370021179318,
      -0.05332047864794731,
      0.017654644325375557,
      -0.07732242345809937,
      -0.022660966962575912,
      -0.010344118811190128,
      0.0967457965016365,
      0.016591256484389305,
      0.016642974689602852,
      0.04332730919122696,
      0.030537521466612816,
      0.023191357031464577,
      -0.021202491596341133,
      0.0005552198854275048,
      -0.00882826279848814,
      0.023334361612796783,
      -0.042676642537117004,
      -0.048591990023851395,
      -0.013615857809782028,
      -0.03606913611292839,
      -0.0015979533782228827,
      0.02903125248849392,
      -0.016891509294509888,
      -0.011874186806380749
    ],
    [
      0.0015025734901428223,
      0.012502893805503845,
      -0.05318090692162514,
      -0.09218631684780121,
      0.07964327186346054,
      -0.07248210906982422,
      -0.07488207519054413,
      0.0018942415481433272,
      0.0024434798397123814,
      -0.04291823133826256,
      -0.038651153445243835,
      -0.07903926074504852,
      -0.03805999085307121,
      0.02008463442325592,
      0.014935414306819439,
      0.029473910108208656,
      0.055959511548280716,
      0.026862679049372673,
      -0.07143688946962357,
      -0.028417741879820824,
      0.0024306413251906633,
      -0.06534827500581741,
      -0.0587494820356369,
      -0.04111725836992264,
      -0.012652894482016563,
      0.10754891484975815,
      0.05680263414978981,
      -0.0396234467625618,
      0.03300408273935318,
      -0.013940316624939442,
      -0.04469146206974983,
      -0.061149127781391144,
      -0.04257379099726677,
      0.029410943388938904,
      -0.023568077012896538,
      -0.021428169682621956,
      0.04381221905350685,
      0.021870100870728493,
      0.039983633905649185,
      0.0003227726847399026,
      -0.06002442538738251,
      0.0011982322903349996,
      -0.07560015469789505,
      -0.0871359184384346,
      -0.05839703232049942,
      0.006811944302171469,
      -0.009330717846751213,
      0.01094086468219757,
      0.042298246175050735,
      -0.01271799486130476,
      0.032513417303562164,
      0.03915836289525032,
      0.0509883277118206,
      -0.06653912365436554,
      -0.05776065215468407,
      -0.018634634092450142,
      -0.06314337253570557,
      0.02987310104072094,
      -0.004921898245811462,
      -0.08573591709136963,
      0.009002779610455036,
      0.04928956180810928,
      -0.023257382214069366,
      -0.0756816565990448,
      0.05387306958436966,
      -0.016898268833756447,
      -0.05468769744038582,
      -0.08181418478488922,
      0.018692534416913986,
      0.09687519818544388,
      0.01819780096411705,
      -0.02206271141767502,
      0.06582565605640411,
      0.021487848833203316,
      -0.02460922673344612,
      0.021820884197950363,
      0.027891434729099274,
      0.004108949564397335,
      -0.01759849302470684,
      -0.014366264455020428,
      -0.05684274435043335,
      0.07820386439561844,
      0.1234242245554924,
      0.005751941818743944,
      0.050392769277095795,
      0.022572139278054237,
      -0.08330553770065308,
      -0.07997796684503555,
      -0.03722916170954704,
      -0.0080949692055583,
      -0.012377372942864895,
      -0.13441526889801025,
      0.007403161376714706,
      0.06783686578273773,
      0.06281571090221405,
      -0.04541660100221634,
      -0.0317421592772007,
      0.027009015902876854,
      0.07826761901378632,
      -0.0232139453291893,
      -0.018398914486169815,
      0.03810691460967064,
      -0.036555979400873184,
      0.06575040519237518,
      0.03095926344394684,
      -0.039460357278585434,
      0.030195970088243484,
      0.04837554693222046,
      -0.00805927999317646,
      -0.061608944088220596,
      0.004100020509213209,
      0.06316551566123962,
      -0.04408342018723488,
      -0.046130597591400146,
      0.020003626123070717,
      0.06971007585525513,
      0.005291431676596403,
      0.008459395729005337,
      -0.022529156878590584,
      -0.034867849200963974,
      0.04214935377240181,
      -0.06992414593696594,
      0.0488162487745285,
      -0.04153672978281975,
      -0.011610978282988071,
      0.04891993850469589,
      0.09190519154071808,
      0.042347244918346405,
      -0.028942516073584557,
      0.010771362110972404,
      -0.016499381512403488,
      0.07121972739696503,
      0.029542526230216026,
      -0.027918437495827675,
      0.056063491851091385,
      0.002791558625176549,
      0.0479821115732193,
      -0.07799367606639862,
      -0.01316798385232687,
      -0.013485795818269253,
      0.03447268530726433,
      -0.03512267395853996,
      0.007165184710174799,
      0.04196277633309364,
      -0.004000575747340918,
      -0.004522332455962896,
      0.030140329152345657,
      -0.0032032246235758066,
      0.0050750612281262875,
      0.004250466823577881,
      -0.0007205354049801826,
      0.019601650536060333,
      0.045003246515989304,
      0.037699464708566666,
      -0.04550665244460106,
      -0.02848649024963379,
      0.02291105128824711,
      0.010880036279559135,
      -0.021493205800652504,
      0.023472970351576805,
      -0.04445784166455269,
      -0.03751915320754051,
      0.03655603155493736,
      0.03139982372522354,
      -0.07228910177946091,
      0.009135073982179165,
      0.032724954187870026,
      0.025890743359923363,
      -0.05027158930897713,
      0.003545542014762759,
      -0.08371447771787643,
      -0.026133114472031593,
      -0.009276306256651878,
      -0.028079545125365257,
      -0.05673517286777496,
      0.05806047096848488,
      -0.013731260783970356,
      -0.0365777350962162,
      -0.07237255573272705,
      0.031333077698946,
      0.08959967643022537,
      0.004148756619542837,
      0.06124095246195793,
      0.05911223217844963,
      -0.024726176634430885,
      -0.051693160086870193,
      0.10003983974456787,
      -0.02237388677895069,
      0.0841546431183815,
      -0.06878969073295593,
      0.04182727634906769,
      0.05699431896209717,
      0.04909169301390648,
      -0.07808280736207962,
      0.05639630928635597,
      0.0774354562163353,
      -0.08642285317182541,
      0.03537384793162346,
      -0.009409544058144093,
      -0.0035089210141450167,
      0.014923790469765663,
      0.08169400691986084,
      -0.0461670346558094,
      0.08161748200654984,
      -0.04749098792672157,
      0.001952257938683033,
      -0.03724794462323189,
      -0.015608345158398151,
      -0.005684842821210623,
      0.00698280381038785,
      -0.08456604927778244,
      0.055726442486047745,
      0.004212692845612764,
      -0.0664810985326767,
      -0.04028229042887688,
      0.05301148444414139,
      0.016279617324471474,
      0.0018070130608975887,
      -0.04920503497123718,
      -0.13466371595859528,
      0.030951129272580147,
      -0.002304131630808115,
      -0.04760901257395744,
      -0.041811734437942505,
      0.04342382401227951,
      -0.038408759981393814,
      -0.07021502405405045,
      0.010559155605733395,
      -0.014849196188151836,
      0.04285968840122223,
      0.053006190806627274,
      -0.034230176359415054,
      -0.03534626588225365,
      0.034108467400074005,
      0.0459502711892128,
      -0.040021564811468124,
      0.054963599890470505,
      -0.008708607405424118,
      0.00828852504491806,
      -0.02682730369269848,
      -0.04049742966890335,
      0.025388307869434357,
      -0.06652956455945969,
      0.020667968317866325,
      0.012673290446400642,
      0.014021642506122589,
      -0.008196509443223476,
      0.07714181393384933,
      -0.019461659714579582,
      -0.07660988718271255,
      -0.0765596404671669,
      -0.0006356180529110134,
      -0.046362075954675674,
      -0.05621318146586418,
      -0.005864295642822981,
      -0.011899368837475777,
      -0.028596365824341774,
      0.018807418644428253,
      -0.0652448758482933,
      -0.07097752392292023,
      0.08139258623123169,
      0.03002217784523964,
      -0.10568410903215408,
      -0.046567630022764206,
      -0.009956884197890759,
      -0.03181890398263931,
      0.019596442580223083,
      -0.037009768187999725,
      0.09266828000545502,
      -0.06831968575716019,
      0.053488776087760925,
      0.019281331449747086,
      0.08204525709152222,
      0.039650119841098785,
      0.03649525344371796,
      0.08814015984535217,
      -0.03463393822312355,
      0.02072235569357872,
      0.044114626944065094,
      -0.0024164204951375723,
      -0.016210874542593956,
      0.0003800547565333545,
      0.028967631980776787,
      0.029764529317617416,
      0.0005316358292475343,
      0.00548875518143177,
      -0.021895280107855797,
      -0.026340628042817116,
      0.08143322914838791,
      0.04894839972257614,
      0.001075324951671064,
      -0.02466476336121559,
      -0.07035168260335922,
      -0.03432903811335564,
      -0.04940320923924446,
      0.013170584104955196,
      -0.039022523909807205,
      0.009572508744895458,
      -0.029604651033878326,
      -0.015839753672480583,
      -0.06263125687837601,
      -0.009516794234514236,
      -0.014081393368542194,
      0.0548027828335762,
      0.00036834017373621464,
      -0.10049588978290558,
      0.038740742951631546,
      -0.075048066675663,
      0.016661128029227257,
      -0.018628336489200592,
      -0.06981442868709564,
      0.05658844858407974,
      -0.00440505426377058,
      -0.04061403498053551,
      0.005551353562623262,
      -0.01136758178472519,
      -0.04502413794398308,
      0.02832134999334812,
      -0.0668184757232666,
      0.020679190754890442,
      0.06774338334798813,
      0.058956220746040344,
      0.01936686784029007,
      0.05459408089518547,
      -0.05080239474773407,
      -0.0651881992816925,
      0.006919562816619873,
      0.02319694496691227,
      -0.03879455849528313,
      -0.0021829132456332445,
      -0.08378326892852783,
      -0.018691442906856537,
      -0.01815233752131462,
      0.014741908758878708,
      -0.005503193009644747,
      0.025839410722255707,
      0.07877528667449951,
      -0.04265981540083885,
      -0.03939131274819374,
      -0.0055552138946950436,
      -0.004222957417368889,
      0.017709190025925636,
      0.07731495797634125,
      -0.044512130320072174,
      0.021732334047555923,
      0.020832423120737076,
      -0.09383831173181534,
      0.08956758677959442,
      0.043159909546375275,
      0.023243827745318413,
      0.014576629735529423,
      0.00878812838345766,
      0.03938129171729088,
      0.008990108035504818,
      0.10895044356584549,
      0.024901030585169792,
      -0.05550513416528702,
      -0.0016152739990502596,
      0.057194676250219345,
      -0.032357651740312576,
      -0.08776281028985977,
      0.018275346606969833,
      -0.030792081728577614,
      -0.07031071931123734,
      -0.07018294930458069,
      -0.02871423028409481,
      -0.03424277529120445,
      0.016402479261159897,
      0.02353321574628353,
      -0.029751542955636978,
      0.03029114007949829,
      -0.01144728995859623,
      0.025945836678147316,
      -0.019590383395552635,
      0.015960300341248512,
      -0.1206531748175621,
      0.04079028218984604,
      0.005053782369941473,
      0.08184921741485596,
      0.018793493509292603,
      0.01259552501142025,
      -0.055528782308101654,
      0.038254160434007645,
      -0.03942085802555084,
      0.013890152797102928,
      0.004511984996497631,
      -0.020429879426956177,
      -0.008551419712603092,
      0.0011049683671444654,
      0.10403371602296829,
      0.08570127189159393,
      0.0010137587087228894,
      0.04312857240438461,
      0.005042974837124348,
      0.024722054600715637,
      -0.009365437552332878,
      -0.09176506847143173,
      0.014993683435022831,
      0.02390003390610218,
      -0.0003900816373061389,
      0.010721464641392231,
      0.02014918066561222,
      0.06748279184103012,
      -0.012459794990718365,
      -0.06351382285356522,
      0.020303135737776756,
      -0.018540216609835625,
      -0.05752510577440262,
      0.020304664969444275,
      -0.03613734617829323,
      0.0034587557893246412,
      0.0020052441395819187,
      0.010606077499687672,
      0.06706535071134567,
      0.05741942673921585,
      0.025342635810375214,
      -0.006614054087549448,
      -0.03656265512108803,
      0.033443793654441833,
      -0.07368053495883942,
      -0.006283631548285484,
      -0.09574305266141891,
      -0.02363453060388565,
      -0.036293499171733856,
      -0.033874936401844025,
      0.04072249308228493,
      0.02323932759463787,
      0.004204252734780312,
      -0.016915451735258102,
      0.02219516783952713,
      -0.06741476058959961,
      0.0412747822701931,
      -0.04367513954639435,
      -0.08092693984508514,
      0.00495934160426259,
      0.02087985724210739,
      -0.0366864837706089,
      -0.017916424199938774,
      -0.05439278483390808,
      -0.035158202052116394,
      -0.04512455686926842,
      0.020380713045597076,
      0.017256511375308037,
      0.0058733210898935795,
      0.029796801507472992,
      -0.06546223163604736,
      -0.0499965138733387,
      -0.03785904124379158,
      -0.021667147055268288,
      0.10876119136810303,
      -0.025086089968681335,
      0.020751357078552246,
      -0.04177919402718544,
      0.06951554864645004,
      0.0228794664144516,
      -0.054349929094314575,
      0.017574762925505638,
      -6.419569399440661e-05,
      0.019337210804224014,
      0.007261401973664761,
      -0.02831278182566166,
      0.05954763665795326,
      -0.032015733420848846,
      -0.0638594925403595,
      -0.07117898762226105,
      -0.05005117505788803,
      0.005655590444803238,
      -0.010989396832883358,
      -0.03155825659632683,
      -0.061101414263248444,
      -0.005326680839061737,
      0.06844796240329742,
      0.003411809913814068,
      -0.009275348857045174,
      0.07618065923452377,
      -0.0740325003862381,
      -0.05947815626859665,
      -0.016720879822969437,
      0.1223878338932991,
      -0.009766179136931896,
      0.01234378106892109,
      -0.012051962316036224,
      0.021005328744649887,
      0.013526516035199165,
      -0.03125707432627678,
      0.0522548146545887,
      -0.06376171857118607,
      -0.02993510290980339,
      -0.06753861159086227,
      -0.04965853691101074,
      0.02362658828496933,
      -0.0605681911110878,
      -0.07381521165370941,
      -0.03342857584357262,
      -0.006593175698071718,
      -0.041109174489974976,
      0.035527125000953674,
      0.013150529004633427,
      -0.00043975203880108893,
      0.021130356937646866,
      0.03428220376372337,
      -0.02136792242527008,
      0.09198329597711563
    ],
    [
      0.011255805380642414,
      -0.07634267956018448,
      0.01681368239223957,
      0.10257834941148758,
      0.03529714047908783,
      -0.019187232479453087,
      -0.08872560411691666,
      0.017565900459885597,
      0.024367548525333405,
      0.06099013239145279,
      0.08134613186120987,
      0.08331922441720963,
      -0.007001971825957298,
      0.047382693737745285,
      0.03356616199016571,
      0.025322632864117622,
      0.008099372498691082,
      -0.07336706668138504,
      -0.010251795873045921,
      -0.018912067636847496,
      0.07321073859930038,
      0.07459770143032074,
      -0.09285905957221985,
      0.1003805547952652,
      -0.014882576651871204,
      0.13480767607688904,
      -0.013545230031013489,
      -0.02617373690009117,
      0.006412151735275984,
      0.04208989068865776,
      -0.024093186482787132,
      -0.0006657102494500577,
      -0.03062617965042591,
      0.010811318643391132,
      0.005526217631995678,
      0.01138379704207182,
      0.03172287344932556,
      0.06703772395849228,
      0.02018716186285019,
      0.03658430650830269,
      0.015662781894207,
      0.0673239454627037,
      -0.04802485555410385,
      0.050131622701883316,
      0.029892858117818832,
      0.037015244364738464,
      0.07924056053161621,
      0.007231785915791988,
      0.0014608107740059495,
      -0.01574430987238884,
      0.07416222989559174,
      0.05351310595870018,
      -0.045724451541900635,
      0.026708165183663368,
      -0.0003718848747666925,
      -0.020491594448685646,
      0.035876378417015076,
      -0.0105832414701581,
      -0.010593993589282036,
      -0.06384957581758499,
      -0.034966643899679184,
      0.06466221064329147,
      0.029293803498148918,
      0.011849560774862766,
      0.0089082857593894,
      0.03990161791443825,
      0.06319120526313782,
      -0.0075069088488817215,
      -0.034749846905469894,
      -0.01462078932672739,
      0.00394795136526227,
      -0.0318341925740242,
      0.007423525210469961,
      0.03426326438784599,
      0.05450110137462616,
      -0.03384966403245926,
      0.016944389790296555,
      0.041680023074150085,
      -0.037385132163763046,
      -0.01216188631951809,
      0.06771199405193329,
      0.040085576474666595,
      -0.03188902512192726,
      0.0037336531095206738,
      0.015216415748000145,
      -0.06425806134939194,
      -0.03959627076983452,
      -0.018943481147289276,
      0.10057370364665985,
      -0.020935311913490295,
      -0.023689253255724907,
      -0.009866581298410892,
      -0.05501073598861694,
      -0.011624450795352459,
      0.012535897083580494,
      0.024528730660676956,
      0.0173113364726305,
      0.012412202544510365,
      -0.005491023883223534,
      0.013028747402131557,
      0.05101526156067848,
      -0.04711752012372017,
      -0.09249719977378845,
      -0.07281512767076492,
      -0.024200478568673134,
      0.014934392645955086,
      0.047563981264829636,
      0.06834384053945541,
      0.10025309771299362,
      -0.025311583653092384,
      -0.048227716237306595,
      -0.059890266507864,
      -0.03596056252717972,
      -0.057529374957084656,
      0.03326795622706413,
      -0.001832634792663157,
      -0.026566190645098686,
      -0.0026201193686574697,
      0.020893044769763947,
      0.10424093157052994,
      -0.06693850457668304,
      0.011906098574399948,
      -0.0692472755908966,
      0.09068016707897186,
      -0.05519472435116768,
      -0.054755061864852905,
      -0.035371337085962296,
      0.034423671662807465,
      -0.008024369366466999,
      0.03069177456200123,
      -0.08167829364538193,
      -0.04121137410402298,
      -0.06883401423692703,
      -0.01710077002644539,
      -0.04397571086883545,
      -0.0026718226727098227,
      0.03368329629302025,
      -0.07651810348033905,
      -0.04838981851935387,
      -0.01829642243683338,
      0.02827399969100952,
      0.013064540922641754,
      -0.038520731031894684,
      0.05243872478604317,
      -0.010026058182120323,
      -0.046198051422834396,
      -0.05146752670407295,
      -0.052443038672208786,
      0.027598997578024864,
      -0.06665337085723877,
      0.0676921159029007,
      0.017952628433704376,
      -0.07156334817409515,
      0.06887843459844589,
      1.2060740118613467e-05,
      -0.020177926868200302,
      -0.020734570920467377,
      -0.03867117688059807,
      -0.019791407510638237,
      -0.0160894263535738,
      0.0041363839991390705,
      0.0028242478147149086,
      0.03765714913606644,
      0.03419457748532295,
      0.020050616934895515,
      -0.04506425932049751,
      0.06535515189170837,
      0.0005813227035105228,
      0.031107423827052116,
      -0.03272772580385208,
      -0.02978609874844551,
      0.05407119169831276,
      0.010474415495991707,
      0.025567200034856796,
      -0.015285174362361431,
      0.03875022381544113,
      -0.01768476888537407,
      0.018409011885523796,
      -0.01947619765996933,
      -0.03253089264035225,
      0.013536274433135986,
      0.0008284271461889148,
      -0.017971979454159737,
      -0.03412964195013046,
      -0.0526898056268692,
      0.09935374557971954,
      0.026633422821760178,
      -0.02217791974544525,
      -0.023718610405921936,
      0.04464208707213402,
      -0.04176158457994461,
      0.07564124464988708,
      -0.04143470525741577,
      -0.06694357842206955,
      -0.02173101156949997,
      0.05497966706752777,
      -0.05153945833444595,
      -0.04675348848104477,
      0.03721955046057701,
      -0.12007894366979599,
      0.0002802742528729141,
      0.06541602313518524,
      0.016845501959323883,
      -0.010492203757166862,
      -0.03216134011745453,
      2.724954902078025e-05,
      -0.0532216913998127,
      -0.0259439405053854,
      0.06079133599996567,
      0.07355431467294693,
      -0.0014461495447903872,
      -0.014282931573688984,
      -0.04683874547481537,
      -0.020558923482894897,
      0.017177361994981766,
      0.05040018633008003,
      -0.035498689860105515,
      -0.011697902344167233,
      -0.008678941056132317,
      0.005953564774245024,
      -0.04475302994251251,
      0.007782182656228542,
      -0.01927587389945984,
      -0.044745512306690216,
      -0.00870442483574152,
      0.021167131140828133,
      -0.11659488826990128,
      -0.06151294335722923,
      -0.058665499091148376,
      0.02031796984374523,
      0.0020866284612566233,
      0.02108701504766941,
      0.0660908967256546,
      -0.039984721690416336,
      0.021300487220287323,
      -0.010866702534258366,
      -0.019734032452106476,
      0.04820583760738373,
      0.03575029596686363,
      0.04631846398115158,
      0.003405481344088912,
      0.03025990165770054,
      -0.0034280309919267893,
      -0.04920918121933937,
      0.04470378905534744,
      -0.008322547189891338,
      0.0046140169724822044,
      -0.034789253026247025,
      0.005183005705475807,
      0.021834172308444977,
      0.0009846939938142896,
      -0.02530977502465248,
      -0.01436022762209177,
      0.024512726813554764,
      0.1285591572523117,
      -0.08356868475675583,
      0.01713080331683159,
      -0.009556622244417667,
      -0.004481473006308079,
      0.04058104008436203,
      0.008092394098639488,
      0.020474376156926155,
      0.0016286906320601702,
      -0.11808769404888153,
      0.024436768144369125,
      -0.008577629923820496,
      0.028438033536076546,
      -0.01921289600431919,
      -0.022943725809454918,
      0.0028797248378396034,
      0.002224351279437542,
      -0.00898859091103077,
      -0.0035297744907438755,
      0.027741793543100357,
      0.02347453497350216,
      0.05351978540420532,
      0.09462491422891617,
      -0.03216914087533951,
      0.04208995774388313,
      0.006143694277852774,
      -0.05188030004501343,
      -0.029691454023122787,
      0.04192614182829857,
      -0.021798739209771156,
      -0.011419749818742275,
      0.007933558896183968,
      -0.0028496680315583944,
      0.06290493160486221,
      0.015636363998055458,
      0.002199250739067793,
      0.0008139013662002981,
      -0.04342365637421608,
      -0.07424123585224152,
      -0.021825328469276428,
      -0.026785356923937798,
      -0.11057037860155106,
      0.009324170649051666,
      -0.017846107482910156,
      0.03755630552768707,
      0.048235174268484116,
      -0.060149628669023514,
      -0.0982089638710022,
      0.0006523895426653326,
      -0.006005771923810244,
      0.017667653039097786,
      0.018387390300631523,
      -0.03823496401309967,
      -0.023677092045545578,
      0.06284157931804657,
      0.028767744079232216,
      0.017780838534235954,
      0.0353357270359993,
      0.057791270315647125,
      -0.042085859924554825,
      -0.042434658855199814,
      -0.03223840147256851,
      0.026282673701643944,
      -0.06050826236605644,
      0.05975816026329994,
      0.07984530180692673,
      0.06876161694526672,
      0.0077275619842112064,
      0.07239716500043869,
      0.07214771211147308,
      -0.02075164206326008,
      -0.04239288717508316,
      0.014987308531999588,
      -0.009622443467378616,
      -0.04294809326529503,
      -0.09195538610219955,
      -0.02215241827070713,
      -0.04844091460108757,
      -0.05528414621949196,
      0.007750147487968206,
      -0.13216112554073334,
      -0.010391821153461933,
      0.012097210623323917,
      0.05036073923110962,
      -0.10439390689134598,
      -0.009998373687267303,
      -0.051935210824012756,
      -0.017145739868283272,
      -0.026028111577033997,
      0.04297535493969917,
      -0.08396220207214355,
      -0.03225157782435417,
      -0.011826842091977596,
      0.0002740143972914666,
      -0.017655299976468086,
      0.05598758906126022,
      0.02787075750529766,
      -0.01745717041194439,
      -0.001637066132389009,
      0.008564891293644905,
      0.02512921765446663,
      0.018223946914076805,
      -0.04596947133541107,
      -0.05034167319536209,
      0.02665923349559307,
      -0.050654336810112,
      0.023291997611522675,
      -0.026671897619962692,
      0.03650433197617531,
      0.05089528113603592,
      -0.058951832354068756,
      0.0014218336436897516,
      0.03931412845849991,
      0.08181062340736389,
      0.007337654009461403,
      0.00213793502189219,
      0.0068069822154939175,
      0.06240006908774376,
      -0.019133489578962326,
      -0.050228461623191833,
      0.049916546791791916,
      -0.07081393152475357,
      0.05200876295566559,
      -0.05525479093194008,
      -0.08467251062393188,
      -0.03388211503624916,
      0.013738102279603481,
      -0.020320625975728035,
      -0.011300886049866676,
      -0.04401206970214844,
      -0.014541469514369965,
      0.01951378397643566,
      0.008417470380663872,
      0.03461364284157753,
      -0.01669822447001934,
      -0.04194687306880951,
      0.0045034573413431644,
      -0.008209106512367725,
      0.025496600195765495,
      -0.0010028594406321645,
      -0.04380875453352928,
      0.02391202375292778,
      -0.02945403940975666,
      -0.05833982303738594,
      0.057780031114816666,
      -0.04069167748093605,
      0.04358828067779541,
      -0.027820149436593056,
      0.09606829285621643,
      -0.04180096462368965,
      -0.029052412137389183,
      0.00550172058865428,
      0.0447426475584507,
      0.030683156102895737,
      0.07986828684806824,
      -0.023337125778198242,
      0.05324043706059456,
      0.06802389770746231,
      -0.03115290030837059,
      0.06189193204045296,
      -0.018800094723701477,
      -0.006405072286725044,
      0.07891373336315155,
      0.015385459177196026,
      0.04255666211247444,
      0.049902401864528656,
      0.009546429850161076,
      0.0021197579335421324,
      -0.09754031151533127,
      0.029146790504455566,
      -0.048143379390239716,
      -0.01252124272286892,
      -0.009169618599116802,
      0.08116865158081055,
      -0.00043289398308843374,
      -0.06813793629407883,
      -0.018182726576924324,
      -0.12931469082832336,
      -0.05426851287484169,
      0.04172549769282341,
      -0.06553088873624802,
      0.003391988342627883,
      -0.0813765749335289,
      -0.06371410191059113,
      -0.013380877673625946,
      -0.0028457529842853546,
      -0.004167918115854263,
      0.033711113035678864,
      -0.06616667658090591,
      -0.08448732644319534,
      0.06417795270681381,
      0.0902840793132782,
      -0.030761869624257088,
      0.01947227492928505,
      -0.0923670157790184,
      0.03875100240111351,
      0.000637344375718385,
      0.003175815800204873,
      -0.03641558811068535,
      0.06957723200321198,
      0.059080954641103745,
      0.043234359472990036,
      -0.052303869277238846,
      0.020847469568252563,
      -0.0272512249648571,
      0.015125335194170475,
      0.024397283792495728,
      0.05336812511086464,
      -0.05174241214990616,
      -0.01947801373898983,
      0.06226078420877457,
      0.02391216717660427,
      -0.006183189805597067,
      -0.007912996225059032,
      -0.08261518180370331,
      0.038168299943208694,
      -0.023646337911486626,
      0.04793758690357208,
      0.023062648251652718,
      -0.06544485688209534,
      0.01688246615231037,
      -0.003822950879111886,
      -0.025577181950211525,
      -0.04465560242533684,
      0.022949229925870895,
      -0.050396520644426346,
      0.02944188006222248,
      -0.08929970115423203,
      0.022369591519236565,
      -0.004910835064947605,
      0.06762974709272385,
      -0.08507358282804489,
      -0.020787449553608894,
      -0.03147708624601364,
      0.0022554672323167324,
      0.057325102388858795,
      -0.01767953671514988,
      -0.015497500076889992,
      -0.0325578972697258,
      -0.058938104659318924,
      0.05435061454772949,
      -0.0292070172727108,
      -0.034933872520923615,
      0.029544293880462646,
      0.05188080295920372,
      -0.006415682844817638,
      0.010197182185947895,
      0.02878359705209732,
      0.015830496326088905
    ],
    [
      -0.002792038954794407,
      -0.0074446178041398525,
      0.045814815908670425,
      -0.06030182167887688,
      0.049644701182842255,
      -0.066129669547081,
      0.0013373030815273523,
      -0.09246285259723663,
      -0.06947357952594757,
      0.04331090301275253,
      0.02701360173523426,
      0.02090742252767086,
      -0.008303615264594555,
      -0.020871153101325035,
      -0.03332915157079697,
      0.053179290145635605,
      0.04582517594099045,
      -0.012495852075517178,
      0.022880326956510544,
      -0.0032985799480229616,
      0.10968685150146484,
      0.06176025792956352,
      -0.06435369700193405,
      -0.0184764601290226,
      0.04228668659925461,
      0.035039983689785004,
      -0.05444937199354172,
      -0.016893325373530388,
      0.04829060286283493,
      -0.055164504796266556,
      -0.07175584137439728,
      0.01708495430648327,
      0.0002479591639712453,
      0.011719086207449436,
      -0.07051446288824081,
      -0.07590853422880173,
      0.04808136820793152,
      -0.028792278841137886,
      0.04397933930158615,
      0.042648397386074066,
      -0.033664919435977936,
      0.03487299382686615,
      0.0059898956678807735,
      0.052357498556375504,
      0.010563147254288197,
      -0.026341179385781288,
      0.002790125086903572,
      -0.07256282866001129,
      -0.04207916930317879,
      0.019610831514000893,
      -0.005894963629543781,
      -0.10768068581819534,
      0.01920175366103649,
      0.0497269481420517,
      -0.04106893762946129,
      0.057695675641298294,
      0.06390460580587387,
      0.0506136380136013,
      -0.04501350596547127,
      -0.04072490707039833,
      0.028537750244140625,
      0.059261564165353775,
      0.09189869463443756,
      -0.02114904671907425,
      -0.016897985711693764,
      0.015807919204235077,
      0.022754967212677002,
      -0.09237128496170044,
      0.014889171347022057,
      -0.07709061354398727,
      0.021241068840026855,
      -0.01816299743950367,
      0.04268752783536911,
      0.026705775409936905,
      0.010935543105006218,
      0.11363217234611511,
      0.0033209563698619604,
      0.020611070096492767,
      -0.035635318607091904,
      -0.030202601104974747,
      -0.018710872158408165,
      -0.030308473855257034,
      -0.057150572538375854,
      -0.056718409061431885,
      -0.03269064798951149,
      0.029889412224292755,
      -0.08646731823682785,
      -0.024993015453219414,
      -0.044126223772764206,
      -0.07109255343675613,
      0.011070504784584045,
      0.025595763698220253,
      0.12584321200847626,
      0.07900914549827576,
      -0.03157319873571396,
      0.021496187895536423,
      0.07463138550519943,
      0.05888194590806961,
      0.048521045595407486,
      0.01897391676902771,
      0.08114077150821686,
      0.018950948491692543,
      0.0019081151112914085,
      -0.08078736811876297,
      0.03608962148427963,
      -0.03792168200016022,
      0.01267195399850607,
      -0.057801492512226105,
      -0.033372197300195694,
      0.07390914112329483,
      0.02284890227019787,
      0.02665243111550808,
      0.012860262766480446,
      -0.13411584496498108,
      -0.05346782132983208,
      0.0828634724020958,
      0.04750526696443558,
      0.032027099281549454,
      1.5226391042233445e-05,
      0.02032591961324215,
      0.07599520683288574,
      -0.027212733402848244,
      0.025935864076018333,
      0.05402335152029991,
      -0.05777484178543091,
      -0.021441571414470673,
      -0.03899777680635452,
      -0.0822882428765297,
      0.01971004717051983,
      -0.047108206897974014,
      -0.009525268338620663,
      -0.03075031004846096,
      -0.008834405802190304,
      -0.010047122836112976,
      0.06820396333932877,
      0.03389716520905495,
      -0.06011494621634483,
      -0.01150673907250166,
      -0.023290302604436874,
      0.047019895166158676,
      -0.03138459473848343,
      0.011775274761021137,
      -0.025451846420764923,
      0.04386981576681137,
      -0.029646584764122963,
      0.03172024339437485,
      0.003084949217736721,
      0.07080290466547012,
      -0.004649614915251732,
      -0.029007595032453537,
      -0.03624359518289566,
      -0.043612606823444366,
      0.022161411121487617,
      -0.015775099396705627,
      0.04436309635639191,
      0.028350267559289932,
      0.001410137047059834,
      0.06797602027654648,
      -0.01526691671460867,
      -0.024098265916109085,
      -0.046339474618434906,
      -0.0074134147725999355,
      -0.010954023338854313,
      0.0739138126373291,
      0.01862221769988537,
      -0.008415560238063335,
      0.004907648079097271,
      0.004034868441522121,
      -0.027136022225022316,
      -0.007108306046575308,
      -0.08388207107782364,
      -0.10044301301240921,
      0.04055078700184822,
      0.02467206120491028,
      -0.006617221049964428,
      0.04747414216399193,
      -0.060959868133068085,
      -0.033055100589990616,
      -0.06169421225786209,
      0.057624246925115585,
      -0.06194007769227028,
      -0.03469846025109291,
      0.012790149077773094,
      0.07137484103441238,
      -0.021990414708852768,
      -0.004077134188264608,
      -0.06440592557191849,
      0.013401011936366558,
      -0.0003880243166349828,
      -0.04108821973204613,
      0.051251720637083054,
      0.007442032918334007,
      -0.002308591967448592,
      -0.03202433884143829,
      0.02878316305577755,
      0.025716813281178474,
      0.0327291302382946,
      0.05034177005290985,
      0.06056726723909378,
      -0.0024656401947140694,
      0.0656774491071701,
      0.010558917187154293,
      0.011062201112508774,
      0.08253706246614456,
      0.005403649061918259,
      -0.08600113540887833,
      -0.018914327025413513,
      -0.03146979957818985,
      -0.028626274317502975,
      0.10703443735837936,
      0.006103300955146551,
      0.04205456003546715,
      0.022344432771205902,
      0.06343656778335571,
      0.04942619055509567,
      0.019025947898626328,
      0.08635367453098297,
      0.0842222198843956,
      -0.013387995772063732,
      0.02356116846203804,
      -0.0829031839966774,
      -0.02470223233103752,
      -0.12182008475065231,
      0.05702175572514534,
      0.03574718162417412,
      -0.03256714716553688,
      0.02581712231040001,
      0.04223322123289108,
      0.034297071397304535,
      -0.03383314609527588,
      -0.031184932217001915,
      -0.006721150130033493,
      -0.021809088066220284,
      0.11448724567890167,
      -0.04829423874616623,
      0.026236794888973236,
      0.09456771612167358,
      -0.05013716220855713,
      0.09176259487867355,
      0.015537758357822895,
      -0.02329796552658081,
      0.017985442653298378,
      0.04457154497504234,
      0.04314887523651123,
      0.043639544397592545,
      -0.09069307893514633,
      -0.0017961565172299743,
      -0.03499054163694382,
      -0.022737476974725723,
      -0.009756973944604397,
      0.012339395470917225,
      0.05896472558379173,
      0.008659533224999905,
      0.05166621506214142,
      0.03497037664055824,
      0.01722855307161808,
      -0.07861341536045074,
      -0.10176333039999008,
      0.03966074436903,
      0.06708673387765884,
      0.0050704460591077805,
      -0.02965788170695305,
      0.005145771894603968,
      0.02394486591219902,
      -0.045225515961647034,
      0.041215553879737854,
      -0.018948877230286598,
      -0.030503783375024796,
      -0.04827610403299332,
      -0.02762056328356266,
      0.01832321472465992,
      0.04541730880737305,
      -0.011389209888875484,
      0.033701274544000626,
      0.03774327039718628,
      0.005853389855474234,
      -0.01303473487496376,
      0.051551975309848785,
      0.04018662869930267,
      -0.03924277797341347,
      0.07395505160093307,
      -0.006490861065685749,
      0.06611622869968414,
      0.04742152988910675,
      -0.03266548365354538,
      0.03938473016023636,
      -0.020182393491268158,
      0.007977651432156563,
      -0.11138612031936646,
      0.025428906083106995,
      -0.00843846332281828,
      0.022599101066589355,
      -0.053948480635881424,
      0.06656886637210846,
      -0.09990651160478592,
      0.010371088981628418,
      0.026406466960906982,
      0.0005645726341754198,
      -0.0022728510666638613,
      0.015861326828598976,
      0.014417853206396103,
      0.004363058600574732,
      -0.00031724796281196177,
      0.005335148423910141,
      -0.059161920100450516,
      -0.0023628820199519396,
      0.05066870525479317,
      -0.017328593879938126,
      -0.05060167610645294,
      0.02743191458284855,
      -0.05701082944869995,
      -0.034225381910800934,
      0.08300281316041946,
      -0.09230247884988785,
      -0.038283832371234894,
      -0.06201503425836563,
      -0.03238007798790932,
      0.01996585913002491,
      0.012165973894298077,
      0.0009531815303489566,
      0.01670464314520359,
      0.0013628213200718164,
      0.028917523100972176,
      0.004870208911597729,
      0.01484981644898653,
      -0.0720253437757492,
      -0.03270206227898598,
      0.013539724983274937,
      -0.017303597182035446,
      0.04183902591466904,
      0.025207258760929108,
      0.06734628230333328,
      0.006544878706336021,
      0.034456364810466766,
      0.027677074074745178,
      0.014209914952516556,
      -0.016620151698589325,
      0.006596601102501154,
      0.018967729061841965,
      -0.013024522922933102,
      0.064815953373909,
      -0.09035691618919373,
      0.03954625502228737,
      0.021688809618353844,
      0.06307602673768997,
      -0.051023177802562714,
      -0.004953488241881132,
      -0.07077986747026443,
      0.01835206337273121,
      0.0077187311835587025,
      0.04659772291779518,
      0.007289079017937183,
      -0.022806035354733467,
      -0.017306756228208542,
      0.0065099564380943775,
      0.02974414825439453,
      0.025149645283818245,
      -0.03427337482571602,
      0.041525986045598984,
      -0.05762314051389694,
      -0.05077626556158066,
      0.0018581326585263014,
      -0.019824866205453873,
      0.08756338804960251,
      -0.008532515726983547,
      -0.027200037613511086,
      -0.01759510673582554,
      0.0380629226565361,
      0.02974899671971798,
      0.020307106897234917,
      -0.021753398701548576,
      0.012724078260362148,
      0.03427699953317642,
      0.012708173133432865,
      -0.002849966287612915,
      -0.02723369561135769,
      0.0018969813827425241,
      -0.010799368843436241,
      -0.04143136367201805,
      -0.02985149249434471,
      -0.019692616537213326,
      -0.01987970992922783,
      -0.06099981442093849,
      -0.007346016354858875,
      0.09855149686336517,
      -0.04919891059398651,
      0.029012635350227356,
      -0.010213680565357208,
      -0.05655883625149727,
      0.05842874199151993,
      -0.04740295186638832,
      -0.008794665336608887,
      -0.05485265702009201,
      -0.03746655955910683,
      -0.013617758639156818,
      0.01444779522716999,
      0.04058985039591789,
      0.016981026157736778,
      -0.057359080761671066,
      0.02737446315586567,
      0.009824786335229874,
      0.0829533040523529,
      -0.058146655559539795,
      0.0033074552193284035,
      -0.09450449794530869,
      0.026016857475042343,
      0.013122549280524254,
      -0.003929565194994211,
      0.02617947943508625,
      -0.024121984839439392,
      -0.05354113131761551,
      0.004750503692775965,
      0.025110049173235893,
      -0.013590609654784203,
      0.007469176780432463,
      -0.08128803223371506,
      0.021001450717449188,
      0.026961635798215866,
      0.04023381695151329,
      -0.011155001819133759,
      0.008483733981847763,
      -0.00570053281262517,
      0.015862800180912018,
      0.0108214495703578,
      -0.027161607518792152,
      -0.036994170397520065,
      -0.037727389484643936,
      -0.07508935034275055,
      -0.040081921964883804,
      -0.020458806306123734,
      0.07186844944953918,
      0.009309851564466953,
      -0.062345828860998154,
      -0.020519794896245003,
      0.024313585832715034,
      0.12455566972494125,
      0.1270485669374466,
      0.01253510545939207,
      0.045598842203617096,
      0.03372754156589508,
      0.027146626263856888,
      0.012497011572122574,
      0.004050733055919409,
      -0.10621947050094604,
      0.011131866835057735,
      -0.01655755192041397,
      0.03616579622030258,
      0.016089392825961113,
      -0.04676084592938423,
      -0.002959181321784854,
      -0.059091612696647644,
      0.048197079449892044,
      0.03142577409744263,
      -0.0045013222843408585,
      -0.08103542029857635,
      -0.021077020093798637,
      -0.015602277591824532,
      0.014795941300690174,
      -0.00462142052128911,
      -0.035981740802526474,
      0.10626903176307678,
      0.03546792268753052,
      0.0004210394690744579,
      -0.02199685387313366,
      -0.003677082946524024,
      -0.04138660058379173,
      0.02700427919626236,
      0.02618234045803547,
      0.010736512951552868,
      -0.031231369823217392,
      0.09516344219446182,
      -0.08261869847774506,
      0.04231712222099304,
      -0.007582991383969784,
      -0.0084335096180439,
      -0.04957665130496025,
      -0.03667435050010681,
      0.010766902007162571,
      -0.0013408503727987409,
      0.03616499900817871,
      0.03839826583862305,
      0.023516591638326645,
      -0.03588193655014038,
      -0.046086858958005905,
      0.0055004386231303215,
      -0.023753467947244644,
      0.028126399964094162,
      -0.023527808487415314,
      -0.027981068938970566,
      0.0024537527933716774,
      0.035386476665735245,
      0.02240315079689026,
      0.03813483193516731,
      -0.029728056862950325,
      -0.043469782918691635,
      -0.019829239696264267,
      0.021742496639490128,
      -0.011554551310837269,
      -0.06309148669242859,
      0.012627962976694107,
      0.0075417207553982735,
      -0.046456530690193176,
      0.057764507830142975
    ],
    [
      -0.01283220387995243,
      0.08885390311479568,
      0.011295591481029987,
      -0.03244310989975929,
      0.060789499431848526,
      -0.0032905293628573418,
      -0.031574685126543045,
      0.0018812792841345072,
      -0.01450699009001255,
      0.09665663540363312,
      -0.005306590814143419,
      -0.0719267800450325,
      -0.058769747614860535,
      0.020677339285612106,
      0.07856529206037521,
      0.011016158387064934,
      0.01478190254420042,
      0.12961901724338531,
      0.010785745456814766,
      -0.040854793041944504,
      0.002568180439993739,
      -0.08589047938585281,
      0.044776551425457,
      0.06978394091129303,
      -0.1072530671954155,
      0.013162660412490368,
      0.08676367253065109,
      0.04001656174659729,
      0.004836308769881725,
      0.014015560038387775,
      -0.06672690808773041,
      0.01929321698844433,
      -0.07678612321615219,
      0.009463262744247913,
      -0.006287453230470419,
      -0.025897255167365074,
      -0.016783511266112328,
      0.0703984722495079,
      -0.01369671430438757,
      0.00028617962379939854,
      0.0020050397142767906,
      -0.043058089911937714,
      0.046264659613370895,
      -0.03104681707918644,
      0.06367520242929459,
      0.036555975675582886,
      0.03149228170514107,
      0.03241784870624542,
      -0.0006046178168617189,
      0.053194426000118256,
      0.06551101058721542,
      -0.014205791987478733,
      0.0569005124270916,
      -0.01953432708978653,
      0.02795284427702427,
      -0.048704005777835846,
      -0.025408489629626274,
      0.017535071820020676,
      0.004087655805051327,
      -0.024932343512773514,
      -0.035474743694067,
      0.017526956275105476,
      0.06627286225557327,
      -0.010584378615021706,
      0.0995606929063797,
      0.01921224035322666,
      0.08227594941854477,
      -0.08316078782081604,
      0.0441894493997097,
      -0.07627729326486588,
      0.04587021842598915,
      -0.03362832963466644,
      0.020236248150467873,
      0.09942667931318283,
      0.026026198640465736,
      0.024452397599816322,
      -0.009717069566249847,
      -0.07420516759157181,
      -0.008171613328158855,
      -0.03469834476709366,
      0.035456907004117966,
      0.07142067700624466,
      0.02625587210059166,
      0.05493781715631485,
      0.04488189518451691,
      -0.054559748619794846,
      0.01751912571489811,
      0.004938722122460604,
      -0.00992171373218298,
      -0.04111845791339874,
      -0.03521764650940895,
      0.04518924653530121,
      0.06074861064553261,
      -0.018604673445224762,
      -0.029370367527008057,
      -0.028953932225704193,
      -0.07670989632606506,
      -0.038295675069093704,
      -0.0460868775844574,
      -0.029082374647259712,
      -0.023613030090928078,
      -0.08082874864339828,
      -0.02294711396098137,
      0.0230880044400692,
      0.06452619284391403,
      0.03315934166312218,
      0.11098139733076096,
      -0.027851266786456108,
      -0.040104545652866364,
      0.09209953993558884,
      -0.05566149577498436,
      -0.016315704211592674,
      0.002329624956473708,
      -0.0361604280769825,
      0.02852262556552887,
      -0.04694393277168274,
      0.032596174627542496,
      0.026583226397633553,
      0.035352230072021484,
      -0.044819701462984085,
      -0.014051306061446667,
      -0.006677978206425905,
      0.030155587941408157,
      0.012370927259325981,
      0.01470379438251257,
      -0.037163447588682175,
      0.06544449925422668,
      -0.013723294250667095,
      -0.05420106276869774,
      -0.019398212432861328,
      -0.03738222271203995,
      -0.11054332554340363,
      0.0866134911775589,
      0.017833279445767403,
      -0.019832158461213112,
      -0.0061614904552698135,
      0.020819589495658875,
      0.037741128355264664,
      -0.012626532465219498,
      0.008123589679598808,
      0.07961957156658173,
      -0.0026200891006737947,
      -0.021969759836792946,
      -0.032017480581998825,
      -0.027000242844223976,
      -0.011856155470013618,
      -0.03333304822444916,
      -0.020721683278679848,
      0.04460981488227844,
      -0.024934202432632446,
      -0.06876737624406815,
      0.02327519655227661,
      0.029596399515867233,
      -0.028579827398061752,
      -0.03125649690628052,
      0.01680857501924038,
      -0.017689725384116173,
      -0.004666001535952091,
      0.0156484916806221,
      0.06985558569431305,
      -0.02638222463428974,
      -0.0754137709736824,
      -0.0020065351855009794,
      -0.047661229968070984,
      0.027738939970731735,
      -0.008073514327406883,
      0.0751463770866394,
      0.07157294452190399,
      0.057878147810697556,
      -0.0005570694338530302,
      -0.03935420513153076,
      -0.0801750048995018,
      -0.012764308601617813,
      0.051133736968040466,
      0.02094561792910099,
      0.031227728351950645,
      -0.03576688840985298,
      0.03644677251577377,
      -0.029705148190259933,
      0.0038171117193996906,
      0.04872830957174301,
      -0.12430481612682343,
      -0.03430624306201935,
      0.011276774108409882,
      -0.02855074591934681,
      0.02341855689883232,
      -0.029158838093280792,
      -0.019310908392071724,
      0.01296290848404169,
      0.0027670355048030615,
      -0.056385479867458344,
      -0.0383799746632576,
      -0.07512693852186203,
      0.01910323277115822,
      0.013558722101151943,
      -0.033343784511089325,
      -0.02284093201160431,
      0.025169245898723602,
      -0.07252898067235947,
      -0.07315263897180557,
      0.020501630380749702,
      -0.08201026916503906,
      -0.057567913085222244,
      -0.06788381189107895,
      -0.03535617142915726,
      0.04681845009326935,
      0.02629651501774788,
      0.0020901004318147898,
      0.021195685490965843,
      0.058408819139003754,
      -0.032477956265211105,
      0.046919066458940506,
      0.03117091953754425,
      0.032866571098566055,
      0.005578290205448866,
      0.042106013745069504,
      -0.030593549832701683,
      0.008947358466684818,
      -0.009814866818487644,
      -0.006734048016369343,
      0.024966733530163765,
      0.036589909344911575,
      0.052711375057697296,
      -0.06528598070144653,
      0.01880359649658203,
      0.056125182658433914,
      0.05749969184398651,
      -0.024978777393698692,
      -0.06883435696363449,
      -0.03296416252851486,
      -0.013887112960219383,
      0.04054485633969307,
      -0.02130955085158348,
      0.025982294231653214,
      -0.1163242980837822,
      -0.08326634019613266,
      0.014673059806227684,
      -0.022775348275899887,
      -0.04487374797463417,
      -0.008006885647773743,
      -0.008524478413164616,
      -0.025611750781536102,
      -0.04109598323702812,
      0.036595962941646576,
      0.0072381519712507725,
      0.01310043502599001,
      0.06647562235593796,
      -0.017360564321279526,
      -0.01184554398059845,
      -0.07714493572711945,
      0.01386173814535141,
      0.04480351507663727,
      0.07906125485897064,
      -0.008624291978776455,
      0.029541917145252228,
      -0.04482617601752281,
      -0.0703202411532402,
      -0.04905132204294205,
      -0.015736503526568413,
      -0.0022564048413187265,
      -0.04268346726894379,
      -0.053403571248054504,
      0.03293050825595856,
      0.02868945337831974,
      0.07943971455097198,
      0.03938540443778038,
      -0.051755860447883606,
      -0.03687773272395134,
      -0.011183405295014381,
      0.005067847203463316,
      -0.04312211647629738,
      -0.015255322679877281,
      -0.021871250122785568,
      -0.05623777583241463,
      -0.012315056286752224,
      -0.014043406583368778,
      -0.046414002776145935,
      0.07397777587175369,
      -0.05457112938165665,
      0.041137050837278366,
      -0.109450563788414,
      -0.022690201178193092,
      0.041815876960754395,
      0.03974049165844917,
      0.12289206683635712,
      0.02517935447394848,
      -0.00381676247343421,
      -0.03558874502778053,
      -0.028903387486934662,
      0.04469115287065506,
      -0.07371102273464203,
      0.01536191813647747,
      0.09207543730735779,
      0.015795590355992317,
      -0.015365013852715492,
      -0.011122132651507854,
      -0.005051334388554096,
      -0.02467993088066578,
      -0.05494718998670578,
      0.04145735874772072,
      0.019646968692541122,
      -0.02774573490023613,
      -0.05682273209095001,
      -0.09455417841672897,
      0.05850435048341751,
      0.0009909962536767125,
      0.0024684001691639423,
      -0.06413596123456955,
      -0.04164374992251396,
      0.016858989372849464,
      0.06637086719274521,
      0.0014230399392545223,
      -0.06061353534460068,
      0.031070919707417488,
      -0.025494465604424477,
      -0.09273506700992584,
      0.04287094622850418,
      0.06662550568580627,
      0.017746349796652794,
      -0.038764405995607376,
      0.08731558173894882,
      0.01852552220225334,
      0.07295215129852295,
      0.04323466494679451,
      -0.04720281809568405,
      -0.006420011166483164,
      -0.006059542298316956,
      0.04109862819314003,
      -0.023925097659230232,
      -0.03671605512499809,
      -0.0008800738723948598,
      0.008132639341056347,
      -0.00152481475379318,
      0.0017332122661173344,
      -0.010193657130002975,
      0.020024344325065613,
      0.0812598168849945,
      0.0104210851714015,
      0.01945265755057335,
      -0.03432217612862587,
      0.015379801392555237,
      0.030707040801644325,
      0.0480693057179451,
      0.0066788713447749615,
      0.026311593130230904,
      -0.0163772851228714,
      -0.004734675399959087,
      0.006591249257326126,
      0.005232269410043955,
      -0.0015371774788945913,
      -0.006461028475314379,
      0.030797166749835014,
      0.0029379520565271378,
      -0.06422961503267288,
      -0.11799745261669159,
      0.03407992050051689,
      0.027094589546322823,
      -0.04076568782329559,
      0.01809079758822918,
      -0.07306462526321411,
      -0.04504651576280594,
      0.008524050936102867,
      0.11857157945632935,
      -0.008219693787395954,
      -0.015605192631483078,
      0.07859164476394653,
      -0.04007706418633461,
      0.058031730353832245,
      -0.04460248351097107,
      -0.009330850094556808,
      -0.013461673632264137,
      -0.06188824027776718,
      -0.08785869181156158,
      -0.03165685757994652,
      0.029702814295887947,
      -0.028462504968047142,
      -0.05515078082680702,
      -0.009902295656502247,
      0.04113655164837837,
      0.015501824207603931,
      0.015127623453736305,
      0.04797245189547539,
      0.00166624179109931,
      0.011792918667197227,
      -0.0011151315411552787,
      -0.019043928012251854,
      0.03441945090889931,
      0.008427128195762634,
      -0.02692384086549282,
      -0.029802529141306877,
      -0.046272195875644684,
      -0.04212082549929619,
      -0.043316490948200226,
      -0.010946692898869514,
      0.07301899045705795,
      0.024773795157670975,
      0.01382487453520298,
      0.024148350581526756,
      -0.004250762518495321,
      0.008962911553680897,
      -0.07556142657995224,
      0.02379239723086357,
      0.0329323410987854,
      -0.010655407793819904,
      -0.058635324239730835,
      -0.04939204826951027,
      0.06848045438528061,
      -0.04410193860530853,
      -0.08798694610595703,
      0.017568394541740417,
      -0.011137631721794605,
      0.03813592717051506,
      -0.09066040813922882,
      0.05071309953927994,
      0.010542918927967548,
      0.017371751368045807,
      -0.06107180938124657,
      0.09013086557388306,
      -0.03983102738857269,
      0.0110936239361763,
      0.018303724005818367,
      0.0349152497947216,
      0.02592579461634159,
      0.01667630299925804,
      0.0096144312992692,
      0.01413162425160408,
      -0.15084829926490784,
      0.09411459416151047,
      0.004788967315107584,
      -0.006373022682964802,
      0.011903434991836548,
      -0.06379838287830353,
      -0.02784031443297863,
      -0.04681340977549553,
      0.0024219779297709465,
      -0.009289659559726715,
      0.06330091506242752,
      0.023150883615016937,
      0.021395446732640266,
      -0.01029565092176199,
      -0.02600930817425251,
      0.0022120869252830744,
      -0.03411681205034256,
      0.01054593175649643,
      -0.004251458216458559,
      -0.05054900050163269,
      0.005566589534282684,
      0.06001701578497887,
      -0.009784727357327938,
      -0.02949586510658264,
      0.007149816025048494,
      0.04688176140189171,
      -0.013089253567159176,
      -0.015515371225774288,
      -0.00034050006070174277,
      -0.047773804515600204,
      -0.03179793432354927,
      0.03599471226334572,
      -0.004461219999939203,
      0.015854114666581154,
      0.026295466348528862,
      -0.043451208621263504,
      -0.005054463166743517,
      0.11275295168161392,
      -0.0270762387663126,
      -0.05948781967163086,
      0.0024568038061261177,
      -0.015189285390079021,
      0.024484116584062576,
      0.01964886672794819,
      -0.005592077039182186,
      0.05991584435105324,
      -0.033290717750787735,
      -0.023651910945773125,
      -0.07965220510959625,
      0.030829619616270065,
      0.017491210252046585,
      -0.025895459577441216,
      0.07891500741243362,
      -0.009483379311859608,
      -0.013470982201397419,
      0.026457704603672028,
      -0.03038139082491398,
      0.03763161227107048,
      0.051224999129772186,
      -0.01927773281931877,
      0.006952960509806871,
      0.0747690424323082,
      0.047837015241384506,
      -0.05774274095892906,
      -0.020638898015022278,
      -0.10047929733991623,
      0.059154167771339417,
      -0.003305975114926696,
      0.0025993390008807182,
      0.00648480886593461,
      0.03033575229346752,
      -0.0038520696107298136,
      0.043450385332107544,
      -0.03853900358080864,
      0.020846916362643242,
      0.014574525877833366,
      -0.014043381437659264
    ],
    [
      0.013024905696511269,
      -0.03758504241704941,
      0.012258671224117279,
      -0.001024260651320219,
      0.033482927829027176,
      -0.02316967584192753,
      0.07321174442768097,
      -0.0968540832400322,
      -0.030567657202482224,
      -0.004010254517197609,
      -0.06825368106365204,
      -0.006808992009609938,
      -0.04391065239906311,
      0.012861398980021477,
      0.025706127285957336,
      0.009942053817212582,
      -0.0018225660314783454,
      -0.02140090987086296,
      -0.05468781292438507,
      0.02599113993346691,
      0.036487605422735214,
      0.012169836089015007,
      -0.01704157143831253,
      0.034921154379844666,
      -0.03725462406873703,
      0.008574462495744228,
      0.014330913312733173,
      -0.0018855014350265265,
      0.10357066243886948,
      -0.041875582188367844,
      0.0006630397983826697,
      0.03096548467874527,
      0.042549677193164825,
      -0.00571824237704277,
      -0.04985224828124046,
      0.07163351029157639,
      -0.0020227059721946716,
      -0.03278522938489914,
      -0.018031295388936996,
      0.05761560797691345,
      -0.025626210495829582,
      0.04525604844093323,
      -0.043018221855163574,
      0.05706677585840225,
      -0.02852783165872097,
      -0.009241912513971329,
      -0.04403216019272804,
      0.05632682144641876,
      0.023795805871486664,
      0.046274811029434204,
      0.015216362662613392,
      -0.08885125070810318,
      0.057069890201091766,
      -0.08499181270599365,
      0.060794346034526825,
      0.034327298402786255,
      -0.03230537474155426,
      -0.062496066093444824,
      -0.02474784292280674,
      -0.02032334730029106,
      -0.03365761786699295,
      0.07297597825527191,
      -0.07369967550039291,
      -0.016903579235076904,
      0.04085623100399971,
      -0.03233928605914116,
      -0.0026606221217662096,
      -0.02132134884595871,
      -0.024812566116452217,
      0.0031941004563122988,
      0.01813649758696556,
      -0.024376511573791504,
      0.014781007543206215,
      -0.0031439275480806828,
      0.02427452802658081,
      0.010485905222594738,
      0.015696872025728226,
      -0.10467300564050674,
      0.07436913996934891,
      -0.05507631227374077,
      0.022841742262244225,
      -0.016464170068502426,
      -0.014256431721150875,
      0.04035819321870804,
      0.07612995058298111,
      -0.0036361240781843662,
      0.0528843030333519,
      -0.00886374432593584,
      -0.035805732011795044,
      -0.05418815463781357,
      0.04922426491975784,
      -0.011539199389517307,
      0.11048318445682526,
      0.05445243418216705,
      0.034112587571144104,
      0.035236943513154984,
      -0.06159891560673714,
      -0.020950330421328545,
      0.027157120406627655,
      -0.09990822523832321,
      0.028532017022371292,
      0.02030205726623535,
      0.06613624095916748,
      -0.03348027914762497,
      -0.012629403732717037,
      -0.027312282472848892,
      -0.001050284830853343,
      -0.003210547147318721,
      -0.01847982592880726,
      0.027466511353850365,
      -0.037278302013874054,
      -0.07661361247301102,
      -0.007296987809240818,
      -0.0004652937932405621,
      0.006912861950695515,
      0.04553738981485367,
      -0.08382195979356766,
      -0.013291558250784874,
      -0.006581129506230354,
      -0.01122253481298685,
      0.014998398721218109,
      -0.028650866821408272,
      -0.027965636923909187,
      -0.029477978125214577,
      0.06146048381924629,
      0.016355445608496666,
      0.010111359879374504,
      -0.022121315822005272,
      -0.02022872120141983,
      -0.02027910016477108,
      -0.07231685519218445,
      0.0738372728228569,
      -0.011627711355686188,
      -0.0013299881247803569,
      -0.04500041529536247,
      -0.09136931598186493,
      0.026572953909635544,
      -0.06255342811346054,
      -0.08757748454809189,
      -0.025888673961162567,
      0.02194225788116455,
      -0.035297952592372894,
      -0.08445283025503159,
      -0.07345043122768402,
      0.07320762425661087,
      0.021303018555045128,
      -0.010207275860011578,
      -0.024739384651184082,
      0.0017385276732966304,
      0.09080374985933304,
      -0.024392254650592804,
      0.005420383997261524,
      -0.07352561503648758,
      0.1061500683426857,
      0.013417751528322697,
      -0.016143979504704475,
      0.02540871873497963,
      -0.030730612576007843,
      0.027572890743613243,
      -0.025818100199103355,
      0.027388866990804672,
      0.017810575664043427,
      0.03163471072912216,
      -0.03943638876080513,
      -0.03355826437473297,
      -0.010981499217450619,
      -0.04373762011528015,
      -0.08573134988546371,
      0.0031748947221785784,
      -0.02591545693576336,
      -0.010784681886434555,
      0.006789933890104294,
      -0.074929378926754,
      -0.062766894698143,
      0.02349800057709217,
      -0.00781707838177681,
      -0.03534018248319626,
      -0.017285358160734177,
      -0.016255756840109825,
      0.014747359789907932,
      -0.029520276933908463,
      0.00601867213845253,
      -0.05814743414521217,
      0.023991858586668968,
      0.01664794236421585,
      -0.006445693783462048,
      0.00722462497651577,
      0.015095422975718975,
      0.03865206986665726,
      0.03799533098936081,
      -0.028113581240177155,
      -0.0005154513637535274,
      0.021467015147209167,
      0.04354169964790344,
      -0.030116084963083267,
      0.0624956451356411,
      -0.05214042589068413,
      0.04280563071370125,
      -0.018428761512041092,
      -0.10368116945028305,
      0.04017799347639084,
      -0.04883492365479469,
      -0.003909998573362827,
      0.07087396830320358,
      -0.07829608768224716,
      0.0839204415678978,
      -0.003717295592650771,
      -0.04477369040250778,
      -0.020959243178367615,
      -0.08002372086048126,
      0.052569713443517685,
      -0.023687994107604027,
      0.03037315420806408,
      -0.04030854254961014,
      -0.04221687465906143,
      0.005747536197304726,
      0.01677713170647621,
      0.03669324889779091,
      -0.09481260180473328,
      -0.035825494676828384,
      0.02547474391758442,
      0.012162977829575539,
      0.019009966403245926,
      -0.032700054347515106,
      0.004488748032599688,
      0.01613713800907135,
      -0.05934235081076622,
      -0.076169453561306,
      -0.024781042709946632,
      -0.027595018967986107,
      0.01517082005739212,
      0.0242009274661541,
      0.07693266868591309,
      0.007792267482727766,
      -0.0005996677791699767,
      0.009034192189574242,
      0.05069654434919357,
      -0.04250376671552658,
      -0.0704135000705719,
      -0.018132535740733147,
      -0.03168949484825134,
      0.028453126549720764,
      -0.05221397057175636,
      -0.07993901520967484,
      -0.08697251230478287,
      0.007866453379392624,
      0.005800866521894932,
      -0.029890768229961395,
      0.0413237102329731,
      -0.011267798021435738,
      -0.05557665973901749,
      -0.002490219660103321,
      -0.04596468806266785,
      0.08265659958124161,
      -0.03927475959062576,
      -0.013926676474511623,
      0.020772945135831833,
      -0.05138951912522316,
      0.018954157829284668,
      0.022488128393888474,
      0.10949471592903137,
      0.0084052300080657,
      -0.033833086490631104,
      -0.07038409262895584,
      0.0018759347731247544,
      -0.004231151659041643,
      -0.05239143967628479,
      -0.028875822201371193,
      -0.11121959239244461,
      -0.046471092849969864,
      0.07928360998630524,
      0.10385634750127792,
      -0.061084236949682236,
      -0.08214055746793747,
      -0.043013446033000946,
      0.05156131088733673,
      0.07224515080451965,
      -0.045829564332962036,
      0.013926995918154716,
      0.006920520681887865,
      -0.05371611937880516,
      0.08374551683664322,
      -0.00471457839012146,
      0.06800205260515213,
      0.014688253402709961,
      0.031038502231240273,
      0.03906756266951561,
      0.01094050146639347,
      -0.07568896561861038,
      -0.03329674154520035,
      -0.03635560721158981,
      0.0895351842045784,
      -0.008356629870831966,
      -0.012635765597224236,
      0.045500028878450394,
      0.005995150189846754,
      -0.03770921751856804,
      -0.03648298233747482,
      -0.02228679694235325,
      0.06888321787118912,
      0.041741784662008286,
      -0.07896103709936142,
      0.03680706024169922,
      -0.07870661467313766,
      -0.0007942626252770424,
      -0.029872631654143333,
      0.019375629723072052,
      0.020505739375948906,
      0.059103865176439285,
      0.15261201560497284,
      0.07263607531785965,
      -0.0656818076968193,
      -0.09097595512866974,
      0.044306930154561996,
      0.06018469110131264,
      -0.029932020232081413,
      -0.020024701952934265,
      0.009762110188603401,
      -0.03044022060930729,
      0.008544225245714188,
      0.039035454392433167,
      0.05281199514865875,
      -0.012742278166115284,
      -0.04918588697910309,
      -0.03860064223408699,
      -0.009425737895071507,
      -0.0731666088104248,
      0.019125601276755333,
      -0.009298947639763355,
      0.03301689028739929,
      -0.012205572798848152,
      0.016208263114094734,
      -0.055881790816783905,
      0.01811087876558304,
      -0.058804597705602646,
      -0.044875118881464005,
      0.04891204833984375,
      -0.061229679733514786,
      -0.07159457355737686,
      0.004954989068210125,
      0.049289241433143616,
      0.034691840410232544,
      -0.01317028421908617,
      -0.008731888607144356,
      -0.0627567395567894,
      -0.08588819205760956,
      0.01277094054967165,
      -0.00048675216385163367,
      0.008262007497251034,
      0.047819189727306366,
      -0.0005717526073567569,
      -0.022696347907185555,
      0.02097702957689762,
      0.051881805062294006,
      -0.04801500588655472,
      -0.005332787521183491,
      0.002287280512973666,
      0.009437325410544872,
      -0.10036087781190872,
      -0.026083113625645638,
      -0.0607660748064518,
      0.13173678517341614,
      0.000852862955071032,
      0.0405782125890255,
      0.060659170150756836,
      -0.050565119832754135,
      0.009653925895690918,
      -0.04689839109778404,
      0.018213454633951187,
      0.016461238265037537,
      0.06727422028779984,
      0.006590577773749828,
      0.024671994149684906,
      -0.10849808156490326,
      0.017492694780230522,
      0.011949652805924416,
      -0.026946287602186203,
      0.058969806879758835,
      -0.024960195645689964,
      -0.06544496864080429,
      -0.027426129207015038,
      0.02308247610926628,
      -0.0038732571993023157,
      -0.013137088157236576,
      -0.034775204956531525,
      0.02764199487864971,
      0.013995390385389328,
      -0.004073766525834799,
      -0.0666559636592865,
      0.007780953776091337,
      -0.021704165264964104,
      -0.0227450393140316,
      -0.022375985980033875,
      0.03166334703564644,
      -0.0028362951707094908,
      0.02406732365489006,
      -0.04473133385181427,
      -0.04337647184729576,
      0.0048245941288769245,
      0.05170078203082085,
      -0.01492877397686243,
      0.017148612067103386,
      0.03918451443314552,
      -0.005563464481383562,
      0.005303803365677595,
      -0.035844285041093826,
      -0.025088869035243988,
      -0.02313927933573723,
      0.04287124425172806,
      0.018774714320898056,
      0.027095548808574677,
      0.04283018782734871,
      0.03552277013659477,
      -0.012091410346329212,
      0.06008331477642059,
      -0.056084781885147095,
      0.006140027195215225,
      -0.015977485105395317,
      -0.010438647121191025,
      0.02459346130490303,
      -0.0074868821538984776,
      -0.058018993586301804,
      0.0025391075760126114,
      0.07743074744939804,
      0.025597885251045227,
      0.02991821989417076,
      0.001001479453407228,
      0.13881675899028778,
      -0.04352115839719772,
      -0.014847176149487495,
      -0.02909085713326931,
      -0.007564684841781855,
      0.05058804899454117,
      -0.02156943455338478,
      -0.006451181601732969,
      -0.0071656773798167706,
      -0.04101461544632912,
      0.052031341940164566,
      0.024663081392645836,
      0.02068687416613102,
      0.02459385246038437,
      -0.002558184787631035,
      0.00018827556050382555,
      -0.04464739188551903,
      0.01975928619503975,
      0.00025028217351064086,
      0.029285384342074394,
      0.008513124659657478,
      -0.0245471503585577,
      0.02481386996805668,
      -0.05201532691717148,
      0.021460264921188354,
      -0.056112140417099,
      0.02892920933663845,
      0.025932366028428078,
      0.04285278543829918,
      0.010845072567462921,
      0.007592627312988043,
      0.006469826214015484,
      0.006748397368937731,
      0.007324691396206617,
      -0.04674429073929787,
      -0.054688408970832825,
      0.03635983541607857,
      0.03818559646606445,
      -0.010181283578276634,
      -0.01650015264749527,
      0.01786317490041256,
      -0.012635239399969578,
      -0.046099953353405,
      0.082016721367836,
      0.022153232246637344,
      -0.014797850511968136,
      -0.00234612007625401,
      0.03523740917444229,
      0.012504730373620987,
      -0.07168930023908615,
      -0.029218148440122604,
      -0.017468206584453583,
      -0.026975642889738083,
      0.005787672009319067,
      -0.001295016030780971,
      -0.04105499014258385,
      0.03758701682090759,
      0.11562643945217133,
      0.021745681762695312,
      -0.08044692873954773,
      -0.0672721266746521,
      0.017491163685917854,
      0.01448629517108202,
      0.03649386391043663,
      -0.06249704584479332,
      0.07151700556278229,
      0.023635977879166603,
      -0.06424692273139954,
      -0.05375632643699646,
      -0.02373485453426838,
      -0.04295981675386429,
      -0.06728149950504303,
      0.009092602878808975,
      -0.026201583445072174,
      -0.018714454025030136,
      -0.023649463430047035
    ],
    [
      -0.00019027013331651688,
      -0.05867287516593933,
      -0.04697002097964287,
      0.03175266459584236,
      -0.004892438184469938,
      -0.06860490143299103,
      0.015951579436659813,
      -0.06447465717792511,
      0.021283680573105812,
      0.038148846477270126,
      -0.011860940605401993,
      -0.07821302115917206,
      0.060302868485450745,
      -0.03758712112903595,
      -0.02402615174651146,
      0.08372418582439423,
      0.040493741631507874,
      0.0560748465359211,
      -0.03319152444601059,
      -0.018539492040872574,
      -0.025830212980508804,
      0.056223064661026,
      0.0093843350186944,
      0.03863807022571564,
      -0.10405726730823517,
      0.004207524936646223,
      0.010289107449352741,
      -0.02601194754242897,
      -0.04144669324159622,
      -0.021113010123372078,
      0.004114084877073765,
      -0.006730120629072189,
      0.05183965712785721,
      0.09606638550758362,
      -0.04899635910987854,
      -0.027176540344953537,
      -0.013784783892333508,
      -0.031035127118229866,
      0.027099711820483208,
      0.03105432167649269,
      0.02717997319996357,
      0.018819749355316162,
      -0.05382627621293068,
      0.014231019653379917,
      -0.09109124541282654,
      0.012410329654812813,
      -0.0020428157877177,
      0.025623323395848274,
      0.0049439771100878716,
      -0.014791930094361305,
      -0.04586152359843254,
      -0.018801424652338028,
      0.037079326808452606,
      -0.02328041009604931,
      0.01765969954431057,
      0.03529040142893791,
      0.010255816392600536,
      -0.004244011361151934,
      -0.05115685611963272,
      -0.015610921196639538,
      -0.043170321732759476,
      -0.049088090658187866,
      0.07442969083786011,
      0.050376974046230316,
      -0.035046420991420746,
      -0.03866014629602432,
      -0.008973849005997181,
      0.08555301278829575,
      0.004003549460321665,
      0.021505339071154594,
      0.0449133925139904,
      0.009850647300481796,
      -0.03756570816040039,
      0.012008377350866795,
      0.04660706967115402,
      0.012099153362214565,
      0.006146537605673075,
      0.05853116139769554,
      0.015907855704426765,
      0.025440972298383713,
      -0.0396902933716774,
      -0.027608683332800865,
      0.05397830531001091,
      -0.038820717483758926,
      0.009373738430440426,
      -0.008481672033667564,
      0.10917995125055313,
      -0.0543087013065815,
      -0.014815808273851871,
      -0.018701335415244102,
      -0.056967683136463165,
      -0.04754673317074776,
      0.019252335652709007,
      0.0701395720243454,
      0.0036368723958730698,
      -0.09563902020454407,
      -0.03983378782868385,
      0.037949249148368835,
      0.0007795585552230477,
      0.01893557980656624,
      0.01830166019499302,
      -0.02018928900361061,
      -0.032405950129032135,
      0.01719755306839943,
      0.07013505697250366,
      0.047844622284173965,
      -0.07336823642253876,
      0.0010695494711399078,
      -0.08504687249660492,
      -0.017992189154028893,
      -0.05005405843257904,
      -0.015730664134025574,
      -0.033252838999032974,
      -0.010796776972711086,
      0.062083955854177475,
      0.006308563053607941,
      -0.017663925886154175,
      -0.02350858971476555,
      0.04443946108222008,
      0.013758369721472263,
      0.07113689184188843,
      0.06256289780139923,
      0.011016572825610638,
      0.029221564531326294,
      -0.011001124046742916,
      -0.01882072165608406,
      0.056641265749931335,
      0.048452746123075485,
      -0.007317057345062494,
      0.01050106342881918,
      0.025114119052886963,
      -0.010899245738983154,
      0.007930287159979343,
      -0.06972626596689224,
      -0.0256939809769392,
      0.03850904479622841,
      -0.028342243283987045,
      -0.09223615378141403,
      0.10211580991744995,
      0.059192728251218796,
      0.010095634497702122,
      -0.029587317258119583,
      -0.029992010444402695,
      0.02091505378484726,
      0.020425211638212204,
      0.04138752445578575,
      0.028945952653884888,
      0.018926357850432396,
      -0.021933704614639282,
      -0.036063358187675476,
      -0.055528294295072556,
      0.0016877994639798999,
      -0.05497307702898979,
      -0.0022564653772860765,
      -0.04641633480787277,
      0.03933923691511154,
      0.0045205033384263515,
      0.01761087030172348,
      0.04066987335681915,
      -0.0014659359585493803,
      -0.10517734289169312,
      -0.06232720986008644,
      0.04231526702642441,
      -0.020654862746596336,
      -0.09232357144355774,
      -0.010404747910797596,
      -0.00424019293859601,
      -0.049954526126384735,
      -0.01791481114923954,
      0.00733208330348134,
      -0.011992501094937325,
      -0.02575475350022316,
      0.06646542251110077,
      0.019505413249135017,
      -0.10741914808750153,
      -0.05161402001976967,
      0.034171994775533676,
      0.06504644453525543,
      0.043230485171079636,
      0.005470674019306898,
      -0.13790205121040344,
      0.009028521366417408,
      0.03671706095337868,
      0.06357139348983765,
      -0.06441663205623627,
      0.054714299738407135,
      0.0021468244958668947,
      -0.01542461384087801,
      -0.11279162764549255,
      -0.036259815096855164,
      -0.08634180575609207,
      0.01687406748533249,
      -0.003183878492563963,
      0.0994584783911705,
      -0.04126104712486267,
      -0.01974172331392765,
      0.07064180076122284,
      0.00878098327666521,
      -0.08152960240840912,
      0.048811182379722595,
      0.0755770206451416,
      0.02881954051554203,
      -0.07563763856887817,
      0.021220901980996132,
      0.004314991179853678,
      0.001483685802668333,
      -0.03873015567660332,
      0.024181002750992775,
      0.03320275619626045,
      0.019412368535995483,
      -0.03419516980648041,
      -0.03689651936292648,
      0.0636722594499588,
      -0.050558481365442276,
      0.05907369405031204,
      0.05297822877764702,
      -0.031200597062706947,
      0.039042387157678604,
      0.0026348389219492674,
      -0.11310089379549026,
      0.003964652307331562,
      -0.04431043937802315,
      -0.07061227411031723,
      -0.03005576878786087,
      0.08082325011491776,
      -0.05905823037028313,
      -0.014179758727550507,
      -0.0022982051596045494,
      0.008106852881610394,
      0.036241672933101654,
      0.017018252983689308,
      0.010534192435443401,
      -0.02905847690999508,
      0.005717451684176922,
      -0.04215438291430473,
      -0.04065672680735588,
      0.005837765522301197,
      0.008509444072842598,
      -0.0667603611946106,
      0.026723848655819893,
      -0.02090338058769703,
      0.036443062126636505,
      -0.038336340337991714,
      -0.024126917123794556,
      -0.025439195334911346,
      0.014103149063885212,
      -0.042450133711099625,
      -0.02655048854649067,
      0.018779587000608444,
      0.04152010381221771,
      -0.0020255534909665585,
      -0.06548181921243668,
      0.001760004204697907,
      0.017123781144618988,
      -0.05489644035696983,
      -0.013336500152945518,
      -0.05483528971672058,
      0.03286700323224068,
      -0.03750285878777504,
      -0.012239131145179272,
      0.0406535379588604,
      0.11975537985563278,
      -0.030852923169732094,
      0.01981135830283165,
      0.05500686913728714,
      0.04886918142437935,
      -0.007189621217548847,
      0.028045102953910828,
      -0.0014189376961439848,
      0.08385860919952393,
      -0.04040396958589554,
      -0.0017456132918596268,
      0.06680593639612198,
      0.01877824030816555,
      0.02766229771077633,
      0.00873048510402441,
      -0.0038288335781544447,
      0.080683134496212,
      0.019117243587970734,
      -0.05380862578749657,
      0.01100909523665905,
      0.054489824920892715,
      -0.0009459624416194856,
      0.005655583459883928,
      0.025420473888516426,
      0.07277368009090424,
      0.031242066994309425,
      -0.03952714800834656,
      -0.0019551445730030537,
      -0.02557520382106304,
      -0.003718511201441288,
      -0.004834639839828014,
      0.024317864328622818,
      -0.0036670430563390255,
      0.026915669441223145,
      0.011054158210754395,
      0.0734872967004776,
      -0.02306281588971615,
      -0.0060498472303152084,
      -0.05503290891647339,
      0.018715934827923775,
      0.004012647550553083,
      0.04917960241436958,
      -0.019206570461392403,
      -0.00825229287147522,
      0.0467802919447422,
      -0.05423498898744583,
      -0.03355833888053894,
      0.03412320837378502,
      -0.011504138819873333,
      0.0077871596440672874,
      0.05254507437348366,
      -0.03774657100439072,
      -0.02828381396830082,
      -0.04728943482041359,
      0.020143583416938782,
      -0.049276452511548996,
      0.016765406355261803,
      0.06292235851287842,
      -0.014071446843445301,
      -0.025846067816019058,
      -0.04383555054664612,
      0.02171429619193077,
      0.0756288394331932,
      0.10402848571538925,
      -0.045103948563337326,
      -0.01184676680713892,
      0.029820887371897697,
      -0.0421721525490284,
      -0.010402591899037361,
      -0.0746975913643837,
      -0.01553057786077261,
      0.016270749270915985,
      -0.008072981610894203,
      0.025146128609776497,
      0.011722847819328308,
      -0.03452860936522484,
      0.048623066395521164,
      -0.058563701808452606,
      -0.011278433725237846,
      -0.007540123071521521,
      -0.016992716118693352,
      -0.025501688942313194,
      -0.06109951436519623,
      0.003457704558968544,
      0.0139685133472085,
      0.034870196133852005,
      -0.046223584562540054,
      0.012475032359361649,
      0.05404278263449669,
      -0.013333940878510475,
      -0.032236408442258835,
      0.015452345833182335,
      0.07610681653022766,
      -0.05805014818906784,
      0.0005339817143976688,
      -0.08370877802371979,
      0.05349423736333847,
      -0.039386674761772156,
      -0.0317094512283802,
      0.015832718461751938,
      0.023728150874376297,
      0.01062269788235426,
      0.010313534177839756,
      -0.02947305515408516,
      0.04631888121366501,
      -0.0011720532784238458,
      -0.07042156159877777,
      -0.020333629101514816,
      0.06092380732297897,
      -0.020081132650375366,
      -0.045298460870981216,
      0.014213869348168373,
      0.09625493735074997,
      0.006610745098441839,
      0.015887362882494926,
      0.017888080328702927,
      0.0307778213173151,
      0.007635472808033228,
      -0.028855441138148308,
      -0.00046148625551722944,
      0.035560429096221924,
      0.01908860169351101,
      -0.03330662474036217,
      0.05808471143245697,
      -0.06005892902612686,
      -0.032922692596912384,
      0.013635960407555103,
      -0.01278581190854311,
      0.02539304457604885,
      -0.049755387008190155,
      -0.01530078798532486,
      -0.014554244466125965,
      0.038930777460336685,
      0.024390624836087227,
      0.01447889395058155,
      -0.03570885211229324,
      0.03268294781446457,
      -0.005022703669965267,
      -0.012695836834609509,
      0.0003900021256413311,
      -0.012336501851677895,
      -0.03392316773533821,
      -0.005155663006007671,
      -0.007509108167141676,
      0.05493835732340813,
      -0.06028032302856445,
      -0.02923235297203064,
      0.007070900872349739,
      0.06321054697036743,
      0.05481894686818123,
      -0.03466464951634407,
      0.027123384177684784,
      -0.06337116658687592,
      0.0034888419322669506,
      -0.06188765913248062,
      0.050180379301309586,
      -0.052622172981500626,
      0.004130676854401827,
      -0.09364591538906097,
      -0.04994380101561546,
      0.06550079584121704,
      0.09099306166172028,
      0.000858861836604774,
      -0.005823125597089529,
      -0.0352221243083477,
      0.005339902359992266,
      0.0006087551009841263,
      0.04739923030138016,
      -0.018352491781115532,
      0.050229862332344055,
      -0.0015171390259638429,
      -0.016420209780335426,
      -0.0029039024375379086,
      -0.03751553222537041,
      0.010831058956682682,
      -0.014714973978698254,
      -0.04156123474240303,
      -0.023176414892077446,
      -0.001898315385915339,
      -0.04809645935893059,
      -0.02995928004384041,
      0.025605402886867523,
      0.003928964026272297,
      -0.04517468810081482,
      -0.05032650753855705,
      0.017920061945915222,
      0.05857831984758377,
      -0.014952870085835457,
      -0.05493183061480522,
      -0.03727119788527489,
      0.013944074511528015,
      0.022593101486563683,
      -0.007149139419198036,
      0.014489120803773403,
      -0.0067390804179012775,
      -0.0087064104154706,
      -0.012212864123284817,
      0.027449386194348335,
      -0.04949821159243584,
      -0.04289025440812111,
      -0.0388798825442791,
      -0.042254894971847534,
      0.018547505140304565,
      -0.01534823328256607,
      0.03712831437587738,
      0.052839476615190506,
      -0.09783012419939041,
      -0.05749891698360443,
      -0.005064559169113636,
      0.010811785236001015,
      0.06883662939071655,
      -0.010979116894304752,
      0.0028791564982384443,
      -0.015626447275280952,
      0.07531047612428665,
      -0.038134634494781494,
      -0.03550688177347183,
      -0.04014606028795242,
      0.03569532185792923,
      -0.0609617717564106,
      0.0468582957983017,
      -0.004786848556250334,
      -0.08003885298967361,
      -0.03366333618760109,
      0.038887470960617065,
      0.0007676543318666518,
      -0.03916779160499573,
      -0.010202000848948956,
      -0.04821619391441345,
      0.07711806893348694,
      0.013985848054289818,
      -0.013838978484272957,
      0.06093612685799599,
      -0.015934985131025314,
      -0.051264308393001556,
      -0.04026435688138008,
      0.005562946200370789,
      0.027320154011249542,
      0.015931036323308945,
      0.10659593343734741,
      -0.0948348119854927,
      0.09778702259063721
    ],
    [
      0.035697340965270996,
      -0.01394694671034813,
      0.06105698645114899,
      0.030135955661535263,
      -0.0004614827048499137,
      0.013476060703396797,
      0.08291635662317276,
      0.02070261351764202,
      0.007130535785108805,
      0.03304053097963333,
      0.00692890165373683,
      -0.042343560606241226,
      -0.005840456113219261,
      -0.033348578959703445,
      0.049594148993492126,
      -0.0023424515966326,
      -0.06938858330249786,
      0.029124008491635323,
      -0.03922007605433464,
      -0.10863412171602249,
      -0.047309502959251404,
      -0.043334607034921646,
      0.02054497040808201,
      0.020702391862869263,
      0.0451325997710228,
      -0.0027227948885411024,
      -0.019229374825954437,
      0.1032944843173027,
      -0.035737987607717514,
      -0.10293921828269958,
      0.014268449507653713,
      0.06492655724287033,
      -0.018108509480953217,
      0.02232949435710907,
      0.0041391379199922085,
      -0.11195168644189835,
      0.018204912543296814,
      -0.019514046609401703,
      -0.0212709940969944,
      0.035229895263910294,
      0.10054541379213333,
      0.0647597536444664,
      -0.05142645910382271,
      0.029428355395793915,
      -0.033547647297382355,
      -0.011266173794865608,
      0.028079887852072716,
      -0.050397224724292755,
      0.1045660451054573,
      -0.04973597079515457,
      -0.014079400338232517,
      0.013317006640136242,
      -0.07749225199222565,
      -0.009557962417602539,
      -0.04800703749060631,
      -0.015517578460276127,
      0.013931388035416603,
      -0.023857364431023598,
      0.012259834446012974,
      0.030586859211325645,
      0.05067674070596695,
      -0.07067009061574936,
      0.03460421413183212,
      0.030005255714058876,
      0.02743646688759327,
      -0.005996015388518572,
      -0.012288670055568218,
      -0.043508365750312805,
      -0.011433986015617847,
      0.02155097760260105,
      -0.038071803748607635,
      -0.018625445663928986,
      -0.025105882436037064,
      0.06931143999099731,
      0.04143992438912392,
      -0.02757958322763443,
      -0.09748128056526184,
      0.0738428607583046,
      0.009251843206584454,
      0.0017727240920066833,
      0.018807293847203255,
      0.026965225115418434,
      0.042358025908470154,
      -0.025245435535907745,
      0.039555199444293976,
      -0.007070193532854319,
      0.048343971371650696,
      0.01865660957992077,
      0.07326571643352509,
      0.007255643140524626,
      0.02002802863717079,
      -0.03017311543226242,
      0.034968867897987366,
      -0.06713857501745224,
      0.018292510882019997,
      0.04321078583598137,
      -0.0007160318200476468,
      0.13097675144672394,
      -0.003041129093617201,
      0.015602844767272472,
      -0.021438060328364372,
      -0.07812841236591339,
      -0.06903708726167679,
      0.02004394866526127,
      0.052226472645998,
      -0.025211278349161148,
      -0.07673357427120209,
      0.033853888511657715,
      -0.02952428348362446,
      -0.02106849104166031,
      0.0758136585354805,
      -0.047342102974653244,
      -0.031248752027750015,
      0.029827693477272987,
      -0.07138466835021973,
      -0.04913216084241867,
      0.09259998053312302,
      -0.012083521112799644,
      -0.06431072950363159,
      -0.0046982415951788425,
      0.04864172264933586,
      0.04127760976552963,
      0.04397493600845337,
      -0.007819237187504768,
      -0.04663198068737984,
      0.03728083521127701,
      -0.037061650305986404,
      0.020390361547470093,
      -0.0029407518450170755,
      0.007864841260015965,
      -0.004453887231647968,
      0.017830325290560722,
      0.06583279371261597,
      -0.04830552265048027,
      0.030573923140764236,
      -0.030392613261938095,
      -0.011055274866521358,
      -0.017874209210276604,
      0.02393130585551262,
      -0.0521886982023716,
      -0.03257792070508003,
      0.03095657005906105,
      -0.04041823744773865,
      0.039985157549381256,
      -0.003517902921885252,
      -0.024550698697566986,
      0.06082961708307266,
      0.07490625232458115,
      -0.0055259158834815025,
      0.02082877792418003,
      -0.028864845633506775,
      0.1026635393500328,
      0.07771411538124084,
      0.006124038249254227,
      -0.021167276427149773,
      0.001324838143773377,
      -0.05538317933678627,
      0.03244299069046974,
      0.02753353863954544,
      -0.09767015278339386,
      -0.06323835998773575,
      0.002584572648629546,
      0.08651192486286163,
      0.017178203910589218,
      -0.001642647897824645,
      -0.06961052119731903,
      -0.007424990646541119,
      -0.004207836929708719,
      0.04949617385864258,
      0.02587778866291046,
      -0.01029090117663145,
      0.0675089955329895,
      -0.018493730574846268,
      -0.0034149857237935066,
      -0.021417003124952316,
      -0.014470700174570084,
      0.04694431647658348,
      0.029141200706362724,
      -0.023035172373056412,
      0.075623519718647,
      -0.03862331435084343,
      -0.030801506713032722,
      -0.047398071736097336,
      -0.07552618533372879,
      -0.03908078372478485,
      -0.00891455914825201,
      -0.048586323857307434,
      0.00065685308072716,
      0.06626183539628983,
      -0.021352557465434074,
      -0.11791575700044632,
      0.006063646636903286,
      0.09925702959299088,
      0.028561614453792572,
      -0.04049338027834892,
      -0.056138794869184494,
      -0.02647988498210907,
      -0.02553192526102066,
      -0.023963291198015213,
      -0.01539728231728077,
      0.023602014407515526,
      0.052792519330978394,
      0.06390225887298584,
      -0.07151949405670166,
      -0.1002991795539856,
      0.04198949784040451,
      -0.018224654719233513,
      -0.061469417065382004,
      -0.018338825553655624,
      -0.006710757501423359,
      -0.0029953133780509233,
      0.007145198527723551,
      0.09513641148805618,
      0.00041766552021726966,
      0.08483287692070007,
      0.012416061013936996,
      -0.017485739663243294,
      0.011643615551292896,
      0.005085189826786518,
      0.0060006799176335335,
      0.025018781423568726,
      -0.039910029619932175,
      -0.029757631942629814,
      -0.01393458154052496,
      0.00446753716096282,
      0.05186593532562256,
      0.033866025507450104,
      -0.0348292775452137,
      0.01996285654604435,
      -0.020906556397676468,
      0.02131653018295765,
      0.06091464310884476,
      0.044058408588171005,
      -0.005562774371355772,
      -0.009025144390761852,
      -0.039978720247745514,
      -0.01350426860153675,
      -0.007858685217797756,
      -0.06783918291330338,
      -0.052632469683885574,
      0.054791487753391266,
      -0.041347164660692215,
      0.0005810699076391757,
      -0.031450625509023666,
      0.0003027011698577553,
      -0.07269737869501114,
      0.007900392636656761,
      -0.019474277272820473,
      -0.027831602841615677,
      -0.08154970407485962,
      0.008921095170080662,
      0.058003149926662445,
      -0.04252200946211815,
      -0.004464419558644295,
      -0.0071645756252110004,
      0.009865808300673962,
      -0.0015429084887728095,
      0.04113895818591118,
      0.05016930401325226,
      0.017704395577311516,
      0.051817622035741806,
      0.08357261121273041,
      0.035581354051828384,
      0.01567348651587963,
      -0.01792408712208271,
      -0.027569007128477097,
      -0.006250706966966391,
      -0.0021766123827546835,
      0.03210098668932915,
      0.016916587948799133,
      0.02894444949924946,
      0.021219056099653244,
      0.07540225237607956,
      0.03451728820800781,
      -0.05578727647662163,
      0.022184446454048157,
      -0.015973296016454697,
      -0.020105861127376556,
      -0.021510491147637367,
      -0.07920629531145096,
      -0.019741440191864967,
      0.04776716232299805,
      -0.02315373718738556,
      -0.005293897818773985,
      -0.023191487416625023,
      0.03936026617884636,
      0.019398443400859833,
      0.1155635192990303,
      0.07912662625312805,
      0.0363110713660717,
      -0.0068481010384857655,
      -0.058330271393060684,
      0.003832469927147031,
      0.016516484320163727,
      -0.020581191405653954,
      -0.03382405266165733,
      -0.007393351290374994,
      -0.016085460782051086,
      0.004121134057641029,
      0.004996584262698889,
      0.04618950933218002,
      -0.05794765055179596,
      0.04197947680950165,
      0.08017287403345108,
      -0.000588432711083442,
      0.03586810082197189,
      -0.008522169664502144,
      -0.009300251491367817,
      -0.09110252559185028,
      0.06540566682815552,
      0.06468657404184341,
      -0.0746178850531578,
      0.016129856929183006,
      0.04904204607009888,
      -0.040780842304229736,
      -0.017925364896655083,
      0.017140433192253113,
      0.029207386076450348,
      -0.011170124635100365,
      0.0022949054837226868,
      -0.06353620439767838,
      -0.06413492560386658,
      0.02454434148967266,
      0.04120124876499176,
      -0.0819619819521904,
      0.031203167513012886,
      -0.011520584113895893,
      -0.014040066860616207,
      0.057384613901376724,
      -0.035179805010557175,
      0.053034890443086624,
      -0.0939456894993782,
      -0.017077485099434853,
      0.026295682415366173,
      -0.04342134669423103,
      0.09174744039773941,
      -0.033992234617471695,
      -0.040710579603910446,
      -0.01293414831161499,
      -0.0026932309847325087,
      0.04204200208187103,
      -0.010419652797281742,
      0.009152768179774284,
      -0.045456625521183014,
      0.022919336333870888,
      -0.031244711950421333,
      0.01628277450799942,
      0.037384383380413055,
      -0.038716815412044525,
      0.06253165006637573,
      0.014272324740886688,
      0.03319793567061424,
      -0.004550952464342117,
      0.014086713083088398,
      0.0036168373189866543,
      -0.01533147320151329,
      -0.006035204511135817,
      -0.015501691959798336,
      0.01932833343744278,
      -0.04211874678730965,
      -0.04155834764242172,
      0.030682440847158432,
      -0.0015071657253429294,
      0.028078855946660042,
      0.05408494547009468,
      0.04879247397184372,
      0.057961177080869675,
      0.008044717833399773,
      -0.01230759546160698,
      -0.04564177989959717,
      -0.04893099144101143,
      0.029208388179540634,
      0.028556758537888527,
      0.013385516591370106,
      0.1284075826406479,
      0.003930558916181326,
      0.027816206216812134,
      0.02034607157111168,
      0.005010766442865133,
      0.061704181134700775,
      -0.04091504216194153,
      -0.04521436244249344,
      0.02392328903079033,
      -0.04542877525091171,
      0.0334651805460453,
      0.0060905455611646175,
      0.08200139552354813,
      -0.06092530116438866,
      -0.02663068100810051,
      -0.036887433379888535,
      -0.012371482327580452,
      -0.06542526930570602,
      0.029428763315081596,
      0.05818137526512146,
      0.062466301023960114,
      -0.07896795868873596,
      -0.017553163692355156,
      -0.019750118255615234,
      0.07790705561637878,
      -0.02314799092710018,
      -0.024761442095041275,
      0.0554809495806694,
      -0.08836696296930313,
      0.05477938428521156,
      -0.0036929200869053602,
      0.03300681710243225,
      0.04122695326805115,
      0.05746405944228172,
      -0.026849549263715744,
      0.09741660952568054,
      -0.05654149875044823,
      0.019477879628539085,
      0.01640775427222252,
      -0.024785542860627174,
      0.05486702546477318,
      -0.02875998243689537,
      0.027840448543429375,
      0.0663641169667244,
      0.02891252562403679,
      0.09945125877857208,
      -0.02688448689877987,
      0.0811241865158081,
      -0.02634323760867119,
      -0.025024909526109695,
      -0.09431582689285278,
      -0.04432030767202377,
      -0.027355164289474487,
      0.03546948358416557,
      0.004877409897744656,
      -0.000999264419078827,
      0.018467489629983902,
      -0.04533755034208298,
      -0.0017894718330353498,
      -0.008783172816038132,
      0.0034185710828751326,
      -0.004979810211807489,
      -0.03584723919630051,
      0.02606724575161934,
      -0.03886530548334122,
      0.0401175320148468,
      -0.03810504078865051,
      -0.04752180352807045,
      -0.015673935413360596,
      -0.03228583559393883,
      -0.09189876168966293,
      9.005435276776552e-05,
      -0.04659493640065193,
      0.0307085532695055,
      0.1082087829709053,
      -0.08234196156263351,
      -0.025701047852635384,
      0.020902317017316818,
      -0.010641518980264664,
      -0.02672952227294445,
      0.02923724241554737,
      0.018274525180459023,
      -0.013702002353966236,
      0.036802876740694046,
      -0.0038747224025428295,
      -0.005816916469484568,
      0.0008420053636655211,
      0.0077698142267763615,
      0.035149428993463516,
      0.009149876423180103,
      -0.02108859457075596,
      0.04131288826465607,
      0.0694241151213646,
      0.04876245930790901,
      0.03249615430831909,
      -0.015136979520320892,
      0.08226832002401352,
      0.016221599653363228,
      0.026241609826683998,
      -0.010349958203732967,
      -0.006800731644034386,
      -0.037823837250471115,
      -0.002075833734124899,
      -0.016607047989964485,
      -0.03145533800125122,
      0.04465861618518829,
      0.028591370210051537,
      -0.0020798519253730774,
      0.015890885144472122,
      -0.07512804120779037,
      -0.020780714228749275,
      -0.020594729110598564,
      0.07411810755729675,
      0.0539625845849514,
      -0.05878760293126106,
      0.011678281240165234,
      -0.06779523193836212,
      -0.05076393485069275,
      0.008639373816549778,
      0.07806603610515594,
      0.006794380024075508,
      0.03289957344532013,
      -0.041389260441064835,
      -0.04365718737244606,
      0.06759453564882278,
      0.04519551247358322,
      0.00983270350843668,
      0.03976896405220032,
      0.05645296350121498
    ],
    [
      0.024673666805028915,
      -0.0999264270067215,
      0.047799110412597656,
      0.03871501237154007,
      0.02128087915480137,
      0.10491453111171722,
      0.03592552989721298,
      0.0010350847151130438,
      -0.01754172518849373,
      0.003722331253811717,
      0.07120006531476974,
      0.12295643240213394,
      -0.0885637179017067,
      0.0073982416652143,
      -0.018154144287109375,
      -0.05488674342632294,
      0.0696682557463646,
      0.011342696845531464,
      -0.007554515730589628,
      -0.01895109750330448,
      -0.02859732322394848,
      0.07276473939418793,
      0.024384263902902603,
      0.005967861507087946,
      -7.299255230464041e-05,
      -0.03927060589194298,
      -0.018515126779675484,
      -0.01644837111234665,
      0.012081945315003395,
      -0.051819510757923126,
      0.0018201511120423675,
      0.020323511213064194,
      0.013894466683268547,
      0.029763473197817802,
      -0.09198286384344101,
      -0.05325767397880554,
      0.038935452699661255,
      0.07206596434116364,
      -0.03364844247698784,
      0.013551447540521622,
      -0.03211798891425133,
      0.013173593208193779,
      -0.07561841607093811,
      -0.00021271652076393366,
      -0.06681586802005768,
      -0.09227509051561356,
      0.032666001468896866,
      0.03797001391649246,
      0.02915475331246853,
      0.03168818727135658,
      -0.009803488850593567,
      -0.053547780960798264,
      0.04753997176885605,
      -0.006678941659629345,
      -0.044817689806222916,
      -0.032691288739442825,
      0.04568417742848396,
      0.014496617950499058,
      -0.03061531111598015,
      -0.10326649993658066,
      -0.026577571406960487,
      0.00378781626932323,
      -0.02416153997182846,
      -0.04451504722237587,
      -0.06587547063827515,
      -0.03142309561371803,
      0.03374878689646721,
      0.0461152158677578,
      -0.033562660217285156,
      0.06331907957792282,
      -0.0005161615554243326,
      -0.032348085194826126,
      -0.07506955415010452,
      -0.037329528480768204,
      0.004942281637340784,
      -0.07789236307144165,
      0.029273735359311104,
      0.036740995943546295,
      0.09055905789136887,
      -0.02489888109266758,
      0.060024194419384,
      0.04722289741039276,
      -0.05707164108753204,
      -0.03923896327614784,
      -0.003249648492783308,
      -0.011268523521721363,
      0.00149463617708534,
      -0.07699321955442429,
      -0.00298267207108438,
      -0.038470953702926636,
      0.05499481037259102,
      -0.05454467609524727,
      -0.053702402859926224,
      -0.0169457346200943,
      0.0412820540368557,
      -0.04668286442756653,
      -0.0687464252114296,
      -0.027170928195118904,
      -0.012047460302710533,
      0.024692820385098457,
      0.0037976352032274008,
      -0.012569623067975044,
      -0.006063176319003105,
      -0.01668407954275608,
      -0.03838273882865906,
      0.013234211131930351,
      0.016464922577142715,
      -0.019579561427235603,
      0.028828611597418785,
      -0.013762940652668476,
      -0.015903713181614876,
      -0.023541532456874847,
      -0.0898856669664383,
      -0.000762135605327785,
      -0.021603712812066078,
      -0.052173566073179245,
      0.012598639354109764,
      0.021393252536654472,
      -0.06456472724676132,
      0.04345151036977768,
      0.0032688810024410486,
      -0.024334510788321495,
      0.03330923989415169,
      0.023046474903821945,
      0.005579609889537096,
      -0.026253316551446915,
      -0.055408723652362823,
      -0.012728104367852211,
      0.06776637583971024,
      -0.043520160019397736,
      0.06672585010528564,
      0.027221137657761574,
      0.09517534077167511,
      -0.07054731994867325,
      -0.04611048102378845,
      0.01116046030074358,
      -0.016332343220710754,
      0.022327611222863197,
      0.010132239200174809,
      0.008611072786152363,
      0.004982658661901951,
      0.0008435123017989099,
      -0.16409288346767426,
      -0.092325359582901,
      -0.10349086672067642,
      0.03224051371216774,
      -0.0007561182137578726,
      0.0022321720607578754,
      0.037502188235521317,
      -0.019216706976294518,
      -0.0004907024558633566,
      -0.01022724062204361,
      0.08565149456262589,
      0.08659739792346954,
      0.004173782654106617,
      0.051283933222293854,
      -0.040426623076200485,
      0.010628261603415012,
      -0.0061012026853859425,
      0.029908284544944763,
      -0.011740200221538544,
      -0.09603514522314072,
      -0.06182796508073807,
      0.061682332307100296,
      -0.023862510919570923,
      -0.10944607108831406,
      0.07599537074565887,
      -0.0011548460461199284,
      0.0030835014767944813,
      -0.04609552398324013,
      -0.03471433371305466,
      0.0720594972372055,
      -0.031731653958559036,
      0.005686746910214424,
      -0.01777762547135353,
      0.01782914809882641,
      0.03466373309493065,
      -0.03007759340107441,
      0.08771885186433792,
      0.024003516882658005,
      0.10416819900274277,
      0.046835098415613174,
      -0.04080365225672722,
      -0.02672557719051838,
      -0.015402071177959442,
      -0.015794958919286728,
      -0.021046996116638184,
      -0.06541556864976883,
      -0.048755399882793427,
      -0.06786555796861649,
      -0.036012548953294754,
      -0.010621173307299614,
      -0.004153195768594742,
      -0.02831742726266384,
      0.016100948676466942,
      -0.026270104572176933,
      0.03690769523382187,
      0.046133484691381454,
      -0.03626244515180588,
      0.05779948830604553,
      0.005620594136416912,
      0.0017186484765261412,
      0.0014317056629806757,
      -0.03191046044230461,
      0.1149556115269661,
      -0.020459920167922974,
      0.049371007829904556,
      0.01823432743549347,
      0.031245741993188858,
      -0.02754724584519863,
      -0.020226897671818733,
      -0.02959170565009117,
      0.047526877373456955,
      -0.011142592877149582,
      0.002095697447657585,
      0.04998147860169411,
      0.054241374135017395,
      -0.017594637349247932,
      0.03545476868748665,
      -0.08409426361322403,
      0.013699068687856197,
      -0.02599213272333145,
      -0.051821254193782806,
      -0.09918713569641113,
      -0.0259432140737772,
      -0.05098266899585724,
      0.07412661612033844,
      1.700950633676257e-05,
      0.01814020611345768,
      -0.004303766414523125,
      0.027257423847913742,
      0.04660463705658913,
      3.9155718695838004e-05,
      -0.027129875496029854,
      -0.009879693388938904,
      -0.030979406088590622,
      -0.029301004484295845,
      -0.00911754835397005,
      0.04162750765681267,
      0.06683856248855591,
      0.06963569670915604,
      0.050920020788908005,
      0.09986400604248047,
      0.05078559368848801,
      -0.04631313309073448,
      -0.014132684096693993,
      0.006676283199340105,
      0.03452866151928902,
      0.00995249580591917,
      -0.018031880259513855,
      -0.02390085719525814,
      -0.0072576566599309444,
      -0.02614946849644184,
      0.05437818914651871,
      -0.057011641561985016,
      0.011306928470730782,
      -0.07568899542093277,
      0.001299968222156167,
      -0.04598483815789223,
      0.04357334226369858,
      0.06486713886260986,
      0.02159668318927288,
      -0.1484633982181549,
      0.050862040370702744,
      0.0583336278796196,
      0.007966319099068642,
      -0.008875558152794838,
      -0.0005056638037785888,
      0.048547420650720596,
      0.0087288124486804,
      -0.07329188287258148,
      0.01596650667488575,
      0.037925224751234055,
      0.014291300438344479,
      -0.0349547453224659,
      -0.0061684404499828815,
      -0.02847130596637726,
      -0.019476797431707382,
      0.023410597816109657,
      -0.027405207976698875,
      -0.02042686566710472,
      -0.003700616303831339,
      -0.03532605245709419,
      0.07067584991455078,
      -0.06219670549035072,
      0.06613504886627197,
      -0.09005193412303925,
      -0.013513525016605854,
      -0.022296173498034477,
      0.026752421632409096,
      -0.10784630477428436,
      -0.014770434238016605,
      0.0009247197885997593,
      -0.015202675014734268,
      -0.03845147415995598,
      -0.04728421941399574,
      0.039088476449251175,
      -0.04287894070148468,
      0.019198885187506676,
      -0.02560890093445778,
      -0.023967761546373367,
      -0.10127224773168564,
      -0.022161608561873436,
      0.019758934155106544,
      0.043875742703676224,
      0.01460687629878521,
      0.017569558694958687,
      -0.00789658073335886,
      0.07839652895927429,
      -0.06909818947315216,
      0.03746967762708664,
      -0.11010207235813141,
      0.03817028924822807,
      -0.0106878113001585,
      -0.02905307710170746,
      -0.0023117223754525185,
      -0.014939711429178715,
      -0.002000426175072789,
      2.0346444216556847e-05,
      -0.028591757640242577,
      0.013644274324178696,
      -0.031555090099573135,
      -0.0087963305413723,
      -0.04646828770637512,
      -0.05541606619954109,
      0.10272220522165298,
      -0.006466991268098354,
      -0.06493469327688217,
      0.07342937588691711,
      -0.023264996707439423,
      0.010242603719234467,
      0.0039567952044308186,
      -0.0008336485479958355,
      -0.011669693514704704,
      0.0035851914435625076,
      -0.002793530933558941,
      -0.006481080316007137,
      -0.0029339694883674383,
      0.008887114003300667,
      -0.019733976572752,
      0.035377487540245056,
      -0.020060332491993904,
      -0.04546352103352547,
      0.06625896692276001,
      -0.11850055307149887,
      0.014906173571944237,
      -0.027313655242323875,
      -0.06125355511903763,
      -0.05770820006728172,
      -0.012385767884552479,
      -0.055867087095975876,
      -0.04703623056411743,
      0.07109136879444122,
      -0.03019699454307556,
      0.032871853560209274,
      0.02403067983686924,
      -0.03258182480931282,
      0.015678362920880318,
      0.03383291885256767,
      -0.006811514962464571,
      -0.0020627432968467474,
      0.0759410560131073,
      -0.033499620854854584,
      0.04446166753768921,
      0.01772337220609188,
      0.009570743888616562,
      -0.07772773504257202,
      0.0010045898379758,
      0.004840184468775988,
      0.0003964089264627546,
      -0.009126159362494946,
      0.000653610157314688,
      0.00943708699196577,
      -0.0850207731127739,
      -0.0030755246989428997,
      -0.04137382656335831,
      0.031669531017541885,
      -0.03398837894201279,
      0.003492409363389015,
      -0.03130082041025162,
      -0.01122125145047903,
      0.0026038866490125656,
      -0.01804449036717415,
      -0.0270977895706892,
      0.01524804625660181,
      -0.06558047980070114,
      0.07043696194887161,
      0.030343806371092796,
      -0.024380730465054512,
      0.01466600876301527,
      0.0424153208732605,
      0.002641799161210656,
      -0.051684848964214325,
      -0.006612254306674004,
      -0.01642264798283577,
      0.011094213463366032,
      -0.010141621343791485,
      -0.01953703723847866,
      -0.039153698831796646,
      -0.052410293370485306,
      -0.05302172899246216,
      0.010046194307506084,
      0.033188678324222565,
      -0.0010314579121768475,
      0.015083126723766327,
      -0.02172316610813141,
      -0.038335949182510376,
      -0.005925769917666912,
      0.0009502620087005198,
      -0.11107292026281357,
      -0.005125940777361393,
      -0.014450528658926487,
      -0.06960495561361313,
      -0.0054902732372283936,
      0.040238309651613235,
      -0.08108770102262497,
      -0.04424265772104263,
      -0.03362436592578888,
      0.0014702563639730215,
      0.009504672139883041,
      0.019855763763189316,
      -0.029660888016223907,
      -0.009653938934206963,
      0.004587746225297451,
      0.054408155381679535,
      -0.08510251343250275,
      -0.09274611622095108,
      -0.0021879319101572037,
      0.05137953907251358,
      0.05175106227397919,
      -0.005135827697813511,
      0.011424725875258446,
      -0.008854652754962444,
      -0.010375128127634525,
      0.007619228679686785,
      -0.02808244340121746,
      0.07678210735321045,
      -3.176713653374463e-05,
      0.061222970485687256,
      -0.06234322115778923,
      -0.027268895879387856,
      0.006743052043020725,
      0.04233226925134659,
      -0.06447586417198181,
      -0.01799008995294571,
      -0.02903851494193077,
      -0.02552586980164051,
      0.02606603130698204,
      -0.008516955189406872,
      -0.0488339401781559,
      -0.011746558360755444,
      -0.05001885071396828,
      0.004500408656895161,
      -0.02391890063881874,
      -0.015325859189033508,
      0.07095293700695038,
      0.009938356466591358,
      -0.07436487078666687,
      0.005997487809509039,
      0.02772851660847664,
      0.00624182540923357,
      -0.0925573781132698,
      0.06800063699483871,
      0.002780200680717826,
      -0.07524123787879944,
      0.07564976811408997,
      -0.0689363032579422,
      -0.03817237913608551,
      0.041953299194574356,
      0.026416713371872902,
      0.07062903791666031,
      0.014742173254489899,
      -0.032316699624061584,
      -0.0473005436360836,
      -0.031424522399902344,
      0.008625715970993042,
      0.0615539588034153,
      0.07324831932783127,
      -0.013137700967490673,
      -0.032208289951086044,
      -0.03479037061333656,
      0.04431721568107605,
      -0.023725058883428574,
      -0.038940925151109695,
      0.04735342413187027,
      0.018943684175610542,
      -0.06465686112642288,
      0.03602547198534012,
      -0.07549124956130981,
      -0.033494725823402405,
      0.002604138106107712,
      0.023886021226644516,
      -0.05535611882805824,
      0.03759201988577843,
      -0.04684653505682945,
      -0.04821421951055527,
      -0.09320741891860962,
      -0.016030853614211082,
      0.11581601202487946,
      0.026507534086704254,
      0.03976253420114517,
      -0.01315055787563324,
      0.040573131293058395
    ],
    [
      0.007225805893540382,
      0.009907389990985394,
      -0.0038240046706050634,
      0.014718245714902878,
      -0.019997822120785713,
      0.024968940764665604,
      0.027388446033000946,
      0.012669965624809265,
      -0.024450521916151047,
      -0.08619848638772964,
      -0.044033557176589966,
      -0.03129318729043007,
      -0.009129361249506474,
      0.006919096689671278,
      0.03352769836783409,
      0.04302884638309479,
      0.0010315232211723924,
      -0.028180580586194992,
      0.02252846769988537,
      0.06140296161174774,
      0.00179157720413059,
      0.021891513839364052,
      -0.061850178986787796,
      -0.012766252271831036,
      -0.053018711507320404,
      -0.027559636160731316,
      0.010604647919535637,
      0.024946775287389755,
      -0.024168595671653748,
      -0.06349903345108032,
      0.045471932739019394,
      -0.06392888724803925,
      0.055392131209373474,
      0.0030791545286774635,
      0.025993414223194122,
      -0.011540219187736511,
      -0.01931646279990673,
      0.0030086154583841562,
      -0.008506977930665016,
      -0.010437984950840473,
      0.06350605934858322,
      0.015105973929166794,
      0.07633426040410995,
      -0.04306219518184662,
      0.021873146295547485,
      0.07614663243293762,
      -0.008856569416821003,
      -0.0066227964125573635,
      -0.030383814126253128,
      0.035230446606874466,
      -0.05635661631822586,
      0.01610374264419079,
      -0.018401144072413445,
      0.008586744777858257,
      0.027731994166970253,
      0.04956401139497757,
      -0.029561562463641167,
      0.004672375041991472,
      -0.007773665711283684,
      -0.026790814474225044,
      -0.0794464647769928,
      -0.048755306750535965,
      -0.04805470630526543,
      0.01555956806987524,
      -0.05126582831144333,
      -0.04821784421801567,
      0.07087589055299759,
      -0.02997421845793724,
      -0.007570447400212288,
      -0.011902940459549427,
      -0.002163856290280819,
      0.06360658258199692,
      -0.0001013690562103875,
      -0.01867201365530491,
      -0.013638749718666077,
      0.05098184198141098,
      0.05079285800457001,
      -0.030614489689469337,
      0.055365536361932755,
      -0.049064721912145615,
      -0.02656577154994011,
      -0.028477855026721954,
      0.05331961810588837,
      0.0007128858705982566,
      -0.027415793389081955,
      0.002277499996125698,
      0.04183729365468025,
      -0.04641443490982056,
      -0.006451701279729605,
      0.025538461282849312,
      0.009103232063353062,
      0.008289175108075142,
      0.06274700909852982,
      -0.06806397438049316,
      -0.05975547805428505,
      0.02053947187960148,
      0.011359321884810925,
      0.01097333338111639,
      -0.01946800947189331,
      -0.02297961339354515,
      -0.028769003227353096,
      -0.005997763946652412,
      -0.03322408348321915,
      0.006267142482101917,
      -0.03614924103021622,
      -0.0519779771566391,
      0.04651251807808876,
      -0.06098802760243416,
      0.0170451607555151,
      -0.08777761459350586,
      0.04033477604389191,
      -0.053290851414203644,
      0.015774985775351524,
      -0.08379339426755905,
      0.01950911059975624,
      0.032616134732961655,
      0.023598847910761833,
      -0.007890102453529835,
      -0.025755085051059723,
      -0.0280598197132349,
      0.029781397432088852,
      0.010293172672390938,
      0.029645945876836777,
      -0.08326523751020432,
      -0.005394943058490753,
      -0.01705155149102211,
      -0.008870984427630901,
      -0.06945844739675522,
      0.07236948609352112,
      0.03746044263243675,
      0.1336514800786972,
      0.05655292049050331,
      -0.008550041355192661,
      -0.010989440605044365,
      -0.014365911483764648,
      0.0069273593835532665,
      0.03964424133300781,
      -0.06481029093265533,
      -0.031493280082941055,
      -0.03464209660887718,
      0.03624362125992775,
      -0.09448753297328949,
      -0.04222416877746582,
      0.039061542600393295,
      0.026685159653425217,
      0.016483938321471214,
      0.03443310782313347,
      -0.035797324031591415,
      0.027150502428412437,
      0.016654333099722862,
      -0.028877858072519302,
      0.05495811626315117,
      0.050753138959407806,
      0.10351582616567612,
      -0.08585209399461746,
      0.0411883108317852,
      -0.07290404289960861,
      0.07000761479139328,
      0.018398838117718697,
      -0.028357012197375298,
      -0.0999426320195198,
      0.0015890431823208928,
      -0.09826307743787766,
      0.01509537547826767,
      -0.04611542448401451,
      -0.06638327986001968,
      -0.00271642510779202,
      0.0034325297456234694,
      0.050003036856651306,
      -0.02995438314974308,
      -0.0324568934738636,
      0.03258576989173889,
      0.046236228197813034,
      -0.017164377495646477,
      0.023126062005758286,
      0.02742571197450161,
      -0.01077498309314251,
      0.06338310986757278,
      0.012754692696034908,
      -0.009813599288463593,
      -0.06533920019865036,
      0.04671274125576019,
      0.000778187473770231,
      -0.021168584004044533,
      0.006626607850193977,
      -0.010908976197242737,
      0.034370940178632736,
      -0.00958462804555893,
      -0.03797456622123718,
      -0.004784028511494398,
      -0.10290953516960144,
      -0.031329575926065445,
      0.0005296922754496336,
      0.07028936594724655,
      -0.055382099002599716,
      0.01226297952234745,
      -0.07989715784788132,
      0.05056503415107727,
      -0.0068613323383033276,
      -0.013668358325958252,
      0.03724448382854462,
      -0.07137812674045563,
      0.01610443741083145,
      0.022420942783355713,
      0.047326408326625824,
      -0.03791787102818489,
      0.09749140590429306,
      0.03655748814344406,
      0.007916846312582493,
      -0.0009831612696871161,
      -0.021820686757564545,
      0.03214419260621071,
      0.0254347026348114,
      -0.03332474082708359,
      -0.04402640089392662,
      -0.07346296310424805,
      0.007239623460918665,
      0.032530445605516434,
      0.08330556005239487,
      0.036468278616666794,
      0.021838994696736336,
      -0.004121209494769573,
      -0.01173891220241785,
      0.009243445470929146,
      -0.07065172493457794,
      0.08273108303546906,
      -0.03593696281313896,
      0.0551069974899292,
      -0.07388799637556076,
      0.0637684091925621,
      0.09443147480487823,
      -0.0007708839839324355,
      0.0248518418520689,
      -0.029781725257635117,
      -0.03600238263607025,
      0.03743133321404457,
      -0.05705160275101662,
      0.043656472116708755,
      -0.050815656781196594,
      -0.024650834500789642,
      0.030232133343815804,
      -0.0069576529785990715,
      -0.015189223922789097,
      -0.03167514130473137,
      -0.03530263900756836,
      0.07773525267839432,
      -0.08164086192846298,
      0.041856441646814346,
      -0.08694912493228912,
      -0.014803571626543999,
      -0.00469389371573925,
      -0.014607841148972511,
      0.020246952772140503,
      0.04749935492873192,
      -0.03649771586060524,
      -0.07386460155248642,
      0.003124790033325553,
      -0.03682482987642288,
      0.013898482546210289,
      -0.09554514288902283,
      -0.017441995441913605,
      -0.03596070781350136,
      -0.01569918729364872,
      0.020720800384879112,
      -0.046994972974061966,
      0.06784987449645996,
      0.001323028700426221,
      -0.005876613315194845,
      0.04532542824745178,
      0.07984622567892075,
      -0.06075863540172577,
      0.08030640333890915,
      -0.003748892806470394,
      -0.08469946682453156,
      -0.028110509738326073,
      0.06421083211898804,
      -0.02791045978665352,
      -0.039653897285461426,
      -0.05453256145119667,
      0.07050284743309021,
      0.0736771821975708,
      0.02714775875210762,
      -0.0101547222584486,
      -0.005523963365703821,
      -0.017423052340745926,
      0.020052146166563034,
      0.013733330182731152,
      0.05732449144124985,
      -0.0049140541814267635,
      0.05232301354408264,
      -0.02104676328599453,
      0.12492106854915619,
      0.03331860899925232,
      0.0026431663427501917,
      0.01750815100967884,
      -0.06748493015766144,
      -0.06109730899333954,
      0.012724892236292362,
      -0.0020196540281176567,
      -0.0001393254497088492,
      -0.017319150269031525,
      0.0699138194322586,
      0.05652632564306259,
      -0.07943660020828247,
      0.08288966864347458,
      -0.05191358923912048,
      -0.02313240058720112,
      0.07690037786960602,
      -0.07395344227552414,
      0.026300828903913498,
      0.041201625019311905,
      -0.017402276396751404,
      -0.02343730442225933,
      -0.002143894787877798,
      0.030291004106402397,
      0.022737225517630577,
      -0.0017505002906545997,
      -0.034837860614061356,
      0.0006204302771948278,
      -0.04447110369801521,
      0.11439444124698639,
      -0.03454354777932167,
      -0.019194092601537704,
      0.007447227369993925,
      -0.07908912003040314,
      -0.00670310202986002,
      0.026770824566483498,
      0.08679381012916565,
      -0.01668556034564972,
      0.03589438647031784,
      0.030835412442684174,
      -0.007245016749948263,
      0.03929857909679413,
      -0.043062031269073486,
      -0.009429275058209896,
      0.042611461132764816,
      -0.03635468706488609,
      0.07963695377111435,
      0.007059805560857058,
      -0.021366864442825317,
      0.048596400767564774,
      -0.00646332697942853,
      -0.0877537876367569,
      0.00834565982222557,
      0.057471875101327896,
      0.009369847364723682,
      -0.00236169109120965,
      -0.11489663273096085,
      -0.03363629803061485,
      -0.0344613641500473,
      -0.014769685454666615,
      -0.020728833973407745,
      0.02501603029668331,
      0.01732846163213253,
      -0.03825477510690689,
      0.0031264417339116335,
      0.012135092169046402,
      -0.02600506693124771,
      0.032751135528087616,
      -0.006196656264364719,
      -0.03330305963754654,
      0.06407129764556885,
      -0.059133123606443405,
      -0.04227328673005104,
      -0.01761554554104805,
      0.04800155758857727,
      -0.04069706052541733,
      -0.013660776428878307,
      -0.029096659272909164,
      -0.018162306398153305,
      0.0058225421234965324,
      0.06341688334941864,
      0.07112488895654678,
      0.029987480491399765,
      -0.029567761346697807,
      -0.014111915603280067,
      -0.09518180042505264,
      0.003190720686689019,
      -0.04233407974243164,
      0.00833726767450571,
      -0.0465419702231884,
      -0.05219152942299843,
      -0.07008323818445206,
      -0.0209419596940279,
      -0.006501681171357632,
      -0.020263301208615303,
      0.03139527514576912,
      -0.01628417707979679,
      0.03058777190744877,
      0.027140934020280838,
      0.022434052079916,
      0.02061307616531849,
      -0.011787847615778446,
      -0.017646312713623047,
      0.005258120130747557,
      -0.01121650729328394,
      -0.019822239875793457,
      -0.0671507865190506,
      0.003690252313390374,
      -0.015328176319599152,
      -0.06030942499637604,
      0.0411025695502758,
      -0.07054728269577026,
      0.06490392237901688,
      0.0862795040011406,
      0.030949287116527557,
      0.00810319371521473,
      -0.024021299555897713,
      -0.026440655812621117,
      -0.06479541957378387,
      0.05009611323475838,
      0.010274781845510006,
      -0.022219713777303696,
      -0.04411248117685318,
      -0.02957334741950035,
      0.020151881501078606,
      -0.06217799708247185,
      0.10738418251276016,
      -0.006501507479697466,
      -0.002478483598679304,
      -0.052255623042583466,
      0.08950483798980713,
      -0.015311140567064285,
      -0.006401392165571451,
      -0.0713280588388443,
      -0.0009263011743314564,
      0.05550847202539444,
      -0.03409724310040474,
      -0.03609664738178253,
      -0.04621700197458267,
      -0.06046606972813606,
      0.053800977766513824,
      0.034844376146793365,
      0.0032183811999857426,
      0.02026958391070366,
      -0.03703892230987549,
      0.04884994775056839,
      0.06429744511842728,
      0.10136415809392929,
      0.002653188770636916,
      -0.08054602891206741,
      -0.029897550120949745,
      0.04380898177623749,
      -0.029603412374854088,
      -0.044185344129800797,
      -0.013008165173232555,
      -0.06106102466583252,
      0.03036528080701828,
      0.034754976630210876,
      0.0414811372756958,
      0.01738068461418152,
      0.009561214596033096,
      0.07716947793960571,
      -0.03622874617576599,
      0.06649671494960785,
      -0.023453518748283386,
      0.03914209455251694,
      0.02915186993777752,
      0.04599679261445999,
      -0.1092006266117096,
      -0.04202825576066971,
      -0.116056889295578,
      0.03502088785171509,
      0.01827525533735752,
      -0.029543211683630943,
      0.03888021036982536,
      0.02234860137104988,
      0.05542953684926033,
      -0.015153159387409687,
      0.038181185722351074,
      -0.0036837407387793064,
      0.01736205630004406,
      -0.0015281857922673225,
      -0.06340067833662033,
      -0.013984967954456806,
      0.01821037195622921,
      -0.08920463919639587,
      -0.000980172771960497,
      -0.011443335562944412,
      0.017680004239082336,
      -0.03940925374627113,
      0.045779772102832794,
      -0.017902797088027,
      -0.016941342502832413,
      0.015314136631786823,
      0.012069194577634335,
      -0.001232091337442398,
      -0.01772919110953808,
      0.027334000915288925,
      0.004538094159215689,
      -0.02840275689959526,
      0.0479762926697731,
      0.011324369348585606,
      -0.051680102944374084,
      0.0023934629280120134,
      0.040296681225299835,
      0.03178980201482773,
      0.009187486954033375,
      0.014256429858505726,
      -0.05607646703720093,
      -0.022271346300840378,
      0.024225184693932533,
      -0.026742910966277122
    ],
    [
      -0.04043600335717201,
      -0.03594553470611572,
      -0.008702506311237812,
      -0.0747290849685669,
      -0.02983625791966915,
      0.006688339170068502,
      -0.03297140821814537,
      -0.010741534642875195,
      -0.002651098882779479,
      -0.004129669163376093,
      -0.009704937227070332,
      -0.0566464364528656,
      -0.01259410660713911,
      0.06530216336250305,
      -0.025955097749829292,
      0.006986525375396013,
      0.013010556809604168,
      -0.02975587546825409,
      -0.03882521390914917,
      0.016429923474788666,
      -0.019214434549212456,
      0.06608200073242188,
      0.03303837403655052,
      -0.017441382631659508,
      -0.005226057954132557,
      0.055561233311891556,
      0.0759270042181015,
      -0.0717812106013298,
      -0.0049676490016281605,
      0.007311176508665085,
      0.004220595583319664,
      -0.03512455150485039,
      -0.022170303389430046,
      0.0035623223520815372,
      -0.09333794564008713,
      0.01448901742696762,
      0.10170939564704895,
      -0.01248499657958746,
      0.005410559941083193,
      -0.038844265043735504,
      0.04188663884997368,
      0.03132755309343338,
      0.049838900566101074,
      -0.06824664771556854,
      0.008084457367658615,
      0.030115550383925438,
      0.041487034410238266,
      0.01515582948923111,
      0.006943033542484045,
      0.008457210846245289,
      -0.009456980042159557,
      0.041745733469724655,
      -0.020287513732910156,
      0.0018463782034814358,
      0.009513587690889835,
      -0.07802271097898483,
      0.002590394578874111,
      -0.013099822215735912,
      -0.005626775324344635,
      -0.01212206669151783,
      -0.0007899542106315494,
      -0.05720282346010208,
      0.08501474559307098,
      0.034078147262334824,
      0.0532912015914917,
      -0.022201379761099815,
      0.08947689831256866,
      0.07228346914052963,
      0.02579524554312229,
      0.07151485979557037,
      0.018631262704730034,
      -0.0486668162047863,
      -0.04044080525636673,
      0.01855771616101265,
      0.049473974853754044,
      0.026410531252622604,
      -0.04918054863810539,
      0.00996469333767891,
      -0.07776393741369247,
      -0.1005447506904602,
      0.04717717319726944,
      -0.013151460327208042,
      -0.0015597648452967405,
      -0.07778862118721008,
      0.04882296547293663,
      0.037279125303030014,
      -0.037581611424684525,
      0.056965991854667664,
      0.06155724450945854,
      0.012416424229741096,
      0.04998776689171791,
      0.016681194305419922,
      -0.10404963046312332,
      -0.07515081018209457,
      0.01207889337092638,
      0.016978826373815536,
      -0.05218907818198204,
      0.00770860118791461,
      -0.013302382081747055,
      0.044515639543533325,
      -0.012450468726456165,
      -0.11451525241136551,
      0.023909561336040497,
      0.0025222490075975657,
      -0.04104355722665787,
      -0.04774201288819313,
      -0.04676297679543495,
      0.00982848834246397,
      -0.0665624663233757,
      -0.021757090464234352,
      -0.10535967350006104,
      0.05674908310174942,
      -0.010396474041044712,
      -0.08044850081205368,
      -0.014795016497373581,
      -0.032988112419843674,
      -0.032438065856695175,
      0.01573387160897255,
      -0.05389126390218735,
      -0.006903741974383593,
      -0.0647667869925499,
      0.03585837036371231,
      0.0005423193797469139,
      -0.004016740247607231,
      -0.07570312917232513,
      -0.02111232280731201,
      0.012181859463453293,
      0.02544490061700344,
      -0.03656618669629097,
      0.01537234429270029,
      0.07443248480558395,
      0.02092745527625084,
      0.009126095101237297,
      -0.032988063991069794,
      0.07322521507740021,
      0.04899218678474426,
      0.017693091183900833,
      0.06225547939538956,
      0.03745076432824135,
      -0.0074801077134907246,
      -0.0023830216377973557,
      0.012049909681081772,
      -0.008505204692482948,
      -0.05969996750354767,
      0.018265236169099808,
      -0.08889297395944595,
      -0.022182580083608627,
      -0.11787919700145721,
      0.012089518830180168,
      0.04874880611896515,
      -0.025535540655255318,
      -0.03575913980603218,
      -0.02288597822189331,
      0.09739645570516586,
      0.04100003093481064,
      -0.061761509627103806,
      -0.0002482949639670551,
      -0.011445581912994385,
      0.02224074676632881,
      0.07945000380277634,
      -0.07834973186254501,
      -0.00037096996675245464,
      0.08703664690256119,
      0.0026152620557695627,
      -0.02809855528175831,
      0.026816759258508682,
      -0.021758001297712326,
      -0.02210664004087448,
      0.04815453663468361,
      0.027012456208467484,
      0.017617642879486084,
      -0.023772265762090683,
      -0.04577768221497536,
      0.024189170449972153,
      0.06646164506673813,
      0.037886571139097214,
      0.05089576542377472,
      -0.025177311152219772,
      0.04734206944704056,
      0.10524065792560577,
      0.046904612332582474,
      0.019657785072922707,
      0.027927467599511147,
      0.004807128105312586,
      0.007193127181380987,
      0.02876453660428524,
      0.14605145156383514,
      0.056673724204301834,
      -0.02546985261142254,
      -0.04794742166996002,
      0.024141699075698853,
      0.004127622582018375,
      0.09460221230983734,
      0.03909776732325554,
      0.013939459808170795,
      -0.03906681016087532,
      0.023958271369338036,
      -0.022254852578043938,
      0.0569121316075325,
      0.07067519426345825,
      -0.00033237418392673135,
      -0.02386731468141079,
      0.03146461024880409,
      0.03876926749944687,
      -0.016881100833415985,
      0.0025323093868792057,
      -0.08599162846803665,
      0.019714519381523132,
      -0.04796413332223892,
      0.1737767904996872,
      -0.004563364200294018,
      0.000901112740393728,
      -0.023771943524479866,
      0.12645351886749268,
      -0.0509972907602787,
      0.06013313680887222,
      0.018437592312693596,
      -0.120900958776474,
      0.01304765697568655,
      -0.008139567449688911,
      0.030124491080641747,
      -0.018684417009353638,
      -0.06887397170066833,
      0.11020615696907043,
      -0.013993564061820507,
      -0.052549127489328384,
      -0.023787787184119225,
      -0.039881180971860886,
      -0.09024368226528168,
      -0.007475840859115124,
      0.013552539981901646,
      0.056502025574445724,
      -0.03450682759284973,
      0.03599582985043526,
      0.01256919652223587,
      -0.008334818296134472,
      0.04336288198828697,
      -0.023805493488907814,
      0.04057275876402855,
      -0.04203007370233536,
      -0.03849588707089424,
      -0.05489863455295563,
      -0.0290683526545763,
      0.002653774106875062,
      0.03391871601343155,
      -0.03828906640410423,
      -0.04267815873026848,
      -0.006494559347629547,
      0.016762778162956238,
      -0.047105979174375534,
      -0.04560283198952675,
      -0.050865840166807175,
      -0.022215215489268303,
      -0.0064560966566205025,
      -0.021808920428156853,
      -0.05473106727004051,
      0.020241469144821167,
      0.0162121020257473,
      0.04915037751197815,
      0.00887341983616352,
      -0.01739860512316227,
      -0.036517441272735596,
      0.08464910089969635,
      -0.02875703014433384,
      0.0355643592774868,
      0.04438619315624237,
      -0.02919352985918522,
      0.013568841852247715,
      -0.03658305108547211,
      -0.03837457671761513,
      0.00778971565887332,
      0.01882864534854889,
      -0.001320308307185769,
      0.04367935284972191,
      -0.014795420691370964,
      0.021790755912661552,
      -0.008234298788011074,
      -0.07037065178155899,
      -0.018111130222678185,
      0.0005194481927901506,
      -0.034715685993433,
      -0.026721535250544548,
      0.04389924928545952,
      0.009440768510103226,
      -0.031692985445261,
      -0.008466308936476707,
      -0.0358242429792881,
      0.03282448649406433,
      -0.032988209277391434,
      0.012787388637661934,
      0.004621385131031275,
      0.03110879473388195,
      0.04880927875638008,
      -0.02715676836669445,
      0.04576084390282631,
      0.023353027179837227,
      0.04711565002799034,
      -0.003596883499994874,
      0.10225554555654526,
      0.021141696721315384,
      0.05671234428882599,
      0.03448305279016495,
      0.027887677773833275,
      0.0374249704182148,
      -0.003441933309659362,
      0.00709939282387495,
      0.09763284772634506,
      0.08373520523309708,
      -0.06681910902261734,
      -0.08969604223966599,
      0.025457892566919327,
      0.02771798148751259,
      -0.021122142672538757,
      0.030129510909318924,
      -0.021822459995746613,
      -0.05223148688673973,
      -0.07633158564567566,
      0.004122007172554731,
      -0.03216371685266495,
      -0.024444734677672386,
      -0.012301096692681313,
      0.0032438302878290415,
      -0.0778236836194992,
      0.054377682507038116,
      -0.10583735257387161,
      0.05013710632920265,
      -0.04975201189517975,
      0.034405484795570374,
      0.09185244143009186,
      0.0324130542576313,
      0.05023244023323059,
      0.04924532398581505,
      0.05696139484643936,
      -0.06291209161281586,
      0.017392562702298164,
      -0.02372935228049755,
      0.06292282789945602,
      0.08440691977739334,
      0.024356946349143982,
      -0.031075013801455498,
      0.012774212285876274,
      -0.032600171864032745,
      -0.03955879062414169,
      0.028120771050453186,
      0.011143151670694351,
      -0.0012781277764588594,
      -0.045675840228796005,
      -0.0775127038359642,
      -0.01594078727066517,
      -0.054689694195985794,
      -0.031863126903772354,
      0.008099568076431751,
      -0.0030619020108133554,
      7.25389618310146e-05,
      0.01350619550794363,
      0.057113248854875565,
      -0.019280755892395973,
      -0.023622769862413406,
      0.058362189680337906,
      0.05666566640138626,
      0.04906386882066727,
      -0.04113965481519699,
      -0.03446356952190399,
      -0.00780827272683382,
      0.07768703997135162,
      0.02447841502726078,
      0.01528701651841402,
      -0.033165913075208664,
      -0.03552126884460449,
      0.08253002166748047,
      0.043584153056144714,
      -0.013602539896965027,
      -0.015780966728925705,
      0.017202839255332947,
      0.033960964530706406,
      0.037327442318201065,
      -0.03706866502761841,
      0.09052931517362595,
      -0.02864912524819374,
      -0.01804257184267044,
      0.0009158829343505204,
      -0.01727059669792652,
      0.0985879972577095,
      -0.07445526123046875,
      0.008374142460525036,
      0.0063122776336967945,
      -0.043169062584638596,
      0.02343020588159561,
      0.023116789758205414,
      -0.00892789475619793,
      0.005917067173868418,
      -0.021758701652288437,
      -0.0375148244202137,
      -0.04186999052762985,
      0.007700059097260237,
      -0.009871457703411579,
      0.044670626521110535,
      -0.023067442700266838,
      -0.025376569479703903,
      0.08003344386816025,
      -0.0616971030831337,
      0.004621788859367371,
      -0.0077539789490401745,
      -0.011079917661845684,
      0.009977677837014198,
      0.12622502446174622,
      0.0014931955374777317,
      0.03142845258116722,
      0.037584878504276276,
      0.033420220017433167,
      0.04546283558011055,
      -0.024472877383232117,
      0.06299673765897751,
      0.057987555861473083,
      0.008809173479676247,
      -0.02601568028330803,
      0.007246825844049454,
      -0.002124610124155879,
      0.036388784646987915,
      0.0168888159096241,
      0.03291101008653641,
      0.018871711567044258,
      0.07903257757425308,
      -0.07428592443466187,
      0.03910940885543823,
      0.02968696877360344,
      0.03792845830321312,
      -0.0566675141453743,
      -0.021102052181959152,
      -0.05228239670395851,
      -0.050583917647600174,
      -0.03190542012453079,
      0.02883731573820114,
      -0.0712292492389679,
      0.0022667604498565197,
      0.05796264857053757,
      0.015773892402648926,
      -0.015999402850866318,
      0.047470204532146454,
      0.024823470041155815,
      -0.02478298731148243,
      -0.060270458459854126,
      -0.050899989902973175,
      -0.03219426050782204,
      -0.06505393236875534,
      0.06946287304162979,
      0.03297780454158783,
      -0.072276771068573,
      -0.022471709176898003,
      0.05476360023021698,
      0.10356248170137405,
      0.010762782767415047,
      0.01710328459739685,
      0.027718162164092064,
      -0.0006636840407736599,
      -0.009928637184202671,
      -0.038215525448322296,
      -0.027925698086619377,
      0.03616156429052353,
      -0.042783282697200775,
      0.02748873271048069,
      -0.036549437791109085,
      0.0032305207569152117,
      0.02722802944481373,
      -0.028063856065273285,
      -0.032965000718832016,
      0.02915659174323082,
      0.07114437967538834,
      -0.03921908140182495,
      -0.03456714749336243,
      -0.04532982409000397,
      -0.019040998071432114,
      -0.027996966615319252,
      -0.00164322170894593,
      0.010687089525163174,
      -0.01439167931675911,
      -0.07166194915771484,
      -0.0487089604139328,
      0.008912941440939903,
      -0.010641041211783886,
      0.015876278281211853,
      0.01981176994740963,
      0.061732955276966095,
      -0.057547565549612045,
      0.025041762739419937,
      -0.00015474467363674194,
      0.03092745505273342,
      0.0057661272585392,
      -0.039357740432024,
      -0.030644740909337997,
      -0.03797955811023712,
      0.056845054030418396,
      0.04718061164021492,
      0.04815123230218887,
      0.055332865566015244,
      -0.11342400312423706,
      0.05353125184774399,
      -0.019328277558088303,
      0.040981944650411606,
      0.050839267671108246,
      0.020504765212535858,
      -0.03081783466041088,
      0.008198082447052002
    ],
    [
      0.037214454263448715,
      -0.05682644620537758,
      -0.032611552625894547,
      -0.006064258050173521,
      -0.006097665522247553,
      -0.004913399927318096,
      0.0012269391445443034,
      -0.01667887717485428,
      0.06571170687675476,
      -0.05366964265704155,
      0.027151737362146378,
      -0.07772550731897354,
      -0.0490712970495224,
      -0.09652511775493622,
      0.07198943942785263,
      -0.002885245019569993,
      -0.0012755539501085877,
      0.02587977796792984,
      -0.07307057082653046,
      -0.05844397097826004,
      0.05548190325498581,
      -0.01110793650150299,
      0.026815852150321007,
      -0.024993836879730225,
      0.1004839763045311,
      -0.04178346320986748,
      0.040588513016700745,
      -0.033489786088466644,
      -0.025837348774075508,
      -0.02511376515030861,
      0.02519417367875576,
      0.012301800772547722,
      0.025135446339845657,
      0.07035708427429199,
      -0.00788569264113903,
      0.03826289251446724,
      -0.03144311159849167,
      0.07747243344783783,
      -0.028486162424087524,
      0.049861881881952286,
      -0.020540321245789528,
      -0.0221705324947834,
      0.02233942225575447,
      0.025179423391819,
      -0.027512602508068085,
      0.031679365783929825,
      -0.07888927310705185,
      -0.0037479803431779146,
      -0.01004110835492611,
      -0.01311403140425682,
      0.03844213858246803,
      -0.05825938656926155,
      -0.02320890501141548,
      -0.03625369444489479,
      0.029645219445228577,
      0.016207169741392136,
      -0.031486138701438904,
      -0.041053406894207,
      -0.0174067635089159,
      0.05952899530529976,
      0.03115309774875641,
      0.04495665803551674,
      0.010292792692780495,
      0.031356438994407654,
      -0.0326320044696331,
      0.020729877054691315,
      0.044246282428503036,
      -0.0016994514735415578,
      -0.001688538584858179,
      -0.003783654887229204,
      -0.005781224928796291,
      -0.04711201786994934,
      0.06052698194980621,
      0.04993273317813873,
      -0.02631147764623165,
      0.040613602846860886,
      -0.03636652231216431,
      0.024980559945106506,
      0.012879777699708939,
      -0.04115363582968712,
      0.0295249093323946,
      -0.0375702828168869,
      -0.03486492857336998,
      -0.033894483000040054,
      -0.018450219184160233,
      0.03704315423965454,
      -0.010164089500904083,
      0.02954866923391819,
      0.01966746337711811,
      0.0030877285171300173,
      -0.07906654477119446,
      0.04236725717782974,
      0.025290530174970627,
      -0.002543529961258173,
      0.06412786990404129,
      -0.04673619940876961,
      -0.016094867140054703,
      -0.06353647261857986,
      -0.05328238010406494,
      -0.022819602862000465,
      0.007719276938587427,
      -0.013644879683852196,
      -0.07132798433303833,
      -0.016985036432743073,
      0.020714513957500458,
      0.0067197410389781,
      -0.01197639387100935,
      0.008922326378524303,
      0.046311717480421066,
      0.039763353765010834,
      -0.01580245979130268,
      -0.03225107863545418,
      -0.0108493035659194,
      0.11082632094621658,
      0.04715637117624283,
      -0.008733857423067093,
      -0.07440659403800964,
      -0.023800699040293694,
      0.046647071838378906,
      -0.05122349038720131,
      -0.03919480741024017,
      -0.038211461156606674,
      0.03799554333090782,
      -0.04914156347513199,
      0.00014233657566364855,
      -0.057600826025009155,
      0.044454947113990784,
      -0.03839472681283951,
      0.016799982637166977,
      -0.0012396208476275206,
      0.07216931879520416,
      0.1390412151813507,
      -0.025211185216903687,
      -0.07941904664039612,
      0.01757589541375637,
      0.12471828609704971,
      -0.038200438022613525,
      -0.022924020886421204,
      0.013930201530456543,
      -0.00214393506757915,
      -0.03624361380934715,
      0.04596814885735512,
      -0.09780514985322952,
      -0.06671107560396194,
      -0.003673470113426447,
      -0.026796020567417145,
      0.08232434839010239,
      0.039264384657144547,
      0.07821062952280045,
      -0.042324986308813095,
      -0.028146760538220406,
      0.013455781154334545,
      -0.03669614717364311,
      -0.010703873820602894,
      -0.040767017751932144,
      -0.026135943830013275,
      0.040473293513059616,
      0.005321259144693613,
      0.035866592079401016,
      0.027800997719168663,
      -0.05480412766337395,
      0.002954005030915141,
      -0.018464531749486923,
      0.005503655876964331,
      -0.07278004288673401,
      0.010686676017940044,
      -0.01206289790570736,
      -0.03295224905014038,
      -0.04999121278524399,
      -0.012763160280883312,
      -0.003412998281419277,
      0.040655191987752914,
      -0.03804523125290871,
      -0.022715896368026733,
      0.015055871568620205,
      -0.020970847457647324,
      0.036875348538160324,
      0.07238098978996277,
      0.03625372424721718,
      -0.01218777522444725,
      0.021876433864235878,
      -0.01226771343499422,
      -0.010465260595083237,
      -0.0017090500332415104,
      -0.023405814543366432,
      0.0755222961306572,
      0.06252627074718475,
      0.006776072550565004,
      -0.050446655601263046,
      0.015681887045502663,
      0.014259552583098412,
      -0.0525224432349205,
      -0.060240067541599274,
      -0.06273873150348663,
      -0.02788042277097702,
      0.0010406841756775975,
      0.010693948715925217,
      -0.0030395996291190386,
      -0.05088556185364723,
      0.027367817237973213,
      -0.04323072358965874,
      0.06039341166615486,
      -0.05730285122990608,
      0.03912488371133804,
      -0.06758387386798859,
      0.027694201096892357,
      -0.09666898846626282,
      0.024987448006868362,
      -0.1070040687918663,
      -0.022178461775183678,
      0.05297774448990822,
      -0.030453121289610863,
      -0.08126888424158096,
      0.00366627867333591,
      0.019223595038056374,
      0.06709113717079163,
      0.10807032138109207,
      0.011274930089712143,
      0.007577888201922178,
      -0.055189408361911774,
      0.04924700781702995,
      0.012436308898031712,
      0.07692119479179382,
      0.025800909847021103,
      -0.029250124469399452,
      0.05446279048919678,
      0.05314365401864052,
      0.08686745911836624,
      0.0062841507606208324,
      -0.02497507445514202,
      0.004115706775337458,
      -0.018722020089626312,
      -0.034516140818595886,
      -0.02648342214524746,
      0.01253546867519617,
      0.009013518691062927,
      -0.020260494202375412,
      -0.023673657327890396,
      -0.029306061565876007,
      0.06586865335702896,
      -0.032595932483673096,
      0.032275762408971786,
      0.07803183794021606,
      0.03508416935801506,
      0.026104683056473732,
      0.012018891982734203,
      -0.07810859382152557,
      -0.0003228818823117763,
      0.010973935946822166,
      0.09179724007844925,
      0.003512995783239603,
      0.0018623180221766233,
      0.013546315021812916,
      -0.0341164730489254,
      0.009082404896616936,
      -0.025295132771134377,
      -0.006791336927562952,
      -0.029022591188549995,
      0.05947517603635788,
      -0.07056182622909546,
      -1.620809780433774e-05,
      0.016631659120321274,
      0.030009081587195396,
      -0.06642138957977295,
      -0.029588215053081512,
      0.06752099841833115,
      -0.036396242678165436,
      -0.019950369372963905,
      -0.03724709153175354,
      0.033393703401088715,
      -0.00958140566945076,
      0.00013496963947545737,
      -0.021419478580355644,
      0.014672251418232918,
      -0.029031265527009964,
      -0.021777087822556496,
      -0.11313264816999435,
      -0.05183839052915573,
      0.020909985527396202,
      -0.025581207126379013,
      -0.04435316473245621,
      0.02069343999028206,
      -0.06326201558113098,
      0.028076594695448875,
      0.06554505974054337,
      0.016662022098898888,
      0.03421153500676155,
      0.03420715406537056,
      0.05540647730231285,
      -0.032694246619939804,
      -0.01756691373884678,
      -0.017596933990716934,
      0.02914421632885933,
      -0.00979641918092966,
      -0.010401848703622818,
      0.014513003639876842,
      -0.04362891614437103,
      -0.09939176589250565,
      -0.004150430206209421,
      -0.025418056175112724,
      0.03643273562192917,
      0.058807287365198135,
      0.009944953955709934,
      0.02661730721592903,
      0.005272924900054932,
      0.0002645406930241734,
      0.062299735844135284,
      0.03928077965974808,
      -0.004799647722393274,
      -0.030443930998444557,
      0.04147882014513016,
      -0.06046522781252861,
      -0.051531776785850525,
      0.034432195127010345,
      -0.017816832289099693,
      0.0006848996854387224,
      0.006742820143699646,
      -0.0025221446994692087,
      0.020469866693019867,
      0.03562617301940918,
      -0.030145060271024704,
      0.041755326092243195,
      -0.059065863490104675,
      0.12088678032159805,
      -0.012672013603150845,
      0.07738804817199707,
      0.020453959703445435,
      -0.031380463391542435,
      0.00377605389803648,
      -0.02755284495651722,
      -0.05883528292179108,
      -0.0008619102300144732,
      -0.0007282152655534446,
      0.03477347642183304,
      0.07363352924585342,
      0.037350982427597046,
      -0.054893068969249725,
      0.039907004684209824,
      0.014033457264304161,
      -0.03282421827316284,
      -0.0004408166860230267,
      -0.01875486597418785,
      0.004842209629714489,
      -0.019434595480561256,
      -0.0013455698499456048,
      0.03005450963973999,
      -0.005124161019921303,
      -0.07434122264385223,
      0.07675360143184662,
      -0.02138710394501686,
      0.011773220263421535,
      -0.028545081615447998,
      -0.027446148917078972,
      0.0033849761821329594,
      -0.09435848146677017,
      -0.04993748292326927,
      0.014325449243187904,
      -0.055135369300842285,
      -0.04057340323925018,
      0.07518796622753143,
      -0.04236074909567833,
      -0.05529279261827469,
      -0.03231427073478699,
      0.06417160481214523,
      -0.05977245420217514,
      0.018635855987668037,
      0.06616319715976715,
      -0.03757736086845398,
      0.012576431967318058,
      -0.05649328604340553,
      0.07106619328260422,
      0.050295718014240265,
      0.004406418185681105,
      -0.030437367036938667,
      -0.01938459649682045,
      -0.05528327450156212,
      0.017047803848981857,
      0.04620399326086044,
      0.027978068217635155,
      -0.031156593933701515,
      0.0041456688195466995,
      -0.007930679246783257,
      -0.001177255529910326,
      -0.03492337465286255,
      0.015592380426824093,
      -0.0007678255788050592,
      0.0424618199467659,
      0.013698008842766285,
      0.0023080657701939344,
      0.025868980213999748,
      0.027996204793453217,
      -0.010614187456667423,
      0.0015293239848688245,
      -0.04332026466727257,
      -0.07213980704545975,
      0.09410898387432098,
      -0.03426048532128334,
      -0.018619457259774208,
      0.08826196938753128,
      0.07488657534122467,
      0.0144532211124897,
      0.009296130388975143,
      -0.004283089656382799,
      0.019005723297595978,
      -0.013094448484480381,
      0.02707221731543541,
      -0.06886807084083557,
      0.0722065269947052,
      0.09352204203605652,
      -0.01887051947414875,
      0.03627069294452667,
      -0.041833002120256424,
      0.034671850502491,
      0.061763860285282135,
      0.01639382913708687,
      -0.03044890984892845,
      -0.022751394659280777,
      -0.06770648062229156,
      0.013118076138198376,
      0.05974666401743889,
      0.032838448882102966,
      -0.006772221997380257,
      -0.019658181816339493,
      -0.047126710414886475,
      0.04246707260608673,
      0.02740740031003952,
      0.02672017738223076,
      -0.010510151274502277,
      -0.06877343356609344,
      -0.05274344980716705,
      -0.000965596700552851,
      -0.016883106902241707,
      -0.007504099979996681,
      -0.043604906648397446,
      -0.043533723801374435,
      0.025460319593548775,
      0.0027269991114735603,
      -0.020572472363710403,
      -0.00876441691070795,
      -0.05977892875671387,
      0.04230266809463501,
      0.08659208565950394,
      0.037697724997997284,
      0.017358625307679176,
      -0.035246025770902634,
      -0.0013082887744531035,
      0.07093615084886551,
      0.09001796692609787,
      -0.02131037414073944,
      -0.03694377839565277,
      0.05748825892806053,
      -0.016400882974267006,
      0.08446808159351349,
      0.005032922141253948,
      -0.05729687586426735,
      -0.02774583362042904,
      0.020852964371442795,
      0.017637377604842186,
      -0.028894517570734024,
      0.008905312046408653,
      0.05049295723438263,
      -0.033308178186416626,
      -0.027142779901623726,
      -0.06922681629657745,
      -0.009813779965043068,
      -0.06149651110172272,
      -0.027124032378196716,
      0.035616736859083176,
      -0.03879682347178459,
      0.08822356909513474,
      -0.014487097971141338,
      -0.022576650604605675,
      0.0380481593310833,
      -0.0023780835326761007,
      -0.0103189991787076,
      -0.03770061954855919,
      0.018513355404138565,
      0.00972204189747572,
      0.01542413979768753,
      0.03177011385560036,
      -0.05903284624218941,
      0.03695966303348541,
      -0.01681155525147915,
      0.011565095745027065,
      0.02379007451236248,
      0.04899553209543228,
      -0.15484634041786194,
      -0.05634231120347977,
      0.017858827486634254,
      -0.0007052326109260321,
      -0.04337085783481598,
      0.03000897355377674,
      0.07486413419246674,
      -0.0013945901300758123,
      -0.03984825313091278,
      0.006122976541519165,
      -0.020470386371016502,
      0.004717162344604731,
      -0.07823869585990906,
      -0.04917951300740242,
      -0.052352335304021835,
      -0.006959165446460247,
      0.028586847707629204
    ],
    [
      0.005612366832792759,
      -0.008729562163352966,
      -0.002004902809858322,
      -0.018902646377682686,
      -0.004672125447541475,
      0.038283757865428925,
      -0.02061128057539463,
      -0.03879855200648308,
      0.02118213288486004,
      -0.04344663396477699,
      0.06403574347496033,
      0.023546217009425163,
      -0.03834111616015434,
      -0.025481346994638443,
      -0.051947422325611115,
      0.03389960899949074,
      0.016262389719486237,
      -0.09556847810745239,
      0.018634021282196045,
      0.013593959622085094,
      -0.004389638546854258,
      -0.0018039675196632743,
      0.021169299259781837,
      -0.014508800581097603,
      0.018301282078027725,
      -0.01567153073847294,
      -0.061253469437360764,
      0.09215637296438217,
      0.03177665174007416,
      0.06242545694112778,
      0.039243459701538086,
      -0.01417889166623354,
      -0.0605180449783802,
      0.010036137886345387,
      -0.012055003084242344,
      -0.005841009318828583,
      -0.004834221210330725,
      0.00018974290287587792,
      0.026216264814138412,
      -0.020696116611361504,
      0.06155413016676903,
      -0.024797551333904266,
      -0.03714311495423317,
      -0.024431785568594933,
      -0.049603171646595,
      -0.004114200826734304,
      -0.009918800555169582,
      -0.06784038245677948,
      -0.02596108429133892,
      0.02952996827661991,
      0.003210362745448947,
      -0.02428954839706421,
      0.012416595593094826,
      0.0650898665189743,
      -0.060998838394880295,
      0.05011944845318794,
      -0.006311825942248106,
      -0.036220483481884,
      -0.023415978997945786,
      -0.007627864368259907,
      -0.06592466682195663,
      -0.022860275581479073,
      -0.025419345125555992,
      0.04341072961688042,
      0.03630399331450462,
      0.008131918497383595,
      -0.00882330909371376,
      -0.011485230177640915,
      0.04193665459752083,
      0.03161320462822914,
      -0.060452938079833984,
      -0.020312190055847168,
      0.028294313699007034,
      -0.021111849695444107,
      -0.0012349182507023215,
      -0.017393717542290688,
      0.045269157737493515,
      -0.02368181385099888,
      0.032116129994392395,
      0.035331327468156815,
      -0.004100385587662458,
      -0.016648901626467705,
      0.01381448470056057,
      -0.06924760341644287,
      -0.00865555927157402,
      0.054931484162807465,
      -0.034341201186180115,
      0.02470342069864273,
      0.05111417546868324,
      0.03692297264933586,
      0.021107833832502365,
      0.03268442675471306,
      0.009336462244391441,
      -0.0036054449155926704,
      -0.05054469779133797,
      0.00886890385299921,
      0.046698857098817825,
      -0.030599309131503105,
      -0.009254880249500275,
      0.012180007062852383,
      0.006488192360848188,
      -0.061658963561058044,
      -0.01941053569316864,
      -0.10398662090301514,
      0.006409073248505592,
      -0.09749812632799149,
      0.020793253555893898,
      -0.00729138171300292,
      -0.007912799715995789,
      -0.00029478149372152984,
      -0.04586150869727135,
      0.030294721946120262,
      -0.0020607009064406157,
      0.016725365072488785,
      -0.03416971117258072,
      -0.09747791290283203,
      -0.007604621816426516,
      0.036046743392944336,
      -0.04710875451564789,
      -0.03231804445385933,
      -0.08494439721107483,
      -0.08398909866809845,
      -0.08985917270183563,
      -0.038769956678152084,
      -0.03859160095453262,
      -0.06391610205173492,
      0.008679117076098919,
      -0.00957638118416071,
      -0.027864044532179832,
      0.003579165320843458,
      0.03922316059470177,
      0.048948805779218674,
      -0.03482814505696297,
      0.029180504381656647,
      -0.008383918553590775,
      0.0294752549380064,
      0.04689980298280716,
      0.04960845783352852,
      -0.021060874685645103,
      0.009657643735408783,
      -0.009592525660991669,
      -0.02649528719484806,
      -0.014348660595715046,
      -0.05648395046591759,
      -0.10978323966264725,
      -0.005858919117599726,
      -0.0764269083738327,
      0.005376357585191727,
      0.09620708972215652,
      -0.0690414160490036,
      0.07988698035478592,
      -0.008904170244932175,
      0.0012978236190974712,
      0.027718666940927505,
      -0.005737368483096361,
      0.016620730981230736,
      -0.007597459480166435,
      -0.0027019495610147715,
      -0.09027235209941864,
      -0.021430151537060738,
      0.01799149066209793,
      0.006752023473381996,
      0.057668279856443405,
      -0.025630103424191475,
      -0.004918810445815325,
      -0.03975125402212143,
      0.027492210268974304,
      0.030657976865768433,
      0.09422849863767624,
      0.028222644701600075,
      0.010562676936388016,
      0.019352661445736885,
      -0.0470915362238884,
      -0.04020467773079872,
      0.08369844406843185,
      0.06506264209747314,
      0.10786829888820648,
      0.05405664071440697,
      -0.014143619686365128,
      0.03141248971223831,
      -0.04997093230485916,
      -0.05448406934738159,
      -0.021343115717172623,
      0.01807195134460926,
      0.031524501740932465,
      -0.03060835786163807,
      -0.04391913861036301,
      -0.07961973547935486,
      0.02184073068201542,
      0.03925754129886627,
      -0.034870799630880356,
      -0.00609293719753623,
      0.051515668630599976,
      0.0006293727201409638,
      -0.09031997621059418,
      0.04370242357254028,
      -0.029380053281784058,
      0.0033650100231170654,
      -0.020987290889024734,
      -0.012946579605340958,
      0.05754593387246132,
      -0.04482593014836311,
      0.03745739534497261,
      0.03896821662783623,
      0.02310262992978096,
      -0.088739775121212,
      -0.08801693469285965,
      -0.0001645875017857179,
      -0.041549380868673325,
      -0.006596364546567202,
      0.035395897924900055,
      0.015049371868371964,
      0.05453962832689285,
      0.03913620114326477,
      -0.0014632075326517224,
      0.005023261997848749,
      0.01940261572599411,
      0.03943127766251564,
      0.0029382037464529276,
      -0.022349193692207336,
      0.08477341383695602,
      0.038141846656799316,
      -0.03204492852091789,
      0.03766307607293129,
      -0.060945432633161545,
      0.0310842152684927,
      -0.06507366895675659,
      0.0234823115170002,
      0.05086435750126839,
      0.05029278248548508,
      -0.007186005357652903,
      -0.0368330217897892,
      0.00619704183191061,
      0.04200342297554016,
      -0.029297061264514923,
      -0.009610572829842567,
      -0.046908438205718994,
      0.003990380093455315,
      0.01567806489765644,
      0.011655425652861595,
      0.05161229893565178,
      0.017598172649741173,
      0.02217991277575493,
      -0.024289725348353386,
      -0.029556643217802048,
      0.004300813656300306,
      -0.026893099769949913,
      0.034001030027866364,
      -0.030957739800214767,
      0.014558784663677216,
      0.0370217002928257,
      -0.016552243381738663,
      0.054789744317531586,
      0.022392475977540016,
      -0.036259230226278305,
      0.05809541046619415,
      -0.1304319053888321,
      0.057335469871759415,
      -0.010661652311682701,
      -0.045304615050554276,
      -0.02777213230729103,
      0.008701820857822895,
      -0.10145638883113861,
      -0.04481495916843414,
      0.018982648849487305,
      -0.10151902586221695,
      0.03280727192759514,
      -0.062240563333034515,
      0.009864256717264652,
      -0.05306857451796532,
      -0.030669070780277252,
      -0.0038033942691981792,
      -0.07520672678947449,
      0.03221464157104492,
      -0.021493075415492058,
      -0.04558974876999855,
      -0.00467304652556777,
      -0.02668546885251999,
      -0.02448107674717903,
      -0.00015046814223751426,
      0.0790393128991127,
      0.0007805917994119227,
      -0.017025312408804893,
      0.06970712542533875,
      0.023891590535640717,
      -0.05265658721327782,
      -0.0002863445261027664,
      0.016794178634881973,
      -0.09997459501028061,
      -0.028808478266000748,
      -0.001108247204683721,
      0.05892994999885559,
      0.011458373628556728,
      -0.08552420139312744,
      0.019626636058092117,
      0.03627540543675423,
      -0.006917696446180344,
      0.029242977499961853,
      0.06420880556106567,
      0.03286624327301979,
      -0.05887150764465332,
      -0.004911311902105808,
      -0.019508039578795433,
      -0.05929199978709221,
      -0.05421551316976547,
      0.02477250061929226,
      0.03141622617840767,
      -0.005099819041788578,
      -0.0633849948644638,
      -0.09813869744539261,
      -0.004437679890543222,
      0.0376933217048645,
      -0.027363769710063934,
      -0.009442878887057304,
      0.03810013458132744,
      0.03340786695480347,
      -0.0844406932592392,
      0.009838099591434002,
      0.01874294877052307,
      -0.029921768233180046,
      -0.00913537759333849,
      -0.0041525475680828094,
      -0.03469962254166603,
      0.0858866348862648,
      -0.07855583727359772,
      -0.030290426686406136,
      0.024304701015353203,
      0.0070511335507035255,
      0.050775714218616486,
      -0.003772525815293193,
      -0.03557455167174339,
      0.020546458661556244,
      0.058048706501722336,
      0.018964989110827446,
      -0.0330096073448658,
      -0.06334760040044785,
      -0.02770882099866867,
      -0.056440386921167374,
      0.003523718100041151,
      -0.028412481769919395,
      -0.012557861395180225,
      0.0716095119714737,
      -0.07394228875637054,
      -0.0047258432023227215,
      -0.0040419078432023525,
      -0.010209767147898674,
      -0.008179171942174435,
      0.014517459087073803,
      -0.004920581821352243,
      0.0035420653875917196,
      0.007437621243298054,
      0.014892863109707832,
      0.033281344920396805,
      0.08986622840166092,
      -0.032939519733190536,
      0.02129117213189602,
      0.06655976921319962,
      -0.032061755657196045,
      0.05129733681678772,
      -0.010237868875265121,
      0.02437497302889824,
      0.048294227570295334,
      0.024454893544316292,
      -0.04200923815369606,
      -0.04102937877178192,
      0.02797381393611431,
      0.02296760305762291,
      0.033539608120918274,
      0.007813399657607079,
      0.007460988592356443,
      -0.03297309949994087,
      -0.08775663375854492,
      0.1449652761220932,
      0.07265910506248474,
      -0.017650634050369263,
      -0.02452757954597473,
      0.053240783512592316,
      -0.008840062655508518,
      0.05049929767847061,
      0.011955340392887592,
      0.058596882969141006,
      0.011049485765397549,
      0.009373052045702934,
      0.04382677003741264,
      -0.07972782105207443,
      -0.0296328142285347,
      0.007610234897583723,
      0.026551242917776108,
      0.04455075040459633,
      0.0450211763381958,
      0.022966522723436356,
      -0.06660223007202148,
      0.0670107752084732,
      -0.02229578234255314,
      -0.03421563655138016,
      -0.04919520020484924,
      0.04382120445370674,
      -0.006294443272054195,
      0.01751415617763996,
      0.01446964405477047,
      -0.033239491283893585,
      0.0078614242374897,
      0.011930946260690689,
      -0.10634059458971024,
      0.0007285022875294089,
      -0.06145952641963959,
      -0.010123306885361671,
      -0.07570931315422058,
      0.04674868285655975,
      -0.022186733782291412,
      -0.005807582754641771,
      -0.021420277655124664,
      0.0908007025718689,
      -0.08665169030427933,
      0.060320936143398285,
      -0.02281736396253109,
      -0.021454375237226486,
      0.008528205566108227,
      -0.017312433570623398,
      0.0778181329369545,
      0.01654643751680851,
      -0.01305103488266468,
      0.06397263705730438,
      -0.03534587100148201,
      -0.029611745849251747,
      0.07103800773620605,
      0.02643992006778717,
      0.07516869157552719,
      0.03780030086636543,
      -0.08958370238542557,
      0.01628229022026062,
      0.025148069486021996,
      0.022553853690624237,
      -0.057381678372621536,
      0.04618890956044197,
      -0.013657291419804096,
      -0.06165889650583267,
      -0.0006754118949174881,
      0.01820988766849041,
      -0.006120048929005861,
      0.017461715266108513,
      0.009385887533426285,
      0.022301938384771347,
      0.031887710094451904,
      -0.07981862872838974,
      0.03726821020245552,
      -0.030822811648249626,
      0.061214983463287354,
      -0.02105707675218582,
      0.016077600419521332,
      0.033666837960481644,
      -0.00019234752107877284,
      0.09297782182693481,
      0.02796131744980812,
      -0.00628390908241272,
      -0.030931061133742332,
      -0.04789377376437187,
      -0.00020959744870197028,
      0.036770861595869064,
      -0.003908184822648764,
      0.049496617168188095,
      -0.03640520200133324,
      -0.054057132452726364,
      -0.012972889468073845,
      -0.03918920084834099,
      0.023118408396840096,
      0.027517471462488174,
      0.0077628446742892265,
      -0.00878846924751997,
      0.07242846488952637,
      -0.05606648325920105,
      0.07416205108165741,
      0.06879112869501114,
      -0.07801684737205505,
      -0.008939594961702824,
      0.03652691841125488,
      0.015257351100444794,
      0.010531983338296413,
      -0.050530679523944855,
      0.10603373497724533,
      -0.037600643932819366,
      -0.02783956378698349,
      0.019463451579213142,
      0.009965594857931137,
      -0.001988643314689398,
      -0.02862575836479664,
      -0.048019617795944214,
      0.049161430448293686,
      0.06464725732803345,
      0.04515760391950607,
      -0.032674409449100494,
      -0.005777069367468357,
      -0.024590615183115005,
      -0.11660154163837433,
      -0.002907954854890704,
      0.06907451152801514,
      0.011554340831935406,
      0.010163713246583939,
      -0.023965653032064438,
      -0.021390164270997047,
      0.006999825593084097,
      -0.05370078235864639,
      -0.01264431793242693
    ],
    [
      0.011436356231570244,
      -0.014250461012125015,
      0.024350561201572418,
      -0.030371246859431267,
      0.06039119139313698,
      -0.045742474496364594,
      0.012927532196044922,
      -0.0013126324629411101,
      -0.030792858451604843,
      -0.061886731535196304,
      -0.0028632739558815956,
      -0.05027082562446594,
      -0.00782782956957817,
      0.04265127331018448,
      -0.043356429785490036,
      0.02454824186861515,
      0.05637442320585251,
      -0.017884600907564163,
      -0.017132773995399475,
      -0.09159380197525024,
      0.0160707775503397,
      0.07898623496294022,
      -0.04408472403883934,
      0.014485259540379047,
      -0.04091763123869896,
      -0.0043264287523925304,
      -0.056061629205942154,
      -0.005031107924878597,
      0.08183211833238602,
      0.040175966918468475,
      0.036191679537296295,
      0.029491480439901352,
      -0.03674350678920746,
      0.03252954035997391,
      0.029360104352235794,
      0.03062787652015686,
      0.030423898249864578,
      0.001931697828695178,
      0.06708522886037827,
      -0.019742870703339577,
      -0.01841810718178749,
      -0.026959598064422607,
      0.016635408625006676,
      -0.09053685516119003,
      -0.03853176534175873,
      0.026149606332182884,
      -0.01744772493839264,
      -0.04285222291946411,
      0.02602406032383442,
      -0.005820645019412041,
      -0.048904743045568466,
      -0.008412991650402546,
      0.06474959850311279,
      -0.038607172667980194,
      -0.1498858779668808,
      -0.030123667791485786,
      -0.024962887167930603,
      0.01813764125108719,
      0.04582098871469498,
      -0.09111004322767258,
      0.003474457422271371,
      -0.030951056629419327,
      0.019005468115210533,
      -0.03752932697534561,
      0.0085477065294981,
      0.00973838847130537,
      0.06831918656826019,
      -0.004902662243694067,
      0.05854509770870209,
      0.07354475557804108,
      0.040682803839445114,
      0.016029074788093567,
      -0.04630325362086296,
      -0.03638093173503876,
      0.022531967610120773,
      -0.014709575101733208,
      -0.0714990422129631,
      0.015037983655929565,
      0.07228010892868042,
      -0.05403224378824234,
      0.12794601917266846,
      -0.024242328479886055,
      0.023080043494701385,
      -0.004168480169028044,
      0.05219010263681412,
      -0.05077710375189781,
      0.016484560444951057,
      0.01667962223291397,
      0.023739036172628403,
      -0.008401909843087196,
      0.02399965189397335,
      0.0691525787115097,
      0.014101522974669933,
      0.017469681799411774,
      0.0683278888463974,
      -0.021318862214684486,
      -0.029967496171593666,
      0.03586814925074577,
      0.05474140867590904,
      -0.007696037646383047,
      0.016232777386903763,
      0.03714298829436302,
      -0.02347513660788536,
      -0.06462456285953522,
      -0.05584278702735901,
      -0.08761735260486603,
      -0.0013776521664112806,
      -0.04596833139657974,
      0.057525429874658585,
      -0.05487080290913582,
      -0.01904415152966976,
      0.016983767971396446,
      0.04285050556063652,
      0.03381700441241264,
      -0.09765610098838806,
      -0.008316999301314354,
      -0.06463172286748886,
      -0.024732349440455437,
      -0.0769096314907074,
      -0.03901813551783562,
      -0.01793009229004383,
      0.05510778725147247,
      0.008202914148569107,
      -0.028477584943175316,
      0.007643985562026501,
      -0.01681622490286827,
      -0.08967235684394836,
      0.026913657784461975,
      0.03110181912779808,
      0.030720243230462074,
      0.00896420981734991,
      -0.051119156181812286,
      0.056113749742507935,
      0.027986891567707062,
      0.0037119959015399218,
      -0.01868191920220852,
      -0.011921381577849388,
      -0.05778878927230835,
      0.043876469135284424,
      -0.0549662709236145,
      0.045600514858961105,
      -0.00046906701754778624,
      -0.03310875967144966,
      0.0029497414361685514,
      -0.07011769711971283,
      0.029530033469200134,
      0.00815176498144865,
      -0.010016479529440403,
      -0.00949028879404068,
      0.030994530767202377,
      0.06168774887919426,
      -0.07035327702760696,
      -0.033895377069711685,
      0.02343767322599888,
      0.0041361115872859955,
      -0.016514796763658524,
      -0.08152487874031067,
      0.02324548363685608,
      -0.056292179971933365,
      0.06013286113739014,
      0.06039217486977577,
      0.03704375773668289,
      0.0030388699378818274,
      -0.001622573472559452,
      -0.03763367980718613,
      -0.03554544597864151,
      -0.0070861754938960075,
      0.019627735018730164,
      0.030693911015987396,
      0.030726827681064606,
      -0.017818700522184372,
      -0.03872954100370407,
      0.07301441580057144,
      -0.007089593447744846,
      0.00281132897362113,
      0.036806829273700714,
      -0.031165337190032005,
      -0.04413672164082527,
      -0.060311105102300644,
      0.0049317143857479095,
      -0.055706657469272614,
      0.0018653672887012362,
      -0.020766565576195717,
      -0.018368443474173546,
      -0.036091938614845276,
      0.02083388902246952,
      -0.005058849696069956,
      -0.0016247438034042716,
      -0.002000645035877824,
      0.016714533790946007,
      0.030707906931638718,
      0.015241581946611404,
      -0.07897188514471054,
      0.0451815165579319,
      -0.005532120354473591,
      0.02808438427746296,
      -0.04703029617667198,
      -0.07099656760692596,
      -0.013334613293409348,
      -0.012868396937847137,
      0.020822562277317047,
      -0.007915511727333069,
      -0.009112091735005379,
      -0.035146523267030716,
      0.02228594943881035,
      -0.0792374387383461,
      -0.10676756501197815,
      0.006534384563565254,
      -0.035298921167850494,
      0.027338907122612,
      -0.021142445504665375,
      0.044639915227890015,
      0.01855510100722313,
      0.01437072642147541,
      0.02986658178269863,
      0.07726946473121643,
      0.050133753567934036,
      0.019667716696858406,
      0.05644053965806961,
      -0.007666243240237236,
      -0.009072021581232548,
      0.06980041414499283,
      -0.09477869421243668,
      -0.024560535326600075,
      0.023647775873541832,
      0.035241130739450455,
      0.08479583263397217,
      0.035901155322790146,
      0.01927795074880123,
      0.0030541850719600916,
      -0.14147768914699554,
      -0.0822364091873169,
      0.0005864686681888998,
      -0.05996083468198776,
      0.00010093534365296364,
      0.0333426259458065,
      -0.013347507454454899,
      0.028765341266989708,
      -0.05383191630244255,
      0.020272105932235718,
      -0.018715139478445053,
      0.05929373577237129,
      -0.10362926870584488,
      -0.003163742134347558,
      -0.047053880989551544,
      0.0032557325903326273,
      -0.007724226918071508,
      0.030132587999105453,
      0.028548380360007286,
      0.017104554921388626,
      0.03352818265557289,
      0.034660935401916504,
      -0.025394169613718987,
      0.049351632595062256,
      -0.03606463596224785,
      -0.038001202046871185,
      0.08944439142942429,
      0.022447247058153152,
      -0.07136601209640503,
      -0.05539829656481743,
      -0.01207305584102869,
      0.024417882785201073,
      0.008386071771383286,
      -0.0008608392090536654,
      0.04867452010512352,
      -0.028371229767799377,
      0.006571347825229168,
      0.024629686027765274,
      -0.07906526327133179,
      -0.010333359241485596,
      -0.05876573920249939,
      -0.058063872158527374,
      0.042459167540073395,
      -0.023824332281947136,
      0.07029968500137329,
      -0.01271911896765232,
      0.0024778353981673717,
      0.0012391872005537152,
      -0.000495393353048712,
      0.018468450754880905,
      -0.07568474858999252,
      -0.038837336003780365,
      0.040651511400938034,
      -0.008742539212107658,
      0.03966512903571129,
      -0.029370833188295364,
      -0.019685763865709305,
      -0.036845456808805466,
      -0.006569694261997938,
      -0.012608308345079422,
      -0.004185190424323082,
      0.04038349539041519,
      -0.06952047348022461,
      0.021462054923176765,
      -0.015113713219761848,
      -0.011792100965976715,
      -0.0031978804618120193,
      0.0015250046271830797,
      -0.06107940897345543,
      0.012284409254789352,
      0.058560051023960114,
      -0.04259699210524559,
      -0.048704080283641815,
      -0.006838531699031591,
      0.014243400655686855,
      -0.04737803712487221,
      -0.019517580047249794,
      0.02757435478270054,
      -0.03693640977144241,
      -0.01751316897571087,
      0.04293441027402878,
      0.02561718225479126,
      -0.058848392218351364,
      0.046704258769750595,
      0.07255927473306656,
      -0.011930224485695362,
      -0.024819063022732735,
      0.01150673907250166,
      0.02700517326593399,
      0.00792626105248928,
      0.02342928573489189,
      -0.08447236567735672,
      -0.04710804298520088,
      0.10459240525960922,
      -0.03363931179046631,
      -0.06228102743625641,
      -0.021127667278051376,
      0.05662239342927933,
      0.05745013430714607,
      -0.02579226717352867,
      -0.08024510741233826,
      -0.009225212037563324,
      -0.0024912082590162754,
      0.05459444969892502,
      -0.006285809446126223,
      0.0185040645301342,
      0.006262559909373522,
      -0.004914553370326757,
      -0.014027249999344349,
      0.03755681589245796,
      0.02745400369167328,
      -0.008456842042505741,
      -0.006425786297768354,
      -0.03858838230371475,
      0.06569665670394897,
      -0.035269249230623245,
      0.053039420396089554,
      -0.012082484550774097,
      -0.020945420488715172,
      0.037431009113788605,
      0.010563651099801064,
      0.02822720818221569,
      -0.03222471848130226,
      0.0022771572694182396,
      -0.039438311010599136,
      -0.01733648031949997,
      -0.02374277450144291,
      -0.019259680062532425,
      0.029492966830730438,
      -0.03386499732732773,
      0.10474912077188492,
      -0.0521390400826931,
      -0.03303141891956329,
      0.011968097649514675,
      0.06337607651948929,
      0.048491742461919785,
      -0.013811780139803886,
      -0.06630382686853409,
      0.03149295225739479,
      -0.030512366443872452,
      0.009742571972310543,
      0.00786953791975975,
      -0.0168903898447752,
      -0.019416077062487602,
      0.019860457628965378,
      -0.012906154617667198,
      -0.022868309170007706,
      0.038283467292785645,
      0.044496018439531326,
      0.02240295335650444,
      -0.009426053613424301,
      0.0352218933403492,
      -0.020229697227478027,
      -0.0478966049849987,
      0.024026621133089066,
      -0.05711839348077774,
      0.03182365000247955,
      -0.026401007547974586,
      0.0253069419413805,
      -0.012927527539432049,
      0.024211034178733826,
      0.04506192356348038,
      0.07057647407054901,
      -0.031670521944761276,
      0.020726995542645454,
      0.07172759622335434,
      -0.0249013751745224,
      -0.11554114520549774,
      0.05359375849366188,
      0.05015556141734123,
      -0.008925051428377628,
      -0.008658376522362232,
      -0.03440195322036743,
      -0.03616635873913765,
      -0.017183292657136917,
      -0.053653694689273834,
      0.06537038087844849,
      -0.015391040593385696,
      0.07515494525432587,
      -0.0005728917894884944,
      0.06854549795389175,
      0.04081989824771881,
      -0.05927678197622299,
      -0.008011948317289352,
      0.0019040824845433235,
      0.02504832111299038,
      0.09163540601730347,
      -0.05590810626745224,
      -0.018368210643529892,
      0.033330611884593964,
      -0.007378708105534315,
      -0.008131963200867176,
      0.07655448466539383,
      0.08426465839147568,
      0.028495406731963158,
      -0.048547595739364624,
      0.07189055532217026,
      0.07958430796861649,
      -0.01956333965063095,
      -0.03813309222459793,
      -0.0628880187869072,
      -0.011000379920005798,
      -0.04327961429953575,
      -0.005387190729379654,
      -0.05357611924409866,
      -0.08000610768795013,
      0.03335665538907051,
      0.01147752907127142,
      0.03362050652503967,
      -0.011355285532772541,
      -0.01582656428217888,
      0.03220466151833534,
      0.03218803554773331,
      0.07827813178300858,
      0.03580811247229576,
      0.038974009454250336,
      0.001657395507209003,
      0.018861262127757072,
      0.02017706260085106,
      0.015661519020795822,
      -0.02594374492764473,
      0.026742562651634216,
      0.0626014769077301,
      0.06023877114057541,
      0.045019350945949554,
      0.019231470301747322,
      0.049606673419475555,
      0.032222893089056015,
      0.01742521859705448,
      0.01613384298980236,
      -0.09976343810558319,
      -0.0897219330072403,
      -0.006192934233695269,
      0.060564104467630386,
      -0.012045415118336678,
      0.0983479917049408,
      0.005145473405718803,
      0.10280831158161163,
      0.0037329737097024918,
      -0.040073808282613754,
      0.07706461101770401,
      0.053629569709300995,
      0.014089501462876797,
      -0.013155285269021988,
      0.046605467796325684,
      0.04933059588074684,
      -0.021882543340325356,
      0.06094483658671379,
      -0.024622255936264992,
      -0.021950500085949898,
      0.017428820952773094,
      -0.03907455876469612,
      0.049595680087804794,
      -0.08485029637813568,
      0.005377872847020626,
      -0.13544341921806335,
      -0.04668566212058067,
      -0.023216603323817253,
      0.04708756133913994,
      -0.0007077881600707769,
      0.07071084529161453,
      0.052681513130664825,
      0.035889819264411926,
      -0.04073818027973175,
      -0.02364216558635235,
      0.06290686875581741,
      0.05017801746726036,
      -0.01478849072009325,
      -0.1341772824525833,
      0.052081409841775894,
      -0.06115931645035744,
      0.03725973144173622,
      0.05284174531698227
    ],
    [
      0.009320670738816261,
      0.0116792693734169,
      0.020672762766480446,
      -0.06385954469442368,
      -0.02598099783062935,
      0.13449087738990784,
      -0.025941984727978706,
      -0.07015878707170486,
      0.008144089952111244,
      -0.05135812237858772,
      0.02921682596206665,
      -0.07391059398651123,
      0.0019546279218047857,
      -0.057805005460977554,
      -0.004477717913687229,
      -0.01828325353562832,
      0.064204141497612,
      0.00924209039658308,
      0.005504289176315069,
      0.04048025980591774,
      -0.033583689481019974,
      0.02456156723201275,
      -0.05392250046133995,
      0.03680189698934555,
      -0.024497607722878456,
      -0.028609616681933403,
      -0.03807646781206131,
      0.04101036489009857,
      0.03593267872929573,
      -0.05852895230054855,
      -0.009593158960342407,
      0.0019116968614980578,
      0.026373086497187614,
      -0.04534516856074333,
      0.034364666789770126,
      -0.03651735559105873,
      -0.04980742186307907,
      0.05188228189945221,
      -0.0026163996662944555,
      -0.06612422317266464,
      -0.10026815533638,
      0.0508766770362854,
      -0.06004151701927185,
      0.022876959294080734,
      -0.09945161640644073,
      -0.03385224938392639,
      -0.01812218874692917,
      0.037551652640104294,
      -0.04016263410449028,
      0.054195940494537354,
      -0.09456957131624222,
      -0.0029600546695291996,
      0.035945773124694824,
      0.025287091732025146,
      0.007068737875670195,
      0.014588664285838604,
      -0.016180772334337234,
      -0.061427053064107895,
      -0.04804117977619171,
      0.07727622985839844,
      0.01622665673494339,
      -0.048161160200834274,
      0.04012373089790344,
      -0.05708780139684677,
      -0.06212247535586357,
      -0.08192247897386551,
      0.034394219517707825,
      -0.11552578955888748,
      -0.002407567575573921,
      -0.004089154303073883,
      0.051221560686826706,
      0.006515346001833677,
      -0.017700325697660446,
      0.02061569318175316,
      -0.026819229125976562,
      -0.008283310569822788,
      0.0035954725462943316,
      0.06880250573158264,
      0.007215834688395262,
      -0.02642107382416725,
      0.05589935928583145,
      0.056224796921014786,
      0.01603596657514572,
      -0.043332986533641815,
      0.03536774218082428,
      -0.0279525239020586,
      0.0071181124076247215,
      -0.012916674837470055,
      -0.009493238292634487,
      -0.01732880249619484,
      0.07929962873458862,
      -0.05714621767401695,
      0.04126296937465668,
      0.033951472491025925,
      0.044783979654312134,
      0.024164462462067604,
      0.08785724639892578,
      -0.019796917214989662,
      -0.03647172451019287,
      -0.007844556123018265,
      -0.010555396787822247,
      0.031521886587142944,
      0.04571620002388954,
      -0.08512335270643234,
      0.08924136310815811,
      0.014337752014398575,
      -0.0411221943795681,
      0.08604010939598083,
      0.06486181169748306,
      -0.014477620832622051,
      -0.03563205152750015,
      0.015553527511656284,
      -0.03409911319613457,
      0.012906927615404129,
      0.06007623299956322,
      -0.08786274492740631,
      -0.0689629465341568,
      -0.009523967280983925,
      0.01837291195988655,
      0.1140981987118721,
      -0.016944605857133865,
      -0.062024328857660294,
      -0.0037129775155335665,
      0.050984688103199005,
      -0.07790277898311615,
      -0.010271375998854637,
      -0.09500992298126221,
      0.05936899408698082,
      0.026105787605047226,
      -0.005896739661693573,
      0.004870271775871515,
      0.03026588261127472,
      -0.04045012593269348,
      -0.021692577749490738,
      0.03657441586256027,
      0.05616232752799988,
      0.029047278687357903,
      -0.028911761939525604,
      0.09224720299243927,
      0.034757401794195175,
      -0.05903931334614754,
      -0.00799845065921545,
      -0.050230782479047775,
      -0.013833834789693356,
      -0.0016395461279898882,
      0.0038365337532013655,
      0.020210418850183487,
      -0.05706947669386864,
      -0.016074741259217262,
      0.09889595210552216,
      0.08633771538734436,
      -0.111260324716568,
      -0.017219476401805878,
      0.04145065322518349,
      -0.024629592895507812,
      0.024334942921996117,
      0.04064773768186569,
      0.046851277351379395,
      -0.009850210510194302,
      -0.059415124356746674,
      -0.018706556409597397,
      -0.04878898710012436,
      -0.0007286053150892258,
      0.026008157059550285,
      0.10473012179136276,
      0.025463692843914032,
      0.04591120779514313,
      -0.038136571645736694,
      -0.018265046179294586,
      -0.007140431087464094,
      0.020979024469852448,
      0.03877456113696098,
      -0.007539822719991207,
      0.015112431719899178,
      0.03286152705550194,
      0.07028761506080627,
      -0.09527019411325455,
      -0.02707301266491413,
      -0.01734616421163082,
      0.0952533408999443,
      -0.07687148451805115,
      -0.06304405629634857,
      -0.016397522762417793,
      0.024721233174204826,
      -0.0004111739981453866,
      0.059590741991996765,
      -0.06900405138731003,
      -0.01071613933891058,
      -0.06755787134170532,
      0.0024973510298877954,
      0.029708469286561012,
      0.03183877095580101,
      0.0006035168189555407,
      -0.03147600218653679,
      0.0032822357024997473,
      -0.004362183623015881,
      -0.010181078687310219,
      0.010630978271365166,
      0.02616109326481819,
      0.010612206533551216,
      -0.07893180847167969,
      -0.09049947559833527,
      0.08276260644197464,
      0.007281571626663208,
      0.021604476496577263,
      0.012841055169701576,
      -0.12341666966676712,
      0.009929624386131763,
      -0.009110568091273308,
      0.06183219328522682,
      0.09198868274688721,
      -0.00788907241076231,
      -0.03477352485060692,
      -0.0952310711145401,
      0.0037452576216310263,
      -0.04139361158013344,
      -0.01612985134124756,
      -0.05062270164489746,
      -0.028241198509931564,
      -0.015480845235288143,
      0.07038851827383041,
      0.007953980006277561,
      0.0015455832472071052,
      0.05034779757261276,
      0.029726224020123482,
      0.020433776080608368,
      -0.006409016903489828,
      0.02490423433482647,
      0.05803835019469261,
      0.0802420824766159,
      -0.029563123360276222,
      0.06542225927114487,
      -0.054859112948179245,
      -0.0947759672999382,
      -0.06867986917495728,
      -0.003457819577306509,
      0.05631299689412117,
      -0.01008392870426178,
      0.03762679547071457,
      -0.04000106826424599,
      -0.07010866701602936,
      0.00449535483494401,
      -0.004510068334639072,
      0.010588057339191437,
      -0.01287904754281044,
      -0.018686873838305473,
      -0.038311686366796494,
      0.007336353417485952,
      -0.026100635528564453,
      0.0249964389950037,
      0.031891193240880966,
      0.0843871608376503,
      0.04333975538611412,
      -0.04149554669857025,
      -0.0265638567507267,
      -0.07911902666091919,
      0.035486117005348206,
      0.023756468668580055,
      0.027594421058893204,
      0.008751694113016129,
      -0.003283255035057664,
      -0.024219749495387077,
      -0.03824520856142044,
      -0.021456211805343628,
      0.017628388479351997,
      0.1252509206533432,
      -0.03313570097088814,
      0.01865019090473652,
      0.018958916887640953,
      -0.016085755079984665,
      0.04713664948940277,
      0.017343033105134964,
      -0.02953275479376316,
      -0.001916983863338828,
      0.06418954581022263,
      -0.03925046697258949,
      -0.09221980720758438,
      -0.0700172409415245,
      -0.00891079567372799,
      -0.03147270902991295,
      0.01574820466339588,
      0.05457919090986252,
      -0.06263316422700882,
      0.042296599596738815,
      -0.016308758407831192,
      -0.02637423202395439,
      -0.03815462812781334,
      -0.027462510392069817,
      -0.06257474422454834,
      0.00738971633836627,
      0.0366404689848423,
      0.01469411887228489,
      0.03904622420668602,
      -0.016337059438228607,
      0.016998831182718277,
      0.038326989859342575,
      0.048685137182474136,
      -0.03705914691090584,
      -0.01151621900498867,
      -0.04655409976840019,
      0.07577230036258698,
      -0.002805874915793538,
      0.03153142333030701,
      0.09717117249965668,
      -0.0007007340900599957,
      -0.0014627338387072086,
      -0.016243716701865196,
      0.013746370561420918,
      -0.07589524984359741,
      -0.017513949424028397,
      -0.012944238260388374,
      -0.09540850669145584,
      0.0790972039103508,
      -0.07827301323413849,
      0.020342789590358734,
      -0.038095928728580475,
      0.016319237649440765,
      0.05461886525154114,
      -0.03818989917635918,
      0.0001336939021712169,
      -0.012241506017744541,
      0.06766930222511292,
      0.023049132898449898,
      -0.029619991779327393,
      0.032766688615083694,
      -0.06252429634332657,
      -0.0010409305104985833,
      -0.06926433742046356,
      0.012069232761859894,
      0.07053984701633453,
      -0.03582645580172539,
      -0.06471135467290878,
      0.03259679675102234,
      0.024104727432131767,
      0.05230982229113579,
      0.039811599999666214,
      0.015297415666282177,
      -0.02817087434232235,
      -0.042991045862436295,
      -0.04494890570640564,
      0.011079272255301476,
      0.010554436594247818,
      -0.03839893639087677,
      -0.04759519547224045,
      0.00040029711090028286,
      -0.031403154134750366,
      0.06333319842815399,
      -0.0022505896631628275,
      0.029953641816973686,
      0.03241788223385811,
      -0.013831284828484058,
      -0.040172040462493896,
      0.03230397775769234,
      -0.08580182492733002,
      -0.038662102073431015,
      -0.06905612349510193,
      -0.030611824244260788,
      0.010987486690282822,
      0.0022367448545992374,
      0.043121811002492905,
      0.005276340059936047,
      0.0576695054769516,
      0.007749945390969515,
      0.004758929833769798,
      -0.002821617294102907,
      -0.03294966369867325,
      0.024020349606871605,
      -0.01626567356288433,
      -0.0026192364748567343,
      -0.007752223871648312,
      0.019981976598501205,
      0.005688091274350882,
      0.08592302352190018,
      0.05037667974829674,
      -0.002058314858004451,
      -0.018949825316667557,
      0.05075275897979736,
      -0.013133748434484005,
      0.015200023539364338,
      0.00981349591165781,
      0.014371959492564201,
      0.03260485827922821,
      -0.008823588490486145,
      0.017813611775636673,
      0.08943813294172287,
      -0.040951892733573914,
      0.0041732992976903915,
      0.016333462670445442,
      -0.08087294548749924,
      0.007838360965251923,
      -0.03783181309700012,
      -0.06840120255947113,
      -0.0003577295574359596,
      0.019203880801796913,
      -0.023312833160161972,
      -0.046719178557395935,
      -0.078293576836586,
      -0.055939339101314545,
      -0.013235582038760185,
      -0.01981525868177414,
      0.046435076743364334,
      -0.054007917642593384,
      0.07838600873947144,
      0.003843993879854679,
      -0.01868678629398346,
      -0.027522524818778038,
      -0.031951554119586945,
      -0.017976468428969383,
      -0.06121629849076271,
      -0.014757484197616577,
      0.06540724635124207,
      0.003919203765690327,
      0.02661736123263836,
      -0.05223339796066284,
      0.011524266563355923,
      0.01641685701906681,
      0.014628411270678043,
      -0.03873281180858612,
      0.0009797720704227686,
      -0.05233941599726677,
      0.0015349325258284807,
      -0.027069183066487312,
      0.011820496991276741,
      -0.0034359623678028584,
      0.038072679191827774,
      0.0807499960064888,
      -0.008680643513798714,
      0.042439352720975876,
      -0.02137245424091816,
      -0.03138688951730728,
      0.05449819937348366,
      -0.0021554925478994846,
      0.02903403900563717,
      0.028788086026906967,
      -0.01691780798137188,
      0.04472655802965164,
      -0.007498910650610924,
      0.042523570358753204,
      0.011381268501281738,
      0.004083350300788879,
      -0.0031757319811731577,
      0.08819562941789627,
      0.0003984938084613532,
      0.07645542174577713,
      -0.037183891981840134,
      0.028113285079598427,
      0.02112158015370369,
      -0.004876674152910709,
      -0.004867298528552055,
      0.059605058282613754,
      -0.062119755893945694,
      -0.13850675523281097,
      0.006261161994189024,
      -0.05899850279092789,
      0.015459051355719566,
      0.04503339156508446,
      -0.01636204868555069,
      0.04712187871336937,
      -0.0048932950012385845,
      0.009072880260646343,
      0.017248578369617462,
      -0.07176822423934937,
      0.03170419856905937,
      0.003301554126664996,
      -0.01657288707792759,
      0.08954201638698578,
      -0.015957172960042953,
      0.04358022287487984,
      -0.005065827630460262,
      -0.02819015644490719,
      0.025632023811340332,
      0.04526520520448685,
      -0.005075576715171337,
      -0.06015481427311897,
      -0.003148899879306555,
      -0.023015277460217476,
      -0.05618961900472641,
      -0.010299788787961006,
      -0.043707553297281265,
      -0.021721823140978813,
      -0.03890521079301834,
      0.03938687592744827,
      0.05691230297088623,
      0.03153730928897858,
      -0.02898368425667286,
      0.0022395760752260685,
      -0.06044868007302284,
      0.03202689439058304,
      0.019992507994174957,
      -0.03647416830062866,
      -0.06562265753746033,
      -0.036331046372652054,
      0.04301357641816139,
      0.005190007854253054,
      0.015140663832426071,
      -0.03961729258298874,
      -0.034401118755340576,
      0.034086260944604874,
      -4.70031845907215e-05,
      0.035086508840322495,
      -0.02743065357208252,
      0.0274420864880085,
      0.012386731803417206
    ],
    [
      0.0578623041510582,
      0.03214168921113014,
      -0.01661127619445324,
      -0.0015648151747882366,
      -0.07557395845651627,
      -0.040899861603975296,
      0.0209616981446743,
      0.0048850770108401775,
      0.04728706553578377,
      0.0070794690400362015,
      -0.07903330028057098,
      -0.06106908619403839,
      -0.005772831849753857,
      0.0008963469881564379,
      0.07787451148033142,
      0.0553322471678257,
      0.024077918380498886,
      0.03525681421160698,
      0.029239991679787636,
      0.045840322971343994,
      0.013628466054797173,
      -0.05903731659054756,
      -0.06566977500915527,
      0.06848083436489105,
      0.009023629128932953,
      0.025983165949583054,
      0.06944926083087921,
      0.06868146359920502,
      -0.0021106088533997536,
      0.016771947965025902,
      -0.056413471698760986,
      0.08522682636976242,
      -0.0043142628856003284,
      -0.033214304596185684,
      -0.04828234389424324,
      0.017260251566767693,
      -0.01777576468884945,
      -0.04354448616504669,
      -0.07298316806554794,
      -0.016677437350153923,
      -0.05149046331644058,
      -0.02183525450527668,
      -0.004167929757386446,
      -0.03284776210784912,
      -0.09711193293333054,
      0.04470875859260559,
      -0.07360127568244934,
      -0.03746483847498894,
      0.05173245444893837,
      0.02351236157119274,
      0.0094566959887743,
      0.030814040452241898,
      -0.0018267964478582144,
      -0.017724065110087395,
      0.006358248647302389,
      0.043083760887384415,
      -0.030002901330590248,
      -0.05114620551466942,
      0.06050556153059006,
      0.027890589088201523,
      -0.05634782835841179,
      -0.029857410117983818,
      -0.06408783793449402,
      0.022745288908481598,
      -0.013188940472900867,
      0.005117615219205618,
      -0.034158434718847275,
      -0.024017196148633957,
      -0.01953418366611004,
      0.053947433829307556,
      0.009632357396185398,
      0.02521594613790512,
      -0.023979566991329193,
      0.016974424943327904,
      0.004093467257916927,
      0.015800360590219498,
      -0.07138099521398544,
      0.048743296414613724,
      -0.0530504547059536,
      -0.045283202081918716,
      0.11428528279066086,
      0.011450784280896187,
      0.08768366277217865,
      0.04744481295347214,
      -0.024240899831056595,
      0.011469644494354725,
      -0.020988453179597855,
      -0.016328485682606697,
      0.026156386360526085,
      -0.00534028047695756,
      0.0033471882343292236,
      -0.026998834684491158,
      0.057101041078567505,
      -0.004114757291972637,
      -0.013165127485990524,
      0.008622143417596817,
      0.015826750546693802,
      0.029876071959733963,
      0.05025583878159523,
      0.0014929736498743296,
      -0.003989873453974724,
      0.02553599886596203,
      -0.04718994349241257,
      -0.00029101682594045997,
      -0.02207154966890812,
      -0.041121069341897964,
      -0.03885287046432495,
      0.024488501250743866,
      0.04650748521089554,
      0.007760009728372097,
      -0.03155726566910744,
      -0.03144890442490578,
      -0.03795825317502022,
      -0.04816883057355881,
      -0.046091530472040176,
      -0.038362324237823486,
      -0.03230432793498039,
      0.10870499908924103,
      0.04924994707107544,
      0.041796665638685226,
      0.032396890223026276,
      -0.019949017092585564,
      0.030536331236362457,
      0.03942970931529999,
      -0.01340247318148613,
      -0.0882551372051239,
      -0.00602957047522068,
      -0.03466061130166054,
      -0.018225805833935738,
      -0.010357778519392014,
      0.021561920642852783,
      0.02242649905383587,
      -0.011355996131896973,
      -0.053125228732824326,
      0.05939110368490219,
      0.09545322507619858,
      -0.0361536368727684,
      -0.05874505639076233,
      -0.04669632390141487,
      -0.09947220981121063,
      0.09778570383787155,
      -0.06449788808822632,
      0.001421303953975439,
      -0.045381538569927216,
      -0.004151430446654558,
      0.03603030741214752,
      -0.13618090748786926,
      -0.06253933906555176,
      -0.04381377622485161,
      -0.04347056522965431,
      0.0337260439991951,
      -0.05700463801622391,
      0.0417599231004715,
      0.03991452977061272,
      -0.0203572828322649,
      -0.056882161647081375,
      0.04671843722462654,
      -0.015804216265678406,
      0.08652190119028091,
      0.04406085982918739,
      -0.012989582493901253,
      -0.05907197296619415,
      0.026313133537769318,
      -0.02104802615940571,
      -0.04927164688706398,
      -0.06517785787582397,
      0.08432844281196594,
      0.04474933072924614,
      0.06979995220899582,
      0.07957398146390915,
      0.05388494208455086,
      0.029264766722917557,
      0.08125763386487961,
      0.014427494257688522,
      -0.005199769511818886,
      -0.039879102259874344,
      -0.05279518663883209,
      -0.0510995127260685,
      -0.05534302443265915,
      0.021140431985259056,
      -0.04916350170969963,
      -0.02600187249481678,
      0.009529807604849339,
      -0.029489727690815926,
      0.08796180039644241,
      -0.06878340989351273,
      0.037212666124105453,
      -0.02957061491906643,
      -0.010684098117053509,
      -0.010824705474078655,
      -0.0668570026755333,
      -0.014721587300300598,
      -0.10614249110221863,
      0.03786967694759369,
      0.07811985909938812,
      -0.03388901427388191,
      -0.09174882620573044,
      -0.058398399502038956,
      0.018083270639181137,
      0.06815396994352341,
      0.09256674349308014,
      0.009267838671803474,
      0.11568651348352432,
      -0.042176805436611176,
      -0.04385744780302048,
      0.007621545810252428,
      -0.016323605552315712,
      0.007936011999845505,
      0.0343300886452198,
      0.002576634753495455,
      -0.03183543682098389,
      0.04037969559431076,
      0.040105048567056656,
      -0.025024672970175743,
      0.0065914057195186615,
      -0.017473936080932617,
      -0.03322601318359375,
      0.0204459298402071,
      -0.06138521060347557,
      0.011617876589298248,
      0.022316494956612587,
      -0.010327351279556751,
      0.009559688158333302,
      0.00933641567826271,
      -0.03151195868849754,
      0.0404939241707325,
      0.03336432948708534,
      0.01980612240731716,
      -0.01689642295241356,
      2.35305506066652e-05,
      -0.033646129071712494,
      0.02873869426548481,
      -0.018297405913472176,
      0.00794328935444355,
      -0.012490028515458107,
      -0.035198599100112915,
      -0.018620824441313744,
      0.06386500597000122,
      0.015653515234589577,
      0.015112917870283127,
      -0.0643354281783104,
      -0.04480147361755371,
      0.08888135850429535,
      0.0013995131012052298,
      -0.029414819553494453,
      0.003111224854364991,
      0.022203944623470306,
      0.02257595583796501,
      0.01706691086292267,
      0.045605625957250595,
      -0.0394342802464962,
      0.003955974243581295,
      0.0020932601764798164,
      -0.0662994235754013,
      -0.0074442545883357525,
      -0.014363973401486874,
      0.07178553193807602,
      -0.030470428988337517,
      -0.01392485573887825,
      -0.06665777415037155,
      -0.012893271632492542,
      0.0522131621837616,
      -0.026020918041467667,
      0.026533233001828194,
      0.03770681843161583,
      -0.004988802131265402,
      0.006221480667591095,
      -0.017169399186968803,
      0.004963675979524851,
      -0.0649947077035904,
      -0.029699472710490227,
      -0.018795112147927284,
      0.054185651242733,
      -0.062044963240623474,
      0.03885326907038689,
      0.015827003866434097,
      -0.02733500301837921,
      0.030297648161649704,
      0.025509733706712723,
      0.026931876316666603,
      0.037966497242450714,
      -0.04298650100827217,
      -0.030916530638933182,
      -0.07277107983827591,
      0.005686330609023571,
      -0.010329165495932102,
      -0.004500921815633774,
      0.0063201431185007095,
      -0.05177776888012886,
      -0.06014129891991615,
      -0.011201580055058002,
      -0.010708525776863098,
      0.012866510078310966,
      -0.04388073831796646,
      0.009168179705739021,
      0.002766003366559744,
      0.07696927338838577,
      -0.024313747882843018,
      -0.009467724710702896,
      -0.02461227960884571,
      0.02573658898472786,
      0.03988654166460037,
      0.016706302762031555,
      0.006665402092039585,
      -0.11425480991601944,
      -0.019509073346853256,
      -0.02828887104988098,
      0.026340549811720848,
      -0.03698038309812546,
      0.07518336176872253,
      0.0022725772578269243,
      0.0317123681306839,
      0.003764451015740633,
      0.0031488025560975075,
      -0.024557780474424362,
      0.11644527316093445,
      0.014708736911416054,
      0.06994415819644928,
      -0.06075236573815346,
      0.0017177367117255926,
      0.07555392384529114,
      0.03605964034795761,
      0.03073859214782715,
      -0.09069938957691193,
      -0.046190712600946426,
      -0.029839500784873962,
      0.017764456570148468,
      -0.059681158512830734,
      0.043943993747234344,
      -0.020238351076841354,
      -0.009501180611550808,
      -0.0015141365583986044,
      -0.008923708461225033,
      0.024841079488396645,
      0.09100005030632019,
      0.018813546746969223,
      -0.019917011260986328,
      0.026211196556687355,
      -0.10579819232225418,
      -0.0333404466509819,
      0.030415168032050133,
      0.01077841967344284,
      -0.07439038157463074,
      0.02618100307881832,
      0.008145323023200035,
      0.0033628386445343494,
      -0.03859669342637062,
      -0.012029835022985935,
      -0.021223606541752815,
      0.029290547594428062,
      -0.039835285395383835,
      -0.010847295634448528,
      -0.0228110384196043,
      0.002097923308610916,
      0.03150778263807297,
      -0.011063210666179657,
      0.009190637618303299,
      -0.044857241213321686,
      0.028618674725294113,
      0.05842287093400955,
      0.037066370248794556,
      0.03595566004514694,
      0.02077721804380417,
      -0.049450069665908813,
      -0.032238107174634933,
      0.0649016946554184,
      -0.027953626587986946,
      -0.03720959275960922,
      -0.03640777990221977,
      -0.035691551864147186,
      0.0001771667302818969,
      -0.01933637447655201,
      -0.08566496521234512,
      0.008109629154205322,
      -0.029720744118094444,
      -0.0012261071242392063,
      -0.01620412804186344,
      0.013095182366669178,
      -0.06847081333398819,
      -0.04983562231063843,
      -0.048440903425216675,
      -0.004411402624100447,
      -0.00407367805019021,
      0.04193224012851715,
      -0.004303959663957357,
      0.007124367170035839,
      0.06461437046527863,
      -0.02650030143558979,
      0.02799086458981037,
      0.043023765087127686,
      -0.00847385823726654,
      -0.04928426817059517,
      0.022671641781926155,
      0.008773893117904663,
      -0.0807284265756607,
      0.008082483895123005,
      -0.004911566618829966,
      -0.02379664219915867,
      0.05471077188849449,
      -0.03610299155116081,
      -0.08206390589475632,
      -0.0199966412037611,
      0.07460679858922958,
      -0.04524119570851326,
      -0.025908317416906357,
      0.02953651361167431,
      0.01560951303690672,
      0.008814331144094467,
      -0.11530403047800064,
      0.015534219332039356,
      -0.030129458755254745,
      0.03739729896187782,
      0.03234115615487099,
      0.039591699838638306,
      -0.0009245396940968931,
      -0.0514727421104908,
      0.0015399790136143565,
      -0.08153312653303146,
      0.036068860441446304,
      0.013243675231933594,
      -0.002166525460779667,
      -0.017980605363845825,
      -0.0429348461329937,
      0.01592455618083477,
      -0.11263696849346161,
      -0.06499084830284119,
      0.0006626679096370935,
      -0.042701356112957,
      -0.08135552704334259,
      -0.005307281855493784,
      -0.015723899006843567,
      0.008164633996784687,
      0.004413851536810398,
      -0.03466114401817322,
      0.023405369371175766,
      -0.024072490632534027,
      -0.029515964910387993,
      -0.038312554359436035,
      -0.004306378308683634,
      0.03237203136086464,
      -0.03354480490088463,
      0.038637224584817886,
      -0.013862431049346924,
      0.04880653694272041,
      -0.04525504261255264,
      0.02664795331656933,
      0.024281423538923264,
      -0.03311171010136604,
      -0.03055482544004917,
      -0.0037613261956721544,
      -0.03784848377108574,
      -0.03834078833460808,
      -0.0017725519137457013,
      0.028807444497942924,
      0.043100081384181976,
      0.014483107253909111,
      0.026802560314536095,
      -0.0370480939745903,
      -0.0030626824591308832,
      0.06228586658835411,
      0.06024052947759628,
      -0.037189122289419174,
      0.03567975014448166,
      -0.07744938880205154,
      -0.01737057976424694,
      0.0015272601740434766,
      0.04299719259142876,
      0.024135790765285492,
      -0.0498642697930336,
      0.024665027856826782,
      -0.011766716837882996,
      -0.03361038491129875,
      -0.10405702143907547,
      0.03409909829497337,
      -0.005284579005092382,
      -0.01690170355141163,
      -0.0342613123357296,
      0.017644569277763367,
      -0.03297031298279762,
      0.017524050548672676,
      0.0868915244936943,
      -0.009753014892339706,
      -0.003776054596528411,
      -8.968859765445814e-05,
      0.023110195994377136,
      -0.031870435923337936,
      -0.07159842550754547,
      0.01024715043604374,
      -0.022535761818289757,
      0.016412818804383278,
      -0.04592524841427803,
      0.042161233723163605,
      -0.006566719617694616,
      -0.00328009482473135,
      0.03763674199581146,
      0.07986549288034439,
      0.021865956485271454,
      0.0472283735871315,
      -0.05465077981352806,
      -0.10974869132041931,
      0.0863855853676796,
      -0.11765628308057785,
      -0.04764240235090256
    ],
    [
      -0.01132369413971901,
      -0.049051664769649506,
      0.037143103778362274,
      0.007668303791433573,
      0.09352662414312363,
      -0.028263455256819725,
      -0.002358231693506241,
      -0.06496693193912506,
      0.07663095742464066,
      -0.011857468634843826,
      -0.00039530752110295,
      0.0035721431486308575,
      -0.02838074415922165,
      -0.02139110304415226,
      0.0028120821807533503,
      -0.07873105257749557,
      -0.038389962166547775,
      -0.0977083370089531,
      -0.035226356238126755,
      -0.0016497375909239054,
      -0.01972290873527527,
      -0.005037201102823019,
      -0.02102300338447094,
      0.014729027636349201,
      0.10618158429861069,
      -0.03920723870396614,
      -0.0547030046582222,
      0.014248955063521862,
      -0.0371013805270195,
      0.010160994715988636,
      -0.036869537085294724,
      -0.033528707921504974,
      0.07157176733016968,
      0.040979355573654175,
      0.010713868774473667,
      0.04090483486652374,
      0.013042555190622807,
      0.04839419946074486,
      -0.03152993321418762,
      0.09701628983020782,
      -0.005300484597682953,
      -0.03468698635697365,
      -0.01678306795656681,
      0.03855119273066521,
      -0.062040481716394424,
      0.032020580023527145,
      0.012649506330490112,
      0.020937159657478333,
      0.0587400458753109,
      0.036542221903800964,
      -0.02709762565791607,
      0.0004377800796646625,
      -0.02594062127172947,
      0.025165604427456856,
      -0.0060040149837732315,
      0.10968025773763657,
      -0.029960522428154945,
      -0.018963051959872246,
      0.025001155212521553,
      0.04673133045434952,
      -0.05110260844230652,
      -0.04165744036436081,
      0.049630384892225266,
      -0.04450082778930664,
      0.05316309630870819,
      -0.06812974810600281,
      -0.0659387856721878,
      0.044945623725652695,
      0.01509516965597868,
      0.05759809911251068,
      -0.020483747124671936,
      -0.0036720307543873787,
      -0.0023017716594040394,
      -0.02937750518321991,
      -0.021254008635878563,
      -0.034656573086977005,
      0.01188614685088396,
      0.09144721925258636,
      0.04124489426612854,
      0.020335765555500984,
      0.030725285410881042,
      -0.01941031776368618,
      -0.024050965905189514,
      0.011357823386788368,
      0.01730409450829029,
      0.018247803673148155,
      0.11228322982788086,
      -0.012423958629369736,
      -0.004688445944339037,
      -0.02454092726111412,
      -0.0868195965886116,
      -0.02885134518146515,
      0.06330689042806625,
      0.002780141308903694,
      0.00384506699629128,
      0.010809316299855709,
      -0.010016591288149357,
      0.016829535365104675,
      0.06675484031438828,
      0.0019039165927097201,
      -0.006253550294786692,
      0.03976021334528923,
      0.07058121263980865,
      0.022847630083560944,
      -0.01961618661880493,
      0.04067258536815643,
      -0.06657883524894714,
      0.03278544545173645,
      -0.07396619021892548,
      -0.0263430867344141,
      -0.05752640217542648,
      0.013573339208960533,
      0.015781881287693977,
      0.029112834483385086,
      -0.03653344884514809,
      -0.004212362691760063,
      0.011104833334684372,
      0.06175404414534569,
      -0.03680234029889107,
      0.002038779901340604,
      -0.027374448254704475,
      -0.02038998156785965,
      -0.00403400557115674,
      0.017514966428279877,
      0.04250757396221161,
      0.03297645226120949,
      0.003795336466282606,
      -0.06241041421890259,
      0.03990151733160019,
      0.03570733219385147,
      -0.020502330735325813,
      0.0642879530787468,
      -0.03508913144469261,
      -0.018724601715803146,
      -0.05263917148113251,
      -0.04360271617770195,
      -0.06440605223178864,
      -0.05143172666430473,
      -0.005108154844492674,
      0.005814120639115572,
      0.004525472410023212,
      0.02559087797999382,
      0.005309957545250654,
      -0.03867385536432266,
      -0.010806244798004627,
      0.04235207289457321,
      0.013932676054537296,
      -0.0851888656616211,
      -0.01181094627827406,
      0.048471130430698395,
      -0.034572940319776535,
      0.0037909976672381163,
      0.0393499992787838,
      0.010489278472959995,
      0.009075609967112541,
      -0.06916766613721848,
      0.013673420064151287,
      -0.005556837189942598,
      -0.00899377278983593,
      -0.01391452457755804,
      -0.07156600058078766,
      0.04419882968068123,
      -0.05871308594942093,
      -0.042720451951026917,
      -0.018480179831385612,
      -0.016141999512910843,
      0.015927717089653015,
      -0.04918692633509636,
      0.01812129281461239,
      0.11007247865200043,
      -0.04354381188750267,
      0.009083477780222893,
      0.017603421583771706,
      0.03233148530125618,
      -0.0386115126311779,
      -0.06147855147719383,
      0.026200659573078156,
      -0.01942010037600994,
      0.018182680010795593,
      0.06796616315841675,
      0.018106743693351746,
      -0.0034554132726043463,
      -0.02700241282582283,
      0.055508606135845184,
      0.004203734453767538,
      -0.04475868120789528,
      0.09002155810594559,
      0.026413656771183014,
      -0.035582542419433594,
      -0.03879794478416443,
      0.006036422681063414,
      0.012548420578241348,
      -0.03681708872318268,
      0.07596695423126221,
      -0.07343888282775879,
      -0.07592452317476273,
      -0.030746715143322945,
      -0.006391789298504591,
      0.03167865797877312,
      0.0028347151819616556,
      0.0403670072555542,
      0.005237771198153496,
      0.05297447368502617,
      -0.015673089772462845,
      0.0013500354252755642,
      -0.0072593106888234615,
      -0.10088693350553513,
      -0.0319126695394516,
      0.020337319001555443,
      0.04416805878281593,
      0.0419493094086647,
      0.06597648561000824,
      0.03595234826207161,
      -0.04181889444589615,
      -0.07304693758487701,
      -0.005307551007717848,
      0.0030204406939446926,
      0.03705544024705887,
      0.0068155983462929726,
      0.06884965300559998,
      -0.03168574720621109,
      0.0686408206820488,
      0.059977009892463684,
      0.04595252871513367,
      -0.10527359694242477,
      0.05977973714470863,
      -0.043023448437452316,
      0.07465916872024536,
      0.004622708074748516,
      -0.06928910315036774,
      -0.04507437348365784,
      -0.04148675501346588,
      0.010826674290001392,
      0.04395990073680878,
      -0.07048031687736511,
      0.01455644890666008,
      0.030690768733620644,
      0.05567161738872528,
      -0.0015950463712215424,
      0.0269628819078207,
      0.050999902188777924,
      0.00725723197683692,
      -0.04643402621150017,
      -0.0033648351673036814,
      -0.08085749298334122,
      0.040685735642910004,
      -0.06556063145399094,
      0.04110801964998245,
      -0.01990961655974388,
      0.028802506625652313,
      -0.011170759797096252,
      0.04971376433968544,
      -0.0325377993285656,
      -0.011074533686041832,
      0.012039069086313248,
      0.03416964411735535,
      -0.00340434187091887,
      -0.018543487414717674,
      -0.03475572168827057,
      0.02686435729265213,
      0.020315714180469513,
      -0.012064394541084766,
      -0.06346339732408524,
      -0.006934333126991987,
      0.05718538537621498,
      -0.04222763702273369,
      -0.046758681535720825,
      0.08203162997961044,
      0.05105641856789589,
      0.002359532518312335,
      0.06140099838376045,
      -0.050317782908678055,
      0.001900414121337235,
      -0.03796180710196495,
      -0.018278395757079124,
      0.0008631940581835806,
      -0.06895314902067184,
      -0.02853989228606224,
      -0.05612783879041672,
      0.019939353689551353,
      0.07100930064916611,
      0.013122599571943283,
      0.03536147624254227,
      0.0647430270910263,
      -0.0006241209921427071,
      -0.05076347663998604,
      0.08311513811349869,
      -0.04796534404158592,
      0.060057468712329865,
      0.030112655833363533,
      -0.055037956684827805,
      0.07568788528442383,
      0.01875913143157959,
      -0.029694708064198494,
      -0.03226650506258011,
      -0.007362524978816509,
      -0.04214673861861229,
      0.003144112415611744,
      0.037463873624801636,
      0.030714115127921104,
      -0.001383095746859908,
      -0.027897970750927925,
      -0.04476829990744591,
      -0.06822971999645233,
      0.013475308194756508,
      0.04568122327327728,
      0.05183051526546478,
      0.019275035709142685,
      -0.07999587804079056,
      -0.028174085542559624,
      0.08928069472312927,
      -0.052520252764225006,
      0.10292786359786987,
      0.015083851292729378,
      -0.0978001207113266,
      0.05446503311395645,
      -0.05395468324422836,
      0.03714578226208687,
      -0.01816931553184986,
      0.019834140315651894,
      0.05667673051357269,
      -0.07249750196933746,
      0.023703353479504585,
      0.0024881884455680847,
      -0.05817101523280144,
      -0.011675555258989334,
      0.030792757868766785,
      0.08035106211900711,
      0.010596465319395065,
      -0.08060135692358017,
      -0.017460724338889122,
      -0.061927832663059235,
      0.11354372650384903,
      -0.09474802762269974,
      -0.01686294563114643,
      -0.04144259914755821,
      -0.01693693920969963,
      0.051365748047828674,
      -0.030719930306077003,
      0.0009423602023161948,
      -0.0020745997317135334,
      0.026960639283061028,
      -0.05780806392431259,
      -0.0027832507621496916,
      0.058296505361795425,
      -0.01910729520022869,
      0.030536562204360962,
      0.009658181108534336,
      0.06322241574525833,
      0.06065161153674126,
      -0.0521262064576149,
      -0.054455503821372986,
      -0.017719818279147148,
      0.0353316105902195,
      -0.04643697664141655,
      -0.011199024505913258,
      0.04601982235908508,
      -0.011517900973558426,
      0.01499675028026104,
      0.0740070566534996,
      0.029696892946958542,
      0.01061132550239563,
      -0.04577726125717163,
      -0.027957962825894356,
      0.0068074800074100494,
      -0.03827962651848793,
      0.04551031440496445,
      -0.0237888153642416,
      0.0030696119647473097,
      0.011361433193087578,
      -0.03258995711803436,
      -0.024216685444116592,
      0.016774559393525124,
      0.0589144229888916,
      -0.01600521430373192,
      -0.04252507910132408,
      0.028333617374300957,
      0.029717376455664635,
      -0.029082557186484337,
      0.015296651981770992,
      0.013066825456917286,
      -0.025105610489845276,
      -0.02196963131427765,
      0.011045071296393871,
      0.009911080822348595,
      -0.026747629046440125,
      -0.008533255197107792,
      0.03911910578608513,
      0.01911979541182518,
      -0.010857511311769485,
      -0.03312970697879791,
      -0.08247669041156769,
      -0.03520868346095085,
      0.00010756090341601521,
      -0.00882111769169569,
      -0.0030845936853438616,
      0.08084549009799957,
      0.06928840279579163,
      0.04630567505955696,
      0.08698391914367676,
      0.008691951632499695,
      0.04594050720334053,
      0.002085018903017044,
      -0.02817569673061371,
      -0.015161742456257343,
      -0.06395059078931808,
      0.004370551090687513,
      0.024180347099900246,
      0.034023452550172806,
      -0.04470566287636757,
      -0.03765883296728134,
      0.0056307269260287285,
      -0.11543705314397812,
      0.060284536331892014,
      -0.01762230508029461,
      0.021180452778935432,
      -0.023467538878321648,
      0.04357384145259857,
      0.06150508299469948,
      0.04697626829147339,
      0.013869639486074448,
      0.0008730576955713332,
      -0.04229353740811348,
      0.03126409277319908,
      -0.038485992699861526,
      -0.019679643213748932,
      0.005880119279026985,
      -0.038363974541425705,
      -0.06411612778902054,
      0.06375189870595932,
      0.0026685341726988554,
      -0.024849504232406616,
      -0.027563540264964104,
      0.0022876481525599957,
      -0.05736099183559418,
      0.05846767500042915,
      -0.016344895586371422,
      -0.03877280279994011,
      -0.0586475133895874,
      -0.005120838992297649,
      -0.003005347214639187,
      0.02471937984228134,
      -0.06615540385246277,
      -0.04189350828528404,
      0.02319699339568615,
      0.005931975319981575,
      -0.0030241103377193213,
      0.018286358565092087,
      0.05287954956293106,
      0.012494778260588646,
      -0.0076712267473340034,
      0.01241787988692522,
      0.08933893591165543,
      0.02707628346979618,
      0.024158164858818054,
      -0.005309564061462879,
      0.07853513956069946,
      0.028402330353856087,
      0.033572014421224594,
      0.013039175420999527,
      0.04496892914175987,
      0.0227991733700037,
      -0.0018801058176904917,
      0.022424275055527687,
      0.037407126277685165,
      0.02770901471376419,
      0.029728859663009644,
      -0.00398995028808713,
      -0.0016143264947459102,
      0.05748281627893448,
      0.009880641475319862,
      0.011443349532783031,
      0.04831811413168907,
      -0.08656780421733856,
      0.03666369989514351,
      -0.0037594973109662533,
      0.043926119804382324,
      0.00987861305475235,
      0.00961682852357626,
      0.014370812103152275,
      -0.01928548887372017,
      -0.03599846735596657,
      -0.006349514238536358,
      -0.033705439418554306,
      -0.006385487969964743,
      0.06950130313634872,
      -0.015211319550871849,
      0.007238556165248156,
      -0.01013850700110197,
      -0.03696369007229805,
      0.03807275742292404,
      0.05576610565185547,
      0.01447459775954485,
      -0.03167048841714859,
      -0.010265262797474861,
      -0.0013344428734853864,
      0.028749017044901848,
      0.014792308211326599,
      0.057262521237134933,
      -0.04593725502490997,
      0.006870531942695379,
      -0.01148512028157711,
      0.014967710711061954
    ],
    [
      0.0032695529516786337,
      0.04412110149860382,
      0.020166246220469475,
      -0.005069294478744268,
      -0.02733343280851841,
      0.05476941540837288,
      0.011095913127064705,
      0.0016316575929522514,
      -0.024845607578754425,
      -0.04966177046298981,
      0.014430640265345573,
      0.000692333618644625,
      -0.04657236114144325,
      -0.014904429204761982,
      0.008326812647283077,
      -0.04321078583598137,
      -0.007612200919538736,
      -0.020586801692843437,
      0.02543969824910164,
      0.012929661199450493,
      0.01646798849105835,
      -0.02777312509715557,
      0.03722856193780899,
      0.02978196181356907,
      0.01629146933555603,
      -0.10066011548042297,
      -0.08810646831989288,
      0.021017340943217278,
      0.07244420796632767,
      0.05029693618416786,
      0.038540881127119064,
      0.058927640318870544,
      -0.04700883850455284,
      -0.003138341475278139,
      -0.025650983676314354,
      0.043967317789793015,
      -0.04595410078763962,
      0.10408320277929306,
      0.036452993750572205,
      0.011094595305621624,
      0.06253053992986679,
      0.05770916864275932,
      0.0253747571259737,
      -0.0363943949341774,
      0.019261637702584267,
      0.0089528514072299,
      -0.008529833517968655,
      0.03091062419116497,
      -0.03348870575428009,
      0.03014538809657097,
      0.06894869357347488,
      -0.013574883341789246,
      0.0744963064789772,
      0.025463145226240158,
      -0.009537714533507824,
      -0.0032224319875240326,
      0.03488738834857941,
      0.05861002951860428,
      -0.017751872539520264,
      -0.0790129080414772,
      -0.04967831075191498,
      -0.04589981213212013,
      0.03942935913801193,
      -0.007761436048895121,
      0.042879294604063034,
      0.017527904361486435,
      0.05161931365728378,
      -0.050780586898326874,
      0.016580982133746147,
      -0.07162345200777054,
      0.050475988537073135,
      -0.02756405994296074,
      -0.033207934349775314,
      0.06012845039367676,
      -0.040559880435466766,
      -0.014680463820695877,
      0.01134727243334055,
      0.009671052917838097,
      -0.035242609679698944,
      0.001691591925919056,
      -0.027255836874246597,
      0.02360549010336399,
      -0.034070324152708054,
      -0.029271908104419708,
      0.014731683768332005,
      0.009511159732937813,
      0.04209696501493454,
      -0.04878045246005058,
      0.006716572679579258,
      -0.0012881176080554724,
      -0.08816608786582947,
      0.0612974688410759,
      -0.035107940435409546,
      0.026270529255270958,
      0.06349743902683258,
      0.057537686079740524,
      0.006121573504060507,
      0.009078712202608585,
      0.08346433192491531,
      0.037569381296634674,
      -0.0634380429983139,
      0.008605703711509705,
      -0.02959642931818962,
      -0.036228686571121216,
      0.058000221848487854,
      -0.024734819307923317,
      0.007490925025194883,
      0.03787835314869881,
      -0.026268120855093002,
      0.010699990205466747,
      -0.01647309958934784,
      0.0036693632137030363,
      -0.02516683004796505,
      -0.05582517385482788,
      -0.013568057678639889,
      0.03697400167584419,
      -0.09328296035528183,
      -0.0018664902308955789,
      0.03824390098452568,
      -0.04885265603661537,
      0.04544501751661301,
      -0.01160313282161951,
      0.015601501800119877,
      -0.0697978213429451,
      0.01364609319716692,
      -0.03441494330763817,
      0.07367679476737976,
      -0.04945410043001175,
      0.013511169701814651,
      -0.03454336151480675,
      0.014170984737575054,
      0.024018291383981705,
      -0.02532465197145939,
      -0.03532084822654724,
      -0.08583983778953552,
      -0.013827433809638023,
      0.049144893884658813,
      0.013002235442399979,
      0.04833618551492691,
      0.00759639497846365,
      0.044181857258081436,
      0.00039355826447717845,
      0.01923578977584839,
      0.020262569189071655,
      0.0037939136382192373,
      -0.07789933681488037,
      -0.010915093123912811,
      -0.017488371580839157,
      -0.029988370835781097,
      0.031858738511800766,
      -0.024580663070082664,
      -0.05945296213030815,
      -0.031368911266326904,
      0.005933492444455624,
      0.041451964527368546,
      -0.008915727958083153,
      -0.06260522454977036,
      0.00908234715461731,
      -0.03156987950205803,
      0.042439110577106476,
      -0.061037205159664154,
      0.03033427894115448,
      0.0337710976600647,
      -0.02411944605410099,
      -0.06117604300379753,
      -0.03524192050099373,
      -0.021521326154470444,
      -0.017948448657989502,
      -0.023556936532258987,
      -0.007166496943682432,
      0.011132876388728619,
      -0.05637975409626961,
      -0.11793264001607895,
      -0.06172949820756912,
      0.02026047743856907,
      -0.03134525939822197,
      0.021130526438355446,
      0.0988481342792511,
      0.14328937232494354,
      0.056235238909721375,
      -0.05527081713080406,
      -0.07509038597345352,
      -0.0670296773314476,
      0.006492695305496454,
      -0.06029839441180229,
      0.01967526413500309,
      -0.005729338154196739,
      0.02903502620756626,
      0.010520796291530132,
      0.06511443853378296,
      0.023487718775868416,
      0.03925282508134842,
      -0.06257312744855881,
      0.012388563714921474,
      -0.005945001728832722,
      0.058481473475694656,
      0.06584819406270981,
      -0.05949871614575386,
      0.01217698585242033,
      0.08371670544147491,
      0.0030691626016050577,
      0.014929573982954025,
      -0.08899760246276855,
      -0.014001850970089436,
      -0.03563874214887619,
      0.0032595843076705933,
      -0.00794187281280756,
      -0.04729937016963959,
      -0.03827312961220741,
      0.024002354592084885,
      0.041442397981882095,
      -0.06310995668172836,
      -0.02050340361893177,
      -0.01847543567419052,
      -0.12498056143522263,
      0.0002785130636766553,
      -0.012939853593707085,
      -0.04164732247591019,
      -0.040117040276527405,
      0.014740926213562489,
      0.0520901195704937,
      0.030264560133218765,
      0.022333582863211632,
      0.010770772583782673,
      -0.03206780552864075,
      -0.018934233114123344,
      0.052574943751096725,
      0.014709598384797573,
      0.027356348931789398,
      0.09162471443414688,
      0.08881263434886932,
      0.023809116333723068,
      0.011413408443331718,
      -0.05735674872994423,
      -0.10118643194437027,
      0.03065815381705761,
      -0.05274231359362602,
      -0.01331015769392252,
      -0.08111638575792313,
      -0.011253450997173786,
      -0.023243365809321404,
      -0.038224879652261734,
      -0.005526204593479633,
      -0.02627691999077797,
      0.01919153518974781,
      0.02996920980513096,
      0.029841391369700432,
      0.03955043479800224,
      -0.0268168356269598,
      0.012158621102571487,
      0.01995115913450718,
      -0.05838606134057045,
      0.056662946939468384,
      0.024200260639190674,
      0.033557139337062836,
      -0.056864820420742035,
      -0.039367564022541046,
      0.00466206856071949,
      0.041593533009290695,
      0.01929473876953125,
      0.049340907484292984,
      0.04554131627082825,
      0.03093273937702179,
      0.04993435740470886,
      -0.03871999680995941,
      -0.03503052890300751,
      0.024430051445961,
      -0.07680856436491013,
      0.06167072057723999,
      -0.037800244987010956,
      0.01575423777103424,
      0.015735432505607605,
      0.028348324820399284,
      0.005001600831747055,
      0.030980877578258514,
      -0.04401639103889465,
      -0.0006059169536456466,
      0.02270139567553997,
      -0.04308241605758667,
      -0.03833113983273506,
      0.011338377371430397,
      -0.004879504907876253,
      0.018365919589996338,
      0.025635868310928345,
      -0.017314838245511055,
      0.025436555966734886,
      -0.01891971193253994,
      -0.03944449499249458,
      -0.0019098225748166442,
      0.022242743521928787,
      0.01896144077181816,
      -0.0023439719807356596,
      -0.042828869074583054,
      0.037970028817653656,
      0.056004323065280914,
      -0.02382519096136093,
      -0.01610320620238781,
      -0.0036281540524214506,
      -0.010392685420811176,
      0.012769539840519428,
      0.07850364595651627,
      0.002880333922803402,
      0.025147506967186928,
      0.04685166850686073,
      -0.04549425467848778,
      -0.006740768440067768,
      0.07360377907752991,
      -0.0223206989467144,
      0.017466682940721512,
      0.009783054701983929,
      0.025684285908937454,
      0.024677637964487076,
      0.015807073563337326,
      -0.07670602202415466,
      0.0363365113735199,
      0.0018037214176729321,
      0.08321139961481094,
      0.05266477167606354,
      0.006251436658203602,
      -0.013385447673499584,
      0.0003763702407013625,
      -0.016435660421848297,
      0.014961210079491138,
      0.0014184408355504274,
      -0.06463649123907089,
      0.013965235091745853,
      0.012038382701575756,
      -0.09425591677427292,
      -0.0022985858377069235,
      -0.07281125336885452,
      0.031430162489414215,
      0.04189401492476463,
      0.011462443508207798,
      -0.028207480907440186,
      0.05702972412109375,
      -0.010989006608724594,
      -0.03206963837146759,
      0.02556711621582508,
      -0.07957915961742401,
      -0.06752528250217438,
      0.03317923843860626,
      0.09252923727035522,
      0.1705823689699173,
      0.08561629056930542,
      -0.04609426110982895,
      -0.0381980799138546,
      0.001091392943635583,
      0.025166712701320648,
      -0.028690818697214127,
      0.02510889619588852,
      -0.07049020379781723,
      0.07384940981864929,
      -0.02238054759800434,
      -0.029254745692014694,
      -0.07005669921636581,
      0.06626530736684799,
      -0.05529053881764412,
      -0.0024395594373345375,
      0.018718570470809937,
      -0.0024516512639820576,
      -0.05412864685058594,
      0.06374488770961761,
      -0.07427092641592026,
      0.05298245698213577,
      0.011070995591580868,
      0.0397016741335392,
      -0.08523731678724289,
      0.051903825253248215,
      0.02143426053225994,
      -0.03528948873281479,
      0.07395191490650177,
      -0.012454749085009098,
      0.001306795747950673,
      0.028805753216147423,
      0.009816529229283333,
      -0.02187454141676426,
      0.015093293972313404,
      5.040397081756964e-05,
      -0.04450153186917305,
      -0.04783828929066658,
      -0.010687248781323433,
      -0.024470970034599304,
      0.014585711993277073,
      -0.04035625979304314,
      -0.044429711997509,
      0.030557403340935707,
      -0.011385570280253887,
      0.044998493045568466,
      -0.078048475086689,
      0.006824730429798365,
      0.039120666682720184,
      -0.041139017790555954,
      0.00139031617436558,
      0.13602009415626526,
      -0.048641350120306015,
      0.005837414879351854,
      0.0027398576494306326,
      0.06868431717157364,
      0.004241641610860825,
      0.03457345813512802,
      -0.03088100254535675,
      0.0245734341442585,
      0.04508441686630249,
      -0.05782083049416542,
      -0.06090681999921799,
      0.029413430020213127,
      -0.05871972069144249,
      0.02421625517308712,
      0.06476210057735443,
      -0.029423249885439873,
      0.0841364860534668,
      0.05873849615454674,
      2.957232754852157e-05,
      -0.05415402352809906,
      0.01826416328549385,
      -0.03834928572177887,
      -0.07314194738864899,
      0.00714095076546073,
      0.06080261990427971,
      -0.0033749770373106003,
      0.038555245846509933,
      -0.020793810486793518,
      -0.0009604299557395279,
      0.0031919877510517836,
      0.08332306891679764,
      -0.10547128319740295,
      -0.08846533298492432,
      -0.014058682136237621,
      0.012371332384645939,
      -0.0341518335044384,
      -0.030338792130351067,
      0.012286663055419922,
      -0.013449541293084621,
      -0.009772155433893204,
      -0.01578240655362606,
      0.06811647117137909,
      -0.021313266828656197,
      -0.009374536573886871,
      0.04675009474158287,
      0.048673056066036224,
      0.024481412023305893,
      0.0019417600706219673,
      -0.03461994603276253,
      -0.00032784941140562296,
      0.01617625169456005,
      0.019119994714856148,
      -0.02412126585841179,
      0.022064033895730972,
      0.0206113550812006,
      -0.03175940737128258,
      -0.02074447087943554,
      0.085211381316185,
      0.0065545872785151005,
      0.01409235317260027,
      -0.09761305153369904,
      0.006398409605026245,
      -0.00900452584028244,
      -0.010670052841305733,
      -0.05899111554026604,
      0.048195477575063705,
      0.0033921818248927593,
      -0.011990619823336601,
      -0.01530295517295599,
      -0.01704975590109825,
      -0.014882011339068413,
      -0.01988452672958374,
      -0.016383416950702667,
      -0.08942330628633499,
      -0.010908170603215694,
      -0.05860058218240738,
      -0.05218847841024399,
      0.05736393854022026,
      0.062229447066783905,
      0.07598476111888885,
      0.06029738485813141,
      -0.06996818631887436,
      -0.014427411369979382,
      -0.022934384644031525,
      -0.030427364632487297,
      -0.08206982165575027,
      -0.04193760082125664,
      -0.01244968082755804,
      -0.01427734550088644,
      -0.023769577965140343,
      0.019370997324585915,
      -0.0030735034961253405,
      -0.11047613620758057,
      0.03539872542023659,
      -0.07534356415271759,
      0.040248941630125046,
      -0.0028406353667378426,
      -0.02769576571881771,
      -0.007233431562781334,
      -0.00036065204767510295,
      -0.004871883429586887,
      -0.01734006032347679,
      -0.032287441194057465,
      -0.05643008276820183,
      0.028626034036278725,
      -0.004501689691096544,
      -0.035132694989442825,
      0.061726391315460205,
      -0.041157640516757965
    ],
    [
      0.02993575483560562,
      -0.020923206582665443,
      0.014486902393400669,
      0.06036953628063202,
      0.0020814375020563602,
      0.0921558141708374,
      -0.035949379205703735,
      -4.698281372839119e-06,
      0.026572465896606445,
      0.0134507454931736,
      0.05404051020741463,
      -0.04461580142378807,
      -0.0005738461622968316,
      0.10380721837282181,
      0.002052681054919958,
      0.034525275230407715,
      0.009892672300338745,
      0.033704474568367004,
      -0.14264346659183502,
      0.04723716527223587,
      -0.0281063225120306,
      0.07265317440032959,
      0.01090514101088047,
      -0.0018920556176453829,
      0.0713595524430275,
      0.01196480542421341,
      0.02611672133207321,
      0.0385780856013298,
      0.02533024176955223,
      0.07936906814575195,
      -0.027336061000823975,
      0.014608141966164112,
      -0.03231777250766754,
      -0.005501174367964268,
      -0.007086550816893578,
      -0.01000154297798872,
      0.07615076750516891,
      -0.029443537816405296,
      0.026940394192934036,
      0.061519499868154526,
      -0.06557241082191467,
      -0.006241591647267342,
      -0.047995585948228836,
      0.04751221835613251,
      -0.07513528317213058,
      0.07399780303239822,
      -0.07662547379732132,
      0.05675460770726204,
      -0.02380816452205181,
      -0.0038577737286686897,
      0.012341372668743134,
      -0.04165397584438324,
      -0.0348602831363678,
      -0.04240059480071068,
      -0.016897736117243767,
      -0.019742298871278763,
      -0.0004435394948814064,
      -0.022152569144964218,
      -0.061851855367422104,
      0.08598242700099945,
      -0.03710470721125603,
      -0.046297550201416016,
      -0.0426688976585865,
      0.10963813215494156,
      -0.03378360718488693,
      -0.03913753852248192,
      -0.012657959945499897,
      0.015102018602192402,
      0.05760275945067406,
      0.09545528888702393,
      -0.025811592116951942,
      -0.045753706246614456,
      -0.10887669026851654,
      0.05390702188014984,
      0.056538019329309464,
      0.07293631136417389,
      -0.03282573074102402,
      -0.0429743193089962,
      -0.07855600863695145,
      0.07127425074577332,
      -0.041837938129901886,
      0.01969693787395954,
      0.03154819458723068,
      0.04315512999892235,
      0.05805452913045883,
      -0.003831247566267848,
      0.00638772826641798,
      0.01656314730644226,
      0.007852979935705662,
      0.02351870760321617,
      0.002811193699017167,
      0.008371977135539055,
      0.04102600738406181,
      -0.016793549060821533,
      0.09616845101118088,
      0.02238253504037857,
      -0.0019426896469667554,
      -0.012872443534433842,
      -0.025764524936676025,
      -0.0529690682888031,
      0.0365484356880188,
      -0.016025550663471222,
      -0.009518096223473549,
      -0.03345942124724388,
      0.009379269555211067,
      -0.03237830474972725,
      0.0014474266208708286,
      0.024047590792179108,
      0.040633171796798706,
      0.02391711436212063,
      -0.05012524500489235,
      -0.03766367956995964,
      0.007982670329511166,
      -0.00103083997964859,
      -0.07782028615474701,
      -0.04355650767683983,
      0.008530793711543083,
      -0.062135644257068634,
      0.016978824511170387,
      -0.03573645278811455,
      0.08682291209697723,
      0.0031514018774032593,
      0.035894956439733505,
      -0.01275906153023243,
      0.04322952777147293,
      0.0017329796683043242,
      -0.07950551807880402,
      -0.03319384530186653,
      0.04087155684828758,
      0.07169810682535172,
      0.010984673164784908,
      0.04879835247993469,
      0.040121596306562424,
      0.014109201729297638,
      -0.019150950014591217,
      -0.009376461617648602,
      0.02733547054231167,
      -0.03538694605231285,
      -0.06875371932983398,
      0.035058386623859406,
      0.029954027384519577,
      0.01881321705877781,
      0.050639472901821136,
      0.006102289073169231,
      -0.06427957117557526,
      0.04174456745386124,
      -0.060926247388124466,
      -0.08224716037511826,
      0.06516401469707489,
      0.002321400213986635,
      -0.007295020390301943,
      -0.00629608565941453,
      -0.047182753682136536,
      -0.013406766578555107,
      0.04104508459568024,
      0.062262315303087234,
      -0.06638924032449722,
      -0.06404579430818558,
      -0.07241581380367279,
      -0.00802732165902853,
      -0.03791414201259613,
      -0.038405969738960266,
      0.07119207084178925,
      0.08174148201942444,
      -0.06158338114619255,
      -0.053227927535772324,
      0.07531139999628067,
      -0.001979799708351493,
      0.010769055224955082,
      0.05566130951046944,
      -0.029604285955429077,
      -0.019000979140400887,
      0.06884878128767014,
      -0.047535523772239685,
      -0.056415896862745285,
      -0.030460791662335396,
      -0.05338849127292633,
      -0.040447019040584564,
      -0.0016368760261684656,
      -0.05462273582816124,
      -0.012397795915603638,
      -0.03681156039237976,
      0.010960478335618973,
      -0.0699385330080986,
      -0.061006270349025726,
      0.06708648800849915,
      0.0075516412034630775,
      0.0008919502724893391,
      -0.039095696061849594,
      0.009423011913895607,
      0.023825781419873238,
      -0.08301849663257599,
      -0.009281306527554989,
      0.05660345405340195,
      -0.028130969032645226,
      0.027741510421037674,
      -0.09922809153795242,
      0.02259746752679348,
      0.11109152436256409,
      -0.03974390774965286,
      0.08124732226133347,
      0.0023068683221936226,
      -0.02498561516404152,
      0.004417371936142445,
      -0.049686867743730545,
      -0.0008873729966580868,
      0.10974261164665222,
      0.041368912905454636,
      -0.03584442660212517,
      0.05091284587979317,
      -0.009647215716540813,
      0.029637249186635017,
      -0.02696678787469864,
      0.05099597945809364,
      -0.01889980025589466,
      0.0030622691847383976,
      0.06165875867009163,
      0.015378755517303944,
      -0.030976029112935066,
      -0.05438988655805588,
      0.0006673388998024166,
      -0.07626740634441376,
      -0.050023168325424194,
      -0.0887976735830307,
      -0.011950402520596981,
      0.002192603424191475,
      -0.002756325528025627,
      -0.0007473430596292019,
      0.00531776063144207,
      -0.0029012556187808514,
      0.01771387830376625,
      -0.07576411217451096,
      -0.01755114085972309,
      0.008280972950160503,
      0.01906067505478859,
      0.02024111896753311,
      -0.04666759446263313,
      -0.016555113717913628,
      -0.04644129052758217,
      -0.0234798826277256,
      -0.0034573820885270834,
      0.04479118809103966,
      0.023490631952881813,
      0.08707602322101593,
      0.030341273173689842,
      -0.06232276186347008,
      -0.02963419072329998,
      0.03104688785970211,
      0.061813533306121826,
      -0.021170997992157936,
      0.024691453203558922,
      0.033389829099178314,
      -0.058439817279577255,
      0.0038243168964982033,
      -0.024856356903910637,
      0.0403166338801384,
      0.08744516223669052,
      0.07905857264995575,
      0.012497110292315483,
      0.01533527672290802,
      -0.039929211139678955,
      0.022656267508864403,
      -0.009749408811330795,
      0.020728230476379395,
      0.001628322061151266,
      -0.03475071117281914,
      0.02569657191634178,
      0.05689016729593277,
      -0.03717125579714775,
      -0.013950574211776257,
      0.005559107288718224,
      0.005426902323961258,
      -0.03227465972304344,
      -0.015558821149170399,
      0.021698297932744026,
      0.0744466781616211,
      0.1301497370004654,
      -0.006608536932617426,
      0.04812208563089371,
      0.04433475807309151,
      0.029436839744448662,
      0.005441754125058651,
      0.060281138867139816,
      0.06192944943904877,
      0.020723387598991394,
      0.017259109765291214,
      -0.07396653294563293,
      0.03962862119078636,
      -0.00033190008252859116,
      0.061152249574661255,
      -0.012436030432581902,
      0.008436634205281734,
      -0.019047651439905167,
      0.02705409750342369,
      0.014933615922927856,
      0.005404757801443338,
      0.012686620466411114,
      0.08636016398668289,
      -0.03859622776508331,
      -0.0031894741114228964,
      -0.04528180882334709,
      -0.040421321988105774,
      -0.004140919074416161,
      -0.00536399707198143,
      -0.008586004376411438,
      0.030043527483940125,
      0.03126080706715584,
      -0.04731880873441696,
      -0.011096209287643433,
      -0.042752329260110855,
      0.05169873684644699,
      -0.009687874466180801,
      0.00028746604220941663,
      0.01785910688340664,
      -0.018063552677631378,
      0.06558786332607269,
      -0.1087183803319931,
      -0.0007611559121869504,
      -0.00029313648701645434,
      -0.0058646779507398605,
      -0.008964067324995995,
      -0.01756744273006916,
      0.0720399022102356,
      0.005979855544865131,
      -0.04899893328547478,
      -0.09246116131544113,
      -0.05403270199894905,
      -0.08946369588375092,
      -0.0291792843490839,
      0.11384415626525879,
      0.07624012976884842,
      -0.053229741752147675,
      -0.06420499086380005,
      -0.01836969330906868,
      0.03138532117009163,
      0.06131676584482193,
      -0.05086103081703186,
      0.06109144166111946,
      0.024311989545822144,
      0.03596240282058716,
      0.04572107642889023,
      -0.048742059618234634,
      -0.014671748504042625,
      -0.07552193850278854,
      0.09315350651741028,
      0.023923367261886597,
      -0.013141936622560024,
      -0.022941306233406067,
      -0.0017163512529805303,
      -0.07340001314878464,
      -0.07178827375173569,
      -0.0464506633579731,
      -0.02295164205133915,
      -0.014249797910451889,
      0.033149994909763336,
      0.009215211495757103,
      -0.002381411846727133,
      -0.05359635129570961,
      0.007102275267243385,
      -0.056646544486284256,
      -0.053994905203580856,
      0.015295876190066338,
      0.01712731458246708,
      0.008703668601810932,
      -0.03947983682155609,
      -0.06255325675010681,
      -0.0067145260982215405,
      0.03251782804727554,
      -0.04602506011724472,
      0.05305810272693634,
      0.021140193566679955,
      0.0017989028710871935,
      0.025944964960217476,
      0.026998529210686684,
      -0.017177360132336617,
      -0.1211094781756401,
      -0.0352005660533905,
      0.11227931082248688,
      -0.020551107823848724,
      -0.04115968197584152,
      -0.0571608766913414,
      0.061565302312374115,
      -0.005061249248683453,
      -0.005172757897526026,
      0.035260796546936035,
      0.0037312640342861414,
      0.019040677696466446,
      0.0624210424721241,
      0.06900954246520996,
      -0.08699313551187515,
      0.047343336045742035,
      0.028219664469361305,
      -0.05167967081069946,
      0.019700443372130394,
      -0.02936217375099659,
      0.04877101257443428,
      -0.022375866770744324,
      0.016563640907406807,
      0.007514025550335646,
      0.06688794493675232,
      -0.018599743023514748,
      -0.02319464087486267,
      -0.04751116409897804,
      -0.044516731053590775,
      0.028070179745554924,
      -0.05057457834482193,
      -0.023097556084394455,
      0.01166430301964283,
      -0.04698537290096283,
      0.022790396586060524,
      0.019313249737024307,
      -0.04950471967458725,
      0.03656157851219177,
      0.000386005180189386,
      0.04021238535642624,
      -0.012774426490068436,
      -0.003112873760983348,
      0.002208300167694688,
      0.05169837921857834,
      -0.03175325319170952,
      0.046626340597867966,
      0.0153899434953928,
      -0.008074101991951466,
      -0.03961654752492905,
      0.041033413261175156,
      0.023051146417856216,
      -0.03379620611667633,
      -0.058744411915540695,
      0.03978314995765686,
      -0.005307803396135569,
      -0.021564237773418427,
      0.05133570358157158,
      -0.02514379844069481,
      0.05275604501366615,
      0.030808275565505028,
      0.03132195025682449,
      -0.06927972286939621,
      -0.058439452201128006,
      0.016081150621175766,
      0.01468711718916893,
      -0.015675975009799004,
      -0.0342189259827137,
      0.01859753020107746,
      -0.019621634855866432,
      0.054192960262298584,
      -0.06204352527856827,
      0.009621434845030308,
      0.01192932203412056,
      -0.04564611613750458,
      -0.052116889506578445,
      0.03301620855927467,
      -0.030495833605527878,
      0.021585065871477127,
      -0.050940755754709244,
      0.005026360973715782,
      -0.04752116650342941,
      0.020802367478609085,
      -0.046884216368198395,
      -0.1084001362323761,
      0.041493289172649384,
      -0.014540635980665684,
      -0.055225882679224014,
      0.11146174371242523,
      0.03906078636646271,
      -0.04196539521217346,
      -0.014419157058000565,
      -0.0732022374868393,
      -0.026052994653582573,
      -0.038237642496824265,
      0.12657856941223145,
      -0.005705907475203276,
      0.024837464094161987,
      0.008426989428699017,
      -0.021578757092356682,
      0.00048673147102817893,
      0.10014786571264267,
      0.04357348755002022,
      0.04531234875321388,
      -0.02529815398156643,
      -0.01587395928800106,
      0.0065043093636631966,
      0.08231030404567719,
      -0.08533450961112976,
      -0.013532515615224838,
      -0.001352125545963645,
      0.0028929810505360365,
      0.022812237963080406,
      0.005538459401577711,
      0.0636109709739685,
      0.02865571901202202,
      0.06014150008559227,
      0.015881577506661415,
      -0.01728396862745285,
      -0.04697432741522789,
      0.0074493675492703915,
      0.006493809632956982,
      0.03005254454910755,
      -0.037816938012838364,
      -0.03449738025665283,
      -0.05175543576478958,
      -0.02569561079144478,
      0.02226843498647213,
      -0.024393972009420395
    ],
    [
      0.03287885710597038,
      0.013595589436590672,
      0.08465983718633652,
      0.003171665593981743,
      -0.03231605142354965,
      0.0026225741021335125,
      -0.09260677546262741,
      0.004653404001146555,
      -0.0757361575961113,
      0.022167308256030083,
      -0.0014174068346619606,
      0.10236523300409317,
      0.11181648075580597,
      0.071758933365345,
      0.05759207904338837,
      0.0712185874581337,
      -0.0704631507396698,
      0.023315690457820892,
      -0.021105924621224403,
      0.007795014884322882,
      0.009745134972035885,
      0.04398750513792038,
      -0.03366537764668465,
      -0.13375383615493774,
      0.027461353689432144,
      -0.045949697494506836,
      0.04894100874662399,
      -0.01325143687427044,
      0.05381244793534279,
      -0.028863046318292618,
      0.014110674150288105,
      0.0345006063580513,
      -0.01839524693787098,
      -0.007351825479418039,
      -0.0008046422153711319,
      0.06644627451896667,
      -0.009657096117734909,
      0.04048461094498634,
      -0.007446951232850552,
      0.012728873640298843,
      -0.0560724101960659,
      -0.00020862719975411892,
      0.05114820972084999,
      0.027634916827082634,
      -0.11853132396936417,
      0.025547977536916733,
      0.013458616100251675,
      0.04120201990008354,
      -0.005552188493311405,
      0.07953236997127533,
      -0.049404703080654144,
      0.02625853195786476,
      0.04153307154774666,
      0.09945181012153625,
      -0.02924802526831627,
      0.0649743601679802,
      0.0416889488697052,
      -0.0034575629979372025,
      -0.023572901263833046,
      -0.041946060955524445,
      0.08545639365911484,
      -0.052373021841049194,
      -0.056584205478429794,
      0.02939465083181858,
      -0.04305094853043556,
      0.0010423356434330344,
      0.013508802279829979,
      -0.031223271042108536,
      -0.053278110921382904,
      -0.023775463923811913,
      0.07109493762254715,
      0.04396715387701988,
      0.009247629903256893,
      -0.05607561394572258,
      -0.016430821269750595,
      -0.01523785013705492,
      -0.07637811452150345,
      -0.05177036300301552,
      0.03245173767209053,
      -0.036367859691381454,
      0.07371487468481064,
      0.12496499717235565,
      -0.010553906671702862,
      0.04081583768129349,
      0.00506619643419981,
      0.0016718199476599693,
      -0.08747363835573196,
      -0.0071943290531635284,
      0.015566379763185978,
      -0.04760095104575157,
      -0.055392470210790634,
      0.04087376594543457,
      0.0034638731740415096,
      0.06044444814324379,
      -0.08460057526826859,
      0.04113350063562393,
      0.012519427575170994,
      -0.12385436147451401,
      -0.04776417464017868,
      0.06615360826253891,
      -0.02860822156071663,
      -0.08068060129880905,
      0.03555638715624809,
      0.006182526238262653,
      0.02533789537847042,
      0.011742965318262577,
      0.006099437829107046,
      0.027748432010412216,
      0.005208105780184269,
      0.06625387817621231,
      -0.007165379356592894,
      -0.02668769657611847,
      0.017275094985961914,
      0.0012878114357590675,
      -0.07992181181907654,
      -0.03566526249051094,
      -0.0761643722653389,
      0.029835786670446396,
      0.0483328178524971,
      -0.037015896290540695,
      -0.004993329755961895,
      0.007994897663593292,
      -0.05271129310131073,
      -0.010090699419379234,
      -0.014735073782503605,
      -0.02666548453271389,
      0.04107465222477913,
      -0.002859559841454029,
      -0.05233291536569595,
      0.030281636863946915,
      -0.0066683329641819,
      0.06804393231868744,
      0.04328973963856697,
      -0.03570370376110077,
      0.021441515535116196,
      -0.03806949406862259,
      -0.03197711333632469,
      0.00770628172904253,
      -0.17017866671085358,
      0.049155671149492264,
      0.02089313045144081,
      -0.04922652617096901,
      0.026024097576737404,
      0.033241573721170425,
      0.048372793942689896,
      0.029081230983138084,
      -0.027915380895137787,
      -0.0948963463306427,
      0.06594154983758926,
      -0.017793195322155952,
      0.061916690319776535,
      -0.023856621235609055,
      -0.011115341447293758,
      -0.06528504192829132,
      -0.017025837674736977,
      -0.011952775530517101,
      -0.05775978043675423,
      -0.04260191693902016,
      0.057264748960733414,
      -0.009958344511687756,
      -0.007400583941489458,
      -0.05258604884147644,
      0.014391702599823475,
      -0.08803050965070724,
      -0.036772917956113815,
      0.01872856169939041,
      -0.04492923617362976,
      -0.034291911870241165,
      -0.005140272434800863,
      0.03795555606484413,
      -0.07771666347980499,
      -0.0545303076505661,
      0.06724672019481659,
      0.06845668703317642,
      -0.08220315724611282,
      0.0056582726538181305,
      -0.01717112772166729,
      -0.05615410581231117,
      0.10710623860359192,
      -0.07392458617687225,
      -0.02988094836473465,
      -0.041288476437330246,
      0.09092382341623306,
      -0.09984555840492249,
      0.03501161187887192,
      -0.01968667283654213,
      0.021821346133947372,
      0.037033990025520325,
      0.01362650003284216,
      0.009913681074976921,
      0.03670791536569595,
      0.046630192548036575,
      -0.0011280023027211428,
      -0.0422513484954834,
      -0.05878278240561485,
      -0.07900437712669373,
      0.002791964914649725,
      0.022132491692900658,
      0.03277864307165146,
      0.03789762780070305,
      0.06985943019390106,
      0.052215855568647385,
      -0.09315599501132965,
      0.05092927813529968,
      0.008693148382008076,
      -0.015986382961273193,
      -0.04907764866948128,
      -0.028599878773093224,
      0.026841362938284874,
      0.08852249383926392,
      0.007677154149860144,
      -0.00904620997607708,
      0.02905023656785488,
      -0.0056103309616446495,
      0.00727011589333415,
      -0.010819449089467525,
      0.10282771289348602,
      0.008554685860872269,
      -0.0188591331243515,
      0.026908578351140022,
      -0.0008319740300066769,
      -0.012702960520982742,
      -0.06206437572836876,
      -0.043817926198244095,
      0.05741240456700325,
      -0.014104750007390976,
      0.003606487298384309,
      -0.012119274586439133,
      0.0614578016102314,
      0.022523455321788788,
      0.03296003118157387,
      0.08123043179512024,
      -0.01867767795920372,
      0.031696613878011703,
      0.015554445795714855,
      -0.004546022042632103,
      0.0068267653696238995,
      0.013539579696953297,
      -0.07760075479745865,
      -0.08583865314722061,
      -0.029693149030208588,
      -0.0002911138581112027,
      -0.05197722464799881,
      -0.02840174362063408,
      0.016088495030999184,
      -0.042447082698345184,
      0.045319441705942154,
      0.04878238961100578,
      0.046414006501436234,
      0.001970946090295911,
      -0.03036896325647831,
      0.03148234263062477,
      -0.02087266556918621,
      -0.044034089893102646,
      0.05681270733475685,
      -0.00750823225826025,
      0.03261510282754898,
      -0.01791383884847164,
      0.030912358313798904,
      -0.012900540605187416,
      0.059512440115213394,
      -0.03314780443906784,
      -0.06278242915868759,
      -0.01310073770582676,
      0.022828131914138794,
      0.02872198447585106,
      -0.08173204958438873,
      -0.0811619758605957,
      -0.05523206293582916,
      0.058646269142627716,
      0.039518628269433975,
      -0.03504225239157677,
      0.035415712743997574,
      -0.10000046342611313,
      -0.09164522588253021,
      -0.04784058779478073,
      -0.001517416792921722,
      0.028529049828648567,
      0.039309337735176086,
      -0.00149855378549546,
      -0.019990691915154457,
      0.03489060327410698,
      -0.09255387634038925,
      0.05165278539061546,
      0.03712250664830208,
      -0.026553895324468613,
      0.07164926081895828,
      0.04686540365219116,
      0.007596025709062815,
      -0.11321747303009033,
      -0.086361363530159,
      -0.04119817540049553,
      0.030241139233112335,
      -0.022126443684101105,
      -0.0455506332218647,
      0.01887543871998787,
      0.0018765988061204553,
      -0.009052854031324387,
      -0.07155659794807434,
      0.03381916880607605,
      0.0072333673015236855,
      -0.07272098958492279,
      -0.030958544462919235,
      -0.0001923008676385507,
      0.016961289569735527,
      -0.028707746416330338,
      -0.06688553094863892,
      -0.07798080891370773,
      -0.0037950153928250074,
      -0.003349300939589739,
      0.03659483417868614,
      -0.00754561647772789,
      -0.013119949959218502,
      -0.052098050713539124,
      -0.04001210629940033,
      0.017283368855714798,
      0.0004426100349519402,
      -0.04729954153299332,
      0.057335130870342255,
      0.004991362802684307,
      0.040849074721336365,
      -0.0809151902794838,
      -0.08919640630483627,
      0.09772952646017075,
      0.014299027621746063,
      -0.002312081167474389,
      -0.03619606792926788,
      0.04538571462035179,
      0.0033053031656891108,
      -0.03547477722167969,
      0.04428679123520851,
      0.0509171262383461,
      0.03519425168633461,
      0.041252054274082184,
      -0.10236149281263351,
      0.08585972338914871,
      -0.053617604076862335,
      -0.03218848258256912,
      0.014187456108629704,
      -0.014927172102034092,
      -0.03774302825331688,
      0.017627056688070297,
      0.007196121383458376,
      -0.0973195880651474,
      0.003884644014760852,
      0.04847165197134018,
      0.10743916779756546,
      -0.016648804768919945,
      0.05202101543545723,
      -0.02276352234184742,
      -0.009607862681150436,
      0.011174431070685387,
      -0.007568010129034519,
      0.03694673627614975,
      0.058682262897491455,
      0.005217875819653273,
      -0.09190290421247482,
      0.033998023718595505,
      0.05492527782917023,
      -0.009011193178594112,
      0.07441052049398422,
      -0.035882823169231415,
      0.03800597041845322,
      0.03885534033179283,
      0.044073689728975296,
      -0.01359548419713974,
      0.010465027764439583,
      -0.003414766862988472,
      -0.05828780308365822,
      0.10714132338762283,
      -0.03829830139875412,
      -0.03603469580411911,
      0.07223305851221085,
      0.006416631862521172,
      -0.018230024725198746,
      -0.09618823230266571,
      0.005186454392969608,
      -0.041807495057582855,
      -0.030202627182006836,
      -0.0850355327129364,
      0.050546176731586456,
      0.008645636960864067,
      0.03714911639690399,
      -0.10652687400579453,
      -0.0034914922434836626,
      0.010057114064693451,
      0.06164274364709854,
      -0.018037229776382446,
      0.062267452478408813,
      0.04449693113565445,
      -0.019604520872235298,
      -0.06557463109493256,
      -0.066316619515419,
      -0.08012104034423828,
      -0.009373876266181469,
      -0.01962118409574032,
      0.00792730413377285,
      -0.007410556077957153,
      0.02018837444484234,
      -0.058312591165304184,
      0.06473379582166672,
      0.08518297225236893,
      0.05151880905032158,
      -0.016836220398545265,
      0.053385209292173386,
      0.003255513496696949,
      0.010005654767155647,
      -0.0031464716885238886,
      -0.0224495567381382,
      0.021642649546265602,
      0.040102601051330566,
      -0.016625214368104935,
      -0.042493440210819244,
      -0.07669224590063095,
      0.0009991570841521025,
      0.016365835443139076,
      0.07782040536403656,
      -0.0016984494868665934,
      -0.009969514794647694,
      0.004418597556650639,
      0.014688683673739433,
      0.04571045562624931,
      -0.0870145857334137,
      0.020655643194913864,
      -0.02868812344968319,
      8.966340828919783e-05,
      -0.021365884691476822,
      0.0007137121865525842,
      0.019825803115963936,
      -0.012463493272662163,
      -0.11196767538785934,
      0.058557771146297455,
      0.0275969710201025,
      0.00801209919154644,
      0.08643634617328644,
      -0.014183851890265942,
      -0.12223759293556213,
      0.040315672755241394,
      -0.04648233577609062,
      -0.01730361022055149,
      -0.10515992343425751,
      -0.04812942445278168,
      -0.0630652979016304,
      -0.06635089218616486,
      -0.05365602672100067,
      -0.01947450451552868,
      -0.015207086689770222,
      -0.029799070209264755,
      0.08267898857593536,
      -0.05874359607696533,
      -0.014074153266847134,
      0.012003634124994278,
      0.04915637522935867,
      -0.06953534483909607,
      -0.01618751883506775,
      0.06382773816585541,
      0.024622570723295212,
      0.019322510808706284,
      0.060854386538267136,
      -0.02706117369234562,
      0.07698176801204681,
      -0.010732566937804222,
      -0.05127057060599327,
      0.04166315123438835,
      0.009457760490477085,
      -0.012851422652602196,
      -0.05668230354785919,
      0.025701576843857765,
      0.01057092659175396,
      -0.0030952265951782465,
      0.0602748841047287,
      -0.016426920890808105,
      -0.09414948523044586,
      -0.06072231009602547,
      -0.059335291385650635,
      0.009295563213527203,
      0.024386350065469742,
      -0.08769242465496063,
      0.05122780427336693,
      0.05490003898739815,
      -0.023295024409890175,
      -0.06078613921999931,
      -0.011953654699027538,
      -0.012672238983213902,
      0.023849692195653915,
      0.012961795553565025,
      -0.056913524866104126,
      -0.030040372163057327,
      -0.026615753769874573,
      0.04720205068588257,
      -0.012616222724318504,
      -0.06662556529045105,
      -0.03601011633872986,
      0.07903357595205307,
      0.048559293150901794,
      0.021786179393529892,
      -0.035011641681194305,
      -0.005136603955179453,
      0.001596195506863296,
      0.0653613731265068,
      0.017011476680636406,
      0.04236399382352829,
      -0.04237949103116989
    ],
    [
      0.01820722222328186,
      0.008969200775027275,
      -0.08082190901041031,
      0.05764519423246384,
      -0.024327799677848816,
      -0.009193569421768188,
      0.056153345853090286,
      -0.012011125683784485,
      0.005845305044203997,
      0.0012518343282863498,
      -0.0052228826098144054,
      0.07023905217647552,
      -0.050666674971580505,
      0.040676310658454895,
      -0.019296428188681602,
      -0.034203413873910904,
      0.026687726378440857,
      -0.016895968466997147,
      -0.051020536571741104,
      0.031582772731781006,
      -0.03304677829146385,
      0.05695558339357376,
      0.0005920715630054474,
      -0.027224136516451836,
      0.01438106782734394,
      -0.05229174345731735,
      -0.010556957684457302,
      0.040909506380558014,
      -0.016479497775435448,
      0.014939713291823864,
      -0.011118760332465172,
      -0.07001475244760513,
      0.01975955255329609,
      -0.011734557338058949,
      0.057208992540836334,
      -0.09052637964487076,
      0.01828969642519951,
      -0.05465015023946762,
      -0.10330404341220856,
      0.02881380170583725,
      -0.06580627709627151,
      -0.053421616554260254,
      0.0215173177421093,
      0.08747101575136185,
      -0.012512429617345333,
      -0.0021203879732638597,
      -0.09580719470977783,
      0.0556287057697773,
      -0.03969130665063858,
      -0.07572433352470398,
      0.07226479053497314,
      0.022162042558193207,
      0.07064657658338547,
      -0.06366670876741409,
      0.09354173392057419,
      0.030401403084397316,
      -0.017494529485702515,
      -0.06585833430290222,
      0.007460484281182289,
      0.04374556615948677,
      -0.07135241478681564,
      -0.013974293135106564,
      -0.0028294462244957685,
      -0.03109794668853283,
      -0.05010486766695976,
      -0.049502983689308167,
      0.08752270042896271,
      0.0008553520310670137,
      0.04307301342487335,
      0.0004041704523842782,
      -0.011556628160178661,
      -0.09836704283952713,
      -0.0444994755089283,
      -0.02492287941277027,
      0.022022617980837822,
      -0.08665885776281357,
      0.02321295626461506,
      -0.014665535651147366,
      -0.011044289916753769,
      0.007431069388985634,
      0.08002716302871704,
      -0.026005366817116737,
      0.07295405119657516,
      0.10439757257699966,
      0.004547936376184225,
      -0.018399454653263092,
      0.07613719999790192,
      -0.1199788823723793,
      -0.07054276019334793,
      -0.061538226902484894,
      -0.0003612931468524039,
      0.06485290080308914,
      0.03350963070988655,
      -0.017407668754458427,
      0.11416343599557877,
      0.013185779564082623,
      -0.01420388463884592,
      0.09409990906715393,
      0.08988644182682037,
      0.13231302797794342,
      -0.034812040627002716,
      -0.06889171153306961,
      -0.04277126118540764,
      0.010253878310322762,
      -0.04210517928004265,
      0.04742065817117691,
      0.021815944463014603,
      0.019551748409867287,
      -0.01821371167898178,
      0.052737168967723846,
      -0.009917798452079296,
      -0.033776670694351196,
      -0.004042945336550474,
      0.03356175124645233,
      0.03840089216828346,
      -0.0642351284623146,
      0.03770383819937706,
      0.0731276199221611,
      -0.07338108867406845,
      -0.004115625284612179,
      0.0517156682908535,
      -0.022572241723537445,
      0.011827578768134117,
      0.1543496996164322,
      0.00750700244680047,
      -0.02201266773045063,
      0.04837699607014656,
      0.00957424659281969,
      0.012270401231944561,
      0.07413600385189056,
      0.014493629336357117,
      0.050144538283348083,
      -0.09062512218952179,
      -0.01799009181559086,
      0.031446706503629684,
      0.09922526031732559,
      0.022477034479379654,
      -0.05879221856594086,
      -0.047103215008974075,
      0.03498946875333786,
      0.0749850645661354,
      -0.05540015548467636,
      0.019062072038650513,
      0.05266474187374115,
      0.04903937876224518,
      -0.016073569655418396,
      0.08586057275533676,
      -0.026044504716992378,
      -0.012315706349909306,
      0.06444742530584335,
      -0.045379191637039185,
      0.05389685183763504,
      0.03348517417907715,
      -0.005696098320186138,
      -0.06909191608428955,
      0.03423353284597397,
      0.08307871967554092,
      -0.0791107714176178,
      -0.011449772864580154,
      -0.027611348778009415,
      0.1357082575559616,
      -0.02092229761183262,
      -0.0541050024330616,
      -0.0017839808715507388,
      -0.018192918971180916,
      0.0005655954009853303,
      -0.0014248264487832785,
      0.0003911629319190979,
      0.0627148300409317,
      0.004891626536846161,
      -0.015603759326040745,
      -0.030654538422822952,
      -0.003772679017856717,
      0.037478141486644745,
      -0.057357851415872574,
      -0.020246662199497223,
      -0.022030731663107872,
      -0.0021897682454437017,
      0.004542568698525429,
      -0.008776451461017132,
      -0.038514748215675354,
      0.0022120901849120855,
      0.030419910326600075,
      0.04664747789502144,
      -0.016772435978055,
      -0.046313390135765076,
      0.0184908639639616,
      0.0023825718089938164,
      0.04488445073366165,
      0.14011931419372559,
      -0.032768115401268005,
      0.08577149361371994,
      -0.0016635028878226876,
      -0.048860128968954086,
      0.035275258123874664,
      0.005316249560564756,
      -0.04217715933918953,
      0.014817720279097557,
      0.0548870749771595,
      0.010289236903190613,
      -0.012398721650242805,
      0.07116875797510147,
      0.08575623482465744,
      -0.009155225940048695,
      -0.04017103463411331,
      0.002408034633845091,
      -0.017732882872223854,
      0.015648841857910156,
      0.025185348466038704,
      -0.04229661449790001,
      0.02945711463689804,
      -0.06515908986330032,
      -0.007488802075386047,
      -0.03427128866314888,
      0.0003379663685336709,
      -0.022629743441939354,
      0.05609075352549553,
      0.038686998188495636,
      0.042126089334487915,
      0.03143627941608429,
      -0.0020583593286573887,
      0.022658100351691246,
      0.07727988809347153,
      0.07711634039878845,
      0.059679847210645676,
      0.024786904454231262,
      -0.06219504773616791,
      -0.013072175905108452,
      0.06135782599449158,
      0.00929680373519659,
      0.006295967381447554,
      0.0190822035074234,
      0.027355069294571877,
      0.023716343566775322,
      0.025860706344246864,
      0.06032821163535118,
      -0.08130849897861481,
      0.029586931690573692,
      -0.05763355642557144,
      0.02685132436454296,
      -0.03284212201833725,
      0.03120434656739235,
      0.028707413002848625,
      -0.01261983998119831,
      -0.04619291052222252,
      0.008764900267124176,
      0.047070086002349854,
      -0.013879386708140373,
      0.0019979123026132584,
      0.046094875782728195,
      0.04537244513630867,
      -0.090822234749794,
      0.01565287634730339,
      0.014003405347466469,
      -0.10234013199806213,
      -0.018545255064964294,
      0.011011465452611446,
      -0.03290187940001488,
      -0.005869476590305567,
      0.03736712411046028,
      0.033742278814315796,
      0.040283024311065674,
      0.0693853572010994,
      0.03098931349813938,
      0.0538724847137928,
      0.02728436514735222,
      -0.05925062671303749,
      0.0032215507235378027,
      -0.08479654788970947,
      -0.007108480669558048,
      -0.030020492151379585,
      -0.0024436686653643847,
      0.01239299587905407,
      -0.030493875965476036,
      0.01052998285740614,
      0.04609157517552376,
      -0.08128911256790161,
      0.04534146562218666,
      -0.005892775021493435,
      -0.023777484893798828,
      -0.054681021720170975,
      0.06355613470077515,
      0.053597453981637955,
      -0.01319064199924469,
      0.017506517469882965,
      -0.00046221358934417367,
      0.05758170410990715,
      0.006277238950133324,
      -0.05433989688754082,
      -0.015052254311740398,
      -0.050855863839387894,
      0.005847687367349863,
      -0.005132107529789209,
      0.025120476260781288,
      -0.02269444614648819,
      0.04195225611329079,
      0.05263626575469971,
      -0.01171071082353592,
      0.03018099255859852,
      -0.022249916568398476,
      -0.027552353218197823,
      -0.010724957101047039,
      0.009297453798353672,
      -0.0518568716943264,
      0.06961777806282043,
      -0.1195811852812767,
      0.018046079203486443,
      0.05728105828166008,
      0.037546563893556595,
      0.019364789128303528,
      0.040101829916238785,
      0.04266398400068283,
      0.024302955716848373,
      0.04647271707653999,
      0.07291893661022186,
      0.027017418295145035,
      0.0007284224266186357,
      0.04424561932682991,
      0.03480870649218559,
      -0.04130835831165314,
      0.004878033418208361,
      -0.007229948882013559,
      0.03234971687197685,
      0.027322575449943542,
      -0.025189202278852463,
      -0.03880402445793152,
      0.03818826004862785,
      -0.022991502657532692,
      -0.023804662749171257,
      0.07012829929590225,
      -0.06636237353086472,
      0.01688993163406849,
      0.049816444516181946,
      -0.038418691605329514,
      0.05864673852920532,
      0.008038431406021118,
      -0.07262635976076126,
      0.0057427226565778255,
      -0.02822503261268139,
      -0.0011312221176922321,
      -0.007686959579586983,
      -0.06694208830595016,
      -0.021971378475427628,
      -0.01777544803917408,
      -0.03653046861290932,
      -0.0025582010857760906,
      -0.014561646617949009,
      0.05053447559475899,
      -0.022069452330470085,
      -0.03600599989295006,
      -0.03483590483665466,
      0.019782746210694313,
      -0.03527456521987915,
      -0.01845911704003811,
      -0.04021376371383667,
      -0.05109405890107155,
      0.02307567000389099,
      -0.0020374679006636143,
      -0.009657945483922958,
      -0.04772162064909935,
      0.023450389504432678,
      -0.015106869861483574,
      0.04156618192791939,
      0.014178743585944176,
      0.0011827684938907623,
      0.09486789256334305,
      0.11760717630386353,
      -0.02690337970852852,
      0.006560096051543951,
      -0.07604579627513885,
      0.04017576575279236,
      0.08776791393756866,
      0.059017617255449295,
      0.006473959423601627,
      -0.044794514775276184,
      -0.00722463708370924,
      -0.006497998256236315,
      -0.08423996716737747,
      -0.042452529072761536,
      0.06863118708133698,
      -0.054891400039196014,
      0.037644557654857635,
      0.014265792444348335,
      -0.06964141130447388,
      -0.036112602800130844,
      0.08763246238231659,
      0.024022169411182404,
      0.08265241980552673,
      0.015580935403704643,
      0.049240387976169586,
      -0.02930189110338688,
      0.03759581595659256,
      0.08079706877470016,
      -0.03567695617675781,
      -0.02741236798465252,
      0.05403723567724228,
      -0.015123908407986164,
      0.010137178935110569,
      -0.05853317677974701,
      -0.007203092332929373,
      0.030690040439367294,
      -0.006157886236906052,
      -0.054754722863435745,
      -0.020671524107456207,
      -0.06250720471143723,
      -0.051511943340301514,
      -0.0074759903363883495,
      -0.06150022894144058,
      -0.06355998665094376,
      0.036327291280031204,
      -0.020913224667310715,
      0.06067968159914017,
      0.012683355249464512,
      -0.026387639343738556,
      0.07810065150260925,
      0.05646162107586861,
      -0.004536381457000971,
      -0.05910339951515198,
      0.05376240611076355,
      -0.08377530425786972,
      -0.021640710532665253,
      0.010334718972444534,
      -0.056013140827417374,
      -0.03794543445110321,
      0.12041948735713959,
      -0.04458032175898552,
      0.012534267269074917,
      -0.023263366892933846,
      0.0942152589559555,
      -0.04346304386854172,
      -0.1099812239408493,
      -0.004120727069675922,
      0.0013012842973694205,
      0.025081705302000046,
      0.03952384740114212,
      0.057472120970487595,
      0.02807541750371456,
      -0.013833096250891685,
      0.09592133015394211,
      0.06328953057527542,
      -0.08658066391944885,
      -0.12128131836652756,
      0.0227725338190794,
      0.08955038338899612,
      0.02069857344031334,
      -0.01584366336464882,
      -0.024496152997016907,
      0.011548928916454315,
      0.060059867799282074,
      0.026278037577867508,
      -0.006822089198976755,
      0.014321007765829563,
      -0.012422727420926094,
      0.015956049785017967,
      0.025390183553099632,
      -0.020024878904223442,
      0.004251549486070871,
      -0.03957553952932358,
      0.00929341185837984,
      0.03598729893565178,
      0.008405307307839394,
      -0.03468408063054085,
      0.05378229171037674,
      -0.05129513889551163,
      0.010097439400851727,
      -0.047083258628845215,
      0.01160573959350586,
      0.10434320569038391,
      0.022414498031139374,
      0.030834579840302467,
      -0.019218377768993378,
      0.002237061969935894,
      0.004581628367304802,
      -0.001308758626691997,
      -0.035733941942453384,
      0.008247451856732368,
      0.017171790823340416,
      0.029800547286868095,
      0.0011938526295125484,
      0.039760176092386246,
      0.0038454898167401552,
      0.016874238848686218,
      -0.0037201852537691593,
      -0.04518403112888336,
      0.005458406172692776,
      0.024894023314118385,
      0.0031566007528454065,
      -0.053594980388879776,
      -0.002948169130831957,
      -0.008615054190158844,
      -0.04762428626418114,
      0.032342128455638885,
      -0.04352276772260666,
      -0.033936042338609695,
      0.007118940819054842,
      -0.03582865372300148,
      -0.07397211343050003,
      -0.02513037621974945,
      -0.006123659200966358,
      0.02256837673485279,
      -0.029855767264962196,
      0.0012837063986808062,
      -0.0420842170715332
    ],
    [
      0.02955879084765911,
      0.0008132999646477401,
      -0.001074798870831728,
      0.06310299783945084,
      -0.00929215457290411,
      0.052838295698165894,
      0.03955157846212387,
      0.07098275423049927,
      -0.008618559688329697,
      -0.01392362266778946,
      0.01092776469886303,
      -0.011204646900296211,
      0.005959719885140657,
      0.11183654516935349,
      -0.004107662010937929,
      -0.01564379781484604,
      0.02336985431611538,
      0.10855767130851746,
      -0.041789911687374115,
      -0.06564470380544662,
      0.07848933339118958,
      -0.04818236082792282,
      -0.05547040328383446,
      0.06707774102687836,
      0.11179943382740021,
      -0.025856299325823784,
      0.0011116364039480686,
      0.03427425026893616,
      0.029028307646512985,
      -0.008484398946166039,
      0.023272477090358734,
      -0.016452882438898087,
      -0.06549805402755737,
      -0.04983317479491234,
      0.024731425568461418,
      0.029008520767092705,
      0.007268783636391163,
      -0.031799428164958954,
      -0.05337047204375267,
      -0.016257258132100105,
      0.008110749535262585,
      -0.0179284755140543,
      0.013173514045774937,
      -0.018976449966430664,
      0.05951503664255142,
      -0.05441407859325409,
      -0.0456053726375103,
      -0.03615419566631317,
      -0.012264042161405087,
      -0.02489634044468403,
      0.026545865461230278,
      -0.00411537429317832,
      0.008798274211585522,
      0.03651190176606178,
      0.09299061447381973,
      0.06283759325742722,
      -0.011188971810042858,
      0.039030522108078,
      0.028959916904568672,
      0.008091628551483154,
      0.04443039745092392,
      -0.014237054623663425,
      -0.030524006113409996,
      0.03719126060605049,
      -0.020667297765612602,
      0.03875969350337982,
      0.030081719160079956,
      0.01198143232613802,
      -0.015790782868862152,
      -0.06967105716466904,
      0.013937888666987419,
      0.03384411334991455,
      -0.01563047245144844,
      -0.010114715434610844,
      0.03654098138213158,
      -0.06586378812789917,
      0.019293267279863358,
      0.07268966734409332,
      -0.030452866107225418,
      0.05088215693831444,
      -0.004572971258312464,
      -0.10843497514724731,
      -0.015432863496243954,
      0.026126578450202942,
      -0.040597524493932724,
      0.06454386562108994,
      -0.003689734498038888,
      -0.05782545730471611,
      -0.0414307601749897,
      0.07866058498620987,
      -0.06568549573421478,
      -0.03925109654664993,
      0.05344478413462639,
      0.01906927488744259,
      -0.008410624228417873,
      -0.009103706106543541,
      -0.014861518517136574,
      0.05051999166607857,
      0.039024122059345245,
      0.049692921340465546,
      0.022322596982121468,
      -0.03540941700339317,
      -0.05886579304933548,
      0.0025609126314520836,
      -0.004474518820643425,
      0.04508010670542717,
      -0.06706159561872482,
      0.054340895265340805,
      -0.006505480036139488,
      -0.029486868530511856,
      0.014647911302745342,
      -0.005820529535412788,
      0.027470149099826813,
      0.006949743255972862,
      -0.04095702990889549,
      0.0488802045583725,
      0.07098820060491562,
      0.023121489211916924,
      0.04759717360138893,
      0.03506990149617195,
      0.006689072586596012,
      -0.05507480353116989,
      -0.05439198389649391,
      -0.032818105071783066,
      0.0010788829531520605,
      -0.0364476814866066,
      -0.05816575139760971,
      -0.0101882079616189,
      -0.0478157140314579,
      -0.07520972192287445,
      -0.005963539704680443,
      -0.0008630779921077192,
      -0.0337597019970417,
      0.09722507745027542,
      0.04045077785849571,
      -0.06249565631151199,
      -0.009536217898130417,
      0.03668246790766716,
      0.0355246439576149,
      0.02317742258310318,
      0.09155026823282242,
      0.020909016951918602,
      0.03686627000570297,
      -0.025863345712423325,
      -0.0033455078955739737,
      -0.05176306888461113,
      -0.05895201489329338,
      -0.06283797323703766,
      -0.01055056694895029,
      0.038663845509290695,
      0.06246485561132431,
      0.022526634857058525,
      0.04773981496691704,
      0.06740465015172958,
      -0.0311829075217247,
      0.04380526766180992,
      0.061712026596069336,
      -0.012412922456860542,
      -0.0441024973988533,
      -0.037251438945531845,
      0.010568256489932537,
      0.08506152033805847,
      0.02642662636935711,
      0.035463254898786545,
      0.04141131415963173,
      0.055034175515174866,
      0.04490731656551361,
      -0.07153879106044769,
      0.04153404384851456,
      0.0005080253467895091,
      -0.019175425171852112,
      0.03693486750125885,
      0.0004990389570593834,
      0.04672883078455925,
      -0.014244680292904377,
      0.03562890738248825,
      0.012978173792362213,
      0.02584109827876091,
      0.039511844515800476,
      0.04581589624285698,
      -0.017880737781524658,
      0.0494246669113636,
      -0.004730158019810915,
      0.05553331971168518,
      -0.05482395738363266,
      0.002871304051950574,
      0.0829630047082901,
      -0.03440438210964203,
      -0.07518066465854645,
      0.07923554629087448,
      -0.031830888241529465,
      0.005925442557781935,
      -0.09580666571855545,
      -0.047248076647520065,
      -0.008398253470659256,
      -0.03859914094209671,
      0.011715329252183437,
      0.017022283747792244,
      -0.006995628587901592,
      0.036483678966760635,
      -0.014078579843044281,
      -0.006407011765986681,
      0.017789829522371292,
      -0.077644482254982,
      0.01349122915416956,
      0.04410186782479286,
      -0.018190594390034676,
      0.13259117305278778,
      0.05318085104227066,
      -0.02543606236577034,
      -0.04261656478047371,
      0.03013610653579235,
      -0.012315168976783752,
      -0.04356278106570244,
      -0.03847742825746536,
      -0.03596125915646553,
      -0.004623305983841419,
      -0.09450722485780716,
      0.015474175103008747,
      -0.043674636632204056,
      0.025751005858182907,
      9.474859689362347e-05,
      -0.03327381610870361,
      0.007834318093955517,
      -0.066774882376194,
      -0.003802448743954301,
      0.03780084475874901,
      -0.01996607705950737,
      0.037878360599279404,
      0.015846973285079002,
      -0.02986813895404339,
      -0.014469836838543415,
      0.03916287049651146,
      0.07401479035615921,
      0.04106048867106438,
      -0.020088909193873405,
      -0.11205495148897171,
      -0.001220844453200698,
      0.058411505073308945,
      0.09473085403442383,
      -0.110786572098732,
      -0.060237932950258255,
      0.013023016974329948,
      0.04059239849448204,
      0.01551844272762537,
      0.014385920017957687,
      0.09544439613819122,
      -0.02177802100777626,
      0.028698353096842766,
      -0.0383419506251812,
      0.05790011212229729,
      0.030771329998970032,
      0.028622670099139214,
      -0.0005676455912180245,
      -0.020429562777280807,
      0.03585357591509819,
      -0.01997932232916355,
      -0.021440301090478897,
      0.05955693498253822,
      0.07244204729795456,
      -0.0031742877326905727,
      -0.030718551948666573,
      0.10192429274320602,
      0.007113868836313486,
      0.0962853729724884,
      0.01956571824848652,
      -0.0003935657732654363,
      -0.0589824840426445,
      0.07171223312616348,
      -0.032677873969078064,
      -0.02212751843035221,
      -0.018300741910934448,
      0.06846556067466736,
      0.01836557127535343,
      -0.02581827901303768,
      0.034954287111759186,
      -0.044108591973781586,
      0.006583797745406628,
      -0.02069069817662239,
      0.0643862783908844,
      -0.016109323129057884,
      0.01634935662150383,
      0.032720305025577545,
      0.09703174233436584,
      0.014195424504578114,
      -0.11306905746459961,
      0.009873911738395691,
      -0.020200040191411972,
      -0.01737251505255699,
      0.04845920950174332,
      -0.07293860614299774,
      -0.01493155024945736,
      -0.050817277282476425,
      0.001699676620773971,
      -0.03799435496330261,
      0.04213406518101692,
      -0.007838312536478043,
      0.01486645545810461,
      0.03231692314147949,
      0.029058106243610382,
      0.013842239044606686,
      -0.022280432283878326,
      -0.03616411238908768,
      0.10201598703861237,
      -0.09107009321451187,
      -0.06712151318788528,
      0.030340081080794334,
      0.0026262213941663504,
      0.012027138844132423,
      0.017078280448913574,
      0.057906560599803925,
      -0.0335126556456089,
      0.01819799840450287,
      0.04335664212703705,
      0.015615873970091343,
      0.015389161184430122,
      0.01969640515744686,
      -0.003201739164069295,
      0.03626176342368126,
      -0.031041469424962997,
      -0.004172759596258402,
      0.023054329678416252,
      -0.0011639371514320374,
      0.01775730401277542,
      -0.02046646550297737,
      -0.02634158544242382,
      -0.01562601514160633,
      -0.017720788717269897,
      -0.04094343259930611,
      -0.00651960726827383,
      -0.04727143421769142,
      -0.021827032789587975,
      0.0034853217657655478,
      -0.008456890471279621,
      0.0025272062048316,
      -0.0026204476598650217,
      0.003730097785592079,
      0.06242278590798378,
      -0.019772499799728394,
      -0.019597668200731277,
      -0.014797418378293514,
      -0.008780022151768208,
      -0.035059068351984024,
      0.039467133581638336,
      -0.0008582723094150424,
      -0.034748367965221405,
      -0.06716292351484299,
      -0.05805172026157379,
      0.022292396053671837,
      -0.11695759743452072,
      -0.022630756720900536,
      0.02004847303032875,
      -0.013871376402676105,
      -0.11775632202625275,
      -0.008299369364976883,
      -0.031539157032966614,
      -0.0208483524620533,
      0.016223127022385597,
      0.008779187686741352,
      -0.07461750507354736,
      -0.07096610218286514,
      -0.05054178833961487,
      -0.03831568360328674,
      0.0954243540763855,
      -0.03953173756599426,
      -0.03382589668035507,
      0.05636383220553398,
      0.007460258901119232,
      -0.040587954223155975,
      0.03990896791219711,
      0.069662906229496,
      0.0017065320862457156,
      -0.037001051008701324,
      0.018760982900857925,
      0.03996745124459267,
      -0.012363229878246784,
      -0.00994353648275137,
      -0.04172428697347641,
      -0.044664204120635986,
      -0.025760885328054428,
      -0.04429275542497635,
      -0.009051780216395855,
      0.029138503596186638,
      0.03669566288590431,
      0.03654344007372856,
      -0.022214777767658234,
      -0.0313156433403492,
      -0.04158433526754379,
      0.026371421292424202,
      0.02708558551967144,
      -0.029118694365024567,
      0.0027358343359082937,
      0.06073104217648506,
      0.0023572477512061596,
      0.008252453058958054,
      0.030330795794725418,
      0.06563366949558258,
      0.030180230736732483,
      -0.022670892998576164,
      -0.048355478793382645,
      -0.01866212859749794,
      0.05322793126106262,
      -0.028819160535931587,
      -0.03347349911928177,
      -0.08539514243602753,
      0.046592142432928085,
      -0.0725095272064209,
      0.06090933829545975,
      -0.013621456921100616,
      0.0431559681892395,
      -0.03768790140748024,
      0.04315730184316635,
      -0.08491828292608261,
      -0.014920513145625591,
      -0.02084590122103691,
      0.04544248431921005,
      0.022898444905877113,
      0.000303268083371222,
      -0.015771055594086647,
      0.0045157261192798615,
      -0.04244941100478172,
      -0.02774083986878395,
      -0.04515623673796654,
      -0.017268523573875427,
      -0.0002738415787462145,
      -0.03697727620601654,
      0.06766322255134583,
      0.0005355984903872013,
      0.05646643042564392,
      -0.04303416609764099,
      0.08209975063800812,
      -0.024794381111860275,
      -0.04909078776836395,
      0.023582959547638893,
      -0.01572320982813835,
      0.015634622424840927,
      -0.061307650059461594,
      -0.006117468699812889,
      -0.033482495695352554,
      0.09528400748968124,
      0.0455411933362484,
      0.053230490535497665,
      -0.007807599380612373,
      0.036525361239910126,
      -0.013184593990445137,
      -0.03303151950240135,
      -0.008886822499334812,
      0.01964505761861801,
      -0.061295684427022934,
      0.0029038151260465384,
      0.013194394297897816,
      -0.03065105713903904,
      -0.05796312540769577,
      0.051883820444345474,
      0.06612922996282578,
      0.03363145515322685,
      -0.0011407756246626377,
      0.033364102244377136,
      -0.020981643348932266,
      0.008724719285964966,
      0.043841682374477386,
      -0.027531197294592857,
      0.009946651756763458,
      -0.053382549434900284,
      0.04452754929661751,
      -0.02965940535068512,
      -0.02060335874557495,
      0.027838706970214844,
      0.002333077834919095,
      -0.052184879779815674,
      0.051643066108226776,
      -0.0674346461892128,
      0.02052043378353119,
      -0.05217765271663666,
      0.03651450201869011,
      0.04718240350484848,
      -0.015648772940039635,
      -0.032815199345350266,
      -0.0764814242720604,
      0.009518560953438282,
      0.06763171404600143,
      -0.02327815815806389,
      0.033033259212970734,
      0.008142785169184208,
      -0.04726541414856911,
      -0.045467957854270935,
      -0.037854380905628204,
      -0.036920271813869476,
      0.04581502079963684,
      -0.037125688046216965,
      -0.00203511631116271,
      -0.004997230134904385,
      0.046403005719184875,
      0.03955870494246483,
      -0.007416379172354937,
      0.022224746644496918,
      -0.046427905559539795,
      0.05308572202920914,
      0.0014892179751768708,
      0.02187524177134037,
      -0.012014701962471008,
      -0.03841393068432808,
      -0.056294575333595276
    ],
    [
      -0.0021778568625450134,
      -0.03579968959093094,
      -0.08150651305913925,
      -0.05424628034234047,
      -0.03265402838587761,
      -0.0492466539144516,
      0.05340626463294029,
      0.02299821563065052,
      -0.015408691950142384,
      -0.021588150411844254,
      0.014193420298397541,
      0.08633580803871155,
      0.012200484983623028,
      -0.11728152632713318,
      0.013084136880934238,
      -0.05215785279870033,
      0.0763794481754303,
      0.06785670667886734,
      -0.03336772322654724,
      -0.03065769374370575,
      0.024665769189596176,
      -0.007304951082915068,
      0.06073373928666115,
      -0.044155705720186234,
      0.025691285729408264,
      0.05549965426325798,
      -0.03189878165721893,
      0.05265031382441521,
      -0.06238507479429245,
      0.033992912620306015,
      0.014290088787674904,
      -0.005888533312827349,
      -0.042382799088954926,
      0.07823637127876282,
      0.025895994156599045,
      0.023267541080713272,
      -0.03243830054998398,
      0.025609644129872322,
      -0.06371404230594635,
      0.0585143081843853,
      0.024804506450891495,
      0.06206841394305229,
      -0.034016966819763184,
      0.03674481064081192,
      -0.06368133425712585,
      -0.054668962955474854,
      -0.02080645225942135,
      0.07159852981567383,
      -0.00931266788393259,
      0.021702470257878304,
      -0.038196809589862823,
      0.030297037214040756,
      -0.00016384819173254073,
      0.005035164300352335,
      -0.03896035999059677,
      -0.014158936217427254,
      -0.028398742899298668,
      0.03944746032357216,
      0.023001980036497116,
      -0.05887379124760628,
      0.0034989365376532078,
      -0.07964115589857101,
      0.030099136754870415,
      0.06504014879465103,
      0.006698643323034048,
      -0.003805658081546426,
      -0.00938395131379366,
      0.012594115920364857,
      -0.022150414064526558,
      0.06614720076322556,
      -0.04549860581755638,
      -0.11049143970012665,
      0.004648294299840927,
      0.03371872752904892,
      0.05127374455332756,
      -0.07689086347818375,
      -0.00921205896884203,
      -0.0024067903868854046,
      -0.03267313167452812,
      0.011284155771136284,
      0.014683897607028484,
      0.04366776719689369,
      -0.013131017796695232,
      0.07597915828227997,
      0.0360468365252018,
      0.02032296173274517,
      0.10496499389410019,
      -0.07919090986251831,
      -0.013001174665987492,
      -0.04077655449509621,
      -0.036406416445970535,
      -0.08359973877668381,
      -0.043663326650857925,
      0.003636538051068783,
      -0.0026802457869052887,
      0.01904802769422531,
      0.08959821611642838,
      0.01776599884033203,
      -0.005358565598726273,
      -0.04145060479640961,
      -0.09870069473981857,
      -0.044879913330078125,
      0.03203212842345238,
      0.05262108892202377,
      0.038562558591365814,
      -0.030963996425271034,
      -0.024676362052559853,
      -0.020217662677168846,
      -0.02098054811358452,
      -0.09139887988567352,
      -0.05624103546142578,
      0.010868375189602375,
      0.01478565577417612,
      -0.01265252660959959,
      0.04248164966702461,
      0.0039993696846067905,
      0.06412896513938904,
      -0.05632149800658226,
      0.029572192579507828,
      0.05861807242035866,
      -0.009639906696975231,
      -0.040884677320718765,
      -0.030243750661611557,
      -0.07993662357330322,
      0.07357019931077957,
      0.03145752474665642,
      0.010398531332612038,
      -0.016776880249381065,
      -0.03932575508952141,
      0.01433358620852232,
      -0.01713430881500244,
      0.07772839814424515,
      0.13436198234558105,
      0.01156544778496027,
      0.007838890887796879,
      0.015134206041693687,
      -0.008817397989332676,
      0.0051613058894872665,
      0.10775396972894669,
      -0.005997734144330025,
      -0.01237714383751154,
      -0.05543189495801926,
      -0.04277445748448372,
      -0.011318898759782314,
      0.014504268765449524,
      0.010459410026669502,
      -0.05966123566031456,
      0.009897721000015736,
      0.023579243570566177,
      0.058649297803640366,
      -0.016322145238518715,
      0.02672336995601654,
      0.1300145834684372,
      0.008480251766741276,
      -0.06767303496599197,
      -0.021328886970877647,
      -0.014972670935094357,
      -0.03384087607264519,
      -0.017740752547979355,
      -0.0341217927634716,
      0.00646371440961957,
      -0.038227543234825134,
      0.015467742457985878,
      -0.012106380425393581,
      -0.08903423696756363,
      -0.002581323031336069,
      0.035843249410390854,
      0.021902676671743393,
      -0.009188714437186718,
      -0.04394471272826195,
      -0.0628378689289093,
      0.10427404195070267,
      -0.012309860438108444,
      -0.06657274812459946,
      -0.03361286222934723,
      0.026284141466021538,
      0.07181395590305328,
      0.09974750131368637,
      0.0030106455087661743,
      0.0679091289639473,
      0.014074879698455334,
      -0.028015514835715294,
      -0.03493019565939903,
      0.016250845044851303,
      -0.018855666741728783,
      0.007256277371197939,
      -0.05779462680220604,
      -0.06746842712163925,
      -0.003156882245093584,
      -0.012995847500860691,
      -0.06271108239889145,
      0.07806848734617233,
      -0.0889081284403801,
      0.04693002998828888,
      0.02005893923342228,
      -0.06799875944852829,
      -0.044405750930309296,
      0.034234799444675446,
      0.09628244489431381,
      0.03324354812502861,
      -0.1021542027592659,
      -0.11839497089385986,
      -0.016493773087859154,
      -0.006537511013448238,
      -0.023064376786351204,
      0.0489170216023922,
      -0.011314966715872288,
      0.01753118820488453,
      -0.03279822692275047,
      0.002569101518020034,
      0.03171219304203987,
      0.010732749477028847,
      -0.09815750271081924,
      0.06743619590997696,
      0.014671934768557549,
      0.08387182652950287,
      -0.025005657225847244,
      0.040785763412714005,
      0.029765872284770012,
      0.02199202962219715,
      0.0038472393061965704,
      0.017685046419501305,
      -0.08448724448680878,
      0.0107735525816679,
      -0.01724262535572052,
      -0.07617148756980896,
      0.021356450393795967,
      -0.02263410948216915,
      0.023213839158415794,
      0.002800782211124897,
      0.021741623058915138,
      -0.0260817501693964,
      0.007146412972360849,
      -0.0901123583316803,
      0.0287043284624815,
      -0.03395276889204979,
      0.11380430310964584,
      0.011099165305495262,
      -0.09556969255208969,
      -0.010101075284183025,
      0.005445003509521484,
      0.017215067520737648,
      -0.014731977134943008,
      -0.01466774195432663,
      -0.040480922907590866,
      -0.1406918466091156,
      -0.07563938200473785,
      0.01740887761116028,
      -0.11016861349344254,
      -0.02546944096684456,
      0.018004707992076874,
      -0.02022266574203968,
      0.0006906031630933285,
      0.01724746823310852,
      0.022544313222169876,
      0.01866777613759041,
      -0.02005763165652752,
      -0.014630268327891827,
      0.0427563339471817,
      -0.009659919887781143,
      0.007464596536010504,
      -0.0006704418337903917,
      -0.05418947711586952,
      0.04095461219549179,
      0.07234463095664978,
      -0.01954464614391327,
      -0.008760317228734493,
      -0.06638936698436737,
      -0.009953395463526249,
      0.017837820574641228,
      -0.03539806604385376,
      0.029146339744329453,
      0.03178393840789795,
      0.01839343272149563,
      -0.021547168493270874,
      -0.02528003789484501,
      -0.0013720197603106499,
      0.006128567736595869,
      0.036577258259058,
      -0.01921871304512024,
      -0.0930328518152237,
      0.07189399749040604,
      0.00573769211769104,
      0.046194858849048615,
      -0.04507208988070488,
      -0.012388001196086407,
      -0.05780772119760513,
      -0.0030619576573371887,
      -0.0022909718099981546,
      0.024264808744192123,
      -0.009783045388758183,
      0.004026236478239298,
      0.030225597321987152,
      -0.033279359340667725,
      -0.05066307634115219,
      0.020581554621458054,
      0.022321242839097977,
      -0.0576440766453743,
      0.013559583574533463,
      -0.011946914717555046,
      -0.007467892020940781,
      -0.05123447626829147,
      0.04569973424077034,
      -0.0822797566652298,
      0.0052742911502718925,
      0.01038165669888258,
      0.041035644710063934,
      0.003840786637738347,
      -0.0034279862884432077,
      -0.02565031684935093,
      0.004997664596885443,
      -0.06108774617314339,
      0.02875816635787487,
      0.025955097749829292,
      -0.0148936090990901,
      0.08823677152395248,
      0.0770178735256195,
      -0.006280838046222925,
      3.452790406299755e-05,
      -0.0318484902381897,
      0.006680043879896402,
      -0.025112926959991455,
      0.031912077218294144,
      0.003321520984172821,
      -0.026431968435645103,
      0.0678330510854721,
      0.05280580744147301,
      0.018312394618988037,
      -0.047224536538124084,
      -0.03458527848124504,
      -0.09137532114982605,
      0.004918066319078207,
      -0.07536201924085617,
      -0.009226037189364433,
      0.032398756593465805,
      -0.01346002146601677,
      0.007140222005546093,
      -0.026851901784539223,
      -0.037145890295505524,
      -0.032717254012823105,
      0.025670846924185753,
      0.032926276326179504,
      0.060317348688840866,
      -0.03217518702149391,
      -0.08243604749441147,
      -0.1028171181678772,
      0.012530231848359108,
      -0.046940382570028305,
      0.004307060036808252,
      -0.018178053200244904,
      0.10096332430839539,
      0.006524625699967146,
      -0.007602398749440908,
      -0.01803375408053398,
      0.009275441989302635,
      0.009319912642240524,
      0.06954991072416306,
      -0.028083916753530502,
      -0.0028198654763400555,
      -0.003139493754133582,
      0.07941097021102905,
      -0.08403909206390381,
      -0.011208037845790386,
      -0.014423150569200516,
      0.09119158983230591,
      0.016201118007302284,
      -0.007743256166577339,
      -0.024882430210709572,
      0.02871229499578476,
      0.06445445120334625,
      0.024097904562950134,
      0.08375691622495651,
      0.005456456448882818,
      -0.04151424020528793,
      0.014566767029464245,
      0.0966852679848671,
      -0.020092524588108063,
      -0.036528486758470535,
      0.02018102817237377,
      0.0007332944660447538,
      -0.015751976519823074,
      0.06651266664266586,
      -0.04040789231657982,
      0.04560578987002373,
      0.01637965813279152,
      -0.031611569225788116,
      0.050690360367298126,
      -0.05721665546298027,
      0.06973358243703842,
      -0.07501484453678131,
      0.03144481033086777,
      0.008784702979028225,
      -0.02392829768359661,
      -0.08087613433599472,
      0.07759226113557816,
      0.024214094504714012,
      0.04523032531142235,
      -0.0798974260687828,
      -0.019834494218230247,
      0.007676839828491211,
      -0.01461644098162651,
      -0.0690067857503891,
      0.008540892042219639,
      -0.019558411091566086,
      -0.030712340027093887,
      -0.05394593998789787,
      0.02245095744729042,
      0.07694969326257706,
      0.015431917272508144,
      -0.013755234889686108,
      0.04611526429653168,
      -0.020036980509757996,
      0.02003888040781021,
      0.041872985661029816,
      -0.00688628014177084,
      -0.04036664217710495,
      -0.014030061662197113,
      -0.04758402332663536,
      -0.032004017382860184,
      0.0058654239401221275,
      0.07411117106676102,
      0.03053267113864422,
      -0.007893353700637817,
      0.01609530858695507,
      -0.007401077542454004,
      -0.0017473017796874046,
      0.08456704765558243,
      0.04214112460613251,
      -0.022682761773467064,
      0.05433857440948486,
      0.005809351336210966,
      0.05756548419594765,
      -0.08494830876588821,
      0.0642455667257309,
      0.038141362369060516,
      0.01259955856949091,
      0.026046350598335266,
      0.005017447751015425,
      -0.03396805003285408,
      0.018467409536242485,
      -0.0012865732423961163,
      0.013349898159503937,
      0.01348068006336689,
      0.04905654117465019,
      0.02092721313238144,
      -0.03177366033196449,
      0.009116996079683304,
      -0.10982248932123184,
      -0.07315096259117126,
      -0.021676620468497276,
      -0.004103389568626881,
      0.07800698280334473,
      -0.050198715180158615,
      0.0029076235368847847,
      -0.008941679261624813,
      0.015719832852482796,
      0.051173239946365356,
      -0.030843529850244522,
      -0.052498988807201385,
      -0.035821445286273956,
      0.053455524146556854,
      -0.034357424825429916,
      0.04516660422086716,
      -0.04267098382115364,
      -0.008573343977332115,
      -0.010691454634070396,
      -0.06346099823713303,
      0.007069257088005543,
      -0.043546970933675766,
      -0.027786176651716232,
      0.0247960165143013,
      -0.043526433408260345,
      -0.018128782510757446,
      -0.047347214072942734,
      -0.0840207114815712,
      0.05912046134471893,
      -0.07870689034461975,
      0.04442864656448364,
      0.027619807049632072,
      -0.041905760765075684,
      0.03091682307422161,
      0.05730237439274788,
      0.0015632433351129293,
      0.18246148526668549,
      -0.06607124209403992,
      0.031045803800225258,
      0.05319821834564209,
      0.013883386738598347,
      0.043521393090486526,
      0.02403387799859047,
      0.06377534568309784,
      0.018085286021232605,
      0.007434250321239233,
      0.007275665178894997,
      0.0351266972720623,
      0.0533737875521183,
      -0.06962553411722183,
      0.012820436619222164,
      0.014695990830659866,
      -0.047320131212472916,
      -0.038690969347953796,
      0.01502581499516964,
      -0.02717195823788643
    ],
    [
      -0.02622099407017231,
      -0.09078700095415115,
      0.028788907453417778,
      0.08981861174106598,
      -0.03939708322286606,
      0.013488966971635818,
      0.020382069051265717,
      -0.017430176958441734,
      0.029800470918416977,
      0.0007533467141911387,
      -0.02719690464437008,
      -0.018465619534254074,
      0.015552830882370472,
      -0.020567363128066063,
      0.06452777981758118,
      -0.043909311294555664,
      -0.04064219817519188,
      0.02792646363377571,
      0.012590151280164719,
      -0.04194740206003189,
      0.04848207160830498,
      0.04095480591058731,
      0.008255197666585445,
      0.0356990247964859,
      -0.06402932852506638,
      -0.014551584608852863,
      0.005181774962693453,
      -0.03566114604473114,
      0.04985081031918526,
      0.008277987129986286,
      -0.0013539302162826061,
      0.016781112179160118,
      0.013287200592458248,
      0.013168116100132465,
      0.012243092991411686,
      -0.011354257352650166,
      0.03522913157939911,
      0.01891445368528366,
      0.04643194377422333,
      0.06365998834371567,
      -0.0638231709599495,
      -0.02115287259221077,
      -0.04671689495444298,
      0.04664725065231323,
      0.049674466252326965,
      0.007717089727520943,
      0.0012258937349542975,
      -0.022038716822862625,
      -0.02643081173300743,
      -0.006587468087673187,
      -0.010914190672338009,
      0.015464617870748043,
      0.05177321285009384,
      -0.08271429687738419,
      -0.013754968531429768,
      -0.021282248198986053,
      -0.03146405145525932,
      -0.0075006731785833836,
      -0.03302536532282829,
      0.0057923211716115475,
      -0.004109072498977184,
      0.030675848945975304,
      0.025876855477690697,
      0.03500467166304588,
      0.017759064212441444,
      0.00816663634032011,
      -0.02723969705402851,
      0.0017711772816255689,
      -0.03717697784304619,
      0.10862276703119278,
      -0.002699265955016017,
      0.027905482798814774,
      -0.015493784099817276,
      0.0021218466572463512,
      0.020354457199573517,
      -0.0535724014043808,
      -0.017073074355721474,
      0.0207064226269722,
      -0.0221332348883152,
      0.014524787664413452,
      0.03235476091504097,
      -0.040419239550828934,
      0.0038752250839024782,
      0.06806730479001999,
      0.11925287544727325,
      -0.053376708179712296,
      0.042289454489946365,
      0.08139108121395111,
      -0.06160253286361694,
      -0.08010086417198181,
      -0.008466042578220367,
      -0.002952373819425702,
      -0.03714386001229286,
      0.014854011125862598,
      0.07386156171560287,
      -0.007914593443274498,
      -0.01199988555163145,
      -0.013018414378166199,
      -0.05913669988512993,
      0.01905021443963051,
      -0.028995903208851814,
      -0.030071472749114037,
      -0.021184921264648438,
      -0.04441596567630768,
      -0.007217586040496826,
      -8.965432789409533e-05,
      0.0453038215637207,
      0.06091531366109848,
      0.08013264089822769,
      -0.04860571399331093,
      0.0012095252750441432,
      -0.006027657072991133,
      -0.011892268434166908,
      -0.024467933923006058,
      0.0593111552298069,
      -0.0403304360806942,
      0.02684059366583824,
      -0.000820817775093019,
      0.00882047601044178,
      -0.0378379300236702,
      -0.012979554012417793,
      -0.007564282976090908,
      -0.14141292870044708,
      0.06588432192802429,
      0.027377355843782425,
      0.011403086595237255,
      -0.015682794153690338,
      -0.026864338666200638,
      -0.05162293091416359,
      -0.0026546295266598463,
      -0.02952621690928936,
      -0.011149096302688122,
      -0.029980380088090897,
      0.02874615415930748,
      0.05434849113225937,
      -0.07392941415309906,
      -0.04186258465051651,
      -0.012315081432461739,
      -0.028406349942088127,
      -0.0216839499771595,
      0.04602278396487236,
      -0.03333607688546181,
      -0.056733738631010056,
      0.037303291261196136,
      0.02360813319683075,
      -0.016939273104071617,
      0.058858055621385574,
      -0.002997636329382658,
      0.02269912138581276,
      -0.016859233379364014,
      -0.04556327313184738,
      0.015923989936709404,
      0.030413677915930748,
      0.0021253132726997137,
      -0.007926174439489841,
      -0.023072252050042152,
      0.008271790109574795,
      -0.07136278599500656,
      0.027558181434869766,
      0.07395303249359131,
      0.017899146303534508,
      0.08450479060411453,
      -0.001151322154328227,
      -0.0060215899720788,
      -0.03236915543675423,
      0.02272254228591919,
      -0.026062116026878357,
      -0.06017300486564636,
      0.046568259596824646,
      -0.04115743562579155,
      -0.04451227933168411,
      -0.04623132199048996,
      0.046054620295763016,
      0.09507524967193604,
      -0.0048104142770171165,
      0.05096547678112984,
      0.054561398923397064,
      0.00810077041387558,
      0.026718204841017723,
      -0.0329221710562706,
      -0.05747714638710022,
      0.014070948585867882,
      0.06794075667858124,
      0.024292511865496635,
      -0.005122367292642593,
      -0.030667901039123535,
      -0.06945672631263733,
      0.05534912273287773,
      0.003215233562514186,
      -0.022571668028831482,
      -0.025847116485238075,
      -0.01072411984205246,
      0.037910304963588715,
      -0.04222283139824867,
      0.0007653621723875403,
      -0.035603102296590805,
      -0.004138255957514048,
      -0.02863076701760292,
      0.01164823118597269,
      0.06143142282962799,
      0.016335494816303253,
      0.016268432140350342,
      0.12736526131629944,
      -0.06680987775325775,
      -0.007448308635503054,
      0.03165930137038231,
      -0.08034615218639374,
      -0.006114138290286064,
      -0.061078522354364395,
      0.061785485595464706,
      0.027587080374360085,
      -0.002651387359946966,
      -0.07751195132732391,
      0.023772798478603363,
      0.03181535378098488,
      0.09754372388124466,
      -0.0073690274730324745,
      -0.030618706718087196,
      0.07808366417884827,
      -0.031578436493873596,
      -0.041018761694431305,
      0.019060572609305382,
      0.008512361906468868,
      -0.07259313762187958,
      0.009106911718845367,
      -0.01016054768115282,
      0.021873602643609047,
      0.01627933979034424,
      0.07209639251232147,
      -0.04943595826625824,
      0.0011188144562765956,
      -0.012022784911096096,
      4.1372518353455234e-06,
      0.04023720324039459,
      0.08454351872205734,
      0.08339348435401917,
      0.0074335685931146145,
      0.023676466196775436,
      -0.0235847607254982,
      0.010433266870677471,
      -0.021768899634480476,
      -0.04654015973210335,
      0.0497053898870945,
      -0.029662741348147392,
      -0.057799674570560455,
      0.03696572780609131,
      -0.052153948694467545,
      0.05459043011069298,
      -0.07252934575080872,
      -0.0188736654818058,
      -0.025912996381521225,
      -0.056628141552209854,
      -0.006917654536664486,
      -0.012360592372715473,
      0.05526071786880493,
      0.002668672939762473,
      -0.0018457593396306038,
      0.008623912930488586,
      0.02347458153963089,
      -0.07359113544225693,
      0.0318094938993454,
      -0.014787533320486546,
      0.008640620857477188,
      -0.010648342780768871,
      0.06635311245918274,
      -0.01485426165163517,
      0.031116895377635956,
      -0.016948392614722252,
      -0.07132449001073837,
      -0.020603381097316742,
      0.0163589995354414,
      0.04163740947842598,
      0.06100790202617645,
      -0.03024154156446457,
      -0.03525308892130852,
      -0.07743912935256958,
      0.07663007080554962,
      0.030452176928520203,
      0.04070473834872246,
      0.004307944793254137,
      -0.011399947106838226,
      0.013013607822358608,
      0.08974552899599075,
      0.06230328977108002,
      -0.04344673454761505,
      -0.015009576454758644,
      -0.02643445134162903,
      -0.04953514784574509,
      0.007916954346001148,
      0.016912519931793213,
      -0.11553752422332764,
      -0.011684221215546131,
      -0.060903824865818024,
      -0.029506739228963852,
      -0.0857313722372055,
      -0.029507100582122803,
      -0.020357433706521988,
      -0.014977004379034042,
      0.05624110624194145,
      -0.032048918306827545,
      -0.010415434837341309,
      -0.017197221517562866,
      0.025805192068219185,
      -0.04876265674829483,
      0.038133393973112106,
      0.021378979086875916,
      -0.0365508571267128,
      -0.02833782322704792,
      0.056389134377241135,
      -0.033172521740198135,
      0.062141094356775284,
      -0.02206496335566044,
      -0.004227584693580866,
      0.042437050491571426,
      0.05833079293370247,
      0.043312687426805496,
      -0.02649187482893467,
      0.025159647688269615,
      -0.01304058451205492,
      -0.023916009813547134,
      0.08255321532487869,
      0.03941823169589043,
      0.02267734706401825,
      -0.048959776759147644,
      -0.02918165922164917,
      -0.11441256105899811,
      0.036615289747714996,
      -0.02812131680548191,
      0.022754933685064316,
      0.07768288254737854,
      0.12564168870449066,
      0.0038729768712073565,
      0.00839696079492569,
      -0.04770056903362274,
      -0.03169936314225197,
      -0.004053868819028139,
      -0.02952493540942669,
      -0.03396499156951904,
      -0.01810430735349655,
      -0.022848783060908318,
      0.0030466390307992697,
      -0.032313041388988495,
      -0.03583439812064171,
      0.07876916229724884,
      -0.002224178984761238,
      -0.024723991751670837,
      -0.0018543434562161565,
      0.05139847844839096,
      -0.05397428199648857,
      0.03379194065928459,
      0.05334122106432915,
      0.08806242793798447,
      -0.10115070641040802,
      0.023776570335030556,
      -0.041461281478405,
      0.023507265374064445,
      -0.0749095231294632,
      -0.014400946907699108,
      -0.002839701948687434,
      -0.08795706927776337,
      0.023050827905535698,
      -0.04226098582148552,
      -0.051408227533102036,
      -0.03467449173331261,
      0.06098192557692528,
      -0.05262589082121849,
      -0.07238812744617462,
      0.003906087251380086,
      0.06526917964220047,
      -0.020424440503120422,
      0.0617288202047348,
      -0.09191740304231644,
      0.05295548960566521,
      -0.01204446516931057,
      -0.024227416142821312,
      -0.01678900048136711,
      0.0012882176088169217,
      -2.6503203116590157e-05,
      0.03356076404452324,
      -0.07278694957494736,
      0.040821317583322525,
      -0.03612944483757019,
      0.00015951870591379702,
      -0.010380616411566734,
      -0.0065873730927705765,
      -0.06138740852475166,
      -0.0016655732179060578,
      0.0641065388917923,
      0.02397884987294674,
      -0.018181229010224342,
      -0.018161768093705177,
      -0.0594649538397789,
      -0.0037135365419089794,
      0.018185362219810486,
      0.006182197481393814,
      -0.004084764048457146,
      0.01835191622376442,
      0.06272010505199432,
      -0.025863759219646454,
      -0.0264125969260931,
      -0.020141344517469406,
      0.004465150181204081,
      0.06915222108364105,
      -0.02745397761464119,
      0.01477820985019207,
      0.04046408832073212,
      -0.004846543539315462,
      -0.023187870159745216,
      -0.05372875556349754,
      0.020502973347902298,
      -0.03767313063144684,
      -0.03955402225255966,
      0.05021408945322037,
      0.08255770802497864,
      0.03487975150346756,
      -0.07386047393083572,
      0.010246144607663155,
      -0.019089531153440475,
      0.02134905941784382,
      0.041255272924900055,
      -0.018847713246941566,
      0.02238108217716217,
      -0.022999802604317665,
      -0.032161857932806015,
      0.06383243203163147,
      -0.06033723056316376,
      0.0690261647105217,
      0.02066948264837265,
      0.05436081066727638,
      0.009416112676262856,
      -0.04629876837134361,
      0.06544148176908493,
      -0.08786405622959137,
      0.012082751840353012,
      -0.13654597103595734,
      -0.04568696767091751,
      -0.011082419194281101,
      0.05114014819264412,
      0.0993848368525505,
      -0.015201834961771965,
      -0.10791970044374466,
      -0.004687513690441847,
      -0.008183852769434452,
      0.009469977580010891,
      -0.016875294968485832,
      -0.005437460262328386,
      -0.029835334047675133,
      0.02429695427417755,
      0.047615617513656616,
      -0.01096472516655922,
      0.016762863844633102,
      0.03530920669436455,
      -0.040528181940317154,
      0.05284537747502327,
      0.03918333351612091,
      -0.00807011779397726,
      0.018928810954093933,
      0.031493280082941055,
      -0.01768864318728447,
      -0.014870288781821728,
      -0.01381524745374918,
      -0.011549806222319603,
      -0.004589177668094635,
      -0.08190349489450455,
      0.04556762054562569,
      -0.020128361880779266,
      0.004209225531667471,
      0.04802827164530754,
      0.19079628586769104,
      -0.023929331451654434,
      0.022377634420990944,
      0.11612050980329514,
      -0.0021293286699801683,
      0.03346860408782959,
      -0.019095567986369133,
      0.0733870193362236,
      -0.02127215452492237,
      -0.005354652181267738,
      0.010183751583099365,
      -0.09569234400987625,
      0.013422449119389057,
      -0.025716092437505722,
      0.0388859398663044,
      -0.044101569801568985,
      0.019424794241786003,
      -0.02876160852611065,
      0.029964448884129524,
      0.0480332151055336,
      0.02689650095999241,
      -0.016061119735240936,
      -0.02816404402256012,
      0.04231121391057968,
      0.0686076208949089,
      0.05008538067340851,
      0.041375212371349335,
      0.027196474373340607,
      0.02439379319548607,
      -0.015490720048546791,
      -0.010372262448072433,
      -0.03360782191157341,
      0.10023320466279984,
      0.0058867293410003185,
      -0.03140757977962494
    ],
    [
      0.05495588108897209,
      -0.009256662800908089,
      -0.017703378573060036,
      0.054316747933626175,
      0.03325512632727623,
      -0.005099848844110966,
      0.07759916037321091,
      -0.027965793386101723,
      0.03804424777626991,
      0.07646211236715317,
      -0.012031327933073044,
      -0.014763033017516136,
      -0.029859092086553574,
      -0.01653650961816311,
      0.029617028310894966,
      0.09541697800159454,
      0.012351268902420998,
      -0.016424572095274925,
      -0.021522799506783485,
      0.03701169416308403,
      -0.06552216410636902,
      -0.09176255762577057,
      -0.051268551498651505,
      -0.0467638298869133,
      -0.007648829370737076,
      -0.037923771888017654,
      0.12275209277868271,
      -0.019816657528281212,
      -0.04286077991127968,
      0.018441837280988693,
      0.004523950163275003,
      0.0383659228682518,
      0.010320569388568401,
      0.01283991988748312,
      0.0014060336397960782,
      -0.0010799551382660866,
      -0.021348170936107635,
      -0.006651535164564848,
      -0.036905236542224884,
      0.019335204735398293,
      -0.005537260789424181,
      -0.041070692241191864,
      0.018627099692821503,
      0.04734494537115097,
      -0.05837961286306381,
      -0.011107366532087326,
      0.11396930366754532,
      0.07156822830438614,
      0.08025123924016953,
      -0.0725632831454277,
      0.049973174929618835,
      0.04249744862318039,
      -0.06422194093465805,
      0.03114919736981392,
      -0.0020570175256580114,
      -0.02656484581530094,
      -0.039867036044597626,
      0.016580602154135704,
      0.06502868980169296,
      -0.0033876884263008833,
      -0.007661823648959398,
      -0.012072376906871796,
      0.06442849338054657,
      -0.1121932864189148,
      -0.04163972660899162,
      -0.015032925643026829,
      0.0099500035867095,
      -0.0706171989440918,
      -0.053303610533475876,
      -0.07304683327674866,
      -0.029774675145745277,
      -0.09325630217790604,
      -0.03736189007759094,
      -0.1342819333076477,
      0.009099126793444157,
      -0.03485697880387306,
      -0.028194719925522804,
      0.03646767511963844,
      -0.04258744791150093,
      -0.04326440766453743,
      0.017997847869992256,
      -0.011131174862384796,
      0.0498284250497818,
      -0.04583633691072464,
      -0.0773102194070816,
      -0.019368290901184082,
      -0.014434599317610264,
      0.009274886921048164,
      -0.03478574752807617,
      -0.019793488085269928,
      0.0034344366285949945,
      0.08838634192943573,
      -0.06915406882762909,
      -0.013252089731395245,
      0.08491426706314087,
      0.007248356007039547,
      -0.016266537830233574,
      0.01338905282318592,
      0.02365122362971306,
      0.04266396909952164,
      -0.035919301211833954,
      -0.0023517708759754896,
      0.02709585800766945,
      -0.009002325125038624,
      0.06521378457546234,
      -0.004444182850420475,
      0.0024844459258019924,
      -0.018546516075730324,
      -0.04172636196017265,
      -0.011988833546638489,
      -0.0025528825353831053,
      -0.03036862052977085,
      0.04712310805916786,
      -0.09147465974092484,
      -0.019071832299232483,
      0.09080377966165543,
      0.01372293196618557,
      -0.011131900362670422,
      0.02303791604936123,
      -0.0512658953666687,
      -0.029157554730772972,
      0.05831165984272957,
      0.03797559067606926,
      0.05021258071064949,
      0.003667975077405572,
      0.02541329897940159,
      0.005698225926607847,
      0.06570452451705933,
      -0.027913866564631462,
      0.024720653891563416,
      -0.051580023020505905,
      0.011470484547317028,
      -0.005592438392341137,
      -0.05217427760362625,
      0.056308016180992126,
      0.07018258422613144,
      0.07148722559213638,
      0.000810014083981514,
      0.05070142447948456,
      0.009902128018438816,
      -0.028474513441324234,
      -0.023840226233005524,
      0.015166602097451687,
      -0.027795812115073204,
      0.020964032039046288,
      -0.025492088869214058,
      0.01144303847104311,
      -0.0329773910343647,
      0.0860079899430275,
      0.019179977476596832,
      -0.011184567585587502,
      0.08090601116418839,
      -0.007841710932552814,
      -0.002564456779509783,
      0.013693281449377537,
      0.033797360956668854,
      -0.0008314601145684719,
      -0.0036742815282195807,
      0.10003018379211426,
      0.028631234541535378,
      0.005240752827376127,
      0.05643562600016594,
      0.016513554379343987,
      0.03971992805600166,
      0.055961959064006805,
      -0.05604420229792595,
      0.015425954945385456,
      0.02071479894220829,
      -0.014645596966147423,
      0.05264062434434891,
      -0.03399939835071564,
      -0.016409751027822495,
      -0.00726767024025321,
      -0.05847472697496414,
      -0.03430267423391342,
      -0.041585519909858704,
      0.007268007379025221,
      0.016516800969839096,
      0.026673926040530205,
      -0.08718845993280411,
      -0.009120895527303219,
      0.007607453502714634,
      -0.012879415415227413,
      -0.10741306096315384,
      -0.02686808630824089,
      0.014755884185433388,
      0.0783563181757927,
      -0.02518397383391857,
      0.03249266743659973,
      0.06266611069440842,
      -0.024044977501034737,
      0.06482495367527008,
      -0.0020944580901414156,
      -0.11000893265008926,
      0.025254754349589348,
      -0.03254115954041481,
      0.028388025239109993,
      -0.02121015451848507,
      0.06287352740764618,
      -0.023425115272402763,
      -0.017298448830842972,
      0.014183389022946358,
      -0.028568534180521965,
      -0.018611250445246696,
      0.021377436816692352,
      0.01994187943637371,
      -0.04995974898338318,
      -0.009365448728203773,
      0.05739510804414749,
      -0.07654446363449097,
      -0.01831022836267948,
      0.019509412348270416,
      0.03836182504892349,
      0.003496031276881695,
      -0.08399105817079544,
      0.04884055629372597,
      0.02823934517800808,
      -0.03299199789762497,
      0.013495612889528275,
      0.03677801042795181,
      -0.03191550821065903,
      -0.024082232266664505,
      0.004902471322566271,
      0.03186425939202309,
      0.021254312247037888,
      0.04261424019932747,
      0.02009437046945095,
      -0.01617749035358429,
      0.009001473896205425,
      -0.03819755092263222,
      -0.062311626970767975,
      0.03636528179049492,
      0.054343558847904205,
      0.05928761512041092,
      0.025410883128643036,
      0.008869875222444534,
      -0.04511675611138344,
      -0.05380924418568611,
      0.03544338047504425,
      -0.0038778437301516533,
      0.013217717409133911,
      0.026766864582896233,
      0.00643412908539176,
      0.030739426612854004,
      0.012638087384402752,
      0.030401205644011497,
      0.01856844872236252,
      -0.004988178610801697,
      0.0409076102077961,
      0.038282278925180435,
      0.020530881360173225,
      -0.07557667791843414,
      -0.03821880742907524,
      0.0449523888528347,
      0.02355056256055832,
      -0.042857926338911057,
      -0.004443593788892031,
      0.012898355722427368,
      0.05143086984753609,
      -0.03729791194200516,
      0.02749733254313469,
      0.03160189464688301,
      0.045810163021087646,
      0.006707867607474327,
      -0.01823710836470127,
      -0.018101990222930908,
      -0.04057138040661812,
      -0.06548917293548584,
      0.01716279610991478,
      -0.06427530199289322,
      0.050544384866952896,
      -0.01638094335794449,
      -0.050225116312503815,
      0.019602691754698753,
      0.022812506183981895,
      -0.030104782432317734,
      -0.008622554130852222,
      -0.03501099348068237,
      0.0336000993847847,
      -0.07820965349674225,
      -0.010955093428492546,
      -0.03543739765882492,
      -0.033678531646728516,
      0.012387820519506931,
      -0.03291936591267586,
      0.017862290143966675,
      0.04966961219906807,
      0.0068860952742397785,
      0.0807577520608902,
      0.06711976230144501,
      -0.050435397773981094,
      -0.018650615587830544,
      0.10177521407604218,
      -0.04254217445850372,
      -0.053878992795944214,
      -0.037739213556051254,
      0.0010554409818723798,
      0.021140053868293762,
      -0.07670068740844727,
      -0.019739430397748947,
      0.04516749456524849,
      -0.04527713730931282,
      0.056726135313510895,
      -0.032575808465480804,
      0.035935722291469574,
      0.10396531224250793,
      -0.0037848984356969595,
      0.007039723452180624,
      -0.0516592301428318,
      -0.0268922820687294,
      0.011635650880634785,
      -0.08063960820436478,
      0.02328353188931942,
      0.04008675739169121,
      0.006787399295717478,
      -0.04291801527142525,
      0.025631999596953392,
      0.008423229679465294,
      -0.026244577020406723,
      0.05535237118601799,
      -0.013307292945683002,
      0.07137691974639893,
      0.016536634415388107,
      0.021862519904971123,
      0.023628070950508118,
      0.0056391083635389805,
      0.005866991356015205,
      -0.017619987949728966,
      -0.031258925795555115,
      -0.01913021132349968,
      0.07967085391283035,
      -0.021193286404013634,
      0.05738217011094093,
      -0.007677512243390083,
      -0.06979558616876602,
      -0.03810133412480354,
      -0.007788677234202623,
      -0.026166927069425583,
      0.057834990322589874,
      -0.043907828629016876,
      0.08175624907016754,
      0.022453390061855316,
      0.007337462157011032,
      -0.009388083592057228,
      0.0627540871500969,
      0.06820168346166611,
      0.0469338595867157,
      -0.04938477277755737,
      0.04605707898736,
      -0.022784724831581116,
      0.04367799311876297,
      0.01840478740632534,
      0.018503444269299507,
      0.05835029110312462,
      -0.03488286957144737,
      0.03155594691634178,
      0.07560374587774277,
      0.02178794890642166,
      0.0208125077188015,
      0.0770130306482315,
      -0.09247748553752899,
      -0.006279543973505497,
      0.010960315354168415,
      -0.03491734713315964,
      -0.05485162138938904,
      0.08409387618303299,
      0.06496749073266983,
      -0.014933132566511631,
      -0.07127228379249573,
      0.012633577920496464,
      0.0178373996168375,
      -0.08506156504154205,
      -0.01607026904821396,
      0.0004045570967718959,
      0.055471789091825485,
      0.037005916237831116,
      0.013447795994579792,
      -0.02552860602736473,
      -0.0757463350892067,
      -0.04474921151995659,
      -0.016481474041938782,
      0.031625356525182724,
      0.10788597166538239,
      0.05781903117895126,
      0.026685582473874092,
      0.07393595576286316,
      -0.06608249992132187,
      0.015445148572325706,
      0.038637202233076096,
      0.03095819801092148,
      0.005487329792231321,
      -0.022025566548109055,
      0.033930547535419464,
      0.005871077999472618,
      -0.04769877716898918,
      0.04168970510363579,
      -0.0005919627146795392,
      0.05785267800092697,
      0.039577558636665344,
      0.027509979903697968,
      -0.0639285147190094,
      -0.03302447870373726,
      0.04083782806992531,
      -0.00935147050768137,
      0.030178038403391838,
      0.039178796112537384,
      -0.030622242018580437,
      0.054878655821084976,
      -0.058429062366485596,
      -0.0019614845514297485,
      -0.04338296875357628,
      -0.0973372533917427,
      0.024175383150577545,
      -0.04563889279961586,
      -0.02111574076116085,
      -0.00020491858595050871,
      0.01645929552614689,
      0.07295548915863037,
      -0.04067077487707138,
      0.059364885091781616,
      0.059943947941064835,
      -0.020431840792298317,
      -0.015592619776725769,
      0.07838770747184753,
      -0.02469668537378311,
      0.023079805076122284,
      0.008559896610677242,
      0.014478810131549835,
      0.009836265817284584,
      -0.014857018366456032,
      0.07410453259944916,
      0.008067518472671509,
      0.010660144500434399,
      0.0035122691188007593,
      0.007451669313013554,
      -0.0410798154771328,
      0.02562009170651436,
      0.06219000741839409,
      0.004664430394768715,
      -0.039142608642578125,
      0.06014815345406532,
      -0.0030176853761076927,
      0.0009337755036540329,
      0.059129174798727036,
      -0.0014610388316214085,
      0.0771901085972786,
      -0.013766516931355,
      0.04955196753144264,
      -0.040192678570747375,
      -0.05171217396855354,
      0.021979771554470062,
      -0.01268651895225048,
      -0.024197131395339966,
      0.0640031173825264,
      0.009040351957082748,
      0.007861780934035778,
      0.06745749711990356,
      -0.012330705299973488,
      0.10207334905862808,
      -0.002904730150476098,
      0.012471215799450874,
      0.0068771811202168465,
      -0.006017213221639395,
      -0.06252408027648926,
      0.06206408888101578,
      -0.004250286612659693,
      -0.04203015938401222,
      -0.023805513978004456,
      0.04138394817709923,
      0.02294652722775936,
      -0.026080114766955376,
      -0.034069616347551346,
      -0.07555346190929413,
      -0.021364882588386536,
      -0.045639533549547195,
      0.05840965732932091,
      -0.014487997628748417,
      0.123927041888237,
      -0.08733826875686646,
      -0.024876166135072708,
      0.04134150967001915,
      -0.0545877180993557,
      -0.008632994256913662,
      -0.00048677585436962545,
      -0.01251110527664423,
      -0.001952498103491962,
      0.14687828719615936,
      0.042082879692316055,
      -0.021550798788666725,
      0.08078592270612717,
      -0.018447360023856163,
      -0.04412306100130081,
      -0.039868373423814774,
      0.02048775739967823,
      -0.04215118661522865,
      0.011723533272743225,
      0.018019236624240875,
      0.10473097860813141,
      -0.07864898443222046,
      0.008853415958583355,
      -0.08921357989311218,
      0.011609791778028011,
      -0.004382257349789143
    ],
    [
      -0.012249957770109177,
      0.011047806590795517,
      -0.07448186725378036,
      -0.04616722837090492,
      -0.01252011675387621,
      0.03492921590805054,
      -0.019169367849826813,
      -0.05739973857998848,
      -0.02081945911049843,
      -0.07301440089941025,
      0.045846156775951385,
      0.015638431534171104,
      -0.036570314317941666,
      0.01079786941409111,
      0.05332944542169571,
      -0.012857640162110329,
      -0.06999754905700684,
      -0.06163892149925232,
      0.01788606122136116,
      -0.056898292154073715,
      0.03167349472641945,
      0.01181227806955576,
      -0.07203640788793564,
      -0.06707791984081268,
      0.010957349091768265,
      0.0412982702255249,
      -0.016352934762835503,
      -0.053586043417453766,
      -0.017065072432160378,
      -0.009492835961282253,
      -0.005523311905562878,
      -0.005642877891659737,
      -0.01948591321706772,
      0.04687788709998131,
      -0.04150375723838806,
      0.05997515469789505,
      -0.03819691762328148,
      -0.013406181707978249,
      -0.05449247360229492,
      0.023181447759270668,
      -0.04562036693096161,
      -0.030380038544535637,
      0.04482470825314522,
      0.009616914205253124,
      -0.05999751016497612,
      0.002751939231529832,
      0.017498543485999107,
      0.007543330546468496,
      0.030681008473038673,
      0.07257295399904251,
      0.07148849964141846,
      0.01952987164258957,
      0.026287546381354332,
      -0.035286419093608856,
      0.0033266693353652954,
      -0.08501226454973221,
      0.09292259812355042,
      0.016400260850787163,
      -0.015355580486357212,
      -0.11725589632987976,
      -0.006213741842657328,
      -0.00804021768271923,
      -0.011165423318743706,
      -0.003723782254382968,
      0.025420667603611946,
      -0.021275080740451813,
      0.04081251472234726,
      -0.023216446861624718,
      -0.012544703669846058,
      -0.008841830305755138,
      -0.015010840259492397,
      0.013300818391144276,
      -0.002242635004222393,
      -0.09622671455144882,
      -0.04168533533811569,
      0.011803306639194489,
      -0.015575826168060303,
      0.04444219544529915,
      -0.02945607900619507,
      0.07834380120038986,
      -0.001948466175235808,
      -0.01190339494496584,
      -0.05539514496922493,
      0.033744361251592636,
      -0.009091519750654697,
      0.006007499527186155,
      0.04100487381219864,
      0.022305304184556007,
      -0.013814263977110386,
      -0.01801549270749092,
      -0.001120865112170577,
      -0.01754206418991089,
      0.06722012162208557,
      0.013645310886204243,
      -0.01340827438980341,
      0.02125314623117447,
      0.0018098127329722047,
      -0.006224270910024643,
      0.016422050073742867,
      -0.07743116468191147,
      -0.15670306980609894,
      -0.018307700753211975,
      0.027191445231437683,
      0.0434303842484951,
      0.022348778322339058,
      -0.020226232707500458,
      0.03656449913978577,
      0.06306162476539612,
      -0.015501459129154682,
      -0.0891895741224289,
      0.07044441252946854,
      0.0006571299163624644,
      -0.022212976589798927,
      0.003925005439668894,
      0.02402622625231743,
      -0.014749283902347088,
      0.04585587605834007,
      0.02578553929924965,
      -0.0368071123957634,
      -0.09146478027105331,
      -0.08432133495807648,
      0.04805314913392067,
      0.0028013477567583323,
      -0.07301079481840134,
      0.09520409256219864,
      -0.003341424511745572,
      0.057214219123125076,
      0.044041164219379425,
      -0.01786542311310768,
      -0.017903964966535568,
      -0.028553754091262817,
      0.014428352005779743,
      0.0006843713927082717,
      0.007586694322526455,
      0.0539984330534935,
      0.03414151817560196,
      -0.03638486936688423,
      -0.01296248659491539,
      -0.030939316377043724,
      0.013366860337555408,
      -0.01294396910816431,
      0.04031591862440109,
      -0.04006136581301689,
      0.030388114973902702,
      -0.003524551633745432,
      -0.010468719527125359,
      0.0170791856944561,
      -0.034609027206897736,
      -3.6673278373200446e-05,
      0.0678299218416214,
      0.03407327085733414,
      -0.025772595778107643,
      -0.006473312620073557,
      -0.015108825638890266,
      -0.04578657075762749,
      0.012200452387332916,
      0.026222964748740196,
      0.005013586021959782,
      -0.07407903671264648,
      0.12149043381214142,
      0.0067399172112345695,
      0.0755520761013031,
      -0.06250817328691483,
      0.03271780535578728,
      -0.054454393684864044,
      0.029415443539619446,
      0.09179271012544632,
      0.10563917458057404,
      0.013587507419288158,
      0.004124386236071587,
      0.03071429580450058,
      -0.017253868281841278,
      -0.05671527981758118,
      0.018724534660577774,
      0.008598215878009796,
      -0.062297411262989044,
      0.004806882236152887,
      -0.024281060323119164,
      -0.01675085723400116,
      0.03256658837199211,
      -0.02847670391201973,
      0.010939739644527435,
      0.01909247227013111,
      0.0900411307811737,
      0.02084590494632721,
      -0.043151553720235825,
      0.013052377849817276,
      -0.03428833931684494,
      0.06353514641523361,
      1.796231845219154e-05,
      -0.02462833561003208,
      0.01464096549898386,
      0.020273227244615555,
      0.02219490148127079,
      -0.052520278841257095,
      -0.13488315045833588,
      -0.020017145201563835,
      -0.02399439550936222,
      0.03638899698853493,
      -0.032881319522857666,
      -0.051513221114873886,
      0.045965299010276794,
      -0.09190694242715836,
      -0.027738157659769058,
      0.053461991250514984,
      0.0652518942952156,
      0.08936096727848053,
      -0.043221261352300644,
      -0.04991269111633301,
      -0.12031827867031097,
      0.018275221809744835,
      -0.012332458980381489,
      -0.007021283730864525,
      0.014420014806091785,
      -0.012520907446742058,
      0.005276410840451717,
      0.04235483333468437,
      -0.02152075618505478,
      -0.050795428454875946,
      0.08796495944261551,
      -0.050899937748909,
      0.022143928334116936,
      0.06395746022462845,
      0.04367522522807121,
      0.057769712060689926,
      -0.09595413506031036,
      -0.023968689143657684,
      0.10750852525234222,
      0.018151581287384033,
      0.06232733651995659,
      -0.06537087261676788,
      0.04580458998680115,
      0.007937182672321796,
      -0.014234974049031734,
      -0.04736668989062309,
      0.08976387232542038,
      -0.032655034214258194,
      -0.02434457838535309,
      -0.039390016347169876,
      0.016470827162265778,
      0.038128986954689026,
      0.009542981162667274,
      -0.021938754245638847,
      -0.02175946719944477,
      0.0247662216424942,
      -0.02173413150012493,
      -0.06916090846061707,
      0.024370308965444565,
      0.029407374560832977,
      -0.050824835896492004,
      0.06154903396964073,
      0.06115236133337021,
      -0.0353575199842453,
      0.01853363588452339,
      0.04346481338143349,
      0.020076852291822433,
      0.026286866515874863,
      0.024529218673706055,
      0.01835436373949051,
      0.0313183031976223,
      0.05996645241975784,
      0.018299443647265434,
      -0.031128857284784317,
      0.0006797892856411636,
      -0.03624705970287323,
      0.010857537388801575,
      0.06574001908302307,
      -0.02574118785560131,
      -0.004917883314192295,
      0.012852480635046959,
      0.01851509138941765,
      0.02193223498761654,
      0.05451219901442528,
      0.05180588737130165,
      0.004636220168322325,
      0.0730331763625145,
      -0.02609117329120636,
      0.018450597301125526,
      -0.013515019789338112,
      -0.005022631958127022,
      0.03538685664534569,
      -0.021826578304171562,
      0.042461514472961426,
      -0.004939664620906115,
      -0.04249706119298935,
      0.02891889028251171,
      0.059176672250032425,
      0.045730531215667725,
      -0.010192446410655975,
      -0.0955059602856636,
      0.02347848005592823,
      -0.0178456399589777,
      0.0374261736869812,
      -0.020289361476898193,
      0.00809457991272211,
      0.02423018030822277,
      0.011339410208165646,
      0.05131472274661064,
      -0.025974176824092865,
      0.0005500589031726122,
      -0.008778099901974201,
      -0.0681399405002594,
      0.01806638389825821,
      -0.03729510307312012,
      -0.014466078020632267,
      0.015447159297764301,
      0.04863293468952179,
      0.0643947497010231,
      0.054576899856328964,
      -0.04487176984548569,
      -0.010282403789460659,
      -0.0836034044623375,
      0.02389800362288952,
      0.011728723533451557,
      -0.06337116658687592,
      0.035657480359077454,
      -0.07481799274682999,
      0.05034537985920906,
      -0.047855425626039505,
      -0.09981027990579605,
      0.09222057461738586,
      -0.10114136338233948,
      0.005054259207099676,
      0.012815023772418499,
      0.13844017684459686,
      0.049568090587854385,
      -0.006732194684445858,
      0.07187240570783615,
      -0.04308269917964935,
      0.07959868013858795,
      -0.03777255117893219,
      0.05304466187953949,
      0.06092585623264313,
      0.05109256133437157,
      -0.024602502584457397,
      -0.09198835492134094,
      0.0036891289055347443,
      0.007797833997756243,
      -0.004384092520922422,
      0.027326883748173714,
      -0.11965741962194443,
      0.021326741203665733,
      -0.03760123997926712,
      -0.05585016310214996,
      -0.030985264107584953,
      -0.0077813295647501945,
      -0.05180627480149269,
      0.06181415915489197,
      0.04236409813165665,
      0.07662519067525864,
      0.03428570181131363,
      0.011078409850597382,
      -0.026742685586214066,
      0.022335216403007507,
      -0.00013677513925358653,
      0.047704458236694336,
      -0.015891771763563156,
      -0.052312467247247696,
      0.013364299200475216,
      0.010931267403066158,
      -0.008018525317311287,
      -0.0018869367195293307,
      -0.0361715629696846,
      -0.05643597990274429,
      0.06452441960573196,
      0.00887414813041687,
      -0.01741665229201317,
      0.056126683950424194,
      0.025208257138729095,
      0.06369638442993164,
      0.005423924420028925,
      -0.035535067319869995,
      -0.016712024807929993,
      -0.001122589921578765,
      -0.06929995864629745,
      0.04029962047934532,
      0.04037490487098694,
      0.044286202639341354,
      0.026901140809059143,
      -0.01730421371757984,
      -0.02331714518368244,
      0.08957487344741821,
      -0.05772644653916359,
      0.07088463008403778,
      -0.03866561874747276,
      -0.03898667171597481,
      -0.03202826529741287,
      0.0014584459131583571,
      -0.003866602433845401,
      -0.03950855880975723,
      0.028673939406871796,
      -0.03824938088655472,
      0.06419802457094193,
      -0.03382733836770058,
      -0.03424442559480667,
      -0.11994577944278717,
      0.02723686583340168,
      0.002166306832805276,
      0.001068280776962638,
      -0.013476009480655193,
      0.024832170456647873,
      0.040272489190101624,
      0.03438401594758034,
      0.02700580097734928,
      -0.03166621923446655,
      -0.03353046253323555,
      0.02444344200193882,
      0.06450190395116806,
      -0.016306083649396896,
      -0.05095832422375679,
      -0.030318807810544968,
      0.046743620187044144,
      0.013925094157457352,
      0.09296683222055435,
      -0.02541492134332657,
      0.002913853619247675,
      0.02616559900343418,
      0.037287987768650055,
      0.012994032353162766,
      -0.0029271875973790884,
      -0.10613788664340973,
      0.019916396588087082,
      -0.05090152472257614,
      -0.04625735431909561,
      -0.018970100209116936,
      -0.05112270638346672,
      -0.027679042890667915,
      0.04820701479911804,
      0.04157989099621773,
      -0.018030494451522827,
      -0.00840011052787304,
      0.0001871923595899716,
      0.006170563865453005,
      -0.022349895909428596,
      -0.05038520693778992,
      -0.023759379982948303,
      -0.028340181335806847,
      0.012839886359870434,
      0.027383608743548393,
      0.005114094819873571,
      0.04850119352340698,
      0.0859687402844429,
      -0.010561376810073853,
      -0.04994681850075722,
      0.04471690580248833,
      0.10696136951446533,
      0.005081349518150091,
      0.020310942083597183,
      -0.019702423363924026,
      -0.06335384398698807,
      -0.022570565342903137,
      0.008423849008977413,
      -0.015259814448654652,
      -0.024149512872099876,
      -0.007886460050940514,
      0.003256674623116851,
      0.029084723442792892,
      -0.09238666296005249,
      -0.003097214037552476,
      -0.010048864409327507,
      -0.02240477316081524,
      0.0031234321650117636,
      -0.00881931185722351,
      0.03876858577132225,
      0.050569694489240646,
      0.03352770954370499,
      -0.006291595753282309,
      -0.04658600315451622,
      -0.11190798133611679,
      0.06000494211912155,
      -0.04461487755179405,
      -0.07591057568788528,
      -0.0015396835515275598,
      -0.057019833475351334,
      -0.0022143099922686815,
      0.011593377217650414,
      -0.009681595489382744,
      0.027116892859339714,
      0.015435840934515,
      0.029750417917966843,
      -0.021565711125731468,
      0.029216935858130455,
      -0.09291345626115799,
      -0.017989201471209526,
      0.025590166449546814,
      0.043875645846128464,
      0.02398657612502575,
      0.016343124210834503,
      -0.004781968425959349,
      0.04404116049408913,
      -0.0017669686349108815,
      0.034574978053569794,
      -0.010258401744067669,
      0.03528919443488121,
      -0.006360730621963739,
      -0.039732955396175385,
      -0.05707722529768944,
      0.03794568032026291,
      -0.00841321237385273,
      -0.0035883423406630754,
      0.0609564408659935,
      -0.011166791431605816,
      0.034128881990909576
    ],
    [
      0.02537156082689762,
      -0.014357361942529678,
      0.014376886188983917,
      0.03550416976213455,
      0.006742645520716906,
      0.013178749941289425,
      -0.028443073853850365,
      -0.02284473553299904,
      -0.046879518777132034,
      0.0817565768957138,
      -0.08446178585290909,
      0.03441859781742096,
      0.008292903192341328,
      -0.04344455525279045,
      -0.05497562885284424,
      0.010802516713738441,
      -0.019567951560020447,
      0.0001488670677645132,
      0.07189535349607468,
      -0.05333314090967178,
      -0.009255504235625267,
      0.02766086906194687,
      0.08856827765703201,
      0.026098115369677544,
      0.0385722815990448,
      -0.0323580801486969,
      -0.032670699059963226,
      -0.03265496343374252,
      -0.02255084551870823,
      0.021020235493779182,
      0.00042991992086172104,
      0.00369850592687726,
      -0.06419143080711365,
      0.034690018743276596,
      -0.04830099642276764,
      0.0029723220504820347,
      0.028346702456474304,
      0.021170485764741898,
      -0.041236959397792816,
      -0.07632653415203094,
      -0.054110001772642136,
      -0.040007270872592926,
      0.04616960510611534,
      0.08216742426156998,
      0.11978153139352798,
      0.03362313285470009,
      0.033186595886945724,
      0.034156717360019684,
      0.04878877103328705,
      0.058212220668792725,
      -0.021740349009633064,
      0.015897857025265694,
      0.06197655200958252,
      0.0027796404901891947,
      0.030499348416924477,
      -0.02939518727362156,
      0.008183011785149574,
      0.03520295396447182,
      -0.004197726026177406,
      -0.0956488773226738,
      -0.02595030516386032,
      -0.025569681078195572,
      0.02997012250125408,
      -0.0032950243912637234,
      -0.047178301960229874,
      -0.03446546196937561,
      -0.043040964752435684,
      -0.0010267449542880058,
      0.02542274259030819,
      0.00889621488749981,
      0.00754559226334095,
      -0.022433198988437653,
      0.018570054322481155,
      -0.0027091288939118385,
      -0.05115765333175659,
      -0.014956752769649029,
      -0.0017349562840536237,
      -0.020368404686450958,
      -0.04882648587226868,
      -0.018623225390911102,
      -0.015553953126072884,
      -0.02207901142537594,
      0.009416624903678894,
      0.00897061638534069,
      0.04424189403653145,
      -0.028314828872680664,
      0.02251375839114189,
      0.0013634666102007031,
      -0.09379106760025024,
      0.04522117227315903,
      -0.0164572112262249,
      -0.0630875825881958,
      0.01866281032562256,
      0.02520856447517872,
      -0.040468160063028336,
      0.010953043587505817,
      0.015308494679629803,
      -0.008194150403141975,
      0.02116445265710354,
      0.02323739044368267,
      -0.04232000559568405,
      -0.02871718443930149,
      0.10761532932519913,
      -0.015451734885573387,
      0.03926322981715202,
      0.05327720567584038,
      -0.060055918991565704,
      0.06849059462547302,
      0.011616314761340618,
      -0.045862678438425064,
      0.044144585728645325,
      0.015976425260305405,
      -0.07771372050046921,
      -0.0034319423139095306,
      0.038364920765161514,
      0.037584010511636734,
      -0.04333900287747383,
      0.009804594330489635,
      0.04376084730029106,
      0.022108793258666992,
      0.030039459466934204,
      -0.0575559027493,
      0.03239738196134567,
      -0.01192745566368103,
      -0.019202416762709618,
      0.016661714762449265,
      0.01806287094950676,
      -0.058758001774549484,
      0.07374119013547897,
      -0.03171780705451965,
      -0.04744403436779976,
      -0.021286478266119957,
      -0.019189100712537766,
      -0.013193562626838684,
      0.0016979023348540068,
      -0.01753077656030655,
      0.05988314375281334,
      0.04368982091546059,
      0.044019728899002075,
      0.0834958478808403,
      -0.017204061150550842,
      -0.017073916271328926,
      -0.0023519317619502544,
      0.031746745109558105,
      0.03599196672439575,
      0.005427015945315361,
      0.02050747722387314,
      0.021870600059628487,
      -0.061658795922994614,
      0.008423808962106705,
      -0.0011012775357812643,
      0.044674329459667206,
      -0.06504083424806595,
      0.025631511583924294,
      0.04684717580676079,
      0.006972573697566986,
      -0.024979116395115852,
      -0.01201421208679676,
      -0.09940703213214874,
      -0.029420185834169388,
      0.03018014505505562,
      -0.0009834376396611333,
      -0.016915014013648033,
      0.1112297996878624,
      -0.047941047698259354,
      0.018070492893457413,
      0.023254528641700745,
      -0.04996683448553085,
      0.02421061508357525,
      0.04118800535798073,
      -0.03589635342359543,
      -0.021313568577170372,
      -0.018808158114552498,
      -0.03366631641983986,
      0.054451484233140945,
      -0.10516580939292908,
      -0.044009048491716385,
      -0.009367682971060276,
      0.06987644731998444,
      0.02975410968065262,
      0.012211522087454796,
      0.003984922077506781,
      0.024672485888004303,
      -0.056564364582300186,
      0.03376786410808563,
      -0.011633137241005898,
      0.010252103209495544,
      0.004157599527388811,
      -0.024580122902989388,
      0.002684658160433173,
      -0.03834919631481171,
      -0.0017228169599547982,
      -0.05904238671064377,
      -0.0504741370677948,
      -0.018223511055111885,
      0.03819442167878151,
      -0.032354358583688736,
      0.04896730184555054,
      0.04806358367204666,
      -0.002680388977751136,
      0.0884663462638855,
      -0.05626396834850311,
      -0.024299709126353264,
      -0.04371059685945511,
      0.03702986612915993,
      -0.035006362944841385,
      -0.0419139601290226,
      -0.03544645756483078,
      0.0033337294589728117,
      0.03174692764878273,
      0.08356249332427979,
      0.007967346347868443,
      -0.03977026045322418,
      -0.06519553810358047,
      0.030953360721468925,
      -0.016606653109192848,
      -0.07021507620811462,
      0.084311343729496,
      -0.011268086731433868,
      -0.01522852387279272,
      0.006316047627478838,
      0.061174359172582626,
      0.04319251701235771,
      0.011739542707800865,
      -0.042543310672044754,
      0.0016929969424381852,
      -0.021161235868930817,
      -0.008834173902869225,
      0.03206080198287964,
      0.0027328929863870144,
      0.031267520040273666,
      0.08566215634346008,
      0.10027602314949036,
      -0.02355106733739376,
      0.03267133608460426,
      0.02631903626024723,
      0.049316052347421646,
      -0.026950843632221222,
      -0.06939014792442322,
      -0.03761979192495346,
      0.08712422102689743,
      -0.012372549623250961,
      0.0052968040108680725,
      -0.020781511440873146,
      0.02448749542236328,
      -0.050474073737859726,
      0.016245467588305473,
      0.07899159938097,
      -0.0201384536921978,
      -0.009677075780928135,
      -0.01449516136199236,
      -0.05109783262014389,
      0.038151487708091736,
      0.013685490004718304,
      -0.057252272963523865,
      0.08104466646909714,
      0.08718301355838776,
      0.06996091455221176,
      0.03977522253990173,
      -0.0030930100474506617,
      -0.022393615916371346,
      -0.013657571747899055,
      -0.02207781746983528,
      0.00172902992926538,
      -0.008981360122561455,
      0.02449992299079895,
      -0.0366126224398613,
      0.03195405751466751,
      0.078096903860569,
      -0.08369915932416916,
      0.020036764442920685,
      0.11957668513059616,
      0.04218226298689842,
      -0.013016359880566597,
      0.0035597141832113266,
      0.010789317078888416,
      -0.009663451462984085,
      -0.03411833196878433,
      -0.0387604646384716,
      -0.018072746694087982,
      -0.07085070759057999,
      -0.05776216834783554,
      0.05204212665557861,
      -0.07098895311355591,
      -0.05095112323760986,
      0.038322627544403076,
      0.056435126811265945,
      0.05334187299013138,
      -0.008454362861812115,
      -0.015518172644078732,
      0.01012657955288887,
      0.015427390113472939,
      0.07429909706115723,
      -0.014790558256208897,
      0.015420712530612946,
      -0.01891021616756916,
      -0.019073911011219025,
      0.03435321897268295,
      -0.005043277982622385,
      0.0011956541566178203,
      0.043716996908187866,
      -0.06991276144981384,
      -0.01465601846575737,
      -0.11651217192411423,
      0.005216954741626978,
      -0.04761217534542084,
      0.021969938650727272,
      0.01921328343451023,
      0.03967168554663658,
      -0.026647979393601418,
      -0.04182137921452522,
      0.008608379401266575,
      -0.08698787540197372,
      0.08258948475122452,
      0.03787524998188019,
      -0.00014537715469487011,
      0.10865181684494019,
      0.01901681162416935,
      -0.05329320579767227,
      0.006268333178013563,
      -0.019298117607831955,
      -0.025348540395498276,
      -0.056680258363485336,
      0.017662039026618004,
      -0.007947772741317749,
      -0.04929095879197121,
      -0.0033914828673005104,
      0.029951822012662888,
      0.015058016404509544,
      0.00446247449144721,
      0.06420581787824631,
      -0.03672538697719574,
      -0.05575085058808327,
      0.028730446472764015,
      0.04759173467755318,
      -0.061479222029447556,
      -0.013080970384180546,
      -0.06586098670959473,
      -0.04501746594905853,
      0.014805324375629425,
      -0.06172548234462738,
      0.04509613662958145,
      0.00862809456884861,
      -0.05062950775027275,
      -0.011736154556274414,
      -0.048091474920511246,
      0.034042567014694214,
      -0.0035198936238884926,
      -0.05872742831707001,
      0.030769528821110725,
      -0.024604536592960358,
      0.05213433504104614,
      -0.0340406596660614,
      0.030343888327479362,
      -0.020566334947943687,
      0.0036616174038499594,
      -0.01753528043627739,
      0.011113203130662441,
      -0.04788898676633835,
      0.11543729156255722,
      -0.001708542462438345,
      -0.028005698695778847,
      0.06468578428030014,
      -0.0009192528086714447,
      -0.07102984189987183,
      0.005460994318127632,
      0.05610693618655205,
      0.07114876806735992,
      0.012457194738090038,
      0.030185870826244354,
      0.015905236825346947,
      0.057573605328798294,
      0.0029944416601210833,
      0.035371970385313034,
      0.018265174701809883,
      -0.016436800360679626,
      -0.011318688280880451,
      0.013134422712028027,
      -0.015506786294281483,
      0.0030084478203207254,
      0.08229489624500275,
      0.041218604892492294,
      -0.010631880722939968,
      0.0466020330786705,
      -0.05802169814705849,
      0.025172391906380653,
      -0.027637943625450134,
      -0.009305654093623161,
      -0.012392723932862282,
      -0.06078125536441803,
      0.060742031782865524,
      -0.05139788240194321,
      0.03551727533340454,
      -0.032965634018182755,
      -0.04889887943863869,
      -0.012748301960527897,
      0.04700656235218048,
      -0.04824875295162201,
      -0.05355806648731232,
      0.007674298714846373,
      0.08692820370197296,
      0.03882404789328575,
      0.0006659831269644201,
      -0.05887323245406151,
      0.05040649697184563,
      0.062213536351919174,
      0.028233522549271584,
      0.023424187675118446,
      -0.0028922660276293755,
      -0.0346163734793663,
      -0.08853056281805038,
      0.006174743175506592,
      -0.010958218015730381,
      0.1297076940536499,
      -0.003983854316174984,
      0.006900465581566095,
      0.017529301345348358,
      -0.0043062930926680565,
      -0.010551190003752708,
      0.0012555774301290512,
      0.05578469857573509,
      -0.05451434478163719,
      0.09500695765018463,
      -0.01597941480576992,
      0.032656580209732056,
      -0.01725432649254799,
      0.034800074994564056,
      0.10640864074230194,
      0.005448531825095415,
      -0.00603837426751852,
      0.04720804840326309,
      0.054136570543050766,
      0.011483581736683846,
      0.012632597237825394,
      0.043122611939907074,
      0.00750750582665205,
      0.02601497434079647,
      -0.01499711349606514,
      -0.021773286163806915,
      0.033026523888111115,
      -0.0065497951582074165,
      -0.016408709809184074,
      0.05084780231118202,
      -0.014143584296107292,
      0.04434794560074806,
      0.04896637052297592,
      0.01190335676074028,
      -0.061961960047483444,
      0.005192335229367018,
      0.01570197194814682,
      0.03951501473784447,
      -0.016825834289193153,
      -0.03630397468805313,
      -0.002729621948674321,
      0.06196533888578415,
      0.05508678779006004,
      -0.029591059312224388,
      -0.07180339843034744,
      0.05938353016972542,
      0.05563298985362053,
      -0.003302842378616333,
      0.03319726511836052,
      0.026132861152291298,
      -0.07586740702390671,
      0.05804428830742836,
      -0.02341935969889164,
      0.011350075714290142,
      -0.07730314135551453,
      -0.0045570628717541695,
      -0.051683783531188965,
      -0.04239535704255104,
      0.014502388425171375,
      -0.023027122020721436,
      -0.02002742700278759,
      0.02989119477570057,
      0.0032667333725839853,
      -0.007442818954586983,
      -0.13287384808063507,
      0.002925143577158451,
      -0.055513471364974976,
      0.04109901934862137,
      -0.00382047682069242,
      0.024424094706773758,
      -0.047215163707733154,
      0.04282229021191597,
      -0.014233248308300972,
      0.034440264105796814,
      0.07895366847515106,
      0.030186552554368973,
      -0.025386793538928032,
      -0.00483335368335247,
      0.006464969366788864,
      -0.05534769594669342,
      0.03348911181092262,
      0.03373691812157631,
      0.05980859324336052,
      -0.023144518956542015,
      -0.03822571039199829,
      -0.07399190962314606,
      -0.0018561497563496232,
      -0.0700155571103096,
      -0.014677990227937698,
      0.03633428364992142
    ],
    [
      -0.0004084237152710557,
      -0.00629267655313015,
      0.05660770833492279,
      0.032721538096666336,
      0.057568665593862534,
      0.030045371502637863,
      -0.021341487765312195,
      -0.10812158137559891,
      -0.005927734542638063,
      0.05117832496762276,
      -0.008924560621380806,
      0.029005886986851692,
      -0.05880804359912872,
      0.025077741593122482,
      -0.025060070678591728,
      -0.026086391881108284,
      0.07444488257169724,
      -0.027425721287727356,
      -0.044700536876916885,
      -0.0024326681159436703,
      0.024946128949522972,
      0.006275610066950321,
      -0.10311418026685715,
      -0.08097193390130997,
      0.019700052216649055,
      0.01350775733590126,
      0.008691665716469288,
      0.01758028008043766,
      -0.0452379435300827,
      -0.01662231795489788,
      0.014162755571305752,
      0.0016789694782346487,
      -0.09574950486421585,
      0.06434104591608047,
      -0.0651308074593544,
      -0.034659065306186676,
      -0.06721820682287216,
      0.021178429946303368,
      -0.0061630867421627045,
      -0.04096506908535957,
      -0.060451194643974304,
      0.005192357115447521,
      -0.022456252947449684,
      -0.11555090546607971,
      0.0066660004667937756,
      -0.07634320110082626,
      -0.007216393947601318,
      0.09812008589506149,
      -0.0540146678686142,
      -0.019644586369395256,
      0.017136555165052414,
      -0.008148272521793842,
      -0.053959764540195465,
      0.027918050065636635,
      -0.050756972283124924,
      0.034099169075489044,
      -0.004348794464021921,
      0.0638233944773674,
      -0.06107618287205696,
      0.06585229933261871,
      -0.029887858778238297,
      0.01156106498092413,
      0.028612850233912468,
      -0.07921574264764786,
      0.03944609314203262,
      0.0385812409222126,
      0.052769970148801804,
      0.015094785019755363,
      0.021370841190218925,
      0.030269699171185493,
      -0.07227805256843567,
      -0.061127182096242905,
      -0.02272854931652546,
      -0.04481271654367447,
      0.013906576670706272,
      -0.0172007717192173,
      0.07468847185373306,
      0.004991721827536821,
      -0.03290662169456482,
      -0.02515946328639984,
      0.07398457080125809,
      0.048357393592596054,
      -0.028540609404444695,
      0.0317053459584713,
      0.006348717957735062,
      0.0034736681263893843,
      0.055680327117443085,
      -0.0013704656157642603,
      0.02747439779341221,
      -0.02090223878622055,
      0.025061193853616714,
      -0.025056444108486176,
      0.06240759417414665,
      0.07176601886749268,
      0.002593077253550291,
      0.034494850784540176,
      -0.04932219907641411,
      0.05775763839483261,
      0.04787010699510574,
      0.006297980435192585,
      -0.019986269995570183,
      0.034849345684051514,
      0.049350276589393616,
      0.010734104551374912,
      -0.03863821551203728,
      -0.005895008333027363,
      -0.004161774180829525,
      -0.026029976084828377,
      0.0008403037791140378,
      -0.04406653717160225,
      0.036287371069192886,
      0.012720460072159767,
      0.022421596571803093,
      0.05031541362404823,
      -0.09775854647159576,
      -0.013090168125927448,
      -0.024841101840138435,
      -0.003642328316345811,
      0.0091636897996068,
      0.05805030092597008,
      0.11151033639907837,
      -0.017181409522891045,
      -0.0010763158788904548,
      -0.00561006972566247,
      0.0015890690265223384,
      -0.0011455472558736801,
      0.019514277577400208,
      0.038204606622457504,
      0.04494713991880417,
      -0.0004950830480083823,
      0.0171800684183836,
      0.0007222918211482465,
      -0.043736085295677185,
      0.022837188094854355,
      0.03337308391928673,
      -0.06916487216949463,
      -0.03435394540429115,
      0.014616564847528934,
      0.06199764832854271,
      -0.004478281829506159,
      -0.051579538732767105,
      -0.08984298259019852,
      0.10008758306503296,
      -0.0006153605063445866,
      -0.032252319157123566,
      0.006053397431969643,
      0.0011327923275530338,
      -0.003655281150713563,
      -0.03283916041254997,
      0.03143157437443733,
      -0.02145760878920555,
      -0.016882553696632385,
      -0.08256316930055618,
      -0.05154328793287277,
      -0.0316338874399662,
      0.006634835619479418,
      0.033793509006500244,
      0.018304482102394104,
      0.0004875420418102294,
      -0.03826446086168289,
      0.02071923203766346,
      0.05496830865740776,
      0.06119731068611145,
      -0.04984654113650322,
      0.06043926998972893,
      0.0021362605039030313,
      0.03939446434378624,
      0.018308304250240326,
      -0.05327710509300232,
      -0.037995968014001846,
      -0.017693670466542244,
      0.04434376582503319,
      0.00368898524902761,
      -0.07583703845739365,
      -0.012534691020846367,
      0.0039051624480634928,
      -0.011607197113335133,
      -0.051433391869068146,
      -0.020548254251480103,
      -0.005151878576725721,
      0.01764923520386219,
      -0.0017736100126057863,
      0.032138850539922714,
      0.04771365970373154,
      -0.05187182500958443,
      -0.08069267123937607,
      -0.023715004324913025,
      -0.010983556509017944,
      -0.017789334058761597,
      -0.017655208706855774,
      -0.0076424600556492805,
      -0.02412247471511364,
      0.017773931846022606,
      -0.03990383446216583,
      0.008064284920692444,
      -0.04918836057186127,
      -0.05867701768875122,
      0.03065362572669983,
      -0.0026756504084914923,
      -0.03771590813994408,
      0.06711632758378983,
      0.08326838165521622,
      -0.030939659103751183,
      -0.014438875950872898,
      0.009801819920539856,
      0.04116552695631981,
      -0.0313972532749176,
      -0.04356478154659271,
      0.015388552099466324,
      0.024575289338827133,
      -0.015571624040603638,
      -0.015260009095072746,
      -0.021511513739824295,
      -0.014631061814725399,
      0.024632500484585762,
      0.04370393231511116,
      -0.03960205987095833,
      -0.05704634636640549,
      0.01580660417675972,
      -0.04705134779214859,
      0.011220323853194714,
      -0.017129840329289436,
      0.05539819225668907,
      -0.04635036736726761,
      -0.018459998071193695,
      0.029539164155721664,
      0.04363016411662102,
      0.04893394932150841,
      -0.01761803776025772,
      -0.040054239332675934,
      -0.028636226430535316,
      0.03856389597058296,
      -0.04181923717260361,
      -0.046712476760149,
      -0.06322504580020905,
      -0.007655418943613768,
      0.04607374593615532,
      -0.007381468545645475,
      0.05138685554265976,
      0.009009027853608131,
      -0.033487655222415924,
      0.006532058585435152,
      -0.04231685772538185,
      0.02579747885465622,
      0.07247122377157211,
      -0.01983972080051899,
      -0.06463950872421265,
      0.004704106133431196,
      0.049796897917985916,
      0.06950019299983978,
      0.060421399772167206,
      -0.031630195677280426,
      -0.019312303513288498,
      -0.06378466635942459,
      -0.0007193572819232941,
      0.057777710258960724,
      -0.03383616358041763,
      0.02126593329012394,
      0.09456049650907516,
      -0.023639120161533356,
      -0.0559220090508461,
      0.013804681599140167,
      -0.04327578470110893,
      0.07215997576713562,
      0.04706482216715813,
      -0.019338665530085564,
      -0.05059981718659401,
      -0.011945757083594799,
      -0.04901565611362457,
      -0.006637678947299719,
      0.025787830352783203,
      0.09099901467561722,
      0.06490761786699295,
      0.019370535388588905,
      0.04004631191492081,
      -0.1254062056541443,
      -0.003074659500271082,
      -0.012550382874906063,
      0.12938417494297028,
      0.05343475192785263,
      -0.03188786283135414,
      -0.008948350325226784,
      0.04195065796375275,
      -0.02478630281984806,
      -0.10935117304325104,
      0.006722119636833668,
      -0.011327135376632214,
      0.03396621346473694,
      -0.050851937383413315,
      0.0009032525704242289,
      -0.027642475441098213,
      0.015842629596590996,
      0.09011755138635635,
      -0.006671607494354248,
      -0.007569001987576485,
      -0.029070891439914703,
      -0.03812669590115547,
      0.008923224173486233,
      -0.008881611749529839,
      -0.000508312601596117,
      -0.08287698030471802,
      -0.0022102012299001217,
      0.04242812842130661,
      -0.0033031299244612455,
      -0.02708265371620655,
      0.018615679815411568,
      -0.12113090604543686,
      -0.032132625579833984,
      -0.027637483552098274,
      0.04831898584961891,
      -0.010439010336995125,
      0.03974711149930954,
      0.11007044464349747,
      -0.053237538784742355,
      -0.059056613594293594,
      -0.016004301607608795,
      0.0649997815489769,
      -0.004948182497173548,
      -0.006160047836601734,
      -0.009080114774405956,
      -0.006427417974919081,
      -0.053332287818193436,
      -0.011790190823376179,
      -0.014496007934212685,
      -0.031218482181429863,
      -0.05696982890367508,
      0.010839808732271194,
      -0.1166362389922142,
      -0.00023987273743841797,
      -0.024923497810959816,
      0.01762789860367775,
      0.008141455240547657,
      -0.05294620245695114,
      0.0679205134510994,
      -0.0133587084710598,
      0.008591566234827042,
      0.012500327080488205,
      -0.08609756082296371,
      -0.0358959399163723,
      0.05239003896713257,
      0.0674562081694603,
      -0.005482973996549845,
      0.01566018909215927,
      0.06345478445291519,
      0.05974630266427994,
      0.030764825642108917,
      -0.005687699653208256,
      0.001562576275318861,
      0.041455503553152084,
      0.017294233664870262,
      0.05081641674041748,
      -0.01412685215473175,
      -0.047583531588315964,
      0.033129725605249405,
      0.06917177885770798,
      0.023926759138703346,
      -0.0037829598877578974,
      -0.05460848659276962,
      -0.04155443608760834,
      0.023597994819283485,
      -0.014038583263754845,
      -0.05594932287931442,
      0.011036178097128868,
      -0.05564737319946289,
      -0.01765381172299385,
      -0.02956492453813553,
      -0.0071996059268713,
      0.06398924440145493,
      0.0022496283054351807,
      0.04706595465540886,
      0.05233007296919823,
      -0.07856611162424088,
      -0.018931280821561813,
      0.04073648527264595,
      0.04557477682828903,
      -0.057746849954128265,
      0.0706954076886177,
      0.0075662764720618725,
      -0.0721302181482315,
      -0.06765367835760117,
      -0.0537768229842186,
      -0.005182961467653513,
      -0.059476256370544434,
      -0.027303840965032578,
      0.03486648201942444,
      0.014013934880495071,
      -0.0350082591176033,
      -0.05533363297581673,
      -0.07139389961957932,
      0.04133043810725212,
      0.04137704521417618,
      -0.04450023174285889,
      -0.008833491243422031,
      0.04321201518177986,
      -0.026637790724635124,
      0.024711716920137405,
      -0.04095173627138138,
      -0.004807245451956987,
      -0.08505173772573471,
      0.030282724648714066,
      -0.039885036647319794,
      0.056564703583717346,
      -0.04092499241232872,
      -0.007835057564079762,
      0.06327200680971146,
      0.04447610676288605,
      0.019316252321004868,
      -0.017293471843004227,
      -0.017462896183133125,
      0.024678723886609077,
      -0.018299251794815063,
      0.01383162010461092,
      0.07553265988826752,
      0.0570618137717247,
      -0.0510045625269413,
      -0.019332516938447952,
      0.04840236157178879,
      0.0123642822727561,
      0.00588349811732769,
      0.021800508722662926,
      -0.08276460319757462,
      0.053619787096977234,
      0.04557076841592789,
      -0.0016518306219950318,
      -0.01791093498468399,
      0.035084571689367294,
      -0.019191503524780273,
      -0.0819309651851654,
      0.06464599072933197,
      -0.051042672246694565,
      -0.06792682409286499,
      0.020156390964984894,
      0.016243407502770424,
      0.026813261210918427,
      0.0755905956029892,
      0.07532200217247009,
      0.0665447786450386,
      -0.007359945215284824,
      -0.0014531475026160479,
      -0.0006619467749260366,
      -0.019073566421866417,
      -0.09485930949449539,
      -0.016529904678463936,
      -0.025753267109394073,
      -0.017610548064112663,
      -0.029441677033901215,
      -0.04327945038676262,
      -0.007314897142350674,
      -0.09413538128137589,
      0.05842190980911255,
      0.025370899587869644,
      0.03822796419262886,
      0.05558158829808235,
      -0.003364875912666321,
      -0.06597238779067993,
      0.015299547463655472,
      -0.06529851257801056,
      0.007555186748504639,
      -0.07255391031503677,
      -0.010354100726544857,
      0.011865402571856976,
      0.012815200723707676,
      -0.03891156613826752,
      0.012877233326435089,
      0.008797140792012215,
      0.035092972218990326,
      0.045579683035612106,
      -0.011272119358181953,
      0.04211009666323662,
      0.03530820086598396,
      -0.01224411278963089,
      0.045689307153224945,
      0.03575848788022995,
      -0.022876299917697906,
      -0.013326970860362053,
      0.024029524996876717,
      0.011105062440037727,
      -0.0069046784192323685,
      0.021027378737926483,
      0.0343472920358181,
      -0.10407235473394394,
      0.06773177534341812,
      0.012791812419891357,
      0.034109849482774734,
      -0.032276254147291183,
      -0.030814286321401596,
      -0.03475194051861763,
      0.011183062568306923,
      -0.021724754944443703,
      0.09137476235628128,
      0.016726667061448097,
      0.03213146701455116,
      0.02011887915432453,
      0.03462493419647217,
      0.07672113925218582,
      -0.002919719321653247,
      -0.039679594337940216,
      0.08078719675540924,
      0.07510532438755035,
      -0.0065511674620211124,
      -0.003841981291770935,
      -0.025743653997778893,
      0.03712063282728195
    ],
    [
      0.020352279767394066,
      -0.10192619264125824,
      0.027544787153601646,
      0.018539074808359146,
      0.0506586879491806,
      -0.12148124724626541,
      0.08006155490875244,
      0.04800720140337944,
      -0.03622240200638771,
      -0.023698411881923676,
      0.041631001979112625,
      -0.036568667739629745,
      -0.025542346760630608,
      0.043251883238554,
      0.05100632831454277,
      0.06486490368843079,
      -0.00021917495178058743,
      -0.026745805516839027,
      0.012978103943169117,
      -0.003380862297490239,
      0.019696926698088646,
      -0.047087181359529495,
      0.05819128826260567,
      -0.07702039927244186,
      -0.05452491343021393,
      -0.029840262606739998,
      0.052351146936416626,
      -0.050159767270088196,
      -0.05958763137459755,
      -0.06634770333766937,
      -0.027600392699241638,
      0.011744841001927853,
      -0.01742800511419773,
      0.01521231234073639,
      0.04638171195983887,
      0.003157211234793067,
      -0.060316476970911026,
      -0.035588588565588,
      -0.05096748471260071,
      -0.01848866418004036,
      -0.0658574104309082,
      0.048326749354600906,
      -0.009031849913299084,
      0.02227519266307354,
      0.0801146849989891,
      0.040064889937639236,
      -0.07405638694763184,
      0.004143438767641783,
      -0.024879945442080498,
      0.051012009382247925,
      -0.10922594368457794,
      0.10408619791269302,
      -0.059023842215538025,
      -0.03425075486302376,
      0.04391474649310112,
      -0.03561178967356682,
      0.05026717111468315,
      -0.0015431620413437486,
      0.050088368356227875,
      0.002348880982026458,
      -0.05293687433004379,
      -0.04309936612844467,
      -0.005128742661327124,
      -0.02341380901634693,
      -0.08215712010860443,
      -0.02327212505042553,
      0.004848298616707325,
      -0.03982478752732277,
      -0.011048223823308945,
      0.03202215954661369,
      -0.008425840176641941,
      0.07222829014062881,
      0.028252430260181427,
      0.041138309985399246,
      0.04910948872566223,
      -0.03258708491921425,
      -0.005263178609311581,
      0.018351150676608086,
      0.08791851252317429,
      -0.03602539002895355,
      0.03056284785270691,
      -0.06524237245321274,
      -0.052590545266866684,
      -0.006969408132135868,
      -0.004712048452347517,
      -0.022506877779960632,
      -0.04825649783015251,
      -0.018445493653416634,
      -0.05465551093220711,
      0.04464221000671387,
      -0.027774203568696976,
      -0.0704384371638298,
      -0.0569569356739521,
      -0.024634186178445816,
      -0.07293190807104111,
      0.07544559240341187,
      -0.0005336629692465067,
      0.02970465086400509,
      -0.048672180622816086,
      0.02189113199710846,
      0.010409312322735786,
      0.0770002081990242,
      -0.013569818809628487,
      -0.01870960369706154,
      0.022964293137192726,
      0.013009941205382347,
      0.030184296891093254,
      -0.061273183673620224,
      -0.03826689347624779,
      -0.013925787061452866,
      0.02739170379936695,
      0.016856497153639793,
      -0.08281251043081284,
      -0.007607620675116777,
      -0.01605246402323246,
      0.007768404670059681,
      -0.06859460473060608,
      -0.015115727670490742,
      0.03025977499783039,
      -0.016750270500779152,
      0.033942390233278275,
      -0.04987544193863869,
      0.04446612671017647,
      0.028831616044044495,
      -0.036031030118465424,
      0.02147662825882435,
      0.020452937111258507,
      -0.01430119201540947,
      -0.05918644741177559,
      0.009033535607159138,
      0.0023254796396940947,
      -0.08494304865598679,
      -0.044619593769311905,
      -0.02200084924697876,
      0.05583394691348076,
      0.020618285983800888,
      0.02482922188937664,
      0.04617771506309509,
      -0.005150902550667524,
      0.04560992121696472,
      -0.0772094652056694,
      -0.023115282878279686,
      0.04627211019396782,
      -0.11449430137872696,
      0.0130529860034585,
      0.09038043767213821,
      -0.05568823590874672,
      -0.029347101226449013,
      -0.07646551728248596,
      -0.013322627171874046,
      0.05373789370059967,
      -0.001998305320739746,
      0.037349846214056015,
      -0.060452185571193695,
      0.027637233957648277,
      -0.007115290034562349,
      -0.0005881621618755162,
      -0.06514550745487213,
      0.007327530067414045,
      -0.04342876002192497,
      0.036187123507261276,
      -0.011931473389267921,
      0.04917291924357414,
      0.007291960064321756,
      0.015973500907421112,
      0.05530621483922005,
      -0.045209452509880066,
      0.006952059455215931,
      0.001025894656777382,
      -0.05640731006860733,
      0.0019519091583788395,
      -0.06553288549184799,
      0.010159275494515896,
      -0.05425780639052391,
      0.013925205916166306,
      0.0119202621281147,
      -0.04124642536044121,
      0.0773814395070076,
      0.032984163612127304,
      0.02561519294977188,
      -0.036059144884347916,
      -0.07367183268070221,
      0.025899870321154594,
      0.05369812995195389,
      0.03121626004576683,
      0.08210249990224838,
      -0.003957045264542103,
      -0.028150293976068497,
      0.008917300030589104,
      -6.0349029809003696e-05,
      -0.028822243213653564,
      -0.019216574728488922,
      -0.07835012674331665,
      -0.013580757193267345,
      -0.06052703410387039,
      0.036821696907281876,
      -0.014422189444303513,
      -0.030054543167352676,
      -0.031382571905851364,
      -0.047942645847797394,
      0.03303167596459389,
      0.03481379151344299,
      0.03306117653846741,
      -0.012716642580926418,
      -0.018874261528253555,
      0.015546838752925396,
      0.007517616730183363,
      -0.010999580845236778,
      -0.02125147543847561,
      0.056602977216243744,
      -0.015757938846945763,
      0.03868934512138367,
      0.03758087754249573,
      -0.013924730010330677,
      -0.038957882672548294,
      -0.0068721589632332325,
      0.0610814206302166,
      -0.0380181185901165,
      -0.05479927361011505,
      -0.009095658548176289,
      -0.001129943528212607,
      -0.045502498745918274,
      0.0464591458439827,
      0.06867825984954834,
      -0.07540617883205414,
      0.038600433617830276,
      -0.05965132638812065,
      -0.01734226942062378,
      -0.019189300015568733,
      0.03855485841631889,
      0.030818328261375427,
      0.02580748125910759,
      0.09514518082141876,
      0.04932784661650658,
      0.06321311742067337,
      0.06632798910140991,
      0.02274530567228794,
      -0.028465434908866882,
      -0.031337447464466095,
      0.004465556237846613,
      -0.019765589386224747,
      0.012299797497689724,
      0.033841539174318314,
      0.0317048504948616,
      0.06879477947950363,
      0.009627895429730415,
      -0.015664855018258095,
      0.0009818610269576311,
      0.005718008149415255,
      -0.06067018210887909,
      -0.01883629336953163,
      -0.05964961275458336,
      -0.04506433755159378,
      -0.04099180921912193,
      0.020079419016838074,
      0.0141425346955657,
      -0.00584364403039217,
      0.08316156268119812,
      -0.009026425890624523,
      -0.012775985524058342,
      -0.050512298941612244,
      0.03318355232477188,
      0.06482390314340591,
      0.022289182990789413,
      -0.08299461752176285,
      -0.06281595677137375,
      -0.026283251121640205,
      0.024078164249658585,
      0.04433939605951309,
      -0.0120582589879632,
      -0.002633890835568309,
      -0.0795363262295723,
      0.12924759089946747,
      -0.03321147337555885,
      0.003619719762355089,
      -0.008012905716896057,
      0.06751175224781036,
      -0.06749562174081802,
      0.0068062166683375835,
      0.015883779153227806,
      -0.014631785452365875,
      -0.020771685987710953,
      0.10556771606206894,
      0.0188742782920599,
      -0.014977932907640934,
      0.10158489644527435,
      -0.02426125667989254,
      0.04175499454140663,
      0.0480632483959198,
      -0.005657715257257223,
      -0.08384779840707779,
      -0.03252750635147095,
      -0.0009191748686134815,
      0.006247819401323795,
      -0.017966629937291145,
      -0.03016756847500801,
      -0.02678012289106846,
      0.00686729745939374,
      0.05847015976905823,
      0.048780057579278946,
      0.019042372703552246,
      -0.025412317365407944,
      0.007064554374665022,
      0.013084333389997482,
      0.08371420204639435,
      0.006448634900152683,
      0.033048033714294434,
      0.008986234664916992,
      -0.001151340315118432,
      -0.010955868288874626,
      -0.09387962520122528,
      0.050410397350788116,
      0.01051400788128376,
      0.010814093984663486,
      0.016153164207935333,
      -0.04101167246699333,
      -0.04185883328318596,
      0.07245540618896484,
      -0.07462745159864426,
      -0.02458304539322853,
      -0.012813526205718517,
      0.026251614093780518,
      -0.0011641058372333646,
      -0.03516378253698349,
      0.0084309633821249,
      0.09303165227174759,
      -0.006934888195246458,
      -0.03695967048406601,
      -0.003805898828431964,
      0.047226034104824066,
      0.018892688676714897,
      -0.02383403480052948,
      0.03962334245443344,
      -0.10265276581048965,
      0.0007414924330078065,
      0.01804795116186142,
      -0.03595859557390213,
      -0.03171609342098236,
      -0.007972355000674725,
      0.025803815573453903,
      -0.010781297460198402,
      0.012125280685722828,
      0.039445459842681885,
      0.024301687255501747,
      -2.5905741495080292e-05,
      -0.07022727280855179,
      0.06428087502717972,
      0.0002563213056419045,
      -0.024703791365027428,
      0.022481868043541908,
      -0.016689296811819077,
      -0.00567193329334259,
      -0.046645987778902054,
      0.03149007260799408,
      0.02868073433637619,
      0.034716371446847916,
      0.08583907037973404,
      0.023022379726171494,
      -0.030012819916009903,
      0.0494145005941391,
      0.08021985739469528,
      0.017632080242037773,
      -0.024289311841130257,
      -0.04518863186240196,
      0.012922693975269794,
      -0.04213119298219681,
      -0.026639200747013092,
      -0.009319425560534,
      -0.037501294165849686,
      0.02470938302576542,
      -0.0019983649253845215,
      -0.050380196422338486,
      0.03434309363365173,
      0.01676204614341259,
      0.05881758779287338,
      0.019623752683401108,
      0.052907269448041916,
      0.02800995670258999,
      0.07369347661733627,
      -0.019565245136618614,
      -0.05377620458602905,
      -0.011424577794969082,
      -0.049440279603004456,
      0.05093267932534218,
      0.08572271466255188,
      0.049343593418598175,
      -0.024469710886478424,
      -0.03696437180042267,
      0.042616065591573715,
      0.05582962557673454,
      0.09428143501281738,
      0.001095092506147921,
      0.04001818969845772,
      -0.007010506931692362,
      -0.0616629458963871,
      -0.005606352351605892,
      0.030640549957752228,
      0.008206984959542751,
      0.026131317019462585,
      -0.07721227407455444,
      0.07820160686969757,
      -0.0456722117960453,
      0.05108821019530296,
      -0.009141827002167702,
      -0.0645078718662262,
      -0.053581610321998596,
      0.02049470879137516,
      0.023438405245542526,
      0.0970744639635086,
      -0.010834511369466782,
      -0.04345006123185158,
      0.012636315077543259,
      -0.013435766100883484,
      -0.05692710354924202,
      0.0444493442773819,
      -0.03298870846629143,
      -0.0015273437602445483,
      0.0011539350962266326,
      -0.012208929285407066,
      -0.015020053833723068,
      0.035920485854148865,
      0.01037160586565733,
      -0.04458294063806534,
      -0.04027777910232544,
      -0.01903320476412773,
      -0.03100893646478653,
      0.005270409397780895,
      -0.04541284590959549,
      0.040692202746868134,
      -0.013955894857645035,
      -0.05661763250827789,
      -0.058049675077199936,
      -0.025923673063516617,
      0.018021035939455032,
      0.004378827288746834,
      0.024100275710225105,
      -0.04755039140582085,
      0.05700533092021942,
      0.05041324347257614,
      0.009191523306071758,
      0.01466300617903471,
      0.03673196956515312,
      0.03405168280005455,
      0.06658812612295151,
      0.03878342732787132,
      0.014209257438778877,
      -0.012589546851813793,
      0.016231292858719826,
      0.02967638336122036,
      0.021561823785305023,
      -0.06138858571648598,
      -0.09302002936601639,
      -0.03976209834218025,
      0.02263394184410572,
      0.00195073033683002,
      -0.08058035373687744,
      -0.046138837933540344,
      -0.025156624615192413,
      -0.0027674760203808546,
      0.033047303557395935,
      -0.005325953476130962,
      0.08286326378583908,
      -0.019155053421854973,
      0.0024717755150049925,
      -0.006150430534034967,
      -0.001576090115122497,
      0.006301690358668566,
      0.04116018861532211,
      -0.057184427976608276,
      -0.0007634010980837047,
      0.04788779839873314,
      0.050275739282369614,
      -0.022377492859959602,
      0.04898326098918915,
      0.013111217878758907,
      -0.0357184037566185,
      -0.06993325799703598,
      0.004647721070796251,
      0.04633030295372009,
      0.00987681932747364,
      -0.02599463053047657,
      -0.018821343779563904,
      -0.05658164620399475,
      0.05603336915373802,
      -0.03466089814901352,
      -0.025967298075556755,
      -0.02830801159143448,
      0.01734861731529236,
      0.004855385050177574,
      0.03281398490071297,
      -0.04722976312041283,
      -0.07030120491981506,
      -0.07739242911338806,
      0.07318910211324692,
      0.06316018104553223,
      -0.07637130469083786,
      -0.037933941930532455,
      -0.04331505298614502,
      -0.04747425764799118,
      0.0034169559367001057,
      0.027632826939225197,
      0.016128139570355415,
      0.03461449220776558
    ],
    [
      -0.018976639956235886,
      0.003407361451536417,
      -0.03478971868753433,
      -0.005776493810117245,
      -0.029176004230976105,
      -0.06654151529073715,
      -0.0629458874464035,
      -0.03528544306755066,
      0.04665295407176018,
      0.07593940198421478,
      -0.022397782653570175,
      -0.001516263117082417,
      0.059928782284259796,
      -0.016244608908891678,
      0.06778378039598465,
      0.04717963561415672,
      -0.0003152208519168198,
      -0.04263550788164139,
      -0.0023689644876867533,
      -0.004766939673572779,
      -0.06388897448778152,
      0.007648875005543232,
      0.00863652490079403,
      0.052707698196172714,
      -0.006974711548537016,
      -0.017535457387566566,
      0.005214115139096975,
      0.011556492187082767,
      -0.07408712804317474,
      -0.01806977204978466,
      0.026996424421668053,
      -0.003424158552661538,
      0.05221119895577431,
      0.04464317113161087,
      0.020065082237124443,
      -0.038666728883981705,
      0.05258813500404358,
      -0.010445467196404934,
      -0.026702556759119034,
      -0.02303444594144821,
      -0.11693508923053741,
      0.02189093455672264,
      -0.07894057780504227,
      -0.010863319970667362,
      0.046432726085186005,
      -0.015046863816678524,
      0.0016359243309125304,
      -0.024388393387198448,
      0.06575441360473633,
      -0.013817135244607925,
      -0.006104529369622469,
      0.00794918742030859,
      0.07524345815181732,
      0.03299538418650627,
      -0.013400615192949772,
      -0.04322829470038414,
      -0.08399439603090286,
      -0.11489824950695038,
      0.09095164388418198,
      -0.010007219389081001,
      -0.15164245665073395,
      0.03920213133096695,
      -0.010567983612418175,
      0.08487197756767273,
      0.0017337753670290112,
      -0.02845693565905094,
      0.023263247683644295,
      0.06939149647951126,
      -0.0017646998167037964,
      -0.03585182502865791,
      -0.019927354529500008,
      8.197844726964831e-05,
      -0.06371524930000305,
      -0.02297263592481613,
      0.03455345332622528,
      0.001973763108253479,
      0.0065185860730707645,
      -0.002415500581264496,
      -0.0259522944688797,
      -0.09138892590999603,
      0.08961842954158783,
      -0.007242338731884956,
      0.038771241903305054,
      0.032894205302000046,
      -0.06468696147203445,
      0.0064527192153036594,
      -0.013384833931922913,
      0.12112109363079071,
      0.02006383240222931,
      -0.010603290051221848,
      -0.02465132437646389,
      0.013766185380518436,
      0.04626930132508278,
      -0.09319578856229782,
      -0.01761816255748272,
      0.005558876320719719,
      0.0023837317712605,
      0.002434596186503768,
      -0.09743592888116837,
      -0.005988533142954111,
      -0.10800352692604065,
      -0.0014959354884922504,
      0.053976964205503464,
      0.03080431930720806,
      -0.0041497391648590565,
      0.010606931522488594,
      0.04211758077144623,
      -0.10317855328321457,
      -0.032340455800294876,
      -0.027207715436816216,
      -0.03284694626927376,
      0.06412778049707413,
      -0.009839282371103764,
      0.006487845443189144,
      0.0027761394158005714,
      0.0035089312586933374,
      0.015074593015015125,
      -0.03427896648645401,
      -0.003263333113864064,
      0.02831956557929516,
      0.010750623419880867,
      0.03676287829875946,
      -0.03776651248335838,
      0.020327022299170494,
      0.03678802028298378,
      0.030152618885040283,
      -0.040843136608600616,
      0.016623856499791145,
      0.012709492817521095,
      0.04003935679793358,
      0.0009380525443702936,
      -0.04724240303039551,
      -0.04351120442152023,
      -0.04779208451509476,
      -0.038464948534965515,
      -0.05905304476618767,
      -0.029775451868772507,
      -0.022123180329799652,
      0.0052190558053553104,
      -0.03741069138050079,
      0.012070071883499622,
      -0.10285147279500961,
      -0.002239695517346263,
      0.1277482509613037,
      0.056271038949489594,
      0.05231258273124695,
      -0.013926989398896694,
      -0.05215779319405556,
      0.004499358590692282,
      -0.04437221959233284,
      0.05089394003152847,
      0.056210242211818695,
      -0.06846974790096283,
      0.0831555500626564,
      -0.02451585978269577,
      -0.06947547197341919,
      -0.009919416159391403,
      -0.0050851646810770035,
      0.03583521395921707,
      0.014228406362235546,
      -0.02664300426840782,
      -0.015330751426517963,
      0.0004968991270288825,
      -0.05607956647872925,
      -0.04811953008174896,
      0.039470795542001724,
      -0.05873075872659683,
      -0.014947322197258472,
      0.02307393029332161,
      -0.05911758169531822,
      -0.010160590521991253,
      0.008214501664042473,
      -0.08753117173910141,
      -0.0017404442187398672,
      0.06295602768659592,
      -0.05984216928482056,
      -0.009075630456209183,
      -0.04073061794042587,
      -0.042377326637506485,
      0.014060128480196,
      0.009154368191957474,
      -0.0014915649080649018,
      0.09378774464130402,
      -0.000410834705689922,
      0.00841141864657402,
      -0.031859055161476135,
      0.02069169655442238,
      0.02652478963136673,
      -0.013228336349129677,
      -0.08267220854759216,
      0.00016361390589736402,
      0.04003627225756645,
      -0.01616620644927025,
      0.02279862016439438,
      0.07253635674715042,
      0.11635054647922516,
      0.03264394775032997,
      0.006254069972783327,
      -0.04480714723467827,
      -0.0329124890267849,
      0.060519926249980927,
      -0.030417516827583313,
      0.03269834443926811,
      -0.06805883347988129,
      -0.03159838542342186,
      0.002632716903463006,
      0.07146994769573212,
      -0.025911303237080574,
      -0.010410821996629238,
      0.04445270076394081,
      -0.03657427802681923,
      0.02971602790057659,
      0.07234600931406021,
      -0.05320020020008087,
      -0.03229907527565956,
      0.05937418341636658,
      -0.007726779207587242,
      0.033979784697294235,
      -0.01599721424281597,
      0.023869039490818977,
      -0.05848151072859764,
      -0.0030950980726629496,
      0.004017460159957409,
      -0.042874693870544434,
      -0.03409319743514061,
      0.04182758927345276,
      -0.11169419437646866,
      -0.018592694774270058,
      0.0841175988316536,
      0.0697406604886055,
      0.024396171793341637,
      0.04893139749765396,
      0.011419758200645447,
      0.03422730043530464,
      -0.08640063554048538,
      -0.041645973920822144,
      0.020488034933805466,
      -0.00045591191155835986,
      0.020251097157597542,
      -0.021604161709547043,
      -0.009760829620063305,
      -0.057435885071754456,
      0.02268923446536064,
      -0.05268465727567673,
      0.060098424553871155,
      0.004226981662213802,
      0.003503544023260474,
      -7.405249198200181e-05,
      -0.010860432870686054,
      0.002132668159902096,
      0.016652299091219902,
      0.002005975693464279,
      0.037881121039390564,
      0.019241947680711746,
      0.009027549996972084,
      0.040957603603601456,
      -0.001209809328429401,
      -0.09609515964984894,
      -0.022595401853322983,
      -0.009929521940648556,
      0.04627375677227974,
      0.08090850710868835,
      -0.03667597472667694,
      0.06657852977514267,
      0.034639209508895874,
      -0.02277587726712227,
      0.036291055381298065,
      -0.05123622715473175,
      -0.06936351209878922,
      -0.021048445254564285,
      -0.01509148720651865,
      0.08643452823162079,
      0.043175023049116135,
      -0.0021724177058786154,
      -0.012053686194121838,
      -0.04340561851859093,
      -0.012711318209767342,
      0.028296587988734245,
      0.06455987691879272,
      -0.047073185443878174,
      -0.021541336551308632,
      -0.047517210245132446,
      0.017245370894670486,
      -0.024008316919207573,
      -0.0012097822036594152,
      0.017480676993727684,
      -0.025216765701770782,
      -0.03417319804430008,
      -0.05192120373249054,
      -0.026502113789319992,
      -0.032323308289051056,
      0.010591975413262844,
      0.05581158772110939,
      -0.04395369812846184,
      0.018615180626511574,
      -0.09440769255161285,
      -0.05684567242860794,
      -0.07767880707979202,
      0.037145595997571945,
      -0.016367504373192787,
      -0.025583764538168907,
      0.10777965933084488,
      -0.033843498677015305,
      -0.01657608151435852,
      0.01792812906205654,
      -0.07057147473096848,
      -0.02203438989818096,
      0.029552171006798744,
      0.02165159583091736,
      -0.058395516127347946,
      -0.09476955980062485,
      -0.015957532450556755,
      -0.046051811426877975,
      0.06751091778278351,
      0.03190132603049278,
      0.013958841562271118,
      0.02303507924079895,
      -0.04489004611968994,
      -0.029959319159388542,
      -0.002520208712667227,
      0.00018820122932083905,
      -0.03543275594711304,
      0.002814097562804818,
      -0.03134935349225998,
      0.03835500404238701,
      -0.027770405635237694,
      -0.05796007439494133,
      0.045050300657749176,
      0.013909943401813507,
      -0.09014692902565002,
      -0.03147754818201065,
      0.06357753276824951,
      -0.021273573860526085,
      -0.0012149893445894122,
      0.01121081504970789,
      0.04134563356637955,
      0.09348541498184204,
      -0.014438462443649769,
      0.029847409576177597,
      0.05076772719621658,
      -0.04641030356287956,
      -0.03566127270460129,
      0.0245815496891737,
      -0.05268391966819763,
      -0.06877893954515457,
      0.022259047254920006,
      0.025591177865862846,
      -0.0012615674640983343,
      0.05019484460353851,
      0.022825872525572777,
      -0.051641758531332016,
      -0.03789399564266205,
      -0.03153161704540253,
      0.04450949281454086,
      0.005121604073792696,
      -0.0036638332530856133,
      -0.051072124391794205,
      0.0020373864099383354,
      -0.00871126540005207,
      -0.059014927595853806,
      0.03795059770345688,
      0.011646820232272148,
      -0.0014280221657827497,
      0.015458139590919018,
      -0.003152008168399334,
      -0.014073451980948448,
      0.04567486792802811,
      0.0173142421990633,
      0.00707819452509284,
      0.05419984832406044,
      0.02560250461101532,
      0.025470273569226265,
      -0.03476826101541519,
      0.10811752080917358,
      -0.06376278400421143,
      0.031002167612314224,
      0.02734638936817646,
      -0.014957638457417488,
      -0.02350618876516819,
      0.03280137851834297,
      -0.0302529726177454,
      -0.02791568450629711,
      -0.03963994234800339,
      0.052536532282829285,
      0.009678871370851994,
      0.056709978729486465,
      0.050603874027729034,
      -0.05698167532682419,
      -0.04236194118857384,
      0.019657064229249954,
      -0.03029637597501278,
      -0.031984660774469376,
      -0.0003997362800873816,
      0.026812763884663582,
      -0.03386082127690315,
      -0.03597359359264374,
      -0.03394914045929909,
      -0.01799679920077324,
      -0.042668651789426804,
      -0.023074528202414513,
      0.04563858360052109,
      0.027140475809574127,
      0.05346009135246277,
      0.032454028725624084,
      -0.049261584877967834,
      0.040513888001441956,
      -0.005048847757279873,
      -0.0005694753490388393,
      0.043552838265895844,
      0.042590733617544174,
      -0.06376801431179047,
      0.0008278791210614145,
      0.06712070107460022,
      0.12274261564016342,
      -0.013914481736719608,
      0.01602143421769142,
      0.01584855280816555,
      0.035176921635866165,
      0.004621068947017193,
      0.008599165827035904,
      -0.01216596458107233,
      0.0515378974378109,
      0.05816832557320595,
      0.023575974628329277,
      0.022569911554455757,
      -0.010623220354318619,
      0.07927686721086502,
      0.05230033025145531,
      0.02522965706884861,
      -0.027913475409150124,
      0.024426700547337532,
      0.007577279582619667,
      -2.1312741864676354e-06,
      0.1290600597858429,
      0.0348953939974308,
      0.04266337677836418,
      0.05213455483317375,
      0.022372979670763016,
      0.032693151384592056,
      -0.009561518207192421,
      0.04696284234523773,
      -0.02817646414041519,
      -0.0021029398776590824,
      0.05216506868600845,
      -0.0026419656351208687,
      0.029621951282024384,
      0.016466034576296806,
      0.035991717129945755,
      -0.03206025809049606,
      -0.01922197826206684,
      0.028681447729468346,
      -0.05716224014759064,
      0.0057602194137871265,
      -0.028432169929146767,
      -0.06933984905481339,
      -0.0004571186436805874,
      0.08284029364585876,
      0.029987646266818047,
      0.03565893694758415,
      0.01962016336619854,
      0.035358645021915436,
      0.07525069266557693,
      -0.005246587097644806,
      0.0014598065754398704,
      -0.04601997882127762,
      0.08327954262495041,
      0.01858551800251007,
      0.05253952741622925,
      -0.054613687098026276,
      0.030986972153186798,
      0.0584816075861454,
      0.026939278468489647,
      -0.016541561111807823,
      -0.033600278198719025,
      0.00502754794433713,
      0.014853437431156635,
      -0.021967407315969467,
      0.08155274391174316,
      -0.02334676682949066,
      -0.028411855921149254,
      0.0412306971848011,
      -0.04273255541920662,
      0.043665315955877304,
      0.0037054275162518024,
      0.10501854121685028,
      0.0753001943230629,
      -0.0691937729716301,
      -0.049352869391441345,
      -0.04442259296774864,
      -0.0077031985856592655,
      -0.058190617710351944,
      -0.00947610754519701,
      -0.04001444950699806,
      0.002705201506614685,
      -0.048798590898513794,
      0.04720861464738846,
      -0.024171119555830956,
      -0.04613131657242775,
      0.03466825932264328,
      -0.051134757697582245,
      -0.03380439430475235,
      -0.015574227087199688,
      0.04790491238236427
    ],
    [
      -0.023547666147351265,
      0.045039910823106766,
      -0.08115515857934952,
      0.07482635229825974,
      -0.001661567366681993,
      0.001981011126190424,
      -0.01472393237054348,
      0.07477542757987976,
      0.0414164774119854,
      0.0348774716258049,
      0.0390690453350544,
      -0.017740754410624504,
      -0.017780238762497902,
      0.047607019543647766,
      0.004682998638600111,
      -0.04087619483470917,
      0.09585056453943253,
      0.02419060282409191,
      0.008528592996299267,
      0.06520423293113708,
      -0.0408351793885231,
      0.012522942386567593,
      0.024850571528077126,
      0.06754697859287262,
      0.020897632464766502,
      -0.021870065480470657,
      -0.023088224232196808,
      0.01884562149643898,
      0.09290308505296707,
      0.0044214255176484585,
      -0.008547212928533554,
      0.002676607808098197,
      -0.06168229505419731,
      -0.00395510159432888,
      -0.028963036835193634,
      -0.029070692136883736,
      0.06894497573375702,
      -0.11445058882236481,
      0.010537611320614815,
      0.07973573356866837,
      -0.09829392284154892,
      0.006942572072148323,
      -0.04875573515892029,
      0.06432820856571198,
      0.04496964439749718,
      0.03785893693566322,
      -0.04679151251912117,
      -0.0009950017556548119,
      -0.008273006416857243,
      -0.001751112169586122,
      -0.0982716828584671,
      0.007226604036986828,
      -0.02287357486784458,
      -0.031061043962836266,
      -0.018946364521980286,
      0.08816725760698318,
      -0.06890038400888443,
      -0.06858412176370621,
      -0.06232978403568268,
      0.004287269897758961,
      0.00333798979409039,
      0.04852592572569847,
      -0.01577986590564251,
      -0.014346958138048649,
      -0.08618460595607758,
      -0.01645040512084961,
      0.10348623245954514,
      -0.02712014690041542,
      -0.03709607198834419,
      -0.0038901129737496376,
      0.014159728772938251,
      0.05224011838436127,
      -0.06035598739981651,
      -0.00010023482172982767,
      0.07421384751796722,
      -0.02599952556192875,
      0.044174738228321075,
      0.06377408653497696,
      0.07915281504392624,
      0.04972122609615326,
      0.0069213490933179855,
      0.014261513017117977,
      0.06670331209897995,
      0.033540982753038406,
      -0.01520986296236515,
      4.980015000910498e-05,
      -0.038659583777189255,
      -0.04576488956809044,
      -0.031288210302591324,
      -0.07887506484985352,
      0.01937277615070343,
      0.012432604096829891,
      0.011097246780991554,
      0.04455634951591492,
      -0.0008536551031284034,
      0.03054887242615223,
      0.04563909024000168,
      -0.02095041796565056,
      0.024691062048077583,
      0.007332771085202694,
      0.0155452536419034,
      -0.03534967452287674,
      -0.05823751166462898,
      0.011293482035398483,
      -0.051667679101228714,
      0.07450035959482193,
      0.042867355048656464,
      0.09390272200107574,
      0.014854616485536098,
      0.02498329058289528,
      0.0006699246587231755,
      -0.014892973937094212,
      -0.06846926361322403,
      0.019856834784150124,
      0.00025194030604325235,
      -0.04717384651303291,
      -0.006853803526610136,
      -0.05740519240498543,
      -0.018548794090747833,
      0.007912830449640751,
      -0.0016406872309744358,
      -0.0382450595498085,
      -0.005210607312619686,
      -0.04965269938111305,
      0.047977741807699203,
      0.12126191705465317,
      0.004021200351417065,
      0.05068397894501686,
      0.03168046101927757,
      0.06994166225194931,
      0.04985673353075981,
      -0.029854388907551765,
      0.0534820631146431,
      0.019563928246498108,
      -0.0005126477917656302,
      0.007244654465466738,
      -0.005709783174097538,
      -0.012674042955040932,
      0.08734655380249023,
      -0.02311702072620392,
      -0.054361388087272644,
      -0.045026447623968124,
      0.07012269645929337,
      0.0553700253367424,
      0.07851220667362213,
      -0.005435999482870102,
      -0.07674223929643631,
      -0.0776311457157135,
      0.021637145429849625,
      0.04811730608344078,
      0.0032403133809566498,
      0.07860671728849411,
      -0.09000038355588913,
      -0.051608938723802567,
      -0.01946352608501911,
      0.007765388116240501,
      0.0018754091579467058,
      0.06413155049085617,
      0.018475305289030075,
      -0.03745637461543083,
      0.007291682530194521,
      0.02381962165236473,
      0.08371656388044357,
      0.0059408992528915405,
      0.06252985447645187,
      0.02104278653860092,
      -0.043294262140989304,
      0.04144429415464401,
      -0.009089061059057713,
      -0.015763359144330025,
      0.04590839147567749,
      0.007396214175969362,
      0.04437239095568657,
      0.03279704600572586,
      0.017609385773539543,
      -0.02634943649172783,
      -0.055540237575769424,
      0.023400353267788887,
      0.024562397971749306,
      -0.007582127582281828,
      0.06688524782657623,
      0.0434788316488266,
      -0.0052453866228461266,
      0.11754898726940155,
      -0.041428349912166595,
      -0.09839349240064621,
      -0.03027375042438507,
      -0.008347472175955772,
      -0.042582008987665176,
      0.07996273785829544,
      -0.03998773917555809,
      0.039984043687582016,
      -0.01221696101129055,
      -0.04517953097820282,
      -0.049855343997478485,
      -0.02369857206940651,
      -0.05443357303738594,
      0.0270112082362175,
      -0.09979212284088135,
      0.033535122871398926,
      0.026957303285598755,
      0.04307474195957184,
      0.013014444150030613,
      0.07218938320875168,
      0.020694885402917862,
      0.02321651019155979,
      -0.03452656418085098,
      -0.00737027870491147,
      -0.10545498877763748,
      0.05257583037018776,
      0.007557454984635115,
      0.034178245812654495,
      0.00997721217572689,
      0.023564152419567108,
      -0.053950607776641846,
      0.012266247533261776,
      0.008214768953621387,
      0.02651163749396801,
      -0.05815771222114563,
      0.020350083708763123,
      0.0546753816306591,
      0.04862559586763382,
      -0.026997243985533714,
      -0.030846988782286644,
      -0.027181116864085197,
      0.005826223641633987,
      0.004180201329290867,
      -0.009945088066160679,
      -0.029063498601317406,
      0.03789553791284561,
      0.00748506048694253,
      -0.037233851850032806,
      0.0350412093102932,
      0.0313626192510128,
      -0.07379966974258423,
      0.0170123428106308,
      -0.003968266770243645,
      -0.025459663942456245,
      -0.07418424636125565,
      0.10100388526916504,
      -0.04037109389901161,
      0.006050538271665573,
      0.011264955624938011,
      0.017900383099913597,
      0.004599374253302813,
      -0.0632670670747757,
      0.00726920273154974,
      0.025682391598820686,
      -0.08617415279150009,
      -0.07423994690179825,
      -0.05810762196779251,
      -0.039255350828170776,
      0.07293490320444107,
      -0.0838768258690834,
      0.03265143930912018,
      -0.017067788168787956,
      0.000831236073281616,
      -0.03070344403386116,
      0.005146578885614872,
      0.05420387536287308,
      -0.007032417692244053,
      0.02754366770386696,
      -0.004065250512212515,
      0.12017876654863358,
      0.004088886082172394,
      -0.052573561668395996,
      0.010841158218681812,
      0.007942449301481247,
      0.07768707722425461,
      0.0611899271607399,
      -0.04075195640325546,
      0.04432784393429756,
      -0.0448194183409214,
      0.048720650374889374,
      -0.031221790239214897,
      -0.08313637226819992,
      0.02466331608593464,
      -0.030469628050923347,
      -0.06258656829595566,
      -0.056385692209005356,
      0.05184003338217735,
      0.00841405987739563,
      0.006214998662471771,
      -0.02807268500328064,
      -0.02038656361401081,
      0.017693575471639633,
      -0.035635676234960556,
      0.033246368169784546,
      0.02159169875085354,
      -0.00531891779974103,
      0.04709165543317795,
      0.009445274248719215,
      -0.05690990760922432,
      0.05494086816906929,
      0.04106474667787552,
      -0.060206860303878784,
      -0.059189341962337494,
      0.054836150258779526,
      0.021860603243112564,
      -0.01529066264629364,
      0.020687783136963844,
      -0.08586212247610092,
      0.07575929164886475,
      0.02397318184375763,
      0.09381422400474548,
      -0.026124129071831703,
      -0.05380996689200401,
      0.0012227464467287064,
      -0.09063992649316788,
      0.017230834811925888,
      -0.022164467722177505,
      -0.0075054834596812725,
      0.06895604729652405,
      -0.027248337864875793,
      -0.050648972392082214,
      -0.028920384123921394,
      0.07473798096179962,
      -0.01684224046766758,
      -0.03404165059328079,
      0.005904188379645348,
      -0.030112579464912415,
      -0.10745343565940857,
      -0.11438722908496857,
      -0.05780244991183281,
      0.07675992697477341,
      0.005748740863054991,
      0.013686031103134155,
      -0.03196791559457779,
      0.06073928251862526,
      -0.0550822839140892,
      0.04472405090928078,
      -0.004679576493799686,
      -0.017353586852550507,
      -0.017303062602877617,
      0.06323166191577911,
      0.009387526661157608,
      0.03505418077111244,
      -0.014966621994972229,
      -0.011287109926342964,
      -0.049082208424806595,
      0.04894815757870674,
      0.023347459733486176,
      0.05903840437531471,
      -0.0350414514541626,
      0.02190917357802391,
      -0.018041232600808144,
      0.039910998195409775,
      0.01836276985704899,
      0.004623158369213343,
      -0.002460591262206435,
      -0.014611566439270973,
      -0.04683084040880203,
      0.04932812973856926,
      0.021609917283058167,
      -0.03735041245818138,
      0.036297835409641266,
      0.018007611855864525,
      -0.011022265069186687,
      0.033193439245224,
      0.018835103139281273,
      0.029018288478255272,
      0.013905910775065422,
      -0.15157988667488098,
      -0.0006441388977691531,
      -0.024950796738266945,
      -0.02335939183831215,
      0.030279245227575302,
      0.017634913325309753,
      -0.0076918150298297405,
      -0.043552011251449585,
      0.005156893283128738,
      -0.04942722246050835,
      -0.04491572454571724,
      -0.021728668361902237,
      -0.06319976598024368,
      0.01188869122415781,
      0.019119026139378548,
      -0.04683350399136543,
      0.028280962258577347,
      0.05372602120041847,
      -0.06530580669641495,
      -0.027248922735452652,
      0.02883346751332283,
      -0.03802453354001045,
      0.006650817580521107,
      0.032613810151815414,
      -0.020051825791597366,
      0.015527945943176746,
      0.04061988741159439,
      0.09242051839828491,
      0.0035243798047304153,
      -0.06288529187440872,
      0.028404904529452324,
      -0.10457611829042435,
      0.04515659809112549,
      -0.038294125348329544,
      -0.05271584540605545,
      0.03986084833741188,
      0.09606318175792694,
      -0.03883115202188492,
      0.03879234194755554,
      -0.007131153717637062,
      -0.01721205934882164,
      -0.034280937165021896,
      0.03336481377482414,
      0.0004788516089320183,
      0.04496002569794655,
      0.03114498406648636,
      0.09273844957351685,
      -0.04429108276963234,
      0.013088060542941093,
      0.024387704208493233,
      0.018551593646407127,
      0.09664222598075867,
      0.010812059976160526,
      0.02441222406923771,
      -0.00035795007715933025,
      0.026314565911889076,
      0.04147855564951897,
      0.07683970779180527,
      -0.09870343655347824,
      0.0016278204275295138,
      0.03364128619432449,
      -0.01581009104847908,
      -0.008488654159009457,
      -0.021382758393883705,
      0.004577680490911007,
      0.015013718977570534,
      -0.014231771230697632,
      -0.001450805924832821,
      0.016848700121045113,
      -0.10785868763923645,
      -0.008016292005777359,
      -0.06336069852113724,
      -0.11880273371934891,
      0.06507407873868942,
      0.03968685492873192,
      -0.021872596815228462,
      -0.015581989660859108,
      -0.02436540275812149,
      -0.05529426783323288,
      0.07508216798305511,
      0.014626453630626202,
      -0.008437969721853733,
      -0.03960483521223068,
      0.044177137315273285,
      -0.09377302974462509,
      0.03821378946304321,
      0.011765019968152046,
      0.004768002312630415,
      -0.018758215010166168,
      -0.021867208182811737,
      -0.05327931419014931,
      -0.011579726822674274,
      0.06285625696182251,
      0.046299245208501816,
      -0.015652520582079887,
      0.06365291774272919,
      -0.05623980984091759,
      -0.01198010053485632,
      0.006391658913344145,
      0.027596022933721542,
      -0.024481244385242462,
      -0.032321762293577194,
      0.013599761761724949,
      -0.010595886036753654,
      0.03586241975426674,
      -0.09020031243562698,
      -0.0017513365019112825,
      0.04216335713863373,
      0.05074407160282135,
      -0.018749890848994255,
      0.061358556151390076,
      -0.0035367277450859547,
      -0.026683297008275986,
      0.037040114402770996,
      0.01814122125506401,
      -0.007084849756211042,
      0.08492936939001083,
      -0.005807625129818916,
      -0.0799492746591568,
      0.030114425346255302,
      -0.008554292842745781,
      -0.015325188636779785,
      0.1034797728061676,
      0.05208703503012657,
      -0.009214525111019611,
      0.019931690767407417,
      -0.04589684307575226,
      -0.029186008498072624,
      -0.000750277831684798,
      0.031810685992240906,
      -0.014877892099320889,
      0.008189825341105461,
      0.017237873747944832,
      0.04872281104326248,
      0.07727355509996414,
      -0.047217100858688354,
      -0.06061919033527374,
      0.06346999853849411,
      0.09246800094842911,
      0.03261842951178551,
      -0.07302702963352203
    ],
    [
      0.041495274752378464,
      -0.06647814810276031,
      0.02655668370425701,
      0.02531787008047104,
      0.03014019876718521,
      0.056065965443849564,
      -0.02564137987792492,
      0.028571976348757744,
      0.0030723940581083298,
      0.002588517963886261,
      0.02794024534523487,
      3.8274058169918135e-05,
      0.02673620916903019,
      0.0018080294830724597,
      0.044160425662994385,
      -0.031980518251657486,
      -0.04108817130327225,
      0.11704765260219574,
      -0.01911282166838646,
      0.04944407939910889,
      -0.005276923067867756,
      -0.022199159488081932,
      0.008442167192697525,
      -0.05224088206887245,
      -0.0442596971988678,
      0.011837124824523926,
      0.0695195347070694,
      -0.03840692713856697,
      -0.0235209409147501,
      -0.046302929520606995,
      -0.001801899983547628,
      -0.03657066449522972,
      -0.05595819652080536,
      -0.04067268967628479,
      -0.07305025309324265,
      0.015598797239363194,
      0.030833851546049118,
      -0.061960313469171524,
      0.0025221011601388454,
      -0.062800332903862,
      -0.08828222006559372,
      -0.01727924682199955,
      -0.07527225464582443,
      -0.0600413903594017,
      -0.05638062581419945,
      0.02332186885178089,
      -0.03611962869763374,
      0.10803532600402832,
      0.08354273438453674,
      0.01191079430282116,
      0.0014496240764856339,
      -0.047151558101177216,
      -0.012680686078965664,
      0.01792624592781067,
      0.05282992497086525,
      0.01166172232478857,
      -0.03261207789182663,
      -0.02150794118642807,
      -0.051523175090551376,
      0.06069450080394745,
      0.058618225157260895,
      0.008036565966904163,
      -0.09416475147008896,
      -0.07739924639463425,
      -0.055217865854501724,
      0.017394306138157845,
      0.03391741216182709,
      -0.04507112130522728,
      -0.05678911507129669,
      0.041290342807769775,
      -0.033656224608421326,
      0.05331210792064667,
      -0.04671570286154747,
      0.0343671552836895,
      0.04451999440789223,
      -0.005069591570645571,
      -0.0467710867524147,
      0.027222976088523865,
      -0.04181744530797005,
      -0.015017803758382797,
      0.03987816348671913,
      0.03125869110226631,
      -0.02594204619526863,
      0.049307141453027725,
      -0.02211969718337059,
      0.03703232854604721,
      0.011299453675746918,
      0.044140126556158066,
      -0.041248586028814316,
      0.08244947344064713,
      -0.023592093959450722,
      0.006296250503510237,
      -0.015002860687673092,
      -0.010258269496262074,
      -0.01155933365225792,
      0.07227390259504318,
      0.005772043485194445,
      -0.04064439982175827,
      -0.0035740952007472515,
      -0.03663943335413933,
      -0.0019523072987794876,
      -0.037005022168159485,
      0.006585365626960993,
      0.01347916480153799,
      0.011833468452095985,
      -0.030875682830810547,
      -0.010096278972923756,
      0.04217036813497543,
      -0.02600749582052231,
      0.01995951309800148,
      -0.03423871099948883,
      0.012572607025504112,
      -0.04618358239531517,
      0.006358996964991093,
      0.05415138602256775,
      -0.10743603110313416,
      -0.034698933362960815,
      -0.031110214069485664,
      0.03397645801305771,
      -0.03245353326201439,
      0.02981005422770977,
      -0.04048549383878708,
      0.10240504145622253,
      0.05446619912981987,
      0.024347705766558647,
      0.07395417243242264,
      -0.009272598661482334,
      0.06037019193172455,
      0.004423920996487141,
      0.04421374946832657,
      -0.023729808628559113,
      0.017900586128234863,
      0.04448768123984337,
      -0.03999685123562813,
      -0.027375394478440285,
      -0.03127918392419815,
      0.024695800617337227,
      -0.01505501102656126,
      -0.019892355427145958,
      0.01208406686782837,
      -0.021293193101882935,
      0.007108502089977264,
      -0.0415819026529789,
      -0.05462658032774925,
      0.03174817934632301,
      -0.0605379156768322,
      -0.004457076080143452,
      -0.004132226575165987,
      0.037589024752378464,
      -0.014266042970120907,
      -0.04388519003987312,
      6.395573291229084e-05,
      -0.05913807451725006,
      -0.007468220312148333,
      -0.039565883576869965,
      0.0027343840338289738,
      0.008958552032709122,
      -0.03486468642950058,
      -0.08427414298057556,
      -0.036804139614105225,
      -0.034659113734960556,
      -0.004027978517115116,
      0.06384453177452087,
      -0.04772090166807175,
      0.028497979044914246,
      -0.03099951520562172,
      -0.02415546029806137,
      0.01885906420648098,
      -0.06316709518432617,
      0.054258793592453,
      0.026112891733646393,
      -0.053024325519800186,
      -0.005695673171430826,
      0.011504319496452808,
      0.00820140354335308,
      0.05255667492747307,
      0.02402987703680992,
      0.0026839806232601404,
      0.03018779307603836,
      0.04043455794453621,
      -0.04159251227974892,
      -0.073215551674366,
      -0.0971376895904541,
      0.05524837225675583,
      0.041926153004169464,
      0.0064272149465978146,
      -0.012152747251093388,
      -0.02672250010073185,
      -0.04052400961518288,
      -0.10500531643629074,
      -0.12139646708965302,
      -0.03107582964003086,
      -0.07848931849002838,
      0.008183402940630913,
      0.002337331185117364,
      0.031238269060850143,
      -0.009873262606561184,
      0.013964039273560047,
      -0.028673570603132248,
      -0.00981952529400587,
      0.07995488494634628,
      0.02543817274272442,
      0.008330716751515865,
      0.08731000125408173,
      -0.005374682601541281,
      0.019594823941588402,
      0.006982195191085339,
      -0.08672841638326645,
      -0.05491921305656433,
      0.030853336676955223,
      0.008427288383245468,
      -0.09794029593467712,
      -0.029432790353894234,
      -0.006366297136992216,
      0.016300683841109276,
      0.019250119104981422,
      -0.023091750219464302,
      0.02498014084994793,
      0.06423160433769226,
      0.08636967092752457,
      0.04456919804215431,
      -0.09430410712957382,
      -0.0356428325176239,
      0.00595277501270175,
      -0.035976361483335495,
      -0.014516848139464855,
      0.08159776031970978,
      0.04933864250779152,
      0.03329520300030708,
      -0.038652293384075165,
      0.05385533720254898,
      0.016414612531661987,
      0.0931488573551178,
      -0.08879636228084564,
      0.03642605245113373,
      0.03222180902957916,
      0.005389512982219458,
      0.006041283253580332,
      -0.021146897226572037,
      0.012137430720031261,
      0.051383908838033676,
      -0.061160482466220856,
      -0.06243210658431053,
      0.02368321642279625,
      0.06988919526338577,
      0.005833185277879238,
      0.0071532647125422955,
      -0.036206718534231186,
      0.04231414943933487,
      0.03171354904770851,
      -0.018181325867772102,
      0.04033456742763519,
      0.06743109971284866,
      -0.08731229603290558,
      0.08813711255788803,
      -0.08628998696804047,
      -0.04032886028289795,
      -0.008808702230453491,
      0.04885486513376236,
      -0.020303165540099144,
      -0.049524858593940735,
      -0.10985587537288666,
      0.017598697915673256,
      0.12140318006277084,
      -0.09144779294729233,
      0.054273445159196854,
      0.03602054715156555,
      0.029343923553824425,
      -0.10940118879079819,
      0.030192725360393524,
      -0.05036702752113342,
      0.012575620785355568,
      -0.01620500721037388,
      -0.08623475581407547,
      -0.04799362272024155,
      0.0342748686671257,
      -0.003866649465635419,
      0.040132008492946625,
      0.01654862053692341,
      0.018055057153105736,
      -0.09610538929700851,
      0.04347393289208412,
      0.027177616953849792,
      -0.02517692744731903,
      -0.03588371351361275,
      0.005757583305239677,
      0.021706361323595047,
      0.02799001708626747,
      0.0062851677648723125,
      -0.09387613087892532,
      -0.01495333295315504,
      -0.05901937186717987,
      0.06269838660955429,
      -0.01249792892485857,
      -0.0030982671305537224,
      -0.018161842599511147,
      -0.026726778596639633,
      0.04159172996878624,
      0.0927160382270813,
      0.017124740406870842,
      0.028618542477488518,
      -0.07574906945228577,
      -0.0567496083676815,
      0.02192516438663006,
      -0.005800861399620771,
      -0.06360412389039993,
      -0.08142079412937164,
      0.07220222055912018,
      -0.09679506719112396,
      0.028379466384649277,
      -0.028460806235671043,
      -0.025941697880625725,
      0.003980834968388081,
      0.03006347082555294,
      0.025150666013360023,
      -0.019012436270713806,
      0.040910907089710236,
      -0.024696417152881622,
      -0.00605683634057641,
      0.057324860244989395,
      0.02183401584625244,
      0.004236478358507156,
      -0.06312628835439682,
      0.032614611089229584,
      -0.02181253209710121,
      -0.020542094483971596,
      0.028857657685875893,
      0.036234401166439056,
      0.03848867863416672,
      -0.12357164174318314,
      -0.03659241273999214,
      -0.06715451180934906,
      -0.012223470956087112,
      0.016975725069642067,
      -0.022790228947997093,
      0.006461478304117918,
      -0.0202040933072567,
      -0.025401271879673004,
      0.021271247416734695,
      -0.02152016945183277,
      0.02026364393532276,
      0.09042493253946304,
      0.002597152255475521,
      0.04043811559677124,
      0.04988023638725281,
      0.013395635411143303,
      0.004424570593982935,
      0.009346593171358109,
      0.027340199798345566,
      -0.005098091904073954,
      -0.02059393934905529,
      0.043326690793037415,
      -0.02057725191116333,
      0.010800804011523724,
      -0.005148141644895077,
      0.0031372960656881332,
      -0.06881245970726013,
      -0.019411349669098854,
      -0.04849391058087349,
      0.044793929904699326,
      -0.003641426097601652,
      0.01591017283499241,
      0.0687568187713623,
      -0.015773141756653786,
      -0.08096753805875778,
      -0.022614143788814545,
      0.026039108633995056,
      0.013797525316476822,
      -0.025174643844366074,
      -0.035253096371889114,
      0.00575332110747695,
      -0.03674204275012016,
      -0.0012324972776696086,
      -0.02110404334962368,
      -0.05275287106633186,
      -0.03950682282447815,
      0.018300117924809456,
      0.0885392352938652,
      0.033684056252241135,
      -0.09625037759542465,
      0.006594951264560223,
      -0.08657756447792053,
      -0.044269271194934845,
      0.03714076802134514,
      0.013868749141693115,
      -0.03513393551111221,
      -0.024234510958194733,
      -0.01749436743557453,
      0.007188223302364349,
      0.031476039439439774,
      -0.003454466350376606,
      0.10606038570404053,
      -0.05799649655818939,
      -0.05054491013288498,
      -0.04760988429188728,
      0.06558465957641602,
      -0.019027139991521835,
      -0.04717172309756279,
      -0.03123626857995987,
      0.00026270750095136464,
      -0.04367222264409065,
      -0.03508145362138748,
      0.049607764929533005,
      0.027611780911684036,
      -0.04187735170125961,
      -0.04001513496041298,
      -0.024107392877340317,
      0.03255263715982437,
      -0.05188183858990669,
      0.028398754075169563,
      0.015399854630231857,
      -0.02382892370223999,
      -0.02780826762318611,
      -0.007181137800216675,
      0.009038951247930527,
      0.04623061791062355,
      0.00701479148119688,
      -0.04339538887143135,
      -0.03550465404987335,
      -0.0035345647484064102,
      -0.044267307966947556,
      0.006862916983664036,
      -0.032895371317863464,
      0.025446997955441475,
      -0.058449842035770416,
      -0.08708490431308746,
      -0.05534544587135315,
      -0.01951713114976883,
      -0.054230622947216034,
      0.060223229229450226,
      -0.02531624771654606,
      -0.07225460559129715,
      -0.05919340252876282,
      0.08556923270225525,
      -0.006496106274425983,
      0.049308739602565765,
      -0.06121855974197388,
      -0.055318433791399,
      0.03784917667508125,
      0.05225367099046707,
      -0.023949962109327316,
      -0.0637526661157608,
      0.019968077540397644,
      -0.0572090819478035,
      -0.05279730632901192,
      0.01409106608480215,
      -0.05269348993897438,
      -0.03904086351394653,
      0.025449233129620552,
      0.03058372251689434,
      -0.004730697255581617,
      -0.038584548979997635,
      -0.01867886446416378,
      0.01496116816997528,
      -0.0872817412018776,
      -0.10427381843328476,
      0.07991490513086319,
      0.09307242184877396,
      0.023753827437758446,
      -0.020473510026931763,
      0.007352703716605902,
      0.02823374792933464,
      0.0859343633055687,
      -0.0034879602026194334,
      0.06858128309249878,
      0.05646880343556404,
      0.04253238067030907,
      -0.09000551700592041,
      -0.05693446844816208,
      0.014275972731411457,
      -0.023577911779284477,
      0.012785892002284527,
      0.012510430067777634,
      0.06137755885720253,
      0.04511578008532524,
      -0.0545135922729969,
      0.009639359079301357,
      -0.0761355459690094,
      0.003650558879598975,
      0.0357891283929348,
      -0.04210382327437401,
      -0.03588487580418587,
      0.05028172582387924,
      -0.028835151344537735,
      -0.029043596237897873,
      0.03509807214140892,
      -0.05615837872028351,
      0.05382475629448891,
      0.014179286547005177,
      -0.047650642693042755,
      0.04310161620378494,
      -4.762367098010145e-06,
      0.022333526983857155,
      0.01689121127128601,
      -0.008251962251961231,
      -0.01419402938336134,
      -0.02029407024383545,
      0.011430490761995316,
      0.015056044794619083,
      0.08488276600837708,
      0.001576269161887467,
      -0.016930323094129562,
      -0.09964336454868317
    ],
    [
      0.016043055802583694,
      0.015531327575445175,
      -0.05334453284740448,
      -0.06814044713973999,
      0.04884906858205795,
      0.048694539815187454,
      -0.038817763328552246,
      0.023088160902261734,
      -0.02154146507382393,
      -0.08586596697568893,
      -0.04049132764339447,
      -0.05109589174389839,
      -0.013177363201975822,
      0.011522171087563038,
      0.009803363122045994,
      0.00933803804218769,
      0.0038991852197796106,
      -0.05317021533846855,
      0.0369550995528698,
      -0.07895959913730621,
      -0.007484050467610359,
      -0.00825172383338213,
      0.021339189261198044,
      0.008708960376679897,
      -0.05306993052363396,
      0.08097536116838455,
      0.017234405502676964,
      -0.050668828189373016,
      -0.04381368309259415,
      0.016465624794363976,
      -0.01630817912518978,
      -0.025777803733944893,
      -0.016750026494264603,
      -0.03177553042769432,
      0.027755338698625565,
      0.058329224586486816,
      0.0547589547932148,
      0.016673125326633453,
      0.0865679532289505,
      -0.025866778567433357,
      -0.09247400611639023,
      -0.015754586085677147,
      -0.0413811169564724,
      -0.014260095544159412,
      -0.001528269611299038,
      -0.07856931537389755,
      0.04665165767073631,
      0.003341527422890067,
      0.008761811070144176,
      0.013079768046736717,
      0.015338504686951637,
      -0.01762791909277439,
      0.05088802054524422,
      -0.020296288654208183,
      0.025997735559940338,
      -0.02070672996342182,
      -0.022783754393458366,
      -0.056329093873500824,
      -0.0007333013345487416,
      0.0012409368064254522,
      -0.018631815910339355,
      0.041049789637327194,
      0.00565326726064086,
      -0.015174905769526958,
      -0.025616681203246117,
      0.03486724570393562,
      -0.04793113097548485,
      -0.0248718224465847,
      0.044127121567726135,
      -0.026033857837319374,
      0.0538669191300869,
      -0.041322410106658936,
      -0.06033176928758621,
      -0.004266262054443359,
      0.00025801468291319907,
      -0.018955936655402184,
      -0.060746148228645325,
      0.03266805782914162,
      0.043206214904785156,
      -0.015943188220262527,
      -0.031172476708889008,
      -0.008268590085208416,
      0.05075344815850258,
      -0.0068729291670024395,
      0.029882704839110374,
      -0.034190304577350616,
      -0.015666179358959198,
      -0.08547856658697128,
      0.03600126504898071,
      0.05400654301047325,
      -0.007312954403460026,
      -0.0166336540132761,
      -0.0089925117790699,
      -0.04987674579024315,
      -0.045923810452222824,
      -0.02949119359254837,
      0.02771780639886856,
      -0.02648572251200676,
      -0.03955167904496193,
      -0.04273723065853119,
      0.005098877940326929,
      0.058698464184999466,
      -0.07877187430858612,
      0.05348034203052521,
      0.016520949080586433,
      0.024983666837215424,
      -0.044875483959913254,
      -0.09935741871595383,
      0.018338274210691452,
      -0.06763565540313721,
      0.02505367249250412,
      -0.02523362822830677,
      0.08649186789989471,
      0.007595625706017017,
      -0.026316720992326736,
      0.034999046474695206,
      0.06209813430905342,
      -0.05167170614004135,
      0.04441195726394653,
      0.08172302693128586,
      0.04220317304134369,
      0.021416006609797478,
      0.009839150123298168,
      -0.010155990719795227,
      -0.04923658445477486,
      -0.0023666759952902794,
      -0.06364602595567703,
      0.03383437916636467,
      -0.0708278939127922,
      -0.004868763964623213,
      0.03028757870197296,
      0.004498571623116732,
      0.04505916312336922,
      -0.015291629359126091,
      -0.042927782982587814,
      -0.04134618863463402,
      -0.04849541559815407,
      -0.00031749316258355975,
      -0.023832393810153008,
      0.0266568586230278,
      -0.06513414531946182,
      0.031810175627470016,
      -0.043307237327098846,
      0.04682008549571037,
      -0.024824928492307663,
      0.053772445768117905,
      -0.0414695218205452,
      -0.04044729098677635,
      -0.09039205312728882,
      0.01755322515964508,
      0.016357503831386566,
      -0.013771685771644115,
      -0.05482781305909157,
      -0.036725010722875595,
      -0.009671286679804325,
      -0.01778707653284073,
      -0.017590932548046112,
      -0.010760098695755005,
      -0.00353064457885921,
      -0.014482890255749226,
      0.024778632447123528,
      -0.007169694174081087,
      0.015906428918242455,
      0.03596271201968193,
      0.06946587562561035,
      0.008587626740336418,
      0.02905377373099327,
      -0.03986114636063576,
      0.01913842000067234,
      0.04860730096697807,
      -0.01145200151950121,
      -0.09184552729129791,
      -0.04434340074658394,
      0.09914585202932358,
      -0.060221340507268906,
      -0.04080715402960777,
      -0.02837808057665825,
      -0.005636204034090042,
      -0.1238894835114479,
      -0.06057917699217796,
      0.03882543742656708,
      0.03253995254635811,
      -0.021844694390892982,
      0.043834201991558075,
      -0.007037442643195391,
      0.03425728529691696,
      0.019592154771089554,
      0.06952432543039322,
      -0.08517685532569885,
      0.016501745209097862,
      -0.05384400486946106,
      -0.02643485553562641,
      0.05023324117064476,
      0.030723227187991142,
      -0.00504170311614871,
      0.0006939661107026041,
      0.02981293946504593,
      -0.0005025140126235783,
      -0.025920219719409943,
      -0.07734237611293793,
      0.07943843305110931,
      0.047141171991825104,
      -0.05548197031021118,
      0.017359234392642975,
      -0.017829935997724533,
      -0.02834433875977993,
      0.056272123008966446,
      -0.023367872461676598,
      0.00383713748306036,
      -0.031006086617708206,
      -0.037135325372219086,
      -0.007684444542974234,
      0.052028872072696686,
      0.058788836002349854,
      0.03827311843633652,
      -0.04680665582418442,
      -0.07245031744241714,
      -0.012648046016693115,
      0.020320145413279533,
      -0.014719163067638874,
      -0.03049616888165474,
      -0.05006065592169762,
      -0.032225850969552994,
      -0.126348614692688,
      0.02274825982749462,
      0.029564082622528076,
      -0.019355760887265205,
      0.03329281508922577,
      0.09779667109251022,
      -0.036530353128910065,
      0.029646432027220726,
      0.08507897704839706,
      -0.0666266605257988,
      -0.0387459360063076,
      0.020993873476982117,
      -0.058078937232494354,
      0.06540140509605408,
      0.06432030349969864,
      -0.03402082994580269,
      -0.040975943207740784,
      0.018373677507042885,
      -0.07074598968029022,
      -0.01041875034570694,
      0.1000627726316452,
      0.025175822898745537,
      -0.08151829987764359,
      0.010940064676105976,
      0.10647105425596237,
      0.01646718941628933,
      -0.056044403463602066,
      0.0062453364953398705,
      -0.003840419929474592,
      -0.004435026552528143,
      -0.046056147664785385,
      0.060176171362400055,
      -0.07143965363502502,
      0.01242920197546482,
      -0.05880182981491089,
      0.04243567958474159,
      -0.012384860776364803,
      0.03019528277218342,
      -0.0503629706799984,
      -0.02337121032178402,
      -0.011960029602050781,
      -0.04445462301373482,
      -0.01722557470202446,
      -0.0207215603441,
      -0.0867081806063652,
      -0.047289617359638214,
      -0.062464457005262375,
      0.007662788033485413,
      0.017021751031279564,
      -0.0841405838727951,
      -0.011145425960421562,
      -0.05901603028178215,
      0.014065057039260864,
      -0.009326658211648464,
      -0.00882339384406805,
      -0.06514119356870651,
      -0.01389846857637167,
      0.0631125345826149,
      0.017263198271393776,
      0.05148230120539665,
      -0.013273272663354874,
      0.03568060323596001,
      0.017699694260954857,
      0.022205209359526634,
      -0.018287811428308487,
      0.07395041733980179,
      -0.07334905862808228,
      0.010101043619215488,
      -0.00925358571112156,
      -0.00978739745914936,
      0.007987831719219685,
      0.05016785487532616,
      0.014517211355268955,
      -0.09199175238609314,
      0.03618626669049263,
      0.03373081982135773,
      -0.0046613807789981365,
      -0.09356566518545151,
      0.031601134687662125,
      -0.031953174620866776,
      0.019440457224845886,
      0.05133194848895073,
      -0.055811893194913864,
      0.06088356301188469,
      -0.0894593745470047,
      -0.0649775043129921,
      -0.025334432721138,
      -0.019784150645136833,
      0.01578308269381523,
      -0.07399517297744751,
      0.07722202688455582,
      -0.012484541162848473,
      0.025958405807614326,
      -0.014428097754716873,
      -0.07848625630140305,
      -0.01868206448853016,
      -0.06933455914258957,
      0.084030881524086,
      0.0729287639260292,
      0.0832449421286583,
      0.031865574419498444,
      0.0535864531993866,
      -0.11394252628087997,
      -0.03233788162469864,
      0.06213829666376114,
      0.030828658491373062,
      -0.03733736276626587,
      -0.031610552221536636,
      -0.033407360315322876,
      0.028717217966914177,
      -0.005428353790193796,
      -0.024113772436976433,
      -0.004195395391434431,
      0.011680565774440765,
      0.03613908961415291,
      0.023025086149573326,
      0.03343786299228668,
      0.02587619610130787,
      -0.11033845692873001,
      0.06886401027441025,
      0.026197895407676697,
      0.024842415004968643,
      -0.08140644431114197,
      0.021365011110901833,
      0.06477579474449158,
      0.06517700850963593,
      -0.04215169697999954,
      -0.002208826132118702,
      -0.01564931683242321,
      0.029251767322421074,
      0.01976834237575531,
      0.060115132480859756,
      0.009592789225280285,
      0.04598570615053177,
      0.013552473857998848,
      0.008768334984779358,
      -0.01603742502629757,
      0.003956635948270559,
      0.060209210962057114,
      0.030499421060085297,
      -0.08996554464101791,
      -0.01926799677312374,
      0.08414972573518753,
      0.016321131959557533,
      -0.030476240441203117,
      -0.03658922389149666,
      -0.0306788831949234,
      -0.010588998906314373,
      0.0778081938624382,
      -0.005815213546156883,
      -0.03569896146655083,
      -0.04519020393490791,
      0.013332085683941841,
      0.04208463057875633,
      0.029018132016062737,
      0.07102634757757187,
      0.013047130778431892,
      -0.08225958049297333,
      0.013863073661923409,
      -0.010975001379847527,
      0.05122169852256775,
      -0.002462875097990036,
      -0.014898410998284817,
      -0.01941194385290146,
      0.021390611305832863,
      -0.02782825566828251,
      0.0850524976849556,
      0.0016614780761301517,
      -0.07654977589845657,
      0.030495161190629005,
      -0.030734173953533173,
      -0.055906470865011215,
      -0.018924737349152565,
      -0.027352318167686462,
      0.014232320711016655,
      0.04299251735210419,
      -0.012389088980853558,
      -0.013019303791224957,
      0.020241238176822662,
      -0.0711827278137207,
      0.010871565900743008,
      -0.04684854671359062,
      0.0010731437942013144,
      -0.003598689567297697,
      0.017593974247574806,
      0.05119932442903519,
      -0.0012648601550608873,
      -0.013165373355150223,
      -0.025299157947301865,
      -0.036171574145555496,
      0.012017670087516308,
      -0.05708349496126175,
      -0.055418211966753006,
      -0.01615149900317192,
      0.01139870285987854,
      -0.02405182644724846,
      0.03600405156612396,
      -0.10653099417686462,
      -0.08264368772506714,
      0.012964373454451561,
      0.022477107122540474,
      -0.004829262383282185,
      -0.021842123940587044,
      0.06410877406597137,
      -0.003331265179440379,
      -0.07742822915315628,
      0.009346802718937397,
      -0.04072841629385948,
      -0.047593697905540466,
      0.01633634604513645,
      -0.021231334656476974,
      0.028224924579262733,
      0.02592598646879196,
      -0.019361207261681557,
      0.08604133129119873,
      -0.012660066597163677,
      0.042291875928640366,
      -0.01747170463204384,
      0.05652846768498421,
      -0.025808751583099365,
      -0.00011158915003761649,
      -0.005313208792358637,
      -0.01908010058104992,
      0.034757792949676514,
      -0.015496695414185524,
      0.031646426767110825,
      0.06474072486162186,
      -0.025440655648708344,
      6.394291267497465e-05,
      -0.11747541278600693,
      -0.05387226864695549,
      0.01575477607548237,
      -0.04615067318081856,
      0.022909846156835556,
      0.0824432522058487,
      -0.08195534348487854,
      -0.019797086715698242,
      -0.020053019747138023,
      -0.015972917899489403,
      -0.08234404027462006,
      -0.038250748068094254,
      0.005475284531712532,
      -0.03885221108794212,
      0.031420376151800156,
      -0.09817919880151749,
      0.010576922446489334,
      -0.03141810745000839,
      0.016743499785661697,
      -0.09004595875740051,
      0.019288379698991776,
      0.08259056508541107,
      -0.048603788018226624,
      0.0017480849055573344,
      0.013080845586955547,
      0.016306376084685326,
      0.03552112728357315,
      -0.02350873500108719,
      0.00268501415848732,
      -0.044045206159353256,
      0.039212506264448166,
      0.03282364085316658,
      0.07069606333971024,
      0.011416085064411163,
      -0.02414841018617153,
      -0.005095227155834436,
      0.03920818865299225,
      -0.02793487161397934,
      0.06885363161563873,
      -0.027003200724720955,
      0.05565920099616051,
      -0.07896154373884201,
      0.11207444220781326,
      -0.048495661467313766,
      -0.04216185584664345,
      -0.020348060876131058,
      0.03317609429359436,
      -0.0240626260638237,
      -0.02244660258293152,
      0.009063899517059326,
      0.03512757644057274
    ],
    [
      -0.016195932403206825,
      0.07733746618032455,
      -0.03188876062631607,
      -0.019104519858956337,
      -0.06329961866140366,
      -0.031088296324014664,
      -0.008480027318000793,
      -0.06865963339805603,
      0.030195510014891624,
      -0.012980521656572819,
      -0.012299503199756145,
      -0.02489282935857773,
      0.02592003531754017,
      0.03531339392066002,
      0.04243049770593643,
      0.0013987065758556128,
      0.02596348337829113,
      0.02464478835463524,
      -0.016323134303092957,
      -0.020748645067214966,
      0.059628039598464966,
      0.03346540778875351,
      0.07917629927396774,
      0.000439263618318364,
      -0.09407728165388107,
      0.039386097341775894,
      -0.02570783905684948,
      -0.014248140156269073,
      0.008768901228904724,
      0.002336074598133564,
      -0.033119890838861465,
      0.0639081746339798,
      -0.061929117888212204,
      0.07340341806411743,
      0.0031762265134602785,
      0.08725390583276749,
      0.044200073927640915,
      0.08116297423839569,
      -0.023189524188637733,
      0.06689438968896866,
      -0.019026076421141624,
      0.027768095955252647,
      -0.04331004619598389,
      0.001260886201635003,
      0.023491984233260155,
      -0.031386882066726685,
      -0.01284882239997387,
      0.01586652174592018,
      0.03816072270274162,
      0.0007719903369434178,
      -0.06877853721380234,
      0.06745777279138565,
      -0.004902580287307501,
      -0.02415383607149124,
      0.012868007645010948,
      0.023712413385510445,
      -0.05630750209093094,
      0.004428899846971035,
      0.007705743424594402,
      -0.028804389759898186,
      -0.06590122729539871,
      0.0512530617415905,
      0.057468004524707794,
      -0.028061121702194214,
      -0.039174795150756836,
      -0.00448817340657115,
      0.01451712567359209,
      -0.06888198107481003,
      0.0855998545885086,
      -0.0011334470473229885,
      -0.008982887491583824,
      -0.0227181538939476,
      -9.584389044903219e-05,
      -0.0285768061876297,
      0.09466424584388733,
      0.032077088952064514,
      -0.06077287346124649,
      0.03294527530670166,
      0.053721457719802856,
      0.025271661579608917,
      -0.017742237076163292,
      0.04405468702316284,
      0.0017865914851427078,
      0.03503212332725525,
      -0.04709121584892273,
      0.011575116775929928,
      -0.060778141021728516,
      -0.11899727582931519,
      -0.018164677545428276,
      -0.039401207119226456,
      0.014506503939628601,
      -0.023973533883690834,
      0.026944702491164207,
      0.007755320984870195,
      -0.03588445857167244,
      -0.031827934086322784,
      0.015297818928956985,
      0.047059427946805954,
      0.07141287624835968,
      -0.05378803238272667,
      0.07641472667455673,
      0.057397983968257904,
      0.035223815590143204,
      0.020838255062699318,
      0.0373009517788887,
      0.015165019780397415,
      -0.0022036961745470762,
      -0.016007574275135994,
      -0.03670234605669975,
      0.14320026338100433,
      -0.09270228445529938,
      -0.015651574358344078,
      -0.044541943818330765,
      0.006817374378442764,
      0.023508016020059586,
      -0.07753629982471466,
      -0.034352704882621765,
      0.04891739413142204,
      0.11978815495967865,
      0.028644101694226265,
      -0.022636234760284424,
      -0.0030655378941446543,
      0.03871098905801773,
      0.019201109185814857,
      -0.007162011228501797,
      0.04633856937289238,
      -0.01562947779893875,
      0.03916116803884506,
      -0.025200527161359787,
      -0.005586081184446812,
      -0.0357038676738739,
      -0.09245161712169647,
      0.044141776859760284,
      0.1099071055650711,
      -0.04223963990807533,
      0.06399929523468018,
      0.06529779732227325,
      -0.054184529930353165,
      0.010171093977987766,
      0.03474006801843643,
      -0.001880365307442844,
      -0.057358015328645706,
      -0.040699295699596405,
      -0.004962149541825056,
      -0.0033295240718871355,
      -0.040545690804719925,
      -0.06197823956608772,
      0.0546383336186409,
      -0.013624384999275208,
      -0.033497393131256104,
      0.014894098974764347,
      0.024103298783302307,
      0.010104876942932606,
      0.039389584213495255,
      -0.1346738487482071,
      0.0005190663505345583,
      -0.02877477928996086,
      -0.04716446250677109,
      -0.11695946753025055,
      -0.02124570496380329,
      -0.05063246190547943,
      -0.03316434845328331,
      0.10264455527067184,
      -0.0408162996172905,
      -0.04635200276970863,
      -0.09229712188243866,
      0.06285285204648972,
      -0.015864219516515732,
      0.010755733586847782,
      0.06508368998765945,
      0.014231288805603981,
      -0.015553254634141922,
      -0.010699084028601646,
      -0.0393267385661602,
      0.05157935991883278,
      0.028653007000684738,
      0.013753131031990051,
      -0.06608614325523376,
      0.056086719036102295,
      0.018674755468964577,
      0.04880224168300629,
      0.05641566216945648,
      -0.03074837103486061,
      0.08375898748636246,
      0.014539544470608234,
      0.016642916947603226,
      0.020964477211236954,
      0.08039318770170212,
      -0.03109842725098133,
      -0.009084015153348446,
      -0.01911930926144123,
      -0.06249859556555748,
      0.0738750770688057,
      -0.0718141421675682,
      -0.06861948221921921,
      -0.005865064449608326,
      0.007213665638118982,
      0.06139564886689186,
      -1.8902341253124177e-05,
      0.04101567342877388,
      0.040997497737407684,
      -0.08942624926567078,
      0.011944552883505821,
      0.008336379192769527,
      0.018917230889201164,
      0.05635679140686989,
      0.012306034564971924,
      0.05915362760424614,
      -0.04755956307053566,
      -0.03424026444554329,
      -0.0021708307322114706,
      0.0615164190530777,
      0.03305687755346298,
      -0.044724054634571075,
      0.01925615593791008,
      0.005839752033352852,
      0.0316317155957222,
      0.006097814068198204,
      -0.019529424607753754,
      -0.02943982183933258,
      -0.002157669747248292,
      -0.03247601166367531,
      0.07480539381504059,
      0.029010949656367302,
      -0.06139814853668213,
      -0.03524700924754143,
      0.030960144475102425,
      -0.06283758580684662,
      -0.03126201033592224,
      0.0010791956447064877,
      -0.017387738451361656,
      0.12494512647390366,
      0.07944189012050629,
      -0.007327443454414606,
      -0.06304371356964111,
      0.010196628049015999,
      -0.02390328049659729,
      -0.022948840633034706,
      0.0018023907905444503,
      -0.0378839485347271,
      -0.07194230705499649,
      0.05768142268061638,
      -0.008957264013588428,
      0.08509562909603119,
      0.025079188868403435,
      -0.03137100860476494,
      -0.04989379271864891,
      0.03608933463692665,
      -0.0829850360751152,
      -0.14606711268424988,
      0.04695356264710426,
      -0.03215556964278221,
      0.03390001878142357,
      -0.06740035116672516,
      -0.05096473544836044,
      0.01581128127872944,
      0.019894137978553772,
      -0.004192377906292677,
      0.013845635578036308,
      0.005610514897853136,
      0.021673038601875305,
      0.025848057121038437,
      0.007288353051990271,
      0.06686298549175262,
      -0.06908946484327316,
      0.04786781221628189,
      0.014342410489916801,
      0.009074355475604534,
      -0.041797950863838196,
      -0.05847405642271042,
      0.02045024372637272,
      -0.008139535784721375,
      -0.04518996551632881,
      -0.02974436804652214,
      0.0063528455793857574,
      0.001849271240644157,
      -0.031675685197114944,
      0.05894002690911293,
      0.028320658951997757,
      -0.0039337691850960255,
      -0.023278795182704926,
      -0.001259181764908135,
      -0.06674451380968094,
      -0.013490259647369385,
      0.012592521496117115,
      -0.030710816383361816,
      -0.017354898154735565,
      -0.03154255822300911,
      -0.08110541850328445,
      0.09581706672906876,
      -0.019359081983566284,
      -0.039856646209955215,
      0.013468793593347073,
      -0.002555010374635458,
      0.05571382865309715,
      0.036859408020973206,
      -0.015272506512701511,
      0.013037490658462048,
      -0.026782983914017677,
      0.05205908417701721,
      0.00012439694546628743,
      -0.003119919216260314,
      -0.08660195767879486,
      0.00803887378424406,
      -0.026811419054865837,
      -0.021786263212561607,
      0.008177170529961586,
      -0.06074940040707588,
      0.009072410874068737,
      0.011366765946149826,
      -0.08032745867967606,
      0.013803577981889248,
      -0.03253834322094917,
      -0.012183955870568752,
      -0.021791312843561172,
      -0.07816493511199951,
      0.015957361087203026,
      -0.07137714326381683,
      -0.014016023837029934,
      0.07741601765155792,
      0.043092675507068634,
      0.0075659481808543205,
      -0.07328176498413086,
      0.10026847571134567,
      -0.039145782589912415,
      0.05583891272544861,
      0.02800462394952774,
      -0.043619126081466675,
      0.01508491300046444,
      -0.0025091783609241247,
      -0.028453277423977852,
      -0.0198887400329113,
      0.006101399194449186,
      0.012352018617093563,
      -0.021507149562239647,
      0.031246371567249298,
      0.0737810954451561,
      -0.003947537858039141,
      0.04785062000155449,
      0.010865328833460808,
      -0.0475233718752861,
      0.008619405329227448,
      0.02925359085202217,
      0.01168876700103283,
      0.029833996668457985,
      -0.010283943265676498,
      -0.03031851537525654,
      -0.0391094833612442,
      0.03395463153719902,
      -0.02508339285850525,
      -0.04901125654578209,
      0.004732542205601931,
      -0.01622919924557209,
      0.005824722349643707,
      0.04380875453352928,
      0.03521246835589409,
      -0.07552329450845718,
      -0.0552808940410614,
      -0.014182495884597301,
      -0.04616345837712288,
      -0.026765748858451843,
      0.05771889165043831,
      -0.028483640402555466,
      0.06488111615180969,
      0.023789985105395317,
      -0.09461849927902222,
      -0.08944857120513916,
      0.12232346832752228,
      0.002538795117288828,
      -0.015954211354255676,
      -0.08561044186353683,
      -0.024299073964357376,
      -0.04620600864291191,
      -0.04090963676571846,
      0.025363683700561523,
      0.044801175594329834,
      0.022417990490794182,
      0.012111015617847443,
      -0.0538657046854496,
      0.010499953292310238,
      0.04735392704606056,
      -0.025732140988111496,
      0.0333995595574379,
      0.0032117569353431463,
      -0.02018391713500023,
      0.1059332862496376,
      -0.0005233140545897186,
      -0.047085318714380264,
      0.009432153776288033,
      -0.05670827999711037,
      -0.026798073202371597,
      0.026018083095550537,
      -0.03854192793369293,
      0.048743389546871185,
      0.005335078574717045,
      0.024173825979232788,
      -0.03690244257450104,
      0.044538360089063644,
      0.027708206325769424,
      -0.028130559250712395,
      0.02780798077583313,
      0.015024729073047638,
      -0.003344866679981351,
      -0.0033110433723777533,
      -0.04055553302168846,
      -0.0641012191772461,
      0.0234073493629694,
      -0.03743660822510719,
      0.039698947221040726,
      0.01608947105705738,
      -0.03342681750655174,
      0.0021621682681143284,
      -0.006170513108372688,
      0.07520186901092529,
      0.05097661912441254,
      -0.04838163033127785,
      -0.04983890429139137,
      0.06245517358183861,
      0.01549511682242155,
      -0.02236516773700714,
      -0.09342638403177261,
      -0.029930448159575462,
      0.07020455598831177,
      0.0440932959318161,
      0.055873095989227295,
      0.032422248274087906,
      0.022182058542966843,
      -0.010558055713772774,
      0.027242116630077362,
      0.029142554849386215,
      -0.03598405048251152,
      -0.032884418964385986,
      -0.0014344619121402502,
      0.07923698425292969,
      -0.04672793671488762,
      -0.02560623176395893,
      0.05008240416646004,
      0.06231991946697235,
      -0.00283009372651577,
      -0.04299292713403702,
      0.05766092240810394,
      0.017738087102770805,
      -0.006476004142314196,
      0.01442032027989626,
      0.018673419952392578,
      0.05906780809164047,
      0.04759687930345535,
      0.023742342367768288,
      0.019385432824492455,
      0.02467399835586548,
      0.06716585904359818,
      -0.006720127537846565,
      0.09470061957836151,
      -0.00407029502093792,
      -0.008550756610929966,
      0.028478479012846947,
      -0.00999750941991806,
      0.014744110405445099,
      -0.042411599308252335,
      0.061282023787498474,
      -0.015066348947584629,
      0.015819618478417397,
      0.054820381104946136,
      0.008936588652431965,
      -0.004513221327215433,
      -0.03996161371469498,
      0.1518831104040146,
      0.11533254384994507,
      -0.024099521338939667,
      -0.11250274628400803,
      0.04836098849773407,
      -0.005141913425177336,
      -0.007210396695882082,
      0.015782132744789124,
      0.13069960474967957,
      -0.03620579093694687,
      -0.06060631945729256,
      0.026177145540714264,
      0.06538499891757965,
      -0.034011032432317734,
      -0.006126104388386011,
      -0.051286328583955765,
      -0.030579764395952225,
      -0.05597439780831337,
      0.0222849752753973,
      0.07586459815502167,
      -0.083153136074543,
      -0.026870494708418846,
      -0.05043153837323189,
      0.012310165911912918,
      0.0050510563887655735,
      0.02890174090862274,
      0.014438100159168243,
      0.024008486419916153,
      0.04116634652018547,
      -0.04963542893528938,
      0.010717138648033142,
      -0.04305626079440117,
      0.0019564770627766848,
      0.0129960672929883,
      0.02351219207048416,
      0.010418543592095375,
      -0.026770856231451035
    ],
    [
      -0.03843413665890694,
      0.01754920184612274,
      -0.026271607726812363,
      -0.05183332413434982,
      0.06553720682859421,
      0.03658447787165642,
      -0.04218052327632904,
      0.02007542923092842,
      0.0446259081363678,
      -0.07271852344274521,
      -0.009415455162525177,
      -0.018495049327611923,
      0.03837081789970398,
      0.008532418869435787,
      -0.024738561362028122,
      0.015018286183476448,
      -0.08676987886428833,
      -0.08782490342855453,
      0.06225515156984329,
      0.022943221032619476,
      -0.034161362797021866,
      0.013981570489704609,
      0.02256549522280693,
      -0.007711341604590416,
      -0.0463886484503746,
      0.04487175866961479,
      -0.03791547194123268,
      -0.02018008753657341,
      -0.026601776480674744,
      0.04621098190546036,
      -0.07964400202035904,
      0.01952335238456726,
      -0.023479294031858444,
      0.020718785002827644,
      -0.017231987789273262,
      0.06933601945638657,
      0.005258060991764069,
      -0.011993215419352055,
      0.025880467146635056,
      0.0006823122967034578,
      -0.0507037416100502,
      0.028894107788801193,
      -0.0451592281460762,
      0.02192896232008934,
      0.006170225795358419,
      -0.07984061539173126,
      0.04161388799548149,
      0.012259402312338352,
      -0.01810348406434059,
      -0.012564456090331078,
      -0.015024176798760891,
      0.04308464005589485,
      -0.07526025921106339,
      -0.08359281718730927,
      0.023223651573061943,
      0.024833589792251587,
      -0.031183863058686256,
      -0.02040785737335682,
      -0.09584742784500122,
      0.014620425179600716,
      0.016166135668754578,
      0.00940463226288557,
      -0.04997122287750244,
      -0.014870507642626762,
      0.02339937724173069,
      0.02895287796854973,
      0.009410258382558823,
      -0.024704718962311745,
      0.03388728201389313,
      0.004912463016808033,
      -0.05254995450377464,
      -0.0015008329646661878,
      0.05443847179412842,
      -0.05968465283513069,
      -0.004455213434994221,
      0.00793886836618185,
      -0.013918893411755562,
      -0.019462428987026215,
      0.03819481283426285,
      -0.03551139682531357,
      0.06340421736240387,
      0.023331349715590477,
      0.01655234396457672,
      0.012627248652279377,
      0.004245462361723185,
      -0.03165343776345253,
      -0.08026115596294403,
      -0.05710425227880478,
      0.029491497203707695,
      0.04390653222799301,
      -0.005707337986677885,
      -0.007724264170974493,
      -0.005777428857982159,
      0.07366127520799637,
      -0.03434091433882713,
      0.015341934747993946,
      -0.06987015157938004,
      0.003744889050722122,
      -0.018755031749606133,
      0.006438753101974726,
      0.05963573604822159,
      -0.0004821503534913063,
      0.029045557603240013,
      0.01264755055308342,
      0.0763324722647667,
      0.015616708435118198,
      0.014133174903690815,
      -0.01575298048555851,
      -0.0004012762219645083,
      0.03367302566766739,
      -0.0056145815178751945,
      -0.02196500077843666,
      -0.004562931135296822,
      -0.07587261497974396,
      0.024836836382746696,
      0.0854286253452301,
      -0.013644096441566944,
      0.02978554181754589,
      0.05502483993768692,
      0.033864591270685196,
      0.06731143593788147,
      -0.013209439814090729,
      0.018837057054042816,
      0.027462434023618698,
      -0.08415739983320236,
      -0.06923475116491318,
      -0.0370681956410408,
      0.07653307914733887,
      -0.054457612335681915,
      0.034921981394290924,
      -0.06418555229902267,
      0.013053041882812977,
      0.017083514481782913,
      0.016982458531856537,
      0.037123069167137146,
      -0.0568150095641613,
      -0.012201225385069847,
      -0.0016669983742758632,
      -0.02596333436667919,
      0.06906024366617203,
      -0.027156267315149307,
      0.05687464028596878,
      0.002109469147399068,
      0.02415914088487625,
      -0.0012684590183198452,
      0.0693562924861908,
      -0.05823646858334541,
      0.052153658121824265,
      0.009235449135303497,
      -0.016189850866794586,
      -0.043064266443252563,
      0.03415169194340706,
      -0.07530075311660767,
      -0.08945764601230621,
      -0.04993438348174095,
      -0.014673545025289059,
      0.07378426194190979,
      -0.05513206496834755,
      0.027293045073747635,
      -0.0022750920616090298,
      0.02879142202436924,
      -0.028962505981326103,
      0.08788285404443741,
      0.028016336262226105,
      0.01291043683886528,
      -0.013077515177428722,
      0.07895689457654953,
      -0.024815935641527176,
      -0.0015148780075833201,
      0.010722292587161064,
      0.018402239307761192,
      0.0819089487195015,
      0.006195292342454195,
      0.01450765784829855,
      0.00585870910435915,
      -0.08752406388521194,
      0.1293174922466278,
      0.10187804698944092,
      -0.024721717461943626,
      -0.03457997366786003,
      -0.08633977174758911,
      -0.003754808334633708,
      -0.02470468170940876,
      0.03139999881386757,
      0.032101672142744064,
      -0.04050422087311745,
      -0.0321507602930069,
      -0.01000746339559555,
      -0.011699697934091091,
      -0.007473616395145655,
      0.01853405125439167,
      0.01965988054871559,
      -0.08158288151025772,
      -0.007570537272840738,
      0.015072239562869072,
      0.04489916190505028,
      0.10692939907312393,
      -0.030897922813892365,
      -0.0034098559990525246,
      -0.043111927807331085,
      0.02303006500005722,
      0.020605919882655144,
      -0.030526479706168175,
      0.11404597014188766,
      -0.04987521469593048,
      0.04555002227425575,
      -0.09622184932231903,
      0.014711424708366394,
      0.004992564208805561,
      0.007154541555792093,
      0.00093533267499879,
      -0.010319776833057404,
      0.006136763375252485,
      0.015446823090314865,
      0.03813314065337181,
      0.01472780853509903,
      -0.0886632427573204,
      -0.05113877356052399,
      0.013561055064201355,
      -0.053099703043699265,
      -0.07663613557815552,
      -0.027901509776711464,
      -0.0037707549054175615,
      0.05023615434765816,
      -0.013826548121869564,
      0.014019244350492954,
      -0.03306356817483902,
      -0.06008393317461014,
      0.07520083338022232,
      -0.00916562881320715,
      0.03368854895234108,
      -0.04786381125450134,
      0.00961178820580244,
      -0.011255540885031223,
      0.0512101985514164,
      -0.05150424316525459,
      -0.05851580575108528,
      0.01166888140141964,
      0.006146289873868227,
      0.007594555150717497,
      -0.011487104929983616,
      0.0048437705263495445,
      0.05193140730261803,
      0.0012323056580498815,
      0.0004542836104519665,
      -0.0024623076897114515,
      -0.05158436670899391,
      0.0159286018460989,
      0.05409422144293785,
      -0.0392819344997406,
      0.03213483840227127,
      0.004317835438996553,
      0.025640645995736122,
      0.011497771367430687,
      0.0035465897526592016,
      0.019970033317804337,
      -0.034550778567790985,
      -0.0959383174777031,
      0.01719639264047146,
      0.03866042196750641,
      0.0720616802573204,
      -0.05560002475976944,
      -0.006119551602751017,
      0.002906522247940302,
      -0.03712724149227142,
      0.03560966998338699,
      -0.0434098094701767,
      -0.07833878695964813,
      0.05227053537964821,
      -0.06846315413713455,
      -0.006738054100424051,
      0.09315202385187149,
      -0.038699403405189514,
      -0.02225184626877308,
      -0.016890104860067368,
      -0.034362729638814926,
      0.01024638395756483,
      0.0145461056381464,
      0.08062884211540222,
      0.02098587155342102,
      0.06798111647367477,
      0.011951825581490993,
      -0.02573118545114994,
      0.034552671015262604,
      0.025933489203453064,
      -0.02417011186480522,
      0.012600925751030445,
      -0.004782233387231827,
      -0.006580677814781666,
      0.016030211001634598,
      -0.10558143258094788,
      -0.05753609910607338,
      -0.025981886312365532,
      -0.0066487668082118034,
      -0.042723484337329865,
      -0.032428015023469925,
      -0.003061764407902956,
      0.008270835503935814,
      0.03540462255477905,
      -0.007496003992855549,
      -0.02118486911058426,
      0.004731584340333939,
      -0.031017949804663658,
      0.047629497945308685,
      0.019354363903403282,
      0.05109524354338646,
      -0.019788378849625587,
      -0.009850762784481049,
      -0.03899760544300079,
      -0.012043127790093422,
      -0.0038088662549853325,
      0.00149144500028342,
      -0.02935180440545082,
      -0.01915201172232628,
      -0.018779002130031586,
      -0.00412346888333559,
      -0.028520995751023293,
      0.09018981456756592,
      0.0017028861911967397,
      -0.017303721979260445,
      0.01263425499200821,
      0.035241905599832535,
      0.05488864704966545,
      0.03732459992170334,
      0.02447696402668953,
      -0.07321597635746002,
      -0.10430988669395447,
      0.04219679906964302,
      0.06673356145620346,
      -0.00367710436694324,
      -0.012318029068410397,
      0.0003485501802060753,
      -0.043036237359046936,
      0.015304727479815483,
      -0.04242614656686783,
      0.028964271768927574,
      -0.0027749785222113132,
      0.07391729950904846,
      0.039758916944265366,
      -0.03750702738761902,
      -0.04687391594052315,
      -0.0039171576499938965,
      -0.02176493965089321,
      0.041393086314201355,
      0.07925243675708771,
      -0.04340832307934761,
      0.04208659008145332,
      0.05522167310118675,
      -0.07912735641002655,
      0.028501028195023537,
      0.008132350631058216,
      0.025602908805012703,
      0.013991784304380417,
      -0.025857513770461082,
      0.03179936856031418,
      0.049509841948747635,
      -0.00287566427141428,
      0.07887077331542969,
      -0.09250958263874054,
      -0.04300980642437935,
      0.01940014772117138,
      -0.017478138208389282,
      -0.08811723440885544,
      0.0732584223151207,
      0.08414425700902939,
      -0.004992099944502115,
      0.012926639057695866,
      -0.022990960627794266,
      -0.010162553749978542,
      -0.033836729824543,
      -0.028239579871296883,
      0.0478656031191349,
      -0.03013499453663826,
      0.019911399111151695,
      0.005967710632830858,
      -0.04608536511659622,
      0.025339461863040924,
      0.03885683789849281,
      0.03099365532398224,
      -0.07022856920957565,
      0.01277446560561657,
      0.0023647493217140436,
      0.03186658397316933,
      -0.050317276269197464,
      -0.009890646673738956,
      -0.03085276298224926,
      0.012075497768819332,
      0.0069136531092226505,
      -0.06566484272480011,
      0.03889667987823486,
      0.0489235557615757,
      -0.04171307012438774,
      -0.03241632506251335,
      -0.03846713900566101,
      -0.05700346454977989,
      -0.03678378462791443,
      0.04328008368611336,
      0.0080752307549119,
      -0.004356495104730129,
      0.05012279748916626,
      0.053079474717378616,
      0.0058825635351240635,
      -0.07091722637414932,
      0.01592971384525299,
      -0.027634819969534874,
      0.001557792304083705,
      0.042622003704309464,
      0.00705492589622736,
      0.043102677911520004,
      -0.012443186715245247,
      -0.0366402305662632,
      0.013241736218333244,
      -0.08195508271455765,
      -0.02414969727396965,
      0.06706489622592926,
      0.021975992247462273,
      0.06496841460466385,
      -0.05267980322241783,
      -0.012388844974339008,
      0.0021432144567370415,
      -0.02796764299273491,
      -0.08363651484251022,
      0.00824460107833147,
      -0.020321432501077652,
      0.0006424825987778604,
      -0.009422585368156433,
      -0.06706751137971878,
      0.05266754701733589,
      0.001783235464245081,
      -0.02149234525859356,
      0.05187927931547165,
      -0.03554053604602814,
      0.036853257566690445,
      -0.03358686342835426,
      -0.08102554082870483,
      0.07586190104484558,
      0.00743819959461689,
      -0.018860993906855583,
      0.023756952956318855,
      0.08598491549491882,
      -0.04137653857469559,
      -0.04712695628404617,
      -0.013066223822534084,
      0.00484315725043416,
      -0.06682005524635315,
      -0.048423659056425095,
      -0.010757862590253353,
      0.04134169593453407,
      -0.09608805179595947,
      -0.0516587533056736,
      -0.006891519296914339,
      -0.010871533304452896,
      -0.024058645591139793,
      -0.024238400161266327,
      -0.007150810211896896,
      -0.08046786487102509,
      -0.028222233057022095,
      0.10593859106302261,
      -0.002750444458797574,
      -0.021674005314707756,
      0.11257697641849518,
      -0.0020417298655956984,
      0.0156028401106596,
      0.018073318526148796,
      0.03470253944396973,
      0.00605871994048357,
      -0.01591581664979458,
      -0.06685800105333328,
      0.03283899277448654,
      -0.07437548041343689,
      0.018825041130185127,
      -0.05953086167573929,
      -0.005290770437568426,
      0.044217828661203384,
      -0.08323102444410324,
      0.014268623664975166,
      0.043803904205560684,
      -0.05968504771590233,
      0.0621391125023365,
      0.015277977101504803,
      -0.03281472623348236,
      -0.04781493544578552,
      -0.049085911363363266,
      -0.04214099422097206,
      0.13540367782115936,
      0.05408821254968643,
      -0.027762113139033318,
      0.004612584598362446,
      0.04125884920358658,
      0.050618547946214676,
      0.07156987488269806,
      -0.0414339080452919,
      0.07141589373350143,
      -0.07855674624443054,
      -0.013592001050710678,
      -0.049017805606126785,
      0.044609688222408295,
      0.0014195169787853956,
      -0.009938293136656284,
      0.0708424299955368,
      0.054052773863077164,
      -0.039015501737594604,
      0.08594857901334763
    ],
    [
      0.006642181891947985,
      0.03378734365105629,
      0.03776274621486664,
      -0.03197372332215309,
      0.08058717101812363,
      -0.04272458329796791,
      -0.053216807544231415,
      -0.002934071235358715,
      -0.0004416049923747778,
      0.07915450632572174,
      -0.011758275330066681,
      0.008687649853527546,
      0.031989630311727524,
      0.08897743374109268,
      -0.0430927649140358,
      0.051281992346048355,
      0.04092593118548393,
      -0.0059944381937384605,
      0.016463441774249077,
      0.0214876476675272,
      -0.04584217816591263,
      0.05420264974236488,
      0.10463078320026398,
      -0.03845416381955147,
      -0.0617363303899765,
      0.05436145141720772,
      -0.016990985721349716,
      -0.045455824583768845,
      0.004144828300923109,
      -0.04349943622946739,
      0.02959359437227249,
      -0.09648807346820831,
      0.023606041446328163,
      -0.0019499589689075947,
      0.0281393900513649,
      -0.07756856083869934,
      0.03388290852308273,
      0.05232502147555351,
      0.019272904843091965,
      0.007014836184680462,
      0.024117019027471542,
      0.05012378469109535,
      0.030531665310263634,
      0.0008394366595894098,
      0.12804214656352997,
      -0.06985083222389221,
      0.026144148781895638,
      -0.02044667862355709,
      -0.026959411799907684,
      0.05928529053926468,
      0.023128051310777664,
      -0.021205121651291847,
      -0.06877634674310684,
      -0.02349253185093403,
      -0.040334757417440414,
      -0.03825276345014572,
      -0.05412859842181206,
      0.03893239051103592,
      -0.06067650020122528,
      0.04957025498151779,
      0.05724864825606346,
      0.04754425957798958,
      -0.0879230946302414,
      -0.0030102129094302654,
      -0.04649495705962181,
      -0.016516221687197685,
      0.08934326469898224,
      -0.033309392631053925,
      -0.0701095387339592,
      0.014679248444736004,
      -0.027873730286955833,
      -0.016729220747947693,
      0.060037482529878616,
      0.04430157318711281,
      -0.038671329617500305,
      0.024887608364224434,
      0.01734662614762783,
      0.02795553021132946,
      -0.045179083943367004,
      -0.008842003531754017,
      -0.0894368514418602,
      -0.013586850836873055,
      0.012091077864170074,
      -0.01032753475010395,
      0.01870238035917282,
      0.002071433002129197,
      0.007084762677550316,
      0.07480964064598083,
      0.01571929268538952,
      0.06296061724424362,
      -0.03553428500890732,
      0.08288336545228958,
      0.05594981461763382,
      -0.005796011537313461,
      -0.01576361060142517,
      -0.0037584651727229357,
      0.012209921143949032,
      0.03169383481144905,
      -0.08756237477064133,
      0.04433223232626915,
      -0.05657201632857323,
      0.023137133568525314,
      -0.010198977775871754,
      0.024292007088661194,
      0.01050609815865755,
      0.038467030972242355,
      0.06360676139593124,
      0.02765156328678131,
      0.044432248920202255,
      -0.05028365179896355,
      -0.06430850923061371,
      -0.050693631172180176,
      -0.012674604542553425,
      -0.014215460978448391,
      -0.0011020174715667963,
      0.026827432215213776,
      -0.02519095502793789,
      -0.053490906953811646,
      -0.0010484782978892326,
      -0.09244388341903687,
      0.09459725767374039,
      -0.04618651419878006,
      0.014207153581082821,
      0.018365858122706413,
      -0.08725576847791672,
      -0.017761193215847015,
      -0.02079937420785427,
      0.08545927703380585,
      -0.030555006116628647,
      -0.006147992331534624,
      0.0022993143647909164,
      0.00958870630711317,
      0.05904107168316841,
      -0.04837297275662422,
      0.02783489227294922,
      0.022202562540769577,
      -0.09452740103006363,
      0.04424522444605827,
      0.007116006687283516,
      0.0036993399262428284,
      0.0013578321086242795,
      0.033251550048589706,
      0.04615814983844757,
      -0.004668413195759058,
      -0.006154052913188934,
      -0.054528702050447464,
      -0.036400746554136276,
      0.003219826612621546,
      -0.06376723945140839,
      0.007896649651229382,
      0.02539115771651268,
      0.03165256232023239,
      -0.06236303597688675,
      0.022990552708506584,
      -0.020573066547513008,
      -0.012323831208050251,
      -0.034675661474466324,
      -0.09600849449634552,
      0.0050949594005942345,
      -0.04579097777605057,
      -0.024529606103897095,
      -0.1025187075138092,
      0.05005820468068123,
      -0.05186742916703224,
      -0.01630886271595955,
      -0.04031524434685707,
      0.023706473410129547,
      0.0006325420690700412,
      -0.008541597984731197,
      0.01695975847542286,
      0.006247262470424175,
      -0.004670425318181515,
      0.05357519909739494,
      -0.02689753845334053,
      0.032206859439611435,
      -0.04002610966563225,
      0.01603570766746998,
      0.039403315633535385,
      -0.05891622230410576,
      -0.02504580281674862,
      0.0430045947432518,
      -0.0015013362281024456,
      0.020215122029185295,
      -0.012086753733456135,
      -0.005086785182356834,
      -0.039090875536203384,
      0.04370766505599022,
      0.02950611710548401,
      -0.029736455529928207,
      -0.0423395149409771,
      0.03285297378897667,
      -0.004312429111450911,
      0.033404018729925156,
      0.01305502187460661,
      0.023472696542739868,
      -0.043848250061273575,
      -0.0491131953895092,
      -0.1272544264793396,
      -0.04536254703998566,
      -0.0827777087688446,
      0.01929558254778385,
      -0.0861796960234642,
      0.04403850436210632,
      0.010726443491876125,
      -0.0661308690905571,
      -0.04988376051187515,
      -0.045639779418706894,
      0.0621819831430912,
      0.07901959866285324,
      -0.014135697856545448,
      -0.0273289792239666,
      0.008515387773513794,
      0.032692622393369675,
      0.08066298067569733,
      0.03665490448474884,
      -0.04890332370996475,
      0.07110265642404556,
      0.011949652805924416,
      -0.017301058396697044,
      -0.0029615857638418674,
      0.019592417404055595,
      0.08626461029052734,
      0.04552184417843819,
      0.024067923426628113,
      0.0494224838912487,
      -0.012537525035440922,
      -0.024002142250537872,
      0.0568704828619957,
      -0.030107680708169937,
      -0.07511651515960693,
      -0.0036643389612436295,
      0.03612985834479332,
      -0.03711162135004997,
      0.02710013836622238,
      0.0013648110907524824,
      0.009670812636613846,
      0.03389019891619682,
      0.01911264844238758,
      -0.03696316480636597,
      3.868282510666177e-05,
      -0.006476754788309336,
      0.031995318830013275,
      -0.006725477986037731,
      -0.011023150756955147,
      -0.010212292894721031,
      -0.10478822141885757,
      0.09434325248003006,
      -0.03009159304201603,
      0.10583680868148804,
      -0.056765422224998474,
      -0.04748834669589996,
      0.04728193208575249,
      0.03548399358987808,
      0.005548778455704451,
      0.0799875482916832,
      -0.06072169169783592,
      -0.03472787141799927,
      0.003848080988973379,
      -0.013768430799245834,
      -0.06112248823046684,
      0.0049292463809251785,
      -0.010240957140922546,
      0.003031613305211067,
      0.04557870328426361,
      0.03777791187167168,
      -0.04755517840385437,
      -0.03275100514292717,
      0.017832767218351364,
      -0.07079411298036575,
      -0.05537252873182297,
      0.08118002861738205,
      0.013341667130589485,
      -0.03576924651861191,
      -0.025200851261615753,
      -0.041014160960912704,
      0.0938233807682991,
      0.006286857649683952,
      0.04366855323314667,
      0.01360298227518797,
      -0.0530029721558094,
      0.003722353605553508,
      -0.04685224965214729,
      0.03463248535990715,
      -0.01676117070019245,
      0.02723674662411213,
      -0.007359935436397791,
      -0.016091590747237206,
      0.0025746270548552275,
      0.009514937177300453,
      0.06757612526416779,
      -0.029829053208231926,
      -0.023620279505848885,
      -0.025416307151317596,
      0.051863450556993484,
      0.05043957382440567,
      -0.022717928513884544,
      -0.010888050310313702,
      -0.07009585201740265,
      -0.07839322835206985,
      -0.012711248360574245,
      0.03142527863383293,
      0.00797651894390583,
      -0.062389157712459564,
      -0.03844497352838516,
      -0.030249565839767456,
      0.026974735781550407,
      -0.009896050207316875,
      -0.007877142168581486,
      -0.027990691363811493,
      -0.012117511592805386,
      0.045187972486019135,
      0.08225762099027634,
      -0.07366351038217545,
      -0.022753441706299782,
      0.05138435214757919,
      -0.034827105700969696,
      0.03390645608305931,
      -0.07568364590406418,
      0.030134374275803566,
      -0.04118220508098602,
      -0.00381283788010478,
      0.003266263986006379,
      0.014230415225028992,
      0.05723097547888756,
      -0.06803545355796814,
      -0.0260216873139143,
      0.007974406704306602,
      -0.03699815273284912,
      0.06227469816803932,
      -0.07303670048713684,
      -0.014355568215250969,
      0.061662424355745316,
      -0.06794682890176773,
      0.01576557196676731,
      0.1175784096121788,
      -0.008058295585215092,
      0.03198269382119179,
      -0.04117508977651596,
      0.0507165901362896,
      0.01328470092266798,
      0.050231967121362686,
      0.09265557676553726,
      -0.036925483494997025,
      0.05164703354239464,
      0.003678286913782358,
      -0.04104110598564148,
      0.08638157695531845,
      0.026614923030138016,
      0.028794938698410988,
      -0.07487467676401138,
      -0.10278812795877457,
      -0.029906712472438812,
      0.007728375028818846,
      -0.016774188727140427,
      -0.061016712337732315,
      0.01529404241591692,
      0.009459192864596844,
      -0.004304161760956049,
      -0.008187992498278618,
      -0.035525642335414886,
      -0.03133057430386543,
      -0.03749077394604683,
      0.08464350551366806,
      -0.014424210414290428,
      0.03048909269273281,
      -0.051013194024562836,
      -0.06068064272403717,
      0.033623408526182175,
      0.011650511994957924,
      -0.016410905867815018,
      0.02607736736536026,
      0.015772242099046707,
      0.07836021482944489,
      -0.07394591718912125,
      0.03833521157503128,
      -0.00885573960840702,
      0.11359775811433792,
      0.04653004929423332,
      0.08193478733301163,
      0.004378299228847027,
      0.04943579062819481,
      -0.03023536689579487,
      0.012077286839485168,
      -0.0619586743414402,
      0.06400321424007416,
      0.04067688062787056,
      0.05932387337088585,
      0.06080636754631996,
      0.03885212168097496,
      -0.08200259506702423,
      -0.09691859781742096,
      0.010413067415356636,
      0.0588509738445282,
      0.032862551510334015,
      -0.05020353943109512,
      0.049512963742017746,
      -0.03890322893857956,
      -0.027325641363859177,
      -0.013671540655195713,
      0.00010056431347038597,
      -0.047983020544052124,
      0.016471408307552338,
      0.030123163014650345,
      -0.07917342334985733,
      -0.03577447682619095,
      -0.015151984989643097,
      -0.0008453393238596618,
      -0.007295324467122555,
      0.03803175315260887,
      -0.026228317990899086,
      -0.05698343738913536,
      -0.09585195779800415,
      -0.009914858266711235,
      0.00641855550929904,
      0.044901903718709946,
      0.02918609231710434,
      -0.0483214408159256,
      0.046887077391147614,
      -0.059864506125450134,
      0.016247136518359184,
      -0.030995722860097885,
      -0.042566969990730286,
      -0.05025375634431839,
      0.00786513090133667,
      0.010982651263475418,
      0.004499984905123711,
      -0.02125938981771469,
      0.06917998939752579,
      -0.020777426660060883,
      -0.011320876888930798,
      0.005995237268507481,
      -0.01774788089096546,
      0.0899779349565506,
      -0.003111392492428422,
      0.006085628643631935,
      0.02123095840215683,
      -0.030875591561198235,
      0.012023822404444218,
      -0.005109578836709261,
      0.018358642235398293,
      0.01583012565970421,
      0.01569429412484169,
      0.06234709918498993,
      -0.009206702001392841,
      0.03430068492889404,
      0.03402513265609741,
      0.017057526856660843,
      0.05803679674863815,
      -0.0753568485379219,
      -0.004911964759230614,
      -0.0678786188364029,
      -0.015405954793095589,
      0.020074551925063133,
      0.025730706751346588,
      0.017760425806045532,
      0.13706836104393005,
      -0.01220415998250246,
      0.013646875508129597,
      0.11716277152299881,
      0.0322231687605381,
      -4.4026513933204114e-05,
      -0.032647743821144104,
      -0.04852547124028206,
      0.008256128989160061,
      -0.05674139782786369,
      -0.023782819509506226,
      0.024710604920983315,
      0.021530454978346825,
      0.018172308802604675,
      0.01744157075881958,
      0.016311952844262123,
      0.030767111107707024,
      -0.0068433028645813465,
      -0.013130932115018368,
      -0.008351125754415989,
      0.025662245228886604,
      0.025108028203248978,
      0.09468325227499008,
      -0.017395872622728348,
      0.06382486969232559,
      -0.005148305092006922,
      0.041081417351961136,
      -0.03505050763487816,
      0.010540596209466457,
      0.058321986347436905,
      0.025006674230098724,
      -0.13645251095294952,
      0.015491178259253502,
      0.01069304347038269,
      0.04008570685982704,
      0.044034287333488464,
      -0.04536183178424835,
      -0.043397534638643265,
      -0.08862550556659698,
      0.1222744807600975,
      -0.044067561626434326,
      0.02202138490974903,
      -0.019889380782842636,
      0.008665170520544052,
      0.006065723951905966,
      0.012997344136238098,
      0.07372591644525528,
      0.014280331321060658
    ],
    [
      0.022187942638993263,
      0.003942128736525774,
      -0.02988894097507,
      0.02423495054244995,
      0.0479426346719265,
      -0.052953291684389114,
      -0.07253287732601166,
      -0.03704802691936493,
      -0.03425069525837898,
      -0.036040592938661575,
      -0.014849117025732994,
      -0.044001612812280655,
      0.03945288807153702,
      0.014207148924469948,
      -0.04077235609292984,
      -0.010326528921723366,
      0.006150855682790279,
      0.0847870409488678,
      -0.11576390266418457,
      0.02327975071966648,
      0.015838623046875,
      0.07968562841415405,
      0.09846552461385727,
      -0.019120965152978897,
      -0.013833587057888508,
      0.006817827932536602,
      0.008029535412788391,
      -0.09574566036462784,
      -0.09334123134613037,
      0.04629262909293175,
      0.001160381711088121,
      -0.017313329502940178,
      0.0019452222622931004,
      -0.01970878429710865,
      0.03677576035261154,
      0.006467594299465418,
      -0.00943718571215868,
      -0.08008834719657898,
      -0.015034722164273262,
      0.04735405370593071,
      -0.024660443887114525,
      -0.0484495535492897,
      -0.06257444620132446,
      -0.02933637984097004,
      -0.033414777368307114,
      -0.04302164912223816,
      0.02669774740934372,
      0.06435378640890121,
      -0.050837550312280655,
      0.004348814487457275,
      0.060789965093135834,
      0.05297033488750458,
      0.07492390275001526,
      -0.03834323585033417,
      0.014627018943428993,
      0.07880260795354843,
      -0.03628406301140785,
      0.07172771543264389,
      0.05473855510354042,
      0.029173744842410088,
      0.0662696585059166,
      0.05310593917965889,
      0.05416519194841385,
      -0.019337842240929604,
      0.024206414818763733,
      -0.07610881328582764,
      0.07699606567621231,
      0.010626515373587608,
      0.004086290951818228,
      -0.02837878093123436,
      0.021296875551342964,
      0.02367646060883999,
      -0.050474900752305984,
      0.007802916225045919,
      0.03143778443336487,
      -0.03957096114754677,
      -0.017111659049987793,
      -0.02290438674390316,
      -0.050280019640922546,
      -0.05135880783200264,
      0.003377174958586693,
      0.003477840917184949,
      -0.073265440762043,
      0.020159466192126274,
      -0.038709159940481186,
      -0.02868359349668026,
      -0.04379229620099068,
      -0.023724259808659554,
      -0.05002998560667038,
      -0.05005038157105446,
      -0.026248103007674217,
      0.045932672917842865,
      -0.05152095854282379,
      -0.09618327021598816,
      -0.055965159088373184,
      -0.0861336961388588,
      -0.035136424005031586,
      -0.0396866500377655,
      0.040067173540592194,
      0.02131803333759308,
      -0.02408873476088047,
      0.041965607553720474,
      -0.09487704932689667,
      0.056327495723962784,
      0.03208641707897186,
      -0.05204460397362709,
      -0.0018278586212545633,
      -0.08229666203260422,
      -0.05014633759856224,
      0.052459679543972015,
      -0.08924874663352966,
      0.022104207426309586,
      -0.08920414000749588,
      0.05520587041974068,
      -0.006955407559871674,
      0.030330626294016838,
      -0.04742726311087608,
      0.004209147300571203,
      0.039371367543935776,
      0.09465036541223526,
      0.03430633991956711,
      -0.029296735301613808,
      0.05625453591346741,
      -0.045893870294094086,
      -0.07129226624965668,
      0.02851739339530468,
      0.04289025068283081,
      0.03730785474181175,
      -0.047162361443042755,
      0.03746498003602028,
      -0.0071234265342354774,
      -0.012514088302850723,
      0.0049797832034528255,
      0.028764372691512108,
      -0.03626864403486252,
      0.03929663822054863,
      -0.008183141238987446,
      0.02838166058063507,
      -0.06800363212823868,
      -0.008573305793106556,
      -0.030138636007905006,
      0.0896725133061409,
      -0.043306730687618256,
      -0.09535554051399231,
      0.012546975165605545,
      0.07268894463777542,
      -0.07180684059858322,
      -0.025636987760663033,
      0.03287661075592041,
      0.0007171641336753964,
      -0.06373519450426102,
      0.04082057997584343,
      -0.023920472711324692,
      -0.13305780291557312,
      0.035281188786029816,
      0.024505561217665672,
      0.04690585285425186,
      0.00772550655528903,
      0.01027707289904356,
      0.019405880942940712,
      -0.00597391277551651,
      -0.05971941351890564,
      0.06493525207042694,
      -0.01022121962159872,
      0.060080647468566895,
      -0.03551449254155159,
      6.061780368327163e-05,
      -0.006850476376712322,
      0.026460496708750725,
      0.008448386564850807,
      -0.033924296498298645,
      -0.01557574700564146,
      -0.0984678715467453,
      -0.011997971683740616,
      -0.017347753047943115,
      -0.014489555731415749,
      0.05440312623977661,
      -0.07564488798379898,
      -0.02069564163684845,
      0.018065666779875755,
      0.04309706762433052,
      0.06042009964585304,
      0.035793256014585495,
      0.011319867335259914,
      0.03430207446217537,
      0.0339292511343956,
      0.0203323382884264,
      0.029349742457270622,
      -0.019258247688412666,
      -0.0030403067357838154,
      0.008036807179450989,
      -0.0751558467745781,
      0.10465909540653229,
      0.06844702363014221,
      -0.02210411988198757,
      0.0031389847863465548,
      0.07483352720737457,
      0.03963790833950043,
      0.003373959334567189,
      -0.026972321793437004,
      0.06827127188444138,
      0.03645221143960953,
      0.026940342038869858,
      0.01593596860766411,
      0.09746289253234863,
      -0.007476885803043842,
      0.028193935751914978,
      -0.0441066212952137,
      0.018928421661257744,
      0.04263656586408615,
      -0.0014554595109075308,
      -0.05767522752285004,
      0.025064589455723763,
      0.009982800111174583,
      0.012288316152989864,
      0.018369276076555252,
      0.02511223778128624,
      0.010525829158723354,
      -0.016935303807258606,
      -0.11479084938764572,
      0.026467202231287956,
      -0.024784713983535767,
      -0.04245895519852638,
      0.009390600956976414,
      -0.011155650019645691,
      -0.006369546987116337,
      0.011981183663010597,
      -0.023630861192941666,
      0.04836716130375862,
      0.00023129640612751245,
      -0.005940835922956467,
      0.06668323278427124,
      -0.016406530514359474,
      0.005402083974331617,
      -0.0554656945168972,
      0.09765562415122986,
      -0.020227724686264992,
      0.029361335560679436,
      -0.08581963181495667,
      0.005944428499788046,
      0.08663064241409302,
      -0.03364104405045509,
      0.003712177975103259,
      0.08828918635845184,
      -0.05975436046719551,
      0.02327239140868187,
      0.0027109994553029537,
      0.02205907180905342,
      0.04752343147993088,
      -0.11688277870416641,
      0.030565960332751274,
      0.02216167561709881,
      0.053393490612506866,
      0.04814917594194412,
      -0.10031203180551529,
      -0.042405735701322556,
      -0.0790410041809082,
      -0.02421143464744091,
      0.04257727414369583,
      -0.013585438020527363,
      0.032280828803777695,
      0.05491648241877556,
      0.0009227399714291096,
      -0.09002965688705444,
      -0.014567424543201923,
      -0.08219984918832779,
      0.0017157432157546282,
      0.006922494620084763,
      -0.02153514139354229,
      -0.013666538521647453,
      0.004517910536378622,
      0.03834347799420357,
      0.014062370173633099,
      -0.017696937546133995,
      0.011420209892094135,
      -0.05092383548617363,
      -0.030892161652445793,
      0.024788089096546173,
      0.022683437913656235,
      -0.030366402119398117,
      0.08865315467119217,
      -0.042317379266023636,
      0.07874593138694763,
      -0.06913668662309647,
      0.00903096329420805,
      -0.035625506192445755,
      -0.037420015782117844,
      0.06100033223628998,
      -0.05569819360971451,
      0.03540142998099327,
      0.056548550724983215,
      -0.07695521414279938,
      -0.07440755516290665,
      0.011253449134528637,
      0.002094070427119732,
      0.03578457981348038,
      -0.02936430461704731,
      -0.02923646941781044,
      -0.039913445711135864,
      -0.05815482512116432,
      -0.04961739853024483,
      0.011294594965875149,
      -0.019071629270911217,
      -0.010175634175539017,
      -0.060899633914232254,
      -0.12250299751758575,
      -0.05099301040172577,
      0.012208445928990841,
      0.04136788845062256,
      0.030283646658062935,
      -0.027337966486811638,
      -0.047077056020498276,
      -0.054220352321863174,
      -0.04155129939317703,
      0.011262204498052597,
      0.02379836142063141,
      -0.019800987094640732,
      -0.07619817554950714,
      0.007095465902239084,
      -0.0002667585213202983,
      -0.05372457206249237,
      0.03297782316803932,
      0.004538766108453274,
      0.05286567658185959,
      0.0274448711425066,
      0.00938336830586195,
      0.0028713210485875607,
      0.0033764189574867487,
      0.06302748620510101,
      -0.033771250396966934,
      0.012719603255391121,
      -0.007611764129251242,
      -0.004452833905816078,
      -0.0422179140150547,
      0.0281253419816494,
      -0.028736842796206474,
      -0.011957172304391861,
      -0.028380529955029488,
      0.04582865536212921,
      0.0820050984621048,
      -0.008929062634706497,
      0.01287625078111887,
      -0.036519814282655716,
      0.00996709056198597,
      -0.01272500865161419,
      0.026531415060162544,
      0.03348309174180031,
      -0.010215002112090588,
      -0.028081413358449936,
      -0.13251091539859772,
      -0.025829458609223366,
      0.01282415259629488,
      -0.06582067161798477,
      0.08394730091094971,
      0.03218887373805046,
      0.017700031399726868,
      -0.07118230313062668,
      0.0439477376639843,
      -0.10087474435567856,
      -0.05647105351090431,
      -0.05657046288251877,
      -0.011143960990011692,
      0.00509468000382185,
      0.012499505653977394,
      0.03503113612532616,
      0.05040081590414047,
      -0.023882437497377396,
      -0.0017427186248824,
      -0.05441851168870926,
      -0.03090260736644268,
      -0.0029097008518874645,
      0.013863129541277885,
      -0.037073731422424316,
      -0.0294681116938591,
      0.05864982306957245,
      0.01961640641093254,
      -0.05378882959485054,
      0.016236014664173126,
      0.058116115629673004,
      0.10263215750455856,
      -0.003142773173749447,
      0.04373626783490181,
      -0.02982264570891857,
      -0.038952384144067764,
      0.04022376984357834,
      0.07691517472267151,
      0.00946849212050438,
      -0.06060609593987465,
      0.04381005838513374,
      -0.008595370687544346,
      -0.04817347601056099,
      -0.01754305325448513,
      0.04271147772669792,
      -0.02499164268374443,
      -0.07052157819271088,
      -0.02612701989710331,
      -0.048763204365968704,
      0.07408800721168518,
      -0.07219906151294708,
      0.0019611057359725237,
      0.06743131577968597,
      0.054034627974033356,
      -0.04160214215517044,
      -0.08913441747426987,
      -0.04364881291985512,
      -0.025557734072208405,
      -0.024243265390396118,
      -0.016522211953997612,
      -0.022695427760481834,
      0.05337510630488396,
      0.046738795936107635,
      -0.07406263053417206,
      0.040283869951963425,
      0.0393940731883049,
      0.08994598686695099,
      0.027645424008369446,
      -0.09607585519552231,
      0.03905269503593445,
      -0.03053722158074379,
      0.002011789008975029,
      0.011507592163980007,
      0.02339692786335945,
      0.009253913536667824,
      -0.023204660043120384,
      -0.03165334090590477,
      0.07944856584072113,
      0.006279115565121174,
      -0.018288109451532364,
      -0.053062018007040024,
      -0.0029341529589146376,
      0.050777729600667953,
      -0.06149166449904442,
      -0.1619633585214615,
      0.0407535657286644,
      0.015554167330265045,
      0.03483470156788826,
      -0.026754742488265038,
      0.008146421983838081,
      0.04466652870178223,
      -0.10999350994825363,
      0.012788296677172184,
      0.023683352395892143,
      0.02335488609969616,
      0.006937836762517691,
      -0.03425201028585434,
      0.03868599981069565,
      -0.007070737425237894,
      0.10374047607183456,
      -0.0012115207500755787,
      0.08190063387155533,
      -0.01505931094288826,
      0.022076742723584175,
      0.05923192948102951,
      0.05987415835261345,
      -0.05197415128350258,
      0.02842973917722702,
      0.02398751489818096,
      -0.004752565640956163,
      -0.06239241361618042,
      0.004534649197012186,
      -0.013094333000481129,
      -0.03615733981132507,
      0.01614903099834919,
      -0.053581833839416504,
      -0.06086869537830353,
      -0.06091545894742012,
      0.018079649657011032,
      0.04705436900258064,
      0.07202400267124176,
      -0.0206717811524868,
      0.07843492180109024,
      0.017387371510267258,
      0.03165225684642792,
      -0.0475800447165966,
      0.02488073706626892,
      0.08467909693717957,
      -0.11115856468677521,
      0.008813970722258091,
      0.016568586230278015,
      0.08461346477270126,
      -0.025655554607510567,
      0.04979930445551872,
      -0.03787524998188019,
      -0.040235985070466995,
      0.07706259191036224,
      0.027409307658672333,
      0.05666263401508331,
      -0.06143728271126747,
      0.03839070349931717,
      0.047029800713062286,
      -0.017653202638030052,
      -0.004557333420962095,
      -0.014501095749437809,
      0.009975871071219444,
      0.05831598490476608,
      0.015582774765789509,
      -0.09743103384971619,
      0.019166547805070877,
      -0.007519259583204985,
      0.009948637336492538,
      0.06915733218193054,
      0.0293706227093935,
      -0.004090445581823587
    ],
    [
      0.022482534870505333,
      0.015522020868957043,
      -0.048975639045238495,
      -0.018874095752835274,
      0.04702751711010933,
      -0.026633644476532936,
      -0.026145558804273605,
      -0.015168417245149612,
      0.038921844214200974,
      0.13375616073608398,
      0.017925376072525978,
      0.043078213930130005,
      0.03874821588397026,
      0.024465462192893028,
      0.07038035243749619,
      0.016861392185091972,
      -0.024014901369810104,
      0.07515610754489899,
      0.00720575824379921,
      -0.027761340141296387,
      -0.0033524848986417055,
      0.01975438743829727,
      -0.02470371127128601,
      -0.02077876776456833,
      0.0075004021637141705,
      0.005048196297138929,
      -0.022010864689946175,
      0.038632817566394806,
      -0.0011727568926289678,
      -0.03142143413424492,
      0.0870625302195549,
      -0.03391367197036743,
      -0.0033066945616155863,
      0.03260494023561478,
      -0.03886360675096512,
      0.02425984852015972,
      -0.0028158999048173428,
      -0.008688642643392086,
      0.002227699151262641,
      0.015910163521766663,
      0.10626556724309921,
      -0.08256363123655319,
      -0.10365542769432068,
      -0.08565413951873779,
      -0.09442553669214249,
      -0.06584134697914124,
      -0.037028003484010696,
      -0.022358249872922897,
      -0.0349850133061409,
      3.8384085200959817e-05,
      0.0288925152271986,
      -0.1057010218501091,
      0.029616598039865494,
      -0.07260789722204208,
      -0.12857188284397125,
      -0.01003336813300848,
      0.11392559111118317,
      -0.015002898871898651,
      0.021287111565470695,
      -0.07207822054624557,
      -0.023480182513594627,
      -0.01194667350500822,
      0.025444889441132545,
      -0.030438492074608803,
      -0.05033092945814133,
      -0.044844936579465866,
      0.00042198540177196264,
      0.1110401526093483,
      0.08727725595235825,
      -0.011030602268874645,
      0.004566974006593227,
      0.03503403067588806,
      0.04657868295907974,
      -0.01122189313173294,
      -0.014251102693378925,
      -0.0005538536934182048,
      -0.0222011748701334,
      -0.000979023752734065,
      -0.00819041021168232,
      -0.002758122282102704,
      0.0706702396273613,
      0.08700081706047058,
      0.07866204530000687,
      0.026571279391646385,
      0.07337646186351776,
      -0.03709717467427254,
      -0.0021488601341843605,
      -0.016666142269968987,
      0.013084117323160172,
      -0.03606421500444412,
      -0.03329024836421013,
      -0.05435556545853615,
      0.020508790388703346,
      -0.0025387585628777742,
      -0.0008141595753841102,
      0.027692029252648354,
      0.011028469540178776,
      0.01357388123869896,
      0.08299755305051804,
      -0.016352582722902298,
      0.028863441199064255,
      -0.06658772379159927,
      -0.025779282674193382,
      -0.005181357730180025,
      0.09691653400659561,
      0.053265754133462906,
      0.025175578892230988,
      0.04395375773310661,
      0.05068041756749153,
      0.12992024421691895,
      0.012860973365604877,
      0.03773932158946991,
      0.015460305847227573,
      -0.0026664729230105877,
      0.08270598202943802,
      0.02768348902463913,
      -0.04834494739770889,
      -0.009524567984044552,
      0.05615353211760521,
      0.06583338230848312,
      0.0808333158493042,
      0.01087009347975254,
      -0.0016436332371085882,
      0.008054948411881924,
      -0.03582362085580826,
      0.04683326929807663,
      -0.03241516649723053,
      -0.04328274726867676,
      -0.13252677023410797,
      -0.058561988174915314,
      -0.024122042581439018,
      0.035480204969644547,
      -0.0280710831284523,
      0.025551363825798035,
      0.06459122151136398,
      0.050477687269449234,
      0.022917594760656357,
      -0.05605259910225868,
      -0.02192949317395687,
      -0.018098361790180206,
      -0.04430535063147545,
      -0.04130773991346359,
      0.0023520239628851414,
      -0.016264203935861588,
      0.07501442730426788,
      -0.03804131969809532,
      -0.009220562875270844,
      0.050153788179159164,
      -0.03771808743476868,
      -0.035596758127212524,
      0.02770564891397953,
      0.0040166424587368965,
      0.018578868359327316,
      0.04538757726550102,
      0.03341442346572876,
      0.102890245616436,
      0.01113094575703144,
      0.05010661110281944,
      0.03127911686897278,
      -0.009001138620078564,
      0.009450456127524376,
      0.08270718902349472,
      -0.024126283824443817,
      0.07967140525579453,
      0.01856996864080429,
      -0.0015484452014788985,
      0.03809377923607826,
      0.021552134305238724,
      0.033966291695833206,
      -0.04826577380299568,
      0.019809631630778313,
      -0.09579835832118988,
      0.021211903542280197,
      0.005157642997801304,
      0.026202885434031487,
      0.09240744262933731,
      -0.0191765446215868,
      0.04629237577319145,
      -0.04580450430512428,
      0.0024843867868185043,
      -0.0009183502406813204,
      0.015450806356966496,
      -0.005122868809849024,
      -0.0010927749099209905,
      0.00030530107324011624,
      0.1430521160364151,
      0.018276838585734367,
      0.024615373462438583,
      -0.06788864731788635,
      0.024568604305386543,
      -0.010409645736217499,
      -0.01639850065112114,
      0.03426920995116234,
      -0.02214938774704933,
      0.030583426356315613,
      -0.004577809013426304,
      0.06121161952614784,
      -0.04683443158864975,
      -0.04903025925159454,
      0.027279017493128777,
      0.0013664461439475417,
      -0.003801105311140418,
      -0.0490446574985981,
      -0.026183048263192177,
      0.02780914306640625,
      -0.041633330285549164,
      0.030833138152956963,
      -0.07297011464834213,
      -0.051172249019145966,
      0.024367744103074074,
      0.11147046834230423,
      -0.006479418836534023,
      -0.003571476321667433,
      0.05496171861886978,
      0.030754247680306435,
      0.003963255323469639,
      -0.028840860351920128,
      0.043576523661613464,
      -0.04920550808310509,
      0.019879069179296494,
      0.06471992284059525,
      -0.0009224014938808978,
      0.05962932109832764,
      0.007819815538823605,
      -0.018466036766767502,
      0.026828831061720848,
      0.0163711030036211,
      -0.035242583602666855,
      0.07750661671161652,
      0.06310898810625076,
      0.018622372299432755,
      -0.025198249146342278,
      0.008471432141959667,
      -0.012590824626386166,
      0.019908785820007324,
      0.0025742289144545794,
      0.017370877787470818,
      0.06796087324619293,
      0.007390649523586035,
      -0.033268339931964874,
      -0.039646316319704056,
      0.005579991731792688,
      0.016738077625632286,
      -0.013012967072427273,
      -0.0050294846296310425,
      0.0653558149933815,
      0.02355949394404888,
      0.058567628264427185,
      0.024475708603858948,
      0.005368939600884914,
      -0.005836475640535355,
      0.01853240840137005,
      0.09425709396600723,
      0.059080228209495544,
      -0.06860679388046265,
      0.07204736024141312,
      -0.027821088209748268,
      -0.03332924842834473,
      0.02070937119424343,
      0.01613749749958515,
      0.028508538380265236,
      0.020350884646177292,
      -0.0857376903295517,
      0.0033471849747002125,
      -0.010938442312180996,
      -9.019969729706645e-05,
      -0.03072875179350376,
      0.016444316133856773,
      0.0773123949766159,
      0.03358273580670357,
      0.07585952430963516,
      -0.014566064812242985,
      -0.025901474058628082,
      0.015298141166567802,
      0.06670580804347992,
      0.04455901309847832,
      0.006510901264846325,
      -0.04476341977715492,
      -0.016811061650514603,
      0.01053697895258665,
      -0.02609717659652233,
      -0.0347946472465992,
      0.03476141765713692,
      0.04851511865854263,
      0.040815554559230804,
      0.015997115522623062,
      0.00149543269071728,
      0.035471849143505096,
      0.015360662713646889,
      0.025996727868914604,
      -0.031004944816231728,
      -0.0011946054873988032,
      0.043787430971860886,
      0.017840193584561348,
      0.019513390958309174,
      -0.038460519164800644,
      0.027645284309983253,
      0.02903139777481556,
      -0.02088152803480625,
      0.040023140609264374,
      -0.004532268736511469,
      0.06491335481405258,
      -0.02879904955625534,
      -0.02491656132042408,
      -0.006274291314184666,
      -0.017585420981049538,
      -0.10191800445318222,
      -0.059322454035282135,
      -0.05218586325645447,
      0.02862967550754547,
      0.042882826179265976,
      -0.02448400855064392,
      -0.06312797218561172,
      0.034748490899801254,
      0.021796725690364838,
      -0.026117343455553055,
      -0.023430824279785156,
      0.01730494759976864,
      0.035336803644895554,
      0.016576379537582397,
      0.05893721058964729,
      0.03275531902909279,
      -0.013421333394944668,
      0.08091142773628235,
      -0.04622035101056099,
      0.020869331434369087,
      0.016829364001750946,
      0.07414716482162476,
      -0.041851215064525604,
      -0.0718434676527977,
      -0.027392160147428513,
      0.01978614553809166,
      0.0015565627254545689,
      -0.05181131139397621,
      -0.06870332360267639,
      -0.11168611794710159,
      0.05897238850593567,
      0.09508953988552094,
      -0.01782885566353798,
      -0.009800773113965988,
      0.021657662466168404,
      0.029472125694155693,
      0.035082317888736725,
      0.028639065101742744,
      -0.004391367547214031,
      -0.02656545862555504,
      -0.02977365255355835,
      0.04532641917467117,
      0.009877359494566917,
      0.08168786019086838,
      -0.009955857880413532,
      0.06174120679497719,
      0.07150352746248245,
      0.023238224908709526,
      -0.005175688769668341,
      0.003389472607523203,
      0.039265070110559464,
      0.09756781905889511,
      0.000591693096794188,
      -0.107106514275074,
      0.016928113996982574,
      -0.007072167936712503,
      0.020764561370015144,
      -0.02144450880587101,
      0.05053834617137909,
      -0.03112572245299816,
      0.04343872889876366,
      0.0018246887484565377,
      -0.050076741725206375,
      -0.008239192888140678,
      -0.035662420094013214,
      -0.015897488221526146,
      0.07058799266815186,
      0.11526371538639069,
      0.06381590664386749,
      -0.03870576247572899,
      -0.05888681858778,
      -0.014434587210416794,
      -0.03070312924683094,
      0.021523775532841682,
      -0.01997213251888752,
      -0.007682275027036667,
      0.025682702660560608,
      0.06835339218378067,
      -0.021481096744537354,
      0.04659522324800491,
      -0.03644762188196182,
      0.009116408415138721,
      -0.09994367510080338,
      0.07156120240688324,
      0.07652966678142548,
      -0.006521412171423435,
      0.06122605875134468,
      -0.015261135995388031,
      0.02868134342133999,
      -0.05323810502886772,
      0.06835416704416275,
      0.061772171407938004,
      0.022113120183348656,
      -0.029548050835728645,
      -0.037340790033340454,
      0.06415829807519913,
      0.034717269241809845,
      0.04982269927859306,
      0.03998526558279991,
      -0.061750125139951706,
      -0.034286532551050186,
      -0.03091767057776451,
      0.050142236053943634,
      -0.04822831600904465,
      -0.02542457915842533,
      -0.08785326033830643,
      -0.018950900062918663,
      0.012435664422810078,
      -0.09166397899389267,
      -0.04940878227353096,
      -0.0474531352519989,
      0.05378471314907074,
      -0.023121191188693047,
      -0.0003973757557105273,
      -0.021481890231370926,
      -0.025171315297484398,
      -0.04973991587758064,
      -0.03403857722878456,
      0.02166140265762806,
      -0.04265560582280159,
      -0.01428646594285965,
      0.007238257210701704,
      -0.013799376785755157,
      0.007415489759296179,
      0.05571749806404114,
      0.03451211377978325,
      0.04102912172675133,
      0.010287023149430752,
      -0.006943770218640566,
      0.092104472219944,
      -0.05895572528243065,
      -0.02950035035610199,
      -0.012053039856255054,
      -0.004362946376204491,
      -0.04238620400428772,
      0.0276500154286623,
      -0.02433757111430168,
      0.09946075826883316,
      0.014412366785109043,
      0.009084251709282398,
      0.01810275949537754,
      -0.015870103612542152,
      -0.042772673070430756,
      0.01767732948064804,
      -0.10949694365262985,
      -0.0356307290494442,
      0.0005939139518886805,
      0.016601085662841797,
      0.0851653441786766,
      -0.04834575578570366,
      -0.05287279188632965,
      -0.011706653982400894,
      0.024405954405665398,
      0.058520007878541946,
      0.08868969976902008,
      0.012900140136480331,
      -0.03774229437112808,
      -0.03712214529514313,
      -0.03246195614337921,
      -0.05250560864806175,
      -0.027638856321573257,
      -0.03129654377698898,
      -0.04277307540178299,
      0.024669691920280457,
      0.03309587016701698,
      0.0009894027607515454,
      0.013515791855752468,
      0.03264043852686882,
      -0.025861961767077446,
      -0.09334272891283035,
      0.043866608291864395,
      -0.035146232694387436,
      -0.06755638867616653,
      0.039627499878406525,
      -0.02846505120396614,
      0.06673755496740341,
      -0.09005649387836456,
      -0.0571286641061306,
      -0.029454655945301056,
      0.03924669697880745,
      0.02480565756559372,
      -0.030327605083584785,
      0.03334283456206322,
      0.000935515679884702,
      -0.05766782537102699,
      0.07628823816776276,
      -0.09428868442773819,
      -0.039825402200222015,
      -0.013194297440350056,
      -0.019559383392333984,
      -0.01363756600767374,
      0.01634557545185089,
      -0.01943277381360531,
      -0.05728019028902054,
      0.048432279378175735,
      -0.05115382745862007,
      0.009562437422573566
    ],
    [
      0.01990756019949913,
      -0.022326035425066948,
      0.004847871605306864,
      -0.037734001874923706,
      0.055390916764736176,
      0.010309336706995964,
      -0.05277740955352783,
      -0.003262103535234928,
      -0.051171381026506424,
      0.041370466351509094,
      -0.03092537447810173,
      0.047054000198841095,
      -0.03561566397547722,
      -0.019004523754119873,
      -0.045291222631931305,
      -0.05801071226596832,
      0.011489877477288246,
      -0.019342858344316483,
      -0.03640558198094368,
      0.006264643743634224,
      -0.05001762509346008,
      -0.07641211897134781,
      -0.039135824888944626,
      -0.0058548105880618095,
      0.015804065391421318,
      0.025956951081752777,
      -0.08520179986953735,
      0.0005944907898083329,
      -0.0017685566563159227,
      -0.025118615478277206,
      0.03691043704748154,
      -0.025564098730683327,
      -0.05560166761279106,
      0.006070651113986969,
      -0.006790320388972759,
      -0.014662828296422958,
      -0.01683622971177101,
      -0.004930867347866297,
      0.058259062469005585,
      -0.04681087285280228,
      0.06560550630092621,
      0.038077376782894135,
      0.017576925456523895,
      0.04140500724315643,
      -0.030903654173016548,
      0.07215788960456848,
      -0.03648622706532478,
      0.014234974049031734,
      0.017301876097917557,
      0.003406116273254156,
      0.09066552668809891,
      0.05125753581523895,
      -0.017112204805016518,
      -0.038479771465063095,
      -0.02396954968571663,
      0.013291596435010433,
      -0.09976916760206223,
      -0.059045664966106415,
      0.05063813552260399,
      0.005689402110874653,
      -0.050984278321266174,
      0.0673004686832428,
      0.0811624750494957,
      -0.02265336737036705,
      0.016606513410806656,
      -0.048356980085372925,
      0.01735238917171955,
      -0.09404636919498444,
      -0.04224950075149536,
      -0.05992946773767471,
      0.06935149431228638,
      0.010602617636322975,
      -0.004926995374262333,
      0.06542131304740906,
      -0.0764436423778534,
      -0.06295259296894073,
      -0.05477166175842285,
      -0.06546849012374878,
      -0.058201149106025696,
      -0.06013662740588188,
      -0.010860912501811981,
      0.11281968653202057,
      -0.0444660447537899,
      0.03955403342843056,
      0.02977796085178852,
      -0.0003306986764073372,
      0.014225040562450886,
      0.09845562279224396,
      0.05081098899245262,
      0.04790695756673813,
      -0.013185108080506325,
      -0.015078122727572918,
      -0.02723398059606552,
      0.006086897570639849,
      0.04835504665970802,
      0.09289969503879547,
      -0.06717683374881744,
      -0.014348035678267479,
      0.0016010135877877474,
      0.01666567102074623,
      0.07761438935995102,
      -0.0436067208647728,
      -0.1015234962105751,
      -0.020627694204449654,
      0.009025690145790577,
      0.02120693027973175,
      0.08274436742067337,
      -0.048152536153793335,
      -0.025869177654385567,
      0.043429501354694366,
      -0.03307318314909935,
      -0.01071556843817234,
      -0.02480849251151085,
      -0.02091796323657036,
      0.01825432851910591,
      0.05990343913435936,
      -0.058074578642845154,
      -0.012343185022473335,
      0.020875386893749237,
      0.031871162354946136,
      -0.01412492897361517,
      -0.050319407135248184,
      0.01797826588153839,
      0.02366829477250576,
      0.03199424967169762,
      0.007001249585300684,
      -0.024594107642769814,
      -0.02171448990702629,
      -0.0862206220626831,
      -0.06797897815704346,
      -0.017367951571941376,
      -0.010186693631112576,
      -0.049876533448696136,
      0.006717911455780268,
      0.019260147586464882,
      -0.06173894554376602,
      -0.0784916803240776,
      0.002943970263004303,
      0.03896653279662132,
      0.00301750167272985,
      -0.006324434652924538,
      -0.02743043564260006,
      -0.007864410057663918,
      -0.029410136863589287,
      -0.011789388954639435,
      0.016518065705895424,
      -0.008838346228003502,
      0.018421966582536697,
      0.029161890968680382,
      0.07840628921985626,
      0.012354003265500069,
      -0.0109307412058115,
      0.025033146142959595,
      -0.0878523737192154,
      0.006824256852269173,
      0.019392654299736023,
      -0.1094493642449379,
      -0.02287052385509014,
      0.09312396496534348,
      -0.07241832464933395,
      -0.10569054633378983,
      -0.003950728103518486,
      -0.004562821239233017,
      0.03521599993109703,
      -0.07672472298145294,
      -0.020208781585097313,
      0.029033122584223747,
      0.019165072590112686,
      -0.04869057610630989,
      -0.024866625666618347,
      0.05536913871765137,
      0.04626692831516266,
      0.01612071692943573,
      -0.0030670780688524246,
      0.012582618743181229,
      0.050133366137742996,
      0.02059759758412838,
      -0.030067894607782364,
      -0.004476402420550585,
      -0.02653152123093605,
      -0.019380873069167137,
      0.010682540014386177,
      -0.0396772138774395,
      0.04662559926509857,
      -0.02468453161418438,
      0.05901368334889412,
      -0.082057423889637,
      -0.02182106114923954,
      -0.024291353300213814,
      -0.026506120339035988,
      0.055224910378456116,
      0.03482343256473541,
      0.022273879498243332,
      0.03182738274335861,
      0.018784405663609505,
      0.033533282577991486,
      -0.025650743395090103,
      0.02983676828444004,
      0.04084854945540428,
      -0.03067290410399437,
      -0.029865151271224022,
      -0.03843735158443451,
      -0.00011546692985575646,
      -0.028234928846359253,
      0.038934092968702316,
      0.015121552161872387,
      0.05066625028848648,
      -0.01447361335158348,
      0.01638648472726345,
      -0.08013683557510376,
      -0.04690639302134514,
      0.045176371932029724,
      0.01619679480791092,
      0.07395472377538681,
      -0.05786443501710892,
      0.08504389226436615,
      0.05008360743522644,
      0.027064863592386246,
      -0.07080406695604324,
      0.015222189947962761,
      0.02180362492799759,
      0.012782839126884937,
      -0.0009708072175271809,
      0.02741997502744198,
      0.039337992668151855,
      0.004444716032594442,
      0.011638336814939976,
      -0.05216362327337265,
      -0.08584155887365341,
      0.012298280373215675,
      0.036140426993370056,
      0.060654670000076294,
      -0.01873067580163479,
      0.005405336618423462,
      -0.07168903201818466,
      0.03413186967372894,
      -0.029068071395158768,
      -0.0379483588039875,
      0.01421283558011055,
      -0.041225943714380264,
      0.06030362471938133,
      -0.020099345594644547,
      0.00833932776004076,
      -0.04781538248062134,
      -0.041756950318813324,
      0.004766817204654217,
      0.025424377992749214,
      0.013627342879772186,
      -0.05521060898900032,
      0.06484731286764145,
      -0.049138568341732025,
      -0.015218417160212994,
      -0.07005582004785538,
      -0.04529794678092003,
      -0.0759977325797081,
      -0.1168469786643982,
      -0.03697846084833145,
      -0.003367082215845585,
      -0.030813705176115036,
      -0.06624512374401093,
      -0.061097580939531326,
      0.055490635335445404,
      -0.1196824163198471,
      -0.01740875095129013,
      -0.0646064430475235,
      0.024295445531606674,
      -0.037994228303432465,
      -0.12017180025577545,
      -0.06936664879322052,
      0.031366996467113495,
      0.023817744106054306,
      0.04078087955713272,
      0.049327749758958817,
      0.08156725019216537,
      -0.03563288226723671,
      -0.021109195426106453,
      0.07022899389266968,
      0.03557514771819115,
      -0.047638189047575,
      -0.010980828665196896,
      -0.09068084508180618,
      0.04286197945475578,
      -0.015508926473557949,
      -0.0005588844651356339,
      -0.0645977184176445,
      0.08075817674398422,
      -0.05919939652085304,
      0.01884649135172367,
      -0.033018890768289566,
      0.01828678697347641,
      0.0034937625750899315,
      0.11006918549537659,
      0.03381740301847458,
      -0.0218006893992424,
      -0.018122484907507896,
      -0.005672989878803492,
      -0.06647296249866486,
      -0.05631314590573311,
      -0.03478226810693741,
      -0.012871965765953064,
      -0.036669716238975525,
      0.008749837055802345,
      -0.04033863544464111,
      -0.019824497401714325,
      -0.021443555131554604,
      0.04972543939948082,
      0.05125042051076889,
      0.05361553654074669,
      -0.031680069863796234,
      -0.08944317698478699,
      0.011553220450878143,
      0.05938564985990524,
      -0.019117167219519615,
      0.006748490035533905,
      -0.02161991596221924,
      -0.053393106907606125,
      0.04504510387778282,
      0.0166728887706995,
      -0.043487194925546646,
      -0.021764451637864113,
      0.03224333003163338,
      0.022520504891872406,
      -0.016266459599137306,
      0.0695747435092926,
      0.08354263752698898,
      -0.051672495901584625,
      0.03800082579255104,
      0.020544691011309624,
      0.017981572076678276,
      -0.052091214805841446,
      -0.05265774205327034,
      -0.026469996199011803,
      -0.02487310953438282,
      0.02794755809009075,
      0.0528966560959816,
      -0.07227464020252228,
      0.025035396218299866,
      0.1288451850414276,
      0.03547973558306694,
      -0.02232213318347931,
      -0.01063353568315506,
      -0.059669241309165955,
      0.008479618467390537,
      0.05032967031002045,
      0.06774506717920303,
      -0.039156053215265274,
      0.042266592383384705,
      -0.013656068593263626,
      0.0565008781850338,
      0.04509463906288147,
      0.03992500156164169,
      0.0020551392808556557,
      0.06698740273714066,
      -0.03926585614681244,
      -0.07949627190828323,
      -0.014874713495373726,
      0.02331320382654667,
      0.042143456637859344,
      -0.06654549390077591,
      0.02485964260995388,
      -0.025690719485282898,
      -0.030667070299386978,
      0.05603305250406265,
      0.04520982503890991,
      -0.04761131480336189,
      0.02460295893251896,
      0.03221160173416138,
      -0.05872058495879173,
      0.012799746356904507,
      0.04244696721434593,
      -0.05746108666062355,
      -0.06544926762580872,
      -0.022501330822706223,
      0.04546904191374779,
      -0.007215593475848436,
      -0.03172266483306885,
      -0.031889162957668304,
      0.0778917446732521,
      0.024554423987865448,
      0.05137623846530914,
      -0.042491182684898376,
      -0.02778013050556183,
      0.0335933156311512,
      0.016966214403510094,
      -0.03487126901745796,
      -0.006953677628189325,
      0.0648638904094696,
      -0.005462163593620062,
      0.08090924471616745,
      0.018553365021944046,
      -0.06427904963493347,
      -0.0659727081656456,
      -0.08892319351434708,
      0.0014897671062499285,
      0.051574185490608215,
      0.06902050226926804,
      -0.020068999379873276,
      -0.07436347752809525,
      -0.023517372086644173,
      -0.0024790684692561626,
      -0.06306787580251694,
      -0.0759207084774971,
      -0.005398280918598175,
      -0.044038910418748856,
      -0.026746489107608795,
      0.041297852993011475,
      0.07549997419118881,
      -0.03804541006684303,
      -0.002626498229801655,
      -0.007644587196409702,
      -0.04761026054620743,
      0.05929143726825714,
      -0.01579097844660282,
      -0.10879155248403549,
      0.036197729408741,
      -0.001987376483157277,
      -0.01381910964846611,
      0.013883909210562706,
      0.02705356664955616,
      0.053005870431661606,
      -0.02290182188153267,
      0.019047629088163376,
      -0.010127760469913483,
      0.009458913467824459,
      0.04811905696988106,
      0.014839114621281624,
      0.02894270233809948,
      -5.032141780247912e-05,
      -0.0006666097324341536,
      0.06629760563373566,
      -0.0030378878582268953,
      -0.034956518560647964,
      0.11486990749835968,
      -0.01902013085782528,
      -9.980877803172916e-05,
      -0.017171481624245644,
      0.08421646803617477,
      -0.13478590548038483,
      -0.030258258804678917,
      -0.008592700585722923,
      -0.06269163638353348,
      0.008501153439283371,
      0.016484500840306282,
      0.016706904396414757,
      0.010577627457678318,
      -0.063017837703228,
      -0.029435232281684875,
      -0.00028608564753085375,
      -0.04441682994365692,
      -0.028285076841711998,
      -0.019945798441767693,
      -0.02926539070904255,
      -0.036374855786561966,
      -0.0056873951107263565,
      -0.04323924332857132,
      -0.0228372011333704,
      -0.0240755844861269,
      0.03874910622835159,
      0.04357628524303436,
      -0.03379497304558754,
      -0.007070217747241259,
      -0.04179858788847923,
      0.04731578752398491,
      -0.034239500761032104,
      -0.06318952888250351,
      -0.02550506591796875,
      -0.014526784420013428,
      -0.003909098915755749,
      0.00727376202121377,
      -0.028555478900671005,
      0.045246951282024384,
      0.00048499126569367945,
      -0.11605090647935867,
      0.005674771033227444,
      0.01527279894798994,
      -0.020250443369150162,
      0.0006031602388247848,
      -0.03377193957567215,
      0.07885739207267761,
      0.04158700257539749,
      -0.00806826539337635,
      0.02238662913441658,
      -0.012084300629794598,
      -0.00944560393691063,
      0.09223622828722,
      0.009805519133806229,
      -0.060785140842199326,
      -0.11233565211296082,
      -0.044275980442762375,
      -0.006124276202172041,
      0.05001288279891014,
      0.03407074138522148,
      -0.09283982962369919,
      -0.00025061078486032784,
      0.007333157118409872,
      -0.06531958281993866,
      0.0365922674536705,
      -0.050940848886966705,
      -0.00498163141310215,
      0.0005577350966632366,
      0.02340366691350937,
      -0.06043842434883118,
      -0.05307420715689659
    ],
    [
      -0.054527245461940765,
      0.04103574901819229,
      -0.057869803160429,
      -0.026156673207879066,
      -0.10100209712982178,
      0.029751906171441078,
      -0.045087262988090515,
      0.029931429773569107,
      0.020024774596095085,
      0.07855191081762314,
      -0.031524255871772766,
      -0.042922522872686386,
      0.003986290656030178,
      0.14515487849712372,
      0.023777347058057785,
      -0.06066064164042473,
      -0.032335616648197174,
      0.013692078180611134,
      0.02589724399149418,
      0.03322597220540047,
      0.04711102321743965,
      -0.062001436948776245,
      0.0016642243135720491,
      -0.009229084476828575,
      -0.08421297371387482,
      0.0072430456057190895,
      -0.01696358434855938,
      -0.03680133447051048,
      -0.11190210282802582,
      -0.03314826637506485,
      -0.013504590839147568,
      0.06731077283620834,
      0.0048906803131103516,
      -0.0024153687991201878,
      0.025191837921738625,
      -0.010738372802734375,
      0.00011814838944701478,
      -0.02437197044491768,
      0.030731473118066788,
      -0.028942611068487167,
      0.009472544305026531,
      0.021238533779978752,
      -0.0261110607534647,
      -0.008111444301903248,
      -0.09200534224510193,
      0.07046198844909668,
      -0.04141538217663765,
      -0.10602403432130814,
      -0.07721864432096481,
      -0.012543718330562115,
      -0.01782899722456932,
      -0.09868451207876205,
      0.042990125715732574,
      0.028959190472960472,
      -0.08941716700792313,
      0.06002673879265785,
      -0.012481147423386574,
      0.020566264167428017,
      -0.00850697048008442,
      -0.03173501789569855,
      0.04876206815242767,
      -0.05923529714345932,
      0.0028882399201393127,
      0.015445136465132236,
      0.013521034270524979,
      -0.021930890157818794,
      0.008865203708410263,
      -0.0009655877365730703,
      -0.07195253670215607,
      -0.08309072256088257,
      0.004728938918560743,
      0.01144237071275711,
      -0.038726817816495895,
      -0.024996289983391762,
      0.024347253143787384,
      -0.03282022848725319,
      -0.026869885623455048,
      0.046530187129974365,
      -0.0234220027923584,
      0.011145724914968014,
      -0.07731537520885468,
      0.009381940588355064,
      0.02900758944451809,
      0.04505520313978195,
      -0.00666703749448061,
      -0.04454665258526802,
      -0.004245000425726175,
      0.010468014515936375,
      -0.0017749101389199495,
      -0.04040278494358063,
      -0.029101459309458733,
      -0.002970318077132106,
      0.03774731606245041,
      0.03857716917991638,
      -0.05841373652219772,
      -0.02803085744380951,
      -0.03986157849431038,
      -0.003613039618358016,
      -0.033780958503484726,
      0.0010232006898149848,
      -0.06318291276693344,
      0.02098606340587139,
      -0.08585042506456375,
      0.09691204130649567,
      0.06852127611637115,
      0.0010263576405122876,
      -0.04892980307340622,
      0.03809281811118126,
      0.03335733711719513,
      0.06309612840414047,
      0.0015692375600337982,
      0.0015769063029438257,
      -0.0037321497220546007,
      -0.00108776253182441,
      0.0021351901814341545,
      -0.016267459839582443,
      -0.02662389539182186,
      0.016700314357876778,
      -0.03718267381191254,
      -0.03641249239444733,
      0.053733374923467636,
      0.06622309982776642,
      -0.06903750449419022,
      0.06210680305957794,
      0.027010207995772362,
      0.01113439816981554,
      -0.02469908632338047,
      0.080647774040699,
      -0.006284358911216259,
      -0.06419206410646439,
      0.032837044447660446,
      -0.026887262240052223,
      0.007555463816970587,
      -0.03182513639330864,
      0.001760835642926395,
      -0.037012550979852676,
      0.008662407286465168,
      0.005473671946674585,
      -0.003919233568012714,
      0.04212408885359764,
      -0.07358544319868088,
      -0.030592506751418114,
      0.05231345817446709,
      -0.06120438873767853,
      0.05956132337450981,
      -0.03361143544316292,
      -0.007168247364461422,
      -0.006494009867310524,
      0.0008048831368796527,
      -0.047328826040029526,
      -0.004797969479113817,
      -0.03948095068335533,
      0.04707420617341995,
      -0.011327293701469898,
      -0.021204276010394096,
      0.040720876306295395,
      -0.025673218071460724,
      0.011079702526330948,
      0.03635375201702118,
      -0.026754360646009445,
      -0.016717579215765,
      0.0032616022508591413,
      0.01637904718518257,
      -0.052529189735651016,
      -0.029201148077845573,
      -0.05957762897014618,
      -0.0549888014793396,
      0.07532370835542679,
      0.05204050987958908,
      -0.00848575122654438,
      0.0056110769510269165,
      -0.019049640744924545,
      -0.036377307027578354,
      -0.0025528124533593655,
      -0.026859279721975327,
      0.016956288367509842,
      0.01641884259879589,
      0.009186839684844017,
      -0.015833644196391106,
      -0.004188005346804857,
      -0.0071272775530815125,
      0.0231313556432724,
      0.021396450698375702,
      -0.08297301828861237,
      -0.08623264729976654,
      -0.004774234723299742,
      -0.019777297973632812,
      0.014423614367842674,
      -0.044304266571998596,
      0.012910839170217514,
      -0.05077527463436127,
      0.003017330076545477,
      0.02074125036597252,
      -0.017185784876346588,
      -0.0005722991772927344,
      -0.00799401756376028,
      -0.04624895751476288,
      -0.015105301514267921,
      0.03058144450187683,
      -0.03251805901527405,
      -0.006921394262462854,
      0.014044877141714096,
      0.014894070103764534,
      -0.04317718744277954,
      0.07278644293546677,
      -0.03451712802052498,
      -0.036238234490156174,
      -0.04038650169968605,
      -0.000652271555736661,
      -0.04373517632484436,
      0.005762164015322924,
      0.03146810829639435,
      0.06078946590423584,
      -0.024086397141218185,
      -0.0381665863096714,
      0.0552445724606514,
      0.043313030153512955,
      -0.004583051893860102,
      0.07248961925506592,
      -0.013598349876701832,
      -0.04327112436294556,
      0.014760012738406658,
      -0.02408558689057827,
      0.045115672051906586,
      -0.06359930336475372,
      0.03767763450741768,
      -0.04308384284377098,
      -0.03279096633195877,
      -0.012102135457098484,
      -0.011741865426301956,
      0.033803388476371765,
      0.06916197389364243,
      -0.02867056243121624,
      -0.025592239573597908,
      -0.014203076250851154,
      0.0207058135420084,
      -0.016903281211853027,
      -0.00907493382692337,
      -0.04318428784608841,
      -0.024440694600343704,
      -0.0782977044582367,
      -0.003758360631763935,
      0.01905294507741928,
      -0.06303352117538452,
      0.011387190781533718,
      0.08511990308761597,
      -0.015341611579060555,
      -0.005841160658746958,
      -0.12264067679643631,
      0.04660896956920624,
      0.07908112555742264,
      0.09067140519618988,
      0.009738607332110405,
      0.0347367525100708,
      0.01601269468665123,
      -0.054897185415029526,
      -0.04592655226588249,
      -0.022677717730402946,
      -0.009026536718010902,
      0.06836063414812088,
      -0.017941856756806374,
      -0.0017296954756602645,
      0.00812609400600195,
      -2.390333429502789e-05,
      -0.09662509709596634,
      -0.04276518151164055,
      0.08747991174459457,
      0.09205132722854614,
      0.09750482439994812,
      0.024150796234607697,
      -0.06228860095143318,
      -0.008848244324326515,
      -0.028624745085835457,
      0.10702718794345856,
      -0.016509367153048515,
      0.009337482042610645,
      -0.01760650984942913,
      0.046105675399303436,
      -0.12465905398130417,
      -0.0712074264883995,
      0.007975642569363117,
      -0.029015541076660156,
      -0.07422206550836563,
      -0.002582092769443989,
      -0.02727331779897213,
      0.06420309841632843,
      -0.008173569105565548,
      0.03460051119327545,
      -0.09643596410751343,
      -0.012083607725799084,
      -9.816511010285467e-05,
      0.022844767197966576,
      -0.0609779991209507,
      -0.012926573865115643,
      0.035773761570453644,
      -0.02899327129125595,
      0.002095435746014118,
      -0.04571746662259102,
      -0.026042506098747253,
      -0.008353592827916145,
      0.010242112912237644,
      -0.06673640012741089,
      0.1062517911195755,
      -0.02118234522640705,
      0.01983637921512127,
      -0.04298685863614082,
      -0.06431116163730621,
      0.034619979560375214,
      -0.008730551227927208,
      0.04195982217788696,
      0.11073914915323257,
      0.08870835602283478,
      -0.01602351665496826,
      -0.0416722297668457,
      0.056207075715065,
      -0.03542719781398773,
      -0.0013418869348242879,
      -0.04393181949853897,
      0.03654426708817482,
      0.059873584657907486,
      -0.013681351207196712,
      0.01275960449129343,
      0.02646290324628353,
      -0.05102425813674927,
      -0.055429454892873764,
      -0.011737317778170109,
      -0.006517635192722082,
      0.031153103336691856,
      0.03505270183086395,
      -0.0372319370508194,
      0.052956316620111465,
      0.007753570098429918,
      0.030050942674279213,
      -0.027126343920826912,
      -0.00187367491889745,
      0.01662537083029747,
      -0.022341983392834663,
      0.052022602409124374,
      -0.037312764674425125,
      -0.04660478234291077,
      -0.04463218152523041,
      -0.03559480234980583,
      -0.016894152387976646,
      -0.024775972589850426,
      0.052947498857975006,
      0.038372207432985306,
      0.003317526774480939,
      -0.03081655688583851,
      -0.005725387018173933,
      -0.015775252133607864,
      -0.07279977202415466,
      0.09879162162542343,
      0.034401196986436844,
      -0.07432939857244492,
      0.0443572923541069,
      -0.057098399847745895,
      0.08852419257164001,
      0.025071950629353523,
      -0.0038598733954131603,
      0.03171780705451965,
      -0.03525856137275696,
      0.04641574248671532,
      -0.0026074296329170465,
      0.028301939368247986,
      -0.09548112750053406,
      0.04055273160338402,
      -0.05200942978262901,
      -0.01884455233812332,
      -0.06645071506500244,
      -0.09009812772274017,
      0.0792476162314415,
      -0.019014347344636917,
      -0.06262045353651047,
      0.027626795694231987,
      -0.009498403407633305,
      -0.00010362727334722877,
      -0.027928180992603302,
      -0.027756482362747192,
      -0.042875025421381,
      0.029697222635149956,
      -0.008683639578521252,
      -0.019197361543774605,
      -0.0003782680432777852,
      -0.04792151227593422,
      0.001443327171728015,
      0.009526276029646397,
      -0.02707001194357872,
      0.05029947683215141,
      0.05020853504538536,
      0.016986142843961716,
      -0.003965085372328758,
      0.021813081577420235,
      -0.006631872616708279,
      0.02783907949924469,
      0.0972127914428711,
      -0.06223887577652931,
      -0.049268752336502075,
      0.01991138607263565,
      -0.04090462625026703,
      -0.024491626769304276,
      -0.06050228327512741,
      -0.06950141489505768,
      -0.017861641943454742,
      0.012168481945991516,
      -0.14530549943447113,
      0.022868812084197998,
      -0.03763391450047493,
      0.06474488973617554,
      -0.07283152639865875,
      0.0035482796374708414,
      0.023952079936861992,
      -0.11877544969320297,
      0.06766001880168915,
      0.019798696041107178,
      0.0002495798107702285,
      0.006401111837476492,
      0.029651716351509094,
      -0.011648669838905334,
      -0.0258837528526783,
      0.08749930560588837,
      0.009476100094616413,
      -0.0780315175652504,
      -0.008322412148118019,
      -0.0027655595913529396,
      -0.032961294054985046,
      -0.0010460724588483572,
      0.04289824515581131,
      0.1241733729839325,
      0.10653439909219742,
      0.03939260169863701,
      -0.024902334436774254,
      -0.004517564550042152,
      -0.06430824100971222,
      -0.057329680770635605,
      0.05046411231160164,
      0.005425971932709217,
      0.046154700219631195,
      0.03067401796579361,
      0.07382123172283173,
      -0.010534144937992096,
      -0.01824682205915451,
      0.027161981910467148,
      0.0492391511797905,
      -0.029408259317278862,
      0.003151882207021117,
      0.016269730404019356,
      0.057936955243349075,
      0.03059808723628521,
      -0.02335483767092228,
      0.0019859527237713337,
      -0.03589287027716637,
      0.02177029475569725,
      0.012440097518265247,
      0.04700338467955589,
      0.006359083112329245,
      -0.022568531334400177,
      -0.0054130409844219685,
      0.023065820336341858,
      0.03545675054192543,
      -0.14212313294410706,
      0.017786163836717606,
      -0.0731818675994873,
      0.0016405314672738314,
      0.012716727331280708,
      0.05578767880797386,
      -0.014074529521167278,
      0.002637827303260565,
      -0.04917323961853981,
      -0.09038066864013672,
      0.003922519739717245,
      -0.04729560762643814,
      0.006250295788049698,
      0.00591510022059083,
      -0.057085007429122925,
      -0.015588020905852318,
      -0.012117087841033936,
      0.025730958208441734,
      0.01896769180893898,
      0.0024624387733638287,
      0.047834403812885284,
      0.09205448627471924,
      0.010130000300705433,
      0.041521575301885605,
      0.002741148928180337,
      -0.019367216154932976,
      0.013684860430657864,
      0.002874065889045596,
      -0.0494174025952816,
      -0.03160206601023674,
      0.0720401331782341,
      -0.10107862204313278,
      0.05357532203197479,
      0.026636019349098206,
      -0.034585606306791306,
      -0.047740623354911804,
      -0.09456128627061844,
      -0.08237423002719879,
      -0.00695311464369297,
      0.0413704514503479,
      0.042191457003355026,
      -0.025619199499487877,
      -0.016199052333831787,
      0.01673196814954281
    ],
    [
      -0.002118277596309781,
      -0.013217339292168617,
      0.004641663283109665,
      0.014678861945867538,
      0.04391351342201233,
      0.04812555015087128,
      0.05985594168305397,
      -0.031174900010228157,
      -0.008668262511491776,
      -0.04151466488838196,
      -0.01168785523623228,
      -0.04520518705248833,
      0.014945825561881065,
      0.05859224498271942,
      0.002749174367636442,
      0.013540176674723625,
      0.038275305181741714,
      0.025045376271009445,
      -0.013180484995245934,
      -0.029336877167224884,
      0.006325294263660908,
      0.037199974060058594,
      -0.04722734913229942,
      0.011947781778872013,
      -0.06656990200281143,
      -0.04339428246021271,
      -0.06545592844486237,
      -0.049610357731580734,
      -0.008982839062809944,
      0.05502299219369888,
      -0.017423704266548157,
      -0.011873180978000164,
      -0.021814461797475815,
      0.04089510813355446,
      0.004496867302805185,
      0.07178404927253723,
      0.018753884360194206,
      -0.044967230409383774,
      -0.040181007236242294,
      -0.01168068964034319,
      -0.008855067193508148,
      -0.038804177194833755,
      -0.021013524383306503,
      -0.011909624561667442,
      0.06371952593326569,
      -0.0740688294172287,
      -0.04122408106923103,
      -0.07576917111873627,
      -0.018472889438271523,
      -0.03172651678323746,
      0.08512243628501892,
      -0.033263593912124634,
      0.007385211996734142,
      -0.0009096776484511793,
      0.004997867625206709,
      -0.048012617975473404,
      -0.015731081366539,
      0.06469571590423584,
      -0.01272716373205185,
      0.02611333690583706,
      0.012547004036605358,
      0.02851784974336624,
      -0.0004471029678825289,
      0.04501296579837799,
      0.010360912419855595,
      -0.027679065242409706,
      0.060135554522275925,
      -0.0891520157456398,
      -0.06519770622253418,
      -0.002657899633049965,
      0.0012390007032081485,
      0.022451573982834816,
      0.06882995367050171,
      0.003963257651776075,
      -0.015679430216550827,
      0.045614421367645264,
      0.033936724066734314,
      -0.04476306959986687,
      0.02535034529864788,
      0.000252493133302778,
      0.012424761429429054,
      0.02286524884402752,
      0.024767953902482986,
      -0.0049457852728664875,
      0.08589370548725128,
      0.023708360269665718,
      0.005601937882602215,
      0.05860132724046707,
      -0.03747791424393654,
      -0.0046730502508580685,
      0.03287271410226822,
      -0.037783488631248474,
      0.014201894402503967,
      0.06337083876132965,
      0.010402275249361992,
      -0.07517584413290024,
      0.05752880498766899,
      0.03094608709216118,
      -0.11812498420476913,
      -0.005094092804938555,
      -0.015827208757400513,
      -0.06171219050884247,
      -0.012311615981161594,
      -0.061934519559144974,
      0.012205210514366627,
      0.006639020051807165,
      -0.03245347738265991,
      -0.0336163267493248,
      -0.07158287614583969,
      0.06657688319683075,
      -0.004217277746647596,
      0.06737477332353592,
      -0.003243011422455311,
      -0.03721850737929344,
      0.04629727825522423,
      0.002581185195595026,
      0.018983466550707817,
      0.07149159908294678,
      0.044448669999837875,
      0.029513893648982048,
      0.002119337674230337,
      -0.02227236144244671,
      0.011402193456888199,
      -0.04126189276576042,
      -0.022637657821178436,
      0.041758403182029724,
      0.046245504170656204,
      0.07130134105682373,
      -0.02344120852649212,
      0.002113936934620142,
      -0.08466922491788864,
      0.06796445697546005,
      -0.06931497901678085,
      0.07169018685817719,
      0.03594852238893509,
      -0.041514843702316284,
      -0.02450461871922016,
      0.0014930308097973466,
      0.0451517216861248,
      -0.06257137656211853,
      -0.03709109127521515,
      0.009327355772256851,
      -0.020843444392085075,
      -0.05128452554345131,
      -0.03474998101592064,
      -0.05374614894390106,
      0.013066157698631287,
      0.07933032512664795,
      0.005355236120522022,
      -0.05712400749325752,
      -0.040616776794195175,
      0.08224505186080933,
      -0.033414486795663834,
      0.08321091532707214,
      -0.06351032108068466,
      0.049663644284009933,
      0.015695687383413315,
      -0.016485296189785004,
      -0.027212079614400864,
      -0.01638542301952839,
      -0.08287540078163147,
      -0.043036650866270065,
      -0.026520920917391777,
      -0.025271490216255188,
      -0.032692816108465195,
      0.00961293000727892,
      -0.018394527956843376,
      0.033685214817523956,
      -0.04945693165063858,
      -0.024847934022545815,
      -0.006481783930212259,
      -0.05479063466191292,
      -0.04541134834289551,
      -0.03463559225201607,
      -0.010439512319862843,
      0.06864078342914581,
      0.023951902985572815,
      0.021657122299075127,
      -0.010560121387243271,
      0.03308351710438728,
      -0.1081661731004715,
      0.011448804289102554,
      -0.06324861943721771,
      -0.05746028199791908,
      0.008212464861571789,
      0.06657890975475311,
      -0.030625412240624428,
      0.03156396001577377,
      0.009179452434182167,
      -0.016950733959674835,
      -0.017902890220284462,
      -0.03052414022386074,
      0.005479904823005199,
      0.057693928480148315,
      0.013381680473685265,
      0.09142713993787766,
      0.010932089760899544,
      -0.010792392306029797,
      -0.0044929408468306065,
      0.05560360476374626,
      -0.024239100515842438,
      0.06912758946418762,
      -0.015217923559248447,
      0.06199142336845398,
      0.023939507082104683,
      -0.02768380008637905,
      -0.04330740123987198,
      0.017244931310415268,
      -0.01889771968126297,
      -0.02239285223186016,
      0.025531822815537453,
      -0.013274578377604485,
      0.023438842967152596,
      -0.03060002811253071,
      0.03175920620560646,
      0.05238683521747589,
      0.004865400958806276,
      -0.053395435214042664,
      -0.037354983389377594,
      0.007305240258574486,
      -0.024147098883986473,
      0.04736963286995888,
      -0.04483406990766525,
      0.027510741725564003,
      -0.03843182697892189,
      0.008561380207538605,
      0.01971520110964775,
      0.0026571236085146666,
      0.017736032605171204,
      0.014037144370377064,
      -0.04851747676730156,
      -0.03815030679106712,
      0.05487417057156563,
      0.07132981717586517,
      0.07357663661241531,
      -0.02819511853158474,
      -0.010396970435976982,
      0.06348462402820587,
      -0.02363545075058937,
      -0.029879417270421982,
      0.022501148283481598,
      0.0992535725235939,
      0.02268936298787594,
      0.026272332295775414,
      0.003766018431633711,
      -0.03251399099826813,
      0.04836323857307434,
      -0.007406023796647787,
      -0.03014339879155159,
      0.060083381831645966,
      -0.0021003729198127985,
      0.030594272539019585,
      -0.008580557070672512,
      0.012756348587572575,
      -0.057233136147260666,
      -0.0831431895494461,
      -0.02646748535335064,
      0.06176785007119179,
      0.050264377146959305,
      0.04382087290287018,
      0.0476217120885849,
      0.029018670320510864,
      0.08957123756408691,
      -0.06969612091779709,
      -0.025307269766926765,
      0.017975585535168648,
      -0.026821698993444443,
      -0.043216146528720856,
      -0.051848381757736206,
      -0.010581969283521175,
      -0.06142154335975647,
      -0.13280096650123596,
      0.038570936769247055,
      0.011347892694175243,
      -0.02673489972949028,
      -0.08877451717853546,
      0.05965302139520645,
      0.03747004270553589,
      -0.06826404482126236,
      0.01276522409170866,
      0.019652049988508224,
      -0.023965541273355484,
      -0.008534036576747894,
      0.027700159698724747,
      0.015182623639702797,
      0.09234797209501266,
      0.06884824484586716,
      -0.030835401266813278,
      0.028399357572197914,
      -0.06112329289317131,
      -0.0022113234736025333,
      0.006071136798709631,
      0.03418603911995888,
      0.026985036209225655,
      0.0039414213970303535,
      0.0286246370524168,
      -0.013989431783556938,
      -0.01750781014561653,
      -0.05386282876133919,
      0.0010088428389281034,
      -0.012235771864652634,
      -0.042392123490571976,
      0.0414569266140461,
      -0.0692150890827179,
      0.041991833597421646,
      0.011254366487264633,
      -0.04252716526389122,
      -0.0026420855429023504,
      -0.02303769625723362,
      -0.0071997265331447124,
      0.06037941947579384,
      -0.06662556529045105,
      0.039698392152786255,
      0.0043580662459135056,
      -0.019293081015348434,
      -0.05286000669002533,
      -0.010236396454274654,
      -0.025777624920010567,
      0.004278790205717087,
      -0.00016466061060782522,
      -0.0017613471718505025,
      0.017846178263425827,
      -0.04802896827459335,
      0.02751883864402771,
      -0.06217029318213463,
      -0.018968448042869568,
      -0.03677761182188988,
      0.06220153346657753,
      0.005869777407497168,
      0.06876842677593231,
      -0.007030270993709564,
      -0.01626584492623806,
      -0.00033786476706154644,
      0.015026696026325226,
      0.014218023046851158,
      0.04011557996273041,
      0.06831841170787811,
      -0.04058687388896942,
      -0.018257929012179375,
      -0.0034339800477027893,
      0.04596107080578804,
      0.006553393788635731,
      -0.09437937289476395,
      0.04645051434636116,
      0.06565771996974945,
      -0.024242069572210312,
      -0.017763590440154076,
      -0.007821961306035519,
      -0.00796249508857727,
      -0.057606447488069534,
      0.006287298630923033,
      0.0556689128279686,
      -0.021236730739474297,
      0.007724040187895298,
      -0.010971223935484886,
      0.021262744441628456,
      0.09159701317548752,
      0.05635567009449005,
      -0.03250696137547493,
      0.056024037301540375,
      0.06493709981441498,
      -0.02130168117582798,
      -0.06184908375144005,
      -0.04445792734622955,
      -0.01869012787938118,
      0.013426817953586578,
      -0.00982941035181284,
      -0.011129695922136307,
      -0.03938519209623337,
      0.035521846264600754,
      -0.02733154036104679,
      0.0017906365683302283,
      0.03927755355834961,
      0.007703146431595087,
      -0.04033832252025604,
      0.038644734770059586,
      0.06469744443893433,
      -0.02479436993598938,
      -0.008832217194139957,
      -0.011718730442225933,
      -0.10528255999088287,
      -0.019796030595898628,
      -0.06741511076688766,
      0.04687238857150078,
      -0.0604521669447422,
      -0.02568807080388069,
      0.009414932690560818,
      0.02758411131799221,
      0.004445833619683981,
      0.013712734915316105,
      -0.031210340559482574,
      -0.0014449984300881624,
      0.032473597675561905,
      0.019369596615433693,
      0.03166350722312927,
      -0.055577170103788376,
      0.01132658775895834,
      -0.0022731241770088673,
      0.07167462259531021,
      0.010505888611078262,
      -0.10305336862802505,
      -0.03381973132491112,
      -0.05845751240849495,
      -0.01088375598192215,
      0.01741703785955906,
      -0.039070870727300644,
      0.005093615967780352,
      -0.032175954431295395,
      -0.013584092259407043,
      0.004022715613245964,
      -0.043973274528980255,
      0.03882480040192604,
      0.004621788393706083,
      -0.0009776424849405885,
      -0.0023062790278345346,
      0.0052846441976726055,
      -0.018039079383015633,
      0.03402752801775932,
      0.010131791234016418,
      -0.038347888737916946,
      0.03157969191670418,
      -0.01890004798769951,
      0.013995938934385777,
      -0.03138882666826248,
      -0.022025782614946365,
      0.013243039138615131,
      -0.03890111297369003,
      0.10489121079444885,
      0.00032607861794531345,
      0.01988827809691429,
      -0.04873369634151459,
      -0.021444648504257202,
      -0.06105886027216911,
      0.037324704229831696,
      -0.023430442437529564,
      -0.022307273000478745,
      0.08189914375543594,
      0.04679201915860176,
      -0.04095719754695892,
      -0.01733817532658577,
      0.04417043924331665,
      -0.012318134307861328,
      -0.016022654250264168,
      0.004030832555145025,
      -0.0881844237446785,
      -0.007497899234294891,
      -0.06910864263772964,
      0.04799704626202583,
      -0.012651043944060802,
      0.012799089774489403,
      0.010578149929642677,
      0.025917204096913338,
      -0.05432356894016266,
      0.04493580013513565,
      0.07945363223552704,
      -0.028748853132128716,
      -0.015803596004843712,
      -0.06582680344581604,
      0.01774086430668831,
      -0.00013771878730040044,
      -0.010480080731213093,
      -0.07948558032512665,
      -0.01774176023900509,
      -0.006729654967784882,
      -0.02384723164141178,
      0.022996272891759872,
      -0.00410460727289319,
      0.016713349148631096,
      -0.031149812042713165,
      -0.08663025498390198,
      -0.07211608439683914,
      0.011079835705459118,
      0.011835355311632156,
      0.03757345303893089,
      -0.07421217113733292,
      0.046822305768728256,
      -0.07579454034566879,
      -0.021684227511286736,
      -0.049637120217084885,
      -0.027851847931742668,
      0.013007411733269691,
      0.02446022816002369,
      -0.04155067354440689,
      -0.003938223235309124,
      -0.053411148488521576,
      0.0027706294786185026,
      0.00046547004603780806,
      -0.03196730092167854,
      -0.049301132559776306,
      0.02842070162296295,
      0.01649872586131096,
      0.01127745769917965,
      -0.022577160969376564,
      0.030837293714284897,
      -0.05712662264704704,
      0.01322703342884779,
      -0.05807290971279144,
      0.003067729063332081,
      0.03413255512714386,
      -0.05438970774412155,
      -0.12096437066793442,
      -0.01065177470445633,
      0.06168660148978233
    ],
    [
      -0.037626683712005615,
      -2.3172340661403723e-05,
      0.01911436952650547,
      0.00572572648525238,
      -0.06015501543879509,
      0.031277645379304886,
      0.11445563286542892,
      0.04566493630409241,
      0.01213093288242817,
      0.09637969732284546,
      0.0010678555117920041,
      0.05548755079507828,
      -0.014833381399512291,
      -0.06246814504265785,
      -0.005898280069231987,
      -0.0678744986653328,
      0.06357681006193161,
      0.014541695825755596,
      -0.011785155162215233,
      -0.013660889118909836,
      0.051285695284605026,
      0.010493719018995762,
      -0.02131032571196556,
      -0.002419882221147418,
      0.018883224576711655,
      -0.019255179911851883,
      -0.057133376598358154,
      -0.0477624237537384,
      -0.03702932223677635,
      0.025675807148218155,
      0.024412760511040688,
      -0.01537870429456234,
      0.056945547461509705,
      -0.03739829733967781,
      0.02216418646275997,
      -0.01508006826043129,
      0.029049444943666458,
      0.009480301290750504,
      0.03516906872391701,
      -0.013620011508464813,
      -0.004790320992469788,
      0.001154139288701117,
      0.0016308060148730874,
      -0.01782922074198723,
      -0.11106052249670029,
      -0.01790393702685833,
      -0.07392322272062302,
      0.0677359476685524,
      0.00800345093011856,
      0.008100122213363647,
      0.042800746858119965,
      -0.05599316582083702,
      -0.030608413740992546,
      -0.039723463356494904,
      -0.04718991369009018,
      -0.01848248764872551,
      -0.01984676718711853,
      -0.02868509851396084,
      0.02051563560962677,
      0.03547235578298569,
      0.0565667450428009,
      0.010136748664081097,
      0.027247926220297813,
      0.007260763086378574,
      0.03325687721371651,
      -0.037146538496017456,
      0.0016555996844545007,
      -0.05065777525305748,
      -0.0318027064204216,
      0.013407929800450802,
      0.006240570452064276,
      0.06503187865018845,
      -0.061846014112234116,
      0.014063888229429722,
      0.0696830004453659,
      -0.03780891373753548,
      -0.03073963150382042,
      0.017036212608218193,
      -0.0015405750600621104,
      0.022836929187178612,
      -0.0442357212305069,
      -0.041766032576560974,
      -0.008519412018358707,
      -0.005047006532549858,
      0.05650884658098221,
      -0.05518394336104393,
      -0.0022491109557449818,
      0.05987239256501198,
      -0.038025226444005966,
      -0.003149579046294093,
      -0.011784959584474564,
      0.05623957887291908,
      -0.014734910801053047,
      -0.002193706575781107,
      -0.022034116089344025,
      0.000744576333090663,
      0.012288770638406277,
      -0.009050117805600166,
      0.009505772963166237,
      0.04401485621929169,
      -0.03301665559411049,
      -0.003936692140996456,
      0.024178827181458473,
      -0.05744631960988045,
      0.08329948037862778,
      -0.02650655247271061,
      0.03928462043404579,
      -0.042029548436403275,
      0.04650762677192688,
      0.033463429659605026,
      0.0364423505961895,
      0.008027330040931702,
      -0.014672297053039074,
      0.037004511803388596,
      -0.051255498081445694,
      -0.009250360541045666,
      0.012794611044228077,
      -0.02428371086716652,
      -0.08945067971944809,
      -0.008383532054722309,
      0.11254601925611496,
      -0.015921004116535187,
      -0.0020676518324762583,
      -0.08525563776493073,
      -0.03204360604286194,
      0.010323315858840942,
      0.058045893907547,
      0.011517731472849846,
      -0.03876049071550369,
      0.07382053136825562,
      0.008204851299524307,
      0.06684964895248413,
      -0.060594454407691956,
      -0.047240834683179855,
      -0.0362749844789505,
      0.02116144821047783,
      -0.050766151398420334,
      0.027165770530700684,
      0.02132076397538185,
      0.06291045248508453,
      0.09506309032440186,
      0.03495965525507927,
      0.016471758484840393,
      -0.08752155303955078,
      -0.022379733622074127,
      -0.029508493840694427,
      0.02081863023340702,
      -0.10882824659347534,
      -0.009570451453328133,
      0.08716239780187607,
      -0.016764860600233078,
      -0.09008339792490005,
      -0.012889883480966091,
      0.016358284279704094,
      -0.03290728107094765,
      0.04335379600524902,
      -0.039673008024692535,
      -0.019152607768774033,
      0.003477531485259533,
      -0.05696674808859825,
      0.021830130368471146,
      0.035296980291604996,
      -0.010292171500623226,
      -0.011773688718676567,
      -0.015336104668676853,
      -0.024698229506611824,
      -0.058533333241939545,
      0.10632727295160294,
      0.058921799063682556,
      0.06487540155649185,
      -0.03770451992750168,
      0.01946084015071392,
      -0.04626910015940666,
      -0.0073981634341180325,
      -0.07861992716789246,
      -0.0821051374077797,
      0.021459544077515602,
      -0.014000499621033669,
      -0.030439959838986397,
      -0.0002665654756128788,
      0.0659235492348671,
      0.03754309564828873,
      -0.05636454373598099,
      -0.1023453027009964,
      0.05588337406516075,
      0.015652719885110855,
      0.053999535739421844,
      -0.02173941768705845,
      0.0021872061770409346,
      -0.03530726581811905,
      0.008570453152060509,
      -0.007999900728464127,
      0.038719095289707184,
      -0.1427062600851059,
      0.027156000956892967,
      -0.019183548167347908,
      -0.006166988983750343,
      0.014532320201396942,
      -0.0078069670125842094,
      0.08715976029634476,
      0.019618313759565353,
      0.023887040093541145,
      -0.057652078568935394,
      0.012614278122782707,
      -0.03978804871439934,
      7.111455488484353e-05,
      -0.035100068897008896,
      0.0448041707277298,
      -0.013097847811877728,
      0.06141406297683716,
      -0.01329600065946579,
      0.03607579320669174,
      -0.021934092044830322,
      0.054008305072784424,
      0.0742911770939827,
      -0.0768655464053154,
      0.03826944902539253,
      -0.03620748594403267,
      0.00659592030569911,
      0.04229870066046715,
      0.0352017842233181,
      0.04597730562090874,
      -0.027561096474528313,
      0.008860738016664982,
      0.1418338567018509,
      0.06948685646057129,
      0.0652962476015091,
      0.02992754988372326,
      -0.032454587519168854,
      -0.01230399776250124,
      0.007444711867719889,
      -0.036328304558992386,
      -0.009448494762182236,
      0.04340032860636711,
      0.040213704109191895,
      0.06693633645772934,
      -0.018174825236201286,
      -0.025992466136813164,
      0.04320279136300087,
      0.0012745796702802181,
      0.051172591745853424,
      0.012343969196081161,
      0.06816067546606064,
      -0.01958710141479969,
      -0.05143158882856369,
      -0.0412977859377861,
      -0.06931168586015701,
      -0.004706611856818199,
      0.037439096719026566,
      0.03224506974220276,
      0.02011043205857277,
      0.10668870806694031,
      -0.09522505849599838,
      -0.0017408059211447835,
      -0.0601692758500576,
      0.062329601496458054,
      -0.013197359628975391,
      -0.02889065630733967,
      -0.062029074877500534,
      -0.02845725603401661,
      -0.0792337954044342,
      -0.0594371035695076,
      -0.026371682062745094,
      -0.013863453641533852,
      -0.0011854443000629544,
      -0.04426850005984306,
      -0.0076939561404287815,
      -0.011646661907434464,
      0.017740869894623756,
      -0.05017049238085747,
      -0.04536362737417221,
      0.04298922047019005,
      0.026842283084988594,
      -0.0008544914308004081,
      0.04139631614089012,
      0.01966550201177597,
      0.048971276730298996,
      0.029026461765170097,
      -0.05339738354086876,
      0.0033045271411538124,
      -0.049286775290966034,
      -0.010820946656167507,
      -0.02447122521698475,
      0.02921692468225956,
      -0.05430049076676369,
      0.026073403656482697,
      0.044233646243810654,
      0.004819951951503754,
      -0.027996664866805077,
      -0.09119700640439987,
      -0.08508289605379105,
      -0.008584433235228062,
      0.04224821552634239,
      0.05405319482088089,
      0.020139357075095177,
      0.0402618832886219,
      -0.0162492748349905,
      0.04259173944592476,
      0.018894491717219353,
      0.007531062699854374,
      -0.017254281789064407,
      0.0077420552261173725,
      0.06115884333848953,
      -0.03344535827636719,
      -0.0016474387375637889,
      0.05807340890169144,
      -0.00726085202768445,
      0.0678725615143776,
      -0.09636740386486053,
      -0.06251510977745056,
      -0.06238261237740517,
      0.0036159739829599857,
      0.06551965326070786,
      -0.03167186304926872,
      0.02613866701722145,
      0.03950665146112442,
      0.045613206923007965,
      0.043172579258680344,
      0.023939214646816254,
      -0.0042767333798110485,
      0.06186758354306221,
      0.0071801673620939255,
      0.030040564015507698,
      0.04850292205810547,
      0.018705112859606743,
      0.07361321896314621,
      -0.05896620824933052,
      -0.04317512735724449,
      0.010846703313291073,
      0.005149907432496548,
      -0.02249952033162117,
      0.016981279477477074,
      0.05703479424118996,
      -0.009583879262208939,
      -0.021137231960892677,
      -0.02270863577723503,
      0.07774903625249863,
      -0.0015740070957690477,
      0.03470585122704506,
      0.053332943469285965,
      -0.003290200373157859,
      0.04254324361681938,
      -0.009857231751084328,
      0.015024244785308838,
      0.08128473162651062,
      0.10285232216119766,
      0.011816692538559437,
      0.04248668998479843,
      0.042970262467861176,
      -0.003561086719855666,
      -0.00640622153878212,
      0.08320950716733932,
      -0.0007155014900490642,
      -0.044770821928977966,
      -0.008165093138813972,
      0.02714991569519043,
      0.0415889210999012,
      -0.02904857136309147,
      -0.09432311356067657,
      0.0443984791636467,
      -0.018090015277266502,
      -0.003140532411634922,
      -0.06665477901697159,
      -0.047727130353450775,
      0.023046785965561867,
      -0.10325711220502853,
      0.047420356422662735,
      -0.04415972903370857,
      -0.013140769675374031,
      0.02717394009232521,
      0.0871451273560524,
      0.06202126294374466,
      0.051291778683662415,
      -0.006496374495327473,
      -0.023501738905906677,
      -0.09433766454458237,
      0.014285336248576641,
      0.07350882887840271,
      -0.04057961329817772,
      -0.03031398542225361,
      0.027014827355742455,
      -0.03884095326066017,
      0.05672108381986618,
      0.030503885820508003,
      0.06081865355372429,
      -0.036177027970552444,
      0.013680522330105305,
      -0.0165411289781332,
      0.0745934322476387,
      0.025171827524900436,
      0.010749682784080505,
      0.0114124221727252,
      -0.11994245648384094,
      -0.016504747793078423,
      -0.01918889954686165,
      -0.03929142281413078,
      -0.016322311013936996,
      0.046546533703804016,
      -0.01048792339861393,
      -0.041952576488256454,
      -0.037425167858600616,
      0.07510364055633545,
      -0.021561263129115105,
      0.025288071483373642,
      0.006322330795228481,
      0.023355599492788315,
      -0.0185568705201149,
      -0.1073765754699707,
      0.05263600870966911,
      0.052745431661605835,
      -0.0674341470003128,
      -0.01844898797571659,
      -0.025759942829608917,
      0.014632136560976505,
      -0.05973219498991966,
      0.036830224096775055,
      0.04412689432501793,
      0.009847073815762997,
      -0.06318514049053192,
      0.05462528020143509,
      -0.06107182800769806,
      -0.024836676195263863,
      0.029067082330584526,
      0.0505543015897274,
      -0.059756871312856674,
      -0.03164922446012497,
      0.037354547530412674,
      0.017691530287265778,
      -0.034267328679561615,
      -0.03074480965733528,
      0.018668705597519875,
      0.07200629264116287,
      -0.028971485793590546,
      0.03362342715263367,
      -0.06349772214889526,
      0.04905952140688896,
      -0.005602037999778986,
      -0.002118159318342805,
      0.006267044693231583,
      0.0043138605542480946,
      0.0010609442833811045,
      0.031031226739287376,
      0.0010224591242149472,
      -0.028323063626885414,
      -0.06643858551979065,
      0.05894489586353302,
      -0.014963372610509396,
      -0.01686250790953636,
      0.016395794227719307,
      -0.039371248334646225,
      0.00885799340903759,
      0.060654591768980026,
      0.026871586218476295,
      -0.004040993750095367,
      0.004897568840533495,
      -0.00758003257215023,
      -0.04215371608734131,
      -0.07640080899000168,
      0.14118966460227966,
      0.025030672550201416,
      0.010702044703066349,
      -0.020670132711529732,
      -0.021123746410012245,
      0.04418419674038887,
      0.025884045287966728,
      -0.034170083701610565,
      0.04175901040434837,
      0.005629238206893206,
      -0.0172080360352993,
      0.0634237751364708,
      0.04907163977622986,
      -0.08817356824874878,
      -0.09177256375551224,
      0.008481060154736042,
      0.08531764894723892,
      0.02915167436003685,
      -0.008672782219946384,
      -0.00041327913640998304,
      -0.046211425215005875,
      -0.041043758392333984,
      0.0015983787598088384,
      -0.019476691260933876,
      -0.007846580818295479,
      0.003814849304035306,
      0.04845886677503586,
      0.0767306461930275,
      -0.049138788133859634,
      0.001315463799983263,
      -0.0060022491961717606,
      0.022910352796316147,
      0.04549868032336235,
      -0.03005017153918743,
      -0.005690480582416058,
      -0.05031523108482361,
      -0.010020404122769833,
      -0.01729111559689045,
      1.171316580439452e-05,
      -0.03355007991194725,
      0.007583710364997387,
      -0.01306361798197031,
      -0.0532524473965168,
      -0.06382539123296738,
      0.08541034907102585
    ],
    [
      0.07859742641448975,
      -0.009490960277616978,
      -0.031892940402030945,
      0.045545317232608795,
      -0.010552095249295235,
      -0.027321554720401764,
      0.0629739835858345,
      -0.08186927437782288,
      0.06999985873699188,
      -0.0247038621455431,
      -0.013196805492043495,
      -0.07438531517982483,
      0.010336442850530148,
      -0.038915909826755524,
      -0.012596615590155125,
      -0.005572427064180374,
      0.023866526782512665,
      0.017181746661663055,
      -0.04619443044066429,
      -0.027861930429935455,
      0.032854534685611725,
      0.05337880551815033,
      0.03622601553797722,
      0.04613182693719864,
      -0.08075236529111862,
      -0.018010614439845085,
      0.0033183731138706207,
      -0.029451586306095123,
      -0.015804773196578026,
      0.04899947717785835,
      -0.035283952951431274,
      -0.007080921437591314,
      0.00025960992206819355,
      -0.05358382686972618,
      0.06490439921617508,
      0.023732494562864304,
      -0.05471893772482872,
      0.04999120905995369,
      -0.04612581059336662,
      -0.010526169091463089,
      0.06256943941116333,
      -0.040053050965070724,
      0.04118717834353447,
      -0.08686931431293488,
      -0.04286457970738411,
      -0.04801786318421364,
      -0.005897263530641794,
      -0.007074843626469374,
      0.06067327782511711,
      0.04458211362361908,
      -0.08711375296115875,
      -0.014874054118990898,
      -0.009982186369597912,
      -0.019141394644975662,
      -0.011837580241262913,
      0.009221303276717663,
      0.04073642939329147,
      -0.04071052744984627,
      -0.002744923811405897,
      0.023864809423685074,
      -0.005661127623170614,
      -0.025925759226083755,
      -0.022325729951262474,
      0.03554410859942436,
      -0.048675376921892166,
      0.007622777484357357,
      -0.00127114646602422,
      -0.0753261148929596,
      -0.03462597355246544,
      -0.04540364071726799,
      0.007150385994464159,
      -0.024385126307606697,
      0.007837064564228058,
      -0.0700889304280281,
      0.0023281220346689224,
      -0.08139542490243912,
      0.005042731761932373,
      -0.005262269638478756,
      -0.01116983126848936,
      -0.029474454000592232,
      0.05794982239603996,
      0.01788157969713211,
      -0.07150180637836456,
      5.620975207420997e-05,
      0.03507699444890022,
      -0.0210768710821867,
      0.053002312779426575,
      0.029915878549218178,
      -0.011047880165278912,
      -0.004659546539187431,
      0.048484209924936295,
      -0.01039830967783928,
      0.004326461348682642,
      0.01942392997443676,
      0.015797646716237068,
      -0.041180819272994995,
      0.054274700582027435,
      0.03642011433839798,
      -0.008638456463813782,
      -0.016858268529176712,
      -0.06931208819150925,
      0.028620576485991478,
      0.06997618079185486,
      -0.08146845549345016,
      0.017779365181922913,
      -0.07843241095542908,
      -0.011625071056187153,
      -0.016080116853117943,
      0.04334687814116478,
      0.07307565957307816,
      -0.020442919805645943,
      0.04393930360674858,
      0.00791967660188675,
      -0.006566049996763468,
      -0.008161509409546852,
      0.03498155623674393,
      -0.0315123125910759,
      0.01742103509604931,
      0.05979115515947342,
      0.011030628345906734,
      0.002906288718804717,
      0.010348277166485786,
      0.011463554576039314,
      -0.013656659051775932,
      -0.006938952021300793,
      0.053964558988809586,
      -0.06946011632680893,
      -0.0018646775279194117,
      -0.041011836379766464,
      0.10550745576620102,
      0.06877447664737701,
      0.0012709369184449315,
      0.05833271145820618,
      -0.04555884376168251,
      -0.03271030634641647,
      -0.01073506847023964,
      -0.01380607858300209,
      0.02649236097931862,
      0.0033867466263473034,
      -0.03980982303619385,
      -0.028998367488384247,
      -0.05278968811035156,
      -0.0906258225440979,
      -0.07273438572883606,
      -0.03213285654783249,
      0.02131485752761364,
      0.07709518074989319,
      -0.022495098412036896,
      -0.1023126170039177,
      0.010018094442784786,
      0.0146303940564394,
      -0.0651569664478302,
      -0.007535049691796303,
      0.08528310060501099,
      0.06014053151011467,
      0.01311376690864563,
      -0.004798272158950567,
      0.04471705108880997,
      -0.011795012280344963,
      -0.03795906901359558,
      0.03475771099328995,
      -0.019641917198896408,
      0.02685561217367649,
      -0.044991400092840195,
      0.03833944350481033,
      -0.04658377915620804,
      0.04959656298160553,
      0.015590708702802658,
      -0.009828009642660618,
      -0.008305401541292667,
      0.03688909113407135,
      0.07160775363445282,
      -0.044497255235910416,
      0.05987915024161339,
      0.000815815816167742,
      -0.05499991774559021,
      -0.04542526975274086,
      0.02655358426272869,
      0.009998721070587635,
      -0.01906997710466385,
      -0.08276504278182983,
      -0.017441796138882637,
      -0.041617270559072495,
      0.00962645374238491,
      0.028817929327487946,
      0.019157588481903076,
      -0.019007094204425812,
      -0.006668928544968367,
      -0.045240629464387894,
      0.02289915271103382,
      -0.0849083662033081,
      -0.049415089190006256,
      -0.0459328331053257,
      0.05452166870236397,
      -0.05379217863082886,
      0.059188924729824066,
      0.03091862052679062,
      0.05049159750342369,
      0.0025407075881958008,
      0.13331642746925354,
      -0.0034775827080011368,
      -0.03022228181362152,
      -0.01812361367046833,
      -0.06425920128822327,
      -0.01276831142604351,
      0.00904836319386959,
      -0.01301740575581789,
      0.015766536816954613,
      -0.013055771589279175,
      -0.030493231490254402,
      0.06032651290297508,
      0.0700470358133316,
      0.001995521830394864,
      0.08207223564386368,
      0.006623747758567333,
      -0.031091105192899704,
      -0.04328754544258118,
      -0.0012531119864434004,
      0.022437583655118942,
      0.0601758249104023,
      -0.038546133786439896,
      -0.025882624089717865,
      -0.10028120875358582,
      0.038937900215387344,
      0.020946620032191277,
      0.0023113563656806946,
      0.07399307936429977,
      -0.027008168399333954,
      -0.011924642138183117,
      -0.07257400453090668,
      -0.0971229150891304,
      0.07669779658317566,
      -0.012991665862500668,
      -0.032998889684677124,
      -0.017982453107833862,
      -0.04710520803928375,
      0.06521149724721909,
      -0.042625542730093,
      0.012992779724299908,
      -0.009615564718842506,
      0.011637142859399319,
      -0.0063848854042589664,
      0.03639762103557587,
      0.13015124201774597,
      -0.04658020660281181,
      0.05204293876886368,
      -0.05546034872531891,
      -0.02631847746670246,
      -0.05251719057559967,
      0.048244405537843704,
      0.03266986459493637,
      0.04602837190032005,
      -0.03511780872941017,
      0.04497223719954491,
      -0.015120160765945911,
      -0.007972151972353458,
      0.05771784111857414,
      -0.0324048288166523,
      -0.09472393244504929,
      -0.004984202329069376,
      -0.06452758610248566,
      0.0354623943567276,
      -0.032778482884168625,
      -0.010543541982769966,
      -0.0077096447348594666,
      -0.048367805778980255,
      -0.0011483971029520035,
      0.013083208352327347,
      -0.030881455168128014,
      -0.03236101567745209,
      0.01765144057571888,
      0.0350506491959095,
      0.07407135516405106,
      0.014024940319359303,
      -0.010041647590696812,
      -0.02750692516565323,
      -0.006658936385065317,
      0.04715350270271301,
      0.003234359435737133,
      -0.008365883491933346,
      -0.03311920911073685,
      0.039171893149614334,
      0.05529558286070824,
      -0.022435354068875313,
      -0.011068262159824371,
      -0.0012680253712460399,
      -0.027343999594449997,
      -0.060626644641160965,
      -0.05254444107413292,
      -0.1132919117808342,
      -0.006579243112355471,
      0.054308198392391205,
      0.002894938224926591,
      -0.06613276153802872,
      0.0023085547145456076,
      0.009663528762757778,
      0.016430746763944626,
      -0.14639388024806976,
      -0.040359679609537125,
      -0.06246308609843254,
      0.011475837789475918,
      0.03667765483260155,
      -0.05222683027386665,
      0.03704126179218292,
      0.054996877908706665,
      -0.08343418687582016,
      0.09512811154127121,
      -0.0639532282948494,
      -0.03451289236545563,
      -0.0601826086640358,
      0.12796816229820251,
      0.028486162424087524,
      0.02283266931772232,
      0.04465053975582123,
      -0.0952102541923523,
      0.038488201797008514,
      -0.02298293448984623,
      0.03851419687271118,
      0.03517506644129753,
      -0.06904775649309158,
      0.040147487074136734,
      -0.021831778809428215,
      0.024658342823386192,
      0.02983514592051506,
      -0.04488622769713402,
      0.05247378349304199,
      0.00843648612499237,
      0.00493819871917367,
      0.00692317821085453,
      0.013056728057563305,
      -0.040704160928726196,
      -0.0196122657507658,
      0.03211001679301262,
      -0.03612799197435379,
      -0.0815138965845108,
      -0.04645693302154541,
      -0.05782964453101158,
      0.04698507860302925,
      0.04800757020711899,
      -0.009773806668817997,
      -0.023576803505420685,
      -0.002508024452254176,
      0.04674500226974487,
      0.031246434897184372,
      -0.043509289622306824,
      0.018881196156144142,
      0.06400128453969955,
      -0.009626326151192188,
      0.008439775556325912,
      0.06786136329174042,
      0.009660536423325539,
      -0.046341802924871445,
      0.032159723341464996,
      -0.035393502563238144,
      0.09687602519989014,
      -0.009646302089095116,
      0.04101545736193657,
      0.026183554902672768,
      -0.09670735150575638,
      -0.012160719372332096,
      0.06694192439317703,
      -0.04898988455533981,
      -0.01250001322478056,
      -0.056683752685785294,
      0.007109296508133411,
      0.04709375649690628,
      -0.02042628638446331,
      -0.0933927521109581,
      0.05452378839254379,
      0.029022514820098877,
      0.015806110575795174,
      -0.017918536439538002,
      -0.022879455238580704,
      0.05081941932439804,
      0.0383429117500782,
      0.0018541474128142,
      0.0033662503119558096,
      -0.02387905679643154,
      -0.01927567645907402,
      -0.017806004732847214,
      -0.055872026830911636,
      -0.07359668612480164,
      -0.0022059036418795586,
      -0.0835280790925026,
      0.05907734856009483,
      -0.01800026185810566,
      0.02770208567380905,
      -0.03171234950423241,
      0.03909820690751076,
      -0.0025086579844355583,
      0.03713829815387726,
      -0.028421683236956596,
      0.047116395086050034,
      0.09230317175388336,
      -0.029388267546892166,
      0.017888642847537994,
      -0.00414319010451436,
      0.055801402777433395,
      -0.020094016566872597,
      0.008199608884751797,
      -0.07082825154066086,
      -0.007910991087555885,
      -0.018187133595347404,
      -0.0370354987680912,
      0.01679922081530094,
      -0.014757317490875721,
      -0.06127327308058739,
      0.005333575885742903,
      -0.04103534296154976,
      0.04993182420730591,
      0.05803654342889786,
      -0.02742900513112545,
      -0.05552224814891815,
      -0.048030320554971695,
      0.03830769658088684,
      0.021346550434827805,
      -0.05494878441095352,
      0.035534657537937164,
      0.03392622992396355,
      -0.04652151092886925,
      -0.0293931532651186,
      -0.08385222405195236,
      0.05217662453651428,
      0.02482561767101288,
      -0.024685928598046303,
      -0.1020452231168747,
      0.04695197567343712,
      0.010767345316708088,
      0.08195580542087555,
      -0.01439837645739317,
      -0.01183260791003704,
      -0.019134292379021645,
      -0.07662945240736008,
      0.03838811069726944,
      -0.0004706473555415869,
      -0.018354112282395363,
      0.03040643408894539,
      0.08799659460783005,
      -0.03306105360388756,
      0.04848451539874077,
      -0.02261069230735302,
      0.03463098779320717,
      0.0396718829870224,
      -0.009096941910684109,
      -0.02941499464213848,
      -0.03711088374257088,
      -0.012364930473268032,
      0.06636787205934525,
      0.07816540449857712,
      -0.12311219424009323,
      -0.09688294678926468,
      0.10753517597913742,
      0.055665161460638046,
      0.04625588655471802,
      -0.04224599152803421,
      -0.09417856484651566,
      0.005600479431450367,
      0.007665000390261412,
      -0.0004508205165620893,
      0.034224383533000946,
      -0.0009268533904105425,
      -0.08392921090126038,
      0.03869928419589996,
      0.030898338183760643,
      0.01753051020205021,
      -0.02781681902706623,
      0.010064675472676754,
      0.02724764123558998,
      0.057467084378004074,
      0.006490703672170639,
      0.06694169342517853,
      -0.0403585210442543,
      -0.021049322560429573,
      -0.00022405220079235733,
      -0.00196289480663836,
      0.04388301074504852,
      -0.003637150628492236,
      0.027260346338152885,
      0.006369949784129858,
      -0.00404800521209836,
      0.012173451483249664,
      -0.017212385311722755,
      -0.11790549010038376,
      -0.08007902652025223,
      0.03582599014043808,
      0.036467742174863815,
      -0.04060682654380798,
      -0.016904613003134727,
      -0.0013163672992959619,
      0.04103497415781021,
      0.0693073570728302,
      -0.07430599629878998,
      0.027430886402726173,
      0.006926697213202715,
      0.06871063262224197,
      0.048726264387369156,
      0.0067446487955749035,
      -0.04445543885231018,
      -0.005811647046357393,
      0.013720789924263954,
      -0.019865816459059715,
      0.06649424135684967,
      0.08519347012042999
    ],
    [
      0.01867557130753994,
      -0.057863958179950714,
      0.0235267486423254,
      0.007883965969085693,
      -0.044849831610918045,
      0.03675692155957222,
      0.042853329330682755,
      -0.027969134971499443,
      0.01611305959522724,
      -0.0988655611872673,
      0.07331407815217972,
      -0.03087804466485977,
      0.060810089111328125,
      -0.06651876121759415,
      -0.022278353571891785,
      -0.0010785438353195786,
      0.04166298359632492,
      -0.026729604229331017,
      -0.01649748533964157,
      0.004465787205845118,
      0.0042192088440060616,
      0.017236657440662384,
      0.01145663671195507,
      0.030828427523374557,
      0.1242925152182579,
      -0.03100871853530407,
      -0.012814482674002647,
      0.029083432629704475,
      -0.0956014022231102,
      0.025246432051062584,
      0.058162715286016464,
      -0.012068620882928371,
      0.11085064709186554,
      -0.061654120683670044,
      -0.10222288221120834,
      0.038080815225839615,
      0.055371250957250595,
      0.003735244506970048,
      0.008079996332526207,
      -0.06774017959833145,
      0.010679039172828197,
      -0.006287314463406801,
      -0.020058784633874893,
      -0.06526777148246765,
      -0.0736166462302208,
      -0.023838866502046585,
      0.025147104635834694,
      0.03953344002366066,
      -0.02863321825861931,
      0.06519678235054016,
      0.03103264421224594,
      -0.0013521903892979026,
      -0.035370927304029465,
      0.01124087255448103,
      -0.03839951381087303,
      -0.01674104668200016,
      -0.05976138636469841,
      -0.05468440055847168,
      0.029131941497325897,
      -0.017203234136104584,
      0.029426366090774536,
      -0.02649887464940548,
      -0.031007831916213036,
      -0.017619367688894272,
      0.024959947913885117,
      -0.04580724239349365,
      0.025617480278015137,
      0.002341859508305788,
      -0.03359066694974899,
      0.01641961745917797,
      0.021334489807486534,
      0.01263269130140543,
      -0.09940925985574722,
      -0.054726678878068924,
      -0.007746582385152578,
      0.006418285891413689,
      0.0051163481548428535,
      0.10169444233179092,
      -0.03724958747625351,
      0.052067361772060394,
      -0.02350522018969059,
      -0.005524358246475458,
      -0.049971990287303925,
      -0.12067623436450958,
      0.011684758588671684,
      0.03151553124189377,
      -0.07559008151292801,
      0.1264091432094574,
      -0.018758181482553482,
      -0.021787092089653015,
      0.017603851854801178,
      0.06243254616856575,
      -0.0298833716660738,
      0.0638890340924263,
      0.009559961967170238,
      0.012364938855171204,
      -0.024725405499339104,
      -0.0298018679022789,
      -0.012830954045057297,
      0.015049857087433338,
      0.026693157851696014,
      0.0022591608576476574,
      0.04636130481958389,
      0.026602745056152344,
      -0.04120118170976639,
      -0.09818356484174728,
      -0.02375294454395771,
      0.05248154699802399,
      0.06394252181053162,
      -0.0075726015493273735,
      -0.07528594881296158,
      0.019193103536963463,
      0.011356242001056671,
      0.07726489007472992,
      -0.008963347412645817,
      0.038017578423023224,
      0.00882790144532919,
      0.007850020192563534,
      -0.009893875569105148,
      0.005916965659707785,
      0.05232439190149307,
      0.04583757370710373,
      -0.05260747671127319,
      0.026385748758912086,
      -0.03605612367391586,
      -0.0400533527135849,
      0.00388589920476079,
      -0.024993382394313812,
      -0.04950517788529396,
      0.05409620702266693,
      -0.026252003386616707,
      -0.014445151202380657,
      0.010494540445506573,
      0.08254441618919373,
      0.05888885632157326,
      -0.031127620488405228,
      -0.017531786113977432,
      0.023112472146749496,
      0.0284210704267025,
      0.024093449115753174,
      0.010930579155683517,
      -0.08013840019702911,
      0.08775486052036285,
      -0.033594608306884766,
      -0.005295114126056433,
      -0.006940810941159725,
      0.046241674572229385,
      -0.00407416932284832,
      0.0992223247885704,
      0.02989213727414608,
      -0.004174146335572004,
      0.0702199637889862,
      -0.05232406035065651,
      0.019714727997779846,
      -0.02925880067050457,
      0.0013548154383897781,
      -0.020899763330817223,
      -0.031567588448524475,
      0.022612664848566055,
      0.011653785593807697,
      -0.03623330220580101,
      0.09721926599740982,
      0.03604741767048836,
      0.038387101143598557,
      0.026035286486148834,
      -0.015160136856138706,
      -0.025802964344620705,
      0.012787332758307457,
      0.11358390748500824,
      -0.050726521760225296,
      -0.004485907033085823,
      -0.0552201010286808,
      0.08055922389030457,
      -0.012819926254451275,
      0.013735346496105194,
      -0.037137825042009354,
      0.01644168235361576,
      0.05358332023024559,
      0.041742634028196335,
      0.03941556066274643,
      0.0561220683157444,
      -0.018664810806512833,
      -0.027045845985412598,
      -0.08595351129770279,
      0.007837753742933273,
      0.09246814996004105,
      -0.03050270862877369,
      0.008783034980297089,
      0.014863143675029278,
      0.04371580481529236,
      0.00925179198384285,
      0.021062571555376053,
      -0.0876726508140564,
      0.0023941125255078077,
      -0.04460462927818298,
      0.0008584713796153665,
      -0.005533858668059111,
      -0.04788702353835106,
      0.00024387847224716097,
      0.011327285319566727,
      -0.03867747634649277,
      0.005171378143131733,
      0.017645398154854774,
      -0.10188847780227661,
      0.043146032840013504,
      -0.0001486066321376711,
      -0.03200359642505646,
      0.03741758316755295,
      -0.0028021731413900852,
      0.010315504856407642,
      -0.0365685373544693,
      -0.012814190238714218,
      0.015524933114647865,
      0.012856632471084595,
      -0.009504015557467937,
      -0.03922837972640991,
      0.0071056904271245,
      0.012791791930794716,
      -0.006218312308192253,
      0.04109090194106102,
      0.04969125986099243,
      -0.0010370309464633465,
      0.03721243515610695,
      0.0010076564503833652,
      -0.11172530800104141,
      -0.05969753861427307,
      -0.05546388402581215,
      0.010661629028618336,
      0.07770021259784698,
      -0.003128448035567999,
      -0.02529502660036087,
      -0.0356433168053627,
      -0.07555647939443588,
      0.07481154054403305,
      -0.05638202279806137,
      0.007567154243588448,
      0.019992435351014137,
      0.03600902482867241,
      -0.02870340086519718,
      0.021241161972284317,
      0.05726921930909157,
      0.038926221430301666,
      -0.02274596504867077,
      0.03762735426425934,
      -0.07706033438444138,
      0.08566946536302567,
      -0.07705820351839066,
      0.061486974358558655,
      0.019076962023973465,
      0.03550414368510246,
      -0.017864234745502472,
      -0.002256755717098713,
      0.01727951690554619,
      0.018210502341389656,
      0.022605089470744133,
      0.03745633736252785,
      0.07838131487369537,
      -0.05690682679414749,
      0.003607294987887144,
      0.02576674520969391,
      -0.03672656789422035,
      -0.05784817412495613,
      0.0036547707859426737,
      -0.03931440785527229,
      -0.06103398650884628,
      -0.02609245479106903,
      -0.08109058439731598,
      -0.002388205612078309,
      -0.0006822514696978033,
      0.0404202826321125,
      -0.0040349834598600864,
      0.058200377970933914,
      -0.00042777115595526993,
      -0.04448637366294861,
      -0.015869777649641037,
      -0.02100110799074173,
      -0.04291307181119919,
      -0.06954333186149597,
      0.015005933120846748,
      0.024123109877109528,
      0.07665649056434631,
      0.021130699664354324,
      -0.07130581140518188,
      -0.014879452995955944,
      -0.019370680674910545,
      0.0654541552066803,
      0.0020262557081878185,
      0.014050238765776157,
      -0.022760098800063133,
      0.0453348308801651,
      -0.05475130304694176,
      -0.001629051985219121,
      0.014416922815144062,
      0.02970052883028984,
      -0.05593237653374672,
      0.04365522414445877,
      -0.035494767129421234,
      -0.049951426684856415,
      -0.0354728139936924,
      0.04464733228087425,
      -0.0818990096449852,
      -0.017237668856978416,
      -0.029228318482637405,
      -0.005865513347089291,
      -0.007383888121694326,
      -0.0008139795972965658,
      0.007417938206344843,
      0.051676783710718155,
      0.08022201806306839,
      0.008987977169454098,
      -0.008817859925329685,
      0.09352671355009079,
      -0.011951157823204994,
      -0.01324338000267744,
      -0.05694786086678505,
      -0.04055345430970192,
      0.107517771422863,
      -0.03614463284611702,
      -0.034138619899749756,
      -0.08521215617656708,
      0.018426885828375816,
      -0.007554234005510807,
      -0.03624890744686127,
      0.05076005682349205,
      -0.08514529466629028,
      -0.0415353924036026,
      -0.11309406161308289,
      -0.03358878195285797,
      -0.06572303175926208,
      -0.07086382806301117,
      0.00778708141297102,
      0.0509856678545475,
      -0.01160910539329052,
      -0.03363512083888054,
      0.022196603938937187,
      -0.07511572539806366,
      -0.0667826235294342,
      -0.0046138763427734375,
      0.09359267354011536,
      -0.019477108493447304,
      -0.02108117938041687,
      -0.043540194630622864,
      0.006719386205077171,
      0.08861201256513596,
      0.03738713636994362,
      0.015106440521776676,
      -0.044467538595199585,
      -0.0005509608890861273,
      0.016209950670599937,
      -0.011038759723305702,
      -0.065004363656044,
      -0.028141209855675697,
      -0.07557343691587448,
      -0.010731706395745277,
      0.06830550730228424,
      -0.023998450487852097,
      -0.0575052909553051,
      -0.11279580742120743,
      -0.04833639785647392,
      -0.02094249799847603,
      -0.022134244441986084,
      0.008870809338986874,
      -0.0030405193101614714,
      -0.02304898202419281,
      0.03318164497613907,
      -0.0655725747346878,
      -0.053302496671676636,
      0.03720390051603317,
      0.0037670552264899015,
      -0.0025502368807792664,
      -0.013890945352613926,
      -0.03967633843421936,
      0.01869707554578781,
      0.008681942708790302,
      0.04692130535840988,
      -0.03932580351829529,
      0.011468007229268551,
      0.0016228435561060905,
      0.053848013281822205,
      -0.005255931057035923,
      -0.005276007577776909,
      -0.016671575605869293,
      -0.009943858720362186,
      0.009335271082818508,
      -0.018002133816480637,
      -0.026174936443567276,
      -0.045789118856191635,
      0.020859483629465103,
      -0.02772524580359459,
      0.014788257889449596,
      0.06596552580595016,
      -0.05828484147787094,
      0.01949221082031727,
      -0.012115392833948135,
      -0.02720835618674755,
      0.025803912431001663,
      0.02682344801723957,
      -0.07145208865404129,
      -0.01997474953532219,
      0.00899367406964302,
      -0.08662015199661255,
      -0.057198770344257355,
      -0.02592315897345543,
      -0.01726999506354332,
      0.008602882735431194,
      0.015715839341282845,
      0.009084908291697502,
      -0.015024004504084587,
      0.023491812869906425,
      0.006171864457428455,
      0.052191559225320816,
      -0.012586834840476513,
      0.03391077741980553,
      -0.0461970679461956,
      -0.11381002515554428,
      0.04219961166381836,
      -0.04689604416489601,
      -0.053217627108097076,
      -0.07088439166545868,
      -0.12218121439218521,
      -0.010639778338372707,
      -0.039254799485206604,
      -0.04433761537075043,
      0.05014604702591896,
      0.006330160889774561,
      -0.055862028151750565,
      0.09108110517263412,
      -0.08328759670257568,
      -0.0038003057707101107,
      -0.01406074408441782,
      0.04764128103852272,
      -0.12315438687801361,
      0.023769818246364594,
      0.038471560925245285,
      -0.04517126455903053,
      -0.10577715188264847,
      0.03521762043237686,
      -0.1120963990688324,
      -0.02715231291949749,
      -0.023527760058641434,
      -0.02611689269542694,
      -0.005546731874346733,
      0.016181353479623795,
      0.04454052448272705,
      0.019589388743042946,
      -0.03188776969909668,
      0.014704890549182892,
      -0.022784600034356117,
      0.06457354128360748,
      0.001380786532536149,
      0.03848029673099518,
      -0.00930845458060503,
      0.0530138835310936,
      -0.016311967745423317,
      -0.061449356377124786,
      -0.0252769123762846,
      0.008257132023572922,
      0.011895710602402687,
      -0.05677684396505356,
      -0.06438834965229034,
      0.024726534262299538,
      -0.03723801299929619,
      -0.005700256675481796,
      -0.06709455698728561,
      -0.012291801162064075,
      0.05571208894252777,
      0.040342386811971664,
      -0.023209866136312485,
      0.0728859081864357,
      -0.017381496727466583,
      0.09167063236236572,
      -0.03521009907126427,
      0.0007488588453270495,
      0.05218759924173355,
      0.09362226724624634,
      -0.04679219797253609,
      0.029433883726596832,
      0.072563536465168,
      -0.0020473587792366743,
      -0.002911172341555357,
      0.04926697537302971,
      0.04121016710996628,
      -0.02197318524122238,
      -0.15552739799022675,
      -0.07884915173053741,
      -0.006499759387224913,
      -0.006424265913665295,
      0.02624593675136566,
      -0.021259432658553123,
      -0.013402942568063736,
      -0.03499229624867439,
      0.03602883219718933,
      -0.06213826313614845,
      -0.07760006189346313,
      0.04727252945303917,
      -0.0071469820104539394,
      0.01879853382706642,
      0.033867672085762024,
      0.033531561493873596,
      0.004884226247668266,
      0.008877856656908989,
      0.030446907505393028,
      0.07758457213640213
    ],
    [
      0.015415585599839687,
      -0.05837332084774971,
      -0.036831408739089966,
      0.05380401760339737,
      -0.06023604795336723,
      0.037160541862249374,
      -0.02972688339650631,
      0.04461124166846275,
      -0.04002750292420387,
      0.011583170853555202,
      -0.00956364069133997,
      -0.10006698220968246,
      -0.04374727979302406,
      0.03913438692688942,
      0.07546424120664597,
      0.03064115159213543,
      -0.030830761417746544,
      -0.005104330834001303,
      -0.03573000431060791,
      0.015023634769022465,
      -0.0019466191297397017,
      0.029432237148284912,
      -0.03855320066213608,
      -0.006873606704175472,
      0.010280998423695564,
      0.009034954011440277,
      0.030175672844052315,
      0.0085500692948699,
      -0.004923770669847727,
      -0.03728670999407768,
      0.029011642560362816,
      0.0737573429942131,
      -0.049143996089696884,
      0.010494775138795376,
      -0.039793215692043304,
      -0.007784878835082054,
      0.038147829473018646,
      0.0873790755867958,
      -0.022735947743058205,
      -0.07580992579460144,
      0.005240855272859335,
      -0.05513574555516243,
      0.036267854273319244,
      0.07823596894741058,
      -0.037587862461805344,
      -0.013790219090878963,
      -0.048442184925079346,
      -0.004237441811710596,
      0.0065369270741939545,
      -0.001655316911637783,
      0.019678473472595215,
      0.0763976201415062,
      -0.07819382846355438,
      -0.02117067389190197,
      -0.1550670564174652,
      0.003560655517503619,
      0.017565395683050156,
      -0.06256722658872604,
      0.04395308345556259,
      0.10590093582868576,
      -0.07172045111656189,
      -0.0008597488049417734,
      -0.024706818163394928,
      -0.012144254520535469,
      0.05280182510614395,
      -0.03053462691605091,
      0.06482334434986115,
      0.02732137218117714,
      -0.04705842211842537,
      -0.007162718567997217,
      -0.10877889394760132,
      0.10663409531116486,
      0.011852787807583809,
      -0.04012975096702576,
      -0.0237046517431736,
      0.06467466801404953,
      0.0023496379144489765,
      0.05979747697710991,
      -0.03819001093506813,
      0.05512778088450432,
      0.03346296027302742,
      0.0466478057205677,
      -0.02413608320057392,
      -0.0246442761272192,
      -0.08024321496486664,
      -0.009139670059084892,
      -0.03278597444295883,
      -0.032838426530361176,
      -0.023257169872522354,
      0.06271761655807495,
      -0.059588585048913956,
      -0.07943041622638702,
      0.027582678943872452,
      -0.0022865962237119675,
      0.05237652361392975,
      -0.019407980144023895,
      0.027453767135739326,
      0.0381445437669754,
      0.026554184034466743,
      0.03900812938809395,
      -0.015806742012500763,
      -0.04950810596346855,
      -0.036732085049152374,
      -0.02113267593085766,
      -0.01663271337747574,
      -0.01653711311519146,
      -0.011503377929329872,
      0.03458114340901375,
      0.05982590466737747,
      -0.05449405685067177,
      -0.12415692210197449,
      -0.003491233568638563,
      0.03523529693484306,
      0.0022787179332226515,
      -0.019980628043413162,
      -0.05041183531284332,
      0.026979587972164154,
      -0.03679263964295387,
      -0.01831490360200405,
      -0.02633681893348694,
      -0.026351837441325188,
      -0.05181372910737991,
      -0.00952768512070179,
      -0.017216365784406662,
      -0.049289338290691376,
      0.013440419919788837,
      -0.033878643065690994,
      -0.03953099250793457,
      -0.025799991562962532,
      0.05054572969675064,
      -0.09174498170614243,
      0.0340690091252327,
      -0.030990373343229294,
      0.02097817324101925,
      0.03422946855425835,
      -0.030242377892136574,
      -0.030261976644396782,
      0.011182164773344994,
      0.12427396327257156,
      0.06835620105266571,
      0.03152710571885109,
      -0.033007942140102386,
      -0.1404026746749878,
      -0.00498897023499012,
      -0.046473752707242966,
      0.13391144573688507,
      0.030374929308891296,
      -0.01093766838312149,
      0.031445492058992386,
      0.015066538006067276,
      -0.05508928745985031,
      0.05906166508793831,
      0.06373270601034164,
      0.015766242519021034,
      -0.022410832345485687,
      -0.00956167932599783,
      -0.040589168667793274,
      0.0035161240957677364,
      -0.007619056850671768,
      -0.04733303561806679,
      -0.07081663608551025,
      -0.09974964708089828,
      0.03858066350221634,
      0.06629089266061783,
      -0.05781661346554756,
      -0.013237622566521168,
      0.04734387993812561,
      -0.010184415616095066,
      0.015496079809963703,
      0.010125679895281792,
      -0.007320793345570564,
      -0.054505400359630585,
      -0.011005003936588764,
      0.059058934450149536,
      -0.022680923342704773,
      -0.03029581904411316,
      0.02501411736011505,
      0.035690367221832275,
      -0.04945454001426697,
      -0.011427201330661774,
      -0.029104458168148994,
      -0.0236943531781435,
      0.020639173686504364,
      0.044922616332769394,
      -0.02679755911231041,
      -0.03192299231886864,
      0.04094623401761055,
      9.167334792437032e-05,
      -0.05072731897234917,
      0.010996358469128609,
      0.037599388509988785,
      -0.1133389100432396,
      -0.048928432166576385,
      -0.014330324716866016,
      0.04807469993829727,
      -0.014004952274262905,
      -0.10219738632440567,
      0.04124845564365387,
      0.04745499789714813,
      0.040283966809511185,
      -0.007027856539934874,
      0.0005212718388065696,
      0.010912617668509483,
      -0.012808142229914665,
      0.022353939712047577,
      0.07485515624284744,
      -0.06583192944526672,
      -0.0505920872092247,
      -0.020735880360007286,
      0.02628600224852562,
      -0.012517946772277355,
      -0.08394933491945267,
      -0.071471206843853,
      -0.07690455764532089,
      -0.05963679775595665,
      0.03131606802344322,
      0.058723803609609604,
      -0.0010283617302775383,
      -0.029459083452820778,
      0.016240615397691727,
      0.042072806507349014,
      -0.05771854147315025,
      -0.008867167867720127,
      0.03368069604039192,
      -0.03925613686442375,
      0.05130773037672043,
      0.01873060315847397,
      -0.04784221947193146,
      0.030393850058317184,
      0.05922278016805649,
      -0.07414580136537552,
      -0.03193274140357971,
      -0.04504179581999779,
      0.023475881665945053,
      0.09030197560787201,
      -0.044720202684402466,
      -0.017062809318304062,
      -0.03760453313589096,
      0.03357580304145813,
      -0.001399477245286107,
      0.0009115091525018215,
      0.03575488179922104,
      -0.03719460964202881,
      -0.013196608051657677,
      -0.02867998741567135,
      0.0018650357378646731,
      0.023923641070723534,
      0.005725228227674961,
      0.015436985529959202,
      0.0012445810716599226,
      0.011444803327322006,
      0.013431396335363388,
      -0.04763433709740639,
      -0.045320603996515274,
      -0.09879280626773834,
      0.015558180399239063,
      0.02273630164563656,
      -0.04755481705069542,
      0.016234595328569412,
      0.013968998566269875,
      -0.024356244131922722,
      -0.01579073816537857,
      -0.042670782655477524,
      0.056591764092445374,
      0.06323625892400742,
      0.025377530604600906,
      0.053850557655096054,
      0.015263067558407784,
      -0.010273018851876259,
      0.0035465587861835957,
      -0.04114091396331787,
      -0.003487156704068184,
      0.05280899628996849,
      -0.02472415566444397,
      -0.034476254135370255,
      -0.050960153341293335,
      0.03835925832390785,
      -0.04956941679120064,
      -0.0011750442208722234,
      0.09465225040912628,
      0.0006828596815466881,
      0.03349417820572853,
      -0.012813949957489967,
      0.03856382891535759,
      0.014988147653639317,
      0.08968326449394226,
      -0.09322147071361542,
      0.00439827935770154,
      -0.0014665276976302266,
      -0.0020291574764996767,
      0.020507823675870895,
      0.11235136538743973,
      0.007665191311389208,
      -0.03479905053973198,
      0.020817821845412254,
      -0.0012741254176944494,
      -0.004726436920464039,
      -0.01920204795897007,
      0.03478880599141121,
      -0.02925930730998516,
      -0.0212086234241724,
      -0.005878082476556301,
      -0.023545971140265465,
      -0.0713336244225502,
      0.07285721600055695,
      -0.052028581500053406,
      -0.04499148949980736,
      0.03538007661700249,
      0.02467511221766472,
      -0.03938687965273857,
      0.010471262037754059,
      0.02201785333454609,
      -0.015112140215933323,
      0.03583541139960289,
      -0.0003306436992716044,
      0.009625719860196114,
      -0.07817653566598892,
      0.10922720283269882,
      -0.003829863853752613,
      0.04494597762823105,
      0.03418055921792984,
      0.040987007319927216,
      0.048069655895233154,
      0.012242043390870094,
      -0.09900788962841034,
      -0.0012519200099632144,
      0.042821429669857025,
      -0.04073449224233627,
      0.03394734486937523,
      -0.02898816019296646,
      -0.04709941893815994,
      -0.04742489382624626,
      0.03821376711130142,
      0.004669018089771271,
      0.05118773505091667,
      0.008274092338979244,
      0.04896549880504608,
      -0.0017162373987957835,
      0.015007169917225838,
      0.022485192865133286,
      0.001559123513288796,
      -0.0022657711524516344,
      -0.013588666915893555,
      -0.04836258664727211,
      -0.015095803886651993,
      -0.026809686794877052,
      0.003800251754000783,
      -0.037804920226335526,
      0.049294613301754,
      0.04607704281806946,
      -0.042512197047472,
      -0.003226235741749406,
      -0.025051962584257126,
      0.05394356697797775,
      0.021484652534127235,
      0.01047921646386385,
      0.04216450825333595,
      0.10193125158548355,
      0.0580446831882,
      0.01880740374326706,
      0.03118300437927246,
      0.037431780248880386,
      -0.03606510907411575,
      0.001566156279295683,
      -0.01363311242312193,
      0.05053179711103439,
      -0.005922230891883373,
      -0.0411599725484848,
      0.001954850973561406,
      -0.01449369266629219,
      0.055045608431100845,
      -0.03473376855254173,
      0.06012257933616638,
      -0.01895187236368656,
      0.007865087129175663,
      -0.12880145013332367,
      -0.0250773373991251,
      0.01639712229371071,
      0.07889114320278168,
      0.03791812062263489,
      0.013629313558340073,
      -0.026491496711969376,
      -0.03522798418998718,
      0.05074271559715271,
      -0.00338000082410872,
      -0.015032267197966576,
      0.020038889721035957,
      -0.04795011132955551,
      0.046151913702487946,
      -0.06947823613882065,
      -0.04261922463774681,
      -0.03819127008318901,
      -0.06573166698217392,
      0.03723638877272606,
      -0.006621151231229305,
      0.032671008259058,
      -0.11026384681463242,
      -0.014242393895983696,
      -0.07139254361391068,
      0.012644141912460327,
      0.004696189891546965,
      -0.053505972027778625,
      0.10782403498888016,
      0.05567329749464989,
      0.06103501841425896,
      -0.051649756729602814,
      -0.059650570154190063,
      -0.01252420712262392,
      -0.015796761959791183,
      0.056621819734573364,
      0.05086757615208626,
      -0.02909497171640396,
      -0.047252438962459564,
      -0.1002093106508255,
      0.004346292465925217,
      -0.02360188588500023,
      0.002633773721754551,
      -0.04574885591864586,
      0.030860790982842445,
      -0.010445346124470234,
      0.022154763340950012,
      0.0006425118772312999,
      0.009271030314266682,
      -0.09099394828081131,
      0.01299192477017641,
      0.09909127652645111,
      0.006367585156112909,
      -0.027486417442560196,
      0.013395627029240131,
      0.06748394668102264,
      0.02414383366703987,
      -0.007398036774247885,
      0.0138670913875103,
      -0.009785043075680733,
      -0.01863127201795578,
      -0.07188495248556137,
      0.0036192391999065876,
      -0.06145215407013893,
      0.012745645828545094,
      -0.005795084871351719,
      -0.027086051180958748,
      0.06636970490217209,
      0.020080938935279846,
      0.036694593727588654,
      -0.07434070855379105,
      0.0066015650518238544,
      -0.04092932492494583,
      -0.018880844116210938,
      0.013588852249085903,
      -0.010683326981961727,
      -0.009805353358387947,
      -0.04042655974626541,
      -0.00621431740000844,
      -0.010265456512570381,
      0.0017131584463641047,
      -0.010890969075262547,
      -0.018506018444895744,
      0.047853194177150726,
      0.0422358475625515,
      0.04143363982439041,
      -0.01449596881866455,
      -0.0806451365351677,
      0.010241623967885971,
      0.02505168877542019,
      0.05803293362259865,
      0.02019955776631832,
      0.0008033604826778173,
      -0.0014842074597254395,
      0.0184545386582613,
      -0.02377934195101261,
      0.09961706399917603,
      0.015457402914762497,
      0.02608298696577549,
      -0.05896131694316864,
      -0.05512547492980957,
      -0.00263567129150033,
      0.048389218747615814,
      0.03336424008011818,
      -0.1015753448009491,
      -0.02054559625685215,
      0.06825248897075653,
      -0.02530396543443203,
      0.010409647598862648,
      -0.03715246915817261,
      0.003386956173926592,
      0.02981562912464142,
      -0.0012099768500775099,
      -0.013905693776905537,
      -0.006244940683245659,
      0.026331109926104546,
      0.008345747366547585,
      -0.010197204537689686,
      0.027435477823019028,
      -0.003340540686622262,
      -0.035508397966623306,
      -0.014261801727116108,
      -0.05207688733935356,
      0.019820909947156906,
      0.04654252156615257,
      0.1340642273426056,
      0.07958044856786728,
      0.04971986263990402,
      0.045915860682725906
    ],
    [
      0.01644008420407772,
      0.054839249700307846,
      0.021457461640238762,
      -0.09471559524536133,
      0.06329035758972168,
      -0.014328207820653915,
      -0.05020669847726822,
      -0.01276328880339861,
      0.07080325484275818,
      0.0696154236793518,
      0.0030204877257347107,
      0.024225035682320595,
      0.023028381168842316,
      0.044466085731983185,
      0.06547079980373383,
      0.027301689609885216,
      0.04718152433633804,
      -0.05895702913403511,
      0.006900356151163578,
      -0.005083302967250347,
      0.00032674623071216047,
      0.03241586312651634,
      0.033568914979696274,
      0.003752265591174364,
      0.029346143826842308,
      0.038702014833688736,
      -0.07989232987165451,
      0.007688287645578384,
      0.0035765538923442364,
      0.015900621190667152,
      -0.01924913562834263,
      -0.02526182495057583,
      -0.031849488615989685,
      -0.0715932697057724,
      0.0332820750772953,
      0.01147259958088398,
      -0.062109146267175674,
      -0.09437285363674164,
      0.021478842943906784,
      -0.009707612916827202,
      0.019428467378020287,
      -0.009346582926809788,
      0.027201920747756958,
      -0.013491698540747166,
      0.03501628711819649,
      -0.04724183306097984,
      0.03542540594935417,
      0.028826076537370682,
      0.05837557092308998,
      0.04562557488679886,
      0.011773349717259407,
      -0.03897572308778763,
      0.08126866072416306,
      -0.06914664804935455,
      0.0295612383633852,
      0.021802779287099838,
      0.01875731348991394,
      -0.042141664773225784,
      0.034271132200956345,
      -0.032118286937475204,
      0.02118728682398796,
      -0.009464212693274021,
      -0.03285656496882439,
      -0.045280572026968,
      0.01445178221911192,
      0.032329585403203964,
      0.010892384685575962,
      0.04034955054521561,
      -0.01785188540816307,
      0.0245297122746706,
      0.013910645619034767,
      -0.015754401683807373,
      -0.0571763813495636,
      0.056868407875299454,
      0.04806342348456383,
      0.004679603036493063,
      0.04039239138364792,
      0.06567907333374023,
      0.002996375784277916,
      0.05927730351686478,
      0.0015360742108896375,
      0.06328662484884262,
      0.06027272716164589,
      0.021552525460720062,
      -0.02504950948059559,
      0.031997356563806534,
      -0.05518112704157829,
      0.040175601840019226,
      -0.026392566040158272,
      -0.014529256150126457,
      -0.05373566597700119,
      0.012314318679273129,
      -0.04856985807418823,
      0.030344385653734207,
      0.0804414227604866,
      0.05619005858898163,
      0.06382966786623001,
      0.08891583979129791,
      -0.022258304059505463,
      0.03489880636334419,
      0.04987082630395889,
      0.011644575744867325,
      -0.06150554493069649,
      -0.07126686722040176,
      0.021849701181054115,
      0.006983238738030195,
      0.07121392339468002,
      0.04049309343099594,
      0.0397108756005764,
      0.0262902919203043,
      -0.0027815797366201878,
      0.003859530435875058,
      0.024480972439050674,
      -0.0772065594792366,
      0.017692405730485916,
      0.08595702052116394,
      0.03422205522656441,
      0.05023881793022156,
      -0.0347023531794548,
      -0.011267255060374737,
      0.0015594742726534605,
      -0.058719903230667114,
      0.015903465449810028,
      0.009148819372057915,
      -0.08717106282711029,
      -0.008392199873924255,
      -0.07759083062410355,
      -0.025705305859446526,
      0.04806888848543167,
      -0.0006694693001918495,
      -0.07627598196268082,
      -0.05167818441987038,
      0.009652855806052685,
      -0.056703899055719376,
      0.07850494235754013,
      -0.013610581867396832,
      -0.06026269868016243,
      0.0431264266371727,
      0.009645368903875351,
      -0.002031649462878704,
      -0.01617950201034546,
      -0.02743949182331562,
      -0.025669191032648087,
      -0.05437115207314491,
      -0.04892193153500557,
      -0.02579743228852749,
      -0.021764153614640236,
      -0.006511432118713856,
      0.009094230830669403,
      0.02686830423772335,
      0.07273869961500168,
      -0.048095352947711945,
      -0.019842909649014473,
      0.04894104599952698,
      -0.014090234413743019,
      0.014648450538516045,
      0.024551469832658768,
      -0.023612001910805702,
      0.004150698892772198,
      -0.05054350569844246,
      -0.10718526691198349,
      -0.005847496446222067,
      0.0627172440290451,
      0.018241016194224358,
      0.057211365550756454,
      0.0020755224395543337,
      0.011565725319087505,
      0.02461734041571617,
      0.0006312922341749072,
      0.009564768522977829,
      -0.0525837317109108,
      -0.02296840026974678,
      0.02078928053379059,
      -0.08224554359912872,
      0.040998976677656174,
      -0.041042327880859375,
      0.07149864733219147,
      0.023732149973511696,
      0.033391956239938736,
      0.03698679432272911,
      0.06936132162809372,
      -0.07502315193414688,
      -0.0021189965773373842,
      -0.04523633420467377,
      -0.011503985151648521,
      -0.05190347880125046,
      -0.08280066400766373,
      0.0628456100821495,
      0.0032566774170845747,
      0.022152792662382126,
      0.04164177179336548,
      0.003336654743179679,
      -0.030935263261198997,
      -0.1118323802947998,
      -0.02377667836844921,
      -0.01526516955345869,
      0.03917316719889641,
      0.03460323065519333,
      -0.02234012819826603,
      -0.009363051503896713,
      -0.03261353820562363,
      -0.017922602593898773,
      -0.007369531784206629,
      0.008836437948048115,
      -0.02758430503308773,
      -0.006657502613961697,
      -0.00806178618222475,
      -0.0014961976557970047,
      0.07459805905818939,
      0.020655164495110512,
      -0.015809519216418266,
      0.08072927594184875,
      -0.03397829085588455,
      0.038114190101623535,
      0.02320878393948078,
      0.07694803923368454,
      0.004980695433914661,
      0.06528628617525101,
      0.06611259281635284,
      0.006718257442116737,
      0.009766600094735622,
      -0.028139345347881317,
      -0.03668990358710289,
      -0.055513523519039154,
      0.0017087961314246058,
      -0.03055032715201378,
      -0.028086582198739052,
      0.03629940748214722,
      0.07371395826339722,
      0.0056779952719807625,
      0.0537002868950367,
      0.0545152947306633,
      -0.03940374404191971,
      0.049472201615571976,
      -0.048734501004219055,
      0.055039942264556885,
      -0.04665990546345711,
      0.007123177405446768,
      -0.07920310646295547,
      0.009838636964559555,
      -0.021641792729496956,
      -0.021174386143684387,
      0.01647917926311493,
      -0.030326558277010918,
      0.01428559422492981,
      -0.01957789435982704,
      0.017952745780348778,
      -0.05099821835756302,
      0.0051489840261638165,
      0.08227483928203583,
      0.007638843264430761,
      0.01234333124011755,
      -0.05322133004665375,
      0.03165686875581741,
      -0.010383202694356441,
      0.002106685424223542,
      -0.0200236476957798,
      -0.01415521651506424,
      0.0011840331135317683,
      0.05455558001995087,
      -0.07524574548006058,
      0.01148186344653368,
      -0.01775951124727726,
      -0.05077120289206505,
      0.006141833029687405,
      -0.0675029531121254,
      -0.007576657459139824,
      -0.03166009858250618,
      -0.0030707907862961292,
      0.018114261329174042,
      0.006906611379235983,
      -0.02017292007803917,
      -0.05649492144584656,
      0.025573628023266792,
      -0.005594866815954447,
      0.0044975061900913715,
      -0.05817720666527748,
      0.011369062587618828,
      -0.015256179496645927,
      -0.019698653370141983,
      0.08592121303081512,
      0.09619255363941193,
      -0.039503056555986404,
      -0.010697649791836739,
      0.09122651815414429,
      0.018047060817480087,
      0.007784312125295401,
      -0.004327626898884773,
      0.06319677084684372,
      -0.03272026404738426,
      -0.06085466220974922,
      0.04997213929891586,
      0.03783836215734482,
      -0.034990519285202026,
      -0.05015186220407486,
      0.023222578689455986,
      -0.00945561658591032,
      -0.04072200134396553,
      -0.041133176535367966,
      -0.003347987774759531,
      0.00804806686937809,
      0.010543878190219402,
      0.027826108038425446,
      0.005651906598359346,
      0.008486563339829445,
      0.0330653041601181,
      -0.02502342127263546,
      0.012367300689220428,
      0.05502304807305336,
      -0.0425180159509182,
      0.029226018115878105,
      0.023830944672226906,
      -0.09475798904895782,
      -0.015124662779271603,
      0.030225738883018494,
      -0.0711572989821434,
      -0.06581229716539383,
      0.11859017610549927,
      -0.009632484056055546,
      0.016265790909528732,
      0.014528896659612656,
      0.052269503474235535,
      0.05136989429593086,
      0.07654285430908203,
      -0.034675806760787964,
      -0.0029865524265915155,
      -0.050544511526823044,
      0.019434722140431404,
      -0.03068508952856064,
      0.055812206119298935,
      0.034616619348526,
      0.06457561254501343,
      -0.027486151084303856,
      0.01965145952999592,
      0.0681268647313118,
      -0.038880616426467896,
      -0.04748457297682762,
      0.03165276721119881,
      -0.025890864431858063,
      -0.02529509924352169,
      0.004328443668782711,
      -0.012531368061900139,
      0.03783273324370384,
      -0.011650788597762585,
      0.029050329700112343,
      0.05435098335146904,
      0.01758040115237236,
      -0.04615167900919914,
      0.09113635122776031,
      -0.04691973701119423,
      0.008921243250370026,
      -0.04795894771814346,
      0.004131179768592119,
      -0.041401755064725876,
      0.009878729470074177,
      -0.03276319429278374,
      0.03234994411468506,
      -0.09318080544471741,
      -0.018321586772799492,
      0.010615678504109383,
      0.04520205035805702,
      -0.015834681689739227,
      -0.031759850680828094,
      0.016999434679746628,
      0.12593300640583038,
      -0.08993334323167801,
      0.057897187769412994,
      0.05082777887582779,
      0.028094736859202385,
      -0.06479567289352417,
      0.014777172356843948,
      -0.08206038177013397,
      0.03257172927260399,
      -0.0753648579120636,
      0.03515089675784111,
      -0.07892404496669769,
      0.050936002284288406,
      -0.014201147481799126,
      -0.0018028480699285865,
      0.0064907679334282875,
      0.0071310363709926605,
      -0.025560472160577774,
      -0.07619896531105042,
      0.004740868229418993,
      0.06333370506763458,
      -0.026573194190859795,
      0.045702122151851654,
      0.08099770545959473,
      -0.013280680403113365,
      0.04708397015929222,
      -0.004314987920224667,
      -0.030345052480697632,
      -0.07021662592887878,
      -0.005702095106244087,
      -0.013186780735850334,
      0.004545061383396387,
      0.07431966066360474,
      -0.0006585674709640443,
      0.021587571129202843,
      0.06923850625753403,
      0.01628715731203556,
      0.025570344179868698,
      0.0014948060270398855,
      -0.052841901779174805,
      0.05465775355696678,
      0.008447883650660515,
      0.04478361830115318,
      0.06622665375471115,
      -0.019261572510004044,
      -0.05335683375597,
      0.06701086461544037,
      0.0012163618812337518,
      -0.018703505396842957,
      0.02381056174635887,
      -0.02877662517130375,
      -0.08512024581432343,
      -0.046815138310194016,
      0.03371530398726463,
      -0.07386840134859085,
      0.01893090270459652,
      0.04376237839460373,
      -0.10798850655555725,
      -0.02251647412776947,
      0.015838349238038063,
      0.004611471202224493,
      0.03822655975818634,
      0.08736204355955124,
      -0.05934818461537361,
      0.02600342594087124,
      0.06212157756090164,
      -0.03276799991726875,
      0.013181892223656178,
      -0.06690026819705963,
      0.01717681623995304,
      0.02914797142148018,
      -0.010802576318383217,
      -0.1029360443353653,
      -0.004448950290679932,
      0.010299459099769592,
      0.0781862735748291,
      0.006832890212535858,
      0.02926458790898323,
      0.06135383993387222,
      0.00034193150349892676,
      -0.08056209981441498,
      -0.045200686901807785,
      0.013840804807841778,
      0.09230975806713104,
      -0.00876307487487793,
      0.04568834602832794,
      -0.0010372408432886004,
      0.0764496773481369,
      0.0016629481688141823,
      0.05307153984904289,
      0.04298613220453262,
      0.011953892186284065,
      -0.09563110023736954,
      0.0229424387216568,
      -0.03844049200415611,
      0.06738351285457611,
      -0.08087348192930222,
      -0.09011188894510269,
      -0.04761502891778946,
      0.04689016565680504,
      0.026774978265166283,
      -0.010922346264123917,
      0.023524612188339233,
      0.00407423684373498,
      0.01896372251212597,
      0.036683596670627594,
      -0.006757669150829315,
      -0.04555150493979454,
      0.038046322762966156,
      0.019452285021543503,
      -0.011036282405257225,
      -0.0015387882012873888,
      0.08134076744318008,
      0.020379118621349335,
      -0.06213416904211044,
      -0.0020076578948646784,
      -0.019347084686160088,
      -0.0023013215977698565,
      -0.06948444992303848,
      -0.03011183813214302,
      0.001967101125046611,
      -0.043784599751234055,
      0.028627151623368263,
      0.018811671063303947,
      0.0387675017118454,
      -0.04807468131184578,
      -0.018948493525385857,
      0.03851752355694771,
      -0.03716113790869713,
      -0.044089946895837784,
      -0.00036207237280905247,
      0.013441884890198708,
      -0.02949550747871399,
      0.060892924666404724,
      -0.021879101172089577,
      0.08427334576845169,
      0.016970403492450714,
      -0.011116363108158112,
      0.035621531307697296
    ],
    [
      -0.01889829710125923,
      -0.026114609092473984,
      -0.01892109401524067,
      -0.014379831030964851,
      0.09132068604230881,
      0.0024803578853607178,
      0.002946071093901992,
      0.03797072917222977,
      0.02876942604780197,
      -0.018207577988505363,
      0.03532078489661217,
      0.0029504175763577223,
      0.04138119891285896,
      0.1418033093214035,
      0.012624954804778099,
      0.02296937070786953,
      0.0014053843915462494,
      0.024585576727986336,
      -0.056805383414030075,
      0.04775437340140343,
      -0.10099004209041595,
      0.014682554639875889,
      -0.0044539072550833225,
      -0.03942076861858368,
      0.04051186516880989,
      0.04187379777431488,
      0.0021950053051114082,
      -0.017769064754247665,
      0.03090103343129158,
      0.04120192676782608,
      -0.04368080198764801,
      0.06840895861387253,
      0.05198486149311066,
      0.041205037385225296,
      0.03827611356973648,
      0.0010081252548843622,
      0.08047809451818466,
      -0.0028784291353076696,
      0.011271045543253422,
      0.027804525569081306,
      -0.049386825412511826,
      -0.08906726539134979,
      0.07082059234380722,
      -0.022514019161462784,
      -0.02751346305012703,
      0.024920040741562843,
      -0.020525984466075897,
      0.0802096351981163,
      -0.04064769670367241,
      -0.028063621371984482,
      0.021043037995696068,
      0.00019346349290572107,
      0.014447233639657497,
      -0.0030764308758080006,
      -0.049041666090488434,
      0.13147427141666412,
      -0.01867237314581871,
      -0.04365034028887749,
      0.05767378211021423,
      0.04847174510359764,
      0.03089524433016777,
      -0.04900473728775978,
      0.024727918207645416,
      0.027393193915486336,
      0.012293308041989803,
      0.015230108983814716,
      0.05323031172156334,
      0.07373000681400299,
      -0.04369496926665306,
      -0.019822005182504654,
      0.0020666371565312147,
      0.0378854013979435,
      0.041294679045677185,
      0.011519541032612324,
      0.05002845451235771,
      0.02395475097000599,
      0.10647570341825485,
      -0.03787826746702194,
      0.010817820206284523,
      0.0007831372786313295,
      0.11931268870830536,
      0.005753903184086084,
      0.05108534172177315,
      -0.04472823813557625,
      -0.0011327904649078846,
      0.03786047548055649,
      -0.036277513951063156,
      -0.009395006112754345,
      0.03118760511279106,
      -0.04545213654637337,
      -0.03695575147867203,
      0.010426726192235947,
      -0.013211367651820183,
      -0.1150326281785965,
      0.061584439128637314,
      -0.027633313089609146,
      0.009637913666665554,
      -0.09373240172863007,
      0.007683041971176863,
      0.07728040218353271,
      -0.005658465437591076,
      0.022372452542185783,
      -0.06896457076072693,
      -0.008891982026398182,
      -0.06947082281112671,
      -0.023435844108462334,
      0.08652738481760025,
      0.04418134316802025,
      -0.03382573649287224,
      0.043990422040224075,
      -0.11384336650371552,
      -0.044730331748723984,
      0.04135638102889061,
      0.018855895847082138,
      -0.055326566100120544,
      -0.017166271805763245,
      -0.017082151025533676,
      0.003753937780857086,
      -0.057178255170583725,
      0.03417453542351723,
      -0.0434085987508297,
      -0.008925560861825943,
      0.02221330627799034,
      0.03641923889517784,
      -0.010864334180951118,
      -0.021243862807750702,
      0.016389334574341774,
      -0.05652032792568207,
      -0.06311564892530441,
      -0.005406233016401529,
      -0.020274171605706215,
      0.09474463760852814,
      0.007269701920449734,
      0.046678002923727036,
      -0.01985280215740204,
      0.047860853374004364,
      -0.014400445856153965,
      -0.04520032927393913,
      -0.05152786523103714,
      -0.02623691037297249,
      -0.08606789261102676,
      -0.05690029636025429,
      -0.026898982003331184,
      0.014620170928537846,
      -0.0656701996922493,
      0.07124689966440201,
      0.004112492315471172,
      0.014482460916042328,
      -0.014608125202357769,
      -0.010979631915688515,
      -0.009212207980453968,
      0.024985646829009056,
      0.038431741297245026,
      0.040560491383075714,
      0.002626533154398203,
      -0.07724344730377197,
      0.04064396768808365,
      -0.004850864876061678,
      0.009118076413869858,
      -0.019575245678424835,
      -0.004385528154671192,
      -0.036072805523872375,
      0.0267206821590662,
      -0.04890497028827667,
      0.019587503746151924,
      0.03654509410262108,
      -0.018734710291028023,
      -0.01715710759162903,
      0.037817228585481644,
      -0.06666343659162521,
      0.008594869635999203,
      0.034704770892858505,
      0.00854641292244196,
      -0.04493894800543785,
      -0.04191294312477112,
      0.007885600440204144,
      -0.0023857587948441505,
      0.008399290964007378,
      0.04968015104532242,
      0.02623160555958748,
      0.012093482539057732,
      -0.006159740965813398,
      -0.03589147701859474,
      0.07979881763458252,
      -0.024995623156428337,
      0.012734439224004745,
      -0.019951097667217255,
      -0.0615181028842926,
      -0.05196937918663025,
      0.07366492599248886,
      0.0006622001528739929,
      -0.05739421397447586,
      0.0034054815769195557,
      0.0019063652725890279,
      0.05951045826077461,
      -0.004013622645288706,
      -0.018460780382156372,
      -0.0022860905155539513,
      -0.018552303314208984,
      -0.002272453624755144,
      0.011366713792085648,
      -0.032317038625478745,
      -0.04305801913142204,
      -0.008681858889758587,
      0.0168194230645895,
      -0.02754487842321396,
      -0.0036415017675608397,
      0.03113151155412197,
      -0.05838559940457344,
      0.04501309245824814,
      0.05321512371301651,
      0.05047979950904846,
      -0.07814756780862808,
      -0.005300123244524002,
      0.10458575934171677,
      -0.023637032136321068,
      0.0932919979095459,
      0.026797527447342873,
      -0.018432574346661568,
      0.04805951192975044,
      0.07522137463092804,
      0.08023786544799805,
      -0.025548258796334267,
      0.002435100730508566,
      0.06092483922839165,
      0.056059714406728745,
      0.05050111562013626,
      0.007478251587599516,
      0.047801997512578964,
      -0.04162365943193436,
      -0.017745377495884895,
      -0.007250658702105284,
      -0.014014516025781631,
      0.01709476299583912,
      0.011806861497461796,
      -0.02342914044857025,
      -0.03191133216023445,
      -0.042673736810684204,
      -0.008815174922347069,
      -0.05445665866136551,
      -0.03463476896286011,
      -0.050399065017700195,
      -0.023913951590657234,
      0.06679119169712067,
      -0.0043846662156283855,
      -0.008032622747123241,
      0.01561214029788971,
      -0.05742969736456871,
      -0.01177647802978754,
      0.06588960438966751,
      -0.0734604224562645,
      -0.009151914156973362,
      -0.08797044306993484,
      0.12172871828079224,
      -0.0070899189449846745,
      -0.03154489025473595,
      -0.005325371399521828,
      0.021760834380984306,
      0.014897122979164124,
      -0.05273569002747536,
      0.025707632303237915,
      0.01959768310189247,
      -0.002125796163454652,
      -0.034707505255937576,
      -0.07289011031389236,
      0.08312330394983292,
      0.06830261647701263,
      -0.018452201038599014,
      -0.015800392255187035,
      -0.1003211960196495,
      0.029624734073877335,
      -0.037567008286714554,
      0.07735582441091537,
      -0.037994157522916794,
      0.02351856231689453,
      -0.007360476069152355,
      0.03145165368914604,
      -0.027887383475899696,
      -0.026535863056778908,
      0.032865509390830994,
      0.01022964809089899,
      0.0748475193977356,
      -0.06141484156250954,
      -0.027188271284103394,
      0.10891319066286087,
      0.03703255206346512,
      -0.03809270262718201,
      0.015080180950462818,
      -0.03620085492730141,
      0.0032348029781132936,
      -0.08192963898181915,
      -0.002831091871485114,
      -0.021896928548812866,
      -0.00228256918489933,
      -0.03361152857542038,
      -0.035810817033052444,
      0.01676655001938343,
      0.04565669223666191,
      0.00791491661220789,
      -0.005309323780238628,
      -0.00019066361710429192,
      0.0049758958630263805,
      -0.017712870612740517,
      -0.07347651571035385,
      0.07096201926469803,
      -0.05940435826778412,
      0.07215104252099991,
      -0.008117441087961197,
      0.0372510589659214,
      0.06314241141080856,
      0.08831451088190079,
      0.06631384044885635,
      -0.05653606727719307,
      -0.03683008626103401,
      0.04935441166162491,
      0.0002155241381842643,
      -0.03153165057301521,
      -0.01870318502187729,
      -0.036468327045440674,
      -0.06720934808254242,
      -0.016003360971808434,
      0.002135644666850567,
      -0.01508990116417408,
      0.04603075608611107,
      -0.04292565584182739,
      0.044526226818561554,
      -0.06306791305541992,
      -0.02868739329278469,
      -0.043589167296886444,
      -0.007538659032434225,
      -0.029391782358288765,
      -0.058794837445020676,
      0.049089644104242325,
      0.028767362236976624,
      -0.05680087208747864,
      -0.06177351251244545,
      0.06030019372701645,
      0.012774876318871975,
      0.030691657215356827,
      -0.011692572385072708,
      0.011477285996079445,
      -0.08479844033718109,
      0.005429404322057962,
      -0.04039519652724266,
      -0.018017200753092766,
      0.006234819535166025,
      -0.03665987029671669,
      -0.016309602186083794,
      0.03390016406774521,
      0.09597175568342209,
      0.050245463848114014,
      0.04022067412734032,
      0.07166463881731033,
      0.03723142668604851,
      0.08037084341049194,
      -0.03831789642572403,
      0.058992255479097366,
      0.003810336347669363,
      0.011751290410757065,
      -0.024715514853596687,
      -0.06201987713575363,
      -0.06097085401415825,
      -0.024428151547908783,
      -0.03504561260342598,
      0.0018610236002132297,
      -0.013453997671604156,
      -0.049997005611658096,
      0.02029617503285408,
      0.0031014203559607267,
      -0.04457772150635719,
      0.007273395080119371,
      -0.0686429813504219,
      -0.009114271961152554,
      -0.041701048612594604,
      -0.049364957958459854,
      -0.09701835364103317,
      0.009643353521823883,
      0.008221529424190521,
      -0.08993899822235107,
      0.09460587799549103,
      0.011960634961724281,
      0.026739656925201416,
      0.0034569096751511097,
      -0.017145566642284393,
      -0.038010697811841965,
      0.029571548104286194,
      0.025039799511432648,
      0.011705711483955383,
      -0.021184025332331657,
      0.004544564988464117,
      0.05793893337249756,
      -7.739563443465158e-05,
      0.0015985858626663685,
      0.0232984758913517,
      -0.017571089789271355,
      0.011057037860155106,
      -0.03297581896185875,
      -0.044998280704021454,
      0.01983693242073059,
      0.011424009688198566,
      -0.07839401066303253,
      -0.038196176290512085,
      -0.009794304147362709,
      0.040029264986515045,
      0.03198094293475151,
      0.017089448869228363,
      -0.022968187928199768,
      0.08856724947690964,
      -0.08448045700788498,
      -0.0871342346072197,
      -0.001138358493335545,
      0.021077534183859825,
      0.028333112597465515,
      0.05868912115693092,
      -0.10002510994672775,
      -0.003309329506009817,
      -0.08798109740018845,
      -0.004142961464822292,
      -0.042810894548892975,
      0.0017606766195967793,
      0.0054265959188342094,
      0.020942872390151024,
      -0.03799723461270332,
      -0.006986845284700394,
      0.05521615222096443,
      -0.03282877802848816,
      -0.003066194010898471,
      0.015868330374360085,
      -0.013991372659802437,
      -0.0558290109038353,
      0.018928484991192818,
      0.025891482830047607,
      0.09050348401069641,
      -0.0335816852748394,
      -0.02890130504965782,
      -0.0824197307229042,
      0.02496110275387764,
      0.0029075974598526955,
      0.00022838375298306346,
      0.04072454571723938,
      -0.022920964285731316,
      -0.061527978628873825,
      -0.007033605594187975,
      0.02655157633125782,
      -0.10853452980518341,
      -0.06390750408172607,
      -0.03830248489975929,
      -0.03631695732474327,
      0.04101278632879257,
      -0.002923403400927782,
      0.01816622167825699,
      0.0407828614115715,
      0.04514014348387718,
      -0.003364804433658719,
      0.09771464765071869,
      -0.03787005692720413,
      0.005025097634643316,
      0.03546293452382088,
      -0.11138834059238434,
      -0.06319643557071686,
      -0.0732799544930458,
      -0.06935887783765793,
      -0.05300385132431984,
      -0.030474314466118813,
      -0.06823936849832535,
      -0.04871996492147446,
      0.0051579964347183704,
      -0.08702488988637924,
      -0.04367630183696747,
      -0.058327578008174896,
      -0.07427994161844254,
      0.07235278934240341,
      0.06765681505203247,
      0.005344378761947155,
      0.0032219989225268364,
      0.02858434058725834,
      -0.02665834128856659,
      -0.04732232913374901,
      -0.04628656059503555,
      0.04723535478115082,
      -0.02929774485528469,
      -0.011470415629446507,
      -0.07914864271879196,
      0.05151466280221939,
      0.08776320517063141,
      -0.056769806891679764,
      0.04387158155441284,
      -0.05508900433778763,
      0.013439001515507698,
      0.05046849325299263,
      0.03330110013484955,
      -0.04980810359120369,
      0.041995398700237274,
      -0.00967341847717762,
      0.1089775562286377,
      -0.06018245220184326,
      0.027897348627448082,
      -0.015932515263557434,
      -0.06355398893356323,
      -0.0011485174763947725,
      0.03710726276040077,
      -0.0369957871735096,
      0.022342480719089508
    ],
    [
      -0.006382830440998077,
      0.016402186825871468,
      -0.04771217331290245,
      -0.008935210295021534,
      0.020528798922896385,
      -0.01470599602907896,
      0.016509220004081726,
      0.040957774966955185,
      0.07443969696760178,
      -0.06121663376688957,
      -0.0381750725209713,
      0.06356672942638397,
      -0.04097769409418106,
      -0.03185407072305679,
      0.016393553465604782,
      -0.047166094183921814,
      -0.0241255946457386,
      -0.041894104331731796,
      0.02763974480330944,
      0.017046552151441574,
      0.009090807288885117,
      0.004159644711762667,
      -0.0052986652590334415,
      -0.003488456830382347,
      -0.06123918294906616,
      -0.05799685791134834,
      -0.009944645687937737,
      0.05725383013486862,
      -0.023054810240864754,
      -0.005666689481586218,
      0.010158862918615341,
      0.035456687211990356,
      -0.043325722217559814,
      0.020361991599202156,
      0.05057447776198387,
      0.06262928247451782,
      -0.000503783521708101,
      -0.05070536956191063,
      -0.0554681196808815,
      -0.05382456257939339,
      0.05491424351930618,
      0.034469831734895706,
      0.07844100147485733,
      -0.017296593636274338,
      -0.028443992137908936,
      -0.037704579532146454,
      0.05515414848923683,
      0.006036732345819473,
      0.017187906429171562,
      -0.006640676874667406,
      0.10251270979642868,
      -0.028096411377191544,
      -0.0487552210688591,
      -0.03679009526968002,
      -0.05405087023973465,
      0.09083310514688492,
      -0.0461803674697876,
      -0.02729186788201332,
      0.021862931549549103,
      -0.01882358267903328,
      0.003655661130324006,
      0.03852447494864464,
      -0.019176648929715157,
      0.013677732087671757,
      -0.12773603200912476,
      -0.030730752274394035,
      -0.004736334551125765,
      0.0031224670819938183,
      0.07667271047830582,
      -0.017777303233742714,
      0.0329149030148983,
      0.0353950560092926,
      0.009410702623426914,
      -0.017510464414954185,
      0.05293833836913109,
      -0.030744068324565887,
      0.03352248668670654,
      0.05538183078169823,
      -0.06144198030233383,
      -0.023453569039702415,
      0.012832886539399624,
      0.03649663180112839,
      0.052046652883291245,
      0.007369671016931534,
      0.024461185559630394,
      -0.09129492193460464,
      0.05571920424699783,
      -0.0035233094822615385,
      0.026018960401415825,
      -0.05988525599241257,
      0.030177006497979164,
      -0.021724997088313103,
      0.08125261217355728,
      -0.0038068005815148354,
      0.019742192700505257,
      -0.020148994401097298,
      0.005529982503503561,
      -0.016696352511644363,
      -0.03361201658844948,
      0.02772805094718933,
      0.005370116792619228,
      -0.02141258493065834,
      -0.060399364680051804,
      -0.02712325006723404,
      0.10878225415945053,
      -0.07157909125089645,
      -0.04082812741398811,
      0.01765347830951214,
      0.0789746642112732,
      -0.017896609380841255,
      -0.010674898512661457,
      -0.08929411321878433,
      0.0031129196286201477,
      0.06540753692388535,
      0.03209967538714409,
      -0.027729135006666183,
      -0.00732954079285264,
      0.04978353902697563,
      0.031690336763858795,
      0.04894910380244255,
      -0.0530996210873127,
      0.05812704563140869,
      -0.05524103716015816,
      -0.08969969302415848,
      0.06963378190994263,
      -0.07547170668840408,
      -0.07532185316085815,
      -0.008720291778445244,
      -0.04437393695116043,
      -0.012344221584498882,
      -0.10057811439037323,
      -0.023576494306325912,
      0.007937422953546047,
      0.005225340835750103,
      0.021499482914805412,
      0.04129490256309509,
      -0.008950532414019108,
      -0.029910709708929062,
      -0.12743236124515533,
      -0.01103939488530159,
      -0.00728121493011713,
      -0.023156311362981796,
      -0.08772136270999908,
      0.010753188282251358,
      -0.019588474184274673,
      -0.001749335671775043,
      -0.03100346401333809,
      -0.02218293584883213,
      -0.01613989844918251,
      -0.016512636095285416,
      0.0764053612947464,
      -0.05907771736383438,
      0.042456354945898056,
      0.006564885377883911,
      -0.0018447785405442119,
      0.025778429582715034,
      0.05304838344454765,
      -0.007965726777911186,
      -0.08419396728277206,
      -0.0413365438580513,
      0.044115692377090454,
      -0.004748592618852854,
      -0.12591230869293213,
      0.02362862415611744,
      -0.0039892191998660564,
      -0.017805559560656548,
      -0.03500835597515106,
      0.029568003490567207,
      0.031023608520627022,
      0.008906363509595394,
      0.02280641533434391,
      -0.008178479969501495,
      0.01598409004509449,
      0.06557244062423706,
      -0.019136428833007812,
      -0.003103140741586685,
      0.02394377626478672,
      0.08268727362155914,
      0.022893527522683144,
      -0.02489606663584709,
      0.010132770985364914,
      -0.033787477761507034,
      -0.04046193137764931,
      -0.01452788058668375,
      -0.01171913743019104,
      0.06565305590629578,
      0.05439369007945061,
      0.05396758019924164,
      0.0409456305205822,
      0.03398466110229492,
      0.012235340662300587,
      0.03410053625702858,
      0.05010469630360603,
      -0.035044115036726,
      0.052370835095644,
      -0.060817569494247437,
      -0.027450915426015854,
      0.024819662794470787,
      -0.08324890583753586,
      0.04235340282320976,
      -0.06332479417324066,
      -0.09020059555768967,
      0.03841159865260124,
      -0.006325853988528252,
      0.016224393621087074,
      -0.0319601334631443,
      -0.05439385771751404,
      0.04881631210446358,
      -0.03964877128601074,
      -0.011712593957781792,
      0.0020891313906759024,
      -0.007746398914605379,
      -0.00803290493786335,
      -0.0032891673035919666,
      0.0023514064960181713,
      0.022319519892334938,
      0.07235313206911087,
      0.010798145085573196,
      0.012701861560344696,
      -0.013301819562911987,
      0.012280844151973724,
      -0.037943918257951736,
      -0.057364724576473236,
      -0.011313937604427338,
      0.017291026189923286,
      -0.08157991617918015,
      0.00927844736725092,
      -0.026764916256070137,
      -0.027603959664702415,
      0.008573890663683414,
      -0.06452357769012451,
      -0.07374639809131622,
      -0.037633199244737625,
      0.07462219148874283,
      -0.03588957339525223,
      0.022713743150234222,
      -0.022762611508369446,
      -0.02214818075299263,
      0.02549845725297928,
      -0.027723340317606926,
      -0.04470028728246689,
      0.007913143374025822,
      0.0634666159749031,
      -0.03135151416063309,
      0.012236972339451313,
      0.02155582793056965,
      -0.006810290738940239,
      0.005656454246491194,
      0.014002758078277111,
      -0.10242711007595062,
      -0.05747797340154648,
      -0.003506810637190938,
      -0.06464380025863647,
      -0.01670151948928833,
      -0.042699575424194336,
      0.0560116283595562,
      -0.01830831728875637,
      -0.03464693948626518,
      0.12804025411605835,
      -0.0193388219922781,
      -0.001154429162852466,
      -0.012323721311986446,
      0.10529578477144241,
      -0.03402679041028023,
      0.014683793298900127,
      -0.03514496982097626,
      0.07028564065694809,
      0.017278550192713737,
      -0.003859925316646695,
      -0.050582051277160645,
      -0.058401934802532196,
      -0.014735223725438118,
      0.008291499689221382,
      0.021830998361110687,
      -0.07362589985132217,
      0.05867181345820427,
      0.006871397141367197,
      -0.012747286818921566,
      -0.11909032613039017,
      0.027493730187416077,
      -0.03932187706232071,
      -0.021001582965254784,
      0.0443386435508728,
      0.012938553467392921,
      0.026085227727890015,
      -0.030393339693546295,
      0.041126564145088196,
      -0.01358027569949627,
      0.0010117325000464916,
      0.10514692962169647,
      0.01393863558769226,
      -0.015180417336523533,
      0.05363265797495842,
      -0.014082412235438824,
      0.021654140204191208,
      -0.014701100066304207,
      -0.009641190059483051,
      -0.04974404349923134,
      0.012547311373054981,
      -0.07878471165895462,
      0.03577713668346405,
      0.0007374483975581825,
      0.023952586576342583,
      -7.631159678567201e-05,
      -0.031798627227544785,
      0.0742136538028717,
      0.06462221592664719,
      0.05868850648403168,
      -0.05691736936569214,
      -0.011176413856446743,
      0.028427228331565857,
      0.004737837240099907,
      -0.0011878826189786196,
      0.019306890666484833,
      -0.00280395383015275,
      -0.016595276072621346,
      -0.0589965395629406,
      0.030133532360196114,
      5.382733070291579e-05,
      -0.0072485702112317085,
      0.04156415909528732,
      0.066197469830513,
      -0.06941672414541245,
      0.05901510268449783,
      0.018091673031449318,
      0.0019102268852293491,
      -0.007058208342641592,
      0.035646818578243256,
      0.04065340757369995,
      -0.0124350069090724,
      -0.01190890371799469,
      0.10848743468523026,
      0.06344977021217346,
      0.006180411204695702,
      -0.011050713248550892,
      0.02553708478808403,
      -0.07086712121963501,
      -0.01690799929201603,
      0.03287772834300995,
      0.016408361494541168,
      -0.0024783131666481495,
      0.041637714952230453,
      0.002527127508074045,
      -0.08659958094358444,
      -0.05341809242963791,
      0.02779398113489151,
      0.002333636162802577,
      0.006556667387485504,
      -0.043773043900728226,
      -0.11475735157728195,
      -0.026948262006044388,
      0.007468659430742264,
      -0.0569576658308506,
      0.040229473263025284,
      -0.0660816878080368,
      -0.06475616991519928,
      0.02097989246249199,
      -0.0041906205005943775,
      0.045960359275341034,
      0.06630589812994003,
      -0.09170648455619812,
      0.061310507357120514,
      -0.008584481664001942,
      -0.0389048308134079,
      0.09652716666460037,
      0.019292525947093964,
      0.05260961130261421,
      0.012662725523114204,
      -0.0030572430696338415,
      -0.010110183618962765,
      0.014950803481042385,
      -0.0613834373652935,
      0.026846006512641907,
      0.02354811131954193,
      -0.06913741677999496,
      0.01720847748219967,
      0.014691200107336044,
      0.03436481952667236,
      -0.06550206243991852,
      -0.012934147380292416,
      0.008044295012950897,
      -0.018264682963490486,
      -0.045297395437955856,
      0.014330738224089146,
      0.08413312584161758,
      0.010343288071453571,
      0.03325826674699783,
      0.04224688187241554,
      -0.036361705511808395,
      0.020832818001508713,
      -0.04609603434801102,
      0.11032744497060776,
      -0.03552393987774849,
      0.018272342160344124,
      -0.05391339212656021,
      0.031896088272333145,
      -0.015645265579223633,
      0.02289259433746338,
      1.4705387002322823e-05,
      -0.023260224610567093,
      0.08708608150482178,
      0.003108524950221181,
      0.06160777062177658,
      0.05348224192857742,
      0.0630013570189476,
      -0.005839153658598661,
      -0.02847539633512497,
      0.07390256226062775,
      -0.05035046860575676,
      -0.017470309510827065,
      -0.004462259355932474,
      0.054722245782613754,
      -0.07272815704345703,
      0.03275113180279732,
      -0.050796691328287125,
      -0.01795373298227787,
      -0.028096690773963928,
      0.03488074988126755,
      0.002620181767269969,
      -0.018403619527816772,
      0.05890985578298569,
      -0.019680550321936607,
      -0.05211488530039787,
      -0.01849411241710186,
      0.13289949297904968,
      0.03430550917983055,
      0.03322228416800499,
      -0.02070516347885132,
      -0.03728987276554108,
      0.06772155314683914,
      0.020013943314552307,
      0.019469745457172394,
      0.007203821092844009,
      0.004197706468403339,
      -0.0662502646446228,
      -0.05915531516075134,
      -0.01228816993534565,
      -0.021453123539686203,
      -0.003079421818256378,
      -0.051019538193941116,
      0.020206084474921227,
      0.035373687744140625,
      -0.01419536117464304,
      -0.027607649564743042,
      -0.013324894942343235,
      -0.03997669741511345,
      -0.05583341419696808,
      -0.05088893324136734,
      0.0411047525703907,
      -0.06292620301246643,
      0.05281594395637512,
      0.01614590920507908,
      0.04018951579928398,
      -0.013529804535210133,
      -0.028190117329359055,
      -0.013407164253294468,
      0.0027934175450354815,
      0.06580241024494171,
      -0.04735318943858147,
      0.03638187050819397,
      -0.05876247212290764,
      0.008303876966238022,
      0.02579263225197792,
      -0.0031573153100907803,
      0.019304268062114716,
      -0.04998068884015083,
      -0.014048993587493896,
      -0.024727392941713333,
      -0.03366266191005707,
      -0.015163524076342583,
      0.005460864864289761,
      0.006853111553937197,
      0.018891341984272003,
      -0.012224329635500908,
      0.01081579364836216,
      0.013009343296289444,
      0.0199056975543499,
      0.019278768450021744,
      -0.07385832816362381,
      0.01664244756102562,
      -0.006691808812320232,
      -0.021997256204485893,
      0.0015363178681582212,
      0.029224500060081482,
      -0.0322745181620121,
      -0.023690732195973396,
      0.03774188086390495,
      0.04932520538568497,
      0.03637692332267761,
      -0.05204058438539505,
      -0.018919792026281357,
      -0.08413233608007431,
      -0.006153387017548084,
      -0.031531717628240585,
      -0.028406953439116478,
      -0.05490441247820854,
      0.011473218910396099,
      0.0024269612040370703,
      0.05674762651324272,
      -0.07248833775520325,
      -0.007076545152813196,
      -0.01938732899725437,
      -0.004989995155483484
    ],
    [
      -0.011248515918850899,
      -0.12035828828811646,
      0.020285677164793015,
      -0.0065237837843596935,
      -0.002759730676189065,
      0.0345902182161808,
      0.015751948580145836,
      -0.03391888737678528,
      0.08753110468387604,
      0.019050998613238335,
      0.03653327748179436,
      -0.08783794194459915,
      -0.05352139100432396,
      -0.05167365074157715,
      -0.023136088624596596,
      -0.061452195048332214,
      -0.0602644719183445,
      0.0004680487618315965,
      -0.005064147058874369,
      0.04402419179677963,
      0.01162770576775074,
      -0.004332760814577341,
      -0.023986581712961197,
      0.04420878738164902,
      0.10757466405630112,
      -0.028569089248776436,
      -0.0044022598303854465,
      -0.025661829859018326,
      -0.023134933784604073,
      0.0889270007610321,
      -0.004296252503991127,
      -0.001999588217586279,
      0.07033207267522812,
      0.0008539330447092652,
      -0.10428124666213989,
      -0.08367063105106354,
      0.09142225980758667,
      -0.024663859978318214,
      -0.001995195634663105,
      -0.02951795980334282,
      -0.049936503171920776,
      -0.006206714082509279,
      0.05792074277997017,
      0.1354198455810547,
      -0.0042481753043830395,
      0.006798438262194395,
      0.0395939014852047,
      -0.045332424342632294,
      0.03731377422809601,
      0.0252075158059597,
      0.03579331561923027,
      -0.00786103866994381,
      -0.03496560826897621,
      -0.12761183083057404,
      0.007849138230085373,
      -0.059322431683540344,
      -0.07913215458393097,
      0.005209292285144329,
      -0.028087779879570007,
      -0.0033118785358965397,
      -0.061388831585645676,
      -0.004434897098690271,
      -0.03383336588740349,
      -0.032051172107458115,
      -0.07359141856431961,
      0.02200721763074398,
      0.023870136588811874,
      0.045961108058691025,
      -0.012065045535564423,
      -0.06555906683206558,
      -0.006333754863590002,
      -0.00587092898786068,
      -0.018906116485595703,
      0.056635137647390366,
      -0.019690195098519325,
      -0.04740198701620102,
      0.0064771599136292934,
      -0.03374369814991951,
      -0.09279952198266983,
      -0.06989531964063644,
      -0.0384693369269371,
      -0.010958072729408741,
      -0.07890307903289795,
      -0.08281581103801727,
      -0.07143760472536087,
      -0.05252698063850403,
      -0.0723080113530159,
      -0.07807948440313339,
      0.057761725038290024,
      0.004734936635941267,
      0.05960879847407341,
      0.007325278595089912,
      -0.032328058034181595,
      0.06083843111991882,
      0.01351673062890768,
      -0.10028649121522903,
      -0.016355860978364944,
      -0.015805231407284737,
      0.00410678843036294,
      0.029424984008073807,
      0.09467870742082596,
      -0.014500996097922325,
      0.018258603289723396,
      -0.022590510547161102,
      0.00012674032768700272,
      0.018703043460845947,
      0.014280810952186584,
      -0.002226585289463401,
      0.03566388785839081,
      0.041829511523246765,
      -0.05231940373778343,
      -0.0032803756184875965,
      -0.024899998679757118,
      -0.023419702425599098,
      0.03452141955494881,
      0.014840252697467804,
      0.010786926373839378,
      -0.04066460579633713,
      -0.018850237131118774,
      0.0031159326899796724,
      -0.11701558530330658,
      -0.07105216383934021,
      0.01199343241751194,
      -0.02084355801343918,
      -0.0666487067937851,
      0.0452791191637516,
      -0.019486844539642334,
      -0.045665014535188675,
      -0.03430784493684769,
      0.05222376435995102,
      0.008075105026364326,
      0.027083924040198326,
      -0.013166853226721287,
      -0.0009285405976697803,
      -0.01264277659356594,
      0.009224964305758476,
      0.0667746514081955,
      0.007765487302094698,
      -0.07993775606155396,
      -0.030960064381361008,
      -0.018538353964686394,
      -0.10620775073766708,
      0.05758708342909813,
      0.0982675701379776,
      -0.08305040001869202,
      0.05495649203658104,
      0.027550876140594482,
      0.03206942602992058,
      0.018171845003962517,
      -0.003256674390286207,
      -0.007418548688292503,
      0.06909436732530594,
      0.07328592240810394,
      -0.0022131039295345545,
      0.0487683042883873,
      0.040534522384405136,
      -0.08865073323249817,
      -0.017513904720544815,
      -0.03150695562362671,
      -0.03642883151769638,
      -9.204159869113937e-05,
      -0.07726214081048965,
      -0.09986463189125061,
      -0.002980554010719061,
      -0.07760585844516754,
      0.037410371005535126,
      0.010900663211941719,
      0.0009202518267557025,
      -0.013732120394706726,
      -0.06046053394675255,
      -0.03493422269821167,
      0.048544615507125854,
      0.026501642540097237,
      -0.009817193262279034,
      -0.01725628226995468,
      -0.08581707626581192,
      0.018985003232955933,
      0.03974677622318268,
      -0.07169071584939957,
      0.14476287364959717,
      0.06447479128837585,
      0.013997785747051239,
      -0.0002080106787616387,
      0.05699340999126434,
      0.016323214396834373,
      0.01882154494524002,
      -0.028374996036291122,
      0.014307690784335136,
      0.037729211151599884,
      0.08162804692983627,
      -0.014546995051205158,
      -0.05330190062522888,
      -0.07519058883190155,
      0.0037653713952749968,
      0.029923640191555023,
      -0.007119203917682171,
      -0.06566569954156876,
      0.017379870638251305,
      -0.01896701194345951,
      0.021040132269263268,
      -0.08374816924333572,
      0.033447518944740295,
      0.047640636563301086,
      0.014680321328341961,
      -0.08895865082740784,
      0.009963704273104668,
      -0.07292011380195618,
      -0.005640445277094841,
      0.04315149784088135,
      0.07006342709064484,
      -0.050506338477134705,
      -0.048779163509607315,
      -0.012111233547329903,
      -0.0654762014746666,
      -0.06718883663415909,
      0.024151282384991646,
      0.03338802233338356,
      -0.04926826059818268,
      -0.06652750074863434,
      -0.07362169027328491,
      -0.043382950127124786,
      -0.03933475539088249,
      0.0009002680308185518,
      0.0374162420630455,
      -0.030374743044376373,
      0.05056261643767357,
      -0.018559003248810768,
      0.033890724182128906,
      0.038937758654356,
      -0.009332366287708282,
      0.016940543428063393,
      0.012457439675927162,
      -0.03375552222132683,
      -0.01281815767288208,
      0.02240716479718685,
      -0.03966032341122627,
      0.06545741856098175,
      0.006190603598952293,
      0.00932908896356821,
      0.01948830857872963,
      -0.024540947750210762,
      -0.04913247004151344,
      -0.0021887498442083597,
      0.0010080250212922692,
      0.023239241912961006,
      0.018720531836152077,
      0.03803510218858719,
      -0.04258068650960922,
      -0.026075992733240128,
      0.003931565210223198,
      0.033435311168432236,
      0.014623909257352352,
      0.06866203248500824,
      -0.009495806880295277,
      0.04618288204073906,
      -0.03772128000855446,
      -0.02263708971440792,
      -0.009998121298849583,
      0.02385403774678707,
      -0.004686437547206879,
      -0.025871960446238518,
      0.05974312499165535,
      0.012220011092722416,
      -0.008656098507344723,
      -0.02533143386244774,
      0.03459170088171959,
      0.016313569620251656,
      0.06563907861709595,
      -0.054480571299791336,
      0.030093150213360786,
      0.05212241783738136,
      -0.030158553272485733,
      0.024035489186644554,
      -0.033089764416217804,
      0.0052813333459198475,
      -0.04959292337298393,
      0.014674062840640545,
      -0.03224769979715347,
      0.07238706946372986,
      0.029128368943929672,
      0.038552176207304,
      0.008066367357969284,
      -0.010724016465246677,
      0.03533517196774483,
      -0.028371548280119896,
      -0.06334561854600906,
      -0.016769975423812866,
      0.011024774983525276,
      0.07961565256118774,
      0.06709875911474228,
      -0.1045360416173935,
      0.04433932527899742,
      -0.05380743369460106,
      0.007292030844837427,
      0.02575748972594738,
      0.04567929357290268,
      -0.030689576640725136,
      -0.00408504344522953,
      -0.02406245470046997,
      -0.052410226315259933,
      -0.06995747983455658,
      -0.0020722621120512486,
      0.045752670615911484,
      -0.001279148506000638,
      -0.02376740798354149,
      -0.015772536396980286,
      -0.027018021792173386,
      0.04735737293958664,
      -0.01800573617219925,
      -0.05744016915559769,
      0.0005051494226790965,
      0.04949210211634636,
      0.03696487471461296,
      0.01981928013265133,
      -0.049830906093120575,
      0.005094351712614298,
      0.023647930473089218,
      0.03815297782421112,
      -0.0472429133951664,
      0.027799474075436592,
      -0.026884598657488823,
      0.05559087544679642,
      0.07720787823200226,
      0.01794038712978363,
      -0.01858939602971077,
      0.01029451284557581,
      -0.09123582392930984,
      0.021034110337495804,
      0.07111632078886032,
      -0.041962940245866776,
      -0.05201062932610512,
      -0.020221451297402382,
      -0.024845821782946587,
      -0.0179204773157835,
      0.021800993010401726,
      0.0210518017411232,
      0.027724336832761765,
      -0.05575603246688843,
      0.006002023350447416,
      0.007387048099189997,
      0.03672367334365845,
      -0.010270247235894203,
      0.00865890085697174,
      -0.051338549703359604,
      -0.016215855255723,
      0.008376705460250378,
      -0.004350027535110712,
      -0.01978815160691738,
      -0.025444677099585533,
      -0.017019862309098244,
      -0.004997887648642063,
      -0.044139374047517776,
      -0.010398193262517452,
      0.0146749597042799,
      0.00860548298805952,
      0.02710450440645218,
      0.028747308999300003,
      0.02164703980088234,
      0.05442899093031883,
      0.0038077521603554487,
      -0.05063192546367645,
      -0.01037565991282463,
      0.06935392320156097,
      -0.004679354839026928,
      -0.11171694844961166,
      0.06899800151586533,
      -0.043150145560503006,
      -0.026969723403453827,
      -0.02853734791278839,
      -0.004501761868596077,
      0.007050736807286739,
      -0.04017152264714241,
      0.051305852830410004,
      0.05078061297535896,
      0.0025524625089019537,
      -0.03420568257570267,
      0.07284306734800339,
      0.0012753682676702738,
      -0.009856962598860264,
      -0.07615792751312256,
      -0.08342684805393219,
      -0.029729310423135757,
      -0.020946184173226357,
      0.0026761742774397135,
      -0.013383322395384312,
      -0.023255182430148125,
      -0.018033141270279884,
      0.09072467684745789,
      -0.040738027542829514,
      -0.02355409413576126,
      0.061001911759376526,
      -0.007186847738921642,
      0.019373532384634018,
      0.013429726473987103,
      -0.016713589429855347,
      -0.031001189723610878,
      0.03781060501933098,
      0.02809525839984417,
      -0.08759933710098267,
      0.04203125089406967,
      -0.05661126971244812,
      -0.038072213530540466,
      0.10289882868528366,
      -0.018178695812821388,
      0.025608832016587257,
      -0.02193598262965679,
      0.06502702087163925,
      0.023120535537600517,
      -0.0067369453608989716,
      -0.042300738394260406,
      -0.0478040985763073,
      0.030668698251247406,
      0.07588165998458862,
      -0.12317890673875809,
      0.08443421125411987,
      0.019838839769363403,
      0.022118639200925827,
      0.01936153881251812,
      0.04668736830353737,
      -0.07216767966747284,
      0.05408107116818428,
      0.07047300785779953,
      -0.08799254149198532,
      -0.04690481349825859,
      0.036180876195430756,
      0.07525195181369781,
      -0.0006315248901955783,
      -0.008104251697659492,
      0.01638340950012207,
      0.0232129767537117,
      0.03480730950832367,
      -0.017115358263254166,
      0.046157531440258026,
      -0.008328828029334545,
      0.02235845848917961,
      -0.032962433993816376,
      0.10623959451913834,
      -0.010171300731599331,
      -0.029465286061167717,
      0.03366262465715408,
      0.07664008438587189,
      0.08113257586956024,
      0.04119419306516647,
      0.022808760404586792,
      -0.05473022162914276,
      0.0016442063497379422,
      -0.00918271578848362,
      0.030127637088298798,
      0.02198648452758789,
      0.01764080300927162,
      0.0183554794639349,
      0.025914255529642105,
      0.02543940395116806,
      -0.03097698464989662,
      0.021482564508914948,
      -0.009248342365026474,
      0.001036193105392158,
      0.06524615734815598,
      0.05594954267144203,
      -0.0738207995891571,
      0.014035061001777649,
      -0.0781521126627922,
      0.10891583561897278,
      -0.004329846706241369,
      0.12870381772518158,
      0.02025376446545124,
      0.09725551307201385,
      0.04488183185458183,
      -0.08119598776102066,
      0.013420208357274532,
      -0.015416148118674755,
      -0.04945987090468407,
      0.06868518888950348,
      0.0644063726067543,
      0.003677867352962494,
      -0.03365466743707657,
      0.019557224586606026,
      -0.022678948938846588,
      -0.02069823071360588,
      -0.03404008597135544,
      0.039875149726867676,
      0.01726633682847023,
      -0.08293680101633072,
      -0.02320294827222824,
      -0.030313750728964806,
      -0.005931933410465717,
      0.024088555946946144,
      -0.023013651371002197,
      -0.050648365169763565,
      -0.016994215548038483,
      0.0050285859033465385,
      0.02317279390990734,
      0.012754675932228565,
      -0.01567767560482025,
      0.05061280354857445,
      0.03154294565320015,
      0.0771176740527153,
      0.03581720590591431,
      -0.010034499689936638,
      0.05361876264214516,
      0.020998675376176834,
      -0.07559449225664139,
      0.006093829870223999
    ],
    [
      -0.00789917167276144,
      -0.027266625314950943,
      0.06908906996250153,
      -0.0009768846211954951,
      0.00588490255177021,
      -0.009120633825659752,
      0.0793914869427681,
      -0.07195492088794708,
      -0.02711690589785576,
      0.03479834645986557,
      -0.019311146810650826,
      -0.046351127326488495,
      -0.016492128372192383,
      -0.005878553260117769,
      0.10386630147695541,
      0.0340397022664547,
      -0.022722439840435982,
      0.0863623321056366,
      0.04316534474492073,
      -0.043554432690143585,
      -0.06926276534795761,
      0.044734179973602295,
      -0.008249434642493725,
      0.05187450349330902,
      -0.019932234659790993,
      -0.005593715235590935,
      0.12353880703449249,
      -0.010698539204895496,
      0.017800884321331978,
      0.02106897346675396,
      0.054313935339450836,
      0.00657354760915041,
      0.06892033666372299,
      -0.004595293663442135,
      0.004613965749740601,
      -0.02209458500146866,
      -0.011835295706987381,
      -0.0358537919819355,
      -0.025929439812898636,
      0.014176195487380028,
      0.011863878928124905,
      0.014350123703479767,
      -0.023425936698913574,
      -0.05299150198698044,
      0.01842583529651165,
      -0.08362209796905518,
      0.07394866645336151,
      0.0705379918217659,
      -0.03452817350625992,
      -0.012467213906347752,
      0.00013914555893279612,
      0.0370187908411026,
      0.0089262705296278,
      -0.06091061979532242,
      -0.03620949387550354,
      -0.047155894339084625,
      0.03857836499810219,
      0.043898049741983414,
      0.06488675624132156,
      0.0073831453919410706,
      -0.05906287208199501,
      0.01599317044019699,
      0.030754875391721725,
      -0.026271002367138863,
      -0.05128999054431915,
      -0.001226619933731854,
      0.015133347362279892,
      -0.005393635015934706,
      -0.0215675737708807,
      -0.012571443803608418,
      -0.004843141417950392,
      0.02001066319644451,
      0.036993496119976044,
      -0.02454190142452717,
      -0.031167609617114067,
      0.06285962462425232,
      -0.036634378135204315,
      0.04293418303132057,
      -0.003686042968183756,
      -0.05781180039048195,
      0.07575628161430359,
      0.010377559810876846,
      0.031227121129631996,
      0.02425375021994114,
      -0.07302430272102356,
      0.01290817279368639,
      0.05180753394961357,
      0.030683722347021103,
      -0.053916674107313156,
      0.04587225615978241,
      0.00530026713386178,
      -0.014314739964902401,
      0.015320655889809132,
      0.01492930669337511,
      0.0319465808570385,
      0.037483710795640945,
      -0.03376472741365433,
      0.018236037343740463,
      0.028544483706355095,
      -0.02948501519858837,
      0.0048483251594007015,
      0.00681639788672328,
      0.008410176262259483,
      0.06832095980644226,
      -0.002646675566211343,
      -0.007612579967826605,
      -0.04011242091655731,
      -0.0233619287610054,
      -0.012338339351117611,
      0.0381338894367218,
      -0.0709511861205101,
      -0.03246808424592018,
      0.061024926602840424,
      -0.06950844079256058,
      0.024016601964831352,
      -0.07058393210172653,
      0.010732637718319893,
      -0.04198766499757767,
      0.10067030787467957,
      -0.02175365388393402,
      -0.03220605477690697,
      0.0009254272445105016,
      -0.019556952640414238,
      0.03399139642715454,
      0.011364834383130074,
      -0.05874995142221451,
      -0.027874143794178963,
      -0.007550474256277084,
      0.022094018757343292,
      0.01958329603075981,
      0.02113126963376999,
      0.0400884635746479,
      -0.019060203805565834,
      0.0205354206264019,
      -0.05403688922524452,
      -0.02171408012509346,
      0.020430151373147964,
      -0.00034681532997637987,
      0.00022201858519110829,
      0.036452725529670715,
      -0.01996520347893238,
      0.031101584434509277,
      -0.04493526741862297,
      -0.0946706160902977,
      -0.01951763965189457,
      0.08116219937801361,
      0.0005514334188774228,
      0.004714977461844683,
      0.059292953461408615,
      0.0729164332151413,
      0.02226385474205017,
      -0.005906547885388136,
      -0.0032811162527650595,
      -0.049113817512989044,
      -0.060623083263635635,
      0.030339987948536873,
      0.024304794147610664,
      0.13983385264873505,
      -0.028409169986844063,
      -0.06553922593593597,
      -0.012385854497551918,
      -0.0012441499857231975,
      -0.005773558747023344,
      -0.05120058357715607,
      -0.035639453679323196,
      -0.02504281885921955,
      0.023541709408164024,
      0.01424078457057476,
      -0.0014921509427949786,
      0.029283665120601654,
      0.0248035229742527,
      0.0021535141859203577,
      -0.0036948458291590214,
      -0.014739422127604485,
      0.08189799636602402,
      -0.05374390259385109,
      -0.03430325165390968,
      -0.003981428220868111,
      -0.06680719554424286,
      0.047472190111875534,
      0.014904769137501717,
      -0.08581691980361938,
      -0.031211458146572113,
      0.05528944358229637,
      -0.010749909095466137,
      -0.006490413565188646,
      -0.005034833215177059,
      0.029267577454447746,
      -0.026017850264906883,
      0.023534076288342476,
      -0.005843935068696737,
      -0.022313818335533142,
      0.023695651441812515,
      0.04135744646191597,
      0.02945128083229065,
      0.015783896669745445,
      0.015668386593461037,
      0.07289503514766693,
      0.03128068894147873,
      -0.0013056264724582434,
      0.021886808797717094,
      -0.03713268041610718,
      -0.04354037344455719,
      -0.014434532262384892,
      -0.022526461631059647,
      -0.058289241045713425,
      0.04799038916826248,
      0.008280177600681782,
      0.061119452118873596,
      -0.09069769829511642,
      0.011162767186760902,
      0.0007485764217562973,
      -0.004000899847596884,
      -0.0030303755775094032,
      0.06229259818792343,
      -0.02627629227936268,
      0.017817432060837746,
      -0.02988751232624054,
      -0.09465618431568146,
      -0.039551228284835815,
      0.03874623775482178,
      -0.06883265823125839,
      -0.0432417094707489,
      -0.022557523101568222,
      -0.04309822991490364,
      -0.030719134956598282,
      0.025977108627557755,
      -0.015094831585884094,
      0.06573543697595596,
      0.03899318724870682,
      0.0900740921497345,
      0.008541430346667767,
      -0.07519324868917465,
      -0.027751954272389412,
      -0.03965199366211891,
      -0.01108529418706894,
      -0.021967917680740356,
      -0.10648812353610992,
      0.026264136657118797,
      0.029587222263216972,
      -0.06577428430318832,
      0.07759904116392136,
      0.08871830254793167,
      -0.07171289622783661,
      0.10138620436191559,
      0.015305288136005402,
      -0.008074607700109482,
      0.013229197822511196,
      -0.05600396543741226,
      -0.014200415462255478,
      -0.024229466915130615,
      -0.043650876730680466,
      -0.09734101593494415,
      0.06474729627370834,
      -0.048366084694862366,
      -0.025128746405243874,
      -0.010568073019385338,
      -0.09496603906154633,
      -0.06588336825370789,
      0.036343324929475784,
      -0.07750318944454193,
      0.04576091468334198,
      0.0025254779029637575,
      -0.011898397468030453,
      -0.02796674333512783,
      0.014178140088915825,
      0.002687114058062434,
      -0.015179638750851154,
      0.0025467213708907366,
      -0.0047788782976567745,
      0.09977003186941147,
      0.06052842736244202,
      -0.05479344725608826,
      -0.00546256685629487,
      0.05146655812859535,
      -0.00020981734269298613,
      0.019176693633198738,
      0.11183138191699982,
      -0.0417633056640625,
      -0.032987575978040695,
      -0.01448326651006937,
      -0.009929598309099674,
      -0.010806461796164513,
      -0.056063972413539886,
      -0.048280417919158936,
      -0.04935428872704506,
      0.014581162482500076,
      -0.007510801311582327,
      0.05081288889050484,
      -0.0006052697426639497,
      0.07862924784421921,
      -0.0009457019623368979,
      -0.026492251083254814,
      0.07477910816669464,
      0.035487592220306396,
      -0.04527660831809044,
      -0.015865130349993706,
      -0.061610475182533264,
      0.03386850282549858,
      0.022214334458112717,
      0.10202775150537491,
      0.05979730188846588,
      -0.09709092229604721,
      0.07193557173013687,
      0.007343394216150045,
      0.0417681522667408,
      0.021090731024742126,
      0.04464546963572502,
      -0.1113661453127861,
      0.024753956124186516,
      0.09160567075014114,
      -0.04316239058971405,
      0.04184417426586151,
      -0.03571176156401634,
      -0.09671642631292343,
      0.07014279812574387,
      0.048784397542476654,
      0.025898925960063934,
      -0.05615643784403801,
      -0.09140286594629288,
      0.10894790291786194,
      0.02033553645014763,
      0.011198372580111027,
      -0.03241274878382683,
      -0.013602797873318195,
      -0.04756176099181175,
      -0.013416294939815998,
      -0.02326962724328041,
      0.007273361552506685,
      -0.049485884606838226,
      0.06383097171783447,
      0.014670287258923054,
      0.015768304467201233,
      -0.06777585297822952,
      0.01659240387380123,
      -0.060629311949014664,
      -0.017930269241333008,
      0.04130659997463226,
      -0.01992242969572544,
      -0.039851486682891846,
      -0.022565584629774094,
      0.024252746254205704,
      -0.031113620847463608,
      0.0021796394139528275,
      -0.026038218289613724,
      0.02774391882121563,
      0.03715795278549194,
      0.11344307661056519,
      -0.002043064683675766,
      0.00148606242146343,
      -0.007680482231080532,
      -0.05549964681267738,
      0.009942456148564816,
      -0.013435977511107922,
      -0.024983860552310944,
      -0.0199662446975708,
      -0.05143270641565323,
      0.045723117887973785,
      0.013756144791841507,
      0.06066150963306427,
      0.0028785117901861668,
      -0.07043306529521942,
      0.05458706244826317,
      0.03431019186973572,
      0.07851705700159073,
      0.026050319895148277,
      -0.10983376204967499,
      -0.04140552505850792,
      0.036250799894332886,
      -0.03942268341779709,
      0.07826388627290726,
      -0.06901124864816666,
      -0.01109465304762125,
      -0.038115035742521286,
      0.04099085181951523,
      0.049701374024152756,
      0.057648446410894394,
      -0.05331362038850784,
      0.02654738537967205,
      -0.013610144145786762,
      -0.06462922692298889,
      -0.002019129227846861,
      0.028536522760987282,
      -0.044621728360652924,
      -0.024571998044848442,
      -0.04894676432013512,
      0.06820720434188843,
      0.07603771984577179,
      0.012017986737191677,
      0.01102324016392231,
      -0.0452706478536129,
      -0.03378109261393547,
      -0.03155785799026489,
      0.008450995199382305,
      0.05417225509881973,
      -0.011736365966498852,
      -0.029720457270741463,
      -0.01808502897620201,
      -0.021499089896678925,
      -0.04288381338119507,
      -0.025894688442349434,
      0.051297470927238464,
      -0.047587763518095016,
      0.03548473119735718,
      0.03617507591843605,
      0.025877486914396286,
      -0.03879714384675026,
      -0.038912899792194366,
      -0.018231486901640892,
      -0.02474554255604744,
      -0.014005031436681747,
      -0.009696309454739094,
      -0.03462927043437958,
      -0.008858748711645603,
      -0.045979250222444534,
      0.037068575620651245,
      0.07181421667337418,
      -0.02195717953145504,
      -0.022778356447815895,
      0.028614075854420662,
      -0.08200350403785706,
      -0.02572924643754959,
      -0.0075664217583835125,
      0.02907073125243187,
      0.0008902937406674027,
      -0.0048568835482001305,
      0.0314733125269413,
      -0.012633542530238628,
      0.011422996409237385,
      -0.009851769544184208,
      -0.06046518683433533,
      -0.03486669436097145,
      0.07884705811738968,
      -0.029801033437252045,
      0.00843803770840168,
      0.06377854943275452,
      0.07995191961526871,
      0.003208460519090295,
      -0.05449194461107254,
      0.0144426841288805,
      0.023192785680294037,
      0.07111053913831711,
      -0.1546422839164734,
      -0.005425082519650459,
      -0.0067659709602594376,
      -0.013717605732381344,
      0.00746751856058836,
      0.007602564059197903,
      0.06406565010547638,
      0.06762579083442688,
      0.05240520462393761,
      -0.06612155586481094,
      0.053228769451379776,
      -0.006086751818656921,
      -0.017027147114276886,
      0.04775962978601456,
      0.007725215516984463,
      0.037413448095321655,
      -0.022009316831827164,
      0.06616295129060745,
      -0.0006208496051840484,
      -0.03342548757791519,
      0.02708871103823185,
      0.023935193195939064,
      -0.07648985832929611,
      0.03890567645430565,
      0.03157136216759682,
      -0.047231897711753845,
      0.050250932574272156,
      -0.048076726496219635,
      0.0006338173989206553,
      0.05157873034477234,
      -0.10712829977273941,
      0.012690397910773754,
      0.04277645796537399,
      -0.06692595779895782,
      0.008054506033658981,
      -0.0022826476488262415,
      0.004959419369697571,
      -0.01981331594288349,
      0.09600825607776642,
      0.03938744589686394,
      -0.021578200161457062,
      -0.04480555281043053,
      0.06004824861884117,
      -0.050039030611515045,
      -0.00182597734965384,
      -0.031241994351148605,
      0.060271114110946655,
      0.01081926841288805,
      0.12195499241352081,
      -0.018175631761550903,
      0.048817578703165054,
      -0.009469927288591862,
      0.03085101768374443,
      -0.0077581810764968395,
      -0.02633829601109028,
      0.017107687890529633,
      0.028701748698949814,
      0.0022488415706902742,
      -0.008120417594909668,
      0.09216824918985367,
      -0.026820598170161247
    ],
    [
      -0.00984359160065651,
      -0.005220686085522175,
      -0.09886252880096436,
      0.010597610846161842,
      0.025458449497818947,
      -0.05293796956539154,
      0.03255775570869446,
      -0.025970235466957092,
      0.03135179355740547,
      -0.04625970870256424,
      -0.05205322429537773,
      -0.08092846721410751,
      -0.0034776029642671347,
      -0.0975339487195015,
      0.03558005765080452,
      0.07816740870475769,
      0.014620914123952389,
      -0.0147521011531353,
      -0.01630052737891674,
      0.019186366349458694,
      -0.006045050453394651,
      0.028362248092889786,
      0.07934251427650452,
      -0.04889191687107086,
      -0.0044404566287994385,
      0.03959720954298973,
      0.0018798814853653312,
      0.008861981332302094,
      -0.008867330849170685,
      0.04482751712203026,
      0.006977185141295195,
      -0.036253441125154495,
      0.059750884771347046,
      0.014931485056877136,
      -0.01766080968081951,
      -0.028010860085487366,
      0.0011953662615269423,
      -0.0017546176677569747,
      -0.05290188640356064,
      0.00025494812871329486,
      0.0770680382847786,
      0.03915110230445862,
      0.022624818608164787,
      -0.025629788637161255,
      0.06650637835264206,
      0.034660786390304565,
      0.0704939141869545,
      0.07999266684055328,
      0.061372000724077225,
      -0.018212562426924706,
      0.04400693252682686,
      0.033129557967185974,
      0.030510880053043365,
      -0.08063674718141556,
      -0.05688199773430824,
      0.08509383350610733,
      -0.012818369083106518,
      -0.09087519347667694,
      0.006734021008014679,
      -0.03432777523994446,
      -0.02816830389201641,
      -0.07715379446744919,
      0.036287322640419006,
      -0.04930363968014717,
      0.0058486489579081535,
      -0.028392063453793526,
      0.07455115020275116,
      -0.05050920695066452,
      -0.05360446125268936,
      0.033320557326078415,
      -0.012594575993716717,
      -0.03164735063910484,
      0.030074790120124817,
      0.02611437253654003,
      -0.027260370552539825,
      -0.0026865005493164062,
      0.039080943912267685,
      -0.10581877827644348,
      0.05452217161655426,
      -0.03427451103925705,
      -0.0004049231647513807,
      -0.061089444905519485,
      0.04487135633826256,
      -0.014390614815056324,
      0.03010249137878418,
      -0.013889441266655922,
      0.00014761656348127872,
      -0.046204712241888046,
      -0.04469001665711403,
      -0.027631090953946114,
      -0.01531509030610323,
      -0.042735181748867035,
      0.06209757924079895,
      -0.08184266090393066,
      0.0024454842787235975,
      0.031145939603447914,
      -0.019707489758729935,
      -0.0048704203218221664,
      0.02023555524647236,
      0.09824831783771515,
      -0.02960316278040409,
      -0.07864971458911896,
      -0.01255187951028347,
      -0.001327737350948155,
      -0.006846671458333731,
      -0.0025504736695438623,
      0.004479826427996159,
      0.05326725170016289,
      -0.019440487027168274,
      -0.019009124487638474,
      -0.03617602586746216,
      0.0002524407464079559,
      0.015467303805053234,
      0.02924015372991562,
      0.05225837603211403,
      0.006037810351699591,
      -0.009781994856894016,
      -0.022909071296453476,
      -0.06162877380847931,
      0.06909216940402985,
      -0.05457314848899841,
      -0.031993914395570755,
      0.05521576106548309,
      0.05383467674255371,
      -0.08398031443357468,
      0.052452269941568375,
      0.03960532322525978,
      -0.06294243037700653,
      0.09606094658374786,
      0.03517543897032738,
      0.01095733605325222,
      0.042374562472105026,
      0.01651354692876339,
      0.03830045089125633,
      0.052043698728084564,
      0.0010238968534395099,
      -0.0006093577248975635,
      0.09530427306890488,
      0.07844637334346771,
      -0.003113821614533663,
      -0.0495668388903141,
      -0.05294347554445267,
      0.011410542763769627,
      -0.05662095546722412,
      0.028697896748781204,
      -0.047724418342113495,
      0.03708677738904953,
      0.06254319846630096,
      -0.036044005304574966,
      -0.055382758378982544,
      0.04246381297707558,
      0.05277992784976959,
      -0.012023587711155415,
      -0.04776034876704216,
      0.03663741797208786,
      -0.02925286814570427,
      -0.03168908506631851,
      -0.07430049031972885,
      0.03456735238432884,
      -0.006255356129258871,
      -0.00679875398054719,
      0.020100519061088562,
      0.03939796984195709,
      -0.03521639481186867,
      0.030713053420186043,
      -0.006299306638538837,
      -0.054848767817020416,
      -0.029068484902381897,
      0.01946653425693512,
      0.029741112142801285,
      -0.043391596525907516,
      0.03174994885921478,
      -0.05050487816333771,
      -0.032159436494112015,
      -0.03666386008262634,
      0.07085230946540833,
      -0.02630208246409893,
      -0.026141580194234848,
      -0.030560769140720367,
      0.000832349294796586,
      0.049655262380838394,
      -0.05872137099504471,
      0.009881445206701756,
      -0.02035868540406227,
      -0.009058146737515926,
      -0.06693676114082336,
      0.04988272860646248,
      -0.06134364753961563,
      0.09835678339004517,
      0.07478178292512894,
      -0.05664064362645149,
      -0.02227948233485222,
      -0.010101497173309326,
      0.09025052189826965,
      0.016896532848477364,
      -0.011296612210571766,
      -0.08348588645458221,
      0.015772217884659767,
      -0.0002641041064634919,
      0.015073553659021854,
      0.02925528772175312,
      0.024454543367028236,
      -0.03553121164441109,
      0.02476911060512066,
      0.019390344619750977,
      -0.013744642026722431,
      0.04558409005403519,
      -0.01907430961728096,
      0.0710122138261795,
      -0.008183339610695839,
      0.03937770426273346,
      0.06243875250220299,
      0.01251112949103117,
      -0.08977001160383224,
      -0.045350730419158936,
      0.009609228000044823,
      0.02921215444803238,
      0.04841569811105728,
      -0.040262967348098755,
      0.04345424473285675,
      -0.008593115024268627,
      0.009175969287753105,
      -0.01626015640795231,
      0.06678423285484314,
      0.001891658641397953,
      0.023228703066706657,
      -0.011266497895121574,
      -0.01957610808312893,
      0.015895657241344452,
      0.02506595477461815,
      0.0166705921292305,
      -0.038736723363399506,
      0.06843193620443344,
      -0.019301611930131912,
      0.0324290469288826,
      -0.018319929018616676,
      0.05352228507399559,
      -0.0574793741106987,
      0.00801405031234026,
      0.018128782510757446,
      0.00217753485776484,
      -0.007290943060070276,
      0.04709111899137497,
      0.028106095269322395,
      -0.05837218463420868,
      0.020451268181204796,
      0.05263097956776619,
      -0.04583050310611725,
      -0.01227393839508295,
      -0.04132193699479103,
      0.012925313785672188,
      0.028878314420580864,
      0.054512836039066315,
      -0.07140297442674637,
      -0.013985750265419483,
      -0.06328233331441879,
      0.008430606685578823,
      0.009293504990637302,
      -0.008874584920704365,
      0.016241639852523804,
      -0.006587189622223377,
      -0.023135412484407425,
      -0.043000444769859314,
      -0.04338977858424187,
      -0.04059982672333717,
      -0.018226874992251396,
      0.02809799462556839,
      -0.08151856064796448,
      0.00878466572612524,
      0.05359601974487305,
      -0.034904845058918,
      -0.01616576872766018,
      -0.04668997973203659,
      0.014049751684069633,
      -0.01924506202340126,
      -0.041502419859170914,
      0.09113840758800507,
      0.010862658731639385,
      -0.07730164378881454,
      0.07783825695514679,
      0.02803940139710903,
      0.01710604876279831,
      0.024943727999925613,
      -0.013956335373222828,
      -0.006074900738894939,
      0.06144171953201294,
      0.011299832724034786,
      0.0012304080883041024,
      -0.028119830414652824,
      0.07488802075386047,
      -0.01267910934984684,
      0.0473957397043705,
      0.021395158022642136,
      0.024974463507533073,
      -0.015047354623675346,
      -0.032380446791648865,
      -0.025761686265468597,
      0.08162631094455719,
      -0.04185670614242554,
      0.08741652220487595,
      -0.012181434780359268,
      0.072486013174057,
      0.014579354785382748,
      0.013036017306149006,
      0.01517210341989994,
      -0.011966191232204437,
      0.028965376317501068,
      -0.06740187853574753,
      -0.01746094413101673,
      -0.008915035985410213,
      0.08437718451023102,
      0.026531554758548737,
      0.0006315460777841508,
      -0.031737446784973145,
      -0.012196161784231663,
      0.009876606985926628,
      -0.012339331209659576,
      -0.08174489438533783,
      -0.07571297138929367,
      0.03225504606962204,
      0.05398818105459213,
      0.0018143412889912724,
      0.0017985935555770993,
      0.00626838905736804,
      0.014810062944889069,
      0.01815747283399105,
      0.02904978021979332,
      -0.033398762345314026,
      0.0505269393324852,
      -0.020761914551258087,
      -0.02544303424656391,
      -0.09570680558681488,
      -0.029975958168506622,
      -0.04014074429869652,
      -0.06909804791212082,
      -0.06550491601228714,
      -0.08847667276859283,
      0.04914592579007149,
      -0.03793100640177727,
      -0.08609192073345184,
      0.04398388788104057,
      -0.05501467362046242,
      -0.008654494769871235,
      -0.06651915609836578,
      0.04676416143774986,
      0.09983546286821365,
      -0.049957700073719025,
      -0.05438599735498428,
      -0.056356094777584076,
      -0.03582948446273804,
      0.013269349932670593,
      0.0417415052652359,
      0.024774989113211632,
      0.005405663046985865,
      -0.01427490171045065,
      0.06487715989351273,
      -0.08454428613185883,
      -0.021996499970555305,
      0.03829405456781387,
      0.04303749278187752,
      -0.0012855244567617774,
      -0.06773598492145538,
      0.030401764437556267,
      -0.0433734692633152,
      -0.07695137709379196,
      -0.03227908909320831,
      0.07560817152261734,
      -0.012207552790641785,
      -0.09622208774089813,
      0.08362318575382233,
      -0.06429646164178848,
      -0.056411128491163254,
      -0.06888557970523834,
      0.08226791024208069,
      0.061352264136075974,
      0.007866946049034595,
      0.036638300865888596,
      -0.016955360770225525,
      0.03357706591486931,
      0.044789064675569534,
      0.0019436924485489726,
      -0.04460880532860756,
      0.008650385774672031,
      0.051352716982364655,
      0.029112067073583603,
      -0.02940763160586357,
      0.06993552297353745,
      0.06557835638523102,
      0.03589211404323578,
      0.02741328440606594,
      -0.07612694054841995,
      0.05972724035382271,
      0.0083057451993227,
      -0.019001411274075508,
      0.059136006981134415,
      0.04937881976366043,
      -0.010192451067268848,
      0.031057003885507584,
      0.012672756798565388,
      -0.07094314694404602,
      -0.05899268761277199,
      0.008478951640427113,
      0.0524015910923481,
      -0.05328952148556709,
      -0.04284115880727768,
      0.006436644587665796,
      0.011638680472970009,
      0.08361166715621948,
      -0.05825319141149521,
      0.03754131868481636,
      -0.012753165327012539,
      0.05987933650612831,
      0.014081996865570545,
      -0.0067888181656599045,
      0.0351843498647213,
      0.023159073665738106,
      -0.021164119243621826,
      0.007139433640986681,
      0.09945842623710632,
      0.06763070821762085,
      0.03367256373167038,
      -0.009303000755608082,
      -0.03370245546102524,
      0.006441488862037659,
      0.005295614246279001,
      0.016293223947286606,
      -0.0625867173075676,
      0.019838912412524223,
      -0.05406585708260536,
      0.02971397154033184,
      -0.052042532712221146,
      0.06706951558589935,
      -0.033427268266677856,
      0.0501755028963089,
      0.015561830252408981,
      -0.00011123183503514156,
      0.0471886582672596,
      0.0631711333990097,
      -0.040259022265672684,
      -0.04236048460006714,
      0.019774839282035828,
      -0.0380636602640152,
      0.08205700665712357,
      0.03987135365605354,
      0.019433435052633286,
      -0.08824294805526733,
      0.013131438754498959,
      0.05550456792116165,
      -0.007148267235606909,
      -0.013246161863207817,
      0.01661713793873787,
      0.0591438002884388,
      0.0069487569853663445,
      0.0678575336933136,
      0.04276053234934807,
      -0.012328396551311016,
      -0.037350401282310486,
      -0.011222956702113152,
      -0.015400335192680359,
      0.034883882850408554,
      0.06997188925743103,
      0.04456528648734093,
      0.03636239841580391,
      0.0459938682615757,
      0.02673005498945713,
      0.07666157931089401,
      0.021212611347436905,
      0.03304694965481758,
      0.11977950483560562,
      0.06846966594457626,
      0.016151029616594315,
      -0.05365307629108429,
      -0.029267288744449615,
      0.01531706191599369,
      -0.028876038268208504,
      -0.08260742574930191,
      0.0578567273914814,
      0.05939376726746559,
      -0.014887522906064987,
      -0.015980180352926254,
      -0.04709576442837715,
      -0.001276814378798008,
      -0.06849280744791031,
      0.04856722801923752,
      -0.008954196237027645,
      0.00418128864839673,
      0.020405469462275505,
      -0.09967872500419617,
      -0.05360400304198265,
      0.03942753002047539,
      -0.023428183048963547,
      -0.03993179276585579,
      0.0045206183567643166,
      0.028375402092933655,
      0.021537339314818382,
      0.04957588016986847,
      -0.05052068829536438,
      -0.00015296517813112587,
      -0.012349287047982216,
      -0.03348555043339729,
      0.018444353714585304,
      0.028762446716427803,
      0.0014300645561888814
    ],
    [
      -0.04985671490430832,
      -0.026898616924881935,
      -0.042367737740278244,
      0.021572088822722435,
      -0.009588763117790222,
      0.0030709351412951946,
      0.032312557101249695,
      -0.0036924355663359165,
      0.04756318777799606,
      0.005585379432886839,
      0.03181304410099983,
      0.013096202164888382,
      -0.06969385594129562,
      0.007673631887882948,
      0.04397127404808998,
      -0.05608140677213669,
      -0.05958728492259979,
      0.007769826799631119,
      0.029720231890678406,
      -0.010893965139985085,
      -0.003954468294978142,
      0.02063777856528759,
      0.037334371358156204,
      0.03426189348101616,
      -0.016990870237350464,
      0.019950876012444496,
      -0.03911050036549568,
      0.04638342931866646,
      0.025884978473186493,
      -0.04184187203645706,
      -0.05193842202425003,
      0.003407856682315469,
      -0.03714543208479881,
      0.06396465748548508,
      0.03419342264533043,
      -0.057363010942935944,
      0.0720519870519638,
      0.05449436604976654,
      -0.012756927870213985,
      -0.09926237165927887,
      -0.018865304067730904,
      -0.023826638236641884,
      0.02436058223247528,
      0.023858072236180305,
      -0.04968809708952904,
      -0.03182155266404152,
      -0.00438021682202816,
      -0.03872891515493393,
      0.06652160733938217,
      -0.021644219756126404,
      0.03467585891485214,
      0.03984985500574112,
      -0.051625948399305344,
      -0.002904412103816867,
      -0.007486972492188215,
      0.004079666920006275,
      -0.05014181137084961,
      0.039551991969347,
      -0.047633443027734756,
      -0.06396665424108505,
      -0.0256813932210207,
      -0.05460282787680626,
      0.010748025961220264,
      -0.027539966627955437,
      0.018039416521787643,
      0.030533455312252045,
      0.0776354968547821,
      -0.005841179750859737,
      -0.0008550815400667489,
      -0.018850982189178467,
      -0.013778749853372574,
      -0.05335665121674538,
      0.032231301069259644,
      0.020685864612460136,
      0.001789796631783247,
      -0.013261733576655388,
      0.044524047523736954,
      0.0008025628631003201,
      0.060176361352205276,
      0.011439813300967216,
      0.06705120950937271,
      -0.057575345039367676,
      0.05782971903681755,
      0.07465969771146774,
      0.0477384589612484,
      0.0593574196100235,
      0.054568808525800705,
      0.03869498148560524,
      0.01612713746726513,
      0.0581522062420845,
      -0.017732009291648865,
      0.04183768853545189,
      0.04123176634311676,
      0.09209222346544266,
      0.017188819125294685,
      0.006449280772358179,
      0.04870966076850891,
      -0.06617239117622375,
      -0.036537833511829376,
      0.021424567326903343,
      -0.007475749123841524,
      0.005305812694132328,
      0.019486498087644577,
      -0.008669028989970684,
      0.01834559626877308,
      -0.024188240990042686,
      -0.0013143037213012576,
      0.012810812331736088,
      -0.010705760680139065,
      0.0038840272463858128,
      -0.004926667083054781,
      0.0006102331099100411,
      -0.10069241374731064,
      -0.021703552454710007,
      -0.013118969276547432,
      -0.05803975462913513,
      -0.024280264973640442,
      0.025276467204093933,
      0.03620440885424614,
      -0.06074811890721321,
      0.04933324083685875,
      0.07055212557315826,
      0.007687509059906006,
      -0.07078579813241959,
      0.02280486933887005,
      -0.07402292639017105,
      0.012218168936669827,
      -0.00799122266471386,
      -0.14571548998355865,
      0.03179970383644104,
      -0.05021873116493225,
      -0.03296629339456558,
      0.04099148139357567,
      0.003325626952573657,
      -0.00022833174443803728,
      0.00016997936472762376,
      0.08818747103214264,
      -0.04543258249759674,
      -0.03156990185379982,
      0.037559084594249725,
      -0.013368560001254082,
      -0.10453420132398605,
      -0.052061665803194046,
      -0.010124360211193562,
      0.011489422991871834,
      -0.046865545213222504,
      0.052136559039354324,
      -0.06348461657762527,
      -0.029224785044789314,
      0.034146130084991455,
      -0.0666806772351265,
      -0.021830379962921143,
      -0.017188778147101402,
      0.00882184598594904,
      0.06605763733386993,
      -0.023008331656455994,
      -0.06408308446407318,
      0.027527425438165665,
      -0.0033391404431313276,
      -0.05577002093195915,
      -0.008658475242555141,
      0.011141584254801273,
      -0.03989158198237419,
      -0.11047503352165222,
      0.0752338171005249,
      0.058324720710515976,
      -0.0358244962990284,
      0.11008399724960327,
      -0.010521449148654938,
      -0.037380099296569824,
      -0.0042687575332820415,
      0.04250752180814743,
      -0.009310378693044186,
      -0.02263393998146057,
      0.025164686143398285,
      -0.08442714065313339,
      -0.0025220911484211683,
      0.09113994985818863,
      -0.029478128999471664,
      0.10491017252206802,
      0.014567825943231583,
      -0.021103767678141594,
      -0.0601898618042469,
      -0.006752645131200552,
      -0.02043062448501587,
      -0.05991992726922035,
      0.0006849969504401088,
      0.029692810028791428,
      -0.0540991947054863,
      -0.06963463127613068,
      0.026381436735391617,
      -0.1208055168390274,
      0.02756214141845703,
      -0.02154754288494587,
      0.004819781519472599,
      -0.05225609615445137,
      0.05375292897224426,
      -0.04405377805233002,
      0.020170850679278374,
      0.019704651087522507,
      -0.041754696518182755,
      0.011162053793668747,
      0.014219940640032291,
      0.06449251621961594,
      0.05667159706354141,
      0.004815975204110146,
      0.009763347916305065,
      0.011532544158399105,
      -0.06982061266899109,
      0.017843861132860184,
      0.0025152969174087048,
      -0.027281368151307106,
      0.04465257003903389,
      -0.03537515923380852,
      -0.01788386143743992,
      -0.017487430945038795,
      0.022541599348187447,
      0.04494771361351013,
      0.01824270747601986,
      -0.026020033285021782,
      -0.014388775452971458,
      0.06201085448265076,
      -0.010857890360057354,
      0.007554508745670319,
      0.07770482450723648,
      0.011523080058395863,
      0.04737455025315285,
      0.014876618050038815,
      0.006804333068430424,
      0.0556086041033268,
      -0.05714932829141617,
      -0.06438156217336655,
      0.015198164619505405,
      -0.011558983474969864,
      -0.03270799294114113,
      0.03561950474977493,
      0.0017868042923510075,
      -0.009694844484329224,
      0.02248949185013771,
      -0.07713320851325989,
      -0.06683319807052612,
      -0.004213971551507711,
      0.045713406056165695,
      -0.035172127187252045,
      -0.0031152490992099047,
      0.01636384427547455,
      -0.086555115878582,
      -0.0016545180696994066,
      -0.031756311655044556,
      -0.03736574575304985,
      0.03289521113038063,
      -0.0068665845319628716,
      -0.00156118453014642,
      0.04544055834412575,
      -0.09950460493564606,
      0.03789905831217766,
      0.03399766981601715,
      -0.00906066782772541,
      0.05672474578022957,
      0.03817933797836304,
      -0.01624835655093193,
      0.09283547848463058,
      0.087895967066288,
      0.004578787833452225,
      0.05241299420595169,
      0.040447335690259933,
      -0.005396312568336725,
      0.009869455359876156,
      0.09300485253334045,
      -0.06804300844669342,
      0.06290402263402939,
      -0.003590348409488797,
      0.022413620725274086,
      0.023451536893844604,
      -0.04971516132354736,
      -0.000865939655341208,
      0.03789129480719566,
      0.02718530409038067,
      -0.03923226520419121,
      0.017443349584937096,
      0.08622884005308151,
      -0.012270924635231495,
      0.017206989228725433,
      -0.009802918881177902,
      -0.02123132161796093,
      0.03800258785486221,
      0.02023598738014698,
      0.07005362212657928,
      -0.04579276964068413,
      -0.013292264193296432,
      -0.025988401845097542,
      -0.02842472679913044,
      0.017984548583626747,
      0.024922175332903862,
      -0.00974123552441597,
      -0.02475137822329998,
      -0.01248159259557724,
      0.057719096541404724,
      -0.010123293846845627,
      0.02391418069601059,
      0.003484307788312435,
      0.08377274125814438,
      -0.03516046702861786,
      -0.04571135342121124,
      0.08083795756101608,
      0.018107393756508827,
      -0.04117652028799057,
      -0.00855837669223547,
      0.033082325011491776,
      -0.007768618408590555,
      -0.004898126237094402,
      0.021506739780306816,
      -0.0018127193907275796,
      0.04017767682671547,
      0.01288655772805214,
      -0.00438461359590292,
      0.025269363075494766,
      0.052902743220329285,
      -0.009119926020503044,
      0.06660835444927216,
      -0.032167207449674606,
      0.036595579236745834,
      -0.05255000665783882,
      -0.018351642414927483,
      0.06967272609472275,
      -0.09056682139635086,
      0.025873370468616486,
      -0.08185192942619324,
      0.026569848880171776,
      0.017417196184396744,
      0.04425520822405815,
      -0.0703221932053566,
      0.05305570363998413,
      -0.08927217870950699,
      0.05941301956772804,
      0.03049987554550171,
      -0.01151924766600132,
      -0.09372178465127945,
      0.03169465810060501,
      -0.030928978696465492,
      0.04899680241942406,
      0.011140521615743637,
      0.01203218474984169,
      -0.024911725893616676,
      0.1110694408416748,
      0.019270578399300575,
      -0.006835208274424076,
      0.022640565410256386,
      -0.03943216800689697,
      0.0072979857213795185,
      -0.002599735977128148,
      -0.03261362761259079,
      -0.0007206392474472523,
      0.0700761154294014,
      0.039935775101184845,
      -0.048157088458538055,
      0.02781173400580883,
      0.01888441853225231,
      -0.036963626742362976,
      0.03878223896026611,
      -0.07057361304759979,
      -0.02611129730939865,
      0.027907274663448334,
      0.007781981024891138,
      0.02290209010243416,
      0.025218965485692024,
      -0.014879091642796993,
      -0.02961643412709236,
      0.005198393017053604,
      0.047490302473306656,
      -0.06537442654371262,
      -0.009308765642344952,
      -0.033935677260160446,
      0.054474398493766785,
      0.05837766453623772,
      -0.04194844141602516,
      -0.023490259423851967,
      0.04231547936797142,
      -0.04434286057949066,
      0.014696149155497551,
      -0.1320216804742813,
      0.0034493966959416866,
      -0.04727942496538162,
      -0.056661371141672134,
      0.02368747629225254,
      -0.048382434993982315,
      -0.06356102228164673,
      0.009523918852210045,
      0.042509809136390686,
      0.03057519532740116,
      9.426477845408954e-06,
      -0.03945566341280937,
      0.07139928638935089,
      -0.015998024493455887,
      0.062471501529216766,
      0.051993727684020996,
      -0.04502742737531662,
      0.07984882593154907,
      -0.03983869403600693,
      0.041347309947013855,
      -0.027800017967820168,
      -0.021347975358366966,
      0.017478903755545616,
      -0.05129177123308182,
      0.0060011460445821285,
      0.008123932406306267,
      0.05704626441001892,
      0.030477965250611305,
      0.029960161074995995,
      0.03189433366060257,
      0.012104611843824387,
      -0.008711942471563816,
      0.031160561367869377,
      -0.07988853007555008,
      -0.003251505084335804,
      -0.009366720914840698,
      0.00958964042365551,
      -0.00843003112822771,
      -0.10068295150995255,
      -0.03913794457912445,
      -0.010215170681476593,
      -0.0366130992770195,
      -0.01840021461248398,
      -0.08229020237922668,
      -0.009919428266584873,
      -0.019285427406430244,
      -0.04361719638109207,
      0.04498596489429474,
      0.06712783128023148,
      0.07277072221040726,
      0.0178262609988451,
      -0.055645860731601715,
      0.052472010254859924,
      -0.038709454238414764,
      -0.052839603275060654,
      -0.04407975450158119,
      -0.03854399919509888,
      0.06214596703648567,
      -0.04717981442809105,
      -0.07569791376590729,
      -0.003173100994899869,
      -0.03705386817455292,
      -0.04125556722283363,
      -0.033301565796136856,
      0.0867903083562851,
      -0.013299638405442238,
      0.006997240241616964,
      0.006868902128189802,
      0.021666936576366425,
      -0.0872465968132019,
      0.060349129140377045,
      -0.05272799730300903,
      -0.014368070289492607,
      -0.014968065544962883,
      0.06920196115970612,
      -0.04964717850089073,
      -0.02244637906551361,
      -0.031047474592924118,
      -0.0005902733537368476,
      -0.022812495008111,
      -0.03739088401198387,
      -0.08609672635793686,
      -0.04039764776825905,
      0.016630979254841805,
      -0.01300995796918869,
      0.010766380466520786,
      -0.0088518550619483,
      0.00990215688943863,
      0.03377804532647133,
      -0.06684219092130661,
      0.05126934498548508,
      -0.015848645940423012,
      -0.03349749371409416,
      0.032957836985588074,
      0.037967272102832794,
      -0.07029137015342712,
      0.03131818026304245,
      -0.006453757639974356,
      0.027683760970830917,
      0.021232806146144867,
      0.07219091057777405,
      -0.04771321639418602,
      -0.04510875418782234,
      -0.034661147743463516,
      0.012211155146360397,
      -0.016467368230223656,
      0.00392012856900692,
      -0.024219073355197906,
      -0.011656109243631363,
      -0.02050967328250408,
      -0.07696360349655151,
      -0.03316238895058632,
      0.028379768133163452,
      -0.04437787085771561,
      0.05223362520337105,
      -0.019375376403331757,
      -0.006226829718798399,
      0.012291966006159782,
      -0.0268195029348135,
      -0.0642663761973381,
      0.01697460375726223,
      0.01667090132832527,
      -0.03184228390455246
    ],
    [
      0.0012852454092353582,
      -0.07207853347063065,
      -0.015243874862790108,
      0.07019931823015213,
      -0.024905163794755936,
      -0.03056272491812706,
      -0.03463656082749367,
      0.04435425624251366,
      -0.0037963930517435074,
      0.0916731208562851,
      0.0444297231733799,
      0.005059872288256884,
      -0.008870101533830166,
      -0.0537339523434639,
      -0.09792612493038177,
      0.07797365635633469,
      -0.013555365614593029,
      -0.008830140344798565,
      0.01798876002430916,
      0.07324103266000748,
      -0.018147269263863564,
      0.030409982427954674,
      -0.01553493645042181,
      0.039609361439943314,
      0.03575029969215393,
      -0.04537244886159897,
      0.015726344659924507,
      0.024801794439554214,
      0.05224199593067169,
      -0.015431776642799377,
      0.06766974925994873,
      0.027409683912992477,
      0.011083532124757767,
      0.03221430629491806,
      0.07648643851280212,
      -0.06028405949473381,
      0.014516109600663185,
      -0.05271906405687332,
      -0.06735814362764359,
      -0.050364550203084946,
      -0.04535206779837608,
      -0.007945507764816284,
      -0.07430525124073029,
      -0.008424283005297184,
      -0.04963861405849457,
      -0.020788051187992096,
      0.051801104098558426,
      0.08747350424528122,
      0.044080182909965515,
      0.009522577747702599,
      -0.0053559294901788235,
      0.01278470829129219,
      0.07987809926271439,
      0.002616662299260497,
      -0.09080258011817932,
      -0.015555198304355145,
      -0.013721421360969543,
      0.011044706217944622,
      0.029084287583827972,
      0.008069073781371117,
      -0.04308300465345383,
      -0.0204376969486475,
      -0.003331870539113879,
      -0.054804395884275436,
      0.007690641097724438,
      0.0026236700359731913,
      0.00735386973246932,
      -0.005670760292559862,
      0.022766297683119774,
      0.06156010180711746,
      0.004178028088063002,
      -0.07192552089691162,
      0.04850718751549721,
      0.012406975962221622,
      0.015095686540007591,
      -0.023336265236139297,
      0.0006462299497798085,
      -0.026704091578722,
      -0.04084193706512451,
      -0.019960040226578712,
      0.016975954174995422,
      0.016177397221326828,
      -0.01642543449997902,
      -0.04995261877775192,
      -0.0184028223156929,
      0.05108476057648659,
      0.011950346641242504,
      0.005970034748315811,
      0.010877377353608608,
      -0.06270290166139603,
      0.04341685026884079,
      -0.061090193688869476,
      0.014017128385603428,
      -0.009657351300120354,
      -0.027633775025606155,
      -0.018080435693264008,
      -0.016498204320669174,
      -0.01977524720132351,
      0.022995131090283394,
      0.0027373277116566896,
      0.0008112721261568367,
      -0.028644803911447525,
      -0.06719937175512314,
      -0.07933174073696136,
      0.11486119031906128,
      -0.00731304008513689,
      0.009184831753373146,
      -0.02639981545507908,
      -0.021444225683808327,
      -0.017679715529084206,
      -0.028779588639736176,
      -0.02168121002614498,
      -0.04576458781957626,
      0.0076193539425730705,
      -0.068177230656147,
      -0.03414202854037285,
      -0.019591929391026497,
      0.053986597806215286,
      0.026711324229836464,
      0.00355585222132504,
      0.09112600237131119,
      0.04820302873849869,
      0.028585046529769897,
      -0.04838886484503746,
      -0.002235172549262643,
      -0.05651580169796944,
      -0.0018795884680002928,
      -0.006846735253930092,
      -0.047935228794813156,
      -0.036531321704387665,
      0.0010845562210306525,
      -0.004097955301403999,
      -0.05819331482052803,
      -0.0112921092659235,
      0.029026787728071213,
      -0.02767268754541874,
      -0.029577912762761116,
      -0.009866219945251942,
      -0.01633453741669655,
      -0.0232973825186491,
      0.04214594513177872,
      0.02071124128997326,
      0.08673868328332901,
      0.0092468848451972,
      -0.12013676017522812,
      -0.0813550353050232,
      0.006816595792770386,
      -0.026394657790660858,
      0.07447753101587296,
      -0.03396206349134445,
      -0.09019742906093597,
      -0.09846161305904388,
      0.027801482006907463,
      0.037548959255218506,
      0.029203064739704132,
      0.00976389180868864,
      -0.02923721633851528,
      -0.10215577483177185,
      -0.08814938366413116,
      -0.0014625793555751443,
      -0.11085813492536545,
      -0.03406178951263428,
      0.01716371811926365,
      -0.06180427223443985,
      -0.018118301406502724,
      0.04722750931978226,
      0.02339865081012249,
      0.020968295633792877,
      -0.032421812415122986,
      -0.0066209109500050545,
      0.03163618966937065,
      -0.049093130975961685,
      0.09877945482730865,
      -0.004927376750856638,
      -0.11525052785873413,
      -0.01007260475307703,
      -0.0009153075516223907,
      0.05682029202580452,
      0.02652771957218647,
      0.04085709527134895,
      0.015206640586256981,
      0.10292285680770874,
      0.06253987550735474,
      0.03785070404410362,
      0.05358187481760979,
      -0.04951109364628792,
      -0.03820386901497841,
      -0.012445492669939995,
      0.04433760792016983,
      0.02109956555068493,
      -0.05755534768104553,
      -0.08810259401798248,
      -0.031181881204247475,
      -0.04156595095992088,
      -0.0017740573966875672,
      0.11177755147218704,
      -0.03852953016757965,
      -0.0031602298840880394,
      0.047689251601696014,
      -0.038706257939338684,
      -0.03680538013577461,
      -0.018936216831207275,
      0.03753313422203064,
      0.0859900712966919,
      -0.03084060549736023,
      0.02098451741039753,
      0.08193334937095642,
      -0.010478023439645767,
      -0.05852893739938736,
      0.04448138177394867,
      0.04267081245779991,
      0.021698368713259697,
      0.019186420366168022,
      -0.07537993043661118,
      -0.025593919679522514,
      0.07832609117031097,
      -0.01097695529460907,
      0.06335995346307755,
      -0.02300199121236801,
      0.094701386988163,
      0.016768405213952065,
      -0.043963994830846786,
      0.11466854065656662,
      -0.012134991586208344,
      -0.006783403921872377,
      -0.046947091817855835,
      0.025066128000617027,
      -0.001752884709276259,
      -0.002171679399907589,
      0.0027994567062705755,
      0.022525321692228317,
      0.027707280591130257,
      -0.10836739093065262,
      -0.03306584432721138,
      0.02259693294763565,
      -0.05785207077860832,
      -0.014125634916126728,
      -0.05954987555742264,
      -0.07704587280750275,
      -0.01677757501602173,
      0.0552046112716198,
      0.04015502333641052,
      -0.057980727404356,
      0.0812416598200798,
      0.0658920481801033,
      -0.01591271162033081,
      -0.006666150875389576,
      0.03718110918998718,
      0.047876156866550446,
      -0.018003053963184357,
      -0.052361972630023956,
      0.0009886862244457006,
      0.02892785519361496,
      0.009916779585182667,
      -0.03425243869423866,
      -0.017123470082879066,
      0.001421589870005846,
      0.01819576695561409,
      -0.06293261796236038,
      0.01576140895485878,
      0.050509288907051086,
      0.008764192461967468,
      0.002990684937685728,
      -0.02143988572061062,
      0.027464279904961586,
      0.02126581035554409,
      0.027070097625255585,
      0.04490406811237335,
      0.030604304745793343,
      -0.06236870959401131,
      0.06168629601597786,
      0.039633072912693024,
      0.043066371232271194,
      -0.024531714618206024,
      -0.030612947419285774,
      -0.028861209750175476,
      0.05256231874227524,
      0.015647457912564278,
      0.01735295169055462,
      0.02473246306180954,
      0.016110841184854507,
      0.043377093970775604,
      0.05386492982506752,
      0.03053900972008705,
      0.0024951789528131485,
      -0.031461358070373535,
      -0.035696305334568024,
      -0.03955981135368347,
      -0.06178080290555954,
      0.02356567792594433,
      -0.0708242878317833,
      0.0030791424214839935,
      -0.006215808913111687,
      0.05183294788002968,
      -0.012861286289989948,
      -0.06470407545566559,
      -0.035829685628414154,
      -0.04200537130236626,
      0.09493452310562134,
      -0.05187893286347389,
      0.02276100590825081,
      0.0500023178756237,
      0.07334157824516296,
      -0.05156436935067177,
      -0.019233746454119682,
      -0.0026344372890889645,
      -0.022911498323082924,
      -0.05335848778486252,
      -0.059430014342069626,
      0.02175954543054104,
      0.0010010219411924481,
      0.05531485751271248,
      0.004743064753711224,
      0.011280275881290436,
      -0.02220170758664608,
      0.006611058488488197,
      -0.08430646359920502,
      -0.047318343073129654,
      0.04670540988445282,
      -0.014193535782396793,
      -0.014656703919172287,
      -0.019453462213277817,
      0.04714255407452583,
      0.08784271776676178,
      0.03075004182755947,
      -0.035625290125608444,
      0.0433308407664299,
      -0.011114823631942272,
      0.02482045628130436,
      0.07923992723226547,
      -0.0023426515981554985,
      0.004892104305326939,
      -0.009756011888384819,
      0.02107715606689453,
      -0.0696241557598114,
      -0.0073335375636816025,
      0.026193728670477867,
      0.01602287031710148,
      0.04478093609213829,
      0.018711896613240242,
      -0.009018849581480026,
      -0.004236347042024136,
      0.05176537483930588,
      -0.04492679983377457,
      0.03310331702232361,
      0.05385364219546318,
      0.05386106297373772,
      0.08911190181970596,
      -0.04821370169520378,
      0.009633194655179977,
      -0.044221214950084686,
      0.012079085223376751,
      -0.012495668604969978,
      -0.06293442100286484,
      0.06679120659828186,
      0.020433573052287102,
      0.02020200341939926,
      -0.1026093065738678,
      -0.0559750460088253,
      -0.06756802648305893,
      0.01042126677930355,
      -0.04750318080186844,
      -0.03695644438266754,
      -0.0013896605232730508,
      0.034939683973789215,
      0.020820582285523415,
      0.05803287401795387,
      -0.0023912410251796246,
      -0.05705627426505089,
      -0.11187825351953506,
      0.01553917583078146,
      0.02027914486825466,
      0.07852915674448013,
      -0.029430434107780457,
      0.008762229233980179,
      -0.048694346100091934,
      -0.025787796825170517,
      0.05647461488842964,
      -0.07182854413986206,
      0.000547918607480824,
      -0.08300408720970154,
      -0.051291000097990036,
      -0.021610669791698456,
      0.003118753433227539,
      -0.05768710747361183,
      -0.034547653049230576,
      0.02409120835363865,
      0.0017303001368418336,
      0.04852230101823807,
      0.043696559965610504,
      -0.012635789811611176,
      -0.07106037437915802,
      -0.041729774326086044,
      0.1132788434624672,
      0.0709851086139679,
      0.05649103969335556,
      0.04363938048481941,
      -0.041426144540309906,
      0.05999258533120155,
      -0.02190445363521576,
      0.029923558235168457,
      -0.06267862021923065,
      -0.07366880774497986,
      -0.07236265391111374,
      -0.012599161826074123,
      0.09011868387460709,
      -0.04415500909090042,
      0.07433755695819855,
      0.0953890010714531,
      0.013398606330156326,
      0.0021351727191358805,
      0.02397949807345867,
      -0.005546897649765015,
      -0.052884791046381,
      -0.009859837591648102,
      -0.0018917396664619446,
      -0.018879421055316925,
      -0.05181705579161644,
      0.09260395169258118,
      -0.006280897650867701,
      -0.00041300192242488265,
      0.04547302797436714,
      -0.1220361515879631,
      -0.07050355523824692,
      -0.03929601237177849,
      0.06039042770862579,
      0.012765680439770222,
      -0.05429073050618172,
      0.011174424551427364,
      0.03346893936395645,
      -0.011492121033370495,
      0.02072734571993351,
      0.00435115210711956,
      0.0061369952745735645,
      0.013039616867899895,
      -0.0008651287062093616,
      -0.020578280091285706,
      -0.012006845325231552,
      0.036643318831920624,
      -0.07853373885154724,
      -0.04529675468802452,
      0.006289353594183922,
      0.02252081222832203,
      -0.00477207638323307,
      -0.021027646958827972,
      0.02534608356654644,
      0.022076232358813286,
      0.011133027262985706,
      0.068952277302742,
      0.0030240118503570557,
      -0.09714151918888092,
      -0.08046425879001617,
      -0.07750308513641357,
      0.019895754754543304,
      0.006020043510943651,
      0.08409436047077179,
      -0.03873506933450699,
      0.029643315821886063,
      -0.06276973336935043,
      -0.03267788514494896,
      0.020096272230148315,
      0.02498219721019268,
      -0.04960586503148079,
      -0.013975542970001698,
      -0.0658864825963974,
      -0.012052346020936966,
      0.09327443689107895,
      0.0037191009614616632,
      -0.027610190212726593,
      0.014701055362820625,
      0.030654411762952805,
      -0.03527989611029625,
      -0.02115589752793312,
      -0.07397925108671188,
      0.02268090844154358,
      -0.06532374024391174,
      0.08891972154378891,
      -0.008411305025219917,
      -0.03316282853484154,
      0.0015259821666404605,
      -0.0305044986307621,
      -0.030940815806388855,
      -0.02378932200372219,
      0.04792334511876106,
      -0.026297930628061295,
      0.003775162622332573,
      -0.0040449355728924274,
      0.0023349339608103037,
      0.03923394903540611,
      -0.032321855425834656,
      0.045040275901556015,
      -0.03579337149858475,
      0.01810424216091633,
      -0.045859429985284805,
      0.06730850040912628,
      0.09499888122081757,
      -0.061602894216775894,
      0.032475534826517105,
      0.0418526865541935,
      -0.013942422345280647,
      -0.020815305411815643,
      -0.04654821753501892,
      -0.04289998486638069
    ],
    [
      0.07500059902667999,
      -0.014329377561807632,
      0.04968138039112091,
      0.03182088956236839,
      -0.08230940252542496,
      0.013770369812846184,
      0.019904887303709984,
      0.030780348926782608,
      -0.017105666920542717,
      -0.05669167637825012,
      0.040276624262332916,
      0.003923800308257341,
      0.033607032150030136,
      -0.06906194239854813,
      -0.019681526347994804,
      -0.023933643475174904,
      -0.08566232025623322,
      0.03158781677484512,
      0.015466245822608471,
      -0.033288583159446716,
      0.046922191977500916,
      0.0067505002953112125,
      0.007716932334005833,
      -0.011221780441701412,
      0.06759519875049591,
      0.03797648102045059,
      0.055859293788671494,
      0.025250623002648354,
      -0.001672476762905717,
      -0.015711573883891106,
      -0.019923333078622818,
      -0.027568401768803596,
      0.06694049388170242,
      0.0014568769838660955,
      0.054145339876413345,
      0.06854131072759628,
      -0.0004505896649789065,
      -0.07662638276815414,
      -0.0011828712886199355,
      0.04966183751821518,
      0.10133592784404755,
      -0.09376248717308044,
      0.03236908093094826,
      0.07637374848127365,
      -0.08305332064628601,
      -0.052212197333574295,
      0.023976849392056465,
      0.04241018369793892,
      0.025311632081866264,
      0.04895755276083946,
      0.09628349542617798,
      0.03476156294345856,
      0.02954741381108761,
      0.07867023348808289,
      0.008389236405491829,
      0.06184595078229904,
      0.00018672127043828368,
      0.0299020204693079,
      0.009401087649166584,
      0.08152955770492554,
      0.014390682801604271,
      0.012361903674900532,
      -0.07283256947994232,
      -0.05898524075746536,
      -0.012960654683411121,
      0.032527804374694824,
      -0.004660273436456919,
      -0.02248062752187252,
      -0.009264021180570126,
      0.023806745186448097,
      0.033118970692157745,
      -0.04674393683671951,
      -0.023114755749702454,
      0.04967718943953514,
      0.04477423056960106,
      0.034702856093645096,
      -0.012765860185027122,
      -0.013410266488790512,
      0.093756303191185,
      -0.03179777041077614,
      0.0583091601729393,
      0.01679304614663124,
      -0.014216046780347824,
      0.02481083758175373,
      0.0064118108712136745,
      -0.037980008870363235,
      0.0006934350822120905,
      0.054207682609558105,
      -0.11317320913076401,
      0.08529344201087952,
      0.08389689028263092,
      -0.05208177492022514,
      0.012419769540429115,
      -0.005955131258815527,
      0.06771509349346161,
      0.0008492556517012417,
      -0.019249334931373596,
      0.01098706480115652,
      -0.005976198241114616,
      0.04626845568418503,
      0.056759413331747055,
      -0.029548030346632004,
      -0.004508247133344412,
      -0.002971475012600422,
      0.05555509403347969,
      0.03392740711569786,
      0.0547168031334877,
      0.14128901064395905,
      0.020454706624150276,
      0.010978172533214092,
      -0.0240153931081295,
      0.03967592492699623,
      -0.05862167477607727,
      -0.03288481384515762,
      0.06324431300163269,
      0.015245698392391205,
      0.0019762455485761166,
      0.02142629772424698,
      0.01958615891635418,
      -0.003590187756344676,
      0.008393103256821632,
      -0.010216339491307735,
      -0.09601642936468124,
      0.0290586706250906,
      -0.051577143371105194,
      0.010003023780882359,
      0.05380260571837425,
      -0.04563358798623085,
      -0.06059389188885689,
      0.04503895342350006,
      -0.024787049740552902,
      -0.09376899898052216,
      0.027608005329966545,
      -0.04346568509936333,
      -0.0672207772731781,
      0.005691228900104761,
      0.0013962207594886422,
      -0.012987526133656502,
      0.06956817954778671,
      -0.011594239622354507,
      -0.08773519098758698,
      -0.06136440485715866,
      -0.07357598096132278,
      -0.018337612971663475,
      -0.061391156166791916,
      -0.022564997896552086,
      0.046146128326654434,
      -0.00810050405561924,
      0.038936685770750046,
      -0.043236710131168365,
      -0.05051862448453903,
      0.037403058260679245,
      -0.050748854875564575,
      0.0549168661236763,
      0.002731572138145566,
      0.010406838729977608,
      -0.01756211370229721,
      -0.06793530285358429,
      0.017728809267282486,
      0.036369774490594864,
      0.006960245314985514,
      0.015244407579302788,
      -0.01405039057135582,
      -0.028987763449549675,
      0.05507383868098259,
      0.02231367863714695,
      0.07406695187091827,
      -0.03109949268400669,
      0.00017277755250688642,
      0.09723056852817535,
      -0.010251287370920181,
      0.02457433193922043,
      0.010468034073710442,
      0.03398654982447624,
      0.03465859219431877,
      0.01341105718165636,
      0.08633752167224884,
      0.027866270393133163,
      0.023764915764331818,
      -0.04069439694285393,
      0.005507652647793293,
      0.09976405650377274,
      0.02781655266880989,
      -0.04864661768078804,
      -0.07816296815872192,
      0.03315805643796921,
      0.07882632315158844,
      0.016927262768149376,
      0.029870813712477684,
      -0.03882839158177376,
      0.01360015943646431,
      0.01716819778084755,
      0.033890485763549805,
      0.049991168081760406,
      0.010910974815487862,
      -0.003709411481395364,
      0.017495203763246536,
      0.0017421800876036286,
      -0.0006834682426415384,
      0.007149230223149061,
      0.010023976676166058,
      0.0009472330566495657,
      -0.024835709482431412,
      0.024066684767603874,
      -0.04610131308436394,
      -0.06132306158542633,
      -0.021461157128214836,
      -0.04954377934336662,
      0.017431972548365593,
      -0.010362631641328335,
      -0.011532876640558243,
      -0.08834954351186752,
      0.01708286814391613,
      -0.02308760955929756,
      0.013373306952416897,
      0.08850658684968948,
      0.08033967018127441,
      -0.0555693693459034,
      0.07813587039709091,
      0.021595343947410583,
      0.008490931242704391,
      0.0121253477409482,
      0.057607874274253845,
      0.03990992158651352,
      0.06861576437950134,
      -0.009578901343047619,
      -0.029845450073480606,
      0.05526154115796089,
      0.007813292555510998,
      0.009301330894231796,
      -0.01721530221402645,
      0.033205095678567886,
      -0.028301430866122246,
      -0.005064640659838915,
      -0.10712839663028717,
      0.01874721236526966,
      0.041525550186634064,
      -0.0011705526849254966,
      0.005006297957152128,
      -0.028498487547039986,
      -0.002288327319547534,
      -0.00876622274518013,
      0.028769824653863907,
      0.0264879297465086,
      0.028873495757579803,
      0.03749436140060425,
      -0.0374625027179718,
      -0.11825908720493317,
      -0.0005749427946284413,
      0.02316334657371044,
      -0.08689387887716293,
      -0.07049132883548737,
      0.0035759806632995605,
      -0.008643986657261848,
      -0.032720159739255905,
      -0.025622006505727768,
      -0.03517543897032738,
      0.04069963097572327,
      0.039940327405929565,
      0.03672393038868904,
      0.03500301018357277,
      -0.002138962037861347,
      -0.07871288806200027,
      -0.013430432416498661,
      -0.03593967482447624,
      0.04357123747467995,
      0.07631012797355652,
      0.0529349148273468,
      -0.07768680900335312,
      -0.07802275568246841,
      -0.025010142475366592,
      -0.07267658412456512,
      0.026490800082683563,
      -0.009460028260946274,
      -0.05737324431538582,
      0.041182033717632294,
      0.0054949684999883175,
      0.002116213319823146,
      0.01780702732503414,
      0.02507621794939041,
      0.007571165915578604,
      -0.059397175908088684,
      0.012815320864319801,
      -0.11982550472021103,
      -0.039424896240234375,
      -0.0012475780677050352,
      -0.03348332643508911,
      0.05052072927355766,
      -0.004365877714008093,
      -0.02653064765036106,
      0.0342591218650341,
      0.07269182056188583,
      0.00877767987549305,
      -0.068520188331604,
      0.10057938098907471,
      0.08868269622325897,
      -2.570796641521156e-05,
      -0.07293698191642761,
      0.012583144940435886,
      0.04054903984069824,
      0.039341382682323456,
      0.01986394077539444,
      -0.006039551459252834,
      -0.030841518193483353,
      -0.050322726368904114,
      0.026926115155220032,
      0.06209053844213486,
      0.04870578274130821,
      0.022862885147333145,
      0.01871374063193798,
      0.02395039051771164,
      0.0569273978471756,
      -0.08827929198741913,
      0.041020575910806656,
      -0.0599532276391983,
      -0.023975715041160583,
      -0.018850313499569893,
      0.1002776175737381,
      0.005508174654096365,
      -0.0859735757112503,
      0.03408002480864525,
      0.006079662591218948,
      -0.0038506712298840284,
      0.024147270247340202,
      0.05362815409898758,
      0.018633656203746796,
      -0.05042733624577522,
      0.09848450124263763,
      -0.028075790032744408,
      -0.07727475464344025,
      0.010382091626524925,
      -0.025035446509718895,
      -0.036816854029893875,
      -0.019904466345906258,
      -0.01113036833703518,
      0.009607936255633831,
      0.07144748419523239,
      0.009608406573534012,
      -0.05601487681269646,
      0.04645845293998718,
      -0.08013201504945755,
      0.029902160167694092,
      -0.009981370531022549,
      0.02302207238972187,
      -0.0021772694308310747,
      0.010488885454833508,
      0.038595885038375854,
      -0.010115304030478,
      0.02306268736720085,
      0.06272661685943604,
      -0.02692621946334839,
      0.0341891385614872,
      0.03462749719619751,
      0.025005443021655083,
      0.031137466430664062,
      0.008316323161125183,
      0.004364900756627321,
      0.054654717445373535,
      -0.041723959147930145,
      0.0529511459171772,
      -0.03421378508210182,
      0.012643549591302872,
      -0.04346241056919098,
      0.059599172323942184,
      -0.01651313714683056,
      -0.009090090170502663,
      0.02977857179939747,
      0.003405075753107667,
      0.0663362592458725,
      0.04768037050962448,
      -0.04228196293115616,
      0.04961181432008743,
      -0.04863560572266579,
      0.004970833659172058,
      0.02685713768005371,
      0.05381219461560249,
      -0.0018172069685533643,
      -0.03003580868244171,
      0.02148534171283245,
      0.006853566970676184,
      0.034938011318445206,
      -0.09019936621189117,
      0.08241986483335495,
      -0.020833034068346024,
      -0.00904697086662054,
      -0.02787557803094387,
      0.05845188722014427,
      0.04162852466106415,
      -0.02540370635688305,
      0.02654838189482689,
      0.04471862316131592,
      0.05458074435591698,
      0.0025615592021495104,
      0.007560827769339085,
      0.03394346311688423,
      0.020811643451452255,
      0.010526102036237717,
      0.04753183200955391,
      0.07213622331619263,
      -0.025212934240698814,
      0.014688563533127308,
      -0.04895064979791641,
      0.024273980408906937,
      -0.003333461005240679,
      0.027572186663746834,
      0.007924871519207954,
      0.03019273281097412,
      0.08064202964305878,
      -0.009982595220208168,
      -0.06921367347240448,
      -0.05922386422753334,
      0.04562779888510704,
      -0.050063177943229675,
      -7.651469059055671e-05,
      -0.005851447116583586,
      -0.03404088690876961,
      0.05988840386271477,
      0.04312088340520859,
      -0.05516059696674347,
      -0.027270499616861343,
      0.001421440509147942,
      0.029477985575795174,
      -0.0018497960409149528,
      -0.041354913264513016,
      -0.08630852401256561,
      -0.05796436220407486,
      -0.021107671782374382,
      0.10660208016633987,
      0.07059840112924576,
      -0.00043193649617023766,
      0.015832511708140373,
      0.012814334593713284,
      -0.054397646337747574,
      -0.05371154844760895,
      -0.07983933389186859,
      -0.03970889374613762,
      -0.007729640230536461,
      0.04313504695892334,
      0.02280040830373764,
      0.04110897704958916,
      -0.0963190346956253,
      -0.0564960241317749,
      0.005755042657256126,
      0.024485941976308823,
      0.011141191236674786,
      -0.10655481368303299,
      0.02800845541059971,
      0.004276289604604244,
      0.02646709233522415,
      -0.06774001568555832,
      0.02116457186639309,
      0.06522269546985626,
      0.05726029351353645,
      -0.03309117257595062,
      0.007162446156144142,
      -0.023124855011701584,
      0.013006050139665604,
      0.015197068452835083,
      0.05666591227054596,
      -0.050422508269548416,
      0.0015416607493534684,
      0.0021573780104517937,
      0.020596573129296303,
      -0.03212617337703705,
      0.0700235664844513,
      0.038902997970581055,
      -0.0348905511200428,
      -0.004728052765130997,
      -0.015456384979188442,
      0.06157269328832626,
      -0.01297640148550272,
      0.004863475915044546,
      -0.008927268907427788,
      0.016404956579208374,
      -0.005106761120259762,
      0.005838443990796804,
      0.020158695057034492,
      -0.05000205338001251,
      -0.08006338030099869,
      -0.03653483837842941,
      0.032972510904073715,
      0.02132321335375309,
      0.01163868885487318,
      0.144212543964386,
      0.009501646272838116,
      0.011287404224276543,
      -0.08141186088323593,
      0.11389327049255371,
      -0.013688341714441776,
      0.01350369118154049,
      -0.03792881220579147,
      -0.016379261389374733,
      0.10014013200998306,
      -0.000948558037634939,
      -0.03148912265896797,
      0.01072588562965393,
      0.049405939877033234,
      -0.003671831451356411,
      0.08836545050144196,
      0.03183797374367714,
      0.05145847052335739,
      0.017468208447098732,
      0.09980688989162445
    ],
    [
      0.00963426660746336,
      -0.009428474120795727,
      0.019867248833179474,
      -0.018242865800857544,
      -0.001254751579836011,
      -0.037756554782390594,
      0.05470217391848564,
      0.08559926599264145,
      0.07019785791635513,
      -0.08145695179700851,
      0.07924892753362656,
      -0.0007721228175796568,
      -0.016756009310483932,
      -0.1050388514995575,
      -0.032610274851322174,
      -0.10003054141998291,
      0.008715650998055935,
      -0.0767451673746109,
      0.07594875246286392,
      -0.01010048482567072,
      0.016961978748440742,
      0.012417100369930267,
      0.10615573078393936,
      -0.02126454934477806,
      0.029934309422969818,
      0.07020649313926697,
      0.025099297985434532,
      0.07453569024801254,
      0.03873155638575554,
      -0.019072696566581726,
      -0.027433011680841446,
      -0.05971353128552437,
      -0.0003019337309524417,
      -0.05271143093705177,
      0.073311448097229,
      -0.007417568936944008,
      0.028778696432709694,
      -0.023489823564887047,
      0.0783495232462883,
      -0.10282464325428009,
      -0.018869105726480484,
      0.0899924784898758,
      0.0034563038498163223,
      -0.0001756301207933575,
      -0.027191758155822754,
      0.04755881056189537,
      -0.03018292970955372,
      -0.014193261042237282,
      0.07503796368837357,
      -0.03390313312411308,
      0.05654202774167061,
      0.042030103504657745,
      -0.012989852577447891,
      -0.08637575060129166,
      0.028433149680495262,
      0.006555312778800726,
      -0.04586315527558327,
      0.03667702153325081,
      0.013385387137532234,
      -0.006274153478443623,
      -0.00579424761235714,
      -0.07163061946630478,
      0.03821717947721481,
      0.008736022748053074,
      -0.04278545454144478,
      -0.08548524975776672,
      0.03008691780269146,
      0.008945005014538765,
      -0.05388057976961136,
      0.08221983909606934,
      0.043528880923986435,
      -0.06830592453479767,
      0.09345388412475586,
      0.004190264269709587,
      0.031806133687496185,
      0.04400566220283508,
      0.023689722642302513,
      0.01626979373395443,
      -0.07557138055562973,
      0.012369786389172077,
      -0.05015131086111069,
      0.03380488231778145,
      0.003924380522221327,
      0.024497108533978462,
      0.003916959743946791,
      0.007111025974154472,
      -0.00608240207657218,
      0.021200258284807205,
      -0.04789423570036888,
      0.030186818912625313,
      -0.05689217150211334,
      0.015237174928188324,
      -0.010534357279539108,
      0.046096596866846085,
      0.07582354545593262,
      0.0018929954385384917,
      -0.03873298689723015,
      0.027799924835562706,
      -0.0009195072925649583,
      0.02676929347217083,
      -0.022929714992642403,
      0.07090010493993759,
      -0.04036140441894531,
      0.05236395448446274,
      0.018715491518378258,
      -0.04726836830377579,
      0.01164292823523283,
      0.051067452877759933,
      -0.00711391307413578,
      -0.015604937449097633,
      0.03613927960395813,
      0.012779771350324154,
      0.05362531542778015,
      -0.04155370965600014,
      -0.006180897355079651,
      -0.05113301798701286,
      0.018511511385440826,
      -0.07524088770151138,
      0.03694899380207062,
      -0.08359701931476593,
      0.015219861641526222,
      -0.010162304155528545,
      -0.06259328871965408,
      0.03083757869899273,
      0.028837312012910843,
      -0.06493772566318512,
      0.0006088375230319798,
      -0.011505710892379284,
      -0.12080209702253342,
      0.05969100072979927,
      -0.002443598350510001,
      -0.023866675794124603,
      0.030553292483091354,
      0.07398949563503265,
      0.034010909497737885,
      0.09633831679821014,
      0.04427260160446167,
      -0.09646166861057281,
      0.008310822769999504,
      -0.009871123358607292,
      -0.06678371131420135,
      0.04844113811850548,
      -0.07494322210550308,
      0.06563190370798111,
      0.07735086977481842,
      -0.010642659850418568,
      -0.023058947175741196,
      0.026247985661029816,
      0.02338632196187973,
      0.032293543219566345,
      -0.002621925435960293,
      0.042898453772068024,
      -0.002311418764293194,
      -0.03264978528022766,
      0.02582673355937004,
      -0.057351253926754,
      0.09331733733415604,
      0.0692761018872261,
      -0.046718284487724304,
      0.017286917194724083,
      -0.035862695425748825,
      0.09896532446146011,
      -0.07512795180082321,
      0.020087702199816704,
      0.030710740014910698,
      0.04536449536681175,
      -0.04760647937655449,
      -0.018824908882379532,
      -0.03443119674921036,
      0.004599070642143488,
      -0.017838431522250175,
      0.01748421974480152,
      -0.018797313794493675,
      -0.05009842664003372,
      0.005156895611435175,
      0.05219564586877823,
      -0.023439444601535797,
      0.034277088940143585,
      -0.011103993281722069,
      0.0013341531157493591,
      0.03793470561504364,
      0.026942841708660126,
      -0.06426984816789627,
      -0.03030356951057911,
      -0.04309295117855072,
      -0.024383071810007095,
      -0.03454560786485672,
      -0.009815043769776821,
      -0.05701505392789841,
      -0.011808155104517937,
      0.04138755053281784,
      -0.02208779752254486,
      0.04851533845067024,
      0.007265395484864712,
      -0.026432912796735764,
      0.05253490060567856,
      0.05998039245605469,
      -0.06419789791107178,
      0.029964344576001167,
      0.001132208970375359,
      0.04307150840759277,
      -0.05204594507813454,
      -0.03813552111387253,
      0.008671753108501434,
      0.006243921350687742,
      -0.009970585815608501,
      0.009629050269722939,
      0.004045659676194191,
      0.03789867088198662,
      -0.05088438093662262,
      -0.002817704575136304,
      -0.009746947325766087,
      -0.050926342606544495,
      0.01805071532726288,
      0.03172023594379425,
      -0.08369415998458862,
      -0.005371745675802231,
      0.020783571526408195,
      0.00917685218155384,
      0.06874433904886246,
      0.01844756118953228,
      -0.0626620352268219,
      -0.013478582724928856,
      0.023311367258429527,
      0.04704584181308746,
      -0.01738223247230053,
      0.030366748571395874,
      -0.00885666161775589,
      -0.01754748262465,
      -0.0008339761407114565,
      -0.06874597072601318,
      0.013524135574698448,
      -0.05450327694416046,
      0.055349864065647125,
      0.012550048530101776,
      -0.04211939126253128,
      0.045416172593832016,
      0.07836063951253891,
      0.034835051745176315,
      0.03393067419528961,
      0.0032776996959000826,
      -0.018484141677618027,
      0.03869885578751564,
      0.11518228054046631,
      0.06447810679674149,
      0.018785109743475914,
      0.024589894339442253,
      -0.08370423316955566,
      0.0077383085153996944,
      -0.08697838336229324,
      0.018722346052527428,
      0.01882351189851761,
      -0.02793581597507,
      -0.07111018896102905,
      -0.01964716799557209,
      0.0026561475824564695,
      0.04132217913866043,
      -0.0035319929011166096,
      -0.019144359976053238,
      0.005584919825196266,
      0.08288733661174774,
      -0.04225213825702667,
      0.041188642382621765,
      0.04070945456624031,
      -0.08452951908111572,
      -0.03710893914103508,
      0.018810560926795006,
      -0.037547823041677475,
      -0.06816187500953674,
      -0.06252779811620712,
      0.018326355144381523,
      0.011281558312475681,
      -0.011729361489415169,
      -0.07538764178752899,
      0.005649971310049295,
      0.016399839892983437,
      0.051860202103853226,
      0.06280452758073807,
      -0.07252154499292374,
      0.015619952231645584,
      0.03472977131605148,
      -0.062010787427425385,
      -0.0241258442401886,
      0.034503936767578125,
      -0.03205641731619835,
      -0.07015842944383621,
      0.04135854169726372,
      0.08019284904003143,
      0.09742278605699539,
      -0.0401071198284626,
      -0.025306060910224915,
      0.022523503750562668,
      -0.03170173242688179,
      0.03035804070532322,
      0.01069694384932518,
      0.024017591029405594,
      0.037498898804187775,
      -0.0037225966807454824,
      -0.03168383240699768,
      -0.02376720681786537,
      -0.00864971149712801,
      0.08159100264310837,
      0.050396014004945755,
      -0.0979599803686142,
      0.020627282559871674,
      -0.0038620673585683107,
      0.029190775007009506,
      0.015893258154392242,
      -0.07126539945602417,
      -0.07898234575986862,
      0.04146873578429222,
      0.053600095212459564,
      -0.056117065250873566,
      0.08006913214921951,
      -0.018677596002817154,
      0.11467143893241882,
      0.003836272982880473,
      -0.016149232164025307,
      -0.024003174155950546,
      0.022273382171988487,
      0.04183720052242279,
      -0.06972367316484451,
      -0.05542149767279625,
      -0.009178402833640575,
      -0.024608593434095383,
      0.001233136048540473,
      -0.0744529590010643,
      0.07890443503856659,
      0.013389591127634048,
      0.019328519701957703,
      0.046351294964551926,
      0.014755263924598694,
      -0.02561304345726967,
      -0.02433265559375286,
      -0.010380947962403297,
      0.023912806063890457,
      0.00280949124135077,
      0.04119129851460457,
      0.0010946734109893441,
      -0.07029347866773605,
      -0.034991804510354996,
      -0.01547873392701149,
      -0.0822187215089798,
      -0.012158398516476154,
      -0.031034480780363083,
      -0.05177762731909752,
      0.026235073804855347,
      0.10552316159009933,
      0.015863748267292976,
      -0.002654414623975754,
      -0.03664623945951462,
      0.048065185546875,
      -0.0232235137373209,
      -0.05504573509097099,
      -0.0015221722424030304,
      -0.001201071310788393,
      -0.0027938252314925194,
      -0.0036209418904036283,
      -0.025738777592778206,
      -0.007389078848063946,
      0.05462487041950226,
      0.06113377958536148,
      0.04374131187796593,
      -0.06301040947437286,
      0.021091347560286522,
      0.007611199747771025,
      0.055593088269233704,
      0.07205985486507416,
      0.0021684651728719473,
      -0.06409156322479248,
      0.051166363060474396,
      -0.07924222946166992,
      0.009419200010597706,
      0.030101802200078964,
      0.017576919868588448,
      0.058740682899951935,
      0.039095617830753326,
      0.06188470125198364,
      -0.08625014126300812,
      0.0013673349749296904,
      -0.02616306208074093,
      0.0526806004345417,
      -0.03818628937005997,
      -0.05983267351984978,
      -0.0005453377380035818,
      0.02482455037534237,
      -0.03949775919318199,
      -0.0004563048714771867,
      0.028052957728505135,
      -0.027151459828019142,
      -0.02902868762612343,
      -0.06635040789842606,
      -0.09946965426206589,
      0.05855419114232063,
      0.03595905750989914,
      -0.009967857040464878,
      0.045181699097156525,
      -0.0006155872251838446,
      -0.060622669756412506,
      0.08575499802827835,
      -0.05166204273700714,
      0.011527507565915585,
      0.02368784509599209,
      0.007925671525299549,
      0.04654579237103462,
      -0.06890925019979477,
      -0.04159969091415405,
      0.028144558891654015,
      0.02757441997528076,
      -0.007674290332943201,
      0.09161520004272461,
      0.08174209296703339,
      0.019261877983808517,
      0.04757676273584366,
      0.023143300786614418,
      -0.007944947108626366,
      0.05011735484004021,
      0.03603590652346611,
      -0.013205879367887974,
      0.03694727644324303,
      -0.0154343256726861,
      -0.0219129528850317,
      0.05498592555522919,
      -0.07127342373132706,
      0.003609523642808199,
      0.007621623110026121,
      0.038280051201581955,
      0.09165778756141663,
      -0.07072795182466507,
      0.0706099346280098,
      0.03652115911245346,
      -0.06384523212909698,
      -0.07266958802938461,
      -0.01581934094429016,
      -0.003740983083844185,
      -0.07900619506835938,
      -0.02803768217563629,
      -0.06551121920347214,
      -0.052713681012392044,
      -0.03699144348502159,
      -0.00921669788658619,
      -0.010722029954195023,
      0.0007391966064460576,
      -0.006249892059713602,
      -0.03351418673992157,
      0.015616684220731258,
      0.0019232156919315457,
      -0.05191756784915924,
      0.06763309240341187,
      -0.008439689874649048,
      0.032299380749464035,
      0.04824080318212509,
      -0.04392949491739273,
      0.0013138629728928208,
      0.05252588540315628,
      0.04913654550909996,
      0.005356111563742161,
      -0.04410417750477791,
      0.0007702114526182413,
      0.004504825454205275,
      0.10473505407571793,
      0.008759915828704834,
      0.026729440316557884,
      -0.061477288603782654,
      -0.002084259642288089,
      0.02701633796095848,
      0.006589509081095457,
      0.022413190454244614,
      -0.0027628166135400534,
      -0.05702697113156319,
      -0.06185884773731232,
      0.0851735845208168,
      -0.05461835116147995,
      0.02083306573331356,
      -0.03251414746046066,
      -0.01975654810667038,
      -0.0036381513345986605,
      -0.03471352532505989,
      0.004651712253689766,
      -0.007534827571362257,
      0.010460537858307362,
      0.012687050737440586,
      -0.11452876776456833,
      -0.0008068036986514926,
      0.021119391545653343,
      -0.0214567668735981,
      0.05616156756877899,
      -0.023933781310915947,
      0.027897590771317482,
      -0.0373818539083004,
      -0.044194545596838,
      0.06623926758766174,
      0.03430088981986046,
      0.10511591285467148,
      -0.036515336483716965,
      0.023680556565523148,
      0.11396094411611557,
      0.024087131023406982,
      -0.13355979323387146,
      -0.006726578343659639,
      0.08349790424108505,
      -0.05988268181681633,
      0.03197500482201576
    ],
    [
      0.14022201299667358,
      0.003829298308119178,
      -0.0019634715281426907,
      0.0532684251666069,
      -0.0539604090154171,
      0.11159341037273407,
      0.03333532065153122,
      0.06966310739517212,
      -0.0029624802991747856,
      -0.06809617578983307,
      0.10241276770830154,
      0.02745053358376026,
      -0.033946771174669266,
      0.08354504406452179,
      -0.014735517092049122,
      0.033447638154029846,
      -0.01881604641675949,
      0.011659234762191772,
      -0.007667941972613335,
      -0.015112687833607197,
      -0.06506609171628952,
      0.06654330343008041,
      -0.06455332040786743,
      -0.04453136771917343,
      -0.005048686172813177,
      -0.010520979762077332,
      -0.0482930950820446,
      0.06729374825954437,
      0.05211489275097847,
      0.0811178982257843,
      -0.00887046754360199,
      0.03314576297998428,
      -0.0019513264996930957,
      0.002432464621961117,
      0.030578970909118652,
      -0.017813000828027725,
      0.05370459705591202,
      0.03824619948863983,
      -0.05632835254073143,
      0.03244828060269356,
      -0.008776042610406876,
      -0.039187703281641006,
      0.05293736979365349,
      0.07598830759525299,
      0.02167780138552189,
      0.0508112832903862,
      0.029470836743712425,
      -0.028488745912909508,
      0.024317560717463493,
      0.06765470653772354,
      -0.01786329783499241,
      -0.007199902553111315,
      -0.05794420465826988,
      0.018104074522852898,
      0.004511523526161909,
      0.012808696366846561,
      0.09904050827026367,
      0.025887565687298775,
      0.003176482394337654,
      0.0361570306122303,
      0.013703355565667152,
      0.04810969904065132,
      0.04097451642155647,
      0.06613091379404068,
      -0.011139912530779839,
      0.004030989017337561,
      0.09489655494689941,
      -0.037991538643836975,
      -0.0034742404241114855,
      0.053785886615514755,
      0.07451143115758896,
      -0.041229553520679474,
      -0.019645681604743004,
      7.960927177919075e-05,
      0.022293467074632645,
      0.021996309980750084,
      -0.07345595210790634,
      0.027181342244148254,
      -0.0019451535772532225,
      0.014792462810873985,
      0.001970272744074464,
      0.004707972053438425,
      0.07198837399482727,
      0.028243934735655785,
      0.00621830765157938,
      -0.05003674700856209,
      -0.024720050394535065,
      -0.04072284698486328,
      0.006450383458286524,
      -0.014843370765447617,
      -0.05274772644042969,
      -0.04836336150765419,
      -0.058702509850263596,
      0.05616253614425659,
      -0.019378703087568283,
      0.0038313125260174274,
      0.05171158164739609,
      0.012075042352080345,
      0.06877431273460388,
      -0.05711285024881363,
      -0.02832159958779812,
      -0.02532644383609295,
      -0.08191495388746262,
      -0.003929183818399906,
      0.03570324927568436,
      -0.03158075734972954,
      0.01441632304340601,
      0.08593621850013733,
      -0.045777078717947006,
      -0.058543868362903595,
      -0.09157047420740128,
      0.05816212669014931,
      0.037151359021663666,
      -0.023327583447098732,
      -0.021723520010709763,
      0.03439509496092796,
      0.05638575181365013,
      -0.05190090835094452,
      0.02277582511305809,
      -0.04899383708834648,
      -0.02063766121864319,
      -0.0833686962723732,
      0.043679289519786835,
      0.010751101188361645,
      0.01123350765556097,
      -0.006959339138120413,
      0.05421838536858559,
      -0.04630470648407936,
      -0.013942675665020943,
      0.05957761034369469,
      0.03500537574291229,
      0.03488408774137497,
      -0.018921930342912674,
      0.061044491827487946,
      -0.01608150079846382,
      0.006556627806276083,
      0.06436196714639664,
      -0.040038082748651505,
      -0.04408358410000801,
      0.004336170852184296,
      -0.009392372332513332,
      0.02462167665362358,
      0.03468432277441025,
      0.013108620420098305,
      -0.03212100639939308,
      -0.04557916522026062,
      0.025350011885166168,
      -0.04781901463866234,
      -0.011877535842359066,
      -0.013857131823897362,
      -0.10018271207809448,
      -0.002416689647361636,
      0.04527183249592781,
      0.022550886496901512,
      0.027834929525852203,
      -0.025845086202025414,
      0.016969280317425728,
      -0.08400201052427292,
      0.03833584487438202,
      -0.017741438001394272,
      0.053048275411129,
      0.059266190975904465,
      -0.02044856734573841,
      -0.029818715527653694,
      -0.01079582329839468,
      -0.0032231640070676804,
      -0.03236507251858711,
      -0.0516962856054306,
      0.019704831764101982,
      0.07933817058801651,
      -0.11353351175785065,
      0.04804285243153572,
      0.020360171794891357,
      0.013668575324118137,
      0.07177706062793732,
      -0.0505615696310997,
      -0.042552508413791656,
      -0.031820256263017654,
      0.06439176201820374,
      0.026432260870933533,
      0.06619629263877869,
      0.055062953382730484,
      -0.01643693633377552,
      0.05527425929903984,
      -0.02718929760158062,
      -0.012184386141598225,
      -0.033249277621507645,
      -0.02228892594575882,
      0.03643781319260597,
      -0.002279030391946435,
      0.02372586913406849,
      -0.015278530307114124,
      -0.014531603083014488,
      -0.011588821187615395,
      0.0826791301369667,
      -0.06803055852651596,
      -0.03522632643580437,
      0.08850467205047607,
      -0.023904090747237206,
      -0.023872386664152145,
      -0.053300462663173676,
      0.06509552896022797,
      -0.020341232419013977,
      -0.0004024367081001401,
      -0.08489584177732468,
      0.013899716548621655,
      -0.06264327466487885,
      -0.045248206704854965,
      0.051124922931194305,
      0.001773057272657752,
      -0.012096663005650043,
      0.02743416465818882,
      -0.041380830109119415,
      -0.07849159836769104,
      -0.04252593591809273,
      0.03544464707374573,
      0.06819530576467514,
      0.0037156983744353056,
      0.06932738423347473,
      0.024396175518631935,
      -0.02516600675880909,
      0.018243147060275078,
      0.02805696241557598,
      -0.0464458167552948,
      0.07316658645868301,
      0.028468217700719833,
      -0.03345226123929024,
      0.028798790648579597,
      0.013941277749836445,
      0.009747753851115704,
      0.013696336187422276,
      -0.008710636757314205,
      0.006761917844414711,
      -0.013904070481657982,
      -0.02762790583074093,
      0.02722848579287529,
      0.047909244894981384,
      -0.0392577163875103,
      0.0065163373947143555,
      -0.07413247972726822,
      -0.0409914068877697,
      0.01224012952297926,
      0.0714711993932724,
      0.0919799879193306,
      0.06661296635866165,
      -0.012616701424121857,
      -0.05481412261724472,
      -0.037883222103118896,
      0.001082132919691503,
      -0.036853689700365067,
      0.017604874446988106,
      -0.09703658521175385,
      -0.020176660269498825,
      -0.0006225825054571033,
      -0.04611758887767792,
      0.019269907847046852,
      0.022645704448223114,
      0.08672983199357986,
      -0.039757996797561646,
      -0.07180333137512207,
      0.038357850164175034,
      0.00509475264698267,
      -0.014522487297654152,
      0.03192789480090141,
      0.0711519792675972,
      0.020242253318428993,
      -0.020034391433000565,
      -0.05395842716097832,
      -0.029459059238433838,
      -0.07042995095252991,
      0.012968677096068859,
      -0.005773425567895174,
      -0.07714957743883133,
      -0.054651618003845215,
      -0.07773435115814209,
      0.031357552856206894,
      0.09789463877677917,
      0.022027185186743736,
      -0.07364163547754288,
      -0.029143918305635452,
      -0.08444609493017197,
      -0.029629569500684738,
      0.006523527670651674,
      -0.020691698417067528,
      0.011590298265218735,
      -0.029386375099420547,
      0.030987905338406563,
      0.010516343638300896,
      0.025509262457489967,
      -0.05911393463611603,
      0.010858491063117981,
      -0.006407928187400103,
      -0.07664550095796585,
      0.01904820092022419,
      0.03749673068523407,
      0.05689990893006325,
      -0.01891171559691429,
      0.009933127090334892,
      0.03255491703748703,
      -0.08255092799663544,
      -0.03499336540699005,
      -0.00432466808706522,
      -0.005122534465044737,
      0.020247219130396843,
      -0.023117557168006897,
      -0.05934221297502518,
      0.09463371336460114,
      0.01286117359995842,
      0.07703004777431488,
      0.022989563643932343,
      0.047142937779426575,
      -0.07657232880592346,
      0.02727350778877735,
      -0.08742360770702362,
      -0.10189785063266754,
      -0.06057356670498848,
      0.016385512426495552,
      -0.023806504905223846,
      0.06826028972864151,
      0.015127383172512054,
      -0.06385190784931183,
      0.01649273745715618,
      -0.025582371279597282,
      0.09510904550552368,
      -0.008858119137585163,
      0.001539510441944003,
      0.04041915014386177,
      0.04892430454492569,
      -0.024111634120345116,
      -0.016268163919448853,
      -0.03952307626605034,
      -0.02736740931868553,
      0.014478911645710468,
      -0.01978742703795433,
      0.010953078046441078,
      -0.04775823652744293,
      0.020969025790691376,
      -0.005508885253220797,
      0.016749657690525055,
      -0.013355682604014874,
      0.00850698258727789,
      -0.03077661618590355,
      0.015994738787412643,
      -0.039046429097652435,
      0.0009145227377302945,
      0.03456663340330124,
      0.0626484602689743,
      0.0702759176492691,
      -0.0005922313430346549,
      -0.008569846861064434,
      -0.036354295909404755,
      -0.08818962424993515,
      -0.04586545377969742,
      0.00244245957583189,
      0.12420818209648132,
      0.04415144771337509,
      0.0010916817700490355,
      -0.05406540632247925,
      -0.03243182227015495,
      0.0580817349255085,
      0.001185593893751502,
      0.034398153424263,
      0.020857015624642372,
      0.05341925472021103,
      0.0014637613203376532,
      0.01071341335773468,
      0.037086594849824905,
      0.051524583250284195,
      -0.11559980362653732,
      0.02610214427113533,
      -0.05051877349615097,
      0.05837022513151169,
      0.014002367854118347,
      0.021349679678678513,
      -0.06282823532819748,
      0.005875295959413052,
      -0.033611960709095,
      0.018595600500702858,
      -0.037555716931819916,
      -0.030206158757209778,
      0.04611646384000778,
      -0.05468650162220001,
      -0.014135721139609814,
      0.004375350195914507,
      0.031165851280093193,
      0.006199783179908991,
      0.004255854524672031,
      -0.047012776136398315,
      0.1433158665895462,
      -0.012579555623233318,
      -0.022322531789541245,
      0.018125373870134354,
      0.029850127175450325,
      -0.002772067440673709,
      -0.019715357571840286,
      -0.060467030853033066,
      0.027600649744272232,
      0.013445270247757435,
      -0.0012906173942610621,
      0.029387924820184708,
      -0.011399517767131329,
      -0.023156804963946342,
      0.0332607701420784,
      -0.0428570918738842,
      0.023828815668821335,
      0.030562251806259155,
      -0.02934838831424713,
      -0.022719895467162132,
      -0.022850345820188522,
      0.009859229438006878,
      -0.06860299408435822,
      -0.013415800407528877,
      0.02056017890572548,
      -0.018068397417664528,
      -0.08963948488235474,
      -0.08291613310575485,
      -0.03217006474733353,
      0.0009272767347283661,
      -0.12310725450515747,
      0.005349738523364067,
      0.021902916952967644,
      -0.013881290331482887,
      -0.03169899433851242,
      -0.04548697918653488,
      -0.006835934706032276,
      -0.009631812572479248,
      0.02317873015999794,
      0.060580041259527206,
      0.10143256932497025,
      -0.05138837546110153,
      0.10950127989053726,
      -0.0037896980065852404,
      -0.022194772958755493,
      0.03579067811369896,
      -0.002077230019494891,
      -0.03817648068070412,
      0.005133385770022869,
      -0.04054384306073189,
      0.08211749792098999,
      0.05213186517357826,
      -0.009762261994183064,
      0.06695462018251419,
      0.02509525790810585,
      0.01841082237660885,
      0.03861360624432564,
      -0.053787123411893845,
      0.07652273029088974,
      -0.054476477205753326,
      0.006525963079184294,
      -0.007893201895058155,
      0.02979343570768833,
      0.0878358706831932,
      -0.03087444230914116,
      0.03756169229745865,
      0.03746342286467552,
      -0.022742753848433495,
      0.007337316870689392,
      0.03901081904768944,
      -0.0199178084731102,
      0.04046860337257385,
      -0.01564793474972248,
      -0.027321530506014824,
      0.0034793901722878218,
      0.07015956193208694,
      0.08841486275196075,
      0.0563797727227211,
      0.04633131995797157,
      0.04198223352432251,
      0.03938407450914383,
      -0.0214290339499712,
      -0.09984070062637329,
      -0.06477977335453033,
      0.015054036863148212,
      -0.05386011302471161,
      0.017598146572709084,
      -0.015484804287552834,
      -0.02997332066297531,
      0.04424840956926346,
      -0.031257033348083496,
      -0.035096216946840286,
      0.09179945290088654,
      -0.030013978481292725,
      -0.07549725472927094,
      0.07233261317014694,
      -0.041364822536706924,
      0.03657470643520355,
      0.006990616675466299,
      -0.007044753525406122,
      0.0667867511510849,
      0.004142374265938997,
      0.038578107953071594,
      0.055188681930303574,
      0.14376415312290192,
      -0.03688838705420494,
      0.015548440627753735,
      0.015524648129940033,
      -0.024209707975387573,
      -0.018320569768548012,
      0.07453123480081558,
      0.0388362742960453,
      -0.06065172329545021,
      -0.091696597635746,
      -0.029873980209231377
    ],
    [
      0.019148651510477066,
      0.0662003755569458,
      -0.019387146458029747,
      0.06424488127231598,
      -0.03403807058930397,
      -0.046470168977975845,
      0.005846506915986538,
      -0.05922701954841614,
      0.06760482490062714,
      -0.05224408581852913,
      0.030015066266059875,
      -0.029737764969468117,
      -0.008632196113467216,
      0.03003528341650963,
      -0.0789746567606926,
      -0.001289539271965623,
      0.020086437463760376,
      -0.07463948428630829,
      -0.046779222786426544,
      0.004274113103747368,
      -0.007565945386886597,
      -0.02918071113526821,
      0.09586554765701294,
      -0.04300643503665924,
      0.05070171132683754,
      -0.0034833878744393587,
      -0.016771161928772926,
      -0.04467080906033516,
      0.018416453152894974,
      -0.10845144838094711,
      0.016770541667938232,
      0.004470332991331816,
      -0.04928353428840637,
      -0.03076425939798355,
      -0.06801475584506989,
      -0.043822500854730606,
      0.00929022766649723,
      -0.006617352366447449,
      0.012214372865855694,
      -0.03837750852108002,
      -0.026909179985523224,
      0.04697495698928833,
      0.011688943952322006,
      -0.005270460154861212,
      0.10784109681844711,
      0.006445829290896654,
      0.049795664846897125,
      0.09245600551366806,
      0.01011169794946909,
      -0.06278201192617416,
      0.048977404832839966,
      -0.04420460760593414,
      0.03503241389989853,
      0.009899808093905449,
      0.010297862812876701,
      -0.033805280923843384,
      0.02945336326956749,
      -0.0028388756327331066,
      0.039364662021398544,
      -0.008877462707459927,
      -0.004669405985623598,
      0.0023533001076430082,
      -0.044086456298828125,
      0.059838615357875824,
      0.024556949734687805,
      -0.07251664996147156,
      0.028442326933145523,
      0.04530033469200134,
      -0.0496305488049984,
      0.02089008502662182,
      -0.01349145919084549,
      0.022819463163614273,
      0.006632075645029545,
      0.06505776941776276,
      0.002714223461225629,
      0.05537626892328262,
      0.08679168671369553,
      0.033073410391807556,
      -0.07595755159854889,
      0.06137065216898918,
      -0.025474760681390762,
      0.028068430721759796,
      0.035155944526195526,
      -0.03870246931910515,
      -0.07339271903038025,
      0.029232090339064598,
      -0.05908495560288429,
      -0.0008872167090885341,
      0.04170683026313782,
      0.014291387982666492,
      -0.05165794491767883,
      -0.012461403384804726,
      -0.004976682830601931,
      0.039881572127342224,
      -0.008398196659982204,
      0.0247980747371912,
      -0.035295452922582626,
      -0.0019662990234792233,
      -0.035094987601041794,
      -0.03356418386101723,
      0.013168917037546635,
      0.04183351621031761,
      -0.08142108470201492,
      0.03716432675719261,
      0.03498975932598114,
      -0.0038361602928489447,
      0.050716567784547806,
      0.0308127049356699,
      0.0669645294547081,
      -0.013612832874059677,
      -0.02756820060312748,
      0.016757644712924957,
      -0.04086318612098694,
      -0.011327499523758888,
      -0.02917645126581192,
      -0.02541506476700306,
      -0.011267293244600296,
      0.10467339307069778,
      -0.043904803693294525,
      0.04419190436601639,
      -0.01849282905459404,
      -0.02692016400396824,
      -0.004175365436822176,
      -0.057392433285713196,
      -0.012032425962388515,
      0.06063951179385185,
      0.07418946176767349,
      0.05213012173771858,
      0.04097672551870346,
      0.04305988550186157,
      0.043537724763154984,
      -0.07282575219869614,
      -0.005477508530020714,
      0.030135340988636017,
      0.08056812733411789,
      0.04025354981422424,
      0.06532634794712067,
      0.02540379948914051,
      -0.005209986120462418,
      -0.015707464888691902,
      0.012530582956969738,
      0.07375698536634445,
      0.0011930244509130716,
      0.057929471135139465,
      0.050870005041360855,
      -0.006721410900354385,
      0.04985480383038521,
      -0.03440264239907265,
      -0.012406759895384312,
      -0.007347801700234413,
      0.016653520986437798,
      -0.025666257366538048,
      0.06781302392482758,
      -0.007667409256100655,
      0.044614095240831375,
      0.012430883944034576,
      0.005011785309761763,
      -0.017129776999354362,
      0.07123035192489624,
      0.01383719127625227,
      0.056902796030044556,
      -0.0014940943801775575,
      -0.05839625000953674,
      0.013348282314836979,
      -0.009015010669827461,
      -0.01896749995648861,
      0.05261816456913948,
      0.03704914450645447,
      0.0026726964861154556,
      -0.008178316056728363,
      -0.03740275278687477,
      0.008694630116224289,
      -0.0903933122754097,
      0.11035314947366714,
      -0.034100428223609924,
      0.0025842732284218073,
      -0.0596802793443203,
      0.009167587384581566,
      0.01999453641474247,
      -0.10605716705322266,
      -0.013057571835815907,
      0.003809192916378379,
      -0.06917232275009155,
      -0.04651457816362381,
      0.008804906159639359,
      -0.02770930528640747,
      -0.10901349782943726,
      -0.005809363443404436,
      0.027666155248880386,
      0.007973283529281616,
      -0.020136112347245216,
      0.0367884486913681,
      0.024999447166919708,
      -0.04164539650082588,
      0.08919480443000793,
      0.016389798372983932,
      0.009072830900549889,
      0.005474179983139038,
      -0.018626654520630836,
      0.05422760546207428,
      -0.017336610704660416,
      0.03967558592557907,
      -0.07539542019367218,
      -0.0008836847264319658,
      0.011359513737261295,
      -0.03954280912876129,
      0.06749411672353745,
      0.001077020657248795,
      0.08792134374380112,
      0.011811488308012486,
      0.041818197816610336,
      0.019794076681137085,
      0.031203830614686012,
      -0.019091947004199028,
      -0.010316072963178158,
      -0.061878006905317307,
      0.007160054054111242,
      -0.011287407949566841,
      -0.013224132359027863,
      0.0957094132900238,
      -0.04324658587574959,
      0.010080818086862564,
      0.03535535931587219,
      0.012257019057869911,
      0.0646204799413681,
      0.010235332883894444,
      -0.011593393981456757,
      -0.019988849759101868,
      0.07582519203424454,
      -0.00235160905867815,
      0.03602614998817444,
      0.044168226420879364,
      -0.005430691875517368,
      0.029206926003098488,
      0.03245800733566284,
      0.022709865123033524,
      -0.06461106985807419,
      -0.005767572671175003,
      -0.034397270530462265,
      -0.03312177211046219,
      -0.052281901240348816,
      0.015271447598934174,
      0.06506561487913132,
      -0.0303462203592062,
      -0.08744685351848602,
      0.016027584671974182,
      0.09665778279304504,
      -0.0088446494191885,
      -0.028836598619818687,
      -0.046849966049194336,
      -0.009142890572547913,
      -0.058697495609521866,
      -0.03885664418339729,
      0.014237923547625542,
      -0.03194119781255722,
      -0.02014463022351265,
      0.012689933180809021,
      0.013457237742841244,
      0.0999857559800148,
      -0.02171177975833416,
      0.05970880389213562,
      -0.05861351266503334,
      0.0355863980948925,
      0.08085069805383682,
      -0.047573186457157135,
      -0.021625911816954613,
      -0.07036296278238297,
      0.07886434346437454,
      -0.02738303132355213,
      -0.0669911652803421,
      0.020636914297938347,
      -0.08912074565887451,
      -0.06771598756313324,
      0.04222158342599869,
      -0.03261488676071167,
      0.004402055870741606,
      0.030827093869447708,
      0.01721286214888096,
      -0.056999750435352325,
      -0.01128323469310999,
      -0.06765646487474442,
      -0.11034329980611801,
      -0.06312721967697144,
      -0.015852520242333412,
      0.023962870240211487,
      0.035354938358068466,
      0.03198843076825142,
      -0.033265139907598495,
      -0.008994382806122303,
      0.012105359695851803,
      0.022956740111112595,
      0.027422217652201653,
      -0.09433622658252716,
      -0.06848550587892532,
      -0.013443770818412304,
      -0.0613148957490921,
      0.04605090990662575,
      0.05346732214093208,
      0.011483350768685341,
      -0.006206599064171314,
      0.07535803318023682,
      0.0292939692735672,
      -0.011406932957470417,
      -0.011127141304314137,
      0.07623346894979477,
      -0.011103729717433453,
      0.007282425183802843,
      -0.014688112773001194,
      -0.056598905473947525,
      -0.01114309299737215,
      -0.0669705718755722,
      -0.021874286234378815,
      -0.028058961033821106,
      0.0004669087065849453,
      -0.03723267465829849,
      -0.06308146566152573,
      0.020347457379102707,
      0.032096873968839645,
      -0.028528854250907898,
      0.030006563290953636,
      -0.06131983548402786,
      0.003272234695032239,
      -0.048391908407211304,
      -0.010669372975826263,
      -0.01095967460423708,
      0.005675280932337046,
      0.002040601335465908,
      -0.011034490540623665,
      -0.008350668475031853,
      0.038024235516786575,
      0.07437369227409363,
      0.04060059040784836,
      0.02103898487985134,
      0.013807574287056923,
      0.07797130197286606,
      -0.00630757724866271,
      0.06590471416711807,
      -0.048631925135850906,
      0.014049381017684937,
      0.06968268752098083,
      0.05862492322921753,
      -0.013297094032168388,
      -0.04248156026005745,
      -0.0015530576929450035,
      -0.06087912246584892,
      -0.031153662130236626,
      0.032918330281972885,
      0.04547445848584175,
      -0.06396444886922836,
      0.007522814441472292,
      -0.02140154503285885,
      0.01495674904435873,
      -0.017884641885757446,
      -0.01889556460082531,
      -0.0014604737516492605,
      0.05955546349287033,
      0.06896037608385086,
      0.05410914495587349,
      -0.0321035273373127,
      0.0027752872556447983,
      -0.026197144761681557,
      0.04411017522215843,
      -0.028262171894311905,
      -0.06943296641111374,
      0.114647276699543,
      -0.037258271127939224,
      -0.10257712006568909,
      -0.0778990313410759,
      0.004921266343444586,
      0.036170121282339096,
      0.017702722921967506,
      -0.04973980039358139,
      0.05699754133820534,
      0.034771621227264404,
      0.0056361048482358456,
      -0.046333711594343185,
      0.0024134914856404066,
      -0.042852308601140976,
      0.0215138029307127,
      0.01058343704789877,
      0.020192597061395645,
      0.0037442639004439116,
      -0.05733189731836319,
      -0.03730233758687973,
      -0.01893489435315132,
      -0.05406777560710907,
      -0.03879636153578758,
      -0.00710233673453331,
      0.03125403821468353,
      0.008549008518457413,
      -0.03734293207526207,
      0.014012562111020088,
      0.07281465828418732,
      0.029254915192723274,
      -0.02452852576971054,
      0.009051652625203133,
      0.007883038371801376,
      -0.0032100235112011433,
      -0.06648220866918564,
      -0.049760378897190094,
      0.028776351362466812,
      -0.025290049612522125,
      0.029049102216959,
      -0.08279982209205627,
      0.07611972093582153,
      0.027343349531292915,
      -0.0381011925637722,
      -0.0023959209211170673,
      -0.05445169284939766,
      0.05590413883328438,
      -0.052415262907743454,
      0.0427781343460083,
      -0.027322925627231598,
      -0.027588892728090286,
      -0.028241349384188652,
      -0.0030888542532920837,
      -0.03331521525979042,
      -0.07471377402544022,
      0.004700315184891224,
      -0.010684894397854805,
      -0.0179735217243433,
      -0.03867773339152336,
      0.01401454396545887,
      0.0036457418464124203,
      -0.025756273418664932,
      -0.038459088653326035,
      0.000288009294308722,
      0.019763126969337463,
      0.016881590709090233,
      0.07494588196277618,
      -0.012673872523009777,
      0.016271784901618958,
      -0.06133796647191048,
      0.03316979855298996,
      0.0387788861989975,
      0.06223655492067337,
      0.04518565535545349,
      0.028686143457889557,
      -0.051575250923633575,
      0.06028006970882416,
      0.051440101116895676,
      -0.03635130077600479,
      -0.052723340690135956,
      -0.01734117977321148,
      0.07252319157123566,
      -0.01353573426604271,
      -0.058691345155239105,
      -0.012712595984339714,
      0.04494742304086685,
      -0.02353835664689541,
      0.021566465497016907,
      -0.031119652092456818,
      -0.012353525497019291,
      -0.054470982402563095,
      0.0514334999024868,
      0.018361404538154602,
      -0.003927338402718306,
      0.0275367870926857,
      -0.06281016021966934,
      0.052155785262584686,
      0.02890913560986519,
      0.018480081111192703,
      0.015393901616334915,
      0.049871865659952164,
      -0.014861767180263996,
      -0.0139623349532485,
      0.023264672607183456,
      -0.03511589765548706,
      0.04169131815433502,
      -0.03970129415392876,
      -0.014868581667542458,
      -0.03365075960755348,
      0.01929234340786934,
      0.012227937579154968,
      -0.014044413343071938,
      0.04150741919875145,
      0.004544162191450596,
      -0.0770617201924324,
      0.029374130070209503,
      0.11237145960330963,
      0.019842246547341347,
      0.01581694185733795,
      0.04788995161652565,
      -0.02484673634171486,
      -0.006538947578519583,
      0.07076545059680939,
      0.05582347512245178,
      0.018218068405985832,
      0.019205745309591293,
      -0.034481845796108246,
      -0.0281804408878088,
      0.0013287096517160535,
      -0.002570513403043151,
      -0.0177951417863369,
      0.01973702758550644,
      0.009069765917956829,
      -0.03792751580476761,
      -0.007240328937768936,
      0.10563240945339203,
      -0.08575030416250229,
      0.09242860227823257,
      -0.11644136905670166,
      0.060842741280794144
    ],
    [
      -0.08906444162130356,
      -0.08435257524251938,
      0.048862945288419724,
      0.017824674025177956,
      0.0801444724202156,
      0.06349669396877289,
      -0.008413469418883324,
      0.02264789678156376,
      -0.010969413444399834,
      -0.08269591629505157,
      0.05504054203629494,
      0.004670399706810713,
      0.014769659377634525,
      -0.07844651490449905,
      0.03129938617348671,
      -0.012433044612407684,
      -0.0034036182332783937,
      0.02192690037190914,
      0.09070295095443726,
      0.05537077784538269,
      0.06231873854994774,
      -0.1442350447177887,
      -0.02159906178712845,
      -0.05024905130267143,
      -0.03424551710486412,
      0.04932844638824463,
      -0.0016563752433285117,
      -0.04854116961359978,
      0.03613629937171936,
      0.09803181141614914,
      0.0081761684268713,
      -0.08157340437173843,
      -0.026500917971134186,
      -0.002985874190926552,
      0.04693995416164398,
      -0.10499589145183563,
      0.005175767466425896,
      -0.04016202688217163,
      0.0073389932513237,
      0.12109266221523285,
      -0.03317578136920929,
      0.0010561177041381598,
      -0.034345321357250214,
      -0.014959882944822311,
      0.013941604644060135,
      -0.04178578034043312,
      0.036624301224946976,
      -0.03981301933526993,
      0.0510706827044487,
      -0.009074929170310497,
      0.007129492238163948,
      -0.03391716629266739,
      0.013860444538295269,
      0.001495016855187714,
      -0.04592301696538925,
      0.016464021056890488,
      0.011894348077476025,
      0.03893088921904564,
      -0.01519901491701603,
      0.058595575392246246,
      -0.008808369748294353,
      -0.0017545471200719476,
      0.0847436711192131,
      0.01949530467391014,
      0.049831029027700424,
      -0.04446063190698624,
      -0.05239848792552948,
      -0.0598738007247448,
      0.0495365634560585,
      0.07341694086790085,
      -0.04127432405948639,
      -0.052232325077056885,
      -0.07850471138954163,
      -0.033446017652750015,
      -0.046131208539009094,
      0.008525902405381203,
      0.0007925385725684464,
      0.04455765336751938,
      -0.034443438053131104,
      0.011704624630510807,
      -0.014630350284278393,
      0.016316896304488182,
      -0.006776901427656412,
      -0.02317998930811882,
      0.059683091938495636,
      0.036119863390922546,
      0.006687910296022892,
      0.03249642252922058,
      0.050030749291181564,
      -0.11212475597858429,
      0.07221680134534836,
      -0.01694480888545513,
      0.10680238157510757,
      -0.03175351023674011,
      -0.08803476393222809,
      -0.03733241558074951,
      -0.03289060667157173,
      -0.006754736881703138,
      0.022026998922228813,
      0.016215194016695023,
      0.06090804561972618,
      -0.09546200186014175,
      0.07490841299295425,
      -0.04029671847820282,
      -0.04125216230750084,
      0.050457119941711426,
      -0.001566092949360609,
      -0.017328163608908653,
      0.09664767235517502,
      0.0014539067633450031,
      0.07349114120006561,
      0.09543483704328537,
      -0.023272322490811348,
      -0.013492361642420292,
      0.026820244267582893,
      -0.011281650513410568,
      -0.1014278456568718,
      -0.043022021651268005,
      -0.11853741854429245,
      0.0026934086345136166,
      0.05660352483391762,
      -0.02575439028441906,
      -0.013902358710765839,
      -0.08479688316583633,
      0.05793651193380356,
      0.08311469107866287,
      0.06752437353134155,
      -0.02210812456905842,
      -0.038816746324300766,
      -0.021870333701372147,
      -0.025605695322155952,
      0.00358595116995275,
      -0.05850891023874283,
      0.10128432512283325,
      0.09023581445217133,
      -0.01428286824375391,
      0.065616175532341,
      -0.043940622359514236,
      0.016381124034523964,
      -0.06427004933357239,
      -0.10659772157669067,
      -0.009577827528119087,
      0.003637592773884535,
      -0.01372084952890873,
      -0.03806110844016075,
      0.07139388471841812,
      0.05700666829943657,
      0.027263574302196503,
      0.029327325522899628,
      0.021522577852010727,
      0.018317772075533867,
      -0.06107689440250397,
      -0.04363285377621651,
      0.021145762875676155,
      -0.01627960242331028,
      0.0432187058031559,
      -0.016692977398633957,
      0.018144071102142334,
      0.054648302495479584,
      -0.0897500216960907,
      0.02483144961297512,
      -0.036294884979724884,
      0.0006038863211870193,
      0.007725014816969633,
      -0.04569677263498306,
      -0.00869669858366251,
      0.04073643311858177,
      -0.03607610985636711,
      0.01758318766951561,
      0.010701696388423443,
      -0.027435289695858955,
      -0.054090067744255066,
      -0.034423425793647766,
      0.02564387209713459,
      0.00436770124360919,
      0.03334605321288109,
      0.09295675903558731,
      -0.017022419720888138,
      -0.04474908113479614,
      -0.02306411974132061,
      -0.0711253434419632,
      -0.10939223319292068,
      0.024506984278559685,
      0.03233664855360985,
      -0.000164281707839109,
      0.0240964125841856,
      0.04733313247561455,
      0.053902409970760345,
      -0.05039765685796738,
      0.061192937195301056,
      -0.06739702075719833,
      0.02819201536476612,
      0.0021360041573643684,
      0.006686701439321041,
      -0.00035145372385159135,
      -0.14367948472499847,
      -0.06650874018669128,
      0.1133495569229126,
      0.05043131485581398,
      0.040871333330869675,
      -0.03758356720209122,
      0.1101059541106224,
      -0.08585153520107269,
      -0.010725852102041245,
      -0.07316889613866806,
      -0.013386196456849575,
      0.011213166639208794,
      0.0046395412646234035,
      0.030021432787179947,
      -0.019758882001042366,
      0.0001882566575659439,
      0.05589626729488373,
      -0.01502931211143732,
      -0.018269192427396774,
      -0.03540661931037903,
      0.0426044687628746,
      0.015250857919454575,
      -0.02781599387526512,
      0.058704957365989685,
      0.03562507778406143,
      0.02441919781267643,
      0.09169122576713562,
      0.013391926884651184,
      -0.08889216184616089,
      0.02415350265800953,
      -0.08952988684177399,
      -0.018145345151424408,
      0.005105640739202499,
      0.02898440696299076,
      0.09563541412353516,
      0.04481199011206627,
      0.02070462331175804,
      -0.004008396062999964,
      -0.054559458047151566,
      -0.017389820888638496,
      0.034963954240083694,
      0.059494007378816605,
      -0.004067453555762768,
      0.15634162724018097,
      0.008456933312118053,
      -0.04075511544942856,
      -0.0790291279554367,
      0.0042257048189640045,
      -0.07873354852199554,
      0.04245230183005333,
      0.012894878163933754,
      0.0002784807584248483,
      0.06393441557884216,
      -0.031694479286670685,
      -0.009694665670394897,
      0.04885102063417435,
      -0.023943819105625153,
      0.0027700576465576887,
      0.02342323586344719,
      0.010906080715358257,
      0.03389459848403931,
      0.06963612139225006,
      0.007034612819552422,
      0.019007613882422447,
      0.09169499576091766,
      0.023136494681239128,
      0.021003488451242447,
      0.005432944744825363,
      0.01501440815627575,
      0.03905126079916954,
      0.0025196566712111235,
      0.042743243277072906,
      -0.014057272113859653,
      0.018367212265729904,
      0.017347846180200577,
      -0.013502167537808418,
      -0.1075354814529419,
      -0.0725996196269989,
      -0.006963747087866068,
      0.021685369312763214,
      0.049563758075237274,
      0.04935012385249138,
      -0.007433511782437563,
      -0.09161441028118134,
      0.012671410106122494,
      0.011820390820503235,
      0.02926860749721527,
      0.06293820589780807,
      0.025014003738760948,
      0.03608996421098709,
      -0.038738708943128586,
      -0.01799415983259678,
      -0.025099871680140495,
      0.024017583578824997,
      -0.011147994548082352,
      0.028701314702630043,
      -0.05914492905139923,
      0.05892303213477135,
      0.06190607696771622,
      0.022175991907715797,
      -0.05392475426197052,
      0.03916972503066063,
      0.023611338809132576,
      -0.026971595361828804,
      0.045145466923713684,
      0.0761854499578476,
      0.022097190842032433,
      -0.023347189649939537,
      0.015719803050160408,
      -0.008236401714384556,
      0.012161782011389732,
      -0.0659622997045517,
      0.0732152909040451,
      -0.025480063632130623,
      0.010557256639003754,
      -0.014025128446519375,
      -0.01078824046999216,
      -0.08218570798635483,
      0.10371562838554382,
      -0.06488606333732605,
      -0.020814988762140274,
      -0.036323945969343185,
      -0.06872502714395523,
      0.015607379376888275,
      0.018041133880615234,
      -0.07342518866062164,
      0.017563873901963234,
      0.06236375868320465,
      -0.049561478197574615,
      0.08509919047355652,
      0.054072313010692596,
      0.001529166242107749,
      -0.08278188854455948,
      0.08630804717540741,
      -0.050188545137643814,
      0.007630165666341782,
      0.012487661093473434,
      0.018404988572001457,
      -0.037875644862651825,
      -0.051924560219049454,
      -0.014108880423009396,
      0.018752412870526314,
      0.041829388588666916,
      -0.041402146220207214,
      -0.007490996271371841,
      -0.02586010843515396,
      0.023884205147624016,
      0.004184396006166935,
      0.12166833132505417,
      -0.07335903495550156,
      -0.011258088052272797,
      -0.00377255748026073,
      -0.009276174940168858,
      0.1075340285897255,
      0.03399624675512314,
      0.03374053165316582,
      -0.032481640577316284,
      -0.005714730359613895,
      0.037654004991054535,
      -0.016974374651908875,
      -1.9275426893727854e-05,
      -0.009319760836660862,
      -0.05858323350548744,
      0.07551009953022003,
      -0.08477802574634552,
      -0.048640429973602295,
      -0.030043890699744225,
      -0.008727277629077435,
      -0.016175052151083946,
      -0.04802930727601051,
      0.017189785838127136,
      0.06079716980457306,
      -0.012987266294658184,
      0.04092225804924965,
      -0.01297538448125124,
      0.0822097435593605,
      0.04262154921889305,
      -0.06648937612771988,
      0.004612250719219446,
      0.025101080536842346,
      -0.0025626260321587324,
      -0.07971813529729843,
      -0.04529421031475067,
      0.03037789650261402,
      -0.0022778604179620743,
      -0.09697877615690231,
      0.06918895244598389,
      -0.055939022451639175,
      0.07597517967224121,
      0.09596426784992218,
      0.028176886960864067,
      -0.05703373998403549,
      -0.029070038348436356,
      0.07991942018270493,
      0.06219390407204628,
      -0.051625192165374756,
      -0.04099195823073387,
      0.038554321974515915,
      0.07087157666683197,
      0.07973042130470276,
      0.06196099519729614,
      0.07679569721221924,
      -0.021485881879925728,
      0.14997932314872742,
      0.06313639879226685,
      0.01757005602121353,
      0.06138901039958,
      0.003595922375097871,
      -0.07656977325677872,
      -0.075846366584301,
      0.0361625999212265,
      -0.03348977491259575,
      0.03992501273751259,
      -0.005508453585207462,
      -0.0010487952968105674,
      -0.09364846348762512,
      0.024393510073423386,
      0.08444610238075256,
      0.012279215268790722,
      0.04132203012704849,
      0.0012458894634619355,
      -0.03931140899658203,
      0.007605259772390127,
      -0.050788089632987976,
      0.0800800770521164,
      0.05223793908953667,
      0.015386554412543774,
      0.018184902146458626,
      0.011365439742803574,
      -0.00885901227593422,
      0.005979197099804878,
      0.04292486980557442,
      -0.05667710676789284,
      -0.021960144862532616,
      0.04522380232810974,
      -0.006992324721068144,
      -0.01848321221768856,
      -0.02725314535200596,
      -0.06747180968523026,
      -0.0016583785181865096,
      0.013903846964240074,
      0.0009535200661048293,
      0.03010096773505211,
      -0.05846073105931282,
      -0.008366690017282963,
      0.03042854554951191,
      -0.013822121545672417,
      0.005609369371086359,
      0.09360381960868835,
      0.0819372609257698,
      -0.01203175075352192,
      -0.003611007472500205,
      -0.027974242344498634,
      -0.018027806654572487,
      0.041191261261701584,
      0.02196597121655941,
      0.07414933294057846,
      0.07394029200077057,
      -0.00929873064160347,
      -0.08119882643222809,
      6.737442163284868e-05,
      0.07323900610208511,
      0.014929086901247501,
      -0.07961731404066086,
      0.0042834775522351265,
      0.04243546724319458,
      -0.027103165164589882,
      0.016998251900076866,
      -0.046850018203258514,
      0.06811237335205078,
      0.012091662734746933,
      -0.09753454476594925,
      0.01362445205450058,
      0.004485448356717825,
      0.049841850996017456,
      0.003344750264659524,
      -0.04306909069418907,
      0.05160927399992943,
      0.011699769645929337,
      0.019408507272601128,
      0.02473066933453083,
      0.07720469683408737,
      0.08364805579185486,
      0.031010702252388,
      -0.05773268640041351,
      -0.015753308311104774,
      0.038281626999378204,
      0.024574721232056618,
      0.006468922831118107,
      -0.03309658169746399,
      0.07496184855699539,
      -0.013711294159293175,
      0.03133561462163925,
      -0.029755324125289917,
      0.0016830567037686706,
      -0.06613337248563766,
      -0.012149769812822342,
      -0.0850011557340622,
      -0.018992610275745392,
      0.006306375842541456,
      0.0499681793153286,
      0.05315743386745453,
      -0.06589894741773605,
      -0.03572605177760124,
      0.01566736027598381,
      0.029108047485351562,
      -0.049339354038238525,
      -0.05686198174953461
    ],
    [
      -0.033701349049806595,
      -0.04176148772239685,
      -0.03527481108903885,
      0.03876631706953049,
      0.01570959761738777,
      -0.03836781904101372,
      0.002684700535610318,
      -0.02028919756412506,
      0.018837416544556618,
      0.008181779645383358,
      -0.05744137614965439,
      -0.022710246965289116,
      0.010358745232224464,
      0.0015389107866212726,
      -0.04913754388689995,
      -0.01076485961675644,
      -0.01078067533671856,
      -0.0072857397608459,
      0.04832969978451729,
      0.035265542566776276,
      -0.04008004441857338,
      0.04951317980885506,
      0.007153320126235485,
      -0.10009054094552994,
      -0.14723411202430725,
      0.05300735682249069,
      0.006481117103248835,
      -0.028135407716035843,
      0.05523207038640976,
      -0.05383247882127762,
      0.031203996390104294,
      0.011323869228363037,
      -0.008865813724696636,
      0.03417442366480827,
      -0.007494353223592043,
      0.03366946801543236,
      0.049219854176044464,
      0.026054320856928825,
      -0.0091963279992342,
      0.06068261340260506,
      -0.06543263792991638,
      0.010945022106170654,
      0.050569456070661545,
      0.0664728581905365,
      0.029298080131411552,
      0.0165934469550848,
      -0.07858146727085114,
      0.03113923966884613,
      -0.06587114185094833,
      -0.0034552752040326595,
      -0.03433310613036156,
      0.08898114413022995,
      -0.0041425093077123165,
      -0.0013749903300777078,
      0.03066408261656761,
      0.060977108776569366,
      -0.06263525038957596,
      0.054800376296043396,
      -0.004574396647512913,
      -0.0002563590824138373,
      0.013269402086734772,
      0.010956342332065105,
      0.06691107153892517,
      -0.05422445759177208,
      0.02670281007885933,
      -0.006418387871235609,
      -0.02091117762029171,
      -0.020552150905132294,
      -0.022120995447039604,
      -0.025519030168652534,
      0.02527894265949726,
      -0.07881379127502441,
      0.0402129627764225,
      -0.050109971314668655,
      0.01562400534749031,
      0.0061783092096447945,
      0.013045713305473328,
      -0.021429695188999176,
      0.029710985720157623,
      -0.1021353155374527,
      -0.04323115199804306,
      0.016066741198301315,
      0.003628623206168413,
      0.03731492906808853,
      -0.10455583781003952,
      -0.008248813450336456,
      -0.008635989390313625,
      -0.011433186009526253,
      0.008221042342483997,
      0.004162613768130541,
      0.06539873778820038,
      -0.005504177417606115,
      0.015284170396625996,
      -0.05057899281382561,
      0.015072861686348915,
      -0.07717675715684891,
      -0.04526368901133537,
      -0.043454062193632126,
      0.00884538609534502,
      0.0937197282910347,
      0.10951704531908035,
      0.03746704012155533,
      0.01437460072338581,
      -0.051273640245199203,
      0.10297654569149017,
      0.048257820308208466,
      -0.019010329619050026,
      0.010508961044251919,
      -0.004376168828457594,
      -0.055168550461530685,
      -0.020978663116693497,
      -0.0016055138548836112,
      -0.03624475747346878,
      0.0038955623749643564,
      -0.006106556858867407,
      0.037025533616542816,
      -0.042575541883707047,
      0.055361900478601456,
      -0.03986257687211037,
      0.04812798276543617,
      -0.005441917106509209,
      0.01384073868393898,
      -0.0323796272277832,
      0.07337907701730728,
      -0.07615330815315247,
      -0.021680468693375587,
      -0.027618752792477608,
      0.021979618817567825,
      -0.03962685540318489,
      -0.01808641292154789,
      0.01992536336183548,
      0.008137216791510582,
      0.02216057851910591,
      0.0063807847909629345,
      0.052849333733320236,
      -0.052814193069934845,
      0.09133931994438171,
      -0.033778272569179535,
      0.04731902852654457,
      0.01146584004163742,
      0.057310909032821655,
      0.021130738779902458,
      -0.030278800055384636,
      0.04933307692408562,
      0.07702924311161041,
      0.04106299579143524,
      0.05197581276297569,
      0.01790984347462654,
      -0.02860158495604992,
      0.05643994361162186,
      0.061682190746068954,
      -0.04318346455693245,
      0.033593881875276566,
      0.0029486233834177256,
      0.01697830855846405,
      -0.013625969178974628,
      0.019271640107035637,
      0.010693945921957493,
      -0.026941856369376183,
      0.04309859499335289,
      -0.0011807105038315058,
      -0.03632698208093643,
      -0.06620486080646515,
      0.02358449250459671,
      0.002503344090655446,
      0.04313584789633751,
      -0.0895506963133812,
      -0.04585534706711769,
      0.036902640014886856,
      -0.007107349578291178,
      -0.03451593965291977,
      0.03938201069831848,
      -0.06195170432329178,
      0.06270278990268707,
      0.03678346425294876,
      0.04337921738624573,
      -0.013255245052278042,
      -0.07681943476200104,
      0.013867788016796112,
      0.008649058640003204,
      -0.0440535694360733,
      -0.03259711340069771,
      -0.02524441108107567,
      -0.017816878855228424,
      0.040228888392448425,
      -0.037549134343862534,
      0.10153506696224213,
      -0.01632418856024742,
      -0.019394131377339363,
      0.006424048915505409,
      0.05777965113520622,
      -0.0015399358235299587,
      -0.0023813117295503616,
      0.05217622220516205,
      0.016883717849850655,
      0.0268996711820364,
      0.022985972464084625,
      0.03825787454843521,
      0.04711507260799408,
      -0.03915766254067421,
      -0.011477305553853512,
      0.03533676266670227,
      0.047982107847929,
      0.05604403465986252,
      -0.03806459531188011,
      0.006684822496026754,
      -0.07283646613359451,
      0.07619794458150864,
      0.009153997525572777,
      -0.09176617860794067,
      0.003490036353468895,
      0.040390755981206894,
      -0.05575627461075783,
      0.024880211800336838,
      -0.002981719793751836,
      0.008655798621475697,
      0.0038245420437306166,
      -0.019038541242480278,
      0.09326505661010742,
      0.026249822229146957,
      0.007813870906829834,
      0.046270448714494705,
      0.01286134123802185,
      0.12218563258647919,
      0.0663679763674736,
      -0.07396310567855835,
      0.07477742433547974,
      0.025606270879507065,
      -0.043461497873067856,
      0.06685883551836014,
      -0.03676491230726242,
      -0.00486094132065773,
      0.04286420717835426,
      0.03960304334759712,
      0.05852992832660675,
      -0.03492480143904686,
      -0.008225718513131142,
      -0.008844765834510326,
      -0.008114950731396675,
      0.07485360652208328,
      -0.016956113278865814,
      0.045974429696798325,
      0.020354146137833595,
      -0.007457895204424858,
      0.051771655678749084,
      0.008277440443634987,
      -0.040252745151519775,
      0.0361330546438694,
      -0.028406182304024696,
      -0.0001934722240548581,
      0.025109535083174706,
      0.042938146740198135,
      -0.08022534847259521,
      -0.028951503336429596,
      0.00031291210325434804,
      0.04391178488731384,
      -0.08234965801239014,
      0.0047730072401463985,
      -0.04620910808444023,
      0.05857149511575699,
      -0.08040600270032883,
      -0.013074838556349277,
      -0.013090685941278934,
      0.055522315204143524,
      0.06350036710500717,
      -0.05036166310310364,
      0.03653747960925102,
      -0.03903514891862869,
      -0.04002126306295395,
      -0.039841651916503906,
      0.07896984368562698,
      0.056442078202962875,
      0.03221217915415764,
      0.013769393786787987,
      0.020194919779896736,
      -0.017868248745799065,
      0.0639650747179985,
      -0.04347376525402069,
      -0.009097104892134666,
      0.0032244957983493805,
      -0.013393804430961609,
      -0.04770606756210327,
      -0.021371671929955482,
      -0.049631714820861816,
      -0.023524152114987373,
      0.04871269315481186,
      -0.0333075150847435,
      0.01658892259001732,
      0.03655817732214928,
      0.01893935538828373,
      -0.05862128734588623,
      0.02093484252691269,
      0.020564712584018707,
      -0.002579420106485486,
      -0.03373850882053375,
      0.009332292713224888,
      -0.011976736597716808,
      0.050183817744255066,
      0.09197831898927689,
      -0.0036994267720729113,
      -0.05896925926208496,
      -0.07206373661756516,
      -0.0026802904903888702,
      -0.10283350199460983,
      -0.01254433486610651,
      0.04540884867310524,
      0.07384804636240005,
      -0.03358425572514534,
      0.09816578030586243,
      -0.0046921600587666035,
      -0.05841377004981041,
      -0.029443252831697464,
      -0.06875889748334885,
      0.028849946334958076,
      0.05507306754589081,
      0.011793135665357113,
      -0.017043227329850197,
      -0.01663798838853836,
      -0.0076687694527208805,
      -0.000661333033349365,
      -0.09467515349388123,
      -0.02886265143752098,
      -0.025909477844834328,
      -0.021067824214696884,
      -0.02303815633058548,
      0.033854853361845016,
      -0.024234367534518242,
      0.028676025569438934,
      0.04781147092580795,
      0.01856253854930401,
      -0.08354801684617996,
      -0.07143557816743851,
      -0.018853750079870224,
      0.05298081785440445,
      0.021601060405373573,
      0.018206322565674782,
      0.07065258920192719,
      0.00326506607234478,
      0.020210370421409607,
      0.06293699890375137,
      -0.01412916835397482,
      0.042040616273880005,
      0.024110764265060425,
      0.05994237959384918,
      -0.053291525691747665,
      -0.052328161895275116,
      -0.04499651864171028,
      0.029558168724179268,
      0.055140700191259384,
      -0.021961966529488564,
      0.04105111211538315,
      0.014357770793139935,
      0.017723318189382553,
      -0.03643786162137985,
      -0.10596735030412674,
      -0.05448025092482567,
      0.05453783646225929,
      0.0310821495950222,
      0.032110027968883514,
      -0.0056265429593622684,
      0.01711634360253811,
      -0.042468711733818054,
      -0.0022115856409072876,
      -0.0011823591776192188,
      0.01670216955244541,
      -0.00642959913238883,
      0.07399735599756241,
      -0.0439714752137661,
      0.07901512086391449,
      -0.025984320789575577,
      0.0327627956867218,
      -0.03677692264318466,
      -0.008335881866514683,
      -0.022778749465942383,
      0.01609153300523758,
      -0.019313162192702293,
      -0.038102488964796066,
      -0.022447584196925163,
      0.047949377447366714,
      6.17011683061719e-05,
      -0.009240956045687199,
      -0.03396264463663101,
      0.0767354741692543,
      0.08253328502178192,
      -0.005169832147657871,
      -0.027055948972702026,
      -0.04771485924720764,
      -0.02297033742070198,
      0.10576526075601578,
      0.01023669820278883,
      -0.03906601294875145,
      -0.03212786465883255,
      0.004792745225131512,
      -0.08436807990074158,
      0.015690069645643234,
      -0.03557749092578888,
      -0.057821109890937805,
      0.04999140277504921,
      0.031879324465990067,
      -0.009861011058092117,
      0.02852124162018299,
      -0.023650560528039932,
      0.049048442393541336,
      -0.03790421038866043,
      -0.03750643879175186,
      -0.03077915869653225,
      -0.0742347463965416,
      -0.06236667186021805,
      -0.04283913969993591,
      -0.05817181244492531,
      0.01181101892143488,
      -0.0035792312119156122,
      0.06089579313993454,
      -0.028020868077874184,
      -0.004698335193097591,
      0.020646091550588608,
      -0.1029612198472023,
      0.01797117479145527,
      0.051309458911418915,
      -0.015863541513681412,
      0.026993505656719208,
      0.04537540674209595,
      0.0739402174949646,
      0.03868576139211655,
      -0.001877081347629428,
      0.0030948854982852936,
      -0.06826279312372208,
      -0.021661650389432907,
      -0.011951979249715805,
      0.03996480256319046,
      -0.07905299961566925,
      0.060613278299570084,
      0.04182795435190201,
      -0.004152875393629074,
      -0.057255759835243225,
      0.08362496644258499,
      0.02355370856821537,
      0.08657082915306091,
      0.01379187498241663,
      -0.021007128059864044,
      -0.07289457321166992,
      0.04799226298928261,
      -0.06239990517497063,
      0.045797329396009445,
      -0.025379624217748642,
      -0.024414805695414543,
      -0.002354517113417387,
      0.0735512226819992,
      -0.018862511962652206,
      0.09144595265388489,
      0.03187914192676544,
      0.00991608016192913,
      0.024495095014572144,
      -0.015592345967888832,
      -0.028807008638978004,
      0.04591371864080429,
      0.10149344801902771,
      -0.0007536015473306179,
      -0.05125855281949043,
      0.024473799392580986,
      -0.08239050954580307,
      0.03107438236474991,
      -0.024988004937767982,
      0.06770234555006027,
      0.060706693679094315,
      0.06494399160146713,
      0.025967273861169815,
      -0.0006835369858890772,
      -0.024334032088518143,
      -0.009553235024213791,
      -0.04007941856980324,
      -0.054400451481342316,
      0.010771124623715878,
      -0.0062879291363060474,
      0.014448575675487518,
      0.07093846797943115,
      0.08471355587244034,
      0.007374643348157406,
      -0.04064096510410309,
      -0.022361861541867256,
      0.005030735861510038,
      0.003703892696648836,
      0.012518470175564289,
      0.024813318625092506,
      0.013925821520388126,
      -0.01836167462170124,
      0.004708928521722555,
      0.050418026745319366,
      0.07936917990446091,
      -0.03342023864388466,
      -0.052507322281599045,
      0.0564240999519825,
      -0.005620797164738178,
      0.027296751737594604,
      0.059087421745061874,
      0.020243890583515167,
      -0.013365481048822403,
      -0.005142234731465578,
      -0.04410921409726143,
      -0.021691838279366493,
      -0.06115146353840828,
      -0.07150117307901382,
      0.024300020188093185
    ],
    [
      -0.002655110554769635,
      0.02196035534143448,
      -0.08682703971862793,
      0.035317592322826385,
      0.11598481237888336,
      -0.01818516105413437,
      0.061346035450696945,
      -0.032740235328674316,
      -0.03303268179297447,
      -0.009188448078930378,
      0.009548226371407509,
      0.029341965913772583,
      0.04015187546610832,
      0.11956346780061722,
      -0.11929479241371155,
      -0.000279069208772853,
      -0.0013007414527237415,
      -0.07077836990356445,
      -0.000670317152980715,
      -0.005305135156959295,
      -0.028893833979964256,
      0.023854950442910194,
      -0.04801163449883461,
      -0.03297533839941025,
      -0.01194562017917633,
      0.010810911655426025,
      0.02040817402303219,
      0.006107802037149668,
      -0.00395866297185421,
      0.008609278127551079,
      -0.0016994139878079295,
      0.05168687179684639,
      -0.012553079053759575,
      -0.03471342474222183,
      0.02581702545285225,
      -0.08171332627534866,
      -0.07774095237255096,
      0.03718298673629761,
      0.007494706194847822,
      -0.0626087635755539,
      -0.07999496161937714,
      -0.0708775594830513,
      -0.019080759957432747,
      0.03000331111252308,
      3.659546564449556e-06,
      0.0031859625596553087,
      -0.0518370047211647,
      0.012374737299978733,
      -0.06213870644569397,
      0.04138944298028946,
      -0.00904824584722519,
      0.023152239620685577,
      -0.044140320271253586,
      0.03543439507484436,
      -0.03979896008968353,
      -0.06487257033586502,
      0.006295070517808199,
      0.06816066801548004,
      -0.008702131919562817,
      -0.022419381886720657,
      -0.013238434679806232,
      -0.0032341096084564924,
      -0.03911375254392624,
      0.012731439433991909,
      0.018994130194187164,
      -0.007753313519060612,
      -0.06206071004271507,
      0.017031989991664886,
      -0.022621160373091698,
      0.022639455273747444,
      0.022815415635704994,
      0.023522285744547844,
      0.027965690940618515,
      0.0385558195412159,
      0.024028483778238297,
      0.07016157358884811,
      -0.0066749658435583115,
      0.028004612773656845,
      -0.027135606855154037,
      0.014568456448614597,
      0.05070269852876663,
      0.0043631684966385365,
      -0.0429564006626606,
      0.06908605992794037,
      -0.016472699120640755,
      -0.028161972761154175,
      0.014828191138803959,
      -0.04738980159163475,
      -0.007641959004104137,
      0.03376316651701927,
      -0.04826201871037483,
      -0.0019760827999562025,
      -0.0038015206810086966,
      -0.018960731104016304,
      0.0258278027176857,
      0.09508312493562698,
      0.0033493759110569954,
      0.00338146579451859,
      0.03546667471528053,
      0.06576447188854218,
      -0.04309520125389099,
      0.0609726756811142,
      0.04566841199994087,
      -0.04996703565120697,
      0.06577244400978088,
      0.015211560763418674,
      0.00648777699097991,
      0.011600342579185963,
      0.10668075829744339,
      0.007680158130824566,
      -0.019828934222459793,
      -0.044750142842531204,
      0.01391644962131977,
      0.0012352238409221172,
      -0.030652398243546486,
      0.014905029907822609,
      0.06685137003660202,
      0.02142409421503544,
      -0.011779611930251122,
      0.029919670894742012,
      0.08257591724395752,
      -0.04126228019595146,
      0.010240158066153526,
      0.06609655916690826,
      0.01087074913084507,
      -0.04533865302801132,
      0.06621872633695602,
      0.008934605866670609,
      0.024515245109796524,
      0.025665227323770523,
      -0.07476967573165894,
      -0.007253692951053381,
      0.03327897936105728,
      -0.0010404926724731922,
      0.02861100248992443,
      0.015045484527945518,
      -0.000806864001788199,
      0.018661754205822945,
      0.006209720857441425,
      0.030148431658744812,
      -0.036917977035045624,
      -0.03373683616518974,
      0.013335414230823517,
      0.04906274378299713,
      0.05346297845244408,
      0.03490418195724487,
      0.005066432058811188,
      -0.01328299380838871,
      -0.031118197366595268,
      0.012753174640238285,
      0.03076435998082161,
      -0.004160360433161259,
      -0.027627596631646156,
      -0.0061371466144919395,
      -0.016314206644892693,
      -0.008259211666882038,
      -0.008040552958846092,
      0.03150936961174011,
      0.04650784656405449,
      0.015028312802314758,
      0.06811593472957611,
      0.028498617932200432,
      -0.0010656171943992376,
      -0.011015493422746658,
      0.06625032424926758,
      0.022492237389087677,
      -0.045162640511989594,
      0.047748684883117676,
      0.010371515527367592,
      -0.024207143113017082,
      0.005782850086688995,
      -0.044644881039857864,
      -0.0159151628613472,
      0.03976936638355255,
      -0.07319408655166626,
      -0.08317524939775467,
      -0.00785073172301054,
      0.06434092670679092,
      -0.05903146043419838,
      0.018075723201036453,
      0.0030601308681070805,
      0.059766985476017,
      0.03886045888066292,
      -0.047987062484025955,
      -0.049748651683330536,
      0.0036721783690154552,
      -0.015056109055876732,
      0.042705003172159195,
      0.04260899871587753,
      -0.00319008924998343,
      -0.007338682655245066,
      0.059262923896312714,
      0.041636526584625244,
      0.0003398061962798238,
      -0.03092673420906067,
      0.015754181891679764,
      -0.01814284920692444,
      0.010017726570367813,
      -0.04819629341363907,
      -0.07438559085130692,
      0.011242437176406384,
      -0.03139377385377884,
      0.03590734302997589,
      0.023087408393621445,
      0.003526490880176425,
      -0.017278097569942474,
      -0.02397453784942627,
      0.0029490808956325054,
      -0.01660998910665512,
      0.005439148750156164,
      -0.018770843744277954,
      -0.02751341089606285,
      0.06329789757728577,
      -0.03227091580629349,
      0.11685676127672195,
      -0.030726656317710876,
      0.004467800259590149,
      0.017918478697538376,
      0.0009525895002298057,
      0.043491024523973465,
      -0.037500910460948944,
      -0.016480160877108574,
      -0.01775967888534069,
      -0.04498978331685066,
      0.032722629606723785,
      0.04755502566695213,
      0.04575667530298233,
      0.012408745475113392,
      -0.10722209513187408,
      0.06419903039932251,
      0.0252453051507473,
      -9.656242764322087e-05,
      0.01525585725903511,
      0.03842491656541824,
      0.011830992996692657,
      0.05812951549887657,
      -0.01804942637681961,
      0.04499868303537369,
      0.03921031951904297,
      -0.06744207441806793,
      0.013360419310629368,
      0.04241865500807762,
      0.0670529380440712,
      -0.09952381998300552,
      0.036067619919776917,
      -0.03418483957648277,
      0.005517075303941965,
      0.0260439682751894,
      0.008317656815052032,
      -0.00857488065958023,
      -0.02297128178179264,
      -0.05635841563344002,
      0.001632616389542818,
      -0.018149036914110184,
      -0.007975248619914055,
      0.02723507024347782,
      -0.021420378237962723,
      -0.0012758250813931227,
      -0.03437189385294914,
      -0.030955757945775986,
      0.0418177954852581,
      -0.028582923114299774,
      -0.018797529861330986,
      0.030051754787564278,
      -0.028806015849113464,
      -0.007892102003097534,
      -0.0036315068136900663,
      0.006294415798038244,
      -0.06614755094051361,
      -0.08287187665700912,
      0.023090705275535583,
      -0.004647207446396351,
      -0.028477288782596588,
      0.04867018386721611,
      -0.010001587681472301,
      -0.032208267599344254,
      -0.017586905509233475,
      0.013984712772071362,
      -0.10620812326669693,
      -0.0026268085930496454,
      -0.04997573420405388,
      0.022142386063933372,
      0.011604097671806812,
      0.018353546038269997,
      -0.012509729713201523,
      -0.03175126761198044,
      0.03661641851067543,
      -0.01498398371040821,
      -0.020128127187490463,
      -0.015588562935590744,
      -0.009969035163521767,
      0.021184654906392097,
      0.02332957088947296,
      0.00816284865140915,
      0.026454763486981392,
      -0.028986699879169464,
      0.002150736516341567,
      0.034380506724119186,
      0.02143552340567112,
      -0.00539161404594779,
      0.05294318124651909,
      -0.03811664879322052,
      0.035816628485918045,
      -0.02431575581431389,
      0.04992356523871422,
      0.03706079721450806,
      0.007267249748110771,
      -0.0161347184330225,
      -0.0062765092588961124,
      0.07981457561254501,
      -0.011155720800161362,
      0.045307058840990067,
      0.09973607212305069,
      -0.043426040560007095,
      -0.07307180762290955,
      0.10260193794965744,
      -0.0013064020313322544,
      0.06440907716751099,
      -0.05259549245238304,
      0.012322920374572277,
      0.005228853784501553,
      -0.03367313742637634,
      0.02062956802546978,
      0.01121655572205782,
      0.007454920094460249,
      -0.04688702151179314,
      -0.018612680956721306,
      0.039697371423244476,
      0.09926129132509232,
      -0.02888757362961769,
      0.0789431482553482,
      -0.01653599739074707,
      -0.03361862152814865,
      0.11986444890499115,
      0.051003824919462204,
      0.0718432366847992,
      0.092050701379776,
      -0.01399094145745039,
      -0.016032693907618523,
      -0.0016190822934731841,
      0.03189810737967491,
      0.046087250113487244,
      0.032685983926057816,
      0.003092619124799967,
      0.024236982688307762,
      -0.002530454657971859,
      -0.04040998965501785,
      -0.0011639363365247846,
      -0.02416026033461094,
      0.02666352316737175,
      0.0633460059762001,
      0.03211900591850281,
      0.03192601725459099,
      -0.018364764750003815,
      0.0867464542388916,
      -0.03408852964639664,
      -0.03156501427292824,
      7.3013681685552e-05,
      -0.09659921377897263,
      0.03289016708731651,
      -0.02069096639752388,
      -0.031045690178871155,
      -0.030074618756771088,
      -0.03781154751777649,
      -0.03788544982671738,
      0.02501918561756611,
      0.03885696083307266,
      -0.0050397650338709354,
      -0.0011340047931298614,
      -0.04694719985127449,
      -0.008461938239634037,
      -0.07507075369358063,
      0.026670655235648155,
      -0.031679145991802216,
      0.01959807425737381,
      -0.019606376066803932,
      0.003270089393481612,
      -0.05277317762374878,
      -0.028932107612490654,
      0.07381059974431992,
      -0.016748657450079918,
      0.03026546724140644,
      0.004852876532822847,
      0.03278310224413872,
      0.05303057283163071,
      -0.03232572600245476,
      0.041773296892642975,
      -0.07368627190589905,
      0.022870317101478577,
      -0.011642671190202236,
      0.019512759521603584,
      0.02002173475921154,
      0.05287519469857216,
      0.05962412431836128,
      -0.004811807069927454,
      -0.0038133657071739435,
      0.040698882192373276,
      0.05961776524782181,
      0.00831220019608736,
      -0.05815892294049263,
      0.02396359108388424,
      0.08138395100831985,
      0.028788309544324875,
      0.025885319337248802,
      0.008082414977252483,
      -0.044943612068891525,
      0.004582597874104977,
      -0.03251488506793976,
      0.0007198360981419683,
      -0.07145273685455322,
      0.0011622891761362553,
      0.08586416393518448,
      -0.031494129449129105,
      0.02393728494644165,
      0.01569581963121891,
      0.028759442269802094,
      -0.002647243905812502,
      -0.029220882803201675,
      -0.03707900270819664,
      0.02817392908036709,
      0.027071397751569748,
      0.010524381883442402,
      -0.0044068582355976105,
      -0.014909173361957073,
      0.017981424927711487,
      0.02126093953847885,
      -0.022931700572371483,
      0.009198350831866264,
      -0.07924801111221313,
      -0.014105206355452538,
      -0.03259473294019699,
      0.05106467008590698,
      -0.01008978858590126,
      0.058959122747182846,
      -0.03323737159371376,
      -0.053094856441020966,
      0.048552002757787704,
      0.010480642318725586,
      -0.056037597358226776,
      0.008655913174152374,
      -0.024658603593707085,
      0.04965177923440933,
      -0.01826041378080845,
      -0.0008821352384984493,
      -0.019216448068618774,
      0.031105762347579002,
      -0.047164130955934525,
      0.025009578093886375,
      -0.07798328250646591,
      -0.05808761715888977,
      -0.004005216993391514,
      -0.0170546006411314,
      -0.04705984145402908,
      0.028946993872523308,
      -0.08032016456127167,
      0.05582095682621002,
      0.03624814748764038,
      0.04527309536933899,
      -0.06317067891359329,
      0.0028395417612046003,
      0.05135827884078026,
      -0.04599316418170929,
      0.0663054883480072,
      0.015046861954033375,
      -0.011926572769880295,
      -0.08086960762739182,
      -0.046525828540325165,
      0.00863596796989441,
      0.0752759799361229,
      0.04168607294559479,
      0.04746239632368088,
      0.07523847371339798,
      -0.009900425560772419,
      -0.04574884474277496,
      0.03819400817155838,
      0.045307524502277374,
      -0.027822401374578476,
      0.0037814124953001738,
      0.06028914824128151,
      -0.006646729074418545,
      0.039989739656448364,
      0.03655434772372246,
      0.024163400754332542,
      0.023655276745557785,
      0.002868888434022665,
      -0.02156265266239643,
      -0.017887108027935028,
      0.009088587015867233,
      0.011041214689612389,
      -0.003294436726719141,
      -0.03512824326753616,
      -0.03538339585065842,
      -0.06593277305364609,
      0.034287020564079285,
      -0.009635060094296932,
      0.03510771319270134,
      -0.0011052991030737758,
      -0.14788086712360382,
      -0.04944206401705742,
      -0.03937099128961563,
      -0.039505645632743835,
      -0.018103696405887604,
      -0.0040821353904902935
    ],
    [
      0.02281014434993267,
      0.012893316335976124,
      0.019285617396235466,
      -0.05969739705324173,
      -0.010376258753240108,
      -0.002109688241034746,
      0.05375063419342041,
      -0.053261443972587585,
      0.04329720139503479,
      -0.015042250044643879,
      -0.016348421573638916,
      0.025870803743600845,
      0.0063611106015741825,
      0.08400000631809235,
      -0.032407741993665695,
      -0.013644097372889519,
      0.010352116078138351,
      0.05386722460389137,
      0.005596335977315903,
      -0.0021134158596396446,
      0.03271152079105377,
      -0.0528186559677124,
      -0.023621659725904465,
      0.05949942767620087,
      0.06553828716278076,
      0.029591763392090797,
      -0.004653026349842548,
      -0.059931833297014236,
      -0.03117763251066208,
      0.02032727189362049,
      0.06835603713989258,
      -0.01050820853561163,
      -0.05742988362908363,
      -0.03867768496274948,
      -0.018738973885774612,
      -0.015403416007757187,
      0.07416652143001556,
      -0.05096185952425003,
      0.020976370200514793,
      0.014136155135929585,
      -0.09984999150037766,
      0.01376026589423418,
      -0.01987018994987011,
      0.01199550274759531,
      -0.06576056778430939,
      -0.03243935480713844,
      -0.02153198793530464,
      0.05473613366484642,
      -0.01608331874012947,
      -0.0011986156459897757,
      0.0029980798717588186,
      -0.03492103889584541,
      -0.018880600109696388,
      -0.03333699703216553,
      0.026700159534811974,
      -0.03669515624642372,
      -0.007930055260658264,
      0.024986455217003822,
      -0.0031268023885786533,
      -0.00675591267645359,
      -0.03388581424951553,
      0.02834213711321354,
      0.029808837920427322,
      -0.04643376171588898,
      -0.019131405279040337,
      -0.030240502208471298,
      0.01244199089705944,
      -0.04031744599342346,
      -0.06060875579714775,
      0.025858469307422638,
      0.028654171153903008,
      -0.031705036759376526,
      -0.04927901178598404,
      0.012841260060667992,
      -0.022580867633223534,
      0.04065022990107536,
      0.09285227209329605,
      -0.05567747354507446,
      0.011756060644984245,
      0.03019612282514572,
      -0.0009003338636830449,
      0.04405179247260094,
      -0.09900090843439102,
      0.13236871361732483,
      -0.044291287660598755,
      0.02498617395758629,
      -0.014561386778950691,
      -0.012093345634639263,
      0.07439220696687698,
      -0.08376791328191757,
      -0.07780700922012329,
      0.034193675965070724,
      0.09888108819723129,
      0.03672520071268082,
      0.0645245611667633,
      -0.057151928544044495,
      -0.03609194606542587,
      0.001675787614658475,
      -0.08051516860723495,
      0.015206512995064259,
      -0.022768227383494377,
      0.04056662321090698,
      -0.0010011446429416537,
      0.02032800205051899,
      -0.011722557246685028,
      -0.05100550502538681,
      -0.05524750053882599,
      0.012534372508525848,
      0.0038701477460563183,
      0.05001703277230263,
      0.07112911343574524,
      0.03134710341691971,
      -0.01766122691333294,
      0.07519735395908356,
      0.02629145421087742,
      0.017478499561548233,
      -0.021949103102087975,
      -0.041408970952034,
      -0.035697098821401596,
      -0.020500732585787773,
      -0.01542914379388094,
      0.07184769958257675,
      0.03226257115602493,
      -0.04362280294299126,
      0.005051506217569113,
      -0.015973668545484543,
      -0.008588973432779312,
      0.08804401755332947,
      0.04857229068875313,
      0.013664377853274345,
      0.008559477515518665,
      -0.05577930063009262,
      -0.04533784091472626,
      0.0009470630902796984,
      0.006895812228322029,
      -0.06750334054231644,
      0.08851703256368637,
      -0.04251958802342415,
      0.01212023850530386,
      0.04162286967039108,
      0.03570876643061638,
      -0.0053182062692940235,
      -0.003928008023649454,
      -0.08371259272098541,
      0.06484121084213257,
      -0.0482468418776989,
      -0.019046543166041374,
      0.023889269679784775,
      0.035032160580158234,
      -0.07937580347061157,
      -0.0476347878575325,
      0.030397482216358185,
      -0.012309065088629723,
      0.014174803160130978,
      0.019197920337319374,
      0.06940489262342453,
      0.04815569892525673,
      0.02561585232615471,
      0.0586501881480217,
      0.037304747849702835,
      0.0060421740636229515,
      -0.004090947564691305,
      0.08074140548706055,
      -0.04601548984646797,
      0.06083054840564728,
      0.04461808502674103,
      -0.010314084589481354,
      -0.031291816383600235,
      -0.003943734802305698,
      -0.02687440812587738,
      -0.037122681736946106,
      -0.0341220460832119,
      -0.0019121546065434813,
      -0.022639475762844086,
      0.11548139154911041,
      -0.021137474104762077,
      -0.09176593273878098,
      -0.043585047125816345,
      -0.11147565394639969,
      -0.010241792537271976,
      0.01543924119323492,
      -0.027278827503323555,
      -0.04854034632444382,
      0.007569313049316406,
      -0.006029155571013689,
      0.014511502347886562,
      -0.04763517901301384,
      -0.002629577647894621,
      0.01053990051150322,
      0.037611450999975204,
      0.011747932992875576,
      -0.03791157901287079,
      0.06307239085435867,
      -0.02897646650671959,
      0.0018510962836444378,
      -0.049759794026613235,
      0.0038151266053318977,
      0.006212523207068443,
      -0.0011186301708221436,
      0.05870261788368225,
      0.03684503585100174,
      -0.05887545645236969,
      0.026613278314471245,
      0.0015048598870635033,
      -0.07660391926765442,
      0.010689173825085163,
      0.013673784211277962,
      -0.07319360971450806,
      -0.052537702023983,
      0.011686006560921669,
      0.05032527819275856,
      -0.10063938796520233,
      -0.011900038458406925,
      0.04519537091255188,
      -0.03352876007556915,
      -0.020132889971137047,
      0.0063159954734146595,
      0.01435060054063797,
      0.0051305415108799934,
      0.03804032877087593,
      0.017672240734100342,
      -0.06280674040317535,
      0.042129479348659515,
      0.03007291443645954,
      -0.08783581852912903,
      0.016070688143372536,
      -0.06948479264974594,
      0.03259114548563957,
      0.046492937952280045,
      -0.004062705673277378,
      -0.030437612906098366,
      0.04173744097352028,
      -0.0026148026809096336,
      -0.04343080148100853,
      0.05476660653948784,
      0.0075538563542068005,
      -0.05791977420449257,
      0.029724879190325737,
      -0.009806690737605095,
      -0.036194466054439545,
      -0.06586070358753204,
      -0.004990567918866873,
      0.08728393167257309,
      0.013005941174924374,
      0.03455531597137451,
      0.004982009995728731,
      0.05465616658329964,
      0.01973026432096958,
      0.02992199920117855,
      0.03193239867687225,
      -0.05475842207670212,
      -0.014946101233363152,
      0.06899168342351913,
      0.02327367104589939,
      -0.02893306128680706,
      -0.042487043887376785,
      -0.04091181606054306,
      -0.12164288759231567,
      1.0708345143939368e-07,
      -0.03246689587831497,
      -0.04910613223910332,
      -0.06205018237233162,
      -0.06679430603981018,
      0.07402545213699341,
      -0.08109918981790543,
      0.01230166107416153,
      0.04034070298075676,
      0.04649854823946953,
      -0.050060778856277466,
      0.03240632638335228,
      0.06366731971502304,
      -0.015696587041020393,
      -0.01275902334600687,
      0.014438909478485584,
      0.02537701278924942,
      -0.003208071691915393,
      0.01018442865461111,
      0.03237592428922653,
      0.028432808816432953,
      -0.07396439462900162,
      0.03604315221309662,
      0.029321519657969475,
      0.07258694618940353,
      0.11396913230419159,
      -0.04129955917596817,
      0.011197695508599281,
      0.026801053434610367,
      0.012206938117742538,
      0.029770400375127792,
      0.013125982135534286,
      -0.06291097402572632,
      0.021977435797452927,
      -0.07611633837223053,
      0.0592842698097229,
      -0.012713974341750145,
      0.01444066409021616,
      0.0802253857254982,
      0.010739014483988285,
      0.06469529867172241,
      0.14398297667503357,
      -0.019202589988708496,
      0.06503254175186157,
      0.0873117670416832,
      0.052225206047296524,
      -0.057124171406030655,
      0.0729217678308487,
      -0.018623076379299164,
      -0.047629762440919876,
      -0.04779287055134773,
      0.0694134458899498,
      -0.03808218240737915,
      -0.025881558656692505,
      0.007601737044751644,
      -0.052007149904966354,
      -0.022998807951807976,
      0.002611387986689806,
      0.036002665758132935,
      -0.012903507798910141,
      0.055304452776908875,
      -0.024642914533615112,
      0.09431210160255432,
      0.014824376441538334,
      0.048723991960287094,
      0.041654009371995926,
      -0.0134024228900671,
      -0.06940866261720657,
      -0.023330267518758774,
      -0.0027903029695153236,
      0.043761350214481354,
      0.03853769227862358,
      0.006737265270203352,
      -0.04062556475400925,
      0.034532442688941956,
      0.01691589504480362,
      -0.009931826032698154,
      -0.08861663937568665,
      0.003128951881080866,
      -0.02461346611380577,
      0.022382525727152824,
      -0.011077462695538998,
      -0.028840670362114906,
      0.016190847381949425,
      -0.009749196469783783,
      -0.07557035982608795,
      0.052190519869327545,
      0.030290799215435982,
      -0.06309469789266586,
      -0.02061283029615879,
      0.0009944832418113947,
      0.033026594668626785,
      0.017372475937008858,
      0.017737144604325294,
      -0.07637599855661392,
      0.018428118899464607,
      0.08507449924945831,
      -0.044714126735925674,
      -0.0470087006688118,
      0.01444897148758173,
      -0.025601999834179878,
      0.08009599149227142,
      0.05580949783325195,
      0.05042845755815506,
      -0.00741925835609436,
      0.019067831337451935,
      -0.014074563980102539,
      0.03475618734955788,
      0.007703000213950872,
      0.0367332324385643,
      0.05892210081219673,
      -0.01873721182346344,
      -0.0482790432870388,
      0.024649180471897125,
      -0.051856111735105515,
      0.10385358333587646,
      0.06634917110204697,
      -0.07545293867588043,
      0.0007355135749094188,
      -0.04060111567378044,
      -0.04602576047182083,
      -0.03628023713827133,
      -0.05351077765226364,
      0.009900661185383797,
      -0.04982391744852066,
      -0.06171531602740288,
      -0.003133096732199192,
      0.06751290708780289,
      -0.03895801678299904,
      -0.015719087794423103,
      -0.02076420746743679,
      -0.049976348876953125,
      0.07694602012634277,
      -0.010064790025353432,
      0.04244442656636238,
      0.029195504263043404,
      0.08026481419801712,
      0.022351618856191635,
      0.028905309736728668,
      0.0045660012401640415,
      -0.07385995239019394,
      -0.030759470537304878,
      -0.015601723454892635,
      0.0005147768533788621,
      0.024374717846512794,
      0.022349214181303978,
      -0.00392517726868391,
      -0.010293575935065746,
      -0.03063488006591797,
      -0.01752401702105999,
      -0.0005424254923127592,
      -0.02259356901049614,
      -0.052576206624507904,
      -0.08119171857833862,
      0.04414866864681244,
      -0.07456936687231064,
      -0.04096400737762451,
      0.051518313586711884,
      0.09120066463947296,
      -0.02134755440056324,
      -0.003153098514303565,
      0.007493921089917421,
      -0.02443457953631878,
      0.050076019018888474,
      -0.021270673722028732,
      0.020932121202349663,
      0.02412882074713707,
      0.021855566650629044,
      -0.0056946962140500546,
      0.021558746695518494,
      -0.07421040534973145,
      -0.019744746387004852,
      -0.024567965418100357,
      0.06762020289897919,
      0.05827748402953148,
      0.07661887258291245,
      0.11944594979286194,
      0.0042511033825576305,
      -0.045316167175769806,
      0.02859746292233467,
      -0.00048562727170065045,
      0.05256037786602974,
      -0.07733750343322754,
      -0.028644442558288574,
      0.0062429229728877544,
      -0.020602162927389145,
      0.11209952086210251,
      -0.01331610418856144,
      -0.028305407613515854,
      0.014350507408380508,
      -0.03595759719610214,
      -0.08758097141981125,
      0.10043127834796906,
      -0.038612477481365204,
      -0.038530223071575165,
      -0.028952330350875854,
      -0.025614231824874878,
      -0.028855524957180023,
      0.054222457110881805,
      -0.06512226164340973,
      0.09727756679058075,
      0.00434446707367897,
      0.08692186325788498,
      0.025082701817154884,
      -0.07782421261072159,
      -0.006622645538300276,
      -0.02008715085685253,
      -0.0898417979478836,
      -0.05095681920647621,
      0.011177956126630306,
      -0.022167250514030457,
      0.0422123521566391,
      0.004776891320943832,
      0.047431040555238724,
      -0.035201117396354675,
      0.059786953032016754,
      -0.02588294818997383,
      -0.0008978105615824461,
      -0.064766064286232,
      -0.023469330742955208,
      0.011788505129516125,
      0.06966876238584518,
      0.06894218921661377,
      -0.04732208698987961,
      -0.030559401959180832,
      -0.023465227335691452,
      0.023097587749361992,
      0.028700366616249084,
      0.05447765812277794,
      0.0457950159907341,
      -0.024222947657108307,
      -0.06041721627116203,
      0.007341142278164625,
      -0.07430282980203629,
      -0.043815284967422485,
      -0.055296968668699265,
      -0.07175938040018082,
      0.048496078699827194,
      -0.03477093577384949,
      0.023740850389003754,
      -0.061807747930288315,
      -0.03557319939136505,
      -0.007383828517049551,
      -0.005639003124088049,
      0.016455331817269325
    ],
    [
      0.07282661646604538,
      0.012060528621077538,
      -0.12980367243289948,
      -0.004074652213603258,
      -0.020051999017596245,
      0.062057994306087494,
      -0.024796990677714348,
      -0.022642550989985466,
      0.05794981122016907,
      -0.005730604752898216,
      0.18310172855854034,
      -0.023503661155700684,
      0.00044339484884403646,
      0.019958090037107468,
      -0.04553303122520447,
      -0.016055449843406677,
      0.00941168051213026,
      -0.0008898253436200321,
      0.015046839602291584,
      -0.08661898225545883,
      -0.06978412717580795,
      0.037961695343256,
      -0.08015166968107224,
      -0.12436801195144653,
      0.009573829360306263,
      -0.027115438133478165,
      0.005885649938136339,
      -0.02728612720966339,
      0.03271407634019852,
      0.054020531475543976,
      -0.10492680966854095,
      -0.014153272844851017,
      0.025107260793447495,
      0.013905583880841732,
      -0.025931695476174355,
      -0.029582498595118523,
      0.060632914304733276,
      -0.09302451461553574,
      -0.020567547529935837,
      -0.049594540148973465,
      -0.04131237044930458,
      -0.06923918426036835,
      0.034690022468566895,
      0.000258195010246709,
      -0.026467420160770416,
      0.037562064826488495,
      0.01654263213276863,
      0.012005911208689213,
      0.0712837427854538,
      -0.04286640137434006,
      0.05995846912264824,
      0.10946878045797348,
      -0.021422918885946274,
      -0.06740143150091171,
      0.04935230314731598,
      0.09648767858743668,
      -0.027880016714334488,
      0.05380462482571602,
      0.020468641072511673,
      -0.06042987480759621,
      -0.06949790567159653,
      0.043249648064374924,
      -0.015978528186678886,
      0.01686808280646801,
      -0.10249174386262894,
      0.030999071896076202,
      0.035096459090709686,
      -0.028201354667544365,
      0.03665035218000412,
      0.02908221445977688,
      -0.032796964049339294,
      0.024979345500469208,
      -0.012584230862557888,
      -0.0001178093662019819,
      -0.014134028926491737,
      0.01759864203631878,
      0.01693013124167919,
      0.013034991919994354,
      -0.02867106907069683,
      -0.02712091989815235,
      -0.006375346798449755,
      -0.049286480993032455,
      0.025027936324477196,
      0.06368418037891388,
      -0.08540160953998566,
      0.03144703432917595,
      0.011835952289402485,
      -0.03018808364868164,
      0.05288936197757721,
      0.08336184173822403,
      -0.03307473286986351,
      0.033827200531959534,
      -0.005669428501278162,
      -0.0572902075946331,
      -0.03246654197573662,
      0.051544949412345886,
      -0.11249111592769623,
      0.03649092838168144,
      0.04718395695090294,
      -0.011226808652281761,
      0.07833489030599594,
      -0.06725914031267166,
      0.005995906889438629,
      -0.01131456159055233,
      0.01633419841527939,
      0.007229737006127834,
      -0.010806932114064693,
      -0.012996566481888294,
      -0.07686249166727066,
      0.06102926284074783,
      -0.04897651821374893,
      0.09109865874052048,
      -0.014365134760737419,
      -0.005578041076660156,
      -0.01033269427716732,
      -0.08494594693183899,
      -0.047519151121377945,
      0.020802270621061325,
      0.0314410924911499,
      -0.0014571796637028456,
      -0.0476079098880291,
      0.034602392464876175,
      0.020734870806336403,
      0.017630279064178467,
      -0.01173629891127348,
      -0.04567708075046539,
      0.006628304719924927,
      0.022043554112315178,
      0.08852547407150269,
      0.0040581789799034595,
      -0.0739646777510643,
      -0.08683472871780396,
      -0.017890704795718193,
      -0.03052094578742981,
      0.023167187348008156,
      -0.0033654181752353907,
      -0.03321938216686249,
      -0.05567629635334015,
      0.023143362253904343,
      0.018582655116915703,
      -0.019620640203356743,
      -0.028014468029141426,
      0.04573589563369751,
      -0.04582760110497475,
      0.07518258690834045,
      -0.07867506891489029,
      -0.04234040156006813,
      -0.06406469643115997,
      -0.011424839496612549,
      0.03574197366833687,
      0.020767053589224815,
      -0.010593296028673649,
      -0.03187977895140648,
      -0.04749559238553047,
      -0.0880320742726326,
      0.008737396448850632,
      0.02456941083073616,
      -0.053141720592975616,
      0.02033333107829094,
      0.033306021243333817,
      -0.09290124475955963,
      -0.06876038014888763,
      0.02123512513935566,
      -0.06945354491472244,
      -0.027013413608074188,
      0.056634627282619476,
      0.009794189594686031,
      0.023979326710104942,
      0.003719793166965246,
      0.011945885606110096,
      -0.1188594400882721,
      0.04595086723566055,
      -0.035727452486753464,
      0.019636590033769608,
      -0.037888314574956894,
      0.01034996286034584,
      -0.04157930612564087,
      -0.0047181136906147,
      -0.05994044244289398,
      0.0008175860275514424,
      -0.032451432198286057,
      0.06475336104631424,
      -0.041446831077337265,
      0.09372816979885101,
      0.06975239515304565,
      -0.010151749476790428,
      0.02952953241765499,
      0.039663538336753845,
      0.08850749582052231,
      -0.13176558911800385,
      0.06869596987962723,
      0.0598393939435482,
      0.05169405788183212,
      -0.0698351189494133,
      -0.02289402298629284,
      -0.05889233574271202,
      -0.001361184986308217,
      -0.017164196819067,
      -0.008748284541070461,
      -0.026500537991523743,
      -0.056387580931186676,
      -0.008907720446586609,
      0.05513389781117439,
      0.06653855741024017,
      -0.04998506233096123,
      0.0321669764816761,
      0.05277867242693901,
      -0.013099105097353458,
      0.015802912414073944,
      -0.01774102821946144,
      -0.04133595898747444,
      0.029318472370505333,
      0.013218453153967857,
      -0.010521769523620605,
      -0.03328798711299896,
      -0.04582276567816734,
      -0.0036740058567374945,
      -0.03368757292628288,
      -0.039228953421115875,
      0.05729241296648979,
      0.008311145007610321,
      -0.031579773873090744,
      0.02193450555205345,
      -0.0017616725526750088,
      -0.06861468404531479,
      0.08883984386920929,
      -0.030289649963378906,
      0.0012896223925054073,
      -0.033114172518253326,
      -0.0601218156516552,
      -0.026150507852435112,
      -0.002504253527149558,
      -0.12933169305324554,
      0.11379982531070709,
      0.025383206084370613,
      -0.029913177713751793,
      0.10735449939966202,
      -0.027455808594822884,
      0.07305146753787994,
      0.04805859178304672,
      -0.06501509249210358,
      -0.04081280529499054,
      -0.04360884800553322,
      -0.03133828565478325,
      0.010682202875614166,
      -0.0756373405456543,
      -0.04976516589522362,
      0.01904594711959362,
      -0.03192843496799469,
      -0.05969526618719101,
      -0.0005147209158167243,
      0.010756667703390121,
      0.0923357829451561,
      0.0957089513540268,
      0.03147658333182335,
      -0.00882462877780199,
      0.08087155222892761,
      -0.06512555480003357,
      -0.008863233029842377,
      -0.021678855642676353,
      0.011524992994964123,
      -0.011871173046529293,
      0.0781511440873146,
      -0.07280315458774567,
      0.02514832280576229,
      -0.0596366710960865,
      0.08763938397169113,
      -0.09812673181295395,
      -0.04931598901748657,
      0.021145405247807503,
      0.051248699426651,
      -0.02781992219388485,
      0.07125335931777954,
      -0.031372539699077606,
      -0.10452713072299957,
      0.08495093882083893,
      0.014304417185485363,
      0.05249248445034027,
      0.027380621060729027,
      -0.012971787713468075,
      0.0021709350403398275,
      -0.046020347625017166,
      0.054302316159009933,
      -0.04024709016084671,
      -0.002297022147104144,
      -0.06307400017976761,
      -0.009437446482479572,
      0.010557033121585846,
      -0.01756885275244713,
      0.06388633698225021,
      0.01003664918243885,
      -0.01211575884371996,
      0.053619612008333206,
      0.019111743196845055,
      0.09122658520936966,
      -0.05183353275060654,
      0.03593454882502556,
      0.06503702700138092,
      -0.03550835698843002,
      0.025699961930513382,
      -0.016785670071840286,
      -0.023065287619829178,
      0.0335024818778038,
      -0.03728610277175903,
      -0.030003437772393227,
      -0.017823928967118263,
      -0.0363081693649292,
      -0.021745428442955017,
      0.07009803503751755,
      0.030012834817171097,
      -0.001999245723709464,
      0.0133871641010046,
      0.020501405000686646,
      0.005069898907095194,
      0.0077340127900242805,
      -0.026323752477765083,
      -0.07159192860126495,
      -0.06753679364919662,
      0.009303336963057518,
      0.024143634364008904,
      -0.04003220796585083,
      0.07628152519464493,
      0.03211083635687828,
      0.03329380229115486,
      0.007741979323327541,
      -0.027452925220131874,
      0.040067121386528015,
      -0.06309064477682114,
      0.057226818054914474,
      0.04139610379934311,
      0.005764693021774292,
      0.0026087621226906776,
      -0.02987106889486313,
      0.05800411105155945,
      -0.08837141841650009,
      -0.027895784005522728,
      -0.05104227364063263,
      -0.044709764420986176,
      0.013875304721295834,
      -0.040439948439598083,
      -0.001764725660905242,
      0.02128387987613678,
      0.052742134779691696,
      -0.02000909484922886,
      -0.007319915108382702,
      -0.038870032876729965,
      -0.014476271346211433,
      -0.030979705974459648,
      -0.04604513198137283,
      -0.07056957483291626,
      -0.02849479764699936,
      0.09302517771720886,
      -0.007786007598042488,
      0.010946458205580711,
      0.0478198416531086,
      0.07345741987228394,
      0.05683086812496185,
      -0.024158900603652,
      -0.05922049283981323,
      0.0009939830051735044,
      -0.05719691142439842,
      0.015388553030788898,
      -0.07194460928440094,
      -0.07245666533708572,
      0.005336422007530928,
      -0.01950373686850071,
      0.02768796682357788,
      -0.08535723388195038,
      -0.051864851266145706,
      0.025414302945137024,
      -0.04194791242480278,
      -0.035194240510463715,
      -0.027639109641313553,
      -0.03800211474299431,
      0.014914463274180889,
      -0.016926025971770287,
      0.031807366758584976,
      -0.05494393780827522,
      -0.022665759548544884,
      -0.08511313796043396,
      0.05726992338895798,
      -0.08061676472425461,
      -0.0437978133559227,
      -0.0502202995121479,
      -0.03697575256228447,
      0.011156435124576092,
      0.03711341693997383,
      0.025138571858406067,
      -0.059033285826444626,
      -0.0036352442111819983,
      -0.0028064155485481024,
      0.05874968692660332,
      -0.001493431394919753,
      -0.001025509787723422,
      -0.004103776533156633,
      -0.01912704110145569,
      0.06586666405200958,
      0.015539510175585747,
      0.11183957010507584,
      0.02392336167395115,
      0.026717212051153183,
      0.053985774517059326,
      0.01591094583272934,
      0.09113045781850815,
      0.015519753098487854,
      0.019129637628793716,
      -0.011240042746067047,
      -0.06240532919764519,
      0.040623508393764496,
      0.05086764320731163,
      -0.004744789097458124,
      0.03150930255651474,
      0.09402309358119965,
      0.013866046443581581,
      -0.03870174288749695,
      -0.03483613580465317,
      -0.05215911567211151,
      0.06075476109981537,
      0.05187501013278961,
      0.010968406684696674,
      -0.007593689486384392,
      -0.007871512323617935,
      0.027766859158873558,
      -0.0015641433419659734,
      -0.007178103551268578,
      0.017986495047807693,
      -0.04323906823992729,
      0.003623922122642398,
      -0.07484053075313568,
      0.014803819358348846,
      -0.05862175673246384,
      -0.025332825258374214,
      0.02681969851255417,
      0.013549218885600567,
      -0.07425190508365631,
      0.044516872614622116,
      -0.07777011394500732,
      0.062323082238435745,
      -0.03827463835477829,
      -0.0018701504450291395,
      -0.015107475221157074,
      0.09701953828334808,
      -0.021006163209676743,
      -0.04284018278121948,
      0.0811438038945198,
      -0.033170439302921295,
      -0.014172271825373173,
      0.0022206667345017195,
      0.07136514782905579,
      -0.003811699105426669,
      0.014439641498029232,
      -0.03802355378866196,
      -0.03474537655711174,
      -0.0542968213558197,
      -0.0029166475869715214,
      -0.04642724618315697,
      0.05891995504498482,
      -0.01781516894698143,
      -0.0021134268026798964,
      0.0055467491038143635,
      -0.03176925703883171,
      0.01994037628173828,
      -0.003976346459239721,
      0.04375983402132988,
      -0.00037112366408109665,
      0.021404633298516273,
      0.00039949073106981814,
      0.023314280435442924,
      -0.055242761969566345,
      0.04410374537110329,
      0.034800466150045395,
      0.011513099074363708,
      -0.035573992878198624,
      0.02865634858608246,
      0.0645015612244606,
      0.047849252820014954,
      0.040215153247117996,
      -0.0027799063827842474,
      -0.025061005726456642,
      0.0022667839657515287,
      0.131937175989151,
      0.013497700914740562,
      -0.02141851931810379,
      0.00851347018033266,
      0.07375733554363251,
      -0.05865895003080368,
      -0.062034569680690765,
      0.020946435630321503,
      -0.07980311661958694,
      -0.01994577795267105,
      0.04264804720878601,
      -0.028166232630610466,
      -0.030956992879509926,
      0.007622905541211367,
      0.0032807968091219664,
      0.0655454620718956,
      -0.03140748664736748,
      -0.07130029052495956,
      0.010377849452197552,
      -0.017386360093951225,
      -0.008843242190778255,
      -0.05814278498291969,
      6.339459650916979e-05
    ],
    [
      -0.01376077625900507,
      0.04622751101851463,
      -0.01766190305352211,
      -0.10596764832735062,
      0.019063496962189674,
      -0.05659940838813782,
      -0.037646204233169556,
      -0.07153868675231934,
      0.023879842832684517,
      0.04968886449933052,
      0.0013235429069027305,
      0.04372230917215347,
      -0.035873908549547195,
      0.04673467203974724,
      0.09917588531970978,
      -0.078822560608387,
      0.07456009089946747,
      0.03668547421693802,
      -0.025226077064871788,
      -0.03160666301846504,
      -0.029395923018455505,
      0.024494655430316925,
      0.08591689169406891,
      -0.01647719368338585,
      -0.018034275621175766,
      0.06195809319615364,
      -0.07461913675069809,
      -0.05128099024295807,
      0.06515967100858688,
      -0.01150983851402998,
      -0.0615076869726181,
      0.0687541663646698,
      -0.0046280003152787685,
      -9.402057185070589e-05,
      -0.04051373898983002,
      0.028742913156747818,
      0.036889418959617615,
      0.086250901222229,
      -0.006629590410739183,
      0.09392903000116348,
      -0.0262445081025362,
      0.05621820688247681,
      -0.022862529382109642,
      -0.032147206366062164,
      0.028794685378670692,
      0.010330001823604107,
      0.05060140788555145,
      -0.03395537659525871,
      -0.010987577959895134,
      -0.02331889420747757,
      -0.06708242744207382,
      0.08392803370952606,
      0.017851529642939568,
      0.006047659087926149,
      0.013969337567687035,
      0.060575421899557114,
      -0.0039300983771681786,
      -0.0010474799200892448,
      0.05210414156317711,
      -0.06665090471506119,
      -0.011862368322908878,
      -0.011831204406917095,
      0.04391559958457947,
      0.0729508176445961,
      -0.023451929911971092,
      0.011467508040368557,
      -0.05679778382182121,
      0.04030944034457207,
      -0.030565546825528145,
      0.05326364189386368,
      0.0035667638294398785,
      0.015899106860160828,
      -0.018038611859083176,
      0.036180611699819565,
      -0.06040634587407112,
      0.02937033399939537,
      -0.0181034617125988,
      -0.011059789918363094,
      0.04087734967470169,
      0.00047927346895448864,
      -0.017682546749711037,
      0.06173918768763542,
      -0.030655262991786003,
      0.07103738933801651,
      0.01930028200149536,
      0.04590028151869774,
      0.06295813620090485,
      -0.08336307853460312,
      -0.036235395818948746,
      -0.043564774096012115,
      0.024729080498218536,
      -0.10107903182506561,
      0.03466040641069412,
      -0.09878140687942505,
      -0.05223051458597183,
      0.022207770496606827,
      -0.047660235315561295,
      0.07372745871543884,
      0.02882576920092106,
      0.027234094217419624,
      -0.010082120075821877,
      0.020814597606658936,
      -0.045867349952459335,
      -0.02317657321691513,
      0.0018586908699944615,
      0.0526810958981514,
      0.03466681018471718,
      -0.04312899336218834,
      -0.06175641715526581,
      0.007955091074109077,
      -0.02311035990715027,
      0.044151388108730316,
      0.08659903705120087,
      -0.04786214232444763,
      0.007489757612347603,
      -0.033481910824775696,
      0.018542930483818054,
      0.03653386980295181,
      -0.02597290836274624,
      -0.05560681223869324,
      0.06761664897203445,
      0.057607535272836685,
      0.012172962538897991,
      0.003048960817977786,
      -0.0018580672331154346,
      -0.05444270744919777,
      -0.11086386442184448,
      0.002895948477089405,
      0.10471006482839584,
      -0.013682718388736248,
      -0.006781048607081175,
      -0.0240126121789217,
      0.06549020111560822,
      -0.08262930810451508,
      0.057450976222753525,
      0.03669479861855507,
      0.043288715183734894,
      -0.06094883382320404,
      -0.017780054360628128,
      -0.06738244742155075,
      0.007792729418724775,
      -0.1019957959651947,
      0.0047488026320934296,
      0.0023500898387283087,
      -0.0367281511425972,
      0.12165901809930801,
      -0.031762104481458664,
      0.02690710499882698,
      0.007941282354295254,
      -0.03259877488017082,
      -0.07446739822626114,
      0.030135134235024452,
      -0.1014416366815567,
      -0.09149380773305893,
      0.02554844319820404,
      0.03527995944023132,
      -0.026109842583537102,
      -0.07626669853925705,
      0.038192205131053925,
      0.03677621856331825,
      0.010528024286031723,
      0.04453607276082039,
      -0.009933462366461754,
      0.021176718175411224,
      -0.03190138563513756,
      0.017764119431376457,
      -0.05591198429465294,
      0.03350621089339256,
      0.004420883022248745,
      0.08226951211690903,
      0.04626106843352318,
      0.06583461165428162,
      -0.00967356190085411,
      -0.07969941943883896,
      -0.01786714233458042,
      -0.026876704767346382,
      0.02115418203175068,
      -0.02467951737344265,
      -0.07148478925228119,
      0.024702534079551697,
      0.024910910055041313,
      0.05601930618286133,
      0.029333122074604034,
      -0.017446652054786682,
      0.056280896067619324,
      0.08147981017827988,
      0.04677136614918709,
      -0.004761015065014362,
      -0.02339101955294609,
      -0.013547977432608604,
      -0.00933651439845562,
      0.006818100344389677,
      -0.04585041105747223,
      -0.020147059112787247,
      0.01056713704019785,
      0.0006647561676800251,
      -0.05408113822340965,
      -0.07859726250171661,
      0.061991218477487564,
      -0.004499487578868866,
      0.04222586378455162,
      -0.004707436077296734,
      0.11051507294178009,
      -0.004620459862053394,
      0.03683637082576752,
      -0.025961564853787422,
      -0.049947164952754974,
      -0.00980124156922102,
      0.019149836152791977,
      -0.024695005267858505,
      -0.06027069687843323,
      -0.025420114398002625,
      -0.008241227827966213,
      0.017412232235074043,
      0.018483322113752365,
      -0.04854504391551018,
      -0.050031132996082306,
      -0.020387306809425354,
      0.030847758054733276,
      0.014291136525571346,
      0.011270569637417793,
      -0.0020784183871001005,
      0.01722552254796028,
      0.020002273842692375,
      -0.04825028032064438,
      -0.0036376342177391052,
      -0.059545084834098816,
      0.07945357263088226,
      -0.006230163387954235,
      -0.023455316200852394,
      -0.022403515875339508,
      -0.019300486892461777,
      0.00885081011801958,
      -0.08263984322547913,
      0.07260045409202576,
      -0.02638883702456951,
      0.07055026292800903,
      0.04960092902183533,
      0.047964394092559814,
      -0.0418386310338974,
      -0.009990175254642963,
      0.05347194895148277,
      -0.10514099895954132,
      -0.06756135076284409,
      0.0196066964417696,
      0.04672473669052124,
      -0.02489815279841423,
      -0.04154496639966965,
      -0.06055773049592972,
      -0.051313016563653946,
      -0.0604708157479763,
      -0.003515595803037286,
      0.032035619020462036,
      -0.0023979763500392437,
      -0.020861150696873665,
      0.02553377114236355,
      -0.008066859096288681,
      -0.0323125384747982,
      0.08421498537063599,
      0.02710169367492199,
      0.03713354468345642,
      0.019909067079424858,
      0.02188332937657833,
      0.030894499272108078,
      -0.010223093442618847,
      0.054451752454042435,
      -0.021675627678632736,
      -0.06373077630996704,
      0.002476742723956704,
      -0.022872159257531166,
      0.0037846986670047045,
      -0.05582870915532112,
      -0.021453319117426872,
      -0.03973647579550743,
      0.04506181925535202,
      0.031271424144506454,
      -0.022516706958413124,
      -0.027824262157082558,
      -0.04639023542404175,
      -0.04206429421901703,
      0.0419468879699707,
      0.09388796240091324,
      0.017581302672624588,
      -0.01570681296288967,
      0.04929415509104729,
      -0.029834216460585594,
      -0.015000364743173122,
      -0.047551099210977554,
      0.012049463577568531,
      0.029639368876814842,
      -0.05974743142724037,
      -0.09830229729413986,
      -0.06203175336122513,
      -0.027643349021673203,
      0.025110075250267982,
      0.01222943514585495,
      -0.04297668859362602,
      -0.1055283322930336,
      -0.08026323467493057,
      -0.023840997368097305,
      0.018983956426382065,
      -0.07357179373502731,
      -0.02570919319987297,
      -0.02525719255208969,
      -0.10424619913101196,
      -0.023307165130972862,
      0.04554929956793785,
      -0.04402197524905205,
      -0.022374289110302925,
      -0.09484326839447021,
      -0.06868090480566025,
      -0.02180393971502781,
      -0.11791186034679413,
      0.041351672261953354,
      0.05592544376850128,
      -0.046371083706617355,
      0.02546735666692257,
      -0.05416814982891083,
      -0.06686588376760483,
      -0.024526186287403107,
      -0.007589085027575493,
      -0.042863111943006516,
      0.07409483194351196,
      0.05958281829953194,
      -0.04268118366599083,
      -0.03373086825013161,
      -0.02994605526328087,
      0.02490212395787239,
      0.055493950843811035,
      0.0308848787099123,
      -0.10781089961528778,
      0.019232086837291718,
      -0.09642785042524338,
      -0.07846590876579285,
      -0.04798099026083946,
      0.026617877185344696,
      0.056214284151792526,
      -0.04474012926220894,
      0.01796633191406727,
      -0.007701134774833918,
      -0.029775047674775124,
      0.06202726066112518,
      -0.04494153708219528,
      0.007329663261771202,
      0.015873342752456665,
      0.029006145894527435,
      -0.025152217596769333,
      0.016433166339993477,
      -0.002314779907464981,
      0.028292205184698105,
      0.09273239225149155,
      0.029602836817502975,
      -0.009386854246258736,
      -0.021907301619648933,
      -0.027772465720772743,
      -0.01370258443057537,
      0.05262276902794838,
      -0.013299982994794846,
      -0.06750409305095673,
      -0.02952939085662365,
      0.008103127591311932,
      0.030938973650336266,
      -0.004287839401513338,
      -0.05529094114899635,
      0.054131414741277695,
      0.13730601966381073,
      0.035399433225393295,
      0.06471647322177887,
      -0.08027263730764389,
      0.03968699648976326,
      0.012717343866825104,
      0.035096175968647,
      -0.03477969020605087,
      -0.015957284718751907,
      0.009343856945633888,
      -0.03205576166510582,
      0.048355832695961,
      0.003775605233386159,
      0.02285340614616871,
      -0.04149856045842171,
      -0.011783188208937645,
      -0.02616005577147007,
      0.04657606780529022,
      0.006985700689256191,
      0.011374467983841896,
      0.05764448270201683,
      0.009850013069808483,
      0.023657776415348053,
      -0.061995331197977066,
      0.01362823136150837,
      0.055599384009838104,
      -0.04262741282582283,
      -0.055802226066589355,
      -0.10207511484622955,
      0.012069578282535076,
      -0.033300988376140594,
      -0.03975861519575119,
      0.011972084641456604,
      -0.001364374067634344,
      -0.06439197063446045,
      0.04661847651004791,
      -0.018111739307641983,
      0.1379416137933731,
      0.05570932850241661,
      0.01742016337811947,
      -0.01034860871732235,
      -0.02110363356769085,
      -0.011556509882211685,
      -0.057725489139556885,
      -0.08677251636981964,
      0.029921142384409904,
      0.09144864231348038,
      -0.023877376690506935,
      0.1230124831199646,
      0.010550626553595066,
      0.0429636612534523,
      -0.030452219769358635,
      -0.0032338150776922703,
      0.0921536460518837,
      0.014356710016727448,
      0.016962498426437378,
      0.07332304120063782,
      -0.0021934807300567627,
      -0.07394926249980927,
      0.0190084520727396,
      -0.015246854163706303,
      0.03223796188831329,
      -0.0479569137096405,
      -0.01197141781449318,
      -0.0632028877735138,
      2.7295198378851637e-05,
      -0.04422011226415634,
      -0.0344165600836277,
      -0.022795110940933228,
      -0.028136901557445526,
      -0.0017584084998816252,
      0.030458344146609306,
      -0.0611446350812912,
      -0.05564550682902336,
      -0.04858537018299103,
      -0.03316911309957504,
      0.07816842943429947,
      -0.03558317571878433,
      -0.07478160411119461,
      0.09124743938446045,
      -0.028718803077936172,
      -0.004095354583114386,
      0.08917335420846939,
      0.01803647167980671,
      0.04790444299578667,
      0.022904613986611366,
      0.011702596209943295,
      0.051144618541002274,
      0.06413815915584564,
      -0.05681607499718666,
      0.04994572326540947,
      -0.009847767651081085,
      0.03551193326711655,
      -0.005910241510719061,
      0.0705326721072197,
      0.03591276705265045,
      0.008520438335835934,
      0.029077686369419098,
      -0.00016608396254014224,
      -0.013632171787321568,
      0.008464556187391281,
      -0.06260161846876144,
      -0.031973306089639664,
      0.02724403329193592,
      0.05627836287021637,
      0.08706120401620865,
      -0.07833101600408554,
      0.04114554822444916,
      0.06497638672590256,
      0.02335754781961441,
      0.039413485676050186,
      -0.06696899980306625,
      -0.02639819122850895,
      0.034866396337747574,
      -0.058430545032024384,
      -0.0031017863657325506,
      -0.04653913155198097,
      0.027436459437012672,
      0.05749328061938286,
      -0.040312401950359344,
      0.017506785690784454,
      -0.13156001269817352,
      -0.018655139952898026,
      0.08314128965139389,
      0.020282503217458725,
      0.04582483321428299,
      0.06258401274681091,
      0.03928440436720848,
      -0.017517510801553726,
      0.08617668598890305,
      0.07307653874158859,
      -0.08420594036579132,
      0.03647179529070854,
      -0.057156700640916824,
      -0.010718253441154957,
      -0.04369743913412094,
      0.12714332342147827,
      -0.033888302743434906
    ],
    [
      0.008111737668514252,
      -0.022130673751235008,
      0.08015584945678711,
      -0.03087420016527176,
      -0.016850950196385384,
      0.05626619979739189,
      -0.042890798300504684,
      0.03725888207554817,
      0.09610103070735931,
      -0.03316613286733627,
      0.039774615317583084,
      0.028526879847049713,
      0.013670605607330799,
      -0.043909650295972824,
      -0.05305670574307442,
      0.043227024376392365,
      0.010045687668025494,
      -0.004130485467612743,
      -0.037956107407808304,
      -0.08817897737026215,
      0.014120794832706451,
      -0.013817894272506237,
      0.029576174914836884,
      -0.021217433735728264,
      -0.07423021644353867,
      0.03200303018093109,
      0.05183993652462959,
      0.014587530866265297,
      -0.06221989169716835,
      0.010279461741447449,
      0.02907916158437729,
      0.01922394335269928,
      0.026712944731116295,
      0.02397998981177807,
      0.026923110708594322,
      0.008610508404672146,
      -0.020293915644288063,
      -0.06499078869819641,
      0.004611043259501457,
      0.015303339809179306,
      -0.039109211415052414,
      0.07203290611505508,
      -0.036855876445770264,
      0.016860511153936386,
      0.04804550111293793,
      -0.027717122808098793,
      -0.008924669586122036,
      0.014209498651325703,
      0.008812629617750645,
      -0.026617223396897316,
      0.005213406402617693,
      0.03479337692260742,
      0.031650904566049576,
      -0.011093869805335999,
      -0.051551058888435364,
      -0.023937884718179703,
      0.011121252551674843,
      -0.08077210187911987,
      -0.007714481558650732,
      -0.016563983634114265,
      -0.029952798038721085,
      0.07718989998102188,
      -0.014949952252209187,
      0.018559733405709267,
      -0.06411268562078476,
      0.021992061287164688,
      0.041468553245067596,
      0.00801251083612442,
      -0.03385390713810921,
      0.04839440435171127,
      0.08860906213521957,
      0.037265364080667496,
      -0.005546876695007086,
      0.09533130377531052,
      0.04210101440548897,
      0.008149459026753902,
      -0.008353305980563164,
      0.03897758945822716,
      0.017148248851299286,
      0.04007187485694885,
      -0.037000324577093124,
      0.05467505007982254,
      0.04773233085870743,
      0.058406680822372437,
      0.0016946241958066821,
      0.011523566208779812,
      -0.07739382982254028,
      0.04792668670415878,
      -0.00372625095769763,
      -0.0013607688015326858,
      -0.0018891115905717015,
      -0.06897976249456406,
      -0.011741467751562595,
      0.013160870410501957,
      -0.007276804186403751,
      -0.03989917039871216,
      -0.05542005971074104,
      -0.0504474900662899,
      -0.012841424904763699,
      -0.0229838527739048,
      -0.0485495887696743,
      -0.025560803711414337,
      -0.09775996953248978,
      0.04679373279213905,
      0.06394593417644501,
      0.0062049697153270245,
      -0.0023976387456059456,
      0.13032092154026031,
      -0.033026762306690216,
      -0.006882632151246071,
      0.07823128253221512,
      -0.054959289729595184,
      0.009095950052142143,
      0.02236105501651764,
      -0.0032024160027503967,
      -0.06958896666765213,
      -0.06783594936132431,
      -0.06225666403770447,
      -0.010632260702550411,
      0.028422784060239792,
      -0.022021720185875893,
      -0.009379619732499123,
      -0.008789065293967724,
      -0.03480155020952225,
      0.00048158105346374214,
      0.014893743209540844,
      0.026412779465317726,
      0.0034758097026497126,
      -0.01540448609739542,
      -0.0014548979233950377,
      0.0025828920770436525,
      -0.042625006288290024,
      -0.01351346354931593,
      0.013569005765020847,
      0.10598953068256378,
      0.03976934403181076,
      -0.0727790966629982,
      0.03953484073281288,
      0.015296782366931438,
      -0.04823508858680725,
      0.019334586337208748,
      -0.003906823694705963,
      -0.05620618164539337,
      0.03815177083015442,
      -0.014350822195410728,
      -0.0590815506875515,
      -0.03188033401966095,
      -0.014213676564395428,
      -0.025141993537545204,
      -0.004412223119288683,
      -0.015162164345383644,
      -0.013702030293643475,
      0.10193663835525513,
      -0.05067439749836922,
      0.006124380510300398,
      -0.04842521995306015,
      -0.00010288317571394145,
      -0.009252918884158134,
      -0.0013206307776272297,
      0.03685277700424194,
      0.03153353929519653,
      0.029420871287584305,
      0.0019071580609306693,
      -0.0339357890188694,
      -0.06920187175273895,
      -0.014541281387209892,
      0.021217690780758858,
      -0.043843675404787064,
      -0.020836425945162773,
      0.09565014392137527,
      0.08315028250217438,
      0.07862632721662521,
      -0.04399548098444939,
      -0.0160053838044405,
      -0.034900616854429245,
      -0.007440460380166769,
      0.008529884740710258,
      0.14586114883422852,
      -0.06738116592168808,
      0.04734914004802704,
      0.011250847950577736,
      0.10175517201423645,
      -0.010232205502688885,
      0.012333842925727367,
      0.09503205865621567,
      -0.07320671528577805,
      0.003947562538087368,
      -0.003771869232878089,
      0.0077669559977948666,
      -0.027286257594823837,
      -0.029516564682126045,
      0.0246095173060894,
      0.048159096390008926,
      -0.02067386917769909,
      0.07451466470956802,
      -0.003647405654191971,
      -0.0009081047610379755,
      -0.012248515151441097,
      -0.02168167009949684,
      0.0008240996394306421,
      0.09186446666717529,
      -0.03555617108941078,
      0.07435806840658188,
      0.011057057417929173,
      -0.031120309606194496,
      -0.07399655133485794,
      -0.004410613793879747,
      -0.003577872645109892,
      0.03141893073916435,
      0.005717071238905191,
      0.06593801081180573,
      -0.024132153019309044,
      -0.05492597073316574,
      -0.0004544248804450035,
      0.011972840875387192,
      0.002402979414910078,
      -0.11568103730678558,
      -0.016982773318886757,
      0.01439992617815733,
      0.03230452910065651,
      -0.050049472600221634,
      -0.059562914073467255,
      -0.054611098021268845,
      -0.07851434499025345,
      0.022548630833625793,
      0.08466628193855286,
      -0.057633962482213974,
      -0.0372963584959507,
      0.04156700149178505,
      0.0015689658466726542,
      -0.0006265301490202546,
      0.013190182857215405,
      0.004129473585635424,
      0.07056862860918045,
      0.0672493651509285,
      0.010494548827409744,
      -0.05890617147088051,
      -0.03819999471306801,
      0.009187426418066025,
      -0.05302263796329498,
      -0.02425641380250454,
      -0.01788897067308426,
      -0.056745290756225586,
      -0.03955085203051567,
      0.02133285067975521,
      0.01806783489882946,
      -0.06779677420854568,
      -0.013004343956708908,
      -0.0051172636449337006,
      0.019894471392035484,
      0.02655332162976265,
      0.0014272494008764625,
      -0.01274845190346241,
      -0.07457896322011948,
      0.008942651562392712,
      -0.019542790949344635,
      -0.06594762951135635,
      -0.03532885015010834,
      0.013821082189679146,
      0.05262747034430504,
      0.027731675654649734,
      0.01999816857278347,
      -0.014996789395809174,
      0.02893245592713356,
      -0.04512086510658264,
      -0.023562874644994736,
      -0.04936106875538826,
      0.03454152122139931,
      0.024855423718690872,
      -0.0007594273192808032,
      0.06301755458116531,
      0.06445736438035965,
      0.033026497811079025,
      -0.043644122779369354,
      0.016552109271287918,
      0.06528028845787048,
      0.06940209865570068,
      -0.001900208299048245,
      -0.04149848222732544,
      -0.00717278802767396,
      0.01812516525387764,
      0.06387600302696228,
      -0.05461737513542175,
      0.02112494967877865,
      -0.017383510246872902,
      0.034188270568847656,
      0.038274578750133514,
      -0.007931730709969997,
      -0.009952069260179996,
      0.008079776540398598,
      0.03638118505477905,
      -0.01316775381565094,
      0.014354836195707321,
      -0.017040207982063293,
      -0.02526240423321724,
      0.006065397057682276,
      0.014518007636070251,
      -0.03493029251694679,
      -0.007611345034092665,
      0.06322159618139267,
      0.0358189195394516,
      -0.01620686985552311,
      0.06035137549042702,
      0.04650923237204552,
      0.0040786429308354855,
      -0.027763227000832558,
      0.029188215732574463,
      0.022448677569627762,
      -0.06857196241617203,
      -0.09159345924854279,
      0.002531463513150811,
      0.08177740126848221,
      -0.01730777509510517,
      -0.022715099155902863,
      -0.016375698149204254,
      0.060024697333574295,
      0.0030514064710587263,
      -0.09032807499170303,
      0.016582097858190536,
      -0.016498712822794914,
      0.07758717983961105,
      0.07700400054454803,
      -0.002672818023711443,
      0.007602335419505835,
      -0.02961795963346958,
      0.061612434685230255,
      -0.008876060135662556,
      -0.00617162324488163,
      -0.0493125393986702,
      0.0038835371378809214,
      -0.01888168416917324,
      -0.010121682658791542,
      -0.07523930817842484,
      -0.05925685539841652,
      -0.03293418884277344,
      -0.005612524226307869,
      -0.055924635380506516,
      0.016455885022878647,
      -0.02284775860607624,
      -0.0684911385178566,
      -0.06745876371860504,
      -0.0723140612244606,
      0.032874301075935364,
      0.016665633767843246,
      0.1284296214580536,
      -0.04750112444162369,
      0.057636141777038574,
      -0.02487396076321602,
      -0.017408255487680435,
      0.03194728121161461,
      0.07190392911434174,
      0.07602258026599884,
      0.03306148201227188,
      0.01861773431301117,
      -0.12666361033916473,
      0.004264836199581623,
      -0.051750753074884415,
      -0.06726277619600296,
      0.03433315455913544,
      0.04574029520153999,
      0.027651648968458176,
      -0.012639664113521576,
      -0.015198640525341034,
      0.01959811896085739,
      0.022428402677178383,
      0.009151899255812168,
      0.008406025357544422,
      0.002988589694723487,
      -0.03272297605872154,
      -0.0016882754862308502,
      0.018876302987337112,
      -0.01484227180480957,
      -0.007292768452316523,
      -0.022399144247174263,
      -0.1267986297607422,
      -0.062425896525382996,
      0.02131584659218788,
      0.00401172274723649,
      -0.04503057152032852,
      0.009614492766559124,
      0.0016996823251247406,
      -0.007786656264215708,
      0.023592490702867508,
      -0.12241531163454056,
      -0.022318493574857712,
      -0.02734380215406418,
      -0.002738646464422345,
      0.041123781353235245,
      -0.06784531474113464,
      -0.06436645239591599,
      -0.04286433011293411,
      -0.03255275636911392,
      0.06422345340251923,
      -0.018901200965046883,
      0.02075488679111004,
      0.08140983432531357,
      0.06594739109277725,
      -0.01727740466594696,
      0.004345943219959736,
      0.019574729725718498,
      0.005034935660660267,
      -0.05617242306470871,
      0.0326511524617672,
      0.06130709871649742,
      0.04989542439579964,
      -0.05060916393995285,
      -0.02469548024237156,
      -0.017386896535754204,
      0.05470803380012512,
      0.02283986657857895,
      -0.059318527579307556,
      0.07247696071863174,
      -0.02840527333319187,
      0.030440865084528923,
      0.1058979257941246,
      -0.05341383442282677,
      0.0636255219578743,
      0.028830720111727715,
      -0.01561497151851654,
      -0.0017615036340430379,
      0.018552202731370926,
      -0.05934787914156914,
      -0.12037500739097595,
      -0.09116224944591522,
      -0.059037622064352036,
      0.054646462202072144,
      -0.026735464110970497,
      0.002464120276272297,
      -0.042001523077487946,
      0.05152684822678566,
      -0.032507941126823425,
      -0.0014123307773843408,
      -0.07641923427581787,
      0.017024943605065346,
      0.04314711317420006,
      -0.019381405785679817,
      0.003747238079085946,
      -0.001309151528403163,
      -0.05101873725652695,
      -0.0508294403553009,
      -0.053218308836221695,
      -0.07469271123409271,
      0.057247553020715714,
      0.06618302315473557,
      0.09797608852386475,
      0.06566863507032394,
      -0.07724986970424652,
      -0.06946234405040741,
      0.02919301763176918,
      -0.015128441154956818,
      0.10775439441204071,
      0.01597529463469982,
      -0.02429613657295704,
      -0.015280256979167461,
      -0.07295192778110504,
      0.0006876379484310746,
      -0.0161629356443882,
      0.036691609770059586,
      0.07087399810552597,
      0.023538244888186455,
      0.02199382521212101,
      0.06578254699707031,
      0.022795120254158974,
      -0.0007209701579995453,
      -0.044500917196273804,
      0.05434974655508995,
      -0.024016959592700005,
      -0.05453003570437431,
      -0.04687308520078659,
      0.019270000979304314,
      -0.004329015500843525,
      -0.02739516645669937,
      -0.02702055126428604,
      -0.0213555209338665,
      0.022622812539339066,
      -0.025833308696746826,
      -0.06460563838481903,
      -0.047143880277872086,
      0.057533424347639084,
      -0.056605014950037,
      0.037371519953012466,
      0.05663704499602318,
      0.03513709083199501,
      -0.04980287700891495,
      -0.004257237538695335,
      0.04229293391108513,
      -0.14276839792728424,
      -0.018553392961621284,
      0.018421724438667297,
      0.013159526512026787,
      -0.02136196382343769,
      0.008635391481220722,
      0.07812846451997757,
      -0.07467829436063766,
      0.054825738072395325,
      -0.01789737679064274,
      0.015977902337908745,
      -0.0748780220746994,
      -0.03134508803486824,
      0.00023790859268046916,
      -0.021174529567360878,
      0.010189320892095566,
      -0.052406273782253265
    ],
    [
      0.05620770528912544,
      -0.035524941980838776,
      0.008687474764883518,
      0.0806010439991951,
      -0.020588820800185204,
      0.015555552206933498,
      -0.022328892722725868,
      -0.033453553915023804,
      -0.058719489723443985,
      0.009115602821111679,
      -0.011178234592080116,
      -0.007674416992813349,
      0.010648077353835106,
      0.04203343763947487,
      -0.02399507723748684,
      -0.08718948066234589,
      -0.03599342703819275,
      0.004227072931826115,
      -0.05895385891199112,
      0.011314970441162586,
      0.05593421682715416,
      -0.03977228328585625,
      -0.03874415159225464,
      0.0008340345229953527,
      -0.07626580446958542,
      0.02727128379046917,
      -0.05104105919599533,
      -0.03531744331121445,
      -0.06640617549419403,
      0.015085560269653797,
      0.0558285228908062,
      0.006375608965754509,
      0.01920817606151104,
      0.016427762806415558,
      -0.04904758557677269,
      -0.0883302167057991,
      -0.031959932297468185,
      -0.00627561891451478,
      -0.031554896384477615,
      -0.03846646100282669,
      0.03658861294388771,
      -0.1034005805850029,
      0.03559846431016922,
      0.002179090166464448,
      -0.07374031841754913,
      -0.006960757076740265,
      -0.011485607363283634,
      0.0068908012472093105,
      0.019390054047107697,
      -0.0612960085272789,
      -0.011433246545493603,
      -0.004818725399672985,
      0.031392112374305725,
      0.01163714099675417,
      -0.017290260642766953,
      0.050890445709228516,
      -0.08647504448890686,
      -0.031816817820072174,
      -0.06554882973432541,
      -0.060121238231658936,
      0.027218012139201164,
      0.030207186937332153,
      0.011927991174161434,
      -0.0006092566181905568,
      -0.09659863263368607,
      0.02987133152782917,
      0.00802109856158495,
      -0.027314918115735054,
      0.005933703389018774,
      -0.005774019751697779,
      0.07171659916639328,
      0.09233777970075607,
      -0.0007635758956894279,
      0.116452157497406,
      0.016451817005872726,
      -0.017147179692983627,
      -0.03690101578831673,
      0.10775802284479141,
      -0.030138997361063957,
      -0.01219776552170515,
      0.07326878607273102,
      -0.03280249610543251,
      0.07988245040178299,
      -0.019001612439751625,
      -0.04724184796214104,
      0.04374343529343605,
      0.014099759981036186,
      -0.028845077380537987,
      -0.024370066821575165,
      -0.08430223912000656,
      -0.024037029594182968,
      0.06243547797203064,
      0.02751900628209114,
      0.010563758201897144,
      0.0176965594291687,
      -0.12932901084423065,
      -0.06838971376419067,
      -0.024951620027422905,
      0.013223153539001942,
      -4.284900933271274e-05,
      0.0007622928242199123,
      -0.04742979258298874,
      -0.02458750084042549,
      0.03130944445729256,
      -0.017924431711435318,
      0.025373108685016632,
      -0.012984034605324268,
      0.03223893791437149,
      0.048889901489019394,
      0.05165663734078407,
      0.04174882173538208,
      -0.06941883265972137,
      0.05812981724739075,
      0.010845269076526165,
      -0.05105411633849144,
      0.023642156273126602,
      -0.06199217215180397,
      0.02147907018661499,
      -0.05229000002145767,
      0.08865073323249817,
      0.017615605145692825,
      0.024766039103269577,
      0.04951963946223259,
      0.06607948243618011,
      -0.027446715161204338,
      0.04204457625746727,
      -0.025250650942325592,
      0.008883312344551086,
      0.02076573297381401,
      -0.030243856832385063,
      -0.03828393667936325,
      -0.026041243225336075,
      0.0014882704708725214,
      0.04988575354218483,
      -0.017240118235349655,
      -0.044277824461460114,
      -0.06593697518110275,
      -0.014031956903636456,
      0.022546088322997093,
      0.021477537229657173,
      -0.10011892765760422,
      0.05535360053181648,
      -0.03921002149581909,
      0.004556518979370594,
      0.03554264083504677,
      0.05721060559153557,
      -0.07817889750003815,
      -0.0015978438314050436,
      -0.06352721899747849,
      0.0664597898721695,
      -0.028946438804268837,
      0.06146983057260513,
      -0.085604727268219,
      -0.08087020367383957,
      -0.025042450055480003,
      -0.04452347010374069,
      -0.04432755336165428,
      -0.003319887211546302,
      -0.01252674125134945,
      -0.03870101273059845,
      0.08294641971588135,
      0.07580695301294327,
      -0.017607618123292923,
      0.019478823989629745,
      0.02379864826798439,
      0.0117619838565588,
      0.034291066229343414,
      0.02565119042992592,
      -0.030990809202194214,
      -0.012359855696558952,
      -0.02692403644323349,
      0.04369069263339043,
      0.0441940538585186,
      -0.02708820253610611,
      -0.05309927091002464,
      -0.03543699532747269,
      0.008111122995615005,
      0.07622142881155014,
      -0.0571981742978096,
      -0.020309967920184135,
      -0.07486382126808167,
      -0.0005756611353717744,
      0.04772281274199486,
      0.05394890159368515,
      0.01569928601384163,
      -0.0750349685549736,
      -0.0076088267378509045,
      -0.01841033436357975,
      -0.020029986277222633,
      0.02071302756667137,
      -0.002248370787128806,
      -0.010336149483919144,
      0.00995062105357647,
      -0.07609150558710098,
      0.052678875625133514,
      0.05415917560458183,
      -0.02912299521267414,
      0.006007419433444738,
      -0.045998524874448776,
      -0.06058121472597122,
      0.07566363364458084,
      -0.08565026521682739,
      0.05830522999167442,
      0.015183307230472565,
      -0.06721823662519455,
      -0.04246889054775238,
      0.03771211579442024,
      0.08609991520643234,
      0.010723471641540527,
      0.06918956339359283,
      0.07113911211490631,
      -0.03857051953673363,
      0.03584248945116997,
      0.1585371345281601,
      0.0065611680038273335,
      -0.04680904373526573,
      0.03388850390911102,
      0.027997609227895737,
      -0.02927367016673088,
      0.08040978759527206,
      -0.0058867172338068485,
      -0.010985779576003551,
      -0.03181852772831917,
      -0.08508691191673279,
      0.00664562126621604,
      -0.012372490018606186,
      -0.043290991336107254,
      -0.008632570505142212,
      0.030454888939857483,
      0.02710864320397377,
      -0.005121668800711632,
      0.10091447830200195,
      -0.040573108941316605,
      -0.009665964171290398,
      -0.015668967738747597,
      -0.011631255969405174,
      -0.03874044865369797,
      0.02352834865450859,
      0.005040515214204788,
      -0.015472576953470707,
      0.035409823060035706,
      -0.008725954219698906,
      -0.05721406266093254,
      -0.020443269982933998,
      -0.021378247067332268,
      -0.01365713495761156,
      0.03448209539055824,
      0.04127087444067001,
      -0.004711924586445093,
      -0.027548713609576225,
      -0.0032034318428486586,
      0.02490859106183052,
      0.011831982992589474,
      -0.009437941946089268,
      -0.05819440633058548,
      -0.07279203087091446,
      -0.0726718083024025,
      -0.07182823866605759,
      0.029258618131279945,
      -0.020867493003606796,
      0.01060411799699068,
      -0.06584721058607101,
      0.032051242887973785,
      -0.007100332994014025,
      0.04308752343058586,
      0.0600292794406414,
      0.040433961898088455,
      0.008311303332448006,
      -0.057125724852085114,
      -0.028736324980854988,
      0.015283109620213509,
      0.03988557681441307,
      -0.025379987433552742,
      0.04071992263197899,
      0.03238237276673317,
      0.08613241463899612,
      0.05245595797896385,
      0.041276879608631134,
      -0.06226268783211708,
      -0.0695921927690506,
      -0.0654618963599205,
      -0.00998313445597887,
      0.034690503031015396,
      -0.06048852205276489,
      -0.06416797637939453,
      0.07220740616321564,
      0.08537697792053223,
      0.028424357995390892,
      0.030331052839756012,
      0.029885049909353256,
      0.05603320896625519,
      0.053561169654130936,
      -0.024185769259929657,
      -0.01941852457821369,
      0.07366285473108292,
      0.03828147426247597,
      0.05658937618136406,
      -0.02759234607219696,
      -0.0018776498036459088,
      -0.02155488356947899,
      0.013282856903970242,
      0.05441971495747566,
      0.026441356167197227,
      0.0004106685519218445,
      -0.04245419800281525,
      -0.03979167714715004,
      -0.07797814160585403,
      0.09728308767080307,
      -0.016468724235892296,
      -0.0434451624751091,
      -0.018389245495200157,
      -0.022339623421430588,
      -0.046735260635614395,
      0.013467981480062008,
      -0.05467955768108368,
      -0.025586653500795364,
      0.09720759093761444,
      -0.0198284313082695,
      0.0677533969283104,
      -0.02618122100830078,
      0.0427192859351635,
      0.06568286567926407,
      0.044319018721580505,
      0.08820179104804993,
      0.035179171711206436,
      -0.010209404863417149,
      0.09859016537666321,
      0.0031955924350768328,
      0.02627851627767086,
      -0.022951994091272354,
      -0.005212859250605106,
      -0.035892654210329056,
      0.02572702243924141,
      0.008655707351863384,
      0.006934630684554577,
      0.08738119900226593,
      0.06895164400339127,
      -0.029627904295921326,
      -0.0631052702665329,
      0.08597774803638458,
      0.018457671627402306,
      0.01306150946766138,
      0.04514247179031372,
      -0.04940822720527649,
      0.031031105667352676,
      0.05782168731093407,
      -0.028276871889829636,
      0.03782620653510094,
      0.029025407508015633,
      0.013084097765386105,
      -0.016947142779827118,
      0.08909275382757187,
      -0.0242622047662735,
      0.06702125072479248,
      -0.04922819882631302,
      -0.06673462688922882,
      -0.017218921333551407,
      0.04455745592713356,
      -0.025857269763946533,
      -0.05248681455850601,
      -0.08833111822605133,
      -0.041617248207330704,
      -0.022374533116817474,
      -0.07725832611322403,
      -0.06978713721036911,
      -0.02812620997428894,
      0.03675150126218796,
      0.09141911566257477,
      -0.0036121478769928217,
      -0.009983312338590622,
      0.06152359023690224,
      0.014170127920806408,
      0.002906111301854253,
      0.07946328818798065,
      0.03994443267583847,
      0.04658252000808716,
      0.032443881034851074,
      0.006712795235216618,
      0.0845160260796547,
      -0.004985632840543985,
      0.0539451465010643,
      -0.03215385973453522,
      -0.0056112236343324184,
      -0.08538783341646194,
      0.03578214719891548,
      0.07540398836135864,
      -0.017454665154218674,
      0.006703296676278114,
      -0.037514157593250275,
      -0.014214376918971539,
      0.05064671114087105,
      0.04562806338071823,
      0.023753084242343903,
      0.06815037131309509,
      0.009242567233741283,
      -0.027168497443199158,
      -0.026594460010528564,
      0.01340305246412754,
      0.00475537683814764,
      0.05709237605333328,
      0.010234811343252659,
      0.0428742840886116,
      0.011378644965589046,
      -0.03703270107507706,
      0.045512158423662186,
      0.0242264736443758,
      -0.022148825228214264,
      -0.07158540189266205,
      0.048463378101587296,
      0.056185878813266754,
      -0.03516329452395439,
      -0.04158329591155052,
      0.007956452667713165,
      0.015344249084591866,
      -0.04240775108337402,
      0.02536984346807003,
      -0.10971549153327942,
      0.04490480199456215,
      -0.05832896754145622,
      -0.007288113236427307,
      -0.013518991880118847,
      -0.03959876671433449,
      0.014485225081443787,
      0.08507626503705978,
      0.01479105930775404,
      0.01749606244266033,
      0.11332642287015915,
      0.08600705116987228,
      0.0016830648528411984,
      0.01701216772198677,
      0.0049058799631893635,
      -0.017599398270249367,
      -0.028983965516090393,
      -0.10052292048931122,
      -0.005049412604421377,
      -0.014298222959041595,
      -0.06800873577594757,
      0.04997187852859497,
      -0.01552837435156107,
      -0.02207222208380699,
      0.0037440790329128504,
      0.003119809553027153,
      -0.035977110266685486,
      -0.06373896449804306,
      0.07384780049324036,
      0.005860181525349617,
      -0.03585560992360115,
      -0.07542084902524948,
      -0.03535585477948189,
      -0.01443527266383171,
      -0.008938984014093876,
      -0.06774647533893585,
      -0.0126412408426404,
      0.03931257501244545,
      -0.0022046715021133423,
      0.011206255294382572,
      -0.10596898943185806,
      0.05266309157013893,
      -0.007805558852851391,
      -0.10487236827611923,
      0.018344268202781677,
      0.04880594462156296,
      -0.04416772723197937,
      0.08031895756721497,
      0.021633127704262733,
      -0.08821827918291092,
      0.008022219873964787,
      -0.0028976760804653168,
      0.003974417690187693,
      0.00969475507736206,
      0.03148231655359268,
      -0.08011826872825623,
      -0.09308137744665146,
      0.008508293889462948,
      -0.04997275769710541,
      0.009682574309408665,
      -0.08685269951820374,
      -0.0808006003499031,
      0.025438178330659866,
      0.0827598050236702,
      -0.027700703591108322,
      0.09609324485063553,
      0.0028697573579847813,
      0.04398734122514725,
      0.009267209097743034,
      -0.004366286098957062,
      -0.08911453932523727,
      -0.061616502702236176,
      -0.044356632977724075,
      0.07523765414953232,
      0.04399207606911659,
      -0.019398627802729607,
      -0.04747077077627182,
      0.006708880420774221,
      -0.018407272174954414,
      0.01980791613459587,
      0.03696497157216072,
      -0.01911931112408638,
      0.004544955678284168,
      -0.02151999995112419,
      0.025341732427477837,
      -0.012684782966971397,
      -0.04822908341884613
    ],
    [
      0.04137169197201729,
      -0.025966182351112366,
      0.015721196308732033,
      -0.05727735906839371,
      0.019273199141025543,
      0.07003911584615707,
      -0.07613728940486908,
      -0.05513057857751846,
      -0.004289435688406229,
      -0.04949988052248955,
      -0.1077655777335167,
      -0.017830409109592438,
      -0.02826699987053871,
      -0.08778326213359833,
      -0.039511919021606445,
      -0.0049912468530237675,
      0.04624610394239426,
      -0.007897787727415562,
      0.02785368822515011,
      0.028251761570572853,
      -0.0650457888841629,
      -0.03532019630074501,
      0.010001759976148605,
      0.07700221985578537,
      0.0420478917658329,
      0.05197349190711975,
      -0.058746587485075,
      -0.059967171400785446,
      0.029177211225032806,
      -0.12483097612857819,
      -0.10314252227544785,
      -0.006650828756392002,
      0.12380074709653854,
      -0.08377870172262192,
      -0.04221276566386223,
      0.027049802243709564,
      0.06481678783893585,
      -0.013000432401895523,
      0.013450405560433865,
      0.0343148410320282,
      -0.03589218482375145,
      0.012125777080655098,
      -0.06086425855755806,
      0.05622339993715286,
      0.006574439350515604,
      -0.037412915378808975,
      -0.03296772018074989,
      0.006968035362660885,
      -0.04610191658139229,
      0.006529620848596096,
      0.023833414539694786,
      0.05882935971021652,
      -0.03803589940071106,
      0.018886027857661247,
      -0.07162302732467651,
      -0.0035974602214992046,
      -0.04899105057120323,
      -0.017702769488096237,
      0.03737637400627136,
      -0.04663391783833504,
      -0.012906312011182308,
      0.07987376302480698,
      0.036575861275196075,
      -0.015790151432156563,
      0.03551739826798439,
      -0.05249464139342308,
      -0.03240375220775604,
      -0.01748739741742611,
      0.026174239814281464,
      -0.03701576590538025,
      -0.012869912199676037,
      0.033742908388376236,
      -0.033485230058431625,
      0.04249822720885277,
      0.05018307641148567,
      -0.0738668143749237,
      -0.04107912257313728,
      0.00800366885960102,
      0.021113993600010872,
      -0.028182102367281914,
      0.0560329295694828,
      0.01579931005835533,
      -0.02662237547338009,
      0.012908266857266426,
      0.07237424701452255,
      0.0671570897102356,
      0.03726216405630112,
      -0.045930568128824234,
      0.05322526395320892,
      -0.042052119970321655,
      -0.04739627614617348,
      -0.023165464401245117,
      0.06825490295886993,
      -0.017837820574641228,
      -0.1448955535888672,
      -0.002881089225411415,
      -0.05566256493330002,
      -0.0669574961066246,
      -0.10583971440792084,
      0.10844479501247406,
      0.0258807260543108,
      0.06865627318620682,
      0.012134366668760777,
      -0.016256513074040413,
      0.10746988654136658,
      -0.03722545504570007,
      0.021221481263637543,
      0.07803785055875778,
      0.07129119336605072,
      0.045713938772678375,
      -0.016948634758591652,
      0.012665924616158009,
      -0.015450318343937397,
      -0.028607690706849098,
      0.002535992069169879,
      0.011645435355603695,
      -0.026669751852750778,
      -0.03998255729675293,
      0.016819585114717484,
      0.017360074445605278,
      -0.02893087826669216,
      0.07822509855031967,
      -0.048763521015644073,
      -0.019005119800567627,
      -0.05099822208285332,
      0.025469660758972168,
      -0.02787010371685028,
      0.025450844317674637,
      0.028353750705718994,
      -0.05732628330588341,
      -0.1438279151916504,
      0.03071247972548008,
      -0.038178496062755585,
      -0.037700213491916656,
      0.031812626868486404,
      0.022839879617094994,
      0.07763996720314026,
      -0.07287915050983429,
      0.04779086634516716,
      0.015508856624364853,
      0.007525088731199503,
      0.04429612681269646,
      -0.05310175567865372,
      0.026590876281261444,
      0.00832592137157917,
      -0.01489488035440445,
      0.011232493445277214,
      -0.0036009715404361486,
      0.005523070227354765,
      0.007198921870440245,
      -0.029775047674775124,
      0.012597822584211826,
      -0.15717753767967224,
      0.08846034109592438,
      -0.002257454674690962,
      0.01908077672123909,
      0.09285943955183029,
      -0.008924719877541065,
      -0.017830748111009598,
      0.029649412259459496,
      0.06923165172338486,
      0.02881525829434395,
      0.08929263055324554,
      0.024188974872231483,
      -0.007860960438847542,
      0.0676654577255249,
      -0.04485268145799637,
      0.026341360062360764,
      -0.027848802506923676,
      -0.017283130437135696,
      0.06927278637886047,
      -0.06146031990647316,
      0.0716760978102684,
      -0.05968115106225014,
      -0.0006535440916195512,
      -0.04705551266670227,
      -0.06246716529130936,
      0.09997053444385529,
      -0.02732204459607601,
      0.022155025973916054,
      0.028881030157208443,
      0.0014434641925618052,
      -0.05561022832989693,
      0.015374721959233284,
      -0.03244964778423309,
      -0.03170524165034294,
      0.07027506083250046,
      -0.08485576510429382,
      -0.08093458414077759,
      0.07823013514280319,
      0.0026373101864010096,
      -0.004674918949604034,
      0.0789935439825058,
      -0.05590735003352165,
      -0.002269159536808729,
      -0.10144662111997604,
      -0.04298589006066322,
      0.009950627572834492,
      -0.007463987451046705,
      0.004514402709901333,
      0.14728863537311554,
      -0.056340835988521576,
      0.011555281467735767,
      -0.05879748985171318,
      -0.037612736225128174,
      -0.00925699807703495,
      0.08956431597471237,
      0.08470012247562408,
      0.01433494221419096,
      0.018894968554377556,
      -0.07003486901521683,
      -0.059006333351135254,
      0.022861747071146965,
      0.01217619888484478,
      0.004041746724396944,
      0.08061825484037399,
      -0.009629625827074051,
      -0.022888246923685074,
      0.002550512785091996,
      0.03566225618124008,
      -0.11292298883199692,
      -0.043407756835222244,
      0.038458582013845444,
      -0.022990209981799126,
      0.08089057356119156,
      -0.027326414361596107,
      -0.046822767704725266,
      0.021642696112394333,
      0.03860313072800636,
      -0.027136215940117836,
      0.05499771237373352,
      -0.00019997615891043097,
      0.03494174778461456,
      -0.0741276890039444,
      0.020492851734161377,
      0.026712898164987564,
      0.004414496477693319,
      0.02073652110993862,
      0.08163166046142578,
      -0.012236794456839561,
      -0.06567375361919403,
      0.016205936670303345,
      -0.05591319873929024,
      -0.07683257013559341,
      -2.923411739175208e-05,
      0.017811158671975136,
      -0.007451614830642939,
      0.05834028869867325,
      -0.012179480865597725,
      -0.1080557107925415,
      -0.0062108878046274185,
      -0.04819393530488014,
      0.015796100720763206,
      -0.009680895134806633,
      0.05641472339630127,
      -0.031206710264086723,
      -0.02058393321931362,
      -0.04526258260011673,
      0.026025358587503433,
      0.07164164632558823,
      0.00398318050429225,
      0.027621161192655563,
      0.02414778433740139,
      -0.0659545287489891,
      -0.03816405311226845,
      -0.012212414294481277,
      0.0009024643222801387,
      0.045059531927108765,
      -0.09226440638303757,
      -0.0021010611671954393,
      -0.044288519769907,
      -0.020787328481674194,
      -0.03811044991016388,
      0.022131862118840218,
      -0.05106278136372566,
      0.025725778192281723,
      0.08366115391254425,
      -0.015520086511969566,
      0.07464470714330673,
      0.04479656741023064,
      0.01735544390976429,
      -0.1340905725955963,
      -0.005615931935608387,
      0.053644392639398575,
      -0.026011047884821892,
      0.024227652698755264,
      0.046371981501579285,
      0.06323673576116562,
      0.011287841014564037,
      -0.05353013053536415,
      0.046696797013282776,
      -0.026667581871151924,
      -0.02871043048799038,
      -0.01570337824523449,
      -0.04988108575344086,
      0.027994783595204353,
      0.03772279992699623,
      0.04324028640985489,
      -0.038395218551158905,
      -0.005402261391282082,
      0.04645977541804314,
      -0.0059487540274858475,
      -0.05695117264986038,
      -0.025954578071832657,
      0.013242075219750404,
      0.06139729544520378,
      -0.03179489076137543,
      -0.02475413680076599,
      -0.012542353942990303,
      0.026716290041804314,
      -0.08162198960781097,
      -0.015094996429979801,
      -0.029380640015006065,
      -0.07509268075227737,
      -0.03494755178689957,
      -0.06863898783922195,
      0.025549130514264107,
      -0.020771510899066925,
      0.024656422436237335,
      -0.043536990880966187,
      -0.0006128772511146963,
      -0.0846342146396637,
      0.02242620848119259,
      -0.038099505007267,
      0.05398797616362572,
      0.03565315157175064,
      -0.06128336489200592,
      -0.03402833640575409,
      -0.002929132431745529,
      -0.012955193407833576,
      -0.018513385206460953,
      -0.08419328182935715,
      -0.16325218975543976,
      -0.003517717821523547,
      -0.014517351053655148,
      -0.002337066689506173,
      0.015149950049817562,
      0.025979459285736084,
      -0.031000256538391113,
      0.022635148838162422,
      -0.053476348519325256,
      -0.03510698303580284,
      -0.03084058128297329,
      -0.052393894642591476,
      0.012772763147950172,
      0.03176998347043991,
      -0.01371585763990879,
      0.028476368635892868,
      -0.016770023852586746,
      0.005221524275839329,
      -0.01680443435907364,
      0.05357978120446205,
      -0.009469142183661461,
      -0.03532380238175392,
      -0.02268114499747753,
      0.02649499662220478,
      -0.029840437695384026,
      -0.06496108323335648,
      -0.08426518738269806,
      -0.07871066778898239,
      -0.005818468052893877,
      -0.057451121509075165,
      -0.09688975661993027,
      -0.11063484847545624,
      -0.051023904234170914,
      -0.04693039506673813,
      -0.0042114295065402985,
      -0.002960770856589079,
      -0.01301722601056099,
      -0.03710709512233734,
      -0.11998173594474792,
      -0.07717311382293701,
      0.01724742166697979,
      -0.04198803752660751,
      0.017852671444416046,
      -0.13975566625595093,
      0.013485364615917206,
      0.0327066071331501,
      -0.04148462414741516,
      0.02531495876610279,
      -0.022284645587205887,
      -0.0680377185344696,
      -0.07506554573774338,
      0.030921081081032753,
      -0.026764921844005585,
      0.022612622007727623,
      0.001481428975239396,
      0.02050018310546875,
      -0.040500458329916,
      -0.02857634797692299,
      0.020466694608330727,
      0.02464248053729534,
      0.02879726141691208,
      0.06077689304947853,
      -0.005484870634973049,
      -0.007387613877654076,
      -0.061059001833200455,
      -0.03427104651927948,
      0.02004052884876728,
      -0.04340152442455292,
      -0.06078227236866951,
      -0.005051847081631422,
      0.03127119690179825,
      0.03647761419415474,
      -0.009067036211490631,
      0.05174935609102249,
      -0.022364763543009758,
      0.07930541783571243,
      -0.034147266298532486,
      -0.01630408689379692,
      -0.05951235443353653,
      0.04451484978199005,
      -0.07659206539392471,
      -0.01895969733595848,
      -0.01740783266723156,
      -0.029543377459049225,
      0.016168782487511635,
      -0.07082885503768921,
      0.0154468584805727,
      0.024240540340542793,
      -0.014650990255177021,
      -0.05279385298490524,
      -0.0047454251907765865,
      0.007423084694892168,
      0.019177334383130074,
      0.06099752336740494,
      -0.022734280675649643,
      -0.032177429646253586,
      -0.00885492842644453,
      0.061027150601148605,
      -0.01149434968829155,
      -0.09447068721055984,
      -0.018303798511624336,
      0.019353434443473816,
      -0.004865898750722408,
      -0.006419819314032793,
      -0.01095504965633154,
      -0.05667716637253761,
      0.010310439392924309,
      0.03273959830403328,
      0.04007445648312569,
      0.030641648918390274,
      -0.001798258163034916,
      -0.0054962956346571445,
      0.0919727087020874,
      -0.024892494082450867,
      0.052840035408735275,
      -0.0002705122751649469,
      -0.10513745993375778,
      0.01418324001133442,
      -0.021144455298781395,
      0.0016770896036177874,
      -0.025854872539639473,
      -0.07960228621959686,
      0.008324703201651573,
      0.021761542186141014,
      0.04415873438119888,
      0.051315512508153915,
      0.040175165981054306,
      -0.0519757941365242,
      -0.04483643174171448,
      -0.054681356996297836,
      -0.055093005299568176,
      0.045399803668260574,
      -0.011021208018064499,
      0.053006794303655624,
      -0.021338026970624924,
      0.013872395269572735,
      -0.01931767910718918,
      0.013325479812920094,
      0.011228022165596485,
      0.03896923363208771,
      0.0051267449744045734,
      0.013097047805786133,
      -0.06924819946289062,
      0.02457139641046524,
      -0.06477934867143631,
      -0.03438262641429901,
      -0.0015655355527997017,
      0.021881921216845512,
      0.027862371876835823,
      -0.018561678007245064,
      -0.016334297135472298,
      -0.03655329719185829,
      0.00991180818527937,
      -0.0409332737326622,
      0.062313396483659744,
      -0.07777566462755203,
      0.04336503520607948,
      0.011561287567019463,
      -0.008662370033562183,
      -0.003496296238154173,
      0.05190439522266388,
      -0.04744059592485428,
      -0.04340227320790291,
      0.03231631591916084,
      0.02257872000336647,
      0.010502486489713192,
      -0.08771108835935593,
      0.008926741778850555,
      -0.03638754040002823,
      -0.04316362366080284,
      0.04644540324807167
    ],
    [
      0.02008488029241562,
      -0.0432521291077137,
      0.022674990817904472,
      -0.05841513350605965,
      -0.012962961569428444,
      -0.032111361622810364,
      0.023780526593327522,
      -0.0068178982473909855,
      0.0510580949485302,
      -0.004494474269449711,
      -0.0070356763899326324,
      -0.0011157990666106343,
      -0.027290567755699158,
      0.02411685325205326,
      0.039410270750522614,
      0.0734134167432785,
      0.02587353251874447,
      0.0690392330288887,
      0.028090430423617363,
      0.0838027149438858,
      -0.026494206860661507,
      0.05577030032873154,
      0.03841375187039375,
      -0.059083808213472366,
      -0.04192858934402466,
      0.0033088219352066517,
      -0.004424777813255787,
      -0.011681431904435158,
      0.055274005979299545,
      -0.02044581063091755,
      -0.06658891588449478,
      0.043108027428388596,
      0.08451125770807266,
      0.055547457188367844,
      0.06836562603712082,
      0.07946554571390152,
      -0.022657472640275955,
      0.06829759478569031,
      0.05264489725232124,
      0.032495517283678055,
      -0.062476787716150284,
      0.03836427628993988,
      -0.11115999519824982,
      0.023530414327979088,
      0.0965474545955658,
      0.026691725477576256,
      0.024254165589809418,
      -0.012401146814227104,
      0.02866237610578537,
      -0.057183146476745605,
      -0.03162465989589691,
      0.030887974426150322,
      0.019641615450382233,
      0.0561746247112751,
      -0.03244355693459511,
      0.001360365771688521,
      -0.09461203217506409,
      0.05690830200910568,
      -0.017037855461239815,
      -0.017293700948357582,
      0.0425226166844368,
      0.02823326177895069,
      0.00413469597697258,
      0.03157632425427437,
      0.02227134443819523,
      -0.03685383498668671,
      0.049557048827409744,
      0.011339087970554829,
      0.0645001083612442,
      -0.00624124426394701,
      -0.03540755808353424,
      0.05930669978260994,
      -0.04567883163690567,
      0.0982292965054512,
      0.043307945132255554,
      -0.04043937847018242,
      0.08987943083047867,
      0.03200458735227585,
      -0.0744081512093544,
      -0.03202880918979645,
      -0.01043737679719925,
      0.03150005266070366,
      0.00477225985378027,
      0.1001737117767334,
      0.03337147459387779,
      -0.03524931147694588,
      -0.03304080665111542,
      -0.05395902693271637,
      0.012093725614249706,
      -0.010419542901217937,
      -0.05307091400027275,
      -0.011027761735022068,
      -0.015747180208563805,
      0.028097793459892273,
      -0.010974220931529999,
      -0.0694834440946579,
      -0.005298708565533161,
      0.017831789329648018,
      -0.004145071376115084,
      -0.017249837517738342,
      -0.017294077202677727,
      -0.0860549733042717,
      0.07221561670303345,
      -0.00660363445058465,
      -0.05125376954674721,
      0.07077081501483917,
      0.02557501569390297,
      -0.007467623334378004,
      -0.009692780673503876,
      0.05664302036166191,
      -0.06013297662138939,
      -9.068447980098426e-05,
      0.0056775412522256374,
      -0.08632775396108627,
      0.08575787395238876,
      0.02516264282166958,
      -0.001956124557182193,
      0.032604094594717026,
      -0.024382837116718292,
      -0.08176274597644806,
      0.0705491155385971,
      0.04607345908880234,
      -0.05503832921385765,
      0.07111544907093048,
      0.027780311182141304,
      0.012147764675319195,
      0.06665313243865967,
      0.09015325456857681,
      0.06434997916221619,
      0.03877655416727066,
      -0.01885594241321087,
      0.057191405445337296,
      0.0031111394055187702,
      -0.04574616625905037,
      -0.0038315311539918184,
      0.02553722634911537,
      0.03636302426457405,
      0.016301343217492104,
      -0.03414328396320343,
      -0.056394245475530624,
      -0.006091019604355097,
      0.055659737437963486,
      -0.01232996117323637,
      -0.06108630448579788,
      -0.004379835445433855,
      0.06107562407851219,
      -0.07770667225122452,
      -0.01998424343764782,
      -0.020577773451805115,
      -0.054134197533130646,
      -0.0053922394290566444,
      0.012899561785161495,
      0.05196337774395943,
      -0.024688059464097023,
      -0.029719891026616096,
      -0.05340585857629776,
      0.09336676448583603,
      -0.030957605689764023,
      -0.0016780226724222302,
      -0.041461776942014694,
      -0.04129774123430252,
      0.008595873601734638,
      0.04580710083246231,
      -0.019194863736629486,
      0.052318669855594635,
      0.013141832314431667,
      0.002503413939848542,
      0.04702971130609512,
      -0.07056400179862976,
      -0.02769451215863228,
      0.007647089194506407,
      0.003904117504134774,
      0.08505324274301529,
      -0.034170132130384445,
      -0.0014450268354266882,
      0.034081898629665375,
      0.007984232157468796,
      0.0009897472336888313,
      0.016091514378786087,
      -0.037718214094638824,
      0.020470505580306053,
      0.0203146543353796,
      0.023240532726049423,
      0.054491136223077774,
      0.032208606600761414,
      0.023248804733157158,
      0.022870616987347603,
      -0.04633713513612747,
      0.030744438990950584,
      0.017869388684630394,
      -0.09091953933238983,
      -0.026423687115311623,
      -0.059835951775312424,
      -0.0362943671643734,
      0.07149558514356613,
      0.04905177652835846,
      0.11620397865772247,
      -0.025425918400287628,
      -0.01893950253725052,
      -0.011789387091994286,
      0.014309562742710114,
      -0.1699034571647644,
      0.05362682417035103,
      0.04437989369034767,
      0.006851040292531252,
      -0.02744930610060692,
      -0.012758609838783741,
      -0.031013939529657364,
      0.016584431752562523,
      -0.09372922778129578,
      -0.04338587075471878,
      0.042666345834732056,
      0.07683666795492172,
      0.03441212698817253,
      0.07400069385766983,
      0.014805421233177185,
      -0.029467571526765823,
      -0.0020545709412544966,
      0.010638282634317875,
      0.08099327981472015,
      -0.025396747514605522,
      -0.08444669097661972,
      -0.007035704795271158,
      0.0391644649207592,
      -0.018680687993764877,
      0.0864320695400238,
      -0.027763603255152702,
      -0.08512958884239197,
      -0.020170899108052254,
      0.022872408851981163,
      -0.047935597598552704,
      -0.030186550691723824,
      -0.02066619135439396,
      -0.04455236345529556,
      -0.04868694022297859,
      -0.0033040556591004133,
      0.03698979318141937,
      0.07994195073843002,
      -0.017496556043624878,
      0.048178818076848984,
      0.08011884242296219,
      0.05607180297374725,
      0.050025708973407745,
      -0.04856909066438675,
      -0.018727988004684448,
      0.05495366454124451,
      -0.012180806137621403,
      0.028113147243857384,
      -0.029638957232236862,
      -0.03722698614001274,
      0.036682695150375366,
      -0.0008799654897302389,
      0.005695573054254055,
      0.00903009157627821,
      0.055936846882104874,
      -0.008032746613025665,
      -0.1400405764579773,
      -0.02435157261788845,
      0.05169663578271866,
      -0.012546135112643242,
      0.027495425194501877,
      0.025088749825954437,
      0.014835277572274208,
      -0.07845864444971085,
      -0.017106475308537483,
      -0.03013051487505436,
      -0.03921523317694664,
      0.0326894074678421,
      -0.010640619322657585,
      0.02656402625143528,
      0.013032159768044949,
      0.05906764417886734,
      -0.06311873346567154,
      -0.02208777144551277,
      0.00821975152939558,
      0.0413421168923378,
      0.03835929185152054,
      0.035058680921792984,
      -0.0008779218187555671,
      0.03813520446419716,
      -0.015230453573167324,
      -0.043839432299137115,
      -0.01954781822860241,
      -0.026144053786993027,
      -0.05693783611059189,
      0.030487878248095512,
      -0.02619510516524315,
      0.0304835457354784,
      -0.025340043008327484,
      0.03968336060643196,
      0.007083899807184935,
      -0.07570111751556396,
      0.01592743583023548,
      0.002107031177729368,
      0.01687021739780903,
      0.006048197392374277,
      -0.042981941252946854,
      -0.05098051577806473,
      -0.05235052853822708,
      -0.012628831900656223,
      0.046675071120262146,
      -0.06147507205605507,
      -0.059287331998348236,
      0.01355933677405119,
      0.0778217762708664,
      -0.057901330292224884,
      0.06788350641727448,
      -0.0008952432544901967,
      -0.062368929386138916,
      0.01730179786682129,
      -0.00600168714299798,
      -0.032858408987522125,
      0.0432509109377861,
      0.0845763087272644,
      0.038657817989587784,
      -0.02884681150317192,
      0.015523431822657585,
      -0.11649350076913834,
      -0.027183186262845993,
      0.027971971780061722,
      -0.06036785617470741,
      0.016329849138855934,
      0.06557823717594147,
      -0.042854826897382736,
      -0.05247466638684273,
      -0.0359797365963459,
      -0.09680864214897156,
      -0.008792988955974579,
      0.00480493251234293,
      0.0208745077252388,
      -0.11367686837911606,
      0.04623877629637718,
      0.050498656928539276,
      -0.008242771960794926,
      -0.026717409491539,
      -0.001024231780320406,
      0.023381268605589867,
      -0.03821941465139389,
      -0.018918318673968315,
      -0.013823720626533031,
      0.07232006639242172,
      -0.0364983007311821,
      0.0005798768834210932,
      0.016859563067555428,
      0.0851435512304306,
      -0.001616191235370934,
      -0.005935849156230688,
      0.02056531235575676,
      -0.035829007625579834,
      0.025299200788140297,
      -0.06747494637966156,
      -0.020398752763867378,
      -0.007694280240684748,
      0.06881634891033173,
      0.02437436953186989,
      -0.041397444903850555,
      -0.029203519225120544,
      0.05395795404911041,
      0.023373061791062355,
      -0.05204371362924576,
      -0.05813294276595116,
      0.006829630583524704,
      -0.027677826583385468,
      0.0020253851544111967,
      -0.040461476892232895,
      0.049106381833553314,
      -0.06926541030406952,
      0.05351129546761513,
      0.07405144721269608,
      0.03963817283511162,
      -0.0801885649561882,
      -0.07371384650468826,
      -0.009491169825196266,
      -0.07406408339738846,
      0.01591024361550808,
      0.05274301767349243,
      -0.04950965940952301,
      -0.01180884800851345,
      0.018414383754134178,
      0.002049591625109315,
      -0.031344227492809296,
      0.02212027832865715,
      0.06433273106813431,
      -0.08683078736066818,
      0.041425563395023346,
      -0.017472026869654655,
      -0.057011090219020844,
      0.015584783628582954,
      -0.030357172712683678,
      -0.01622496172785759,
      0.08819573372602463,
      -0.00026454826002009213,
      0.0200455654412508,
      0.05764665827155113,
      -0.0749005526304245,
      0.03569108620285988,
      0.010009108111262321,
      -0.02111947536468506,
      -0.0374913290143013,
      -0.019477814435958862,
      -0.026350198313593864,
      -0.006768084596842527,
      -0.06608036160469055,
      -0.021984444931149483,
      -0.005723939277231693,
      -0.07916427403688431,
      -0.0012853712541982532,
      0.00524678872898221,
      -0.010150554589927197,
      0.0009916661074385047,
      -0.06712309271097183,
      0.06295880675315857,
      0.1041133776307106,
      -0.04061752185225487,
      0.0498427078127861,
      -0.030057502910494804,
      0.034643761813640594,
      0.024260932579636574,
      0.04186034947633743,
      0.04516459256410599,
      -0.035172030329704285,
      -0.0016417271690443158,
      0.055387433618307114,
      -0.031029628589749336,
      0.12537790834903717,
      0.052125051617622375,
      -0.03775464743375778,
      0.006645084358751774,
      0.00018941258895210922,
      -0.0046512964181602,
      -0.022839317098259926,
      0.007109344936907291,
      -0.04611578956246376,
      -0.0186663456261158,
      0.01629851572215557,
      -0.010603977367281914,
      0.07389825582504272,
      0.01433744840323925,
      -0.07673507928848267,
      0.0026941117830574512,
      -0.0475202277302742,
      -0.00038613760261796415,
      -0.01559256762266159,
      -1.4781453501200303e-05,
      -0.054970286786556244,
      -0.02898147888481617,
      -0.03813367336988449,
      -0.030782338231801987,
      0.05705063045024872,
      0.024608366191387177,
      0.02642074227333069,
      0.0701804980635643,
      -0.002924845088273287,
      -0.02881454862654209,
      -0.06560175120830536,
      0.027804739773273468,
      -0.01005390752106905,
      0.09485437721014023,
      0.03825686126947403,
      0.026729626581072807,
      0.04058637097477913,
      -0.03408665210008621,
      -0.06541300565004349,
      0.005235171876847744,
      0.037190500646829605,
      0.01647079363465309,
      0.017908599227666855,
      -0.0037626472767442465,
      0.07059285789728165,
      -0.03621748462319374,
      -0.02443690225481987,
      -0.006543533876538277,
      0.0013371718814596534,
      -0.009888982400298119,
      -0.07721221446990967,
      0.0024718192871659994,
      0.03480074182152748,
      0.030420299619436264,
      0.026401519775390625,
      -0.04452194646000862,
      -0.046615369617938995,
      0.030094796791672707,
      0.08953283727169037,
      -0.016011405736207962,
      0.039845410734415054,
      -0.026238011196255684,
      -0.12082427740097046,
      -0.1597999483346939,
      -0.03487358242273331,
      -0.08459801226854324,
      -0.03550839424133301,
      0.013786811381578445,
      0.02865079790353775,
      -0.060892634093761444,
      -0.02477365732192993,
      -0.05629531294107437,
      0.03495851159095764,
      0.0689711943268776,
      0.011652099899947643,
      0.013060023076832294,
      0.03157905861735344,
      0.004957408644258976,
      -0.032589275389909744
    ],
    [
      -0.07490034401416779,
      0.017573975026607513,
      -0.004158850759267807,
      -0.01065148413181305,
      0.06770724803209305,
      0.03400133550167084,
      -0.02098063752055168,
      -0.08139423280954361,
      0.02774268016219139,
      -0.019894776865839958,
      -0.057423535734415054,
      -0.023202449083328247,
      0.039334800094366074,
      -0.043389443308115005,
      0.010427855886518955,
      0.05964456498622894,
      -0.0025866462383419275,
      0.04998348653316498,
      -0.03877206891775131,
      0.016816234216094017,
      -0.016303125768899918,
      0.03355037793517113,
      0.11391455680131912,
      0.03137139230966568,
      -0.01815713383257389,
      0.09219292551279068,
      -0.03820037841796875,
      0.011790722608566284,
      -0.01953195035457611,
      0.05407138913869858,
      -0.01733032800257206,
      -0.022134726867079735,
      0.03086700104176998,
      0.15451517701148987,
      -0.06252982467412949,
      -0.06854314357042313,
      0.014788882806897163,
      -0.06521435081958771,
      0.017180060967803,
      0.010633156634867191,
      -0.06350607424974442,
      0.014564642682671547,
      -0.011898127384483814,
      0.026769615709781647,
      -0.005472433753311634,
      0.01210192684084177,
      0.010238058865070343,
      -0.059979893267154694,
      0.03965704143047333,
      -0.019595880061388016,
      0.023450512439012527,
      -0.0432886928319931,
      0.05126938596367836,
      -0.0009246900444850326,
      0.015944739803671837,
      -0.003381064161658287,
      -0.12977242469787598,
      0.0483747199177742,
      -0.018340468406677246,
      0.04593929648399353,
      -0.028294498100876808,
      0.08571670204401016,
      0.09835478663444519,
      -0.05220844969153404,
      -0.006935773883014917,
      0.005975645966827869,
      -0.024376172572374344,
      -0.0945763885974884,
      0.02902694046497345,
      -0.06239708513021469,
      0.01962353102862835,
      0.03994207829236984,
      0.03320350497961044,
      0.050522349774837494,
      -0.0595613569021225,
      0.01656765677034855,
      0.08913065493106842,
      -0.009794024750590324,
      0.014455056749284267,
      0.02874159812927246,
      0.10967862606048584,
      0.055812329053878784,
      0.04043709859251976,
      0.03643902391195297,
      -0.0009609941625967622,
      -0.053275711834430695,
      0.09912117570638657,
      -0.0018233011942356825,
      -0.005592515226453543,
      -0.010733600705862045,
      -0.10988952219486237,
      0.015090474858880043,
      0.05714240297675133,
      -0.030621912330389023,
      -0.028632506728172302,
      0.07840655744075775,
      0.020662778988480568,
      -0.06634289026260376,
      -0.008450486697256565,
      -0.004608334507793188,
      -0.007753919344395399,
      0.07360698282718658,
      -0.09597285836935043,
      0.04149636998772621,
      0.006251769606024027,
      0.0621524304151535,
      0.06851567327976227,
      -0.04729427024722099,
      0.05263960361480713,
      0.0369749516248703,
      -0.03176461160182953,
      -0.033830974251031876,
      0.02962387539446354,
      0.0020921474788337946,
      0.09763694554567337,
      -0.06641995906829834,
      -0.007381785660982132,
      -0.0425143763422966,
      0.056234993040561676,
      0.02038848027586937,
      0.027816860005259514,
      -0.03869803622364998,
      0.04810181260108948,
      -0.06308671087026596,
      -0.1207452341914177,
      0.03427225723862648,
      -0.04330775514245033,
      -0.01241206657141447,
      -0.09005419909954071,
      0.06345618516206741,
      -0.03372088074684143,
      0.1031825840473175,
      -0.008011331781744957,
      -0.023602619767189026,
      -0.03810406103730202,
      -0.026042260229587555,
      -0.08803903311491013,
      -0.014592384919524193,
      0.06290481239557266,
      0.05318819731473923,
      -0.018544940277934074,
      -0.03595174476504326,
      0.04904907941818237,
      -0.009366709738969803,
      0.008888443000614643,
      0.015767036005854607,
      -0.012832586653530598,
      -0.0337054543197155,
      0.06500700116157532,
      0.054242826998233795,
      0.017856117337942123,
      0.02922377549111843,
      0.04016365110874176,
      -0.029929054901003838,
      0.02563883364200592,
      0.0877411738038063,
      -0.06543174386024475,
      -0.016730137169361115,
      -0.0021847225725650787,
      -0.015124660916626453,
      0.02974606864154339,
      0.0702114924788475,
      -0.10913068801164627,
      -0.03781561553478241,
      -0.022849885746836662,
      0.01568722166121006,
      -0.014258963987231255,
      -0.03470835089683533,
      0.00725277466699481,
      0.06405009329319,
      0.01990969106554985,
      0.032015956938266754,
      -0.12913715839385986,
      0.04367004334926605,
      0.05553438887000084,
      -0.031658757477998734,
      0.023671746253967285,
      -0.0006657593185082078,
      0.0020770651753991842,
      -0.00026876211632043123,
      -0.03694259375333786,
      0.05174446851015091,
      0.011094952933490276,
      0.07034008204936981,
      -0.03357022628188133,
      -0.09856797009706497,
      0.018122779205441475,
      -0.012224755249917507,
      -0.012987666763365269,
      0.07626791298389435,
      0.020733803510665894,
      0.02158329263329506,
      0.03845316171646118,
      -0.0348157063126564,
      -0.06573539972305298,
      0.005322603043168783,
      -0.017178932204842567,
      0.022772714495658875,
      0.03647339344024658,
      0.009649766609072685,
      -0.022097576409578323,
      -0.026058021932840347,
      0.013922205194830894,
      -0.046399135142564774,
      0.025243576616048813,
      0.03675585985183716,
      0.02554277889430523,
      -0.0689830482006073,
      -0.017744682729244232,
      0.028623921796679497,
      0.026033909991383553,
      0.030519193038344383,
      0.012934114784002304,
      0.046906109899282455,
      -0.015111638233065605,
      -0.022320108488202095,
      0.06237427890300751,
      0.021639948710799217,
      -0.02271886169910431,
      -0.060817986726760864,
      0.03370242938399315,
      -0.07859120517969131,
      0.042133186012506485,
      0.05270778760313988,
      0.01953885704278946,
      0.007597427349537611,
      -0.0592753142118454,
      -0.04598942771553993,
      0.04152870178222656,
      -0.10094451159238815,
      0.01324530877172947,
      -0.023816026747226715,
      0.03555627167224884,
      0.0008220595773309469,
      0.015401763841509819,
      0.016616305336356163,
      -0.07672358304262161,
      0.03430144488811493,
      0.022990919649600983,
      0.07720896601676941,
      -0.021057790145277977,
      -0.009703954681754112,
      -0.012934902682900429,
      -0.008953195065259933,
      -0.06053388863801956,
      0.01771981082856655,
      -0.09259161353111267,
      0.0022206013090908527,
      -0.030643774196505547,
      0.025894276797771454,
      0.04569178819656372,
      0.018943194299936295,
      -0.0441090390086174,
      -0.056557316333055496,
      0.030859999358654022,
      0.024134928360581398,
      0.004361802712082863,
      -0.012753048911690712,
      0.05348619073629379,
      0.036085695028305054,
      -0.0302018690854311,
      -0.015212556347250938,
      -0.0777268260717392,
      -0.06388454139232635,
      -0.017017265781760216,
      -0.0354313999414444,
      0.044474564492702484,
      -0.03588921204209328,
      0.03780469670891762,
      0.04294658824801445,
      -0.08689741045236588,
      0.07753418385982513,
      -0.04401610791683197,
      0.03779449313879013,
      0.09243647754192352,
      0.026308495551347733,
      0.004308556672185659,
      0.021924545988440514,
      -0.030797192826867104,
      -0.007305346429347992,
      -0.012576280161738396,
      0.03425632044672966,
      0.04495890066027641,
      0.149836927652359,
      -0.08421027660369873,
      -0.07104194164276123,
      0.032725006341934204,
      -0.027651265263557434,
      -0.055309783667325974,
      0.00952756404876709,
      -0.04170231521129608,
      0.08773326873779297,
      0.049947500228881836,
      0.08090364187955856,
      0.043101441115140915,
      0.005736365914344788,
      0.0017227439675480127,
      0.05161795765161514,
      0.06370017677545547,
      0.08843245357275009,
      -0.02408667653799057,
      0.07116052508354187,
      -0.12810520827770233,
      -0.026365896686911583,
      0.04083706811070442,
      -0.04116826131939888,
      -0.014893200248479843,
      -0.036844685673713684,
      -0.0012685339897871017,
      0.027168920263648033,
      -0.04512181505560875,
      -0.08058416843414307,
      -0.047985151410102844,
      0.04179113730788231,
      -0.020929662510752678,
      -0.007681794930249453,
      -0.018850570544600487,
      -0.03583040460944176,
      -0.04901712015271187,
      0.004848698619753122,
      0.07430705428123474,
      -0.05758891999721527,
      -0.023540178313851357,
      0.0168742798268795,
      -0.014986126683652401,
      -0.01564827375113964,
      -0.016849929466843605,
      -0.06461335718631744,
      0.1349979043006897,
      -0.04085806757211685,
      -0.06235697492957115,
      0.01668296754360199,
      0.06853445619344711,
      0.02687160111963749,
      -0.01101619377732277,
      -0.032690662890672684,
      -0.0028244645800441504,
      0.05067369341850281,
      -0.12161171436309814,
      0.019988631829619408,
      0.011670171283185482,
      0.005705222021788359,
      0.004786171484738588,
      0.050930239260196686,
      -0.05378740280866623,
      0.02570389024913311,
      0.016072876751422882,
      -0.052204083651304245,
      -0.016454804688692093,
      -0.05581148713827133,
      -0.0036087417975068092,
      -0.026946881785988808,
      0.05912100151181221,
      0.04061795026063919,
      0.11929713934659958,
      0.025046657770872116,
      0.000840539752971381,
      0.04326504468917847,
      -0.12570929527282715,
      0.004403903614729643,
      0.07115364074707031,
      -0.0502655915915966,
      -0.00835584569722414,
      -0.0207696370780468,
      -0.025745296850800514,
      0.0437350757420063,
      -0.018430758267641068,
      0.0014869109727442265,
      -0.00955874938517809,
      -0.02059216797351837,
      -0.06203564628958702,
      0.02469753473997116,
      -0.03568258509039879,
      0.0976848155260086,
      -0.03378581255674362,
      -0.0026029672008007765,
      0.009346174076199532,
      0.012140934355556965,
      -0.024022726342082024,
      0.052040573209524155,
      0.01348128728568554,
      0.04556967318058014,
      -0.049592360854148865,
      0.0036872646305710077,
      0.006863995920866728,
      -0.059593137353658676,
      -0.007671955041587353,
      0.0031881725881248713,
      0.004740686155855656,
      -0.02071804367005825,
      -0.02256595902144909,
      0.065007284283638,
      -0.04621009901165962,
      0.022739559412002563,
      -0.005091735161840916,
      0.02020150050520897,
      -0.05746142193675041,
      -0.06194550171494484,
      -0.0028866829816251993,
      0.03249691426753998,
      0.0004750410153064877,
      0.08365486562252045,
      -0.0040367357432842255,
      0.0007129587465897202,
      -0.0038368275854736567,
      0.05252593383193016,
      -0.047270264476537704,
      -0.004806726239621639,
      0.1689784675836563,
      -0.012097282335162163,
      -0.08074802160263062,
      0.09916394203901291,
      0.03718308359384537,
      0.012715025804936886,
      0.0019606631249189377,
      -0.043790385127067566,
      0.04894643649458885,
      -0.01908409409224987,
      0.07845443487167358,
      -0.01964397169649601,
      0.029837388545274734,
      -0.02553754672408104,
      -0.022144587710499763,
      0.008950229734182358,
      0.006706189829856157,
      -0.0032746640499681234,
      -0.008761737495660782,
      -0.004175323992967606,
      -0.04715012013912201,
      -0.0481276735663414,
      0.01962791197001934,
      0.004152127541601658,
      -0.01604783535003662,
      -0.04483108967542648,
      0.009514903649687767,
      0.044525787234306335,
      0.004573965910822153,
      -0.05807460471987724,
      0.03702601045370102,
      -0.013406742364168167,
      -0.052933163940906525,
      -0.018195180222392082,
      -0.08125824481248856,
      0.03400418534874916,
      0.021453317254781723,
      -0.008618596009910107,
      -0.00882288534194231,
      0.08330067247152328,
      0.07807612419128418,
      -0.08068909496068954,
      -0.029698478057980537,
      0.0502387173473835,
      -0.05409740284085274,
      0.03328270837664604,
      0.0475555881857872,
      -0.05307738110423088,
      -0.030163314193487167,
      0.022917158901691437,
      0.04711209237575531,
      0.11965539306402206,
      0.02256760559976101,
      0.0394570454955101,
      -0.02176177315413952,
      -0.0014231271343305707,
      0.022801140323281288,
      -0.006197346840053797,
      -0.0383545458316803,
      0.002146180486306548,
      0.0498749241232872,
      0.005600525997579098,
      0.04206392541527748,
      -0.08908601850271225,
      0.019658025354146957,
      -0.03152504190802574,
      0.02281259559094906,
      -0.0406147837638855,
      -0.020087355747818947,
      -0.023676928132772446,
      0.05382557213306427,
      0.00038895074976608157,
      -0.0011211420642212033,
      0.059038273990154266,
      0.037746284157037735,
      -0.025073707103729248,
      -0.0005063684657216072,
      -0.013595146127045155,
      -0.07167096436023712,
      0.0008998668054118752,
      -0.04821982979774475,
      -0.07246210426092148,
      0.00224104686640203,
      0.0428633876144886,
      -0.07997799664735794,
      -0.006098512094467878,
      0.0028782417066395283,
      -0.03455962613224983,
      -0.026278560981154442,
      -0.062491096556186676,
      0.08548836410045624,
      0.020543241873383522,
      0.05044097080826759,
      -0.01833796128630638,
      -0.02325371466577053
    ],
    [
      0.044304706156253815,
      -0.0008892661426216364,
      -0.11742457747459412,
      0.007940785028040409,
      0.1059311181306839,
      0.0038618077524006367,
      0.08377920091152191,
      0.005055464804172516,
      0.06664258986711502,
      0.12419839948415756,
      -0.020798036828637123,
      0.019965115934610367,
      -0.054898977279663086,
      -0.033624548465013504,
      0.09284711629152298,
      0.017745885998010635,
      0.014944597147405148,
      0.026653358712792397,
      -0.027640895918011665,
      0.014021053910255432,
      -0.011974834837019444,
      -0.007051640190184116,
      -0.13819675147533417,
      -0.016706738620996475,
      -0.014634791761636734,
      0.02798507735133171,
      -0.025654619559645653,
      -0.02581786923110485,
      -0.008055180311203003,
      0.001572542591020465,
      0.01327819935977459,
      0.0205706600099802,
      -0.014933956786990166,
      0.05556432902812958,
      0.00430372916162014,
      -0.0038957837969064713,
      0.019856326282024384,
      0.0060847545973956585,
      -0.005479238927364349,
      0.0017484123818576336,
      -0.06387025862932205,
      0.07715439051389694,
      -0.03860412538051605,
      0.01010812632739544,
      0.06775801628828049,
      -0.029042981564998627,
      0.01709725148975849,
      0.007920779287815094,
      0.01632259413599968,
      -0.08992387354373932,
      0.024310486391186714,
      -0.0375627800822258,
      0.029722729697823524,
      0.016318684443831444,
      -0.01863073743879795,
      -0.01012309268116951,
      -0.008061005733907223,
      -0.036392077803611755,
      -0.021246708929538727,
      0.0695238932967186,
      -0.0774909034371376,
      0.07794500142335892,
      0.05800800770521164,
      -0.008046668022871017,
      0.034925591200590134,
      -0.038321953266859055,
      0.022566359490156174,
      -0.011637100949883461,
      0.03496116027235985,
      -0.014038830064237118,
      -0.03780907392501831,
      0.017181705683469772,
      -0.050776466727256775,
      -0.04932994395494461,
      0.0714813619852066,
      -0.022670209407806396,
      -0.008510300889611244,
      0.030184049159288406,
      0.01959448866546154,
      -0.008275771513581276,
      -0.059500329196453094,
      -0.04605405032634735,
      -0.04481448605656624,
      0.017713719978928566,
      -0.006584362126886845,
      0.01994416117668152,
      -0.08624112606048584,
      -0.032286256551742554,
      0.0025938127655535936,
      0.06093699857592583,
      -0.06183621287345886,
      -0.012507514096796513,
      -0.036248818039894104,
      -0.0057824295945465565,
      0.04378705099225044,
      -0.0009817986283451319,
      -0.0136062977835536,
      -0.044918157160282135,
      -0.02846416085958481,
      -0.03467336297035217,
      0.0165462214499712,
      0.0024723815731704235,
      -0.0394778698682785,
      -0.03410731628537178,
      0.11039578914642334,
      -0.002246192190796137,
      -0.020882613956928253,
      0.04117586463689804,
      -0.009643037803471088,
      0.12400239706039429,
      -0.024978721514344215,
      0.014402328990399837,
      0.017223428934812546,
      -0.038455575704574585,
      0.04565352946519852,
      -0.059079304337501526,
      -0.037913233041763306,
      -0.045642413198947906,
      0.06227262318134308,
      -0.03361161798238754,
      0.07409559935331345,
      -0.058805905282497406,
      -0.009182850830256939,
      -0.05287693440914154,
      0.014187748543918133,
      0.053673580288887024,
      -0.0067433821968734264,
      -0.008546141907572746,
      -0.003199594561010599,
      -0.012749400921165943,
      -0.03412746638059616,
      -0.04004017263650894,
      -0.03941475972533226,
      0.04459591209888458,
      -0.027926336973905563,
      0.023428568616509438,
      0.05231671780347824,
      0.01442712638527155,
      -0.015071384608745575,
      0.03226635977625847,
      0.0028565125539898872,
      -0.011711743660271168,
      -0.008841194212436676,
      -0.015151364728808403,
      -0.07125750184059143,
      -0.02484687604010105,
      0.029688306152820587,
      0.04625609144568443,
      -0.05962776392698288,
      -0.0006570137338712811,
      -0.057881295680999756,
      -0.055329639464616776,
      -0.03636020049452782,
      0.01978999376296997,
      -0.022403551265597343,
      0.01867000199854374,
      -0.05420152470469475,
      -0.024485856294631958,
      0.002702687168493867,
      0.026173250749707222,
      -0.025734644383192062,
      0.04826394468545914,
      -0.018094005063176155,
      -0.07579278945922852,
      0.023564504459500313,
      0.005860331933945417,
      0.03171548992395401,
      0.030059777200222015,
      -0.06330647319555283,
      0.030459504574537277,
      0.03446175530552864,
      -0.033094555139541626,
      0.06830333173274994,
      -0.06259652972221375,
      0.050673212856054306,
      -0.052915867418050766,
      0.0458669438958168,
      0.017827849835157394,
      -0.06512027233839035,
      -0.010242810472846031,
      0.002865731017664075,
      0.03110591508448124,
      -0.051701825112104416,
      0.011037618853151798,
      0.004625832196325064,
      0.0054348912090063095,
      0.011250818148255348,
      0.06521191447973251,
      -0.01899006962776184,
      -0.04673529788851738,
      -0.01869579777121544,
      -0.02869272604584694,
      -0.036883607506752014,
      0.014783515594899654,
      0.021017760038375854,
      0.03914507478475571,
      -0.03135112673044205,
      0.07009033113718033,
      -0.08939237892627716,
      -0.008774654939770699,
      0.026012729853391647,
      -0.03601090610027313,
      0.04810773581266403,
      0.05910396948456764,
      -0.06294772028923035,
      0.017732057720422745,
      0.016174089163541794,
      -0.025488469749689102,
      0.06729614734649658,
      -0.017745506018400192,
      0.07807385921478271,
      -0.02311657927930355,
      0.03717288374900818,
      0.006515338085591793,
      0.014503146521747112,
      -0.004182605538517237,
      -0.07611311227083206,
      -0.04107382893562317,
      -0.0131698502227664,
      -0.04690171033143997,
      -0.028365587815642357,
      -0.02310597337782383,
      0.058560702949762344,
      -0.054809220135211945,
      0.061970364302396774,
      0.0160713791847229,
      -0.07457654923200607,
      -0.05527421087026596,
      0.027044644579291344,
      -0.04194239154458046,
      0.03073967806994915,
      0.043409716337919235,
      0.0589836947619915,
      0.09042447060346603,
      0.014200813136994839,
      0.0021292262244969606,
      0.09230179339647293,
      -0.0021441837307065725,
      0.009646068327128887,
      0.024065501987934113,
      0.05054812133312225,
      -0.07118348032236099,
      0.04124218225479126,
      -0.06345140933990479,
      -0.10608380287885666,
      -0.07836637645959854,
      -0.06494829803705215,
      -0.001059407484717667,
      0.007174843456596136,
      -0.03450533375144005,
      -0.02724076248705387,
      -0.15048106014728546,
      1.4599469068343751e-05,
      0.018623165786266327,
      -0.024484125897288322,
      0.03528515249490738,
      0.021998807787895203,
      -0.08160588890314102,
      0.02305677905678749,
      0.025378771126270294,
      -0.023692933842539787,
      -0.011180904693901539,
      0.01089558470994234,
      0.010990144684910774,
      -0.050674423575401306,
      0.009185400791466236,
      0.07956267893314362,
      0.023092146962881088,
      -0.05088123679161072,
      -0.047314006835222244,
      0.021596435457468033,
      0.10406635701656342,
      0.08513675630092621,
      0.021585678681731224,
      -0.0002795447362586856,
      -0.059203874319791794,
      -0.0008532469510100782,
      -0.012829598970711231,
      -0.07792912423610687,
      0.03828391060233116,
      0.03220677748322487,
      0.004894251469522715,
      -0.022116543725132942,
      -0.05501936003565788,
      -0.0055419085547327995,
      -0.055129989981651306,
      -0.0004396126314532012,
      0.04575886204838753,
      0.0021087198983877897,
      0.027249548584222794,
      -0.027933083474636078,
      -0.025507131591439247,
      0.03280001878738403,
      -0.03243592008948326,
      -0.09022533148527145,
      0.048410799354314804,
      -0.04807843267917633,
      -0.012475362978875637,
      -0.04324515908956528,
      0.03331802412867546,
      0.0017698340816423297,
      -0.0015307936118915677,
      0.04351860284805298,
      -0.007468706462532282,
      -0.052784498780965805,
      -0.04242631047964096,
      -0.032038602977991104,
      0.003994903527200222,
      -0.1033668965101242,
      0.01391446590423584,
      -0.011007782071828842,
      0.0417480543255806,
      -0.004789198748767376,
      0.029397564008831978,
      0.04241970181465149,
      -0.060480356216430664,
      -0.0013090764405205846,
      -0.006602111272513866,
      -0.041715700179338455,
      0.007587539032101631,
      -0.004253847990185022,
      -0.006253400351852179,
      0.07946420460939407,
      0.035521868616342545,
      -0.03134096786379814,
      0.0049621229991316795,
      -0.0995742455124855,
      0.04120466485619545,
      0.08375175297260284,
      -0.017008734866976738,
      0.030916763469576836,
      -0.07101433724164963,
      -0.01777723990380764,
      -0.05897269770503044,
      -0.0006160648190416396,
      0.03384964168071747,
      0.026712903752923012,
      0.067107193171978,
      -0.03843636065721512,
      0.005070078652352095,
      -0.009908514097332954,
      -0.015362453646957874,
      -0.028506111353635788,
      -0.05557534843683243,
      0.06529069691896439,
      -0.010033141821622849,
      -0.01188174169510603,
      0.015650702640414238,
      -0.010374929755926132,
      -0.040644124150276184,
      0.028676753863692284,
      0.1098647341132164,
      -0.00901861023157835,
      -0.016233602538704872,
      -0.02195439487695694,
      0.034815531224012375,
      0.019650666043162346,
      0.0029415329918265343,
      -0.06789170205593109,
      -0.04865520820021629,
      0.0503217875957489,
      0.038330599665641785,
      -0.01553223468363285,
      -0.0031074557919055223,
      0.017347635701298714,
      0.044888686388731,
      -0.02098659612238407,
      0.05471065640449524,
      0.049089569598436356,
      -0.01963796280324459,
      -0.01915201172232628,
      -0.07740268856287003,
      0.08973081409931183,
      -0.08535441756248474,
      0.02817811816930771,
      0.0003795912489295006,
      0.011545303277671337,
      0.006459766533225775,
      0.08221043646335602,
      -0.04196370765566826,
      -0.028886539861559868,
      0.016004793345928192,
      -0.02434058114886284,
      0.005794834345579147,
      -0.0203599464148283,
      0.036637142300605774,
      0.012657083570957184,
      -0.016527168452739716,
      0.030875638127326965,
      -0.03013022243976593,
      0.016409097239375114,
      0.023282581940293312,
      0.014414975419640541,
      0.009292270056903362,
      -0.050221383571624756,
      -0.008318866603076458,
      -0.0773458331823349,
      -0.023698315024375916,
      -0.05357716605067253,
      0.007766260299831629,
      -0.08648708462715149,
      -0.05086986720561981,
      -0.028872530907392502,
      0.011548750102519989,
      0.018726017326116562,
      0.04715769737958908,
      -0.03629770874977112,
      -0.006626722402870655,
      -0.02328043431043625,
      0.02893191948533058,
      0.10189762711524963,
      -0.02966483309864998,
      0.03803377225995064,
      0.0026632705703377724,
      0.004610545467585325,
      -0.002909761155024171,
      -0.04113415256142616,
      -0.008042903617024422,
      0.008980202488601208,
      -0.0630577802658081,
      0.02250651828944683,
      -0.02195589430630207,
      -0.02050136774778366,
      0.1643752157688141,
      0.07177748531103134,
      -0.050773944705724716,
      -0.02490277588367462,
      -0.00891969259828329,
      -0.07562903314828873,
      -0.0201874952763319,
      0.08146875351667404,
      0.11621031910181046,
      0.07641085237264633,
      -0.03797880932688713,
      -0.020310599356889725,
      0.052621468901634216,
      0.12649361789226532,
      0.021790945902466774,
      0.08777175843715668,
      -0.0007395537686534226,
      -0.037736676633358,
      0.017253749072551727,
      -0.002397641772404313,
      -0.04271246865391731,
      0.06129095330834389,
      0.08712686598300934,
      -0.0062284222804009914,
      0.06372097134590149,
      -0.02117992378771305,
      0.047614749521017075,
      0.045222122222185135,
      -0.0030675632879137993,
      -0.04753964766860008,
      0.043472159653902054,
      -0.012406573630869389,
      -0.04326942190527916,
      0.036228686571121216,
      0.0681285560131073,
      0.01466252002865076,
      -0.11933301389217377,
      -0.0030857387464493513,
      -0.04423033073544502,
      0.021438179537653923,
      -0.027151085436344147,
      -0.014595423825085163,
      0.03846265748143196,
      -0.00556648476049304,
      -0.01652098074555397,
      -0.023700661957263947,
      0.033012036234140396,
      0.07139008492231369,
      -0.0051104421727359295,
      0.064228855073452,
      0.014483085833489895,
      0.013772942125797272,
      -0.0033311545848846436,
      -0.13479162752628326,
      -0.03164896368980408,
      -0.02445041388273239,
      0.03175557032227516,
      -0.04120619595050812,
      -0.009248649701476097,
      0.03607510030269623,
      -0.02341589145362377,
      0.04809508100152016,
      0.02098689414560795,
      0.008783782832324505,
      -0.016060717403888702,
      -0.0024062409065663815,
      -0.07045134902000427,
      -0.00964747741818428,
      -0.0025393501855432987,
      0.03215085715055466,
      -0.06884628534317017,
      0.006968900561332703,
      -0.01646655797958374,
      0.002858022227883339,
      0.036537136882543564,
      0.03210842236876488,
      0.030201086774468422,
      -0.015759214758872986,
      -0.0670771673321724,
      -0.04169199988245964
    ],
    [
      0.028774412348866463,
      0.015427145175635815,
      0.013376553542912006,
      0.011024586856365204,
      0.07668746262788773,
      0.004698968958109617,
      -0.024970345199108124,
      0.04694511741399765,
      0.0574195496737957,
      0.045789267867803574,
      0.019489651545882225,
      0.0648636743426323,
      -0.05438808351755142,
      0.057674240320920944,
      0.006067213602364063,
      0.0718609169125557,
      -0.020166369155049324,
      -0.02057500183582306,
      0.008519455790519714,
      0.02020508423447609,
      0.026947354897856712,
      0.037768635898828506,
      -0.00117632863111794,
      0.10682667046785355,
      -0.09213140606880188,
      -0.061806272715330124,
      -0.015147808939218521,
      -0.0306587852537632,
      -0.004973009694367647,
      0.09263207763433456,
      0.03308344632387161,
      -0.013615977019071579,
      -0.027684286236763,
      -0.09905725717544556,
      -0.009089904837310314,
      0.0071069709956645966,
      0.061212096363306046,
      0.08657437562942505,
      -0.011977491900324821,
      -0.018812881782650948,
      0.033920105546712875,
      0.06175991892814636,
      -0.015187939628958702,
      0.025327417999505997,
      -0.03174405172467232,
      -0.03611426800489426,
      -0.07293015718460083,
      0.03112686797976494,
      0.012876691296696663,
      -0.012547481805086136,
      0.015738746151328087,
      -0.0012504510814324021,
      -0.020844144746661186,
      -0.023605287075042725,
      -0.03120153583586216,
      -0.01203180942684412,
      -0.041061338037252426,
      -0.0008741528727114201,
      -0.06549058854579926,
      -0.07804609835147858,
      -0.04482799395918846,
      -0.008985520340502262,
      -0.06594845652580261,
      -0.003621133277192712,
      -0.009073893539607525,
      0.015093755908310413,
      0.04568483307957649,
      -0.02903621830046177,
      -0.03643602132797241,
      0.0019795771222561598,
      -0.04954648017883301,
      0.05781935900449753,
      0.03298201411962509,
      0.05479814112186432,
      0.013751529157161713,
      0.014843673445284367,
      0.024075647816061974,
      0.05263223126530647,
      -0.01228491310030222,
      0.03944800794124603,
      -0.02109336107969284,
      0.0979016050696373,
      -0.02466108463704586,
      -0.005827905610203743,
      0.0598120279610157,
      0.04589347168803215,
      -0.08164788782596588,
      0.04166467487812042,
      0.06395609676837921,
      -0.0007112399325706065,
      0.021194396540522575,
      0.07804104685783386,
      -0.12231616675853729,
      0.03573000431060791,
      -0.03341086581349373,
      0.005639527924358845,
      0.02244739420711994,
      0.01732667163014412,
      0.035672035068273544,
      -0.005959526170045137,
      0.05553846061229706,
      0.05195324495434761,
      -0.12420226633548737,
      0.05895378440618515,
      0.07962743192911148,
      0.058715373277664185,
      -0.037698421627283096,
      -0.0512864887714386,
      -0.037987470626831055,
      -0.027075881138443947,
      -0.13213296234607697,
      0.0829533189535141,
      0.011018138378858566,
      0.006474050227552652,
      0.08389593660831451,
      0.01583772897720337,
      -0.034746795892715454,
      -0.003962567076086998,
      -0.04590127244591713,
      -0.01506583858281374,
      0.029547732323408127,
      0.07383521646261215,
      0.02090461552143097,
      0.004026435315608978,
      -0.10193867981433868,
      0.011938001960515976,
      -0.01924240030348301,
      0.02158723771572113,
      -0.08387789130210876,
      -0.03702966496348381,
      0.040191229432821274,
      -0.10963238030672073,
      0.031626146286726,
      -0.058893024921417236,
      -0.03180934116244316,
      0.005297217518091202,
      -0.022921185940504074,
      -0.0069574699737131596,
      0.03659480810165405,
      -0.00623443815857172,
      0.0034064988140016794,
      0.005240802187472582,
      0.001840229146182537,
      -0.07288248836994171,
      -0.005359177477657795,
      0.07204075157642365,
      -0.07575812190771103,
      -0.0033901063725352287,
      0.020972298458218575,
      -0.006081391591578722,
      -0.006093268748372793,
      0.06963474303483963,
      -0.03577100858092308,
      0.03565536066889763,
      -0.011154907755553722,
      0.06038869172334671,
      0.015262468717992306,
      -0.011639376170933247,
      -0.012618616223335266,
      0.03941299393773079,
      0.04129901900887489,
      0.004919707775115967,
      0.08440189808607101,
      -0.00932741817086935,
      -0.08943552523851395,
      -0.005203199572861195,
      0.0213504359126091,
      -0.05874885618686676,
      0.015966570004820824,
      0.13151590526103973,
      0.026692355051636696,
      0.03675294667482376,
      0.004386058542877436,
      -0.10413654893636703,
      -0.06477184593677521,
      0.03837167099118233,
      -0.027876976877450943,
      -0.015871470794081688,
      0.010926489718258381,
      0.02696649357676506,
      -0.045106858015060425,
      -0.11598707735538483,
      -0.029844727367162704,
      0.04437435790896416,
      -0.023189950734376907,
      0.024582810699939728,
      -0.02385242097079754,
      0.038247011601924896,
      -0.005551941227167845,
      0.007286697626113892,
      0.007368253078311682,
      -0.03892677649855614,
      -0.007487364113330841,
      0.019709661602973938,
      0.06616717576980591,
      -0.004824337549507618,
      0.07007161527872086,
      0.06208938732743263,
      0.04814695939421654,
      0.08217057585716248,
      -0.07330245524644852,
      -0.05620739981532097,
      -0.009267386049032211,
      -0.07648985832929611,
      0.10284427553415298,
      0.052294738590717316,
      -0.0425039678812027,
      0.0003366419696249068,
      -0.008228985592722893,
      0.013080582953989506,
      0.025747913867235184,
      -0.008129730820655823,
      -0.016211245208978653,
      0.08507456630468369,
      0.02063463255763054,
      -0.0040543475188314915,
      -0.054695673286914825,
      0.04157477244734764,
      -0.05429082363843918,
      -0.028871839866042137,
      0.013153288513422012,
      -0.04515599459409714,
      0.02390994504094124,
      0.006037326995283365,
      0.06331907957792282,
      -0.014609772711992264,
      -0.04679127037525177,
      -0.07138888537883759,
      -0.05629460886120796,
      0.014017710462212563,
      0.09582448750734329,
      0.0015930492663756013,
      0.0014256618451327085,
      0.058671750128269196,
      0.04508213326334953,
      0.04348037391901016,
      0.019342677667737007,
      0.04978259652853012,
      -0.05843517929315567,
      -0.030457859858870506,
      0.030833406373858452,
      -0.07587310671806335,
      0.06922473013401031,
      0.0698418915271759,
      -0.007393728010356426,
      -0.013346124440431595,
      -0.025487758219242096,
      0.015842007473111153,
      -0.02226344868540764,
      -0.09636441618204117,
      -0.08004887402057648,
      -0.010009805671870708,
      0.017405910417437553,
      -0.04341994225978851,
      -0.025122663006186485,
      -0.0783625990152359,
      0.02472667209804058,
      -0.059555623680353165,
      -0.005812854040414095,
      -0.03833945840597153,
      0.01219087652862072,
      0.09437493234872818,
      -0.005946354474872351,
      -0.028218302875757217,
      -0.02224534936249256,
      -0.04406145215034485,
      -0.09382517635822296,
      -0.032618843019008636,
      0.03605864942073822,
      0.0009651676518842578,
      0.02766023576259613,
      0.023173853754997253,
      -0.02422785572707653,
      -0.041658177971839905,
      -0.027138924226164818,
      0.02369111217558384,
      0.04038191959261894,
      0.027671854943037033,
      -0.004531360697001219,
      -0.04114256426692009,
      0.004996150266379118,
      -0.009611671790480614,
      0.08161704242229462,
      0.09978051483631134,
      -0.03325483202934265,
      0.007707909215241671,
      0.012726964429020882,
      0.014454853720963001,
      -0.0008260015747509897,
      -0.07140561938285828,
      -0.07463791221380234,
      0.05181732028722763,
      -0.013037381693720818,
      0.03579439967870712,
      0.06517446786165237,
      0.028005855157971382,
      0.004838153719902039,
      -0.06363982707262039,
      0.031644172966480255,
      -0.010409102775156498,
      0.01944616809487343,
      -0.012353156693279743,
      0.04111451655626297,
      0.02949289232492447,
      0.01845330186188221,
      -0.02745847962796688,
      0.005009375046938658,
      0.004519532434642315,
      -0.053573571145534515,
      0.08003005385398865,
      -0.0031493064016103745,
      0.0029891356825828552,
      0.01110919564962387,
      -0.023655550554394722,
      0.015509183518588543,
      0.0722968727350235,
      0.02368002198636532,
      -0.04543601721525192,
      0.05375092476606369,
      -0.03750024735927582,
      0.041049763560295105,
      0.04898117482662201,
      -0.0036147991195321083,
      0.032722536474466324,
      0.040555164217948914,
      -0.02108410932123661,
      -0.08624369651079178,
      0.0827513039112091,
      0.08433236926794052,
      -0.09876064956188202,
      0.04291723668575287,
      0.02125498466193676,
      -0.0017050298629328609,
      -0.016734542325139046,
      0.02351541817188263,
      0.026671921834349632,
      -0.06932833790779114,
      -0.05183391273021698,
      -0.08344041556119919,
      -0.034847453236579895,
      0.08028315752744675,
      -0.04616165533661842,
      -0.029942821711301804,
      -0.055560462176799774,
      0.032858166843652725,
      0.02819330058991909,
      0.02219238318502903,
      0.037405844777822495,
      -0.030592573806643486,
      -0.017317034304142,
      -0.06889673322439194,
      0.013868381269276142,
      -0.001986075658351183,
      -0.012034698389470577,
      -0.03825080767273903,
      -0.028212884441018105,
      -0.04903668165206909,
      0.0924723669886589,
      -0.054186202585697174,
      -0.006678663659840822,
      -0.03586937114596367,
      -0.007276683580130339,
      -0.048239968717098236,
      0.02906912937760353,
      -0.08769829571247101,
      -0.021821076050400734,
      -0.04587257280945778,
      -0.011506155133247375,
      -0.0619235560297966,
      0.04333846643567085,
      -0.004956704098731279,
      0.051451198756694794,
      -0.030075853690505028,
      -0.0513683557510376,
      0.02707521989941597,
      0.00849529542028904,
      0.02774675562977791,
      0.02346869930624962,
      0.0651315227150917,
      -0.016056522727012634,
      0.041491128504276276,
      0.03994111344218254,
      0.0983799546957016,
      -0.022361306473612785,
      -0.0019236780935898423,
      -0.022269753739237785,
      0.0004797862493433058,
      -0.08852066099643707,
      -0.01470066700130701,
      -0.03220673277974129,
      -0.024884069338440895,
      -0.05485230311751366,
      -0.045349765568971634,
      -0.014059999026358128,
      -0.05642617121338844,
      -0.018255608156323433,
      0.03107409179210663,
      0.055307786911726,
      -0.0515747033059597,
      -0.012787670828402042,
      -0.05670518800616264,
      0.05771838501095772,
      -0.10559272021055222,
      -0.06633704900741577,
      -0.004936932120472193,
      0.03553561866283417,
      0.007250222377479076,
      -0.024432379752397537,
      0.03841693326830864,
      -0.008310259319841862,
      0.01608249917626381,
      -0.006181714124977589,
      -0.04567800834774971,
      0.03825647011399269,
      0.08345360308885574,
      0.026631848886609077,
      0.06118881329894066,
      -0.00807316042482853,
      0.023285770788788795,
      -0.027100777253508568,
      0.058175284415483475,
      0.02962569147348404,
      0.029636265709996223,
      0.03806186094880104,
      -0.0014046302530914545,
      0.0099484883248806,
      0.06486833095550537,
      -0.024519288912415504,
      -0.017947515472769737,
      -0.009770387783646584,
      -0.029973261058330536,
      -0.004391890484839678,
      -0.08298232406377792,
      0.07559049129486084,
      0.08654757589101791,
      0.036143410950899124,
      0.015186015516519547,
      0.0828809142112732,
      -0.018097199499607086,
      0.004722598474472761,
      0.023056726902723312,
      -0.06807044893503189,
      -0.01741999387741089,
      -0.0837770476937294,
      -0.02061975933611393,
      0.03572644665837288,
      0.04207862913608551,
      -0.00799365434795618,
      0.06374983489513397,
      -0.04735888913273811,
      -0.08151893317699432,
      -0.004695746582001448,
      -0.03660218417644501,
      0.040149372071027756,
      0.04433461278676987,
      -0.032980307936668396,
      -0.002950632944703102,
      0.09975135326385498,
      -0.00924244150519371,
      -0.0020570894703269005,
      -0.05176408588886261,
      0.0074310037307441235,
      0.005835974123328924,
      -0.05355969816446304,
      -0.02001061849296093,
      -0.02998114936053753,
      0.019681094214320183,
      -0.03979400917887688,
      0.05922578647732735,
      0.039884909987449646,
      0.00859753880649805,
      0.0891546756029129,
      -0.012225504033267498,
      -0.0027403119020164013,
      0.030392643064260483,
      0.08722732216119766,
      -0.048845600336790085,
      0.01516750082373619,
      0.05530460923910141,
      0.05421319603919983,
      -0.004833759739995003,
      -0.02118818089365959,
      -0.054538846015930176,
      0.005893846042454243,
      -0.030337631702423096,
      -0.009269122034311295,
      -0.02051129750907421,
      0.021153243258595467,
      0.0696069672703743,
      0.004592233803123236,
      0.021769067272543907,
      -0.02376696839928627,
      0.004886660724878311,
      -0.03358571231365204,
      0.02746465988457203,
      -0.12738440930843353,
      -0.02485150471329689,
      -0.02224227786064148,
      0.012503492645919323,
      0.009634391404688358,
      0.0808086022734642,
      0.01041255984455347,
      -0.04347037151455879
    ],
    [
      -0.039959002286195755,
      -0.003088520374149084,
      0.03022012487053871,
      0.036317840218544006,
      0.04357438161969185,
      -0.06745614111423492,
      0.02106393128633499,
      -0.07287805527448654,
      0.03945784643292427,
      0.0727507695555687,
      0.04960339143872261,
      0.0078918831422925,
      0.006400153506547213,
      0.03267442435026169,
      -0.06003157049417496,
      0.06798601895570755,
      -0.05109152942895889,
      -0.06969235837459564,
      -0.021479345858097076,
      -0.08816364407539368,
      -0.0006474205292761326,
      0.0447133406996727,
      0.029829345643520355,
      0.061819348484277725,
      -0.0506126694381237,
      0.03184965252876282,
      0.11019212007522583,
      -0.006355790421366692,
      0.00883136224001646,
      -0.026760166510939598,
      0.13185203075408936,
      0.01905193366110325,
      -0.004436596296727657,
      0.0315527468919754,
      -0.020236896350979805,
      0.0057653626427054405,
      -0.006636681966483593,
      0.04010617360472679,
      0.022134654223918915,
      0.048378605395555496,
      -0.04911476746201515,
      0.00564451003447175,
      0.0008522085845470428,
      -0.07554960995912552,
      -0.08341467380523682,
      0.04114712402224541,
      -0.10631201416254044,
      -0.043327294290065765,
      0.05023279786109924,
      0.00044456881005316973,
      0.07278212904930115,
      -0.018701577559113503,
      0.002780294744297862,
      -0.016097811982035637,
      0.04209478572010994,
      -0.039300885051488876,
      -0.03188004717230797,
      -0.05869406834244728,
      0.009963270276784897,
      0.04966937378048897,
      0.03186812251806259,
      0.015459760092198849,
      -0.05866144970059395,
      0.053013455122709274,
      -0.015137848444283009,
      0.06546324491500854,
      0.015562162734568119,
      -0.0177841167896986,
      0.008538033813238144,
      0.0012104936176910996,
      0.02098432369530201,
      0.05286937206983566,
      0.08104579895734787,
      0.05444500967860222,
      -0.020179560407996178,
      0.0709380954504013,
      -0.03167489171028137,
      -0.01739083044230938,
      -0.004921089857816696,
      -0.05891863629221916,
      -0.02396373823285103,
      0.07756030559539795,
      0.0036578087601810694,
      -0.08421487361192703,
      0.05131584033370018,
      -0.062411025166511536,
      -0.06274527311325073,
      -0.09254170954227448,
      -0.02376363053917885,
      -0.04660565406084061,
      -0.019799701869487762,
      0.016711406409740448,
      -0.06199197471141815,
      0.05441519245505333,
      -0.07859960943460464,
      0.039578571915626526,
      -0.02232186682522297,
      0.04536531865596771,
      -0.005233996082097292,
      -0.04342719167470932,
      0.17222042381763458,
      -0.04749748483300209,
      -0.055728111416101456,
      0.03813314065337181,
      0.071683369576931,
      -0.005691888276487589,
      0.011767872609198093,
      -0.03523293510079384,
      -0.042337190359830856,
      0.0018980572931468487,
      -0.10584056377410889,
      -0.10670795291662216,
      0.05314982682466507,
      -0.008630667813122272,
      -0.008318126201629639,
      0.0799051970243454,
      0.06727281957864761,
      0.021415267139673233,
      0.051918040961027145,
      -0.04579361528158188,
      -0.07598354667425156,
      -0.05202771723270416,
      0.0027705368120223284,
      -0.03809318318963051,
      -0.033569272607564926,
      0.05902732536196709,
      -0.017401697114109993,
      0.06059424579143524,
      0.013860316947102547,
      0.06368131190538406,
      -0.00011734802683349699,
      0.052327461540699005,
      0.012307371944189072,
      0.05576283484697342,
      -0.011126861907541752,
      -0.001156873651780188,
      0.1177319884300232,
      0.04894081503152847,
      0.025820011273026466,
      -0.05142483487725258,
      0.0400191955268383,
      0.012627757154405117,
      -0.05225134640932083,
      -0.015805823728442192,
      0.03811802715063095,
      -0.04680042713880539,
      -0.05571293830871582,
      0.013082190416753292,
      0.01626523956656456,
      0.052901994436979294,
      0.017718162387609482,
      0.03260481357574463,
      0.046969082206487656,
      0.01046114694327116,
      0.0454033687710762,
      0.03521756827831268,
      -0.03939508646726608,
      0.014307994395494461,
      -0.023584812879562378,
      0.04546847194433212,
      -0.005734909325838089,
      0.012569490820169449,
      0.03283417597413063,
      -0.015720859169960022,
      -0.02464967407286167,
      -0.017439723014831543,
      -0.04157865792512894,
      -0.029371630400419235,
      0.06133685261011124,
      0.02080579847097397,
      -0.02714063972234726,
      0.04740878939628601,
      0.044984120875597,
      -0.0348370261490345,
      0.05256243795156479,
      0.05912867933511734,
      0.032602712512016296,
      -0.01937371864914894,
      -0.03041687048971653,
      0.08306488394737244,
      -0.03262889012694359,
      0.0813317820429802,
      0.045210614800453186,
      -0.01919657364487648,
      0.024987803772091866,
      -0.011608868837356567,
      0.0641852393746376,
      0.0029563475400209427,
      0.03172680735588074,
      -0.10213921964168549,
      -0.0033882714342325926,
      -0.003992934711277485,
      -0.0004493137530516833,
      0.011304112151265144,
      0.007163564674556255,
      0.02343129739165306,
      -0.04137188568711281,
      -0.005009813234210014,
      0.1328514963388443,
      0.03490886837244034,
      -0.011241858825087547,
      0.06255842000246048,
      0.045119453221559525,
      0.002332816831767559,
      0.01804579608142376,
      0.03423287719488144,
      0.05182018503546715,
      -0.11164259910583496,
      0.04945576936006546,
      0.0916638895869255,
      -0.000511376594658941,
      0.055425792932510376,
      0.01171866338700056,
      -0.029606062918901443,
      -0.027118930593132973,
      0.034731145948171616,
      -0.07079337537288666,
      0.04294487088918686,
      -0.06581585854291916,
      -0.05398857966065407,
      -0.006828253623098135,
      -0.02254374325275421,
      -0.063448466360569,
      -0.038084033876657486,
      -0.015468791127204895,
      -0.03960428759455681,
      0.04148115590214729,
      -0.04445723816752434,
      0.03547822684049606,
      0.047967322170734406,
      -0.028111478313803673,
      -0.03998859226703644,
      -0.01806606538593769,
      0.06673180311918259,
      -0.004595829639583826,
      -0.008383193053305149,
      0.03691427782177925,
      -0.050974443554878235,
      -0.03978203237056732,
      -0.0035315745044499636,
      -0.04739620164036751,
      0.011239495128393173,
      -0.032396458089351654,
      -0.01945345103740692,
      -0.025308527052402496,
      -0.02664748951792717,
      -0.03714577481150627,
      0.016322840005159378,
      -0.05162037909030914,
      -0.061399612575769424,
      0.04201850667595863,
      0.02347288280725479,
      0.05511565878987312,
      0.03996778279542923,
      0.03200439363718033,
      -0.026812827214598656,
      0.024560710415244102,
      0.004412395879626274,
      -0.07087390869855881,
      0.017386164516210556,
      -0.03250851109623909,
      0.02102835662662983,
      -0.020586596801877022,
      0.013643397018313408,
      -0.06858991831541061,
      -0.03663039579987526,
      0.03322954103350639,
      0.027436498552560806,
      -0.04197847843170166,
      0.003574424423277378,
      0.02312193624675274,
      -0.01573406159877777,
      0.0242808498442173,
      -0.022116519510746002,
      -0.027501560747623444,
      0.025590328499674797,
      -0.003960724920034409,
      0.04357123374938965,
      -0.04104888439178467,
      0.014955790713429451,
      -0.014530074782669544,
      -0.03442370519042015,
      0.03578302264213562,
      0.0644700825214386,
      0.01895689219236374,
      -0.014663341455161572,
      -0.014215180650353432,
      0.04444361478090286,
      -0.0348624661564827,
      -0.042729344218969345,
      -0.0053335209377110004,
      0.08836520463228226,
      0.020125357434153557,
      0.0027493529487401247,
      0.14322297275066376,
      0.09449643641710281,
      -0.06597962230443954,
      -0.03331444412469864,
      0.09975843876600266,
      0.024759408086538315,
      -0.019587242975831032,
      -0.012247469276189804,
      0.03178068995475769,
      0.028193341568112373,
      -0.024274297058582306,
      -0.0017677104333415627,
      -0.04286423325538635,
      -0.09634355455636978,
      -0.11225030571222305,
      -0.019425807520747185,
      0.003529124893248081,
      -0.010073690675199032,
      -0.02598540298640728,
      0.020844794809818268,
      -0.0026979083195328712,
      0.031184015795588493,
      0.03091615065932274,
      -0.007932891137897968,
      -0.014801803044974804,
      -0.012754899449646473,
      0.053231287747621536,
      0.10951622575521469,
      0.033714476972818375,
      0.03436899557709694,
      -0.032980140298604965,
      0.004407948814332485,
      -0.0064722634851932526,
      -0.026267128065228462,
      0.12000679224729538,
      0.0024316769558936357,
      0.06589008867740631,
      -0.012562097050249577,
      -0.011356700211763382,
      -0.06660192459821701,
      -0.028657982125878334,
      0.006759606767445803,
      -0.03172225132584572,
      -0.06360512226819992,
      0.009749796241521835,
      -0.0837864801287651,
      -0.05574089288711548,
      -0.028065381571650505,
      -0.03538830205798149,
      0.02595798298716545,
      -0.07713353633880615,
      -0.030610671266913414,
      -0.005955487489700317,
      -0.028132738545536995,
      -0.0628398135304451,
      0.020948249846696854,
      0.0071639735251665115,
      0.03663944825530052,
      -0.023401927202939987,
      0.029692312702536583,
      -0.029133286327123642,
      -0.029202576726675034,
      0.06760433316230774,
      0.06796667724847794,
      -0.03034897707402706,
      -0.005314156413078308,
      -0.011106478981673717,
      -0.02913888730108738,
      0.016468914225697517,
      0.04154649376869202,
      0.09298856556415558,
      -0.04815761372447014,
      -0.007460391148924828,
      0.07433139532804489,
      0.02605360560119152,
      -0.0028635773342102766,
      0.023547234013676643,
      0.029796946793794632,
      0.12002093344926834,
      0.0101130660623312,
      -0.039287958294153214,
      -0.04076147451996803,
      -0.01274721510708332,
      0.005143309477716684,
      -0.014834902249276638,
      0.009529214352369308,
      -0.07026160508394241,
      -0.059920601546764374,
      0.06951122730970383,
      -0.040272749960422516,
      -0.016108939424157143,
      0.047941725701093674,
      0.029502401128411293,
      -0.000420406082412228,
      0.022152919322252274,
      -0.027168365195393562,
      0.0007480209460482001,
      -0.043065790086984634,
      0.06676232814788818,
      0.012864600867033005,
      0.030084440484642982,
      -0.01750439964234829,
      -0.017922354862093925,
      -0.02245211787521839,
      0.01855221949517727,
      -0.010749546810984612,
      -0.034577466547489166,
      -0.037383243441581726,
      0.05409590154886246,
      -0.01853291690349579,
      0.009289569221436977,
      0.030682416632771492,
      0.05079926177859306,
      -0.02937380224466324,
      0.029104577377438545,
      0.07170002162456512,
      -0.00687531428411603,
      0.04325636103749275,
      0.024660218507051468,
      -0.028936106711626053,
      0.07612745463848114,
      -0.042782027274370193,
      -0.04140971601009369,
      0.036313172429800034,
      -0.00858544372022152,
      0.06286247819662094,
      0.036598090082407,
      -0.1258103847503662,
      -0.0362476110458374,
      -0.04659359157085419,
      -0.04089995473623276,
      -0.119655080139637,
      0.03641748055815697,
      -0.01076343934983015,
      0.011821385473012924,
      0.073379747569561,
      -0.013213244266808033,
      -0.06484521925449371,
      -0.048429783433675766,
      0.006573298014700413,
      -0.0439506471157074,
      -0.08535072207450867,
      0.03283463418483734,
      -0.005711316131055355,
      -0.04218577966094017,
      -0.085995614528656,
      0.07263229787349701,
      -0.011363849975168705,
      -0.007959826849400997,
      0.021517733111977577,
      0.010464811697602272,
      0.010944759473204613,
      -0.06068285182118416,
      -0.03525460511445999,
      -0.04929642751812935,
      0.02793477289378643,
      -0.016393663361668587,
      -0.01818513125181198,
      -0.04634588956832886,
      -0.01674962043762207,
      0.02374163456261158,
      0.01219847146421671,
      0.02243959903717041,
      0.037396691739559174,
      -0.034891027957201004,
      0.04169432073831558,
      0.00645490875467658,
      -0.020609159022569656,
      0.10151094198226929,
      -0.010037661530077457,
      0.03417330980300903,
      -0.04912467300891876,
      0.04323485121130943,
      -0.014885392971336842,
      0.058591313660144806,
      0.02457886002957821,
      -0.04310312122106552,
      -0.04024064540863037,
      -0.006397290620952845,
      0.03116813488304615,
      -0.01412541326135397,
      0.0015772338956594467,
      -0.012535683810710907,
      -0.005629879888147116,
      0.05621810257434845,
      0.025108711794018745,
      -0.08703184127807617,
      -0.004198066890239716,
      0.031233813613653183,
      0.010202105157077312,
      0.058588989078998566,
      0.05589443817734718,
      -0.00910270120948553,
      -0.06538663059473038,
      -0.019246362149715424,
      0.01893695630133152,
      -0.0275588296353817,
      0.04446618631482124,
      -0.041904982179403305,
      0.02593540959060192,
      -0.04840119183063507,
      0.05121931806206703,
      0.04747147113084793,
      0.01623840630054474,
      0.06836805492639542,
      0.009115258231759071,
      -0.04051056131720543,
      -0.04131058603525162,
      0.03904762119054794
    ],
    [
      0.012634187005460262,
      -0.0213822890073061,
      0.08165191859006882,
      0.023666322231292725,
      0.033825065940618515,
      0.007417458109557629,
      -0.015879817306995392,
      -0.010117892175912857,
      0.00831833016127348,
      -0.026796957477927208,
      0.11833492666482925,
      0.03497711941599846,
      -0.06626113504171371,
      0.044362954795360565,
      -0.09934224933385849,
      -0.005868768319487572,
      0.03688652068376541,
      -0.004474377725273371,
      0.007507557515054941,
      0.07921630889177322,
      -0.007195161655545235,
      -0.06432775408029556,
      -0.02516940049827099,
      -0.04768138378858566,
      -0.041376885026693344,
      -0.03841092064976692,
      -0.012070272117853165,
      0.004653540439903736,
      -0.03942227363586426,
      0.018173430114984512,
      -0.016570305451750755,
      0.004771157167851925,
      -0.016989031806588173,
      0.009565410204231739,
      -0.03516807779669762,
      0.04711826145648956,
      0.02550479769706726,
      0.020319845527410507,
      0.05014844238758087,
      0.009810599498450756,
      0.07514308393001556,
      0.06603921949863434,
      0.004199868068099022,
      0.04790596291422844,
      0.023965105414390564,
      0.06067917123436928,
      -0.025214407593011856,
      0.02572815865278244,
      -0.014983694069087505,
      -0.051881324499845505,
      -0.03398768976330757,
      0.015580634586513042,
      0.011062930338084698,
      0.008949418552219868,
      0.006355203688144684,
      0.009689617902040482,
      0.03584911301732063,
      -0.054688841104507446,
      -0.007402336690574884,
      0.015427610836923122,
      0.03739289939403534,
      -0.0027545394841581583,
      -0.08488099277019501,
      0.007551988121122122,
      0.07655629515647888,
      -0.1357826292514801,
      0.031290531158447266,
      0.03390563279390335,
      -0.012942242436110973,
      0.09436322748661041,
      -0.015180520713329315,
      0.08630940318107605,
      0.011655764654278755,
      0.06727292388677597,
      -0.016521606594324112,
      -0.027755819261074066,
      -0.01070177461951971,
      0.018940262496471405,
      -0.03782011196017265,
      0.004631920251995325,
      0.0010584428673610091,
      0.05697549134492874,
      0.03129158541560173,
      -0.008986042812466621,
      0.0019150112057104707,
      -0.06233574077486992,
      0.028443990275263786,
      -0.03312022611498833,
      -0.014032263308763504,
      0.03745267540216446,
      0.01718621328473091,
      0.021548878401517868,
      -0.04743535444140434,
      0.0592738501727581,
      -0.03579990193247795,
      -0.0466761514544487,
      -0.02434287965297699,
      0.018254077062010765,
      -0.08377206325531006,
      0.023559406399726868,
      0.002776104724034667,
      -0.042018573731184006,
      -0.035165637731552124,
      -0.08203993737697601,
      0.028246473520994186,
      -0.03269210085272789,
      0.0021411562338471413,
      -0.03955468907952309,
      -0.03355885669589043,
      0.07548674941062927,
      -0.03333044424653053,
      0.0064984881319105625,
      -0.0005255005089566112,
      0.026131710037589073,
      0.020332112908363342,
      0.018443144857883453,
      -0.07485146820545197,
      -0.03433471918106079,
      0.06514202803373337,
      0.033700816333293915,
      -0.02280757576227188,
      -0.06914167106151581,
      -0.020255276933312416,
      -0.029226768761873245,
      -0.016302959993481636,
      0.00847557745873928,
      0.11154275387525558,
      -0.02396993525326252,
      -0.04341966286301613,
      -0.08271299302577972,
      -0.014904878102242947,
      0.010828053578734398,
      -0.0039136046543717384,
      -0.023489566519856453,
      0.019724814221262932,
      -0.06766487658023834,
      -0.03698354959487915,
      0.010810290463268757,
      -0.12848804891109467,
      0.07439468801021576,
      -0.01806880347430706,
      -0.007999511435627937,
      -0.0754716619849205,
      -0.013604532927274704,
      -0.016132500022649765,
      -0.05947045609354973,
      0.018605994060635567,
      -0.08301664888858795,
      -0.04002194479107857,
      0.0005888753803446889,
      -0.0008907035808078945,
      0.031496312469244,
      -0.004195007029920816,
      0.06558012962341309,
      -0.031207503750920296,
      -0.05286743491888046,
      0.021413765847682953,
      0.008598905056715012,
      0.03740891069173813,
      -0.10973769426345825,
      0.04331368952989578,
      -0.0007303716265596449,
      0.02217280864715576,
      -0.010516779497265816,
      0.03844362869858742,
      -0.023322366178035736,
      0.11360561847686768,
      0.047187626361846924,
      -0.012049186043441296,
      -0.055629611015319824,
      -0.02798277512192726,
      -0.021092016249895096,
      -0.010065407492220402,
      0.0023591837380081415,
      0.0008191250381059945,
      -0.037901997566223145,
      0.05668966472148895,
      -0.048279181122779846,
      -0.10735538601875305,
      0.1084287166595459,
      0.08166682720184326,
      -0.057907797396183014,
      -0.0230858214199543,
      -0.054621849209070206,
      0.04406723752617836,
      -0.04633480682969093,
      -0.023083852604031563,
      -0.0009952470427379012,
      -0.03499516099691391,
      -0.0474950410425663,
      -0.03571790084242821,
      -0.013262427411973476,
      -0.01778975874185562,
      -0.06476274877786636,
      0.04989553615450859,
      0.04104671999812126,
      -0.06650840491056442,
      0.0032624430023133755,
      0.07878154516220093,
      -0.017489740625023842,
      0.00786240492016077,
      -0.059368301182985306,
      0.03323705494403839,
      -0.06273055076599121,
      -0.03819900378584862,
      0.026514366269111633,
      0.031383976340293884,
      0.0377080962061882,
      0.04578198119997978,
      -0.03941660374403,
      0.010873034596443176,
      0.0030654643196612597,
      0.08601503819227219,
      0.04866410046815872,
      -0.010109501890838146,
      -0.00796032976359129,
      0.0696849375963211,
      0.05315297096967697,
      0.044074639678001404,
      0.06327860057353973,
      0.04412594810128212,
      -0.018366364762187004,
      -0.07344651222229004,
      -0.010759010910987854,
      -0.04084480181336403,
      -0.014659644104540348,
      -0.008907969109714031,
      -0.03615264594554901,
      -0.00704830652102828,
      0.0833001658320427,
      -0.04035066440701485,
      -0.0553138330578804,
      0.07088463753461838,
      0.04556555300951004,
      0.024753499776124954,
      -0.02222394570708275,
      0.0006058482686057687,
      -0.037038933485746384,
      0.04905881732702255,
      -0.10380185395479202,
      -0.05238718166947365,
      -0.06616148352622986,
      -0.07874859869480133,
      -0.020125728100538254,
      -0.06786802411079407,
      0.0020555774681270123,
      -0.0047633289359509945,
      -0.05515758693218231,
      -0.0066823214292526245,
      0.09046515822410583,
      -0.03234194219112396,
      -0.019579583778977394,
      -0.009144560433924198,
      0.006566356867551804,
      -0.06225093826651573,
      -0.01255593728274107,
      -0.0636899322271347,
      0.024939924478530884,
      -0.09125044196844101,
      0.004903923254460096,
      -0.003493944648653269,
      0.017580123618245125,
      -0.0023398660123348236,
      -0.007872038520872593,
      0.0730813592672348,
      -0.029633108526468277,
      0.098039910197258,
      -0.043939925730228424,
      0.028393294662237167,
      -0.03376098349690437,
      -0.0339425690472126,
      0.06990569829940796,
      -0.019198892638087273,
      -0.02508651465177536,
      0.06392747163772583,
      -0.03408115729689598,
      -0.017613761126995087,
      0.010348446667194366,
      -0.15727195143699646,
      0.02528349496424198,
      -0.023517576977610588,
      0.07622132450342178,
      -0.021336674690246582,
      0.040480829775333405,
      -0.07438988983631134,
      -0.06086990237236023,
      -0.02138015814125538,
      0.021588416770100594,
      -0.004133585374802351,
      -0.040328942239284515,
      -0.010759087279438972,
      0.004048854578286409,
      -0.018137997016310692,
      -0.08994517475366592,
      0.034424252808094025,
      0.07187256217002869,
      -0.01900099217891693,
      -0.0907636359333992,
      -0.044410936534404755,
      0.019142722710967064,
      -0.0451338067650795,
      -0.06857930123806,
      0.04535127431154251,
      0.06788342446088791,
      0.013751774094998837,
      -0.06439865380525589,
      -0.027668241411447525,
      -0.004357088357210159,
      0.005562134087085724,
      0.009618883952498436,
      0.12617966532707214,
      -0.08763574808835983,
      0.021522728726267815,
      -0.0016080159693956375,
      0.012862984091043472,
      0.0669000893831253,
      -0.005247282795608044,
      0.01897621341049671,
      0.004801904782652855,
      -0.019053040072321892,
      0.056673675775527954,
      0.056751471012830734,
      -0.037533871829509735,
      -0.022380227223038673,
      -0.01379803940653801,
      0.003251258749514818,
      -0.08181843906641006,
      0.021519940346479416,
      0.025194086134433746,
      0.00500944210216403,
      0.03209655359387398,
      0.06920745223760605,
      -0.02767735905945301,
      0.04746367037296295,
      -0.012956569902598858,
      0.004171764012426138,
      0.042035289108753204,
      0.021969454362988472,
      -0.019598117098212242,
      0.04596924036741257,
      -0.018432628363370895,
      -0.0004295753315091133,
      -0.035094764083623886,
      0.016791220754384995,
      -0.021696388721466064,
      0.03522293642163277,
      0.03582807630300522,
      0.07394807785749435,
      -0.017216255888342857,
      0.02025587484240532,
      0.004682324361056089,
      -0.0021830603946000338,
      0.05484761297702789,
      0.015683241188526154,
      -0.02784758061170578,
      0.0359114371240139,
      0.009020338766276836,
      0.04783396050333977,
      -0.07304923981428146,
      -0.02278207801282406,
      0.0558297336101532,
      0.017681026831269264,
      0.030595526099205017,
      0.023759691044688225,
      0.016039008274674416,
      -0.04982258006930351,
      0.0032450438011437654,
      -0.08469334989786148,
      0.08047638833522797,
      -0.017068132758140564,
      -0.021189486607909203,
      -0.007923908531665802,
      0.052773188799619675,
      0.05251515284180641,
      -0.05226309224963188,
      0.0848095640540123,
      -0.03564565256237984,
      0.00261594052426517,
      0.002664001425728202,
      -0.12567010521888733,
      -0.017717620357871056,
      -0.024849869310855865,
      0.013802828267216682,
      0.005261934828013182,
      0.022878499701619148,
      0.017982009798288345,
      0.007959769107401371,
      -0.03187219798564911,
      -0.030912576243281364,
      0.010930025950074196,
      -0.07392238825559616,
      -0.05399535223841667,
      -0.10175944864749908,
      -0.04332457855343819,
      0.055277228355407715,
      0.007529210764914751,
      -0.02063203603029251,
      0.0008642987813800573,
      -0.05914454162120819,
      -8.349575364263728e-05,
      -0.1024971678853035,
      -0.06583751738071442,
      0.009641573764383793,
      -0.019586464390158653,
      0.02192714251577854,
      0.001347412820905447,
      -0.02128417231142521,
      -0.06424501538276672,
      0.09268546104431152,
      0.08268251270055771,
      0.053553592413663864,
      -0.0015779506647959352,
      0.012489731423556805,
      -0.04422423988580704,
      -0.05651078373193741,
      0.0200925525277853,
      0.020293384790420532,
      -0.08724267035722733,
      0.005172476172447205,
      0.028702279552817345,
      0.010288719087839127,
      -0.04687505587935448,
      0.033027417957782745,
      0.030556680634617805,
      0.003690805984660983,
      -0.01078917644917965,
      -0.008151416666805744,
      0.05406499654054642,
      0.050956062972545624,
      -0.10564011335372925,
      -0.06854639947414398,
      -0.028948014602065086,
      0.060528624802827835,
      0.03757549822330475,
      -0.01741333305835724,
      -0.008224263787269592,
      0.0029587564058601856,
      -0.08438391238451004,
      -0.05478202924132347,
      -0.04600963369011879,
      -0.012786823324859142,
      -0.06012728810310364,
      -0.0053316508419811726,
      0.023383168503642082,
      0.04037943109869957,
      0.004685132764279842,
      0.07084187120199203,
      0.020523158833384514,
      0.02085864543914795,
      -0.010506137274205685,
      -0.0722607970237732,
      -0.0622323639690876,
      -0.02317262999713421,
      -0.021779896691441536,
      0.02333599328994751,
      0.03974365442991257,
      0.02860231325030327,
      0.044382739812135696,
      0.008892088197171688,
      -0.034954044967889786,
      -0.028536738827824593,
      -0.1230107769370079,
      0.0690133199095726,
      0.004736799281090498,
      -0.04680244252085686,
      0.05479217693209648,
      0.06212685629725456,
      -0.025622062385082245,
      -0.03255888447165489,
      0.12944801151752472,
      0.07713541388511658,
      0.006958763115108013,
      0.07706677913665771,
      -0.014294084161520004,
      -0.02148292027413845,
      0.05528957396745682,
      -0.0077688065357506275,
      -0.03822828084230423,
      0.06273958832025528,
      0.04208725318312645,
      -0.032501883804798126,
      0.025510871782898903,
      -0.08481515944004059,
      0.03658992052078247,
      -0.0013318504206836224,
      0.013910678215324879,
      0.004872919991612434,
      -0.06251850724220276,
      0.008910058997571468,
      0.0619962178170681,
      0.09140584617853165,
      0.08592498302459717,
      0.095990851521492,
      -0.01687120459973812,
      0.005492051597684622,
      0.00017707677034195513,
      -0.019091647118330002,
      -0.04691683501005173,
      0.04280669242143631,
      0.02169269137084484,
      0.08325333893299103,
      -0.0369894914329052,
      0.059924714267253876
    ],
    [
      -0.011722437106072903,
      -0.06727492809295654,
      -0.018407857045531273,
      0.03828616440296173,
      0.009859826415777206,
      0.01410125195980072,
      0.0027137985453009605,
      0.012854723259806633,
      0.053608544170856476,
      -0.03290960192680359,
      0.013551357202231884,
      0.018221326172351837,
      -0.04050508141517639,
      -0.017572907730937004,
      0.022494547069072723,
      -0.06640429049730301,
      -0.08575326204299927,
      -0.026488903909921646,
      -0.035982318222522736,
      0.06354279071092606,
      0.021473834291100502,
      0.03043336793780327,
      -0.012567808851599693,
      0.06009925901889801,
      0.0362408421933651,
      -0.00975626241415739,
      0.023742040619254112,
      0.07147935777902603,
      -0.03600892797112465,
      -0.0673900619149208,
      -0.044877585023641586,
      -0.10044682025909424,
      -0.04495789483189583,
      0.11955656856298447,
      -0.0029927606228739023,
      -0.015403433702886105,
      0.02217511087656021,
      0.06767699867486954,
      -0.029051870107650757,
      0.045523855835199356,
      0.06562252342700958,
      -0.015613432042300701,
      -0.106904998421669,
      -0.04952230677008629,
      -0.022244464606046677,
      0.0069516790099442005,
      -0.041219424456357956,
      0.011313044466078281,
      0.051518216729164124,
      -0.03411366418004036,
      0.01858813501894474,
      -0.046068720519542694,
      0.05134729668498039,
      -0.008492804132401943,
      -0.025045882910490036,
      0.10242173075675964,
      -0.0009325817809440196,
      -0.048135045915842056,
      -0.021015778183937073,
      0.04540559649467468,
      -0.056962285190820694,
      -0.0037040216848254204,
      0.07409437745809555,
      0.020741064101457596,
      -0.050666745752096176,
      -0.05771626904606819,
      -0.028194095939397812,
      0.09201429784297943,
      0.01931910216808319,
      0.049907516688108444,
      0.10987643152475357,
      0.033272258937358856,
      0.05305945873260498,
      0.0744360163807869,
      -0.00312274182215333,
      -0.049319975078105927,
      -0.0283694826066494,
      0.01880727894604206,
      -0.1281159520149231,
      0.006646185647696257,
      -0.022846728563308716,
      0.13666340708732605,
      -0.057178840041160583,
      -0.030506832525134087,
      -0.016247544437646866,
      -0.07126188278198242,
      0.0331985242664814,
      -0.05468262359499931,
      0.016714513301849365,
      -0.007177641615271568,
      0.0046555595472455025,
      0.052110593765974045,
      -0.00581758888438344,
      -0.04595068097114563,
      -0.020821450278162956,
      -0.001899800612591207,
      0.03449144959449768,
      -0.014597755856812,
      -0.0332583524286747,
      0.018684184178709984,
      -0.011303543113172054,
      -0.027901124209165573,
      -0.07542014122009277,
      0.04391341283917427,
      0.04957158491015434,
      0.02659730426967144,
      -0.07846569269895554,
      0.047928716987371445,
      0.00239385268650949,
      0.0596732497215271,
      -0.06140153855085373,
      0.019817698746919632,
      0.0380818210542202,
      -0.06194549426436424,
      0.029251685366034508,
      0.005035249050706625,
      0.04094793647527695,
      -0.024853793904185295,
      -0.01297910325229168,
      -0.006500244606286287,
      0.013531658798456192,
      0.01268979161977768,
      -0.05385330319404602,
      0.01207766868174076,
      0.11318539828062057,
      0.0472988560795784,
      0.019604604691267014,
      -0.012920351698994637,
      -0.03587091341614723,
      -0.03782576695084572,
      0.0007531899609602988,
      0.05127895250916481,
      -0.027221819385886192,
      -0.04261624440550804,
      0.04774804785847664,
      0.024333566427230835,
      0.037010565400123596,
      -0.02784726209938526,
      0.03301696851849556,
      -0.028949450701475143,
      0.04295041784644127,
      -0.0199267640709877,
      0.007057195995002985,
      -0.0930461660027504,
      -0.026429884135723114,
      0.027648020535707474,
      0.07609216868877411,
      0.0842881128191948,
      0.02539500594139099,
      -0.004360412247478962,
      -0.05535739287734032,
      -0.04575240612030029,
      -0.0024289011489599943,
      -0.003490361850708723,
      0.013698614202439785,
      -0.0059167020954191685,
      0.030745776370167732,
      0.046743519604206085,
      0.04253056272864342,
      0.011246264912188053,
      -0.016104402020573616,
      -0.007096420973539352,
      0.01842944137752056,
      0.022165572270751,
      0.03692580386996269,
      -0.03184141218662262,
      -0.010136417113244534,
      0.030296191573143005,
      0.01691293716430664,
      0.077845998108387,
      0.08532866090536118,
      0.08753343671560287,
      0.05922159552574158,
      0.009940000250935555,
      0.014743258245289326,
      -0.10541919618844986,
      -0.06680084019899368,
      -0.04412578046321869,
      -0.07054661214351654,
      0.10472266376018524,
      0.052847232669591904,
      0.054660551249980927,
      -0.036775264889001846,
      -0.08793781697750092,
      0.08241891115903854,
      0.05478932335972786,
      0.04064982011914253,
      0.016535602509975433,
      -0.008472174406051636,
      -0.0778745710849762,
      0.05491216853260994,
      0.009183946065604687,
      0.027513982728123665,
      -0.016245141625404358,
      -0.020206419751048088,
      -0.015483995899558067,
      0.035465631633996964,
      0.06350002437829971,
      -0.01659611612558365,
      0.061687298119068146,
      -0.00985997635871172,
      -0.01262994296848774,
      0.026407381519675255,
      -0.029950199648737907,
      -0.05974075198173523,
      0.030698180198669434,
      -0.0025654027704149485,
      0.044311825186014175,
      -0.03591288626194,
      0.022640399634838104,
      0.07061713933944702,
      0.04313774034380913,
      -0.033469248563051224,
      -0.045371007174253464,
      -0.0491601824760437,
      0.02063259296119213,
      0.08858278393745422,
      -0.033310141414403915,
      0.0007107335259206593,
      0.019661786034703255,
      -0.0140348169952631,
      0.009720467031002045,
      0.00026721658650785685,
      0.032050762325525284,
      -0.06393077969551086,
      -0.002116662450134754,
      -0.013788238167762756,
      -0.0396849550306797,
      -0.011976314708590508,
      -0.04425261914730072,
      0.03102526068687439,
      0.027440644800662994,
      -0.0003322687407489866,
      -0.015301578678190708,
      -0.06184004247188568,
      -0.00732618710026145,
      0.014395162463188171,
      -0.09130655229091644,
      0.054533783346414566,
      -0.006396858021616936,
      0.039430126547813416,
      0.020001087337732315,
      0.067688949406147,
      -0.06667479127645493,
      -0.036979760974645615,
      0.008508993312716484,
      0.01033496018499136,
      0.10889365524053574,
      -0.001756607205606997,
      -0.10879487544298172,
      -0.07496951520442963,
      0.038508061319589615,
      -0.03367137536406517,
      0.09676611423492432,
      -0.05576178804039955,
      -0.0017840131185948849,
      0.015468562953174114,
      0.10967166721820831,
      -0.017808180302381516,
      -0.014351713471114635,
      -0.05129507556557655,
      -0.016906004399061203,
      -0.021497534587979317,
      -0.06955298781394958,
      0.04582398757338524,
      -0.010688412934541702,
      -0.027995191514492035,
      0.09158250689506531,
      -0.056471727788448334,
      -0.01980704255402088,
      0.04836750775575638,
      0.08697505295276642,
      0.00622671190649271,
      0.015558525919914246,
      0.07015612721443176,
      -0.036691609770059586,
      0.0239022858440876,
      0.05921083688735962,
      0.04105279967188835,
      -0.04381890222430229,
      0.0011500670807436109,
      -0.039731502532958984,
      -0.019811078906059265,
      -0.017898494377732277,
      -0.014166999608278275,
      0.034750498831272125,
      -0.05626986548304558,
      0.09012161940336227,
      0.01562200952321291,
      -0.0453554131090641,
      0.07637574523687363,
      0.007046632003039122,
      0.022849231958389282,
      -0.04964108020067215,
      0.026102229952812195,
      0.018126368522644043,
      -0.01899033784866333,
      -0.004659685306251049,
      0.018460754305124283,
      0.007837207056581974,
      0.009994364343583584,
      0.11497284471988678,
      -0.019073113799095154,
      -0.012078272178769112,
      -0.025531437247991562,
      -0.025739090517163277,
      -0.009978997521102428,
      0.01975870691239834,
      0.05472711846232414,
      -0.030825678259134293,
      -0.059538207948207855,
      0.01795784942805767,
      0.024868082255125046,
      -0.048971664160490036,
      -0.025766316801309586,
      -0.01681647077202797,
      0.0417245551943779,
      -0.00546678714454174,
      0.019508101046085358,
      0.0440446212887764,
      0.01806509681046009,
      0.03052276000380516,
      -0.0340537466108799,
      0.0018278455827385187,
      0.013028023764491081,
      -0.05822722613811493,
      0.0664069727063179,
      -0.0021958048455417156,
      0.035865239799022675,
      0.062164556235075,
      0.03424195200204849,
      0.021835941821336746,
      0.06286387890577316,
      0.06404554843902588,
      0.050259996205568314,
      -0.03446713462471962,
      0.0545123815536499,
      0.09192480891942978,
      0.09413417428731918,
      0.051726385951042175,
      -0.1658952683210373,
      0.0012953538680449128,
      0.025482194498181343,
      0.04638054966926575,
      -0.03929850086569786,
      0.004760347306728363,
      -0.019786329939961433,
      0.07340050488710403,
      -0.05338506028056145,
      -0.019915131852030754,
      -0.09473772346973419,
      0.04857688397169113,
      0.011389232240617275,
      0.05862126126885414,
      0.024945668876171112,
      -0.012126165442168713,
      -0.017448194324970245,
      0.007804303430020809,
      -0.003590136766433716,
      -0.07673536241054535,
      0.013947213999927044,
      0.022091155871748924,
      0.003818419761955738,
      -0.020475022494792938,
      0.06707045435905457,
      0.010386088863015175,
      0.0009625821257941425,
      0.0151312705129385,
      -0.024095216765999794,
      0.060422785580158234,
      0.009261074475944042,
      -0.07227018475532532,
      -0.007861479185521603,
      0.007805436383932829,
      0.0074953981675207615,
      -0.0066200257278978825,
      -0.029063964262604713,
      -0.07039027661085129,
      0.046554017812013626,
      0.03600410372018814,
      -0.011672019958496094,
      -0.03453032672405243,
      -0.045214250683784485,
      0.07077278941869736,
      -0.028843143954873085,
      -0.028697824105620384,
      -0.0741991177201271,
      0.052179642021656036,
      0.12297922372817993,
      -0.013371177017688751,
      0.009875558316707611,
      0.03569325804710388,
      -0.08273698389530182,
      0.09705953299999237,
      -0.006736618932336569,
      -0.0016746923793107271,
      -0.00217608199454844,
      0.034205660223960876,
      -0.0058038136921823025,
      0.08441329747438431,
      -0.04621507599949837,
      0.02826976403594017,
      -0.03614237904548645,
      -0.019612830132246017,
      -0.02661462128162384,
      0.07085801661014557,
      0.06445740163326263,
      -0.061932846903800964,
      -0.07045340538024902,
      0.033739011734724045,
      -0.06234435364603996,
      0.01709468849003315,
      0.03530215099453926,
      0.023668043315410614,
      -0.042016129940748215,
      0.011271914467215538,
      -0.06533584743738174,
      -0.05688643455505371,
      0.01458889339119196,
      0.021858206018805504,
      0.00968308188021183,
      -0.09233739227056503,
      0.012309994548559189,
      -0.006383264437317848,
      0.031125733628869057,
      -0.02427961863577366,
      -0.028375422582030296,
      0.024363037198781967,
      -0.012201775796711445,
      -0.04789552092552185,
      -0.0335841067135334,
      -0.025795241817831993,
      -0.006319125648587942,
      0.016570085659623146,
      0.0946030393242836,
      0.02456328459084034,
      0.008459384553134441,
      -0.017210382968187332,
      0.05093248561024666,
      -0.034119151532649994,
      -0.1096663549542427,
      -0.05194501578807831,
      0.01868947222828865,
      0.017992721870541573,
      -0.03987229987978935,
      0.06526008993387222,
      0.01932469569146633,
      -0.055013544857501984,
      0.05204841122031212,
      0.09401995688676834,
      -0.01735657826066017,
      -0.03973030298948288,
      0.010209490545094013,
      -0.023818695917725563,
      -0.03577706590294838,
      -0.016758745536208153,
      0.06060267239809036,
      0.045394591987133026,
      -0.04174234718084335,
      0.006273017730563879,
      0.008036782033741474,
      -0.013943330384790897,
      -0.04304603487253189,
      0.06735748797655106,
      -0.014751987531781197,
      -0.020064320415258408,
      -0.02980419434607029,
      0.05373748019337654,
      -0.0601160004734993,
      0.04766589775681496,
      0.10347840189933777,
      -0.04749644920229912,
      -0.009090096689760685,
      0.03167475387454033,
      0.021046752110123634,
      0.012293918989598751,
      0.10871410369873047,
      -0.01998194493353367,
      -0.02631603740155697,
      0.012142689898610115,
      -0.05601468309760094,
      -0.007911174558103085,
      0.0680442526936531,
      -0.012961662374436855,
      -0.02689332142472267,
      -0.016899829730391502,
      0.010755750350654125,
      -0.03757994621992111,
      0.05289943143725395,
      0.016482550650835037,
      0.033000100404024124,
      0.06266267597675323,
      0.006293079815804958,
      -0.08192308992147446,
      -0.057456597685813904,
      0.08972775936126709,
      0.04759421944618225,
      -0.07656340301036835,
      -0.035874929279088974,
      -0.02305152453482151,
      -0.04234914481639862,
      -0.029274186119437218,
      0.05145939067006111
    ],
    [
      0.07909266650676727,
      -0.016836728900671005,
      -0.10879609733819962,
      -0.04575800150632858,
      -0.0009226624388247728,
      0.08509614318609238,
      -0.05112661421298981,
      -0.06434778869152069,
      -0.05460643768310547,
      -0.051136747002601624,
      0.000697459967341274,
      0.023324284702539444,
      -0.013243853114545345,
      -0.0500585101544857,
      -0.0281198900192976,
      -0.03138522058725357,
      -0.05997474119067192,
      -0.0754968598484993,
      0.06187456101179123,
      -0.04511816427111626,
      0.03447163477540016,
      0.05133963003754616,
      0.0346398688852787,
      0.0012197195319458842,
      -0.02381107024848461,
      -0.023851897567510605,
      -0.010762213729321957,
      0.005921478848904371,
      -0.01891862414777279,
      -0.029352305456995964,
      -0.12483382970094681,
      -0.05179717764258385,
      -0.0507909432053566,
      -0.007538577076047659,
      -0.045874785631895065,
      -0.06376008689403534,
      0.04337841272354126,
      -0.02933565154671669,
      -0.07668434828519821,
      -0.028207380324602127,
      -0.08794910460710526,
      -0.004900557920336723,
      -0.05614594742655754,
      -0.00969232339411974,
      -0.06276262551546097,
      -0.014554007910192013,
      0.0299343541264534,
      0.0011457146611064672,
      0.060310643166303635,
      0.009744962677359581,
      0.03858340159058571,
      0.03298148140311241,
      0.0785052478313446,
      0.021410644054412842,
      -0.05138527229428291,
      -0.06261801719665527,
      -0.04148607701063156,
      -0.10435830801725388,
      0.02803700603544712,
      -0.034822165966033936,
      0.06817635893821716,
      -0.05984215810894966,
      -0.055585842579603195,
      0.037284307181835175,
      0.09302212297916412,
      -0.01773163676261902,
      0.06467662006616592,
      0.000947702385019511,
      0.016782153397798538,
      0.019057173281908035,
      -0.002129933563992381,
      0.02870432287454605,
      0.012105082161724567,
      0.016748948022723198,
      0.0014672375982627273,
      -0.016510825604200363,
      -0.001299736904911697,
      -0.016796477138996124,
      0.036688245832920074,
      0.013207263313233852,
      -0.047292131930589676,
      0.06404661387205124,
      0.021112091839313507,
      -0.022566305473446846,
      0.045725807547569275,
      0.016198813915252686,
      0.09647103399038315,
      -0.020999977365136147,
      -0.025286003947257996,
      -0.0030810856260359287,
      -0.011619584634900093,
      0.008799695409834385,
      -0.024458562955260277,
      0.005880081094801426,
      -0.08311641961336136,
      0.06114008277654648,
      -0.03267241269350052,
      -0.03674667701125145,
      0.07999735325574875,
      -0.017226362600922585,
      0.04576875641942024,
      -0.0809108167886734,
      -0.005065922159701586,
      0.030981699004769325,
      0.001825776882469654,
      -0.002001796616241336,
      -0.06419417262077332,
      0.008653872646391392,
      0.022134842351078987,
      0.054785698652267456,
      -0.06158393621444702,
      0.005187089089304209,
      0.07597982883453369,
      -0.028198476880788803,
      -0.0033299028873443604,
      -0.008530926890671253,
      0.01974756084382534,
      0.06087677925825119,
      -0.06446871906518936,
      0.019078653305768967,
      -0.07540802657604218,
      0.032814912497997284,
      0.005604838486760855,
      0.06150349974632263,
      0.0016393951373174787,
      -0.013084248639643192,
      -0.018984613940119743,
      0.0066008130088448524,
      0.02208656817674637,
      0.007073913235217333,
      -0.00452645868062973,
      0.0679638683795929,
      0.010440238751471043,
      0.010345004498958588,
      -0.008334178477525711,
      0.010336939245462418,
      0.0755804032087326,
      -0.018288029357790947,
      -0.01968866027891636,
      -0.004283227026462555,
      -0.009331136010587215,
      -0.014499603770673275,
      0.01739097386598587,
      -0.01412325631827116,
      -0.03795143961906433,
      -0.06137339770793915,
      0.03520403429865837,
      0.07951982319355011,
      -0.010254241526126862,
      -0.005441952031105757,
      -0.05986819788813591,
      -0.04446405917406082,
      0.02514611929655075,
      0.003326656762510538,
      -0.05346537381410599,
      -2.8766689865733497e-05,
      -0.03218262270092964,
      0.051580529659986496,
      -0.034445784986019135,
      -0.034677378833293915,
      -0.057536907494068146,
      0.04724421352148056,
      0.04492621496319771,
      -0.037660107016563416,
      0.03993278741836548,
      0.028774332255125046,
      -0.021172519773244858,
      0.04905504360795021,
      -0.012508145533502102,
      -0.035360559821128845,
      -0.042066823691129684,
      0.010838102549314499,
      -0.0021669750567525625,
      0.06009828299283981,
      0.008423089049756527,
      0.013302580453455448,
      0.039593376219272614,
      -0.0638415589928627,
      -0.09645841270685196,
      -0.0027992187533527613,
      -0.0059898290783166885,
      0.014059562236070633,
      -0.03430140018463135,
      -0.07075142115354538,
      0.009610794484615326,
      -0.00362441991455853,
      0.03873490169644356,
      -0.005482008680701256,
      -0.06615796685218811,
      0.028653103858232498,
      3.7840556615265086e-05,
      -0.014025813899934292,
      0.013578399084508419,
      0.07942381501197815,
      0.03816971555352211,
      0.058781787753105164,
      0.018400775268673897,
      0.025467876344919205,
      -0.02655300870537758,
      -0.0320996418595314,
      -0.01466782670468092,
      -0.0014531371416524053,
      -0.019474131986498833,
      -0.08061061799526215,
      0.02420078031718731,
      -0.02645915001630783,
      0.03662882000207901,
      0.07560151070356369,
      0.003935811575502157,
      -0.024547751992940903,
      0.02008960209786892,
      -0.08131114393472672,
      0.06198054552078247,
      0.07627509534358978,
      0.006561690475791693,
      -0.011832395568490028,
      0.07024165987968445,
      0.009119952097535133,
      0.0019724054727703333,
      -0.018534479662775993,
      0.03126106411218643,
      0.0043530031107366085,
      -0.16335316002368927,
      0.023004867136478424,
      0.013603891246020794,
      0.08658470213413239,
      0.05178888887166977,
      0.017427388578653336,
      -0.0625317320227623,
      0.037670232355594635,
      -0.07617367804050446,
      0.018821515142917633,
      -0.08534064888954163,
      0.05245020240545273,
      -0.044388264417648315,
      -0.012597782537341118,
      -0.03670163080096245,
      -0.030235329642891884,
      -0.047241464257240295,
      -0.01957564614713192,
      -0.017839116975665092,
      0.052251242101192474,
      0.04659201577305794,
      -0.0040134540759027,
      -0.05489659309387207,
      0.015590299852192402,
      0.044704653322696686,
      -0.0418604277074337,
      -0.05467807501554489,
      -0.009845491498708725,
      -0.061096493154764175,
      0.05143241584300995,
      -0.000482165691209957,
      0.009693552739918232,
      -0.013148020021617413,
      -0.017719466239213943,
      0.026526637375354767,
      -0.0056243762373924255,
      -0.03083806484937668,
      0.11401411145925522,
      -0.13650622963905334,
      0.080134816467762,
      0.007366412319242954,
      0.028045259416103363,
      -0.01130544114857912,
      0.014093347825109959,
      0.0068335081450641155,
      0.05189472809433937,
      0.021944014355540276,
      0.046339455991983414,
      0.06683535128831863,
      0.06579127162694931,
      -0.10593874752521515,
      0.03069622628390789,
      -0.0005647482466883957,
      0.008350832387804985,
      -0.0211330559104681,
      0.010640054009854794,
      0.024544691666960716,
      -0.08869936317205429,
      -0.01920785941183567,
      -0.03577113151550293,
      0.04576679319143295,
      0.023093946278095245,
      0.06590066850185394,
      0.06222313269972801,
      -0.1328025758266449,
      0.010106663219630718,
      0.06762656569480896,
      -0.08009760826826096,
      -0.019541127607226372,
      -0.01832016184926033,
      0.0466146357357502,
      -0.00808017048984766,
      -0.038463886827230453,
      0.015788089483976364,
      -0.03467147797346115,
      -0.06083230301737785,
      -0.043512504547834396,
      0.06809544563293457,
      0.006805844604969025,
      0.010849175974726677,
      0.0012657298939302564,
      -0.0025598695501685143,
      0.026032332330942154,
      0.016890820115804672,
      0.015944253653287888,
      0.019095275551080704,
      -0.01013685017824173,
      -0.03515341877937317,
      0.02685345709323883,
      -0.05542454496026039,
      -0.08753194659948349,
      -0.00015884450112935156,
      -0.11461089551448822,
      0.04368088021874428,
      -0.08500182628631592,
      -0.04523235186934471,
      -0.025736041367053986,
      -0.09973786771297455,
      0.04403688758611679,
      -0.10605251044034958,
      -0.0069753616116940975,
      0.02526351623237133,
      -0.0363236628472805,
      -0.03339624032378197,
      -0.0508839450776577,
      -0.02904323674738407,
      0.007618963252753019,
      -0.03816249221563339,
      0.03654405474662781,
      -0.06580716371536255,
      0.012683164328336716,
      0.0374596044421196,
      -0.037077516317367554,
      -0.03172077238559723,
      0.06542036682367325,
      0.11894237995147705,
      -0.0592043437063694,
      0.04960165172815323,
      -0.061738695949316025,
      -0.06479261815547943,
      -0.007756542414426804,
      0.01572534255683422,
      -0.03665770962834358,
      0.07359186559915543,
      0.019055353477597237,
      0.03691687434911728,
      0.014970362186431885,
      0.014366304501891136,
      0.040262799710035324,
      0.07529435306787491,
      0.008669923059642315,
      0.04372759908437729,
      0.09307736903429031,
      0.02168099582195282,
      0.03434492275118828,
      0.07670723646879196,
      -0.045311205089092255,
      -0.026363495737314224,
      0.036724332720041275,
      0.04830462858080864,
      0.008439356461167336,
      0.009325004182755947,
      -0.05072908475995064,
      -0.06175316870212555,
      0.005068239755928516,
      -0.008001773618161678,
      -0.08949193358421326,
      -0.01692398451268673,
      0.024264011532068253,
      0.011649354360997677,
      0.0409977026283741,
      0.050519198179244995,
      0.09536489844322205,
      -0.10814538598060608,
      -0.04799449071288109,
      0.015878090634942055,
      0.006434218492358923,
      -0.026553813368082047,
      -0.01272173598408699,
      0.013029993511736393,
      -0.01678863726556301,
      -0.036682531237602234,
      0.03140164911746979,
      -0.10233421623706818,
      0.07332561165094376,
      0.04039544239640236,
      0.03671036288142204,
      -0.06867937743663788,
      0.014421059750020504,
      -0.048991791903972626,
      -0.058174535632133484,
      0.07013675570487976,
      -0.05335512012243271,
      -0.06318236887454987,
      0.053146641701459885,
      -0.009702876210212708,
      0.05552813410758972,
      -0.01075532753020525,
      0.0026910642627626657,
      0.00881384126842022,
      0.022419828921556473,
      -0.07171810418367386,
      -0.050591710954904556,
      -0.021092547103762627,
      0.019947249442338943,
      -0.02588561736047268,
      0.045406240969896317,
      0.0019204956479370594,
      0.006362822838127613,
      0.09989005327224731,
      -0.028771400451660156,
      -0.022689592093229294,
      0.00869153905659914,
      0.010206650011241436,
      -0.024583827704191208,
      -0.04334449768066406,
      0.10094087570905685,
      0.03385121747851372,
      0.012124818749725819,
      -0.07132721692323685,
      0.017859168350696564,
      -0.07560620456933975,
      -0.04822641611099243,
      -0.013700012117624283,
      -0.08724036812782288,
      0.04794424772262573,
      -0.0011961961863562465,
      0.15167281031608582,
      0.04796094074845314,
      0.05430285260081291,
      -0.01971600577235222,
      -0.034087881445884705,
      0.03476632013916969,
      -0.005791505798697472,
      -0.03377760946750641,
      0.06897196918725967,
      -0.026364117860794067,
      -0.05215103551745415,
      -0.06612882763147354,
      -0.06360696256160736,
      0.006631111726164818,
      0.026491781696677208,
      -0.052526697516441345,
      0.004209782928228378,
      -0.03208225220441818,
      -0.054855599999427795,
      0.0006966225919313729,
      0.008472480811178684,
      0.01293841190636158,
      -0.02005779556930065,
      -0.0804004818201065,
      0.005666459910571575,
      0.006272553000599146,
      0.033011894673109055,
      0.03175325319170952,
      -0.04125125706195831,
      0.02327948994934559,
      0.043955426663160324,
      0.027788503095507622,
      0.055210888385772705,
      -0.03367675840854645,
      -0.024428686127066612,
      -0.027763593941926956,
      -0.03452569618821144,
      0.052812959998846054,
      0.02682485617697239,
      0.05827562138438225,
      -0.004840571898967028,
      -0.05868609622120857,
      -0.09622526913881302,
      -0.03045695647597313,
      0.043550558388233185,
      -0.0007124298717826605,
      -0.013071924448013306,
      -0.04490404203534126,
      0.005115385167300701,
      0.018403926864266396,
      0.03948446735739708,
      0.023570680990815163,
      -0.03794528543949127,
      -0.07123848050832748,
      0.012856430374085903,
      0.005823757033795118,
      0.07630812376737595,
      0.03616051375865936,
      0.006859038025140762,
      -0.08820594847202301,
      0.09845443814992905,
      0.004515410400927067,
      0.033781129866838455,
      0.03122977912425995,
      0.0033779507502913475,
      0.00010265227319905534,
      0.03563479706645012,
      0.018347660079598427,
      -0.02872154861688614,
      0.017755232751369476,
      0.024468135088682175,
      0.02752247266471386,
      0.04139663651585579,
      0.03396717086434364
    ],
    [
      0.034908536821603775,
      -0.0426412932574749,
      -0.10956796258687973,
      -0.013601732440292835,
      -0.06889953464269638,
      -0.057849448174238205,
      -0.03999415412545204,
      -0.04938289150595665,
      0.040685899555683136,
      -0.024547846987843513,
      0.06477618962526321,
      0.010890192352235317,
      -0.019376885145902634,
      0.021859316155314445,
      0.01654493622481823,
      -0.004820332862436771,
      -0.005464417859911919,
      0.004341602325439453,
      0.023805607110261917,
      0.03374262526631355,
      -0.026574131101369858,
      0.019848722964525223,
      0.004756244830787182,
      -0.04673809930682182,
      0.008189341984689236,
      -0.011697117239236832,
      0.04536885395646095,
      0.13006584346294403,
      0.07783400267362595,
      0.115180604159832,
      0.017151428386569023,
      -0.03083793818950653,
      0.006761558819562197,
      -0.06645192205905914,
      -0.031929999589920044,
      0.03308483585715294,
      0.047069694846868515,
      0.022105643525719643,
      -0.016938358545303345,
      0.000894716999027878,
      0.07597525417804718,
      0.07741054147481918,
      -0.014424588531255722,
      0.06790608167648315,
      0.007219599559903145,
      -0.003298689378425479,
      0.013461053371429443,
      0.0021485642064362764,
      -0.03460093215107918,
      0.05141323804855347,
      -0.03339671716094017,
      0.06436372548341751,
      0.11035536229610443,
      0.026858648285269737,
      -0.08292032033205032,
      0.009329522028565407,
      -0.03015303425490856,
      -0.14408814907073975,
      0.0006757863448001444,
      -0.08712281286716461,
      0.0883721113204956,
      -0.015737449750304222,
      -0.023865962401032448,
      0.017572106793522835,
      -0.0046325265429914,
      0.012256148271262646,
      0.08666975796222687,
      0.005051584914326668,
      0.019403962418437004,
      0.1025010347366333,
      0.10895538330078125,
      0.003982890397310257,
      -0.07176181674003601,
      0.05296577140688896,
      0.030197223648428917,
      -0.10498757660388947,
      0.028485246002674103,
      0.017580606043338776,
      0.04523751884698868,
      0.04603664577007294,
      -0.015035380609333515,
      -0.021830085664987564,
      -0.030063457787036896,
      -0.018663041293621063,
      0.04936970770359039,
      0.06042182072997093,
      -0.04034321755170822,
      0.03382277861237526,
      0.06327243894338608,
      0.09337726980447769,
      -0.060033880174160004,
      -0.057988036423921585,
      -0.006795526947826147,
      0.010451811365783215,
      0.04168884828686714,
      0.013847564347088337,
      0.006347064394503832,
      -0.019638538360595703,
      0.006185580510646105,
      0.05810844153165817,
      0.049857206642627716,
      0.014346947893500328,
      -0.00163569999858737,
      -0.027008136734366417,
      -0.019086042419075966,
      -0.019445933401584625,
      -0.005090426653623581,
      0.02876126766204834,
      0.08333590626716614,
      -0.057269223034381866,
      0.03998030349612236,
      -0.02391214855015278,
      -0.1164056733250618,
      -0.05911276489496231,
      -0.045621056109666824,
      0.007365918718278408,
      -0.006226252764463425,
      0.04270870238542557,
      -0.0887809693813324,
      -0.01775195449590683,
      -0.03381190821528435,
      0.03748860955238342,
      -0.02150639146566391,
      0.0022908300161361694,
      -0.01723833754658699,
      -0.021873358637094498,
      -0.08417349308729172,
      -0.006749775726348162,
      -0.005104513838887215,
      -0.02305467240512371,
      -0.03423924744129181,
      0.016198690980672836,
      -0.01973220705986023,
      -0.05114591866731644,
      0.00578420702368021,
      0.04372350126504898,
      0.07241983711719513,
      -0.0060639227740466595,
      0.008987472392618656,
      -0.10380825400352478,
      -0.007406861521303654,
      0.03755933418869972,
      -0.07211719453334808,
      -0.07274188101291656,
      0.006802642252296209,
      -0.006672927178442478,
      0.05663653090596199,
      0.035774022340774536,
      0.03904533386230469,
      -0.054164282977581024,
      -0.04856789484620094,
      -0.03616935387253761,
      0.003598287934437394,
      0.08088614791631699,
      -0.05302204564213753,
      -0.001040659612044692,
      0.08485229313373566,
      0.07103350013494492,
      0.06267210096120834,
      -0.03822322189807892,
      -0.02875165455043316,
      -0.03204099088907242,
      -0.03830243647098541,
      0.0030927658081054688,
      -0.05658790096640587,
      0.03681202977895737,
      0.016719287261366844,
      0.04917009174823761,
      0.07760331779718399,
      0.04932732135057449,
      0.0059786527417600155,
      -0.03841997683048248,
      -0.01876595988869667,
      -0.09239661693572998,
      0.008301977068185806,
      0.0486823134124279,
      -0.03314594924449921,
      -0.01479960884898901,
      -0.06819099187850952,
      -0.0016080827917903662,
      -0.09802154451608658,
      0.02188755013048649,
      0.05347901210188866,
      -0.03173307701945305,
      0.06903953105211258,
      0.02931799553334713,
      -0.008959575556218624,
      -0.0031598887871950865,
      -0.026050329208374023,
      0.008458772674202919,
      0.08909612894058228,
      -0.00669267401099205,
      0.026459254324436188,
      -0.0832347497344017,
      0.017819523811340332,
      0.0167742557823658,
      -0.01828385889530182,
      0.015399644151329994,
      0.022932279855012894,
      0.023446833714842796,
      0.013709396123886108,
      0.056287359446287155,
      -0.0629509836435318,
      -0.028163544833660126,
      0.06211108714342117,
      0.05407857149839401,
      -0.01256200484931469,
      0.006003431044518948,
      0.014417211525142193,
      -0.0007599719101563096,
      0.04471874609589577,
      -0.0010976092889904976,
      -0.018833694979548454,
      -0.0013540887739509344,
      -0.027227304875850677,
      0.03407697379589081,
      0.0666578933596611,
      0.06706927716732025,
      -0.002674751915037632,
      -0.11611570417881012,
      0.023759644478559494,
      -0.04475811868906021,
      -0.020671896636486053,
      0.061575863510370255,
      0.007412436883896589,
      0.0164848193526268,
      0.05310055613517761,
      0.05093231424689293,
      0.05632802098989487,
      -0.02427143231034279,
      0.00808743480592966,
      0.01077184546738863,
      -0.018081694841384888,
      0.026410600170493126,
      -0.007363914046436548,
      0.006356172263622284,
      0.017031384631991386,
      0.014859970659017563,
      0.05796176195144653,
      0.03874274343252182,
      -0.040274325758218765,
      0.0006844416493549943,
      0.0235694982111454,
      0.03381967917084694,
      0.0063847824931144714,
      0.07412935793399811,
      0.009143652394413948,
      -0.00447528762742877,
      -0.05116672068834305,
      0.033694591373205185,
      0.05938063561916351,
      0.052485059946775436,
      0.004395819269120693,
      0.002161318203434348,
      -0.02155417576432228,
      -0.00032669928623363376,
      -0.01058126799762249,
      0.051612209528684616,
      -0.036764323711395264,
      -0.0764109194278717,
      -0.017315346747636795,
      0.060359515249729156,
      0.06253503262996674,
      -0.03134804964065552,
      0.002749189268797636,
      0.002317563397809863,
      -0.0038113677874207497,
      0.08360273391008377,
      -0.09551338106393814,
      8.88401482370682e-05,
      0.005828165914863348,
      0.02662375196814537,
      -0.029857559129595757,
      -0.07845412939786911,
      0.032532066106796265,
      -0.03401773422956467,
      0.04956121742725372,
      -0.07188640534877777,
      -0.053785424679517746,
      0.0020492994226515293,
      -0.04610953480005264,
      -0.02859226055443287,
      -0.052196308970451355,
      0.04208926111459732,
      0.04941185563802719,
      0.044344883412122726,
      -0.038006991147994995,
      0.0013392732944339514,
      0.022935686632990837,
      -0.018700972199440002,
      0.0245524812489748,
      -0.004593948367983103,
      0.018210923299193382,
      0.03673521429300308,
      0.009149138815701008,
      0.03464365005493164,
      -0.03884715586900711,
      -0.10290142893791199,
      -0.027964433655142784,
      -0.00830142479389906,
      0.015763206407427788,
      -0.014361650682985783,
      -0.02124939300119877,
      0.04341713711619377,
      -0.028545062988996506,
      0.024100732058286667,
      -0.011091860011219978,
      -0.05106277018785477,
      -0.009942676872015,
      -0.034330472350120544,
      0.030067119747400284,
      -0.10435795038938522,
      -0.036983780562877655,
      -0.05141649395227432,
      -0.02314920723438263,
      0.051087167114019394,
      -0.04436718299984932,
      -0.06120620667934418,
      0.0016469360562041402,
      -0.021706856787204742,
      0.04061074182391167,
      -0.04785136505961418,
      -0.018265949562191963,
      0.012957996688783169,
      -0.049027856439352036,
      0.0882413238286972,
      -0.03895841911435127,
      -0.02089873142540455,
      0.08482105284929276,
      0.009613854810595512,
      0.03717788681387901,
      0.06290556490421295,
      -0.09328795224428177,
      -0.0318368598818779,
      -0.015281237661838531,
      0.08066265285015106,
      0.036425743252038956,
      0.0012745484709739685,
      -0.011076289229094982,
      -0.01004720013588667,
      0.059041980654001236,
      -0.0031089461408555508,
      0.03195570409297943,
      0.04054887965321541,
      -0.006045512855052948,
      0.005521734710782766,
      0.05887471139431,
      -0.0793815329670906,
      0.014946316368877888,
      0.03158893808722496,
      0.008953171782195568,
      0.0163273848593235,
      0.0021431988570839167,
      -9.168517135549337e-05,
      -0.015631889924407005,
      -0.009641916491091251,
      -0.0030741391237825155,
      0.027504121884703636,
      -0.019177762791514397,
      -0.00237078033387661,
      0.02961171418428421,
      0.06359574943780899,
      -0.058895766735076904,
      -0.049233775585889816,
      0.0019316051620990038,
      0.012488401494920254,
      -0.0631103664636612,
      0.012924321927130222,
      -0.01218685507774353,
      -0.03280768543481827,
      0.016787711530923843,
      0.055922120809555054,
      0.09059065580368042,
      -0.07076296955347061,
      -0.04176856204867363,
      0.04781626909971237,
      -0.018230298534035683,
      0.02178402990102768,
      -0.044641684740781784,
      0.017057189717888832,
      0.026011276990175247,
      0.03501370549201965,
      -0.05783451348543167,
      0.014617999084293842,
      -0.01781376637518406,
      -0.05815446749329567,
      0.015966329723596573,
      0.02646481618285179,
      0.052253495901823044,
      -0.022732768207788467,
      0.0033868637401610613,
      0.06729502230882645,
      -0.0056166574358940125,
      -0.002739576855674386,
      -0.0488094724714756,
      0.03837694600224495,
      0.055543120950460434,
      -0.06585349142551422,
      0.02634461037814617,
      -0.007649365812540054,
      -0.047482796013355255,
      0.0491490364074707,
      0.018621006980538368,
      0.07987448573112488,
      -0.03875722736120224,
      -0.037952955812215805,
      -0.007028477732092142,
      0.02663233131170273,
      -0.003561344463378191,
      0.0076387072913348675,
      0.07156339287757874,
      0.04201750457286835,
      -0.02674749866127968,
      -0.05032148212194443,
      0.08941049873828888,
      0.07135657221078873,
      0.01822827011346817,
      -0.008626117371022701,
      0.0120956776663661,
      -0.02509577013552189,
      0.007212492171674967,
      0.032612770795822144,
      0.031957779079675674,
      0.06520464271306992,
      -0.03616972267627716,
      0.011465879157185555,
      0.043038610368967056,
      0.08377040922641754,
      -0.031980253756046295,
      0.05871809273958206,
      0.029769234359264374,
      -0.00017060368554666638,
      0.026165671646595,
      0.014590222388505936,
      -0.04149193316698074,
      0.06395774334669113,
      -0.053002189844846725,
      -0.01695306785404682,
      -0.08055467903614044,
      0.000709293526597321,
      0.041184306144714355,
      -0.01218170765787363,
      0.00883280485868454,
      0.03353342041373253,
      -0.011941547505557537,
      -0.014082365669310093,
      -0.021479876711964607,
      -0.03843038156628609,
      -0.022018563002347946,
      -0.02438807114958763,
      -0.00592617504298687,
      0.06426962465047836,
      0.07717913389205933,
      -0.0259306188672781,
      0.0007605910068377852,
      -0.054415635764598846,
      0.006198647897690535,
      -0.05836006626486778,
      -0.01531998347491026,
      0.10899146646261215,
      0.0650084838271141,
      -0.011739978566765785,
      0.06609693914651871,
      -0.01133190281689167,
      0.020211994647979736,
      0.011687260121107101,
      0.037445079535245895,
      0.027317579835653305,
      0.005771972704678774,
      0.017799189314246178,
      0.0335027240216732,
      -0.02903664857149124,
      -0.10007081925868988,
      -0.006233116611838341,
      -0.05537290498614311,
      0.05973362550139427,
      0.04181930050253868,
      -0.010382545180618763,
      0.009367997758090496,
      -0.08220893889665604,
      -0.09117703139781952,
      0.03986493870615959,
      -0.041419513523578644,
      0.0009562468621879816,
      0.028775382786989212,
      -0.041679684072732925,
      0.0576571524143219,
      0.01522680465131998,
      -0.04727410897612572,
      -0.05831997096538544,
      0.04145592451095581,
      0.00044596183579415083,
      0.059818219393491745,
      0.06316403299570084,
      -0.01083600614219904,
      -0.02764013037085533,
      -0.08931789547204971,
      -0.036054883152246475,
      0.03837501257658005,
      0.024720406159758568,
      0.039828065782785416,
      -0.03137769550085068,
      0.07538024336099625
    ],
    [
      0.006787456106394529,
      0.009727731347084045,
      -0.045872945338487625,
      -0.01698223687708378,
      -0.04467885196208954,
      0.02902839705348015,
      0.022217627614736557,
      -0.012331990525126457,
      0.0808890089392662,
      -0.03990239277482033,
      -0.022086787968873978,
      0.007306856568902731,
      0.007301178760826588,
      -0.07031625509262085,
      0.037192728370428085,
      0.056601494550704956,
      -0.03859294578433037,
      -0.03962583839893341,
      0.0005114238010719419,
      0.05959656089544296,
      -0.020147696137428284,
      0.05950558930635452,
      0.0026274388656020164,
      0.045975569635629654,
      0.021406015381217003,
      -0.05181511119008064,
      -0.03291663900017738,
      0.07303160429000854,
      0.02536494843661785,
      -0.03195961192250252,
      0.033611685037612915,
      -0.01617014966905117,
      0.10260125994682312,
      -0.0025068132672458887,
      0.05773700028657913,
      0.031207101419568062,
      0.011713939718902111,
      -0.0032765467185527086,
      -0.030980385839939117,
      -0.05330736190080643,
      -0.018824448809027672,
      0.0009363100398331881,
      -0.0419316440820694,
      0.03506917878985405,
      -0.011717690154910088,
      0.03422471508383751,
      0.010510253719985485,
      0.013026152737438679,
      0.05600748583674431,
      -0.00627929950132966,
      -0.01988162100315094,
      -0.06732268631458282,
      0.025761637836694717,
      0.09275421500205994,
      -0.06218279153108597,
      0.059201739728450775,
      -0.050639938563108444,
      0.03674338385462761,
      -0.07520908862352371,
      0.013381184078752995,
      0.03691370040178299,
      -0.02240147441625595,
      0.05535704270005226,
      0.020265748724341393,
      -0.04561717063188553,
      -0.00454271724447608,
      -0.02048513852059841,
      0.038069628179073334,
      -0.08163236081600189,
      -0.07107549905776978,
      -0.007184192072600126,
      0.06281185895204544,
      -0.020225785672664642,
      -0.09256615489721298,
      0.06841662526130676,
      0.04024969041347504,
      0.006027486175298691,
      -0.017583856359124184,
      0.0049676732160151005,
      0.020479368045926094,
      0.0005406744894571602,
      0.032597802579402924,
      -0.06910880655050278,
      -0.08717932552099228,
      0.07165408134460449,
      -0.013574979268014431,
      -0.04215839132666588,
      0.01941664330661297,
      0.033513378351926804,
      -0.009220072068274021,
      -0.04131603240966797,
      0.016682451590895653,
      0.014143715612590313,
      -0.016644874587655067,
      -0.017092425376176834,
      -0.014333090744912624,
      0.030215924605727196,
      -0.007942351512610912,
      0.055205244570970535,
      0.0276070237159729,
      -0.035287708044052124,
      -0.022635236382484436,
      0.050345730036497116,
      -0.03509426489472389,
      0.012623160146176815,
      -0.025539878755807877,
      0.03427116945385933,
      -0.08725831657648087,
      0.06514590233564377,
      -0.02034830115735531,
      -0.05504850298166275,
      -0.02009289152920246,
      0.005771869793534279,
      -0.02027970179915428,
      0.02839687280356884,
      0.04782645404338837,
      -0.03296099230647087,
      -0.024967335164546967,
      0.021488094702363014,
      -0.028548555448651314,
      -0.02878362499177456,
      0.02410118468105793,
      -0.06324326246976852,
      -0.047995924949645996,
      -0.047121159732341766,
      -0.02063535898923874,
      -0.038474854081869125,
      0.03552769497036934,
      0.03719101473689079,
      0.037385065108537674,
      -0.032053571194410324,
      0.06471217423677444,
      0.025462377816438675,
      -0.04541955888271332,
      -0.009625326842069626,
      0.01999153010547161,
      0.07614981383085251,
      0.027139252051711082,
      -0.08532625436782837,
      -0.02524797059595585,
      -0.06626970320940018,
      -0.023980464786291122,
      -0.05287710204720497,
      -0.06888424605131149,
      -0.07349580526351929,
      -0.043966151773929596,
      0.04721464216709137,
      0.0015407927567139268,
      0.023215245455503464,
      0.03783426061272621,
      -0.0694948211312294,
      -0.06730233132839203,
      0.16530528664588928,
      0.04651236906647682,
      -0.005565212573856115,
      0.03953414782881737,
      0.00026544820866547525,
      0.06554841995239258,
      -0.07143130898475647,
      -0.0025366998743265867,
      -0.06470903009176254,
      -0.01397085003554821,
      0.010596182197332382,
      -0.0305025652050972,
      0.016885722056031227,
      -0.03687410056591034,
      0.012787711806595325,
      -0.0329507440328598,
      -0.021231241524219513,
      -0.04757701978087425,
      -0.0694999173283577,
      -0.01259907428175211,
      0.06253460794687271,
      0.03400423750281334,
      0.0960284024477005,
      0.07639830559492111,
      0.021414805203676224,
      0.028107261285185814,
      -0.015674354508519173,
      0.00477088475599885,
      -0.003218872006982565,
      -0.046710435301065445,
      0.040881019085645676,
      -0.040761228650808334,
      -0.02552039548754692,
      -0.03576110675930977,
      0.03454551845788956,
      -0.08208628743886948,
      -0.06260533630847931,
      -0.0026323955971747637,
      -0.02318023145198822,
      -0.026744192466139793,
      0.09403897076845169,
      -0.08801250159740448,
      -0.013015743345022202,
      0.019357437267899513,
      0.05678453668951988,
      -0.10682795941829681,
      0.04217946529388428,
      0.0837789922952652,
      -0.038044702261686325,
      -0.030246540904045105,
      -0.06445283442735672,
      -0.007195599377155304,
      0.056365568190813065,
      0.029283875599503517,
      -0.03420334309339523,
      0.002714534057304263,
      0.019358810037374496,
      -0.021457739174365997,
      0.03556322306394577,
      0.06512897461652756,
      0.04046770557761192,
      -0.03205626830458641,
      0.006392437033355236,
      -0.042578235268592834,
      0.003247990505769849,
      -0.02383323945105076,
      0.011296832002699375,
      0.012895030900835991,
      0.04740301892161369,
      0.01654311828315258,
      -0.0005319659248925745,
      0.0038272948004305363,
      0.054694753140211105,
      0.009734401479363441,
      0.01571536622941494,
      0.022618398070335388,
      0.0025689308531582355,
      0.020591197535395622,
      0.02122483216226101,
      0.08952907472848892,
      -0.02125314623117447,
      -0.05532308295369148,
      -0.012045197188854218,
      -0.0456596203148365,
      0.016015850007534027,
      -0.13584187626838684,
      -0.05676451697945595,
      0.0404922179877758,
      0.03848839923739433,
      -0.06468676775693893,
      -0.05057443678379059,
      -0.023677559569478035,
      -0.049439094960689545,
      0.04452083632349968,
      0.06267935782670975,
      -0.00973418727517128,
      -0.004251496866345406,
      0.06700026243925095,
      -0.012821906246244907,
      0.04271278902888298,
      0.03520646691322327,
      -0.002078409306704998,
      -0.03240421041846275,
      -0.01520211435854435,
      0.05223291367292404,
      -0.04474418982863426,
      -0.061926569789648056,
      -0.019876951351761818,
      -0.056387580931186676,
      -0.0014859640505164862,
      0.045623887330293655,
      0.011050968430936337,
      -0.04712754115462303,
      0.008654564619064331,
      -0.012865259312093258,
      0.049166832119226456,
      0.036835357546806335,
      0.010885229334235191,
      0.046279121190309525,
      -0.011498335748910904,
      0.012205242179334164,
      -0.025585202500224113,
      -0.0046606785617768764,
      -0.016858085989952087,
      0.012314140796661377,
      0.046733275055885315,
      0.07134450972080231,
      0.05375369265675545,
      0.00620853528380394,
      0.06233425810933113,
      -0.0007493430166505277,
      0.03645914047956467,
      0.052872221916913986,
      0.04731876403093338,
      0.020451879128813744,
      0.020945357158780098,
      0.017917193472385406,
      -0.024991262704133987,
      -0.02895815670490265,
      0.031802698969841,
      -0.025339679792523384,
      -0.0019677982199937105,
      -0.005641066003590822,
      0.06171382591128349,
      0.046691104769706726,
      0.026956288143992424,
      0.035097796469926834,
      0.0281831007450819,
      -0.06098556146025658,
      -0.06936221569776535,
      0.03270687535405159,
      0.04334584251046181,
      0.005926044657826424,
      -0.04442465677857399,
      -0.06911354511976242,
      -0.020280202850699425,
      0.005993661470711231,
      -0.06603261083364487,
      -0.031127270311117172,
      0.04760090634226799,
      -0.08218103647232056,
      -0.05892963334918022,
      0.09736738353967667,
      -0.05776286870241165,
      0.02734636515378952,
      -0.006203887052834034,
      -0.021462665870785713,
      -0.04178019240498543,
      0.06272426247596741,
      0.04168027639389038,
      -0.024375101551413536,
      0.05942291393876076,
      0.00856148824095726,
      0.011401993222534657,
      0.015573632903397083,
      -0.07873164862394333,
      0.010548674501478672,
      0.011761395260691643,
      -0.002704108599573374,
      0.01637868955731392,
      -0.02475450560450554,
      0.01918310858309269,
      -0.022034436464309692,
      -0.05602990463376045,
      -0.04296325892210007,
      0.043695420026779175,
      0.013048263266682625,
      -0.11328007280826569,
      0.0027180537581443787,
      0.008570373989641666,
      0.008737601339817047,
      -0.049211081117391586,
      0.01695909909904003,
      0.005980486981570721,
      0.06613441556692123,
      0.022820832207798958,
      0.06607494503259659,
      0.06266959756612778,
      -0.10316837579011917,
      -0.052130814641714096,
      0.022383887320756912,
      -0.037060122936964035,
      -0.05753380432724953,
      0.00018630690465215594,
      -0.042539749294519424,
      -0.06761127710342407,
      -0.015704043209552765,
      0.013804872520267963,
      0.05597466602921486,
      -0.023354416713118553,
      -0.015362421981990337,
      0.0033988517243415117,
      -0.03247469663619995,
      0.0027907632756978273,
      0.01870083250105381,
      -0.066751629114151,
      -0.06873387843370438,
      -0.04254176467657089,
      0.054897502064704895,
      -0.0004668725887313485,
      0.09094700217247009,
      -0.06626450270414352,
      0.009187653660774231,
      -0.14286035299301147,
      0.010981305502355099,
      0.04552098363637924,
      0.02242874540388584,
      0.032944127917289734,
      0.053935568779706955,
      -0.03781140595674515,
      -0.04620361328125,
      0.02056570164859295,
      0.019955039024353027,
      0.030285315588116646,
      -0.0335327684879303,
      0.006687675602734089,
      -0.004130765330046415,
      0.008970965631306171,
      -0.09406695514917374,
      -0.0467829704284668,
      -0.008955586701631546,
      0.05193222314119339,
      -0.09842485189437866,
      -0.025502875447273254,
      0.014313658699393272,
      0.04306209087371826,
      -0.03997892141342163,
      -0.037955258041620255,
      0.00825668964534998,
      -0.0006987355300225317,
      0.06840083003044128,
      -0.009353607892990112,
      -0.00958719290792942,
      -0.008489025756716728,
      0.025255000218749046,
      -0.030041035264730453,
      0.074612557888031,
      -0.0462392121553421,
      0.00021873676450923085,
      0.11183823645114899,
      -0.08646219968795776,
      0.061942439526319504,
      0.04592588543891907,
      0.04314219579100609,
      0.05476181581616402,
      0.010656173340976238,
      0.016668153926730156,
      0.0003010578511748463,
      0.06764049082994461,
      -0.07412335276603699,
      9.129497630055994e-05,
      0.002809620928019285,
      0.0031646438874304295,
      -0.00865105353295803,
      -0.03483836352825165,
      -0.00672941654920578,
      -0.006931548472493887,
      0.05869795382022858,
      0.058174580335617065,
      -0.05012768507003784,
      0.04924405738711357,
      -0.01365305669605732,
      -0.0034247026778757572,
      -0.022177699953317642,
      -0.028420178219676018,
      0.013271120376884937,
      0.04365813732147217,
      -0.08079767227172852,
      -0.0285516194999218,
      0.03209194913506508,
      0.01903572492301464,
      -0.034504540264606476,
      -0.04077834263443947,
      0.0671544075012207,
      0.03510131314396858,
      0.008275317959487438,
      -0.09823038429021835,
      -0.018641626462340355,
      0.03994837403297424,
      0.020085148513317108,
      0.06703455746173859,
      0.020375201478600502,
      0.0878063440322876,
      -0.03436551243066788,
      0.004231647122651339,
      0.043441981077194214,
      0.016583701595664024,
      0.06414049863815308,
      -0.01833098940551281,
      -0.01995208114385605,
      0.0172079149633646,
      0.05905894935131073,
      -0.08076400309801102,
      0.057985320687294006,
      0.010931847617030144,
      -0.053928304463624954,
      -0.022103242576122284,
      0.08819828182458878,
      -0.027299726381897926,
      -0.02398480847477913,
      0.027551425620913506,
      0.02187841199338436,
      0.0033409367315471172,
      -0.056990690529346466,
      0.002725709229707718,
      0.05618792027235031,
      0.018016211688518524,
      -0.04753977432847023,
      -0.0020874172914773226,
      -0.037619370967149734,
      -0.0659240111708641,
      0.07214785367250443,
      0.01756846159696579,
      0.06643396615982056,
      -0.03325613588094711,
      -0.06807389855384827,
      0.049193426966667175,
      -0.03250717744231224,
      -0.031466249376535416,
      0.018599161878228188,
      -0.012003242038190365,
      -0.038051243871450424,
      0.05700058862566948,
      -0.008464385755360126,
      -0.08379843086004257,
      -0.026419004425406456,
      -0.02085447870194912,
      -0.03648575395345688,
      -0.05105983838438988,
      -0.057343848049640656,
      0.01516786776483059
    ],
    [
      -0.04041047766804695,
      -0.03497568890452385,
      -0.030242744833230972,
      0.023112215101718903,
      0.007573074195533991,
      -0.016185510903596878,
      -0.097213976085186,
      0.062037792056798935,
      0.07569243013858795,
      0.02826204150915146,
      0.035242851823568344,
      0.02221871167421341,
      0.0037693947087973356,
      -0.053711019456386566,
      -0.04434804990887642,
      0.026067562401294708,
      0.06484290212392807,
      -0.066370889544487,
      -0.05341751500964165,
      -0.017736045643687248,
      -0.04203954339027405,
      -0.04544966667890549,
      0.0010353003162890673,
      0.031979046761989594,
      -0.06015913188457489,
      -0.031255289912223816,
      0.028551042079925537,
      0.0845382958650589,
      -0.04391113668680191,
      -0.013491299003362656,
      0.05660741403698921,
      0.010697638615965843,
      0.014157930389046669,
      0.013468317687511444,
      -0.027705688029527664,
      0.014516147784888744,
      0.07247001677751541,
      -0.0668095126748085,
      0.05294259265065193,
      0.036418236792087555,
      -0.015485708601772785,
      -0.018150273710489273,
      -0.017597023397684097,
      -0.07054057717323303,
      -0.061042338609695435,
      -0.07592733949422836,
      -0.015222000889480114,
      -0.01803254708647728,
      0.0057257660664618015,
      -0.013176975771784782,
      0.07672014832496643,
      -0.005657514091581106,
      0.03207724913954735,
      -0.09196557849645615,
      -0.013471119105815887,
      0.0051420945674180984,
      -0.05324438959360123,
      -0.07041408121585846,
      0.04727906733751297,
      -0.01887735165655613,
      -0.03992229700088501,
      -0.05861784145236015,
      0.03417021408677101,
      -0.018771618604660034,
      0.029128074645996094,
      -0.021770482882857323,
      -0.04269011691212654,
      0.07823111861944199,
      0.04300892353057861,
      0.005938025191426277,
      0.013821350410580635,
      0.019932899624109268,
      0.05798353999853134,
      -0.012057632207870483,
      0.052299439907073975,
      0.0016126486007124186,
      -0.06182384863495827,
      -0.0626973956823349,
      0.045652471482753754,
      0.05229104682803154,
      0.011649273335933685,
      0.04179178923368454,
      0.050198744982481,
      -0.08726868778467178,
      -0.021785324439406395,
      0.03949343040585518,
      -0.014001614414155483,
      -0.023729320615530014,
      0.017952783033251762,
      0.020953407511115074,
      -0.060182202607393265,
      -0.005159668158739805,
      -0.03901548311114311,
      -0.03333249315619469,
      -0.0277673602104187,
      0.03325192630290985,
      0.013883971609175205,
      -0.0072992900386452675,
      0.04909985139966011,
      0.06868838518857956,
      -0.02069089561700821,
      -0.06136972829699516,
      0.012357523664832115,
      -0.06450798362493515,
      0.0015201116912066936,
      -0.05561436340212822,
      0.025880364701151848,
      -0.054705385118722916,
      0.015721550211310387,
      0.052954524755477905,
      0.03252049535512924,
      -0.01290159672498703,
      0.02064090594649315,
      0.05006211996078491,
      0.032130513340234756,
      0.04212192818522453,
      -0.03836171701550484,
      0.06666742265224457,
      -0.06479660421609879,
      -0.07068058103322983,
      0.07490620762109756,
      -0.018792374059557915,
      -0.06806528568267822,
      -0.05315918102860451,
      -0.05878078192472458,
      -0.041393157094717026,
      0.03677807375788689,
      0.016738012433052063,
      -0.01329072006046772,
      -0.01932433806359768,
      -0.010660836473107338,
      0.06985406577587128,
      0.018078748136758804,
      -0.07216393947601318,
      -0.009883392602205276,
      0.00317978672683239,
      -0.009152943268418312,
      -0.032786011695861816,
      -0.05969592556357384,
      -0.02286897599697113,
      0.03532256558537483,
      -0.03168685361742973,
      -0.020320234820246696,
      -0.025561034679412842,
      -0.026526857167482376,
      0.007947038859128952,
      0.09189977496862411,
      0.047217484563589096,
      -0.07858002930879593,
      0.07085445523262024,
      0.02958226017653942,
      -0.023972520604729652,
      0.013128578662872314,
      0.015314552932977676,
      0.05599790811538696,
      0.005974719300866127,
      0.025057122111320496,
      -0.033573176711797714,
      0.0212076548486948,
      0.019107947126030922,
      -0.1377483606338501,
      -0.05394410714507103,
      -0.018928926438093185,
      0.01493372954428196,
      -0.0333084762096405,
      0.03615819662809372,
      -0.044175490736961365,
      -0.09949430078268051,
      -0.0013772522797808051,
      -0.035070016980171204,
      0.030682940036058426,
      0.03488840162754059,
      -0.002039276994764805,
      0.0037525922525674105,
      0.04992883279919624,
      0.0510270930826664,
      -0.005533182993531227,
      -0.004741088952869177,
      0.08597424626350403,
      -0.06824930757284164,
      0.01593216322362423,
      -0.037681855261325836,
      0.008909021504223347,
      0.0633712187409401,
      0.02353787049651146,
      0.027620064094662666,
      0.02686385251581669,
      -0.042736757546663284,
      0.02026989683508873,
      0.010752404108643532,
      -0.0018559072632342577,
      0.05177982151508331,
      -0.04571933299303055,
      0.025763116776943207,
      0.0006500785239040852,
      -0.06344421952962875,
      0.05866715684533119,
      0.016168363392353058,
      0.00610955199226737,
      0.014885829761624336,
      0.05738457292318344,
      -0.0422198511660099,
      0.03613269701600075,
      -0.07588741928339005,
      -0.018236776813864708,
      0.06033805385231972,
      -0.03254225477576256,
      -0.0038227299228310585,
      -0.04175126925110817,
      0.02801724150776863,
      -0.019964629784226418,
      0.0714239627122879,
      -0.02441466972231865,
      -0.04610180854797363,
      0.04106943681836128,
      -0.0069709764793515205,
      -0.008147760294377804,
      -0.007246910594403744,
      0.04426372051239014,
      0.0172133632004261,
      0.02670968882739544,
      0.0004592266632243991,
      -0.02314668335020542,
      0.0360577367246151,
      -0.012828444130718708,
      -0.019653119146823883,
      -0.054182786494493484,
      0.04438772052526474,
      0.075645312666893,
      -0.0415305569767952,
      -0.055644433945417404,
      -0.0041171894408762455,
      0.02826322615146637,
      -0.10937579721212387,
      0.007517973892390728,
      -0.01796269230544567,
      0.0953681692481041,
      0.08143628388643265,
      -0.04607893526554108,
      -0.018381167203187943,
      0.06394480168819427,
      -0.05371948331594467,
      0.0602836050093174,
      -0.014431867748498917,
      0.0012368775205686688,
      0.06166467070579529,
      -0.020673051476478577,
      -0.061664827167987823,
      0.06612058728933334,
      -0.018359314650297165,
      -0.019210657104849815,
      -0.0008916513179428875,
      -0.014394745230674744,
      0.005923820193856955,
      -0.09246859699487686,
      -0.02421758510172367,
      0.02172027714550495,
      0.01665445975959301,
      -0.004857867024838924,
      -0.03334150090813637,
      -0.022075805813074112,
      -0.030199356377124786,
      -0.06409436464309692,
      -0.008044514805078506,
      0.0551680363714695,
      -0.0021755932830274105,
      0.05613713711500168,
      -0.05421964451670647,
      0.03234060853719711,
      0.0014243611367419362,
      -0.020676134154200554,
      0.08284161239862442,
      -0.005042819306254387,
      0.024048427119851112,
      0.04942866787314415,
      0.026230769231915474,
      0.046123672276735306,
      -0.07577282935380936,
      -0.080740787088871,
      0.051915619522333145,
      -0.0642424076795578,
      0.031708888709545135,
      -0.017137648537755013,
      0.02088291011750698,
      -0.13471387326717377,
      -0.05340210720896721,
      -0.04479649290442467,
      -0.043960630893707275,
      -0.02194589376449585,
      0.04642743617296219,
      -0.04645887017250061,
      0.07260587066411972,
      0.06986896693706512,
      -0.05546857416629791,
      0.025876831263303757,
      0.013110018335282803,
      -0.009028250351548195,
      -0.03259236738085747,
      0.02467929944396019,
      -0.06732851266860962,
      0.04501393809914589,
      0.004083107691258192,
      0.04080759361386299,
      0.010166062042117119,
      0.06269428879022598,
      0.02765357680618763,
      0.046535689383745193,
      -0.011765789240598679,
      -0.048443157225847244,
      0.0037094152066856623,
      0.02934255823493004,
      -0.016243424266576767,
      -0.08872885257005692,
      0.020586540922522545,
      -0.05256495997309685,
      0.05754398927092552,
      -0.06965349614620209,
      0.03558126837015152,
      0.03677299618721008,
      0.021241631358861923,
      0.02435040846467018,
      0.023523641750216484,
      0.08842878043651581,
      0.03492002189159393,
      -0.11483383178710938,
      -0.08351326733827591,
      0.024500612169504166,
      0.02548152208328247,
      0.10971319675445557,
      -0.05864521116018295,
      -0.0002618965518195182,
      -0.0041899667121469975,
      -0.05893835425376892,
      0.023576179519295692,
      -0.029652133584022522,
      -0.005142598878592253,
      0.013820020481944084,
      -0.002232597442343831,
      -0.029178116470575333,
      0.0521903820335865,
      -0.014596396125853062,
      -0.08449134230613708,
      0.00027841608971357346,
      -0.04864222928881645,
      0.06355872005224228,
      0.026991194114089012,
      -0.03183925524353981,
      0.045137669891119,
      -0.08487048000097275,
      0.018763722851872444,
      -0.06382977217435837,
      -0.07935037463903427,
      0.005407559219747782,
      0.012652481906116009,
      -0.05640505254268646,
      -0.02506915107369423,
      -0.020433815196156502,
      -0.05726918205618858,
      0.04582562670111656,
      0.017496705055236816,
      -0.06619958579540253,
      -0.0341692753136158,
      0.031127341091632843,
      0.00802166759967804,
      -0.01656593568623066,
      0.08168380707502365,
      -0.008304296061396599,
      -0.08904873579740524,
      0.06919274479150772,
      0.004913005977869034,
      0.036922603845596313,
      0.023439262062311172,
      -0.07585015892982483,
      -0.054301898926496506,
      -0.027690529823303223,
      -0.04340842738747597,
      0.04684295132756233,
      -0.003913679625838995,
      0.03220272436738014,
      0.06828412413597107,
      0.07697994261980057,
      0.012259501963853836,
      -0.01672154851257801,
      0.00860579777508974,
      0.008703107945621014,
      -0.08034325391054153,
      0.040416017174720764,
      0.08446338027715683,
      0.06478685140609741,
      0.07069721072912216,
      0.02358228527009487,
      0.04629500210285187,
      -0.042563632130622864,
      0.09011022001504898,
      -0.018797729164361954,
      0.08917064219713211,
      -0.07383362203836441,
      -0.040514666587114334,
      -0.04691728204488754,
      0.02152528055012226,
      -0.031896717846393585,
      -0.027225695550441742,
      0.0021054737735539675,
      0.0001333634281763807,
      0.0030326435808092356,
      -0.010621928609907627,
      -0.04047751426696777,
      -0.0326508991420269,
      0.025828799232840538,
      0.07572019845247269,
      0.02456015720963478,
      -0.08447261154651642,
      -0.02729225344955921,
      -0.011356902308762074,
      -0.010187767446041107,
      0.020423896610736847,
      -0.022569315508008003,
      -0.016293929889798164,
      -0.05757306516170502,
      -0.046224772930145264,
      0.0032197125256061554,
      -0.024434400722384453,
      -0.0306781567633152,
      0.05681847780942917,
      0.11728612333536148,
      0.009159884415566921,
      0.11807707697153091,
      -0.012896961532533169,
      0.05695132166147232,
      -0.05574386939406395,
      -0.023668916895985603,
      -0.013170945458114147,
      -0.024446215480566025,
      0.04881342500448227,
      -0.038938071578741074,
      0.04272444546222687,
      -0.04592761769890785,
      -0.0072359670884907246,
      0.01937536895275116,
      -0.018716130405664444,
      -0.002723399316892028,
      0.04735013470053673,
      0.04935804754495621,
      -0.01853587105870247,
      -0.012244467623531818,
      0.007799328304827213,
      0.03516142815351486,
      0.03215024992823601,
      -0.006769927218556404,
      -0.010686362162232399,
      -0.010899397544562817,
      -0.0070136417634785175,
      0.025810901075601578,
      -0.014525160193443298,
      -0.0014309515245258808,
      -0.002630614908412099,
      0.02631392329931259,
      0.0463465079665184,
      0.07317081093788147,
      -0.06734797358512878,
      -0.012997926212847233,
      -0.04138219729065895,
      0.009333175607025623,
      -0.007115157321095467,
      -0.010579736903309822,
      -0.002987335668876767,
      -0.005395466927438974,
      0.04454124718904495,
      0.10368305444717407,
      0.02844393439590931,
      0.08675752580165863,
      -0.012246659956872463,
      -0.12130261957645416,
      0.014163864776492119,
      0.017046498134732246,
      -0.048659518361091614,
      0.01664675399661064,
      -0.0803716704249382,
      0.01889771968126297,
      0.05487295612692833,
      0.021665995940566063,
      -0.02658722922205925,
      0.03160626441240311,
      -0.042906880378723145,
      0.0500916987657547,
      -0.025360360741615295,
      0.015207119286060333,
      -0.05460154637694359,
      -0.02296151965856552,
      0.012393084354698658,
      0.02542656846344471,
      0.05162450298666954,
      -0.00882504228502512,
      -0.05656524375081062,
      0.021293647587299347,
      -0.04607784375548363,
      -0.0004899755003862083,
      -0.02191092073917389,
      -0.005983022507280111,
      -0.001607055077329278,
      -0.05724465474486351,
      -0.031107770279049873,
      -0.006733909714967012
    ],
    [
      0.04916921630501747,
      -0.00913725420832634,
      0.06535026431083679,
      -0.052773475646972656,
      -0.028098231181502342,
      0.006538074463605881,
      0.02612052485346794,
      0.01038153376430273,
      0.012536370195448399,
      0.006764092482626438,
      -0.014545640908181667,
      -0.09019708633422852,
      -0.03129326179623604,
      0.012620696797966957,
      -0.051016032695770264,
      0.03821380436420441,
      0.0022771540097892284,
      -0.03340727835893631,
      -0.04508563131093979,
      -0.11376611143350601,
      -0.03291468694806099,
      0.037645936012268066,
      0.0286615788936615,
      -0.03002147004008293,
      0.04594462737441063,
      0.022514276206493378,
      -0.031242165714502335,
      0.04054994508624077,
      -0.05024654045701027,
      0.01689103990793228,
      -0.024699529632925987,
      -0.0374903604388237,
      -0.04792125150561333,
      0.04990933835506439,
      0.022049276158213615,
      0.023949941620230675,
      -0.04413337633013725,
      0.019774366170167923,
      0.13131754100322723,
      -0.049086280167102814,
      0.036246586591005325,
      0.01131060253828764,
      -0.005801683757454157,
      0.016027754172682762,
      -0.042384352535009384,
      0.00041053484892472625,
      -0.03271712362766266,
      0.07361932843923569,
      0.06311000138521194,
      -0.14178113639354706,
      -0.04399683699011803,
      0.05919048562645912,
      0.054577458649873734,
      0.015580227598547935,
      -0.10293509066104889,
      -0.0826241746544838,
      0.014357381500303745,
      -0.04459047317504883,
      -0.05441629886627197,
      0.04656403511762619,
      0.05761966481804848,
      -0.01970546506345272,
      -0.06827080249786377,
      0.00971722137182951,
      0.021839044988155365,
      -0.0505562387406826,
      0.061811257153749466,
      0.001060742069967091,
      0.020238885655999184,
      0.08502469211816788,
      -0.07601036131381989,
      0.09688013046979904,
      -0.02374335005879402,
      0.01466993149369955,
      0.05141591653227806,
      -0.03519727662205696,
      0.07492899149656296,
      -0.013735893182456493,
      0.045610129833221436,
      0.019394654780626297,
      0.11713377386331558,
      0.01813443936407566,
      -0.025471456348896027,
      -0.0965171679854393,
      0.004116426687687635,
      0.008709519170224667,
      0.014505735598504543,
      -0.03787131607532501,
      0.004914003890007734,
      0.011696044355630875,
      0.01379745826125145,
      -0.038616809993982315,
      0.03750351443886757,
      0.002152819884940982,
      0.0028678139206022024,
      0.03027402050793171,
      -0.06902984529733658,
      0.030659906566143036,
      0.06314893066883087,
      -0.0608050674200058,
      0.06881588697433472,
      0.0663796216249466,
      0.015088259242475033,
      -0.03796175494790077,
      0.030239902436733246,
      -0.03287038207054138,
      -0.17277488112449646,
      0.007738810032606125,
      -0.025805598124861717,
      -0.0016299068229272962,
      -0.07359019666910172,
      -0.042713865637779236,
      -0.058032676577568054,
      0.030932681635022163,
      0.007190897595137358,
      0.028852025046944618,
      -0.005995500832796097,
      0.05093865469098091,
      -0.018622132018208504,
      -0.03312038630247116,
      0.04705489054322243,
      -0.025850197300314903,
      -0.094163678586483,
      -0.019924718886613846,
      -0.02975810505449772,
      -0.01951475255191326,
      -0.006559747736901045,
      -0.053905896842479706,
      0.018663976341485977,
      0.010839728638529778,
      -0.008385980501770973,
      -0.00045959456474520266,
      -0.043474964797496796,
      0.009459864348173141,
      -0.05128306522965431,
      -0.08777870982885361,
      0.016633987426757812,
      0.009911815635859966,
      0.13245192170143127,
      0.00848478265106678,
      0.06736879795789719,
      -0.06240778788924217,
      0.01846436783671379,
      -0.03507255017757416,
      -0.07489671558141708,
      0.018730416893959045,
      0.07398039102554321,
      0.05269685015082359,
      0.005064080934971571,
      0.01414417289197445,
      0.028836403042078018,
      -0.0515991635620594,
      0.016764845699071884,
      0.012030362151563168,
      -0.04911775887012482,
      -2.3046490241540596e-05,
      -0.05852004513144493,
      0.020289016887545586,
      -0.035771891474723816,
      -0.014855182729661465,
      -0.021928036585450172,
      0.004907252267003059,
      -0.0011565720196813345,
      0.013449772261083126,
      0.03406804800033569,
      -0.0363931804895401,
      -0.014708487316966057,
      -0.04701496288180351,
      0.06165410205721855,
      -0.12158339470624924,
      -0.05223293602466583,
      -0.009792882949113846,
      -0.00643503712490201,
      -0.033330269157886505,
      -0.017309924587607384,
      0.0020018345676362514,
      -0.024569183588027954,
      0.08897611498832703,
      -0.07654525339603424,
      0.0493597574532032,
      0.011830735951662064,
      0.01314469613134861,
      -0.017717856913805008,
      -0.06632653623819351,
      -0.002339734463021159,
      -0.04788178578019142,
      0.02079104259610176,
      0.053121134638786316,
      -0.03314971178770065,
      -0.05960856378078461,
      -0.03957158699631691,
      -0.039659079164266586,
      -0.06904184073209763,
      0.005674769636243582,
      0.024268019944429398,
      -0.025322413071990013,
      0.09086352586746216,
      0.08717904984951019,
      0.010753227397799492,
      0.01975313015282154,
      -0.05960238724946976,
      0.060346730053424835,
      -0.03448567911982536,
      0.025559833273291588,
      0.012720954604446888,
      -0.04594423994421959,
      -0.05139504745602608,
      -0.03469056636095047,
      0.005145088769495487,
      -0.012520135380327702,
      -0.027138860896229744,
      0.040951214730739594,
      0.012188298627734184,
      0.0002451443870086223,
      0.04563881829380989,
      0.005492851138114929,
      0.03394952416419983,
      -0.03780356049537659,
      0.019983261823654175,
      0.010908238589763641,
      -0.030220650136470795,
      -0.008929348550736904,
      0.027818934991955757,
      -0.0188677366822958,
      -0.0570373609662056,
      -0.014722924679517746,
      0.043699800968170166,
      0.01600063219666481,
      -0.043143607676029205,
      0.018948625773191452,
      -0.002385941334068775,
      -0.01836807280778885,
      -0.013939746655523777,
      -0.03478088974952698,
      0.020128192380070686,
      0.04301896691322327,
      0.018014341592788696,
      0.006216276902705431,
      0.05326838418841362,
      0.04110774025321007,
      0.014296435751020908,
      -0.013445068150758743,
      0.025368068367242813,
      0.03263983502984047,
      0.03694099560379982,
      0.04000304266810417,
      0.08667498081922531,
      0.02865706756711006,
      -0.037907231599092484,
      0.03562648221850395,
      0.039240021258592606,
      0.01574721746146679,
      -0.02657278999686241,
      0.07526183873414993,
      0.02426820620894432,
      -0.01210530661046505,
      0.05083436146378517,
      0.009965617209672928,
      -0.008014834485948086,
      0.024679750204086304,
      0.001107600750401616,
      0.04197200760245323,
      0.01743677631020546,
      -0.050016503781080246,
      -0.00042641579057089984,
      -0.04010394215583801,
      0.06875449419021606,
      0.11501292884349823,
      0.041525695472955704,
      -0.004556796979159117,
      -0.09891761839389801,
      0.030139651149511337,
      0.03776665776968002,
      0.06448691338300705,
      -0.06779839098453522,
      0.019181475043296814,
      0.07866200804710388,
      -0.08169853687286377,
      -0.07854966819286346,
      -0.011763897724449635,
      0.023131972178816795,
      -0.05042145401239395,
      0.027858560904860497,
      0.045527562499046326,
      -0.052385684102773666,
      -0.02065451256930828,
      -0.00847636628895998,
      -0.016690509393811226,
      0.054255396127700806,
      -0.029246142134070396,
      -0.038587331771850586,
      0.06807418912649155,
      -0.019981645047664642,
      0.07220760732889175,
      -0.05205823853611946,
      0.06386688351631165,
      -0.03562597557902336,
      0.0010093983728438616,
      0.02112816646695137,
      0.04862888902425766,
      -0.0023533490020781755,
      0.03118666633963585,
      0.009928210638463497,
      -0.026661556214094162,
      -0.012932131998240948,
      0.061142779886722565,
      -0.02613387070596218,
      -0.09936404228210449,
      0.06202467903494835,
      -0.12302263081073761,
      0.03445684537291527,
      0.03197591379284859,
      0.060684915632009506,
      0.03252267837524414,
      0.059671781957149506,
      -0.03328903391957283,
      0.02777687832713127,
      0.062466274946928024,
      -0.022010743618011475,
      0.030565502122044563,
      0.012039647437632084,
      -0.00047672426444478333,
      -0.059972088783979416,
      0.016024796292185783,
      -0.06572223454713821,
      -0.04688893258571625,
      0.014624604023993015,
      -0.045741934329271317,
      -0.03030587173998356,
      -0.005995277781039476,
      -0.01548012439161539,
      0.058839548379182816,
      0.04498186707496643,
      0.0025844385381788015,
      0.050345949828624725,
      0.0014001673553138971,
      -0.010944516398012638,
      0.10218130797147751,
      0.007110979873687029,
      -0.0491667278110981,
      -0.011802207678556442,
      0.07709789276123047,
      0.001364643219858408,
      -0.031327586621046066,
      -0.0904187336564064,
      -0.010774381458759308,
      0.08963708579540253,
      0.020297616720199585,
      -0.06648960709571838,
      0.006944428198039532,
      -0.07922973483800888,
      0.07139720767736435,
      -0.06897205859422684,
      0.09303666651248932,
      -0.03378099203109741,
      -0.012822145596146584,
      0.010498417541384697,
      0.029289839789271355,
      0.02343175560235977,
      0.09061811864376068,
      0.0023191762156784534,
      -0.0673266053199768,
      0.0040303184650838375,
      0.0009459302527830005,
      -0.0971241295337677,
      -0.02805262804031372,
      -0.08047471940517426,
      -0.06979282200336456,
      -0.03288960084319115,
      0.04518827795982361,
      -0.08198025077581406,
      0.013699459843337536,
      0.05419326573610306,
      0.021982960402965546,
      0.05074983090162277,
      -0.009495481848716736,
      0.03246317803859711,
      -0.00794498436152935,
      -0.050387393683195114,
      0.03287840634584427,
      -0.03942900896072388,
      0.0769762396812439,
      -0.03749030455946922,
      -0.046356551349163055,
      -0.05426618829369545,
      -0.06521103531122208,
      0.0368642620742321,
      0.05392216145992279,
      0.1345326453447342,
      -0.017303144559264183,
      0.06289249658584595,
      0.010491658933460712,
      -0.06377120316028595,
      -0.01808789372444153,
      -0.014881144277751446,
      0.03736710920929909,
      -0.02120768465101719,
      0.03858345001935959,
      0.0442451648414135,
      -0.06109568104147911,
      -0.11687779426574707,
      0.049014728516340256,
      -0.009182415902614594,
      -0.0032164682634174824,
      0.004683868493884802,
      -0.040487855672836304,
      0.00975127425044775,
      0.020729877054691315,
      0.0025127234403043985,
      -0.019120609387755394,
      -0.000740808027330786,
      0.0761481299996376,
      0.02835809998214245,
      -0.09488727152347565,
      0.08316711336374283,
      -0.12015978991985321,
      -0.005686369724571705,
      0.0973263680934906,
      0.04795123264193535,
      -0.018581673502922058,
      0.00170671078376472,
      0.016140040010213852,
      0.04842273145914078,
      -0.040177710354328156,
      0.04876793920993805,
      -0.01715264841914177,
      0.040532246232032776,
      0.022211283445358276,
      0.0644468292593956,
      -0.045888110995292664,
      0.01126788929104805,
      -0.02406194619834423,
      -0.014440043829381466,
      0.026844164356589317,
      -0.005603100638836622,
      0.08950474858283997,
      -0.07244156301021576,
      0.043183740228414536,
      -0.020617695525288582,
      -0.038480158895254135,
      0.018134087324142456,
      0.010532581247389317,
      -0.004253115970641375,
      -0.016553929075598717,
      0.015122691169381142,
      0.04983368143439293,
      -0.0005657068686559796,
      -0.03949865326285362,
      0.06081235781311989,
      -0.000477595574920997,
      -0.02874736487865448,
      0.09164801239967346,
      0.057813163846731186,
      -0.04133828356862068,
      0.011026130057871342,
      -0.005154671613126993,
      -0.01465434581041336,
      0.005217352416366339,
      -0.025807978585362434,
      0.09501051157712936,
      -0.04410642012953758,
      0.030603080987930298,
      -0.028553009033203125,
      -0.061691317707300186,
      0.013341213576495647,
      -0.07641720771789551,
      0.06360138207674026,
      -0.026100117713212967,
      0.06662049144506454,
      -0.006711504887789488,
      0.0350986085832119,
      -0.012371603399515152,
      -0.07470560073852539,
      -0.09656354784965515,
      0.0045214020647108555,
      0.0248204804956913,
      0.04846683144569397,
      0.10794536769390106,
      -0.028149355202913284,
      0.006507404148578644,
      0.07553364336490631,
      0.005796019919216633,
      -0.07411569356918335,
      -0.026061106473207474,
      0.020828882232308388,
      -0.025861067697405815,
      -0.052625685930252075,
      -0.08158686012029648,
      -0.015220760367810726,
      -0.006332111079245806,
      0.0204786229878664,
      0.028420668095350266,
      0.05520438402891159,
      -0.003247177693992853,
      -0.014801766723394394,
      0.03867076337337494,
      -0.1001121774315834,
      -0.04809610918164253,
      0.02001151256263256,
      0.03361371159553528,
      -0.027890078723430634,
      -0.017841117456555367,
      -0.0075409384444355965
    ],
    [
      0.04805292189121246,
      -0.011495650745928288,
      0.07546279579401016,
      0.02130107581615448,
      0.01274188607931137,
      -0.07755500823259354,
      0.005088074132800102,
      -0.019225792959332466,
      0.10375503450632095,
      0.010722856037318707,
      0.05007424205541611,
      0.05356207489967346,
      -0.13274601101875305,
      -0.009973397478461266,
      0.05495063588023186,
      0.0004496961773838848,
      0.024964356794953346,
      0.08434823155403137,
      -0.030795590952038765,
      -0.03608888015151024,
      -0.06701523065567017,
      -0.06045578047633171,
      -0.06491561979055405,
      0.08320150524377823,
      0.00876987911760807,
      -0.009583341889083385,
      0.03868750482797623,
      0.03053061105310917,
      -0.05460972338914871,
      0.006016777828335762,
      0.007562635466456413,
      -0.012675943784415722,
      0.04053894430398941,
      -0.01818317361176014,
      -0.010410928167402744,
      -0.0003424763272050768,
      -0.0671948567032814,
      -0.026296395808458328,
      -0.11487041413784027,
      -0.002197542693465948,
      -0.04137440398335457,
      -0.06324251741170883,
      -0.020454462617635727,
      -0.017088981345295906,
      0.06564164906740189,
      -0.036720771342515945,
      0.009841825813055038,
      0.004801224451512098,
      0.03169145807623863,
      0.014979706145823002,
      0.0541200190782547,
      0.014873340725898743,
      -0.004424929618835449,
      0.0606139674782753,
      0.024165639653801918,
      -0.004751982633024454,
      -0.05793003365397453,
      -0.013279088772833347,
      0.012644254602491856,
      -0.0026200239080935717,
      -0.038260139524936676,
      -0.02321668714284897,
      0.0606689415872097,
      -0.03776910528540611,
      0.012604596093297005,
      -0.015318133868277073,
      -0.027923332527279854,
      -0.006578940432518721,
      0.011270969174802303,
      0.046019893139600754,
      0.022090498358011246,
      -0.016665399074554443,
      -0.06692507863044739,
      -0.02792707085609436,
      0.019670650362968445,
      -0.04972362518310547,
      -0.048499785363674164,
      0.04851461574435234,
      -0.04769496992230415,
      -0.052192267030477524,
      0.06980718672275543,
      0.015571020543575287,
      -0.019667182117700577,
      -0.0890474021434784,
      -0.03653401508927345,
      -0.035935211926698685,
      -0.05022943392395973,
      -0.06646732985973358,
      -0.0227569080889225,
      -0.0038052350282669067,
      0.013703200966119766,
      -0.07752284407615662,
      0.00025385941262356937,
      0.06989791989326477,
      0.04106036573648453,
      0.019856682047247887,
      -0.02108033187687397,
      -0.027243776246905327,
      0.05868525430560112,
      -0.0026609632186591625,
      0.03706962987780571,
      -0.028019648045301437,
      0.04583260044455528,
      -0.013639135286211967,
      0.1256522238254547,
      -0.04157523438334465,
      -0.02019745483994484,
      0.030181249603629112,
      -0.0081251235678792,
      0.07244055718183517,
      0.01690354198217392,
      -0.008082752116024494,
      0.0225888192653656,
      0.055396731942892075,
      -0.024491138756275177,
      -0.058333054184913635,
      -0.013904379680752754,
      0.01232233364135027,
      -0.045780908316373825,
      -0.05692916736006737,
      0.0024430667981505394,
      0.020435139536857605,
      0.015227626077830791,
      -0.012673291377723217,
      -0.058514129370450974,
      0.06346526741981506,
      -0.05149746686220169,
      0.0009249788708984852,
      0.039275068789720535,
      -0.014110402204096317,
      -0.06671131402254105,
      0.03925429284572601,
      -0.02550787851214409,
      0.039227861911058426,
      -0.035663943737745285,
      -0.020888078957796097,
      0.026945069432258606,
      -0.05214126408100128,
      0.02066650427877903,
      -0.04478779062628746,
      -0.04014182463288307,
      -0.040231529623270035,
      -0.022253699600696564,
      -0.005987063515931368,
      -0.10325270891189575,
      0.06010884791612625,
      0.006003237329423428,
      0.015309146605432034,
      0.052724726498126984,
      0.04432389512658119,
      -0.02824830636382103,
      -0.09720490127801895,
      0.10022982954978943,
      0.07388334721326828,
      -0.010290653444826603,
      -0.004419074393808842,
      -0.04168687015771866,
      -0.049381621181964874,
      -0.0062463656067848206,
      -0.006839280016720295,
      0.0783238336443901,
      0.013678781688213348,
      -0.0449732206761837,
      -0.015434636734426022,
      0.027393938973546028,
      -0.0332951620221138,
      0.003530103014782071,
      -0.02988562546670437,
      0.004714184440672398,
      0.027509357780218124,
      -0.04532299190759659,
      0.027465730905532837,
      -0.00026267862995155156,
      0.0015455519314855337,
      0.043044619262218475,
      0.057682134211063385,
      -0.02384861558675766,
      0.06004408746957779,
      0.06251750141382217,
      0.046949781477451324,
      0.02899925783276558,
      0.03483264893293381,
      0.003415291663259268,
      -0.047428518533706665,
      -0.002577435225248337,
      -0.013574481941759586,
      0.05668534338474274,
      -0.05918768420815468,
      0.004374179523438215,
      -0.015460834838449955,
      0.0618765726685524,
      -0.01948634348809719,
      -0.006298593245446682,
      0.044357530772686005,
      0.0008345431415364146,
      -0.03914394602179527,
      0.0044389585964381695,
      -0.05680613964796066,
      -0.021854544058442116,
      0.018080726265907288,
      0.04082247242331505,
      0.023004868999123573,
      0.023623116314411163,
      0.011938576586544514,
      0.004413273185491562,
      0.0012196582974866033,
      -0.0469750352203846,
      0.025689180940389633,
      -0.10967841744422913,
      -0.017064344137907028,
      0.0648498460650444,
      0.02374948374927044,
      -0.0826847106218338,
      0.02773522026836872,
      0.03671646863222122,
      -0.005497581325471401,
      0.01911914348602295,
      -0.01885080523788929,
      0.031003721058368683,
      0.019525500014424324,
      0.05411355942487717,
      -0.0510748066008091,
      -0.03317178785800934,
      -0.007213976699858904,
      0.01078237034380436,
      0.02883180044591427,
      0.029066918417811394,
      0.0924421101808548,
      -0.03602185845375061,
      -0.07501082867383957,
      0.01107524149119854,
      0.012789503671228886,
      0.006500498857349157,
      -0.009731141850352287,
      0.04204193875193596,
      0.03361888974905014,
      0.03389626368880272,
      0.03517647460103035,
      -0.03373074531555176,
      0.026864854618906975,
      0.0033769782166928053,
      0.016595536842942238,
      0.08537174761295319,
      -0.06138105317950249,
      -0.09336534142494202,
      -0.002827924443408847,
      0.05631348118185997,
      -0.013466124422848225,
      -0.013640307821333408,
      0.10775255411863327,
      -0.05594898387789726,
      -0.07204417884349823,
      0.037101272493600845,
      -0.009961379691958427,
      0.008919828571379185,
      -0.02259376086294651,
      0.02651684544980526,
      0.03849053010344505,
      0.025283416733145714,
      -0.008523877710103989,
      0.04850425943732262,
      -0.0076088313944637775,
      0.022822216153144836,
      -0.015802036970853806,
      0.027426786720752716,
      0.049676038324832916,
      0.04784960299730301,
      0.06331893056631088,
      -0.03964006528258324,
      -0.023774245753884315,
      0.04406052827835083,
      -0.015914151445031166,
      -0.001195660326629877,
      -0.013718664646148682,
      0.0021734542679041624,
      0.007858794182538986,
      0.015651410445570946,
      -0.014734555035829544,
      0.023921215906739235,
      0.03596923127770424,
      -0.013498540036380291,
      -0.025849729776382446,
      -0.018289966508746147,
      0.025952517986297607,
      -0.00051411910681054,
      0.007889400236308575,
      -0.023051917552947998,
      0.04791511595249176,
      -0.02416917122900486,
      0.013978053815662861,
      0.0030562863685190678,
      0.012490087188780308,
      -0.039640944451093674,
      -0.04352787882089615,
      -0.06021903455257416,
      -0.03902953863143921,
      0.028075894340872765,
      0.0511283278465271,
      -0.06416819989681244,
      -0.004824804607778788,
      0.028772931545972824,
      -0.01602081209421158,
      -0.09728702902793884,
      -0.030209936201572418,
      0.059981897473335266,
      0.07975617796182632,
      -0.013526305556297302,
      0.05354025214910507,
      -0.07087349891662598,
      -0.03865973278880119,
      0.002133746165782213,
      -0.03600618615746498,
      0.06380008906126022,
      0.01647968403995037,
      0.021193770691752434,
      0.009480632841587067,
      -0.13596519827842712,
      -0.0665215328335762,
      -0.0569758303463459,
      -0.03951435536146164,
      0.008512875996530056,
      0.07388239353895187,
      0.0363011434674263,
      0.11291113495826721,
      -0.0608932264149189,
      -0.06272556632757187,
      -0.010552783496677876,
      -0.0257565937936306,
      -0.010440685786306858,
      0.09890349209308624,
      0.017155542969703674,
      0.027778444811701775,
      -0.04726254194974899,
      0.0062487064860761166,
      -0.0018254643073305488,
      -0.0302460715174675,
      0.009073469787836075,
      -0.05501033738255501,
      -0.02223568595945835,
      0.08805502951145172,
      -0.0129853505641222,
      -0.04810215160250664,
      0.026629311963915825,
      -0.011345088481903076,
      0.05086282640695572,
      -0.036483198404312134,
      0.035548239946365356,
      0.0545157715678215,
      -0.06784560531377792,
      0.05122421681880951,
      0.020727168768644333,
      -0.0572885163128376,
      0.05441725626587868,
      0.03671557456254959,
      -0.07139165699481964,
      0.0037629185244441032,
      0.06437519192695618,
      0.03887109085917473,
      -0.008877902291715145,
      0.019310003146529198,
      -0.027878092601895332,
      -0.006022531073540449,
      0.016182534396648407,
      0.010086745955049992,
      -0.03778228163719177,
      0.02539634145796299,
      0.1507788896560669,
      0.03115132637321949,
      0.011991556733846664,
      -0.045717500150203705,
      0.024388408288359642,
      -6.957102868909715e-06,
      -0.04502477869391441,
      0.015460310503840446,
      0.04985496774315834,
      -0.04495377093553543,
      -0.019954847171902657,
      -0.022958936169743538,
      0.03742675110697746,
      -0.02901359833776951,
      -0.11208909004926682,
      -0.05396425724029541,
      -0.01621885970234871,
      0.05468948185443878,
      0.037780486047267914,
      0.006068042013794184,
      0.044455721974372864,
      0.04492291435599327,
      0.11230451613664627,
      0.028632810339331627,
      -0.05103808641433716,
      -0.016104238107800484,
      0.009495762176811695,
      -0.03873719274997711,
      -0.01722964644432068,
      0.052697889506816864,
      -0.10653893649578094,
      0.05041931942105293,
      -0.007112717255949974,
      0.02706453762948513,
      0.010763938538730145,
      0.08757975697517395,
      0.008077854290604591,
      -0.058187782764434814,
      -0.05699416995048523,
      0.05689314007759094,
      -0.017569253221154213,
      0.003627135418355465,
      0.015929898247122765,
      0.01585637405514717,
      0.061341237276792526,
      0.012581861577928066,
      0.02544531226158142,
      0.0531952790915966,
      0.012610049918293953,
      0.010970029048621655,
      -0.0754646360874176,
      -0.016821198165416718,
      0.060970138758420944,
      0.012104419991374016,
      0.06348995119333267,
      -0.036240383982658386,
      -0.070777028799057,
      -0.04346802085638046,
      0.010606972500681877,
      0.025252675637602806,
      -0.011364193633198738,
      0.06319095194339752,
      -0.053634922951459885,
      0.009244680404663086,
      0.07574015855789185,
      -0.04501501843333244,
      0.03977891802787781,
      0.009625416249036789,
      0.07215558737516403,
      -0.06995179504156113,
      -0.021456602960824966,
      -0.0037211799062788486,
      0.023658262565732002,
      -0.00048710478586144745,
      -0.00789171364158392,
      -0.009505145251750946,
      0.003398820525035262,
      -0.016846297308802605,
      -0.12789440155029297,
      -0.07703126221895218,
      0.033922478556632996,
      -0.0006893048994243145,
      -0.021492302417755127,
      0.10500159114599228,
      0.03892768546938896,
      -0.04838983714580536,
      -0.020976293832063675,
      0.06406202167272568,
      0.006753499154001474,
      -0.015261766500771046,
      -0.09089075028896332,
      -0.018839916214346886,
      -0.04287708178162575,
      0.003880781354382634,
      -0.07151167094707489,
      -0.029845936223864555,
      -0.06598576158285141,
      0.001866804319433868,
      -0.0011534884106367826,
      -0.0210066307336092,
      0.05831295624375343,
      -0.04730142652988434,
      -0.01983576826751232,
      -0.03938484191894531,
      0.03813714161515236,
      0.03420848399400711,
      -0.03329494595527649,
      0.014127098023891449,
      0.019443809986114502,
      -0.02855827286839485,
      0.005761201027780771,
      -0.05105677247047424,
      -0.038879990577697754,
      -0.048569388687610626,
      -0.07842065393924713,
      -0.04025290161371231,
      -0.049249790608882904,
      -0.03698347508907318,
      0.02517341636121273,
      0.03236280381679535,
      0.05582686886191368,
      -0.040617793798446655,
      -0.05381486192345619,
      0.008229142986238003,
      0.011226097121834755,
      0.010114127770066261,
      -0.035497281700372696,
      -0.03120773285627365,
      -0.00038151582702994347,
      0.0065917461179196835,
      -0.006213281769305468,
      0.014458372257649899,
      -0.0168857853859663,
      -0.026886213570833206,
      0.021633794531226158,
      -0.034011565148830414,
      0.011201996356248856
    ],
    [
      0.02068210579454899,
      0.017931027337908745,
      0.023960784077644348,
      0.07284921407699585,
      -0.03088877536356449,
      -0.07829208672046661,
      0.05806858465075493,
      0.05223850533366203,
      -0.0777406319975853,
      -0.09944633394479752,
      0.017534200102090836,
      -0.0011768251424655318,
      -0.006320670712739229,
      0.05076311156153679,
      -0.07945462316274643,
      -0.11146792769432068,
      -0.019139830023050308,
      -0.03039160929620266,
      0.028755756095051765,
      0.08616584539413452,
      -0.018977807834744453,
      0.03917178511619568,
      -0.00926500465720892,
      0.06622382998466492,
      -0.04433881863951683,
      0.0001836284063756466,
      -0.025245163589715958,
      0.08604280650615692,
      0.01441637147217989,
      0.030163312330842018,
      -0.06037292629480362,
      -0.06816993653774261,
      0.030097706243395805,
      -0.043544039130210876,
      -0.020886242389678955,
      0.018289437517523766,
      -0.01913268119096756,
      0.03448581323027611,
      0.03708699718117714,
      -0.07818025350570679,
      -0.043150950223207474,
      -0.09078066796064377,
      0.0077445548959076405,
      -0.05163511633872986,
      -0.03204169124364853,
      0.040746863931417465,
      -0.009248931892216206,
      -0.017433827742934227,
      -0.049951259046792984,
      0.012363435700535774,
      0.005572868045419455,
      -0.04476606845855713,
      0.07325758785009384,
      -0.009235809557139874,
      -0.09024995565414429,
      -0.034177761524915695,
      0.0007965231779962778,
      -0.007844433188438416,
      -0.016934026032686234,
      -0.04591451212763786,
      0.01337058562785387,
      -0.12489808350801468,
      -0.0438716784119606,
      -0.04654339700937271,
      -0.04408370703458786,
      0.00852309912443161,
      0.04615076631307602,
      -0.00985765177756548,
      0.0018977927975356579,
      -0.0016143927350640297,
      0.04953025281429291,
      0.04482327029109001,
      0.0012433032970875502,
      -0.025522621348500252,
      0.030047839507460594,
      0.028643496334552765,
      -0.016528761014342308,
      -0.10745745897293091,
      0.04209185019135475,
      -0.022029638290405273,
      0.0631752759218216,
      0.11237409710884094,
      -0.02726602554321289,
      -0.009714971296489239,
      0.017802076414227486,
      -0.008221587166190147,
      0.0009778245585039258,
      -0.022824512794613838,
      -0.046255968511104584,
      0.016603941097855568,
      0.003550160676240921,
      -0.04843733087182045,
      -0.016837595030665398,
      -0.09119518101215363,
      -0.009021483361721039,
      -0.0163111574947834,
      0.06820636242628098,
      0.012361578643321991,
      0.039013057947158813,
      0.04671134799718857,
      -0.03636565059423447,
      -0.025671591982245445,
      -0.06700009852647781,
      -0.038520000874996185,
      0.010174011811614037,
      -0.0230376236140728,
      0.003388909390196204,
      -0.025131845846772194,
      0.04797401279211044,
      -0.04346044361591339,
      -0.047183096408843994,
      0.04817819222807884,
      -0.013387277722358704,
      0.042645733803510666,
      0.021772531792521477,
      -0.0024900808930397034,
      -0.0029670335352420807,
      -0.02871464379131794,
      -0.08591730892658234,
      0.05440298467874527,
      -0.009716360829770565,
      -0.024767445400357246,
      -0.034587401896715164,
      -0.06512469053268433,
      -0.014698922634124756,
      0.023072177544236183,
      -0.02439109981060028,
      -0.05447237938642502,
      -0.021895406767725945,
      -0.06545424461364746,
      0.008664638735353947,
      -0.0009147495729848742,
      -0.04576680064201355,
      -0.05026795715093613,
      0.11070965230464935,
      0.010007317177951336,
      -0.026856867596507072,
      0.034363631159067154,
      0.006365232635289431,
      -0.03407694399356842,
      -0.03322076052427292,
      -0.10432785004377365,
      0.005582844372838736,
      0.042822856456041336,
      -0.05139783397316933,
      0.01648634672164917,
      -0.050403207540512085,
      0.02982415445148945,
      0.06776946038007736,
      0.015101318247616291,
      -0.05485987663269043,
      -0.007156550884246826,
      -0.055307336151599884,
      -0.016218803822994232,
      -0.032761625945568085,
      0.12304338812828064,
      -0.02147512510418892,
      0.06540466099977493,
      0.007945561781525612,
      0.02431544102728367,
      -0.053556375205516815,
      0.03931382670998573,
      -0.0628461018204689,
      0.04194922000169754,
      0.05361367017030716,
      0.004506102297455072,
      -0.03103036805987358,
      -0.044237326830625534,
      0.030419809743762016,
      0.04859170317649841,
      0.000375973351765424,
      -0.02273467741906643,
      -0.013874095864593983,
      0.005130701698362827,
      0.05621478334069252,
      -0.05700603500008583,
      0.07904140651226044,
      0.0038234659004956484,
      0.04803482070565224,
      -0.01580602116882801,
      0.0068036471493542194,
      0.04253022000193596,
      0.02694825269281864,
      0.027466919273138046,
      0.042646389454603195,
      0.013027391396462917,
      0.005517900455743074,
      0.018969343975186348,
      -0.028571950271725655,
      0.006219238042831421,
      0.01101736817508936,
      -0.03096909075975418,
      -0.002369149122387171,
      -0.0025169719010591507,
      -0.08324229717254639,
      0.036587368696928024,
      -0.018251441419124603,
      -0.10803831368684769,
      0.07761629670858383,
      -0.05210801586508751,
      -0.00394568732008338,
      0.029305513948202133,
      -0.07975424826145172,
      0.022243276238441467,
      -0.03660363331437111,
      -0.02399941347539425,
      -0.061417825520038605,
      0.0766940638422966,
      -0.10672160983085632,
      -0.011624408885836601,
      0.0039073810912668705,
      0.010149306617677212,
      -0.012732673436403275,
      -0.01273201685398817,
      0.028270697221159935,
      -0.023654675111174583,
      0.0757325142621994,
      0.005458885803818703,
      0.06962301582098007,
      -0.023129045963287354,
      0.015289895236492157,
      -0.05346265062689781,
      -0.051719702780246735,
      0.049118123948574066,
      -0.05171683430671692,
      -0.010485916398465633,
      0.01532240305095911,
      0.029824601486325264,
      0.02441501058638096,
      0.08088723570108414,
      0.0036592883989214897,
      -0.0006845032330602407,
      -0.06948814541101456,
      0.006257809232920408,
      -0.0063529303297400475,
      -0.05671323463320732,
      -0.05658810958266258,
      0.10898727923631668,
      -0.029803548008203506,
      0.0036718430928885937,
      -0.05646758899092674,
      -0.0032569461036473513,
      0.005954524502158165,
      -0.020028376951813698,
      0.01988445781171322,
      0.1054428443312645,
      -0.0429394356906414,
      0.057069722563028336,
      -0.01747451163828373,
      0.050409913063049316,
      0.0728418231010437,
      -0.011627177707850933,
      -0.002308005467057228,
      -0.03123881295323372,
      -0.03822445124387741,
      -0.11624275147914886,
      -0.012905184179544449,
      -0.12458435446023941,
      -0.053693875670433044,
      0.0436515286564827,
      -0.061744414269924164,
      -0.013568928465247154,
      0.054235655814409256,
      -0.004824885632842779,
      -0.01106822956353426,
      -0.035322144627571106,
      0.09431982785463333,
      0.04429536685347557,
      0.023080848157405853,
      -0.04249826446175575,
      0.05739289149641991,
      0.00604855315759778,
      -0.047980695962905884,
      0.040933091193437576,
      -0.00711947912350297,
      0.0015271393349394202,
      0.04416206106543541,
      0.039278674870729446,
      0.0053371526300907135,
      0.05198269709944725,
      0.01769719272851944,
      0.002355583943426609,
      0.018524644896388054,
      0.044381510466337204,
      0.004235941916704178,
      -0.02271346002817154,
      -0.018378598615527153,
      0.08281855285167694,
      -0.0010077558690682054,
      -0.01765195094048977,
      -0.020656244829297066,
      0.04869644716382027,
      -0.026754453778266907,
      0.01935885287821293,
      0.0357830747961998,
      0.024898407980799675,
      -0.0724351555109024,
      -0.025360435247421265,
      0.009379757568240166,
      0.025090331211686134,
      0.07024016231298447,
      0.09998750686645508,
      -0.02332746982574463,
      -0.010741809383034706,
      -0.02642979472875595,
      0.03165855258703232,
      0.01988500915467739,
      0.07765296846628189,
      0.0076307328417897224,
      -0.022741341963410378,
      -0.00521898316219449,
      -0.10383767634630203,
      0.01741359382867813,
      -0.016036443412303925,
      -0.035542961210012436,
      0.03105946257710457,
      -0.06317424029111862,
      -0.021889038383960724,
      -0.019110912457108498,
      0.01985497772693634,
      0.06096714362502098,
      0.07400945574045181,
      -0.06848657131195068,
      0.0637568011879921,
      -0.06655806303024292,
      0.06538625061511993,
      0.02459384873509407,
      -0.008504227735102177,
      0.0767417699098587,
      0.03276296705007553,
      -0.09065388143062592,
      0.07845628261566162,
      0.007770464289933443,
      -0.024910736829042435,
      0.004134131129831076,
      -0.04521336778998375,
      -0.03200175240635872,
      -0.03433780372142792,
      -0.01959618180990219,
      -0.013943441212177277,
      -0.04497290402650833,
      -0.03873532637953758,
      0.06664208322763443,
      0.027335122227668762,
      0.06594803929328918,
      -0.02837482839822769,
      0.01322296354919672,
      0.06595425307750702,
      -0.03579762578010559,
      -0.034531280398368835,
      -0.02411499246954918,
      -0.03656671196222305,
      0.10023728013038635,
      -0.07982274889945984,
      -0.032171085476875305,
      -0.0038175375666469336,
      0.04681351035833359,
      0.020000945776700974,
      0.00553567660972476,
      0.033303141593933105,
      0.05715963989496231,
      0.012842145748436451,
      -0.014918617904186249,
      -0.02570362761616707,
      0.056793659925460815,
      -0.024753665551543236,
      0.019845198839902878,
      0.020518751814961433,
      -0.03910556435585022,
      0.015098090283572674,
      0.11681798845529556,
      -0.022757040336728096,
      -0.04131878539919853,
      0.07946997135877609,
      -0.09620139747858047,
      -0.10781046748161316,
      0.05488404631614685,
      0.018211619928479195,
      -0.004131332039833069,
      -0.00034371274523437023,
      -0.03696030005812645,
      0.019790731370449066,
      0.03417119011282921,
      0.06069696694612503,
      -0.03837288171052933,
      -0.05539003759622574,
      0.031829237937927246,
      -0.060785166919231415,
      0.014558090828359127,
      -0.027252554893493652,
      -0.03485456854104996,
      -0.05409446358680725,
      -0.03132651746273041,
      0.04800168797373772,
      0.0009488257928751409,
      -0.04102202132344246,
      -0.021016759797930717,
      0.03685450553894043,
      -0.07972217351198196,
      -0.07513792812824249,
      0.025886233896017075,
      0.02949712425470352,
      -0.018486183136701584,
      0.028451556339859962,
      0.10518303513526917,
      -0.0792442113161087,
      -0.018961071968078613,
      0.01180141419172287,
      -0.005937666166573763,
      0.040990766137838364,
      0.01994858868420124,
      -0.04920579493045807,
      -0.09433264285326004,
      -0.11192268133163452,
      -0.05197616294026375,
      -0.0024890790227800608,
      0.018469784408807755,
      -0.06228816881775856,
      0.05352713540196419,
      -0.026768159121274948,
      0.05491957440972328,
      -0.04668458551168442,
      0.04381170868873596,
      -0.0350956916809082,
      0.0507044643163681,
      -0.0794280394911766,
      0.1465758979320526,
      0.03258160501718521,
      -0.10190063714981079,
      -0.02716093137860298,
      -0.0925515741109848,
      -0.009978575631976128,
      0.010009489953517914,
      -0.04176972433924675,
      -0.005757515784353018,
      0.0020281467586755753,
      -0.019948145374655724,
      0.021465005353093147,
      0.020647307857871056,
      -0.006188620813190937,
      0.060833875089883804,
      0.006718893069773912,
      -0.05401157587766647,
      0.061791714280843735,
      -0.051007892936468124,
      0.013553197495639324,
      -0.05054962635040283,
      -0.021747464314103127,
      0.046306729316711426,
      -0.03587333485484123,
      -0.028160974383354187,
      -0.05834221839904785,
      0.04255375638604164,
      0.016979670152068138,
      0.04874812439084053,
      -0.013011603616178036,
      -0.033015377819538116,
      0.03300589695572853,
      -0.06156966835260391,
      0.07154298573732376,
      0.011693750508129597,
      -0.046947598457336426,
      -0.07442601770162582,
      0.006972840055823326,
      0.01649247668683529,
      -0.03369232267141342,
      0.06015993282198906,
      0.006814854685217142,
      -0.04997096210718155,
      0.027582265436649323,
      -0.0276486873626709,
      -0.030651159584522247,
      0.0354912206530571,
      -0.05131973698735237,
      0.004546733573079109,
      0.04095035791397095,
      0.08136549592018127,
      0.046933989971876144,
      -0.023337749764323235,
      0.002741037169471383,
      0.0051926495507359505,
      0.049388062208890915,
      -0.006512337364256382,
      -0.0395827479660511,
      -0.05403110757470131,
      -0.044851820915937424,
      -0.051043447107076645,
      -0.06580831855535507,
      -0.10525721311569214,
      0.023448674008250237,
      -0.02263832837343216,
      -0.021945590153336525,
      0.05456283688545227,
      0.03482990339398384,
      -0.02775566838681698,
      0.002671001013368368,
      0.05596843734383583,
      -0.013042265549302101,
      -0.026614388450980186,
      0.07104747742414474,
      -0.11148836463689804,
      -0.02079150639474392,
      0.013651474379003048
    ],
    [
      -0.022718114778399467,
      -0.0024027989711612463,
      0.01607564464211464,
      0.0019508328987285495,
      -0.010390864685177803,
      0.0038817350286990404,
      -0.017498984932899475,
      -0.10485514998435974,
      0.012910730205476284,
      0.027759524062275887,
      -0.008004690520465374,
      0.04318637773394585,
      -0.01890651136636734,
      0.002227802062407136,
      0.05717722699046135,
      0.0906960666179657,
      0.0787341520190239,
      -0.012934464029967785,
      0.012017184868454933,
      0.0538579486310482,
      0.07096501439809799,
      0.04288794845342636,
      0.0029111553449183702,
      -0.019884759560227394,
      0.06397954374551773,
      0.002260514535009861,
      -0.026957066729664803,
      0.05015837401151657,
      0.019593754783272743,
      0.007139779161661863,
      0.0613119937479496,
      -0.04414442181587219,
      -0.05036585032939911,
      -0.037527259439229965,
      -0.01770082116127014,
      -0.0719493106007576,
      -0.03546467795968056,
      -0.0682675689458847,
      -0.05129285156726837,
      0.012038550339639187,
      0.05884488299489021,
      0.09296928346157074,
      -0.06426350772380829,
      -0.004782261326909065,
      -0.03235150873661041,
      0.015767909586429596,
      -0.06154210492968559,
      -0.0019255622755736113,
      -0.022992663085460663,
      0.0016027458477765322,
      0.012453333474695683,
      -0.022320164367556572,
      0.06816505640745163,
      -0.03459194675087929,
      -0.005752816330641508,
      0.03863462805747986,
      -0.014484798535704613,
      -0.01127639226615429,
      0.0709194540977478,
      -0.016004273667931557,
      0.04051555320620537,
      -0.027810998260974884,
      -0.06407386064529419,
      -0.02774876356124878,
      -0.0017389447893947363,
      0.0094371372833848,
      0.013037040829658508,
      0.04485427588224411,
      0.009770316071808338,
      0.05657845363020897,
      0.004838771186769009,
      -0.015351352281868458,
      -0.062023378908634186,
      0.03063448704779148,
      -0.047391023486852646,
      -0.0705375224351883,
      0.03564261272549629,
      0.05084057152271271,
      0.0444980151951313,
      -0.004460768774151802,
      0.034484442323446274,
      0.04093914106488228,
      -0.014975707046687603,
      0.08621636033058167,
      0.020549539476633072,
      0.06353738158941269,
      0.03149415925145149,
      0.002471132902428508,
      0.029000062495470047,
      0.04248694330453873,
      0.02706972137093544,
      -0.03120172768831253,
      0.01577875204384327,
      -0.051195915788412094,
      -0.04160526767373085,
      -0.011382169090211391,
      -0.009531183168292046,
      0.010021224617958069,
      -0.026347804814577103,
      -0.052914369851350784,
      -0.07004523277282715,
      -0.00606063986197114,
      -0.03555839881300926,
      -0.01747249811887741,
      0.05261443182826042,
      0.02207808941602707,
      0.04729052260518074,
      0.04564657807350159,
      0.08078696578741074,
      -0.011560651473701,
      -0.07920508831739426,
      -0.021683083847165108,
      0.06700301170349121,
      0.08222264796495438,
      -0.01586155779659748,
      0.052119091153144836,
      -0.024929288774728775,
      0.0322270393371582,
      0.016608823090791702,
      0.006036786828190088,
      0.03414500504732132,
      -0.02366454154253006,
      0.017331644892692566,
      -0.01810859702527523,
      -0.07286810874938965,
      0.017468245700001717,
      0.020848041400313377,
      0.005014913156628609,
      -0.07544037699699402,
      0.01876731961965561,
      -0.030201636254787445,
      0.039119280874729156,
      0.05538858473300934,
      0.05394173040986061,
      0.010898043401539326,
      0.009867132641375065,
      -0.09278935939073563,
      0.0412248894572258,
      0.0017735682195052505,
      -0.03706740587949753,
      -0.012258438393473625,
      -0.03247477486729622,
      -0.016222944483160973,
      0.0022113921586424112,
      -0.023276053369045258,
      0.01968487538397312,
      0.018483975902199745,
      -0.005980830639600754,
      -0.017810314893722534,
      -0.01714055798947811,
      -0.04615570604801178,
      -0.005314444657415152,
      -0.044289421290159225,
      -0.03983244672417641,
      -0.11625321209430695,
      -1.5408322724397294e-05,
      -0.026316622272133827,
      0.0019408668158575892,
      -0.02305082231760025,
      7.085827382979915e-05,
      -0.06774245947599411,
      -0.05998443439602852,
      -0.00450003519654274,
      0.03459836542606354,
      -0.009121162816882133,
      0.06050136312842369,
      -0.022153334692120552,
      -0.027057290077209473,
      0.11476482450962067,
      0.019793910905718803,
      -0.08891764283180237,
      -0.03108755126595497,
      0.05548953264951706,
      0.008172868750989437,
      0.036511845886707306,
      -0.02564362622797489,
      0.043264519423246384,
      0.07946377992630005,
      0.024169830605387688,
      -0.044774945825338364,
      0.018902726471424103,
      0.020814279094338417,
      -0.06868981570005417,
      -0.0031978427432477474,
      0.06867274641990662,
      -0.029438210651278496,
      0.0860946998000145,
      0.00735242385417223,
      0.03839383274316788,
      -0.05736474320292473,
      0.04386560618877411,
      -0.05701931565999985,
      -0.02917875163257122,
      0.03262483328580856,
      -0.07833045721054077,
      -0.03596442565321922,
      -0.02794683910906315,
      0.0019149461295455694,
      -0.012812186032533646,
      0.08894935250282288,
      -0.04766843467950821,
      -0.04830292984843254,
      0.013719180598855019,
      0.00690149562433362,
      -0.04015100747346878,
      -0.025207333266735077,
      -0.023296667262911797,
      0.042727723717689514,
      -0.0033145793713629246,
      -0.04713889956474304,
      -0.011500922031700611,
      -0.028988495469093323,
      -0.0014940855326130986,
      0.002215608721598983,
      -0.02334252931177616,
      -0.08088448643684387,
      0.027954746037721634,
      -0.011516661383211613,
      -0.08927766978740692,
      0.018912423402071,
      0.036535561084747314,
      -0.038988783955574036,
      -0.12546981871128082,
      -0.02538430318236351,
      0.015530099160969257,
      -0.0011208122596144676,
      0.006052474491298199,
      0.037401966750621796,
      0.03067740984261036,
      0.11888182908296585,
      0.04620250687003136,
      0.06531790643930435,
      -0.06693330407142639,
      -0.012803998775780201,
      0.02656043879687786,
      -0.01302612479776144,
      0.0017113820649683475,
      -0.05280938744544983,
      -0.04851469025015831,
      0.058672141283750534,
      0.011227807961404324,
      0.024847257882356644,
      0.003031852189451456,
      0.0740659162402153,
      0.0765068456530571,
      0.020909395068883896,
      -0.060850996524095535,
      0.022794604301452637,
      -0.022169696167111397,
      0.030936485156416893,
      -0.09338124841451645,
      -0.02805609628558159,
      0.038015156984329224,
      0.07129057496786118,
      -0.07593291997909546,
      -0.05782046914100647,
      0.06828775256872177,
      0.03265130892395973,
      0.009535497054457664,
      -0.026753941550850868,
      0.0075893159955739975,
      -0.0158991776406765,
      0.058601900935173035,
      0.007175298873335123,
      0.051849786192178726,
      -0.025707878172397614,
      -0.007675552275031805,
      0.0226267222315073,
      0.027501195669174194,
      -0.04177146032452583,
      -0.0071705118753015995,
      0.03414377570152283,
      -0.04486841708421707,
      0.038748957216739655,
      -0.004165456164628267,
      0.07842590659856796,
      0.01352724153548479,
      0.009393350221216679,
      -0.023934699594974518,
      -0.0828644186258316,
      -0.00373867922462523,
      -0.09327886998653412,
      -0.03117459826171398,
      -0.00474115926772356,
      0.014435839839279652,
      -0.01568356342613697,
      -0.04055871441960335,
      0.019237462431192398,
      -0.0313582643866539,
      -0.06244361028075218,
      -0.052788835018873215,
      -0.05444137752056122,
      0.006960536353290081,
      0.03318604826927185,
      0.07928049564361572,
      0.007552056107670069,
      0.11611657589673996,
      0.037449657917022705,
      -0.029491262510418892,
      0.0463690385222435,
      0.00010069307609228417,
      -0.10404501110315323,
      -0.02519197389483452,
      0.00513825286179781,
      -0.009275767020881176,
      0.026282621547579765,
      0.0335274301469326,
      -0.06435801088809967,
      -0.016372425481677055,
      -0.03024992346763611,
      0.09443102777004242,
      0.013670725747942924,
      0.01752305217087269,
      0.01886194385588169,
      0.046931371092796326,
      0.028940360993146896,
      -0.004891380202025175,
      -0.034368615597486496,
      0.00831800326704979,
      0.01632445491850376,
      0.05182850360870361,
      0.09581264108419418,
      0.0026570928748697042,
      0.028991153463721275,
      0.05263369902968407,
      0.016473405063152313,
      0.02123085781931877,
      -0.01898174360394478,
      0.03831324726343155,
      0.007838940247893333,
      0.0010297559201717377,
      -0.02683749608695507,
      -0.010302949696779251,
      -0.03368733823299408,
      0.01575576514005661,
      -0.009328574873507023,
      -0.05282910168170929,
      0.019504748284816742,
      0.06342111527919769,
      0.042044755071401596,
      -0.055958379060029984,
      0.001604233286343515,
      0.05414438992738724,
      0.05268917605280876,
      -0.02880680561065674,
      -0.07174553722143173,
      0.005833282135426998,
      0.045294322073459625,
      -0.004183069337159395,
      0.011407018639147282,
      0.003883030731230974,
      0.004936045967042446,
      -0.03940825164318085,
      0.0012652998557314277,
      0.09954537451267242,
      -0.035172805190086365,
      -0.015370494686067104,
      -0.05477364361286163,
      0.06766550242900848,
      0.04237786680459976,
      -0.005386374890804291,
      0.10272581875324249,
      -0.02623995579779148,
      -0.02083883062005043,
      0.0009954015258699656,
      0.022807862609624863,
      0.029133077710866928,
      0.01761738397181034,
      0.028706924989819527,
      -0.04289118945598602,
      -0.017693430185317993,
      -0.02568311057984829,
      0.008205277845263481,
      -0.09711786359548569,
      -0.013697524555027485,
      -0.03399919345974922,
      0.06109576299786568,
      -0.027817940339446068,
      0.04037037491798401,
      0.0074916561134159565,
      -0.07846609503030777,
      0.023744871839880943,
      0.030523231253027916,
      0.005437220446765423,
      0.02903830073773861,
      -0.0325976237654686,
      0.0010133106261491776,
      -0.026861589401960373,
      -0.006643844768404961,
      -0.022593514993786812,
      0.044436369091272354,
      0.04110509157180786,
      0.023038256913423538,
      -0.00654261652380228,
      0.049588121473789215,
      0.04126844182610512,
      0.08145038783550262,
      0.01089464407414198,
      -0.05479009822010994,
      -0.05460546910762787,
      -0.04989693686366081,
      0.04907545819878578,
      0.011982129886746407,
      0.046436749398708344,
      -0.041399918496608734,
      0.000720601063221693,
      -0.04682854935526848,
      0.005239911377429962,
      -0.09322361648082733,
      0.011135263368487358,
      -0.06923285871744156,
      0.031060708686709404,
      0.05038181319832802,
      0.026033887639641762,
      0.00420629046857357,
      0.006955145858228207,
      0.04617754742503166,
      -0.029368676245212555,
      0.010114178992807865,
      0.0794740840792656,
      -0.016383010894060135,
      -0.008860603906214237,
      0.0405673123896122,
      0.016351502388715744,
      0.042766980826854706,
      0.013312483206391335,
      0.016009893268346786,
      -0.05581769719719887,
      0.03445246070623398,
      -0.022413356229662895,
      -0.009746544994413853,
      0.027874335646629333,
      -0.006882431451231241,
      0.013197243213653564,
      0.010149000212550163,
      -0.011104206554591656,
      -0.023760393261909485,
      0.03728601336479187,
      -0.00846329890191555,
      0.009255156852304935,
      0.03261670097708702,
      0.08616845309734344,
      -0.019453013315796852,
      0.06392854452133179,
      0.01986812613904476,
      -0.028161602094769478,
      -0.005610656924545765,
      0.01711374893784523,
      0.034520044922828674,
      -0.04340579733252525,
      0.020880114287137985,
      -0.024674134328961372,
      -0.009319878183305264,
      -0.008388344198465347,
      0.02300935424864292,
      0.050823964178562164,
      0.03223401680588722,
      -0.029535507783293724,
      0.07436300069093704,
      -0.050143200904130936,
      -0.04974798485636711,
      -0.08814457803964615,
      0.06158759817481041,
      -0.05533918738365173,
      0.011423262767493725,
      -0.06676249206066132,
      0.06605144590139389,
      0.01504275482147932,
      -0.09867798537015915,
      -0.05500849708914757,
      0.012773833237588406,
      -0.045836225152015686,
      0.08229225128889084,
      -0.009970365092158318,
      -0.05668975040316582,
      -0.011430561542510986,
      -0.13840486109256744,
      0.030006716027855873,
      -0.019199980422854424,
      0.02694707177579403,
      -0.03875656798481941,
      0.04049185290932655,
      0.04319849610328674,
      0.03704427182674408,
      0.07783675193786621,
      0.0478815883398056,
      -0.08009262382984161,
      -0.052870504558086395,
      -0.03532380610704422,
      0.009564568288624287,
      -0.014356916770339012,
      -0.021626511588692665,
      0.009477471932768822,
      -0.034596532583236694,
      -0.02991476096212864,
      0.10510794073343277,
      0.009804649278521538,
      0.04312453418970108,
      0.0015337166842073202,
      0.03061174787580967,
      0.04891533404588699,
      -0.02548155188560486,
      0.0956658273935318
    ],
    [
      -0.07836306095123291,
      0.015389922074973583,
      0.03582383692264557,
      0.015568733215332031,
      -0.005473391618579626,
      0.04807966575026512,
      0.03785126283764839,
      -0.011213630437850952,
      0.045100774616003036,
      -0.05061200633645058,
      0.05690363422036171,
      0.07986792176961899,
      0.027982451021671295,
      -0.023038595914840698,
      -0.03227441757917404,
      -0.0019464362412691116,
      -0.022069649770855904,
      -0.0778760015964508,
      -0.06536399573087692,
      -0.05376448854804039,
      0.09061948210000992,
      0.010251522995531559,
      -0.034436024725437164,
      0.04369719326496124,
      0.01765124313533306,
      -0.029113585129380226,
      0.005494627170264721,
      0.023026756942272186,
      0.058469220995903015,
      0.02228897623717785,
      -0.09036043286323547,
      -0.026490820571780205,
      -0.0025021955370903015,
      -0.11123231798410416,
      -0.028126612305641174,
      0.026673078536987305,
      0.04621550813317299,
      -0.12019673734903336,
      0.08001899719238281,
      -0.0056072017177939415,
      -0.012997385114431381,
      0.00654304726049304,
      0.02368142083287239,
      -0.03643825650215149,
      0.03995489701628685,
      -0.014095681719481945,
      0.03578593209385872,
      -0.012100879102945328,
      -0.02509213611483574,
      -0.06244857981801033,
      0.008281448855996132,
      -0.08004410564899445,
      0.008998667821288109,
      -0.044106416404247284,
      -0.05592424422502518,
      -0.03228192403912544,
      -0.03770183026790619,
      -0.06383243203163147,
      0.015553743578493595,
      0.07381108403205872,
      -0.05770942196249962,
      -0.16027580201625824,
      -0.06115853041410446,
      0.021404333412647247,
      -0.015523566864430904,
      -0.0111992247402668,
      0.035766858607530594,
      0.06482905149459839,
      -0.06098569929599762,
      0.020377591252326965,
      -0.0015187259996309876,
      0.01864558644592762,
      -0.04092559590935707,
      0.0023161291610449553,
      0.04382500797510147,
      -0.007592662237584591,
      -0.05333585664629936,
      0.046597663313150406,
      0.0603594109416008,
      -0.062213052064180374,
      -0.015732847154140472,
      0.012170861475169659,
      -0.0436042845249176,
      0.011263248510658741,
      0.07762467116117477,
      -0.032962948083877563,
      -0.004182940814644098,
      -0.013182179071009159,
      -0.024466730654239655,
      -0.045206040143966675,
      -0.03942267969250679,
      -0.04352165758609772,
      -0.07558906823396683,
      -0.04675985872745514,
      0.06615076214075089,
      0.06525923311710358,
      0.009332501329481602,
      0.05434569716453552,
      0.04430687427520752,
      -0.0719788447022438,
      0.02600734494626522,
      0.00236715585924685,
      0.017388230189681053,
      0.020961076021194458,
      -0.011940712109208107,
      0.023679094389081,
      -0.024198615923523903,
      -0.005776189733296633,
      0.02169537916779518,
      0.03098820149898529,
      -0.09668800979852676,
      0.0006178750190883875,
      0.04714634642004967,
      -0.02015031687915325,
      -0.03546151891350746,
      0.0372888557612896,
      0.07900058478116989,
      -0.04114596173167229,
      0.11115946620702744,
      0.06180870160460472,
      0.04305998608469963,
      0.09721124917268753,
      -0.0379047729074955,
      -0.0996837317943573,
      -0.006896245293319225,
      0.018271757289767265,
      -0.04605848714709282,
      0.010371429845690727,
      -0.028991056606173515,
      0.0026081493124365807,
      -0.04877082630991936,
      -0.0425591841340065,
      -0.002441795077174902,
      -0.02811666578054428,
      -0.13608002662658691,
      -0.10206758975982666,
      -0.017413178458809853,
      -0.03311358019709587,
      -0.006679906975477934,
      0.02632981911301613,
      0.007383936550468206,
      -0.05201982334256172,
      0.0020949719473719597,
      -0.04642432555556297,
      -0.03632819280028343,
      -0.014740733429789543,
      -0.013233992271125317,
      -0.006991170812398195,
      -0.036393724381923676,
      -0.044557180255651474,
      0.06150506064295769,
      0.03790639340877533,
      0.03153879940509796,
      0.06011013686656952,
      -0.015364410355687141,
      -0.028504658490419388,
      0.0797589123249054,
      -0.013366805389523506,
      -0.009334981441497803,
      0.11149799823760986,
      -0.009917168878018856,
      0.022857196629047394,
      -0.011280914768576622,
      -0.031288791447877884,
      0.035160135477781296,
      0.042463161051273346,
      0.0025905470829457045,
      -0.018302936106920242,
      0.00539209833368659,
      0.00937500037252903,
      -0.05099337548017502,
      -0.005710331257432699,
      -0.02859731763601303,
      -0.07448185980319977,
      0.05693892762064934,
      -0.001257624477148056,
      0.0992632508277893,
      0.04476463794708252,
      -0.055080801248550415,
      -0.06252887845039368,
      0.04653994366526604,
      0.030728371813893318,
      -0.05830445513129234,
      0.07178480923175812,
      -0.028072644025087357,
      0.004690846893936396,
      -0.03691895306110382,
      0.023494180291891098,
      0.041635286062955856,
      -0.06833337992429733,
      -0.023266615346074104,
      -0.04147958755493164,
      0.1043611466884613,
      -0.022046789526939392,
      0.02181185409426689,
      0.002002284163609147,
      -0.019194943830370903,
      0.022385284304618835,
      -0.05097491294145584,
      0.03809342160820961,
      0.012659812346100807,
      0.01924894191324711,
      -0.0013996902853250504,
      0.07345259934663773,
      0.05341115593910217,
      0.0033161670435220003,
      -0.10807554423809052,
      -0.047825101763010025,
      -0.03482269495725632,
      0.05760687589645386,
      0.04326785355806351,
      0.08874834328889847,
      -0.01821381039917469,
      -0.011028068140149117,
      0.03780706599354744,
      -0.042223162949085236,
      0.06333453208208084,
      0.08238541334867477,
      0.0011572368675842881,
      -0.05156129226088524,
      -0.07768581807613373,
      -0.07471930980682373,
      0.007172540295869112,
      0.06836593151092529,
      -0.05097990483045578,
      0.0020457778591662645,
      -0.02367394044995308,
      -0.023698007687926292,
      0.02115495689213276,
      0.04682391136884689,
      0.028476044535636902,
      -0.049094997346401215,
      0.007258877623826265,
      -0.05993889272212982,
      -0.06804255396127701,
      0.005647576414048672,
      0.021418238058686256,
      0.04265686869621277,
      -0.05421757698059082,
      0.0963737741112709,
      -0.0407625250518322,
      -0.041732240468263626,
      -0.002333135576918721,
      0.06076713651418686,
      0.016891255974769592,
      0.08531878888607025,
      0.009208884090185165,
      -0.03714292123913765,
      0.026138776913285255,
      0.02439422905445099,
      0.003996466286480427,
      -0.08685509860515594,
      0.02157220058143139,
      0.0621744729578495,
      0.013981147669255733,
      -0.06122606620192528,
      0.003892060834914446,
      0.01841263473033905,
      0.00024818142992444336,
      -0.026470134034752846,
      -0.10426478832960129,
      0.05772757530212402,
      0.018279336392879486,
      -0.020954860374331474,
      -0.03805932402610779,
      -0.036916982382535934,
      -0.026976237073540688,
      0.03244715556502342,
      -0.024472549557685852,
      0.013432642444968224,
      -0.040637750178575516,
      -0.03483973443508148,
      -0.019617173820734024,
      -0.04783904179930687,
      -0.0027149186935275793,
      -0.0461152121424675,
      -0.06308690458536148,
      -0.008320202119648457,
      -0.08201809227466583,
      0.031014015898108482,
      0.05583077669143677,
      0.06218118220567703,
      -0.009334705770015717,
      -0.07156338542699814,
      -0.03149019181728363,
      0.04512707516551018,
      -0.035369258373975754,
      0.06450706720352173,
      -0.08939951658248901,
      0.009477880783379078,
      -0.0045350417494773865,
      -0.02102654241025448,
      0.04107062891125679,
      -0.04628966376185417,
      -0.0033947911579161882,
      0.09544113278388977,
      -0.02036101743578911,
      -0.03126709535717964,
      0.06524135172367096,
      0.026566773653030396,
      -0.08207441121339798,
      -0.10048679262399673,
      0.033628836274147034,
      0.0031493522692471743,
      -0.03444181755185127,
      -0.018543727695941925,
      0.022110147401690483,
      0.013973371125757694,
      -0.02087363414466381,
      0.003643779084086418,
      0.04509544000029564,
      -0.09522561728954315,
      0.031068069860339165,
      -0.0490138903260231,
      0.038105759769678116,
      -0.0942796915769577,
      -0.048199836164712906,
      0.074884794652462,
      0.017702016979455948,
      -0.029984349384903908,
      0.013975946232676506,
      -0.08574182540178299,
      0.054837338626384735,
      -0.042715590447187424,
      0.04404705390334129,
      0.07272010296583176,
      -0.0772102028131485,
      0.06907586008310318,
      -0.02087288536131382,
      0.09299859404563904,
      -0.027959227561950684,
      0.018859853968024254,
      0.06374223530292511,
      -0.03779119253158569,
      -0.03884143382310867,
      -0.09783487021923065,
      -0.012004555203020573,
      0.02444360964000225,
      0.043821752071380615,
      -0.022182807326316833,
      0.04010051488876343,
      0.052785806357860565,
      0.004033718258142471,
      -0.0332280732691288,
      -0.010326924733817577,
      0.017697712406516075,
      -0.05235299840569496,
      0.04067302495241165,
      0.04579850658774376,
      0.07670213282108307,
      0.011727845296263695,
      0.021885083988308907,
      -0.018056459724903107,
      -0.05083032697439194,
      -0.014513670466840267,
      0.006798978429287672,
      0.0007475366583094001,
      0.0407610759139061,
      -0.008926662616431713,
      0.020164478570222855,
      -0.08296072483062744,
      0.003849532688036561,
      0.015834098681807518,
      0.042735688388347626,
      0.026561912149190903,
      0.04087463766336441,
      0.012241183780133724,
      0.022855248302221298,
      0.03839363902807236,
      0.08928641676902771,
      0.03555435314774513,
      -0.09166686981916428,
      -0.0753965973854065,
      -0.06864821910858154,
      0.04386383295059204,
      0.02309294603765011,
      0.08418212831020355,
      -0.021053852513432503,
      0.0016195407370105386,
      0.009095104411244392,
      0.06950709968805313,
      -0.018677175045013428,
      0.03607429563999176,
      0.030521489679813385,
      0.027927987277507782,
      -0.041613057255744934,
      -0.005972845945507288,
      -0.011745864525437355,
      -0.0452665239572525,
      0.022137479856610298,
      0.013142285868525505,
      0.024754278361797333,
      -0.02007083222270012,
      0.026983221992850304,
      0.02379937469959259,
      -0.022693486884236336,
      0.018131986260414124,
      0.03821505606174469,
      -0.04575701057910919,
      0.00620471965521574,
      -0.004691802430897951,
      0.03760071471333504,
      -0.010552558116614819,
      0.0354464091360569,
      -0.03467164561152458,
      -0.01110934466123581,
      0.012287546880543232,
      -0.03619873523712158,
      0.007934460416436195,
      0.008127455599606037,
      -0.06729613989591599,
      -0.005364114884287119,
      -0.023779628798365593,
      -0.012943916022777557,
      -0.025576967746019363,
      -0.16505800187587738,
      0.059761036187410355,
      -0.029826227575540543,
      0.025055810809135437,
      -0.06647884845733643,
      -0.06380122900009155,
      0.019683413207530975,
      -0.013714221306145191,
      0.03789745271205902,
      0.03578580915927887,
      0.002870488679036498,
      0.01246807910501957,
      -0.03594161197543144,
      -0.04078112542629242,
      -0.0356048047542572,
      -0.021699823439121246,
      -0.01725979894399643,
      0.013237844221293926,
      -0.0082031125202775,
      -0.008788609877228737,
      0.05757248029112816,
      0.07763227820396423,
      0.018938161432743073,
      0.09256641566753387,
      -0.05660165846347809,
      -0.05612829700112343,
      0.013804220594465733,
      -0.06400260329246521,
      -0.0513741634786129,
      -0.03373386338353157,
      0.05400375649333,
      0.010445760563015938,
      -0.01390525046736002,
      0.013586016371846199,
      0.024682803079485893,
      0.0407181940972805,
      -0.013995463959872723,
      0.03909457102417946,
      0.005180866923183203,
      -0.03583989664912224,
      -0.02072293497622013,
      -0.045856017619371414,
      0.04324863478541374,
      0.058440688997507095,
      -0.054881658405065536,
      0.0002497845562174916,
      0.014746030792593956,
      0.0183027982711792,
      -0.029740601778030396,
      0.01052738819271326,
      -0.023376477882266045,
      0.0070050437934696674,
      -0.048729222267866135,
      -0.029713839292526245,
      -0.08357121050357819,
      -0.01468597911298275,
      0.0325423926115036,
      0.01751047931611538,
      -0.008346142247319221,
      -0.016435474157333374,
      -0.06434842944145203,
      0.031412139534950256,
      0.08249763399362564,
      -0.012705553323030472,
      0.06915660202503204,
      0.006824372801929712,
      -0.018856747075915337,
      0.08031956106424332,
      -0.07387182861566544,
      0.03763110935688019,
      -0.021754207089543343,
      0.039890218526124954,
      0.03429317846894264,
      -0.07455277442932129,
      -0.03221294283866882,
      -0.021972302347421646,
      -0.009552791714668274,
      -0.05593014508485794,
      0.04651105776429176,
      -0.050135254859924316,
      -0.04763909801840782,
      -0.023237058892846107,
      0.027854612097144127,
      0.050382617861032486,
      0.05524835363030434,
      -0.04083573445677757,
      -0.042698971927165985,
      -0.03805195540189743
    ],
    [
      -0.016863996163010597,
      -0.054202377796173096,
      0.006733392830938101,
      -0.042965758591890335,
      -0.05428489297628403,
      -0.012945053167641163,
      -0.037949010729789734,
      0.08509276062250137,
      0.042518358677625656,
      0.019009945914149284,
      0.0605974942445755,
      -0.00011015558266080916,
      -0.0078047169372439384,
      -0.027502989396452904,
      0.019989432767033577,
      0.008432644419372082,
      0.06872213631868362,
      0.017908280715346336,
      0.07296798378229141,
      0.030378976836800575,
      0.02875988371670246,
      -0.028191987425088882,
      -0.035025984048843384,
      -0.029168009757995605,
      -0.07867713272571564,
      0.03849979117512703,
      -0.051361266523599625,
      0.04026045277714729,
      -0.022614633664488792,
      0.022837266325950623,
      0.0061940536834299564,
      -0.00569054763764143,
      -0.009711788035929203,
      -0.05585735663771629,
      0.06800809502601624,
      0.017400996759533882,
      -0.06560203433036804,
      0.0002704935905057937,
      -0.0618688240647316,
      0.001589638995938003,
      -0.03908463940024376,
      -0.05055897682905197,
      -0.009817687794566154,
      -0.05017504841089249,
      -0.02234918810427189,
      -0.047209177166223526,
      0.0913865864276886,
      0.08305729180574417,
      0.08503630012273788,
      -0.03624093160033226,
      0.03380041569471359,
      0.014375886879861355,
      0.009734082967042923,
      0.0413975864648819,
      -0.05392521247267723,
      -0.025007840245962143,
      -0.002648703521117568,
      -0.04499215632677078,
      0.025509126484394073,
      0.040277983993291855,
      -0.11576777696609497,
      0.024154769256711006,
      -0.01651201769709587,
      0.04405752196907997,
      -0.020727699622511864,
      -0.08922751247882843,
      -0.02664930932223797,
      0.04313286766409874,
      0.07393572479486465,
      -0.029891779646277428,
      -0.015557199716567993,
      -0.028950823470950127,
      0.03239636495709419,
      0.007419266272336245,
      -0.03041606768965721,
      -0.0020075791981071234,
      -0.007559039164334536,
      0.024082476273179054,
      -0.07283037155866623,
      0.04582194983959198,
      -0.08901270478963852,
      -0.001504261395893991,
      -0.030887912958860397,
      -0.02382449433207512,
      0.03261847794055939,
      0.05292101576924324,
      0.04987417533993721,
      0.028438514098525047,
      0.020356083288788795,
      0.003442933550104499,
      -0.010690187104046345,
      -0.005529880523681641,
      0.0014036674983799458,
      -0.023645300418138504,
      -0.00039280118653550744,
      -0.11517619341611862,
      -0.005295377690345049,
      -2.9972999982419424e-05,
      0.021894454956054688,
      -0.02864423394203186,
      0.0227070115506649,
      0.03133689612150192,
      -0.044142238795757294,
      -0.0731642097234726,
      -0.0010321874869987369,
      -0.048946309834718704,
      -0.04182833433151245,
      -0.050540272146463394,
      0.01775699481368065,
      0.008208786137402058,
      -0.009491216391324997,
      -0.012941847555339336,
      0.07560767233371735,
      -0.007918369956314564,
      0.012386864982545376,
      0.07419238239526749,
      -0.01127771008759737,
      0.07597210258245468,
      -0.001062852912582457,
      -0.000373801653040573,
      0.04154707491397858,
      -0.030144404619932175,
      0.08283212035894394,
      -0.048490263521671295,
      0.04222549870610237,
      -0.04056176543235779,
      -0.005846852902323008,
      -0.03259892016649246,
      -0.04608214274048805,
      -0.00628150487318635,
      0.03388535976409912,
      -0.014626486226916313,
      -0.09654980152845383,
      -0.020179567858576775,
      0.035108745098114014,
      0.01497427187860012,
      -0.008103981614112854,
      -0.05307888984680176,
      -0.028137557208538055,
      0.01775495335459709,
      -0.004726484417915344,
      -0.018173307180404663,
      -0.0672185942530632,
      0.06886278837919235,
      0.014261852018535137,
      0.0242200568318367,
      0.09444104135036469,
      -0.00265980651602149,
      0.0698910504579544,
      -0.025376826524734497,
      -0.007761120330542326,
      0.04770806431770325,
      -0.04209931939840317,
      0.0815780758857727,
      0.02006908692419529,
      0.04547438025474548,
      -0.007692717481404543,
      -0.06146790459752083,
      -0.07018055766820908,
      -0.017429077997803688,
      -0.034126173704862595,
      0.02242354489862919,
      0.05556686595082283,
      -0.048047129064798355,
      0.023034175857901573,
      -0.0641472339630127,
      -0.0011031406465917826,
      -0.019834356382489204,
      -0.034207943826913834,
      0.011707533150911331,
      0.062343232333660126,
      -0.08790861070156097,
      -0.028226247057318687,
      -0.007060181349515915,
      -0.02763279341161251,
      -0.04988599196076393,
      -0.018565697595477104,
      0.05202129855751991,
      -0.04597131907939911,
      0.01564265601336956,
      -0.00020684018090832978,
      -0.036267612129449844,
      -0.018112992867827415,
      -0.06692676991224289,
      -0.08001744747161865,
      0.026536060497164726,
      -0.14323079586029053,
      -0.0018354248022660613,
      0.04287078604102135,
      0.012566810473799706,
      -0.010393013246357441,
      -0.08627040684223175,
      -0.005117134191095829,
      -0.03241746500134468,
      -0.03259989246726036,
      0.05936778336763382,
      0.07350490987300873,
      0.018418215215206146,
      -0.02808273583650589,
      0.024899374693632126,
      0.029545506462454796,
      0.005125519819557667,
      -0.002222446957603097,
      -0.03169546648859978,
      0.054920658469200134,
      -0.02525639347732067,
      0.06103540584445,
      0.036704692989587784,
      0.003362282644957304,
      -0.0023837138433009386,
      -0.0298855509608984,
      0.06344325840473175,
      0.03217127174139023,
      0.11644154042005539,
      -0.037397198379039764,
      0.060070037841796875,
      0.047950733453035355,
      0.022627422586083412,
      0.017353208735585213,
      0.014505849219858646,
      0.12696139514446259,
      -0.031472254544496536,
      -0.040154967457056046,
      0.04283883795142174,
      -0.05683664232492447,
      0.09866490215063095,
      -0.02614571340382099,
      0.02777956612408161,
      0.01948557235300541,
      -0.042785968631505966,
      -0.051391713321208954,
      -0.10287380963563919,
      -0.05037138983607292,
      -0.06986001133918762,
      0.07700652629137039,
      0.010824361816048622,
      -0.08465094119310379,
      0.04420498013496399,
      -0.04198068752884865,
      -0.03140200674533844,
      -0.05332459136843681,
      0.042133133858442307,
      -0.08128192275762558,
      0.010807438753545284,
      -0.07115203887224197,
      -0.007223675027489662,
      -0.01283535361289978,
      -0.04723507538437843,
      0.003069381695240736,
      0.03971666097640991,
      -0.023367607966065407,
      0.0795099064707756,
      0.05642009526491165,
      0.07207466661930084,
      0.007416900247335434,
      -0.0515594556927681,
      0.04591730609536171,
      -0.07796843349933624,
      0.025440862402319908,
      0.023279575631022453,
      -0.021813495084643364,
      -0.013176235370337963,
      -0.010445158928632736,
      -0.01965543068945408,
      -0.10727240890264511,
      -0.013317820616066456,
      0.02088014781475067,
      -0.0376204252243042,
      -0.09389707446098328,
      0.012102401815354824,
      0.12241873890161514,
      0.05345488712191582,
      0.04750547930598259,
      -0.10250848531723022,
      0.024522099643945694,
      0.08335098624229431,
      0.06812454015016556,
      -0.00302803423255682,
      -0.0001621396077098325,
      0.07311592996120453,
      0.029644813388586044,
      -0.027982786297798157,
      -0.0880904421210289,
      0.000716298003681004,
      0.0677773654460907,
      -0.04238274693489075,
      0.07074471563100815,
      0.010196943767368793,
      0.08756810426712036,
      0.07753390073776245,
      -0.03730012848973274,
      -0.05031730234622955,
      -0.008932836353778839,
      0.03531982749700546,
      0.04390934109687805,
      0.0890013799071312,
      0.07609117031097412,
      0.07618410140275955,
      -0.07140563428401947,
      0.011799495667219162,
      0.03547181934118271,
      0.02504286728799343,
      -0.08191698044538498,
      -0.04427291825413704,
      -0.021523702889680862,
      -0.0033185218926519156,
      -0.04550643265247345,
      0.06242414563894272,
      -0.004682393744587898,
      -0.030226806178689003,
      0.0630626529455185,
      -0.004208003170788288,
      0.07065457850694656,
      0.059366799890995026,
      -0.058753132820129395,
      -0.024251721799373627,
      0.017100200057029724,
      -0.036238763481378555,
      0.003916179295629263,
      -0.003070756094530225,
      -0.030682144686579704,
      0.028433939442038536,
      -0.04914169758558273,
      0.05262412503361702,
      -0.058219064027071,
      -0.011423301883041859,
      0.06383825093507767,
      -0.04550540819764137,
      0.01442831289023161,
      -0.04579981416463852,
      0.0691211000084877,
      -0.028566226363182068,
      -0.01790551468729973,
      -0.03147397190332413,
      0.030041707679629326,
      -0.01013458427041769,
      0.08498886227607727,
      0.04972964897751808,
      -0.016180306673049927,
      0.09716647863388062,
      -0.04503346234560013,
      -0.08124549686908722,
      0.01959245651960373,
      -0.03549011051654816,
      0.028940582647919655,
      0.023470036685466766,
      -0.03279760107398033,
      0.05976838991045952,
      0.019444039091467857,
      -0.03629283607006073,
      0.01721855252981186,
      0.048944827169179916,
      -0.052370451390743256,
      -0.024979086592793465,
      -0.03921784460544586,
      -0.12226399779319763,
      0.01916002668440342,
      -0.002675986848771572,
      0.03897397965192795,
      -0.01834850199520588,
      0.10948923230171204,
      -0.00025223055854439735,
      0.012008273974061012,
      -0.020280148833990097,
      0.011456398293375969,
      -0.037112750113010406,
      0.06615779548883438,
      -0.11728360503911972,
      -0.013508445583283901,
      -0.020483123138546944,
      -0.024176791310310364,
      0.02871757000684738,
      0.005997807718813419,
      0.004372661467641592,
      0.03970377892255783,
      -0.07633381336927414,
      -0.017310982570052147,
      0.050462860614061356,
      -0.005223679821938276,
      -0.05193696543574333,
      0.020387673750519753,
      -0.017409510910511017,
      0.07538720965385437,
      -0.027880089357495308,
      -0.05833637714385986,
      -0.046332091093063354,
      -0.029133794829249382,
      0.04734247177839279,
      0.026835516095161438,
      -0.042594704777002335,
      0.00945108663290739,
      -0.020862026140093803,
      -0.005152265541255474,
      0.05488860607147217,
      0.07032182067632675,
      -0.0243842676281929,
      -0.008159685879945755,
      0.0009157943422906101,
      0.04983929917216301,
      -0.011569860391318798,
      0.002954538445919752,
      -0.007735390681773424,
      -0.026177354156970978,
      0.023124825209379196,
      -0.05281723290681839,
      0.06767339259386063,
      0.028766151517629623,
      0.0019378606230020523,
      -0.06167176365852356,
      -0.022002698853611946,
      -0.006593937519937754,
      -0.042160116136074066,
      0.052367303520441055,
      -0.017186664044857025,
      -0.009545596316456795,
      0.09188614040613174,
      0.009461906738579273,
      -0.0326608344912529,
      0.013634474948048592,
      -0.05566471070051193,
      -0.04866417869925499,
      0.011041546240448952,
      -0.06012802571058273,
      0.06455447524785995,
      -0.03762197867035866,
      0.05888935178518295,
      0.0004228730103932321,
      0.056973040103912354,
      -0.01165499072521925,
      -0.028740055859088898,
      -0.012680518440902233,
      0.03976393863558769,
      0.03342597186565399,
      0.00944035965949297,
      -0.007462668232619762,
      0.02858729660511017,
      -0.048909991979599,
      -0.04364517331123352,
      0.024619072675704956,
      0.029675185680389404,
      -0.06907657533884048,
      0.08970189839601517,
      0.023341041058301926,
      0.05128442868590355,
      0.0029871216975152493,
      0.08127354085445404,
      0.04241267964243889,
      0.014168781228363514,
      -0.06338311731815338,
      -0.017254509031772614,
      -0.047942668199539185,
      -0.028666898608207703,
      -0.013327733613550663,
      0.019748881459236145,
      -0.04119871184229851,
      0.009510439820587635,
      -0.04713355377316475,
      0.06091909110546112,
      -0.02650374546647072,
      0.004045531619340181,
      0.050383131951093674,
      0.007339345756918192,
      -0.02373085543513298,
      0.03395167365670204,
      -0.007656991481781006,
      -0.014096710830926895,
      -0.005367764737457037,
      -0.009693984873592854,
      0.024197116494178772,
      -0.007821735925972462,
      -0.030652664601802826,
      0.06059352681040764,
      -0.024347705766558647,
      0.08080877363681793,
      -0.022429905831813812,
      0.020718034356832504,
      0.023114297538995743,
      -0.013424587436020374,
      0.05409678816795349,
      0.03683686628937721,
      -0.007857614196836948,
      0.08655345439910889,
      -0.01901356130838394,
      -0.01180938072502613,
      -0.05159960314631462,
      -0.02080165036022663,
      0.02886570803821087,
      -0.010756054893136024,
      -0.015342986211180687,
      0.009195821359753609,
      -0.002385444240644574,
      -0.003889376064762473,
      0.024062028154730797,
      -0.08103512972593307,
      0.03551091253757477,
      0.011473293416202068,
      -0.04494776204228401,
      0.013027621433138847,
      0.04796883463859558,
      -0.0031186353880912066,
      -0.019910745322704315,
      0.022207988426089287,
      0.0939602330327034
    ],
    [
      0.000386798259569332,
      0.015103306621313095,
      0.011949135921895504,
      0.018116822466254234,
      -0.018226560205221176,
      -0.040683645755052567,
      0.0714525505900383,
      0.08810551464557648,
      -0.020859351381659508,
      0.041724786162376404,
      0.16738596558570862,
      0.038896817713975906,
      0.02655065432190895,
      -0.03502435237169266,
      -0.02556086704134941,
      0.05700112134218216,
      -0.03143702819943428,
      0.012235547415912151,
      -0.019650958478450775,
      0.07424666732549667,
      -0.006608677562326193,
      -0.05926951393485069,
      0.02279527671635151,
      0.021566899493336678,
      -0.040841877460479736,
      0.011651904322206974,
      0.011476360261440277,
      0.06187882646918297,
      -0.0646863579750061,
      -0.042045220732688904,
      0.02938096411526203,
      0.006126644089818001,
      0.08617426455020905,
      0.004387210123240948,
      -0.03670099005103111,
      0.017871709540486336,
      -0.021796103566884995,
      0.01526574231684208,
      -0.05360354110598564,
      0.02312098816037178,
      0.00753638194873929,
      0.049380164593458176,
      0.04144515097141266,
      -0.07665762305259705,
      -0.04261079058051109,
      0.033661264926195145,
      0.07910194993019104,
      0.006082500796765089,
      0.025336338207125664,
      -0.00748971663415432,
      -0.04991476237773895,
      -0.03434514254331589,
      -0.017200123518705368,
      0.015592039562761784,
      0.05465305969119072,
      -0.0014192009111866355,
      0.03486420959234238,
      0.0332498736679554,
      -0.015285810455679893,
      0.020802682265639305,
      0.04125484824180603,
      0.02256827987730503,
      0.05155529826879501,
      -0.04920479655265808,
      -0.055662933737039566,
      0.024725697934627533,
      0.029543815180659294,
      -0.07167698442935944,
      -0.0019424846395850182,
      2.07102839340223e-05,
      -0.01480813603848219,
      0.014377948828041553,
      0.04253992438316345,
      -0.02982322871685028,
      -0.031452711671590805,
      0.001973292790353298,
      -0.02582637220621109,
      0.02340581640601158,
      0.019352950155735016,
      -0.07189217209815979,
      0.018389321863651276,
      0.0029505868442356586,
      -0.019356386736035347,
      0.03423086553812027,
      0.016786014661192894,
      0.026364633813500404,
      0.08603336662054062,
      -0.0384393110871315,
      -0.0899571105837822,
      -0.004814113490283489,
      -0.05283501371741295,
      0.09143035113811493,
      0.07969731092453003,
      0.039960794150829315,
      0.0923021212220192,
      -0.018834982067346573,
      -0.08149005472660065,
      0.036995574831962585,
      -0.045408181846141815,
      -0.011118677444756031,
      -0.029330868273973465,
      0.005584217607975006,
      -0.03414082154631615,
      0.029574202373623848,
      0.026308473199605942,
      -0.020865164697170258,
      0.027030164375901222,
      0.032614920288324356,
      0.05008039250969887,
      0.03417793661355972,
      -0.014584583230316639,
      0.05127215385437012,
      -0.0828150138258934,
      0.0010208685416728258,
      0.01113070733845234,
      -0.0071592689491808414,
      -0.029195306822657585,
      0.003090142970904708,
      -0.032340072095394135,
      0.06447404623031616,
      0.049461737275123596,
      0.052055735141038895,
      -0.020479310303926468,
      -0.04801522567868233,
      -0.021266881376504898,
      -0.053122710436582565,
      0.02362223155796528,
      -0.04739397391676903,
      -0.0659027248620987,
      -0.035112958401441574,
      -0.03419344499707222,
      -0.10032961517572403,
      0.05232606828212738,
      -0.003274602582678199,
      -0.05997978895902634,
      -0.1043921560049057,
      -0.029293596744537354,
      -0.03959811478853226,
      -0.013891776092350483,
      0.03866224363446236,
      -0.08926068246364594,
      -0.050723135471343994,
      -0.00887233205139637,
      -0.03805844113230705,
      0.0008199429139494896,
      -0.0630924180150032,
      0.05009092390537262,
      0.05046367272734642,
      0.005002216901630163,
      -0.052981533110141754,
      -0.08641750365495682,
      0.05630367994308472,
      0.048949792981147766,
      0.002855695551261306,
      0.011084792204201221,
      -0.06772830337285995,
      0.001686297357082367,
      -0.06252460181713104,
      -0.03669828921556473,
      0.005651888437569141,
      -0.017334913834929466,
      0.06965258717536926,
      0.04442555457353592,
      -0.06009393185377121,
      0.07414756715297699,
      -0.026255713775753975,
      -0.004652208182960749,
      -0.1508280336856842,
      0.006141381803900003,
      0.010687194764614105,
      -0.006291501224040985,
      -0.07561971247196198,
      0.009667768143117428,
      -0.003514261683449149,
      0.08903494477272034,
      -0.04748248681426048,
      0.07727552950382233,
      -0.07676585018634796,
      -0.04536306858062744,
      -0.062057968229055405,
      -0.05179312452673912,
      0.06374671310186386,
      -0.051468174904584885,
      0.01579185016453266,
      0.05129709467291832,
      0.010419843718409538,
      0.08729998022317886,
      -0.05276508629322052,
      -0.024051927030086517,
      -0.04174680635333061,
      -0.06436789035797119,
      -0.0007656813831999898,
      -0.008848834782838821,
      -0.0013059579068794847,
      -0.08903304487466812,
      0.032894961535930634,
      0.00252240221016109,
      -0.010212767869234085,
      -0.003327039536088705,
      -0.00336528685875237,
      0.049991678446531296,
      -0.001394706661812961,
      -0.08494800329208374,
      0.030717384070158005,
      -0.026992270722985268,
      0.02667824551463127,
      -0.03321322426199913,
      -1.253626305697253e-05,
      0.023517968133091927,
      -0.01180153340101242,
      -0.04407733678817749,
      -0.025710828602313995,
      0.04478049650788307,
      0.014642272144556046,
      -0.07725901901721954,
      0.04018859937787056,
      0.07394645363092422,
      -0.039523426443338394,
      0.013450268656015396,
      -0.08823396265506744,
      0.09618935734033585,
      0.020275911316275597,
      0.07221665978431702,
      -0.0007565509295091033,
      0.09145811200141907,
      -0.007265577558428049,
      -0.018497755751013756,
      -0.040349315851926804,
      0.018908780068159103,
      0.09576355665922165,
      0.035045892000198364,
      0.039033133536577225,
      -0.0853118821978569,
      0.08619213849306107,
      0.00496956892311573,
      -0.06310615688562393,
      0.03481678292155266,
      -0.12124374508857727,
      0.03264831751585007,
      -0.030095020309090614,
      -0.03127855062484741,
      0.008285300806164742,
      0.07925626635551453,
      0.02693631872534752,
      0.04777038097381592,
      -0.03586437553167343,
      0.02693788707256317,
      0.0006642945227213204,
      0.011474061757326126,
      0.009618100710213184,
      0.002766064601019025,
      0.033257585018873215,
      -0.07053496688604355,
      -0.015950951725244522,
      0.08695657551288605,
      -0.035567887127399445,
      0.05618149787187576,
      -0.00037732685450464487,
      -0.012668427079916,
      -0.02616291679441929,
      -0.011306535452604294,
      -0.01646612025797367,
      -0.003681069938465953,
      -0.05096901208162308,
      0.02395540475845337,
      -0.027461649850010872,
      -0.002604276640340686,
      -0.03246084228157997,
      -0.0791008397936821,
      0.015491780824959278,
      -0.04818485304713249,
      0.10169937461614609,
      0.011256764642894268,
      -0.04592692479491234,
      0.04716780036687851,
      0.009002218954265118,
      -0.005285879597067833,
      0.017091626301407814,
      0.01455328892916441,
      0.0335727259516716,
      0.049757517874240875,
      0.054676737636327744,
      0.06873933970928192,
      0.0026055523194372654,
      0.01973719894886017,
      -0.055476490408182144,
      -0.05416591838002205,
      0.011579465121030807,
      -0.04505513235926628,
      -0.0027858165558427572,
      -0.05223541334271431,
      -0.10169228911399841,
      -0.009304306469857693,
      -0.056105513125658035,
      0.058858808130025864,
      -0.007246332708746195,
      -0.04455437511205673,
      0.04550301283597946,
      -0.030515775084495544,
      0.019467085599899292,
      0.019813790917396545,
      0.027379145845770836,
      -0.0067478809505701065,
      -0.04046892002224922,
      0.0002106121537508443,
      -0.03317926824092865,
      0.051245879381895065,
      -0.017670271918177605,
      0.03392397612333298,
      0.030826279893517494,
      -0.03292415291070938,
      -0.022911373525857925,
      0.08734523504972458,
      0.032801128923892975,
      0.004955103620886803,
      0.027851516380906105,
      0.07429725676774979,
      0.09434378892183304,
      -0.0481254868209362,
      0.1617475301027298,
      -0.04994344711303711,
      -0.04312417656183243,
      -0.02678276039659977,
      -0.007634327281266451,
      0.0017738371388986707,
      -0.03444335609674454,
      -0.011400093324482441,
      -0.03726830333471298,
      0.11062484979629517,
      -0.007900167256593704,
      -0.041074760258197784,
      -0.0046068597584962845,
      -0.0046871076337993145,
      0.003015149850398302,
      -0.012698761187493801,
      -0.08226172626018524,
      -0.06298742443323135,
      0.007943705655634403,
      -0.0670856311917305,
      0.03699541836977005,
      -0.06723175942897797,
      0.020237045362591743,
      0.0053986357524991035,
      0.009333265945315361,
      0.008072596974670887,
      0.014967250637710094,
      -0.004678694996982813,
      -0.03864122927188873,
      0.028089651837944984,
      0.0735679492354393,
      0.0201615858823061,
      -0.0003999706532340497,
      0.02813725173473358,
      -0.007859387435019016,
      -0.05696660280227661,
      0.04142008349299431,
      0.005213644355535507,
      0.060996223241090775,
      0.026365380734205246,
      -0.027966370806097984,
      0.11988462507724762,
      0.04153909534215927,
      -0.02611398883163929,
      -0.010654209181666374,
      0.02239239402115345,
      0.004925734829157591,
      0.06261208653450012,
      0.014687566086649895,
      -0.036632832139730453,
      0.031024757772684097,
      0.05587764456868172,
      0.0858682170510292,
      0.05422225594520569,
      0.011750797741115093,
      0.02235272340476513,
      0.036128874868154526,
      0.025479892268776894,
      0.002736475085839629,
      0.06492011249065399,
      -0.05147530883550644,
      -0.0533120334148407,
      -0.041105564683675766,
      -0.047371067106723785,
      0.0118239251896739,
      0.03961343318223953,
      0.023535387590527534,
      -0.026222314685583115,
      0.0035302038304507732,
      -0.062028732150793076,
      0.05415444076061249,
      0.04459173604846001,
      0.047630082815885544,
      0.017564155161380768,
      -0.05373380333185196,
      -0.010949552059173584,
      -0.0009921275777742267,
      0.011045582592487335,
      -0.03587742894887924,
      0.0513799712061882,
      -0.0014155132230371237,
      0.02044452168047428,
      -0.04332505911588669,
      0.03608214482665062,
      -0.0944281667470932,
      -0.013943970203399658,
      0.046174194663763046,
      0.007290440145879984,
      0.013679166324436665,
      -0.06176383048295975,
      0.016184212639927864,
      -0.009293749928474426,
      -0.03745630383491516,
      -0.015266316942870617,
      -0.05664483830332756,
      0.09953496605157852,
      -0.020374564453959465,
      -0.01789180003106594,
      0.09509798884391785,
      0.008411988615989685,
      -0.08187495172023773,
      0.02713015489280224,
      -0.028103550896048546,
      0.016147742047905922,
      -0.05864863097667694,
      -0.05272509530186653,
      0.026095114648342133,
      -0.03647327795624733,
      -0.00176421704236418,
      0.0036323745734989643,
      -0.0393495112657547,
      0.050851911306381226,
      0.008592391386628151,
      -0.07475145161151886,
      0.028630200773477554,
      -0.03666523098945618,
      0.019341792911291122,
      -0.016400516033172607,
      -0.030116166919469833,
      0.04002831503748894,
      0.0757581889629364,
      -0.019488390535116196,
      -0.08496332168579102,
      0.02189180627465248,
      0.01887664757668972,
      -0.0025911377742886543,
      -0.015572983771562576,
      0.04651559516787529,
      -0.07621684670448303,
      -0.00266998540610075,
      -0.02711321972310543,
      0.05563581734895706,
      0.023775774985551834,
      0.038207001984119415,
      0.049950651824474335,
      0.04807378724217415,
      0.017936402931809425,
      0.019068622961640358,
      -0.010407010093331337,
      -0.06941495835781097,
      0.04666033759713173,
      -0.021725967526435852,
      -0.07649200409650803,
      0.03219110146164894,
      0.09557504951953888,
      -0.014305290766060352,
      -0.03663897141814232,
      0.03272043168544769,
      -0.037490054965019226,
      0.013967410661280155,
      0.005563625134527683,
      0.03382051736116409,
      0.009654354304075241,
      -0.00018109385564457625,
      -0.01699175499379635,
      -0.05738766863942146,
      0.011582578532397747,
      0.005624562967568636,
      0.027934618294239044,
      0.012742162682116032,
      0.00735496124252677,
      -0.007838656194508076,
      0.03705604001879692,
      0.0452517569065094,
      0.031477250158786774,
      0.01840614527463913,
      0.06787549704313278,
      0.00326541461981833,
      -0.0218790415674448,
      -0.0614052340388298,
      0.021498899906873703,
      0.0947943925857544,
      0.010254516266286373,
      0.04863296449184418,
      0.058755867183208466,
      0.001005043275654316,
      -0.00503391120582819,
      -0.0034451186656951904,
      -0.016257626935839653,
      0.098358154296875,
      -0.006194537039846182,
      -0.03481509909033775,
      -0.004290283657610416,
      0.048129141330718994
    ],
    [
      0.008694996125996113,
      0.018951239064335823,
      -0.05325836315751076,
      0.02655772864818573,
      -0.0011749172117561102,
      -0.0013947644038125873,
      0.004142386373132467,
      0.06540372222661972,
      0.02763679251074791,
      -0.05529756471514702,
      0.03666115924715996,
      -0.013877655379474163,
      0.01602163352072239,
      -0.0199462678283453,
      -0.0038973435293883085,
      -0.03912273421883583,
      -0.02477404847741127,
      0.00909427646547556,
      0.008186801336705685,
      0.047800835222005844,
      0.04789576306939125,
      0.0920872911810875,
      0.01618199422955513,
      0.04916123300790787,
      0.03385661542415619,
      0.018733574077486992,
      -0.01572144404053688,
      0.06140807271003723,
      0.02421729639172554,
      -0.010789287276566029,
      -0.05233355611562729,
      -0.0018102650064975023,
      0.0636354461312294,
      0.021823203191161156,
      -0.0413554385304451,
      -0.039180804044008255,
      -0.0056295860558748245,
      0.02431591972708702,
      0.040593989193439484,
      -0.09922769665718079,
      -0.057211704552173615,
      -0.04850439727306366,
      0.14203688502311707,
      0.011440563946962357,
      0.0473712719976902,
      -0.04607892408967018,
      0.003639955772086978,
      0.040086742490530014,
      -0.011864366009831429,
      0.016579998657107353,
      0.05724290385842323,
      -0.014934301376342773,
      -0.017902003601193428,
      -0.008371002040803432,
      -0.04928025230765343,
      0.03430422395467758,
      0.007320782635360956,
      0.08225598186254501,
      0.008526382967829704,
      -0.017614824697375298,
      0.07091498374938965,
      -0.007177311927080154,
      -0.019536122679710388,
      0.0655955821275711,
      0.07850687205791473,
      0.04028201848268509,
      -0.04215656593441963,
      0.06190550699830055,
      -0.015818774700164795,
      0.05094480887055397,
      -0.029504697769880295,
      0.13485711812973022,
      -0.01914638839662075,
      0.027347464114427567,
      -0.056883472949266434,
      0.07594592124223709,
      -0.061935342848300934,
      0.0748324766755104,
      0.008124588057398796,
      0.031182782724499702,
      0.027283435687422752,
      -0.03597557917237282,
      0.021112734451889992,
      -0.0027184223290532827,
      0.048769980669021606,
      0.032954711467027664,
      0.023839179426431656,
      -0.0021849654149264097,
      0.04872956499457359,
      0.08676532655954361,
      0.0195259228348732,
      -0.08545619994401932,
      -0.0029338758904486895,
      -0.002432884182780981,
      0.09004824608564377,
      0.08074137568473816,
      0.003912082873284817,
      -0.0036562613677233458,
      0.04747920483350754,
      0.038176678121089935,
      0.03130205348134041,
      0.0012016252148896456,
      -0.012867448851466179,
      -0.01848149113357067,
      -0.031207304447889328,
      -0.05346064642071724,
      0.05583487078547478,
      -0.003107562428340316,
      0.04326147958636284,
      -0.03277494013309479,
      0.001019307761453092,
      -0.04576822742819786,
      0.023881161585450172,
      0.02814314514398575,
      -0.0014951388584449887,
      0.027379097416996956,
      -0.010028639808297157,
      0.01772325672209263,
      0.02239643596112728,
      -0.04497995972633362,
      0.07533341646194458,
      0.07388020306825638,
      -0.12516750395298004,
      0.0014393707970157266,
      0.006860989611595869,
      -0.03645860403776169,
      -0.0015428774058818817,
      -0.037167903035879135,
      -0.11415436118841171,
      -0.01787741295993328,
      0.04034808650612831,
      0.01891981065273285,
      -0.0057388124987483025,
      0.0167512446641922,
      -0.006227697245776653,
      0.0026209340430796146,
      0.052174706012010574,
      0.013554898090660572,
      -0.03709658980369568,
      -0.0039049654733389616,
      -0.062231164425611496,
      -0.029177304357290268,
      0.04598557949066162,
      0.04508741572499275,
      0.08879727870225906,
      0.023294473066926003,
      0.033677902072668076,
      0.027100820094347,
      -0.061594158411026,
      0.08361100405454636,
      0.025225551798939705,
      -0.0886530876159668,
      -0.03996120020747185,
      -0.01107505802065134,
      0.10590585321187973,
      0.006984644569456577,
      -0.050239983946084976,
      -0.05249267816543579,
      -0.01402819063514471,
      -0.01792665384709835,
      0.05058451369404793,
      0.013968275859951973,
      -0.006405726540833712,
      -0.05386307090520859,
      -0.03759370744228363,
      0.006429255474358797,
      -0.0638498067855835,
      0.10589813441038132,
      -0.014534139074385166,
      -0.02946024388074875,
      -0.039880670607089996,
      0.014696460217237473,
      -0.03470977768301964,
      -0.02688419632613659,
      -0.07532244175672531,
      -0.03884478658437729,
      -0.016155371442437172,
      -0.027197496965527534,
      0.01551054511219263,
      0.0482126846909523,
      0.009524856694042683,
      0.001272049848921597,
      -0.004573810379952192,
      0.03838261589407921,
      -0.030207931995391846,
      -0.036053724586963654,
      0.038347028195858,
      0.032440874725580215,
      -0.037736278027296066,
      -0.03230508789420128,
      -0.06413615494966507,
      -0.04657139256596565,
      0.09433291107416153,
      0.0842985063791275,
      0.027075398713350296,
      0.011278408579528332,
      0.014344164170324802,
      -0.08782467991113663,
      0.05481577292084694,
      0.04260939732193947,
      -0.017142359167337418,
      0.001784002291969955,
      0.014160250313580036,
      0.03999881446361542,
      -0.05065103992819786,
      -0.002802701434120536,
      -0.057892464101314545,
      0.008504457771778107,
      -0.06079012528061867,
      0.05086706578731537,
      0.03746729716658592,
      0.03634919226169586,
      -0.006958574056625366,
      0.053887318819761276,
      -0.047447770833969116,
      -0.0296538844704628,
      0.10911061614751816,
      0.031114453449845314,
      0.05367189273238182,
      0.03247354179620743,
      0.008090820163488388,
      -0.033803705126047134,
      -0.021693386137485504,
      -0.09871915727853775,
      0.033482626080513,
      0.004618365783244371,
      0.012368797324597836,
      -0.0007841994520276785,
      -0.04604000598192215,
      -0.021975979208946228,
      0.01734483800828457,
      0.038031984120607376,
      0.01775399036705494,
      -0.028541943058371544,
      0.026907220482826233,
      -0.02356170490384102,
      0.028729427605867386,
      0.009716727770864964,
      0.01600339077413082,
      -0.01569605991244316,
      0.09062716364860535,
      -0.008818221278488636,
      0.09481311589479446,
      -0.024575520306825638,
      0.08197826892137527,
      0.011988565325737,
      -0.10399274528026581,
      -0.0014964415458962321,
      -0.019455883651971817,
      -0.016852542757987976,
      0.04520283639431,
      0.015706082805991173,
      0.04843967407941818,
      0.03537401929497719,
      0.015544736757874489,
      -0.03604208305478096,
      -0.022827915847301483,
      -0.07043368369340897,
      -0.014485842548310757,
      0.029657937586307526,
      -0.01260538212954998,
      0.036419399082660675,
      -0.006646472029387951,
      0.056116584688425064,
      -0.008688461035490036,
      -0.02163712866604328,
      0.00455881142988801,
      0.07082708925008774,
      -0.03977043554186821,
      -0.026049911975860596,
      0.016986656934022903,
      0.1587134301662445,
      0.08562231808900833,
      -0.03799141198396683,
      0.017826808616518974,
      -0.07788927853107452,
      0.004643415100872517,
      0.029071366414427757,
      0.13672539591789246,
      -0.02234525978565216,
      -0.04147493094205856,
      -0.040452297776937485,
      0.02955746464431286,
      0.06162336468696594,
      0.004961592145264149,
      -0.04363657906651497,
      0.11429637670516968,
      0.0009507042123004794,
      0.02774009481072426,
      0.056004222482442856,
      -0.010967613197863102,
      0.005299681797623634,
      -0.011471780017018318,
      0.04554931819438934,
      -0.037379417568445206,
      -0.012895307503640652,
      0.009880287572741508,
      0.014144848100841045,
      -0.02678346261382103,
      -0.030917908996343613,
      0.00919733103364706,
      0.013853940181434155,
      -0.04840502887964249,
      -0.03018932044506073,
      -0.03188956156373024,
      -0.0413813591003418,
      0.042433395981788635,
      -0.06481338292360306,
      -0.13517455756664276,
      0.03128514066338539,
      0.008992957882583141,
      0.036938391625881195,
      0.02164928801357746,
      -0.045342110097408295,
      0.0013999133370816708,
      -0.01809096522629261,
      0.017717665061354637,
      -0.06718234717845917,
      -0.06609030812978745,
      0.0704597532749176,
      -0.005917690694332123,
      -0.0033824441488832235,
      0.05618428811430931,
      0.0018234376329928637,
      -0.015680264681577682,
      0.02185603603720665,
      -0.057666681706905365,
      0.03234032914042473,
      -0.03465579077601433,
      0.07622241973876953,
      0.019260836765170097,
      0.030493151396512985,
      -0.05969589948654175,
      0.0048897527158260345,
      -0.03387119993567467,
      0.031193634495139122,
      -0.008300605230033398,
      0.05753599852323532,
      -0.0031566040124744177,
      0.08296209573745728,
      0.0036069320049136877,
      -0.0717572569847107,
      -0.015261427499353886,
      0.0010689747286960483,
      -0.03066561557352543,
      -0.020365532487630844,
      0.002024231944233179,
      -0.030101526528596878,
      -0.009303569793701172,
      0.046340394765138626,
      -0.039958711713552475,
      -0.009466292336583138,
      -0.055623337626457214,
      0.006070767529308796,
      -0.01115637831389904,
      0.019518915563821793,
      -0.01134344283491373,
      0.0013430352555587888,
      0.0562053844332695,
      -0.00826947670429945,
      -0.07451239973306656,
      0.03737388923764229,
      -0.08760370314121246,
      0.015843117609620094,
      0.01518869586288929,
      0.04598936438560486,
      0.0017587277106940746,
      -0.0072370595298707485,
      -0.07832743972539902,
      0.012043054215610027,
      -0.04881829023361206,
      0.015663523226976395,
      0.019136102870106697,
      0.03784935921430588,
      0.02430073544383049,
      0.023907095193862915,
      -0.03764687478542328,
      0.05011597275733948,
      0.03407925367355347,
      -0.009586689062416553,
      0.014170650392770767,
      -0.015404774807393551,
      -0.041863419115543365,
      0.005902203265577555,
      -0.028180770576000214,
      -0.05803411826491356,
      -0.010472153313457966,
      -0.05798891931772232,
      0.09482370316982269,
      0.012487879954278469,
      -0.026353744789958,
      0.02318698540329933,
      0.09330560266971588,
      -0.005145630799233913,
      0.006802110932767391,
      -0.008647789247334003,
      0.026783887296915054,
      0.0025185931008309126,
      0.026626083999872208,
      -0.03743971884250641,
      -0.1324312537908554,
      0.008062445558607578,
      -0.0038934850599616766,
      0.03181169927120209,
      -0.028742287307977676,
      0.10106856375932693,
      -0.03750753775238991,
      0.03018875978887081,
      -0.03986921161413193,
      -0.05251123011112213,
      0.07579619437456131,
      0.037397921085357666,
      -0.040539778769016266,
      -0.01940321736037731,
      -0.03577696904540062,
      0.013149282895028591,
      -0.030135996639728546,
      -0.017284415662288666,
      0.05102219805121422,
      0.04213019087910652,
      -0.009452263824641705,
      0.04908033832907677,
      -0.0244820024818182,
      0.035162050276994705,
      0.007661610841751099,
      -0.00778192700818181,
      -0.016462033614516258,
      -0.02611924335360527,
      0.0661877915263176,
      0.039138320833444595,
      -0.03960184007883072,
      -0.07059108465909958,
      -0.10539443790912628,
      -0.10278207063674927,
      0.025610553100705147,
      -0.014885570853948593,
      0.05745897442102432,
      -0.01176512148231268,
      -0.03312285244464874,
      -0.013315425254404545,
      0.01145109347999096,
      0.010541756637394428,
      0.04437066242098808,
      -0.059578996151685715,
      0.0016757796984165907,
      -0.010020101442933083,
      -0.0644424557685852,
      -0.09143250435590744,
      -0.014673369936645031,
      -0.011434762738645077,
      0.015044772066175938,
      -0.02267313562333584,
      0.07845813035964966,
      -0.002577437087893486,
      -0.011126641184091568,
      0.006449520122259855,
      -0.028290897607803345,
      -0.06296919286251068,
      -0.017670901492238045,
      0.031132536008954048,
      -0.0798054039478302,
      0.0028414411935955286,
      -0.04496685042977333,
      -0.13860060274600983,
      0.03149183467030525,
      -0.008917680941522121,
      -0.007573556154966354,
      -0.033737678080797195,
      0.0043118963949382305,
      0.012302890419960022,
      0.02698918618261814,
      -0.014094240963459015,
      -0.0018502603052183986,
      0.017338953912258148,
      -0.012556255795061588,
      -0.017212683334946632,
      -0.0041940417140722275,
      -0.022661404684185982,
      -0.008250278420746326,
      -0.02020622044801712,
      0.02718665823340416,
      0.042730700224637985,
      0.05886828154325485,
      -0.01955355331301689,
      -0.12150615453720093,
      -0.013111344538629055,
      0.012330858036875725,
      -0.016380999237298965,
      0.009601982310414314,
      0.0407925583422184,
      -0.024728743359446526,
      -0.01980021223425865,
      0.004907098598778248,
      0.008025079034268856,
      0.0052914097905159,
      0.007697906345129013,
      -0.07563107460737228,
      0.009341551922261715,
      -0.007154407911002636,
      -0.03921514376997948,
      -0.0505174845457077,
      -0.05586325377225876,
      -0.027450397610664368
    ],
    [
      -0.07875843346118927,
      0.04375002905726433,
      -0.04847231134772301,
      0.005521340295672417,
      0.0317014642059803,
      0.038802195340394974,
      -0.05931088328361511,
      -0.057530052959918976,
      -0.09567055851221085,
      -0.010374507866799831,
      -0.033693280071020126,
      0.03897725045681,
      0.11347459256649017,
      0.027495231479406357,
      -0.0036553246900439262,
      0.08289095014333725,
      0.0028953123837709427,
      0.020354915410280228,
      0.017461808398365974,
      -0.040928035974502563,
      -0.0024976469576358795,
      0.08555156737565994,
      -0.025783929973840714,
      -0.025467703118920326,
      0.08210355043411255,
      0.05077563226222992,
      -0.055716995149850845,
      0.05032593011856079,
      0.036873772740364075,
      -0.08450333029031754,
      -0.006412246730178595,
      -0.026503797620534897,
      0.06967565417289734,
      -0.013226278126239777,
      0.009203430265188217,
      0.04036040976643562,
      0.016731221228837967,
      0.11972653120756149,
      -0.017443638294935226,
      0.02058371715247631,
      -0.0884939581155777,
      0.026368023827672005,
      -0.021756859496235847,
      0.0200441163033247,
      0.025011012330651283,
      0.06858399510383606,
      0.016516301780939102,
      -0.06525006890296936,
      -0.004221129696816206,
      -0.001168508897535503,
      -0.0018786906730383635,
      -0.07615624368190765,
      -0.0439017079770565,
      0.043867263942956924,
      -0.08048418909311295,
      -0.008523460477590561,
      -0.052507977932691574,
      0.044273652136325836,
      -0.07117614150047302,
      0.07859261333942413,
      -0.027539271861314774,
      -0.09774863719940186,
      0.02187589555978775,
      0.05398606136441231,
      0.07614614814519882,
      -0.009069487452507019,
      -0.04477144032716751,
      0.05638830363750458,
      -0.015323678031563759,
      -0.0010308695491403341,
      0.07999993860721588,
      -0.010557090863585472,
      -0.019127922132611275,
      0.07809670269489288,
      0.02251467853784561,
      -0.03651309385895729,
      0.02316931262612343,
      -0.023123061284422874,
      -0.028579168021678925,
      -0.01778716966509819,
      -0.04306195303797722,
      -0.022053714841604233,
      -0.1015121266245842,
      -0.017362967133522034,
      -0.034792304039001465,
      -0.009180296212434769,
      -0.019483167678117752,
      0.02201727032661438,
      0.09052726626396179,
      0.08410310745239258,
      0.03640052303671837,
      -0.04618177190423012,
      0.029531048610806465,
      -0.005542467348277569,
      -0.06060897931456566,
      -0.005197479389607906,
      -0.05480402335524559,
      0.008369402028620243,
      -0.060316912829875946,
      -0.01563316583633423,
      0.016906961798667908,
      -0.03525464981794357,
      -0.13172852993011475,
      0.020429575815796852,
      -0.0009668185957707465,
      0.014589081518352032,
      0.03634221479296684,
      0.0332312136888504,
      -0.05365394055843353,
      0.016615433618426323,
      -0.04216594249010086,
      -0.02013665810227394,
      -0.03576769307255745,
      -0.04902063310146332,
      0.042632970958948135,
      -0.007177833002060652,
      0.10730872303247452,
      -0.03713497519493103,
      -0.030753696337342262,
      0.0312174204736948,
      0.06875742971897125,
      0.04282773658633232,
      0.02138691209256649,
      -0.02157985232770443,
      -0.04684552550315857,
      -0.05341462790966034,
      -0.014090473763644695,
      -0.12334518879652023,
      -0.059005193412303925,
      0.03504268825054169,
      -0.00489015830680728,
      -0.01328935194760561,
      -0.031216368079185486,
      0.003184740198776126,
      0.008429103530943394,
      0.05622062459588051,
      -0.05327370762825012,
      0.05489367991685867,
      -0.0289006270468235,
      0.0057501415722072124,
      -0.03317659720778465,
      0.034762728959321976,
      0.025468213483691216,
      0.10636986047029495,
      -0.030318712815642357,
      -0.04104391857981682,
      -0.017200224101543427,
      0.09310078620910645,
      0.010922613553702831,
      -0.13829189538955688,
      -0.019006861373782158,
      0.08410937339067459,
      -0.021288910880684853,
      0.009470785036683083,
      -0.005555865354835987,
      -0.011360102333128452,
      0.039865124970674515,
      0.014398273080587387,
      -0.004801013972610235,
      0.024177521467208862,
      -0.0020685517229139805,
      -0.01901622675359249,
      -0.03614351898431778,
      -0.0342574343085289,
      0.0025046581868082285,
      -0.0076213618740439415,
      0.009629756212234497,
      -0.010662571527063847,
      0.02524060197174549,
      0.013344503939151764,
      0.058676060289144516,
      0.07345947623252869,
      0.09311752021312714,
      -0.04667253419756889,
      -0.016221845522522926,
      0.014917985536158085,
      0.009620040655136108,
      -0.04534551501274109,
      0.007405247539281845,
      -0.01849854737520218,
      0.023232173174619675,
      0.007040141150355339,
      0.004111419897526503,
      -0.002807443728670478,
      0.003782646032050252,
      0.014942942187190056,
      0.06446203589439392,
      0.028602246195077896,
      0.021971875801682472,
      0.02455230802297592,
      -0.030799325555562973,
      -0.05482472479343414,
      0.007141002453863621,
      -0.03723181039094925,
      0.06000816822052002,
      -0.011256307363510132,
      0.022887960076332092,
      0.02889014407992363,
      -0.11784162372350693,
      0.05690880864858627,
      0.010810178704559803,
      0.022121399641036987,
      0.014078373089432716,
      0.056465763598680496,
      0.024526024237275124,
      -0.014316369779407978,
      -0.01254901010543108,
      0.08131124824285507,
      -0.015674035996198654,
      -0.023343654349446297,
      0.026959169656038284,
      -0.01874914951622486,
      0.011004848405718803,
      0.0655437558889389,
      0.05451884865760803,
      -0.01295393891632557,
      0.028587009757757187,
      -0.028018930926918983,
      -0.00709569500759244,
      -0.041572365909814835,
      0.036510515958070755,
      -0.03767102211713791,
      0.027444375678896904,
      0.017713570967316628,
      -0.04984881728887558,
      -0.016885485500097275,
      0.01445109024643898,
      0.10829738527536392,
      0.016945455223321915,
      0.05487757548689842,
      0.08342210948467255,
      -0.010179218836128712,
      -0.07745397090911865,
      -0.0158829428255558,
      0.018875930458307266,
      -0.0005593757377937436,
      -0.04099228233098984,
      0.024180211126804352,
      0.041817404329776764,
      0.00893252994865179,
      0.0742931142449379,
      -0.0005253133713267744,
      0.007735785096883774,
      -0.035311825573444366,
      0.015817895531654358,
      -0.009851540438830853,
      0.021685916930437088,
      -0.02846493385732174,
      -0.06010124459862709,
      0.003287004306912422,
      -0.005955114960670471,
      -0.0013169483281672,
      0.077216736972332,
      -0.08074662834405899,
      0.08397827297449112,
      0.00842487532645464,
      0.05909351259469986,
      -0.046664606779813766,
      0.0162563044577837,
      0.0164913609623909,
      -0.024871747940778732,
      0.025319190695881844,
      -0.022458959370851517,
      -0.010221078060567379,
      -0.05974972993135452,
      0.08904489129781723,
      0.07311426848173141,
      -0.006273181177675724,
      0.02673884481191635,
      0.03623070567846298,
      -0.06784453243017197,
      0.04282994195818901,
      -0.020263999700546265,
      0.04509788006544113,
      0.04518403857946396,
      -0.03933795914053917,
      0.0038788008969277143,
      -4.864427319262177e-05,
      -0.020302878692746162,
      0.045222681015729904,
      0.02397572249174118,
      -0.09230125695466995,
      0.020369229838252068,
      -0.007952660322189331,
      0.020333992317318916,
      0.09067218005657196,
      0.016522344201803207,
      0.037993818521499634,
      -0.02000240795314312,
      0.05787559598684311,
      -0.04448573663830757,
      -0.022116759791970253,
      -0.03902595490217209,
      0.02855256199836731,
      0.023190658539533615,
      0.0669604167342186,
      0.06095493584871292,
      0.05678403750061989,
      -0.07570750266313553,
      -0.07388907670974731,
      -0.03488943353295326,
      0.028257761150598526,
      -0.015105271711945534,
      0.028179863467812538,
      -0.05264263227581978,
      0.037238959223032,
      -0.04670042172074318,
      -0.014740481041371822,
      0.005920321214944124,
      -0.050184618681669235,
      0.043477218598127365,
      -0.0467374250292778,
      0.02662656269967556,
      0.018454037606716156,
      0.025877896696329117,
      -0.06077996641397476,
      -0.02499781735241413,
      0.03288785368204117,
      0.019888337701559067,
      -0.012362605892121792,
      0.012768945656716824,
      0.026269344612956047,
      0.04991421848535538,
      -0.0061288862489163876,
      -0.009584981948137283,
      -0.034236032515764236,
      0.019098367542028427,
      -0.009619228541851044,
      0.00019516465545166284,
      0.01686694659292698,
      -0.010908694937825203,
      0.009381766431033611,
      0.06653710454702377,
      -0.008778470568358898,
      0.015485393814742565,
      0.02854752354323864,
      0.012649128213524818,
      0.04922453686594963,
      -0.03991904482245445,
      -0.05309315398335457,
      0.07471504807472229,
      0.006696533877402544,
      -0.008596748113632202,
      0.051767461001873016,
      -0.014770472422242165,
      0.004000731278210878,
      -0.010102003812789917,
      -0.053571831434965134,
      -0.022672077640891075,
      0.02188209258019924,
      0.023355891928076744,
      0.02692415378987789,
      0.06414289027452469,
      -0.02294822968542576,
      0.012737841345369816,
      -0.04679430276155472,
      0.0293889082968235,
      -0.0884319320321083,
      -0.04120740294456482,
      0.04556620866060257,
      0.004400167614221573,
      -0.03741510957479477,
      0.05403337627649307,
      0.01710531860589981,
      -0.051304783672094345,
      -0.06473399698734283,
      -0.03646276891231537,
      0.04111078381538391,
      0.024160662665963173,
      0.045553162693977356,
      0.04299245402216911,
      0.006398751400411129,
      0.0494445376098156,
      -0.04006775841116905,
      -0.07041335850954056,
      0.0670531690120697,
      0.004211716819554567,
      -0.005516973324120045,
      -0.008613266050815582,
      -0.017124347388744354,
      -0.02290513552725315,
      -0.01136174239218235,
      0.06221127137541771,
      -0.012214372865855694,
      -0.07407046854496002,
      -0.005725149065256119,
      0.07779817283153534,
      -0.01711364835500717,
      0.07762119174003601,
      -0.035376545041799545,
      -0.05391700938344002,
      -0.035214267671108246,
      0.0029320453759282827,
      -0.07640209048986435,
      -0.03458794206380844,
      0.05495337024331093,
      0.027230219915509224,
      0.04822442680597305,
      0.043074365705251694,
      0.04312016814947128,
      -0.0757247656583786,
      0.033124081790447235,
      -0.0036715196911245584,
      0.025389835238456726,
      0.01754741184413433,
      0.02634531632065773,
      -0.015126957558095455,
      -0.08948724716901779,
      -0.07742968201637268,
      -0.0010490373242646456,
      -0.0437404103577137,
      0.06053619086742401,
      0.011610661633312702,
      -0.029376139864325523,
      0.03639700263738632,
      -0.006539118010550737,
      -0.005663196090608835,
      -0.004028961528092623,
      0.013507247902452946,
      -0.004328692797571421,
      -0.031115561723709106,
      -0.0158705934882164,
      -0.005464383400976658,
      -0.03604050353169441,
      0.004068552982062101,
      -0.11165723204612732,
      0.018593834713101387,
      0.0070090689696371555,
      0.023655246943235397,
      0.056180939078330994,
      0.10994288325309753,
      -0.02565455250442028,
      0.05802958831191063,
      -0.0450357086956501,
      -0.09284389019012451,
      0.03155697509646416,
      -0.052074141800403595,
      0.024481965228915215,
      0.04320356622338295,
      -0.04378869757056236,
      -0.029763810336589813,
      -0.04268304631114006,
      -0.014518218114972115,
      -0.012412591837346554,
      0.042295247316360474,
      0.009034068323671818,
      0.042167045176029205,
      -0.03594386950135231,
      -0.05860655754804611,
      -0.015952114015817642,
      -0.008968868292868137,
      0.013464118354022503,
      -0.004589553456753492,
      0.00705056544393301,
      0.016262346878647804,
      0.07267139852046967,
      -0.0478743240237236,
      -0.05727582424879074,
      -0.05114411190152168,
      -0.06112099066376686,
      -0.005736479535698891,
      -0.007537946570664644,
      -0.06557262688875198,
      -0.017727229744195938,
      0.05271769315004349,
      0.06052326783537865,
      -0.05009659752249718,
      -0.07382561266422272,
      0.019406601786613464,
      0.04701093211770058,
      -0.021367328241467476,
      0.015539608895778656,
      0.06550037115812302,
      -0.02051909640431404,
      -0.06795603036880493,
      -0.01978120021522045,
      -0.0851733461022377,
      0.06893760710954666,
      0.058657702058553696,
      -0.01224534586071968,
      0.023545045405626297,
      -0.006939099170267582,
      -0.002057763049378991,
      -0.01917276531457901,
      0.03305045887827873,
      -0.03305068239569664,
      0.009808369912207127,
      0.04196368530392647,
      0.040247946977615356,
      0.019832180812954903,
      0.004205975215882063,
      -0.07961512356996536,
      -0.08071006089448929,
      -0.09252606332302094,
      -0.04339158535003662,
      0.05403784662485123,
      -0.006807797122746706,
      0.019879724830389023,
      0.08067595958709717,
      0.02607247233390808,
      -0.008071987889707088,
      0.037038762122392654,
      0.026098432019352913
    ],
    [
      0.00045647102524526417,
      0.04588227719068527,
      -0.015155351720750332,
      -0.06405583024024963,
      -0.0278775691986084,
      0.02375878393650055,
      0.027314748615026474,
      0.04224126785993576,
      0.00015455781249329448,
      -0.02863222546875477,
      -0.045704398304224014,
      -0.03673382103443146,
      0.04906191676855087,
      -0.027651047334074974,
      0.008524595759809017,
      -0.015891076996922493,
      -0.03707128018140793,
      -0.039872534573078156,
      -0.007099194452166557,
      0.009443417191505432,
      0.030100399628281593,
      0.03911256045103073,
      -0.10026320815086365,
      0.008515502326190472,
      0.11055511981248856,
      -0.004043813794851303,
      0.008424385450780392,
      -0.0552172027528286,
      -0.008348368108272552,
      -0.007156423293054104,
      -0.012672063894569874,
      0.01573348604142666,
      0.0047623226419091225,
      -0.037125080823898315,
      -0.01616993546485901,
      0.028648993000388145,
      0.0002841842360794544,
      -0.052652113139629364,
      0.07792144268751144,
      -0.0428905226290226,
      0.029667064547538757,
      -0.008379781618714333,
      -0.05216269940137863,
      0.003506479086354375,
      -0.08203525096178055,
      -0.04906827583909035,
      -0.004236248321831226,
      0.07955081015825272,
      -0.09319647401571274,
      -0.010595564730465412,
      -0.03579031303524971,
      0.041841913014650345,
      -0.07222079485654831,
      0.04160364344716072,
      0.04314035922288895,
      0.022623881697654724,
      0.02614210732281208,
      0.09768306463956833,
      -0.002196143614128232,
      -0.04179023206233978,
      -0.027855336666107178,
      -0.0005575838731601834,
      -0.05577525123953819,
      0.03304099664092064,
      -0.07805878669023514,
      0.05278503894805908,
      0.045174773782491684,
      -0.028655633330345154,
      0.07671554386615753,
      -0.05014561861753464,
      -0.01712772436439991,
      0.036034781485795975,
      0.025908559560775757,
      -0.001126968301832676,
      0.07268179208040237,
      -0.03395995870232582,
      0.06286463141441345,
      -0.009653168730437756,
      -0.032562006264925,
      0.017477838322520256,
      0.06625863909721375,
      0.0026235398836433887,
      0.008522369898855686,
      -0.10620985925197601,
      0.04449630528688431,
      -0.015526610426604748,
      0.02068888209760189,
      -0.039398957043886185,
      -0.06722964346408844,
      0.028813036158680916,
      0.03227071091532707,
      -0.013999887742102146,
      0.0037696468643844128,
      -0.08669224381446838,
      -0.019387390464544296,
      -0.04428046569228172,
      0.024530179798603058,
      0.07490585744380951,
      0.04133125767111778,
      0.046607404947280884,
      -0.0113142104819417,
      -0.015171735547482967,
      0.04595545306801796,
      0.08267085999250412,
      0.012874454259872437,
      -0.012378161773085594,
      -0.0349404439330101,
      -0.048719651997089386,
      0.03683798387646675,
      0.03247009217739105,
      0.021097855642437935,
      0.04564162343740463,
      -0.03123760037124157,
      0.009451922960579395,
      0.07164502888917923,
      0.025455649942159653,
      -0.01316896267235279,
      -0.03262440115213394,
      -0.076253741979599,
      -0.0311886016279459,
      -0.058077212423086166,
      -0.00030404850258491933,
      -0.07252674549818039,
      0.028229668736457825,
      0.0244638379663229,
      0.01367117278277874,
      0.01800874061882496,
      -0.09565352648496628,
      -0.025471549481153488,
      -0.09028027951717377,
      -0.019340885803103447,
      0.029883377254009247,
      0.04854508489370346,
      0.04488420486450195,
      0.027351902797818184,
      0.005294203758239746,
      0.008433333598077297,
      -0.0033023732248693705,
      -0.019409120082855225,
      -0.03875741362571716,
      -0.051673248410224915,
      -0.06233307346701622,
      0.0008025715942494571,
      0.025538241490721703,
      -0.020602038130164146,
      -0.023156989365816116,
      -0.041091322898864746,
      0.04738985374569893,
      0.0446016788482666,
      0.0050244638696312904,
      0.0431140772998333,
      0.02212211862206459,
      0.05349096283316612,
      -0.019492346793413162,
      0.07388243824243546,
      0.04353614151477814,
      0.06578607112169266,
      -0.03897271677851677,
      0.09242750704288483,
      0.034273382276296616,
      -0.014406598173081875,
      -0.014629282988607883,
      -0.0009287702850997448,
      -0.004325062967836857,
      0.0037558998446911573,
      0.036125730723142624,
      0.03103194758296013,
      0.06353158503770828,
      -0.14956039190292358,
      -0.07304049283266068,
      -0.049230609089136124,
      -0.014041755348443985,
      0.011040755547583103,
      -0.042606085538864136,
      -0.021704023703932762,
      0.03099112957715988,
      -0.014846492558717728,
      0.020357510074973106,
      0.059598058462142944,
      0.024586737155914307,
      0.012378880754113197,
      -0.03931685909628868,
      0.05365590378642082,
      -0.060862116515636444,
      -0.027806008234620094,
      0.004602531436830759,
      -0.030311208218336105,
      -0.012729744426906109,
      0.017100142315030098,
      0.03793833777308464,
      0.01856534369289875,
      0.06350581347942352,
      0.09646417945623398,
      -0.06638940423727036,
      -0.02839544229209423,
      0.03212949261069298,
      0.0032439655624330044,
      -0.014839831739664078,
      -0.0008185438346117735,
      0.010726068168878555,
      -0.04598835110664368,
      0.021779309958219528,
      0.02393156662583351,
      0.03681063652038574,
      -0.03972407802939415,
      -0.01309176068753004,
      -0.034300461411476135,
      -0.044016867876052856,
      0.04092251881957054,
      0.014357151463627815,
      -0.013352727517485619,
      0.029975494369864464,
      -0.012805798090994358,
      -0.02069196105003357,
      0.01182654406875372,
      0.01841220259666443,
      -0.023543531075119972,
      0.08583546429872513,
      -0.0924113467335701,
      0.003973295912146568,
      0.037491679191589355,
      -0.02747567556798458,
      0.05115543678402901,
      0.024775240570306778,
      0.032666902989149094,
      -0.014623470604419708,
      -0.012081979773938656,
      -0.017356857657432556,
      0.08733479678630829,
      0.02866549603641033,
      0.003519183024764061,
      -0.015609947964549065,
      0.0836673304438591,
      -0.02519618533551693,
      -0.07352854311466217,
      -0.018028320744633675,
      0.02644467167556286,
      -0.022032206878066063,
      0.019837209954857826,
      0.039953891187906265,
      -0.003955600783228874,
      -0.05710834264755249,
      0.04084770008921623,
      -0.053019165992736816,
      -0.01797943189740181,
      -0.010864345356822014,
      -0.07805674523115158,
      -0.037361763417720795,
      -0.05272174999117851,
      -0.050931692123413086,
      0.0242884773761034,
      -0.009771525859832764,
      -0.02272598072886467,
      -0.030331378802657127,
      0.0018868658225983381,
      0.019815370440483093,
      0.06405956298112869,
      -0.0234419833868742,
      0.020056340843439102,
      0.04053794965147972,
      -0.03354028984904289,
      0.020728815346956253,
      -0.08497964590787888,
      0.05482056736946106,
      -0.06217891722917557,
      -0.015840426087379456,
      -0.025605550035834312,
      0.005402375478297472,
      0.08942285925149918,
      -0.027409527450799942,
      0.002858222695067525,
      0.005010821390897036,
      -0.054905567318201065,
      -0.05323084071278572,
      -0.054756488651037216,
      -0.011791612021625042,
      0.05769365280866623,
      0.10061399638652802,
      -0.046410463750362396,
      -0.052833884954452515,
      0.01758757047355175,
      -0.0864972248673439,
      0.026719538494944572,
      0.02159973233938217,
      -0.014261932112276554,
      -0.02104584313929081,
      0.044764552265405655,
      0.08378656953573227,
      0.00020479584054555744,
      -0.04714187607169151,
      0.07844889163970947,
      0.00619707815349102,
      0.022747663781046867,
      -0.049690648913383484,
      -0.054939355701208115,
      -0.026907391846179962,
      0.014292990788817406,
      -0.0011819185456261039,
      -0.029069606214761734,
      0.048929762095212936,
      -0.03252115100622177,
      0.10058460384607315,
      0.06183610484004021,
      0.015185343101620674,
      -0.018309108912944794,
      0.06132425367832184,
      0.044814903289079666,
      0.06207449734210968,
      -0.019717711955308914,
      -0.07256292551755905,
      0.027044160291552544,
      -0.05810369923710823,
      0.06586351990699768,
      -0.018321096897125244,
      -0.010922394692897797,
      -0.0016888572135940194,
      0.03730049729347229,
      0.03868468105792999,
      0.08489400148391724,
      0.06333805620670319,
      -0.021943343803286552,
      -0.031163616105914116,
      0.03708852827548981,
      -0.025650056079030037,
      0.017534637823700905,
      -0.020533191040158272,
      -0.010175094939768314,
      -0.08950236439704895,
      0.04602618142962456,
      -0.07086416333913803,
      -0.026563623920083046,
      0.077864870429039,
      -0.0039178780280053616,
      -0.030767003074288368,
      0.006160429213196039,
      -0.03386734426021576,
      -0.11091947555541992,
      -0.04131308197975159,
      0.05172540619969368,
      -0.06392274796962738,
      -0.012558158487081528,
      -0.00611106026917696,
      0.011712546460330486,
      0.03391131013631821,
      0.07776103168725967,
      0.009922027587890625,
      0.033736567944288254,
      0.027979161590337753,
      0.03473195433616638,
      0.010062320157885551,
      0.0788264349102974,
      -0.05312288925051689,
      -0.07695652544498444,
      -0.010235904715955257,
      -0.07784927636384964,
      -0.024539612233638763,
      -0.04892688989639282,
      -0.009122274816036224,
      0.004444700665771961,
      0.07641977816820145,
      0.03438958525657654,
      -0.0786895751953125,
      -0.0059692454524338245,
      0.034063082188367844,
      -0.005769929848611355,
      0.09031637758016586,
      -0.0531713105738163,
      0.008736057206988335,
      -0.0960221216082573,
      -0.07297965884208679,
      0.037154145538806915,
      0.019639266654849052,
      -0.028447577729821205,
      0.040113665163517,
      -0.0023188195191323757,
      0.003073719097301364,
      -0.04528592899441719,
      0.03222981467843056,
      -0.05270683765411377,
      -0.03698530048131943,
      -0.06743776798248291,
      0.011220854707062244,
      0.04402827098965645,
      -0.04072021320462227,
      -0.014437953941524029,
      -0.06737176328897476,
      -0.00859226007014513,
      0.037099141627550125,
      0.027816180139780045,
      -0.046246450394392014,
      -0.06122837960720062,
      -0.017897995188832283,
      -0.027874261140823364,
      -0.06684113293886185,
      -0.0512724407017231,
      -0.05588687211275101,
      -0.045374251902103424,
      -0.0121225006878376,
      0.02611648105084896,
      -0.04327617213129997,
      0.004922856111079454,
      -0.0046119666658341885,
      0.012801756151020527,
      -0.01383332908153534,
      0.01652386225759983,
      0.021863488480448723,
      -0.05679256469011307,
      0.06731143593788147,
      -0.06179460138082504,
      -0.04789489880204201,
      0.06054268777370453,
      0.0376623272895813,
      -0.05522811412811279,
      -0.026549700647592545,
      -0.04534783586859703,
      -0.024612367153167725,
      0.050463683903217316,
      0.06373808532953262,
      -0.053552113473415375,
      0.005450709257274866,
      -0.0014205774059519172,
      0.08258752524852753,
      0.0050896513275802135,
      0.0748533383011818,
      0.013866154477000237,
      -0.0774863064289093,
      0.02582242526113987,
      0.02287692204117775,
      0.022363701835274696,
      0.030577458441257477,
      -0.03995124250650406,
      -0.018246065825223923,
      -0.06231929361820221,
      0.009502585977315903,
      -0.011635382659733295,
      -0.04340279474854469,
      0.07967264950275421,
      0.03174132853746414,
      0.09401874244213104,
      0.07466432452201843,
      -0.09788139164447784,
      0.028316039592027664,
      -0.11013233661651611,
      -0.07375577837228775,
      -0.05226878449320793,
      0.041129376739263535,
      0.033438023179769516,
      -0.06620043516159058,
      -0.032364942133426666,
      0.01546191330999136,
      -0.026155216619372368,
      -0.018630316480994225,
      -0.033524952828884125,
      -0.04481067508459091,
      0.06349196285009384,
      0.011944849044084549,
      -0.03466765955090523,
      -0.0031714001670479774,
      0.0668698325753212,
      0.008455983363091946,
      -0.024536948651075363,
      -0.10404892265796661,
      0.008556348271667957,
      -0.020501017570495605,
      8.368829730898142e-05,
      -0.03913513198494911,
      -0.07153131067752838,
      -0.03950486332178116,
      0.09982714802026749,
      0.018195800483226776,
      -0.08817532658576965,
      -0.03501906245946884,
      -0.003013295354321599,
      0.005144970957189798,
      -0.016047654673457146,
      -0.015065990388393402,
      -0.05992889404296875,
      -0.03278261050581932,
      -0.09135404229164124,
      0.041509997099637985,
      0.022353628650307655,
      0.03385002538561821,
      -0.01797165907919407,
      -0.047937359660863876,
      -0.0022644237615168095,
      0.004876779392361641,
      0.006868249271064997,
      -0.026581572368741035,
      0.05213324353098869,
      -0.09280490130186081,
      0.06620731204748154,
      -0.005749296862632036,
      0.010054899379611015,
      -0.029148723930120468,
      0.016694797202944756,
      0.0016589048318564892,
      -0.031265825033187866,
      -0.013737215660512447,
      -0.05910050868988037,
      0.01381614152342081,
      -0.02372930757701397,
      -0.001976483967155218,
      -0.0218537375330925
    ],
    [
      0.017260123044252396,
      -0.0130007890984416,
      0.01305369846522808,
      0.08924123644828796,
      -0.06800214946269989,
      0.05143459886312485,
      0.06349167227745056,
      -0.0697953924536705,
      0.08788630366325378,
      -0.0699877142906189,
      -0.06939993798732758,
      -0.06686267256736755,
      -0.021388830617070198,
      -0.05524924024939537,
      -0.013904386200010777,
      -0.032080359756946564,
      -0.027283988893032074,
      0.047124627977609634,
      0.08015703409910202,
      0.023881997913122177,
      0.04708249121904373,
      0.035363659262657166,
      0.010736537165939808,
      0.013264916837215424,
      0.08067965507507324,
      0.016281966120004654,
      0.018225135281682014,
      -0.002621545223519206,
      -0.07649460434913635,
      -0.0042750597931444645,
      -0.013563385233283043,
      -0.03724055737257004,
      -0.0696629136800766,
      0.011708613485097885,
      0.09808282554149628,
      0.03007590025663376,
      0.030274424701929092,
      0.01119348406791687,
      0.026151534169912338,
      0.009557872079312801,
      -0.06738892197608948,
      -0.05952981114387512,
      -0.03428444266319275,
      0.03613501414656639,
      0.033441126346588135,
      0.060744404792785645,
      -0.07021350413560867,
      -0.04825723543763161,
      0.05621384084224701,
      0.01386276725679636,
      -0.04583125561475754,
      0.06757710129022598,
      0.04426248371601105,
      0.07636382430791855,
      0.06988564878702164,
      -0.07381480187177658,
      -0.017205629497766495,
      0.04398130625486374,
      -0.037719015032052994,
      0.03897959366440773,
      -0.04732764512300491,
      0.007287229876965284,
      0.000577764876652509,
      0.050129443407058716,
      0.06215353682637215,
      -0.009011121466755867,
      -0.032065972685813904,
      -0.050022438168525696,
      -0.1809161901473999,
      -0.01640787161886692,
      -0.03800938278436661,
      -0.06140400096774101,
      0.013229766860604286,
      -0.03882801905274391,
      0.029617277905344963,
      -0.003972413018345833,
      0.03992365673184395,
      0.038680676370859146,
      0.07496682554483414,
      0.029415283352136612,
      0.008413703180849552,
      -0.019678879529237747,
      0.05548561364412308,
      -0.07842367887496948,
      0.04877779260277748,
      0.006640423089265823,
      -0.017243411391973495,
      -0.0895538181066513,
      -0.036562953144311905,
      0.02904527448117733,
      0.0016436506994068623,
      0.07207230478525162,
      -0.015125432051718235,
      -0.02089873142540455,
      0.006547382567077875,
      -0.03299563005566597,
      -0.020135778933763504,
      0.04147019237279892,
      -0.002657160395756364,
      -0.020754273980855942,
      -0.0320400707423687,
      0.05121375247836113,
      -0.056959882378578186,
      0.14977040886878967,
      -0.023103244602680206,
      -0.07082806527614594,
      0.05156518891453743,
      0.002864604350179434,
      -0.026670221239328384,
      -0.08954661339521408,
      0.0600912906229496,
      -0.008568991906940937,
      0.07565228641033173,
      0.021043624728918076,
      0.023504748940467834,
      -0.03946501761674881,
      0.0008189402869902551,
      -0.06611567735671997,
      0.04491758346557617,
      0.03975487872958183,
      -0.0366457961499691,
      0.005960300099104643,
      0.011126026511192322,
      0.028925850987434387,
      0.08848889172077179,
      0.11275861412286758,
      0.010626343078911304,
      -0.04784497246146202,
      0.019462943077087402,
      0.04213996231555939,
      0.019490258768200874,
      0.026851724833250046,
      -0.03837691992521286,
      -0.0514792874455452,
      0.05050640553236008,
      -0.0475323386490345,
      0.01796194165945053,
      0.03366466611623764,
      0.059861283749341965,
      0.07657286524772644,
      -0.0797426700592041,
      0.026042519137263298,
      -0.040377646684646606,
      0.06815582513809204,
      0.07169663906097412,
      0.02139182575047016,
      -0.022200653329491615,
      0.033400800079107285,
      -0.023217296227812767,
      0.010826526209712029,
      0.005936690140515566,
      0.017135027796030045,
      0.038863036781549454,
      -0.027677021920681,
      0.06354574859142303,
      0.09916791319847107,
      0.05118085443973541,
      0.027557848021388054,
      0.02557426318526268,
      -0.05120060592889786,
      -0.02230420708656311,
      0.009416244924068451,
      0.02044643461704254,
      0.01610608771443367,
      -0.021336402744054794,
      0.021595072001218796,
      0.0324862040579319,
      0.0524640791118145,
      0.022152988240122795,
      0.002770685590803623,
      -0.03482988476753235,
      0.016474084928631783,
      -0.043237220495939255,
      0.030495742335915565,
      0.009889568202197552,
      0.007854148745536804,
      -0.08431989699602127,
      0.044854532927274704,
      -0.0016713979421183467,
      -0.06096707284450531,
      -0.055192794650793076,
      0.12285897880792618,
      -0.018923424184322357,
      0.01704031229019165,
      -0.02807948924601078,
      0.06178690865635872,
      0.036662325263023376,
      -0.07911041378974915,
      0.028243619948625565,
      0.014806842431426048,
      0.04781953617930412,
      -0.05430644005537033,
      -0.020060330629348755,
      -0.056605350226163864,
      0.007523064501583576,
      -0.04773664101958275,
      -0.004196550231426954,
      0.011378581635653973,
      0.0030164916533976793,
      0.020584961399435997,
      -0.034174852073192596,
      -0.08123236894607544,
      0.032049067318439484,
      -0.07750028371810913,
      0.027753330767154694,
      -0.01449563354253769,
      0.07224200665950775,
      0.008656132966279984,
      0.00882983859628439,
      0.04818246513605118,
      0.04485928267240524,
      -0.0438157357275486,
      -0.021964961662888527,
      -0.004997873213142157,
      -0.003262630430981517,
      -0.03190245106816292,
      0.0757204219698906,
      0.042996685951948166,
      0.0026053546462208033,
      -0.06502502411603928,
      0.06474034488201141,
      0.04973047226667404,
      0.06560320407152176,
      0.049229830503463745,
      -0.02875637263059616,
      0.02096560038626194,
      -0.02812849171459675,
      -0.06969714164733887,
      0.0735427588224411,
      0.06533531844615936,
      0.02343996986746788,
      -0.03414617106318474,
      0.057358112186193466,
      -0.03534522280097008,
      0.005423495080322027,
      0.033760931342840195,
      0.04122272878885269,
      -0.05549440532922745,
      0.03125717490911484,
      0.04199393466114998,
      0.0014652792597189546,
      -0.014190013520419598,
      -0.01692066714167595,
      -0.002018397208303213,
      -0.009601197205483913,
      -0.06911252439022064,
      -0.0771331861615181,
      -0.01907503418624401,
      0.04768556356430054,
      -0.005118167959153652,
      0.055070068687200546,
      -0.0006507653743028641,
      -0.007916074246168137,
      0.03305409103631973,
      -0.019572922959923744,
      0.004469757899641991,
      -0.016015024855732918,
      0.0182329174131155,
      -0.01572755165398121,
      -0.028172509744763374,
      0.029420219361782074,
      -0.014197586104273796,
      -0.03689764812588692,
      0.03578745573759079,
      -0.02654380537569523,
      0.02240937575697899,
      -0.01484638825058937,
      0.005881338845938444,
      -0.017978860065340996,
      0.005768586415797472,
      0.10048021376132965,
      -0.051827069371938705,
      0.028029736131429672,
      0.037986595183610916,
      0.09407231956720352,
      0.1111670657992363,
      0.10171881318092346,
      0.019821470603346825,
      0.03514094278216362,
      0.06672294437885284,
      -0.016423553228378296,
      0.017542455345392227,
      0.05735468864440918,
      0.01998702995479107,
      -0.013994988985359669,
      -0.04803622141480446,
      0.07405154407024384,
      -0.03965796157717705,
      -0.014026110991835594,
      0.01990482769906521,
      -0.017608659341931343,
      -0.006908675655722618,
      -0.0320197157561779,
      0.001856919494457543,
      0.03174619749188423,
      0.03869173303246498,
      0.018126308917999268,
      0.04653621464967728,
      -0.02389022335410118,
      -0.008538846857845783,
      -0.04030221328139305,
      -0.02574295550584793,
      -0.005052992608398199,
      -0.01873978041112423,
      -0.04111003130674362,
      0.02244601957499981,
      0.029968151822686195,
      0.01008799858391285,
      -0.01209541130810976,
      0.03085659071803093,
      -0.005566032137721777,
      0.0023656440898776054,
      0.004918298684060574,
      -0.0205202866345644,
      -0.014432753436267376,
      0.0425269640982151,
      0.004419408738613129,
      -0.027128716930747032,
      0.04380680248141289,
      -0.08091039210557938,
      -0.00932135246694088,
      -0.01885254494845867,
      0.041757285594940186,
      -0.0031545863021165133,
      -0.012889569625258446,
      0.028578532859683037,
      0.05787824094295502,
      0.04504615068435669,
      -0.06585986167192459,
      0.02772192843258381,
      -0.010771063156425953,
      -0.06810809671878815,
      -0.013755970634520054,
      0.004997359588742256,
      0.008676565252244473,
      -0.012354464270174503,
      -0.021076584234833717,
      0.012955639511346817,
      -0.018157463520765305,
      -0.009016325697302818,
      -0.06849728524684906,
      -0.009511860087513924,
      0.022976208478212357,
      0.034773971885442734,
      -0.002850192366167903,
      -0.0615982823073864,
      -0.04186057671904564,
      -0.028344787657260895,
      -0.004168251994997263,
      0.03521934524178505,
      0.007213334087282419,
      0.062324441969394684,
      -0.010841523297131062,
      -0.013162488117814064,
      0.00799959059804678,
      -0.03763556480407715,
      -3.3081673791457433e-06,
      -0.12421174347400665,
      -0.02381207048892975,
      0.05799812823534012,
      0.02051970176398754,
      -0.014189202338457108,
      0.006534414365887642,
      -0.05646974593400955,
      0.007913777604699135,
      0.055243778973817825,
      0.013352267444133759,
      -0.0683949738740921,
      0.002834826475009322,
      0.034340742975473404,
      0.045780546963214874,
      -0.04680224135518074,
      -0.03048226237297058,
      0.019805800169706345,
      -0.0038747668731957674,
      -0.02975372038781643,
      0.0722956731915474,
      0.00975326169282198,
      0.019327333196997643,
      -0.12271970510482788,
      -0.03245115652680397,
      0.013003244064748287,
      -0.03643320873379707,
      0.017522277310490608,
      -0.05689161643385887,
      0.024576187133789062,
      -0.02321544848382473,
      0.06798908114433289,
      0.0073961797170341015,
      -0.03768300637602806,
      -0.017993243411183357,
      -0.05261591449379921,
      0.050909798592329025,
      0.02284066565334797,
      -0.0667836144566536,
      -0.03138219565153122,
      -0.030454574152827263,
      0.01571720279753208,
      -0.04233928024768829,
      -0.009262981824576855,
      0.07611516863107681,
      -0.07228393107652664,
      0.02208869159221649,
      0.020637154579162598,
      -0.033595867455005646,
      0.01864769123494625,
      -0.013027979992330074,
      0.0029942807741463184,
      0.008150407113134861,
      0.030069833621382713,
      -0.022230831906199455,
      -0.029939662665128708,
      0.0010498843621462584,
      -0.0027544882614165545,
      -0.10024084895849228,
      0.02315516211092472,
      -0.04828350245952606,
      -0.008620073087513447,
      0.025694942101836205,
      0.0779072716832161,
      0.015784917399287224,
      0.07098684459924698,
      -0.037716157734394073,
      -0.04307398572564125,
      0.06233230605721474,
      -0.0645756721496582,
      0.016302499920129776,
      0.10059215128421783,
      0.08772245794534683,
      2.0086443328182213e-05,
      -0.10535173118114471,
      0.08103382587432861,
      -0.06089802086353302,
      0.09028393775224686,
      -0.07426898181438446,
      0.016529621556401253,
      -0.087697334587574,
      -0.06300552934408188,
      0.05583548545837402,
      -0.031141478568315506,
      0.020182613283395767,
      0.0025471607223153114,
      0.04142933711409569,
      -0.015573112294077873,
      0.054079651832580566,
      0.06791145354509354,
      -0.08326034992933273,
      0.03739690035581589,
      0.06744739413261414,
      -0.007590562105178833,
      0.03547718748450279,
      -0.01612883433699608,
      -0.003239752957597375,
      0.03295019268989563,
      -0.009485027752816677,
      0.051630593836307526,
      0.010158704593777657,
      0.018543323501944542,
      0.03199736401438713,
      0.0428512766957283,
      0.013148673810064793,
      -0.03632967546582222,
      -0.016032522544264793,
      -0.058399081230163574,
      0.12053011357784271,
      -0.022608360275626183,
      -0.05210432410240173,
      0.02647787146270275,
      0.09101603180170059,
      -0.0723988488316536,
      -0.06421907246112823,
      -0.019377458840608597,
      -0.03516487032175064,
      -0.04592768847942352,
      0.04062682017683983,
      0.06975818425416946,
      -0.005071206018328667,
      0.02705240622162819,
      -0.0400460883975029,
      -0.042123209685087204,
      -0.06362062692642212,
      0.013221374712884426,
      -0.006464576814323664,
      0.01472555659711361,
      -0.0685090497136116,
      0.07333052158355713,
      -0.051057007163763046,
      0.10747414827346802,
      0.05313649773597717,
      0.005476216319948435,
      0.052384886890649796,
      -0.05138973519206047,
      0.007583451922982931,
      0.012208082713186741,
      -0.09273137897253036,
      0.0029763197526335716,
      0.03638350963592529,
      0.030834542587399483,
      -0.02079714834690094,
      -0.04061819985508919,
      -0.03966197744011879,
      0.022497419267892838,
      0.01975301466882229
    ],
    [
      0.11629702150821686,
      0.011820035055279732,
      0.02000844106078148,
      0.0679749920964241,
      -0.007809674367308617,
      -0.002761188196018338,
      0.006785671692341566,
      0.03554195165634155,
      -0.04258360341191292,
      -0.1430906057357788,
      -0.006005618721246719,
      -0.046559423208236694,
      0.051010213792324066,
      -0.0029794296715408564,
      0.0006194430170580745,
      0.03367305546998978,
      -0.02257305383682251,
      -0.009538961574435234,
      0.009909555315971375,
      0.006306127179414034,
      0.03257331624627113,
      -0.0364193432033062,
      -0.010091803967952728,
      -0.007937496528029442,
      -0.004146005026996136,
      0.047895900905132294,
      -0.0561249814927578,
      0.02095104567706585,
      0.054112162441015244,
      -0.08406846225261688,
      0.033244166523218155,
      -0.013629435561597347,
      -0.0754777267575264,
      0.010562500916421413,
      0.05159073695540428,
      0.030459297820925713,
      0.07969512045383453,
      0.014284001663327217,
      -0.0036340011283755302,
      0.007117574568837881,
      0.04125434160232544,
      -0.046877484768629074,
      0.01847599260509014,
      0.01246743556112051,
      0.0582348071038723,
      0.030386587604880333,
      0.08350933343172073,
      -0.06669306755065918,
      -0.034567464143037796,
      0.04332221671938896,
      0.05736185982823372,
      0.03238536790013313,
      -0.037220824509859085,
      0.01320323720574379,
      -0.01772209070622921,
      0.020417891442775726,
      0.020386602729558945,
      -0.04222233220934868,
      0.040095653384923935,
      -0.07853612303733826,
      -0.015799976885318756,
      0.005609279032796621,
      0.13281631469726562,
      0.04695066064596176,
      -0.04041249305009842,
      -0.013958473689854145,
      0.008001037873327732,
      -0.004520912654697895,
      0.06637338548898697,
      0.010004265233874321,
      -0.04923439770936966,
      0.07361631095409393,
      0.04280846193432808,
      -0.09007138013839722,
      -0.002580635715276003,
      -0.022939283400774002,
      -0.0012713044416159391,
      0.020569974556565285,
      -0.023863108828663826,
      -0.006311362609267235,
      0.03747363016009331,
      0.02372599020600319,
      -0.057732727378606796,
      0.01657608337700367,
      0.05006856843829155,
      0.026337848976254463,
      -0.0051808906719088554,
      0.0178231131285429,
      -0.04272093251347542,
      0.018969980999827385,
      0.07041379809379578,
      -0.022778380662202835,
      -0.09071710705757141,
      0.003054435830563307,
      -0.04374030977487564,
      0.0212730560451746,
      -0.022448435425758362,
      0.055473748594522476,
      0.014352777972817421,
      0.015000552870333195,
      -0.007008640095591545,
      -0.05911297723650932,
      -0.030664630234241486,
      0.03038754127919674,
      0.022021908313035965,
      -0.04515993222594261,
      0.0590565986931324,
      -0.06391742080450058,
      0.004754373803734779,
      -0.0073288818821311,
      0.06529313325881958,
      -0.015148619189858437,
      0.036301564425230026,
      0.01917118765413761,
      0.01010211743414402,
      -0.006046680733561516,
      0.003350074402987957,
      0.06295406073331833,
      0.027864227071404457,
      0.038730233907699585,
      -0.031710851937532425,
      -0.028771203011274338,
      -0.013844679109752178,
      0.033213891088962555,
      0.09002619981765747,
      0.07559436559677124,
      0.0009059018339030445,
      -0.03186153993010521,
      0.06168520078063011,
      0.021423665806651115,
      0.020008087158203125,
      -0.0792221799492836,
      0.07222706079483032,
      -0.039681777358055115,
      -0.002516023814678192,
      -0.0001961919479072094,
      0.010870694182813168,
      0.016420001164078712,
      0.01749706082046032,
      -0.010632418096065521,
      0.011521433480083942,
      0.03579287976026535,
      0.012539602816104889,
      -0.0002464248100295663,
      -0.04883811995387077,
      -0.03492039442062378,
      -0.02153349108994007,
      -0.06622982025146484,
      -0.05800338834524155,
      0.0052534653805196285,
      0.009691046550869942,
      -0.05850955843925476,
      0.03065452352166176,
      -0.07084467262029648,
      0.0544489249587059,
      -0.0125178387388587,
      0.0094899358227849,
      0.010217082686722279,
      -0.04538940638303757,
      0.11095751076936722,
      -0.09644270688295364,
      0.06988760083913803,
      -0.02216746099293232,
      -0.005646539852023125,
      -0.019205406308174133,
      0.03581513091921806,
      -0.06967706233263016,
      -0.06848608702421188,
      0.01569637656211853,
      0.03954416140913963,
      0.043607015162706375,
      -0.008426281623542309,
      -0.05599625036120415,
      -0.04435329511761665,
      -0.010419371537864208,
      0.0030910989735275507,
      -0.0026752364356070757,
      -0.03722269833087921,
      0.0751710757613182,
      0.002250034362077713,
      0.03775808960199356,
      0.07753270119428635,
      0.02479429356753826,
      -0.026536626741290092,
      -0.016704516485333443,
      -0.022433292120695114,
      0.01003288570791483,
      0.0495067797601223,
      -0.007216107565909624,
      -0.037416569888591766,
      0.053390394896268845,
      0.0777466744184494,
      -0.02383619174361229,
      -0.04286964237689972,
      0.03815269097685814,
      0.08730392903089523,
      0.029816899448633194,
      -0.00010228316386928782,
      0.05700184032320976,
      -0.0028553646989166737,
      0.0022797954734414816,
      0.044992826879024506,
      -0.08432695269584656,
      -0.03496624529361725,
      0.035986993461847305,
      -0.053517937660217285,
      -0.059064384549856186,
      0.012206688523292542,
      -0.0028204619884490967,
      0.07087522000074387,
      -0.1065828874707222,
      -0.004018917214125395,
      0.016471415758132935,
      -0.01667705737054348,
      0.05157805606722832,
      -0.04392605274915695,
      0.032016389071941376,
      -0.020154733210802078,
      0.024477463215589523,
      0.031242283061146736,
      -0.037787310779094696,
      0.05111132562160492,
      0.03720659390091896,
      0.08032761514186859,
      0.10031913965940475,
      -0.008087033405900002,
      -0.06593076884746552,
      -0.06872032582759857,
      0.0728970617055893,
      0.045425571501255035,
      0.025407791137695312,
      -0.041783407330513,
      0.015537470579147339,
      0.04634759575128555,
      -0.12194934487342834,
      0.07582271099090576,
      -0.005677034147083759,
      0.06302078813314438,
      0.029600052163004875,
      -0.04346109926700592,
      -0.06057898700237274,
      -0.012756302021443844,
      0.05805588141083717,
      -0.018337411805987358,
      -0.03231662139296532,
      0.03295855596661568,
      -0.003235388547182083,
      -0.11746810376644135,
      0.0050740549340844154,
      0.0018508562352508307,
      -0.06155548617243767,
      -0.032054971903562546,
      0.08734700083732605,
      0.0045118858106434345,
      0.0014940472319722176,
      -0.014581362716853619,
      -0.03688574209809303,
      -0.011492484249174595,
      0.01139683648943901,
      0.024766666814684868,
      -0.04636983945965767,
      0.039734337478876114,
      -0.007961004972457886,
      0.030891267582774162,
      0.052644580602645874,
      0.04383370280265808,
      -0.013354826718568802,
      -0.06205949932336807,
      0.08233007788658142,
      -0.055814895778894424,
      0.047635920345783234,
      -0.03752482309937477,
      0.03133995085954666,
      0.03823111206293106,
      0.02529294230043888,
      0.04877462983131409,
      0.017299789935350418,
      0.040605127811431885,
      -0.015065882354974747,
      -0.009470709599554539,
      0.03320670127868652,
      0.005880176089704037,
      -0.005087592639029026,
      0.009339243173599243,
      0.02099958434700966,
      -0.013881195336580276,
      -0.043266862630844116,
      -0.05720933526754379,
      0.026062048971652985,
      0.03235712647438049,
      0.03871632367372513,
      -0.002869618823751807,
      -0.03155854716897011,
      -0.04013832286000252,
      0.026469876989722252,
      0.04876801744103432,
      0.002467688173055649,
      -0.01273855660110712,
      -0.05410976707935333,
      -0.07451405376195908,
      0.003940029069781303,
      0.08573611825704575,
      -0.0696166604757309,
      0.006310055963695049,
      0.03132252395153046,
      0.015350463800132275,
      -0.07998190820217133,
      -0.10914088785648346,
      -0.05578736215829849,
      -0.05331895872950554,
      -0.005501420237123966,
      0.014737208373844624,
      -0.05680949613451958,
      -0.009798794984817505,
      -0.03665214031934738,
      0.07677316665649414,
      -0.014013241976499557,
      -0.06769242137670517,
      0.02722940221428871,
      0.13560830056667328,
      -0.0540238693356514,
      -0.10232820361852646,
      0.0050602140836417675,
      0.03731421381235123,
      -0.012509563006460667,
      -0.0229046568274498,
      0.021087471395730972,
      -0.009726510383188725,
      -0.06777779757976532,
      -0.011152383871376514,
      0.0259980671107769,
      -0.04634100943803787,
      -0.03373165801167488,
      -0.05123624578118324,
      0.012473572045564651,
      0.03885801509022713,
      -0.04539328068494797,
      -0.03519422560930252,
      -0.048705413937568665,
      -0.061066243797540665,
      0.02512769214808941,
      -0.002892980817705393,
      0.025289643555879593,
      0.03457162156701088,
      -0.010383917018771172,
      0.04470805451273918,
      -0.0467132031917572,
      0.08916912227869034,
      -0.022406091913580894,
      0.022982917726039886,
      0.011367319151759148,
      -0.05356457084417343,
      -0.142159566283226,
      0.005925754550844431,
      -0.020099801942706108,
      -0.012372205033898354,
      -0.027237216010689735,
      0.006261269561946392,
      -0.013854963704943657,
      0.003038305789232254,
      -0.035806987434625626,
      -0.005986033007502556,
      -0.03213353082537651,
      0.034880150109529495,
      -0.008161814883351326,
      -0.004861952271312475,
      -0.026104329153895378,
      -0.008710289373993874,
      -0.016468649730086327,
      -0.0007218514219857752,
      0.02564670890569687,
      -0.09753107279539108,
      -0.010397064499557018,
      -0.08870840817689896,
      -0.036272916942834854,
      0.04462258517742157,
      0.010224339552223682,
      0.003490158123895526,
      -0.039083331823349,
      -0.02631272003054619,
      -0.09019850194454193,
      0.0957377478480339,
      -0.027905134484171867,
      -0.010219425894320011,
      -0.02030305005609989,
      -0.05613739788532257,
      0.06792145222425461,
      0.028395092114806175,
      -0.047171223908662796,
      -0.054019227623939514,
      -0.03183046355843544,
      -0.09762685745954514,
      0.030319679528474808,
      -0.02021193504333496,
      0.020667895674705505,
      0.025608187541365623,
      0.06935326755046844,
      -0.0925864651799202,
      0.04631772264838219,
      -0.030256036669015884,
      0.059236325323581696,
      -0.08135667443275452,
      -0.06257136166095734,
      0.06474360078573227,
      0.03729398921132088,
      0.06199958920478821,
      -0.040059830993413925,
      -0.026486199349164963,
      0.03348947688937187,
      -0.040922634303569794,
      0.02788364887237549,
      0.04435526952147484,
      0.034052349627017975,
      0.03761446103453636,
      -0.044856783002614975,
      -0.014025681652128696,
      -0.006117793265730143,
      -0.00010749349894467741,
      -0.0768105536699295,
      -0.020164325833320618,
      0.03191341459751129,
      0.018308185040950775,
      0.025529995560646057,
      -0.01573054865002632,
      0.05165386572480202,
      0.027598032727837563,
      -0.0588410384953022,
      0.029946444556117058,
      -0.03618333116173744,
      -0.013394283130764961,
      -0.03632175922393799,
      0.026389654725790024,
      0.0018981706816703081,
      -0.015256910584867,
      -0.010192068293690681,
      -0.08872148394584656,
      -0.04971703886985779,
      -0.02810615859925747,
      0.07832923531532288,
      0.05611748620867729,
      -0.050261132419109344,
      -0.0832943543791771,
      -0.030991673469543457,
      0.012797710485756397,
      0.09194481372833252,
      -0.05742715299129486,
      0.06658733636140823,
      0.02169480361044407,
      0.07444939762353897,
      0.005061369389295578,
      -0.05512190982699394,
      0.02898370288312435,
      -0.06849925220012665,
      0.011164255440235138,
      0.0008925306028686464,
      0.08856990188360214,
      -0.013165296986699104,
      -0.03451569378376007,
      0.005437075160443783,
      0.0020504104904830456,
      0.01684822142124176,
      -0.020683713257312775,
      0.05678829550743103,
      0.025105642154812813,
      -0.056999269872903824,
      0.048060838133096695,
      0.01330376323312521,
      0.02046125754714012,
      -0.08029366284608841,
      0.016097119078040123,
      -0.018153570592403412,
      -0.06103847548365593,
      0.0167161263525486,
      0.08694403618574142,
      -0.01378391869366169,
      0.031300514936447144,
      -0.09038501232862473,
      -0.07167506217956543,
      0.026589658111333847,
      0.008950456976890564,
      -0.07914440333843231,
      -0.07943733781576157,
      0.05274966359138489,
      0.05128920450806618,
      0.06086442247033119,
      0.007423495873808861,
      -0.012955938465893269,
      -0.051910966634750366,
      0.07470422238111496,
      0.10968416929244995,
      0.026018919423222542,
      -0.0630241259932518,
      0.0011912381742149591,
      0.017455313354730606,
      -0.015929730609059334,
      -0.01813005842268467,
      -0.011285954155027866,
      0.04685342684388161,
      -0.053174376487731934,
      -0.0036715988535434008,
      -0.05795229971408844,
      0.024694116786122322,
      0.013763554394245148
    ],
    [
      0.027287332341074944,
      0.0032937119249254465,
      -0.02522900141775608,
      0.08083079010248184,
      -0.0062040709890425205,
      -0.00518784811720252,
      -0.020741134881973267,
      -0.020227007567882538,
      -0.014876794070005417,
      -0.027000412344932556,
      -0.013307093642652035,
      -0.049289654940366745,
      -0.052139561623334885,
      -0.05493194982409477,
      0.026825498789548874,
      -0.003785215551033616,
      0.010809814557433128,
      -0.013472133316099644,
      0.031747933477163315,
      0.03090802952647209,
      -0.039816759526729584,
      -0.06308351457118988,
      0.060390014201402664,
      0.013262913562357426,
      0.046783920377492905,
      -0.019828984513878822,
      0.03757638484239578,
      0.014468759298324585,
      -0.008744342252612114,
      -0.01805911771953106,
      -0.03240320086479187,
      -0.03460882976651192,
      0.021300334483385086,
      -0.014385188929736614,
      -0.01346562709659338,
      0.02125687152147293,
      -0.05194566771388054,
      -0.04569009318947792,
      -0.001461684936657548,
      0.061864160001277924,
      0.005671230610460043,
      0.05271343141794205,
      -0.049331169575452805,
      -0.02837919071316719,
      -0.025815829634666443,
      -0.006814267486333847,
      -0.023243889212608337,
      -0.02074013650417328,
      -0.0032823984511196613,
      0.08114958554506302,
      0.01930074393749237,
      0.02264324203133583,
      0.041824985295534134,
      0.061905037611722946,
      0.04530907794833183,
      0.020052846521139145,
      0.05629075691103935,
      -0.07115381956100464,
      -0.01254662498831749,
      -0.018785951659083366,
      -0.015530371107161045,
      0.011444315314292908,
      -0.071110300719738,
      0.01914561167359352,
      -0.023698724806308746,
      -0.008274083025753498,
      0.033658597618341446,
      -0.0007710367790423334,
      -0.07460961490869522,
      0.025032440200448036,
      -0.10230127722024918,
      0.058115795254707336,
      0.032466694712638855,
      0.014302590861916542,
      -0.014472941868007183,
      -0.07586726546287537,
      -0.02242421545088291,
      -0.011997256428003311,
      0.0036396458745002747,
      0.013153630308806896,
      0.04696834459900856,
      -0.03405186906456947,
      0.023660900071263313,
      0.04713486135005951,
      -0.03449960798025131,
      -0.020400607958436012,
      0.023655705153942108,
      0.01688784919679165,
      -0.0029328661039471626,
      0.03062516450881958,
      0.060380201786756516,
      0.0033585350029170513,
      0.0435623861849308,
      -0.01760532706975937,
      0.007312949281185865,
      0.11307578533887863,
      -0.0295080728828907,
      -0.0014388934941962361,
      0.02093290537595749,
      -0.05327865108847618,
      0.01960097812116146,
      -0.05158640444278717,
      -0.035206180065870285,
      0.012514554895460606,
      -0.05787891522049904,
      -0.0121747562661767,
      0.022114261984825134,
      0.11943668872117996,
      -0.029002605006098747,
      -0.03364655002951622,
      -0.07285433262586594,
      0.1113719493150711,
      -0.024670090526342392,
      -0.0072858803905546665,
      -0.007372843101620674,
      -0.024262910708785057,
      -0.01607530564069748,
      -0.005621119402348995,
      -0.05670192837715149,
      -0.023080013692378998,
      -0.022951368242502213,
      0.004233254585415125,
      0.02426689676940441,
      -0.0002281499473610893,
      0.07820175588130951,
      -0.028282783925533295,
      0.04738466441631317,
      -0.005578582175076008,
      -0.006278529297560453,
      -0.015639912337064743,
      -0.03212808445096016,
      0.005044982302933931,
      -0.0639779269695282,
      -0.012532615102827549,
      0.02995637059211731,
      -0.07326128333806992,
      -0.003154371166601777,
      0.051597874611616135,
      0.03501188009977341,
      -0.07009156048297882,
      -0.028507769107818604,
      0.00927119143307209,
      -0.0735640823841095,
      -0.04771021753549576,
      -0.016004515811800957,
      -0.011090919375419617,
      -0.05334249883890152,
      -0.02581702545285225,
      -0.03307262435555458,
      0.0035219064448028803,
      0.09012504667043686,
      -0.031896915286779404,
      0.016653578728437424,
      -0.06406883895397186,
      -0.005041799508035183,
      -0.004146457649767399,
      0.024448731914162636,
      0.012498250231146812,
      0.055813614279031754,
      -0.01296040415763855,
      -0.07067295908927917,
      -0.04146907851099968,
      -0.0071729389019310474,
      0.011317658238112926,
      0.023965608328580856,
      0.012771278619766235,
      -0.008852711878716946,
      -0.0392087921500206,
      -0.030887605622410774,
      -0.0039984919130802155,
      -0.044647395610809326,
      -0.06341508030891418,
      0.0033921832218766212,
      0.03730550780892372,
      -0.026109181344509125,
      -0.007877402007579803,
      -0.0025848799850791693,
      0.020891327410936356,
      -0.0004844688519369811,
      0.042764611542224884,
      -0.0224456787109375,
      -0.07078031450510025,
      0.007069251500070095,
      0.01337715145200491,
      0.025627298280596733,
      -0.07188443839550018,
      0.04115738347172737,
      -0.08226092904806137,
      0.004701151978224516,
      -0.024087579920887947,
      0.040998347103595734,
      -0.04505963250994682,
      0.013477712869644165,
      -0.01036319974809885,
      -0.011306204833090305,
      -0.05217990651726723,
      -0.035096049308776855,
      0.03797253966331482,
      -0.057824429124593735,
      -0.032386522740125656,
      -0.036386698484420776,
      0.06787603348493576,
      -0.02033945731818676,
      0.017629992216825485,
      -0.0018435909878462553,
      -0.028167877346277237,
      0.059698473662137985,
      -0.04489102214574814,
      -0.00914269220083952,
      0.03360806778073311,
      -0.007240218576043844,
      -0.053571879863739014,
      -0.0610959455370903,
      -0.11889365315437317,
      0.048901017755270004,
      -0.08529908955097198,
      0.03213184326887131,
      0.04481564834713936,
      -0.01590696908533573,
      -0.14248482882976532,
      0.01153272669762373,
      0.05598810315132141,
      -0.021028833463788033,
      0.017161842435598373,
      -0.07356169074773788,
      -0.044097334146499634,
      -0.04892485588788986,
      -0.021055808290839195,
      0.021126383915543556,
      0.08400095254182816,
      -0.021345295011997223,
      0.06178545951843262,
      0.04740053787827492,
      -0.01953490450978279,
      -0.015192613005638123,
      -0.08648206293582916,
      0.03903123736381531,
      -0.003799001919105649,
      0.010153396986424923,
      -0.07984135299921036,
      0.0615224689245224,
      0.0860210657119751,
      -0.0001698907872196287,
      0.018611697480082512,
      -0.06157507374882698,
      -0.008918778039515018,
      -0.03598566725850105,
      -0.008636945858597755,
      0.011916950345039368,
      0.003803226863965392,
      -0.0362517423927784,
      -0.027413584291934967,
      -0.047424547374248505,
      -0.04901878535747528,
      0.07752262055873871,
      -0.006306050810962915,
      0.00036546296905726194,
      -0.027153262868523598,
      0.002332355361431837,
      -0.00479317968711257,
      -0.04703333601355553,
      0.021939367055892944,
      -0.03615988790988922,
      0.002706154715269804,
      -0.016484161838889122,
      0.03923090919852257,
      0.047128647565841675,
      0.029469726607203484,
      0.07674816250801086,
      0.07270725071430206,
      0.017530256882309914,
      0.0025051606353372335,
      -0.0005719935870729387,
      -0.1288873851299286,
      -0.014104209840297699,
      0.021985860541462898,
      0.011756449937820435,
      0.018601395189762115,
      -0.03024418093264103,
      -0.14957523345947266,
      -0.039069779217243195,
      -0.015053734183311462,
      0.003886319464072585,
      -0.10545726120471954,
      -0.047791749238967896,
      -0.016124311834573746,
      -0.016976820304989815,
      -0.014666293747723103,
      -0.03894970566034317,
      0.00752254156395793,
      0.02099061757326126,
      0.02304939180612564,
      0.05240253731608391,
      -0.005108078476041555,
      0.03422921895980835,
      -0.025408955290913582,
      0.024099865928292274,
      0.04802405461668968,
      -0.07959792762994766,
      0.0074848695658147335,
      0.015963850542902946,
      0.003568419488146901,
      -0.032383691519498825,
      0.000646259868517518,
      0.06245316192507744,
      -0.026036472991108894,
      0.055896494537591934,
      -0.061434485018253326,
      -0.01124651450663805,
      -0.0064618587493896484,
      -0.012290188111364841,
      0.06738251447677612,
      -0.021552585065364838,
      0.047821540385484695,
      0.02494077943265438,
      -0.078940249979496,
      -0.04203280434012413,
      0.06669391691684723,
      -0.020836198702454567,
      -0.007938332855701447,
      0.049786526709795,
      -0.004296107217669487,
      0.0387391597032547,
      0.059093818068504333,
      0.0626579001545906,
      -0.04049887880682945,
      0.12709097564220428,
      -0.03565067797899246,
      -0.049356859177351,
      -0.013639806769788265,
      0.006030449643731117,
      -0.05461252108216286,
      -0.0007653518114238977,
      0.01220500934869051,
      0.0063210586085915565,
      -0.01580611802637577,
      -0.0014581106370314956,
      -0.10497729480266571,
      -0.00909635704010725,
      0.030842341482639313,
      0.021008165553212166,
      -0.005893162917345762,
      -0.02298443391919136,
      -0.022716347128152847,
      0.047446105629205704,
      -0.10328319668769836,
      0.029479525983333588,
      0.039014969021081924,
      -0.015146171674132347,
      -0.06390944123268127,
      0.029914099723100662,
      -0.033928968012332916,
      0.06348767131567001,
      0.060913920402526855,
      0.0341971293091774,
      -0.03775275498628616,
      -0.03325405344367027,
      0.03089633584022522,
      0.012978757731616497,
      -0.03932531550526619,
      -0.036286063492298126,
      0.016131265088915825,
      -0.01604154147207737,
      0.010782969184219837,
      -0.08184237778186798,
      -0.04035704582929611,
      0.014359794557094574,
      0.035709865391254425,
      -0.06551280617713928,
      -0.05717490240931511,
      0.01629035174846649,
      0.021643970161676407,
      -0.014863272197544575,
      -0.025434933602809906,
      0.007519464008510113,
      -0.05225134640932083,
      0.0351586788892746,
      0.033307988196611404,
      0.010113179683685303,
      -0.03428570181131363,
      -0.04441150277853012,
      0.008669567294418812,
      -0.002545627299696207,
      0.01974649541079998,
      0.009663349017500877,
      -0.0006938639562577009,
      0.018503567203879356,
      -0.004744792822748423,
      -0.018621474504470825,
      0.04241909831762314,
      0.03005307726562023,
      -0.02551295794546604,
      0.02600213699042797,
      0.005925554782152176,
      -0.023462891578674316,
      0.013948804698884487,
      0.005977673921734095,
      -0.05768847465515137,
      -0.03401101753115654,
      0.029394593089818954,
      -0.015625732019543648,
      -0.02961747907102108,
      0.062239035964012146,
      0.020550398156046867,
      0.023953614756464958,
      0.05747412145137787,
      -0.015760568901896477,
      -0.030873866751790047,
      -0.030837280675768852,
      0.047431133687496185,
      0.02758793719112873,
      -0.08501965552568436,
      -0.016219738870859146,
      0.024594277143478394,
      -0.06840243935585022,
      0.023822765797376633,
      -0.037095800042152405,
      -0.07043733447790146,
      0.02010856196284294,
      0.07310868799686432,
      0.04578277841210365,
      0.09184741228818893,
      0.0024827755987644196,
      -0.04012808948755264,
      -0.026284880936145782,
      0.05316629260778427,
      0.08078097552061081,
      0.044646117836236954,
      -0.001990951132029295,
      -0.059070393443107605,
      -0.054936960339546204,
      -0.09161445498466492,
      0.04739898070693016,
      0.012079418636858463,
      0.04633452743291855,
      0.01510937325656414,
      0.0342714823782444,
      -0.021456150338053703,
      0.008376233279705048,
      0.005901075433939695,
      -0.04494517669081688,
      0.05648823827505112,
      -0.00027532491367310286,
      -0.0733422264456749,
      0.027765410020947456,
      -0.06524969637393951,
      0.01899542100727558,
      -0.0011014487827196717,
      -0.025038983672857285,
      0.031356025487184525,
      -0.09543544054031372,
      0.0134678203612566,
      -0.023737061768770218,
      -0.0161744374781847,
      -0.0003551666741259396,
      0.05445396527647972,
      -0.04246779531240463,
      -0.011562513187527657,
      0.029846608638763428,
      -0.12433850020170212,
      -0.1023678183555603,
      0.03753329813480377,
      -0.03215356171131134,
      -0.0871918797492981,
      0.007995743304491043,
      0.01665290631353855,
      -0.0028494535945355892,
      -0.045053042471408844,
      0.05174107104539871,
      0.03237215802073479,
      -0.06868968904018402,
      -0.04981482774019241,
      -0.039759110659360886,
      -0.08229363709688187,
      -0.08932121843099594,
      0.012316416017711163,
      -0.02596621960401535,
      0.09551427513360977,
      -0.07558577507734299,
      0.025104206055402756,
      -0.020678313449025154,
      0.004691512323915958,
      0.003333951812237501,
      0.005076852161437273,
      -0.09743624180555344,
      0.0544976107776165,
      -0.029467536136507988,
      0.10603354871273041,
      -0.02307063527405262,
      0.028941979631781578,
      0.07161998003721237,
      0.04475652426481247,
      -0.07026446610689163,
      -0.004845184274017811,
      -0.0012545381905511022,
      0.0058559514582157135,
      0.04358604550361633,
      -0.029106395319104195,
      -0.0018191385315731168,
      0.06454242765903473,
      0.036039937287569046,
      -0.08539413660764694,
      -0.01865898258984089
    ],
    [
      -0.025316527113318443,
      -0.02321282960474491,
      0.05021372810006142,
      -0.02650141716003418,
      -0.018607499077916145,
      -0.024986784905195236,
      0.04952634125947952,
      0.03580549731850624,
      -0.031164033338427544,
      -0.067877858877182,
      -0.011037899181246758,
      -0.016279000788927078,
      -0.03213176503777504,
      -0.022709548473358154,
      0.07366050034761429,
      0.03841675817966461,
      0.07733827829360962,
      0.009820234030485153,
      0.03956735134124756,
      0.04143824428319931,
      -0.06725025922060013,
      0.045028071850538254,
      -0.004531756043434143,
      -0.02048790641129017,
      -0.009882112964987755,
      -0.04868355393409729,
      -0.05152581259608269,
      0.001297011156566441,
      -0.060170356184244156,
      -0.03861679881811142,
      0.011605211533606052,
      -0.0052263010293245316,
      -0.07715332508087158,
      0.08199876546859741,
      0.06861909478902817,
      -0.021562892943620682,
      0.07962566614151001,
      0.06695462763309479,
      0.05295171961188316,
      -0.021606843918561935,
      0.015349719673395157,
      0.001804982079192996,
      0.03187759965658188,
      0.05522152781486511,
      0.0937994122505188,
      0.03536059334874153,
      0.013041113503277302,
      4.470302883419208e-05,
      -0.011487743817269802,
      -0.04667780175805092,
      -0.03607703000307083,
      -0.06367573142051697,
      -0.01729782484471798,
      0.022181985899806023,
      -0.06537724286317825,
      -0.025493213906884193,
      -0.05493639409542084,
      0.05457889288663864,
      0.04879724979400635,
      -0.011654816567897797,
      0.0706903412938118,
      -0.04067821428179741,
      0.00017294735880568624,
      -0.04240136221051216,
      0.039517052471637726,
      0.03183986246585846,
      -0.0848647728562355,
      0.0016843278426676989,
      -0.047735974192619324,
      -0.061796557158231735,
      0.030094774439930916,
      0.0824800506234169,
      -0.02166091278195381,
      0.04236849769949913,
      -0.004015345126390457,
      -0.007005605380982161,
      0.04042385518550873,
      0.053974539041519165,
      -0.05404119938611984,
      0.023801058530807495,
      0.012459028512239456,
      -0.04786626249551773,
      -0.009493499994277954,
      0.05055619776248932,
      -0.0156891830265522,
      0.04786507412791252,
      0.04274630919098854,
      0.03583259508013725,
      0.006261564791202545,
      -0.10206771641969681,
      0.03480057418346405,
      0.04960261657834053,
      0.004846543539315462,
      -0.0017085744766518474,
      -0.026311738416552544,
      0.0025072572752833366,
      -0.015934372320771217,
      -0.05074847489595413,
      -0.0996309295296669,
      -0.14456136524677277,
      -0.060139209032058716,
      0.00718779768794775,
      0.03934114798903465,
      -0.010797088965773582,
      0.063552625477314,
      -0.019955666735768318,
      0.0345524400472641,
      0.03256765380501747,
      -0.0931437686085701,
      -0.011904754675924778,
      -0.046286690980196,
      0.035043567419052124,
      -0.028479235246777534,
      -0.009520887397229671,
      0.08646522462368011,
      -0.043742865324020386,
      0.05394773557782173,
      -0.03787999227643013,
      0.08413258194923401,
      0.03590194880962372,
      0.08936702460050583,
      0.010833372361958027,
      0.03010830655694008,
      -0.051008470356464386,
      0.06527774035930634,
      -0.05103567987680435,
      -0.04100782424211502,
      0.0202479250729084,
      -0.024294449016451836,
      0.02757818065583706,
      0.03517088666558266,
      0.029104547575116158,
      0.014536406844854355,
      0.033201366662979126,
      0.011831765994429588,
      -0.041251055896282196,
      -0.010293982923030853,
      0.01342655811458826,
      -0.0775691419839859,
      0.0074300868436694145,
      0.038050513714551926,
      -0.0573720782995224,
      0.03502039611339569,
      0.022612174972891808,
      0.06276339292526245,
      0.04600747674703598,
      -0.05963798239827156,
      0.012966702692210674,
      -0.09744566679000854,
      -0.050303470343351364,
      -0.008506106212735176,
      -0.09762807935476303,
      -0.022449595853686333,
      0.009291048161685467,
      -0.05975600332021713,
      -0.017875639721751213,
      0.08842364698648453,
      -0.05964834615588188,
      0.060950569808483124,
      0.02059943787753582,
      0.10217171162366867,
      0.04340536519885063,
      -0.004355935845524073,
      -0.06496042758226395,
      0.018323509022593498,
      0.07493705302476883,
      0.0674460157752037,
      0.04570541903376579,
      0.09894628822803497,
      0.016470151022076607,
      -0.05807016044855118,
      -0.04772447422146797,
      -0.018020935356616974,
      -0.012333794496953487,
      -0.0010019501205533743,
      -0.08435864001512527,
      -0.03451548516750336,
      -0.06939182430505753,
      0.010426468215882778,
      -0.05746205523610115,
      -0.009350166656076908,
      0.07241130620241165,
      0.056574538350105286,
      0.009422753006219864,
      0.05525337532162666,
      -0.003086308017373085,
      0.008436746895313263,
      -0.05010214447975159,
      0.017596499994397163,
      -0.021916477009654045,
      -0.02577214315533638,
      -0.04599136859178543,
      0.04236908629536629,
      -0.03620216250419617,
      -0.001283761695958674,
      -0.010167419910430908,
      -0.0728248581290245,
      -0.0850924700498581,
      -0.04490270838141441,
      0.06631393730640411,
      0.002241880167275667,
      -0.0036354083567857742,
      -0.057282574474811554,
      -0.02305464819073677,
      -0.028072983026504517,
      0.020160537213087082,
      -0.039579156786203384,
      -0.03390750661492348,
      -0.034371234476566315,
      0.09862959384918213,
      -0.004723347723484039,
      0.05583063140511513,
      -0.048430364578962326,
      0.06685911864042282,
      -0.04060063138604164,
      0.04978007823228836,
      0.06892674416303635,
      -0.010379199869930744,
      0.09209300577640533,
      0.04275127500295639,
      -0.07989577203989029,
      -0.08097340166568756,
      -0.03596101701259613,
      0.008415875025093555,
      0.043411243706941605,
      0.019725598394870758,
      -0.040041483938694,
      0.041764333844184875,
      -0.030249934643507004,
      0.04064930975437164,
      -0.03736988082528114,
      -0.07073457539081573,
      -0.02028116211295128,
      -0.042776089161634445,
      0.05266584828495979,
      0.013064085505902767,
      0.03433125093579292,
      -0.007277338299900293,
      -0.012405920773744583,
      0.03927089273929596,
      0.0037704030983150005,
      -0.08275870978832245,
      0.03142281994223595,
      0.05360310524702072,
      0.004004000220447779,
      -0.019990012049674988,
      0.02855592779815197,
      -0.010073337703943253,
      -0.056670304387807846,
      -0.010168262757360935,
      -0.029572807252407074,
      -0.05506867915391922,
      0.03526308760046959,
      0.003575675655156374,
      -0.007233885582536459,
      0.021878018975257874,
      0.08581721037626266,
      -0.008272799663245678,
      0.040038544684648514,
      -0.01314764004200697,
      0.07712439447641373,
      0.029867691919207573,
      0.04305608570575714,
      -0.04739543795585632,
      -0.03216565400362015,
      -0.1083512008190155,
      -0.0524154007434845,
      0.030995426699519157,
      0.03833887353539467,
      0.040389250963926315,
      0.008444519713521004,
      -0.005103703588247299,
      0.0020177809055894613,
      0.010437128134071827,
      0.00023003759270068258,
      -0.015375438146293163,
      -0.030939899384975433,
      0.0355086550116539,
      0.08083538711071014,
      -0.02417665719985962,
      0.03701705113053322,
      -0.020808640867471695,
      0.05128301680088043,
      0.07604289054870605,
      0.01203656941652298,
      0.08204127103090286,
      -0.04065914824604988,
      -0.01836480386555195,
      -0.07219923287630081,
      0.013908514752984047,
      0.006344334688037634,
      0.0027087994385510683,
      -0.0016618224326521158,
      -0.028571996837854385,
      0.026263799518346786,
      -0.006784928496927023,
      -0.0565582774579525,
      -0.020563950762152672,
      0.03218454495072365,
      0.0697130560874939,
      -0.024532338604331017,
      -0.02055063657462597,
      -0.05211636796593666,
      -0.045053768903017044,
      0.051272932440042496,
      -0.07075922191143036,
      -0.013194046914577484,
      0.020230084657669067,
      0.04392372816801071,
      0.09947516024112701,
      -0.04038521274924278,
      -0.09217289835214615,
      0.041197530925273895,
      -0.059845514595508575,
      -0.02587634138762951,
      -0.03465626761317253,
      -0.052660729736089706,
      0.054703012108802795,
      0.01802804134786129,
      -0.05188477784395218,
      -0.017665980383753777,
      0.041917137801647186,
      0.05086424946784973,
      0.06466332823038101,
      0.03181099891662598,
      0.030674727633595467,
      0.05865909904241562,
      -0.07643525302410126,
      0.02267942577600479,
      0.005929220467805862,
      0.07976937294006348,
      0.02043207176029682,
      0.003774790558964014,
      -0.02355392649769783,
      -0.0035743836779147387,
      0.05354497954249382,
      -0.03782808780670166,
      -0.051468636840581894,
      0.00027554950793273747,
      0.008921707049012184,
      -0.025324828922748566,
      -0.001547562307678163,
      -0.03729098290205002,
      0.005884969606995583,
      -0.04577963054180145,
      0.03051818162202835,
      -0.050338052213191986,
      0.05490883067250252,
      0.03659988194704056,
      0.055092718452215195,
      0.0016099667409434915,
      -0.02286476455628872,
      -0.0669369101524353,
      0.03526206314563751,
      0.02795560285449028,
      -0.025589769706130028,
      -0.07775163650512695,
      -0.05770937725901604,
      -0.020501017570495605,
      0.06326736509799957,
      0.05844922736287117,
      -0.03697497025132179,
      0.014188014902174473,
      0.06178981438279152,
      0.015746286138892174,
      -0.01796552911400795,
      -0.017893506214022636,
      0.026305824518203735,
      -0.023691004142165184,
      0.0004718372074421495,
      0.047255776822566986,
      0.06890938431024551,
      -0.019785327836871147,
      0.06680158525705338,
      -0.050420258194208145,
      -0.02473580092191696,
      0.019629590213298798,
      -0.027298666536808014,
      -0.009525482542812824,
      0.04127483069896698,
      0.03739345073699951,
      0.028854820877313614,
      0.06461302936077118,
      0.07027676701545715,
      0.029608838260173798,
      -0.05310966819524765,
      0.02779645100235939,
      -0.001129831071011722,
      -0.04792328178882599,
      -0.054124265909194946,
      -0.005081199109554291,
      -0.008206729777157307,
      -0.02127145603299141,
      0.03522653132677078,
      -0.05812453851103783,
      0.002247532829642296,
      -0.048262905329465866,
      0.07963709533214569,
      -0.032075971364974976,
      0.09268736094236374,
      0.04280087351799011,
      -0.031161215156316757,
      0.01390793826431036,
      -0.010348808020353317,
      -0.035994865000247955,
      0.028098665177822113,
      -0.08894487470388412,
      0.02835255302488804,
      0.030701112002134323,
      -0.07192179560661316,
      -0.05356069654226303,
      -0.05994106084108353,
      -0.07445627450942993,
      -0.03249151259660721,
      -0.03313148394227028,
      -0.04631677269935608,
      0.0014458535006269813,
      0.017613930627703667,
      0.020628508180379868,
      0.018876075744628906,
      0.06406502425670624,
      0.048755861818790436,
      -0.00961617287248373,
      0.059073809534311295,
      -0.02465958148241043,
      -0.0019434993155300617,
      -0.013515534810721874,
      -0.030938774347305298,
      0.015945104882121086,
      0.05733839422464371,
      -0.06646904349327087,
      -0.01996346190571785,
      -0.040805526077747345,
      -0.058345336467027664,
      -0.012570840306580067,
      0.05904404819011688,
      0.035264790058135986,
      0.025625113397836685,
      0.013414478860795498,
      -0.008769715204834938,
      0.03548233583569527,
      -0.03027133084833622,
      -0.031254205852746964,
      0.05632390081882477,
      -0.05485152825713158,
      -0.023561913520097733,
      -0.009487916715443134,
      0.024914121255278587,
      -0.0017478268127888441,
      0.014240852557122707,
      -0.030999183654785156,
      0.030269455164670944,
      -0.08221019804477692,
      0.048846401274204254,
      0.01518825814127922,
      0.03137228265404701,
      0.0029036421328783035,
      0.03394965082406998,
      -0.031008940190076828,
      -0.020704807713627815,
      -0.04832233488559723,
      0.0005213138647377491,
      0.00017297182057518512,
      -0.04368043318390846,
      0.054359640926122665,
      0.1309676170349121,
      0.08571294695138931,
      0.0014900310197845101,
      0.0440388098359108,
      0.05529375746846199,
      -0.002442758297547698,
      0.02559124305844307,
      -0.04627324268221855,
      -0.05276133120059967,
      0.01627434976398945,
      0.007031298242509365,
      -0.06190197914838791,
      0.021261585876345634,
      0.06466913223266602,
      0.034209609031677246,
      -0.036135733127593994,
      0.03212788328528404,
      0.007758170831948519,
      0.018383732065558434,
      -0.060057383030653,
      0.1236293837428093,
      -0.05350901558995247,
      0.08289018273353577,
      0.022231487557291985,
      -0.04066813737154007,
      0.023135701194405556,
      0.001591982552781701,
      -0.026771467179059982,
      0.024321909993886948,
      -0.03295353427529335,
      0.06143973395228386,
      -0.012724152766168118,
      -0.034788910299539566,
      -0.029957540333271027,
      0.041851796209812164,
      0.06564808636903763,
      0.00030831099138595164,
      -0.04090077057480812
    ],
    [
      -0.07897251844406128,
      0.01946169324219227,
      0.029428746551275253,
      -0.034965530037879944,
      0.024155382066965103,
      0.034529175609350204,
      -0.017115043476223946,
      0.06074266508221626,
      -0.0038945379201322794,
      0.04537709802389145,
      0.07571679353713989,
      0.0010409741662442684,
      -0.10491859167814255,
      0.04765729978680611,
      0.03132705017924309,
      -0.08503694832324982,
      0.045241136103868484,
      0.03929328918457031,
      0.01969153992831707,
      -0.09348542988300323,
      0.02863392047584057,
      -0.024375146254897118,
      -0.03458360955119133,
      0.08652950078248978,
      -0.030439836904406548,
      0.06453926116228104,
      0.025150759145617485,
      0.008428115397691727,
      0.023588739335536957,
      0.006739534437656403,
      0.03108336217701435,
      0.026759374886751175,
      -0.038116514682769775,
      -0.05844089388847351,
      0.09369976073503494,
      0.005332862492650747,
      0.006569214165210724,
      0.07877002656459808,
      0.006548495497554541,
      -0.019188465550541878,
      0.08884312212467194,
      0.03777821362018585,
      -0.021982353180646896,
      -0.02506241202354431,
      0.0737641230225563,
      -0.05009967088699341,
      -0.026499439030885696,
      0.008168885484337807,
      -0.07487694919109344,
      -0.03481106087565422,
      0.07799400389194489,
      0.07199307531118393,
      -0.03664015978574753,
      -0.000870515825226903,
      0.03972429037094116,
      0.031054720282554626,
      0.005112607963383198,
      0.017975281924009323,
      0.03964637219905853,
      -0.024834569543600082,
      -0.018301090225577354,
      -0.00194801006000489,
      0.013249707408249378,
      0.030653512105345726,
      -0.003977758809924126,
      0.009252716787159443,
      -0.011025526560842991,
      -0.06016697734594345,
      -0.06821020692586899,
      -0.03519789129495621,
      0.02197321504354477,
      0.01961854286491871,
      -0.010863136500120163,
      -0.008745900355279446,
      0.04624509438872337,
      0.0427091047167778,
      0.018229098990559578,
      0.04464324563741684,
      -0.026143645867705345,
      -0.008347857743501663,
      0.033304646611213684,
      -0.017581958323717117,
      0.006255647167563438,
      0.048725154250860214,
      -0.06914294511079788,
      0.0042450278997421265,
      -0.047292955219745636,
      0.026931948959827423,
      -0.01616300642490387,
      -0.034454867243766785,
      0.06337518990039825,
      0.00205253716558218,
      0.02638811431825161,
      -0.024357903748750687,
      -0.008583487942814827,
      0.022398579865694046,
      -0.036113958805799484,
      0.05618368834257126,
      0.029281238093972206,
      0.02304663695394993,
      -0.10590305924415588,
      -0.04693368822336197,
      -0.028796035796403885,
      -0.028819363564252853,
      -0.022276388481259346,
      0.05061322823166847,
      -0.039103128015995026,
      -0.018934674561023712,
      -0.021493084728717804,
      0.002787945792078972,
      0.06437475979328156,
      0.03763817995786667,
      -0.011017094366252422,
      0.06049894914031029,
      -0.04002887010574341,
      0.08201350271701813,
      -0.04410205036401749,
      -0.007025641854852438,
      0.0320175439119339,
      0.06653812527656555,
      0.003262452781200409,
      -0.048822224140167236,
      0.05329889804124832,
      -0.04708820953965187,
      -0.002568507567048073,
      -0.053964875638484955,
      -0.03295115754008293,
      -0.032433751970529556,
      -0.02699742652475834,
      -0.08650565147399902,
      -0.014829292893409729,
      -0.11064460128545761,
      -0.003067598445340991,
      -0.028541045263409615,
      -0.02568485587835312,
      -0.017551740631461143,
      -0.05023721605539322,
      0.008815697394311428,
      -0.041009411215782166,
      -0.07823902368545532,
      -0.09864839911460876,
      -0.059495799243450165,
      -0.03430289030075073,
      0.02984766475856304,
      -0.04847446084022522,
      -0.0009526665089651942,
      0.03332461789250374,
      0.02714977040886879,
      -0.024499943479895592,
      -0.016130151227116585,
      -0.001340545597486198,
      -0.026857705786824226,
      0.04838127642869949,
      0.034240562468767166,
      0.02870042435824871,
      0.02404545061290264,
      -0.005053351633250713,
      -0.0892069935798645,
      0.04483496770262718,
      -0.024684717878699303,
      -0.04472041875123978,
      0.026717614382505417,
      0.1095183938741684,
      -0.0034264971036463976,
      0.00939684733748436,
      0.025739388540387154,
      -0.019522037357091904,
      -0.03158368915319443,
      -0.07943277806043625,
      0.07076410949230194,
      -0.021209515631198883,
      -0.021737752482295036,
      -0.03522108122706413,
      -0.011999891139566898,
      -0.051593583077192307,
      -0.03101891651749611,
      0.0071269068866968155,
      0.03356951102614403,
      0.03458712622523308,
      -0.003482889151200652,
      -0.05080438405275345,
      0.05615092068910599,
      0.05374150723218918,
      0.060508567839860916,
      -0.03703005611896515,
      -0.10330874472856522,
      -0.028357848525047302,
      -0.006043223198503256,
      0.02550247125327587,
      0.07232961803674698,
      -0.0063874018378555775,
      -0.0953521654009819,
      0.001886029844172299,
      0.10656744241714478,
      -0.01590586081147194,
      -0.01584097556769848,
      0.03269260376691818,
      0.10531223565340042,
      -0.00817165244370699,
      -0.08935189247131348,
      0.02749648317694664,
      0.02223946340382099,
      0.002990060718730092,
      -0.022066989913582802,
      0.03533761948347092,
      -0.05997022986412048,
      -0.020318008959293365,
      -0.008120984770357609,
      0.11882354319095612,
      -0.011497347615659237,
      0.024848386645317078,
      -0.03366187587380409,
      0.06977374851703644,
      -0.008211814798414707,
      -0.05183812603354454,
      0.03889448568224907,
      -0.00367849413305521,
      -0.042796168476343155,
      -0.02809961885213852,
      -0.004149619955569506,
      -0.05346980690956116,
      0.03229251503944397,
      -0.03458010405302048,
      -0.02766815759241581,
      -0.015891985967755318,
      0.003689997596666217,
      0.007989667356014252,
      -0.0648244321346283,
      -0.0101520586758852,
      0.05723293870687485,
      -0.10194580256938934,
      0.06320108473300934,
      0.01373363845050335,
      -0.007958484813570976,
      -0.00952190812677145,
      -0.09133473038673401,
      -0.03805495426058769,
      0.034917160868644714,
      0.04667897894978523,
      -0.02484903112053871,
      0.07862970978021622,
      0.09852561354637146,
      -0.008510240353643894,
      -0.004402728285640478,
      0.07458417117595673,
      -0.05753851309418678,
      -0.055291276425123215,
      -0.010616940446197987,
      -0.02799159660935402,
      -0.020004719495773315,
      0.04078024625778198,
      -0.0365956649184227,
      0.08237782120704651,
      0.04219659045338631,
      -0.0378706157207489,
      -0.04145967215299606,
      0.06646455079317093,
      -0.01009172759950161,
      0.03332941606640816,
      0.06732465326786041,
      -0.0848117545247078,
      -0.04833461344242096,
      0.0026110114995390177,
      -0.0011113256914541125,
      0.019967354834079742,
      0.01643216237425804,
      0.05987412855029106,
      -0.034359708428382874,
      -0.03564665839076042,
      0.00983473937958479,
      0.025429610162973404,
      0.03178919479250908,
      0.03398981690406799,
      -0.05071041360497475,
      0.037315480411052704,
      0.006661037914454937,
      -0.04907240346074104,
      0.022730309516191483,
      -0.0742766410112381,
      0.006091222167015076,
      -0.02704283967614174,
      -0.09326864778995514,
      0.012783772312104702,
      0.04126425459980965,
      0.009184621274471283,
      0.004273131489753723,
      -0.006643654778599739,
      -0.02238340675830841,
      0.04396061226725578,
      0.0603296160697937,
      0.03936202824115753,
      -0.02470705844461918,
      -0.05792657285928726,
      -0.06626643985509872,
      -0.03752235323190689,
      0.03772708401083946,
      -0.02762514539062977,
      -0.007254539057612419,
      0.04623332992196083,
      -0.014439462684094906,
      0.03592908754944801,
      0.04542621597647667,
      0.01834193244576454,
      0.009724845178425312,
      0.03899698704481125,
      0.05535968020558357,
      -0.055636439472436905,
      -0.02081766538321972,
      -0.1016497015953064,
      0.04424234852194786,
      -0.045337338000535965,
      0.032538022845983505,
      0.06119443476200104,
      -0.003230636939406395,
      -0.04071547091007233,
      0.021868977695703506,
      0.09703425318002701,
      0.049896590411663055,
      -0.039028871804475784,
      -0.04442855715751648,
      -0.09369039535522461,
      -0.011263861320912838,
      -0.03307083994150162,
      -0.049128834158182144,
      0.023244664072990417,
      -0.04491647705435753,
      0.009809999726712704,
      -0.03415871039032936,
      0.004273758269846439,
      0.03534742444753647,
      -0.014941577799618244,
      -0.0029651315417140722,
      -0.0077233752235770226,
      0.03855214640498161,
      0.05122955143451691,
      0.01197444275021553,
      0.016917364671826363,
      -0.008409508503973484,
      0.03664439171552658,
      -0.027772661298513412,
      -0.025129176676273346,
      -0.02525613084435463,
      -0.03437170758843422,
      -0.0850134864449501,
      0.05692976713180542,
      -0.04013537988066673,
      0.060436997562646866,
      0.026325082406401634,
      -0.009806433692574501,
      -0.017471149563789368,
      -0.021884704008698463,
      0.0031447182409465313,
      -0.047513168305158615,
      0.035915639251470566,
      0.030611058697104454,
      -0.0936085656285286,
      0.03308761864900589,
      -0.007852389477193356,
      0.02184865064918995,
      -0.032855428755283356,
      0.027426443994045258,
      -0.00955637264996767,
      -0.013514135032892227,
      0.0167448278516531,
      -0.05556047335267067,
      0.016270577907562256,
      -0.0634983628988266,
      -0.011849421076476574,
      0.008503950200974941,
      -0.004589462652802467,
      0.054756343364715576,
      0.00018224096857011318,
      -0.025914331898093224,
      -0.007425451651215553,
      -0.06468749046325684,
      0.052096590399742126,
      -0.09572792798280716,
      -0.03780866786837578,
      -0.093781977891922,
      0.0636061578989029,
      0.022004982456564903,
      -0.0318424329161644,
      -0.027660226449370384,
      0.007629482075572014,
      0.062433112412691116,
      -0.06010513752698898,
      0.07974878698587418,
      0.022534452378749847,
      -0.006035643629729748,
      -0.07080382853746414,
      0.0065918974578380585,
      0.09408458322286606,
      0.05321255326271057,
      -0.06099430471658707,
      -0.016983535140752792,
      -0.021423455327749252,
      0.04426173120737076,
      -0.009695988148450851,
      -0.020618516951799393,
      -0.04557957872748375,
      0.010214032605290413,
      -0.0636722519993782,
      -0.041385453194379807,
      0.037359099835157394,
      0.011019760742783546,
      0.06458929181098938,
      -0.02500486560165882,
      0.026721250265836716,
      0.0005887319566681981,
      -0.01739230751991272,
      -0.05612232908606529,
      -0.0020344972144812346,
      0.028429903090000153,
      -0.005527103319764137,
      -0.0418042428791523,
      0.05665821209549904,
      0.11136714369058609,
      0.053832702338695526,
      0.010095948353409767,
      0.028036901727318764,
      -0.02900104783475399,
      0.0075148302130401134,
      0.024297324940562248,
      0.01013097632676363,
      -0.017066791653633118,
      -0.057475496083498,
      0.012676580809056759,
      0.1278115063905716,
      -0.08529897779226303,
      0.020504653453826904,
      -0.040365152060985565,
      -0.03519825637340546,
      0.014179631136357784,
      0.027439944446086884,
      0.0469481386244297,
      -0.026806505396962166,
      0.040939103811979294,
      -0.027139563113451004,
      -0.013351262547075748,
      -0.016951462253928185,
      -0.039799727499485016,
      0.02376432716846466,
      0.0956997498869896,
      -0.027323847636580467,
      -0.04461199417710304,
      -0.03776971623301506,
      0.024297945201396942,
      0.024980876594781876,
      0.04264181852340698,
      0.014348160475492477,
      -0.06553719192743301,
      -0.004087705165147781,
      -0.011803736910223961,
      0.02905166894197464,
      0.00017878087237477303,
      0.008235758170485497,
      -0.0062371669337153435,
      -0.031048722565174103,
      -0.07302186638116837,
      0.03402240201830864,
      -0.04799247905611992,
      0.047182999551296234,
      0.0034777282271534204,
      -0.045130182057619095,
      -0.03420298919081688,
      -0.03194624185562134,
      0.00822776835411787,
      0.0029992572963237762,
      0.013345250859856606,
      0.06909675151109695,
      -0.03990566357970238,
      0.03072204440832138,
      -0.04379994049668312,
      0.043835267424583435,
      0.008405923843383789,
      0.008652685210108757,
      -0.023722488433122635,
      0.00012532024993561208,
      -0.013596167787909508,
      -0.000287398201180622,
      -0.0192696712911129,
      -0.0027324657421559095,
      0.03493531793355942,
      0.05632046237587929,
      -0.055107537657022476,
      -0.01444302313029766,
      0.04485931992530823,
      -0.002260759472846985,
      0.08983968198299408,
      0.023879703134298325,
      0.06687507033348083,
      -0.02946203574538231,
      -0.06858401000499725,
      0.04155958816409111,
      0.027196532115340233,
      -0.03877992555499077,
      0.08153662085533142,
      -0.0008525683078914881,
      0.007992060855031013,
      -0.06323985755443573,
      -0.022371342405676842,
      -0.023460743948817253,
      -0.009992164559662342
    ],
    [
      0.02547464333474636,
      -0.05467282235622406,
      -0.03279182314872742,
      -0.007080766372382641,
      0.0570034459233284,
      -0.02856949158012867,
      0.08200822025537491,
      -0.02523350901901722,
      -0.009208406321704388,
      0.019305141642689705,
      -0.027186743915081024,
      -0.04908040910959244,
      0.01734020933508873,
      0.011718792840838432,
      -0.021504130214452744,
      -0.020922882482409477,
      -0.028096895664930344,
      -0.009118719957768917,
      -0.043163515627384186,
      0.01664618030190468,
      0.07597550749778748,
      -0.01285325177013874,
      -0.0030940501019358635,
      -0.01356953103095293,
      0.03103165328502655,
      -0.006412514951080084,
      0.06069726496934891,
      -0.07539291679859161,
      0.03975091502070427,
      0.029761403799057007,
      -0.05915899574756622,
      -0.0843631774187088,
      -0.00482679158449173,
      0.01796051301062107,
      -0.003032389096915722,
      0.02186044305562973,
      0.02133394218981266,
      -0.041826412081718445,
      0.015783317387104034,
      0.024760587140917778,
      -0.05407600477337837,
      0.09270913153886795,
      -0.052712056785821915,
      -0.012385046109557152,
      0.07119590789079666,
      -0.031093448400497437,
      0.01094429288059473,
      -0.010729397647082806,
      0.032112594693899155,
      -0.0700998604297638,
      0.016177648678421974,
      -0.0316680409014225,
      -0.05544354021549225,
      0.031838998198509216,
      -0.01021597720682621,
      0.037054188549518585,
      -0.02051634155213833,
      0.025861192494630814,
      -0.06690921634435654,
      -0.05287329852581024,
      -0.06837452203035355,
      -0.04744410142302513,
      0.00250869058072567,
      -0.03978002816438675,
      -0.01514712069183588,
      -0.016298986971378326,
      0.06791698932647705,
      0.029114892706274986,
      -0.08273480832576752,
      -0.031041938811540604,
      0.00994033832103014,
      0.02268342673778534,
      -0.030801011249423027,
      -0.027002619579434395,
      -0.027896875515580177,
      -0.10847371816635132,
      0.028378766030073166,
      -0.017874620854854584,
      -0.05834432318806648,
      -0.06548216938972473,
      0.010695093311369419,
      -0.050717469304800034,
      -0.026505712419748306,
      0.02801545150578022,
      0.011851954273879528,
      -0.036470964550971985,
      0.027893489226698875,
      0.03626295551657677,
      -0.018528567627072334,
      0.028050009161233902,
      0.013207427226006985,
      0.02162006124854088,
      0.052632011473178864,
      0.0795680582523346,
      -0.014632447622716427,
      0.027196140959858894,
      0.031241849064826965,
      0.029072949662804604,
      0.013495972380042076,
      -0.03312961012125015,
      0.046227630227804184,
      0.023850634694099426,
      -0.01574450172483921,
      -0.0596189945936203,
      -0.09481270611286163,
      0.01413131132721901,
      -0.018450288102030754,
      0.03201059624552727,
      0.01978546939790249,
      -0.05448618903756142,
      -0.09432071447372437,
      -0.019690275192260742,
      -0.054012347012758255,
      0.1059894859790802,
      -0.019117193296551704,
      0.03184683620929718,
      0.014239245094358921,
      -0.036865003407001495,
      0.076651930809021,
      -0.025546228513121605,
      0.01999896578490734,
      0.01320706121623516,
      -0.05075902119278908,
      0.0022949599660933018,
      0.01925901137292385,
      0.021448418498039246,
      0.009634681977331638,
      -0.0487411729991436,
      0.030939161777496338,
      0.012588261626660824,
      -0.07833590358495712,
      0.029916362836956978,
      0.03253301978111267,
      0.007420350331813097,
      0.10885987430810928,
      -0.051559966057538986,
      0.03507038205862045,
      -0.00707256468012929,
      0.014064698480069637,
      -0.0021896741818636656,
      -0.058965541422367096,
      0.010452352464199066,
      -0.015164497308433056,
      0.011367457918822765,
      0.06244911625981331,
      -0.03593306243419647,
      -0.010281626135110855,
      0.0100578423589468,
      0.0174273531883955,
      0.031841397285461426,
      0.03926694020628929,
      -0.058938153088092804,
      -0.08383452892303467,
      -0.05463152006268501,
      0.02453714981675148,
      0.014919773675501347,
      -0.005354678723961115,
      -0.004508597310632467,
      0.0025655569043010473,
      0.002794053638353944,
      -0.06427373737096786,
      0.11015422642230988,
      0.09466913342475891,
      0.040757618844509125,
      0.060222383588552475,
      0.051259350031614304,
      -0.11792033165693283,
      0.08636888116598129,
      -0.03765391185879707,
      0.016820833086967468,
      -0.03239119425415993,
      -0.014254923909902573,
      0.010655107907950878,
      0.02891424298286438,
      0.06972531974315643,
      0.020856617018580437,
      -0.07662889361381531,
      -0.0058130319230258465,
      -0.01870320364832878,
      0.017809275537729263,
      -0.048515766859054565,
      0.007654151413589716,
      -0.13934199512004852,
      0.03676370531320572,
      0.03429359570145607,
      0.04312621429562569,
      -0.023388469591736794,
      -0.024603726342320442,
      0.038690127432346344,
      0.04015529900789261,
      0.09405305236577988,
      0.0013023290084674954,
      -0.00933590717613697,
      0.008406599052250385,
      -0.015028542838990688,
      -0.020640242844820023,
      -0.05463169515132904,
      -0.0033108373172581196,
      -0.02015533670783043,
      -0.08682575076818466,
      0.08251871913671494,
      0.0002878330706153065,
      0.0026758606545627117,
      -0.0024584964849054813,
      0.007484440691769123,
      0.018008146435022354,
      0.11041001230478287,
      0.01230030320584774,
      -0.04928380250930786,
      0.03610779345035553,
      -0.010678838938474655,
      -0.017473267391324043,
      -0.007316059432923794,
      0.023157915100455284,
      -0.04680386185646057,
      -0.006384250242263079,
      0.10242321342229843,
      0.05112577974796295,
      -0.040922749787569046,
      -0.05507791042327881,
      -0.05015004798769951,
      0.0008326584938913584,
      0.04971384257078171,
      -0.014285036362707615,
      -0.018812157213687897,
      0.02454696223139763,
      0.010044772177934647,
      -0.05137177184224129,
      -0.011141667142510414,
      0.046857576817274094,
      -0.03372664377093315,
      0.05369129031896591,
      0.0004415745206642896,
      0.04220042750239372,
      0.00471419608220458,
      0.02403593622148037,
      -0.06794220954179764,
      0.012217016890645027,
      -0.04101097583770752,
      -0.16092678904533386,
      -0.02955542877316475,
      0.021413957700133324,
      -0.0011535512749105692,
      0.015138532035052776,
      0.0532204769551754,
      -0.004484724719077349,
      -0.04913514107465744,
      -0.048415299504995346,
      0.01271145511418581,
      -0.040825940668582916,
      -0.02588628977537155,
      -0.039251577109098434,
      0.015496713109314442,
      -0.01462942361831665,
      -0.03296463191509247,
      -0.002660152269527316,
      0.006821340415626764,
      -0.08452938497066498,
      -0.036304306238889694,
      -0.013721328228712082,
      0.032442498952150345,
      -0.015138188377022743,
      -0.017922144383192062,
      0.019785841926932335,
      0.04028990492224693,
      0.018185626715421677,
      -0.0683605894446373,
      0.005971748847514391,
      -0.04363036900758743,
      -0.05616037920117378,
      0.05548303201794624,
      -0.003569135209545493,
      0.0007204525754787028,
      0.10904662311077118,
      -0.05505682900547981,
      -0.02603241242468357,
      0.00883325282484293,
      -0.0009929523803293705,
      -0.08192729204893112,
      0.0062710861675441265,
      -0.0143591845408082,
      -0.029765581712126732,
      0.07084642350673676,
      0.01483728177845478,
      0.010202383622527122,
      0.06014736369252205,
      -0.07245752960443497,
      -0.04189915210008621,
      -0.04041045531630516,
      -0.03459656238555908,
      -0.012184026651084423,
      0.023395463824272156,
      -0.00014498463133350015,
      0.03468412905931473,
      0.002637427533045411,
      0.03069930523633957,
      -0.09340108186006546,
      0.026536040008068085,
      -0.06206092610955238,
      -0.03183343634009361,
      -0.015221955254673958,
      -0.03211423009634018,
      0.013424569740891457,
      0.06463566422462463,
      0.023554693907499313,
      0.046893488615751266,
      0.02708929404616356,
      0.004904466215521097,
      -0.061435628682374954,
      0.007914667017757893,
      -0.02891644835472107,
      -0.02381102181971073,
      -0.01830834150314331,
      -0.008334922604262829,
      -0.029987916350364685,
      -0.0251921433955431,
      0.015064341016113758,
      0.001958739710971713,
      0.03962310031056404,
      -0.07253801077604294,
      0.01879793219268322,
      -0.03288709372282028,
      0.06690333783626556,
      0.0359518937766552,
      -0.011907494626939297,
      -0.026359302923083305,
      -0.024432851001620293,
      -0.004561303649097681,
      0.016846895217895508,
      -0.025582797825336456,
      -0.026653042063117027,
      -0.03951822593808174,
      -0.030610837042331696,
      -0.024833599105477333,
      0.002317806938663125,
      -0.019521942362189293,
      -0.0009145831572823226,
      -0.028749503195285797,
      -0.0037057672161608934,
      -0.0833296850323677,
      0.043312735855579376,
      0.02610231377184391,
      -0.10051960498094559,
      -0.05560823902487755,
      0.02683568187057972,
      0.09842493385076523,
      -0.0007204456487670541,
      -0.022836843505501747,
      -0.05575549975037575,
      0.01913614012300968,
      0.05376564711332321,
      -0.039921835064888,
      0.01012624055147171,
      -0.025801120325922966,
      0.06246709078550339,
      -0.07933661341667175,
      -0.01021545846015215,
      -0.07590260356664658,
      -0.009448006749153137,
      0.0364362858235836,
      0.013532478362321854,
      -0.07913704216480255,
      0.013684396632015705,
      -0.006298924796283245,
      0.03950456157326698,
      -0.010141000151634216,
      -0.031251274049282074,
      0.006858173292130232,
      -0.046013716608285904,
      0.033426497131586075,
      -0.040045350790023804,
      0.053021714091300964,
      0.006330902688205242,
      -0.06600126624107361,
      0.02681371197104454,
      0.003292806213721633,
      0.062106866389513016,
      0.02233409509062767,
      -0.035078372806310654,
      0.045013539493083954,
      -0.03339425474405289,
      -0.030310487374663353,
      0.03864452615380287,
      -0.08432798832654953,
      0.03205382078886032,
      0.04597008600831032,
      -0.010035754181444645,
      -0.009546912275254726,
      0.016064727678894997,
      -0.06732060015201569,
      -0.0038865436799824238,
      -0.08262461423873901,
      0.002845688723027706,
      0.03868844732642174,
      0.023148108273744583,
      0.006938958074897528,
      0.016290105879306793,
      0.03786972910165787,
      0.01655086874961853,
      0.0391831248998642,
      0.05672263354063034,
      -0.03952817991375923,
      0.007250869646668434,
      0.01115093007683754,
      -0.012235822156071663,
      0.01329721137881279,
      0.009135127067565918,
      -0.004416248295456171,
      -0.024722237139940262,
      -0.051077257841825485,
      -0.015506848692893982,
      -0.031499385833740234,
      0.006073391530662775,
      0.05326466262340546,
      0.05904402583837509,
      -0.01556294783949852,
      0.05626673623919487,
      -0.027685901150107384,
      0.013509145006537437,
      -0.036924127489328384,
      -0.040740158408880234,
      -0.06039895489811897,
      -0.010026522912085056,
      -0.0417216457426548,
      -0.04129951819777489,
      -0.07056137919425964,
      -0.003685764968395233,
      0.053778424859046936,
      -0.01719903200864792,
      -0.016734618693590164,
      0.07497470825910568,
      -0.039673008024692535,
      -0.13948045670986176,
      -0.030526839196681976,
      0.010180884972214699,
      0.039748091250658035,
      -0.008590979501605034,
      -0.014787916094064713,
      -0.03069259785115719,
      -0.07736985385417938,
      -0.046617183834314346,
      -0.10315489023923874,
      -0.00520074088126421,
      -0.06924012303352356,
      0.11925137788057327,
      -0.07319460064172745,
      0.026067156344652176,
      -7.383304910035804e-05,
      -0.1099323257803917,
      0.003151778830215335,
      -0.005848350469022989,
      0.020097289234399796,
      -0.02754513919353485,
      -0.05009409412741661,
      -0.0480072945356369,
      -0.005947141908109188,
      0.1112963929772377,
      0.07197540998458862,
      -0.04894227162003517,
      -0.025835435837507248,
      -0.03913690522313118,
      -0.020158931612968445,
      -0.0986834242939949,
      0.016863597556948662,
      0.06093718111515045,
      -0.06534992903470993,
      0.05730166658759117,
      -0.006434573791921139,
      -0.026466624811291695,
      -0.02002331055700779,
      0.05374908819794655,
      -0.007571469061076641,
      0.09539580345153809,
      -0.03196489065885544,
      0.03720111399888992,
      0.06587893515825272,
      0.022954465821385384,
      -0.0027714346069842577,
      -0.06645664572715759,
      -0.00865414459258318,
      -0.003261324018239975,
      0.03250625357031822,
      -0.020199857652187347,
      0.05005403980612755,
      -0.075823575258255,
      0.055698443204164505,
      -0.027231233194470406,
      0.011609969660639763,
      0.05775952339172363,
      0.027206609025597572,
      -0.03012114390730858,
      -0.04939209669828415,
      -0.012886889278888702,
      -0.007854741998016834,
      0.10922805964946747,
      -0.012730346992611885,
      0.1000787541270256,
      0.053802333772182465,
      0.005527555476874113,
      0.02091147005558014,
      -0.0452113002538681,
      -0.01782635785639286,
      0.06789028644561768
    ],
    [
      0.017855485901236534,
      0.0020758050959557295,
      -0.022967366501688957,
      -0.04424867406487465,
      -0.021386515349149704,
      -0.07356353849172592,
      0.0042413705959916115,
      0.0419955775141716,
      0.10572294145822525,
      0.0948369950056076,
      0.06829189509153366,
      -0.02421589009463787,
      0.01841832883656025,
      0.032536815851926804,
      0.0013789170188829303,
      -0.02167653664946556,
      -0.01315305009484291,
      0.03176308795809746,
      0.009011056274175644,
      0.07663619518280029,
      -0.022406907752156258,
      -0.04172606021165848,
      -0.06256972998380661,
      -0.026061000302433968,
      -0.04640096426010132,
      -0.015124307945370674,
      -0.0035183094441890717,
      0.0617154985666275,
      0.04619131237268448,
      -0.033666715025901794,
      -0.02424648404121399,
      0.005820760037750006,
      0.09888458997011185,
      0.008822117932140827,
      0.03965635970234871,
      0.03502090275287628,
      0.06326176226139069,
      -0.12012411653995514,
      0.029610225930809975,
      -0.0732993632555008,
      -0.029101911932229996,
      0.037972260266542435,
      0.03866204991936684,
      -0.02607354335486889,
      0.06512921303510666,
      -0.01765754446387291,
      0.034327536821365356,
      0.09611345082521439,
      -0.010491201654076576,
      -0.08906816691160202,
      0.08189120143651962,
      -0.008481376804411411,
      0.005780912935733795,
      0.03222010284662247,
      -0.029286766424775124,
      0.08801035583019257,
      -0.02647446095943451,
      0.007520697079598904,
      0.13961167633533478,
      -0.04063689336180687,
      -0.013327420689165592,
      0.040176019072532654,
      0.045424044132232666,
      0.0036310197319835424,
      0.045292701572179794,
      -0.005336924456059933,
      0.0004628844035323709,
      0.017267078161239624,
      -0.07428821921348572,
      -0.04453557729721069,
      -0.06245968118309975,
      0.05920430272817612,
      0.0006754561327397823,
      0.0442437119781971,
      -0.0037843321915715933,
      0.032204143702983856,
      0.05605326592922211,
      -0.038607992231845856,
      -0.06204120069742203,
      0.023206809535622597,
      -0.03829018771648407,
      -0.04798179119825363,
      0.05036439374089241,
      0.00298094330355525,
      -0.030520694330334663,
      -0.0633382797241211,
      -0.0095916036516428,
      -0.05402054637670517,
      -0.029734261333942413,
      0.010141046717762947,
      -0.11499812453985214,
      0.008931131102144718,
      -0.04760119318962097,
      -0.031826891005039215,
      0.02473839558660984,
      0.004158858209848404,
      -0.00205100211314857,
      0.010677213780581951,
      0.06888360530138016,
      -0.025856968015432358,
      0.03292016685009003,
      -0.0759153813123703,
      0.0052764820866286755,
      0.09243664145469666,
      0.06660056114196777,
      0.05992792546749115,
      -0.07257644832134247,
      0.0007118007051758468,
      -0.009882748126983643,
      -0.03188963979482651,
      0.0001307851489400491,
      0.006164792459458113,
      -0.05048169568181038,
      0.03380701690912247,
      -0.013592127710580826,
      -0.004890164826065302,
      0.06127070635557175,
      -0.044301681220531464,
      0.04723900556564331,
      0.04290880635380745,
      -0.01881001517176628,
      0.04290822148323059,
      0.02953256294131279,
      0.052348144352436066,
      0.033898573368787766,
      -0.027629747986793518,
      0.0313519686460495,
      0.05625935271382332,
      -0.02926650084555149,
      0.06606722623109818,
      0.018902959302067757,
      0.029398547485470772,
      -0.016941461712121964,
      -0.021965278312563896,
      0.08376184105873108,
      -0.016439488157629967,
      0.012498187832534313,
      0.007789605297148228,
      -0.006811563856899738,
      0.02998797409236431,
      0.021110909059643745,
      -0.022503703832626343,
      -0.05828755348920822,
      0.08739230781793594,
      -0.043301910161972046,
      0.005459413398057222,
      -0.026286356151103973,
      0.0486098974943161,
      0.14657345414161682,
      0.00019126720144413412,
      0.020238984376192093,
      -0.008329114876687527,
      -0.04906844720244408,
      0.06325045228004456,
      0.03489485755562782,
      -0.0167930256575346,
      -0.026677729561924934,
      0.0390484519302845,
      0.017362644895911217,
      0.0034339716657996178,
      -0.030454382300376892,
      0.09958650916814804,
      0.06393061578273773,
      -0.029485898092389107,
      -0.014976532198488712,
      -0.005909243132919073,
      -0.006021615117788315,
      0.014172632247209549,
      -0.034802019596099854,
      0.02945239283144474,
      -0.07932791858911514,
      -0.030279364436864853,
      -0.02521483600139618,
      0.026363709941506386,
      -0.040237441658973694,
      0.03811315819621086,
      -0.014675083570182323,
      0.039438262581825256,
      0.002258134074509144,
      -0.06783223152160645,
      0.01150978822261095,
      -0.004725391045212746,
      0.0009350291802547872,
      0.03270871937274933,
      -0.054941385984420776,
      0.0028523546643555164,
      -0.06532969325780869,
      -0.02297349087893963,
      0.07280953228473663,
      -0.030314121395349503,
      0.021718157455325127,
      -0.05811448022723198,
      0.06470242887735367,
      0.01685737445950508,
      0.05289062485098839,
      0.006168685853481293,
      -0.026417216286063194,
      0.025201182812452316,
      0.011250074952840805,
      0.047031741589307785,
      0.035612691193819046,
      0.024161066859960556,
      0.0013395693385973573,
      0.004497324116528034,
      -0.0464092493057251,
      -0.06173933297395706,
      0.026781611144542694,
      -0.042334649711847305,
      0.012565063312649727,
      -0.018736043944954872,
      0.018315628170967102,
      -0.10685320943593979,
      -0.081424780189991,
      -0.053252849727869034,
      -0.0481027327477932,
      0.08892235904932022,
      0.012083988636732101,
      -0.03844786062836647,
      -0.06165356561541557,
      0.018014727160334587,
      -0.0604366734623909,
      0.024286171421408653,
      -0.015976201742887497,
      -0.02010626532137394,
      0.02134202979505062,
      0.03310512751340866,
      -0.06449410319328308,
      -0.03447326645255089,
      0.004496162757277489,
      -0.0861678421497345,
      0.04879176616668701,
      -0.006331228651106358,
      0.028402075171470642,
      0.047144293785095215,
      0.05148676782846451,
      -0.06693556159734726,
      -0.09736396372318268,
      0.05147268995642662,
      -0.01878533512353897,
      0.05243244767189026,
      0.018378956243395805,
      0.07099057734012604,
      0.03719881922006607,
      -0.0758541002869606,
      -0.05193628743290901,
      0.06074722483754158,
      0.04326174408197403,
      0.021634787321090698,
      -0.04449642822146416,
      8.922189590521157e-05,
      0.07896631211042404,
      0.007281534373760223,
      0.08944466710090637,
      0.015457918867468834,
      0.03423202410340309,
      0.07442940026521683,
      -0.02995038963854313,
      -0.054697562009096146,
      0.00874866172671318,
      0.04273571819067001,
      -0.012666673399508,
      -0.029578503221273422,
      0.019723286852240562,
      0.09748081117868423,
      -0.05387461930513382,
      -0.04467952623963356,
      -0.00380305340513587,
      -0.011953051201999187,
      -0.01660967990756035,
      -0.05808865651488304,
      0.02867845632135868,
      0.08332850784063339,
      0.0016549300635233521,
      -0.01636725291609764,
      0.029244180768728256,
      -0.03719482570886612,
      -0.020283104851841927,
      0.004774794448167086,
      -0.005139545071870089,
      0.01882017031311989,
      0.008940908126533031,
      -0.026142949238419533,
      0.09964584559202194,
      -0.054259154945611954,
      -0.03578117489814758,
      -0.08859562873840332,
      0.00801420770585537,
      -0.025869203731417656,
      0.05300649628043175,
      0.03962446376681328,
      0.04570234566926956,
      0.0666029155254364,
      -0.004096333868801594,
      -0.05997469648718834,
      -0.10374221205711365,
      0.007496910635381937,
      -0.025460094213485718,
      0.000634634867310524,
      -0.023256603628396988,
      -0.03440112620592117,
      0.056641578674316406,
      -0.014193875715136528,
      0.027016138657927513,
      0.02762843482196331,
      -0.01913931407034397,
      -0.009216111153364182,
      0.0006264842231757939,
      0.01934424787759781,
      -0.014906279742717743,
      -0.015440934337675571,
      -0.021720729768276215,
      -0.046561095863580704,
      0.0006927435752004385,
      -0.02947808988392353,
      -0.020189277827739716,
      -0.039407070726156235,
      0.0447012335062027,
      -0.00438010785728693,
      0.02449154481291771,
      -0.004449253901839256,
      0.045007579028606415,
      0.07843140512704849,
      0.04369472339749336,
      -0.020030217245221138,
      -0.022297706454992294,
      0.01558897364884615,
      -0.00828529428690672,
      0.0056387754157185555,
      0.07248532027006149,
      -0.025091595947742462,
      -0.0036685934755951166,
      0.02915869653224945,
      -0.09773023426532745,
      0.02375626005232334,
      0.01921265199780464,
      -0.042453471571207047,
      -0.04061206057667732,
      -0.037088774144649506,
      -0.004198827315121889,
      -0.04490691050887108,
      -0.036129605025053024,
      0.0021027063485234976,
      0.022021044045686722,
      0.010562218725681305,
      0.0016835108399391174,
      -0.07522772252559662,
      0.016087576746940613,
      -0.0040720743127167225,
      -0.06101958826184273,
      0.01333866361528635,
      -0.005988565273582935,
      0.018204957246780396,
      -0.05829525738954544,
      -0.017595253884792328,
      0.007414941675961018,
      0.12114673107862473,
      0.03803057223558426,
      -0.0172446146607399,
      0.05199502781033516,
      -0.011968034319579601,
      -0.0688614621758461,
      0.010654553771018982,
      0.04029371589422226,
      -0.027189793065190315,
      0.019205590710043907,
      -0.0028436945285648108,
      -0.023720288649201393,
      -0.038253214210271835,
      -0.011097550392150879,
      0.01040668785572052,
      0.028257956728339195,
      -9.263390529667959e-05,
      0.06686194986104965,
      0.01932605728507042,
      -0.06793127954006195,
      -0.009756169281899929,
      -0.03321364149451256,
      -0.017102010548114777,
      0.011136900633573532,
      -0.031848758459091187,
      -0.06518683582544327,
      0.0070299976505339146,
      -0.03704305365681648,
      -0.12233337014913559,
      0.025975152850151062,
      -0.014931775629520416,
      -0.04344981908798218,
      0.05150901898741722,
      0.02740095742046833,
      0.06171143427491188,
      0.027398455888032913,
      0.07073116302490234,
      0.05769379436969757,
      0.02452092058956623,
      -0.033896032720804214,
      0.02012353017926216,
      0.0032798422034829855,
      -0.04887133464217186,
      -0.004447055980563164,
      -0.012351221404969692,
      -0.06150218844413757,
      0.05993157997727394,
      -0.12719424068927765,
      0.036483973264694214,
      0.017104296013712883,
      0.001211328199133277,
      -0.045339349657297134,
      -0.00021186054800637066,
      0.019406365230679512,
      0.08757320791482925,
      -0.08834581077098846,
      -0.027512438595294952,
      0.0349404476583004,
      0.024990534409880638,
      0.0067199659533798695,
      -0.06223289668560028,
      -0.005627780221402645,
      -0.04530315846204758,
      0.09893166273832321,
      -0.013246792368590832,
      -0.017479849979281425,
      0.013524418696761131,
      -0.0014767555985599756,
      0.038252558559179306,
      0.07872872799634933,
      -0.05550124868750572,
      -0.005578815005719662,
      -0.043580859899520874,
      -0.04158912971615791,
      0.02196398936212063,
      -0.11219426989555359,
      -0.014955316670238972,
      -0.023500915616750717,
      0.00023390183923766017,
      -0.0317261777818203,
      -0.013387403450906277,
      0.05512017011642456,
      -0.02768241986632347,
      -0.03988248482346535,
      0.04214464873075485,
      -0.0021540336310863495,
      -0.010551011189818382,
      0.10234127193689346,
      -0.07696903496980667,
      -0.017300941050052643,
      0.009837223216891289,
      0.0031416770070791245,
      -0.0014172302326187491,
      -0.04380026459693909,
      -0.004620544612407684,
      -0.10566424578428268,
      0.03963647410273552,
      0.02897733636200428,
      -0.09330034255981445,
      0.04926035925745964,
      -0.0983898937702179,
      0.016628338024020195,
      -0.005374887026846409,
      0.051030851900577545,
      -0.018212459981441498,
      0.05348420515656471,
      0.07547950744628906,
      -0.10552795976400375,
      0.02907109074294567,
      -0.02741449512541294,
      -0.04813136160373688,
      -0.006979606579989195,
      0.022986846044659615,
      0.021419314667582512,
      0.013518662191927433,
      -0.017292222008109093,
      -0.01210448145866394,
      0.007060913369059563,
      -0.06332557648420334,
      -0.011374915018677711,
      0.020838260650634766,
      -0.01489097997546196,
      0.027583623304963112,
      0.01892090030014515,
      -0.02188514545559883,
      -0.07152847945690155,
      0.02045312523841858,
      -0.0511605441570282,
      -0.08374793082475662,
      0.0147920623421669,
      0.02173464186489582,
      -0.04930032417178154,
      0.02442120760679245,
      0.07244734466075897,
      -0.01986018940806389,
      -0.037087369710206985,
      -0.05901136249303818,
      -0.03294103592634201,
      -0.006727830972522497,
      0.07664727419614792,
      -0.02487887814640999,
      -0.024052884429693222,
      0.03166107460856438,
      -0.09085544943809509,
      -0.006411182228475809,
      0.05106743797659874,
      0.09266219288110733,
      -0.02996085397899151
    ],
    [
      0.025057995691895485,
      0.029105575755238533,
      -0.042608842253685,
      -0.04376468434929848,
      0.030313309282064438,
      -0.029484080150723457,
      -0.012717603705823421,
      -0.014556481502950191,
      0.11599545925855637,
      -0.06293017417192459,
      -0.015307397581636906,
      0.01797153428196907,
      0.08597788959741592,
      -0.009450647979974747,
      0.029363660141825676,
      0.03247365728020668,
      -0.03580412268638611,
      -0.0552588514983654,
      0.07604184746742249,
      0.02060081623494625,
      0.026124319061636925,
      -0.027365708723664284,
      -0.08978695422410965,
      0.013302655890583992,
      0.06532654911279678,
      -0.03072896972298622,
      -0.004673831630498171,
      0.02725878544151783,
      -0.03305972367525101,
      0.007236775942146778,
      0.07185134291648865,
      0.09264808893203735,
      -0.0483849011361599,
      -0.004576184321194887,
      0.07460403442382812,
      0.005140765104442835,
      0.059642866253852844,
      0.0527430921792984,
      -0.013081216253340244,
      -0.017148930579423904,
      -0.12598124146461487,
      -0.06523232161998749,
      0.012905498966574669,
      0.04856709763407707,
      0.032868776470422745,
      0.007801180239766836,
      -0.030714692547917366,
      -0.08163581043481827,
      -0.025437908247113228,
      -0.07104288786649704,
      -0.04066991060972214,
      0.03329804912209511,
      0.05273423343896866,
      0.01894904300570488,
      0.019813576713204384,
      0.009405936114490032,
      0.031330354511737823,
      -0.04744139686226845,
      -0.02856943942606449,
      0.009758025407791138,
      -0.008443241938948631,
      0.05945475772023201,
      0.04002485051751137,
      -0.049375854432582855,
      0.03942997753620148,
      0.016186967492103577,
      -0.004631831776350737,
      0.10514555126428604,
      0.02328638546168804,
      0.0024801960680633783,
      -0.0637454092502594,
      0.01625092327594757,
      -0.04160887375473976,
      -0.017480114474892616,
      0.05752980709075928,
      -0.04700784385204315,
      0.031193220987915993,
      -6.786867743358016e-05,
      -0.02473151870071888,
      -0.03517020866274834,
      0.00898903887718916,
      -0.005107024684548378,
      0.02416878752410412,
      0.07237572968006134,
      -0.024321408942341805,
      -0.001898213173262775,
      0.04985533654689789,
      -0.026311160996556282,
      -0.0075600240379571915,
      0.009284603409469128,
      -0.08892197906970978,
      0.1018504649400711,
      0.02695331908762455,
      -0.045294564217329025,
      0.025868235155940056,
      0.02263188548386097,
      -0.06756823509931564,
      0.0039263623766601086,
      0.03313908725976944,
      -0.022953208535909653,
      0.03877779841423035,
      -0.02683999203145504,
      -0.04064059257507324,
      -0.03428565710783005,
      0.06750756502151489,
      -0.0018369117751717567,
      -0.08256401866674423,
      -0.02284993976354599,
      -0.0320117361843586,
      -0.006134933326393366,
      -0.022287927567958832,
      0.0053587243892252445,
      0.021391449496150017,
      0.04457123577594757,
      0.021164121106266975,
      0.0289366003125906,
      0.04162057116627693,
      -0.04086177423596382,
      -0.04246014356613159,
      0.07941848784685135,
      0.0023817429319024086,
      0.04543327912688255,
      0.19025373458862305,
      -0.04059948772192001,
      0.041895776987075806,
      0.0435195192694664,
      0.0500786267220974,
      -0.036357346922159195,
      -0.003519606776535511,
      -0.014872393570840359,
      -0.11173222213983536,
      -0.0018006782047450542,
      -0.001969569595530629,
      0.046714361757040024,
      0.017416786402463913,
      -0.05276980623602867,
      -0.06333054602146149,
      -0.03497646749019623,
      0.021280700340867043,
      -0.028084298595786095,
      0.010495788417756557,
      -0.02016434818506241,
      0.013006343506276608,
      0.04899749532341957,
      0.1036767065525055,
      0.06457088142633438,
      0.0030163174960762262,
      0.1369767189025879,
      0.015024391002953053,
      -0.06545240432024002,
      0.0005848632426932454,
      0.11149708926677704,
      -0.04924536123871803,
      -0.024569954723119736,
      -0.07603176683187485,
      0.031203070655465126,
      -0.07234556972980499,
      -0.05457327514886856,
      0.03671477362513542,
      -0.03498817980289459,
      -0.08163443952798843,
      0.06158559024333954,
      0.09357796609401703,
      0.05268295481801033,
      -0.01717248745262623,
      0.018098950386047363,
      -0.03894462063908577,
      0.04315138980746269,
      0.02819422259926796,
      -0.04012648016214371,
      0.01782684214413166,
      -0.0005845201085321605,
      0.029777785763144493,
      -0.11675985902547836,
      -0.06464611738920212,
      -0.008443540893495083,
      0.018826672807335854,
      0.10009285807609558,
      -0.00870601274073124,
      0.010248837992548943,
      -0.012111646123230457,
      0.10949064791202545,
      0.046243976801633835,
      0.028830084949731827,
      0.08961677551269531,
      -0.044913530349731445,
      0.07465849816799164,
      0.010373889468610287,
      -0.019975561648607254,
      -0.05376430228352547,
      -0.004464617930352688,
      -0.061060741543769836,
      -0.028792275115847588,
      0.042315371334552765,
      0.028819436207413673,
      -0.006303231231868267,
      0.06280188262462616,
      -0.07576911151409149,
      0.013083024881780148,
      -0.10072048753499985,
      -0.030201928690075874,
      -0.06447318941354752,
      0.056362055242061615,
      0.009646306745707989,
      -0.0045263939537107944,
      0.036091338843107224,
      -0.03116384893655777,
      0.0774829313158989,
      0.02157016657292843,
      -0.023006726056337357,
      -0.02807285264134407,
      0.017908038571476936,
      0.03280673921108246,
      0.021693000569939613,
      0.010818635113537312,
      -0.010187866166234016,
      -0.07605057209730148,
      0.002069368725642562,
      0.004441148601472378,
      0.0825912281870842,
      0.019783973693847656,
      0.04473472759127617,
      0.04419299587607384,
      0.06175896152853966,
      0.02194741554558277,
      -0.03402022272348404,
      0.08953318744897842,
      -0.1456470787525177,
      -0.02017393708229065,
      -0.010591679252684116,
      -0.03210340067744255,
      -0.04364365339279175,
      -0.08085000514984131,
      0.01940729096531868,
      0.05648624524474144,
      0.046042442321777344,
      -0.006139178294688463,
      0.02400483749806881,
      -0.03377557545900345,
      0.03473362699151039,
      0.005231109913438559,
      0.01419137418270111,
      -0.10799732804298401,
      0.09195871651172638,
      0.01941770873963833,
      -0.04038979485630989,
      0.06706952303647995,
      0.002660713391378522,
      0.04321665316820145,
      0.05159122869372368,
      -0.06559064984321594,
      -0.06074945628643036,
      -0.021275635808706284,
      -0.10437347739934921,
      -0.00956661719828844,
      0.06208685785531998,
      0.005445791874080896,
      0.07076461613178253,
      0.05991889536380768,
      0.024680238217115402,
      0.0025395776610821486,
      0.007141249254345894,
      -0.048544444143772125,
      0.005482225213199854,
      -0.001090297824703157,
      -0.04011647403240204,
      -0.09719585627317429,
      -0.032403893768787384,
      0.0412430465221405,
      0.06818325072526932,
      0.005417663604021072,
      0.005539223086088896,
      0.024924593046307564,
      -0.057024285197257996,
      0.03357990086078644,
      0.032719582319259644,
      0.017003655433654785,
      0.1152103841304779,
      -0.035416122525930405,
      -0.003906486555933952,
      0.032777998596429825,
      -0.022656014189124107,
      0.10044820606708527,
      -0.05821586400270462,
      0.004513977095484734,
      -0.06570794433355331,
      0.006748255342245102,
      -0.019030671566724777,
      0.022471265867352486,
      0.08500789850950241,
      -0.001832576934248209,
      -0.0772615522146225,
      -0.007126153446733952,
      0.013522549532353878,
      0.0010405168868601322,
      -0.01664428785443306,
      -0.0725371465086937,
      0.0022344791796058416,
      -0.0035420884378254414,
      0.020404813811182976,
      0.05519655719399452,
      0.006276384927332401,
      -0.009465206414461136,
      -0.013182378374040127,
      0.05111493170261383,
      -0.06658697873353958,
      0.019538523629307747,
      -0.046296145766973495,
      0.036689162254333496,
      -6.34144525974989e-05,
      -0.05908136069774628,
      0.064201220870018,
      -0.0628529042005539,
      -0.004604789428412914,
      0.008509837090969086,
      -0.03953222557902336,
      0.038137003779411316,
      -0.05146769806742668,
      -0.02611980400979519,
      -0.003930988255888224,
      0.12810327112674713,
      -0.06059519574046135,
      -0.04769255593419075,
      0.08832260221242905,
      -0.049438007175922394,
      0.04961983859539032,
      -0.02231021784245968,
      -0.03233969211578369,
      -0.00992934312671423,
      -0.005786429159343243,
      0.0832229033112526,
      0.03270784020423889,
      0.012317134067416191,
      0.029962539672851562,
      0.11096300184726715,
      0.0018797689117491245,
      -0.04163054749369621,
      -0.00933215394616127,
      -0.047911979258060455,
      -0.006718659773468971,
      -0.027858417481184006,
      0.020704425871372223,
      -0.08400679379701614,
      -0.039665624499320984,
      -0.034937649965286255,
      0.011588435620069504,
      0.056528929620981216,
      -0.014184646308422089,
      -0.04543585702776909,
      -0.01152449194341898,
      -0.035100869834423065,
      -0.024684924632310867,
      0.04849693924188614,
      0.06290408968925476,
      -0.032010942697525024,
      -0.00856386311352253,
      -0.04976557940244675,
      -0.04531676694750786,
      -0.014130156487226486,
      -0.014253742061555386,
      -0.04029850289225578,
      0.01855437085032463,
      -0.09916364401578903,
      0.022241180762648582,
      -0.0071494183503091335,
      -0.013049616478383541,
      -0.04279497265815735,
      -0.004564269911497831,
      -0.005297893658280373,
      -0.03320519998669624,
      -0.04232071340084076,
      -0.05268430337309837,
      0.0033502678852528334,
      -0.037552569061517715,
      -0.052051231265068054,
      -0.09280606359243393,
      0.010736861266195774,
      0.004266757518053055,
      -0.08095835894346237,
      -0.006638936698436737,
      0.0937533974647522,
      -0.07009480148553848,
      0.030282922089099884,
      -0.0408535860478878,
      0.04001661017537117,
      0.07211777567863464,
      0.01573294959962368,
      0.0400267168879509,
      -0.039022017270326614,
      -0.049199145287275314,
      0.032893456518650055,
      0.049818333238363266,
      0.020203666761517525,
      0.045823853462934494,
      -0.034814491868019104,
      0.005755349528044462,
      -0.026709135621786118,
      -0.047291986644268036,
      -0.01314481720328331,
      0.03481099009513855,
      0.09739801287651062,
      -0.026734597980976105,
      0.050215333700180054,
      -0.025839893147349358,
      -0.12922781705856323,
      -0.016067292541265488,
      0.015979239717125893,
      -0.0005825424450449646,
      0.06548188626766205,
      -0.043840520083904266,
      -0.05888570100069046,
      0.0866350531578064,
      -6.473229586845264e-05,
      -0.00870427954941988,
      -0.024420294910669327,
      0.09172176569700241,
      -0.020173024386167526,
      -0.02520272694528103,
      0.010011018253862858,
      0.02359747141599655,
      0.06103917956352234,
      -0.029538027942180634,
      -0.08403456956148148,
      -0.06241881102323532,
      -0.03833815082907677,
      -0.04105503112077713,
      -0.02127012610435486,
      0.029710642993450165,
      -0.0355398990213871,
      -0.038639139384031296,
      -0.0026475684717297554,
      -0.0407620370388031,
      0.01197140384465456,
      -0.007145989220589399,
      -0.014519691467285156,
      0.02040357142686844,
      0.00258511770516634,
      0.030329860746860504,
      -0.030655795708298683,
      -0.04750334098935127,
      0.0602547787129879,
      -0.05322403460741043,
      -0.080874003469944,
      -0.07724572718143463,
      0.031219130381941795,
      0.016868004575371742,
      -0.03370450809597969,
      -0.057895880192518234,
      0.07983299344778061,
      0.042116545140743256,
      -0.005638397764414549,
      -0.05446788668632507,
      -0.02969285100698471,
      0.0650903657078743,
      0.03060109354555607,
      0.0019766076002269983,
      -0.01748468168079853,
      0.03342759236693382,
      -0.020503342151641846,
      -0.033450234681367874,
      0.043207067996263504,
      0.012443487532436848,
      0.00577913224697113,
      -0.026183247566223145,
      0.03474089503288269,
      0.0332331508398056,
      -0.02442162297666073,
      -0.07367454469203949,
      0.1030011922121048,
      0.06436119973659515,
      0.07976197451353073,
      0.04371322691440582,
      -0.033554885536432266,
      -0.03605101630091667,
      -0.02453257516026497,
      0.05108722671866417,
      0.0027273751329630613,
      -0.0076589686796069145,
      0.012629647739231586,
      0.030889425426721573,
      0.004770580679178238,
      0.11766497045755386,
      -0.09019399434328079,
      -0.048122067004442215,
      0.06822644174098969,
      -0.05008647218346596,
      -0.08147894591093063,
      0.09792477637529373,
      -0.028081338852643967,
      0.08294540643692017,
      -0.022947929799556732,
      0.042478062212467194,
      -0.08054192364215851,
      -0.09224642813205719,
      0.02274709939956665,
      0.013298134319484234,
      0.06343930214643478,
      0.1271788626909256,
      0.06822122633457184,
      0.03799211233854294,
      -0.0002629394584801048,
      -0.024408429861068726,
      -0.024472853168845177
    ],
    [
      -0.04961375519633293,
      -0.013605967164039612,
      -0.048012714833021164,
      0.008601035922765732,
      -0.03711778298020363,
      -0.059095464646816254,
      -0.07151354104280472,
      -0.006602533161640167,
      -0.022000130265951157,
      -0.028747854754328728,
      -0.015119606629014015,
      -0.025390107184648514,
      -0.030638037249445915,
      -0.04411792755126953,
      -0.032540734857320786,
      -0.017655007541179657,
      0.051128558814525604,
      0.022473663091659546,
      0.014156106859445572,
      -0.027424590662121773,
      0.07307704538106918,
      -0.03533236309885979,
      0.03306771442294121,
      0.013849356211721897,
      -0.02632325142621994,
      0.09268052875995636,
      -0.05062651261687279,
      -0.002836621832102537,
      -0.021730659529566765,
      -0.022291332483291626,
      -0.02709909901022911,
      -0.013915234245359898,
      0.0034186451230198145,
      -0.05180520564317703,
      -0.0034614636097103357,
      -0.0021291205193847418,
      0.03876481205224991,
      -0.0072846850380301476,
      0.0011912514455616474,
      0.055470168590545654,
      -0.0995391234755516,
      -0.0013242580462247133,
      0.019696269184350967,
      0.011637183837592602,
      0.050308190286159515,
      -0.04070219397544861,
      -0.06068712845444679,
      0.037907447665929794,
      0.04154043272137642,
      0.05197633057832718,
      -0.03387344628572464,
      0.025634443387389183,
      0.03204825147986412,
      -0.020844552665948868,
      0.015555729158222675,
      0.08766274899244308,
      -0.02789926715195179,
      -0.019697673618793488,
      -0.07391154766082764,
      -0.052082713693380356,
      -0.05663003399968147,
      -0.06130672246217728,
      -0.03793805465102196,
      0.0520259365439415,
      -0.009290361776947975,
      0.006842581555247307,
      0.10906576365232468,
      0.029398782178759575,
      0.06095828488469124,
      -0.003679096233099699,
      -0.08121354877948761,
      0.015019653365015984,
      0.05046307295560837,
      0.06012045219540596,
      -0.00023218100250232965,
      -0.04513038322329521,
      -0.01337464340031147,
      0.019798768684267998,
      -0.06913071125745773,
      -0.04960208013653755,
      0.012041755951941013,
      -0.04196324944496155,
      0.009190347976982594,
      0.016458358615636826,
      0.01876070909202099,
      0.09403067827224731,
      -0.03374909609556198,
      -0.07573065906763077,
      0.05164526775479317,
      0.046772196888923645,
      -0.0019848837982863188,
      -0.008213447406888008,
      0.020025569945573807,
      -0.0071868677623569965,
      -0.015134175308048725,
      0.0474376380443573,
      -0.016560692340135574,
      -0.03360748663544655,
      -0.08222239464521408,
      -0.056851282715797424,
      0.05648213252425194,
      0.011272781528532505,
      -0.027121786028146744,
      -0.030357331037521362,
      0.0039296988397836685,
      0.050116632133722305,
      -0.03945877030491829,
      -0.049659911543130875,
      -0.021587036550045013,
      -0.018606118857860565,
      -0.04161570221185684,
      0.10389394313097,
      0.00024709224817343056,
      0.02063399739563465,
      -0.020617876201868057,
      -0.008388090878725052,
      0.03415793925523758,
      -0.06132392957806587,
      0.06579199433326721,
      0.0067155975848436356,
      -0.037262413650751114,
      -0.05235326662659645,
      -0.06476475298404694,
      0.02721451036632061,
      -0.008188850246369839,
      -0.06246257200837135,
      0.05386504530906677,
      0.09935782849788666,
      -0.05279740318655968,
      -0.010044792667031288,
      0.005108552053570747,
      0.05224290490150452,
      -0.02413381077349186,
      -0.08800756186246872,
      0.031087147071957588,
      0.01948700286448002,
      -0.021740486845374107,
      0.04233342781662941,
      0.04639023542404175,
      0.04872005805373192,
      0.13136497139930725,
      0.07245802879333496,
      -0.06780564039945602,
      -0.018449999392032623,
      0.030017642304301262,
      -0.043779704719781876,
      -0.0025865097995847464,
      -0.0340944267809391,
      0.0025982314255088568,
      0.030362717807292938,
      -0.09346219152212143,
      0.15006381273269653,
      -0.026310931891202927,
      0.03641096130013466,
      -0.008776640519499779,
      0.009519734419882298,
      0.0173563864082098,
      0.005566834006458521,
      -0.039542339742183685,
      0.10121824592351913,
      -0.020362159237265587,
      -0.07780387252569199,
      0.05943603813648224,
      0.05836553871631622,
      0.0811847671866417,
      0.04848534241318703,
      0.006339562125504017,
      -0.025417227298021317,
      -0.01798170804977417,
      0.10104033350944519,
      0.05298718810081482,
      -0.03665832057595253,
      -0.12066568434238434,
      -0.002517925575375557,
      -0.056648239493370056,
      -0.011714914813637733,
      -0.05229586735367775,
      0.09361619502305984,
      -0.026484737172722816,
      -0.03045114316046238,
      0.01085939072072506,
      -0.0457339771091938,
      0.013231591321527958,
      -0.04137403145432472,
      0.03887655958533287,
      0.010854283347725868,
      -0.058699946850538254,
      -0.02440250851213932,
      -0.036530762910842896,
      -0.06478006392717361,
      -0.0016071631107479334,
      -0.036218587309122086,
      0.04105210304260254,
      0.02944236248731613,
      0.005563478916883469,
      0.0525362491607666,
      -0.010294969193637371,
      -0.0831267312169075,
      0.11729543656110764,
      -0.010841134935617447,
      0.08683037757873535,
      -0.0005770366988144815,
      -0.00304336566478014,
      0.011641988530755043,
      0.036462750285863876,
      -0.08014620095491409,
      -0.04645268991589546,
      0.043560683727264404,
      0.0769122987985611,
      -0.008047206327319145,
      0.01200264785438776,
      -0.022550133988261223,
      0.021757254377007484,
      -0.0014711926924064755,
      -0.02601546049118042,
      -0.014847024343907833,
      -0.0028387592174112797,
      -0.07564803212881088,
      0.046241916716098785,
      0.047804586589336395,
      -0.06641972064971924,
      -0.15768221020698547,
      0.013706116005778313,
      -0.050059180706739426,
      0.053710367530584335,
      -0.005867175292223692,
      -0.08555588871240616,
      -0.07577885687351227,
      -0.029307620599865913,
      0.03603681921958923,
      0.14064058661460876,
      0.0138101726770401,
      0.08589575439691544,
      0.0002576874103397131,
      0.07657923549413681,
      -0.02261761575937271,
      0.03592578321695328,
      -0.06992605328559875,
      5.017565126763657e-05,
      0.09255574643611908,
      -0.03978433087468147,
      -0.032273415476083755,
      -0.0017867289716377854,
      -2.299832704011351e-05,
      -0.034577395766973495,
      0.004792936611920595,
      0.09681444615125656,
      0.022586585953831673,
      -0.0010020671179518104,
      -0.035904742777347565,
      0.030455414205789566,
      -0.0052297525107860565,
      -0.08919503539800644,
      -0.013270695693790913,
      0.001619871356524527,
      0.05250556766986847,
      0.08654628694057465,
      -0.029720216989517212,
      -0.033556606620550156,
      -0.008623450994491577,
      0.023969562724232674,
      0.1269046515226364,
      -0.012534936890006065,
      -0.04505527392029762,
      -0.024424640461802483,
      0.05694065988063812,
      -0.002182259690016508,
      0.02092062495648861,
      -0.07396148890256882,
      0.007474446669220924,
      -0.05006272718310356,
      -0.016422374173998833,
      0.0597105510532856,
      -0.02019195631146431,
      0.0148348119109869,
      -0.014681341126561165,
      -0.0060904864221811295,
      0.054802533239126205,
      0.02798847109079361,
      -0.02381134405732155,
      0.04498498886823654,
      -0.02662241831421852,
      0.03891561180353165,
      -0.0009038590942509472,
      0.04421161115169525,
      -0.03545757383108139,
      -0.004475973546504974,
      -0.02300051599740982,
      0.014135684818029404,
      0.05560437589883804,
      0.04883492365479469,
      -0.053184911608695984,
      0.01699325628578663,
      -0.09119658172130585,
      -0.07439544051885605,
      0.016118118539452553,
      0.022082051262259483,
      0.0385013073682785,
      -0.06819657236337662,
      -0.058464400470256805,
      0.039620015770196915,
      -0.031403448432683945,
      -0.053741104900836945,
      0.010759999975562096,
      -0.00815183762460947,
      -0.06439648568630219,
      0.02622370794415474,
      0.02239704318344593,
      -0.02208954282104969,
      0.03204825520515442,
      -0.07826141268014908,
      -0.025031503289937973,
      -0.00528379762545228,
      -0.0017296939622610807,
      -0.05900689959526062,
      -0.012649238109588623,
      0.08251528441905975,
      -0.07029952853918076,
      0.0737605020403862,
      0.01200884860008955,
      0.09442229568958282,
      0.02184722013771534,
      0.024246567860245705,
      -0.05470028147101402,
      -0.06894348561763763,
      0.036411307752132416,
      -0.05072247236967087,
      0.04942820221185684,
      -0.06258907914161682,
      -0.0071755037643015385,
      -0.03350847214460373,
      -0.0058293030597269535,
      -0.004727396182715893,
      -0.008861145935952663,
      0.020628435537219048,
      -0.00927648413926363,
      0.002770554507151246,
      0.0021144591737538576,
      0.04206297546625137,
      0.04987001046538353,
      -0.04314626008272171,
      0.02120077796280384,
      -0.016545120626688004,
      -0.004736114758998156,
      0.0684119313955307,
      0.009384039789438248,
      0.07491805404424667,
      0.059752900153398514,
      -0.02995116263628006,
      0.0222015380859375,
      0.0892188623547554,
      -0.032123297452926636,
      0.013705953024327755,
      0.062231291085481644,
      0.021008223295211792,
      0.03462260589003563,
      0.005591236520558596,
      -0.02613845281302929,
      -0.04266567528247833,
      -0.04279719665646553,
      -0.01099659688770771,
      -0.02723798342049122,
      -0.007425972260534763,
      -0.03436161205172539,
      0.03910418227314949,
      -0.04457258805632591,
      -0.059255748987197876,
      0.07115844637155533,
      -0.022761210799217224,
      0.005339302588254213,
      -0.04762522131204605,
      0.016347356140613556,
      0.07620365917682648,
      -0.013579728081822395,
      0.019564248621463776,
      -0.0010214486392214894,
      -0.013766160234808922,
      0.019696718081831932,
      -0.06320153921842575,
      -0.014366446994245052,
      0.02242390438914299,
      0.018076568841934204,
      -0.004394597839564085,
      -0.03926580026745796,
      -0.04709949716925621,
      0.04383831098675728,
      -0.035081710666418076,
      -0.01435238029807806,
      -0.0479830801486969,
      0.02877826988697052,
      0.04604773968458176,
      0.04820746183395386,
      0.061130426824092865,
      0.015601486898958683,
      -0.06490034610033035,
      0.01672966219484806,
      0.024787142872810364,
      -0.11775613576173782,
      -0.0945381447672844,
      0.0793883353471756,
      0.12250640243291855,
      0.006057757884263992,
      0.07676149159669876,
      -0.0044607119634747505,
      0.08042044192552567,
      0.04633798822760582,
      0.09453200548887253,
      -0.002123560756444931,
      0.04225251451134682,
      -0.048586081713438034,
      -0.08820779621601105,
      0.062118615955114365,
      0.028740832582116127,
      0.037038180977106094,
      -0.047838736325502396,
      0.026889245957136154,
      -0.007234806660562754,
      0.030094118788838387,
      0.039845701307058334,
      0.04906676709651947,
      0.019343363121151924,
      0.08313245326280594,
      -0.02808363363146782,
      -0.024715838953852654,
      -0.013955049216747284,
      -0.005049165338277817,
      -0.029195323586463928,
      -0.032713450491428375,
      0.03146413341164589,
      0.11906839907169342,
      0.039883844554424286,
      -0.07325489073991776,
      -0.027770139276981354,
      0.004805173724889755,
      0.048759784549474716,
      0.03312820941209793,
      0.07559355348348618,
      0.049138497561216354,
      0.00758699094876647,
      0.0003840551944449544,
      0.003411192912608385,
      -0.009162979200482368,
      0.07629875838756561,
      -0.06516097486019135,
      0.04167495667934418,
      0.05572947487235069,
      -0.024441659450531006,
      -0.021299850195646286,
      -0.049681175500154495,
      -0.028086120262742043,
      -0.02995469607412815,
      -0.06644213199615479,
      -0.03688384220004082,
      -0.008671490475535393,
      0.01806682161986828,
      -0.0070434445515275,
      0.05145377293229103,
      0.033623408526182175,
      0.033554721623659134,
      0.0015417998656630516,
      0.03599253669381142,
      -0.027687128633260727,
      0.012681610882282257,
      -0.019733505323529243,
      -0.07620050758123398,
      0.04812469333410263,
      0.09943535178899765,
      -0.007226259913295507,
      -0.0260224100202322,
      -0.09933499246835709,
      0.05308789387345314,
      -0.015548694878816605,
      -0.005061516538262367,
      0.04567646235227585,
      0.06798289716243744,
      -0.00867454893887043,
      -0.06130320206284523,
      -0.02078133076429367,
      0.06512710452079773,
      -0.008281799964606762,
      0.10502321273088455,
      0.017422068864107132,
      0.018990399315953255,
      0.06208265200257301,
      0.07115976512432098,
      -0.07567053288221359,
      -0.028757162392139435,
      0.02794024907052517,
      -0.005965360440313816,
      -0.007089065853506327,
      -0.005673802923411131,
      0.06602632254362106,
      0.029384339228272438,
      -0.09667400270700455,
      0.01915784925222397,
      0.023709923028945923,
      0.027399972081184387,
      0.05573339760303497,
      -0.018236489966511726,
      0.0261183213442564,
      0.03495948761701584,
      0.04472855105996132,
      -0.062313374131917953
    ],
    [
      0.04098702222108841,
      -0.0017187573248520494,
      -0.08586939424276352,
      -0.03511618450284004,
      0.034664254635572433,
      0.015435771085321903,
      0.029135026037693024,
      0.015277393162250519,
      0.1037735864520073,
      0.007522184401750565,
      -0.0019520050846040249,
      0.03073040023446083,
      0.09175970405340195,
      -0.06902140378952026,
      -0.025571666657924652,
      -0.037181977182626724,
      0.030958764255046844,
      -0.028141863644123077,
      0.08645358681678772,
      0.01842874474823475,
      -0.05533849447965622,
      0.010284320451319218,
      0.012953185476362705,
      0.015380408614873886,
      0.0036718309856951237,
      0.07570671290159225,
      -0.062417980283498764,
      0.041883163154125214,
      0.036288779228925705,
      0.029739918187260628,
      -0.04573293402791023,
      0.044697657227516174,
      -0.08947812765836716,
      -0.06285321712493896,
      -0.009303559549152851,
      0.062306925654411316,
      -0.04587182402610779,
      -0.02757088467478752,
      0.04471278190612793,
      -0.05534333363175392,
      -0.10838520526885986,
      -0.007018977776169777,
      0.03329484537243843,
      -0.0314636193215847,
      0.022676981985569,
      0.07729435712099075,
      -0.035018712282180786,
      0.0694645345211029,
      0.062219128012657166,
      -0.04970467835664749,
      0.029524026438593864,
      -0.0017614293610677123,
      -0.002973938826471567,
      0.037741150707006454,
      0.013907759450376034,
      0.04825381934642792,
      -0.045761894434690475,
      -0.008371548727154732,
      -0.01278630830347538,
      -0.0033265030942857265,
      -0.01149793528020382,
      0.03913209214806557,
      0.04425403103232384,
      -0.05439670756459236,
      0.014967439696192741,
      0.03104114532470703,
      -0.022954657673835754,
      0.02118391916155815,
      -0.027173543348908424,
      0.032283008098602295,
      0.03494033217430115,
      0.05472655966877937,
      0.020897679030895233,
      0.017525697126984596,
      -0.031741395592689514,
      -0.06094960495829582,
      0.026772689074277878,
      0.026889534667134285,
      0.03630978614091873,
      -0.052197836339473724,
      -0.02538829855620861,
      0.02873792126774788,
      -0.05401679873466492,
      0.02588023617863655,
      0.002508132718503475,
      0.00698438286781311,
      0.055356673896312714,
      -0.05916186422109604,
      0.021900145336985588,
      0.029554683715105057,
      0.027738057076931,
      -0.04824782907962799,
      0.020607350394129753,
      0.026674119755625725,
      0.01642480306327343,
      -0.05093339458107948,
      -0.025418579578399658,
      -0.017679234966635704,
      -0.01609530672430992,
      -0.028644192963838577,
      0.054690685123205185,
      -0.022251375019550323,
      0.027647074311971664,
      0.0013745796168223023,
      0.031788118183612823,
      -0.053125545382499695,
      0.011184364557266235,
      -0.049791958183050156,
      0.05568068474531174,
      -0.04957160726189613,
      -0.05009854957461357,
      -0.008505836129188538,
      -0.0310007743537426,
      0.01099340245127678,
      -0.027239754796028137,
      -0.06928116083145142,
      -0.038976941257715225,
      -0.021537642925977707,
      0.017753588035702705,
      -0.04702768102288246,
      0.11447480320930481,
      0.03450106084346771,
      0.03306214138865471,
      0.022756388410925865,
      -0.04557124525308609,
      -0.06916595995426178,
      0.03281741589307785,
      -0.0034450003877282143,
      0.006306915078312159,
      -0.016445791348814964,
      -0.007110618520528078,
      0.0365879200398922,
      -0.04697730019688606,
      0.0966629832983017,
      -0.05033912509679794,
      -0.029507966712117195,
      0.01672287844121456,
      -0.04194393381476402,
      0.05911407992243767,
      0.020915566012263298,
      -0.11244343966245651,
      0.025363797321915627,
      -0.008096101693809032,
      -0.02323455736041069,
      -0.005144785158336163,
      -0.009571320377290249,
      0.030904371291399002,
      -0.04173002764582634,
      -0.009862687438726425,
      0.00519193522632122,
      0.012365492060780525,
      0.03665337339043617,
      -0.05057472363114357,
      0.017090769484639168,
      0.03830443695187569,
      -0.031999506056308746,
      0.03715333342552185,
      -0.023426737636327744,
      0.06429469585418701,
      0.04145180433988571,
      0.04682363569736481,
      -0.0013842935441061854,
      0.1562298834323883,
      0.022769814357161522,
      0.04655664041638374,
      0.052281416952610016,
      0.004696686286479235,
      -0.10797834396362305,
      -0.014672358520328999,
      0.04019187390804291,
      -0.020052161067724228,
      0.05192272737622261,
      0.0023545026779174805,
      -0.0786195620894432,
      -0.0027948268689215183,
      -0.04780866578221321,
      -0.1021072268486023,
      0.04842952638864517,
      -0.07418864965438843,
      -0.01789870485663414,
      -0.0497068390250206,
      0.027538560330867767,
      0.08300662040710449,
      0.018492726609110832,
      0.013142305426299572,
      0.05285225063562393,
      0.016581755131483078,
      0.03316238895058632,
      -0.041172564029693604,
      0.042540986090898514,
      -0.0061533753760159016,
      -0.008940653875470161,
      0.0683610737323761,
      -0.006788101978600025,
      0.07120762020349503,
      0.0030830895993858576,
      0.010280760936439037,
      0.06513289362192154,
      0.01643466204404831,
      -0.057996563613414764,
      0.12545107305049896,
      0.0642056092619896,
      0.01729138195514679,
      0.02909976989030838,
      -0.016760755330324173,
      -0.031574755907058716,
      0.09675001353025436,
      -0.0804443433880806,
      0.03166817128658295,
      0.042661670595407486,
      0.047572311013936996,
      0.002667190507054329,
      0.08194099366664886,
      0.042020175606012344,
      -0.013700723648071289,
      0.04120828956365585,
      -0.0021773683838546276,
      -0.05086512491106987,
      -0.1143200621008873,
      -0.04600932449102402,
      0.017724057659506798,
      -0.14158743619918823,
      0.07748797535896301,
      0.08657079190015793,
      -0.042810600250959396,
      -0.034614771604537964,
      -0.025933291763067245,
      -0.06433862447738647,
      0.027219854295253754,
      -0.026653189212083817,
      0.019594822078943253,
      0.0439491868019104,
      0.042735688388347626,
      -0.014273479580879211,
      0.02860553376376629,
      -0.005313059780746698,
      -0.0053209951147437096,
      0.06561246514320374,
      0.05155891925096512,
      -0.08243374526500702,
      -0.09289595484733582,
      0.07638353109359741,
      -0.026045028120279312,
      0.0887204110622406,
      0.05720856413245201,
      -0.053974706679582596,
      0.035366710275411606,
      0.1264486014842987,
      -0.017558252438902855,
      -0.05755080282688141,
      -0.048349034041166306,
      -0.05943992733955383,
      -0.06262310594320297,
      0.0010172667680308223,
      0.007769605610519648,
      -0.016066106036305428,
      0.014305749908089638,
      -0.028832243755459785,
      0.03880556300282478,
      0.004353771451860666,
      -0.04053477570414543,
      -0.013182660564780235,
      -0.09116385132074356,
      0.0331212617456913,
      -0.026247836649417877,
      0.03294158726930618,
      -0.021243931725621223,
      -0.059050995856523514,
      0.01753557287156582,
      -0.05574903264641762,
      0.030443677678704262,
      -0.03437535837292671,
      -0.06870048493146896,
      -0.006120603531599045,
      0.0014544004807248712,
      0.0616518035531044,
      0.00639349315315485,
      0.09336041659116745,
      -0.002191329374909401,
      0.014459083788096905,
      -0.039260298013687134,
      0.03307105600833893,
      -0.002384946448728442,
      -0.003380151931196451,
      0.05776851996779442,
      0.022376451641321182,
      0.04641102999448776,
      -0.0536067932844162,
      -0.031000444665551186,
      0.009574929252266884,
      -0.09115524590015411,
      0.002840911503881216,
      0.051399316638708115,
      0.09046346694231033,
      -0.05973668769001961,
      0.005024924408644438,
      -0.010169453918933868,
      0.042299121618270874,
      -0.005524280481040478,
      0.029309537261724472,
      -0.03801090270280838,
      0.011926236562430859,
      -0.03621640056371689,
      0.0034899928141385317,
      0.03474479913711548,
      -0.012240324169397354,
      -0.009972277097404003,
      -0.03084065020084381,
      -0.01822580024600029,
      0.0004432088462635875,
      0.014748036861419678,
      0.05254778265953064,
      -0.011887332424521446,
      0.008599244058132172,
      0.047355879098176956,
      -0.012017292901873589,
      -0.0058319116942584515,
      -0.019603785127401352,
      -0.020578667521476746,
      -0.026633277535438538,
      0.064822718501091,
      0.01962265931069851,
      0.02372857555747032,
      0.062459684908390045,
      -0.09028792381286621,
      0.008905974216759205,
      -0.03934743255376816,
      0.02574104629456997,
      0.11675971001386642,
      -0.037650104612112045,
      0.05418184772133827,
      0.01711798831820488,
      -0.053341228514909744,
      -0.040802281349897385,
      0.056293316185474396,
      0.01921890117228031,
      0.08008547872304916,
      -0.023997759446501732,
      -0.027605606243014336,
      0.02273419499397278,
      -0.030519917607307434,
      -0.0006144486251287162,
      -0.018082240596413612,
      -0.04975363612174988,
      -0.0379452258348465,
      -0.029495978727936745,
      -0.002432907233014703,
      0.039214909076690674,
      0.010340213775634766,
      -0.01608724147081375,
      -0.0856860876083374,
      0.011212519370019436,
      -0.0008617857238277793,
      -0.06623893231153488,
      -0.0005400971858762205,
      -0.033636461943387985,
      0.03178592398762703,
      0.007072237320244312,
      -0.03729083389043808,
      -0.016064751893281937,
      0.09696278721094131,
      0.014233197085559368,
      -0.08590546995401382,
      -0.01823212020099163,
      0.015173855237662792,
      -0.053721506148576736,
      -0.03856026008725166,
      0.11261455714702606,
      -0.012136390432715416,
      0.02601838856935501,
      -0.027432989329099655,
      -0.08936920762062073,
      -0.0003444261965341866,
      -0.023926785215735435,
      0.04638234153389931,
      -0.04021787270903587,
      0.007693065330386162,
      -0.0011477115331217647,
      -0.013953050598502159,
      0.01740691065788269,
      0.001860232325270772,
      -0.017906995490193367,
      0.00811646319925785,
      -0.06474823504686356,
      -0.02058832161128521,
      0.0915687084197998,
      0.023352157324552536,
      -0.028337707743048668,
      -0.07609956711530685,
      -0.01973482221364975,
      0.04321187362074852,
      -0.07725769281387329,
      0.038920123130083084,
      0.012556368485093117,
      0.03376526013016701,
      -0.034879375249147415,
      -0.027852805331349373,
      0.003733286401256919,
      -0.08345840126276016,
      0.017974430695176125,
      -0.017309457063674927,
      0.007964679040014744,
      0.11167417466640472,
      -0.05361192300915718,
      -0.0084126191213727,
      0.021118411794304848,
      -0.03671552985906601,
      0.04017861559987068,
      0.03879108652472496,
      0.018772196024656296,
      -0.07112342119216919,
      -0.014986616559326649,
      0.03343794867396355,
      0.03075604885816574,
      0.0013258634135127068,
      0.01565791666507721,
      -0.05895516276359558,
      0.061657194048166275,
      0.01609407365322113,
      -0.02212003618478775,
      -0.02730586938560009,
      0.01921755075454712,
      -0.007584225852042437,
      -0.00659977039322257,
      -0.004145088605582714,
      -0.009159844368696213,
      -0.053924985229969025,
      -0.008459262549877167,
      -0.06959918886423111,
      -0.010847458615899086,
      -0.013872730545699596,
      -0.002219319809228182,
      -0.04420694708824158,
      0.05537782609462738,
      0.041816435754299164,
      0.025470148772001266,
      -0.0007500271312892437,
      0.033816706389188766,
      -0.03405183181166649,
      0.05558113381266594,
      0.01373918354511261,
      0.007314033340662718,
      -0.007408663164824247,
      0.028928451240062714,
      0.1050199344754219,
      -0.0025789591018110514,
      -0.009029950015246868,
      -0.03268185630440712,
      0.0014050065074115992,
      -0.04702618345618248,
      -0.0012131630210205913,
      0.004825509153306484,
      -0.011651935055851936,
      0.02167501486837864,
      -0.003243369050323963,
      0.05184505507349968,
      0.0962640643119812,
      0.12947659194469452,
      -0.011922928504645824,
      -0.004453407600522041,
      0.001174118951894343,
      0.001145035377703607,
      -0.009414157830178738,
      -0.06084320321679115,
      -0.01735868491232395,
      -0.04587709903717041,
      -0.053338900208473206,
      0.0385625846683979,
      0.044382717460393906,
      -0.029445061460137367,
      0.09226977825164795,
      0.08358506858348846,
      -0.011911208741366863,
      0.026271387934684753,
      0.030461423099040985,
      -0.005925895180553198,
      -0.05627001076936722,
      -0.039363037794828415,
      0.03925682604312897,
      -0.09426107257604599,
      -0.033500608056783676,
      0.016480840742588043,
      -0.0176247488707304,
      -0.08884406089782715,
      0.01673194020986557,
      -0.018389571458101273,
      -0.07900384813547134,
      -0.019911903887987137,
      0.04185966029763222,
      -0.025714542716741562,
      -0.040187593549489975,
      -0.006393432151526213,
      0.04397599771618843,
      -0.02212064154446125,
      0.048583220690488815,
      0.003799272933974862,
      0.04411507397890091,
      0.010637665167450905,
      -0.04602551460266113,
      -0.07976318150758743,
      0.052137766033411026,
      -0.08129119873046875,
      0.04079101234674454
    ],
    [
      -0.04059219732880592,
      0.027542414143681526,
      0.00905479397624731,
      -0.037259507924318314,
      0.049484964460134506,
      -0.0982486680150032,
      0.003850918263196945,
      0.026525547727942467,
      0.08548411726951599,
      0.06613697856664658,
      0.009939765557646751,
      0.022850187495350838,
      -0.08740605413913727,
      0.037344031035900116,
      -0.026611456647515297,
      -0.05002059414982796,
      0.06461039185523987,
      -0.11706434190273285,
      -0.003077839268371463,
      -0.0004699328274000436,
      0.04889318346977234,
      0.019068697467446327,
      -0.02355893887579441,
      0.09733018279075623,
      0.0010423919884487987,
      0.039781950414180756,
      -0.007094874046742916,
      0.055240221321582794,
      0.018627654761075974,
      0.010517654940485954,
      0.014509690925478935,
      -0.039201732724905014,
      -0.031513821333646774,
      0.053340233862400055,
      -0.019466837868094444,
      -0.06691469252109528,
      0.04689023643732071,
      0.01518006157130003,
      0.014791074208915234,
      0.008180303499102592,
      -0.009479287080466747,
      0.04295432195067406,
      0.05117030069231987,
      0.03335154801607132,
      0.0004087334673386067,
      -0.07926256209611893,
      0.09933876991271973,
      -0.011128309182822704,
      -0.01914960891008377,
      -0.10607291013002396,
      0.05829078331589699,
      0.06462930142879486,
      0.00033080572029575706,
      0.05461890622973442,
      -0.02680983394384384,
      -0.06300894170999527,
      -0.10459978133440018,
      0.011753642931580544,
      -0.05251061171293259,
      -0.0007688274490647018,
      0.059723205864429474,
      0.014890317805111408,
      0.02061520516872406,
      -0.07069674134254456,
      -0.07641245424747467,
      -0.004932326730340719,
      0.01240015309303999,
      0.11920937150716782,
      -0.016285274177789688,
      -0.017570246011018753,
      0.009534278884530067,
      -0.03869076445698738,
      0.040686529129743576,
      -0.037597861140966415,
      0.126155823469162,
      -0.060794975608587265,
      0.09471303969621658,
      0.01780884712934494,
      -0.030659150332212448,
      -0.0029286982025951147,
      -0.028965197503566742,
      0.02853858284652233,
      -0.0038665728643536568,
      0.0775565430521965,
      0.03537977114319801,
      -0.035629745572805405,
      0.02384081669151783,
      -0.05596119165420532,
      -0.023961922153830528,
      0.009930417872965336,
      0.049347538501024246,
      -0.01174475159496069,
      -0.0448218509554863,
      -0.043738413602113724,
      0.019291138276457787,
      0.0061091766692698,
      -0.021765556186437607,
      -0.053225331008434296,
      -0.06916718184947968,
      0.043746218085289,
      0.024142341688275337,
      0.007035169750452042,
      -0.025314977392554283,
      0.0452926903963089,
      -0.004856939427554607,
      -0.057930197566747665,
      -0.08319178968667984,
      -0.0005514735821634531,
      -0.04248318076133728,
      0.007503827568143606,
      -0.018655596300959587,
      -0.003978385590016842,
      0.0017977195093408227,
      -0.05675444379448891,
      0.050058018416166306,
      0.04236292839050293,
      -0.017306141555309296,
      0.011077354662120342,
      0.07598050683736801,
      0.06769896298646927,
      0.060465846210718155,
      -0.008702834136784077,
      -0.01464514248073101,
      -0.04909682646393776,
      0.006672068499028683,
      -0.05839405208826065,
      -0.0163386519998312,
      0.09544114023447037,
      -0.052486978471279144,
      -0.049330636858940125,
      -0.011083468794822693,
      -0.03145362436771393,
      -0.07498793303966522,
      0.021737247705459595,
      0.036418214440345764,
      0.00940800178796053,
      -0.02754841186106205,
      0.06466592103242874,
      0.022923754528164864,
      0.07103672623634338,
      -0.01853509247303009,
      0.06259004026651382,
      -0.007188091985881329,
      0.04565005749464035,
      -0.016817545518279076,
      0.030946873128414154,
      -0.04031727463006973,
      -0.0140159260481596,
      0.018204113468527794,
      0.01887066662311554,
      0.0017385096289217472,
      -0.03397100791335106,
      -0.006749880034476519,
      0.03746761381626129,
      0.002385198837146163,
      -0.023896289989352226,
      -0.02332383766770363,
      0.05895153805613518,
      -0.004563877824693918,
      0.08247928321361542,
      0.024716433137655258,
      -0.030075853690505028,
      0.03276367485523224,
      -0.035028766840696335,
      0.020484330132603645,
      0.015597103163599968,
      -0.028997818008065224,
      0.038560472428798676,
      -0.011970018967986107,
      0.03163738176226616,
      0.00989408977329731,
      0.006820697337388992,
      0.020411772653460503,
      0.07285791635513306,
      -0.01876639574766159,
      -0.03920167312026024,
      -0.08658719807863235,
      0.010570304468274117,
      -0.01645607128739357,
      0.03695179149508476,
      0.022943327203392982,
      -0.0072423191741108894,
      0.0861792117357254,
      0.060559310019016266,
      -0.012531900778412819,
      0.00679574441164732,
      0.04196606203913689,
      0.029072655364871025,
      -0.041563041508197784,
      -0.08288701623678207,
      -0.005368422716856003,
      -0.029915399849414825,
      0.0470748171210289,
      -0.0012930782977491617,
      0.0076431347988545895,
      -5.966160460957326e-05,
      -0.03904341906309128,
      -0.033266592770814896,
      0.016021331772208214,
      -0.06076882407069206,
      0.07591407746076584,
      0.09794066101312637,
      -0.060560569167137146,
      0.0061955139972269535,
      0.02465132623910904,
      0.03250127658247948,
      0.07750549912452698,
      0.054972581565380096,
      0.07042518258094788,
      0.03674021735787392,
      0.049955978989601135,
      0.0016920530470088124,
      0.0790340006351471,
      -0.006938507314771414,
      0.05808477848768234,
      -0.018237276002764702,
      -0.011789736337959766,
      -0.046311065554618835,
      -0.022008050233125687,
      0.021371791139245033,
      -0.019960055127739906,
      -0.0926501527428627,
      -0.0016849797684699297,
      0.06336381286382675,
      -0.0334928035736084,
      0.008408814668655396,
      0.03925744816660881,
      -0.045448243618011475,
      0.048324212431907654,
      0.07284896075725555,
      0.07892134040594101,
      0.07574084401130676,
      0.014197900891304016,
      0.00018927085329778492,
      0.010618127882480621,
      0.026767672970891,
      0.046874020248651505,
      0.036992188543081284,
      -0.00506872683763504,
      0.0487145259976387,
      -0.11564698070287704,
      -0.07082612067461014,
      0.006136362440884113,
      -0.05309080705046654,
      -0.01857289858162403,
      0.06602660566568375,
      -0.04541013389825821,
      -0.006347615737468004,
      0.053262580186128616,
      0.03203292563557625,
      -0.03890193626284599,
      0.023038940504193306,
      0.0032300676684826612,
      0.03625563532114029,
      -0.0035894622560590506,
      0.0030544516630470753,
      -0.06344279646873474,
      -0.024179259315133095,
      -0.04245082288980484,
      0.004187401384115219,
      -0.062069378793239594,
      -0.04930964484810829,
      0.02374333143234253,
      -0.013942135497927666,
      -0.059414733201265335,
      0.02339382842183113,
      0.10166505724191666,
      -0.0371660552918911,
      -0.057279668748378754,
      -0.04637568071484566,
      0.0270673930644989,
      0.06767050921916962,
      0.011427238583564758,
      0.006207597907632589,
      -0.023169860243797302,
      -0.05211428925395012,
      0.017900727689266205,
      -0.008430154994130135,
      0.0726400688290596,
      -0.0030038163531571627,
      -0.007878346368670464,
      -0.013378314673900604,
      -0.00028521340573206544,
      0.010188927873969078,
      0.01692371629178524,
      -0.014738193713128567,
      -0.012577315792441368,
      0.032982759177684784,
      0.09171987324953079,
      -0.006579436827450991,
      -0.07377110421657562,
      0.0075903357937932014,
      -0.0037769535556435585,
      -0.086982861161232,
      -0.018044915050268173,
      -0.010215110145509243,
      -0.005342989694327116,
      -0.01190858706831932,
      0.10426239669322968,
      0.08726126700639725,
      0.03888344764709473,
      0.04614748805761337,
      0.026437025517225266,
      0.02570473402738571,
      -0.04695194959640503,
      0.06642207503318787,
      -3.24273387377616e-05,
      -0.07025784999132156,
      -0.048264648765325546,
      0.04099453240633011,
      -0.014893125742673874,
      0.01866872049868107,
      0.025782424956560135,
      -0.004924906883388758,
      0.03618265315890312,
      0.047586265951395035,
      0.016138777136802673,
      -0.011291448026895523,
      0.04174451529979706,
      -0.01728902757167816,
      0.037741780281066895,
      -0.0017215876141563058,
      0.04281451180577278,
      -0.026840277016162872,
      0.011675049550831318,
      -0.04680974781513214,
      0.05851532891392708,
      0.07311080396175385,
      0.05598730221390724,
      -0.05102534219622612,
      0.010269430465996265,
      0.0034072138369083405,
      0.02842053957283497,
      -0.030360933393239975,
      0.00489669106900692,
      -0.044395577162504196,
      0.08264627307653427,
      0.017566528171300888,
      0.0016292281216010451,
      0.020266544073820114,
      -0.077755406498909,
      -0.03282054513692856,
      -0.12564779818058014,
      0.024758853018283844,
      0.06948218494653702,
      0.04001219943165779,
      0.008774938061833382,
      0.016064714640378952,
      -0.04891487956047058,
      -0.16360223293304443,
      0.06111885607242584,
      0.07425366342067719,
      0.010136773809790611,
      -0.047890547662973404,
      0.03655462712049484,
      0.029633361846208572,
      0.040009692311286926,
      0.059167563915252686,
      -0.028270939365029335,
      -0.026292098686099052,
      0.027402816340327263,
      -0.029751194640994072,
      0.03335399553179741,
      0.0169999822974205,
      0.021995827555656433,
      0.015296848490834236,
      0.008661585859954357,
      -0.017222536727786064,
      -0.00868946686387062,
      0.059994492679834366,
      0.011824185028672218,
      0.04996854439377785,
      -0.030944837257266045,
      -0.00858011282980442,
      -0.02996090240776539,
      -0.0058654858730733395,
      0.010678572580218315,
      0.13547778129577637,
      -0.015855710953474045,
      -0.07162930816411972,
      -0.040459584444761276,
      -0.027901794761419296,
      0.056324176490306854,
      -0.015096704475581646,
      -0.0008773666922934353,
      0.04284791648387909,
      -0.10771562159061432,
      0.08310411870479584,
      -0.053593821823596954,
      -0.041159577667713165,
      0.014449425041675568,
      -0.013338576070964336,
      -0.03538338467478752,
      -0.013259302824735641,
      -0.03992975875735283,
      -0.03618527948856354,
      0.0050407289527356625,
      -0.042429521679878235,
      5.02981711179018e-05,
      -0.048703890293836594,
      -0.06742443144321442,
      -0.04115314036607742,
      0.034924402832984924,
      0.053273364901542664,
      0.03875220939517021,
      0.07245396077632904,
      -0.03997017815709114,
      0.02410162426531315,
      0.035941410809755325,
      -0.007190090138465166,
      0.04744797945022583,
      0.08940698951482773,
      0.04199725762009621,
      0.0037111537531018257,
      -0.06253788620233536,
      -0.009486046619713306,
      0.04684256389737129,
      0.004618337377905846,
      -0.020832335576415062,
      -0.04060298576951027,
      -0.03284551203250885,
      0.007575279101729393,
      -0.0556364506483078,
      -0.016113121062517166,
      -0.06944660097360611,
      -0.026002373546361923,
      -0.012809201143682003,
      -0.0513569712638855,
      -0.008065947331488132,
      -0.018495140597224236,
      0.09090973436832428,
      -0.02920174039900303,
      0.007321542128920555,
      -0.040628381073474884,
      0.052245721220970154,
      0.05131423845887184,
      0.016607463359832764,
      0.10577230900526047,
      0.06634040921926498,
      0.05544328689575195,
      -0.08548952639102936,
      0.025294315069913864,
      -0.0296491589397192,
      -0.011752847582101822,
      0.012471950612962246,
      -0.053158942610025406,
      0.0027039512060582638,
      -0.04541435465216637,
      0.08898022770881653,
      0.008270182646811008,
      0.02351325750350952,
      0.05195731297135353,
      0.013291708193719387,
      -0.0735391154885292,
      -0.050316959619522095,
      0.008354668505489826,
      -0.0157318077981472,
      0.09627797454595566,
      0.04720044881105423,
      0.005601759999990463,
      0.028796033933758736,
      -0.017417654395103455,
      -0.05138498917222023,
      0.02656698413193226,
      0.03867059201002121,
      0.0383666530251503,
      0.0019003204070031643,
      0.09349028766155243,
      -0.06264365464448929,
      0.09686968475580215,
      0.01845189929008484,
      0.010624678805470467,
      0.027140872552990913,
      0.049847185611724854,
      -0.00836107786744833,
      -0.019366001710295677,
      -0.020361721515655518,
      -0.015170450322329998,
      -0.019009118899703026,
      0.04698562994599342,
      -0.026300979778170586,
      -0.01613161340355873,
      -0.03606962785124779,
      -0.019743548706173897,
      -0.04781158268451691,
      -0.009015229530632496,
      0.007916589267551899,
      0.0054128747433424,
      0.07322615385055542,
      0.059851277619600296,
      -0.03489133343100548,
      0.025787120684981346,
      0.02543938159942627,
      0.05373595654964447,
      -0.015428180806338787,
      -0.057771068066358566,
      -0.04240570217370987,
      -0.04941621795296669,
      0.010126412846148014,
      0.14319902658462524,
      -0.027444321662187576,
      -0.06683040410280228,
      0.015918642282485962
    ],
    [
      -0.09854603558778763,
      -0.033441442996263504,
      -0.023428866639733315,
      -0.15576450526714325,
      0.027725549414753914,
      -0.046497855335474014,
      -0.007895775139331818,
      0.005470826290547848,
      -0.07627632468938828,
      -0.022736070677638054,
      -0.006000220309942961,
      -0.11619186401367188,
      -0.0032677920535206795,
      -0.021182172000408173,
      -0.0029048179276287556,
      0.0050473762676119804,
      0.08966100215911865,
      0.0413556769490242,
      -0.04256441071629524,
      0.010271717794239521,
      0.013097140938043594,
      0.02548673003911972,
      0.018642889335751534,
      0.06200278550386429,
      -0.012595104984939098,
      0.07769586145877838,
      -3.078330337302759e-05,
      -0.006214360240846872,
      0.12187723815441132,
      0.057310231029987335,
      -0.08529508858919144,
      0.043657246977090836,
      0.07827988266944885,
      -0.012814455665647984,
      0.03099695034325123,
      -0.047168850898742676,
      -0.038958590477705,
      -0.0003039908187929541,
      -0.0075516244396567345,
      0.020785903558135033,
      -0.08438736945390701,
      0.0106655927374959,
      0.006644546519964933,
      -0.016866592690348625,
      -0.06971962004899979,
      0.05045991763472557,
      0.03236133232712746,
      0.02344694919884205,
      -0.05015198513865471,
      -0.05696599557995796,
      0.07455708831548691,
      -0.034847866743803024,
      0.07694759219884872,
      0.04568244516849518,
      0.021325061097741127,
      -0.005709768272936344,
      -0.05319453775882721,
      -0.016976960003376007,
      -0.029908305034041405,
      0.010410106740891933,
      -0.0837298333644867,
      0.035848286002874374,
      -0.05249404162168503,
      0.020028484985232353,
      -0.04600157216191292,
      0.07321694493293762,
      -0.062485937029123306,
      -0.01752551831305027,
      -0.03487394377589226,
      -0.05496158450841904,
      -0.01360805332660675,
      0.07018635421991348,
      -0.0022584155667573214,
      0.01269586756825447,
      0.029284700751304626,
      -0.011049388907849789,
      0.04046368971467018,
      0.017510445788502693,
      -0.052653297781944275,
      0.015056246891617775,
      0.011495133861899376,
      -0.04824267700314522,
      0.022848471999168396,
      -0.04166141897439957,
      -0.022820718586444855,
      0.05602964386343956,
      0.0071069286204874516,
      -0.013975163921713829,
      0.01342237088829279,
      -0.04648198187351227,
      -0.06547675281763077,
      -0.04968806728720665,
      -0.022914225235581398,
      -0.005901586730033159,
      0.030094198882579803,
      0.002761309966444969,
      0.009823177941143513,
      0.003776691621169448,
      -0.04084297642111778,
      -0.06880327314138412,
      0.10325286537408829,
      -0.03954904153943062,
      -0.06502850353717804,
      0.032229650765657425,
      -0.041207876056432724,
      0.04517282173037529,
      -0.10513696819543839,
      -0.005421893671154976,
      -0.024935482069849968,
      -0.01950814388692379,
      0.08050994575023651,
      0.042769741266965866,
      -0.004873234778642654,
      -0.01543273776769638,
      -0.020016402006149292,
      -0.04882132634520531,
      0.02223588526248932,
      -0.041233520954847336,
      0.009318900294601917,
      -0.011676765978336334,
      0.12567253410816193,
      -0.004267284646630287,
      -0.017828695476055145,
      -0.00402016332373023,
      -0.03143436089158058,
      -0.008119005709886551,
      0.06120133772492409,
      0.041563067585229874,
      -0.059373948723077774,
      0.0029755025170743465,
      -0.05132206156849861,
      -0.01175368670374155,
      -0.03753609210252762,
      -0.02797875367105007,
      -0.11826933920383453,
      0.025627462193369865,
      0.03361184895038605,
      -0.050956226885318756,
      0.019875211641192436,
      0.006663782522082329,
      -0.06748530268669128,
      0.004711944609880447,
      -0.00790654681622982,
      0.05102134495973587,
      0.05535039305686951,
      0.044992417097091675,
      0.0002933930663857609,
      -0.04539225250482559,
      0.08958400040864944,
      -0.03513864427804947,
      0.022011741995811462,
      0.0065452842973172665,
      -0.009219245985150337,
      -0.010290962643921375,
      -0.0001763881737133488,
      -0.017466727644205093,
      0.11313570290803909,
      0.07704232633113861,
      -0.022046435624361038,
      -0.0019164307741448283,
      0.003732826327905059,
      0.057189732789993286,
      0.10534396022558212,
      -0.071273073554039,
      0.016796516254544258,
      0.07178500294685364,
      -0.005325464066118002,
      -0.024071114137768745,
      0.013655243441462517,
      0.04289252683520317,
      -0.08446437120437622,
      -0.012671408243477345,
      -0.01712719351053238,
      -0.025268156081438065,
      0.006889232899993658,
      0.035604022443294525,
      -0.016290908679366112,
      -0.014434890821576118,
      0.007793954573571682,
      0.040103815495967865,
      0.0012978066224604845,
      0.024162573739886284,
      -0.006805448792874813,
      0.05607089027762413,
      0.0018782850820571184,
      0.07479222863912582,
      -0.10070452094078064,
      0.009624863974750042,
      0.005290711764246225,
      0.01992802508175373,
      0.031284086406230927,
      -0.06431643664836884,
      0.015738360583782196,
      -0.04180717095732689,
      -0.024290146306157112,
      0.009133335202932358,
      -0.0075183711014688015,
      0.01971830055117607,
      -0.02622971311211586,
      -0.008301268331706524,
      -0.05426918715238571,
      -0.04754919186234474,
      0.010686853900551796,
      0.06166697293519974,
      0.03195831924676895,
      0.020624468103051186,
      0.06529607623815536,
      -0.006648160982877016,
      -0.009390722028911114,
      0.03382256254553795,
      0.02340095303952694,
      -0.02408801019191742,
      0.03774556890130043,
      0.052652183920145035,
      0.08031824976205826,
      0.02018355205655098,
      -0.07736217975616455,
      0.001318784081377089,
      -0.03651297092437744,
      0.004994345363229513,
      -0.08381279557943344,
      -0.022578533738851547,
      0.0019705977756530046,
      0.04578227549791336,
      0.05333709344267845,
      -0.028461510315537453,
      -0.02486945502460003,
      -0.032694362103939056,
      -0.04348530247807503,
      -0.020017385482788086,
      0.0571894571185112,
      0.021329201757907867,
      0.009947792626917362,
      -0.0884065255522728,
      -0.056578513234853745,
      -0.050550591200590134,
      -0.005567032843828201,
      0.05541343614459038,
      0.03991810604929924,
      -0.006708452943712473,
      -0.03744720667600632,
      -0.06810291856527328,
      -0.0382324755191803,
      -0.015233817510306835,
      0.030251385644078255,
      -0.02045220322906971,
      0.06578311324119568,
      0.04408755898475647,
      -0.08096776902675629,
      0.02115507423877716,
      0.028172679245471954,
      -0.01747809536755085,
      -0.008224966935813427,
      0.04128262773156166,
      -0.14505434036254883,
      -0.08589901775121689,
      -0.07017815113067627,
      -0.08361826092004776,
      0.06962050497531891,
      0.09963713586330414,
      0.03268302604556084,
      -0.01670779474079609,
      -0.015964286401867867,
      0.008720596320927143,
      -0.07248338311910629,
      -0.07169461995363235,
      0.01684815064072609,
      0.024884458631277084,
      -0.11800506711006165,
      0.01668054424226284,
      -0.04441902041435242,
      0.022439926862716675,
      -0.022101404145359993,
      0.02016730047762394,
      0.049736618995666504,
      -0.06483249366283417,
      0.12827707827091217,
      0.016506096348166466,
      0.02387327514588833,
      0.004741821903735399,
      0.06551508605480194,
      -0.014187611639499664,
      -0.013389034196734428,
      0.04259175807237625,
      -0.09337372332811356,
      -0.05643772333860397,
      0.026462893933057785,
      0.028367115184664726,
      -0.03657930716872215,
      0.0033139572478830814,
      0.0014311147388070822,
      -0.0544605478644371,
      -0.001963516930118203,
      -0.10542584955692291,
      0.03900626301765442,
      0.034874413162469864,
      -0.03302740678191185,
      -0.010342428460717201,
      -0.042620204389095306,
      0.04122273623943329,
      -0.05196014419198036,
      -0.08202947676181793,
      0.06943854689598083,
      0.006313144229352474,
      -0.0035712015815079212,
      -0.003909443039447069,
      0.022054551169276237,
      0.02029959112405777,
      -0.0785897970199585,
      0.031550221145153046,
      -0.06113165616989136,
      0.005621683783829212,
      -0.04480147734284401,
      -0.0580468475818634,
      -0.008109737187623978,
      -0.008467833511531353,
      0.05420577526092529,
      -0.01270841620862484,
      -0.01710236258804798,
      0.07447874546051025,
      0.06016600877046585,
      0.0129085136577487,
      -0.024501463398337364,
      -0.00252173887565732,
      -0.04176997020840645,
      -0.027904421091079712,
      0.006095091346651316,
      -0.034941159188747406,
      0.10201618820428848,
      0.011415745131671429,
      0.015931211411952972,
      0.06029393523931503,
      -0.021615244448184967,
      0.021838108077645302,
      -0.06373186409473419,
      -0.055827945470809937,
      0.06324899196624756,
      -0.011963739059865475,
      0.09521837532520294,
      -0.04336369037628174,
      -0.06439581513404846,
      0.0434705913066864,
      0.06428055465221405,
      0.02666459232568741,
      -0.01945388875901699,
      -0.0038172160275280476,
      0.043464723974466324,
      -0.018338728696107864,
      -0.013269590213894844,
      0.025094693526625633,
      -0.037559956312179565,
      0.014442584477365017,
      0.043622374534606934,
      -0.08097415417432785,
      -0.0015758825466036797,
      -0.01430861372500658,
      0.14959856867790222,
      0.04350907355546951,
      -0.02271305024623871,
      -0.03498886153101921,
      0.03491770848631859,
      0.07008378207683563,
      0.06990155577659607,
      0.028732405975461006,
      -0.057476505637168884,
      0.027790755033493042,
      -0.05616525560617447,
      -0.008829358033835888,
      0.09056999534368515,
      0.026340201497077942,
      -0.05490990728139877,
      0.04041187837719917,
      -0.033834658563137054,
      -0.11705098301172256,
      -0.03144458681344986,
      0.0575733557343483,
      0.01701442152261734,
      0.055491797626018524,
      -0.00529663497582078,
      0.017800606787204742,
      -0.03491222485899925,
      -0.06935767829418182,
      -0.043007925152778625,
      -0.046969328075647354,
      -0.02675054781138897,
      0.04240432381629944,
      -0.026774782687425613,
      0.02739546075463295,
      0.018561115488409996,
      0.043892744928598404,
      0.07243417203426361,
      0.05558016151189804,
      -0.03600001335144043,
      -0.02734411135315895,
      -0.012684154324233532,
      -0.053278665989637375,
      0.03513967990875244,
      -0.12901772558689117,
      0.03741795942187309,
      0.03964076563715935,
      -0.012918400578200817,
      -0.03965474292635918,
      -0.05348672345280647,
      0.03828434646129608,
      -0.010081863030791283,
      0.004423323553055525,
      0.0606435090303421,
      0.06904426217079163,
      0.12557928264141083,
      -0.05337478220462799,
      -0.022801995277404785,
      0.022963540628552437,
      0.012038002721965313,
      0.03613264113664627,
      -0.03760707005858421,
      -0.009116161614656448,
      -0.011993572115898132,
      0.027108939364552498,
      0.03537016734480858,
      0.01672796905040741,
      -0.012700838036835194,
      -0.023012086749076843,
      -0.037360504269599915,
      -0.07796331495046616,
      -0.008077534846961498,
      0.004145787097513676,
      -0.046843256801366806,
      -0.001335122506134212,
      -0.01991519145667553,
      -0.02434946410357952,
      -0.0060141244903206825,
      0.0009547207155264914,
      -0.01947277970612049,
      0.018321722745895386,
      -0.030169690027832985,
      -0.02880411595106125,
      0.028544072061777115,
      0.032949648797512054,
      0.03571813553571701,
      -0.0731116309762001,
      -0.09348846971988678,
      0.000775705324485898,
      0.05439312383532524,
      -0.06020648404955864,
      0.004007402807474136,
      0.00980748888105154,
      -0.007628769148141146,
      -0.0066188606433570385,
      -0.0619647242128849,
      -0.03340872749686241,
      -0.02638791874051094,
      -0.09825734049081802,
      -0.07497294247150421,
      -0.024610955268144608,
      -0.02703876607120037,
      -0.021509302780032158,
      0.012377976439893246,
      0.051572784781455994,
      -0.05564115568995476,
      -0.01699279062449932,
      0.0731506273150444,
      -0.002768400125205517,
      0.06072946637868881,
      -0.015596303157508373,
      -0.04985208436846733,
      -0.011454119347035885,
      -0.003103037364780903,
      0.019426574930548668,
      0.06738891452550888,
      0.04826822876930237,
      -0.08163782209157944,
      -0.007593132089823484,
      -0.002145624253898859,
      -0.0066108484752476215,
      0.02939673513174057,
      0.06651971489191055,
      -0.014536833390593529,
      0.04470083490014076,
      0.03141982480883598,
      0.019570307806134224,
      0.0742039605975151,
      0.006775653921067715,
      0.05681336671113968,
      0.01313523668795824,
      0.07550850510597229,
      0.031257741153240204,
      0.04208067059516907,
      -0.06976117938756943,
      -0.026883253827691078,
      -0.043589383363723755,
      0.0006490971427410841,
      0.04497447982430458,
      0.04701409861445427,
      -0.06273388117551804,
      0.06573759019374847,
      -0.021771620959043503,
      0.002494387561455369,
      -0.06492799520492554,
      -0.039522331207990646,
      0.08763550966978073,
      0.038886647671461105,
      0.002762117423117161,
      -0.006418843287974596
    ],
    [
      -0.04356967285275459,
      -0.08011576533317566,
      -0.0538489855825901,
      -0.06940838694572449,
      0.0787673220038414,
      0.08244087547063828,
      0.010045318864285946,
      0.020958349108695984,
      0.01503850519657135,
      0.08786772936582565,
      0.009251589886844158,
      -0.01650848239660263,
      -0.06965015083551407,
      0.03291986882686615,
      0.008910495787858963,
      0.04218238219618797,
      0.03591122850775719,
      -0.03760071471333504,
      0.0888471007347107,
      -0.018301283940672874,
      0.030307181179523468,
      0.05538923293352127,
      -0.012073496356606483,
      0.06203916668891907,
      -0.025671135634183884,
      0.052278783172369,
      -0.031697120517492294,
      -0.00020586159371305257,
      -0.04276962950825691,
      0.060509540140628815,
      0.012447618879377842,
      -0.052575137466192245,
      0.03129785507917404,
      0.10051608830690384,
      0.016329454258084297,
      0.013162340968847275,
      -0.004442287143319845,
      0.0342443585395813,
      -0.009777097031474113,
      0.004494872875511646,
      0.050988804548978806,
      0.04608887806534767,
      -0.043152280151844025,
      0.001175047131255269,
      0.012562783434987068,
      0.04270501434803009,
      0.06126103922724724,
      -0.002840602770447731,
      -0.030751537531614304,
      0.030906153842806816,
      0.05782097205519676,
      -0.033663295209407806,
      0.033175986260175705,
      -0.02878056839108467,
      0.009554502554237843,
      0.06273039430379868,
      -0.13891826570034027,
      0.007075041066855192,
      -0.0512506328523159,
      0.03269423171877861,
      -0.039835602045059204,
      0.08935999125242233,
      -0.03355639427900314,
      0.0017712295521050692,
      0.04081990197300911,
      -0.019388984888792038,
      0.02056567184627056,
      0.031948257237672806,
      0.008541147224605083,
      -0.0031445713248103857,
      0.024164453148841858,
      -0.035743486136198044,
      -0.03523460775613785,
      0.029831482097506523,
      0.04267376288771629,
      -0.09958086907863617,
      -0.04364847391843796,
      0.028796792030334473,
      -0.0009310191380791366,
      -0.014600477181375027,
      -0.08007185161113739,
      0.053164560347795486,
      0.018462931737303734,
      0.020319925621151924,
      -0.00843843724578619,
      0.03402383252978325,
      0.04588894918560982,
      0.01506790891289711,
      -0.10396786034107208,
      0.07810983806848526,
      -0.01596316136419773,
      -0.03213009610772133,
      0.06288795918226242,
      0.034653279930353165,
      -0.0302218459546566,
      0.006833925377577543,
      -0.04327886924147606,
      0.04121775925159454,
      0.06274612993001938,
      0.018422961235046387,
      0.015875423327088356,
      -0.026796182617545128,
      -0.0985378548502922,
      0.04387207701802254,
      0.058587852865457535,
      -0.011676597408950329,
      0.07342471927404404,
      -0.002225482603535056,
      0.005518265534192324,
      0.04518750309944153,
      0.005694215651601553,
      0.06997252255678177,
      0.06655588746070862,
      0.007061985321342945,
      0.08221611380577087,
      -0.0794626921415329,
      -0.004471140913665295,
      0.005142310634255409,
      0.06765709817409515,
      0.03762682527303696,
      0.03739777207374573,
      -0.12821123003959656,
      -0.02459801733493805,
      0.05521944910287857,
      -0.05199616402387619,
      -0.0066000958904623985,
      0.03659972548484802,
      -0.03364447504281998,
      -0.031737200915813446,
      -0.055075109004974365,
      -0.0580347441136837,
      -0.03708105906844139,
      0.017087046056985855,
      0.007969789206981659,
      -0.07380067557096481,
      -0.022605018690228462,
      -0.02129962481558323,
      -0.022983800619840622,
      -0.04112527146935463,
      -0.00035490066511556506,
      -0.021797459572553635,
      0.006706420797854662,
      -0.055203843861818314,
      -0.023397747427225113,
      -0.006650748196989298,
      0.01717717945575714,
      -0.02627735026180744,
      0.009097568690776825,
      0.055099114775657654,
      0.036203425377607346,
      0.04024837911128998,
      0.0034381397999823093,
      0.003535825526341796,
      0.001919145928695798,
      0.08621615171432495,
      0.03497650474309921,
      0.0129586486145854,
      0.0004361255851108581,
      -0.015102141536772251,
      0.02646687813103199,
      0.03764556348323822,
      -0.01699313335120678,
      0.027662884443998337,
      0.025115350261330605,
      -0.011450623162090778,
      -0.0577763170003891,
      0.027268100529909134,
      -0.03408801928162575,
      -0.0105628352612257,
      0.046619269996881485,
      0.0431956872344017,
      -0.020794739946722984,
      0.030580686405301094,
      -0.017042549327015877,
      0.010524949058890343,
      -0.01624671183526516,
      -0.0035637258552014828,
      -0.04241601377725601,
      0.0072532519698143005,
      0.11039812862873077,
      0.02228274941444397,
      0.008714951574802399,
      -0.0007574706105515361,
      0.011629213578999043,
      0.010057497769594193,
      0.04876246303319931,
      -0.02480524778366089,
      -0.0016801926540210843,
      0.04202995449304581,
      0.0051641021855175495,
      -0.029883909970521927,
      0.001838042982853949,
      0.03214753791689873,
      0.013706851750612259,
      -0.03600207343697548,
      -0.03437981382012367,
      0.04681027680635452,
      -0.0016096833860501647,
      0.013964304700493813,
      -0.07395726442337036,
      0.00614395784214139,
      -0.03785954415798187,
      -0.06110436096787453,
      0.05995291471481323,
      -0.12140271812677383,
      0.034764692187309265,
      -0.02124948240816593,
      -0.08549713343381882,
      0.09406726807355881,
      0.0017587154870852828,
      0.09466410428285599,
      -0.08500363677740097,
      0.045647040009498596,
      0.03263409435749054,
      0.006811420898884535,
      -0.020560573786497116,
      -0.00956139899790287,
      0.003046076511964202,
      -0.0660412609577179,
      0.021189147606492043,
      0.029910553246736526,
      -0.071720190346241,
      0.0836370512843132,
      0.042725853621959686,
      0.0032688176725059748,
      0.036172207444906235,
      -0.005299294367432594,
      0.0012627504765987396,
      0.06997014582157135,
      0.015391978435218334,
      0.07982020080089569,
      -0.0040528541430830956,
      -0.011944277212023735,
      0.05888481065630913,
      0.023742087185382843,
      0.06465782225131989,
      -0.04914946109056473,
      0.04486709088087082,
      0.029211876913905144,
      0.047508999705314636,
      -0.06232529506087303,
      0.021338893100619316,
      0.05626591667532921,
      0.052352819591760635,
      -0.016459347680211067,
      -0.028646517544984818,
      0.007555442862212658,
      0.07943392544984818,
      0.061389464884996414,
      -0.046925775706768036,
      0.12741346657276154,
      0.005418364889919758,
      -0.000816625077277422,
      -0.08261264860630035,
      0.051287394016981125,
      0.02797948569059372,
      -0.05499203875660896,
      -0.061053600162267685,
      -0.017759883776307106,
      -0.024507777765393257,
      -0.0026577464304864407,
      0.008058284409344196,
      0.01715111918747425,
      0.020254507660865784,
      0.005308929830789566,
      0.05917631834745407,
      -0.016779158264398575,
      -0.028920108452439308,
      -0.017189376056194305,
      0.012342569418251514,
      -0.0017539708642289042,
      -0.02062882110476494,
      0.0022244411520659924,
      -0.04414338991045952,
      0.007426895666867495,
      0.00801607221364975,
      0.0341888889670372,
      -0.10004246979951859,
      0.03384780511260033,
      0.02670379914343357,
      -0.06497738510370255,
      0.05505342781543732,
      -0.017604753375053406,
      0.018315954133868217,
      0.051004212349653244,
      -0.06694051623344421,
      0.008089669980108738,
      0.0225681159645319,
      0.0616520456969738,
      0.07204192131757736,
      0.07123889029026031,
      -0.0030515657272189856,
      0.06950340420007706,
      -0.021911567077040672,
      -0.013071805238723755,
      -0.021556319668889046,
      -0.03717873990535736,
      -0.06736994534730911,
      -0.02808121033012867,
      0.02274186536669731,
      -0.016209477558732033,
      -0.016748128458857536,
      -0.006211317144334316,
      0.002486491110175848,
      0.046688906848430634,
      -0.1043991968035698,
      -0.035362329334020615,
      -0.08184769749641418,
      -0.05830759182572365,
      0.026191454380750656,
      0.004143142607063055,
      0.01338549330830574,
      0.020228415727615356,
      -0.02938082069158554,
      0.016886496916413307,
      -0.023336274549365044,
      0.0043762605637311935,
      0.012003601528704166,
      -0.07894787192344666,
      -0.014579540118575096,
      0.06283309310674667,
      -0.00036533412639983,
      0.03509380295872688,
      -0.030141741037368774,
      -0.029307357966899872,
      0.02539709210395813,
      -0.07377281039953232,
      0.04545174166560173,
      0.07952260226011276,
      -0.003972342237830162,
      0.0038322799373418093,
      0.0448085181415081,
      0.01084069348871708,
      -0.005041678436100483,
      -0.0344739593565464,
      -0.02441580407321453,
      0.008677043952047825,
      -0.0018406538292765617,
      0.004474852699786425,
      0.022993527352809906,
      0.039648473262786865,
      -0.03149992972612381,
      -0.04992726072669029,
      -0.11402978003025055,
      0.06155262887477875,
      -0.04816700890660286,
      0.044181305915117264,
      -0.08191345632076263,
      -0.007808191701769829,
      0.0038104199338704348,
      0.10955406725406647,
      -0.05684671923518181,
      -0.06902962177991867,
      0.015209878794848919,
      -0.0772842988371849,
      0.01709182746708393,
      0.021984519436955452,
      0.03712814301252365,
      -0.038185153156518936,
      0.043686121702194214,
      -0.006033763755112886,
      0.06844460964202881,
      0.030287794768810272,
      0.03037201799452305,
      -0.05952836200594902,
      0.010868548415601254,
      -0.037073224782943726,
      0.021392159163951874,
      0.057360902428627014,
      0.05172789841890335,
      0.05345828831195831,
      -0.040653638541698456,
      0.06878423690795898,
      -0.051667824387550354,
      0.05255894362926483,
      -0.013859986327588558,
      -0.030928488820791245,
      0.02319706417620182,
      0.03896401450037956,
      -0.033627256751060486,
      0.01183335017412901,
      0.006062389351427555,
      -0.08169003576040268,
      -0.03936580568552017,
      -0.04957938194274902,
      0.026536813005805016,
      0.016203604638576508,
      -0.0976528525352478,
      -0.008970856666564941,
      -0.015163151547312737,
      0.005019440781325102,
      -0.011107495054602623,
      0.046194516122341156,
      -0.05905642360448837,
      -0.04797370359301567,
      -0.021735910326242447,
      0.019636401906609535,
      -0.0277999360114336,
      -0.034853026270866394,
      -0.03278902918100357,
      0.0048040952533483505,
      -0.0037988810800015926,
      -0.04990142583847046,
      -0.051828738301992416,
      -0.01905635930597782,
      -0.08512021601200104,
      0.025521818548440933,
      -0.063148133456707,
      -0.010684511624276638,
      -0.06185334548354149,
      0.007136196829378605,
      0.004664663225412369,
      0.023208877071738243,
      -0.07424422353506088,
      -0.03179481625556946,
      -0.06588634103536606,
      0.06314786523580551,
      -0.02432263456285,
      0.02391272969543934,
      0.026552405208349228,
      -0.1249140202999115,
      -0.06086203083395958,
      0.052439846098423004,
      -0.018547892570495605,
      0.009556914679706097,
      -0.09099365025758743,
      -0.00013041911006439477,
      -0.07865938544273376,
      -0.0014327330281957984,
      0.024191519245505333,
      0.07005628943443298,
      -0.022097721695899963,
      -0.0007127279532141984,
      -0.018489502370357513,
      0.0005516029195860028,
      -0.020732255652546883,
      -0.001166212372481823,
      0.06558685004711151,
      -0.025873854756355286,
      -0.07501567900180817,
      0.0011000125668942928,
      -0.05467698723077774,
      -0.036225397139787674,
      -0.029170773923397064,
      0.0630611851811409,
      -0.036195676773786545,
      0.06806949526071548,
      -0.037164222449064255,
      0.04112178832292557,
      0.038892701268196106,
      0.02700841799378395,
      -0.004337662365287542,
      -0.0665750652551651,
      0.05638756603002548,
      0.05241607874631882,
      -0.002970070345327258,
      0.0741090178489685,
      0.05210181325674057,
      0.04764090105891228,
      0.013596945442259312,
      0.08258809894323349,
      -0.0006865091854706407,
      0.06779403984546661,
      0.016313359141349792,
      -0.033122722059488297,
      0.01692371629178524,
      0.032456498593091965,
      0.045889850705862045,
      0.07389573007822037,
      -0.013828907161951065,
      0.022888358682394028,
      -0.046109363436698914,
      -0.056628644466400146,
      -0.09277401864528656,
      -0.03402203693985939,
      -0.03554876893758774,
      -0.07485519349575043,
      -0.005810788366943598,
      0.050499413162469864,
      0.008254525251686573,
      -0.008598924614489079,
      0.026596013456583023,
      0.04153526946902275,
      -0.11542259156703949,
      0.015355197712779045,
      -0.0464816614985466,
      -0.08620882779359818,
      -0.02874627150595188,
      0.023836499080061913,
      0.06703966856002808,
      0.007054162677377462,
      -0.014497910626232624,
      -0.021548626944422722,
      -0.04138735309243202,
      0.02850378304719925,
      -0.004248659126460552,
      -0.0161313246935606,
      -0.031297843903303146,
      0.0034448581282049417,
      -0.007102698087692261,
      -0.0009668479906395078,
      0.07530275732278824,
      0.029245981946587563
    ],
    [
      0.017777808010578156,
      0.04128248244524002,
      0.027456389740109444,
      0.01490834541618824,
      0.028597790747880936,
      0.003862986573949456,
      0.05389416962862015,
      -0.03296900913119316,
      0.03066134825348854,
      0.003876433242112398,
      -0.016079431399703026,
      -0.024103371426463127,
      -0.031009888276457787,
      0.09441827982664108,
      -0.0077268583700060844,
      -0.038575347512960434,
      0.03234431520104408,
      0.0031912645790725946,
      0.010372238233685493,
      0.12700143456459045,
      0.05301336571574211,
      0.0214532483369112,
      0.043336544185876846,
      -0.0036252066493034363,
      -0.08079010993242264,
      0.053081613034009933,
      0.018307121470570564,
      -0.021703096106648445,
      -0.01528919953852892,
      0.018870417028665543,
      0.04095877334475517,
      -0.02018291875720024,
      -0.03194699436426163,
      0.034174639731645584,
      -0.021342286840081215,
      0.0036243160720914602,
      -0.029429467394948006,
      0.017766036093235016,
      -0.1276039183139801,
      0.027680566534399986,
      -0.043165914714336395,
      -0.01674576662480831,
      0.01854485645890236,
      0.022054523229599,
      0.0030405386351048946,
      -0.04798433557152748,
      0.01699567213654518,
      0.0009192782454192638,
      0.007911855354905128,
      0.009397863410413265,
      -0.03333468735218048,
      -0.016306933015584946,
      0.03798479959368706,
      0.04677468165755272,
      0.008787774480879307,
      -0.02111104317009449,
      0.006248583551496267,
      -0.03002585470676422,
      0.005547407548874617,
      -0.01952027715742588,
      0.04443536326289177,
      -0.04162955284118652,
      0.0782356932759285,
      0.0020733997225761414,
      -0.032690271735191345,
      -0.002377220429480076,
      -0.02230260893702507,
      0.0846475139260292,
      -0.00142397521995008,
      -0.012440524064004421,
      0.031905047595500946,
      0.019769584760069847,
      0.05503583699464798,
      0.05861504748463631,
      0.010656953789293766,
      0.04163822904229164,
      -0.020383980125188828,
      0.03247906267642975,
      0.004000436514616013,
      -0.05611245706677437,
      0.050821006298065186,
      0.06063029170036316,
      0.03769305348396301,
      0.04642246663570404,
      -0.0511191226541996,
      -0.0023884237743914127,
      0.07886030524969101,
      -0.029908912256360054,
      0.005697942338883877,
      0.03135518357157707,
      -0.013001949526369572,
      -0.0422782301902771,
      0.08142002671957016,
      0.07116159796714783,
      0.020831560716032982,
      -0.05013730004429817,
      -0.012060916051268578,
      0.014418543316423893,
      0.0004780643794219941,
      -0.05539283528923988,
      0.08528710156679153,
      0.03852666914463043,
      -0.0021323536057025194,
      0.013173192739486694,
      0.056919164955616,
      0.005777309648692608,
      0.11339885741472244,
      0.03506065532565117,
      0.08161509037017822,
      -0.017990635707974434,
      -0.038605351001024246,
      0.0006261051166802645,
      -0.00264350650832057,
      0.007865437306463718,
      -0.07362299412488937,
      0.043309956789016724,
      0.009895711205899715,
      -0.016561970114707947,
      -0.03254716470837593,
      0.015271015465259552,
      -0.03619256243109703,
      -0.03130772337317467,
      0.0410955511033535,
      0.018050890415906906,
      -0.023338010534644127,
      -0.04645446315407753,
      -0.0006983703933656216,
      0.025659529492259026,
      0.022631734609603882,
      -0.03207695856690407,
      -0.0753801241517067,
      0.046557582914829254,
      0.006004038732498884,
      -0.025719832628965378,
      -0.025367608293890953,
      -0.0269129928201437,
      0.005509269889444113,
      -0.04077496379613876,
      0.08219374716281891,
      0.013940638862550259,
      -0.0474884957075119,
      -0.044611070305109024,
      -0.047247372567653656,
      -0.034035589545965195,
      0.016244705766439438,
      -0.019183795899152756,
      0.008257417008280754,
      -0.03278113529086113,
      -0.06040116399526596,
      0.011764999479055405,
      0.023743383586406708,
      -0.05058736726641655,
      0.0075618550181388855,
      -0.06044024974107742,
      -0.03943843021988869,
      -0.04238387942314148,
      0.009144647046923637,
      -0.04222404211759567,
      -0.08082292228937149,
      0.006901708897203207,
      -0.02800297923386097,
      0.07578542828559875,
      0.015465185046195984,
      -0.060021188110113144,
      -0.006310302764177322,
      0.07330487668514252,
      -0.005222872830927372,
      -0.05893882364034653,
      0.050219811499118805,
      0.03418659418821335,
      0.013085035607218742,
      0.08990903198719025,
      0.06780122965574265,
      -0.042666178196668625,
      -0.001670688739977777,
      -0.019588222727179527,
      -0.02747730165719986,
      -0.04944310709834099,
      0.009331402368843555,
      -0.07124841958284378,
      0.03560406342148781,
      0.019636167213320732,
      0.04152059182524681,
      -0.004765104968100786,
      0.05024491995573044,
      -0.0793674886226654,
      0.0024250363931059837,
      0.00071543431840837,
      -0.10050295293331146,
      -0.028588123619556427,
      0.025355948135256767,
      0.00986793264746666,
      -0.03052280843257904,
      0.027674246579408646,
      0.06864422559738159,
      -0.017156554386019707,
      0.005942684598267078,
      0.06066351383924484,
      -0.008239413611590862,
      -0.03725485876202583,
      -0.025752350687980652,
      0.0043302010744810104,
      0.06647395342588425,
      -0.01528790220618248,
      -0.04337271675467491,
      0.015104539692401886,
      0.06783100962638855,
      0.02682563103735447,
      -0.036459870636463165,
      -0.05425945296883583,
      -0.016048315912485123,
      -0.01664385013282299,
      0.06325288116931915,
      -0.03269974887371063,
      -0.0016001983312889934,
      0.01642458885908127,
      -0.020911378785967827,
      -0.06601043790578842,
      -0.09833236038684845,
      0.0395696796476841,
      -0.021571293473243713,
      -0.07874120026826859,
      0.002678713295608759,
      0.008554770611226559,
      0.04775509238243103,
      0.005590770393610001,
      0.06648373603820801,
      -0.05721775069832802,
      -0.0026767272502183914,
      0.026820631697773933,
      -0.04751997068524361,
      -0.00686180405318737,
      0.03360646590590477,
      -0.015655504539608955,
      0.06391727179288864,
      -0.0691809430718422,
      -0.06310509145259857,
      -0.0022963681258261204,
      0.05985900014638901,
      0.06206432357430458,
      0.07486648857593536,
      -0.03715600073337555,
      -0.011731620877981186,
      -0.03476336598396301,
      -0.0663815587759018,
      0.01705392636358738,
      -0.01855730637907982,
      0.10294321924448013,
      -0.023171909153461456,
      -0.0334518663585186,
      -0.03152387961745262,
      0.014852798543870449,
      0.015666117891669273,
      0.002441314747557044,
      -1.9844841517624445e-05,
      -0.037159763276576996,
      -0.037984639406204224,
      0.128603994846344,
      0.05325331911444664,
      0.020175639539957047,
      -0.10190334916114807,
      -0.02896052412688732,
      0.001273644738830626,
      0.08403345942497253,
      0.019970793277025223,
      -0.0007646644371561706,
      -0.09046920388936996,
      -0.059854455292224884,
      -0.07552402466535568,
      -0.04146983101963997,
      -0.04911230504512787,
      -0.005518751684576273,
      -0.0067840320989489555,
      0.05342329666018486,
      0.06531056761741638,
      -0.012988721020519733,
      -0.006361035630106926,
      0.011375106871128082,
      0.021997667849063873,
      -0.06388892233371735,
      -0.009284465573728085,
      0.014133572578430176,
      -0.011711372062563896,
      -0.026703570038080215,
      -0.06362922489643097,
      -0.008075988851487637,
      0.05166412144899368,
      0.04100687801837921,
      -0.007544299121946096,
      0.0028336404357105494,
      0.01921069622039795,
      -0.1296042650938034,
      -0.027998873963952065,
      0.040812812745571136,
      -0.030399315059185028,
      0.03617217019200325,
      -0.02094644121825695,
      -0.1304321587085724,
      -0.03045477718114853,
      0.01638028956949711,
      0.010223215445876122,
      0.05718124657869339,
      0.015639198943972588,
      -0.10103105753660202,
      0.0007621688419021666,
      -0.010001133196055889,
      0.0033194778952747583,
      0.05653990060091019,
      0.0051068272441625595,
      -0.034992244094610214,
      -0.027135342359542847,
      -0.04331441596150398,
      0.0009326279978267848,
      -0.0028746118769049644,
      0.0069343894720077515,
      0.03678389638662338,
      0.03281014785170555,
      0.028110476210713387,
      0.027826426550745964,
      0.02342291921377182,
      0.09043034166097641,
      0.007197734899818897,
      -0.001572481240145862,
      -0.043309297412633896,
      -0.003426159964874387,
      0.04143336042761803,
      0.03814997524023056,
      0.054950471967458725,
      0.054881006479263306,
      0.0488230399787426,
      0.005380196496844292,
      0.05111571401357651,
      0.010831060819327831,
      0.005833487492054701,
      -0.05015196651220322,
      0.0031181275844573975,
      0.03610263764858246,
      0.018543532118201256,
      0.04735100641846657,
      -0.015049534849822521,
      -0.07082484662532806,
      -0.06233619153499603,
      -0.02617340348660946,
      0.08326755464076996,
      0.031282346695661545,
      0.10490976274013519,
      0.0014305936638265848,
      0.07503031194210052,
      -0.12818846106529236,
      -0.01516936905682087,
      -0.051950737833976746,
      -0.00448928726837039,
      0.05690127611160278,
      -0.07248712331056595,
      0.029612058773636818,
      0.03083547204732895,
      -0.0183727890253067,
      0.004191775806248188,
      -0.04861500859260559,
      0.03480914235115051,
      0.06676264852285385,
      0.0478215217590332,
      -0.01305477786809206,
      0.02966247871518135,
      -0.008518840186297894,
      0.01327318325638771,
      0.017526697367429733,
      -0.04803071171045303,
      0.01568596437573433,
      0.014526665210723877,
      -0.08943585306406021,
      0.059988465160131454,
      0.015272020362317562,
      0.04730166122317314,
      -0.0039012099150568247,
      0.08073478192090988,
      0.06041235849261284,
      0.031167786568403244,
      0.008006359450519085,
      -0.014443943277001381,
      0.014043554663658142,
      -0.007450198754668236,
      0.026263250038027763,
      -0.07870626449584961,
      -0.003405416151508689,
      0.036211300641298294,
      0.01851954124867916,
      -0.034823402762413025,
      -0.006026763003319502,
      -0.06809494644403458,
      -0.0657232254743576,
      0.012278065085411072,
      0.026260096579790115,
      0.007985331118106842,
      0.02933586575090885,
      -0.07572612911462784,
      0.011308214627206326,
      -0.09123392403125763,
      -0.015286125242710114,
      -0.0907723531126976,
      -0.02410225197672844,
      -0.04988684132695198,
      -0.06970936805009842,
      -0.0699215680360794,
      0.012187520042061806,
      0.012278047390282154,
      0.028471751138567924,
      0.004174528643488884,
      0.041238296777009964,
      0.04218880087137222,
      0.012528881430625916,
      0.009497336111962795,
      -0.03225509077310562,
      -0.037810832262039185,
      0.002213957253843546,
      0.0039550322107970715,
      0.07578121870756149,
      -0.03458685800433159,
      -0.04738425835967064,
      -0.009306123480200768,
      -0.02126253768801689,
      -0.046333424746990204,
      -0.05757974833250046,
      -0.028312519192695618,
      0.058219730854034424,
      -0.005879594944417477,
      0.00121019477955997,
      -0.0011808410054072738,
      0.05559081956744194,
      0.07120012491941452,
      -0.04505787417292595,
      -0.04776826128363609,
      0.027567626908421516,
      -0.03671032935380936,
      0.07091307640075684,
      -0.08244600147008896,
      -0.012247398495674133,
      0.054765485227108,
      -0.03021812066435814,
      0.04901263117790222,
      0.07068607956171036,
      -0.07405708730220795,
      0.03475058823823929,
      -0.009140076115727425,
      -0.0010310504585504532,
      -0.07615248113870621,
      -0.052897680550813675,
      0.04491537809371948,
      0.06322923302650452,
      0.015426862984895706,
      0.028409073129296303,
      0.01344452053308487,
      -0.01163541804999113,
      -0.07242191582918167,
      0.013969550840556622,
      -0.0757342129945755,
      0.008573406375944614,
      0.014405028894543648,
      0.030480537563562393,
      0.015669550746679306,
      -0.0005425615236163139,
      -0.06238241493701935,
      -0.010686931200325489,
      0.017516350373625755,
      -0.050564151257276535,
      0.05690452456474304,
      -0.0649162232875824,
      -0.04316240921616554,
      0.03956924006342888,
      0.00117399450391531,
      0.07989189028739929,
      0.09950311481952667,
      -0.05596334487199783,
      -0.008896860294044018,
      -0.01564352586865425,
      -0.0463012270629406,
      0.009866151958703995,
      -0.04286356270313263,
      -0.051318228244781494,
      -0.011164546012878418,
      0.0007570198504254222,
      0.009414475411176682,
      0.050601013004779816,
      0.012112781405448914,
      0.06436993926763535,
      -0.07489709556102753,
      -0.06682726740837097,
      -0.07838903367519379,
      -0.11802191287279129,
      -0.007484101690351963,
      -0.07202215492725372,
      0.02274611033499241,
      -0.039331626147031784,
      -0.043961942195892334,
      -0.07362852245569229,
      -0.015351726673543453,
      0.015000876039266586,
      -0.0534890778362751,
      -0.0373343862593174,
      -0.04102151095867157,
      0.03988458216190338,
      -0.003071447368711233,
      0.15561048686504364
    ],
    [
      0.02122434973716736,
      0.009486867114901543,
      -0.022147301584482193,
      -0.038532860577106476,
      0.11372016370296478,
      0.033811070024967194,
      0.01950598508119583,
      -5.157409032108262e-05,
      0.010332592763006687,
      0.08172977715730667,
      0.05416524037718773,
      0.009712887927889824,
      0.08368179947137833,
      0.04080578312277794,
      0.05227804183959961,
      -0.00505891302600503,
      -0.05167986452579498,
      -0.05092393979430199,
      -0.009231584146618843,
      0.07659975439310074,
      -0.005845209117978811,
      -0.006107169669121504,
      0.07691418379545212,
      -0.013365080580115318,
      -0.03984261304140091,
      0.04148128256201744,
      0.043791335076093674,
      0.06080682948231697,
      0.0220774058252573,
      0.056080639362335205,
      0.01798427104949951,
      0.003359464928507805,
      -0.10580740123987198,
      -0.02862999029457569,
      0.02121341973543167,
      0.058587271720170975,
      -0.03541775792837143,
      0.04719189181923866,
      0.024095144122838974,
      -0.0525403693318367,
      -0.057181838899850845,
      0.007955691777169704,
      0.053009092807769775,
      -0.006333258002996445,
      -0.011541415937244892,
      0.02875908464193344,
      -0.010358472354710102,
      0.01775258779525757,
      -0.04921815171837807,
      0.03391779214143753,
      -0.06508824974298477,
      -0.06293807923793793,
      -0.03705022111535072,
      -0.07414097338914871,
      0.0457666851580143,
      -0.008365252055227757,
      -0.010926737450063229,
      -0.06076144799590111,
      0.010410888120532036,
      -0.06514792144298553,
      0.026476457715034485,
      -0.016407165676355362,
      -0.037327174097299576,
      0.00891882460564375,
      -0.04699287936091423,
      -0.0010375312995165586,
      0.026326516643166542,
      -0.03126000240445137,
      0.006267006509006023,
      -0.005926570389419794,
      -0.07835876196622849,
      0.0007601062534376979,
      0.08248249441385269,
      0.019331181421875954,
      -0.011372145265340805,
      -0.03530028834939003,
      -0.03856975957751274,
      0.059247810393571854,
      0.06202135980129242,
      -0.02596096880733967,
      0.030936602503061295,
      0.04831273481249809,
      -0.0487808994948864,
      0.007057661190629005,
      -0.05382850393652916,
      0.03549785166978836,
      0.027131034061312675,
      -0.03734683245420456,
      0.03048967383801937,
      -0.025657115504145622,
      -0.06762675940990448,
      0.008798359893262386,
      -0.066301628947258,
      0.005952209234237671,
      0.012151166796684265,
      0.03326839953660965,
      0.025097329169511795,
      0.019416894763708115,
      -0.025411397218704224,
      0.033692654222249985,
      0.040854379534721375,
      0.0444917157292366,
      -0.04936135560274124,
      -0.04107386991381645,
      0.021364403888583183,
      0.031564146280288696,
      -0.08648140728473663,
      0.10529661178588867,
      -0.021622788161039352,
      -0.042869359254837036,
      0.003827654756605625,
      0.050466977059841156,
      0.035940445959568024,
      -0.07961113750934601,
      0.03979835286736488,
      -0.0059310318902134895,
      -0.029664797708392143,
      0.0010909991106018424,
      0.06767618656158447,
      -0.08327499777078629,
      0.08817696571350098,
      -0.048300568014383316,
      -0.005862497258931398,
      0.010101217776536942,
      0.02619614265859127,
      -0.04249582067131996,
      -0.019241299480199814,
      0.08920557796955109,
      -0.0015641230856999755,
      -0.040439948439598083,
      0.016154590994119644,
      0.06085332855582237,
      0.00841402355581522,
      0.05342009291052818,
      0.0280279703438282,
      0.07433979958295822,
      -0.13545078039169312,
      -0.05053777992725372,
      -0.09040442109107971,
      0.0082294512540102,
      0.016989557072520256,
      0.02537783980369568,
      -0.03340357914566994,
      -0.036136507987976074,
      0.024642521515488625,
      0.046910759061574936,
      -0.045376554131507874,
      -0.015016425400972366,
      0.04563485458493233,
      0.08196830749511719,
      0.06986430287361145,
      0.09942516684532166,
      0.009610454551875591,
      -0.07120628654956818,
      -0.018396927043795586,
      -0.04862586408853531,
      -0.012781629338860512,
      -0.02385924570262432,
      -0.04434194415807724,
      0.04890555143356323,
      -0.06490796059370041,
      -0.01857065036892891,
      -0.011611676774919033,
      -0.03719190135598183,
      0.01569778472185135,
      -0.07731802761554718,
      -0.023892827332019806,
      0.09274701774120331,
      0.013401500880718231,
      -0.012687502428889275,
      -0.007004196289926767,
      -0.004759937524795532,
      0.07724357396364212,
      -0.011302330531179905,
      -0.07312903553247452,
      0.034201402217149734,
      0.027442002668976784,
      0.008354329504072666,
      0.006889707874506712,
      0.045116785913705826,
      0.009610280394554138,
      0.07115674018859863,
      -0.062267154455184937,
      -0.04781104251742363,
      -0.01982557401061058,
      -0.060584720224142075,
      0.04644547775387764,
      0.007031932007521391,
      -0.04287456348538399,
      -0.03662566840648651,
      0.07745880633592606,
      0.007259472273290157,
      0.07101693004369736,
      0.01739761233329773,
      -0.03724904730916023,
      0.031661368906497955,
      0.04021429270505905,
      -0.03178682178258896,
      -0.06289384514093399,
      -0.014652105048298836,
      -0.002691851928830147,
      -0.01970088854432106,
      -8.040133252507076e-05,
      -0.03849231079220772,
      -0.021256443113088608,
      -0.0851992666721344,
      0.02925318479537964,
      0.0031615637708455324,
      0.0547785721719265,
      0.011720455251634121,
      0.00773581862449646,
      0.045261211693286896,
      0.024696655571460724,
      -0.02173103578388691,
      0.027761472389101982,
      0.004601098131388426,
      0.1018005982041359,
      -0.04526921361684799,
      -0.045568883419036865,
      0.004611090291291475,
      -0.02053857035934925,
      0.003118057968094945,
      0.0646037757396698,
      0.007185520604252815,
      0.0430985689163208,
      -0.025165317580103874,
      -0.0708456039428711,
      -0.014371905475854874,
      -0.019359882920980453,
      -0.00945354625582695,
      0.08924728631973267,
      0.022049322724342346,
      0.013905308209359646,
      -0.021448874846100807,
      0.04224865511059761,
      0.009733381681144238,
      0.06776505708694458,
      -0.047489672899246216,
      0.06820335984230042,
      -0.0283883735537529,
      -0.057148486375808716,
      -0.033233143389225006,
      -0.01999995857477188,
      0.04780289903283119,
      -0.04091697558760643,
      0.001028669299557805,
      -0.10396333038806915,
      0.03503287211060524,
      0.01524808257818222,
      0.0026718927547335625,
      0.0077752163633704185,
      -0.042582541704177856,
      0.03859326243400574,
      0.017466770485043526,
      -0.0506267175078392,
      0.06374941021203995,
      -0.06730366498231888,
      -0.06408586353063583,
      0.026493988931179047,
      0.005786570254713297,
      -0.023029008880257607,
      -0.06482774764299393,
      -0.01972576044499874,
      -0.003250830341130495,
      -0.012405996210873127,
      0.014058965258300304,
      -5.108332516101655e-06,
      0.05732385441660881,
      -0.003595120972022414,
      -0.02919836714863777,
      0.08186543732881546,
      0.06118287891149521,
      -0.02343347854912281,
      0.0042916047386825085,
      -0.0865304172039032,
      -0.06413725763559341,
      0.03925468027591705,
      0.029890509322285652,
      0.016544722020626068,
      -0.05613287538290024,
      -0.06969419121742249,
      0.04722783714532852,
      0.029177172109484673,
      0.0011319252662360668,
      0.01571488380432129,
      -0.09071682393550873,
      -0.050213772803545,
      -0.034763872623443604,
      -0.014234434813261032,
      -0.03900126367807388,
      -0.0452137291431427,
      -0.05421190708875656,
      0.011299663223326206,
      0.07511865347623825,
      -0.0631810799241066,
      0.03991053253412247,
      -0.06990580260753632,
      0.05445004999637604,
      0.0772671177983284,
      0.013938245363533497,
      -0.012411549687385559,
      0.06468644738197327,
      -0.016023309901356697,
      0.05231470614671707,
      -0.06110430508852005,
      -0.03266996890306473,
      0.029353056102991104,
      -0.011489525437355042,
      -0.10651462525129318,
      0.07467343658208847,
      -0.014435878954827785,
      0.036707282066345215,
      -0.032756175845861435,
      0.007662752643227577,
      0.023052234202623367,
      -0.04405716434121132,
      -0.022976500913500786,
      0.004828228615224361,
      0.03271811455488205,
      0.021427609026432037,
      -0.0028477355372160673,
      0.026451045647263527,
      0.004002411849796772,
      -0.009421167895197868,
      -0.05233769118785858,
      -0.08390965312719345,
      -0.008692746981978416,
      0.042031753808259964,
      -0.024165131151676178,
      -0.03601008281111717,
      -0.043776482343673706,
      -0.01667443849146366,
      -0.03180099278688431,
      0.01421776507049799,
      -0.07347631454467773,
      -0.0038193154614418745,
      -0.06820345669984818,
      0.04624849930405617,
      0.010310848243534565,
      -0.0377059169113636,
      -0.09085720777511597,
      -0.020545074716210365,
      0.0357968732714653,
      -0.05467512458562851,
      0.048275772482156754,
      0.047785595059394836,
      0.08399682492017746,
      -0.032206907868385315,
      0.02988673560321331,
      0.02264661341905594,
      0.009545057080686092,
      -0.0024290469009429216,
      0.040613945573568344,
      -0.11762042343616486,
      0.09416761994361877,
      -0.024466130882501602,
      -0.004668710753321648,
      0.0770440623164177,
      0.028461534529924393,
      -0.012234975583851337,
      -0.024786729365587234,
      -0.09208480268716812,
      0.00437561608850956,
      0.04146820679306984,
      -0.004372093826532364,
      -0.012482739053666592,
      -0.006534787360578775,
      -0.03082045540213585,
      0.05751389265060425,
      -0.06652273237705231,
      0.013427289202809334,
      0.04392106086015701,
      0.05706851929426193,
      0.023593375459313393,
      0.004921079613268375,
      0.015203348360955715,
      0.01394304446876049,
      0.02716120518743992,
      0.00949782319366932,
      -0.024765368551015854,
      -0.11243300884962082,
      -0.05074829235672951,
      -0.04129801318049431,
      -0.0746953934431076,
      0.02013161964714527,
      0.013931590132415295,
      -0.06433871388435364,
      0.09254249930381775,
      0.010082986205816269,
      0.013255102559924126,
      0.001784909050911665,
      0.019210033118724823,
      0.08875709772109985,
      -0.015611785463988781,
      0.07037492096424103,
      0.04287242889404297,
      0.015428575687110424,
      -0.007120629772543907,
      0.013100657612085342,
      -0.03290414437651634,
      -0.00844209548085928,
      -0.04047561436891556,
      -0.010962222702801228,
      0.011929012835025787,
      -0.0032053892500698566,
      0.08400409668684006,
      0.005457633174955845,
      -0.036837682127952576,
      -0.09516262263059616,
      0.050401926040649414,
      -0.009756459854543209,
      0.07410812377929688,
      0.006400213111191988,
      0.010530739091336727,
      0.13543514907360077,
      0.06090035289525986,
      0.014312333427369595,
      -0.00352261564694345,
      0.08014452457427979,
      0.11441987007856369,
      0.012546918354928493,
      -0.025734713301062584,
      0.029123010113835335,
      0.04681084677577019,
      -0.03421306237578392,
      0.012463103979825974,
      -0.03870387375354767,
      0.0031904021743685007,
      0.00937830563634634,
      -0.05182833969593048,
      -0.011151038110256195,
      -0.018867848441004753,
      0.06600502878427505,
      0.03233166038990021,
      -0.010224414058029652,
      0.06585486233234406,
      0.023789647966623306,
      -0.08147859573364258,
      0.007772450800985098,
      -0.013465809635818005,
      0.05489933490753174,
      -0.05375341698527336,
      -0.07433467358350754,
      0.017056317999958992,
      -0.03861451521515846,
      -0.028262078762054443,
      0.0694328248500824,
      0.043705519288778305,
      0.03897329792380333,
      -0.014004367403686047,
      -0.03847959637641907,
      -0.03746762126684189,
      -0.033205147832632065,
      -0.0021262147929519415,
      0.05953378602862358,
      0.04579857736825943,
      0.066889189183712,
      0.10351476818323135,
      -0.02142137847840786,
      -0.00024794114870019257,
      -0.016776716336607933,
      -0.013764522969722748,
      0.023172948509454727,
      0.07193674147129059,
      -0.08131808787584305,
      -0.00016784922627266496,
      -0.04680294170975685,
      0.04027121514081955,
      -0.04669661074876785,
      -0.044156454503536224,
      0.05257473140954971,
      -0.009640444070100784,
      -0.00413033040240407,
      -0.0030283776577562094,
      0.0014187254710122943,
      0.032231006771326065,
      0.018224872648715973,
      0.04743630811572075,
      0.005374392494559288,
      0.019946865737438202,
      0.05437581241130829,
      -0.028646159917116165,
      -0.06644789129495621,
      0.008725762367248535,
      0.02743583917617798,
      0.0073461635038256645,
      0.02651096135377884,
      -0.03158307075500488,
      -0.0582888200879097,
      -0.04010815918445587,
      0.01457128208130598,
      0.0085718659684062,
      -0.012575601227581501,
      -0.08502031862735748,
      0.06327255815267563,
      0.020421158522367477,
      -0.020305775105953217,
      -0.019137132912874222,
      -0.09316196292638779,
      0.03485746681690216,
      0.011874529533088207,
      0.02297661080956459,
      -0.012809723615646362
    ],
    [
      0.004677535500377417,
      -0.07333353906869888,
      0.03369016945362091,
      0.054598018527030945,
      0.129533052444458,
      0.026125963777303696,
      0.045126449316740036,
      -0.04761315882205963,
      0.00588111812248826,
      0.05589922145009041,
      -0.024705128744244576,
      -0.052298251539468765,
      -0.04539873078465462,
      0.07339992374181747,
      -0.06961090117692947,
      -0.020290104672312737,
      -0.034769922494888306,
      0.031122030690312386,
      -0.029340151697397232,
      0.003809134941548109,
      -0.0058371201157569885,
      0.05196619778871536,
      0.04053999483585358,
      -0.04853424057364464,
      0.016719482839107513,
      0.015379518270492554,
      0.01898353360593319,
      -0.07191924005746841,
      0.03650735691189766,
      -0.024156684055924416,
      -0.026071470230817795,
      -0.0185732189565897,
      -0.0025521337520331144,
      0.016051925718784332,
      0.032024871557950974,
      0.035479314625263214,
      0.056039247661828995,
      -0.011627385392785072,
      0.035078130662441254,
      -0.05671403929591179,
      0.11294551193714142,
      -0.0677909255027771,
      0.04690105468034744,
      0.0014916310319676995,
      0.100567527115345,
      0.034982532262802124,
      -0.04978968948125839,
      -0.01666693203151226,
      -0.029019581153988838,
      -0.06036173924803734,
      0.018572963774204254,
      -0.09311259537935257,
      -0.012984204106032848,
      -0.07188737392425537,
      -0.008754350244998932,
      -0.011754026636481285,
      -0.06754151731729507,
      -0.027941344305872917,
      -0.009551111608743668,
      -0.0239108856767416,
      0.05269907787442207,
      0.03718443214893341,
      0.02327827364206314,
      0.02558780647814274,
      -0.004658102989196777,
      0.02770058810710907,
      -0.009146098978817463,
      0.020168783143162727,
      0.05666814371943474,
      0.028498630970716476,
      0.058154597878456116,
      0.027494678273797035,
      0.04616105556488037,
      0.032215654850006104,
      0.03524552658200264,
      0.003880112897604704,
      -0.06992308050394058,
      -0.025792602449655533,
      0.028189722448587418,
      0.003917246591299772,
      -0.08920051902532578,
      0.043712496757507324,
      0.04829726368188858,
      0.005173165816813707,
      0.05369674414396286,
      0.051125332713127136,
      -0.017560819163918495,
      0.018766945227980614,
      0.0015605096705257893,
      -0.041078582406044006,
      0.05057227239012718,
      -0.07993675768375397,
      -0.07110927253961563,
      0.009173275902867317,
      -0.03147029131650925,
      0.013924278318881989,
      0.09186042100191116,
      0.040565647184848785,
      -0.025559941306710243,
      0.07772594690322876,
      0.024076854810118675,
      0.07065168023109436,
      -0.006526387762278318,
      0.0025132012087851763,
      -0.0807739868760109,
      0.019791457802057266,
      -0.05033990368247032,
      -0.06263279914855957,
      -0.018472658470273018,
      0.061401430517435074,
      -0.01545868068933487,
      0.05462343618273735,
      -0.044550128281116486,
      0.07697831094264984,
      -0.04299081861972809,
      0.04309846833348274,
      0.030945179983973503,
      0.028484055772423744,
      -0.03400852531194687,
      -0.053099408745765686,
      -0.04197969287633896,
      0.013160309754312038,
      -0.0657866895198822,
      0.10419842600822449,
      0.04315018281340599,
      0.02953847497701645,
      0.013946427963674068,
      0.007606634870171547,
      -0.014580591581761837,
      0.013308079913258553,
      -0.009394751861691475,
      0.08225060254335403,
      -0.057696085423231125,
      0.054327450692653656,
      0.0011993651278316975,
      0.008998212404549122,
      -0.07096738368272781,
      -0.05785162374377251,
      3.3944201277336106e-05,
      0.04459419846534729,
      -0.000543345115147531,
      0.02629745565354824,
      -0.03148690238595009,
      -0.0191856287419796,
      -0.03892272710800171,
      -0.09664452075958252,
      0.02791377529501915,
      0.043427035212516785,
      -0.05065426975488663,
      -0.001770500559359789,
      -0.01800358109176159,
      -0.04647664353251457,
      -0.06778140366077423,
      0.06167628988623619,
      0.011685086414217949,
      -0.007583169732242823,
      -0.033378809690475464,
      -0.005933415610343218,
      -0.07719358056783676,
      0.0007621768163517118,
      -0.032955970615148544,
      -0.0013067384716123343,
      0.05597575381398201,
      0.00649625901132822,
      -0.06394534558057785,
      -0.008736483752727509,
      -0.051714714616537094,
      0.0008074570796452463,
      0.015067525207996368,
      -0.06505537033081055,
      -0.010628316551446915,
      0.02279551327228546,
      0.009274333715438843,
      -0.0639839768409729,
      0.0043004960753023624,
      -0.041164521127939224,
      -0.04332638159394264,
      -0.00426600594073534,
      -0.005978346336632967,
      0.014983155764639378,
      -0.007899543270468712,
      0.07438456267118454,
      -0.062416285276412964,
      -0.060228560119867325,
      -0.08145922422409058,
      -0.06083112955093384,
      0.030330125242471695,
      -0.07562138885259628,
      -0.097048819065094,
      -0.04226280376315117,
      0.026460101827979088,
      0.017083724960684776,
      -0.00896372552961111,
      -0.04415447637438774,
      0.07970429211854935,
      0.1396714150905609,
      -0.008238433860242367,
      -0.016025802120566368,
      -0.07540210336446762,
      -0.06030721962451935,
      -0.014879907481372356,
      -0.024595532566308975,
      -0.042717345058918,
      -0.06542032212018967,
      -0.0008095476077869534,
      -0.005067180842161179,
      -0.01240338571369648,
      0.08065745234489441,
      -0.01797955110669136,
      0.07201847434043884,
      0.032797280699014664,
      -0.0008258780580945313,
      0.08727378398180008,
      0.018816674128174782,
      -0.06816701591014862,
      -0.07318541407585144,
      0.06966722756624222,
      0.03650325909256935,
      -0.00795622356235981,
      0.01926126517355442,
      0.03302501514554024,
      0.05427655205130577,
      0.007583961356431246,
      0.044914666563272476,
      -0.027837632223963737,
      -0.056491922587156296,
      -0.019244428724050522,
      0.051063280552625656,
      0.0722661167383194,
      -0.07951529324054718,
      0.04944205284118652,
      -0.05426192283630371,
      0.04363580048084259,
      0.0825185626745224,
      0.012196644209325314,
      -0.007242260966449976,
      -0.056864164769649506,
      -0.025459324941039085,
      -0.04345397651195526,
      -0.01638794131577015,
      -0.0325208343565464,
      0.0014674831181764603,
      -0.013260000385344028,
      0.04975432902574539,
      0.006510206498205662,
      -0.07641527056694031,
      0.028470244258642197,
      0.06642115116119385,
      0.02986229583621025,
      -0.035225190222263336,
      -0.008369071409106255,
      -0.02061179094016552,
      0.028351806104183197,
      -0.02322196401655674,
      -0.04069843143224716,
      -0.06009313464164734,
      0.001114005339331925,
      -0.08123527467250824,
      -0.05358584597706795,
      -0.03933174908161163,
      0.05556518957018852,
      -0.07383999973535538,
      0.08024414628744125,
      -0.035906970500946045,
      0.05638130381703377,
      0.0034529417753219604,
      -0.0075219119898974895,
      -0.002254027407616377,
      0.036603160202503204,
      0.010253223590552807,
      0.05622956529259682,
      0.04111412912607193,
      -0.04739406704902649,
      -0.07200679928064346,
      0.011061561293900013,
      -0.03269350901246071,
      -0.02126597799360752,
      0.004976709373295307,
      0.042363498359918594,
      -0.01453210599720478,
      -0.03186264634132385,
      0.009774914011359215,
      0.05827925726771355,
      -0.0026931280735880136,
      0.02157176472246647,
      0.006492254324257374,
      -0.01412161998450756,
      0.13147827982902527,
      0.03191877529025078,
      0.016967900097370148,
      -0.00394577719271183,
      -0.0006228406564332545,
      -0.04987652599811554,
      -0.05450449138879776,
      0.08456975966691971,
      0.008379868231713772,
      -0.03745369240641594,
      -0.06712491065263748,
      0.011191604658961296,
      -0.008727176114916801,
      0.004779438488185406,
      0.00030637107556685805,
      -0.04478324577212334,
      0.00907045416533947,
      -0.022370003163814545,
      0.004806285258382559,
      -0.07144258171319962,
      0.10180141031742096,
      -0.08267223834991455,
      -0.04277745634317398,
      0.013118323870003223,
      -0.03132598102092743,
      0.017456388100981712,
      0.010645520873367786,
      0.08304660767316818,
      0.04605426266789436,
      0.016449322924017906,
      7.331766391871497e-05,
      -0.0698154866695404,
      0.01651388593018055,
      -0.016751477494835854,
      0.04506993293762207,
      -0.028463860973715782,
      0.03875264525413513,
      -0.06318365782499313,
      -0.0317235067486763,
      -0.028489084914326668,
      -0.08563987165689468,
      0.0002734200097620487,
      -0.08434630930423737,
      0.03623529151082039,
      -0.013117648661136627,
      -0.049490127712488174,
      -0.014771738089621067,
      0.022741908207535744,
      0.013382493518292904,
      0.10555823147296906,
      -0.02956589311361313,
      -0.06126263737678528,
      -0.0812692940235138,
      0.022697048261761665,
      0.01223518792539835,
      -0.09169691801071167,
      -0.02884160727262497,
      0.03698936477303505,
      -0.01126833911985159,
      -0.03381984680891037,
      -0.031036244705319405,
      0.06110464036464691,
      0.043632593005895615,
      -0.02335641346871853,
      -0.06067992001771927,
      -0.053101785480976105,
      0.009736922569572926,
      0.10338950157165527,
      0.056848932057619095,
      0.02085765451192856,
      0.025950932875275612,
      0.0057234326377511024,
      -0.04560689255595207,
      -0.062068428844213486,
      0.030516386032104492,
      -0.013316691853106022,
      0.024900024756789207,
      0.027709195390343666,
      0.005991367157548666,
      -0.009110908024013042,
      -0.005280754994601011,
      0.1036057248711586,
      0.03509540110826492,
      0.015285152941942215,
      -0.058986179530620575,
      0.12027998268604279,
      -0.10149243474006653,
      0.06969373673200607,
      -0.043788861483335495,
      -0.05620121955871582,
      0.0581962950527668,
      0.09742220491170883,
      0.015347890555858612,
      0.08318765461444855,
      0.06506013125181198,
      -0.08236420154571533,
      -0.027868660166859627,
      -0.13419044017791748,
      -0.05008254945278168,
      -0.0005417193751782179,
      -0.007512861862778664,
      0.07726564258337021,
      0.030073223635554314,
      -0.03833768144249916,
      -0.028832031413912773,
      -0.011228838935494423,
      -0.07372443377971649,
      0.006074516102671623,
      0.0317978635430336,
      0.004795524757355452,
      -0.016357237473130226,
      0.027270032092928886,
      -0.08642220497131348,
      -0.0539388582110405,
      -0.0017521781846880913,
      0.008517656475305557,
      -0.01372088585048914,
      0.023428907617926598,
      0.04914173111319542,
      -0.022890249267220497,
      -0.07038605213165283,
      0.013545028865337372,
      0.011031498201191425,
      -0.088051438331604,
      0.014185766689479351,
      -0.105438731610775,
      0.020085938274860382,
      -0.031485337764024734,
      -0.03660775348544121,
      -0.006453545298427343,
      0.032496485859155655,
      -0.023689324036240578,
      -0.1038597822189331,
      -0.07962073385715485,
      0.010982748121023178,
      0.007187652867287397,
      -0.029505761340260506,
      0.0009659819770604372,
      0.048890456557273865,
      -0.005823830142617226,
      0.02256673201918602,
      0.00942020770162344,
      0.00927156861871481,
      -0.007546170614659786,
      -0.050572581589221954,
      -0.03239449858665466,
      -0.03003685735166073,
      0.034845735877752304,
      -0.08386823534965515,
      -0.0041545480489730835,
      0.03983274847269058,
      0.010438378900289536,
      -0.0437411330640316,
      -0.10128601640462875,
      0.029705336317420006,
      0.05154179781675339,
      -0.04559985175728798,
      -0.06548503786325455,
      0.01679641380906105,
      -0.04426540061831474,
      0.03200724348425865,
      0.02448291890323162,
      -0.08005240559577942,
      -0.07527889311313629,
      0.004971191752701998,
      -0.06029040366411209,
      -0.048878226429224014,
      0.016661643981933594,
      -0.005821005441248417,
      0.00813279952853918,
      0.002495155669748783,
      0.059019580483436584,
      0.027216782793402672,
      0.0009151477715931833,
      -0.06110154464840889,
      -0.05186527222394943,
      -0.05934159457683563,
      -0.033864185214042664,
      -0.03639820218086243,
      -0.003873030189424753,
      0.0817231684923172,
      0.05446378514170647,
      -0.054054632782936096,
      -0.05803335830569267,
      -0.027007363736629486,
      -0.016744228079915047,
      0.028652405366301537,
      -0.05046418681740761,
      -0.022441284731030464,
      -0.056356582790613174,
      -0.042005471885204315,
      -0.00506082596257329,
      -0.024961870163679123,
      0.054585203528404236,
      -0.02052622102200985,
      -0.007500283885747194,
      -0.004753307905048132,
      -0.003128599841147661,
      -0.03631626442074776,
      -0.015765052288770676,
      -0.11491351574659348,
      0.059851761907339096,
      -0.038800425827503204,
      -0.050476670265197754,
      -0.03338246047496796,
      0.004049129784107208,
      0.06239135563373566,
      -0.008505948819220066,
      -0.058913473039865494,
      -0.03718011826276779,
      0.035012662410736084,
      -0.05104279890656471,
      -0.05471860617399216,
      0.0008208259823732078,
      -0.048096656799316406,
      0.00483133876696229
    ],
    [
      -0.03858836367726326,
      -0.04586140438914299,
      -0.055380914360284805,
      -0.04725383594632149,
      0.046039752662181854,
      -0.04784221947193146,
      0.049351803958415985,
      0.026902806013822556,
      0.0247364304959774,
      0.06638500839471817,
      -0.014629943296313286,
      0.032322440296411514,
      0.012316606007516384,
      -0.0988374873995781,
      0.02989659085869789,
      -0.012574275024235249,
      0.09185193479061127,
      -0.030839720740914345,
      -0.04007143899798393,
      0.012811337597668171,
      0.018014846369624138,
      0.03043605014681816,
      0.029062345623970032,
      0.035299498587846756,
      0.03385676443576813,
      0.011159203946590424,
      -0.08650213479995728,
      0.0353805236518383,
      0.03516123816370964,
      0.008708277717232704,
      -0.05726010724902153,
      -0.049269407987594604,
      -0.02564895898103714,
      0.05930542200803757,
      -0.039669182151556015,
      0.01568959467113018,
      -0.03877347707748413,
      0.015752138569951057,
      0.05934503301978111,
      -0.05480782687664032,
      -0.037667032331228256,
      -0.04204246774315834,
      0.04711582511663437,
      0.03970567137002945,
      -0.043599002063274384,
      0.029026295989751816,
      -0.08825459331274033,
      0.018657026812434196,
      -0.0006894342368468642,
      -0.042472850531339645,
      0.001983189955353737,
      -0.1067231297492981,
      0.014701072126626968,
      -0.06606215238571167,
      0.019625810906291008,
      0.05426349490880966,
      -0.10274108499288559,
      -0.08259216696023941,
      0.07064501196146011,
      -0.027160245925188065,
      -0.07452234625816345,
      0.04325953125953674,
      -0.058420468121767044,
      0.06758996844291687,
      -0.035824380815029144,
      0.036254074424505234,
      0.062134750187397,
      0.007370742037892342,
      -0.03926439210772514,
      -0.08029934018850327,
      -0.06633149087429047,
      -0.04920037090778351,
      0.007629408501088619,
      0.0024552405811846256,
      -0.014736798591911793,
      0.014186796732246876,
      0.005181360058486462,
      0.05379756540060043,
      -0.06622469425201416,
      -0.009559666737914085,
      0.08089336007833481,
      0.08194681257009506,
      -0.13789188861846924,
      -0.04284602403640747,
      -0.003085860749706626,
      -0.005876753479242325,
      -0.04349645599722862,
      -0.02706635184586048,
      0.011886158026754856,
      0.00505667133256793,
      -0.005766228307038546,
      -0.08608850836753845,
      -0.03620306774973869,
      -0.06095677241683006,
      -0.03794362396001816,
      -0.006342377047985792,
      -0.001287531340494752,
      -0.01797092892229557,
      -0.045743174850940704,
      -0.0296515841037035,
      -0.009639889933168888,
      0.0024458139669150114,
      -0.07842303812503815,
      -0.054955046623945236,
      0.05544864386320114,
      0.022854935377836227,
      -0.08428061753511429,
      0.05757052078843117,
      -0.07486598193645477,
      0.07780099660158157,
      0.01573321782052517,
      0.03571567311882973,
      0.04396621137857437,
      0.023856306448578835,
      -0.06652373820543289,
      -0.06837701052427292,
      -0.042998574674129486,
      -0.03903139382600784,
      -0.018275130540132523,
      -0.026510516181588173,
      -0.041067689657211304,
      0.017501048743724823,
      -0.07471195608377457,
      0.043082769960165024,
      0.0101785184815526,
      -0.07199189066886902,
      0.04355264827609062,
      0.058514051139354706,
      -0.08185984194278717,
      0.04882818087935448,
      0.03540704771876335,
      0.09085544943809509,
      -0.040088851004838943,
      0.018814489245414734,
      0.00037120000342838466,
      0.017216885462403297,
      -0.020470913499593735,
      0.05668126791715622,
      0.04885495826601982,
      0.04417688772082329,
      0.012256087735295296,
      0.03051968663930893,
      -0.022254252806305885,
      -0.05984306335449219,
      -0.09387888759374619,
      0.0001443918445147574,
      0.02420688606798649,
      -0.001330288127064705,
      0.10051103681325912,
      -0.006619162391871214,
      -0.05403747037053108,
      0.033860500901937485,
      0.020416345447301865,
      0.01594861038029194,
      -0.033199746161699295,
      0.020944613963365555,
      -0.10012335330247879,
      0.032898157835006714,
      -0.023629972711205482,
      0.0016934664454311132,
      0.03444089740514755,
      -0.024498604238033295,
      -0.04790099337697029,
      -0.029342280700802803,
      -0.053884029388427734,
      0.03675162047147751,
      0.07549632340669632,
      -0.03605017438530922,
      0.030902335420250893,
      0.004803108517080545,
      0.0003931870451197028,
      -0.0013369297375902534,
      -0.055994100868701935,
      0.00538116367533803,
      -0.05772142484784126,
      -0.0914420634508133,
      -0.05570772662758827,
      0.027545617893338203,
      -0.07530596107244492,
      -0.08252596110105515,
      -0.019643040373921394,
      0.014557492919266224,
      -0.052624545991420746,
      -0.06276977062225342,
      0.03143958002328873,
      -0.01321784034371376,
      0.03794890642166138,
      0.04704209417104721,
      -0.014053666964173317,
      0.015693293884396553,
      0.10929927229881287,
      -0.052668455988168716,
      0.004371115937829018,
      0.0043180822394788265,
      0.03814910724759102,
      0.010321934707462788,
      0.02874271757900715,
      0.022318292409181595,
      0.03555100038647652,
      -0.05291851982474327,
      0.01665063574910164,
      0.00125203188508749,
      0.010779714211821556,
      -0.05140109360218048,
      -0.014276595786213875,
      -0.038100793957710266,
      0.06516876816749573,
      0.04168885573744774,
      -0.03917406499385834,
      0.054903969168663025,
      0.031661100685596466,
      -0.05409365892410278,
      0.07178345322608948,
      -0.11688391119241714,
      -0.04014236852526665,
      -0.02480190619826317,
      0.017483830451965332,
      0.0366741344332695,
      -0.07423847168684006,
      0.021191248670220375,
      0.004304930102080107,
      0.013765987940132618,
      -0.05803535878658295,
      0.10731372982263565,
      0.006818562746047974,
      0.06539376825094223,
      0.019837573170661926,
      0.011229678988456726,
      -0.032367318868637085,
      0.019251665100455284,
      0.06523865461349487,
      -0.013308880850672722,
      0.03746703639626503,
      0.07624737173318863,
      -0.06777793914079666,
      0.04848390072584152,
      -0.003965717740356922,
      0.022449100390076637,
      -0.1117103174328804,
      0.009298286400735378,
      -0.042767371982336044,
      -0.030894706025719643,
      -0.04794018715620041,
      -0.057431191205978394,
      -0.11396368592977524,
      -0.000684817903675139,
      0.027308836579322815,
      0.058850981295108795,
      -0.027602288872003555,
      0.0007297683041542768,
      -0.014252864755690098,
      0.013033788651227951,
      0.0588604137301445,
      -0.055340833961963654,
      0.10136516392230988,
      -0.10458523780107498,
      0.005564242601394653,
      0.02142215333878994,
      0.05317211523652077,
      0.05718100816011429,
      -0.07196512073278427,
      0.023336421698331833,
      -0.029229281470179558,
      -0.005736587103456259,
      0.0377240814268589,
      -0.02127055451273918,
      0.004166095517575741,
      -0.009331947192549706,
      -0.02763506956398487,
      -0.021494902670383453,
      -0.022550275549292564,
      0.052825286984443665,
      -0.029968801885843277,
      -0.005974626634269953,
      -0.019312677904963493,
      -0.045091480016708374,
      0.038801174610853195,
      0.005387611221522093,
      0.05317744240164757,
      -0.012116126716136932,
      0.008884347975254059,
      0.07533234357833862,
      -0.013074424117803574,
      0.06540759652853012,
      -0.021651942282915115,
      0.017907848581671715,
      -0.028614724054932594,
      -0.010490340180695057,
      0.02184067852795124,
      0.07341759651899338,
      0.0436742939054966,
      0.02648278698325157,
      -0.0022719919215887785,
      0.01719728298485279,
      0.06861025840044022,
      0.006601245142519474,
      0.08145681023597717,
      0.08155421912670135,
      0.06717310845851898,
      -0.036650482565164566,
      -0.0057532149367034435,
      -0.01315952930599451,
      -0.03714999929070473,
      -0.013355604372918606,
      -0.10814998298883438,
      -0.033850282430648804,
      -0.03790794685482979,
      -0.02649848535656929,
      0.07469705492258072,
      0.045959848910570145,
      -0.03404711186885834,
      -0.05443098396062851,
      0.011149732396006584,
      0.00936479028314352,
      0.022625867277383804,
      0.023520775139331818,
      0.07183537632226944,
      0.026332879438996315,
      -0.0005819102516397834,
      0.01915307715535164,
      -0.04437711834907532,
      -0.06317632645368576,
      -0.015318864025175571,
      -0.03010462410748005,
      -0.016244441270828247,
      0.05124469846487045,
      -0.04411652684211731,
      0.030253449454903603,
      0.018869588151574135,
      0.07283146679401398,
      -0.07662173360586166,
      0.00018940547306556255,
      -0.047683242708444595,
      0.014664343558251858,
      -0.010602056048810482,
      -0.004105554427951574,
      0.05136272683739662,
      -0.025682780891656876,
      -0.02357937954366207,
      -0.026310648769140244,
      -0.08164060115814209,
      -0.055909376591444016,
      0.06595364212989807,
      -0.021227210760116577,
      0.03420447185635567,
      0.07127472758293152,
      -0.006468501407653093,
      0.039625637233257294,
      0.03776078298687935,
      0.014755668118596077,
      0.0413203202188015,
      0.027979165315628052,
      0.033325932919979095,
      -0.05164535716176033,
      -0.018881000578403473,
      0.028680484741926193,
      -0.06750179082155228,
      0.07188097387552261,
      0.021057073026895523,
      0.025293700397014618,
      0.06776431947946548,
      0.018458601087331772,
      -0.03239759802818298,
      -0.023652680218219757,
      0.02122006006538868,
      -0.014169973321259022,
      0.021641025319695473,
      -0.009494855999946594,
      -0.000570503412745893,
      -0.05185728147625923,
      -0.04176374524831772,
      -0.012922542169690132,
      0.037463512271642685,
      0.016148963943123817,
      -0.017120156437158585,
      -0.024478191509842873,
      0.03396355360746384,
      0.017969464883208275,
      -0.010682879947125912,
      -0.05705532431602478,
      0.035087741911411285,
      0.009335576556622982,
      -0.005535242613404989,
      -0.07102371007204056,
      -0.003860904835164547,
      -0.08229416608810425,
      -0.0067500099539756775,
      -0.0043494682759046555,
      -0.01032430212944746,
      0.02600816823542118,
      0.025086425244808197,
      0.01608852855861187,
      -0.05605297163128853,
      -0.0024884736631065607,
      0.040438275784254074,
      0.05056245997548103,
      0.05433548986911774,
      -0.0245487242937088,
      0.037932634353637695,
      -0.0024335754569619894,
      -0.002141787437722087,
      -0.08659101277589798,
      0.012278761714696884,
      -0.02691865712404251,
      0.02124369516968727,
      -0.026452913880348206,
      -0.02064710669219494,
      -0.0429629385471344,
      -0.06031883880496025,
      0.05306161567568779,
      0.014391922391951084,
      0.10670488327741623,
      0.008484691381454468,
      -0.010131162591278553,
      0.07105135917663574,
      0.04372290149331093,
      0.01140572689473629,
      -0.013485047034919262,
      0.007018581964075565,
      0.10424722731113434,
      -0.003881740616634488,
      -0.0729181244969368,
      -0.07592885941267014,
      -0.01830039918422699,
      -0.056602682918310165,
      0.06335320323705673,
      -0.0052556563168764114,
      -0.026120172813534737,
      0.07254946976900101,
      -0.018179303035140038,
      0.021526988595724106,
      -0.11474635452032089,
      0.12540797889232635,
      -0.03253884240984917,
      0.1319390833377838,
      -0.05632040649652481,
      -0.013373952358961105,
      0.025114797055721283,
      0.05511929839849472,
      -0.019236182793974876,
      0.026549864560365677,
      -0.026817919686436653,
      0.005073010455816984,
      -0.04635480046272278,
      -0.048447199165821075,
      -0.09107518941164017,
      0.0379740335047245,
      -0.08220132440328598,
      0.023809729143977165,
      -0.01287675742059946,
      -0.004830921068787575,
      0.03925067186355591,
      -0.0030015904922038317,
      0.05052630603313446,
      0.040835849940776825,
      -0.07146894931793213,
      -0.017201224341988564,
      0.074534110724926,
      -0.041150759905576706,
      0.031149107962846756,
      0.10361312329769135,
      -0.07270483672618866,
      0.0008525355951860547,
      0.047190506011247635,
      -0.09656304866075516,
      -0.030551854521036148,
      0.060244299471378326,
      0.06794415414333344,
      -0.019134992733597755,
      -0.06801529228687286,
      0.11345167458057404,
      0.006479892414063215,
      -0.009953595697879791,
      0.045565567910671234,
      -0.07962887734174728,
      0.04281671345233917,
      0.00039574431139044464,
      -0.02057408168911934,
      -0.139804869890213,
      -0.0023262740578502417,
      0.03526176139712334,
      0.05239824578166008,
      -0.014194645918905735,
      0.03164300695061684,
      -0.02493329346179962,
      -0.034292060881853104,
      -0.027820158749818802,
      -0.08650372922420502,
      -0.015968849882483482,
      -0.06718120723962784,
      -0.03852350637316704,
      0.06366141885519028,
      0.026652634143829346,
      -0.05401059612631798,
      0.04808376356959343,
      -0.009738193824887276,
      -0.025166841223835945,
      0.025000551715493202,
      -0.08798930048942566,
      -0.04744739085435867,
      0.012817254289984703,
      -0.027494946494698524,
      0.03165483474731445
    ],
    [
      -0.06148407608270645,
      -0.024954818189144135,
      -0.021264147013425827,
      -0.009107513353228569,
      -0.006223801989108324,
      0.009233634918928146,
      0.05264008790254593,
      0.036700960248708725,
      0.026644917204976082,
      -0.012872908264398575,
      -0.007746080867946148,
      -0.02642979845404625,
      -0.04563364014029503,
      -0.009044009260833263,
      0.04360414668917656,
      0.024760279804468155,
      -0.027674652636051178,
      -0.03680883347988129,
      -0.056487180292606354,
      -0.03555005043745041,
      -0.014268198050558567,
      -0.0365145206451416,
      0.0005139377899467945,
      0.01953912153840065,
      0.10674742609262466,
      0.005605943035334349,
      0.052195049822330475,
      -0.012335625477135181,
      0.02365025505423546,
      0.011843184940516949,
      -0.05483304336667061,
      0.022724270820617676,
      -0.025305533781647682,
      -0.05939244106411934,
      -0.06900050491094589,
      0.008111624978482723,
      -0.058749645948410034,
      -0.04308932647109032,
      0.05208468437194824,
      -0.02131868340075016,
      0.08666903525590897,
      0.025999274104833603,
      -0.043665047734975815,
      -0.013034405186772346,
      -0.07622994482517242,
      0.011519946157932281,
      -0.022889479994773865,
      0.02347636967897415,
      -0.0025989399291574955,
      -0.05156034976243973,
      -0.0007437528693117201,
      0.01434739027172327,
      0.042377769947052,
      -0.07367978990077972,
      0.10029183328151703,
      0.013646022416651249,
      -0.07159551978111267,
      0.0164888147264719,
      -0.018074549734592438,
      0.0014024178963154554,
      -0.005496799014508724,
      0.07337115705013275,
      0.03774823620915413,
      0.04838099330663681,
      0.06828135251998901,
      -0.04053669050335884,
      -0.06643864512443542,
      -0.05621930584311485,
      -0.02182580530643463,
      0.12766136229038239,
      0.028034158051013947,
      -0.01661926880478859,
      0.010854794643819332,
      -0.0016100290231406689,
      -0.006602354813367128,
      -0.009627997875213623,
      0.004547424148768187,
      0.03962008282542229,
      -0.008613558486104012,
      -0.09973879903554916,
      -0.04591292515397072,
      -0.058394286781549454,
      -0.022262737154960632,
      0.02436194382607937,
      -0.034399982541799545,
      -0.024078944697976112,
      -0.041195981204509735,
      0.030453993007540703,
      0.016273461282253265,
      0.07286341488361359,
      0.01388434786349535,
      -0.06649446487426758,
      0.035029176622629166,
      0.020776059478521347,
      0.005303241778165102,
      -0.034278977662324905,
      -0.01343767810612917,
      -0.0885910764336586,
      -0.04780490696430206,
      -0.0083301467821002,
      0.04503312334418297,
      0.03499283641576767,
      -0.061320532113313675,
      -0.005989398341625929,
      0.060675058513879776,
      -0.042705047875642776,
      -0.010954816825687885,
      -0.026287028566002846,
      0.04868399351835251,
      0.011602092534303665,
      -0.046436917036771774,
      -0.0036992402747273445,
      0.00048338473425246775,
      0.0611831396818161,
      -0.0036624332424253225,
      -0.009248485788702965,
      0.03794262558221817,
      -0.024688053876161575,
      0.05193484574556351,
      -0.06152758747339249,
      -0.0123073048889637,
      0.03113873116672039,
      -0.01928146928548813,
      -0.029738852754235268,
      -0.01712683029472828,
      0.011927193030714989,
      -0.053123801946640015,
      0.02911926805973053,
      0.0004924426320940256,
      -0.04795151576399803,
      0.021209649741649628,
      -0.013318325392901897,
      -0.037954580038785934,
      0.059298742562532425,
      -0.010543972253799438,
      -0.028727946802973747,
      -0.0016803251346573234,
      -0.044075559824705124,
      0.005620973650366068,
      0.020676307380199432,
      0.002522032707929611,
      0.038007620722055435,
      -0.07033465057611465,
      -0.0011284563224762678,
      -0.05783616751432419,
      -0.015310958959162235,
      -0.02086762897670269,
      0.018161799758672714,
      -0.04788118600845337,
      0.0147554287686944,
      -0.0479569137096405,
      -0.0320623442530632,
      0.014202017337083817,
      0.0034460469614714384,
      -0.023465601727366447,
      -0.019224485382437706,
      -0.06472022086381912,
      -0.025123801082372665,
      -0.013904440216720104,
      -0.008622259832918644,
      -0.00972074642777443,
      0.05396776273846626,
      -0.01853964477777481,
      -0.030564259737730026,
      -0.02057027816772461,
      0.04885753616690636,
      -0.0574166513979435,
      0.03549419343471527,
      0.013722877018153667,
      -0.03612016141414642,
      0.06555015593767166,
      -0.025727104395627975,
      -0.013654916547238827,
      0.0021993478294461966,
      -0.027840344235301018,
      -0.04344971477985382,
      0.03063814342021942,
      0.04610936716198921,
      0.01759372651576996,
      0.10110823065042496,
      0.011546855792403221,
      0.09879359602928162,
      -0.048970963805913925,
      -0.03097028099000454,
      0.09099806845188141,
      0.0024027382023632526,
      0.014157349243760109,
      0.04016093537211418,
      -0.04435783252120018,
      0.11571376770734787,
      0.005277909804135561,
      -0.00104100676253438,
      -0.05562269315123558,
      -0.06442303210496902,
      0.060998961329460144,
      0.026028970256447792,
      0.006374518387019634,
      0.07022811472415924,
      0.017554016783833504,
      -0.04865588992834091,
      -0.0644480511546135,
      -0.04304981231689453,
      0.03836026042699814,
      -0.0665980726480484,
      0.039694275707006454,
      -0.028355153277516365,
      -0.09170106798410416,
      0.04464798793196678,
      0.08652731031179428,
      0.03360236808657646,
      0.1305675208568573,
      0.03055080771446228,
      0.08519085496664047,
      0.017916442826390266,
      0.047411851584911346,
      0.04310034215450287,
      0.05068054795265198,
      -0.047106120735406876,
      -0.021139053627848625,
      -0.05976046994328499,
      0.06523974239826202,
      0.029352586716413498,
      -0.05970832705497742,
      0.0027721042279154062,
      -0.04236488416790962,
      0.055924005806446075,
      0.01609344221651554,
      0.04815635457634926,
      0.04466928914189339,
      -0.03206990659236908,
      -0.05461489409208298,
      0.023165537044405937,
      0.04638250544667244,
      0.0685545802116394,
      0.001336339977569878,
      0.09189758449792862,
      -0.05363998934626579,
      0.02294650301337242,
      0.07802783697843552,
      -0.03162781149148941,
      0.08887169510126114,
      0.02202395349740982,
      -0.03310062736272812,
      -0.03230338543653488,
      -0.041123781353235245,
      0.04976743087172508,
      -0.0506560318171978,
      0.06288409978151321,
      -0.002060484141111374,
      -0.05095702409744263,
      -0.03795551508665085,
      -0.005535953212529421,
      0.037835557013750076,
      -0.042417012155056,
      -0.029659880325198174,
      -0.0458931066095829,
      0.10890927165746689,
      -0.08147305995225906,
      -0.057979632169008255,
      -0.03402078151702881,
      -0.09297232329845428,
      -0.029413551092147827,
      0.061428677290678024,
      -0.061488814651966095,
      0.015112810768187046,
      -0.07067873328924179,
      0.025924181565642357,
      -0.015220957808196545,
      0.004364602733403444,
      -0.06950606405735016,
      -0.04988403618335724,
      0.09958960860967636,
      -0.02968185395002365,
      -0.021460093557834625,
      -0.029765423387289047,
      -0.03403124585747719,
      0.03335152938961983,
      0.07513454556465149,
      -0.03293628990650177,
      -0.015988919883966446,
      -0.03135308250784874,
      0.04688979685306549,
      0.03327745944261551,
      -0.06142030656337738,
      0.0377739742398262,
      0.01487359032034874,
      0.04719168320298195,
      0.047695472836494446,
      0.01788129284977913,
      -0.0830869972705841,
      0.03962279111146927,
      0.07231087237596512,
      -0.024193352088332176,
      0.01675521209836006,
      -0.026707377284765244,
      -0.06273936480283737,
      0.06569790840148926,
      -0.0003828828048426658,
      -0.0043160258792340755,
      0.027578171342611313,
      -0.03774295374751091,
      0.019882818683981895,
      -0.010605590417981148,
      -0.0020488486625254154,
      0.03217579051852226,
      0.03882908076047897,
      -0.029918227344751358,
      -0.13535349071025848,
      -0.08177024871110916,
      -0.013314823620021343,
      0.0984048843383789,
      -0.005816097836941481,
      -0.034344274550676346,
      -0.07389315217733383,
      0.026529507711529732,
      0.00725287152454257,
      0.0870639756321907,
      -0.016649072989821434,
      -0.0122210793197155,
      -0.1257459819316864,
      0.03211501985788345,
      0.04762750118970871,
      0.030581854283809662,
      -0.07232028245925903,
      0.0016677131643518806,
      0.0336775928735733,
      -0.005537147168070078,
      -0.08063686639070511,
      0.0052199093624949455,
      -0.031084571033716202,
      -0.1057409793138504,
      -0.0006070913514122367,
      0.003434983314946294,
      -0.01646661013364792,
      0.012419191189110279,
      -0.024289317429065704,
      -0.022793738171458244,
      -0.007720137946307659,
      -0.01820101961493492,
      -0.05897285416722298,
      0.0261578056961298,
      0.015062075108289719,
      -0.01614917255938053,
      -0.07921180874109268,
      -0.02339618280529976,
      0.035462066531181335,
      -0.019712897017598152,
      -0.022265274077653885,
      -0.07315021753311157,
      0.00922030583024025,
      0.01592971757054329,
      0.029641849920153618,
      -0.08760715276002884,
      -0.032342661172151566,
      -0.026327678933739662,
      0.007852044887840748,
      -0.008923440240323544,
      0.07365374267101288,
      0.04460570216178894,
      -0.006369409617036581,
      0.06868216395378113,
      -0.0290993619710207,
      0.020150141790509224,
      0.048112623393535614,
      -0.03179468959569931,
      -0.06298720091581345,
      0.00228687422350049,
      -0.04297402501106262,
      0.016075629740953445,
      0.005172740668058395,
      -0.12272688001394272,
      0.0072188307531178,
      0.016583474352955818,
      -0.028954753652215004,
      0.008557151071727276,
      0.08266646414995193,
      0.011028793640434742,
      0.012414194643497467,
      0.04195578768849373,
      0.03249174356460571,
      0.04202636703848839,
      0.05777806416153908,
      0.014600151218473911,
      0.013582040555775166,
      0.07602372020483017,
      0.022091714665293694,
      -0.005269831977784634,
      -0.04381515830755234,
      0.018015660345554352,
      0.001781289465725422,
      -0.04486636817455292,
      0.03161969780921936,
      -0.040081944316625595,
      -0.044761959463357925,
      0.07519248127937317,
      -0.022301865741610527,
      0.08535652607679367,
      -0.07493187487125397,
      -0.0633830726146698,
      -0.016538124531507492,
      0.0003069390368182212,
      -0.005466180387884378,
      -0.11501210182905197,
      -0.03339548408985138,
      -0.04917963221669197,
      0.02862013690173626,
      0.0429336354136467,
      0.03987235575914383,
      -0.0050204601138830185,
      0.01862424425780773,
      0.03633112460374832,
      -0.007222255226224661,
      0.041388578712940216,
      -0.04992431029677391,
      -0.08199381828308105,
      0.005643370095640421,
      -0.0558764822781086,
      0.005559234414249659,
      0.12273205816745758,
      0.01918978802859783,
      -0.02623886428773403,
      -0.020205829292535782,
      -0.056972309947013855,
      0.019485153257846832,
      0.008388563059270382,
      0.03715894743800163,
      -0.02240210585296154,
      -0.018368711695075035,
      0.015033948235213757,
      -0.03335496783256531,
      -0.025897420942783356,
      -0.07474767416715622,
      0.04154699668288231,
      0.019489943981170654,
      -0.009062034077942371,
      -0.038996655493974686,
      0.060137420892715454,
      -0.03089076466858387,
      -0.0001651401980780065,
      0.06286249309778214,
      -0.011948981322348118,
      0.01653224043548107,
      0.006522312294691801,
      -0.07553207129240036,
      -0.04159702733159065,
      -0.0908123329281807,
      0.028445981442928314,
      0.01405200082808733,
      -0.02256818488240242,
      -0.04413028061389923,
      -0.06864026933908463,
      -0.017865678295493126,
      0.01608060486614704,
      -0.014657305553555489,
      0.007329053245484829,
      -0.03615298867225647,
      0.0020360478665679693,
      0.031703267246484756,
      0.0018839832628145814,
      -0.020637132227420807,
      0.1066342294216156,
      -0.060641344636678696,
      -0.07557731866836548,
      0.031218543648719788,
      -0.1025039479136467,
      0.04030442237854004,
      0.05279584974050522,
      0.0014752475544810295,
      -0.02096644975244999,
      0.0027193359564989805,
      -0.050157371908426285,
      -0.030124006792902946,
      0.018320592120289803,
      0.01913730800151825,
      0.023595210164785385,
      -0.02680172398686409,
      0.013718321919441223,
      0.02632630616426468,
      0.040075745433568954,
      0.03223811089992523,
      0.14026372134685516,
      0.04390404373407364,
      -0.0233036819845438,
      0.019551709294319153,
      0.03473883494734764,
      0.027012407779693604,
      0.011835207231342793,
      -0.04235038906335831,
      -0.06643383949995041,
      -0.003733196761459112,
      -0.0030315180774778128,
      -0.0070038167759776115,
      -0.037299808114767075,
      0.032917361706495285,
      0.01595279388129711,
      0.002650071866810322,
      0.0607152059674263,
      0.013940336182713509,
      0.03671058639883995,
      -0.008652741089463234,
      -0.036492086946964264,
      -0.008648247458040714,
      -0.007951725274324417
    ],
    [
      -0.004964452236890793,
      -0.00191616325173527,
      0.013511580415070057,
      -0.09829185903072357,
      -0.015825165435671806,
      -0.023811692371964455,
      0.10048488527536392,
      -0.027727928012609482,
      -0.004211880266666412,
      0.013728711754083633,
      0.055839426815509796,
      0.07101491838693619,
      0.0469832606613636,
      0.006819512229412794,
      -0.08525587618350983,
      0.01610017567873001,
      0.08603134006261826,
      -0.04104749113321304,
      0.04189446195960045,
      -0.015860220417380333,
      -0.0006398202385753393,
      0.029999447986483574,
      0.027776433154940605,
      0.07315580546855927,
      0.067362479865551,
      -0.009903526864945889,
      -0.022552309557795525,
      0.004988383501768112,
      -0.04851449280977249,
      0.005856520030647516,
      -0.008542301133275032,
      -0.0005118770059198141,
      -0.035504817962646484,
      -0.0036173327825963497,
      -0.08763088285923004,
      0.003552038688212633,
      0.0007824955973774195,
      0.06640778481960297,
      -0.009900814853608608,
      -0.0028885656502097845,
      -0.05304056778550148,
      0.024044543504714966,
      0.00549290468916297,
      -0.052182141691446304,
      0.0542915053665638,
      0.039703790098428726,
      -0.04690583795309067,
      0.03514006361365318,
      0.04598638787865639,
      0.04562266170978546,
      0.041354525834321976,
      -0.018957393243908882,
      -0.014356573112308979,
      -0.004325385671108961,
      0.018825888633728027,
      0.027076486498117447,
      0.013405611738562584,
      -0.02841188572347164,
      0.08019611984491348,
      -0.004244975280016661,
      -0.06683900952339172,
      0.006116952281445265,
      0.02654709294438362,
      0.028576308861374855,
      -0.002153589390218258,
      -0.028240257874131203,
      -0.021599527448415756,
      -0.025924088433384895,
      0.0147253954783082,
      -0.09269571304321289,
      0.03266604244709015,
      -0.03870895877480507,
      -0.04448292776942253,
      0.0078087858855724335,
      0.07778226584196091,
      0.030568325892090797,
      0.0006393385701812804,
      0.03399708867073059,
      0.023156778886914253,
      -0.06035225838422775,
      -0.017018305137753487,
      -0.06541427224874496,
      0.051768552511930466,
      0.1028386577963829,
      0.028632789850234985,
      -0.025770165026187897,
      -0.031190479174256325,
      -0.04432503506541252,
      -0.07692211866378784,
      0.016306407749652863,
      0.013135223649442196,
      -0.014888430945575237,
      -0.008423460647463799,
      -0.011259200982749462,
      0.0024829518515616655,
      0.04184473678469658,
      -0.0013324382016435266,
      -0.027130279690027237,
      0.05399858206510544,
      0.03747083991765976,
      -0.03606591746211052,
      -0.021476028487086296,
      -0.06734849512577057,
      -0.08319074660539627,
      -0.01415283139795065,
      -0.031964488327503204,
      0.013561607338488102,
      -0.03490679711103439,
      -0.010714481584727764,
      0.05516022443771362,
      -0.041958872228860855,
      0.08811546117067337,
      0.03485970199108124,
      0.11193552613258362,
      0.030573055148124695,
      0.021761314943432808,
      0.0036282562650740147,
      0.018867356702685356,
      -0.041810110211372375,
      -0.03686501458287239,
      0.010680326260626316,
      -0.028283366933465004,
      0.018396764993667603,
      -0.017387468367815018,
      -0.038405220955610275,
      0.06897589564323425,
      -0.02729112282395363,
      0.06883776932954788,
      0.05399950221180916,
      -0.10766968876123428,
      0.08036324381828308,
      0.012995492666959763,
      -0.01551896147429943,
      -0.027560211718082428,
      0.0348316952586174,
      -0.06835512071847916,
      -0.003464222652837634,
      -0.03341830149292946,
      -0.008913395926356316,
      -0.011267179623246193,
      0.048651643097400665,
      -0.026481639593839645,
      -0.09148605912923813,
      -0.014166826382279396,
      0.04338629171252251,
      -0.024505168199539185,
      0.0252265315502882,
      -0.08089437335729599,
      0.04079396277666092,
      0.0784747302532196,
      -0.0027407340239733458,
      0.0049850246869027615,
      -0.04144938662648201,
      0.05845979228615761,
      -0.025121089071035385,
      -0.027582088485360146,
      0.03024669550359249,
      -0.011404457502067089,
      0.01564018242061138,
      -0.08067839592695236,
      -0.029601072892546654,
      -0.12629936635494232,
      0.0031152046285569668,
      -0.05342555046081543,
      -0.0012970094103366137,
      -0.07990632206201553,
      -0.041590090841054916,
      0.009938746690750122,
      0.08445897698402405,
      0.05741066113114357,
      0.00900027435272932,
      -0.031474336981773376,
      0.03681504353880882,
      0.05010906606912613,
      0.06954717636108398,
      -0.09251923859119415,
      0.014457469806075096,
      -0.009657167829573154,
      0.011812196113169193,
      0.05934198573231697,
      -0.006697571370750666,
      0.061503127217292786,
      -0.05520303547382355,
      -0.02420307695865631,
      -0.015418783761560917,
      -0.074198417365551,
      0.0716324970126152,
      -0.004048562608659267,
      0.05363358557224274,
      -0.015382847748696804,
      0.007774980738759041,
      -0.014644023962318897,
      0.02058584801852703,
      -0.07297752797603607,
      -0.02164170891046524,
      0.006850318983197212,
      0.07154306769371033,
      -0.03692382946610451,
      -1.0647483577486128e-05,
      0.056944530457258224,
      -0.035908013582229614,
      -0.011646074242889881,
      0.07162114232778549,
      -0.08459708839654922,
      0.04314478114247322,
      -0.06811685860157013,
      -0.06176287308335304,
      -0.04827072098851204,
      -0.025680407881736755,
      0.07661539316177368,
      -0.03963956981897354,
      0.0640263482928276,
      0.03787641227245331,
      -0.048367634415626526,
      -0.09192613512277603,
      0.05361829325556755,
      0.0034242135006934404,
      0.09441269189119339,
      -0.06644686311483383,
      -0.05089293047785759,
      0.04099645838141441,
      0.0047445823438465595,
      -0.042972467839717865,
      -0.022359466180205345,
      -0.00986494030803442,
      0.002442347351461649,
      0.027290016412734985,
      0.04881487786769867,
      0.022309565916657448,
      -0.02635437250137329,
      0.0246504545211792,
      -0.00876251794397831,
      -0.026186112314462662,
      0.01176074706017971,
      -0.04124974459409714,
      -0.00841927994042635,
      -0.03918498381972313,
      -0.07076124101877213,
      0.054143957793712616,
      0.051229462027549744,
      -0.06873577833175659,
      -0.003721351968124509,
      0.021698616445064545,
      0.06063932925462723,
      -0.021297892555594444,
      0.10454316437244415,
      0.016867417842149734,
      -0.005076688248664141,
      0.03082607127726078,
      -0.030654381960630417,
      0.06431510299444199,
      -0.0314454548060894,
      0.025028090924024582,
      0.03428459167480469,
      0.008754697628319263,
      0.010765908285975456,
      0.01817668415606022,
      -0.03218166157603264,
      0.03858679533004761,
      -0.07519601285457611,
      -0.03648185357451439,
      -0.02833225391805172,
      0.0022111169528216124,
      0.021283574402332306,
      0.01039084605872631,
      0.032977908849716187,
      -0.050415366888046265,
      -0.0018614113796502352,
      -0.0012573216808959842,
      0.07716444879770279,
      0.002500095870345831,
      0.11486252397298813,
      -0.07462098449468613,
      -0.05824197456240654,
      -0.05965173989534378,
      0.09165144711732864,
      -0.05444670841097832,
      0.03936482220888138,
      0.022410186007618904,
      -0.01957724615931511,
      0.01679048128426075,
      0.12857647240161896,
      -0.008240952156484127,
      0.013639822602272034,
      -0.00967684667557478,
      -0.05984382703900337,
      0.02918114699423313,
      -0.013325913809239864,
      0.004359337501227856,
      -0.05714121088385582,
      -0.058463647961616516,
      0.058215878903865814,
      -0.03445145860314369,
      0.019747288897633553,
      0.03089124709367752,
      -0.015154934488236904,
      -0.018247414380311966,
      -0.0014658657601103187,
      -0.014838526025414467,
      0.059709180146455765,
      -0.03576567396521568,
      0.008143779821693897,
      -0.04724816232919693,
      0.04099743440747261,
      0.005247901193797588,
      0.09295667707920074,
      0.000746271398384124,
      0.03554866835474968,
      -0.041009146720170975,
      0.008039616979658604,
      -0.025094211101531982,
      0.0376034714281559,
      0.07175061851739883,
      0.03707074001431465,
      -0.00388663774356246,
      0.026186328381299973,
      0.028003668412566185,
      0.07057711482048035,
      -0.04340159893035889,
      -0.024508312344551086,
      0.0005035707144998014,
      0.03100281022489071,
      0.008193321526050568,
      0.06935645639896393,
      -0.019800299778580666,
      0.011746536940336227,
      0.019124720245599747,
      -0.05657912790775299,
      0.09135108441114426,
      -0.08676118403673172,
      -0.030960697680711746,
      -0.11619657278060913,
      0.015414354391396046,
      2.5520497729303315e-05,
      -0.046263087540864944,
      -0.005759330932050943,
      0.0356249138712883,
      0.048828285187482834,
      0.0008421356906183064,
      -0.03304000571370125,
      -0.013929269276559353,
      0.010853554122149944,
      -0.07707670331001282,
      0.044720228761434555,
      -0.0008473487687297165,
      -0.0013270158087834716,
      -0.009329775348305702,
      -0.08902807533740997,
      -0.022121528163552284,
      0.07004860043525696,
      -0.07955018430948257,
      0.04812740907073021,
      0.05841189622879028,
      0.04110301658511162,
      -0.0712146982550621,
      -0.10688633471727371,
      0.07905606925487518,
      0.032272376120090485,
      0.06654112040996552,
      0.06062187999486923,
      0.008354862220585346,
      -0.028619427233934402,
      0.021410021930933,
      -0.026553845033049583,
      -0.017270509153604507,
      -0.004027334973216057,
      0.010689133778214455,
      -0.02730756253004074,
      -0.06352505087852478,
      -0.01968221180140972,
      0.02194606326520443,
      -0.03864150866866112,
      -0.0020881416276097298,
      0.021547140553593636,
      0.06624424457550049,
      0.0352369025349617,
      0.02771121822297573,
      0.08494900166988373,
      0.022759810090065002,
      0.014838284812867641,
      0.0380030982196331,
      0.04087920859456062,
      -0.018190667033195496,
      0.053337763994932175,
      0.00613422691822052,
      0.0010832477128133178,
      -0.07105743885040283,
      -0.008867619559168816,
      0.06534586101770401,
      0.009970895946025848,
      -0.016254208981990814,
      0.02769777737557888,
      -0.04594855010509491,
      0.009760419838130474,
      -0.013196000829339027,
      -0.0290022362023592,
      -0.0381125807762146,
      -0.12229305505752563,
      0.050558775663375854,
      -0.008421679958701134,
      -0.04812268540263176,
      0.06134941801428795,
      -0.0028298208490014076,
      0.03689827769994736,
      -0.0822024792432785,
      -0.04867422208189964,
      -0.020170018076896667,
      -0.03671908378601074,
      0.0089043490588665,
      0.005529144778847694,
      0.01465460006147623,
      -0.04093991592526436,
      -0.0379822738468647,
      -0.04775869473814964,
      -0.0367395244538784,
      0.031174954026937485,
      0.040043387562036514,
      -0.015732837840914726,
      -0.05274548754096031,
      0.07845833897590637,
      -0.061504919081926346,
      0.002501030918210745,
      -0.013848300091922283,
      0.030078750103712082,
      -0.05718692019581795,
      -0.008306560106575489,
      -0.03414561599493027,
      0.027010634541511536,
      -0.0319112092256546,
      0.041226547211408615,
      -0.029306653887033463,
      -0.044319335371255875,
      0.08961044996976852,
      -0.0366433709859848,
      -0.015538345091044903,
      -0.004783177748322487,
      -0.11322237551212311,
      0.04841466248035431,
      0.1231549009680748,
      -0.051741961389780045,
      -0.05436820909380913,
      0.027105629444122314,
      -0.03802253678441048,
      -0.06519342958927155,
      0.027562877163290977,
      0.0026744611095637083,
      -0.04171546921133995,
      0.016145048663020134,
      0.049462754279375076,
      0.020034683868288994,
      -0.09021054953336716,
      -0.04195861145853996,
      -0.023019563406705856,
      0.03267186880111694,
      0.03235962241888046,
      -0.1050027459859848,
      0.027521057054400444,
      -0.018253734335303307,
      0.008360397070646286,
      0.03809503838419914,
      0.09978611767292023,
      -0.026840034872293472,
      0.003659599693492055,
      -0.04280560836195946,
      -0.001670525292865932,
      -0.007780074141919613,
      -0.007316056173294783,
      0.02289077453315258,
      0.0383724719285965,
      0.05788854882121086,
      0.06686025112867355,
      0.04563698545098305,
      0.01780509203672409,
      -0.007898249663412571,
      0.014132004231214523,
      0.046519067138433456,
      -0.022667963057756424,
      -0.0016898716567084193,
      -0.05216539278626442,
      -0.01834767311811447,
      0.03086194023489952,
      0.00911321584135294,
      -0.033773165196180344,
      0.017604177817702293,
      -0.0007119139190763235,
      0.021560296416282654,
      -0.0039029100444167852,
      0.06443759053945541,
      -0.006761271972209215,
      0.02795037254691124,
      -0.06174850091338158,
      0.008355356752872467,
      -0.031618423759937286,
      0.0378386490046978,
      0.03227316215634346,
      -0.014720961451530457,
      0.015195087529718876,
      -0.044977787882089615,
      -0.10991908609867096,
      0.023140428587794304,
      -0.025228336453437805,
      -0.0024449056945741177,
      0.00901219341903925
    ],
    [
      -0.008458920754492283,
      -0.019506940618157387,
      -0.010685586370527744,
      -0.03363466262817383,
      0.017675068229436874,
      -0.039176009595394135,
      0.009571826085448265,
      -0.01052576769143343,
      0.07191716134548187,
      -0.018246080726385117,
      0.08246751129627228,
      -0.007223045919090509,
      0.02510356344282627,
      -0.03483196347951889,
      0.035665109753608704,
      -0.034414730966091156,
      0.0018172813579440117,
      0.000980559503659606,
      -0.059354063123464584,
      0.0006258763605728745,
      -0.10088888555765152,
      0.03727437183260918,
      0.034493908286094666,
      -0.04594870284199715,
      0.03684519976377487,
      0.02545570395886898,
      0.025287028402090073,
      0.025968611240386963,
      -0.017799023538827896,
      -0.02820349857211113,
      -0.028067175298929214,
      0.08886588364839554,
      -0.08475527912378311,
      0.033554382622241974,
      0.0039670695550739765,
      -0.05197814851999283,
      0.07850564271211624,
      -0.0031800544820725918,
      -0.10434336215257645,
      -0.07238655537366867,
      0.043084364384412766,
      -0.08621303737163544,
      0.10259300470352173,
      -0.00843295082449913,
      0.021488085389137268,
      -0.06688548624515533,
      -0.03072882816195488,
      0.04753861203789711,
      0.04314352944493294,
      -0.05882412940263748,
      -0.04190624877810478,
      -0.07139264792203903,
      -0.07861829549074173,
      -0.04768329858779907,
      -0.08926506340503693,
      0.01764073595404625,
      -0.06530611962080002,
      -0.0490165650844574,
      0.014698365703225136,
      0.094493567943573,
      -0.06607270240783691,
      -0.06755022704601288,
      -0.010029864497482777,
      0.04173491150140762,
      0.00015382985293399543,
      0.01875399239361286,
      -0.04409095644950867,
      0.002751144114881754,
      0.016178803518414497,
      0.10118500143289566,
      0.04913539066910744,
      0.013230381533503532,
      0.00022840246674604714,
      0.042438361793756485,
      0.05058438330888748,
      0.018981212750077248,
      -0.0017340255435556173,
      0.013505874201655388,
      0.02439046837389469,
      0.026467179879546165,
      0.0992397591471672,
      0.07005523890256882,
      0.015782583504915237,
      -0.05105794966220856,
      -0.03586576133966446,
      -0.08506934344768524,
      -0.013444415293633938,
      0.0034278554376214743,
      -0.031614143401384354,
      0.029358329251408577,
      4.670871567213908e-05,
      -0.06966549158096313,
      0.0027701216749846935,
      -0.04369372874498367,
      -0.05372729152441025,
      -0.040456339716911316,
      0.011641712859272957,
      0.006889856420457363,
      0.0009249176946468651,
      -0.044701673090457916,
      -0.009786841459572315,
      0.021789206191897392,
      -0.018010428175330162,
      -0.04238143935799599,
      -0.027479838579893112,
      0.000649609079118818,
      -0.002212222432717681,
      0.008665608242154121,
      0.03201469033956528,
      -0.012239327654242516,
      -0.021644288673996925,
      0.049895960837602615,
      -0.011462043970823288,
      0.017769450321793556,
      0.05814114585518837,
      -0.05160153657197952,
      -0.12026232481002808,
      0.02947591058909893,
      -0.05897415429353714,
      0.03292708471417427,
      0.007233398035168648,
      0.051332470029592514,
      -0.06470979750156403,
      0.03959190100431442,
      -0.07119813561439514,
      0.0131334587931633,
      -0.0010491259163245559,
      -0.059483543038368225,
      -0.034647125750780106,
      -0.06173960864543915,
      0.039164431393146515,
      0.022383539006114006,
      0.0008388088899664581,
      -0.07540538161993027,
      -0.020510507747530937,
      0.03827892988920212,
      -0.07268653810024261,
      0.043779633939266205,
      -0.06368276476860046,
      -0.030725371092557907,
      0.03880547359585762,
      0.10837011784315109,
      0.006867610849440098,
      -0.06490891426801682,
      0.013786201365292072,
      -0.04294891655445099,
      0.06378430873155594,
      -0.07724794745445251,
      0.03299702703952789,
      0.02880813181400299,
      0.007445288356393576,
      -0.03980378061532974,
      0.0034314158838242292,
      0.10242703557014465,
      0.04717898368835449,
      0.038487508893013,
      0.030618974938988686,
      -0.0030227522365748882,
      -0.03484838455915451,
      0.008271186612546444,
      -0.1125299409031868,
      -0.05353183299303055,
      -0.07199477404356003,
      0.09831320494413376,
      -0.02104686014354229,
      0.0026172928046435118,
      0.020201195031404495,
      0.0038674429524689913,
      0.031185394152998924,
      0.007196769583970308,
      -0.009469692595303059,
      0.026255063712596893,
      0.01073923148214817,
      -0.01843232288956642,
      -0.02250984124839306,
      -0.03970698267221451,
      0.0021296667400747538,
      0.007966877892613411,
      -0.010670075193047523,
      0.08179561048746109,
      0.0829220861196518,
      0.004767939448356628,
      -0.014514782465994358,
      -0.023926032707095146,
      -0.03027869574725628,
      0.020036641508340836,
      0.06299632042646408,
      -0.026368817314505577,
      -0.047275811433792114,
      -0.01473828312009573,
      -0.017821120098233223,
      -0.018756596371531487,
      -0.04070519283413887,
      -0.04916040599346161,
      0.046263620257377625,
      0.0031230133026838303,
      -0.00884450227022171,
      0.00546671450138092,
      0.008085962384939194,
      0.11576573550701141,
      0.02133604697883129,
      0.01651874929666519,
      0.10748536884784698,
      -0.0420469231903553,
      0.0464809276163578,
      -0.05353318899869919,
      -0.041673194617033005,
      -0.017347414046525955,
      -0.04888620972633362,
      0.10727143287658691,
      -0.01539099495857954,
      0.06261089444160461,
      0.08305627107620239,
      0.026600634679198265,
      -0.013980703428387642,
      -0.08516738563776016,
      0.07936330884695053,
      -0.02813229337334633,
      -0.00764391990378499,
      -0.044283799827098846,
      0.09379430115222931,
      -0.0644930750131607,
      0.023381264880299568,
      0.06255900114774704,
      0.02978420816361904,
      -0.010290076024830341,
      0.05332962051033974,
      0.0547698438167572,
      -0.04949469864368439,
      -0.0034264596179127693,
      0.022631511092185974,
      0.006547930184751749,
      0.009708862751722336,
      -0.0035025174729526043,
      -0.007724613416939974,
      0.012052287347614765,
      -0.007790875621140003,
      0.012047876603901386,
      -0.04672045260667801,
      0.010978281497955322,
      0.003494237782433629,
      -0.07118907570838928,
      0.0606943741440773,
      -0.08967918157577515,
      -0.057500626891851425,
      -0.00544085493311286,
      -0.06511116772890091,
      -0.0003635855973698199,
      0.05261019244790077,
      0.07326634228229523,
      -0.042949236929416656,
      -0.02447393350303173,
      -0.04453016072511673,
      -0.013539275154471397,
      0.000815711566247046,
      0.0098791578784585,
      0.0740414634346962,
      0.016971386969089508,
      -0.007518961559981108,
      -0.05402398854494095,
      0.021286776289343834,
      0.004913531709462404,
      -0.031659726053476334,
      -0.0035809886176139116,
      0.11676335334777832,
      -0.009137392975389957,
      0.00744206365197897,
      0.034891821444034576,
      -0.015505983494222164,
      0.06382321566343307,
      -0.08997166901826859,
      0.07267985492944717,
      0.008479856885969639,
      0.00044677391997538507,
      0.05004756525158882,
      -0.023945843800902367,
      0.02990579791367054,
      -0.03404206782579422,
      -0.0947222113609314,
      -0.055586282163858414,
      0.07666437327861786,
      0.024403274059295654,
      -0.029618218541145325,
      0.04704646021127701,
      -0.004909445997327566,
      0.029973257333040237,
      -0.06067943572998047,
      -0.11418479681015015,
      -0.047237545251846313,
      -0.06484393775463104,
      -0.03094067983329296,
      0.04757118970155716,
      -0.03924839571118355,
      -0.06063147634267807,
      -0.01499792467802763,
      0.001908198813907802,
      -0.041672781109809875,
      -0.040416378527879715,
      0.03345104306936264,
      -0.05638275668025017,
      0.06505044549703598,
      0.004833943210542202,
      -0.08206439763307571,
      -0.06627767533063889,
      -0.03843684121966362,
      0.006193033419549465,
      -0.04947992041707039,
      -0.061636634171009064,
      0.02391141466796398,
      -0.061176177114248276,
      0.053238727152347565,
      0.0005558966076932847,
      0.07845587283372879,
      -0.13492165505886078,
      -0.11185469478368759,
      -0.09702574461698532,
      -0.013773923739790916,
      -0.0015629433328285813,
      -0.034194812178611755,
      0.006435172166675329,
      0.05190899968147278,
      -0.023707065731287003,
      0.0034261031541973352,
      0.09302036464214325,
      0.029560454189777374,
      0.02969452552497387,
      0.031308624893426895,
      -0.0019513157894834876,
      -0.07160399854183197,
      0.03578600659966469,
      -0.024613089859485626,
      -0.02936604432761669,
      0.02273566648364067,
      -0.008979166857898235,
      0.005599564872682095,
      -0.0241952296346426,
      -0.04531596228480339,
      0.059235017746686935,
      -0.03486138954758644,
      -0.018487541005015373,
      -0.04234140366315842,
      -0.03674808144569397,
      -0.01925244741141796,
      0.019017277285456657,
      -0.046589311212301254,
      0.05015422776341438,
      0.08257293701171875,
      0.0525321438908577,
      -0.014272982254624367,
      -0.01164453849196434,
      -0.11302922666072845,
      -0.03000088408589363,
      -0.002852202393114567,
      0.005654233507812023,
      -0.04426908865571022,
      -0.0230087973177433,
      0.05011756718158722,
      0.03718428686261177,
      0.03542628884315491,
      -0.07500316947698593,
      -0.01571577414870262,
      -0.035468146204948425,
      0.06720324605703354,
      0.0038804090581834316,
      0.019506964832544327,
      0.040996771305799484,
      0.04107135534286499,
      0.03564932569861412,
      -0.0003079751622863114,
      -0.010377614758908749,
      0.07761885970830917,
      -0.07118730992078781,
      -0.017197653651237488,
      0.06339335441589355,
      0.015660028904676437,
      -0.03969014808535576,
      -0.0015063334722071886,
      0.07900096476078033,
      -0.030420983210206032,
      -0.06360173225402832,
      0.04426439478993416,
      0.030895864591002464,
      -0.07299306988716125,
      0.07678435742855072,
      -0.005173791199922562,
      -0.011894427239894867,
      0.02023097313940525,
      -0.05462992191314697,
      0.0303970780223608,
      0.01199257280677557,
      0.04293591156601906,
      -0.015454807318747044,
      0.11622060090303421,
      -0.030447695404291153,
      0.033365972340106964,
      0.0010545747354626656,
      0.06132810190320015,
      -0.07260306924581528,
      -0.007457278668880463,
      -0.015983115881681442,
      -0.020854171365499496,
      0.010845829732716084,
      0.06252806633710861,
      0.03647172823548317,
      0.004926163703203201,
      -0.07387077808380127,
      -0.018452586606144905,
      -0.035275522619485855,
      -0.02544616162776947,
      0.019063999876379967,
      0.0740353986620903,
      0.043398283421993256,
      0.026526102796196938,
      0.0023576845414936543,
      0.019588548690080643,
      -0.0012995718279853463,
      0.04193482547998428,
      -0.07930157333612442,
      0.051145099103450775,
      0.03014233149588108,
      -0.09123805910348892,
      0.019752152264118195,
      0.029589252546429634,
      0.07031238824129105,
      -0.023951029404997826,
      0.03483713045716286,
      0.0062066493555903435,
      -0.018294958397746086,
      0.01578710973262787,
      0.04227231815457344,
      -0.03787101432681084,
      0.023091483861207962,
      -0.018499208614230156,
      -0.00539196515455842,
      -0.06493248045444489,
      0.008815613575279713,
      -0.045978106558322906,
      -0.046774450689554214,
      0.006284334231168032,
      -0.03456766903400421,
      -0.004604791756719351,
      -0.004374340642243624,
      0.013969885185360909,
      0.006812978070229292,
      0.018170412629842758,
      -0.01913291960954666,
      0.049656037241220474,
      -0.008446539752185345,
      0.12263400852680206,
      -0.011652549728751183,
      -0.052144959568977356,
      0.03833010047674179,
      0.08503729850053787,
      -0.03810011222958565,
      0.007244786713272333,
      -0.073895663022995,
      -0.058526188135147095,
      -0.005750785116106272,
      -0.02011690102517605,
      -0.03322366625070572,
      0.03056187927722931,
      -0.02288968674838543,
      0.10501468181610107,
      0.03415616974234581,
      0.08540277928113937,
      -0.02328311838209629,
      0.0535561703145504,
      -0.05450323224067688,
      -0.08550290018320084,
      0.06982608884572983,
      -0.027807490900158882,
      0.0065774391405284405,
      0.04789142683148384,
      -0.0213922206312418,
      -0.00908482912927866,
      0.06137651205062866,
      0.11785707622766495,
      -0.08292289078235626,
      -0.0702982246875763,
      0.03911512717604637,
      0.010741302743554115,
      -0.027720602229237556,
      -0.015693701803684235,
      0.043209075927734375,
      -0.039014775305986404,
      -0.003433682257309556,
      0.09580011665821075,
      -0.06847558915615082,
      -0.040572963654994965,
      -0.03634854406118393,
      -0.014567307196557522,
      0.0015632971189916134,
      0.066740021109581,
      -0.11422363668680191,
      -0.05334785580635071,
      0.009161803871393204,
      -0.022105377167463303,
      0.05995608866214752,
      -0.012911023572087288,
      -0.07357805222272873,
      -0.0051386659033596516,
      -0.03784305602312088,
      -0.015234109945595264
    ],
    [
      -0.04642597213387489,
      -0.09178588539361954,
      -0.043528709560632706,
      -0.048554420471191406,
      -0.017567740753293037,
      0.0035603241994976997,
      -0.005353972315788269,
      0.024377012625336647,
      -0.04433656483888626,
      0.007178529631346464,
      0.04793884605169296,
      -0.00958933960646391,
      0.003428604919463396,
      -0.0008681798353791237,
      0.019896280020475388,
      -0.035946205258369446,
      0.028225446119904518,
      0.0152701735496521,
      0.07317396998405457,
      0.03346462547779083,
      -0.100865438580513,
      0.043252140283584595,
      -0.04397301375865936,
      -0.031958818435668945,
      0.013417255133390427,
      -0.006255654618144035,
      -0.09020610898733139,
      -0.021307481452822685,
      0.06906378269195557,
      -0.0012726825661957264,
      0.022179463878273964,
      0.055429354310035706,
      0.047442641109228134,
      0.055626094341278076,
      0.00023696123389527202,
      -0.003099848749116063,
      0.029188044369220734,
      -0.008955462835729122,
      -0.0037130299024283886,
      -0.06395009160041809,
      -0.014083467423915863,
      -0.009042599238455296,
      0.010533342137932777,
      0.0727771744132042,
      0.019434679299592972,
      -0.07419302314519882,
      -0.01516643911600113,
      -0.0066905212588608265,
      0.024618836119771004,
      -0.0052873920649290085,
      -0.04007774963974953,
      -0.010140539146959782,
      0.06712901592254639,
      0.024947891011834145,
      -0.03817575424909592,
      -0.014530000276863575,
      -0.03154739364981651,
      -0.05374860018491745,
      -0.04609484225511551,
      0.024876566603779793,
      -0.10461056232452393,
      0.005712973419576883,
      0.002372108167037368,
      -0.005578311160206795,
      0.0035264675971120596,
      -0.019805921241641045,
      0.06021325662732124,
      0.040002159774303436,
      -0.016022996976971626,
      0.011411306448280811,
      0.02654636651277542,
      -0.04924340546131134,
      0.02609645389020443,
      0.0023396045435220003,
      0.012386367656290531,
      -0.020039573311805725,
      0.010261989198625088,
      0.011004860512912273,
      -0.04429087042808533,
      -0.005085485056042671,
      -0.002606099471449852,
      -0.044330526143312454,
      0.01615043543279171,
      0.050718046724796295,
      -0.01952941343188286,
      -0.0837380662560463,
      0.02915073186159134,
      0.06131753697991371,
      -0.031621478497982025,
      0.016062390059232712,
      -0.04102647304534912,
      0.031999483704566956,
      -0.011984431184828281,
      0.027915270999073982,
      0.015650926157832146,
      -0.0033048263285309076,
      0.032911594957113266,
      0.051099516451358795,
      0.049800142645835876,
      0.08674570173025131,
      0.027332894504070282,
      -0.03419706970453262,
      -0.011667685583233833,
      -0.05138368904590607,
      -0.0002946140302810818,
      -0.00030217572930268943,
      -0.012597309425473213,
      -0.018127689138054848,
      0.01980126090347767,
      0.05366873741149902,
      -0.06693855673074722,
      0.0007290068897418678,
      -0.002135480986908078,
      0.04444435238838196,
      -0.060450777411460876,
      0.03449664264917374,
      0.04024764895439148,
      0.029481790959835052,
      -0.047560688108205795,
      -0.09624920040369034,
      -0.03341420367360115,
      -0.002044758992269635,
      -0.045640405267477036,
      -0.04987919330596924,
      -0.007372061721980572,
      -0.04748443141579628,
      -0.030167793855071068,
      0.013034691102802753,
      0.07975228875875473,
      -0.06294123083353043,
      -0.031752873212099075,
      -0.018496841192245483,
      -0.004105632659047842,
      0.020083792507648468,
      -0.0025837314315140247,
      -0.03819547966122627,
      0.03291400149464607,
      0.06380091607570648,
      -0.05028034374117851,
      -0.06151234358549118,
      0.02435249090194702,
      -0.09836311638355255,
      -0.006154052447527647,
      -0.06324885785579681,
      -0.0878802165389061,
      0.05071304365992546,
      0.0006787931779399514,
      0.044960495084524155,
      0.030148150399327278,
      -0.04522461071610451,
      -0.051993392407894135,
      -0.004708603955805302,
      0.008952591568231583,
      0.05578077957034111,
      0.028706207871437073,
      -0.07933354377746582,
      -0.013161880895495415,
      0.07241853326559067,
      -0.0002907533780671656,
      -0.0700424462556839,
      -0.009646443650126457,
      0.005720303859561682,
      0.0796024277806282,
      0.016871202737092972,
      0.0034952079877257347,
      0.027393627911806107,
      0.04362499713897705,
      -0.009485432878136635,
      0.08793345093727112,
      -0.04233032092452049,
      0.019922111183404922,
      -0.07942957431077957,
      0.06041683256626129,
      0.04092508926987648,
      0.08509080111980438,
      0.026662837713956833,
      -0.08411694318056107,
      0.050736669450998306,
      -4.679901758208871e-05,
      -0.0441095195710659,
      -0.0200762078166008,
      0.0682872086763382,
      -0.05629913508892059,
      -0.005067301914095879,
      0.00447752233594656,
      -0.028148774057626724,
      0.022132281213998795,
      -0.05072382092475891,
      -0.03960428759455681,
      -0.025113798677921295,
      -0.004862854722887278,
      0.027804087847471237,
      -0.0015875939279794693,
      -0.027060607448220253,
      0.12492629140615463,
      -0.024984348565340042,
      -0.050752390176057816,
      0.03838106989860535,
      -0.07013730704784393,
      0.027599869295954704,
      0.0016648577293381095,
      0.05122038722038269,
      0.06730601191520691,
      -0.040984608232975006,
      0.013220030814409256,
      0.003076492575928569,
      -0.04109393432736397,
      -0.03161115199327469,
      -0.03375661373138428,
      -0.06505866348743439,
      -0.01998620294034481,
      0.11427297443151474,
      0.046870578080415726,
      -0.027233170345425606,
      0.08180462568998337,
      0.031574271619319916,
      0.05915144458413124,
      -0.0383615642786026,
      0.010311935096979141,
      -0.05347532406449318,
      0.120986208319664,
      -0.031508803367614746,
      -0.059331413358449936,
      -0.062367845326662064,
      0.015443474985659122,
      0.0021170503459870815,
      -0.006764326244592667,
      0.11032526940107346,
      0.011650413274765015,
      0.005562235601246357,
      0.0669940933585167,
      0.0019253744976595044,
      0.05257662385702133,
      0.023796020075678825,
      -0.04415971413254738,
      0.018915876746177673,
      -0.05035246163606644,
      0.026097800582647324,
      0.08832308650016785,
      0.05161968246102333,
      -0.07229205220937729,
      0.027621958404779434,
      0.009272893890738487,
      0.00725720077753067,
      0.030119366943836212,
      0.05993976816534996,
      0.00443906057626009,
      -0.019323546439409256,
      -0.04110219329595566,
      0.045731071382761,
      -0.0017034222837537527,
      -0.025773609057068825,
      0.012682619504630566,
      0.01054596807807684,
      0.016155553981661797,
      0.042992278933525085,
      0.09564134478569031,
      -0.006896553095430136,
      -0.01485134195536375,
      0.002402630867436528,
      -0.07721901684999466,
      -0.037509988993406296,
      0.014491576701402664,
      0.10006815195083618,
      -0.004933414980769157,
      0.04763046279549599,
      0.0014584455639123917,
      -0.025486713275313377,
      -0.0018730253214016557,
      0.033452123403549194,
      -0.02114599011838436,
      0.02187647670507431,
      -0.013829058967530727,
      0.10018225759267807,
      -0.045831214636564255,
      0.03525872528553009,
      -0.05940081551671028,
      -0.0668237954378128,
      0.025596393272280693,
      0.08570549637079239,
      0.05798827484250069,
      -0.02394614741206169,
      -0.01657206006348133,
      -0.02363073080778122,
      0.0967273935675621,
      -0.049224115908145905,
      -0.00742777856066823,
      0.08405808359384537,
      0.038711030036211014,
      -0.00419324915856123,
      -0.038527049124240875,
      0.04745388776063919,
      -0.04529525712132454,
      -0.033494625240564346,
      0.04443274438381195,
      -0.046803612262010574,
      0.03274470195174217,
      -0.0677734985947609,
      -0.05350378155708313,
      0.04639272019267082,
      0.008267614059150219,
      -0.04428616166114807,
      -0.0013569144066423178,
      -0.026433749124407768,
      -0.07549548894166946,
      0.003182705957442522,
      -0.02531573176383972,
      -0.06201615184545517,
      0.08749620616436005,
      0.00046800507698208094,
      0.10086658596992493,
      -0.033644627779722214,
      -0.020945841446518898,
      -0.003056604415178299,
      -0.01681606099009514,
      -0.00938455481082201,
      -0.02750740759074688,
      0.0563894584774971,
      -0.08281764388084412,
      0.028385130688548088,
      -0.008860071189701557,
      -0.010412012226879597,
      0.09213993698358536,
      0.021526610478758812,
      0.018313296139240265,
      0.10305803269147873,
      -0.06087670475244522,
      -0.046030160039663315,
      0.031286321580410004,
      -0.002881512977182865,
      -0.10676341503858566,
      -0.03629926219582558,
      -0.01782122440636158,
      0.027549760416150093,
      -0.10028327256441116,
      -0.0883115604519844,
      0.04061432182788849,
      0.04036499932408333,
      0.036081377416849136,
      -0.0350850448012352,
      -0.058940958231687546,
      0.08096761256456375,
      0.08894498646259308,
      0.01277550496160984,
      0.05511624738574028,
      0.09188032895326614,
      0.024832239374518394,
      0.004326668102294207,
      -0.03587670624256134,
      0.014199559576809406,
      -0.03589337691664696,
      -0.01638329029083252,
      -0.017084822058677673,
      0.07900937646627426,
      0.05014714598655701,
      -0.031708911061286926,
      -0.045723430812358856,
      0.09788147360086441,
      0.0816812813282013,
      0.04033876582980156,
      -0.027205202728509903,
      -0.03254643455147743,
      -0.05174737423658371,
      -0.03816501051187515,
      -0.04360073432326317,
      0.023185160011053085,
      -0.05679423362016678,
      -0.04259858652949333,
      -0.026048090308904648,
      -0.02532184310257435,
      0.032500386238098145,
      0.013306248933076859,
      -0.006920168176293373,
      -0.027836982160806656,
      0.0179496668279171,
      -0.058476824313402176,
      0.043126095086336136,
      0.12956205010414124,
      0.03369932621717453,
      0.036950837820768356,
      0.0751975029706955,
      -0.019193176180124283,
      0.009320152923464775,
      -0.06868860870599747,
      0.04087955132126808,
      0.03200225532054901,
      0.02267123945057392,
      -0.057266682386398315,
      -0.01883566565811634,
      0.018869007006287575,
      0.0700492188334465,
      -0.013116537593305111,
      -0.0864250659942627,
      0.0020181401632726192,
      0.009044098667800426,
      0.01773240603506565,
      0.056319862604141235,
      -0.06949585676193237,
      0.03720221295952797,
      0.04443514719605446,
      0.0016631298931315541,
      0.04323425889015198,
      0.012486881576478481,
      0.03983461484313011,
      0.01091736275702715,
      -0.03347301855683327,
      -0.003160925582051277,
      -0.023332849144935608,
      -0.037607718259096146,
      0.03507464379072189,
      0.009940572082996368,
      0.06710796058177948,
      0.09915126860141754,
      0.04265573248267174,
      -0.02232416905462742,
      0.016131561249494553,
      -0.08831209689378738,
      -0.008487500250339508,
      -0.07539679855108261,
      -0.028245804831385612,
      0.08584380894899368,
      0.05035489797592163,
      -0.005944645032286644,
      -0.0049659376963973045,
      -0.08841704577207565,
      0.017034003511071205,
      -0.009414147585630417,
      0.09323342144489288,
      0.06021834909915924,
      0.04911724105477333,
      -0.023134134709835052,
      0.014132477343082428,
      0.0019168304279446602,
      -0.06523780524730682,
      0.011091298423707485,
      -0.0015890436479821801,
      -0.013762395828962326,
      -0.008641070686280727,
      0.06957270950078964,
      -0.11005595326423645,
      -0.08596637845039368,
      -0.03565742075443268,
      -0.019098058342933655,
      0.013578832149505615,
      0.05854151397943497,
      -0.028299890458583832,
      -0.006783629301935434,
      -0.027014201506972313,
      0.02829994261264801,
      -0.016508445143699646,
      -0.07818157970905304,
      -0.029093822464346886,
      0.04822975769639015,
      0.020734090358018875,
      0.02443547733128071,
      -0.10089744627475739,
      -0.10191016644239426,
      -0.017901727929711342,
      -0.04802306741476059,
      -0.0481693409383297,
      -0.009756281040608883,
      -0.022940708324313164,
      0.033539287745952606,
      -0.0072343600913882256,
      -0.013213400729000568,
      -0.002321838401257992,
      0.038312651216983795,
      0.04134894534945488,
      0.053826916962862015,
      -0.01887519285082817,
      -0.006679069716483355,
      -0.02078920044004917,
      -0.03760832920670509,
      -0.0025117332115769386,
      -0.02097880281507969,
      -0.04975806921720505,
      0.05573093891143799,
      -0.030326956883072853,
      -0.18940183520317078,
      -0.0892411321401596,
      -0.0014586427714675665,
      0.014926725998520851,
      -0.03196658566594124,
      0.03450241684913635,
      0.0016044536605477333,
      0.0006380594568327069,
      -0.0056204721331596375,
      0.013410727493464947,
      -0.04212430864572525,
      -0.027603762224316597,
      -0.05783029645681381,
      -0.029095372185111046,
      0.05881600081920624,
      0.0943925529718399,
      -0.05889623612165451,
      0.008005637675523758,
      -0.05488075315952301,
      0.008144237101078033,
      0.03708047792315483,
      0.027540793642401695,
      0.033238328993320465,
      -0.010678724385797977,
      0.08062411844730377
    ],
    [
      -0.0010154371848329902,
      -0.03637729585170746,
      -0.015160365030169487,
      0.03339789807796478,
      0.0480010099709034,
      -6.675608892692253e-05,
      -0.046021632850170135,
      0.02445332333445549,
      -0.005276995711028576,
      0.005848248489201069,
      0.022091513499617577,
      0.02644057385623455,
      0.02254718355834484,
      -0.050851840525865555,
      -0.005479599814862013,
      -0.0012303281109780073,
      0.059384703636169434,
      -0.0020786330569535494,
      -0.08025595545768738,
      0.0407077819108963,
      -0.034858349710702896,
      0.05055070295929909,
      -0.020197615027427673,
      -0.04880001023411751,
      -0.04485709220170975,
      0.05614542216062546,
      0.038659218698740005,
      0.055148523300886154,
      0.1250290870666504,
      0.03824809193611145,
      -0.05333896353840828,
      -0.028160778805613518,
      -0.018611254170536995,
      0.02569713070988655,
      -0.021026240661740303,
      -0.036547787487506866,
      -0.04216717556118965,
      -0.008579074405133724,
      -0.03848155587911606,
      0.051631659269332886,
      -0.039809808135032654,
      -0.019423745572566986,
      0.04310348629951477,
      0.023441271856427193,
      -0.01583007536828518,
      0.05904221162199974,
      -0.04685181751847267,
      0.028236253187060356,
      -0.025279467925429344,
      -0.00256915926001966,
      -0.008208963088691235,
      -0.02992986887693405,
      0.013400662690401077,
      -0.06540592014789581,
      0.04758235439658165,
      -0.0100505780428648,
      0.06443734467029572,
      -0.03315619379281998,
      -0.08185268938541412,
      0.030217040330171585,
      -0.029457969591021538,
      -0.09721449017524719,
      -0.05809175595641136,
      0.04382416605949402,
      -0.05304216220974922,
      0.003511115675792098,
      -0.02742832712829113,
      0.05820704251527786,
      -0.007000788114964962,
      0.034048620611429214,
      -0.00016681375564076006,
      -0.07646896690130234,
      -0.003932155668735504,
      -0.05942458286881447,
      0.00612321263179183,
      0.005703684873878956,
      0.07730180770158768,
      0.08801938593387604,
      -0.06994067877531052,
      0.07761260122060776,
      0.0402192696928978,
      -0.02277582325041294,
      -0.0006523377960547805,
      -0.028552694246172905,
      0.004406513646245003,
      -0.07368532568216324,
      0.021580446511507034,
      0.03832116350531578,
      -0.008244344033300877,
      0.04109308868646622,
      -0.08632495254278183,
      0.03751001134514809,
      -0.056668542325496674,
      0.02972744032740593,
      0.05275332182645798,
      -0.02451101504266262,
      0.006488679908216,
      0.04723265394568443,
      0.03496558964252472,
      -0.020807506516575813,
      0.005472471471875906,
      -0.05691419169306755,
      0.013786481693387032,
      -0.04510635882616043,
      0.013501583598554134,
      -0.044656381011009216,
      0.051256779581308365,
      -0.007754509802907705,
      0.004114088136702776,
      -0.03360631316900253,
      -0.0370715856552124,
      0.015072936192154884,
      0.043712031096220016,
      0.010488539934158325,
      0.04311051592230797,
      -0.042557381093502045,
      0.039523281157016754,
      0.08416282385587692,
      -0.009641055017709732,
      0.11652599275112152,
      -0.014033250510692596,
      -0.026783408597111702,
      -0.012013666331768036,
      0.052454378455877304,
      -0.044606659561395645,
      -0.010594614781439304,
      -0.020062938332557678,
      0.03479500114917755,
      -0.023606443777680397,
      -0.08501148968935013,
      -0.07127943634986877,
      -0.03693060949444771,
      -0.01850311830639839,
      -0.03718249499797821,
      -0.006610022392123938,
      0.07518670707941055,
      0.049901656806468964,
      0.010689349845051765,
      0.06067716330289841,
      0.029027344658970833,
      -0.019694913178682327,
      -0.059717051684856415,
      -0.06189882010221481,
      -0.014008873142302036,
      -0.11061261594295502,
      0.01626192219555378,
      0.03675089776515961,
      0.03744148090481758,
      -0.008418726734817028,
      0.08216783404350281,
      -0.011515935882925987,
      0.02445719949901104,
      0.015112129971385002,
      0.08110123127698898,
      -0.009492631070315838,
      -0.03128322958946228,
      0.022677142173051834,
      -0.0605507493019104,
      -0.049500975757837296,
      -0.07797376066446304,
      -0.05165694281458855,
      -0.04816285893321037,
      0.040655527263879776,
      -0.055701494216918945,
      0.007318619638681412,
      0.03810558468103409,
      -0.01188318245112896,
      -0.07618418335914612,
      0.013869293965399265,
      -0.0767354741692543,
      -0.06395810842514038,
      0.012524978257715702,
      -0.09391751885414124,
      -0.04521165415644646,
      0.01095398236066103,
      0.04743785783648491,
      -0.03175204619765282,
      -0.06686031818389893,
      -0.0860648974776268,
      0.07773353159427643,
      -0.022660044953227043,
      0.013831640593707561,
      -0.10050243139266968,
      -0.02884170413017273,
      -0.03290945291519165,
      -0.03962979465723038,
      0.015960481017827988,
      -0.07055173069238663,
      -0.01955266110599041,
      0.04972273111343384,
      0.040596216917037964,
      -0.017751088365912437,
      0.01975218951702118,
      -0.056623779237270355,
      0.021890664473176003,
      0.02729158103466034,
      0.03923668712377548,
      0.028408806771039963,
      0.019911393523216248,
      -0.06316841393709183,
      -0.03869132697582245,
      0.03753048554062843,
      0.02440684288740158,
      -0.023816971108317375,
      0.03201445937156677,
      0.004555354360491037,
      -0.007538930047303438,
      -0.0052288491278886795,
      0.010369506664574146,
      0.02137508988380432,
      -0.015090914443135262,
      0.05038357153534889,
      -0.04198221489787102,
      0.04073025658726692,
      -0.08524155616760254,
      0.009295240044593811,
      0.03991508483886719,
      -0.03567364439368248,
      -0.037948571145534515,
      0.04304317757487297,
      0.006067247595638037,
      -0.030786694958806038,
      -0.07273995876312256,
      0.04116099700331688,
      -0.03698481619358063,
      0.016398783773183823,
      -0.04659396782517433,
      -0.0032729459926486015,
      0.04039228335022926,
      0.06956476718187332,
      -0.01885082572698593,
      0.08057316392660141,
      0.059062931686639786,
      -0.013307440094649792,
      0.07349643111228943,
      0.0002753239532466978,
      0.014149869792163372,
      0.06314090639352798,
      0.02411467395722866,
      0.09582716971635818,
      0.02714652195572853,
      0.029095562174916267,
      0.021954018622636795,
      -0.022106491029262543,
      -0.005189858376979828,
      0.03126292675733566,
      -0.05125376954674721,
      0.0076548014767467976,
      -0.054152440279722214,
      -0.014591297134757042,
      0.012698204256594181,
      0.05715090408921242,
      0.04676368832588196,
      -0.012281977571547031,
      0.02108539454638958,
      0.013257374987006187,
      0.06065647304058075,
      -0.0001651903585297987,
      -0.03771212324500084,
      -0.038610417395830154,
      -0.08609629422426224,
      -0.004184862598776817,
      0.02408834919333458,
      -0.04967861250042915,
      -0.09582729637622833,
      -0.05906771123409271,
      -0.00845315121114254,
      0.033880461007356644,
      -0.043355315923690796,
      0.02913516014814377,
      -0.0019857005681842566,
      0.027905762195587158,
      0.02181510254740715,
      0.03906938061118126,
      -0.022840311750769615,
      -0.08107778429985046,
      -0.03065834566950798,
      -0.051061421632766724,
      0.03628728911280632,
      -0.03254168853163719,
      0.05620644614100456,
      -0.0077705685980618,
      -0.015489978715777397,
      0.05030020698904991,
      -0.009218564257025719,
      -0.01822332851588726,
      -0.07182573527097702,
      -0.004390997346490622,
      -0.06838688999414444,
      0.035109709948301315,
      -0.00015157593588810414,
      0.04339509829878807,
      -0.04537758231163025,
      -0.0035835965536534786,
      0.0869782567024231,
      -0.009689104743301868,
      -0.02970116212964058,
      -0.016643309965729713,
      0.022966578602790833,
      0.0400356724858284,
      -0.046088043600320816,
      -0.02209397405385971,
      -0.05842602998018265,
      -0.022497626021504402,
      -0.04622609168291092,
      0.03019585832953453,
      0.00972199160605669,
      0.042988572269678116,
      -0.021411316469311714,
      0.051211170852184296,
      0.050746235996484756,
      -0.009735417552292347,
      -0.013489744625985622,
      -0.010455857962369919,
      0.040871188044548035,
      -0.06751759350299835,
      -0.012492232024669647,
      0.01920464262366295,
      -0.032093025743961334,
      0.05543944239616394,
      0.1289355456829071,
      -0.026532968506217003,
      0.008497060276567936,
      -0.027868036180734634,
      0.046730633825063705,
      -0.006388746201992035,
      -0.027849920094013214,
      0.050277963280677795,
      -0.03546872362494469,
      0.06470236927270889,
      -0.006761081516742706,
      -0.10803519189357758,
      -0.00038370679249055684,
      -0.000294459518045187,
      -0.048351727426052094,
      0.03975800424814224,
      -0.08717922121286392,
      -0.02518431283533573,
      0.06756435334682465,
      0.09955374151468277,
      0.016306208446621895,
      0.040335074067115784,
      -0.020689290016889572,
      -0.05544361099600792,
      -0.014432113617658615,
      -0.046486277133226395,
      0.03754051402211189,
      -0.005035378970205784,
      0.01003161072731018,
      -0.0525851845741272,
      -0.05855465680360794,
      -0.002201192080974579,
      0.02834734320640564,
      -0.03262903541326523,
      -0.022199690341949463,
      -0.016704704612493515,
      0.07309460639953613,
      -0.02700245939195156,
      0.038743723183870316,
      -0.02126956731081009,
      -0.06266272068023682,
      -0.009639057330787182,
      0.034025322645902634,
      -0.04280109331011772,
      -0.03725695237517357,
      -0.019961850717663765,
      0.02986028604209423,
      0.03388262540102005,
      0.07489365339279175,
      0.0009880880825221539,
      0.00484086899086833,
      0.016491321846842766,
      -0.02612334117293358,
      -0.028722427785396576,
      -0.03375564143061638,
      -0.01942422240972519,
      0.007508712355047464,
      0.011006171815097332,
      0.014359843917191029,
      0.02054898999631405,
      -0.01979167014360428,
      0.014472628943622112,
      -0.06311778724193573,
      -0.06386567652225494,
      0.022304151207208633,
      -0.027179615572094917,
      -0.03758292272686958,
      0.062100671231746674,
      0.054047100245952606,
      -0.03008021041750908,
      -0.0213988795876503,
      0.019439971074461937,
      0.011415774933993816,
      -0.033031415194272995,
      -0.08380341529846191,
      -0.02720065228641033,
      -0.07672197371721268,
      -0.006005966104567051,
      -0.03481317684054375,
      -0.00048185072955675423,
      -0.011293509043753147,
      0.006656634621322155,
      -0.0267882589250803,
      -0.016329560428857803,
      -0.05015949532389641,
      -0.03487179055809975,
      0.02585407719016075,
      -0.07839327305555344,
      -0.04544661566615105,
      0.02372855320572853,
      -0.017891356721520424,
      0.012030061334371567,
      0.0251753106713295,
      -0.005154353566467762,
      -0.021790670230984688,
      0.062355030328035355,
      0.014131022617220879,
      -0.02235342375934124,
      -0.07612894475460052,
      -0.09802155196666718,
      0.017387893050909042,
      0.0076194461435079575,
      -0.048371173441410065,
      -0.030917296186089516,
      0.024983949959278107,
      0.09316319972276688,
      -0.006567321717739105,
      0.06319744884967804,
      -0.040927473455667496,
      0.0511183999478817,
      -0.011389554478228092,
      0.02257557399570942,
      -0.01877395063638687,
      0.011544697917997837,
      -0.019819241017103195,
      -0.030786216259002686,
      -0.04707527533173561,
      0.009184963069856167,
      0.043623607605695724,
      0.016563117504119873,
      0.05030852183699608,
      -0.006450945045799017,
      -0.08259405195713043,
      0.0419631227850914,
      -0.023183424025774002,
      0.07670991122722626,
      -0.022653497755527496,
      0.09498223662376404,
      -0.018460454419255257,
      -0.07338070869445801,
      0.005006356630474329,
      0.03901347890496254,
      0.03362220898270607,
      0.024242669343948364,
      0.025033067911863327,
      -0.028690848499536514,
      0.05625306814908981,
      0.018315274268388748,
      -0.06984103471040726,
      0.04791262745857239,
      0.011846569366753101,
      -0.05315354838967323,
      0.010053491219878197,
      -0.023701781406998634,
      -0.07411537319421768,
      -0.027337606996297836,
      -0.012667285278439522,
      0.034251585602760315,
      -0.025522705167531967,
      -0.03110821172595024,
      0.04774259030818939,
      0.07380883395671844,
      -0.03412641957402229,
      -0.08073223382234573,
      0.020851634442806244,
      0.04295601695775986,
      0.05673149973154068,
      0.038100797683000565,
      -0.05441742390394211,
      0.037714846432209015,
      0.009012016467750072,
      -0.05348164960741997,
      0.024660399183630943,
      0.01891998201608658,
      2.596603189886082e-05,
      -0.09614677727222443,
      -0.07341959327459335,
      -0.04491918161511421,
      -0.0015318519435822964,
      -0.01828937791287899,
      -0.025276584550738335,
      0.036802738904953,
      0.043680496513843536,
      -0.008926513604819775,
      -0.0392049141228199,
      0.016789207234978676,
      0.023076960816979408,
      -0.003638717345893383,
      -0.03659523278474808,
      -0.06236839294433594,
      -0.023123271763324738,
      -0.03758995607495308,
      0.12230803817510605
    ],
    [
      -0.07398534566164017,
      0.004810533951967955,
      0.06728440523147583,
      -0.023656731471419334,
      -0.006371291354298592,
      -0.04877154156565666,
      -0.026583412662148476,
      -0.009041385725140572,
      0.07124265283346176,
      -0.036964382976293564,
      0.026403848081827164,
      -0.007932567037642002,
      0.027381224557757378,
      0.004993943031877279,
      0.0007249278714880347,
      -0.0482676699757576,
      -0.057000160217285156,
      -0.049147751182317734,
      -0.03149089217185974,
      0.008560862392187119,
      0.013057614676654339,
      0.03306670859456062,
      -0.05048835277557373,
      -0.01576838456094265,
      0.009613258764147758,
      0.047231525182724,
      0.012556818313896656,
      -0.010297996923327446,
      -0.007749719545245171,
      0.05268992856144905,
      0.11516112089157104,
      0.07194185256958008,
      -0.058677349239587784,
      -0.04507308080792427,
      -0.08550523966550827,
      0.04569148272275925,
      0.01857091672718525,
      0.041556548327207565,
      0.017013797536492348,
      -0.041490208357572556,
      -0.04488629847764969,
      -0.02869226597249508,
      0.005488509312272072,
      0.01631925068795681,
      0.0770021602511406,
      -0.04521186277270317,
      -0.017870649695396423,
      0.03359883278608322,
      -0.04435928910970688,
      0.004534388426691294,
      0.10013304650783539,
      -0.021128494292497635,
      0.0421832837164402,
      -0.042226117104291916,
      0.010652056895196438,
      0.05483334884047508,
      0.03549475595355034,
      -0.02919432893395424,
      -0.06912236660718918,
      0.028395025059580803,
      -0.00015724361583124846,
      0.0004679159901570529,
      -0.013758525252342224,
      0.07610474526882172,
      -0.08174747973680496,
      0.03891252353787422,
      0.06290140748023987,
      0.015249102376401424,
      0.025395317003130913,
      -0.036473598331213,
      0.0703929215669632,
      0.03011968731880188,
      -0.03867607191205025,
      -0.03578127548098564,
      -0.022395189851522446,
      -0.01460413821041584,
      0.058556392788887024,
      0.03431485593318939,
      -0.0033205144573003054,
      -0.04676874354481697,
      0.04659954085946083,
      0.07063964009284973,
      0.02758040837943554,
      0.07454139739274979,
      -0.06785907596349716,
      0.03320086747407913,
      -0.07233771681785583,
      -0.020155951380729675,
      -0.07432187348604202,
      0.006950865499675274,
      -0.022343479096889496,
      0.022073473781347275,
      0.0004064998065587133,
      -0.050951939076185226,
      0.02281654253602028,
      -0.003976969048380852,
      0.0071480548940598965,
      -0.04179942607879639,
      0.14403262734413147,
      -0.029158983379602432,
      -0.0215167049318552,
      0.040036674588918686,
      -0.012493593618273735,
      -0.019987687468528748,
      0.06012894585728645,
      -0.038454096764326096,
      -0.005196157842874527,
      -0.06507764756679535,
      0.057016849517822266,
      0.027388891205191612,
      -0.08671627938747406,
      0.028506789356470108,
      0.04797111824154854,
      0.030020233243703842,
      0.011346043087542057,
      0.01829766482114792,
      0.006573999300599098,
      0.03702295571565628,
      0.013878623023629189,
      -0.05339973792433739,
      -0.0393933542072773,
      0.026008648797869682,
      -0.02432258240878582,
      0.02210228145122528,
      0.04637520760297775,
      -0.0028834722470492125,
      -0.021764738485217094,
      0.07699350267648697,
      0.005324986297637224,
      -0.05003584548830986,
      0.026791507378220558,
      0.030974019318819046,
      0.009469781070947647,
      -0.02110726200044155,
      0.016827261075377464,
      -0.08691766113042831,
      -0.052390191704034805,
      0.05230369791388512,
      0.01686626486480236,
      -0.005562357138842344,
      -0.05021210387349129,
      0.03882116824388504,
      -0.04279618337750435,
      0.023907436057925224,
      -0.08230671286582947,
      -0.04898058623075485,
      -0.009057668969035149,
      -0.0017282124608755112,
      0.013308295980095863,
      -0.03104388155043125,
      -0.02621033601462841,
      0.0550244003534317,
      0.009901490062475204,
      0.035455767065286636,
      -0.07465285062789917,
      -0.13084734976291656,
      0.04572419449687004,
      -0.020323578268289566,
      -0.064473956823349,
      0.022760575637221336,
      0.05114743113517761,
      0.020219018682837486,
      0.018763797357678413,
      -0.05045075714588165,
      -0.0009254721808247268,
      -0.014892689883708954,
      0.0789714828133583,
      -0.07076413184404373,
      0.05370059236884117,
      0.044975362718105316,
      -0.007964137941598892,
      -0.03473389148712158,
      -0.00726287392899394,
      0.0382072813808918,
      0.017540201544761658,
      0.04388894513249397,
      -0.026236549019813538,
      0.005614158231765032,
      -0.025279797613620758,
      -0.0012248195707798004,
      -0.0016525080427527428,
      -0.06039974093437195,
      -0.07288673520088196,
      -0.07849649339914322,
      -0.0133818369358778,
      0.03403010964393616,
      -0.02798323705792427,
      -0.013876154087483883,
      -0.0325973778963089,
      0.0849335566163063,
      -0.02431790716946125,
      0.07510150969028473,
      -0.055633071810007095,
      0.0355558879673481,
      -0.06627652049064636,
      0.09082397818565369,
      0.0018225011881440878,
      0.024542447179555893,
      -0.009473574347794056,
      0.007868715561926365,
      -0.016926050186157227,
      0.04741892218589783,
      0.0012901730369776487,
      -0.004444096703082323,
      0.0031219872180372477,
      -0.036163657903671265,
      -0.05239683389663696,
      0.11738162487745285,
      0.050779469311237335,
      -0.02793390117585659,
      0.015446784906089306,
      -0.04931200295686722,
      -0.013828306458890438,
      0.04501720145344734,
      0.028155984356999397,
      -0.0012535271234810352,
      0.10522956401109695,
      -0.0022096142638474703,
      -0.10714801400899887,
      0.0030634028371423483,
      0.045022837817668915,
      -0.036182500422000885,
      0.04594080522656441,
      0.038050513714551926,
      -0.027812663465738297,
      0.04028119891881943,
      0.015168254263699055,
      0.02720293402671814,
      0.032698363065719604,
      0.08769354224205017,
      0.10315895825624466,
      0.058774616569280624,
      -0.023673245683312416,
      -0.009269608184695244,
      0.033664070069789886,
      -0.0820871964097023,
      0.01874474063515663,
      0.021960722282528877,
      -0.007958953268826008,
      0.03455156460404396,
      -0.010351560078561306,
      0.053565509617328644,
      0.07917740195989609,
      0.01458287239074707,
      0.014707077294588089,
      -0.03369932249188423,
      -0.002847051015123725,
      0.004677807912230492,
      -0.01947956345975399,
      0.059262316673994064,
      0.023053240031003952,
      -0.05718941241502762,
      -0.016579002141952515,
      -0.014340635389089584,
      0.0026416536420583725,
      -0.007721632719039917,
      0.034067314118146896,
      0.031256772577762604,
      -0.012360851280391216,
      0.0725148543715477,
      -0.03039553388953209,
      0.0009975078282877803,
      0.02441677451133728,
      -0.036792460829019547,
      0.006995583884418011,
      0.027071034535765648,
      0.0022447663359344006,
      -0.03283534571528435,
      0.007406295742839575,
      -0.00465497886762023,
      -0.03548068180680275,
      0.0015473553212359548,
      -0.008699554949998856,
      0.0013030740665271878,
      -0.05783076584339142,
      0.07602881640195847,
      0.023798339068889618,
      -0.020985964685678482,
      -0.01738825812935829,
      -0.06673689931631088,
      0.08706943690776825,
      -0.01875690557062626,
      -0.04193228483200073,
      -0.036986466497182846,
      0.009783017449080944,
      -0.030886471271514893,
      0.008804824203252792,
      0.03574611246585846,
      -0.03869659826159477,
      0.05476638302206993,
      -0.05901259183883667,
      0.023320967331528664,
      -0.039583589881658554,
      -0.048217516392469406,
      -0.06577964872121811,
      -0.03941085934638977,
      -0.02299722656607628,
      0.006822062190622091,
      -0.0033550646621733904,
      0.0784713625907898,
      -0.06122926250100136,
      -0.00823911838233471,
      -0.07588483393192291,
      -0.04441041871905327,
      -0.01717791147530079,
      0.06752113252878189,
      -0.004393818322569132,
      0.03454510495066643,
      -0.03098699264228344,
      -0.04010038822889328,
      0.07470262795686722,
      -0.020569724962115288,
      -0.04629252105951309,
      -0.011841829866170883,
      -0.03192399442195892,
      0.009314251132309437,
      0.009387512691318989,
      -0.023915942758321762,
      0.008122294209897518,
      0.04027996212244034,
      0.015271640382707119,
      -0.003067771904170513,
      -0.0083540054038167,
      0.04829280078411102,
      0.0071019744500517845,
      -0.06537764519453049,
      0.027723971754312515,
      -0.04056490957736969,
      0.10522997379302979,
      0.017917655408382416,
      0.01785384677350521,
      0.048558495938777924,
      0.08171985298395157,
      -0.04247971624135971,
      0.022341247648000717,
      -0.00010684981680242345,
      -0.013007174246013165,
      -0.01857324130833149,
      -0.020725125446915627,
      0.01509029883891344,
      -0.049282342195510864,
      -0.01552350353449583,
      -0.05394550412893295,
      0.06435845792293549,
      0.04690278694033623,
      -0.03544933348894119,
      -0.0011155845131725073,
      -0.08630530536174774,
      0.06613790988922119,
      -0.0004945856053382158,
      0.021550919860601425,
      -0.09255026280879974,
      -0.01732654683291912,
      -0.01713959500193596,
      -0.030869852751493454,
      -0.02986300364136696,
      0.01180549431592226,
      0.011463448405265808,
      -0.0612783320248127,
      0.06187184154987335,
      -0.007613628637045622,
      -0.04983658343553543,
      0.040091436356306076,
      0.04065980389714241,
      0.0880095586180687,
      0.027428453788161278,
      0.09231820702552795,
      -0.037129029631614685,
      -0.01388067938387394,
      0.006563945207744837,
      -0.028026320040225983,
      0.014611814171075821,
      0.04866233840584755,
      -0.0031517641618847847,
      -0.02079259417951107,
      -0.14298976957798004,
      0.09168180078268051,
      0.0008044001297093928,
      0.027003416791558266,
      -0.023481497541069984,
      0.07100383937358856,
      -0.021897828206419945,
      0.033078063279390335,
      -0.06312810629606247,
      -0.04789206385612488,
      0.040880974382162094,
      0.02466387115418911,
      0.012603995390236378,
      0.05293460562825203,
      -0.05893098562955856,
      0.021845152601599693,
      0.03237709775567055,
      -0.06830126792192459,
      0.0050488621927797794,
      0.06198769062757492,
      -0.06624385714530945,
      0.09515110403299332,
      -0.06000583991408348,
      0.03820337727665901,
      -0.017511440441012383,
      -0.006040351931005716,
      -0.03479110822081566,
      -0.034600935876369476,
      0.015671182423830032,
      0.0641513466835022,
      -0.042238615453243256,
      -0.011644103564321995,
      -0.03833278268575668,
      -0.018704863265156746,
      -0.042697347700595856,
      0.07813858240842819,
      -0.04709065705537796,
      0.07113629579544067,
      -0.03588997945189476,
      -0.027006424963474274,
      -0.02855309285223484,
      -0.024104079231619835,
      0.05517158657312393,
      -0.004588381852954626,
      -0.013118254020810127,
      -0.03219914436340332,
      0.07783990353345871,
      0.032880235463380814,
      -0.0568154901266098,
      0.04182460159063339,
      -0.14283539354801178,
      0.012840408831834793,
      0.05151578411459923,
      -0.06615807861089706,
      0.049830928444862366,
      0.029918599873781204,
      -0.022233353927731514,
      0.02543765679001808,
      0.004943647887557745,
      -0.013709601946175098,
      -0.04929909110069275,
      -0.06752576678991318,
      0.05264226719737053,
      0.08245019614696503,
      0.06319019198417664,
      -0.005402826704084873,
      -0.03021243028342724,
      0.06836172938346863,
      -0.06010540574789047,
      -0.022052599117159843,
      0.028355129063129425,
      0.0632118210196495,
      -0.04169023036956787,
      0.054418038576841354,
      0.05501261726021767,
      -0.076533742249012,
      -0.04882796108722687,
      0.03507152199745178,
      -0.020687295123934746,
      0.05774982273578644,
      -0.07053365558385849,
      -0.009205249138176441,
      -0.010808625258505344,
      -0.015828164294362068,
      -0.06183348223567009,
      -0.08275012671947479,
      -0.007266152184456587,
      0.056491054594516754,
      -0.019277041777968407,
      -0.004643372260034084,
      0.02431092970073223,
      0.03289242461323738,
      -0.009652755223214626,
      -0.015485464595258236,
      -0.06960909068584442,
      0.13874687254428864,
      -0.0032991021871566772,
      0.03177962824702263,
      0.03344966098666191,
      0.08679414540529251,
      0.027864674106240273,
      -0.0021521085873246193,
      -0.03552275151014328,
      -0.01993686519563198,
      -2.348595990042668e-05,
      -0.10828513652086258,
      0.032992277294397354,
      0.014898701570928097,
      -0.046054042875766754,
      0.11409752070903778,
      0.03465171530842781,
      -0.031723812222480774,
      0.00025691778864711523,
      -0.03631702810525894,
      0.028422921895980835,
      0.02938125655055046,
      0.0019661341793835163,
      0.05233761668205261,
      0.076658234000206,
      0.010169202461838722,
      0.04637464880943298,
      -0.019904570654034615,
      0.010113832540810108,
      -0.021696336567401886,
      -0.028588848188519478,
      0.07212256640195847,
      0.00312557234428823,
      0.04558543115854263
    ],
    [
      -0.028105013072490692,
      0.05909202620387077,
      0.06980692595243454,
      0.04820547252893448,
      0.09592248499393463,
      0.03809399902820587,
      0.05605325102806091,
      0.017669422551989555,
      0.033015888184309006,
      0.06439367681741714,
      -0.030341094359755516,
      -0.04074682295322418,
      0.08031245321035385,
      -0.00192351418081671,
      -0.08459080755710602,
      0.046172846108675,
      -0.054896362125873566,
      -0.03766865283250809,
      -0.023401468992233276,
      -0.022072482854127884,
      0.03738637641072273,
      -0.04727252200245857,
      -0.009987102821469307,
      0.07040219008922577,
      0.010019933804869652,
      -0.08997663855552673,
      -0.03506666049361229,
      -0.001104970695450902,
      -0.007365919183939695,
      -0.00811458844691515,
      -0.0055431220680475235,
      0.05345377326011658,
      -0.060329049825668335,
      0.02743474394083023,
      -0.061605241149663925,
      -0.01958303153514862,
      -0.018579823896288872,
      0.04643421992659569,
      0.03595513850450516,
      -0.013065087608993053,
      0.006873099599033594,
      0.09695880860090256,
      0.006933698430657387,
      0.01633325405418873,
      0.0693175196647644,
      0.011251275427639484,
      0.01516704261302948,
      -0.044016215950250626,
      -0.027553709223866463,
      0.006614147685468197,
      -0.027469560503959656,
      -0.039667438715696335,
      0.07141157239675522,
      -0.020283790305256844,
      -0.001400944427587092,
      0.035285141319036484,
      -0.07704128324985504,
      -0.05095720663666725,
      0.04135192185640335,
      0.06909143179655075,
      -0.05919265002012253,
      0.003650168189778924,
      0.009918094612658024,
      0.08117203414440155,
      0.006759605836123228,
      0.0026782264467328787,
      0.05068531632423401,
      0.018662188202142715,
      -0.04867646098136902,
      -0.01614891178905964,
      0.026000287383794785,
      0.025676261633634567,
      -0.006318502128124237,
      0.027890630066394806,
      0.035600196570158005,
      0.05115307494997978,
      0.04463767632842064,
      0.07060888409614563,
      -0.028190160170197487,
      -0.0020718700252473354,
      -0.006721175741404295,
      0.12586168944835663,
      0.016610821709036827,
      0.0627480000257492,
      0.03853894770145416,
      -0.018011918291449547,
      -0.03184245154261589,
      0.052036017179489136,
      0.01937062293291092,
      -0.0024523846805095673,
      -0.0811232402920723,
      -0.023819848895072937,
      0.047485239803791046,
      0.012677686288952827,
      -0.01414011511951685,
      0.06249968707561493,
      -0.02587234601378441,
      0.050029605627059937,
      0.04412420466542244,
      -0.05382537469267845,
      -0.10841462761163712,
      -0.047875773161649704,
      -0.07103679329156876,
      0.010114332661032677,
      -0.05353211984038353,
      0.004709591623395681,
      0.02717316895723343,
      0.06456032395362854,
      0.03753466531634331,
      -0.018015215173363686,
      -0.04190769046545029,
      0.021857962012290955,
      -0.0016230870969593525,
      -0.045608535408973694,
      -0.009036106988787651,
      0.026616660878062248,
      0.002863083267584443,
      0.021256186068058014,
      -0.00740079628303647,
      0.007388655561953783,
      -0.01765700988471508,
      0.028856921941041946,
      0.0044528646394610405,
      0.0687994733452797,
      -0.014827939681708813,
      0.013918180949985981,
      0.004620234947651625,
      0.06122316047549248,
      0.004642474465072155,
      0.04627365991473198,
      -0.1008363887667656,
      0.006510112434625626,
      -0.02884320728480816,
      0.039760660380125046,
      -0.0814656913280487,
      0.08546457439661026,
      -0.006168264430016279,
      -0.011871963739395142,
      -0.04398271068930626,
      -0.07746012508869171,
      -0.0029325701761990786,
      0.020107105374336243,
      -0.0075536444783210754,
      -0.13417193293571472,
      -0.015575258992612362,
      0.0018949784571304917,
      0.07339997589588165,
      -0.024215063080191612,
      -0.027179956436157227,
      -0.03447256609797478,
      0.02832375094294548,
      0.02411600574851036,
      -0.025337079539895058,
      -0.012851380743086338,
      0.006881867069751024,
      -0.04419194534420967,
      0.05945349112153053,
      0.008690946735441685,
      -0.008740438148379326,
      -0.03870166465640068,
      -0.08540540188550949,
      -0.03689923882484436,
      -0.03226064518094063,
      -0.03936938941478729,
      -0.04256793484091759,
      0.004612538497895002,
      0.06180668622255325,
      0.04114025458693504,
      0.02092556282877922,
      -0.03243769705295563,
      0.043412402272224426,
      -0.1271161586046219,
      -0.0535111241042614,
      0.052199505269527435,
      0.042999520897865295,
      -0.029919695109128952,
      0.04170267656445503,
      -0.04595077410340309,
      -0.03738116845488548,
      0.053246673196554184,
      -0.051971107721328735,
      -0.040043435990810394,
      0.002486355835571885,
      -0.04870191588997841,
      -0.021732665598392487,
      -0.002761554205790162,
      -0.0027178418822586536,
      -0.06919597089290619,
      -0.031800612807273865,
      0.09469190239906311,
      0.014021728187799454,
      -0.0010949964635074139,
      0.004428194370120764,
      -0.009661980904638767,
      -0.06671557575464249,
      0.01022725086659193,
      -0.040199343115091324,
      -0.0030623378697782755,
      0.0014601994771510363,
      0.050260961055755615,
      0.04323141276836395,
      -0.06383293122053146,
      0.03582482039928436,
      0.02494026906788349,
      0.0006318698870018125,
      0.031865447759628296,
      -0.054595526307821274,
      0.03556591644883156,
      -0.09070950746536255,
      0.0227104090154171,
      0.020587963983416557,
      0.005158929619938135,
      0.046527501195669174,
      0.024140765890479088,
      -0.006316967308521271,
      0.040423374623060226,
      -0.013147951103746891,
      -0.0168900266289711,
      -0.025818584486842155,
      -0.046606551855802536,
      -0.03440927341580391,
      -0.02166731096804142,
      -0.02193470671772957,
      0.022798284888267517,
      -0.024054212495684624,
      -0.033633824437856674,
      0.0060537438839674,
      -0.0009167124517261982,
      0.06629030406475067,
      -0.033290546387434006,
      -0.045142579823732376,
      0.031917255371809006,
      -0.06944284588098526,
      0.03371652215719223,
      0.04101653769612312,
      -0.016409236937761307,
      0.005147266201674938,
      -0.028539737686514854,
      -0.020794110372662544,
      -0.03338104858994484,
      -0.04904736578464508,
      0.006784842349588871,
      0.07659441232681274,
      0.012624463997781277,
      0.014107460156083107,
      0.08388838171958923,
      0.09221228957176208,
      -0.04072090610861778,
      -0.04159325733780861,
      -0.0496394969522953,
      0.05193012207746506,
      0.026751916855573654,
      -0.014115547761321068,
      0.043019719421863556,
      -0.0005656859721057117,
      -0.0037005823105573654,
      0.08210325241088867,
      -0.05785128101706505,
      0.020361950621008873,
      0.038897931575775146,
      -0.011490218341350555,
      -0.03359227254986763,
      -0.005310467444360256,
      -0.021969040855765343,
      0.007113988045603037,
      -0.05886991694569588,
      0.013988782651722431,
      0.06513959914445877,
      0.04299801588058472,
      0.04818212613463402,
      -0.02053612284362316,
      0.038718972355127335,
      -0.033307790756225586,
      0.04944572225213051,
      0.04229874908924103,
      -0.0003050961240660399,
      -0.010867748409509659,
      0.007680559065192938,
      0.06408577412366867,
      -0.043150581419467926,
      0.027185307815670967,
      0.12121368199586868,
      -0.02860010415315628,
      -0.006288764998316765,
      -0.05874074622988701,
      0.1455804407596588,
      -0.1296362280845642,
      0.018482225015759468,
      0.029631074517965317,
      0.046718496829271317,
      -0.06031794473528862,
      0.08632851392030716,
      -0.03841286897659302,
      -0.016912102699279785,
      0.029066162183880806,
      0.050817299634218216,
      0.06822894513607025,
      0.006268622819334269,
      -8.117876859614626e-07,
      0.09135347604751587,
      0.007329392246901989,
      0.015872929245233536,
      -0.0170331671833992,
      -0.02772260084748268,
      0.044486526399850845,
      -0.07055632770061493,
      0.014875110238790512,
      0.04309692978858948,
      -0.05215832218527794,
      -0.03194182366132736,
      0.029963012784719467,
      0.011826450936496258,
      0.005676061846315861,
      -0.047906845808029175,
      0.036361631006002426,
      0.025220457464456558,
      -0.05919458717107773,
      0.02959159016609192,
      -0.05885994806885719,
      -0.014168835245072842,
      -0.03925632685422897,
      -0.008061341010034084,
      0.08546478301286697,
      0.1045609787106514,
      -0.011659592390060425,
      0.00893946923315525,
      -0.023406922817230225,
      -0.03469160571694374,
      0.167998805642128,
      0.019623322412371635,
      -0.037528976798057556,
      0.009114967659115791,
      0.053192153573036194,
      0.05094802752137184,
      -0.014374963939189911,
      0.009536906145513058,
      0.010285664349794388,
      -0.056543681770563126,
      0.05760915204882622,
      -0.0031739650294184685,
      -0.03645346686244011,
      -0.0021731865126639605,
      0.019572142511606216,
      -0.06383274495601654,
      -0.012812274508178234,
      0.0449342355132103,
      0.05326765403151512,
      -0.053767379373311996,
      -0.01734171248972416,
      0.08702624589204788,
      0.003600524505600333,
      -0.03996521979570389,
      0.014131388626992702,
      -0.008534370921552181,
      -0.031640369445085526,
      0.010801391676068306,
      0.051207371056079865,
      0.04809879511594772,
      0.08441761881113052,
      0.018115626648068428,
      -0.05525628849864006,
      0.04734635725617409,
      0.035273514688014984,
      -0.016561806201934814,
      0.05431877821683884,
      -0.07294666022062302,
      0.060392867773771286,
      -0.009054496884346008,
      -0.08548547327518463,
      -0.06887364387512207,
      0.015943964943289757,
      0.039074692875146866,
      0.05146387219429016,
      -0.05640106275677681,
      0.05662717670202255,
      -0.004617499653249979,
      0.01910797879099846,
      0.007324903272092342,
      0.06433895975351334,
      0.007872819900512695,
      -0.007996343076229095,
      -0.02024502493441105,
      -0.04363224655389786,
      0.048646342009305954,
      0.0050427536480128765,
      0.001628235331736505,
      0.01393094751983881,
      0.04600348323583603,
      -0.05800562724471092,
      -0.029677849262952805,
      -0.05625622346997261,
      0.04780472815036774,
      0.004233937244862318,
      0.044768325984478,
      0.02609705552458763,
      -0.08084287494421005,
      0.013586599379777908,
      0.07700487226247787,
      -0.0411166213452816,
      -0.08051587641239166,
      -0.05829364433884621,
      -0.007805738132447004,
      0.027043433859944344,
      0.014012906700372696,
      0.05747208371758461,
      -0.03581831604242325,
      -0.018782921135425568,
      0.016698801890015602,
      0.04695526137948036,
      0.030352966859936714,
      0.09303725510835648,
      -0.030537642538547516,
      -0.025457225739955902,
      -0.03723245486617088,
      0.028351832181215286,
      0.007529372815042734,
      -0.07889997214078903,
      0.0422036238014698,
      0.007736631669104099,
      -0.025846417993307114,
      0.00873669795691967,
      -0.02622785046696663,
      -0.04985838383436203,
      -0.07353495061397552,
      -0.04864799976348877,
      0.03054681047797203,
      0.042840179055929184,
      0.053874991834163666,
      -0.02981266751885414,
      0.04195811599493027,
      -0.0432153083384037,
      -0.07114483416080475,
      0.03159662336111069,
      -0.040727876126766205,
      -0.06468413770198822,
      0.029212359338998795,
      -0.015901979058980942,
      -0.015099232085049152,
      0.00967364851385355,
      0.00937753263860941,
      0.05825958028435707,
      -0.023714061826467514,
      0.007099636364728212,
      0.04353061690926552,
      -0.007262577302753925,
      0.0947098582983017,
      0.024559179320931435,
      0.09480533003807068,
      0.009930365718901157,
      0.020072702318429947,
      -0.03742094337940216,
      0.024089369922876358,
      0.013380861841142178,
      -0.047793127596378326,
      0.047764360904693604,
      0.01568726822733879,
      -0.11566602438688278,
      0.10875257849693298,
      -0.005925075151026249,
      -0.009577089920639992,
      -0.06523372232913971,
      0.013392203487455845,
      -0.018221981823444366,
      -0.09509812295436859,
      -0.029129521921277046,
      -0.026420358568429947,
      0.008191240020096302,
      -0.046861451119184494,
      -0.012937179766595364,
      -0.00911120418459177,
      0.019918637350201607,
      -0.037066880613565445,
      -0.08518234640359879,
      -0.04279666766524315,
      -0.05042118951678276,
      0.04260627180337906,
      -0.051420312374830246,
      0.00016431625408586115,
      0.009704165160655975,
      -0.028092453256249428,
      0.041636206209659576,
      -0.014330270700156689,
      0.07379496842622757,
      0.0010080995270982385,
      -0.019550194963812828,
      0.023482168093323708,
      -0.016009053215384483,
      -0.008804076351225376,
      0.0066415369510650635,
      -0.018833423033356667,
      0.0520336851477623,
      0.01838998682796955,
      0.017343297600746155,
      0.04479588568210602,
      0.025501392781734467,
      -0.04401625320315361,
      0.04131060466170311,
      0.004080358427017927,
      -0.04704761132597923,
      -0.06441058218479156,
      0.057781606912612915,
      -0.03038046322762966,
      -0.021352877840399742
    ],
    [
      0.003844343824312091,
      -0.01056940108537674,
      -0.0011910962639376521,
      0.02902294509112835,
      -0.007292408030480146,
      0.09030066430568695,
      -0.04808765649795532,
      -0.05362540856003761,
      -0.08096061646938324,
      0.040045008063316345,
      0.09808354824781418,
      -0.08159025758504868,
      -0.04035790264606476,
      -0.02629920281469822,
      0.04145368933677673,
      0.03274427726864815,
      -0.023620525375008583,
      0.041453782469034195,
      -0.020947782322764397,
      0.03329489380121231,
      0.069791279733181,
      -0.04646749049425125,
      -0.0036792161408811808,
      0.027575818821787834,
      0.04274987429380417,
      -0.031407568603754044,
      -0.01972348988056183,
      0.10475602746009827,
      -0.048665858805179596,
      0.0020559635013341904,
      -0.058640435338020325,
      -0.04146801680326462,
      0.053742192685604095,
      -0.08634985238313675,
      -0.0667298287153244,
      -0.05752968043088913,
      -0.03637363389134407,
      -0.026757344603538513,
      -0.006372653879225254,
      0.02836783044040203,
      -0.02395276352763176,
      -0.08322972804307938,
      -0.04360819607973099,
      -0.01894311234354973,
      0.0003178424958605319,
      -0.05044187977910042,
      0.021254153922200203,
      -0.0133599191904068,
      -0.05346475914120674,
      -0.014069697819650173,
      -0.04309698939323425,
      -0.055477991700172424,
      0.04748089239001274,
      0.043796028941869736,
      -0.0446801632642746,
      -0.032935429364442825,
      -0.034527312964200974,
      -0.03054284118115902,
      0.06188717857003212,
      -0.018190396949648857,
      0.031534891575574875,
      -0.06485692411661148,
      -0.02005467377603054,
      -0.10166305303573608,
      0.05596190318465233,
      -0.042170166969299316,
      -0.014432498253881931,
      0.05251165106892586,
      -0.051701419055461884,
      -0.03925951570272446,
      -0.03749413043260574,
      0.017875945195555687,
      -0.05613929405808449,
      0.08250544965267181,
      -0.027796011418104172,
      -0.022937536239624023,
      0.04362872615456581,
      -0.017356907948851585,
      -0.09340636432170868,
      -0.053408510982990265,
      0.027259891852736473,
      0.04931439831852913,
      0.0021389287430793047,
      -0.0920037254691124,
      -0.039996691048145294,
      -0.024273836985230446,
      -0.06146314740180969,
      0.05423717573285103,
      -0.022253936156630516,
      0.08368882536888123,
      0.05052810534834862,
      -0.0649617612361908,
      0.09751594811677933,
      -0.0462033785879612,
      -0.051257532089948654,
      0.060690175741910934,
      0.0756864920258522,
      -0.00952368788421154,
      0.034525975584983826,
      -0.04935891926288605,
      -0.01162602286785841,
      -0.0629664957523346,
      -0.03633911907672882,
      -0.046879108995199203,
      0.06396156549453735,
      0.03551898151636124,
      0.015128979459404945,
      -0.01356918178498745,
      0.07913946360349655,
      -0.0013279370032250881,
      -0.05749235302209854,
      -0.08633874356746674,
      -0.011485541239380836,
      -0.03148822858929634,
      -0.034094277769327164,
      -0.038107626140117645,
      -0.06111276522278786,
      0.06841674447059631,
      -0.020332660526037216,
      -0.011143935844302177,
      0.07000566273927689,
      -0.025624005123972893,
      -0.10558831691741943,
      0.026183925569057465,
      -0.042625315487384796,
      -0.0660116896033287,
      0.01781333051621914,
      0.034679755568504333,
      0.00978938676416874,
      0.005730858072638512,
      -0.03890512138605118,
      -0.06217193603515625,
      3.1800671422388405e-05,
      0.04821997880935669,
      -0.025369159877300262,
      0.031010542064905167,
      -0.021471742540597916,
      -0.0114752147346735,
      -0.011260461062192917,
      0.0176100991666317,
      0.01660591922700405,
      0.06925812363624573,
      -0.015332549810409546,
      -0.025610940530896187,
      0.0064131165854632854,
      0.007254632189869881,
      -0.003052010666579008,
      0.011190442368388176,
      0.033040981739759445,
      0.046997107565402985,
      0.0821741372346878,
      -0.06067979708313942,
      0.010364042595028877,
      -0.011313742958009243,
      0.00924869254231453,
      0.04704925790429115,
      -0.0330825112760067,
      -0.023240387439727783,
      -0.008813747204840183,
      0.03561306744813919,
      0.017725083976984024,
      -0.015736017376184464,
      -0.035092052072286606,
      -0.0629696249961853,
      -0.009935013018548489,
      -0.0111959557980299,
      -0.015162735246121883,
      -0.026795433834195137,
      -0.065363310277462,
      -0.021326081827282906,
      -0.07933612912893295,
      0.005335901863873005,
      0.02723352611064911,
      -0.07520510256290436,
      0.07504855841398239,
      -0.03034280799329281,
      0.005791750270873308,
      0.011140620335936546,
      -0.011528480798006058,
      0.05986659228801727,
      -0.08194559812545776,
      -0.0967603549361229,
      0.014660712331533432,
      0.042459867894649506,
      -0.07555732131004333,
      -0.10154438763856888,
      0.09917602688074112,
      -0.001708009745925665,
      0.043626606464385986,
      -0.041169438511133194,
      0.014724169857800007,
      0.03717603161931038,
      0.003438082989305258,
      0.030880790203809738,
      0.010626763105392456,
      -0.008466464467346668,
      0.04659043624997139,
      -0.07838957756757736,
      -0.0898158922791481,
      0.08808212727308273,
      -0.015304512344300747,
      0.016390107572078705,
      0.053519461303949356,
      0.058330241590738297,
      -0.0034753482323139906,
      -0.06205065920948982,
      0.010342925786972046,
      -0.050551239401102066,
      0.003333039814606309,
      0.017778681591153145,
      -0.06751703470945358,
      0.004903067834675312,
      -0.030001334846019745,
      0.05058202147483826,
      -0.020682314410805702,
      -0.00619569281116128,
      -0.038733914494514465,
      0.03532359376549721,
      0.0345754474401474,
      0.0949428603053093,
      0.045960087329149246,
      -0.006728746462613344,
      -0.011483910493552685,
      0.02023310400545597,
      -0.033030420541763306,
      0.014379961416125298,
      -0.010338963009417057,
      -0.029715826734900475,
      -0.0057257493026554585,
      -0.07600224763154984,
      -0.014691388234496117,
      0.013395135290920734,
      0.005218216683715582,
      -0.04791003093123436,
      -0.027555426582694054,
      -0.01720411702990532,
      0.02293437160551548,
      -0.05574622005224228,
      -0.01748897321522236,
      -0.07252685725688934,
      -0.03707144781947136,
      0.06450704485177994,
      -0.005014468915760517,
      -0.03595627099275589,
      -0.01600683480501175,
      0.04047461599111557,
      -0.0252747293561697,
      0.03883877396583557,
      0.05350873991847038,
      -0.03591104596853256,
      -0.08011827617883682,
      0.05517487972974777,
      0.0216286089271307,
      0.00084830162813887,
      0.023778989911079407,
      -0.0888691321015358,
      0.01260063424706459,
      -0.04536810517311096,
      0.029044074937701225,
      -0.03461607173085213,
      -0.03211454674601555,
      -0.015192875638604164,
      -0.000313430093228817,
      0.027366960421204567,
      0.00596575066447258,
      0.007777170278131962,
      -0.015870872884988785,
      -0.02230115421116352,
      0.010174957104027271,
      -0.03890576958656311,
      -0.007717549800872803,
      0.032171111553907394,
      -0.005549520719796419,
      -0.018482431769371033,
      0.008619881235063076,
      0.13448187708854675,
      0.02937624417245388,
      -0.06856592744588852,
      0.09098096191883087,
      0.021226011216640472,
      -0.032517578452825546,
      0.045752864331007004,
      -0.012317722663283348,
      0.003889535553753376,
      0.0073350705206394196,
      -0.04174313321709633,
      -0.15029402077198029,
      -0.00603548064827919,
      -0.04431401938199997,
      -0.02609473280608654,
      -0.04126642644405365,
      -0.03494800254702568,
      -0.07549044489860535,
      -0.011650110594928265,
      0.06850443035364151,
      0.03442934900522232,
      0.03139759600162506,
      0.037657201290130615,
      0.03340715169906616,
      0.016068018972873688,
      -0.02016911655664444,
      0.017801271751523018,
      -0.021451149135828018,
      0.009580045938491821,
      -0.016477232798933983,
      0.015048982575535774,
      0.09836697578430176,
      -0.06569817662239075,
      0.029926957562565804,
      0.026691017672419548,
      0.022631773725152016,
      0.021488595753908157,
      0.0524069108068943,
      0.010074658319354057,
      -0.02833356335759163,
      0.04970831051468849,
      0.00685458118095994,
      0.06378180533647537,
      0.06909395754337311,
      0.015189345926046371,
      0.009448456577956676,
      0.03411330282688141,
      0.03301815688610077,
      0.02392938919365406,
      -0.06750306487083435,
      0.04497338458895683,
      0.0986768826842308,
      -0.06685111671686172,
      -0.0831025242805481,
      0.038869284093379974,
      -0.007760573644191027,
      0.003211617935448885,
      0.010010527446866035,
      -0.006770089268684387,
      0.04357392340898514,
      0.04079797491431236,
      0.012074202299118042,
      -0.0006915408303029835,
      0.11303701996803284,
      -0.020655643194913864,
      0.0041548339650034904,
      0.0005268000531941652,
      0.0607580691576004,
      -0.001175242941826582,
      0.01679287664592266,
      0.028112616389989853,
      -0.05391017347574234,
      -0.020128773525357246,
      -0.08653408288955688,
      0.016895312815904617,
      -0.09990701824426651,
      0.020325180143117905,
      -0.01195892971009016,
      0.014267347753047943,
      -0.03898925706744194,
      -0.028739677742123604,
      0.031653810292482376,
      0.062003977596759796,
      -0.010409081354737282,
      -0.013541343621909618,
      -0.007633034605532885,
      0.052543479949235916,
      0.06343474984169006,
      0.06391719728708267,
      0.030281392857432365,
      -0.056012917309999466,
      -0.017832959070801735,
      -0.01436866819858551,
      -0.05561546981334686,
      0.007054882124066353,
      0.10758733749389648,
      -0.04846717044711113,
      -0.03458762913942337,
      -0.0522150956094265,
      0.053562261164188385,
      -0.08824311196804047,
      0.01304195262491703,
      0.042971089482307434,
      -0.04483560845255852,
      -0.029710672795772552,
      -0.027943860739469528,
      -0.08742156624794006,
      0.0243685245513916,
      0.011427790857851505,
      -0.019445044919848442,
      -0.01076465379446745,
      -0.023361539468169212,
      -0.03334331884980202,
      -0.048096660524606705,
      0.0019078354816883802,
      0.004642816260457039,
      -0.04655773937702179,
      -0.003750456962734461,
      0.08024446666240692,
      -0.03859759867191315,
      -0.01866881735622883,
      0.010630320757627487,
      -0.008725772611796856,
      -0.013750814832746983,
      0.01873738318681717,
      -0.038831062614917755,
      -0.047277502715587616,
      -0.0009393186192028224,
      -0.016124166548252106,
      0.06941908597946167,
      0.022284474223852158,
      -0.039063457399606705,
      0.052989065647125244,
      -0.07992085069417953,
      0.003798827063292265,
      0.015746289864182472,
      0.03365069627761841,
      -0.012261898256838322,
      0.005318619776517153,
      0.032482437789440155,
      -0.004206581972539425,
      -0.014852885156869888,
      0.0335504375398159,
      -0.019632132723927498,
      0.028445616364479065,
      -0.03853658586740494,
      0.058030955493450165,
      0.014571011066436768,
      -0.04298055171966553,
      0.0361974835395813,
      0.08361813426017761,
      0.06405670940876007,
      -0.0059373206458985806,
      -0.032094474881887436,
      0.00979999266564846,
      -0.029708415269851685,
      -0.0741032063961029,
      0.06406907737255096,
      0.09876242280006409,
      0.05311265215277672,
      -0.0008889305754564703,
      -0.017559025436639786,
      -0.01158030703663826,
      0.06847316771745682,
      0.04597393050789833,
      0.024211809039115906,
      -0.09977564960718155,
      -0.0036081273574382067,
      0.033918771892786026,
      -0.08807367086410522,
      -0.019280852749943733,
      0.027753174304962158,
      -0.0013156477361917496,
      0.042621906846761703,
      0.08057656139135361,
      0.0060591609217226505,
      -0.016785776242613792,
      0.026679299771785736,
      -0.008068614639341831,
      -0.07794337719678879,
      0.0013265818124637008,
      0.099381223320961,
      -0.04578256607055664,
      -0.013387268409132957,
      0.023176824674010277,
      -0.08889171481132507,
      -0.00632200762629509,
      -0.012645632959902287,
      -0.032975953072309494,
      0.030881009995937347,
      0.004767787177115679,
      0.03241756558418274,
      0.05824650079011917,
      -0.05920102819800377,
      -0.05891497805714607,
      0.043898846954107285,
      0.03008468821644783,
      -0.0815272331237793,
      0.04179838299751282,
      0.021272683516144753,
      0.06368525326251984,
      -0.02320193685591221,
      -0.038765355944633484,
      0.03673907369375229,
      0.07628659904003143,
      -0.061781737953424454,
      0.010804330930113792,
      0.003401244292035699,
      0.005899862851947546,
      -0.018865354359149933,
      -0.01809924654662609,
      -0.05848095193505287,
      0.0045770686119794846,
      0.08306510746479034,
      -0.01800520159304142,
      0.0005748138064518571,
      0.018026912584900856,
      0.05421239510178566,
      0.030516168102622032,
      -0.06433314830064774,
      -0.01628733240067959,
      -0.020071668550372124,
      0.08026888221502304,
      -0.0014595421962440014,
      0.030067186802625656,
      -0.07342705875635147,
      -0.03622296452522278,
      -0.02753818966448307
    ],
    [
      0.022749843075871468,
      0.047376688569784164,
      0.01995454728603363,
      -0.01502833142876625,
      -0.07169835269451141,
      -0.06114507466554642,
      0.02903018891811371,
      -0.016133645549416542,
      -0.01292319968342781,
      -0.01972576044499874,
      -0.004971847403794527,
      -0.048980921506881714,
      0.03927397355437279,
      -0.04602509364485741,
      -0.03820988908410072,
      -0.010815021581947803,
      -0.0443592332303524,
      -0.04313059151172638,
      0.00985708273947239,
      -0.05472647398710251,
      -0.002259058179333806,
      -0.010571285150945187,
      0.07893077284097672,
      -0.0513642393052578,
      0.016175338998436928,
      -0.03307436779141426,
      0.05569467321038246,
      0.025921571999788284,
      -0.006404177751392126,
      -0.03686849772930145,
      0.014533005654811859,
      -0.035840313881635666,
      0.027237668633461,
      0.016818804666399956,
      -0.027959313243627548,
      -0.03846883773803711,
      0.013220296241343021,
      0.0316973477602005,
      -0.009610455483198166,
      0.0034502982161939144,
      0.03284483402967453,
      3.714502236107364e-05,
      0.042854610830545425,
      -0.04242705553770065,
      0.0016721929423511028,
      0.00552657525986433,
      -0.03051195852458477,
      0.003776884637773037,
      -0.03155078738927841,
      -0.013976232148706913,
      0.027612971141934395,
      -0.021652230992913246,
      -0.07674466818571091,
      -0.038146376609802246,
      -0.001965522300451994,
      0.017385104671120644,
      -0.03764170780777931,
      -0.02123299054801464,
      0.11246628314256668,
      -0.09053045511245728,
      0.0038109123706817627,
      0.021277407184243202,
      -0.008842289447784424,
      0.02000468596816063,
      -0.028045009821653366,
      0.07045713067054749,
      -0.01484005805104971,
      -0.043519966304302216,
      -0.005181103479117155,
      0.13590826094150543,
      0.010220635682344437,
      -0.008772226981818676,
      -0.004238732159137726,
      0.055649712681770325,
      -0.06019704043865204,
      -0.0377800390124321,
      0.024143382906913757,
      0.0021822245325893164,
      0.005969783291220665,
      -0.02321939915418625,
      -0.030917717143893242,
      -0.005922216456383467,
      -0.06500829756259918,
      -0.01841939613223076,
      -0.008924119174480438,
      0.006384185049682856,
      -0.014786578714847565,
      -0.04385825991630554,
      -0.00098925840575248,
      -0.02733280323445797,
      -0.008793093264102936,
      -0.011495889164507389,
      0.0016365295741707087,
      0.04276446998119354,
      0.06118929386138916,
      0.030510183423757553,
      0.025671789422631264,
      -0.008260384202003479,
      0.014450198039412498,
      -0.028013627976179123,
      0.05025132745504379,
      0.023725226521492004,
      0.0024842526763677597,
      0.039622873067855835,
      0.002369005000218749,
      -0.07786459475755692,
      0.004060250706970692,
      -0.024507634341716766,
      -0.04918639734387398,
      -0.01686456985771656,
      -0.04295353218913078,
      0.004024545196443796,
      -0.07927753031253815,
      0.026999594643712044,
      0.0032653063535690308,
      0.07848288863897324,
      0.09089335054159164,
      0.008389468304812908,
      0.0685366690158844,
      0.025389857590198517,
      -0.03694862499833107,
      -0.06178953871130943,
      0.01947670616209507,
      0.06955228745937347,
      -0.029583143070340157,
      0.01898406445980072,
      -0.0166975986212492,
      0.03581971302628517,
      -0.02672536112368107,
      -0.05160439759492874,
      -0.006404190789908171,
      -0.0003739197854883969,
      0.010839101858437061,
      0.04388231039047241,
      -0.02356862649321556,
      -0.018416628241539,
      0.028297655284404755,
      -0.08137603849172592,
      0.026880525052547455,
      -0.025105811655521393,
      0.06694050878286362,
      -0.003206083085387945,
      -0.06296667456626892,
      0.008031398989260197,
      -0.016937965527176857,
      0.051943086087703705,
      0.03585493937134743,
      -0.013039686717092991,
      -0.004335622303187847,
      0.01970147155225277,
      0.031260326504707336,
      -0.036005742847919464,
      -0.03998542204499245,
      0.006896876730024815,
      -0.026577018201351166,
      0.040081534534692764,
      0.02483798936009407,
      -0.022326625883579254,
      -0.11099958419799805,
      0.019323552027344704,
      -0.023955192416906357,
      -0.06450818479061127,
      0.021427655592560768,
      -0.04210725799202919,
      -0.03555195406079292,
      0.0014219422591850162,
      0.035802483558654785,
      -0.014432480558753014,
      -0.017991404980421066,
      -0.07832750678062439,
      0.005898496136069298,
      -0.0702056735754013,
      0.030883686617016792,
      0.026534195989370346,
      0.02669842354953289,
      -0.057325329631567,
      -0.03893844038248062,
      -0.049249522387981415,
      0.008493234403431416,
      0.009877096861600876,
      -0.05639519542455673,
      0.005909602157771587,
      -0.06379961222410202,
      -0.005528636742383242,
      0.05381132289767265,
      0.03734865412116051,
      0.04396006464958191,
      0.04784604534506798,
      -0.018623111769557,
      -0.013018851168453693,
      -0.03470494598150253,
      -0.09311043471097946,
      -0.05695640295743942,
      -0.014543221332132816,
      0.030496858060359955,
      -0.014379939064383507,
      -0.06373545527458191,
      -0.07031065970659256,
      0.028606608510017395,
      0.065206378698349,
      0.03473734110593796,
      0.030022699385881424,
      -0.10260143131017685,
      0.004136772360652685,
      -0.024279702454805374,
      0.032945793122053146,
      -0.008063231594860554,
      0.11708161234855652,
      0.008523020893335342,
      0.08741441369056702,
      -0.021039729937911034,
      0.005271575413644314,
      0.015368249267339706,
      -0.06047473102807999,
      -0.01205295417457819,
      -0.0546049140393734,
      0.02463303506374359,
      0.045002806931734085,
      -0.06748400628566742,
      -0.02228393964469433,
      -0.05615241825580597,
      0.020871061831712723,
      0.006286938209086657,
      0.03315677121281624,
      0.010046514682471752,
      -0.0395897775888443,
      -0.025690149515867233,
      0.01953502744436264,
      0.04775151610374451,
      -0.017757656052708626,
      -0.015713538974523544,
      0.05977674201130867,
      -0.08329980820417404,
      -0.06272576004266739,
      -0.03004612773656845,
      -0.05308276042342186,
      -0.04275984689593315,
      -0.02768605574965477,
      -0.005073509179055691,
      0.0033804369159042835,
      0.010344449430704117,
      0.0675136148929596,
      -0.029759224504232407,
      0.0025030779652297497,
      0.030712293460965157,
      0.03904549777507782,
      0.03807348757982254,
      0.04869067296385765,
      0.011490963399410248,
      0.017213871702551842,
      0.024564245715737343,
      0.00017335564189124852,
      -0.019273538142442703,
      0.03603283688426018,
      -0.01931971125304699,
      -0.009031924419105053,
      0.018480708822607994,
      0.08150040358304977,
      0.06352927535772324,
      0.04920008033514023,
      -0.00458876695483923,
      -0.10463668406009674,
      -0.017618846148252487,
      -0.06507375836372375,
      0.027465254068374634,
      0.027110783383250237,
      0.008036209270358086,
      0.04303763806819916,
      0.011620480567216873,
      0.041198667138814926,
      -0.0067424410954117775,
      -0.01310528814792633,
      -0.02247161976993084,
      0.005389727186411619,
      0.04761223495006561,
      0.04210447520017624,
      0.03410431742668152,
      -0.012760056182742119,
      -0.05562253296375275,
      -0.0011952787172049284,
      -0.018211767077445984,
      0.05616696551442146,
      0.0028353917878121138,
      -0.025084955617785454,
      -0.009199096821248531,
      0.05605902522802353,
      0.054988302290439606,
      0.0819801390171051,
      -0.020810658112168312,
      -0.06861300021409988,
      -0.04017341136932373,
      0.06153510883450508,
      0.014759352430701256,
      0.03277716413140297,
      -0.0070607950910925865,
      0.026184652000665665,
      0.0007223653374239802,
      0.02825106494128704,
      0.04467231407761574,
      0.035820070654153824,
      -0.011593755334615707,
      -0.10962074995040894,
      0.04870644211769104,
      0.00030284278909675777,
      -0.0679304376244545,
      0.0779176652431488,
      -0.0448010191321373,
      -0.03529306501150131,
      -0.053748566657304764,
      -0.12817199528217316,
      0.04958830028772354,
      0.013496795669198036,
      0.0008375234319828451,
      0.014126145280897617,
      0.04408682882785797,
      0.0439860075712204,
      -0.02362331934273243,
      0.025870967656373978,
      -0.0011117381509393454,
      -0.008788182400166988,
      -0.01901429332792759,
      -0.0026453786995261908,
      0.14181526005268097,
      0.034126244485378265,
      0.08284342288970947,
      0.031002169474959373,
      0.06960397213697433,
      0.07014987617731094,
      0.019075240939855576,
      0.03778344392776489,
      -0.018375439569354057,
      -0.025290675461292267,
      0.014551576226949692,
      -0.012543890625238419,
      0.04177860543131828,
      0.016047382727265358,
      0.06424605846405029,
      0.04386819526553154,
      0.04771559312939644,
      0.009459543973207474,
      0.009372067637741566,
      0.006961940322071314,
      -0.11510714888572693,
      0.013263634406030178,
      0.022426843643188477,
      -0.010090154595673084,
      -0.03640105575323105,
      -0.0001549599110148847,
      -0.05235520377755165,
      -0.02187846228480339,
      -0.014096828177571297,
      -0.005460408516228199,
      0.023298168554902077,
      0.04918524995446205,
      -0.04600059613585472,
      -0.0018093910766765475,
      0.019190646708011627,
      -0.007776397746056318,
      0.0034558039624243975,
      -0.03793485090136528,
      -0.017989186570048332,
      0.027543239295482635,
      -0.027316372841596603,
      0.05236503854393959,
      0.021472716704010963,
      -0.0633079782128334,
      -0.019228478893637657,
      -0.036980967968702316,
      -0.0677386075258255,
      -0.05994082987308502,
      0.004906033631414175,
      -0.034033361822366714,
      0.03643374890089035,
      -0.05333884805440903,
      0.014412414282560349,
      0.05571315065026283,
      -0.06177246570587158,
      -0.008547315374016762,
      -0.08641821891069412,
      0.03317646682262421,
      -0.027122871950268745,
      -0.02110701985657215,
      0.03233514726161957,
      0.06439360976219177,
      -0.03658229857683182,
      -0.03835677728056908,
      0.02144257351756096,
      -0.0060534290969371796,
      -0.01746559701859951,
      0.05181135982275009,
      0.012479226104915142,
      0.07339929789304733,
      -0.04221317917108536,
      0.010393796488642693,
      0.0305317472666502,
      0.0035197290126234293,
      -0.058931514620780945,
      -0.01886373944580555,
      0.000311846612021327,
      -0.05182763189077377,
      0.053724486380815506,
      0.0020122150890529156,
      0.03665732219815254,
      -0.03918048366904259,
      0.032139237970113754,
      0.07217283546924591,
      0.003777357516810298,
      -0.002847593044862151,
      -0.05753720551729202,
      0.009271860122680664,
      -0.030775126069784164,
      0.02468288317322731,
      0.04994199052453041,
      0.0069276681169867516,
      -0.03150399774312973,
      0.047646522521972656,
      0.03281893953680992,
      0.02526828646659851,
      0.019848717376589775,
      0.028206415474414825,
      0.06932525336742401,
      0.016575532034039497,
      0.02502967230975628,
      -0.014254082925617695,
      -0.12255005538463593,
      0.0331462100148201,
      -0.02777796797454357,
      -0.018673362210392952,
      -0.013791157864034176,
      -0.006088282447308302,
      -0.008922934532165527,
      0.007012150716036558,
      0.017719468101859093,
      0.025189559906721115,
      -0.006844657473266125,
      -0.027313120663166046,
      0.0024658243637531996,
      0.01441987045109272,
      -0.051040589809417725,
      0.03759625926613808,
      0.05550526827573776,
      -0.04104021191596985,
      0.0207535233348608,
      -0.005080260336399078,
      -0.06689686328172684,
      0.029063694179058075,
      -0.045040566474199295,
      -0.026476221159100533,
      -0.02787994220852852,
      -0.0004653282812796533,
      0.0011410173028707504,
      0.03765840083360672,
      -0.03168293461203575,
      0.08314979076385498,
      -0.07579254359006882,
      0.05063677579164505,
      -0.04042338952422142,
      -0.01696143113076687,
      -0.01254283543676138,
      -0.02991880103945732,
      -0.031997427344322205,
      0.0262348260730505,
      0.05740966647863388,
      -0.017793899402022362,
      0.007886557839810848,
      0.030581114813685417,
      0.08860458433628082,
      0.05807199701666832,
      0.08303933590650558,
      0.0021831307094544172,
      -0.0037211349699646235,
      0.044908955693244934,
      -0.07160637527704239,
      -0.07673775404691696,
      0.04266376048326492,
      0.07100160419940948,
      -0.14852555096149445,
      0.07721894979476929,
      -0.002141430042684078,
      -0.0727541521191597,
      -0.042447280138731,
      -0.041698288172483444,
      -0.013614682480692863,
      -0.037871889770030975,
      0.0828675851225853,
      -0.05159889534115791,
      0.018452027812600136,
      0.016624562442302704,
      0.0032182110007852316,
      0.033021457493305206,
      -0.005684529896825552,
      0.03163708746433258,
      -0.024857662618160248,
      -0.04216989874839783,
      -0.05400542914867401,
      -0.006261074915528297,
      -0.06771284341812134,
      0.001873473753221333,
      0.08803313225507736,
      -0.037438392639160156,
      -0.07492426037788391,
      -0.05152546241879463,
      0.01314057782292366
    ],
    [
      -0.0652758777141571,
      0.057828743010759354,
      0.023270295932888985,
      0.023731835186481476,
      0.022385822609066963,
      -0.06366410106420517,
      -0.04019955173134804,
      -0.02176525630056858,
      0.01858563721179962,
      -0.04143235459923744,
      -0.013423158787190914,
      0.025569265708327293,
      0.026630045846104622,
      -0.05156591907143593,
      0.018994824960827827,
      -0.003061761148273945,
      -0.049964677542448044,
      -0.03569912910461426,
      -0.0029550001490861177,
      0.019841386005282402,
      -0.13875803351402283,
      -0.014094901271164417,
      0.01764596626162529,
      0.035699084401130676,
      0.10999515652656555,
      0.01290000881999731,
      -0.09471721947193146,
      -0.002590868854895234,
      -0.013498547486960888,
      -0.0883888527750969,
      -0.014962874352931976,
      0.008947993628680706,
      0.01751587726175785,
      -0.1034582331776619,
      -0.08546584099531174,
      -0.03969487547874451,
      0.06496378034353256,
      0.07571949809789658,
      0.024798281490802765,
      0.032430537045001984,
      0.024254867807030678,
      -0.015473577193915844,
      0.028007814660668373,
      0.014090423472225666,
      0.06156722083687782,
      -0.11406712979078293,
      0.01916966214776039,
      -0.0364842526614666,
      0.030907217413187027,
      -0.041950952261686325,
      0.11419793218374252,
      0.03261210769414902,
      0.0775865688920021,
      -0.060758017003536224,
      -0.12123090773820877,
      -0.026030199602246284,
      0.04350743442773819,
      0.01102934405207634,
      0.014549260027706623,
      -0.06196315214037895,
      -0.09543118625879288,
      -0.02665080316364765,
      -0.031949613243341446,
      0.06948987394571304,
      -0.008491535671055317,
      -0.03959595412015915,
      -0.03405173867940903,
      -0.007965140976011753,
      0.0726180151104927,
      0.023234345018863678,
      -0.05514638125896454,
      0.005026386585086584,
      -0.056169748306274414,
      0.02011452428996563,
      -0.012368889525532722,
      0.04016997665166855,
      -0.01487868744879961,
      0.025608548894524574,
      -0.019001666456460953,
      -0.06570123881101608,
      0.014843489043414593,
      0.0605739988386631,
      0.050035037100315094,
      -0.07254720479249954,
      -0.07844270020723343,
      0.029741108417510986,
      0.014188745990395546,
      -0.01805625855922699,
      -0.0976535975933075,
      0.04439197853207588,
      -9.285857231589034e-05,
      -0.017310021445155144,
      0.019755568355321884,
      -0.047444481402635574,
      0.018396418541669846,
      -0.04146122187376022,
      -0.027576914057135582,
      0.004469634499400854,
      -0.01068559754639864,
      -0.02537771500647068,
      0.052871450781822205,
      -0.009771407581865788,
      -0.05768365040421486,
      -0.0019416147843003273,
      0.019223129376769066,
      -0.02989189885556698,
      0.0072241718880832195,
      -0.012023882940411568,
      0.0016544308746233582,
      -0.0010310825891792774,
      0.02647721953690052,
      -0.03617953881621361,
      -0.003223205916583538,
      -0.058695629239082336,
      -0.022529859095811844,
      0.08660560101270676,
      -0.08739414811134338,
      0.04049258306622505,
      0.057888101786375046,
      0.03069613128900528,
      0.016073357313871384,
      0.039782144129276276,
      0.007790083531290293,
      -0.05911025032401085,
      0.03971804678440094,
      -0.04560517147183418,
      -0.039489392191171646,
      0.06640466302633286,
      0.009322681464254856,
      0.0004688558110501617,
      -0.014790584333240986,
      0.01382164005190134,
      0.016888655722141266,
      -0.0031474812421947718,
      0.050968755036592484,
      -0.10436958074569702,
      -0.05888207629323006,
      -0.02364753745496273,
      0.04898427426815033,
      -0.012627564370632172,
      -0.03094675950706005,
      0.07082964479923248,
      -0.042068883776664734,
      0.06780102849006653,
      -0.022934408858418465,
      -0.07766921073198318,
      -0.007732119411230087,
      0.00363685330376029,
      -0.014151775278151035,
      0.04968234524130821,
      0.0719151496887207,
      -0.03556646779179573,
      -0.001827359665185213,
      0.024592624977231026,
      -0.09355906397104263,
      0.06448087096214294,
      -0.027924172580242157,
      -0.054563798010349274,
      -0.0341857485473156,
      0.03132454305887222,
      -0.03435584157705307,
      -0.015220321714878082,
      0.012354492209851742,
      0.020581182092428207,
      0.03706460818648338,
      -0.04577159881591797,
      -0.028774689882993698,
      -0.0015437734546139836,
      0.02575618028640747,
      0.00984601117670536,
      -0.004460563417524099,
      0.0061790780164301395,
      -0.016915084794163704,
      -0.021191678941249847,
      0.033921703696250916,
      -0.05986509472131729,
      -0.04590292647480965,
      -0.02918015979230404,
      -0.11237741261720657,
      0.01189574133604765,
      -0.0005909103201702237,
      0.06706064194440842,
      0.01607094146311283,
      -0.10722582787275314,
      -0.0381026454269886,
      0.021494276821613312,
      0.05024942755699158,
      0.0663592591881752,
      0.058923542499542236,
      0.08475979417562485,
      -0.0005752996657975018,
      -0.044970203191041946,
      -0.012070896103978157,
      0.004380843602120876,
      -0.05441473424434662,
      -0.08904362469911575,
      -0.03861674666404724,
      -0.05306394025683403,
      0.007248780690133572,
      0.018592718988656998,
      -0.003236261894926429,
      0.0010288177290931344,
      0.05312024801969528,
      -0.013607613742351532,
      0.03457671031355858,
      -0.02787909284234047,
      -0.0937945693731308,
      0.05064908787608147,
      -0.03781641647219658,
      -0.029238376766443253,
      0.05071284994482994,
      -0.017580335959792137,
      -0.010319984517991543,
      0.026424100622534752,
      -0.038718122988939285,
      -0.024190133437514305,
      -0.01170960534363985,
      0.03104785457253456,
      0.03184589743614197,
      -0.003871635999530554,
      0.018968557938933372,
      -0.05791495740413666,
      -0.0210907980799675,
      -0.02415967918932438,
      0.0017662624595686793,
      0.0016798853175714612,
      0.029293948784470558,
      -0.019463540986180305,
      0.0791398137807846,
      0.0602886863052845,
      0.016552794724702835,
      0.059999991208314896,
      -0.003699379740282893,
      -0.06133517995476723,
      -0.005629376042634249,
      0.03283248841762543,
      0.02212626300752163,
      -0.01900608465075493,
      -0.0546933189034462,
      -0.03166462481021881,
      -0.03183427453041077,
      0.030370958149433136,
      0.024863524362444878,
      0.023483257740736008,
      -0.09796120226383209,
      0.05924186110496521,
      -0.04647815227508545,
      0.05460672453045845,
      -0.002395829651504755,
      0.06968923658132553,
      0.01419349480420351,
      -0.008254933170974255,
      0.03828851878643036,
      0.051693376153707504,
      0.03167249262332916,
      0.058676429092884064,
      -0.028532318770885468,
      -0.06026579067111015,
      0.007941240444779396,
      0.09358973801136017,
      -0.050874195992946625,
      0.016447298228740692,
      0.07322738319635391,
      0.01935715228319168,
      0.02651011198759079,
      -0.03429614007472992,
      0.004950286354869604,
      0.010110590606927872,
      -0.08805171400308609,
      0.03135857358574867,
      -0.010658963583409786,
      0.03148580342531204,
      -0.0658431127667427,
      -0.0203706044703722,
      -0.04203755781054497,
      -0.03485056757926941,
      0.02359952963888645,
      -0.025830434635281563,
      -0.12462258338928223,
      -0.005701516754925251,
      0.0070024169981479645,
      -0.027161896228790283,
      -0.04801216721534729,
      0.009165196679532528,
      -0.1302853524684906,
      0.013500785455107689,
      -0.017678501084446907,
      -0.009896304458379745,
      0.053907547146081924,
      0.036217160522937775,
      -0.024557556957006454,
      0.029971498996019363,
      -0.03976118564605713,
      -0.008620576933026314,
      -0.017373181879520416,
      0.04318679869174957,
      0.014652892015874386,
      0.029691878706216812,
      -0.0041493563912808895,
      -0.026246868073940277,
      -0.053292542695999146,
      -0.00506868539378047,
      -0.0774771124124527,
      -0.05597831308841705,
      -0.05686308443546295,
      0.017558034509420395,
      0.0781325250864029,
      0.02200157754123211,
      0.06220974773168564,
      -0.06995612382888794,
      0.06253812462091446,
      -0.10643726587295532,
      0.03395351395010948,
      -0.015815960243344307,
      0.018382195383310318,
      -0.01676015369594097,
      0.029657553881406784,
      -0.07767938822507858,
      -0.04593154042959213,
      -0.04971202090382576,
      -0.049616970121860504,
      -0.0872177854180336,
      0.05039813742041588,
      0.04837675392627716,
      -0.016971047967672348,
      -0.05843032896518707,
      0.09944000095129013,
      0.07213252782821655,
      0.08636775612831116,
      0.060332681983709335,
      0.01580081693828106,
      -0.015368195250630379,
      -0.013405300676822662,
      0.016753211617469788,
      0.008162463083863258,
      0.03536533936858177,
      0.02115650475025177,
      0.07732633501291275,
      0.020856885239481926,
      0.0007891380228102207,
      -0.029703175649046898,
      0.012148208916187286,
      -0.054884541779756546,
      0.0020004049874842167,
      0.013708447106182575,
      -0.0344584658741951,
      0.0545896477997303,
      0.06372727453708649,
      0.013409629464149475,
      0.02608725056052208,
      0.014480240643024445,
      0.019528115168213844,
      0.013529779389500618,
      0.02776365354657173,
      -0.04622989892959595,
      -0.02701200358569622,
      -0.037920523434877396,
      -0.07723117619752884,
      -0.023554032668471336,
      0.00752167496830225,
      -0.013709676451981068,
      0.029265746474266052,
      0.03632603585720062,
      0.004417517688125372,
      0.025054799392819405,
      -0.000555000442545861,
      0.046710118651390076,
      0.0455777533352375,
      0.04988022521138191,
      0.01617666520178318,
      0.06038891524076462,
      0.034903522580862045,
      -0.02359803207218647,
      -0.1038319543004036,
      0.04070688784122467,
      0.015519081614911556,
      0.00837964192032814,
      0.040643759071826935,
      0.06055322289466858,
      -0.0003560656332410872,
      -0.0718284323811531,
      -0.014651257544755936,
      -0.05524447187781334,
      -0.07558989524841309,
      0.06527339667081833,
      -0.05813797563314438,
      -0.07412410527467728,
      0.018141644075512886,
      0.012156310491263866,
      -0.08046937733888626,
      -0.029232660308480263,
      0.03499651327729225,
      -0.07697319984436035,
      -0.013557445257902145,
      0.06743506342172623,
      0.007107025943696499,
      -0.03117845393717289,
      -0.02450699172914028,
      0.014098318293690681,
      0.046263787895441055,
      -0.04257639870047569,
      0.038727037608623505,
      0.009253934025764465,
      -0.025539318099617958,
      0.04136974364519119,
      -0.0072236694395542145,
      -0.009750346653163433,
      0.07707437127828598,
      -0.052806269377470016,
      -0.0016593701438978314,
      -0.045384038239717484,
      0.09031308442354202,
      -0.07983986288309097,
      -0.015130228362977505,
      0.03461328148841858,
      -0.054214030504226685,
      -0.06778694689273834,
      0.022360121831297874,
      0.016597550362348557,
      -0.04248916357755661,
      0.04016020521521568,
      0.032892752438783646,
      0.06111839413642883,
      -0.0754329189658165,
      -0.010522458702325821,
      0.0023833829909563065,
      -0.057764191180467606,
      -0.01467897929251194,
      0.04196115583181381,
      -0.0021052551455795765,
      -0.0694129690527916,
      -0.04508567601442337,
      -0.01098206639289856,
      -0.006795146502554417,
      0.017656035721302032,
      -0.02270164154469967,
      -0.0018164255889132619,
      -0.006910466589033604,
      -0.11854467540979385,
      0.11287868022918701,
      0.01053549349308014,
      0.01430598832666874,
      0.028154423460364342,
      -0.06843631714582443,
      0.0046062772162258625,
      -0.09964688867330551,
      -0.0011615175753831863,
      -0.06506618112325668,
      0.016380151733756065,
      0.03794540837407112,
      -0.044747211039066315,
      -0.010957127436995506,
      0.007922627031803131,
      -0.027356043457984924,
      0.03034454770386219,
      0.002955223200842738,
      -0.02539774961769581,
      -0.006898289546370506,
      -0.019434161484241486,
      -0.048423171043395996,
      -0.053627271205186844,
      -0.01736595667898655,
      -0.100916787981987,
      -0.017685960978269577,
      0.016205525025725365,
      0.047702379524707794,
      0.03388601914048195,
      -0.05449928343296051,
      0.002562070731073618,
      -0.0267945509403944,
      -0.031387750059366226,
      -0.001377235515974462,
      -0.0007916569011285901,
      -0.010316122323274612,
      -0.03597761318087578,
      0.02085619792342186,
      -0.04290435090661049,
      -0.08327851444482803,
      -0.013819017447531223,
      0.055625490844249725,
      -0.04407937079668045,
      -0.03619500622153282,
      0.0508517324924469,
      -0.02347455359995365,
      0.014240034855902195,
      0.05528563633561134,
      0.06447678804397583,
      0.03056318871676922,
      -0.03516339138150215,
      0.03271868824958801,
      -0.03567923977971077,
      -0.007541313301771879,
      0.06593537330627441,
      0.017671557143330574,
      -0.05083455517888069,
      0.024795297533273697,
      0.03340499475598335,
      -0.03806459158658981,
      0.005393578205257654,
      0.009094750508666039,
      0.06128714233636856,
      0.006523607298731804,
      -0.021204259246587753
    ],
    [
      0.009716869331896305,
      -0.034745536744594574,
      0.0008068396127782762,
      -0.01828165166079998,
      -0.028756985440850258,
      0.0010100206127390265,
      0.005357610527426004,
      -0.05166137218475342,
      -0.02250702865421772,
      0.02601819857954979,
      0.026021551340818405,
      0.0020423794630914927,
      0.04471828043460846,
      -0.02141927368938923,
      0.024839546531438828,
      -0.03079371526837349,
      -0.03200117126107216,
      0.007541097234934568,
      0.006602593697607517,
      0.06670015305280685,
      -0.06884775310754776,
      0.08956583589315414,
      -0.024717247113585472,
      -0.038226328790187836,
      -0.03147998824715614,
      0.011814877390861511,
      0.053137075155973434,
      0.026614928618073463,
      0.03955630585551262,
      -0.02202148549258709,
      -0.033873751759529114,
      0.017346706241369247,
      0.03573725000023842,
      -0.06260152906179428,
      -0.08209685236215591,
      -0.018747808411717415,
      0.021348170936107635,
      -0.049108654260635376,
      0.03769594058394432,
      0.004230635706335306,
      -0.04461497813463211,
      0.03718298301100731,
      0.10111737996339798,
      0.04782293736934662,
      0.0712936669588089,
      -0.026585448533296585,
      0.06739430874586105,
      0.014331483282148838,
      -0.010734551586210728,
      -0.0619000643491745,
      -0.013717313297092915,
      0.04536695033311844,
      0.014037540182471275,
      -0.017641790211200714,
      0.041077885776758194,
      0.019486814737319946,
      0.0321485809981823,
      -0.03926219791173935,
      0.04491127282381058,
      -0.03881280869245529,
      0.022175369784235954,
      -0.04560965299606323,
      0.022933797910809517,
      0.07692636549472809,
      -0.02518821321427822,
      0.01038334146142006,
      0.01582220382988453,
      0.06043746694922447,
      -0.02503778599202633,
      0.004351901821792126,
      -0.03887238726019859,
      -0.03692417964339256,
      -0.03863721340894699,
      -0.034101635217666626,
      0.03224204108119011,
      0.05580537021160126,
      0.02633841708302498,
      -0.005313803907483816,
      0.024502791464328766,
      0.051700711250305176,
      -0.008804727345705032,
      -0.05872392654418945,
      0.0247944463044405,
      0.05817557871341705,
      0.015016508288681507,
      0.11507837474346161,
      0.04411997273564339,
      0.04068838432431221,
      -0.003285423619672656,
      -0.015709741041064262,
      0.10182911157608032,
      -0.031977564096450806,
      0.03128265589475632,
      0.08545663952827454,
      0.028173143044114113,
      0.04623788222670555,
      0.04017883911728859,
      -0.03432609885931015,
      0.030384983867406845,
      -0.01931907795369625,
      0.013557606376707554,
      -0.016057349741458893,
      0.015610962174832821,
      -0.06428005546331406,
      0.006745455786585808,
      -0.007275883574038744,
      -0.02073618769645691,
      -0.021510476246476173,
      -0.0022030617110431194,
      -0.026513295248150826,
      0.04635545238852501,
      0.00284942495636642,
      -0.07747742533683777,
      -0.024409905076026917,
      -0.019127998501062393,
      -0.01635296829044819,
      -0.04089563339948654,
      0.08899287134408951,
      0.02063334919512272,
      0.026733912527561188,
      -0.07151530683040619,
      -0.07116325944662094,
      -0.052835121750831604,
      -0.012332847341895103,
      -0.02213900163769722,
      -0.01756373420357704,
      -0.017054744064807892,
      -0.013938643038272858,
      -0.00725475512444973,
      -0.06844907253980637,
      -0.0376133918762207,
      0.0688645988702774,
      0.011961785145103931,
      -0.031248951330780983,
      -0.0011108438484370708,
      -0.013436953537166119,
      -0.08562091737985611,
      -0.03609318286180496,
      0.07753908634185791,
      -0.06729073077440262,
      -0.041321273893117905,
      0.0480569452047348,
      0.10086917132139206,
      -0.00731667410582304,
      0.11287562549114227,
      0.01718118041753769,
      0.026831280440092087,
      0.025118740275502205,
      -0.03804425522685051,
      0.004696606192737818,
      -0.0073907701298594475,
      -0.03447917848825455,
      0.012779084034264088,
      -0.04206154868006706,
      -0.07882539927959442,
      0.001376192201860249,
      -0.0069373301230371,
      -0.07169900089502335,
      -0.01797325350344181,
      0.035284373909235,
      -0.04756946116685867,
      0.07460422068834305,
      -0.05488559603691101,
      -0.014638631604611874,
      6.71543602948077e-05,
      -0.010858348570764065,
      0.0056225331500172615,
      -0.01023832056671381,
      -0.006957248784601688,
      0.0934702679514885,
      -0.005455312319099903,
      0.000680849829223007,
      -0.006867358461022377,
      -0.044267650693655014,
      0.006385693326592445,
      0.026703935116529465,
      -0.0037309196777641773,
      -0.012851922772824764,
      -0.05011115223169327,
      0.05816677585244179,
      0.021856211125850677,
      -0.03770461678504944,
      0.06677878648042679,
      0.05248044431209564,
      -0.10257801413536072,
      0.014977606944739819,
      0.0483378991484642,
      0.01701347902417183,
      0.013183873146772385,
      0.022862344980239868,
      0.04425041377544403,
      -0.02333400398492813,
      0.04558958858251572,
      -0.02149495668709278,
      0.023612674325704575,
      -0.0382220521569252,
      0.02588365599513054,
      -0.024670133367180824,
      0.07849221676588058,
      0.028689436614513397,
      -0.07576268911361694,
      0.03237194940447807,
      0.01876368373632431,
      -0.0023055991623550653,
      -0.09852486103773117,
      -0.009358718059957027,
      -0.027786212041974068,
      -0.01820904016494751,
      -0.08443449437618256,
      -0.05231807753443718,
      -0.038099948316812515,
      0.025463968515396118,
      0.04890613257884979,
      0.0064851026982069016,
      -0.021551094949245453,
      0.021114811301231384,
      0.033862896263599396,
      0.02510683983564377,
      -0.0523822121322155,
      0.052190572023391724,
      0.019368717446923256,
      0.032990142703056335,
      0.02357332780957222,
      -0.008926506154239178,
      -0.04272343963384628,
      0.01576567441225052,
      0.035677555948495865,
      0.04006499797105789,
      -0.02646377868950367,
      0.004440130200237036,
      0.08109266310930252,
      0.06646640598773956,
      0.027723409235477448,
      0.014955442398786545,
      0.07689020782709122,
      -0.034644365310668945,
      -0.09748507291078568,
      -0.031541258096694946,
      0.007123973686248064,
      0.004542871378362179,
      -0.02956434153020382,
      -0.06317063421010971,
      0.04172293841838837,
      0.0014326584059745073,
      -0.008871903643012047,
      -0.030403725802898407,
      0.01852596551179886,
      -0.019373493269085884,
      0.001358785550110042,
      -0.06449981778860092,
      -0.05326719209551811,
      -0.007279291283339262,
      0.04965886101126671,
      -0.01274637971073389,
      -0.036983564496040344,
      -0.01960420235991478,
      -0.0552518256008625,
      0.035883307456970215,
      0.07661210745573044,
      -0.08647546917200089,
      0.017621194943785667,
      0.04454539716243744,
      0.026097791269421577,
      -0.04820626601576805,
      -0.058223843574523926,
      -0.0067972750402987,
      -0.03859822824597359,
      0.04151283577084541,
      0.040123894810676575,
      0.025378521531820297,
      -0.018369074910879135,
      0.0731632262468338,
      0.017422540113329887,
      -0.03670145198702812,
      -0.01129032764583826,
      0.008144114166498184,
      -0.03365950286388397,
      -0.0025994922034442425,
      -0.025749998167157173,
      -0.10650154948234558,
      0.031215975061058998,
      -0.0118587426841259,
      -0.027218688279390335,
      0.047803957015275955,
      0.05533754453063011,
      -0.0018191607668995857,
      -0.03470705449581146,
      0.027300583198666573,
      -0.05469455197453499,
      -0.04311281442642212,
      -0.0008271002443507314,
      0.01797262579202652,
      0.052538685500621796,
      0.011064652353525162,
      -0.02845151163637638,
      -0.00670963991433382,
      0.01055840216577053,
      -0.025072727352380753,
      0.03889579325914383,
      0.052131205797195435,
      -0.032403796911239624,
      -0.03809337690472603,
      0.07368440926074982,
      -0.11377463489770889,
      0.06718451529741287,
      4.517486013355665e-05,
      -0.05813797190785408,
      -0.05878081172704697,
      -0.0739484578371048,
      0.06909536570310593,
      0.05975678563117981,
      0.024837570264935493,
      -0.06428086757659912,
      -0.053744636476039886,
      0.02241263911128044,
      -0.05925338715314865,
      -0.0031641831155866385,
      0.024571610614657402,
      0.012265119701623917,
      -0.036153148859739304,
      0.014277476817369461,
      0.0239141546189785,
      0.06811949610710144,
      0.037723030894994736,
      -0.09212689846754074,
      0.0054396977648139,
      -0.015618988312780857,
      -0.1024756208062172,
      0.07816105335950851,
      0.038243137300014496,
      0.016183581203222275,
      -0.03208701312541962,
      0.04488784447312355,
      -0.07333821803331375,
      -0.009493518620729446,
      0.03361515328288078,
      0.09714539349079132,
      -0.028218165040016174,
      0.017379671335220337,
      -0.0075150043703615665,
      0.0065856389701366425,
      0.0111435791477561,
      0.028231704607605934,
      -0.005673437379300594,
      0.06019587069749832,
      0.055489666759967804,
      0.11645466834306717,
      0.058340009301900864,
      0.04601765051484108,
      0.07321285456418991,
      -0.03772040829062462,
      0.07109323889017105,
      -0.009013623930513859,
      -0.04033660888671875,
      0.022528670728206635,
      -0.027368837967514992,
      -0.013747899793088436,
      -0.015579341910779476,
      0.03320597484707832,
      -0.034755952656269073,
      0.06886253505945206,
      -0.00860025454312563,
      0.004765816032886505,
      -0.030510542914271355,
      0.02870132401585579,
      0.07442930340766907,
      0.09142854809761047,
      0.0924067497253418,
      -0.03809552267193794,
      0.008985843509435654,
      -0.06859618425369263,
      0.04684678837656975,
      0.05857140198349953,
      -0.056680794805288315,
      -0.04647306725382805,
      -0.01396903395652771,
      -0.062316928058862686,
      0.07939574867486954,
      0.02850053459405899,
      0.0387692004442215,
      0.006724731530994177,
      0.0415903776884079,
      -0.04941745474934578,
      -0.1076197475194931,
      -0.0043669831939041615,
      -0.033452752977609634,
      -0.0049108839593827724,
      0.0032835619058459997,
      0.015493873506784439,
      -0.015069816261529922,
      0.059370893985033035,
      0.04424206167459488,
      -0.012343295849859715,
      0.015533287078142166,
      0.058199625462293625,
      -0.07659254223108292,
      -0.01558019407093525,
      0.01648981124162674,
      0.032517608255147934,
      0.003273684997111559,
      0.02274991199374199,
      -0.04165157675743103,
      0.01340571977198124,
      -0.03222578763961792,
      0.09544963389635086,
      -0.08421109616756439,
      0.03237695246934891,
      -0.01265469379723072,
      0.10211574286222458,
      0.007526890840381384,
      -0.03052188642323017,
      0.0019423459889367223,
      0.0062610432505607605,
      -0.06157301738858223,
      -0.04603798687458038,
      -0.017060771584510803,
      -0.02560211531817913,
      0.11507382988929749,
      -0.01715461164712906,
      -0.08607862889766693,
      0.027997607365250587,
      -0.023289835080504417,
      0.008165041916072369,
      0.004009366966784,
      -0.05903250351548195,
      0.02965117245912552,
      0.04365967586636543,
      -0.03230590000748634,
      0.029942406341433525,
      0.04256868362426758,
      0.011310582980513573,
      0.054256364703178406,
      -0.015905233100056648,
      0.06028572842478752,
      0.016297169029712677,
      0.0040352786891162395,
      0.003174595069140196,
      -0.017816690728068352,
      0.021899310871958733,
      -0.04252343252301216,
      -0.020654944702982903,
      0.04206061363220215,
      0.005173105746507645,
      0.052204836159944534,
      -0.019697977229952812,
      7.739834836684167e-05,
      0.00415194733068347,
      -0.027899548411369324,
      0.024551790207624435,
      -0.013498836196959019,
      0.0017272429540753365,
      -0.025709785521030426,
      0.0728335902094841,
      0.005754983983933926,
      0.02781243622303009,
      0.0748639851808548,
      0.029846379533410072,
      0.04356398433446884,
      0.04563913866877556,
      0.008548821322619915,
      -0.03343924880027771,
      -0.031114486977458,
      0.005352165084332228,
      -0.02337765134871006,
      0.0642390251159668,
      -0.009359118528664112,
      -0.06746245175600052,
      -0.05108621343970299,
      0.04460475593805313,
      -0.03472859039902687,
      0.0028358944691717625,
      0.01883918233215809,
      0.008884997107088566,
      -0.02071545459330082,
      -0.032048311084508896,
      0.01847459375858307,
      -0.04983793944120407,
      -0.009935885667800903,
      0.03584972396492958,
      -0.05263429135084152,
      -0.023145750164985657,
      -0.0069814519956707954,
      -0.06754720956087112,
      -0.026600556448101997,
      0.02367904968559742,
      0.036853864789009094,
      -0.10664721578359604,
      -0.017875943332910538,
      -0.013384145684540272,
      0.026309847831726074,
      -0.023111913353204727,
      0.027476977556943893,
      -0.019892960786819458,
      0.03715274855494499,
      0.008287245407700539,
      0.030844565480947495,
      -0.07886931300163269,
      -0.03449162840843201,
      0.06398870795965195,
      -0.06339254230260849,
      0.05272237956523895,
      -0.027969010174274445,
      0.02641143836081028
    ],
    [
      -0.01768459565937519,
      0.03280318155884743,
      -0.0024301724042743444,
      0.034601159393787384,
      -0.0539267472922802,
      -0.06835916638374329,
      0.058698706328868866,
      0.003703747410327196,
      0.006647214293479919,
      0.012170384638011456,
      0.02505313977599144,
      0.07595271617174149,
      -0.034300025552511215,
      -0.035363513976335526,
      0.033784788101911545,
      0.020894741639494896,
      0.016998877748847008,
      -0.040684040635824203,
      0.047599177807569504,
      0.017733613029122353,
      0.007838073186576366,
      -0.07070918381214142,
      -0.013185800984501839,
      -0.04036648944020271,
      0.02141338586807251,
      -0.002994873095303774,
      0.00971772987395525,
      0.0353337898850441,
      -0.02332375943660736,
      0.05115726962685585,
      0.03108963370323181,
      -0.011328576132655144,
      0.016292188316583633,
      -0.011089426465332508,
      0.033335380256175995,
      0.0013664565049111843,
      -0.009096743538975716,
      0.048984915018081665,
      -0.0684603899717331,
      -0.006558020133525133,
      0.03906485438346863,
      0.017136573791503906,
      0.011063940823078156,
      -0.012043708004057407,
      -0.01239848043769598,
      0.004796176217496395,
      0.04465049132704735,
      -0.020253721624612808,
      -0.015423459932208061,
      0.0007256221724674106,
      -0.020329095423221588,
      -0.004544942174106836,
      -0.04349003732204437,
      0.019126033410429955,
      -0.11541009694337845,
      -0.06682780385017395,
      -0.012717647477984428,
      0.02599533647298813,
      0.06597547233104706,
      0.040456488728523254,
      0.06829917430877686,
      0.024529917165637016,
      -0.010644227266311646,
      0.0017713786801323295,
      -0.035331934690475464,
      0.03182661533355713,
      0.04659665748476982,
      -0.03256262093782425,
      -0.038153275847435,
      0.016191301867365837,
      0.011763338930904865,
      -0.023130910471081734,
      -0.015831908211112022,
      -0.04955754056572914,
      0.029811568558216095,
      -0.03428291156888008,
      0.10246291756629944,
      -0.003840809455141425,
      -0.040610700845718384,
      0.017374297603964806,
      -0.054383233189582825,
      -0.05819159746170044,
      0.015235687606036663,
      -0.047459255903959274,
      -0.0015031888615339994,
      -0.06080236658453941,
      -0.04893309250473976,
      -0.028045564889907837,
      0.07025977969169617,
      -0.07063054293394089,
      -0.014725851826369762,
      -0.0012423477601259947,
      -0.020794907584786415,
      0.03283790498971939,
      0.014977258630096912,
      -0.01440043281763792,
      0.020166585221886635,
      -0.005481567699462175,
      0.08363174647092819,
      0.05157218128442764,
      0.06497527658939362,
      -0.006609706208109856,
      -0.026297593489289284,
      0.042047835886478424,
      0.07246083766222,
      0.030118532478809357,
      -0.050132136791944504,
      -0.06525342166423798,
      0.015470105223357677,
      -0.06563092023134232,
      -0.02018333040177822,
      -0.026804909110069275,
      -0.050290241837501526,
      -0.0776653066277504,
      0.08482068032026291,
      -0.04823116958141327,
      0.11721674352884293,
      -0.07741662859916687,
      -0.057953208684921265,
      0.027330514043569565,
      0.030010312795639038,
      0.02472156286239624,
      0.006576839834451675,
      0.010822372511029243,
      -0.04032756760716438,
      -0.00721809733659029,
      0.024661587551236153,
      -0.02499331161379814,
      -0.04281541705131531,
      0.04111966863274574,
      0.01733403280377388,
      -0.008288362994790077,
      -0.02137898840010166,
      -0.08806305378675461,
      -0.03027922473847866,
      0.026023371145129204,
      0.004681027494370937,
      0.00492033502086997,
      -0.004380444530397654,
      0.006369286682456732,
      -0.007848034612834454,
      0.08125672489404678,
      -0.052077516913414,
      0.03362496569752693,
      0.03193286806344986,
      0.012941849417984486,
      0.05190713331103325,
      -0.011394642293453217,
      -0.02597881853580475,
      0.028623782098293304,
      0.03210537135601044,
      0.0439484529197216,
      -0.0026774192228913307,
      -0.03217525780200958,
      0.05264808610081673,
      -0.0955788865685463,
      -0.012089303694665432,
      0.06776009500026703,
      -0.04973483458161354,
      -0.054242804646492004,
      0.020116928964853287,
      0.05696605145931244,
      0.0020396809559315443,
      -0.03168502077460289,
      -0.0022989590652287006,
      -0.025240620598196983,
      -0.007265113759785891,
      0.08362686634063721,
      -0.01679888367652893,
      0.047296058386564255,
      -0.023254545405507088,
      0.030718915164470673,
      -0.08958087861537933,
      -0.10326632857322693,
      0.06374654918909073,
      -0.02270975150167942,
      -0.0003777354140765965,
      0.03651072084903717,
      -0.07425500452518463,
      -0.05048803985118866,
      -0.030400129035115242,
      0.039140596985816956,
      0.0007777580176480114,
      0.05862395837903023,
      -0.02540217526257038,
      -0.04177676886320114,
      0.092696413397789,
      0.0379372164607048,
      0.005679837893694639,
      -0.048643872141838074,
      -0.007245306856930256,
      0.07079194486141205,
      -0.038105424493551254,
      -0.0330306701362133,
      0.01080381404608488,
      -0.06296980381011963,
      -0.01219811849296093,
      -0.0013642561389133334,
      -0.05649387836456299,
      0.010445951484143734,
      -0.0679381713271141,
      -0.05882410705089569,
      -0.010561020113527775,
      0.0058320374228060246,
      0.00069730932591483,
      -0.01174092199653387,
      0.07145752012729645,
      0.06262265890836716,
      -0.006860864348709583,
      -0.0038489801809191704,
      0.013250994496047497,
      -0.057545147836208344,
      -0.05687477067112923,
      0.07226888090372086,
      -0.008156088180840015,
      -0.004630353767424822,
      0.003513702191412449,
      0.09824205189943314,
      0.04658083990216255,
      0.04663889482617378,
      -0.08924088627099991,
      -0.0762152299284935,
      0.008889398537576199,
      -0.04952280968427658,
      -0.015052185393869877,
      -0.058976151049137115,
      0.055657193064689636,
      0.03027448244392872,
      0.03413541242480278,
      -0.014187569729983807,
      -0.0168414656072855,
      -0.07967449724674225,
      -0.024566520005464554,
      -0.04185616970062256,
      -0.013684227131307125,
      -0.007467746268957853,
      -0.01184156071394682,
      -0.00101721310056746,
      -0.002707711886614561,
      -0.034480106085538864,
      -0.045207679271698,
      -0.02255711890757084,
      0.012909162789583206,
      0.07997418195009232,
      0.027254965156316757,
      -0.005714435130357742,
      -0.01498818676918745,
      0.0177420973777771,
      0.0007953579188324511,
      0.0160287544131279,
      0.04843693971633911,
      -0.06927772611379623,
      0.0279679037630558,
      -0.038765307515859604,
      0.002138125943019986,
      -0.07349126785993576,
      -0.04715464264154434,
      -0.02200385369360447,
      -0.0004994618357159197,
      -0.06896348297595978,
      0.007576717063784599,
      -0.029432088136672974,
      0.03532864898443222,
      0.05070855841040611,
      -0.04506167769432068,
      0.012382052838802338,
      0.006491117179393768,
      -0.014251168817281723,
      -0.09715460240840912,
      -0.00964186992496252,
      0.016112733632326126,
      0.04575852304697037,
      0.07651469856500626,
      -0.04017005115747452,
      0.010275019332766533,
      -0.07405270636081696,
      0.014317031018435955,
      0.008397509343922138,
      0.02490883134305477,
      -0.05421872064471245,
      -0.0335422158241272,
      -0.04131671041250229,
      0.0002370975271333009,
      -0.0374864786863327,
      -0.04894686117768288,
      -0.007572433911263943,
      0.03644053637981415,
      0.01061545591801405,
      -0.012437508441507816,
      0.005464197602123022,
      -0.035485703498125076,
      0.026337403804063797,
      -0.027391863986849785,
      0.034063275903463364,
      -0.03938809782266617,
      0.06708436459302902,
      -0.04625547677278519,
      0.03420570492744446,
      -0.005492118187248707,
      0.03372664377093315,
      -0.03099922090768814,
      -0.02013697661459446,
      0.07968396693468094,
      -0.02103687822818756,
      -0.06722081452608109,
      -0.05570811778306961,
      0.0681767389178276,
      -0.043906357139348984,
      0.015052523463964462,
      -0.05000108852982521,
      0.02433689497411251,
      0.03409980237483978,
      0.05473765730857849,
      0.03044370375573635,
      0.0006958266021683812,
      -0.004485568031668663,
      -0.04683105647563934,
      0.020840657874941826,
      -0.03547319397330284,
      -0.042936839163303375,
      0.08529247343540192,
      0.10161402076482773,
      0.04784718528389931,
      0.035318661481142044,
      -0.04852069541811943,
      -0.06280121207237244,
      0.023458603769540787,
      -0.0058345189318060875,
      -0.0020687161013484,
      -0.052086491137742996,
      -0.027016622945666313,
      0.02728152647614479,
      -0.028358224779367447,
      -0.02126694843173027,
      -0.01927933841943741,
      0.0021287931594997644,
      -0.03283696994185448,
      0.07433026283979416,
      -0.07614073157310486,
      0.05458083376288414,
      0.03938848897814751,
      0.04627617448568344,
      0.04428144916892052,
      0.06900344043970108,
      -0.05937204509973526,
      -0.019909508526325226,
      -0.07441291213035583,
      0.02423175796866417,
      -0.09500616788864136,
      -0.03949292004108429,
      -0.06314568966627121,
      0.017995255067944527,
      -0.09036268293857574,
      0.029568320140242577,
      -0.05775994062423706,
      0.005900276359170675,
      0.004170357249677181,
      0.016917169094085693,
      0.07693227380514145,
      0.03578096628189087,
      -0.030288951471447945,
      -0.02865985408425331,
      0.013104806654155254,
      0.016586909070611,
      0.08979091793298721,
      -0.06828057020902634,
      -0.018815401941537857,
      -0.03703689947724342,
      -0.06509699672460556,
      0.033949147909879684,
      0.03452150523662567,
      -0.005417331121861935,
      0.03560563921928406,
      0.05045560002326965,
      -0.0035077922511845827,
      -0.050783175975084305,
      0.017212647944688797,
      -0.09347422420978546,
      0.037940457463264465,
      -0.06389335542917252,
      0.08016321808099747,
      0.019122624769806862,
      -0.01084223110228777,
      -0.02942761965095997,
      -0.060148946940898895,
      0.07247120887041092,
      -0.10703936219215393,
      -0.0663570687174797,
      -0.016980493441224098,
      0.03681055083870888,
      -0.008912044577300549,
      0.024156173691153526,
      -0.00924388412386179,
      0.02673771232366562,
      0.04130009189248085,
      0.03938686102628708,
      -0.08669131249189377,
      -0.03095441311597824,
      -0.030920034274458885,
      0.021018804982304573,
      -0.05045836791396141,
      0.04742742329835892,
      -0.0011654659174382687,
      0.04282631725072861,
      0.09447380155324936,
      0.03730523958802223,
      -0.04480487108230591,
      0.05710526555776596,
      -0.04267449304461479,
      -0.0425436832010746,
      -0.04124848544597626,
      -0.014309256337583065,
      -0.07428683340549469,
      -0.018039386719465256,
      -0.061794716864824295,
      0.08312166482210159,
      -0.05386755242943764,
      -0.017335839569568634,
      -0.009044055826961994,
      -0.02147330716252327,
      0.0034194858744740486,
      0.046905361115932465,
      -0.009363045915961266,
      -0.026278076693415642,
      -0.027179565280675888,
      -9.506916103418916e-05,
      0.057865265756845474,
      0.08095293492078781,
      0.08719642460346222,
      -0.018531152978539467,
      -0.020563168451189995,
      0.019236622378230095,
      -0.018712928518652916,
      0.01808333955705166,
      -0.038388438522815704,
      0.00611999211832881,
      -0.030642518773674965,
      0.005970107391476631,
      -0.010222606360912323,
      0.0463390089571476,
      -0.08689805120229721,
      -0.04562319070100784,
      -0.010250188410282135,
      0.007737320847809315,
      -0.06318975985050201,
      0.09878551959991455,
      0.005293610040098429,
      -0.09913434088230133,
      0.030976207926869392,
      0.031703460961580276,
      0.0053610908798873425,
      -0.025577517226338387,
      0.02431672066450119,
      0.015233517624437809,
      -0.001636918750591576,
      0.029056336730718613,
      0.03663395345211029,
      0.012017481029033661,
      -0.022284679114818573,
      -0.010517426766455173,
      0.012991191819310188,
      -0.06322860717773438,
      -0.06604268401861191,
      0.03760041296482086,
      -0.047765493392944336,
      -0.104402557015419,
      0.0718974843621254,
      0.010696022771298885,
      0.02665070816874504,
      -0.04675270989537239,
      0.0028020222671329975,
      -0.026185564696788788,
      -0.06717132031917572,
      -0.019298961386084557,
      0.011530174873769283,
      0.051658496260643005,
      0.07440278679132462,
      0.030245112255215645,
      -0.03581945225596428,
      0.05693061277270317,
      0.023992372676730156,
      0.0845390185713768,
      -0.05867306515574455,
      0.054394856095314026,
      -0.03178105875849724,
      0.03247770667076111,
      -0.055765341967344284,
      -0.016732685267925262,
      0.08656025677919388,
      0.01131644006818533,
      -0.06745094805955887,
      -0.029945479705929756,
      -0.010438620112836361,
      0.009144715033471584,
      -0.06243081018328667,
      -0.02827131748199463,
      -0.03843763470649719,
      -0.010008592158555984,
      0.05314880609512329,
      -0.018645351752638817,
      -0.02079816162586212,
      -0.011249997653067112,
      0.005437594372779131
    ],
    [
      -0.056960590183734894,
      0.048769909888505936,
      -0.06536116451025009,
      -0.05404941365122795,
      0.0008478814852423966,
      -0.015543125569820404,
      -0.038839466869831085,
      -0.03447914496064186,
      0.02429346926510334,
      -0.028517017140984535,
      0.11337524652481079,
      -0.04338797554373741,
      -0.03464706242084503,
      -0.004053582902997732,
      0.0514843575656414,
      0.027553394436836243,
      0.06486193835735321,
      -0.022235974669456482,
      -0.029217010363936424,
      -0.012144454754889011,
      -0.07741065323352814,
      0.007600354962050915,
      -0.07002881169319153,
      -0.02592034824192524,
      -0.02767968364059925,
      -0.00024149761884473264,
      0.024742580950260162,
      0.035892754793167114,
      -0.022011810913681984,
      0.058525778353214264,
      0.050484757870435715,
      0.04722076281905174,
      -0.07584253698587418,
      -0.12454438954591751,
      -0.04066629335284233,
      0.022436821833252907,
      0.1288471221923828,
      -0.01723347418010235,
      -0.028593050315976143,
      -0.04512433707714081,
      0.056630704551935196,
      0.01918526366353035,
      0.05087069049477577,
      -0.00798809714615345,
      0.020357150584459305,
      -0.051687080413103104,
      0.0516432486474514,
      0.03352605924010277,
      -0.04020968824625015,
      0.0495477095246315,
      0.06475023925304413,
      -0.01161282416433096,
      0.02761104516685009,
      -0.03283704072237015,
      -0.05165684595704079,
      0.052459195256233215,
      -0.08315188437700272,
      0.0579327791929245,
      -0.06633588671684265,
      -0.008345692418515682,
      0.003698797896504402,
      0.019654113799333572,
      -0.04991467297077179,
      -0.02088133804500103,
      -0.014759593643248081,
      0.14714427292346954,
      0.06012322008609772,
      -0.04043613746762276,
      0.02840847708284855,
      0.017961911857128143,
      -0.0500970259308815,
      -0.03531752526760101,
      0.000916113902349025,
      -0.02897355519235134,
      -0.049380846321582794,
      -0.02317829802632332,
      0.018113847821950912,
      -0.004783776588737965,
      -0.042454201728105545,
      -0.08007069677114487,
      0.04180482029914856,
      -0.06837243586778641,
      -0.039617858827114105,
      0.02801555022597313,
      -0.019411789253354073,
      -0.02050112932920456,
      -0.031051039695739746,
      0.08743803948163986,
      -0.015791164711117744,
      -0.022764671593904495,
      -0.035679109394550323,
      0.06383339315652847,
      0.04265325516462326,
      -0.024971894919872284,
      0.05565935745835304,
      -0.09253945201635361,
      -0.04250038415193558,
      -0.0298006609082222,
      -0.01328462827950716,
      -0.06404633820056915,
      0.016512444242835045,
      -0.03745533525943756,
      -0.036498211324214935,
      -0.03815200552344322,
      0.06106242164969444,
      -0.015297284349799156,
      -0.055231209844350815,
      0.03256198391318321,
      0.0348372682929039,
      -0.07364591211080551,
      0.03320373594760895,
      0.005242344923317432,
      0.031138312071561813,
      -0.05220126360654831,
      -0.04420197010040283,
      -0.05912594124674797,
      -0.061060454696416855,
      0.05396081134676933,
      -0.038328867405653,
      0.05529024824500084,
      0.005794008262455463,
      -0.07595934718847275,
      0.02392931841313839,
      0.010379894636571407,
      -0.014735975302755833,
      -0.041806165128946304,
      -0.011608829721808434,
      0.0013758516870439053,
      -0.08141390979290009,
      -0.009470165707170963,
      -0.0070088631473481655,
      0.028295397758483887,
      0.042972978204488754,
      0.001969106262549758,
      -0.05856310576200485,
      0.0030253923032432795,
      -0.02109483815729618,
      0.021449457854032516,
      -0.052789825946092606,
      0.030959466472268105,
      0.00022737645485904068,
      0.06565424799919128,
      -0.0034655036870390177,
      0.06225758418440819,
      -0.06843847781419754,
      -0.03374674543738365,
      0.028815386816859245,
      0.034353744238615036,
      -0.022817742079496384,
      0.012126080691814423,
      -0.009610050357878208,
      -0.015905676409602165,
      0.01149443257600069,
      0.07509976625442505,
      0.020214958116412163,
      0.019537055864930153,
      -0.11877718567848206,
      -0.012808353640139103,
      0.02239304780960083,
      -0.04023391380906105,
      0.029926583170890808,
      0.03609395772218704,
      -0.02124113030731678,
      -0.007791042327880859,
      -0.02019607648253441,
      -0.025724157691001892,
      0.02569078467786312,
      -0.031798623502254486,
      0.014047219417989254,
      -0.007228693459182978,
      0.05574777349829674,
      -0.04322595149278641,
      -0.026111017912626266,
      -0.06936406344175339,
      -0.0348769910633564,
      -0.035884760320186615,
      -0.03991137444972992,
      0.028484007343649864,
      -0.03422936424612999,
      -0.07006174325942993,
      0.020496880635619164,
      0.03862079232931137,
      0.007573361974209547,
      0.017449302598834038,
      -0.06878881901502609,
      -0.05045352131128311,
      -0.023002613335847855,
      -0.10864607244729996,
      -0.06118251383304596,
      0.0009369613253511488,
      -0.011465649120509624,
      -0.024097446352243423,
      0.0019294440280646086,
      0.016170063987374306,
      -0.006213396321982145,
      -0.0008497493690811098,
      0.02411777898669243,
      -0.039660777896642685,
      -0.034161459654569626,
      0.08097322285175323,
      0.07148458063602448,
      0.09787321835756302,
      -0.015885083004832268,
      -0.11040567606687546,
      0.05080194026231766,
      0.026466557756066322,
      -0.027654238045215607,
      0.07365714013576508,
      -0.018669582903385162,
      -0.022563131526112556,
      0.0467519536614418,
      -0.002030993578955531,
      -0.016948295757174492,
      0.02469162456691265,
      0.00818342249840498,
      0.03866266459226608,
      0.03379656374454498,
      0.001097236410714686,
      -0.01391935907304287,
      -0.011917522177100182,
      -0.07544276863336563,
      -0.051037080585956573,
      0.05049710348248482,
      -0.008394544012844563,
      -0.024738021194934845,
      -0.06960521638393402,
      -0.07077793776988983,
      0.02353048510849476,
      -0.056730717420578,
      0.027672266587615013,
      0.00612611835822463,
      -0.007051123771816492,
      -0.05547749623656273,
      -0.004153642803430557,
      0.0367126502096653,
      0.030465027317404747,
      0.004926098510622978,
      0.050140514969825745,
      0.03929324448108673,
      -0.010132820345461369,
      0.08530119806528091,
      0.008151920512318611,
      -0.011245107278227806,
      0.062144581228494644,
      -0.03518097847700119,
      -0.03886089101433754,
      -0.0863819420337677,
      0.0279843769967556,
      0.02284347452223301,
      -0.020353350788354874,
      -0.033721137791872025,
      0.01702277921140194,
      0.03236931189894676,
      0.0017904663691297174,
      -0.03270904719829559,
      0.04309775307774544,
      0.027461878955364227,
      -0.013902125880122185,
      0.061008524149656296,
      -0.06534367054700851,
      0.05128266289830208,
      0.08780678361654282,
      0.01625344157218933,
      0.006027027498930693,
      -0.0332823321223259,
      -0.006566280499100685,
      0.009688539430499077,
      0.0025035333819687366,
      0.047486454248428345,
      0.04243826866149902,
      0.13837400078773499,
      0.007730056997388601,
      0.002462947741150856,
      0.03300181031227112,
      0.051642950624227524,
      -0.03384940326213837,
      0.015345774590969086,
      0.043778982013463974,
      0.06134876236319542,
      0.07005596905946732,
      0.01869344897568226,
      0.006831579841673374,
      -0.013847866095602512,
      -0.020665466785430908,
      0.042692601680755615,
      -0.004793664440512657,
      -0.05249986797571182,
      0.013514744117856026,
      -0.0762268677353859,
      -0.013792075216770172,
      -0.06385079771280289,
      0.038384612649679184,
      0.011657790280878544,
      -0.0055884928442537785,
      -0.0390482172369957,
      0.0010908832773566246,
      0.049426231533288956,
      -0.014704275876283646,
      -0.024797586724162102,
      0.0018834806978702545,
      0.05214567855000496,
      0.059069421142339706,
      0.029755892232060432,
      0.047470949590206146,
      -0.04106839373707771,
      -0.039632778614759445,
      -0.058757614344358444,
      0.12028171122074127,
      0.0388970673084259,
      0.03463800251483917,
      0.04954376816749573,
      0.037845395505428314,
      0.05701136216521263,
      -0.05942332372069359,
      -0.024554291740059853,
      -0.05179353430867195,
      0.02253773994743824,
      0.033222876489162445,
      -0.010575806722044945,
      0.0313086174428463,
      0.008508115075528622,
      0.01073030848056078,
      0.023413080722093582,
      -0.0321895070374012,
      0.0688958615064621,
      -0.06414792686700821,
      -0.051108743995428085,
      -0.05135860666632652,
      -0.007325685117393732,
      0.013359682634472847,
      -0.055564429610967636,
      -0.020687779411673546,
      -0.046901363879442215,
      0.015898995101451874,
      0.018506674095988274,
      -0.032116446644067764,
      -0.027561424300074577,
      0.08551841974258423,
      0.08599463105201721,
      -0.004723054356873035,
      0.013956956565380096,
      -0.03786231949925423,
      0.0519334152340889,
      0.014345158822834492,
      0.038643963634967804,
      0.037887949496507645,
      0.004198820795863867,
      -0.01373162679374218,
      0.069207102060318,
      0.053828977048397064,
      -0.033556658774614334,
      -0.04540444165468216,
      -0.09206841886043549,
      0.07723167538642883,
      0.09245923906564713,
      -0.054672278463840485,
      0.011469747871160507,
      0.015321404673159122,
      -0.008280639536678791,
      -0.005085103213787079,
      -0.02458018995821476,
      0.007322992198169231,
      0.0037678212393075228,
      0.042922493070364,
      0.037961069494485855,
      -0.04420603811740875,
      0.012245085090398788,
      0.010229784063994884,
      0.06923530995845795,
      -0.033819254487752914,
      -0.007057594135403633,
      -0.02429969236254692,
      -0.03716985136270523,
      0.03383077308535576,
      0.06252206116914749,
      -0.07174599915742874,
      -0.059424854815006256,
      0.03611336648464203,
      0.010751809924840927,
      -0.04064486548304558,
      -0.08153913170099258,
      -0.016529550775885582,
      -0.03124612383544445,
      -0.03982001543045044,
      0.021383630111813545,
      -0.016916191205382347,
      -0.016060721129179,
      -0.07220055162906647,
      0.0650429055094719,
      -0.029993489384651184,
      -0.1345311850309372,
      0.00020157461403869092,
      -0.06751558184623718,
      -0.01727667637169361,
      0.06837932765483856,
      -0.02550913393497467,
      -0.02530822344124317,
      0.03441936522722244,
      -0.017995834350585938,
      -0.009587636217474937,
      -0.02691207081079483,
      -0.009708266705274582,
      -0.07856980711221695,
      -0.03170306608080864,
      -0.028805339708924294,
      -0.04811179265379906,
      0.05135650932788849,
      0.008301930502057076,
      0.030123339965939522,
      -0.0033015739172697067,
      0.04871825501322746,
      0.08526891469955444,
      0.0792640969157219,
      0.022994941100478172,
      -0.025346465408802032,
      -0.05305638909339905,
      -0.03026467189192772,
      -0.021021464839577675,
      0.0265364870429039,
      -0.012600509449839592,
      0.005356733687222004,
      -0.04460413008928299,
      -0.023638397455215454,
      -0.045213792473077774,
      -0.005700522568076849,
      0.03858057036995888,
      -0.00972632598131895,
      0.026950199156999588,
      0.045992765575647354,
      -0.003628065576776862,
      -0.04110683128237724,
      0.027523275464773178,
      -0.02018275111913681,
      0.07866088300943375,
      0.033392369747161865,
      0.011051932349801064,
      0.09142456203699112,
      0.012829300947487354,
      -0.027270497754216194,
      -0.027491191402077675,
      -0.014667470008134842,
      0.07474544644355774,
      0.08170896768569946,
      -0.027036767452955246,
      0.07865709811449051,
      0.07205763459205627,
      0.08681180328130722,
      -0.02695135958492756,
      0.031157054007053375,
      0.015803560614585876,
      -0.007972526364028454,
      0.05556164309382439,
      0.003849593922495842,
      -0.02657010219991207,
      -0.03424416109919548,
      -0.02604873850941658,
      -0.0035413310397416353,
      -0.01353207603096962,
      -0.01401867438107729,
      -0.012706027366220951,
      -0.05592134967446327,
      -0.0036603533662855625,
      -0.08876187354326248,
      0.03894834220409393,
      0.02586851269006729,
      0.017126433551311493,
      -0.08872921019792557,
      -0.010210331529378891,
      0.020082104951143265,
      0.08076190948486328,
      -0.009412109851837158,
      0.07735513895750046,
      -0.014327016659080982,
      0.034599900245666504,
      0.07955239713191986,
      0.0558638758957386,
      0.0005987371550872922,
      0.055185042321681976,
      -0.015385959297418594,
      -0.059932343661785126,
      -0.10714862495660782,
      0.04429001361131668,
      -0.0233312901109457,
      0.023014497011899948,
      0.018088914453983307,
      0.05154651030898094,
      0.046912916004657745,
      0.02644430659711361,
      0.046878982335329056,
      0.01731131784617901,
      0.09933092445135117,
      -0.00668665487319231,
      0.054794903844594955,
      0.008869970217347145,
      0.0553002804517746,
      0.036977287381887436,
      0.021081294864416122,
      -0.0054882587864995,
      -0.010921317152678967,
      0.02240576595067978,
      0.0043495758436620235,
      -0.02913842909038067,
      -0.020965537056326866
    ],
    [
      -0.007099145092070103,
      0.11504877358675003,
      0.013249017298221588,
      -0.01668577641248703,
      -0.06640169769525528,
      0.04121322184801102,
      -0.007002352736890316,
      0.056157246232032776,
      0.00418436573818326,
      0.03509974852204323,
      0.08778651803731918,
      0.022574016824364662,
      0.025053171440958977,
      -0.050030551850795746,
      0.04352196678519249,
      0.03739982843399048,
      0.07650831341743469,
      -0.00590222654864192,
      -0.0052211894653737545,
      0.04719365015625954,
      -0.017410069704055786,
      -0.01689808815717697,
      0.014716194942593575,
      -0.08215510100126266,
      0.008080702275037766,
      0.08473379164934158,
      -0.00382406753487885,
      -0.01914173737168312,
      -0.02538740634918213,
      -0.04992254078388214,
      0.02020244300365448,
      0.001484576496295631,
      -0.029983535408973694,
      -0.027121534571051598,
      0.08008041977882385,
      -0.0003898346330970526,
      -0.007976916618645191,
      0.026134861633181572,
      0.008099392987787724,
      0.011993148364126682,
      0.02888541668653488,
      0.029630210250616074,
      -0.049240853637456894,
      -0.006681320257484913,
      0.031427182257175446,
      0.0202920064330101,
      0.05260978639125824,
      0.015523994341492653,
      -0.009182627312839031,
      0.06105046719312668,
      -0.020460089668631554,
      -0.046581003814935684,
      -0.06887532770633698,
      0.05696012079715729,
      -0.07388761639595032,
      -0.07428048551082611,
      0.026117989793419838,
      -0.017972003668546677,
      -0.012251924723386765,
      -0.03620089590549469,
      0.010744809173047543,
      -0.05523035302758217,
      0.029386034235358238,
      0.04175916314125061,
      -0.018425701186060905,
      -0.0324946865439415,
      -0.022788019850850105,
      -0.002124589867889881,
      0.0341862253844738,
      0.040215130895376205,
      -0.03563961386680603,
      0.07157744467258453,
      0.09703335165977478,
      0.002201045397669077,
      0.010527901351451874,
      -0.029641686007380486,
      -0.05843191593885422,
      0.014864292927086353,
      0.04005061089992523,
      -0.07065548002719879,
      -0.004698652308434248,
      -0.0527731291949749,
      0.036936353892087936,
      -0.08264300227165222,
      0.06684671342372894,
      -0.04468972980976105,
      -0.03533943369984627,
      0.0030555319972336292,
      -0.030799103900790215,
      -0.02255687117576599,
      -0.017106160521507263,
      -0.02304225228726864,
      0.010448712855577469,
      0.046960946172475815,
      -0.060214731842279434,
      0.0020741350017488003,
      -0.029042823240160942,
      0.07802630215883255,
      -0.04137654975056648,
      -0.035728197544813156,
      -0.011405699886381626,
      -0.04806492477655411,
      0.007421975955367088,
      0.023259056732058525,
      0.048195164650678635,
      -0.04716440290212631,
      -0.06913384795188904,
      -0.05528879910707474,
      -0.07491769641637802,
      0.009039714001119137,
      -0.042567815631628036,
      0.0033902910072356462,
      -0.022790871560573578,
      -0.04872902110219002,
      0.006134327966719866,
      0.03224244341254234,
      -0.02529073692858219,
      0.11072767525911331,
      -0.03011924959719181,
      -0.020974688231945038,
      0.03603283315896988,
      -0.05376022309064865,
      -0.1038944348692894,
      0.037578143179416656,
      -0.0635053813457489,
      0.015538563951849937,
      0.025367120280861855,
      -0.08820460736751556,
      0.02253291942179203,
      0.02308080531656742,
      0.07178802043199539,
      0.02644258551299572,
      -0.06803324073553085,
      0.05032951757311821,
      -0.00041913148015737534,
      -0.08775810152292252,
      -0.09663812816143036,
      -0.0007631983608007431,
      -0.019976774230599403,
      0.01196917425841093,
      0.040833767503499985,
      -0.052122145891189575,
      0.05550079792737961,
      0.07378389686346054,
      0.058656882494688034,
      -0.03581535071134567,
      0.10146240144968033,
      0.021167317405343056,
      0.03616880625486374,
      0.03772060573101044,
      0.024641593918204308,
      -0.05927152559161186,
      0.03964266926050186,
      -0.004328898619860411,
      0.03659368306398392,
      -0.03144655376672745,
      0.03889108821749687,
      0.061067115515470505,
      0.06985782086849213,
      -0.003781707724556327,
      0.07497265934944153,
      0.045620065182447433,
      -0.021104756742715836,
      -0.01367108803242445,
      -0.0726528987288475,
      -0.019495675340294838,
      -0.05194398760795593,
      0.045098185539245605,
      -0.0138888880610466,
      -0.028348462656140327,
      -0.03160933405160904,
      0.02008993551135063,
      0.029936518520116806,
      0.024654734879732132,
      -0.030138395726680756,
      0.028761735185980797,
      -0.026668190956115723,
      0.004611715208739042,
      -0.004159052390605211,
      0.011558596044778824,
      -0.028505872935056686,
      0.029314691200852394,
      -0.07911524921655655,
      0.05411701276898384,
      -0.0691709816455841,
      0.01414098683744669,
      -0.04159129410982132,
      0.02490811236202717,
      -0.04984939098358154,
      0.08676216006278992,
      0.04690508916974068,
      -0.006432114169001579,
      0.021226931363344193,
      -0.021266696974635124,
      0.018179694190621376,
      -0.050005216151475906,
      -0.00945647805929184,
      -0.016061503440141678,
      0.020906126126646996,
      0.08434607088565826,
      -0.050698887556791306,
      0.00660299975425005,
      0.04995139315724373,
      -0.026356801390647888,
      -0.024550676345825195,
      -0.016279594972729683,
      -0.03152528777718544,
      0.0034511506091803312,
      -0.021257715299725533,
      0.021624617278575897,
      0.05105496570467949,
      -0.00020347003010101616,
      0.06670401990413666,
      0.00535976979881525,
      -0.0589805506169796,
      -0.004541230853646994,
      -0.030674723908305168,
      -0.019405506551265717,
      -0.014654073864221573,
      -0.012807920575141907,
      -0.01753535121679306,
      -0.07104219496250153,
      0.09383971244096756,
      -0.027365338057279587,
      -0.01527982484549284,
      -0.003020644886419177,
      -0.06406091153621674,
      -0.03813952952623367,
      0.022822987288236618,
      -0.03542761132121086,
      -0.0628272071480751,
      -0.06900489330291748,
      0.03939814865589142,
      0.04649665951728821,
      -0.019852876663208008,
      0.012947973795235157,
      -0.06835706532001495,
      -0.0098996851593256,
      0.023546097800135612,
      -0.044504642486572266,
      -0.03269200026988983,
      -0.02489011362195015,
      0.03240031376481056,
      -0.02148793451488018,
      -0.07122111320495605,
      -0.035341933369636536,
      -0.016900401562452316,
      -0.06606440246105194,
      0.032211340963840485,
      0.0075459666550159454,
      -0.039073698222637177,
      0.013172971084713936,
      0.014242616482079029,
      0.020672284066677094,
      -0.04881984367966652,
      0.0003469641087576747,
      -0.010563969612121582,
      -0.013183167204260826,
      0.064763143658638,
      0.00974445790052414,
      -0.0214468352496624,
      -0.07659739255905151,
      0.09139733016490936,
      -0.07786476612091064,
      0.007222763728350401,
      0.03012501262128353,
      -0.05685177445411682,
      -0.03131384029984474,
      0.051893990486860275,
      -0.01796925626695156,
      0.04226531460881233,
      0.0384671613574028,
      -0.013357510790228844,
      -0.008594529703259468,
      0.021110305562615395,
      0.03857513144612312,
      0.014952968806028366,
      0.049337610602378845,
      -0.005799747072160244,
      -0.04593870788812637,
      -0.0017044590786099434,
      -0.000810661818832159,
      0.0175624992698431,
      0.054780248552560806,
      -0.06905845552682877,
      -0.002960942452773452,
      -0.031006358563899994,
      0.019113784655928612,
      -0.052626095712184906,
      0.0017407686682417989,
      0.012268956750631332,
      -0.03825471177697182,
      0.043874144554138184,
      0.08196719735860825,
      -0.004321342334151268,
      -0.031158532947301865,
      0.009200726635754108,
      -0.07613815367221832,
      -0.005386317614465952,
      0.022315625101327896,
      -0.04116490110754967,
      -0.02361973747611046,
      -0.03755176439881325,
      -0.010093862190842628,
      -0.026282699778676033,
      0.06401341408491135,
      0.0028733692597597837,
      -0.0516526885330677,
      -0.11038083583116531,
      0.043122656643390656,
      0.08245082199573517,
      -0.0034518695902079344,
      -0.045016638934612274,
      -0.10663580149412155,
      0.05088668316602707,
      -0.08104466646909714,
      0.03869055211544037,
      0.02830118127167225,
      -0.013983739539980888,
      -0.03153698891401291,
      0.006234984844923019,
      -0.007004108279943466,
      0.02060253731906414,
      0.023761829361319542,
      -0.011171405203640461,
      0.025182576850056648,
      0.01748282089829445,
      -0.011881249956786633,
      0.005899170413613319,
      0.00206163851544261,
      0.046218354254961014,
      0.03360867127776146,
      -0.05867915228009224,
      -0.03523048385977745,
      0.07580389082431793,
      0.007369003724306822,
      -0.004257567692548037,
      -0.04315290227532387,
      0.08143652230501175,
      0.025009533390402794,
      0.05495327711105347,
      -0.015642082318663597,
      -0.03318348899483681,
      0.0036657017190009356,
      -0.0013055361341685057,
      -0.005771427880972624,
      -0.10425107926130295,
      0.08783674985170364,
      -0.008884210139513016,
      0.034562040120363235,
      -0.01493595540523529,
      0.049279067665338516,
      0.0331881083548069,
      0.01799514703452587,
      0.024067288264632225,
      -0.03949178755283356,
      0.02518068626523018,
      0.09470260143280029,
      0.02881830930709839,
      -0.012096919119358063,
      0.004008887801319361,
      0.004105468280613422,
      0.019824929535388947,
      0.023991648107767105,
      0.09464956074953079,
      -0.03593999519944191,
      -0.01545173954218626,
      0.0853109210729599,
      -0.023214448243379593,
      -0.023929890245199203,
      0.08699739724397659,
      -0.02908944897353649,
      0.027486350387334824,
      0.0006525972275994718,
      -0.03309411182999611,
      -0.06448519229888916,
      -0.06754955649375916,
      0.004074574913829565,
      0.04863453656435013,
      -0.0409567654132843,
      -0.0066060759127140045,
      0.02173496037721634,
      0.057028040289878845,
      0.02277238853275776,
      0.004244303796440363,
      -0.06411974877119064,
      0.036402370780706406,
      -0.035191986709833145,
      0.058283571153879166,
      0.006807469297200441,
      0.024699557572603226,
      -0.01696658879518509,
      -0.0054842387326061726,
      -0.04458203911781311,
      0.019071277230978012,
      0.009457623586058617,
      0.005936498288065195,
      -0.018041539937257767,
      -0.03929317370057106,
      -0.06238754838705063,
      -0.026127889752388,
      0.06698480248451233,
      -0.044998448342084885,
      0.0011589801870286465,
      -0.007383682299405336,
      0.05002475902438164,
      -0.0025195933412760496,
      -0.004621881525963545,
      -0.042726073414087296,
      -0.010809672996401787,
      -0.1011296883225441,
      0.0030626035295426846,
      -0.046050794422626495,
      -0.03633582592010498,
      0.016137639060616493,
      -0.040314752608537674,
      -0.0408516451716423,
      0.059699647128582,
      0.04929882287979126,
      -0.04322917386889458,
      0.01759311743080616,
      0.0379023402929306,
      0.014510927721858025,
      0.009388944134116173,
      0.012242498807609081,
      -0.008106055669486523,
      -0.023709122091531754,
      -0.02767578326165676,
      -0.01867787167429924,
      0.02156604826450348,
      0.0574880987405777,
      0.01842292957007885,
      -0.007899213582277298,
      -0.027284463867545128,
      -0.0661633312702179,
      -0.04423891380429268,
      -0.03362613916397095,
      0.04763336479663849,
      -0.10614169389009476,
      0.015000315383076668,
      0.12449457496404648,
      0.008662250824272633,
      -0.019170982763171196,
      0.01194223202764988,
      0.02006356418132782,
      0.012035837396979332,
      0.007003593724220991,
      -0.009357438422739506,
      0.015401584096252918,
      -0.03480696305632591,
      0.09766210615634918,
      -0.011088583618402481,
      0.02298366092145443,
      -0.02968359924852848,
      -0.0063082631677389145,
      0.12146388739347458,
      0.03752093389630318,
      0.07814899832010269,
      -0.02290785312652588,
      -0.004563287366181612,
      0.01106592733412981,
      0.026682840660214424,
      -0.0033743472304195166,
      0.0008059054962359369,
      -0.02699519693851471,
      0.07368244975805283,
      0.04486716538667679,
      0.03750120475888252,
      0.044946298003196716,
      -0.0014100446132943034,
      -0.023595081642270088,
      -0.05386045575141907,
      -0.012949147261679173,
      -0.02691439352929592,
      -0.018546514213085175,
      -0.015866465866565704,
      -0.050829216837882996,
      -0.013361293822526932,
      0.025604380294680595,
      -0.00932599138468504,
      0.0625496581196785,
      -0.08911365270614624,
      0.0649949386715889,
      -0.01835179701447487,
      -0.00043177208863198757,
      -0.006148235406726599,
      -0.04104485735297203,
      0.03634615242481232,
      0.04269902780652046,
      -0.0929035171866417,
      0.03790634870529175,
      -0.006824145093560219,
      0.045518919825553894,
      -0.03059062361717224,
      -0.006762932054698467,
      0.054326269775629044,
      0.002068146364763379,
      0.014861701056361198,
      -0.07321784645318985,
      0.03516533225774765,
      -0.02045322023332119,
      0.05007493123412132,
      -0.05792739987373352
    ],
    [
      0.019705016165971756,
      0.031356874853372574,
      0.004790512379258871,
      -0.002799423411488533,
      -0.08312481641769409,
      0.04483470320701599,
      0.047182925045490265,
      0.000672699068672955,
      0.0642412081360817,
      -0.01795501820743084,
      -0.025514228269457817,
      -0.02550271712243557,
      0.010173114947974682,
      -0.04252857714891434,
      0.019584475085139275,
      -0.029884349554777145,
      -0.04503500834107399,
      -0.06093962490558624,
      0.021132897585630417,
      -0.06760968267917633,
      0.028065349906682968,
      -0.04445035383105278,
      -0.006791410502046347,
      0.006319545675069094,
      0.018323838710784912,
      -0.04641088843345642,
      0.016482088714838028,
      0.03817075863480568,
      0.08679089695215225,
      -0.002592183882370591,
      -0.001707982155494392,
      -0.08199466019868851,
      0.0009609800181351602,
      -0.002937478944659233,
      -0.08705225586891174,
      -0.02351113222539425,
      0.011393255554139614,
      0.025945991277694702,
      0.06054229661822319,
      -0.08585742115974426,
      0.06313188374042511,
      -0.01181807555258274,
      -0.06783843040466309,
      0.024602197110652924,
      0.04699147492647171,
      0.04681333526968956,
      0.10389190167188644,
      -0.014096099883317947,
      0.04834308847784996,
      -0.03219941258430481,
      0.010927241295576096,
      0.025614256039261818,
      -0.0790363997220993,
      -0.00025656650541350245,
      -0.03223389759659767,
      -0.015165835618972778,
      -0.011974445544183254,
      -0.01730980910360813,
      0.07320033013820648,
      -0.015328040346503258,
      -0.09630367159843445,
      0.00341052352450788,
      0.007627044804394245,
      0.03316914662718773,
      -0.005408904515206814,
      0.03151793032884598,
      -0.04363761842250824,
      -0.009456297382712364,
      -0.09718631207942963,
      -0.06794703006744385,
      0.006619537714868784,
      -0.03659527748823166,
      -0.00428363960236311,
      0.028681211173534393,
      0.02520446479320526,
      -0.0321253165602684,
      -0.010984739288687706,
      0.04737013205885887,
      -0.020382650196552277,
      -0.0028495141305029392,
      0.05598388984799385,
      -0.042157988995313644,
      0.0519469678401947,
      -0.018773861229419708,
      -0.036406174302101135,
      0.03488948568701744,
      0.0427749902009964,
      0.03273891285061836,
      -0.057914409786462784,
      -0.008776115253567696,
      0.04177185148000717,
      -0.004813161678612232,
      0.06732512265443802,
      -0.032491594552993774,
      0.02927146479487419,
      -0.031597718596458435,
      0.05506041273474693,
      0.025411607697606087,
      -0.056134771555662155,
      0.01175856962800026,
      0.06222354993224144,
      -0.04962778836488724,
      -0.011331668123602867,
      0.08325575292110443,
      0.06015406921505928,
      -0.03268131986260414,
      -0.04138834774494171,
      -0.029080184176564217,
      0.07844372093677521,
      -0.023786500096321106,
      0.022680938243865967,
      -0.015094686299562454,
      0.03650049865245819,
      -0.004250985104590654,
      -0.05772189423441887,
      -0.026769183576107025,
      0.03217508643865585,
      -0.015479306690394878,
      -0.010264609009027481,
      0.0011136787943542004,
      0.02420935407280922,
      -0.0027009323239326477,
      0.05476629361510277,
      0.09742863476276398,
      0.019811278209090233,
      0.07991138845682144,
      -0.040428660809993744,
      -0.06425798684358597,
      -0.04303235560655594,
      -0.04444993659853935,
      -0.011965609155595303,
      -0.027392081916332245,
      -0.03729923442006111,
      0.04826226830482483,
      0.07704458385705948,
      -0.029282372444868088,
      0.08335158228874207,
      -0.02228367328643799,
      0.00740937702357769,
      0.0006499098381027579,
      0.0065309456549584866,
      0.07062888890504837,
      -0.048644933849573135,
      0.04111086204648018,
      -0.05292622745037079,
      0.006469666957855225,
      -0.025163665413856506,
      -0.05016201362013817,
      0.03355826064944267,
      -0.05196034163236618,
      0.011779488995671272,
      0.04549004137516022,
      0.004700799472630024,
      0.012921231798827648,
      0.04670412465929985,
      0.03523527458310127,
      -0.025563836097717285,
      -0.013955606147646904,
      0.006479309871792793,
      -0.013923738151788712,
      -0.08300773799419403,
      0.0072886748239398,
      0.05636897310614586,
      -0.005123834125697613,
      -0.029003597795963287,
      0.015429648570716381,
      -0.007565200794488192,
      -0.009570026770234108,
      -0.02694900892674923,
      0.02091350220143795,
      -0.016962559893727303,
      0.035383440554142,
      0.013927709311246872,
      0.045075055211782455,
      -0.03859720379114151,
      0.06594445556402206,
      0.01557004265487194,
      0.06617043167352676,
      0.03093276359140873,
      -0.04229648411273956,
      0.0766882672905922,
      0.06667154282331467,
      -0.01140141673386097,
      -0.0005551216891035438,
      -0.00360979326069355,
      0.0038639630656689405,
      0.019631246104836464,
      0.07036900520324707,
      0.012418464757502079,
      -0.050016459077596664,
      -0.10116951167583466,
      -0.060665328055620193,
      0.08780428022146225,
      -0.01929452456533909,
      -0.06353145837783813,
      -0.004936869256198406,
      -0.03694263845682144,
      0.03368467092514038,
      -0.010054602287709713,
      -0.03532468155026436,
      -0.12641164660453796,
      0.027207616716623306,
      -0.004076879937201738,
      0.05321965739130974,
      0.017455298453569412,
      0.02234688214957714,
      0.05663987994194031,
      0.034066375344991684,
      -0.06562076508998871,
      -0.09850771725177765,
      0.005278531927615404,
      -0.005336822010576725,
      -0.05521504208445549,
      0.08407773077487946,
      0.10196748375892639,
      0.022494634613394737,
      0.06922859698534012,
      -0.054561179131269455,
      0.07098645716905594,
      0.03272373974323273,
      -0.041345372796058655,
      -0.12425202131271362,
      0.06828382611274719,
      -0.020562943071126938,
      -0.06918155401945114,
      -0.032788388431072235,
      -0.04746134206652641,
      0.001500272541306913,
      0.02482997067272663,
      -0.05663253739476204,
      0.0011269537499174476,
      -0.04851078614592552,
      0.02821211703121662,
      -0.058969900012016296,
      -0.01954880729317665,
      0.011742995120584965,
      -0.07979397475719452,
      0.019694549962878227,
      -0.06059756502509117,
      -0.06664023548364639,
      0.04557441547513008,
      0.022539379075169563,
      -0.002520596142858267,
      0.05087226256728172,
      -0.0544256791472435,
      0.0025379015132784843,
      -0.0443468801677227,
      0.012408328242599964,
      0.0404217354953289,
      0.048663102090358734,
      0.02506658434867859,
      0.036588363349437714,
      0.03822092339396477,
      0.021761976182460785,
      -0.04917452111840248,
      0.036825165152549744,
      0.05737854167819023,
      -0.025075655430555344,
      0.027248859405517578,
      0.04002191871404648,
      0.04779181629419327,
      -0.05494121089577675,
      -0.022322965785861015,
      0.03073757328093052,
      0.061529844999313354,
      0.04277490824460983,
      -0.058560702949762344,
      0.0016471361741423607,
      0.05680248886346817,
      -0.0026759786996990442,
      -0.032100025564432144,
      -0.08652760088443756,
      0.002153384266421199,
      -0.039990853518247604,
      -0.08351407945156097,
      0.006793362554162741,
      0.05640299245715141,
      0.006520713213831186,
      -0.002393893664702773,
      0.03315100446343422,
      -0.024091044440865517,
      -0.04224439337849617,
      -0.0238200630992651,
      -0.03190382197499275,
      -0.03145536035299301,
      0.032747942954301834,
      -0.05682651698589325,
      -0.012243734672665596,
      0.020671045407652855,
      0.11878648400306702,
      0.022849954664707184,
      -0.04927488788962364,
      0.06065933033823967,
      0.06295757740736008,
      -0.08193156123161316,
      0.015506151132285595,
      -0.027345407754182816,
      0.041553985327482224,
      0.007988200522959232,
      0.02157563343644142,
      0.06921499967575073,
      -0.029639728367328644,
      -0.0008122831932269037,
      0.019430484622716904,
      0.05541396141052246,
      0.02120892144739628,
      0.06979391723871231,
      0.001154416473582387,
      0.031730856746435165,
      0.025451576337218285,
      0.05077150836586952,
      -0.04111851751804352,
      0.08916309475898743,
      0.0981336161494255,
      -0.048987336456775665,
      0.018240243196487427,
      0.05306459963321686,
      -0.04250155761837959,
      -0.0005309526459313929,
      -0.02975158579647541,
      0.03669237345457077,
      0.01207304559648037,
      0.007438269909471273,
      0.02891906164586544,
      -0.011933433823287487,
      -0.03637514263391495,
      -0.037388063967227936,
      0.06372062116861343,
      -0.009760509245097637,
      -0.03297295793890953,
      -0.055677201598882675,
      0.024820566177368164,
      0.07666224241256714,
      0.012983002699911594,
      -0.018674012273550034,
      -0.020136713981628418,
      0.00038395996671169996,
      -0.012808343395590782,
      0.05376724898815155,
      0.05490588769316673,
      -0.014119320549070835,
      -0.07533847540616989,
      0.014013194479048252,
      -0.009184429422020912,
      -0.004500167910009623,
      -0.010284875519573689,
      0.015580921433866024,
      0.0760466456413269,
      -0.01733528822660446,
      -0.015816329047083855,
      -0.03824001923203468,
      0.00025289555196650326,
      -0.0003553100395947695,
      0.003056249348446727,
      -0.0023724883794784546,
      0.05611470341682434,
      -0.02132965251803398,
      0.002462326781824231,
      -0.0010616891086101532,
      0.08029354363679886,
      -0.03822529315948486,
      0.004377908539026976,
      -0.021701529622077942,
      -0.00359705975279212,
      0.04445328935980797,
      -0.10181225091218948,
      -0.028185507282614708,
      -0.008837377652525902,
      -0.0607941560447216,
      -0.06133880466222763,
      0.03272142633795738,
      0.00698067294433713,
      -0.024779748171567917,
      -0.039438676089048386,
      -0.014597511850297451,
      0.001953158061951399,
      -0.042849622666835785,
      0.06276032328605652,
      -0.002976172138005495,
      0.04727552458643913,
      0.00747506832703948,
      0.010159337893128395,
      0.035216230899095535,
      -0.025455672293901443,
      -0.026501594111323357,
      -0.005600753705948591,
      -0.05832464620471001,
      0.04001539573073387,
      0.0660586878657341,
      0.06135759502649307,
      0.11303701996803284,
      0.014695205725729465,
      0.03465619683265686,
      0.0010761376470327377,
      -0.040429484099149704,
      0.03521989658474922,
      -0.0601995624601841,
      -0.019123714417219162,
      0.04734954610466957,
      -0.017808759585022926,
      0.027804812416434288,
      -0.05796727538108826,
      0.00017742937779985368,
      0.005313585512340069,
      0.035155437886714935,
      0.03774138540029526,
      0.02548370324075222,
      0.03907470777630806,
      -0.0017882362008094788,
      0.006930678151547909,
      -0.02567862719297409,
      -0.01655651070177555,
      0.04191262274980545,
      -0.04625317081809044,
      0.018025381490588188,
      -0.06413550674915314,
      -0.005269770976155996,
      -0.027242956683039665,
      0.020049316808581352,
      0.037192702293395996,
      -0.010004430077970028,
      -0.011748068034648895,
      -0.03573691472411156,
      0.06324724853038788,
      -0.007163900416344404,
      -0.016410231590270996,
      -0.0027531117666512728,
      -0.0531376451253891,
      -0.12239503860473633,
      -0.011671044863760471,
      -0.03512364253401756,
      -0.041083287447690964,
      0.04704487696290016,
      -0.052798300981521606,
      0.06505093723535538,
      0.0031078075990080833,
      -0.05309168994426727,
      -0.043508727103471756,
      -0.03435036912560463,
      0.10326306521892548,
      0.027765236794948578,
      -0.01249779388308525,
      -0.018618013709783554,
      0.026128286495804787,
      -0.04083568602800369,
      0.05738893523812294,
      -0.02174830064177513,
      -0.06061260402202606,
      -0.0005128187476657331,
      -0.02195473201572895,
      -0.013702886179089546,
      -0.033562906086444855,
      -0.04611845687031746,
      0.027273042127490044,
      -0.02334803342819214,
      0.0713871493935585,
      -0.019474154338240623,
      0.0077597652561962605,
      0.011994282715022564,
      0.015477610751986504,
      0.07843342423439026,
      -0.012734523974359035,
      0.0032859935890883207,
      -0.03918568789958954,
      0.057071976363658905,
      -0.06010732427239418,
      -0.004876293707638979,
      -0.020251179113984108,
      0.018146654590964317,
      -0.09833918511867523,
      -0.03337889537215233,
      0.027677401900291443,
      0.04865594208240509,
      0.055015064775943756,
      -0.05705831199884415,
      -0.010323775932192802,
      -0.07858812808990479,
      -0.00019270970369689167,
      -0.009114384651184082,
      0.05455011501908302,
      0.03481433540582657,
      -0.023309199139475822,
      0.010192977264523506,
      0.03298123925924301,
      -0.03881925344467163,
      -0.14135314524173737,
      0.046802785247564316,
      0.09966576844453812,
      -0.035885490477085114,
      0.013479866087436676,
      -0.026175720617175102,
      0.0756886750459671,
      0.04252500832080841,
      -0.007365361321717501,
      0.04318070039153099,
      0.005973941180855036,
      0.01710524410009384,
      1.0717824807215948e-05,
      -0.056022483855485916,
      -0.036576732993125916,
      0.10148777812719345,
      -0.044677045196294785,
      -0.007780143059790134
    ],
    [
      -0.052091725170612335,
      -0.012367567047476768,
      -0.014070053584873676,
      -0.006526487413793802,
      -0.12020236998796463,
      0.00039180630119517446,
      0.002302155364304781,
      0.03843623027205467,
      -0.013880815356969833,
      0.05262809991836548,
      0.019523946568369865,
      0.01687786914408207,
      0.0032419227063655853,
      -0.04517015442252159,
      -0.10918478667736053,
      -0.00311549031175673,
      0.012475789524614811,
      0.03256409987807274,
      0.04489653557538986,
      0.04917311668395996,
      -0.027760975062847137,
      0.014984513632953167,
      -0.050566237419843674,
      -0.009824737906455994,
      -0.024325545877218246,
      -0.04730990156531334,
      -0.021741991862654686,
      0.06747297197580338,
      -0.0010925948154181242,
      -0.0024458065163344145,
      0.013189923949539661,
      0.009903877042233944,
      0.05667904019355774,
      0.03570739924907684,
      0.06478763371706009,
      0.03174610063433647,
      0.01051088236272335,
      -0.11831891536712646,
      -0.0020779180340468884,
      0.0867697224020958,
      -0.03680664673447609,
      -0.009472891688346863,
      0.03592068701982498,
      0.029709171503782272,
      0.013189256191253662,
      0.025961073115468025,
      -0.0035877462942153215,
      -0.06799231469631195,
      0.06369306892156601,
      0.016405608505010605,
      -0.031142521649599075,
      0.07433145493268967,
      0.05111949145793915,
      0.0796784907579422,
      0.049308355897665024,
      -0.05904455855488777,
      -0.003064805408939719,
      0.05211992189288139,
      -0.06285419315099716,
      0.03425188735127449,
      -0.06508420407772064,
      0.04897897690534592,
      -0.01199936494231224,
      0.073951356112957,
      -0.07350536435842514,
      0.08068544417619705,
      -0.07165434211492538,
      -0.06142089515924454,
      0.051281124353408813,
      0.017239267006516457,
      0.01764371432363987,
      -0.0004520849324762821,
      -0.01957257278263569,
      0.023597711697220802,
      -0.005871687084436417,
      0.0249006450176239,
      -0.06631419062614441,
      0.0811765044927597,
      0.024131814017891884,
      0.12791642546653748,
      -0.07461657375097275,
      0.0007191206095740199,
      -0.027591601014137268,
      0.007270191330462694,
      -0.01711447164416313,
      -0.03519529476761818,
      -0.025365440174937248,
      -0.0030877653043717146,
      0.002823019865900278,
      -0.052127689123153687,
      0.112311452627182,
      0.02145303040742874,
      0.033269003033638,
      0.037933871150016785,
      0.012766504660248756,
      -0.0024897430557757616,
      -0.07826916873455048,
      -0.04042956233024597,
      0.05075851082801819,
      -0.03881673514842987,
      0.019392525777220726,
      -0.026659367606043816,
      0.025213507935404778,
      -0.023751312866806984,
      0.08231422305107117,
      -0.010606440715491772,
      -0.02533280849456787,
      0.060626640915870667,
      0.054072074592113495,
      0.06772446632385254,
      0.04203096777200699,
      0.060527436435222626,
      -0.0274919755756855,
      -0.022962868213653564,
      -0.04810662940144539,
      0.01465556863695383,
      0.03536340594291687,
      -0.049166638404130936,
      -0.01822991855442524,
      -0.05125986412167549,
      -0.017721597105264664,
      0.03760455176234245,
      0.0401032492518425,
      -0.006656554993242025,
      0.048873137682676315,
      0.049961257725954056,
      -0.03036075085401535,
      -0.03156164288520813,
      -0.021341213956475258,
      -0.040373582392930984,
      0.011960051022469997,
      0.013564301654696465,
      -0.027313677594065666,
      0.05774618685245514,
      -0.021396910771727562,
      -0.024628523737192154,
      0.039358869194984436,
      0.018912138417363167,
      0.023732516914606094,
      0.036031387746334076,
      0.014201253652572632,
      -0.045004621148109436,
      -0.007696073967963457,
      0.012041198089718819,
      0.0014247777871787548,
      -0.005773351527750492,
      0.028762320056557655,
      -0.053404297679662704,
      -0.046054940670728683,
      0.018172554671764374,
      -0.09129071980714798,
      -0.0012905127368867397,
      -0.017645662650465965,
      -0.038586415350437164,
      0.017575448378920555,
      -0.07183358073234558,
      -0.022821087390184402,
      -0.055314622819423676,
      0.04925896227359772,
      -0.03474251553416252,
      -0.027729038149118423,
      -0.0698009803891182,
      -0.021464724093675613,
      0.018335286527872086,
      -0.1327500343322754,
      -0.028219709172844887,
      -0.05105981603264809,
      -0.022147513926029205,
      0.008414519019424915,
      -0.013373048044741154,
      0.012481878511607647,
      0.05241752415895462,
      -0.026988454163074493,
      -0.07607925683259964,
      0.05493054538965225,
      -0.018733952194452286,
      0.01722406968474388,
      -0.0323009118437767,
      0.0005026287981308997,
      -0.04059382155537605,
      0.031557269394397736,
      -0.0035834608133882284,
      -0.08093574643135071,
      -0.024855751544237137,
      0.010000165551900864,
      -0.07440697401762009,
      -0.039962105453014374,
      0.02938334457576275,
      0.0764239951968193,
      -0.0074068279936909676,
      -0.043308697640895844,
      -0.025261972099542618,
      0.05453925579786301,
      -0.0126051539555192,
      0.02734551951289177,
      -0.007474437355995178,
      0.03726319223642349,
      -0.10517647862434387,
      -0.06298176199197769,
      -0.0380077064037323,
      -0.010525164194405079,
      -0.023222200572490692,
      0.00036408918094821274,
      -0.006703325547277927,
      0.038215842097997665,
      -0.04549100622534752,
      -0.041556466370821,
      0.03235137462615967,
      0.010147220455110073,
      0.02760445885360241,
      0.028431452810764313,
      0.021563146263360977,
      -0.0008319929474964738,
      0.024029193446040154,
      -0.03616487979888916,
      0.008014610968530178,
      0.02852313406765461,
      0.03480013832449913,
      -0.010384343564510345,
      0.06034499406814575,
      -0.04756275191903114,
      -0.0013657035306096077,
      -0.01846611127257347,
      0.02528166025876999,
      -0.03400368243455887,
      0.029845958575606346,
      -0.025210212916135788,
      0.026358798146247864,
      0.049795471131801605,
      0.030341649428009987,
      -0.004557223059237003,
      0.009779009968042374,
      0.06919358670711517,
      -0.0854925662279129,
      -0.07766310125589371,
      -0.04290981590747833,
      -0.04720627889037132,
      0.008152725175023079,
      0.004411123692989349,
      0.050592731684446335,
      0.009219254367053509,
      0.03248509392142296,
      -0.04026837646961212,
      0.09660432487726212,
      0.016692301258444786,
      0.02514556050300598,
      0.08557594567537308,
      -0.05587060749530792,
      -0.04515804350376129,
      0.09654389321804047,
      -0.014874309301376343,
      0.02403612993657589,
      0.07068543136119843,
      0.10478729754686356,
      0.02163509838283062,
      0.009591718204319477,
      -0.0401664637029171,
      0.05094568431377411,
      0.017407255247235298,
      0.02810094505548477,
      0.06684287637472153,
      0.06880566477775574,
      -0.04692641273140907,
      -0.00021344101696740836,
      0.05205993354320526,
      0.0016050185076892376,
      -0.028178934007883072,
      0.04631330445408821,
      -0.01182463113218546,
      0.06150868907570839,
      0.002362469444051385,
      0.008894084021449089,
      -0.02192847616970539,
      0.08382519334554672,
      -0.0595267228782177,
      0.04816508665680885,
      0.0061179641634225845,
      0.0149544021114707,
      -0.054969511926174164,
      -0.036193739622831345,
      -0.0251486636698246,
      -0.02078280784189701,
      0.03632352128624916,
      -0.04741395637392998,
      -0.05125504732131958,
      0.011654085479676723,
      -0.06099413335323334,
      -0.006119299679994583,
      -0.03363387659192085,
      -0.006820142734795809,
      -0.000636243843473494,
      -0.08205392956733704,
      -0.04704643785953522,
      0.021925339475274086,
      -0.08156267553567886,
      0.01002881396561861,
      -0.042881522327661514,
      0.07240305840969086,
      -0.016961615532636642,
      -0.09099916368722916,
      0.05013294517993927,
      0.04716099053621292,
      0.037762075662612915,
      0.044192660599946976,
      0.07453472912311554,
      -0.03405889496207237,
      -0.0214027538895607,
      -0.028748104348778725,
      -0.025807620957493782,
      0.0373791866004467,
      -0.04605267196893692,
      0.051502589136362076,
      0.11027536541223526,
      0.0026388047263026237,
      -0.02459421008825302,
      0.0031919414177536964,
      0.039247915148735046,
      0.03875292092561722,
      -0.014180857688188553,
      0.06779153645038605,
      0.036457497626543045,
      0.03728368878364563,
      -0.025032393634319305,
      -0.0255095474421978,
      0.04389730468392372,
      -0.0008528474718332291,
      0.0769961029291153,
      0.07411808520555496,
      -0.07891096919775009,
      0.011316979303956032,
      -0.026628239080309868,
      -0.05330843850970268,
      -0.04881066828966141,
      0.028138071298599243,
      0.05662642791867256,
      -0.03692794591188431,
      0.02473212219774723,
      0.01975805126130581,
      0.012925291433930397,
      0.05456952750682831,
      -0.06704014539718628,
      -0.031557347625494,
      0.010157806798815727,
      0.028549760580062866,
      0.005064991768449545,
      -0.000365221785614267,
      0.03727711737155914,
      0.0072359745390713215,
      -0.024843554943799973,
      0.012357588857412338,
      -0.02004840597510338,
      0.05154097452759743,
      0.012553716078400612,
      0.03660183772444725,
      0.0773395374417305,
      0.013413104228675365,
      0.0007445099763572216,
      -0.04768137261271477,
      0.0305036511272192,
      -0.06616657227277756,
      0.02449772134423256,
      -0.018548524007201195,
      -0.016629282385110855,
      -0.026782626286149025,
      0.05439876765012741,
      0.05778007581830025,
      -0.03993646800518036,
      -0.007394696120172739,
      -0.062281496822834015,
      -0.06791677325963974,
      0.04308253154158592,
      0.03569817543029785,
      0.006489574443548918,
      0.11636155843734741,
      -0.03586825355887413,
      0.05273057892918587,
      0.0575784370303154,
      0.10461487621068954,
      -0.05734868347644806,
      0.017357615754008293,
      -0.0009124641073867679,
      0.025744736194610596,
      -0.009107288904488087,
      -0.026932455599308014,
      0.03269020840525627,
      -0.028675690293312073,
      -0.0035266766790300608,
      -0.03215198218822479,
      -0.039939943701028824,
      0.018721479922533035,
      0.031839966773986816,
      0.06429556757211685,
      -0.025733044371008873,
      0.04940929636359215,
      0.06194588169455528,
      0.00454439502209425,
      0.027844097465276718,
      -0.03635357692837715,
      0.021974995732307434,
      -0.049016762524843216,
      -0.03692711889743805,
      0.03121146745979786,
      -0.0067315371707081795,
      -0.0062562343664467335,
      0.023714931681752205,
      -0.06525815278291702,
      0.055481962859630585,
      0.0014144576853141189,
      -0.036445148289203644,
      -0.023453108966350555,
      -0.030209355056285858,
      -0.029889874160289764,
      0.05850779265165329,
      0.00896969623863697,
      0.020947664976119995,
      -0.05066051334142685,
      -0.01921420730650425,
      0.023598086088895798,
      -0.0029928351286798716,
      -0.032924432307481766,
      0.06985926628112793,
      -0.024431688711047173,
      -0.07543659210205078,
      -0.029271988198161125,
      8.791746950009838e-05,
      -0.06007545068860054,
      -0.04794492945075035,
      -0.01052062213420868,
      0.039640799164772034,
      -0.03204499930143356,
      -0.05442154034972191,
      0.02128387615084648,
      0.013011390343308449,
      0.03841652721166611,
      -0.005132052581757307,
      -0.061734020709991455,
      0.011591330170631409,
      0.06900479644536972,
      -0.051680032163858414,
      -0.00555728655308485,
      -0.010720102116465569,
      0.02677459642291069,
      0.008700111880898476,
      -0.03346984088420868,
      -0.047583695501089096,
      0.09888562560081482,
      -0.0574188306927681,
      0.005827471613883972,
      0.024143321439623833,
      -0.019337834790349007,
      0.025637682527303696,
      0.037525057792663574,
      0.034610264003276825,
      -0.07386297732591629,
      -0.0010515330359339714,
      0.02973548322916031,
      0.03943372145295143,
      -0.01004801969975233,
      0.05065925419330597,
      -0.025278933346271515,
      -0.0356101356446743,
      -0.04014641419053078,
      0.027737105265259743,
      -0.09927801787853241,
      -0.03019081987440586,
      -0.01846371404826641,
      -0.07638909667730331,
      -0.0070328619331121445,
      -0.005442660767585039,
      -0.0020386402029544115,
      -0.07769595086574554,
      -0.09669174998998642,
      -0.00245884177275002,
      -0.009953384287655354,
      0.013391448184847832,
      0.006344625726342201,
      -0.017185233533382416,
      0.05840630084276199,
      -0.01587115041911602,
      -0.08698870241641998,
      0.029854433611035347,
      -0.055833082646131516,
      -0.023048005998134613,
      0.05360344797372818,
      -0.05026281252503395,
      -0.06109529733657837,
      -0.03809886425733566,
      0.07548893988132477,
      -0.026689887046813965,
      -0.0023520621471107006,
      0.004384595900774002,
      -0.028587577864527702,
      0.0650874674320221,
      -0.0618276372551918,
      0.006584509741514921,
      0.023599576205015182,
      0.02709735743701458,
      -0.051061585545539856,
      0.06741081923246384,
      -0.0674452930688858,
      -0.06557705253362656,
      0.03239504247903824,
      -0.06638174504041672
    ],
    [
      0.001361163565889001,
      -0.034309208393096924,
      -0.010897000320255756,
      -0.02727864868938923,
      -0.07367528975009918,
      0.002202912699431181,
      0.017935266718268394,
      0.05961732938885689,
      -0.00244871131144464,
      7.277232361957431e-05,
      0.02110271155834198,
      -0.05209721252322197,
      0.04341451823711395,
      0.04038311168551445,
      -0.005578862968832254,
      -0.0674041286110878,
      -0.0049955882132053375,
      -0.042413804680109024,
      -0.00584795419126749,
      -0.006147218402475119,
      0.023872457444667816,
      0.020397676154971123,
      0.0076072607189416885,
      -0.004916067700833082,
      0.0212737824767828,
      0.019287150353193283,
      0.032372184097766876,
      -0.023757996037602425,
      0.011367754079401493,
      -0.013371814973652363,
      0.023630166426301003,
      0.1413039118051529,
      -0.003549264743924141,
      0.04125240445137024,
      0.04612436145544052,
      -0.008837322704494,
      0.04097728058695793,
      0.07249744981527328,
      0.020479287952184677,
      -0.030515165999531746,
      -0.08759170025587082,
      -0.00988033041357994,
      -0.03492143005132675,
      0.05486507713794708,
      -0.007508995942771435,
      -0.00639504287391901,
      0.05356315150856972,
      -0.019792066887021065,
      0.05415501073002815,
      0.06669262051582336,
      -0.008986546657979488,
      0.018567727878689766,
      0.04365994781255722,
      0.05784694477915764,
      -0.004029850475490093,
      0.015306315384805202,
      0.041303180158138275,
      0.05597144365310669,
      0.04211944341659546,
      -0.00230768951587379,
      0.018632858991622925,
      0.028560804203152657,
      0.02788751758635044,
      0.013232728466391563,
      -0.01621529646217823,
      -0.003980039618909359,
      -0.019844552502036095,
      0.001609014580026269,
      -0.0971863865852356,
      0.04886564612388611,
      0.08611749857664108,
      -0.05640847235918045,
      -0.08842796087265015,
      -0.0013560368679463863,
      0.037063803523778915,
      0.09731412678956985,
      0.005233930889517069,
      0.028349880129098892,
      -0.010974876582622528,
      0.0019081066129729152,
      -0.009568271227180958,
      -0.00022773344244342297,
      -0.008271556347608566,
      0.012300243601202965,
      0.053103256970644,
      -0.06207676976919174,
      0.017276214435696602,
      0.0022363795433193445,
      -0.011131616309285164,
      -0.044693902134895325,
      0.05548390746116638,
      -0.03090480901300907,
      0.02343582734465599,
      0.018455278128385544,
      0.01566731370985508,
      0.03366927057504654,
      0.025073878467082977,
      -0.04479727894067764,
      -0.09191861748695374,
      -0.03210883215069771,
      -0.009162425063550472,
      -0.10783877223730087,
      -0.02673403173685074,
      0.0436662957072258,
      -0.0721830502152443,
      0.024220511317253113,
      -0.010948870331048965,
      0.06173083558678627,
      -0.022064030170440674,
      -0.04711523652076721,
      0.023987336084246635,
      -0.0010146292624995112,
      -0.07328906655311584,
      0.01059033628553152,
      -0.05231313034892082,
      -0.06189722567796707,
      0.02727236971259117,
      -0.0668625757098198,
      -0.0571797750890255,
      -0.040527310222387314,
      -0.050713568925857544,
      -0.03522203490138054,
      0.03536241874098778,
      -0.10057977586984634,
      -0.008763437159359455,
      -0.08244618028402328,
      -0.02984456904232502,
      -0.05154762789607048,
      -0.012000564485788345,
      0.04185868427157402,
      -0.02419576607644558,
      -0.02780226804316044,
      0.009535539895296097,
      0.049605611711740494,
      0.032151151448488235,
      -0.039153601974248886,
      -0.0009283325052820146,
      -0.01733498089015484,
      0.046449899673461914,
      0.01823137328028679,
      0.042474132031202316,
      0.07521108537912369,
      0.031614866107702255,
      0.08764315396547318,
      0.014565006829798222,
      -0.032602883875370026,
      0.03297985717654228,
      -0.07277379930019379,
      0.05588662251830101,
      -0.07894748449325562,
      -0.017962386831641197,
      0.05492928624153137,
      -0.08032941073179245,
      -0.02333204634487629,
      0.04863262176513672,
      -0.07908420264720917,
      0.010065407492220402,
      -0.04827718064188957,
      -0.05105707421898842,
      0.08146053552627563,
      0.02317534200847149,
      -0.08686460554599762,
      -0.016175128519535065,
      -0.051455024629831314,
      0.002763379132375121,
      0.008906022645533085,
      0.029788419604301453,
      -0.032765839248895645,
      0.090513676404953,
      -0.02188090607523918,
      -0.07143335789442062,
      0.15289665758609772,
      -0.04379059001803398,
      -0.04406556859612465,
      -0.04570630565285683,
      0.023924220353364944,
      -0.11202024668455124,
      0.05491545423865318,
      0.07144777476787567,
      -0.044652048498392105,
      -0.02628675103187561,
      -0.015163222327828407,
      0.04907441511750221,
      0.07177408039569855,
      0.09374276548624039,
      -0.00844730157405138,
      0.03413628041744232,
      -0.06490907818078995,
      0.01567061059176922,
      -0.004100891295820475,
      -0.01500933337956667,
      0.01088155061006546,
      -0.04551073908805847,
      0.006296064704656601,
      0.027399800717830658,
      0.06567715108394623,
      -0.04983525723218918,
      -0.08462214469909668,
      -0.08216273784637451,
      0.02167373336851597,
      -0.02034316398203373,
      0.0747595950961113,
      0.013238689862191677,
      0.003863366786390543,
      0.00842937733978033,
      -0.005197134800255299,
      0.01686740666627884,
      0.045861177146434784,
      -0.0396883599460125,
      0.03935135155916214,
      0.013664920814335346,
      -0.027471140027046204,
      -0.051944006234407425,
      0.053377654403448105,
      0.041042864322662354,
      -0.0013496184255927801,
      0.008312991820275784,
      0.020108377560973167,
      0.007210316136479378,
      0.04764103889465332,
      -0.051688823848962784,
      -0.04515741392970085,
      0.06021633744239807,
      0.03225858882069588,
      -0.06507433950901031,
      -0.056279465556144714,
      -0.012932350859045982,
      -0.01615227200090885,
      0.0662456527352333,
      0.053599655628204346,
      0.015349346213042736,
      -0.050801731646060944,
      -0.08328937739133835,
      0.0500204898416996,
      0.0016885714139789343,
      -0.034888409078121185,
      0.048832621425390244,
      0.02054566517472267,
      0.02270868606865406,
      -0.06685875356197357,
      0.04707123711705208,
      0.03813083842396736,
      -0.0063824960961937904,
      0.012417501769959927,
      0.009933228604495525,
      -0.06517010182142258,
      -0.01863066665828228,
      0.007084678392857313,
      -0.06633233278989792,
      -0.04742226004600525,
      -0.04114336892962456,
      0.011073105968534946,
      0.004368850030004978,
      -0.01458973903208971,
      -0.04953192546963692,
      0.06061212718486786,
      0.02260252647101879,
      0.011170746758580208,
      0.00955617893487215,
      0.022358590736985207,
      0.058053091168403625,
      -0.009280592203140259,
      -0.0379340797662735,
      -0.05492866784334183,
      -0.04624992236495018,
      -0.038567688316106796,
      0.0006924856570549309,
      0.05331060290336609,
      -0.08713668584823608,
      0.05409790202975273,
      0.005703180097043514,
      -0.06850692629814148,
      0.011139570735394955,
      0.014272516593337059,
      0.13444548845291138,
      -0.019006308168172836,
      -0.03580118715763092,
      0.03444818779826164,
      -0.0170939601957798,
      -0.0345618762075901,
      0.038396548479795456,
      -0.11178423464298248,
      -0.019545644521713257,
      0.053538642823696136,
      -0.0053169080056250095,
      -0.05356929078698158,
      0.003162543522194028,
      0.02545243874192238,
      -0.08999956399202347,
      -0.030826309695839882,
      -0.05816972628235817,
      -0.01711578667163849,
      0.01826101914048195,
      0.021307317540049553,
      -0.009066647849977016,
      0.021832918748259544,
      -0.0601675771176815,
      -0.03316304832696915,
      0.018665999174118042,
      0.019120827317237854,
      0.044286273419857025,
      -0.04897473379969597,
      -0.029997266829013824,
      -0.004183420445770025,
      0.012226049788296223,
      0.0010001229820773005,
      0.04494021460413933,
      0.09829660505056381,
      -0.10024368762969971,
      0.002464858116582036,
      -0.0023054201155900955,
      -0.056618012487888336,
      -0.002897034166380763,
      0.028705617412924767,
      -0.012740193866193295,
      -0.020045585930347443,
      0.02676745504140854,
      0.015599788166582584,
      -0.01642196625471115,
      -0.08658847212791443,
      -0.006355703342705965,
      -0.031053710728883743,
      -0.021291861310601234,
      0.0321400947868824,
      -0.026840273290872574,
      -0.02220059558749199,
      0.06641282141208649,
      -0.039304327219724655,
      0.07903346419334412,
      0.030184896662831306,
      0.07764981687068939,
      -0.022494282573461533,
      -0.03437917307019234,
      0.05083553120493889,
      -0.021744588389992714,
      -0.0618167445063591,
      -0.08509530127048492,
      -0.03632562234997749,
      0.07595441490411758,
      -0.028935005888342857,
      0.0033006095327436924,
      0.08580565452575684,
      0.01738518662750721,
      -0.09363686293363571,
      -0.022430691868066788,
      -0.06131486967206001,
      0.02052268758416176,
      -0.016202522441744804,
      -0.003296953160315752,
      -0.067954421043396,
      0.04682474955916405,
      -0.03384215012192726,
      0.006466961465775967,
      -0.00464393338188529,
      -0.048292070627212524,
      -0.007317160721868277,
      -0.0542847104370594,
      0.006116971839219332,
      0.018720591440796852,
      -0.034657347947359085,
      0.04670136794447899,
      0.11982177197933197,
      0.05325772240757942,
      -0.06353117525577545,
      -0.09147439897060394,
      -0.01019167061895132,
      0.0030604039784520864,
      0.010734615847468376,
      -0.047445572912693024,
      0.035115376114845276,
      0.06591150909662247,
      0.03669784963130951,
      0.02959658019244671,
      0.04365061968564987,
      -0.11097779870033264,
      0.05345726013183594,
      -0.004361659754067659,
      -0.011936291120946407,
      0.006420135963708162,
      0.024424239993095398,
      -0.04587521404027939,
      0.05807872861623764,
      -0.016688456758856773,
      0.006826114375144243,
      0.027456292882561684,
      -0.005861734040081501,
      -0.046687591820955276,
      0.055323537439107895,
      0.022846434265375137,
      0.017053546383976936,
      -0.04302864521741867,
      0.050479523837566376,
      0.02016577683389187,
      0.015962261706590652,
      0.04889807477593422,
      0.03564434126019478,
      0.030194969847798347,
      0.06868255883455276,
      -0.0852893516421318,
      -0.040157124400138855,
      -0.010696248151361942,
      -0.05515073612332344,
      -0.092458575963974,
      0.009001055732369423,
      -0.018349550664424896,
      0.00766854640096426,
      -0.024082768708467484,
      0.021063603460788727,
      0.04535428434610367,
      0.03987283632159233,
      -0.021961655467748642,
      0.08981003612279892,
      0.01687869429588318,
      0.04201474413275719,
      0.06790786981582642,
      -0.06238783150911331,
      -0.09202923625707626,
      -0.03676033392548561,
      -0.03345578908920288,
      0.008601757697761059,
      -0.03608163446187973,
      0.024445829913020134,
      0.0014317171880975366,
      -0.015986282378435135,
      0.052056100219488144,
      0.05917186290025711,
      -0.05799209699034691,
      0.13083282113075256,
      -0.0028231055475771427,
      -0.020993812009692192,
      0.02437596768140793,
      0.08143533021211624,
      -0.07842808216810226,
      -0.06740178912878036,
      0.0913051888346672,
      0.08671561628580093,
      0.020793817937374115,
      0.013245072215795517,
      0.01907435990869999,
      -0.07065527141094208,
      0.01011627446860075,
      0.0029084444977343082,
      -0.06847194582223892,
      -0.061130620539188385,
      -0.05859166756272316,
      0.043097805231809616,
      0.037786368280649185,
      0.022960538044571877,
      0.0068407137878239155,
      -0.004530724138021469,
      0.04403260722756386,
      0.008142099715769291,
      -0.052686482667922974,
      0.1456490457057953,
      -0.006862170994281769,
      0.009668475948274136,
      -0.013716188259422779,
      -0.01841268129646778,
      -0.010827681049704552,
      -0.032820507884025574,
      -0.02378610521554947,
      -0.019731109961867332,
      -0.07036612182855606,
      0.024090034887194633,
      0.03655947372317314,
      0.05269322916865349,
      -0.054454658180475235,
      0.058283574879169464,
      -0.022964995354413986,
      -0.039650462567806244,
      0.025923876091837883,
      0.026401503011584282,
      0.05408374220132828,
      0.12149637192487717,
      0.05519919842481613,
      0.0847555547952652,
      0.040412504225969315,
      -0.046805139631032944,
      -0.042678218334913254,
      -0.049172431230545044,
      0.03434465453028679,
      -0.027029531076550484,
      -0.012893331237137318,
      0.03967098891735077,
      0.05318986251950264,
      -0.01880759559571743,
      0.005789772607386112,
      0.011861968785524368,
      0.03256794437766075,
      -0.018101539462804794,
      0.04945394769310951,
      0.039336204528808594,
      -0.03764433041214943,
      0.04321502521634102,
      0.04685147851705551,
      -0.0024735017213970423,
      -0.033680904656648636,
      0.0409783273935318,
      -0.07738791406154633,
      -0.011141102761030197,
      -0.026553886011242867,
      -0.006006020586937666,
      -0.03319534659385681
    ],
    [
      -0.022685548290610313,
      0.02974657528102398,
      0.02965359017252922,
      0.0004099615034647286,
      0.014799021184444427,
      -0.014775170013308525,
      0.0045087034814059734,
      -0.004816911183297634,
      -0.05769910290837288,
      -0.05707411468029022,
      0.059082355350255966,
      -0.002020510844886303,
      0.053117092698812485,
      0.003685292322188616,
      -0.003488859161734581,
      0.016734670847654343,
      0.06570897996425629,
      0.010654077865183353,
      0.028930358588695526,
      0.032174453139305115,
      -0.009496286511421204,
      0.05527540296316147,
      0.0012058666907250881,
      -0.03234975039958954,
      -0.010441826656460762,
      0.03218372166156769,
      -0.02353501319885254,
      -0.0722477063536644,
      0.0611339770257473,
      -0.027826087549328804,
      0.053605545312166214,
      -0.014465218409895897,
      -0.0058028437197208405,
      0.01291604619473219,
      -0.0651714950799942,
      0.10996048152446747,
      -0.008855108171701431,
      -0.04417327046394348,
      0.029926156625151634,
      -0.016906846314668655,
      0.019625749439001083,
      -0.059864360839128494,
      0.06320489197969437,
      0.10793605446815491,
      -0.016204193234443665,
      -0.009523015469312668,
      0.03827784210443497,
      -0.018557442352175713,
      0.09812284260988235,
      -0.05221864953637123,
      0.01044466346502304,
      0.07019651681184769,
      -0.033728308975696564,
      0.01979796029627323,
      0.04925663396716118,
      -0.1140415295958519,
      0.003796499455347657,
      0.00818505510687828,
      0.02409668080508709,
      0.04205276817083359,
      -0.030657069757580757,
      0.022723184898495674,
      0.0358126163482666,
      -0.05038845166563988,
      -0.019488578662276268,
      0.04080218821763992,
      -0.10365594178438187,
      -0.00379456439986825,
      0.04778316989541054,
      0.0647156611084938,
      0.057937510311603546,
      -0.07271095365285873,
      -0.015014762990176678,
      -0.013837968930602074,
      0.02385875955224037,
      -0.006315936334431171,
      -0.0013860504841431975,
      -0.018822524696588516,
      -0.020772162824869156,
      0.027540497481822968,
      0.051183249801397324,
      -0.07531671226024628,
      0.007391477935016155,
      0.028043778613209724,
      -0.07230261713266373,
      -0.0022022428456693888,
      0.03969182074069977,
      0.07264474034309387,
      -0.03562100604176521,
      0.016603998839855194,
      -0.0010825976496562362,
      0.03865734860301018,
      0.045825082808732986,
      0.005011198576539755,
      0.03203758969902992,
      0.038476668298244476,
      0.0073240469209849834,
      0.023783443495631218,
      -0.013603635132312775,
      -0.013771298341453075,
      0.002349251415580511,
      -0.02620810642838478,
      0.005030919797718525,
      0.039980821311473846,
      0.04277398809790611,
      0.08775042742490768,
      -0.022049112245440483,
      0.05520107224583626,
      -0.006175038404762745,
      -0.03175735846161842,
      0.05309269204735756,
      -0.006175749935209751,
      -0.07910226285457611,
      0.06986131519079208,
      -0.08593263477087021,
      -0.0008092271746136248,
      0.019667061045765877,
      0.07678036391735077,
      0.005307265557348728,
      0.036617692559957504,
      -0.0013458728790283203,
      -0.009325654245913029,
      -0.07505564391613007,
      0.04300956800580025,
      0.05528987571597099,
      -0.042150698602199554,
      0.008629772812128067,
      0.02848796546459198,
      -0.018882496282458305,
      0.0033770548179745674,
      0.06517038494348526,
      -0.10906399041414261,
      0.0056137447245419025,
      -0.024339070543646812,
      -0.051354002207517624,
      -0.02736591175198555,
      -0.035524073988199234,
      0.015309709124267101,
      -0.0024217611644417048,
      0.008537127636373043,
      0.024307573214173317,
      0.08547709137201309,
      -0.0019152030581608415,
      -0.046839382499456406,
      0.07235057651996613,
      -0.027633199468255043,
      -0.06930842250585556,
      -0.035836439579725266,
      -0.0642629861831665,
      -0.0031873690895736217,
      -0.03937648609280586,
      0.014362014830112457,
      0.040356751531362534,
      -0.038514234125614166,
      0.06552808731794357,
      0.040294427424669266,
      -0.07121895253658295,
      -0.0030672361608594656,
      0.0641639307141304,
      -0.030620152130723,
      -0.029335957020521164,
      0.11010274291038513,
      0.03459661453962326,
      0.04410044103860855,
      -0.08575795590877533,
      -0.001264179009012878,
      -0.0014556461246684194,
      0.027199814096093178,
      -0.016125520691275597,
      0.01697315275669098,
      -0.024026477709412575,
      0.023997316136956215,
      0.02686252072453499,
      -0.01351863332092762,
      -0.02066117897629738,
      -0.0030402124393731356,
      -0.010513437911868095,
      0.04431662708520889,
      -0.06090840324759483,
      -0.011721672490239143,
      0.03061916120350361,
      0.02306535467505455,
      -0.023669777438044548,
      -0.07598011195659637,
      0.06041118875145912,
      0.07865536957979202,
      -0.016278937458992004,
      -0.031489428132772446,
      0.017825430259108543,
      0.03168458491563797,
      0.08089680969715118,
      0.02477549947798252,
      0.038258567452430725,
      -0.01955418474972248,
      0.010608479380607605,
      -0.02307107299566269,
      -0.005849883425980806,
      0.005198350176215172,
      -0.008341390639543533,
      0.039966851472854614,
      -0.04387067258358002,
      0.06323857605457306,
      0.013291483744978905,
      -0.005721481516957283,
      -0.019632980227470398,
      -0.06680653244256973,
      -0.07018453627824783,
      -0.008752421475946903,
      -0.0010141507955268025,
      0.08375833928585052,
      0.018287526443600655,
      -0.02063043974339962,
      0.002961796009913087,
      -0.01503090187907219,
      0.007265853229910135,
      -0.09648805111646652,
      0.10489403456449509,
      -0.03723713383078575,
      0.04443346709012985,
      0.04909148067235947,
      -0.040777649730443954,
      0.06098804622888565,
      -0.013708194717764854,
      -0.009414075873792171,
      -0.027207497507333755,
      0.020555878058075905,
      0.011874604038894176,
      0.03041456826031208,
      0.011583290994167328,
      -0.031241798773407936,
      -0.06779959052801132,
      0.0631372407078743,
      0.022186942398548126,
      0.11573183536529541,
      -0.016894543543457985,
      -0.017320280894637108,
      0.005450855009257793,
      0.02806968241930008,
      -0.05029953271150589,
      -0.0464930385351181,
      0.02846868894994259,
      0.022840425372123718,
      0.0547572523355484,
      -0.012117053382098675,
      0.03716530278325081,
      -0.017255617305636406,
      0.024443596601486206,
      -0.041476521641016006,
      0.03725532069802284,
      -0.010741757228970528,
      -0.023644842207431793,
      -0.09583718329668045,
      -0.003640358103439212,
      -0.01652824506163597,
      0.008840041235089302,
      0.020068516954779625,
      0.0064719975925982,
      -0.038017790764570236,
      0.07100409269332886,
      0.017906351014971733,
      0.036951448768377304,
      -0.034870438277721405,
      0.005941912066191435,
      0.047011904418468475,
      0.015163714066147804,
      0.020496778190135956,
      0.017923543229699135,
      0.01103313360363245,
      0.022927502170205116,
      -0.024223696440458298,
      0.014718174934387207,
      0.06477370858192444,
      -0.007378379348665476,
      -0.029894117265939713,
      -0.0191035158932209,
      -0.028053289279341698,
      -0.06608068197965622,
      -0.05420931056141853,
      -0.05141332745552063,
      0.02022506482899189,
      0.06618119776248932,
      -0.0400364063680172,
      -0.05912057310342789,
      -0.08772053569555283,
      -0.019336089491844177,
      -0.046063054352998734,
      -0.016359515488147736,
      -0.014907478354871273,
      0.0947175845503807,
      0.06016305088996887,
      0.010173805989325047,
      -0.01430468913167715,
      0.03360151872038841,
      -0.02178393118083477,
      -0.006906946189701557,
      -0.025437669828534126,
      0.031355079263448715,
      -0.0035584194120019674,
      0.03402157500386238,
      0.04220594838261604,
      0.006471084430813789,
      -0.015782848000526428,
      -0.013193394988775253,
      0.014803284779191017,
      0.0541720874607563,
      0.058528680354356766,
      -0.03872646391391754,
      -0.0530037060379982,
      -0.035409945994615555,
      -0.0019939616322517395,
      -0.02180880680680275,
      0.05370010808110237,
      0.07282119989395142,
      -0.030115187168121338,
      0.05908825993537903,
      -0.01152797695249319,
      -0.062373775988817215,
      0.04514105245471001,
      0.060479238629341125,
      -0.08719601482152939,
      -0.01919647678732872,
      0.004519405774772167,
      -0.09008385241031647,
      -0.015430676750838757,
      0.03525509312748909,
      0.027469974011182785,
      0.012145170010626316,
      -0.007575221825391054,
      -0.010190526023507118,
      -0.029190152883529663,
      -0.04258498549461365,
      -0.03756702318787575,
      0.04056158289313316,
      0.080308698117733,
      -0.011028469540178776,
      -0.011770851910114288,
      -0.022492436692118645,
      0.013333807699382305,
      -0.04800490289926529,
      0.035201895982027054,
      -0.012911418452858925,
      0.0006536691798828542,
      -0.006139224860817194,
      0.040724173188209534,
      -0.0047234841622412205,
      0.02554621919989586,
      0.020860111340880394,
      0.02323314920067787,
      0.0171554796397686,
      0.051005490124225616,
      0.03477560728788376,
      -0.10602568835020065,
      0.0340348519384861,
      -0.0445597767829895,
      0.018426580354571342,
      0.014003467746078968,
      -0.08319525420665741,
      -0.030982721596956253,
      0.03104562871158123,
      0.0997903123497963,
      -0.03383167088031769,
      0.033898547291755676,
      5.62835839446052e-06,
      -0.005968334153294563,
      0.0634986162185669,
      0.04147970303893089,
      0.009860264137387276,
      -0.05105975270271301,
      -0.007855434902012348,
      -0.033283255994319916,
      0.07674971967935562,
      -0.058894358575344086,
      0.038907136768102646,
      0.008358574472367764,
      -0.010833952575922012,
      0.056673966348171234,
      -0.05094622075557709,
      0.046042293310165405,
      -0.07893744111061096,
      -0.022122861817479134,
      0.06242481246590614,
      0.013508923351764679,
      -0.06037025526165962,
      -0.02552122063934803,
      0.09930911660194397,
      -0.04437510669231415,
      0.014306736178696156,
      -0.10919883847236633,
      -0.03933423012495041,
      0.05288372561335564,
      -0.010293496772646904,
      -0.027046307921409607,
      0.003073883941397071,
      0.024898571893572807,
      -0.010875813663005829,
      0.024464214220643044,
      -0.034892406314611435,
      0.0038624568842351437,
      0.012726729735732079,
      0.022097572684288025,
      0.020139915868639946,
      0.018135536462068558,
      0.038581736385822296,
      0.04258723184466362,
      -0.05318262428045273,
      -0.003592301392927766,
      0.05285094305872917,
      0.04398765787482262,
      -0.06375440210103989,
      -0.02355372905731201,
      -0.02342662774026394,
      0.05211715027689934,
      -0.04510436952114105,
      -0.013866933062672615,
      0.002185611054301262,
      0.05475987493991852,
      0.0023429980501532555,
      -0.01569758728146553,
      -0.006139558739960194,
      0.024575382471084595,
      0.010501277633011341,
      0.03985210135579109,
      -0.01718241721391678,
      0.06927881389856339,
      -0.033497657626867294,
      0.02632630243897438,
      0.0006413379451259971,
      0.09682921320199966,
      -0.043414413928985596,
      0.02683253027498722,
      -0.04105766862630844,
      0.03765997290611267,
      -0.00407086405903101,
      0.0005340895731933415,
      -0.06586124002933502,
      0.011034154333174229,
      0.053459376096725464,
      0.031276311725378036,
      0.06891139596700668,
      -0.004156038165092468,
      0.04037649929523468,
      -0.09055779874324799,
      -0.030692780390381813,
      0.022142957895994186,
      -0.010110515169799328,
      -0.025658780708909035,
      0.06819254904985428,
      -0.0321771465241909,
      -0.02131938375532627,
      -0.0240017082542181,
      0.06442473083734512,
      0.04446854442358017,
      0.0025796296540647745,
      -0.014723091386258602,
      -0.012247130274772644,
      0.07114975899457932,
      -0.027231188490986824,
      -0.015043685212731361,
      0.09674768894910812,
      -0.08193095028400421,
      -0.021087907254695892,
      0.030838683247566223,
      -0.08615516126155853,
      -0.01681632176041603,
      0.017763953655958176,
      -0.0023855771869421005,
      0.053899429738521576,
      0.05405084043741226,
      0.04488339647650719,
      0.05228424444794655,
      0.032627612352371216,
      0.007310888264328241,
      0.07481726258993149,
      -0.013651194050908089,
      -0.03442161902785301,
      0.031031256541609764,
      -0.03251513093709946,
      0.04147881269454956,
      0.10794350504875183,
      0.03105863928794861,
      0.07826875150203705,
      -0.013902020640671253,
      -0.06466325372457504,
      0.048278991132974625,
      -0.006468046922236681,
      0.031284257769584656,
      0.06767363101243973,
      -0.014462145045399666,
      0.02569553069770336,
      0.0012406628811731935,
      0.009309310466051102,
      -0.0445832721889019,
      -0.07630714029073715,
      -0.026986734941601753,
      -0.009201358072459698,
      -0.04679322615265846,
      0.03600369021296501,
      0.01101239025592804,
      0.05973411723971367,
      -0.017523348331451416,
      0.03925163671374321,
      0.010921550914645195,
      0.00553025770932436
    ],
    [
      0.06682189553976059,
      -0.09194894880056381,
      -0.05579620599746704,
      0.002382590901106596,
      -0.07663136720657349,
      -0.019756272435188293,
      0.03574429079890251,
      0.01743868738412857,
      -0.021464591845870018,
      0.07234303653240204,
      -0.03521064296364784,
      -0.05671269819140434,
      0.01796560175716877,
      -0.07022973150014877,
      -0.048007406294345856,
      -0.021315352991223335,
      0.04818316921591759,
      -0.0110912611708045,
      0.0053549413569271564,
      0.012019910849630833,
      0.007261611521244049,
      -0.06440960615873337,
      -0.05780595913529396,
      -0.03393052890896797,
      0.012724262662231922,
      -0.0256034042686224,
      0.028538573533296585,
      -0.05191038176417351,
      -0.005549922585487366,
      0.019666438922286034,
      0.010288604535162449,
      0.01362970843911171,
      0.08990849554538727,
      -0.06519993394613266,
      0.027370288968086243,
      0.12900544703006744,
      0.014138428494334221,
      -0.04990113154053688,
      0.020749971270561218,
      -0.010142039507627487,
      0.01646244153380394,
      -0.07151978462934494,
      0.028916366398334503,
      0.05437791720032692,
      0.018144452944397926,
      0.0018070134101435542,
      0.07266295701265335,
      -0.09808343648910522,
      0.03875528648495674,
      -0.08501935005187988,
      -0.009238578379154205,
      -0.06209742650389671,
      0.03322021663188934,
      0.01011717040091753,
      0.028214752674102783,
      -0.04951295256614685,
      0.05142589658498764,
      0.061048075556755066,
      0.027464458718895912,
      -0.030855225399136543,
      0.02338011935353279,
      0.012735528871417046,
      0.012231496162712574,
      -0.028309956192970276,
      0.042685847729444504,
      -0.03134043887257576,
      -0.017935777083039284,
      -0.020598279312253,
      -0.014521019533276558,
      0.01142057217657566,
      0.004866152536123991,
      0.04493401572108269,
      -0.005401988048106432,
      -0.0031163517851382494,
      -0.0657670870423317,
      -0.005224309395998716,
      0.007182793226093054,
      0.042919132858514786,
      -0.039404336363077164,
      0.03325134143233299,
      0.02172163873910904,
      0.0001586738071637228,
      -0.011020240373909473,
      0.01457255519926548,
      -0.040148794651031494,
      -0.02671770006418228,
      -0.07022781670093536,
      0.04340541735291481,
      -0.021222596988081932,
      0.035027582198381424,
      0.07719206809997559,
      0.051384784281253815,
      -0.017132384702563286,
      -0.09801025688648224,
      -0.01855030655860901,
      0.07373607903718948,
      -0.02007899433374405,
      -0.0840093344449997,
      0.029578840360045433,
      0.002802881645038724,
      -0.10451994091272354,
      0.021383127197623253,
      -0.0772169753909111,
      -0.02434796467423439,
      -0.04564537853002548,
      -0.046428654342889786,
      0.006295543164014816,
      0.03651202842593193,
      0.00710979662835598,
      -0.0753273144364357,
      0.013035446405410767,
      -0.0016389443771913648,
      0.10559380054473877,
      -0.003247069427743554,
      -0.04858512803912163,
      0.004478977527469397,
      -0.016352247446775436,
      -0.051402680575847626,
      -0.04941823333501816,
      -0.11452236026525497,
      -0.004493069835007191,
      -0.015432354062795639,
      0.030003530904650688,
      0.03612462058663368,
      0.015581868588924408,
      0.01938128098845482,
      -0.053829409182071686,
      0.045634057372808456,
      0.029109003022313118,
      -0.055699609220027924,
      -0.0403006337583065,
      0.0064760129898786545,
      0.005226835608482361,
      -0.04886886104941368,
      -0.028537515550851822,
      -0.02269548736512661,
      -0.0026571135967969894,
      -0.057128727436065674,
      -0.01285590510815382,
      -0.06012800335884094,
      0.007966685108840466,
      -0.003364433767274022,
      0.03410648927092552,
      0.07361766695976257,
      0.04026447609066963,
      -0.046049170196056366,
      0.06768836826086044,
      0.02773612178862095,
      -0.08929349482059479,
      -0.03497197851538658,
      -0.023003380745649338,
      0.02023100107908249,
      0.047621950507164,
      -0.066956527531147,
      -0.007992150261998177,
      -0.00608569011092186,
      -0.029278278350830078,
      -0.011838316917419434,
      -0.005162754561752081,
      -0.014030124992132187,
      0.025261864066123962,
      0.0690205991268158,
      0.018964160233736038,
      0.030544057488441467,
      0.0013986787525936961,
      -0.0227003563195467,
      -0.06841284036636353,
      -0.014497283846139908,
      0.05075528472661972,
      -0.014198387041687965,
      -0.111378975212574,
      -5.5611919378861785e-05,
      -0.0019291508942842484,
      0.05040722340345383,
      -0.07064040750265121,
      -0.03007565066218376,
      0.026128210127353668,
      0.040214430540800095,
      -0.04855382815003395,
      -0.006402186583727598,
      0.03543958440423012,
      0.00625962857156992,
      0.03602108731865883,
      0.041569557040929794,
      -0.08580123633146286,
      0.05253051221370697,
      0.0024939398281276226,
      0.02799520082771778,
      -0.008389601483941078,
      -0.05758712813258171,
      0.00031591355218552053,
      -0.04189232736825943,
      0.12355080246925354,
      0.007958320900797844,
      0.02940773218870163,
      0.06665435433387756,
      0.006051306612789631,
      0.05286170542240143,
      0.010342268273234367,
      -0.029263360425829887,
      -0.0020583642181009054,
      0.03656034544110298,
      0.02358381263911724,
      -0.06121121719479561,
      -0.08374185860157013,
      0.03914984315633774,
      -0.0030879084952175617,
      0.06638345867395401,
      0.06714647263288498,
      0.049825895577669144,
      -0.04919896274805069,
      0.008654282428324223,
      0.021868472918868065,
      0.04071538522839546,
      -0.004295608028769493,
      -0.0574825257062912,
      -0.035498157143592834,
      0.07861517369747162,
      0.052242498844861984,
      0.037635933607816696,
      0.06765466183423996,
      -0.005938793066889048,
      0.04809992015361786,
      0.06359749287366867,
      0.025467943400144577,
      -0.012724373489618301,
      0.050045087933540344,
      -0.014199500903487206,
      0.057605814188718796,
      0.031918715685606,
      -0.07137788087129593,
      -0.002979497192427516,
      9.9676035461016e-05,
      -0.02131585031747818,
      -0.012286419980227947,
      0.0908217579126358,
      0.018074551597237587,
      0.044989120215177536,
      0.04170270636677742,
      0.07016345858573914,
      0.08794592320919037,
      0.00501757999882102,
      0.05341702327132225,
      -0.0070763248950243,
      -0.06817023456096649,
      -0.02475138008594513,
      -0.011461418122053146,
      -0.07415720075368881,
      -0.03965932875871658,
      -0.11154755204916,
      0.058039259165525436,
      0.030199244618415833,
      -0.029635095968842506,
      0.0055786315351724625,
      -0.031056731939315796,
      0.026113348081707954,
      0.032778866589069366,
      -0.02960309199988842,
      0.04993686079978943,
      0.03594396263360977,
      0.014960181899368763,
      0.019797008484601974,
      -0.007604357786476612,
      0.0020098420791327953,
      -0.008100362494587898,
      0.06899139285087585,
      0.00829610601067543,
      0.0007496890029869974,
      0.05849133059382439,
      -0.04421873018145561,
      -0.04581519961357117,
      0.03883231431245804,
      0.0002473195199854672,
      -0.024331847205758095,
      0.008041051216423512,
      0.050385795533657074,
      -0.02706797793507576,
      0.006827388424426317,
      -0.06891050189733505,
      -0.0071613057516515255,
      -0.1031922698020935,
      -0.04000091180205345,
      0.03581368550658226,
      0.01662472076714039,
      0.009441114030778408,
      0.005520528648048639,
      -0.014182516373693943,
      -0.06080332025885582,
      -0.0064532216638326645,
      0.010031484998762608,
      -0.06374097615480423,
      -0.09504745900630951,
      -0.010035034269094467,
      0.02063300646841526,
      0.03431004658341408,
      -0.004861635155975819,
      -0.0651274248957634,
      -0.051590219140052795,
      0.05929035693407059,
      0.02622040919959545,
      -0.03791987523436546,
      -0.020011214539408684,
      -0.0011060001561418176,
      -0.008861876092851162,
      -0.008479173295199871,
      0.030286967754364014,
      -0.004708336666226387,
      0.010185380466282368,
      -0.04101070016622543,
      0.008539899252355099,
      0.007793055847287178,
      0.03751545399427414,
      -0.0026406380347907543,
      -0.11808531731367111,
      -0.06463564187288284,
      -0.03666868060827255,
      0.02927636168897152,
      -0.09522001445293427,
      -0.025455495342612267,
      -0.06049608811736107,
      0.04657218977808952,
      -0.020269034430384636,
      0.014286259189248085,
      0.03456157073378563,
      0.013333767652511597,
      -0.01642432250082493,
      0.04933235049247742,
      -0.0626470148563385,
      -0.03403804078698158,
      0.10343414545059204,
      -0.03834136202931404,
      0.05736127123236656,
      0.00022145768161863089,
      0.006907706148922443,
      0.0686822235584259,
      -0.045110225677490234,
      0.027820123359560966,
      0.021169276908040047,
      -0.030611179769039154,
      -0.03692752122879028,
      0.031602706760168076,
      -0.003256932832300663,
      -0.046841345727443695,
      -0.025777675211429596,
      -0.0012853472726419568,
      -0.030668865889310837,
      0.061442118138074875,
      0.013734214939177036,
      -0.0241852980107069,
      -0.01950806938111782,
      0.128742054104805,
      0.036987680941820145,
      -0.0006110664689913392,
      -0.046671826392412186,
      0.015862485393881798,
      0.04453732818365097,
      0.004648291971534491,
      -0.02275649830698967,
      -0.036244429647922516,
      -0.00239172182045877,
      0.010074248537421227,
      -0.035891156643629074,
      0.014582186006009579,
      -0.062280699610710144,
      -0.010168174281716347,
      -0.05172831565141678,
      0.014103964902460575,
      -0.0019486203091219068,
      0.036440957337617874,
      -0.05286040157079697,
      -0.07271778583526611,
      0.030457308515906334,
      -0.10976921021938324,
      0.0857374370098114,
      0.07423590123653412,
      0.038090065121650696,
      -0.0032016977202147245,
      -0.11685214191675186,
      0.04265865683555603,
      0.01497834175825119,
      0.007002535276114941,
      0.05347280204296112,
      0.07318533211946487,
      -0.0020959211979061365,
      -0.06792944669723511,
      -0.0782565176486969,
      -0.06539563834667206,
      0.059084292501211166,
      -0.0007630233303643763,
      -0.07317406684160233,
      -0.0009870089124888182,
      -0.007767168339341879,
      -0.005215154495090246,
      0.05398842319846153,
      0.07260408252477646,
      0.05161786824464798,
      -0.08348169922828674,
      -0.010340353474020958,
      0.040820952504873276,
      0.08349940180778503,
      -0.05082927271723747,
      0.038644276559352875,
      0.010500350035727024,
      -0.10489195585250854,
      0.0014829025603830814,
      -0.006472857668995857,
      -0.05634124204516411,
      0.021580059081315994,
      0.0015466859331354499,
      -0.01595805399119854,
      0.006974522490054369,
      0.014512667432427406,
      0.005778382997959852,
      0.0050386241637170315,
      0.0344160795211792,
      -0.010230003856122494,
      0.03412299230694771,
      -0.013037956319749355,
      -0.02674907073378563,
      0.033856794238090515,
      0.04225349798798561,
      0.01065702922642231,
      -0.017175422981381416,
      0.06534303724765778,
      0.021521788090467453,
      0.03296347334980965,
      0.0049228910356760025,
      -0.03289831802248955,
      -0.007516997866332531,
      -0.019983571022748947,
      0.011553842574357986,
      0.01688745804131031,
      -0.004531570244580507,
      0.03178749233484268,
      0.04645077884197235,
      -0.043798256665468216,
      0.0029999155085533857,
      0.0788421705365181,
      -0.03657544404268265,
      0.0016657438827678561,
      0.011923002079129219,
      0.003412270685657859,
      -0.028377261012792587,
      -0.08005902171134949,
      0.02268792875111103,
      0.05472349748015404,
      -0.0941365659236908,
      0.011113626882433891,
      0.025942672044038773,
      -0.003311340929940343,
      -0.04753267019987106,
      0.033508606255054474,
      0.026167647913098335,
      0.020032577216625214,
      -0.012960674241185188,
      -0.03020438738167286,
      -0.026023544371128082,
      0.08971595019102097,
      0.002832676051184535,
      0.02994035929441452,
      0.04158441722393036,
      -0.08220861107110977,
      -0.0064059896394610405,
      -0.00575195113196969,
      0.038714900612831116,
      0.002969185123220086,
      -0.02057795412838459,
      -0.043520569801330566,
      -0.05354465916752815,
      0.013370883651077747,
      -0.03459879755973816,
      0.005959643516689539,
      -0.03604384884238243,
      0.038383062928915024,
      0.07450825721025467,
      0.10350285470485687,
      -0.0010434661526232958,
      0.023880062624812126,
      -0.036726098507642746,
      -0.08807077258825302,
      -0.06805403530597687,
      0.02072816714644432,
      0.05660877004265785,
      0.036793481558561325,
      0.026279442012310028,
      0.019521081820130348,
      0.0020352648571133614,
      0.03192868083715439,
      0.05009162053465843,
      -0.039251502603292465,
      0.0362633652985096,
      0.053753722459077835,
      0.04027978330850601,
      0.01181037351489067,
      -0.0742776170372963,
      -0.014766279608011246,
      -0.01063088234513998,
      0.027356786653399467,
      -0.013546030037105083,
      -0.040630023926496506,
      0.042881615459918976,
      -0.015475582331418991,
      -0.038823314011096954
    ],
    [
      0.06226237863302231,
      0.04070696979761124,
      0.01796880178153515,
      0.016868412494659424,
      -0.004077317658811808,
      -0.054695963859558105,
      0.012986470013856888,
      0.00645169522613287,
      0.054522495716810226,
      -0.03129437938332558,
      0.0020724767819046974,
      -0.014070515520870686,
      0.015258540399372578,
      0.04679730534553528,
      -0.00285335723310709,
      -0.010588501580059528,
      0.029010774567723274,
      -0.041180532425642014,
      0.036379557102918625,
      0.0077499994076788425,
      -0.03870413824915886,
      -0.03201949596405029,
      -0.029878249391913414,
      -0.06000012904405594,
      0.022454410791397095,
      0.009408965706825256,
      0.012336569838225842,
      -0.04569747671484947,
      -0.029175348579883575,
      -0.05526313930749893,
      0.03946322202682495,
      -0.03229321166872978,
      0.014535360038280487,
      -0.021076666191220284,
      -0.009593712165951729,
      0.08019443601369858,
      -0.009325948543846607,
      0.014516551047563553,
      -0.03357115387916565,
      -0.014353021048009396,
      -0.11147822439670563,
      -0.02138664573431015,
      -0.015325862914323807,
      -0.07177260518074036,
      0.05374454706907272,
      -0.08119399100542068,
      0.03170652315020561,
      -0.011552294716238976,
      0.026249147951602936,
      0.06215064972639084,
      0.008416347205638885,
      0.03215028718113899,
      0.03359755873680115,
      -0.048170991241931915,
      -0.005882869474589825,
      -0.04869372025132179,
      -0.013633796945214272,
      0.026201795786619186,
      0.031293198466300964,
      -0.03588280826807022,
      0.010083288885653019,
      0.0386321134865284,
      -0.030272703617811203,
      0.02314809523522854,
      -0.03462027385830879,
      0.020043546333909035,
      -0.014628441073000431,
      0.03922196105122566,
      -0.015097472816705704,
      0.032698072493076324,
      0.02136431634426117,
      -0.03870447352528572,
      -0.02063721790909767,
      0.019617170095443726,
      0.012074158526957035,
      -0.004013893194496632,
      -0.010421058163046837,
      -0.021435165777802467,
      0.0340852290391922,
      -0.07030031830072403,
      0.0295132827013731,
      -0.02426649071276188,
      0.03559598699212074,
      0.056562379002571106,
      0.018664132803678513,
      0.020950431004166603,
      -0.03545346483588219,
      0.08480286598205566,
      -0.0032276150304824114,
      0.021683594211935997,
      0.03820427134633064,
      -0.060526251792907715,
      0.022667210549116135,
      -0.0791749581694603,
      -0.02553659863770008,
      -0.048016034066677094,
      0.04581288620829582,
      0.049965739250183105,
      0.041049081832170486,
      -0.02329671010375023,
      0.04488450288772583,
      0.07226696610450745,
      -0.048581235110759735,
      0.04969818890094757,
      -0.026421265676617622,
      0.07044067233800888,
      0.017837973311543465,
      0.03376760333776474,
      0.01970956102013588,
      -0.06890225410461426,
      -0.07659360021352768,
      -0.056377310305833817,
      -0.029840124770998955,
      -0.02498103305697441,
      -0.012350048869848251,
      0.04227503761649132,
      -0.026949163526296616,
      -0.012269637547433376,
      0.049952518194913864,
      -0.00019408762454986572,
      0.02951151318848133,
      -0.04602860286831856,
      -0.035284582525491714,
      0.007062406279146671,
      -0.015854930505156517,
      -0.0034050981048494577,
      -0.023982012644410133,
      -0.03663857281208038,
      0.03591375797986984,
      -0.009198143146932125,
      0.077627032995224,
      0.013186933472752571,
      -0.12431499361991882,
      -0.025472629815340042,
      0.0028919100295752287,
      0.009441062808036804,
      -0.06529878824949265,
      -0.0848025307059288,
      -0.012923460453748703,
      -0.08389720320701599,
      -0.005575650837272406,
      0.008389787748456001,
      -0.014675130136311054,
      -0.014722168445587158,
      0.032956212759017944,
      0.015758033841848373,
      0.04665457084774971,
      0.03897261247038841,
      -0.004552795551717281,
      0.039260320365428925,
      -0.04705611243844032,
      -0.03204395994544029,
      0.04424683749675751,
      0.03347894549369812,
      -0.006131854839622974,
      -0.07422710210084915,
      -0.04802323505282402,
      0.0068982490338385105,
      -0.013471730053424835,
      0.07777908444404602,
      -0.0021317112259566784,
      -0.016923943534493446,
      -0.0233596321195364,
      0.05686039850115776,
      -0.05561855807900429,
      -0.060586556792259216,
      0.011512291617691517,
      -0.04925231635570526,
      -0.021378006786108017,
      0.01606723666191101,
      -0.11218961328268051,
      0.022091925144195557,
      0.006094858516007662,
      0.01233914215117693,
      -0.0072863418608903885,
      -0.03005906194448471,
      -0.053889304399490356,
      0.003786665154621005,
      0.028298581019043922,
      0.056781962513923645,
      -0.006911193486303091,
      -0.014890018850564957,
      0.006325107533484697,
      -0.0053612408228218555,
      0.06307140737771988,
      -0.01841144636273384,
      -0.03864431008696556,
      0.009755482897162437,
      0.02359793148934841,
      0.022292587906122208,
      -0.09250424057245255,
      -0.03311649709939957,
      -0.009683978743851185,
      0.0746944472193718,
      -0.04007096588611603,
      0.13906536996364594,
      0.0197007916867733,
      -0.053780168294906616,
      -0.08400861918926239,
      -0.01584620773792267,
      0.017830003052949905,
      0.03790545091032982,
      -0.02520287036895752,
      0.040618401020765305,
      -0.06314993649721146,
      0.05546793341636658,
      -0.03074186109006405,
      0.07982411980628967,
      0.005781738553196192,
      0.03122175857424736,
      -0.05582231655716896,
      0.009570909664034843,
      0.0012360685504972935,
      -0.04038247838616371,
      0.007777187041938305,
      -0.014859989285469055,
      0.041151344776153564,
      0.042799871414899826,
      -0.022457260638475418,
      -0.03580021485686302,
      0.0009174594888463616,
      -0.019690316170454025,
      0.009580790065228939,
      0.03820837289094925,
      -0.062256477773189545,
      0.017149941995739937,
      -0.09439205378293991,
      -0.005306611768901348,
      -0.04680214449763298,
      -0.06748335808515549,
      0.05860583111643791,
      -0.05277930572628975,
      -0.02594410628080368,
      0.03476595878601074,
      0.03197165206074715,
      0.03395022451877594,
      -0.027373062446713448,
      -0.008421367034316063,
      0.0685705691576004,
      0.006988857872784138,
      0.018706636503338814,
      -0.007278938312083483,
      0.027057519182562828,
      -0.05143630877137184,
      0.003601653501391411,
      0.031102435663342476,
      -0.010035032406449318,
      -0.05467758700251579,
      -0.020710093900561333,
      -0.07015726715326309,
      0.009498831816017628,
      0.012803275138139725,
      0.05971432849764824,
      0.06012928485870361,
      0.026605241000652313,
      -0.04787805303931236,
      0.008623932488262653,
      0.009603247046470642,
      0.039401911199092865,
      -0.036019209772348404,
      -0.022144924849271774,
      -0.028295623138546944,
      -0.05347815155982971,
      -0.01788691058754921,
      -0.10308683663606644,
      0.009343056939542294,
      0.015003965236246586,
      0.020250733941793442,
      0.0425051748752594,
      -0.025773361325263977,
      0.016191769391298294,
      -0.008832748979330063,
      0.01911490596830845,
      -0.049227580428123474,
      0.005581516772508621,
      -0.06128496676683426,
      -0.04541078954935074,
      -0.0013044276274740696,
      0.1089605987071991,
      -0.050306688994169235,
      0.06217145174741745,
      -0.019887972623109818,
      0.10701628029346466,
      -0.02065773867070675,
      -0.019667666405439377,
      -0.015737785026431084,
      -0.018941406160593033,
      0.042265914380550385,
      0.06573761254549026,
      -0.07686078548431396,
      -0.05654803663492203,
      0.017428435385227203,
      0.02990071475505829,
      -0.09248822927474976,
      0.01712527684867382,
      0.014427287504076958,
      0.005849719513207674,
      -0.004570451565086842,
      -0.06961847841739655,
      -0.057408664375543594,
      -0.040540698915719986,
      0.028737477958202362,
      0.08027227222919464,
      -0.001849820138886571,
      0.06079360097646713,
      0.0194541122764349,
      0.02526981383562088,
      -0.01378779485821724,
      0.008799687959253788,
      -0.015576151199638844,
      0.01070262212306261,
      -0.04289761185646057,
      0.01602889969944954,
      -0.05370471626520157,
      0.044422656297683716,
      -0.010097254067659378,
      0.03723843768239021,
      0.04617084190249443,
      -0.05459148436784744,
      0.033211518079042435,
      -0.06257899105548859,
      0.008752101100981236,
      0.061317432671785355,
      0.005167750176042318,
      -0.007381460163742304,
      0.016122575849294662,
      0.015896176919341087,
      0.009041542187333107,
      0.0014807881088927388,
      0.007295913062989712,
      0.009717082604765892,
      0.020014699548482895,
      -0.014915985986590385,
      0.01776106096804142,
      0.002725753467530012,
      0.018343623727560043,
      0.034371327608823776,
      0.007098263129591942,
      -0.03056352399289608,
      -0.07344576716423035,
      0.0776100903749466,
      0.013677233830094337,
      0.03384922072291374,
      -0.10326957702636719,
      -0.03236259147524834,
      0.04799776151776314,
      0.005825774744153023,
      0.02107374556362629,
      -0.017620760947465897,
      -0.012713965959846973,
      0.0860988199710846,
      -0.02369060181081295,
      -0.03664889186620712,
      0.00415927916765213,
      0.04655403271317482,
      -0.009414410218596458,
      -0.028976526111364365,
      0.09270710498094559,
      -0.08889639377593994,
      0.04152524843811989,
      -0.0098196342587471,
      -0.02146177366375923,
      -0.05321698635816574,
      -0.06983398646116257,
      -0.06057015061378479,
      -0.12938402593135834,
      0.060737889260053635,
      0.023161927238106728,
      0.01022743247449398,
      -0.008165303617715836,
      -0.004892047960311174,
      -0.025353774428367615,
      0.09116053581237793,
      0.03800344467163086,
      0.07644160091876984,
      -0.01277991198003292,
      -0.08095505833625793,
      -0.04410158842802048,
      0.05259459465742111,
      0.001739112427458167,
      0.05239298939704895,
      -0.010357426479458809,
      -0.016977401450276375,
      -0.004040137864649296,
      -0.006471708882600069,
      0.013840747997164726,
      0.00039879875839687884,
      0.02145947702229023,
      -0.00010584908886812627,
      -0.0479559451341629,
      0.03828621283173561,
      -0.03294605016708374,
      -0.011038883589208126,
      0.0147332064807415,
      0.005228989291936159,
      -0.03309616073966026,
      -0.04240502789616585,
      -0.025176413357257843,
      -0.06266961991786957,
      -0.022015176713466644,
      0.0691099464893341,
      0.004714334383606911,
      -0.053893595933914185,
      0.0822252556681633,
      -0.035342734307050705,
      0.02330799587070942,
      0.0054626138880848885,
      0.05571180209517479,
      0.04093014448881149,
      0.044785503298044205,
      -0.0405835323035717,
      0.027864445000886917,
      0.020906725898385048,
      0.022765686735510826,
      -0.02256915718317032,
      0.009207422845065594,
      -0.06129276007413864,
      -0.03725837171077728,
      -0.045986659824848175,
      -0.045432113111019135,
      -0.07774616777896881,
      0.02217583730816841,
      0.08145309239625931,
      -0.0214633047580719,
      0.05350596085190773,
      0.032607920467853546,
      -0.044128287583589554,
      -0.003075395943596959,
      -0.005383833311498165,
      -0.056746069341897964,
      -0.014112642966210842,
      0.0016901142662391067,
      -0.0010688521433621645,
      0.00618973933160305,
      0.024711349979043007,
      0.006873536389321089,
      -0.036820799112319946,
      -0.015729503706097603,
      0.04249415174126625,
      -0.014159908518195152,
      0.019840102642774582,
      -0.08725877851247787,
      0.04197058826684952,
      0.056901585310697556,
      0.030673518776893616,
      0.05822300165891647,
      -0.11614557355642319,
      0.007566062733530998,
      -0.02065304107964039,
      -0.022990040481090546,
      -0.06407197564840317,
      0.016995763406157494,
      -0.009293362498283386,
      0.025393765419721603,
      0.008718122728168964,
      0.008984332904219627,
      -0.058026496320962906,
      0.03787219896912575,
      -0.011892490088939667,
      -0.019092770293354988,
      0.05529557541012764,
      0.08619561791419983,
      -0.002311588730663061,
      -0.01267112884670496,
      0.07215617597103119,
      0.00649465573951602,
      -0.01606837660074234,
      0.007779187988489866,
      -0.006630558520555496,
      0.00850000698119402,
      -0.000544451700989157,
      -0.08159328252077103,
      0.009372493252158165,
      -0.051347993314266205,
      -0.002424575388431549,
      -0.05019271373748779,
      -0.07452234625816345,
      -0.07533495128154755,
      0.08953975886106491,
      0.05469679832458496,
      0.015372922644019127,
      -0.05398431792855263,
      -0.05231795087456703,
      0.017479566857218742,
      -0.07766731083393097,
      -0.02203584648668766,
      -0.06788960844278336,
      0.023134827613830566,
      0.05241139605641365,
      0.01669458858668804,
      -0.04569457098841667,
      0.040515512228012085,
      0.00606513861566782,
      -0.029976075515151024,
      0.027807842940092087,
      0.023786479607224464,
      0.013886463828384876,
      0.03573934733867645,
      -0.023975277319550514,
      -0.04285798594355583,
      -0.003998802043497562,
      -0.049866851419210434,
      0.042294543236494064
    ],
    [
      0.01345203910022974,
      0.029637770727276802,
      -0.016050264239311218,
      -0.08332709223031998,
      0.041754599660634995,
      0.014053525403141975,
      0.10624431073665619,
      -0.04913114011287689,
      0.06313757598400116,
      0.07970497012138367,
      0.03467386215925217,
      0.006118944380432367,
      -0.0463523305952549,
      0.07654666155576706,
      0.014866315759718418,
      0.02205805294215679,
      0.00022202389664016664,
      0.00768811022862792,
      0.010200755670666695,
      -0.08228006213903427,
      0.028435111045837402,
      -0.11257899552583694,
      0.0904737189412117,
      0.037675902247428894,
      -0.027145154774188995,
      0.015827806666493416,
      0.05718088150024414,
      0.04665258526802063,
      -0.07842706143856049,
      0.05652827396988869,
      0.020074892789125443,
      0.03340334817767143,
      0.027955153957009315,
      -0.057827290147542953,
      0.047051820904016495,
      -0.026367615908384323,
      -0.00191396102309227,
      0.044548869132995605,
      0.04608599469065666,
      -0.02529250457882881,
      -0.011540953069925308,
      -0.08720158785581589,
      -0.0034273872151970863,
      0.09581008553504944,
      -0.029145313426852226,
      -0.027392035350203514,
      0.06974755227565765,
      -0.0015570784453302622,
      0.059543829411268234,
      0.031990401446819305,
      -0.037506699562072754,
      0.029130620881915092,
      -0.007974203675985336,
      0.03500983119010925,
      -0.010686115361750126,
      -0.0059621818363666534,
      0.006155533250421286,
      0.07702334970235825,
      0.09381727129220963,
      -0.0026922698598355055,
      -0.050058476626873016,
      0.00351140508428216,
      0.11819877475500107,
      0.0015353186754509807,
      -0.0029827719554305077,
      -0.013865400105714798,
      -0.0022274635266512632,
      0.006677428260445595,
      0.010007977485656738,
      -0.012459548190236092,
      0.005566415376961231,
      -0.02160690724849701,
      -0.02101774699985981,
      0.05322105810046196,
      0.019475681707262993,
      -0.020214514806866646,
      0.058979034423828125,
      -0.014318348839879036,
      0.02352713979780674,
      0.007868937216699123,
      -0.01110744010657072,
      -0.09468615800142288,
      0.0035464249085634947,
      -0.00030161283211782575,
      -0.023710718378424644,
      -0.016679273918271065,
      0.0417712964117527,
      -0.08799802511930466,
      0.01976255141198635,
      -0.07977938652038574,
      0.005220889579504728,
      -0.07826871424913406,
      0.014183305203914642,
      -0.0689784437417984,
      0.021010058000683784,
      0.02224358357489109,
      -0.032713595777750015,
      0.027663709595799446,
      0.01297760009765625,
      -0.032310813665390015,
      -0.032208770513534546,
      0.0193775724619627,
      -0.04419384524226189,
      0.030387986451387405,
      -0.020574072375893593,
      0.05969930812716484,
      0.011583184823393822,
      -0.009750237688422203,
      -0.06048157438635826,
      -0.0472307987511158,
      0.029622351750731468,
      -0.0375681109726429,
      -0.025347137823700905,
      0.0637899786233902,
      -0.015119022689759731,
      -0.05542704463005066,
      -0.04717462137341499,
      0.010115797631442547,
      -0.030629735440015793,
      0.022744081914424896,
      0.09049917757511139,
      0.05813642963767052,
      -0.03022693283855915,
      0.0730060338973999,
      -0.002622094936668873,
      -0.056046683341264725,
      -0.05563244968652725,
      -0.043796606361866,
      -0.01097510289400816,
      -0.013796326704323292,
      0.02487814985215664,
      0.11128692328929901,
      -0.03686827793717384,
      0.02269691973924637,
      -0.07203711569309235,
      0.06402909010648727,
      0.00829216931015253,
      0.03397425264120102,
      -0.011107532307505608,
      -0.016373492777347565,
      0.038848407566547394,
      0.004250777419656515,
      -0.05474333465099335,
      0.040650539100170135,
      0.009843719191849232,
      -0.023290738463401794,
      -0.09796243160963058,
      -0.047803670167922974,
      -0.10833420604467392,
      0.028193769976496696,
      0.010574139654636383,
      0.028848916292190552,
      -0.01010624598711729,
      0.0032046420965343714,
      0.013658960349857807,
      0.047401223331689835,
      9.33897026698105e-05,
      -0.012927601113915443,
      -0.04014115035533905,
      -0.0256539024412632,
      0.06976259499788284,
      -0.023324696347117424,
      0.048220764845609665,
      0.014763549901545048,
      0.008163361810147762,
      -0.03461334481835365,
      -0.08615360409021378,
      -0.028649451211094856,
      -0.03894132375717163,
      0.08793798834085464,
      -0.03718695789575577,
      0.005315788090229034,
      0.08005136996507645,
      -0.007279120851308107,
      -0.040955547243356705,
      -0.05918419733643532,
      -0.048694197088479996,
      0.10746649652719498,
      -0.11168332397937775,
      -0.0629996731877327,
      0.019213823601603508,
      0.043809711933135986,
      -0.014478661119937897,
      0.06356826424598694,
      -0.004967108368873596,
      -0.00788920372724533,
      0.0346415676176548,
      -0.0004045695241075009,
      -0.0568176694214344,
      -0.020130375400185585,
      0.047895751893520355,
      0.05892813578248024,
      -0.0316055603325367,
      0.053971726447343826,
      0.033730439841747284,
      -0.005305384751409292,
      -0.03680160269141197,
      -0.015419366769492626,
      0.04977530613541603,
      -0.024679962545633316,
      -0.011418529786169529,
      -0.0010271905921399593,
      -0.01712961681187153,
      -0.0291608776897192,
      0.0912964940071106,
      -0.002009536372497678,
      0.03206097334623337,
      0.06394019722938538,
      0.052096910774707794,
      -0.05245642364025116,
      0.03183179348707199,
      0.03979957103729248,
      0.05822278931736946,
      -0.010532918386161327,
      -0.00645046029239893,
      -0.02729717269539833,
      0.0377490408718586,
      0.0011488372692838311,
      0.009440489113330841,
      0.00548508670181036,
      -0.007176689337939024,
      -0.002953727263957262,
      0.016988806426525116,
      -0.052343301475048065,
      0.05111444741487503,
      -0.046046819537878036,
      0.03947555646300316,
      -0.041434671729803085,
      0.014736735261976719,
      0.015360921621322632,
      -0.006393379531800747,
      0.01707538031041622,
      -0.05215585231781006,
      -0.024512216448783875,
      0.035402409732341766,
      0.020580923184752464,
      -0.01944395899772644,
      0.004509145859628916,
      -0.05478138104081154,
      -0.021290695294737816,
      -0.04555433988571167,
      -0.05729672685265541,
      0.040674325078725815,
      0.04291415214538574,
      0.01612035743892193,
      -0.06014915555715561,
      -0.031682297587394714,
      0.02114940620958805,
      0.06637106090784073,
      0.03366229310631752,
      0.022698430344462395,
      0.02421078458428383,
      0.000136193135404028,
      -0.0024409503675997257,
      -0.018774209544062614,
      -0.03641248866915703,
      0.06585713475942612,
      -0.060172487050294876,
      -0.017128484323620796,
      -0.004238927736878395,
      -0.0876038521528244,
      -0.053556427359580994,
      -0.004538935609161854,
      0.005371274892240763,
      0.035917241126298904,
      -0.024441475048661232,
      -0.029558205977082253,
      0.003177858190611005,
      0.06963098049163818,
      -0.014672748744487762,
      0.01722382754087448,
      0.0034207520075142384,
      0.04296361282467842,
      -0.026340842247009277,
      0.03686368092894554,
      0.01208828017115593,
      0.060225170105695724,
      -0.01780831813812256,
      0.037033870816230774,
      0.0966084823012352,
      -0.01820542849600315,
      -0.018397314473986626,
      0.038828298449516296,
      0.028145987540483475,
      -0.0011010150192305446,
      -0.025237195193767548,
      -0.007294659037142992,
      0.013388164341449738,
      -0.031983260065317154,
      0.010638458654284477,
      0.002611042931675911,
      -0.1402359902858734,
      0.07082279771566391,
      0.030879821628332138,
      0.019644349813461304,
      0.002659779042005539,
      -0.045968085527420044,
      0.0203690342605114,
      -0.11170197278261185,
      -0.019865384325385094,
      -0.10467702895402908,
      0.06919980049133301,
      -0.019470123574137688,
      -0.04175460338592529,
      0.020733408629894257,
      0.0627821758389473,
      0.06952020525932312,
      0.020588651299476624,
      0.021156081929802895,
      0.058795396238565445,
      -0.08233512938022614,
      0.07214555889368057,
      -0.05301674082875252,
      0.0510244220495224,
      0.014488236978650093,
      0.0011102502467110753,
      0.04402613267302513,
      -0.03808301314711571,
      0.0481947623193264,
      0.0024987750221043825,
      0.001168721471913159,
      0.028746480122208595,
      0.10021370649337769,
      -0.04733435809612274,
      0.031061191111803055,
      0.030757563188672066,
      -0.018062962219119072,
      -0.011728238314390182,
      0.04356451332569122,
      0.008459892123937607,
      0.019179074093699455,
      0.032767217606306076,
      0.03679254278540611,
      0.055037617683410645,
      0.03885696083307266,
      -0.06175381690263748,
      -0.028896449133753777,
      0.0008000805391930044,
      -0.000865819922182709,
      0.07282084226608276,
      -0.11516958475112915,
      0.04641547426581383,
      -0.006137183401733637,
      -0.030278848484158516,
      0.07373388111591339,
      0.07148323208093643,
      -0.000539834494702518,
      0.09021276235580444,
      -0.04551586136221886,
      -0.04866841807961464,
      0.019864574074745178,
      0.10734159499406815,
      -0.05424163490533829,
      -0.06962515413761139,
      -0.041836995631456375,
      0.0897412896156311,
      -0.011771313846111298,
      -0.03793984279036522,
      -0.04356876388192177,
      0.018449991941452026,
      -0.1355624496936798,
      0.055398739874362946,
      0.03903041407465935,
      -0.007545821834355593,
      -0.026638252660632133,
      -0.02596326544880867,
      0.024245889857411385,
      -0.07846342027187347,
      0.05723022297024727,
      0.030072061344981194,
      -0.11139872670173645,
      0.000185096898349002,
      -0.10607027262449265,
      0.04323693737387657,
      -0.009867354296147823,
      -0.0017157555557787418,
      -0.037287063896656036,
      0.02426649071276188,
      -0.007941903546452522,
      0.02490156702697277,
      0.009498205967247486,
      0.03167538717389107,
      0.0340915322303772,
      -0.00838383287191391,
      0.08324428647756577,
      -0.08059755712747574,
      -0.006716527510434389,
      -0.024624470621347427,
      -0.005915919318795204,
      0.07822735607624054,
      -0.00983318593353033,
      -0.0002636568679008633,
      0.003821228165179491,
      0.05343562737107277,
      -0.03667481616139412,
      0.03305138275027275,
      0.04443887993693352,
      -0.02062837779521942,
      -0.03206127882003784,
      -0.044977061450481415,
      0.08935093134641647,
      0.03375786170363426,
      0.04255963861942291,
      -0.0005315348389558494,
      -0.08701232820749283,
      -0.03170644864439964,
      0.025845415890216827,
      -0.00944070890545845,
      0.037208687514066696,
      -0.056100983172655106,
      -0.10947928577661514,
      -0.03192795813083649,
      -0.005395533517003059,
      0.00180283363442868,
      -0.006989230867475271,
      -0.05579141527414322,
      0.03851564601063728,
      0.05398668348789215,
      -0.09962023794651031,
      0.07380513101816177,
      0.00041082012467086315,
      0.09526825696229935,
      0.038160525262355804,
      0.00737632904201746,
      0.06593840569257736,
      -0.035025883466005325,
      0.001722820452414453,
      0.003061936004087329,
      0.011360341683030128,
      0.004352224059402943,
      0.047348324209451675,
      -0.018454479053616524,
      0.04911785200238228,
      -0.021202271804213524,
      -0.024955522269010544,
      0.051693323999643326,
      -0.07130134850740433,
      0.003452588338404894,
      0.028751613572239876,
      0.076016865670681,
      -0.05539260804653168,
      -0.0236680768430233,
      -0.05501068755984306,
      0.04360346496105194,
      0.05165331810712814,
      -0.021038467064499855,
      0.038616843521595,
      0.019924115389585495,
      0.03968906030058861,
      -0.013817316852509975,
      -0.0532781220972538,
      0.016462283208966255,
      0.015402107499539852,
      -0.014867540448904037,
      -0.018363360315561295,
      0.033698685467243195,
      0.01833433471620083,
      0.00807911530137062,
      0.026294155046343803,
      -0.029330862686038017,
      -0.036648333072662354,
      0.06333387643098831,
      0.05737259238958359,
      0.008698878809809685,
      0.06436605006456375,
      -0.04577317833900452,
      -0.025962920859456062,
      0.009003963321447372,
      0.01045232079923153,
      -0.012213537469506264,
      -0.03487657383084297,
      0.015201347880065441,
      0.0138161052018404,
      0.049105893820524216,
      0.038352470844984055,
      0.055479101836681366,
      -0.04276434704661369,
      0.024163473397493362,
      0.009169160388410091,
      -0.049896810203790665,
      0.08748378604650497,
      0.10923752933740616,
      0.030360272154211998,
      0.047951098531484604,
      0.03870442882180214,
      0.02009674720466137,
      -0.00954289548099041,
      -0.00762163707986474,
      -0.05375742167234421,
      -0.01564319245517254,
      0.03702623397111893,
      -0.003660214366391301,
      -0.062164802104234695,
      -0.0240778811275959,
      0.039329301565885544,
      0.10140461474657059,
      -0.014724536798894405,
      -0.04063746705651283,
      0.08642575144767761,
      -0.01257268339395523,
      -0.021104859188199043,
      0.10336890071630478,
      -0.061823029071092606
    ],
    [
      0.018082408234477043,
      0.0015768226003274322,
      0.028785375878214836,
      -0.02485017105937004,
      0.05362974479794502,
      -0.014588519930839539,
      -0.012313693761825562,
      -0.009417749010026455,
      0.005470465403050184,
      0.05652042478322983,
      0.0540473498404026,
      0.056963082402944565,
      0.0171646885573864,
      -0.057950459420681,
      0.021383129060268402,
      0.01441874261945486,
      0.004986861255019903,
      0.062481485307216644,
      0.03950081393122673,
      -0.05840848758816719,
      0.02171741984784603,
      0.021024292334914207,
      -0.01928398571908474,
      0.06828559935092926,
      0.03624182194471359,
      0.048452697694301605,
      -0.01647101528942585,
      0.0020962997805327177,
      -0.010629931464791298,
      0.025893397629261017,
      0.014761626720428467,
      -0.03864526003599167,
      -0.06828509271144867,
      0.013193180784583092,
      -0.03990110009908676,
      0.014604398049414158,
      0.08761422336101532,
      -0.011174374260008335,
      -0.043510857969522476,
      0.05624370649456978,
      -0.02006431855261326,
      0.12395893037319183,
      -0.022861789911985397,
      0.042242392897605896,
      -0.06698544323444366,
      0.013473249971866608,
      0.060612406581640244,
      0.005197689402848482,
      -0.14395774900913239,
      -0.084752656519413,
      0.0528637059032917,
      -0.003774769837036729,
      -0.05724787712097168,
      -0.004541106056421995,
      0.019372647628188133,
      0.022739112377166748,
      -0.03682109713554382,
      -0.013070652261376381,
      -0.022461095824837685,
      0.004041298758238554,
      -0.033564791083335876,
      0.064239002764225,
      -0.017020147293806076,
      0.030001895502209663,
      0.0007477749604731798,
      -0.056619834154844284,
      0.07703933864831924,
      0.0010728853521868587,
      -0.01145107951015234,
      0.03388410061597824,
      0.056290656328201294,
      0.012948790565133095,
      0.00862784031778574,
      0.10014224797487259,
      0.04282142594456673,
      -0.04759575426578522,
      0.09365878254175186,
      0.0019620282109826803,
      -0.05025046691298485,
      -0.03134068101644516,
      0.037008535116910934,
      -0.02090216986835003,
      0.010883866809308529,
      -0.02357221581041813,
      -0.033460572361946106,
      0.056689102202653885,
      0.14513666927814484,
      -0.03958086669445038,
      -0.036816731095314026,
      0.051053207367658615,
      -0.060203392058610916,
      0.06616055965423584,
      -0.036376308649778366,
      -0.0019470894476398826,
      0.0431138277053833,
      0.01246112585067749,
      -0.03525498881936073,
      -0.03797187656164169,
      -0.016593066975474358,
      0.006938581820577383,
      0.00869057234376669,
      0.01885879598557949,
      -0.04792746528983116,
      0.049481380730867386,
      0.05582868680357933,
      -0.01850239932537079,
      0.025281652808189392,
      0.12959642708301544,
      0.05290937423706055,
      -0.02988596260547638,
      0.03701779991388321,
      -0.042532652616500854,
      -0.06030645966529846,
      -0.08121929317712784,
      0.101593978703022,
      -0.058468177914619446,
      -0.014816438779234886,
      -0.057906538248062134,
      0.08836480975151062,
      0.050136830657720566,
      0.018129272386431694,
      -0.11729840934276581,
      0.08080726116895676,
      0.0170767642557621,
      0.06632356345653534,
      -0.024582264944911003,
      -0.05067545548081398,
      0.060870781540870667,
      0.08114982396364212,
      -0.024182604625821114,
      -0.028433380648493767,
      0.09317829459905624,
      0.05385732650756836,
      0.024909473955631256,
      -0.0012543456396088004,
      -0.03537515923380852,
      0.03444235026836395,
      0.0012434576638042927,
      -0.062452975660562515,
      -0.028739754110574722,
      -0.03315192461013794,
      0.04656866565346718,
      -0.024645280092954636,
      0.0015186837408691645,
      0.05937531590461731,
      0.08167866617441177,
      -0.016721440479159355,
      -0.04148147255182266,
      0.004313827026635408,
      -0.06343062967061996,
      0.009959989227354527,
      0.14137423038482666,
      -0.049219708889722824,
      -0.03217022120952606,
      -0.014572345651686192,
      0.0344514474272728,
      0.00550702353939414,
      -0.03226493299007416,
      -0.020912183448672295,
      -0.04590533301234245,
      -0.03733375668525696,
      0.06033199653029442,
      0.08208531886339188,
      0.002216172404587269,
      -0.005020719952881336,
      0.045123402029275894,
      -0.03470811992883682,
      -0.012632978148758411,
      -0.07800841331481934,
      -0.11656450480222702,
      0.05743397772312164,
      0.0339689776301384,
      -0.03988850116729736,
      0.027391694486141205,
      0.055488914251327515,
      -0.014772064052522182,
      -0.007764066103845835,
      -0.007087470032274723,
      0.0400584451854229,
      -0.06483160704374313,
      0.0026126536540687084,
      -0.04234353080391884,
      -0.017429882660508156,
      0.06669565290212631,
      0.04139920696616173,
      -0.01719592697918415,
      0.0017647409113124013,
      0.03908669576048851,
      -0.035981036722660065,
      0.007127925753593445,
      -0.039636969566345215,
      0.05964910238981247,
      -0.0696784034371376,
      -0.035946864634752274,
      0.048823826014995575,
      0.007614389061927795,
      0.03425123915076256,
      0.01643138937652111,
      0.04032623767852783,
      -0.0505160428583622,
      -0.03085194155573845,
      0.07436318695545197,
      0.05874231830239296,
      0.0037408703938126564,
      0.01096170675009489,
      0.05386032536625862,
      0.06167403981089592,
      0.012724126689136028,
      0.0598287358880043,
      0.03260404244065285,
      0.030051345005631447,
      -0.14709007740020752,
      -0.016363315284252167,
      0.09186933189630508,
      -0.021530425176024437,
      -0.12241648137569427,
      -0.0021998099982738495,
      -0.0012207736726850271,
      0.04387170821428299,
      0.010636139661073685,
      -0.0784318819642067,
      -0.13975995779037476,
      0.06657645106315613,
      -0.013986815698444843,
      -0.027098871767520905,
      0.013460964895784855,
      0.007817159406840801,
      -0.049499042332172394,
      -0.05808176100254059,
      0.005263619590550661,
      -0.03820637986063957,
      -0.012113863602280617,
      0.01571415737271309,
      0.0672084391117096,
      0.056788407266139984,
      -0.08371419459581375,
      -0.012539390474557877,
      0.03663240000605583,
      -0.057384271174669266,
      0.019840115681290627,
      -0.02147732302546501,
      -0.06906110048294067,
      0.0613224022090435,
      0.013359258882701397,
      -0.046286698430776596,
      -0.060980383306741714,
      -0.04064725339412689,
      -0.0597868487238884,
      -0.03761513903737068,
      0.00041207161848433316,
      0.016702841967344284,
      -0.03034091740846634,
      0.016351552680134773,
      -0.024336382746696472,
      -0.06055048108100891,
      0.024679796770215034,
      0.009822075255215168,
      0.045441821217536926,
      0.039493925869464874,
      0.009243784472346306,
      -0.021922126412391663,
      -0.024558980017900467,
      0.004995312076061964,
      0.08090853691101074,
      0.009316795505583286,
      0.052891891449689865,
      0.03885837644338608,
      -0.015304836444556713,
      -0.04148528724908829,
      0.09237167239189148,
      0.009050512686371803,
      0.04735904932022095,
      -0.051885008811950684,
      -0.03423215448856354,
      -0.017396828159689903,
      0.06508675217628479,
      0.025005092844367027,
      -0.010837442241609097,
      -0.004972397815436125,
      -0.033911872655153275,
      0.028223060071468353,
      -0.008613130077719688,
      0.05845075845718384,
      -0.03873133286833763,
      -0.026649074628949165,
      -0.05983114242553711,
      -0.029218915849924088,
      -0.09013406187295914,
      -0.04287561774253845,
      -0.0017542548011988401,
      -0.016114532947540283,
      -0.03682466968894005,
      -0.025356542319059372,
      -0.0044396063312888145,
      0.030689701437950134,
      -0.028452448546886444,
      -0.06029435247182846,
      0.03613562136888504,
      -0.04113247990608215,
      -0.014883678406476974,
      0.03195880725979805,
      -0.08831576257944107,
      -0.09868106991052628,
      0.020331095904111862,
      0.07939394563436508,
      0.015754519030451775,
      -0.0267991553992033,
      -0.004030674230307341,
      -0.14130306243896484,
      -0.016927270218729973,
      -0.01322407741099596,
      -0.004799642134457827,
      0.010702447034418583,
      -0.036334894597530365,
      -0.06963787972927094,
      -0.0026746655348688364,
      0.03873501345515251,
      0.035348985344171524,
      -0.030319694429636,
      0.05726184695959091,
      -0.014886374585330486,
      -0.004126507788896561,
      -0.0034113877918571234,
      0.031004000455141068,
      -0.053068678826093674,
      -0.016982901841402054,
      -0.04535377398133278,
      0.05265382304787636,
      0.006139070726931095,
      0.042702361941337585,
      0.035750240087509155,
      -0.06766337156295776,
      0.03597238287329674,
      0.024706490337848663,
      0.0658053308725357,
      -0.08539508283138275,
      0.10540374368429184,
      0.06811493635177612,
      0.053762439638376236,
      -0.01842496171593666,
      0.03085673227906227,
      0.03524206951260567,
      0.010805855505168438,
      0.04769190400838852,
      0.0846317932009697,
      0.10833138972520828,
      0.015600243583321571,
      -0.03970273211598396,
      -0.01677234098315239,
      0.05488001927733421,
      -0.025312025099992752,
      0.10076886415481567,
      -0.013076870702207088,
      -0.002754750894382596,
      -0.04445343837141991,
      -0.027181848883628845,
      0.02220890298485756,
      -0.05407072231173515,
      -0.00786974560469389,
      0.03992759436368942,
      -0.0032669585198163986,
      0.017806682735681534,
      -0.07355860620737076,
      -0.027657078579068184,
      0.07251051068305969,
      0.034990474581718445,
      -0.06067776679992676,
      -0.01525017898529768,
      -0.005166422575712204,
      -0.007927828468382359,
      0.008123855106532574,
      -0.10246717184782028,
      0.010640843771398067,
      -0.026339000090956688,
      0.005347512662410736,
      0.07404761016368866,
      0.07117055356502533,
      0.040906332433223724,
      -0.02690442092716694,
      -0.003823199076578021,
      0.03376021981239319,
      0.003212211187928915,
      -0.03937609866261482,
      0.028409764170646667,
      0.01900656893849373,
      -0.014204442501068115,
      0.09414650499820709,
      0.020011721178889275,
      -0.0048866914585232735,
      0.008405747823417187,
      0.031816497445106506,
      -0.09776376932859421,
      -0.03484395146369934,
      0.06130100041627884,
      -0.0028957747854292393,
      0.09024084359407425,
      0.006533323787152767,
      0.11510974168777466,
      0.00819556787610054,
      -0.013457747176289558,
      0.032171830534935,
      -0.019892944023013115,
      -0.01616097241640091,
      -0.0626000389456749,
      0.023296378552913666,
      -0.08228909969329834,
      -0.025477569550275803,
      -0.04817254841327667,
      0.04477612301707268,
      0.01025946531444788,
      -0.06607161462306976,
      -0.0009501626482233405,
      -0.07259045541286469,
      0.0041214521043002605,
      0.0221654511988163,
      0.017009945586323738,
      -0.06769315153360367,
      0.08846837282180786,
      0.014601633884012699,
      -0.09400611370801926,
      -0.040195997804403305,
      -0.06975207477807999,
      -0.01319210696965456,
      -0.014116249047219753,
      -0.04101160541176796,
      0.06679297983646393,
      -0.02703467197716236,
      -0.039107322692871094,
      0.010930046439170837,
      0.0015758371446281672,
      0.03374572470784187,
      -0.08085288107395172,
      -0.005569125059992075,
      -0.0028880839236080647,
      -0.040701620280742645,
      -0.042334023863077164,
      0.05294075608253479,
      0.03152330219745636,
      -0.005405436269938946,
      -0.052187543362379074,
      0.04079063609242439,
      -0.05206741765141487,
      0.005353687796741724,
      -0.1221899464726448,
      -0.015146024525165558,
      -0.013481859117746353,
      -0.07746883481740952,
      0.02372227981686592,
      -0.0409945547580719,
      0.030561234802007675,
      -0.06046207994222641,
      0.07738734036684036,
      0.009633531793951988,
      0.012118324637413025,
      -0.05630890652537346,
      0.01898065395653248,
      0.0735154077410698,
      0.0615224651992321,
      -0.06034034863114357,
      0.017829997465014458,
      -0.043041110038757324,
      -0.008749219588935375,
      0.006900380365550518,
      -0.03439818695187569,
      -0.005202391184866428,
      0.007527675945311785,
      0.045180030167102814,
      -0.11530563235282898,
      0.023074917495250702,
      -0.05379815027117729,
      0.07798135280609131,
      0.022535502910614014,
      0.03789389878511429,
      0.02358223684132099,
      -0.013693809509277344,
      -0.031515415757894516,
      -0.07597111165523529,
      0.012570453807711601,
      0.0037434371188282967,
      -0.08133170008659363,
      -0.020449159666895866,
      0.02965085208415985,
      0.0400644987821579,
      0.020603764802217484,
      0.01994970254600048,
      -0.09985147416591644,
      -0.010978300124406815,
      0.04232843965291977,
      0.010496792383491993,
      -0.09985614567995071,
      -0.060467544943094254,
      0.023631155490875244,
      -0.01754556968808174,
      -0.010822531767189503,
      0.08830565959215164,
      -0.03240228071808815,
      0.027319135144352913,
      0.024897821247577667,
      -0.000490089412778616,
      -0.0977485328912735,
      0.0750274509191513,
      0.010960543528199196,
      -0.005444691050797701
    ],
    [
      0.024095632135868073,
      0.004262994509190321,
      -0.04187498986721039,
      0.056928738951683044,
      -0.03587266430258751,
      0.0729377269744873,
      0.03688766807317734,
      0.038164444267749786,
      0.05309518054127693,
      -0.002111435867846012,
      0.06696264445781708,
      0.03302081674337387,
      0.004116601776331663,
      -0.04924017935991287,
      0.005091344006359577,
      -0.03054128959774971,
      -0.03592692315578461,
      0.015780329704284668,
      -0.047195933759212494,
      0.021557584404945374,
      0.0003941924078390002,
      0.017675312235951424,
      0.08955201506614685,
      0.03751180320978165,
      -0.006880179978907108,
      0.06060020253062248,
      0.08356763422489166,
      -0.0015326235443353653,
      -0.012140743434429169,
      -0.07473602145910263,
      -0.098208487033844,
      0.0221110787242651,
      0.03387764096260071,
      -0.07292404025793076,
      -0.05937562137842178,
      0.07726581394672394,
      0.006633852142840624,
      -0.03354785218834877,
      0.04760346561670303,
      -0.023858513683080673,
      -0.04409128054976463,
      -0.0237134899944067,
      0.006517385598272085,
      0.06640448421239853,
      -0.024437613785266876,
      0.015959633514285088,
      -0.006693630944937468,
      -0.09418293088674545,
      -0.06352129578590393,
      0.06675109267234802,
      0.057658080011606216,
      0.0068425387144088745,
      -0.021744923666119576,
      0.04080357775092125,
      -0.0022450690157711506,
      0.12121948599815369,
      -0.010683711618185043,
      -0.006012238562107086,
      0.014692498371005058,
      0.049173299223184586,
      -0.022014709189534187,
      -0.0619950070977211,
      -0.001911914674565196,
      0.05248425155878067,
      0.047311022877693176,
      -0.03266517072916031,
      0.0315341092646122,
      0.04502881318330765,
      0.07568318396806717,
      0.04285552352666855,
      0.06476189941167831,
      0.052166618406772614,
      -0.0021317361388355494,
      -0.006220718380063772,
      0.029315974563360214,
      0.04085204377770424,
      -0.07113717496395111,
      -0.004481973592191935,
      -0.05267491936683655,
      -0.10271705687046051,
      0.011068351566791534,
      -0.030537201091647148,
      0.0006655151955783367,
      0.037373825907707214,
      -0.08670394867658615,
      0.004034065175801516,
      0.04102308675646782,
      -0.06642278283834457,
      -0.09698861092329025,
      0.07241141051054001,
      -0.02331993542611599,
      -0.018942929804325104,
      -0.01673290692269802,
      0.029088247567415237,
      -0.026714663952589035,
      0.08340135961771011,
      -0.07405376434326172,
      -0.02053149975836277,
      0.028755389153957367,
      -0.03580356761813164,
      -0.045374657958745956,
      0.0009639400523155928,
      -0.034557048231363297,
      0.025591714307665825,
      0.07546915113925934,
      -0.036423176527023315,
      0.0031545350793749094,
      0.014011294580996037,
      0.004434863105416298,
      -0.12357114255428314,
      -0.09367406368255615,
      0.03726719692349434,
      0.10144230723381042,
      0.046738188713788986,
      -0.007408704608678818,
      0.04160592332482338,
      0.007545726839452982,
      0.07329130917787552,
      -0.02815069444477558,
      0.0009553853888064623,
      0.03225024417042732,
      0.07113061845302582,
      0.024429000914096832,
      -0.03190075233578682,
      0.0767938569188118,
      -0.018071182072162628,
      0.01599932834506035,
      -0.059693343937397,
      -0.057784583419561386,
      0.0472450852394104,
      -0.05326550453901291,
      -0.04594612121582031,
      -0.07045958191156387,
      0.03993529453873634,
      -0.013636656105518341,
      -0.010871182195842266,
      4.9731959734344855e-05,
      -0.0019545203540474176,
      -0.031105509027838707,
      0.031349360942840576,
      0.031782329082489014,
      0.005915938410907984,
      -0.04867856949567795,
      0.017070725560188293,
      0.08725398778915405,
      -0.0890863686800003,
      -0.0019284251611679792,
      -0.07409878075122833,
      -0.016831515356898308,
      0.042335521429777145,
      0.07411739230155945,
      0.006075399462133646,
      0.07844843715429306,
      0.05207173526287079,
      -0.03441084176301956,
      -0.06683018058538437,
      0.00818654429167509,
      -0.0068948050029575825,
      -0.07169724255800247,
      0.0384480282664299,
      -0.005323090590536594,
      -0.012466431595385075,
      0.04497682675719261,
      0.03435942903161049,
      0.06289105117321014,
      -0.011168237775564194,
      -0.05903282389044762,
      0.019937491044402122,
      -0.02025420404970646,
      -0.033241115510463715,
      -0.026344764977693558,
      -0.03021482191979885,
      0.039933741092681885,
      0.03925801441073418,
      -0.006201795767992735,
      -0.046586379408836365,
      0.040250036865472794,
      0.038403015583753586,
      -0.027022456750273705,
      0.05714462697505951,
      -0.011714983731508255,
      0.006396174896508455,
      0.023909103125333786,
      0.027301227673888206,
      0.020994095131754875,
      -0.0163520909845829,
      -0.04232661798596382,
      0.020661253482103348,
      -0.05994958057999611,
      0.0005458315717987716,
      -0.06011466309428215,
      -0.078066885471344,
      0.017744343727827072,
      0.02738664671778679,
      -0.034968454390764236,
      -0.04205420985817909,
      -0.04139401763677597,
      0.05086927115917206,
      -0.030417531728744507,
      -0.0034653134644031525,
      0.013154827058315277,
      -0.026862019672989845,
      -0.002570436103269458,
      -0.06659398972988129,
      -0.0364699624478817,
      0.039748381823301315,
      0.00997934490442276,
      0.09291040897369385,
      0.11542589962482452,
      -0.04510912299156189,
      0.06897689402103424,
      0.0473681278526783,
      0.04729297757148743,
      0.002633248222991824,
      0.018649699166417122,
      0.07236399501562119,
      -0.006840844172984362,
      -0.03071189858019352,
      0.05531666800379753,
      -0.051144957542419434,
      -0.00527423620223999,
      -0.0316968597471714,
      0.06396590173244476,
      -0.04193037748336792,
      -0.03293322026729584,
      -0.09704619646072388,
      0.0025565163232386112,
      -0.009390112943947315,
      -0.02520808018743992,
      0.014542964287102222,
      -0.040129851549863815,
      0.0020793576259166002,
      0.02705128863453865,
      0.03827369958162308,
      0.02944258414208889,
      0.023053154349327087,
      -0.042714204639196396,
      0.04403741657733917,
      0.03872310370206833,
      -0.00047270263894461095,
      0.000847407674882561,
      0.040340397506952286,
      0.0017375013558194041,
      0.020950639620423317,
      -0.026097940281033516,
      0.05655518174171448,
      0.004386394750326872,
      0.04051467031240463,
      0.03638050705194473,
      -0.018501976504921913,
      -0.03770173341035843,
      0.03454969823360443,
      -0.035987723618745804,
      -0.0969950333237648,
      -0.09061998128890991,
      -0.022367771714925766,
      -0.02948801778256893,
      0.010828230530023575,
      -0.0020373547449707985,
      -0.0312727652490139,
      -0.023424018174409866,
      -0.02116023190319538,
      0.01753152906894684,
      -0.03635590523481369,
      0.04211493954062462,
      -0.04601151868700981,
      0.11239621043205261,
      -0.012976396828889847,
      0.010235468856990337,
      -0.01075634453445673,
      0.07357514649629593,
      -0.042803339660167694,
      -0.030236033722758293,
      -0.005373036954551935,
      -0.07875778526067734,
      0.04700102657079697,
      -0.003035452449694276,
      0.02682305872440338,
      0.005718112923204899,
      0.019481457769870758,
      -0.06397516280412674,
      0.10138125717639923,
      0.019596826285123825,
      -0.049638744443655014,
      -0.018356407061219215,
      0.029107466340065002,
      0.04247710481286049,
      0.018310002982616425,
      -0.014662958681583405,
      0.04672887921333313,
      -0.015500170178711414,
      -0.025081496685743332,
      0.03139488399028778,
      0.03372976928949356,
      0.0011467699659988284,
      0.029270857572555542,
      -0.05853020027279854,
      -0.009535017423331738,
      0.043642692267894745,
      0.026675863191485405,
      0.009524465538561344,
      -0.0724988505244255,
      0.06116757541894913,
      0.010699986480176449,
      0.010720143094658852,
      0.03945668414235115,
      -0.034427348524332047,
      -0.015561452135443687,
      -0.07529986649751663,
      0.03219957649707794,
      -0.023804740980267525,
      0.0390290692448616,
      -0.01672234944999218,
      0.07029791921377182,
      0.031068069860339165,
      -0.11413763463497162,
      -0.02777179703116417,
      -0.01830463670194149,
      -0.0692068487405777,
      -0.0012040730798617005,
      0.00888647697865963,
      0.006519377697259188,
      0.06376650929450989,
      -0.04516284167766571,
      0.027283158153295517,
      -0.03749725595116615,
      0.021440448239445686,
      0.01065653283149004,
      0.0037309222389012575,
      -0.03505256026983261,
      0.02302374318242073,
      -0.007615657988935709,
      0.08150013536214828,
      -0.010582267306745052,
      -0.02130800113081932,
      0.013717868365347385,
      0.037635259330272675,
      -0.057016678154468536,
      -0.06738671660423279,
      -0.023846732452511787,
      -0.05824555084109306,
      0.041278380900621414,
      -0.03309810906648636,
      -0.0304502435028553,
      0.038656286895275116,
      0.05874275043606758,
      0.010258076712489128,
      0.014378836378455162,
      0.04701513051986694,
      -0.01966634765267372,
      0.011468219570815563,
      -0.014710545539855957,
      0.04038676619529724,
      -0.10769853740930557,
      0.010794810950756073,
      0.017037466168403625,
      0.06802675873041153,
      -0.013503092341125011,
      -0.030782032757997513,
      -0.041212037205696106,
      -0.01413990743458271,
      -0.0579315684735775,
      -0.033697765320539474,
      -0.02677847258746624,
      -0.009543199092149734,
      -0.01452034804970026,
      0.013898559845983982,
      -0.007829226553440094,
      -0.01179021131247282,
      0.07666707038879395,
      -0.020936159417033195,
      0.08100971579551697,
      -0.04544148966670036,
      0.06615650653839111,
      -0.009009922854602337,
      0.054036278277635574,
      -0.022587187588214874,
      -0.0051695904694497585,
      0.04485170543193817,
      -0.01254577748477459,
      -0.00622511375695467,
      0.006517191883176565,
      0.00601836945861578,
      -0.09119486808776855,
      0.015859877690672874,
      -0.0072662606835365295,
      0.023023352026939392,
      -0.07936353981494904,
      0.0012672834564000368,
      -0.10581117868423462,
      0.045620303601026535,
      0.004363966174423695,
      -0.004182362928986549,
      0.05471428111195564,
      -0.033940624445676804,
      0.05913125351071358,
      0.004997752606868744,
      -0.055840618908405304,
      -0.14666229486465454,
      -0.05039102956652641,
      -0.04230247065424919,
      0.0457124300301075,
      0.010732713155448437,
      -0.020434528589248657,
      0.002042267704382539,
      -0.01415978092700243,
      0.035387080162763596,
      -0.021834922954440117,
      0.0015729613369330764,
      0.05893821641802788,
      -0.0659031867980957,
      0.04399676248431206,
      -0.001394358230754733,
      -0.012703342363238335,
      -0.07706014066934586,
      0.014448351226747036,
      0.018406379967927933,
      0.026999717578291893,
      0.08727233111858368,
      0.0553198978304863,
      -0.059485115110874176,
      -0.029433604329824448,
      -0.05914297699928284,
      0.0063638873398303986,
      -0.0354042649269104,
      -0.02226191759109497,
      -0.04958954453468323,
      0.052226293832063675,
      0.021483823657035828,
      0.03681691735982895,
      -0.0004970987210981548,
      -0.008426513522863388,
      0.008446735329926014,
      -0.05149592459201813,
      -0.004433153197169304,
      0.02950490266084671,
      0.043177179992198944,
      0.02265329100191593,
      -0.02570423111319542,
      0.06849035620689392,
      -0.03472867235541344,
      0.024103689938783646,
      -0.00868601631373167,
      0.05902066081762314,
      0.00872713141143322,
      -0.02062905766069889,
      0.039067257195711136,
      -0.019188111647963524,
      0.04685771092772484,
      -0.022086508572101593,
      0.030983518809080124,
      -0.09078731387853622,
      0.017973702400922775,
      0.06358235329389572,
      -0.029740795493125916,
      0.050588224083185196,
      -0.04669585078954697,
      -0.007578403688967228,
      0.029289361089468002,
      -0.004460867960005999,
      -0.016875294968485832,
      0.0050327349454164505,
      0.03955128788948059,
      0.07847818732261658,
      0.03173975273966789,
      -0.01949336752295494,
      0.08046622574329376,
      -0.06643364578485489,
      0.07598573714494705,
      -0.034055229276418686,
      0.04984576627612114,
      0.039796192198991776,
      0.01622701995074749,
      0.010716027580201626,
      0.016550960019230843,
      0.09941122680902481,
      -0.05398901551961899,
      0.013227510266005993,
      0.06622248142957687,
      -0.006757511291652918,
      0.004889308474957943,
      0.020561618730425835,
      0.020450403913855553,
      0.013744639232754707,
      -0.02528158202767372,
      0.008531946688890457,
      -0.05140460282564163,
      0.0037801938597112894,
      -0.06135716661810875,
      -0.055640220642089844,
      -0.04777366667985916,
      -0.007890036329627037,
      0.09960692375898361,
      0.021918324753642082,
      0.05602596700191498,
      -0.02909139357507229,
      -0.00013722977018915117,
      -0.003376720240339637,
      0.023174358531832695,
      -0.07636164128780365,
      -0.09731307625770569,
      0.04492761939764023,
      -0.009196198545396328
    ],
    [
      0.028152475133538246,
      0.005301338620483875,
      0.026086565107107162,
      0.027648234739899635,
      -0.008805839344859123,
      0.024664273485541344,
      0.07858696579933167,
      -0.026202762499451637,
      -0.02154863253235817,
      0.1331859529018402,
      0.03715014085173607,
      -0.054452743381261826,
      0.02877882309257984,
      0.031351909041404724,
      -0.006481422111392021,
      -0.08472923934459686,
      -0.004715122748166323,
      -0.0018169190734624863,
      -0.023168396204710007,
      0.03856242820620537,
      0.03920142725110054,
      0.03779705613851547,
      -0.022374115884304047,
      0.06223379448056221,
      -0.031461816281080246,
      0.011451076716184616,
      -0.06470420956611633,
      0.029733536764979362,
      -0.004846587311476469,
      0.025147730484604836,
      0.014465275220572948,
      0.01918340101838112,
      0.04042903333902359,
      -0.003428341820836067,
      -0.030981358140707016,
      -0.03494267165660858,
      -0.0164524856954813,
      0.06773169338703156,
      0.031663332134485245,
      -0.02485365979373455,
      -0.04964570328593254,
      -0.021455220878124237,
      -0.01859043911099434,
      0.11216083914041519,
      -0.010963981971144676,
      -0.10516908019781113,
      0.037851788103580475,
      -0.058455806225538254,
      0.016007199883461,
      0.06763643026351929,
      0.03979531675577164,
      0.012756399810314178,
      -0.042165495455265045,
      -0.1038181409239769,
      -0.01298425905406475,
      0.027739398181438446,
      0.05486932024359703,
      -0.08254868537187576,
      0.005220270715653896,
      0.008495332673192024,
      0.07765685766935349,
      0.0595780611038208,
      0.011840617284178734,
      -0.029731813818216324,
      -0.07621786743402481,
      -0.025067545473575592,
      0.011420955881476402,
      -0.05345363914966583,
      -0.0663575753569603,
      0.023518361151218414,
      0.0022122806403785944,
      -0.0477772057056427,
      -0.04756321385502815,
      -0.04691680148243904,
      -0.028880326077342033,
      -0.07615353167057037,
      0.08510714024305344,
      -0.003311876207590103,
      0.10642678290605545,
      0.012582345865666866,
      -0.018288662657141685,
      0.03967287391424179,
      -0.029927600175142288,
      -0.0118767861276865,
      -0.020485179498791695,
      -0.03029143437743187,
      0.02073422074317932,
      -0.07307647168636322,
      0.0013167803408578038,
      0.030221154913306236,
      -0.08600018173456192,
      -0.07109250873327255,
      -0.032472919672727585,
      0.018207289278507233,
      0.037245605140924454,
      -0.05390891432762146,
      0.0021164347417652607,
      -0.08064737170934677,
      -0.0032657841220498085,
      -0.07123073935508728,
      0.03653274103999138,
      0.04380907863378525,
      -0.047910548746585846,
      -0.0026868886779993773,
      0.040988970547914505,
      0.015252847224473953,
      0.017706889659166336,
      -0.03730272874236107,
      0.026164105162024498,
      0.018383171409368515,
      0.007712838239967823,
      0.015174594707787037,
      -0.03598819673061371,
      0.06480219960212708,
      -0.018027115613222122,
      -0.035792239010334015,
      0.004310316406190395,
      0.08303627371788025,
      0.06216542050242424,
      -0.0036476263776421547,
      0.07306273281574249,
      -0.0276264026761055,
      0.007580135948956013,
      -0.003350463230162859,
      0.013667751103639603,
      0.05864691734313965,
      0.05642002075910568,
      0.04663495346903801,
      0.012318063527345657,
      0.01282204408198595,
      -0.10080239176750183,
      0.04895572364330292,
      -0.02810676582157612,
      0.048572372645139694,
      0.029091913253068924,
      0.03779090568423271,
      0.04906130209565163,
      -0.05256853997707367,
      0.022379621863365173,
      0.06734234094619751,
      0.015543612651526928,
      -0.03302721306681633,
      -0.12623341381549835,
      0.04855326935648918,
      0.018911708146333694,
      -0.0013535581529140472,
      -0.00890854001045227,
      0.028209201991558075,
      -0.039952732622623444,
      -0.022068476304411888,
      0.014815174974501133,
      0.11855867505073547,
      -0.02925117127597332,
      -0.021943574771285057,
      0.016859691590070724,
      -0.0033868905156850815,
      -0.016659416258335114,
      -0.04472750797867775,
      0.07652821391820908,
      0.0389888770878315,
      -0.02212677150964737,
      0.057263437658548355,
      0.01941617950797081,
      0.005128669552505016,
      0.01846286840736866,
      -0.04283478856086731,
      -0.08570840954780579,
      0.024688754230737686,
      0.020936017856001854,
      -0.019447915256023407,
      0.02228141762316227,
      0.032333265990018845,
      -0.03576473519206047,
      -0.018558192998170853,
      -0.0016794762341305614,
      0.005841502919793129,
      -0.019405411556363106,
      0.0807824656367302,
      -0.03975756838917732,
      0.048905447125434875,
      0.0012006046017631888,
      0.06829029321670532,
      0.03192472830414772,
      0.050067201256752014,
      -0.055116716772317886,
      -0.019326668232679367,
      -0.019500436261296272,
      0.07877872884273529,
      -0.06147867441177368,
      -0.0639876052737236,
      0.03938615694642067,
      0.018779616802930832,
      0.015948297455906868,
      -0.08461178839206696,
      0.015316280536353588,
      -0.02110295556485653,
      0.013947620056569576,
      0.06528738886117935,
      0.008965902961790562,
      -0.09865231066942215,
      -0.025668280199170113,
      -0.010056898929178715,
      -0.010290615260601044,
      0.02272281050682068,
      -0.014361007139086723,
      -0.040386371314525604,
      -0.10965179651975632,
      0.06858569383621216,
      -0.0004123457765672356,
      0.021567532792687416,
      -0.013076246716082096,
      -0.05809745192527771,
      0.045270998030900955,
      0.059193648397922516,
      -0.013990132138133049,
      0.011983306147158146,
      0.05851230397820473,
      -0.08739341050386429,
      0.04604189470410347,
      0.026213277131319046,
      -0.0397924929857254,
      -0.02511010691523552,
      0.009354181587696075,
      0.019634509459137917,
      -0.03714084252715111,
      0.06863302737474442,
      -0.0058986106887459755,
      0.04793425649404526,
      0.015189532190561295,
      -0.0666578859090805,
      0.019268572330474854,
      -0.005272950977087021,
      -0.01550295576453209,
      -0.00036712331348098814,
      -0.06013720482587814,
      0.08434345573186874,
      -0.023817846551537514,
      -0.0014752208953723311,
      0.03909589722752571,
      -0.012171528302133083,
      0.06073686480522156,
      -0.038014113903045654,
      -0.012072820216417313,
      0.01729333959519863,
      -0.02200777642428875,
      -0.026318559423089027,
      0.002386500360444188,
      0.04962427169084549,
      -0.008153749629855156,
      -0.03194880858063698,
      -0.0029459542129188776,
      -0.043052367866039276,
      -0.038319703191518784,
      0.06917893141508102,
      0.0733298510313034,
      -0.01610085368156433,
      0.009537489153444767,
      -0.022550741210579872,
      0.04029272869229317,
      0.006660827901214361,
      0.023988118395209312,
      -0.014557301066815853,
      -0.0012459855061024427,
      0.004946934059262276,
      -0.06192738935351372,
      0.02010139636695385,
      -0.046828895807266235,
      -0.05141589790582657,
      0.04155806824564934,
      -0.007811449933797121,
      0.03940682113170624,
      -0.003313635243102908,
      -0.02621082216501236,
      -0.0009368182509206235,
      0.0372086837887764,
      -0.011184644885361195,
      -0.09890822321176529,
      -0.005911408457905054,
      0.03435726463794708,
      -0.02267954684793949,
      -0.08214503526687622,
      0.04615029692649841,
      -0.056950174272060394,
      -0.06440108269453049,
      -0.02312159352004528,
      0.03321424499154091,
      0.0672006905078888,
      -0.04414942488074303,
      -0.04787738621234894,
      0.014614490792155266,
      -0.07646641135215759,
      0.04998726025223732,
      -0.005192141979932785,
      -0.02021014876663685,
      0.03547590225934982,
      0.0913337916135788,
      0.04205479472875595,
      -0.0005854652845300734,
      -0.0209139846265316,
      -0.036211948841810226,
      0.03624908998608589,
      0.03816286474466324,
      -0.027662208303809166,
      0.01941821351647377,
      -0.02893393486738205,
      -0.028588293120265007,
      0.03228520601987839,
      -0.04812898114323616,
      0.034125588834285736,
      0.023841699585318565,
      -0.003513755975291133,
      0.04190489277243614,
      0.0017145286547020078,
      -0.02731308341026306,
      -0.08853400498628616,
      -0.011452484875917435,
      0.06529498845338821,
      0.07865343242883682,
      0.011265991255640984,
      -0.032333701848983765,
      0.025463078171014786,
      -0.03204744681715965,
      -0.0027578759472817183,
      0.0163428895175457,
      0.0031184202525764704,
      0.015673277899622917,
      0.013265497982501984,
      0.0030234206933528185,
      0.11471251398324966,
      -0.04826619103550911,
      0.03524982929229736,
      0.017444130033254623,
      -0.0111116673797369,
      0.10393708199262619,
      0.033731527626514435,
      -0.003581172553822398,
      0.015439679846167564,
      -0.03783927112817764,
      -0.021212629973888397,
      -0.0036658537574112415,
      -0.0025668207090348005,
      -0.11597920209169388,
      -0.002426300896331668,
      -0.010882681235671043,
      0.07791012525558472,
      -0.011095333844423294,
      -0.0968204140663147,
      0.032523103058338165,
      0.01712326519191265,
      0.08554409444332123,
      0.02423558011651039,
      -0.0365862101316452,
      0.000379808567231521,
      -0.06591787189245224,
      -0.07667671889066696,
      -0.09059402346611023,
      -0.04917849600315094,
      -0.10871213674545288,
      -0.10809660702943802,
      0.013247254304587841,
      0.011825820431113243,
      -0.01290825568139553,
      0.02570922113955021,
      -0.02429201453924179,
      0.0010623669950291514,
      0.05019393563270569,
      0.0023041521199047565,
      -0.0022384305484592915,
      0.021929578855633736,
      -0.008099646307528019,
      -0.021595435217022896,
      0.021400053054094315,
      0.06785139441490173,
      -0.04930124431848526,
      0.023756470531225204,
      -0.04664018750190735,
      -0.009920147247612476,
      0.03831653296947479,
      -0.06813893467187881,
      -0.005639476701617241,
      0.018309369683265686,
      -0.08890324831008911,
      -0.05871908366680145,
      -0.05539312586188316,
      -0.008533925749361515,
      -0.06702960282564163,
      0.03166646137833595,
      -0.07807239145040512,
      -0.06959690153598785,
      0.029477087780833244,
      0.014459517784416676,
      0.014054014347493649,
      -0.041812241077423096,
      0.010585609823465347,
      -0.019720187410712242,
      -0.09520029276609421,
      0.0726284459233284,
      -0.04026394709944725,
      0.054277416318655014,
      0.010294650681316853,
      0.012966912239789963,
      0.011434576474130154,
      0.024987051263451576,
      -0.010650760494172573,
      -0.013051845133304596,
      -0.01170478854328394,
      -0.02740599401295185,
      0.007322956342250109,
      0.06700704991817474,
      -0.007857577875256538,
      -0.09003365784883499,
      0.007097066380083561,
      0.04785832390189171,
      0.07182755321264267,
      -0.007208713795989752,
      0.08339390158653259,
      0.007307035382837057,
      0.024212710559368134,
      -0.00881241075694561,
      -0.02134541980922222,
      -0.04681089147925377,
      -0.05780620500445366,
      -0.03949522599577904,
      -0.01579427160322666,
      -0.039860621094703674,
      -0.0018215910531580448,
      0.001806540065445006,
      0.008190357126295567,
      -0.06725506484508514,
      0.06454845517873764,
      -0.06405268609523773,
      -0.012884367257356644,
      0.0571289025247097,
      0.023846887052059174,
      -0.010113686323165894,
      -0.06246509775519371,
      0.006979787722229958,
      0.0582040399312973,
      0.00595029117539525,
      0.03549816459417343,
      -0.06438504904508591,
      0.005166079383343458,
      -0.0002635277051012963,
      0.008505639620125294,
      0.01300482451915741,
      -0.002998922485858202,
      0.016613712534308434,
      0.034981805831193924,
      0.012123383581638336,
      -0.012224104255437851,
      -0.02914038673043251,
      -0.03199073672294617,
      -0.025075362995266914,
      -0.017075467854738235,
      -0.0027495555114001036,
      0.05737599357962608,
      0.03412763029336929,
      -0.007696751970797777,
      -0.025103237479925156,
      -0.03451642021536827,
      -0.010741644538939,
      -0.05491550639271736,
      0.02480461448431015,
      -0.0063917734660208225,
      0.032926417887210846,
      -0.027659166604280472,
      -0.020053548738360405,
      -0.01495263073593378,
      0.11307141929864883,
      -0.08116180449724197,
      -0.01543885562568903,
      -0.04668077081441879,
      -0.06781544536352158,
      0.03414565697312355,
      0.016934359446167946,
      0.03234555944800377,
      -0.03693678602576256,
      -0.016339058056473732,
      -0.021231399849057198,
      -0.05645214393734932,
      -0.02936551906168461,
      0.024187343195080757,
      0.03775721788406372,
      -0.04229514300823212,
      0.005402167793363333,
      0.03166557475924492,
      -0.08525346219539642,
      0.034846313297748566,
      -0.005129938945174217,
      -0.04904269054532051,
      0.02630409598350525,
      0.003024784615263343,
      -0.029155703261494637,
      -0.0083616953343153,
      0.008019776083528996,
      -0.012971045449376106,
      0.02194792777299881,
      0.03228228911757469,
      0.005102755501866341,
      -0.061117399483919144,
      0.004405091051012278,
      0.06681431084871292,
      -0.007309886161237955
    ],
    [
      -0.04258299246430397,
      -0.06934738904237747,
      0.017878225073218346,
      -0.111873097717762,
      -0.00017328240210190415,
      -0.0293270293623209,
      -0.042937226593494415,
      -0.03640914708375931,
      0.003459353232756257,
      -0.0013530525611713529,
      0.04341191053390503,
      0.07458570599555969,
      0.042569439858198166,
      0.03385194018483162,
      -0.06306034326553345,
      -0.06057090684771538,
      -0.04506896808743477,
      0.014312307350337505,
      -0.0163001399487257,
      0.007484212517738342,
      0.06032315269112587,
      -0.042634863406419754,
      -0.010497246868908405,
      0.015712762251496315,
      -0.030362864956259727,
      0.02869831584393978,
      0.028684409335255623,
      -0.015524172224104404,
      0.009681793861091137,
      0.005727226380258799,
      -0.0440925732254982,
      -0.004281564615666866,
      0.07244232296943665,
      0.01863364316523075,
      -0.02978367544710636,
      -0.08039362728595734,
      0.05675733834505081,
      0.03523034602403641,
      -0.04425201565027237,
      0.014117670245468616,
      -0.018047146499156952,
      0.06028497591614723,
      0.019561534747481346,
      0.035837266594171524,
      -0.02730582281947136,
      -0.1486109495162964,
      -0.007919445633888245,
      -0.017657838761806488,
      -0.01837036944925785,
      -0.03389174863696098,
      0.10945199429988861,
      -0.039249639958143234,
      -0.0439058355987072,
      -0.0521036721765995,
      -0.03198200836777687,
      -0.05798254907131195,
      -0.01477196253836155,
      -0.08275797218084335,
      0.0384993813931942,
      0.008521824143826962,
      -0.04341699555516243,
      0.030171344056725502,
      0.03045080043375492,
      -0.001251501147635281,
      -0.01656176522374153,
      -0.022163525223731995,
      0.03677847608923912,
      -0.05735887214541435,
      -0.00529341958463192,
      0.09650852531194687,
      -0.07678401470184326,
      0.02040720544755459,
      -0.08124672621488571,
      -0.04696820676326752,
      -0.022961994633078575,
      0.031714554876089096,
      0.04529006406664848,
      0.029377536848187447,
      -0.010289310477674007,
      0.010025886818766594,
      0.016088375821709633,
      -0.021197453141212463,
      -0.03865944221615791,
      0.05776694789528847,
      -0.06384929269552231,
      0.01992710307240486,
      -0.04588060453534126,
      -0.09062432497739792,
      0.005686841905117035,
      -0.019390862435102463,
      0.07304108142852783,
      0.034620046615600586,
      0.06391653418540955,
      -0.02037735842168331,
      -0.00010699332051444799,
      -0.005956765729933977,
      0.043559059500694275,
      0.07805386930704117,
      0.06831938773393631,
      -0.04434932395815849,
      0.006291838362812996,
      0.021887140348553658,
      -0.009788711555302143,
      0.030515486374497414,
      0.07195725291967392,
      -0.05652426555752754,
      -0.041065514087677,
      -0.09664735943078995,
      0.024080028757452965,
      0.06573785841464996,
      0.07983513921499252,
      -0.04909773916006088,
      -0.02696489542722702,
      -0.01433521043509245,
      -0.03148799017071724,
      0.0708540678024292,
      -0.024630265310406685,
      0.05067915469408035,
      0.02176043763756752,
      -0.04056430235505104,
      0.025347299873828888,
      0.009113060310482979,
      0.018615828827023506,
      0.0029878888744860888,
      -0.00023294637503568083,
      -0.011492456309497356,
      -0.09840051829814911,
      0.05045198276638985,
      0.023762455210089684,
      -0.013245710171759129,
      -0.035262394696474075,
      -0.045046448707580566,
      -0.03119189850986004,
      0.06829190254211426,
      0.07316228747367859,
      -0.049717508256435394,
      0.013025190681219101,
      -0.02019667625427246,
      0.01882152445614338,
      -0.014636448584496975,
      0.067532479763031,
      -0.058751482516527176,
      -0.02156754583120346,
      -0.070992611348629,
      0.025835493579506874,
      0.0037562032230198383,
      -0.08779632300138474,
      -0.06255391240119934,
      0.0639868676662445,
      -0.08589643985033035,
      0.018733374774456024,
      -0.018847456201910973,
      0.03344254940748215,
      0.024387940764427185,
      0.049036119133234024,
      0.0008744979277253151,
      0.005053095985203981,
      0.07704269140958786,
      -0.0252980999648571,
      -0.015000668354332447,
      0.060052935034036636,
      -0.01793581433594227,
      0.03831182420253754,
      -0.026637032628059387,
      0.060541339218616486,
      0.04467180371284485,
      -0.022006146609783173,
      0.08293256908655167,
      0.04974838346242905,
      0.0712115541100502,
      0.06883084028959274,
      0.04358964040875435,
      -0.0037880167365074158,
      0.024555090814828873,
      0.021777112036943436,
      -0.020737148821353912,
      -0.002985516795888543,
      -0.06382898986339569,
      -0.055538613349199295,
      -0.0030476017855107784,
      0.01371781900525093,
      0.06327412277460098,
      -0.062614805996418,
      -0.017083387821912766,
      -0.02657260373234749,
      -0.05518170818686485,
      0.0026152681093662977,
      0.0619785413146019,
      -0.05590754747390747,
      -0.019790953025221825,
      0.026508407667279243,
      0.04359927400946617,
      -0.024647114798426628,
      0.010127662681043148,
      -0.06217643618583679,
      0.016809824854135513,
      0.03627212718129158,
      0.06348379701375961,
      -0.019688792526721954,
      -0.07452551275491714,
      0.06374073028564453,
      0.030015837401151657,
      -0.007695878855884075,
      0.013143845833837986,
      -0.09421979635953903,
      0.0053457715548574924,
      -0.05459363013505936,
      0.005050773732364178,
      -0.01961533911526203,
      0.010712645016610622,
      0.10606884956359863,
      -0.007842008955776691,
      0.07455175369977951,
      0.11980592459440231,
      -0.03752432391047478,
      -0.0032388537656515837,
      -0.03260976821184158,
      -0.009069710038602352,
      0.04734502360224724,
      -0.013076236471533775,
      -0.047981057316064835,
      -0.012265507131814957,
      -0.052629195153713226,
      0.03761184215545654,
      0.051258258521556854,
      -0.02741411328315735,
      0.02980077639222145,
      -0.03898542746901512,
      -0.026078110560774803,
      -0.03711900860071182,
      0.04482996091246605,
      -0.02070317417383194,
      0.008314237929880619,
      0.05654546245932579,
      0.018001388758420944,
      0.07328981906175613,
      0.007187978830188513,
      0.027570674195885658,
      0.05579757317900658,
      0.0006182577344588935,
      0.009066933766007423,
      -0.016122180968523026,
      -0.04699959605932236,
      -0.08396811783313751,
      -0.010267245583236217,
      -0.10022306442260742,
      0.006525571923702955,
      0.03433997184038162,
      -0.0007899069460108876,
      0.03336330130696297,
      -0.00012270177830941975,
      -0.07071471214294434,
      -0.02457372657954693,
      -0.005860389210283756,
      -0.06917835026979446,
      -0.003653667401522398,
      -0.07468613237142563,
      -0.01652487739920616,
      -0.040634166449308395,
      -0.010717436671257019,
      0.010728552006185055,
      -0.038405705243349075,
      0.017285354435443878,
      -0.09290193766355515,
      -0.036743033677339554,
      0.01959615759551525,
      -0.0013902804348617792,
      0.11638110131025314,
      0.019413555040955544,
      -0.036241140216588974,
      0.04181032255291939,
      0.0450860969722271,
      0.08765508979558945,
      -0.05287837237119675,
      -0.10344091057777405,
      0.0024498854763805866,
      -0.023893075063824654,
      -0.04379637911915779,
      0.03948288410902023,
      0.0347675085067749,
      -0.0574975460767746,
      -0.029712453484535217,
      -0.01087708119302988,
      0.04696592316031456,
      0.044772081077098846,
      -0.07759886234998703,
      0.035871122032403946,
      0.047558460384607315,
      0.032302144914865494,
      -0.003345383098348975,
      -0.03225399926304817,
      -0.061794713139534,
      0.029808904975652695,
      0.05122027173638344,
      0.07693540304899216,
      -0.02116728387773037,
      -0.0002726703532971442,
      0.033622536808252335,
      -0.030687814578413963,
      0.006887743249535561,
      0.0029291282407939434,
      -0.09874139726161957,
      0.053645577281713486,
      0.008152041584253311,
      0.08978517353534698,
      0.05591552332043648,
      -0.02700456790626049,
      0.021446475759148598,
      -0.013923530466854572,
      0.05015287548303604,
      0.011557349935173988,
      -0.028812026605010033,
      0.015228361822664738,
      0.07178714126348495,
      0.008957562036812305,
      0.02689329721033573,
      0.018533501774072647,
      0.04452427476644516,
      -0.06177540868520737,
      0.00023325160145759583,
      -0.00826296117156744,
      -0.0021758137736469507,
      -0.03731917589902878,
      0.07283564656972885,
      -0.010726596228778362,
      -0.007638791110366583,
      0.08089584857225418,
      0.06072913482785225,
      0.04228757321834564,
      0.04597508907318115,
      0.04185306280851364,
      0.057081934064626694,
      -0.0008965415763668716,
      -0.11175817251205444,
      0.023915844038128853,
      -0.007638055365532637,
      0.009091508574783802,
      -0.0321681872010231,
      -0.0005152084631845355,
      0.056661207228899,
      -0.047988057136535645,
      -0.005721160210669041,
      -0.005671458784490824,
      0.004050105344504118,
      0.09461372345685959,
      -0.03917744383215904,
      0.07088884711265564,
      0.019089534878730774,
      0.03504593297839165,
      -0.09932015836238861,
      0.0034999696072191,
      0.01729491353034973,
      0.006464736070483923,
      -0.010318883694708347,
      0.03394211456179619,
      0.07418008893728256,
      -0.05471908301115036,
      -0.018735641613602638,
      -0.004180916119366884,
      -0.04092979431152344,
      0.009711403399705887,
      -0.03864686191082001,
      0.04948713630437851,
      0.042719386518001556,
      0.009744973853230476,
      -0.05035928636789322,
      -0.005906194914132357,
      0.010458712466061115,
      -0.0008017697837203741,
      0.04105140268802643,
      -0.0456051267683506,
      0.0487273707985878,
      -0.10715377330780029,
      -0.07377596944570541,
      0.05904637277126312,
      -0.012071074917912483,
      0.01830397918820381,
      0.014991928823292255,
      -0.03211100772023201,
      0.004591051954776049,
      0.01362489815801382,
      0.006521564442664385,
      -0.024285361170768738,
      -0.0035305058117955923,
      -0.03747553750872612,
      0.018381506204605103,
      0.000571900571230799,
      0.004162589553743601,
      0.03282778337597847,
      -0.009800594300031662,
      -0.01635810360312462,
      -0.025846168398857117,
      -0.010656392201781273,
      0.03609126806259155,
      -0.022669145837426186,
      -0.0007261504651978612,
      -0.07065525650978088,
      -0.015349555760622025,
      0.006333420053124428,
      0.02475215122103691,
      -0.038468122482299805,
      -0.04575757309794426,
      -0.022499823942780495,
      -0.021582504734396935,
      -0.022914616391062737,
      0.014770993031561375,
      -0.03343509882688522,
      -0.039149828255176544,
      0.005863747093826532,
      -0.019317172467708588,
      -0.06519072502851486,
      0.00014007232675794512,
      -0.10667148977518082,
      -0.07230650633573532,
      0.008673446252942085,
      -0.06972050666809082,
      -0.0245012566447258,
      -0.06885498762130737,
      -0.031969308853149414,
      -0.01096508651971817,
      0.020252570509910583,
      -0.0057533816434443,
      0.06323838979005814,
      0.0022700708359479904,
      0.05918283015489578,
      0.005271731875836849,
      -0.06770645827054977,
      0.015301140956580639,
      0.007461038418114185,
      0.02384568378329277,
      0.03407195210456848,
      -0.04245903715491295,
      0.11884269118309021,
      -0.027505846694111824,
      -0.014643102884292603,
      -0.001312616397626698,
      0.03565267473459244,
      0.0019444379722699523,
      -0.029893510043621063,
      -0.0761217251420021,
      -0.03559071943163872,
      0.06517281383275986,
      0.024212181568145752,
      0.004061195068061352,
      0.004876438528299332,
      0.01392205711454153,
      -0.003817654214799404,
      0.007238482590764761,
      0.1404666304588318,
      0.0028346353210508823,
      -0.027411334216594696,
      0.03318570554256439,
      -0.0345669649541378,
      0.025776373222470284,
      0.05162675306200981,
      0.036367110908031464,
      -0.07654653489589691,
      0.10025180876255035,
      0.02834918163716793,
      0.020593807101249695,
      0.07074057310819626,
      0.037767909467220306,
      -0.02038121409714222,
      -0.002429751679301262,
      -0.04951716214418411,
      -0.042493630200624466,
      0.005026920232921839,
      0.004459090996533632,
      0.03429128974676132,
      -0.018783314153552055,
      0.03086502104997635,
      -0.03460635617375374,
      -0.04048832133412361,
      -0.06022431328892708,
      0.023449309170246124,
      0.046443816274404526,
      -0.0812477394938469,
      -0.04491172358393669,
      -0.0023484141565859318,
      0.03057984635233879,
      0.00011037804506486282,
      0.028631892055273056,
      -0.037356920540332794,
      -0.09114769101142883,
      0.010646187700331211,
      -0.017226630821824074,
      -0.03813973814249039,
      0.04607652872800827,
      -0.003290453925728798,
      0.027923330664634705,
      0.10038963705301285,
      -0.07349873334169388,
      0.06780797243118286,
      -0.01966836303472519,
      0.00031999629572965205,
      0.016705606132745743,
      -0.013548323884606361,
      0.04491977393627167,
      0.04521580785512924,
      -0.022799871861934662,
      -0.059235990047454834,
      -0.012628857977688313,
      -0.003468551440164447
    ],
    [
      0.001360189402475953,
      -0.008106730878353119,
      0.03340938314795494,
      -0.021958641707897186,
      0.005841997917741537,
      -0.0035568815656006336,
      0.016622385010123253,
      -0.026503844186663628,
      0.010278088040649891,
      0.03744412213563919,
      0.034815892577171326,
      0.08447258919477463,
      -0.05051950737833977,
      0.06054765358567238,
      0.06224145367741585,
      -0.031982045620679855,
      0.04734519496560097,
      -0.03249311074614525,
      0.015993794426321983,
      -0.04891837760806084,
      0.0545973926782608,
      0.05666220188140869,
      -0.029579758644104004,
      -0.009010192938148975,
      -0.0762685090303421,
      -0.020714350044727325,
      0.06269523501396179,
      -0.0227621141821146,
      -0.02732616662979126,
      -0.03825231269001961,
      0.014207528904080391,
      -0.0031236144714057446,
      -0.021129393950104713,
      -0.01707693748176098,
      0.007820157334208488,
      0.019763978198170662,
      0.04289380460977554,
      0.03369796648621559,
      -0.07044803351163864,
      -0.08562362939119339,
      -0.09900880604982376,
      0.0034447310026735067,
      0.06422299891710281,
      0.005915879271924496,
      -0.03234930709004402,
      0.031532227993011475,
      -0.03469836339354515,
      0.018488291651010513,
      -0.000570617092307657,
      0.014129486866295338,
      0.03920779004693031,
      0.023092206567525864,
      0.0010335957631468773,
      -0.05935484543442726,
      0.0775906965136528,
      0.024095037952065468,
      0.002078963676467538,
      0.02541826106607914,
      -0.02634301409125328,
      -0.06306617707014084,
      -0.02553688921034336,
      0.01716354489326477,
      0.05949542298913002,
      0.03535572439432144,
      -0.04122219607234001,
      -0.021422002464532852,
      0.014074099250137806,
      0.03769097849726677,
      -0.047835737466812134,
      -0.06551656872034073,
      0.0571930892765522,
      0.056358564645051956,
      -0.020287571474909782,
      -0.012078878469765186,
      0.016260934993624687,
      -0.0023902698885649443,
      -0.004119939636439085,
      -0.05537660792469978,
      0.01303369551897049,
      -0.01790367253124714,
      -0.088500015437603,
      0.08056356012821198,
      -0.017574502155184746,
      0.05355587974190712,
      0.013416331261396408,
      -0.002711871638894081,
      -0.0012643480440601707,
      -0.0030139910522848368,
      0.01187528483569622,
      -0.0773170068860054,
      0.027664989233016968,
      0.026132293045520782,
      -0.04073416814208031,
      0.036256276071071625,
      -0.04340894892811775,
      -0.016203271225094795,
      0.0360107496380806,
      -0.016390422359108925,
      -0.005892791785299778,
      0.02363688498735428,
      0.10662480443716049,
      0.03961855545639992,
      -0.030395153909921646,
      0.032020192593336105,
      0.021123675629496574,
      0.0781843513250351,
      0.03574042767286301,
      -0.08072041720151901,
      0.006922000087797642,
      -0.02640482224524021,
      0.019888097420334816,
      -0.017360486090183258,
      0.01152330543845892,
      -0.016118248924613,
      0.04646880179643631,
      -0.018801501020789146,
      -0.03743787109851837,
      -0.0004118778451811522,
      0.0030369136948138475,
      0.01129366084933281,
      0.043791305273771286,
      -0.002062689047306776,
      -0.001804929575882852,
      0.0033194792922586203,
      -0.03012593276798725,
      -0.055292341858148575,
      0.0017394782043993473,
      0.066700778901577,
      -0.016138901934027672,
      0.02266562730073929,
      -0.04404620826244354,
      0.03398841619491577,
      -0.08993029594421387,
      -0.07254242897033691,
      0.06661625951528549,
      0.11755742877721786,
      -0.0042589083313941956,
      -0.02238558791577816,
      0.13564661145210266,
      0.04491781070828438,
      -0.06444793194532394,
      -0.08104699850082397,
      -0.07220681011676788,
      0.017482390627264977,
      0.026591291651129723,
      0.0023378629703074694,
      -0.006747971288859844,
      0.019561534747481346,
      -0.037381816655397415,
      0.008351068943738937,
      -0.005822357255965471,
      0.03167792409658432,
      0.041568636894226074,
      -0.006721319165080786,
      0.009575150907039642,
      -0.05459795147180557,
      -0.08927201479673386,
      -0.019773926585912704,
      -0.038408443331718445,
      0.018771430477499962,
      -0.017593730241060257,
      -0.04594931751489639,
      0.05650091543793678,
      -0.07040145248174667,
      0.0023402308579534292,
      -0.04920642450451851,
      0.03191810101270676,
      -0.07866348326206207,
      0.055915117263793945,
      0.09910966455936432,
      -0.029615530744194984,
      0.0241636224091053,
      -0.09403122961521149,
      -0.033882226794958115,
      0.021030355244874954,
      -0.05496411770582199,
      -0.0653916746377945,
      0.006459455471485853,
      -0.009297761134803295,
      -0.06343524903059006,
      0.03651915490627289,
      -0.0012794164940714836,
      -0.005356942303478718,
      0.04217662662267685,
      -0.022930240258574486,
      0.03460844233632088,
      -0.03477146849036217,
      0.0026723407208919525,
      0.04286954551935196,
      0.09354179352521896,
      0.041961364448070526,
      -0.008240806870162487,
      0.04878610372543335,
      -0.011437520384788513,
      -0.033437781035900116,
      -0.04959104582667351,
      -0.009393195621669292,
      -0.053435537964105606,
      0.0083481315523386,
      -0.12995491921901703,
      0.11008740216493607,
      -0.015201205387711525,
      0.03367457911372185,
      0.00385650503449142,
      0.014401782304048538,
      0.0140162268653512,
      -0.04688545688986778,
      -0.10009694844484329,
      0.030877498909831047,
      0.007632765918970108,
      0.10284961760044098,
      -0.04823702573776245,
      -0.002995296847075224,
      -0.02824513614177704,
      -0.09128741174936295,
      0.00890577957034111,
      0.030836360529065132,
      -0.05644669383764267,
      0.08196703344583511,
      0.038180653005838394,
      -0.047652289271354675,
      0.018589533865451813,
      0.019294079393148422,
      0.08616973459720612,
      -0.012761815451085567,
      -0.0007571518654003739,
      -0.032248567789793015,
      -0.022222286090254784,
      -0.06981316208839417,
      0.03661451116204262,
      -0.009783826768398285,
      0.017473667860031128,
      0.08280239254236221,
      0.07755737751722336,
      0.014384009875357151,
      0.029323577880859375,
      0.004854250233620405,
      -0.0031187294516712427,
      -0.0924868956208229,
      -0.012735264375805855,
      -0.007410218007862568,
      -0.03959136828780174,
      -0.011774171143770218,
      0.09274683147668839,
      -0.032797861844301224,
      0.024476513266563416,
      -0.024028969928622246,
      0.00568738579750061,
      0.011280487291514874,
      -0.004357706755399704,
      -0.01596774533390999,
      0.010796379297971725,
      -0.006511948071420193,
      0.010628880001604557,
      0.048386119306087494,
      -0.09498793631792068,
      -0.016613846644759178,
      -0.03260236978530884,
      0.0032365964725613594,
      -0.03922563046216965,
      0.0013448985991999507,
      -0.04967666044831276,
      -0.031095828860998154,
      -0.05436418950557709,
      -0.012565054930746555,
      0.07716341316699982,
      0.06088154390454292,
      0.08177407830953598,
      0.024807119742035866,
      -0.034226104617118835,
      -0.01476514246314764,
      0.014751896262168884,
      -0.012135769240558147,
      -0.0757022276520729,
      0.006115531548857689,
      -0.039229027926921844,
      0.03328249230980873,
      0.0688469186425209,
      0.014785989187657833,
      0.024683693423867226,
      -0.014012686908245087,
      -0.005856126081198454,
      -0.044645700603723526,
      0.04055530205368996,
      0.053475555032491684,
      0.016006452962756157,
      -0.058891333639621735,
      0.07137004286050797,
      -0.001621572533622384,
      -0.009676600806415081,
      -0.15393611788749695,
      -0.12316791713237762,
      -0.03871115297079086,
      0.03760099411010742,
      0.03987928107380867,
      0.041436612606048584,
      0.09949824213981628,
      -0.014925554394721985,
      0.04140875115990639,
      0.0037506455555558205,
      0.01335901953279972,
      -0.021328089758753777,
      -0.10480723530054092,
      -0.032733190804719925,
      0.054818153381347656,
      -0.03313184529542923,
      0.03978629410266876,
      0.07300636172294617,
      0.023144090548157692,
      -0.012069245800375938,
      -0.01719868928194046,
      -0.07452903687953949,
      -0.09679564088582993,
      0.09095849096775055,
      -0.08122151345014572,
      0.06791933625936508,
      0.020125895738601685,
      -0.038023691624403,
      -0.015535959973931313,
      -0.023451615124940872,
      0.03256211802363396,
      -0.00871503446251154,
      -0.06759225577116013,
      0.027240516617894173,
      0.002455072244629264,
      -0.04929102584719658,
      0.03170806169509888,
      -0.02829575538635254,
      0.07624157518148422,
      -0.016869449988007545,
      0.019308678805828094,
      -0.01068179216235876,
      0.004506984259933233,
      -0.010967927053570747,
      -0.013472521677613258,
      -0.07980874180793762,
      0.006124523933976889,
      -0.0016688213218003511,
      -0.01847320981323719,
      0.013760979287326336,
      0.05551683157682419,
      -0.02821536175906658,
      0.010962067171931267,
      0.07915957272052765,
      0.11086529493331909,
      -0.09222418069839478,
      -0.048528771847486496,
      0.08107992261648178,
      0.004191725980490446,
      -0.00682815769687295,
      -0.06155531853437424,
      -0.047773055732250214,
      -0.022030791267752647,
      -0.04409565404057503,
      -0.005121869966387749,
      -0.06929619610309601,
      -0.059832606464624405,
      -0.07428736984729767,
      -0.013330576941370964,
      -0.03892244026064873,
      -0.010992225259542465,
      -0.011550085619091988,
      -0.015354184433817863,
      0.00028079768526367843,
      -0.019111989066004753,
      0.002007469069212675,
      0.04010956361889839,
      -0.011431221850216389,
      -0.06314218789339066,
      -0.054056812077760696,
      -0.07542000710964203,
      -0.0166324432939291,
      0.03360385075211525,
      0.0591982863843441,
      0.011900129728019238,
      -0.0001161294276244007,
      0.0738523006439209,
      0.04306095093488693,
      0.00916762463748455,
      -0.030422555282711983,
      0.0491165965795517,
      -0.025183092802762985,
      0.0296142790466547,
      -0.04444698244333267,
      -0.04853031784296036,
      -0.002533468184992671,
      0.030056029558181763,
      0.07410333305597305,
      -0.014681223779916763,
      -0.002796747488901019,
      -0.12202592194080353,
      -0.015219616703689098,
      -0.03763504698872566,
      0.11005038022994995,
      -0.03828054666519165,
      0.016831591725349426,
      -0.07448165118694305,
      -0.06083443760871887,
      -0.031198114156723022,
      0.04106468707323074,
      -0.0116366446018219,
      0.07685783505439758,
      0.008745400235056877,
      -0.056130532175302505,
      0.02357861027121544,
      -0.06984609365463257,
      0.026742523536086082,
      -0.0444905050098896,
      -0.05352307856082916,
      0.007434961386024952,
      -0.059779733419418335,
      0.04971684515476227,
      -0.022461486980319023,
      -0.0124803576618433,
      -0.030245862901210785,
      -0.02722409926354885,
      0.11290381103754044,
      0.008113628253340721,
      0.058723971247673035,
      -0.08272013813257217,
      0.018596667796373367,
      -0.08108431845903397,
      0.049323081970214844,
      0.03303445130586624,
      -0.030717547982931137,
      -0.010706014931201935,
      -0.014742674306035042,
      0.007202834822237492,
      -0.026602614670991898,
      0.04550548270344734,
      -0.006136762443929911,
      0.04348105937242508,
      -0.008207832463085651,
      0.055436939001083374,
      -0.029130015522241592,
      -0.04672781378030777,
      0.011081742122769356,
      0.10325711965560913,
      0.036392003297805786,
      -0.0790523812174797,
      0.06219157204031944,
      -0.05006404221057892,
      0.014063484035432339,
      -0.0009041053126566112,
      -0.0008879094384610653,
      -0.0520550012588501,
      0.007214103825390339,
      0.07963448017835617,
      -0.022245053201913834,
      -0.002039470011368394,
      0.019667215645313263,
      0.02687239646911621,
      -0.053057823330163956,
      -0.02049887366592884,
      0.008913157507777214,
      -0.04819150269031525,
      0.12733660638332367,
      0.07047303766012192,
      0.04405513405799866,
      0.07145020365715027,
      -0.034998711198568344,
      0.009306995198130608,
      -0.06296601891517639,
      0.03181891143321991,
      0.0022942540235817432,
      0.029521239921450615,
      0.036045853048563004,
      -0.03533881902694702,
      -0.0013075129827484488,
      0.03638619929552078,
      -0.03651389479637146,
      -0.058257803320884705,
      -0.050739556550979614,
      -0.03138543292880058,
      -0.05991487577557564,
      0.034270983189344406,
      0.037899330258369446,
      -0.06344330310821533,
      -0.04490760713815689,
      0.001815647236071527,
      -0.003942543640732765,
      -0.020825447514653206,
      0.01879418082535267,
      -0.03257865831255913,
      0.09585609287023544,
      -0.06641002744436264,
      -0.07548905164003372,
      0.06715946644544601,
      -0.0007493928424082696,
      -0.031535662710666656,
      -0.07247453182935715,
      -0.0005763467052020133,
      -0.08983884751796722,
      0.005337296519428492,
      0.049582257866859436,
      -0.03441993147134781,
      -0.00839995313435793,
      0.05690200999379158,
      -0.007412104867398739,
      -0.03311866894364357,
      -0.06928713619709015,
      -0.054842688143253326,
      -0.04739053547382355
    ],
    [
      -0.10078267008066177,
      -0.011767040938138962,
      -0.0004675367963500321,
      -0.01642054319381714,
      0.05696532875299454,
      0.011437403969466686,
      0.016890116035938263,
      0.0008209282532334328,
      0.09070053696632385,
      0.0093086501583457,
      0.033622920513153076,
      -0.07174079120159149,
      -0.07449020445346832,
      -0.02218281850218773,
      -0.0731261670589447,
      0.019376322627067566,
      0.0228493083268404,
      -0.04183577373623848,
      0.012445072643458843,
      0.06177382171154022,
      0.030325427651405334,
      0.02972322702407837,
      -0.015564600005745888,
      -0.0017238120781257749,
      -0.028806522488594055,
      -0.00814992468804121,
      0.10341452062129974,
      0.06153930351138115,
      -0.02523658238351345,
      0.07444225996732712,
      0.0208051186054945,
      -0.05730587616562843,
      0.02372884750366211,
      0.06443274766206741,
      -0.015495819970965385,
      0.02841905690729618,
      0.0016491085989400744,
      -0.10079408437013626,
      0.04979300498962402,
      -0.040007591247558594,
      -0.09836000204086304,
      -0.07176101207733154,
      0.025495631620287895,
      -0.017568666487932205,
      -0.02900749444961548,
      -0.07141558080911636,
      0.062217827886343,
      -0.08432365953922272,
      0.010343506932258606,
      0.040998391807079315,
      0.01931714080274105,
      -0.009643378667533398,
      -0.03235223889350891,
      0.058252036571502686,
      -0.06620515882968903,
      -0.013279357925057411,
      -0.027235157787799835,
      -0.0044302125461399555,
      -0.041348110884428024,
      0.0382913313806057,
      -0.020435819402337074,
      0.020108863711357117,
      -0.021228084340691566,
      0.00553501769900322,
      0.03910376504063606,
      -0.04839644953608513,
      0.05719662830233574,
      -0.0158228762447834,
      -0.07459232211112976,
      0.08872579038143158,
      0.02583669312298298,
      -0.06601331382989883,
      0.04255055636167526,
      0.009678741917014122,
      0.04785730689764023,
      -0.05733727291226387,
      -0.008731123059988022,
      0.03453073650598526,
      -0.03310076519846916,
      -0.09246797859668732,
      -0.02149663120508194,
      -0.040063340216875076,
      -0.03147624060511589,
      0.021169954910874367,
      -0.03987288475036621,
      -0.11749273538589478,
      -0.006180340424180031,
      -0.04862917214632034,
      -0.07841828465461731,
      -0.01542405504733324,
      -0.046676527708768845,
      -0.04228276386857033,
      -0.01436510868370533,
      0.05575492978096008,
      -0.07303506880998611,
      -0.05414138734340668,
      -0.0250933188945055,
      -0.011961439624428749,
      -0.03494906798005104,
      -0.004549157340079546,
      0.011195773258805275,
      -0.020916471257805824,
      0.02716754376888275,
      0.027637924998998642,
      0.10039916634559631,
      0.04201258718967438,
      0.009128499776124954,
      -0.04516077786684036,
      -0.06216353923082352,
      0.027808550745248795,
      -0.06027529016137123,
      -0.06840305030345917,
      -0.012336472980678082,
      0.013165244832634926,
      0.00025336697581224144,
      -0.06985105574131012,
      -0.03802002593874931,
      -0.0539749450981617,
      0.09434401988983154,
      -0.004596529994159937,
      0.030123218894004822,
      0.0465136282145977,
      -0.009498689323663712,
      0.01791471429169178,
      -0.029517654329538345,
      0.017984185367822647,
      -0.025428200140595436,
      0.06652560085058212,
      0.032962389290332794,
      -0.010363822802901268,
      -0.014837044291198254,
      -0.03486265614628792,
      0.07729604095220566,
      -0.0540459007024765,
      0.027213726192712784,
      0.09472520649433136,
      0.02635682001709938,
      -0.05621591955423355,
      0.0018999966559931636,
      0.03440728783607483,
      -0.08417533338069916,
      0.07660254091024399,
      -0.0289382915943861,
      -0.032720860093832016,
      0.08821294456720352,
      -0.12230850756168365,
      0.04180915281176567,
      -0.10490985959768295,
      0.004041429143399,
      -0.0014758881879970431,
      -0.07304885238409042,
      -0.02848975919187069,
      0.02487274631857872,
      0.06089545786380768,
      0.02624659240245819,
      0.0013605011627078056,
      0.0230155847966671,
      0.020347807556390762,
      0.09184277057647705,
      0.04517960920929909,
      -0.004321429878473282,
      -0.0015594987198710442,
      0.04769383370876312,
      0.008916393853724003,
      -0.0393800288438797,
      0.011909553781151772,
      -0.04374722018837929,
      -0.032793689519166946,
      0.032669853419065475,
      -0.04691699892282486,
      0.016637105494737625,
      -0.04408220574259758,
      0.05131649598479271,
      -0.015014736913144588,
      -0.04864126443862915,
      0.07051783055067062,
      -0.056071165949106216,
      0.04946530982851982,
      0.0063279797323048115,
      0.01847544126212597,
      -0.06396277993917465,
      0.01097266934812069,
      0.019316313788294792,
      0.025335248559713364,
      0.008063621819019318,
      0.10986117273569107,
      -0.03588947281241417,
      0.023869354277849197,
      -0.03423277288675308,
      -0.009510200470685959,
      -0.015571155585348606,
      0.05426971986889839,
      0.021379008889198303,
      -0.03525955602526665,
      0.02400769479572773,
      0.0007553584291599691,
      0.003311856184154749,
      0.006846855394542217,
      -0.017833638936281204,
      -0.011587490327656269,
      -0.0009942412143573165,
      0.029447821900248528,
      -0.0018610259285196662,
      0.10977080464363098,
      0.02678169123828411,
      0.016502363607287407,
      0.014226142317056656,
      -0.0761667788028717,
      -0.02208491787314415,
      0.02331141196191311,
      0.09878203272819519,
      -0.029307622462511063,
      0.018845798447728157,
      0.0812697559595108,
      -0.010276151821017265,
      0.021407432854175568,
      -0.008706930093467236,
      -0.030456816777586937,
      0.010115031152963638,
      -0.01686539314687252,
      -0.034550927579402924,
      -0.12510697543621063,
      -0.016343722119927406,
      0.045883700251579285,
      -0.016802622005343437,
      0.03639886528253555,
      0.017676839604973793,
      -0.017446693032979965,
      0.0027032860089093447,
      0.029486116021871567,
      -0.005967383738607168,
      -0.03230008855462074,
      0.07120003551244736,
      0.05929696559906006,
      -0.039411257952451706,
      -0.06299474835395813,
      0.028657158836722374,
      0.0004927613772451878,
      -0.0034053893759846687,
      0.0023381453938782215,
      -0.07682373374700546,
      0.04462635889649391,
      -0.042617570608854294,
      -0.04179349169135094,
      0.03578251227736473,
      -0.031624309718608856,
      -0.005179990082979202,
      0.00998779945075512,
      0.04638557508587837,
      -0.013315844349563122,
      0.01750919409096241,
      0.040909137576818466,
      0.006110194139182568,
      0.01167116966098547,
      0.014124690555036068,
      -0.016956115141510963,
      -0.00102721038274467,
      -0.13680173456668854,
      -0.001993540907278657,
      0.04136819764971733,
      0.006943991873413324,
      -0.02381751500070095,
      -0.025009579956531525,
      -0.0014226310886442661,
      0.0445590578019619,
      0.01001517940312624,
      0.0321979857981205,
      -0.08170769363641739,
      0.014036952517926693,
      -0.057299185544252396,
      -0.028771350160241127,
      0.05231064185500145,
      0.01606466807425022,
      -0.031760718673467636,
      -0.0453057698905468,
      0.07832282036542892,
      0.027609767392277718,
      0.07043585926294327,
      0.07509072870016098,
      0.03862869367003441,
      -0.028553716838359833,
      0.01687445305287838,
      -0.03676630184054375,
      -0.04673944041132927,
      -0.027183808386325836,
      -0.03046335279941559,
      0.004998619202524424,
      0.03250642120838165,
      -0.051628220826387405,
      -0.033780161291360855,
      -0.059874966740608215,
      -0.015705008059740067,
      -0.0322883315384388,
      0.007933673448860645,
      -0.023821808397769928,
      0.0641477033495903,
      0.03553534671664238,
      0.015870248898863792,
      0.06234224513173103,
      0.05398078262805939,
      0.003171660704538226,
      0.07185850292444229,
      -0.00012253645400051028,
      0.03571751341223717,
      0.10607735812664032,
      0.0630037859082222,
      0.037291597574949265,
      -0.018750697374343872,
      -0.0027839764952659607,
      0.024048682302236557,
      -0.04564670845866203,
      0.07282532751560211,
      0.016260797157883644,
      -0.02770589478313923,
      0.03712383657693863,
      0.023358432576060295,
      0.03873284533619881,
      -0.05609903484582901,
      -0.025830160826444626,
      -0.011842617765069008,
      0.02784365974366665,
      0.09207585453987122,
      -0.009563211351633072,
      0.07842209935188293,
      -0.0056228660978376865,
      0.01777232252061367,
      0.022742247208952904,
      0.007388068828731775,
      0.03265199065208435,
      -0.08157471567392349,
      0.1216224730014801,
      0.03755511716008186,
      0.010686869733035564,
      0.0006991659756749868,
      0.027714945375919342,
      0.01471575815230608,
      -0.028530340641736984,
      -0.0474398136138916,
      0.048644863069057465,
      0.0547744445502758,
      0.035554613918066025,
      -0.04336351528763771,
      -0.045893192291259766,
      -0.0359121635556221,
      -0.004012866877019405,
      0.061920374631881714,
      0.034534670412540436,
      0.06754307448863983,
      -0.10027848929166794,
      -0.07720104604959488,
      -0.012038792483508587,
      0.0530848428606987,
      0.09559743106365204,
      -0.04494790732860565,
      -0.0005315998569130898,
      0.05045276880264282,
      0.012218196876347065,
      0.05233140289783478,
      -0.08452875167131424,
      0.0205300934612751,
      -0.007863848470151424,
      0.014004462398588657,
      0.041679851710796356,
      0.056190043687820435,
      0.003051414620131254,
      0.047015007585287094,
      0.04112986475229263,
      0.02219306118786335,
      0.012186309322714806,
      -0.020182538777589798,
      0.01359520759433508,
      0.018696360290050507,
      0.07205532491207123,
      -0.060457903891801834,
      -0.028484206646680832,
      -0.04269479960203171,
      0.05970650911331177,
      0.05262831225991249,
      0.006017209030687809,
      0.053235478699207306,
      -0.01899530552327633,
      -0.021797770634293556,
      0.0027101910673081875,
      -0.06578227132558823,
      -0.013710351660847664,
      0.0178447887301445,
      -0.010920428670942783,
      -0.006578532978892326,
      0.060887522995471954,
      0.06737321615219116,
      -0.04849935322999954,
      -0.09657443314790726,
      0.010333208367228508,
      0.06021435931324959,
      -0.02213132008910179,
      -0.040574025362730026,
      0.016391988843679428,
      0.03462338447570801,
      0.05197630077600479,
      0.02093878947198391,
      -0.05339589715003967,
      -0.015088062733411789,
      0.00649710139259696,
      0.02715989761054516,
      0.09733293205499649,
      0.05589473620057106,
      0.015616391785442829,
      -0.025494178757071495,
      0.03368695452809334,
      0.0706680417060852,
      -0.016066431999206543,
      0.0013011855771765113,
      0.0021527751814574003,
      0.02484414167702198,
      -0.027692383155226707,
      -0.04155130311846733,
      0.03559340164065361,
      -0.049522507935762405,
      -0.06242470070719719,
      -0.0018086349591612816,
      -0.05310974270105362,
      -0.02999754250049591,
      -0.0064035626128315926,
      -0.004541232716292143,
      -0.07874822616577148,
      0.02100166119635105,
      0.024499062448740005,
      0.03089374303817749,
      0.059892669320106506,
      0.007554047740995884,
      -0.014543414115905762,
      -0.0559825599193573,
      0.012075250968337059,
      0.026708507910370827,
      -0.052999451756477356,
      0.02355915494263172,
      0.05176630988717079,
      -0.07410524040460587,
      0.012947454117238522,
      -0.0027682792861014605,
      0.04046391323208809,
      0.07996354252099991,
      0.0011431046295911074,
      -0.017255114391446114,
      -0.021961232647299767,
      -0.055351682007312775,
      -0.023291490972042084,
      0.06372188776731491,
      0.013832318596541882,
      -0.01490398682653904,
      -0.015971271321177483,
      0.001437627011910081,
      0.01920996978878975,
      0.037047095596790314,
      -0.005818015430122614,
      -0.09256172925233841,
      -0.009836685843765736,
      0.027570895850658417,
      -0.012885051779448986,
      -0.004932894837111235,
      0.09578073769807816,
      -0.0020523113198578358,
      -0.044041018933057785,
      -0.027646316215395927,
      -0.03991062566637993,
      0.01213156245648861,
      -0.04872358962893486,
      -0.06519658863544464,
      -0.03965006768703461,
      0.015740185976028442,
      0.03920046612620354,
      -0.01952267996966839,
      -0.039158936589956284,
      -0.08123337477445602,
      0.010578109882771969,
      0.005695647094398737,
      -0.13245998322963715,
      0.0028684160206466913,
      0.09041951596736908,
      0.08823507279157639,
      -0.011169392615556717,
      0.0661168098449707,
      -0.04579504206776619,
      0.017849454656243324,
      0.01351976953446865,
      -0.10358374565839767,
      0.014213638380169868,
      -0.11036685109138489,
      -0.010859454050660133,
      0.041402097791433334,
      0.018402524292469025,
      0.00020815803145524114,
      -0.04451941326260567,
      0.03526774048805237,
      0.007835284806787968,
      -0.04873836413025856,
      -0.021868279203772545,
      0.04904567822813988,
      0.03481749817728996,
      0.04513470083475113,
      0.014431975781917572,
      0.005909743718802929,
      -0.01042364351451397
    ],
    [
      -0.025630353018641472,
      0.041844144463539124,
      0.011615552939474583,
      -0.09072291105985641,
      0.06398456543684006,
      -0.06114097312092781,
      0.06979541480541229,
      -0.010729939676821232,
      -0.03260108456015587,
      0.038544293493032455,
      -0.05384454503655434,
      -0.01975920796394348,
      -0.040090978145599365,
      0.011742758564651012,
      0.004201457370072603,
      0.06825555860996246,
      0.007754528895020485,
      -0.030249914154410362,
      0.005880984477698803,
      0.01917998678982258,
      0.08303515613079071,
      0.1255321353673935,
      0.05486888810992241,
      0.03326040506362915,
      0.026652587577700615,
      0.0071744476445019245,
      -0.0481945239007473,
      -0.013769067823886871,
      0.07415580004453659,
      -0.000799178087618202,
      -0.005549402441829443,
      -0.0007982514798641205,
      -0.013230091892182827,
      0.007754898630082607,
      -0.0582263208925724,
      0.009536064229905605,
      -0.022483525797724724,
      -0.009870721027255058,
      0.03805730491876602,
      0.05036327987909317,
      0.02277742326259613,
      0.02735886164009571,
      -0.08186981827020645,
      0.01057547889649868,
      0.04700415953993797,
      -0.04121113568544388,
      -0.029733439907431602,
      -0.002855404280126095,
      -0.014085838571190834,
      0.01869664527475834,
      0.11365670710802078,
      -0.02283378131687641,
      -0.023784827440977097,
      0.015277156606316566,
      -0.010895454324781895,
      0.009147586300969124,
      -0.06736268103122711,
      -0.0619109570980072,
      -0.0819217786192894,
      0.018237611278891563,
      -0.06425350159406662,
      0.04763486236333847,
      0.04084974154829979,
      0.024330073967576027,
      -0.019275259226560593,
      -0.0030465645249933004,
      -0.053793758153915405,
      0.0344824343919754,
      0.07299982011318207,
      0.028858941048383713,
      -0.03224656358361244,
      0.03548397123813629,
      0.0028560820501297712,
      0.021162133663892746,
      -0.07354793697595596,
      0.002543033566325903,
      0.01694064401090145,
      -0.03664839267730713,
      -0.02104259468615055,
      -0.07709145545959473,
      -0.15410570800304413,
      0.025207724422216415,
      0.0032012572046369314,
      -0.010635759681463242,
      -0.06766980141401291,
      0.014822599478065968,
      0.02280375175178051,
      -0.005090225953608751,
      -0.008105800487101078,
      -0.028041888028383255,
      -0.0072109634056687355,
      -0.038495879620313644,
      0.013360240496695042,
      -0.00953712873160839,
      -0.09132157266139984,
      0.0418303981423378,
      -0.03962772339582443,
      0.061469223350286484,
      -0.0006891508819535375,
      0.028403358533978462,
      0.05314702168107033,
      -0.1036321148276329,
      -0.06275542080402374,
      0.049477674067020416,
      0.004320882726460695,
      0.00880428310483694,
      -0.02686231955885887,
      -0.03699615225195885,
      0.024688618257641792,
      -0.0025728479959070683,
      0.002097892574965954,
      -0.03258472681045532,
      -0.05917580798268318,
      0.0499458909034729,
      0.019737590104341507,
      -0.09506916999816895,
      -0.01382896676659584,
      -0.06718558073043823,
      0.007357192225754261,
      -0.005028211046010256,
      0.05261644721031189,
      0.009639243595302105,
      -0.028443293645977974,
      0.024078670889139175,
      -0.06042829900979996,
      0.04622957855463028,
      -0.07259434461593628,
      0.05000096559524536,
      -0.051947880536317825,
      0.05234110355377197,
      -0.030132990330457687,
      -0.008907520212233067,
      -0.03353109210729599,
      0.036344241350889206,
      0.09551139920949936,
      0.09392210841178894,
      0.026194782927632332,
      -0.0354016050696373,
      0.02311140112578869,
      -0.04166874289512634,
      0.03786971792578697,
      0.018123647198081017,
      -0.008433341979980469,
      -0.008522965013980865,
      -0.029780400916934013,
      -0.03442642465233803,
      -0.036196526139974594,
      -0.013124105520546436,
      0.029371920973062515,
      -0.02456108294427395,
      -0.05350421741604805,
      0.10485506802797318,
      0.0031386702321469784,
      0.06988555192947388,
      -0.020168717950582504,
      0.05359361693263054,
      0.07694275677204132,
      0.01865415647625923,
      -0.014060002751648426,
      -0.0678897574543953,
      0.07950971275568008,
      -0.007616719231009483,
      -0.029074139893054962,
      0.07957680523395538,
      0.018096184358000755,
      -0.06612016260623932,
      0.055204566568136215,
      0.012544376775622368,
      -0.03524300456047058,
      0.04750990495085716,
      0.007214828860014677,
      0.0389043428003788,
      0.05631820484995842,
      -0.05035798251628876,
      0.06546198576688766,
      -0.011275370605289936,
      -0.05467992275953293,
      -0.0026549645699560642,
      0.0032252951059490442,
      -0.07204204052686691,
      0.0030866293236613274,
      0.07400093972682953,
      0.03790847584605217,
      0.04934173449873924,
      -0.06211719289422035,
      -0.04149774834513664,
      -0.0026125172153115273,
      -0.05374458059668541,
      -0.12459824979305267,
      0.005090265069156885,
      0.09699808806180954,
      -0.08240076154470444,
      -0.004720579367130995,
      -0.05944785848259926,
      -0.03291647508740425,
      -0.015935353934764862,
      0.003429470583796501,
      -0.025627724826335907,
      0.04015370458364487,
      0.01444198377430439,
      0.0734141618013382,
      0.04051576554775238,
      -0.09750790148973465,
      0.0417989082634449,
      -0.029533999040722847,
      -0.005083916243165731,
      0.07506579905748367,
      -0.028660956770181656,
      0.00818636640906334,
      -0.0017012684838846326,
      -0.07524854689836502,
      0.0010155660565942526,
      0.04002288356423378,
      -0.05163286626338959,
      -0.07617213577032089,
      0.04182156175374985,
      -0.014523912221193314,
      0.06668610870838165,
      -0.010118266567587852,
      0.03615398332476616,
      -0.05561724677681923,
      -0.028635183349251747,
      0.03144995495676994,
      -0.02155805565416813,
      0.013237537816166878,
      0.0343601293861866,
      -0.0834718719124794,
      -0.009832742623984814,
      0.10328899323940277,
      -0.04770662263035774,
      0.019275793805718422,
      0.019464628770947456,
      0.04056927189230919,
      0.03614012897014618,
      -0.01891288347542286,
      0.02777794376015663,
      0.029419150203466415,
      -0.01764574460685253,
      -0.04596806690096855,
      0.015435808338224888,
      0.07125138491392136,
      -0.061498913913965225,
      -0.007328354753553867,
      0.0227044727653265,
      0.012056760489940643,
      -0.04540008306503296,
      -0.017584754154086113,
      -0.008958816528320312,
      0.003451356664299965,
      -0.06449688225984573,
      -0.04982970282435417,
      0.0015866098692640662,
      0.00013369722000788897,
      0.04633669555187225,
      0.018682682886719704,
      0.015718255192041397,
      -0.07308299839496613,
      -0.028978200629353523,
      0.011252020485699177,
      -0.0021826273296028376,
      -0.03966739773750305,
      -0.01799081824719906,
      0.00022391120728570968,
      0.08448215574026108,
      -0.10745523124933243,
      -0.00041764703928492963,
      0.04839693009853363,
      -0.001832017325796187,
      -0.026925893500447273,
      0.009645825251936913,
      -0.05508332699537277,
      0.07925498485565186,
      -0.006017439998686314,
      -0.009007503278553486,
      0.0009660068317316473,
      0.013640236109495163,
      0.0009948795195668936,
      -0.06514698266983032,
      0.03136453777551651,
      -0.05160515755414963,
      0.10041536390781403,
      -0.04163942113518715,
      0.06969694048166275,
      0.05008712410926819,
      0.017826687544584274,
      -0.06769601255655289,
      -0.025797411799430847,
      0.04622269794344902,
      -0.05108127370476723,
      -0.009195649065077305,
      -0.013232500292360783,
      -0.0530165359377861,
      -0.019228003919124603,
      -0.00064086850034073,
      0.03667237237095833,
      -0.011527646332979202,
      0.06364912539720535,
      -0.03732789307832718,
      -0.004332886543124914,
      0.03064066544175148,
      -0.013380809687077999,
      0.018568774685263634,
      -0.0864500179886818,
      -0.07827922701835632,
      -0.01584535650908947,
      0.018418747931718826,
      0.011871591210365295,
      0.005006724968552589,
      0.03858724236488342,
      0.040577325969934464,
      0.0850057527422905,
      0.004125654231756926,
      0.022432083263993263,
      -0.006606205366551876,
      -0.008616145700216293,
      0.008743856102228165,
      -0.016066815704107285,
      -0.055331625044345856,
      0.05522504821419716,
      -0.06673527508974075,
      0.004387334454804659,
      -0.0492670051753521,
      -0.030793258920311928,
      0.0021222790237516165,
      0.023378882557153702,
      -0.018148014321923256,
      0.0609152726829052,
      0.03517467528581619,
      0.004429199732840061,
      -0.018380992114543915,
      -0.11470136046409607,
      -0.003677341155707836,
      -0.030258774757385254,
      -0.027918316423892975,
      0.028535781428217888,
      -0.05766676738858223,
      -0.013854775577783585,
      0.0228786151856184,
      0.07435604184865952,
      -0.020840682089328766,
      0.010341482236981392,
      0.02291026897728443,
      0.03105795569717884,
      -0.007206934504210949,
      -0.0130615234375,
      0.023574281483888626,
      0.03666514530777931,
      -0.06515718996524811,
      -0.042924899607896805,
      -0.008440006524324417,
      0.038212813436985016,
      -0.004514158703386784,
      0.05096098780632019,
      -0.03511817380785942,
      0.03158213570713997,
      0.02569342590868473,
      0.02139594592154026,
      0.038808804005384445,
      -0.09163983911275864,
      0.006133780814707279,
      0.06890819221735,
      0.013810385018587112,
      -0.045277390629053116,
      0.06820610910654068,
      -0.019424648955464363,
      0.043253690004348755,
      -0.006399062462151051,
      -0.03288504108786583,
      0.026110686361789703,
      -0.037730325013399124,
      -0.000840645341668278,
      -0.024382593110203743,
      -0.04548461362719536,
      -0.02178252302110195,
      -0.05983128026127815,
      0.059653140604496,
      -0.01652045175433159,
      0.016988053917884827,
      -0.043621551245450974,
      -0.05805959925055504,
      0.0021685324609279633,
      0.01926257647573948,
      -0.0005225021159276366,
      -0.04282527416944504,
      -0.010733041912317276,
      0.013668179512023926,
      0.025807194411754608,
      -0.025955388322472572,
      -0.04610521346330643,
      0.0009335508802905679,
      0.007744846865534782,
      -0.031956322491168976,
      -0.06012265756726265,
      0.07268483191728592,
      0.023361269384622574,
      0.022849474102258682,
      -0.04864044114947319,
      0.059464890509843826,
      0.03030022792518139,
      -0.005007512867450714,
      -0.04545611888170242,
      -0.08142822980880737,
      0.053167615085840225,
      -0.01856081187725067,
      -0.01852361299097538,
      0.060485534369945526,
      -0.011703399010002613,
      -0.030710946768522263,
      0.05645361915230751,
      0.07211563736200333,
      0.022589560598134995,
      0.011389012448489666,
      0.003487981390208006,
      -0.003312507411465049,
      0.09043429046869278,
      -0.039654336869716644,
      0.01877329871058464,
      0.031602345407009125,
      0.021833661943674088,
      0.01569836586713791,
      0.059228576719760895,
      -0.030079001560807228,
      -0.004887031856924295,
      0.016744229942560196,
      0.014266764745116234,
      0.02807719074189663,
      -0.031817685812711716,
      0.0281665176153183,
      -0.06725335121154785,
      0.002988202730193734,
      -0.02657250128686428,
      -0.010394829325377941,
      -0.0014313658466562629,
      0.06471182405948639,
      -0.042469557374715805,
      -0.03346856310963631,
      0.09709767252206802,
      -0.042798422276973724,
      0.012504109181463718,
      0.024896841496229172,
      -0.01846146769821644,
      0.008596574887633324,
      0.04590504989027977,
      -0.03235136345028877,
      0.027277948334813118,
      0.0067484718747437,
      -0.020120572298765182,
      -0.05828198418021202,
      -0.005109370220452547,
      -0.05996666103601456,
      0.021403329446911812,
      0.08406645804643631,
      0.03818414732813835,
      0.035221751779317856,
      0.015701938420534134,
      -0.07969087362289429,
      0.01681489311158657,
      0.05676470324397087,
      0.07612083107233047,
      0.0021863405127078295,
      0.0961337611079216,
      -0.039907705038785934,
      0.03220853582024574,
      -0.11030542850494385,
      -0.03157057985663414,
      -0.03444833680987358,
      -0.044062480330467224,
      -0.016508454456925392,
      -0.030936473980545998,
      0.09840183705091476,
      0.05073113366961479,
      0.07734118402004242,
      0.022442851215600967,
      0.06731952726840973,
      -0.05252911522984505,
      -0.03827282041311264,
      -0.0027532607782632113,
      -0.032556354999542236,
      0.006292385049164295,
      0.046162258833646774,
      -0.036544300615787506,
      -0.027498072013258934,
      -0.028648657724261284,
      0.04941169545054436,
      0.04000486433506012,
      0.03817891329526901,
      -0.0035915749613195658,
      -0.0076483068987727165,
      -0.026212554425001144,
      0.013408397324383259,
      -0.0009899112628772855,
      -0.0037536185700446367,
      -0.031256332993507385,
      -0.02990395948290825,
      0.021774668246507645,
      -0.0026403595693409443,
      0.00678915623575449,
      -0.040928203612565994,
      -0.09812245517969131,
      -0.02192770503461361,
      0.04496903717517853,
      0.002198419300839305,
      -0.006533390376716852
    ],
    [
      0.002111065899953246,
      0.02784307673573494,
      -0.06170881912112236,
      -0.013159524649381638,
      0.09524872153997421,
      0.056145500391721725,
      -0.004904737696051598,
      -0.018375659361481667,
      -0.010555362328886986,
      0.07146551460027695,
      0.04689949005842209,
      -0.08475645631551743,
      -0.010364840738475323,
      -0.011335724033415318,
      0.026043519377708435,
      0.06749489158391953,
      -0.02645992673933506,
      -0.032048482447862625,
      0.07594490051269531,
      -0.032727040350437164,
      -0.09637302160263062,
      -0.01893637143075466,
      -0.002864518901333213,
      0.08612877130508423,
      0.051142822951078415,
      0.03515606001019478,
      0.05535893142223358,
      0.0886722207069397,
      0.007143219001591206,
      0.0036118144635111094,
      -0.02712474763393402,
      0.04315605014562607,
      -0.02986351028084755,
      -0.010848323814570904,
      -0.04711088165640831,
      -0.052931077778339386,
      0.10056161880493164,
      0.02905951626598835,
      -0.055332738906145096,
      0.006279688328504562,
      0.014401189051568508,
      -0.10888662934303284,
      -0.07130317389965057,
      -0.0210211593657732,
      -0.07704497873783112,
      0.0376630537211895,
      -0.004766209051012993,
      0.07734764367341995,
      0.009257792495191097,
      0.013592611066997051,
      0.06552834808826447,
      -0.042749520391225815,
      0.036873698234558105,
      0.00711348420009017,
      0.013543225824832916,
      -0.08455058932304382,
      -0.03246611729264259,
      0.09170018136501312,
      -0.051225725561380386,
      -0.03151293471455574,
      0.004764525219798088,
      0.05323897302150726,
      0.04551340267062187,
      -0.009080425836145878,
      -0.01457001082599163,
      0.01095651090145111,
      0.06275208294391632,
      0.008738351985812187,
      0.016813479363918304,
      -0.009382779709994793,
      0.09827577322721481,
      0.03221169486641884,
      -0.02922564558684826,
      0.04785991460084915,
      0.05538607016205788,
      0.002280796179547906,
      0.03923257440328598,
      -0.03735143691301346,
      -0.014715095981955528,
      0.006710698362439871,
      -0.0011699821334332228,
      0.007719846908003092,
      0.02016369253396988,
      0.021451421082019806,
      -0.003507588990032673,
      -0.02538769319653511,
      -0.021654848009347916,
      -0.027184201404452324,
      0.033024340867996216,
      0.005556382238864899,
      0.010652009397745132,
      -0.01581629365682602,
      -0.0036375808995217085,
      0.004028883762657642,
      -0.05640258267521858,
      0.088771291077137,
      0.02757388912141323,
      0.007010098081082106,
      0.0012037871638312936,
      0.02196730114519596,
      0.0482790432870388,
      -0.03725733980536461,
      -0.015454048290848732,
      -0.0266842283308506,
      0.03859766200184822,
      0.054367609322071075,
      -0.06895569711923599,
      -0.06156357377767563,
      -0.055649083107709885,
      -0.018075376749038696,
      -0.0774809792637825,
      0.043701834976673126,
      0.0668739303946495,
      0.014741769060492516,
      0.0037860977463424206,
      -0.037200067192316055,
      0.051818445324897766,
      -0.0277912225574255,
      -0.01120603084564209,
      -0.04029034450650215,
      -0.02268974669277668,
      0.02507699467241764,
      -0.008718959987163544,
      0.0158501286059618,
      -0.12832850217819214,
      0.04754770174622536,
      0.0433230958878994,
      0.06868117302656174,
      -0.057751186192035675,
      -0.03932756185531616,
      0.002751924330368638,
      0.04432004690170288,
      0.007025391794741154,
      0.01724243350327015,
      0.00930456817150116,
      0.08142741769552231,
      -0.010621428489685059,
      -0.0017308740643784404,
      -0.007143784314393997,
      0.014060016721487045,
      0.07843096554279327,
      -0.04180695861577988,
      -0.04862521216273308,
      -0.011475511826574802,
      0.09586142748594284,
      -0.022115161642432213,
      -0.06352686136960983,
      0.0531792975962162,
      0.0003517098957672715,
      0.03383232280611992,
      -0.0523185059428215,
      0.020427994430065155,
      -0.06898233294487,
      0.07589699327945709,
      0.02488313429057598,
      0.026073021814227104,
      -0.00882838387042284,
      0.026723206043243408,
      0.08395746350288391,
      -0.021601520478725433,
      0.0762903094291687,
      -0.04612771049141884,
      0.015852775424718857,
      0.057553377002477646,
      -0.040180955082178116,
      -0.07703251391649246,
      -0.016673166304826736,
      0.05845053866505623,
      -0.051493167877197266,
      -0.016869356855750084,
      0.02401149645447731,
      -0.01602744683623314,
      0.06466387212276459,
      -0.06749311834573746,
      0.013363759033381939,
      0.011542837135493755,
      -0.0019338378915563226,
      0.023332079872488976,
      -0.0267794206738472,
      0.09940205514431,
      -0.02390807308256626,
      0.046456482261419296,
      -0.004214813467115164,
      0.046205706894397736,
      -0.024879466742277145,
      -0.05023469030857086,
      -0.005178067833185196,
      -0.06263791769742966,
      0.015172943472862244,
      -0.07021323591470718,
      -0.032798416912555695,
      0.006359833758324385,
      0.014947056770324707,
      -0.02000076323747635,
      0.06384993344545364,
      0.035264983773231506,
      0.02631043829023838,
      -0.02642303705215454,
      -0.02882361225783825,
      -0.05628456920385361,
      0.02254953607916832,
      0.007549804635345936,
      0.027936596423387527,
      -0.0036513004451990128,
      -0.023655584082007408,
      -0.04216170310974121,
      -0.009143763221800327,
      -0.003597656264901161,
      0.019098971039056778,
      -0.01378505490720272,
      -0.056528061628341675,
      0.06221838667988777,
      0.08472923189401627,
      0.02083040028810501,
      -0.058871883898973465,
      -0.006447071675211191,
      0.03339638561010361,
      0.028273731470108032,
      0.0024927102494984865,
      0.04874931648373604,
      0.00329672172665596,
      -0.042524706572294235,
      -0.0056965164840221405,
      0.029291976243257523,
      0.000624052423518151,
      -0.00031986957765184343,
      -0.0014276060974225402,
      -0.09761390089988708,
      -0.020642029121518135,
      0.036600951105356216,
      0.06700439751148224,
      -0.0429701991379261,
      -0.058805786073207855,
      0.060637686401605606,
      -0.010727541521191597,
      -0.05228636413812637,
      -0.04057806730270386,
      0.08946854621171951,
      0.07274165004491806,
      -0.02614901214838028,
      -0.03204345703125,
      0.02166317217051983,
      0.0093260258436203,
      -0.03935587406158447,
      -0.07546642422676086,
      -0.03784479573369026,
      -0.031228819862008095,
      -0.00817592442035675,
      -0.021296827122569084,
      -0.0711638405919075,
      0.020200571045279503,
      0.04050202667713165,
      0.016664931550621986,
      -0.058973390609025955,
      -0.05574089661240578,
      -0.006885992828756571,
      -0.019016293808817863,
      -0.05099781975150108,
      0.0021229099947959185,
      -0.006854620762169361,
      0.002081584418192506,
      0.05937553569674492,
      -0.02186577022075653,
      -0.029549289494752884,
      0.04080439358949661,
      -0.06733874976634979,
      -0.018109211698174477,
      0.0892590656876564,
      -0.05515183508396149,
      0.05810672789812088,
      -0.041524313390254974,
      0.07369031757116318,
      -0.003916264045983553,
      0.016745829954743385,
      0.008363358676433563,
      0.0761839896440506,
      -0.01184625644236803,
      -0.02068476565182209,
      0.09078417718410492,
      -0.03368335962295532,
      0.009540379978716373,
      0.005264030769467354,
      0.05835232511162758,
      -0.005013053305447102,
      -0.021231485530734062,
      -0.019652018323540688,
      0.06280235946178436,
      0.06289976835250854,
      -0.05671093612909317,
      0.053194310516119,
      0.003340350231155753,
      -0.02825784683227539,
      -0.06613599509000778,
      -0.039577774703502655,
      -0.006820830516517162,
      0.03376803174614906,
      -0.035274896770715714,
      -0.07494238764047623,
      -0.013370531611144543,
      0.0734759271144867,
      0.04600595310330391,
      -0.006923542357981205,
      -0.08509479463100433,
      -0.009604187682271004,
      -0.03271833807229996,
      -0.016945533454418182,
      0.019926032051444054,
      0.03259663283824921,
      -0.08356308192014694,
      0.0804896280169487,
      0.03593140468001366,
      0.03145165741443634,
      -0.034436605870723724,
      -0.07338884472846985,
      0.015155917964875698,
      0.09451694041490555,
      -0.009239744395017624,
      -0.04333288595080376,
      0.029275545850396156,
      0.020246416330337524,
      0.047115061432123184,
      0.007704221177846193,
      0.00829478818923235,
      0.06215677782893181,
      -0.11875644326210022,
      -0.013031854294240475,
      0.029751937836408615,
      0.007161024492233992,
      0.03586333245038986,
      0.007384930737316608,
      0.051278356462717056,
      -0.014306806027889252,
      -0.050806835293769836,
      0.05261874571442604,
      0.0202349741011858,
      -0.024158991873264313,
      -0.010005246847867966,
      0.04041207209229469,
      -0.02839270420372486,
      0.033304158598184586,
      -0.10180413722991943,
      -0.05955704674124718,
      0.03037795051932335,
      -0.024730347096920013,
      0.009441527538001537,
      -0.06719432026147842,
      0.03786134719848633,
      0.03644942864775658,
      0.011346886865794659,
      -0.0033975844271481037,
      0.015469073317945004,
      0.009190874174237251,
      0.011825542896986008,
      0.029858563095331192,
      -0.08280499279499054,
      -0.04698915034532547,
      -0.057755034416913986,
      0.07008524239063263,
      0.004946151282638311,
      0.024682683870196342,
      -0.004989197943359613,
      0.03654884546995163,
      -0.06829598546028137,
      0.026071038097143173,
      0.02262125536799431,
      -0.053081683814525604,
      -0.017539674416184425,
      0.016120033338665962,
      0.017533859238028526,
      0.021073604002594948,
      -0.019947487860918045,
      -0.0438779778778553,
      0.04395945742726326,
      0.08104586601257324,
      0.04364902153611183,
      -0.004646431189030409,
      0.0654347687959671,
      0.028396517038345337,
      0.025197459384799004,
      0.028532514348626137,
      0.00662658829241991,
      -0.07689080387353897,
      0.020371120423078537,
      -0.031684018671512604,
      -0.07140515744686127,
      -0.0037976172752678394,
      0.0032534722704440355,
      -0.0805668756365776,
      0.03936634212732315,
      -0.09926261007785797,
      -0.010779622942209244,
      0.08418945223093033,
      0.06405854225158691,
      0.06661214679479599,
      -0.008348865434527397,
      0.09450145065784454,
      0.015790635719895363,
      -0.04163750633597374,
      -0.0019285711459815502,
      -0.038164686411619186,
      -0.0545339472591877,
      0.012442811392247677,
      0.03675362840294838,
      0.002810838632285595,
      0.03442407771945,
      -0.0346686951816082,
      -0.014666764065623283,
      -0.04404440149664879,
      -0.0066924807615578175,
      -7.294752867892385e-05,
      -0.062425099313259125,
      -0.025834638625383377,
      0.005402411799877882,
      0.05875485762953758,
      0.01699863187968731,
      -0.023790031671524048,
      0.04817899316549301,
      0.05404443293809891,
      -0.050867389887571335,
      -0.03882403299212456,
      -5.590008004219271e-05,
      -0.004041236359626055,
      -0.0918981283903122,
      -0.08616407215595245,
      -0.02754085697233677,
      0.1288115680217743,
      0.009074931964278221,
      -0.03272054344415665,
      -0.08899077773094177,
      0.01517245639115572,
      -0.053217288106679916,
      0.0525776743888855,
      -0.017330020666122437,
      -0.03494010865688324,
      0.010958153754472733,
      -0.027732711285352707,
      0.025834189727902412,
      0.08137089759111404,
      -0.003046842059120536,
      0.013047123327851295,
      0.024828484281897545,
      0.052291590720415115,
      0.036652300506830215,
      -0.015059039928019047,
      -0.004141094163060188,
      0.03255683556199074,
      -0.0186526570469141,
      -0.003606292651966214,
      -0.0654497742652893,
      -0.018613530322909355,
      -0.04300764575600624,
      -0.019435234367847443,
      -0.1018502488732338,
      -0.01780526526272297,
      -0.021885858848690987,
      -0.04825979843735695,
      0.03664471209049225,
      0.05919826403260231,
      -0.056629572063684464,
      0.00371527299284935,
      -0.023069405928254128,
      -0.02633487991988659,
      -0.020182380452752113,
      -0.03812137991189957,
      -0.017103558406233788,
      0.045028943568468094,
      0.11513141542673111,
      -0.07703661918640137,
      0.06687784940004349,
      0.001784169697202742,
      0.06796298176050186,
      -0.08917033672332764,
      0.03439904376864433,
      0.015210828743875027,
      0.010011354461312294,
      0.007791927084326744,
      -0.026424076408147812,
      -0.006553383078426123,
      0.011276244185864925,
      -0.07433134317398071,
      0.012410043738782406,
      -0.048009827733039856,
      0.014617237262427807,
      -0.06842966377735138,
      0.04965733364224434,
      -0.05184408649802208,
      -0.03757888451218605,
      -0.06109939515590668,
      0.01493928488343954,
      0.026212425902485847,
      0.010327824391424656,
      -0.042623620480298996,
      -0.05047336965799332,
      0.007495169527828693,
      -0.04482514038681984,
      0.00029026801348663867,
      0.0006135788280516863,
      0.028128210455179214,
      -0.029978221282362938,
      -0.014019599184393883,
      -0.058970168232917786,
      0.042357683181762695,
      -0.05597103014588356
    ],
    [
      -0.019504351541399956,
      -0.004703918006271124,
      -0.04099830240011215,
      -0.0786123126745224,
      -0.003940669819712639,
      0.0029729234520345926,
      -0.055206719785928726,
      -0.07569856196641922,
      -0.030252162367105484,
      0.010895381681621075,
      -0.026260383427143097,
      -0.06404168903827667,
      0.025935783982276917,
      0.021408671513199806,
      0.047157686203718185,
      0.00641588494181633,
      0.10667438060045242,
      0.002520266454666853,
      -0.05478522554039955,
      -0.06467173248529434,
      0.08629179000854492,
      -0.019425785169005394,
      -0.022183720022439957,
      0.014039851725101471,
      -0.007935833185911179,
      -0.018319135531783104,
      0.003020665841177106,
      -0.025500452145934105,
      0.05644616112112999,
      0.04379160329699516,
      -0.02961467206478119,
      0.007061108946800232,
      -0.022346043959259987,
      0.033257775008678436,
      0.010365203954279423,
      0.05400632694363594,
      0.10847003012895584,
      -0.010378118604421616,
      0.012899868190288544,
      -0.0005645486526191235,
      0.03352443128824234,
      -0.012131738476455212,
      0.010728730820119381,
      -0.028037099167704582,
      0.0445687510073185,
      -0.04420134425163269,
      0.046426963061094284,
      0.03726881742477417,
      0.00828349869698286,
      -0.019745992496609688,
      0.030770834535360336,
      -0.07355406135320663,
      -0.04700247198343277,
      0.025117529556155205,
      0.055969350039958954,
      -0.00790354423224926,
      -0.018339695408940315,
      -0.06757194548845291,
      -0.0066769798286259174,
      -0.02352854423224926,
      -0.02385505847632885,
      0.027334941551089287,
      0.11736083030700684,
      0.030965955927968025,
      -0.028277425095438957,
      -0.04679252579808235,
      0.0022248919121921062,
      -0.03527706488966942,
      -0.025141991674900055,
      -0.031448058784008026,
      -0.024080432951450348,
      0.11294703930616379,
      -0.012666817754507065,
      -0.028910066932439804,
      -0.07970909774303436,
      -0.03613223508000374,
      0.05054083094000816,
      0.052526090294122696,
      0.07110066711902618,
      0.027470046654343605,
      -0.07825517654418945,
      -0.01793096214532852,
      -0.05802332982420921,
      -0.035374779254198074,
      -0.010445651598274708,
      0.07164571434259415,
      -0.061022188514471054,
      0.0841858983039856,
      -0.04392790049314499,
      -0.007927166298031807,
      0.024899110198020935,
      0.03202054277062416,
      0.02000848576426506,
      -0.07158947736024857,
      -0.012719828635454178,
      0.10256431251764297,
      -0.1038089320063591,
      0.08721110969781876,
      -0.061907410621643066,
      -0.00460650073364377,
      0.006497666705399752,
      0.003799176076427102,
      -0.06100131571292877,
      0.08664584159851074,
      0.03925395756959915,
      0.011851898394525051,
      -0.01993417553603649,
      -0.042413365095853806,
      0.052847158163785934,
      0.06083330512046814,
      -0.05094301328063011,
      0.02507593110203743,
      0.05769980326294899,
      0.02952236868441105,
      0.019159292802214622,
      0.004148570355027914,
      0.06453745812177658,
      -0.014731043949723244,
      0.047663647681474686,
      0.065091073513031,
      0.17029690742492676,
      0.0018932793755084276,
      -0.019258582964539528,
      0.013225004076957703,
      0.02037394419312477,
      -0.0009907546918839216,
      0.032615743577480316,
      -0.007264295127242804,
      -0.010327761061489582,
      -0.04854227975010872,
      -0.017449747771024704,
      0.03498484566807747,
      -0.08275187015533447,
      0.005253096576780081,
      -0.006302674300968647,
      -0.03714121878147125,
      -0.01792818121612072,
      -0.023665688931941986,
      -0.052157893776893616,
      0.08755886554718018,
      0.025601109489798546,
      -0.044459886848926544,
      -0.025051042437553406,
      -0.01938730478286743,
      0.007468291558325291,
      -0.01121961697936058,
      -0.06727094948291779,
      0.03453250229358673,
      -0.005298014730215073,
      -0.00879565067589283,
      0.020102988928556442,
      -0.047356076538562775,
      0.00881995726376772,
      -0.07704389095306396,
      0.015780506655573845,
      0.003896193578839302,
      0.03369120508432388,
      0.058420829474925995,
      -0.023426849395036697,
      -0.00360363838262856,
      -0.0034734064247459173,
      -0.02266092225909233,
      -0.03954233229160309,
      -0.11577976495027542,
      0.004972441121935844,
      0.060485780239105225,
      0.06273183971643448,
      0.009777338244020939,
      -0.08963413536548615,
      0.009914212860167027,
      -0.0013608887093141675,
      -0.04356272518634796,
      -0.034141846001148224,
      -0.031721100211143494,
      0.010452395305037498,
      -0.02368127554655075,
      -0.0477101095020771,
      0.02408900111913681,
      -0.014091512188315392,
      0.05733250454068184,
      0.01615656539797783,
      0.009594215080142021,
      -0.07432109862565994,
      -0.022836895659565926,
      0.006977789103984833,
      0.02349444478750229,
      -0.005751841235905886,
      0.010895774699747562,
      -0.017078392207622528,
      0.0005295178270898759,
      0.012266249395906925,
      0.005398987326771021,
      0.014701664447784424,
      0.051450375467538834,
      0.020160909742116928,
      0.007444232702255249,
      0.015741607174277306,
      0.017834413796663284,
      -0.022043978795409203,
      0.04058348014950752,
      -0.047540999948978424,
      -0.0650230199098587,
      0.02207639254629612,
      -0.059285759925842285,
      -0.0489000603556633,
      0.0065286364406347275,
      0.02978416159749031,
      0.05984776094555855,
      0.027105629444122314,
      0.037765391170978546,
      0.05414002388715744,
      0.05078554525971413,
      -0.013675832189619541,
      0.00695785554125905,
      0.013730271719396114,
      -0.03211869299411774,
      0.035627398639917374,
      0.021055079996585846,
      -0.0069943866692483425,
      -0.05282450094819069,
      0.06323140114545822,
      -0.05528142303228378,
      -0.021075651049613953,
      0.03209822624921799,
      0.024591146036982536,
      -0.0314963273704052,
      -0.01569095253944397,
      -0.05705118551850319,
      0.029915818944573402,
      0.05024024844169617,
      0.005599376279860735,
      -0.03843020275235176,
      -0.0022092044819146395,
      0.031677279621362686,
      0.029196076095104218,
      0.04361025243997574,
      -0.05291210860013962,
      -0.013396920636296272,
      0.030662646517157555,
      0.09787318855524063,
      -0.005660243332386017,
      -0.021331537514925003,
      0.004145723767578602,
      -0.02995419129729271,
      0.005553837865591049,
      -0.016553543508052826,
      0.04373879358172417,
      0.033406998962163925,
      0.01745986007153988,
      -0.05365972965955734,
      -0.05257207527756691,
      0.021419154480099678,
      -0.008567006327211857,
      0.011238889768719673,
      -0.03410562127828598,
      0.025479773059487343,
      -0.06821657717227936,
      0.052525803446769714,
      -0.04966195300221443,
      -0.009497551247477531,
      -0.07747193425893784,
      -0.004714919254183769,
      0.009419899433851242,
      0.02707310952246189,
      -0.09846978634595871,
      0.016353202983736992,
      0.0894823968410492,
      -0.004793905653059483,
      -0.026715978980064392,
      -0.07667379081249237,
      0.10442092269659042,
      0.025017011910676956,
      -0.06686333566904068,
      0.0002813843893818557,
      0.07358689606189728,
      -0.07148676365613937,
      -0.019237488508224487,
      0.06308890879154205,
      0.043496277183294296,
      0.016716668382287025,
      -0.06723518669605255,
      -0.03329786658287048,
      0.04198632761836052,
      -0.01639217883348465,
      0.07697553932666779,
      0.0068979039788246155,
      0.039980337023735046,
      0.06942741572856903,
      -0.015015550889074802,
      0.05355873331427574,
      -0.015753112733364105,
      -0.029887815937399864,
      -0.07519004493951797,
      -0.0472017340362072,
      -0.0020176591351628304,
      0.012091496028006077,
      -0.025544457137584686,
      -0.019113996997475624,
      0.0065699671395123005,
      -0.0007625287980772555,
      0.01213786844164133,
      -0.044159773737192154,
      0.007325217593461275,
      0.037466179579496384,
      -0.020550215616822243,
      -0.013003984466195107,
      0.013115201145410538,
      -0.02905363403260708,
      0.00891268439590931,
      -0.02124771848320961,
      -0.09989354759454727,
      0.023038525134325027,
      -0.042404115200042725,
      0.05813268944621086,
      -0.04399064928293228,
      -0.032849863171577454,
      0.13581082224845886,
      -0.019834082573652267,
      -0.027889540418982506,
      -0.01598656177520752,
      0.02040417306125164,
      0.0543099045753479,
      0.027081841602921486,
      0.07722914963960648,
      0.051082760095596313,
      0.0350305400788784,
      0.08609429746866226,
      0.0631960853934288,
      0.06575479358434677,
      -0.0008804769604466856,
      0.07465729117393494,
      -0.03456531837582588,
      0.025198593735694885,
      -0.026032790541648865,
      0.042139824479818344,
      -0.07568316161632538,
      0.07931743562221527,
      0.06968274712562561,
      0.0029732927214354277,
      -0.06540966778993607,
      0.04455775395035744,
      0.03090253844857216,
      -0.010452888906002045,
      0.05550260841846466,
      0.02319330908358097,
      0.09249112010002136,
      -0.012840339913964272,
      -0.03111216425895691,
      -0.11268262565135956,
      0.007777877151966095,
      0.02675655670464039,
      0.02472442016005516,
      -0.02501389943063259,
      -0.027877748012542725,
      -0.060314614325761795,
      0.0595054067671299,
      -0.0032990744803100824,
      0.09708406031131744,
      -0.012562846764922142,
      0.06377553194761276,
      -0.03427187725901604,
      0.022773806005716324,
      0.026432335376739502,
      0.06987621635198593,
      0.007576930336654186,
      0.05490075424313545,
      -0.022266434505581856,
      0.03583882004022598,
      -0.1068573072552681,
      -0.0013927798718214035,
      -0.11285379528999329,
      -0.023614507168531418,
      -0.029243405908346176,
      -0.04526848718523979,
      0.018278418108820915,
      0.05234793946146965,
      -0.02513173781335354,
      -0.009791234508156776,
      0.027425497770309448,
      0.07436512410640717,
      -0.0032503227703273296,
      -0.013498163782060146,
      0.047532688826322556,
      -0.08784204721450806,
      -0.03401071950793266,
      0.024205604568123817,
      -0.023575125262141228,
      0.007566923275589943,
      0.0378631129860878,
      -0.05164261907339096,
      -0.012795144692063332,
      0.014665431343019009,
      0.005477437749505043,
      -0.06538090109825134,
      0.006951954215764999,
      0.03910182788968086,
      0.013528724201023579,
      -0.036156974732875824,
      0.041279714554548264,
      -0.017438624054193497,
      -0.07265978306531906,
      -0.02935338206589222,
      -0.14100556075572968,
      -0.01904633454978466,
      0.020399142056703568,
      0.04237879812717438,
      -0.019234366714954376,
      0.048369038850069046,
      0.007502675987780094,
      0.025652162730693817,
      -0.027996953576803207,
      -0.0025270702317357063,
      0.06510987132787704,
      -0.03500458225607872,
      -0.08781252801418304,
      0.10165554285049438,
      0.049824755638837814,
      0.015602683648467064,
      0.05005521699786186,
      0.007763425353914499,
      0.03725146874785423,
      -0.03631726652383804,
      -0.012366140261292458,
      0.0029269845690578222,
      -0.007523797918111086,
      -0.03918756544589996,
      -0.09692800790071487,
      -0.0049188691191375256,
      0.010923169553279877,
      0.003501741448417306,
      -0.06500361114740372,
      0.0001156697835540399,
      0.05738644674420357,
      0.00425745639950037,
      0.010157600045204163,
      -0.055241573601961136,
      -0.04990847408771515,
      -0.03475793078541756,
      -0.01280922070145607,
      -0.01873570866882801,
      -0.029401160776615143,
      -0.03898679465055466,
      -0.016646211966872215,
      -0.07109653949737549,
      -0.0001338133733952418,
      -0.027421914041042328,
      0.046964697539806366,
      0.055829182267189026,
      0.06391052901744843,
      0.053325481712818146,
      0.009853656403720379,
      -0.014885217882692814,
      -0.02123715728521347,
      -0.01251221727579832,
      -0.027507232502102852,
      0.015655187889933586,
      -0.023309489712119102,
      0.0017975575756281614,
      0.044143714010715485,
      -0.0384625643491745,
      0.03342621773481369,
      -0.0046128989197313786,
      -0.013506682589650154,
      -0.02405525930225849,
      -0.06593848764896393,
      0.10788432508707047,
      -0.013213595375418663,
      0.09904330223798752,
      0.021585693582892418,
      -0.02837868221104145,
      0.06034214794635773,
      -0.03723983094096184,
      0.02254483476281166,
      0.014334776438772678,
      -0.03318949043750763,
      -0.04854568466544151,
      -0.029085876420140266,
      0.012153069488704205,
      0.004511744249612093,
      0.014303053729236126,
      -0.05352622643113136,
      0.07506441324949265,
      -0.027419447898864746,
      0.06327807158231735,
      0.015613782219588757,
      0.04354193061590195,
      -0.017627771943807602,
      -0.10632411390542984,
      0.006862322799861431,
      0.05216901749372482,
      -0.024508897215127945,
      0.02420051023364067,
      0.005985040217638016,
      -0.03743097558617592,
      -0.004030151758342981,
      -0.02013266645371914,
      0.06609385460615158,
      0.027885355055332184,
      0.02216373197734356,
      0.049091044813394547,
      -0.06834282726049423,
      0.05731726437807083,
      -0.03210426867008209
    ],
    [
      -0.036272868514060974,
      0.022705834358930588,
      -0.03245310112833977,
      -0.06981579959392548,
      0.013399300165474415,
      -0.014094354584813118,
      0.04435870051383972,
      -0.0720478817820549,
      0.04305877536535263,
      0.03454364091157913,
      0.04600086063146591,
      -0.011717794463038445,
      -0.08190543204545975,
      -0.0172957144677639,
      -0.06067762151360512,
      -0.00246600853279233,
      0.013525827787816525,
      0.0033850243780761957,
      -0.07260476052761078,
      0.05919399484992027,
      0.02393868938088417,
      0.07507675141096115,
      -0.019477559253573418,
      0.059478648006916046,
      -0.055821165442466736,
      0.06000811234116554,
      -0.02968798391520977,
      0.008108491078019142,
      0.004520501475781202,
      -0.06925150752067566,
      -0.05853524059057236,
      0.004862627014517784,
      -0.017289983108639717,
      0.029855886474251747,
      -0.0015281479572877288,
      0.009465567767620087,
      0.03325210511684418,
      -0.04293151572346687,
      -0.03144945576786995,
      0.029622657224535942,
      0.060965582728385925,
      -0.0019339515129104257,
      0.028948849067091942,
      0.024435412138700485,
      -0.028914444148540497,
      0.06838114559650421,
      0.005542276427149773,
      -0.05824338644742966,
      -0.007340256590396166,
      -0.06467835605144501,
      0.0480702705681324,
      -0.03262685611844063,
      0.03301619365811348,
      -0.0529666393995285,
      0.07815853506326675,
      -0.024923497810959816,
      -0.00858242902904749,
      -0.03258787840604782,
      0.034196045249700546,
      0.04728224501013756,
      -0.004821650218218565,
      -0.008636861108243465,
      0.0018991936231032014,
      0.022800112143158913,
      0.02130255475640297,
      0.02901141159236431,
      -0.039768580347299576,
      0.009828528389334679,
      0.015464856289327145,
      0.04783215373754501,
      -0.11310610175132751,
      0.10714060813188553,
      0.00014866080891806632,
      0.003520618425682187,
      0.014553632587194443,
      8.722999336896464e-05,
      0.027431687340140343,
      -0.03832255303859711,
      -0.023325541988015175,
      0.07741658389568329,
      0.009564084000885487,
      0.0035554252099245787,
      0.0009983322815969586,
      -0.07453132420778275,
      -0.05985023081302643,
      0.08837323635816574,
      0.05977466329932213,
      0.1238110363483429,
      -0.02108207903802395,
      0.05316322669386864,
      -0.0411166250705719,
      -0.03171468898653984,
      0.053401779383420944,
      -0.049100544303655624,
      -0.02194627746939659,
      0.022235138341784477,
      0.06620420515537262,
      -0.02468334697186947,
      -0.04140211269259453,
      0.016726911067962646,
      -0.02543707937002182,
      0.0955609530210495,
      0.017613036558032036,
      -0.06457692384719849,
      0.05231365188956261,
      0.028918789699673653,
      0.03653217479586601,
      -0.020399801433086395,
      -0.035542093217372894,
      0.08570635318756104,
      -0.04289940744638443,
      0.001287830644287169,
      -0.01238187775015831,
      -0.07579731196165085,
      0.0007628684979863465,
      -0.016406863927841187,
      -0.001661572139710188,
      -0.018876276910305023,
      -0.045973002910614014,
      0.012570222839713097,
      -0.008352968841791153,
      0.0010597051586955786,
      0.030856287106871605,
      0.0164964497089386,
      -0.08057933300733566,
      -0.050798289477825165,
      -0.09910698980093002,
      0.06929533183574677,
      -0.048815902322530746,
      -0.07446515560150146,
      0.007219383027404547,
      0.004440396558493376,
      0.0023966440930962563,
      -0.014259053394198418,
      0.01703418605029583,
      -0.003750171745195985,
      0.018055561929941177,
      -0.017303677275776863,
      -0.061775363981723785,
      0.04984855651855469,
      -0.016709890216588974,
      0.039932940155267715,
      -0.017528168857097626,
      -0.05167033523321152,
      -0.03011736460030079,
      0.007428028155118227,
      -0.06326974928379059,
      -0.05976538360118866,
      0.01661829836666584,
      0.06898629665374756,
      -0.012800954282283783,
      0.019109411165118217,
      -0.03358091413974762,
      0.02939213067293167,
      -0.02674981765449047,
      0.024112315848469734,
      0.04688524454832077,
      -0.003320326330140233,
      0.006545634940266609,
      -0.020541789010167122,
      0.05061968415975571,
      0.05009346455335617,
      0.05631384998559952,
      0.07430364191532135,
      -0.016061631962656975,
      0.04368112236261368,
      0.02450454607605934,
      0.05563613027334213,
      -0.05754948779940605,
      0.014376433566212654,
      0.031649403274059296,
      -0.011300638318061829,
      -0.14141398668289185,
      0.009607886895537376,
      0.09629765897989273,
      0.0021644977387040854,
      0.02660556323826313,
      0.011170483194291592,
      -0.0002558246487751603,
      0.11952047795057297,
      -0.0005365330725908279,
      0.02983599528670311,
      0.0007061601500026882,
      -0.02574801631271839,
      0.014924086630344391,
      -0.022276880219578743,
      0.045583177357912064,
      0.06223032623529434,
      -0.09759718179702759,
      0.01131583284586668,
      0.05530252680182457,
      0.05332773178815842,
      -0.04302910715341568,
      -0.0666387677192688,
      -0.040888283401727676,
      -0.015414377674460411,
      -0.03954296559095383,
      -0.023556146770715714,
      -0.014557218179106712,
      -0.013413166627287865,
      0.05087801441550255,
      -0.08147922158241272,
      -0.028596974909305573,
      -0.026579294353723526,
      0.03679729253053665,
      0.061149727553129196,
      0.04513397067785263,
      -0.011603915132582188,
      0.016513606533408165,
      -0.004663263913244009,
      0.009242989122867584,
      0.026384105905890465,
      0.058058444410562515,
      0.014057270251214504,
      0.02713754028081894,
      -0.08489729464054108,
      0.012991580180823803,
      -0.06110449880361557,
      0.010477722622454166,
      -0.08310292661190033,
      0.07130579650402069,
      -0.020655205473303795,
      -0.021311558783054352,
      -0.02213573455810547,
      0.02890978381037712,
      -0.049681615084409714,
      0.019589485600590706,
      -0.07533849775791168,
      -0.04808425158262253,
      -0.032604146748781204,
      0.0743478462100029,
      0.0024036262184381485,
      -0.033303361386060715,
      0.08439892530441284,
      -0.02349596656858921,
      0.0019935774616897106,
      -0.0015130332903936505,
      0.04059046879410744,
      0.09419722110033035,
      -0.07939793914556503,
      0.012880327180027962,
      -0.01735799014568329,
      -0.1028679683804512,
      -0.04623311758041382,
      -0.06051728501915932,
      0.028925899416208267,
      -0.07366024702787399,
      -0.008976719342172146,
      -0.05887265130877495,
      0.00827991683036089,
      0.026873769238591194,
      0.03917279466986656,
      0.051122620701789856,
      0.08225666731595993,
      -0.07229739427566528,
      -0.10764636844396591,
      -0.009659978561103344,
      -0.033308595418930054,
      0.04137197881937027,
      -0.0053698173724114895,
      0.02449766732752323,
      0.04766424745321274,
      -0.08618786185979843,
      -0.04760611429810524,
      -0.016505910083651543,
      -0.045827917754650116,
      0.03779911249876022,
      0.024428116157650948,
      0.05291229113936424,
      0.12542958557605743,
      0.09918627887964249,
      -0.011314203962683678,
      -0.027636693790555,
      -0.010473738424479961,
      -0.027770832180976868,
      -0.09846914559602737,
      0.017020124942064285,
      0.028572140261530876,
      -0.012797919102013111,
      0.006190047133713961,
      -0.05794372037053108,
      -0.024529077112674713,
      -0.03143102675676346,
      -0.03402232006192207,
      -0.01294026430696249,
      -0.029888274148106575,
      -0.02954816445708275,
      -0.025446616113185883,
      -0.05711047723889351,
      -0.044639743864536285,
      -0.02337202988564968,
      -0.03397538885474205,
      0.05578352510929108,
      -0.05648060888051987,
      -0.03838980197906494,
      0.025935159996151924,
      -0.008077218197286129,
      0.028918834403157234,
      -0.0022243428975343704,
      -0.06297026574611664,
      -0.05689294636249542,
      -0.05346878990530968,
      -0.06178344786167145,
      0.019640035927295685,
      -0.05470341071486473,
      -0.019399624317884445,
      0.056171219795942307,
      -0.01801769994199276,
      -0.02763344906270504,
      0.026448072865605354,
      -0.045120641589164734,
      0.0272076278924942,
      -0.04580608755350113,
      -0.041398122906684875,
      -0.0791497454047203,
      0.021070891991257668,
      0.034171152859926224,
      0.01664762571454048,
      0.008536807261407375,
      0.04309917241334915,
      -0.0024235749151557684,
      0.000635641859844327,
      -0.05857635289430618,
      -0.004524351097643375,
      -0.03761599212884903,
      0.041711825877428055,
      -0.04953908547759056,
      0.008077981881797314,
      0.09392165392637253,
      0.047364626079797745,
      -0.04628336802124977,
      0.03844544291496277,
      0.06638173758983612,
      0.03399088233709335,
      0.004101226106286049,
      -0.018016641959547997,
      0.09518986940383911,
      -0.02678103931248188,
      -0.1327567994594574,
      -0.049053855240345,
      0.0024546089116483927,
      0.03315480053424835,
      -0.05560225993394852,
      -0.05557487905025482,
      -0.04806940257549286,
      0.008495342917740345,
      0.025397488847374916,
      0.017989998683333397,
      -0.08037438988685608,
      0.007618893403559923,
      -0.09030599147081375,
      -0.008015023544430733,
      0.05881573259830475,
      0.001041505835019052,
      -0.006385602988302708,
      0.0351523719727993,
      -0.03564926236867905,
      0.05573197826743126,
      -0.03298689052462578,
      0.047090597450733185,
      -0.0006659242790192366,
      0.028913157060742378,
      0.09511074423789978,
      0.08424775302410126,
      0.023400330916047096,
      -0.0024433545768260956,
      0.019539393484592438,
      -0.08630558848381042,
      -0.02507488988339901,
      0.050897493958473206,
      -0.06277211010456085,
      -0.06296250969171524,
      0.06994375586509705,
      0.04594213515520096,
      -0.08194588869810104,
      0.10002283751964569,
      -0.012637689709663391,
      -0.033517371863126755,
      -0.09997229278087616,
      0.02856566570699215,
      0.05021965503692627,
      -0.09858854115009308,
      0.0016893550055101514,
      -0.0032562699634581804,
      0.04577416181564331,
      0.04359326884150505,
      -0.002526429481804371,
      0.08283162862062454,
      0.046587008982896805,
      -0.04231815040111542,
      0.022985810413956642,
      0.014242144301533699,
      0.012090574949979782,
      -0.018564356490969658,
      0.041023798286914825,
      -0.07428131997585297,
      -0.013170810416340828,
      -0.027609819546341896,
      0.018926121294498444,
      0.04140270873904228,
      0.042886994779109955,
      -0.021004484966397285,
      0.03455783799290657,
      0.024809107184410095,
      -0.0052934857085347176,
      -0.021244626492261887,
      -0.01700693368911743,
      -0.009173114784061909,
      0.004206308163702488,
      0.019289173185825348,
      -0.020853154361248016,
      0.0833655595779419,
      -0.060998089611530304,
      0.03538885340094566,
      0.001849932363256812,
      -0.004763799253851175,
      -0.06420046836137772,
      -0.023511109873652458,
      -0.03391384705901146,
      -0.0524108000099659,
      0.061248019337654114,
      0.0029338125605136156,
      -0.007867330685257912,
      0.06583639234304428,
      -0.022902972996234894,
      -0.02009398117661476,
      -0.03667869791388512,
      0.02078613080084324,
      0.02279740199446678,
      -0.07984518259763718,
      0.018667902797460556,
      -0.015221807174384594,
      0.07686571776866913,
      0.026150228455662727,
      0.02206936664879322,
      0.009521396830677986,
      -0.06128166988492012,
      0.012654785998165607,
      -0.09859240055084229,
      -0.025077050551772118,
      0.06168893724679947,
      -0.1038452610373497,
      -0.0056438441388309,
      0.03607760742306709,
      -0.05185205861926079,
      -0.005480658728629351,
      0.04623164236545563,
      0.03512689843773842,
      0.007881687954068184,
      -0.043148890137672424,
      0.1104574203491211,
      0.01499307993799448,
      0.005833972245454788,
      0.03936732932925224,
      -0.0008107862086035311,
      -0.08938704431056976,
      -0.01767854392528534,
      0.07124243676662445,
      -0.03716343641281128,
      -0.02822646126151085,
      0.045755933970212936,
      0.02278180420398712,
      0.020581554621458054,
      -0.005407846532762051,
      -0.07199963927268982,
      0.05373823270201683,
      0.039545688778162,
      0.008044430986046791,
      0.03038696013391018,
      -0.033702827990055084,
      0.005594141315668821,
      0.05596892535686493,
      -0.04297192022204399,
      0.06149502098560333,
      0.03681521490216255,
      -0.003009919775649905,
      0.018066341057419777,
      -0.04891069978475571,
      0.047441307455301285,
      -0.03686213865876198,
      0.008396124467253685,
      0.042917072772979736,
      -0.017034627497196198,
      -0.07844769209623337,
      -0.023442301899194717,
      0.06503990292549133,
      0.07562937587499619,
      -0.0398155078291893,
      0.07346375286579132,
      -0.004287063609808683,
      -0.010677902959287167,
      0.05514088273048401,
      0.01436835527420044,
      -0.06030179560184479,
      -0.029350677505135536,
      0.01911645568907261,
      -0.0022448233794420958,
      0.024458013474941254,
      -0.017393285408616066,
      -0.0006215194589458406,
      0.13054510951042175,
      0.01344289630651474,
      0.013138393871486187
    ],
    [
      -0.03918192535638809,
      -0.06315306574106216,
      0.007767956238240004,
      0.0013901693746447563,
      0.009257974103093147,
      -0.007541879080235958,
      0.09212108701467514,
      0.003029976040124893,
      0.0948387160897255,
      0.032288726419210434,
      -0.0385793000459671,
      0.03100634552538395,
      -0.038176313042640686,
      -0.05958671122789383,
      0.02427397109568119,
      -0.09248989075422287,
      0.10100611299276352,
      0.05704239755868912,
      0.01303387526422739,
      -0.035476550459861755,
      0.007442030590027571,
      0.03565724194049835,
      -0.043530382215976715,
      0.05803263187408447,
      0.004032307304441929,
      -0.013093698769807816,
      0.05401220917701721,
      -0.03361978754401207,
      -0.00641088280826807,
      -0.00528328400105238,
      -0.01880820095539093,
      0.04259380325675011,
      0.010933589190244675,
      -0.0657251700758934,
      0.0909915640950203,
      0.1155475303530693,
      -0.03146440535783768,
      -0.018308604136109352,
      0.08304034173488617,
      -0.07398625463247299,
      0.02912004292011261,
      0.06442872434854507,
      -0.028398213908076286,
      -0.012374838814139366,
      0.02048526331782341,
      0.044036153703927994,
      -0.07737282663583755,
      -0.04402431100606918,
      -0.04980218783020973,
      -0.006374821532517672,
      0.04374150186777115,
      0.03656414896249771,
      -0.09047552198171616,
      0.045547645539045334,
      0.00957766268402338,
      0.00877979677170515,
      0.03033008798956871,
      -0.04543964937329292,
      0.03389132395386696,
      -9.127703378908336e-05,
      -0.05442007631063461,
      -0.0127824442461133,
      0.006139605771750212,
      -0.020900418981909752,
      -0.06149081885814667,
      -0.021215403452515602,
      -0.028155745938420296,
      -0.07268901914358139,
      0.050702136009931564,
      0.021780120208859444,
      0.0013625685824081302,
      0.04434576258063316,
      0.02864452823996544,
      0.04337320476770401,
      0.04242371767759323,
      -0.0018840086413547397,
      0.04492916539311409,
      -0.04650824889540672,
      0.09523632377386093,
      -0.027534300461411476,
      -0.07168950140476227,
      -0.06651674211025238,
      -0.1030692458152771,
      0.0070463730953633785,
      -0.003889024956151843,
      0.033825188875198364,
      -0.03187228739261627,
      -0.012868332676589489,
      -0.018664628267288208,
      -0.0524427704513073,
      0.015358238480985165,
      -0.09454662352800369,
      -0.03276129066944122,
      0.039515454322099686,
      0.050740573555231094,
      -0.0009800082771107554,
      -0.02712990902364254,
      -0.005748927127569914,
      -0.023353079333901405,
      0.10051269084215164,
      0.0034726401790976524,
      -0.06675895303487778,
      0.031171537935733795,
      -0.0643993467092514,
      0.038522280752658844,
      0.01850973814725876,
      0.039927415549755096,
      -0.04271821677684784,
      -0.058961328119039536,
      0.02387811429798603,
      -0.04757416993379593,
      0.033796343952417374,
      -0.0614863745868206,
      0.04072980955243111,
      -0.03636143356561661,
      0.025294210761785507,
      -0.05240988731384277,
      0.05319305881857872,
      0.045118361711502075,
      -0.003682537004351616,
      -0.042002663016319275,
      -0.04896456375718117,
      0.053029563277959824,
      0.021891547366976738,
      0.03739858791232109,
      -0.0350244902074337,
      0.061946433037519455,
      0.06562943756580353,
      -0.054519377648830414,
      -0.05997086316347122,
      -0.05125654861330986,
      -0.08811455219984055,
      0.04131104052066803,
      0.005836479365825653,
      0.05175076425075531,
      -0.026345400139689445,
      -0.03860868141055107,
      -0.01975560560822487,
      0.010644247755408287,
      0.009624559432268143,
      0.027570702135562897,
      0.06888233870267868,
      -0.05133973807096481,
      -0.057457443326711655,
      0.0505339540541172,
      -0.029610753059387207,
      -0.039786938577890396,
      -0.09657009690999985,
      0.08658340573310852,
      -0.050744734704494476,
      -0.0013993930770084262,
      -0.057399503886699677,
      0.05812057480216026,
      -0.11269564926624298,
      0.07087421417236328,
      -0.001801578444428742,
      0.055415548384189606,
      0.027086498215794563,
      -0.01503544021397829,
      -0.0084272101521492,
      -0.1374608278274536,
      -0.03033500537276268,
      0.037340402603149414,
      0.027024677023291588,
      -0.006853810045868158,
      0.04743948206305504,
      -0.003984780050814152,
      0.02348494529724121,
      -0.015196016058325768,
      0.0011983035365119576,
      0.013180995360016823,
      -0.03361585736274719,
      -0.01155176106840372,
      -0.08200667053461075,
      0.051046449691057205,
      -0.03648405522108078,
      -0.04240047559142113,
      -0.006389198824763298,
      0.054696083068847656,
      -0.0030515247490257025,
      0.01926531456410885,
      0.0055941371247172356,
      -0.05531899258494377,
      -0.08597100526094437,
      -0.007679680362343788,
      -0.02181614749133587,
      -0.07794905453920364,
      0.03441990166902542,
      -0.023079589009284973,
      0.019225886091589928,
      0.01051240786910057,
      -0.056627534329891205,
      0.01994708739221096,
      -0.008363950997591019,
      0.041002463549375534,
      -0.017442839220166206,
      -0.034590039402246475,
      0.007123447954654694,
      -0.08906470239162445,
      0.04080069437623024,
      -0.0731884315609932,
      0.01570987142622471,
      -0.005601765587925911,
      -0.012479543685913086,
      -0.014143316075205803,
      -0.020281711593270302,
      0.0063350084237754345,
      0.0283960048109293,
      0.0798863098025322,
      -0.05248101055622101,
      0.045501708984375,
      -0.04410886764526367,
      0.04141954332590103,
      -0.002354652853682637,
      -0.0189772080630064,
      -0.05089115351438522,
      0.037344515323638916,
      0.049184177070856094,
      -0.025342727079987526,
      0.048006705939769745,
      -0.010033431462943554,
      0.007747500203549862,
      0.012057894840836525,
      0.028742602095007896,
      -0.06045617535710335,
      -0.005835758987814188,
      -0.031103014945983887,
      0.012968601658940315,
      0.04756000638008118,
      0.04809093102812767,
      -0.03588070720434189,
      -0.10634563118219376,
      0.06585829704999924,
      0.10073160380125046,
      -0.00408586161211133,
      -0.04625200852751732,
      -0.08696938306093216,
      -0.08599453419446945,
      0.0044023203663527966,
      0.010297181084752083,
      0.003712597070261836,
      -0.06339625269174576,
      -0.06499683111906052,
      -0.10487871617078781,
      -0.052347004413604736,
      0.07899660617113113,
      -0.02398335561156273,
      0.10635651648044586,
      0.018527811393141747,
      0.09564951062202454,
      -0.028891565278172493,
      0.08314699679613113,
      -0.0772513598203659,
      0.027094444260001183,
      -0.05915496498346329,
      -0.03147505596280098,
      -0.04799763113260269,
      -0.029123201966285706,
      0.009429940953850746,
      -0.05631030350923538,
      -0.08540313690900803,
      0.06522926688194275,
      -0.029161810874938965,
      -0.1437576413154602,
      -0.013742485083639622,
      0.03601287305355072,
      -0.06236357241868973,
      -0.12161020189523697,
      0.001650212681852281,
      -0.014984429813921452,
      0.01871476322412491,
      0.10148998349905014,
      0.04417574405670166,
      -0.005883794277906418,
      0.01416611298918724,
      -0.04098937660455704,
      -0.07892771065235138,
      0.059219393879175186,
      0.058495793491601944,
      0.04425054416060448,
      3.2416777685284615e-05,
      0.07706880569458008,
      -0.04281746223568916,
      -0.01636447012424469,
      0.030194180086255074,
      -0.03325873613357544,
      0.01060542929917574,
      0.09233764559030533,
      -0.01927262917160988,
      -0.04673663526773453,
      -0.01503907423466444,
      -0.026766007766127586,
      -0.035490963608026505,
      -0.0028363445308059454,
      0.001570459920912981,
      0.06395118683576584,
      0.055335722863674164,
      0.07259547710418701,
      -0.00012146993685746565,
      -0.0136956125497818,
      0.022526031360030174,
      -0.008316089399158955,
      -0.048664119094610214,
      0.0045111761428415775,
      -0.06611353904008865,
      -0.037740398198366165,
      0.07527299225330353,
      0.02934712916612625,
      0.001893099513836205,
      -0.040958378463983536,
      0.03599075227975845,
      0.06270825117826462,
      0.01402472984045744,
      0.06874005496501923,
      -0.07533764094114304,
      0.008919482119381428,
      0.011101414449512959,
      -0.0031256633810698986,
      0.016742346808314323,
      0.03314727172255516,
      -0.06737261265516281,
      0.017232662066817284,
      -0.022009113803505898,
      -0.0061912257224321365,
      -0.014455040916800499,
      0.017282560467720032,
      0.0037001913879066706,
      0.027544191107153893,
      -0.00944525096565485,
      0.17094676196575165,
      0.05825958773493767,
      -0.018238358199596405,
      0.022859733551740646,
      0.026193417608737946,
      0.05606412515044212,
      0.015052717179059982,
      0.03963124752044678,
      0.020420122891664505,
      -0.025917882099747658,
      -0.002208603313192725,
      -0.019887790083885193,
      0.04148900508880615,
      0.005530886352062225,
      0.03339362516999245,
      0.10561907291412354,
      -0.006798013113439083,
      0.015390196815133095,
      -0.06316272169351578,
      0.014794559217989445,
      0.029142437502741814,
      -0.012949983589351177,
      -0.01732238568365574,
      0.06448833644390106,
      -0.0008065436268225312,
      0.012714485637843609,
      0.08055621385574341,
      0.043817922472953796,
      0.057555507868528366,
      -0.1554100662469864,
      -0.030955921858549118,
      0.04698637127876282,
      -0.04998689889907837,
      -0.02861666865646839,
      -0.01014660019427538,
      0.06512730568647385,
      0.023251185193657875,
      0.013026597909629345,
      0.015205147676169872,
      -0.05372679978609085,
      0.05259685963392258,
      -0.09369193017482758,
      0.029877303168177605,
      -0.021274816244840622,
      0.024205444380640984,
      0.024427691474556923,
      -0.021297050639986992,
      -0.11175940930843353,
      0.03839912265539169,
      -0.016715887933969498,
      0.0036244664806872606,
      -0.014880891889333725,
      -0.07436280697584152,
      0.03216484189033508,
      0.04641351476311684,
      0.0226217582821846,
      0.03425205498933792,
      0.0039884597063064575,
      -0.026034794747829437,
      -0.05322883650660515,
      -0.05037837475538254,
      0.023697271943092346,
      0.035421814769506454,
      -0.06661966443061829,
      0.001596990623511374,
      -0.07992525398731232,
      -0.05518023297190666,
      0.01329678576439619,
      -0.004021406173706055,
      0.1065259501338005,
      -0.046641755849123,
      -0.07463187724351883,
      0.011913862079381943,
      -0.022151147946715355,
      0.01862229034304619,
      0.06174611300230026,
      0.0756518542766571,
      -0.005643160082399845,
      0.03326055034995079,
      -0.01440839096903801,
      -0.03005772829055786,
      0.047154951840639114,
      -0.057400673627853394,
      0.04677065834403038,
      0.020579030737280846,
      0.041262440383434296,
      -0.0057648601941764355,
      -0.005037423688918352,
      -0.09379283338785172,
      -0.008807660080492496,
      -0.013985849916934967,
      -0.03511449694633484,
      0.0011778314365074039,
      0.007846786640584469,
      -0.04071397706866264,
      -0.06507059931755066,
      -0.003935195971280336,
      -0.01773139089345932,
      -0.0046600159257650375,
      0.03766677901148796,
      -0.024148469790816307,
      0.0026997006498277187,
      -0.03123629093170166,
      -0.010358277708292007,
      -0.04104357957839966,
      0.06165799871087074,
      0.04936828464269638,
      0.05048185586929321,
      -0.015112471766769886,
      0.04433417692780495,
      -0.0172987449914217,
      -0.02438567765057087,
      -0.031808242201805115,
      -0.0035546382423490286,
      -0.036490507423877716,
      0.0050069415010511875,
      -0.0703645795583725,
      0.0066071744076907635,
      -0.0003953878185711801,
      0.007189649157226086,
      0.056928448379039764,
      0.053086183965206146,
      -0.03219791129231453,
      -0.04731075465679169,
      0.04289635643362999,
      0.05946367233991623,
      -0.09152963757514954,
      0.07009009271860123,
      -0.06952374428510666,
      -0.00899411179125309,
      0.048948388546705246,
      -0.05487028881907463,
      -0.01610732264816761,
      -0.019875675439834595,
      -0.02364388480782509,
      -0.04533020406961441,
      -0.01447196863591671,
      0.026669269427657127,
      0.044552337378263474,
      -0.030349135398864746,
      -0.04483930394053459,
      0.05298873409628868,
      -0.030712397769093513,
      -0.007630846928805113,
      0.05030632019042969,
      -0.0682276040315628,
      -0.03040686435997486,
      0.05973800644278526,
      -0.03311024606227875,
      0.15098313987255096,
      7.088670827215537e-05,
      -0.06290652602910995,
      0.028927067294716835,
      0.013546361587941647,
      -0.029353536665439606,
      0.031528208404779434,
      -0.02852209284901619,
      -0.03519750013947487,
      -0.1281285136938095,
      -0.0016036855522543192,
      -0.015241015702486038,
      -0.023007716983556747,
      0.04569067060947418,
      -0.06094378978013992,
      0.07403804361820221,
      -0.058635734021663666,
      -0.025030340999364853,
      0.007733426988124847,
      -0.0031110697891563177,
      0.012824511155486107,
      0.005371991544961929,
      0.03292372077703476,
      0.017764145508408546,
      -0.005416563246399164
    ],
    [
      0.010077602230012417,
      -0.0254604984074831,
      -0.003907799255102873,
      -0.0049722520634531975,
      0.045515481382608414,
      0.0219543669372797,
      0.07682115584611893,
      0.006570350378751755,
      0.016424646601080894,
      0.14227141439914703,
      -0.005480506457388401,
      -0.024311935529112816,
      0.048534613102674484,
      0.06878214329481125,
      -0.029912086203694344,
      0.08302909880876541,
      -0.09929947555065155,
      -0.056486569344997406,
      -0.023578574880957603,
      0.08573202043771744,
      0.049772318452596664,
      -0.009102794341742992,
      0.03548647090792656,
      0.04014325141906738,
      0.017266904935240746,
      0.002775728004053235,
      0.02296963892877102,
      -0.005112326238304377,
      -0.008529078215360641,
      -0.026540886610746384,
      0.004606727976351976,
      0.022657686844468117,
      -0.026065852493047714,
      -0.042934007942676544,
      -0.07409925758838654,
      0.00021132078836672008,
      -0.025131331756711006,
      -0.02288348414003849,
      0.019642826169729233,
      0.04208719730377197,
      0.0232052244246006,
      -0.06987419724464417,
      -0.027740461751818657,
      0.019655222073197365,
      -0.08060583472251892,
      0.09111620485782623,
      0.044809650629758835,
      -0.01452631689608097,
      0.03435925021767616,
      -0.02195526286959648,
      0.025048935785889626,
      -0.011456011794507504,
      0.0501539409160614,
      0.0019713114015758038,
      0.036410700529813766,
      0.00019088448607362807,
      0.01273806020617485,
      -0.006416302174329758,
      -0.06097394973039627,
      0.07436297833919525,
      -0.0046868412755429745,
      0.05423367768526077,
      -0.01493249461054802,
      0.027398856356739998,
      -0.0013111487496644258,
      0.008253443986177444,
      0.06485339254140854,
      -0.060835011303424835,
      0.05836057290434837,
      -0.03583249822258949,
      0.010238968767225742,
      -0.0016750919166952372,
      -0.10855837166309357,
      -0.024248380213975906,
      -0.01939583383500576,
      0.01483011431992054,
      -0.033492930233478546,
      0.04387720674276352,
      -0.010121636092662811,
      -0.06205232813954353,
      -0.02820451557636261,
      0.018050840124487877,
      0.06896062195301056,
      0.022193357348442078,
      -0.03373536095023155,
      0.011069994419813156,
      0.047533340752124786,
      0.04546760767698288,
      0.03697754070162773,
      0.011660744436085224,
      0.07910268008708954,
      0.0029243153985589743,
      0.05099547654390335,
      0.0019438796443864703,
      -0.08556867390871048,
      0.05897844210267067,
      -0.002507641911506653,
      -0.0718100294470787,
      -0.036552537232637405,
      -0.004508895333856344,
      0.028750242665410042,
      0.04690031707286835,
      -0.008542878553271294,
      -0.026362484320998192,
      0.10714761912822723,
      -0.0009640447678975761,
      0.025482289493083954,
      -0.0175187811255455,
      -0.0003121124464087188,
      0.059153057634830475,
      -0.047105539590120316,
      -0.022344183176755905,
      -0.03567840903997421,
      0.022755935788154602,
      0.02357424423098564,
      -0.011528287082910538,
      -0.017658373340964317,
      0.017684003338217735,
      -0.03848183900117874,
      -0.021924763917922974,
      -0.058132871985435486,
      0.018500156700611115,
      -0.11248133331537247,
      0.03510981798171997,
      -0.11758267134428024,
      -0.04421138018369675,
      0.028101082891225815,
      -0.026332207024097443,
      -0.013162382878363132,
      -0.07220417261123657,
      0.0008316193707287312,
      0.00350682414136827,
      -0.07463660836219788,
      -0.003315893467515707,
      -0.06151065602898598,
      -0.0046984972432255745,
      -0.01284169964492321,
      0.048922449350357056,
      -0.042932167649269104,
      -0.05123819038271904,
      -0.017765339463949203,
      0.009971973486244678,
      -0.09077443182468414,
      -0.06042304262518883,
      -0.04584460332989693,
      -0.020251763984560966,
      0.0670110359787941,
      -0.0024782021064311266,
      0.06715799868106842,
      -0.010485311038792133,
      0.07304102182388306,
      -0.01443996001034975,
      0.02330726385116577,
      0.04102075472474098,
      -0.04725188389420509,
      -0.0027403123676776886,
      -0.009513171389698982,
      -0.020805003121495247,
      -0.001527416636236012,
      0.06340306252241135,
      -0.011850004084408283,
      -0.03678114712238312,
      0.0693892166018486,
      -0.016167068853974342,
      0.016370415687561035,
      -0.009994562715291977,
      0.06555066257715225,
      -0.027290960773825645,
      0.013214314356446266,
      -0.027089610695838928,
      0.01019282266497612,
      -0.0273382980376482,
      -0.01424653921276331,
      0.036907825618982315,
      0.036189574748277664,
      0.010425634682178497,
      0.016627470031380653,
      -0.008485982194542885,
      -0.1288604587316513,
      0.016416048631072044,
      -0.0165614765137434,
      -0.030174974352121353,
      0.042212508618831635,
      -0.038499973714351654,
      0.10328485816717148,
      -0.013521849177777767,
      0.08495223522186279,
      0.07777495682239532,
      0.012219865806400776,
      0.07984140515327454,
      0.050441671162843704,
      0.044405851513147354,
      -0.0006832356448285282,
      0.007379887159913778,
      -0.0632631704211235,
      -0.07662593573331833,
      0.0127102667465806,
      0.07928572595119476,
      0.018445618450641632,
      -0.011776317842304707,
      0.0013340733712539077,
      0.05285727605223656,
      0.04028996825218201,
      -0.0012197036994621158,
      0.018792491406202316,
      -0.04790142551064491,
      -0.025935539975762367,
      -0.04544735699892044,
      -0.002822229405865073,
      -0.036612458527088165,
      0.057983677834272385,
      -0.026202579960227013,
      -0.0321958102285862,
      -0.033615391701459885,
      0.04866890236735344,
      0.05404098704457283,
      -0.03161289170384407,
      -0.036610811948776245,
      -0.03317985311150551,
      -0.0085438322275877,
      0.00474125612527132,
      -0.06163371354341507,
      -0.04979294165968895,
      -0.00955449603497982,
      -0.01745082065463066,
      0.0013875548029318452,
      -0.02145477384328842,
      -0.032018259167671204,
      -0.010164888575673103,
      0.08903545141220093,
      0.043818775564432144,
      0.011678563430905342,
      -0.03592435643076897,
      -0.02153124287724495,
      -0.03682911396026611,
      0.03458407521247864,
      -0.019899282604455948,
      0.09236891567707062,
      -0.05049768090248108,
      -0.01995980180799961,
      0.019839467480778694,
      -0.023990781977772713,
      0.0015032417140901089,
      0.04348660632967949,
      -0.04520873725414276,
      0.03866492211818695,
      0.018929986283183098,
      0.017032187432050705,
      -0.05668505281209946,
      -0.024734972044825554,
      0.033002451062202454,
      0.01905806176364422,
      -0.08173231780529022,
      -0.005261172540485859,
      -0.048609696328639984,
      -0.03323156759142876,
      -0.02514593116939068,
      0.049997273832559586,
      -0.013679180294275284,
      -0.0368913859128952,
      -0.02638530544936657,
      0.04706822708249092,
      0.05234324932098389,
      -0.034646909683942795,
      -0.0672105923295021,
      0.01401334349066019,
      -0.009974747896194458,
      -0.03291831538081169,
      -0.00968163926154375,
      0.07139859348535538,
      -0.01690550334751606,
      0.020615439862012863,
      0.005484631285071373,
      -0.042052026838064194,
      -0.05094720050692558,
      0.033135928213596344,
      0.09335983544588089,
      0.08907240629196167,
      -0.08052743971347809,
      -0.005382440518587828,
      -0.0031788547057658434,
      -0.0015143953496590257,
      0.004121702630072832,
      0.05047939345240593,
      -0.020491473376750946,
      -0.0821906328201294,
      -0.05661448463797569,
      0.06306567788124084,
      0.0014196069678291678,
      -0.053781650960445404,
      -0.0643685907125473,
      0.08104758709669113,
      -0.03812572732567787,
      -0.10174158215522766,
      0.11692405492067337,
      -0.05090010538697243,
      -0.07393995672464371,
      0.025541268289089203,
      0.00260650971904397,
      0.007996462285518646,
      -0.06316181272268295,
      0.01334462035447359,
      0.04497984051704407,
      -0.04977704957127571,
      -0.038074299693107605,
      0.013785705901682377,
      -0.0670723095536232,
      0.029003124684095383,
      -0.037766195833683014,
      0.0489390566945076,
      0.03901433199644089,
      -0.03995006904006004,
      -0.00559006305411458,
      0.07373081892728806,
      0.022926656529307365,
      -0.04712029919028282,
      -0.05515405908226967,
      0.030778344720602036,
      0.0765710100531578,
      0.010199673473834991,
      -0.0035225788597017527,
      0.05004657804965973,
      0.0002171037340303883,
      0.03765725716948509,
      -0.002301391214132309,
      -0.024466397240757942,
      -0.039925336837768555,
      0.05489185079932213,
      -0.007728740572929382,
      -0.02471908926963806,
      0.007944018580019474,
      -0.00865190289914608,
      -0.00035445200046524405,
      0.03725915774703026,
      0.0030224507208913565,
      0.060073141008615494,
      -0.017014190554618835,
      0.016160612925887108,
      -0.010974090546369553,
      -0.016750482842326164,
      0.015251831151545048,
      0.00815182738006115,
      0.09368184953927994,
      0.01338061410933733,
      0.04516177251935005,
      0.08134140074253082,
      0.025449465960264206,
      -0.04380889609456062,
      -0.021340547129511833,
      0.040116142481565475,
      -0.009790504351258278,
      0.00802692025899887,
      -0.035111114382743835,
      -0.014813634566962719,
      0.04582733288407326,
      0.012844021432101727,
      0.022663351148366928,
      0.06334173679351807,
      0.023567257449030876,
      0.002805726369842887,
      -0.09945877641439438,
      0.10106068104505539,
      0.030786996707320213,
      0.0477510541677475,
      0.06070685014128685,
      0.010834354907274246,
      0.015385582111775875,
      -0.08344321697950363,
      -0.038265861570835114,
      0.055369436740875244,
      -0.04742469638586044,
      0.04154527187347412,
      0.12701250612735748,
      -0.058963578194379807,
      0.033560436218976974,
      -0.05225466191768646,
      -0.029874464496970177,
      0.04749375954270363,
      -0.09580043703317642,
      -0.0020620403811335564,
      0.04882301017642021,
      -0.008594516664743423,
      -0.007427421864122152,
      -0.05736279860138893,
      -0.017268870025873184,
      -0.02371695637702942,
      0.002728231018409133,
      -0.003942571580410004,
      0.012827735394239426,
      0.014410503208637238,
      0.04124484583735466,
      0.02691134810447693,
      -0.056582577526569366,
      -0.05507836118340492,
      -0.08197643607854843,
      -0.007422402501106262,
      -0.018633084371685982,
      -0.11926018446683884,
      0.02260672114789486,
      0.03853924944996834,
      0.013055686838924885,
      -0.04016799479722977,
      0.028146957978606224,
      -0.09085485339164734,
      0.020093949511647224,
      -0.017847098410129547,
      -0.023373370990157127,
      -0.04776361584663391,
      -0.008081698790192604,
      -0.03102978691458702,
      0.020315853878855705,
      0.005064493976533413,
      0.023014262318611145,
      -0.019746871665120125,
      0.0024523274041712284,
      0.016903750598430634,
      0.014250685460865498,
      0.04425627738237381,
      -0.017851145938038826,
      0.043285299092531204,
      -0.08617949485778809,
      -0.01178551372140646,
      0.049579814076423645,
      -0.02749759890139103,
      -0.03021549992263317,
      0.05892273783683777,
      0.0504780076444149,
      0.020459149032831192,
      -0.03841886296868324,
      -0.025009555742144585,
      0.021849170327186584,
      -0.0019728795159608126,
      -0.10036242008209229,
      -0.03909386321902275,
      -0.0248093381524086,
      -0.08227137476205826,
      0.04285936802625656,
      0.03435854613780975,
      -0.039391301572322845,
      0.025395158678293228,
      0.021402467042207718,
      0.041032321751117706,
      0.024357501417398453,
      0.020521830767393112,
      -0.012235736474394798,
      -0.06625808030366898,
      0.003151223063468933,
      0.039240702986717224,
      0.008881727233529091,
      -0.05517395958304405,
      0.033234234899282455,
      -0.06840293854475021,
      -0.04041963815689087,
      -0.03071923553943634,
      0.04771599546074867,
      -0.04232124611735344,
      -0.08917585015296936,
      0.05598445236682892,
      -0.023634349927306175,
      -0.014716924168169498,
      -0.03461139276623726,
      -0.0034846942871809006,
      0.05854804068803787,
      0.03473050892353058,
      -0.0612608902156353,
      0.001908535254187882,
      0.06419222056865692,
      -0.05205614119768143,
      0.022411063313484192,
      0.020557746291160583,
      0.07129280269145966,
      -0.02721037156879902,
      -0.025427956134080887,
      0.0034800993744283915,
      0.030421163886785507,
      0.014080336317420006,
      0.09071517735719681,
      -0.006197734270244837,
      0.024084074422717094,
      0.016829825937747955,
      0.0335434190928936,
      -0.06463108956813812,
      -0.05029400438070297,
      -0.041069526225328445,
      -0.010363410227000713,
      0.05806876718997955,
      0.0329039990901947,
      -0.026661865413188934,
      -0.02128140814602375,
      0.021351123228669167,
      -0.0032292844261974096,
      0.09223174303770065,
      0.042398255318403244,
      -0.09035895764827728,
      0.059436194598674774,
      -0.04076628386974335,
      -0.00445556128397584,
      -0.002579521620646119,
      -0.00636992696672678,
      -0.06812530010938644,
      0.07777666300535202,
      0.01687552221119404,
      -0.039522718638181686
    ],
    [
      -0.06160495802760124,
      -0.027032557874917984,
      -0.023101532831788063,
      -0.034699488431215286,
      0.03478727117180824,
      -0.046041227877140045,
      -0.03297870606184006,
      -0.11404982954263687,
      0.021830739453434944,
      -0.010317252017557621,
      0.030189143493771553,
      0.049862854182720184,
      0.05765392258763313,
      -0.07126739621162415,
      -0.020935356616973877,
      -0.0451403483748436,
      0.005893712863326073,
      -0.019943656399846077,
      -0.004572823643684387,
      0.021593548357486725,
      -0.06020667031407356,
      -0.06784938275814056,
      0.020216213539242744,
      0.03385058790445328,
      0.03626435250043869,
      -0.07011540234088898,
      0.007540566846728325,
      -0.04403223097324371,
      0.034201499074697495,
      0.018826879560947418,
      -0.004482925403863192,
      0.01707657426595688,
      0.05997703969478607,
      0.03741694986820221,
      -0.02393951639533043,
      0.05351180210709572,
      -0.00014804405509494245,
      -0.0013826440554112196,
      0.03188701719045639,
      0.028401214629411697,
      -0.01716991886496544,
      -0.0010902740759775043,
      0.017178915441036224,
      -0.03423572704195976,
      -0.04860132560133934,
      -0.019181709736585617,
      -0.10492777824401855,
      0.02490231767296791,
      0.02479303628206253,
      0.0378233939409256,
      -0.04526530206203461,
      0.000245631585130468,
      -0.05000225082039833,
      0.0058746254071593285,
      0.012857228517532349,
      -0.0038719724398106337,
      -0.04990466684103012,
      0.039903223514556885,
      -0.09093146026134491,
      0.003945041447877884,
      -0.06128493323922157,
      -0.015531966462731361,
      -0.03337165713310242,
      -0.029954584315419197,
      -0.007453577127307653,
      0.009558645077049732,
      0.039403337985277176,
      -0.018065670505166054,
      0.00824768841266632,
      0.023056797683238983,
      -0.03788967430591583,
      -0.02947901375591755,
      -0.061372123658657074,
      -0.03537163883447647,
      0.04869275540113449,
      -0.04260202869772911,
      -0.0012958127772435546,
      -0.0038421989884227514,
      0.0008388414280489087,
      0.013754286803305149,
      -0.01226313691586256,
      0.048783767968416214,
      0.01723978854715824,
      -0.08897637575864792,
      0.012669974938035011,
      0.0019509433768689632,
      -0.08982564508914948,
      0.01928565837442875,
      0.02601815201342106,
      -0.008668344467878342,
      -0.0082694748416543,
      -0.0687265694141388,
      -0.08104197680950165,
      0.004061116371303797,
      -0.06735487282276154,
      -0.04130776599049568,
      -0.002684648148715496,
      -0.07759445160627365,
      0.06118818372488022,
      -0.056864023208618164,
      0.019320352002978325,
      -0.0385001115500927,
      0.024751419201493263,
      -0.007405582349747419,
      0.01577015593647957,
      -0.027710378170013428,
      0.023396847769618034,
      0.046211402863264084,
      0.08634477853775024,
      0.03745314106345177,
      -0.0981535091996193,
      -0.04737810045480728,
      0.06407502293586731,
      0.006727001629769802,
      -0.022874832153320312,
      0.00336499628610909,
      0.07531102001667023,
      -0.05041535198688507,
      0.013021261431276798,
      0.035037294030189514,
      0.02596670761704445,
      -0.035539302974939346,
      0.0017696835566312075,
      0.031110059469938278,
      -0.058497730642557144,
      -0.021043170243501663,
      0.03779612109065056,
      0.0021760198287665844,
      -0.022936593741178513,
      -0.11487676948308945,
      0.056435998529195786,
      0.07758156210184097,
      -0.04941851273179054,
      0.05193155258893967,
      -0.0677897110581398,
      0.02315901406109333,
      0.010653434321284294,
      -0.019191136583685875,
      -0.059352342039346695,
      0.013218839652836323,
      -0.011882902123034,
      0.0038513625040650368,
      -0.026647156104445457,
      -0.025764942169189453,
      0.0267137810587883,
      0.0026508441660553217,
      0.004643314052373171,
      -0.006973764393478632,
      0.025188228115439415,
      0.06698208302259445,
      0.08822324872016907,
      -0.09697535634040833,
      0.043452803045511246,
      0.004795700311660767,
      -0.04611311852931976,
      -0.01026965118944645,
      -0.040444716811180115,
      0.03141738101840019,
      -0.01967562362551689,
      -0.02983878366649151,
      -0.02096313051879406,
      -0.021688753738999367,
      -0.04544002190232277,
      -0.030845850706100464,
      0.06797366589307785,
      -0.04293522983789444,
      0.01588433049619198,
      0.041691847145557404,
      0.0503971092402935,
      -0.06853824108839035,
      0.043371304869651794,
      -0.0018283934332430363,
      -0.03510933369398117,
      -0.04901284724473953,
      -0.0326593816280365,
      -0.004642743617296219,
      -0.02918042242527008,
      -0.06542816758155823,
      0.01049607340246439,
      0.012725623324513435,
      -0.031487226486206055,
      -0.005878820084035397,
      0.0565820075571537,
      -0.08806270360946655,
      -0.051735781133174896,
      0.03076576255261898,
      0.016201497986912727,
      -0.058924075216054916,
      -0.01385505311191082,
      -0.03254994377493858,
      -0.023455115035176277,
      -0.007630664389580488,
      -0.003929808270186186,
      0.043507084250450134,
      -0.05979852005839348,
      -0.007050888147205114,
      -0.03849096968770027,
      0.04380554333329201,
      -0.03197233006358147,
      -0.05157959833741188,
      0.01536918617784977,
      -0.01820475049316883,
      -0.02626563049852848,
      -0.0459829606115818,
      0.07148343324661255,
      -0.06415154784917831,
      -0.09893685579299927,
      0.029821932315826416,
      -0.0019643965642899275,
      0.022749153897166252,
      -0.03194752708077431,
      0.12301759421825409,
      0.08001362532377243,
      -0.007109829690307379,
      0.06536378711462021,
      0.05839322507381439,
      -0.020128408446907997,
      0.07608781009912491,
      0.06858497112989426,
      -0.03292989358305931,
      -0.0463620163500309,
      0.01506708562374115,
      0.007460637483745813,
      0.01764940284192562,
      0.02944725565612316,
      -0.02475561946630478,
      0.014064141549170017,
      0.03416644409298897,
      -0.00447997497394681,
      -0.03248436003923416,
      0.019314397126436234,
      -0.011768428608775139,
      0.002704968675971031,
      0.055139970034360886,
      -0.04397626966238022,
      -0.017135657370090485,
      0.03691497817635536,
      0.060971152037382126,
      -0.000563145789783448,
      0.01584591530263424,
      0.04443205147981644,
      -0.00810911227017641,
      0.053909678012132645,
      0.018955275416374207,
      -0.02400927059352398,
      0.019459553062915802,
      -0.13368971645832062,
      0.030574046075344086,
      -0.0004609202442225069,
      0.035696204751729965,
      -0.07053481042385101,
      0.055280398577451706,
      -0.022003216668963432,
      0.030421877279877663,
      -0.030911965295672417,
      -0.0003096438304055482,
      -0.059104982763528824,
      -0.022288544103503227,
      0.008503741584718227,
      -0.05878041312098503,
      -0.03672272339463234,
      0.0251026451587677,
      -0.08315205574035645,
      -0.09572302550077438,
      -0.009475448168814182,
      0.0512581393122673,
      -0.018701573833823204,
      -0.01573929935693741,
      0.021732833236455917,
      0.03232267498970032,
      -0.07823332399129868,
      -0.010232476517558098,
      0.061744917184114456,
      -0.008011381141841412,
      -0.008165559731423855,
      0.03116503916680813,
      -0.0017197892302647233,
      -0.020106174051761627,
      -0.02086169458925724,
      -0.03629835322499275,
      -0.014170664362609386,
      0.08446162194013596,
      0.019229598343372345,
      -0.018113816156983376,
      -0.0408484973013401,
      -0.03988879919052124,
      -0.08087778836488724,
      -0.02176438830792904,
      0.0568920262157917,
      0.04245814308524132,
      -0.023409578949213028,
      0.05655420199036598,
      -0.02636776864528656,
      -0.06536979228258133,
      -0.041343070566654205,
      0.04245591163635254,
      0.0028669771272689104,
      0.0606391616165638,
      -0.04866011068224907,
      -0.009626762941479683,
      0.02148677036166191,
      0.012221526354551315,
      0.026694826781749725,
      0.0509011447429657,
      0.012056603096425533,
      -0.007029772736132145,
      -0.010866131633520126,
      -0.028680162504315376,
      -0.029955018311738968,
      -0.005424597300589085,
      -0.030451152473688126,
      0.004697147756814957,
      -0.005195297300815582,
      0.01663978397846222,
      -0.006189381238073111,
      0.025132326409220695,
      -0.02886413037776947,
      -0.01828875206410885,
      -0.016082920134067535,
      -0.008918684907257557,
      -0.03744728863239288,
      0.05867952108383179,
      0.03929845243692398,
      -0.02783677726984024,
      -0.12555058300495148,
      0.0705626979470253,
      0.022374095395207405,
      0.09237118065357208,
      0.03748288005590439,
      0.02970600500702858,
      0.031404346227645874,
      -0.008381710387766361,
      -0.06147024407982826,
      0.045765139162540436,
      -0.0334625281393528,
      0.07650160789489746,
      -0.0012660318752750754,
      -0.051309242844581604,
      -0.0038655484095215797,
      -0.027584364637732506,
      0.018706826493144035,
      -0.01975099928677082,
      -0.04530327394604683,
      -0.02195538580417633,
      0.017183799296617508,
      0.0416875034570694,
      0.029460161924362183,
      0.016752691939473152,
      0.021785836666822433,
      -0.049547236412763596,
      -0.026207592338323593,
      0.05141310393810272,
      -0.028684038668870926,
      -0.02297259494662285,
      -0.06634805351495743,
      0.02331594191491604,
      -0.02368922531604767,
      -0.0017957700183615088,
      0.02300313487648964,
      0.05559585615992546,
      -0.06734088808298111,
      -0.027639850974082947,
      -0.06820928305387497,
      0.0018270892323926091,
      -0.005243029445409775,
      -0.006443760823458433,
      0.0374724455177784,
      -0.007021521218121052,
      -0.04188617318868637,
      0.04309934005141258,
      0.020956246182322502,
      -0.023891495540738106,
      -0.011793762445449829,
      -0.09433677792549133,
      0.02564573846757412,
      -0.05072157457470894,
      -0.028543701395392418,
      0.006546712480485439,
      -0.037449467927217484,
      0.00513444235548377,
      -0.04943179339170456,
      0.02444879710674286,
      0.032227229326963425,
      -0.0760836973786354,
      0.017884302884340286,
      0.011624814011156559,
      0.01151441503316164,
      0.04632340371608734,
      0.021776530891656876,
      -0.022728202864527702,
      -0.07515807449817657,
      0.023762712255120277,
      0.004567204043269157,
      -0.04719272628426552,
      -0.05819858983159065,
      -0.0037378575652837753,
      -0.030152050778269768,
      -0.020614763721823692,
      -0.057287875562906265,
      -0.008448303677141666,
      0.05808944255113602,
      -0.0015701145166531205,
      -0.06574852019548416,
      0.01341965515166521,
      -0.006766271777451038,
      -0.024759823456406593,
      0.06707114726305008,
      0.02282518707215786,
      -0.012870578095316887,
      -0.06369201093912125,
      -0.0580044649541378,
      0.05004454031586647,
      -0.033945534378290176,
      -0.032049063593149185,
      -0.015981001779437065,
      0.008907012641429901,
      -0.02241457626223564,
      -0.10851158946752548,
      0.03853181377053261,
      -0.0354817770421505,
      0.010289817117154598,
      -0.02117551863193512,
      0.03413044661283493,
      -0.03997618705034256,
      0.010946511290967464,
      -0.019875869154930115,
      -0.03557663410902023,
      -0.01586153171956539,
      0.00917940866202116,
      0.006603872403502464,
      0.049162425100803375,
      0.03383709862828255,
      -0.044096291065216064,
      -0.026108132675290108,
      -0.035531990230083466,
      -0.031518422067165375,
      0.021562792360782623,
      -0.04550464078783989,
      0.12037637084722519,
      -0.043468374758958817,
      0.011736134998500347,
      -0.04954644292593002,
      0.012703401036560535,
      -0.030112573876976967,
      0.02124669961631298,
      -0.06181824207305908,
      0.001070079393684864,
      0.10176627337932587,
      -0.021057365462183952,
      -0.01949276216328144,
      0.015465082600712776,
      -0.01596793159842491,
      0.011389302089810371,
      -0.07709762454032898,
      0.05126253142952919,
      -0.06794187426567078,
      -0.06536005437374115,
      0.022503023967146873,
      0.018996084108948708,
      -0.012523959390819073,
      0.008305373601615429,
      -0.05866403877735138,
      -0.010214325971901417,
      -0.03807251900434494,
      -0.020536288619041443,
      -0.011918604373931885,
      -0.03240407630801201,
      0.011801116168498993,
      0.010535825975239277,
      0.0665280669927597,
      -0.02464914694428444,
      0.033530764281749725,
      -0.026333915069699287,
      -0.006843743845820427,
      0.054371487349271774,
      -0.038945384323596954,
      -0.09238545596599579,
      -0.010284055024385452,
      -0.03372061625123024,
      -0.0403779000043869,
      0.030476300045847893,
      -0.031747542321681976,
      -0.06001170724630356,
      -0.024401510134339333,
      0.036800794303417206,
      0.026187412440776825,
      0.04329322278499603,
      -0.0565674751996994,
      -0.019337788224220276,
      0.011549302376806736,
      0.03729216009378433,
      0.04635358974337578,
      0.027701612561941147,
      -0.054457202553749084,
      0.06746967881917953,
      0.057249680161476135,
      0.018587708473205566,
      -0.010449168272316456,
      0.013009377755224705,
      -0.06591960042715073,
      0.05695443972945213,
      0.08561936020851135,
      -0.0425095334649086
    ],
    [
      0.0053720329888165,
      -0.05328267812728882,
      -0.010050051845610142,
      -0.0927700474858284,
      -0.07110727578401566,
      0.050025444477796555,
      0.08650155365467072,
      -0.04175956919789314,
      0.017056530341506004,
      -0.04853710159659386,
      0.045036379247903824,
      -0.012667584232985973,
      0.016896959394216537,
      -0.014841106720268726,
      0.004139060620218515,
      0.0016965114045888186,
      0.026664195582270622,
      0.07252766191959381,
      0.00734481168910861,
      -0.005289874039590359,
      0.012399213388562202,
      -0.06671711802482605,
      -0.03442797064781189,
      -0.0027596266008913517,
      0.01026492565870285,
      -0.017483124509453773,
      -0.04912050813436508,
      -0.022814780473709106,
      -0.03352737799286842,
      -0.01814899407327175,
      0.01702324114739895,
      0.07384035736322403,
      0.016299165785312653,
      0.06792239844799042,
      -0.11755561828613281,
      0.043574098497629166,
      -0.053041718900203705,
      -0.026133999228477478,
      -0.037578798830509186,
      -0.04505282640457153,
      -0.03774968162178993,
      -0.043916597962379456,
      -0.005873738322407007,
      -0.03153223171830177,
      0.0036645750515162945,
      -0.016563978046178818,
      -0.003580233780667186,
      -0.03297700360417366,
      -0.051383744925260544,
      0.008565250784158707,
      0.05292130261659622,
      -0.05540211871266365,
      -0.030604729428887367,
      0.04319874197244644,
      -0.03132186830043793,
      0.04211874678730965,
      0.013048512861132622,
      0.029235882684588432,
      0.021455461159348488,
      0.08643509447574615,
      0.03502001240849495,
      0.05624368041753769,
      0.017231222242116928,
      0.0026367881800979376,
      0.03922253102064133,
      0.0790514200925827,
      0.01424567773938179,
      -0.015383252874016762,
      0.008967198431491852,
      0.06160593777894974,
      0.05310188978910446,
      -0.04647376760840416,
      0.03783620521426201,
      0.038822855800390244,
      0.016082243993878365,
      0.040789082646369934,
      0.060135770589113235,
      0.07030788064002991,
      -0.012558362446725368,
      0.058572664856910706,
      0.03761546313762665,
      -0.04161069169640541,
      0.03895427659153938,
      -0.008149215951561928,
      -0.05634171888232231,
      -0.024405699223279953,
      -0.07426855713129044,
      -0.036224957555532455,
      -0.0592915304005146,
      0.019467946141958237,
      -0.021409299224615097,
      0.02334904856979847,
      0.08979944884777069,
      -0.015875788405537605,
      -0.024052970111370087,
      0.026028869673609734,
      -0.07534798979759216,
      0.03400911018252373,
      -0.0972009226679802,
      -0.014430333860218525,
      0.03046347014605999,
      0.038834113627672195,
      0.00845842994749546,
      -0.11683976650238037,
      0.012703826650977135,
      0.015603525564074516,
      0.017923057079315186,
      -0.04473971202969551,
      0.029844922944903374,
      0.050670165568590164,
      -0.043960388749837875,
      0.02950497530400753,
      0.040718596428632736,
      0.014816111885011196,
      -0.06349018216133118,
      0.0008055689395405352,
      -0.0422125943005085,
      0.01848548837006092,
      0.10805236548185349,
      -0.041607584804296494,
      -0.012401411309838295,
      0.03672207519412041,
      0.026866618543863297,
      0.0036318269558250904,
      -0.061691444367170334,
      0.003909829538315535,
      -0.023528648540377617,
      -0.05677978694438934,
      -0.0002559975255280733,
      0.02686532959342003,
      0.024622872471809387,
      0.004793605767190456,
      0.009751726873219013,
      -0.04272701218724251,
      0.07936601340770721,
      -0.04182170331478119,
      0.0011865639826282859,
      0.0398327074944973,
      -0.001962757669389248,
      -0.0015952460234984756,
      0.05990080162882805,
      0.026391543447971344,
      -0.11797978729009628,
      -0.06834853440523148,
      -0.08586996793746948,
      -0.05456177517771721,
      -0.0031329740304499865,
      -0.008202430792152882,
      -0.01866966299712658,
      -0.00057082629064098,
      0.08959908783435822,
      0.027561964467167854,
      0.038214392960071564,
      0.04102945700287819,
      0.0548105388879776,
      -0.03408117592334747,
      -0.07924628257751465,
      -0.0016087876865640283,
      -0.054323095828294754,
      0.01241597905755043,
      0.028762081637978554,
      -0.0056223743595182896,
      -0.006771905347704887,
      0.02335994876921177,
      0.01191176287829876,
      -0.01739678718149662,
      -0.04576238617300987,
      -0.028384972363710403,
      -0.0982978418469429,
      0.01099646557122469,
      0.053798411041498184,
      -0.08807332813739777,
      0.05592268705368042,
      -0.05496186390519142,
      -0.02195487543940544,
      0.04645797982811928,
      -0.03982328623533249,
      -0.04389045760035515,
      -0.031456079334020615,
      0.10227879881858826,
      0.028086530044674873,
      -0.0059463935904204845,
      0.03156529366970062,
      0.004319077357649803,
      -0.013039038516581059,
      -0.03901657089591026,
      0.0036609559319913387,
      0.003595557762309909,
      -0.045131027698516846,
      -0.023468168452382088,
      -0.02593179978430271,
      0.00947796180844307,
      0.00680428696796298,
      -0.016395647078752518,
      0.026672229170799255,
      0.039904482662677765,
      -0.008458864875137806,
      -0.006083693355321884,
      -0.021448103711009026,
      0.10320624709129333,
      0.04175230488181114,
      0.0055838581174612045,
      0.063226617872715,
      0.005348332226276398,
      -0.04912577196955681,
      -0.08130067586898804,
      -0.004986615385860205,
      -0.004617518279701471,
      -0.04407976567745209,
      0.020513255149126053,
      0.028019940480589867,
      0.01890423335134983,
      0.05496823042631149,
      -0.027245180681347847,
      -0.00016046677774284035,
      -0.0055132051929831505,
      -0.0016777737764641643,
      -0.032590970396995544,
      0.03728595748543739,
      -0.004618518985807896,
      0.047819219529628754,
      0.02152923122048378,
      -0.07497202605009079,
      0.060318537056446075,
      -0.02952468954026699,
      -0.017486998811364174,
      -0.04016661271452904,
      0.0625208392739296,
      0.010626067407429218,
      0.010018501430749893,
      0.019558461382985115,
      0.03767186775803566,
      -0.048334237188100815,
      0.033682290464639664,
      -0.015084813348948956,
      -0.0250523891299963,
      0.027398010715842247,
      0.01992422342300415,
      -0.005004582926630974,
      0.05584124103188515,
      0.07400821149349213,
      0.0680336132645607,
      0.06436942517757416,
      0.08061806112527847,
      0.05642499402165413,
      0.043310485780239105,
      -0.06570058315992355,
      -0.08878636360168457,
      -0.04412771016359329,
      0.08492372930049896,
      -0.0059000039473176,
      -0.01864577643573284,
      0.0002100355486618355,
      0.04179536923766136,
      -0.018039502203464508,
      0.003222079947590828,
      0.019942279905080795,
      0.08599361032247543,
      -0.015202539972960949,
      -0.061375368386507034,
      -0.0015403175493702292,
      -0.02517155557870865,
      0.10638920962810516,
      0.07318145036697388,
      -0.015188785269856453,
      0.07832833379507065,
      -0.04007694497704506,
      -0.02701188251376152,
      -0.005383853334933519,
      -0.014942923560738564,
      -0.04492892324924469,
      0.03603418171405792,
      -0.0479891300201416,
      -0.06230483576655388,
      -0.009684622287750244,
      -0.06430374830961227,
      -0.014761950820684433,
      -0.011558881029486656,
      0.021140309050679207,
      0.002422536024823785,
      -0.007456366904079914,
      -0.0006596033927053213,
      0.012588989920914173,
      0.02249598503112793,
      -0.04948798567056656,
      -0.08275923132896423,
      0.07578932493925095,
      0.012218812480568886,
      -0.013887116685509682,
      0.03630537539720535,
      0.02219400741159916,
      0.020578568801283836,
      0.09223823994398117,
      -0.04707932844758034,
      0.0377841480076313,
      0.0473623163998127,
      0.02850375697016716,
      -0.0432543121278286,
      0.02727663144469261,
      -0.008421579375863075,
      -0.03092978708446026,
      0.02287551946938038,
      -0.0007001753547228873,
      -0.03710612654685974,
      -0.008348289877176285,
      0.030342984944581985,
      -0.01477779820561409,
      -0.0198224987834692,
      -0.05692087113857269,
      0.08029714971780777,
      -0.003198551246896386,
      0.02358773536980152,
      0.014782714657485485,
      -0.0003042404423467815,
      0.006402168422937393,
      0.008412339724600315,
      0.010656701400876045,
      0.028598755598068237,
      -0.03543313965201378,
      -0.026776542887091637,
      0.01496820617467165,
      0.03844299539923668,
      0.06999560445547104,
      0.007977246306836605,
      -0.039950065314769745,
      0.0747624859213829,
      -0.031152358278632164,
      0.10828949511051178,
      0.08510799705982208,
      -0.009303426370024681,
      0.03745874762535095,
      0.048858191817998886,
      0.000548729847650975,
      0.0029807649552822113,
      -0.004671200178563595,
      -0.03967878967523575,
      0.004488615784794092,
      -0.038188859820365906,
      0.014092333614826202,
      -0.06721145659685135,
      0.02346094325184822,
      -0.014955039136111736,
      0.023446574807167053,
      -0.06268823891878128,
      0.07496960461139679,
      0.057031597942113876,
      0.04351147264242172,
      -0.016413342207670212,
      -0.055789582431316376,
      0.03773800656199455,
      0.04337684065103531,
      -0.07120699435472488,
      0.02013501338660717,
      0.04109600931406021,
      0.029307005926966667,
      0.006668446119874716,
      0.04872719570994377,
      0.07612345367670059,
      -0.02014567144215107,
      0.028925364837050438,
      0.01973317377269268,
      -0.04228140786290169,
      0.05910239368677139,
      -0.019377000629901886,
      -0.021506743505597115,
      -0.02860713191330433,
      0.052874576300382614,
      0.054335661232471466,
      -0.08454199880361557,
      0.01950915902853012,
      0.07069401443004608,
      0.026548074558377266,
      0.058830250054597855,
      -0.07219233363866806,
      0.07360482960939407,
      0.0022483174689114094,
      -0.038743309676647186,
      0.08095324039459229,
      0.02866862155497074,
      -4.677302058553323e-05,
      -0.028646262362599373,
      -0.03322141245007515,
      0.023665472865104675,
      -0.0032973908819258213,
      -0.008599033579230309,
      -0.025667177513241768,
      -0.030983982607722282,
      0.021736064925789833,
      -0.012150944210588932,
      -0.021718347445130348,
      0.08455390483140945,
      0.009708378463983536,
      0.013468957506120205,
      -0.07728749513626099,
      -0.011388606391847134,
      -0.017161231487989426,
      -0.053741466253995895,
      0.053927548229694366,
      0.014679147861897945,
      -0.04998054727911949,
      -0.03700646013021469,
      -0.048701830208301544,
      0.0007789303199388087,
      0.016945449635386467,
      0.055622342973947525,
      -0.07469717413187027,
      -0.09713459759950638,
      0.10217837989330292,
      0.039033178240060806,
      0.0007417378365062177,
      0.004518499132245779,
      0.018036210909485817,
      0.042997200042009354,
      -0.042949363589286804,
      -0.10189422965049744,
      -0.0604429692029953,
      0.02240188792347908,
      -0.018564283847808838,
      -0.005513446871191263,
      -0.004058967810124159,
      -0.1281002312898636,
      -0.04417268931865692,
      -0.031343527138233185,
      -0.07401617616415024,
      0.047142770141363144,
      0.002964599058032036,
      0.06492956727743149,
      -0.06096779555082321,
      0.052362099289894104,
      -0.04940411075949669,
      -0.017523907124996185,
      -0.06334070861339569,
      -0.03373528644442558,
      -0.019694628193974495,
      -0.016029486432671547,
      -0.08925411850214005,
      -0.0707787424325943,
      -0.057049527764320374,
      -0.014852546155452728,
      0.04915660619735718,
      -0.01132294163107872,
      -0.009932808578014374,
      -0.025858189910650253,
      0.05051657184958458,
      -0.03909521549940109,
      -0.028107183054089546,
      0.012767796404659748,
      0.020213065668940544,
      -0.016510946676135063,
      0.00598536292091012,
      -0.0693034678697586,
      0.023088090121746063,
      0.06771758198738098,
      -0.023324094712734222,
      0.12250535190105438,
      -0.0471070297062397,
      -0.08423416316509247,
      -0.06401298195123672,
      -0.018642423674464226,
      -0.08735129237174988,
      0.09162363409996033,
      -0.06237044557929039,
      -0.048244427889585495,
      -0.021855536848306656,
      -0.036454200744628906,
      -0.0005523805157281458,
      -0.007738332264125347,
      0.022800864651799202,
      0.034114427864551544,
      -0.007227920461446047,
      0.12081020325422287,
      -0.04278835281729698,
      0.022478315979242325,
      -0.02601487934589386,
      0.08204151690006256,
      -0.004240026231855154,
      0.02869357354938984,
      0.026890119537711143,
      0.0020354213193058968,
      -0.010849305428564548,
      0.017393246293067932,
      0.011784244328737259,
      -0.0007051697466522455,
      0.006981695536524057,
      0.06525913625955582,
      0.022153150290250778,
      0.03313855454325676,
      -0.033823587000370026,
      0.0175352580845356,
      -0.022115593776106834,
      -0.021535860374569893,
      -0.11311332136392593,
      -0.02081364393234253,
      -0.01016145572066307,
      0.002636851742863655,
      0.02024032734334469,
      -0.014641683548688889,
      0.047624457627534866,
      0.006755745969712734,
      0.03527731075882912,
      -0.034018442034721375,
      -0.09057757258415222,
      -0.0032190103083848953
    ],
    [
      0.05969606339931488,
      -0.014292657375335693,
      0.0036331440787762403,
      -0.06664253771305084,
      -0.02525022253394127,
      0.04763675481081009,
      -0.05616504326462746,
      -0.03859271854162216,
      -0.01804974302649498,
      -0.05634173005819321,
      -0.030603989958763123,
      0.0653412938117981,
      0.044139422476291656,
      0.03409002348780632,
      0.021175097674131393,
      0.04457635432481766,
      0.0034181626979261637,
      0.07471663504838943,
      0.025971630588173866,
      0.1238957941532135,
      0.028922464698553085,
      0.04076530411839485,
      0.01381414569914341,
      0.029426755383610725,
      0.007871991954743862,
      -0.05975157395005226,
      0.07467691600322723,
      0.059374306350946426,
      -0.0885891243815422,
      0.001091996906325221,
      -0.0021602483466267586,
      -0.040653958916664124,
      -0.004928725305944681,
      -0.025283602997660637,
      -0.04123614355921745,
      0.02828613668680191,
      0.0019140663789585233,
      0.005920639261603355,
      0.05119079351425171,
      0.02782486192882061,
      -0.04693753272294998,
      -0.04637504741549492,
      -0.0816606879234314,
      -0.017199432477355003,
      0.0035270685330033302,
      -0.01777258887887001,
      -0.018695872277021408,
      -0.03953546658158302,
      0.022832604125142097,
      -0.04141124337911606,
      -0.0315319262444973,
      -0.06129912659525871,
      -0.03582959249615669,
      -0.05041946843266487,
      -0.017410920932888985,
      -0.07493170350790024,
      -0.03509614244103432,
      0.012179444544017315,
      -0.035527799278497696,
      -0.06092996522784233,
      -0.039927154779434204,
      -0.06147069111466408,
      -0.07481713593006134,
      0.020480044186115265,
      0.042109712958335876,
      -0.02183682844042778,
      0.02192692831158638,
      0.016135776415467262,
      -0.018792791292071342,
      -0.011787619441747665,
      0.047848522663116455,
      0.012243695557117462,
      -0.0036664889194071293,
      0.022171899676322937,
      0.03967428579926491,
      -0.013130014762282372,
      0.02422933839261532,
      0.08247232437133789,
      -0.031011998653411865,
      0.019979866221547127,
      0.057328008115291595,
      -0.02185414917767048,
      -0.04486473277211189,
      -0.013683033175766468,
      -0.07564795762300491,
      -0.04714355617761612,
      -0.05721167474985123,
      0.015980899333953857,
      -0.0631602331995964,
      -0.001848155865445733,
      -0.06271924078464508,
      -0.002839243272319436,
      0.025406131520867348,
      -0.07205069065093994,
      -0.08507665991783142,
      0.03093395195901394,
      0.012492763809859753,
      0.010184403508901596,
      0.02214672975242138,
      0.06688597053289413,
      0.07370303571224213,
      -0.016356302425265312,
      0.014016027562320232,
      -0.008144228719174862,
      0.05249784141778946,
      -0.05923829600214958,
      -0.04645983502268791,
      0.013760184869170189,
      0.09492941945791245,
      0.01727670058608055,
      -0.0270115714520216,
      0.023255208507180214,
      -0.006326110567897558,
      -0.023035025224089622,
      -0.0333118736743927,
      -0.061851516366004944,
      -0.024482086300849915,
      0.013201103545725346,
      -0.0011814245954155922,
      -0.0037632829044014215,
      0.03707694262266159,
      0.018963275477290154,
      -0.05374640226364136,
      0.04125327244400978,
      -0.06856086105108261,
      -0.0325310193002224,
      0.04035935178399086,
      0.012186829932034016,
      0.05252847447991371,
      -0.0056347958743572235,
      0.12164637446403503,
      0.01612252928316593,
      0.011751660145819187,
      -0.0228167362511158,
      -0.01822965033352375,
      -0.09834205359220505,
      0.001545450882986188,
      0.0055270069278776646,
      -0.01842452958226204,
      -0.02174200490117073,
      -0.031057965010404587,
      0.05848854035139084,
      0.01579991728067398,
      0.07837637513875961,
      -0.06923623383045197,
      -0.04737255722284317,
      0.058194782584905624,
      0.012788443826138973,
      -0.016648001968860626,
      -0.07066187262535095,
      -0.05415233597159386,
      -0.019405337050557137,
      -0.015619168989360332,
      0.06260088086128235,
      -0.032336749136447906,
      0.07525094598531723,
      0.07861756533384323,
      -0.04036901891231537,
      0.03696010634303093,
      -0.02655307576060295,
      -0.12128464132547379,
      -0.025254026055336,
      0.050319854170084,
      0.028088778257369995,
      -0.10655134916305542,
      0.015982113778591156,
      0.07573892921209335,
      0.017207613214850426,
      0.051802948117256165,
      0.0441049300134182,
      -0.011463714763522148,
      -0.0003335599903948605,
      -0.04795597493648529,
      -0.00025575023028068244,
      0.036890145391225815,
      -0.038535840809345245,
      0.028852004557847977,
      0.042858000844717026,
      0.026492133736610413,
      0.05732956901192665,
      -0.008819138631224632,
      -0.043440088629722595,
      0.05021890625357628,
      0.03014979138970375,
      0.002732297172769904,
      0.019540848210453987,
      0.04686042293906212,
      -0.014272920787334442,
      -0.01709049753844738,
      -0.02620513178408146,
      -0.05223098024725914,
      -0.041874077171087265,
      0.06402362138032913,
      0.07820971310138702,
      0.04239039123058319,
      -0.024908501654863358,
      -0.02420888841152191,
      -0.03577331081032753,
      0.016242660582065582,
      0.009847893379628658,
      -0.014181944541633129,
      0.03880736976861954,
      0.001005273312330246,
      -0.05278325453400612,
      -0.019931569695472717,
      0.021149037405848503,
      -0.06636568903923035,
      0.01627122052013874,
      -0.007116513326764107,
      0.02857065200805664,
      -0.014620878733694553,
      -0.03953719884157181,
      0.017624441534280777,
      0.01931113749742508,
      0.003193393349647522,
      0.007012676913291216,
      0.07900231331586838,
      -0.04390745237469673,
      0.07918961346149445,
      -0.017413580790162086,
      0.06235698238015175,
      -0.0025164077524095774,
      -0.008607840165495872,
      0.06591025739908218,
      -0.02716643363237381,
      0.023030437529087067,
      0.025501864030957222,
      0.006018432788550854,
      0.01285229716449976,
      -0.01884477213025093,
      -0.001875717076472938,
      0.0054636625573039055,
      0.012669732794165611,
      -0.09543735533952713,
      -0.030453165993094444,
      0.00675278389826417,
      -0.0011546775931492448,
      0.031164200976490974,
      -1.6548116036574356e-05,
      -0.03696491941809654,
      -0.0152661744505167,
      0.03177356347441673,
      0.007608153857290745,
      -0.07773660123348236,
      -0.03580905497074127,
      -0.05400266498327255,
      -0.006122722290456295,
      -0.03372808173298836,
      -0.02515864558517933,
      0.06425917148590088,
      -0.005120984278619289,
      0.012795940041542053,
      -0.10698699951171875,
      -0.032207950949668884,
      0.01623811200261116,
      -0.008606397546827793,
      0.013058927841484547,
      0.005531067028641701,
      0.062408894300460815,
      -0.04864651337265968,
      -0.00792214646935463,
      0.011412568390369415,
      0.015690449625253677,
      -0.06710700690746307,
      0.07021135091781616,
      0.057780083268880844,
      0.0829261988401413,
      -0.07019691914319992,
      -0.013533801771700382,
      0.007457610685378313,
      -0.07783243060112,
      0.03703395649790764,
      0.0416576974093914,
      0.06681310385465622,
      0.007289396598935127,
      -0.07769083976745605,
      -0.013093075715005398,
      -0.06575081497430801,
      -0.017435144633054733,
      0.06084570288658142,
      0.04337011277675629,
      0.03641129285097122,
      0.018784964457154274,
      0.07757300138473511,
      0.05355751886963844,
      -0.03881645202636719,
      0.07134290784597397,
      0.00015345835709013045,
      0.04145073890686035,
      0.017617952078580856,
      0.01866862177848816,
      -0.0639406219124794,
      -0.03270407393574715,
      -0.013704467564821243,
      0.056024543941020966,
      -0.0005266573280096054,
      0.013147294521331787,
      -0.006946266628801823,
      -0.026318490505218506,
      -0.02465158887207508,
      0.015978310257196426,
      -0.02228458598256111,
      -0.024995693936944008,
      0.011968969367444515,
      -0.044291406869888306,
      -0.009966902434825897,
      0.059614405035972595,
      0.052249979227781296,
      -0.0021016383543610573,
      -0.054364386945962906,
      -0.05487634614109993,
      0.010187368839979172,
      0.04551918804645538,
      0.04882282391190529,
      -0.09872026741504669,
      0.03806261718273163,
      -0.09493448585271835,
      0.03600625321269035,
      0.02519182115793228,
      0.015424929559230804,
      0.13149785995483398,
      -0.011809294112026691,
      0.0019967316184192896,
      0.02448459342122078,
      -0.05226163566112518,
      0.07201509922742844,
      0.023701662197709084,
      -0.017092546448111534,
      0.0416690967977047,
      0.13749489188194275,
      -0.003978272899985313,
      -0.025079913437366486,
      0.03410280495882034,
      0.0022354372777044773,
      0.06845114380121231,
      -0.03066779114305973,
      0.013745269738137722,
      0.03716058284044266,
      -0.06857768446207047,
      -0.0007783602341078222,
      0.06527601927518845,
      0.0015833331272006035,
      0.047407329082489014,
      -0.004765646997839212,
      0.04964363947510719,
      0.03619195148348808,
      0.012747477740049362,
      -0.030889099463820457,
      -0.00098363496363163,
      -0.12655268609523773,
      -0.0226293858140707,
      -0.07941922545433044,
      0.03203305974602699,
      -0.0679783746600151,
      0.05849571153521538,
      0.00040915797580964863,
      -0.010033885948359966,
      0.05750776827335358,
      -0.0067017353139817715,
      -0.06452073156833649,
      -0.06546017527580261,
      0.03144986927509308,
      -0.006052013486623764,
      0.029149319976568222,
      -0.044688403606414795,
      0.0025413103867322206,
      -0.022337427362799644,
      -0.07423834502696991,
      -0.03677423670887947,
      -0.02200954407453537,
      -0.020164839923381805,
      -0.017840052023530006,
      0.05424860492348671,
      -0.04063244163990021,
      -0.023030009120702744,
      0.009976494126021862,
      0.051487863063812256,
      0.02376580610871315,
      -0.04170874506235123,
      0.03505707159638405,
      -0.023426776751875877,
      0.0787387415766716,
      -0.07567308098077774,
      -0.017999771982431412,
      0.024857763200998306,
      -0.025731246918439865,
      0.11423484236001968,
      0.04960165545344353,
      0.058556221425533295,
      0.09206531196832657,
      0.027366576716303825,
      -0.04954922944307327,
      0.027639364823698997,
      -0.02068706601858139,
      -0.005173460580408573,
      0.0215345099568367,
      0.01906488835811615,
      0.07756846398115158,
      -0.04858257248997688,
      -0.052315231412649155,
      -0.06875307857990265,
      0.007509803399443626,
      0.002013174816966057,
      -0.03129473328590393,
      -0.030493609607219696,
      0.005402310285717249,
      0.028882279992103577,
      0.057289041578769684,
      0.021930670365691185,
      0.07162643224000931,
      0.06110050529241562,
      -0.03294592723250389,
      0.0687086209654808,
      0.011683433316648006,
      0.029415834695100784,
      -0.06357967853546143,
      -0.031400248408317566,
      -0.0021105166524648666,
      0.025280093774199486,
      0.02404395304620266,
      0.020989060401916504,
      -0.002985000377520919,
      -0.008847023360431194,
      0.02427254058420658,
      -0.02791929990053177,
      0.08608969300985336,
      0.004077250137925148,
      0.03651447966694832,
      0.00636571180075407,
      -0.045883845537900925,
      0.04434959590435028,
      0.02159873954951763,
      -0.004501380026340485,
      0.03905582055449486,
      0.008651440031826496,
      0.00804408174008131,
      -0.031112994998693466,
      0.025903474539518356,
      0.02318337745964527,
      0.04346413537859917,
      -0.02435367926955223,
      0.047698453068733215,
      0.052618030458688736,
      0.026477837935090065,
      0.0017920457758009434,
      0.034108176827430725,
      -0.029103543609380722,
      -0.010369076393544674,
      0.046654343605041504,
      -0.016435135155916214,
      -0.026427267119288445,
      0.02883298136293888,
      -0.000896850076969713,
      0.012455175630748272,
      -0.01096336916089058,
      -0.08125092834234238,
      -0.004342288710176945,
      0.07333997637033463,
      -0.02069471776485443,
      -0.0052162655629217625,
      0.024089131504297256,
      -0.024016451090574265,
      0.04612492397427559,
      0.02655196562409401,
      -0.004655987955629826,
      0.03915676102042198,
      -0.04336453601717949,
      -0.0414285734295845,
      -0.022067023441195488,
      0.046254970133304596,
      -0.07364808768033981,
      0.004446764476597309,
      0.055195681750774384,
      0.009709233418107033,
      -0.04699687287211418,
      0.04513964056968689,
      0.012346235103905201,
      -0.02087787352502346,
      0.04581143334507942,
      -0.023403890430927277,
      0.022051218897104263,
      0.032195694744586945,
      0.05142732337117195,
      -0.019300078973174095,
      -0.01815163716673851,
      -0.0005403917166404426,
      0.04140451177954674,
      -0.031106825917959213,
      -0.06897801160812378,
      -0.06090226396918297,
      0.05081242695450783,
      0.002164335921406746,
      -0.033842816948890686,
      -0.03183560073375702,
      -0.08066307008266449,
      0.024321191012859344,
      -0.06538441777229309,
      -0.01821400783956051,
      0.044849880039691925,
      -0.007767141796648502,
      0.04814488813281059,
      -0.0776933878660202,
      0.08593448996543884
    ],
    [
      -0.0625494122505188,
      -0.02069466933608055,
      0.06514985114336014,
      0.005393827334046364,
      -0.024874774739146233,
      0.03329137712717056,
      -0.01479667704552412,
      0.06138593703508377,
      0.07114005088806152,
      -0.014439285732805729,
      -0.032393500208854675,
      -0.00732262572273612,
      -0.05331485718488693,
      0.060087163001298904,
      0.05631434544920921,
      0.0005540519487112761,
      -0.02824651449918747,
      0.06875289976596832,
      0.015656396746635437,
      -0.006803483702242374,
      -0.0440177321434021,
      0.00034291448537260294,
      0.01256320346146822,
      0.02222137153148651,
      0.052110254764556885,
      -0.06737756729125977,
      -0.06205243989825249,
      0.07003718614578247,
      -0.061980534344911575,
      -0.025824518874287605,
      -0.0049239397048950195,
      0.047734685242176056,
      -0.04255208373069763,
      -0.0026846006512641907,
      -0.0023379346821457148,
      -0.03568915277719498,
      0.02860851399600506,
      0.006782348267734051,
      0.03119378536939621,
      -0.06483884900808334,
      0.025428639724850655,
      0.022742746397852898,
      0.0007719785207882524,
      0.03678400069475174,
      -0.16359449923038483,
      0.014718892052769661,
      -0.06989049166440964,
      0.01241383422166109,
      -0.08773921430110931,
      -0.004797446075826883,
      -0.039354510605335236,
      -0.06470229476690292,
      0.056838762015104294,
      0.0407804511487484,
      -0.01365804485976696,
      0.0004775417037308216,
      -0.03588755056262016,
      -0.1263466328382492,
      0.04630561172962189,
      -0.010900883935391903,
      -0.04506085813045502,
      0.022361228242516518,
      -0.009157027117908001,
      0.03592154383659363,
      -0.014901434071362019,
      0.02967928908765316,
      -0.06474403291940689,
      0.06796081364154816,
      0.046966686844825745,
      0.004457247909158468,
      0.03742065653204918,
      0.04871934652328491,
      -0.0261925607919693,
      -0.0168349277228117,
      0.00016621520626358688,
      0.01976204477250576,
      0.01803789660334587,
      0.09457068145275116,
      -0.04546195641160011,
      0.020482491701841354,
      0.06825753301382065,
      0.07983554899692535,
      -0.011682569049298763,
      -0.0052007390186190605,
      -0.004968282766640186,
      0.011858872137963772,
      -0.00886358693242073,
      -0.029260486364364624,
      -0.018970951437950134,
      -0.07674603164196014,
      -0.09918418526649475,
      -0.07967924326658249,
      0.007798791863024235,
      0.02524198219180107,
      -0.03433884307742119,
      0.016708970069885254,
      0.009167750366032124,
      -0.0022374701220542192,
      0.05825843662023544,
      0.009972195141017437,
      -0.02713702991604805,
      -0.09225456416606903,
      0.028625238686800003,
      -0.09059549868106842,
      -0.014806324616074562,
      -0.030982859432697296,
      0.030153164640069008,
      -0.05512155592441559,
      0.0791526511311531,
      0.0047757793217897415,
      -0.013995392248034477,
      0.11499925702810287,
      -0.015203488990664482,
      -0.008284793235361576,
      0.014530944637954235,
      -0.007613120134919882,
      0.048601049929857254,
      -0.01912299357354641,
      -0.04436713084578514,
      0.016259433701634407,
      0.03545606508851051,
      -0.12505239248275757,
      0.07545354962348938,
      -0.011694343760609627,
      -0.0196849312633276,
      0.022592836990952492,
      -0.022461991757154465,
      0.061086222529411316,
      -0.036787379533052444,
      0.037654828280210495,
      -0.017122551798820496,
      -0.033822543919086456,
      0.003419951768592,
      0.016555508598685265,
      0.014338571578264236,
      -0.047790948301553726,
      0.03801311179995537,
      -0.02750474214553833,
      -0.03310229629278183,
      -0.00602736696600914,
      -0.012045809999108315,
      -0.0627899318933487,
      -0.06809373944997787,
      0.04597166180610657,
      -0.0366637259721756,
      -0.054894521832466125,
      0.010368181392550468,
      0.0003399701963644475,
      -0.015213195234537125,
      0.08195994794368744,
      0.0011407791171222925,
      -0.0850084125995636,
      -0.011238389648497105,
      0.1193174347281456,
      -0.025582820177078247,
      -0.06128723546862602,
      -0.0011774682207033038,
      0.03147125616669655,
      0.07378475368022919,
      -0.05043674260377884,
      -0.0828588455915451,
      -0.00342002185061574,
      -0.010879077948629856,
      0.010897348634898663,
      -0.02043566294014454,
      -0.06275513768196106,
      -0.00320937717333436,
      0.07549677789211273,
      -0.028825148940086365,
      -0.035429876297712326,
      0.020141350105404854,
      0.029341936111450195,
      -0.015006265603005886,
      -0.024846473708748817,
      -0.10312797129154205,
      0.03234726935625076,
      0.0464550144970417,
      -0.015451052226126194,
      0.04675232991576195,
      0.014614221639931202,
      0.0825052410364151,
      -0.0032238126732409,
      0.04542244225740433,
      -0.022320924326777458,
      -0.00720561295747757,
      0.0530533567070961,
      0.06689610332250595,
      -0.016543598845601082,
      -0.07429293543100357,
      -0.04974956065416336,
      0.009713102132081985,
      0.004433737136423588,
      -0.017859160900115967,
      -0.13071350753307343,
      0.023707306012511253,
      0.014896734617650509,
      -0.03418447822332382,
      0.006044299341738224,
      0.04115426540374756,
      0.003638257272541523,
      -0.021888548508286476,
      -0.0045742555521428585,
      0.07933927327394485,
      0.03613917902112007,
      -0.028792671859264374,
      -0.07959455996751785,
      -0.010680463165044785,
      -0.06553346663713455,
      0.007097180001437664,
      -0.052816685289144516,
      -0.03367849066853523,
      0.027767734602093697,
      0.03784576803445816,
      -0.008594137616455555,
      0.07599908858537674,
      0.023906312882900238,
      -0.03947388753294945,
      -0.14488112926483154,
      -0.015491647645831108,
      -0.056582625955343246,
      0.030938485637307167,
      -0.07517507672309875,
      -0.03236608952283859,
      0.008368659764528275,
      -0.08995399624109268,
      0.03489167243242264,
      0.057469092309474945,
      0.05305977165699005,
      0.04290993884205818,
      0.051457904279232025,
      0.04683437943458557,
      0.10156553238630295,
      0.036903634667396545,
      0.021779393777251244,
      -0.011463365517556667,
      0.08012744039297104,
      -0.023915966972708702,
      0.008827983401715755,
      -0.0635051429271698,
      -0.057185959070920944,
      -0.06563176959753036,
      0.08817540109157562,
      0.12418481707572937,
      -0.01128106378018856,
      0.025839872658252716,
      -0.015647204592823982,
      -0.03772709518671036,
      0.07356937974691391,
      -0.021624434739351273,
      0.09751175343990326,
      -0.10058844089508057,
      0.021241774782538414,
      0.030592992901802063,
      0.0459248349070549,
      -0.014541933313012123,
      0.03223874047398567,
      0.002623224863782525,
      0.016756948083639145,
      0.030768632888793945,
      -0.010661977343261242,
      -0.13548731803894043,
      0.026635749265551567,
      -0.02167939394712448,
      -0.05754682049155235,
      -0.02503550611436367,
      0.03716904670000076,
      0.032222457230091095,
      0.03687773272395134,
      0.07897153496742249,
      0.028077714145183563,
      -0.0040250420570373535,
      -0.0662350282073021,
      -0.005802976433187723,
      -0.015011012554168701,
      0.03748077526688576,
      -0.009658108465373516,
      -0.05325853452086449,
      0.04028470441699028,
      0.07046415656805038,
      -0.06781771034002304,
      -0.01619824208319187,
      0.02334308624267578,
      -0.07241736352443695,
      0.030611686408519745,
      -0.009059552103281021,
      0.02423064596951008,
      0.00037107456591911614,
      -0.02142556570470333,
      0.031123006716370583,
      -0.035575252026319504,
      -0.062287479639053345,
      0.039794184267520905,
      -0.01912764087319374,
      0.008792330510914326,
      0.02038790099322796,
      -0.01759612187743187,
      -0.03309699147939682,
      -0.019383352249860764,
      0.02549842931330204,
      0.019356539472937584,
      0.013494080863893032,
      0.005469891708344221,
      0.043032024055719376,
      -0.00309964083135128,
      0.045040637254714966,
      -0.017350703477859497,
      0.024782730266451836,
      0.004852345213294029,
      -0.05251497030258179,
      -0.0756760686635971,
      0.032318755984306335,
      0.03329569101333618,
      -0.0410146526992321,
      0.05947374925017357,
      0.032156772911548615,
      -0.05290645360946655,
      0.03159349784255028,
      0.0023514286149293184,
      -0.0875062420964241,
      -0.09393874555826187,
      0.05645544081926346,
      -0.031430937349796295,
      -0.035213571041822433,
      0.06888755410909653,
      -0.005043742246925831,
      -0.03220859915018082,
      0.049085624516010284,
      -0.023741142824292183,
      -0.02347053959965706,
      0.09843224287033081,
      0.08156448602676392,
      0.04919104650616646,
      -0.03924774006009102,
      -0.05581948161125183,
      0.0016719094710424542,
      -0.08908766508102417,
      -0.013785604387521744,
      0.041274234652519226,
      -0.05234153941273689,
      -0.01983707956969738,
      0.06469908356666565,
      -0.05589263141155243,
      0.06158323585987091,
      0.0194888636469841,
      0.01994023658335209,
      0.053353819996118546,
      -0.04672904685139656,
      -0.01831032708287239,
      0.02636110968887806,
      -0.04204915463924408,
      -0.0025993615854531527,
      -0.014343013986945152,
      0.057090554386377335,
      -0.0992051213979721,
      -0.0901678204536438,
      -0.029047386720776558,
      0.0061343517154455185,
      0.047795865684747696,
      0.0780273973941803,
      0.07955633103847504,
      -0.025529731065034866,
      0.024040430784225464,
      0.02509067766368389,
      -0.05877278372645378,
      -0.021505940705537796,
      -0.039358798414468765,
      -0.005021483637392521,
      0.00572684733197093,
      -0.03286639228463173,
      -0.01408863440155983,
      0.01639445312321186,
      0.0681639239192009,
      0.020087625831365585,
      0.006274123210459948,
      -0.028722170740365982,
      -0.01843014732003212,
      0.001297583687119186,
      -0.023656625300645828,
      -0.08018355071544647,
      0.002395383780822158,
      -0.006990957539528608,
      -0.11063920706510544,
      -0.010863509960472584,
      -0.02087647095322609,
      -0.007892806082963943,
      0.010376802645623684,
      -0.005967709701508284,
      -0.016512395814061165,
      0.020072072744369507,
      0.00016791597590781748,
      -0.020048102363944054,
      0.07835108041763306,
      -0.0093524269759655,
      0.014752429910004139,
      -0.06432226300239563,
      -0.0023484511766582727,
      -0.007106956094503403,
      -0.08696415275335312,
      -0.05081853270530701,
      0.004989295732229948,
      -0.09076537936925888,
      -0.05372427776455879,
      -0.029655154794454575,
      0.026117926463484764,
      0.0765431672334671,
      -0.04753080755472183,
      -0.04724631458520889,
      0.04933660477399826,
      0.038416504859924316,
      -0.01266514789313078,
      0.045886848121881485,
      0.004205295350402594,
      0.013119161128997803,
      0.0962885320186615,
      -0.060926567763090134,
      0.0077560776844620705,
      -0.029850300401449203,
      -0.04504196718335152,
      0.08818940818309784,
      0.07459616661071777,
      0.01756923645734787,
      -0.011767325922846794,
      -0.0597623810172081,
      0.00481412373483181,
      0.06662420183420181,
      0.011663038283586502,
      -0.0191002506762743,
      -0.015532640740275383,
      -0.0046560573391616344,
      0.07356688380241394,
      -0.011000295169651508,
      -0.0405387207865715,
      0.06025589257478714,
      -0.02812170423567295,
      -0.12005644291639328,
      -0.04715718328952789,
      -0.01725226268172264,
      0.01484764739871025,
      0.028031928464770317,
      0.03902051970362663,
      0.019870605319738388,
      -0.0005960070411674678,
      0.014095637947320938,
      0.027194734662771225,
      -0.025872349739074707,
      -0.04962414875626564,
      -0.020303118973970413,
      0.012100111693143845,
      0.007332886569201946,
      0.020785342901945114,
      -0.03242270275950432,
      -0.0041169673204422,
      0.11010756343603134,
      -0.0001534506882308051,
      0.008071317337453365,
      0.005960472393780947,
      0.02563321776688099,
      0.05149191617965698,
      -0.023848485201597214,
      0.010582578368484974,
      -0.06917444616556168,
      0.021853702142834663,
      -0.029655903577804565,
      -0.018265409395098686,
      0.04902419075369835,
      -0.0018746497808024287,
      0.07733506709337234,
      -0.01747891865670681,
      0.08556998521089554,
      -0.03533456474542618,
      -0.031151123344898224,
      -0.0008108502370305359,
      0.13141922652721405,
      0.03772113844752312,
      -0.012516316026449203,
      -0.04241573438048363,
      0.0020266487263143063,
      0.028048593550920486,
      0.012137031182646751,
      0.03605451062321663,
      -0.03612186387181282,
      -0.0320756658911705,
      0.02579335682094097,
      0.013675278052687645,
      0.010791067034006119,
      -0.03800426051020622,
      -0.016826916486024857,
      -0.020092735067009926,
      -0.05039270967245102,
      -0.045431725680828094,
      0.02870645932853222,
      0.027060868218541145,
      -0.027576519176363945,
      0.018184956163167953,
      0.03455176204442978,
      -0.04304653778672218,
      -0.007278066594153643,
      -0.0006254679174162447,
      -0.02485678344964981,
      0.027819287031888962,
      -0.08254892379045486,
      -0.0989753007888794,
      -0.04614683613181114
    ],
    [
      0.011366540566086769,
      -0.012094108387827873,
      0.007114917505532503,
      -0.07668051868677139,
      0.04451476037502289,
      -0.021755946800112724,
      0.05661265552043915,
      0.0633513331413269,
      0.02034587413072586,
      -0.08571938425302505,
      -0.0018438517581671476,
      -0.06546317785978317,
      0.03561410307884216,
      0.020270485430955887,
      0.08480764925479889,
      0.044012781232595444,
      -0.018417073413729668,
      -0.005338445771485567,
      -0.03832525759935379,
      -0.0015342584811151028,
      0.006554885767400265,
      -0.047962337732315063,
      -0.018574845045804977,
      0.03935675323009491,
      0.024010194465517998,
      0.03519466146826744,
      0.0875924751162529,
      0.05753067880868912,
      0.03970734402537346,
      0.018182847648859024,
      0.0010183592094108462,
      0.022819364443421364,
      0.00434041628614068,
      -0.03700326755642891,
      -0.03713579475879669,
      -0.08623189479112625,
      0.008590285666286945,
      0.11180057376623154,
      0.012669282034039497,
      -0.03963688760995865,
      0.018161822110414505,
      -0.005705432500690222,
      0.020378129556775093,
      0.004760164767503738,
      0.0706968680024147,
      -0.09507109224796295,
      -0.022143838927149773,
      -0.06927423924207687,
      0.036125294864177704,
      -0.05562037229537964,
      -0.008836159482598305,
      -0.027237460017204285,
      -0.01763632334768772,
      -0.01570553332567215,
      -0.052243124693632126,
      -0.04683230072259903,
      0.03820575028657913,
      -0.05630474165081978,
      -0.05753641203045845,
      -0.03483087569475174,
      0.0766381099820137,
      -0.06417857855558395,
      -0.03979446738958359,
      0.02337842620909214,
      -0.02729838900268078,
      0.051941875368356705,
      0.02896980009973049,
      -0.0170173067599535,
      -0.002468929160386324,
      -0.015566692687571049,
      -0.0043256403878331184,
      0.09062009304761887,
      0.07534255087375641,
      0.009977936744689941,
      0.11488024890422821,
      0.015442407689988613,
      -0.024410048499703407,
      0.044113945215940475,
      0.004682772792875767,
      0.006983660161495209,
      -0.010003514587879181,
      -0.019754081964492798,
      0.02959110587835312,
      -0.028314756229519844,
      0.03936326503753662,
      -0.07104261219501495,
      -0.03668970987200737,
      0.02088531292974949,
      0.024874554947018623,
      0.010085562244057655,
      -0.0026206448674201965,
      -0.02804182469844818,
      0.038164712488651276,
      0.007877890020608902,
      -0.02401960827410221,
      -0.016719313338398933,
      -0.003354334272444248,
      -0.05451793596148491,
      -0.009898889809846878,
      0.04070833697915077,
      0.021694455295801163,
      0.05222718417644501,
      0.07257872819900513,
      0.045123111456632614,
      0.018618637695908546,
      -0.06576596200466156,
      0.012559394352138042,
      -0.06816926598548889,
      0.0006130032707005739,
      0.022141246125102043,
      -0.07240712642669678,
      0.006122171878814697,
      0.06002267822623253,
      -0.05375777184963226,
      -0.014188401401042938,
      -0.052449487149715424,
      0.048317454755306244,
      -0.04554314911365509,
      -0.019290149211883545,
      0.026571910828351974,
      -0.03507199138402939,
      -0.013706954196095467,
      -0.04821888729929924,
      -0.06561864912509918,
      -0.11202257126569748,
      -0.08273598551750183,
      0.027232902124524117,
      0.059228718280792236,
      0.07320040464401245,
      -0.07819214463233948,
      0.017463665455579758,
      -0.019343562424182892,
      -0.0012754121562466025,
      -0.050996556878089905,
      0.015730030834674835,
      0.00823686271905899,
      0.08359526842832565,
      -0.03220561891794205,
      0.06869887560606003,
      -0.0018468915950506926,
      -0.06811163574457169,
      -0.011484838090837002,
      -0.03003711625933647,
      -0.024802977219223976,
      -0.014801627956330776,
      -0.08749392628669739,
      -0.0029232956003397703,
      0.03332282975316048,
      0.016776692122220993,
      -0.025675486773252487,
      0.00964510440826416,
      0.04260808601975441,
      0.06030261889100075,
      0.08204680681228638,
      0.0036403208505362272,
      -0.006037728860974312,
      -0.12477230280637741,
      0.0001747269998304546,
      0.005223599262535572,
      -0.02146029844880104,
      -0.03805526718497276,
      0.011045326478779316,
      0.005812323652207851,
      -0.017526870593428612,
      0.07635223120450974,
      -0.05900048092007637,
      0.05019586533308029,
      0.010541996918618679,
      -0.06151765584945679,
      -0.0031865390483289957,
      -0.044449154287576675,
      -0.004194792825728655,
      0.05212884768843651,
      -0.028936894610524178,
      -0.0036018474493175745,
      0.07467503845691681,
      0.04251261055469513,
      0.042683131992816925,
      0.03182026371359825,
      0.010208811610937119,
      -0.08591290563344955,
      0.023554636165499687,
      -0.04233113303780556,
      -0.02312043122947216,
      0.04524005949497223,
      -0.007275216281414032,
      0.04119880869984627,
      0.027459459379315376,
      0.03218270093202591,
      -0.0321328267455101,
      -0.08419199287891388,
      -0.008810113184154034,
      -0.05942833423614502,
      0.03403092920780182,
      -0.015736524015665054,
      0.01369835902005434,
      0.025496259331703186,
      -0.05548229068517685,
      0.013331277295947075,
      0.06744004040956497,
      0.024260139092803,
      -0.011235193349421024,
      0.016329975798726082,
      -0.03028959222137928,
      -0.04265182465314865,
      0.04059484601020813,
      -0.06444887071847916,
      0.08397607505321503,
      0.07357095181941986,
      0.02158336527645588,
      -0.08816850930452347,
      0.03199739754199982,
      0.10586317628622055,
      0.045087527483701706,
      0.09092345088720322,
      -0.02720959112048149,
      0.05570356175303459,
      -0.035325657576322556,
      0.03883948177099228,
      -0.035338301211595535,
      0.005316843278706074,
      -0.012940156273543835,
      0.004616728983819485,
      0.00762614980340004,
      -0.009452259168028831,
      -0.023366712033748627,
      0.014955990016460419,
      0.039862025529146194,
      0.05022759363055229,
      0.026235193014144897,
      -0.030680956318974495,
      -0.005769644398242235,
      -0.014482066966593266,
      -0.0890912339091301,
      -0.08906542509794235,
      0.03867407515645027,
      -0.05691668391227722,
      -0.025153346359729767,
      -0.02178710326552391,
      0.00416689645498991,
      0.02052530087530613,
      -0.02215103805065155,
      -0.054528266191482544,
      -0.057427044957876205,
      0.012276967987418175,
      -0.07464932650327682,
      -0.021482275798916817,
      0.12778490781784058,
      -0.007474274840205908,
      0.06255994737148285,
      0.023861531168222427,
      9.558342571835965e-05,
      -0.05302402377128601,
      0.03834390640258789,
      -0.04371475428342819,
      -0.01416721660643816,
      0.030848881229758263,
      -0.021494174376130104,
      0.03478168323636055,
      0.03518371284008026,
      0.006545684766024351,
      -0.04654364660382271,
      -0.01846715249121189,
      0.05497201159596443,
      -0.017522228881716728,
      -0.002646868582814932,
      0.030809590592980385,
      0.02294977754354477,
      0.11644827574491501,
      -0.06180339306592941,
      0.010525781661272049,
      0.009808496572077274,
      0.008427055552601814,
      0.06354445964097977,
      -0.03164203092455864,
      0.019390875473618507,
      -0.03943091630935669,
      -0.08102766424417496,
      0.02945469506084919,
      0.024977505207061768,
      0.06086717173457146,
      0.14292266964912415,
      0.054536521434783936,
      0.01949882321059704,
      -0.0497741624712944,
      0.014536634087562561,
      0.030229026451706886,
      -0.06220511347055435,
      -0.008275440894067287,
      -0.013850532472133636,
      0.050156235694885254,
      0.07149169594049454,
      0.009748445823788643,
      0.0291881263256073,
      0.005943384021520615,
      -0.0018671100260689855,
      -0.04405326768755913,
      0.0384005643427372,
      -0.01429468858987093,
      0.0018026047619059682,
      0.0447494275867939,
      0.0033492445945739746,
      0.03368280455470085,
      0.0030607704538851976,
      0.0608360655605793,
      0.006052595563232899,
      -0.027122847735881805,
      0.03958143666386604,
      -0.055183086544275284,
      -0.03251562640070915,
      0.01432027481496334,
      -0.027389587834477425,
      -0.027721337974071503,
      -0.006593732628971338,
      0.029677797108888626,
      -0.0030067164916545153,
      -0.054635003209114075,
      0.05293544754385948,
      -0.012108598835766315,
      -0.026179146021604538,
      -0.0026863119564950466,
      -0.023293962702155113,
      -0.06490252912044525,
      0.02978294901549816,
      -0.025232991203665733,
      0.028111282736063004,
      0.023018676787614822,
      -0.003785902401432395,
      0.03203904628753662,
      0.06383664160966873,
      0.00980308186262846,
      -0.01951625943183899,
      0.02199060097336769,
      -0.05013123154640198,
      0.012270539067685604,
      0.03418807312846184,
      0.0026419188361614943,
      -0.03417496010661125,
      -0.032995738089084625,
      -0.03683669865131378,
      -0.03342455253005028,
      0.014371332712471485,
      -0.05859172344207764,
      -0.019610730931162834,
      0.018142234534025192,
      0.06798837333917618,
      0.015014516189694405,
      -0.035280462354421616,
      0.03128737211227417,
      -0.04416218400001526,
      -0.016713691875338554,
      -0.04030097648501396,
      0.022968001663684845,
      -0.011569198220968246,
      -0.010624545626342297,
      -0.06407008320093155,
      -0.03013036586344242,
      0.08766042441129684,
      0.06503554433584213,
      -0.03348597139120102,
      0.01870477944612503,
      -0.05013836920261383,
      -0.008534046821296215,
      -0.030457407236099243,
      0.02677474170923233,
      0.007628792431205511,
      -0.07214324176311493,
      -0.025432759895920753,
      0.020207669585943222,
      0.026859527453780174,
      0.015859879553318024,
      0.007255399599671364,
      -0.002021955791860819,
      0.07157838344573975,
      -0.012583483010530472,
      0.052835557609796524,
      0.08471997082233429,
      -0.014187722466886044,
      -0.08874407410621643,
      -0.016231194138526917,
      -0.06091772019863129,
      0.09450443834066391,
      -0.05484456196427345,
      -0.035122063010931015,
      0.013777543790638447,
      -0.0687251091003418,
      0.02336852252483368,
      0.011624944396317005,
      -0.026690497994422913,
      0.041247837245464325,
      -0.005406585056334734,
      0.03547085076570511,
      0.07050909847021103,
      -0.002051291987299919,
      -0.08041763305664062,
      0.0454472079873085,
      -0.00024022070283535868,
      -0.014692972414195538,
      0.051087234169244766,
      -0.009133324958384037,
      0.02656448446214199,
      0.0101203303784132,
      0.024125931784510612,
      0.046716269105672836,
      -0.06766791641712189,
      -0.11389259994029999,
      -0.0447167232632637,
      -0.05664480850100517,
      0.0003707967989612371,
      -0.017299043014645576,
      0.060728490352630615,
      0.03611644729971886,
      0.05537084490060806,
      0.05321300029754639,
      0.0786612257361412,
      0.03164146468043327,
      -0.06820463389158249,
      0.015597316436469555,
      0.005937557201832533,
      0.001623859046958387,
      -0.08341334015130997,
      0.031076006591320038,
      0.0031257441733032465,
      0.020285122096538544,
      -0.06325713545084,
      0.005404602736234665,
      0.07167072594165802,
      -0.0013227954041212797,
      -0.012769266963005066,
      -0.013941435143351555,
      0.011196655221283436,
      0.0032371808774769306,
      -0.06405623257160187,
      -0.055057309567928314,
      -0.0800662562251091,
      0.01246008649468422,
      0.03037072904407978,
      0.06834329664707184,
      0.022344550117850304,
      -0.08447709679603577,
      -0.027080699801445007,
      0.022682730108499527,
      0.01815653219819069,
      0.015374804846942425,
      0.023854127153754234,
      -0.036126669496297836,
      0.08070462942123413,
      0.019808661192655563,
      0.0023373339790850878,
      0.0004790968378074467,
      0.014829687774181366,
      0.039155371487140656,
      0.08965355902910233,
      -0.02183663472533226,
      0.01980799250304699,
      -0.006067427806556225,
      -0.03834828734397888,
      -0.04248226806521416,
      -0.02320871129631996,
      0.008832397870719433,
      -0.0600539855659008,
      -0.07549241930246353,
      0.02199762500822544,
      -0.013688337989151478,
      0.045180175453424454,
      0.030433671548962593,
      -0.030380716547369957,
      0.036315593868494034,
      -0.014786586165428162,
      -0.008601818233728409,
      0.010980069637298584,
      0.04378366470336914,
      -0.03641841560602188,
      -0.012626883573830128,
      -0.005184841807931662,
      0.011679164133965969,
      0.06949420273303986,
      -0.03438270837068558,
      -0.017277436330914497,
      -0.004348444286733866,
      0.0559019036591053,
      -0.03960880637168884,
      -0.023408247157931328,
      -0.030864814296364784,
      0.0022128138225525618,
      0.01913333497941494,
      0.02423737570643425,
      -0.008533753454685211,
      -0.0075834388844668865,
      -0.044516295194625854,
      0.02130599319934845,
      -0.027133772149682045,
      0.0040241992101073265,
      0.06681499630212784,
      0.013335772790014744,
      0.033169448375701904,
      0.06413719058036804,
      0.024356357753276825,
      -0.010242560878396034,
      -0.017266593873500824,
      -0.028753260150551796,
      -0.00544870737940073,
      0.036189086735248566
    ],
    [
      -0.004169026389718056,
      -0.09697983413934708,
      0.039631862193346024,
      -0.04911448433995247,
      -0.06402406096458435,
      -0.01966741308569908,
      0.041049983352422714,
      0.05584195256233215,
      -0.07656929641962051,
      -0.033027760684490204,
      0.012210212647914886,
      0.0009426088072359562,
      -0.0285035390406847,
      -0.10828390717506409,
      0.060515642166137695,
      0.00685567781329155,
      -0.07909903675317764,
      -0.07323362678289413,
      -0.028911136090755463,
      0.07967396080493927,
      -0.04288356006145477,
      0.05336681008338928,
      0.04356210306286812,
      -0.01468943152576685,
      -0.023255005478858948,
      -0.02532375045120716,
      0.001530798152089119,
      0.1343776136636734,
      -0.021747810766100883,
      -0.07311514765024185,
      -0.028361396864056587,
      0.07149869203567505,
      -0.043586015701293945,
      -0.16273944079875946,
      0.004223873373121023,
      0.018313011154532433,
      0.01515849120914936,
      0.012636683881282806,
      0.0022263540886342525,
      0.02375660091638565,
      0.022396622225642204,
      -0.07108024507761002,
      0.02620612643659115,
      0.05918430536985397,
      0.014247418381273746,
      -0.1231498271226883,
      0.032776396721601486,
      -0.003907271195203066,
      0.050115570425987244,
      -0.043657951056957245,
      0.051405005156993866,
      -0.05591338500380516,
      0.03772783279418945,
      0.01598713919520378,
      -0.043613508343696594,
      0.05094112455844879,
      0.008398964069783688,
      -0.014400334097445011,
      0.027697503566741943,
      -0.013842982240021229,
      -0.03708333149552345,
      -0.012376922182738781,
      -0.009722299873828888,
      -0.01956326514482498,
      0.017987998202443123,
      0.025163233280181885,
      -0.00571975065395236,
      0.060401882976293564,
      0.07039672136306763,
      -0.013368422165513039,
      0.008391688577830791,
      0.07319259643554688,
      -0.02441241778433323,
      0.03846621513366699,
      0.09122844785451889,
      0.06326549500226974,
      -0.0337027907371521,
      0.028926916420459747,
      -0.006311596371233463,
      -0.01414833590388298,
      -0.033401552587747574,
      0.17340824007987976,
      0.04551844298839569,
      0.005535207688808441,
      0.004760126117616892,
      -0.05522254481911659,
      -0.02801133319735527,
      0.002323515247553587,
      0.03431351110339165,
      0.022987274453043938,
      0.004182287957519293,
      0.05638750642538071,
      0.010085619054734707,
      0.047305840998888016,
      0.045979708433151245,
      0.010803895071148872,
      0.004898267798125744,
      0.05094762519001961,
      0.02882569283246994,
      -0.004956843797117472,
      -0.06451264023780823,
      -0.057491403073072433,
      0.04875902086496353,
      0.07294586300849915,
      0.039721690118312836,
      -0.0027824288699775934,
      0.04883035272359848,
      0.09893637895584106,
      -0.029444275423884392,
      0.007361702620983124,
      -0.042433660477399826,
      -0.018956772983074188,
      -0.03493741527199745,
      -0.014472780749201775,
      -0.0509478822350502,
      -0.034304410219192505,
      0.04201440513134003,
      -0.031568530946969986,
      0.057890478521585464,
      0.05167187377810478,
      -0.03390807285904884,
      -0.051682066172361374,
      0.045435864478349686,
      0.004704353399574757,
      0.00368305342271924,
      0.010062226094305515,
      -0.01649296097457409,
      0.06336333602666855,
      0.034473586827516556,
      0.014853565022349358,
      -0.05064856633543968,
      0.015134848654270172,
      0.012194227427244186,
      -0.011575242504477501,
      0.022999385371804237,
      0.059928860515356064,
      0.005552728660404682,
      0.04633113369345665,
      0.041149504482746124,
      -0.16575118899345398,
      0.02166324108839035,
      -0.041736483573913574,
      -0.012064194306731224,
      -0.06565432250499725,
      -0.023041384294629097,
      0.04354697838425636,
      0.11204875260591507,
      -0.03529799357056618,
      0.03704139217734337,
      -0.06093369796872139,
      0.06794768571853638,
      -0.03839710354804993,
      0.016717003658413887,
      0.06779207289218903,
      -0.02916281670331955,
      0.0032110633328557014,
      0.02801199071109295,
      -0.00031433181720785797,
      -0.07405412197113037,
      -0.042851272970438004,
      0.00525196036323905,
      0.12446253001689911,
      0.02733292244374752,
      0.02439051680266857,
      -0.045808739960193634,
      -0.026811517775058746,
      0.020703807473182678,
      -0.06182302162051201,
      0.05224918946623802,
      -0.002878703409805894,
      -0.009396262466907501,
      -0.1126517802476883,
      -0.024486688897013664,
      0.02767098695039749,
      0.010118328966200352,
      0.016916604712605476,
      -0.005556268617510796,
      -0.039199501276016235,
      -0.012058819644153118,
      -0.024097083136439323,
      0.0799064114689827,
      -0.015694357454776764,
      -0.08622181415557861,
      0.04355384036898613,
      -0.04439444839954376,
      0.0067843240685760975,
      0.012208545580506325,
      0.005173713434487581,
      -0.025125747546553612,
      -0.09806905686855316,
      -0.05484540015459061,
      0.016251781955361366,
      -0.046563345938920975,
      -0.031533077359199524,
      0.011404508724808693,
      -0.024621829390525818,
      0.05777367949485779,
      0.04778766632080078,
      0.008599192835390568,
      0.06234493851661682,
      -0.008135193958878517,
      -0.023291533812880516,
      -0.03674973174929619,
      0.033530060201883316,
      0.057504624128341675,
      0.042075350880622864,
      -0.04366139695048332,
      0.07217557728290558,
      -0.07350742816925049,
      -0.005957720801234245,
      -0.002722902223467827,
      0.002398372394964099,
      0.04983142390847206,
      -0.07732148468494415,
      0.00882622692734003,
      -0.06138232722878456,
      0.08565665781497955,
      0.0945560410618782,
      0.013385467231273651,
      0.0007380566094070673,
      0.013963533565402031,
      0.023323286324739456,
      -0.01943478174507618,
      -0.028524568304419518,
      0.0014657218707725406,
      0.0290838610380888,
      0.04872695729136467,
      0.035396069288253784,
      0.15111297369003296,
      0.01692899502813816,
      -0.013871499337255955,
      0.06502986699342728,
      -0.011499695479869843,
      0.010004826821386814,
      -0.019129225984215736,
      0.05164267495274544,
      0.05953604355454445,
      -0.039011139422655106,
      0.05857699736952782,
      -0.00028268343885429204,
      0.05750495195388794,
      0.012991504743695259,
      0.01477763056755066,
      0.04431229829788208,
      -0.009032689966261387,
      0.03522524610161781,
      0.033513881266117096,
      -0.04691601172089577,
      0.0048811836168169975,
      0.11009154468774796,
      0.0524236336350441,
      -0.02017267979681492,
      -0.07548956573009491,
      0.01526991743594408,
      0.0342986173927784,
      0.02435845509171486,
      0.03473181277513504,
      0.0156621765345335,
      0.034423183649778366,
      -0.020546838641166687,
      -0.11378568410873413,
      -0.03285837545990944,
      -0.09009364247322083,
      -0.07647174596786499,
      0.0035581181291490793,
      0.01025568787008524,
      0.08090890198945999,
      0.002765323268249631,
      0.060252197086811066,
      -0.05697082728147507,
      -0.06400170177221298,
      -0.00609693443402648,
      -0.08549005538225174,
      0.02627534419298172,
      -0.0011970152845606208,
      0.015636954456567764,
      -0.04228908568620682,
      -0.04571361467242241,
      0.006188727915287018,
      -0.05432876944541931,
      -0.026066351681947708,
      -0.046133413910865784,
      -0.01609768345952034,
      -0.002308834111317992,
      -0.017066918313503265,
      0.033844318240880966,
      0.06327064335346222,
      -0.056984931230545044,
      -0.08424367755651474,
      -0.0015056361444294453,
      -0.033749062567949295,
      0.1243954598903656,
      0.0319937989115715,
      -0.023276224732398987,
      -0.00033813604386523366,
      0.02349025383591652,
      -0.048625119030475616,
      -0.02230331487953663,
      -0.013912838883697987,
      0.04879824072122574,
      -0.005738906562328339,
      -0.13060490787029266,
      -0.08895310759544373,
      0.004621777683496475,
      -0.010479859076440334,
      0.008027800358831882,
      -0.10728976130485535,
      -0.024168480187654495,
      0.0073209539987146854,
      -0.03078152984380722,
      0.04012628272175789,
      0.022762715816497803,
      -0.11178411543369293,
      -0.02300446666777134,
      -0.03270064666867256,
      0.03842543810606003,
      -0.056552838534116745,
      0.0171958040446043,
      -0.01113736443221569,
      0.05159788206219673,
      0.031161561608314514,
      0.010742631740868092,
      -4.6662862587254494e-05,
      0.04788423702120781,
      0.023061612620949745,
      0.016066893935203552,
      -0.010758107528090477,
      -0.010755089111626148,
      0.00030209278338588774,
      -0.0028907805681228638,
      -0.03707938641309738,
      0.0057305265218019485,
      0.057296812534332275,
      0.0005287096719257534,
      -0.019914597272872925,
      0.03982493653893471,
      -0.07225523889064789,
      -0.014456691220402718,
      -0.03972863405942917,
      -0.00748758390545845,
      -0.0445621982216835,
      0.001752145355567336,
      0.013610780239105225,
      -0.03456416726112366,
      0.020945260301232338,
      0.012082668952643871,
      -0.002853147219866514,
      0.023957066237926483,
      0.025238648056983948,
      -0.039146170020103455,
      -0.027472257614135742,
      -0.03280381113290787,
      -0.005508034024387598,
      0.06762208789587021,
      -0.030064046382904053,
      0.021087611094117165,
      0.025698021054267883,
      0.022600894793868065,
      -0.000699001713655889,
      -0.04710882529616356,
      0.021726641803979874,
      0.09728167951107025,
      0.0611162893474102,
      0.09174983203411102,
      -0.016052057966589928,
      0.08739706873893738,
      -0.05415070801973343,
      0.013137596659362316,
      -0.04034373164176941,
      0.02832959219813347,
      -0.005664805881679058,
      0.01463557593524456,
      0.03127126023173332,
      -0.08180854469537735,
      -0.07402069121599197,
      0.0030842418782413006,
      0.038075536489486694,
      -0.04085995629429817,
      0.1073802262544632,
      0.0681307464838028,
      -0.009802744723856449,
      0.01852397620677948,
      0.10650748759508133,
      -0.04745044186711311,
      -0.010842796415090561,
      0.006919448263943195,
      0.07291342318058014,
      -0.022412452846765518,
      0.021244751289486885,
      0.05456257984042168,
      0.05066673457622528,
      0.029195962473750114,
      0.01284655649214983,
      0.011971301399171352,
      -0.050746265798807144,
      -0.050595395267009735,
      0.0020112458150833845,
      0.01117029506713152,
      0.048535753041505814,
      0.03546486422419548,
      -0.005175371188670397,
      0.00128692586440593,
      0.033964551985263824,
      -0.015219510532915592,
      -0.08880665153265,
      -0.007380891125649214,
      0.0031454432755708694,
      0.02579566463828087,
      0.028672881424427032,
      -0.041957296431064606,
      0.09468304365873337,
      0.02232406474649906,
      0.030352048575878143,
      -0.04201158881187439,
      -0.0816534012556076,
      0.031266599893569946,
      0.032192785292863846,
      0.025012671947479248,
      0.0030914421658962965,
      -0.11613740772008896,
      0.03236857056617737,
      0.014149937778711319,
      -0.005120378918945789,
      -0.03065897896885872,
      -0.02234119549393654,
      0.011389650404453278,
      0.003099480178207159,
      0.029249029234051704,
      -0.009765705093741417,
      -0.025866443291306496,
      -0.06764858961105347,
      0.04530499130487442,
      0.008089762181043625,
      -0.016446124762296677,
      -0.02474825829267502,
      -0.04815652593970299,
      0.023658229038119316,
      0.0457867756485939,
      -0.0040589794516563416,
      0.011247407644987106,
      -0.058608528226614,
      0.03949236124753952,
      0.020648576319217682,
      -0.02526141330599785,
      -0.017102714627981186,
      0.05390847474336624,
      0.03932421654462814,
      -0.013286082074046135,
      -0.012882978655397892,
      -0.029018038883805275,
      -0.0374024361371994,
      -0.02933996543288231,
      0.05912838503718376,
      0.04709253087639809,
      0.05783148109912872,
      -0.06861884146928787,
      -0.03595456853508949,
      -0.0021464768797159195,
      0.006401401478797197,
      -0.041116468608379364,
      0.023083152249455452,
      -0.02870374545454979,
      -0.01689196564257145,
      0.015730679035186768,
      -0.006512629799544811,
      -0.025037892162799835,
      0.004487159661948681,
      0.04004191979765892,
      0.05971871688961983,
      -0.004884517751634121,
      -0.00381111865863204,
      -0.03821248188614845,
      0.018961040303111076,
      -0.05702154338359833,
      -0.0305571798235178,
      0.016898328438401222,
      -0.03969787061214447,
      -0.015193282626569271,
      0.010217005386948586,
      -0.002108796499669552,
      0.02359449304640293,
      -0.03527121618390083,
      -0.09409601241350174,
      0.01592480204999447,
      0.034139204770326614,
      0.019539091736078262,
      0.01949431747198105,
      -0.14305949211120605,
      0.07191489636898041,
      -0.053298141807317734,
      0.10483621060848236,
      -0.08518122881650925,
      0.013035055249929428,
      0.11526091396808624,
      -0.02140049636363983,
      0.02555999718606472,
      0.03699667006731033,
      -0.017980098724365234,
      -0.03988875448703766,
      0.00893194880336523,
      0.018080852925777435,
      0.007421295624226332,
      -0.01875307597219944
    ],
    [
      0.001739774364978075,
      0.06718757003545761,
      0.005764596629887819,
      0.010676697827875614,
      -0.031848471611738205,
      0.021363086998462677,
      0.12116978317499161,
      -0.023988567292690277,
      -0.026110809296369553,
      0.0018179460894316435,
      -0.0215073823928833,
      -0.010469992645084858,
      0.001789945992641151,
      0.005288523621857166,
      0.05007145181298256,
      -0.01624796725809574,
      -0.0554116889834404,
      0.0015931936213746667,
      0.05441578850150108,
      0.0067964275367558,
      0.0713268592953682,
      0.04647423326969147,
      0.015847252681851387,
      -0.017081549391150475,
      -0.002424434060230851,
      0.04628340154886246,
      0.08270615339279175,
      0.09031671285629272,
      -0.057747308164834976,
      -0.0466531440615654,
      -0.06395509093999863,
      0.019611090421676636,
      0.08151142299175262,
      -0.05318458378314972,
      0.09734343737363815,
      0.07649196684360504,
      -0.06840207427740097,
      -0.007245870307087898,
      -0.052763257175683975,
      0.0290371086448431,
      -0.09070634841918945,
      -0.03498250991106033,
      0.08350085467100143,
      -0.015239899978041649,
      -0.02305496670305729,
      0.006977741606533527,
      0.04855884611606598,
      0.030293051153421402,
      0.02916642837226391,
      -0.055881161242723465,
      0.013393552042543888,
      -0.016774993389844894,
      -0.014926859177649021,
      0.01811804622411728,
      -0.029076699167490005,
      0.024255521595478058,
      -0.05673110485076904,
      -0.006101742386817932,
      0.015407423488795757,
      -0.06606031954288483,
      0.02046944759786129,
      -0.04888399690389633,
      -0.000724265119060874,
      0.04904928803443909,
      0.027199141681194305,
      -0.061007577925920486,
      -0.04432372748851776,
      0.03525932505726814,
      0.12241582572460175,
      0.047574423253536224,
      -0.0013184893177822232,
      -0.09981086850166321,
      0.025501124560832977,
      0.016355033963918686,
      0.021508941426873207,
      -0.02926386520266533,
      0.0587141327559948,
      0.036805085837841034,
      0.03863430395722389,
      0.02767455019056797,
      -3.7695081118727103e-06,
      -0.02519972436130047,
      0.07592575997114182,
      -0.04505453631281853,
      -0.060906533151865005,
      -0.021841974928975105,
      -0.016428625211119652,
      -0.0006352622294798493,
      0.06067643314599991,
      0.04015883803367615,
      0.01227521151304245,
      -0.04149927943944931,
      0.06724865734577179,
      -0.08362165093421936,
      0.06439618766307831,
      0.043259501457214355,
      -0.044726766645908356,
      0.05934344232082367,
      -0.043366093188524246,
      0.0520971454679966,
      -0.03840252012014389,
      -0.03336254507303238,
      -0.061781659722328186,
      0.024992642924189568,
      -0.019095096737146378,
      -0.026532959192991257,
      0.0797811895608902,
      0.027871310710906982,
      0.019857894629240036,
      0.020816655829548836,
      -0.005312898196280003,
      -0.025676490738987923,
      -0.04006767272949219,
      0.04091801866889,
      0.01289959903806448,
      -0.0047810557298362255,
      -0.03298943489789963,
      -0.0708065927028656,
      -0.014402857050299644,
      0.07808109372854233,
      0.08835051953792572,
      0.07671629637479782,
      -0.014040669426321983,
      -0.01318982895463705,
      -0.02653343975543976,
      -0.07551322132349014,
      0.016401220113039017,
      -0.02915452979505062,
      -0.050138071179389954,
      -0.0059380182065069675,
      -0.018329937011003494,
      -0.021586451679468155,
      -0.051801808178424835,
      -0.0005034666392020881,
      -0.0713433101773262,
      0.01311792153865099,
      -0.026298316195607185,
      -0.08967121690511703,
      -0.05456789955496788,
      -0.016883671283721924,
      0.05071399360895157,
      0.006896053906530142,
      -0.004599479027092457,
      -0.02198467217385769,
      0.03384479880332947,
      -0.014342195354402065,
      0.017084313556551933,
      -0.1044512614607811,
      0.0031355612445622683,
      -0.02195126563310623,
      -0.027969274669885635,
      -0.030606035143136978,
      0.01273657102137804,
      0.0566340833902359,
      -0.06310655921697617,
      -0.013610084541141987,
      -0.021527567878365517,
      0.03270537033677101,
      -0.022354913875460625,
      -0.1267504096031189,
      6.58733188174665e-05,
      0.010859182104468346,
      0.05363798141479492,
      0.049740757793188095,
      -0.057978879660367966,
      -0.005854176823049784,
      0.0743151530623436,
      -0.0007351752137765288,
      0.04685850441455841,
      0.0074042160995304585,
      0.0008562819566577673,
      -0.04912378266453743,
      -0.0567849799990654,
      -0.01570151001214981,
      0.014576132409274578,
      -0.05851985141634941,
      0.04064834117889404,
      -0.029716838151216507,
      -0.012500118464231491,
      -0.032295700162649155,
      0.060687724500894547,
      -0.008907275274395943,
      0.016840606927871704,
      0.027971135452389717,
      0.026014098897576332,
      0.04490596428513527,
      -0.003880825126543641,
      0.06406980752944946,
      -0.0067252060398459435,
      0.05830369517207146,
      -0.013333381153643131,
      0.01942228525876999,
      -0.028308458626270294,
      0.0764656513929367,
      0.019853094592690468,
      -0.02429703064262867,
      -0.019736943766474724,
      0.03929611295461655,
      -0.08805135637521744,
      0.05062369257211685,
      0.00873226672410965,
      0.06510136276483536,
      -0.027544528245925903,
      -0.009061072953045368,
      -0.06286253035068512,
      -0.0033971285447478294,
      0.08832041174173355,
      0.008977862074971199,
      0.05426464229822159,
      0.09631938487291336,
      0.014808997511863708,
      0.03120974265038967,
      0.09563261270523071,
      0.10198042541742325,
      0.07487688958644867,
      -0.035626184195280075,
      0.012289093807339668,
      -0.012140898033976555,
      -0.022703612223267555,
      0.0320986770093441,
      0.07996424287557602,
      0.006077054422348738,
      -0.0011585132451727986,
      0.03659793734550476,
      0.038004111498594284,
      0.017388593405485153,
      -0.02289396896958351,
      -0.0012464700266718864,
      0.06980614364147186,
      0.02405085787177086,
      0.02598203718662262,
      -0.05782080814242363,
      0.0035367729142308235,
      0.05833055078983307,
      0.039045993238687515,
      -0.017278077080845833,
      -0.03682917729020119,
      0.013058180920779705,
      -0.00858329702168703,
      -0.036266978830099106,
      -0.057845115661621094,
      0.0364970825612545,
      -0.01696472428739071,
      -0.10362697392702103,
      -0.02120869606733322,
      0.0025023203343153,
      0.026218822225928307,
      -0.1299249231815338,
      -0.07779243588447571,
      0.02629627101123333,
      -0.009973855689167976,
      0.03471209108829498,
      -0.07324586063623428,
      0.012583627365529537,
      0.0312894769012928,
      0.06730414181947708,
      0.054464951157569885,
      0.029480580240488052,
      0.08573570847511292,
      -0.003045008284971118,
      -0.06298302859067917,
      -0.030971648171544075,
      -0.10125352442264557,
      -0.03470594808459282,
      0.012874382548034191,
      0.01744953729212284,
      -0.030685286968946457,
      -0.050808049738407135,
      -0.01619388908147812,
      0.023632843047380447,
      -0.13117443025112152,
      0.04803673177957535,
      0.01974601112306118,
      0.0878012478351593,
      0.05119647830724716,
      0.0072677042335271835,
      0.032190728932619095,
      0.019406046718358994,
      -0.03253210708498955,
      -0.01933860406279564,
      0.00770542211830616,
      -0.012709672562777996,
      -0.06644989550113678,
      -0.0017087535234168172,
      0.03223664313554764,
      0.04744872450828552,
      0.04454652592539787,
      0.04591827094554901,
      -0.04042217507958412,
      -0.04009891673922539,
      -0.08151769638061523,
      -0.031381212174892426,
      -0.10377169400453568,
      0.011707301251590252,
      0.04744913429021835,
      -0.05269742012023926,
      0.0029236243572086096,
      -0.017856134101748466,
      -0.0684361383318901,
      -0.02814299240708351,
      0.059829577803611755,
      -0.06823545694351196,
      -0.06839274615049362,
      0.03440568596124649,
      -0.0256242323666811,
      -0.024588676169514656,
      -0.028722038492560387,
      0.018597949296236038,
      -0.013795542530715466,
      -0.020347343757748604,
      0.06787393987178802,
      -0.09688033163547516,
      -0.023315075784921646,
      -0.027169177308678627,
      -0.049943096935749054,
      -0.03533731400966644,
      -0.025091582909226418,
      7.241356070153415e-05,
      -0.08392550051212311,
      0.02588638663291931,
      0.005685504525899887,
      0.06505371630191803,
      -0.019509805366396904,
      0.07741396129131317,
      -0.05308898910880089,
      -0.10714588314294815,
      -0.04806520789861679,
      0.04410836473107338,
      0.05956713855266571,
      0.04974823445081711,
      0.02939486689865589,
      -0.025075720623135567,
      0.007827960886061192,
      0.07272882014513016,
      -0.07432253658771515,
      0.02998378872871399,
      0.01871730573475361,
      -0.049590934067964554,
      0.04487927630543709,
      0.0018158607417717576,
      -0.013332112692296505,
      -0.0635468140244484,
      -0.04783773049712181,
      -0.024468226358294487,
      -0.01572364941239357,
      -0.05320075899362564,
      -0.01450115256011486,
      -0.018739916384220123,
      0.011241060681641102,
      -0.020345699042081833,
      -0.03494850918650627,
      0.029482191428542137,
      -0.0189823005348444,
      0.09400717169046402,
      -0.08663495630025864,
      -0.02503742091357708,
      -0.002218088135123253,
      -0.08584965020418167,
      -0.06802347302436829,
      0.0017396933399140835,
      -0.0780516117811203,
      0.017684591934084892,
      0.06563401967287064,
      -0.031084636226296425,
      0.0011945257429033518,
      -0.027364030480384827,
      0.05220039561390877,
      0.05023082718253136,
      0.003839768934994936,
      -0.03414922207593918,
      0.028889399021863937,
      0.013083871454000473,
      0.06457165628671646,
      -0.010576287284493446,
      0.09431596100330353,
      -0.06576190143823624,
      0.07026276737451553,
      0.05218842998147011,
      0.0013171522878110409,
      0.007271924987435341,
      0.03397238999605179,
      -0.0633644238114357,
      -0.02307957410812378,
      0.03921809792518616,
      0.04641049727797508,
      0.07226834446191788,
      0.011602888815104961,
      0.00631709722802043,
      0.07377315312623978,
      0.0040727718733251095,
      0.04491502419114113,
      -0.024762243032455444,
      -0.02447020448744297,
      -0.020043514668941498,
      0.03214140236377716,
      0.03079953044652939,
      -0.022643474861979485,
      -0.00985192134976387,
      0.0246588047593832,
      -0.04715845361351967,
      -0.02482009306550026,
      -0.04827090725302696,
      0.057645510882139206,
      -0.08245047926902771,
      -0.0023974268697202206,
      0.020946647971868515,
      0.042376771569252014,
      -0.03829222545027733,
      -0.08594705909490585,
      -0.0018414536025375128,
      0.018719591200351715,
      0.0544656366109848,
      0.07890485227108002,
      -0.030608702450990677,
      -0.013548861257731915,
      0.07726791501045227,
      -0.03926640748977661,
      0.08943755179643631,
      -0.022598931565880775,
      -0.06420380622148514,
      -0.07043254375457764,
      -0.055012572556734085,
      0.06404856592416763,
      0.00613810122013092,
      0.0004972012829966843,
      0.01598777063190937,
      0.030385280027985573,
      -0.042844414710998535,
      0.06251853704452515,
      0.005643216427415609,
      -0.05309124290943146,
      -0.01576182246208191,
      -0.010539712384343147,
      0.03265521302819252,
      -0.0040559424087405205,
      -0.044117797166109085,
      -0.0657520741224289,
      -0.028506694361567497,
      -0.0033086412586271763,
      -0.03668639436364174,
      0.007039469666779041,
      0.008555806241929531,
      0.07746121287345886,
      0.0003230503643862903,
      -0.04647451639175415,
      -0.04167785122990608,
      0.03823714330792427,
      0.02156711183488369,
      0.014420152641832829,
      -0.038766033947467804,
      0.06376751512289047,
      -0.12733960151672363,
      0.0703921988606453,
      0.07255425304174423,
      0.026494093239307404,
      0.047049861401319504,
      -0.05695154517889023,
      0.006720768753439188,
      -0.02295096591114998,
      -0.04002663493156433,
      0.02254490554332733,
      -0.006158872041851282,
      0.001313048298470676,
      0.04483535513281822,
      -0.00847838819026947,
      -0.021065378561615944,
      0.04337931424379349,
      -0.0036065452732145786,
      -0.03153766691684723,
      -0.03461068123579025,
      0.031854379922151566,
      -0.060484349727630615,
      -0.05880149453878403,
      0.013939663767814636,
      -0.036348409950733185,
      -0.040807418525218964,
      -0.02707616239786148,
      -0.016824856400489807,
      -0.0126762380823493,
      -0.02652239240705967,
      -0.05764356628060341,
      -0.0365186408162117,
      0.016668686643242836,
      -0.08992177248001099,
      0.054977867752313614,
      -0.0827661007642746,
      -0.021443676203489304,
      -0.03618272766470909,
      0.05009911209344864,
      0.01382039487361908,
      -0.004319868981838226,
      0.0015481316950172186,
      -0.027392081916332245,
      -0.045482195913791656,
      -0.015671906992793083,
      -0.004385331179946661,
      0.06543430685997009,
      0.10395330935716629,
      -0.03874301537871361,
      -0.01829572767019272,
      -0.05598197877407074,
      0.013787515461444855,
      -0.012159659527242184
    ],
    [
      -0.01089800987392664,
      -0.016581563279032707,
      0.039383240044116974,
      -0.011403916403651237,
      -0.01687627099454403,
      -0.019463634118437767,
      -0.0006947593647055328,
      0.016463667154312134,
      0.04774355888366699,
      -0.02062254771590233,
      0.0006539465975947678,
      -0.02363058365881443,
      0.07318947464227676,
      -0.008984257467091084,
      0.05452090501785278,
      -0.01040874794125557,
      0.061102744191884995,
      0.0530114471912384,
      0.012873007915914059,
      -0.033175501972436905,
      -0.029812177643179893,
      -0.03408258035778999,
      0.025968709960579872,
      0.0158410482108593,
      -0.08774126321077347,
      0.02832384780049324,
      -0.04078173637390137,
      0.0027484132442623377,
      -0.02254834584891796,
      -0.05636515095829964,
      -0.013711527921259403,
      0.036298904567956924,
      0.04104730486869812,
      -0.0013459722977131605,
      -0.061045095324516296,
      -0.028544394299387932,
      0.05929199978709221,
      -0.03832985833287239,
      -0.03261089697480202,
      0.018529973924160004,
      -0.03929881379008293,
      -0.017190277576446533,
      -0.0130385747179389,
      -0.03762557730078697,
      0.06462942063808441,
      0.06917976588010788,
      -0.03437243402004242,
      0.01447679940611124,
      -0.023373788222670555,
      -0.04601942002773285,
      -0.015371294692158699,
      -0.046993229538202286,
      -0.029739217832684517,
      -0.08114206045866013,
      -0.007062801159918308,
      0.05756015330553055,
      -0.03422114625573158,
      -0.03832915425300598,
      0.0427057184278965,
      -0.04796549305319786,
      -0.0038667942862957716,
      -0.061417173594236374,
      0.00832738634198904,
      -0.03425237908959389,
      -0.02910407818853855,
      -0.011089205741882324,
      0.05694137141108513,
      -0.02671804279088974,
      -0.01950058713555336,
      0.004039910156279802,
      0.0054267155937850475,
      -0.02490939199924469,
      0.010879351757466793,
      -0.11777932196855545,
      -0.027252979576587677,
      -0.0063052973710000515,
      -0.015881812199950218,
      -0.0495932511985302,
      -0.0629325807094574,
      0.009237864054739475,
      0.04154122248291969,
      0.03614209219813347,
      -0.03825106844305992,
      -0.030561968684196472,
      0.05155474320054054,
      -0.008923145942389965,
      -0.062055036425590515,
      -0.04194027557969093,
      -0.04710497707128525,
      -0.04037044569849968,
      -0.025770215317606926,
      0.025230851024389267,
      -0.004538358189165592,
      0.01331021822988987,
      -0.022067980840802193,
      0.017684660851955414,
      0.07704738527536392,
      0.027940602973103523,
      0.04108831658959389,
      -0.037228744477033615,
      -0.03691364824771881,
      -0.012453282251954079,
      -0.07238122820854187,
      -0.08940616995096207,
      0.0073929051868617535,
      -0.0710635557770729,
      0.06451389938592911,
      0.06053866446018219,
      -0.00018159554747398943,
      0.002354046795517206,
      -0.01399778388440609,
      -0.04138675332069397,
      0.03520360589027405,
      -0.078448086977005,
      0.0112876882776618,
      0.0793270617723465,
      0.013276679441332817,
      0.04956180974841118,
      0.008448387496173382,
      0.036170151084661484,
      0.010105208493769169,
      0.10398585349321365,
      -0.06713580340147018,
      0.04364170506596565,
      0.0037391672376543283,
      -0.047536030411720276,
      -0.08758366107940674,
      0.03500517085194588,
      -0.07915112376213074,
      -0.0228531826287508,
      -0.008441740646958351,
      -0.03611627593636513,
      0.10535771399736404,
      -0.02311067283153534,
      0.007907403632998466,
      0.012030037119984627,
      -0.014784800820052624,
      0.032762546092271805,
      0.026896700263023376,
      -0.0356094166636467,
      0.016933763399720192,
      0.014571591280400753,
      0.054494649171829224,
      -0.012459687888622284,
      0.016318270936608315,
      -0.02256636507809162,
      0.02035742625594139,
      -0.019768916070461273,
      -0.0469379723072052,
      0.03795160353183746,
      0.037664465606212616,
      0.010413087904453278,
      0.0025695885997265577,
      0.017452223226428032,
      0.04549729451537132,
      -0.022902123630046844,
      0.022709263488650322,
      0.03129837289452553,
      0.05661751329898834,
      -0.049101296812295914,
      0.012331051751971245,
      0.043347734957933426,
      0.06325750797986984,
      -0.08597256243228912,
      -0.030689513310790062,
      -0.09388242661952972,
      0.01050488743931055,
      0.09881741553544998,
      0.0007896535098552704,
      0.0874064564704895,
      0.009706729091703892,
      -0.03734162822365761,
      -0.05640531703829765,
      -0.06611771136522293,
      0.005376112647354603,
      -0.045757461339235306,
      0.07161536812782288,
      0.08312837779521942,
      -0.018486659973859787,
      0.03720781207084656,
      0.0016820551827549934,
      -0.028240738436579704,
      -0.07478998601436615,
      -0.04143974930047989,
      0.10957668721675873,
      -0.007034596521407366,
      0.04529835283756256,
      -0.015279840677976608,
      -0.13092854619026184,
      -0.020057018846273422,
      -0.01758803240954876,
      0.00195589498616755,
      -0.005722961388528347,
      -0.03826555982232094,
      0.016128500923514366,
      0.04933875799179077,
      0.021685175597667694,
      -0.006335712503641844,
      -0.06645520776510239,
      0.08750992268323898,
      0.08124399185180664,
      -0.00046630753786303103,
      0.04939989373087883,
      -0.008619182743132114,
      -0.04494750127196312,
      -0.03289065510034561,
      -0.011265506036579609,
      0.056796807795763016,
      -0.1019928902387619,
      0.03253495320677757,
      -0.0676955133676529,
      -0.019080260768532753,
      -0.03280219808220863,
      0.0314689464867115,
      -0.029035503044724464,
      0.017469029873609543,
      0.1307145208120346,
      -0.015532001852989197,
      -0.051208171993494034,
      0.031783316284418106,
      -0.01289750263094902,
      0.0026218804996460676,
      0.04980164021253586,
      0.08537012338638306,
      -0.007750968914479017,
      0.0217580646276474,
      0.03089832328259945,
      0.023533878847956657,
      0.012646108865737915,
      0.03147876262664795,
      -0.03723646700382233,
      -0.03324224054813385,
      0.06305874139070511,
      0.056826431304216385,
      0.05118068307638168,
      -0.015948396176099777,
      -0.010551643557846546,
      0.0012789089232683182,
      0.05683235451579094,
      0.03602435067296028,
      0.07701591402292252,
      0.005461289081722498,
      0.009256999008357525,
      -0.03427881374955177,
      0.0062287612818181515,
      -0.0023906449787318707,
      -0.08671152591705322,
      -0.03196467086672783,
      0.013947791419923306,
      -0.058052875101566315,
      -0.06275344640016556,
      0.020171208307147026,
      0.1032053530216217,
      -0.018678991124033928,
      -0.014348125085234642,
      -0.0053634317591786385,
      0.04431743547320366,
      -0.10276403278112411,
      -0.03979707509279251,
      -0.014586562290787697,
      -0.022764846682548523,
      0.000687778927385807,
      0.06329983472824097,
      0.021975506097078323,
      -0.04046277701854706,
      -0.09204094856977463,
      0.03035305067896843,
      0.08667110651731491,
      0.0473000593483448,
      0.01926531083881855,
      -0.056171610951423645,
      -0.03064781241118908,
      -0.03230546414852142,
      0.08903118222951889,
      -0.0007965778349898756,
      -0.006245112046599388,
      -0.029657892882823944,
      -0.014904588460922241,
      -0.04946543648838997,
      -0.016582362353801727,
      0.004209115169942379,
      0.01511618122458458,
      0.004358155187219381,
      0.1032039001584053,
      0.10460710525512695,
      0.029715945944190025,
      -0.029295381158590317,
      0.11016304790973663,
      -0.022339366376399994,
      -0.03620455414056778,
      -0.031724169850349426,
      0.01290083583444357,
      -0.02867097035050392,
      -0.038694921880960464,
      0.03638960421085358,
      0.014986993744969368,
      -0.06539631634950638,
      0.01086948812007904,
      -0.06865441054105759,
      0.06305524706840515,
      -0.010036639869213104,
      0.0484921894967556,
      -0.014266903512179852,
      0.00820942223072052,
      0.030424274504184723,
      -0.07107237726449966,
      -0.08446428179740906,
      0.07652916014194489,
      -0.07205203175544739,
      0.04271119460463524,
      0.05319133773446083,
      0.00743482168763876,
      -0.01742287538945675,
      0.04844213277101517,
      0.022648515179753304,
      -0.09312594681978226,
      0.06508465856313705,
      0.01608039066195488,
      0.07631367444992065,
      -0.013061859644949436,
      0.03603324294090271,
      0.0072310627438127995,
      -0.0027458935510367155,
      0.04804258048534393,
      0.04412831738591194,
      0.05475623160600662,
      -0.004760270472615957,
      -0.02819288708269596,
      0.008696092292666435,
      -0.017575204372406006,
      0.0057009486481547356,
      0.032139889895915985,
      -0.017906561493873596,
      0.03748249635100365,
      0.012524052523076534,
      -0.02363649755716324,
      -0.04968925938010216,
      -0.039698004722595215,
      -0.02901267260313034,
      -0.04189152270555496,
      -0.014234771020710468,
      -0.03128863498568535,
      0.017906643450260162,
      0.008942507207393646,
      0.004937541671097279,
      -0.0009838136611506343,
      -0.022611867636442184,
      0.0026944552082568407,
      -0.126067116856575,
      -0.03676414489746094,
      0.006822257302701473,
      -0.016578346490859985,
      0.003982777241617441,
      -0.04049953818321228,
      0.030796591192483902,
      0.05014024302363396,
      -0.008556141518056393,
      -0.07728280872106552,
      0.00887126661837101,
      -0.02357812039554119,
      0.04347274452447891,
      -0.01967741549015045,
      0.01905507780611515,
      -0.004795633722096682,
      0.014322006143629551,
      -0.005975630600005388,
      -0.0032562525011599064,
      0.03337208554148674,
      0.03089705854654312,
      0.009291327558457851,
      0.027490001171827316,
      -0.07486443221569061,
      0.011744492687284946,
      -0.002532999264076352,
      0.020384211093187332,
      -0.038996372371912,
      0.06269722431898117,
      -0.029259875416755676,
      0.003880194155499339,
      0.012215161696076393,
      0.007120922673493624,
      0.040839094668626785,
      -0.06096576154232025,
      0.07267307490110397,
      -0.0010081327054649591,
      0.0552021749317646,
      0.02898578718304634,
      0.022103168070316315,
      0.0017981149721890688,
      -0.027593601495027542,
      -0.005722619593143463,
      0.022312607616186142,
      -0.06472369283437729,
      0.027658026665449142,
      0.02232978865504265,
      0.0613042414188385,
      -0.07636331021785736,
      0.0003951849357690662,
      -0.0627485141158104,
      -0.060621194541454315,
      -0.0038656084798276424,
      -0.08066858351230621,
      0.0004884208901785314,
      -0.02739635854959488,
      -0.022343099117279053,
      -0.08953147381544113,
      -0.0014493224443867803,
      0.06824066489934921,
      -0.026694342494010925,
      0.059915006160736084,
      -0.05413053557276726,
      -0.055847033858299255,
      -0.0037446925416588783,
      0.020845673978328705,
      -0.06301969289779663,
      0.013579688034951687,
      -0.023519176989793777,
      0.030727699398994446,
      0.02137800119817257,
      -0.005820821039378643,
      -0.003804305102676153,
      -0.008090032264590263,
      0.07324594259262085,
      -0.014913671649992466,
      -0.0476534441113472,
      -0.03354182839393616,
      0.07417817413806915,
      -0.004124624654650688,
      -0.03982541710138321,
      0.039298851042985916,
      -0.003655367763713002,
      -0.023845920339226723,
      -0.01679842360317707,
      0.014158220961689949,
      -0.0159356240183115,
      -0.019677288830280304,
      -0.04982753470540047,
      -0.01065016258507967,
      0.032486382871866226,
      -0.02904343418776989,
      0.01576000452041626,
      0.03607972711324692,
      -0.052534069865942,
      -0.043452903628349304,
      0.0547218918800354,
      -0.09193763881921768,
      0.06486959010362625,
      0.00848805345594883,
      0.011247091926634312,
      0.0223724115639925,
      0.015245522372424603,
      0.0017683642217889428,
      -0.02743985503911972,
      0.04510797932744026,
      0.05113079771399498,
      0.01681084930896759,
      -0.013755585998296738,
      0.015950003638863564,
      -0.11865978688001633,
      0.04718014970421791,
      0.060924362391233444,
      -0.06234259158372879,
      0.008611968718469143,
      0.013900416903197765,
      0.0023470930755138397,
      0.03181269392371178,
      -0.047491539269685745,
      0.00021467379701789469,
      0.021420644596219063,
      -0.049162063747644424,
      0.015906760469079018,
      0.02093861624598503,
      0.017566440626978874,
      -0.00011909408931387588,
      -0.047947224229574203,
      0.029543695971369743,
      -0.012197630479931831,
      -0.019244661554694176,
      0.022272298112511635,
      0.03903449326753616,
      -0.04512535780668259,
      0.05078905448317528,
      0.0487760528922081,
      0.013566276989877224,
      -0.020133905112743378,
      0.0033545871265232563,
      -0.047987233847379684,
      0.02293759025633335,
      -0.036167316138744354,
      -0.031069524586200714,
      -0.013601230457425117,
      -0.001988520845770836,
      0.04677411913871765,
      -0.057072963565588,
      0.040084827691316605,
      0.005208349786698818,
      0.038506876677274704,
      0.029731623828411102,
      -0.00544141698628664,
      -0.02833530120551586,
      0.004518546629697084,
      0.06345327943563461,
      -0.0017508184537291527
    ],
    [
      0.0071610878221690655,
      -0.06061355024576187,
      -0.038063894957304,
      -0.06813891977071762,
      0.023942986503243446,
      0.006689898669719696,
      0.07051438838243484,
      -0.03331742063164711,
      0.05645066127181053,
      -0.0050799050368368626,
      -0.07725193351507187,
      0.05664155259728432,
      -0.0103196632117033,
      0.004615373443812132,
      0.03552032262086868,
      0.030964531004428864,
      -0.009021437726914883,
      0.007608788553625345,
      -0.0029595920350402594,
      0.040073927491903305,
      0.04113135486841202,
      0.09000027924776077,
      0.05810866504907608,
      0.010012396611273289,
      -0.10480905324220657,
      0.026455804705619812,
      -0.020881880074739456,
      0.01987619884312153,
      0.05602362006902695,
      0.032616861164569855,
      -0.0011328221298754215,
      0.0022184087429195642,
      -0.03751629218459129,
      -0.08589538931846619,
      0.05415136367082596,
      -0.02940409816801548,
      0.03374006971716881,
      -0.026709934696555138,
      0.043470825999975204,
      0.04567921534180641,
      0.0683322623372078,
      0.03387509658932686,
      -0.07700512558221817,
      0.04399498924612999,
      -0.046095456928014755,
      -0.025137871503829956,
      0.015427025966346264,
      0.009253877215087414,
      -0.047390930354595184,
      0.01906713843345642,
      -0.023830510675907135,
      0.058062516152858734,
      -0.0635547935962677,
      0.04448156803846359,
      -0.01883406564593315,
      0.0007353906403295696,
      -0.012299680151045322,
      -0.003165735863149166,
      0.004807355813682079,
      -0.012332473881542683,
      -0.015306473709642887,
      0.01889856718480587,
      -0.02206459455192089,
      -0.013781043700873852,
      -0.08039247989654541,
      0.03212323039770126,
      -0.06637854129076004,
      0.06571370363235474,
      -0.002385260770097375,
      -0.04446638748049736,
      0.02483403868973255,
      -0.08039194345474243,
      0.06633025407791138,
      -0.04519070312380791,
      -0.0006864802562631667,
      0.030700532719492912,
      0.0018690101569518447,
      0.0803675428032875,
      -0.04384211078286171,
      -0.028556786477565765,
      0.009924923069775105,
      0.055005040019750595,
      0.003792905481532216,
      0.08020725846290588,
      -0.0004385921929497272,
      0.011406286619603634,
      0.00874052383005619,
      0.03333720192313194,
      -0.0052668084390461445,
      -0.011750167235732079,
      0.010223614051938057,
      0.05640508234500885,
      0.045791056007146835,
      -0.027300916612148285,
      -0.03624016419053078,
      0.03193683922290802,
      -0.05157799646258354,
      -0.10582467168569565,
      -0.045950740575790405,
      -0.012484021484851837,
      0.03398268297314644,
      -0.05560361593961716,
      -0.04330098256468773,
      -0.0314912311732769,
      0.15600687265396118,
      0.06271196901798248,
      -0.01797945611178875,
      -0.0028205090202391148,
      -0.0014153904048725963,
      -0.02802702970802784,
      0.026363203302025795,
      -0.06078954041004181,
      -0.020415032282471657,
      0.034429486840963364,
      -0.01505711954087019,
      -0.0060045006684958935,
      0.016771629452705383,
      -0.01379304751753807,
      0.04763205721974373,
      0.04780827462673187,
      0.04071121662855148,
      -0.030918290838599205,
      0.04029124602675438,
      0.04739558324217796,
      -0.0328451432287693,
      -0.08335193246603012,
      -0.0031981724314391613,
      0.00903582014143467,
      -0.009583628736436367,
      0.021937593817710876,
      0.02992493100464344,
      -0.01046226266771555,
      -0.06537669152021408,
      0.0035795781295746565,
      0.007356083951890469,
      0.03286775201559067,
      0.020533736795186996,
      -0.003718653228133917,
      -0.014730271883308887,
      0.01754351705312729,
      0.0034628077410161495,
      0.045338526368141174,
      0.06816713511943817,
      -0.024580731987953186,
      -0.00598337035626173,
      0.04622954502701759,
      0.08932467550039291,
      -0.009968675673007965,
      -0.0321355015039444,
      -0.0073903645388782024,
      -0.012847917154431343,
      -0.01648341305553913,
      0.053215671330690384,
      -0.010697543621063232,
      0.039540357887744904,
      0.0166255421936512,
      0.039317261427640915,
      0.032090652734041214,
      -0.03050852194428444,
      -0.013497069478034973,
      -0.07139955461025238,
      0.014040889218449593,
      0.030256099998950958,
      0.0072306483052670956,
      -0.061459168791770935,
      -0.09632982313632965,
      0.045102741569280624,
      0.023596813902258873,
      -0.06156367436051369,
      -0.046642888337373734,
      -0.1101473867893219,
      0.017666490748524666,
      -0.03481282293796539,
      -0.06465256214141846,
      0.03512261435389519,
      0.0433177575469017,
      -0.010874909348785877,
      -0.035151947289705276,
      0.023029852658510208,
      0.008888475596904755,
      -0.006007001269608736,
      0.042902130633592606,
      0.08956138789653778,
      -0.05033024773001671,
      -0.04635804146528244,
      -0.0646757036447525,
      -0.012542694807052612,
      0.006562371738255024,
      -0.007454980164766312,
      0.01694800704717636,
      0.043063968420028687,
      0.011656451970338821,
      -0.03913692384958267,
      0.020791834220290184,
      -0.014462481252849102,
      0.04142399877309799,
      -0.11065125465393066,
      0.03593004122376442,
      0.05438860133290291,
      0.08718838542699814,
      0.02942618727684021,
      0.05866536125540733,
      0.10462886095046997,
      -0.08106555789709091,
      -0.02023802511394024,
      0.044618137180805206,
      0.06685658544301987,
      -0.008612961508333683,
      -0.041115064173936844,
      -0.04042638838291168,
      0.04399479925632477,
      0.020724037662148476,
      -0.08962777256965637,
      -0.13406972587108612,
      0.031926967203617096,
      0.013430749997496605,
      -0.00927942804992199,
      -0.05619489774107933,
      0.008262874558568,
      0.05185026675462723,
      -0.018539849668741226,
      0.024311164394021034,
      0.01830463856458664,
      -0.07351185381412506,
      -0.000999448006041348,
      -0.011788619682192802,
      0.03460704907774925,
      0.04238767549395561,
      0.05397047847509384,
      0.05236987769603729,
      -0.04142497479915619,
      -0.0043270220048725605,
      -0.022402122616767883,
      -0.047087740153074265,
      0.03752054274082184,
      -0.009434317238628864,
      -0.002434664871543646,
      -0.013200890272855759,
      0.044481247663497925,
      -0.03081510402262211,
      -0.0613982118666172,
      -0.007628682069480419,
      -0.015070237219333649,
      0.017891274765133858,
      0.005219507031142712,
      -0.008679821155965328,
      0.024723412469029427,
      -0.059774018824100494,
      0.00619040010496974,
      -0.001992319244891405,
      -0.07606589794158936,
      0.08396720141172409,
      0.05862775072455406,
      0.08564751595258713,
      -0.007173139601945877,
      0.045666664838790894,
      0.0760219544172287,
      0.0941973552107811,
      -0.01633192040026188,
      0.05614985153079033,
      -0.04691370204091072,
      -0.01577501744031906,
      0.08569277077913284,
      -0.02262064442038536,
      -0.06288014352321625,
      -0.051687903702259064,
      -0.015544427558779716,
      -0.08525644987821579,
      0.04086524248123169,
      0.02173217013478279,
      0.05536479502916336,
      0.05808132886886597,
      0.008217846043407917,
      0.033408716320991516,
      0.025058984756469727,
      -0.02095676027238369,
      0.01118412148207426,
      -0.05172528699040413,
      -0.03224092349410057,
      -0.002091694390401244,
      0.06425324827432632,
      0.04143504425883293,
      0.01258647907525301,
      -0.02847938984632492,
      -0.044410642236471176,
      0.06993556767702103,
      0.02572612836956978,
      0.01902163028717041,
      -0.005024889949709177,
      -0.03224237635731697,
      -0.03612983226776123,
      0.006158370058983564,
      -0.0768924355506897,
      -0.023959245532751083,
      0.011986569501459599,
      0.03057669661939144,
      0.09290048480033875,
      0.02783128432929516,
      0.013012907467782497,
      -0.0439697802066803,
      -0.007674912456423044,
      0.07527022063732147,
      0.01901593990623951,
      -0.010990864597260952,
      0.039672642946243286,
      -0.04355233535170555,
      0.04527606442570686,
      0.007644333876669407,
      0.02115599811077118,
      -0.06517437845468521,
      0.02661355398595333,
      -0.003161828499287367,
      0.016497928649187088,
      -0.008538334630429745,
      -0.0006125582731328905,
      0.0020771408453583717,
      0.0019634950440376997,
      0.026586173102259636,
      -0.014365468174219131,
      0.04776248335838318,
      -0.02314216084778309,
      0.058589253574609756,
      0.03303312510251999,
      0.07126185297966003,
      0.018108675256371498,
      -0.0009237351478077471,
      -0.004186536185443401,
      -0.0032696735579520464,
      0.02090398594737053,
      0.03005732223391533,
      -0.04281565919518471,
      0.06650058925151825,
      -0.024919802322983742,
      0.04697950556874275,
      0.045814868062734604,
      0.021932749077677727,
      -0.029491445049643517,
      -0.04760335385799408,
      -0.019808785989880562,
      0.056377824395895004,
      -0.09392654895782471,
      -0.012917849235236645,
      -0.05326181277632713,
      0.028560500591993332,
      0.016707098111510277,
      -0.031036820262670517,
      0.045766159892082214,
      -0.020477550104260445,
      -0.10940531641244888,
      0.0353776179254055,
      -0.027986692264676094,
      0.004058304242789745,
      -0.04087413474917412,
      0.01413679774850607,
      -0.01854747161269188,
      0.017246704548597336,
      0.025188611820340157,
      0.040184542536735535,
      0.02739689126610756,
      0.044817887246608734,
      -0.0015590065158903599,
      0.06901600956916809,
      -0.01927400752902031,
      -0.024058284237980843,
      0.0018468137132003903,
      -0.07742636650800705,
      0.0691726878285408,
      0.02723417617380619,
      -0.027981849387288094,
      0.01287259254604578,
      0.04024669528007507,
      0.0063119810074567795,
      0.021820710971951485,
      -0.00399781484156847,
      0.008129251189529896,
      -0.03061171993613243,
      0.018809136003255844,
      -0.0389358326792717,
      -0.035819441080093384,
      -0.11071348935365677,
      -0.017568450421094894,
      -0.05781903862953186,
      0.07210499048233032,
      0.008018942549824715,
      -0.007413218263536692,
      0.04923620820045471,
      0.014665675349533558,
      -0.013045359402894974,
      -6.801389827160165e-05,
      -0.03971792757511139,
      -0.06899341195821762,
      0.06731695681810379,
      -0.03226538002490997,
      0.05661947280168533,
      0.024949204176664352,
      0.0028320509009063244,
      0.09238432347774506,
      0.059995561838150024,
      0.08047714084386826,
      -0.0172173660248518,
      0.012530895881354809,
      0.028587564826011658,
      0.010981210507452488,
      -0.056474119424819946,
      -0.025830652564764023,
      -0.004090121481567621,
      0.026913132518529892,
      0.003399141598492861,
      -0.0341792032122612,
      -0.0419330894947052,
      0.02611832693219185,
      0.04206174239516258,
      -0.020875215530395508,
      0.024684559553861618,
      0.01652672328054905,
      -0.05268540233373642,
      0.04995383322238922,
      -0.00826727319508791,
      -0.03311055153608322,
      -0.053508758544921875,
      -0.010730989277362823,
      -0.07995546609163284,
      -0.0037742480635643005,
      -0.05270005017518997,
      -0.02693789079785347,
      -0.04659001901745796,
      0.09174206852912903,
      -0.029909400269389153,
      0.006811374798417091,
      0.0009412273648194969,
      -0.024667982012033463,
      0.016082918271422386,
      0.022989856079220772,
      -0.1147807389497757,
      -0.040811214596033096,
      -0.05181075260043144,
      -0.05551110953092575,
      0.010083924978971481,
      -0.04330858960747719,
      0.027455346658825874,
      0.01422206312417984,
      0.015800846740603447,
      -0.04136396944522858,
      0.03752700984477997,
      0.0033518478740006685,
      -0.0680815652012825,
      0.06305033713579178,
      0.031935326755046844,
      -0.023505188524723053,
      -0.01700347475707531,
      -0.054908186197280884,
      0.04585568234324455,
      0.05787649750709534,
      0.053685590624809265,
      -0.07586195319890976,
      -0.040506262332201004,
      0.07561251521110535,
      0.050837088376283646,
      -0.01998310536146164,
      -0.012763749808073044,
      0.01755269058048725,
      -0.0165565125644207,
      -0.05284275487065315,
      -0.0035208810586482286,
      -0.006959333084523678,
      -0.018062008544802666,
      0.030809903517365456,
      0.00751294381916523,
      -0.009656518697738647,
      -0.043086159974336624,
      0.006777058355510235,
      -0.05300319939851761,
      0.012657997198402882,
      -0.007994408719241619,
      0.03448580205440521,
      0.1372893899679184,
      0.06143216788768768,
      -0.015302125364542007,
      0.019827982410788536,
      0.0027029630728065968,
      -0.015591662377119064,
      0.0007605196442455053,
      0.04817023500800133,
      -0.0011123219737783074,
      -0.033859752118587494,
      0.006328599061816931,
      -0.018348949030041695,
      0.024420123547315598,
      -0.008993870578706264,
      -0.022717399522662163,
      -0.04377296566963196,
      -0.02468952350318432,
      -0.0040191588923335075,
      0.014894840307533741,
      0.03233344480395317,
      0.02818957157433033,
      0.0035842773504555225,
      -0.0013634070055559278,
      0.05367438495159149,
      -0.005314444657415152,
      -0.014116375707089901,
      -0.020156966522336006,
      -0.15154767036437988
    ],
    [
      0.017299829050898552,
      -0.06564079225063324,
      -0.012894725427031517,
      -0.01964178867638111,
      0.02754196710884571,
      0.0029145656153559685,
      -0.01587621308863163,
      -0.03944425657391548,
      0.13211654126644135,
      0.024837130680680275,
      0.02413037233054638,
      -0.02817034162580967,
      -0.16643506288528442,
      -0.08611975610256195,
      -0.007099006790667772,
      0.08205623924732208,
      0.003750246251001954,
      -0.0004206571902614087,
      0.0024903644807636738,
      0.03843076899647713,
      0.0188890490680933,
      0.06567130982875824,
      0.0056573692709207535,
      0.013476066291332245,
      -0.030407031998038292,
      -0.042200781404972076,
      0.037850521504879,
      0.023734811693429947,
      -0.016411494463682175,
      -0.008483120240271091,
      -0.04777491092681885,
      0.04619452729821205,
      -0.06017943471670151,
      -0.09694329649209976,
      0.010223162360489368,
      -0.04812871292233467,
      -0.01136490423232317,
      0.046500567346811295,
      0.040087249130010605,
      -0.03945805877447128,
      0.04603463411331177,
      -0.013372015208005905,
      -0.01900043524801731,
      0.0034348696935921907,
      0.003936070948839188,
      0.0035188719630241394,
      -0.01563485711812973,
      0.014926545321941376,
      0.033527277410030365,
      -0.06813140958547592,
      -0.004232581704854965,
      -0.05158643797039986,
      -0.009073743596673012,
      -0.08332144469022751,
      -0.005526870489120483,
      0.13192543387413025,
      -0.0027041982393711805,
      0.04722896218299866,
      0.022243326529860497,
      0.006856511812657118,
      -0.0014937393134459853,
      0.029975371435284615,
      -0.004441117402166128,
      0.023328961804509163,
      -0.06248069182038307,
      0.07976830005645752,
      0.031693946570158005,
      -0.030509762465953827,
      0.021754559129476547,
      0.003483789274469018,
      0.020351748913526535,
      -0.011549053713679314,
      -0.10255811363458633,
      0.06296485662460327,
      -0.04036501422524452,
      0.042504750192165375,
      0.061658814549446106,
      0.03445343300700188,
      0.005785193294286728,
      -0.007565338630229235,
      -0.03146354854106903,
      0.07671037316322327,
      0.04429725930094719,
      0.10894928127527237,
      -0.02393786795437336,
      0.00433396315202117,
      0.07798561453819275,
      -0.005072126165032387,
      0.09517299383878708,
      -0.08052395284175873,
      0.027752846479415894,
      -0.027268894016742706,
      0.07987339049577713,
      0.044434819370508194,
      -0.06225518882274628,
      -0.04255939647555351,
      0.0943296030163765,
      0.014073958620429039,
      0.12246138602495193,
      0.03991785645484924,
      0.025040043517947197,
      -0.0787448137998581,
      0.05850065499544144,
      0.01839091256260872,
      -0.08423224836587906,
      0.01705358177423477,
      0.05505174770951271,
      0.06715146452188492,
      0.026059474796056747,
      0.13552901148796082,
      -0.025028862059116364,
      0.05830201879143715,
      0.05251457169651985,
      -0.042006608098745346,
      0.05940237268805504,
      0.03402196615934372,
      0.04381343349814415,
      0.002463592914864421,
      -0.058138344436883926,
      -0.015310083515942097,
      0.11366131901741028,
      0.08300482481718063,
      0.005652192980051041,
      0.02173776552081108,
      0.00881693884730339,
      -0.0024927814956754446,
      0.012228621169924736,
      0.062395237386226654,
      0.003629455342888832,
      -0.0018377701053395867,
      -0.009274395182728767,
      -0.0625545084476471,
      -0.07678604871034622,
      -0.07129766792058945,
      0.002776088658720255,
      -0.0687451884150505,
      -0.01972425915300846,
      -0.03184693306684494,
      -0.009612496942281723,
      0.07917594164609909,
      -0.014432352967560291,
      0.018800979480147362,
      -0.018272558227181435,
      0.02227294258773327,
      0.05935093015432358,
      -0.02913442999124527,
      0.02590671367943287,
      0.007907168008387089,
      -0.01062820479273796,
      -0.045984067022800446,
      0.07793385535478592,
      -0.034832801669836044,
      0.04806794598698616,
      0.017087068408727646,
      0.046999793499708176,
      0.0476393848657608,
      0.003012544009834528,
      0.04089660197496414,
      0.10131717473268509,
      -0.007294852286577225,
      -0.03707674518227577,
      0.01692880690097809,
      -0.029259568080306053,
      -0.04691386967897415,
      0.027836648747324944,
      0.06446991115808487,
      -0.005494668148458004,
      -0.05704434961080551,
      -0.05622151121497154,
      -0.042757272720336914,
      -0.07568718492984772,
      0.012068898417055607,
      0.041828855872154236,
      -0.022770343348383904,
      -0.037095099687576294,
      0.026042860001325607,
      -0.0016642868285998702,
      -0.13378633558750153,
      0.004257957451045513,
      -0.012116184458136559,
      -0.028790630400180817,
      0.02229546196758747,
      0.027809983119368553,
      0.021885227411985397,
      0.01369561068713665,
      0.058403365314006805,
      0.018222197890281677,
      0.01825551874935627,
      -0.0023166919127106667,
      -0.061215516179800034,
      -0.028307050466537476,
      0.013454136438667774,
      0.025145649909973145,
      0.10059259086847305,
      0.05355570837855339,
      0.029342755675315857,
      0.06571370363235474,
      -0.010000908747315407,
      -0.05816340073943138,
      0.023604407906532288,
      -0.01691809855401516,
      -0.08220848441123962,
      -0.025688709691166878,
      -0.0005201370804570615,
      0.03738366812467575,
      -0.06515160202980042,
      0.03618813678622246,
      0.028326191008090973,
      -0.03923801705241203,
      0.02003445103764534,
      -0.02085399255156517,
      0.004684662912040949,
      -0.038625359535217285,
      -0.008329414762556553,
      -0.03205135092139244,
      -0.004095631651580334,
      0.005816321354359388,
      -0.00755049753934145,
      -0.019118158146739006,
      -0.016965782269835472,
      0.0314137302339077,
      0.016246920451521873,
      0.03433989733457565,
      -0.013319924473762512,
      -0.00600205734372139,
      0.05158504843711853,
      0.042632684111595154,
      0.01573140174150467,
      -0.008270705118775368,
      0.050949182361364365,
      -0.027362819761037827,
      0.042123693972826004,
      -0.025985777378082275,
      -0.04669515788555145,
      -0.040002867579460144,
      -0.05816900357604027,
      -0.1031024158000946,
      -0.05670706555247307,
      -0.011707671917974949,
      0.014201073907315731,
      0.10664620995521545,
      0.0037313858047127724,
      -0.00619710236787796,
      0.047302961349487305,
      -0.07733860611915588,
      0.02506006509065628,
      -0.007249413523823023,
      -0.02615024894475937,
      -0.037555187940597534,
      -0.0381147675216198,
      -0.020997202023863792,
      -0.03399832546710968,
      0.00641958462074399,
      -0.045779384672641754,
      0.015335782431066036,
      0.03961332514882088,
      0.03608299791812897,
      0.028314189985394478,
      0.01303164567798376,
      0.009170169942080975,
      -0.034676097333431244,
      0.05326355621218681,
      -0.03534160926938057,
      -0.0010276834946125746,
      -0.02904309518635273,
      -0.006183084100484848,
      -0.030286012217402458,
      0.049172915518283844,
      -0.022751791402697563,
      -0.07661841064691544,
      -0.007036385126411915,
      0.004866923205554485,
      0.005354694556444883,
      0.03213091194629669,
      -0.015266289003193378,
      0.025864791125059128,
      0.054527997970581055,
      0.0576271116733551,
      -0.03410216420888901,
      -0.023294523358345032,
      -0.0044809067621827126,
      -0.01668013259768486,
      0.0008245254866778851,
      -0.06484504789113998,
      -0.06189396232366562,
      0.0036596590653061867,
      -0.023547181859612465,
      -0.031885214149951935,
      -0.01910565234720707,
      -0.04112841561436653,
      -0.09623266756534576,
      -0.04060222953557968,
      0.07348764687776566,
      0.011767015792429447,
      -0.03557031974196434,
      -0.024628162384033203,
      -0.046828966587781906,
      0.002495605032891035,
      0.0748448595404625,
      -0.04509647563099861,
      0.034460488706827164,
      -0.019572071731090546,
      0.020789608359336853,
      0.056366752833127975,
      -0.0860373005270958,
      -0.02887481264770031,
      0.08456680178642273,
      0.02692548930644989,
      -0.022314753383398056,
      0.07908208668231964,
      0.08470619469881058,
      0.03418585658073425,
      -0.010637974366545677,
      0.01243025902658701,
      0.05407050624489784,
      -0.061962105333805084,
      0.025760827586054802,
      0.009896310046315193,
      0.04198777675628662,
      -0.008421248756349087,
      -0.047638554126024246,
      -0.06303147971630096,
      -0.0056000202894210815,
      0.025026045739650726,
      0.009709160774946213,
      0.00787470955401659,
      0.03883959725499153,
      -0.012444869615137577,
      0.10804243385791779,
      0.043277278542518616,
      -0.07687977701425552,
      -0.09299077093601227,
      0.006106743589043617,
      0.03940228372812271,
      0.012899475172162056,
      -0.040971674025058746,
      -0.06442359834909439,
      -0.0029877997003495693,
      -0.016873199492692947,
      0.02917582541704178,
      -0.05002596974372864,
      -0.06795098632574081,
      0.03155134245753288,
      -0.03138721361756325,
      -0.05610118433833122,
      0.05010424181818962,
      0.06899114698171616,
      -0.06151726096868515,
      0.031796589493751526,
      -0.0018714278703555465,
      -0.07174542546272278,
      -0.05035722255706787,
      -0.08460486680269241,
      -0.047723568975925446,
      -0.05035565420985222,
      0.012610029429197311,
      -0.014772784896194935,
      0.00010590761667117476,
      -0.00467928871512413,
      0.01588493585586548,
      -0.02017631195485592,
      -0.025175534188747406,
      0.04006781801581383,
      -0.05593200773000717,
      -0.004452827386558056,
      0.07201193273067474,
      -0.025305630639195442,
      0.02147606573998928,
      -0.052741967141628265,
      -0.08045607060194016,
      -0.011749154888093472,
      0.004886641167104244,
      -0.00722213089466095,
      0.04769136384129524,
      0.002155622933059931,
      -0.002529561286792159,
      -0.06604477018117905,
      0.06199928745627403,
      -0.02756357565522194,
      0.04826204851269722,
      -0.04874187707901001,
      0.030254965648055077,
      0.005088909529149532,
      -0.024875657632946968,
      -0.0077969105914235115,
      -0.02968355268239975,
      -0.012407605536282063,
      -0.07173190265893936,
      -0.04582474008202553,
      -0.046987634152173996,
      -0.0128251314163208,
      0.06797441840171814,
      -0.01506717037409544,
      -0.0028670127503573895,
      -0.02232801541686058,
      0.08786685764789581,
      -0.11440937966108322,
      -0.005036652088165283,
      0.11938166618347168,
      0.04483598843216896,
      0.03677557408809662,
      -0.02780202031135559,
      -0.05897503346204758,
      0.06350916624069214,
      0.006740564946085215,
      -0.07615464925765991,
      0.03264288231730461,
      -0.021442245692014694,
      -0.007226445712149143,
      -0.057343341410160065,
      0.023659829050302505,
      0.027491185814142227,
      -0.00652342289686203,
      0.09737235307693481,
      0.03335927799344063,
      0.038800518959760666,
      -0.024415362626314163,
      0.006319691427052021,
      -0.0007644911529496312,
      -0.04184383526444435,
      0.039231568574905396,
      0.011244448833167553,
      -0.06472602486610413,
      -0.04309902712702751,
      0.020872874185442924,
      -0.008269185200333595,
      0.0671556293964386,
      -0.03370274230837822,
      0.1393040418624878,
      0.02944621443748474,
      0.04758407175540924,
      -0.01727500557899475,
      -0.07466739416122437,
      -0.08275768905878067,
      0.045512374490499496,
      -0.011475324630737305,
      -0.030437082052230835,
      0.03201044723391533,
      0.00046984385699033737,
      -0.01528642326593399,
      0.002873474732041359,
      -0.017657384276390076,
      -0.01286428514868021,
      -0.016256649047136307,
      0.0908806324005127,
      -0.04571765661239624,
      -0.0021219956688582897,
      0.07159751653671265,
      0.027384506538510323,
      -0.021823877468705177,
      0.016129717230796814,
      0.044831134378910065,
      0.05842100456357002,
      0.0017461103852838278,
      0.09091079235076904,
      0.08330315351486206,
      -0.05425947904586792,
      -0.0072310930117964745,
      -0.06136779859662056,
      -0.015280124731361866,
      -0.03425276279449463,
      0.01029412541538477,
      0.04351874440908432,
      0.07079725712537766,
      -0.01808137260377407,
      0.052146680653095245,
      -0.038269273936748505,
      0.06132710352540016,
      0.03164246678352356,
      -0.0033150948584079742,
      -0.07514075189828873,
      0.016763566061854362,
      0.01093167345970869,
      -0.056550826877355576,
      0.025948433205485344,
      0.07769087702035904,
      0.009325534105300903,
      -0.034890905022621155,
      0.003713282523676753,
      -0.013597178272902966,
      -0.03268647566437721,
      -0.02044020965695381,
      -0.04854864627122879,
      0.06742467731237411,
      0.03253336623311043,
      -0.039841845631599426,
      -0.03210380673408508,
      -0.07914647459983826,
      -0.006734107621014118,
      0.024649199098348618,
      0.03684826195240021,
      -0.09220507740974426,
      -0.016944408416748047,
      0.03750808164477348,
      -0.020361941307783127,
      -0.014162851497530937,
      0.03235350921750069,
      -0.02023644745349884,
      0.013336691074073315,
      -0.03686608374118805,
      0.029597308486700058,
      0.033261705189943314,
      -0.0815429612994194
    ],
    [
      -0.001993755344301462,
      -0.030283469706773758,
      -0.04126512259244919,
      -0.0733364149928093,
      0.022954648360610008,
      -0.009718809276819229,
      0.0030449661426246166,
      0.003583422861993313,
      0.004242461174726486,
      0.05365537106990814,
      -0.024595852941274643,
      -0.01832486502826214,
      -0.06679369509220123,
      -0.08815315365791321,
      -0.008578532375395298,
      -0.0917944610118866,
      0.0396026112139225,
      -0.0819244384765625,
      0.06317262351512909,
      -0.06381145119667053,
      -0.035550981760025024,
      0.08853917568922043,
      0.029695114120841026,
      -0.010702024213969707,
      0.011656294576823711,
      0.043878503143787384,
      0.016559455543756485,
      -0.004793907515704632,
      -0.024964986369013786,
      0.061134908348321915,
      0.0479203462600708,
      -0.026567988097667694,
      -0.04156947880983353,
      -0.037824176251888275,
      -0.03586622700095177,
      -0.014852515421807766,
      0.0037168418057262897,
      0.03380025923252106,
      0.04002665355801582,
      -0.06899694353342056,
      0.020443961024284363,
      0.03514968603849411,
      0.04820803925395012,
      -0.03551238030195236,
      0.05199146643280983,
      -0.06366759538650513,
      0.0843796506524086,
      -0.13403725624084473,
      -0.01086397934705019,
      -0.02891962043941021,
      0.07517851889133453,
      0.04877723008394241,
      -0.09821324795484543,
      0.016699980944395065,
      -0.08519330620765686,
      0.005569519940763712,
      -0.04849477857351303,
      -0.014717862010002136,
      -0.014663297683000565,
      -0.007558110170066357,
      -0.059206269681453705,
      -0.05929744988679886,
      -0.027839334681630135,
      -0.09422867745161057,
      0.06894545257091522,
      -0.01838451251387596,
      0.026327261701226234,
      -0.0253017358481884,
      -0.006880135275423527,
      0.05448458343744278,
      -0.005195217672735453,
      -0.08909861743450165,
      0.039233218878507614,
      0.029283003881573677,
      -0.06504195183515549,
      0.021382300183176994,
      -0.023565523326396942,
      -0.045805349946022034,
      0.022922124713659286,
      -0.006934415549039841,
      0.0029179418925195932,
      -0.011644257232546806,
      -0.03872812166810036,
      -0.0263046957552433,
      -0.052671246230602264,
      0.027385620400309563,
      0.005763956345617771,
      0.06583746522665024,
      -0.028743378818035126,
      0.032120540738105774,
      -0.060095932334661484,
      -0.041283369064331055,
      0.029794741421937943,
      -0.04673764109611511,
      -0.047535087913274765,
      0.007067329250276089,
      0.04506092891097069,
      -0.04901266098022461,
      0.02905537560582161,
      0.053415410220623016,
      -0.038623057305812836,
      -0.044516902416944504,
      -0.029153896495699883,
      0.06695593893527985,
      -0.053378570824861526,
      0.023550083860754967,
      0.031761493533849716,
      0.0032128484454005957,
      0.0074168178252875805,
      -0.011205178685486317,
      0.03932233527302742,
      -0.04794444143772125,
      -0.011994740925729275,
      0.104457326233387,
      -0.039758387953042984,
      0.0036402775440365076,
      -0.05998354032635689,
      0.05668279901146889,
      0.09478580951690674,
      -0.048504460602998734,
      0.05473174527287483,
      0.0303631741553545,
      -0.010989200323820114,
      0.07060698419809341,
      0.05169369652867317,
      -0.018741482868790627,
      0.016462456434965134,
      -0.021371958777308464,
      0.012665096670389175,
      0.0034348575863987207,
      0.029899004846811295,
      -0.07040823996067047,
      0.021623462438583374,
      0.02587018348276615,
      0.02367509715259075,
      0.017490969970822334,
      -0.004994228482246399,
      -0.04632467404007912,
      -0.004483393859118223,
      -0.020704107359051704,
      0.023368021473288536,
      0.050396885722875595,
      0.0784517452120781,
      -0.026920322328805923,
      0.02683201991021633,
      -0.015009293332695961,
      0.041140686720609665,
      0.026085278019309044,
      0.012216228991746902,
      -0.01335502415895462,
      0.05106738582253456,
      0.047233931720256805,
      0.09365105628967285,
      -0.05717499181628227,
      0.05001265928149223,
      -0.04871030151844025,
      -0.07185802608728409,
      0.05941683426499367,
      0.04581641033291817,
      -0.05625925585627556,
      -0.04620708152651787,
      -0.00642677815631032,
      0.03701361268758774,
      -0.06796484440565109,
      0.06499098241329193,
      -0.04381424933671951,
      -0.028003694489598274,
      0.03898252919316292,
      0.011341978795826435,
      -0.03193134069442749,
      -0.10495393723249435,
      0.09159931540489197,
      0.05442929267883301,
      0.03069125860929489,
      0.02869948372244835,
      -0.030247993767261505,
      -0.11564585566520691,
      -0.039824217557907104,
      0.04101916775107384,
      0.003229229710996151,
      0.0178282018750906,
      0.067278653383255,
      -0.04811462387442589,
      -0.041158922016620636,
      0.00026721053291112185,
      -0.0463598258793354,
      -0.048813603818416595,
      -0.016802841797471046,
      -0.02018074505031109,
      0.06872989237308502,
      0.0034027742221951485,
      -0.09663650393486023,
      -0.03917405381798744,
      -0.0011897329241037369,
      0.0013769773067906499,
      0.007957220077514648,
      -0.016101982444524765,
      0.06828057020902634,
      0.020295610651373863,
      0.039789829403162,
      0.002872964134439826,
      -0.045267216861248016,
      0.04659483954310417,
      -0.035982877016067505,
      -0.009027889929711819,
      -0.001400026842020452,
      0.04482005164027214,
      0.0495414063334465,
      -0.05213014408946037,
      0.0026464906986802816,
      0.034902483224868774,
      -0.04050707444548607,
      0.05616477504372597,
      -0.058988116681575775,
      0.018683386966586113,
      -0.017275620251893997,
      -0.026316527277231216,
      0.015622846782207489,
      -0.055308837443590164,
      -0.028395798057317734,
      0.03638218715786934,
      0.0199942197650671,
      0.035186633467674255,
      -0.011930078268051147,
      -0.07020116597414017,
      0.001956747379153967,
      0.02001775987446308,
      0.01851453259587288,
      -0.09374861419200897,
      -0.004188463557511568,
      0.054815296083688736,
      -0.04328124225139618,
      -0.10191182047128677,
      -0.053032614290714264,
      -0.06550855189561844,
      -0.08213456720113754,
      0.05203738436102867,
      0.04896891117095947,
      0.011424907483160496,
      0.009513536468148232,
      0.014898213557898998,
      0.027182096615433693,
      -0.07077421247959137,
      0.028023380786180496,
      -0.07295055687427521,
      0.027063846588134766,
      0.07327083498239517,
      -0.0003862347512040287,
      0.03316858783364296,
      -0.0033849929459393024,
      0.03459541127085686,
      0.004476489499211311,
      -0.0011580261634662747,
      -0.006235372740775347,
      -0.011362424120306969,
      0.015750303864479065,
      -0.008561693131923676,
      0.00045981217408552766,
      0.06792684644460678,
      -0.041750043630599976,
      0.05501832440495491,
      0.06058506667613983,
      -0.03807956352829933,
      -0.0030797289218753576,
      -0.003894354682415724,
      0.002847377909347415,
      -0.032803166657686234,
      0.0005328680272214115,
      0.0033670614939182997,
      0.04944773018360138,
      -0.010584321804344654,
      -0.07381335645914078,
      -0.15695345401763916,
      -0.034088362008333206,
      -0.024974631145596504,
      0.03240193426609039,
      -0.06784497946500778,
      -0.04782848060131073,
      -0.015787795186042786,
      -0.03341063857078552,
      -0.04201548174023628,
      0.04046642780303955,
      0.00868129450827837,
      -0.06779009103775024,
      -0.051953669637441635,
      -0.02040625363588333,
      -0.02722463384270668,
      -0.005084514617919922,
      -0.0074491845443844795,
      0.010256465524435043,
      -0.12467940151691437,
      0.0030039099510759115,
      0.029628600925207138,
      0.024942057207226753,
      0.06495049595832825,
      -0.03814307972788811,
      -0.00834774598479271,
      0.034182362258434296,
      0.01283528283238411,
      0.028394170105457306,
      -0.11413773894309998,
      -0.04538017138838768,
      0.002899450482800603,
      -0.08021137118339539,
      -0.04694916307926178,
      0.06925518065690994,
      -0.03690006211400032,
      0.026355884969234467,
      0.046949513256549835,
      0.03159082308411598,
      0.09203433245420456,
      0.03655337914824486,
      0.059369154274463654,
      0.0031164626125246286,
      0.10226190090179443,
      0.0326799638569355,
      -0.07916922122240067,
      0.04897794499993324,
      -0.017310984432697296,
      0.047029875218868256,
      -0.03395173326134682,
      -0.04347318038344383,
      0.01941031403839588,
      0.09393233805894852,
      -0.018383227288722992,
      -0.00482459319755435,
      -0.010095098055899143,
      0.021443666890263557,
      0.052314162254333496,
      0.041668977588415146,
      -0.04847557470202446,
      -0.028400784358382225,
      -0.0070322658866643906,
      0.03109928034245968,
      0.005219901446253061,
      -0.04859676957130432,
      0.008337940089404583,
      -0.0056499275378882885,
      0.005689064972102642,
      -0.0214585792273283,
      0.01042650081217289,
      0.05186488851904869,
      0.042511772364377975,
      -0.03942717984318733,
      0.057406965643167496,
      0.01778550259768963,
      0.026205044239759445,
      0.06063995882868767,
      0.01659402810037136,
      -0.056631699204444885,
      -0.0635942593216896,
      0.0667019635438919,
      0.006611650809645653,
      -0.0599043108522892,
      -0.04983717203140259,
      -0.0049332622438669205,
      -0.01512735802680254,
      0.022305799648165703,
      0.04744519665837288,
      0.023874422535300255,
      -0.05319351702928543,
      -0.028931990265846252,
      0.03889317810535431,
      -0.025909727439284325,
      -0.08355382829904556,
      0.03702881932258606,
      0.007852028124034405,
      0.0721464604139328,
      0.039843764156103134,
      -0.04469424858689308,
      0.06277274340391159,
      0.01572209969162941,
      0.04057801514863968,
      -0.10884042084217072,
      -0.025069722905755043,
      -0.015480280853807926,
      -0.010425778105854988,
      -0.029966475442051888,
      -0.05455696955323219,
      -0.06663818657398224,
      -0.012931699864566326,
      0.02183765172958374,
      -0.023220455273985863,
      -0.05809571221470833,
      0.0021632539574056864,
      0.01690906472504139,
      -0.043801676481962204,
      0.04023350775241852,
      -0.012690111063420773,
      0.010532291606068611,
      -0.0013280882267281413,
      0.02058326080441475,
      -0.011216087266802788,
      0.0034763338044285774,
      -0.008162389509379864,
      -0.04402090236544609,
      -0.06711982935667038,
      0.055243171751499176,
      0.022250641137361526,
      -0.0010873706778511405,
      -0.07156215608119965,
      0.0346178263425827,
      -0.093658946454525,
      0.0390738882124424,
      -0.03198770061135292,
      0.027678972110152245,
      -0.005801668856292963,
      -0.0713089108467102,
      -0.024926859885454178,
      -0.0023231646046042442,
      -0.0036489353515207767,
      0.10487697273492813,
      -0.07485848665237427,
      -0.029802141711115837,
      -0.08338934183120728,
      -9.581990889273584e-05,
      0.03199373930692673,
      0.027912599965929985,
      0.06346725672483444,
      0.01405132096260786,
      0.031634621322155,
      -0.02252124808728695,
      0.0033088570926338434,
      -0.03442983701825142,
      0.05196352303028107,
      -0.05809048190712929,
      0.0003441504668444395,
      -0.036561209708452225,
      0.0042999014258384705,
      -0.045736681669950485,
      0.0010391419054940343,
      -0.02156279981136322,
      -0.030185740441083908,
      0.0037261785473674536,
      -0.004154021851718426,
      0.030004089698195457,
      0.01381461787968874,
      0.041236381977796555,
      -0.020844001322984695,
      -0.021086959168314934,
      0.0038635064847767353,
      -0.004940077196806669,
      -0.040709905326366425,
      -0.03251121565699577,
      0.07798310369253159,
      -0.07063782960176468,
      -0.07166172564029694,
      0.06505319476127625,
      -0.08801450580358505,
      0.09056845307350159,
      0.08884885907173157,
      -0.006022626534104347,
      0.014970331452786922,
      0.06348803639411926,
      -0.05645783245563507,
      -0.05438058823347092,
      -0.0320252887904644,
      0.11040295660495758,
      0.004604890942573547,
      -0.042551189661026,
      -0.013393815606832504,
      0.009395758621394634,
      -0.05588700994849205,
      -0.025688568130135536,
      -0.02522118203341961,
      -0.04628785699605942,
      -0.020337315276265144,
      -0.038984496146440506,
      3.4958808100782335e-05,
      -0.011631274595856667,
      -0.0246579609811306,
      -0.0042841192334890366,
      0.018972283229231834,
      0.028764288872480392,
      -0.033115122467279434,
      -0.051788002252578735,
      0.005480453837662935,
      -0.0039395298808813095,
      0.0026269142981618643,
      -0.0168913546949625,
      0.04333701729774475,
      0.019864993169903755,
      -0.00773266889154911,
      -0.0002827542775776237,
      -0.004114402458071709,
      0.0479724258184433,
      -0.008818806149065495,
      0.011330452747642994,
      -0.01892426237463951,
      -0.04706075042486191,
      0.013285431079566479,
      0.057776354253292084,
      0.020816745236516,
      -0.03606898710131645,
      0.02348191849887371,
      -0.01773725636303425,
      0.11852114647626877,
      0.008197673596441746,
      -0.030400948598980904,
      -0.058194659650325775,
      -0.04150642082095146,
      0.01594371907413006,
      -0.01807856559753418
    ],
    [
      -0.029825709760189056,
      0.019246194511651993,
      -0.02384362742304802,
      0.0017345660598948598,
      -0.04139542952179909,
      0.0931720957159996,
      -0.002598123624920845,
      -0.006455263122916222,
      -0.00956515222787857,
      -0.0032345419749617577,
      0.00033588733640499413,
      0.008643693290650845,
      -0.04561980441212654,
      -0.03347837179899216,
      0.04283459857106209,
      -0.012233583256602287,
      0.03854364901781082,
      -0.004488316364586353,
      0.019943688064813614,
      -0.02851445972919464,
      0.05840407684445381,
      0.08922097086906433,
      -0.11720651388168335,
      -0.01680091954767704,
      -0.07583386451005936,
      -0.037421297281980515,
      0.014814169146120548,
      0.024594398215413094,
      -0.033598583191633224,
      0.029785066843032837,
      -0.0295107364654541,
      0.04263358563184738,
      -0.050864290446043015,
      -0.019975760951638222,
      -0.032097648829221725,
      -0.005789520684629679,
      0.008640265092253685,
      0.05690087378025055,
      -0.003766197944059968,
      -0.09855460375547409,
      -0.05646859109401703,
      -0.024576932191848755,
      -0.048242080956697464,
      0.047862131148576736,
      -0.007751212455332279,
      0.04843970015645027,
      0.03141671046614647,
      0.007851354777812958,
      0.032100241631269455,
      -0.025564543902873993,
      -0.03882402554154396,
      -0.02213180996477604,
      0.001263719517737627,
      0.06287124007940292,
      -0.02208586223423481,
      0.01851981319487095,
      -0.03651582449674606,
      0.05220671743154526,
      -0.05510494485497475,
      -0.00041298838914372027,
      -0.02254737727344036,
      0.0395304411649704,
      -0.01631535030901432,
      0.05954809486865997,
      -0.08738651871681213,
      0.029145875945687294,
      -0.0785490944981575,
      -0.007202757056802511,
      0.019002722576260567,
      -0.004860574379563332,
      0.00416230782866478,
      -0.04167138785123825,
      0.009012497030198574,
      0.00927542895078659,
      0.0236198827624321,
      0.03867166116833687,
      -0.002268850337713957,
      0.0976932942867279,
      0.055974505841732025,
      -0.021722612902522087,
      -0.008260820060968399,
      -0.06439204514026642,
      0.03158148378133774,
      0.008083823136985302,
      -0.016488775610923767,
      0.10171504318714142,
      -0.06996484100818634,
      -0.07716141641139984,
      0.010103998705744743,
      -0.0036064290907233953,
      0.03006802871823311,
      0.002171154599636793,
      0.04425817355513573,
      0.013708452694118023,
      0.05615692213177681,
      0.08285646140575409,
      -0.08899076282978058,
      0.0046510230749845505,
      -0.06755726784467697,
      0.01794566586613655,
      -0.08093181997537613,
      0.04591398686170578,
      -0.008551754988729954,
      -0.008573186583817005,
      0.02140202187001705,
      -0.044165994971990585,
      0.02299686148762703,
      -0.012521055527031422,
      -0.038958799093961716,
      0.01550713274627924,
      -0.008402298204600811,
      -0.005680627189576626,
      0.003133961698040366,
      -0.03721797838807106,
      0.047486331313848495,
      0.039568617939949036,
      -0.0285099595785141,
      0.028586117550730705,
      -0.00972567219287157,
      0.010345165617763996,
      -0.027843791991472244,
      -0.08109527826309204,
      -0.03384597972035408,
      0.0883394405245781,
      -0.004418135154992342,
      0.026816653087735176,
      0.028933066874742508,
      -0.0056737251579761505,
      0.010514111258089542,
      0.020793238654732704,
      0.05093752220273018,
      -0.001737853279337287,
      -0.04973706975579262,
      -0.02055116556584835,
      -0.058775026351213455,
      0.033589329570531845,
      -0.09245086461305618,
      0.0009148030076175928,
      0.03998563811182976,
      -0.07836589217185974,
      0.013238874264061451,
      0.025543056428432465,
      -0.01834891177713871,
      -0.01385555975139141,
      -0.02911544032394886,
      -0.03349466249346733,
      -0.04280075430870056,
      -0.022294020280241966,
      -0.06643299758434296,
      0.06066516414284706,
      0.037456125020980835,
      -0.02582220919430256,
      -0.05794159695506096,
      0.016993407160043716,
      0.05236370489001274,
      -0.03682016581296921,
      -0.004068010486662388,
      0.023249967023730278,
      -0.006337832659482956,
      0.026999272406101227,
      -0.028403831645846367,
      -0.023427505046129227,
      -0.006286121904850006,
      0.007220927160233259,
      -0.03724299371242523,
      -0.01508285105228424,
      -0.05165286362171173,
      0.02028149552643299,
      0.027700774371623993,
      0.024715175852179527,
      -0.030472969636321068,
      0.030622024089097977,
      -0.028238143771886826,
      0.025964466854929924,
      0.010802160948514938,
      0.03088974766433239,
      0.1208263635635376,
      0.022533735260367393,
      -0.009400047361850739,
      -0.015781406313180923,
      0.07818112522363663,
      -0.0070165107026696205,
      -0.08143075555562973,
      0.03142663836479187,
      0.04117227718234062,
      0.032096125185489655,
      -0.023213431239128113,
      -0.036728959530591965,
      0.02301825024187565,
      -0.04006755352020264,
      -0.018627284094691277,
      -0.02029893733561039,
      0.029391588643193245,
      -0.015857761725783348,
      0.011892732232809067,
      -0.04203887656331062,
      0.03405531868338585,
      -0.005792705342173576,
      -0.0032744640484452248,
      0.05703619122505188,
      -0.052649639546871185,
      -0.034703537821769714,
      0.017847370356321335,
      0.024478763341903687,
      0.0005452720215544105,
      -0.04544883593916893,
      0.050433021038770676,
      0.028853101655840874,
      -0.03265698254108429,
      0.010918836109340191,
      0.011827317997813225,
      -0.0704525038599968,
      -0.0821409597992897,
      0.025114981457591057,
      0.01777910254895687,
      -0.025593886151909828,
      0.004643745720386505,
      -0.019877387210726738,
      0.0031650543678551912,
      -0.0036265975795686245,
      -0.05793018639087677,
      -0.06345966458320618,
      0.03104351833462715,
      0.050894320011138916,
      -0.12656128406524658,
      -0.08041394501924515,
      0.023847023025155067,
      0.02906186506152153,
      0.06427284330129623,
      -0.07043472677469254,
      0.00040394789539277554,
      -0.04174264147877693,
      0.03942587226629257,
      -0.05949750915169716,
      0.051260847598314285,
      0.014563889242708683,
      -0.05426030233502388,
      0.003592285793274641,
      -0.05569889023900032,
      0.021324319764971733,
      0.025500167161226273,
      -0.010734516195952892,
      0.02471311017870903,
      0.001980078173801303,
      -0.017301583662629128,
      -0.044184353202581406,
      0.03486047685146332,
      0.012155399657785892,
      0.034773875027894974,
      -0.021082259714603424,
      0.005969191435724497,
      0.006809994112700224,
      -0.022395102307200432,
      -0.01597391441464424,
      -0.08603841811418533,
      0.04135729372501373,
      0.01483642216771841,
      0.01438168529421091,
      -0.027438625693321228,
      0.09381843358278275,
      0.06832610815763474,
      -0.09332570433616638,
      -0.004667208064347506,
      -0.036824099719524384,
      0.11025379598140717,
      0.008327282965183258,
      0.026933258399367332,
      -0.05830312520265579,
      -0.006000922992825508,
      -0.06827247887849808,
      0.023284021764993668,
      -0.008288422599434853,
      0.0027129172813147306,
      -0.009287004359066486,
      -0.03555711358785629,
      0.027855712920427322,
      0.05322248488664627,
      0.029956134036183357,
      0.0046269590966403484,
      -0.0003195471072103828,
      -0.036279719322919846,
      0.007341483142226934,
      0.006763037759810686,
      -0.04681425541639328,
      0.0023119314573705196,
      0.009987196885049343,
      -0.00030449649784713984,
      0.056066688150167465,
      0.07549993693828583,
      0.04255925118923187,
      -0.13465610146522522,
      0.0656513199210167,
      -0.012482704594731331,
      -0.044076260179281235,
      0.00920221209526062,
      0.029252689331769943,
      0.040416307747364044,
      0.0025296472012996674,
      -0.055685847997665405,
      -0.0102655915543437,
      0.012746524065732956,
      0.0069816517643630505,
      -0.013600161299109459,
      -0.017458250746130943,
      0.03861461579799652,
      -0.057644255459308624,
      -0.01196102425456047,
      0.0738169327378273,
      0.010917870327830315,
      -0.04705996438860893,
      0.007385245990008116,
      0.05712411552667618,
      0.00109648157376796,
      -0.005597034003585577,
      0.06600271910429001,
      0.006130666937679052,
      0.015610122121870518,
      -0.01935339719057083,
      -0.0025619559455662966,
      0.025684429332613945,
      0.002360099460929632,
      0.006915968377143145,
      -0.0646369457244873,
      -0.039104074239730835,
      -0.0018554225098341703,
      -0.03607827052474022,
      -0.003680312307551503,
      -0.019645052030682564,
      0.09778136759996414,
      -0.051144685596227646,
      -0.0678611695766449,
      -0.05068109929561615,
      0.060895487666130066,
      -0.06269975006580353,
      -0.0602746456861496,
      -0.0008541597053408623,
      -0.014540575444698334,
      -0.049630746245384216,
      -0.05358137562870979,
      -0.025963347405195236,
      -0.08302009850740433,
      0.04318595305085182,
      0.024500032886862755,
      0.02986123412847519,
      0.041289474815130234,
      0.014794775284826756,
      0.0005900153191760182,
      0.035594481974840164,
      -0.07389843463897705,
      0.013503733091056347,
      0.0002619364531710744,
      0.0338553860783577,
      -0.0626286044716835,
      0.04456871375441551,
      -0.05163279548287392,
      0.012326894327998161,
      -0.05213961750268936,
      -0.03639470040798187,
      -0.03359135612845421,
      -0.03596731647849083,
      -0.059333108365535736,
      0.03464869409799576,
      0.03311881795525551,
      -0.02117210067808628,
      0.057738449424505234,
      0.034200262278318405,
      -0.08853959292173386,
      -0.03063637763261795,
      0.019367599859833717,
      -0.039167579263448715,
      -0.059250541031360626,
      -0.028976701200008392,
      0.01345902867615223,
      -0.00021194442524574697,
      -0.0900234505534172,
      0.013002883642911911,
      -0.012522343546152115,
      0.0566079281270504,
      0.01797373592853546,
      0.06482166796922684,
      -0.019136380404233932,
      -0.04383811354637146,
      -0.04242144525051117,
      -0.04285603016614914,
      0.07545197010040283,
      0.02403731644153595,
      0.06675335019826889,
      0.03838781267404556,
      0.030968809500336647,
      -0.008395557291805744,
      -0.013083118945360184,
      -0.03881659358739853,
      -0.05561523139476776,
      -0.064189612865448,
      0.04759710282087326,
      0.06122640147805214,
      0.03336245194077492,
      -0.005473220720887184,
      0.0123963737860322,
      -0.027318160980939865,
      -0.021808259189128876,
      0.024524826556444168,
      -0.04415607079863548,
      -0.0693376287817955,
      0.03710301220417023,
      0.172577366232872,
      -0.025254372507333755,
      0.0167422853410244,
      -0.07293170690536499,
      0.028483888134360313,
      0.006552297621965408,
      0.034528832882642746,
      0.027488797903060913,
      0.04569752514362335,
      0.03322870284318924,
      -0.06696423143148422,
      -0.01020313985645771,
      -0.006217633839696646,
      -0.03812867030501366,
      0.0660037025809288,
      -0.0351608507335186,
      -0.062065642327070236,
      0.05690234526991844,
      0.019466735422611237,
      -0.05386275053024292,
      -0.08054925501346588,
      -0.014698593877255917,
      0.004994457121938467,
      0.027829356491565704,
      0.03725997358560562,
      -0.0598042868077755,
      -0.011907207779586315,
      0.1032479777932167,
      -0.0019510784186422825,
      0.06438320130109787,
      0.051833365112543106,
      -0.0827798917889595,
      -0.024766672402620316,
      0.013394687324762344,
      0.06491520255804062,
      0.06239340081810951,
      -0.034968383610248566,
      0.0884513333439827,
      -0.032635681331157684,
      -0.021532626822590828,
      0.0035204733721911907,
      0.046049509197473526,
      -0.07654693722724915,
      0.06800247728824615,
      -0.031418535858392715,
      -0.0073261079378426075,
      -0.02792998217046261,
      0.039514362812042236,
      -0.006706858053803444,
      0.05630365014076233,
      0.06548186391592026,
      0.0543060377240181,
      -0.05599427595734596,
      0.06435375660657883,
      -0.038960784673690796,
      -0.005975289270281792,
      -0.03006823919713497,
      -0.041851770132780075,
      -0.01199905015528202,
      0.0010320344008505344,
      0.020260583609342575,
      0.0691162720322609,
      0.028117233887314796,
      0.028488103300333023,
      -0.0868084728717804,
      0.03316345438361168,
      0.039377372711896896,
      0.012548326514661312,
      0.03706340119242668,
      0.001280782395042479,
      -0.04441949725151062,
      0.003952634055167437,
      0.001707612071186304,
      0.002321877283975482,
      -0.05486935004591942,
      -0.011476825922727585,
      0.08180882036685944,
      0.025568801909685135,
      0.00966818816959858,
      -0.020752660930156708,
      -0.03684390336275101,
      0.0029045958071947098,
      0.028249509632587433,
      0.012758133001625538,
      -0.03735547885298729,
      0.012876955792307854,
      0.06746495515108109,
      -0.06597355753183365,
      -0.024332448840141296,
      0.014570727944374084,
      0.07462800294160843,
      0.0302707701921463,
      0.03547391667962074,
      0.016758056357502937,
      0.014132896438241005,
      -0.01013317983597517,
      0.0034977190662175417,
      -0.024005573242902756
    ],
    [
      0.0006028599455021322,
      0.09243293106555939,
      -0.019732937216758728,
      0.08475732058286667,
      -0.01831912435591221,
      0.055672112852334976,
      -0.011065379716455936,
      -0.035497475415468216,
      0.021809326484799385,
      -0.032879721373319626,
      -0.017053082585334778,
      0.08802315592765808,
      0.0029088726732879877,
      -0.025722982361912727,
      -0.04274400696158409,
      -0.009736811742186546,
      -0.0034349870402365923,
      -0.09594201296567917,
      0.0021961783058941364,
      -0.0683172270655632,
      -0.010017718188464642,
      0.007836646400392056,
      -0.004987055901437998,
      0.00365942413918674,
      0.023945992812514305,
      0.031288135796785355,
      0.03989692032337189,
      0.021821774542331696,
      0.003927646204829216,
      0.003414370119571686,
      0.057096417993307114,
      -0.06288913637399673,
      0.03272208943963051,
      -0.010334872640669346,
      0.021728266030550003,
      -0.042065780609846115,
      0.020923497155308723,
      0.013863274827599525,
      0.014093920588493347,
      -0.010474293492734432,
      -0.021864531561732292,
      0.05194372683763504,
      -0.047913771122694016,
      0.039249666035175323,
      -0.05620303750038147,
      -0.05267232283949852,
      0.03528410196304321,
      0.018444281071424484,
      0.052639175206422806,
      -0.028602242469787598,
      0.011765999719500542,
      0.003669001627713442,
      -0.053167831152677536,
      -0.015523211099207401,
      -0.0643712729215622,
      -0.03697587549686432,
      0.010697674937546253,
      0.08717428892850876,
      0.010373634286224842,
      -0.024578241631388664,
      0.002881757915019989,
      -0.027764344587922096,
      0.00817365013062954,
      0.005176097620278597,
      -0.05163704603910446,
      -0.008920235559344292,
      0.0760769471526146,
      0.02860444225370884,
      -0.04266805946826935,
      0.006694660987704992,
      0.023948751389980316,
      0.03206411004066467,
      -0.003102292539551854,
      -0.025705328211188316,
      0.0764247328042984,
      -0.0007197711966000497,
      -0.011848196387290955,
      -0.03779232129454613,
      -0.055173806846141815,
      -0.032497379928827286,
      0.0829298123717308,
      -0.13330572843551636,
      -0.013674817979335785,
      -0.03977315500378609,
      -0.036689579486846924,
      -0.005984442308545113,
      -0.009080235846340656,
      0.045532457530498505,
      0.040279898792505264,
      0.05780114606022835,
      0.08075407147407532,
      0.07772471755743027,
      0.015429756604135036,
      -0.02407103031873703,
      0.04469780623912811,
      -0.032837629318237305,
      0.046766653656959534,
      0.05252521112561226,
      -0.002270415425300598,
      0.04467039182782173,
      0.0318426713347435,
      -0.0046727475710213184,
      0.014354140497744083,
      0.0011602138401940465,
      0.00338332774117589,
      0.037125710397958755,
      0.07808845490217209,
      0.025015685707330704,
      0.02655157633125782,
      -0.011107170954346657,
      0.1445690393447876,
      -0.03063899837434292,
      -0.013865472748875618,
      0.01393012423068285,
      -0.043394483625888824,
      -0.026925206184387207,
      -0.05679357051849365,
      0.027941003441810608,
      0.014632536098361015,
      -0.04864371940493584,
      0.04430774971842766,
      0.06437095254659653,
      -0.05514666065573692,
      -0.018409105017781258,
      0.04157250374555588,
      0.05926891416311264,
      -0.09821407496929169,
      -0.026292473077774048,
      -0.05849717557430267,
      -0.012818281538784504,
      -0.02909846417605877,
      -0.05122726038098335,
      -0.03502192348241806,
      0.0036085222382098436,
      0.06267209351062775,
      -0.029218433424830437,
      -0.015710676088929176,
      0.031006518751382828,
      0.032103825360536575,
      -0.029113011434674263,
      0.06552449613809586,
      0.00598348630592227,
      -0.01828104443848133,
      0.10174956917762756,
      0.05001106113195419,
      -0.02863684669137001,
      0.05573089048266411,
      0.11295841634273529,
      0.013313636183738708,
      0.08289716392755508,
      0.03640472888946533,
      -0.01530152466148138,
      -0.006981461774557829,
      0.05954065918922424,
      -0.017125921323895454,
      -0.006630220916122198,
      0.06627701222896576,
      -0.0117443036288023,
      0.04242512583732605,
      0.00926248449832201,
      0.08184055984020233,
      0.07592826336622238,
      -0.027362369000911713,
      0.03827100992202759,
      0.011333409696817398,
      0.028874816372990608,
      0.030603112652897835,
      0.045246779918670654,
      0.08282694965600967,
      -0.006689750123769045,
      -0.04440733790397644,
      0.0009106086799874902,
      0.0020944271236658096,
      0.007438804488629103,
      0.04753304645419121,
      -0.039784714579582214,
      0.10081052780151367,
      -0.011827158741652966,
      -0.09040424227714539,
      0.06287453323602676,
      0.019350115209817886,
      -0.05321148782968521,
      -0.002212496707215905,
      -0.014221575111150742,
      -0.017697684466838837,
      -0.006583990063518286,
      0.07192844897508621,
      0.0420733317732811,
      -0.05287877470254898,
      -0.027411438524723053,
      -0.044563379138708115,
      -0.04610586538910866,
      -0.024064594879746437,
      0.011870449408888817,
      -0.027852799743413925,
      -0.017277410253882408,
      0.060379162430763245,
      -0.024404017254710197,
      -0.027162844315171242,
      0.033373571932315826,
      0.03528057411313057,
      -0.028127292171120644,
      -0.02151971310377121,
      0.003565748455002904,
      0.04153149947524071,
      -0.041594889014959335,
      -0.007303537335246801,
      0.018085544928908348,
      -0.014338749460875988,
      -0.03951307013630867,
      0.017235031351447105,
      -0.000691104622092098,
      0.010706624016165733,
      0.0405358150601387,
      0.07779959589242935,
      -0.050987038761377335,
      0.001207146910019219,
      -0.02214054949581623,
      -0.05805599316954613,
      -0.04132945090532303,
      0.005494872108101845,
      -0.009879662655293941,
      0.02581207826733589,
      0.03968527168035507,
      -0.038643114268779755,
      0.0064128125086426735,
      0.021886011585593224,
      -0.013323106802999973,
      0.09401427954435349,
      0.004099035169929266,
      -0.009583665058016777,
      -0.04563627019524574,
      0.061626747250556946,
      -0.011415072716772556,
      -0.002421703189611435,
      0.03561091050505638,
      -0.019355637952685356,
      -0.07615706324577332,
      -0.018539901822805405,
      -0.024144301190972328,
      -0.1124773770570755,
      -0.007100008428096771,
      0.0006331974873319268,
      0.006664355285465717,
      -0.06704489141702652,
      -0.04003916680812836,
      0.11593636870384216,
      0.06664366275072098,
      0.0045335073955357075,
      -0.0212722085416317,
      -0.08193006366491318,
      -0.03494969382882118,
      -0.022542275488376617,
      -0.0176050066947937,
      -0.06639406085014343,
      0.024867810308933258,
      0.049174364656209946,
      0.128362238407135,
      0.025993403047323227,
      0.02670038305222988,
      0.011900701560080051,
      0.07519643753767014,
      -0.03013332560658455,
      0.010268931277096272,
      0.013284208253026009,
      0.009892120026051998,
      0.04710417613387108,
      -0.03001527488231659,
      0.01602041721343994,
      -0.0071024103090167046,
      0.015612899325788021,
      0.02696029469370842,
      0.0717170387506485,
      0.0017028769943863153,
      0.020052140578627586,
      -0.04226222634315491,
      0.018229182809591293,
      0.08895280957221985,
      0.038427554070949554,
      -0.07234989106655121,
      -0.019789446145296097,
      -0.04133560135960579,
      -0.041987765580415726,
      0.02198636159300804,
      -0.05508612468838692,
      0.02514577843248844,
      0.0031618785578757524,
      -0.0420653261244297,
      0.08464199304580688,
      0.04402216523885727,
      -0.07931280136108398,
      -0.018860138952732086,
      0.015137940645217896,
      0.05020301043987274,
      -0.010280326940119267,
      -0.009586943313479424,
      0.020573420450091362,
      -0.005986041855067015,
      -0.036618176847696304,
      -0.04016416519880295,
      -0.09669668972492218,
      -0.057267189025878906,
      -0.0035627654287964106,
      0.009387011639773846,
      0.032831910997629166,
      -0.004315318074077368,
      -0.0890122801065445,
      0.05973242595791817,
      -0.018202293664216995,
      -0.008983569219708443,
      -0.021267075091600418,
      0.06452388316392899,
      0.06589026749134064,
      -0.0008834013133309782,
      -0.03225591033697128,
      -0.0019133706809952855,
      -0.04050414264202118,
      0.05852899327874184,
      -0.04492276906967163,
      -0.008853541687130928,
      -0.07877808064222336,
      -0.016002753749489784,
      -0.035934168845415115,
      0.09831847995519638,
      0.046296216547489166,
      -0.011863968335092068,
      0.0016800297889858484,
      -0.05133002623915672,
      0.04182131215929985,
      -0.03308359161019325,
      -0.046005431562662125,
      -0.0287542212754488,
      0.024364721029996872,
      -0.06325847655534744,
      0.011916592717170715,
      -0.002302119741216302,
      0.0050150859169662,
      -0.03575271740555763,
      -0.019533967599272728,
      -0.04955189675092697,
      -0.10515139251947403,
      0.008268710225820541,
      -0.011723268777132034,
      -0.0065695676021277905,
      0.031775299459695816,
      0.07971606403589249,
      -0.05447971075773239,
      0.06146734952926636,
      0.08941569179296494,
      0.07130726426839828,
      -0.0512302964925766,
      -0.007770667318254709,
      0.03307805955410004,
      0.017583182081580162,
      0.022358408197760582,
      0.012588225305080414,
      0.044493433088064194,
      -0.042701106518507004,
      0.05005620792508125,
      0.042363349348306656,
      -0.010190723463892937,
      -0.005360858980566263,
      -0.06908270716667175,
      0.048614561557769775,
      -0.08082330226898193,
      -0.04500975459814072,
      0.05280846729874611,
      0.004858883563429117,
      -0.007322875317186117,
      -0.027020152658224106,
      -0.01948203332722187,
      -0.07389090210199356,
      -0.005941774230450392,
      -0.013958518393337727,
      0.012027164921164513,
      -0.014589408412575722,
      -0.013868722133338451,
      -0.01843777298927307,
      -0.09753111004829407,
      -0.015520874410867691,
      -0.047968629747629166,
      -0.025199251249432564,
      0.05186550319194794,
      0.008498497307300568,
      0.0009376637171953917,
      -0.08040039986371994,
      -0.001962195849046111,
      -0.04852354899048805,
      0.08340710401535034,
      -0.012787720188498497,
      -0.032398615032434464,
      0.10218124836683273,
      -0.015376388095319271,
      0.017819445580244064,
      -0.003356657223775983,
      -0.029986007139086723,
      0.006969721056520939,
      0.018400421366095543,
      -0.032916150987148285,
      0.06979633122682571,
      -0.048609331250190735,
      0.06299717724323273,
      0.040195532143116,
      -0.062355887144804,
      -0.010796427726745605,
      0.017809664830565453,
      -0.03956685960292816,
      0.030441151931881905,
      -0.002986639505252242,
      0.034887660294771194,
      -0.04674063250422478,
      0.059835661202669144,
      -0.04115588963031769,
      0.03429004177451134,
      0.09447356313467026,
      -0.0003904420882463455,
      0.01802959106862545,
      -0.04028353840112686,
      0.037698663771152496,
      0.05726683512330055,
      -0.030985914170742035,
      0.012892451137304306,
      -0.03828679770231247,
      -0.029194818809628487,
      -0.016097383573651314,
      -0.0015895195538178086,
      0.016559449955821037,
      0.013062526471912861,
      0.039568934589624405,
      0.06483087688684464,
      0.01940065622329712,
      -0.03960651904344559,
      -0.01912960410118103,
      -0.025231536477804184,
      -0.03860178589820862,
      -0.08247140794992447,
      -0.018573444336652756,
      0.05711863189935684,
      -0.03607604652643204,
      -0.018714018166065216,
      -0.055021680891513824,
      0.03581272438168526,
      0.03313937783241272,
      0.04686657711863518,
      -0.055825311690568924,
      -0.010232031345367432,
      0.06247343122959137,
      6.921331805642694e-05,
      -0.02903623878955841,
      -0.019489828497171402,
      0.024688245728611946,
      -0.07077251374721527,
      0.014227516017854214,
      -0.03881654888391495,
      0.09490110725164413,
      -0.0006075807614251971,
      -0.004126029089093208,
      0.015681426972150803,
      0.03417440503835678,
      -0.021321237087249756,
      -0.03334871679544449,
      -0.07398475706577301,
      0.019191494211554527,
      -0.007762822322547436,
      0.002084581647068262,
      0.02913183532655239,
      -0.014516210183501244,
      -0.05787571519613266,
      0.0785597637295723,
      0.05240783840417862,
      -0.059378281235694885,
      -0.0063482834957540035,
      0.025462491437792778,
      -0.006480877287685871,
      -0.03304285928606987,
      -0.02739536017179489,
      -0.012744749896228313,
      -0.008694684132933617,
      -0.07817111164331436,
      0.009063564240932465,
      -0.05579386278986931,
      0.000921761617064476,
      -0.045983362942934036,
      0.06732142716646194,
      0.0024060618598014116,
      -0.07106202095746994,
      -0.017395038157701492,
      0.051104836165905,
      0.005145930219441652,
      -0.028010625392198563,
      0.056859418749809265,
      -0.0351296104490757,
      0.0372525230050087,
      -0.027262859046459198,
      0.024746274575591087,
      -0.049864739179611206,
      -0.04039013013243675,
      0.012611023150384426,
      0.0342942550778389,
      0.025631627067923546,
      0.034235138446092606,
      -0.001692639896646142,
      0.0024523178581148386
    ],
    [
      -0.0738048106431961,
      0.03710022196173668,
      0.022183377295732498,
      -0.022651929408311844,
      0.00046569755068048835,
      0.018108921125531197,
      -0.020428715273737907,
      -0.01955876126885414,
      0.06704559922218323,
      -0.011417466215789318,
      0.014982763677835464,
      -0.04989800229668617,
      -0.016178738325834274,
      0.01727345399558544,
      -0.04339092969894409,
      0.014363531023263931,
      -0.05555117130279541,
      0.0002691377594601363,
      0.02912227436900139,
      -0.004088610410690308,
      -0.04139358550310135,
      0.007702479604631662,
      -0.011617609299719334,
      -0.04690488800406456,
      0.08456546068191528,
      -0.025434864684939384,
      -0.00608061533421278,
      0.017166176810860634,
      -0.02925146371126175,
      -0.012579201720654964,
      -0.03268874064087868,
      0.048412468284368515,
      -0.02130434475839138,
      0.003861886914819479,
      -0.013845312409102917,
      0.019910449162125587,
      0.09509638696908951,
      -0.03826496750116348,
      -0.039322879165410995,
      0.10345175117254257,
      0.00402165437117219,
      -0.06903506070375443,
      0.001967546995729208,
      -0.016897052526474,
      0.03233923017978668,
      0.017431529238820076,
      0.03015889786183834,
      -0.026689862832427025,
      0.06840725988149643,
      -0.00816044770181179,
      0.03829682618379593,
      0.019259430468082428,
      0.05986497551202774,
      -0.02422090247273445,
      0.02486746944487095,
      0.037601038813591,
      -0.09443723410367966,
      0.005452254321426153,
      -0.0009100206661969423,
      0.09574641287326813,
      0.031057965010404587,
      0.02318768948316574,
      -0.007342199794948101,
      0.0361371785402298,
      -0.04337958246469498,
      -0.014420641586184502,
      0.006493512541055679,
      0.01818852871656418,
      -0.07754771411418915,
      0.027455223724246025,
      0.06988834589719772,
      0.08665768057107925,
      0.009554433636367321,
      -0.06088218092918396,
      0.10673604160547256,
      -0.02843593619763851,
      0.03768070414662361,
      -0.041423264890909195,
      0.04171263054013252,
      0.009451908990740776,
      0.012650926597416401,
      0.020919134840369225,
      -0.0248881783336401,
      0.04522031918168068,
      0.07195354998111725,
      0.050278306007385254,
      -0.014564456418156624,
      0.0036111592780798674,
      -0.022428279742598534,
      0.0812915712594986,
      -0.0030579736921936274,
      -0.013210239820182323,
      -0.013331850990653038,
      0.05744640529155731,
      0.009347522631287575,
      -0.058888986706733704,
      -0.02935510128736496,
      0.011624650098383427,
      -0.036222442984580994,
      0.017797257751226425,
      0.036256562918424606,
      0.03358613699674606,
      -0.06399813294410706,
      -0.015889769420027733,
      0.031657878309488297,
      0.043142057955265045,
      0.004923027474433184,
      -0.04375286400318146,
      0.01379062607884407,
      -0.0569259375333786,
      -0.010102873668074608,
      -0.00586624164134264,
      0.0034271441400051117,
      0.0061526852659881115,
      0.043066028505563736,
      -0.020498642697930336,
      0.030764896422624588,
      0.05828212574124336,
      0.029775027185678482,
      -0.0388820543885231,
      0.016156760975718498,
      0.04366037994623184,
      -0.02477463334798813,
      0.0022966712713241577,
      0.030015312135219574,
      0.006403265055269003,
      -0.0011626124614849687,
      -0.041280582547187805,
      -0.0006239663925953209,
      0.033378928899765015,
      0.05327403545379639,
      0.002511792816221714,
      -0.032280001789331436,
      0.006238164380192757,
      -0.00859764963388443,
      0.023500798270106316,
      0.015074962750077248,
      -0.0020116923842579126,
      -0.01533395703881979,
      0.018186405301094055,
      0.008202038705348969,
      -0.006007289048284292,
      0.09380348771810532,
      0.08768906444311142,
      0.006653035059571266,
      -0.0554426833987236,
      0.04879632219672203,
      -0.048562001436948776,
      -0.05959475785493851,
      -0.0481712631881237,
      0.05624506250023842,
      0.01557462103664875,
      -0.06264058500528336,
      -0.08147262036800385,
      0.012302453629672527,
      0.07467688620090485,
      0.005810214206576347,
      0.07968153804540634,
      -0.03515513241291046,
      -0.017932625487446785,
      -0.036132413893938065,
      0.10900086909532547,
      0.006804024334996939,
      -0.04974126070737839,
      0.02403009869158268,
      -0.020948762074112892,
      0.062155380845069885,
      -0.07690940052270889,
      0.03283112496137619,
      -0.05230334401130676,
      -0.015781814232468605,
      0.02356180176138878,
      -0.002035219222307205,
      -0.022424688562750816,
      0.01756233721971512,
      0.07596950232982635,
      0.009631137363612652,
      -0.0516994372010231,
      0.03577391803264618,
      0.04735284671187401,
      -0.025395656004548073,
      0.0058183311484754086,
      0.015459490939974785,
      0.10760385543107986,
      0.022682884708046913,
      -0.019089754670858383,
      -0.027067702263593674,
      -0.08036861568689346,
      -0.0032183262519538403,
      0.008244880475103855,
      -0.018012957647442818,
      0.03686395287513733,
      0.08178598433732986,
      -0.004492351785302162,
      0.047780923545360565,
      -0.1044773980975151,
      -0.013807461597025394,
      -0.08018886297941208,
      -0.00443965895101428,
      0.015370640903711319,
      -0.03624474257230759,
      0.038724593818187714,
      0.012380252592265606,
      -0.0327434241771698,
      0.06849095225334167,
      0.00784899853169918,
      0.001062164781615138,
      -0.028009556233882904,
      -0.04899818077683449,
      -0.032959841191768646,
      -0.03307468071579933,
      -0.022123398259282112,
      -0.09669054299592972,
      0.04688176512718201,
      -0.023925984278321266,
      0.0335364006459713,
      0.07930486649274826,
      -0.016556410118937492,
      -0.01344495639204979,
      0.04730456694960594,
      -0.06850092113018036,
      0.03329802304506302,
      0.03261713311076164,
      0.042917296290397644,
      -0.042790140956640244,
      0.05895137041807175,
      0.06951761990785599,
      0.01760910078883171,
      0.03525000810623169,
      0.012702148407697678,
      0.02705615945160389,
      -0.05641068145632744,
      -0.04216883331537247,
      0.0800090879201889,
      -0.0691554918885231,
      0.01648920588195324,
      -0.039324093610048294,
      -0.06177340820431709,
      -0.124350406229496,
      -0.007412481587380171,
      0.022643573582172394,
      0.10727916657924652,
      -0.03608294948935509,
      -0.017640206962823868,
      0.10903126001358032,
      -0.06399626284837723,
      -0.09237363934516907,
      0.03619282320141792,
      -0.028913097456097603,
      0.05439755320549011,
      0.00979328341782093,
      -8.368125418201089e-05,
      0.027922239154577255,
      0.019075557589530945,
      -0.06387190520763397,
      0.0436943918466568,
      0.007050241809338331,
      0.03205746412277222,
      0.00681346096098423,
      -0.03530692309141159,
      0.08577942848205566,
      -0.08745383471250534,
      0.011196763254702091,
      0.08288037776947021,
      -0.013273698277771473,
      0.0015902158338576555,
      -0.08848241716623306,
      -0.058838099241256714,
      0.018241338431835175,
      -0.08830532431602478,
      0.01836390607059002,
      -0.013770397752523422,
      0.018218040466308594,
      -0.03192410245537758,
      0.02996216155588627,
      -0.13125820457935333,
      0.013288590125739574,
      0.010770633816719055,
      0.006985903717577457,
      0.004391241353005171,
      -0.01975863054394722,
      0.018200555816292763,
      -0.0004206107696518302,
      -0.018436720594763756,
      -0.0017084255814552307,
      -0.08310914039611816,
      0.04922160506248474,
      -0.0502360463142395,
      0.09464108198881149,
      -0.042611971497535706,
      -0.04412945732474327,
      -0.022868307307362556,
      0.05489317327737808,
      -0.020136257633566856,
      0.06378723680973053,
      0.018374666571617126,
      -0.02317032776772976,
      -0.028276413679122925,
      -0.0543617345392704,
      -0.031993087381124496,
      -0.008009715937077999,
      0.011643143370747566,
      0.04740253835916519,
      -0.003346169600263238,
      -0.0161543358117342,
      0.03747158497571945,
      -0.09268506616353989,
      0.025321796536445618,
      0.11041349917650223,
      -0.016798313707113266,
      0.04602065682411194,
      -0.039168428629636765,
      -0.02529916912317276,
      0.017467938363552094,
      -0.024894826114177704,
      -0.08608006685972214,
      0.015207892283797264,
      0.0364251583814621,
      0.012564712204039097,
      -0.05538792908191681,
      -0.040650375187397,
      0.028436925262212753,
      -0.07867404073476791,
      0.011483034119009972,
      -0.03560836613178253,
      0.030179938301444054,
      0.009597172960639,
      0.004858598578721285,
      -0.057709481567144394,
      0.014621281065046787,
      0.07130115479230881,
      0.007614476140588522,
      0.022721052169799805,
      -0.016689755022525787,
      -0.01611115224659443,
      0.031284965574741364,
      0.0018542709294706583,
      0.08898032456636429,
      0.021549629047513008,
      -0.046590156853199005,
      -0.06635772436857224,
      -0.021949192509055138,
      -0.06873999536037445,
      -0.0011887962464243174,
      -0.11017350107431412,
      0.002078497549518943,
      -0.05293027311563492,
      -0.028782209381461143,
      0.0576695054769516,
      -0.006477476563304663,
      0.03297029435634613,
      0.0056586191058158875,
      -0.00833985861390829,
      -0.03333795443177223,
      -0.061276763677597046,
      -0.035174526274204254,
      0.09827980399131775,
      0.06876012682914734,
      -0.004869213793426752,
      0.011368653737008572,
      0.024886764585971832,
      0.08307584375143051,
      -0.08590429276227951,
      0.03261866793036461,
      -0.016055019572377205,
      0.011958926916122437,
      -0.04468396306037903,
      -0.011499030515551567,
      -0.0011312155984342098,
      0.054911911487579346,
      0.09166140854358673,
      -0.08382943272590637,
      0.049559589475393295,
      0.013527508825063705,
      0.051658619195222855,
      0.005174948833882809,
      0.0025302967987954617,
      0.04816483333706856,
      -0.025087211281061172,
      -0.031050141900777817,
      -0.0705166682600975,
      -0.03636341914534569,
      0.004726600367575884,
      -0.08820290118455887,
      -0.03450740873813629,
      -0.02411126345396042,
      0.013871383853256702,
      -0.049313634634017944,
      0.009112261235713959,
      -0.026293741539120674,
      -0.025358080863952637,
      -0.002945702988654375,
      0.027240492403507233,
      0.02801169641315937,
      -0.042737510055303574,
      -0.06518802046775818,
      0.01277193333953619,
      0.0211607925593853,
      0.024712201207876205,
      -0.037311043590307236,
      0.05029750242829323,
      -0.012594940140843391,
      -0.012570589780807495,
      -0.05881704390048981,
      0.03832739219069481,
      0.030854463577270508,
      0.059523917734622955,
      -0.04517577588558197,
      -0.054970744997262955,
      -0.019671231508255005,
      -0.08189027011394501,
      0.0003374704974703491,
      -0.0852746069431305,
      0.007926900871098042,
      -0.03211149573326111,
      0.0017830695724114776,
      -0.004013761878013611,
      -0.060823023319244385,
      -0.0136893130838871,
      -0.021689243614673615,
      0.02988761104643345,
      0.03798437863588333,
      0.014696861617267132,
      -0.05293542146682739,
      0.01501444447785616,
      0.005013181362301111,
      0.0706915333867073,
      0.1237344741821289,
      0.043355245143175125,
      0.008679354563355446,
      0.033492572605609894,
      0.0362335741519928,
      0.01152956485748291,
      0.0569741390645504,
      -0.0742291659116745,
      0.005802857223898172,
      0.011291944421827793,
      -0.015126986429095268,
      -0.06440910696983337,
      0.010627885349094868,
      0.05634947121143341,
      0.003119114553555846,
      0.01619144342839718,
      0.03343695402145386,
      -0.004865699447691441,
      -0.07998988032341003,
      0.043262798339128494,
      0.06900322437286377,
      0.043595198541879654,
      -0.08895177394151688,
      0.05852476879954338,
      -0.04629625007510185,
      0.007982954382896423,
      0.006910391617566347,
      -0.07172117382287979,
      0.0053270854987204075,
      0.0066582215949893,
      0.034346722066402435,
      -0.06173298880457878,
      -0.10810506343841553,
      -0.1151161938905716,
      0.03369846194982529,
      -0.026062985882163048,
      -0.02338387630879879,
      0.07198398560285568,
      0.040417224168777466,
      0.00953923910856247,
      -0.02904314547777176,
      0.044151633977890015,
      0.06856636703014374,
      -0.058429643511772156,
      0.00740636745467782,
      -0.0018596223089843988,
      -0.06308934837579727,
      0.024310309439897537,
      -0.044592615216970444,
      -0.02658342942595482,
      -0.03516501560807228,
      0.034751854836940765,
      -0.037943802773952484,
      0.021981574594974518,
      0.10823829472064972,
      -0.027999574318528175,
      0.0460941344499588,
      -0.030356094241142273,
      -0.026083150878548622,
      -0.14132314920425415,
      0.08248793333768845,
      0.0006633067969232798,
      0.002695494331419468,
      -0.013579084537923336,
      0.0076998090371489525,
      -0.0008484732243232429,
      0.10384557396173477,
      0.03911367803812027,
      0.024978157132864,
      0.008781364187598228,
      -0.025034883990883827,
      -0.09650126099586487,
      0.04749263823032379,
      -0.0004722484154626727,
      -0.0027246291283518076,
      0.02101217582821846
    ],
    [
      -0.0704689621925354,
      0.00500974478200078,
      0.04441390931606293,
      0.06344057619571686,
      0.04198739305138588,
      -0.010928560979664326,
      0.028169354423880577,
      0.014314274303615093,
      0.0580982007086277,
      0.02736162766814232,
      0.03101162053644657,
      0.04121502861380577,
      0.011968648061156273,
      -0.07945800572633743,
      -0.010590814985334873,
      -0.00489719258621335,
      -0.04065796732902527,
      -0.01395259890705347,
      0.05971872806549072,
      -0.05130339413881302,
      0.021137002855539322,
      -0.008613859303295612,
      0.019487271085381508,
      -0.010439102537930012,
      0.012230172753334045,
      -0.038496144115924835,
      0.014037856832146645,
      -0.004585516173392534,
      -0.004979603458195925,
      0.020408090204000473,
      -0.0771508663892746,
      -0.016675040125846863,
      -0.02606883831322193,
      -0.015119662508368492,
      -0.0380888432264328,
      0.03706920146942139,
      -0.03849535807967186,
      -0.011438950896263123,
      -0.1622643917798996,
      -0.013173539191484451,
      0.02005925588309765,
      0.06396409869194031,
      0.03352240100502968,
      0.02589932456612587,
      0.04877568036317825,
      -0.016323745250701904,
      0.0430782325565815,
      -0.04923386871814728,
      -0.07724156975746155,
      0.014418018981814384,
      -0.008176423609256744,
      -0.003689549397677183,
      0.04155039042234421,
      0.007067590486258268,
      0.07118021696805954,
      -0.06614523380994797,
      -0.060424018651247025,
      0.021504372358322144,
      -0.029349254444241524,
      0.10733816772699356,
      0.013509070500731468,
      -0.06081559509038925,
      0.04564670845866203,
      0.004509002901613712,
      -0.018828094005584717,
      0.022632502019405365,
      0.0492226779460907,
      -0.053321823477745056,
      -0.03304193541407585,
      -0.07131047546863556,
      0.06957141309976578,
      -0.047078944742679596,
      -0.0037991737481206656,
      0.02883896604180336,
      0.10731053352355957,
      0.0022338072303682566,
      0.0036877021193504333,
      -0.022497355937957764,
      0.09681901335716248,
      0.05530350282788277,
      -0.04227306321263313,
      0.056498683989048004,
      0.009517200291156769,
      0.018068987876176834,
      0.030383944511413574,
      -0.05153011530637741,
      0.022088365629315376,
      0.007135444786399603,
      0.07940656691789627,
      0.013336720876395702,
      0.011960831470787525,
      0.07584734261035919,
      -0.02882242761552334,
      0.039143435657024384,
      -0.01591680385172367,
      -0.08376467227935791,
      -0.08575505018234253,
      -0.05086496099829674,
      -0.026082638651132584,
      0.042773474007844925,
      0.022838950157165527,
      0.03477640077471733,
      -0.03752407431602478,
      0.015009946189820766,
      -0.0304547268897295,
      -0.005901177413761616,
      -0.053404804319143295,
      0.08214253932237625,
      0.06706885248422623,
      -0.0014519216492772102,
      -0.009237061254680157,
      -0.04346608743071556,
      -0.004206038545817137,
      -0.04843590781092644,
      -0.068184994161129,
      0.05166873335838318,
      0.06726637482643127,
      0.03610226511955261,
      0.013954968191683292,
      0.022667238488793373,
      0.03107363171875477,
      0.07499096542596817,
      0.05348731949925423,
      0.033792439848184586,
      -0.0030691814608871937,
      -0.0478471964597702,
      -0.04086127132177353,
      -0.038400646299123764,
      -0.067532978951931,
      -0.02583075314760208,
      -0.015049370005726814,
      -0.003195048775523901,
      -0.017707468941807747,
      -0.023973530158400536,
      0.0227215513586998,
      0.0712587982416153,
      -0.044992510229349136,
      -0.01367755327373743,
      0.053299032151699066,
      0.034920621663331985,
      0.04016101732850075,
      0.015301861800253391,
      0.07786191254854202,
      0.038372863084077835,
      0.037071891129016876,
      -0.09190617501735687,
      -0.028856627643108368,
      0.03735906258225441,
      -0.028928793966770172,
      -0.014147469773888588,
      0.010472468100488186,
      -0.03446035832166672,
      -0.05153921619057655,
      0.06671455502510071,
      0.0651554986834526,
      -0.07213641703128815,
      0.025825710967183113,
      -0.014250490814447403,
      0.017544778063893318,
      -0.029431656002998352,
      -0.06644782423973083,
      -0.025841353461146355,
      0.037581056356430054,
      0.050967127084732056,
      -0.04644190892577171,
      0.011158260516822338,
      0.012880240567028522,
      0.10746657103300095,
      -0.01659870147705078,
      -0.03497368469834328,
      -0.11882052570581436,
      0.008155503310263157,
      0.06114897131919861,
      -0.02805771678686142,
      0.04410848394036293,
      -0.01999952830374241,
      0.022132741287350655,
      -0.06011560559272766,
      0.03327043354511261,
      -0.02537139318883419,
      -0.005257760174572468,
      -0.019230853766202927,
      -0.006867723073810339,
      0.024570219218730927,
      -0.0058804345317184925,
      -0.05505780875682831,
      0.04096294194459915,
      -0.01059651467949152,
      0.053090181201696396,
      0.09456575661897659,
      0.04338505119085312,
      -0.0018944311887025833,
      0.04207432642579079,
      -0.0019865992944687605,
      -0.026186956092715263,
      -0.08049455285072327,
      0.010215322487056255,
      -0.10349562019109726,
      -0.04617835581302643,
      0.0416434109210968,
      -0.0717184767127037,
      -0.08789194375276566,
      0.039022717624902725,
      -0.10454713553190231,
      -0.03972218930721283,
      0.014156965538859367,
      0.012647689320147038,
      0.07296827435493469,
      0.05320477485656738,
      -0.03061862103641033,
      0.05585186183452606,
      0.07418173551559448,
      0.01024574227631092,
      0.01759885996580124,
      -0.05795280635356903,
      -0.0621299110352993,
      0.03550608828663826,
      0.008456040173768997,
      0.033502403646707535,
      0.07387557625770569,
      -0.023249438032507896,
      0.0529472678899765,
      0.026729421690106392,
      0.004250125959515572,
      -0.11507491022348404,
      -0.02144302800297737,
      0.004149091430008411,
      0.009701346978545189,
      -0.008469238877296448,
      0.05138000100851059,
      0.002736445050686598,
      -0.02997424826025963,
      0.04862862825393677,
      0.06227114051580429,
      -0.007931310683488846,
      0.025934968143701553,
      -0.032189276069402695,
      -0.0176651980727911,
      -0.01650259457528591,
      -0.022047007456421852,
      -0.008026820607483387,
      -0.052092377096414566,
      -0.004676337819546461,
      -0.05428474023938179,
      0.046493928879499435,
      -0.033159371465444565,
      -0.019336242228746414,
      -0.05454258993268013,
      -0.08224809169769287,
      -0.01823020912706852,
      0.026038825511932373,
      0.008080851286649704,
      0.023616930469870567,
      0.016749190166592598,
      0.04639396816492081,
      -0.04746946319937706,
      -0.0055967955850064754,
      0.02324896678328514,
      -0.033339206129312515,
      -0.04389774799346924,
      0.044762562960386276,
      0.059218887239694595,
      -0.038880426436662674,
      0.023669175803661346,
      -0.008142638951539993,
      -0.03963027894496918,
      0.0211231280118227,
      0.009613067843019962,
      -0.024242812767624855,
      -0.04238663613796234,
      0.010263191536068916,
      -0.06263823807239532,
      0.06869412958621979,
      -0.007862797006964684,
      -0.02303709089756012,
      0.016515681520104408,
      0.006965276785194874,
      0.10420817136764526,
      0.019069112837314606,
      0.005333518609404564,
      0.11387868225574493,
      -0.03519942983984947,
      -0.019413866102695465,
      -0.07538177818059921,
      -0.04348219558596611,
      0.010668386705219746,
      0.0004806422221008688,
      0.004408959299325943,
      0.027627434581518173,
      0.030301231890916824,
      -0.026484286412596703,
      0.012102600187063217,
      -0.001199187128804624,
      0.02878628484904766,
      0.04209088906645775,
      0.06236564740538597,
      -0.05351144075393677,
      0.07889194786548615,
      -0.051526837050914764,
      0.010943800210952759,
      0.014294817112386227,
      -0.05724715441465378,
      -0.014502299018204212,
      -0.11872368305921555,
      0.00451710494235158,
      -0.04246272146701813,
      0.020978564396500587,
      -0.016175372526049614,
      0.06269792467355728,
      -0.08488640934228897,
      -0.0024658837355673313,
      0.0003527554217725992,
      0.09403957426548004,
      0.04856478422880173,
      0.10956548154354095,
      0.038139019161462784,
      0.010395912453532219,
      0.019664674997329712,
      0.04193738102912903,
      -0.021739087998867035,
      -0.03658610209822655,
      -0.05677397549152374,
      -0.003758381586521864,
      -0.02359776385128498,
      0.057726550847291946,
      0.02511400170624256,
      0.01876921020448208,
      -0.09560960531234741,
      -0.02476278506219387,
      -0.031607452780008316,
      -0.045067425817251205,
      0.05909115821123123,
      -0.016087083145976067,
      -0.08959605544805527,
      -0.0005041172844357789,
      0.026250362396240234,
      0.0006410505739040673,
      0.07150189578533173,
      0.056072015315294266,
      0.021579040214419365,
      0.029733480885624886,
      0.01763812266290188,
      -0.0031417275313287973,
      0.037211284041404724,
      -0.016538530588150024,
      -0.015232020057737827,
      0.02514219470322132,
      -0.03735006973147392,
      -0.039789602160453796,
      0.011556765995919704,
      0.04922185093164444,
      0.030054178088903427,
      -0.032591406255960464,
      0.025757407769560814,
      -0.023662343621253967,
      0.10112574696540833,
      0.006643365602940321,
      0.07860395312309265,
      -0.046511583030223846,
      0.02411443181335926,
      0.011539293453097343,
      0.00280966074205935,
      -0.020487161353230476,
      -0.020657271146774292,
      -0.09999309480190277,
      -0.011858913116157055,
      0.03119480051100254,
      0.029626088216900826,
      -0.030855564400553703,
      -0.02528819814324379,
      0.015927009284496307,
      -0.03163783997297287,
      -0.006675377022475004,
      0.017912259325385094,
      -0.09029558300971985,
      -0.015479990281164646,
      -0.005497439298778772,
      -0.07798571139574051,
      -0.0031959109473973513,
      -0.015342911705374718,
      0.03439188748598099,
      -0.018269896507263184,
      0.020472930744290352,
      -0.11602665483951569,
      0.020822549238801003,
      0.008184324018657207,
      -0.016772739589214325,
      -0.008234460838139057,
      0.10554572939872742,
      -0.00907224602997303,
      0.06129826232790947,
      0.020038776099681854,
      0.049777328968048096,
      0.009699037298560143,
      -0.0543956495821476,
      0.03808266669511795,
      -0.012822706252336502,
      0.027901722118258476,
      0.04879459738731384,
      0.0676952376961708,
      0.059431977570056915,
      -0.050130050629377365,
      0.0018303431570529938,
      -0.0611729733645916,
      -0.01706751435995102,
      0.0051117404364049435,
      -0.008074488490819931,
      -0.032449737191200256,
      -0.008884350769221783,
      0.0004432637069839984,
      -0.009721124544739723,
      0.0023790542036294937,
      0.03976760432124138,
      -0.01340028177946806,
      0.005338246934115887,
      0.07705802470445633,
      -0.03521835803985596,
      0.01840934343636036,
      0.03658408671617508,
      0.006111664231866598,
      0.0995931327342987,
      0.06937260180711746,
      0.0752510353922844,
      0.029824785888195038,
      -0.04088524356484413,
      -0.06935656815767288,
      -0.02059270441532135,
      0.009304455481469631,
      0.02718876115977764,
      -0.09139201790094376,
      -0.016592560335993767,
      -0.05325440689921379,
      -0.012864682823419571,
      -0.045264776796102524,
      0.041209876537323,
      0.05010863021016121,
      -0.013730836100876331,
      -0.05779942497611046,
      -0.08167409151792526,
      0.014386182650923729,
      -0.08454351127147675,
      -0.027871519327163696,
      0.001927709556184709,
      0.011728676967322826,
      -0.003922272473573685,
      -0.016506986692547798,
      0.043506916612386703,
      -0.057722967118024826,
      0.010997182689607143,
      0.08484352380037308,
      0.008826476521790028,
      0.0877968966960907,
      -0.019052939489483833,
      0.013279384933412075,
      0.016854768618941307,
      -0.006544927600771189,
      0.00024102409952320158,
      0.045908354222774506,
      0.08649832010269165,
      0.030945822596549988,
      -0.05079963803291321,
      0.10247079282999039,
      -0.04068436473608017,
      -0.031906336545944214,
      0.04429252818226814,
      0.049877118319272995,
      -0.04102126136422157,
      -0.04982070252299309,
      0.05196618661284447,
      -0.04296613112092018,
      -0.050732698291540146,
      0.03994959592819214,
      0.057882364839315414,
      -0.04293588176369667,
      0.08671373128890991,
      -0.029099823907017708,
      0.012008097022771835,
      -0.07763229310512543,
      -0.06569521129131317,
      0.03985724225640297,
      0.0033763982355594635,
      -0.03626815602183342,
      0.0503920279443264,
      0.005522533319890499,
      0.02176772430539131,
      -0.014327272772789001,
      -0.012698104605078697,
      0.013545186258852482,
      0.047540780156850815,
      -0.05103130266070366,
      -0.04765653982758522,
      -0.0391099750995636,
      0.04060456156730652,
      0.09155706316232681,
      0.016116421669721603,
      0.0012707042042165995,
      0.038252245634794235,
      -0.01420681457966566,
      0.057967837899923325,
      -0.004635286051779985,
      0.028873398900032043,
      -0.06984877586364746,
      0.008096152916550636
    ],
    [
      0.04315156117081642,
      0.016454264521598816,
      0.06259480863809586,
      -0.020632624626159668,
      -0.008115265518426895,
      0.007528872229158878,
      0.03640594705939293,
      0.07357590645551682,
      -0.022007115185260773,
      -0.07257316261529922,
      0.022951403632760048,
      -0.00440596928820014,
      0.019164342433214188,
      0.007681488059461117,
      -0.020095927640795708,
      -0.0323273241519928,
      -0.0329696461558342,
      0.05223175138235092,
      0.07922601699829102,
      0.06212013214826584,
      0.00693651894107461,
      -0.034309472888708115,
      0.020045815035700798,
      0.01353535894304514,
      -0.0014080529799684882,
      0.001904923003166914,
      -0.04125425964593887,
      -0.014445014297962189,
      -0.0029089092276990414,
      -0.04284445941448212,
      -0.01527128741145134,
      0.06539767980575562,
      -0.08623874932527542,
      -0.039582155644893646,
      0.041749466210603714,
      0.014986160211265087,
      -0.03175964578986168,
      0.023900043219327927,
      -0.09516476839780807,
      0.0022825945634394884,
      0.02846553735435009,
      -0.01578463613986969,
      0.04122302308678627,
      -0.003424684749916196,
      -0.004602047614753246,
      -0.04639003425836563,
      0.09028547257184982,
      0.059741757810115814,
      -0.0573134571313858,
      -0.021675176918506622,
      0.011404563672840595,
      0.06447529047727585,
      -0.036628395318984985,
      0.044217295944690704,
      0.004637142177671194,
      -0.017803937196731567,
      0.022826263681054115,
      0.012017996050417423,
      0.051555052399635315,
      -0.011216654442250729,
      0.05272778496146202,
      0.05857475474476814,
      0.046405963599681854,
      -0.06183785945177078,
      0.03737638145685196,
      -0.03854818269610405,
      -0.028568319976329803,
      0.04085106775164604,
      -0.012057483196258545,
      -0.0670720785856247,
      0.0006123940111137927,
      -0.05995429679751396,
      0.07542777806520462,
      -0.04059009999036789,
      -0.08840952813625336,
      -0.045651596039533615,
      0.016870614141225815,
      0.0070382654666900635,
      -0.01754489354789257,
      -0.0482662208378315,
      -0.005556735675781965,
      -0.042550329118967056,
      0.01333694253116846,
      0.05473770201206207,
      -0.03917248174548149,
      -0.05340565741062164,
      0.05143161863088608,
      -0.005979431793093681,
      0.045028917491436005,
      0.03532140702009201,
      -0.028513355180621147,
      0.0200252216309309,
      -0.014803008176386356,
      -0.008052795194089413,
      -0.044372204691171646,
      -0.006260852329432964,
      0.04162837564945221,
      -0.03567560389637947,
      0.007846357300877571,
      -0.011680271476507187,
      0.030194256454706192,
      0.07516419887542725,
      0.03761785104870796,
      0.046720899641513824,
      0.0069940644316375256,
      0.008586005307734013,
      -0.05915850028395653,
      -0.09379120171070099,
      0.048851724714040756,
      0.001086547039449215,
      0.03158888220787048,
      0.013450461439788342,
      0.02401348575949669,
      -0.0942518338561058,
      0.025840403512120247,
      -0.0031081344932317734,
      0.0004883413203060627,
      0.0210749302059412,
      -0.01266429852694273,
      0.05192762613296509,
      -0.009941454976797104,
      -0.011826026253402233,
      0.03516077995300293,
      -0.0743328109383583,
      0.0964004397392273,
      0.05319739133119583,
      -0.0009166492382064462,
      -0.055854327976703644,
      0.010638238862156868,
      0.036170028150081635,
      0.026231830939650536,
      0.029150590300559998,
      -0.004593114834278822,
      0.08456747978925705,
      0.06707030534744263,
      -0.018924640491604805,
      -0.036782462149858475,
      -0.06694850325584412,
      -0.042745333164930344,
      -0.04955572262406349,
      0.01403940748423338,
      -0.11028950661420822,
      0.06530068814754486,
      0.055436450988054276,
      0.044493597000837326,
      0.07667648792266846,
      0.02923980914056301,
      -0.010587343014776707,
      -0.04294741898775101,
      -0.017395664006471634,
      -0.0010131554445251822,
      -0.04154409095644951,
      -0.0053986734710633755,
      0.05323495343327522,
      0.0053252349607646465,
      -0.050656650215387344,
      0.022360123693943024,
      -0.025870228186249733,
      0.030210070312023163,
      -0.019886117428541183,
      0.010615325532853603,
      0.04947082698345184,
      0.03352852165699005,
      -0.026413341984152794,
      -0.011329561471939087,
      -0.051652438938617706,
      -0.059722837060689926,
      -0.02513882704079151,
      0.0376037172973156,
      0.0328245535492897,
      -0.0028908096719533205,
      0.03628064692020416,
      0.01814918778836727,
      -0.06394520401954651,
      -0.02762327902019024,
      -0.06010234355926514,
      -0.04996291920542717,
      -0.05832082778215408,
      0.013095826841890812,
      -0.2159171998500824,
      0.0025498229078948498,
      0.008062939159572124,
      0.01681569032371044,
      0.006233404390513897,
      0.03240559622645378,
      0.009219327010214329,
      -0.04027233645319939,
      -0.02003275603055954,
      0.06004813313484192,
      -0.02647051215171814,
      0.019838886335492134,
      -0.03854444995522499,
      0.03626119717955589,
      0.048822224140167236,
      -0.07588685303926468,
      0.008625192567706108,
      0.04522841423749924,
      -0.024277804419398308,
      0.005628487560898066,
      0.06787992268800735,
      0.04254606366157532,
      -0.004255901090800762,
      -0.027840502560138702,
      0.06811518222093582,
      -0.01959906332194805,
      -0.02557716704905033,
      -0.008880019187927246,
      -0.016728537157177925,
      -0.04150579497218132,
      0.033328354358673096,
      0.04544255509972572,
      0.001508044428192079,
      -0.04514303430914879,
      -0.055441487580537796,
      0.00105262640863657,
      0.052519090473651886,
      0.023470502346754074,
      0.035215411335229874,
      0.014949970878660679,
      -0.008644619025290012,
      -0.052477460354566574,
      -0.02158818021416664,
      0.032521359622478485,
      -0.07706362754106522,
      -0.040595024824142456,
      -0.06471440196037292,
      -0.04735870659351349,
      0.07035240530967712,
      0.058246951550245285,
      -0.014000451192259789,
      -0.0015762007096782327,
      -0.06415876746177673,
      0.03818367421627045,
      -0.09871894121170044,
      -0.0519014410674572,
      0.06439824402332306,
      -0.029245007783174515,
      -0.010309656150639057,
      -0.03205306455492973,
      0.044684868305921555,
      0.02499973773956299,
      0.02679319866001606,
      -0.08423452079296112,
      -0.0327836237847805,
      -0.00021409420878626406,
      -0.07005928456783295,
      -0.014111703261733055,
      -0.01875998079776764,
      0.10005699098110199,
      -0.03642059117555618,
      -0.002629860769957304,
      -0.014762155711650848,
      0.0688004121184349,
      -0.015854153782129288,
      0.02699238806962967,
      0.10796422511339188,
      0.05145562067627907,
      0.11150713264942169,
      0.052632492035627365,
      -0.07449125498533249,
      -0.002427414758130908,
      -0.05358858406543732,
      0.04102582111954689,
      0.0002636677527334541,
      -0.038166582584381104,
      0.048905372619628906,
      0.017547445371747017,
      0.059772416949272156,
      -0.007398953195661306,
      0.06986606866121292,
      0.10005509853363037,
      0.03366192802786827,
      -0.07376720756292343,
      0.03123653493821621,
      -0.007738566491752863,
      0.07063283026218414,
      -0.03218305483460426,
      0.03140468895435333,
      0.010349774733185768,
      -0.013112076558172703,
      -0.041918061673641205,
      -0.034596532583236694,
      0.1147034615278244,
      0.013070928864181042,
      -0.049952615052461624,
      -0.04177938774228096,
      0.07814958691596985,
      0.04483044147491455,
      -0.060058847069740295,
      -0.03578978776931763,
      -0.03988907113671303,
      0.020271703600883484,
      -0.018281077966094017,
      0.07783160358667374,
      -0.12710241973400116,
      0.06404437124729156,
      0.04982195049524307,
      0.0021374791394919157,
      0.029453283175826073,
      -0.027256373316049576,
      -0.012100188061594963,
      0.03133794292807579,
      0.03360284864902496,
      -0.06404997408390045,
      0.04217915236949921,
      0.04449057951569557,
      -0.026563361287117004,
      0.02065553329885006,
      -0.05422958359122276,
      -0.009742874652147293,
      0.0753955990076065,
      0.05282846465706825,
      -0.022072769701480865,
      -6.074315024306998e-07,
      -0.02783956378698349,
      -0.011252845637500286,
      0.049224190413951874,
      -0.0006375771481543779,
      -0.002272672252729535,
      0.04263285920023918,
      -0.014717995189130306,
      0.02541586384177208,
      0.03639303892850876,
      0.09125002473592758,
      -0.041240088641643524,
      -0.030613001435995102,
      -0.015731927007436752,
      0.04376713186502457,
      -0.025051623582839966,
      0.051977481693029404,
      0.033716391772031784,
      0.04305340722203255,
      -0.032223496586084366,
      0.03160107135772705,
      0.004554701503366232,
      -0.017623046413064003,
      -0.026574717834591866,
      -0.023762164637446404,
      -0.06101326271891594,
      0.09772564470767975,
      0.014092503115534782,
      -0.03231903165578842,
      -0.0018716920167207718,
      0.060278188437223434,
      0.01016127597540617,
      0.009951425716280937,
      -0.018920639529824257,
      -0.01656201109290123,
      -0.10761609673500061,
      0.011763363145291805,
      0.00931675173342228,
      0.03051082417368889,
      0.030289635062217712,
      0.02728240191936493,
      0.091192826628685,
      0.06437366455793381,
      0.054702214896678925,
      -0.012895122170448303,
      -0.08247275650501251,
      -0.007991332560777664,
      -0.019058536738157272,
      -0.017613926902413368,
      -0.08680564165115356,
      0.0781763568520546,
      0.023944109678268433,
      -0.027964778244495392,
      -0.05341906100511551,
      0.05059042200446129,
      0.03539855033159256,
      0.017242662608623505,
      0.023706501349806786,
      -0.05877211317420006,
      0.025243444368243217,
      -0.01857992634177208,
      -0.005976254120469093,
      -0.04060032218694687,
      -0.014920402318239212,
      -0.08956287801265717,
      0.07840850949287415,
      0.07476125657558441,
      -0.008925446309149265,
      -0.017437268048524857,
      0.0644741952419281,
      0.11787581443786621,
      0.036757905036211014,
      -0.05773719400167465,
      0.03376267850399017,
      -0.054954394698143005,
      0.05008595809340477,
      0.04400727525353432,
      0.041618816554546356,
      -0.09636010974645615,
      0.06803078204393387,
      -0.025384381413459778,
      -0.06305515021085739,
      0.029045233502984047,
      -0.051944125443696976,
      0.02588394284248352,
      -0.009956680238246918,
      0.01625080779194832,
      0.0009672114392742515,
      -0.007543348707258701,
      -0.013401488773524761,
      -0.03165096044540405,
      0.07653411477804184,
      0.00524204270914197,
      0.05671814829111099,
      -0.026506902649998665,
      -0.002045499160885811,
      -0.0073234341107308865,
      -0.05708549544215202,
      0.03098766691982746,
      -0.08263375610113144,
      -0.014061189256608486,
      -0.02811802737414837,
      0.03163905814290047,
      -0.039507750421762466,
      -0.04054300859570503,
      -0.016698326915502548,
      0.0925920233130455,
      0.03428635746240616,
      -0.07419870793819427,
      -0.05962361767888069,
      -0.06374373286962509,
      -0.02888280339539051,
      -0.014142886735498905,
      -0.07942645251750946,
      0.0005429197917692363,
      0.014346525073051453,
      -0.023286519572138786,
      0.046101029962301254,
      0.08255545794963837,
      -0.03404375910758972,
      -0.029248874634504318,
      -0.033704861998558044,
      0.004633495118469,
      -0.027321862056851387,
      0.13917729258537292,
      -0.023488445207476616,
      -0.03443321958184242,
      -0.03831791877746582,
      0.10540864616632462,
      0.04230613261461258,
      0.0050632948987185955,
      0.02721044048666954,
      0.06033284217119217,
      0.004575489088892937,
      -0.019338980317115784,
      -0.06855911761522293,
      -0.04516182839870453,
      0.03855953365564346,
      0.051190584897994995,
      0.05262182652950287,
      0.013503323309123516,
      -0.044488850980997086,
      0.06858113408088684,
      0.05583038553595543,
      0.004931091330945492,
      0.054062578827142715,
      0.00841254461556673,
      -0.03323284536600113,
      0.06207754835486412,
      0.11633750796318054,
      0.03820453956723213,
      0.05085807666182518,
      0.0482039712369442,
      -0.04634875804185867,
      -0.022907201200723648,
      -0.017370032146573067,
      -0.043225549161434174,
      -0.09251286089420319,
      -0.04893813654780388,
      0.06267598271369934,
      -0.031163642182946205,
      0.019108707085251808,
      0.052136339247226715,
      -0.035488829016685486,
      0.026908690109848976,
      0.008929777890443802,
      0.03641409054398537,
      0.007607420440763235,
      0.0746120885014534,
      0.009894791059195995,
      -0.001685634721070528,
      -0.07455503940582275,
      -0.06645888090133667,
      0.024164674803614616,
      0.02433386631309986,
      -0.019097618758678436,
      -0.003950948361307383,
      -0.0526680164039135,
      0.06195894256234169,
      0.04082025960087776,
      0.050789665430784225,
      -0.06459774821996689,
      0.019190378487110138,
      0.011924595572054386,
      0.014643794856965542,
      0.0400872640311718,
      0.08239864557981491,
      0.005164600908756256,
      -0.015037774108350277,
      0.014367279596626759
    ],
    [
      0.026453889906406403,
      0.057244542986154556,
      0.032855547964572906,
      0.07680997997522354,
      -0.0530032254755497,
      0.011273144744336605,
      0.05325920879840851,
      -0.05556625872850418,
      0.013912721537053585,
      -0.034608520567417145,
      0.07317116111516953,
      -0.02144406922161579,
      -0.04046086594462395,
      -0.0016227292362600565,
      0.0023623318411409855,
      -0.04448332637548447,
      -0.035191621631383896,
      0.04150865226984024,
      -0.020426427945494652,
      0.025394484400749207,
      0.002234808634966612,
      -0.003559482516720891,
      0.01241140067577362,
      -0.04385260492563248,
      0.030443942174315453,
      -0.046650663018226624,
      0.06282459944486618,
      0.07522595673799515,
      -0.023579180240631104,
      -0.007678996305912733,
      -0.10099466145038605,
      -0.002697237301617861,
      0.013802619650959969,
      -0.03466862067580223,
      0.058604709804058075,
      0.05114565044641495,
      0.018044399097561836,
      -0.026342693716287613,
      -0.09083756059408188,
      0.023668240755796432,
      -0.007993951439857483,
      0.024560533463954926,
      0.018164757639169693,
      -0.04533005133271217,
      0.04417538642883301,
      -0.06586451083421707,
      -0.0250861756503582,
      -0.010397261008620262,
      0.009203385561704636,
      0.06772874295711517,
      -0.010190801694989204,
      -0.005613347515463829,
      0.03975248709321022,
      0.06010910123586655,
      -0.040547728538513184,
      0.03362102061510086,
      0.11748143285512924,
      -0.045594219118356705,
      -0.03318130224943161,
      0.0167715921998024,
      0.06049690395593643,
      0.012406115420162678,
      0.007835589349269867,
      -0.029047859832644463,
      0.05506090819835663,
      0.013525506481528282,
      -0.04042448475956917,
      -0.03554537519812584,
      -0.021133067086338997,
      -0.010371612384915352,
      0.017479941248893738,
      -0.04351979121565819,
      -0.03446497768163681,
      0.021865062415599823,
      -0.01823158748447895,
      -0.00787617452442646,
      0.006497097201645374,
      -0.08761109411716461,
      -0.06178568676114082,
      0.02785617485642433,
      0.04301279038190842,
      -0.09585045278072357,
      0.04039185494184494,
      -0.05212092772126198,
      -0.010836543515324593,
      -0.04829483479261398,
      -0.002494492568075657,
      0.00847494788467884,
      0.054947685450315475,
      -0.030742766335606575,
      -0.05809640511870384,
      0.059780437499284744,
      0.05874931067228317,
      0.0031948434188961983,
      -0.02069508470594883,
      0.07538197189569473,
      -0.06883087754249573,
      -0.007917305454611778,
      -0.06184579059481621,
      -0.05166148394346237,
      0.0016205088468268514,
      0.012951578944921494,
      -0.064670130610466,
      0.05188244208693504,
      -0.08692890405654907,
      -0.005960988812148571,
      0.008451034314930439,
      0.011519143357872963,
      -0.01793430931866169,
      -0.0010041287168860435,
      -0.0229637548327446,
      0.035907063633203506,
      0.024434581398963928,
      -0.04131918400526047,
      0.0035526473075151443,
      -0.050658632069826126,
      0.03714520484209061,
      -0.03300216421484947,
      0.017512045800685883,
      0.01780443824827671,
      -0.02600204199552536,
      0.06490082293748856,
      -0.00867118313908577,
      -0.06317111849784851,
      0.0655696839094162,
      0.02722587250173092,
      0.04691615700721741,
      -0.03040526621043682,
      -0.012219921685755253,
      0.019542647525668144,
      0.1192011833190918,
      0.015546522103250027,
      -0.00208057533018291,
      -0.037986159324645996,
      -0.003209382528439164,
      0.028577525168657303,
      0.059899501502513885,
      -0.04987652972340584,
      0.03618040680885315,
      0.001016960246488452,
      0.021549658849835396,
      -0.04292349889874458,
      0.03432978317141533,
      0.0281293336302042,
      0.09321828931570053,
      0.027277318760752678,
      0.09135798364877701,
      0.028590256348252296,
      0.03066343255341053,
      -0.06498416513204575,
      -0.002153770998120308,
      -0.03292948380112648,
      -0.11256186664104462,
      -0.030077196657657623,
      -0.03950061649084091,
      0.002511320635676384,
      -0.015708979219198227,
      -0.06804783642292023,
      0.024179456755518913,
      0.011583754792809486,
      0.014452170580625534,
      -0.0342562310397625,
      -0.048401881009340286,
      0.008665535598993301,
      -0.12352852523326874,
      -0.02464814856648445,
      0.003430356038734317,
      -0.09669270366430283,
      0.028248893097043037,
      0.025339629501104355,
      -0.05019141733646393,
      0.06888395547866821,
      0.008186793886125088,
      0.04091053456068039,
      0.008612319827079773,
      0.04857955500483513,
      -0.030048610642552376,
      0.030930131673812866,
      0.011219749227166176,
      -0.05033198371529579,
      -0.015547934919595718,
      -0.07402116060256958,
      0.0022123216185718775,
      0.051645804196596146,
      0.03482937440276146,
      -0.031531836837530136,
      -0.0150765310972929,
      -0.011263217777013779,
      0.057744067162275314,
      0.016159890219569206,
      -0.035061512142419815,
      0.0856972262263298,
      -0.009149070829153061,
      -0.03967185690999031,
      -0.051451150327920914,
      0.10523922741413116,
      0.051213543862104416,
      -0.07446089386940002,
      -0.041915714740753174,
      0.04812028259038925,
      0.0540323369204998,
      -0.06075265631079674,
      0.012244537472724915,
      -0.023272613063454628,
      0.01979474350810051,
      0.01583181507885456,
      -0.01074252836406231,
      0.025209220126271248,
      -0.08543957024812698,
      0.0404990091919899,
      -0.041676878929138184,
      -0.031341999769210815,
      -0.08299112319946289,
      -0.03924507647752762,
      -0.06552574783563614,
      -0.031981777399778366,
      0.00579530093818903,
      0.01727277599275112,
      -0.009746233932673931,
      -0.023404667153954506,
      -0.003685431554913521,
      0.014143696054816246,
      0.0008156369440257549,
      0.04216739162802696,
      0.03148317709565163,
      0.03600240871310234,
      0.08972041308879852,
      -0.06499631702899933,
      0.037020403891801834,
      -0.043808501213788986,
      -0.014836928807199001,
      0.004091543145477772,
      -0.03697385638952255,
      -0.03187819570302963,
      -0.12457912415266037,
      -0.047606728971004486,
      0.029027864336967468,
      -0.08555512130260468,
      -0.00831887312233448,
      0.022511884570121765,
      0.08553773909807205,
      -0.0009301296668127179,
      -0.050537001341581345,
      -0.053524862974882126,
      0.01865118369460106,
      -0.05299306660890579,
      -0.01542434561997652,
      0.031987641006708145,
      -0.006925253197550774,
      -0.05322030559182167,
      0.08453203737735748,
      -0.061738256365060806,
      -0.04424571245908737,
      0.01234782300889492,
      -0.04123002663254738,
      0.008286673575639725,
      -0.011724148876965046,
      0.02175978012382984,
      0.04209797456860542,
      -0.03698037564754486,
      -0.013068174012005329,
      0.04049728810787201,
      0.009421753697097301,
      0.00043900482705794275,
      -0.05412779003381729,
      0.001461958629079163,
      -0.024141067638993263,
      -0.05115311220288277,
      0.06005724146962166,
      0.014595171436667442,
      0.0666922852396965,
      0.018007708713412285,
      -0.051688238978385925,
      -0.009627261199057102,
      0.050092678517103195,
      0.03024003840982914,
      -0.038218915462493896,
      0.10547630488872528,
      -0.038290418684482574,
      0.025896938517689705,
      0.04466215521097183,
      0.006660639774054289,
      -0.024091873317956924,
      0.05026484653353691,
      -0.06248311698436737,
      -0.045148734003305435,
      0.005937641952186823,
      0.07486718147993088,
      0.050504811108112335,
      0.02470548450946808,
      -0.0022727872710675,
      0.021112198010087013,
      0.00021765334531664848,
      0.004623263143002987,
      -0.004897839855402708,
      -0.03131817653775215,
      0.04961690306663513,
      -0.04406096041202545,
      -0.05038432776927948,
      0.03811967745423317,
      -0.002867559203878045,
      0.03356096148490906,
      0.014007476158440113,
      0.022480841726064682,
      -0.06938822567462921,
      -0.023766715079545975,
      -0.015512858517467976,
      -0.03408999368548393,
      -0.01489426102489233,
      -0.031638648360967636,
      0.004860435146838427,
      -0.0384439080953598,
      -0.015640927478671074,
      0.027841340750455856,
      -0.0645429790019989,
      -0.017826838418841362,
      0.05566065385937691,
      -0.059317801147699356,
      -0.008407494984567165,
      0.016583183780312538,
      0.023238785564899445,
      -0.014603173360228539,
      -0.011304977349936962,
      0.10809678584337234,
      0.07275912165641785,
      -0.058914944529533386,
      0.03533202409744263,
      -0.027363255620002747,
      -0.029683351516723633,
      0.03305845707654953,
      -0.03980293124914169,
      0.030509021133184433,
      -0.0677981972694397,
      0.0018504851032048464,
      -0.029702203348279,
      -0.013145798817276955,
      0.02124932035803795,
      -0.031824130564928055,
      0.021154530346393585,
      0.017315881326794624,
      0.03642573580145836,
      -0.05904005095362663,
      0.030617231503129005,
      -0.07753819227218628,
      -0.01973983459174633,
      0.015902744606137276,
      0.03894210234284401,
      0.031739745289087296,
      0.04800207167863846,
      -0.0036339443176984787,
      0.008602394722402096,
      0.012662576511502266,
      -0.011857257224619389,
      -0.06333674490451813,
      0.024892188608646393,
      -0.021999189630150795,
      0.0016725938767194748,
      0.010314887389540672,
      0.008125870488584042,
      0.060207221657037735,
      0.0669553354382515,
      -0.0587855763733387,
      -0.043159034103155136,
      -0.09247290343046188,
      0.0115358242765069,
      -0.0844404473900795,
      -0.003268599510192871,
      0.04818505421280861,
      -0.1118069440126419,
      0.05536794289946556,
      0.05101507157087326,
      -0.0035187455359846354,
      -0.027419671416282654,
      0.014538651332259178,
      0.04150283709168434,
      -0.026418304070830345,
      -0.07592971622943878,
      -0.02637382782995701,
      0.009862673468887806,
      -0.008007797412574291,
      0.09131603688001633,
      -0.017121821641921997,
      0.009828422218561172,
      -0.005876751150935888,
      -0.0096643241122365,
      -0.0414886437356472,
      0.02996058762073517,
      -0.030999818816781044,
      -0.04415200278162956,
      0.03497244790196419,
      0.008021418936550617,
      0.02009464241564274,
      0.06705310940742493,
      -0.014872764237225056,
      -0.04206713289022446,
      -0.07476285845041275,
      0.007745844312012196,
      0.008345909416675568,
      0.03626370057463646,
      -0.036576319485902786,
      -0.022841306403279305,
      0.0434611514210701,
      0.0016224250430241227,
      -0.08070185780525208,
      0.08789387345314026,
      -0.02247331663966179,
      -0.03782069683074951,
      -0.0037087618838995695,
      0.08226215094327927,
      -0.06440012156963348,
      -0.025402899831533432,
      -0.050304386764764786,
      -0.007571496535092592,
      0.04836048558354378,
      0.045144204050302505,
      0.07464087754487991,
      -0.06371475756168365,
      0.007886651903390884,
      -0.022673120722174644,
      -0.0010676882229745388,
      0.016599014401435852,
      0.038970522582530975,
      0.027148474007844925,
      -0.045024458318948746,
      0.04681776836514473,
      -0.062127768993377686,
      0.010648936033248901,
      0.047087859362363815,
      -0.1266942322254181,
      -0.0655515268445015,
      0.056426722556352615,
      -0.018750345334410667,
      0.05330982431769371,
      0.03745532035827637,
      -0.01769706979393959,
      -0.001971435733139515,
      -0.041404567658901215,
      0.09316190332174301,
      -0.024241995066404343,
      0.03527149558067322,
      -0.023227248340845108,
      0.01836518384516239,
      0.014101015403866768,
      0.0670461356639862,
      0.023724637925624847,
      -0.01442539319396019,
      -0.005528582260012627,
      0.017910253256559372,
      -0.02735304832458496,
      0.09170877188444138,
      -0.03692921623587608,
      0.014141947962343693,
      -0.008076752535998821,
      0.04484529420733452,
      -0.02252957597374916,
      0.056431934237480164,
      0.004944629035890102,
      -0.05022653937339783,
      -0.035191889852285385,
      0.056462690234184265,
      -0.0017108811298385262,
      0.09352998435497284,
      -0.02615896798670292,
      -0.013755401596426964,
      0.019426457583904266,
      -0.019850270822644234,
      -0.014581355266273022,
      0.03796522691845894,
      0.10885704308748245,
      -0.0517423152923584,
      -0.10035289824008942,
      0.015150287188589573,
      -0.03918548673391342,
      0.040005579590797424,
      0.02117965556681156,
      0.018901867792010307,
      -0.011401211842894554,
      0.014728370122611523,
      0.0622832290828228,
      -0.03345537930727005,
      -0.02157692424952984,
      -0.00735274376347661,
      0.07460679858922958,
      0.021900568157434464,
      0.00011845739209093153,
      0.12875480949878693,
      -0.011822300031781197,
      -0.015713658183813095,
      -0.012761801481246948,
      -0.030035588890314102,
      0.015738200396299362,
      0.026462292298674583,
      -0.05534277483820915,
      0.04452131688594818,
      -0.08587414771318436,
      0.016187652945518494,
      -0.02440747246146202,
      0.030715296044945717,
      0.040889643132686615,
      0.09699229896068573,
      0.03695427626371384,
      -0.01638653688132763,
      -0.04730457812547684
    ],
    [
      0.0272917989641428,
      -0.032116007059812546,
      -0.06835544854402542,
      -0.022221235558390617,
      0.00161576340906322,
      0.035493507981300354,
      -0.01095777377486229,
      -0.0544690303504467,
      0.08330590277910233,
      -0.006538432091474533,
      -0.07199016958475113,
      0.1214827373623848,
      -0.04654534161090851,
      0.006399775389581919,
      0.02243625372648239,
      0.014962932094931602,
      -0.03910110145807266,
      -0.05879523232579231,
      0.08420529216527939,
      0.005545186344534159,
      -0.01442737877368927,
      -0.0030577383004128933,
      -0.07340151816606522,
      -0.004145236220210791,
      0.07324762642383575,
      -0.01780659705400467,
      -0.009501105174422264,
      0.010517273098230362,
      -0.018896114081144333,
      0.08236874639987946,
      0.058456238359212875,
      -0.017035149037837982,
      -0.024392560124397278,
      -0.015293454751372337,
      0.05767000839114189,
      0.023930588737130165,
      0.0005418053478933871,
      0.005017297342419624,
      0.031616050750017166,
      0.029343808069825172,
      -0.04469432309269905,
      0.04833081737160683,
      0.01707100123167038,
      -0.06804639846086502,
      0.030677268281579018,
      -0.015618165954947472,
      0.0891774371266365,
      -0.03909911587834358,
      0.0380106046795845,
      -0.06866492331027985,
      0.020598556846380234,
      -0.05287793278694153,
      0.10583863407373428,
      0.034697551280260086,
      -0.01031236071139574,
      0.0603579543530941,
      -0.004890740383416414,
      0.07730913907289505,
      0.06866469234228134,
      -0.03641652688384056,
      -0.05234048143029213,
      0.0885484367609024,
      -0.0064092050306499004,
      0.028597861528396606,
      -0.052750058472156525,
      0.04674074426293373,
      -0.09390060603618622,
      -0.025693697854876518,
      -0.005320743657648563,
      0.05882035568356514,
      -0.00082003278657794,
      0.026480330154299736,
      0.0761161595582962,
      0.032493311911821365,
      0.0418141670525074,
      -0.021055210381746292,
      -0.03746597468852997,
      -0.02494959719479084,
      0.03800142556428909,
      0.019600559026002884,
      0.055656641721725464,
      -0.01212694775313139,
      -0.06518197059631348,
      0.05682620778679848,
      -0.07583580911159515,
      0.001088372664526105,
      0.041854508221149445,
      0.04214164614677429,
      0.03237596154212952,
      0.0026026107370853424,
      0.04020630195736885,
      0.019535232335329056,
      -0.04540054872632027,
      0.03050205111503601,
      -0.03352855145931244,
      -0.06930740922689438,
      -0.011397761292755604,
      -0.05330633744597435,
      -0.05939548835158348,
      -0.04913065955042839,
      -0.031794868409633636,
      0.11153042316436768,
      -0.011729219928383827,
      0.021698785945773125,
      0.03056286834180355,
      -0.04416622593998909,
      -0.060809213668107986,
      -0.04580220952630043,
      -0.042598702013492584,
      -0.005676058121025562,
      0.03113826923072338,
      0.005368842743337154,
      0.04466621205210686,
      0.025845706462860107,
      0.02069670706987381,
      0.04471859335899353,
      0.041817326098680496,
      0.04458102211356163,
      0.0674712136387825,
      0.13613441586494446,
      0.039569202810525894,
      -0.05387059226632118,
      0.017163187265396118,
      0.010940583422780037,
      0.00034966060775332153,
      -0.013236927799880505,
      0.010815000161528587,
      0.012800585478544235,
      -0.03033672273159027,
      -0.05959189683198929,
      0.01491575688123703,
      -0.06260677427053452,
      -0.032387230545282364,
      0.0034099409822374582,
      0.007263244595378637,
      0.0028305165469646454,
      0.08953807502985,
      0.05438438802957535,
      -0.023136494681239128,
      -0.07134155929088593,
      0.03348592296242714,
      0.017287591472268105,
      0.042824290692806244,
      -0.024642491713166237,
      0.07807838916778564,
      0.007209897041320801,
      0.043857768177986145,
      0.008427584543824196,
      0.026321828365325928,
      0.07938429713249207,
      -0.07955862581729889,
      -0.010247133672237396,
      0.09200163930654526,
      -0.04839427024126053,
      0.005046779755502939,
      -0.05683093145489693,
      -0.021529853343963623,
      0.02074197679758072,
      0.040266018360853195,
      0.0018526319181546569,
      0.09765920788049698,
      -0.014006073586642742,
      -0.058611806482076645,
      -0.03600751981139183,
      -0.02536412514746189,
      -0.03740984946489334,
      -0.05199361965060234,
      -0.07972782850265503,
      -0.04852544516324997,
      0.06389907002449036,
      -0.059326305985450745,
      0.07945577055215836,
      0.011430581100285053,
      0.026987124234437943,
      0.11248372495174408,
      -0.029867978766560555,
      0.042071811854839325,
      0.002955268369987607,
      0.021924497559666634,
      -0.0865374431014061,
      0.019299203529953957,
      0.024239378049969673,
      -0.026626769453287125,
      -0.02549005299806595,
      -0.019738441333174706,
      -0.04123378545045853,
      -0.06478996574878693,
      -0.003468954935669899,
      -0.017017627134919167,
      -0.08967483043670654,
      0.0644003227353096,
      -0.015374823473393917,
      -0.0381697453558445,
      -0.02947293594479561,
      0.013286419212818146,
      -0.015715010464191437,
      0.03757201135158539,
      -0.01871032826602459,
      0.03792357072234154,
      -0.09745481610298157,
      0.0410379134118557,
      0.02447228692471981,
      0.004411716945469379,
      -0.11052999645471573,
      -0.008159499615430832,
      -0.008289081044495106,
      -0.06485223770141602,
      0.0776761844754219,
      -0.019868649542331696,
      -0.0782628059387207,
      0.03682064637541771,
      -0.036623913794755936,
      -0.010798254981637001,
      0.03916572034358978,
      0.055156540125608444,
      0.04058189317584038,
      0.07970994710922241,
      0.04105734825134277,
      0.029665136709809303,
      0.0316738598048687,
      0.020730672404170036,
      -0.043961960822343826,
      0.012361511588096619,
      0.05761251598596573,
      -0.049643322825431824,
      0.09497088938951492,
      -0.06114201992750168,
      -0.07941032201051712,
      0.05973244085907936,
      -0.003689806442707777,
      -0.057567447423934937,
      -0.021894028410315514,
      0.012161467224359512,
      0.03954619914293289,
      0.0744384229183197,
      -0.021069901064038277,
      -0.06230393797159195,
      -0.030150393024086952,
      -0.008409911766648293,
      0.035592008382081985,
      -0.050731781870126724,
      -0.01739911548793316,
      0.08291979134082794,
      -0.04026735946536064,
      -0.021531442180275917,
      -0.13650406897068024,
      -0.02878396026790142,
      -0.01335547398775816,
      0.008702508173882961,
      0.011806564405560493,
      0.006527194287627935,
      0.028887365013360977,
      -0.03812532126903534,
      0.025563985109329224,
      0.0008453297195956111,
      -0.08274338394403458,
      0.04678667336702347,
      -0.005088359583169222,
      0.07108165323734283,
      -0.03693797066807747,
      -0.06673627346754074,
      -0.01600329577922821,
      0.005862812045961618,
      0.044169727712869644,
      0.045982725918293,
      -0.011725718155503273,
      0.005066550336778164,
      -0.0035774712450802326,
      0.019829751923680305,
      -0.05986984446644783,
      -0.011370133608579636,
      0.07723438739776611,
      -0.041675224900245667,
      0.020525256171822548,
      0.009134424850344658,
      -0.0625336766242981,
      0.004760590381920338,
      -0.016464294865727425,
      0.09163177758455276,
      -0.03662281483411789,
      -0.052502959966659546,
      0.05569782108068466,
      -0.006295860279351473,
      -0.04847951978445053,
      0.10186020284891129,
      -0.01399720273911953,
      0.006294159218668938,
      0.03290434181690216,
      0.03273800015449524,
      -0.004708198364824057,
      -0.07215159386396408,
      0.005512588191777468,
      -0.07573064416646957,
      0.040416836738586426,
      -0.0010564697440713644,
      -0.0006599812768399715,
      -0.03451208025217056,
      -0.08999598771333694,
      0.01455951388925314,
      -0.03926101326942444,
      0.042108744382858276,
      0.021158071234822273,
      0.025334086269140244,
      -0.07886580377817154,
      0.03778688982129097,
      -0.07782754302024841,
      -0.025166770443320274,
      -0.04186796024441719,
      0.008505163714289665,
      0.002296164631843567,
      -0.05402617156505585,
      0.07975028455257416,
      0.03824653849005699,
      -0.041102875024080276,
      -0.053895700722932816,
      -0.018095841631293297,
      0.02434803731739521,
      0.03139110282063484,
      0.0012662900844588876,
      0.01807006262242794,
      0.025016997009515762,
      -0.02275289222598076,
      -0.030714543536305428,
      0.00026996489032171667,
      0.028745977208018303,
      0.07058335840702057,
      -0.0011525271693244576,
      -0.015870068222284317,
      0.027467980980873108,
      0.05360342189669609,
      0.01134965755045414,
      0.035415034741163254,
      -0.07179583609104156,
      0.01381586492061615,
      0.00875401496887207,
      0.03396829217672348,
      -0.07172489166259766,
      0.008185872808098793,
      -0.019514907151460648,
      0.02537495456635952,
      0.034475911408662796,
      -0.002592194825410843,
      -0.049167927354574203,
      -0.018121086061000824,
      -0.018365420401096344,
      -0.022081660106778145,
      -0.12172216922044754,
      0.0528721809387207,
      0.012523554265499115,
      0.019896304234862328,
      -0.013429386541247368,
      0.0521952323615551,
      0.020695442333817482,
      -0.011254852637648582,
      -0.015202243812382221,
      0.07408591359853745,
      0.007839085534214973,
      -0.09176921844482422,
      -0.02512223832309246,
      0.0011223070323467255,
      -0.049164220690727234,
      -0.060165245085954666,
      0.04696061834692955,
      0.07199104875326157,
      -0.05685388296842575,
      0.040874332189559937,
      0.007438746280968189,
      0.003947593737393618,
      -0.011700332164764404,
      -0.08688779920339584,
      -0.019687635824084282,
      0.06157633289694786,
      -0.002903357148170471,
      -0.014693966135382652,
      0.03214426338672638,
      -0.021376384422183037,
      -0.05027180165052414,
      -0.009485917165875435,
      -0.0683274120092392,
      -0.06129312887787819,
      -0.05432800203561783,
      -0.11358342319726944,
      -0.03519681841135025,
      -0.03910030052065849,
      0.061360616236925125,
      -0.004509452730417252,
      -0.033858053386211395,
      -0.04757660627365112,
      -0.009115871973335743,
      0.07338611036539078,
      0.008507068268954754,
      -0.021082410588860512,
      -0.05063065513968468,
      -0.028311066329479218,
      0.0021090381778776646,
      0.019707845523953438,
      0.004374756012111902,
      -0.005872647278010845,
      0.009665139019489288,
      -0.027311813086271286,
      -0.00125524727627635,
      0.04578676447272301,
      0.00928377266973257,
      -0.06216124817728996,
      -0.0350368469953537,
      -0.019088737666606903,
      -0.02406814880669117,
      -0.056825198233127594,
      -0.011487025767564774,
      0.05942258611321449,
      0.008042862638831139,
      -0.03187098354101181,
      0.0388517752289772,
      -0.028805440291762352,
      0.006143281236290932,
      -0.07596823573112488,
      -0.0677291750907898,
      0.07430148869752884,
      0.021883491426706314,
      -0.0531652495265007,
      -0.017658771947026253,
      0.0025030612014234066,
      -0.014807243831455708,
      -0.08261429518461227,
      -0.036049846559762955,
      0.0885348990559578,
      -0.025334900245070457,
      0.028092151507735252,
      0.028609542176127434,
      0.0327962189912796,
      0.014111137948930264,
      0.08614961802959442,
      0.045039933174848557,
      -0.0316762775182724,
      0.010716628283262253,
      6.33199597359635e-05,
      0.011883960105478764,
      0.02843954972922802,
      -0.005723871290683746,
      0.05026761442422867,
      -0.013372395187616348,
      0.02814267762005329,
      0.06416865438222885,
      -0.04626874998211861,
      -0.09108804911375046,
      -0.02170897275209427,
      -0.03809705376625061,
      -0.05682783201336861,
      0.007359249982982874,
      -0.05919389799237251,
      0.07092609256505966,
      -0.015989987179636955,
      -0.06813494861125946,
      0.005841558333486319,
      -0.10389906913042068,
      0.05269069969654083,
      0.00345786614343524,
      0.0416494682431221,
      -0.017896132543683052,
      0.02310250699520111,
      0.030971936881542206,
      -0.012106264010071754,
      -0.006669855676591396,
      -0.06530178338289261,
      0.04202809929847717,
      -0.04710224270820618,
      -0.01764572784304619,
      -0.04662121459841728,
      -0.03655378147959709,
      0.016959786415100098,
      -0.027365077286958694,
      0.06650307029485703,
      -0.029033495113253593,
      -0.003390462603420019,
      0.021640295162796974,
      0.031813982874155045,
      0.00839083082973957,
      -0.03236796334385872,
      -0.04976281151175499,
      0.07373496890068054,
      -0.020806359127163887,
      -0.02418881095945835,
      -0.01573150046169758,
      0.042286016047000885,
      0.013493160717189312,
      -0.017852066084742546,
      0.00457786675542593,
      0.07142971456050873,
      0.08784516155719757,
      0.003997029270976782,
      -0.006283478811383247,
      -0.00876766536384821,
      -0.0010445744264870882,
      -0.03097492642700672,
      -0.011973441578447819,
      0.0028739275876432657,
      -0.02881881780922413,
      -0.1149384155869484,
      0.002293709432706237,
      0.010843345895409584,
      -0.09187114238739014,
      0.007744065020233393,
      -0.06035219132900238
    ],
    [
      -0.0386519581079483,
      -0.02839578501880169,
      0.009475321508944035,
      0.014675237238407135,
      -0.02868642471730709,
      -0.036263227462768555,
      0.07734151929616928,
      -0.003164418274536729,
      -0.04694259539246559,
      -0.03345530480146408,
      0.057642534375190735,
      -0.0058125704526901245,
      0.03158266842365265,
      -0.04752129688858986,
      -0.03064289502799511,
      -0.06724761426448822,
      -0.057692088186740875,
      -0.020754443481564522,
      -0.031879231333732605,
      0.04585570842027664,
      -0.02357381395995617,
      -0.02363135665655136,
      0.0012778444215655327,
      -0.03879736363887787,
      0.016253754496574402,
      -0.0037693835329264402,
      -0.005267248023301363,
      -0.03140588104724884,
      -0.04398944228887558,
      0.02331719547510147,
      -0.048054393380880356,
      -0.020008405670523643,
      -0.07452459633350372,
      -0.016734886914491653,
      0.04932021349668503,
      -0.030370144173502922,
      -0.03233252093195915,
      0.06577089428901672,
      0.02140454202890396,
      -0.12336593866348267,
      -0.0204481091350317,
      0.00525435246527195,
      0.016701508313417435,
      -0.039269935339689255,
      0.042089346796274185,
      -0.01292246300727129,
      0.07147838920354843,
      -0.05025811493396759,
      -0.015877466648817062,
      0.03075893595814705,
      0.04634374752640724,
      0.047895632684230804,
      -0.001103818416595459,
      -0.007605162914842367,
      -0.035286370664834976,
      0.06509693711996078,
      -0.09704559296369553,
      -0.04453117027878761,
      0.0062855081632733345,
      0.04342483729124069,
      -0.032333750277757645,
      0.01745152845978737,
      0.07942233234643936,
      0.007446088828146458,
      -0.09973602741956711,
      -0.03262350708246231,
      0.018205348402261734,
      0.03522789105772972,
      0.06535112857818604,
      0.013087065890431404,
      0.04957001656293869,
      -0.0056888265535235405,
      -0.014390853233635426,
      0.09021186083555222,
      0.04474690929055214,
      0.015345697291195393,
      0.03682883456349373,
      0.01892303116619587,
      0.008597595617175102,
      -0.04928318038582802,
      -0.015598360449075699,
      -0.05024843290448189,
      -0.001981771318241954,
      -0.07581792771816254,
      -6.895499245729297e-05,
      -0.08106684684753418,
      8.834693289827555e-05,
      -0.0132563142105937,
      -0.029638180509209633,
      -0.037643417716026306,
      0.023905159905552864,
      -0.007071446627378464,
      0.06677161902189255,
      0.010334944352507591,
      -0.09831031411886215,
      0.005886032246053219,
      0.0249180868268013,
      -0.02581453137099743,
      0.01029932964593172,
      -0.023224256932735443,
      0.0353514738380909,
      0.039085060358047485,
      -0.03197115287184715,
      0.004994293674826622,
      0.03089872933924198,
      -0.029964139685034752,
      0.005035181995481253,
      0.0029405830428004265,
      -0.07515117526054382,
      -0.04887823760509491,
      -0.010344310663640499,
      0.0328366756439209,
      0.0009361276170238853,
      0.04548560082912445,
      -0.03742528706789017,
      0.039242058992385864,
      -0.06546974182128906,
      0.0330708809196949,
      0.001303048338741064,
      -0.04866267740726471,
      0.08499567955732346,
      -0.05363667756319046,
      -0.0012503007892519236,
      0.031766198575496674,
      -0.018550684675574303,
      0.032261352986097336,
      -0.003968554548919201,
      0.007955280132591724,
      -0.011268923059105873,
      0.03481070697307587,
      0.04370351508259773,
      0.035641271620988846,
      -0.008825654163956642,
      0.05405018478631973,
      0.050999924540519714,
      0.03547699749469757,
      -0.013019266538321972,
      -0.07910512387752533,
      0.049743231385946274,
      0.025396328419446945,
      0.007204193156212568,
      0.0024368620943278074,
      0.00869473535567522,
      0.0889497697353363,
      0.0395352803170681,
      0.043744441121816635,
      0.07397039979696274,
      0.0834168866276741,
      0.025523632764816284,
      0.07284845411777496,
      0.05553700029850006,
      -0.003191322321072221,
      -0.03198077529668808,
      0.09282877296209335,
      -0.04302157089114189,
      0.015737559646368027,
      0.02590020000934601,
      -0.045278601348400116,
      -0.02232702448964119,
      -0.03628251701593399,
      -0.06112728640437126,
      -0.008631538599729538,
      0.01206568069756031,
      0.014646835625171661,
      0.06572377681732178,
      0.016422197222709656,
      -0.035561446100473404,
      -0.020419200882315636,
      -0.0023483431432396173,
      0.06751299649477005,
      -0.012516060844063759,
      0.05928659066557884,
      0.031357381492853165,
      -0.0005649923696182668,
      0.06435637921094894,
      0.005769097246229649,
      -0.08735166490077972,
      0.016829049214720726,
      -0.07379283010959625,
      0.05104398727416992,
      0.02089752070605755,
      0.005199455190449953,
      -0.0027580985333770514,
      0.06745171546936035,
      0.0751502513885498,
      0.0013930285349488258,
      0.03658878803253174,
      0.04087246581912041,
      -0.0020533378701657057,
      -0.009972302243113518,
      0.03634459152817726,
      0.06392841786146164,
      0.044905900955200195,
      0.005490946117788553,
      0.002967449137941003,
      -0.018977560102939606,
      -0.04068520665168762,
      0.04124291613698006,
      -0.13810469210147858,
      0.03531796485185623,
      0.020137760788202286,
      5.493686330737546e-05,
      -0.010454212315380573,
      -0.011641174554824829,
      0.011617197655141354,
      -0.02034083753824234,
      0.051731012761592865,
      -0.027619363740086555,
      0.047941483557224274,
      0.026889411732554436,
      0.011738535948097706,
      -0.028849901631474495,
      -0.03388427942991257,
      -0.05442991852760315,
      0.008536793291568756,
      -0.038675058633089066,
      0.012474419549107552,
      -0.06792527437210083,
      -0.01387710589915514,
      0.005949912592768669,
      0.09923001378774643,
      -0.01923455484211445,
      0.003203698666766286,
      0.016711339354515076,
      -0.04731191322207451,
      -0.056147538125514984,
      -0.05352406948804855,
      -0.12698401510715485,
      0.008966593071818352,
      -0.016251616179943085,
      0.029306629672646523,
      -0.015013703145086765,
      -0.02949216216802597,
      0.07422621548175812,
      0.03002995252609253,
      0.00014192504750099033,
      0.007180767599493265,
      0.01306462287902832,
      0.014172843657433987,
      0.010236786678433418,
      -0.12887641787528992,
      -0.023982007056474686,
      0.024267444387078285,
      0.007581667508929968,
      -0.1227908805012703,
      -0.02983902394771576,
      -0.0011955681256949902,
      0.00803202111274004,
      -0.022350654006004333,
      -0.015945464372634888,
      -0.003807131201028824,
      -0.0295183714479208,
      0.027282144874334335,
      0.00964993704110384,
      -0.03106697089970112,
      -0.03130074962973595,
      0.09272408485412598,
      -0.014764188788831234,
      0.07136673480272293,
      -0.054367419332265854,
      -0.0017558917170390487,
      -0.034053318202495575,
      0.03180351108312607,
      0.04014061018824577,
      -0.02936561591923237,
      -0.06699159741401672,
      -0.04363798350095749,
      -0.03853641077876091,
      -0.02659713290631771,
      -0.020165519788861275,
      0.046338651329278946,
      -0.042948123067617416,
      0.01717396453022957,
      0.034794095903635025,
      0.031058557331562042,
      0.08288436383008957,
      -0.02424568496644497,
      0.004900903906673193,
      -0.022195441648364067,
      -0.043137941509485245,
      -0.019945358857512474,
      -0.07132809609174728,
      0.04060247913002968,
      0.049137555062770844,
      -0.013406270183622837,
      0.009198416955769062,
      0.01711294986307621,
      -0.04574339836835861,
      -0.06507492065429688,
      -0.020597567781805992,
      -0.10957300662994385,
      0.02986806444823742,
      -0.028258897364139557,
      -0.028670309111475945,
      -0.0914078801870346,
      -0.028042329475283623,
      -0.008733639493584633,
      0.0020118721295148134,
      -0.01278369128704071,
      0.01999122090637684,
      -0.019989918917417526,
      -0.04003667086362839,
      0.02744229882955551,
      -0.007032076362520456,
      0.04816703870892525,
      0.01006097998470068,
      0.007470108103007078,
      -0.045305199921131134,
      -0.05053403601050377,
      0.005452731158584356,
      -0.041538726538419724,
      -0.004641194362193346,
      0.04157515615224838,
      -0.06206689029932022,
      0.057150039821863174,
      -0.11578337848186493,
      0.12363794445991516,
      -0.03988758102059364,
      0.012709363363683224,
      0.061040494590997696,
      0.06243027374148369,
      -0.028353577479720116,
      0.05428379774093628,
      0.01845393516123295,
      0.029023664072155952,
      0.009479227475821972,
      -0.020406005904078484,
      0.008690916933119297,
      0.0610809288918972,
      -0.018698306754231453,
      -0.0011626308551058173,
      -0.008609703741967678,
      0.029790982604026794,
      0.051137059926986694,
      -0.05241149291396141,
      -0.04574386030435562,
      -0.07299192994832993,
      0.02396721951663494,
      0.0436323918402195,
      0.16176463663578033,
      -0.06306609511375427,
      0.029298825189471245,
      -0.012977645732462406,
      -0.004227959085255861,
      0.011411260813474655,
      -0.020141974091529846,
      0.07280807197093964,
      0.00917130708694458,
      -0.08105825632810593,
      -0.04686324670910835,
      0.03828274458646774,
      -0.022322148084640503,
      -0.0559687465429306,
      0.06790094822645187,
      -0.011913026683032513,
      -0.004871053155511618,
      -0.011342761106789112,
      0.027422301471233368,
      0.03594748675823212,
      0.0053648087196052074,
      -0.04861311614513397,
      -0.05318177118897438,
      0.023682869970798492,
      -0.022943774238228798,
      0.0044227526523172855,
      0.017826562747359276,
      -0.011658736504614353,
      -0.020224710926413536,
      0.007434170693159103,
      0.04320918023586273,
      0.05465245246887207,
      0.12043862044811249,
      -0.042581431567668915,
      0.07917088270187378,
      -0.03600578010082245,
      0.043183475732803345,
      -0.06649219244718552,
      -0.03800783306360245,
      -0.0076239630579948425,
      -0.006251303944736719,
      0.04842764139175415,
      0.020289916545152664,
      -0.07844338566064835,
      0.027928436174988747,
      -0.007910476066172123,
      -0.008855328895151615,
      0.03647663816809654,
      -0.035413362085819244,
      -0.03733566403388977,
      -0.02923898957669735,
      0.05399883911013603,
      0.02223672717809677,
      -0.024910684674978256,
      -0.007554709445685148,
      -0.06372446566820145,
      -0.0018053206149488688,
      0.002118481323122978,
      -0.032799817621707916,
      -0.03069440834224224,
      -0.021501148119568825,
      0.02600383199751377,
      0.07221680134534836,
      0.05529092252254486,
      0.04711618646979332,
      -0.03654645010828972,
      -0.021290557458996773,
      -0.027549080550670624,
      0.0429370142519474,
      -0.010471002198755741,
      -0.018521185964345932,
      -0.1262134462594986,
      -0.042548004537820816,
      0.026805076748132706,
      0.016805127263069153,
      -0.025028051808476448,
      0.02891281619668007,
      -0.053522054105997086,
      0.0056456453166902065,
      0.024510541930794716,
      0.015364433638751507,
      0.0229045320302248,
      0.004017172381281853,
      0.00265160808339715,
      -0.04924553632736206,
      0.07240759581327438,
      0.04984641447663307,
      -0.010813359171152115,
      0.0780227854847908,
      0.06715813279151917,
      0.03600919991731644,
      -0.06887932866811752,
      0.028106896206736565,
      -0.009966593235731125,
      -0.040294766426086426,
      -0.0990309938788414,
      -0.007312231697142124,
      -0.058637142181396484,
      0.019343575462698936,
      0.023297661915421486,
      0.049098655581474304,
      0.04333992302417755,
      0.01156590785831213,
      -0.0061485362239181995,
      0.018429376184940338,
      -0.021445224061608315,
      0.0024972427636384964,
      0.03940265253186226,
      -0.044749222695827484,
      0.053525954484939575,
      -0.019933126866817474,
      -0.01731817238032818,
      -0.09956042468547821,
      -0.053680554032325745,
      0.07206366211175919,
      -0.057299982756376266,
      -0.015550807118415833,
      -0.0020990825723856688,
      0.10713198781013489,
      -0.08003796637058258,
      0.09345059096813202,
      0.02756759151816368,
      0.004027416463941336,
      0.028446702286601067,
      -0.09062611311674118,
      -0.00013343770115170628,
      -0.008534912951290607,
      -0.02868972346186638,
      -0.010040923953056335,
      0.022989103570580482,
      0.008876845240592957,
      -0.08566533029079437,
      0.06417950987815857,
      0.08451266586780548,
      0.0555761381983757,
      -0.05974416807293892,
      0.0443626269698143,
      -0.015864208340644836,
      -0.03300509601831436,
      -0.017551638185977936,
      0.025730827823281288,
      0.04570348188281059,
      0.01992141455411911,
      -0.06394359469413757,
      -0.007766857743263245,
      -0.04274097457528114,
      -0.03557203337550163,
      -0.021151438355445862,
      -0.010439862497150898,
      -0.009272360242903233,
      -0.01773340068757534,
      -0.05159524083137512,
      0.020052259787917137,
      0.03664616495370865,
      -0.08346801996231079,
      -0.03369704261422157,
      -0.027380626648664474,
      -0.08342863619327545,
      0.009238364174962044,
      -0.025319982320070267,
      0.05520268902182579,
      -0.017427824437618256,
      -0.09057504683732986,
      -0.030559314414858818
    ],
    [
      -0.00036539541906677186,
      -0.01762673258781433,
      -0.06536440551280975,
      -0.010082199238240719,
      -0.025958888232707977,
      -0.049006182700395584,
      0.012130212970077991,
      0.004840390291064978,
      0.09245095402002335,
      0.023547880351543427,
      0.0302740391343832,
      0.03728994354605675,
      0.08243459463119507,
      -0.041104935109615326,
      0.0013049902627244592,
      -0.018363909795880318,
      0.005013366229832172,
      0.03303978964686394,
      -0.0033303210511803627,
      0.000663428392726928,
      -0.014489847235381603,
      0.003841174766421318,
      0.0007667767349630594,
      -0.002880202839151025,
      0.06055537238717079,
      0.05652719363570213,
      -0.034397732466459274,
      -0.03127671033143997,
      -0.012399702332913876,
      0.0032248408533632755,
      -0.027036884799599648,
      -0.03032805025577545,
      0.023030145093798637,
      0.022683002054691315,
      -0.023552294820547104,
      -0.01738245040178299,
      -0.0034457880537956953,
      -0.026229899376630783,
      0.00834168866276741,
      -0.008600839413702488,
      -0.06076357141137123,
      0.002362958388403058,
      -0.004237334243953228,
      -0.06427910923957825,
      -0.0007219487451948225,
      0.021207448095083237,
      -0.031040657311677933,
      0.01721050590276718,
      -0.04877428710460663,
      -0.009239391423761845,
      0.05056771636009216,
      0.027691390365362167,
      -0.03511614352464676,
      0.06287266314029694,
      -0.08668278902769089,
      0.047247108072042465,
      0.03569174185395241,
      0.024992117658257484,
      0.0046121953055262566,
      -0.08723907917737961,
      0.02499833144247532,
      0.02669193036854267,
      -0.021543491631746292,
      0.054819561541080475,
      -0.048962656408548355,
      -0.02889656089246273,
      0.005531457252800465,
      0.010841586627066135,
      -0.007873356342315674,
      -0.03208547085523605,
      -0.04222697392106056,
      -0.023702342063188553,
      0.014028807170689106,
      0.00679457513615489,
      -0.051012072712183,
      -0.06436268240213394,
      0.01567865163087845,
      0.019472533836960793,
      0.018639259040355682,
      -0.0026616984978318214,
      0.06646949797868729,
      -0.04631955176591873,
      0.02454931102693081,
      0.007672697305679321,
      -0.021853188052773476,
      0.0037503899075090885,
      0.00436870101839304,
      -0.023868268355727196,
      -0.04314340278506279,
      0.04525475203990936,
      -0.038076166063547134,
      -0.05301613360643387,
      0.0028070181142538786,
      -0.04120923578739166,
      0.02763862907886505,
      -0.03721299394965172,
      -0.043849196285009384,
      0.03615333139896393,
      -0.007458855863660574,
      0.022347599267959595,
      -0.004209600389003754,
      -0.007475650869309902,
      -0.011632252484560013,
      -0.025132568553090096,
      0.09291823953390121,
      0.029006103053689003,
      0.02645830623805523,
      0.0116440299898386,
      -0.005590721499174833,
      -0.002187871839851141,
      -0.006956074852496386,
      0.0008191262022592127,
      0.008057472296059132,
      -0.009422057308256626,
      -0.07751281559467316,
      0.043935347348451614,
      0.034771621227264404,
      0.006204080302268267,
      0.02197047509253025,
      0.05359978973865509,
      -0.0033262805081903934,
      0.024212004616856575,
      0.004483736120164394,
      -0.05477246642112732,
      -0.052752867341041565,
      -0.0215526781976223,
      -0.051266349852085114,
      0.020941248163580894,
      0.027837082743644714,
      0.032306622713804245,
      0.03676933795213699,
      0.04134584218263626,
      -0.032793618738651276,
      -0.03393000736832619,
      0.057876717299222946,
      0.05842708796262741,
      -0.0265401229262352,
      0.0616445392370224,
      -0.028568817302584648,
      -0.030524076893925667,
      0.010881763882935047,
      -0.03984041512012482,
      -0.015215162187814713,
      0.04548843204975128,
      0.061448950320482254,
      0.04295817390084267,
      0.010740544646978378,
      -0.0538208931684494,
      -0.050993144512176514,
      -0.01126310508698225,
      0.025252537801861763,
      0.004732465371489525,
      -0.009318353608250618,
      0.0014324224321171641,
      0.004428451880812645,
      -0.07285737991333008,
      -0.05058194324374199,
      0.040470559149980545,
      0.02350664511322975,
      -0.035657621920108795,
      0.08433160930871964,
      -0.022788625210523605,
      0.020495036616921425,
      -0.09737642109394073,
      0.00962622556835413,
      -0.09533441811800003,
      -0.006858536507934332,
      0.015799686312675476,
      -0.015592905692756176,
      -0.04642451927065849,
      -0.016610540449619293,
      -0.006881142035126686,
      -0.032956257462501526,
      0.0464177280664444,
      -0.020101387053728104,
      0.04226520285010338,
      -0.10435677319765091,
      0.017771326005458832,
      -0.027463827282190323,
      0.06809830665588379,
      -0.055388618260622025,
      0.05085265636444092,
      -0.002289136638864875,
      0.061285171657800674,
      -0.042921099811792374,
      -0.01950588822364807,
      -0.010323623195290565,
      -0.07381916046142578,
      -0.008544296026229858,
      0.014376053586602211,
      0.016276974231004715,
      0.04090556129813194,
      0.0383894257247448,
      -0.11314710229635239,
      0.014301955699920654,
      -0.15516221523284912,
      -0.0025302176363766193,
      -0.04545426741242409,
      0.06600058078765869,
      -0.08947604149580002,
      0.006093496922403574,
      -0.02565631829202175,
      0.02842376008629799,
      -0.008630827069282532,
      -0.03880432993173599,
      -0.0365368127822876,
      0.006909437943249941,
      0.019706878811120987,
      0.026194395497441292,
      0.05235395208001137,
      -0.026077602058649063,
      -0.05687495693564415,
      -0.0055860485881567,
      0.11259955167770386,
      -0.041580140590667725,
      0.00014946296869311482,
      -0.02466738224029541,
      0.005822210572659969,
      -0.025668781250715256,
      0.009203686378896236,
      0.0055300528183579445,
      -0.13397330045700073,
      0.0009132901905104518,
      -0.013654299080371857,
      0.047698598355054855,
      -0.028950048610568047,
      -0.05459685996174812,
      -0.016194917261600494,
      -0.05394943431019783,
      0.058358337730169296,
      0.022927748039364815,
      0.06256834417581558,
      0.07024912536144257,
      0.06454151123762131,
      -0.025063708424568176,
      -0.019611459225416183,
      0.023043444380164146,
      -0.03741394728422165,
      -0.05560029298067093,
      0.021142059937119484,
      0.0032804475631564856,
      -0.05637197196483612,
      -0.023892894387245178,
      -0.0335509367287159,
      0.07555048167705536,
      0.07149796932935715,
      0.004364682361483574,
      0.060078661888837814,
      0.05943715199828148,
      0.02217564359307289,
      -0.01893949881196022,
      0.08362174779176712,
      0.03202005475759506,
      -0.04564061760902405,
      -0.03964721038937569,
      -0.028907988220453262,
      -0.01441048365086317,
      0.004249808378517628,
      -0.0273341815918684,
      -0.03465663269162178,
      0.05299655720591545,
      0.05625976249575615,
      0.06397998332977295,
      0.013202930800616741,
      0.0007831626571714878,
      0.00883396901190281,
      0.09139837324619293,
      -0.02925713174045086,
      0.02380736544728279,
      -0.03405993431806564,
      0.005938459187746048,
      -0.006897991523146629,
      0.05200618505477905,
      -0.06640629470348358,
      -0.0011099287075921893,
      0.01660997048020363,
      -0.008942424319684505,
      0.03363993763923645,
      -0.0013460512273013592,
      -0.001480434206314385,
      -0.008021345362067223,
      0.03515069931745529,
      -0.012387766502797604,
      -0.052761584520339966,
      0.06882748007774353,
      -0.03874514624476433,
      -0.060327865183353424,
      0.0295818280428648,
      -0.011556040495634079,
      -0.057105980813503265,
      -0.056966301053762436,
      -0.03748145326972008,
      0.005519666709005833,
      0.0031834859400987625,
      0.041761614382267,
      -0.008117171004414558,
      -0.031864382326602936,
      -0.035084839910268784,
      0.05363896116614342,
      -0.03878678381443024,
      0.01010212767869234,
      -0.09369008243083954,
      0.05161705240607262,
      -0.042996473610401154,
      -0.02567771077156067,
      0.04183075577020645,
      0.00322393118403852,
      -0.013207684271037579,
      0.03084539622068405,
      0.01980333775281906,
      -0.02465675212442875,
      0.0016004880890250206,
      -0.11171440780162811,
      0.045567333698272705,
      -0.013813749887049198,
      0.005413232371211052,
      0.09445716440677643,
      -0.001607218524441123,
      0.00018861450371332467,
      0.009381929412484169,
      0.0061558946035802364,
      -0.046371590346097946,
      0.07963433116674423,
      0.09530650824308395,
      0.003071376821026206,
      -0.04766290634870529,
      0.018537327647209167,
      -0.002677570329979062,
      -0.004338759463280439,
      0.04006215184926987,
      0.013781558722257614,
      0.0898875817656517,
      -0.026680411770939827,
      -0.03723035380244255,
      0.03346867486834526,
      0.07403212785720825,
      0.026187991723418236,
      -0.04744049906730652,
      0.002705891616642475,
      -0.08107881247997284,
      -0.054815568029880524,
      -0.019470108672976494,
      -0.045410312712192535,
      0.04475284367799759,
      -0.0023103731218725443,
      0.03478146344423294,
      -0.01337004266679287,
      -0.006732544861733913,
      -0.0024356208741664886,
      0.019043417647480965,
      0.03409286588430405,
      0.025446919724345207,
      -0.059116799384355545,
      0.0798937976360321,
      0.020640723407268524,
      0.01810256578028202,
      -0.02753911353647709,
      0.02826116234064102,
      -0.06088561192154884,
      0.05961418151855469,
      0.06672023236751556,
      -0.0004693433875218034,
      -0.04283202812075615,
      -0.04457119107246399,
      -0.00990518182516098,
      0.023566488176584244,
      0.029468923807144165,
      0.014082220382988453,
      0.04447595775127411,
      0.0761873722076416,
      -0.05078034847974777,
      0.03987451270222664,
      0.004365097265690565,
      -0.06467044353485107,
      -0.010569257661700249,
      -0.006870640907436609,
      0.07213621586561203,
      0.027017511427402496,
      0.03546091169118881,
      0.0010084114037454128,
      -0.025081828236579895,
      0.04597007855772972,
      0.06995496153831482,
      -0.03285606577992439,
      0.03015298768877983,
      0.009583380073308945,
      0.0007392556872218847,
      0.0015982127515599132,
      0.08689476549625397,
      -0.006707513239234686,
      -0.010710152797400951,
      -0.0718717873096466,
      0.08340886235237122,
      0.00883142463862896,
      0.026614084839820862,
      -0.08549609035253525,
      -0.028074298053979874,
      -0.028087187558412552,
      -0.07846267521381378,
      0.009733310900628567,
      -0.027964608743786812,
      0.038307055830955505,
      -0.03106866590678692,
      0.0383724719285965,
      -0.08268170058727264,
      0.006744617596268654,
      0.02865421585738659,
      -0.0010283042211085558,
      0.07000947743654251,
      0.04699912667274475,
      -0.07226172089576721,
      -0.01097486075013876,
      0.04956546425819397,
      0.04572136327624321,
      -0.07095146179199219,
      0.04828526824712753,
      0.013332588598132133,
      0.03793156147003174,
      -0.03506368026137352,
      -0.021743860095739365,
      0.02880917862057686,
      -0.007312656380236149,
      -0.00854298286139965,
      0.0659547671675682,
      -0.07657421380281448,
      0.02635703980922699,
      0.06066674366593361,
      0.04545321688055992,
      -0.02488061785697937,
      -0.009975108318030834,
      -0.009624483063817024,
      0.01168747153133154,
      0.02697155438363552,
      -0.037975601851940155,
      -0.06376504898071289,
      -0.09399805963039398,
      0.05413985624909401,
      -0.029352333396673203,
      -0.06098836287856102,
      0.05738918483257294,
      -0.048342544585466385,
      0.048379283398389816,
      0.03342205658555031,
      -0.003755367361009121,
      0.028286056593060493,
      -0.05544305220246315,
      -0.005919625051319599,
      0.004800045862793922,
      0.025680333375930786,
      -0.09357611835002899,
      -0.020115910097956657,
      0.047233160585165024,
      -0.014776253141462803,
      -0.025563104078173637,
      0.042602475732564926,
      -0.08170419931411743,
      -0.0647348016500473,
      -0.05983598157763481,
      -0.03370833769440651,
      0.03393597528338432,
      0.0007466129609383643,
      -0.09700975567102432,
      0.0838833898305893,
      0.06555641442537308,
      0.06975802034139633,
      0.010531536303460598,
      -0.01520236674696207,
      -0.05161210149526596,
      -0.0067924149334430695,
      0.03709596022963524,
      0.053788647055625916,
      -0.03069329634308815,
      0.028492165729403496,
      -0.08238504827022552,
      0.04698273167014122,
      0.047262273728847504,
      -0.06422273069620132,
      -0.012523896060883999,
      -0.007990671321749687,
      -0.0528823547065258,
      -0.06189814582467079,
      0.022392302751541138,
      0.021306365728378296,
      -0.020768124610185623,
      -0.03351317346096039,
      -0.03604661673307419,
      -0.05720292776823044,
      -0.0576229989528656,
      0.0034797615371644497,
      -0.0046011460945010185,
      -0.11333481222391129,
      0.017548613250255585,
      -0.07830700278282166,
      -0.0015124933561310172,
      -0.023181360214948654,
      -0.07947971671819687,
      0.08184384554624557,
      0.07149415463209152,
      -0.042579468339681625,
      -0.07468429207801819,
      -0.03176099434494972,
      -0.023525716736912727,
      -0.029038574546575546
    ],
    [
      0.018001453951001167,
      0.026281988248229027,
      0.07941270619630814,
      -0.060899294912815094,
      -0.04278863966464996,
      -0.07249195873737335,
      0.08504198491573334,
      0.03067004680633545,
      -0.04287697374820709,
      -0.006589020602405071,
      0.08733215183019638,
      0.02348286472260952,
      0.004048704169690609,
      -0.017817864194512367,
      -0.03048362210392952,
      -0.044940147548913956,
      0.029159847646951675,
      0.0013217527884989977,
      -0.037225134670734406,
      -0.02417345717549324,
      0.009471306577324867,
      0.014736071228981018,
      -0.05090242624282837,
      -0.07661662250757217,
      0.051129020750522614,
      0.009652448818087578,
      -0.013489621691405773,
      0.006829570047557354,
      -0.04424210637807846,
      0.06275204569101334,
      -0.007938068360090256,
      0.03802883252501488,
      0.028089387342333794,
      0.05635441467165947,
      -0.05485096201300621,
      -0.020743681117892265,
      0.04863327741622925,
      0.029759857803583145,
      -0.09633895754814148,
      -0.01442197896540165,
      -0.048330165445804596,
      0.008958568796515465,
      -0.07948372513055801,
      -0.013356424868106842,
      -0.006351372692734003,
      -0.002203800482675433,
      0.08450000733137131,
      -0.006916897837072611,
      0.04816074296832085,
      -0.00034038067678920925,
      0.019330663606524467,
      -0.0037771319039165974,
      -0.0011127402540296316,
      -0.009462160058319569,
      -0.0552198700606823,
      0.06634105741977692,
      -0.08859210461378098,
      0.04263724386692047,
      0.019023094326257706,
      -0.00026675008120946586,
      0.05357212573289871,
      0.02459041401743889,
      -0.011081324890255928,
      -0.005040633026510477,
      0.05808553099632263,
      0.0361955352127552,
      0.04968932271003723,
      0.028006495907902718,
      -0.0008236306020990014,
      0.062446389347314835,
      -0.08888491243124008,
      -0.060301441699266434,
      -0.04785272851586342,
      0.07918877899646759,
      -0.04799580201506615,
      0.002053407020866871,
      -0.00653706444427371,
      -0.023403067141771317,
      0.020755715668201447,
      0.02603497914969921,
      -0.0006629664567299187,
      -0.014659779146313667,
      0.09547346085309982,
      0.07721356302499771,
      -0.010217390023171902,
      -0.03916569799184799,
      0.04992201551795006,
      0.04104232415556908,
      -0.09217675775289536,
      0.002909371629357338,
      0.015555732883512974,
      -0.029420895501971245,
      0.03314503654837608,
      0.021146578714251518,
      -0.08160298317670822,
      0.17622797191143036,
      0.059605322778224945,
      -0.05052509158849716,
      0.05098554119467735,
      0.06002862751483917,
      0.010126419365406036,
      -0.023291390389204025,
      -0.08061504364013672,
      0.12720872461795807,
      0.009160583838820457,
      0.010112442076206207,
      0.019905108958482742,
      0.0011844080872833729,
      0.030896339565515518,
      -0.003692279802635312,
      -0.03855403512716293,
      0.0034372429363429546,
      -0.05316805839538574,
      0.1050763726234436,
      -0.03591863065958023,
      0.07631722837686539,
      -0.05703214183449745,
      0.024499962106347084,
      0.06457550823688507,
      -0.03996138274669647,
      0.0566449798643589,
      -0.015043484047055244,
      0.03074214607477188,
      0.015462601557374,
      0.0468781515955925,
      0.013912257738411427,
      0.04746956378221512,
      0.029338661581277847,
      0.06692397594451904,
      0.007839602418243885,
      0.0145550686866045,
      0.011221871711313725,
      -0.022649183869361877,
      -0.010291009210050106,
      0.010551744140684605,
      -0.09122370928525925,
      -0.0635441392660141,
      0.0026668081991374493,
      -0.026417415589094162,
      0.06626272946596146,
      -0.05070772022008896,
      -0.046950530260801315,
      -0.0997781828045845,
      0.0177629217505455,
      0.08931758999824524,
      0.07366017997264862,
      0.0014329564291983843,
      -0.004202527925372124,
      -0.07452239841222763,
      -0.060023870319128036,
      0.016824016347527504,
      0.11281690746545792,
      0.005059211980551481,
      -0.024179039523005486,
      0.034894898533821106,
      -0.03345349803566933,
      -0.02339850552380085,
      -0.06690553575754166,
      0.008106598630547523,
      0.014981620013713837,
      0.007264973130077124,
      -0.013885627500712872,
      0.04644140228629112,
      0.01350310817360878,
      0.054787639528512955,
      0.007157935295253992,
      -0.05104489624500275,
      0.005610707215964794,
      0.08942349255084991,
      -0.0075427694246172905,
      0.011982644908130169,
      0.09070365130901337,
      -0.056542761623859406,
      0.023863038048148155,
      -0.03753459453582764,
      0.0638410747051239,
      -0.03259148821234703,
      0.02817901410162449,
      0.018014032393693924,
      -0.0193068478256464,
      0.0644594132900238,
      0.06039891019463539,
      -0.06648021936416626,
      0.03289058431982994,
      -0.0563349686563015,
      0.010469040833413601,
      -0.09193078428506851,
      0.01976490207016468,
      -0.0063230013474822044,
      -0.03833835944533348,
      -0.021650612354278564,
      0.028131898492574692,
      0.04648957401514053,
      0.006275294814258814,
      0.013976766727864742,
      0.028713300824165344,
      -0.014536792412400246,
      -0.005488975439220667,
      -0.006242384668439627,
      0.04497777670621872,
      -0.003094080835580826,
      0.022643757984042168,
      0.020689507946372032,
      -0.012247994542121887,
      0.01064663752913475,
      -0.007279025390744209,
      -0.01669912599027157,
      0.0032854352612048388,
      -0.007509863469749689,
      0.03506506606936455,
      0.04859431833028793,
      -0.012820567935705185,
      -0.034447018057107925,
      -0.0194088201969862,
      -0.025382937863469124,
      0.06950642913579941,
      0.009292884729802608,
      0.10261784493923187,
      -0.01049373671412468,
      -0.05793594941496849,
      -0.004024895373731852,
      -0.016967156901955605,
      0.10657372325658798,
      -0.023936979472637177,
      0.04566961154341698,
      0.008626271039247513,
      -0.045017533004283905,
      -0.029353559017181396,
      0.110905222594738,
      0.022162577137351036,
      -0.02565249614417553,
      0.048671655356884,
      0.025044551119208336,
      0.03938524052500725,
      0.02650139294564724,
      0.001151218661107123,
      0.008446120657026768,
      -0.009084640070796013,
      0.05776887759566307,
      0.00383966276422143,
      -0.02587561495602131,
      0.025388874113559723,
      -0.011424629017710686,
      -0.01657276600599289,
      -0.010369067080318928,
      -0.04380415752530098,
      0.02761210687458515,
      0.003703518770635128,
      -0.06528180092573166,
      0.007563661318272352,
      -0.017211340367794037,
      -0.02576061524450779,
      -0.010537354275584221,
      -0.0037897625006735325,
      0.007820685394108295,
      -0.04818224161863327,
      -0.024090418592095375,
      -0.019590359181165695,
      0.013567410409450531,
      0.008310660719871521,
      3.268366708653048e-05,
      -0.021281108260154724,
      -0.032030291855335236,
      -0.0791328176856041,
      0.06378678977489471,
      0.015822017565369606,
      -0.06292032450437546,
      -0.02851613238453865,
      0.017571989446878433,
      0.033140771090984344,
      0.0260719433426857,
      -0.03608791157603264,
      -0.09483025968074799,
      -0.023594612255692482,
      -0.03263014927506447,
      0.06417547911405563,
      0.050615135580301285,
      0.024155057966709137,
      0.03023432567715645,
      0.09925193339586258,
      -0.08428940922021866,
      -0.026171281933784485,
      0.03072097711265087,
      0.07421770691871643,
      -0.011983951553702354,
      -0.01505959965288639,
      -0.025807080790400505,
      0.0318576879799366,
      0.007479983381927013,
      0.08359460532665253,
      0.0314641147851944,
      -0.032578375190496445,
      -0.0087641766294837,
      -0.03813021257519722,
      0.02206934429705143,
      0.014929525554180145,
      -0.058228231966495514,
      -0.10306525230407715,
      0.044609926640987396,
      -0.05040479451417923,
      -0.029025400057435036,
      0.03624604642391205,
      0.08094638586044312,
      0.06434927135705948,
      -0.03105669468641281,
      0.05529412999749184,
      0.06267059594392776,
      -0.008528921753168106,
      -0.07051421701908112,
      0.048167627304792404,
      -0.04750553518533707,
      0.0877993106842041,
      0.03426463529467583,
      0.048882707953453064,
      -0.0018927146447822452,
      -0.05595142021775246,
      0.04460287466645241,
      -0.010732007212936878,
      0.016806436702609062,
      -0.001064068521372974,
      -0.0030583871994167566,
      -0.03743485361337662,
      -0.027522942051291466,
      -0.052418727427721024,
      0.05397646501660347,
      -0.07436252385377884,
      0.1047743409872055,
      -0.06790543347597122,
      0.09372243285179138,
      0.013405853882431984,
      0.04458443820476532,
      -0.0048972531221807,
      0.008428815752267838,
      0.005179683677852154,
      -0.02191217616200447,
      0.06518415361642838,
      0.06565680354833603,
      -0.019008852541446686,
      -0.0025247102603316307,
      0.02512029930949211,
      -0.09935683012008667,
      -0.010634888894855976,
      -0.019933270290493965,
      0.0006302779074758291,
      -0.041263967752456665,
      0.035399165004491806,
      -0.02606998383998871,
      0.0697149932384491,
      0.031888388097286224,
      0.029134642332792282,
      0.058112263679504395,
      -0.020912175998091698,
      -0.00037465820787474513,
      -0.03928836062550545,
      -0.0024696728214621544,
      -0.03283042833209038,
      0.02905222959816456,
      -0.028567645698785782,
      -0.04329782724380493,
      -0.008356578648090363,
      -0.04654905945062637,
      0.022029640153050423,
      -0.054767247289419174,
      0.043520063161849976,
      -0.012180695310235023,
      0.07215287536382675,
      0.054174091666936874,
      -0.044222164899110794,
      0.03880922123789787,
      0.03134901449084282,
      0.01025683619081974,
      -0.024996301159262657,
      0.0034806751646101475,
      0.026190245524048805,
      -0.055593471974134445,
      -0.07721096277236938,
      -0.00827784277498722,
      -0.07316906005144119,
      -0.01943315379321575,
      0.07588889449834824,
      -0.03573925793170929,
      -0.054442424327135086,
      0.031936436891555786,
      -0.03091464191675186,
      0.049808748066425323,
      -0.04863550513982773,
      -0.0091664157807827,
      -0.006603903137147427,
      -0.0026135859079658985,
      0.024752648547291756,
      0.09553109109401703,
      -0.023511940613389015,
      0.026804763823747635,
      -0.01794552244246006,
      0.020033011212944984,
      0.07862649112939835,
      0.03886489197611809,
      -0.023577192798256874,
      0.012873886153101921,
      -0.0033097867853939533,
      0.044143736362457275,
      -0.0164208821952343,
      -0.016854768618941307,
      -0.06213684752583504,
      0.006596408784389496,
      -0.0799793228507042,
      -0.02264489233493805,
      -0.09920933097600937,
      0.048660390079021454,
      0.046591632068157196,
      -0.01901904121041298,
      0.051865074783563614,
      0.005657071713358164,
      -0.08352944999933243,
      0.010608930140733719,
      -0.036840423941612244,
      -0.07639524340629578,
      -0.02855905517935753,
      0.01026628166437149,
      0.04406282305717468,
      -0.02766372822225094,
      0.014096872881054878,
      -0.05384237691760063,
      -0.03534433990716934,
      0.07234234362840652,
      0.013557919301092625,
      0.0005397995701059699,
      -0.006581731606274843,
      -2.3187152692116797e-05,
      -0.013403208926320076,
      -0.022691356018185616,
      0.0627192035317421,
      0.09815739840269089,
      -0.022951826453208923,
      0.04570954665541649,
      -0.03186926618218422,
      0.005801074672490358,
      -0.08806223422288895,
      -0.015774276107549667,
      0.0020781480707228184,
      -0.002257908694446087,
      -0.04958253726363182,
      0.03982376307249069,
      0.03150229901075363,
      0.03254202380776405,
      -0.03187203407287598,
      -0.007565018720924854,
      -0.04016668349504471,
      0.025833511725068092,
      0.04575936123728752,
      0.07433793693780899,
      -0.03649124503135681,
      0.023012816905975342,
      -0.03713219612836838,
      0.004161390475928783,
      -0.018240079283714294,
      -0.008735891431570053,
      0.0020608133636415005,
      -0.0206089299172163,
      -0.01747221127152443,
      0.03967491537332535,
      0.0003602628130465746,
      0.006009408738464117,
      -0.002646992215886712,
      0.026558417826890945,
      0.009936901740729809,
      -0.03889141604304314,
      0.0017120731063187122,
      0.04848489910364151,
      -0.0713445320725441,
      0.09772811830043793,
      -0.04842466861009598,
      -0.005711102392524481,
      0.005682268179953098,
      -0.0011722204508259892,
      -0.005395733751356602,
      -0.09136540442705154,
      0.050890401005744934,
      0.026212722063064575,
      -0.003786536632105708,
      0.0009033842943608761,
      -0.05121295899152756,
      -0.0008596935658715665,
      -0.005460872780531645,
      -0.04948599264025688,
      0.06699661165475845,
      -0.043808549642562866,
      0.02487281523644924,
      -0.02248101308941841,
      0.04592265188694,
      0.011595877818763256,
      0.021116258576512337,
      0.03741355612874031,
      0.02191067300736904,
      0.05008283257484436,
      -0.0011732473503798246,
      -0.007048979867249727,
      0.021868975833058357,
      -0.033560097217559814,
      -0.04192330315709114,
      -0.03311854973435402,
      -0.0055935680866241455,
      0.03294891491532326
    ],
    [
      -0.008837060071527958,
      0.05168132111430168,
      -0.024803241714835167,
      -0.08021075278520584,
      -0.023208992555737495,
      -0.04822300747036934,
      -0.025985581800341606,
      -0.012402843683958054,
      -0.008280500769615173,
      0.04121299833059311,
      -0.0072726961225271225,
      0.002203584648668766,
      0.0016705890884622931,
      -0.005261526443064213,
      -0.02569982409477234,
      0.023073308169841766,
      -0.008391068316996098,
      -0.0520843006670475,
      0.008571784943342209,
      -0.0043616099283099174,
      0.06651517748832703,
      -0.04892248660326004,
      0.05976289138197899,
      -0.08297086507081985,
      -0.020918752998113632,
      -0.028582630679011345,
      0.0527004599571228,
      -0.006666986271739006,
      -0.015240376815199852,
      -0.014372716657817364,
      -0.045639362186193466,
      -0.006160880904644728,
      -0.006977233570069075,
      -0.018618881702423096,
      0.03701912611722946,
      -0.008851049467921257,
      0.012598966248333454,
      0.011022365652024746,
      0.0007159712258726358,
      0.025537488982081413,
      -0.031477585434913635,
      -0.022753441706299782,
      0.026674263179302216,
      0.013670227490365505,
      -0.00638581020757556,
      -0.018765494227409363,
      0.030818212777376175,
      -0.008878443390130997,
      0.08205951005220413,
      0.0369885116815567,
      0.0820813849568367,
      -0.0603371299803257,
      -0.05022161826491356,
      -0.028752008453011513,
      -0.153672456741333,
      0.0354410856962204,
      -0.0709032267332077,
      0.08426171541213989,
      -0.008290976285934448,
      0.009272714145481586,
      0.022259242832660675,
      -0.0023380503989756107,
      -0.026708275079727173,
      0.007690104655921459,
      0.01973152719438076,
      -0.07929723709821701,
      -0.028222693130373955,
      0.01791073940694332,
      -0.11825814098119736,
      -0.000984743470326066,
      0.05360928550362587,
      0.011803870089352131,
      -0.030880331993103027,
      0.011241291649639606,
      -0.012998468242585659,
      -0.0423433892428875,
      -0.048459138721227646,
      -0.01903684251010418,
      0.009827526286244392,
      0.0530705563724041,
      0.04140963777899742,
      0.0018445247551426291,
      0.016622234135866165,
      0.05493369325995445,
      -0.05797220394015312,
      0.08530738204717636,
      0.017922742292284966,
      -0.034866783767938614,
      -0.035937052220106125,
      0.0944095104932785,
      0.03870870918035507,
      -0.008774190209805965,
      0.03213176503777504,
      0.055369358509778976,
      -0.014025256969034672,
      -0.02705121971666813,
      0.028652716428041458,
      -0.08249412477016449,
      -0.02695615030825138,
      -0.005591941997408867,
      -0.0029341778717935085,
      -0.002222849056124687,
      -0.06211992725729942,
      0.09120377153158188,
      0.08557528257369995,
      -0.05560814589262009,
      -0.008868968114256859,
      -0.008135545067489147,
      0.025182822719216347,
      0.08362530171871185,
      -0.01981169916689396,
      0.05202273279428482,
      -0.006567766889929771,
      -0.03780663013458252,
      -0.010568595491349697,
      -0.043826572597026825,
      -0.012806634418666363,
      0.018316952511668205,
      -0.012370020151138306,
      -0.042102254927158356,
      0.1299569010734558,
      -0.014606032520532608,
      0.026080651208758354,
      0.005199620500206947,
      -0.04180751368403435,
      -0.009860387071967125,
      -0.04986349493265152,
      0.06647787988185883,
      0.017469733953475952,
      0.00913839228451252,
      -0.025934649631381035,
      0.05253473296761513,
      -0.04332927614450455,
      -0.05644560232758522,
      -0.025739457458257675,
      0.0157227274030447,
      -0.03415277972817421,
      -0.05339251831173897,
      -0.01497514545917511,
      -0.005649062804877758,
      -0.011069663800299168,
      -0.033402301371097565,
      -0.005340629257261753,
      0.12104690074920654,
      0.10322187840938568,
      -0.07984651625156403,
      -0.04260683432221413,
      0.003979279659688473,
      0.03680593892931938,
      0.03290003538131714,
      -0.0017624058527871966,
      0.07014161348342896,
      -0.02087716944515705,
      0.0670614242553711,
      -0.006009224336594343,
      -0.0004284414171706885,
      -0.026015501469373703,
      -0.023783834651112556,
      0.02181483991444111,
      0.07261732965707779,
      0.10070677846670151,
      -0.041697777807712555,
      0.021221693605184555,
      -0.02928372286260128,
      0.023613831028342247,
      -0.0429493673145771,
      0.008151720277965069,
      0.0052612475119531155,
      -0.050492241978645325,
      0.13617399334907532,
      -0.03330538794398308,
      0.026437794789671898,
      0.0933218002319336,
      -0.03963717445731163,
      -0.0053797755390405655,
      -0.06695561856031418,
      -0.006798986345529556,
      0.05973094701766968,
      -0.030142752453684807,
      -0.022544773295521736,
      0.06465831398963928,
      0.0430356040596962,
      0.03278816118836403,
      0.027370188385248184,
      -0.0285453200340271,
      -0.032681141048669815,
      -0.05335528403520584,
      0.004965408705174923,
      0.05486293509602547,
      -0.06831055879592896,
      0.0008139421697705984,
      -0.009030588902533054,
      -0.022278914228081703,
      0.04531756043434143,
      -0.04638867825269699,
      -0.05085809528827667,
      0.004386838525533676,
      -0.013977330178022385,
      0.023410294204950333,
      -0.09628254920244217,
      -0.002126443199813366,
      -0.005476097576320171,
      -0.03929053992033005,
      -0.05744252726435661,
      -0.05183848738670349,
      0.01046544685959816,
      0.010354847647249699,
      0.06483421474695206,
      -0.015376182273030281,
      -0.023686788976192474,
      0.04394841566681862,
      -0.0630401074886322,
      0.048764027655124664,
      0.007552486844360828,
      -0.009615682996809483,
      -0.06300020217895508,
      -0.11200354248285294,
      0.06892657279968262,
      -0.016250045970082283,
      -0.032157979905605316,
      0.007218362297862768,
      -0.042443081736564636,
      -0.00446704588830471,
      0.00941411405801773,
      -0.02540227398276329,
      -0.035113364458084106,
      0.01870134100317955,
      -0.023408181965351105,
      -0.030165210366249084,
      0.07380416989326477,
      0.001844751532189548,
      0.05746782198548317,
      0.043113600462675095,
      -0.07302723079919815,
      0.0855335220694542,
      -0.11618243157863617,
      0.08340820670127869,
      -0.01125616766512394,
      -0.00764606986194849,
      -0.04111616685986519,
      0.04430697485804558,
      -0.06543589383363724,
      -0.03647693619132042,
      0.027702849358320236,
      0.001349271391518414,
      -0.05338398739695549,
      0.1126074492931366,
      -0.03409956768155098,
      0.0010385141940787435,
      -0.03774438798427582,
      -0.13637571036815643,
      0.00229068286716938,
      0.09553895890712738,
      0.027786286547780037,
      -0.021627554669976234,
      -0.06442715972661972,
      0.015343028120696545,
      0.01441983599215746,
      -0.016235703602433205,
      0.03224388509988785,
      -0.1083253026008606,
      -0.011007032357156277,
      -0.0227634459733963,
      0.010337360203266144,
      -0.0058882636949419975,
      -0.041003186255693436,
      0.027001338079571724,
      0.052980173379182816,
      -0.004250077530741692,
      0.014733546413481236,
      0.0588117353618145,
      0.027739526703953743,
      0.004393918439745903,
      0.0712294951081276,
      0.03670092299580574,
      -0.004558813292533159,
      0.007463327143341303,
      -0.005492851138114929,
      -0.03224128484725952,
      0.014810233376920223,
      -0.02489537186920643,
      0.06376450508832932,
      0.06952062994241714,
      0.014241076074540615,
      -0.024786939844489098,
      0.03005201183259487,
      -0.04116833955049515,
      -0.03392745926976204,
      -0.07244809716939926,
      -0.04046967625617981,
      -0.0007050778949633241,
      0.0038372809067368507,
      0.022796064615249634,
      -0.021864477545022964,
      0.05921616032719612,
      0.04465240240097046,
      -0.013723024167120457,
      -0.003600167343392968,
      -0.00264408509247005,
      0.03878694772720337,
      0.03730028495192528,
      0.07331909984350204,
      0.017303401604294777,
      0.047367163002491,
      0.03408000245690346,
      0.009178437292575836,
      -0.062218256294727325,
      0.005413681268692017,
      -0.026898477226495743,
      -0.04568394646048546,
      -0.054084427654743195,
      -0.019040904939174652,
      0.019287217408418655,
      0.022873615846037865,
      0.030707549303770065,
      -0.04108817130327225,
      0.012136528268456459,
      -0.04025636985898018,
      -0.059203363955020905,
      0.008348342031240463,
      -0.0007997960201464593,
      -0.01288997195661068,
      -0.0732506588101387,
      0.07720992714166641,
      -0.025502078235149384,
      -0.0074090296402573586,
      0.02118624560534954,
      -0.03155580535531044,
      0.0835287868976593,
      -0.06169202923774719,
      -0.011598434299230576,
      2.200380913564004e-05,
      0.006401124410331249,
      -0.0015394449001178145,
      -0.09275215119123459,
      -0.04165458306670189,
      0.018948538228869438,
      -0.0042620133608579636,
      -0.045137859880924225,
      0.09484291821718216,
      0.023685583844780922,
      -0.004302261862903833,
      0.026512805372476578,
      0.02567165531218052,
      0.030881475657224655,
      -0.02416260913014412,
      0.01812584325671196,
      0.03550148755311966,
      -0.04059068113565445,
      -0.013669951818883419,
      0.05741085112094879,
      0.0024782654363662004,
      -0.051686521619558334,
      0.01937643066048622,
      0.022288063541054726,
      0.040538493543863297,
      -0.025202475488185883,
      0.10475126653909683,
      -0.030829256400465965,
      0.005803113337606192,
      -0.0959165096282959,
      0.02494334988296032,
      0.009427294135093689,
      -0.0744897872209549,
      -0.022654850035905838,
      0.03556124493479729,
      0.008200776763260365,
      0.014416922815144062,
      -0.04378832131624222,
      0.08487236499786377,
      0.020274536684155464,
      0.016973312944173813,
      -0.02925584279000759,
      0.04141389578580856,
      -0.06714922934770584,
      0.03174516558647156,
      0.047227464616298676,
      0.07555682212114334,
      -0.002142098266631365,
      -0.0048522199504077435,
      0.052655577659606934,
      -0.017865866422653198,
      -0.012011900544166565,
      0.00945180095732212,
      0.07322312146425247,
      0.016278162598609924,
      0.031518083065748215,
      -0.025702016428112984,
      0.087282195687294,
      -0.16524358093738556,
      -0.015074738301336765,
      0.018274931237101555,
      0.0087504917755723,
      -0.03260206803679466,
      -0.05462047830224037,
      -0.020003829151391983,
      -0.05627348646521568,
      -0.04947386309504509,
      0.018647290766239166,
      -0.045710258185863495,
      -0.019236741587519646,
      -0.06929874420166016,
      -0.0611867792904377,
      0.005701728165149689,
      0.00415511941537261,
      -0.048176828771829605,
      -0.034330226480960846,
      -0.07931387424468994,
      0.06772662699222565,
      0.0658826157450676,
      0.01849285326898098,
      -0.004481232725083828,
      -0.026621634140610695,
      0.0010357650462538004,
      -0.012164633721113205,
      -0.010223536752164364,
      0.014847943559288979,
      -0.03538714721798897,
      -0.07742735743522644,
      0.0027157452423125505,
      0.003486348781734705,
      0.05758190155029297,
      0.01088064443320036,
      0.08736218512058258,
      0.022852227091789246,
      -0.03007449209690094,
      -0.019390614703297615,
      0.006202832795679569,
      0.02819244936108589,
      0.12478254735469818,
      -0.04950350522994995,
      0.017515702173113823,
      -0.01580728404223919,
      0.003838550765067339,
      0.026274416595697403,
      -0.054797861725091934,
      0.020008856430649757,
      0.08816446363925934,
      0.06705860048532486,
      -0.005946752615272999,
      0.02763568051159382,
      -0.06930458545684814,
      0.01747450791299343,
      -0.11489163339138031,
      0.03861461952328682,
      0.001991948578506708,
      -0.003065920202061534,
      0.09960365295410156,
      -0.0888800173997879,
      0.02372473105788231,
      -0.0012130995746701956,
      -0.01613292284309864,
      -0.012200850062072277,
      -0.01375352218747139,
      -0.0973346158862114,
      0.011404131539165974,
      0.0229704100638628,
      0.06331648677587509,
      0.05275793746113777,
      0.048971839249134064,
      -0.007879547774791718,
      0.029087649658322334,
      0.022818854078650475,
      0.051994383335113525,
      0.01505469623953104,
      0.04069650173187256,
      0.019490065053105354,
      -0.014535706490278244,
      0.05793929100036621,
      -0.023506181314587593,
      0.027875106781721115,
      -0.03102421574294567,
      0.05080223083496094,
      0.028896693140268326,
      0.07558996975421906,
      0.030072318390011787,
      -0.0284877959638834,
      -0.02293785661458969,
      0.06812996417284012,
      -0.06363633275032043,
      0.029150282964110374,
      0.005180431064218283,
      -0.0011868217261508107,
      -0.06605862081050873,
      0.032756105065345764,
      0.021814590319991112,
      -0.10706673562526703,
      -0.05063549056649208,
      0.04814295843243599,
      -0.0009005190804600716,
      -0.014575581066310406,
      -0.023739153519272804,
      -0.04931753873825073,
      -0.03409275412559509,
      -0.046846408396959305,
      0.089308001101017,
      0.03437196463346481,
      0.04616032913327217,
      -0.014982317574322224,
      -0.019816212356090546,
      -0.051676325500011444,
      0.010998185724020004,
      -0.02237730100750923
    ],
    [
      0.002648036228492856,
      0.06659072637557983,
      0.06057622283697128,
      -0.037184637039899826,
      -0.01892988756299019,
      0.00326252612285316,
      0.01956641674041748,
      0.025259533897042274,
      -0.081301748752594,
      0.0022712876088917255,
      0.007885154336690903,
      0.04001462832093239,
      0.021036818623542786,
      0.08099131286144257,
      -0.035755664110183716,
      0.025458645075559616,
      0.034277405589818954,
      0.02409967966377735,
      -0.016538525000214577,
      0.041575513780117035,
      0.031023530289530754,
      0.051899757236242294,
      0.0013072731671854854,
      0.02938050776720047,
      0.052622392773628235,
      0.05554284527897835,
      -0.07801637798547745,
      0.033160824328660965,
      0.04110227897763252,
      0.060522522777318954,
      -0.05318005755543709,
      0.072098508477211,
      -0.08653456717729568,
      0.02999609336256981,
      0.004078153520822525,
      0.07128387689590454,
      0.027949243783950806,
      -0.031430840492248535,
      0.03981074318289757,
      -0.08899009227752686,
      -0.052381668239831924,
      -0.027662398293614388,
      0.014520539902150631,
      0.05010661855340004,
      0.024247966706752777,
      0.0396001972258091,
      0.014288363978266716,
      -0.05762180685997009,
      0.03321647271513939,
      0.034498199820518494,
      0.05802804231643677,
      -0.033582113683223724,
      0.012325967662036419,
      -0.010069871321320534,
      0.016236562281847,
      0.0073850261978805065,
      0.01959673874080181,
      0.06742386519908905,
      -0.09738028049468994,
      -0.06881004571914673,
      0.07244785875082016,
      0.028619561344385147,
      0.0808321014046669,
      0.0039831907488405704,
      0.026580631732940674,
      -0.03550083562731743,
      0.05461573973298073,
      0.03874962404370308,
      -0.03202854096889496,
      0.03375418856739998,
      -0.046299826353788376,
      0.019470931962132454,
      -0.0207732692360878,
      0.027917936444282532,
      0.015572324395179749,
      -0.024317150935530663,
      0.008701956830918789,
      0.023329958319664,
      0.014693737961351871,
      -0.06101594120264053,
      0.05947774648666382,
      0.09322057664394379,
      0.020005445927381516,
      0.03830568864941597,
      0.04442086070775986,
      0.052049119025468826,
      0.027669912204146385,
      0.0006826137541793287,
      0.0710826963186264,
      0.005482648033648729,
      -0.03057737648487091,
      -0.11844873428344727,
      0.032822586596012115,
      0.08685284107923508,
      -0.007356129586696625,
      -0.01228592824190855,
      -0.023284628987312317,
      -0.07927180826663971,
      -0.0579427070915699,
      -0.028809349983930588,
      0.06789890676736832,
      -0.08836065232753754,
      0.0074644614942371845,
      0.01695638708770275,
      0.03780483826994896,
      0.05877875164151192,
      -0.01616312563419342,
      0.01237545907497406,
      -0.02788836695253849,
      -0.022762136533856392,
      -0.054845180362463,
      0.018937580287456512,
      0.003972211387008429,
      -0.04577169567346573,
      0.011664235964417458,
      0.00927249900996685,
      0.011145392432808876,
      -0.05989193543791771,
      0.010594338178634644,
      -0.009799945168197155,
      0.04242382198572159,
      0.013682661578059196,
      -0.009752029553055763,
      0.07608293741941452,
      -0.026897436007857323,
      0.03944862261414528,
      0.021055808290839195,
      -0.017902590334415436,
      0.011395539157092571,
      -0.01963975839316845,
      -0.1007484421133995,
      -0.03488209843635559,
      -0.022458503022789955,
      0.0779804065823555,
      0.03674207255244255,
      -0.0647524818778038,
      -0.04886498302221298,
      -0.006896037608385086,
      0.028057923540472984,
      0.0008297545136883855,
      -0.022155538201332092,
      -0.024513693526387215,
      -0.015219712629914284,
      0.07056964933872223,
      0.003399719949811697,
      0.025036724284291267,
      -0.04227405786514282,
      0.03979434445500374,
      0.033381931483745575,
      0.06156015396118164,
      0.019397607073187828,
      0.03674587979912758,
      0.02514745481312275,
      0.04692647233605385,
      0.04644636809825897,
      0.054507602006196976,
      -0.03676360845565796,
      0.030094163492321968,
      0.0003361976705491543,
      -0.04576760157942772,
      0.010364108718931675,
      -0.03828288987278938,
      -0.000612402509432286,
      -0.03286197781562805,
      0.07646243274211884,
      0.018938850611448288,
      -0.039192065596580505,
      0.051148753613233566,
      -0.062057532370090485,
      0.06375637650489807,
      0.021436475217342377,
      -0.022916903719305992,
      0.028701050207018852,
      0.018626743927598,
      0.01657743752002716,
      0.04788413271307945,
      -0.060079995542764664,
      0.04795609787106514,
      0.029490016400814056,
      0.008355897851288319,
      -0.03875911235809326,
      0.036493077874183655,
      0.025688139721751213,
      0.07474685460329056,
      -0.040299367159605026,
      0.0009187685209326446,
      -0.03115975297987461,
      -0.03974221646785736,
      -0.0076987892389297485,
      -0.02691691927611828,
      0.023253750056028366,
      0.03899312764406204,
      -0.05575883015990257,
      -0.015189368277788162,
      0.026590587571263313,
      0.0033720287028700113,
      0.026345551013946533,
      0.015213620848953724,
      -0.08658427745103836,
      -0.07424942404031754,
      0.05479202792048454,
      0.07840468734502792,
      -0.029449939727783203,
      -0.0009611555142328143,
      -0.037630267441272736,
      0.03753567859530449,
      -0.0009641677606850863,
      0.053671736270189285,
      -0.047834109514951706,
      0.10819441825151443,
      -0.010037781670689583,
      -0.050982870161533356,
      -0.03328400477766991,
      0.041241686791181564,
      0.05337723344564438,
      0.053769905120134354,
      0.021428244188427925,
      -0.009057626128196716,
      -0.03523466736078262,
      -0.01762745901942253,
      0.018920395523309708,
      -0.02049979381263256,
      0.022509872913360596,
      0.004871266428381205,
      0.04102407395839691,
      0.0222316961735487,
      0.03389706462621689,
      -0.04304496943950653,
      0.03847939893603325,
      -0.007396521978080273,
      0.04880760982632637,
      -0.007616308983415365,
      -0.004354235716164112,
      -0.0459410734474659,
      -0.04480443894863129,
      -0.06382931768894196,
      0.04218115285038948,
      -0.07135192304849625,
      -0.04095553234219551,
      0.06499288231134415,
      -0.03309476003050804,
      0.06949003040790558,
      0.011625783517956734,
      0.006447897292673588,
      0.039593953639268875,
      -0.044341426342725754,
      -0.03369764983654022,
      -0.006430432666093111,
      0.010140348225831985,
      0.015332418493926525,
      0.028618700802326202,
      0.009528654627501965,
      -0.03892471641302109,
      0.0323452427983284,
      -0.05654066056013107,
      -0.04993341863155365,
      0.0396018847823143,
      0.08717209845781326,
      0.061909839510917664,
      0.013110994361341,
      0.10080236196517944,
      -0.04398581013083458,
      0.0024043817538768053,
      0.046772945672273636,
      0.033395975828170776,
      -0.0681416466832161,
      0.024181680753827095,
      -0.05497531220316887,
      -0.025572024285793304,
      -0.05637691915035248,
      -0.057116102427244186,
      -0.0332549512386322,
      -0.023504961282014847,
      0.015266257338225842,
      -0.06082703545689583,
      -0.03631776198744774,
      0.031197478994727135,
      -0.009237134829163551,
      -0.009485693648457527,
      0.0498199425637722,
      -0.07006452232599258,
      0.030971968546509743,
      -0.034597888588905334,
      -0.0038365523796528578,
      -0.02076370269060135,
      0.05648117884993553,
      -0.0065690395422279835,
      0.007492576725780964,
      -0.060813721269369125,
      0.017845958471298218,
      -0.04704514145851135,
      -0.00082390260649845,
      0.046396654099226,
      -0.08642327040433884,
      -0.057283151894807816,
      -0.059617336839437485,
      -0.08075209707021713,
      0.08996744453907013,
      -0.0008151786169037223,
      -0.028999119997024536,
      -0.005719327833503485,
      0.04913714528083801,
      0.0044484916143119335,
      -0.03447513282299042,
      -0.08117837458848953,
      -0.02135356143116951,
      0.04229589179158211,
      -0.05797489732503891,
      -0.082135871052742,
      0.09383554011583328,
      0.037112049758434296,
      -0.016223767772316933,
      0.07335764914751053,
      -0.02436118572950363,
      0.020720956847071648,
      -0.01968424953520298,
      0.05480965971946716,
      -0.03149549663066864,
      -0.05971309170126915,
      0.007593698333948851,
      0.06823627650737762,
      -0.010196857154369354,
      0.12739889323711395,
      0.06342361867427826,
      -0.0030850921757519245,
      -0.08347032964229584,
      0.056570712476968765,
      0.0966392233967781,
      0.04302459955215454,
      -0.08797184377908707,
      -0.033931806683540344,
      -0.053527433425188065,
      -0.03133700415492058,
      0.060614146292209625,
      0.08520639687776566,
      -0.00871079508215189,
      0.04337478056550026,
      -0.02784731052815914,
      0.042099591344594955,
      -0.007514804136008024,
      0.06541495025157928,
      0.04950866475701332,
      -0.01013008039444685,
      -0.04377909377217293,
      0.031006567180156708,
      -0.0012227098923176527,
      0.03279126062989235,
      -0.03294769674539566,
      0.06564857810735703,
      0.01774866133928299,
      0.10392744094133377,
      -0.022350909188389778,
      -0.12382782995700836,
      -0.04476243257522583,
      0.046036116778850555,
      -0.013312474824488163,
      -0.035641610622406006,
      -0.025227012112736702,
      0.1090645119547844,
      -0.03262311592698097,
      -0.009447814896702766,
      0.07624461501836777,
      0.0005089148762635887,
      0.017034929245710373,
      -0.04777098819613457,
      -0.01857324317097664,
      0.031178101897239685,
      -0.009700125083327293,
      -0.0651639997959137,
      -0.015460231341421604,
      -0.005728633608669043,
      0.10755275189876556,
      -0.03315148130059242,
      -0.01908465474843979,
      -0.02158077247440815,
      0.044588442891836166,
      0.037944246083498,
      0.050053395330905914,
      0.016188623383641243,
      -0.01724294014275074,
      -0.0706549882888794,
      -0.13489237427711487,
      0.013923557475209236,
      -0.08857369422912598,
      0.060248248279094696,
      0.0038225646130740643,
      -0.09721836447715759,
      -0.0027055516839027405,
      0.0013829597737640142,
      -0.03573494404554367,
      -0.05201639607548714,
      0.013377209194004536,
      -0.0367998443543911,
      0.04132385179400444,
      0.05622970685362816,
      -0.020543497055768967,
      -0.02093067578971386,
      -0.04343084990978241,
      -0.07410750538110733,
      -0.05318450555205345,
      -0.007288430817425251,
      -0.07959163188934326,
      -0.015365098603069782,
      0.05072449892759323,
      -0.002393890405073762,
      -0.009242736734449863,
      -0.006145155522972345,
      -0.04219440743327141,
      -0.00683897640556097,
      -0.009897466748952866,
      0.0033044787123799324,
      0.04237031936645508,
      -0.06799600273370743,
      -0.02994953840970993,
      0.04937150701880455,
      0.03746901452541351,
      0.0549740195274353,
      -0.11658687144517899,
      0.12588359415531158,
      -0.06017915531992912,
      -0.023048659786581993,
      0.0002154569374397397,
      -0.09515692293643951,
      0.0006001432775519788,
      0.035619936883449554,
      -0.032092269510030746,
      -0.10576196759939194,
      -0.03972006216645241,
      -0.05323188006877899,
      0.013460079208016396,
      -0.06651867181062698,
      -0.05734633281826973,
      0.026132607832551003,
      -0.03026840090751648,
      0.02816370688378811,
      -0.099557064473629,
      -0.0735965445637703,
      -0.018169915303587914,
      0.04760522395372391,
      -0.09668363630771637,
      0.056835275143384933,
      0.03952397033572197,
      -0.0199428740888834,
      0.010763926431536674,
      -0.05693632736802101,
      -0.008541958406567574,
      -0.055131953209638596,
      -0.044741999357938766,
      0.04497662931680679,
      0.010655522346496582,
      0.054927803575992584,
      -0.014118029735982418,
      -0.04114122316241264,
      -0.005572175607085228,
      0.0039496407844126225,
      -0.007137100212275982,
      0.03615080565214157,
      0.11698488891124725,
      0.04709002003073692,
      0.008706879802048206,
      -0.0546034537255764,
      0.00966702215373516,
      -0.05935931205749512,
      -0.018431713804602623,
      -0.05718708410859108,
      0.03438470885157585,
      0.0017078628297895193,
      0.024040894582867622,
      0.03614133223891258,
      -0.0751386284828186,
      0.039284367114305496,
      -0.02314094826579094,
      -0.06594085693359375,
      0.014865352772176266,
      -0.05002586171030998,
      -0.0598796084523201,
      -0.03432125970721245,
      0.022978372871875763,
      -0.016677744686603546,
      0.005855558440089226,
      0.020960956811904907,
      0.1184764951467514,
      0.03753606602549553,
      0.009747409261763096,
      -0.05025380104780197,
      -0.013721776194870472,
      -0.003281101817265153,
      0.0420830212533474,
      -0.07823263108730316,
      0.023658763617277145,
      0.0474710538983345,
      0.00218483479693532,
      -0.03850741311907768,
      -0.013884694315493107,
      -0.0825951099395752,
      -0.04147203266620636,
      0.0229105856269598,
      -0.008143428713083267,
      0.06545352935791016,
      0.01115858182311058,
      -0.04621616750955582,
      0.04836557060480118,
      -0.014849870465695858
    ],
    [
      -0.026624884456396103,
      -0.007942904718220234,
      0.055518705397844315,
      0.03347758203744888,
      -0.021088790148496628,
      -0.013200224377214909,
      0.005108272656798363,
      -0.0911097452044487,
      0.07366810739040375,
      0.06735528260469437,
      0.01141250692307949,
      0.0006193211884237826,
      0.10479580610990524,
      0.06227142736315727,
      0.002954920521005988,
      0.0273901280015707,
      -0.016724977642297745,
      0.016120480373501778,
      0.01205698773264885,
      0.011361693032085896,
      -0.001914668595418334,
      0.014526993967592716,
      0.0075421519577503204,
      -0.047378357499837875,
      -0.013432387262582779,
      -0.03214200958609581,
      -0.008636626414954662,
      0.07198625802993774,
      0.05292662978172302,
      -0.03052334487438202,
      -0.04107646271586418,
      -0.014439988881349564,
      -0.04580644518136978,
      0.03583855554461479,
      -0.01702478900551796,
      0.025015000253915787,
      -0.008445648476481438,
      0.019718535244464874,
      0.05597352236509323,
      0.001770999631844461,
      -0.07219013571739197,
      -0.031058620661497116,
      0.02133265696465969,
      0.008665669709444046,
      0.004318859428167343,
      -0.10165996104478836,
      -0.003106198273599148,
      -0.023936284705996513,
      -0.03708343207836151,
      0.0516696497797966,
      0.05177706107497215,
      -0.09341655671596527,
      -0.01612274907529354,
      0.01905152201652527,
      -0.036989253014326096,
      -0.008273590356111526,
      -0.044503435492515564,
      0.06076109781861305,
      -0.00041359078022651374,
      -0.010077299550175667,
      -0.048495177179574966,
      0.00027441440033726394,
      -0.020839186385273933,
      -0.026777882128953934,
      -0.05598674342036247,
      0.058576952666044235,
      0.03442176803946495,
      -0.010415003634989262,
      0.026014041155576706,
      -0.01487533375620842,
      -0.03843047097325325,
      -0.03253210335969925,
      -0.004766465630382299,
      -0.007117883767932653,
      -0.004828173667192459,
      -0.0027717750053852797,
      -0.04276801645755768,
      -0.012309566140174866,
      0.04774022474884987,
      0.02481049671769142,
      -0.003288616891950369,
      0.005021161865442991,
      0.03326726332306862,
      0.04078778997063637,
      0.024129249155521393,
      0.028188802301883698,
      0.02245909906923771,
      -0.038173891603946686,
      0.0749010369181633,
      -0.001592652639374137,
      0.036318618804216385,
      -0.0035158763639628887,
      0.004146928898990154,
      0.009520375169813633,
      -0.0806264877319336,
      0.02627018466591835,
      -0.04524434357881546,
      0.06048794090747833,
      -0.034667376428842545,
      -0.037606410682201385,
      0.05982403829693794,
      0.032531388103961945,
      -0.05117791146039963,
      0.029485251754522324,
      0.02200990356504917,
      0.07729434967041016,
      -0.07760558277368546,
      0.03278166800737381,
      0.04325978085398674,
      0.027890188619494438,
      -0.06005019694566727,
      0.005730974487960339,
      -0.06763649731874466,
      0.012330753728747368,
      0.036513522267341614,
      -0.041365087032318115,
      -0.07644063234329224,
      0.024081828072667122,
      0.003998789936304092,
      0.018310176208615303,
      0.065567746758461,
      0.10999970883131027,
      -0.008542027324438095,
      0.050975870341062546,
      0.02693110518157482,
      0.006915964651852846,
      -0.0036708933766931295,
      0.09082593768835068,
      0.0454779788851738,
      -0.06957841664552689,
      -0.07187378406524658,
      0.05982477217912674,
      0.007141515612602234,
      0.0068709636107087135,
      0.10765697807073593,
      0.028057346120476723,
      0.010649862699210644,
      -0.04564402252435684,
      0.04966574162244797,
      0.07552213221788406,
      0.022792477160692215,
      -0.01900460757315159,
      -0.05382338911294937,
      0.1114758625626564,
      0.04490751773118973,
      0.00837252102792263,
      -0.026102812960743904,
      0.0014266513753682375,
      0.062085092067718506,
      -0.09047038108110428,
      -0.004178817383944988,
      0.058175668120384216,
      0.016586583107709885,
      -0.0009080705931410193,
      0.02277585119009018,
      0.03260808065533638,
      0.02579626813530922,
      -0.04031363129615784,
      0.015520813874900341,
      0.01740049570798874,
      -0.06841737776994705,
      0.010070858523249626,
      0.05439067631959915,
      -0.022902045398950577,
      -0.07505356520414352,
      0.02459339238703251,
      0.016035964712500572,
      -0.008889731951057911,
      0.021120840683579445,
      -0.08450081944465637,
      0.004563536960631609,
      -0.007166809868067503,
      -0.004831681493669748,
      0.011229807510972023,
      -0.021097512915730476,
      0.009266889654099941,
      0.00223032059147954,
      -0.029167454689741135,
      -0.017398888245224953,
      0.05767977237701416,
      0.08287188410758972,
      0.031148625537753105,
      -0.028551626950502396,
      0.04988677799701691,
      0.049722619354724884,
      -0.026086287572979927,
      -0.007748812902718782,
      -0.08154179155826569,
      -0.004701870493590832,
      -0.015172707848250866,
      0.02463676780462265,
      0.04029034078121185,
      0.026355333626270294,
      -0.036016445606946945,
      -0.039287272840738297,
      0.006564510986208916,
      -0.058067671954631805,
      -0.04755251109600067,
      -0.021698717027902603,
      -0.06838271766901016,
      -0.04766954854130745,
      0.03850361332297325,
      0.06427742540836334,
      -0.03970932960510254,
      -0.026010649278759956,
      -0.06339864432811737,
      0.04412133991718292,
      0.0344327911734581,
      0.04028692841529846,
      0.027493633329868317,
      0.012463031336665154,
      0.057149872183799744,
      0.05918825790286064,
      0.07680246233940125,
      -0.08577131479978561,
      -0.03605290502309799,
      -0.006796418689191341,
      -0.02157820574939251,
      0.014340264722704887,
      -0.042650360614061356,
      0.04590185731649399,
      0.003858344629406929,
      0.10758164525032043,
      0.046674735844135284,
      0.010032355785369873,
      0.023739755153656006,
      -0.0010089650750160217,
      -0.0017750546103343368,
      -0.006587469018995762,
      -0.02988475374877453,
      0.04435329511761665,
      -0.10991968214511871,
      -0.02548658661544323,
      0.058862995356321335,
      -0.03650055453181267,
      -0.04078441858291626,
      0.021605338901281357,
      -0.047349248081445694,
      0.015935178846120834,
      0.08699575066566467,
      -0.025742307305336,
      0.06032157316803932,
      -0.05614474415779114,
      0.02862582355737686,
      0.04611798748373985,
      -0.006817173212766647,
      0.048319850116968155,
      -0.06884338706731796,
      0.012065380811691284,
      0.00832915399223566,
      0.022282082587480545,
      -0.04983605816960335,
      -0.049900006502866745,
      -0.06734084337949753,
      0.007634337525814772,
      -0.026225952431559563,
      0.008601314388215542,
      -0.012919084168970585,
      0.11376301944255829,
      -0.08489750325679779,
      0.11635575443506241,
      0.003030182560905814,
      0.025285452604293823,
      0.05293673276901245,
      0.011493486352264881,
      -0.08987601101398468,
      0.004380726721137762,
      -0.04218459129333496,
      -0.020617827773094177,
      0.004628526046872139,
      -0.005935392342507839,
      0.03156266361474991,
      -0.05470200255513191,
      0.03450118750333786,
      -0.03253168612718582,
      -0.005868289154022932,
      0.0006542574847117066,
      0.011168974451720715,
      -0.008325505070388317,
      0.02197505533695221,
      -0.03667396679520607,
      -0.06828639656305313,
      0.0162452794611454,
      0.006380282808095217,
      0.021871961653232574,
      -0.0841720700263977,
      0.023549780249595642,
      -0.050819408148527145,
      -0.04953393712639809,
      -0.038034841418266296,
      0.004232713486999273,
      0.017808789387345314,
      0.006081802304834127,
      0.06421184539794922,
      0.054927293211221695,
      0.024342266842722893,
      -0.023900754749774933,
      -0.08722999691963196,
      0.015046474523842335,
      -0.07553362846374512,
      0.004411699716001749,
      -0.008224289864301682,
      -0.0038409957196563482,
      0.05073565989732742,
      0.01968751847743988,
      0.01023365743458271,
      -0.04528524726629257,
      0.011434359475970268,
      -0.0019809973891824484,
      -0.0020613332744687796,
      -0.026872973889112473,
      -0.007194909732788801,
      -0.05206914618611336,
      -0.005036108661442995,
      -0.005723400041460991,
      0.0225971732288599,
      0.08002707362174988,
      0.03515859693288803,
      -0.04889640957117081,
      -0.02831467054784298,
      -0.04038473218679428,
      0.01532855536788702,
      0.0031664930284023285,
      -0.01710348390042782,
      -0.062293313443660736,
      0.06737995892763138,
      0.07893182337284088,
      -0.040462955832481384,
      0.06806578487157822,
      -0.06549549102783203,
      0.05422680452466011,
      -0.01672329381108284,
      -0.0527370385825634,
      0.009750494733452797,
      -0.05030794441699982,
      -0.056522566825151443,
      0.07491928339004517,
      0.12345864623785019,
      0.035866398364305496,
      0.00743178790435195,
      -0.0404561385512352,
      -0.06718185544013977,
      0.0660267248749733,
      -0.04799467697739601,
      0.07351012527942657,
      0.0627521425485611,
      0.0005437206709757447,
      -0.00307452748529613,
      -0.030100490897893906,
      0.0024880655109882355,
      -0.024893801659345627,
      0.009805714711546898,
      -0.09177074581384659,
      0.002692192792892456,
      -0.01423522550612688,
      0.017276935279369354,
      -0.007438435684889555,
      0.008849652484059334,
      -0.017560113221406937,
      0.004624155815690756,
      -0.021477121859788895,
      -0.013226283714175224,
      -0.013494201004505157,
      -0.11875186860561371,
      -0.05038757622241974,
      -0.04903838783502579,
      0.00338884093798697,
      0.0024601351469755173,
      0.035596754401922226,
      -0.057134099304676056,
      -0.04583783820271492,
      0.05009518936276436,
      0.02412262186408043,
      0.002791136270388961,
      -0.04210110381245613,
      -0.04291347786784172,
      -0.004638379905372858,
      0.02391485497355461,
      -0.004271989688277245,
      0.053646937012672424,
      -0.024121778085827827,
      -0.029007630422711372,
      -0.0070151882246136665,
      0.0012471358058974147,
      -0.061903130263090134,
      -0.11143483221530914,
      0.01901996321976185,
      -0.07169336825609207,
      -0.09135088324546814,
      -0.013635731302201748,
      -0.05853189527988434,
      -0.062124140560626984,
      -0.04800670966506004,
      -0.002626219764351845,
      -0.012732051312923431,
      -0.02866409905254841,
      0.03618002310395241,
      -0.010382816195487976,
      -0.03156532347202301,
      -0.005478186998516321,
      0.025001710280776024,
      -0.0029555303044617176,
      -0.025500699877738953,
      0.01877753436565399,
      -0.009367179125547409,
      -0.010950380004942417,
      -0.11595968157052994,
      0.029804402962327003,
      0.05132804438471794,
      -0.007293321657925844,
      -0.07002976536750793,
      0.025964787229895592,
      0.00012110190436942503,
      0.05237903818488121,
      -0.005351477302610874,
      0.11250568181276321,
      -0.03758092224597931,
      0.009303929284214973,
      0.014224695973098278,
      0.049199752509593964,
      -0.06969930231571198,
      0.028629906475543976,
      -0.029994703829288483,
      -0.07825371623039246,
      -0.028454912826418877,
      -0.011826184578239918,
      -0.005841168574988842,
      0.009420736692845821,
      0.010921826586127281,
      0.07747867703437805,
      0.016489308327436447,
      0.047593578696250916,
      -0.023784253746271133,
      0.025729581713676453,
      0.014729240909218788,
      -0.03385148569941521,
      0.032926224172115326,
      0.027600014582276344,
      0.057710424065589905,
      -0.028751011937856674,
      -0.06848261505365372,
      -0.04957185685634613,
      -0.011062480509281158,
      -0.05077739059925079,
      0.02202444151043892,
      -0.02095738798379898,
      0.04718955606222153,
      0.06626175343990326,
      0.015906326472759247,
      -0.03875578194856644,
      -0.041199102997779846,
      -0.009205751121044159,
      -0.00874904915690422,
      0.004666788969188929,
      -0.01271485723555088,
      0.0019171267049387097,
      0.007641373202204704,
      0.11132733523845673,
      0.04808400571346283,
      -0.004928514827042818,
      -0.03773593530058861,
      -0.026587512344121933,
      -0.0315871499478817,
      0.07161407917737961,
      -0.08418088406324387,
      0.04373833164572716,
      0.012550774961709976,
      0.0264052152633667,
      -0.05223032087087631,
      0.02392561547458172,
      0.0031345784664154053,
      0.0066432347521185875,
      0.020866064354777336,
      -0.050582535564899445,
      0.05636560544371605,
      -0.08844170719385147,
      -0.06071503460407257,
      0.0282041747123003,
      0.03574861213564873,
      -0.07070378214120865,
      0.01942623220384121,
      -0.0358290895819664,
      -0.034409698098897934,
      0.038864608854055405,
      -0.01388095784932375,
      -0.0075013102032244205,
      -0.06936901807785034,
      -0.010674048215150833,
      0.0274843517690897,
      -0.024310395121574402,
      0.044608354568481445,
      -0.007728160824626684,
      -0.05190528556704521,
      -0.006379162427037954,
      -0.011031820438802242,
      -0.009480888955295086,
      -0.04284463822841644,
      0.02123568207025528,
      -0.02816617861390114,
      -0.052925433963537216,
      -0.06310553103685379,
      0.06413400918245316,
      -0.014719018712639809
    ],
    [
      -0.08135177195072174,
      -0.028971053659915924,
      0.04742431640625,
      -0.01271833386272192,
      0.00184141774661839,
      0.00019029356190003455,
      -0.03794533386826515,
      -0.007148341275751591,
      0.05636382848024368,
      0.0427902415394783,
      -0.01627175323665142,
      -0.06368780881166458,
      0.03605195879936218,
      0.04947279021143913,
      0.003211584873497486,
      0.0076031978242099285,
      -0.017029326409101486,
      -0.006885262206196785,
      0.03865998983383179,
      0.03527568280696869,
      0.007892405614256859,
      0.0291769839823246,
      -0.07896123081445694,
      0.06609871983528137,
      -0.04922020435333252,
      -0.03504651412367821,
      0.0066239601001143456,
      0.03687337413430214,
      -0.030507419258356094,
      0.017140528187155724,
      -0.039142169058322906,
      0.03973544016480446,
      0.0016521592624485493,
      -0.07258988916873932,
      -0.01988181099295616,
      0.018535632640123367,
      0.08248944580554962,
      0.04248133301734924,
      0.01850074902176857,
      -0.010326837189495564,
      0.06301702558994293,
      -0.03300180286169052,
      -0.006338254548609257,
      0.02944883517920971,
      -0.018784353509545326,
      0.061866600066423416,
      0.00200247741304338,
      0.042879778891801834,
      -0.02684151753783226,
      0.048282332718372345,
      -0.02024354785680771,
      -0.03188004344701767,
      0.045764561742544174,
      -0.03594505041837692,
      -0.05594547837972641,
      0.02572459913790226,
      0.01709865964949131,
      0.09431483596563339,
      -0.0772116482257843,
      -0.01971111260354519,
      0.005655247718095779,
      -0.03522318974137306,
      0.028763489797711372,
      0.06284427642822266,
      0.03935299813747406,
      0.05225071683526039,
      -0.012744666077196598,
      0.013553491793572903,
      0.034834735095500946,
      -0.015501330606639385,
      -0.0355803482234478,
      -0.09324931353330612,
      -0.05490798503160477,
      0.05332428589463234,
      0.023408329114317894,
      0.027682965621352196,
      -0.15599752962589264,
      -0.04112040996551514,
      -0.056987013667821884,
      0.06101108342409134,
      -0.06707966327667236,
      0.022860819473862648,
      0.09005489945411682,
      0.07895413786172867,
      -0.054182019084692,
      0.006926427595317364,
      -0.05359739065170288,
      -0.04124508425593376,
      0.0049669682048261166,
      0.04749426990747452,
      -0.04219331964850426,
      0.01339302770793438,
      -0.04052170738577843,
      -0.039943333715200424,
      -0.013908116146922112,
      -0.030210211873054504,
      0.10057101398706436,
      -0.025832414627075195,
      -0.06104985624551773,
      0.0330338180065155,
      -0.009441324509680271,
      0.011915761046111584,
      -0.013154083862900734,
      0.015837397426366806,
      0.06958629935979843,
      0.03389943763613701,
      0.10247065871953964,
      -0.010659635998308659,
      0.018971679732203484,
      0.12650886178016663,
      -0.04305700212717056,
      0.09553748369216919,
      -0.045212358236312866,
      -0.0012800884433090687,
      -0.059415269643068314,
      0.07202398777008057,
      -0.05951803922653198,
      0.014947384595870972,
      0.025334401056170464,
      0.023365341126918793,
      0.033549245446920395,
      0.07523372769355774,
      -0.0412660650908947,
      -0.0521431490778923,
      0.016869567334651947,
      -0.015406785532832146,
      -0.0005917844828218222,
      0.06937140226364136,
      -0.02853170596063137,
      0.034815702587366104,
      0.011080680415034294,
      0.02621978521347046,
      -0.02692100591957569,
      0.02149791829288006,
      -0.014178307726979256,
      -0.11202036589384079,
      -0.011194183491170406,
      -0.04114041104912758,
      0.02072993479669094,
      0.02261899597942829,
      0.0013114380417391658,
      -0.05370193347334862,
      0.012239276431500912,
      0.09289029985666275,
      0.06180427595973015,
      0.055469561368227005,
      -0.09483256191015244,
      -0.003936941735446453,
      -0.05577445030212402,
      0.02414250373840332,
      -0.0035273421090096235,
      0.056164197623729706,
      0.06933669745922089,
      0.004920962266623974,
      0.05039743334054947,
      0.0028185639530420303,
      -0.014875534921884537,
      -0.006592102348804474,
      -0.023233136162161827,
      0.046768102794885635,
      -0.04155751317739487,
      0.0019206543220207095,
      -0.03365376219153404,
      0.0038634687662124634,
      -0.011870338581502438,
      0.039648205041885376,
      0.03027297928929329,
      0.06003894284367561,
      -0.05719245597720146,
      0.005227156449109316,
      0.04813321679830551,
      0.007007651962339878,
      -0.015293396078050137,
      0.019884023815393448,
      -0.027212316170334816,
      -0.07702501863241196,
      -0.007955212146043777,
      0.08338351547718048,
      -0.022989237681031227,
      0.016571592539548874,
      0.023840831592679024,
      0.0006708288565278053,
      0.005417936481535435,
      0.0791359543800354,
      -0.04335984215140343,
      -0.0038303332403302193,
      -0.04686698690056801,
      -0.004734728019684553,
      0.06496311724185944,
      0.09574247896671295,
      0.08428113162517548,
      -0.046594008803367615,
      -0.04170718044042587,
      0.051705874502658844,
      0.019238164648413658,
      -0.047306664288043976,
      -0.03361295163631439,
      -0.028093494474887848,
      -0.05536806583404541,
      -0.0960506945848465,
      -0.002478592097759247,
      0.007047415245324373,
      0.04876168817281723,
      0.04366664960980415,
      -0.04627036675810814,
      0.014394665136933327,
      0.08067706972360611,
      -0.05182294920086861,
      0.033861178904771805,
      0.04466908052563667,
      0.007600951008498669,
      -0.06019822880625725,
      0.07040784507989883,
      0.06410496681928635,
      0.005313284229487181,
      -0.009920233860611916,
      0.027888864278793335,
      -0.10390863567590714,
      -0.015367092564702034,
      0.02424803003668785,
      0.026991520076990128,
      -0.09376607835292816,
      0.022541852667927742,
      0.0848824754357338,
      -0.07364355027675629,
      -0.03216312453150749,
      0.00750733120366931,
      0.01020100899040699,
      -0.0435064323246479,
      -0.045583825558423996,
      0.02035277709364891,
      -0.10156846046447754,
      -0.006014487240463495,
      -0.0004510733997449279,
      -0.04807744920253754,
      -0.03637677803635597,
      0.03594446927309036,
      -0.010832916013896465,
      0.042389772832393646,
      0.03467883914709091,
      0.05097135901451111,
      -0.00034173615858890116,
      -0.02746182680130005,
      0.036144599318504333,
      -0.027513334527611732,
      -0.057738177478313446,
      -0.015155686065554619,
      0.04536942020058632,
      0.05339404195547104,
      -0.050368618220090866,
      0.05862928926944733,
      0.016926798969507217,
      -0.009940344840288162,
      -0.009803863242268562,
      -0.0946301594376564,
      -0.06449734419584274,
      -0.004188466817140579,
      -0.07548566907644272,
      0.06644468754529953,
      -0.05774259939789772,
      0.036461539566516876,
      -0.013854804448783398,
      0.007375525310635567,
      -0.10504283010959625,
      -0.029634559527039528,
      0.020928939804434776,
      -0.009833034127950668,
      -0.002312291646376252,
      0.012755481526255608,
      0.03201001510024071,
      0.10479532182216644,
      0.11681525409221649,
      0.04300845414400101,
      -0.030605768784880638,
      -0.09093903005123138,
      -0.0012799424584954977,
      -0.04772353544831276,
      -0.02758212573826313,
      0.06493376940488815,
      -0.03120235539972782,
      0.030995018780231476,
      -0.09886755049228668,
      0.009976215660572052,
      0.053860265761613846,
      0.03548908978700638,
      -0.07247793674468994,
      -0.02191138081252575,
      -0.049267400056123734,
      0.026380358263850212,
      -0.05119533836841583,
      -0.0011391929583624005,
      0.055878691375255585,
      -0.005366344936192036,
      0.0065893144346773624,
      0.04046719893813133,
      0.054136987775564194,
      -0.021884923800826073,
      -0.010077196173369884,
      0.017140930518507957,
      0.0015942365862429142,
      -0.01789153926074505,
      -0.051030389964580536,
      -0.0199692752212286,
      0.07365256547927856,
      0.012061383575201035,
      -0.040642280131578445,
      0.007063417695462704,
      -0.019892467185854912,
      -0.04248402267694473,
      0.0397704616189003,
      -0.08202582597732544,
      0.01643572933971882,
      -0.0627625584602356,
      -0.057184621691703796,
      0.025739353150129318,
      -0.01916024647653103,
      0.0757967010140419,
      -0.04382457211613655,
      0.03381050005555153,
      -0.0033879668917506933,
      -0.008133155293762684,
      -0.02930230274796486,
      -0.0366516038775444,
      0.03554975986480713,
      -0.01377363596111536,
      0.006767790764570236,
      0.019603295251727104,
      -0.0024189441464841366,
      0.0012844534358009696,
      -0.020398985594511032,
      0.09575439989566803,
      -0.022379038855433464,
      0.022312380373477936,
      -0.019669676199555397,
      -0.014932435005903244,
      -0.017111433669924736,
      -0.04584464803338051,
      0.07896784693002701,
      -0.00011902307596756145,
      -0.04667661711573601,
      0.01257589366286993,
      -0.007072661072015762,
      0.024770120158791542,
      0.0042099072597920895,
      0.013280125334858894,
      -0.062252722680568695,
      0.019939986988902092,
      -0.03902417793869972,
      0.0336788184940815,
      0.01955767534673214,
      0.029267389327287674,
      -0.07661286741495132,
      0.05928746983408928,
      0.025255223736166954,
      0.027672545984387398,
      -0.008086069487035275,
      0.006567112170159817,
      -0.03514545038342476,
      -0.07204221934080124,
      -0.021132895722985268,
      0.031080590561032295,
      -0.04251579940319061,
      -0.07519583404064178,
      -0.081577830016613,
      0.02677350863814354,
      -0.026401085779070854,
      -0.022455208003520966,
      -0.028996430337429047,
      -0.021807601675391197,
      0.026713240891695023,
      -0.058705128729343414,
      -0.02294495701789856,
      -0.018813272938132286,
      -0.009914062917232513,
      0.02766723558306694,
      -0.00882025621831417,
      -0.005674633197486401,
      0.07348757237195969,
      -0.08705632388591766,
      0.023391710594296455,
      -0.03702336549758911,
      -0.005085476208478212,
      0.018260501325130463,
      -0.042972106486558914,
      -0.04721182584762573,
      0.053879790008068085,
      -0.06849664449691772,
      0.038725681602954865,
      -0.07331138104200363,
      -0.05743449926376343,
      -0.04798081889748573,
      0.024700960144400597,
      0.01147804781794548,
      -0.0610966756939888,
      -0.031184621155261993,
      -0.017794933170080185,
      0.007855845615267754,
      -0.021729934960603714,
      0.013009686022996902,
      0.0007317002746276557,
      -0.017798757180571556,
      -0.011397160589694977,
      -0.01206392701715231,
      0.05019808188080788,
      0.00660942355170846,
      -0.11244604736566544,
      -0.06946805864572525,
      -0.0034433987457305193,
      0.01542552001774311,
      -0.06476719677448273,
      -0.01487546693533659,
      0.03136979416012764,
      0.010416345670819283,
      -0.01446449849754572,
      0.026566926389932632,
      -0.0014497308293357491,
      0.033477533608675,
      -0.03125331550836563,
      0.0024157543666660786,
      0.043265461921691895,
      -0.021089838817715645,
      0.023937152698636055,
      0.021041948348283768,
      0.008056994527578354,
      0.03358440846204758,
      0.04479612037539482,
      -0.0790737196803093,
      0.05788261070847511,
      -0.05141196772456169,
      0.07514478266239166,
      -0.00736840208992362,
      -0.037413522601127625,
      0.03793860971927643,
      -0.026710843667387962,
      0.030350320041179657,
      -0.06099320948123932,
      0.0036801574751734734,
      0.031819332391023636,
      0.00830038171261549,
      -0.06947822868824005,
      0.03688599541783333,
      0.03136376664042473,
      0.08559516072273254,
      0.009612475521862507,
      0.0031634389888495207,
      0.010203177109360695,
      0.03963291272521019,
      0.053614161908626556,
      0.042215198278427124,
      0.02525247260928154,
      0.04611175134778023,
      -0.02206283248960972,
      -0.004393870942294598,
      0.023983636870980263,
      -0.004213718231767416,
      -0.03596973791718483,
      -0.015959667041897774,
      0.013208605349063873,
      0.04226043075323105,
      -0.02944587916135788,
      0.03382037207484245,
      0.02937907725572586,
      0.04258011654019356,
      0.006926918402314186,
      -0.018469832837581635,
      0.0009881188161671162,
      -0.004002633970230818,
      0.009347788989543915,
      -0.06605571508407593,
      -0.028971096500754356,
      0.051157210022211075,
      -0.013791690580546856,
      -0.011944112367928028,
      -0.0818910226225853,
      -0.0028240717947483063,
      0.04938103258609772,
      -0.05772387236356735,
      0.04304615408182144,
      -0.018296314403414726,
      0.027585959061980247,
      -0.08441384881734848,
      0.07931642979383469,
      -0.07499169558286667,
      0.002758959773927927,
      0.001328946789726615,
      0.022645682096481323,
      -0.1205153539776802,
      -0.047798559069633484,
      -0.037096209824085236,
      0.01700708270072937,
      0.06462132185697556,
      0.009990527294576168,
      -0.030304577201604843,
      -0.022554881870746613,
      -0.03676551207900047,
      0.037076883018016815,
      -0.042050741612911224,
      0.04389495402574539,
      -0.00663210591301322,
      -0.010014163330197334,
      -0.07522328943014145,
      0.06025266274809837,
      -0.0048776427283883095
    ],
    [
      -0.07001437246799469,
      -0.017875147983431816,
      0.0012558442540466785,
      0.009329808875918388,
      0.01983838714659214,
      -0.007749197073280811,
      -0.006737246178090572,
      -0.017903095111250877,
      0.029716696590185165,
      0.050092410296201706,
      -0.03523823246359825,
      -0.062331974506378174,
      0.0058953179977834225,
      -0.01387584488838911,
      -0.07046481221914291,
      0.05277349427342415,
      -0.009999238885939121,
      -0.016652556136250496,
      -0.04069802910089493,
      0.03972423076629639,
      0.046046607196331024,
      0.003712047589942813,
      -0.058027930557727814,
      0.02227325178682804,
      0.007862401194870472,
      -0.043246958404779434,
      -0.031519532203674316,
      0.025994570925831795,
      -0.03896663337945938,
      -0.016217226162552834,
      -0.10351260006427765,
      0.053434498608112335,
      -0.01557806134223938,
      0.033380452543497086,
      -0.05350933223962784,
      0.03500174731016159,
      -0.010786357335746288,
      0.04817195236682892,
      0.018195893615484238,
      0.015015928074717522,
      -0.08979449421167374,
      -0.017563441768288612,
      0.0638720840215683,
      -0.0014651708770543337,
      -0.010033360682427883,
      -0.06458960473537445,
      -0.06718730181455612,
      0.0007696135435253382,
      -0.018476728349924088,
      0.014447721652686596,
      -0.059065449982881546,
      -0.03910259157419205,
      0.054203957319259644,
      -0.011259409599006176,
      -0.030670994892716408,
      -0.014081229455769062,
      -0.09923063218593597,
      0.05361443758010864,
      -0.005265628453344107,
      0.011703215539455414,
      -0.08441029489040375,
      0.022133614867925644,
      -0.009364809840917587,
      -0.03090704418718815,
      -0.04590034857392311,
      0.038327548652887344,
      -0.09102914482355118,
      -0.0060928137972950935,
      -0.028168387711048126,
      0.008984546177089214,
      -0.0054710847325623035,
      0.07566983997821808,
      0.02148817479610443,
      0.07183830440044403,
      -0.01844763569533825,
      0.02055967040359974,
      0.0372028648853302,
      -0.03876114636659622,
      -0.0028967976104468107,
      -0.039932284504175186,
      -0.009960675612092018,
      -0.01759156584739685,
      0.0919373482465744,
      -0.010489513166248798,
      -0.0066273631528019905,
      0.01004593912512064,
      -0.023897206410765648,
      -0.08421404659748077,
      -0.01872767135500908,
      -0.06448663026094437,
      -0.020012468099594116,
      0.031239280477166176,
      -0.027007900178432465,
      0.04072800651192665,
      0.013971129432320595,
      0.03319769725203514,
      0.020752795040607452,
      -0.08385519683361053,
      -0.036500029265880585,
      -0.0014690473908558488,
      -0.006123016122728586,
      0.03038220852613449,
      -0.052963513880968094,
      0.07006878405809402,
      0.06569501757621765,
      -0.006878301035612822,
      -0.03224951773881912,
      -0.0038727722130715847,
      0.01324846688657999,
      0.012484249658882618,
      0.028866656124591827,
      0.055838312953710556,
      0.015868496149778366,
      -0.014638234861195087,
      0.018270526081323624,
      0.05821214243769646,
      0.05122752487659454,
      0.04626363143324852,
      -0.04158681258559227,
      0.04645174369215965,
      0.07382829487323761,
      -0.001140396110713482,
      0.021285785362124443,
      -0.005990170408040285,
      -0.08297545462846756,
      -0.03001800924539566,
      -0.04234931245446205,
      0.06548069417476654,
      0.004398793447762728,
      0.007299324031919241,
      -0.005506175570189953,
      0.023128069937229156,
      0.001604517106898129,
      -0.03386441618204117,
      -0.007005077786743641,
      0.022103948518633842,
      -0.053838539868593216,
      0.09613252431154251,
      -0.08049257099628448,
      0.04938887804746628,
      0.009146845899522305,
      -0.07318196445703506,
      -0.05757376179099083,
      -0.009733090177178383,
      0.016528956592082977,
      0.011329791508615017,
      -0.028100499883294106,
      0.033108774572610855,
      0.050238970667123795,
      0.0704558715224266,
      0.00926235318183899,
      -8.964884909801185e-05,
      0.06499892473220825,
      0.0022482790518552065,
      0.06504666805267334,
      -0.01164466142654419,
      -0.0017497580265626311,
      -0.004846722353249788,
      -0.0033122505992650986,
      -0.026711395010352135,
      -0.06004561111330986,
      0.03154963254928589,
      0.07662514597177505,
      0.006831314414739609,
      -0.016685428097844124,
      0.046771612018346786,
      -0.10661699622869492,
      -0.006634619552642107,
      0.016674721613526344,
      -0.027120115235447884,
      -0.014096061699092388,
      0.008012676611542702,
      -0.015022305771708488,
      0.011658516712486744,
      -0.06631147116422653,
      -0.02446174994111061,
      0.05585244670510292,
      -0.047775790095329285,
      -0.08178949356079102,
      0.007303958293050528,
      -0.008197017945349216,
      0.07004573196172714,
      0.0012189469998702407,
      -0.016291696578264236,
      -0.0042841313406825066,
      0.04941322281956673,
      0.04083047807216644,
      0.06415434926748276,
      -0.0003194532182533294,
      0.029484175145626068,
      -0.020509222522377968,
      -0.018758093938231468,
      0.028706640005111694,
      0.04338502138853073,
      -0.03187182545661926,
      0.030462251976132393,
      0.015100515447556973,
      -0.0638287290930748,
      0.023086512461304665,
      -0.06009139120578766,
      0.02313769981265068,
      0.009807958267629147,
      0.019302092492580414,
      -0.005624478682875633,
      -0.00034227469586767256,
      0.009984084405004978,
      0.07023687660694122,
      -0.059831053018569946,
      -0.010090873576700687,
      0.017724134027957916,
      -0.047694187611341476,
      -0.007902830839157104,
      0.039649490267038345,
      0.014087358489632607,
      0.023908864706754684,
      0.03125204145908356,
      -0.006546199321746826,
      -0.029414203017950058,
      -0.04026666283607483,
      0.011934099718928337,
      0.03948861360549927,
      -0.023860882967710495,
      0.013201422058045864,
      -0.041638750582933426,
      0.04164036363363266,
      0.07614787667989731,
      -0.05096299201250076,
      -0.01017577014863491,
      -0.04272473603487015,
      -0.02423039637506008,
      0.050790220499038696,
      -0.030947642400860786,
      -0.018639065325260162,
      -0.007652048021554947,
      0.04213300719857216,
      0.014912134036421776,
      -0.09388761222362518,
      -0.017910439521074295,
      -0.02066725306212902,
      -0.002505761804059148,
      0.04366039112210274,
      -0.0010520946234464645,
      0.013450577855110168,
      0.03527870774269104,
      -0.012773148715496063,
      -0.016857104375958443,
      0.04784088954329491,
      -0.0014328139368444681,
      0.06577465683221817,
      0.019672060385346413,
      0.038160763680934906,
      -0.04330917447805405,
      0.033677052706480026,
      0.008355910889804363,
      -0.020488621667027473,
      -0.008662287145853043,
      -0.020315926522016525,
      -0.020021552219986916,
      0.034896522760391235,
      -0.003878978779539466,
      0.0367339625954628,
      0.002744552679359913,
      0.017757249996066093,
      -0.06973925232887268,
      0.020431233569979668,
      -0.029212765395641327,
      -0.031150778755545616,
      0.01046584639698267,
      -0.019898846745491028,
      0.03262932971119881,
      -0.07168686389923096,
      0.074379563331604,
      -0.009106471203267574,
      -0.01309217233210802,
      -0.015419607050716877,
      0.11586717516183853,
      -0.10418785363435745,
      0.0934959426522255,
      -0.0040577189065515995,
      -0.043444134294986725,
      0.04037898778915405,
      0.022208480164408684,
      -0.0358540415763855,
      0.032141655683517456,
      -0.021567022427916527,
      -0.0018269019201397896,
      -0.020857080817222595,
      0.012678229250013828,
      0.007465901784598827,
      -0.06139816716313362,
      0.016923261806368828,
      -0.04185172915458679,
      0.03338070586323738,
      -0.03217395767569542,
      -0.002356251236051321,
      0.009071596898138523,
      0.07438042759895325,
      -0.019730228930711746,
      -0.054055895656347275,
      -0.036783210933208466,
      0.014369488693773746,
      -0.019252337515354156,
      0.046701159328222275,
      -0.009632580913603306,
      -0.04200522601604462,
      -0.009389004670083523,
      0.014326262287795544,
      -0.03917515650391579,
      0.018177418038249016,
      -0.06382811814546585,
      0.07068874686956406,
      0.06305193901062012,
      0.030366621911525726,
      0.0020297979936003685,
      -0.027047760784626007,
      0.02076217345893383,
      0.015254721976816654,
      -0.014743948355317116,
      -0.02644730731844902,
      -0.048771925270557404,
      -0.033871643245220184,
      -0.048403795808553696,
      0.016650613397359848,
      -0.014462593011558056,
      -0.05334072560071945,
      -0.0009361011325381696,
      -0.01605132967233658,
      0.0703396424651146,
      0.056721094995737076,
      -0.021777452901005745,
      0.0012925381306558847,
      -0.0001591488835401833,
      -0.06391020119190216,
      0.01367957517504692,
      -0.09696895629167557,
      -0.011510448530316353,
      0.03579576313495636,
      0.0423964262008667,
      -0.019662287086248398,
      -0.0375264436006546,
      -0.0038325772620737553,
      -0.02350304089486599,
      0.04850267246365547,
      0.011879646219313145,
      0.06319323927164078,
      -0.0050168028101325035,
      0.09993555396795273,
      -0.04135822877287865,
      0.00194312515668571,
      0.0037921962793916464,
      -0.040925104171037674,
      -0.03110905922949314,
      0.019677968695759773,
      0.09032084792852402,
      0.04297097772359848,
      0.008896450512111187,
      0.04046448692679405,
      0.039935216307640076,
      0.008724678307771683,
      -0.03920286148786545,
      0.016735633835196495,
      0.01723863184452057,
      0.03298845887184143,
      -0.023381926119327545,
      -0.025232648476958275,
      0.03614448755979538,
      0.02652115374803543,
      -0.016929350793361664,
      -0.003543707774952054,
      0.010329659096896648,
      -0.04995017871260643,
      0.018980342894792557,
      -0.018836596980690956,
      0.015111680142581463,
      -0.07228826731443405,
      0.06398705393075943,
      0.0794026255607605,
      -0.03184658661484718,
      0.006908182520419359,
      -0.07724873721599579,
      -0.06221446767449379,
      -0.078936368227005,
      0.07307171076536179,
      -0.069002166390419,
      0.04982087388634682,
      0.05017419904470444,
      0.02248198352754116,
      0.03662789613008499,
      -0.002004198031499982,
      -0.0008015998755581677,
      -0.02868223376572132,
      0.030120408162474632,
      -0.0502043217420578,
      -0.007393441163003445,
      -0.010879019275307655,
      0.021101973950862885,
      -0.0925508439540863,
      0.019855566322803497,
      0.09086861461400986,
      -0.004764149896800518,
      -0.0002424582198727876,
      0.006246581207960844,
      -0.013269671238958836,
      0.07330730557441711,
      -0.09433900564908981,
      -0.08174493908882141,
      -0.07251131534576416,
      0.023187005892395973,
      0.03235290199518204,
      0.013079984113574028,
      0.007605242542922497,
      0.07655814290046692,
      -0.01740388572216034,
      -0.04172762855887413,
      0.04004640504717827,
      0.04638228565454483,
      -0.021812478080391884,
      -0.014162578620016575,
      -0.02106975018978119,
      -0.11600276827812195,
      -0.0005872652400285006,
      -0.11488407850265503,
      -0.011271062307059765,
      0.01790594309568405,
      0.04212838038802147,
      0.027908263728022575,
      -0.09341374039649963,
      0.007671822793781757,
      -0.063921719789505,
      -0.03364550694823265,
      0.024155016988515854,
      -0.05706973001360893,
      0.046539049595594406,
      -0.018529105931520462,
      0.05118241906166077,
      0.0015734720509499311,
      -0.048656802624464035,
      0.08414436876773834,
      0.019652049988508224,
      0.07785116136074066,
      0.06878785789012909,
      -0.021340202540159225,
      -0.06555851548910141,
      -0.041981231421232224,
      -0.003340501571074128,
      0.0031983356457203627,
      0.08245237171649933,
      0.049149539321660995,
      0.022634638473391533,
      -0.06511174142360687,
      0.06477179378271103,
      -0.0016553207533434033,
      -0.11157771944999695,
      0.08130362629890442,
      -0.004846819210797548,
      -0.06015373021364212,
      0.0460612028837204,
      -0.003964627161622047,
      -0.027380259707570076,
      -0.0884912982583046,
      0.012840170413255692,
      -0.06663624197244644,
      0.07012687623500824,
      0.014218626543879509,
      0.0324224978685379,
      -0.003843780606985092,
      -0.0218929722905159,
      -0.04727901145815849,
      -0.041457124054431915,
      -0.035324905067682266,
      0.04662808030843735,
      0.042522430419921875,
      0.029157236218452454,
      0.0019917874597012997,
      0.02001582272350788,
      0.005594114772975445,
      -0.05366913974285126,
      -0.020231924951076508,
      0.026261307299137115,
      -0.0023578149266541004,
      0.0603758729994297,
      0.0025155851617455482,
      0.05412352457642555,
      -0.04132669046521187,
      0.011864220723509789,
      0.037076909095048904,
      -0.06738826632499695,
      -0.021935053169727325,
      -0.0948626771569252,
      -0.011265789158642292,
      0.15059581398963928,
      -0.0300295427441597,
      0.05007315054535866,
      -0.015446878038346767,
      0.019113721325993538,
      -0.050156328827142715,
      -0.018107334151864052,
      0.03231678530573845,
      0.00351630593650043,
      -0.006772396620362997,
      -0.024895669892430305,
      -0.05443311110138893,
      0.009255453944206238
    ],
    [
      -0.10721909254789352,
      0.02072049304842949,
      0.014044513925909996,
      -0.09400861710309982,
      0.0168861523270607,
      0.037917494773864746,
      -0.06561023741960526,
      -0.025470083579421043,
      0.03472920507192612,
      0.08488786220550537,
      0.020055348053574562,
      0.042313043028116226,
      0.001586866332218051,
      -0.046384233981370926,
      0.005495934747159481,
      -0.001823043916374445,
      0.038999442011117935,
      -0.005467571783810854,
      -0.005026218481361866,
      -0.008859542198479176,
      0.04099976271390915,
      0.04158932343125343,
      0.05232209339737892,
      0.08623529970645905,
      -0.007314121816307306,
      -0.04829845577478409,
      0.016971366479992867,
      0.05438608676195145,
      0.12258565425872803,
      0.012465058825910091,
      -0.07937345653772354,
      0.06538604199886322,
      0.14697451889514923,
      -0.0593314953148365,
      0.023461652919650078,
      -0.012729957699775696,
      0.055827245116233826,
      -0.029483221471309662,
      -0.0015342507977038622,
      -0.007115569431334734,
      0.01666792668402195,
      0.022171800956130028,
      -0.0816011056303978,
      0.03892968222498894,
      0.08290798217058182,
      -0.05329369008541107,
      -0.07777813076972961,
      0.012508736923336983,
      0.0493946336209774,
      0.025451425462961197,
      0.01925547607243061,
      -0.01637949049472809,
      -0.03123285248875618,
      -0.02555633895099163,
      0.05503443628549576,
      0.009273969568312168,
      -0.052425820380449295,
      -0.013388864696025848,
      -0.07773957401514053,
      -0.046542346477508545,
      0.024556702002882957,
      -0.015234921127557755,
      0.0681576281785965,
      0.053763497620821,
      0.05242156982421875,
      0.023284927010536194,
      0.02340753562748432,
      0.005093403626233339,
      -0.02953006513416767,
      -0.026287011802196503,
      -0.0027684203814715147,
      0.04206380993127823,
      -0.01881411112844944,
      0.00033347003045491874,
      0.08513247966766357,
      0.0681394562125206,
      0.016443541273474693,
      -0.037579622119665146,
      0.00319695845246315,
      -0.026884499937295914,
      0.005368195008486509,
      0.01147899217903614,
      0.0342174768447876,
      0.017323516309261322,
      -0.0017127443570643663,
      -0.0319405272603035,
      0.0805954784154892,
      -0.02831210568547249,
      0.011202079243957996,
      -0.0474078394472599,
      0.03446235507726669,
      -0.035598110407590866,
      0.07489944249391556,
      -0.07013069093227386,
      -0.048907697200775146,
      0.05863044783473015,
      -0.0984473004937172,
      -0.03999445587396622,
      -0.07753452658653259,
      -0.028337866067886353,
      0.037165913730859756,
      0.078356072306633,
      -0.0890641063451767,
      0.0067705255933105946,
      0.09949436783790588,
      0.04047240689396858,
      -0.042001448571681976,
      0.044153664261102676,
      -0.013106828555464745,
      0.04564846679568291,
      -0.11881065368652344,
      0.097828209400177,
      -0.04339418187737465,
      0.006324633490294218,
      -0.00782778114080429,
      0.0022196632344275713,
      0.023776380345225334,
      0.0818500816822052,
      0.06794516742229462,
      -0.036308471113443375,
      -0.00625557079911232,
      -0.021937793120741844,
      0.019126271829009056,
      -0.00549378152936697,
      -0.014443404041230679,
      -0.04212764650583267,
      0.02695397287607193,
      0.04996929317712784,
      -0.06533503532409668,
      -0.055758342146873474,
      0.05874531716108322,
      -0.09306848049163818,
      -0.02718946896493435,
      0.07392454147338867,
      -0.021988945081830025,
      0.012396562844514847,
      0.05220196396112442,
      -0.06238510087132454,
      0.017148448154330254,
      -0.010027986019849777,
      -0.055356401950120926,
      -0.01102781668305397,
      0.02470816858112812,
      0.03924648091197014,
      0.10932788997888565,
      -0.05702584236860275,
      -0.07435382157564163,
      0.014244601130485535,
      -0.03535496070981026,
      0.0013897940516471863,
      0.0749383196234703,
      0.12961898744106293,
      0.013577787205576897,
      0.04861104488372803,
      -0.014424250461161137,
      -0.08280232548713684,
      0.02684103138744831,
      -0.03404726833105087,
      0.00016758656420279294,
      -0.10686149448156357,
      0.07369331270456314,
      -0.04694321006536484,
      0.04006471484899521,
      -0.0006157997413538396,
      -0.07670862227678299,
      0.016159962862730026,
      0.008505657315254211,
      0.02276671677827835,
      0.03424462676048279,
      0.026729846373200417,
      -0.01812906004488468,
      0.012385120615363121,
      0.026524320244789124,
      0.028762366622686386,
      -0.00839750561863184,
      0.01858408749103546,
      -0.11858624964952469,
      -0.02248501218855381,
      -0.01783970557153225,
      0.041871026158332825,
      0.0784909725189209,
      0.0005719464388675988,
      0.043702930212020874,
      0.053990937769412994,
      -0.011719269677996635,
      -0.004163976293057203,
      0.057261884212493896,
      -0.029895272105932236,
      -0.04725870490074158,
      0.060621477663517,
      -0.04373203590512276,
      0.06978200376033783,
      0.10679090023040771,
      0.05117715895175934,
      -0.04367868974804878,
      0.0910676121711731,
      0.03833165392279625,
      -0.027750693261623383,
      0.0861685574054718,
      -0.008301625959575176,
      0.017072085291147232,
      -0.09967438876628876,
      0.11689617484807968,
      0.0629354938864708,
      0.031220488250255585,
      -0.0017230045050382614,
      -0.0536109134554863,
      -0.006896610371768475,
      0.03778471425175667,
      -0.02947958931326866,
      0.07632716000080109,
      -0.00980364065617323,
      -0.011372484266757965,
      0.062364526093006134,
      -0.013485567644238472,
      -0.009573646821081638,
      0.07686807960271835,
      -0.06282005459070206,
      0.006080757826566696,
      0.06154875084757805,
      -0.012655775994062424,
      -0.012955138459801674,
      0.05167878046631813,
      0.0518164336681366,
      -0.030404657125473022,
      0.05866953358054161,
      -0.13837695121765137,
      0.0006185016827657819,
      0.08398322761058807,
      0.005781115498393774,
      -0.0337265282869339,
      -0.07674799114465714,
      -0.015160290524363518,
      -0.02301735244691372,
      0.036748699843883514,
      0.010223369114100933,
      0.04743015021085739,
      0.017026232555508614,
      0.0023992792703211308,
      -0.08626674115657806,
      0.08077564835548401,
      0.033248189836740494,
      -0.01919422671198845,
      0.03319234400987625,
      0.00024110036611091346,
      -0.06152662634849548,
      -0.0003366196760907769,
      0.06023870036005974,
      0.02836504392325878,
      -0.00913884025067091,
      0.005031186155974865,
      -0.005780423525720835,
      0.004330367315560579,
      -0.03966450318694115,
      -0.12088321149349213,
      -0.035490699112415314,
      0.01922682486474514,
      0.008398585021495819,
      0.049692049622535706,
      -0.014849131926894188,
      -0.004278098698705435,
      0.01008595246821642,
      -0.023945627734065056,
      -0.03714269772171974,
      -0.00610260060057044,
      -0.05367052182555199,
      -0.021162644028663635,
      -0.006433140952140093,
      0.027632396668195724,
      -0.0480300709605217,
      -0.0021177444141358137,
      0.00735860550776124,
      -0.014451772905886173,
      -0.035463444888591766,
      -0.04833304136991501,
      -0.01744096726179123,
      -0.026787107810378075,
      -0.01032903604209423,
      -0.06980397552251816,
      0.046438369899988174,
      -0.010766099207103252,
      -0.0925101712346077,
      -0.036614060401916504,
      0.08770431578159332,
      -0.009467411786317825,
      -0.007016845513135195,
      -0.005315662361681461,
      0.01196986623108387,
      -0.06417424976825714,
      0.024856606498360634,
      0.10094019770622253,
      -0.04407176747918129,
      -0.040947325527668,
      0.038017015904188156,
      0.041015900671482086,
      -0.028078658506274223,
      -0.010509621351957321,
      -0.06109137833118439,
      -0.019477123394608498,
      -0.06554463505744934,
      0.10863623023033142,
      -0.06332476437091827,
      -0.05427827313542366,
      0.006521810777485371,
      -0.03923752158880234,
      -0.002860802225768566,
      -0.0011211097007617354,
      -0.06252004206180573,
      0.02636893093585968,
      -0.01300873328000307,
      -0.03952765092253685,
      -0.035848286002874374,
      -0.03839607909321785,
      0.016428425908088684,
      0.0190773606300354,
      -0.032616592943668365,
      0.07396950572729111,
      0.0227433443069458,
      -0.006229132413864136,
      0.02355494163930416,
      -0.014728620648384094,
      -0.007329929154366255,
      -0.005032896995544434,
      0.019989697262644768,
      0.07571036368608475,
      0.07557976990938187,
      -0.006233167368918657,
      -0.021264974027872086,
      0.007732011377811432,
      0.03703092038631439,
      -0.05844944342970848,
      -0.025807250291109085,
      0.05321132391691208,
      0.024638880044221878,
      0.002955495845526457,
      0.01574201136827469,
      0.004817686043679714,
      0.04887894541025162,
      -0.055009499192237854,
      0.050821758806705475,
      0.0354270413517952,
      -0.018246270716190338,
      0.027305733412504196,
      -0.037263594567775726,
      -0.0025908390525728464,
      0.033799536526203156,
      -0.059132035821676254,
      0.04981464520096779,
      0.061545729637145996,
      0.060028787702322006,
      0.007730855140835047,
      0.007940300740301609,
      0.04105135425925255,
      -0.09521984308958054,
      0.020018549636006355,
      0.05736497417092323,
      0.06973284482955933,
      0.0013771553058177233,
      -0.0382298119366169,
      -0.03049921989440918,
      0.024460848420858383,
      -0.01872246153652668,
      0.031657326966524124,
      0.042459480464458466,
      0.017598727717995644,
      -0.03135709837079048,
      -0.018170516937971115,
      0.0562446229159832,
      -0.06192564219236374,
      0.041154876351356506,
      0.06364436447620392,
      0.00013805449998471886,
      0.012485239654779434,
      0.03821975737810135,
      -0.009372236207127571,
      -0.030659709125757217,
      0.022302422672510147,
      0.03404807671904564,
      0.05531458929181099,
      -0.046267591416835785,
      0.014855903573334217,
      -0.007016728166490793,
      -0.04402753338217735,
      -0.06640799343585968,
      0.05028131976723671,
      0.002729075262323022,
      -0.04121887683868408,
      -0.05798313394188881,
      0.0392259918153286,
      -0.007130319252610207,
      0.02045358344912529,
      -0.004006573464721441,
      -0.010663619264960289,
      0.0007869572727940977,
      -0.012868271209299564,
      -0.06471115350723267,
      -0.04864657670259476,
      -0.013669891282916069,
      0.1087675467133522,
      -0.06611382216215134,
      -0.02683623693883419,
      0.007070960011333227,
      -0.04856215789914131,
      -0.021045712754130363,
      0.012500837445259094,
      0.06776943057775497,
      -0.05250602960586548,
      0.002968186279758811,
      -0.010235211811959743,
      0.03523586690425873,
      -0.015152222476899624,
      0.02273559384047985,
      0.010903935879468918,
      -0.09869393706321716,
      -0.11316509544849396,
      -0.05884713679552078,
      0.008601593784987926,
      -0.03429360315203667,
      0.08783010393381119,
      0.013973203487694263,
      0.02419540286064148,
      -0.02716841548681259,
      -0.0523538663983345,
      -0.038570307195186615,
      -0.06668967008590698,
      0.036138344556093216,
      0.011135656386613846,
      0.05484911426901817,
      -0.01304683182388544,
      0.009814304299652576,
      0.009653381071984768,
      0.032684992998838425,
      -0.009350237436592579,
      -0.0316208079457283,
      0.026380926370620728,
      -0.013861571438610554,
      -0.019257422536611557,
      0.03668128699064255,
      -0.05864075571298599,
      -0.03870267793536186,
      0.018441399559378624,
      0.059329502284526825,
      0.021693283692002296,
      -0.07677512615919113,
      -0.094533771276474,
      -0.006837867200374603,
      0.02881341055035591,
      0.0010338083375245333,
      -0.0006663840031251311,
      0.06911085546016693,
      -0.004334560129791498,
      -0.007683240808546543,
      -0.08485274761915207,
      0.02099836803972721,
      -0.033241722732782364,
      -0.0766686350107193,
      -0.01963088847696781,
      0.08000331372022629,
      0.0022144801914691925,
      -0.03973829373717308,
      -0.0019161993404850364,
      -0.020843036472797394,
      0.007319472264498472,
      -0.021413816139101982,
      0.0141112906858325,
      -0.07516960054636002,
      -0.02674805372953415,
      0.0744105726480484,
      -0.051786646246910095,
      0.04913381114602089,
      0.03116944432258606,
      -0.03634568303823471,
      -0.0653189942240715,
      -0.023165639489889145,
      0.07287979871034622,
      -0.03508161008358002,
      -0.07095784693956375,
      0.0020031514577567577,
      0.02674126997590065,
      0.020523658022284508,
      -0.07323133200407028,
      -0.02099260874092579,
      0.04658155143260956,
      -0.023208025842905045,
      -0.0007337697315961123,
      -0.07814261317253113,
      -0.05173912271857262,
      -0.02896132320165634,
      -0.019868353381752968,
      -0.04347742348909378,
      0.024248899891972542,
      -0.07084528356790543,
      0.028916846960783005,
      -0.008376377634704113,
      0.03408755734562874,
      0.0860106498003006,
      0.04663974791765213,
      -0.032873645424842834,
      -0.03363105282187462,
      0.02872835285961628,
      0.0033941175788640976,
      -0.07210811972618103,
      -0.005458544474095106
    ],
    [
      -0.018034765496850014,
      -0.012782612815499306,
      -0.07022111862897873,
      -0.014994148164987564,
      -0.004127384629100561,
      0.060923803597688675,
      0.007740110158920288,
      0.013210850767791271,
      0.02821912057697773,
      0.06729277968406677,
      0.044820092618465424,
      0.05276453495025635,
      0.03294115886092186,
      -0.05416741222143173,
      0.10283292084932327,
      -0.03582525625824928,
      0.0610341839492321,
      -0.0009696813649497926,
      -0.0014450503513216972,
      0.05574334040284157,
      -0.0014057436492294073,
      0.0095993522554636,
      0.04208383336663246,
      -0.010704271495342255,
      -0.022308776155114174,
      -0.038478050380945206,
      0.048952750861644745,
      -0.0635741800069809,
      -0.07569367438554764,
      0.013892208226025105,
      -0.1229575052857399,
      0.012191676534712315,
      0.052364010363817215,
      -0.053390637040138245,
      -0.03968658670783043,
      0.04133011773228645,
      -0.06892148405313492,
      -0.07699483633041382,
      -0.05754648521542549,
      -0.04727042838931084,
      -0.016827689483761787,
      0.012214833870530128,
      -0.016974622383713722,
      -0.0025814042892307043,
      -0.07095535099506378,
      -0.005369710270315409,
      0.024043716490268707,
      -0.0140238581225276,
      0.023333285003900528,
      0.01931653916835785,
      0.06056084856390953,
      -0.04068168252706528,
      -0.0348898284137249,
      -0.006165762897580862,
      0.041735175997018814,
      -0.05329218879342079,
      -0.04529423639178276,
      0.009905457496643066,
      -0.023526206612586975,
      -0.030431002378463745,
      0.04968539625406265,
      0.06065232679247856,
      0.022498253732919693,
      -0.04149509221315384,
      0.0160977765917778,
      -0.038013163954019547,
      0.03679610788822174,
      -0.013845326378941536,
      0.026515139266848564,
      0.017760807648301125,
      -0.03477044776082039,
      0.03992736339569092,
      -0.04936258867383003,
      -0.007484355941414833,
      0.007907661609351635,
      0.07789061963558197,
      0.04026166722178459,
      -0.014044919051229954,
      -0.012281191535294056,
      -0.03386272117495537,
      0.01997021585702896,
      0.006836300250142813,
      -0.01761372946202755,
      0.058183565735816956,
      -0.04060292989015579,
      0.060640983283519745,
      0.0002968344197142869,
      0.013684727251529694,
      -0.023785002529621124,
      -0.09953758120536804,
      0.022441331297159195,
      -0.056094687432050705,
      0.04358353093266487,
      -0.024115821346640587,
      0.01894165761768818,
      -0.020063720643520355,
      0.009816328063607216,
      -0.08392712473869324,
      -0.05634690821170807,
      -0.01914093643426895,
      -0.023465216159820557,
      0.04869075119495392,
      -0.03187727555632591,
      0.0028755299281328917,
      0.11616408824920654,
      -0.0055060796439647675,
      0.034668393433094025,
      -0.052075888961553574,
      -0.03049090877175331,
      0.04137749224901199,
      -0.008519704453647137,
      0.051193539053201675,
      -0.018200982362031937,
      0.02139429561793804,
      -0.06914845108985901,
      -0.06124067306518555,
      -0.008992585353553295,
      -0.08702899515628815,
      0.007985967211425304,
      0.08113687485456467,
      0.03628955036401749,
      0.002523820148780942,
      0.019768690690398216,
      -0.06167270243167877,
      0.012122015468776226,
      0.04594431445002556,
      0.04383362829685211,
      0.026680227369070053,
      -0.02596914768218994,
      -0.09807120263576508,
      0.010172327049076557,
      0.03714640811085701,
      -0.035959575325250626,
      -0.015031738206744194,
      -0.026192130520939827,
      0.026037419214844704,
      0.01616247184574604,
      -0.07883316278457642,
      -0.002146734856069088,
      0.01803513802587986,
      0.04898499324917793,
      0.019581545144319534,
      -0.00915141124278307,
      0.02093070186674595,
      0.027291035279631615,
      0.03700590133666992,
      -0.09480641037225723,
      -0.0013481565983965993,
      0.04872621223330498,
      0.05837205424904823,
      0.018931714817881584,
      -0.003410994540899992,
      -0.10990820825099945,
      -0.08447374403476715,
      0.04509144648909569,
      0.02245170623064041,
      0.004381548147648573,
      -0.0462074913084507,
      -0.06742988526821136,
      -0.02257763408124447,
      0.03145845979452133,
      -0.014177260920405388,
      -0.003912939224392176,
      -0.009767376817762852,
      -0.007005949970334768,
      -0.02057804726064205,
      1.269027325179195e-05,
      -0.007642884738743305,
      0.013433609157800674,
      -0.10948801040649414,
      0.059361983090639114,
      -0.005173457786440849,
      0.016856810078024864,
      -0.027049295604228973,
      -0.0038159433752298355,
      0.04640340432524681,
      -0.03467082977294922,
      0.020974209532141685,
      0.03610831871628761,
      -0.021404655650258064,
      -0.01696089841425419,
      0.054586783051490784,
      0.04404854029417038,
      -0.0022257620003074408,
      0.004552514757961035,
      0.02001623436808586,
      -0.010834910906851292,
      0.08450109511613846,
      0.007989631965756416,
      0.004135348368436098,
      0.004804730415344238,
      0.01917816512286663,
      -0.054396361112594604,
      0.007476138416677713,
      0.11738013476133347,
      0.03356777876615524,
      -0.035827670246362686,
      0.04046039655804634,
      -0.02526366338133812,
      -0.08590202778577805,
      -0.025097699835896492,
      -0.01862766221165657,
      -0.030066298320889473,
      0.011577291414141655,
      0.034396663308143616,
      -0.004794893320649862,
      -0.006320202257484198,
      -0.038179636001586914,
      -0.043554823845624924,
      -0.02673184685409069,
      -0.0341382771730423,
      0.03181343153119087,
      0.10411437600851059,
      -0.027093635872006416,
      0.067226342856884,
      -0.06154509261250496,
      0.00967715959995985,
      -0.018013913184404373,
      0.020392945036292076,
      -0.04057658091187477,
      -0.0743224173784256,
      0.010688838548958302,
      0.010835127905011177,
      0.03868933767080307,
      -0.004411539528518915,
      -0.03688928857445717,
      -0.02271553874015808,
      0.05030401051044464,
      0.008822626434266567,
      0.01147480122745037,
      0.08932807296514511,
      -0.03799844905734062,
      0.04289225861430168,
      0.0535941943526268,
      0.0152413509786129,
      0.005028491839766502,
      -0.00032952730543911457,
      0.030233072116971016,
      0.007072807289659977,
      -0.10046903043985367,
      -0.05632206052541733,
      0.018989253789186478,
      0.023030119016766548,
      0.010637928731739521,
      -0.00806443765759468,
      0.0027909190393984318,
      -0.013817803002893925,
      0.013255092315375805,
      0.025409961119294167,
      -0.023110050708055496,
      0.061448000371456146,
      0.0665203258395195,
      -0.012289467267692089,
      0.06565891206264496,
      -0.07590935379266739,
      0.027304476127028465,
      0.034435514360666275,
      0.08795121312141418,
      0.025272216647863388,
      -0.04447360709309578,
      -0.049942877143621445,
      0.041525330394506454,
      0.08619190752506256,
      0.005883362609893084,
      -0.008803557604551315,
      -0.001046977937221527,
      0.05852945148944855,
      0.0025845863856375217,
      0.009393309243023396,
      0.003237188095226884,
      -0.030405757948756218,
      0.050702035427093506,
      -0.019522085785865784,
      0.0007006170926615596,
      0.07228970527648926,
      -0.007718213833868504,
      0.03846709802746773,
      0.07318196445703506,
      0.006652353331446648,
      -0.07886657863855362,
      -0.09467347711324692,
      0.06168392300605774,
      0.010156719945371151,
      0.021758753806352615,
      -0.05518215522170067,
      -0.07252936065196991,
      -0.017700599506497383,
      -0.023262593895196915,
      -0.04291350394487381,
      0.013783092610538006,
      -0.013538653030991554,
      -0.0584714375436306,
      -0.042484454810619354,
      -0.033282723277807236,
      -0.006750947795808315,
      0.08492232859134674,
      -0.023604746907949448,
      0.06412850320339203,
      0.07746715098619461,
      0.05732393637299538,
      0.06088773161172867,
      -0.0566394105553627,
      -0.005633214954286814,
      0.00311745866201818,
      0.014211318455636501,
      0.03876008465886116,
      0.003051432780921459,
      0.0477464534342289,
      -0.06626931577920914,
      -0.033938366919755936,
      0.0022179135121405125,
      0.0146176191046834,
      0.028192099183797836,
      -0.023460768163204193,
      -0.005871395580470562,
      -0.0635131299495697,
      0.05263126268982887,
      -0.1226615384221077,
      -0.032594162970781326,
      -0.0823238417506218,
      0.002411418128758669,
      -0.026407621800899506,
      0.04254485294222832,
      0.04765930026769638,
      0.07918421179056168,
      0.06550238281488419,
      0.06356964260339737,
      -0.13007061183452606,
      0.024687375873327255,
      -0.04960020259022713,
      -0.019621113315224648,
      -0.006776796653866768,
      0.01384733710438013,
      0.01819748803973198,
      0.0015354985371232033,
      -0.012525366619229317,
      -0.012351649813354015,
      0.09237159043550491,
      -0.046818386763334274,
      -0.02853456325829029,
      -0.004148585721850395,
      -0.06437741219997406,
      -0.032372500747442245,
      0.02153145521879196,
      0.09607095271348953,
      -0.034588031470775604,
      0.058812037110328674,
      0.010517966002225876,
      0.01778535358607769,
      0.007351614534854889,
      -0.10014398396015167,
      -0.004045925568789244,
      0.04587072134017944,
      0.032159097492694855,
      0.011463514529168606,
      0.07707569003105164,
      -0.0585310272872448,
      0.05349373072385788,
      -0.022010337561368942,
      0.03636461868882179,
      0.032989874482154846,
      -0.02501273714005947,
      -0.025095123797655106,
      -0.023429961875081062,
      0.09094814211130142,
      -0.018448781222105026,
      0.0339270755648613,
      0.028550243005156517,
      0.010101066902279854,
      0.021440904587507248,
      0.024214008823037148,
      0.000908563903067261,
      -0.03930184617638588,
      -0.027955008670687675,
      0.004184847231954336,
      -0.017974894493818283,
      -0.01153404638171196,
      0.04975982382893562,
      0.06589477509260178,
      0.027419663965702057,
      0.012597911059856415,
      0.006593510042876005,
      -0.004885063972324133,
      -0.1369583159685135,
      -0.026809627190232277,
      -0.009834522381424904,
      -0.05626330152153969,
      -0.04495326429605484,
      0.07520642876625061,
      0.018875716254115105,
      -0.03613773733377457,
      -0.06238807365298271,
      -0.02473556250333786,
      -0.07231630384922028,
      0.024968346580863,
      -0.02386503480374813,
      -0.06403754651546478,
      -0.14196619391441345,
      0.02753833867609501,
      -0.01949159801006317,
      0.00673512602224946,
      0.007457170635461807,
      -0.028047693893313408,
      0.036204349249601364,
      0.03654635697603226,
      -0.005723720882087946,
      -0.007478265091776848,
      0.022697553038597107,
      0.06928098946809769,
      0.08683256059885025,
      -0.0337485708296299,
      0.04586463421583176,
      -0.04224921390414238,
      -0.04366554319858551,
      0.03086041659116745,
      -0.02559051662683487,
      -0.06278477609157562,
      0.05121787264943123,
      0.021194973960518837,
      -0.00906922947615385,
      0.05262200906872749,
      -0.020569002255797386,
      0.09908413141965866,
      -0.03320445492863655,
      -0.01983894221484661,
      -0.046839792281389236,
      -0.03192289173603058,
      -0.013888977468013763,
      0.08053313195705414,
      -0.03643731027841568,
      -0.13070346415042877,
      0.03628885746002197,
      -0.06368988007307053,
      0.002911433344706893,
      0.012316204607486725,
      -0.0385209359228611,
      -0.009800797328352928,
      0.09653937071561813,
      0.015434603206813335,
      0.04361492767930031,
      -0.04817277193069458,
      -0.0033999888692051172,
      0.03292009234428406,
      -0.022161461412906647,
      0.021944493055343628,
      -0.0007441148045472801,
      0.04854737967252731,
      0.023417741060256958,
      0.07658005505800247,
      0.004773014225065708,
      -0.030061328783631325,
      -0.023149361833930016,
      -0.001548115280456841,
      -0.01157168298959732,
      -0.025733791291713715,
      -0.06056414544582367,
      -0.018201561644673347,
      -0.0028418363071978092,
      -0.041790418326854706,
      -0.0029854699969291687,
      0.013218451291322708,
      -0.028518276289105415,
      -0.015584737993776798,
      -0.04370793327689171,
      -0.007661708630621433,
      0.04768890514969826,
      0.037184957414865494,
      0.010118303820490837,
      -0.029052184894680977,
      0.003913921769708395,
      0.01939164474606514,
      0.02575880102813244,
      -0.02235269919037819,
      0.02050851099193096,
      -0.05774800851941109,
      0.04049181193113327,
      0.007639866787940264,
      0.011071526445448399,
      0.012456173077225685,
      0.05192054435610771,
      -0.10725085437297821,
      0.050139233469963074,
      0.020580006763339043,
      -0.035309094935655594,
      0.07046046108007431,
      -0.08224578201770782,
      -0.08430548012256622,
      -0.056850872933864594,
      0.04251384735107422,
      -0.006234103348106146,
      0.03774029389023781,
      0.019701136276125908,
      0.039076875895261765,
      0.022377094253897667,
      -0.015629542991518974,
      -0.023263517767190933,
      0.00106888881418854,
      -0.0529794879257679,
      -0.0013588203582912683,
      0.016103094443678856,
      -0.06418488919734955,
      -0.006323220208287239,
      -0.03341955691576004
    ],
    [
      -0.061407290399074554,
      -0.0441407784819603,
      -0.06675713509321213,
      -0.0419873371720314,
      -0.02555125579237938,
      -0.006779917981475592,
      -0.0005625021876767278,
      -0.06269308924674988,
      0.09192632883787155,
      -0.015218191780149937,
      0.02230576053261757,
      0.028180647641420364,
      -0.010663659311830997,
      0.035097043961286545,
      -0.005744847469031811,
      0.04496213421225548,
      0.02578144334256649,
      -0.020514801144599915,
      0.048773910850286484,
      -0.04338313266634941,
      0.030853452160954475,
      -0.03934057801961899,
      0.03989157825708389,
      0.01606845296919346,
      -0.061317283660173416,
      -0.017740201205015182,
      -0.04894912242889404,
      0.03591518849134445,
      0.04046355187892914,
      0.01845541223883629,
      0.07062598317861557,
      -0.008227786980569363,
      0.008680266328155994,
      0.027897028252482414,
      0.03302408382296562,
      -0.10698789358139038,
      -0.04239240288734436,
      -0.022730814293026924,
      -0.0161930900067091,
      -0.011588229797780514,
      -0.03889070451259613,
      -0.09053652733564377,
      -0.0003695039195008576,
      0.0487801618874073,
      -0.033958323299884796,
      0.009437129832804203,
      -0.03780867159366608,
      0.013466722331941128,
      -0.04264437034726143,
      -0.013089332729578018,
      0.07199253886938095,
      0.008640829473733902,
      0.04050431773066521,
      0.016703348606824875,
      0.020778397098183632,
      0.009790860116481781,
      -0.01889120601117611,
      -0.06040138378739357,
      0.0021769970189779997,
      -0.030332762748003006,
      0.021188385784626007,
      -0.01630670763552189,
      -0.002779765520244837,
      -0.003915673121809959,
      -0.0325944684445858,
      -0.013176853768527508,
      0.005037797149270773,
      0.0015020117862150073,
      -0.011911542154848576,
      0.048307813704013824,
      -0.04509802162647247,
      -0.012592560611665249,
      0.036664947867393494,
      0.038817159831523895,
      -0.02260844223201275,
      0.05413747578859329,
      -0.01873115450143814,
      -0.056947458535432816,
      0.006559005472809076,
      0.1303015500307083,
      0.00849678460508585,
      -0.03287443146109581,
      0.012720686383545399,
      -0.0036502154543995857,
      0.03186944127082825,
      -0.045024074614048004,
      0.05937732756137848,
      0.09215791523456573,
      0.02745005302131176,
      -0.08388341963291168,
      0.04904814437031746,
      -0.02731221355497837,
      -0.02677088789641857,
      0.06288117915391922,
      0.026330657303333282,
      0.023571550846099854,
      -0.008867282420396805,
      0.035381823778152466,
      0.020980816334486008,
      0.0844707190990448,
      0.05945480987429619,
      -0.023217860609292984,
      -0.07231645286083221,
      -0.013202427886426449,
      -0.013756800442934036,
      0.07035559415817261,
      0.06769825518131256,
      -0.06923661381006241,
      0.0396566167473793,
      0.08157870918512344,
      -0.0048928395844995975,
      0.014002803713083267,
      0.01720493845641613,
      0.024736985564231873,
      -0.043999411165714264,
      -0.05213911831378937,
      -0.044706664979457855,
      -0.024141401052474976,
      0.01668800599873066,
      -0.029345186427235603,
      0.036895591765642166,
      0.030152257531881332,
      -0.11740460246801376,
      -0.04739155247807503,
      -0.10028740763664246,
      0.02974904701113701,
      0.0196961909532547,
      0.047170285135507584,
      -0.004116746596992016,
      0.029255790635943413,
      -0.04456988722085953,
      0.06024699658155441,
      0.005666323471814394,
      0.07583292573690414,
      -0.06576408445835114,
      0.016038618981838226,
      -0.027952734380960464,
      -0.0425482913851738,
      0.011082470417022705,
      0.004383675288408995,
      0.01703019067645073,
      0.03373211249709129,
      -0.002195624401792884,
      -0.015954172238707542,
      -0.029219001531600952,
      0.006689008325338364,
      -0.001441658940166235,
      0.019689738750457764,
      -0.019666707143187523,
      0.03696984052658081,
      0.07225660234689713,
      0.00911744311451912,
      0.12448190897703171,
      0.03519327566027641,
      0.008912188932299614,
      -0.007865340448915958,
      -0.03195967525243759,
      0.042278751730918884,
      -0.036472778767347336,
      0.057389035820961,
      -0.014545336365699768,
      -0.02735857665538788,
      -0.030566098168492317,
      0.006094660144299269,
      -0.06440982222557068,
      -0.07645411044359207,
      -0.034886863082647324,
      -0.03293720632791519,
      -0.0419883206486702,
      -0.003961267415434122,
      0.07700245082378387,
      0.04467179253697395,
      -0.046142082661390305,
      -0.007903213612735271,
      0.003008232219144702,
      -0.006366750691086054,
      0.0982644334435463,
      0.10887500643730164,
      0.00010242718417430297,
      0.03428323194384575,
      0.0861833244562149,
      0.01963641121983528,
      -0.03724146634340286,
      0.025671064853668213,
      0.026657911017537117,
      0.01774512231349945,
      -0.03695537522435188,
      -0.02842068113386631,
      -0.030033418908715248,
      -0.033266812562942505,
      -0.003166033187881112,
      -0.030648723244667053,
      0.008326342329382896,
      0.004654425196349621,
      -0.073445625603199,
      0.011975535191595554,
      0.026071028783917427,
      0.04974157363176346,
      0.01923033408820629,
      0.016605013981461525,
      0.07239515334367752,
      -0.057962559163570404,
      0.05241844803094864,
      -0.018022967502474785,
      0.01657768338918686,
      0.027568764984607697,
      -0.005290928762406111,
      -0.036579400300979614,
      0.07524097710847855,
      -0.020798569545149803,
      0.003514338983222842,
      0.009871229529380798,
      0.024817802011966705,
      -0.013974342495203018,
      0.00037623356911353767,
      -0.029161503538489342,
      0.08003673702478409,
      0.03855375945568085,
      0.026953648775815964,
      -0.04027033969759941,
      0.035445116460323334,
      -0.02879522182047367,
      0.003812602488324046,
      -0.061182912439107895,
      0.034134890884160995,
      -0.07933943718671799,
      -0.026087384670972824,
      -0.059993401169776917,
      -0.022903824225068092,
      -0.06434618681669235,
      0.0210827998816967,
      -0.017581794410943985,
      0.024485792964696884,
      -0.02268240414559841,
      0.039638347923755646,
      -0.0731566995382309,
      0.02280937321484089,
      0.07117415964603424,
      0.06305114179849625,
      -0.007043562829494476,
      -0.033133018761873245,
      0.017674149945378304,
      -0.012886284850537777,
      0.051916513592004776,
      -0.01410755142569542,
      0.004658477380871773,
      -0.04104038327932358,
      0.02432923950254917,
      -0.013205611146986485,
      0.01265581976622343,
      -0.033958178013563156,
      0.04168497771024704,
      0.07104040682315826,
      -0.021074820309877396,
      -0.04986519739031792,
      -0.03018888644874096,
      -0.04835600033402443,
      0.0680193230509758,
      0.013788417913019657,
      0.0024380297400057316,
      -0.017531078308820724,
      0.0833199992775917,
      -0.08308929949998856,
      0.03888518363237381,
      0.042378053069114685,
      -0.07515542954206467,
      -0.007988044060766697,
      -0.04647970572113991,
      0.032980311661958694,
      0.00015648390399292111,
      -0.05201570689678192,
      0.06631796061992645,
      -0.060057759284973145,
      0.0007643051794730127,
      0.0004437115858308971,
      0.04987869784235954,
      -0.019135817885398865,
      -0.0326312817633152,
      0.013040854595601559,
      0.045616500079631805,
      -0.04414919391274452,
      0.06584349274635315,
      0.0006516380817629397,
      0.0061968304216861725,
      -0.006154539994895458,
      -0.050689585506916046,
      -0.01316220872104168,
      -0.006964628119021654,
      -0.006701026111841202,
      0.000909968453925103,
      -0.029849587008357048,
      0.01684550940990448,
      0.00631033256649971,
      -0.047166429460048676,
      -0.02733292616903782,
      -0.10825764387845993,
      0.003166477894410491,
      0.026449253782629967,
      -0.012024293653666973,
      0.003527424531057477,
      -0.0580320730805397,
      0.009219595231115818,
      -0.01602436974644661,
      0.05338168516755104,
      -0.02145664393901825,
      -0.0605713427066803,
      0.03198852390050888,
      0.008252514526247978,
      -0.059000637382268906,
      0.014174766838550568,
      -0.04512752965092659,
      0.05646985396742821,
      -0.004567819647490978,
      -0.014236586168408394,
      -0.05188649892807007,
      0.0123757878318429,
      0.07602286338806152,
      -0.13639627397060394,
      -0.05315287783741951,
      -0.02656145952641964,
      0.04102412983775139,
      -0.017812056466937065,
      0.006583622191101313,
      -0.019751857966184616,
      -0.024220619350671768,
      0.031064171344041824,
      0.004121108911931515,
      -0.04226294904947281,
      -0.00872117280960083,
      -0.0626974105834961,
      0.03729911148548126,
      -0.03879880905151367,
      -0.056315694004297256,
      0.009060400538146496,
      0.02612844854593277,
      0.06644509732723236,
      0.03928866609930992,
      0.07013700902462006,
      0.016944408416748047,
      -0.035805705934762955,
      -0.06461544334888458,
      -0.005526152905076742,
      -0.03942858800292015,
      -0.004957389552146196,
      0.0058946749195456505,
      -0.021730707958340645,
      0.12405340373516083,
      0.13197799026966095,
      0.004505731165409088,
      -0.000498774170409888,
      0.03279891982674599,
      0.115473173558712,
      -0.03162901848554611,
      0.06219210475683212,
      -0.01953808031976223,
      0.0849723070859909,
      -0.016846872866153717,
      0.049793899059295654,
      -0.020269177854061127,
      0.011204903945326805,
      -0.03986144810914993,
      -0.032462041825056076,
      -0.05242333933711052,
      -0.03245808556675911,
      0.02430921047925949,
      0.04198861122131348,
      -0.07537900656461716,
      -0.027508597820997238,
      0.06134265661239624,
      -0.019973445683717728,
      -0.018465187400579453,
      0.004483844619244337,
      0.04219978302717209,
      0.05734715610742569,
      -0.047771234065294266,
      0.025829974561929703,
      0.05313756689429283,
      0.09631553292274475,
      -0.04308955743908882,
      0.03310238569974899,
      -0.05485433340072632,
      -0.02889753133058548,
      0.06006087735295296,
      -0.05471465364098549,
      0.051449254155159,
      0.05445970967411995,
      -0.03679206222295761,
      0.06073152273893356,
      -0.01264961902052164,
      -0.03722791373729706,
      0.10227299481630325,
      -0.032419562339782715,
      0.06341415643692017,
      0.007740925997495651,
      0.049757201224565506,
      -0.04389532655477524,
      -0.01282498799264431,
      -0.08876550197601318,
      0.02708493359386921,
      -0.016009120270609856,
      -0.019418250769376755,
      0.048211220651865005,
      -0.09342844784259796,
      0.03469943627715111,
      0.047086797654628754,
      -0.04132051393389702,
      -0.05474819615483284,
      -0.051154669374227524,
      0.035012416541576385,
      0.008799807168543339,
      -0.03705323114991188,
      -0.004141002427786589,
      -0.0026765000075101852,
      -0.024004971608519554,
      0.008344175294041634,
      -0.00433971406891942,
      0.04094554856419563,
      -0.034155912697315216,
      0.02836359106004238,
      -0.05110061541199684,
      -0.04707181081175804,
      -0.003412368707358837,
      0.01941998489201069,
      -0.01583288237452507,
      0.047827720642089844,
      -0.04181678220629692,
      0.03173700347542763,
      -0.006704237777739763,
      -0.052504394203424454,
      0.01891239546239376,
      -0.008226759731769562,
      -0.1291094571352005,
      0.10915929079055786,
      0.0719805359840393,
      -0.026051990687847137,
      0.029022132977843285,
      0.007472285535186529,
      0.08415380120277405,
      -0.019369307905435562,
      -0.05758538097143173,
      -0.05774129182100296,
      -0.015701787546277046,
      -0.01564868539571762,
      -0.06599849462509155,
      0.09513015300035477,
      -0.02150401845574379,
      -0.06550846248865128,
      -0.037328775972127914,
      -0.013320253230631351,
      -0.015344478189945221,
      -0.009350084699690342,
      -0.09608148783445358,
      0.03381101042032242,
      0.06406892091035843,
      -0.029387442395091057,
      -0.00011378282215446234,
      -0.010215438902378082,
      -0.06862874329090118,
      -0.023069990798830986,
      0.014785334467887878,
      0.019611071795225143,
      -0.04351469501852989,
      -0.024647777900099754,
      0.032588228583335876,
      -0.08106377720832825,
      0.007694092579185963,
      -0.07252199947834015,
      -0.009894369170069695,
      0.021400505676865578,
      0.04374069720506668,
      0.11506110429763794,
      0.09008380025625229,
      0.013072723522782326,
      -0.03288484737277031,
      0.10401895642280579,
      -0.0876111313700676,
      0.09035841375589371,
      -0.030064519494771957,
      -0.024433976039290428,
      0.00705698411911726,
      -0.08786412328481674,
      -0.04459087550640106,
      0.0277046300470829,
      -0.03107268549501896,
      0.051815129816532135,
      -0.03458397462964058,
      0.005023767706006765,
      -0.06309334188699722,
      0.06175950914621353,
      -0.05239974707365036,
      -0.0481133870780468,
      0.017727285623550415,
      -0.014900279231369495,
      0.01052152644842863,
      -0.040128376334905624,
      -0.018079686909914017,
      0.025083282962441444,
      -0.019282175227999687,
      0.022432291880249977,
      -0.030050931498408318,
      -0.048530206084251404,
      0.0032743022311478853,
      0.11848263442516327
    ],
    [
      -0.014779705554246902,
      -0.05523659288883209,
      0.04922168701887131,
      0.014000850729644299,
      0.10757517069578171,
      -0.04212195426225662,
      0.06440404057502747,
      -0.035278528928756714,
      0.058871079236269,
      0.050427284091711044,
      0.06038637459278107,
      -0.041322972625494,
      -0.06042124703526497,
      0.010622672736644745,
      -0.015907354652881622,
      -0.033206094056367874,
      -0.040870532393455505,
      0.04148624464869499,
      -0.056972287595272064,
      -0.04526444524526596,
      -0.02516057714819908,
      0.06781858950853348,
      -0.03039208985865116,
      0.01565956138074398,
      0.06458275020122528,
      -0.02525928243994713,
      -0.004193922504782677,
      -0.05810297653079033,
      -0.025091135874390602,
      0.09368713200092316,
      0.037782635539770126,
      -0.057649414986371994,
      -0.05163470655679703,
      0.0008283330826088786,
      0.0032011906150728464,
      0.12311697751283646,
      0.004965479951351881,
      0.005987036041915417,
      -0.029137495905160904,
      0.009176668711006641,
      0.012792414054274559,
      0.009451094083487988,
      -0.04728720337152481,
      0.04821000248193741,
      0.041455429047346115,
      0.07754473388195038,
      0.019154254347085953,
      -0.016196170821785927,
      0.014189356006681919,
      -0.006194847635924816,
      0.03618427738547325,
      0.025324935093522072,
      -0.014079591259360313,
      -0.02952355146408081,
      0.05415649339556694,
      -0.06877589970827103,
      -0.01377908792346716,
      -0.06940323114395142,
      -0.046119365841150284,
      0.035198964178562164,
      -0.025163881480693817,
      0.052619222551584244,
      -0.0509948767721653,
      -0.016747063025832176,
      -0.07133857905864716,
      -0.03568071871995926,
      0.1093502938747406,
      0.0040919906459748745,
      0.04466557130217552,
      0.07342468947172165,
      -0.046014204621315,
      0.04537046328186989,
      -0.037528958171606064,
      0.02010485716164112,
      0.029299739748239517,
      0.004392792005091906,
      -0.0027594713028520346,
      0.1039774939417839,
      0.0068368990905582905,
      -0.023428192362189293,
      -0.10169629752635956,
      0.03802819922566414,
      -0.0554504357278347,
      0.05339755117893219,
      0.016028154641389847,
      -0.03224044665694237,
      0.0165141299366951,
      0.039896491914987564,
      0.022582655772566795,
      0.03540530055761337,
      -0.05625847354531288,
      -0.016645582392811775,
      0.009099571965634823,
      0.12266851216554642,
      -0.11064831912517548,
      -0.03797009959816933,
      0.015329735353589058,
      -0.016001779586076736,
      0.019602162763476372,
      0.02222604490816593,
      0.038297876715660095,
      0.05189424380660057,
      0.019559532403945923,
      -0.037819817662239075,
      0.023198699578642845,
      0.04322408139705658,
      -0.03176042437553406,
      -0.08291736990213394,
      0.008892604149878025,
      0.012488489970564842,
      -0.09097383916378021,
      0.04364931210875511,
      0.06518727540969849,
      -0.04178423807024956,
      -0.01597798429429531,
      -0.04289727658033371,
      -0.008324660360813141,
      -0.006782489363104105,
      0.09081223607063293,
      -0.04378026723861694,
      0.0388374887406826,
      -0.05068960785865784,
      0.01338498666882515,
      0.02374052256345749,
      0.016704201698303223,
      0.04249437898397446,
      -0.01390750426799059,
      0.010087748989462852,
      -0.05491441488265991,
      -0.03315106779336929,
      -0.0010270889615640044,
      0.027965258806943893,
      0.0028603936079889536,
      0.004951959941536188,
      0.041339024901390076,
      -0.039305996149778366,
      0.08970003575086594,
      0.05282709747552872,
      -0.034296099096536636,
      0.02324632555246353,
      -0.09275944530963898,
      -0.02555369958281517,
      -0.015639053657650948,
      0.005863546393811703,
      0.027326343581080437,
      -0.07824565470218658,
      -0.03984440490603447,
      -0.05687950551509857,
      0.06603681296110153,
      -0.004041675943881273,
      0.01776977628469467,
      0.041591495275497437,
      0.040484629571437836,
      0.04181690514087677,
      0.0823168009519577,
      0.07915350794792175,
      0.03399118408560753,
      0.040219664573669434,
      -0.020542049780488014,
      0.04083675518631935,
      -0.033779267221689224,
      -0.003557046642526984,
      -0.02302807942032814,
      0.03651982918381691,
      0.028604738414287567,
      -0.0006880406872369349,
      0.01303030364215374,
      0.01411565113812685,
      -0.08790213614702225,
      0.033356163650751114,
      -0.029741739854216576,
      0.03189682587981224,
      0.060306232422590256,
      0.012391365133225918,
      0.02878250926733017,
      0.07081381976604462,
      0.06708258390426636,
      -0.017269490286707878,
      -0.07635969668626785,
      0.005808698013424873,
      0.009430758655071259,
      0.10634032636880875,
      -0.017358241602778435,
      0.012165391817688942,
      -0.05895240977406502,
      0.04172614961862564,
      0.042583875358104706,
      0.03770080953836441,
      -0.01687178760766983,
      -0.012521132826805115,
      -0.0565798394382,
      0.04409576207399368,
      0.025303175672888756,
      0.01193682849407196,
      0.021362120285630226,
      0.09797383844852448,
      -0.039729777723550797,
      -0.020128149539232254,
      -0.045412708073854446,
      -0.005544715095311403,
      -0.06947885453701019,
      -0.028437646105885506,
      0.020301174372434616,
      -0.10351438075304031,
      0.030054602771997452,
      -0.020129092037677765,
      0.029144123196601868,
      -0.051367998123168945,
      0.04076031967997551,
      -0.002820681082084775,
      -0.03337790071964264,
      -0.002272129524499178,
      -0.04331691563129425,
      0.005717705935239792,
      -0.05140232294797897,
      -0.06507418304681778,
      0.07085117697715759,
      0.04366736114025116,
      -0.06226066127419472,
      0.012353628873825073,
      -0.0515916608273983,
      0.030348042026162148,
      0.04913570359349251,
      -0.04431754723191261,
      0.012269426137208939,
      -0.05046878382563591,
      -0.079153873026371,
      0.004434950649738312,
      -0.039415642619132996,
      0.0652242973446846,
      0.0449511893093586,
      0.021337153390049934,
      0.0029444401152431965,
      -0.032643817365169525,
      -0.05372854694724083,
      0.031726300716400146,
      0.050569888204336166,
      -0.11099860072135925,
      -0.014332152903079987,
      -0.00807129591703415,
      -0.005386186297982931,
      0.042613327503204346,
      -0.004656856879591942,
      -0.020390132442116737,
      0.009461573325097561,
      -0.015391618013381958,
      -0.0078933946788311,
      0.03906669840216637,
      0.02403382956981659,
      -0.003466289024800062,
      -0.040241532027721405,
      0.08508364111185074,
      -0.09881754219532013,
      0.04478776827454567,
      -0.06193073093891144,
      -0.02444649115204811,
      -0.008154739625751972,
      -0.07365579903125763,
      0.010896247811615467,
      0.042001012712717056,
      -0.09055117517709732,
      -0.04124661162495613,
      -0.04550983011722565,
      -0.03834661468863487,
      -0.005189830437302589,
      -0.006395314820110798,
      0.0053344834595918655,
      -0.036421891301870346,
      -0.06446541845798492,
      -0.06528878957033157,
      -0.035961925983428955,
      0.06516271829605103,
      0.07824930548667908,
      -0.1399974375963211,
      -0.02125224657356739,
      -0.05233963578939438,
      -0.039870113134384155,
      0.051691967993974686,
      -0.04875458776950836,
      -0.05573159083724022,
      0.03796546906232834,
      0.006530895363539457,
      0.018934261053800583,
      -0.06424707919359207,
      -0.03489884361624718,
      0.029110131785273552,
      -0.07466786354780197,
      0.021504797041416168,
      0.01161560881882906,
      0.05592690035700798,
      -0.015400845557451248,
      -0.016111180186271667,
      -0.05188727006316185,
      -0.06932641565799713,
      -0.029421376064419746,
      -0.023460952565073967,
      -0.0475175678730011,
      -0.021669840440154076,
      0.007937808521091938,
      0.03215235099196434,
      -0.0396326407790184,
      -0.042494915425777435,
      -0.036389436572790146,
      -0.058159396052360535,
      -0.028892043977975845,
      0.017855998128652573,
      -0.06445682048797607,
      -3.1634210699849064e-06,
      -0.01879029907286167,
      -0.058028530329465866,
      -0.022407134994864464,
      -0.010188025422394276,
      -0.02015729621052742,
      -0.04635963216423988,
      -0.03207899630069733,
      0.012090552598237991,
      0.01885087974369526,
      -0.012655669823288918,
      -0.029620589688420296,
      0.060933005064725876,
      -0.00813452061265707,
      -0.048936404287815094,
      -0.032293111085891724,
      -0.03953654691576958,
      -0.06894585490226746,
      -0.04568164050579071,
      -0.11512192338705063,
      0.03110886551439762,
      -0.004015569109469652,
      0.06310873478651047,
      0.027550438418984413,
      0.02595800906419754,
      0.02940140664577484,
      0.01722431369125843,
      0.044457562267780304,
      0.048895932734012604,
      -0.03345680236816406,
      0.013438435271382332,
      0.11447052657604218,
      0.07119742780923843,
      -0.08920294046401978,
      -0.010320719331502914,
      0.008310416713356972,
      0.010036421939730644,
      0.02037501521408558,
      0.036998163908720016,
      -0.025083551183342934,
      0.013685078360140324,
      0.0037916824221611023,
      -0.05396313592791557,
      -0.04168133810162544,
      0.006098922807723284,
      -0.05084829777479172,
      -0.0530465729534626,
      0.007219827268272638,
      -0.03433431684970856,
      -0.008405817672610283,
      0.10258221626281738,
      -0.08251488953828812,
      0.008960680104792118,
      -0.008242436684668064,
      -0.02280053123831749,
      0.05219699442386627,
      0.09876175969839096,
      0.0013216837542131543,
      -0.06252738833427429,
      -0.10570267587900162,
      -0.010224311612546444,
      -0.004247703123837709,
      -0.0008525184239260852,
      -0.015366019681096077,
      -0.00047851790441200137,
      0.0031038413289934397,
      0.04229952394962311,
      0.015315813012421131,
      -0.000561971333809197,
      0.09799585491418839,
      0.08183518052101135,
      0.029259614646434784,
      -0.04176964610815048,
      -0.012569693848490715,
      0.020826585590839386,
      0.01466012466698885,
      -0.00579438591375947,
      -0.014795380644500256,
      0.03344622254371643,
      0.008431589230895042,
      0.07079921662807465,
      0.057016000151634216,
      0.08987881988286972,
      -0.013465827330946922,
      0.11896109580993652,
      0.02899783104658127,
      -0.03655771166086197,
      -0.05653359740972519,
      -0.03956111893057823,
      -0.061786994338035583,
      0.036272577941417694,
      -0.004836851730942726,
      -0.03665928170084953,
      -0.012149587273597717,
      -0.04300053417682648,
      0.06728953123092651,
      -0.04565037786960602,
      0.13502490520477295,
      -0.017949698492884636,
      -0.003362888703122735,
      0.018682004883885384,
      0.08609149605035782,
      -0.0076720030046999454,
      -0.06124412640929222,
      0.0894474983215332,
      0.03363610804080963,
      -0.044725771993398666,
      0.06507650017738342,
      0.045732058584690094,
      -0.01770898886024952,
      0.0004028209950774908,
      0.04015616700053215,
      -0.013181124813854694,
      0.05850408598780632,
      -0.09765247255563736,
      0.011370914056897163,
      0.06811842322349548,
      0.0077845314517617226,
      -0.030850864946842194,
      0.02188604138791561,
      0.015889529138803482,
      -0.06140943989157677,
      -0.01160741038620472,
      -0.03983869403600693,
      -0.07093500345945358,
      -0.04490508884191513,
      -0.007843497209250927,
      -0.03479839488863945,
      0.0013163896510377526,
      0.01460527628660202,
      -0.02976611815392971,
      0.018492860719561577,
      0.04840696603059769,
      0.02452072501182556,
      -0.011626035906374454,
      -0.023224249482154846,
      -0.028986942023038864,
      -0.06142965331673622,
      -0.10723275691270828,
      -0.05201130732893944,
      0.04818663001060486,
      -0.0384705550968647,
      0.005647891201078892,
      -0.07203920185565948,
      -0.015142936259508133,
      0.010328167118132114,
      0.002450640546157956,
      -0.005345307756215334,
      -0.06105956807732582,
      0.026048297062516212,
      -0.031314194202423096,
      -0.06105787679553032,
      -0.046674203127622604,
      -0.023901108652353287,
      -0.0896892100572586,
      -0.02637719362974167,
      -0.03280352056026459,
      -0.05389958992600441,
      -0.02004237473011017,
      0.06868460029363632,
      -0.02151966094970703,
      0.08219671994447708,
      -0.007684173062443733,
      -0.0561075396835804,
      -0.006912399083375931,
      0.010081751272082329,
      -0.007168980315327644,
      -0.0011809900170192122,
      -0.008699306286871433,
      -0.007902765646576881,
      0.035053763538599014,
      0.015902923420071602,
      -0.012448583729565144,
      -0.0484296977519989,
      -0.010216801427304745,
      -0.01900476962327957,
      -0.05388966202735901,
      -0.11422202736139297,
      0.02328016236424446,
      0.008686535060405731,
      -0.1021198108792305,
      -0.005304796155542135,
      0.004646120592951775,
      -0.08374235779047012,
      0.003246594686061144,
      -0.030332455411553383,
      -0.008149763569235802,
      0.028524087741971016,
      0.06392714381217957,
      -0.009325118735432625,
      -0.03624344617128372,
      -0.06197522208094597,
      0.06289306282997131,
      -0.0037839622236788273,
      -0.003765898523852229,
      -0.050445787608623505
    ],
    [
      0.04365580528974533,
      0.1542435735464096,
      -0.02746792882680893,
      -0.10779903829097748,
      -0.029076585546135902,
      0.07906929403543472,
      0.028835749253630638,
      -0.011091737076640129,
      -0.07340598851442337,
      0.13624799251556396,
      0.034929629415273666,
      0.0014478379162028432,
      -0.04639841243624687,
      -0.02159523218870163,
      -0.01731927879154682,
      -0.051617808640003204,
      -0.04249797761440277,
      -0.08039136230945587,
      -0.037810925394296646,
      -0.008813944645226002,
      -0.014033803716301918,
      -0.022216692566871643,
      0.018544523045420647,
      0.06696602702140808,
      -0.032983750104904175,
      -0.017552927136421204,
      -0.06738821417093277,
      -0.012482082471251488,
      -0.008848044089972973,
      0.06382663547992706,
      -0.05987459793686867,
      0.06854091584682465,
      0.005813158117234707,
      0.07226968556642532,
      -0.045273128896951675,
      -0.027614301070570946,
      0.06333079189062119,
      0.006503184325993061,
      0.0622127465903759,
      -0.04398651421070099,
      0.039972320199012756,
      0.01562210638076067,
      0.12451571226119995,
      0.048785895109176636,
      -0.08363782614469528,
      0.04645718261599541,
      -0.03327713906764984,
      -0.06595883518457413,
      0.029410438612103462,
      0.0076729306019842625,
      0.014803657308220863,
      -0.04911082610487938,
      -0.0878673866391182,
      0.014866488054394722,
      -0.020205505192279816,
      -0.04160222038626671,
      -0.07789987325668335,
      -0.008388941176235676,
      -0.03373296558856964,
      0.062144406139850616,
      0.010753796435892582,
      0.01866070181131363,
      -0.023198435083031654,
      -0.0700020119547844,
      0.04575010761618614,
      -0.0006876846309751272,
      -0.008088664151728153,
      0.036688562482595444,
      -0.01685008779168129,
      0.07593519985675812,
      0.05941120535135269,
      0.07703118771314621,
      -0.04026259481906891,
      0.07794839888811111,
      -0.001959867076948285,
      -0.01609843038022518,
      -0.05059376358985901,
      0.011936786584556103,
      0.0972258597612381,
      0.0008798693888820708,
      -0.023074757307767868,
      0.061164941638708115,
      -0.008480552583932877,
      0.017542902380228043,
      0.07039819657802582,
      0.0056169177405536175,
      0.03761661797761917,
      0.03284028545022011,
      -0.010064536705613136,
      0.013857850804924965,
      -0.013298073783516884,
      -0.07569152116775513,
      -0.03133578971028328,
      0.020268581807613373,
      -0.02990362048149109,
      0.011661950498819351,
      0.02756853960454464,
      -0.09961587935686111,
      0.012034725397825241,
      -0.03196468949317932,
      0.0036649969406425953,
      0.04367358982563019,
      -0.09388048201799393,
      -0.10263261198997498,
      0.04611103981733322,
      0.01472315564751625,
      -0.035460736602544785,
      0.013623392209410667,
      -0.052179235965013504,
      0.04625262692570686,
      -0.07151525467634201,
      -0.1122579574584961,
      0.04602084308862686,
      0.040191248059272766,
      0.013072412461042404,
      0.016244782134890556,
      0.01673411764204502,
      -0.04762484133243561,
      -0.04279853776097298,
      -0.08000703155994415,
      -0.06403450667858124,
      -0.04496507719159126,
      -0.02409091219305992,
      0.009067674167454243,
      -0.029705645516514778,
      0.03580382093787193,
      -0.06155009567737579,
      0.07580456137657166,
      0.048729851841926575,
      0.017959898337721825,
      -0.08118879050016403,
      -0.0893731638789177,
      0.06328196078538895,
      -0.02945510484278202,
      0.04349305480718613,
      -0.032645173370838165,
      -0.07359528541564941,
      -0.005242870654910803,
      -0.023954208940267563,
      -0.03432196378707886,
      0.04672248288989067,
      0.006896291859447956,
      -0.05740348994731903,
      0.032218869775533676,
      0.007824617438018322,
      0.004172178916633129,
      0.04949002340435982,
      0.0017549466574564576,
      -0.0077380468137562275,
      -0.018418481573462486,
      0.012254394590854645,
      -0.04051554203033447,
      0.046273358166217804,
      0.02181493118405342,
      -0.009497727267444134,
      0.02347843535244465,
      0.06398389488458633,
      0.02148977853357792,
      0.04892583191394806,
      0.07752368599176407,
      -0.05251731351017952,
      -0.041429731994867325,
      0.019563088193535805,
      -0.06886208057403564,
      -0.004138501826673746,
      -0.010809142142534256,
      -0.01270690280944109,
      0.002641413826495409,
      0.030500611290335655,
      -0.034723103046417236,
      -0.01998852752149105,
      -0.037544265389442444,
      -0.06677675247192383,
      -0.027096061035990715,
      0.0012683747336268425,
      0.01575693115592003,
      0.03501496836543083,
      0.04753561317920685,
      -0.0006384743028320372,
      0.0638313740491867,
      -0.01984464004635811,
      -0.01668362133204937,
      0.0003152843564748764,
      -0.03434009477496147,
      0.0034353823866695166,
      -0.02950488217175007,
      -0.05875283479690552,
      0.03299307823181152,
      0.0511450320482254,
      0.04994673281908035,
      0.012156503275036812,
      -0.016908224672079086,
      0.05891650915145874,
      0.008885389193892479,
      -0.0020200482103973627,
      0.02423279359936714,
      0.025079166516661644,
      0.017918821424245834,
      0.01943814381957054,
      0.003199828788638115,
      0.006220455747097731,
      -0.055834293365478516,
      0.05484647676348686,
      -0.004185175523161888,
      0.02099524438381195,
      0.052606191486120224,
      0.04647945612668991,
      -0.0028128125704824924,
      0.0015806457959115505,
      0.05929363891482353,
      0.007747745141386986,
      -0.03839996084570885,
      -0.0450020395219326,
      -0.07163399457931519,
      -0.08600679785013199,
      -0.02941904589533806,
      -0.024675223976373672,
      0.04321689531207085,
      0.01251619029790163,
      0.02882637456059456,
      -0.03637002408504486,
      0.008348861709237099,
      0.04160193353891373,
      -0.06240314617753029,
      0.003840571967884898,
      -0.03710459545254707,
      0.007620898075401783,
      0.03183623030781746,
      -0.0326327383518219,
      0.09182198345661163,
      0.008457847870886326,
      -0.05254704877734184,
      0.028897641226649284,
      0.11202611774206161,
      0.019846033304929733,
      -0.03945726528763771,
      0.0059223719872534275,
      0.05442449450492859,
      0.008750973269343376,
      -0.04867859184741974,
      -0.017831508070230484,
      0.011989636346697807,
      0.06930367648601532,
      0.03980249539017677,
      -0.05409543216228485,
      -0.007975931279361248,
      0.029108941555023193,
      -0.007065820973366499,
      -0.09333367645740509,
      -0.0012119464809074998,
      0.03706634044647217,
      0.028409676626324654,
      -0.0001794824202079326,
      -0.022333092987537384,
      -0.0022381010930985212,
      -0.052714526653289795,
      0.04712643474340439,
      0.03810796141624451,
      0.03165481239557266,
      0.00812357198446989,
      -0.09996256232261658,
      0.05574626848101616,
      0.02547125332057476,
      -0.005085993558168411,
      -0.012165244668722153,
      0.01618140935897827,
      -0.008690625429153442,
      0.008628826588392258,
      0.09058002382516861,
      0.005137410014867783,
      -0.022404268383979797,
      0.07462525367736816,
      -0.021193068474531174,
      -0.03149320185184479,
      -0.0062980144284665585,
      -0.02034727670252323,
      -0.056638214737176895,
      0.025483986362814903,
      0.023347657173871994,
      0.013915734365582466,
      -0.041448310017585754,
      -0.00047851691488176584,
      0.046539463102817535,
      0.004436052870005369,
      0.003950263373553753,
      -0.047412797808647156,
      -0.0017260583117604256,
      0.050401438027620316,
      -0.002148387487977743,
      0.05167876556515694,
      -0.0022553822491317987,
      -0.020387019962072372,
      -0.013860629871487617,
      -0.01774466782808304,
      0.0043846978805959225,
      0.017775660380721092,
      -0.01400804053992033,
      -0.06649532169103622,
      -0.020554395392537117,
      0.005407213233411312,
      -0.0065607368014752865,
      0.006496518850326538,
      -0.003445808542892337,
      -0.014828965999186039,
      0.031817976385354996,
      0.03929600119590759,
      0.0032313924748450518,
      -0.009400834329426289,
      -0.09238076210021973,
      0.03463307023048401,
      0.03161193057894707,
      0.05141053348779678,
      0.053668707609176636,
      0.0685277059674263,
      0.01388032827526331,
      -0.018384508788585663,
      -0.080486960709095,
      -0.06221168860793114,
      -0.058023206889629364,
      -0.004064705688506365,
      -0.017643511295318604,
      0.02078980766236782,
      -0.043086182326078415,
      0.01693088747560978,
      0.06816840916872025,
      -0.004131602123379707,
      -0.008959447965025902,
      -0.005826802458614111,
      0.06311050057411194,
      -0.009715024381875992,
      -0.005595679394900799,
      -0.013721807859838009,
      -0.0464489609003067,
      0.06161944940686226,
      -0.06122191995382309,
      0.0356566458940506,
      0.03130649775266647,
      -0.016095411032438278,
      -0.05204451084136963,
      0.05255834758281708,
      0.06320394575595856,
      0.007228435482829809,
      0.031405724585056305,
      0.012728919275105,
      0.007385076489299536,
      0.05695923790335655,
      -0.030594361945986748,
      -0.016848046332597733,
      0.08311210572719574,
      -0.034036580473184586,
      0.03059723973274231,
      0.07958979904651642,
      -0.03526129946112633,
      -0.052271950989961624,
      -0.05236661061644554,
      0.07984643429517746,
      0.03418085351586342,
      0.0527338907122612,
      0.030913807451725006,
      -0.029689738526940346,
      0.026932917535305023,
      0.0260376688092947,
      -0.04303852468729019,
      0.0601516030728817,
      0.052639782428741455,
      -0.011904146522283554,
      -0.14258502423763275,
      -0.10338748246431351,
      0.03311290964484215,
      -0.027260027825832367,
      0.01046798750758171,
      0.04699036478996277,
      -0.02860332280397415,
      0.00024672591825947165,
      0.03354943171143532,
      0.05110996961593628,
      0.04346923902630806,
      -0.028771759942173958,
      -0.012871954590082169,
      0.011880232021212578,
      -0.05125819146633148,
      -0.04423057287931442,
      0.043096281588077545,
      -0.09574385732412338,
      -0.05949663370847702,
      -0.04110938683152199,
      -0.00732525484636426,
      0.05549941584467888,
      -0.02887457050383091,
      -0.0007349983206950128,
      0.022090597078204155,
      0.025057965889573097,
      -0.09164408594369888,
      0.05111713707447052,
      -0.03449637070298195,
      -0.04314080625772476,
      0.10461536794900894,
      -0.04890800267457962,
      0.0008133650990203023,
      0.06595341861248016,
      -0.033345140516757965,
      -0.05149228498339653,
      0.002219677437096834,
      -0.02444152720272541,
      -0.019405389204621315,
      0.006471404805779457,
      0.009110108017921448,
      -0.018198169767856598,
      -0.009580408222973347,
      0.0416550412774086,
      -0.006906707771122456,
      -0.018486719578504562,
      0.05139752849936485,
      -0.10071137547492981,
      0.04462685436010361,
      0.05116720497608185,
      0.0016892094863578677,
      -0.09395413100719452,
      0.02795799821615219,
      -0.01933516189455986,
      -0.10640715062618256,
      -0.045319776982069016,
      0.042054664343595505,
      0.002937904791906476,
      0.028331564739346504,
      0.005417075473815203,
      0.16120575368404388,
      0.06112942099571228,
      0.10157518833875656,
      0.014795140363276005,
      -0.018961980938911438,
      -0.08235199749469757,
      -0.035886358469724655,
      -0.08292925357818604,
      -0.05133747309446335,
      -0.047533757984638214,
      -0.025094807147979736,
      -0.010648165829479694,
      -0.05804211273789406,
      0.02399476431310177,
      0.0729886144399643,
      -0.01329588983207941,
      -0.048201706260442734,
      0.08613374084234238,
      -0.016811899840831757,
      0.011861195787787437,
      -0.01569763757288456,
      0.01924671046435833,
      0.05116679519414902,
      0.04457565024495125,
      -0.05326781049370766,
      -0.05600587651133537,
      -0.04648737236857414,
      -0.04782821983098984,
      -0.013876167125999928,
      -0.13584381341934204,
      0.03981110453605652,
      -0.024160515516996384,
      -0.057767223566770554,
      0.047304630279541016,
      -0.02450491115450859,
      -0.04155019298195839,
      0.03474317863583565,
      -0.07118584215641022,
      -0.07779256999492645,
      -0.05164317786693573,
      0.0036424414720386267,
      -0.0007829354726709425,
      -0.006884320639073849,
      -0.030629049986600876,
      0.025895388796925545,
      -0.05869801342487335,
      0.024503331631422043,
      0.04669559374451637,
      -0.029190389439463615,
      -0.02105390466749668,
      0.0009160280460491776,
      -0.03149369731545448,
      0.03737850859761238,
      0.1811986118555069,
      -0.01931753382086754,
      0.016916701570153236,
      0.0417216494679451,
      -0.0358116514980793,
      -0.04410197213292122,
      0.0006156364106573164,
      -0.051200494170188904,
      -0.05629770830273628,
      -0.0019488732796162367,
      0.003358992747962475,
      -0.053254298865795135,
      0.0035447191912680864,
      -0.05740135908126831,
      -0.057476550340652466,
      -0.05782300606369972,
      -0.04855521023273468,
      0.024232245981693268,
      -0.07606950402259827,
      0.024805938825011253,
      -0.09624040871858597,
      0.0393001064658165,
      -0.005779361817985773,
      -0.02219114638864994
    ],
    [
      -0.05223076790571213,
      -0.011860673315823078,
      0.08123470097780228,
      0.001394729595631361,
      -0.02884499542415142,
      -0.04517986997961998,
      -0.023357991129159927,
      0.027296096086502075,
      0.017583396285772324,
      -0.0753541886806488,
      -0.12935645878314972,
      0.008359885774552822,
      0.00047349295346066356,
      0.006156477145850658,
      -0.00829208642244339,
      0.009833332151174545,
      -0.006311416160315275,
      0.06544186174869537,
      -0.01474207267165184,
      -0.00667065754532814,
      -0.034277983009815216,
      -0.03418375179171562,
      0.04380614310503006,
      -0.004521367605775595,
      0.019278015941381454,
      -0.029650753363966942,
      0.013883881270885468,
      0.007689978461712599,
      -0.03410889953374863,
      0.0327029787003994,
      -0.09164977818727493,
      0.018041294068098068,
      -0.011823556385934353,
      -0.009215389378368855,
      0.0036808550357818604,
      0.013900982216000557,
      -0.011727818287909031,
      0.01558233518153429,
      0.09842482209205627,
      0.038183510303497314,
      -0.001257451600395143,
      -0.02571273408830166,
      -0.03434889391064644,
      0.02640889398753643,
      -0.06454597413539886,
      0.029874129220843315,
      -0.07669919729232788,
      0.03570568189024925,
      -0.012073964811861515,
      0.06427201628684998,
      0.038351405411958694,
      -0.061079543083906174,
      -0.014763749204576015,
      -0.06185738369822502,
      -0.0014510091859847307,
      -0.0433681383728981,
      -0.001710469019599259,
      -0.043958358466625214,
      0.020465822890400887,
      -0.06880024820566177,
      -0.05223096162080765,
      -0.007204877212643623,
      -0.04726091027259827,
      -0.0005517305107787251,
      -0.07352468371391296,
      0.05803864821791649,
      0.04735029488801956,
      -0.03603024035692215,
      0.049285490065813065,
      0.025234544649720192,
      -0.0006402274593710899,
      0.051208388060331345,
      0.04671160504221916,
      -0.021888641640543938,
      0.06882379204034805,
      0.0021574953570961952,
      0.028581062331795692,
      0.008024094626307487,
      0.01132320985198021,
      0.04412095993757248,
      -0.05870382487773895,
      0.08281783014535904,
      -0.027943529188632965,
      -0.0080803781747818,
      0.034664664417505264,
      -0.05164305865764618,
      -0.055254366248846054,
      0.0029669804498553276,
      -0.007600723300129175,
      0.008400138467550278,
      -0.032867301255464554,
      -0.07675566524267197,
      -0.006247672252357006,
      -0.01579456590116024,
      -0.10398783534765244,
      0.01201542466878891,
      0.047920893877744675,
      0.05412180721759796,
      -0.019581537693738937,
      0.02994369901716709,
      -0.04413621500134468,
      0.00987063068896532,
      -0.020413195714354515,
      0.00998180452734232,
      0.03216259554028511,
      0.027022775262594223,
      0.024096187204122543,
      -0.023874521255493164,
      0.03483688086271286,
      0.039805468171834946,
      -0.055536799132823944,
      -0.048226580023765564,
      -0.03669300302863121,
      0.022814376279711723,
      0.020619459450244904,
      -0.005178239196538925,
      0.04655160382390022,
      -0.04373708367347717,
      -0.01614425517618656,
      -0.002376035088673234,
      0.07372183352708817,
      -0.044065460562705994,
      -0.05332347750663757,
      -0.010148730129003525,
      -0.03788376599550247,
      0.04190573841333389,
      0.06470996886491776,
      0.009733865037560463,
      -0.11021400988101959,
      -0.09966951608657837,
      -0.010807279497385025,
      -0.013733013533055782,
      -0.025392021983861923,
      -0.051466550678014755,
      0.07010286301374435,
      -0.042690616101026535,
      0.03751349449157715,
      -0.013085870072245598,
      -0.00920849945396185,
      0.006457772105932236,
      -0.03379128500819206,
      0.02230069786310196,
      0.02694246731698513,
      -0.012480874545872211,
      -0.035742197185754776,
      0.02644772082567215,
      0.020704060792922974,
      0.02361482009291649,
      0.12171296030282974,
      -0.021012572571635246,
      0.04553354158997536,
      -0.014293227344751358,
      0.05137527361512184,
      0.14562414586544037,
      -0.016311489045619965,
      -0.06335882097482681,
      0.029633644968271255,
      0.0670703575015068,
      -0.0037387628108263016,
      -0.05327177792787552,
      0.023693308234214783,
      -0.026855533942580223,
      0.03229716420173645,
      0.007459348067641258,
      -0.0019598070066422224,
      -0.05063573271036148,
      0.05129418149590492,
      -0.027497172355651855,
      0.05234507843852043,
      -0.038554150611162186,
      -0.014665014110505581,
      -0.0021505188196897507,
      -0.024100149050354958,
      -0.050499532371759415,
      -0.043279893696308136,
      -0.07119137048721313,
      0.14467577636241913,
      -0.029834352433681488,
      -0.0671965703368187,
      0.008697113022208214,
      -0.007039103657007217,
      0.023921793326735497,
      0.025644451379776,
      0.019584104418754578,
      -0.03942699357867241,
      0.01526136789470911,
      0.04602254182100296,
      -0.06602533906698227,
      -0.009249108843505383,
      -0.04143402352929115,
      -0.05777593329548836,
      0.019094735383987427,
      0.0024056194815784693,
      0.03404581546783447,
      -0.011151495389640331,
      0.0099805798381567,
      0.033326197415590286,
      0.07043876498937607,
      0.021203959360718727,
      -0.08210963755846024,
      0.04332386702299118,
      -0.02384820021688938,
      0.02796226553618908,
      -0.100558340549469,
      -0.0024268892593681812,
      0.06060591712594032,
      -0.04374605789780617,
      -0.003511509159579873,
      0.030099008232355118,
      0.030942538753151894,
      -0.011700263246893883,
      0.07531003654003143,
      0.05161866545677185,
      -0.006770971696823835,
      -0.03370346128940582,
      0.09187786281108856,
      -0.01349912490695715,
      0.034971460700035095,
      -0.0886337012052536,
      -0.006394983269274235,
      -0.024576818570494652,
      0.01636578142642975,
      -0.031183838844299316,
      0.0279674269258976,
      0.012908865697681904,
      0.05417245253920555,
      0.04743743687868118,
      -0.041224751621484756,
      -0.07428856194019318,
      0.09740900248289108,
      0.002243627095595002,
      0.013790734112262726,
      0.03854312375187874,
      0.012429541908204556,
      -0.022811634466052055,
      -0.019771238788962364,
      0.0471310168504715,
      -0.05196960270404816,
      0.01704520173370838,
      -0.09075357019901276,
      -0.029334422200918198,
      0.0060046520084142685,
      -0.07567118853330612,
      -0.02041630819439888,
      -0.039930447936058044,
      0.017556872218847275,
      0.035992324352264404,
      0.014056331478059292,
      0.007896928116679192,
      -0.025118017569184303,
      0.023987144231796265,
      -0.02944628708064556,
      0.02904565818607807,
      -0.07369369268417358,
      0.0038062315434217453,
      0.017726469784975052,
      0.0072425371035933495,
      -0.04136672616004944,
      -0.004074606578797102,
      0.006406385917216539,
      0.03374738618731499,
      0.10819689929485321,
      -0.05167994275689125,
      -0.09138040989637375,
      0.025762589648365974,
      0.009060397744178772,
      0.04301571846008301,
      -0.06201881915330887,
      -0.03704370558261871,
      -0.05473914369940758,
      0.09504304081201553,
      0.08292967081069946,
      0.0017673034453764558,
      -0.02047475427389145,
      -0.00308435270562768,
      0.08184780180454254,
      -0.1556423306465149,
      0.040221620351076126,
      -0.006243680603802204,
      -0.02637617290019989,
      0.009762868285179138,
      -0.015397603623569012,
      -0.014544583857059479,
      0.011705568060278893,
      -0.08876784145832062,
      0.018974198028445244,
      0.07810404896736145,
      0.12009327858686447,
      -0.001830334309488535,
      0.02814052812755108,
      -0.012203116901218891,
      0.12903927266597748,
      0.06604743748903275,
      0.009802944958209991,
      0.04050036892294884,
      0.02579629048705101,
      -0.06053243950009346,
      -0.012740063481032848,
      0.002458643401041627,
      0.01617308333516121,
      0.05667326971888542,
      0.008331971243023872,
      0.026297682896256447,
      0.06350847333669662,
      0.06170572713017464,
      0.012632127851247787,
      -0.040144506841897964,
      -0.011422975920140743,
      -0.04117151349782944,
      0.07179301977157593,
      0.017094334587454796,
      0.027109939604997635,
      -0.012550192885100842,
      -0.0021427867468446493,
      -0.0025200420059263706,
      0.005303428508341312,
      0.02423340268433094,
      0.016510626301169395,
      0.060725897550582886,
      0.033179715275764465,
      -0.0064817164093256,
      0.005978207569569349,
      -0.014749877154827118,
      0.05595250427722931,
      -0.02855376899242401,
      0.025298813357949257,
      0.024111267179250717,
      0.04024454206228256,
      0.045423369854688644,
      0.018334612250328064,
      -0.05532537400722504,
      0.037636175751686096,
      0.047251295298337936,
      -0.027792969718575478,
      0.04156005382537842,
      0.04699784144759178,
      0.12130773812532425,
      -0.010424507781863213,
      -0.07445667684078217,
      -0.10453253984451294,
      -0.020423803478479385,
      0.03257254138588905,
      0.029394561424851418,
      0.009962882846593857,
      0.03956935554742813,
      -0.02377365715801716,
      0.04975728318095207,
      0.03415212035179138,
      -0.012505565769970417,
      0.003940419293940067,
      0.023730142042040825,
      0.07498592138290405,
      0.0022285173181444407,
      -0.04356676712632179,
      -0.0734521895647049,
      0.04459695890545845,
      0.07550347596406937,
      0.11435514688491821,
      0.03471264988183975,
      0.014561700634658337,
      -0.037338726222515106,
      0.030145756900310516,
      -0.010123217478394508,
      -0.0015467259800061584,
      0.02241310104727745,
      0.05021809786558151,
      -0.00506866630166769,
      -0.014349863864481449,
      -0.03321204334497452,
      -0.0011408203281462193,
      -0.0039311787113547325,
      -0.05643763393163681,
      0.08251995593309402,
      -0.018629375845193863,
      -0.03882623836398125,
      0.03580243140459061,
      0.029095841571688652,
      0.015511063858866692,
      -0.07626064121723175,
      -0.02669248729944229,
      -0.0032012697774916887,
      -0.02612083964049816,
      0.03510577976703644,
      -0.05240580439567566,
      -0.040880534797906876,
      -0.030219877138733864,
      0.07600874453783035,
      -0.022690804675221443,
      -0.00695557938888669,
      0.01737027056515217,
      -0.05536255985498428,
      0.0037525056395679712,
      -0.019720375537872314,
      -0.007543192245066166,
      0.035268884152173996,
      0.03988851234316826,
      -0.04828552529215813,
      -0.04208238050341606,
      0.0053124879486858845,
      -0.053839050233364105,
      -0.018939457833766937,
      -0.01741650141775608,
      -0.011357107199728489,
      -0.005048735532909632,
      -0.015699222683906555,
      -0.07614773511886597,
      0.023999018594622612,
      0.0009459287393838167,
      0.08879610896110535,
      -0.04972868785262108,
      -0.027494318783283234,
      0.04229097440838814,
      -0.028575098142027855,
      -0.00405177753418684,
      0.03485438600182533,
      -0.012074204161763191,
      -0.0465032197535038,
      -0.04525524750351906,
      0.02662368305027485,
      -0.05621105432510376,
      0.023660654202103615,
      -0.0142952436581254,
      -0.05333675816655159,
      -0.036112915724515915,
      0.00844657700508833,
      0.0657256469130516,
      0.04375159740447998,
      0.03373070806264877,
      0.07528804242610931,
      0.03964514285326004,
      -0.0695623978972435,
      -0.005988794844597578,
      0.040744930505752563,
      0.027603870257735252,
      0.06117419898509979,
      0.006452526897192001,
      0.059022534638643265,
      -0.010325548239052296,
      0.017862142994999886,
      0.026311200112104416,
      -0.006938608828932047,
      -0.02119550108909607,
      -0.014377495273947716,
      -0.03352946788072586,
      0.024017512798309326,
      0.04812268540263176,
      -0.006284738425165415,
      -0.004401884973049164,
      -0.054029982537031174,
      -0.024408729746937752,
      0.00200660084374249,
      -0.03395199775695801,
      -0.00220112525857985,
      0.04028570279479027,
      0.053266365081071854,
      -0.07392237335443497,
      0.059341829270124435,
      0.02371877245604992,
      -0.029435517266392708,
      0.01408136636018753,
      0.08638987690210342,
      6.914196274010465e-05,
      0.011058084666728973,
      -0.08348581194877625,
      0.003301145974546671,
      0.022681789472699165,
      -0.02413451112806797,
      0.06754913926124573,
      -0.006712717469781637,
      -0.015123128890991211,
      0.034739527851343155,
      -0.011514079757034779,
      0.016220493242144585,
      -0.004405515268445015,
      -0.020259100943803787,
      0.0007386141805909574,
      0.04969245195388794,
      0.006589959375560284,
      -0.029941394925117493,
      0.12280794978141785,
      0.0444997102022171,
      0.019270287826657295,
      0.025166908279061317,
      -0.05692121759057045,
      0.01544291339814663,
      -0.02365131303668022,
      0.0010565946577116847,
      -0.01493231114000082,
      0.03879978507757187,
      0.10287202894687653,
      0.032416801899671555,
      0.08605888485908508,
      0.012354576028883457,
      -0.047017037868499756,
      0.018201058730483055,
      -0.04062044620513916,
      0.0642734169960022,
      0.01691766455769539,
      -0.044679027050733566,
      -0.028853764757514,
      0.047911640256643295,
      -0.030271220952272415,
      -0.0007680661510676146
    ],
    [
      0.10264541208744049,
      -0.03733867034316063,
      0.055974941700696945,
      0.05297130346298218,
      0.006559677422046661,
      -0.01455078087747097,
      0.014713470824062824,
      -0.04541665315628052,
      0.07882750034332275,
      -0.036836300045251846,
      0.07762490957975388,
      -0.10934469848871231,
      -0.06027904152870178,
      -0.02418464608490467,
      0.029734235256910324,
      0.0026397507172077894,
      0.07809142768383026,
      -0.05273141711950302,
      0.02198207378387451,
      0.007615741807967424,
      -0.0065180277451872826,
      -0.03511355072259903,
      -0.04283732548356056,
      -0.10814370214939117,
      -0.07657918334007263,
      -0.013985661789774895,
      -0.01837521605193615,
      -0.030951067805290222,
      -0.03979332745075226,
      -0.00017354576266370714,
      0.009322553873062134,
      0.06889993697404861,
      -0.060081303119659424,
      0.0411255806684494,
      0.020393572747707367,
      -0.07327040284872055,
      -0.06082569435238838,
      0.025688422843813896,
      -0.05616427958011627,
      0.04347671568393707,
      0.070444755256176,
      -0.011919800192117691,
      -0.07429879903793335,
      -0.03337309882044792,
      -0.02802451327443123,
      -0.0687219500541687,
      -0.09115049242973328,
      0.07432489097118378,
      0.0016837368020787835,
      0.011880018748342991,
      -0.050677236169576645,
      -0.05445399507880211,
      0.0561893954873085,
      0.013102758675813675,
      0.026515915989875793,
      -0.014309084042906761,
      -0.02365051954984665,
      -0.041888527572155,
      -0.030498530715703964,
      0.015714013949036598,
      -0.008656175807118416,
      0.03846697136759758,
      -0.0404103547334671,
      0.017786871641874313,
      -0.020030677318572998,
      -0.015665719285607338,
      0.00048314419109374285,
      0.004680049140006304,
      -0.06442271918058395,
      0.09530209004878998,
      -0.024940039962530136,
      -0.035935331135988235,
      0.027183707803487778,
      0.04443296790122986,
      -0.018207333981990814,
      -0.0748828798532486,
      -0.009848413057625294,
      0.06803635507822037,
      -0.04602086916565895,
      -0.052476245909929276,
      0.07530847191810608,
      0.019152795895934105,
      0.061386093497276306,
      0.007337481249123812,
      -0.039675820618867874,
      0.04172113165259361,
      0.04071534052491188,
      0.028549185022711754,
      -0.0011323352809995413,
      -0.08898334205150604,
      -0.07386559247970581,
      -0.036349210888147354,
      -0.10998710244894028,
      0.001013219472952187,
      0.0101557532325387,
      -0.02589499019086361,
      0.007256190292537212,
      -0.08809271454811096,
      0.0844290629029274,
      -0.010981167666614056,
      0.01346917636692524,
      0.05683295428752899,
      -0.022528599947690964,
      0.0019621250685304403,
      0.060040999203920364,
      -0.01812673918902874,
      0.03819647431373596,
      0.01627969741821289,
      0.011089686304330826,
      -0.015363861806690693,
      -0.11993689835071564,
      0.030955616384744644,
      0.008552072569727898,
      0.03622494637966156,
      -0.0026424690149724483,
      0.020933834835886955,
      -0.056514009833335876,
      0.023280572146177292,
      -0.011125072836875916,
      0.051528044044971466,
      -0.062380943447351456,
      -0.08705718070268631,
      -0.002491308841854334,
      -0.005427943542599678,
      -0.10690765827894211,
      0.05347828194499016,
      0.003925417549908161,
      0.008936094120144844,
      0.011392314918339252,
      -0.04338797554373741,
      0.03688891604542732,
      -0.008836783468723297,
      -0.05727986618876457,
      -0.01974182203412056,
      0.023473991081118584,
      -0.04027474671602249,
      0.026286903768777847,
      -0.0038675484247505665,
      -0.011544261127710342,
      0.01267560850828886,
      0.0029764214996248484,
      0.03144993633031845,
      -0.0264884103089571,
      -0.03670116886496544,
      0.0032263919711112976,
      -0.021785052493214607,
      -0.036760300397872925,
      -0.06285585463047028,
      0.008980406448245049,
      -0.0737384483218193,
      0.0053334422409534454,
      -0.02831137366592884,
      0.047383736819028854,
      0.03251681476831436,
      0.08557935059070587,
      -0.08550466597080231,
      0.08135204017162323,
      -0.03880898281931877,
      -0.006995723117142916,
      -0.02526571974158287,
      -0.09080514311790466,
      0.009418808855116367,
      -0.07549937814474106,
      -0.06035599112510681,
      -0.06651484966278076,
      -0.01352685783058405,
      0.04487770423293114,
      -0.003717597806826234,
      0.029784081503748894,
      0.08636222779750824,
      0.056928664445877075,
      -0.008794228546321392,
      -0.0623236782848835,
      -0.12855854630470276,
      0.00508642103523016,
      -0.042413387447595596,
      -0.021506240591406822,
      0.033317890018224716,
      -0.07483682036399841,
      0.00708490377292037,
      -0.028704466298222542,
      -0.06815174221992493,
      -0.046664997935295105,
      -0.05286453291773796,
      -0.031138284131884575,
      0.010552704334259033,
      0.009506450966000557,
      0.06338886171579361,
      0.027454042807221413,
      -0.08081261813640594,
      -0.01992826536297798,
      0.002646041102707386,
      0.020194582641124725,
      0.00879181083291769,
      -0.07132336497306824,
      0.04134127125144005,
      0.04626943916082382,
      -0.057770200073719025,
      -0.020920010283589363,
      0.022000964730978012,
      0.002788440091535449,
      0.010519650764763355,
      0.0057627856731414795,
      0.019016608595848083,
      -0.04624779149889946,
      -0.0052413553930819035,
      -0.12265893816947937,
      0.01744265668094158,
      -0.004156759008765221,
      -0.0664992555975914,
      0.024966107681393623,
      0.0575588159263134,
      0.0328933522105217,
      0.019525187090039253,
      0.002506508957594633,
      0.012763851322233677,
      -0.02003619819879532,
      0.025046031922101974,
      -0.008220536634325981,
      -0.005614721681922674,
      0.02598002180457115,
      -0.005857159849256277,
      -0.009110497310757637,
      0.05066210776567459,
      -0.027905739843845367,
      -0.004030759911984205,
      0.039538148790597916,
      0.07761797308921814,
      -0.02250617928802967,
      -0.0553361177444458,
      0.011102696880698204,
      0.000587797723710537,
      -0.0402301549911499,
      -0.05051387846469879,
      0.041201747953891754,
      -0.0010361071908846498,
      0.02181236632168293,
      0.02511175535619259,
      0.015334881842136383,
      -0.03665517643094063,
      0.04787638410925865,
      -0.0554102398455143,
      -0.02129741571843624,
      -0.0744013637304306,
      -0.07407455146312714,
      -0.0020553150679916143,
      0.01355026476085186,
      0.0030690613202750683,
      -0.028108417987823486,
      0.07387855648994446,
      0.04717545211315155,
      0.1103418618440628,
      -0.03241858258843422,
      -0.01742665469646454,
      0.0011748342076316476,
      0.007870982401072979,
      0.00717282434925437,
      -0.07793610543012619,
      -0.014347156509757042,
      -0.04903418570756912,
      -0.10020363330841064,
      0.13297225534915924,
      0.0007158764055930078,
      0.024584388360381126,
      -0.03546435013413429,
      -0.012857218272984028,
      0.026309272274374962,
      -0.05705178529024124,
      -0.009795501828193665,
      0.07113659381866455,
      -0.06081303581595421,
      -0.021932227537035942,
      0.07841148227453232,
      0.133445605635643,
      -0.03624877706170082,
      -0.0006582958158105612,
      -0.022735031321644783,
      -0.016169842332601547,
      0.0025996356271207333,
      -0.030419200658798218,
      -0.02479557693004608,
      0.055042143911123276,
      -0.03571625053882599,
      -0.036292143166065216,
      0.04715919867157936,
      0.000495878339279443,
      0.05880998447537422,
      -0.05422309413552284,
      0.012167748063802719,
      -0.004233721178025007,
      -0.009770309552550316,
      0.03547382727265358,
      0.038341108709573746,
      0.05781014636158943,
      0.027383210137486458,
      -0.019766569137573242,
      -0.004726838786154985,
      0.04976480454206467,
      -0.00886098388582468,
      0.06935880333185196,
      0.025779245421290398,
      0.01820695772767067,
      -0.10553057491779327,
      0.0053280070424079895,
      -0.04556349292397499,
      -0.004247105680406094,
      -0.05644465610384941,
      0.01116947177797556,
      -0.045408423990011215,
      0.02089296095073223,
      -0.034653834998607635,
      -0.011448211036622524,
      -0.03407272696495056,
      0.02994106337428093,
      0.008824871852993965,
      0.05997825413942337,
      0.09222619980573654,
      -0.04461497813463211,
      -0.0038579017855226994,
      -0.02947380207479,
      0.0638396218419075,
      -0.0010468170512467623,
      -0.002730512758716941,
      -0.005808514077216387,
      -0.00787431187927723,
      0.018172303214669228,
      0.015329951420426369,
      -0.06131759285926819,
      0.025386372581124306,
      0.037470072507858276,
      0.0030262207146734,
      0.0803312435746193,
      0.04599719122052193,
      -0.0576237291097641,
      -0.002548867603763938,
      0.011387397535145283,
      0.04122983664274216,
      0.052950821816921234,
      -0.00570678012445569,
      -0.004178272560238838,
      0.0415322482585907,
      -0.03995842486619949,
      -0.008088070899248123,
      -0.05497017875313759,
      0.13994449377059937,
      0.02017442137002945,
      0.08008772879838943,
      -0.021447038277983665,
      0.04641111567616463,
      0.04050428420305252,
      -0.006914631463587284,
      -0.05649033933877945,
      -0.029717303812503815,
      -0.0687403678894043,
      -0.0011687407968565822,
      -0.03125756233930588,
      -0.06022373214364052,
      0.05084964632987976,
      -0.03134359046816826,
      0.01687479205429554,
      0.03070794604718685,
      -0.04589603468775749,
      -0.030715856701135635,
      -0.04436498135328293,
      -0.012894514948129654,
      0.06330519914627075,
      0.06863033026456833,
      -0.03572607785463333,
      0.06429985910654068,
      -0.06088624894618988,
      0.058116305619478226,
      0.04998888820409775,
      0.00020737519662361592,
      -0.03473358228802681,
      -0.08231896162033081,
      0.09058141708374023,
      0.03216204792261124,
      -0.010631364770233631,
      -0.1305813193321228,
      -0.053882043808698654,
      -0.04592173174023628,
      0.03664807602763176,
      -0.036362405866384506,
      0.010279286652803421,
      0.04479355737566948,
      0.03182952105998993,
      -0.11352286487817764,
      0.03389645367860794,
      -0.0325523242354393,
      0.015408821403980255,
      0.037868574261665344,
      0.016139449551701546,
      -0.02853870391845703,
      0.005542401224374771,
      -0.016006048768758774,
      0.02935263328254223,
      -0.020891403779387474,
      -0.03089742176234722,
      -0.061158500611782074,
      -0.03195052221417427,
      -0.05923990160226822,
      -0.05263461172580719,
      -0.06352785229682922,
      -0.025457249954342842,
      -0.017142843455076218,
      0.026551710441708565,
      0.021688302978873253,
      0.04580153152346611,
      0.03438340872526169,
      0.01770377717912197,
      0.1012514978647232,
      0.036949433386325836,
      0.037241291254758835,
      0.007159494329243898,
      0.0025412729009985924,
      0.07142205536365509,
      -0.03515930473804474,
      0.020589163526892662,
      0.02011909708380699,
      -0.01882706768810749,
      -0.0803137868642807,
      -0.028283707797527313,
      -0.07397913187742233,
      0.013287801295518875,
      -0.01605384238064289,
      -0.029245620593428612,
      -0.007037383504211903,
      0.022866755723953247,
      0.04143722727894783,
      -0.01685364358127117,
      0.011456029489636421,
      0.0336117148399353,
      0.02056952565908432,
      0.04744589328765869,
      -0.004627200774848461,
      -0.03465026989579201,
      -0.11641884595155716,
      0.012044158764183521,
      0.03923511877655983,
      -0.020057538524270058,
      -0.013915103860199451,
      -0.058689117431640625,
      -0.005912753753364086,
      0.05242695286870003,
      -0.02903912588953972,
      -0.0450860895216465,
      -0.02020939625799656,
      0.07944352179765701,
      -0.019142793491482735,
      0.036365002393722534,
      -0.00046949624083936214,
      0.04355865344405174,
      -0.016081660985946655,
      0.03973250463604927,
      -0.008698436431586742,
      -0.06888774782419205,
      0.03058805875480175,
      -0.005103299859911203,
      -0.03479020670056343,
      0.017061276361346245,
      0.05454077199101448,
      0.06377222388982773,
      -0.07705014199018478,
      0.04607328772544861,
      -0.06819341331720352,
      0.06012610346078873,
      -0.013329897075891495,
      -0.04490908980369568,
      0.0013446916127577424,
      0.019237056374549866,
      0.05792710557579994,
      -0.0983075499534607,
      0.02946620061993599,
      -0.01772734895348549,
      -0.028371399268507957,
      -0.09879684448242188,
      0.015318569727241993,
      -0.016176002100110054,
      -0.028462640941143036,
      0.03570079803466797,
      0.008105777204036713,
      -0.12964588403701782,
      0.06333812326192856,
      0.04412715882062912,
      0.03729308396577835,
      -0.06843031942844391,
      -0.00898999534547329,
      0.0036393902264535427,
      0.02269638516008854,
      -0.045086897909641266,
      -0.06943614780902863,
      0.09507177770137787,
      -0.060107264667749405,
      0.009948000311851501,
      -0.03894869610667229,
      -0.06312033534049988,
      -0.007609586231410503,
      -0.00820872001349926,
      -0.05101168900728226,
      -0.071275994181633,
      0.0035373324062675238,
      0.06730320304632187,
      0.0054953391663730145
    ],
    [
      -0.008767207153141499,
      -0.01570558361709118,
      0.04575009271502495,
      -0.012784875929355621,
      -0.09647122770547867,
      -0.058288197964429855,
      0.05525300279259682,
      0.041441116482019424,
      -0.05685551092028618,
      0.05060076341032982,
      0.06648508459329605,
      0.07902093231678009,
      -0.06763364374637604,
      0.013826487585902214,
      0.021710122004151344,
      -0.047470495104789734,
      0.05616350844502449,
      -0.036408789455890656,
      0.048191461712121964,
      -0.024843111634254456,
      -0.10092282295227051,
      -0.030241448432207108,
      -0.10457609593868256,
      0.020957056432962418,
      -0.04822910577058792,
      0.0004137266078032553,
      0.011251700110733509,
      0.055700767785310745,
      0.02125021442770958,
      0.06356773525476456,
      -0.04400316998362541,
      0.021534135565161705,
      0.002029148628935218,
      0.03994600102305412,
      0.06754110753536224,
      -0.006250799633562565,
      -0.015138166025280952,
      0.016389325261116028,
      -0.013668587431311607,
      -0.008812193758785725,
      0.023319875821471214,
      0.016901034861803055,
      -0.04103810340166092,
      -0.03429040685296059,
      0.04705687612295151,
      -0.016324078664183617,
      -0.017690761014819145,
      -0.00698500033468008,
      0.0001747456844896078,
      -0.04464893788099289,
      -0.02047712355852127,
      -0.09694220870733261,
      0.023889733478426933,
      -0.00478664506226778,
      -0.026942433789372444,
      0.006499637383967638,
      -0.06618409603834152,
      -0.06307052075862885,
      -0.06341441720724106,
      -0.005505038890987635,
      0.02234319970011711,
      -0.06327034533023834,
      0.024364231154322624,
      -0.019170843064785004,
      -0.005042240954935551,
      -0.017106860876083374,
      0.035932958126068115,
      -0.0026485705748200417,
      -0.08280317485332489,
      0.013338711112737656,
      0.011933748610317707,
      0.035463470965623856,
      0.024738216772675514,
      -0.009722293354570866,
      0.031613413244485855,
      -0.02133752964437008,
      0.049495879560709,
      0.04738711193203926,
      0.01588674820959568,
      0.0009335034992545843,
      -0.01655026711523533,
      0.01592816784977913,
      -0.027059750631451607,
      -0.05975957587361336,
      -0.08622504025697708,
      0.010315630584955215,
      -0.06862655282020569,
      0.03645128011703491,
      0.021036993712186813,
      0.003487775567919016,
      0.03329909220337868,
      -0.034708261489868164,
      -0.00908646173775196,
      0.07555879652500153,
      -0.034592460840940475,
      -0.07215419411659241,
      0.046446457505226135,
      0.0224685650318861,
      0.013226774521172047,
      0.0826258584856987,
      0.04406936466693878,
      -0.042295124381780624,
      -0.03500726819038391,
      0.007139112334698439,
      0.005795570090413094,
      -0.03249042108654976,
      0.03783378377556801,
      0.06814707815647125,
      0.043098535388708115,
      0.0727020651102066,
      -0.09117882698774338,
      0.001155921141617,
      0.05660737678408623,
      0.07234497368335724,
      0.04031552001833916,
      -0.09827012568712234,
      0.011261659674346447,
      -0.00879815872758627,
      0.015187328681349754,
      0.037402838468551636,
      -0.055218107998371124,
      -0.02165832743048668,
      -0.06002016365528107,
      0.05590192973613739,
      -0.05739891156554222,
      0.05427064746618271,
      0.012000419199466705,
      0.03966062515974045,
      0.06398889422416687,
      0.021356873214244843,
      0.0011647684732452035,
      0.039147987961769104,
      -0.07275249809026718,
      0.028111260384321213,
      -0.01547172386199236,
      0.0701669380068779,
      0.048024196177721024,
      0.06720595806837082,
      0.017772778868675232,
      -0.08123940229415894,
      -0.06207021698355675,
      -0.04261264204978943,
      -0.0062451534904539585,
      -0.025539105758070946,
      -4.8401499952888116e-05,
      -0.059100206941366196,
      0.07204645127058029,
      0.011149728670716286,
      0.06856917589902878,
      -0.0816226378083229,
      -0.01957499235868454,
      -0.034518051892519,
      -0.013894987292587757,
      0.019916215911507607,
      0.0741594061255455,
      -0.01356140524148941,
      0.00556528614833951,
      0.011542689986526966,
      -0.020963316783308983,
      -0.0326697938144207,
      -0.001852627145126462,
      -0.03202841058373451,
      -0.011175711639225483,
      -0.06008473038673401,
      -0.026306547224521637,
      -0.05189517140388489,
      -0.027467822656035423,
      0.05737953260540962,
      0.0025929114781320095,
      -0.008136793971061707,
      0.030164185911417007,
      0.055197130888700485,
      -0.0037647078279405832,
      -0.011855297721922398,
      0.01591319963335991,
      0.02308577299118042,
      0.09049929678440094,
      0.039963118731975555,
      -0.0343191958963871,
      0.0009710752055980265,
      0.11594101786613464,
      -0.08244245499372482,
      -0.014029227197170258,
      -0.00904038641601801,
      -0.04185173660516739,
      -0.013403329998254776,
      0.02054581604897976,
      0.0341222919523716,
      -0.0035923703107982874,
      0.012016688473522663,
      0.021338840946555138,
      -0.023265207186341286,
      0.01929277926683426,
      0.05912700295448303,
      -0.02771739661693573,
      -0.03884416073560715,
      -0.08837733417749405,
      -0.01737009733915329,
      -0.061096109449863434,
      0.0014761713100597262,
      0.09582061320543289,
      -0.020842954516410828,
      0.04638669639825821,
      0.0179047342389822,
      -0.007492772303521633,
      0.018635476008057594,
      -0.038858912885189056,
      0.06228463724255562,
      -0.033483557403087616,
      0.013634197413921356,
      0.043406203389167786,
      -0.009021853096783161,
      0.02916921302676201,
      0.06575522571802139,
      -0.011936808936297894,
      0.008741745725274086,
      0.007576677016913891,
      0.08634234964847565,
      0.05454438552260399,
      -0.0169228482991457,
      0.023667963221669197,
      -0.04407050088047981,
      -0.05071990191936493,
      0.018079202622175217,
      0.019176995381712914,
      -0.046190742403268814,
      -0.005409827455878258,
      0.08777015656232834,
      0.049417644739151,
      0.06525662541389465,
      0.026154465973377228,
      0.06911114603281021,
      0.032303351908922195,
      0.05539454147219658,
      -0.005272731650620699,
      -0.03658902645111084,
      0.01912848837673664,
      0.06223907694220543,
      0.00669684074819088,
      -0.019375717267394066,
      0.04111303389072418,
      -0.001404954120516777,
      0.031187275424599648,
      -0.015566625632345676,
      -0.040407273918390274,
      0.05116291716694832,
      -0.05143541097640991,
      0.037445105612277985,
      0.016118336468935013,
      0.05191465839743614,
      -0.004152439069002867,
      -0.0533432811498642,
      -0.03245758265256882,
      0.04392625391483307,
      -0.009202465415000916,
      0.03209471330046654,
      0.022323301061987877,
      -0.0026226979680359364,
      0.02331370860338211,
      -0.012379714287817478,
      0.007971738465130329,
      -0.018042396754026413,
      -0.05570399761199951,
      -0.07624702900648117,
      0.005325921345502138,
      0.050005096942186356,
      0.06689504534006119,
      -0.0042470176704227924,
      0.02232765220105648,
      0.0481334887444973,
      -0.01797039434313774,
      -0.08114077150821686,
      -0.00034497762680985034,
      -0.014558080583810806,
      -0.012256759218871593,
      -0.005609962623566389,
      -0.019995257258415222,
      -0.07517441362142563,
      -0.005230727139860392,
      -0.047972921282052994,
      -0.002568635391071439,
      0.015047873370349407,
      -0.0237714946269989,
      0.00343396607786417,
      -0.0340435653924942,
      0.028488019481301308,
      -0.0015404541045427322,
      0.05885493755340576,
      -0.11051508039236069,
      0.004051004070788622,
      0.010666086338460445,
      0.021841108798980713,
      -0.003987415693700314,
      0.057317301630973816,
      -0.011540462262928486,
      -0.021129218861460686,
      -0.06740139424800873,
      0.03394268825650215,
      0.031957462430000305,
      0.02054128795862198,
      0.04223829135298729,
      -0.035124607384204865,
      -0.008235611021518707,
      -0.03276572376489639,
      -0.033569060266017914,
      -0.0137909771874547,
      0.03179670125246048,
      -0.001299045281484723,
      0.00973526295274496,
      -0.0046281819231808186,
      -0.059445206075906754,
      -0.0062384651973843575,
      0.030131129547953606,
      0.04656270891427994,
      0.026218002662062645,
      6.668889545835555e-05,
      -0.02921975404024124,
      -0.04024014621973038,
      0.09213943779468536,
      0.02178242802619934,
      0.02802661806344986,
      0.07619257271289825,
      0.02006380818784237,
      0.10863561183214188,
      -0.010700908489525318,
      -0.0296943299472332,
      -0.08792232722043991,
      -0.0014990015188232064,
      0.021244099363684654,
      0.028689440339803696,
      -0.02399977296590805,
      0.004259886220097542,
      -0.029957910999655724,
      0.003159950952976942,
      0.1082400381565094,
      -0.0255622249096632,
      0.04182551056146622,
      -0.04238635674118996,
      -0.0030710496939718723,
      0.015293904580175877,
      -0.08246666193008423,
      0.011718995869159698,
      0.0003430037759244442,
      0.034456342458724976,
      0.06934834271669388,
      -0.028355613350868225,
      0.037472713738679886,
      -0.08723795413970947,
      -0.04943887144327164,
      -0.03114708885550499,
      0.06685391813516617,
      -0.07229946553707123,
      0.026015494018793106,
      0.009004613384604454,
      0.006322066765278578,
      -0.019671089947223663,
      0.006328304298222065,
      0.11718345433473587,
      -0.06806844472885132,
      0.059288740158081055,
      -0.00021680021018255502,
      -0.017624415457248688,
      -0.047003548592329025,
      0.05070042237639427,
      -0.025026241317391396,
      -0.004690883215516806,
      -0.042881373316049576,
      0.0190171767026186,
      -0.007430914789438248,
      -0.03780079260468483,
      0.018599625676870346,
      -0.027698108926415443,
      0.003494506236165762,
      -0.03197438269853592,
      -0.026746762916445732,
      -0.031089773401618004,
      0.0715617910027504,
      0.03719041869044304,
      0.03047238476574421,
      -0.016618043184280396,
      0.0018294769106432796,
      -0.017083756625652313,
      -0.015693388879299164,
      -0.006965307984501123,
      -0.00859539583325386,
      0.07048813998699188,
      0.05731372535228729,
      -0.019379569217562675,
      0.051369715481996536,
      0.019529137760400772,
      -0.001514294883236289,
      0.04323786869645119,
      -0.011260001920163631,
      0.02639666758477688,
      0.038159895688295364,
      0.018898654729127884,
      0.07174233347177505,
      0.01947813853621483,
      0.034265708178281784,
      -0.1004972979426384,
      -0.0558665469288826,
      -0.050312042236328125,
      -0.020711947232484818,
      -0.058504119515419006,
      0.037319254130125046,
      0.0036866338923573494,
      0.0321100689470768,
      -0.051525089889764786,
      0.01567663438618183,
      -0.008155694231390953,
      -0.01798485592007637,
      -0.006190768908709288,
      0.03390727564692497,
      -0.11094781011343002,
      -0.06153516843914986,
      -0.0488881878554821,
      -0.023213235661387444,
      0.050576966255903244,
      0.012954684905707836,
      -0.12035156786441803,
      -0.015816662460565567,
      0.03921433165669441,
      -0.003602617187425494,
      0.056508224457502365,
      0.05716633424162865,
      0.0015994674758985639,
      0.05305305868387222,
      -0.16395345330238342,
      -0.01380910910665989,
      -0.04981761798262596,
      0.04190083593130112,
      -0.02387884445488453,
      -0.018092218786478043,
      -0.03810172528028488,
      -0.05264155566692352,
      -0.06454111635684967,
      -0.1419677436351776,
      -0.006327401380985975,
      -0.014366595074534416,
      0.03205559775233269,
      0.028314735740423203,
      -0.008739725686609745,
      0.009585852734744549,
      0.06329913437366486,
      -0.020212417468428612,
      -0.03362276405096054,
      -0.0018167289672419429,
      0.06914929300546646,
      0.04790179431438446,
      -0.040860097855329514,
      -0.011180214583873749,
      -0.047122273594141006,
      0.06830053776502609,
      -0.0077851139940321445,
      -0.05123153328895569,
      -0.015368105843663216,
      -0.037855248898267746,
      0.03464950993657112,
      -0.00038035071338526905,
      -0.001004849560558796,
      -0.019974999129772186,
      0.0244227834045887,
      0.035116635262966156,
      0.034992117434740067,
      -0.07113876938819885,
      0.04096602275967598,
      0.019174158573150635,
      0.0034522463101893663,
      -0.054382022470235825,
      0.08626403659582138,
      0.0063583035953342915,
      0.005839289166033268,
      -0.037710126489400864,
      0.004896892234683037,
      0.0026115982327610254,
      -0.04249585419893265,
      -0.012839817441999912,
      0.048205114901065826,
      0.029937587678432465,
      0.03303126245737076,
      0.01931716501712799,
      -0.03852427005767822,
      0.020159222185611725,
      0.007602600380778313,
      0.07932811230421066,
      0.04876626655459404,
      0.07486731559038162,
      -0.004436586517840624,
      -0.024929923936724663,
      0.008172263391315937,
      0.04231660068035126,
      -0.03741469606757164,
      0.028964275494217873,
      -0.009173689410090446,
      0.012424069456756115,
      0.00017438076611142606,
      -0.01181618683040142,
      0.02728363685309887,
      0.08477257192134857,
      0.034607745707035065,
      -0.04574458301067352,
      -0.06452465057373047,
      0.013509108684957027
    ],
    [
      -0.039221227169036865,
      0.05641617625951767,
      0.03319726139307022,
      -0.049737364053726196,
      -0.05826621875166893,
      -0.014647647738456726,
      0.00182250514626503,
      -0.03678867965936661,
      0.023702504113316536,
      -0.03101653978228569,
      -0.0015985434874892235,
      -0.02156531624495983,
      -0.03771210089325905,
      0.0016591842286288738,
      0.005327743478119373,
      -0.03652075305581093,
      0.023380806669592857,
      0.018822001293301582,
      -0.027776967734098434,
      0.026401275768876076,
      0.03960351645946503,
      0.10584057122468948,
      0.05233559384942055,
      -0.008082515560090542,
      0.01480557769536972,
      -0.002378035569563508,
      0.08425591886043549,
      -0.025624074041843414,
      -0.007091390900313854,
      0.029043305665254593,
      -0.04050938040018082,
      0.02457721158862114,
      -0.019625907763838768,
      0.0006075340206734836,
      0.0007461552741006017,
      -0.10425792634487152,
      0.014144254848361015,
      0.026086844503879547,
      -0.1349254995584488,
      -0.039086803793907166,
      0.002150836167857051,
      -0.08768428862094879,
      0.010784229263663292,
      -0.028262494131922722,
      0.0309864804148674,
      0.015984218567609787,
      -0.044754162430763245,
      -0.05463336035609245,
      0.008231572806835175,
      -0.029144378378987312,
      -0.010696769692003727,
      -0.0517411008477211,
      -0.026564650237560272,
      0.04313795641064644,
      -0.04216114431619644,
      0.004425634630024433,
      0.009358731098473072,
      0.006573149468749762,
      0.07518718391656876,
      -0.0284412931650877,
      -0.12441766262054443,
      0.01623719185590744,
      0.0213038120418787,
      0.059497907757759094,
      0.053796615451574326,
      0.009188489988446236,
      -0.01001338753849268,
      0.022926265373826027,
      -0.00935413222759962,
      -0.03130180761218071,
      -0.05045554041862488,
      0.010458624921739101,
      -0.0039885155856609344,
      0.057110466063022614,
      -0.053155165165662766,
      0.062338922172784805,
      -0.04046088457107544,
      0.06302493810653687,
      0.055813319981098175,
      -0.0004616024671122432,
      -0.020192939788103104,
      0.06193637102842331,
      0.022038059309124947,
      -0.0581810437142849,
      0.03165404126048088,
      -0.017281100153923035,
      0.05084674805402756,
      -0.012088572606444359,
      -0.06728127598762512,
      0.04581809043884277,
      0.009569494053721428,
      -0.0864684209227562,
      -0.06258512288331985,
      -0.009898834861814976,
      -0.028384791687130928,
      0.05882542207837105,
      0.010509030893445015,
      0.005841850768774748,
      0.04823226109147072,
      0.05386103317141533,
      0.0550457127392292,
      0.014919501729309559,
      0.028180396184325218,
      -0.055837322026491165,
      0.06314712762832642,
      -0.005588649306446314,
      -0.022208821028470993,
      0.060242604464292526,
      0.006296160630881786,
      0.0772002637386322,
      0.007481336127966642,
      -0.03318393602967262,
      0.05923447385430336,
      0.04493347555398941,
      0.011454589664936066,
      0.029554829001426697,
      -0.03299296647310257,
      -0.001712554250843823,
      0.013420100323855877,
      -0.029692409560084343,
      0.04122472554445267,
      -0.017945565283298492,
      0.040434207767248154,
      0.080546535551548,
      -0.01508212462067604,
      -0.03162476420402527,
      0.0640847384929657,
      0.03440820425748825,
      -0.035789620131254196,
      -0.07820502668619156,
      -0.027011044323444366,
      0.057463664561510086,
      -0.04033534601330757,
      -0.09777066856622696,
      0.02921340800821781,
      0.020939894020557404,
      0.019792012870311737,
      0.014218981377780437,
      -0.014712570235133171,
      -0.08373843133449554,
      0.036217670887708664,
      -0.11132612824440002,
      0.01535749901086092,
      -0.0108407624065876,
      0.02619205228984356,
      -0.007702984381467104,
      0.0771787092089653,
      -0.07487057149410248,
      -0.003351364051923156,
      -0.038475386798381805,
      0.07284072786569595,
      -0.061896052211523056,
      0.01446235179901123,
      -0.019542306661605835,
      0.03333083912730217,
      -0.02789114974439144,
      0.006880673114210367,
      -0.027252644300460815,
      0.04599399492144585,
      -0.010508038103580475,
      -0.00024236344324890524,
      0.007182635832577944,
      -0.006977418903261423,
      0.07789146900177002,
      0.03883938491344452,
      -0.023852864280343056,
      0.0297007504850626,
      -0.01889003813266754,
      -0.04769730940461159,
      -0.00547040393576026,
      -0.05934484675526619,
      -0.05150054395198822,
      -0.0196636151522398,
      0.045117929577827454,
      -0.04802422225475311,
      -0.001487867091782391,
      0.08084533363580704,
      -0.028625469654798508,
      0.07514925301074982,
      -0.03447287529706955,
      0.031018689274787903,
      -0.11289286613464355,
      0.046779703348875046,
      -0.05658255144953728,
      0.02861185185611248,
      -0.018313908949494362,
      -0.02165350690484047,
      -0.08461710065603256,
      0.03957479074597359,
      0.02444671466946602,
      0.002306161681190133,
      -0.040349312126636505,
      -0.05827269330620766,
      -0.016985265538096428,
      0.02430134452879429,
      0.02503720298409462,
      0.04056248068809509,
      0.020774628967046738,
      0.058611076325178146,
      -0.030542360618710518,
      0.05267352983355522,
      -0.017490921542048454,
      -0.04205753281712532,
      -0.04191005229949951,
      -0.06413450092077255,
      -0.062453292310237885,
      0.046092208474874496,
      -0.014820787124335766,
      0.011582225561141968,
      0.004561710637062788,
      0.03981931880116463,
      0.01351723913103342,
      -0.006948849186301231,
      0.040695104748010635,
      -0.002817718544974923,
      -0.09287165105342865,
      -0.03220990300178528,
      -0.009317033924162388,
      0.02663220651447773,
      0.01145341619849205,
      0.0027457368560135365,
      -0.08335131406784058,
      -0.013339512050151825,
      -0.00024611919070594013,
      -0.001732216915115714,
      -0.018243458122015,
      0.01729404740035534,
      0.05295315012335777,
      0.024972805753350258,
      -0.0040420060977339745,
      0.03654930740594864,
      0.007914860732853413,
      -0.03446578606963158,
      -0.011572492308914661,
      0.03310404345393181,
      0.030917422845959663,
      0.038888294249773026,
      -0.05513014644384384,
      -0.01715697906911373,
      -0.008364259265363216,
      -0.06374724209308624,
      -0.006060915533453226,
      -0.015317382290959358,
      -0.050506386905908585,
      -0.031279340386390686,
      0.010519745759665966,
      0.03907015919685364,
      0.020846493542194366,
      -0.03020828403532505,
      0.0068672336637973785,
      -0.03757493942975998,
      0.0548526756465435,
      -0.012752147391438484,
      -0.0388173907995224,
      -0.06844648718833923,
      0.0037819738499820232,
      0.0370388962328434,
      -0.07699623703956604,
      -0.048373907804489136,
      0.08342888951301575,
      -0.044176939874887466,
      -0.009793994016945362,
      -0.012526636011898518,
      -0.08182578533887863,
      0.08415823429822922,
      0.012585950084030628,
      0.02404315024614334,
      -0.01367550902068615,
      0.06826148182153702,
      0.06567159295082092,
      -0.07897543907165527,
      0.07662564516067505,
      -0.02648860588669777,
      -0.052335262298583984,
      0.009125438518822193,
      0.019902590662240982,
      -0.008853049948811531,
      -0.037814103066921234,
      -0.03426361829042435,
      0.05489400774240494,
      -0.02094380185008049,
      -0.0921722874045372,
      -0.08380521088838577,
      0.01066977996379137,
      -0.008119519799947739,
      0.01410556398332119,
      0.0320151150226593,
      0.02219119854271412,
      0.018732154741883278,
      -0.0544174462556839,
      -0.07328184694051743,
      -0.0046697198413312435,
      0.011948486790060997,
      -0.012542842887341976,
      -0.033625341951847076,
      0.03174237906932831,
      -0.0006751799373887479,
      -0.014249376021325588,
      -0.07500016689300537,
      -0.020275970920920372,
      0.005687047261744738,
      0.022176112979650497,
      0.014473147690296173,
      -0.041312187910079956,
      0.030463892966508865,
      -0.03523814678192139,
      0.015040646307170391,
      0.016553428024053574,
      -0.1081044152379036,
      -0.09170088917016983,
      -0.0013182545080780983,
      -0.06729545444250107,
      -0.05397694557905197,
      0.0026374037843197584,
      -0.014302005991339684,
      -0.013524666428565979,
      -0.017638370394706726,
      0.02731366828083992,
      -0.012064865790307522,
      -0.02098783478140831,
      -0.04604914411902428,
      -0.039679352194070816,
      -0.02298142947256565,
      0.03838062286376953,
      0.014921468682587147,
      0.02082763984799385,
      -0.018781661987304688,
      -0.032172951847314835,
      0.08822411298751831,
      0.07626093178987503,
      -0.0017681652680039406,
      0.06110500171780586,
      -0.0018290996085852385,
      -0.04210610315203667,
      -0.005355852656066418,
      0.02977079525589943,
      0.00218521011993289,
      0.03673015907406807,
      0.09273268282413483,
      -0.06446287781000137,
      -0.06943535804748535,
      -0.002627706853672862,
      0.03632540628314018,
      0.036225877702236176,
      0.014615194872021675,
      -0.08783909678459167,
      0.030568009242415428,
      -0.07335215061903,
      0.012918444350361824,
      -0.0293801948428154,
      -0.027590634301304817,
      -0.007976863533258438,
      -0.023783285170793533,
      0.05932343378663063,
      -0.05147812142968178,
      0.031766656786203384,
      0.02982618845999241,
      0.05114128813147545,
      0.027491189539432526,
      0.05208571255207062,
      -0.008810404688119888,
      0.053993042558431625,
      -0.018144115805625916,
      0.004797128029167652,
      0.028728699311614037,
      0.056069985032081604,
      0.06390975415706635,
      0.0022073229774832726,
      0.005091454833745956,
      -0.08043667674064636,
      -0.01474953442811966,
      0.03166147693991661,
      0.06121288612484932,
      -0.02622586116194725,
      0.0034667986910790205,
      -0.015875011682510376,
      0.01598447561264038,
      0.018162213265895844,
      0.047477852553129196,
      -0.03747357800602913,
      -0.03219420835375786,
      0.010273521766066551,
      0.04096435382962227,
      -0.05189397931098938,
      -0.03336877003312111,
      -0.04161645472049713,
      -0.060085952281951904,
      -0.05500268191099167,
      0.037717606872320175,
      0.060178883373737335,
      -0.049229688942432404,
      0.033299535512924194,
      0.04678710550069809,
      -0.020478826016187668,
      -0.04926152154803276,
      0.015681080520153046,
      0.005556289106607437,
      -0.008149787783622742,
      0.0029070591554045677,
      0.04824552685022354,
      0.005437758285552263,
      0.022688843309879303,
      -0.01673106476664543,
      -0.036467377096414566,
      -0.0019768965430557728,
      -0.05780291557312012,
      -0.03856053203344345,
      -0.03023359179496765,
      0.03039310686290264,
      0.05914709344506264,
      -0.02808532863855362,
      -0.052152205258607864,
      0.028283463791012764,
      0.060363318771123886,
      -0.004751667380332947,
      -0.029588188976049423,
      0.13767918944358826,
      -0.03896722197532654,
      0.00014884046686347574,
      -0.031048092991113663,
      -0.010114606469869614,
      0.07871577143669128,
      0.0164940282702446,
      0.01976054534316063,
      0.01335989497601986,
      -0.06961905211210251,
      -0.0447428897023201,
      -0.07957246154546738,
      0.030452517792582512,
      0.0502648763358593,
      -0.01640629582107067,
      0.03143656998872757,
      -0.0022710650227963924,
      0.0391588918864727,
      -0.002525750547647476,
      -0.012885111384093761,
      0.04871964082121849,
      0.010037840344011784,
      0.002695741830393672,
      0.025240110233426094,
      0.04491112753748894,
      0.01371015701442957,
      0.0033007762394845486,
      0.032433632761240005,
      -0.062049541622400284,
      0.07667939364910126,
      -0.012778280302882195,
      0.049174387007951736,
      0.07677052915096283,
      -0.08405202627182007,
      -0.03133746609091759,
      0.015593117102980614,
      0.05324798449873924,
      0.007955390959978104,
      -0.0034396506380289793,
      -0.11620412021875381,
      -0.04919786378741264,
      0.015001689083874226,
      0.03866719827055931,
      0.020220953971147537,
      -0.022336648777127266,
      0.11423245817422867,
      0.03888319432735443,
      0.03905884549021721,
      0.04457901790738106,
      -0.01210915669798851,
      0.010645504109561443,
      0.050667840987443924,
      -0.0077235815115273,
      -0.0573660284280777,
      0.03398756682872772,
      0.02467399463057518,
      -0.12356752157211304,
      -0.01637912541627884,
      -0.03431091085076332,
      -0.0075260852463543415,
      0.03659673407673836,
      -0.017284121364355087,
      -0.06785817444324493,
      0.010392347350716591,
      0.0581514909863472,
      -0.026739932596683502,
      0.042836666107177734,
      0.003395312000066042,
      -0.09110238403081894,
      0.07822299748659134,
      -0.015159732662141323,
      -0.05511758476495743,
      0.04652857407927513,
      0.10196952521800995,
      0.016051536425948143,
      -0.054793987423181534,
      0.07661161571741104,
      -0.0009075860143639147,
      0.007206875830888748,
      0.008024211041629314,
      0.0296343844383955,
      -0.015024853870272636,
      -0.049838729202747345,
      0.018967049196362495,
      -0.03414330631494522,
      0.04326053336262703,
      0.08114676177501678
    ],
    [
      -0.020638104528188705,
      0.047320831567049026,
      -0.05211435630917549,
      0.016269726678729057,
      -0.009061076678335667,
      0.037818990647792816,
      -0.022728033363819122,
      -0.0813242495059967,
      0.0016400087624788284,
      0.026262693107128143,
      -0.015788720920681953,
      0.004560843575745821,
      -0.05449383705854416,
      -0.013732538558542728,
      0.04073832556605339,
      0.03684823215007782,
      -0.00809581857174635,
      0.022412709891796112,
      0.13098888099193573,
      -0.032510049641132355,
      0.02022915706038475,
      0.05810604989528656,
      0.027051903307437897,
      -0.10229331254959106,
      -0.004278289619833231,
      0.011730881407856941,
      0.031236354261636734,
      0.03078865259885788,
      0.06285480409860611,
      0.020414739847183228,
      0.011105131357908249,
      0.008906475268304348,
      -0.040563710033893585,
      -0.06013750284910202,
      0.022635892033576965,
      0.033093154430389404,
      -0.03217209503054619,
      0.03916991129517555,
      -0.0005038080271333456,
      -0.02437658980488777,
      -0.002430250635370612,
      -0.005989446770399809,
      -0.052308667451143265,
      -0.011758700013160706,
      0.011198177002370358,
      -0.10060606151819229,
      -0.04929063841700554,
      -0.0021892464719712734,
      0.014556331560015678,
      -0.0021084025502204895,
      -0.029010575264692307,
      -0.0351918488740921,
      -0.013723551295697689,
      -0.0018255255417898297,
      -0.001538690528832376,
      0.020403051748871803,
      -0.057735443115234375,
      0.036299850791692734,
      -0.03426440805196762,
      0.048618901520967484,
      0.0030480946879833937,
      0.0012908712960779667,
      -0.0075119128450751305,
      0.01715451292693615,
      0.017339084297418594,
      0.010026264004409313,
      0.04598388448357582,
      0.06613830476999283,
      0.010542185977101326,
      0.05033322051167488,
      0.03333915024995804,
      0.018278339877724648,
      0.020737189799547195,
      0.03432285785675049,
      0.06605270504951477,
      -0.006296214647591114,
      0.023267308250069618,
      0.05117173492908478,
      0.0404663160443306,
      0.006169253494590521,
      0.06441205739974976,
      0.04828233644366264,
      0.05368000641465187,
      0.01784130558371544,
      -0.005177763290703297,
      0.038857653737068176,
      0.0024409943725913763,
      -0.03974515572190285,
      -0.05936354026198387,
      0.009571773931384087,
      -0.0020042534451931715,
      0.08264435082674026,
      -0.11803211271762848,
      0.0357440821826458,
      0.012350527569651604,
      0.07812128961086273,
      -0.10326392948627472,
      0.05895239859819412,
      0.046557530760765076,
      0.09510841220617294,
      0.007100503426045179,
      0.0459439791738987,
      -0.03104354813694954,
      -0.019523106515407562,
      -0.048076216131448746,
      -0.010662340559065342,
      0.07356595247983932,
      0.09587632119655609,
      0.06936140358448029,
      -0.030082084238529205,
      -0.0046632313169538975,
      -0.0195184163749218,
      -0.019132466986775398,
      0.02846032939851284,
      0.019932474941015244,
      -0.01624547503888607,
      0.02438425086438656,
      0.033333420753479004,
      -0.018365031108260155,
      -0.001981058856472373,
      -0.07138562202453613,
      -0.0032265910413116217,
      -0.09722716361284256,
      -0.011600259691476822,
      -0.047007009387016296,
      -0.07263630628585815,
      0.041760727763175964,
      0.0070240069180727005,
      0.05423101410269737,
      0.014123987406492233,
      -0.03783188760280609,
      0.05343332886695862,
      -0.022442089393734932,
      -0.06623630970716476,
      -0.013855751603841782,
      0.01895025558769703,
      -0.06547524780035019,
      0.09965015947818756,
      -0.049087803810834885,
      0.01792771741747856,
      -0.03608683496713638,
      -0.02521887794137001,
      0.0152761060744524,
      0.09420555084943771,
      -0.08321741223335266,
      -0.04239996150135994,
      0.03887498378753662,
      -0.0008664066554047167,
      0.004325198009610176,
      0.008059731684625149,
      0.038687385618686676,
      -0.026000000536441803,
      -0.08198697865009308,
      -0.016081925481557846,
      -0.0499555878341198,
      -0.07764903455972672,
      0.030708180740475655,
      -0.05832425132393837,
      -0.051192641258239746,
      0.07086572796106339,
      0.0017884968547150493,
      0.019888505339622498,
      -0.039685096591711044,
      -0.004139157012104988,
      -0.02710447832942009,
      -0.006415211129933596,
      0.040141962468624115,
      0.07034614682197571,
      0.043235380202531815,
      0.008266073651611805,
      -0.07265015691518784,
      -0.07179556787014008,
      0.0413634330034256,
      -0.06548868119716644,
      -0.008383647538721561,
      -0.01996791362762451,
      0.073580801486969,
      -0.005481997970491648,
      -0.004780859220772982,
      0.06032121554017067,
      0.024317804723978043,
      -0.008629248477518559,
      -0.020073214545845985,
      -0.012215180322527885,
      -0.0342547744512558,
      -0.006669302936643362,
      -0.034951478242874146,
      -0.06027916446328163,
      -0.029557200148701668,
      -0.061979930847883224,
      0.03589153662323952,
      -0.06367525458335876,
      -0.016971305012702942,
      0.022304385900497437,
      0.06442492455244064,
      -0.004289848729968071,
      0.04264487326145172,
      -0.04344206303358078,
      -0.006393564399331808,
      0.0704740434885025,
      -0.009140798822045326,
      0.0025644879788160324,
      0.1138879582285881,
      0.01306996215134859,
      -0.04444282874464989,
      0.0722375214099884,
      -0.0355645976960659,
      0.050795335322618484,
      -0.042452409863471985,
      -0.045812398195266724,
      0.026992691680788994,
      -0.00905761681497097,
      0.04277150705456734,
      0.04388183355331421,
      -0.06484081596136093,
      0.09557123482227325,
      -0.0011507580056786537,
      0.030982524156570435,
      -0.03830599784851074,
      -0.0295852143317461,
      -0.007875625975430012,
      -0.020227503031492233,
      -0.04041494429111481,
      -0.01578766666352749,
      0.024525903165340424,
      0.15848849713802338,
      0.04191826283931732,
      0.030689416453242302,
      0.06515561044216156,
      0.05448485165834427,
      -0.006012829951941967,
      0.06864986568689346,
      -0.04748984053730965,
      -0.026864461600780487,
      -0.05339830741286278,
      0.042349956929683685,
      -0.053892359137535095,
      0.007002184633165598,
      0.10615330189466476,
      0.0673958957195282,
      -0.03659883514046669,
      0.013440342620015144,
      0.04545187950134277,
      -0.015760311856865883,
      0.01898936741054058,
      -0.006097041070461273,
      -0.028213221579790115,
      -5.84097251703497e-05,
      0.011179400607943535,
      -0.0017516937805339694,
      0.03793184831738472,
      -0.03083823062479496,
      0.024778204038739204,
      -0.027650225907564163,
      -0.061281539499759674,
      0.02996819280087948,
      -0.008639250881969929,
      0.012848745100200176,
      -0.022697458043694496,
      -0.017533190548419952,
      -0.08877851814031601,
      0.04450496658682823,
      -0.011189492419362068,
      -0.014685267582535744,
      -0.02378760650753975,
      -0.010014212690293789,
      -0.05444367602467537,
      0.06254135817289352,
      -0.039833053946495056,
      -0.012835419736802578,
      -0.0979158952832222,
      0.08687837421894073,
      0.0244109109044075,
      -0.008549499325454235,
      -0.020650997757911682,
      -0.044624797999858856,
      0.0010081477230414748,
      0.007011436857283115,
      -0.11561416834592819,
      0.060879725962877274,
      0.09895578026771545,
      -0.08903511613607407,
      0.007204898167401552,
      -0.00993643794208765,
      0.046429067850112915,
      -0.0383097343146801,
      -0.026449769735336304,
      -0.058710988610982895,
      -0.02991042472422123,
      -0.056505318731069565,
      -0.04949871450662613,
      0.030610835179686546,
      0.033637676388025284,
      -0.04027128964662552,
      -0.061425186693668365,
      -0.05027199164032936,
      0.02951759472489357,
      -0.011462235823273659,
      -0.012632637284696102,
      -0.04163380712270737,
      0.020776525139808655,
      -0.012823529541492462,
      -0.017977099865674973,
      -0.054561320692300797,
      0.009163959883153439,
      -0.059911470860242844,
      0.009483655914664268,
      -0.01029632892459631,
      0.03297270089387894,
      -0.04854907840490341,
      0.079600989818573,
      -0.0674944743514061,
      -0.002433505142107606,
      -0.004910746123641729,
      -0.04026584327220917,
      0.019987639039754868,
      0.10873305797576904,
      -0.017308231443166733,
      0.02777921035885811,
      -0.030815541744232178,
      0.0029366479720920324,
      0.006360297091305256,
      -0.019171563908457756,
      0.020052088424563408,
      0.07411327958106995,
      -0.014010971412062645,
      0.037155620753765106,
      -0.025998638942837715,
      0.07371889799833298,
      0.020817063748836517,
      0.011984358541667461,
      0.004078097175806761,
      -0.01649695821106434,
      0.014406573958694935,
      0.02222740463912487,
      -0.002800969872623682,
      -0.0020089081954210997,
      0.06821595877408981,
      -0.003612095955759287,
      0.026298711076378822,
      -0.05222807824611664,
      0.08924296498298645,
      0.04030423238873482,
      -0.04131002351641655,
      -0.016089022159576416,
      -0.014398345723748207,
      0.05213936045765877,
      -0.09088165313005447,
      -0.02765647880733013,
      -0.03289748728275299,
      0.0068763503804802895,
      0.07037939876317978,
      -0.03521668165922165,
      -0.08623703569173813,
      0.005162491463124752,
      0.04953094944357872,
      0.011697208508849144,
      0.0005864283302798867,
      -0.009855864569544792,
      0.03020557016134262,
      0.014842797070741653,
      -0.0327775739133358,
      -0.01070500910282135,
      -0.0516214519739151,
      0.06920239329338074,
      0.01284030918031931,
      -0.03347865492105484,
      0.03558934107422829,
      0.03391150012612343,
      0.057336319237947464,
      0.04808773100376129,
      -0.05707266926765442,
      0.0030375688802450895,
      -0.04495722055435181,
      0.04704993963241577,
      0.06839162856340408,
      0.031448494642972946,
      -0.005828178953379393,
      -0.03129420801997185,
      -0.03450394421815872,
      0.014143521897494793,
      0.028276145458221436,
      -0.044447362422943115,
      -0.07188156992197037,
      0.08771096169948578,
      0.041484054177999496,
      -0.022481586784124374,
      -0.09909365326166153,
      0.059942010790109634,
      -0.0785314291715622,
      0.05507725104689598,
      0.010604009963572025,
      -0.031728439033031464,
      -0.05471831187605858,
      -0.05096176266670227,
      -0.024610446766018867,
      0.017001578584313393,
      0.05407462641596794,
      0.0031200770754367113,
      0.005959097295999527,
      0.06390322744846344,
      -0.013790960423648357,
      0.017422860488295555,
      0.03439553827047348,
      -0.002811963204294443,
      -0.034588757902383804,
      -0.06267678737640381,
      -0.06701722741127014,
      -0.010616318322718143,
      0.011912775225937366,
      0.027987221255898476,
      0.05557175353169441,
      -0.04189823940396309,
      0.03557327017188072,
      -0.023201122879981995,
      0.011002265848219395,
      -0.02232072874903679,
      -0.03963203728199005,
      0.06461836397647858,
      -0.10456252098083496,
      0.11302992701530457,
      0.051861174404621124,
      -0.049358341842889786,
      0.06991693377494812,
      -0.027133869007229805,
      -0.0478958860039711,
      0.0063805473037064075,
      -0.038407448679208755,
      -0.020039454102516174,
      0.025662271305918694,
      -0.06761284172534943,
      0.01892371103167534,
      0.06320492923259735,
      0.015377863310277462,
      -0.016475193202495575,
      0.06080525368452072,
      -0.03368796408176422,
      0.03921973332762718,
      0.012695672921836376,
      0.030557241290807724,
      -0.06404484063386917,
      0.07165908813476562,
      -0.02934904210269451,
      -0.03273094817996025,
      -0.07959414273500443,
      0.00045824822154827416,
      -0.007606235798448324,
      0.021306775510311127,
      0.0037111202254891396,
      -0.06002116948366165,
      0.009343430399894714,
      0.04459284991025925,
      0.1126287430524826,
      0.00045911537017673254,
      0.003271637484431267,
      0.031665995717048645,
      -0.004782841075211763,
      -0.055381886661052704,
      -0.035920049995183945,
      0.056456275284290314,
      0.007841859944164753,
      0.07660914957523346,
      0.04665350168943405,
      0.1104942336678505,
      0.021114811301231384,
      -0.013569875620305538,
      -0.017607027664780617,
      0.0326777882874012,
      0.0012987818336114287,
      -0.031441427767276764,
      0.028673527762293816,
      -0.004689596593379974,
      -0.06853222101926804,
      0.039016395807266235,
      0.025305628776550293,
      -0.019646910950541496,
      0.03838857263326645,
      -0.11689868569374084,
      0.04144098609685898,
      -0.037658341228961945,
      -0.0028049026150256395,
      0.07512167096138,
      0.07068382203578949,
      0.015159516595304012,
      0.026990337297320366,
      -0.03574008122086525,
      0.060498129576444626,
      -0.05538853630423546,
      0.005630937870591879,
      0.019253814592957497,
      -0.01483537070453167,
      0.05352145805954933,
      0.043440330773591995,
      0.019694935530424118,
      0.015930281952023506,
      0.0978008434176445,
      0.005149776116013527,
      -0.021615127101540565,
      -0.022932136431336403,
      -0.00810310710221529,
      0.01335536316037178,
      -0.061726029962301254,
      0.004243974108248949
    ],
    [
      0.0353383831679821,
      0.010917221195995808,
      0.015496955253183842,
      -0.04593108594417572,
      0.000607520982157439,
      -0.033026181161403656,
      -0.011803820729255676,
      0.06922249495983124,
      -0.054388564079999924,
      0.07480768114328384,
      -0.04276068136096001,
      0.08563704788684845,
      -0.03706199303269386,
      0.005112056154757738,
      0.022651173174381256,
      0.030825328081846237,
      -0.024835508316755295,
      0.02691783383488655,
      0.013777495361864567,
      0.010745488107204437,
      -0.03846219927072525,
      0.0752987489104271,
      -0.047638244926929474,
      -0.018552133813500404,
      -0.042325496673583984,
      0.012063839472830296,
      0.0325954332947731,
      0.006686322391033173,
      0.021648256108164787,
      0.0032106004655361176,
      -0.06099949777126312,
      -0.05249502509832382,
      0.02278168313205242,
      -0.07790014147758484,
      -0.07605899125337601,
      0.0004832895356230438,
      -0.08125755935907364,
      -0.023798691108822823,
      0.02704915776848793,
      0.01133442111313343,
      0.015213513746857643,
      -0.0001231097267009318,
      -0.008618026971817017,
      -0.0007591145113110542,
      0.014677884057164192,
      -0.03883321210741997,
      -0.05590151622891426,
      0.026728728786110878,
      0.020330678671598434,
      0.028173506259918213,
      -0.01124503929167986,
      0.010098015889525414,
      -0.022675124928355217,
      0.03757810220122337,
      -0.02172211743891239,
      0.07073912769556046,
      -0.006300430279225111,
      0.017513416707515717,
      -0.017998140305280685,
      -0.009425109252333641,
      -0.014264536090195179,
      -0.05048956349492073,
      -0.053277600556612015,
      -0.016691431403160095,
      -0.06998390704393387,
      -0.06625616550445557,
      -0.04804133251309395,
      -0.024224504828453064,
      0.0064530991949141026,
      0.004923305008560419,
      0.02224227786064148,
      -0.07924248278141022,
      -0.025533681735396385,
      -0.030501779168844223,
      0.037868063896894455,
      0.05255662277340889,
      -0.04112080857157707,
      0.08632347732782364,
      0.08946547657251358,
      -0.023337382823228836,
      -0.018442941829562187,
      -0.002589185256510973,
      0.11687315255403519,
      -0.11741948872804642,
      0.07439567148685455,
      0.016323672607541084,
      -0.06191331893205643,
      -0.008424020372331142,
      -0.02525404468178749,
      -0.009927134960889816,
      -0.07915035635232925,
      0.014235788024961948,
      0.0914236530661583,
      0.033301450312137604,
      -0.03050275519490242,
      -0.025513138622045517,
      -0.01399653498083353,
      0.030249662697315216,
      0.04454013332724571,
      0.013195847161114216,
      0.045004624873399734,
      -0.028682231903076172,
      -0.021086618304252625,
      0.0310165174305439,
      -0.02268591895699501,
      -0.0221734419465065,
      -0.016570324078202248,
      -0.028755823150277138,
      0.045232489705085754,
      0.06838439404964447,
      0.015207787975668907,
      -0.06463075429201126,
      0.046762220561504364,
      -0.018309874460101128,
      0.05921313539147377,
      0.02523632161319256,
      0.003179074265062809,
      -0.019891465082764626,
      0.08265546709299088,
      -0.0018495252588763833,
      -0.01412422489374876,
      -0.04608779773116112,
      -0.05817649886012077,
      -0.05336015298962593,
      -0.07861387729644775,
      0.012326127849519253,
      0.03905048593878746,
      -0.05240955576300621,
      -0.02819765917956829,
      -0.00020571085042320192,
      0.04237804189324379,
      -0.05950659513473511,
      0.058668024837970734,
      -0.02692589908838272,
      0.03444584459066391,
      -0.07783643901348114,
      -0.006727598607540131,
      -0.07074207812547684,
      -0.011344647966325283,
      -0.061267342418432236,
      0.05238057300448418,
      0.014034338295459747,
      -0.06824474036693573,
      -0.07848461717367172,
      -0.0019314244855195284,
      -0.01301727257668972,
      0.0398416668176651,
      -0.0503038726747036,
      -0.034287385642528534,
      0.04720950126647949,
      -0.043639615178108215,
      -0.004850017372518778,
      0.04923180118203163,
      0.0819837898015976,
      -0.02428765781223774,
      0.04075925424695015,
      0.012973116710782051,
      0.06047583743929863,
      0.06831195950508118,
      0.02578047849237919,
      0.10924059897661209,
      0.05607990175485611,
      0.017325498163700104,
      0.003464482491835952,
      0.03421454876661301,
      -0.004705526866018772,
      0.03295302391052246,
      -0.0333128497004509,
      0.03233204409480095,
      0.0016092838486656547,
      0.011008840054273605,
      0.05040919780731201,
      -0.0054689375683665276,
      -0.02100651152431965,
      -0.015445813536643982,
      -0.02743135392665863,
      0.007266913540661335,
      -0.0170516949146986,
      -0.04287223517894745,
      -0.005888061132282019,
      -0.02731274627149105,
      -0.009043465368449688,
      0.07280230522155762,
      -0.018200531601905823,
      0.05116119608283043,
      0.03786338120698929,
      0.059539537876844406,
      0.03526422381401062,
      -0.026353614404797554,
      -0.030580751597881317,
      -0.00524695822969079,
      -0.06135273352265358,
      -0.05156970024108887,
      0.013846032321453094,
      -0.03251788020133972,
      0.05245335027575493,
      0.00852384977042675,
      -0.0031824917532503605,
      -0.08988989144563675,
      0.06126495450735092,
      0.009274679236114025,
      -0.11591587960720062,
      -0.041444942355155945,
      -0.00804741121828556,
      -0.028566911816596985,
      0.07048340141773224,
      0.03655747324228287,
      -0.05353577435016632,
      -0.045264460146427155,
      0.02243887260556221,
      0.007577262818813324,
      0.054940737783908844,
      0.01972743310034275,
      -0.04274260997772217,
      -0.04827949404716492,
      0.02945653721690178,
      0.014781906269490719,
      0.05246643349528313,
      -0.00471442611888051,
      -0.05786103010177612,
      -0.034642402082681656,
      -0.0423140786588192,
      -0.014934295788407326,
      0.06343793869018555,
      0.0052682398818433285,
      0.03208319842815399,
      0.030718840658664703,
      -0.05169323831796646,
      0.0680161640048027,
      -0.0538518950343132,
      -0.010769366286695004,
      0.005939941853284836,
      -0.023487022146582603,
      -0.09379690140485764,
      -0.005595748778432608,
      0.020900815725326538,
      0.007411176338791847,
      -0.030693188309669495,
      -0.0429849736392498,
      0.12187856435775757,
      0.03282702714204788,
      -0.012595516629517078,
      0.04064299538731575,
      0.011893010698258877,
      -0.046914488077163696,
      0.11868837475776672,
      -0.04217404127120972,
      -0.03390054777264595,
      0.047461025416851044,
      -0.026705894619226456,
      -0.07640039175748825,
      0.023707173764705658,
      -0.02816968970000744,
      -0.03557918965816498,
      0.014152739197015762,
      0.010244777426123619,
      -0.0007494790479540825,
      -0.0342516154050827,
      0.023006228730082512,
      -0.08386901021003723,
      -0.06498445570468903,
      -0.03553205356001854,
      0.05276193842291832,
      0.0057539381086826324,
      0.08115454018115997,
      -0.018266968429088593,
      0.02514141984283924,
      0.0022375357802957296,
      -0.04236830398440361,
      0.07078637927770615,
      -0.03184306621551514,
      0.05827619135379791,
      -0.05457215756177902,
      -0.03175239637494087,
      -0.09327394515275955,
      -0.0541340671479702,
      0.011285113170742989,
      0.030057426542043686,
      0.05619928613305092,
      0.09926503896713257,
      0.01588672399520874,
      -0.017847653478384018,
      -0.0361957885324955,
      -0.052490487694740295,
      -0.07422354072332382,
      0.0029495551716536283,
      0.015127723105251789,
      0.013135608285665512,
      0.07372288405895233,
      0.10489591211080551,
      -0.059121496975421906,
      0.04337624832987785,
      -0.014290323480963707,
      0.0802798792719841,
      -0.0643608346581459,
      -0.004561940673738718,
      -0.023920690640807152,
      -0.007292659487575293,
      0.023015910759568214,
      0.03926105797290802,
      0.03774392604827881,
      -0.045836444944143295,
      0.052821848541498184,
      0.007478106766939163,
      -0.010353509336709976,
      0.07021841406822205,
      -0.050285704433918,
      -0.011018865741789341,
      0.07056796550750732,
      0.10159815847873688,
      0.05421410873532295,
      0.025093773379921913,
      0.007050449959933758,
      -0.009019172750413418,
      -0.08031951636075974,
      0.07576534897089005,
      -0.03450317680835724,
      -0.014957473613321781,
      -0.07467988133430481,
      -0.006385847460478544,
      0.04088335484266281,
      -0.09243190288543701,
      0.00940384529531002,
      0.030396822839975357,
      -0.038507089018821716,
      -0.019618801772594452,
      -0.012952498160302639,
      -0.10285605490207672,
      -0.046883586794137955,
      0.09423722326755524,
      0.029174599796533585,
      0.0029305133502930403,
      -0.019206561148166656,
      -0.026708701625466347,
      -0.09647758305072784,
      0.032486122101545334,
      -0.07089461386203766,
      0.12568657100200653,
      0.007330319844186306,
      -0.033848125487565994,
      0.023577284067869186,
      -0.01640932820737362,
      -0.03725414723157883,
      -0.0985620990395546,
      0.02522537298500538,
      -0.1012021079659462,
      0.042430777102708817,
      0.05992591008543968,
      0.07660278677940369,
      0.02914067544043064,
      -0.04860129952430725,
      -0.10597702860832214,
      0.019765064120292664,
      0.025421084836125374,
      -0.07117140293121338,
      -0.014888201840221882,
      -0.05383004993200302,
      -0.048037756234407425,
      0.034983932971954346,
      0.0037894686684012413,
      -0.040079597383737564,
      -0.061126064509153366,
      -0.01951112598180771,
      -0.06417332589626312,
      0.02550055831670761,
      -0.07487300038337708,
      0.020914755761623383,
      -0.038708459585905075,
      0.010823659598827362,
      0.10371453315019608,
      0.04783065989613533,
      -0.0006474492256529629,
      -0.06309682875871658,
      -0.012093105353415012,
      0.0394105464220047,
      -0.02333066239953041,
      0.04957284405827522,
      0.012711110524833202,
      -0.05735829845070839,
      0.04329974949359894,
      0.05161245912313461,
      0.009339503943920135,
      -0.039785236120224,
      -0.03560143709182739,
      0.07888147234916687,
      0.007163933478295803,
      -0.017881380394101143,
      0.007992696948349476,
      0.012649227865040302,
      0.02400009147822857,
      0.02924342080950737,
      0.03546329215168953,
      -0.02406909689307213,
      -0.017553426325321198,
      -0.051870692521333694,
      -0.05244939401745796,
      0.08176819980144501,
      0.020134977996349335,
      -0.031214876100420952,
      -0.042562831193208694,
      -0.12499640136957169,
      0.03366723284125328,
      0.02253507450222969,
      0.03637406602501869,
      -0.027333760634064674,
      -0.01475590467453003,
      -0.04102340340614319,
      -0.06030527129769325,
      -0.024086810648441315,
      0.033671651035547256,
      0.004189349245280027,
      -0.004610609728842974,
      0.01920514553785324,
      -0.09454923123121262,
      0.021723870187997818,
      0.08948297798633575,
      0.024901995435357094,
      0.04623813554644585,
      -0.02372095361351967,
      -0.048100728541612625,
      -0.0215457696467638,
      -0.0756252110004425,
      -0.03149421513080597,
      -0.0457477867603302,
      -0.048989150673151016,
      -0.017372379079461098,
      0.06814412027597427,
      -0.029451560229063034,
      0.03541230410337448,
      0.006493089720606804,
      0.03769320249557495,
      -0.0044781919568777084,
      0.0003051089006476104,
      0.08411453664302826,
      -0.07595732063055038,
      0.0623595267534256,
      -0.015717335045337677,
      -0.04074680432677269,
      0.04845166951417923,
      -0.02987753413617611,
      0.04940295219421387,
      -0.002819993533194065,
      -0.002270063618198037,
      0.08455746620893478,
      0.041098132729530334,
      -0.05405043065547943,
      0.007791606243699789,
      0.02787841111421585,
      0.025448201224207878,
      -0.032098595052957535,
      -0.006547102238982916,
      0.013450377620756626,
      0.11129490286111832,
      0.05327962338924408,
      -0.022200094535946846,
      -0.046397097408771515,
      -0.10846883803606033,
      -0.07941550016403198,
      0.059088900685310364,
      0.018669994547963142,
      -0.05420873686671257,
      0.009104335680603981,
      -0.010783187113702297,
      0.07298578321933746,
      -0.01696860045194626,
      -0.04719046130776405,
      0.042877644300460815,
      -0.011829828843474388,
      -0.01232131663709879,
      0.016075313091278076,
      -0.004365298897027969,
      -0.035170577466487885,
      0.07213512808084488,
      -0.037462711334228516,
      -0.023494498804211617,
      -0.012510428205132484,
      -0.027097946032881737,
      -0.015977688133716583,
      0.08936314284801483,
      0.031244846060872078,
      -0.046566933393478394,
      0.03821820765733719,
      -0.06709607690572739,
      -0.005398768465965986,
      -0.01630525477230549,
      -0.06488689035177231,
      0.04295596852898598,
      -0.08030815422534943,
      0.001461397623643279,
      0.03104024939239025,
      -0.03560088202357292,
      0.0016431411495432258,
      0.03776298463344574,
      -0.01409213338047266,
      -0.08878014236688614,
      0.024275194853544235,
      -0.052686017006635666,
      0.014417008496820927,
      0.05145527422428131,
      -0.01917007751762867,
      0.034034695476293564,
      0.016093693673610687,
      0.052538517862558365
    ],
    [
      -0.0071597350761294365,
      0.005723088514059782,
      0.00681742699816823,
      -0.008909611962735653,
      -0.025289306417107582,
      -0.012321257032454014,
      0.0905291736125946,
      0.03747839853167534,
      -0.1263994574546814,
      0.01692253351211548,
      -0.0002762672957032919,
      0.0531357116997242,
      0.020821813493967056,
      -0.0024557120632380247,
      0.0027637325692921877,
      -0.006724232342094183,
      0.011361388489603996,
      -0.0010369288502261043,
      -0.043652936816215515,
      -0.021896908059716225,
      -0.01579173468053341,
      0.06782266497612,
      -0.016696956008672714,
      -0.0652325451374054,
      0.08767643570899963,
      -0.08739675581455231,
      0.04544484242796898,
      0.08812467753887177,
      0.049255382269620895,
      0.12309179455041885,
      -0.04163196682929993,
      -0.005950608290731907,
      0.05339531973004341,
      -0.08445437252521515,
      -0.0027812551707029343,
      -0.05051602050662041,
      0.05289599671959877,
      -0.051857493817806244,
      0.028794338926672935,
      0.007199326530098915,
      -0.00016766175394877791,
      0.037471916526556015,
      -0.030579203739762306,
      0.0226658433675766,
      -0.03500445559620857,
      -0.025655323639512062,
      0.059022728353738785,
      -0.059754934161901474,
      -0.0041712624952197075,
      0.028730373829603195,
      0.09296483546495438,
      -0.03635258600115776,
      0.050611019134521484,
      0.10890697687864304,
      -0.0661427453160286,
      -0.005383746232837439,
      -0.004487308207899332,
      -0.089381143450737,
      0.05234028026461601,
      0.055086564272642136,
      -0.016788624227046967,
      0.046803928911685944,
      0.016749558970332146,
      -0.03570937365293503,
      -0.041787225753068924,
      0.03237132728099823,
      0.054163891822099686,
      0.02518540620803833,
      -0.04435422271490097,
      0.04356688633561134,
      0.013005021028220654,
      0.022062141448259354,
      -0.013368461281061172,
      -0.04864655062556267,
      -0.015337818302214146,
      0.027063312008976936,
      -0.01838710717856884,
      0.036628492176532745,
      0.013205788098275661,
      -0.023457907140254974,
      0.06725703924894333,
      0.011511598713696003,
      -0.00010020218178397045,
      0.091880664229393,
      0.021671658381819725,
      -0.002369463909417391,
      0.008867626078426838,
      0.02441076748073101,
      0.05317368358373642,
      -0.05771387740969658,
      -0.04193694517016411,
      0.05388009548187256,
      -0.010060766711831093,
      -0.0528523214161396,
      0.04100692272186279,
      -0.03641511872410774,
      -0.026464905589818954,
      0.017936253920197487,
      0.04006137698888779,
      -0.0018992854747921228,
      -0.07771476358175278,
      -0.06598926335573196,
      -0.06050034984946251,
      0.04921123385429382,
      -0.012615805491805077,
      -0.0037593087181448936,
      -0.02043708600103855,
      0.011274266988039017,
      0.011971455067396164,
      0.022832488641142845,
      0.014448728412389755,
      0.04789834842085838,
      0.002678566612303257,
      -0.09467942267656326,
      -0.011929924599826336,
      0.026825815439224243,
      -0.04927712678909302,
      -0.0004626357404049486,
      -0.01871461048722267,
      0.025160150602459908,
      0.021228529512882233,
      -0.021269965916872025,
      0.0014621770242229104,
      0.038520898669958115,
      0.001000952091999352,
      -0.013931302353739738,
      -0.02680767886340618,
      -0.06940822303295135,
      -0.04604159668087959,
      -0.08142292499542236,
      0.029786106199026108,
      0.010349759832024574,
      0.011834933422505856,
      0.02189822867512703,
      -0.012056147679686546,
      -0.03852435201406479,
      0.018796006217598915,
      -0.03417891263961792,
      -0.01241192128509283,
      0.02025827020406723,
      -0.11157840490341187,
      -0.06326641887426376,
      -0.006185133941471577,
      0.033616673201322556,
      -0.042042069137096405,
      0.03418392315506935,
      0.009605114348232746,
      -0.0157651174813509,
      0.03837104141712189,
      -0.07207135111093521,
      0.0597720704972744,
      -0.006575407925993204,
      -0.02851017564535141,
      0.020139463245868683,
      -0.015362999401986599,
      -0.03454411402344704,
      -0.020859114825725555,
      0.012658946216106415,
      -0.047723930329084396,
      0.028225159272551537,
      -0.03639756515622139,
      -0.11487390846014023,
      0.03010273538529873,
      0.04699619114398956,
      0.11554122716188431,
      -0.07522246986627579,
      -0.0035761604085564613,
      0.022260211408138275,
      0.006815524771809578,
      -0.11420377343893051,
      0.05858922004699707,
      0.027128783985972404,
      0.010039097629487514,
      0.010877205990254879,
      -0.06068766489624977,
      -0.017207855358719826,
      -0.032235365360975266,
      -0.03200910985469818,
      -0.011916525661945343,
      0.02428179606795311,
      -0.0005388420540839434,
      0.05032473802566528,
      -0.03867931291460991,
      -0.08851272612810135,
      0.017576733604073524,
      -0.00959585327655077,
      0.05617668852210045,
      -0.0033738783095031977,
      -0.044269707053899765,
      0.05510801449418068,
      -0.022065790370106697,
      0.04444589465856552,
      -0.02114112116396427,
      0.011521970853209496,
      0.00758039765059948,
      -0.05200623720884323,
      0.008046520873904228,
      -0.045132193714380264,
      -0.05521619692444801,
      0.05876608192920685,
      -0.005482811015099287,
      0.03806629404425621,
      0.04914410039782524,
      0.021801482886075974,
      -0.11199399828910828,
      0.02775631844997406,
      0.033437635749578476,
      -0.046974051743745804,
      -0.015301372855901718,
      0.04018273949623108,
      0.018906056880950928,
      0.0021189250983297825,
      -0.017924027517437935,
      -0.015261894091963768,
      0.0005783252418041229,
      0.033874813467264175,
      -0.06421835720539093,
      0.02914397604763508,
      0.04412144795060158,
      -0.06599029898643494,
      0.031420886516571045,
      0.03978933021426201,
      -0.023208152502775192,
      0.010457889176905155,
      -0.00421086186543107,
      -0.05824701860547066,
      -0.067327119410038,
      -0.0014690709067508578,
      0.12062985450029373,
      -0.06063341721892357,
      0.10625128448009491,
      0.04235541447997093,
      -0.04483469948172569,
      0.004242706578224897,
      -0.05164313316345215,
      0.005524465348571539,
      -0.039603304117918015,
      0.006535709835588932,
      -0.0042770239524543285,
      -0.0024382355622947216,
      0.06350377202033997,
      -0.05442250892519951,
      -0.006687213201075792,
      0.05431193485856056,
      0.060601335018873215,
      0.011639942415058613,
      0.03324221819639206,
      0.004420340061187744,
      -0.010986394248902798,
      -0.038501739501953125,
      -0.04276934638619423,
      0.04847501218318939,
      -0.047742702066898346,
      0.05350516363978386,
      -0.006769236642867327,
      -0.01981356553733349,
      -0.030319031327962875,
      -0.07896096259355545,
      0.02068147249519825,
      -0.05010359734296799,
      0.06760738790035248,
      0.0036988328211009502,
      -0.03585635498166084,
      -0.06129402667284012,
      0.08351137489080429,
      -0.062413059175014496,
      -0.024329032748937607,
      -0.015152934938669205,
      -0.029635686427354813,
      0.019335947930812836,
      -0.027385327965021133,
      0.046482231467962265,
      -0.034766145050525665,
      0.048918142914772034,
      -0.0391463004052639,
      0.025608761236071587,
      0.05206163972616196,
      -0.03724445402622223,
      -0.02153162844479084,
      -0.04858868196606636,
      0.07077989727258682,
      0.09229335933923721,
      0.056939370930194855,
      -0.05263591557741165,
      -0.12558168172836304,
      -0.12720490992069244,
      0.04640569910407066,
      -0.008463786914944649,
      0.023472676053643227,
      0.028950398787856102,
      -0.03573193773627281,
      0.05917052552103996,
      -0.03486930951476097,
      -0.03553757444024086,
      0.05369889736175537,
      -0.0038177594542503357,
      0.004553159233182669,
      -0.07212445139884949,
      -0.015761766582727432,
      0.03890357166528702,
      -0.02371698059141636,
      -0.000991590553894639,
      0.040476713329553604,
      0.009177063591778278,
      -0.004152009263634682,
      0.06930804252624512,
      -0.022289054468274117,
      0.10239622741937637,
      0.0038569956086575985,
      -0.011120455339550972,
      0.01090287696570158,
      0.008234693668782711,
      -0.04162800312042236,
      0.023264752700924873,
      0.07299578934907913,
      0.06151927262544632,
      -0.018298188224434853,
      -0.016295000910758972,
      0.04422973468899727,
      -0.03890793398022652,
      0.04778588190674782,
      -0.021999269723892212,
      -0.006962193641811609,
      0.09858211129903793,
      -0.0597057119011879,
      -0.03584393113851547,
      -0.001252953428775072,
      -0.05183253064751625,
      0.013429846614599228,
      0.06869559735059738,
      -0.06140269711613655,
      0.027042219415307045,
      -0.03480951860547066,
      0.007823438383638859,
      0.08961088955402374,
      0.0158481877297163,
      0.07396651059389114,
      -0.016952814534306526,
      -0.04206528514623642,
      -0.021414538845419884,
      -0.05869929865002632,
      0.011162965558469296,
      0.027868784964084625,
      -0.007531547453254461,
      0.007975337095558643,
      -0.008534413762390614,
      0.04479993134737015,
      0.010818290524184704,
      0.07935456931591034,
      -0.04608827456831932,
      -0.00020607122860383242,
      0.016318120062351227,
      0.013432861305773258,
      0.018963327631354332,
      0.031058531254529953,
      0.019084079191088676,
      0.06612794101238251,
      0.042370740324258804,
      0.010865082032978535,
      0.047595903277397156,
      -0.028600456193089485,
      0.0076563553884625435,
      0.03388627618551254,
      -0.03741731122136116,
      0.007608609739691019,
      -0.018509045243263245,
      0.004082299303263426,
      0.004646895453333855,
      -0.05520804226398468,
      0.06715963035821915,
      -0.004669984336942434,
      0.020881865173578262,
      -0.013153739273548126,
      0.027738599106669426,
      0.05723642185330391,
      0.01157058123499155,
      -0.04766663536429405,
      0.03756926581263542,
      0.06346298009157181,
      -0.03209387883543968,
      -0.0718158483505249,
      -0.029995767399668694,
      0.04785173758864403,
      -0.022364940494298935,
      -0.012738858349621296,
      -0.02275547757744789,
      0.03681875020265579,
      -0.0127233462408185,
      0.03709404543042183,
      -0.06379394978284836,
      -0.05385579541325569,
      -0.006307840347290039,
      -0.037200022488832474,
      0.017802754417061806,
      0.020395589992403984,
      -0.07519378513097763,
      -0.02120903693139553,
      0.0013573042815551162,
      0.05189824849367142,
      -0.04914969578385353,
      0.038518648594617844,
      -0.05849945917725563,
      0.022508811205625534,
      -0.01626361720263958,
      -0.02952333353459835,
      0.011359808035194874,
      0.08802222460508347,
      -0.035701312124729156,
      0.05717998370528221,
      -0.06986457109451294,
      -0.03609423711895943,
      -0.011550630442798138,
      0.02459464967250824,
      -0.023136109113693237,
      0.04850197210907936,
      0.04799965023994446,
      -0.050435397773981094,
      0.04134668782353401,
      0.08317536115646362,
      5.044601857662201e-05,
      -0.009038364514708519,
      0.03949546068906784,
      -0.00533254211768508,
      -0.056501731276512146,
      0.06539138406515121,
      -0.0575178898870945,
      -0.040290717035532,
      -0.04627712815999985,
      -0.08899319171905518,
      0.01646493747830391,
      0.09173990786075592,
      0.05666476488113403,
      0.014043913222849369,
      -0.031819190829992294,
      -0.010194255970418453,
      -0.03324318304657936,
      -0.03449159860610962,
      0.006447663530707359,
      -0.01371857151389122,
      0.025700990110635757,
      0.022827595472335815,
      0.02075188420712948,
      -0.06749071925878525,
      -0.010095849633216858,
      -0.0639585629105568,
      -0.04268697276711464,
      0.06117943674325943,
      -0.007172577548772097,
      0.013533741235733032,
      0.029812881723046303,
      -0.037935275584459305,
      -0.04613664373755455,
      0.11755531281232834,
      0.07007648795843124,
      -0.009160012006759644,
      0.03001529537141323,
      0.004137284122407436,
      -0.005425028502941132,
      -0.004646977875381708,
      0.032480061054229736,
      -0.05483805015683174,
      -0.08172077685594559,
      0.017953308299183846,
      0.017289599403738976,
      0.03699801117181778,
      0.03136277198791504,
      -0.040016740560531616,
      0.08567459881305695,
      -0.04986383765935898,
      0.05690111592411995,
      0.05290932208299637,
      0.013020298443734646,
      0.011018342338502407,
      -0.006221694406121969,
      -0.062294263392686844,
      0.010097439400851727,
      -0.023113252595067024,
      -0.031114965677261353,
      -0.03744637221097946,
      -0.06924210488796234,
      -0.007229738403111696,
      0.003124142298474908,
      -0.0032649615313857794,
      -0.02605174109339714,
      -0.013722985982894897,
      0.013739131391048431,
      0.013103996403515339,
      -0.07778020948171616,
      0.05237527936697006,
      -0.0279207956045866,
      -0.013436347246170044,
      -0.04879126325249672,
      0.04495115950703621,
      0.02857779711484909,
      0.08868828415870667,
      0.03710847347974777,
      0.011392481625080109,
      -0.034303221851587296,
      0.0334211103618145,
      -0.09848594665527344,
      0.02195112220942974,
      -0.02160036191344261,
      -0.043715156614780426
    ],
    [
      0.03297989070415497,
      -0.039137836545705795,
      0.01822661980986595,
      0.004122637677937746,
      0.00261939549818635,
      -0.025849474593997,
      0.03719038888812065,
      0.053017571568489075,
      0.03340348228812218,
      -0.040510330349206924,
      0.06314616650342941,
      -0.11092836409807205,
      -0.056199006736278534,
      0.058177173137664795,
      -0.0010044357040897012,
      -0.020311152562499046,
      -0.031152669340372086,
      0.057277001440525055,
      -0.013466479256749153,
      0.01809738017618656,
      -0.07134753465652466,
      0.046447448432445526,
      -0.006135853007435799,
      -0.0356372632086277,
      0.011537491343915462,
      -0.015103326179087162,
      0.0073061599396169186,
      0.083656907081604,
      -0.05650484189391136,
      0.02211061865091324,
      -0.049192506819963455,
      0.051990360021591187,
      -0.04434392228722572,
      -0.03309943526983261,
      -0.018984217196702957,
      -0.011013530194759369,
      -0.016088927164673805,
      0.025896886363625526,
      0.029000280424952507,
      0.02893218956887722,
      -0.0011642510071396828,
      0.039138972759246826,
      -0.006331904325634241,
      -0.012373390607535839,
      -0.024516314268112183,
      0.03202207759022713,
      0.04549232870340347,
      0.016495397314429283,
      -0.01747460663318634,
      -0.06739526242017746,
      0.02347235195338726,
      0.006071505602449179,
      -0.015225513838231564,
      -0.030061621218919754,
      -0.010523278266191483,
      -0.048271991312503815,
      -0.03126681223511696,
      0.008918612264096737,
      0.04961370304226875,
      0.022741112858057022,
      -0.02600391022861004,
      -0.07073626667261124,
      -0.0571693517267704,
      0.016866059973835945,
      0.03021385706961155,
      -0.018697939813137054,
      -0.028486043214797974,
      -0.029275747016072273,
      -0.0408027321100235,
      0.004645506385713816,
      -0.018191944807767868,
      0.03496729955077171,
      0.06573901325464249,
      -0.06256166100502014,
      -0.006685295607894659,
      0.06590571999549866,
      0.006251719780266285,
      0.047696638852357864,
      0.04114385321736336,
      -0.0816485658288002,
      -0.016140908002853394,
      -0.070265032351017,
      -2.070517439278774e-05,
      -0.06194233149290085,
      -0.05311572924256325,
      -0.03862473741173744,
      -0.09246058017015457,
      -0.023294616490602493,
      0.00026684897602535784,
      0.03380850329995155,
      -0.03884390741586685,
      0.012582531198859215,
      -0.06895747035741806,
      -0.03977593034505844,
      0.003259123768657446,
      -0.006019133143126965,
      -0.006979250814765692,
      0.04666071757674217,
      0.09094573557376862,
      0.008864270523190498,
      0.03165462240576744,
      -0.016475878655910492,
      -0.038750775158405304,
      -0.02325672283768654,
      -0.07201524823904037,
      -0.024169715121388435,
      0.04505326226353645,
      -0.05932268872857094,
      0.00017454067710787058,
      0.006065515801310539,
      0.01276382151991129,
      0.0022239971440285444,
      0.036194924265146255,
      0.007878221571445465,
      0.0015069404616951942,
      0.0062536089681088924,
      -0.15566550195217133,
      0.024310605600476265,
      0.029500942677259445,
      0.008758767507970333,
      0.042342692613601685,
      -0.12964946031570435,
      0.06356475502252579,
      0.02195240743458271,
      -0.09921951591968536,
      0.05335202440619469,
      -0.05245082825422287,
      0.04062745347619057,
      0.0025302530266344547,
      -0.0459049828350544,
      -0.04730202630162239,
      -0.015290942043066025,
      0.07507406920194626,
      -0.024795066565275192,
      0.005573682487010956,
      0.023248054087162018,
      -0.09110572934150696,
      0.005201784428209066,
      -0.02494884468615055,
      0.09187035262584686,
      -0.014125238172709942,
      0.014233589172363281,
      0.059144970029592514,
      -0.06030840426683426,
      -0.04346318915486336,
      -0.0003073324332945049,
      0.01920272596180439,
      0.0018203515792265534,
      0.007842774502933025,
      -0.004920531064271927,
      -0.03134383261203766,
      -0.0326259583234787,
      0.017414672300219536,
      0.031157735735177994,
      -0.08095360547304153,
      -0.08831246942281723,
      0.04715379327535629,
      -0.05887957662343979,
      -0.01590552181005478,
      -0.09819178283214569,
      -0.07850298285484314,
      -0.028069648891687393,
      0.017671361565589905,
      -0.03919956833124161,
      0.13322754204273224,
      -0.028170913457870483,
      0.004639039747416973,
      -0.033621691167354584,
      -0.04303107038140297,
      0.008246851153671741,
      0.05488298088312149,
      0.010922448709607124,
      0.07094993442296982,
      -0.03536909818649292,
      -0.04220959544181824,
      0.025327792391180992,
      -0.01287878304719925,
      0.008485701866447926,
      -0.027525896206498146,
      0.020538607612252235,
      -3.9654038118897006e-05,
      -0.0876612514257431,
      0.04712941125035286,
      -0.014196769334375858,
      0.00023781538766343147,
      0.016085729002952576,
      0.016833791509270668,
      0.006583897862583399,
      0.0007493766606785357,
      -0.05401093140244484,
      -0.021900812163949013,
      -0.024401891976594925,
      -0.035340551286935806,
      0.011182566173374653,
      0.06250149756669998,
      -0.049613721668720245,
      0.017808541655540466,
      0.013058049604296684,
      -0.01163614634424448,
      0.036787696182727814,
      0.041636016219854355,
      -0.03840707987546921,
      -0.005012080539017916,
      0.013046770356595516,
      0.023190218955278397,
      -0.020313527435064316,
      0.023200107738375664,
      -0.08089669048786163,
      0.032262858003377914,
      -0.024129025638103485,
      0.030204126611351967,
      0.002181754447519779,
      -0.04478953033685684,
      0.0011307370150461793,
      -0.05560452491044998,
      -0.0026953122578561306,
      -0.0006257410859689116,
      -0.005829880479723215,
      -0.035184890031814575,
      -0.005593195091933012,
      0.01942220889031887,
      -0.04610762745141983,
      -0.029130399227142334,
      0.011538081802427769,
      0.013979440554976463,
      0.04609883576631546,
      0.026996487751603127,
      -0.06808879971504211,
      -0.0003142962232232094,
      0.009004008956253529,
      -0.06177636608481407,
      0.008114011958241463,
      -0.07686375081539154,
      -0.056312114000320435,
      0.07303085923194885,
      -0.031107138842344284,
      0.04266480356454849,
      -0.0004211698251310736,
      -0.07920508086681366,
      -0.04136683791875839,
      -0.0362217016518116,
      0.007904427126049995,
      0.06800266355276108,
      0.049958039075136185,
      0.06585212796926498,
      0.0022382873576134443,
      0.04390231519937515,
      -0.02346237190067768,
      -0.05140053480863571,
      -0.02357623539865017,
      0.05308903008699417,
      -0.05664290115237236,
      0.01680273376405239,
      0.07931572943925858,
      -0.03789081797003746,
      -0.02271180972456932,
      -0.05870595574378967,
      0.016673799604177475,
      0.06521904468536377,
      -0.038092635571956635,
      0.00999130867421627,
      0.04590804502367973,
      -0.01661624014377594,
      -0.024435533210635185,
      -0.01893215999007225,
      -0.015993766486644745,
      -0.02962236851453781,
      0.002808538731187582,
      0.038068804889917374,
      0.04666341096162796,
      -0.05773456022143364,
      0.05670276656746864,
      0.035773660987615585,
      0.010054404847323895,
      -0.10180453211069107,
      0.07147175818681717,
      0.028975501656532288,
      -0.0704977810382843,
      -0.06968672573566437,
      0.00375337153673172,
      0.05794130265712738,
      -0.0039505064487457275,
      -0.0074770632199943066,
      -0.08928362280130386,
      -0.0523516871035099,
      -0.03887685015797615,
      -0.009690421633422375,
      -0.023565467447042465,
      0.017002057284116745,
      0.0037101218476891518,
      0.05245286226272583,
      -0.01772632822394371,
      -0.03229142352938652,
      0.01884835585951805,
      0.004066643305122852,
      0.07679057866334915,
      0.036020465195178986,
      0.05357030779123306,
      0.022729337215423584,
      0.013093223795294762,
      0.01696375198662281,
      -0.09372948855161667,
      0.012145869433879852,
      -0.02410910278558731,
      -0.06529485434293747,
      0.048023466020822525,
      -0.0165327750146389,
      0.04711382836103439,
      0.059700220823287964,
      0.008527827449142933,
      -0.007503751665353775,
      -0.016965191811323166,
      -0.003937080968171358,
      -0.0238175131380558,
      0.005973164923489094,
      -0.04701317846775055,
      -0.0539918914437294,
      -0.010491118766367435,
      0.027836108580231667,
      -0.03023187816143036,
      0.010681661777198315,
      0.02894219383597374,
      -0.019923977553844452,
      -0.033856939524412155,
      -0.0451388880610466,
      -0.0338909812271595,
      0.05570262670516968,
      -0.026521429419517517,
      0.056771472096443176,
      0.04413731023669243,
      -0.05526541918516159,
      0.0021974253468215466,
      -0.07154771685600281,
      0.002011307515203953,
      -0.034244220703840256,
      0.04080316424369812,
      -0.0055205728858709335,
      0.01674702577292919,
      0.000693690322805196,
      -0.12626470625400543,
      -0.048468027263879776,
      -0.0957961156964302,
      -0.011041750200092793,
      -0.008715201169252396,
      -0.010172026231884956,
      0.03922783210873604,
      0.07737849652767181,
      0.024001343175768852,
      -0.014732654206454754,
      -0.013588051311671734,
      -0.04199158400297165,
      0.044749047607183456,
      -0.0566166490316391,
      0.01854303851723671,
      -0.026083393022418022,
      -0.0733279436826706,
      -0.08155401051044464,
      -0.04770314320921898,
      0.046699605882167816,
      -0.03473753109574318,
      -0.019190018996596336,
      0.04293879494071007,
      -0.05191553384065628,
      -0.07027796655893326,
      0.030643533915281296,
      -0.08452105522155762,
      0.0580451600253582,
      0.056348465383052826,
      0.0009656336042098701,
      -0.052346255630254745,
      0.03337196633219719,
      -0.018720410764217377,
      0.050835102796554565,
      -0.003520790720358491,
      -0.040068499743938446,
      -0.04170288145542145,
      -0.06487366557121277,
      0.002804864663630724,
      -0.010071468539536,
      -0.032324377447366714,
      -0.045677464455366135,
      -0.021397734060883522,
      -0.1004311814904213,
      -0.05952257290482521,
      -0.09075717628002167,
      -0.05574977397918701,
      0.022249264642596245,
      -0.001638488844037056,
      -0.021863972768187523,
      -0.00653529167175293,
      -0.02816217578947544,
      0.08183886110782623,
      -0.02322964370250702,
      -0.0913492813706398,
      0.0280750822275877,
      0.024110717698931694,
      0.03241991624236107,
      0.03579173982143402,
      0.017752615734934807,
      0.07746301591396332,
      0.008693758398294449,
      -0.051484256982803345,
      0.012477977201342583,
      -0.08554460108280182,
      -0.017183056101202965,
      0.08777066320180893,
      -0.017425065860152245,
      -0.006724041420966387,
      -0.0013214524369686842,
      0.020671352744102478,
      -0.002899794839322567,
      0.08605950325727463,
      -0.0008463429985567927,
      0.019470371305942535,
      0.08675698935985565,
      -0.07345183938741684,
      -0.030277227982878685,
      0.0300642978399992,
      -0.05910325050354004,
      -0.08816636353731155,
      -0.024702021852135658,
      0.011019239202141762,
      -0.00036425632424652576,
      -0.01473508682101965,
      -0.038445670157670975,
      -0.045254457741975784,
      -0.01755829155445099,
      -0.037009067833423615,
      0.044329218566417694,
      0.03067903220653534,
      -0.01297134067863226,
      -0.060341037809848785,
      -0.000520864559803158,
      0.001096617430448532,
      -0.05365581437945366,
      -0.05140835419297218,
      0.0002750533167272806,
      -0.005326647777110338,
      0.03897356241941452,
      0.04881088435649872,
      -0.04007337614893913,
      -0.002945512067526579,
      0.04662071540951729,
      -0.03330589458346367,
      -0.02282649092376232,
      -0.02687077783048153,
      -0.060512278228998184,
      0.042360819876194,
      -0.03783350810408592,
      0.03294438123703003,
      0.08290187269449234,
      0.00212444388307631,
      0.06013055890798569,
      -0.0027221422642469406,
      0.04179813340306282,
      0.09767601639032364,
      -0.0020888447761535645,
      -0.00027515512192621827,
      -0.05096068233251572,
      0.029346201568841934,
      -0.002763781463727355,
      0.00768945412710309,
      -0.05664169415831566,
      0.0009553010459057987,
      0.02365785650908947,
      -0.0189110916107893,
      0.05490195378661156,
      0.04025664180517197,
      0.029071994125843048,
      0.027150824666023254,
      -0.06732821464538574,
      0.08516918867826462,
      0.017354747280478477,
      -0.028989240527153015,
      -0.010966396890580654,
      0.045786257833242416,
      0.034371647983789444,
      -0.0056285858154296875,
      0.02060314267873764,
      0.04391932487487793,
      -0.003592625493183732,
      -0.03137573227286339,
      0.08222618699073792,
      0.052693333476781845,
      -0.07977660000324249,
      -0.0005679425084963441,
      -0.00934784859418869,
      0.05499137192964554,
      0.027870729565620422,
      -0.00802010577172041,
      -0.011518365703523159,
      0.04693140462040901,
      -0.003181096399202943,
      0.03469439968466759,
      -0.022563893347978592,
      -0.00561686884611845,
      -0.05709467828273773,
      -0.004023160319775343,
      -0.05595559999346733,
      -0.08002769201993942,
      0.04364776238799095,
      -0.0773107260465622,
      0.002739482093602419
    ],
    [
      0.0015926979249343276,
      -0.0062787472270429134,
      -0.05134168639779091,
      0.043925948441028595,
      -0.10748262703418732,
      -0.04832754656672478,
      -0.04025368019938469,
      0.062472254037857056,
      0.07359783351421356,
      0.04996412619948387,
      0.04666903614997864,
      0.05502462387084961,
      -0.046045735478401184,
      -0.10621284693479538,
      0.05744292214512825,
      0.007874568924307823,
      0.03652247041463852,
      -0.06385575979948044,
      -0.04190204665064812,
      -0.022791830822825432,
      0.027817757800221443,
      0.011623862199485302,
      0.04774373397231102,
      0.04392377287149429,
      0.0028577756602317095,
      0.04147830232977867,
      0.06479581445455551,
      0.011756003834307194,
      0.019400842487812042,
      -0.08301015943288803,
      0.03233470022678375,
      0.0564732700586319,
      -0.012447577901184559,
      -0.04358014091849327,
      -0.03180127218365669,
      0.002958637895062566,
      -0.03268471732735634,
      0.04527045413851738,
      -0.04351983219385147,
      -0.02215546928346157,
      -0.0484725683927536,
      0.0029788913670927286,
      0.0676141306757927,
      -0.04124078154563904,
      0.013360867276787758,
      0.004652949050068855,
      -0.011531278491020203,
      -0.015148716047406197,
      -0.026859868317842484,
      -0.03956533595919609,
      0.05612321197986603,
      -0.07943597435951233,
      0.004003248643130064,
      0.025301514193415642,
      -0.02223021723330021,
      0.03342832624912262,
      0.0384802520275116,
      0.04844839498400688,
      0.029955506324768066,
      0.005233473144471645,
      -0.008656671270728111,
      -0.0067886896431446075,
      -0.00011211886157980189,
      -0.023278947919607162,
      0.08891740441322327,
      -0.008284879848361015,
      0.0022741896100342274,
      0.12139689922332764,
      0.016361895948648453,
      0.07696369290351868,
      -0.03731865808367729,
      0.052358075976371765,
      0.05964769050478935,
      -0.022023625671863556,
      0.01444190088659525,
      0.036192093044519424,
      0.0013641994446516037,
      0.009131275117397308,
      0.07599794864654541,
      -0.018508993089199066,
      0.024062247946858406,
      -0.02712351270020008,
      -0.08927708119153976,
      -0.015840116888284683,
      -0.050394948571920395,
      -0.12365502864122391,
      -0.0028620678931474686,
      -0.08760011941194534,
      0.01805872842669487,
      -0.041435178369283676,
      -0.035880763083696365,
      -0.004104198422282934,
      0.01386079378426075,
      0.07618823647499084,
      0.012356759048998356,
      0.015685131773352623,
      0.03279678151011467,
      0.009339812211692333,
      0.015450970269739628,
      -0.029425201937556267,
      -0.0026618943084031343,
      0.0912247747182846,
      -0.03729735314846039,
      -0.018390901386737823,
      -0.04566117003560066,
      -0.037139758467674255,
      0.04744163155555725,
      0.027800915762782097,
      0.032063256949186325,
      -0.06174960732460022,
      0.00640848046168685,
      -0.030611664056777954,
      -0.020774703472852707,
      0.011085459031164646,
      0.01544913649559021,
      -0.04535633325576782,
      -0.07243809103965759,
      -0.006359382066875696,
      -0.04539797455072403,
      0.0450301431119442,
      0.05286218598484993,
      0.03254576027393341,
      0.028636744245886803,
      -0.0025157115887850523,
      -0.08953545242547989,
      0.02378692291676998,
      -0.00433503370732069,
      0.008886011317372322,
      -0.06701342761516571,
      -0.031741075217723846,
      -0.009194079786539078,
      0.0644773468375206,
      0.0034588868729770184,
      -0.02332516759634018,
      0.0503370463848114,
      0.03802710771560669,
      0.04212513566017151,
      0.04178658872842789,
      -0.04818985238671303,
      -0.04792420566082001,
      -0.018570629879832268,
      -0.022353164851665497,
      -0.053859684616327286,
      -0.03339258208870888,
      -0.01205710880458355,
      -0.041331205517053604,
      0.04542255774140358,
      -0.022331764921545982,
      -0.0011541050625965,
      -0.00867549329996109,
      -0.017196817323565483,
      -0.019133497029542923,
      0.047683339565992355,
      -0.01587027497589588,
      0.08115028589963913,
      -0.04553231596946716,
      -0.048852480947971344,
      -0.051397159695625305,
      0.03406062349677086,
      0.016306180506944656,
      -0.059932492673397064,
      -0.062459852546453476,
      0.020124083384871483,
      -0.023863324895501137,
      0.024107998237013817,
      0.08181063830852509,
      0.03068278171122074,
      0.035251133143901825,
      0.015996482223272324,
      0.05546640604734421,
      0.024494586512446404,
      -0.0028900066390633583,
      -0.031153416261076927,
      0.03858816996216774,
      0.012611586600542068,
      0.029184510931372643,
      0.03601386770606041,
      0.005042292643338442,
      0.041046954691410065,
      0.0735795646905899,
      -0.016978159546852112,
      -0.12577976286411285,
      -0.027153639122843742,
      0.002883468521758914,
      0.040536198765039444,
      0.02150360308587551,
      4.925091161567252e-07,
      0.11074406653642654,
      -0.06507840007543564,
      -0.020662812516093254,
      0.03864094614982605,
      -0.0674576535820961,
      -0.00795053318142891,
      -0.0013299896381795406,
      0.0465095154941082,
      -0.060258179903030396,
      -0.03523709997534752,
      -0.05938619747757912,
      0.06435884535312653,
      0.016418926417827606,
      0.026714971289038658,
      -0.04312409088015556,
      0.009989778511226177,
      -0.04322230443358421,
      -0.03169175237417221,
      -0.048789359629154205,
      -0.07915646582841873,
      -0.1006353422999382,
      0.024754663929343224,
      -0.0614316388964653,
      0.06348396837711334,
      0.020839085802435875,
      0.035852767527103424,
      0.07879522442817688,
      0.08605693280696869,
      0.0706268697977066,
      0.027507223188877106,
      -0.012172980234026909,
      -0.02304372563958168,
      -0.055623430758714676,
      0.015908900648355484,
      0.0522918738424778,
      -0.023662403225898743,
      0.01731637492775917,
      0.0075810994021594524,
      0.039469070732593536,
      0.03466268628835678,
      0.018886655569076538,
      0.05701766908168793,
      -0.003984491340816021,
      -0.06166587397456169,
      -0.00848506111651659,
      -0.007328580133616924,
      -0.04278955236077309,
      -0.00066401093499735,
      0.0018788788001984358,
      -0.08401750028133392,
      0.03673699125647545,
      0.03601878508925438,
      -0.02289656177163124,
      -0.08153051137924194,
      0.04530702531337738,
      -0.012983436696231365,
      0.03260252624750137,
      0.038047902286052704,
      -0.06265867501497269,
      -0.015741605311632156,
      -0.031004225835204124,
      -0.02241232804954052,
      -0.006356875877827406,
      -0.0651780515909195,
      0.04612323269248009,
      -0.008815565146505833,
      0.013424958102405071,
      0.05096757039427757,
      0.015620897524058819,
      -0.020543446764349937,
      0.008768067695200443,
      0.08417303115129471,
      -0.04874313250184059,
      -0.06566830724477768,
      -0.023698478937149048,
      0.020257089287042618,
      -0.01422810833901167,
      0.046841222792863846,
      0.0032190904021263123,
      0.021377265453338623,
      0.055713094770908356,
      0.031325824558734894,
      -0.00454028369858861,
      0.010988772846758366,
      0.0821235403418541,
      0.005729048978537321,
      0.03615016117691994,
      -0.03158607333898544,
      0.008777682669460773,
      -0.0860004797577858,
      0.009241852909326553,
      0.036973439157009125,
      -0.06291355192661285,
      -0.013729535974562168,
      0.007139494642615318,
      -0.020158782601356506,
      -0.00905149057507515,
      0.041344549506902695,
      -0.007123141083866358,
      -0.08199869096279144,
      -0.017911966890096664,
      -0.00030708807753399014,
      0.04879118129611015,
      0.0024268468841910362,
      -0.09059720486402512,
      -0.05750590190291405,
      -0.009762769564986229,
      -0.05718189850449562,
      -0.06633403897285461,
      -0.0005185482441447675,
      -0.04963035508990288,
      0.03474032133817673,
      0.01351979561150074,
      0.04255720600485802,
      -0.047961387783288956,
      -0.0525084026157856,
      -0.09313934296369553,
      -0.03329598903656006,
      0.07907863706350327,
      0.03634054213762283,
      -0.0672963336110115,
      -0.03269750252366066,
      -0.019473135471343994,
      0.03728523477911949,
      0.04835835471749306,
      0.07161940634250641,
      0.0045130448415875435,
      0.009239192120730877,
      -0.06976387649774551,
      -0.004191627260297537,
      -0.0005110275815241039,
      -0.0022244038991630077,
      0.019205383956432343,
      -0.11266053467988968,
      0.012628762051463127,
      -0.016927188262343407,
      0.07148879766464233,
      -0.037636443972587585,
      0.03882819414138794,
      0.013816151767969131,
      0.08119478821754456,
      -0.026558030396699905,
      0.03949878364801407,
      -0.017351899296045303,
      -0.040752753615379333,
      0.0379507914185524,
      0.0017930443864315748,
      -0.037716422230005264,
      -0.04659608006477356,
      0.0348491370677948,
      -0.0066552539356052876,
      0.02735074795782566,
      -0.10094211250543594,
      -0.09112508594989777,
      0.04230519011616707,
      -0.035471536219120026,
      -0.02362886071205139,
      0.007922543212771416,
      -0.029257861897349358,
      0.03880107030272484,
      -0.006492761429399252,
      -0.024060145020484924,
      -0.046652939170598984,
      -0.041900068521499634,
      0.03959181159734726,
      0.026678849011659622,
      0.027743462473154068,
      -0.03716760501265526,
      0.028714308515191078,
      -0.00538417836651206,
      -0.03075597994029522,
      0.024180462583899498,
      0.08176632225513458,
      -0.000849007919896394,
      -0.0177924744784832,
      -0.014755528420209885,
      0.002711254172027111,
      0.030710875988006592,
      -0.02632562257349491,
      -0.029938286170363426,
      -0.0020569716580212116,
      -0.017018260434269905,
      -0.024758953601121902,
      -0.08022354543209076,
      0.05975235253572464,
      0.06398171931505203,
      -0.032312821596860886,
      -0.07074487209320068,
      -0.05632472783327103,
      -0.012567670084536076,
      -0.039948634803295135,
      -0.08726975321769714,
      0.04990328103303909,
      0.06220219284296036,
      -0.039369236677885056,
      0.027840569615364075,
      -0.029224369674921036,
      0.04281531646847725,
      -0.018041856586933136,
      -0.010828616097569466,
      -0.006135937292128801,
      -0.024299614131450653,
      0.009190560318529606,
      0.021976199001073837,
      0.017210902646183968,
      -0.0020776314195245504,
      -0.08017348498106003,
      -0.021564221009612083,
      -0.13914808630943298,
      -0.024449827149510384,
      -0.013653496280312538,
      -0.01974703185260296,
      0.04899922385811806,
      -0.0233774296939373,
      0.012221656739711761,
      0.03527086228132248,
      -0.07053355872631073,
      0.05823270231485367,
      -0.07923708111047745,
      0.029185112565755844,
      -0.015569660812616348,
      -0.0518818199634552,
      0.023697929456830025,
      -0.03694043681025505,
      -0.031308867037296295,
      -0.0041457489132881165,
      0.0596802681684494,
      0.021372834220528603,
      0.08661622554063797,
      0.07003819942474365,
      0.0997769832611084,
      -0.015192714519798756,
      0.007809172850102186,
      -0.002750437706708908,
      0.0006011690711602569,
      0.014959104359149933,
      0.030841264873743057,
      -0.004879848100244999,
      0.04248863086104393,
      0.03712710365653038,
      0.003381239017471671,
      0.048904452472925186,
      0.02362142875790596,
      0.07015224546194077,
      -0.0011211507953703403,
      0.12882737815380096,
      0.003346753539517522,
      0.00963378045707941,
      -0.02058388479053974,
      -0.08112112432718277,
      -0.020042158663272858,
      0.013144822791218758,
      0.0162674468010664,
      0.03796545788645744,
      -0.11886423081159592,
      0.03269175440073013,
      -0.03143661841750145,
      0.07592983543872833,
      0.039136651903390884,
      0.01756211556494236,
      0.004658752586692572,
      0.007170212920755148,
      -0.03634659945964813,
      0.011432254686951637,
      0.025096997618675232,
      0.01803508959710598,
      0.01889468915760517,
      -0.07931002974510193,
      0.052838779985904694,
      -0.10011609643697739,
      0.060342900454998016,
      0.03182164952158928,
      -0.07064326107501984,
      -0.027078183367848396,
      -0.03300449624657631,
      0.04288152977824211,
      0.040354128926992416,
      -0.05423035845160484,
      0.0017715547000989318,
      0.03100384585559368,
      -0.08390846848487854,
      -0.07476664334535599,
      -0.014698334969580173,
      0.004990441724658012,
      0.029190069064497948,
      -0.04477763921022415,
      -0.07768026739358902,
      0.041918136179447174,
      0.010628853924572468,
      -0.043314892798662186,
      0.05200804024934769,
      -0.050685614347457886,
      0.0018498472636565566,
      -0.03707356005907059,
      -0.03373315557837486,
      -0.047689326107501984,
      -0.08868420124053955,
      -0.02758527174592018,
      0.08003827184438705,
      -0.08374234288930893,
      0.0417867973446846,
      0.011498047038912773,
      0.043354716151952744,
      -0.02612556517124176,
      0.022356214001774788,
      -0.04406573995947838,
      -0.043821126222610474,
      0.06755296885967255,
      -0.05265354365110397,
      -0.03982818126678467,
      0.0643821433186531,
      0.061548151075839996,
      0.0009981791954487562,
      0.02136874943971634,
      -0.1191851794719696,
      -0.033120978623628616
    ],
    [
      0.028355509042739868,
      -0.02068590745329857,
      0.05670541897416115,
      0.011544943787157536,
      0.010084719397127628,
      -0.013248509727418423,
      0.05452786758542061,
      0.003507111454382539,
      0.11345409601926804,
      -0.021606381982564926,
      -0.02987472154200077,
      -0.019805170595645905,
      -0.005899996496737003,
      0.04212820157408714,
      0.09250133484601974,
      0.09172336012125015,
      -0.050511471927165985,
      0.02054712362587452,
      -0.03663664683699608,
      0.04733862355351448,
      -0.03195284679532051,
      -0.025940969586372375,
      -0.016467025503516197,
      0.00455789640545845,
      -0.010148540139198303,
      -0.057878654450178146,
      -0.06542567163705826,
      -0.014508114196360111,
      -0.009831225499510765,
      0.023565659299492836,
      -0.016733091324567795,
      -0.021832797676324844,
      0.030309302732348442,
      -0.02972710132598877,
      -0.01907646283507347,
      -0.10066148638725281,
      -0.010826775804162025,
      0.0161434356123209,
      -0.07617785036563873,
      0.017656460404396057,
      -0.11446531116962433,
      0.024960080161690712,
      0.027275605127215385,
      -0.029644398018717766,
      -0.047142427414655685,
      -0.041003890335559845,
      -0.01157244574278593,
      0.030641738325357437,
      0.033632274717092514,
      -0.017876828089356422,
      0.0560440719127655,
      0.025911210104823112,
      0.003883400233462453,
      -0.0011871899478137493,
      -0.03468549996614456,
      0.03106166608631611,
      -0.07389931380748749,
      -0.040896955877542496,
      0.04282797500491142,
      -0.05682724714279175,
      0.020977169275283813,
      0.03378681093454361,
      -0.02782377600669861,
      -0.014678770676255226,
      0.00369652989320457,
      0.03194785490632057,
      0.035827551037073135,
      -0.00929508451372385,
      0.02885623648762703,
      -0.010669929906725883,
      -0.03865044191479683,
      0.00894124060869217,
      0.021404623985290527,
      0.034117985516786575,
      0.006193869281560183,
      -0.058780934661626816,
      -0.0006857375847175717,
      -0.027007993310689926,
      0.033359773457050323,
      -0.05416973680257797,
      -0.004087409004569054,
      0.013173379004001617,
      -0.030765315517783165,
      0.011480528861284256,
      0.046237532049417496,
      0.015257170423865318,
      -0.030857933685183525,
      0.008488049730658531,
      3.896018461091444e-05,
      0.015110869891941547,
      -0.006296168081462383,
      0.0341225229203701,
      0.01261222641915083,
      0.04988383129239082,
      0.020841849967837334,
      0.02009642869234085,
      0.0037541144993156195,
      -0.019595187157392502,
      -0.011439650319516659,
      -0.023226207122206688,
      -0.003676948370411992,
      -0.01285429298877716,
      -0.022544071078300476,
      0.03617566451430321,
      -0.04735307767987251,
      -0.03118693083524704,
      0.04404463991522789,
      -0.004813761916011572,
      -0.022023458033800125,
      -0.015816306695342064,
      0.0647188052535057,
      0.020629268139600754,
      -0.024698402732610703,
      0.096017025411129,
      0.05641186982393265,
      -0.01170284766703844,
      -0.042174696922302246,
      -0.009938538074493408,
      0.002248580800369382,
      0.006856840569525957,
      0.08772832155227661,
      0.05993666499853134,
      0.03651365637779236,
      -0.014326062984764576,
      0.08021748065948486,
      -0.008223183453083038,
      -0.032945409417152405,
      -0.021662382408976555,
      -0.06170979142189026,
      -0.03782495856285095,
      0.013257747516036034,
      0.0013204292627051473,
      -0.04387257248163223,
      -0.03249330818653107,
      -0.03262673690915108,
      0.02238810434937477,
      0.027108022943139076,
      0.038343023508787155,
      -0.006751216948032379,
      0.022324517369270325,
      -0.05274825915694237,
      -0.08565579354763031,
      0.0033913145307451487,
      0.02016078121960163,
      -0.029206203296780586,
      -0.02045968733727932,
      -0.007894106209278107,
      0.019641462713479996,
      0.06166275963187218,
      0.04267901927232742,
      0.034228552132844925,
      -0.002840519417077303,
      -0.09713876247406006,
      0.022202467545866966,
      0.05062625929713249,
      0.07138519734144211,
      -0.013532229699194431,
      0.0268626119941473,
      0.04486079514026642,
      -0.06490916758775711,
      -0.01034836657345295,
      0.001707272371277213,
      -0.01895863562822342,
      -0.0057429419830441475,
      0.034864526242017746,
      0.056944504380226135,
      0.09714973717927933,
      -0.01257424708455801,
      0.00747425202280283,
      0.0328531339764595,
      -0.032181065529584885,
      0.026964599266648293,
      0.032916344702243805,
      -0.0440998412668705,
      0.05107080563902855,
      -0.10534237325191498,
      -0.050423651933670044,
      0.037649258971214294,
      -0.028238344937562943,
      -0.06353134661912918,
      -0.011547395959496498,
      -0.04079536348581314,
      0.04237095266580582,
      0.03862742707133293,
      -0.05038661137223244,
      0.048029400408267975,
      -0.02689848095178604,
      -0.08366856724023819,
      0.04157646372914314,
      0.05435093492269516,
      -0.023510167375206947,
      -0.0655791237950325,
      -0.01233510673046112,
      -0.0286499485373497,
      -0.026484552770853043,
      0.022758163511753082,
      0.019542163237929344,
      0.028556082397699356,
      0.012625392526388168,
      0.03277410939335823,
      -0.030620025470852852,
      0.05100785568356514,
      0.030067304149270058,
      0.051739130169153214,
      -0.03704449161887169,
      -0.02081855945289135,
      0.06018471345305443,
      0.06877283751964569,
      0.011111658997833729,
      -0.028714284300804138,
      0.09529342502355576,
      -0.016402041539549828,
      -0.014641864225268364,
      -0.006398998200893402,
      -0.011527047492563725,
      -0.01200499851256609,
      0.11671070754528046,
      0.04091241955757141,
      -0.06532551348209381,
      0.0712166354060173,
      0.08312436193227768,
      0.07591455429792404,
      0.000753235537558794,
      -0.10079014301300049,
      0.05694291368126869,
      0.027848247438669205,
      0.0100184241309762,
      0.019890353083610535,
      0.09034811705350876,
      -0.0011645295890048146,
      -0.04887712001800537,
      -0.03190391883254051,
      -0.032105833292007446,
      -0.020749984309077263,
      0.004154749680310488,
      -0.012140372768044472,
      0.035802144557237625,
      0.02347576431930065,
      -0.03697950392961502,
      -0.013849359937012196,
      0.027201680466532707,
      -0.050564978271722794,
      -0.014556675218045712,
      0.019132932648062706,
      0.01193062961101532,
      -0.034426815807819366,
      -0.045482851564884186,
      -0.024683890864253044,
      -0.01080884225666523,
      -0.010499939322471619,
      0.005729441996663809,
      -0.009298629127442837,
      0.07024411112070084,
      -0.0118623748421669,
      -0.02107921615242958,
      0.02616949751973152,
      0.0038218116387724876,
      -0.031646013259887695,
      0.05335661396384239,
      -0.030900947749614716,
      -0.0070408242754638195,
      0.009946062229573727,
      0.027292415499687195,
      -0.05156204476952553,
      0.009636144153773785,
      0.08427787572145462,
      -0.05194513499736786,
      -0.07326805591583252,
      -0.011673432774841785,
      -0.07525193691253662,
      -0.06040165573358536,
      -0.014054127968847752,
      -0.017829284071922302,
      0.005807346198707819,
      -0.04038889333605766,
      0.036077044904232025,
      0.030709674581885338,
      -0.015279624611139297,
      -0.06073632091283798,
      -0.04157168045639992,
      0.052488770335912704,
      -0.006453115958720446,
      0.07356055080890656,
      0.020905645564198494,
      0.004631582647562027,
      0.02450309880077839,
      0.0870111808180809,
      0.07710288465023041,
      -0.0383695624768734,
      0.05127311125397682,
      -0.03795937821269035,
      0.03155937045812607,
      0.03957315534353256,
      -0.007290779147297144,
      -0.03937004506587982,
      0.1388930380344391,
      0.011662759818136692,
      -0.0293987188488245,
      -0.035145603120326996,
      0.04729161038994789,
      0.028542356565594673,
      -0.004815304651856422,
      -0.06333015859127045,
      -0.030531108379364014,
      -0.09003893285989761,
      -0.007088128011673689,
      0.03796079009771347,
      -0.03206145018339157,
      -0.014615677297115326,
      -0.04308893904089928,
      0.02492332085967064,
      -0.023578288033604622,
      0.06771239638328552,
      -0.02421686239540577,
      -0.07605393975973129,
      -0.021557817235589027,
      0.016521703451871872,
      -0.020817570388317108,
      -0.06437798589468002,
      0.006657417863607407,
      -0.03500644117593765,
      -0.010149389505386353,
      0.014389319345355034,
      -0.00412279088050127,
      -0.06095361337065697,
      0.04313021898269653,
      0.044564519077539444,
      -0.06410917639732361,
      0.09459644556045532,
      0.08701367676258087,
      -0.0035100369714200497,
      -0.028822753578424454,
      -0.06888541579246521,
      0.004953283350914717,
      -0.05059928819537163,
      -0.13861677050590515,
      -0.04698551073670387,
      -0.024770164862275124,
      0.02835988625884056,
      -0.021874936297535896,
      0.06212925538420677,
      -0.03622112795710564,
      -0.01850791461765766,
      -0.13188575208187103,
      -0.041841644793748856,
      -0.04755038022994995,
      0.10057871788740158,
      -0.014657215215265751,
      -0.06088809296488762,
      0.06348562985658646,
      0.04872952029109001,
      0.02059130184352398,
      0.034520410001277924,
      0.0026280181482434273,
      0.05338968709111214,
      0.050795916467905045,
      -0.05687114968895912,
      0.006508184131234884,
      -0.034880317747592926,
      0.04846581444144249,
      0.022059109061956406,
      -0.03898736089468002,
      -0.08838903903961182,
      0.05971816927194595,
      0.02682916447520256,
      -0.05312252417206764,
      -0.009796685539186,
      -0.053372666239738464,
      0.02675740048289299,
      -0.010366855189204216,
      0.00779035035520792,
      0.029910406097769737,
      0.02670401707291603,
      -0.0827668309211731,
      -0.10052291303873062,
      -0.008251810446381569,
      0.02202574722468853,
      0.07773859798908234,
      -0.0662464052438736,
      -0.05628805235028267,
      0.015590694732964039,
      -0.04045281931757927,
      0.009030797518789768,
      -0.0017654194962233305,
      -0.012651470489799976,
      -0.0623198077082634,
      0.01983514241874218,
      -0.013071101158857346,
      0.004921223968267441,
      -0.02876797877252102,
      0.03143509477376938,
      -0.04363302141427994,
      0.05834332853555679,
      -0.047980595380067825,
      0.01956481672823429,
      -0.07560691982507706,
      0.026723025366663933,
      -0.0702778771519661,
      -0.013826441951096058,
      -0.06907819956541061,
      0.04652095213532448,
      0.005360340233892202,
      -0.06650029867887497,
      0.058146875351667404,
      -0.023432152345776558,
      -0.03225519880652428,
      -0.037094730883836746,
      -0.01479971781373024,
      0.038257449865341187,
      -0.04993811249732971,
      -0.0004785697383340448,
      0.006991260685026646,
      -0.020809955894947052,
      0.005487867631018162,
      -0.008074669167399406,
      -0.020738843828439713,
      0.004848568234592676,
      -0.040147773921489716,
      0.016977623105049133,
      0.010590393096208572,
      -0.009323679842054844,
      0.010573896579444408,
      0.13122694194316864,
      -0.045930664986371994,
      -0.0021318590734153986,
      0.04785040020942688,
      -0.002873292425647378,
      -0.01786882057785988,
      -0.07204990088939667,
      0.04842004179954529,
      0.01212775893509388,
      -0.11678218096494675,
      -0.05543101951479912,
      -0.01579512655735016,
      -0.011757071129977703,
      -0.041822269558906555,
      -0.050105828791856766,
      0.05629781633615494,
      0.02734682522714138,
      0.09529169648885727,
      0.04163819178938866,
      0.004063338506966829,
      -0.010953613556921482,
      0.05609282851219177,
      0.039930179715156555,
      0.0006409932393580675,
      -0.024265101179480553,
      -0.0639679878950119,
      -0.013636070303618908,
      0.09368409961462021,
      0.013489127159118652,
      0.002923231106251478,
      -0.07293111085891724,
      -0.01720692776143551,
      -0.023303864523768425,
      0.040763262659311295,
      0.037304606288671494,
      -0.03991065174341202,
      -0.014992419630289078,
      0.059911079704761505,
      0.0012707806890830398,
      -0.021108001470565796,
      0.011361940763890743,
      0.017485402524471283,
      -0.045967597514390945,
      -0.027941668406128883,
      -0.08805732429027557,
      -0.06538568437099457,
      -0.014617718756198883,
      -0.06835153698921204,
      -0.012481776997447014,
      -0.012658802792429924,
      0.040066197514534,
      0.029096176847815514,
      -0.0010728760389611125,
      -0.04274742305278778,
      -0.05758512392640114,
      0.06666960567235947,
      -0.0376594215631485,
      -0.059841498732566833,
      0.09137190878391266,
      -0.02321396768093109,
      -0.038052793592214584,
      -0.026025990024209023,
      0.016583262011408806,
      0.0016371633391827345,
      -0.007426820695400238,
      -0.022063326090574265,
      -0.04700014740228653,
      0.050249990075826645,
      -0.027762526646256447,
      0.0009840461425483227,
      -0.055648718029260635,
      -0.017610471695661545,
      -0.013776436448097229,
      0.010405671782791615,
      0.05466549098491669,
      0.030801890417933464,
      0.0016583595424890518,
      0.08318651467561722,
      -0.06627138704061508,
      0.022835418581962585,
      0.028923772275447845
    ],
    [
      0.006612079683691263,
      0.07849112153053284,
      0.040130093693733215,
      0.02138655260205269,
      -0.02597925066947937,
      -0.009147446602582932,
      0.02981455810368061,
      0.022606901824474335,
      0.09794775396585464,
      0.04635319858789444,
      0.06748659163713455,
      -0.009164546616375446,
      -0.029376477003097534,
      0.037932101637125015,
      0.04267806559801102,
      -0.05829423666000366,
      0.016615310683846474,
      0.024904834106564522,
      0.02433750033378601,
      -0.029016047716140747,
      0.0726691335439682,
      0.007211002521216869,
      0.04308216646313667,
      0.007394308224320412,
      -0.004443660844117403,
      0.024640100076794624,
      -0.029269989579916,
      -0.008517090231180191,
      0.04031303524971008,
      -0.038260385394096375,
      -0.00782059133052826,
      0.10711666196584702,
      -0.01877928152680397,
      -0.013481025584042072,
      0.06387625634670258,
      0.009927662089467049,
      0.05852827802300453,
      -0.00873624999076128,
      -0.006100619677454233,
      -0.013871410861611366,
      0.018365683034062386,
      -0.05473170801997185,
      -0.026242146268486977,
      -0.041871391236782074,
      0.02608659118413925,
      0.044638197869062424,
      -0.10497625172138214,
      -0.02469753473997116,
      -0.010155539028346539,
      -0.0029335657600313425,
      0.03161468729376793,
      -0.0779375284910202,
      0.053373321890830994,
      -0.03773784637451172,
      -0.05566321313381195,
      0.02662491984665394,
      -0.07097404450178146,
      0.10037162154912949,
      -0.015769803896546364,
      -0.04918991029262543,
      -0.04672006517648697,
      -0.03934064880013466,
      0.004459308460354805,
      -0.016823099926114082,
      -0.043135322630405426,
      -0.030118228867650032,
      -0.03664045408368111,
      -0.03750091791152954,
      0.0012966188369318843,
      0.01015886478126049,
      -0.0013575139455497265,
      -0.013398704119026661,
      -0.04157516732811928,
      -0.06065097451210022,
      0.07091351598501205,
      -0.07155006378889084,
      -0.03489503264427185,
      -0.04720338433980942,
      0.08576853573322296,
      -0.05551952123641968,
      -0.097295843064785,
      0.05048077926039696,
      -0.07797102630138397,
      0.03883097693324089,
      0.06294112652540207,
      -0.002318912884220481,
      0.08373645693063736,
      0.02662237174808979,
      0.027709202840924263,
      -0.04265501722693443,
      0.05044467747211456,
      0.00989413633942604,
      0.006026835180819035,
      0.07821158319711685,
      0.06574289500713348,
      -0.03598644211888313,
      0.033272240310907364,
      0.003824330633506179,
      0.03057027980685234,
      -0.08175960183143616,
      -0.0490914061665535,
      -0.04709761589765549,
      -0.06777709722518921,
      0.06355058401823044,
      -0.017678210511803627,
      0.02241634391248226,
      0.00628646370023489,
      -0.0884944349527359,
      0.04629591107368469,
      -0.0023629905190318823,
      -0.03552400693297386,
      -0.02407463826239109,
      0.032674696296453476,
      -0.010088760405778885,
      -0.020966898649930954,
      0.027551842853426933,
      -0.08786532282829285,
      -0.03720022737979889,
      -0.01616576686501503,
      0.020798586308956146,
      -0.008127101697027683,
      -0.0721696987748146,
      0.012570791877806187,
      0.04581214487552643,
      -0.04528651386499405,
      -0.03425998240709305,
      0.026616863906383514,
      0.023165356367826462,
      -0.020462563261389732,
      -0.031020233407616615,
      -0.01804235205054283,
      -0.029252586886286736,
      -0.02349969930946827,
      -0.04310498386621475,
      -0.007194129750132561,
      0.004105814732611179,
      0.0041763982735574245,
      0.015245752409100533,
      0.0027048613410443068,
      -0.019180933013558388,
      0.03049740567803383,
      -0.05965432524681091,
      -0.07174109667539597,
      0.03613872081041336,
      -0.017974181100726128,
      -0.00641969870775938,
      -0.06764421612024307,
      -0.008264518342912197,
      -0.10399327427148819,
      0.05665428936481476,
      -0.08006583154201508,
      -0.02795099839568138,
      -0.01342843845486641,
      -0.025101669132709503,
      0.07154137641191483,
      0.06078668683767319,
      0.005732154473662376,
      -0.09391477704048157,
      0.07289281487464905,
      0.07328669726848602,
      0.05709783732891083,
      -0.07055564224720001,
      0.13687093555927277,
      0.031736359000205994,
      0.02553664520382881,
      0.02873663790524006,
      -0.07289177179336548,
      0.04849620535969734,
      -0.027322843670845032,
      0.011782385408878326,
      0.058578506112098694,
      -0.016863569617271423,
      0.057504747062921524,
      0.02629646100103855,
      -0.030444342643022537,
      0.06587634235620499,
      0.07586997002363205,
      0.012624912895262241,
      0.09454650431871414,
      -0.023889105767011642,
      -0.12646210193634033,
      -0.02206294797360897,
      0.01693209819495678,
      -0.0459444634616375,
      0.015084503218531609,
      0.0905250757932663,
      -0.015310276299715042,
      -0.059936124831438065,
      -0.018633561208844185,
      -0.03170057386159897,
      -0.003907587379217148,
      -0.03429040312767029,
      0.022445648908615112,
      -0.01121341809630394,
      0.047478895634412766,
      -0.0179213248193264,
      -0.04515604302287102,
      -0.02099049836397171,
      -0.03158967196941376,
      0.08962975442409515,
      -0.030773650854825974,
      0.02599472925066948,
      -0.06855214387178421,
      -0.006184042431414127,
      -0.1181277260184288,
      0.04069780185818672,
      0.03511666879057884,
      -0.07785683870315552,
      -0.026289481669664383,
      -0.06253805011510849,
      -0.008407057262957096,
      -0.03250942751765251,
      -0.03423362597823143,
      -0.01605193503201008,
      -0.09402517974376678,
      -0.06254690140485764,
      0.00902259349822998,
      0.00385294109582901,
      0.006485883612185717,
      -0.0012754343915730715,
      -0.058818936347961426,
      -0.04770185798406601,
      0.0602400116622448,
      0.06500010937452316,
      0.007597505580633879,
      -0.0004237541579641402,
      0.03796353191137314,
      -0.040053240954875946,
      0.004166651051491499,
      -0.008819682523608208,
      -0.012138665653765202,
      0.06849363446235657,
      0.0029169328045099974,
      -0.008508983999490738,
      0.061647459864616394,
      0.061258066445589066,
      0.01957232505083084,
      -0.02689257077872753,
      -0.0148230055347085,
      0.04990969970822334,
      -0.013185511343181133,
      -0.011307084932923317,
      -0.03747813031077385,
      -0.07983949780464172,
      -0.05835873633623123,
      0.07110662013292313,
      0.09082107990980148,
      -0.057607896625995636,
      -0.06373796612024307,
      0.001082219067029655,
      0.022857850417494774,
      -0.014284555800259113,
      -0.018259786069393158,
      0.02892557717859745,
      -0.014898717403411865,
      0.010673209093511105,
      0.07249145954847336,
      -0.07201188057661057,
      0.01729901134967804,
      -0.040229931473731995,
      0.01787613146007061,
      -0.01846974343061447,
      0.014943618327379227,
      0.04681464657187462,
      -0.006184433586895466,
      0.03324798867106438,
      0.05447642505168915,
      -0.008208432234823704,
      -0.0007565018604509532,
      0.06509474664926529,
      -0.011922219768166542,
      0.08907425403594971,
      -0.05045148730278015,
      -0.011551132425665855,
      0.014470907859504223,
      -0.055722739547491074,
      0.016239911317825317,
      0.019468313083052635,
      -0.018777763471007347,
      0.02628856897354126,
      0.022625040262937546,
      -0.03330555558204651,
      0.026976002380251884,
      -0.01805577613413334,
      0.05768072232604027,
      -0.07455473393201828,
      -0.02980729751288891,
      0.00767123606055975,
      -0.016972705721855164,
      0.05829881131649017,
      -0.050119005143642426,
      0.07539163529872894,
      0.03219182789325714,
      0.04074794054031372,
      -0.0038545504212379456,
      7.796491991030052e-05,
      0.005009602289646864,
      -0.033273324370384216,
      -0.046607088297605515,
      -0.03297910466790199,
      -0.045321885496377945,
      0.007867952808737755,
      0.09897702187299728,
      -0.08296270668506622,
      -0.06369148939847946,
      0.0510442778468132,
      0.03851388394832611,
      -0.06757830828428268,
      -0.07292405515909195,
      -0.05540448799729347,
      -0.025491567328572273,
      0.0783257707953453,
      0.010359047912061214,
      -0.04384886845946312,
      0.1138327345252037,
      -0.030531106516718864,
      -0.02245098352432251,
      0.007071038708090782,
      -0.12388573586940765,
      -0.047373972833156586,
      -0.013951517641544342,
      -0.08246888220310211,
      -0.04925079643726349,
      0.050581105053424835,
      -0.001256107003428042,
      0.040907785296440125,
      -0.10694650560617447,
      0.012765515595674515,
      0.03542166203260422,
      0.021949144080281258,
      -0.07357855141162872,
      0.019813885912299156,
      0.06839466094970703,
      0.0014104736037552357,
      0.006581117399036884,
      -0.03715536370873451,
      0.024759719148278236,
      0.044443950057029724,
      0.0031548039987683296,
      0.02231711708009243,
      0.00984529871493578,
      -0.036627475172281265,
      -0.13027630746364594,
      -0.06683775037527084,
      0.03349275887012482,
      -0.015391242690384388,
      0.021590329706668854,
      -0.019894642755389214,
      -0.037382982671260834,
      0.07357943803071976,
      4.0144506783690304e-05,
      0.014491671696305275,
      0.010152967646718025,
      0.031029243022203445,
      -0.047560915350914,
      0.010053298436105251,
      -0.012078999541699886,
      0.001651160535402596,
      -0.005244075320661068,
      0.036865852773189545,
      -0.002585113514214754,
      -0.0560469776391983,
      0.006928900256752968,
      -0.022583434358239174,
      0.016260605305433273,
      -0.04742478206753731,
      -0.06761573255062103,
      0.07864601165056229,
      0.01176840253174305,
      -0.04226265475153923,
      -0.00011088247993029654,
      -0.07589714229106903,
      -0.005742358975112438,
      0.0023692757822573185,
      -0.010416407138109207,
      -0.006689788773655891,
      0.023899177089333534,
      0.038324326276779175,
      -0.01221286877989769,
      0.07580509036779404,
      0.0616920068860054,
      -0.028697162866592407,
      -0.004150776658207178,
      -0.015805082395672798,
      -0.010681098327040672,
      -0.0052400571294128895,
      0.006703988183289766,
      0.069005087018013,
      0.05947408825159073,
      0.023198680952191353,
      -0.018381332978606224,
      0.057504311203956604,
      0.03282850980758667,
      0.009552537463605404,
      0.058961428701877594,
      -0.009174483828246593,
      0.05383365973830223,
      0.013999504037201405,
      0.019550258293747902,
      0.024176063016057014,
      -0.00858310703188181,
      -0.06918927282094955,
      -0.07620743662118912,
      0.02178584598004818,
      -0.07586852461099625,
      -0.01752847619354725,
      0.021594980731606483,
      0.06048969551920891,
      0.10762037336826324,
      -0.02207985892891884,
      -0.02224595844745636,
      -0.0038102371618151665,
      -0.0307917520403862,
      -0.011622284539043903,
      0.010128730908036232,
      -0.09097054600715637,
      -0.027238572016358376,
      0.01864355057477951,
      0.053676992654800415,
      -0.0175192691385746,
      0.05239327996969223,
      0.022894971072673798,
      0.058369096368551254,
      -0.07057610899209976,
      -0.03718341141939163,
      0.027614213526248932,
      0.03208333998918533,
      -0.01457696221768856,
      0.04290618747472763,
      0.04647001624107361,
      0.038788482546806335,
      0.00755544938147068,
      0.005547781009227037,
      -0.006953731179237366,
      -0.012793787755072117,
      -0.03637431189417839,
      -0.03070167265832424,
      -0.04299194738268852,
      0.05145621672272682,
      0.010434438474476337,
      -0.029247194528579712,
      0.0796816349029541,
      0.03327416628599167,
      0.02631911262869835,
      -0.052699703723192215,
      -0.018958399072289467,
      0.008579348213970661,
      0.04514887183904648,
      0.08213512599468231,
      -0.00523790530860424,
      0.03049454092979431,
      -0.05477548763155937,
      0.015892311930656433,
      0.07144385576248169,
      -0.019779611378908157,
      0.015126174315810204,
      -0.005703803617507219,
      -0.03697476536035538,
      0.06640292704105377,
      -0.010803581215441227,
      0.05915256589651108,
      0.005203131586313248,
      0.019086740911006927,
      -0.009586459025740623,
      -0.03908026963472366,
      -0.009746967814862728,
      -0.08505810052156448,
      0.1143789142370224,
      -0.06950079649686813,
      0.07475937902927399,
      0.051882054656744,
      -0.01328686997294426,
      -0.042862605303525925,
      -0.04046250134706497,
      0.019777460023760796,
      -0.013415752910077572,
      0.020363235846161842,
      -0.057636458426713943,
      -0.06644288450479507,
      -0.023124659433960915,
      0.062090206891298294,
      0.06575072556734085,
      0.041968878358602524,
      -0.0014448609435930848,
      -0.123200424015522,
      -0.056876227259635925,
      -0.03434949740767479,
      0.010723737999796867,
      0.008104918524622917,
      0.021086839959025383,
      0.07645943760871887,
      -0.04985707625746727,
      0.08597701042890549,
      0.05228472128510475,
      0.021526597440242767,
      -0.0518810860812664,
      0.04671209305524826,
      0.0650433599948883,
      0.014792857691645622,
      -0.15437430143356323,
      -0.016112592071294785,
      0.038901437073946,
      0.005649699829518795
    ],
    [
      0.033369291573762894,
      -0.05005991458892822,
      0.03736947849392891,
      0.0189074594527483,
      -0.03703060746192932,
      0.0067781987600028515,
      0.055507611483335495,
      0.004925137851387262,
      -0.05430889502167702,
      0.027027007192373276,
      -0.05263489857316017,
      0.06522074341773987,
      -0.06300440430641174,
      -0.04334074258804321,
      0.04268217086791992,
      0.0002943304425571114,
      -0.04087565839290619,
      0.013234400190412998,
      0.028818203136324883,
      0.03466933220624924,
      -0.04142159968614578,
      0.007829267531633377,
      0.0310702882707119,
      0.060175295919179916,
      -0.04272495210170746,
      -0.04199829697608948,
      -0.012793316505849361,
      0.13530635833740234,
      0.06607900559902191,
      0.012010806240141392,
      0.07189325988292694,
      0.013585276901721954,
      0.06434313207864761,
      0.007828475907444954,
      0.05266045033931732,
      0.014244399033486843,
      0.08368473500013351,
      0.09277210384607315,
      0.029435575008392334,
      0.027048543095588684,
      0.05323927104473114,
      -0.0072384849190711975,
      -0.04566367715597153,
      -0.005554843228310347,
      0.017273619771003723,
      -0.07759734988212585,
      0.04060501977801323,
      -0.11406700313091278,
      0.03415146470069885,
      -0.0140609759837389,
      0.02850220538675785,
      0.03458026796579361,
      -0.079710453748703,
      0.012026757001876831,
      0.011409626342356205,
      0.04449000582098961,
      -0.08462709188461304,
      -0.05210849642753601,
      -0.03002260997891426,
      -0.022554172202944756,
      0.053508199751377106,
      -0.044347334653139114,
      0.019392814487218857,
      -0.021048584952950478,
      -0.01861158013343811,
      0.0008797001210041344,
      0.04230119660496712,
      0.006203984376043081,
      0.010014327242970467,
      0.09255371987819672,
      -0.028382685035467148,
      0.028669429942965508,
      -0.04848913848400116,
      -0.06220996379852295,
      -0.021921711042523384,
      0.03731387481093407,
      0.000994106405414641,
      -0.0006697537610307336,
      -0.04144676774740219,
      0.010209821164608002,
      0.01642420142889023,
      0.024099014699459076,
      0.006547708995640278,
      -0.028607288375496864,
      -0.014756743796169758,
      -0.0685649961233139,
      -0.015483921393752098,
      -0.07462446391582489,
      -0.0001387540833093226,
      -0.0777372345328331,
      0.04576530307531357,
      0.09516429901123047,
      0.02781486324965954,
      0.01686820574104786,
      -0.0029933431651443243,
      -0.011491017416119576,
      -0.004161620046943426,
      -0.0073890043422579765,
      0.04545307159423828,
      -0.0041059572249650955,
      -0.05711332708597183,
      0.013550043106079102,
      0.026503365486860275,
      0.06590447574853897,
      -0.011661759577691555,
      0.013901179656386375,
      -0.0005207709618844092,
      0.04541127011179924,
      -0.028642963618040085,
      -0.10314971208572388,
      -0.048355583101511,
      0.06143127381801605,
      0.03143870830535889,
      -0.05244553089141846,
      -0.046982962638139725,
      -0.030762899667024612,
      -0.012807943858206272,
      0.09234077483415604,
      0.1611369252204895,
      -0.05992107465863228,
      -0.05556122586131096,
      0.07390474528074265,
      0.030318183824419975,
      0.00798116996884346,
      -0.005840258672833443,
      -0.0675683319568634,
      0.053196825087070465,
      -0.12002367526292801,
      -0.06670281291007996,
      0.01713385619223118,
      -0.008475128561258316,
      -0.02457273378968239,
      0.018013495951890945,
      -0.0784711018204689,
      0.040731970220804214,
      -0.058556728065013885,
      -0.005644175224006176,
      -0.08304602652788162,
      0.0023743377532809973,
      -0.045216936618089676,
      -0.05199709162116051,
      0.014353948645293713,
      -0.015423974953591824,
      0.047359976917505264,
      -0.05706129968166351,
      0.04776795208454132,
      0.06157565116882324,
      -0.004314201418310404,
      0.02608514204621315,
      0.03314995393157005,
      -0.051275886595249176,
      0.010951532982289791,
      0.0633915364742279,
      0.040265828371047974,
      -0.011049985885620117,
      0.043044254183769226,
      0.06043614074587822,
      -0.0177259873598814,
      0.039329465478658676,
      -0.06750553101301193,
      0.02597743086516857,
      -0.009217699989676476,
      0.03804491087794304,
      0.022426825016736984,
      0.029791418462991714,
      -0.00209666951559484,
      -0.011229561641812325,
      0.015765896067023277,
      0.03605546057224274,
      -0.0049866498447954655,
      0.006939811632037163,
      -0.07685133814811707,
      -0.013936919160187244,
      -0.011780262924730778,
      -0.08015616983175278,
      -0.012181988917291164,
      -0.005695354659110308,
      0.07798273116350174,
      0.006423900369554758,
      0.07227042317390442,
      -0.022148074582219124,
      0.0491119921207428,
      0.023561790585517883,
      -0.1158158928155899,
      -0.07299574464559555,
      0.0003623868396971375,
      -0.038960859179496765,
      0.020573792979121208,
      0.011153836734592915,
      -0.012433553114533424,
      0.009021376259624958,
      0.0037921774201095104,
      0.09487270563840866,
      -0.03469120338559151,
      0.01524376217275858,
      0.021101651713252068,
      0.03356050327420235,
      0.0028401929885149,
      0.04616640508174896,
      0.00479907775297761,
      0.05670400708913803,
      0.005715199746191502,
      -0.0952833890914917,
      0.027661560103297234,
      -0.009932074695825577,
      -0.04860592633485794,
      0.05088701844215393,
      -0.06413080543279648,
      -0.029054740443825722,
      -0.028262333944439888,
      0.020460916683077812,
      -0.03998631611466408,
      0.0359143428504467,
      -0.04251734912395477,
      0.040747370570898056,
      -0.02254357375204563,
      -0.02080608531832695,
      0.014522578567266464,
      -0.058097466826438904,
      0.06316466629505157,
      0.00598537502810359,
      -0.009281804785132408,
      0.052729878574609756,
      0.05076489597558975,
      0.04932068660855293,
      0.09295029193162918,
      -0.0407339483499527,
      -0.0712263435125351,
      0.026548750698566437,
      0.014861182309687138,
      0.0032884348183870316,
      0.005926867946982384,
      -0.04158465564250946,
      -0.04030969366431236,
      0.04726848751306534,
      -0.01391057763248682,
      -0.015120656229555607,
      -0.09149347245693207,
      0.00425522867590189,
      -0.05304016172885895,
      -0.037406809628009796,
      -0.06896373629570007,
      0.08647453039884567,
      0.009503263048827648,
      -0.054587170481681824,
      0.05493413656949997,
      -0.017624609172344208,
      0.007534942124038935,
      0.008460940793156624,
      -0.015299702063202858,
      -0.01271281111985445,
      0.012105390429496765,
      -0.08324306458234787,
      -0.03957275301218033,
      -0.0002417712821625173,
      0.01746276393532753,
      0.05009326711297035,
      0.03782963007688522,
      -0.025239750742912292,
      -0.0627208799123764,
      0.04971587285399437,
      0.057358432561159134,
      -0.03115682490170002,
      -0.05521693080663681,
      0.021245043724775314,
      -0.07060207426548004,
      0.0005869926535524428,
      0.01678832247853279,
      -0.010699056088924408,
      0.010931958444416523,
      0.022552073001861572,
      -0.007252929266542196,
      0.013911270536482334,
      -0.012532089836895466,
      -0.017919033765792847,
      -0.018734179437160492,
      0.019904620945453644,
      0.006279408931732178,
      0.019405994564294815,
      0.05371867120265961,
      0.13024219870567322,
      0.005694575607776642,
      -0.013219691812992096,
      0.03150375187397003,
      0.03919197618961334,
      0.051250211894512177,
      0.024187713861465454,
      0.09729152172803879,
      -0.006024912465363741,
      -0.03611210361123085,
      -0.021635008975863457,
      0.03268074616789818,
      0.04306909441947937,
      -0.0382782407104969,
      0.015630073845386505,
      0.03133789822459221,
      -0.060793109238147736,
      0.015419223345816135,
      -0.13397857546806335,
      -0.04101953282952309,
      0.026354826986789703,
      -0.022633874788880348,
      0.038728017359972,
      0.055654123425483704,
      0.035663679242134094,
      0.0723792165517807,
      -0.005606539081782103,
      0.019102465361356735,
      -0.03999190032482147,
      -0.03853878378868103,
      -0.04244328290224075,
      0.0269655529409647,
      0.08917359262704849,
      0.11056798696517944,
      -0.013734797015786171,
      0.07191368192434311,
      0.12111295759677887,
      0.06649792194366455,
      -0.0007347097271122038,
      0.02809925563633442,
      0.05583673715591431,
      -0.01937810331583023,
      -0.010796983726322651,
      0.06039350852370262,
      -0.017169922590255737,
      0.006115793716162443,
      -0.02483489364385605,
      0.010995982214808464,
      0.06918347626924515,
      -0.06568332016468048,
      0.0577867217361927,
      -0.07514463365077972,
      -0.00012341534602455795,
      0.041188549250364304,
      -0.004042335785925388,
      0.026423634961247444,
      0.023407934233546257,
      -0.020192967727780342,
      -0.019613642245531082,
      0.049351535737514496,
      -0.04397053271532059,
      0.09416250139474869,
      -0.030811430886387825,
      -0.03486090525984764,
      -0.055274832993745804,
      0.04426214098930359,
      0.0753304660320282,
      0.010045341216027737,
      0.01456738356500864,
      0.02663792297244072,
      0.04123227670788765,
      -0.00649834331125021,
      0.05138690397143364,
      0.048649005591869354,
      0.03532809764146805,
      0.017036471515893936,
      -0.018776146695017815,
      -0.060365352779626846,
      -0.07562367618083954,
      -0.04787377268075943,
      -0.04429672658443451,
      -0.0804404616355896,
      0.07932113111019135,
      0.04323528707027435,
      0.005610249005258083,
      -0.011853983625769615,
      -0.05478516221046448,
      -0.09233032166957855,
      0.007314187474548817,
      0.005301415454596281,
      0.0636180192232132,
      0.01824791729450226,
      -0.05284183844923973,
      0.03577018529176712,
      0.0703844204545021,
      0.027110455557703972,
      0.003929193131625652,
      -0.0472676157951355,
      0.029861150309443474,
      0.010875496082007885,
      0.009048002772033215,
      -0.022837607190012932,
      0.07631391286849976,
      -0.02970172092318535,
      0.058023709803819656,
      0.00696964468806982,
      0.02265816554427147,
      0.037807196378707886,
      0.08225887268781662,
      -0.047718822956085205,
      -0.02892754226922989,
      0.049121659249067307,
      0.08007947355508804,
      -0.0003305141581222415,
      0.05081517621874809,
      -0.023470202460885048,
      -0.01688852161169052,
      0.04447820410132408,
      0.0038224023301154375,
      0.10289695858955383,
      -0.03488220274448395,
      -0.022118058055639267,
      -0.011581546626985073,
      -0.07778937369585037,
      0.012085916474461555,
      -0.03485613688826561,
      -0.08930179476737976,
      0.009393515065312386,
      -0.018473979085683823,
      -0.019302576780319214,
      -0.031720586121082306,
      0.05687734857201576,
      -0.027505429461598396,
      0.03252213075757027,
      -0.034553345292806625,
      0.0074026091024279594,
      -0.07166620343923569,
      0.06406552344560623,
      0.10933335870504379,
      0.05520779639482498,
      0.03269528970122337,
      0.02270306833088398,
      -0.06594718247652054,
      0.040326718240976334,
      -0.013279756531119347,
      -0.011459817178547382,
      0.04457909241318703,
      0.009666814468801022,
      0.106458380818367,
      0.12727484107017517,
      -0.024347910657525063,
      -0.051448334008455276,
      -0.04466210678219795,
      -0.010383035987615585,
      0.034454599022865295,
      -0.051866255700588226,
      -0.04074590653181076,
      0.06706026941537857,
      -0.07668505609035492,
      -0.05700675770640373,
      0.015884678810834885,
      -0.026270899921655655,
      0.034895118325948715,
      0.07304883003234863,
      -0.036813780665397644,
      -0.040780700743198395,
      0.03643348067998886,
      -0.0331692211329937,
      0.06829124689102173,
      0.04027042165398598,
      -0.03533441200852394,
      0.07360469549894333,
      -0.00037614419125020504,
      0.008198321796953678,
      0.03001517802476883,
      0.0006609411211684346,
      -0.0075133503414690495,
      -0.0025658579543232918,
      0.0392027273774147,
      0.034873150289058685,
      -0.06638212502002716,
      0.005032540298998356,
      -0.029483407735824585,
      -0.04230574890971184,
      0.01555958017706871,
      0.03413136303424835,
      -0.03452819213271141,
      0.08944106847047806,
      0.0013869204558432102,
      0.015647558495402336,
      0.02816406823694706,
      0.0039056355599313974,
      -0.024419939145445824,
      0.05706591159105301,
      -0.021066205576062202,
      -0.05113685876131058,
      0.01661776565015316,
      0.13985005021095276,
      -0.01888751983642578,
      -0.043669458478689194,
      0.011794984340667725,
      -0.020491788163781166,
      -0.017594709992408752,
      -0.053890056908130646,
      0.001838652533479035,
      -0.0478455014526844,
      -0.001447630813345313,
      0.05759753659367561,
      -0.01336468756198883,
      -0.0037930146791040897,
      0.007041133940219879,
      -0.04773794487118721,
      0.05608757585287094,
      0.01617204025387764,
      0.0008092573843896389,
      0.08004463464021683,
      0.024744439870119095,
      0.014424671418964863,
      0.06945579499006271,
      -0.023838002234697342,
      -0.011244310066103935,
      -0.002325258683413267,
      -0.036109406501054764
    ],
    [
      -0.048745471984148026,
      0.0786084309220314,
      -0.015885964035987854,
      -0.07754914462566376,
      0.02284693345427513,
      -0.0034673865884542465,
      0.02888290397822857,
      0.03422652930021286,
      0.07545900344848633,
      -0.05642464756965637,
      0.07143037766218185,
      0.011332035064697266,
      -0.039045557379722595,
      0.010349995456635952,
      0.011653675697743893,
      -0.054582707583904266,
      0.005232177674770355,
      0.014014570973813534,
      0.051192477345466614,
      -0.02445036917924881,
      -0.031697213649749756,
      -0.09829584509134293,
      -0.04335601255297661,
      0.012566890567541122,
      0.011829664930701256,
      -0.027586504817008972,
      0.018601858988404274,
      0.04475598782300949,
      -0.0019155180780217052,
      -0.0014821679797023535,
      -0.05567897483706474,
      -0.026430463418364525,
      0.04352940618991852,
      -0.06180151551961899,
      0.014759824611246586,
      0.04282506927847862,
      0.02120176889002323,
      -0.038610756397247314,
      -0.05017373338341713,
      0.003529260167852044,
      0.039635129272937775,
      0.00966386217623949,
      -0.020528865978121758,
      -0.014431055635213852,
      -0.0766083225607872,
      0.03515966981649399,
      -0.002824116265401244,
      -0.031096581369638443,
      -0.022341808304190636,
      -0.03876572474837303,
      0.020151473581790924,
      0.005861976183950901,
      -0.019887138158082962,
      0.0019825873896479607,
      0.024499693885445595,
      -0.02123299427330494,
      -0.07803806662559509,
      -0.037739191204309464,
      0.07173484563827515,
      0.026174897328019142,
      -0.08227109163999557,
      -0.024222001433372498,
      0.015831448137760162,
      0.04668078571557999,
      -0.06328219175338745,
      0.006019501015543938,
      -0.006626683287322521,
      0.01232392992824316,
      0.0007786533678881824,
      0.04692980274558067,
      -0.0029508958104997873,
      -0.006341667380183935,
      -0.0155856404453516,
      -1.7478159861639142e-05,
      0.04888521507382393,
      -0.08418556302785873,
      0.009500177577137947,
      -0.030835120007395744,
      0.01173238642513752,
      0.022852279245853424,
      -0.0653630793094635,
      -0.03117552027106285,
      -0.008695026859641075,
      0.023062758147716522,
      0.028275515884160995,
      0.04934090003371239,
      0.0726126879453659,
      0.09495999664068222,
      0.011328459717333317,
      0.07297330349683762,
      -0.06064195930957794,
      -0.049293216317892075,
      -0.0005468466551974416,
      -0.0006077932775951922,
      -0.04045841842889786,
      -0.07669274508953094,
      0.010288326069712639,
      0.0012354704085737467,
      0.008141111582517624,
      0.029424473643302917,
      0.020195934921503067,
      -0.07142963260412216,
      0.044909145683050156,
      0.03379058092832565,
      0.030090756714344025,
      0.016880327835679054,
      -0.04771045222878456,
      0.07736057043075562,
      -0.010485555045306683,
      -0.03288763388991356,
      -0.05652124062180519,
      -0.07849489897489548,
      -0.03427384793758392,
      0.06182960420846939,
      0.06599171459674835,
      0.026863088831305504,
      0.0039034311193972826,
      0.0464436374604702,
      -0.04811950400471687,
      -0.03959338739514351,
      0.05505046248435974,
      -0.046811703592538834,
      -0.0569094754755497,
      -0.02401084452867508,
      -0.05118279904127121,
      -0.0355057492852211,
      -0.0035939442459493876,
      -0.05207762494683266,
      0.07329726964235306,
      -0.012483110651373863,
      0.021868640556931496,
      0.033186789602041245,
      -0.0033807731233537197,
      -0.0043235947377979755,
      0.011962122283875942,
      0.005690702237188816,
      0.0659865140914917,
      0.0507650151848793,
      0.02631872147321701,
      0.01355281937867403,
      0.06313473731279373,
      0.08132805675268173,
      -0.01142987422645092,
      0.06829182058572769,
      0.009274238720536232,
      0.04468447342514992,
      0.007400545757263899,
      0.013881847262382507,
      -0.08978161960840225,
      -0.038105640560388565,
      -0.04859551787376404,
      0.07445000857114792,
      -0.033733054995536804,
      0.014110376127064228,
      -0.07901185750961304,
      0.0224023275077343,
      -0.06402070820331573,
      -0.04128468781709671,
      0.055585965514183044,
      -0.041662946343421936,
      -0.05466495826840401,
      -0.03500654175877571,
      -0.028516964986920357,
      -0.03259437158703804,
      -0.04924032464623451,
      0.03218046575784683,
      -0.028216656297445297,
      0.027061618864536285,
      -0.025136390700936317,
      -0.06277356296777725,
      0.0002495483204256743,
      0.0010764271719381213,
      -0.030269619077444077,
      -0.035101231187582016,
      -0.08653358370065689,
      0.017935579642653465,
      -0.028146442025899887,
      0.03296607732772827,
      -8.519020775565878e-05,
      -0.07297798246145248,
      0.019975243136286736,
      -0.008186925202608109,
      0.03164951875805855,
      0.04705742746591568,
      -0.036924757063388824,
      0.006079251412302256,
      -0.016619805246591568,
      -0.011394689790904522,
      0.016304655000567436,
      0.06957278400659561,
      -0.0582038052380085,
      0.0028632893227040768,
      -0.012764213606715202,
      -0.07792264968156815,
      -0.005905334372073412,
      0.06875694543123245,
      0.0030306593980640173,
      0.020625146105885506,
      -0.021674644201993942,
      -0.09429153054952621,
      -0.10627864301204681,
      -0.08603227138519287,
      0.03609541431069374,
      0.005076271947473288,
      -0.11409767717123032,
      0.034153785556554794,
      -0.004691361915320158,
      -0.053476300090551376,
      -0.008513334207236767,
      -0.009924516081809998,
      -0.021083222702145576,
      -0.014606957323849201,
      -0.04342929273843765,
      -0.049501933157444,
      -0.03794856742024422,
      -0.04666856303811073,
      -0.048459626734256744,
      0.008832128718495369,
      -0.015159250237047672,
      0.05153030902147293,
      0.04310745373368263,
      0.05397578701376915,
      -0.005561073310673237,
      -0.09258996695280075,
      0.0852883905172348,
      0.10148639231920242,
      0.007240749895572662,
      0.044691767543554306,
      -0.02411944791674614,
      0.0019304550951346755,
      0.005807042121887207,
      -0.026815729215741158,
      -0.07405512034893036,
      -0.03802908584475517,
      -0.0045343548990786076,
      -0.015317248180508614,
      0.022679658606648445,
      -0.036594782024621964,
      0.06746343523263931,
      -0.08712157607078552,
      -0.003774364246055484,
      0.008965406566858292,
      -0.027526339516043663,
      -0.022894728928804398,
      -0.04900185018777847,
      0.015036258846521378,
      0.02410593442618847,
      -0.028043903410434723,
      -0.06848829239606857,
      0.0009476995910517871,
      -0.020807486027479172,
      0.05688948556780815,
      -0.0450991615653038,
      0.0723966583609581,
      -0.04705893620848656,
      0.07084006816148758,
      0.0027442998252809048,
      0.034447796642780304,
      0.04979478940367699,
      0.010456454940140247,
      0.023743705824017525,
      -0.052107829600572586,
      0.0009737011278048158,
      -0.003206667024642229,
      0.007495614234358072,
      0.08358549326658249,
      -0.03319912031292915,
      -0.0019767016638070345,
      0.023751068860292435,
      0.07260429114103317,
      -0.026122381910681725,
      0.01730194315314293,
      -0.06174742057919502,
      -0.020888790488243103,
      0.0390130989253521,
      0.02090686932206154,
      -0.06853232532739639,
      -0.047340914607048035,
      -0.0025596008636057377,
      -0.007617567200213671,
      0.02646057680249214,
      -0.0957205519080162,
      -0.030365150421857834,
      0.042420387268066406,
      0.027461931109428406,
      -0.018705148249864578,
      0.10618963837623596,
      0.1149892657995224,
      -0.00882641039788723,
      -0.05340689793229103,
      0.055668123066425323,
      0.03156522661447525,
      -0.020052587613463402,
      -0.05035446584224701,
      -0.06834354996681213,
      0.02397109568119049,
      0.012483431026339531,
      0.06149495765566826,
      -0.022219456732273102,
      -0.010371174663305283,
      0.05577882379293442,
      0.0008613774552941322,
      -0.036024075001478195,
      -0.04094470292329788,
      -0.05944660305976868,
      -0.03826330974698067,
      0.03392054885625839,
      0.014009232632815838,
      -0.047315116971731186,
      -0.05223875865340233,
      0.11456936597824097,
      0.0045333621092140675,
      -0.0007528917049057782,
      -0.028168438002467155,
      -0.0760084018111229,
      -0.043766994029283524,
      -0.013960028067231178,
      0.04300655424594879,
      0.001862264471128583,
      -0.020703554153442383,
      -0.04402077943086624,
      -0.018671559169888496,
      -0.042068567126989365,
      0.0012613792205229402,
      0.005868813022971153,
      -0.05275179445743561,
      -0.017150865867733955,
      -0.061788320541381836,
      -0.037957094609737396,
      0.026537366211414337,
      0.0766286849975586,
      0.0281416866928339,
      0.00611317390576005,
      -0.01674177497625351,
      0.043304476886987686,
      -0.07147588580846786,
      -0.06162986159324646,
      -0.021109292283654213,
      0.01836482062935829,
      0.06785376369953156,
      0.01300110761076212,
      -0.04220014438033104,
      0.06110978499054909,
      -0.04004262015223503,
      0.05555630847811699,
      0.058361493051052094,
      0.09018909931182861,
      0.04362890124320984,
      -0.028475141152739525,
      0.012759892269968987,
      -0.018538091331720352,
      -0.011535944417119026,
      -0.0007454923470504582,
      -0.0846787765622139,
      0.02722700871527195,
      -0.030798114836215973,
      0.01684398762881756,
      0.0729023888707161,
      0.010840356349945068,
      0.03874374181032181,
      0.08901780843734741,
      -0.05282467231154442,
      -0.02372199296951294,
      -0.02244308963418007,
      0.02497459389269352,
      -0.04079989343881607,
      0.0010792742250487208,
      0.030122846364974976,
      0.03131401911377907,
      -0.004297351464629173,
      0.05608227103948593,
      0.04089130461215973,
      0.04352038353681564,
      0.09877295047044754,
      -0.0010197972878813744,
      -0.01580112986266613,
      0.021808458492159843,
      -0.04858927056193352,
      0.06535465270280838,
      -0.057373978197574615,
      -0.07568543404340744,
      -0.02546115219593048,
      -0.023058876395225525,
      -0.0007160173845477402,
      0.08757142722606659,
      -0.03216651827096939,
      -0.08472373336553574,
      -0.10011803358793259,
      -0.03221564739942551,
      -0.014076578430831432,
      -0.047736845910549164,
      -0.03960913047194481,
      -0.023314926773309708,
      0.01520815584808588,
      0.02530520409345627,
      0.052264079451560974,
      0.07486167550086975,
      0.047549061477184296,
      -0.013293324038386345,
      0.024927247315645218,
      0.02886238321661949,
      0.019113142043352127,
      -0.030415374785661697,
      -0.03464673459529877,
      -0.035839058458805084,
      0.027879390865564346,
      -0.02853972278535366,
      0.014558341354131699,
      0.0003040852607227862,
      -0.01831032522022724,
      0.003980436362326145,
      0.02648077718913555,
      -0.01585470698773861,
      -0.039355598390102386,
      0.032136958092451096,
      0.05061846598982811,
      0.03299598768353462,
      -0.010884176939725876,
      -0.056532930582761765,
      -0.06784079223871231,
      0.0056303818710148335,
      0.02975098416209221,
      0.02064346894621849,
      0.050995007157325745,
      0.037551894783973694,
      -0.044796012341976166,
      -0.07062846422195435,
      0.054921526461839676,
      0.02159433811903,
      -0.07303653657436371,
      0.02192777581512928,
      0.08715017139911652,
      -0.05226302519440651,
      -0.09968090802431107,
      -0.015388584695756435,
      0.03154829889535904,
      -0.011107240803539753,
      -0.043718382716178894,
      0.08414444327354431,
      -0.09286348521709442,
      0.004093185067176819,
      -0.0036118675488978624,
      0.009023354388773441,
      0.027061304077506065,
      0.02457495778799057,
      -0.06630929559469223,
      0.08730152994394302,
      -0.050214193761348724,
      0.04637262597680092,
      -0.02646564692258835,
      0.05694758519530296,
      0.07362578809261322,
      -0.013226617127656937,
      0.045860931277275085,
      0.020916609093546867,
      0.07881109416484833,
      -0.017182892188429832,
      0.05000976845622063,
      -0.02306201308965683,
      0.04815257340669632,
      0.012850423343479633,
      -0.0021680081263184547,
      0.012648873031139374,
      0.03799620643258095,
      -0.03617790713906288,
      0.004061739891767502,
      -0.06694380939006805,
      -0.020739926025271416,
      0.05209435895085335,
      0.06786295771598816,
      0.04664991796016693,
      0.06851226091384888,
      0.03876255080103874,
      0.026972878724336624,
      -0.05113457888364792,
      0.0622234009206295,
      -0.03601960465312004,
      -0.06853608787059784,
      0.015335453674197197,
      -0.025032654404640198,
      -0.009571772068738937,
      -0.03768058866262436,
      -0.09094611555337906,
      0.016588620841503143,
      -0.030304448679089546,
      -0.027613958343863487,
      -0.03590046614408493,
      -0.02172689326107502,
      -0.06298188120126724,
      -0.007532002869993448,
      -0.0389341376721859,
      -0.0033537575509399176,
      -0.04623208940029144,
      0.05561353266239166,
      -0.03823873773217201,
      0.007682391442358494,
      -0.005086515098810196,
      0.00016885943477973342,
      -0.03109768219292164,
      0.008507591672241688,
      0.057200923562049866,
      -0.005134775768965483,
      -0.02436709590256214
    ],
    [
      0.08691179752349854,
      -0.022082272917032242,
      0.0036097350530326366,
      -0.032210271805524826,
      0.006517284084111452,
      -0.04741768166422844,
      -0.038095541298389435,
      0.014830449596047401,
      -0.01464379858225584,
      -0.036648523062467575,
      0.05944608151912689,
      0.04105043411254883,
      0.03227562829852104,
      0.02521115355193615,
      0.06291059404611588,
      0.06050365790724754,
      -0.0009719738736748695,
      -0.01145591028034687,
      0.031997062265872955,
      0.06225496158003807,
      -0.01529726106673479,
      0.00447872094810009,
      -0.019306514412164688,
      -0.08943995088338852,
      -0.018801681697368622,
      0.0011150959180667996,
      -0.05228019505739212,
      0.019001754000782967,
      0.10165850073099136,
      -0.0070227645337581635,
      -0.001741089392453432,
      -0.03492452949285507,
      0.002029644325375557,
      -0.03651425987482071,
      0.059622909873723984,
      0.036232732236385345,
      0.027265936136245728,
      -0.007853151299059391,
      0.056155577301979065,
      0.006531683262437582,
      0.0049275620840489864,
      -0.03834455460309982,
      0.030709557235240936,
      -0.021633585914969444,
      -0.01720079407095909,
      -0.00534979160875082,
      -0.02144216001033783,
      -0.03550272434949875,
      -0.021751753985881805,
      0.07191047072410583,
      0.025683598592877388,
      0.022422106936573982,
      0.04298468306660652,
      0.030077245086431503,
      -0.07217154651880264,
      0.002179240109398961,
      0.013296995311975479,
      0.09560205787420273,
      0.050913192331790924,
      0.04706883803009987,
      -0.03994492068886757,
      -0.053165435791015625,
      -0.025814570486545563,
      -0.0675710067152977,
      0.003823477542027831,
      0.03707124665379524,
      0.007498695515096188,
      -0.046091996133327484,
      -0.04626179113984108,
      0.021634092554450035,
      -0.034804265946149826,
      0.09485726803541183,
      -0.005621207412332296,
      0.009063288569450378,
      0.05181987211108208,
      0.00046341345296241343,
      -0.014462153427302837,
      0.06680142879486084,
      -0.009435146115720272,
      0.020950615406036377,
      -0.030239515006542206,
      0.07428279519081116,
      0.01980450376868248,
      0.0461898073554039,
      -0.07306364178657532,
      -0.03806858882308006,
      -0.008341346867382526,
      0.030092312023043633,
      -0.021639956161379814,
      -0.061348073184490204,
      -0.004124263301491737,
      0.08054214715957642,
      -0.023394575342535973,
      -0.01592520996928215,
      -0.02641315385699272,
      0.005223318934440613,
      -0.009934873320162296,
      0.020303938537836075,
      0.0594920851290226,
      0.004205972421914339,
      0.02046721801161766,
      0.03617902100086212,
      0.061305589973926544,
      0.016750244423747063,
      -0.04308811202645302,
      0.015315539203584194,
      -0.02646477520465851,
      -0.02508910745382309,
      0.0012204305967316031,
      0.025972001254558563,
      -0.05598768964409828,
      -0.04293029382824898,
      -0.024378430098295212,
      0.009395520202815533,
      0.000676692056003958,
      -0.05131806433200836,
      -0.009158884175121784,
      0.06889797002077103,
      0.009185681119561195,
      0.01188990194350481,
      -0.12313773483037949,
      -0.04600103944540024,
      0.05180778354406357,
      -0.011410200968384743,
      0.03190114349126816,
      -0.00037065058131702244,
      -0.016825325787067413,
      0.0016022901982069016,
      0.014425267465412617,
      -0.06069047003984451,
      0.01728261262178421,
      -0.022941546514630318,
      -0.010201537050306797,
      0.03985185548663139,
      0.07857035100460052,
      0.03964533656835556,
      -0.011677946895360947,
      -0.07228238135576248,
      -0.05227867141366005,
      -0.07564553618431091,
      0.07981603592634201,
      0.030895903706550598,
      -0.005318873096257448,
      -0.0017345681553706527,
      -0.06368470191955566,
      0.020166687667369843,
      -0.0005759493797086179,
      -0.006337776314467192,
      0.08256851881742477,
      0.01690034009516239,
      -0.011649529449641705,
      -0.011347206309437752,
      -0.024769362062215805,
      -0.006851369049400091,
      -0.0676516443490982,
      0.05411965399980545,
      0.03443698585033417,
      -0.013643474318087101,
      -0.04331598058342934,
      -0.053616780787706375,
      -0.04621254280209541,
      0.013671706430613995,
      0.0015886471373960376,
      0.041443683207035065,
      -0.06089833006262779,
      -0.02816760167479515,
      0.030592873692512512,
      -0.06015238165855408,
      -0.05460715666413307,
      0.06467092782258987,
      0.031622905284166336,
      0.0346505381166935,
      0.05606793984770775,
      0.043418094515800476,
      0.015410883352160454,
      0.04031195491552353,
      -0.024249887093901634,
      0.004628719761967659,
      -0.04767583683133125,
      -0.047318145632743835,
      -0.04747513681650162,
      0.022047463804483414,
      -0.0030827121809124947,
      0.018655085936188698,
      0.05588863044977188,
      0.005599904339760542,
      0.032918136566877365,
      0.025122545659542084,
      -0.0020965279545634985,
      0.030173610895872116,
      0.06082896515727043,
      -0.06994879245758057,
      -0.06644666194915771,
      0.005133259110152721,
      -0.009527505375444889,
      0.02944117970764637,
      0.022284433245658875,
      -0.015021559782326221,
      0.03325458616018295,
      -0.004247586242854595,
      -0.041870955377817154,
      0.025829385966062546,
      0.018258465453982353,
      -0.05783146619796753,
      0.09568463265895844,
      0.046796321868896484,
      0.0008468938176520169,
      0.008222446776926517,
      0.05266157165169716,
      -0.07347671687602997,
      -0.0448962040245533,
      -0.02629775181412697,
      0.05931365117430687,
      -0.02210308611392975,
      -0.033029817044734955,
      0.042366139590740204,
      0.07498614490032196,
      -0.054959431290626526,
      0.024149153381586075,
      0.06628181040287018,
      0.010989327915012836,
      0.0026573098730295897,
      0.048718005418777466,
      0.06464739143848419,
      0.014734667725861073,
      0.04262074828147888,
      0.0086982985958457,
      0.04721878096461296,
      -0.0051835221238434315,
      0.04395213723182678,
      0.06738578528165817,
      0.11181258410215378,
      -0.02145567536354065,
      -0.005942862015217543,
      -0.04496528580784798,
      0.001014145789667964,
      -0.049855951219797134,
      0.09250806272029877,
      0.07220547646284103,
      0.03954875469207764,
      0.0931413397192955,
      -0.05429709330201149,
      -0.038372818380594254,
      -0.004152100533246994,
      0.0351642444729805,
      -0.14047174155712128,
      0.020061474293470383,
      0.0032857691403478384,
      -0.019216276705265045,
      0.03675169125199318,
      0.0005543222650885582,
      -0.04992293566465378,
      -0.028406785801053047,
      -0.015242469497025013,
      -0.03956557437777519,
      -0.010645485483109951,
      0.03586404398083687,
      0.03549865633249283,
      0.05086086690425873,
      0.04061906412243843,
      -0.019816257059574127,
      0.0018004541052505374,
      -0.027688605710864067,
      -0.03182543069124222,
      -0.0029707790818065405,
      0.03072059527039528,
      0.010115235112607479,
      0.015299994498491287,
      0.007560709025710821,
      0.019768763333559036,
      0.04478660598397255,
      -0.07382583618164062,
      0.010948451235890388,
      -0.02517397329211235,
      -0.06409720331430435,
      -0.05421123281121254,
      -0.034036632627248764,
      0.03497019410133362,
      0.05176898092031479,
      0.03149838000535965,
      0.023478157818317413,
      0.08870960026979446,
      -0.03356087580323219,
      -0.1654084175825119,
      -0.023142963647842407,
      -0.003950305748730898,
      -0.003156721591949463,
      0.014610045589506626,
      -0.03715275228023529,
      0.04589088261127472,
      -0.06768832355737686,
      0.06868566572666168,
      0.028508532792329788,
      -0.044669412076473236,
      -0.017598604783415794,
      0.006598015781491995,
      0.03979695588350296,
      0.04897141829133034,
      0.07325326651334763,
      -0.04515017941594124,
      0.09383849799633026,
      0.05649431422352791,
      0.01013355515897274,
      -0.051699984818696976,
      0.04459778219461441,
      0.02101164124906063,
      -0.049259476363658905,
      -0.04154720529913902,
      -0.01616215519607067,
      -0.035656869411468506,
      0.05114901065826416,
      0.06396646052598953,
      0.01890227198600769,
      -0.04252813756465912,
      -0.02767978236079216,
      0.017679067328572273,
      -0.019076764583587646,
      -0.018900973722338676,
      0.006172766909003258,
      -0.002946169814094901,
      -0.02413661777973175,
      0.00407969206571579,
      -0.019727464765310287,
      -0.025643913075327873,
      -0.030952075496315956,
      0.029597442597150803,
      0.08376933634281158,
      0.05384310707449913,
      -0.06517699360847473,
      0.04709075391292572,
      -0.024508627131581306,
      -0.011375696398317814,
      -0.011926787905395031,
      -0.08969283849000931,
      -0.016247453168034554,
      -0.025469815358519554,
      0.030782831832766533,
      0.03649018704891205,
      0.0041999309323728085,
      -0.024892793968319893,
      -0.02139817550778389,
      -0.026486936956644058,
      -0.03917501121759415,
      -0.015795502811670303,
      -0.11011062562465668,
      0.013222654350101948,
      0.046049565076828,
      0.015473614446818829,
      0.06415276229381561,
      0.047613807022571564,
      -0.0388590544462204,
      0.028547711670398712,
      -0.06413797289133072,
      0.0053809708915650845,
      0.025198904797434807,
      -0.031139684841036797,
      0.03626517206430435,
      -0.006972751580178738,
      -0.014142927713692188,
      0.13165047764778137,
      -0.06170576065778732,
      0.05685185641050339,
      0.031643446534872055,
      0.014210296794772148,
      -0.07868488132953644,
      0.07094455510377884,
      -0.03706233948469162,
      -0.015298216603696346,
      0.03749473765492439,
      0.044422756880521774,
      0.008653461001813412,
      0.017639070749282837,
      0.04396289214491844,
      0.02116524614393711,
      -0.012839613482356071,
      -0.04060496762394905,
      -0.01777673326432705,
      -0.021070582792162895,
      -0.00506672915071249,
      -0.04479162395000458,
      -0.03486926853656769,
      -0.026879705488681793,
      -0.030820155516266823,
      -0.040785275399684906,
      -0.0028658767696470022,
      -0.00012334177154116333,
      -0.030681870877742767,
      -0.0019838185980916023,
      0.07137218117713928,
      -0.01101219467818737,
      -0.04563518613576889,
      0.019374528899788857,
      0.04163751378655434,
      0.07949322462081909,
      0.06808289140462875,
      -0.08564984053373337,
      -0.08664458245038986,
      0.017204461619257927,
      -0.0500718355178833,
      0.06592810899019241,
      -0.08508720993995667,
      0.0023181673604995012,
      -0.06062621623277664,
      0.011149449273943901,
      -0.020422333851456642,
      0.04754412919282913,
      0.0013391863321885467,
      0.07438415288925171,
      -0.01028547715395689,
      0.0734393522143364,
      0.0898633599281311,
      0.05917325243353844,
      -0.002523500006645918,
      0.05009244382381439,
      -0.05101553350687027,
      -0.02685587666928768,
      0.012127566151320934,
      0.11097530275583267,
      0.016104910522699356,
      -0.004124961793422699,
      0.08652818948030472,
      0.045716289430856705,
      0.027009258046746254,
      -0.10079757869243622,
      -0.00609577214345336,
      -0.03900535777211189,
      0.06242648884654045,
      -0.011371996253728867,
      0.0187037643045187,
      0.0056479391641914845,
      0.010923773050308228,
      0.06639447808265686,
      -0.024677390232682228,
      0.0007268186891451478,
      0.01173465047031641,
      0.012553824111819267,
      0.0319373682141304,
      0.04331008344888687,
      -0.05556930974125862,
      0.020230507478117943,
      -0.043424591422080994,
      0.0350651815533638,
      -0.07398156076669693,
      -0.035542070865631104,
      0.0011512774508446455,
      0.013251589611172676,
      -0.09376101195812225,
      0.009556586854159832,
      0.0199641901999712,
      0.12153255194425583,
      0.0674365684390068,
      -0.06503909081220627,
      -0.038577280938625336,
      0.005895576439797878,
      -0.012074315920472145,
      0.00367557886056602,
      0.049958113580942154,
      0.03395255282521248,
      -0.01588662527501583,
      0.0559515580534935,
      -0.02189948968589306,
      0.025688501074910164,
      -0.08835022151470184,
      0.039970267564058304,
      -0.013369006104767323,
      -0.026164066046476364,
      -0.050399456173181534,
      0.03564991429448128,
      0.052035246044397354,
      0.005078567657619715,
      -0.09703309088945389,
      0.04605194553732872,
      0.008822162635624409,
      -0.017192991450428963,
      -0.00667080283164978,
      0.023707633838057518,
      -0.040138017386198044,
      0.022336816415190697,
      -0.02266686037182808,
      0.050543203949928284,
      -0.006551255006343126,
      0.05718572437763214,
      -0.02276601828634739,
      0.030268065631389618,
      -0.017677340656518936,
      -0.033636584877967834,
      0.01783575862646103,
      0.0062676831148564816,
      0.02177717350423336,
      0.024150678887963295,
      0.04401901736855507,
      0.005314249079674482,
      -0.035617321729660034,
      0.014930390752851963,
      -0.002513366285711527,
      -0.02300924062728882,
      -0.05736875161528587,
      0.016079606488347054,
      0.087441585958004,
      -0.06160649284720421,
      0.0028071487322449684,
      0.015344247221946716,
      0.0004253090301062912
    ],
    [
      0.06583315134048462,
      -0.12077847868204117,
      0.004817767068743706,
      -0.03386688232421875,
      -0.06062522903084755,
      0.0009911134839057922,
      -0.017864683642983437,
      -0.03249676898121834,
      0.020496703684329987,
      -0.035049308091402054,
      -0.10392381995916367,
      0.0007810800452716649,
      -0.09299018979072571,
      -0.06577868014574051,
      -0.01432405598461628,
      0.0405818447470665,
      -0.015021126717329025,
      -0.02095741033554077,
      0.005488764028996229,
      -0.02887631021440029,
      0.06891424208879471,
      0.02282085455954075,
      0.04558698460459709,
      0.04669300094246864,
      0.01322219893336296,
      -0.03587673604488373,
      0.017342358827590942,
      -0.03219456225633621,
      0.0773550346493721,
      0.07145074009895325,
      -0.09865609556436539,
      -0.030343925580382347,
      0.009030337445437908,
      0.019664639607071877,
      0.0012562284246087074,
      0.07996665686368942,
      -0.010439569130539894,
      -0.017875365912914276,
      0.006133812014013529,
      0.030481945723295212,
      0.025636665523052216,
      -0.021700093522667885,
      0.05369573459029198,
      0.007850272580981255,
      -0.033149804919958115,
      0.007761740125715733,
      -0.012366966344416142,
      -0.05806678906083107,
      0.09278035163879395,
      -0.08666177093982697,
      -0.05283616855740547,
      -0.0012587147066369653,
      -0.011840226128697395,
      -0.048269014805555344,
      0.03949587047100067,
      -0.01792902871966362,
      -0.04667564854025841,
      0.05218927934765816,
      0.015350854024291039,
      -0.004355530254542828,
      -0.01197290513664484,
      -0.05358525365591049,
      0.04177895933389664,
      -0.005112410522997379,
      -0.021375246345996857,
      0.06245730444788933,
      0.02589389495551586,
      0.05070953443646431,
      -0.06863373517990112,
      0.04531746357679367,
      -0.020076636224985123,
      -0.020962422713637352,
      -0.01428579818457365,
      0.0032952288165688515,
      0.04660433530807495,
      0.047501686960458755,
      -0.04251488670706749,
      -0.02013164944946766,
      0.019167838618159294,
      -0.02297334559261799,
      0.005101214163005352,
      -0.06453462690114975,
      0.019918885082006454,
      0.06771782785654068,
      0.016305886209011078,
      0.03981576859951019,
      0.04531514272093773,
      -0.007430735509842634,
      0.05503435805439949,
      0.027369406074285507,
      -0.0052760508842766285,
      0.03222006931900978,
      0.04205137863755226,
      -0.032882511615753174,
      -0.05086760222911835,
      -0.0504172258079052,
      0.005964279640465975,
      -0.057796910405159,
      -0.0352698490023613,
      0.08141272515058517,
      0.06487797200679779,
      0.05854281410574913,
      0.005594558548182249,
      -0.06496112793684006,
      0.022756829857826233,
      -0.04049084335565567,
      0.011467551812529564,
      0.04902055487036705,
      -0.0053200204856693745,
      -0.06602939963340759,
      -0.013527780771255493,
      -0.08069320768117905,
      -0.04954324662685394,
      -0.02635868452489376,
      -0.06665606796741486,
      0.016833454370498657,
      0.04785621538758278,
      0.0258955005556345,
      -0.06269170343875885,
      -0.012366604059934616,
      0.048925116658210754,
      0.031409457325935364,
      -0.03617967665195465,
      0.04374551400542259,
      0.01963728293776512,
      -0.02518763579428196,
      0.058715976774692535,
      0.050199467688798904,
      -0.0820283517241478,
      -0.027877889573574066,
      0.05604520067572594,
      -0.00959600880742073,
      0.0744815468788147,
      0.0239755529910326,
      0.05045519396662712,
      0.023859072476625443,
      -0.06383778154850006,
      0.034214962273836136,
      0.11696235835552216,
      -0.016819167882204056,
      0.009406193159520626,
      -0.005793391261249781,
      0.0028732172213494778,
      0.08756779134273529,
      0.04578336328268051,
      0.05122974514961243,
      -0.01484945509582758,
      -0.024238111451268196,
      0.02719571255147457,
      -0.10764262080192566,
      0.0326516218483448,
      -0.05084808170795441,
      0.0437537282705307,
      -0.002521236427128315,
      -0.005890911445021629,
      0.052335627377033234,
      0.03663614019751549,
      0.00047883877414278686,
      -0.055153168737888336,
      0.05128460004925728,
      -0.025935491546988487,
      0.01604710891842842,
      -0.01992219127714634,
      0.036751244217157364,
      -0.009472881443798542,
      0.03634803369641304,
      -0.02120557241141796,
      0.020808197557926178,
      0.035601697862148285,
      0.06150475889444351,
      -0.08727052807807922,
      -0.045268069952726364,
      0.07603579759597778,
      0.00021216385357547551,
      -0.05258781090378761,
      -0.012338035739958286,
      -0.08274352550506592,
      0.010481094941496849,
      -0.09488546848297119,
      -0.01775902882218361,
      -0.018147753551602364,
      0.0389949269592762,
      0.0178519319742918,
      -0.0652260109782219,
      -0.05523800849914551,
      -0.02231188863515854,
      0.06559018790721893,
      0.0009759157546795905,
      -0.026264861226081848,
      0.03550011292099953,
      0.002581635257229209,
      0.019121022894978523,
      0.05218014121055603,
      0.027919434010982513,
      0.08777552098035812,
      -0.01606200821697712,
      -0.04964544251561165,
      -0.047378234565258026,
      0.04031037911772728,
      0.034143876284360886,
      0.025766171514987946,
      0.04512724652886391,
      -0.005100456532090902,
      0.0520927719771862,
      0.008472626097500324,
      -0.012822668999433517,
      0.10984036326408386,
      0.014179510995745659,
      -0.04257616400718689,
      -0.03240359574556351,
      0.00793150532990694,
      -0.07479476183652878,
      -0.04198475182056427,
      -0.04774124547839165,
      0.010648522526025772,
      0.05548892915248871,
      0.03125213459134102,
      -0.02119266614317894,
      -0.023048125207424164,
      -0.008269112557172775,
      0.048466116189956665,
      -0.0291073489934206,
      0.03464219346642494,
      -0.027233771979808807,
      0.054924674332141876,
      -0.04065978154540062,
      0.0681527778506279,
      0.03313035890460014,
      0.02008366957306862,
      0.009898136369884014,
      -0.019709501415491104,
      -0.08615120500326157,
      -0.019366571679711342,
      -0.015484600327908993,
      0.037353456020355225,
      -0.014488538727164268,
      0.009968651458621025,
      0.00013497563486453146,
      -0.01259315013885498,
      0.04908415302634239,
      -0.012438751757144928,
      -0.03322238475084305,
      -0.005158974789083004,
      0.07087284326553345,
      0.003949585370719433,
      -0.0001413741265423596,
      0.025347519665956497,
      -0.0076698982156813145,
      0.0958610326051712,
      0.02762729860842228,
      0.04245063289999962,
      -0.03472075238823891,
      0.05263238027691841,
      0.04129577800631523,
      -0.008964242413640022,
      0.01593337021768093,
      0.040142402052879333,
      0.030005963519215584,
      -0.0659632682800293,
      0.07967985421419144,
      0.03936333954334259,
      -0.004691323731094599,
      -0.08229289948940277,
      0.013264302164316177,
      -0.06589958071708679,
      0.06149817258119583,
      -0.05776352435350418,
      -0.005415438674390316,
      -0.034283917397260666,
      -0.005543295759707689,
      0.031210483983159065,
      0.02038794383406639,
      0.12933848798274994,
      0.06048284098505974,
      -0.07892300188541412,
      -0.02049936354160309,
      0.020941397175192833,
      0.02072259597480297,
      0.09183217585086823,
      0.015035419724881649,
      0.06327296793460846,
      0.006243037059903145,
      0.04368889331817627,
      -0.025709019973874092,
      -0.05367002636194229,
      -0.005254372954368591,
      -0.029940485954284668,
      -0.01713014952838421,
      -0.015062950551509857,
      0.06111888214945793,
      -0.010193999856710434,
      0.05556997284293175,
      -0.07901477068662643,
      0.004913610871881247,
      -0.0207118708640337,
      -0.018462730571627617,
      -0.06726541370153427,
      0.02763669192790985,
      6.532030965900049e-06,
      0.11148881167173386,
      -0.08870970457792282,
      -0.010893517173826694,
      0.007265501189976931,
      -0.14468179643154144,
      0.0377233512699604,
      0.012128742411732674,
      -0.04780235141515732,
      0.003264285856857896,
      0.019730165600776672,
      -0.011677706614136696,
      -0.005691389553248882,
      -0.08522641658782959,
      0.037930842489004135,
      0.009913334622979164,
      0.011680872179567814,
      -0.0630386546254158,
      0.09422510862350464,
      0.05521269142627716,
      0.05305078253149986,
      -0.051984384655952454,
      0.016808168962597847,
      0.006031360011547804,
      0.002288718009367585,
      -0.04483646899461746,
      -0.057316455990076065,
      0.032465096563100815,
      0.054329659789800644,
      0.05449819192290306,
      0.05832478031516075,
      0.03497939184308052,
      -0.008903859183192253,
      -0.007096062880009413,
      0.07161000370979309,
      -0.05123204365372658,
      -0.03276980295777321,
      0.05256453901529312,
      0.06470853835344315,
      0.04318753629922867,
      0.04579457640647888,
      -0.07695871591567993,
      0.00197170814499259,
      0.03270665183663368,
      0.02799299545586109,
      0.029719291254878044,
      -0.03092031367123127,
      0.035149142146110535,
      0.05522056296467781,
      -0.020358961075544357,
      -0.015860775485634804,
      -0.026974622160196304,
      -0.019668474793434143,
      0.03736349940299988,
      0.036944348365068436,
      -0.01589220017194748,
      0.016777293756604195,
      0.0928509533405304,
      -0.03959406539797783,
      -0.04162568598985672,
      0.025510290637612343,
      0.07254066318273544,
      -0.07541950047016144,
      -0.0009221998043358326,
      0.04631192609667778,
      0.03713284060359001,
      0.012913921847939491,
      0.03996927663683891,
      -0.018126068636775017,
      -0.07424066215753555,
      0.07743427902460098,
      0.039463575929403305,
      0.06627930700778961,
      -0.004298592917621136,
      -0.06196010857820511,
      0.02854338102042675,
      -0.051749106496572495,
      -0.026943711563944817,
      -0.02989945188164711,
      -0.024585679173469543,
      -0.07308285683393478,
      -0.0006894215475767851,
      6.945674249436706e-05,
      -0.041639938950538635,
      -0.016031930223107338,
      -0.02686038427054882,
      -0.0038572195917367935,
      0.004358804784715176,
      -0.03410089761018753,
      -0.010956531390547752,
      0.058630798012018204,
      0.020588776096701622,
      -0.0062614562921226025,
      0.10665474086999893,
      -0.003764475928619504,
      0.006733277812600136,
      -0.06764306128025055,
      0.06639596819877625,
      0.008985045365989208,
      0.037505120038986206,
      -0.035465553402900696,
      0.013753068633377552,
      0.020785100758075714,
      -0.0617983415722847,
      -0.033736225217580795,
      0.02099267952144146,
      0.06474994868040085,
      0.07337646931409836,
      -0.04513508826494217,
      0.10664626210927963,
      -0.01657521352171898,
      -0.0712118074297905,
      0.03888760879635811,
      0.0018425318412482738,
      -0.050705403089523315,
      0.03713027387857437,
      0.07055483758449554,
      0.03237725421786308,
      -0.015379432588815689,
      -0.09535077214241028,
      0.0787121057510376,
      0.015743227675557137,
      0.03293757885694504,
      0.03628648817539215,
      -0.030915966257452965,
      0.02499443292617798,
      0.023520607501268387,
      0.02971748262643814,
      0.05726953595876694,
      0.07780671864748001,
      -0.019440507516264915,
      0.006310205906629562,
      -0.0568767786026001,
      0.050782062113285065,
      -0.07520632445812225,
      -0.044959962368011475,
      0.05029847100377083,
      -0.027336567640304565,
      0.021302640438079834,
      -0.05638909339904785,
      0.018079789355397224,
      0.015298474580049515,
      -0.006328259129077196,
      0.014081502333283424,
      0.041897013783454895,
      -0.05237355828285217,
      0.024514516815543175,
      -0.025546157732605934,
      0.00812560971826315,
      -0.005610697437077761,
      0.12127507477998734,
      -0.05000680312514305,
      0.03395828604698181,
      0.024347184225916862,
      -0.009719702415168285,
      -0.03206050023436546,
      -0.0293577928096056,
      0.045781150460243225,
      -0.05099641531705856,
      -0.014414231292903423,
      -0.03064577840268612,
      0.007809423841536045,
      -0.12868739664554596,
      -0.017800794914364815,
      0.06219242885708809,
      0.006943176034837961,
      0.0595044381916523,
      -0.014132373034954071,
      -0.05515319108963013,
      -0.052741650491952896,
      0.006153493653982878,
      0.008126208558678627,
      0.0035914056934416294,
      0.038923464715480804,
      0.005829405505210161,
      -0.05957730486989021,
      -0.042021866887807846,
      -0.02542661316692829,
      -0.04774751141667366,
      0.020003989338874817,
      0.03706036135554314,
      -0.05544722080230713,
      -0.011636161245405674,
      0.003974189981818199,
      0.03992291912436485,
      0.002101903548464179,
      -0.01099014189094305,
      -0.02987338975071907,
      0.012791569344699383,
      0.0684714987874031,
      0.008405101485550404,
      0.07196623831987381,
      0.02393782138824463,
      -0.013256598263978958,
      -0.017483005300164223,
      -0.02227601408958435,
      -0.01709345355629921,
      0.036498673260211945,
      0.029874127358198166,
      -0.056167133152484894,
      0.030084431171417236,
      0.046806108206510544,
      -0.03625555336475372,
      -0.028497373685240746,
      0.010719322599470615
    ],
    [
      -0.03605780750513077,
      -0.02964908815920353,
      -0.0015387431485578418,
      0.07554276287555695,
      0.1132812649011612,
      -0.001558294054120779,
      0.014108429662883282,
      -0.02498527057468891,
      -0.03904556483030319,
      -0.05487149581313133,
      -0.018139269202947617,
      0.05957453325390816,
      0.05925840884447098,
      -0.006688983645290136,
      0.00817038118839264,
      -0.032595593482255936,
      0.0335150770843029,
      0.015584931708872318,
      0.0021865093149244785,
      -0.03489011153578758,
      -0.062818244099617,
      0.01025343593209982,
      0.012396584264934063,
      -0.0500587597489357,
      0.027279458940029144,
      -0.06430769711732864,
      -0.038772448897361755,
      -0.06079554557800293,
      -0.0334450900554657,
      -0.026893064379692078,
      0.010212937369942665,
      0.0023161941207945347,
      -0.023767421022057533,
      -0.00365095934830606,
      0.04329409822821617,
      -0.02293369360268116,
      0.0043535130098462105,
      0.003257145406678319,
      -0.03861629590392113,
      -0.04370454326272011,
      0.01045529916882515,
      0.021265611052513123,
      0.04483126103878021,
      -0.04373607411980629,
      -0.007187186740338802,
      0.06691942363977432,
      0.007938642986118793,
      -0.03733516111969948,
      -0.09626509994268417,
      0.05404471978545189,
      0.0013712336076423526,
      -0.05546431988477707,
      0.0319942831993103,
      -0.004682760685682297,
      -0.03103584423661232,
      0.01441802829504013,
      -0.006683612708002329,
      0.0201568566262722,
      0.014914405532181263,
      -0.054432645440101624,
      0.016893135383725166,
      0.008843445219099522,
      -0.013779145665466785,
      0.00526494812220335,
      -0.02905585803091526,
      0.013472212478518486,
      -0.08153369277715683,
      -0.010063685476779938,
      0.047032807022333145,
      0.03658655658364296,
      -0.04214330017566681,
      -0.13155561685562134,
      -0.01620985008776188,
      0.04249179735779762,
      -0.017619406804442406,
      -0.023578453809022903,
      -0.022453060373663902,
      0.03196556493639946,
      0.0018615244189277291,
      0.05747625231742859,
      0.008020938374102116,
      -0.02148110792040825,
      0.0062573859468102455,
      0.17009301483631134,
      -0.030152201652526855,
      0.007354035973548889,
      0.08007841557264328,
      0.03401259705424309,
      -0.06241544336080551,
      0.055506303906440735,
      0.01143622025847435,
      0.012720458209514618,
      -0.003355408785864711,
      0.033978015184402466,
      -0.018610097467899323,
      -0.053460367023944855,
      0.01906994730234146,
      -0.013798481784760952,
      0.0825304463505745,
      0.0016112332232296467,
      -0.015878114849328995,
      -0.02039952762424946,
      -0.0023139098193496466,
      0.05108668655157089,
      -0.05835457146167755,
      -0.07199546694755554,
      -0.014431670308113098,
      -0.005306081846356392,
      -0.04164218530058861,
      -0.01781240850687027,
      0.06653158366680145,
      0.013938982039690018,
      0.039849236607551575,
      0.027285989373922348,
      0.02866913191974163,
      0.055060237646102905,
      -0.053489238023757935,
      0.0771108940243721,
      -0.0814523696899414,
      -0.07547670602798462,
      -0.06596437841653824,
      0.006056034937500954,
      0.009912190958857536,
      -0.0060280305333435535,
      0.07379158586263657,
      -0.0016968630952760577,
      0.006871230434626341,
      -0.019566921517252922,
      -0.00598818389698863,
      0.022247573360800743,
      -0.03458074480295181,
      -0.06701105087995529,
      -0.08457088470458984,
      0.06686069816350937,
      0.0066028861328959465,
      -0.012783502228558064,
      0.024538328871130943,
      -0.031199492514133453,
      -0.0038108560256659985,
      0.06819574534893036,
      -0.011699610389769077,
      0.032899919897317886,
      -0.0168165136128664,
      0.05110742151737213,
      -0.00986064039170742,
      0.0021037650294601917,
      0.04339858517050743,
      0.014782668091356754,
      -0.01283743791282177,
      0.050702545791864395,
      0.016459833830595016,
      0.0189066119492054,
      0.04105863347649574,
      -0.00980367511510849,
      0.014408031478524208,
      0.016083432361483574,
      -0.0661899670958519,
      0.05034346133470535,
      0.005388745106756687,
      0.05682161822915077,
      0.04470409080386162,
      -0.06829649209976196,
      0.0318034291267395,
      -0.06489405781030655,
      0.013357841409742832,
      0.0018788216402754188,
      -0.07465442270040512,
      -0.04474666714668274,
      -0.027822908014059067,
      -0.004318321123719215,
      -0.04108308255672455,
      0.021677440032362938,
      0.012269209139049053,
      -0.033611878752708435,
      -0.08190647512674332,
      -0.08891694992780685,
      0.03380655124783516,
      0.07951733469963074,
      -0.04837881401181221,
      -0.004358283244073391,
      -0.06874704360961914,
      0.1716369390487671,
      -0.009524649940431118,
      -0.005215256940573454,
      -0.014122996479272842,
      -0.06600122153759003,
      0.04724150896072388,
      -0.022021304816007614,
      -0.04177280142903328,
      -0.008700455538928509,
      -0.01719035394489765,
      0.014453982003033161,
      0.13432398438453674,
      -0.07027250528335571,
      0.007584160193800926,
      0.022847937420010567,
      -0.009326658211648464,
      0.10894507169723511,
      0.004311080556362867,
      0.016471650451421738,
      0.020104598253965378,
      -0.03308502584695816,
      -0.06182277202606201,
      -0.026959020644426346,
      0.10654597729444504,
      0.017741717398166656,
      -0.0018749161390587687,
      0.02505774423480034,
      -0.017226573079824448,
      -0.00207213731482625,
      0.055737316608428955,
      -0.0078814048320055,
      -0.07468139380216599,
      0.025175170972943306,
      0.006568825803697109,
      0.06918790936470032,
      0.08197202533483505,
      -0.027558986097574234,
      0.08801595866680145,
      0.02423742599785328,
      -0.013208084739744663,
      0.013347716070711613,
      -0.03362284600734711,
      -0.013271545991301537,
      -0.004369099158793688,
      0.028802238404750824,
      -0.04908207431435585,
      0.027508951723575592,
      -0.015941346064209938,
      0.0560661144554615,
      -0.05140591785311699,
      -0.018118731677532196,
      0.07702739536762238,
      0.02431509457528591,
      -0.07230369746685028,
      -0.048938460648059845,
      0.08413516730070114,
      0.07618444412946701,
      -0.05224672704935074,
      -0.09384208917617798,
      -0.01984323561191559,
      -0.07200062274932861,
      -0.0813920870423317,
      0.043403372168540955,
      -0.010700581595301628,
      0.012885166332125664,
      -0.012997433543205261,
      0.026216749101877213,
      0.007973773404955864,
      -0.07037612795829773,
      0.03028620034456253,
      -0.05532802641391754,
      -0.011769293807446957,
      -0.030566388741135597,
      -0.023109769448637962,
      -0.06669885665178299,
      0.06663226336240768,
      0.034625545144081116,
      0.09235970675945282,
      -0.03061208501458168,
      0.020775295794010162,
      -0.0744207352399826,
      -0.11328757554292679,
      0.04972013458609581,
      0.04132911190390587,
      -0.028839141130447388,
      -0.07269245386123657,
      -0.05438049137592316,
      -0.0007492692093364894,
      0.07908476144075394,
      -0.03426828235387802,
      0.01821724884212017,
      0.02276223711669445,
      -0.00995530653744936,
      -0.008790292777121067,
      0.06317304819822311,
      -0.01762397773563862,
      0.08232799917459488,
      -0.06175128370523453,
      -0.059332579374313354,
      0.04910995438694954,
      -0.011292895302176476,
      0.06291051208972931,
      -0.06861533224582672,
      0.028080355376005173,
      0.019404346123337746,
      0.08539330959320068,
      0.0613865964114666,
      -0.03139278665184975,
      -0.049250826239585876,
      -0.027983203530311584,
      0.004372979048639536,
      -0.024558167904615402,
      -0.03800424933433533,
      -0.025073597207665443,
      0.11082198470830917,
      -0.05126549303531647,
      -0.021070990711450577,
      0.024314869195222855,
      -0.08540279418230057,
      0.005824453197419643,
      0.07867652922868729,
      0.06862450391054153,
      0.011452018283307552,
      0.011600433848798275,
      -0.016809450462460518,
      -0.05004791170358658,
      -0.043832600116729736,
      0.014631432481110096,
      0.03237631916999817,
      0.042457420378923416,
      0.03519975021481514,
      0.0070548695512115955,
      -0.0442703552544117,
      0.04495534673333168,
      -0.027530299499630928,
      0.001730829942971468,
      0.00841066986322403,
      0.018368449062108994,
      -0.059906914830207825,
      -0.024774709716439247,
      -0.021765537559986115,
      -0.04782615229487419,
      0.06919332593679428,
      -0.027110133320093155,
      -0.04852120950818062,
      0.0035695412661880255,
      0.016239702701568604,
      -0.023101292550563812,
      0.011827069334685802,
      -0.031126748770475388,
      -0.02291749231517315,
      -0.0012631842400878668,
      -0.059353433549404144,
      0.028786245733499527,
      -0.10195135325193405,
      0.012819706462323666,
      0.007677118759602308,
      -0.015408402308821678,
      0.005052485968917608,
      -0.006327360402792692,
      -0.019524799659848213,
      -0.14524419605731964,
      0.01236787624657154,
      -0.08676538616418839,
      0.056988850235939026,
      -0.02150188386440277,
      -0.026148350909352303,
      0.040232036262750626,
      0.0010915646562352777,
      0.0038232188671827316,
      -0.030132800340652466,
      -0.03857797011733055,
      -0.022814104333519936,
      -0.030045989900827408,
      0.043739818036556244,
      0.01613624207675457,
      0.03269744664430618,
      -0.0018779298989102244,
      0.038637738674879074,
      0.03240859508514404,
      0.015410248190164566,
      -0.07952865958213806,
      -0.00399798434227705,
      -0.014313995838165283,
      -0.06333768367767334,
      0.05364871025085449,
      0.0833531841635704,
      -0.017137804999947548,
      4.202880518278107e-05,
      -0.005064624827355146,
      -0.02123859152197838,
      -0.04482845962047577,
      -0.042111948132514954,
      -0.0032256462145596743,
      0.1031045913696289,
      -0.03507502004504204,
      0.0658392459154129,
      0.05601804330945015,
      0.057943906635046005,
      -0.10168550908565521,
      0.07073178142309189,
      -0.05791138485074043,
      0.038022931665182114,
      -0.0285529475659132,
      -0.04792781174182892,
      0.010370338335633278,
      0.010465875267982483,
      0.003590052016079426,
      0.02130335383117199,
      -0.060505807399749756,
      -0.04197894409298897,
      0.03115459717810154,
      0.018013834953308105,
      -0.051731329411268234,
      0.012249079532921314,
      -0.006657583639025688,
      0.03090352565050125,
      -0.019568556919693947,
      -0.045646000653505325,
      0.012456881813704967,
      0.11044187843799591,
      -0.023363223299384117,
      0.008447520434856415,
      -0.005218473728746176,
      -0.011084270663559437,
      -0.04199051856994629,
      0.05357835814356804,
      0.056466445326805115,
      0.05282223969697952,
      -0.01962093450129032,
      0.14449471235275269,
      0.08932550996541977,
      0.009431764483451843,
      0.007772156503051519,
      -0.021577846258878708,
      -0.06665389984846115,
      -0.01937681809067726,
      0.032014235854148865,
      0.031409766525030136,
      -0.028418099507689476,
      0.06489132344722748,
      0.03424745425581932,
      0.03302886337041855,
      0.09047455340623856,
      -0.025681836530566216,
      -0.0200818944722414,
      -0.02912757731974125,
      0.0656510666012764,
      -0.0328008234500885,
      -0.04722357168793678,
      0.007089505437761545,
      6.655830657109618e-05,
      0.0450197234749794,
      -0.008158206939697266,
      0.0478268601000309,
      0.028835320845246315,
      0.015695203095674515,
      0.012169108726084232,
      0.03693845495581627,
      -0.04216485098004341,
      -0.009264998137950897,
      0.011254584416747093,
      -0.07794388383626938,
      0.058190472424030304,
      0.024898702278733253,
      -0.025936124846339226,
      -0.03671149164438248,
      0.0008573090308345854,
      -0.03773864731192589,
      -0.048875004053115845,
      -0.02146952599287033,
      0.01956024393439293,
      -0.01563064567744732,
      -0.025419339537620544,
      0.10298610478639603,
      0.02083560638129711,
      -0.05578039214015007,
      0.02125956118106842,
      0.02455236203968525,
      -0.004993279464542866,
      -0.04492787644267082,
      -0.11092975735664368,
      0.06437307596206665,
      0.017010856419801712,
      0.0477338582277298,
      -0.004571609664708376,
      -0.004528426099568605,
      0.07010854035615921,
      -0.024692490696907043,
      -0.0493299700319767,
      -0.00801757536828518,
      0.017920810729265213,
      -0.05567024275660515,
      0.09006454050540924,
      -0.03717536851763725,
      -0.045576512813568115,
      -0.03218294680118561,
      0.001457549398764968,
      -0.013404589146375656,
      0.011640973389148712,
      0.03408564627170563,
      0.09717452526092529,
      0.06107241287827492,
      -0.014162430539727211,
      -0.0021851458586752415,
      0.029098166152834892,
      0.013306857086718082,
      0.0016675881342962384,
      0.006482998840510845,
      0.003545474261045456,
      0.015984663739800453,
      0.06082899123430252,
      -0.03209354728460312,
      0.008094952441751957,
      0.0384988896548748,
      -0.03455859422683716,
      -0.016426347196102142,
      0.009807568974792957,
      -0.04336095601320267,
      0.02519998699426651,
      -0.02644670568406582,
      -0.02766105905175209
    ],
    [
      0.03888315707445145,
      -0.00841698981821537,
      0.04859483242034912,
      -0.07798182964324951,
      -0.0024521818850189447,
      -0.017620965838432312,
      0.06391526013612747,
      -0.06749238073825836,
      0.013635505922138691,
      -0.038227830082178116,
      -0.01696188747882843,
      -0.03629107400774956,
      0.03312191739678383,
      -0.010320968925952911,
      0.086100734770298,
      -0.018420066684484482,
      0.03982575610280037,
      -0.014733904041349888,
      -0.04676045477390289,
      -0.0009438747074455023,
      0.0051792380400002,
      -0.045767925679683685,
      0.06420262902975082,
      -0.052683375775814056,
      0.06455961614847183,
      -0.06521755456924438,
      0.05966568738222122,
      -0.039982572197914124,
      0.010355350561439991,
      -0.004227293189615011,
      0.02893158420920372,
      -0.0033218825701624155,
      -0.018896043300628662,
      -0.023274393752217293,
      0.05882716178894043,
      0.002666313434019685,
      -0.00477050244808197,
      -0.03855593129992485,
      0.03882219269871712,
      -0.027686938643455505,
      -0.05587790533900261,
      0.014308460056781769,
      0.04297412186861038,
      0.00727533595636487,
      0.04495430737733841,
      -0.025087876245379448,
      0.025321897119283676,
      0.04031025245785713,
      -0.00508889788761735,
      -0.015367205254733562,
      -0.04483989626169205,
      -0.011661496013402939,
      -0.02515636943280697,
      0.04823339357972145,
      0.00616118311882019,
      0.02848440781235695,
      0.0822686180472374,
      0.008623281493782997,
      0.07246099412441254,
      -0.0007907432154752314,
      -0.10150860995054245,
      -0.005450658965855837,
      0.0018205901142209768,
      0.08502046763896942,
      0.00955915916711092,
      -0.04357751086354256,
      -0.043347492814064026,
      -0.0019558053463697433,
      0.0368964709341526,
      -0.020047413185238838,
      0.008208774961531162,
      -0.020004160702228546,
      -0.059867098927497864,
      -0.0077520571649074554,
      0.0010281394934281707,
      -0.050935376435518265,
      0.008579612709581852,
      -0.009566759690642357,
      0.03738625720143318,
      0.038653064519166946,
      -0.03775982931256294,
      -0.07849398255348206,
      -0.014898130670189857,
      0.015538056381046772,
      -0.09042520821094513,
      -0.06181808188557625,
      -0.01970773935317993,
      0.018740879371762276,
      -0.042511194944381714,
      -0.015027586370706558,
      -0.008841670118272305,
      0.04192664474248886,
      0.0016384075861424208,
      0.009527541697025299,
      0.055148012936115265,
      -0.06747165322303772,
      -0.02255534753203392,
      0.014495676383376122,
      0.04309501871466637,
      -0.046014852821826935,
      0.05015859752893448,
      -0.0201092641800642,
      0.029388463124632835,
      -0.0014567496255040169,
      -0.019501179456710815,
      -0.027957161888480186,
      0.05174389109015465,
      -0.0030362491961568594,
      0.027066832408308983,
      -0.006501610390841961,
      0.006326223257929087,
      0.0018615793669596314,
      0.02661863900721073,
      -0.10609571635723114,
      -0.053522538393735886,
      0.032999299466609955,
      -0.08111429959535599,
      0.017539475113153458,
      0.03848867118358612,
      -0.006730576977133751,
      -0.014348873868584633,
      -0.009261216036975384,
      0.015094755217432976,
      0.041173115372657776,
      -0.050367165356874466,
      0.03821590542793274,
      0.014628233388066292,
      -0.05866864323616028,
      0.01030824426561594,
      0.043304525315761566,
      0.05202709510922432,
      0.03481077030301094,
      0.09215293079614639,
      0.05630068480968475,
      -0.02768704481422901,
      -0.04610690101981163,
      -0.0622541606426239,
      -0.03456904739141464,
      0.01544799841940403,
      -0.031817443668842316,
      0.0613274946808815,
      -0.0448676273226738,
      0.05871342495083809,
      0.06782438606023788,
      0.025578195229172707,
      -0.02225843444466591,
      0.04775482043623924,
      -0.03694109991192818,
      -0.024709800258278847,
      0.03241803124547005,
      -0.026817359030246735,
      -0.018223190680146217,
      -0.030356906354427338,
      0.01617942936718464,
      0.06746209412813187,
      -0.1486378014087677,
      -0.029469432309269905,
      -0.02130959928035736,
      0.00951642170548439,
      0.0024727557320147753,
      -0.015285993926227093,
      -0.057486239820718765,
      0.02592000924050808,
      -0.02813282050192356,
      0.12524688243865967,
      0.012083825655281544,
      -0.006384320557117462,
      0.09798502177000046,
      -0.030909182503819466,
      -0.0066720061004161835,
      -0.036392126232385635,
      0.0797719806432724,
      -0.022251201793551445,
      0.016786739230155945,
      0.046850331127643585,
      0.091249018907547,
      0.05561082810163498,
      -0.050594475120306015,
      0.0462040901184082,
      0.040480874478816986,
      -0.048225775361061096,
      0.06171798333525658,
      -0.046252746134996414,
      -6.171591667225584e-05,
      0.08046426624059677,
      -0.07258907705545425,
      -0.005374662112444639,
      -0.019256366416811943,
      -0.015287150628864765,
      -0.0362614281475544,
      -0.08766114711761475,
      0.0018339240923523903,
      0.017023485153913498,
      0.047710198909044266,
      -0.047705795615911484,
      0.044714272022247314,
      -0.07778646796941757,
      0.0371079221367836,
      -0.04867418110370636,
      0.08271143585443497,
      -0.016891097649931908,
      0.013953848741948605,
      -0.017171401530504227,
      0.08573916554450989,
      -0.051392730325460434,
      -0.028837483376264572,
      0.07044357806444168,
      0.09606602787971497,
      0.04626941680908203,
      0.012301391921937466,
      0.06985320150852203,
      -0.04038529843091965,
      -0.05537114664912224,
      -0.026052407920360565,
      -0.012058715336024761,
      0.021676821634173393,
      -0.01852569542825222,
      -0.01741896942257881,
      -0.0023612382356077433,
      0.02777682989835739,
      -0.06380574405193329,
      0.04387374967336655,
      -0.05568881705403328,
      0.018598269671201706,
      -0.09017785638570786,
      -0.0004360168532002717,
      0.07141510397195816,
      0.06920907646417618,
      -0.04210864380002022,
      -0.022476760670542717,
      0.0063529182225465775,
      0.06805577874183655,
      0.00897274725139141,
      -0.027043405920267105,
      0.02462274208664894,
      0.028049763292074203,
      0.04653168097138405,
      0.01861759088933468,
      -0.08057696372270584,
      0.007967410609126091,
      0.028615647926926613,
      0.026538437232375145,
      -0.07766113430261612,
      -0.02282792143523693,
      0.046141475439071655,
      -0.04020661115646362,
      -0.001890429062768817,
      -0.04694696143269539,
      -0.00995508674532175,
      -0.015870116651058197,
      -0.0020346820820122957,
      -0.03943391144275665,
      -0.034371547400951385,
      0.026444924995303154,
      0.028266754001379013,
      0.055966440588235855,
      0.03664673864841461,
      0.017936334013938904,
      0.0816572904586792,
      -0.10305417329072952,
      0.0689409077167511,
      -0.02125728130340576,
      -0.0063035376369953156,
      -0.03352344036102295,
      -0.05830349773168564,
      -0.019796576350927353,
      0.010944911278784275,
      -0.0022965811658650637,
      0.027354994788765907,
      -0.06206584721803665,
      0.027860119938850403,
      -0.11589298397302628,
      0.05516112968325615,
      -0.011431444436311722,
      0.003573615336790681,
      -0.026177119463682175,
      -0.005546458065509796,
      -0.01723625510931015,
      -0.009307203814387321,
      0.00822494924068451,
      -0.07343527674674988,
      0.06976035982370377,
      0.04647034779191017,
      -0.025140758603811264,
      0.001855537062510848,
      -0.018066199496388435,
      0.040741804987192154,
      0.02471979334950447,
      0.014814517460763454,
      -0.010272997431457043,
      0.021626923233270645,
      -0.006461070850491524,
      0.003462642664089799,
      -0.039582911878824234,
      -0.04233464226126671,
      -0.03864460811018944,
      -0.041800592094659805,
      -0.025050422176718712,
      0.03574563190340996,
      -0.05977437272667885,
      -0.06788977235555649,
      0.06540177017450333,
      0.018545979633927345,
      -0.013737604953348637,
      0.02384958416223526,
      0.03176204860210419,
      0.01021049078553915,
      -0.06529957056045532,
      -0.05245457589626312,
      -0.088446244597435,
      -0.03694329038262367,
      -0.004744808655232191,
      0.04365120083093643,
      -0.0003905008779838681,
      0.005573391914367676,
      0.029073497280478477,
      0.03465147316455841,
      0.03398934751749039,
      -0.0008570365025661886,
      -0.042756810784339905,
      -0.05961979925632477,
      0.04035333916544914,
      0.06466609239578247,
      -0.05800184980034828,
      0.0239633247256279,
      -0.05555957183241844,
      -0.0022681935224682093,
      0.003694056998938322,
      0.041705116629600525,
      -0.019053557887673378,
      0.021509576588869095,
      0.06672733277082443,
      0.07766778022050858,
      0.03725753352046013,
      0.028493572026491165,
      0.03450116515159607,
      0.0705747976899147,
      -0.03427131474018097,
      0.033409103751182556,
      0.0047547039575874805,
      0.05056525766849518,
      -0.04182574152946472,
      0.012004558928310871,
      -0.013029084540903568,
      -0.02770131267607212,
      -0.02300254814326763,
      0.08539442718029022,
      -0.03057253733277321,
      -0.047972194850444794,
      -0.00327495695091784,
      -0.03239293023943901,
      -0.012479128316044807,
      0.0818600207567215,
      -0.028337547555565834,
      -0.01582513563334942,
      -0.0431327149271965,
      0.06031181290745735,
      0.03489423915743828,
      0.04117871820926666,
      0.008517353795468807,
      0.044789962470531464,
      -0.040794290602207184,
      -0.047232307493686676,
      0.021486693993210793,
      0.04410828277468681,
      -0.017318913713097572,
      0.020376766100525856,
      0.03354305401444435,
      0.08950065076351166,
      0.056071486324071884,
      0.044718094170093536,
      -0.040648382157087326,
      -0.08218536525964737,
      0.02563847415149212,
      0.03072294406592846,
      -0.0049861944280564785,
      0.05944789573550224,
      0.05852788686752319,
      0.03671632707118988,
      0.04238863289356232,
      0.11402755230665207,
      0.05613600090146065,
      -0.0018548036459833384,
      -0.08201500028371811,
      -0.01893349178135395,
      0.1070900708436966,
      0.006244615651667118,
      -0.06593722850084305,
      -0.012925295159220695,
      0.008230754174292088,
      -0.006260563153773546,
      -0.01853608712553978,
      0.0331510454416275,
      -0.058107927441596985,
      0.06204189360141754,
      0.08497525751590729,
      0.0728091299533844,
      0.036994170397520065,
      -0.025366300716996193,
      0.06919415295124054,
      0.09977560490369797,
      -0.010358111932873726,
      0.003164841327816248,
      0.024202344939112663,
      0.03640308231115341,
      -0.0003491802781354636,
      -0.020974021404981613,
      0.059522271156311035,
      0.028865154832601547,
      -0.06010182574391365,
      0.03767123818397522,
      -0.024715514853596687,
      0.03894975036382675,
      -0.021198274567723274,
      0.054721131920814514,
      -0.010224688798189163,
      -0.07433300465345383,
      0.02689545229077339,
      -0.0013267385074868798,
      0.012387154623866081,
      -0.06408553570508957,
      -0.05551016703248024,
      -0.08651242405176163,
      0.021888887509703636,
      -8.472163608530536e-05,
      0.02676975727081299,
      0.005432089325040579,
      0.03936363384127617,
      0.06977780163288116,
      0.019490590319037437,
      0.036241061985492706,
      -0.05814559385180473,
      -0.06035902351140976,
      0.03895765542984009,
      0.03190217539668083,
      0.05461584031581879,
      -0.006162319332361221,
      0.016672560945153236,
      -0.05248701572418213,
      0.011781559325754642,
      -0.08232827484607697,
      -0.03027728572487831,
      -0.01891320012509823,
      0.05275415629148483,
      -0.0394425131380558,
      -0.0228410717099905,
      -0.05090389400720596,
      0.009598991833627224,
      -0.03805888071656227,
      -0.006125623360276222,
      -0.009933083318173885,
      0.08833064138889313,
      0.11225050687789917,
      -0.0941450297832489,
      -0.04854946583509445,
      0.013870084658265114,
      -0.017052121460437775,
      -0.003252863185480237,
      0.015320212580263615,
      0.07764774560928345,
      0.012815994210541248,
      -0.04573722183704376,
      0.05269002169370651,
      -0.056463856250047684,
      0.09165358543395996,
      0.029669489711523056,
      -0.06207692623138428,
      -0.06197591871023178,
      0.026743901893496513,
      -0.037474311888217926,
      0.0006321807741187513,
      -0.06633950769901276,
      -0.01958489790558815,
      -0.05730338767170906,
      -0.006270099431276321,
      -0.04348275810480118,
      -0.009182726964354515,
      0.054615844041109085,
      0.06087002530694008,
      0.006931634154170752,
      0.003389735007658601,
      0.03408196568489075,
      -0.04213738441467285,
      0.017375556752085686,
      -0.04553574323654175,
      -0.007693344261497259,
      0.014399874024093151,
      0.024072911590337753,
      0.049392689019441605,
      0.08132902532815933,
      0.05898716300725937,
      0.07181662321090698,
      0.01632012240588665,
      0.012669812887907028,
      0.09788577258586884,
      0.037811409682035446,
      -0.04043462499976158,
      -0.051849182695150375,
      0.07320331782102585,
      -0.02386670745909214,
      -0.008155891671776772,
      0.021858982741832733,
      0.009563329629600048
    ],
    [
      0.012948312796652317,
      -0.02742178365588188,
      0.028611361980438232,
      0.044060613960027695,
      0.04973037913441658,
      0.03275998681783676,
      0.016680127009749413,
      -0.037152249366045,
      -0.01959470473229885,
      -0.012638132087886333,
      0.020148990675807,
      -0.024086061865091324,
      -0.08532099425792694,
      -0.04192391782999039,
      0.11458083242177963,
      0.09604492038488388,
      0.06788412481546402,
      -0.007832691073417664,
      -0.0361422635614872,
      0.04719551280140877,
      -0.03949110582470894,
      -0.030949385836720467,
      -0.05930847302079201,
      0.03465406969189644,
      0.07755963504314423,
      0.04179830104112625,
      -0.05719424784183502,
      0.008300786837935448,
      0.025317862629890442,
      -0.007638439070433378,
      0.011612873524427414,
      -0.05235059931874275,
      0.023548588156700134,
      -0.011654534377157688,
      -0.03651980683207512,
      0.04744937643408775,
      -0.053486570715904236,
      0.128301203250885,
      -0.1131572499871254,
      0.0032859884668141603,
      0.017982242628932,
      0.051265716552734375,
      0.045711029320955276,
      0.010593903250992298,
      0.05748451128602028,
      -0.057543911039829254,
      0.04852830246090889,
      -0.041427694261074066,
      0.0149147380143404,
      -0.03024282306432724,
      0.06268806010484695,
      0.06849420070648193,
      -0.04728483781218529,
      -0.006991411093622446,
      0.07504075765609741,
      0.024576472118496895,
      -0.008355911821126938,
      0.004551990889012814,
      0.08701533079147339,
      -0.02904096059501171,
      0.004426142666488886,
      -0.04645655304193497,
      0.07039716839790344,
      -0.08567282557487488,
      -0.05693840980529785,
      -0.043687403202056885,
      0.05667313560843468,
      -0.029871711507439613,
      -0.04982990771532059,
      -0.08856004476547241,
      0.055824000388383865,
      0.03219563513994217,
      -0.07077531516551971,
      0.01606367528438568,
      -0.020135769620537758,
      -0.013081798329949379,
      -0.07085506618022919,
      0.02843341790139675,
      -0.06495748460292816,
      0.06642191857099533,
      0.01850348897278309,
      -0.00030941469594836235,
      -0.021352609619498253,
      -0.05894221365451813,
      0.0021352730691432953,
      0.05152895301580429,
      0.10544908791780472,
      0.005041252356022596,
      0.039882171899080276,
      -0.06342960149049759,
      0.09147604554891586,
      0.06216267868876457,
      -0.020031943917274475,
      0.004847485106438398,
      0.06599046289920807,
      0.023964274674654007,
      -0.079960897564888,
      -0.039964109659194946,
      -0.07054119557142258,
      0.03962811455130577,
      -0.035654403269290924,
      0.037915777415037155,
      -0.007691030856221914,
      -0.0050840191543102264,
      -0.017706001177430153,
      0.057526033371686935,
      0.023286737501621246,
      0.09788931161165237,
      -0.01676172763109207,
      -0.015143763273954391,
      0.08713638782501221,
      0.01946619711816311,
      0.07268314063549042,
      -0.026517024263739586,
      -0.006130562629550695,
      0.037735454738140106,
      -0.025161828845739365,
      -0.019477875903248787,
      -0.0062150913290679455,
      0.02462231181561947,
      -0.026027604937553406,
      -0.010252564214169979,
      -0.01273017842322588,
      0.05376811698079109,
      0.02264516055583954,
      -0.024463584646582603,
      -0.0151576092466712,
      -0.02218964323401451,
      -0.022490303963422775,
      -0.04266746714711189,
      -0.008430983871221542,
      -0.0490681529045105,
      -0.024774150922894478,
      0.08264023065567017,
      0.0763024240732193,
      -2.141803088306915e-05,
      0.04590476304292679,
      0.017115512862801552,
      -0.008340224623680115,
      0.011724970303475857,
      0.05681093782186508,
      -0.08396037667989731,
      0.019671490415930748,
      0.035518910735845566,
      0.07144054025411606,
      0.06707295030355453,
      -0.02993283048272133,
      -0.036850083619356155,
      0.03245389088988304,
      -0.01096956618130207,
      -0.0005221545579843223,
      0.08275361359119415,
      -0.02409718744456768,
      -0.008853042498230934,
      0.027180582284927368,
      0.007225789129734039,
      -0.028516924008727074,
      0.07669330388307571,
      -0.034464310854673386,
      -0.020812569186091423,
      0.04096602648496628,
      0.058864716440439224,
      0.0702359601855278,
      -0.004074187483638525,
      0.10247866064310074,
      0.03347291797399521,
      0.03943180665373802,
      0.08507406711578369,
      -0.05846228823065758,
      -0.0758700892329216,
      -0.015467226505279541,
      -0.019078737124800682,
      -0.008299126289784908,
      0.09521807730197906,
      0.024519456550478935,
      -0.0375177301466465,
      -0.033894751220941544,
      -0.004372578579932451,
      0.013799119740724564,
      -0.05180588737130165,
      -0.04254286363720894,
      -0.006928743328899145,
      -0.015789255499839783,
      -0.010184654965996742,
      0.027356982231140137,
      -0.02789420634508133,
      -0.03910590335726738,
      0.019391557201743126,
      0.06193683668971062,
      -0.0010723790619522333,
      0.009377291426062584,
      0.030614953488111496,
      0.07489100098609924,
      -0.01036206353455782,
      0.00843991618603468,
      -0.012053473852574825,
      -0.03473610430955887,
      -0.013956470414996147,
      0.09149601310491562,
      0.0012812477070838213,
      0.021298512816429138,
      -0.018116125836968422,
      0.030512379482388496,
      -0.009344333782792091,
      -0.01786731369793415,
      -0.030536508187651634,
      -0.0716194286942482,
      0.03073452226817608,
      -0.0808970034122467,
      0.0661359578371048,
      0.054321639239788055,
      -0.011099130846560001,
      -0.00831966008991003,
      -0.028937343508005142,
      -0.04258142039179802,
      0.06072916463017464,
      0.004268331918865442,
      0.08982833474874496,
      0.13166210055351257,
      -0.000775765860453248,
      -0.060700468719005585,
      -0.05819202959537506,
      0.014318237081170082,
      -0.00790378823876381,
      -0.027022428810596466,
      0.005422140471637249,
      -0.010279671289026737,
      -0.004705898463726044,
      0.052482977509498596,
      -0.035973262041807175,
      0.0007654037326574326,
      -0.0681936964392662,
      -0.07190284878015518,
      0.06698492169380188,
      -0.035915207117795944,
      -0.06009586900472641,
      -0.03205332159996033,
      -0.03782924264669418,
      0.016042692586779594,
      -0.012235107831656933,
      -0.021481653675436974,
      0.052425067871809006,
      -0.02973543480038643,
      0.0017558046383783221,
      0.09754488617181778,
      0.05262119323015213,
      0.017013581469655037,
      -0.07881175726652145,
      -0.027263695374131203,
      -0.03376001864671707,
      -0.06109299510717392,
      0.0009927847422659397,
      -0.017145736142992973,
      -0.02218009904026985,
      -0.024528929963707924,
      0.028784388676285744,
      -0.05549289658665657,
      0.09359174221754074,
      0.011126627214252949,
      0.07304807752370834,
      -0.043908681720495224,
      0.021131614223122597,
      -0.07037627696990967,
      -0.022145753726363182,
      0.007628867868334055,
      -0.004228474106639624,
      -0.025740353390574455,
      0.09070032089948654,
      0.013843260705471039,
      -0.04046560451388359,
      -0.004884341266006231,
      -0.06409462541341782,
      -0.011914469301700592,
      0.07716016471385956,
      -0.04871456325054169,
      -0.002964347368106246,
      -0.017718087881803513,
      -0.00907908659428358,
      0.01832861267030239,
      -0.013746152631938457,
      0.039664655923843384,
      -0.047997768968343735,
      0.03562275320291519,
      -0.06728227436542511,
      0.029403597116470337,
      -0.023043997585773468,
      -0.11040803045034409,
      -0.09641095250844955,
      -0.014160494320094585,
      0.08091530203819275,
      -0.028837746009230614,
      0.009006474167108536,
      0.008717604912817478,
      0.10091977566480637,
      0.07928727567195892,
      0.0016110425349324942,
      -0.011507648974657059,
      0.009829393588006496,
      0.03306000679731369,
      -0.016577642410993576,
      0.022426046431064606,
      0.002457210561260581,
      -0.020356755703687668,
      0.057318538427352905,
      0.03910987079143524,
      0.06740941852331161,
      0.0465630404651165,
      -0.04680981859564781,
      0.04111078381538391,
      -0.06484724581241608,
      0.009561819955706596,
      0.03651583567261696,
      0.061895474791526794,
      0.06985531002283096,
      0.009255541488528252,
      0.030742056667804718,
      0.06022746115922928,
      -0.009446763433516026,
      0.02153167501091957,
      -0.049632444977760315,
      -0.10021724551916122,
      -0.0536717064678669,
      -0.0008227353100664914,
      0.026897305622696877,
      -0.030438272282481194,
      0.0067299287766218185,
      0.07891571521759033,
      -0.029658392071723938,
      0.05879988148808479,
      -0.0030137954745441675,
      -0.019142137840390205,
      -0.05382281541824341,
      0.005016209091991186,
      -0.013493525795638561,
      -0.016102846711874008,
      -0.00737741170451045,
      0.04920916631817818,
      -0.04561309888958931,
      -0.010919379070401192,
      0.033204495906829834,
      0.05274507403373718,
      0.049250759184360504,
      -0.0833016186952591,
      -0.035432957112789154,
      -0.02848704159259796,
      -0.0334000326693058,
      -0.013902158476412296,
      -0.025607237592339516,
      0.12644372880458832,
      -0.053048957139253616,
      0.07052003592252731,
      0.05388869345188141,
      0.021530061960220337,
      -0.026430830359458923,
      0.008908605203032494,
      -0.09323861449956894,
      0.06756991893053055,
      -0.033400777727365494,
      0.03429600968956947,
      0.026332173496484756,
      0.01633463054895401,
      0.009830357506871223,
      -0.06922681629657745,
      0.04496372491121292,
      -0.02234838530421257,
      -0.040518712252378464,
      0.0015667164698243141,
      0.05997221916913986,
      0.00938342697918415,
      -0.014702551998198032,
      0.03162654489278793,
      -0.02272823080420494,
      0.05041957646608353,
      0.07517201453447342,
      0.029483802616596222,
      -0.045432910323143005,
      -0.002539603505283594,
      0.016942260786890984,
      0.014458289369940758,
      -0.020724281668663025,
      -0.0010453838622197509,
      -0.019671399146318436,
      -0.022282689809799194,
      0.005742926616221666,
      0.006560516078025103,
      -0.00029300572350621223,
      0.0010575472842901945,
      0.0018179765902459621,
      0.011095134541392326,
      0.0035413193982094526,
      0.01737167499959469,
      0.04416479170322418,
      0.0666218101978302,
      0.023668982088565826,
      0.037313222885131836,
      -0.03021038882434368,
      0.08539443463087082,
      0.0021869377233088017,
      -0.022718647494912148,
      0.007674353662878275,
      -0.03831664100289345,
      0.0039975568652153015,
      -0.03549536317586899,
      -0.025673866271972656,
      0.00730952899903059,
      0.025508534163236618,
      -0.01981387659907341,
      -0.012913769111037254,
      0.0490260049700737,
      -0.08528885990381241,
      -0.04076174274086952,
      0.02271842397749424,
      0.06467415392398834,
      -0.06951922178268433,
      -0.057546988129615784,
      -0.00794034730643034,
      -0.03345247358083725,
      0.03283632546663284,
      0.045605290681123734,
      0.022710924968123436,
      -0.013942709192633629,
      0.026414232328534126,
      0.0004985062987543643,
      -0.008427022956311703,
      -0.02572263404726982,
      -0.01567157544195652,
      0.011265281587839127,
      0.016665002331137657,
      0.026802854612469673,
      0.015183083713054657,
      -0.027656665071845055,
      0.04546720162034035,
      -0.0778016448020935,
      -0.04476393386721611,
      0.004516462795436382,
      -0.05834438279271126,
      -0.003984453622251749,
      -0.011186322197318077,
      -0.058762338012456894,
      -0.017206385731697083,
      0.06134198606014252,
      0.06924068927764893,
      0.04906737431883812,
      -0.009352462366223335,
      0.025131214410066605,
      0.011886750347912312,
      0.06882452219724655,
      0.06605774164199829,
      0.08543954789638519,
      -0.047845058143138885,
      0.004388596396893263,
      -0.10759341716766357,
      0.01601666584610939,
      0.02572612278163433,
      -0.10719217360019684,
      -0.011143980547785759,
      0.008132112212479115,
      0.04075785353779793,
      0.019079633057117462,
      0.010918157175183296,
      -0.11143797636032104,
      -0.1063588485121727,
      -0.023248249664902687,
      -0.07864214479923248,
      0.012335908599197865,
      0.02168378420174122,
      0.03349030762910843,
      -0.0014111724449321628,
      0.011715151369571686,
      -0.01108640804886818,
      -0.005806710571050644,
      -0.036578260362148285,
      -0.10131563246250153,
      -0.014236340299248695,
      0.04654662683606148,
      -0.0063104648143053055,
      0.015960412099957466,
      -0.08607573807239532,
      -0.032717861235141754,
      0.036442242562770844,
      -0.07875069975852966,
      -0.09824245423078537,
      -3.6600689782062545e-05,
      -0.027649400755763054,
      -0.044563792645931244,
      0.03890587016940117,
      0.04338487610220909,
      -0.03644412010908127,
      0.0003779951948672533,
      -0.012298141606152058,
      -0.04584449157118797,
      0.014573504216969013,
      -0.029736369848251343,
      0.056381698697805405,
      0.04715407267212868,
      -0.02137865126132965,
      0.04229282587766647,
      0.002605669666081667,
      -0.001001408789306879,
      0.0267845056951046,
      0.031492434442043304,
      0.016085224226117134,
      -0.0002761040232144296
    ],
    [
      -0.10164539515972137,
      -0.044795822352170944,
      0.04228388890624046,
      -0.014116894453763962,
      0.046359796077013016,
      0.002867815550416708,
      0.09104795008897781,
      -0.03171543776988983,
      -0.0316249281167984,
      0.02651888132095337,
      -0.0009211510769091547,
      0.030430534854531288,
      0.028214087709784508,
      -0.08053486794233322,
      -0.0034632033202797174,
      -0.015746256336569786,
      -0.0357355922460556,
      -0.019685471430420876,
      -0.023276181891560555,
      -0.019464589655399323,
      0.043929655104875565,
      -0.0614587627351284,
      -0.03570893034338951,
      -0.04370307922363281,
      0.09563083201646805,
      0.014613422565162182,
      0.0246887244284153,
      -0.07404078543186188,
      -0.04749836027622223,
      0.025466475635766983,
      -0.05388924106955528,
      -0.016002370044589043,
      0.002424311824142933,
      0.05619079992175102,
      0.0624612532556057,
      -0.02246212773025036,
      -0.017239244654774666,
      -0.12394317239522934,
      -0.053318336606025696,
      0.045494869351387024,
      0.038003288209438324,
      -0.05338926613330841,
      0.07187098264694214,
      0.13276901841163635,
      0.02935626171529293,
      -0.028030741959810257,
      0.010754430666565895,
      0.03828585520386696,
      0.06288930028676987,
      0.001805129460990429,
      0.07009271532297134,
      -0.0017344384687021375,
      -0.020501302555203438,
      -0.02158832922577858,
      -0.014881649985909462,
      0.02161550521850586,
      0.005333487410098314,
      0.04883570224046707,
      -0.06310515850782394,
      -0.011286254040896893,
      0.04656842350959778,
      0.05213839188218117,
      -0.043445318937301636,
      0.023561302572488785,
      -0.10184506326913834,
      0.02341749146580696,
      0.07997334748506546,
      0.04182357341051102,
      0.004181712865829468,
      0.06335064768791199,
      0.09529843926429749,
      -0.05447450652718544,
      -0.01930881105363369,
      -0.01879470981657505,
      -0.04555587098002434,
      -0.041408926248550415,
      0.06198102608323097,
      -0.024167193099856377,
      0.0034833871759474277,
      -0.028092071413993835,
      -0.02223106287419796,
      -0.0077356500551104546,
      -0.04241715371608734,
      0.07822394371032715,
      -0.0012618941254913807,
      -0.054915402084589005,
      0.07616119086742401,
      0.025360148400068283,
      -0.001850846572779119,
      -0.04914648458361626,
      0.11237992346286774,
      -0.014329958707094193,
      -0.029658475890755653,
      0.13272710144519806,
      -0.03260892629623413,
      -0.03712582588195801,
      0.05937514826655388,
      -0.04152081534266472,
      -0.04155854880809784,
      0.031238732859492302,
      0.008849761448800564,
      -0.051995649933815,
      0.03374319523572922,
      0.0019711919594556093,
      0.05073767155408859,
      0.02871510572731495,
      0.02388845942914486,
      0.02859434485435486,
      -0.03598490357398987,
      0.056973692029714584,
      -0.0600355789065361,
      0.022859595715999603,
      0.026201199740171432,
      -0.02863805554807186,
      0.04417850077152252,
      0.005269878078252077,
      -0.04371508210897446,
      0.02129177935421467,
      -0.05003400519490242,
      0.05733421444892883,
      0.044089049100875854,
      -0.017381399869918823,
      -0.07011698931455612,
      -0.016639655455946922,
      -0.05945086106657982,
      0.059808604419231415,
      -0.017817363142967224,
      -0.03136853873729706,
      0.04665490239858627,
      -0.005739487241953611,
      -0.044936634600162506,
      0.037270426750183105,
      -0.04768478497862816,
      0.0022991837467998266,
      0.10329893231391907,
      0.017628632485866547,
      -0.07592394202947617,
      -0.0016110483556985855,
      -0.006155815441161394,
      -0.019739244133234024,
      0.056847330182790756,
      -0.02455112710595131,
      0.021644845604896545,
      -0.007813290692865849,
      0.022628311067819595,
      -0.05008094012737274,
      0.012368792667984962,
      -0.03244553506374359,
      -0.025870556011795998,
      -0.012464870698750019,
      -0.054948657751083374,
      0.07091256976127625,
      0.011591659858822823,
      -0.02011972665786743,
      -0.01702822558581829,
      0.011852150782942772,
      -0.01738164760172367,
      0.028490198776125908,
      0.016596978530287743,
      0.01228887215256691,
      -0.0762518048286438,
      -0.02342945709824562,
      0.01855386048555374,
      0.04066263511776924,
      -0.005578428041189909,
      -0.07043147832155228,
      -0.06623835861682892,
      0.021981706842780113,
      0.07806845009326935,
      0.054759517312049866,
      -0.009076341986656189,
      0.10976465046405792,
      -0.009083494544029236,
      -0.04489483684301376,
      0.03119507245719433,
      0.08256760239601135,
      0.028437523171305656,
      -0.007011000998318195,
      -0.0655449852347374,
      -0.05882759392261505,
      -0.03482265770435333,
      -0.005052575841546059,
      -0.05860527232289314,
      -0.03112765960395336,
      0.04901469498872757,
      -0.014340073801577091,
      -0.042884934693574905,
      0.028821589425206184,
      0.08299241214990616,
      -0.11060695350170135,
      0.021880609914660454,
      -0.03301793709397316,
      -0.05937178432941437,
      0.02860088460147381,
      -0.033025432378053665,
      -0.036341212689876556,
      -0.005159512162208557,
      0.0016833695117384195,
      0.04053283482789993,
      0.04941317439079285,
      0.049120765179395676,
      0.00926363654434681,
      0.03796850144863129,
      -0.04948000982403755,
      0.05636509135365486,
      -0.07868991792201996,
      -0.03749673441052437,
      -0.059555549174547195,
      0.02959827147424221,
      0.08484213054180145,
      -0.0041955155320465565,
      -0.007923737168312073,
      -0.1145259365439415,
      0.08405715972185135,
      -0.0002561552100814879,
      -0.02402355894446373,
      0.05109594389796257,
      -0.035936105996370316,
      -0.009628274478018284,
      0.038474854081869125,
      0.055985987186431885,
      -0.043262600898742676,
      -0.02999274805188179,
      0.04443268105387688,
      0.05758385732769966,
      0.03940347954630852,
      -0.04668497294187546,
      -0.000514363928232342,
      -0.033177558332681656,
      0.03005535528063774,
      -0.04807766154408455,
      0.02712448500096798,
      -0.038738686591386795,
      0.0036359322257339954,
      0.016938859596848488,
      0.05286223441362381,
      -0.006011610850691795,
      -0.01537503395229578,
      -0.04078638181090355,
      -0.004419483244419098,
      0.007079244591295719,
      -0.05895240977406502,
      -0.03918526694178581,
      0.04410839080810547,
      0.04503227397799492,
      -0.01898777298629284,
      0.05610088258981705,
      0.11291814595460892,
      -0.048087019473314285,
      -0.04653790593147278,
      -0.02782311476767063,
      -0.09721612930297852,
      0.04754782095551491,
      -0.01630946435034275,
      -0.013368030078709126,
      0.010317249223589897,
      0.017319215461611748,
      0.10136178135871887,
      0.03583249822258949,
      -0.015439985319972038,
      -0.04639654606580734,
      0.06110658124089241,
      0.008327418006956577,
      -0.021391049027442932,
      -0.04628641530871391,
      0.047146026045084,
      -0.03346167877316475,
      0.004378104582428932,
      0.11143177002668381,
      -0.012461083941161633,
      0.009612909518182278,
      -0.022446291521191597,
      -0.019416147843003273,
      0.034705888479948044,
      0.02602783776819706,
      -0.00519699789583683,
      -0.009046636521816254,
      -0.025150498375296593,
      0.07798744738101959,
      0.08549755066633224,
      -0.013284502550959587,
      0.035728007555007935,
      0.04129482060670853,
      -0.02700895443558693,
      -0.013287315145134926,
      -0.013537924736738205,
      0.03622052073478699,
      -0.037073392421007156,
      0.013018819503486156,
      -0.010149947367608547,
      -0.06716925650835037,
      -0.07510575652122498,
      -0.056489624083042145,
      -0.049263715744018555,
      -0.08310199528932571,
      -0.08837790787220001,
      0.006081505678594112,
      0.0674360990524292,
      0.030793994665145874,
      0.01379628386348486,
      -0.04840361326932907,
      -0.05048740655183792,
      0.04468701779842377,
      0.0019147213315591216,
      0.06498372554779053,
      -0.07795761525630951,
      -0.07735678553581238,
      -0.02535725012421608,
      -0.03151799738407135,
      -0.011688166297972202,
      0.03407885506749153,
      3.057583307963796e-05,
      0.04187255725264549,
      -0.0286972988396883,
      0.004676462151110172,
      -0.015417058952152729,
      -0.0030107847414910793,
      -0.03819333016872406,
      0.04352036118507385,
      -0.040476296097040176,
      -0.07463423907756805,
      0.017248334363102913,
      -0.05552084743976593,
      0.024605916813015938,
      0.03717793524265289,
      0.006769586820155382,
      0.047421954572200775,
      -0.008767126128077507,
      0.03538187965750694,
      -0.05920152738690376,
      0.08508476614952087,
      0.017162462696433067,
      -0.012742470018565655,
      -0.0051785921677947044,
      -0.015126864425837994,
      0.020414860919117928,
      -0.10361862182617188,
      0.0681125745177269,
      0.10040204226970673,
      0.05925076827406883,
      -0.03384951874613762,
      0.040037352591753006,
      0.010004601441323757,
      -0.0183717031031847,
      -0.05040418729186058,
      -0.008215736597776413,
      0.08264833688735962,
      0.08781392127275467,
      -0.032169777899980545,
      0.01236210111528635,
      -0.08575823158025742,
      -0.02287440188229084,
      -0.017289653420448303,
      0.0809253454208374,
      0.031960710883140564,
      0.0028480507899075747,
      0.0474485382437706,
      -0.02448827400803566,
      -0.03562063351273537,
      0.052686236798763275,
      -0.011404791846871376,
      -0.047320205718278885,
      0.0030679425690323114,
      0.024276934564113617,
      -0.0042550149373710155,
      0.046069301664829254,
      0.06999695301055908,
      0.004546439740806818,
      -0.04142453521490097,
      -0.005098108667880297,
      -0.02136322297155857,
      -0.06239623576402664,
      0.019835129380226135,
      0.052556250244379044,
      0.05804877355694771,
      0.03933640941977501,
      -0.10082999616861343,
      -0.012887174263596535,
      0.040577232837677,
      0.036775294691324234,
      0.050811223685741425,
      0.05176028236746788,
      -0.02413124218583107,
      0.03889813274145126,
      0.001773146097548306,
      0.03013940341770649,
      0.06772554665803909,
      -0.06903669983148575,
      0.06797425448894501,
      0.025539681315422058,
      -0.04808548837900162,
      0.0775584727525711,
      -0.03431335836648941,
      -0.013058716431260109,
      -0.020856158807873726,
      0.006962681654840708,
      -0.005290431901812553,
      -0.05792350694537163,
      -0.07517064362764359,
      0.023484865203499794,
      0.01858099177479744,
      0.010734917595982552,
      0.01507645845413208,
      0.06070683151483536,
      -0.019818169996142387,
      -0.001734195975586772,
      -0.04973331466317177,
      0.043264616280794144,
      -0.002136729657649994,
      0.04268006607890129,
      -0.10605466365814209,
      -0.061054158955812454,
      0.023839591071009636,
      0.008701950311660767,
      0.006498664617538452,
      0.018691806122660637,
      -0.005945828277617693,
      0.02833319827914238,
      0.05277260020375252,
      -0.034389059990644455,
      0.04783269762992859,
      -0.04478718712925911,
      -0.010996217839419842,
      -0.1095205768942833,
      -0.08785732090473175,
      -0.0017714333953335881,
      -0.03816196694970131,
      0.022723866626620293,
      -0.01610662415623665,
      0.08702526241540909,
      0.027850056067109108,
      0.10831230878829956,
      -0.04836476221680641,
      -0.027396533638238907,
      0.022207627072930336,
      0.06370474398136139,
      0.03621078282594681,
      -0.003362910822033882,
      0.027244899421930313,
      0.10222769528627396,
      0.0011266141664236784,
      -0.10927610099315643,
      -7.060086500132456e-05,
      0.02288786880671978,
      0.06540084630250931,
      -0.06256197392940521,
      0.01514980848878622,
      -0.06607242673635483,
      0.025366775691509247,
      -0.044444192200899124,
      0.08000269532203674,
      -0.029612112790346146,
      -0.003383111674338579,
      0.0006096147699281573,
      -0.06317656487226486,
      -0.0017034849151968956,
      -0.015407746657729149,
      0.09759699553251266,
      -0.07844133675098419,
      0.002832122379913926,
      0.0036877719685435295,
      -0.010982077568769455,
      -0.052544329315423965,
      0.012675001285970211,
      0.022588547319173813,
      0.009781417436897755,
      -0.07183543592691422,
      -0.04302460327744484,
      0.07271227240562439,
      -0.004651005379855633,
      -0.07657648622989655,
      -0.03555445373058319,
      -0.021879728883504868,
      -0.017383156344294548,
      -0.040269237011671066,
      -0.005932745523750782,
      -0.013448209501802921,
      0.05995672568678856,
      0.069130077958107,
      0.003467690432444215,
      0.0325654074549675,
      -0.047021206468343735,
      -0.027286533266305923,
      0.07378730922937393,
      0.047205112874507904,
      0.025567173957824707,
      -0.040948573499917984,
      0.1003352552652359,
      0.0836913213133812,
      0.08604759722948074,
      0.002551120240241289,
      0.07009529322385788,
      -0.07474331557750702,
      -0.002202344126999378,
      -0.03580697253346443,
      0.038516949862241745,
      -0.007671469822525978,
      0.0749492347240448,
      0.07730146497488022,
      -0.0023106855805963278,
      -0.09689733386039734,
      0.027659548446536064,
      -0.01940605416893959
    ],
    [
      -0.12632028758525848,
      -0.06940727680921555,
      0.0854836106300354,
      0.023592963814735413,
      0.0033051730133593082,
      0.05795750766992569,
      0.06886427104473114,
      -0.010221780277788639,
      -0.02551022171974182,
      0.09373881667852402,
      0.08949616551399231,
      0.015396385453641415,
      0.0417385958135128,
      -0.01589706540107727,
      0.014131980016827583,
      0.06061079353094101,
      0.08369940519332886,
      0.019025245681405067,
      0.0014809693675488234,
      0.0070368885062634945,
      0.05654928833246231,
      -0.061460889875888824,
      -0.06653722375631332,
      -0.005005328916013241,
      -0.01746625266969204,
      -0.040043432265520096,
      -0.003778499085456133,
      0.07104631513357162,
      0.03671299293637276,
      0.03660077229142189,
      0.010018937289714813,
      0.025350388139486313,
      -0.08502587676048279,
      -0.0035709647927433252,
      0.033609066158533096,
      -0.03913559392094612,
      0.004387875087559223,
      -0.061918631196022034,
      0.06122121587395668,
      -0.04900618642568588,
      0.00951527152210474,
      -0.012512286193668842,
      -0.015698213130235672,
      -0.028648173436522484,
      0.016357984393835068,
      0.00605550454929471,
      0.007442802656441927,
      -9.903948375722393e-05,
      0.010474632494151592,
      0.04030333086848259,
      0.11155560612678528,
      -0.03628399968147278,
      0.02923990786075592,
      -0.005715203937143087,
      0.0008967709727585316,
      0.08759738504886627,
      0.016571786254644394,
      -0.056369997560977936,
      0.09032788127660751,
      0.05220530182123184,
      0.03696412593126297,
      0.011085054837167263,
      0.03250260278582573,
      0.04648616164922714,
      0.03654149919748306,
      0.09195743501186371,
      -0.007561929058283567,
      0.00231173075735569,
      0.019912097603082657,
      -0.04608219489455223,
      -0.0534244142472744,
      -0.049391116946935654,
      0.00900248996913433,
      0.043460663408041,
      0.028614912182092667,
      -0.003452276811003685,
      0.03133835643529892,
      0.029635366052389145,
      -0.032239895313978195,
      0.04221072420477867,
      0.04522565379738808,
      0.02975534461438656,
      0.07117720693349838,
      -0.07795540988445282,
      -0.033818669617176056,
      -0.054885417222976685,
      0.010844700038433075,
      0.01833568513393402,
      0.01874781772494316,
      -0.0024811772163957357,
      -0.04757557436823845,
      0.04189310595393181,
      -0.04877542331814766,
      0.03387202322483063,
      -0.020863020792603493,
      -0.047622375190258026,
      -0.003687717951834202,
      -0.021865971386432648,
      -0.02561667189002037,
      0.03793821483850479,
      -0.027240240946412086,
      0.04089733213186264,
      0.009786291047930717,
      0.03259371221065521,
      0.004148266278207302,
      -0.010332555510103703,
      -0.09091857820749283,
      -0.027309531345963478,
      0.0007652541971765459,
      0.055860452353954315,
      -0.003889756742864847,
      0.006138563621789217,
      0.013541379943490028,
      -0.03866862878203392,
      0.01899038255214691,
      0.03673044964671135,
      0.033730681985616684,
      0.03746352717280388,
      -0.0016682865098118782,
      -0.011900059878826141,
      -0.0024621919728815556,
      -0.001095341402105987,
      0.013636169023811817,
      -0.05299345776438713,
      0.023448560386896133,
      0.0016383379697799683,
      -0.032081782817840576,
      -0.012599575333297253,
      -0.04124034196138382,
      0.030297517776489258,
      -0.02275548316538334,
      -0.003048143582418561,
      0.017116330564022064,
      -0.017588142305612564,
      0.027410369366407394,
      0.003981122747063637,
      0.013583848252892494,
      0.034244563430547714,
      0.035905301570892334,
      -0.010114971548318863,
      0.02505350299179554,
      0.012675294652581215,
      0.035471342504024506,
      0.06980416923761368,
      0.050314392894506454,
      0.016496704891324043,
      0.04121728613972664,
      0.06539743393659592,
      -0.012924949638545513,
      0.022345032542943954,
      0.043512869626283646,
      0.02651873044669628,
      -0.09906923025846481,
      0.04723580554127693,
      0.05836079642176628,
      0.02557915635406971,
      0.05979746952652931,
      0.07671376317739487,
      0.04023704677820206,
      0.005515715107321739,
      -0.004589238669723272,
      0.04314187914133072,
      -0.026437237858772278,
      0.02615251950919628,
      0.04729168489575386,
      0.00921096745878458,
      -0.10212826728820801,
      -0.07608332484960556,
      -0.009531272575259209,
      0.04219869151711464,
      -0.051188938319683075,
      0.03275778517127037,
      0.03441252559423447,
      -0.024486489593982697,
      -0.003092843573540449,
      0.010195453651249409,
      -0.03910079598426819,
      -0.03617152199149132,
      0.0389682911336422,
      -0.036351755261421204,
      -0.055454473942518234,
      0.006084036082029343,
      -0.030213499441742897,
      -0.07428118586540222,
      -0.017221707850694656,
      -0.10554177314043045,
      -0.025020930916070938,
      -0.04961934685707092,
      -0.01949498988687992,
      0.051785919815301895,
      -0.023871131241321564,
      0.014356558211147785,
      -0.023041637614369392,
      0.08177865296602249,
      0.005370743107050657,
      0.0033655348233878613,
      0.03598852455615997,
      0.03814706578850746,
      0.045747991651296616,
      -0.015339904464781284,
      -0.04428398236632347,
      0.048057086765766144,
      0.012697776779532433,
      -0.11399437487125397,
      -0.038479033857584,
      -0.02701598033308983,
      0.10059548914432526,
      -0.04073575511574745,
      0.04529073089361191,
      0.027961870655417442,
      -0.0708189308643341,
      -0.017832079902291298,
      0.057525165379047394,
      0.026052886620163918,
      -0.034629371017217636,
      0.0868152305483818,
      -0.0008943183929659426,
      -0.02799035795032978,
      0.06357941776514053,
      -0.006500122137367725,
      -0.06260985136032104,
      -0.005001946352422237,
      -0.07432598620653152,
      0.035562288016080856,
      -0.025075620040297508,
      0.018538232892751694,
      -0.07609222084283829,
      0.02077704854309559,
      0.011571918614208698,
      0.009315955452620983,
      0.02918146550655365,
      0.02828386425971985,
      0.0770486369729042,
      -0.05153042823076248,
      0.017212621867656708,
      0.05592787265777588,
      -0.06560338288545609,
      0.04007795825600624,
      -0.06710188090801239,
      0.03677858039736748,
      -0.07060615718364716,
      -0.03526535630226135,
      -0.014816655777394772,
      -0.06449058651924133,
      0.015594027005136013,
      -0.07235927134752274,
      -0.014622963033616543,
      0.069060318171978,
      0.013070842251181602,
      -0.030260557308793068,
      -0.009455958381295204,
      0.017429864034056664,
      0.003945774864405394,
      0.013876209035515785,
      -0.00386922899633646,
      -0.035207197070121765,
      0.012679902836680412,
      0.06462574005126953,
      0.06350324302911758,
      0.008478566072881222,
      0.09760072827339172,
      -0.06733491271734238,
      -0.04537787660956383,
      -0.010196667164564133,
      0.007858370430767536,
      0.021091463044285774,
      -0.04094838723540306,
      0.014763353392481804,
      0.06914008408784866,
      -0.07868056744337082,
      0.05330602079629898,
      -0.11511030048131943,
      -0.023237230256199837,
      -0.031999439001083374,
      -0.013340657576918602,
      -0.030459530651569366,
      -0.02090819925069809,
      -0.009655684232711792,
      0.014356202445924282,
      -0.00997774675488472,
      -0.022007182240486145,
      -0.0477604977786541,
      0.041611991822719574,
      -0.09183976799249649,
      0.00462624104693532,
      0.015445246361196041,
      -0.047827038913965225,
      -0.007959739305078983,
      0.06750336289405823,
      0.0915655791759491,
      -0.08252795040607452,
      -0.05056295916438103,
      -0.018283331766724586,
      -0.022061368450522423,
      -0.0549146831035614,
      -0.006739743519574404,
      -0.05265207588672638,
      -0.04316790774464607,
      -0.026662552729249,
      -0.03438488021492958,
      0.025562254711985588,
      0.09828925877809525,
      -0.011790559627115726,
      0.00542211951687932,
      0.02322564087808132,
      0.034436117857694626,
      0.006968336645513773,
      0.054325662553310394,
      0.018175499513745308,
      -0.000990637461654842,
      -0.02110709249973297,
      0.06665483117103577,
      0.03402252495288849,
      -0.0005662778276018798,
      0.07259940356016159,
      0.07709203660488129,
      0.037339769303798676,
      -0.06648305058479309,
      -0.06586531549692154,
      0.008889179676771164,
      -0.010887634940445423,
      0.04233616963028908,
      -0.06537425518035889,
      0.001065903576090932,
      -0.028326839208602905,
      -0.011408028192818165,
      -0.01931508630514145,
      0.019324200227856636,
      -0.007280318532139063,
      -0.05228164419531822,
      0.03226151317358017,
      -0.07824886590242386,
      -0.04833438619971275,
      0.0057934727519750595,
      0.017586195841431618,
      0.0257022213190794,
      -0.052211303263902664,
      -0.047101445496082306,
      0.07744581997394562,
      0.005137694999575615,
      0.04983802139759064,
      0.035732053220272064,
      -0.004384555388242006,
      0.04107401520013809,
      -0.014630724675953388,
      0.07327359914779663,
      -0.004478482995182276,
      -0.014338353648781776,
      -0.009883289225399494,
      -0.07874119281768799,
      0.006029380019754171,
      0.014823791570961475,
      -0.019239483401179314,
      0.09037025272846222,
      -0.003424034221097827,
      0.07163628935813904,
      -0.0009619613410905004,
      -0.005256542470306158,
      -0.10854848474264145,
      0.04598287492990494,
      -0.020862018689513206,
      -0.059284813702106476,
      -0.008462945930659771,
      -0.041977427899837494,
      -0.041477520018815994,
      -0.023209013044834137,
      -0.0005685779615305364,
      0.038765087723731995,
      -0.007935707457363605,
      -0.028222816064953804,
      0.030318979173898697,
      -0.10582118481397629,
      -0.049728941172361374,
      -0.029453761875629425,
      -0.06610026955604553,
      -0.02361653931438923,
      -0.0045037660747766495,
      0.034169312566518784,
      -0.05602734163403511,
      0.00015465797332581133,
      -0.008800989016890526,
      0.00721720140427351,
      -0.027601594105362892,
      -0.087839774787426,
      0.05321350321173668,
      -0.04848932847380638,
      0.0010005023796111345,
      -0.03151975944638252,
      -0.03647339716553688,
      -0.01386747881770134,
      -0.05486737936735153,
      -0.041605595499277115,
      0.07038652151823044,
      0.087757408618927,
      0.07698854058980942,
      0.09486095607280731,
      -0.009524190798401833,
      0.09662824124097824,
      -0.017699649557471275,
      0.033072710037231445,
      -0.008947671391069889,
      -0.03909143805503845,
      0.012829791754484177,
      -0.058687396347522736,
      -0.02444944716989994,
      -0.045052602887153625,
      -0.03153738006949425,
      0.03343261033296585,
      0.07124915719032288,
      0.033712320029735565,
      -0.017497511580586433,
      0.0251826923340559,
      -0.04700819030404091,
      -0.04284315183758736,
      0.02689206972718239,
      -0.04031926020979881,
      -0.00034447299549356103,
      0.03063064068555832,
      0.024880338460206985,
      0.012520807795226574,
      -0.05080007389187813,
      -0.02231309749186039,
      0.020596595481038094,
      -0.03529784455895424,
      -0.0016278106486424804,
      0.006068602204322815,
      0.0058737206272780895,
      0.13307152688503265,
      0.009469511918723583,
      0.0015896932454779744,
      0.011638632044196129,
      -0.03564839065074921,
      -0.04909471794962883,
      0.02209588699042797,
      0.07486043870449066,
      -0.008916640654206276,
      0.08146982640028,
      0.04813743755221367,
      0.005602144170552492,
      0.05104898288846016,
      0.002159079536795616,
      -0.07335007190704346,
      -0.0551384761929512,
      -0.06314513832330704,
      0.01369018666446209,
      0.025414209812879562,
      -0.01730939745903015,
      0.0461239367723465,
      -0.03937555104494095,
      0.11052463203668594,
      0.004404361359775066,
      -0.012892629019916058,
      -0.025987764820456505,
      -0.03666510432958603,
      0.06591717153787613,
      0.02940320037305355,
      -0.05722034350037575,
      0.02584482729434967,
      -0.03427702933549881,
      -0.006532822735607624,
      0.02129920944571495,
      -0.02013164758682251,
      0.053962934762239456,
      -0.09589578211307526,
      0.061421021819114685,
      0.013114256784319878,
      -0.05768989771604538,
      -0.025290723890066147,
      -0.012470700778067112,
      -0.0006974110729061067,
      0.0413006991147995,
      -0.05687374994158745,
      0.0013393275439739227,
      -0.02281622774899006,
      -0.07507467269897461,
      0.015233284793794155,
      -0.013054225593805313,
      0.021709144115447998,
      0.02613821066915989,
      0.02881993167102337,
      0.058962270617485046,
      0.023535404354333878,
      0.0023738888558000326,
      -0.026722179725766182,
      -0.01050451397895813,
      -0.01710532419383526,
      -0.005471752490848303,
      -0.00634338241070509,
      0.0413677841424942,
      0.016397472470998764,
      -0.054003745317459106,
      0.011916395276784897,
      0.01849493943154812,
      -0.02407017908990383,
      -0.01707041636109352,
      0.04290822520852089,
      -2.1340621970011853e-05,
      0.062283847481012344,
      0.049722012132406235,
      -0.08003097027540207,
      0.010709957219660282,
      0.002430427586659789,
      -0.07184018194675446
    ],
    [
      0.07063627988100052,
      0.0036416086368262768,
      -0.012478796765208244,
      0.012744156643748283,
      -0.07977908849716187,
      -0.02247309684753418,
      -0.035988178104162216,
      0.05020131170749664,
      0.028668934479355812,
      0.1023171916604042,
      0.047984588891267776,
      0.04743470251560211,
      0.13022129237651825,
      -0.05529170483350754,
      0.020445866510272026,
      -0.020768655464053154,
      0.05919082462787628,
      -0.014245833270251751,
      0.0661618560552597,
      -0.05608462542295456,
      -0.0065218196250498295,
      -0.06701724231243134,
      0.02252643182873726,
      0.004844612907618284,
      0.043200891464948654,
      0.022464420646429062,
      -0.0028985703829675913,
      0.0012769452296197414,
      0.031623177230358124,
      0.056494951248168945,
      -0.006712248083204031,
      -0.01986284740269184,
      -0.05565376952290535,
      0.014885355718433857,
      -0.039372801780700684,
      -0.04976590722799301,
      0.024157553911209106,
      -0.015078570693731308,
      0.0015312647446990013,
      -0.008336644619703293,
      0.020795386284589767,
      -0.04310842603445053,
      -0.06845435500144958,
      0.04222733899950981,
      0.014587200246751308,
      -0.01977042853832245,
      -0.04324224591255188,
      -0.022494342178106308,
      -0.02504890412092209,
      -0.04213191941380501,
      0.07359116524457932,
      -0.055588286370038986,
      -0.03168545663356781,
      -0.02683732658624649,
      0.09243039786815643,
      0.055405814200639725,
      -0.0483655147254467,
      0.026289457455277443,
      0.07925033569335938,
      -0.019825583323836327,
      0.028970016166567802,
      -0.03648455813527107,
      0.043120376765728,
      -0.0011596584226936102,
      -0.06250368803739548,
      -0.01704309694468975,
      -0.0009282949031330645,
      0.02772589772939682,
      0.0034596892073750496,
      0.001986653311178088,
      1.809954846976325e-05,
      0.07941465079784393,
      0.03190917149186134,
      0.043851323425769806,
      0.06396453082561493,
      -0.022110072895884514,
      -0.028294770047068596,
      0.018638378009200096,
      -0.017848769202828407,
      0.011288722045719624,
      -0.011090780608355999,
      -0.06430201977491379,
      -0.04697979986667633,
      -0.0437408871948719,
      0.02851424552500248,
      0.0319717675447464,
      -0.11150210350751877,
      -0.08657781779766083,
      -0.08232854306697845,
      -0.01860959455370903,
      0.010504852049052715,
      0.06885677576065063,
      0.05954777076840401,
      -0.027554232627153397,
      -0.05144220218062401,
      0.030272942036390305,
      -0.008418409153819084,
      0.04512976109981537,
      0.04037727788090706,
      -0.020206283777952194,
      0.11852113157510757,
      -4.217111836624099e-06,
      -0.040435269474983215,
      0.047129251062870026,
      0.03916545957326889,
      -0.004288624972105026,
      0.04400863125920296,
      -0.06432036310434341,
      -0.012664543464779854,
      -0.041530344635248184,
      -0.04226243123412132,
      -0.09347150474786758,
      -0.0014498099917545915,
      -0.013363144360482693,
      0.00022284229635261,
      0.019950352609157562,
      0.11447189003229141,
      0.029534585773944855,
      -0.0015547201037406921,
      0.010269646532833576,
      0.031244542449712753,
      0.10868245363235474,
      0.08727119863033295,
      0.008930401876568794,
      -0.02571607194840908,
      0.0476735420525074,
      -0.02639973722398281,
      0.06848866492509842,
      0.029659589752554893,
      0.0018565066857263446,
      0.01305331103503704,
      0.006941715255379677,
      -0.0014400893123820424,
      -0.06714379042387009,
      0.032168760895729065,
      0.012352341786026955,
      0.0051026782020926476,
      -0.005584001541137695,
      0.009312479756772518,
      -0.06144406646490097,
      0.06850136816501617,
      0.038607992231845856,
      -0.026095641776919365,
      0.052952464669942856,
      0.050155963748693466,
      0.006293770391494036,
      0.045636508613824844,
      0.010470114648342133,
      -0.07698629796504974,
      0.0109771229326725,
      -0.0024637063033878803,
      0.04810667783021927,
      0.011394448578357697,
      -0.010564601048827171,
      0.05561359226703644,
      0.042134929448366165,
      0.0011910020839422941,
      -0.053730156272649765,
      0.033696796745061874,
      -0.026704320684075356,
      -0.04117970168590546,
      0.08494172990322113,
      0.03771071508526802,
      0.07082140445709229,
      -0.001859909389168024,
      -0.0640464648604393,
      0.021181408315896988,
      0.010835636407136917,
      0.03852418065071106,
      0.06445427238941193,
      -0.023960595950484276,
      0.09944551438093185,
      0.07343299686908722,
      0.018579257652163506,
      -0.012369625270366669,
      0.0019293216755613685,
      -0.026032641530036926,
      0.007675310596823692,
      -0.00933064054697752,
      0.008956638164818287,
      -0.013013479299843311,
      0.006993513088673353,
      -0.047238945960998535,
      -0.002376167569309473,
      -0.02685893513262272,
      0.01885749213397503,
      -0.048662975430488586,
      0.028653865680098534,
      0.06936423480510712,
      0.024342752993106842,
      -0.046244118362665176,
      -0.040888383984565735,
      0.04252881929278374,
      0.07001152634620667,
      0.03311179578304291,
      -0.009319616481661797,
      0.018619133159518242,
      -0.04645147547125816,
      0.02709236927330494,
      -0.0056292093358933926,
      0.04400242865085602,
      -0.024271680042147636,
      0.1152135357260704,
      -0.01870914362370968,
      0.023735133931040764,
      0.014937616884708405,
      -0.004548575263470411,
      0.028280284255743027,
      0.052746713161468506,
      -0.010438857600092888,
      0.02583259716629982,
      -0.06475116312503815,
      0.018590005114674568,
      0.03431231901049614,
      0.06031350791454315,
      -0.033709753304719925,
      -0.03860213980078697,
      0.0033568437211215496,
      -0.02061239443719387,
      0.09234154224395752,
      0.04311954602599144,
      -0.042926110327243805,
      0.1152314767241478,
      -0.012036534026265144,
      -0.027447624132037163,
      0.04423682391643524,
      0.00827762670814991,
      -0.004689318593591452,
      0.0420856811106205,
      -0.011367213912308216,
      -0.030630871653556824,
      -0.05763625353574753,
      0.08798795938491821,
      0.0371585339307785,
      0.021327031776309013,
      0.01762671209871769,
      -0.031918369233608246,
      0.0006443006568588316,
      0.03711944818496704,
      0.13868626952171326,
      0.04661034792661667,
      0.002383404178544879,
      0.009349060244858265,
      0.019148163497447968,
      0.013730267994105816,
      -0.0696156769990921,
      0.037785306572914124,
      -0.024500923231244087,
      -0.009598484262824059,
      -0.045021530240774155,
      -0.018140168860554695,
      0.010350311174988747,
      0.016065333038568497,
      -0.02729215659201145,
      -0.019877053797245026,
      0.001245461986400187,
      -0.04835407808423042,
      -0.07433964312076569,
      0.1011856198310852,
      0.014833015389740467,
      0.02025008015334606,
      0.004327921196818352,
      0.0014868027064949274,
      0.029600393027067184,
      -0.03842388093471527,
      0.006567167583853006,
      -0.02843593619763851,
      -0.02446885034441948,
      0.06439335644245148,
      0.021383140236139297,
      0.027283309027552605,
      0.03054795227944851,
      0.01848628558218479,
      -0.024703802540898323,
      0.011636692099273205,
      -0.009492086246609688,
      -0.004338955041021109,
      -0.01918400265276432,
      -0.04783378913998604,
      -0.043564602732658386,
      0.06133232265710831,
      -0.05734403058886528,
      0.10031436383724213,
      -0.10104233026504517,
      -0.09466835111379623,
      -0.08716413378715515,
      -0.09336258471012115,
      0.09456464648246765,
      -0.01333280187100172,
      0.035308193415403366,
      -0.004506757482886314,
      0.023780517280101776,
      -0.015088653191924095,
      -0.0036602721083909273,
      -0.005296503659337759,
      0.07527225464582443,
      -0.012402133084833622,
      -0.005555019248276949,
      -0.010391591116786003,
      0.08803694695234299,
      -0.06037619709968567,
      0.0436188243329525,
      0.005426774732768536,
      -0.020886920392513275,
      -0.06651691347360611,
      0.021750206127762794,
      0.02335365116596222,
      -0.004432046785950661,
      -0.05575411766767502,
      -0.0861080065369606,
      -0.03701633960008621,
      0.03565046936273575,
      -0.0515330471098423,
      -0.08536866307258606,
      0.03446969762444496,
      -0.03580377250909805,
      0.030641434714198112,
      0.020461494103074074,
      0.032786011695861816,
      0.055535588413476944,
      -0.014032638631761074,
      -0.019772620871663094,
      0.023249005898833275,
      0.016814371570944786,
      -0.028279438614845276,
      -0.014310281723737717,
      -0.03425963968038559,
      -0.09664323180913925,
      0.0747346431016922,
      -0.00682174414396286,
      -0.009872827678918839,
      0.06090684235095978,
      0.07246083766222,
      -0.027058124542236328,
      0.014349350705742836,
      -0.03263160586357117,
      0.020799236372113228,
      0.05767233297228813,
      0.01970347762107849,
      -0.002715037204325199,
      0.011673763394355774,
      0.07909835129976273,
      -0.014691192656755447,
      -0.023804185912013054,
      -0.06920275092124939,
      -0.03296570107340813,
      0.03818433731794357,
      -0.03307487070560455,
      -0.03875504806637764,
      -0.023630773648619652,
      0.05593142285943031,
      -0.0054421089589595795,
      0.014063744805753231,
      0.02784758247435093,
      -0.022466758266091347,
      -0.09286976605653763,
      -0.03179249167442322,
      0.01786034367978573,
      -0.03421789035201073,
      -0.0452798493206501,
      -0.02627243846654892,
      -0.0343000665307045,
      -0.06581918895244598,
      0.061096567660570145,
      0.01780548319220543,
      0.01238050777465105,
      -0.05710099637508392,
      0.0022739809937775135,
      -0.03885204717516899,
      0.0546175055205822,
      0.03181163966655731,
      0.008153182454407215,
      0.004208351019769907,
      0.00935322605073452,
      0.07410978525876999,
      0.04776060953736305,
      -0.012194826267659664,
      -0.020877251401543617,
      0.0057396297343075275,
      -0.051436156034469604,
      0.0477498397231102,
      0.021419189870357513,
      -0.05721910297870636,
      -0.05537562072277069,
      0.0003393586666788906,
      -0.024134043604135513,
      0.062004394829273224,
      0.08244169503450394,
      0.039152566343545914,
      -0.06376256048679352,
      -0.009662521071732044,
      0.053825635462999344,
      -0.02726062200963497,
      0.096407450735569,
      0.00397696765139699,
      -0.05744461342692375,
      -0.015722228214144707,
      0.11336909979581833,
      0.01004880852997303,
      0.015056561678647995,
      -0.0004227078170515597,
      -0.005929321516305208,
      0.02465759962797165,
      0.015021773986518383,
      -0.02118747867643833,
      -0.04733652248978615,
      -0.07882288843393326,
      0.022251537069678307,
      0.08110731840133667,
      0.04145103320479393,
      -0.05600325018167496,
      0.007174812722951174,
      -0.029240813106298447,
      -0.014474892988801003,
      -0.04187279939651489,
      -0.0759192630648613,
      -0.021136237308382988,
      0.05017051845788956,
      0.05017870292067528,
      -0.00031835297704674304,
      0.03050202503800392,
      -0.019720502197742462,
      0.05897926539182663,
      0.011914452537894249,
      -0.03844707831740379,
      0.04696491360664368,
      0.04256900027394295,
      -0.06446173787117004,
      -0.010430319234728813,
      -0.013453025370836258,
      -0.0003668110293801874,
      -0.031642746180295944,
      0.0404793918132782,
      -9.293679568145308e-07,
      0.012682057917118073,
      -0.08249789476394653,
      0.07203440368175507,
      -0.027553467079997063,
      0.0627485066652298,
      0.003249603323638439,
      -0.06088344007730484,
      -0.0470416359603405,
      -0.009764485992491245,
      -0.012892905622720718,
      0.048508431762456894,
      0.05082422494888306,
      -0.04553021118044853,
      0.02344626374542713,
      0.018964413553476334,
      0.04397290572524071,
      -0.03375783935189247,
      -0.04003259912133217,
      -0.0977337434887886,
      -0.03609137609601021,
      0.016013963147997856,
      0.0170772522687912,
      0.042629096657037735,
      0.03505996987223625,
      0.006363680120557547,
      0.021199705079197884,
      0.02196907065808773,
      0.044323183596134186,
      -0.04325997456908226,
      -0.04464763030409813,
      -0.02185622602701187,
      -0.10024406760931015,
      0.07002264261245728,
      -0.018417034298181534,
      -0.007417956832796335,
      -0.03688216581940651,
      0.02904180996119976,
      -0.11634407192468643,
      -0.025787921622395515,
      0.09438399225473404,
      0.08427321165800095,
      0.08275929093360901,
      -0.04072451964020729,
      -0.035421375185251236,
      0.02516511268913746,
      -0.019619209691882133,
      0.08174356073141098,
      0.07555186748504639,
      -0.016570942476391792,
      0.018709395080804825,
      -0.049491286277770996,
      -0.08989863097667694,
      0.0004283002926968038,
      0.013656416907906532,
      -0.042578622698783875,
      -0.10930873453617096,
      0.059670254588127136,
      0.019427696242928505,
      -0.012388558126986027,
      -0.010336177423596382,
      0.06608669459819794,
      -0.013496925123035908,
      -0.004853810649365187,
      -0.004971478134393692,
      0.04884665831923485,
      -0.04708871617913246,
      -0.09504543244838715,
      0.050617724657058716,
      -0.05288664251565933
    ],
    [
      -0.058129411190748215,
      0.0549684576690197,
      -0.03925109654664993,
      -0.000593335775192827,
      -0.04132092371582985,
      0.09839466214179993,
      -0.05495861545205116,
      -0.03389262408018112,
      0.04112305864691734,
      0.06153886392712593,
      -0.025528229773044586,
      0.006575458683073521,
      -0.062299683690071106,
      -0.08885890990495682,
      -0.049821432679891586,
      -0.00041977770160883665,
      -0.06920916587114334,
      0.013431482948362827,
      0.003524036379531026,
      0.05029318481683731,
      0.055148616433143616,
      0.024693762883543968,
      0.08894616365432739,
      0.08378493040800095,
      -0.004834622144699097,
      0.04434719681739807,
      -0.07167809456586838,
      0.07274479418992996,
      -0.05131399258971214,
      0.04290440306067467,
      0.021406522020697594,
      0.05991537496447563,
      0.0077623785473406315,
      -0.09152231365442276,
      -0.005687277298420668,
      0.005853760521858931,
      0.04982078820466995,
      0.0030667318496853113,
      -0.08816109597682953,
      0.010817812755703926,
      -0.07648196071386337,
      0.0038330440875142813,
      0.02815432660281658,
      -0.020017048344016075,
      -0.0073581887409091,
      0.01809065416455269,
      0.1256365031003952,
      -0.04858752340078354,
      0.06741563230752945,
      0.010667632333934307,
      0.08003819733858109,
      0.078187495470047,
      -0.015090744011104107,
      -0.061029911041259766,
      -0.026725417003035545,
      0.013279624283313751,
      -0.026257256045937538,
      0.018748145550489426,
      0.022258756682276726,
      0.010464100167155266,
      -0.015623900108039379,
      -0.012080474756658077,
      0.04792306572198868,
      -0.04313712939620018,
      -0.08752775192260742,
      -0.0143302446231246,
      -0.06728849560022354,
      0.014841809868812561,
      -0.03188294172286987,
      0.03531348705291748,
      -0.06570866703987122,
      0.011748024262487888,
      -0.05229390040040016,
      -0.05251545086503029,
      0.03744489327073097,
      -0.012483482249081135,
      -0.060623809695243835,
      0.004515747539699078,
      0.024626461789011955,
      0.09105955064296722,
      0.029683152213692665,
      -0.059474583715200424,
      -0.01580311357975006,
      0.06055540218949318,
      0.03296533226966858,
      0.04385744407773018,
      0.01715010404586792,
      -0.0655355378985405,
      -0.005396910477429628,
      0.04046066477894783,
      0.02224709279835224,
      -0.006805377546697855,
      0.01969345472753048,
      -0.008875641040503979,
      -0.01981234923005104,
      0.012890310026705265,
      0.022172050550580025,
      0.025440813973546028,
      0.020818380638957024,
      0.014683996327221394,
      -0.0018312196480110288,
      0.0241391621530056,
      -0.058954671025276184,
      0.07416080683469772,
      0.027818206697702408,
      0.07251717895269394,
      -0.03971150517463684,
      0.013332675211131573,
      0.008202114142477512,
      -0.07751801609992981,
      0.032518770545721054,
      -0.010553481057286263,
      -0.005387133918702602,
      -0.005528755486011505,
      -0.03314422816038132,
      -0.004540202673524618,
      -0.005025304388254881,
      -0.04567030444741249,
      -0.005736806895583868,
      0.024051710963249207,
      0.04508864879608154,
      0.057502444833517075,
      0.011119673028588295,
      0.040690649300813675,
      -0.004843350034207106,
      0.016648393124341965,
      -0.0037813992239534855,
      -0.03670094162225723,
      -0.05500311776995659,
      0.09657222032546997,
      -0.037280403077602386,
      0.07291761040687561,
      0.007383548654615879,
      -0.013914061710238457,
      0.013669135048985481,
      -0.03873555362224579,
      -0.015528056770563126,
      -0.024172784760594368,
      0.04508158192038536,
      0.0541217103600502,
      0.02903405763208866,
      -0.012258665636181831,
      -0.03571958839893341,
      0.10413125157356262,
      0.00660520838573575,
      -0.07748435437679291,
      -0.050607919692993164,
      -0.017948180437088013,
      -0.02420143224298954,
      0.07757654786109924,
      -0.01487198006361723,
      -0.03098265640437603,
      -0.12088830769062042,
      -0.02431977353990078,
      -0.00620928592979908,
      -0.049241792410612106,
      -0.013532320968806744,
      0.01053876243531704,
      -0.037958551198244095,
      0.03758537769317627,
      0.020609164610505104,
      0.03004753217101097,
      0.054750945419073105,
      -0.05157390981912613,
      -0.06098082289099693,
      -0.009479095228016376,
      -0.045621808618307114,
      0.09145636111497879,
      0.028047872707247734,
      0.05583909898996353,
      0.02200121432542801,
      -0.05974360555410385,
      -0.005312488880008459,
      0.002095948439091444,
      -0.05599534511566162,
      0.05665070191025734,
      -0.04495277255773544,
      0.045329928398132324,
      -0.02241075411438942,
      -0.03310739994049072,
      0.02280304580926895,
      0.014892707578837872,
      -0.05079592764377594,
      0.01125241443514824,
      -0.006945241708308458,
      0.001850399188697338,
      -0.02175099402666092,
      0.002716805785894394,
      0.01065483782440424,
      -0.01639222726225853,
      0.026295725256204605,
      -0.04456663504242897,
      -0.012499557808041573,
      0.053995221853256226,
      -0.06962233036756516,
      -0.01063605397939682,
      -0.006405110005289316,
      -0.024200493469834328,
      -0.07212893664836884,
      -0.04244277998805046,
      0.05672840401530266,
      0.0036113951355218887,
      -0.04125013202428818,
      -0.00858982838690281,
      0.0230645090341568,
      0.051358647644519806,
      0.007561685983091593,
      0.035688113421201706,
      -0.023861467838287354,
      0.02702263370156288,
      0.034252725541591644,
      0.039452582597732544,
      0.00404856214299798,
      0.05928601324558258,
      -0.06626616418361664,
      -0.05636090785264969,
      -0.007407782133668661,
      0.07325898855924606,
      0.0013201171532273293,
      0.09002333134412766,
      0.03646063432097435,
      -0.09710294008255005,
      0.0007060577627271414,
      -0.00891895592212677,
      0.03892733156681061,
      -0.017666706815361977,
      0.02811938337981701,
      0.04358351230621338,
      0.0493125319480896,
      0.03163796290755272,
      -0.018807699903845787,
      -0.05801030993461609,
      0.044456955045461655,
      -0.014194454066455364,
      -0.06265372037887573,
      -0.03132016584277153,
      0.04754086211323738,
      -0.03791184350848198,
      0.015286899171769619,
      -0.0328284315764904,
      0.025562724098563194,
      -0.047760285437107086,
      0.09265068173408508,
      0.04616333916783333,
      0.09475064277648926,
      -0.06532388925552368,
      -0.03966763615608215,
      -0.057165030390024185,
      -0.042495109140872955,
      -0.03386212885379791,
      0.03541240841150284,
      0.01511339284479618,
      0.024695996195077896,
      -0.10072559118270874,
      -0.024939140304923058,
      -0.0900130495429039,
      -0.06635899096727371,
      0.0767553299665451,
      0.04231540486216545,
      0.03947749733924866,
      0.02109290286898613,
      -0.012728219851851463,
      -0.14391563832759857,
      -0.008015766739845276,
      -0.05365060269832611,
      0.029546508565545082,
      -0.021688438951969147,
      -0.027250204235315323,
      0.03577986732125282,
      -0.046175889670848846,
      0.016418762505054474,
      -0.0013806951465085149,
      0.022761663421988487,
      0.045323703438043594,
      0.0019367370987311006,
      -0.015421187505126,
      0.06923841685056686,
      0.046844370663166046,
      0.0065793488174676895,
      0.05940396711230278,
      0.012476280331611633,
      0.041453514248132706,
      0.1753174215555191,
      0.043282561004161835,
      0.0029226653277873993,
      0.032612092792987823,
      0.039230503141880035,
      -0.025414561852812767,
      -0.022351037710905075,
      -0.07501094043254852,
      -0.017798375338315964,
      0.02692936733365059,
      -0.021938219666481018,
      -0.005823245272040367,
      -0.013604437001049519,
      -0.0866396501660347,
      0.021008389070630074,
      -0.026908669620752335,
      -0.009547693654894829,
      -0.034477394074201584,
      -0.01800365187227726,
      0.04461519047617912,
      -0.011105230078101158,
      0.018462589010596275,
      0.06304580718278885,
      0.00795159861445427,
      -0.001112346420995891,
      0.01909804902970791,
      -0.024292293936014175,
      0.05311804264783859,
      -0.03266264870762825,
      -0.02171223983168602,
      -0.02237801067531109,
      -0.022870438173413277,
      -0.06046638265252113,
      -0.005016686860471964,
      -0.010085212998092175,
      0.04377489909529686,
      -0.004336938261985779,
      0.04780099540948868,
      0.010965744964778423,
      0.07758703827857971,
      -0.03813674673438072,
      0.09605685621500015,
      -0.06756691634654999,
      -0.036392051726579666,
      -0.02366718091070652,
      -0.07043946534395218,
      0.059079840779304504,
      -0.018337439745664597,
      0.012947166338562965,
      0.033184949308633804,
      0.03368915989995003,
      -0.03205623850226402,
      -0.01723562553524971,
      0.00992351770401001,
      0.08398361504077911,
      -0.04333486780524254,
      -0.0036157083231955767,
      -0.04080577939748764,
      -0.026565253734588623,
      -0.017922760918736458,
      -0.04724861681461334,
      -0.0066125537268817425,
      -0.012789109721779823,
      0.0027458525728434324,
      0.08991361409425735,
      -0.01344866119325161,
      -0.06591998785734177,
      -0.0045217787846922874,
      0.05118405073881149,
      0.10239774733781815,
      -0.029497403651475906,
      -0.05651499703526497,
      -0.004879324696958065,
      0.016007233411073685,
      -0.04196107015013695,
      0.004075893200933933,
      -0.08179453015327454,
      -0.056323591619729996,
      0.005746795330196619,
      -0.02896091155707836,
      0.02242969162762165,
      -0.006229901220649481,
      -0.009442831389605999,
      -0.07164551317691803,
      0.0017864868277683854,
      -0.041814059019088745,
      0.018340546637773514,
      0.06742013245820999,
      0.015060200355947018,
      -0.01627887785434723,
      -0.06059717386960983,
      -0.04270331561565399,
      -0.03965939208865166,
      -0.02482723258435726,
      -0.010198947973549366,
      0.06157734617590904,
      -0.01483329851180315,
      -0.01326295081526041,
      -0.023694945499300957,
      -0.028270479291677475,
      0.00575472554191947,
      -0.056862685829401016,
      -0.012996352277696133,
      -0.07029902189970016,
      0.023423008620738983,
      -0.015186121687293053,
      -0.03188763186335564,
      0.055595360696315765,
      -0.004932461306452751,
      0.0010525762336328626,
      -0.013749850913882256,
      -0.07546592503786087,
      -0.016575587913393974,
      0.043367963284254074,
      0.055401235818862915,
      -0.008108780719339848,
      0.006157217547297478,
      0.02377825416624546,
      -0.011786073446273804,
      -0.07396571338176727,
      -0.08249328285455704,
      -0.009722625836730003,
      -0.010808104649186134,
      0.0951852947473526,
      0.03735450655221939,
      0.05951140075922012,
      0.01935955137014389,
      -0.06627262383699417,
      0.0183495432138443,
      0.05605704337358475,
      -0.08704954385757446,
      -0.006503059063106775,
      0.07536523044109344,
      -0.003596646012738347,
      0.02874991111457348,
      -0.06052693352103233,
      0.025736862793564796,
      -0.044357482343912125,
      -0.031171318143606186,
      0.022095434367656708,
      0.012319994159042835,
      -0.0016348460922017694,
      -0.026946384459733963,
      0.02612653560936451,
      -0.01410153042525053,
      0.049484431743621826,
      -0.03612176328897476,
      0.01563500612974167,
      -0.09514544904232025,
      0.04486347362399101,
      0.045705780386924744,
      0.00045461254194378853,
      0.02508879080414772,
      0.05126109719276428,
      -0.010395186021924019,
      -0.0008283502538688481,
      0.0007258133846335113,
      -0.03924541547894478,
      0.0025636230129748583,
      -0.03550828993320465,
      -0.01542594376951456,
      0.006272286642342806,
      0.01722555048763752,
      0.027890976518392563,
      -0.12687605619430542,
      0.03918343782424927,
      0.04151333495974541,
      -0.045369021594524384,
      -0.023102503269910812,
      -0.006918991915881634,
      -0.005869297310709953,
      -0.007693432737141848,
      -0.07523566484451294,
      0.025007599964737892,
      0.035202085971832275,
      0.07623349875211716,
      -0.005897820461541414,
      -0.012444517575204372,
      0.039774175733327866,
      0.002058134414255619,
      0.05091516673564911,
      0.029924487695097923,
      -0.0037567131221294403,
      -0.016011761501431465,
      -0.08928874135017395,
      -0.028326530009508133,
      -0.033219825476408005,
      0.023729795590043068,
      0.06306392699480057,
      0.02427852898836136,
      -0.05277295410633087,
      0.12596291303634644,
      0.004886535927653313,
      -0.03438654541969299,
      -0.02334105595946312,
      -0.0051681892946362495,
      0.010951586998999119,
      0.007549144793301821,
      -0.014202149584889412,
      0.05205874890089035,
      0.024059249088168144,
      0.04976765438914299,
      -0.032592955976724625,
      -0.03321205452084541,
      -0.015173009596765041,
      -0.020204275846481323,
      -0.06112975627183914,
      -0.015773095190525055,
      0.02537604235112667,
      0.0068916394375264645,
      -0.003176217433065176,
      0.00734691321849823,
      0.017593715339899063,
      -0.045397479087114334,
      0.008039082400500774,
      0.05406098812818527,
      -0.02345876954495907,
      -0.060462336987257004,
      -0.0788336917757988,
      0.04479237645864487,
      -0.049188122153282166
    ],
    [
      0.04504266753792763,
      -0.027490127831697464,
      -0.02507224678993225,
      -0.12148270010948181,
      0.0030521987937390804,
      -0.02009638026356697,
      0.026471680030226707,
      0.019385041669011116,
      0.044992946088314056,
      -0.056096337735652924,
      -0.008383787237107754,
      -0.006641080137342215,
      -0.07166632264852524,
      -0.023823995143175125,
      -0.04031690955162048,
      -0.026727396994829178,
      0.015283354558050632,
      0.05776551738381386,
      0.02307559922337532,
      -0.010744941420853138,
      0.023460227996110916,
      -0.042227569967508316,
      -0.05785812810063362,
      0.10674667358398438,
      -0.011862638406455517,
      -0.012358181178569794,
      -0.038731254637241364,
      0.005364423152059317,
      0.026305656880140305,
      0.08881067484617233,
      -0.031100498512387276,
      -0.04502853378653526,
      -0.04227957874536514,
      -0.0485883392393589,
      -0.017907308414578438,
      0.09898875653743744,
      0.0613042451441288,
      -0.07637637108564377,
      0.10626796633005142,
      -0.03491295501589775,
      -0.059413447976112366,
      0.08380256593227386,
      0.0025450263638049364,
      0.0006295456551015377,
      0.04729067161679268,
      -0.046058159321546555,
      -0.028245383873581886,
      -0.026534348726272583,
      -0.08851976692676544,
      -0.048362016677856445,
      -0.027961935847997665,
      0.0029509426094591618,
      0.0004562147369142622,
      -0.06479327380657196,
      0.11001775413751602,
      0.09269022941589355,
      -0.04457308351993561,
      0.02637620083987713,
      0.038717228919267654,
      -0.10786260664463043,
      0.038973964750766754,
      0.009322023019194603,
      -0.08180242776870728,
      -0.037325650453567505,
      -0.04763112962245941,
      -0.047810208052396774,
      0.014527927152812481,
      -0.04887108877301216,
      0.009951994754374027,
      0.05995091423392296,
      0.033118829131126404,
      0.021423324942588806,
      0.01683139055967331,
      -0.022182617336511612,
      -0.001433070283383131,
      -0.015629079192876816,
      -0.07617358863353729,
      -0.10047153383493423,
      0.05532184988260269,
      0.010193836875259876,
      -0.02351433038711548,
      -0.026407485827803612,
      -0.007940288633108139,
      0.08166589587926865,
      0.03937973827123642,
      0.05121328681707382,
      0.01672479696571827,
      -0.04533793777227402,
      -0.014974769204854965,
      -0.0017838618950918317,
      -0.014929872937500477,
      -0.012841320596635342,
      0.041143469512462616,
      0.07937593013048172,
      0.03809328377246857,
      0.08532022684812546,
      -0.01614982634782791,
      -0.04897394776344299,
      -0.006100786384195089,
      -0.012990041635930538,
      0.07423096150159836,
      -0.015188814140856266,
      0.04251694679260254,
      0.035280991345644,
      0.04391814023256302,
      -0.018333090469241142,
      0.004686327651143074,
      0.019038332626223564,
      0.04202400520443916,
      -0.0001250159548362717,
      0.07303746044635773,
      -0.008314562030136585,
      -0.019196445122361183,
      -0.030307238921523094,
      0.05521951988339424,
      -0.0401960052549839,
      0.020239796489477158,
      0.04102061688899994,
      -0.012693480588495731,
      -0.0005601828452199697,
      0.019636118784546852,
      0.02707892842590809,
      -0.0005707719246856868,
      -0.00972310546785593,
      0.012568341568112373,
      0.015615162439644337,
      0.0018295226618647575,
      0.07780823856592178,
      0.017501741647720337,
      0.027719980105757713,
      -0.048863399773836136,
      0.02664090320467949,
      -0.017609892413020134,
      -0.014891218394041061,
      0.014373213052749634,
      0.049407947808504105,
      0.027219586074352264,
      -0.016609972342848778,
      0.04615897312760353,
      -0.06172836199402809,
      0.010505490005016327,
      0.08550149202346802,
      0.07613950222730637,
      0.054518427699804306,
      0.0027328219730407,
      -0.01766819879412651,
      -0.0001521566155133769,
      0.00033143770997412503,
      -0.053672850131988525,
      0.0034065530635416508,
      -0.0518198125064373,
      0.01940338872373104,
      -0.08897705376148224,
      -0.020697755739092827,
      0.021699197590351105,
      0.1176278293132782,
      -0.0805492103099823,
      -0.00286844396032393,
      -0.04428070783615112,
      -0.014328001998364925,
      -0.08859612792730331,
      0.020695123821496964,
      0.024362340569496155,
      -0.04039433225989342,
      0.001621302217245102,
      -0.02957923524081707,
      -0.03160746023058891,
      -0.012815133668482304,
      0.00045457654050551355,
      0.03556864708662033,
      -0.06684103608131409,
      0.0685441642999649,
      0.07192223519086838,
      -0.0803709477186203,
      0.016348741948604584,
      0.0011591261718422174,
      -0.014599709771573544,
      0.07574774324893951,
      0.005966679658740759,
      -0.058907922357320786,
      0.008442100137472153,
      0.05928110331296921,
      -0.07275760173797607,
      -0.029658636078238487,
      -0.0516277514398098,
      -0.016260787844657898,
      -0.027991963550448418,
      0.023760177195072174,
      0.01572308875620365,
      0.05560307204723358,
      -0.0241495780646801,
      -0.06313516944646835,
      0.010290165431797504,
      -0.024134257808327675,
      0.07147029787302017,
      0.034220851957798004,
      0.043424349278211594,
      0.023993929848074913,
      0.014241782017052174,
      -0.02278401330113411,
      0.03939102590084076,
      0.023345250636339188,
      -0.002173135057091713,
      -0.05681157484650612,
      -0.026037855073809624,
      -0.02198987826704979,
      -0.04973797872662544,
      -0.002709662076085806,
      -0.051801543682813644,
      0.012263641692698002,
      0.018018921837210655,
      -0.044671520590782166,
      -0.034930914640426636,
      -0.09122653305530548,
      0.01172233559191227,
      0.04275151342153549,
      0.046463366597890854,
      -0.0019498474430292845,
      0.06173531711101532,
      0.028529496863484383,
      0.01253079529851675,
      -0.021036729216575623,
      0.07600408047437668,
      0.04280735179781914,
      0.02478897012770176,
      -0.008481687866151333,
      -0.046262383460998535,
      -0.11187797039747238,
      -0.021671107038855553,
      0.07898688316345215,
      0.03495084121823311,
      0.013483531773090363,
      -0.017751069739460945,
      0.00678229471668601,
      -0.002197254216298461,
      0.08347728848457336,
      -0.005342806223779917,
      -0.047480419278144836,
      0.08279529958963394,
      0.0036481223069131374,
      -0.05564627796411514,
      0.03628694266080856,
      0.027946773916482925,
      0.008715455420315266,
      -0.043253395706415176,
      -0.0936950296163559,
      -0.03655850514769554,
      -0.045937299728393555,
      -0.04282251372933388,
      -0.024059899151325226,
      -0.03612658008933067,
      -0.021182766184210777,
      0.047962918877601624,
      -0.015229831449687481,
      -0.06185813620686531,
      -0.014445189386606216,
      -0.011393660679459572,
      -0.02650710567831993,
      0.028953831642866135,
      -0.034182026982307434,
      0.05929511412978172,
      -0.0744665265083313,
      0.01911548152565956,
      -0.03083016909658909,
      -0.008451751433312893,
      -0.012280118651688099,
      -0.046320557594299316,
      0.002289620228111744,
      0.032743241637945175,
      0.04121080040931702,
      0.015621928498148918,
      -0.0301204901188612,
      -0.006469149608165026,
      -0.006653922609984875,
      0.01892378367483616,
      0.07762499153614044,
      -0.07433735579252243,
      0.052327532321214676,
      0.04651917144656181,
      -0.047957561910152435,
      0.006240919232368469,
      0.018066180869936943,
      -0.03417891263961792,
      0.0360824316740036,
      -0.013126080855727196,
      -0.05499619245529175,
      0.019656388089060783,
      0.007047083228826523,
      0.005854871589690447,
      0.03516276553273201,
      -0.01702207699418068,
      0.0346694141626358,
      -0.004512675106525421,
      0.015524202957749367,
      0.04201813414692879,
      0.05687780678272247,
      0.08320342749357224,
      -0.03006623312830925,
      -0.008305950090289116,
      0.08766718208789825,
      -0.051969174295663834,
      0.055155184119939804,
      0.004393948707729578,
      0.054111920297145844,
      0.0020382236689329147,
      -0.052477508783340454,
      0.007110971491783857,
      -0.03929128497838974,
      0.05518948659300804,
      -0.02961484156548977,
      -0.01573902927339077,
      0.004689824767410755,
      0.06239033117890358,
      0.06995102018117905,
      0.04489065334200859,
      0.049093157052993774,
      0.05611289665102959,
      0.0006415222305804491,
      -0.06562761217355728,
      0.03697396069765091,
      0.09054887294769287,
      0.07081876695156097,
      0.02245367504656315,
      -0.028049228712916374,
      -0.04119599983096123,
      0.012504341080784798,
      0.13233523070812225,
      0.021812206134200096,
      -0.032355546951293945,
      -0.029508790001273155,
      -0.06589684635400772,
      0.05597525089979172,
      -0.05184698849916458,
      0.017082247883081436,
      -0.06342729181051254,
      0.01816120184957981,
      -0.0298613291233778,
      -0.03068072907626629,
      -0.01238966640084982,
      0.018985357135534286,
      0.027677783742547035,
      -0.004831577651202679,
      -0.007036054041236639,
      0.032618895173072815,
      0.035052068531513214,
      0.020670102909207344,
      -0.05389828607439995,
      -0.05341509357094765,
      0.015679549425840378,
      0.07677251845598221,
      0.03823395073413849,
      0.04963028430938721,
      -0.09280560910701752,
      -0.0919608399271965,
      0.014387606643140316,
      -0.003936196211725473,
      0.02889690175652504,
      0.0252428837120533,
      0.043119460344314575,
      -0.03272365778684616,
      0.04686840623617172,
      -0.019493121653795242,
      0.08770886063575745,
      0.051046691834926605,
      0.0509551540017128,
      -0.049575671553611755,
      0.044533077627420425,
      -0.012972619384527206,
      -0.05938465893268585,
      0.060729071497917175,
      -0.06001428887248039,
      -0.01986020989716053,
      0.054521847516298294,
      0.07173895090818405,
      0.06512589752674103,
      0.007257572375237942,
      0.044140879064798355,
      -0.035312291234731674,
      -0.05296962335705757,
      -0.05921979993581772,
      0.09807164967060089,
      0.036891136318445206,
      0.0018503833562135696,
      -0.0973503440618515,
      -0.02106977440416813,
      0.03937665745615959,
      -0.02891373820602894,
      -0.07550780475139618,
      0.00711784465238452,
      -0.12887269258499146,
      0.028493762016296387,
      -0.03174128383398056,
      0.026385949924588203,
      -0.0007066690595820546,
      0.00023216073168441653,
      -0.008000417612493038,
      -0.039362650364637375,
      0.0214974544942379,
      -0.01713559404015541,
      0.022783031687140465,
      -0.004435283597558737,
      0.02930653840303421,
      0.0396168977022171,
      0.027809353545308113,
      -0.03612607344985008,
      0.022428076714277267,
      0.009913414716720581,
      0.03944425284862518,
      0.060122232884168625,
      -0.026112904772162437,
      -0.057072050869464874,
      0.024723758921027184,
      0.03503861650824547,
      0.025905949994921684,
      -0.03729606047272682,
      -0.057054199278354645,
      -0.003294629044830799,
      -0.0024563660845160484,
      0.03961082920432091,
      0.014919833280146122,
      -0.007936165668070316,
      -0.054659776389598846,
      -0.05401180312037468,
      -0.02763197384774685,
      -0.04263024404644966,
      0.00716436468064785,
      -0.057871825993061066,
      0.0045670997351408005,
      0.05193040147423744,
      0.016586778685450554,
      -0.04567447304725647,
      -0.006009949836879969,
      -0.019033579155802727,
      -0.026244884356856346,
      0.06544499844312668,
      -0.008745010942220688,
      0.07066743820905685,
      0.022037185728549957,
      0.01985134929418564,
      0.0201314277946949,
      -0.0011282569030299783,
      -0.0073104193434119225,
      -0.08213052153587341,
      -0.06105009838938713,
      -0.010706930421292782,
      -0.02334122359752655,
      -0.050284840166568756,
      0.013730520382523537,
      -0.03338482230901718,
      0.029554465785622597,
      0.028068706393241882,
      0.04235900565981865,
      -0.014914853498339653,
      0.0005582275916822255,
      0.026839299127459526,
      0.04735090956091881,
      -0.04783129692077637,
      0.04552564024925232,
      -0.0032920397352427244,
      0.00712128309533,
      -0.005596485920250416,
      0.01429691817611456,
      -0.07445007562637329,
      -0.08603297919034958,
      -0.006231964565813541,
      -0.007930400781333447,
      -0.062438227236270905,
      -0.002071948954835534,
      -0.007662411779165268,
      -0.005259474273771048,
      -0.0014268641825765371,
      -0.05628708750009537,
      -0.042151350528001785,
      0.135700523853302,
      0.042835600674152374,
      0.0075086308643221855,
      0.009141345508396626,
      -0.015097502619028091,
      -0.009855368174612522,
      -0.0873355120420456,
      -0.04455236345529556,
      0.08870712667703629,
      0.02150544710457325,
      -0.06630288809537888,
      -0.023587718605995178,
      0.03779855743050575,
      0.06773610413074493,
      0.008949050679802895,
      -0.05336263030767441,
      -0.06287411600351334,
      -0.07978296279907227,
      0.00996312778443098,
      0.03785313293337822,
      -0.05643396079540253,
      -0.05850929394364357,
      0.02762974239885807,
      -0.042472824454307556,
      0.022741109132766724,
      0.03651413321495056,
      -0.03804821893572807,
      0.05793432518839836,
      0.00500089256092906,
      0.05176148936152458
    ],
    [
      -0.02486700937151909,
      0.009286549873650074,
      0.018205497413873672,
      -0.0011121229035779834,
      -0.010719391517341137,
      -0.018328484147787094,
      0.016777917742729187,
      0.05882490053772926,
      -0.00504087470471859,
      -0.018950052559375763,
      -0.022178033366799355,
      -0.12093677371740341,
      -0.03857846558094025,
      -0.001661192625761032,
      -0.033838655799627304,
      -0.02249743603169918,
      -0.003106583608314395,
      -0.04205253720283508,
      0.027356961742043495,
      0.05369310453534126,
      0.05269055441021919,
      -0.004569711163640022,
      0.0214434452354908,
      -0.05791294947266579,
      0.025577044114470482,
      0.014743596315383911,
      0.031664151698350906,
      -0.06970003992319107,
      0.014852535910904408,
      0.07605469971895218,
      0.03739314526319504,
      0.05471828207373619,
      -0.060799162834882736,
      -0.006683332845568657,
      -0.05687662959098816,
      0.07429154217243195,
      -0.05485806614160538,
      0.030626703053712845,
      0.03190382570028305,
      0.026904897764325142,
      -0.04637489840388298,
      0.006983235944062471,
      0.052743736654520035,
      0.03404565155506134,
      0.008774012327194214,
      0.007868283428251743,
      0.04867791011929512,
      -0.03404974937438965,
      -0.011622742749750614,
      -0.006802049931138754,
      0.010557841509580612,
      -0.03938152641057968,
      -0.02081402577459812,
      -0.021277183666825294,
      0.003443807130679488,
      0.024195358157157898,
      -0.06105049327015877,
      -0.06315520405769348,
      -0.034073032438755035,
      -0.052005622535943985,
      0.05253935605287552,
      -0.012250104919075966,
      -0.024154305458068848,
      -0.05596053600311279,
      0.07052165269851685,
      -0.0199187733232975,
      0.036653902381658554,
      -0.04628809913992882,
      0.04466425999999046,
      -0.05482824891805649,
      -0.11742427945137024,
      0.10632123798131943,
      -0.025831347331404686,
      0.01018817164003849,
      0.0241727065294981,
      0.023583002388477325,
      -0.010711786337196827,
      0.0555315800011158,
      -0.06337796151638031,
      0.05146021395921707,
      0.052282340824604034,
      -0.03354813903570175,
      -0.03468747064471245,
      0.06632592529058456,
      0.002815126907080412,
      -0.03489533066749573,
      0.06295504420995712,
      -0.04575280845165253,
      0.007917508482933044,
      -0.04320436716079712,
      0.017844460904598236,
      0.02291952073574066,
      0.037885330617427826,
      -0.013753463514149189,
      -0.03067750856280327,
      -0.047774624079465866,
      0.016647635027766228,
      -0.0038207434117794037,
      -0.01670822873711586,
      -0.052484530955553055,
      -0.04433158040046692,
      -0.14661648869514465,
      -0.08329808712005615,
      0.10943059623241425,
      0.03398590534925461,
      0.05758528411388397,
      0.026272300630807877,
      -0.021768372505903244,
      0.0014624824980273843,
      0.006485814694315195,
      -0.11011791229248047,
      -0.052104998379945755,
      0.02655712328851223,
      -0.011931237764656544,
      0.014962523244321346,
      0.030261429026722908,
      0.006747882813215256,
      0.020473433658480644,
      0.034100715070962906,
      -0.007912740111351013,
      0.056117668747901917,
      -0.028128596022725105,
      -0.021002935245633125,
      0.035211022943258286,
      -0.06550474464893341,
      -0.007044775877147913,
      0.021001439541578293,
      0.05377652868628502,
      0.051619965583086014,
      0.03470005840063095,
      -0.004709964618086815,
      -0.03129458799958229,
      0.08016163855791092,
      0.029009131714701653,
      0.0657585859298706,
      0.058271076530218124,
      -0.022952193394303322,
      0.010552637279033661,
      -0.07401792705059052,
      0.05736614763736725,
      -0.026555869728326797,
      0.06510576605796814,
      -0.04806020110845566,
      0.03670653700828552,
      0.04905429109930992,
      -0.02181107923388481,
      0.09275050461292267,
      -0.06926871836185455,
      0.000825985916890204,
      0.01524673867970705,
      0.03528339043259621,
      0.011388463899493217,
      0.07489871233701706,
      0.011074735783040524,
      0.021298058331012726,
      -0.03706655651330948,
      -0.09772239625453949,
      -0.04534264653921127,
      -0.01187707670032978,
      0.053551945835351944,
      0.013484486378729343,
      0.036455586552619934,
      0.034253038465976715,
      0.02988443896174431,
      0.07225819677114487,
      0.04679498076438904,
      -0.08749140053987503,
      0.009037971496582031,
      -0.09706038236618042,
      0.1071171909570694,
      0.04299738630652428,
      0.02920624613761902,
      -0.009442686103284359,
      0.00013312508235685527,
      -0.16971082985401154,
      -0.05959472432732582,
      -0.004085028078407049,
      -0.022278791293501854,
      -0.002137764124199748,
      -0.03761685639619827,
      -0.0206235870718956,
      0.044344767928123474,
      -0.031362053006887436,
      0.006197234615683556,
      -0.029671678319573402,
      0.010932032018899918,
      0.09940765798091888,
      0.009103330783545971,
      0.09342449903488159,
      -0.01070819515734911,
      -0.06855645775794983,
      -0.023713910952210426,
      -0.031098904088139534,
      -0.050839245319366455,
      0.018197957426309586,
      -0.037726834416389465,
      0.04073913022875786,
      -0.028233999386429787,
      0.06499041616916656,
      -0.037700630724430084,
      0.04016692563891411,
      0.009295854717493057,
      0.15515851974487305,
      -0.014758693985641003,
      -0.08966938406229019,
      0.09350845962762833,
      -0.03595590963959694,
      -0.045417264103889465,
      0.040565475821495056,
      0.01612553372979164,
      0.01305403932929039,
      0.020657449960708618,
      0.10693509131669998,
      0.09156064689159393,
      0.015753526240587234,
      0.005239221267402172,
      -0.05584978312253952,
      0.05014489218592644,
      -0.011277902871370316,
      0.016580574214458466,
      -0.10473085194826126,
      -0.10288058966398239,
      0.02540753036737442,
      -0.008094792254269123,
      -0.08411771804094315,
      0.0064612324349582195,
      0.004857334308326244,
      -0.01177568081766367,
      -0.026161395013332367,
      0.08002588152885437,
      0.014128819108009338,
      -0.040662284940481186,
      -0.02140713296830654,
      -0.03225841745734215,
      -0.009923388250172138,
      -0.00236940267495811,
      0.022172849625349045,
      -0.010654191486537457,
      0.031885355710983276,
      -0.06232260540127754,
      0.022143159061670303,
      -0.02735729143023491,
      -0.001996803330257535,
      0.06735392659902573,
      -0.04944402351975441,
      -0.04869920760393143,
      -0.032936930656433105,
      -0.13834649324417114,
      0.04355607181787491,
      -0.018982399255037308,
      -0.08270718157291412,
      0.024495771154761314,
      0.020461689680814743,
      0.063514843583107,
      -0.04898104444146156,
      0.007042663171887398,
      -0.02661401964724064,
      -0.012242143042385578,
      0.018592333421111107,
      -0.054178979247808456,
      -0.04047686234116554,
      -0.04735371470451355,
      -0.023251846432685852,
      -0.00502740079537034,
      -0.018437232822179794,
      -0.0009241498191840947,
      0.06486684828996658,
      0.06182416155934334,
      -0.03662943094968796,
      0.013342702761292458,
      -0.007523247040808201,
      0.0373200997710228,
      0.07433149218559265,
      0.018460283055901527,
      -0.03366922587156296,
      0.02395869605243206,
      -0.05598031356930733,
      -0.0027560119051486254,
      -0.05335385352373123,
      0.05442251265048981,
      -0.007979474030435085,
      -0.1046672835946083,
      0.07623548060655594,
      -0.0274028517305851,
      -0.014998461119830608,
      -0.006110303103923798,
      -0.0034125633537769318,
      -0.04917354881763458,
      -0.049733348190784454,
      0.03837180137634277,
      0.01594165712594986,
      0.003267114982008934,
      0.008213473483920097,
      -0.09198047965765,
      -0.03930266201496124,
      0.0487205870449543,
      -0.06286059319972992,
      -0.018829479813575745,
      -0.012009557336568832,
      -0.04133055359125137,
      0.04352113604545593,
      0.025728926062583923,
      -0.01206213515251875,
      -0.03605952858924866,
      -0.09157790243625641,
      -0.02060065232217312,
      0.025083689019083977,
      0.044392794370651245,
      -0.04180465638637543,
      -0.0012594574363902211,
      -0.041523683816194534,
      0.004239358473569155,
      -0.133749321103096,
      0.07475780695676804,
      -0.019343318417668343,
      -0.06386767327785492,
      0.06894809752702713,
      -0.060952939093112946,
      -0.008240697905421257,
      0.00754754152148962,
      -0.03297899663448334,
      -0.0009316514478996396,
      -0.030743757262825966,
      0.029289236292243004,
      -0.005757981911301613,
      0.051358409225940704,
      -0.003327388083562255,
      0.08419501781463623,
      0.03662784770131111,
      0.07140236347913742,
      0.014253705739974976,
      0.049735020846128464,
      -0.0014081770787015557,
      0.03977441042661667,
      0.019440030679106712,
      -0.03869619220495224,
      -0.02871914580464363,
      -0.01240495964884758,
      0.024199893698096275,
      0.0026439926587045193,
      -0.007128429599106312,
      0.01954055018723011,
      0.007891771383583546,
      -0.04205558821558952,
      0.053406864404678345,
      -0.09747318178415298,
      0.03225317969918251,
      0.0039229802787303925,
      -0.03812599554657936,
      0.025145268067717552,
      0.02348596602678299,
      0.01603671908378601,
      0.033750031143426895,
      -0.025532476603984833,
      -0.02097148820757866,
      0.047505225986242294,
      -0.008327079936861992,
      -0.08554857969284058,
      -0.016213107854127884,
      0.1227695420384407,
      0.004091980867087841,
      0.015547819435596466,
      -0.002942885272204876,
      -0.03328796848654747,
      0.0376625619828701,
      -0.04018411785364151,
      -0.03447233885526657,
      0.03870657458901405,
      0.05663492530584335,
      -0.028343595564365387,
      0.012131762690842152,
      -0.0031820624135434628,
      -0.02462163381278515,
      0.07674305140972137,
      -0.03928124159574509,
      -0.01838565431535244,
      0.042619142681360245,
      -0.001969673437997699,
      -0.021734902635216713,
      -0.04353911429643631,
      0.014636335894465446,
      0.02423998713493347,
      -0.012863876298069954,
      -0.034212999045848846,
      0.03161823749542236,
      -0.0027378248050808907,
      -0.039989173412323,
      -0.030176948755979538,
      0.06690801680088043,
      -0.03337022662162781,
      -0.048481814563274384,
      0.0023854472674429417,
      0.04884526878595352,
      0.01592942140996456,
      -0.03647239878773689,
      0.013437805697321892,
      0.09248443692922592,
      -0.014840948395431042,
      -0.07749301195144653,
      0.013979420997202396,
      0.006676285527646542,
      -0.0765594020485878,
      -0.02753688022494316,
      -0.048742398619651794,
      -0.07862193882465363,
      -0.0740513950586319,
      -0.06624514609575272,
      0.020910538733005524,
      -0.019234787672758102,
      -0.08628939837217331,
      -0.016033997759222984,
      -0.012554321438074112,
      0.014965114183723927,
      -0.014106836169958115,
      0.013935324735939503,
      -0.03972524031996727,
      -0.017016267403960228,
      0.05094797909259796,
      -0.029866425320506096,
      -0.026318758726119995,
      -0.09066439419984818,
      -0.011378423310816288,
      0.01998213119804859,
      -0.027171093970537186,
      -0.012951944023370743,
      -0.05165024846792221,
      -0.02127082645893097,
      0.051626697182655334,
      0.02281644567847252,
      0.011340550146996975,
      0.010291979648172855,
      0.010267785750329494,
      -0.11512550711631775,
      0.05954562872648239,
      -0.028599098324775696,
      -0.036340970546007156,
      0.010717547498643398,
      -0.045269694179296494,
      0.0017580906860530376,
      0.031910575926303864,
      0.013246694579720497,
      -0.06521937251091003,
      0.01467562559992075,
      -0.11198153346776962,
      -0.029430722817778587,
      0.03817851096391678,
      0.008112804964184761,
      -0.04990657418966293,
      -0.04967191442847252,
      0.010410130955278873,
      0.00817607156932354,
      -0.018382037058472633,
      -0.030815374106168747,
      0.05341766029596329,
      -0.036670565605163574,
      0.058642443269491196,
      -0.009009686298668385,
      -0.014413918368518353,
      0.011635169386863708,
      -0.026216641068458557,
      0.019215993583202362,
      -0.015912597998976707,
      -0.014870625920593739,
      0.04839246720075607,
      -0.1112421378493309,
      -0.011043738573789597,
      0.009727496653795242,
      -0.005624342244118452,
      0.04158307984471321,
      0.020515378564596176,
      0.02468939684331417,
      0.05304838344454765,
      -0.07572943717241287,
      0.0018891369691118598,
      -0.027972616255283356,
      0.06763521581888199,
      -0.05655612424015999,
      0.033116165548563004,
      0.0005537890829145908,
      0.018960651010274887,
      0.038763392716646194,
      0.09660805016756058,
      0.012978054583072662,
      0.01610793173313141,
      0.101471446454525,
      0.0242085549980402,
      0.009172395803034306,
      -0.045333877205848694,
      -0.06000235676765442,
      -0.07383331656455994,
      0.029312266036868095,
      -0.0657515898346901,
      -0.012702067382633686,
      -0.0466715432703495,
      -0.03449427708983421,
      0.04230909049510956,
      -0.0114212054759264,
      0.042229168117046356,
      0.09009253233671188,
      0.03452061116695404,
      -0.014376587234437466,
      0.021556997671723366,
      -0.002288149669766426
    ],
    [
      -0.08245423436164856,
      -0.005990341771394014,
      -0.01865410804748535,
      -0.09079723805189133,
      0.06186046078801155,
      0.008373286575078964,
      -0.03400551527738571,
      -0.049278683960437775,
      0.03300824388861656,
      -0.04856768250465393,
      0.08697811514139175,
      -0.08884841948747635,
      -0.0066101872362196445,
      -0.007156170904636383,
      -0.008211884647607803,
      -0.002527439035475254,
      -0.04342595487833023,
      -0.015394381247460842,
      0.0763661190867424,
      0.061321064829826355,
      0.05170146003365517,
      0.017464734613895416,
      0.030122803524136543,
      0.012786504812538624,
      -0.021336693316698074,
      -0.03034083917737007,
      -0.0046652317978441715,
      0.06556838750839233,
      0.03153297305107117,
      -0.010004793293774128,
      -0.04872269183397293,
      0.09810411185026169,
      -0.09080013632774353,
      0.014754442498087883,
      0.07933603972196579,
      -0.016419604420661926,
      0.07476111501455307,
      0.03685540333390236,
      0.07450415939092636,
      -0.01984267495572567,
      0.005498696118593216,
      -0.02831660583615303,
      -0.05396445840597153,
      -0.09702759236097336,
      -0.09806409478187561,
      -0.004094603471457958,
      0.07038187235593796,
      0.015099985525012016,
      -0.021915728226304054,
      -0.07112572342157364,
      0.016287911683321,
      -0.020277976989746094,
      -0.06489313393831253,
      0.03248778358101845,
      0.051000818610191345,
      0.00391194736585021,
      -0.0097014419734478,
      -0.012250241823494434,
      -0.027042727917432785,
      -0.024506891146302223,
      -0.034896958619356155,
      -0.005748753435909748,
      -0.011208849027752876,
      0.052895110100507736,
      0.05545443668961525,
      0.050162963569164276,
      -0.1459558755159378,
      0.001831886125728488,
      -0.04454479739069939,
      0.039815038442611694,
      -0.012215317226946354,
      0.02667088247835636,
      -0.011020083911716938,
      -0.06448401510715485,
      -0.06227795407176018,
      -0.021208764985203743,
      0.09955752640962601,
      0.047829609364271164,
      -0.017654411494731903,
      0.014069304801523685,
      -0.04582710564136505,
      0.06932492554187775,
      -0.016509907320141792,
      0.05658530816435814,
      -0.0638754591345787,
      -0.030130425468087196,
      -0.04084395617246628,
      0.061238303780555725,
      -0.01947000063955784,
      0.08575427532196045,
      0.09969113022089005,
      -0.011351874098181725,
      0.05312559753656387,
      -0.026415614411234856,
      0.06175539642572403,
      -0.01300979033112526,
      0.053495168685913086,
      -0.01862816885113716,
      0.002288987161591649,
      -0.08159640431404114,
      -0.010390950366854668,
      -0.017163367941975594,
      -0.06526155769824982,
      0.013101626187562943,
      -0.01878805086016655,
      -0.0018515869742259383,
      0.06276601552963257,
      -0.07359074801206589,
      -0.040838003158569336,
      0.052535057067871094,
      -0.015297427773475647,
      0.039639879018068314,
      0.01093628816306591,
      0.009757068008184433,
      -0.04263480380177498,
      0.017665015533566475,
      0.041634451597929,
      0.03954639658331871,
      0.035525791347026825,
      -0.01763313263654709,
      -0.004085004795342684,
      -0.030781684443354607,
      -0.027550190687179565,
      0.029529184103012085,
      0.1086583137512207,
      0.06178586930036545,
      -0.004808769561350346,
      0.012333421967923641,
      -0.017797239124774933,
      -0.024444038048386574,
      -0.05923612415790558,
      -0.09023606777191162,
      -0.06539284437894821,
      -0.028076793998479843,
      0.042205892503261566,
      -0.0035696816630661488,
      0.020567409694194794,
      -0.0023151342757046223,
      -0.014849042519927025,
      0.008326312527060509,
      -0.012159818783402443,
      0.05065542832016945,
      -0.01798049546778202,
      0.07007446885108948,
      0.10363828390836716,
      0.009553518146276474,
      -0.08122429251670837,
      0.0037624689284712076,
      -0.0099100386723876,
      0.011921657249331474,
      -0.052901457995176315,
      -0.04591679945588112,
      -0.08958104252815247,
      0.0646696612238884,
      -0.04137885943055153,
      -0.006907167844474316,
      0.08348044753074646,
      -0.0769922286272049,
      -0.011152018792927265,
      0.021026423200964928,
      -0.03005063720047474,
      0.013021884486079216,
      -0.029041163623332977,
      -0.029648223891854286,
      0.014795534312725067,
      -0.015951186418533325,
      0.052132781594991684,
      -0.045919984579086304,
      -0.03577306494116783,
      0.042160313576459885,
      0.05610508471727371,
      -0.024463310837745667,
      -0.03963613510131836,
      -0.022477783262729645,
      -0.04054202139377594,
      0.038622014224529266,
      0.013717606663703918,
      0.0623629130423069,
      0.0014812625013291836,
      0.005577361211180687,
      -0.06057794764637947,
      -0.061448562890291214,
      -0.0840507224202156,
      -0.006166301667690277,
      0.004056300036609173,
      -0.03368409723043442,
      0.02511785365641117,
      0.0010325992479920387,
      -0.029196428135037422,
      0.04195807874202728,
      0.053759921342134476,
      0.04830300062894821,
      0.018456537276506424,
      -0.005760529078543186,
      -0.053518202155828476,
      0.013449009507894516,
      -0.02123168483376503,
      0.12680965662002563,
      0.030132904648780823,
      0.016945842653512955,
      0.06427904218435287,
      0.07365861535072327,
      0.03728736191987991,
      -0.0966649204492569,
      -0.009935516864061356,
      0.12446512281894684,
      -0.042885757982730865,
      0.01697181724011898,
      0.05494491010904312,
      0.06411480158567429,
      0.007366149220615625,
      -0.03090236522257328,
      0.04818876087665558,
      0.06219477951526642,
      0.02944616787135601,
      -0.08165252208709717,
      0.004917415790259838,
      0.017908399924635887,
      0.027599023655056953,
      0.04602508619427681,
      -0.01215758454054594,
      0.020335037261247635,
      0.09012727439403534,
      -0.026772184297442436,
      -0.013848093338310719,
      0.0360378734767437,
      0.0019161351956427097,
      -0.06455700844526291,
      -0.06589557975530624,
      -0.008125699125230312,
      0.10964140295982361,
      -0.1278318613767624,
      0.010445338673889637,
      -0.07693804055452347,
      0.06749721616506577,
      -0.007865061052143574,
      0.004435792099684477,
      0.02138996683061123,
      0.026359599083662033,
      0.02519970014691353,
      0.04083440452814102,
      -0.019870374351739883,
      -0.036480240523815155,
      0.028758784756064415,
      0.011164149269461632,
      -0.033593907952308655,
      -0.01319410465657711,
      -0.0035866117104887962,
      -0.0636705607175827,
      0.016266534104943275,
      -0.04979619383811951,
      0.008392414078116417,
      0.004232298582792282,
      -0.0406537726521492,
      -0.023530662059783936,
      -0.05036061629652977,
      0.026607144623994827,
      0.049128782004117966,
      -0.06857133656740189,
      0.07318834215402603,
      -0.020775053650140762,
      -0.011233868077397346,
      0.01123363059014082,
      0.08779465407133102,
      -0.0024347398430109024,
      0.06384923309087753,
      0.030336564406752586,
      -0.04075012728571892,
      -0.003574604168534279,
      0.021049216389656067,
      -0.024392221122980118,
      0.06034447252750397,
      -0.04111695662140846,
      0.005734399426728487,
      0.023257408291101456,
      0.014815796166658401,
      0.049735307693481445,
      0.055014774203300476,
      0.050431620329618454,
      0.054910071194171906,
      -0.03734647110104561,
      -0.03621019050478935,
      -0.028107965365052223,
      0.07525712251663208,
      -0.00040641569648869336,
      -0.027650808915495872,
      -0.07359924912452698,
      0.027040991932153702,
      0.004768635146319866,
      0.0022285333834588528,
      -0.011923237703740597,
      -0.03217730671167374,
      0.016097402200102806,
      -0.06635042279958725,
      -0.011513558216392994,
      0.06576494872570038,
      -0.011586121283471584,
      -0.02537011168897152,
      0.04432709515094757,
      0.0009870098438113928,
      0.0013989369617775083,
      -0.0329761728644371,
      -0.02951132506132126,
      0.003207479603588581,
      0.04188885539770126,
      0.10879404842853546,
      0.0027107372879981995,
      0.05493110418319702,
      0.05914609506726265,
      0.0070542870089411736,
      -0.111463762819767,
      -0.0281671155244112,
      0.018685152754187584,
      0.06441204994916916,
      0.08249586820602417,
      -0.006630862131714821,
      -0.02960553951561451,
      0.03213099017739296,
      -0.006657269783318043,
      -0.0457376204431057,
      0.011316866613924503,
      0.04338342323899269,
      0.03370160236954689,
      -0.03211371973156929,
      -0.0027553539257496595,
      -0.03428960219025612,
      0.0910465344786644,
      0.02789052575826645,
      0.004494913853704929,
      -0.03041459619998932,
      0.054492801427841187,
      0.017327360808849335,
      0.057333845645189285,
      -0.0459531731903553,
      0.026945389807224274,
      0.04075804352760315,
      0.09117303788661957,
      0.03709283471107483,
      0.03128015249967575,
      0.04983658343553543,
      -0.08148616552352905,
      0.026022372767329216,
      0.024100204929709435,
      0.019334986805915833,
      -0.033874139189720154,
      -0.04945411905646324,
      0.001843769452534616,
      0.07797126471996307,
      0.0299159437417984,
      0.06612329930067062,
      0.029169777408242226,
      0.044311802834272385,
      0.021955911070108414,
      -0.0529644638299942,
      0.005405889358371496,
      0.04900151118636131,
      0.014388344250619411,
      -0.00457319151610136,
      -0.13879868388175964,
      -0.06553450226783752,
      -0.029965408146381378,
      0.03354697674512863,
      0.06589974462985992,
      -0.06198534741997719,
      0.040529560297727585,
      -0.03454117104411125,
      0.006954096723347902,
      -0.06902331858873367,
      0.029704654589295387,
      -0.016824491322040558,
      0.04527207091450691,
      0.01644940860569477,
      -0.05760077014565468,
      -0.03047897294163704,
      -0.010668010450899601,
      -0.058398112654685974,
      0.05424066260457039,
      -0.04327348992228508,
      -0.02569066919386387,
      -0.06573189795017242,
      0.05234059318900108,
      0.03275714069604874,
      -0.00877194944769144,
      -0.08146222680807114,
      -0.04719025269150734,
      0.06513305753469467,
      0.01058109663426876,
      0.03664354607462883,
      0.008319525048136711,
      -0.04087365046143532,
      0.05704798921942711,
      -0.11946169286966324,
      -0.006351712625473738,
      0.051102686673402786,
      -0.061347804963588715,
      -0.040461912751197815,
      0.02499246597290039,
      0.0007300408324226737,
      -0.023245569318532944,
      -0.004904457833617926,
      -0.07885289937257767,
      -0.053950779139995575,
      0.024472806602716446,
      -0.04118802398443222,
      -0.02690935507416725,
      -0.01620936580002308,
      -0.05230838060379028,
      -0.0623132660984993,
      0.02524331770837307,
      0.08792702108621597,
      -0.06944157183170319,
      0.03446248546242714,
      -0.03624700382351875,
      0.011583332903683186,
      -0.014178359881043434,
      0.009282078593969345,
      0.033795252442359924,
      -0.027674993500113487,
      0.09887437522411346,
      -0.0048941029235720634,
      -0.005038635339587927,
      -0.005900640040636063,
      -0.03518476337194443,
      0.007191061042249203,
      0.0800771415233612,
      -0.07253970205783844,
      -0.06060202792286873,
      0.024970071390271187,
      0.017492882907390594,
      0.13615448772907257,
      0.036624398082494736,
      -0.004814527463167906,
      0.011025269515812397,
      -0.06957828253507614,
      0.04485443979501724,
      0.01526798214763403,
      -0.04226246848702431,
      0.0009805652080103755,
      0.006557644344866276,
      -0.08781996369361877,
      0.03805208578705788,
      -0.04774681478738785,
      -0.02744401805102825,
      -0.08465783298015594,
      -0.0008633109391666949,
      -0.024731701239943504,
      0.09679877758026123,
      0.044218774884939194,
      -0.029889507219195366,
      0.012533453293144703,
      0.06628122925758362,
      -0.0461646243929863,
      0.051582951098680496,
      0.07413650304079056,
      0.01358458586037159,
      0.008275814354419708,
      0.07411021739244461,
      0.05694732442498207,
      0.08953725546598434,
      0.054585833102464676,
      0.04453634098172188,
      -0.009973379783332348,
      -0.014141708612442017,
      -0.03439680114388466,
      0.02801775000989437,
      -0.002513937884941697,
      0.0010644070571288466,
      0.007084167096763849,
      0.005085819400846958,
      -0.01840178295969963,
      0.06680387258529663,
      -0.12845070660114288,
      0.024217383936047554,
      -0.03281613811850548,
      -0.034302812069654465,
      -0.06971538066864014,
      -0.015951544046401978,
      0.06435403227806091,
      -0.020553605630993843,
      -0.03598978742957115,
      -0.09970789402723312,
      0.06763064116239548,
      -0.03229564055800438,
      -0.02551664039492607,
      -0.010050571523606777,
      -0.04162329435348511,
      -0.04414370283484459,
      -0.008206204511225224,
      0.05467458814382553,
      -0.011357593350112438,
      -0.017159145325422287,
      0.055673010647296906,
      -0.040669914335012436,
      0.060443323105573654,
      0.005740847904235125,
      -0.07439938187599182,
      -0.006630297284573317,
      -0.032046105712652206,
      -0.0135771119967103,
      0.03978624567389488,
      -0.11581346392631531,
      -0.090413898229599,
      -0.018940508365631104
    ],
    [
      0.010685957968235016,
      0.03322623670101166,
      0.027254465967416763,
      -0.09185640513896942,
      -0.04560897871851921,
      -0.01357338111847639,
      -0.038764588534832,
      -0.10521213710308075,
      -0.020937643945217133,
      0.041200410574674606,
      -0.05170341208577156,
      0.01936866156756878,
      -0.031369660049676895,
      0.033796537667512894,
      -0.03408639505505562,
      0.008395330049097538,
      0.056711938232183456,
      0.02350233495235443,
      0.0014749352121725678,
      -0.0007027534302324057,
      0.04462435841560364,
      -0.03182844817638397,
      -0.06998995691537857,
      0.10550141334533691,
      -0.12651154398918152,
      -0.050098732113838196,
      -0.05058695003390312,
      0.03629232197999954,
      -0.10635913163423538,
      0.04583532363176346,
      -0.0715671107172966,
      -0.003691768739372492,
      0.0067283534444868565,
      -0.05447852984070778,
      0.004649984184652567,
      0.027422886341810226,
      0.05481605976819992,
      0.03293079882860184,
      0.015230677090585232,
      -0.015373516827821732,
      -0.0008943710708990693,
      -0.036900315433740616,
      -0.07369761168956757,
      -0.06658537685871124,
      -0.025378825142979622,
      -0.03905998170375824,
      -0.024401644244790077,
      -0.03991599753499031,
      0.0053637330420315266,
      -0.022664330899715424,
      -0.016133688390254974,
      -0.041509635746479034,
      -0.02749532274901867,
      -0.042872559279203415,
      0.018604636192321777,
      0.06250813603401184,
      0.02421483024954796,
      -0.0046019041910767555,
      0.012391000054776669,
      0.004375675693154335,
      -0.004432618152350187,
      -0.00689565297216177,
      0.04006599262356758,
      -0.017346765846014023,
      -0.0035449217539280653,
      0.024110035970807076,
      0.0004249793419148773,
      0.016027864068746567,
      0.01086917333304882,
      0.08638765662908554,
      -0.02167561836540699,
      -0.026114201173186302,
      -0.027252288535237312,
      0.11641410738229752,
      -0.0176249910145998,
      -0.021341748535633087,
      0.04673495143651962,
      -0.006678995676338673,
      -0.02651190757751465,
      0.05025595799088478,
      -0.023141633719205856,
      -0.003731527365744114,
      -0.0008457538206130266,
      0.06779129058122635,
      -0.0027499343268573284,
      -0.022894546389579773,
      -0.017344189807772636,
      -0.039354629814624786,
      0.0807911604642868,
      0.004092134069651365,
      -0.002147951629012823,
      -0.042087409645318985,
      -0.03911212459206581,
      0.0387367345392704,
      0.03308448567986488,
      -0.009644930250942707,
      0.00010772400855785236,
      -0.018836908042430878,
      0.036750443279743195,
      0.031992483884096146,
      0.12748382985591888,
      -0.00025771171203814447,
      -0.07382027059793472,
      -0.008764036931097507,
      0.09816872328519821,
      0.03350389748811722,
      -0.023649903014302254,
      -0.05599583312869072,
      -0.021812448278069496,
      -0.0183787290006876,
      -0.027851955965161324,
      -0.05645531788468361,
      0.01740444451570511,
      0.06549794226884842,
      0.049585528671741486,
      -0.0062225572764873505,
      0.017410023137927055,
      -0.027819493785500526,
      0.07117417454719543,
      0.002054360928013921,
      0.04585463926196098,
      0.021159768104553223,
      0.035631198436021805,
      0.04102281108498573,
      -0.01386616937816143,
      -0.017805462703108788,
      -0.018138378858566284,
      0.0034313295036554337,
      -0.037257205694913864,
      0.004930363968014717,
      0.03976420685648918,
      -0.029970748350024223,
      -0.0015892279334366322,
      0.006522971671074629,
      0.019400902092456818,
      0.039747752249240875,
      0.05226050689816475,
      0.06814856082201004,
      0.0031826957128942013,
      0.04307793080806732,
      -0.020920822396874428,
      0.0011041894322261214,
      0.024659426882863045,
      0.09211939573287964,
      0.045113705098629,
      0.005205797962844372,
      -0.03427720442414284,
      0.02250583842396736,
      0.011261284351348877,
      -0.03887327015399933,
      0.005995083600282669,
      0.037367723882198334,
      -0.03727109357714653,
      0.043682415038347244,
      -0.06266873329877853,
      0.029600191861391068,
      0.04328817501664162,
      -0.044666290283203125,
      0.07393336296081543,
      0.016589583829045296,
      -0.03189155086874962,
      0.07051445543766022,
      0.0031526824459433556,
      0.03373046591877937,
      0.02425261028110981,
      -0.049175187945365906,
      0.005836382508277893,
      0.014439504593610764,
      -0.061020392924547195,
      0.05754464864730835,
      0.024120455607771873,
      -0.017197875306010246,
      0.012170973233878613,
      0.027495555579662323,
      -0.0004228385805618018,
      0.055925317108631134,
      -0.01504143513739109,
      0.0021420959383249283,
      -0.023891285061836243,
      0.012372063472867012,
      0.03724024444818497,
      0.058707986027002335,
      -0.026620548218488693,
      0.044821783900260925,
      0.06978513300418854,
      -0.0586199015378952,
      0.019899729639291763,
      -0.012605450116097927,
      -0.059806905686855316,
      -0.01390501856803894,
      0.05092066898941994,
      -0.07681592553853989,
      -0.018093766644597054,
      -0.03638092800974846,
      0.03206278756260872,
      -0.04468649625778198,
      0.014666305854916573,
      -0.009366622194647789,
      -0.04323774576187134,
      -0.0789470374584198,
      -0.01051995437592268,
      0.01839452236890793,
      0.04633193463087082,
      0.02307642437517643,
      0.03764289990067482,
      -0.029737280681729317,
      0.011741994880139828,
      -0.014955170452594757,
      0.06414420157670975,
      -0.026326000690460205,
      0.06118159741163254,
      -0.0843399241566658,
      0.0011113955406472087,
      0.0014538340037688613,
      0.014927447773516178,
      -0.04528540372848511,
      0.017778705805540085,
      -0.026974206790328026,
      -0.011671487241983414,
      0.08583246916532516,
      -0.03851442411541939,
      -0.06024656072258949,
      0.04126286134123802,
      0.019987380132079124,
      0.0531504787504673,
      -0.011832163669168949,
      -0.01677042432129383,
      -0.04076029360294342,
      0.03548708185553551,
      -0.004616036079823971,
      0.036929935216903687,
      0.0680215135216713,
      0.01598738133907318,
      -0.033761072903871536,
      0.01674365997314453,
      -0.009643398225307465,
      -0.026508843526244164,
      -0.04232122376561165,
      -0.012301307171583176,
      0.05522254854440689,
      -0.007293430157005787,
      -0.06473424285650253,
      0.05052388086915016,
      0.04575248062610626,
      0.011558566242456436,
      -0.016973601654171944,
      -0.02676229365170002,
      -0.05637679621577263,
      -0.035123709589242935,
      -0.049727290868759155,
      0.0017964013386517763,
      0.06010573357343674,
      -0.03923235461115837,
      -0.027346547693014145,
      -0.017942916601896286,
      0.0026162145659327507,
      0.0029105793219059706,
      -0.010373586788773537,
      -0.03215603530406952,
      0.03231239691376686,
      -0.0021991117391735315,
      -0.051154375076293945,
      -0.00018121198809240013,
      -0.029876571148633957,
      0.04512888565659523,
      0.047237277030944824,
      0.01882609724998474,
      -0.015335336327552795,
      -0.061765093356370926,
      0.0019159092335030437,
      0.007388885598629713,
      0.0617951974272728,
      0.048962000757455826,
      -0.027221959084272385,
      0.028391363099217415,
      -0.022749856114387512,
      -0.002023233799263835,
      -0.001297693932428956,
      -0.05573992058634758,
      0.022002555429935455,
      0.059105511754751205,
      -0.05028948560357094,
      0.0017763685900717974,
      -0.015854278579354286,
      -0.08111042529344559,
      -0.06692580878734589,
      0.05078026279807091,
      0.04038136452436447,
      -0.009469546377658844,
      0.004274814855307341,
      -0.07024795562028885,
      -0.00895842257887125,
      -0.004850842524319887,
      -0.004704220686107874,
      0.02091660536825657,
      0.007035419344902039,
      -0.027778195217251778,
      -0.008020865730941296,
      0.048419684171676636,
      0.050721533596515656,
      0.013264980167150497,
      -0.05638518184423447,
      0.051650285720825195,
      0.00909220241010189,
      -0.002003203611820936,
      -0.09493023157119751,
      -0.07370223104953766,
      -0.01657520979642868,
      0.02930622547864914,
      0.011228803545236588,
      -0.002488270401954651,
      -0.01129769254475832,
      -0.0284147709608078,
      0.027679160237312317,
      0.01538565568625927,
      0.012946657836437225,
      0.14522209763526917,
      0.03570745140314102,
      0.002943377010524273,
      -0.027147285640239716,
      0.04629386216402054,
      0.030192410573363304,
      0.07448997348546982,
      -0.036812424659729004,
      0.046672891825437546,
      0.021009746938943863,
      0.03088952787220478,
      -0.0777091383934021,
      0.039255835115909576,
      -0.02671528048813343,
      0.041937947273254395,
      0.002066097455099225,
      -0.02194702811539173,
      0.016831781715154648,
      0.04817900434136391,
      0.016904199495911598,
      -0.09135755151510239,
      0.012958313338458538,
      -0.03234511986374855,
      0.04447348788380623,
      0.04403354972600937,
      -0.022510753944516182,
      -0.05610233172774315,
      -0.06533147394657135,
      -0.030677126720547676,
      -0.014076728373765945,
      0.03882830962538719,
      0.012414870783686638,
      0.033160775899887085,
      0.03673336282372475,
      -0.02319040149450302,
      -0.017625080421566963,
      -0.008046543225646019,
      0.011698385700583458,
      -0.029216710478067398,
      0.06199128180742264,
      0.030399542301893234,
      -0.049982789903879166,
      -0.0852661281824112,
      -0.07416924834251404,
      0.013942738063633442,
      0.007232473697513342,
      0.03858296945691109,
      -0.012788641266524792,
      -0.014209450222551823,
      0.008829941973090172,
      -0.033080317080020905,
      -0.08660144358873367,
      -0.006082267500460148,
      0.042502790689468384,
      -0.05086939036846161,
      -0.05469272658228874,
      -0.03178180009126663,
      -0.009750082157552242,
      -0.007432169280946255,
      -0.05609727278351784,
      0.11105577647686005,
      0.04026823863387108,
      0.01417884323745966,
      0.032357506453990936,
      -0.042981430888175964,
      -0.02298702299594879,
      -0.020577358081936836,
      -0.06853147596120834,
      0.003119962988421321,
      -0.02446248196065426,
      0.040759094059467316,
      -0.0052268411964178085,
      0.001016617869026959,
      -0.04443424567580223,
      -0.022083789110183716,
      -0.023242253810167313,
      0.07591868937015533,
      0.037544265389442444,
      -0.012716026976704597,
      0.03381415084004402,
      -0.030811475589871407,
      0.0006527475197799504,
      0.02311532385647297,
      -0.021550379693508148,
      -0.004986621905118227,
      0.003712021978572011,
      -0.0829247236251831,
      0.016760796308517456,
      0.0030878540128469467,
      -0.010571850463747978,
      0.01576979085803032,
      -0.02267404831945896,
      0.04312723129987717,
      -0.07423466444015503,
      -0.09921484440565109,
      0.01888282224535942,
      0.04828307777643204,
      -0.06789498776197433,
      -0.03168362006545067,
      -0.019232502207159996,
      -0.044804997742176056,
      -0.019448574632406235,
      0.022522423416376114,
      0.0352669321000576,
      0.027624348178505898,
      -0.04042273387312889,
      -0.08231031149625778,
      0.04833722114562988,
      0.0063647013157606125,
      0.0551597885787487,
      0.022314714267849922,
      -0.041555289179086685,
      -0.018809610977768898,
      0.03986938297748566,
      0.03850994631648064,
      0.004626568406820297,
      0.06122496724128723,
      -0.020808646455407143,
      -0.022113315761089325,
      0.10300521552562714,
      0.03956672176718712,
      -0.055199433118104935,
      0.005048966500908136,
      0.04083895683288574,
      0.030409520491957664,
      -0.01598476991057396,
      0.07330689579248428,
      0.0218699611723423,
      0.016742797568440437,
      0.027146581560373306,
      -0.02813025191426277,
      0.05619006231427193,
      -0.017457297071814537,
      0.006388714537024498,
      -0.038480255752801895,
      -0.07302024215459824,
      -0.03310582786798477,
      -0.001209648442454636,
      0.05222639441490173,
      0.03761443495750427,
      0.101584292948246,
      0.04706839844584465,
      0.06012849882245064,
      0.04062797501683235,
      -0.0014510719338431954,
      0.018003730103373528,
      0.007583419792354107,
      0.06098584830760956,
      0.07541229575872421,
      0.05803295224905014,
      0.06975840032100677,
      0.053654201328754425,
      0.06706549972295761,
      -0.04400939494371414,
      0.10666479170322418,
      -0.018404604867100716,
      0.037922412157058716,
      0.06993085891008377,
      -0.06391027569770813,
      -0.07998600602149963,
      -0.016672926023602486,
      0.01717553660273552,
      -0.018994653597474098,
      0.007324692327529192,
      -0.04108690097928047,
      0.06167040392756462,
      -0.06654468178749084,
      -0.003916486166417599,
      -0.04240484535694122,
      -0.0738319382071495,
      0.0022349643986672163,
      -0.04498487710952759,
      0.03689318150281906,
      -0.0074022323824465275,
      -0.0073940567672252655,
      -0.043154727667570114,
      0.00885699037462473,
      0.05958017706871033,
      -0.0015380277764052153,
      -0.03375059366226196,
      0.04824354872107506,
      -0.017839524894952774,
      0.030621705576777458,
      -0.03352455422282219,
      0.01637008599936962,
      0.06774833798408508,
      0.05237771198153496
    ],
    [
      0.06713143736124039,
      -0.04753316193819046,
      -0.028440985828638077,
      -0.08173327147960663,
      0.04146967828273773,
      0.0208832286298275,
      0.045623864978551865,
      0.049465078860521317,
      0.07603850960731506,
      -0.027960199862718582,
      -0.01205326896160841,
      -0.06669794023036957,
      0.029751725494861603,
      0.0051584141328930855,
      -0.03054456226527691,
      -0.09411729127168655,
      0.01827158033847809,
      -0.0017535013612359762,
      0.05981101095676422,
      -0.05965401232242584,
      0.035435184836387634,
      0.060456182807683945,
      -0.058125052601099014,
      0.05491354689002037,
      -0.07795493304729462,
      0.005197085905820131,
      0.026069819927215576,
      0.006375228054821491,
      -0.06096751615405083,
      0.00812582578510046,
      -0.04477623105049133,
      -0.006342066917568445,
      0.04897327348589897,
      -0.041334912180900574,
      -0.036400578916072845,
      0.053995076566934586,
      0.03174876049160957,
      0.01277963351458311,
      0.05398416146636009,
      -0.044064950197935104,
      -0.014210104942321777,
      0.06196623295545578,
      0.07893727719783783,
      -0.0007818525191396475,
      -0.02140946313738823,
      0.026183785870671272,
      0.0590420626103878,
      -0.018968630582094193,
      -0.0003798426769208163,
      0.03537217527627945,
      0.011961203068494797,
      0.07788534462451935,
      0.0497538223862648,
      -0.02231651172041893,
      -0.09195486456155777,
      -0.03421414643526077,
      -0.01839158497750759,
      -0.011824633926153183,
      -0.005190362688153982,
      -0.0036598467268049717,
      0.0022502236533910036,
      -0.011525397188961506,
      -0.012406227178871632,
      -0.03001527488231659,
      -0.05399500951170921,
      -0.051848288625478745,
      -0.0502106212079525,
      0.0317549891769886,
      0.0250029806047678,
      -0.004096665885299444,
      -0.021131454035639763,
      0.08175277709960938,
      -0.02419193461537361,
      -0.03132326900959015,
      -0.021047653630375862,
      -0.037404634058475494,
      -0.0047284336760640144,
      -0.12735489010810852,
      -0.0587485134601593,
      0.0013261692365631461,
      0.04427676647901535,
      0.0013222290435805917,
      0.034283045679330826,
      0.04664159566164017,
      0.0053514172323048115,
      -0.03961832448840141,
      -0.025259055197238922,
      -0.13508065044879913,
      0.015972623601555824,
      0.032663311809301376,
      -0.07760739326477051,
      0.06800097227096558,
      0.07132595777511597,
      -0.03914086893200874,
      -0.08494699001312256,
      0.06536184996366501,
      0.07990235835313797,
      -0.0611712671816349,
      -0.053288184106349945,
      -0.0023671486414968967,
      0.09083345532417297,
      -0.0702141597867012,
      -0.06960997730493546,
      0.007938569411635399,
      0.03252516686916351,
      -0.036203015595674515,
      -0.003978305030614138,
      0.015129202976822853,
      -0.03503093123435974,
      0.08925598114728928,
      -0.048343557864427567,
      -0.10555673390626907,
      -0.06198545917868614,
      -0.0094783715903759,
      -0.05600889399647713,
      0.03333994373679161,
      0.1411317139863968,
      -0.017609931528568268,
      0.12089406698942184,
      -0.00501491641625762,
      0.0363759882748127,
      0.04040617495775223,
      0.0622478649020195,
      0.031134897843003273,
      0.008063407614827156,
      0.01870686560869217,
      -0.00348409335128963,
      -0.019593434408307076,
      -0.04014185070991516,
      -0.07193668931722641,
      0.027035202831029892,
      0.01596751995384693,
      -0.02897798642516136,
      0.0493510402739048,
      0.019112685695290565,
      0.09466498345136642,
      -0.01616016775369644,
      -0.0880148783326149,
      -0.0054842690005898476,
      0.014563418924808502,
      0.0032131001353263855,
      0.02342268079519272,
      -0.03529076278209686,
      0.023256849497556686,
      0.003946753218770027,
      -0.08641522377729416,
      -0.00412237923592329,
      0.005869155749678612,
      -0.012451348826289177,
      -0.03480580449104309,
      0.07329969108104706,
      0.09303087741136551,
      -0.050389889627695084,
      -0.06703782826662064,
      0.021659426391124725,
      -0.021347634494304657,
      0.04245283827185631,
      0.008505051024258137,
      -0.025949876755475998,
      0.001371206482872367,
      -0.02961459383368492,
      -0.016157768666744232,
      0.10048402845859528,
      -0.020940518006682396,
      -0.0036657871678471565,
      -0.04066456854343414,
      0.0036598111037164927,
      0.011378543451428413,
      -0.03547322005033493,
      0.03510604426264763,
      0.03535744920372963,
      0.049645017832517624,
      -0.014669599011540413,
      0.004643939435482025,
      0.026977336034178734,
      0.0666169747710228,
      0.02459181845188141,
      -0.004222370684146881,
      -0.01667139120399952,
      -0.014082837849855423,
      0.07367318123579025,
      -0.01186345610767603,
      0.020443450659513474,
      0.006394161377102137,
      0.0593014732003212,
      -0.0336480513215065,
      -0.062067292630672455,
      -0.03880304470658302,
      0.11032801121473312,
      0.021283753216266632,
      -0.016603883355855942,
      0.05255022272467613,
      -0.04172581806778908,
      0.0028148272540420294,
      0.004340865649282932,
      -0.057200588285923004,
      0.026321962475776672,
      -0.01521958876401186,
      -0.06513679027557373,
      -0.019522933289408684,
      0.08415716141462326,
      0.008371064439415932,
      -0.04452371969819069,
      -0.05276740714907646,
      -0.04825133830308914,
      0.039360642433166504,
      -0.00782964937388897,
      -0.19177527725696564,
      0.1167183518409729,
      -0.009222629480063915,
      0.016729457303881645,
      -0.09267941117286682,
      -0.04702293872833252,
      0.032375551760196686,
      -0.060744285583496094,
      0.023317430168390274,
      -0.07506995648145676,
      -0.04825086519122124,
      -0.0543007031083107,
      -0.058326322585344315,
      -0.03966335952281952,
      -0.041490860283374786,
      -0.00708918459713459,
      -0.01494984794408083,
      0.011604773811995983,
      -0.023405691608786583,
      0.0735834538936615,
      -0.0376334972679615,
      0.002407155465334654,
      0.048670556396245956,
      0.03465605899691582,
      -0.003236614167690277,
      0.04907829686999321,
      0.037853118032217026,
      -0.07268080115318298,
      0.027525514364242554,
      -0.021593701094388962,
      0.012942280620336533,
      -0.008474951609969139,
      0.00034166197292506695,
      0.03251080960035324,
      -0.005124983843415976,
      0.07164273411035538,
      -0.028315013274550438,
      0.0031061209738254547,
      -0.004735772963613272,
      -0.05389218404889107,
      -0.05748719349503517,
      -0.027748113498091698,
      -0.0341799221932888,
      -0.040985602885484695,
      0.03172113001346588,
      -0.006963621359318495,
      -0.0201159305870533,
      -0.014155932702124119,
      0.09163125604391098,
      -0.022378629073500633,
      0.046466320753097534,
      0.012922849506139755,
      0.008229932747781277,
      -0.0032799008768051863,
      0.03454909101128578,
      -0.024109046906232834,
      0.10768107324838638,
      -0.010484801605343819,
      0.01951315626502037,
      -0.0273960642516613,
      -0.01683157868683338,
      -0.05259117856621742,
      0.07528655976057053,
      0.005956329870969057,
      0.040796179324388504,
      -0.05989771708846092,
      -0.06388205289840698,
      -0.11745613068342209,
      0.050897590816020966,
      -0.055240705609321594,
      0.07219871878623962,
      0.024490762501955032,
      0.06915382295846939,
      0.04836050420999527,
      0.0035986853763461113,
      -0.009844142012298107,
      0.0034674813505262136,
      -0.0029276662971824408,
      -0.09633288532495499,
      -0.020689377561211586,
      0.09083236753940582,
      0.03315015882253647,
      -0.020215287804603577,
      -0.04855279251933098,
      -0.012694953009486198,
      -0.004265669267624617,
      0.04176037386059761,
      0.04692724347114563,
      0.0396924689412117,
      -0.05715488642454147,
      -0.0477096289396286,
      0.09102191030979156,
      0.02123880386352539,
      -0.06071522831916809,
      0.045780859887599945,
      -0.021710863336920738,
      0.00245770369656384,
      -0.0023399980273097754,
      0.027122737839818,
      -0.016686612740159035,
      0.046487126499414444,
      0.04020605608820915,
      0.06682298332452774,
      0.012297812849283218,
      -0.011605804786086082,
      0.018043456599116325,
      -0.015274419449269772,
      0.1104162186384201,
      0.08135943859815598,
      0.019292077049613,
      -0.10305453091859818,
      -0.06676875054836273,
      -0.040211621671915054,
      0.004029611591249704,
      -0.022017238661646843,
      -0.016636135056614876,
      -0.03456975519657135,
      -0.05617313086986542,
      -0.03008631430566311,
      0.006945603992789984,
      0.0983852744102478,
      -0.03017202578485012,
      0.04081112518906593,
      -0.0012774482602253556,
      0.030829615890979767,
      -0.007077739108353853,
      0.07342079281806946,
      0.0062459297478199005,
      0.03445003181695938,
      -0.015577542595565319,
      -0.02993846870958805,
      -0.04570282995700836,
      0.060932449996471405,
      -0.06690007448196411,
      0.043029073625802994,
      -0.031028565019369125,
      -0.09278180450201035,
      -0.05883915349841118,
      -0.043036021292209625,
      -0.048265907913446426,
      -0.040305737406015396,
      -0.07983890920877457,
      0.023970115929841995,
      -0.027103379368782043,
      -0.013345041312277317,
      -0.007054547313600779,
      -0.06755201518535614,
      -0.05394640192389488,
      0.07673072069883347,
      0.010094288736581802,
      0.021368568763136864,
      -0.015014148317277431,
      -0.012182500213384628,
      0.03649844601750374,
      0.019585520029067993,
      -0.0387626513838768,
      -0.07828077673912048,
      -0.018305832520127296,
      0.021775906905531883,
      0.03832150623202324,
      -0.04438668489456177,
      -0.052596982568502426,
      0.03870309516787529,
      0.04788855090737343,
      0.013455639593303204,
      -0.03616693988442421,
      -0.00016717791731934994,
      -0.017033776268363,
      0.05319809913635254,
      -0.05051574110984802,
      -0.07732421904802322,
      -0.03067929297685623,
      0.03130793571472168,
      0.09297490119934082,
      0.010916157625615597,
      -0.06538835912942886,
      -0.0700640082359314,
      -0.08150172978639603,
      -0.031629808247089386,
      0.02793576754629612,
      0.07268496602773666,
      -0.0288618765771389,
      -0.0019294687081128359,
      -0.004154587630182505,
      0.07699885964393616,
      0.01991509646177292,
      0.02794240228831768,
      0.039708178490400314,
      -0.04269855096936226,
      0.011585226282477379,
      -0.07170937210321426,
      -0.051805708557367325,
      -0.007950165309011936,
      0.0561869814991951,
      0.007119615562260151,
      -0.02586217224597931,
      0.002587325870990753,
      -0.025165440514683723,
      -0.00933019258081913,
      0.06438393890857697,
      0.012309220619499683,
      0.06699330359697342,
      -0.00896234717220068,
      0.021712975576519966,
      -0.07207652181386948,
      -0.023772571235895157,
      -0.04221336543560028,
      0.051796406507492065,
      0.010194774717092514,
      0.004990109242498875,
      0.037353288382291794,
      -0.029664738103747368,
      -0.022387225180864334,
      -0.015608892776072025,
      -0.04662730172276497,
      -0.009551276452839375,
      0.010001701302826405,
      0.047523319721221924,
      -0.013906673528254032,
      -0.021840417757630348,
      0.011321017518639565,
      -0.019743729382753372,
      0.05136104300618172,
      -0.035096701234579086,
      -0.031199822202324867,
      -0.07609325647354126,
      0.019485656172037125,
      -0.014972046948969364,
      -0.07829109579324722,
      -0.08205736428499222,
      0.03607746958732605,
      -0.03680693358182907,
      -0.025028109550476074,
      -0.08427058905363083,
      -0.018327532336115837,
      -0.0009094655979424715,
      -0.0390825979411602,
      0.017313962802290916,
      0.0052307723090052605,
      0.037256866693496704,
      0.04420062527060509,
      0.03625587373971939,
      0.02627837285399437,
      0.0012929170625284314,
      0.0212923102080822,
      0.026285510510206223,
      0.04886511340737343,
      0.006367577239871025,
      0.0012838863767683506,
      0.03984648734331131,
      -0.057198114693164825,
      0.06570988893508911,
      0.029080195352435112,
      -0.10286310315132141,
      -0.08320882171392441,
      0.0006731440662406385,
      0.014683408662676811,
      -0.022238170728087425,
      0.024558909237384796,
      -0.10427998751401901,
      -0.06429240107536316,
      0.07382950931787491,
      -0.0956539437174797,
      -0.029881540685892105,
      -0.013150041922926903,
      -0.06523045897483826,
      -0.051030442118644714,
      -0.03043907880783081,
      0.014049447141587734,
      -0.02589997835457325,
      -0.04029485583305359,
      0.09032131731510162,
      0.0010720706777647138,
      -0.09839789569377899,
      0.043844468891620636,
      0.026943454518914223,
      0.030006401240825653,
      0.0488634929060936,
      0.03462597355246544,
      -0.0050979419611394405,
      -0.06124461814761162,
      -0.03583361208438873,
      0.018810056149959564,
      -0.007537161465734243,
      -0.07626461982727051,
      0.029274744912981987,
      -0.07512921094894409,
      -0.05408373475074768,
      -0.016841338947415352,
      -0.0404256135225296,
      0.06997323781251907,
      -0.05809283256530762,
      0.0386466421186924,
      -0.02428084798157215,
      -0.023221120238304138,
      0.0914812833070755
    ],
    [
      0.0287802554666996,
      0.03639708831906319,
      -0.022353004664182663,
      0.021283721551299095,
      -0.009487041272222996,
      -0.03540928661823273,
      0.07450521737337112,
      0.05421210452914238,
      0.011532878503203392,
      -0.01983651891350746,
      0.005379925481975079,
      0.03316919878125191,
      -0.011846557259559631,
      -0.021398259326815605,
      0.01053685788065195,
      0.082197405397892,
      0.020467007532715797,
      0.00592122133821249,
      0.07238048315048218,
      0.01320455502718687,
      0.04068730026483536,
      0.008658258244395256,
      -0.004753324668854475,
      0.01980743557214737,
      0.0014795232564210892,
      -0.04339078441262245,
      -0.0003131637640763074,
      -0.06551659107208252,
      -0.05721500515937805,
      0.016871048137545586,
      -0.04978080466389656,
      -0.009507548063993454,
      -0.06461348384618759,
      0.014208177104592323,
      0.003088276833295822,
      -0.018765725195407867,
      -0.02127080038189888,
      -0.04342707246541977,
      0.009646168909966946,
      -0.09062636643648148,
      -0.025585779920220375,
      -0.019841033965349197,
      -0.03867282345890999,
      -0.012076095677912235,
      0.001846970641054213,
      0.009602407924830914,
      0.0411561094224453,
      0.012225393205881119,
      -0.003815075382590294,
      -0.05438254773616791,
      0.040745142847299576,
      0.04570115730166435,
      0.01708373799920082,
      -0.07870447635650635,
      -0.06493314355611801,
      0.0008176591363735497,
      -0.04149555787444115,
      -0.041721805930137634,
      -0.07632274180650711,
      0.03642357885837555,
      0.05798117443919182,
      0.00957423634827137,
      0.01695486530661583,
      0.03754765912890434,
      0.014406096190214157,
      0.027849020436406136,
      0.07581891864538193,
      0.03646675869822502,
      0.04188831150531769,
      0.058038532733917236,
      0.027360284700989723,
      -0.005177696701139212,
      0.01918354630470276,
      0.017577504739165306,
      -0.0038535583298653364,
      0.03504271060228348,
      -0.03382403776049614,
      0.01232631504535675,
      0.0017277002334594727,
      -0.01571369357407093,
      -0.10425892472267151,
      -0.06274241954088211,
      0.07915084809064865,
      -0.0673944354057312,
      -0.06994704902172089,
      -0.012584316544234753,
      0.030525926500558853,
      -0.07174255698919296,
      0.05033467710018158,
      0.031051961705088615,
      -0.02410857379436493,
      0.049006666988134384,
      0.042921002954244614,
      -0.008389138616621494,
      -0.08375608175992966,
      -0.0658780038356781,
      0.1115015372633934,
      0.003912786953151226,
      -0.03765483200550079,
      -0.015455597080290318,
      -0.045161157846450806,
      -0.053844910115003586,
      -0.020891662687063217,
      0.08722620457410812,
      -0.023020073771476746,
      -0.00431446498259902,
      -0.10881879180669785,
      -0.04046594351530075,
      0.08806680887937546,
      0.010584376752376556,
      -0.01649928279221058,
      -0.02769477106630802,
      -0.03407285735011101,
      -0.059705525636672974,
      -0.02200290560722351,
      -0.010420911945402622,
      -0.013423017226159573,
      0.037467505782842636,
      -0.011370455846190453,
      -0.05071405693888664,
      0.07174064964056015,
      -0.024966413155198097,
      -0.024686170741915703,
      -0.04727723076939583,
      -0.058269403874874115,
      -0.012896278873085976,
      -0.024046067148447037,
      0.014152361080050468,
      -0.032214660197496414,
      0.031146753579378128,
      -0.01889866404235363,
      0.09202005714178085,
      -0.06279901415109634,
      0.01425005029886961,
      0.045181818306446075,
      -0.00027173946727998555,
      -0.06925130635499954,
      -0.09000736474990845,
      -0.061256323009729385,
      0.00432624714449048,
      0.013783254660665989,
      0.004797088447958231,
      -0.13436298072338104,
      -0.0407644622027874,
      0.06859173625707626,
      -0.008585527539253235,
      0.09474620223045349,
      -0.0465901643037796,
      -0.07939313352108002,
      0.04230785742402077,
      0.041109733283519745,
      0.019485969096422195,
      -0.022362995892763138,
      -0.025170542299747467,
      0.0628964751958847,
      0.035857077687978745,
      -0.04359115660190582,
      0.02323927730321884,
      0.04432477429509163,
      0.0039969636127352715,
      -0.019141599535942078,
      0.004506755154579878,
      0.058198750019073486,
      0.007272770628333092,
      0.05353227257728577,
      -0.10648251324892044,
      0.06311489641666412,
      -0.010249577462673187,
      0.010656755417585373,
      -0.026994524523615837,
      0.0384954996407032,
      -0.013040282763540745,
      -0.07697068154811859,
      -0.03983862325549126,
      -0.02444424293935299,
      -0.005145463161170483,
      0.014911606907844543,
      -0.020154260098934174,
      -0.003817607881501317,
      -0.03403608128428459,
      0.07682892680168152,
      0.03212041035294533,
      -0.04063308238983154,
      0.026650521904230118,
      0.016570409759879112,
      0.026122262701392174,
      -0.004068714566528797,
      -0.006933159194886684,
      0.01792975142598152,
      0.02786089852452278,
      -0.006055385340005159,
      -0.06368124485015869,
      -0.01774054951965809,
      0.012405539862811565,
      -0.033886488527059555,
      0.022844314575195312,
      -0.005200066138058901,
      0.020697439089417458,
      -0.06548678874969482,
      -0.04762445390224457,
      0.13283811509609222,
      -0.010875229723751545,
      0.08709708601236343,
      -0.005640384741127491,
      -0.005099507514387369,
      -0.03960312530398369,
      0.05253727734088898,
      -0.06733442842960358,
      0.005354221910238266,
      -0.026394231244921684,
      0.04383450746536255,
      -0.008790208026766777,
      0.041636478155851364,
      -0.021168820559978485,
      -0.07394902408123016,
      -0.03196171671152115,
      -0.032932594418525696,
      0.0013971070293337107,
      -0.05799322947859764,
      -0.039270926266908646,
      -0.03601013869047165,
      0.02056553028523922,
      0.04869113489985466,
      0.036860786378383636,
      -0.009653894230723381,
      0.041188523173332214,
      0.008441903628408909,
      0.02899811416864395,
      -0.040580686181783676,
      -0.047413162887096405,
      -0.013974593952298164,
      0.01619245857000351,
      0.04224527254700661,
      0.07278186827898026,
      0.013403824530541897,
      0.03650403767824173,
      -0.02295331284403801,
      -0.03436635434627533,
      -0.016464179381728172,
      -0.024594536051154137,
      0.00894180964678526,
      -0.009249795228242874,
      0.0017296335427090526,
      -0.0021978968288749456,
      -0.09085717052221298,
      -0.009966954588890076,
      -0.051477182656526566,
      -0.07417654991149902,
      -0.0158372949808836,
      -0.016502458602190018,
      0.04633506387472153,
      -0.0032115657813847065,
      0.008749077096581459,
      -0.016938960179686546,
      -0.05236736685037613,
      -0.08012659102678299,
      0.014835584908723831,
      0.012452669441699982,
      -0.03762036934494972,
      -0.07318253815174103,
      -0.009241587482392788,
      0.06664229929447174,
      0.001573558198288083,
      0.022463174536824226,
      0.011221346445381641,
      0.03721296042203903,
      -0.03003835678100586,
      0.04111284017562866,
      0.007936589419841766,
      0.013253560289740562,
      0.08087217807769775,
      0.08369681984186172,
      -0.008591948077082634,
      0.054240480065345764,
      -0.006984060630202293,
      0.01646513119339943,
      0.08724459260702133,
      0.08826340734958649,
      -0.037434305995702744,
      0.00448817852884531,
      0.020228754729032516,
      -0.09012076258659363,
      -0.006524521391838789,
      -0.011401688680052757,
      -0.09620766341686249,
      -0.044812124222517014,
      0.016033418476581573,
      0.05737015977501869,
      0.0303483996540308,
      -0.03691074997186661,
      -0.04736532270908356,
      -0.04318959638476372,
      0.0035615162923932076,
      0.05206051841378212,
      0.002281961962580681,
      -0.014128626324236393,
      -0.04026242718100548,
      0.011411880142986774,
      -0.008703221566975117,
      0.0048301308415830135,
      0.07413557916879654,
      0.008791151456534863,
      0.08131764084100723,
      0.047944314777851105,
      -0.05916755273938179,
      -0.03592924028635025,
      0.04510670527815819,
      -0.025285398587584496,
      0.019620727747678757,
      0.06914839148521423,
      -0.10621760785579681,
      -0.06334857642650604,
      0.045206181704998016,
      0.031357310712337494,
      -0.014711943455040455,
      0.00980861485004425,
      0.10153007507324219,
      -2.6799218176165596e-05,
      0.037677615880966187,
      0.005359686445444822,
      -0.06330553442239761,
      0.06213381513953209,
      -0.043807148933410645,
      0.015870889648795128,
      -0.01749134249985218,
      -0.07526259869337082,
      -0.08596134185791016,
      0.11463885009288788,
      0.09437926858663559,
      0.07831943780183792,
      -0.00253423978574574,
      0.1319003850221634,
      -0.02804909460246563,
      0.0039057170506566763,
      -0.007070504128932953,
      -0.07037679105997086,
      -0.04472721368074417,
      0.027720294892787933,
      -0.010083800181746483,
      0.05212898552417755,
      -0.061118051409721375,
      0.05282192677259445,
      -0.006382386200129986,
      0.00682808505371213,
      -0.002822908805683255,
      -0.04597382992506027,
      0.03145730495452881,
      -0.019010841846466064,
      0.016563985496759415,
      -0.005937079433351755,
      0.033493880182504654,
      -0.0029048968572169542,
      -0.04289933666586876,
      0.04670330509543419,
      0.02113783173263073,
      0.07682549208402634,
      -0.034697726368904114,
      -0.0075482274405658245,
      0.012759980745613575,
      0.030778782442212105,
      -0.023667093366384506,
      -0.051186155527830124,
      0.029035020619630814,
      0.09390776604413986,
      -0.015838706865906715,
      -0.04407840594649315,
      -0.003459210740402341,
      -0.03175804018974304,
      -0.035271257162094116,
      -0.013111308217048645,
      0.04640209674835205,
      0.10088883340358734,
      -0.006655660457909107,
      0.025795485824346542,
      -0.016367821022868156,
      0.016525475308299065,
      -0.002058780286461115,
      -0.009567134082317352,
      -0.05271321162581444,
      0.013679170049726963,
      -0.020891889929771423,
      0.050552353262901306,
      -0.006180581171065569,
      -0.10654034465551376,
      0.034769970923662186,
      -0.0765063688158989,
      0.045395102351903915,
      0.009726140648126602,
      -0.004538391716778278,
      -0.04345359280705452,
      -0.005375548265874386,
      0.056415267288684845,
      0.006919091567397118,
      -0.035500116646289825,
      -0.03191160038113594,
      0.07871708273887634,
      0.012425951659679413,
      -0.041682302951812744,
      -0.07780975848436356,
      0.01648106426000595,
      -0.0003672974999062717,
      0.010353229008615017,
      0.07198000699281693,
      -0.04986870661377907,
      0.023172518238425255,
      -0.016231585294008255,
      0.031217491254210472,
      0.010585300624370575,
      0.0456591360270977,
      0.02646086923778057,
      -0.08511889725923538,
      0.013025213032960892,
      -0.04366357624530792,
      -0.07260488718748093,
      -0.06768283247947693,
      0.02457881160080433,
      -0.021845504641532898,
      -0.03707579895853996,
      -0.07677094638347626,
      -0.039571668952703476,
      -0.0003971232508774847,
      -0.043218716979026794,
      0.005966546945273876,
      0.006318908650428057,
      0.015784969553351402,
      -0.01837904006242752,
      -0.05255179479718208,
      -0.007351467851549387,
      -0.007566371932625771,
      0.01726614125072956,
      0.0497516430914402,
      0.01986890286207199,
      -0.07225686311721802,
      0.005922133103013039,
      -0.0025020106695592403,
      -0.0052394927479326725,
      0.03806125372648239,
      0.13176316022872925,
      -0.005745293572545052,
      -0.024419229477643967,
      0.011488502845168114,
      -0.11428163945674896,
      0.001676914282143116,
      0.06591638922691345,
      -0.05607712268829346,
      0.02435726672410965,
      0.004543520510196686,
      0.016039066016674042,
      0.015404627658426762,
      0.026014069095253944,
      0.04655345901846886,
      -0.026032740250229836,
      -0.05387844890356064,
      -0.0011469869641587138,
      -0.028888585045933723,
      -0.04845239222049713,
      -0.0061184111982584,
      0.028918063268065453,
      0.0907011553645134,
      -0.05050287023186684,
      0.0006257971981540322,
      0.04311405494809151,
      -0.020252158865332603,
      -0.0018879693234339356,
      -0.02185097523033619,
      0.04328693449497223,
      -0.008051984012126923,
      -0.024171248078346252,
      0.017922086641192436,
      -0.046800095587968826,
      -0.024759488180279732,
      0.012865232303738594,
      0.02015622891485691,
      0.010106232948601246,
      0.01997372880578041,
      -0.06943345069885254,
      -0.0671740472316742,
      0.0586550347507,
      0.07841788977384567,
      0.04698784649372101,
      0.04785395413637161,
      -0.03687930852174759,
      -0.00038551687612198293,
      0.005130024626851082,
      -0.0018700994551181793,
      0.05160259082913399,
      -0.04202692210674286,
      -0.0721459686756134,
      -0.025487253442406654,
      0.0002263789065182209,
      -0.018712468445301056,
      0.05730566382408142,
      0.035290103405714035,
      0.018699886277318,
      -0.022517915815114975,
      0.02401917241513729,
      -0.08370456844568253,
      0.06402525305747986,
      0.03702300786972046,
      0.07495955377817154,
      0.011524124071002007,
      0.006613902747631073,
      0.10257723927497864
    ],
    [
      -0.051911018788814545,
      -0.04270661994814873,
      0.017947282642126083,
      0.05422588065266609,
      0.055708758533000946,
      -0.03235410898923874,
      0.044007427990436554,
      -0.02320139668881893,
      -0.050552740693092346,
      0.15838311612606049,
      0.011653436347842216,
      0.01978973113000393,
      -0.03172330930829048,
      0.03816814720630646,
      -0.045938316732645035,
      -0.002716756658628583,
      -0.020705346018075943,
      -0.012834514491260052,
      0.05682589113712311,
      -0.0034014079719781876,
      -0.055386289954185486,
      -0.09209631383419037,
      0.03660506755113602,
      -0.03679376840591431,
      0.07236172258853912,
      0.027839811518788338,
      0.029035653918981552,
      0.006968123838305473,
      -0.04099643975496292,
      -0.0467894971370697,
      -0.12202580273151398,
      0.01647908054292202,
      0.06078684329986572,
      -0.04801282659173012,
      0.04381066933274269,
      0.025307485833764076,
      0.10714935511350632,
      -8.148827328113839e-05,
      0.014407727867364883,
      0.03194355219602585,
      0.00029761073528788984,
      0.005583067890256643,
      -0.048191264271736145,
      0.022023601457476616,
      -0.021418826654553413,
      -0.0271444208920002,
      0.036816708743572235,
      0.025570129975676537,
      0.049304425716400146,
      0.003906856290996075,
      -0.0458182618021965,
      -0.008143190294504166,
      -0.024323144927620888,
      -0.07999266684055328,
      0.0038266414776444435,
      0.035196974873542786,
      -0.08717086911201477,
      -0.0073713562451303005,
      -0.07747143507003784,
      0.0025658251252025366,
      -0.009080333635210991,
      0.012094443663954735,
      0.0008885141578502953,
      -0.007011746987700462,
      0.010254479944705963,
      0.010672913864254951,
      0.0527193583548069,
      0.03016728349030018,
      0.0159230325371027,
      -0.018216188997030258,
      -0.07453808933496475,
      -0.03877558559179306,
      0.05486683547496796,
      0.08525020629167557,
      -0.035286322236061096,
      0.0016340359579771757,
      0.04526418820023537,
      -0.07794282585382462,
      -0.0006294886698015034,
      0.025839941576123238,
      -0.04348163679242134,
      -0.00790357030928135,
      -5.81150125071872e-05,
      -0.028041385114192963,
      -0.023223642259836197,
      -0.013523870147764683,
      -0.036651019006967545,
      -0.06669323146343231,
      0.014943207614123821,
      -0.0036914809606969357,
      -0.08518807590007782,
      0.02208421193063259,
      -0.0014152121730148792,
      -0.023921405896544456,
      0.014707528054714203,
      0.016192739829421043,
      0.05426345020532608,
      -0.01494014821946621,
      0.03626981005072594,
      0.009037410840392113,
      0.08549558371305466,
      0.0023611350916326046,
      -0.019986391067504883,
      -0.02029392309486866,
      0.06761994957923889,
      0.024062279611825943,
      0.12308240681886673,
      0.054297782480716705,
      0.06066831946372986,
      -0.11600161343812943,
      -0.04486995190382004,
      0.014998930506408215,
      -0.05992881581187248,
      0.007021731697022915,
      0.006231059320271015,
      -0.013512196019291878,
      -0.03741701319813728,
      0.027599655091762543,
      0.029172498732805252,
      0.04950528219342232,
      -0.011736070737242699,
      -0.021991966292262077,
      0.10264352709054947,
      -0.03372738137841225,
      -0.0024965463671833277,
      -0.0019866221118718386,
      -0.006167043000459671,
      0.028379952535033226,
      -0.13982103765010834,
      -0.07936381548643112,
      0.015224490314722061,
      0.05734352394938469,
      0.012970028445124626,
      -0.06430896371603012,
      0.06549014151096344,
      -0.0002435350470477715,
      0.005245332606136799,
      -0.03705991059541702,
      -0.01221640594303608,
      0.07520092278718948,
      -0.012001716531813145,
      0.015585818327963352,
      0.07463778555393219,
      -0.06295590847730637,
      0.06380481272935867,
      0.030266471207141876,
      -0.04084925353527069,
      0.051335062831640244,
      -0.012484000064432621,
      -0.04771120101213455,
      0.03288467228412628,
      -0.11801782995462418,
      0.03923264145851135,
      -0.004138094373047352,
      0.005473164841532707,
      -0.04586121067404747,
      -0.046216752380132675,
      -0.001826713909395039,
      -0.07113685458898544,
      0.09158661961555481,
      0.022636283189058304,
      -0.025786852464079857,
      0.021233785897493362,
      -0.044875722378492355,
      -0.016499720513820648,
      0.009872286580502987,
      0.08678330481052399,
      0.030743444338440895,
      -0.004622647538781166,
      0.023331690579652786,
      -0.0007348907529376447,
      -0.07033441960811615,
      -0.005224507302045822,
      0.033182792365550995,
      -0.0559752956032753,
      0.013277655467391014,
      -0.030387289822101593,
      -0.018429236486554146,
      -0.09482105821371078,
      0.07597232609987259,
      0.06689927726984024,
      0.026550227776169777,
      -0.03587464243173599,
      -0.07625792175531387,
      -0.027195507660508156,
      0.06280077248811722,
      -0.12352529913187027,
      -0.06586753576993942,
      0.02090594358742237,
      0.00904323160648346,
      -0.05238085985183716,
      -0.07508541643619537,
      0.01247008889913559,
      0.0033682656940072775,
      -0.03243972361087799,
      -0.020679688081145287,
      0.06404445320367813,
      -0.03045189008116722,
      0.028621088713407516,
      -0.01655297912657261,
      -0.003951734397560358,
      -0.026695815846323967,
      0.05942118912935257,
      0.055998194962739944,
      0.009152497164905071,
      0.00019127089763060212,
      0.04546058923006058,
      0.054439738392829895,
      -0.022717930376529694,
      0.037558261305093765,
      -0.0845857635140419,
      0.03168746456503868,
      0.07899589836597443,
      -0.05755002796649933,
      0.007571904454380274,
      -0.12317817658185959,
      2.1767944417661056e-05,
      0.020157117396593094,
      -0.008362839929759502,
      0.02712518535554409,
      0.09927327930927277,
      0.035916589200496674,
      0.06758120656013489,
      0.06172772869467735,
      -0.08921614289283752,
      -0.028134258463978767,
      -0.0536857508122921,
      -0.0573074035346508,
      0.0485808290541172,
      -0.015955112874507904,
      0.013592423871159554,
      -0.10158081352710724,
      0.07896791398525238,
      0.05538804829120636,
      0.04360322654247284,
      0.0754670798778534,
      -0.008035636506974697,
      -0.011749635450541973,
      -0.03237207233905792,
      -0.011633885093033314,
      0.03567390516400337,
      -0.061505626887083054,
      -0.022964412346482277,
      -0.008913346566259861,
      0.0362287200987339,
      0.011826489120721817,
      -0.036504413932561874,
      0.021706366911530495,
      0.06163264065980911,
      0.02291935123503208,
      0.07994803786277771,
      0.0547003448009491,
      -0.032193973660469055,
      -0.018704423680901527,
      -0.012425502762198448,
      0.08566910773515701,
      -0.044073257595300674,
      0.0282570980489254,
      -0.019809233024716377,
      -0.05177773907780647,
      0.07580304145812988,
      -0.009392302483320236,
      -0.04656224697828293,
      0.011487596668303013,
      0.001979026710614562,
      0.09050989896059036,
      0.07512349635362625,
      -0.00908958725631237,
      0.035285431891679764,
      0.013389087282121181,
      -0.013411443680524826,
      0.012230660766363144,
      -0.003224294865503907,
      0.027600817382335663,
      -0.01680464670062065,
      0.0035416986793279648,
      -0.09679285436868668,
      0.0847059041261673,
      -0.051305435597896576,
      0.05440586060285568,
      -0.022521942853927612,
      -0.01584487594664097,
      0.0013693943619728088,
      0.02981407195329666,
      -0.014668559655547142,
      0.009836671873927116,
      0.06588587909936905,
      0.07281623780727386,
      -0.04351168870925903,
      0.062316618859767914,
      -0.017482956871390343,
      -0.0023562703281641006,
      -0.018517713993787766,
      0.07771952450275421,
      0.07014447450637817,
      0.058135513216257095,
      0.020383615046739578,
      0.012023133225739002,
      -0.06471464037895203,
      -0.02925189770758152,
      0.07677130401134491,
      0.006320692133158445,
      0.02444606088101864,
      0.03329196199774742,
      -0.03483932465314865,
      0.006175549700856209,
      0.03831861540675163,
      -0.05813923105597496,
      -0.06092352792620659,
      0.009549882262945175,
      -0.008446264080703259,
      -0.015526436269283295,
      -0.03995819017291069,
      0.05324197933077812,
      -0.04658742621541023,
      0.04038159176707268,
      0.010812422260642052,
      0.017821332439780235,
      -0.0016436731675639749,
      -0.022190909832715988,
      -0.025943312793970108,
      0.01894664391875267,
      -0.033424004912376404,
      -0.055501554161310196,
      -0.10200673341751099,
      -0.03539380803704262,
      -0.0246073417365551,
      0.05717679485678673,
      0.009692266583442688,
      -0.0036919002886861563,
      -0.023533634841442108,
      -0.021525587886571884,
      0.015354860574007034,
      0.028775520622730255,
      -0.025529930368065834,
      0.05222058668732643,
      -0.009898904711008072,
      -0.035746343433856964,
      0.0034104895312339067,
      -0.02773899957537651,
      0.012763611041009426,
      0.005674557760357857,
      -0.023923274129629135,
      0.00517692044377327,
      0.011629841290414333,
      -0.1011684313416481,
      0.037143852561712265,
      0.012607637792825699,
      0.026064874604344368,
      -0.02867129072546959,
      -0.025270994752645493,
      -0.02474602498114109,
      -0.005781333893537521,
      -0.018728414550423622,
      -0.056299690157175064,
      0.03475479036569595,
      -0.044605810195207596,
      0.03221283480525017,
      -0.048945147544145584,
      -0.050194237381219864,
      0.044842906296253204,
      0.04106173664331436,
      -0.05531466007232666,
      -0.08717969059944153,
      -0.05078347399830818,
      0.08141129463911057,
      -0.027454569935798645,
      -0.02199382521212101,
      0.006446955259889364,
      -0.011322753503918648,
      0.050727661699056625,
      -0.03859129175543785,
      0.054302457720041275,
      -0.02411550097167492,
      -0.052084244787693024,
      0.0032848273403942585,
      0.04790731519460678,
      0.01683635450899601,
      -0.05411487817764282,
      -0.03508005291223526,
      -0.07122919708490372,
      -0.007699078414589167,
      0.011124060489237309,
      -0.029018232598900795,
      -0.0015944201732054353,
      -0.08785004168748856,
      0.03534574434161186,
      -0.13321484625339508,
      -0.003664920572191477,
      0.008342649787664413,
      0.04842078685760498,
      0.06170341372489929,
      0.05834546685218811,
      0.00045812406460754573,
      0.04373273625969887,
      -0.009726851247251034,
      0.004039621911942959,
      -0.07582880556583405,
      -0.020975572988390923,
      0.03242219612002373,
      -0.0011238469742238522,
      -0.061913277953863144,
      0.030656540766358376,
      0.005326413083821535,
      0.017026882618665695,
      0.03689400479197502,
      0.004582986701279879,
      0.0011480530956760049,
      0.07887639105319977,
      -0.05450122058391571,
      -0.005822075065225363,
      0.1092790886759758,
      0.052375540137290955,
      0.03929385170340538,
      0.0246934462338686,
      -0.017706306651234627,
      -0.07604943960905075,
      -0.07455041259527206,
      -0.03730941191315651,
      -0.004660752601921558,
      -0.023015698418021202,
      -0.03246495872735977,
      -0.030145809054374695,
      0.04258718341588974,
      -0.08529677242040634,
      -0.06799333542585373,
      0.04499591886997223,
      0.024094363674521446,
      0.049290575087070465,
      -0.019754739478230476,
      0.004191085696220398,
      -0.010923807509243488,
      0.046741779893636703,
      -0.06256355345249176,
      0.037306711077690125,
      0.05473095178604126,
      0.06159064546227455,
      0.06577168405056,
      -0.0568011999130249,
      0.07465462386608124,
      0.0352790430188179,
      -0.009885992854833603,
      0.012488024309277534,
      -0.034586481750011444,
      0.040888711810112,
      -0.04069524258375168,
      -0.006189173087477684,
      0.08245715498924255,
      -0.021413477137684822,
      -0.0183491762727499,
      0.026674464344978333,
      -0.015083374455571175,
      -0.015515263192355633,
      0.029429953545331955,
      0.05125606432557106,
      -0.031808119267225266,
      -0.027356455102562904,
      0.09610157459974289,
      -0.0070100463926792145,
      -0.03930233418941498,
      0.14275597035884857,
      0.011817521415650845,
      0.052046775817871094,
      0.050534918904304504,
      -0.05403246730566025,
      -0.029130367562174797,
      0.013963949866592884,
      0.005221782252192497,
      0.027384301647543907,
      -0.013300779275596142,
      0.04999835416674614,
      -0.013119586743414402,
      -0.023205753415822983,
      0.007028143387287855,
      0.05815368890762329,
      0.07905606180429459,
      -0.016703059896826744,
      -0.005449857097119093,
      -0.011133694089949131,
      -0.044625211507081985,
      0.04611898586153984,
      0.06576573848724365,
      -0.050687938928604126,
      -0.03219088912010193,
      0.03365463763475418,
      -0.07236955314874649,
      -0.043316155672073364,
      0.005982168018817902,
      -0.05869859829545021,
      -0.05358486250042915,
      0.01482426654547453,
      -0.06300652772188187,
      0.026221929118037224,
      -0.0035339759197086096,
      -0.02434409409761429,
      -0.05929483100771904,
      -0.0380106158554554,
      -0.053325049579143524,
      -0.025660408660769463,
      0.02566206268966198,
      0.07672660797834396,
      -0.09093768894672394,
      -0.04243966192007065
    ],
    [
      -0.011118276044726372,
      -0.0904793068766594,
      0.008000661619007587,
      -0.01774652674794197,
      0.05524314194917679,
      0.045789290219545364,
      -0.0610961876809597,
      -0.011547974310815334,
      -0.009892852045595646,
      0.0842428058385849,
      0.06605900824069977,
      -0.0008986932807601988,
      -0.005812107119709253,
      0.05714025720953941,
      -0.004760518204420805,
      0.016192618757486343,
      0.014916539192199707,
      -0.059478189796209335,
      0.028810083866119385,
      -0.04602979123592377,
      -0.007509856950491667,
      0.06620041280984879,
      0.03588991239666939,
      0.08320379257202148,
      -0.020290056243538857,
      -0.0668041929602623,
      0.012440348975360394,
      -0.07445363700389862,
      0.03032124787569046,
      0.031100070104002953,
      0.05669710785150528,
      -0.004670270718634129,
      -0.004113118629902601,
      0.06058139353990555,
      -0.021099017933011055,
      0.0056786686182022095,
      -0.06303311884403229,
      -0.007476752158254385,
      -0.008495631627738476,
      -0.0544302873313427,
      0.027040425688028336,
      0.029686179012060165,
      -0.020623814314603806,
      0.10834416002035141,
      0.00155452243052423,
      0.012414591386914253,
      -0.09719941020011902,
      -0.03161143511533737,
      -0.0005413478938862681,
      0.0057828715071082115,
      0.08670172840356827,
      -0.006645826157182455,
      0.009965883567929268,
      -0.005626533180475235,
      -0.006279262714087963,
      -0.0020805762615054846,
      -0.05997591093182564,
      0.008798325434327126,
      -0.02171129360795021,
      -0.03358316421508789,
      -0.06407731771469116,
      -0.020459380000829697,
      0.02512212283909321,
      0.05990350991487503,
      -0.05615919083356857,
      0.030788253992795944,
      0.0015021752333268523,
      0.003864469239488244,
      0.003991888836026192,
      0.01516036782413721,
      0.06853602826595306,
      0.0062581617385149,
      0.03143438324332237,
      -0.012576183304190636,
      0.02142532542347908,
      0.06494048237800598,
      -0.021534519270062447,
      -0.01304390374571085,
      -0.0250247772783041,
      0.008060561493039131,
      -0.019727393984794617,
      0.04434148967266083,
      -0.028140757232904434,
      0.041052334010601044,
      -0.03816847875714302,
      0.016870547086000443,
      0.02826809138059616,
      0.09271568059921265,
      -0.010496429167687893,
      -0.02126741223037243,
      0.07344310730695724,
      0.032717764377593994,
      0.0006173167494125664,
      0.009690552949905396,
      -0.06561775505542755,
      -0.03272794187068939,
      -0.008954841643571854,
      -0.030316326767206192,
      -0.061274025589227676,
      -0.06819333136081696,
      -0.04013385996222496,
      -0.05722741037607193,
      -0.01566305197775364,
      0.04624743387103081,
      -0.025727368891239166,
      -0.004124507773667574,
      -0.005907759070396423,
      0.022599123418331146,
      -0.01581946574151516,
      0.05563774332404137,
      0.054555315524339676,
      -0.04372492805123329,
      -0.028322556987404823,
      -0.021629106253385544,
      0.028209540992975235,
      0.01517674420028925,
      0.006000078283250332,
      -0.0319402813911438,
      0.042918551713228226,
      -0.04091056063771248,
      0.057961512356996536,
      -0.0010589092271402478,
      -0.004429854452610016,
      0.022511577233672142,
      -0.00802901666611433,
      0.05714016407728195,
      0.044024623930454254,
      0.07426708191633224,
      -0.045052770525217056,
      -0.0329129695892334,
      -0.0815867930650711,
      -0.05422035977244377,
      -0.0065680695697665215,
      -0.0033363900147378445,
      0.0739206001162529,
      0.020021889358758926,
      0.064364954829216,
      0.013754977844655514,
      0.001742584048770368,
      -0.05188748240470886,
      0.01721961796283722,
      0.008280133828520775,
      0.012485891580581665,
      0.03794318810105324,
      0.007886969484388828,
      -0.06342148035764694,
      -0.019830889999866486,
      -0.005547161214053631,
      -0.009069501422345638,
      -0.04503868147730827,
      -0.016522273421287537,
      -0.019018705934286118,
      0.008428450673818588,
      -0.01577620580792427,
      -0.03358956426382065,
      0.03246179223060608,
      0.017730697989463806,
      -0.02540569007396698,
      0.07783181965351105,
      -0.035344198346138,
      0.013640465214848518,
      -0.023481201380491257,
      0.08429624885320663,
      -0.01435038261115551,
      -0.026252642273902893,
      -0.06899046897888184,
      0.011734594590961933,
      0.0009593467693775892,
      -0.0067333863116800785,
      -0.034605495631694794,
      0.020241498947143555,
      -0.011591151356697083,
      -0.04340602457523346,
      -0.05196531116962433,
      -0.04881725460290909,
      -0.030136048793792725,
      0.020694870501756668,
      0.039838481694459915,
      0.060603391379117966,
      0.07332417368888855,
      -0.003610098036006093,
      0.027315778657794,
      -0.03440023586153984,
      -0.0015723429387435317,
      -0.008524230681359768,
      0.008584665134549141,
      0.028188878670334816,
      0.07340257614850998,
      -0.02186373621225357,
      0.08915013819932938,
      0.034882862120866776,
      -0.04834253340959549,
      -0.015465032309293747,
      0.07141198962926865,
      0.01541217789053917,
      0.014840356074273586,
      0.13368549942970276,
      -0.12998996675014496,
      -0.011310474015772343,
      -0.06871403008699417,
      -0.058775920420885086,
      0.07235082983970642,
      0.01683696173131466,
      -0.01318972185254097,
      -0.10840627551078796,
      0.007175970356911421,
      0.0397966168820858,
      -0.023931680247187614,
      -0.004757470451295376,
      0.03566451370716095,
      -0.021121863275766373,
      -0.05835430324077606,
      0.04644254967570305,
      0.028025027364492416,
      0.022278476506471634,
      -0.03796522319316864,
      0.014751902781426907,
      0.09309382736682892,
      -0.03048144467175007,
      -0.04169769957661629,
      -0.00386243243701756,
      -0.05106265842914581,
      0.024525566026568413,
      -0.05715043470263481,
      0.052034638822078705,
      -0.034517206251621246,
      -0.07443917542695999,
      0.0628606304526329,
      0.012930803932249546,
      -0.002595949685201049,
      0.036433346569538116,
      0.014793363399803638,
      0.0557561032474041,
      0.1250227838754654,
      0.012177716009318829,
      -0.01691100187599659,
      0.0999671071767807,
      -0.009313191287219524,
      0.04390828311443329,
      -0.02434532344341278,
      0.04428590461611748,
      -0.08473160862922668,
      -0.032999396324157715,
      0.0426863357424736,
      0.03487246483564377,
      -0.07302354276180267,
      0.03908045217394829,
      0.010666943155229092,
      0.003258148208260536,
      -0.03318827226758003,
      -0.021554166451096535,
      -0.03226911276578903,
      -0.06766203045845032,
      0.022585388273000717,
      -0.028806356713175774,
      -0.041794635355472565,
      0.017431139945983887,
      -0.008661272004246712,
      0.0399676188826561,
      -0.04699462652206421,
      0.01872541382908821,
      0.07389341294765472,
      0.00520784966647625,
      -0.0023806991521269083,
      0.048792172223329544,
      -0.008069533854722977,
      0.004520540125668049,
      0.028565537184476852,
      -0.02388671785593033,
      0.03413255512714386,
      -0.025072768330574036,
      0.040219876915216446,
      0.006259769666939974,
      -0.061089109629392624,
      0.02946525625884533,
      0.044372137635946274,
      -0.05619608610868454,
      0.03216824308037758,
      0.015965327620506287,
      -0.013604317791759968,
      0.05865458771586418,
      -0.06712620705366135,
      0.02375311218202114,
      0.036310456693172455,
      0.09006314724683762,
      -0.08287070691585541,
      -0.026189949363470078,
      0.029232585802674294,
      0.014805793762207031,
      0.011155151762068272,
      0.03600785508751869,
      -0.04469342157244682,
      0.02782561630010605,
      -0.023208364844322205,
      0.007500852458178997,
      -0.051258888095617294,
      0.020657049492001534,
      0.004249450284987688,
      0.016277413815259933,
      0.02343454211950302,
      -0.010402621701359749,
      -0.06599199771881104,
      -0.08115983754396439,
      -0.029293179512023926,
      -0.0782257467508316,
      0.025397568941116333,
      0.08482125401496887,
      0.0067922193557024,
      0.027773045003414154,
      0.07311330735683441,
      -0.024260561913251877,
      0.08204520493745804,
      -0.015291690826416016,
      0.05910516530275345,
      0.039437614381313324,
      -0.01935695670545101,
      0.04723254591226578,
      0.006035558879375458,
      0.02787771448493004,
      -0.015027462504804134,
      0.008857890963554382,
      0.008258826099336147,
      -0.08277030289173126,
      0.003341617761179805,
      0.050300948321819305,
      -0.012743528932332993,
      -0.004072871059179306,
      -0.03579394146800041,
      0.017526954412460327,
      -0.09126107394695282,
      0.057793281972408295,
      0.007945786230266094,
      0.03291067108511925,
      0.06958065181970596,
      0.03146469220519066,
      -0.00478392606601119,
      0.03550446033477783,
      -0.01462202612310648,
      -0.022654209285974503,
      0.04414939880371094,
      0.0428384505212307,
      0.014459514059126377,
      0.046228259801864624,
      0.055213265120983124,
      0.022675244137644768,
      0.003479096805676818,
      0.047951169312000275,
      0.005318003240972757,
      -0.048030976206064224,
      0.018860014155507088,
      -0.10405255109071732,
      0.06661707162857056,
      -0.028705604374408722,
      0.029104119166731834,
      -0.03625427186489105,
      0.08750015497207642,
      0.05433419719338417,
      0.07962632179260254,
      0.0447242297232151,
      0.03589756786823273,
      0.042354125529527664,
      0.04509037360548973,
      -0.0455009900033474,
      -0.014298428781330585,
      -0.023368975147604942,
      0.10245967656373978,
      -0.006103083491325378,
      -0.049275659024715424,
      -0.012659698724746704,
      0.02769581414759159,
      -0.03906680643558502,
      0.048548374325037,
      0.07449373602867126,
      -0.07890719920396805,
      0.035447344183921814,
      0.07173763960599899,
      0.029123783111572266,
      0.05949217081069946,
      -0.022458823397755623,
      0.02345527708530426,
      -0.06293385475873947,
      -0.08634377270936966,
      -0.04599546641111374,
      -0.0034817028790712357,
      -0.03963056206703186,
      0.031173016875982285,
      0.061279911547899246,
      0.03669601306319237,
      0.10238026082515717,
      0.02077547088265419,
      0.01713380217552185,
      0.025343114510178566,
      0.02096296101808548,
      -0.08840834349393845,
      -0.00042338608182035387,
      5.553904702537693e-05,
      0.07058236747980118,
      -0.054306767880916595,
      -0.028938625007867813,
      -0.005237872246652842,
      -0.030062802135944366,
      0.0002462274569552392,
      -0.10627630352973938,
      0.005085649900138378,
      -0.034526463598012924,
      0.0036662195343524218,
      0.008357931859791279,
      -0.022138675674796104,
      0.09049471467733383,
      0.0028199313674122095,
      0.016240965574979782,
      -0.02859317511320114,
      0.10052727162837982,
      -0.011919303797185421,
      -0.020907921716570854,
      -0.015088899992406368,
      0.0032318776939064264,
      -0.010599717497825623,
      -0.013585506938397884,
      -0.03359173238277435,
      0.0380670465528965,
      -0.026853593066334724,
      -0.08519354462623596,
      0.04261551797389984,
      0.012884121388196945,
      -0.0050778924487531185,
      0.021506916731595993,
      -0.0828244611620903,
      -0.006263145711272955,
      0.035641495138406754,
      -0.029047200456261635,
      -0.04234013706445694,
      0.007725083734840155,
      -0.010863127186894417,
      -0.05991112068295479,
      0.0956142395734787,
      -0.008074122481048107,
      0.040966492146253586,
      -0.00021934173128101975,
      0.041218969970941544,
      0.03006158024072647,
      -0.09301825612783432,
      -0.03332752361893654,
      0.038956426084041595,
      0.023609990254044533,
      -0.1186312660574913,
      0.012322977185249329,
      -0.014305553399026394,
      -0.04282765835523605,
      -0.10776175558567047,
      0.000291642703814432,
      -0.006023047491908073,
      0.0006838435656391084,
      -0.032986607402563095,
      -0.035231176763772964,
      0.04865974187850952,
      0.0010733648668974638,
      0.03607083112001419,
      -0.017623240128159523,
      -0.03890339285135269,
      -0.0762493833899498,
      -0.011226868256926537,
      0.05583352968096733,
      -0.05496522784233093,
      -0.044218726456165314,
      -0.009402173571288586,
      -0.10367787629365921,
      0.014228642918169498,
      0.04354659467935562,
      -0.021895013749599457,
      -0.019145091995596886,
      -0.012088889256119728,
      0.021296285092830658,
      -0.028949525207281113,
      -0.05567608028650284,
      -0.008696588687598705,
      -0.05017412081360817,
      0.021183591336011887,
      -0.03163108974695206,
      0.01999850757420063,
      0.05531878024339676,
      0.053255848586559296,
      0.0027521655429154634,
      -0.03440428152680397,
      -0.03214660659432411,
      0.042839739471673965,
      0.048006571829319,
      -0.03212813660502434,
      -0.05626866593956947,
      -0.020253492519259453,
      -0.011555948294699192,
      -0.0030274761375039816,
      -0.03774541988968849,
      0.060302477329969406,
      -0.01753905974328518,
      -0.021062111482024193,
      0.007237536367028952,
      -0.003106048796325922,
      0.005643284879624844,
      -0.047285281121730804,
      0.03056148812174797,
      -0.05099957063794136,
      0.03439319133758545
    ],
    [
      -0.054522592574357986,
      -0.036332808434963226,
      -0.029823139309883118,
      0.08148245513439178,
      0.013819003477692604,
      0.06022151559591293,
      -0.018034836277365685,
      -0.03694113343954086,
      0.022207671776413918,
      -0.04733842611312866,
      0.09454165399074554,
      -0.048460736870765686,
      -0.049017101526260376,
      -5.913938002777286e-05,
      -0.025594985112547874,
      0.0028225730638951063,
      0.016995327547192574,
      -0.00247769383713603,
      0.02631697990000248,
      0.009238439612090588,
      -0.08082464337348938,
      -0.038759879767894745,
      0.05124937742948532,
      0.039649661630392075,
      -0.058453552424907684,
      -0.06970438361167908,
      -0.07407993078231812,
      -0.05074949935078621,
      -0.016872428357601166,
      0.005757345352321863,
      0.09943215548992157,
      -0.03921452537178993,
      -0.007176214829087257,
      -0.017019324004650116,
      -0.02049988880753517,
      -0.04274763539433479,
      0.007300177589058876,
      0.027694962918758392,
      -0.06998268514871597,
      -0.049400944262742996,
      -0.0306532122194767,
      0.061125874519348145,
      -0.03429029881954193,
      0.04791541025042534,
      -0.04132409766316414,
      -0.03934323787689209,
      0.023143872618675232,
      -0.005922314245253801,
      0.010674204677343369,
      -0.0110540222376585,
      0.03707359731197357,
      0.013318756595253944,
      -0.015846410766243935,
      -0.011530607007443905,
      0.01304470095783472,
      0.10346565395593643,
      0.029071537777781487,
      -0.0033080410212278366,
      0.012005368247628212,
      -0.024686221033334732,
      -0.01850173994898796,
      0.06485190242528915,
      -0.013190243393182755,
      0.08343027532100677,
      -0.04177049174904823,
      -0.053234223276376724,
      -0.06287358701229095,
      -0.013026491738855839,
      -0.053489234298467636,
      0.06405393034219742,
      0.02530573308467865,
      -0.026484521105885506,
      -0.01502068992704153,
      -0.008829964324831963,
      -0.024427248165011406,
      0.041696947067976,
      0.039943572133779526,
      0.028966091573238373,
      -0.05565471947193146,
      0.03168217092752457,
      0.01837126538157463,
      -0.009384039789438248,
      -0.051650188863277435,
      0.010146860964596272,
      0.04864254593849182,
      -0.035582542419433594,
      -0.011203795671463013,
      -0.02442128211259842,
      -0.011837905272841454,
      0.10964150726795197,
      0.017558462917804718,
      -0.0535404197871685,
      0.006140950135886669,
      0.0029651764780282974,
      -0.10003627091646194,
      -0.05377933010458946,
      -0.01920854113996029,
      0.0027371528558433056,
      -0.03339748457074165,
      0.06465154886245728,
      0.01129232533276081,
      0.04443088173866272,
      -0.05153549090027809,
      -0.009878762066364288,
      0.034665629267692566,
      0.05589824914932251,
      -0.038401637226343155,
      0.0598730742931366,
      0.006336224731057882,
      0.14515624940395355,
      -0.03960171341896057,
      0.02533114142715931,
      0.06536561250686646,
      -0.03018762171268463,
      0.00707198167219758,
      -0.06387101113796234,
      0.025535058230161667,
      -0.03136824816465378,
      0.02119278535246849,
      0.09081020951271057,
      -0.0035985703580081463,
      0.04448027163743973,
      -0.01283466350287199,
      -0.06976788491010666,
      -0.04903683438897133,
      -0.0865236148238182,
      -0.005095631815493107,
      0.05316939577460289,
      -0.08061733841896057,
      -0.027491923421621323,
      0.024403266608715057,
      -0.015308042988181114,
      0.03889729455113411,
      0.08937586843967438,
      0.03824017569422722,
      0.10334747284650803,
      0.022308258339762688,
      0.013364163227379322,
      -0.07401899248361588,
      -0.016669027507305145,
      -0.02353740483522415,
      0.01197853684425354,
      -0.09302307665348053,
      0.08997567743062973,
      0.0035444959066808224,
      0.03859313204884529,
      0.007702027913182974,
      0.03674726560711861,
      0.008092916570603848,
      0.034117668867111206,
      -0.05744415894150734,
      0.028757045045495033,
      -0.043490249663591385,
      0.025290226563811302,
      0.011956309899687767,
      0.01362092699855566,
      0.04057132825255394,
      -0.0509134940803051,
      0.042518455535173416,
      -0.06029471382498741,
      0.02604587748646736,
      0.00901296827942133,
      -0.035129860043525696,
      0.009789682924747467,
      0.11021748185157776,
      0.08564182370901108,
      -0.0657537505030632,
      0.06305158138275146,
      0.010271561332046986,
      0.018076928332448006,
      0.015075016766786575,
      0.03101024031639099,
      -0.03081921860575676,
      0.07242055982351303,
      0.043396446853876114,
      0.0018021734431385994,
      0.007185162510722876,
      0.032083120197057724,
      -0.06723867356777191,
      0.013540023937821388,
      0.031544990837574005,
      0.004897009581327438,
      -0.006601597648113966,
      -0.047249022871255875,
      0.021563686430454254,
      -0.008313165977597237,
      0.02361929416656494,
      -0.01626433990895748,
      -0.051639702171087265,
      0.03263641893863678,
      0.043873075395822525,
      0.03001018427312374,
      -0.06550902873277664,
      0.02964215911924839,
      -0.01391421165317297,
      0.04972768574953079,
      0.08852905035018921,
      0.007880998775362968,
      0.02225145883858204,
      -0.004375991877168417,
      -0.07909195125102997,
      0.0353257954120636,
      0.03867768868803978,
      0.051234979182481766,
      -0.05987994000315666,
      0.06390377879142761,
      0.05675102025270462,
      -0.04468507319688797,
      -0.029590869322419167,
      -0.054444074630737305,
      -0.03539410978555679,
      -0.05962677672505379,
      0.01764184981584549,
      -0.03484662249684334,
      0.0027215292211622,
      0.004215807653963566,
      0.02731015346944332,
      0.05455397814512253,
      -0.04433836415410042,
      -0.04164310172200203,
      0.027840586379170418,
      0.01559612900018692,
      0.025406960397958755,
      0.015191773883998394,
      0.04671664163470268,
      -0.0913219302892685,
      0.09627986699342728,
      0.016142236068844795,
      -0.050062138587236404,
      -0.018530715256929398,
      -0.036804717034101486,
      -0.07844262570142746,
      0.009104369208216667,
      0.02112409472465515,
      -0.061287470161914825,
      -0.03224228695034981,
      -0.010458109900355339,
      -0.03333465754985809,
      0.06013040244579315,
      -0.0686793103814125,
      -0.06663636863231659,
      0.03426793962717056,
      -0.04800884425640106,
      -0.03186634182929993,
      -0.07637033611536026,
      0.04044807702302933,
      0.07201400399208069,
      0.13714872300624847,
      0.005073855631053448,
      -0.025507932528853416,
      -0.013460486195981503,
      0.10967235267162323,
      -0.008666117675602436,
      -0.021327335387468338,
      -0.05321914330124855,
      -0.01585976965725422,
      0.09204722195863724,
      0.03740286827087402,
      -0.02705778181552887,
      -0.00435631163418293,
      -0.007753149606287479,
      0.027296487241983414,
      -0.015894746407866478,
      -0.00982986856251955,
      0.05167584866285324,
      0.06235896795988083,
      0.05957011878490448,
      0.03174101188778877,
      -0.057989366352558136,
      0.010455681942403316,
      0.03037804551422596,
      0.06939907371997833,
      -0.04250682145357132,
      -0.0014069806784391403,
      0.08349805325269699,
      0.010104785673320293,
      0.06868834793567657,
      0.135844424366951,
      0.06046876311302185,
      -0.049299903213977814,
      -0.03958456963300705,
      0.05065276473760605,
      0.007366006262600422,
      0.049363650381565094,
      -0.012898437678813934,
      -0.024124981835484505,
      -0.05007036402821541,
      -0.024115340784192085,
      0.07610490918159485,
      -0.00807455088943243,
      -0.0015968297375366092,
      0.03824174776673317,
      -0.007334352470934391,
      0.027557259425520897,
      0.01740165986120701,
      0.086443692445755,
      0.01236801128834486,
      0.03246574103832245,
      -0.030535854399204254,
      0.029264871031045914,
      -0.04035330191254616,
      -0.056325461715459824,
      0.029810931533575058,
      -0.056586578488349915,
      -0.019034797325730324,
      0.03536730259656906,
      -0.09754922986030579,
      -0.07661544531583786,
      -0.034542448818683624,
      -0.0075855497270822525,
      0.06843557953834534,
      0.07637937366962433,
      0.02632235921919346,
      0.0010459686163812876,
      0.05894988030195236,
      0.0211727824062109,
      -0.02611386962234974,
      -0.04242107272148132,
      -0.034317485988140106,
      -0.09778960794210434,
      -0.06538396328687668,
      0.0014107717433944345,
      -0.05283230543136597,
      -0.08231376111507416,
      0.04258250445127487,
      0.052117109298706055,
      -0.04069561883807182,
      -0.04454198479652405,
      0.04902740940451622,
      -0.06545046716928482,
      -0.011694913730025291,
      -0.005564732011407614,
      0.05654830485582352,
      -0.047234416007995605,
      -0.005326817277818918,
      0.07270247489213943,
      -0.02423596754670143,
      -0.07265672087669373,
      0.0568619929254055,
      0.000304660847177729,
      -0.08133197575807571,
      -0.0635235384106636,
      -0.03960833698511124,
      -0.015635671094059944,
      -0.016275260597467422,
      0.010337519459426403,
      0.02409118413925171,
      -0.012826933525502682,
      -0.016302477568387985,
      -0.023331303149461746,
      -0.030413508415222168,
      0.059551555663347244,
      0.04536426439881325,
      -0.005213551688939333,
      0.04632173106074333,
      0.09014479070901871,
      0.06831755489110947,
      0.010743220336735249,
      0.0036024374421685934,
      0.003311034757643938,
      -0.018999483436346054,
      -0.024786850437521935,
      0.024866444990038872,
      0.008422830142080784,
      -0.03150133788585663,
      -0.0732053741812706,
      -0.030462488532066345,
      0.048555612564086914,
      -0.06511817872524261,
      -0.031846463680267334,
      0.018450310453772545,
      0.018205301836133003,
      0.02730838768184185,
      -0.0621337965130806,
      -0.012996813282370567,
      0.03497445955872536,
      0.020908301696181297,
      0.010011584497988224,
      -0.023384442552924156,
      -0.027901537716388702,
      0.004223032388836145,
      -0.04815327003598213,
      -0.023094309493899345,
      -0.03749096766114235,
      -0.06860678642988205,
      0.028456076979637146,
      0.024218996986746788,
      -0.001752733951434493,
      0.003027736209332943,
      -0.029276864603161812,
      -0.11836894601583481,
      0.08306317031383514,
      0.003137263935059309,
      0.003666689619421959,
      0.07047026604413986,
      -0.010806281119585037,
      0.04935836419463158,
      -0.07749507576227188,
      -0.054901983588933945,
      -0.010781088843941689,
      -0.07451238483190536,
      -0.006181307602673769,
      -0.027749165892601013,
      -0.0028158738277852535,
      0.04547939822077751,
      -0.005186864174902439,
      0.025974396616220474,
      0.011055351234972477,
      0.03086632303893566,
      -0.09087271243333817,
      -0.047683872282505035,
      -0.017694901674985886,
      0.024491507560014725,
      0.00937496405094862,
      0.05756592005491257,
      -0.04472699761390686,
      0.033502791076898575,
      -0.0487760454416275,
      -0.017973406240344048,
      -0.01114275585860014,
      0.05667882040143013,
      0.018692193552851677,
      0.00039336306508630514,
      0.008884342387318611,
      0.010565177537500858,
      -0.05532069131731987,
      -0.038984429091215134,
      0.03441533073782921,
      -0.0020326769445091486,
      -0.035029005259275436,
      -0.02484211139380932,
      0.02768605761229992,
      -0.0586705282330513,
      -0.010322547517716885,
      0.02947114408016205,
      0.056178316473960876,
      0.01755818910896778,
      -0.06251494586467743,
      0.029584502801299095,
      -0.027644602581858635,
      0.03009258210659027,
      -0.09408676624298096,
      -0.001675529987551272,
      -0.05668645352125168,
      0.018797017633914948,
      0.004268841817975044,
      0.05753503739833832,
      -0.031475190073251724,
      0.0015333074843510985,
      0.02825385145843029,
      0.02322698011994362,
      -0.08312056213617325,
      -0.053254228085279465,
      -0.007963637821376324,
      0.021262507885694504,
      0.03389494866132736,
      -0.007621420081704855,
      -0.004387401510030031,
      0.0006280594971030951,
      0.02912050299346447,
      -0.015766536816954613,
      -0.052335821092128754,
      0.025017986074090004,
      -0.005631642881780863,
      0.026869801804423332,
      0.1098865196108818,
      -0.03475629910826683,
      0.0696815699338913,
      0.0731033980846405,
      0.0017094786744564772,
      0.0385487899184227,
      -0.006194659974426031,
      -0.003003097837790847,
      0.03141427040100098,
      0.03705529123544693,
      0.03129860758781433,
      0.025656847283244133,
      0.04298277571797371,
      0.03932659327983856,
      0.03524288907647133,
      -0.07113280147314072,
      0.09951145946979523,
      0.019544173032045364,
      0.04695452004671097,
      0.02109617367386818,
      0.0178836639970541,
      0.027806274592876434,
      -0.06736290454864502,
      0.032739244401454926,
      -0.04073024168610573,
      0.08031212538480759,
      -0.015136553905904293,
      -0.0805535688996315,
      -0.04025699943304062,
      -0.1304437518119812,
      -0.03764916956424713,
      -0.055410418659448624,
      -0.020812416449189186,
      -0.0041061947122216225,
      -0.07829167693853378,
      0.024024996906518936,
      -0.005356663838028908,
      -0.11141337454319
    ],
    [
      0.017791273072361946,
      0.02132515050470829,
      0.022784514352679253,
      0.018380187451839447,
      0.009987957775592804,
      -0.004936820827424526,
      -0.03114563785493374,
      0.00787346251308918,
      0.04819115251302719,
      0.005398370325565338,
      0.005105511285364628,
      -0.03709959611296654,
      0.08235680311918259,
      -0.05768890306353569,
      0.009038534015417099,
      0.03977061063051224,
      0.01025866623967886,
      -0.06348520517349243,
      -0.04773997887969017,
      -0.048518840223550797,
      -0.03907117247581482,
      0.11941001564264297,
      -0.027540532872080803,
      0.09639734774827957,
      -0.04155541956424713,
      -0.025648552924394608,
      -0.0044853780418634415,
      0.025209298357367516,
      0.009639685042202473,
      0.03586133196949959,
      -0.030247578397393227,
      0.003606661455705762,
      -0.038542650640010834,
      -0.02251933515071869,
      -0.01928180828690529,
      0.0862441137433052,
      -0.025149062275886536,
      0.04051677882671356,
      -0.010165414772927761,
      0.005209493450820446,
      0.07539015263319016,
      0.05857134610414505,
      -0.07503089308738708,
      -0.02781783789396286,
      -0.025924937799572945,
      0.06165274605154991,
      -0.05796794593334198,
      -0.04591831564903259,
      0.022415248677134514,
      -0.082161083817482,
      0.06621649861335754,
      0.012187065556645393,
      -0.011447071097791195,
      -0.030637700110673904,
      0.040760450065135956,
      -0.13348950445652008,
      -0.005694999359548092,
      -0.0039016371592879295,
      -0.039887718856334686,
      0.03038441203534603,
      -0.07040224969387054,
      -0.06371772289276123,
      -0.10439419746398926,
      0.04678209871053696,
      -0.017724670469760895,
      0.04950398951768875,
      -0.029743434861302376,
      -0.008603911846876144,
      0.042408350855112076,
      0.02675297111272812,
      -0.030841756612062454,
      0.0828632265329361,
      0.004999458324164152,
      -0.0034879243467003107,
      0.009234418161213398,
      -0.005041889846324921,
      -0.09330343455076218,
      -0.01947525143623352,
      -0.02436666190624237,
      0.03555597364902496,
      0.0267456267029047,
      0.01448770146816969,
      0.03105379454791546,
      -0.010929771699011326,
      -0.008230777457356453,
      0.01586386188864708,
      0.019531507045030594,
      -0.01659579575061798,
      0.08403781056404114,
      0.057542141526937485,
      -0.025672132149338722,
      0.028127459809184074,
      -0.05534696951508522,
      -0.02912212535738945,
      -0.006061283405870199,
      0.019895033910870552,
      0.03601376339793205,
      0.03458738699555397,
      -0.034991197288036346,
      -0.024346906691789627,
      -0.04769087955355644,
      -0.002267367672175169,
      -0.08414926379919052,
      -0.08298788964748383,
      0.06849642843008041,
      0.011479964479804039,
      -0.0030737686902284622,
      -0.025752956047654152,
      0.0008777546463534236,
      -0.02683210000395775,
      -0.046045053750276566,
      -0.06288820505142212,
      -0.013169752433896065,
      0.013933215290307999,
      0.04044831544160843,
      -0.0366777703166008,
      0.014201497659087181,
      0.0010648153256624937,
      -0.03799792379140854,
      -0.0065927691757678986,
      -0.03059384785592556,
      -0.054904788732528687,
      -0.04587535560131073,
      0.09818816930055618,
      -0.0433647520840168,
      -0.0479799322783947,
      -0.013944311998784542,
      0.08840809017419815,
      -0.03128495812416077,
      0.05561364069581032,
      -0.06857495754957199,
      -0.016373498365283012,
      -0.01237323135137558,
      0.002040828112512827,
      -0.01936357468366623,
      -0.012804310768842697,
      0.0338786356151104,
      -0.0071375202387571335,
      0.03526564687490463,
      -0.035118184983730316,
      0.07483909279108047,
      0.0510522723197937,
      0.013261973857879639,
      0.03691193088889122,
      0.035404711961746216,
      -0.009654255583882332,
      -0.06520454585552216,
      0.028794696554541588,
      0.0006923003820702434,
      0.07246837019920349,
      -0.009080628864467144,
      0.001398064661771059,
      0.0012188733089715242,
      0.08041764050722122,
      -0.004953081253916025,
      0.014887428842484951,
      -0.0173934493213892,
      -0.06424399465322495,
      0.004359849262982607,
      -0.09895642846822739,
      -0.017093941569328308,
      -0.06533140689134598,
      0.04120032861828804,
      -0.03617477044463158,
      0.025366991758346558,
      0.0003695863706525415,
      0.1757253259420395,
      0.006887841038405895,
      -0.014404148794710636,
      -0.01946515031158924,
      -0.051003966480493546,
      0.0007203246932476759,
      0.03287724032998085,
      -0.005508353468030691,
      0.04335716366767883,
      -0.03679225966334343,
      -0.018762407824397087,
      -0.020339911803603172,
      -0.036198217421770096,
      -0.01642978936433792,
      0.06272123008966446,
      0.01811329461634159,
      -0.07039481401443481,
      -0.06002315878868103,
      -0.03745415806770325,
      -0.021859169006347656,
      0.027585264295339584,
      0.056568846106529236,
      -0.026729892939329147,
      0.06720317900180817,
      0.06485065817832947,
      -0.0042549967765808105,
      0.032633788883686066,
      0.04651567339897156,
      -0.060016077011823654,
      0.044195756316185,
      -0.05470115318894386,
      -0.021327229216694832,
      -0.009407625533640385,
      0.052616771310567856,
      0.020192384719848633,
      -0.01836176961660385,
      0.08475842326879501,
      -0.05198416858911514,
      -0.0059329005889594555,
      0.05768604949116707,
      0.07863575220108032,
      0.06752396374940872,
      0.016013242304325104,
      0.028832269832491875,
      -0.00506983557716012,
      0.06445839256048203,
      -0.014217814430594444,
      0.02272116392850876,
      -0.05403851717710495,
      -0.009568922221660614,
      -0.0825609415769577,
      0.08631306141614914,
      -0.03089108318090439,
      -0.00941935833543539,
      0.04317880794405937,
      -0.03504139557480812,
      0.10523971170186996,
      0.02893783338367939,
      -0.07432454079389572,
      -0.0052005029283463955,
      -0.05860753357410431,
      0.07854146510362625,
      -0.06352369487285614,
      0.05965692549943924,
      -0.04232204332947731,
      0.012354278936982155,
      0.015724902972579002,
      0.04536229372024536,
      -0.0659475326538086,
      0.03286458179354668,
      0.05609689652919769,
      -0.018640194088220596,
      0.007302281446754932,
      -0.07123897224664688,
      -0.07669966667890549,
      -0.02754243090748787,
      0.010816562920808792,
      -0.0352180190384388,
      0.028777262195944786,
      0.08489397913217545,
      -0.05349745228886604,
      0.054670244455337524,
      -0.03842294588685036,
      -0.07650190591812134,
      0.0158731359988451,
      -0.025511616840958595,
      -0.026003213599324226,
      0.030421406030654907,
      -0.021960806101560593,
      -0.06816451996564865,
      0.047790732234716415,
      -0.005748426076024771,
      0.05174562335014343,
      -0.04773764684796333,
      -0.05311264470219612,
      -0.008245890960097313,
      0.10133978724479675,
      0.04187461733818054,
      -0.04970189556479454,
      0.04434334486722946,
      0.03738566115498543,
      -0.05780184641480446,
      -0.015178054571151733,
      -0.10972191393375397,
      -0.01697169430553913,
      0.05781504511833191,
      0.037081390619277954,
      -0.04710058122873306,
      0.029945947229862213,
      0.05467405542731285,
      -0.004649547394365072,
      -0.010753481648862362,
      -0.1075824648141861,
      0.014955093152821064,
      0.12299542129039764,
      0.05188601464033127,
      -0.048741091042757034,
      0.05944698676466942,
      0.00420085433870554,
      -0.07795447111129761,
      0.0015113711124286056,
      0.053490713238716125,
      -0.06358995288610458,
      0.03010258823633194,
      0.04371943697333336,
      0.030355248600244522,
      0.013483116403222084,
      -0.00414831330999732,
      -0.012597658671438694,
      0.020461391657590866,
      -0.03742460533976555,
      -0.0533059686422348,
      -0.028927652165293694,
      -0.018582815304398537,
      -0.012993703596293926,
      -0.05362415313720703,
      -0.014401081018149853,
      0.04482470452785492,
      -0.06277613341808319,
      -0.010867619886994362,
      -0.045015353709459305,
      -0.04251744598150253,
      0.048902589827775955,
      -0.09368839859962463,
      -0.08278202265501022,
      0.06352385878562927,
      -0.004712379537522793,
      0.048525724560022354,
      0.05131568759679794,
      -0.0019680196419358253,
      -0.06394346058368683,
      -0.026215611025691032,
      0.0699077695608139,
      0.03741517663002014,
      0.04396004229784012,
      0.07242317497730255,
      0.013102774508297443,
      -0.011629047803580761,
      -0.023779988288879395,
      0.016173072159290314,
      -0.013605034910142422,
      0.05249401554465294,
      0.052423518151044846,
      -0.0025855875574052334,
      -0.055402059108018875,
      -0.004358320031315088,
      -0.061085332185029984,
      -0.005947801284492016,
      0.06684659421443939,
      -0.01642901450395584,
      -0.023708956316113472,
      0.03677302598953247,
      0.05114965885877609,
      0.0470069982111454,
      -0.004190117120742798,
      -0.014117661863565445,
      -0.12735570967197418,
      -0.02860911749303341,
      0.03757429122924805,
      -0.007019237615168095,
      0.05153939500451088,
      -0.0962444394826889,
      -0.039212584495544434,
      0.07314043492078781,
      -0.0840664952993393,
      0.024259300902485847,
      0.02633868157863617,
      -0.039838988333940506,
      -0.03579325973987579,
      0.039227426052093506,
      0.0010775142582133412,
      0.09922025352716446,
      0.11528494954109192,
      0.036116380244493484,
      0.07428131997585297,
      0.04972482845187187,
      -0.022513162344694138,
      0.04095926508307457,
      -0.017761725932359695,
      0.04010973870754242,
      0.026314327493309975,
      0.033035602420568466,
      -0.007879274897277355,
      -0.006337397266179323,
      -0.03690531104803085,
      0.01010780967772007,
      0.018334921449422836,
      -0.018773740157485008,
      0.03633616864681244,
      0.013005327433347702,
      0.070158451795578,
      0.01879543997347355,
      -0.02556731179356575,
      0.0026911813765764236,
      0.029828965663909912,
      -0.06434201449155807,
      -0.04094059392809868,
      -0.04610690474510193,
      0.02262384071946144,
      0.0395796000957489,
      0.05851944908499718,
      0.07105431705713272,
      0.02341420389711857,
      -0.02115366794168949,
      -0.0077434624545276165,
      0.03143440932035446,
      0.020349889993667603,
      -0.029110921546816826,
      -0.03932245075702667,
      -0.11507858335971832,
      0.0008613636018708348,
      -0.02655392326414585,
      0.012212474830448627,
      0.06613624840974808,
      -0.011049225926399231,
      0.06562589108943939,
      -0.0049219015054404736,
      0.010965676978230476,
      -0.004647191148251295,
      0.009624209254980087,
      0.09405805915594101,
      0.05029243975877762,
      0.0714593157172203,
      -0.03076361119747162,
      -0.0762455090880394,
      0.0019483065698295832,
      0.02163822390139103,
      0.07697543501853943,
      -0.032741811126470566,
      0.04185349866747856,
      0.05255645141005516,
      -0.039146631956100464,
      -0.04557371884584427,
      -0.03638450428843498,
      0.02801886759698391,
      -0.05125424638390541,
      -0.02224789187312126,
      -0.015705017372965813,
      -0.026069743558764458,
      0.03293994441628456,
      0.08956204354763031,
      -0.03085826337337494,
      -0.00403472688049078,
      0.01783578284084797,
      -0.03761129081249237,
      -0.035749584436416626,
      -0.023918841034173965,
      0.15925131738185883,
      -0.015178813599050045,
      -0.02321716770529747,
      0.03494006395339966,
      -0.03167954087257385,
      -0.035935964435338974,
      0.05332043766975403,
      0.09911245107650757,
      0.03169957175850868,
      -0.014118489809334278,
      -0.048764269798994064,
      -0.06893622130155563,
      -0.052492450922727585,
      -0.08192037045955658,
      0.09953250735998154,
      0.07675766199827194,
      -0.037210460752248764,
      -0.05295684561133385,
      -0.0006218593334779143,
      -0.07990875095129013,
      0.03713482618331909,
      0.030693676322698593,
      -0.12112972885370255,
      -0.07777980715036392,
      0.023808274418115616,
      0.11544764786958694,
      0.04886947199702263,
      0.03920847550034523,
      -0.014144151471555233,
      -0.005384588614106178,
      -0.03167927265167236,
      -0.06962461024522781,
      -0.0004284032329451293,
      0.009372699074447155,
      -0.001511834911070764,
      0.019987303763628006,
      0.04205963388085365,
      -0.03840889781713486,
      -0.023435799404978752,
      -0.02863469533622265,
      -0.059742994606494904,
      -0.04848102480173111,
      -0.038626234978437424,
      0.04230375960469246,
      0.038377825170755386,
      0.022296635434031487,
      -0.0987258329987526,
      0.048739444464445114,
      -0.09633345156908035,
      0.04580169916152954,
      -0.023461440578103065,
      0.06052272394299507,
      0.08705954253673553,
      0.08564604818820953,
      -0.0351925753057003,
      -0.034800175577402115,
      -0.010448511689901352,
      -0.059062499552965164,
      0.02566356025636196,
      0.0729568600654602,
      -0.03253095969557762,
      -0.09873311221599579,
      0.07407300174236298,
      0.027890417724847794,
      -0.11450192332267761,
      -0.012437650933861732,
      0.04119189828634262,
      0.07313594967126846,
      -0.04801226034760475,
      -0.024759303778409958
    ],
    [
      0.003930188715457916,
      0.013375493697822094,
      0.03350694477558136,
      -0.0123119056224823,
      -0.014786683954298496,
      0.00873537641018629,
      -0.13525861501693726,
      -0.019705604761838913,
      0.009660042822360992,
      0.044035542756319046,
      -0.054481979459524155,
      0.061783142387866974,
      -0.004918709862977266,
      0.007179309148341417,
      -0.05053295940160751,
      0.030077239498496056,
      -0.013808011077344418,
      -0.05708524212241173,
      -0.010441250167787075,
      0.004206558223813772,
      -0.02703855372965336,
      -0.013359902426600456,
      0.04123212397098541,
      0.058212313801050186,
      0.06333079934120178,
      0.002314661629498005,
      0.029202573001384735,
      0.004079317674040794,
      0.08044115453958511,
      -0.06084776297211647,
      -0.045919451862573624,
      -0.045681074261665344,
      -0.01963217556476593,
      -0.0032752840779721737,
      -0.050905901938676834,
      -0.030307652428746223,
      0.03184466436505318,
      -0.05986212193965912,
      0.023957358673214912,
      -0.057607222348451614,
      -0.026702500879764557,
      0.008992045186460018,
      -0.013582713901996613,
      -0.01424333918839693,
      -0.07978223264217377,
      0.010208135470747948,
      -0.01600819267332554,
      0.05723176524043083,
      -0.060644712299108505,
      -0.056640058755874634,
      0.019720343872904778,
      -0.08555833250284195,
      0.045764289796352386,
      0.06367017328739166,
      -0.037077754735946655,
      -0.005089582409709692,
      0.011110270395874977,
      -0.0409248024225235,
      -0.08630020916461945,
      -0.0837022066116333,
      -0.03945555165410042,
      -0.061814773827791214,
      -0.010760622099041939,
      0.015798941254615784,
      -0.019190281629562378,
      0.04334709048271179,
      0.007726886309683323,
      0.03940980136394501,
      -0.0013713728403672576,
      0.020772237330675125,
      0.07017357647418976,
      0.07058253884315491,
      -0.031223714351654053,
      0.014242409728467464,
      0.01899619586765766,
      0.02849537320435047,
      -0.027321645990014076,
      -0.005498149897903204,
      0.011750644072890282,
      0.010602949187159538,
      -0.09662943333387375,
      0.010019378736615181,
      0.017040295526385307,
      -0.04186589643359184,
      -0.05045162886381149,
      0.01009015180170536,
      -0.030525149777531624,
      0.11844822764396667,
      0.028774822130799294,
      0.017527537420392036,
      -0.06885411590337753,
      -0.013871290720999241,
      -0.03226074203848839,
      0.03211680054664612,
      -0.0933157205581665,
      0.02167878858745098,
      0.03848297894001007,
      0.016418322920799255,
      -0.07403207570314407,
      -0.02119566686451435,
      -0.015520289540290833,
      -0.045376114547252655,
      -0.04471621289849281,
      -0.026930486783385277,
      0.025655485689640045,
      0.039320312440395355,
      -0.05357414856553078,
      0.040819134563207626,
      -0.050285376608371735,
      0.02414052188396454,
      -0.016640160232782364,
      0.010419793426990509,
      -0.012111402116715908,
      -0.09038876742124557,
      0.16455024480819702,
      0.031241687014698982,
      0.001537557109259069,
      0.022301994264125824,
      -0.04614393785595894,
      0.02028919756412506,
      0.022628486156463623,
      -0.06418120115995407,
      -0.007450147531926632,
      0.0747794508934021,
      0.004450689069926739,
      0.014623554423451424,
      0.0801030620932579,
      -0.01293910015374422,
      -0.07062534242868423,
      -0.04226447269320488,
      0.021721262484788895,
      -0.013220741413533688,
      0.035993851721286774,
      0.03794808313250542,
      -0.018816305324435234,
      -0.06681148707866669,
      0.018627291545271873,
      0.02925497107207775,
      -0.015707606449723244,
      -0.08123686909675598,
      -0.0031087377574294806,
      0.0050035081803798676,
      -0.027765221893787384,
      -0.010484701953828335,
      -0.10760076344013214,
      0.05955100432038307,
      -0.06786461919546127,
      0.0005560622666962445,
      0.024895507842302322,
      -0.02862462028861046,
      -0.045595720410346985,
      0.02110810950398445,
      -0.01704237423837185,
      0.005076861009001732,
      0.010768664069473743,
      0.020919445902109146,
      0.0739169642329216,
      0.05386371538043022,
      0.04592491686344147,
      0.06623990088701248,
      -0.02330463007092476,
      0.021344328299164772,
      0.053041793406009674,
      -0.03726975992321968,
      0.02490311488509178,
      0.04672032967209816,
      0.021770218387246132,
      0.09435474872589111,
      0.01078107487410307,
      0.020594745874404907,
      -0.007787614595144987,
      0.0095128258690238,
      0.02624780498445034,
      -0.07688269764184952,
      0.06471008062362671,
      0.029408561065793037,
      0.14490751922130585,
      -0.007541051134467125,
      -0.04449779912829399,
      -0.05675441026687622,
      -0.03285232186317444,
      0.014818670228123665,
      0.08175765722990036,
      -0.0056057642214000225,
      0.013369995169341564,
      0.003716059261932969,
      -0.01929512806236744,
      0.005686169024556875,
      0.047589704394340515,
      -0.03700735792517662,
      -0.03666276857256889,
      -0.017066286876797676,
      -0.008321265690028667,
      0.02556382492184639,
      -0.02997443452477455,
      -0.005086265504360199,
      0.03944911062717438,
      -0.009926862083375454,
      0.0125045794993639,
      0.044776469469070435,
      -0.009298073127865791,
      0.002736236434429884,
      0.047506850212812424,
      -0.012425408698618412,
      0.015152370557188988,
      0.0502922385931015,
      0.041297998279333115,
      -0.06591242551803589,
      0.058593980967998505,
      0.011012017726898193,
      -0.04716942086815834,
      -0.03883420303463936,
      0.07588361203670502,
      -0.03255508467555046,
      0.031506165862083435,
      -0.06349802017211914,
      0.04295484349131584,
      0.030334386974573135,
      -0.04767714813351631,
      0.02620355598628521,
      -0.051200222223997116,
      -0.05215240642428398,
      0.0043728831224143505,
      0.07957949489355087,
      -0.04185178503394127,
      -0.06019650027155876,
      -0.08046989887952805,
      0.14513663947582245,
      0.0022560989018529654,
      -0.09702607989311218,
      0.02896450087428093,
      0.0034285092260688543,
      0.08411747217178345,
      0.05713292583823204,
      0.017498597502708435,
      -0.034745510667562485,
      0.047221869230270386,
      -0.07024256885051727,
      -0.08156734704971313,
      0.04893118515610695,
      -0.016231326386332512,
      0.05368223413825035,
      0.0016925220843404531,
      -0.00998388696461916,
      -0.09598828852176666,
      0.04156932607293129,
      -0.02417963184416294,
      -0.05918227508664131,
      0.023066509515047073,
      0.019745878875255585,
      0.021634429693222046,
      0.0635940432548523,
      0.01915612258017063,
      -0.025934336706995964,
      -0.04188033565878868,
      -0.028019579127430916,
      0.05254264175891876,
      0.08983304351568222,
      -0.018572663888335228,
      0.03595349192619324,
      -0.023786792531609535,
      -0.02840462513267994,
      -0.022054942324757576,
      0.00012972259719390422,
      -0.073390431702137,
      0.011610576882958412,
      0.040195271372795105,
      -0.05366383120417595,
      -0.027180686593055725,
      0.06504567712545395,
      0.01327467244118452,
      0.027217863127589226,
      -0.05886410176753998,
      0.051865119487047195,
      0.04011058062314987,
      0.017974015325307846,
      -0.015884723514318466,
      -0.002613426884636283,
      -0.0206688791513443,
      -0.01181082334369421,
      -0.056668926030397415,
      -0.017986256629228592,
      0.0245727077126503,
      -0.00769323343411088,
      0.06063072383403778,
      -0.08411995321512222,
      -0.012289553880691528,
      0.062207769602537155,
      0.10882922261953354,
      0.0018605765653774142,
      0.04717056825757027,
      0.008362221531569958,
      0.04523741453886032,
      -0.03005998022854328,
      0.027737939730286598,
      -0.019638247787952423,
      -0.010669160634279251,
      -0.010995210148394108,
      0.011500033549964428,
      0.09987954050302505,
      -0.018346499651670456,
      -0.027576172724366188,
      0.048863887786865234,
      -0.03132641687989235,
      -0.050132859498262405,
      -0.021788882091641426,
      0.028912607580423355,
      0.01168527826666832,
      0.022838421165943146,
      0.03569019213318825,
      0.061590682715177536,
      -0.0006237222114577889,
      -0.08717633038759232,
      -0.01577073708176613,
      0.014025961980223656,
      0.07080714404582977,
      -0.0380878672003746,
      0.08974897116422653,
      -0.013990579172968864,
      0.019673243165016174,
      0.042990121990442276,
      -0.0013747994089499116,
      -0.0062698060646653175,
      0.0432928130030632,
      -0.006849215365946293,
      0.08335079252719879,
      0.0068236761726439,
      -0.010190555825829506,
      -0.08061983436346054,
      0.06475385278463364,
      -0.021253423765301704,
      0.09187142550945282,
      -0.041379570960998535,
      0.025240458548069,
      0.06105515733361244,
      0.037289634346961975,
      0.021584171801805496,
      0.02346944808959961,
      0.00412040576338768,
      -0.08572708070278168,
      0.016516035422682762,
      0.044706251472234726,
      0.018163926899433136,
      0.008101481013000011,
      0.08751681447029114,
      -0.02641262859106064,
      0.0503457747399807,
      -0.07389397919178009,
      -0.07239975035190582,
      -0.02667665295302868,
      -0.0387079156935215,
      0.054255835711956024,
      -0.05761193856596947,
      6.487669452326372e-05,
      0.03798610717058182,
      0.0694374218583107,
      -0.09406367689371109,
      0.016422076150774956,
      0.013607039116322994,
      -0.026320356875658035,
      0.049348488450050354,
      0.02134156972169876,
      -0.04979681596159935,
      -0.0029556299559772015,
      0.05367277190089226,
      0.0021720328368246555,
      0.014496882446110249,
      -0.006820080801844597,
      0.06534258276224136,
      -0.003259005956351757,
      -0.08036334812641144,
      -0.06845415383577347,
      0.02396056428551674,
      0.059040095657110214,
      -0.027197491377592087,
      -0.030308498069643974,
      -0.001987121533602476,
      0.04754955694079399,
      -0.0009559318423271179,
      -0.01745944656431675,
      -0.007121581118553877,
      -0.02283775806427002,
      -0.00025631411699578166,
      -0.05399387329816818,
      0.007511995267122984,
      -0.010733094066381454,
      0.03848649933934212,
      -0.03436169773340225,
      0.006888119969516993,
      0.0403529554605484,
      0.036051683127880096,
      -0.006691960152238607,
      -0.021580390632152557,
      0.03802937641739845,
      0.014414165169000626,
      -0.014861314557492733,
      -0.010966533794999123,
      -0.012006431818008423,
      -0.030295545235276222,
      -0.08874186128377914,
      0.03864508122205734,
      -0.040815319865942,
      0.0269441157579422,
      -0.013084827922284603,
      0.06180042028427124,
      -0.007392755709588528,
      0.03152143210172653,
      0.05628098547458649,
      -0.02552502416074276,
      -0.052162934094667435,
      -0.025415267795324326,
      0.000410733453463763,
      -0.024519354104995728,
      -0.0501147136092186,
      -0.055120550096035004,
      -0.024877414107322693,
      -0.013086755760014057,
      0.06966427713632584,
      0.08885980397462845,
      -0.02720050700008869,
      -0.03479636088013649,
      -0.10764262825250626,
      -0.04091638699173927,
      -0.015748217701911926,
      0.054899971932172775,
      -0.031288210302591324,
      -0.04090069606900215,
      -0.08176158368587494,
      -0.009038733318448067,
      0.010881527327001095,
      -0.05404532328248024,
      0.013298156671226025,
      0.03798065707087517,
      -0.003297568066045642,
      -0.05284787341952324,
      -0.025938749313354492,
      -0.06192836910486221,
      -0.05078966170549393,
      0.00831153430044651,
      0.0021927249617874622,
      0.02476521208882332,
      0.0062023610807955265,
      -0.04600636288523674,
      -0.046275340020656586,
      -0.017397448420524597,
      -0.04340483993291855,
      0.01743551529943943,
      0.04527601599693298,
      0.033281970769166946,
      0.11375468969345093,
      0.020244695246219635,
      0.0871071070432663,
      -0.018648764118552208,
      -0.009379751980304718,
      0.07785289734601974,
      -0.02283584326505661,
      0.014342882670462132,
      -0.017904631793498993,
      -0.020742278546094894,
      -0.02286914549767971,
      0.016009727492928505,
      -0.006172420457005501,
      0.08834503591060638,
      0.028220700100064278,
      -0.00456466106697917,
      0.017294755205512047,
      0.0004451700078789145,
      -0.04633130133152008,
      0.019714757800102234,
      0.005051956512033939,
      0.04213705286383629,
      -0.06837043166160583,
      0.047859515994787216,
      0.10146750509738922,
      0.07156375050544739,
      -0.04259593039751053,
      0.027415301650762558,
      -0.0638914629817009,
      0.0245460607111454,
      -0.041767872869968414,
      -0.06711084395647049,
      -0.11694913357496262,
      0.04017593339085579,
      0.037389907985925674,
      -0.01869850419461727,
      -0.03038026951253414,
      0.0017985530430451035,
      -0.04482397437095642,
      -0.06706025451421738,
      0.028882617130875587,
      0.047027163207530975,
      0.035252880305051804,
      0.03904441371560097,
      0.010798586532473564,
      -0.01945551112294197,
      0.012189876288175583,
      0.007724198512732983,
      -0.018743913620710373,
      0.014824134297668934,
      0.036119088530540466,
      -0.011622310616075993,
      0.013740578666329384,
      0.011299015954136848
    ],
    [
      0.015282235108315945,
      0.038345735520124435,
      0.011330761946737766,
      0.016583597287535667,
      0.02728874422609806,
      0.015545586124062538,
      -0.0022658733651041985,
      -0.09679136425256729,
      0.019197668880224228,
      0.09020274877548218,
      0.0701344683766365,
      -0.07863547652959824,
      0.08411635458469391,
      -0.019679471850395203,
      -0.01701073721051216,
      -0.017945149913430214,
      -0.013511461205780506,
      -0.01410890556871891,
      -0.06263905763626099,
      0.016547784209251404,
      0.020914604887366295,
      -0.06932323426008224,
      -0.02355709858238697,
      0.056655023247003555,
      0.0023325420916080475,
      0.0461542010307312,
      -0.007843784987926483,
      0.03087359108030796,
      0.02003379538655281,
      0.061073847115039825,
      -0.028505459427833557,
      0.0026654829271137714,
      -0.07240834832191467,
      0.0058246334083378315,
      0.03926553204655647,
      0.03734637424349785,
      -0.03796998783946037,
      0.039036672562360764,
      0.07051610201597214,
      -0.005201315972954035,
      0.08500036597251892,
      -0.055895641446113586,
      0.015605070628225803,
      0.0075134518556296825,
      0.0033286986872553825,
      -0.002224490512162447,
      -0.010839828290045261,
      -0.053167883306741714,
      -0.0019881408661603928,
      0.035242870450019836,
      -0.03706498444080353,
      -0.0691227838397026,
      0.04924486204981804,
      0.08123859763145447,
      0.024689827114343643,
      -0.03875787928700447,
      0.035915084183216095,
      -0.016877267509698868,
      0.023166125640273094,
      0.009333646856248379,
      -0.08205261081457138,
      -0.027166208252310753,
      -0.0087035046890378,
      0.016053538769483566,
      -0.008182995952665806,
      0.005088966339826584,
      -0.020220089703798294,
      0.045139119029045105,
      -0.024486180394887924,
      0.009247452951967716,
      0.04135417938232422,
      0.07417207211256027,
      0.019980857148766518,
      -0.0036479299888014793,
      0.001950372476130724,
      -0.06346145272254944,
      0.07704287022352219,
      -0.0558277890086174,
      0.0022092366125434637,
      -0.028819652274250984,
      -0.05314970016479492,
      0.03327649086713791,
      -0.018857495859265327,
      0.025380201637744904,
      0.0168328694999218,
      -0.03096851520240307,
      0.031582798808813095,
      0.007971860468387604,
      -0.012454736977815628,
      -0.024573106318712234,
      0.00753703573718667,
      -0.05752033367753029,
      -0.04244017228484154,
      -0.03995877504348755,
      -0.013773600570857525,
      -0.018439792096614838,
      -0.10776282101869583,
      -0.03495820611715317,
      0.012071548029780388,
      0.0466943122446537,
      -0.034825399518013,
      0.021008143201470375,
      -0.013896658085286617,
      -0.03674958273768425,
      0.046989258378744125,
      -0.0275984276086092,
      0.020030679181218147,
      0.01952977292239666,
      0.04208987206220627,
      -0.025153938680887222,
      -0.09814965724945068,
      0.088382288813591,
      0.052380360662937164,
      -0.019521012902259827,
      -0.019535912200808525,
      -0.01150664035230875,
      -0.030572831630706787,
      -0.11364603787660599,
      -0.07357332855463028,
      0.038610849529504776,
      0.060941774398088455,
      -0.010871429927647114,
      -0.052270881831645966,
      0.03497600927948952,
      -0.03715480491518974,
      -0.06425709277391434,
      0.035140711814165115,
      -0.09931302815675735,
      -0.027674749493598938,
      -0.16338816285133362,
      0.038690418004989624,
      -0.013303360901772976,
      0.0009086105274036527,
      0.013580350205302238,
      0.009211878292262554,
      0.02569914236664772,
      -0.0030988426879048347,
      0.049826230853796005,
      0.046113982796669006,
      -0.007703633978962898,
      0.05202269181609154,
      -0.01393923070281744,
      -0.04078167304396629,
      0.016722090542316437,
      -0.010489986278116703,
      -0.0009407203760929406,
      0.057084500789642334,
      -0.01201591081917286,
      0.033239178359508514,
      -0.047551825642585754,
      -0.0040487791411578655,
      -0.065644770860672,
      0.0330248586833477,
      0.022840037941932678,
      0.03271393105387688,
      -0.002200944349169731,
      -0.04688487574458122,
      -0.027282321825623512,
      0.02165348269045353,
      0.008924261666834354,
      -0.054991185665130615,
      -0.02485661953687668,
      -0.00504853343591094,
      0.00498396810144186,
      0.0058896904811263084,
      0.017715662717819214,
      -0.04480522498488426,
      0.1292501837015152,
      -0.006693078204989433,
      -0.03978830203413963,
      0.06395062059164047,
      -0.0361027866601944,
      -0.09134534001350403,
      0.02623501792550087,
      0.08221716433763504,
      0.05822066217660904,
      0.09515024721622467,
      -0.011647047474980354,
      -0.06658005714416504,
      0.07079102843999863,
      -0.04207265004515648,
      -0.021314628422260284,
      -0.03783291205763817,
      -0.016737431287765503,
      -0.01362030953168869,
      0.03890269622206688,
      0.0534786731004715,
      -0.026415837928652763,
      0.010101187974214554,
      -0.012639859691262245,
      -0.08066943287849426,
      -0.022211816161870956,
      -0.01615852490067482,
      0.058100152760744095,
      -0.004418149124830961,
      -0.0009163627400994301,
      0.018923461437225342,
      0.06345194578170776,
      0.0008558568079024553,
      -0.040811825543642044,
      -0.05123627930879593,
      -0.04176613315939903,
      0.020239422097802162,
      0.012541528791189194,
      0.03999242186546326,
      0.015239587053656578,
      -0.05137674883008003,
      -0.027904726564884186,
      0.032566677778959274,
      -0.02811386249959469,
      0.11045017093420029,
      0.027112701907753944,
      0.017216920852661133,
      -0.03378666564822197,
      0.00859348475933075,
      0.019455038011074066,
      -0.11092294007539749,
      0.05208732560276985,
      -0.038355760276317596,
      0.009609022177755833,
      -0.11108389496803284,
      -0.03904630243778229,
      -0.018317973241209984,
      0.02839800901710987,
      -0.02229941263794899,
      0.032881591469049454,
      -0.0056660049594938755,
      -0.025498678907752037,
      -0.02196810021996498,
      0.003171522170305252,
      0.023098153993487358,
      -0.06396843492984772,
      -0.042824551463127136,
      -0.015014929696917534,
      0.04335246607661247,
      -0.03404663875699043,
      -0.017453432083129883,
      -0.062170155346393585,
      -0.01809443160891533,
      0.08311481028795242,
      -0.010194971226155758,
      0.0422438345849514,
      -0.029402466490864754,
      -0.09706955403089523,
      -0.03741030767560005,
      -0.03750362992286682,
      0.0034116285387426615,
      0.00920260138809681,
      0.02305336482822895,
      -0.039472367614507675,
      -0.0023168919142335653,
      0.024319740012288094,
      0.029660405591130257,
      0.004530289676040411,
      -0.06240672618150711,
      0.01120033860206604,
      0.04502179101109505,
      -0.05491991713643074,
      -0.020254921168088913,
      0.008144606836140156,
      -0.05044291913509369,
      -0.019259747117757797,
      0.07164089381694794,
      0.013639218173921108,
      0.006067751441150904,
      -0.03140631690621376,
      -0.022630425170063972,
      0.03784434124827385,
      -0.02638273686170578,
      -0.0009533631964586675,
      0.013599985279142857,
      -0.0037400212604552507,
      0.05314484238624573,
      -0.04875187575817108,
      0.0671745240688324,
      0.007762562017887831,
      -0.04469896852970123,
      0.02331223338842392,
      0.01505197025835514,
      -0.00067483214661479,
      -0.025048905983567238,
      0.019393665716052055,
      -0.05594232678413391,
      0.0798507109284401,
      0.04939790815114975,
      -0.049383215606212616,
      0.0459439717233181,
      -0.06429744511842728,
      -0.0294323917478323,
      -0.026432238519191742,
      0.024146946147084236,
      0.050277229398489,
      -0.03577020764350891,
      0.05118703842163086,
      0.06183582544326782,
      0.021451279520988464,
      -0.07242456823587418,
      -0.0022569585125893354,
      -0.037850260734558105,
      -0.011953923851251602,
      -0.01011226698756218,
      0.01593635231256485,
      0.029723456129431725,
      0.02620266191661358,
      -0.01775025576353073,
      -0.03572904318571091,
      0.07215338200330734,
      -0.0166864525526762,
      -0.09193159639835358,
      0.016217974945902824,
      -0.005496928468346596,
      -0.02990506961941719,
      -0.05220160260796547,
      -0.023864151909947395,
      -0.052013687789440155,
      0.006082174833863974,
      0.060925278812646866,
      -0.046342313289642334,
      0.053826142102479935,
      0.013808511197566986,
      0.03088730201125145,
      -0.012569526210427284,
      -0.06503652781248093,
      0.04495533928275108,
      0.040820345282554626,
      0.06617897003889084,
      0.01772189885377884,
      0.06875395774841309,
      -0.03882317990064621,
      0.012814668007194996,
      -0.10530141741037369,
      0.032310694456100464,
      -0.06630336493253708,
      0.010615942999720573,
      -0.07468892633914948,
      -0.023253288120031357,
      0.07863187789916992,
      -0.054763637483119965,
      0.0041679563000798225,
      -0.06703349947929382,
      -0.044516727328300476,
      -0.0017214627005159855,
      0.08062678575515747,
      -0.03492290526628494,
      0.04894048720598221,
      -0.046678293496370316,
      0.022876130416989326,
      -0.08714805543422699,
      -0.034013908356428146,
      -0.006329202558845282,
      -0.03358602896332741,
      0.06352435797452927,
      0.06896942108869553,
      -0.04183613508939743,
      -0.021425457671284676,
      0.02049371413886547,
      -0.005269241519272327,
      0.025150803849101067,
      0.013098965398967266,
      -0.020703207701444626,
      -0.100702665746212,
      -0.07118279486894608,
      -0.0067992787808179855,
      0.06975612044334412,
      -0.09762973338365555,
      0.041854582726955414,
      0.022715186700224876,
      -0.039988741278648376,
      0.045507702976465225,
      -0.02557504177093506,
      0.0019462323980405927,
      -0.10847952216863632,
      0.1359315812587738,
      0.07054683566093445,
      -0.054431065917015076,
      -0.003984625451266766,
      -0.023514477536082268,
      -0.06488949060440063,
      0.028222955763339996,
      0.032720353454351425,
      -0.04949827119708061,
      -0.05191383510828018,
      -0.016555558890104294,
      -0.05065278336405754,
      -0.0028873002156615257,
      0.026042360812425613,
      0.007842951454222202,
      0.009833441115915775,
      0.010746899992227554,
      -0.01349232904613018,
      -0.037695225328207016,
      0.005681521724909544,
      0.08886886388063431,
      0.020786117762327194,
      -0.051230866461992264,
      -0.007169920019805431,
      -0.102407306432724,
      -0.005547025706619024,
      0.019874949008226395,
      -0.03883961960673332,
      -0.052467700093984604,
      -0.06284777075052261,
      -0.09956874698400497,
      -0.019345855340361595,
      0.06336420029401779,
      0.01529470644891262,
      0.09281383454799652,
      0.0015300463419407606,
      -0.028267713263630867,
      0.0028596962802112103,
      0.10919082164764404,
      0.03286019712686539,
      0.05765964835882187,
      -0.005150454584509134,
      0.03988959267735481,
      0.03084019385278225,
      0.009692276827991009,
      -0.06084718927741051,
      -0.08727677911520004,
      0.03564126417040825,
      0.0967932939529419,
      -0.06017320603132248,
      -0.011018243618309498,
      0.038983672857284546,
      -0.024472443386912346,
      0.0040815905667841434,
      -0.027990365400910378,
      0.011773859150707722,
      -0.035349249839782715,
      0.00014211198140401393,
      0.028102345764636993,
      -0.020207950845360756,
      0.08443126082420349,
      0.04975965619087219,
      0.031158722937107086,
      0.0014910667669028044,
      0.022786982357501984,
      0.11789248138666153,
      -0.023606183007359505,
      0.001883972785435617,
      0.03797002136707306,
      0.027101678773760796,
      0.023701854050159454,
      0.04037497192621231,
      -0.042307525873184204,
      -0.003896350972354412,
      0.018041830509901047,
      0.0026595478411763906,
      0.10594277083873749,
      -0.048170316964387894,
      -0.010708940215408802,
      0.08420777320861816,
      0.05261920765042305,
      0.031962934881448746,
      -0.033749863505363464,
      -0.06505829095840454,
      -0.0012833384098485112,
      -0.037712689489126205,
      0.058542653918266296,
      -0.035958677530288696,
      0.045810144394636154,
      0.027356022968888283,
      -0.013057112693786621,
      0.019408823922276497,
      -0.019236085936427116,
      0.004988253116607666,
      0.0059676868841052055,
      0.0916767492890358,
      -0.001638116780668497,
      0.025713641196489334,
      0.07569010555744171,
      0.002144168596714735,
      -0.01504950039088726,
      -0.049029506742954254,
      -0.08595086634159088,
      0.05046261101961136,
      -0.00455405144020915,
      -0.05962309613823891,
      -0.039561379700899124,
      -0.04152892529964447,
      -0.007287945132702589,
      -0.040416497737169266,
      0.03736410290002823,
      -0.03133741393685341,
      -0.0764070451259613,
      0.04599190875887871,
      0.07386088371276855,
      -0.04515337571501732,
      0.028403326869010925,
      -0.03355899080634117,
      -0.11163828521966934,
      0.04222388193011284,
      0.0843784362077713,
      -0.012102633714675903,
      -0.027901705354452133,
      -0.010807468555867672,
      -0.04866897314786911,
      0.11728716641664505,
      0.021533826366066933,
      0.02666090801358223,
      0.05905158445239067,
      -0.05873066186904907,
      -0.08925381302833557
    ],
    [
      -0.09209543466567993,
      -0.04104003310203552,
      -0.047544725239276886,
      0.05757792666554451,
      0.03540757670998573,
      -0.008490845561027527,
      -0.06278753280639648,
      0.006634100805968046,
      0.03339692950248718,
      0.009615476243197918,
      0.04011344537138939,
      -0.09182640165090561,
      -0.08628497272729874,
      0.006345044355839491,
      0.005783462431281805,
      -0.02502109482884407,
      -0.05496201291680336,
      -0.029581760987639427,
      -0.05189002677798271,
      0.0178154855966568,
      -0.04083767905831337,
      -0.007008924148976803,
      -0.044332653284072876,
      0.029690202325582504,
      0.07207809388637543,
      -0.026677830144762993,
      0.02436978928744793,
      0.00716973515227437,
      0.025625040754675865,
      -0.07915045320987701,
      0.002942519262433052,
      0.03831004351377487,
      0.006530217360705137,
      -0.029209353029727936,
      -0.05906488373875618,
      0.04225291311740875,
      0.041058529168367386,
      0.03369650989770889,
      0.11100821197032928,
      0.015058575198054314,
      0.02179294265806675,
      -0.03045795112848282,
      0.03382324054837227,
      0.0038005132228136063,
      0.01579193025827408,
      0.013258486986160278,
      -0.07479812949895859,
      0.05206894874572754,
      0.09843215346336365,
      0.024567032232880592,
      -0.030222341418266296,
      -0.08108045160770416,
      -0.03252939507365227,
      -0.06570938229560852,
      0.021242324262857437,
      -0.003266359679400921,
      -0.050373148173093796,
      -0.09861184656620026,
      0.038946595042943954,
      0.0006999109755270183,
      -0.03446758911013603,
      -0.011143219657242298,
      0.015353846363723278,
      -0.04737796634435654,
      -0.0412064790725708,
      0.0053051915019750595,
      -0.018094820901751518,
      0.06758657842874527,
      0.026755213737487793,
      0.013771590776741505,
      -0.04680886119604111,
      -0.03130299970507622,
      0.09618037939071655,
      0.06367666274309158,
      0.06276661902666092,
      0.04851638525724411,
      0.023425156250596046,
      -0.028298422694206238,
      -0.06995046883821487,
      0.027202963829040527,
      0.022312667220830917,
      0.06116889417171478,
      0.04097547009587288,
      -0.018039748072624207,
      0.15965379774570465,
      0.02482766844332218,
      -0.13264842331409454,
      -0.06313098222017288,
      0.026846125721931458,
      0.014074854552745819,
      -0.06696252524852753,
      -0.01400082278996706,
      0.007720247842371464,
      0.014537131413817406,
      -0.12139303982257843,
      0.04208088293671608,
      -0.04562019184231758,
      -0.015777671709656715,
      0.005427760537713766,
      0.0418490432202816,
      0.06569214165210724,
      -0.008901153691112995,
      0.07693565636873245,
      -0.07571172714233398,
      0.01635367050766945,
      0.05294491723179817,
      0.03072013147175312,
      0.008219543844461441,
      0.08221225440502167,
      0.018898027017712593,
      -0.08058039098978043,
      0.0043385266326367855,
      0.04113943874835968,
      0.0009754080092534423,
      -0.04030274599790573,
      -0.0367916002869606,
      -0.02963118813931942,
      -0.04240773990750313,
      -0.08690780401229858,
      -0.013073386624455452,
      -0.007417740765959024,
      -0.07342799752950668,
      0.013424075208604336,
      -0.027327006682753563,
      -0.044365815818309784,
      -0.03305598720908165,
      0.07304835319519043,
      -0.007664930075407028,
      -0.03255901113152504,
      0.005507441237568855,
      -0.07871011644601822,
      -0.014212950132787228,
      0.02261888049542904,
      -0.017421841621398926,
      0.10588168352842331,
      0.07419052720069885,
      0.004773661028593779,
      -0.09455866366624832,
      -0.056242331862449646,
      -0.06692937761545181,
      0.041258107870817184,
      0.03853009641170502,
      0.010375704616308212,
      0.012844301760196686,
      -0.0382990688085556,
      0.0157114677131176,
      0.01952272281050682,
      -0.0017362807411700487,
      0.09346362948417664,
      0.0037547661922872066,
      -0.03286409750580788,
      -0.06482183188199997,
      -0.02957308664917946,
      0.0711163803935051,
      -0.012149862945079803,
      0.028050528839230537,
      0.06987965852022171,
      0.010423165746033192,
      0.005154580343514681,
      -0.005439386237412691,
      -0.01400091964751482,
      -0.012952116318047047,
      -0.05854717269539833,
      -0.023712964728474617,
      0.01752433367073536,
      0.02342255413532257,
      -0.010355307720601559,
      0.022583961486816406,
      -0.008370921015739441,
      -0.09033972769975662,
      0.026488173753023148,
      -0.020268581807613373,
      0.05713270232081413,
      -0.08058944344520569,
      0.003330520587041974,
      0.020423905923962593,
      -0.0050839646719396114,
      -0.044319722801446915,
      -0.03600970283150673,
      0.06498850882053375,
      0.002641587285324931,
      -0.04375487565994263,
      0.013807904906570911,
      -0.0377948060631752,
      0.060705382376909256,
      0.01812177523970604,
      0.07738056033849716,
      -0.040316201746463776,
      -0.023912588134407997,
      -0.048667315393686295,
      -0.03497571125626564,
      0.013277730904519558,
      -0.012317929416894913,
      -0.06214986741542816,
      0.021127259358763695,
      0.036791253834962845,
      -0.060940176248550415,
      0.015928473323583603,
      -0.15558797121047974,
      0.07988796383142471,
      -0.032034989446401596,
      -0.03155360743403435,
      -0.05697137117385864,
      0.03970583900809288,
      -0.030657265335321426,
      0.031095286831259727,
      -0.0021957906428724527,
      -0.10021339356899261,
      0.0709463581442833,
      0.02867993712425232,
      -0.02873987704515457,
      0.03731279447674751,
      0.04304703325033188,
      0.05125058814883232,
      0.05089856684207916,
      0.04364916309714317,
      0.024961790069937706,
      0.027559541165828705,
      -0.05942584201693535,
      -0.023593761026859283,
      -0.009572879411280155,
      0.00859858188778162,
      -0.015590439550578594,
      -0.03506196290254593,
      -0.04018651321530342,
      0.02935141511261463,
      0.014995492063462734,
      0.0798102617263794,
      -0.0374126099050045,
      0.08247876167297363,
      -0.05366107448935509,
      -0.02680135890841484,
      0.009505689144134521,
      -0.010302511975169182,
      0.060983218252658844,
      0.016329403966665268,
      0.008890433236956596,
      -0.011722681112587452,
      -0.013190765865147114,
      -0.09896482527256012,
      -0.015202242881059647,
      0.049123577773571014,
      -0.06258555501699448,
      0.036882977932691574,
      -0.007450138684362173,
      -0.012106809765100479,
      0.012189237400889397,
      -0.0008596880361437798,
      0.012946239672601223,
      0.0224700216203928,
      0.011361801996827126,
      0.03434455767273903,
      0.062195032835006714,
      0.05368635058403015,
      0.040417227894067764,
      -0.016121722757816315,
      0.008388647809624672,
      -0.04626475274562836,
      0.041862983256578445,
      0.0977746769785881,
      -0.05987590178847313,
      0.04381075128912926,
      0.014990322291851044,
      0.02936703898012638,
      0.034774232655763626,
      -0.01821942999958992,
      0.0014382428489625454,
      -0.020772995427250862,
      -0.0011872084578499198,
      0.015815844759345055,
      0.001251628389582038,
      -0.022544188424944878,
      -0.0179356150329113,
      -8.016708306968212e-05,
      0.048302240669727325,
      0.01351964846253395,
      0.0359252505004406,
      -0.05200568214058876,
      -0.07913703471422195,
      0.04392771050333977,
      0.045618411153554916,
      0.03702274337410927,
      0.013937278650701046,
      0.028856990858912468,
      0.005651156418025494,
      0.07367987930774689,
      -0.026051132008433342,
      0.011820999905467033,
      -0.023875797167420387,
      -0.030891811475157738,
      -0.10007834434509277,
      -0.0016475137090310454,
      0.018639229238033295,
      -0.01092542801052332,
      -0.01146106980741024,
      -0.03822709619998932,
      0.04917134717106819,
      -0.0013745717005804181,
      -0.04444926977157593,
      -0.034697916358709335,
      -0.02980627864599228,
      0.031102973967790604,
      -0.014135969802737236,
      0.02353588119149208,
      0.0894070640206337,
      -0.03425151854753494,
      0.01201330404728651,
      0.07111344486474991,
      0.019324207678437233,
      -0.028408925980329514,
      0.013370930217206478,
      -0.054512154310941696,
      0.023798272013664246,
      0.02299351617693901,
      -0.06294900923967361,
      0.01185191236436367,
      0.012514137662947178,
      0.03448763117194176,
      0.03742325305938721,
      0.023071294650435448,
      0.04198174178600311,
      0.03544663265347481,
      0.017121504992246628,
      -0.0024008224718272686,
      -0.010472851805388927,
      0.06937722116708755,
      0.03389368951320648,
      0.03462683781981468,
      0.047588203102350235,
      0.008075302466750145,
      -0.047235600650310516,
      0.043570443987846375,
      -0.0021521395538002253,
      0.042646221816539764,
      0.023596342653036118,
      0.020032960921525955,
      -0.0233308058232069,
      0.0290925782173872,
      -0.015229184180498123,
      -0.08960495889186859,
      0.06589625775814056,
      -0.04029059410095215,
      0.054418761283159256,
      0.015440442599356174,
      0.021317820996046066,
      0.03309706225991249,
      -0.019428066909313202,
      -0.006780903320759535,
      -0.011947287246584892,
      -0.06120595335960388,
      -0.008720844984054565,
      -0.010385316796600819,
      -0.029934227466583252,
      -0.010495726950466633,
      0.04393251612782478,
      0.010386248119175434,
      0.03969624266028404,
      0.0335441417992115,
      0.06751519441604614,
      0.05428214743733406,
      0.03554753214120865,
      0.05093672499060631,
      0.03977419063448906,
      -0.003341319039463997,
      0.04027387127280235,
      -0.00979669950902462,
      -0.06379560381174088,
      0.04828619956970215,
      0.00633499538525939,
      0.05506288632750511,
      0.027088910341262817,
      0.08405908197164536,
      0.0020563097205013037,
      0.07009238749742508,
      0.021345794200897217,
      -0.006881298031657934,
      0.04858079180121422,
      0.07627110183238983,
      -0.04354260116815567,
      -0.08157256990671158,
      -0.056560173630714417,
      -0.04050542041659355,
      -0.022271007299423218,
      -0.07749199867248535,
      0.04417439177632332,
      -0.01860998198390007,
      0.012935684062540531,
      0.005780128762125969,
      -0.03710974007844925,
      -0.028812183067202568,
      -0.006022220477461815,
      0.06912685930728912,
      0.017291828989982605,
      -0.0892602950334549,
      -0.06979075074195862,
      -0.002431390108540654,
      -0.010591153055429459,
      0.020282896235585213,
      0.07365037500858307,
      -0.07840558886528015,
      -0.0028184365946799517,
      -0.025590689852833748,
      0.014516893774271011,
      0.03351076692342758,
      0.04186214879155159,
      -0.017315996810793877,
      -0.005606267135590315,
      -0.0005502995336428285,
      0.07490275800228119,
      0.059957653284072876,
      0.0751439705491066,
      0.05993321165442467,
      0.012160440906882286,
      0.0716899037361145,
      0.12325438112020493,
      0.03694210946559906,
      -0.06813601404428482,
      0.09276025742292404,
      -5.214469274505973e-05,
      0.002267799573019147,
      0.00698166573420167,
      -0.06051642447710037,
      -0.008422316052019596,
      -0.0009308188455179334,
      -0.016642041504383087,
      -0.027392325922846794,
      -0.043075963854789734,
      -0.022331245243549347,
      -0.0596628300845623,
      0.019736086949706078,
      0.05558418110013008,
      0.054103732109069824,
      -0.008846836164593697,
      -0.06119617447257042,
      0.09223846346139908,
      0.004645800683647394,
      0.002361150225624442,
      -0.07466824352741241,
      0.023900387808680534,
      -0.02445368282496929,
      -0.056475088000297546,
      -0.05191916599869728,
      0.03224792703986168,
      -0.03523092344403267,
      -0.013335042633116245,
      -0.07600027322769165,
      -0.00872674211859703,
      -0.05767250061035156,
      -0.08164364844560623,
      -0.010833058506250381,
      0.0701969712972641,
      0.012330455705523491,
      0.009261340834200382,
      -0.03103814832866192,
      -0.024127651005983353,
      -0.04638208821415901,
      -0.01632677949965,
      0.04741145297884941,
      0.03098245896399021,
      -0.041202545166015625,
      -0.09988373517990112,
      -0.01164188887923956,
      0.10498487949371338,
      0.05900775268673897,
      -0.02769150771200657,
      0.013281268998980522,
      -0.019350070506334305,
      -0.10631893575191498,
      -0.06869226694107056,
      0.021473364904522896,
      -0.07247274369001389,
      -0.08426234126091003,
      0.0010277715045958757,
      0.004480586387217045,
      -0.06820071488618851,
      -0.024910200387239456,
      0.01557901781052351,
      0.006069643888622522,
      0.037800420075654984,
      0.004597784020006657,
      0.011194737628102303,
      -0.07003732770681381,
      -0.0316057987511158,
      0.02832200936973095,
      -0.023536833003163338,
      0.0319165363907814,
      -0.002605640096589923,
      -0.017713947221636772,
      0.056931283324956894,
      0.06116683781147003,
      -0.021649403497576714,
      0.01562662050127983,
      0.08946400880813599,
      -0.00027729629073292017,
      0.03753167390823364,
      0.037954676896333694,
      0.09266901016235352,
      -0.04847059026360512,
      -0.06409403681755066,
      -0.049301277846097946,
      -0.03382331505417824,
      0.0255038533359766,
      0.03208651766180992
    ],
    [
      -0.0023016375489532948,
      -0.009571289643645287,
      -0.02143217623233795,
      -0.05619724467396736,
      -0.060272734612226486,
      0.04642625153064728,
      -0.0499805323779583,
      -0.058858130127191544,
      0.024855181574821472,
      -0.04246463626623154,
      -0.003888216335326433,
      -0.04546504095196724,
      -0.05615450069308281,
      0.08025804907083511,
      -0.030467061325907707,
      0.02819879911839962,
      -0.04500789940357208,
      -0.06138549745082855,
      -0.0489024892449379,
      0.01843447797000408,
      -0.0306768249720335,
      0.04926508292555809,
      0.003537967335432768,
      0.05266609415411949,
      -0.01058924850076437,
      -0.029095536097884178,
      0.06518995016813278,
      -0.006101184990257025,
      -0.0853128582239151,
      0.04757067933678627,
      -0.05982077866792679,
      -0.04486073553562164,
      0.08951258659362793,
      0.006379208527505398,
      0.028413506224751472,
      -0.06323622167110443,
      -0.012942460365593433,
      0.02537471614778042,
      0.03392152860760689,
      -0.11412947624921799,
      -0.028715888038277626,
      0.04947960749268532,
      0.06328282505273819,
      -0.013170251622796059,
      0.03212185949087143,
      0.008948225528001785,
      -0.05540173873305321,
      -0.04152299091219902,
      -0.014214511029422283,
      0.06009204313158989,
      -0.029458696022629738,
      0.04796305671334267,
      0.04075751453638077,
      0.0006548562087118626,
      0.05569201335310936,
      -0.04071663320064545,
      -0.059001434594392776,
      -0.006168449763208628,
      -0.03017333149909973,
      0.035840488970279694,
      -0.07719961553812027,
      0.039233800023794174,
      0.023413673043251038,
      -0.005319155752658844,
      0.009512604214251041,
      0.018952248618006706,
      -0.020560825243592262,
      0.04117452725768089,
      -0.018427692353725433,
      -0.02368868701159954,
      0.008717045187950134,
      0.084062360227108,
      0.07577338069677353,
      -0.017535904422402382,
      -0.03916597738862038,
      0.0773860514163971,
      0.06284693628549576,
      0.020709248259663582,
      -0.08831658214330673,
      0.0076782661490142345,
      0.0358908548951149,
      0.04269983991980553,
      0.11374233663082123,
      -0.057574715465307236,
      -0.03930062800645828,
      0.05703026056289673,
      -0.1059630960226059,
      0.07067406177520752,
      -0.016643423587083817,
      0.08846639096736908,
      0.03919898718595505,
      0.01372083555907011,
      -0.003254936309531331,
      -0.019625073298811913,
      0.043219294399023056,
      -0.02592148631811142,
      0.013779712840914726,
      0.07340288907289505,
      0.05801670253276825,
      -0.09293419867753983,
      -0.005293788388371468,
      -0.061036642640829086,
      0.022414911538362503,
      0.060245633125305176,
      0.021259546279907227,
      -0.07950712740421295,
      0.02990359626710415,
      0.03605686128139496,
      0.12275055050849915,
      -0.027793094515800476,
      0.0060613714158535,
      0.07711595296859741,
      -0.05834265053272247,
      -0.010794909670948982,
      -0.03225657716393471,
      -0.038823433220386505,
      0.10598556697368622,
      -0.026991387829184532,
      0.03676240146160126,
      0.038784611970186234,
      -0.007771847303956747,
      0.004799260292202234,
      -0.02966577559709549,
      0.005742931738495827,
      -0.07505378872156143,
      -0.05396798253059387,
      0.00880739837884903,
      -0.0656232237815857,
      -0.024671215564012527,
      -0.03007989190518856,
      0.016450677067041397,
      0.06032630428671837,
      0.0065224748104810715,
      0.009862834587693214,
      -0.028503410518169403,
      -0.04538605362176895,
      -0.041163522750139236,
      -0.03673087805509567,
      -0.06960907578468323,
      -0.022163795307278633,
      0.0662863478064537,
      -0.03611817583441734,
      0.02432859130203724,
      0.028319472447037697,
      -0.04034647345542908,
      0.07830369472503662,
      -0.0030283769592642784,
      -0.03482251614332199,
      -0.02076667733490467,
      -0.06824517995119095,
      0.024686796590685844,
      -0.023091960698366165,
      0.06288474053144455,
      -0.008175106719136238,
      0.04447410628199577,
      -0.02252921089529991,
      -0.007246219553053379,
      -0.00796406902372837,
      0.048530519008636475,
      0.015521879307925701,
      -0.025050051510334015,
      0.014723519794642925,
      -0.0318874791264534,
      0.005960563663393259,
      0.02502121403813362,
      -0.052499692887067795,
      0.06596201658248901,
      0.04075454920530319,
      0.03278142586350441,
      -0.04718765988945961,
      0.07576915621757507,
      -0.02319902554154396,
      -0.041138943284749985,
      0.02167157270014286,
      0.0048333690501749516,
      -0.021996457129716873,
      -0.043202415108680725,
      0.044953394681215286,
      0.05738534778356552,
      0.04693307727575302,
      0.015591239556670189,
      -0.005218070931732655,
      0.09123077988624573,
      -0.006245579570531845,
      0.028465144336223602,
      0.036676954478025436,
      -0.007059971336275339,
      -0.021381473168730736,
      -0.018856622278690338,
      0.05856117978692055,
      0.03957211598753929,
      -0.03450687974691391,
      0.011903184466063976,
      0.044048842042684555,
      0.04741186276078224,
      0.01681973785161972,
      -0.006926525849848986,
      0.0441727451980114,
      0.007786588743329048,
      0.00993808638304472,
      0.06392519176006317,
      0.07006484270095825,
      -0.011225046589970589,
      -0.0989045798778534,
      -0.04017705097794533,
      0.0017306555528193712,
      -0.04560912773013115,
      0.032043490558862686,
      0.046574387699365616,
      0.006375022232532501,
      0.05269932001829147,
      0.06993541121482849,
      -0.08888749033212662,
      0.04518438130617142,
      0.015409886837005615,
      -0.06795912981033325,
      0.00513103511184454,
      0.057202715426683426,
      0.08106837421655655,
      -0.003389294957742095,
      -0.04796633496880531,
      -0.03377717733383179,
      0.052800726145505905,
      -0.023380650207400322,
      0.03871205076575279,
      -0.029646489769220352,
      0.06879812479019165,
      0.0652894526720047,
      0.056962959468364716,
      -0.05453302338719368,
      -0.012757377699017525,
      0.06752325594425201,
      -0.024374503642320633,
      -0.04666369780898094,
      0.002614442491903901,
      -0.048634596168994904,
      0.06737092137336731,
      -0.04958293214440346,
      0.085187628865242,
      0.015162769705057144,
      -0.04162170737981796,
      -0.04523320868611336,
      -0.08474528044462204,
      0.03728118911385536,
      0.05515176057815552,
      0.0521756187081337,
      -0.020495641976594925,
      -0.0476725809276104,
      -0.03607890009880066,
      0.028851527720689774,
      0.11415782570838928,
      0.0022892614360898733,
      -0.009777378290891647,
      0.04390998184680939,
      -0.04360021650791168,
      -0.017140256240963936,
      -0.028135154396295547,
      -0.012299002148211002,
      -0.009776531718671322,
      -0.01593240350484848,
      0.004149403888732195,
      -0.029721412807703018,
      -0.025205006822943687,
      0.005278617609292269,
      -0.04490065947175026,
      -0.04140815883874893,
      -0.012236399576067924,
      0.0275583416223526,
      0.07728762179613113,
      0.0944376066327095,
      -0.04250601679086685,
      0.0668056458234787,
      -0.05972873046994209,
      0.0418977215886116,
      -0.0034904510248452425,
      -0.003692636964842677,
      -0.0877651795744896,
      -0.017231974750757217,
      0.039659544825553894,
      -0.04746088758111,
      0.15081021189689636,
      0.10096420347690582,
      0.04806622117757797,
      0.0440194234251976,
      0.017294608056545258,
      0.02292688377201557,
      0.05019392445683479,
      -0.02770274691283703,
      0.03612449765205383,
      0.020225638523697853,
      0.0015469833742827177,
      0.0012801307020708919,
      -0.04400492459535599,
      -0.03359346464276314,
      0.04074098914861679,
      0.05438118427991867,
      -0.04928986355662346,
      0.03830669820308685,
      -0.009245665743947029,
      0.062320414930582047,
      -0.04916895553469658,
      -0.010174082592129707,
      0.016127247363328934,
      0.00836691353470087,
      -0.011981521733105183,
      -0.042544618248939514,
      -0.023937679827213287,
      -0.07636158168315887,
      -0.07854271680116653,
      -0.08738326281309128,
      -0.008018293417990208,
      -0.08224508166313171,
      -0.0039665657095611095,
      0.0108944121748209,
      -0.04517461732029915,
      0.013174448162317276,
      -0.04662436991930008,
      0.06147041916847229,
      0.08721087872982025,
      -0.05857188627123833,
      0.08475849032402039,
      0.05600268393754959,
      -0.014855260029435158,
      -0.03531235083937645,
      -0.023833028972148895,
      0.039122696965932846,
      0.019668761640787125,
      -0.032916899770498276,
      0.05201515182852745,
      -0.021619314327836037,
      -0.04358193650841713,
      0.04715941101312637,
      -0.04726085066795349,
      0.027365338057279587,
      0.011919463984668255,
      -0.020857660099864006,
      -0.07692992687225342,
      0.028961671516299248,
      -0.027936307713389397,
      -0.02583702653646469,
      0.008813275024294853,
      -0.030764369294047356,
      -0.01583397015929222,
      -0.02591632306575775,
      -0.07138300687074661,
      -0.04342273995280266,
      0.05092285946011543,
      -0.051115483045578,
      0.003775738412514329,
      -0.023142948746681213,
      -0.05905768647789955,
      -0.0014568924671038985,
      -0.02143274061381817,
      -0.05837123468518257,
      -0.01902650110423565,
      0.005684411618858576,
      -0.020254060626029968,
      0.03577803820371628,
      0.01440983172506094,
      -0.040093082934617996,
      0.058663249015808105,
      -0.032406341284513474,
      0.05207940191030502,
      -0.0620313324034214,
      0.0625767707824707,
      -0.0243737380951643,
      0.012581772170960903,
      -0.048601437360048294,
      0.017542697489261627,
      -0.03388483449816704,
      0.0632522776722908,
      0.02316051535308361,
      0.04224533215165138,
      -0.011930190026760101,
      -0.03350324556231499,
      -0.05180202051997185,
      0.0730603039264679,
      0.025272879749536514,
      0.009578289464116096,
      0.04239288717508316,
      0.09249414503574371,
      -0.09666396677494049,
      0.013915959745645523,
      -0.06398839503526688,
      -0.014398550614714622,
      0.025695256888866425,
      -0.018129682168364525,
      -0.04078688845038414,
      0.06348133832216263,
      -0.015345053747296333,
      -0.031113382428884506,
      0.04143598675727844,
      0.008479712530970573,
      0.02332150563597679,
      -0.022629914805293083,
      0.03604034334421158,
      0.009521396830677986,
      -0.011952388100326061,
      -0.07862411439418793,
      -0.06467992067337036,
      0.05609710514545441,
      -0.02659785933792591,
      -0.015148796141147614,
      -0.008377471938729286,
      -0.00893461611121893,
      0.007315872237086296,
      -0.014134383760392666,
      -0.02280377596616745,
      0.029973313212394714,
      -0.03167877346277237,
      0.03336089849472046,
      0.02498088963329792,
      -0.005512232892215252,
      -0.0565754733979702,
      0.002782154595479369,
      0.013813214376568794,
      0.04246984422206879,
      0.004678358323872089,
      0.018056198954582214,
      -0.024246109649538994,
      -0.016712643206119537,
      0.024578437209129333,
      -0.03846940025687218,
      0.001973542384803295,
      0.011335799470543861,
      -0.008363842032849789,
      0.05038759112358093,
      -0.10873012244701385,
      0.06763755530118942,
      -0.040651142597198486,
      -0.037487324327230453,
      -0.05389685556292534,
      0.039307329803705215,
      0.05525946244597435,
      -0.006693852599710226,
      0.03694036602973938,
      -0.002586993156000972,
      0.033690061420202255,
      0.008408959954977036,
      0.0028674646746367216,
      -0.02144939824938774,
      -0.029676010832190514,
      0.0059911999851465225,
      -0.013320421800017357,
      0.008465518243610859,
      -0.0729532316327095,
      -0.0033417344093322754,
      -0.044100042432546616,
      -0.00867506768554449,
      0.05044396221637726,
      0.0025155264884233475,
      -0.019476095214486122,
      0.10988962650299072,
      0.01673315465450287,
      0.049553319811820984,
      -0.07880866527557373,
      -0.021862195804715157,
      -0.030812332406640053,
      -0.07187470048666,
      -0.03190894052386284,
      -0.012319064699113369,
      -0.022363463416695595,
      0.026007147505879402,
      0.06394274532794952,
      0.030224118381738663,
      0.021828632801771164,
      -0.03167930990457535,
      -0.041259948164224625,
      0.022723188623785973,
      -0.0762462392449379,
      0.05131306126713753,
      -0.11298993229866028,
      -0.0061375852674245834,
      0.09122296422719955,
      0.04221484065055847,
      -0.060461338609457016,
      0.03691214323043823,
      -0.028676887974143028,
      -0.007627412211149931,
      -0.0029051448218524456,
      0.018510907888412476,
      -0.00998214166611433,
      -0.06261646747589111,
      -0.0020835751201957464,
      0.0056772842071950436,
      -0.048318829387426376,
      -0.007588008884340525,
      -0.0007100871298462152,
      0.026078276336193085,
      -0.031427059322595596,
      0.11172911524772644,
      0.01490701362490654,
      0.004739111289381981,
      0.013591006398200989,
      0.023563776165246964,
      0.015901172533631325,
      -0.028879603371024132,
      0.052120380103588104,
      -0.0812729001045227,
      -0.06844943761825562,
      0.05751281976699829,
      -0.03198321908712387,
      -0.03457259759306908
    ],
    [
      0.002825384493917227,
      0.024737920612096786,
      -0.05024782940745354,
      -0.01548819150775671,
      0.050672851502895355,
      0.012066632509231567,
      -0.017289817333221436,
      -0.036916375160217285,
      -0.042163554579019547,
      -0.041175372898578644,
      0.004283972550183535,
      0.06631514430046082,
      -0.01860050857067108,
      -0.008343661203980446,
      0.00554150203242898,
      0.028048135340213776,
      0.05360729247331619,
      -0.004722898360341787,
      -0.12371914833784103,
      -0.023683540523052216,
      0.010362622328102589,
      0.06301882863044739,
      0.02708183042705059,
      -0.005556945223361254,
      -0.025200646370649338,
      0.04459748417139053,
      0.032644543796777725,
      -0.021181292831897736,
      0.030314624309539795,
      -0.0008371124276891351,
      -0.004094672854989767,
      0.0037803284358233213,
      -0.07358065992593765,
      -0.04898950457572937,
      0.04465412348508835,
      -0.0732337161898613,
      0.031105250120162964,
      -0.027289001271128654,
      -0.0037798963021486998,
      -0.11899328976869583,
      0.008692910894751549,
      -0.02218078076839447,
      -0.029589902609586716,
      -0.019058311358094215,
      0.002485003788024187,
      -0.027840595692396164,
      -0.09390823543071747,
      0.005468083079904318,
      0.010022370144724846,
      -0.027256883680820465,
      0.055702466517686844,
      0.03647517040371895,
      0.00896279327571392,
      0.04446466267108917,
      0.03311117738485336,
      0.0065246457234025,
      0.04046441987156868,
      -0.09831525385379791,
      -0.01097451988607645,
      0.056101180613040924,
      -0.0037289292085915804,
      -0.04057005047798157,
      0.0036382123362272978,
      -0.010498655028641224,
      -0.08175278455018997,
      0.02086414024233818,
      -0.0025584865361452103,
      0.041324682533741,
      -0.04623635113239288,
      0.033704329282045364,
      -0.021993501111865044,
      -0.018653500825166702,
      -0.07590442150831223,
      -0.017560983076691628,
      0.016064459457993507,
      0.04153260588645935,
      0.006664441432803869,
      -0.0610082671046257,
      -0.00912303663790226,
      0.014350651763379574,
      0.06459704041481018,
      0.08601564913988113,
      -0.00791265070438385,
      0.03699204698204994,
      0.02761428989470005,
      -0.017922697588801384,
      0.07163528352975845,
      0.03863431140780449,
      0.03202921152114868,
      0.010796599090099335,
      -0.032187070697546005,
      0.011278165504336357,
      0.0258218701928854,
      0.011551390402019024,
      -0.036192264407873154,
      -0.025367101654410362,
      0.019830606877803802,
      0.0025267349556088448,
      0.021753305569291115,
      0.01970781572163105,
      0.006993350572884083,
      -0.0047225188463926315,
      -0.0014662134926766157,
      -0.05638433247804642,
      -0.027126869186758995,
      0.005467060953378677,
      -0.005943235009908676,
      0.015041805803775787,
      0.04277808219194412,
      -0.06254131346940994,
      0.034116558730602264,
      -0.04781709611415863,
      -0.0344509556889534,
      -0.03034006804227829,
      -0.04375269636511803,
      0.005506514571607113,
      -0.08380800485610962,
      0.016721995547413826,
      0.023933766409754753,
      -0.07461444288492203,
      0.08175013959407806,
      -0.014371988363564014,
      -0.04886521026492119,
      0.005921621341258287,
      -0.021796874701976776,
      -0.028335530310869217,
      0.03438999876379967,
      -0.032581672072410583,
      0.04919816181063652,
      0.004664857406169176,
      0.009550542570650578,
      0.045468930155038834,
      0.0017083818092942238,
      0.0066961911506950855,
      -0.019843216985464096,
      0.017056820914149284,
      0.023208480328321457,
      -0.04836767166852951,
      0.04209144040942192,
      -0.006036005448549986,
      -0.010974171571433544,
      0.08150279521942139,
      0.002797764027491212,
      0.07109562307596207,
      -0.05775497853755951,
      0.04734767973423004,
      0.015754936262965202,
      0.01620553433895111,
      0.04417022317647934,
      0.01903275027871132,
      -0.019690005108714104,
      0.035163357853889465,
      -0.060068532824516296,
      0.10361690819263458,
      -0.04078809544444084,
      -0.026832254603505135,
      0.028905823826789856,
      0.004293557722121477,
      -0.02225201576948166,
      -0.02331606298685074,
      -0.037551507353782654,
      0.04534413293004036,
      -0.020778756588697433,
      0.08076786249876022,
      0.0646849200129509,
      0.0634240061044693,
      0.05597049370408058,
      -0.005478084087371826,
      -0.016209298744797707,
      -0.07801100611686707,
      0.05189099535346031,
      0.001480319071561098,
      0.02254979871213436,
      0.01263656746596098,
      0.006266830954700708,
      -0.03931095078587532,
      -0.010035073384642601,
      -0.00571107491850853,
      0.02705480344593525,
      -0.016707805916666985,
      0.008662734180688858,
      -0.01865660771727562,
      0.10429080575704575,
      -0.0006374877411872149,
      0.06746414303779602,
      -0.02288365550339222,
      0.01647746004164219,
      0.029127854853868484,
      -0.042153213173151016,
      -0.048524629324674606,
      0.0457087978720665,
      -0.03814892843365669,
      -0.008663014508783817,
      0.05202683433890343,
      -0.010192951187491417,
      -0.05890481173992157,
      -0.032106008380651474,
      -0.030855663120746613,
      -0.04735557362437248,
      0.014199678786098957,
      -0.04785798117518425,
      -0.029386885464191437,
      -0.03789053484797478,
      -0.135413259267807,
      0.0035848557017743587,
      0.07829981297254562,
      -0.030298985540866852,
      0.047723639756441116,
      -0.015681959688663483,
      0.011677488684654236,
      -0.08051721006631851,
      0.04160201922059059,
      0.01799524389207363,
      0.05280652642250061,
      -0.06010935455560684,
      -0.002746211364865303,
      -0.005520413629710674,
      -0.036125022917985916,
      -0.03401244431734085,
      -0.08870879560709,
      0.02286873385310173,
      -0.00295246671885252,
      0.08228444308042526,
      -0.0030110201332718134,
      0.11045286804437637,
      0.04107239842414856,
      -0.02927066944539547,
      -0.047085363417863846,
      -0.02384905144572258,
      0.0033600819297134876,
      -0.06907922774553299,
      -0.03205297887325287,
      -0.04329104721546173,
      -0.05397465080022812,
      0.013185913674533367,
      -0.03961910307407379,
      -0.06241023167967796,
      0.0500001423060894,
      -0.001454621204175055,
      0.027316760271787643,
      -0.04217822104692459,
      -0.006330833770334721,
      0.017840716987848282,
      -0.01935228705406189,
      0.0235882680863142,
      0.0018834626534953713,
      -0.039815131574869156,
      0.02151455543935299,
      -0.004908846691250801,
      -0.09130538254976273,
      -0.01694193109869957,
      0.029417114332318306,
      0.010420207865536213,
      -0.03533614054322243,
      -0.06019984185695648,
      -0.012106179259717464,
      -0.011584889143705368,
      -0.04576911777257919,
      0.024565784260630608,
      -0.06543538719415665,
      -0.08526105433702469,
      -0.06424479931592941,
      0.03461432829499245,
      -0.02424587495625019,
      0.01005215011537075,
      0.05902628228068352,
      -0.052224595099687576,
      -0.02239297330379486,
      0.017675315961241722,
      0.06803672760725021,
      -0.004996014758944511,
      -0.013663221150636673,
      0.10674948990345001,
      0.01738395355641842,
      -0.0057174768298864365,
      -0.007708525750786066,
      -0.052804429084062576,
      -0.011920865625143051,
      -0.05750219523906708,
      0.014884233474731445,
      -0.0945187360048294,
      0.028571294620633125,
      0.052437953650951385,
      -0.0308682918548584,
      -0.06565475463867188,
      -0.007421053014695644,
      -0.01772613637149334,
      -0.024281205609440804,
      -0.034924816340208054,
      -0.04261768236756325,
      -0.03262262046337128,
      0.05756537988781929,
      0.07548677176237106,
      -0.03711225092411041,
      0.01867716573178768,
      0.06320179998874664,
      0.015316793695092201,
      0.030126698315143585,
      0.0392274484038353,
      0.002903795801103115,
      0.000863177003338933,
      -0.059829045087099075,
      0.00878668949007988,
      -0.022626882418990135,
      0.03361218795180321,
      0.06513170152902603,
      0.02366507239639759,
      0.0467650480568409,
      0.03693545609712601,
      -0.08757366240024567,
      0.10158742219209671,
      0.03727241978049278,
      -0.0711553767323494,
      -0.021385209634900093,
      -0.06398294866085052,
      0.015181920491158962,
      0.04525846615433693,
      0.11058390140533447,
      0.07308141887187958,
      0.007074971217662096,
      0.03100377321243286,
      0.010438693687319756,
      -0.07085824757814407,
      -0.002303308341652155,
      -0.0018967298092320561,
      0.05410592257976532,
      0.008726062253117561,
      -0.030826251953840256,
      0.023091230541467667,
      -0.05240829288959503,
      0.014939096756279469,
      -0.05890517309308052,
      0.015539298765361309,
      -0.019395899027585983,
      0.07641114294528961,
      0.041604042053222656,
      0.06634575128555298,
      0.024127047508955002,
      -0.06616708636283875,
      -0.048569295555353165,
      0.10677886009216309,
      0.041246283799409866,
      -0.011225971393287182,
      0.028487997129559517,
      -0.032112494111061096,
      0.06673920899629593,
      0.02563543990254402,
      -0.0090664466843009,
      0.05358491092920303,
      0.012360917404294014,
      -0.024596860632300377,
      0.034878719598054886,
      0.11558502167463303,
      0.008532106876373291,
      -0.017680983990430832,
      -0.007358820643275976,
      -0.02214847505092621,
      0.05362106114625931,
      -0.00879441574215889,
      -0.016398409381508827,
      -0.09054996073246002,
      0.04481297358870506,
      0.045618824660778046,
      0.0136066023260355,
      -0.02176613360643387,
      0.02571127563714981,
      0.08607342094182968,
      0.016517912968993187,
      0.06777819246053696,
      0.03591599687933922,
      0.011711963452398777,
      0.11218880116939545,
      0.012027913704514503,
      -0.0605735257267952,
      -0.0263746976852417,
      0.009549850597977638,
      -0.014558284543454647,
      0.031045280396938324,
      0.046323347836732864,
      0.024653378874063492,
      0.00972683634608984,
      -0.02059505507349968,
      0.05762571468949318,
      0.0030039462726563215,
      0.013874653726816177,
      0.008465276099741459,
      0.05962170660495758,
      -0.03695456683635712,
      -0.04755035787820816,
      -0.004770298488438129,
      0.08111514896154404,
      0.04745253920555115,
      0.03649976849555969,
      -0.0019269693875685334,
      0.05843376740813255,
      -0.0482349768280983,
      0.048418786376714706,
      0.018752118572592735,
      0.006546927150338888,
      0.04363139346241951,
      -0.029199637472629547,
      0.006647302769124508,
      0.034208837896585464,
      0.050096940249204636,
      -0.025929734110832214,
      -0.018693173304200172,
      0.023777712136507034,
      0.05821496248245239,
      0.07487229257822037,
      -0.05936666950583458,
      0.017297284677624702,
      0.04552232101559639,
      0.0628393292427063,
      0.07273904979228973,
      0.010620718821883202,
      0.011841454543173313,
      0.04027359560132027,
      -0.012857122346758842,
      -0.027605026960372925,
      -0.06907221674919128,
      -0.03299593925476074,
      -0.06340344250202179,
      0.03062380850315094,
      -0.07056225091218948,
      -0.0650356262922287,
      -0.027247963473200798,
      -0.09437553584575653,
      -0.1448822170495987,
      -0.02020956389605999,
      0.08821720629930496,
      -0.03780156001448631,
      0.0961211696267128,
      -0.06883382797241211,
      -0.07502482831478119,
      0.031146423891186714,
      -0.053042199462652206,
      0.026090286672115326,
      0.040553804486989975,
      0.04202753305435181,
      -0.03142235800623894,
      0.05000307410955429,
      -0.001997872721403837,
      0.01629265397787094,
      -0.05282393842935562,
      0.030209094285964966,
      -0.019016236066818237,
      0.0016172752948477864,
      0.008965677581727505,
      0.008359630592167377,
      0.0042293481528759,
      -0.05898510292172432,
      -0.03077797219157219,
      0.04960457980632782,
      0.0436963252723217,
      -0.008674880489706993,
      -0.033583953976631165,
      0.02426597848534584,
      -0.011200478300452232,
      0.033492688089609146,
      -0.025965282693505287,
      0.029024610295891762,
      -0.03821714594960213,
      -0.004136666189879179,
      0.052049607038497925,
      -0.008077902719378471,
      0.002139644930139184,
      0.0003576854651328176,
      0.07861173152923584,
      0.0724540427327156,
      0.010104367509484291,
      0.012593168765306473,
      0.04436667263507843,
      0.08087868243455887,
      -0.026740647852420807,
      0.014412637799978256,
      0.01635918952524662,
      0.031110694631934166,
      -0.03233281895518303,
      -0.028946753591299057,
      -0.026437778025865555,
      0.09363477677106857,
      -0.00591196259483695,
      -0.05398477241396904,
      0.0012031123042106628,
      -0.022346796467900276,
      0.03270776569843292,
      0.031154494732618332,
      0.007978388108313084,
      -0.10277047008275986,
      -0.02313442900776863,
      0.040676798671483994,
      -0.050730083137750626,
      0.006620605476200581,
      -0.07241648435592651,
      0.03894125670194626,
      0.029589645564556122,
      0.0036676370073109865,
      0.05874531343579292,
      -0.01903298869729042,
      0.029259027913212776,
      -0.06425145268440247,
      0.05275985598564148,
      0.03839336335659027
    ],
    [
      -0.04928579926490784,
      -0.0194484181702137,
      0.010868893004953861,
      -0.0373380221426487,
      -0.03186376020312309,
      -0.003304265206679702,
      -0.0029680891893804073,
      -0.017960388213396072,
      0.014685268513858318,
      0.012979859486222267,
      0.06231904774904251,
      -0.04424387589097023,
      0.027320876717567444,
      0.005688770674169064,
      -0.001035970519296825,
      0.05238528177142143,
      -0.05414513126015663,
      0.0171431303024292,
      0.00022108899429440498,
      -0.005299188196659088,
      -0.021095238626003265,
      0.010922057554125786,
      -0.012771128676831722,
      -0.11047829687595367,
      0.0597526878118515,
      0.02342727594077587,
      0.007050476036965847,
      -0.008916426450014114,
      -0.0034127533435821533,
      -0.046982377767562866,
      0.03952408209443092,
      -0.031063638627529144,
      0.09057988971471786,
      -0.02727297507226467,
      -0.006060625892132521,
      0.02524602599442005,
      -0.036054354161024094,
      0.002180231735110283,
      0.04834452271461487,
      -0.016283288598060608,
      0.023901110514998436,
      0.023406753316521645,
      0.004705384839326143,
      0.04073083773255348,
      0.026767494156956673,
      0.07373969256877899,
      0.04774532467126846,
      -0.008002160117030144,
      -0.08249956369400024,
      -0.01989927515387535,
      0.04826703667640686,
      -0.014940736815333366,
      -0.012353455647826195,
      -0.03491991013288498,
      -0.020982712507247925,
      0.03209871053695679,
      -0.028122959658503532,
      -0.05301259830594063,
      0.013018162921071053,
      0.10926792025566101,
      -0.06862682104110718,
      -0.03525787591934204,
      0.044593166559934616,
      -0.06575784832239151,
      0.09378651529550552,
      -0.024250134825706482,
      -0.06565981358289719,
      0.05088942125439644,
      0.018399890512228012,
      -0.014543617144227028,
      -0.029861299321055412,
      -0.002597571350634098,
      0.052291061729192734,
      0.009587534703314304,
      0.006248441990464926,
      -0.03451570123434067,
      0.043048471212387085,
      0.06325649470090866,
      0.06822381913661957,
      -0.029312992468476295,
      -0.023055341094732285,
      0.0469219796359539,
      0.014550418592989445,
      -0.03400905430316925,
      0.022644754499197006,
      -0.03116946667432785,
      -0.005295980721712112,
      -0.02138836681842804,
      -0.061175331473350525,
      0.04614372178912163,
      -0.036078110337257385,
      0.032010674476623535,
      0.060541730374097824,
      -0.03256978467106819,
      0.012591742910444736,
      0.03446768969297409,
      -0.035067103803157806,
      -0.025000663474202156,
      0.036160342395305634,
      0.04462699964642525,
      0.006257252302020788,
      0.07355518639087677,
      0.0014658723957836628,
      -0.033437784761190414,
      -0.07459880411624908,
      -0.0449293777346611,
      0.03660469129681587,
      -0.043681394308805466,
      0.021775109693408012,
      -0.08074776828289032,
      0.013624529354274273,
      -0.02815612405538559,
      -0.016135741025209427,
      0.012632296420633793,
      -0.018088825047016144,
      0.008119513280689716,
      0.10552911460399628,
      0.046522460877895355,
      -0.0509863942861557,
      0.03730146214365959,
      -0.011193212121725082,
      -0.0056023201905190945,
      0.034403130412101746,
      -0.0444059856235981,
      -0.0917779877781868,
      -0.0009239200735464692,
      -0.03709838166832924,
      0.10759182274341583,
      -0.06827531009912491,
      -0.09653037786483765,
      -0.01854044385254383,
      0.04976639524102211,
      -0.03422544524073601,
      -0.005337050184607506,
      -0.08032605797052383,
      0.030841121450066566,
      -0.045180369168519974,
      -0.0001720001600915566,
      0.04391650855541229,
      0.0006442334852181375,
      -0.007476692087948322,
      0.032192207872867584,
      -0.04261910915374756,
      0.06281121075153351,
      0.06854942440986633,
      0.02774883061647415,
      -0.003072451800107956,
      0.04525872319936752,
      -0.026665575802326202,
      -0.0018947395728901029,
      0.009198363870382309,
      -0.04164945334196091,
      0.035272520035505295,
      0.03356807678937912,
      -0.0760209932923317,
      -0.026057567447423935,
      -0.008615556173026562,
      0.0501541942358017,
      -0.0455661416053772,
      0.020508188754320145,
      -0.042938318103551865,
      0.04146299511194229,
      0.0527227520942688,
      -0.02331915870308876,
      -0.04098954796791077,
      -0.08533436805009842,
      0.0398455411195755,
      -0.009545661509037018,
      -0.02664385735988617,
      -0.08477786928415298,
      -0.007569137029349804,
      0.05524691194295883,
      -0.054073695093393326,
      -0.08515207469463348,
      -0.09199108183383942,
      0.016196100041270256,
      0.07245757430791855,
      0.022439492866396904,
      0.010397694073617458,
      -0.060611478984355927,
      -0.04044441506266594,
      -0.06065628305077553,
      -0.07084982097148895,
      0.0644112154841423,
      -0.026042073965072632,
      0.050494108349084854,
      -0.028759168460965157,
      0.077553391456604,
      -0.04160588979721069,
      0.02332114800810814,
      -0.002677023643627763,
      -0.004318418446928263,
      0.035657938569784164,
      -0.050102878361940384,
      -0.041467271745204926,
      0.015981988981366158,
      0.00824976246803999,
      -0.024012254551053047,
      -0.03364968299865723,
      0.02448795922100544,
      0.07691919058561325,
      -0.004918904509395361,
      0.07252276688814163,
      -0.056976646184921265,
      0.060740016400814056,
      -0.06785161793231964,
      -0.03760595992207527,
      0.06686447560787201,
      -0.0009867219487205148,
      0.005930914077907801,
      -0.030911244451999664,
      0.007785263005644083,
      0.023815900087356567,
      0.04759977385401726,
      -0.06488166004419327,
      -0.035762254148721695,
      0.013161572627723217,
      -0.015308971516788006,
      -0.0325826071202755,
      -0.0805368423461914,
      0.011931786313652992,
      0.014434107579290867,
      0.003939818125218153,
      -0.05344187095761299,
      -0.006857389118522406,
      -0.043053291738033295,
      0.04065424203872681,
      0.004807302262634039,
      0.03944011032581329,
      0.010123543441295624,
      0.03830330818891525,
      -0.11106220632791519,
      0.06703498959541321,
      -0.04804614186286926,
      -0.023235950618982315,
      0.04392996430397034,
      0.04783862456679344,
      0.018859343603253365,
      0.03049640730023384,
      0.021719111129641533,
      0.029608504846692085,
      0.01593616232275963,
      0.012787469662725925,
      0.014370384626090527,
      0.01253735926002264,
      0.05781278386712074,
      0.010019037872552872,
      -0.04964785650372505,
      -0.05344786494970322,
      -0.015274904668331146,
      0.0756462886929512,
      0.0020248109940439463,
      0.022475212812423706,
      -0.03507301211357117,
      0.059842050075531006,
      -0.06016654893755913,
      0.024011775851249695,
      0.02042563445866108,
      -0.0447724424302578,
      0.01600445806980133,
      0.0017994757508859038,
      0.008565484546124935,
      0.011924413032829762,
      0.108815997838974,
      -0.03741159290075302,
      -0.019058190286159515,
      -0.052048198878765106,
      0.007898349314928055,
      -0.041070226579904556,
      -0.005951287690550089,
      -0.02100578136742115,
      -0.02674618922173977,
      -0.06960273534059525,
      -0.003098329296335578,
      -0.02142513170838356,
      0.04927146062254906,
      -0.05127204209566116,
      -0.038875650614500046,
      0.03287889435887337,
      -0.03294512256979942,
      0.059269979596138,
      0.024877497926354408,
      0.03774683177471161,
      0.028729869052767754,
      0.038901668041944504,
      0.027202099561691284,
      -0.021691711619496346,
      -0.014012660831212997,
      -0.09251987934112549,
      0.0025134964380413294,
      -0.022501692175865173,
      0.09340706467628479,
      0.03492468222975731,
      -0.006353976204991341,
      0.0026500229723751545,
      0.026346202939748764,
      -0.022368304431438446,
      -0.09056469798088074,
      -0.020319100469350815,
      0.04595867916941643,
      -0.01909325271844864,
      0.046084631234407425,
      0.04268307238817215,
      0.008124996908009052,
      0.04300073906779289,
      -0.01776508428156376,
      -0.017746098339557648,
      -0.018790224567055702,
      -0.05243147909641266,
      0.009822836145758629,
      0.012991995550692081,
      0.037041593343019485,
      -0.03450648859143257,
      0.022149890661239624,
      -0.02437748946249485,
      -0.06302844732999802,
      0.019859932363033295,
      0.0671444833278656,
      0.05782343074679375,
      -0.021086912602186203,
      0.00946024525910616,
      -0.02269551530480385,
      -0.07750238478183746,
      -7.126581476768479e-05,
      0.0014992033829912543,
      0.06735406816005707,
      0.04264077544212341,
      -0.008790913969278336,
      0.06947663426399231,
      0.0711052194237709,
      0.02437594346702099,
      -0.0019038121681660414,
      -0.008016848936676979,
      0.027766108512878418,
      -0.024303032085299492,
      0.0114436075091362,
      0.012878299690783024,
      0.14366702735424042,
      -0.009440830908715725,
      0.012769111432135105,
      -0.005588741507381201,
      0.040370650589466095,
      -0.030833739787340164,
      0.10622186958789825,
      0.0999925285577774,
      -0.047656379640102386,
      -0.010441707447171211,
      0.10490765422582626,
      0.02486012503504753,
      0.05464474484324455,
      -0.0715268924832344,
      -0.027080466970801353,
      0.06794344633817673,
      -0.04860454797744751,
      -0.05782362446188927,
      0.022688861936330795,
      -0.007743061054497957,
      0.026695959270000458,
      0.042176079005002975,
      0.00853149127215147,
      -0.00036223960341885686,
      -0.02480206824839115,
      -0.041407808661460876,
      0.04208365082740784,
      -0.011914395727217197,
      0.060252100229263306,
      -0.05483027920126915,
      0.06128605082631111,
      0.010609864257276058,
      -0.05912919342517853,
      -0.00779753876850009,
      0.08707784116268158,
      0.03809361159801483,
      0.03184041008353233,
      0.00040670495945960283,
      -0.02960861474275589,
      -0.042940035462379456,
      -0.07457311451435089,
      0.03810753673315048,
      -0.019640401005744934,
      0.03857528790831566,
      -0.061228543519973755,
      -0.09759140759706497,
      -0.0356585793197155,
      0.030385999009013176,
      0.009766030125319958,
      0.06658750027418137,
      -0.015324424020946026,
      0.06971675902605057,
      0.027551276609301567,
      -0.10454892367124557,
      0.007132011465728283,
      -0.0028871260583400726,
      -0.02101711370050907,
      -0.00022765237372368574,
      0.052556078881025314,
      0.026639118790626526,
      0.0031497087329626083,
      0.042578861117362976,
      0.01919899508357048,
      -0.029996167868375778,
      -0.09244518727064133,
      -0.015879523009061813,
      0.09101241081953049,
      -0.014522148296236992,
      -0.010743770748376846,
      0.05461984872817993,
      -0.04761701449751854,
      0.06705214083194733,
      0.02514876052737236,
      0.0196591354906559,
      -0.02792593091726303,
      0.013724549673497677,
      -0.06639504432678223,
      -0.06959988921880722,
      0.007067038211971521,
      -0.07338006794452667,
      -0.04737582802772522,
      0.06497134268283844,
      -0.036279868334531784,
      -0.014511069282889366,
      0.004362981300801039,
      -0.02384372241795063,
      -0.012237999588251114,
      -0.010835142806172371,
      -0.008683607913553715,
      0.02061617560684681,
      0.03406929224729538,
      0.00043603009544312954,
      0.018260791897773743,
      0.05934373661875725,
      -0.02116156928241253,
      0.013422072865068913,
      -0.008860177360475063,
      -0.0031291604973375797,
      -0.05270492658019066,
      -0.07753892242908478,
      0.01811322383582592,
      0.056386370211839676,
      0.04105950891971588,
      -0.0011045479914173484,
      0.003674672916531563,
      -0.07020102441310883,
      0.0729139968752861,
      0.011580645106732845,
      -0.011880606412887573,
      0.052559949457645416,
      -0.04694954305887222,
      0.004973599221557379,
      -0.013765397481620312,
      -0.07745383679866791,
      -0.04852437600493431,
      0.06516650319099426,
      -0.02052070014178753,
      -0.030734851956367493,
      -0.09878431260585785,
      0.05145232379436493,
      0.06678570061922073,
      -0.03242648392915726,
      0.060995180159807205,
      0.0538964569568634,
      -0.005716949701309204,
      -0.03572409227490425,
      0.05593876913189888,
      -0.005035205744206905,
      0.10064246505498886,
      -0.008056294173002243,
      0.04278535768389702,
      -0.08040650188922882,
      0.06425491720438004,
      -0.04840840399265289,
      -0.03641128912568092,
      -0.007568461820483208,
      0.1073257252573967,
      0.02985183149576187,
      0.03987549990415573,
      0.012429646216332912,
      0.049450889229774475,
      -0.06321194022893906,
      -0.026580240577459335,
      0.008171004243195057,
      0.050061870366334915,
      -0.014013995416462421,
      -0.07821696996688843,
      -0.032824259251356125,
      -0.036363694816827774,
      -0.04009565711021423,
      0.004952239338308573,
      0.02812700718641281,
      -0.010010872036218643,
      0.042640913277864456,
      -0.017979340627789497,
      0.07742809504270554,
      0.018407048657536507,
      -0.08288035541772842,
      -0.029052063822746277,
      -0.05033429339528084,
      0.01455003209412098,
      0.004678208380937576,
      -0.0747242420911789,
      0.023238833993673325,
      0.05417827516794205,
      -0.026230566203594208
    ],
    [
      0.03366406261920929,
      0.0592869333922863,
      -0.034295860677957535,
      -0.013307025656104088,
      -0.0711628720164299,
      0.028828494250774384,
      0.08948839455842972,
      -0.005038547795265913,
      0.0264821108430624,
      -0.01446493249386549,
      0.13123972713947296,
      -0.047262921929359436,
      -0.06758851557970047,
      -0.013946068473160267,
      -0.010397275909781456,
      -0.014763670973479748,
      -0.027477703988552094,
      0.11130673438310623,
      -0.039212219417095184,
      -0.0390002466738224,
      -0.024134226143360138,
      0.02888866700232029,
      0.04412958770990372,
      -0.04773051664233208,
      0.01485364604741335,
      -0.037966109812259674,
      -0.029789265245199203,
      0.012033063918352127,
      -0.027315432205796242,
      0.07187896221876144,
      -0.019608020782470703,
      -0.024807006120681763,
      0.1282445341348648,
      0.03456709906458855,
      0.006164797116070986,
      -0.037962764501571655,
      0.009430852718651295,
      0.0030292749870568514,
      -0.04308584704995155,
      -0.029056673869490623,
      0.0026308586820960045,
      0.004052144940942526,
      -0.05935957282781601,
      0.04098612815141678,
      0.037680190056562424,
      0.06331399083137512,
      0.03382229432463646,
      0.040517814457416534,
      0.010759780183434486,
      -0.00858060922473669,
      -0.0047263712622225285,
      -0.005907810293138027,
      0.07011878490447998,
      0.02136911079287529,
      0.01478328462690115,
      0.06995892524719238,
      -0.04938117042183876,
      0.015295790508389473,
      -0.03096596896648407,
      0.013566090725362301,
      -0.03398094326257706,
      0.006636084523051977,
      -0.0035960557870566845,
      -0.08779501169919968,
      0.03342979773879051,
      -0.008641600608825684,
      -0.03494156152009964,
      0.03551245853304863,
      0.007367943413555622,
      -0.006367708556354046,
      -0.018643949180841446,
      -0.007993163540959358,
      -0.00391359580680728,
      0.03188202902674675,
      -0.028475485742092133,
      -0.11672967672348022,
      0.10616710782051086,
      0.06943075358867645,
      -0.0077736228704452515,
      0.00921024102717638,
      0.031746163964271545,
      -0.05781522020697594,
      0.04552068933844566,
      -0.0013325298205018044,
      -0.005240762606263161,
      0.06646943092346191,
      -0.007441665977239609,
      0.03772052377462387,
      -0.0356346033513546,
      0.019984619691967964,
      -0.007234788499772549,
      -0.027639465406537056,
      -0.0031438213773071766,
      0.037176355719566345,
      -0.00922174658626318,
      -0.030426451936364174,
      0.014522768557071686,
      0.0007082757074385881,
      0.012319134548306465,
      -0.014824913814663887,
      -0.05241716280579567,
      0.025426413863897324,
      0.054059483110904694,
      -0.03870849311351776,
      -0.03327920287847519,
      -0.00024170220422092825,
      0.06199910491704941,
      -0.07476897537708282,
      0.02702103927731514,
      0.07011815905570984,
      -0.07342807203531265,
      -0.02095278538763523,
      -0.02116900496184826,
      -0.008211495354771614,
      -0.021033059805631638,
      0.0027552256360650063,
      -0.03828604519367218,
      0.047857239842414856,
      0.035307932645082474,
      0.027749568223953247,
      0.09029245376586914,
      0.07463186234235764,
      -0.02012087218463421,
      -0.010752514936029911,
      -0.05233064666390419,
      -0.018872186541557312,
      0.0065733264200389385,
      0.049068301916122437,
      0.021333033218979836,
      -0.04562307894229889,
      -0.01918165571987629,
      -0.01854613609611988,
      0.040310103446245193,
      0.042395636439323425,
      0.025021379813551903,
      -0.01533390674740076,
      -0.040883343666791916,
      0.058370962738990784,
      0.018284281715750694,
      -0.010228779166936874,
      0.05832042545080185,
      0.03769074007868767,
      -0.057166725397109985,
      0.020177308470010757,
      -0.05015112832188606,
      -0.055763594806194305,
      0.019287627190351486,
      -0.04377591237425804,
      -0.01980627328157425,
      -0.02063724771142006,
      -0.06181875988841057,
      0.009029247798025608,
      0.0739067867398262,
      0.011105960234999657,
      -0.03246695548295975,
      -0.04215385019779205,
      -0.039752047508955,
      -0.025569433346390724,
      -0.07680455595254898,
      -0.007512159179896116,
      0.01530782412737608,
      -0.01710749790072441,
      0.05908801034092903,
      0.025546835735440254,
      0.08550342917442322,
      -0.020752040669322014,
      -0.0151401711627841,
      -0.06859477609395981,
      0.001769326627254486,
      0.06796636432409286,
      0.013124307617545128,
      0.05602303147315979,
      0.073509082198143,
      -0.018985416740179062,
      -0.0030279287602752447,
      0.029914403334259987,
      -0.028080690652132034,
      0.04448653385043144,
      -0.029265517368912697,
      -0.03446369618177414,
      -0.02101193740963936,
      -0.02928040735423565,
      -0.066514752805233,
      -0.05420699343085289,
      0.005570795387029648,
      0.0037305487785488367,
      -0.012318756431341171,
      -0.07564931362867355,
      0.0529303215444088,
      -0.002872633980587125,
      0.015223463997244835,
      -0.010871034115552902,
      -0.02898411825299263,
      0.04970581829547882,
      -0.08595894277095795,
      0.07458581775426865,
      0.012005452997982502,
      0.00337844411842525,
      -0.015239077620208263,
      0.057650309056043625,
      -0.008112742565572262,
      -0.01332248654216528,
      0.08091569691896439,
      -0.08139286190271378,
      -0.0052777184173464775,
      -0.04470286890864372,
      -0.02712620608508587,
      -0.0822102501988411,
      0.031299326568841934,
      -0.08207457512617111,
      -0.030919848009943962,
      0.05158638209104538,
      0.06139443442225456,
      0.05184943601489067,
      -0.025084832683205605,
      0.00679698446765542,
      0.020460763946175575,
      -0.025699475780129433,
      -0.010697778314352036,
      0.07582251727581024,
      0.015505346469581127,
      -0.020148184150457382,
      -0.01110359188169241,
      0.04253083094954491,
      0.015219285152852535,
      0.033618804067373276,
      0.0002090355264954269,
      0.008502031676471233,
      0.053870316594839096,
      -0.031813349574804306,
      -0.04214097931981087,
      -0.008676804602146149,
      -0.021376678720116615,
      -0.029065364971756935,
      -0.051352959126234055,
      -0.014711406081914902,
      -0.00870816595852375,
      0.0034078340977430344,
      0.024172179400920868,
      -0.030715733766555786,
      -0.026369541883468628,
      -0.02773115411400795,
      -0.04602712765336037,
      -0.009518327191472054,
      -0.0067436364479362965,
      0.08915241062641144,
      -0.05763640254735947,
      0.018791237846016884,
      -0.025803951546549797,
      0.039238154888153076,
      0.0336582325398922,
      0.004037036094814539,
      -0.0056416247971355915,
      0.020550617948174477,
      -0.07425061613321304,
      0.0461428202688694,
      0.005614797584712505,
      -0.04304838925600052,
      0.008622807450592518,
      0.03595941513776779,
      -0.07659698277711868,
      -0.09331050515174866,
      0.001954116625711322,
      -0.04954824596643448,
      -0.020219769328832626,
      0.0760774314403534,
      0.027022117748856544,
      0.1282140463590622,
      0.04444371536374092,
      -0.01069264579564333,
      -0.11905556172132492,
      0.06006743386387825,
      -0.04763312637805939,
      -0.060506194829940796,
      -0.05629779398441315,
      -0.030952634289860725,
      -0.014211850240826607,
      -0.09873697906732559,
      -0.030219588428735733,
      0.07194294780492783,
      0.07203212380409241,
      0.04819134622812271,
      0.0018495010444894433,
      -0.011898132972419262,
      0.03649257495999336,
      0.06495360285043716,
      0.0010035528102889657,
      0.028905317187309265,
      0.00485965795814991,
      0.07284677028656006,
      -0.027115482836961746,
      -0.028347468003630638,
      -0.022951262071728706,
      0.05803222954273224,
      0.051884133368730545,
      -0.010925492271780968,
      0.03797759860754013,
      -0.016024643555283546,
      0.03544049710035324,
      0.06298539787530899,
      0.016961872577667236,
      -0.03205027058720589,
      -0.0020504924468696117,
      -0.037174250930547714,
      -0.006152674555778503,
      -0.007123265881091356,
      -0.06843680888414383,
      -0.010648736730217934,
      0.04626818373799324,
      0.000189909158507362,
      0.037298452109098434,
      -0.010407207533717155,
      -0.0430632159113884,
      -0.010531621985137463,
      -0.04791403189301491,
      -0.07161137461662292,
      0.002343300497159362,
      0.03302004560828209,
      0.02444930374622345,
      0.014333056285977364,
      0.01747918128967285,
      -0.05449546501040459,
      0.026349717751145363,
      0.0467103011906147,
      0.03796819970011711,
      -0.0237168837338686,
      -0.019070476293563843,
      -0.023080339655280113,
      -0.10554952174425125,
      0.09624454379081726,
      0.02569153718650341,
      0.04481023922562599,
      0.033532366156578064,
      0.07107026129961014,
      -0.07840941846370697,
      0.016925735399127007,
      -0.009563068859279156,
      0.0024420912377536297,
      -0.05810780078172684,
      -0.036061663180589676,
      -0.00543447770178318,
      -0.06246277317404747,
      0.018007606267929077,
      -0.044403064996004105,
      0.029380571097135544,
      0.029057130217552185,
      -0.021220698952674866,
      -0.015187148936092854,
      0.01951705478131771,
      -0.013618199154734612,
      0.023059308528900146,
      -0.09267953783273697,
      -0.08260446041822433,
      -0.023575833067297935,
      0.11505874246358871,
      0.11748988926410675,
      0.03642087057232857,
      -0.038926489651203156,
      -0.01650099828839302,
      -0.03330497443675995,
      -0.018050936982035637,
      -0.001019699266180396,
      0.0699237585067749,
      0.02188616245985031,
      -0.0025785204488784075,
      0.08108976483345032,
      -0.0434693917632103,
      -0.04178690165281296,
      0.013307915069162846,
      -0.007234142627567053,
      0.003916995599865913,
      -0.0036683655343949795,
      -0.09834591299295425,
      0.03145555779337883,
      0.01124632265418768,
      -0.01786605454981327,
      -0.016945181414484978,
      -0.037443049252033234,
      0.02473178692162037,
      -0.08899519592523575,
      0.0513344407081604,
      -0.010518987663090229,
      -0.04526495561003685,
      0.0824890211224556,
      0.046904973685741425,
      0.04490788280963898,
      0.029858211055397987,
      -0.0007650663028471172,
      -0.014923899434506893,
      0.06709367036819458,
      -0.004996857605874538,
      -0.05467195808887482,
      0.02049928344786167,
      -0.03992858901619911,
      -0.03610818460583687,
      -0.07636574655771255,
      -0.013442237861454487,
      0.10181795060634613,
      -0.05627463757991791,
      -0.019132304936647415,
      0.04859026148915291,
      -0.05282237008213997,
      -0.08848799020051956,
      -0.007745923474431038,
      0.05412619560956955,
      0.018830902874469757,
      -0.0339742936193943,
      -0.07735415548086166,
      -0.0036009426694363356,
      0.02410227619111538,
      -0.04419031739234924,
      -0.01653827168047428,
      -0.018673613667488098,
      -0.08015173673629761,
      -0.053131259977817535,
      0.127549409866333,
      0.006284572649747133,
      -0.09258140623569489,
      0.03336932510137558,
      -0.04639071598649025,
      0.010722678154706955,
      -0.02889801561832428,
      0.010451778769493103,
      -0.09451095014810562,
      -0.0259335208684206,
      0.08672146499156952,
      -0.026311226189136505,
      -0.0036656532902270555,
      0.047942694276571274,
      -0.0459439717233181,
      -0.050292354077100754,
      -0.12587015330791473,
      0.11579909920692444,
      -0.022219616919755936,
      -0.009337770752608776,
      -0.043749891221523285,
      0.034312713891267776,
      -0.019856100901961327,
      0.013891290873289108,
      -0.05587088689208031,
      0.01644212380051613,
      0.05168040841817856,
      -0.040474407374858856,
      0.005556365940719843,
      -0.061274345964193344,
      -0.028881965205073357,
      -0.10045108199119568,
      -0.017902540042996407,
      0.062141161412000656,
      -0.01553074736148119,
      -0.043266527354717255,
      -0.08465073257684708,
      -0.03865977004170418,
      0.042453400790691376,
      -0.01947350800037384,
      0.022854622453451157,
      0.032790571451187134,
      0.023079706355929375,
      0.03865499421954155,
      -0.011868011206388474,
      -0.022354044020175934,
      0.009126441553235054,
      0.01828562654554844,
      -0.03236471116542816,
      -0.022044697776436806,
      -0.006835584063082933,
      -0.035691484808921814,
      0.018265826627612114,
      -0.011807085946202278,
      0.04611017554998398,
      0.008093805983662605,
      0.03365756571292877,
      0.04075833782553673,
      0.007735290564596653,
      0.007208165246993303,
      0.01037610787898302,
      -0.011036861687898636,
      -0.03796941414475441,
      0.011378845199942589,
      0.052428390830755234,
      -0.023288091644644737,
      0.005493016913533211,
      0.007004634011536837,
      -0.005608541425317526,
      0.03697316721081734,
      -0.06422875076532364,
      -0.008651809766888618,
      -0.01766764186322689,
      0.005131949204951525,
      0.03142162412405014,
      0.007727250922471285,
      -0.0007785108755342662,
      0.02296474203467369,
      0.033278509974479675,
      -0.12342885881662369,
      -0.040119607001543045,
      0.02351178601384163,
      -0.03112845867872238,
      0.04885721951723099,
      -0.06868385523557663,
      -0.033269889652729034,
      0.09274914860725403,
      0.003980923444032669
    ],
    [
      0.001586589147336781,
      -0.007270658388733864,
      -0.056993428617715836,
      0.033732540905475616,
      0.023089049383997917,
      0.04279479756951332,
      0.07665960490703583,
      0.014653327874839306,
      -0.04426776245236397,
      -0.02645176462829113,
      0.041754648089408875,
      -0.03619587421417236,
      -0.030244601890444756,
      -0.06184963509440422,
      -0.02449268288910389,
      -0.019388249143958092,
      0.05751490965485573,
      0.02187691070139408,
      -0.012695791199803352,
      -0.05873076990246773,
      -0.04118625819683075,
      -0.03424406424164772,
      0.02411162666976452,
      0.08425922691822052,
      0.02330123446881771,
      0.04395350068807602,
      0.05182068049907684,
      -0.0017791541758924723,
      0.0369444414973259,
      0.049990538507699966,
      -0.016435962170362473,
      -0.007825654931366444,
      0.09040390700101852,
      0.020205087959766388,
      -0.06334005296230316,
      -0.0404960922896862,
      0.010317904874682426,
      -0.016928011551499367,
      0.010366247035562992,
      -0.06232883408665657,
      0.042568497359752655,
      -0.018206732347607613,
      -0.00461612781509757,
      0.03426853567361832,
      0.03777546435594559,
      -0.03776349872350693,
      0.015406360849738121,
      0.01450123731046915,
      -0.06426417827606201,
      -0.03132598102092743,
      -0.021072620525956154,
      -0.036557432264089584,
      0.003274431684985757,
      0.03841124102473259,
      0.019757123664021492,
      0.07176079601049423,
      -0.03653474152088165,
      -0.028286293148994446,
      0.033019986003637314,
      -0.08780129253864288,
      0.023608043789863586,
      -0.0845302939414978,
      -0.01882084272801876,
      -0.042096931487321854,
      0.10138916969299316,
      -0.05225718766450882,
      0.02822888270020485,
      -0.018763672560453415,
      0.02552391029894352,
      0.026877503842115402,
      0.036438073962926865,
      -0.06225626915693283,
      -0.012297974899411201,
      0.007531515322625637,
      0.034978706389665604,
      0.004113421309739351,
      0.0724252238869667,
      0.03201601654291153,
      -0.023624487221240997,
      -0.033156756311655045,
      0.0040155746974051,
      0.03269783779978752,
      0.05362993851304054,
      0.004650009796023369,
      0.05667559430003166,
      0.05577670782804489,
      -0.04491298273205757,
      -0.041591476649045944,
      -0.05040774866938591,
      -0.019645920023322105,
      -0.03314704820513725,
      0.029553767293691635,
      -0.09848395735025406,
      0.006971824914216995,
      -0.02014867775142193,
      -0.01609487272799015,
      -0.006717592012137175,
      0.033152688294649124,
      0.009405624121427536,
      0.06982515007257462,
      0.05375630408525467,
      -0.012760779820382595,
      0.028759002685546875,
      -0.049550797790288925,
      0.0032354684080928564,
      0.015245771035552025,
      0.04739050194621086,
      -0.025399968028068542,
      0.01620120368897915,
      -0.014693203382194042,
      0.027502231299877167,
      0.06802508980035782,
      -0.020631320774555206,
      0.028662219643592834,
      0.00033984854235313833,
      -0.0690918117761612,
      0.003927397541701794,
      0.042435262352228165,
      -0.02239682897925377,
      0.032899409532547,
      0.0710221379995346,
      -0.018633050844073296,
      0.017393477261066437,
      0.01094147376716137,
      0.02629246935248375,
      0.0023075509816408157,
      0.038682084530591965,
      0.013620237819850445,
      -0.03069940023124218,
      -0.06377178430557251,
      -0.07539937645196915,
      -0.05338535085320473,
      0.01486621517688036,
      0.0020724330097436905,
      -0.002861500484868884,
      0.0282584335654974,
      0.0801037847995758,
      -0.008653305470943451,
      -0.057147156447172165,
      -0.013000636361539364,
      0.039785441011190414,
      -0.07266873121261597,
      0.01625967212021351,
      -0.05514072999358177,
      -0.03522927686572075,
      0.07311789691448212,
      -9.376040543429554e-05,
      -0.03964652493596077,
      0.01653869077563286,
      -0.015638090670108795,
      0.012466929852962494,
      -0.01858414337038994,
      -0.02563336119055748,
      -0.01623300090432167,
      -0.11406142264604568,
      -0.021328015252947807,
      -0.043623797595500946,
      0.026747440919280052,
      -0.015033751726150513,
      0.026748081669211388,
      -0.016438663005828857,
      -0.03244120255112648,
      0.00933597981929779,
      0.01586965285241604,
      -0.0017572717042639852,
      -0.005663672927767038,
      -0.013197950087487698,
      0.048365186899900436,
      0.055057309567928314,
      -0.004627994727343321,
      -0.0011202520690858364,
      -0.06011735647916794,
      -0.08364163339138031,
      -0.056788500398397446,
      -0.052511200308799744,
      0.030835673213005066,
      -0.03235260397195816,
      0.02350301668047905,
      0.037028178572654724,
      -0.017271162942051888,
      -0.046677619218826294,
      -0.011316654272377491,
      0.011628574691712856,
      -0.07043158262968063,
      0.012977132573723793,
      -0.023819994181394577,
      -0.11642441153526306,
      -0.06603889912366867,
      0.07910602539777756,
      0.0174409169703722,
      0.030142517760396004,
      0.015514601953327656,
      0.011480721645057201,
      -0.0023872731253504753,
      -0.023668665438890457,
      -0.03652452677488327,
      0.05743737518787384,
      0.0501985028386116,
      -0.042827531695365906,
      -0.0701032355427742,
      0.01911717653274536,
      0.03352103382349014,
      -0.008147608488798141,
      0.006430544890463352,
      0.008806699886918068,
      0.03363290801644325,
      -0.0197654590010643,
      0.037852853536605835,
      -0.06313561648130417,
      0.040550388395786285,
      0.01686856336891651,
      0.03334220498800278,
      -0.012630523182451725,
      0.09131375700235367,
      -0.0366998165845871,
      -0.043956831097602844,
      0.059964414685964584,
      0.01624155044555664,
      -0.023859836161136627,
      0.010836513713002205,
      -0.006670745089650154,
      -0.02667299658060074,
      0.07733382284641266,
      0.021708572283387184,
      0.04635339230298996,
      -0.007836849428713322,
      0.05865446478128433,
      0.020006705075502396,
      0.07905604690313339,
      0.057723525911569595,
      0.0474245660007,
      0.07426658272743225,
      -0.02713834121823311,
      0.03325197473168373,
      -0.006799518130719662,
      -0.01796584576368332,
      0.01639917679131031,
      0.04683052748441696,
      -0.014809145592153072,
      0.03177458420395851,
      -0.035389479249715805,
      -0.008272578939795494,
      0.01429004967212677,
      0.08538626879453659,
      0.023991556838154793,
      -0.0638943463563919,
      0.03383587673306465,
      0.03511857986450195,
      -0.015574021264910698,
      -0.051909320056438446,
      -0.030786102637648582,
      -0.016229545697569847,
      0.07227493822574615,
      -0.02211432345211506,
      0.031676068902015686,
      0.052738454192876816,
      -0.01777242310345173,
      0.0031734078656882048,
      0.10217338055372238,
      0.043617770075798035,
      0.0015060034347698092,
      0.01483610738068819,
      0.014900211244821548,
      -0.02979362942278385,
      -0.01265146303921938,
      -0.014723461121320724,
      -0.03486417233943939,
      0.016276007518172264,
      -0.06383538991212845,
      0.02076500654220581,
      -0.11375697702169418,
      -0.035741474479436874,
      -0.026259563863277435,
      0.01611068844795227,
      -0.08216515183448792,
      -0.011659065261483192,
      0.05814328417181969,
      0.0011860637459903955,
      -0.05219464749097824,
      -0.02584635093808174,
      -0.025314295664429665,
      -0.03066294640302658,
      -0.031944114714860916,
      0.03199077025055885,
      0.0007849097019061446,
      0.051211435347795486,
      0.005462321452796459,
      0.008588937111198902,
      0.0835222601890564,
      0.03841863200068474,
      0.010539437644183636,
      -0.06547360867261887,
      -0.06429408490657806,
      -0.02181359753012657,
      0.030942648649215698,
      0.04462601616978645,
      -0.04905301332473755,
      0.0982319638133049,
      0.020219922065734863,
      -0.03369791433215141,
      -0.01156008429825306,
      0.03605480119585991,
      0.08179009705781937,
      0.0003039766161236912,
      -0.04745492339134216,
      0.008689245209097862,
      -0.007187453098595142,
      0.025740522891283035,
      -0.012189862318336964,
      -0.04390880465507507,
      -0.00606813607737422,
      -0.003977802116423845,
      0.016629017889499664,
      0.026383182033896446,
      -0.011866766028106213,
      0.10190114378929138,
      0.061759740114212036,
      -0.020639365538954735,
      -0.030245453119277954,
      -0.0008186991326510906,
      0.006835444364696741,
      -0.01993936486542225,
      -0.05418981984257698,
      0.07026181370019913,
      -0.049811430275440216,
      -0.06331128627061844,
      0.054615043103694916,
      0.004663132131099701,
      0.11704666167497635,
      0.036214858293533325,
      0.0036699878983199596,
      -0.06394065916538239,
      -0.030156338587403297,
      0.005927228834480047,
      -0.04723283648490906,
      0.03466803953051567,
      -0.048875339329242706,
      0.007253759540617466,
      -0.008728542365133762,
      0.01484909001737833,
      0.028287174180150032,
      0.006435031071305275,
      -0.02330753207206726,
      0.02838015928864479,
      -0.036243755370378494,
      -0.058999307453632355,
      0.015600898303091526,
      0.05612439289689064,
      0.05129057168960571,
      -0.15024611353874207,
      -0.012812761589884758,
      -0.014385958202183247,
      -0.03284848481416702,
      0.017410727217793465,
      -0.046746835112571716,
      -0.03721332922577858,
      0.006492883898317814,
      -0.06623438745737076,
      0.0025997688062489033,
      -0.0317063108086586,
      0.00401263777166605,
      0.04664907976984978,
      -0.07148785144090652,
      -0.004937894642353058,
      -0.05424150079488754,
      0.0007763624889776111,
      -0.015742557123303413,
      -0.054644931107759476,
      -0.02741495706140995,
      -0.02125890925526619,
      0.020086847245693207,
      0.020950142294168472,
      -0.009733837097883224,
      -0.03563624992966652,
      -0.024628598242998123,
      -0.028968002647161484,
      -0.009770386852324009,
      0.08260295540094376,
      -0.11198464781045914,
      0.024957632645964622,
      0.03587404638528824,
      -0.01855880208313465,
      -0.03664634749293327,
      -0.0316825769841671,
      0.048185016959905624,
      -0.04336824268102646,
      0.018164629116654396,
      -0.026262594386935234,
      0.020045045763254166,
      -0.020306583493947983,
      0.025151744484901428,
      -0.03599954769015312,
      0.07848385721445084,
      -0.043788228183984756,
      -0.0031929723918437958,
      -0.024389436468482018,
      -0.024211382493376732,
      -0.07025600224733353,
      0.11047722399234772,
      0.04784835875034332,
      -0.04272347688674927,
      -0.016389457508921623,
      -0.08689683675765991,
      0.03649604693055153,
      0.01691550202667713,
      -0.0330827571451664,
      0.03311862424015999,
      -0.06720743328332901,
      0.02482600137591362,
      -0.0013900856720283628,
      -0.0213573407381773,
      0.06422050297260284,
      0.007131525781005621,
      -0.04255373403429985,
      -0.007889014668762684,
      0.023772621527314186,
      -0.043194059282541275,
      0.06420090049505234,
      0.04440934583544731,
      0.019844239577651024,
      -0.018335536122322083,
      -0.004918774589896202,
      -0.06996992975473404,
      -0.024729328230023384,
      0.042867984622716904,
      0.013500320725142956,
      -0.045284245163202286,
      0.023211384192109108,
      0.01412617601454258,
      -0.04261637106537819,
      0.055888932198286057,
      -0.021587317809462547,
      -0.06476737558841705,
      -0.014543893747031689,
      -0.06804981082677841,
      0.04175293445587158,
      -0.03961055725812912,
      0.05719559267163277,
      0.00018134414858650416,
      0.0022005701903253794,
      0.02550404891371727,
      0.0823601558804512,
      0.0023691500537097454,
      0.06743133068084717,
      0.03469446673989296,
      -0.11942663043737411,
      -0.021977189928293228,
      -0.12963396310806274,
      -0.1249021589756012,
      0.019331278279423714,
      0.0023592847865074873,
      0.004350072238594294,
      -0.006102570798248053,
      -0.00942061934620142,
      0.07233472168445587,
      0.01597445271909237,
      0.0020840473007410765,
      -0.03372528403997421,
      -0.021744268015027046,
      -0.05057748034596443,
      -0.03655191510915756,
      -0.10623623430728912,
      0.019324619323015213,
      -0.010831717401742935,
      -0.07086554169654846,
      -0.03041134588420391,
      -0.05466649308800697,
      0.027410926297307014,
      -0.09109313040971756,
      0.03188450261950493,
      0.012146933004260063,
      -0.017351636663079262,
      -0.01625731773674488,
      -0.047523852437734604,
      0.023487286642193794,
      0.010020418092608452,
      -0.03229410946369171,
      0.012022342532873154,
      0.0732821449637413,
      0.0658438429236412,
      0.021212968975305557,
      -0.01944640651345253,
      0.03717384859919548,
      0.020090999081730843,
      -0.06918661296367645,
      0.043265022337436676,
      0.021619470790028572,
      0.07158543169498444,
      0.04036812111735344,
      -0.038310457020998,
      -0.0349305234849453,
      -0.013059386052191257,
      -0.035947371274232864,
      -0.015685738995671272,
      0.010880452580749989,
      0.08242502063512802,
      0.039194948971271515,
      -0.048937730491161346,
      -0.010687294416129589,
      -0.05925893038511276,
      0.05685918405652046,
      0.09712479263544083,
      0.009921674616634846
    ],
    [
      -0.0061477841809391975,
      0.001909393584355712,
      0.041424840688705444,
      -0.011590437963604927,
      -0.04116583988070488,
      -0.008210220374166965,
      -0.0013555927434936166,
      0.03443245589733124,
      0.015398130752146244,
      0.053295981138944626,
      0.016048165038228035,
      0.07414530962705612,
      -0.03425261005759239,
      -0.014117259532213211,
      -0.012778498232364655,
      0.013053403235971928,
      -0.08964844048023224,
      0.028217030689120293,
      -0.003106339368969202,
      -0.03241872414946556,
      -0.08629738539457321,
      -0.02038731426000595,
      -0.014961088076233864,
      -0.002876212354749441,
      0.014049042016267776,
      0.06315987557172775,
      -0.01370939053595066,
      0.08895067125558853,
      -0.07630812376737595,
      -0.019593365490436554,
      0.004399092402309179,
      0.015597308985888958,
      0.028852125629782677,
      -0.13632911443710327,
      0.06332367658615112,
      -0.003949042875319719,
      -0.01564788445830345,
      0.03545860946178436,
      0.0059505607932806015,
      0.0028024110943078995,
      -0.012599135749042034,
      0.010040727443993092,
      0.015987379476428032,
      -0.004208373371511698,
      0.060270436108112335,
      -0.013288813643157482,
      0.003927683457732201,
      -0.039661794900894165,
      -0.01950591243803501,
      0.00966887641698122,
      0.003722300985828042,
      -0.06416600197553635,
      -0.03641854226589203,
      -0.04895291477441788,
      -0.041708894073963165,
      -0.011667577549815178,
      -0.09158286452293396,
      0.00662878667935729,
      -0.03844204545021057,
      -0.06128215789794922,
      0.0033424810972064734,
      0.0484495535492897,
      0.06835552304983139,
      -0.04621896147727966,
      -0.018977584317326546,
      -0.005722195841372013,
      -0.11015462875366211,
      0.04248267412185669,
      -0.01224240567535162,
      0.047368984669446945,
      -0.01825612410902977,
      0.01849416084587574,
      0.026195641607046127,
      -0.059876952320337296,
      -0.0732981413602829,
      0.05243851616978645,
      -0.037663254886865616,
      -0.011483507230877876,
      0.04432186484336853,
      -0.06904106587171555,
      -0.01187230460345745,
      -0.010672573000192642,
      0.0351201668381691,
      -0.0356217585504055,
      0.05660543218255043,
      -0.0068539222702383995,
      0.045128606259822845,
      0.06962979584932327,
      -0.04300030320882797,
      -0.03821400925517082,
      0.01132169645279646,
      0.013799739070236683,
      -0.013575660064816475,
      0.08106549829244614,
      0.054831910878419876,
      0.06553950160741806,
      0.03839031234383583,
      -0.028819050639867783,
      0.06130256876349449,
      0.04019617289304733,
      0.024887725710868835,
      -0.030568642541766167,
      -0.05505254864692688,
      0.015487470664083958,
      -0.07072865217924118,
      -0.007444123271852732,
      -0.0176254753023386,
      -0.06773297488689423,
      0.05991489440202713,
      0.03513776510953903,
      0.0008182058809325099,
      0.027671243995428085,
      -0.03961938992142677,
      -0.05901302769780159,
      0.015607226639986038,
      0.03214607387781143,
      0.0266455989331007,
      -0.007525024004280567,
      0.0020704567432403564,
      -0.03051600232720375,
      0.03440970554947853,
      -0.03293852135539055,
      -0.1173178106546402,
      -0.07733329385519028,
      -0.032258935272693634,
      0.058159202337265015,
      0.004212917294353247,
      0.07081463187932968,
      -0.011202024295926094,
      0.05474371835589409,
      -0.08951901644468307,
      -0.0183770302683115,
      0.031119992956519127,
      0.002941270126029849,
      -0.010742095299065113,
      0.02194969542324543,
      0.03707282990217209,
      -0.003710418241098523,
      -0.018356019631028175,
      -0.03885332867503166,
      -0.0391940213739872,
      0.014287286438047886,
      -0.051103293895721436,
      -0.0634361207485199,
      0.014706253074109554,
      0.05006658285856247,
      0.06191655248403549,
      -0.05991319194436073,
      0.0017906443681567907,
      0.04336768388748169,
      -0.07455413043498993,
      0.06985560059547424,
      0.041184574365615845,
      0.07345341891050339,
      0.022100254893302917,
      0.03583358973264694,
      -0.04313449561595917,
      -0.11986344307661057,
      -0.01547307800501585,
      0.0015931035159155726,
      0.003003232879564166,
      0.06824066489934921,
      -0.00747670466080308,
      -0.049611497670412064,
      -0.06480094790458679,
      0.028965141624212265,
      0.0026836886536329985,
      -0.02678566426038742,
      0.029260076582431793,
      0.04049742966890335,
      -0.06193796917796135,
      0.007671200204640627,
      0.10825987905263901,
      0.010268728248775005,
      0.06974221020936966,
      0.03589972108602524,
      0.05456157773733139,
      -0.05309732258319855,
      -0.001084164367057383,
      0.07122335582971573,
      0.09421704709529877,
      -0.020214909687638283,
      0.024580534547567368,
      -0.01432634238153696,
      -0.046252623200416565,
      -0.05986704304814339,
      0.08185369521379471,
      -0.0580454021692276,
      -0.06404495984315872,
      -0.00895767193287611,
      -0.039433930069208145,
      0.003618825227022171,
      -0.01754692755639553,
      -0.06648597121238708,
      -0.02802061289548874,
      -0.03176666796207428,
      -0.0029494024347513914,
      -0.014538162387907505,
      -0.05294087529182434,
      0.02642889879643917,
      0.09214440733194351,
      0.04506199434399605,
      0.042658206075429916,
      -0.043301790952682495,
      0.03576601669192314,
      0.04068848490715027,
      0.00038621245766989887,
      -0.004052618518471718,
      0.03140510246157646,
      -0.003060145303606987,
      0.047581642866134644,
      -0.023402974009513855,
      0.02442500740289688,
      0.013168218545615673,
      0.061639849096536636,
      0.025158172473311424,
      0.07515278458595276,
      -0.021283168345689774,
      0.028148658573627472,
      -0.012770512141287327,
      -0.0027791408356279135,
      0.03398386016488075,
      -0.05729043856263161,
      0.02292611636221409,
      -0.015722138807177544,
      -0.02277519926428795,
      0.02926395647227764,
      -0.0620708167552948,
      0.0408845916390419,
      0.03886700049042702,
      -0.08446751534938812,
      0.01041110884398222,
      -0.03182915225625038,
      0.008536276407539845,
      0.018688879907131195,
      0.10245763510465622,
      0.011511594988405704,
      0.031954001635313034,
      0.005096827633678913,
      -0.03472552075982094,
      -0.008958646096289158,
      0.004441813100129366,
      -0.02453242987394333,
      0.00847886223345995,
      -0.02081783302128315,
      -0.004940839484333992,
      0.018387844786047935,
      -0.06131604686379433,
      0.08122609555721283,
      0.048339955508708954,
      0.014141279272735119,
      0.030941439792513847,
      0.008679717779159546,
      0.08644597232341766,
      0.027207981795072556,
      -0.009904356673359871,
      0.021326333284378052,
      0.09135623276233673,
      0.1281803399324417,
      -0.010482929646968842,
      -0.06253480166196823,
      -0.03665735945105553,
      -0.020876523107290268,
      -0.0746171697974205,
      0.030986160039901733,
      -0.053315792232751846,
      -0.0325494185090065,
      0.11908924579620361,
      0.026047972962260246,
      0.03004503808915615,
      -0.00874735601246357,
      0.017052263021469116,
      0.023823251947760582,
      0.002167221624404192,
      0.03569610416889191,
      -0.011707019060850143,
      -0.04479255527257919,
      0.013061059638857841,
      0.09839469194412231,
      0.0772438570857048,
      -0.027178870514035225,
      0.07745081931352615,
      0.00666063604876399,
      -0.026270108297467232,
      -0.018816357478499413,
      0.012534373439848423,
      0.040263671427965164,
      -0.07235095649957657,
      -0.04575237259268761,
      -0.032236482948064804,
      0.03271416947245598,
      -0.06682392954826355,
      0.0026414741296321154,
      0.04624026268720627,
      0.024732377380132675,
      0.024666134268045425,
      0.0553060844540596,
      0.008753199130296707,
      -0.06388600170612335,
      0.07010960578918457,
      0.044350314885377884,
      -0.014536888338625431,
      0.010289857164025307,
      0.04379088059067726,
      0.03534161299467087,
      -0.020218275487422943,
      0.060634322464466095,
      0.027497030794620514,
      0.07343767583370209,
      -0.016109973192214966,
      0.00793931819498539,
      -0.04268571361899376,
      0.010979174636304379,
      -0.06962156295776367,
      0.017595846205949783,
      0.01822597160935402,
      -0.010070840828120708,
      -0.05157160758972168,
      -0.06821000576019287,
      -0.0050992597825825214,
      -0.04501129314303398,
      -0.003331027692183852,
      -0.03353720158338547,
      0.053506698459386826,
      0.013414004817605019,
      -0.04262877628207207,
      -0.07477407902479172,
      -0.016907677054405212,
      0.02787955105304718,
      -0.0025847586803138256,
      -0.030481893569231033,
      -0.006438219454139471,
      0.04904218018054962,
      0.011496556922793388,
      -0.08106104284524918,
      -0.041135042905807495,
      0.10316453874111176,
      0.042618054896593094,
      0.024295885115861893,
      -0.010456481948494911,
      -0.022575655952095985,
      -0.013538402505218983,
      -0.05466921254992485,
      0.07885810732841492,
      -0.03698195144534111,
      0.008842363022267818,
      0.016148021444678307,
      -0.015099139884114265,
      0.02490175887942314,
      0.06952650099992752,
      -0.00554933724924922,
      -0.08824966102838516,
      0.12067831307649612,
      0.04406429082155228,
      0.11835070699453354,
      0.02616482600569725,
      -0.07332699745893478,
      -0.0001791867398424074,
      -0.03207811713218689,
      0.046584054827690125,
      -0.03645314276218414,
      -0.026275290176272392,
      -0.014981522224843502,
      0.09851225465536118,
      0.008313018828630447,
      0.02266312763094902,
      0.028500966727733612,
      -0.07370322942733765,
      0.04492038115859032,
      -0.059164319187402725,
      0.01943225786089897,
      0.015644147992134094,
      0.0005439835949800909,
      -0.07018514722585678,
      -0.0648006945848465,
      -0.050955288112163544,
      -0.13273128867149353,
      -0.019714174792170525,
      0.021102339029312134,
      0.05616331472992897,
      -0.06895694136619568,
      0.03992209583520889,
      0.010180567391216755,
      -0.020634829998016357,
      0.02159164659678936,
      0.01113173272460699,
      0.013270508497953415,
      -0.026095274835824966,
      0.03910033032298088,
      -0.01602081023156643,
      0.06875386089086533,
      0.008664021268486977,
      -0.021840132772922516,
      -0.08500906825065613,
      -0.014796593226492405,
      -0.048617858439683914,
      0.04963616281747818,
      0.07211016118526459,
      0.010671748779714108,
      0.06774374097585678,
      0.1072283685207367,
      -0.01142064481973648,
      0.04794027656316757,
      -0.007214713841676712,
      -0.004798945039510727,
      -0.0504455640912056,
      0.016555413603782654,
      -0.043888382613658905,
      -0.0391235426068306,
      0.059891462326049805,
      0.02744113653898239,
      0.05528997629880905,
      -0.047458309680223465,
      0.03611709177494049,
      -0.05383525416254997,
      -0.030940961092710495,
      0.012538721784949303,
      0.047807686030864716,
      -0.01743776910007,
      -0.02125830017030239,
      -0.06886450946331024,
      -0.017774313688278198,
      0.05026603490114212,
      -0.05109236016869545,
      0.012940800748765469,
      0.006548051722347736,
      -0.006526285316795111,
      -0.06376035511493683,
      0.0344160832464695,
      0.03462013602256775,
      0.051477741450071335,
      0.03273007273674011,
      -0.041671548038721085,
      0.057378124445676804,
      -0.06205444782972336,
      -0.03552580624818802,
      -0.0124356709420681,
      -0.06904196739196777,
      0.011201870627701283,
      0.005897284485399723,
      0.0594523586332798,
      -0.013238114304840565,
      0.0191362164914608,
      0.021454421803355217,
      0.09364908188581467,
      -0.05076206475496292,
      -0.02837899513542652,
      0.04499485343694687,
      0.01308093499392271,
      0.02757364884018898,
      -0.048877447843551636,
      -0.009645814076066017,
      0.02012494020164013,
      -0.03545914590358734,
      0.04420547932386398,
      0.1318332999944687,
      0.03712651878595352,
      -0.03163943439722061,
      -0.06275656074285507,
      -0.014424417167901993,
      0.012745466083288193,
      -0.04838572070002556,
      -0.014129731804132462,
      0.018954582512378693,
      0.00950140692293644,
      0.024708043783903122,
      0.018410952761769295,
      0.10571876168251038,
      0.02612646482884884,
      -0.017074014991521835,
      0.01164322067052126,
      0.02347119338810444,
      -0.01852068118751049,
      0.03404179587960243,
      -0.001089514116756618,
      -0.008899913169443607,
      -0.03982093185186386,
      -0.05616502836346626,
      0.01689586043357849,
      -0.03974729776382446,
      -0.008305808529257774,
      0.02657991833984852,
      0.030410706996917725,
      -0.04985564202070236,
      0.029359333217144012,
      -0.024543168023228645,
      0.0023171561770141125,
      0.03488767892122269,
      0.032679397612810135,
      0.008524212054908276,
      -0.02938411943614483,
      -0.06420845538377762,
      0.033319346606731415,
      -0.012494085356593132,
      -0.003443312831223011,
      0.023862270638346672,
      -0.037049297243356705,
      -0.040533050894737244,
      -0.030103346332907677,
      -0.022023985162377357,
      -0.05227985605597496,
      -0.04004107788205147,
      -0.015678806230425835
    ],
    [
      0.022471385076642036,
      -0.05520310997962952,
      -0.023137452080845833,
      -0.037927448749542236,
      0.05742896348237991,
      -0.04646224156022072,
      0.07270122319459915,
      -0.062271423637866974,
      0.05659651756286621,
      -0.004059624392539263,
      -0.01276491116732359,
      -0.038002803921699524,
      0.06932102888822556,
      -0.02223285473883152,
      0.01827724650502205,
      -0.0036472463980317116,
      0.09342959523200989,
      0.008516065776348114,
      0.09648634493350983,
      -0.056824807077646255,
      0.08584386855363846,
      -0.04715967923402786,
      0.02461284026503563,
      0.006648668088018894,
      -0.05254816636443138,
      -4.0885988710215315e-06,
      -0.04049810767173767,
      0.12892429530620575,
      -0.027699384838342667,
      0.02028505876660347,
      -0.02421760931611061,
      0.0009774471400305629,
      -0.09542687982320786,
      0.08352493494749069,
      -0.0024043931625783443,
      -0.011038350872695446,
      0.06541211158037186,
      -0.03495979681611061,
      -0.012185312807559967,
      0.050519201904535294,
      -0.06428288668394089,
      0.06661287695169449,
      0.026812056079506874,
      0.010913439095020294,
      0.03709215298295021,
      0.01666894368827343,
      -0.014311020262539387,
      0.07145119458436966,
      -0.05840082839131355,
      -0.0035468083806335926,
      -0.01724858209490776,
      -0.013241116888821125,
      0.04615838825702667,
      -0.0011755031300708652,
      -0.016181867569684982,
      -0.03594857454299927,
      -0.08586511015892029,
      -0.012517918832600117,
      0.0157118272036314,
      -0.012495440430939198,
      0.06991899758577347,
      0.006602820474654436,
      -0.11020062863826752,
      0.06312039494514465,
      -0.022058401256799698,
      -0.06906416267156601,
      -0.05162816494703293,
      -0.0285131074488163,
      -0.03983468562364578,
      -0.015549307689070702,
      -0.07905755937099457,
      -0.0015493800165131688,
      -0.08140488713979721,
      -0.07607027143239975,
      -0.04622909054160118,
      0.05999353900551796,
      0.0722159817814827,
      -0.045200251042842865,
      -0.008830927312374115,
      -0.028782112523913383,
      -0.026665180921554565,
      -0.00039091394864954054,
      -0.07908711582422256,
      -0.06458602845668793,
      0.017766471952199936,
      0.028903596103191376,
      0.03586072102189064,
      0.010134055279195309,
      -0.1372097283601761,
      0.06269527226686478,
      0.08142758905887604,
      -0.08875268697738647,
      -0.06810106337070465,
      -0.019388293847441673,
      -0.028402164578437805,
      6.70505323796533e-05,
      0.07321688532829285,
      0.03603346645832062,
      -0.02423427626490593,
      -0.0413782112300396,
      -0.060069743543863297,
      -0.07539311051368713,
      0.0032711485400795937,
      -0.024159668013453484,
      -0.07792884111404419,
      0.061530549079179764,
      0.03255554661154747,
      0.018519889563322067,
      0.07412034273147583,
      0.014576726593077183,
      0.006743083707988262,
      0.002891059499233961,
      0.019761595875024796,
      0.009636961854994297,
      -0.057192377746105194,
      -0.005553993862122297,
      -0.041550882160663605,
      0.060499392449855804,
      -0.042086388915777206,
      -0.023327689617872238,
      0.08475156873464584,
      -0.13288867473602295,
      0.03252207487821579,
      0.07363758236169815,
      -0.02732277289032936,
      -0.007494421675801277,
      0.022043567150831223,
      0.06265472620725632,
      -0.07367995381355286,
      -0.020617086440324783,
      -0.032439447939395905,
      0.01682061143219471,
      0.03272399306297302,
      -0.03830433264374733,
      0.05110340192914009,
      0.004675574600696564,
      0.0021515781991183758,
      0.01870097778737545,
      -0.035079896450042725,
      -0.023198982700705528,
      0.013966635800898075,
      -0.03333956375718117,
      -0.0022724741138517857,
      0.01296618115156889,
      0.032654404640197754,
      0.0005038250819779932,
      0.10889548808336258,
      -0.037977058440446854,
      0.007798151578754187,
      -0.008069588802754879,
      0.028833778575062752,
      -0.01720094121992588,
      0.02978174388408661,
      0.08740358799695969,
      -0.020545994862914085,
      -0.07900143414735794,
      0.0067075989209115505,
      0.010533378459513187,
      0.05498414486646652,
      -0.021242564544081688,
      -0.062239617109298706,
      0.03923260048031807,
      0.061142683029174805,
      0.0057965475134551525,
      -0.013005624525249004,
      0.028247488662600517,
      -0.01911647617816925,
      -0.05483613163232803,
      -0.01629980467259884,
      0.016466049477458,
      -0.032224420458078384,
      0.07981393486261368,
      -0.007863739505410194,
      0.03431931510567665,
      -0.023099370300769806,
      0.03552132472395897,
      0.053794581443071365,
      -0.0005817749770358205,
      -0.05520640313625336,
      0.05572260916233063,
      0.008757458999752998,
      0.018570085987448692,
      0.0034408820793032646,
      -0.015817753970623016,
      -0.05991939455270767,
      0.012337639927864075,
      -0.01120956800878048,
      -0.0036037990357726812,
      0.027922268956899643,
      0.030465014278888702,
      0.018773168325424194,
      -0.012846361845731735,
      -0.014037346467375755,
      -0.0028734917286783457,
      -0.04998064413666725,
      0.01727469637989998,
      -0.06358110159635544,
      -0.01480260118842125,
      -0.006282880436629057,
      0.06519832462072372,
      -0.015333924442529678,
      -0.10954831540584564,
      -0.03894490376114845,
      -8.521891140844673e-05,
      -0.05733247101306915,
      -0.010587169788777828,
      0.06119599565863609,
      0.09903368353843689,
      -0.0451824925839901,
      -0.03902208060026169,
      -0.023405546322464943,
      -0.047134868800640106,
      0.10393104702234268,
      -0.06967199593782425,
      -0.004554752726107836,
      0.03550395369529724,
      0.027735086157917976,
      -0.021505415439605713,
      0.026919493451714516,
      0.08194679021835327,
      -0.07902079820632935,
      -0.02244269661605358,
      -0.044359732419252396,
      0.07638011127710342,
      0.009450415149331093,
      0.06851676106452942,
      0.005203843582421541,
      -0.045839473605155945,
      -0.0051105935126543045,
      0.013601931743323803,
      -0.014327357523143291,
      -0.037184108048677444,
      -0.04821409657597542,
      -0.04588339105248451,
      -0.024701673537492752,
      0.01022905670106411,
      0.023369265720248222,
      -0.07578766345977783,
      0.021841436624526978,
      -0.05271930620074272,
      0.0208030566573143,
      -0.1096755713224411,
      0.0027604878414422274,
      -0.03782954066991806,
      0.03617428243160248,
      -0.006837334483861923,
      -0.03304527327418327,
      -0.027095988392829895,
      0.0638563483953476,
      0.03973224386572838,
      0.022384610027074814,
      0.056446049362421036,
      0.07862121611833572,
      0.06839839369058609,
      0.012786542065441608,
      -0.039610691368579865,
      0.039401132613420486,
      -0.056001242250204086,
      -0.0001047233454301022,
      -0.017841774970293045,
      0.011811074800789356,
      0.09208052605390549,
      -0.004941008519381285,
      -0.03054320439696312,
      0.02259788289666176,
      -0.01846597157418728,
      0.039391592144966125,
      -0.013768074102699757,
      -0.016853321343660355,
      -0.023855645209550858,
      -0.06526060402393341,
      -0.013894042931497097,
      0.050247590988874435,
      -0.0069972737692296505,
      -0.027686787769198418,
      0.011505207046866417,
      0.0205757487565279,
      -0.016492178663611412,
      -0.012558440677821636,
      0.025007357820868492,
      0.08892293274402618,
      0.05135184898972511,
      -0.0017555380472913384,
      0.02401239611208439,
      0.08924676477909088,
      -0.009842652827501297,
      0.040971606969833374,
      0.0487474724650383,
      0.032232705503702164,
      -0.03425656631588936,
      -0.07649318873882294,
      0.01322530210018158,
      0.06147797405719757,
      0.007722505368292332,
      -0.04689273610711098,
      0.0366295725107193,
      -0.015804123133420944,
      0.02561197429895401,
      0.031103214249014854,
      0.00034647362190298736,
      0.040981754660606384,
      0.014818104915320873,
      -0.01835864968597889,
      -0.10722783952951431,
      -0.05938282236456871,
      0.01323840394616127,
      -0.027210216969251633,
      0.09504016488790512,
      0.014764545485377312,
      0.0533645935356617,
      0.043080687522888184,
      -0.0916450098156929,
      0.12628193199634552,
      0.022353988140821457,
      0.01830640248954296,
      0.05361539497971535,
      -0.015601140446960926,
      -0.008564011193811893,
      -0.019557498395442963,
      -0.012548261322081089,
      0.0016748326597735286,
      -0.006636541802436113,
      0.046182408928871155,
      0.06481685489416122,
      0.012976309284567833,
      -0.07005549967288971,
      0.012495346367359161,
      -0.007843287661671638,
      0.07648573815822601,
      0.0012786772567778826,
      0.03704877197742462,
      0.05664908513426781,
      -0.0033233033027499914,
      -0.06578512489795685,
      -0.0004319185100030154,
      0.03412690386176109,
      0.014062841422855854,
      -0.054747842252254486,
      -0.06948929280042648,
      0.032846156507730484,
      -0.0064219580963253975,
      -0.08296224474906921,
      -0.011133953928947449,
      -0.02805851586163044,
      0.025730982422828674,
      0.031434159725904465,
      -0.042756013572216034,
      -0.013777704909443855,
      -0.09608027338981628,
      0.062061410397291183,
      0.0035945361014455557,
      -0.020143970847129822,
      0.031911663711071014,
      0.05048421397805214,
      -0.017224974930286407,
      -0.02394578419625759,
      -0.010367780923843384,
      0.04110413044691086,
      -0.054503973573446274,
      0.11773885786533356,
      -0.053718697279691696,
      0.02352093532681465,
      0.0074366494081914425,
      0.016162998974323273,
      -0.04722513630986214,
      -0.006549417972564697,
      -0.043663691729307175,
      -0.06708785146474838,
      0.06651899218559265,
      -0.0029635911341756582,
      0.05963634327054024,
      0.07554902881383896,
      -0.015299232676625252,
      0.0210871621966362,
      -0.012126067653298378,
      -0.02003597654402256,
      0.0058107636868953705,
      -0.042027395218610764,
      0.03921542689204216,
      -0.01908523216843605,
      0.04086225852370262,
      0.02599586546421051,
      0.022206291556358337,
      -0.01328521128743887,
      0.06756308674812317,
      -0.004242474678903818,
      -0.07572861015796661,
      -0.07412613183259964,
      0.07135621458292007,
      0.0029052135068923235,
      0.08087139576673508,
      -0.004283380229026079,
      -0.0009742801776155829,
      -0.10108507424592972,
      0.025776643306016922,
      -0.00863921083509922,
      0.048360854387283325,
      0.018744446337223053,
      -0.01328975148499012,
      0.029558749869465828,
      -0.02878868766129017,
      -0.13969962298870087,
      -0.12542825937271118,
      0.054933007806539536,
      0.009709023870527744,
      -0.028166308999061584,
      -0.05131096765398979,
      -0.013962488621473312,
      0.010175831615924835,
      0.004031929653137922,
      0.023834411054849625,
      -0.05113121494650841,
      -0.0014988515758886933,
      -0.010555434040725231,
      0.04409243166446686,
      0.014191706664860249,
      0.11284619569778442,
      0.05567992106080055,
      -0.0014992818469181657,
      0.006412419956177473,
      -0.07342910021543503,
      0.04497968405485153,
      -0.014695378951728344,
      -0.06124346703290939,
      -0.039395544677972794,
      0.028281286358833313,
      0.07492880523204803,
      -0.003268016502261162,
      0.01982433721423149,
      0.038723573088645935,
      0.019575772807002068,
      0.056328367441892624,
      0.10847103595733643,
      0.02468387596309185,
      -0.0727069303393364,
      -0.037903279066085815,
      -0.04652470350265503,
      -0.09235094487667084,
      -0.002336968434974551,
      0.023647984489798546,
      -0.012781192548573017,
      0.03527078032493591,
      -0.05113447457551956,
      -0.00911771971732378,
      0.10456971824169159,
      -0.04897098243236542,
      -0.041514065116643906,
      0.08335787057876587,
      -0.07673890143632889,
      -0.008715109899640083,
      0.104132741689682,
      0.056051045656204224,
      0.03252790868282318,
      -0.015471135266125202,
      0.004525450523942709,
      0.04950999096035957,
      0.014509185217320919,
      -0.02011452242732048,
      0.016350865364074707,
      -0.0002888080198317766,
      -0.07897128164768219,
      -0.05757373943924904,
      -0.08550326526165009,
      -0.06347879022359848,
      -0.04907172918319702,
      0.03518509864807129,
      -0.07758862525224686,
      0.006651550997048616,
      -0.08951190114021301,
      -0.000514088838826865,
      0.00808747485280037,
      0.03932007774710655,
      0.028808200731873512,
      0.037308380007743835,
      -0.031115269288420677,
      0.09283120185136795,
      0.0544736310839653,
      -0.03762487322092056,
      -0.03764895722270012,
      -0.015858810395002365,
      0.03702075034379959,
      0.014747927896678448,
      -0.02797085978090763,
      0.09845041483640671,
      -0.08641254156827927,
      0.010036897845566273,
      -0.018810564652085304,
      0.02272922545671463,
      -0.03587058186531067,
      -0.022635985165834427,
      0.02860908955335617,
      0.01834338903427124,
      -0.0014672648394480348,
      -0.05724141374230385,
      -0.024384140968322754,
      0.03433002158999443,
      0.08844638615846634,
      -0.08536107838153839,
      -0.0011270022951066494,
      0.015888569876551628,
      -0.058981698006391525,
      0.040046289563179016,
      0.04663025960326195
    ],
    [
      -0.05413159728050232,
      0.0007900500204414129,
      -0.1327740103006363,
      -0.009667999111115932,
      -0.026058707386255264,
      -0.05685035139322281,
      0.010092562064528465,
      -0.04393037408590317,
      -0.006865732371807098,
      0.06303121149539948,
      0.0521954707801342,
      0.005735606420785189,
      -0.002090666675940156,
      0.005247632972896099,
      0.12532013654708862,
      0.0475894920527935,
      -0.01300132367759943,
      -0.01696375571191311,
      -0.025769175961613655,
      -0.0314178392291069,
      -0.08714675158262253,
      0.004692473914474249,
      0.012068519368767738,
      0.00970429740846157,
      0.005614515859633684,
      -0.021984074264764786,
      0.04349939152598381,
      0.07452918589115143,
      -0.001147985109128058,
      -0.01453834306448698,
      0.04654396325349808,
      0.02943897247314453,
      -0.04156029224395752,
      -0.049935951828956604,
      0.02110777050256729,
      -0.014905689284205437,
      0.0499802865087986,
      -0.0020506959408521652,
      -0.039918966591358185,
      0.029503192752599716,
      0.01606210507452488,
      -0.046247344464063644,
      0.023727070540189743,
      0.015317239798605442,
      0.022365305572748184,
      -0.011713527143001556,
      0.005986690986901522,
      -0.018323317170143127,
      0.008338101208209991,
      -0.02058578096330166,
      -0.03173190355300903,
      -0.018413091078400612,
      -0.04100732505321503,
      -0.012699740938842297,
      -0.017594188451766968,
      0.041319407522678375,
      0.006459106225520372,
      -0.04422389343380928,
      -0.019788939505815506,
      0.03535274416208267,
      -0.0022037888411432505,
      -0.051726534962654114,
      -0.006021121051162481,
      0.02233251929283142,
      -0.04256700724363327,
      -0.008488974533975124,
      -0.030183373019099236,
      0.021862341091036797,
      0.0625884160399437,
      0.03697547689080238,
      0.06299656629562378,
      -0.07536090910434723,
      -0.02375882863998413,
      -0.04111717641353607,
      -0.032169245183467865,
      -0.01261377427726984,
      -0.03703971952199936,
      -0.0010166836436837912,
      0.012789278291165829,
      0.010506177321076393,
      -0.03067488968372345,
      0.011999789625406265,
      -0.01232445053756237,
      -0.04872332513332367,
      0.02600356936454773,
      0.006576483603566885,
      -0.02995898760855198,
      -0.03663463518023491,
      -0.009439004585146904,
      0.06749223172664642,
      0.06357840448617935,
      0.056684162467718124,
      -0.008942878805100918,
      -0.011297925375401974,
      -1.770641392795369e-05,
      -0.01109579112380743,
      0.04019917920231819,
      -0.03149890527129173,
      0.008208665996789932,
      0.03218179941177368,
      0.0345078743994236,
      -0.1213478222489357,
      -0.07469999045133591,
      0.03963623195886612,
      0.0857810229063034,
      0.02566620148718357,
      -0.07314059883356094,
      0.02174525335431099,
      -0.04173441603779793,
      -0.012528489343822002,
      0.02279539592564106,
      0.032597895711660385,
      -0.05881860852241516,
      -0.004402186721563339,
      -0.05241064354777336,
      0.02726101689040661,
      0.009884834289550781,
      -0.07088583707809448,
      0.07138683646917343,
      0.08813932538032532,
      -0.00027716803015209734,
      0.019485386088490486,
      0.010457871481776237,
      -0.03737464174628258,
      -0.041307732462882996,
      0.07666600495576859,
      0.029192188754677773,
      -0.05390976369380951,
      -0.05193505808711052,
      0.019078530371189117,
      -0.04142838343977928,
      -0.007232042495161295,
      0.017290368676185608,
      0.05419902876019478,
      0.016986213624477386,
      0.04687940701842308,
      -0.07609021663665771,
      -0.014018732123076916,
      0.054556284099817276,
      -0.008468642830848694,
      -0.005243852734565735,
      0.0062054796144366264,
      -0.096776582300663,
      0.018835218623280525,
      -0.045896731317043304,
      -0.0072833579033613205,
      0.022585352882742882,
      -0.09590382874011993,
      -0.03392653167247772,
      -0.028979767113924026,
      -0.0674513578414917,
      0.07055449485778809,
      0.01017679926007986,
      -0.02151942439377308,
      0.05007581040263176,
      0.00403151148930192,
      0.02487877383828163,
      -0.05210131034255028,
      -0.017766641452908516,
      -0.058145735412836075,
      -0.04123049974441528,
      0.04730160906910896,
      -0.0690496414899826,
      0.001179662998765707,
      0.043239861726760864,
      -0.02067304402589798,
      0.07784922420978546,
      -0.03975369408726692,
      0.02100282721221447,
      -0.012049390934407711,
      0.02179843746125698,
      -0.006188931874930859,
      0.08137737959623337,
      -0.011698776856064796,
      0.020563066005706787,
      -0.041836630553007126,
      -0.022244129329919815,
      0.03328259661793709,
      0.08662743121385574,
      -0.02749452367424965,
      -0.053554851561784744,
      -0.017290616407990456,
      0.0008089260663837194,
      0.038061145693063736,
      0.0031910708639770746,
      0.019413914531469345,
      0.008952674455940723,
      0.007230659946799278,
      -0.05028929188847542,
      -0.034332334995269775,
      0.02897692658007145,
      0.0207221657037735,
      -0.012894249521195889,
      0.035405233502388,
      -0.04078924283385277,
      -0.021106434985995293,
      -0.049939755350351334,
      0.010009963996708393,
      0.012067925184965134,
      0.03439587727189064,
      0.0668119564652443,
      0.03153562918305397,
      0.05431336164474487,
      -0.03099701553583145,
      -0.012152689509093761,
      0.07328065484762192,
      0.01631496660411358,
      -0.06444639712572098,
      0.022023746743798256,
      0.004031291231513023,
      -0.0008973529329523444,
      0.04059885814785957,
      0.09112219512462616,
      0.08887649327516556,
      0.021614104509353638,
      -0.038409795612096786,
      -0.01249514240771532,
      -0.017523452639579773,
      -0.06555618345737457,
      0.024055754765868187,
      0.008615544997155666,
      -0.006435917224735022,
      0.0731610357761383,
      0.01977861113846302,
      -0.046633075922727585,
      0.011484615504741669,
      -0.033143460750579834,
      0.014738067053258419,
      0.042960163205862045,
      0.04992305487394333,
      0.01791543886065483,
      0.019342022016644478,
      -0.05709939822554588,
      -0.030123766511678696,
      -0.06471631675958633,
      -0.08650638908147812,
      0.03169375658035278,
      0.0015295526245608926,
      -0.04252009093761444,
      -0.04329419136047363,
      0.01776336506009102,
      -0.005597683601081371,
      0.025547873228788376,
      0.02512228488922119,
      0.07337477058172226,
      0.003192202653735876,
      0.004357065539807081,
      -0.04961598291993141,
      0.037330545485019684,
      0.016956496983766556,
      -0.00914723239839077,
      0.14962217211723328,
      -0.042814627289772034,
      -0.07672450691461563,
      -0.04263245686888695,
      -0.0160297192633152,
      -0.047926995903253555,
      0.06852904707193375,
      -0.010387919843196869,
      0.00012032088125124574,
      -0.0012222646037116647,
      -0.03675989434123039,
      0.034724652767181396,
      0.02259412594139576,
      -0.0017229184741154313,
      -0.028240816667675972,
      0.05291268602013588,
      0.0062289610505104065,
      0.020704561844468117,
      0.033846016973257065,
      -0.019542930647730827,
      -0.056526556611061096,
      0.08509264886379242,
      0.03216802328824997,
      0.010153518989682198,
      -0.026793664321303368,
      -0.00303194229491055,
      0.007596169598400593,
      -0.06184755638241768,
      -0.031733330339193344,
      -0.011665270663797855,
      -0.0046040671877563,
      -0.01328293513506651,
      0.007477660663425922,
      0.019895179197192192,
      0.027708271518349648,
      -0.008774790912866592,
      -0.01353155542165041,
      -0.01116353739053011,
      -0.020472055301070213,
      -0.0952196717262268,
      -0.049397941678762436,
      0.03745298087596893,
      0.02480817399919033,
      -0.07037758827209473,
      -0.04040952026844025,
      0.008101710118353367,
      0.043165504932403564,
      -0.003183498280122876,
      -0.04784126952290535,
      -0.05785740539431572,
      -0.11786247044801712,
      -0.004438562318682671,
      0.03285885229706764,
      0.0020611716900020838,
      -0.029460186138749123,
      -0.013208948075771332,
      0.09545226395130157,
      0.007395261898636818,
      0.09159824997186661,
      0.05573679506778717,
      0.005091015249490738,
      0.09764519333839417,
      0.05955332890152931,
      -0.003830789588391781,
      0.03714989498257637,
      0.08577226847410202,
      0.01952156238257885,
      -0.03217468783259392,
      -0.0624149926006794,
      -0.0481429398059845,
      -0.011316162534058094,
      0.014079463668167591,
      0.0689658671617508,
      -0.0052071744576096535,
      -0.030519770458340645,
      0.08430056273937225,
      0.06567779928445816,
      0.027021048590540886,
      -0.005153760779649019,
      -0.08269531279802322,
      -0.022905929014086723,
      0.05082283914089203,
      0.045622676610946655,
      0.016514284536242485,
      0.031249158084392548,
      -0.042824938893318176,
      -0.043326009064912796,
      0.02661636471748352,
      0.026429543271660805,
      -0.07544288039207458,
      -0.07824266701936722,
      -0.041578345000743866,
      0.013834407553076744,
      0.002855709521099925,
      0.009808171540498734,
      0.003238800447434187,
      0.02520565502345562,
      0.06326991319656372,
      -0.03947853296995163,
      -0.004226589109748602,
      0.006956454832106829,
      -0.018229791894555092,
      0.01939420774579048,
      -0.011390190571546555,
      0.00676612788811326,
      -0.07830328494310379,
      -0.037782032042741776,
      -0.09181668609380722,
      0.010787437669932842,
      -0.052480824291706085,
      -0.028614766895771027,
      -0.06771638989448547,
      0.004947928711771965,
      0.07347752898931503,
      0.028802240267395973,
      0.07138215005397797,
      0.009895467199385166,
      0.014379655010998249,
      -0.034066684544086456,
      -0.02727692574262619,
      -0.04117279872298241,
      0.011411486193537712,
      0.013751060701906681,
      0.10400006920099258,
      0.06017892435193062,
      0.0089137963950634,
      0.0011280004400759935,
      -0.027483860030770302,
      0.00885873194783926,
      -0.030090615153312683,
      -0.041429247707128525,
      0.019987978041172028,
      -0.005997852887958288,
      -0.05017496272921562,
      0.012541132979094982,
      -0.032362814992666245,
      0.010228260420262814,
      0.03023623675107956,
      -0.03421741724014282,
      0.11846154928207397,
      0.07256349921226501,
      -0.11788810789585114,
      -0.05649261176586151,
      0.03328525647521019,
      -0.024136284366250038,
      -0.033816687762737274,
      -0.0020966194570064545,
      -0.024578526616096497,
      -0.0447981134057045,
      0.04127723351120949,
      0.06299319118261337,
      -0.03240204229950905,
      -0.03874439746141434,
      -0.006074290722608566,
      0.017770469188690186,
      0.04740627855062485,
      -0.0028204366099089384,
      -0.02873075194656849,
      -0.03850899264216423,
      0.027674108743667603,
      0.11632636934518814,
      0.043524231761693954,
      0.006629527546465397,
      -0.0027936173137277365,
      0.001538755721412599,
      -0.038747210055589676,
      -0.0374666266143322,
      0.04557861387729645,
      -0.033957820385694504,
      -0.040529828518629074,
      0.03002803958952427,
      -0.0075177522376179695,
      -0.041280943900346756,
      -0.009665022604167461,
      -0.02017715945839882,
      0.034889105707407,
      0.001076890155673027,
      -0.01794014871120453,
      0.13632839918136597,
      0.04808036983013153,
      0.05391988158226013,
      0.07138989865779877,
      -0.04095056653022766,
      -0.03943009674549103,
      -0.04808377847075462,
      -0.0026148920878767967,
      -0.046420007944107056,
      -0.018499480560421944,
      -0.045508973300457,
      -0.02825806476175785,
      0.02277590148150921,
      -0.025078127160668373,
      0.016584422439336777,
      -0.036123938858509064,
      -0.028060870245099068,
      0.02019554190337658,
      -0.015067865140736103,
      -0.045806385576725006,
      0.044212706387043,
      0.09031513333320618,
      0.05221639946103096,
      -0.07124955207109451,
      -0.03641996160149574,
      0.051858313381671906,
      0.0684811994433403,
      0.03618048131465912,
      0.03648020327091217,
      0.0304794330149889,
      -0.027175238355994225,
      -0.025587595999240875,
      -0.04104484245181084,
      0.006084490101784468,
      -0.04765666276216507,
      -0.049682267010211945,
      -0.04252585768699646,
      0.005964640062302351,
      -0.14083901047706604,
      0.005919588729739189,
      0.017704831436276436,
      0.011168988421559334,
      -0.011036233976483345,
      0.08556363731622696,
      -0.05126461759209633,
      -0.025796744972467422,
      0.00620825681835413,
      0.015441224910318851,
      -0.027709640562534332,
      -0.0008604298927821219,
      -0.04984597861766815,
      -0.04186137765645981,
      -0.08200879395008087,
      -0.023522544652223587,
      -0.03933124616742134,
      0.010558193549513817,
      -0.0004373171250335872,
      -0.03196197375655174,
      0.0377017967402935,
      -0.044509995728731155,
      -0.03305564820766449,
      0.014879828318953514,
      0.029656199738383293,
      -0.0072912354953587055,
      0.007871300913393497,
      -0.00041724398033693433,
      -0.029281219467520714,
      0.008517833426594734,
      -0.06960687786340714,
      -0.05952451750636101,
      0.03907313570380211,
      -0.01116103120148182,
      -0.020173190161585808,
      -0.06413330882787704
    ],
    [
      -0.0164871234446764,
      0.029955018311738968,
      -0.030757537111639977,
      -0.03802359476685524,
      -0.01122435461729765,
      0.07814501971006393,
      0.02726156637072563,
      -0.04835124313831329,
      0.09796926379203796,
      0.11380548030138016,
      0.008202776312828064,
      0.03813748434185982,
      0.056877609342336655,
      -0.08496750146150589,
      -0.03181254863739014,
      -0.051513057202100754,
      0.014243019744753838,
      -0.002667898777872324,
      0.0744367390871048,
      0.04024316370487213,
      -0.016008349135518074,
      0.0026950358878821135,
      0.004351921379566193,
      -0.030729638412594795,
      -0.04243111237883568,
      -0.00010237309470539913,
      -0.005112604703754187,
      -0.0030092389788478613,
      -0.03147979453206062,
      0.0018818775424733758,
      -0.012574917636811733,
      -0.0174732506275177,
      0.07692059874534607,
      -0.03422143682837486,
      0.004081378225237131,
      -0.055008213967084885,
      -0.04915953055024147,
      -0.05604308843612671,
      -0.0532088503241539,
      -0.010564099997282028,
      0.006398328114300966,
      0.026261145249009132,
      0.007374269887804985,
      -0.0413021557033062,
      0.01721050776541233,
      0.045777399092912674,
      0.036103639751672745,
      0.04525778815150261,
      -0.030826399102807045,
      -0.03403859585523605,
      0.0019131661392748356,
      -0.0284764152020216,
      -0.042936403304338455,
      0.022623714059591293,
      0.04318653419613838,
      0.007686134893447161,
      -0.042871586978435516,
      0.005478792358189821,
      -0.03248336538672447,
      -0.014941840432584286,
      0.037057094275951385,
      -0.02178781107068062,
      0.079118512570858,
      0.047863055020570755,
      0.0035539879463613033,
      -0.06761831045150757,
      0.03481167554855347,
      -0.014287600293755531,
      0.05303492769598961,
      -0.0133659103885293,
      0.015341883525252342,
      -0.015458057634532452,
      0.054050836712121964,
      -0.07703235745429993,
      0.005766609683632851,
      0.0020498388912528753,
      -0.06843339651823044,
      0.005271387752145529,
      -0.021168597042560577,
      -0.018458381295204163,
      0.04745763912796974,
      0.028622591868042946,
      0.0630045160651207,
      0.0297334436327219,
      0.019052885472774506,
      -0.009195858612656593,
      -0.05755515396595001,
      -0.0036694377195090055,
      0.0550643689930439,
      -0.025256335735321045,
      -0.012597004882991314,
      -0.014071493409574032,
      -0.033072542399168015,
      0.01778854988515377,
      0.026251453906297684,
      0.06286945939064026,
      0.04376411437988281,
      -0.06396690756082535,
      -0.03599055856466293,
      0.0845504030585289,
      0.07426328212022781,
      0.03797246143221855,
      -0.03999212011694908,
      -0.0004890534328296781,
      0.015405941754579544,
      0.019101915881037712,
      -0.04495637118816376,
      -0.0017640191363170743,
      0.027414727956056595,
      0.05449493229389191,
      -0.0566558800637722,
      -0.06486401706933975,
      -0.004999314900487661,
      -0.016948241740465164,
      0.09038364887237549,
      -0.06548867374658585,
      -0.0066084666177630424,
      0.026204587891697884,
      0.01704998128116131,
      0.0030025814194232225,
      0.0866551399230957,
      -0.041947461664676666,
      0.01205370295792818,
      -0.05359426513314247,
      -0.004559188149869442,
      -0.01619686931371689,
      -0.04893020913004875,
      0.01640491373836994,
      -0.05305768921971321,
      -0.043756790459156036,
      -0.004588732495903969,
      0.02580173686146736,
      -0.029699262231588364,
      -0.03295416012406349,
      -0.034886278212070465,
      -0.018169231712818146,
      0.023993629962205887,
      0.09824343025684357,
      0.011421173810958862,
      -0.011341473087668419,
      -0.006664050277322531,
      -0.04815526306629181,
      0.009288201108574867,
      -0.009836703538894653,
      -0.04732995107769966,
      0.05742660164833069,
      0.012537162750959396,
      0.014705182984471321,
      -0.009912477806210518,
      -0.05098289996385574,
      0.023258913308382034,
      0.0309338066726923,
      -0.04218834638595581,
      0.014154602773487568,
      0.0029886302072554827,
      -0.0638289749622345,
      -0.050839364528656006,
      0.01530811470001936,
      -0.06069459393620491,
      -0.025720421224832535,
      0.025749078020453453,
      0.05582887679338455,
      0.007998366840183735,
      -0.051507771015167236,
      0.043096836656332016,
      0.004687558859586716,
      0.03611048683524132,
      0.01634901575744152,
      0.009838991798460484,
      0.021755781024694443,
      -0.044776398688554764,
      -0.037630971521139145,
      0.05177167430520058,
      -0.06514674425125122,
      0.014001366682350636,
      0.006082715466618538,
      0.043836575001478195,
      0.0002819630317389965,
      0.04144125431776047,
      -0.024954911321401596,
      0.0066716913133859634,
      -0.04114505648612976,
      -0.051105987280607224,
      0.0450722761452198,
      -0.005822299979627132,
      0.0278512854129076,
      0.011775054037570953,
      -0.004389721900224686,
      0.018822774291038513,
      -0.04877281188964844,
      0.002036286285147071,
      0.025794457644224167,
      0.10998261719942093,
      -0.05889274924993515,
      -0.004869481548666954,
      -0.07220666855573654,
      0.023989738896489143,
      -0.048406898975372314,
      -0.010487528517842293,
      0.01797967031598091,
      0.005965887568891048,
      -0.007323636207729578,
      -0.0477563701570034,
      -0.05855908617377281,
      -0.006486423779278994,
      -0.0036701688077300787,
      -0.020983394235372543,
      -0.0003362791321706027,
      -0.017814259976148605,
      -0.0228329598903656,
      -0.07565805315971375,
      -0.07400531321763992,
      0.07602977752685547,
      0.022616351023316383,
      0.012288033030927181,
      -0.03298129141330719,
      0.01755598559975624,
      0.10630282759666443,
      -0.014210342429578304,
      0.059281278401613235,
      -0.020960163325071335,
      -0.0056524453684687614,
      0.03166256844997406,
      -0.0512673482298851,
      0.08520285785198212,
      -0.033995121717453,
      0.044608451426029205,
      -0.03679724037647247,
      -0.05916634947061539,
      -0.030032355338335037,
      -0.01308585423976183,
      -0.04101945832371712,
      -0.03858572244644165,
      -0.06497745960950851,
      0.018945975229144096,
      -0.025005627423524857,
      0.04387201368808746,
      -0.02041437476873398,
      0.011317303404211998,
      0.02550794743001461,
      0.01227517705410719,
      -0.03908971697092056,
      0.018170908093452454,
      -0.03941730409860611,
      0.04953937977552414,
      -0.00018158920283894986,
      0.04402956739068031,
      -0.0617525652050972,
      0.037707358598709106,
      0.033710867166519165,
      -0.049239084124565125,
      -0.05295528471469879,
      -0.019474785774946213,
      -0.04177223891019821,
      -0.014487743377685547,
      -0.01583498902618885,
      -0.006810635328292847,
      0.03345135599374771,
      -0.0712156593799591,
      -0.021127905696630478,
      -0.01792764849960804,
      -0.022455034777522087,
      -0.030962178483605385,
      -0.024122625589370728,
      -0.02167375385761261,
      -0.0023780360352247953,
      -0.031024716794490814,
      0.0027189357206225395,
      0.008262739516794682,
      -0.06348221749067307,
      -6.033951649442315e-08,
      0.0512467585504055,
      0.025930194184184074,
      -0.00583788612857461,
      -0.00985086802393198,
      -0.06449517607688904,
      0.06989681720733643,
      0.05679849535226822,
      -0.0047410717234015465,
      0.005571650341153145,
      0.02865743450820446,
      0.03327217698097229,
      0.04058557003736496,
      0.006873867474496365,
      0.0022181912790983915,
      0.053790509700775146,
      -0.0026796788442879915,
      0.02685472182929516,
      -0.09154290705919266,
      0.05430220440030098,
      -0.006653984077274799,
      0.0013149543665349483,
      0.018517915159463882,
      0.016130652278661728,
      -0.06441865861415863,
      0.031079862266778946,
      -0.01263105496764183,
      0.05277968943119049,
      0.005866815336048603,
      0.05376831814646721,
      0.055633991956710815,
      -0.048418644815683365,
      0.020991219207644463,
      -0.05240218713879585,
      0.017670121043920517,
      -0.02952425368130207,
      -0.016985591500997543,
      0.03943513706326485,
      -0.018485713750123978,
      0.009001044556498528,
      0.01044822670519352,
      0.0481252521276474,
      0.04009082913398743,
      0.005143113434314728,
      0.09292566776275635,
      0.0605604462325573,
      0.02272121049463749,
      0.007994815707206726,
      -0.07367496192455292,
      -0.036067813634872437,
      -0.020333174616098404,
      0.038456954061985016,
      0.061194323003292084,
      0.09135685861110687,
      0.007805049419403076,
      -0.020280806347727776,
      -0.005250944755971432,
      0.010954244993627071,
      0.01810137927532196,
      0.07605759054422379,
      0.007083771284669638,
      0.016491878777742386,
      -0.007771454285830259,
      -0.0031131887808442116,
      -0.03720220550894737,
      0.05590624734759331,
      0.05946620553731918,
      -0.019673096016049385,
      0.03642095625400543,
      0.04576566442847252,
      0.007097845897078514,
      0.04531416296958923,
      -0.02218710258603096,
      -0.019471798092126846,
      0.05846723914146423,
      0.021515265107154846,
      -0.016503332182765007,
      0.005946391262114048,
      -0.044852036982774734,
      -0.04272431135177612,
      -0.03434530273079872,
      0.0015462632291018963,
      -0.012673703953623772,
      -0.005126500502228737,
      -0.0020458619110286236,
      -0.10367247462272644,
      0.004111264366656542,
      0.05918758362531662,
      -0.022279122844338417,
      0.09389375895261765,
      0.09305057674646378,
      0.04863371700048447,
      -0.03282668814063072,
      -0.027694908902049065,
      -0.0009515152196399868,
      -0.022845743224024773,
      -0.07387623935937881,
      0.012533917091786861,
      -0.0893077552318573,
      -0.07834452390670776,
      -0.0014022851828485727,
      0.0041746534407138824,
      0.04522407054901123,
      0.07255032658576965,
      -0.05755969136953354,
      0.09609907865524292,
      0.0038133435882627964,
      0.018814312294125557,
      -0.030748525634407997,
      0.013350848108530045,
      0.03318469598889351,
      -0.0038108997978270054,
      -0.08488412201404572,
      -0.10628367215394974,
      0.03646601736545563,
      -0.08585163205862045,
      0.018873216584324837,
      -0.09255844354629517,
      0.07266561686992645,
      0.015334111638367176,
      0.009652589447796345,
      -0.01178840734064579,
      -8.316028106492013e-05,
      -0.03808119520545006,
      0.04320880025625229,
      0.007155616767704487,
      0.005054614972323179,
      0.024504218250513077,
      0.044762954115867615,
      -0.004210758954286575,
      -0.09077082574367523,
      -0.022095976397395134,
      0.03468051552772522,
      0.009744658134877682,
      0.05558503419160843,
      0.043967362493276596,
      0.02516317553818226,
      -0.007979072630405426,
      -0.026529831811785698,
      -0.024641120806336403,
      0.0426875539124012,
      -0.0434117391705513,
      -0.02205684408545494,
      0.06695374846458435,
      0.029453536495566368,
      0.011872205883264542,
      0.0037190953735262156,
      -0.035558268427848816,
      0.048531193286180496,
      -0.054912302643060684,
      -0.001036698347888887,
      -0.013971447013318539,
      -0.03550296649336815,
      0.005507525056600571,
      0.002652178518474102,
      -0.07966173440217972,
      -0.00563211040571332,
      -0.056502483785152435,
      0.0423247255384922,
      -0.03360443189740181,
      -0.012156173586845398,
      0.0011387597769498825,
      -0.02864892967045307,
      0.08933273702859879,
      -0.09318119287490845,
      -0.0880684182047844,
      -0.03005402721464634,
      0.005754734389483929,
      -0.039321888238191605,
      0.02039162442088127,
      0.03882458433508873,
      0.03318418562412262,
      0.0107118496671319,
      -0.027046168223023415,
      0.025259720161557198,
      0.03261330723762512,
      -0.10200200229883194,
      0.0007417237502522767,
      0.07990750670433044,
      -0.024841533973813057,
      0.04579770565032959,
      0.023207902908325195,
      0.03892206773161888,
      0.02009066380560398,
      0.07056678086519241,
      0.016321083530783653,
      0.022059708833694458,
      -0.03439541906118393,
      0.005540161859244108,
      -0.0070584616623818874,
      0.021903662011027336,
      -0.07528480887413025,
      0.03661938011646271,
      -0.030095882713794708,
      -0.15823860466480255,
      -0.01810523122549057,
      -0.10863775759935379,
      0.09901923686265945,
      0.03362292796373367,
      0.003720432287082076,
      -0.013117875903844833,
      0.029099002480506897,
      0.02311759814620018,
      -0.02053654193878174,
      -0.016617223620414734,
      0.00870827678591013,
      -0.04468485713005066,
      0.05699111893773079,
      -0.004582730121910572,
      -0.06988070905208588,
      -0.00728937704116106,
      0.02638361230492592,
      0.02319948375225067,
      -0.04122774302959442,
      -0.0616937130689621,
      -0.1259368360042572,
      0.05190431326627731,
      0.060246240347623825,
      0.05870968848466873,
      0.016339197754859924,
      0.014232327230274677,
      0.06680607795715332,
      -0.06515101343393326,
      -0.06218503788113594,
      -0.007766790688037872,
      0.004363067913800478,
      0.12801404297351837,
      0.011298755183815956,
      -0.005905170924961567,
      -0.047444164752960205,
      0.09279125183820724,
      -0.016040904447436333,
      0.1218227818608284
    ],
    [
      0.013273605145514011,
      0.046631019562482834,
      0.04350060224533081,
      0.01574481651186943,
      -0.03163032606244087,
      0.002737769391387701,
      -0.03197675198316574,
      0.060141414403915405,
      0.04270654916763306,
      0.02218816429376602,
      -0.08169390261173248,
      -0.07615034282207489,
      -0.055992450565099716,
      -0.13593164086341858,
      0.04709556698799133,
      -0.08158211410045624,
      -0.020420115441083908,
      0.057859987020492554,
      -0.06576263904571533,
      -0.014920766465365887,
      0.045682139694690704,
      0.0775875449180603,
      0.05097797513008118,
      -0.03896351531147957,
      -0.09413739293813705,
      -0.05350084230303764,
      -0.0186457559466362,
      -0.0017120204865932465,
      -0.0005440841778181493,
      -0.0041956594213843346,
      -0.07724130898714066,
      -0.05116196721792221,
      -0.021603049710392952,
      0.018289288505911827,
      0.008783303201198578,
      0.023642702028155327,
      -0.04423713684082031,
      0.028311720117926598,
      0.0028513462748378515,
      -0.003322132397443056,
      0.15323962271213531,
      0.06995387375354767,
      0.05758203938603401,
      -0.049753669649362564,
      0.04225698485970497,
      0.03652935102581978,
      0.07575260102748871,
      0.05241336673498154,
      -0.0006274104816839099,
      0.06600689888000488,
      0.018697500228881836,
      -0.034351952373981476,
      -0.08590883016586304,
      0.006096833851188421,
      0.03619154915213585,
      0.043295979499816895,
      -0.004593615885823965,
      -0.01635921373963356,
      -0.015475037507712841,
      0.04695407301187515,
      -0.031245175749063492,
      -0.05281892791390419,
      -0.07600347697734833,
      -0.01990031823515892,
      -0.051401570439338684,
      0.01687440648674965,
      -0.05870487913489342,
      -0.008719028905034065,
      0.01166019681841135,
      0.021998193114995956,
      -0.07834363728761673,
      -0.03513188660144806,
      -0.05264006555080414,
      -0.012944879941642284,
      0.00016843898629304022,
      -0.024867257103323936,
      0.07951552420854568,
      -0.06886732578277588,
      0.030528971925377846,
      0.04127451777458191,
      -0.046911194920539856,
      -0.1551603376865387,
      -0.003930519800633192,
      -0.00769765954464674,
      0.019887369126081467,
      0.015292556956410408,
      -0.0075047495774924755,
      -0.028667107224464417,
      0.0031295788940042257,
      -0.019257256761193275,
      0.03109731338918209,
      -0.062265556305646896,
      -0.04273183271288872,
      -0.020267892628908157,
      -0.0094660185277462,
      0.01885484904050827,
      -0.033848509192466736,
      -0.027500223368406296,
      -0.011928336694836617,
      -0.011571927927434444,
      -0.003909495659172535,
      -0.008633929304778576,
      0.00856855884194374,
      0.027931924909353256,
      0.0028629538137465715,
      -0.05568026751279831,
      -0.03356027603149414,
      0.02117053233087063,
      0.047795869410037994,
      -0.08269979059696198,
      0.0659494400024414,
      -0.009647867642343044,
      -0.08712100237607956,
      -0.0213016290217638,
      0.013112829066812992,
      0.022602759301662445,
      0.05314869433641434,
      -0.09739986062049866,
      -0.003492923453450203,
      -0.028818121179938316,
      0.08336270600557327,
      0.08609230071306229,
      -0.039368338882923126,
      -0.056651558727025986,
      -0.03794238343834877,
      -0.02540050633251667,
      0.008267350494861603,
      0.00018973049009218812,
      -0.10789748281240463,
      -0.011004103347659111,
      0.024906376376748085,
      -0.07061785459518433,
      0.07312894612550735,
      -0.03472651541233063,
      0.04378579556941986,
      0.007310414221137762,
      0.07831551879644394,
      -0.028942177072167397,
      -0.14675721526145935,
      0.021053878590464592,
      0.024763325229287148,
      -0.05670628324151039,
      -0.03527462109923363,
      0.0418364442884922,
      0.0024467858020216227,
      0.05940782651305199,
      0.03787466138601303,
      0.05048786476254463,
      0.06085607782006264,
      -0.03344205766916275,
      -0.005730634555220604,
      0.03686734661459923,
      0.007564970292150974,
      0.04276805743575096,
      0.017791813239455223,
      0.007464794907718897,
      -0.002550197299569845,
      0.018472611904144287,
      0.030000971630215645,
      0.011918358504772186,
      0.057530228048563004,
      0.01209945883601904,
      0.10243118554353714,
      -0.018459485843777657,
      -0.020880024880170822,
      0.049042098224163055,
      0.010730709880590439,
      0.018808143213391304,
      -0.09098638594150543,
      -0.0054172007367014885,
      0.03147759661078453,
      -0.008072583004832268,
      0.025727497413754463,
      -0.04942735657095909,
      -0.08090896159410477,
      -0.08089698106050491,
      0.00481853261590004,
      0.04794419929385185,
      -0.014170832000672817,
      0.01561061292886734,
      -0.10025789588689804,
      0.035117220133543015,
      -0.0027645877562463284,
      -0.011047950014472008,
      0.018690187484025955,
      0.01197093352675438,
      -0.016193978488445282,
      -0.004505136050283909,
      -0.034756314009428024,
      -0.0009551059920340776,
      0.019183572381734848,
      -0.06663725525140762,
      -0.004097735974937677,
      -0.002776604611426592,
      -0.06667947769165039,
      0.018324071541428566,
      0.01047423668205738,
      -0.052207205444574356,
      -0.004229281563311815,
      -0.011953352950513363,
      0.018652278929948807,
      0.015054968185722828,
      0.02461480349302292,
      -0.05508984625339508,
      0.03190826252102852,
      -0.03004383109509945,
      -0.03643035888671875,
      -0.010637945495545864,
      -0.009109821170568466,
      -0.0039938814006745815,
      -0.025366395711898804,
      -0.01450617890805006,
      0.0007332322420552373,
      -0.07177592813968658,
      0.017456555739045143,
      0.08220408856868744,
      -0.012277407571673393,
      0.02611268311738968,
      -0.004645615816116333,
      0.014917521737515926,
      -0.014026629738509655,
      -0.05614134296774864,
      0.08705444633960724,
      0.05589151382446289,
      0.10013306140899658,
      0.03109128586947918,
      0.00776737229898572,
      -0.07775133103132248,
      0.09288027137517929,
      0.0465727336704731,
      -0.07958562672138214,
      -0.016923340037465096,
      0.03875125199556351,
      -0.0013227807357907295,
      0.015956196933984756,
      0.00759401498362422,
      -0.03860979154706001,
      0.013908667489886284,
      0.032606836408376694,
      -0.010168333537876606,
      -0.0009373678476549685,
      -0.04012351110577583,
      0.0831967145204544,
      0.03456069156527519,
      -0.02831420861184597,
      -0.017879698425531387,
      -0.016628537327051163,
      -0.03991377726197243,
      0.02536144107580185,
      0.0344269797205925,
      0.0360625796020031,
      -0.035627879202365875,
      -0.003078352427110076,
      0.02714483067393303,
      -0.03246257081627846,
      -0.05604059249162674,
      0.014997155405580997,
      0.001587745500728488,
      0.10847779363393784,
      0.028170274570584297,
      -0.021003490313887596,
      0.11523392051458359,
      0.02336815558373928,
      -0.07797742635011673,
      0.03290461003780365,
      0.008441383019089699,
      0.053752169013023376,
      -0.027080800384283066,
      -0.020975086838006973,
      0.01098806131631136,
      -0.022184668108820915,
      0.04055332764983177,
      -0.06296992301940918,
      0.019675947725772858,
      0.03313116729259491,
      0.06890824437141418,
      -0.018288735300302505,
      0.004396104719489813,
      -0.036227017641067505,
      -0.09073459357023239,
      0.0760926902294159,
      -0.0029634565580636263,
      0.08145775645971298,
      -0.016628898680210114,
      0.020555028691887856,
      -0.09424259513616562,
      0.001031500636599958,
      0.04830034449696541,
      0.039084501564502716,
      0.0005911391344852746,
      -0.053099747747182846,
      0.028188291937112808,
      -0.008587611839175224,
      0.03923477977514267,
      0.03742112219333649,
      0.04544483870267868,
      0.09523771703243256,
      0.04180723428726196,
      0.0913987010717392,
      0.013764670118689537,
      -0.005538795609027147,
      0.012641002424061298,
      0.049190811812877655,
      0.019711419939994812,
      0.01249813474714756,
      -0.07955431193113327,
      -0.0038615434896200895,
      -0.0017190874787047505,
      0.030114687979221344,
      -0.02585262805223465,
      0.03985624387860298,
      -0.06658653914928436,
      -0.003783722408115864,
      0.040687862783670425,
      0.040767114609479904,
      0.041895873844623566,
      0.004384798929095268,
      0.0063832770101726055,
      -0.017312701791524887,
      -0.03019619546830654,
      -0.021990038454532623,
      -0.029279841110110283,
      0.0782073587179184,
      0.04933328554034233,
      0.017990201711654663,
      0.022567585110664368,
      -0.05956759303808212,
      3.0279243219411e-06,
      0.009426416829228401,
      0.01908671297132969,
      -0.06270081549882889,
      0.006366964429616928,
      0.02789573185145855,
      -0.028026694431900978,
      0.060933541506528854,
      0.006772650871425867,
      -0.05572899803519249,
      0.005287228152155876,
      0.012022734619677067,
      0.011315809562802315,
      -0.060722965747117996,
      0.0003331831539981067,
      0.007546147797256708,
      -0.012777920812368393,
      -0.051505833864212036,
      0.042854178696870804,
      -0.008174324408173561,
      0.08581055700778961,
      -0.007935531437397003,
      -0.003438538173213601,
      0.03267838805913925,
      0.05948252230882645,
      0.05515227094292641,
      0.006607608869671822,
      -0.006534157320857048,
      -0.04069142788648605,
      -0.030356017872691154,
      0.04589254409074783,
      0.020742228254675865,
      0.04026526212692261,
      -0.0029446997214108706,
      0.01714947447180748,
      0.02392580360174179,
      -0.016184190288186073,
      0.07065311074256897,
      0.0364772193133831,
      -0.049282506108284,
      -0.06717221438884735,
      -0.0019421738106757402,
      0.02959207445383072,
      -0.022602546960115433,
      0.020625008270144463,
      0.06308876723051071,
      -0.008353741839528084,
      0.05694872885942459,
      0.04690651223063469,
      0.04503589868545532,
      0.012778399512171745,
      -0.0798913910984993,
      -0.07630342990159988,
      0.03505253046751022,
      -0.01734728366136551,
      0.04229849576950073,
      0.05918775126338005,
      -0.060552410781383514,
      0.028976406902074814,
      0.10562191903591156,
      -0.01435878686606884,
      0.024534130468964577,
      0.018020007759332657,
      -0.04681271314620972,
      -0.02761864848434925,
      -0.005315862596035004,
      -0.04016413912177086,
      0.018234316259622574,
      0.07208380848169327,
      0.01422040443867445,
      0.14844822883605957,
      0.0006709269946441054,
      -0.03075612150132656,
      -0.024428395554423332,
      -0.010286657139658928,
      0.03651397302746773,
      -0.025325976312160492,
      0.05701958388090134,
      -0.006781642325222492,
      -0.0034274409990757704,
      -0.08463067561388016,
      -0.0939875915646553,
      0.011262721382081509,
      -0.05174626410007477,
      -0.031220298260450363,
      -0.011914948001503944,
      0.004186252597719431,
      -0.06606488674879074,
      -0.016117876395583153,
      0.030614197254180908,
      -0.053216468542814255,
      0.005437434185296297,
      -0.10736062377691269,
      -0.03542404621839523,
      0.05237813666462898,
      0.007130083162337542,
      -0.05121098458766937,
      -0.01221543550491333,
      -0.04254314675927162,
      -0.06739377975463867,
      -0.030245155096054077,
      0.00040191476000472903,
      -0.037439245730638504,
      0.049920447170734406,
      0.026783159002661705,
      0.0015945794293656945,
      -0.020997511222958565,
      -0.014206089079380035,
      0.07082147151231766,
      -0.029054006561636925,
      0.0033320682123303413,
      0.06070159003138542,
      0.04274700954556465,
      0.05431294068694115,
      0.002887977520003915,
      -0.05029622092843056,
      -0.008471309207379818,
      0.02643711119890213,
      0.003547034924849868,
      -0.010728244669735432,
      -0.057644572108983994,
      -0.04082290828227997,
      -0.014092258177697659,
      0.03238677605986595,
      -0.10129410773515701,
      -0.0057155233807861805,
      0.11471596360206604,
      0.044693149626255035,
      0.04808017984032631,
      0.12808743119239807,
      0.10892987251281738,
      -0.07486158609390259,
      -0.015081743709743023,
      -0.06861261278390884,
      0.006436676252633333,
      -0.038321107625961304,
      -0.025463517755270004,
      0.009600773453712463,
      0.0710945799946785,
      -0.04632077366113663,
      -0.01880429871380329,
      0.03213217854499817,
      0.020988931879401207,
      -0.056217730045318604,
      -0.017340857535600662,
      0.019108954817056656,
      0.027294375002384186,
      0.06873549520969391,
      0.032867077738046646,
      -0.057007815688848495,
      -0.05507123842835426,
      -0.01684470847249031,
      -0.07920395582914352,
      -0.05269397050142288,
      -0.05205153673887253,
      -0.032612718641757965,
      -0.01725545898079872,
      -0.0375249944627285,
      0.14693836867809296,
      0.013245548121631145,
      -0.041817303746938705,
      -0.09706428647041321,
      -0.003220596583560109,
      -0.0631820484995842,
      0.017606405541300774,
      -0.02932012639939785,
      -0.06060030311346054,
      0.03165934979915619,
      -0.037666626274585724,
      -0.00706203980371356,
      -0.019180044531822205,
      -0.0014257376315072179,
      -0.03189613297581673,
      -0.017329830676317215,
      0.005365177523344755,
      -0.028164055198431015
    ],
    [
      -0.008841466158628464,
      0.09444928914308548,
      0.023100366815924644,
      0.023097045719623566,
      -0.05038004741072655,
      0.06479865312576294,
      0.05216430500149727,
      -0.00369159784168005,
      0.015657976269721985,
      -0.03848646208643913,
      0.024349713698029518,
      -0.013711491599678993,
      -0.08431632071733475,
      -0.07596183568239212,
      -0.047580260783433914,
      0.07240315526723862,
      0.036969754844903946,
      0.0689309760928154,
      0.05696683004498482,
      0.004302990157157183,
      -0.00032010648283176124,
      -0.061234503984451294,
      -0.012835063971579075,
      -0.01907714083790779,
      -0.05393143743276596,
      -0.04130362346768379,
      -0.09344780445098877,
      0.07960103452205658,
      0.02084696851670742,
      -0.03357071802020073,
      0.018722934648394585,
      0.003869748441502452,
      0.02461673878133297,
      -0.05087706074118614,
      0.11153250187635422,
      0.021304728463292122,
      0.0014753257855772972,
      -0.020301222801208496,
      -0.031176794320344925,
      0.0279436856508255,
      -0.057001400738954544,
      0.024454427883028984,
      -0.033654890954494476,
      0.048201944679021835,
      0.07422555983066559,
      -0.0007903605583123863,
      -0.026717066764831543,
      -0.04463895410299301,
      -0.06134007126092911,
      -0.05039406940340996,
      0.06338148564100266,
      -0.050258569419384,
      0.07415592670440674,
      0.07024005055427551,
      0.0260055810213089,
      0.006037494633346796,
      -0.010045012459158897,
      0.015510725788772106,
      0.03251378610730171,
      0.02295973151922226,
      -0.012724877335131168,
      -0.01940547302365303,
      0.015969714149832726,
      0.03639401122927666,
      -0.033946163952350616,
      -0.07183968275785446,
      -0.02355547621846199,
      -0.004875351209193468,
      0.021855825558304787,
      0.04427368938922882,
      -0.030750377103686333,
      -0.02705245278775692,
      -0.055316656827926636,
      0.0026141454000025988,
      0.0945381224155426,
      0.002984415739774704,
      0.0026210318319499493,
      0.021577781066298485,
      0.011648495681583881,
      0.02622918412089348,
      0.03806663304567337,
      -0.10189966857433319,
      0.037116821855306625,
      -0.025089388713240623,
      -0.044175900518894196,
      0.039191022515296936,
      -0.04626582935452461,
      -0.04793763905763626,
      -0.03926648199558258,
      0.04297059029340744,
      -0.023429540917277336,
      -0.05221664533019066,
      -0.006480242125689983,
      0.011136449873447418,
      0.06094773858785629,
      0.029977893456816673,
      -0.007918858900666237,
      0.0157930888235569,
      0.08084268867969513,
      0.007089858874678612,
      0.0015061472076922655,
      -0.06775060296058655,
      -0.017454592511057854,
      -0.048236310482025146,
      -0.023233473300933838,
      0.04064807668328285,
      -0.04618950933218002,
      -0.04676993191242218,
      0.04216831177473068,
      -0.061307642608881,
      0.030571915209293365,
      0.05827071890234947,
      -0.1428309828042984,
      -0.13232339918613434,
      -0.047345783561468124,
      0.0051910486072301865,
      -0.10148047655820847,
      -0.0296145249158144,
      0.06005217134952545,
      0.040215495973825455,
      -0.0006096884026192129,
      0.00914154201745987,
      -0.025347093120217323,
      0.006344403140246868,
      0.028880560770630836,
      0.007992526516318321,
      -0.0311795175075531,
      -0.08004648983478546,
      -0.023314090445637703,
      -0.023508237674832344,
      -0.030905678868293762,
      -0.009524551220238209,
      0.049962516874074936,
      -0.004290036391466856,
      -0.025553792715072632,
      0.028208045288920403,
      0.0026102582924067974,
      0.06298378109931946,
      0.05195023864507675,
      -0.0916266217827797,
      0.03259309008717537,
      0.015824809670448303,
      0.050369083881378174,
      0.027901897206902504,
      0.00754032377153635,
      0.09652028232812881,
      0.031087517738342285,
      0.05224579945206642,
      0.00945539865642786,
      0.02404092252254486,
      0.07755233347415924,
      0.02127283252775669,
      -0.012130116112530231,
      -0.0012079011648893356,
      0.003537579905241728,
      0.0083840386942029,
      0.03984172269701958,
      0.014330213889479637,
      -0.010953598655760288,
      -0.007083514705300331,
      0.03418387845158577,
      -0.004867243580520153,
      -0.04858250543475151,
      -0.04197730869054794,
      0.04260920360684395,
      0.06501049548387527,
      -0.06951127201318741,
      0.05377498269081116,
      0.041846126317977905,
      -0.0021394770592451096,
      -0.014596281573176384,
      -0.03422222658991814,
      0.06298834830522537,
      0.06585743278265,
      0.016043685376644135,
      -0.005737366154789925,
      0.043064579367637634,
      0.023802103474736214,
      -0.002165059791877866,
      0.022149845957756042,
      0.017102904617786407,
      0.07325395196676254,
      -0.012893847189843655,
      -0.030405284836888313,
      0.03473392128944397,
      -0.048499517142772675,
      -0.05263948813080788,
      -0.01197119988501072,
      0.0016999604413285851,
      -0.027062173932790756,
      -0.07339582592248917,
      -0.04320840165019035,
      0.013646780513226986,
      0.013320453464984894,
      0.08177902549505234,
      -0.015020682476460934,
      0.02484687976539135,
      0.005585409700870514,
      -0.024190232157707214,
      0.01806333288550377,
      -0.0652247965335846,
      -0.044422127306461334,
      0.013121504336595535,
      -0.036444809287786484,
      -0.07880167663097382,
      -0.008161674253642559,
      0.016481950879096985,
      0.03071928583085537,
      -0.03691098839044571,
      0.02194693312048912,
      0.005227823741734028,
      -0.037043794989585876,
      -0.023853767663240433,
      0.01620040461421013,
      -0.0563562847673893,
      -0.032640013843774796,
      0.000735816138330847,
      0.06888207793235779,
      -0.03827474266290665,
      -0.10891436785459518,
      0.051467690616846085,
      -0.03277314454317093,
      0.029520561918616295,
      -0.052542299032211304,
      -0.10503845661878586,
      -0.015976663678884506,
      0.004612849093973637,
      0.03291817754507065,
      0.01813453435897827,
      -0.020849749445915222,
      -0.027097759768366814,
      -0.05359674617648125,
      -0.04695453494787216,
      -0.06274917721748352,
      -0.00838389154523611,
      -0.06397238373756409,
      0.05282527953386307,
      -0.04167388007044792,
      -0.08690372854471207,
      0.043632183223962784,
      0.018546057865023613,
      -0.05260901153087616,
      -0.030798781663179398,
      0.06379693746566772,
      -0.013754479587078094,
      -0.03660187870264053,
      0.01820860616862774,
      -0.05040478706359863,
      0.024880308657884598,
      0.04133235290646553,
      -0.015145206823945045,
      0.022669291123747826,
      0.07750020176172256,
      -0.10096985101699829,
      -0.057031888514757156,
      0.00702853174880147,
      -0.08768681436777115,
      0.05105284973978996,
      0.046496059745550156,
      -0.05097520723938942,
      0.032560814172029495,
      -0.008075558580458164,
      -0.01882855035364628,
      0.004157106392085552,
      0.08235212415456772,
      0.05679568648338318,
      -0.03601692616939545,
      0.04026268795132637,
      -0.00942126102745533,
      0.06790191680192947,
      0.05798190459609032,
      0.05783141404390335,
      -0.005475666373968124,
      -0.08330068737268448,
      -0.05047481879591942,
      -0.05564818158745766,
      -0.016647420823574066,
      0.092833511531353,
      -0.031098227947950363,
      0.04588763415813446,
      0.08243313431739807,
      0.005234334617853165,
      0.06751790642738342,
      -0.08194133639335632,
      0.01234753429889679,
      0.012466981075704098,
      0.10180823504924774,
      -0.02841215580701828,
      -0.01949702762067318,
      -0.05990024283528328,
      -0.1319819688796997,
      0.01670173369348049,
      0.06136313080787659,
      -0.02306552790105343,
      0.060094594955444336,
      -0.009537450037896633,
      0.06950646638870239,
      0.010052950121462345,
      -0.010509196668863297,
      -0.037816233932971954,
      -0.0017951874760910869,
      -0.02043030969798565,
      0.0773286521434784,
      -0.035327766090631485,
      0.03192972391843796,
      0.011000115424394608,
      0.020486751571297646,
      -0.08169080317020416,
      -0.022359758615493774,
      -0.025397490710020065,
      0.044328682124614716,
      -0.0043684872798621655,
      0.03334733843803406,
      0.050508949905633926,
      0.06877061724662781,
      0.00233064335770905,
      -0.03420696035027504,
      0.05604639649391174,
      -0.018066899850964546,
      -0.08977435529232025,
      0.013616087846457958,
      0.03139045834541321,
      0.026622947305440903,
      -0.015979178249835968,
      -0.03866959735751152,
      0.028891921043395996,
      -0.003425467759370804,
      -0.04688255861401558,
      0.033525269478559494,
      0.04820537939667702,
      -0.05717751756310463,
      -0.11931240558624268,
      -0.007822277024388313,
      0.04867582395672798,
      -0.006784379016608,
      -0.07544922083616257,
      -0.014384797774255276,
      0.05187506601214409,
      0.044468045234680176,
      -0.08452827483415604,
      0.030409205704927444,
      0.034099943935871124,
      0.049688760191202164,
      -0.050699591636657715,
      -0.10036139190196991,
      -0.037915874272584915,
      -0.057192496955394745,
      0.04101832956075668,
      0.06144780293107033,
      0.03717876225709915,
      0.018477164208889008,
      -0.03597019612789154,
      0.021061956882476807,
      0.031552184373140335,
      0.02986922673881054,
      -0.052033163607120514,
      0.0527183823287487,
      0.057775434106588364,
      -0.024113094434142113,
      0.0012412023497745395,
      -0.03281841054558754,
      0.03945644944906235,
      0.0065956320613622665,
      -0.022489318624138832,
      0.014088986441493034,
      -0.013295907527208328,
      0.017413053661584854,
      0.02159883640706539,
      -0.01871321350336075,
      -0.006232847459614277,
      0.07007770240306854,
      0.005002255085855722,
      0.01687503606081009,
      0.023968961089849472,
      -0.044914718717336655,
      0.052259862422943115,
      0.10592654347419739,
      -0.0025823249015957117,
      0.033477287739515305,
      0.023312659934163094,
      -0.0007651817868463695,
      0.04354768246412277,
      -0.023444080725312233,
      0.011647051200270653,
      -0.049785222858190536,
      -0.009118917398154736,
      0.0355839729309082,
      -0.00858866237103939,
      0.0004176929942332208,
      -0.045530855655670166,
      0.04534974694252014,
      0.050442490726709366,
      0.07551361620426178,
      -0.025671426206827164,
      -0.1056237518787384,
      0.05847526341676712,
      0.08655428886413574,
      -0.04017404466867447,
      0.00750956404954195,
      0.015701282769441605,
      0.0047599696554243565,
      0.04858570173382759,
      -0.06297233700752258,
      -0.04093490168452263,
      -0.05315777659416199,
      -0.011072429828345776,
      -0.05116746574640274,
      0.021428627893328667,
      -0.03982538729906082,
      0.018171649426221848,
      -0.038208406418561935,
      0.035882215946912766,
      -0.05401275306940079,
      -0.02530224807560444,
      -0.004152655601501465,
      -0.008880273438990116,
      0.04055793955922127,
      0.00017337333702016622,
      -0.012620500288903713,
      -0.01896125264465809,
      0.019998257979750633,
      -0.05470497906208038,
      -0.009807397611439228,
      0.014605769887566566,
      0.0844094455242157,
      -0.029498878866434097,
      0.03832070529460907,
      -0.006052000448107719,
      0.12174613773822784,
      -0.05476647987961769,
      0.010714525356888771,
      0.08272255212068558,
      0.03273667022585869,
      0.016043782234191895,
      -0.003995044156908989,
      0.03206908702850342,
      -0.02252998948097229,
      -0.0348946638405323,
      -0.007862750440835953,
      0.009739384986460209,
      -0.054878056049346924,
      -0.021048525348305702,
      0.06501147896051407,
      0.07287970185279846,
      0.010567281395196915,
      0.04573855176568031,
      0.007261017337441444,
      0.0771259292960167,
      0.014961772598326206,
      0.04085954651236534,
      0.043702248483896255,
      0.030957955867052078,
      0.04661746695637703,
      -0.05299008637666702,
      0.044851478189229965,
      -0.008781670592725277,
      0.0410715788602829,
      -0.040881410241127014,
      0.010186577215790749,
      0.10792717337608337,
      -0.13762372732162476,
      -0.047085121273994446,
      -0.06096404790878296,
      -0.05673843249678612,
      0.0536443367600441,
      0.0642620250582695,
      0.011330869048833847,
      0.04154908284544945,
      -0.08302929997444153,
      0.037978991866111755,
      -0.03321293741464615,
      -0.00019993438036181033,
      0.12089002877473831,
      0.03270168974995613,
      -0.04790598154067993,
      -0.029926616698503494,
      -0.1229775920510292,
      -0.017056312412023544,
      0.016695605590939522,
      -0.02949037216603756,
      6.712878530379385e-05,
      0.05464513227343559,
      -0.0679779201745987,
      0.006273804698139429,
      0.021624796092510223,
      -0.012258495204150677,
      0.03678284212946892,
      0.10727683454751968,
      0.009606841020286083,
      0.030496886000037193,
      0.11463923752307892,
      0.03381676599383354,
      0.03984517604112625,
      0.04887032508850098,
      -0.022028276696801186,
      -0.02488420531153679,
      -0.053548410534858704,
      -0.006002555135637522,
      -0.05232386663556099,
      0.07306988537311554,
      0.011243043467402458,
      0.049009911715984344,
      -0.05836372449994087
    ],
    [
      -0.015501747839152813,
      0.0504884235560894,
      0.010416985489428043,
      0.04536440968513489,
      -0.05507173016667366,
      0.004065350629389286,
      0.11739462614059448,
      0.02509000152349472,
      0.04023999348282814,
      -0.04935068637132645,
      0.07750578969717026,
      -0.016045188531279564,
      0.010416126810014248,
      0.07425786554813385,
      0.08485092222690582,
      -0.01791548915207386,
      0.004919630941003561,
      0.019829079508781433,
      -0.021752409636974335,
      0.05446586385369301,
      -0.005853570532053709,
      0.07463081926107407,
      -0.029859552159905434,
      0.019546402618288994,
      0.07834984362125397,
      0.01577506773173809,
      -0.07338358461856842,
      0.004574818070977926,
      -0.03163051977753639,
      -0.025884296745061874,
      -0.020871983841061592,
      -0.04628179967403412,
      0.02201846055686474,
      -0.002145214006304741,
      -0.03974867984652519,
      0.025789417326450348,
      -0.047159336507320404,
      0.06734351068735123,
      -0.011179598979651928,
      0.03500746190547943,
      0.028324633836746216,
      -0.03378687798976898,
      -0.006680427584797144,
      -0.009211590513586998,
      -0.02065885066986084,
      0.016882523894309998,
      0.026640066877007484,
      -0.051118817180395126,
      0.06574549525976181,
      -0.02524501644074917,
      -0.049628641456365585,
      0.0044430922716856,
      0.01926579885184765,
      -0.023809367790818214,
      -0.02791101112961769,
      -0.00615293812006712,
      -0.026763539761304855,
      0.08405984938144684,
      0.011338651180267334,
      0.06332582235336304,
      0.01713876612484455,
      -0.008562386967241764,
      0.0013356897979974747,
      -0.015336449258029461,
      0.012300577014684677,
      -0.01920960284769535,
      0.010332376696169376,
      0.019005943089723587,
      0.01693733036518097,
      0.06890322268009186,
      0.03231000527739525,
      -0.06469625234603882,
      0.06358005106449127,
      -0.07350657135248184,
      0.10269631445407867,
      -0.05380313843488693,
      0.08178551495075226,
      0.048972561955451965,
      -0.07842578738927841,
      0.04096030443906784,
      0.011045546270906925,
      0.012576199136674404,
      -0.0133045744150877,
      -0.024241529405117035,
      0.002518191235139966,
      -0.03994559869170189,
      0.022636715322732925,
      0.020365091040730476,
      -0.04873032122850418,
      0.008144920691847801,
      -0.03070063330233097,
      -0.01589179039001465,
      -0.014609163627028465,
      0.04842643067240715,
      0.07289618998765945,
      -0.017209835350513458,
      0.0361134298145771,
      0.02094109356403351,
      -0.00906861387193203,
      0.04174519702792168,
      0.008958280086517334,
      -0.016690852120518684,
      0.07155387848615646,
      0.029055243358016014,
      0.01869574747979641,
      -0.02925320528447628,
      -0.040070194751024246,
      0.002645868342369795,
      -0.11322708427906036,
      -0.056740257889032364,
      0.036726854741573334,
      0.08667958527803421,
      0.04789131134748459,
      0.02324647456407547,
      0.07616457343101501,
      0.05561279505491257,
      0.04283871501684189,
      0.049010973423719406,
      0.035170894116163254,
      0.07531163841485977,
      0.013098258525133133,
      -0.04310162737965584,
      0.034215617924928665,
      0.02788499742746353,
      -0.019080618396401405,
      0.024117855355143547,
      0.027571700513362885,
      0.020585088059306145,
      -0.005799665115773678,
      0.02510553039610386,
      -0.0027490488719195127,
      0.023238593712449074,
      0.03301911801099777,
      0.05526776239275932,
      0.0006531303515657783,
      0.03325719013810158,
      -0.12256218492984772,
      -0.10323477536439896,
      -0.025879980996251106,
      -0.06914226710796356,
      0.13375434279441833,
      0.05122024938464165,
      -0.06536789983510971,
      -0.06771776080131531,
      -0.026090286672115326,
      -0.06482557207345963,
      -0.053411517292261124,
      -0.011069189757108688,
      -0.0603310763835907,
      0.0006418325938284397,
      -0.049377720803022385,
      0.03645794466137886,
      -0.015706446021795273,
      -0.09924312680959702,
      0.05584844946861267,
      -0.07961594313383102,
      0.08777820318937302,
      0.021058766171336174,
      -0.03332043066620827,
      0.01905907317996025,
      -0.005797066725790501,
      -0.0417252741754055,
      0.058567024767398834,
      0.054784003645181656,
      -0.01773728057742119,
      -0.05866670981049538,
      -0.019775265827775,
      0.04241619259119034,
      -0.03302903100848198,
      -0.0339338518679142,
      -0.004192944150418043,
      0.01627006195485592,
      0.06067870184779167,
      -0.02853190340101719,
      0.05486884340643883,
      0.07228247821331024,
      0.06939394772052765,
      -8.647471986478195e-05,
      0.03177111595869064,
      0.034040920436382294,
      -0.024865420535206795,
      -0.08105157315731049,
      -0.05553403124213219,
      -0.05725448578596115,
      -0.02460748702287674,
      0.00752765079960227,
      0.01907501183450222,
      0.03588700294494629,
      0.05295128747820854,
      -0.018950851634144783,
      -0.05274378880858421,
      -0.06011739373207092,
      0.07251688092947006,
      -0.06239082291722298,
      -0.03947342187166214,
      0.008861936628818512,
      -0.12898090481758118,
      -0.056700561195611954,
      -0.030919920653104782,
      -0.017214756458997726,
      -0.0017275749705731869,
      0.07129612565040588,
      0.012953321449458599,
      -0.006455454509705305,
      0.019147738814353943,
      0.010008892975747585,
      0.1002366840839386,
      -0.03511316329240799,
      0.01362717803567648,
      -0.02770388312637806,
      0.056426551192998886,
      0.04925249144434929,
      -0.07843711972236633,
      -0.019740218296647072,
      0.012848067097365856,
      0.04742996022105217,
      0.014755532145500183,
      -0.03008727915585041,
      -0.007692956365644932,
      0.03344106301665306,
      0.019505076110363007,
      -0.08016069233417511,
      0.01772603951394558,
      -0.019924383610486984,
      -0.10544119030237198,
      0.02887999452650547,
      -0.03481294959783554,
      -0.05035877600312233,
      0.010434777475893497,
      0.05507837235927582,
      0.0049977982416749,
      -0.02041522227227688,
      0.0165592972189188,
      -0.0174617450684309,
      0.014287285506725311,
      0.07030166685581207,
      -0.054767340421676636,
      0.09182261675596237,
      0.041247136890888214,
      0.040253739804029465,
      0.01470651663839817,
      -0.04877243936061859,
      0.03987012431025505,
      -0.037259623408317566,
      -0.046896398067474365,
      -0.0706133246421814,
      -0.03930377960205078,
      -0.12639334797859192,
      -0.0548119843006134,
      0.004635624121874571,
      0.06280549615621567,
      -0.004208718426525593,
      0.007704290095716715,
      -0.007296035997569561,
      -0.0007324474863708019,
      -0.0032696055714040995,
      0.09647059440612793,
      0.1254606693983078,
      -0.017866967245936394,
      0.04166131839156151,
      -0.05615182965993881,
      -0.053768083453178406,
      -0.002956545911729336,
      -0.013827095739543438,
      -0.017641188576817513,
      -0.014039656147360802,
      0.06040584668517113,
      -0.05035411939024925,
      0.037349849939346313,
      -0.008997886441648006,
      0.10426445305347443,
      0.07409331202507019,
      -0.00414987001568079,
      -0.027015889063477516,
      0.028434639796614647,
      0.026782987639307976,
      -0.08140121400356293,
      0.0310394074767828,
      0.0858440175652504,
      0.0040361229330301285,
      0.021498387679457664,
      0.05548914149403572,
      -0.023215433582663536,
      -0.1079721674323082,
      -0.026608331128954887,
      -0.05571543052792549,
      -0.003084904747083783,
      -0.0049975295551121235,
      -0.030583566054701805,
      -0.06074431166052818,
      -0.0006607654504477978,
      -0.015916857868433,
      0.03703876957297325,
      -0.005207977257668972,
      -0.09220757335424423,
      0.0914531797170639,
      -0.025762688368558884,
      0.016235439106822014,
      -0.06535620987415314,
      -0.022872667759656906,
      -0.0021210082340985537,
      0.037465400993824005,
      -0.01658054254949093,
      0.04513529688119888,
      0.03978985548019409,
      -0.0032430628780275583,
      0.02040890045464039,
      0.04924534633755684,
      0.03888842463493347,
      -0.10357260704040527,
      0.04118872433900833,
      0.09397830814123154,
      0.04080820828676224,
      0.07452810555696487,
      -0.039240021258592606,
      0.048195626586675644,
      0.09973970055580139,
      0.05465063825249672,
      0.008975743316113949,
      0.04149990528821945,
      -0.044536858797073364,
      -0.026279395446181297,
      0.004196737892925739,
      0.0646742433309555,
      -0.00455467589199543,
      0.01651054061949253,
      0.015827003866434097,
      0.040905192494392395,
      0.046660032123327255,
      -0.026289047673344612,
      -0.10028737038373947,
      0.015323140658438206,
      -0.010178571566939354,
      0.008864260278642178,
      -0.08650126308202744,
      -0.04138965904712677,
      -0.030327733606100082,
      -0.020492086187005043,
      0.06620075553655624,
      0.00952566135674715,
      -0.0425887331366539,
      -0.0019232361810281873,
      -0.017333343625068665,
      -0.03148911893367767,
      -0.0033081597648561,
      0.0052741700783371925,
      -0.028424210846424103,
      0.044822271913290024,
      -0.03419126197695732,
      0.019509518519043922,
      -0.01881103217601776,
      -0.017478227615356445,
      -0.06491778045892715,
      0.036336008459329605,
      0.02393777295947075,
      -0.04264184460043907,
      -0.06085248664021492,
      0.045253075659275055,
      0.0402442067861557,
      0.011163339950144291,
      -0.005894373636692762,
      0.027862155809998512,
      0.13239680230617523,
      0.023038586601614952,
      -0.003654246451333165,
      -0.0035564175341278315,
      0.01188620924949646,
      -0.06871720403432846,
      -0.03496634587645531,
      0.016745008528232574,
      0.049221038818359375,
      -0.03474677726626396,
      0.028463419526815414,
      0.039655763655900955,
      0.0131259486079216,
      0.09757795184850693,
      0.00059726950712502,
      -0.032954975962638855,
      -0.03268983215093613,
      0.007747521158307791,
      0.06093594431877136,
      0.0010971591109409928,
      0.0677059218287468,
      0.020056137815117836,
      -0.011201148852705956,
      0.033700622618198395,
      -0.025027841329574585,
      0.007425528950989246,
      -0.08613577485084534,
      -0.03346574306488037,
      0.005500413943082094,
      -0.00406317412853241,
      0.060953572392463684,
      0.0073626236990094185,
      0.05593659356236458,
      0.09653983265161514,
      -0.07465659826993942,
      0.008843876421451569,
      0.08023763447999954,
      0.03609592095017433,
      0.014650862663984299,
      0.06177389621734619,
      -0.021842392161488533,
      -0.03602678328752518,
      -0.010028649121522903,
      -0.037358060479164124,
      -0.09452346712350845,
      0.05995187163352966,
      0.006126753985881805,
      0.038856249302625656,
      -0.082837775349617,
      0.14012742042541504,
      0.00575671624392271,
      -0.048488643020391464,
      0.007071916479617357,
      -0.08279243111610413,
      -0.015277771279215813,
      0.12438879162073135,
      0.0076365284621715546,
      0.06040354445576668,
      -0.017327426001429558,
      0.02293161302804947,
      -0.059972140938043594,
      -0.005922618787735701,
      0.04788840934634209,
      -0.028922345489263535,
      0.05838147550821304,
      0.010523511096835136,
      0.021193213760852814,
      0.05031131953001022,
      -0.0263535026460886,
      -0.016712166368961334,
      -0.07249584794044495,
      -0.016167547553777695,
      -0.021548951044678688,
      0.03170677274465561,
      0.021295109763741493,
      0.09531541168689728,
      0.010286223143339157,
      0.02076605148613453,
      0.022949593141674995,
      -0.04086146131157875,
      0.08948115259408951,
      -0.06725189089775085,
      0.02314041368663311,
      -0.0015469217905774713,
      -0.023660678416490555,
      -0.005783585831522942,
      -0.04682297259569168,
      0.04592004418373108,
      -0.03636891394853592,
      0.06158136948943138,
      0.05612163618206978,
      -0.05580928549170494,
      0.04123038426041603,
      0.12486360967159271,
      0.04475109279155731,
      0.03257061541080475,
      -0.020333467051386833,
      -0.08866427093744278,
      -0.0540301688015461,
      0.038010332733392715,
      0.036323778331279755,
      -0.03150191158056259,
      -0.002310636918991804,
      -0.01097135990858078,
      0.01945207267999649,
      0.053730156272649765,
      0.05645240843296051,
      0.04190773516893387,
      0.024448947980999947,
      -0.03862907364964485,
      0.036853279918432236,
      0.044562842696905136,
      -0.05368944630026817,
      -0.04771549999713898,
      -0.005511160474270582,
      0.022356124594807625,
      0.008878471329808235,
      0.04466330632567406,
      -0.02726617641746998,
      -0.038387101143598557,
      -0.031192371621727943,
      -0.016701363027095795,
      -0.07467275112867355,
      0.034025996923446655,
      -0.08984404802322388,
      0.05797857418656349,
      0.030141476541757584,
      -0.04325973987579346,
      0.07280654460191727,
      0.043183278292417526,
      0.04997026547789574,
      -0.071845144033432,
      0.0638289824128151,
      -0.004401255398988724,
      -0.029537005349993706,
      0.013074023649096489,
      0.021339941769838333,
      -0.02528739906847477,
      0.036133915185928345,
      -0.025420090183615685,
      -0.020343869924545288
    ],
    [
      -0.06939057260751724,
      0.029019664973020554,
      -0.05836689472198486,
      -0.03328005224466324,
      -0.009555176831781864,
      -0.026338675990700722,
      0.03690693899989128,
      -0.012780983000993729,
      0.00882710237056017,
      0.008057938888669014,
      -0.024580376222729683,
      0.026655681431293488,
      -0.012393821030855179,
      -0.0042929258197546005,
      0.032555777579545975,
      -0.021789442747831345,
      -0.01574256829917431,
      -0.030117949470877647,
      0.08312024176120758,
      -0.05118324235081673,
      0.0068224105052649975,
      -0.02953149564564228,
      -0.0033406957518309355,
      0.031061168760061264,
      0.0316774882376194,
      0.0017320422921329737,
      -0.0453411303460598,
      -0.01532834768295288,
      -0.0365724079310894,
      -0.10274027287960052,
      -0.00968068465590477,
      -0.010478968732059002,
      -0.05058452486991882,
      -0.033231403678655624,
      0.04306386411190033,
      0.0018142329063266516,
      0.012923860922455788,
      -0.019602175801992416,
      -0.015175893902778625,
      0.07759778201580048,
      -0.03128489479422569,
      0.010356711223721504,
      -0.009366661310195923,
      0.11481286585330963,
      0.03409743309020996,
      -0.036079537123441696,
      0.07859160006046295,
      0.025504034012556076,
      0.022047992795705795,
      -0.03173224627971649,
      0.0035080453380942345,
      0.014316384680569172,
      0.052711121737957,
      0.036017727106809616,
      0.005181801971048117,
      -0.00018080057634506375,
      0.03258831799030304,
      -0.039140768349170685,
      0.039094626903533936,
      0.019830835983157158,
      -0.0186171792447567,
      -0.08327720314264297,
      0.017800750210881233,
      0.003432143945246935,
      -0.014951789751648903,
      0.021268902346491814,
      -0.008914859034121037,
      -0.011171444319188595,
      0.027137024328112602,
      0.017928965389728546,
      0.014414993114769459,
      -0.013853423297405243,
      0.042197175323963165,
      0.028034096583724022,
      -0.03816787526011467,
      -0.0854937955737114,
      0.021919991821050644,
      0.12272872775793076,
      -0.011982826516032219,
      0.05741152912378311,
      0.059608325362205505,
      0.015552671626210213,
      -0.02910683862864971,
      0.0029582479037344456,
      0.062047600746154785,
      -0.010856238193809986,
      -0.04069517180323601,
      0.016713013872504234,
      -0.10767273604869843,
      -0.0323784239590168,
      0.0405227355659008,
      -0.041341181844472885,
      0.05714164674282074,
      0.05563835799694061,
      0.021592194214463234,
      0.01380704715847969,
      -0.03173698112368584,
      0.04964752867817879,
      0.03846946731209755,
      0.026257693767547607,
      0.036024440079927444,
      -0.1660447120666504,
      0.037085503339767456,
      0.10742700099945068,
      -0.001002008793875575,
      0.003619445487856865,
      0.02999887429177761,
      0.024500781670212746,
      -0.008185530081391335,
      -0.08686318248510361,
      0.08412683755159378,
      0.008195776492357254,
      -0.023625075817108154,
      -0.017780590802431107,
      -0.017113706097006798,
      0.04470342397689819,
      -0.010406873188912868,
      0.05653715878725052,
      -0.007253243122249842,
      0.015037750825285912,
      0.06261876970529556,
      0.017980003729462624,
      -0.03537258878350258,
      0.007375253830105066,
      0.023540150374174118,
      -0.002566274255514145,
      0.011347206309437752,
      -0.0045446911826729774,
      -0.012817001901566982,
      0.024616699665784836,
      0.013794480822980404,
      0.018607115373015404,
      -0.0047812326811254025,
      0.007287732325494289,
      -0.019901614636182785,
      0.022022563964128494,
      -0.030675018206238747,
      -0.03159428387880325,
      0.08147928863763809,
      -0.034439798444509506,
      0.13064682483673096,
      0.002055674558505416,
      0.03341982886195183,
      0.05941292271018028,
      -0.01098343264311552,
      -0.015040992759168148,
      -0.01162662822753191,
      -0.027145320549607277,
      -0.04330720007419586,
      0.042967163026332855,
      -0.028210345655679703,
      -0.030444694682955742,
      0.06960614770650864,
      0.02979222498834133,
      -0.04040560871362686,
      -0.008878507651388645,
      0.030246809124946594,
      -0.03635651245713234,
      0.03017473593354225,
      -0.016064859926700592,
      0.005357231479138136,
      -0.03666633367538452,
      0.03201383724808693,
      0.010132266208529472,
      -0.016663214191794395,
      -0.013988704420626163,
      -0.03746059164404869,
      0.009286202490329742,
      0.011410771869122982,
      0.015685316175222397,
      0.0038969572633504868,
      -0.0436089001595974,
      0.035444024950265884,
      -0.021593522280454636,
      -0.0036109150387346745,
      -0.05461465194821358,
      0.009404064156115055,
      0.035945527255535126,
      -0.003310391679406166,
      -0.07548169046640396,
      0.05377447232604027,
      0.0477263480424881,
      -0.010781287215650082,
      -0.050376418977975845,
      0.032819006592035294,
      0.06780734658241272,
      0.005966383032500744,
      -0.03430832177400589,
      0.07996704429388046,
      -0.0500650592148304,
      0.042571984231472015,
      0.014618907123804092,
      0.07471657544374466,
      0.048203159123659134,
      -0.028263185173273087,
      -0.02135152369737625,
      -0.018256593495607376,
      -0.05753212794661522,
      0.0005076752859167755,
      -0.09444742649793625,
      0.01248019840568304,
      -0.02943817526102066,
      0.011276821605861187,
      0.0011859366204589605,
      -0.049430593848228455,
      0.06499073654413223,
      0.05514755845069885,
      0.0474383570253849,
      0.044797178357839584,
      -0.00244683469645679,
      -0.11214965581893921,
      -0.07538536190986633,
      -0.013181259855628014,
      -0.06147163733839989,
      0.04747829958796501,
      0.021179011091589928,
      0.015976905822753906,
      0.009628956206142902,
      -0.05335906520485878,
      0.03664751723408699,
      0.044867586344480515,
      -0.01793045550584793,
      0.050878819078207016,
      0.02540484629571438,
      0.09054674208164215,
      0.04022572189569473,
      -0.006169260945171118,
      0.0021605847869068384,
      -0.005384049378335476,
      0.004036303609609604,
      -0.012412406504154205,
      -0.017446042969822884,
      -0.10762257128953934,
      0.00463003758341074,
      0.07941781729459763,
      -0.040341176092624664,
      -0.014979388564825058,
      0.016709214076399803,
      -0.04333701357245445,
      0.027577105909585953,
      -0.006727972533553839,
      -0.004836874548345804,
      0.0005171839729882777,
      -0.07908246666193008,
      -0.045020218938589096,
      -0.0678146630525589,
      0.0382130891084671,
      0.022179778665304184,
      0.00696247722953558,
      -0.028563328087329865,
      -0.04920939356088638,
      -0.07175851613283157,
      -0.09522836655378342,
      0.10071563720703125,
      0.03399878367781639,
      0.05879153683781624,
      -0.0019393214024603367,
      0.09566272050142288,
      0.02463192492723465,
      0.05430931597948074,
      -0.038331642746925354,
      -0.0909397080540657,
      0.03349601477384567,
      -0.02615242637693882,
      -0.008144507184624672,
      -0.053345292806625366,
      0.0018846148159354925,
      -0.0331018902361393,
      -0.02660747990012169,
      0.012071316130459309,
      0.024189269170165062,
      -0.04121709242463112,
      0.1034654900431633,
      -0.08043070882558823,
      -0.10236670821905136,
      0.07945737987756729,
      0.023566972464323044,
      0.01044329721480608,
      -0.04484270513057709,
      0.0012698274804279208,
      -0.07667403668165207,
      -0.007888317108154297,
      3.824575469479896e-05,
      -0.01996985822916031,
      -0.0006945613422431052,
      0.0009887564228847623,
      0.037818245589733124,
      0.03173019364476204,
      -0.017327118664979935,
      0.022786105051636696,
      -0.0033087306655943394,
      -0.0016502487706020474,
      -0.03141547739505768,
      0.0011924151331186295,
      0.03406233713030815,
      -0.051616840064525604,
      0.008716443553566933,
      0.0562368780374527,
      -0.11188817024230957,
      0.0221854317933321,
      0.01832028105854988,
      -0.013703753240406513,
      -0.06781352311372757,
      0.034967515617609024,
      0.05181775614619255,
      0.012262419797480106,
      0.010922303423285484,
      0.0050261677242815495,
      -0.05672942101955414,
      -0.008015895262360573,
      0.06583399325609207,
      0.016383759677410126,
      0.02927066944539547,
      0.08327881991863251,
      0.049666207283735275,
      -0.025960532948374748,
      0.01948515512049198,
      -0.01720082387328148,
      -0.06693240255117416,
      0.009253253228962421,
      -0.08126906305551529,
      -0.010092674754559994,
      0.01610706001520157,
      0.0090314457193017,
      -0.06331983953714371,
      -0.04876628890633583,
      0.05052163824439049,
      0.08096654713153839,
      -0.09557544440031052,
      0.15302777290344238,
      0.016772015020251274,
      0.0020935472566634417,
      -0.05395662412047386,
      -0.005291576962918043,
      -0.0069886790588498116,
      0.035415034741163254,
      -0.013625601306557655,
      -0.024368641898036003,
      -0.05652102455496788,
      0.03679909557104111,
      0.023984016850590706,
      -0.046370796859264374,
      0.04283924773335457,
      0.004413370508700609,
      -0.03581603243947029,
      -0.03066011145710945,
      0.028809448704123497,
      -0.004896144382655621,
      0.02352955937385559,
      0.022412996739149094,
      0.0986928790807724,
      0.0759250596165657,
      0.050540581345558167,
      -0.050592321902513504,
      -0.04639342799782753,
      0.0905819982290268,
      -0.03868677467107773,
      0.016122503206133842,
      -0.021547965705394745,
      0.04342355951666832,
      -0.012144680134952068,
      -0.004401389975100756,
      -0.035546302795410156,
      -0.003540751524269581,
      0.0028268699534237385,
      0.07943537831306458,
      -0.022124288603663445,
      0.011525633744895458,
      0.03807488828897476,
      0.010023698210716248,
      0.01897091045975685,
      0.05895247310400009,
      0.0009501794702373445,
      0.06997555494308472,
      -0.03738624230027199,
      0.024007907137274742,
      0.010145816951990128,
      -0.046449653804302216,
      -0.008497502654790878,
      0.05576276779174805,
      -0.01690439134836197,
      0.02771765924990177,
      -0.05909960716962814,
      -0.026493774726986885,
      0.06288984417915344,
      -0.04522782936692238,
      0.05017959326505661,
      -0.010638568550348282,
      -0.06933480501174927,
      -0.07980697602033615,
      -0.004504840821027756,
      -0.0059282067231833935,
      -0.041589077562093735,
      0.006917272694408894,
      -0.038400959223508835,
      0.031879328191280365,
      -0.08292017132043839,
      0.0173931997269392,
      -0.009495431557297707,
      0.07723723351955414,
      -0.009097136557102203,
      -0.10842530429363251,
      -0.011611884459853172,
      -0.039542607963085175,
      -0.0033855638466775417,
      -0.03243722394108772,
      -0.015964144840836525,
      0.04004626348614693,
      -0.05191773548722267,
      -0.013277380727231503,
      -0.13217709958553314,
      -0.008044136688113213,
      -0.05291702225804329,
      0.09381570667028427,
      -0.051859624683856964,
      0.05564442649483681,
      -0.019558146595954895,
      -0.043214887380599976,
      -0.007742357440292835,
      -0.0073982360772788525,
      0.0046959249302744865,
      -0.00833685789257288,
      -0.01817663386464119,
      -0.022238951176404953,
      -0.0002815012412611395,
      -0.056140247732400894,
      0.032021015882492065,
      0.03816621005535126,
      0.05139145255088806,
      0.012896770611405373,
      -0.11414682120084763,
      0.08957957476377487,
      0.03449289873242378,
      -0.05322803556919098,
      -0.02530602365732193,
      0.03687647730112076,
      0.02157629281282425,
      0.03009738400578499,
      -0.047644734382629395,
      -0.06960368156433105,
      0.026917019858956337,
      -0.01753261499106884,
      0.055794306099414825,
      -0.018872352316975594,
      0.05798447132110596,
      0.04910218343138695,
      -0.003074722597375512,
      -0.06588613241910934,
      -0.02028372325003147,
      0.011237964034080505,
      0.058201856911182404,
      -0.06578406691551208,
      -0.0694689229130745,
      -0.012812112458050251,
      0.00614066980779171,
      0.10315897315740585,
      -0.004366380162537098,
      -0.096016526222229,
      -0.016579866409301758,
      -0.0014394385507330298,
      -0.02628830075263977,
      -0.05152672156691551,
      0.018698154017329216,
      -0.04042442515492439,
      0.055755868554115295,
      0.07558510452508926,
      -0.06243153661489487,
      -0.02855871431529522,
      0.05406787246465683,
      0.030185068026185036,
      -0.04406307265162468,
      0.03901482746005058,
      -0.0660027414560318,
      0.1350911408662796,
      -0.014192357659339905,
      -0.042353201657533646,
      -0.05664203688502312,
      0.015920786187052727,
      0.011430191807448864,
      0.009562392719089985,
      0.07466937601566315,
      -0.07512778788805008,
      0.02248922921717167,
      0.006515660788863897,
      -0.0021452291402965784,
      -0.061811551451683044,
      -0.008775254711508751,
      -0.0004828306846320629,
      -0.01238834485411644,
      0.07371491193771362,
      0.056499727070331573,
      0.01779007911682129,
      0.060286782681941986,
      0.05780677869915962,
      0.002435706090182066,
      0.02026313729584217,
      0.08400721102952957,
      -0.026575373485684395,
      -0.0084593640640378,
      -0.01896103098988533,
      0.011911900714039803,
      -0.06964349001646042,
      -0.05223305895924568
    ],
    [
      0.044354867190122604,
      -0.08128981292247772,
      0.07809995859861374,
      0.04138723015785217,
      -0.025737714022397995,
      -0.04544540122151375,
      -0.038633450865745544,
      0.030283236876130104,
      -0.03535911440849304,
      0.032489046454429626,
      0.008079527877271175,
      -0.07204148918390274,
      -0.05699783191084862,
      0.0035705252084881067,
      -0.015694858506321907,
      -0.04392772912979126,
      0.09600722789764404,
      -0.057524725794792175,
      -0.016899777576327324,
      0.017247309908270836,
      0.06828716397285461,
      -0.036386460065841675,
      -0.00193000806029886,
      0.01936892420053482,
      -0.008621416985988617,
      0.01765180565416813,
      -0.047117821872234344,
      -0.026369450613856316,
      -0.05955269932746887,
      -0.06109078973531723,
      0.008497629314661026,
      0.003134216880425811,
      0.012543532066047192,
      -0.026064449921250343,
      -0.032405827194452286,
      -0.02805831842124462,
      0.02324151061475277,
      0.026483258232474327,
      0.033487897366285324,
      -0.04556284472346306,
      -0.036572132259607315,
      -0.017938528209924698,
      -0.005467499140650034,
      -0.045124929398298264,
      0.04505545273423195,
      0.007748219650238752,
      -0.011128325946629047,
      -0.01366728451102972,
      0.001316421083174646,
      0.06185729429125786,
      0.12659944593906403,
      0.027190327644348145,
      -0.06673615425825119,
      0.028660183772444725,
      0.022828390821814537,
      0.042289551347494125,
      -0.061749543994665146,
      -0.015545409172773361,
      -0.020337771624326706,
      -0.07161853462457657,
      -0.004166040103882551,
      0.0022842311300337315,
      -0.04032444208860397,
      0.03589390590786934,
      0.0015796843217685819,
      0.04866081848740578,
      0.003489358816295862,
      -0.04996012896299362,
      -0.02343331091105938,
      0.06467835605144501,
      -0.08983039855957031,
      0.010491820052266121,
      0.05285864695906639,
      0.038475774228572845,
      -0.0113631347194314,
      -0.036237314343452454,
      -0.003129173070192337,
      0.026207705959677696,
      0.010328116826713085,
      0.06570842862129211,
      -0.03898606449365616,
      -0.004531061742454767,
      0.0221230648458004,
      -0.030533039942383766,
      0.04193508252501488,
      0.060372814536094666,
      0.004185392055660486,
      -0.04256759583950043,
      0.037066083401441574,
      -0.01278847735375166,
      -0.0031198395881801844,
      -0.03055143915116787,
      -0.0062685334123671055,
      0.04336142912507057,
      0.04236121103167534,
      -0.022568760439753532,
      0.01603403314948082,
      -0.05857693403959274,
      -0.004255061503499746,
      -0.032382648438215256,
      -0.035986967384815216,
      0.07312868535518646,
      -0.04580379277467728,
      0.054167911410331726,
      0.05183110758662224,
      -0.04163637384772301,
      -0.008525543846189976,
      -0.0857129916548729,
      0.05654599517583847,
      0.013538973405957222,
      0.030633941292762756,
      0.012113124132156372,
      -0.06742999702692032,
      0.019245577976107597,
      0.02372261881828308,
      0.034027837216854095,
      0.014009213075041771,
      -0.05911441519856453,
      -0.0035961149260401726,
      0.057009972631931305,
      -0.01890072412788868,
      -0.06512401252985,
      0.048290375620126724,
      0.01347720343619585,
      -0.016656478866934776,
      -0.021251719444990158,
      -0.06119692698121071,
      0.022001372650265694,
      0.05292460694909096,
      -0.0023741351906210184,
      -0.01719128154218197,
      -0.008398784324526787,
      0.02570006437599659,
      -0.025043057277798653,
      0.007118877489119768,
      -0.011279906146228313,
      0.03261999413371086,
      0.08647167682647705,
      -0.04847792536020279,
      0.09271937608718872,
      -0.004888320341706276,
      0.0535302497446537,
      0.07912138104438782,
      0.05318208411335945,
      0.0708317831158638,
      0.06455165147781372,
      0.07071742415428162,
      -0.006774345878511667,
      0.03920021653175354,
      -0.03076614998281002,
      -0.12207000702619553,
      0.03518754243850708,
      0.04264619201421738,
      -0.04171328991651535,
      0.06507472693920135,
      0.02605433017015457,
      0.0017837175400927663,
      0.027860820293426514,
      -0.00432926882058382,
      0.07673143595457077,
      0.07578334212303162,
      -0.011065146885812283,
      0.09614051878452301,
      0.01027371734380722,
      0.05182148143649101,
      0.06555230170488358,
      0.06421033293008804,
      0.000986837549135089,
      0.09062747657299042,
      0.08885448426008224,
      0.0444672591984272,
      0.05185909569263458,
      0.008016367442905903,
      -0.0006815134547650814,
      0.005953558254987001,
      0.021551353856921196,
      -0.05963539332151413,
      -0.038357917219400406,
      0.021523581817746162,
      -0.00908871740102768,
      -0.027743807062506676,
      0.022209880873560905,
      -0.02222968079149723,
      -0.0023063330445438623,
      0.023479629307985306,
      0.035284437239170074,
      0.02439177967607975,
      0.019158918410539627,
      0.0586041659116745,
      0.03566360101103783,
      -0.013313593342900276,
      0.06010988727211952,
      0.0549900121986866,
      0.0042335269972682,
      0.02857709862291813,
      -0.07285857945680618,
      0.030956050381064415,
      -0.004580438137054443,
      0.060000982135534286,
      0.048552557826042175,
      0.03979970142245293,
      0.03908262774348259,
      0.00024349699378944933,
      -0.04546648636460304,
      -0.01777823641896248,
      0.07056111097335815,
      0.0022992403246462345,
      -0.025297490879893303,
      0.07180748879909515,
      0.0061524431221187115,
      -0.01020852942019701,
      -0.02935929037630558,
      0.04076094552874565,
      -0.035758838057518005,
      -0.014389120042324066,
      0.03534509241580963,
      0.025954389944672585,
      0.03119290992617607,
      0.06838256120681763,
      0.07698536664247513,
      -0.037885632365942,
      0.005723940674215555,
      0.03482642024755478,
      0.04121492803096771,
      0.05772610753774643,
      -0.027312085032463074,
      -0.0425482839345932,
      0.08340853452682495,
      0.02497262693941593,
      -0.000335186516167596,
      -0.05605100095272064,
      -0.006637452635914087,
      0.0677841454744339,
      -0.045090243220329285,
      -0.009387452155351639,
      -0.032491784542798996,
      0.00810274388641119,
      -0.01214203704148531,
      0.08047492057085037,
      0.06948328018188477,
      -0.1254822164773941,
      0.043492626398801804,
      -0.00977588165551424,
      -0.05059275031089783,
      0.00821657944470644,
      -0.07573942095041275,
      0.009446450509130955,
      -0.05060757324099541,
      -0.020997732877731323,
      -0.08780112117528915,
      0.03723073750734329,
      -0.021658960729837418,
      -0.025329221040010452,
      -0.06824159622192383,
      -0.029997235164046288,
      0.023820115253329277,
      0.019456665962934494,
      0.02347343973815441,
      -0.022508986294269562,
      0.04756448417901993,
      0.043779198080301285,
      -0.13064558804035187,
      0.005539204925298691,
      0.034652385860681534,
      0.00018155243014916778,
      0.012823455035686493,
      -0.02837841957807541,
      0.041985075920820236,
      0.02634148672223091,
      0.06531359255313873,
      0.056072186678647995,
      -0.008621850050985813,
      -0.04830402135848999,
      -0.023776380345225334,
      -0.09696287661790848,
      0.007942180149257183,
      0.005082443822175264,
      0.043848320841789246,
      -0.0019202163675799966,
      0.013002762570977211,
      -0.017660072073340416,
      -0.05567743629217148,
      -0.05020323023200035,
      0.03316992148756981,
      -0.05068815127015114,
      0.024745536968111992,
      -0.046718668192625046,
      0.018198391422629356,
      -0.05436800420284271,
      -0.0036080358549952507,
      -0.05749446526169777,
      -0.029780341312289238,
      0.07168776541948318,
      -0.04538939148187637,
      -0.01998540572822094,
      -0.018216457217931747,
      -0.029627878218889236,
      0.038759760558605194,
      0.0007042029174044728,
      0.009218201972544193,
      0.049203358590602875,
      0.05931857228279114,
      0.10976825654506683,
      0.05165538936853409,
      -0.0044498564675450325,
      -0.07987166196107864,
      -0.09603274613618851,
      0.047988247126340866,
      -0.004135610535740852,
      -0.015966057777404785,
      -0.10126509517431259,
      -0.034021247178316116,
      -0.026262275874614716,
      0.017740141600370407,
      -0.010767939500510693,
      -0.013781093992292881,
      0.052931200712919235,
      -0.01684516854584217,
      0.04418814554810524,
      -0.06271088868379593,
      0.015280298888683319,
      0.012807504273951054,
      -0.004649692680686712,
      -0.023840324953198433,
      0.08444839715957642,
      0.008789855055510998,
      -0.0005060501280240715,
      -0.017023807391524315,
      0.025377321988344193,
      0.029337028041481972,
      0.0006656863843090832,
      -0.021995967254042625,
      -0.07392331212759018,
      0.021046899259090424,
      0.02856217883527279,
      0.01912298984825611,
      0.05618969351053238,
      0.08767054229974747,
      0.028764210641384125,
      -0.0218992717564106,
      -0.024485452100634575,
      -0.04307844117283821,
      -0.06912398338317871,
      0.015269235707819462,
      -0.0008749734843149781,
      0.02371503785252571,
      0.006643202621489763,
      -0.01662156544625759,
      0.010095721110701561,
      0.043207086622714996,
      0.00297732325270772,
      0.0557623989880085,
      -0.06486119329929352,
      0.08731383830308914,
      -0.05107003077864647,
      0.011474349536001682,
      -0.046433012932538986,
      0.0561516135931015,
      0.0033324514515697956,
      0.005264427978545427,
      -0.013647399842739105,
      0.010341153480112553,
      0.016510892659425735,
      -0.08688704669475555,
      -0.010202129371464252,
      0.005179184023290873,
      0.01352374255657196,
      0.012420582585036755,
      0.0473610945045948,
      0.028661558404564857,
      0.006095340009778738,
      -0.015888627618551254,
      -0.029106365516781807,
      -0.011928987689316273,
      0.03131572902202606,
      -0.03504738584160805,
      -0.0028675796929746866,
      0.01942637376487255,
      0.02412019670009613,
      -0.051081009209156036,
      -0.04710651561617851,
      -0.03520487993955612,
      0.1094626635313034,
      0.01582774706184864,
      -0.015192180871963501,
      -0.027943650260567665,
      0.032154664397239685,
      0.07427183538675308,
      0.031015483662486076,
      -0.03529062867164612,
      -0.02586803212761879,
      0.03947874903678894,
      0.035018276423215866,
      0.05254334211349487,
      0.029471466317772865,
      0.002989251399412751,
      0.010454133152961731,
      0.09468059241771698,
      0.05055617168545723,
      -0.01916002668440342,
      -0.06541086733341217,
      -0.004070460796356201,
      -0.06540332734584808,
      0.08254779130220413,
      0.05855106934905052,
      0.01371094211935997,
      0.012020807713270187,
      0.005304979160428047,
      0.012610198929905891,
      0.037445347756147385,
      -0.006038632709532976,
      -0.04314733296632767,
      0.04798063263297081,
      -0.03288017958402634,
      -0.028010010719299316,
      0.031982846558094025,
      -0.06556960940361023,
      -0.052758507430553436,
      -0.021022459492087364,
      -0.0034100739285349846,
      0.030828919261693954,
      0.011833466589450836,
      -0.018886353820562363,
      0.025990791618824005,
      0.03972413390874863,
      -0.08662433177232742,
      0.06753341108560562,
      0.04639340937137604,
      -0.02624431811273098,
      -0.014754209667444229,
      0.0238485224545002,
      0.04426635801792145,
      -0.04539141058921814,
      0.04064594954252243,
      -0.03199370950460434,
      0.023120159283280373,
      -0.02612261474132538,
      -0.013259191997349262,
      0.018897660076618195,
      0.04201686009764671,
      -0.018657784909009933,
      0.015633873641490936,
      -0.02508595585823059,
      0.02558363415300846,
      -0.012649460695683956,
      0.05300958827137947,
      0.007686656434088945,
      0.06078726425766945,
      -0.08850793540477753,
      0.04358985647559166,
      0.040721967816352844,
      -0.03682728111743927,
      -0.04494785517454147,
      0.011851238086819649,
      -0.0267014317214489,
      0.0055383844301104546,
      0.07409903407096863,
      -0.05497342720627785,
      -0.0009490752127021551,
      0.022022921591997147,
      0.0789460688829422,
      0.005438704043626785,
      -0.02091064117848873,
      -0.037017714232206345,
      0.10461891442537308,
      0.007724687457084656,
      0.0008992607472464442,
      -0.012298057787120342,
      -0.04214439541101456,
      -0.051937323063611984,
      -0.00826353020966053,
      0.021844787523150444,
      -0.021807121112942696,
      0.021755943074822426,
      -0.02537059225142002,
      -0.016387322917580605,
      -0.012087756767868996,
      -0.039678219705820084,
      0.06927020102739334,
      0.018787290900945663,
      0.011879349127411842,
      0.017906097695231438,
      -0.04401819780468941,
      0.035968389362096786,
      -0.01833914965391159,
      0.023658862337470055,
      -0.037410445511341095,
      0.02694219909608364,
      0.07401583343744278,
      0.005354713648557663,
      -0.05344422534108162,
      0.020326346158981323,
      0.06109444424510002,
      0.07370733469724655,
      -0.0032263800967484713,
      -0.0329473502933979,
      0.060691893100738525,
      -0.028470877557992935,
      -0.004614177625626326,
      0.014733782038092613,
      -0.016837220638990402,
      -0.02075791358947754,
      -0.030150631442666054
    ],
    [
      -0.011740860529243946,
      -0.03651660680770874,
      -0.0074965860694646835,
      -0.02061411738395691,
      -0.009835644625127316,
      0.04550817608833313,
      -0.053299348801374435,
      0.04330190271139145,
      -0.023961607366800308,
      0.006571134552359581,
      -0.033814508467912674,
      -0.04250386729836464,
      0.0029828704427927732,
      0.039585553109645844,
      -0.0780249685049057,
      -0.05962535738945007,
      0.019912950694561005,
      0.022799354046583176,
      -0.06250684708356857,
      0.05508071556687355,
      -0.039227068424224854,
      -2.2327576516545378e-06,
      -0.017641518265008926,
      0.0502743199467659,
      0.013137919828295708,
      0.019666125997900963,
      -0.08183223754167557,
      0.031212521716952324,
      0.06127619743347168,
      0.010054594837129116,
      -0.008703849278390408,
      0.009057478047907352,
      -0.0698663517832756,
      -0.015444771386682987,
      -0.005907007493078709,
      -0.017347902059555054,
      0.036989178508520126,
      -0.02251991257071495,
      -0.035593293607234955,
      -0.035676516592502594,
      -0.07930011302232742,
      -0.044828858226537704,
      0.023434309288859367,
      -0.01872420310974121,
      0.060454122722148895,
      0.03386048600077629,
      0.06441619247198105,
      0.10815399140119553,
      0.01941658742725849,
      -0.007836222648620605,
      0.040655918419361115,
      0.03809521719813347,
      -0.016735894605517387,
      -0.007603464648127556,
      -0.03288715332746506,
      0.020705798640847206,
      -0.04866683483123779,
      0.013458073139190674,
      -0.01938026398420334,
      0.058088529855012894,
      -0.026012172922492027,
      -0.025571342557668686,
      0.01950312778353691,
      -0.07040005922317505,
      -0.01951630972325802,
      -0.05870598182082176,
      -0.12947238981723785,
      0.04798603430390358,
      0.007661163341253996,
      0.004123742692172527,
      -0.016336949542164803,
      0.005688861012458801,
      -0.027384845539927483,
      0.07373210042715073,
      -0.035463590174913406,
      0.004677230957895517,
      0.020785652101039886,
      -0.015168968588113785,
      0.056092508137226105,
      0.11337469518184662,
      -0.0378720685839653,
      0.012388912960886955,
      -0.01752655580639839,
      0.010539742186665535,
      0.00316670467145741,
      -0.01807389408349991,
      0.028135182335972786,
      0.031827621161937714,
      -0.04759148880839348,
      0.002250340301543474,
      0.02779529243707657,
      0.05009053274989128,
      0.026467273011803627,
      0.0855073630809784,
      0.05527126044034958,
      -0.06654861569404602,
      0.02639664337038994,
      0.005455289501696825,
      -0.06889113038778305,
      -0.025293704122304916,
      -0.09913907945156097,
      -0.05419929698109627,
      -0.034572333097457886,
      0.03372705727815628,
      -0.013772432692348957,
      -0.0028083601500838995,
      0.0028398907743394375,
      -0.011739111505448818,
      -0.0634833425283432,
      0.0888083428144455,
      7.196675869636238e-05,
      -0.001157212071120739,
      -0.02005671337246895,
      -0.022105688229203224,
      -0.04697342589497566,
      -0.010610062628984451,
      0.08588758856058121,
      -0.015889599919319153,
      -0.0402558371424675,
      -0.050614021718502045,
      0.057500142604112625,
      0.052158769220113754,
      -0.010485890321433544,
      -0.021322833374142647,
      0.01209904532879591,
      0.010605061426758766,
      -0.14204803109169006,
      -0.033263999968767166,
      0.01715511828660965,
      -0.020218856632709503,
      0.006105105392634869,
      0.01574009656906128,
      0.06159897521138191,
      -0.09151914715766907,
      0.09737557917833328,
      0.034911710768938065,
      0.013617269694805145,
      0.016364315524697304,
      0.041628457605838776,
      0.04823390394449234,
      -0.05292840301990509,
      -0.016793865710496902,
      -0.021580873057246208,
      0.050151191651821136,
      0.04078846052289009,
      -0.07652829587459564,
      0.0013995555927976966,
      0.00294817634858191,
      -0.05732811614871025,
      -0.00926908478140831,
      -0.05153684690594673,
      0.07604467868804932,
      0.13513705134391785,
      -0.018616003915667534,
      0.003045459510758519,
      -0.042339522391557693,
      0.001337917521595955,
      0.01531715877354145,
      -0.025004126131534576,
      0.05519557371735573,
      0.05867224931716919,
      0.0022952489089220762,
      0.008454365655779839,
      -0.03265908360481262,
      -0.018786808475852013,
      -0.06820423901081085,
      0.048021093010902405,
      0.017259497195482254,
      -0.09312521666288376,
      0.0867970883846283,
      0.06102687120437622,
      0.00559071684256196,
      -0.02108786627650261,
      0.06475908309221268,
      -0.0757189691066742,
      0.11453654617071152,
      -0.026658564805984497,
      -0.1008722335100174,
      0.0156778022646904,
      -0.16069240868091583,
      -0.02600933611392975,
      0.013514435850083828,
      0.06877277791500092,
      0.03634578734636307,
      0.07747723162174225,
      -0.11769428104162216,
      -0.08002140372991562,
      0.010666197165846825,
      -0.023889701813459396,
      0.004356152378022671,
      0.0030251285061240196,
      0.0009717796929180622,
      -0.06325150281190872,
      -0.10058122873306274,
      0.028668083250522614,
      -0.056041453033685684,
      -0.013220670633018017,
      -0.03761940449476242,
      -0.07794646918773651,
      0.09926044940948486,
      0.009465095587074757,
      -0.03380166366696358,
      -0.05183740705251694,
      -0.08641175925731659,
      -0.10814554989337921,
      0.03472151234745979,
      -0.02654237113893032,
      -0.05329390987753868,
      0.02818121574819088,
      0.01564846560359001,
      -0.033124927431344986,
      -0.06263809651136398,
      -0.032892242074012756,
      -0.0810987576842308,
      -0.04923069104552269,
      0.023299818858504295,
      -0.03981078788638115,
      0.11900748312473297,
      0.03966832533478737,
      -0.030251629650592804,
      -0.06840601563453674,
      -0.02189970202744007,
      0.021136492490768433,
      -0.009699917398393154,
      0.005557588301599026,
      -0.044036462903022766,
      0.052900467067956924,
      -0.026040269061923027,
      0.1298784613609314,
      0.051043037325143814,
      0.0437156967818737,
      0.06308350712060928,
      -0.08615731447935104,
      0.02117263898253441,
      0.037995461374521255,
      -0.027424590662121773,
      0.014023997820913792,
      0.02003670297563076,
      0.014114424586296082,
      -0.05248052254319191,
      -0.021044140681624413,
      0.028823023661971092,
      0.03989860787987709,
      0.0519915446639061,
      -0.032464925199747086,
      -0.016172945499420166,
      0.04379746690392494,
      -0.11456910520792007,
      0.003698755754157901,
      -0.05647312104701996,
      -0.0049149272963404655,
      -0.0356435663998127,
      -0.00027477729599922895,
      -0.023669464513659477,
      -0.003536529140546918,
      0.021708648651838303,
      -0.027536898851394653,
      -0.04045158252120018,
      0.11494144052267075,
      0.048668693751096725,
      -0.008529901504516602,
      0.06705537438392639,
      -0.02655508928000927,
      -0.030179552733898163,
      -0.01928996481001377,
      -0.0021077748388051987,
      -0.018825093284249306,
      -0.017419258132576942,
      -0.05402432009577751,
      -0.036772675812244415,
      0.07306717336177826,
      -0.001579175004735589,
      0.03147004172205925,
      -0.0233923327177763,
      -0.030766179785132408,
      -0.08682544529438019,
      0.003095409832894802,
      0.07479128241539001,
      -0.007380673661828041,
      -0.020993543788790703,
      0.06182026118040085,
      0.03484548255801201,
      0.09905451536178589,
      0.010075856000185013,
      -0.05217228829860687,
      0.011271587572991848,
      -0.033673204481601715,
      0.014097966253757477,
      0.046212419867515564,
      0.07607530802488327,
      0.03239727020263672,
      0.016601908951997757,
      -0.019196538254618645,
      0.04035602882504463,
      -0.014056779444217682,
      0.004755388014018536,
      -0.0032972923945635557,
      0.0325787253677845,
      0.008153285831212997,
      0.07250016182661057,
      0.0048399087972939014,
      -0.060399144887924194,
      0.008956389501690865,
      0.0013607314322143793,
      0.0072619132697582245,
      -0.030427703633904457,
      -0.09476258605718613,
      0.05575796216726303,
      0.031057240441441536,
      0.03228519484400749,
      -0.0033183156047016382,
      0.026574263349175453,
      -0.014005173929035664,
      -0.04740128293633461,
      -0.04465080052614212,
      0.014185904525220394,
      -0.043693460524082184,
      0.018216561526060104,
      -0.020750312134623528,
      0.03619640693068504,
      0.0030731495935469866,
      0.07006139308214188,
      0.007139823865145445,
      -0.025409985333681107,
      0.05739643797278404,
      -0.007491346914321184,
      0.02544373832643032,
      -0.02154267393052578,
      -0.031964465975761414,
      0.01245848461985588,
      -0.027213845402002335,
      0.07463908940553665,
      0.012716419994831085,
      0.012455577962100506,
      -0.09726963192224503,
      0.051318563520908356,
      -0.006323616486042738,
      -0.04992581158876419,
      0.03182971104979515,
      0.027790918946266174,
      -0.11810891330242157,
      0.011153833009302616,
      -0.05128399282693863,
      -0.06914734840393066,
      0.00533033162355423,
      0.00976504385471344,
      -0.06636141985654831,
      -0.003911295440047979,
      -0.01776058040559292,
      -0.04432101175189018,
      0.07585275173187256,
      -0.03335290774703026,
      -0.031468715518713,
      -0.09965433925390244,
      -0.020359983667731285,
      -0.06355585157871246,
      0.06522582471370697,
      -0.03511103242635727,
      -0.01776910200715065,
      -0.011305725201964378,
      -0.07727713137865067,
      -0.03348606079816818,
      0.08806617558002472,
      -0.008039434440433979,
      0.10804858058691025,
      0.01046807412058115,
      0.0005463904235512018,
      0.02898845635354519,
      -0.020239198580384254,
      -0.04782548174262047,
      -0.008275235071778297,
      0.0014229760272428393,
      -0.027546746656298637,
      0.005847103428095579,
      -0.06392330676317215,
      0.004502812400460243,
      0.06241385266184807,
      -0.0161195769906044,
      -0.006020030006766319,
      0.03294215723872185,
      0.041899457573890686,
      0.02729013003408909,
      -0.02466787025332451,
      0.08407627046108246,
      0.058915507048368454,
      -0.05401286482810974,
      0.12405338138341904,
      0.03220178186893463,
      0.023301051929593086,
      0.0015699261566624045,
      0.09626064449548721,
      -0.029537534341216087,
      0.021569672971963882,
      -0.008286938071250916,
      0.05550688877701759,
      -0.026497576385736465,
      -0.03997921571135521,
      0.03775801137089729,
      0.06410235911607742,
      0.02305655926465988,
      -0.027973275631666183,
      -0.06771937012672424,
      0.02417592704296112,
      -0.06773314625024796,
      0.02629842981696129,
      0.0784323513507843,
      -0.03984202817082405,
      0.08106885105371475,
      -0.07883268594741821,
      -0.060170724987983704,
      -0.08216685056686401,
      -0.056676894426345825,
      -0.019387604668736458,
      -0.0005742501234635711,
      -0.004729373846203089,
      -0.06652634590864182,
      -0.039271824061870575,
      -0.05952250212430954,
      0.020160706713795662,
      0.005650265142321587,
      0.02714877761900425,
      0.03434203565120697,
      0.01668197475373745,
      -0.01477781217545271,
      -0.02350645139813423,
      -0.051925379782915115,
      -0.03284001722931862,
      0.02999028190970421,
      -0.01484644878655672,
      -0.04092235863208771,
      -0.0242066141217947,
      0.027779411524534225,
      -0.02404453419148922,
      0.09596464037895203,
      -0.019265666604042053,
      -0.010194470174610615,
      0.012993376702070236,
      0.040815554559230804,
      -0.02015537954866886,
      -0.04348094388842583,
      -0.006305134855210781,
      -0.009653789922595024,
      0.008894696831703186,
      -0.034358344972133636,
      0.06656313687562943,
      -0.05879410728812218,
      0.05175989493727684,
      -0.057542044669389725,
      -0.06535201519727707,
      0.0907713919878006,
      -0.05634219944477081,
      0.039178092032670975,
      -0.05329941213130951,
      0.009792163036763668,
      0.0340363010764122,
      0.03972332179546356,
      -0.04030581936240196,
      -0.0005974433152005076,
      -0.03996410220861435,
      -0.0039636315777897835,
      0.06104818731546402,
      -0.020726729184389114,
      0.01589871384203434,
      -0.05830008164048195,
      0.07556504756212234,
      -0.09302227944135666,
      -0.08797818422317505,
      -0.029371993616223335,
      0.08796221017837524,
      -0.050285425037145615,
      -0.07196801900863647,
      0.03111540712416172,
      -0.03316708281636238,
      -0.04830360412597656,
      -0.027252592146396637,
      0.07348024100065231,
      -0.09686153382062912,
      0.08967256546020508,
      -0.043164558708667755,
      -0.037052638828754425,
      0.005028510931879282,
      -0.002134500304237008,
      0.024616345763206482,
      -0.05248842015862465,
      -0.09124855697154999,
      -0.040168192237615585,
      0.07901953160762787,
      0.08626382797956467,
      -0.001375781837850809,
      0.02850726619362831,
      -0.07766686379909515,
      -0.08101437985897064,
      -0.07975025475025177,
      -0.052891477942466736,
      0.010225119069218636,
      -0.040357306599617004,
      -0.03660806268453598,
      -0.02681059017777443,
      -0.07451596856117249,
      -0.044092509895563126,
      -0.0960565134882927,
      -0.05662935972213745,
      -0.01838105358183384
    ],
    [
      0.044310785830020905,
      0.006903607398271561,
      -0.04513353481888771,
      0.10302088409662247,
      -0.10265243798494339,
      -0.006360643543303013,
      0.07673928141593933,
      0.04538843780755997,
      0.01358566153794527,
      -0.06193378195166588,
      0.011879438534379005,
      0.022800907492637634,
      -0.06555913388729095,
      -0.0028665531426668167,
      0.022507688030600548,
      -0.08783585578203201,
      -0.007807819172739983,
      0.021549949422478676,
      0.012092811986804008,
      0.014268258586525917,
      0.0789344534277916,
      -0.07838302850723267,
      -0.023395610973238945,
      -0.027330638840794563,
      0.05384941026568413,
      0.02824670821428299,
      0.05282615125179291,
      -0.05642957240343094,
      -0.012110726907849312,
      -0.01515093445777893,
      0.029849747195839882,
      -0.017805766314268112,
      -0.03524143621325493,
      -0.021915139630436897,
      -0.03018176555633545,
      -0.03835403546690941,
      0.051653794944286346,
      -0.024635180830955505,
      -0.0555729866027832,
      0.03170686587691307,
      0.003959690686315298,
      -0.0283712949603796,
      0.005769755225628614,
      -0.06441967189311981,
      0.02943848818540573,
      0.07726690173149109,
      0.10131192207336426,
      0.010955511592328548,
      0.08147422224283218,
      -0.012181943282485008,
      0.04060976952314377,
      0.023636333644390106,
      -0.09428796917200089,
      0.03295348957180977,
      -0.028049906715750694,
      -0.00987748522311449,
      -0.07303804904222488,
      0.0693829208612442,
      0.011308453977108002,
      -0.06197050213813782,
      0.012512248009443283,
      -0.037807583808898926,
      0.04624585807323456,
      0.039851561188697815,
      -0.038379669189453125,
      -0.03968469053506851,
      0.02754865400493145,
      0.011002860963344574,
      -0.02940117008984089,
      0.015752306208014488,
      0.058805305510759354,
      0.0413990393280983,
      0.03718987852334976,
      -0.04257179796695709,
      -0.07644546031951904,
      0.04434973746538162,
      -0.009872550144791603,
      -0.021153723821043968,
      0.05077473446726799,
      0.04690306633710861,
      -0.0018025801982730627,
      -0.01842636428773403,
      -0.06694304943084717,
      0.06453804671764374,
      0.00012835676898248494,
      0.041742365807294846,
      0.035386256873607635,
      -0.0638534277677536,
      -0.01105455495417118,
      0.02601727470755577,
      0.04753933101892471,
      -0.06283989548683167,
      -0.011245811358094215,
      0.07707056403160095,
      -0.052759312093257904,
      0.010676599107682705,
      -0.006326268892735243,
      0.08019895851612091,
      0.06443573534488678,
      -0.04288795217871666,
      -0.01771034486591816,
      -0.0006721615791320801,
      -0.019312463700771332,
      0.00643651420250535,
      0.010768026113510132,
      0.0012858739355579019,
      0.023551730439066887,
      0.05832281708717346,
      -0.09474446624517441,
      -0.08560751378536224,
      -0.024000030010938644,
      -0.005553894676268101,
      0.03616461902856827,
      -0.036511633545160294,
      0.09437073022127151,
      0.07277382165193558,
      -0.01801762543618679,
      0.04714465141296387,
      -0.024329008534550667,
      -0.05915278196334839,
      -0.02043258585035801,
      -0.04681697115302086,
      -0.02139965072274208,
      -0.051580484956502914,
      -0.015995493158698082,
      0.007505721878260374,
      0.042806074023246765,
      -0.03552688658237457,
      0.04686880484223366,
      0.018243610858917236,
      -0.07222411036491394,
      0.05569789186120033,
      0.01928142085671425,
      -0.019184991717338562,
      0.07243712991476059,
      0.0006992006674408913,
      -0.014486582018435001,
      -0.11114217340946198,
      0.04386577382683754,
      -0.07812339812517166,
      0.020674869418144226,
      -0.03180976212024689,
      -0.03926411643624306,
      0.15634772181510925,
      0.07003191113471985,
      0.010481834411621094,
      -0.04146396741271019,
      -0.0113844508305192,
      -0.11010470241308212,
      0.017077868804335594,
      0.029277043417096138,
      -0.0045046014711260796,
      0.055177394300699234,
      -0.004169423133134842,
      0.04269466921687126,
      -0.09950164705514908,
      -0.013936330564320087,
      0.024599071592092514,
      -0.04316617548465729,
      0.07726245373487473,
      -0.06329188495874405,
      0.04386935010552406,
      -0.05191425979137421,
      0.02466615103185177,
      -0.05911095440387726,
      0.024945871904492378,
      -0.013287355192005634,
      0.05034331604838371,
      -0.04086001589894295,
      0.041994377970695496,
      0.013460162095725536,
      0.009901351295411587,
      -0.022198691964149475,
      0.027126824483275414,
      0.02357148937880993,
      0.10098423063755035,
      -0.053038738667964935,
      -0.04267935827374458,
      0.032547831535339355,
      -0.09371715039014816,
      -0.027498722076416016,
      0.06264825165271759,
      -0.021507155150175095,
      -0.027810491621494293,
      -0.024566244333982468,
      0.004172857850790024,
      -0.06952060014009476,
      -0.025105006992816925,
      -0.014726691879332066,
      -0.00292106531560421,
      0.014117260463535786,
      0.051730044186115265,
      0.0006407405016943812,
      -0.02989402413368225,
      -0.037730731070041656,
      -0.02888462133705616,
      -0.05458269268274307,
      -0.008172028698027134,
      -0.015489596873521805,
      0.041317276656627655,
      0.047845903784036636,
      -0.015842700377106667,
      0.1036624014377594,
      -0.0333746112883091,
      -0.0587390772998333,
      0.021255075931549072,
      -0.05166616290807724,
      0.03114774264395237,
      -0.03490442410111427,
      -0.015753600746393204,
      -0.030846992507576942,
      -0.00819456484168768,
      -0.04822550714015961,
      0.05435062572360039,
      -0.019351154565811157,
      -0.029539933428168297,
      -0.025392191484570503,
      0.002016550162807107,
      -0.00397767499089241,
      0.0070844837464392185,
      0.0061476207338273525,
      -0.05545474588871002,
      0.009204802103340626,
      -0.023300299420952797,
      0.0007801169413141906,
      -0.0033480129204690456,
      -0.041318658739328384,
      -0.06686262041330338,
      0.042874209582805634,
      0.013238904997706413,
      0.02458074875175953,
      0.02509133331477642,
      0.024173351004719734,
      0.06593158841133118,
      -0.036554086953401566,
      -0.05907723307609558,
      0.05466168373823166,
      0.09351777285337448,
      -0.09745702892541885,
      -0.04551113024353981,
      0.0036985797341912985,
      -0.04552844166755676,
      0.047366343438625336,
      0.025807565078139305,
      0.05501937493681908,
      -0.041248008608818054,
      0.005610616412013769,
      -0.08433494716882706,
      0.0013442231575027108,
      0.023015741258859634,
      -0.003526736283674836,
      -0.050388921052217484,
      -0.0054146102629601955,
      -0.0007068681297823787,
      0.025375140830874443,
      -0.05210775509476662,
      0.0012361221015453339,
      0.04737362265586853,
      -0.028100358322262764,
      0.00103706622030586,
      0.01734004355967045,
      -0.03863806650042534,
      -0.016866378486156464,
      0.05774197727441788,
      0.0816740170121193,
      -0.06313614547252655,
      -0.06700228154659271,
      -0.008944464847445488,
      -0.027374977245926857,
      -0.07195372134447098,
      0.094228096306324,
      -0.017428869381546974,
      -0.03231257572770119,
      0.019182473421096802,
      0.07083651423454285,
      -0.01847970299422741,
      -7.215999357867986e-05,
      0.0409109927713871,
      -0.04670562595129013,
      0.04997987672686577,
      0.00943179614841938,
      0.025039762258529663,
      0.07204265147447586,
      -0.04278261959552765,
      -0.031614091247320175,
      0.03708246350288391,
      0.05678974464535713,
      -0.008618702180683613,
      -0.04969991371035576,
      -0.03727426752448082,
      0.026557432487607002,
      -0.0752166360616684,
      -0.0186206866055727,
      -0.0262653399258852,
      0.016580985859036446,
      -0.037681929767131805,
      -0.023299727588891983,
      -0.04392439126968384,
      -0.032668329775333405,
      0.007106133736670017,
      0.03584123030304909,
      0.00476363068446517,
      0.08112093061208725,
      0.02900615893304348,
      0.023581987246870995,
      -0.017224730923771858,
      0.021163513883948326,
      -0.006825638003647327,
      -0.07582205533981323,
      0.08218120038509369,
      0.03302961215376854,
      0.05122730880975723,
      0.03596392273902893,
      0.052756667137145996,
      -0.02757709100842476,
      0.06692475825548172,
      0.011792195960879326,
      0.04451863840222359,
      0.013918925076723099,
      0.055702175945043564,
      0.021264081820845604,
      0.018089771270751953,
      -0.06716081500053406,
      0.0801907405257225,
      0.019967298954725266,
      -0.10637154430150986,
      0.08042170107364655,
      -0.048277080059051514,
      0.018013808876276016,
      0.017758814617991447,
      0.03259604424238205,
      0.058446962386369705,
      0.032664705067873,
      0.0006734272465109825,
      -0.03814603015780449,
      -0.0015860369894653559,
      -0.08968251943588257,
      -0.059880584478378296,
      -0.006534644402563572,
      -0.014865149743855,
      -0.01779823936522007,
      0.03127121925354004,
      0.050369828939437866,
      -0.009082144126296043,
      -0.10357124358415604,
      0.02594243921339512,
      0.07129530608654022,
      -0.0004547840799205005,
      -0.030302882194519043,
      -0.01112911943346262,
      0.05558699369430542,
      0.008533017709851265,
      -0.09481336176395416,
      -0.0031024047639220953,
      0.04539933428168297,
      -0.020028337836265564,
      0.11439886689186096,
      0.017792362719774246,
      0.017412269487977028,
      0.0674499124288559,
      0.004696379881352186,
      0.010659968480467796,
      -0.03199455142021179,
      -0.02634742110967636,
      -0.058048371225595474,
      -0.05705830827355385,
      0.09635908156633377,
      -0.020893974229693413,
      0.001985021634027362,
      0.016314316540956497,
      0.008828899823129177,
      -0.045949649065732956,
      -0.019547035917639732,
      -0.07457172125577927,
      0.012051553465425968,
      0.06935267150402069,
      -0.02531455270946026,
      -0.04971962794661522,
      0.045485928654670715,
      -0.01513786893337965,
      0.02062371000647545,
      -0.07238820195198059,
      -0.10484346002340317,
      -0.043266016989946365,
      0.0756387785077095,
      -0.06599637866020203,
      -0.01026415079832077,
      -0.013745006173849106,
      0.007625030353665352,
      -0.014749248512089252,
      0.01804983802139759,
      -0.06447585672140121,
      0.024755965918302536,
      0.03254903480410576,
      0.040824469178915024,
      0.014454967342317104,
      -0.04750760272145271,
      0.0043100775219500065,
      -0.004938879050314426,
      -0.12119177728891373,
      -0.03528190031647682,
      0.012537150643765926,
      0.0171574205160141,
      -0.0399288646876812,
      -0.022380992770195007,
      0.015642354264855385,
      -0.01249126810580492,
      0.03307206928730011,
      -0.004717189818620682,
      -0.026533955708146095,
      -0.05475522577762604,
      0.042521797120571136,
      -0.04980916157364845,
      0.0002683133352547884,
      -0.009624437429010868,
      0.006726615596562624,
      -0.009078439325094223,
      -0.11849000304937363,
      -0.008864762261509895,
      -0.008242316544055939,
      0.01079765148460865,
      0.011207971721887589,
      -0.001038232701830566,
      -0.018133070319890976,
      -0.040387969464063644,
      0.010865185409784317,
      -0.065264992415905,
      -0.03635864332318306,
      0.10280495136976242,
      0.0278506800532341,
      -0.002102299826219678,
      0.026501905173063278,
      0.01601818948984146,
      0.024331143125891685,
      0.029095077887177467,
      -0.0016566686099395156,
      0.029821304604411125,
      -0.0926036387681961,
      -0.028748242184519768,
      -0.029452593997120857,
      -0.0036033049691468477,
      0.020999157801270485,
      0.003347015241160989,
      0.06998667865991592,
      -0.03991599380970001,
      0.040965624153614044,
      0.02782183513045311,
      0.032866913825273514,
      -0.031154783442616463,
      0.019057175144553185,
      -0.0411374606192112,
      -0.034348223358392715,
      -0.024389056488871574,
      0.08662214875221252,
      -0.1020394116640091,
      0.016292860731482506,
      0.03440600261092186,
      -0.012877782806754112,
      -0.01722693257033825,
      -0.08319023996591568,
      0.0095305684953928,
      0.009040125645697117,
      -0.07282757014036179,
      0.03238879516720772,
      0.06943532824516296,
      -0.05833197757601738,
      -0.06633423268795013,
      0.013554335571825504,
      0.055052611976861954,
      -0.08516447991132736,
      0.02169743739068508,
      0.057039447128772736,
      -0.005210220348089933,
      -0.06397886574268341,
      -0.01876097172498703,
      -0.0387265607714653,
      -0.0839066281914711,
      -0.029233373701572418,
      0.05367578938603401,
      0.06462746113538742,
      0.012018426321446896,
      -0.07113739103078842,
      -0.030522450804710388,
      0.003504527034237981,
      0.011894214898347855,
      0.02577989734709263,
      -0.01180589571595192,
      0.050404105335474014,
      0.02034617029130459,
      0.04941800236701965,
      -0.0361514575779438,
      -0.018765749409794807,
      0.012204346247017384,
      -0.03801324591040611,
      -0.06512629985809326,
      0.017066175118088722,
      -0.07764377444982529,
      0.07422895729541779,
      -0.08887819200754166,
      0.04960694536566734,
      0.0040169283747673035,
      -0.031499333679676056,
      -0.10108909755945206
    ],
    [
      0.015242158435285091,
      0.0475415363907814,
      -0.009193447418510914,
      0.056353919208049774,
      -0.05278833582997322,
      0.029875900596380234,
      0.04909142106771469,
      -0.04265633225440979,
      -0.0009194692247547209,
      0.06752901524305344,
      0.010530166327953339,
      -0.06688567250967026,
      0.02975689060986042,
      0.0367375910282135,
      0.012057832442224026,
      0.03543030098080635,
      -0.006760870106518269,
      -0.004399471450597048,
      0.019261078909039497,
      0.005865531973540783,
      -0.039659228175878525,
      0.09842696785926819,
      0.0270469319075346,
      0.01487198006361723,
      0.12656532227993011,
      0.002638320904225111,
      0.04261261969804764,
      0.035085633397102356,
      -0.05968168005347252,
      0.006445693783462048,
      0.06744257360696793,
      0.01337377168238163,
      0.004733762238174677,
      0.046740081161260605,
      0.03926626220345497,
      -0.02961697429418564,
      0.08817335218191147,
      0.005177863873541355,
      0.05605350062251091,
      -0.02540869079530239,
      -0.022325119003653526,
      0.035255350172519684,
      -0.06026718392968178,
      -0.02878650836646557,
      0.002790838712826371,
      -0.06251900643110275,
      -0.022398170083761215,
      0.028326990082859993,
      0.03320321813225746,
      -0.02502937614917755,
      0.04598096013069153,
      -0.001426992821507156,
      -0.006680801976472139,
      -0.012703104875981808,
      0.02670225314795971,
      0.04247628152370453,
      -0.07909219712018967,
      0.01094126608222723,
      0.03380589559674263,
      -0.02593964897096157,
      -0.05459142103791237,
      0.10060574114322662,
      0.015562848187983036,
      -0.03333117440342903,
      -0.0961061641573906,
      0.03266627714037895,
      0.0045161982998251915,
      0.0018254242604598403,
      -0.027140703052282333,
      0.04521050304174423,
      0.035831157118082047,
      0.03287787362933159,
      -0.04278647154569626,
      0.003902294673025608,
      0.037696126848459244,
      0.026226315647363663,
      0.008958284743130207,
      -0.0748765617609024,
      0.00013544097600970417,
      -0.011137695983052254,
      0.02669568546116352,
      -0.07522037625312805,
      0.03740018606185913,
      0.03014850988984108,
      0.013679565861821175,
      0.06416641175746918,
      -0.022047819569706917,
      0.0034389146603643894,
      0.0015720512019470334,
      -0.04453570395708084,
      0.06548060476779938,
      -0.0337214469909668,
      -0.007190907374024391,
      -0.0297197587788105,
      -0.04005976766347885,
      0.0017250542296096683,
      0.005335002206265926,
      -0.02808164805173874,
      0.007447635754942894,
      0.036524128168821335,
      0.05771860107779503,
      0.032759133726358414,
      -0.008771511726081371,
      0.06939912587404251,
      0.024262763559818268,
      0.030078450217843056,
      0.039084140211343765,
      -0.04068529233336449,
      -0.051254305988550186,
      -0.03127528354525566,
      0.036457378417253494,
      -0.019092457368969917,
      -0.017595576122403145,
      -0.08527478575706482,
      -0.03536496311426163,
      0.06036647409200668,
      -0.03729861229658127,
      0.004253900609910488,
      0.05825990065932274,
      -0.013679341413080692,
      0.0489705353975296,
      0.01856646127998829,
      0.04328751564025879,
      -0.01869254931807518,
      -0.06929077208042145,
      -0.066390760242939,
      0.08484427630901337,
      0.08907542377710342,
      -0.039586231112480164,
      -0.010569639503955841,
      0.0151392025873065,
      0.04440227895975113,
      0.048896800726652145,
      -0.1020352840423584,
      0.029944907873868942,
      0.005866365972906351,
      0.028453785926103592,
      0.05919206887483597,
      -0.04233802109956741,
      -0.005047863814979792,
      -0.06669236719608307,
      0.04419296607375145,
      -0.014364131726324558,
      0.03903387486934662,
      -0.03764929994940758,
      0.0032200906425714493,
      0.08468851447105408,
      -0.008228952065110207,
      0.00867700856178999,
      -0.024321764707565308,
      0.09176289290189743,
      0.07656200230121613,
      -0.04189630225300789,
      -0.027121320366859436,
      5.62357745366171e-05,
      -0.019525062292814255,
      0.060711413621902466,
      0.030525313690304756,
      -0.033084265887737274,
      -0.03409803286194801,
      -0.085626021027565,
      0.01834735833108425,
      0.0211303923279047,
      -0.03451988846063614,
      -0.04553789272904396,
      -0.0008314314181916416,
      -0.010068508796393871,
      -0.06819932162761688,
      -0.011822865344583988,
      0.039948973804712296,
      -0.04864582419395447,
      -0.02536480315029621,
      -0.035177573561668396,
      -0.06252694129943848,
      -0.038189347833395004,
      -0.023310258984565735,
      -0.07922247797250748,
      0.09870701283216476,
      -0.023478614166378975,
      -0.00917020719498396,
      0.022200625389814377,
      0.06045996770262718,
      0.041405629366636276,
      0.0312969945371151,
      0.02322992868721485,
      -0.0931776687502861,
      -0.04362757131457329,
      0.09630173444747925,
      -0.06170188635587692,
      -0.012467327527701855,
      -0.05378306657075882,
      -0.011255080811679363,
      -0.000975262897554785,
      -0.0367460697889328,
      -0.06651054322719574,
      0.06404151767492294,
      0.02011261321604252,
      -0.03296038135886192,
      -0.09622027724981308,
      0.03570720553398132,
      0.03536742553114891,
      -0.04150750860571861,
      -0.082138791680336,
      -0.1027306392788887,
      0.0041383965872228146,
      -0.030458685010671616,
      0.027517303824424744,
      0.04707761108875275,
      0.0394761823117733,
      -0.022191036492586136,
      -0.03127865865826607,
      -0.10099856555461884,
      0.023411782458424568,
      -0.0017617368139326572,
      0.008157586678862572,
      -0.013882043771445751,
      -0.013132765889167786,
      -0.03426259011030197,
      0.0215936116874218,
      0.03913157433271408,
      -0.013366647996008396,
      -0.0941077321767807,
      0.07138678431510925,
      -0.06632745265960693,
      -0.023693840950727463,
      0.056031860411167145,
      -0.039431795477867126,
      -0.008912558667361736,
      0.015711668878793716,
      0.0313006229698658,
      0.042136263102293015,
      0.018068430945277214,
      0.038956064730882645,
      0.013162311166524887,
      0.029493020847439766,
      0.03093726933002472,
      0.036010291427373886,
      0.001511356676928699,
      0.060705650597810745,
      -0.017417525872588158,
      0.019650930538773537,
      -0.05000949651002884,
      -0.02765829488635063,
      -0.04837173968553543,
      0.013373464345932007,
      -0.07156970351934433,
      0.0075928824953734875,
      -0.02093173749744892,
      -0.04300288110971451,
      0.04741256311535835,
      0.003684792434796691,
      -0.006188299506902695,
      -0.06735005229711533,
      -0.08307906240224838,
      -0.051987119019031525,
      0.027615001425147057,
      -0.006142198108136654,
      0.01975373364984989,
      0.036093469709157944,
      -0.03362775221467018,
      0.06580260396003723,
      0.019269900396466255,
      0.02579931542277336,
      -0.020205030217766762,
      0.06740191578865051,
      -0.017775192856788635,
      0.046874333173036575,
      -0.04530109092593193,
      0.006080718711018562,
      -0.03917226195335388,
      0.011204423382878304,
      -0.02776772528886795,
      0.055333830416202545,
      -0.023317858576774597,
      0.02236228436231613,
      0.011829889379441738,
      -0.03179293870925903,
      -0.02169513702392578,
      0.0790804848074913,
      -0.010005299001932144,
      -0.06138383969664574,
      -0.040034715086221695,
      0.09583453834056854,
      -0.026176881045103073,
      0.01716708391904831,
      -0.007645215839147568,
      0.0028007174842059612,
      0.02718871831893921,
      0.016018930822610855,
      -0.08288342505693436,
      -0.05430326983332634,
      -0.03455072641372681,
      -0.028152931481599808,
      -0.055849961936473846,
      -0.039381351321935654,
      0.024294840171933174,
      -0.034467365592718124,
      0.028651434928178787,
      -0.014816821552813053,
      -0.04290001466870308,
      0.053301144391298294,
      0.035865407437086105,
      0.04236813262104988,
      0.020671851933002472,
      0.0365290492773056,
      0.05640906095504761,
      -0.10586933046579361,
      -0.037504129111766815,
      0.051966845989227295,
      -0.010377278551459312,
      0.016197090968489647,
      0.05092444643378258,
      0.08640452474355698,
      0.024738742038607597,
      0.02453499287366867,
      -0.04371234029531479,
      0.06008656695485115,
      -0.002232000231742859,
      0.02264714054763317,
      0.06637491285800934,
      0.044173672795295715,
      -0.030690139159560204,
      0.0022930281702429056,
      0.016742169857025146,
      0.019217893481254578,
      -0.021379822865128517,
      0.04180285334587097,
      0.048177655786275864,
      0.06483038514852524,
      0.06489250808954239,
      0.04490555450320244,
      -0.0732521042227745,
      0.11281538754701614,
      0.08007989823818207,
      0.023033197969198227,
      -0.014955156482756138,
      0.0022841966710984707,
      0.020909825339913368,
      0.03824914991855621,
      -0.021050933748483658,
      -0.04840677231550217,
      0.05942216143012047,
      0.018147863447666168,
      0.017638808116316795,
      -0.02353702485561371,
      0.0260396059602499,
      -0.016229411587119102,
      -0.0031825348269194365,
      0.01291932724416256,
      -0.009841173887252808,
      0.020573941990733147,
      -0.06665904819965363,
      -0.018742267042398453,
      -0.06353424489498138,
      -0.024749476462602615,
      -0.03614448755979538,
      -0.09812434762716293,
      -0.11529547721147537,
      0.06430871039628983,
      -0.047389231622219086,
      0.01554228737950325,
      -0.032783474773168564,
      -0.024097967892885208,
      0.042640265077352524,
      -0.04890608787536621,
      -0.010457937605679035,
      -0.0644468441605568,
      -0.07309937477111816,
      -0.05368475243449211,
      -0.04183607175946236,
      0.044070277363061905,
      -0.030698196962475777,
      -0.06274925172328949,
      0.05289296805858612,
      -0.045542068779468536,
      -0.025747403502464294,
      0.0034249296877533197,
      0.021794606000185013,
      0.005453736521303654,
      0.01447101216763258,
      -0.04288056120276451,
      -0.029114829376339912,
      -0.019317369908094406,
      -0.02796323411166668,
      0.02384326420724392,
      -0.00039839616511017084,
      -0.032647863030433655,
      -0.009297233074903488,
      0.020744366571307182,
      -0.043808463960886,
      -0.06559296697378159,
      -0.02233077958226204,
      0.0013083815574645996,
      -0.019684800878167152,
      -0.10209245979785919,
      -0.04539572075009346,
      -0.005075871013104916,
      -0.10471891611814499,
      0.03595395386219025,
      -0.05317068472504616,
      0.03654017299413681,
      -0.10565163195133209,
      0.07499523460865021,
      0.019187673926353455,
      0.023882778361439705,
      0.12335493415594101,
      0.019273415207862854,
      -0.0699533224105835,
      0.06970377266407013,
      0.0454874262213707,
      -0.05903944745659828,
      0.05341600626707077,
      -0.061319638043642044,
      0.02483103610575199,
      -0.10044187307357788,
      0.02872251346707344,
      -0.06115507706999779,
      -0.018775660544633865,
      0.042118001729249954,
      -0.003010937012732029,
      -0.0339704230427742,
      -0.009220525622367859,
      -0.018713265657424927,
      0.03045656718313694,
      -0.0041142613627016544,
      0.05946243926882744,
      -0.055254317820072174,
      0.03661736845970154,
      0.02792852371931076,
      -0.017963789403438568,
      0.02288387157022953,
      0.005805095192044973,
      -0.05167252942919731,
      0.016638388857245445,
      -0.02959350124001503,
      -0.033092934638261795,
      -0.040214721113443375,
      -0.0013476775493472815,
      0.04737604409456253,
      0.03861632198095322,
      0.08672423660755157,
      -0.060513030737638474,
      -0.02100883051753044,
      -0.022074857726693153,
      0.02191293053328991,
      0.08224761486053467,
      0.015069444663822651,
      0.006963102146983147,
      0.02582668513059616,
      0.04495593160390854,
      0.027397725731134415,
      -0.014740756712853909,
      -0.01128209475427866,
      -0.00590351689606905,
      0.01316369604319334,
      0.04174567386507988,
      -0.03603890910744667,
      0.06184399127960205,
      -0.0556798055768013,
      0.003934437409043312,
      0.040832772850990295,
      -0.007673229090869427,
      -0.008467230945825577,
      -0.02281535603106022,
      -0.03290669247508049,
      -0.03244883567094803,
      -0.01789972372353077,
      0.04790017008781433,
      -0.012788510881364346,
      0.07013823091983795,
      0.04864063858985901,
      -0.018029747530817986,
      -0.04510911926627159,
      -0.007692819926887751,
      -0.015328340232372284,
      -0.05070309340953827,
      0.01443087961524725,
      0.007342903409153223,
      0.05297277495265007,
      -0.04339921846985817,
      0.000882937281858176,
      -0.0030581080354750156,
      0.02498982660472393,
      -0.019955527037382126,
      -0.03345153108239174,
      -0.00776301696896553,
      -0.0700719803571701,
      -0.056108929216861725,
      -0.002228757832199335,
      -0.04433809220790863,
      0.0026192660443484783,
      -0.02971329167485237,
      0.08133382350206375,
      -0.005171237513422966,
      -0.0038536530919373035,
      -0.0005104800220578909,
      -0.04239141196012497,
      0.03089735470712185,
      -0.004204985685646534,
      0.02695409394800663,
      -0.03939260169863701,
      -0.02270953170955181,
      -0.08026628196239471
    ],
    [
      -0.0005115565145388246,
      0.017563097178936005,
      0.028459234163165092,
      -0.06321731954813004,
      0.06467154622077942,
      0.0016894389409571886,
      -0.07548311352729797,
      0.012608041986823082,
      0.10459866374731064,
      0.012525659054517746,
      0.009056450799107552,
      0.02292075753211975,
      -0.0573149248957634,
      -0.04833707585930824,
      -0.05656883493065834,
      -0.016435766592621803,
      -0.05645247921347618,
      0.05482727661728859,
      -0.0689995139837265,
      -0.018571794033050537,
      0.004412500653415918,
      0.03468296304345131,
      -0.0376398041844368,
      -0.03436475247144699,
      -0.019856616854667664,
      -0.029177051037549973,
      -0.008707521483302116,
      0.022240260615944862,
      -0.06144466623663902,
      0.06218693405389786,
      -0.028777839615941048,
      -0.0011717247543856502,
      -0.00810967292636633,
      -0.03525707498192787,
      0.02921011671423912,
      -0.06200414150953293,
      0.08478792756795883,
      -0.0024989608209580183,
      0.01868625544011593,
      -0.008800748735666275,
      0.020665695890784264,
      -0.03256978094577789,
      -0.023789869621396065,
      0.08673856407403946,
      -0.0032497600186616182,
      -4.7392431952175684e-06,
      0.004408935084939003,
      -0.020548904314637184,
      0.0649198591709137,
      -0.06108732894062996,
      0.013149982318282127,
      -0.050951652228832245,
      0.009979470632970333,
      -0.06298438459634781,
      -0.009562569670379162,
      0.05150503292679787,
      -0.07985745370388031,
      0.03820693492889404,
      -0.02673768438398838,
      -0.051759637892246246,
      -0.012500222772359848,
      -0.04234042763710022,
      0.03875957801938057,
      0.014980795793235302,
      -0.11264259368181229,
      0.011350061744451523,
      -0.0029485486447811127,
      0.006436573341488838,
      -0.008718837052583694,
      -0.0342102013528347,
      -0.05889957770705223,
      -0.024583160877227783,
      -0.007368611171841621,
      -0.021446721628308296,
      -0.039620719850063324,
      0.031248703598976135,
      0.02774939127266407,
      -0.03856416419148445,
      -0.002613529097288847,
      -0.012298050336539745,
      -0.00572134368121624,
      -0.03790007159113884,
      -0.025887571275234222,
      -0.013265270739793777,
      -0.007261071819812059,
      -0.07404376566410065,
      0.029527220875024796,
      0.01839100383222103,
      0.0327720083296299,
      0.02627634070813656,
      0.015900401398539543,
      -0.03407974913716316,
      0.019795997068285942,
      -0.014419417828321457,
      -0.04093329235911369,
      -0.039824746549129486,
      0.07078384608030319,
      0.0378965400159359,
      0.03622303530573845,
      0.07826951146125793,
      0.003511381335556507,
      -0.05873379111289978,
      0.012350332923233509,
      -0.007357548922300339,
      -0.019067497923970222,
      0.021007057279348373,
      -0.024894418194890022,
      0.0229121595621109,
      -0.05349292233586311,
      -0.06496287882328033,
      0.053368210792541504,
      -0.005258030258119106,
      -0.003374551422894001,
      0.09967591613531113,
      -0.02349771000444889,
      -0.01428891159594059,
      0.05841098725795746,
      -0.0457560159265995,
      -0.03662852197885513,
      0.05190829932689667,
      -0.015234716236591339,
      0.01748729683458805,
      0.014366226270794868,
      0.01673850230872631,
      -0.004809526726603508,
      -0.02839897945523262,
      -0.044932637363672256,
      0.011606569401919842,
      -0.0319109670817852,
      0.04054228216409683,
      0.03794267028570175,
      0.13450852036476135,
      -0.02117113769054413,
      -0.03858691453933716,
      0.015418619848787785,
      -0.02536628767848015,
      0.029821248725056648,
      -0.07258827239274979,
      0.02845945581793785,
      0.04671262204647064,
      -0.02330520749092102,
      0.008555691689252853,
      -0.00010158171789953485,
      0.13179829716682434,
      0.012777069583535194,
      -0.09894803911447525,
      0.0999542698264122,
      0.04011691361665726,
      -0.04484535753726959,
      0.06961390376091003,
      -0.007767103612422943,
      0.0025608455762267113,
      0.00336543214507401,
      0.06332945078611374,
      0.019218245521187782,
      0.006440743338316679,
      0.008625906892120838,
      -0.030879460275173187,
      -0.04924830049276352,
      -0.05681617185473442,
      -0.06657339632511139,
      0.043738413602113724,
      0.03253040835261345,
      0.05739353969693184,
      -0.0004892699653282762,
      0.03981901332736015,
      -0.04140865430235863,
      -0.14229479432106018,
      0.035479143261909485,
      0.04492766782641411,
      -0.038612376898527145,
      -0.019152697175741196,
      -0.004720435943454504,
      0.05753658339381218,
      0.09149982780218124,
      -0.014655240811407566,
      -0.007965627126395702,
      0.07580102235078812,
      0.0495123453438282,
      -0.06580416113138199,
      0.0379473902285099,
      -0.02232028916478157,
      -0.027766713872551918,
      0.07419104874134064,
      -0.06807594001293182,
      0.029914718121290207,
      -0.06085798516869545,
      -0.040373846888542175,
      0.08149159699678421,
      -0.06762689352035522,
      -0.040206242352724075,
      0.05669683590531349,
      -0.058099474757909775,
      -0.01683809421956539,
      0.00577843002974987,
      -0.016778821125626564,
      0.029669662937521935,
      -0.018005434423685074,
      0.02217830903828144,
      -0.05690082162618637,
      0.02334575168788433,
      -0.030106524005532265,
      0.07243455946445465,
      -0.035504866391420364,
      -0.05599949508905411,
      0.07932370901107788,
      0.04370402917265892,
      -0.020972374826669693,
      0.005273027345538139,
      0.00064313062466681,
      0.00986554753035307,
      -0.04381457343697548,
      0.17816336452960968,
      -0.01502368412911892,
      -0.012800659984350204,
      -0.0252248402684927,
      0.047976505011320114,
      0.03572385758161545,
      0.05094360187649727,
      0.007042413577437401,
      -0.020522499457001686,
      0.041010040789842606,
      0.040921036154031754,
      0.0012522139586508274,
      0.01428774744272232,
      0.05249550938606262,
      -0.0032322409097105265,
      -0.05201077088713646,
      -0.0458543598651886,
      0.003843300510197878,
      0.04302625358104706,
      -0.02251373790204525,
      0.01579722762107849,
      0.03543681278824806,
      -0.04979291185736656,
      -0.026353981345891953,
      -0.049764249473810196,
      -0.062754325568676,
      0.0966857373714447,
      0.029646968469023705,
      -0.011116895824670792,
      0.012422398664057255,
      -0.07885966449975967,
      0.03670680522918701,
      -0.013762153685092926,
      -0.06962220370769501,
      -0.0003584150690585375,
      0.055727601051330566,
      0.007175287697464228,
      -0.017726335674524307,
      0.032470155507326126,
      -0.08986833691596985,
      0.07428634911775589,
      -0.02912154793739319,
      -0.04314231500029564,
      -0.010922660119831562,
      0.0928138718008995,
      0.021917784586548805,
      0.05162382498383522,
      0.04412251338362694,
      0.07447242736816406,
      0.0843033418059349,
      -0.0005315777380019426,
      -0.06428692489862442,
      -0.026547932997345924,
      -0.02934449166059494,
      0.00269610108807683,
      0.0385931096971035,
      0.06532105803489685,
      -0.05884427949786186,
      -0.02359844744205475,
      0.11896748840808868,
      0.07838024944067001,
      -0.014824897050857544,
      -0.07209724187850952,
      -0.003306217724457383,
      0.09434445947408676,
      -0.021305853500962257,
      0.04396205395460129,
      0.06426908820867538,
      -0.03278952091932297,
      -0.02491743490099907,
      0.01851746067404747,
      -0.09901604801416397,
      0.02835588902235031,
      0.024426212534308434,
      -0.039112575352191925,
      0.10440008342266083,
      0.04317470267415047,
      0.016893530264496803,
      -0.01547345519065857,
      0.008052082732319832,
      -0.10019416362047195,
      0.03369709104299545,
      0.027633061632514,
      0.0476011224091053,
      0.04059385135769844,
      0.05409057438373566,
      0.021667495369911194,
      -0.05541863292455673,
      -0.021217482164502144,
      0.017951160669326782,
      -0.0035731662064790726,
      -0.013050164096057415,
      0.003840163815766573,
      -0.04544903710484505,
      0.0391286164522171,
      -0.024075470864772797,
      -0.013422898948192596,
      -0.04851464554667473,
      0.032713863998651505,
      -0.02545127272605896,
      -0.008348967880010605,
      0.05429462715983391,
      -0.009719215333461761,
      -0.05783762410283089,
      0.05614778399467468,
      -0.013949956744909286,
      -0.04601243883371353,
      -0.01575501263141632,
      0.018003972247242928,
      -0.03115544281899929,
      0.028167355805635452,
      0.01787719689309597,
      0.006300400476902723,
      0.011751272715628147,
      0.08339403569698334,
      -0.03729427605867386,
      -0.06898698955774307,
      -0.0036104777827858925,
      0.05037877708673477,
      -0.012533606961369514,
      -0.02120332233607769,
      -0.0613192655146122,
      0.02648811973631382,
      -0.01366968173533678,
      0.00435473769903183,
      -0.07787980884313583,
      0.052823156118392944,
      0.04729170352220535,
      -0.015635620802640915,
      -0.006142430938780308,
      -0.01622348465025425,
      -0.04786291345953941,
      -0.028842955827713013,
      -0.13292695581912994,
      0.03201507776975632,
      0.025774745270609856,
      0.02656593918800354,
      0.03391405940055847,
      0.08051130920648575,
      -0.005868624430149794,
      -0.05994666367769241,
      -0.029407484456896782,
      -0.012025652453303337,
      0.08624517917633057,
      0.012177574448287487,
      -0.03452238813042641,
      -0.011184723116457462,
      0.07580942660570145,
      -0.0731658935546875,
      -0.028907854110002518,
      -0.029220378026366234,
      0.049614593386650085,
      -0.1395939141511917,
      0.04007157310843468,
      -0.008557348512113094,
      0.08412554860115051,
      0.032182496041059494,
      0.0006242120289243758,
      -0.026093844324350357,
      -0.029772378504276276,
      0.059177886694669724,
      0.08734399080276489,
      -0.044378362596035004,
      -0.014479288831353188,
      -0.00108628673478961,
      0.02493865229189396,
      -0.023235909640789032,
      0.023432325571775436,
      0.005779296159744263,
      -0.06489083915948868,
      -0.0018014484085142612,
      0.04055528715252876,
      0.055479731410741806,
      -0.04941200837492943,
      0.030990608036518097,
      -0.036940861493349075,
      0.010570616461336613,
      0.08653846383094788,
      -0.0026250712107867002,
      -0.007203802932053804,
      0.04228642210364342,
      -0.047025833278894424,
      -0.04991881549358368,
      -0.014113780111074448,
      -0.09999362379312515,
      0.0412493459880352,
      0.014042261987924576,
      -0.009635869413614273,
      -0.03933930769562721,
      0.046536169946193695,
      0.015514367260038853,
      -0.05796726047992706,
      -0.04500361904501915,
      -0.0054065510630607605,
      -0.030560865998268127,
      0.017929935827851295,
      0.018326641991734505,
      -0.054079003632068634,
      -0.04504505917429924,
      0.07461415976285934,
      -0.06221136078238487,
      0.04820708557963371,
      -0.01977098174393177,
      -0.004882356617599726,
      0.030068395659327507,
      -0.08184295892715454,
      0.01857345923781395,
      -0.016974981874227524,
      -0.05269140005111694,
      -0.031905390322208405,
      -0.035911496728658676,
      0.003271824214607477,
      -0.03401320427656174,
      -0.019787173718214035,
      -0.016037078574299812,
      -0.09738722443580627,
      -0.05291186645627022,
      -0.04108839109539986,
      0.038204941898584366,
      -0.018273761495947838,
      -0.030228428542613983,
      -0.009290657006204128,
      0.03812214732170105,
      -0.048923786729574203,
      0.03220449015498161,
      -0.0758984386920929,
      -0.04490252211689949,
      -0.008572245016694069,
      -0.03350013867020607,
      -0.04437066614627838,
      0.03938990831375122,
      0.002187893493101001,
      0.08198325335979462,
      -0.046435799449682236,
      0.0878179594874382,
      0.021642832085490227,
      0.027952581644058228,
      0.00620507774874568,
      0.019779782742261887,
      -0.08183864504098892,
      -0.013695629313588142,
      0.02891540341079235,
      -0.003189388196915388,
      0.03341006860136986,
      -0.007482910994440317,
      0.0027964080218225718,
      0.09668226540088654,
      0.045613761991262436,
      0.0406971350312233,
      -0.01289274450391531,
      -0.021219899877905846,
      0.055544670671224594,
      -0.05030813068151474,
      0.014614159241318703,
      -0.07026856392621994,
      -0.03251141309738159,
      0.020347632467746735,
      -0.10779134184122086,
      -0.015177380293607712,
      0.021487904712557793,
      0.018428733572363853,
      -0.004734955728054047,
      0.11675743013620377,
      0.023949552327394485,
      -0.007555448450148106,
      0.06047964096069336,
      0.02363426610827446,
      0.006615091115236282,
      -0.014347119256854057,
      0.038929857313632965,
      -0.02724991925060749,
      0.034108761698007584,
      -0.07760369032621384,
      -0.04031933471560478,
      -0.05744841694831848,
      0.036554791033267975,
      0.019565753638744354,
      -0.041158709675073624,
      -0.025448858737945557,
      0.004547338467091322,
      -0.0011330413399264216,
      0.02420099452137947,
      0.020995691418647766,
      -0.02413414604961872,
      -0.03006792441010475,
      0.0424007885158062,
      0.000781152572017163,
      -0.03914829343557358,
      -0.005151393357664347,
      0.020839879289269447,
      -0.046716656535863876
    ],
    [
      0.09797897934913635,
      -0.0552898533642292,
      -0.017268765717744827,
      -0.010197770781815052,
      -0.06020544841885567,
      0.03145284205675125,
      0.06032106280326843,
      -0.019726164638996124,
      0.03942180052399635,
      -0.0331910066306591,
      -0.013641915284097195,
      0.05446777865290642,
      0.08150242269039154,
      -0.005264851730316877,
      0.024893630295991898,
      -0.07785613089799881,
      -0.00907942745834589,
      0.03860737010836601,
      -0.04949627444148064,
      0.019380472600460052,
      -0.012343461625277996,
      -0.07018651068210602,
      -0.0375964492559433,
      -0.016156578436493874,
      -0.029871677979826927,
      -0.03844493627548218,
      -0.018977457657456398,
      0.02574501372873783,
      -0.07390305399894714,
      0.02608945406973362,
      0.04187203198671341,
      0.07366079837083817,
      0.10651335120201111,
      -0.0016616518842056394,
      0.049185991287231445,
      -0.005235962104052305,
      -0.02186017856001854,
      0.009894892573356628,
      0.01251237466931343,
      -0.04488305002450943,
      0.00545245548710227,
      -0.01995546743273735,
      -0.03325662761926651,
      -0.07864165306091309,
      -0.10489226132631302,
      -0.015151817351579666,
      -0.014115882106125355,
      -0.007342712953686714,
      -0.05241911858320236,
      -0.029764976352453232,
      0.057637155055999756,
      0.05805900692939758,
      -0.03526035323739052,
      -0.010855307802557945,
      0.007178702857345343,
      0.08229028433561325,
      0.0009121721377596259,
      0.06885311752557755,
      -0.04504603147506714,
      0.02983337827026844,
      -0.03722335025668144,
      -0.062409207224845886,
      0.058071319013834,
      0.07708901911973953,
      -0.03279381990432739,
      0.030975578352808952,
      0.03182315081357956,
      -0.02990761771798134,
      0.017294198274612427,
      -0.07919104397296906,
      -0.014170493930578232,
      -0.006724993232637644,
      0.05382194742560387,
      -0.018746960908174515,
      0.06458689272403717,
      -0.01667955331504345,
      -0.04644462838768959,
      0.0809909775853157,
      -0.01171812228858471,
      -0.05800297483801842,
      0.023462751880288124,
      -0.04395158216357231,
      0.048810120671987534,
      0.002058893907815218,
      0.08472005277872086,
      0.034684088081121445,
      0.004345029126852751,
      0.063800148665905,
      0.03066852316260338,
      0.04664328321814537,
      0.01761152409017086,
      0.05736357346177101,
      -0.023998016491532326,
      0.011281928047537804,
      -0.022543074563145638,
      -0.039395321160554886,
      -0.010555969551205635,
      0.052795689553022385,
      -0.025731686502695084,
      0.007548242341727018,
      0.02221899852156639,
      0.01334250159561634,
      -0.02689402736723423,
      0.03496344015002251,
      -0.013701189309358597,
      -0.0008918901439756155,
      -0.05121330916881561,
      -0.024583039805293083,
      -0.04359045624732971,
      -0.07045943289995193,
      -0.07869770377874374,
      -0.029218900948762894,
      0.015539063140749931,
      -0.03191457688808441,
      -0.009598341770470142,
      -0.07220643013715744,
      -0.03867214545607567,
      0.04518494755029678,
      -0.026848338544368744,
      -0.02799426019191742,
      0.042295824736356735,
      -0.036489132791757584,
      -0.010755966417491436,
      0.010104578919708729,
      0.0857294574379921,
      -0.02035338245332241,
      0.08298256993293762,
      0.030331045389175415,
      -0.057235829532146454,
      0.0015474655665457249,
      0.028820551931858063,
      -0.006180098745971918,
      0.027688611298799515,
      0.03485088050365448,
      0.038294240832328796,
      -0.02840517647564411,
      -0.010450828820466995,
      -0.05814536288380623,
      -0.02850217930972576,
      -0.00024455119273625314,
      -0.09098657220602036,
      -0.0026087062433362007,
      -0.029391072690486908,
      0.00333399442024529,
      0.06009146571159363,
      0.06260780245065689,
      -0.07615926861763,
      0.045693133026361465,
      -0.019982067868113518,
      0.03006007894873619,
      -0.03806281462311745,
      0.07497016340494156,
      -0.06419546157121658,
      0.045099806040525436,
      0.00964190810918808,
      -0.03456178680062294,
      0.09129805117845535,
      -0.03587889298796654,
      0.06389348208904266,
      -0.013648205436766148,
      -0.09256216138601303,
      0.02309165522456169,
      0.030629178509116173,
      0.012218592688441277,
      -0.03859035670757294,
      -0.018071213737130165,
      0.040383078157901764,
      -0.08304499834775925,
      0.015668945387005806,
      0.03323424980044365,
      -0.07450605183839798,
      0.07629704475402832,
      -0.009532763622701168,
      0.0405510775744915,
      0.030041325837373734,
      -0.0506572388112545,
      -0.0028117347974330187,
      0.09217359870672226,
      0.053346723318099976,
      -0.018008626997470856,
      0.07351520657539368,
      0.005277090705931187,
      -0.09019475430250168,
      0.028203530237078667,
      0.00044352764962241054,
      0.04122607782483101,
      -0.005895667709410191,
      0.030055953189730644,
      0.022351937368512154,
      -0.03227466717362404,
      0.025893868878483772,
      0.028051108121871948,
      -0.034677550196647644,
      0.009809394367039204,
      -0.030058780685067177,
      -0.04556911811232567,
      0.0293473768979311,
      -0.045595213770866394,
      -0.051562294363975525,
      0.008477263152599335,
      0.11786548793315887,
      -0.04469677060842514,
      -0.02781262993812561,
      -0.05643458664417267,
      -0.030768124386668205,
      0.007706935983151197,
      0.017266232520341873,
      0.019722769036889076,
      -0.08402609080076218,
      0.04966098442673683,
      -0.04081322252750397,
      -0.031532447785139084,
      0.03922077640891075,
      -0.014840774238109589,
      -0.009920299053192139,
      0.02478061616420746,
      0.08041415363550186,
      0.014717132784426212,
      -0.02057400532066822,
      -0.003442279761657119,
      0.01678657718002796,
      -0.03377451002597809,
      0.05818652734160423,
      0.0037205354310572147,
      0.012706284411251545,
      0.07895553857088089,
      0.01360577903687954,
      -0.01468991581350565,
      0.07234777510166168,
      0.0005896241054870188,
      0.00996425375342369,
      -0.027523115277290344,
      0.11399690806865692,
      0.010245656594634056,
      -0.02251073531806469,
      0.036777667701244354,
      0.0029139395337551832,
      0.07713279128074646,
      -0.015313776209950447,
      -0.05908589065074921,
      -0.03966020047664642,
      -0.04689251258969307,
      -0.024191154167056084,
      0.09194285422563553,
      0.017193621024489403,
      0.023707963526248932,
      -0.01847955584526062,
      -0.033086951822042465,
      -0.043176647275686264,
      -0.0353480763733387,
      0.04122483357787132,
      -0.024029266089200974,
      0.007712438236922026,
      -0.03855414316058159,
      -0.04373301938176155,
      -0.03538306802511215,
      -0.018847644329071045,
      0.03250082954764366,
      -0.044571466743946075,
      -0.06424689292907715,
      0.01565740443766117,
      -0.1029890924692154,
      0.01777442917227745,
      -0.009085576981306076,
      0.01993965543806553,
      -0.06829056888818741,
      0.013650152832269669,
      -5.9709884226322174e-05,
      0.060120340436697006,
      0.052037883549928665,
      -0.0026529908645898104,
      0.0874248743057251,
      -0.12253829836845398,
      -0.020028335973620415,
      -0.012291040271520615,
      0.020502258092164993,
      -0.01723410375416279,
      -0.01882627047598362,
      0.032280758023262024,
      -0.04370046779513359,
      -0.012622028589248657,
      -0.012764465063810349,
      -0.060247886925935745,
      -0.02152668684720993,
      0.02498621679842472,
      -0.05015459656715393,
      -0.010711987502872944,
      -0.052793920040130615,
      0.01576855778694153,
      0.00039661768823862076,
      -0.1044604554772377,
      -0.08977306634187698,
      -0.004958628676831722,
      -0.01658119075000286,
      0.009185896255075932,
      0.07141783088445663,
      -0.009633391164243221,
      0.06447470933198929,
      -0.0085346270352602,
      -0.0015908932546153665,
      -0.06400761753320694,
      -0.01728532463312149,
      -0.07833734899759293,
      -0.04993355646729469,
      -0.0211484432220459,
      -0.014564172364771366,
      -0.0372811034321785,
      0.054324738681316376,
      -0.07036273181438446,
      -0.006140813697129488,
      0.04645226150751114,
      0.003449162235483527,
      -0.013378005474805832,
      -0.033990271389484406,
      0.045598626136779785,
      -0.009328524582087994,
      0.052798379212617874,
      -0.007601356133818626,
      -0.007143605966120958,
      -0.02003820426762104,
      0.02040920779109001,
      0.05477768927812576,
      0.007269176654517651,
      -0.0006826038006693125,
      0.06472007185220718,
      0.03159279376268387,
      -0.021262289956212044,
      -0.04400550201535225,
      -0.013738961890339851,
      0.0028352669905871153,
      0.007917745038866997,
      -0.029012808576226234,
      0.03560060262680054,
      0.08175729960203171,
      0.052964579313993454,
      0.012086289003491402,
      -0.013913363218307495,
      0.0014663457404822111,
      0.02687307447195053,
      -0.050950538367033005,
      -0.049352534115314484,
      -0.06725525110960007,
      0.03335680440068245,
      -0.03692285344004631,
      -0.022365055978298187,
      0.031157156452536583,
      0.0479898564517498,
      0.006944582331925631,
      -0.00253962236456573,
      0.011836185120046139,
      0.07942073792219162,
      0.0179829690605402,
      0.05357760936021805,
      0.08354976028203964,
      0.07775422185659409,
      0.06051330268383026,
      -0.013718381524085999,
      -0.001117841573432088,
      -0.07300644367933273,
      -0.003935534507036209,
      0.019992660731077194,
      -0.01602283865213394,
      0.03785741701722145,
      0.03093535453081131,
      0.06168841943144798,
      0.0133879529312253,
      -0.02175968699157238,
      0.054211247712373734,
      -0.02031795307993889,
      -0.0024658385664224625,
      0.02649041637778282,
      -0.11805504560470581,
      -0.03041440062224865,
      0.06980753690004349,
      0.05352579057216644,
      -0.04888506978750229,
      0.03826375678181648,
      0.053842414170503616,
      -0.0763770192861557,
      -0.013864222913980484,
      0.008032386191189289,
      -0.07291031628847122,
      0.019105644896626472,
      -0.08011280000209808,
      0.06021241098642349,
      -0.024408867582678795,
      0.10539213567972183,
      -0.05072176456451416,
      0.03562517464160919,
      0.013258490711450577,
      -0.020550601184368134,
      0.028076404705643654,
      0.07461468130350113,
      -0.006153641268610954,
      0.009958754293620586,
      0.023023638874292374,
      0.01735433004796505,
      -0.09326430410146713,
      -0.04752262309193611,
      -0.06326165050268173,
      0.02515578269958496,
      -0.061448827385902405,
      -0.10088999569416046,
      0.020117100328207016,
      -0.03864939510822296,
      0.006967458873987198,
      -0.04883861914277077,
      -0.012610848061740398,
      0.049122154712677,
      0.03534045070409775,
      -0.057919736951589584,
      -0.021423008292913437,
      -0.01965957321226597,
      0.0014617769047617912,
      -0.025570452213287354,
      -0.050992202013731,
      0.0037004800979048014,
      0.03808773308992386,
      -0.0475589893758297,
      -0.00696185976266861,
      0.002853090176358819,
      -0.057706743478775024,
      -0.02052992954850197,
      0.04615116864442825,
      -0.050644923001527786,
      0.035378169268369675,
      -0.031184637919068336,
      0.017327910289168358,
      0.019243376329541206,
      0.007977766916155815,
      0.03551911562681198,
      0.05338301882147789,
      -0.051372501999139786,
      -0.03038930334150791,
      -0.05131133645772934,
      -0.06675730645656586,
      0.02539392001926899,
      0.015943896025419235,
      -0.046606771647930145,
      -0.04896142706274986,
      0.030993683263659477,
      0.013216180726885796,
      -0.00355077488347888,
      0.015353052876889706,
      -0.013847487978637218,
      -0.07086929678916931,
      -0.033181965351104736,
      -0.02164577692747116,
      -0.0347558856010437,
      0.015690037980675697,
      0.0012076712446287274,
      0.014373733662068844,
      -0.009411696344614029,
      0.03909722715616226,
      0.043871402740478516,
      0.07161997258663177,
      0.058954183012247086,
      0.036546602845191956,
      -0.08710122853517532,
      -0.04138780012726784,
      -0.014874552376568317,
      0.025476055219769478,
      -0.0005689373938366771,
      0.05556707829236984,
      0.04227054491639137,
      -0.026389654725790024,
      0.04399184137582779,
      -0.03424425423145294,
      0.09612622857093811,
      0.05846191942691803,
      -0.0473698154091835,
      -0.01116369292140007,
      0.01538234855979681,
      0.04093741253018379,
      0.04613582044839859,
      0.03535160422325134,
      -0.04800964891910553,
      0.03491855412721634,
      -0.030973544344305992,
      -0.10348907858133316,
      0.05106111243367195,
      0.04985982924699783,
      0.0633988231420517,
      -0.02773495763540268,
      0.013101795688271523,
      0.04368586838245392,
      -0.040569525212049484,
      0.07239553332328796,
      0.060724105685949326,
      -0.04683038219809532,
      0.020318035036325455,
      -0.006659999489784241,
      0.07202056050300598,
      -0.036689989268779755,
      0.09440509974956512,
      -0.00017855451733339578,
      -0.0010917208855971694,
      0.05501478910446167,
      0.05737033858895302,
      -0.0036035655066370964,
      0.03709454834461212,
      0.03346705064177513,
      -0.12545892596244812
    ],
    [
      0.04132433980703354,
      -0.06179996579885483,
      0.015932122245430946,
      -0.04268037900328636,
      0.04220180958509445,
      0.02956969663500786,
      -0.010724222287535667,
      0.01922515779733658,
      0.02934100106358528,
      -0.026625750586390495,
      0.046978723257780075,
      0.03602529317140579,
      -0.024861527606844902,
      0.025869740173220634,
      0.04740967974066734,
      -0.00636651273816824,
      0.03367261961102486,
      -0.07131943851709366,
      0.027849838137626648,
      0.05012529343366623,
      0.01702292077243328,
      0.0057046059519052505,
      -0.06936750560998917,
      0.04272565245628357,
      0.005730424541980028,
      0.01099400594830513,
      -0.009596200659871101,
      0.06370765715837479,
      -0.043683674186468124,
      0.03830868378281593,
      -0.03274919465184212,
      0.029126549139618874,
      0.0165144894272089,
      -0.11934636533260345,
      -0.027542507275938988,
      -0.013185647316277027,
      -0.014816762879490852,
      0.025348834693431854,
      -0.08646617084741592,
      -0.04753687232732773,
      0.002127902116626501,
      0.05306444317102432,
      -0.027625268325209618,
      -0.049650371074676514,
      -0.04700945317745209,
      0.06252395361661911,
      0.025744108483195305,
      -0.07692304998636246,
      0.027248011901974678,
      -0.07766202837228775,
      -0.027315597981214523,
      0.009113548323512077,
      -0.04237758368253708,
      0.0045969304628670216,
      0.025415165349841118,
      0.07914971560239792,
      -0.05228222534060478,
      -0.032606326043605804,
      0.08472778648138046,
      0.04619571939110756,
      0.06376165896654129,
      0.014707686379551888,
      0.04271439090371132,
      -0.03677784278988838,
      -0.03320903331041336,
      -0.010508456267416477,
      -0.08379513025283813,
      -0.05602584779262543,
      -0.010789712890982628,
      -0.006290114019066095,
      0.024336662143468857,
      -0.02073197439312935,
      -0.061421334743499756,
      0.0008371166768483818,
      -0.015410990454256535,
      -0.002618181286379695,
      0.012823631055653095,
      -0.006225415505468845,
      -0.010469267144799232,
      -0.030786477029323578,
      -0.09897536784410477,
      0.007028867490589619,
      -0.11182909458875656,
      -0.006304961629211903,
      -0.004710191860795021,
      -0.02325768768787384,
      -0.02204516902565956,
      -0.004446216858923435,
      0.04714484512805939,
      -0.0456494502723217,
      -0.007160105276852846,
      0.07482053339481354,
      -0.014872048050165176,
      -0.07988651096820831,
      -0.06437947601079941,
      -0.07722070813179016,
      -0.04952631890773773,
      -0.03488388657569885,
      -0.047367654740810394,
      -0.005541228223592043,
      0.06203971803188324,
      0.03185071423649788,
      0.023902056738734245,
      0.12710265815258026,
      0.10324584692716599,
      -0.0937829464673996,
      0.10707508772611618,
      0.04944368824362755,
      -0.07557155191898346,
      -0.00028255648794583976,
      0.04577399790287018,
      -0.03811264783143997,
      0.020163780078291893,
      0.0039933305233716965,
      0.06057802960276604,
      0.03590591624379158,
      -0.009779885411262512,
      -0.020412154495716095,
      0.00825676042586565,
      0.02920382097363472,
      0.12203613668680191,
      -0.011539952829480171,
      -0.006658200174570084,
      0.09400198608636856,
      -0.052044034004211426,
      -0.037849310785532,
      -0.08116314560174942,
      -0.047780606895685196,
      0.027498802170157433,
      -0.0715150386095047,
      0.034658193588256836,
      0.046773284673690796,
      -0.050851184874773026,
      0.10333172976970673,
      0.10201239585876465,
      -0.028498856350779533,
      -0.0540790893137455,
      0.0017422999953851104,
      0.09519203752279282,
      0.03471966087818146,
      -0.037316665053367615,
      0.01460722554475069,
      -0.021506933495402336,
      0.08317280560731888,
      -0.014076690189540386,
      -0.02025117725133896,
      0.09566588699817657,
      0.09234627336263657,
      -0.002405649982392788,
      0.06374060362577438,
      -0.10034853219985962,
      0.02009914070367813,
      0.03160035237669945,
      -0.0011049738386645913,
      -0.07565154135227203,
      -0.022759610787034035,
      -0.01074137818068266,
      -0.10929901897907257,
      -0.04400584474205971,
      -0.01805495098233223,
      -0.04837381839752197,
      -0.06855408102273941,
      0.06483451277017593,
      -0.017821718007326126,
      0.014083432964980602,
      0.029614487662911415,
      -0.05398429185152054,
      0.1294270008802414,
      0.01596992276608944,
      0.05645188316702843,
      -0.0653158649802208,
      -0.05656799301505089,
      -0.04298871010541916,
      -0.023204470053315163,
      0.009658767841756344,
      0.06533034890890121,
      0.0755562111735344,
      -0.08114906400442123,
      0.07381425052881241,
      -0.025952553376555443,
      -0.007197486236691475,
      -0.051565300673246384,
      -0.029263978824019432,
      0.025701472535729408,
      -0.018445691093802452,
      0.004415757022798061,
      -0.019504137337207794,
      0.028728490695357323,
      0.037015561014413834,
      -0.017471691593527794,
      0.004265316296368837,
      -0.001196281868033111,
      -0.03705877810716629,
      -0.01411052979528904,
      0.08361301571130753,
      0.03466896712779999,
      -0.03360569104552269,
      -0.03287533298134804,
      -0.027916893362998962,
      0.006015351042151451,
      0.006688309833407402,
      0.0007170798489823937,
      0.12414528429508209,
      -0.047716185450553894,
      -0.07886320352554321,
      -0.02042170614004135,
      0.0241750068962574,
      -0.0947515219449997,
      -0.050109200179576874,
      0.0816006287932396,
      0.08078379184007645,
      -0.14455245435237885,
      0.0013275020755827427,
      0.031605467200279236,
      -0.10129774361848831,
      -0.07451360672712326,
      -0.011323545128107071,
      0.09491840749979019,
      -0.07766921818256378,
      -0.010863455943763256,
      -0.04306827113032341,
      -0.09283968806266785,
      0.0762098878622055,
      -0.09024766832590103,
      0.08194954693317413,
      -0.01328500546514988,
      -0.01208820752799511,
      -0.06720927357673645,
      0.05285298824310303,
      0.018870575353503227,
      -0.07740071415901184,
      -0.07105723768472672,
      0.016683541238307953,
      0.036544185131788254,
      -0.017739614471793175,
      -0.0037180818617343903,
      0.02953219972550869,
      -0.027760352939367294,
      0.028095075860619545,
      -0.039249926805496216,
      -0.08693613111972809,
      0.019528256729245186,
      -0.005217553116381168,
      -0.06270421296358109,
      0.003735100384801626,
      -0.008039354346692562,
      0.03961116448044777,
      -0.07074711471796036,
      0.026426170021295547,
      0.016435688361525536,
      -0.0055419751442968845,
      -0.09353944659233093,
      -0.043437689542770386,
      -0.03751588985323906,
      0.057300712913274765,
      0.06047908961772919,
      0.02626005932688713,
      0.009903233498334885,
      -0.02783387340605259,
      0.02412736415863037,
      0.0026611010544002056,
      0.0013962376397103071,
      -0.05515837296843529,
      -0.012444219551980495,
      -0.02011999860405922,
      0.02198612317442894,
      -0.006611702498048544,
      0.016322588548064232,
      -0.04218071699142456,
      0.03560076653957367,
      -0.02911059930920601,
      0.03201427683234215,
      -0.013689185492694378,
      -0.03495725989341736,
      -0.013148636557161808,
      -0.01115723792463541,
      0.04940019175410271,
      0.07964424788951874,
      0.03539441153407097,
      0.012030291371047497,
      -0.03430071845650673,
      -0.029797958210110664,
      -0.0591549351811409,
      -0.04271315038204193,
      -0.07958252727985382,
      -0.0545656755566597,
      -0.003784707747399807,
      0.01089152880012989,
      0.027996957302093506,
      0.023703355342149734,
      0.008875954896211624,
      -0.07674092054367065,
      -0.011835883371531963,
      0.005604092963039875,
      0.016028286889195442,
      -0.042444586753845215,
      0.0006419733981601894,
      -0.019785001873970032,
      -0.007831687107682228,
      -0.01550560723990202,
      -0.00716032087802887,
      0.05846177786588669,
      -0.028318557888269424,
      0.027307530865073204,
      -0.007584478706121445,
      0.05461934208869934,
      -0.02620628848671913,
      -0.001605035737156868,
      -0.019684193655848503,
      -0.021880391985177994,
      -0.00040598693885840476,
      -0.01764744147658348,
      0.10816166549921036,
      -0.008054313249886036,
      -0.035196173936128616,
      -0.04879055544734001,
      0.0035835024900734425,
      0.0032244001049548388,
      0.07187903672456741,
      -0.04819489270448685,
      0.024164589121937752,
      0.02318108081817627,
      -0.031118299812078476,
      0.04366717115044594,
      -0.00812433660030365,
      0.038645025342702866,
      0.026305783540010452,
      -0.11817191541194916,
      0.03341323137283325,
      -0.0658014565706253,
      0.023352060467004776,
      0.013687031343579292,
      0.06146271899342537,
      -0.05223116651177406,
      -0.09445779025554657,
      0.07583211362361908,
      -0.03896699100732803,
      0.029041578993201256,
      0.03942558914422989,
      -0.030026037245988846,
      -0.09709592163562775,
      -0.011479411274194717,
      -0.09621407091617584,
      0.007103415206074715,
      -0.05058044195175171,
      -0.026401974260807037,
      0.016068359836935997,
      0.11300143599510193,
      0.03433333337306976,
      0.017338117584586143,
      0.0460854098200798,
      0.0010377684375271201,
      0.013327162712812424,
      -0.04245870187878609,
      -0.04133224114775658,
      0.03347689285874367,
      0.02398858591914177,
      -0.09877704083919525,
      0.032692864537239075,
      0.030092541128396988,
      -0.01873284950852394,
      0.017980173230171204,
      -0.008662441745400429,
      0.011893399059772491,
      -0.03971860557794571,
      -0.028121061623096466,
      -0.02608635649085045,
      0.031159816309809685,
      0.0020396695472300053,
      -0.03160584345459938,
      -0.008423902094364166,
      0.028973247855901718,
      -0.0071267918683588505,
      0.0013107892591506243,
      -0.023926157504320145,
      -0.02333374135196209,
      0.008808464743196964,
      0.015424392186105251,
      -0.09728752821683884,
      0.07243498414754868,
      0.020450297743082047,
      -0.05616346001625061,
      0.09186618775129318,
      0.022595446556806564,
      -0.020481741055846214,
      0.023814862594008446,
      0.07777360081672668,
      -0.03478926792740822,
      -0.13145475089550018,
      0.020286493003368378,
      0.04529579356312752,
      0.05016271397471428,
      0.014431807212531567,
      -0.051313161849975586,
      -0.016468677669763565,
      -0.012782427482306957,
      -0.03582262247800827,
      -0.03607044741511345,
      -0.016635160893201828,
      -0.005181578919291496,
      -0.013491763733327389,
      -0.04919114336371422,
      -0.07321295142173767,
      -0.012297612614929676,
      0.013053296133875847,
      0.02260015904903412,
      -0.02687215432524681,
      -0.004418221767991781,
      0.006430600304156542,
      0.06339118629693985,
      -0.087421715259552,
      -0.014519094489514828,
      -0.04797994717955589,
      -0.028023410588502884,
      -0.03351898863911629,
      0.10843003541231155,
      0.03397871553897858,
      -0.04684138670563698,
      0.10541316866874695,
      -0.03987850248813629,
      -0.021769529208540916,
      -0.07075753808021545,
      0.08599410951137543,
      -0.0068679689429700375,
      0.014443532563745975,
      -0.017196090891957283,
      0.05183551833033562,
      0.01909700408577919,
      0.0331413596868515,
      0.06595885753631592,
      -0.0015193509170785546,
      0.022684965282678604,
      -0.051269784569740295,
      0.013586441986262798,
      0.055403273552656174,
      -0.004811019171029329,
      -0.053622130304574966,
      0.014416434802114964,
      0.009198997169733047,
      0.06692535430192947,
      0.04118146374821663,
      -0.06190904974937439,
      -0.0003073749248869717,
      -0.044375885277986526,
      0.04599018022418022,
      -0.05112551525235176,
      0.017915306612849236,
      0.03380918502807617,
      -0.0862601026892662,
      0.026366157457232475,
      0.03902401402592659,
      0.01819927804172039,
      -0.03733878582715988,
      0.09009352326393127,
      -0.023721497505903244,
      0.047454290091991425,
      0.04978196695446968,
      0.11755988746881485,
      0.02780909277498722,
      -0.022236261516809464,
      0.10890883207321167,
      0.07219509780406952,
      -0.008823190815746784,
      -0.009490102529525757,
      -0.041918057948350906,
      0.010998678393661976,
      -0.10376279801130295,
      -0.14981889724731445,
      0.06181985139846802,
      0.09830795228481293,
      0.004332730546593666,
      0.05029252916574478,
      -0.030257726088166237,
      0.023458784446120262,
      0.04537909850478172,
      -0.009134423919022083,
      0.0022329112980514765,
      0.08659522980451584,
      0.03388923779129982,
      -0.01788591407239437,
      0.06811986118555069,
      -0.09312812238931656,
      0.05729631334543228,
      -0.003084837691858411,
      -0.018636077642440796,
      0.036390263587236404,
      -0.054385021328926086,
      -0.049088820815086365,
      -0.03990177810192108,
      -0.016031568869948387,
      0.008143388666212559,
      0.09341318160295486,
      -0.03725159168243408,
      0.04668740928173065,
      -0.024954186752438545,
      0.09590645879507065,
      -0.008859769441187382,
      -0.021041160449385643,
      -0.0072548482567071915,
      -0.12334012985229492,
      -0.016050923615694046,
      0.04090990498661995,
      -0.0777256190776825
    ],
    [
      0.03512720391154289,
      0.04925892874598503,
      -0.01489889994263649,
      -0.037925440818071365,
      0.057794228196144104,
      0.05369212105870247,
      0.043389417231082916,
      -0.02343866229057312,
      0.0405203253030777,
      0.008286435157060623,
      0.0030334838666021824,
      0.07696707546710968,
      0.004694154020398855,
      -0.034404125064611435,
      -0.04773792624473572,
      0.043941430747509,
      -0.05635878071188927,
      0.06050582602620125,
      0.06810940057039261,
      0.029656287282705307,
      -0.003968344535678625,
      -0.04228628799319267,
      0.02030705288052559,
      -0.06176433712244034,
      0.010532403364777565,
      -0.023326369002461433,
      0.006793028209358454,
      0.04977896064519882,
      -0.054727423936128616,
      0.01097557321190834,
      0.020384833216667175,
      0.0031369130592793226,
      0.0032359182368963957,
      0.005078709218651056,
      0.01672014780342579,
      -0.03886881843209267,
      0.016422029584646225,
      0.07268714159727097,
      -0.02347199246287346,
      -0.11833671480417252,
      0.043395135551691055,
      0.04914466291666031,
      -0.07490222901105881,
      0.040949560701847076,
      -0.045578133314847946,
      -0.027297766879200935,
      -0.006086059380322695,
      0.025847166776657104,
      -0.016223086044192314,
      -0.0341874398291111,
      -0.06965912878513336,
      0.012920305132865906,
      -0.027991190552711487,
      0.00916372425854206,
      0.08727136999368668,
      0.038085609674453735,
      -0.05974142253398895,
      -0.040368545800447464,
      0.05461399629712105,
      0.019167471677064896,
      0.0007302677840925753,
      -0.020277060568332672,
      0.05021579936146736,
      -0.00186227448284626,
      -0.02703000418841839,
      0.07295110076665878,
      -0.009512415155768394,
      0.05788496881723404,
      -0.055430904030799866,
      0.04019155725836754,
      0.04841471090912819,
      -0.028797004371881485,
      -0.0671243667602539,
      -0.05638892576098442,
      0.054898690432310104,
      0.050902120769023895,
      -0.003406470874324441,
      -0.0077681951224803925,
      -0.04542876034975052,
      -0.019380120560526848,
      0.017964214086532593,
      -0.043194666504859924,
      -0.019955236464738846,
      0.054498106241226196,
      0.03186294808983803,
      -0.030631454661488533,
      -0.014032591134309769,
      0.07166945934295654,
      0.06235145032405853,
      0.023838011547923088,
      0.02178078517317772,
      0.01787724532186985,
      0.04639063775539398,
      -0.029276017099618912,
      -0.03928396850824356,
      0.0055580479092895985,
      -0.0663064569234848,
      0.009597991593182087,
      0.02410811558365822,
      0.04965299367904663,
      0.028038792312145233,
      -0.04586222022771835,
      -0.05800827220082283,
      0.040495723485946655,
      0.02579297497868538,
      0.05048687383532524,
      0.06811446696519852,
      0.015888363122940063,
      -0.08482718467712402,
      0.008215341717004776,
      -0.019244778901338577,
      -0.011854630894958973,
      -0.04709380492568016,
      0.07038727402687073,
      -0.02479172870516777,
      0.0030100808944553137,
      -0.009633059613406658,
      0.021608062088489532,
      0.00150093634147197,
      -0.003705832874402404,
      0.09916725009679794,
      -0.012984600849449635,
      0.01113131269812584,
      0.05407143011689186,
      -0.02720523066818714,
      0.017657699063420296,
      -0.026856452226638794,
      0.04886113479733467,
      0.01526492740958929,
      -0.032875824719667435,
      -0.05576234683394432,
      -0.02926425263285637,
      0.04517252743244171,
      -0.06724120676517487,
      0.04857185110449791,
      0.04393594339489937,
      -0.060760606080293655,
      -0.01829036884009838,
      0.0725293755531311,
      -0.014775275252759457,
      -0.022213539108633995,
      0.0158238522708416,
      -0.07484015077352524,
      0.03154535964131355,
      0.04751809686422348,
      -0.0012535406276583672,
      -0.0035939551889896393,
      0.06913590431213379,
      -0.044217947870492935,
      0.029620714485645294,
      0.027168186381459236,
      0.05808783322572708,
      -0.08541963994503021,
      0.018404901027679443,
      0.026064932346343994,
      0.0020615989342331886,
      -0.04563841223716736,
      -0.08516497164964676,
      -0.046248894184827805,
      -0.009674513712525368,
      0.03757389262318611,
      -0.008665885776281357,
      0.07320409268140793,
      -0.00394095154479146,
      0.032724037766456604,
      -0.020649947226047516,
      -0.062215786427259445,
      -0.017691008746623993,
      0.05020962655544281,
      0.008174121379852295,
      0.0825996845960617,
      0.0008241763571277261,
      -0.02343292534351349,
      -0.03399134799838066,
      -0.0018097847932949662,
      0.012728968635201454,
      0.03208865970373154,
      -0.03470160439610481,
      -0.08992838114500046,
      -0.028962939977645874,
      0.02684081345796585,
      -0.01816403865814209,
      -0.011454323306679726,
      0.06068572402000427,
      -0.02464468590915203,
      0.0735890120267868,
      -0.01903389021754265,
      -0.01309284195303917,
      0.0815570205450058,
      0.03901917114853859,
      -0.015990065410733223,
      -0.0387556217610836,
      -0.002919014310464263,
      0.02425752393901348,
      -0.0022718291729688644,
      0.01758909970521927,
      0.03667541593313217,
      0.0374436229467392,
      -0.006173716392368078,
      -0.034464627504348755,
      0.03877699747681618,
      -0.07717344164848328,
      0.023909883573651314,
      0.038472022861242294,
      -0.004488274455070496,
      0.039617449045181274,
      -0.053248755633831024,
      0.046123191714286804,
      0.028668925166130066,
      -0.024805109947919846,
      -0.021475857123732567,
      0.01745402254164219,
      0.0988074392080307,
      0.0752640813589096,
      0.025069912895560265,
      0.0544462688267231,
      0.040635846555233,
      -0.02081161178648472,
      -0.008866731077432632,
      0.017381617799401283,
      0.013055377639830112,
      -0.025605937466025352,
      0.022449124604463577,
      -0.06236572563648224,
      -0.02993120066821575,
      0.0308045856654644,
      0.02035386487841606,
      -0.11553411930799484,
      0.11052355170249939,
      0.03394097089767456,
      0.030850084498524666,
      0.01393382903188467,
      0.027434606105089188,
      -0.0060777924954891205,
      -0.012157730758190155,
      -0.008713822811841965,
      -0.007932132109999657,
      -0.008481400087475777,
      0.015993468463420868,
      -0.04641193896532059,
      -0.024337952956557274,
      0.027226699516177177,
      -0.016475137323141098,
      -0.07867709547281265,
      0.00952047947794199,
      0.00983523577451706,
      -0.004060239996761084,
      0.0576464980840683,
      0.03492332622408867,
      -0.04616810753941536,
      -0.03475871682167053,
      -0.002525357296690345,
      -0.05102384090423584,
      0.021094772964715958,
      -0.036144956946372986,
      0.006676615681499243,
      -0.0242936871945858,
      -0.010308141820132732,
      -0.0034763675648719072,
      -0.042667776346206665,
      0.02102792076766491,
      -0.05544351786375046,
      -0.06292428821325302,
      0.005840550642460585,
      -0.030207378789782524,
      -0.029549269005656242,
      -0.026273295283317566,
      -0.030348079279065132,
      0.03659895807504654,
      0.053202200680971146,
      0.09186980128288269,
      0.04019898548722267,
      -0.05679454654455185,
      -0.09424590319395065,
      0.06607301533222198,
      -0.014125891961157322,
      -0.023321984335780144,
      0.048286546021699905,
      -0.04718624800443649,
      0.1496630311012268,
      -0.011981670744717121,
      0.01891467720270157,
      0.006844060029834509,
      -0.008840617723762989,
      0.030975712463259697,
      -0.049818217754364014,
      0.04067152366042137,
      -0.013202295638620853,
      0.015138267539441586,
      0.04922443628311157,
      0.010844539850950241,
      0.03323519229888916,
      0.01553487777709961,
      0.028005771338939667,
      0.05097837746143341,
      0.0060793496668338776,
      -0.03572266548871994,
      -0.02371382713317871,
      0.04923882335424423,
      -0.030001331120729446,
      -0.005715345963835716,
      0.0063761030323803425,
      -0.010362489148974419,
      0.04447579011321068,
      0.001126464456319809,
      0.020809797570109367,
      0.060771968215703964,
      -0.04497945308685303,
      -0.06151962652802467,
      0.0708891823887825,
      -0.02023838646709919,
      -0.003520200029015541,
      0.01328070554882288,
      0.0158047117292881,
      -0.029060354456305504,
      0.11341018229722977,
      0.05791854113340378,
      -0.026164144277572632,
      0.010913435369729996,
      -0.0014395738253369927,
      -0.0281990896910429,
      -0.011554148979485035,
      -0.04291871562600136,
      0.04688821732997894,
      -0.039157360792160034,
      -0.1219516173005104,
      0.04173935949802399,
      -0.04301375523209572,
      -0.0471663661301136,
      0.021935004740953445,
      -0.014531712979078293,
      -0.02671845071017742,
      0.07375713437795639,
      0.0485108345746994,
      0.008274111896753311,
      0.007306077051907778,
      -0.028954021632671356,
      0.05289176106452942,
      0.09364518523216248,
      0.04511398822069168,
      0.00577881745994091,
      0.0354447215795517,
      0.045181259512901306,
      0.08752940595149994,
      -0.031448401510715485,
      0.09270721673965454,
      0.012562649324536324,
      -0.01503850519657135,
      0.05712461844086647,
      0.05648799240589142,
      0.10318925231695175,
      -0.05557599291205406,
      0.0876077339053154,
      -0.03148764744400978,
      0.011482690460979939,
      0.08843408524990082,
      0.024975791573524475,
      -0.020793462172150612,
      -0.08826076239347458,
      0.011275271885097027,
      0.03925386443734169,
      -0.07915835827589035,
      0.0020219902507960796,
      0.03487269952893257,
      0.010140492580831051,
      0.004037796985358,
      0.011385494843125343,
      -0.041542552411556244,
      0.009985595941543579,
      -0.13383367657661438,
      0.058214616030454636,
      -0.006301947869360447,
      -0.002130478387698531,
      0.07048162072896957,
      -0.03563976660370827,
      -0.028718944638967514,
      0.007621922995895147,
      -0.08061382174491882,
      -0.010469330474734306,
      0.05411767587065697,
      -0.014527646824717522,
      -0.02614694833755493,
      0.017679864540696144,
      -0.08200376480817795,
      0.06170923635363579,
      -0.06512764096260071,
      0.004194318316876888,
      0.003479992737993598,
      0.038522761315107346,
      -0.011544925160706043,
      0.0038715300615876913,
      0.012073520570993423,
      -0.08468631654977798,
      -0.10907044261693954,
      0.07337038964033127,
      0.06828028708696365,
      0.043333131819963455,
      -0.04711831733584404,
      -0.07300303876399994,
      -0.0031786111649125814,
      0.06416403502225876,
      -0.054259758442640305,
      0.06014121323823929,
      0.040979497134685516,
      -0.0566631443798542,
      0.018253326416015625,
      -0.029142074286937714,
      -0.056474920362234116,
      0.04051162675023079,
      0.04802423715591431,
      -0.004380480386316776,
      0.03601237013936043,
      0.008394163101911545,
      0.03854751959443092,
      0.025668689981102943,
      -0.012019495479762554,
      -0.10083767026662827,
      -0.00429858872666955,
      -0.017664294689893723,
      -0.07810927927494049,
      -0.06544540822505951,
      -0.03580610826611519,
      0.02774968184530735,
      0.04394789785146713,
      -0.026753423735499382,
      -0.04225288704037666,
      -0.024218302220106125,
      0.03763962164521217,
      0.02110881544649601,
      -0.02123325876891613,
      -0.03848208859562874,
      0.04951130971312523,
      0.03094838373363018,
      0.009731881320476532,
      0.01914358325302601,
      0.05680212005972862,
      -0.033364757895469666,
      0.07012082636356354,
      -0.036045920103788376,
      0.02540058270096779,
      -0.01001886185258627,
      0.030149733647704124,
      0.04430077224969864,
      0.015521018765866756,
      -0.031147388741374016,
      0.0844573900103569,
      -0.10685452073812485,
      -0.0027680902276188135,
      0.04459773376584053,
      -0.011583326384425163,
      -0.005224075634032488,
      -0.024797091260552406,
      -0.05831059813499451,
      -0.04294850304722786,
      -0.001976925181224942,
      -0.008526148274540901,
      -0.020086634904146194,
      0.06053159758448601,
      -0.001311464817263186,
      -0.045719921588897705,
      -0.01636314205825329,
      -0.0013752084923908114,
      -0.0031470563262701035,
      -0.058699220418930054,
      -0.049982741475105286,
      -0.0008009792072698474,
      -0.09594595432281494,
      0.012625269591808319,
      0.04728909209370613,
      -0.03425992652773857,
      -0.13452017307281494,
      -0.10694244503974915,
      -0.005491937510669231,
      0.016814284026622772,
      0.03696024417877197,
      -0.05497288703918457,
      0.0014962900895625353,
      0.06750506162643433,
      0.08400065451860428,
      -0.0657498836517334,
      0.05626453086733818,
      -0.012120033614337444,
      0.010342041030526161,
      -0.022834278643131256,
      -0.011253529228270054,
      -0.13036885857582092,
      -0.08083153516054153,
      0.07825708389282227,
      0.001899804687127471,
      0.006837747525423765,
      0.06040351092815399,
      0.05534164234995842,
      -0.03094160370528698,
      0.06434402614831924,
      0.06710561364889145,
      0.08899108320474625,
      -0.014342871494591236,
      0.09170534461736679,
      0.05629623681306839,
      0.05129178985953331,
      -0.01706899330019951,
      -0.025720972567796707,
      -0.028207775205373764
    ],
    [
      -0.001172268996015191,
      -0.02137359045445919,
      0.048952456563711166,
      -0.05385759472846985,
      0.014216950163245201,
      -0.02131233736872673,
      -0.005372010171413422,
      0.03940212354063988,
      -0.009615878574550152,
      -0.04723697528243065,
      0.06129125505685806,
      -0.03546775504946709,
      -0.11548291891813278,
      0.01215001754462719,
      -0.0014495491050183773,
      0.00571392010897398,
      -0.05526196211576462,
      0.09666602313518524,
      -0.03198756277561188,
      -0.017267536371946335,
      0.0737469270825386,
      0.059720445424318314,
      -0.04933350905776024,
      -0.10483168065547943,
      0.020335329696536064,
      -0.04504600539803505,
      -0.009343392215669155,
      0.010864585638046265,
      0.05508994311094284,
      0.014186636544764042,
      0.030196307227015495,
      -0.015615144744515419,
      -0.07224712520837784,
      -0.02333219163119793,
      0.01889893226325512,
      0.03198830038309097,
      0.001346655422821641,
      0.010637383908033371,
      -0.00037378797424025834,
      0.06775649636983871,
      0.049203772097826004,
      -0.02902054414153099,
      -0.01679164730012417,
      -0.0007726663025096059,
      0.02442772500216961,
      0.012829126790165901,
      0.022216426208615303,
      0.042351461946964264,
      -0.016188988462090492,
      -0.042769625782966614,
      0.04675189405679703,
      -0.06763169914484024,
      0.031301889568567276,
      0.02049793303012848,
      -0.04180391505360603,
      -0.03386925905942917,
      -0.015462543815374374,
      0.10474877059459686,
      0.07788603752851486,
      0.02623642235994339,
      0.02377631515264511,
      0.0039256056770682335,
      -0.025783011689782143,
      -0.06473179906606674,
      0.026875240728259087,
      -0.12817466259002686,
      -0.017915839329361916,
      -0.03711048141121864,
      -0.039233461022377014,
      0.016196224838495255,
      0.024190744385123253,
      0.0009736136998981237,
      -0.00464757950976491,
      0.04293682426214218,
      0.025809040293097496,
      -0.025370649993419647,
      0.010009737685322762,
      0.0371101088821888,
      -0.031171074137091637,
      -0.052551981061697006,
      -0.0812608152627945,
      -0.06433751434087753,
      -0.0005661007598973811,
      0.07020682841539383,
      0.021884972229599953,
      0.02062740921974182,
      -0.04224678874015808,
      -0.0204076636582613,
      -0.018935149535536766,
      0.03255453333258629,
      0.024058572947978973,
      -0.008938589133322239,
      -0.02138177677989006,
      0.03678222373127937,
      0.05101726949214935,
      -0.013273290358483791,
      -0.025800326839089394,
      0.001491060946136713,
      0.023975124582648277,
      0.023131033405661583,
      0.03698142617940903,
      -0.017994506284594536,
      -0.04569712653756142,
      0.05051659792661667,
      -0.011681311763823032,
      0.07592090964317322,
      0.005272983573377132,
      -0.11610057204961777,
      -0.0770757645368576,
      0.03933064639568329,
      -0.007425615098327398,
      -0.06619980186223984,
      -0.061236537992954254,
      0.06572634726762772,
      0.042007338255643845,
      -0.02630092203617096,
      -0.007848191075026989,
      0.01793515868484974,
      -0.06869375705718994,
      -0.021093206480145454,
      0.041645459830760956,
      0.023830469697713852,
      0.0007348839426413178,
      0.00851495936512947,
      -0.07320360839366913,
      -0.011020936071872711,
      0.06521191447973251,
      0.12185893207788467,
      0.048809368163347244,
      0.017970548942685127,
      -0.02600266970694065,
      -0.032761286944150925,
      -0.04455605894327164,
      0.014281881041824818,
      -0.02208435907959938,
      0.038471728563308716,
      -0.054781313985586166,
      -0.05251062661409378,
      0.02872580662369728,
      -0.046301744878292084,
      0.014133875258266926,
      -9.752632468007505e-05,
      -0.03602752834558487,
      0.04044506326317787,
      0.0008618226274847984,
      -0.024276655167341232,
      0.04410251975059509,
      -0.043163131922483444,
      -0.03824150934815407,
      -0.018834179267287254,
      -0.013951481319963932,
      -0.01602211408317089,
      0.009557166136801243,
      -0.04628355801105499,
      0.0069603873416781425,
      0.022235577926039696,
      -0.05296863242983818,
      0.0020629887003451586,
      -0.021442897617816925,
      -0.001406563213095069,
      -0.06314899772405624,
      -0.0073515549302101135,
      0.013714160770177841,
      0.03394916653633118,
      -0.04929091036319733,
      -0.022571835666894913,
      -0.027030810713768005,
      0.054955773055553436,
      0.026724159717559814,
      0.08346891403198242,
      0.02236088551580906,
      0.015191989950835705,
      0.009662693366408348,
      -0.06678879261016846,
      -0.03070829249918461,
      0.013568232767283916,
      -0.07163023203611374,
      0.06947425752878189,
      0.0330033116042614,
      -0.06544835865497589,
      -0.031734928488731384,
      -0.022229060530662537,
      -0.0345519557595253,
      0.04943637549877167,
      0.00275290641002357,
      0.08777210861444473,
      -0.030631843954324722,
      -0.042169034481048584,
      -0.02114401012659073,
      -0.060987308621406555,
      0.0016394708072766662,
      -0.03357376158237457,
      -0.004185310564935207,
      0.047938257455825806,
      0.042194388806819916,
      0.04813976213335991,
      -0.0443904846906662,
      0.034994348883628845,
      -0.103838711977005,
      -0.11699844151735306,
      0.02577817812561989,
      0.01727711409330368,
      -0.008190720342099667,
      -0.012849649414420128,
      -0.07266069948673248,
      -0.011527701281011105,
      -0.04350750520825386,
      -0.03151184320449829,
      -0.009696546010673046,
      -0.0001270384236704558,
      0.016729870811104774,
      -0.04452336207032204,
      0.061627957969903946,
      0.01638185605406761,
      0.05342530831694603,
      -0.063689224421978,
      -0.06327704340219498,
      0.027686042711138725,
      0.022719066590070724,
      0.06352853029966354,
      -0.05682136118412018,
      0.01629386655986309,
      0.05429079756140709,
      -0.06604955345392227,
      0.003752088639885187,
      0.008127800188958645,
      0.021500395610928535,
      -0.0003537166630849242,
      0.013624862767755985,
      -0.010041967034339905,
      -0.04460804536938667,
      -0.01879310980439186,
      0.08176248520612717,
      0.009954258799552917,
      -0.015102176927030087,
      0.009829215705394745,
      -0.044283561408519745,
      -0.04960666969418526,
      0.03959823399782181,
      0.00820117350667715,
      -0.09977990388870239,
      -0.005028911400586367,
      0.03511873632669449,
      0.02912796102464199,
      -0.018955308943986893,
      -0.02549825981259346,
      0.037662334740161896,
      0.056301407516002655,
      -0.0645512118935585,
      -0.05637991428375244,
      0.03613094240427017,
      0.110591821372509,
      -0.004995822440832853,
      0.06190284341573715,
      -0.06247353181242943,
      -0.06866619735956192,
      0.037149347364902496,
      -0.060091037303209305,
      0.03229979798197746,
      0.015256581827998161,
      0.022734178230166435,
      0.021490253508090973,
      -0.06396224349737167,
      -0.005515614058822393,
      -0.04007713869214058,
      0.02387188747525215,
      0.02773251198232174,
      0.04090683162212372,
      -0.03619828447699547,
      -0.06476414948701859,
      0.08325736969709396,
      0.005921455565840006,
      0.02459961734712124,
      0.055497899651527405,
      -0.023701686412096024,
      0.0454733707010746,
      -0.036392681300640106,
      -0.023736344650387764,
      0.009693941101431847,
      0.04312312602996826,
      0.08386934548616409,
      0.020960049703717232,
      0.07701034098863602,
      -0.06416597217321396,
      0.0761704370379448,
      -0.05166652053594589,
      0.049310989677906036,
      0.024889938533306122,
      -0.1164713054895401,
      -0.04046856239438057,
      0.014731279574334621,
      -0.05348268151283264,
      -0.00723707489669323,
      0.04339371249079704,
      -0.027812957763671875,
      0.11221448332071304,
      -0.0013693529181182384,
      0.026129640638828278,
      0.01638723537325859,
      0.038666483014822006,
      0.07610010355710983,
      0.05778064951300621,
      0.013835427351295948,
      -0.019172504544258118,
      -0.08133990317583084,
      -0.010153010487556458,
      -0.02746431715786457,
      0.06442774832248688,
      -0.018851082772016525,
      -0.09339538216590881,
      -0.030020320788025856,
      0.007568432949483395,
      0.01077021099627018,
      0.0114529924467206,
      0.015940850600600243,
      0.00018082791939377785,
      0.12977270781993866,
      -0.01353183202445507,
      0.052203528583049774,
      -0.021899418905377388,
      -0.0843493640422821,
      -0.04752688482403755,
      -0.012328975833952427,
      0.04205615818500519,
      -0.008228992111980915,
      0.053328197449445724,
      0.004066123627126217,
      0.005124371498823166,
      0.027697958052158356,
      0.10098379850387573,
      -0.0070912716910243034,
      0.04782778024673462,
      0.08649491518735886,
      -0.06709841638803482,
      -0.0847746804356575,
      0.02311563864350319,
      -0.05193503946065903,
      -0.0007973458850756288,
      -5.537262404686771e-05,
      -0.009010979905724525,
      -0.032278455793857574,
      -0.07909826189279556,
      0.007204842288047075,
      0.014848357066512108,
      -0.04003533348441124,
      -0.0149966636672616,
      0.02763284370303154,
      0.01671522669494152,
      -0.009391432628035545,
      0.05505846068263054,
      0.004521025810390711,
      -0.025625333189964294,
      0.013760384172201157,
      -0.04328320920467377,
      -0.029928358271718025,
      0.015061402693390846,
      -0.04648088291287422,
      -0.0631292462348938,
      0.04417647421360016,
      0.07925557345151901,
      -0.048502035439014435,
      0.025801075622439384,
      0.06223010644316673,
      0.07362638413906097,
      -0.01823454536497593,
      0.005329430568963289,
      -0.045556265860795975,
      0.056927334517240524,
      -0.0006320252432487905,
      0.08870560675859451,
      0.05575435608625412,
      -0.007041883654892445,
      0.015232793986797333,
      0.015402420423924923,
      0.08555027842521667,
      -0.004035916179418564,
      0.044673606753349304,
      -0.0518258698284626,
      0.022936558350920677,
      0.039322931319475174,
      0.016272183507680893,
      -0.010757165029644966,
      0.05305713787674904,
      -0.043820735067129135,
      0.01904873363673687,
      0.02634909562766552,
      0.010109331458806992,
      -0.006376287434250116,
      -0.008960002101957798,
      0.0961044505238533,
      0.06229356676340103,
      0.02653195895254612,
      -0.06457376480102539,
      -0.03812742233276367,
      -0.020582785829901695,
      -0.060550957918167114,
      0.0783643126487732,
      0.07109498232603073,
      0.10534937679767609,
      0.0579414889216423,
      -0.031085031107068062,
      -0.07453863322734833,
      -0.004534374922513962,
      0.013463609851896763,
      -0.0028614322654902935,
      0.001313823857344687,
      -0.03307851776480675,
      -0.0028788300696760416,
      -0.028333468362689018,
      0.041293323040008545,
      -0.08407346159219742,
      0.011143669486045837,
      0.08186857402324677,
      -0.04896837845444679,
      0.04993663728237152,
      0.007089636288583279,
      0.034021880477666855,
      -0.030726877972483635,
      0.0051451087929308414,
      -0.016775665804743767,
      -0.051550157368183136,
      0.02525852620601654,
      0.05462038144469261,
      -0.0013326577609404922,
      -0.014537878334522247,
      -0.017744410783052444,
      -0.03294552490115166,
      0.020163077861070633,
      0.10615906864404678,
      -0.037218526005744934,
      0.005907257087528706,
      -0.05379126965999603,
      -0.03504429757595062,
      0.05457191914319992,
      0.05142000690102577,
      0.0874113142490387,
      0.055093374103307724,
      0.006264659576117992,
      0.0305421631783247,
      -0.08576247841119766,
      -0.027495786547660828,
      -0.08569266647100449,
      -0.02685036137700081,
      -0.04452890530228615,
      0.0033938002306967974,
      0.00962759181857109,
      0.01307124923914671,
      -0.009467639960348606,
      0.01081796269863844,
      -0.015381882898509502,
      0.08146867901086807,
      0.04261288046836853,
      -0.07033747434616089,
      0.0525333471596241,
      -0.02450188249349594,
      0.019158311188220978,
      0.05464520677924156,
      -0.010668618604540825,
      0.032350167632102966,
      0.02168397232890129,
      0.0377710722386837,
      -0.020871393382549286,
      -0.03337724506855011,
      0.024130268022418022,
      0.027719683945178986,
      0.04869475215673447,
      0.010659539140760899,
      0.0036809584125876427,
      0.001292937551625073,
      0.010253135114908218,
      0.07923313975334167,
      0.014776910655200481,
      0.026130059733986855,
      -0.004966649692505598,
      0.039109840989112854,
      -0.005514950957149267,
      -0.054930008947849274,
      -0.022050078958272934,
      -0.0324847511947155,
      -0.03496219217777252,
      -0.07737789303064346,
      0.01677747443318367,
      0.0025351971853524446,
      0.0012842286378145218,
      0.02581760101020336,
      -0.03482270613312721,
      -0.03155044466257095,
      0.07643351703882217,
      -0.040400635451078415,
      0.055585525929927826,
      -0.03415916860103607,
      -0.058393221348524094,
      0.02765190787613392,
      0.030733969062566757,
      -0.030940569937229156,
      -0.000659560551866889,
      -0.002114431234076619,
      0.0515030138194561,
      -0.05494508892297745,
      -0.051856234669685364,
      0.09148898720741272,
      -0.040631331503391266,
      -0.04285664111375809
    ],
    [
      0.008681404404342175,
      0.037597913295030594,
      -0.08762356638908386,
      -0.02400938794016838,
      -0.024781061336398125,
      -0.04769890382885933,
      0.029575422406196594,
      0.05686658248305321,
      -0.002061642939224839,
      0.07923222333192825,
      -0.06784568727016449,
      -0.01861530728638172,
      0.032210975885391235,
      -0.03078041970729828,
      0.01686687394976616,
      0.06463908404111862,
      0.028444113209843636,
      -0.03157225623726845,
      0.08325044810771942,
      -0.051699258387088776,
      -0.01627958193421364,
      -0.07123393565416336,
      -0.06993573158979416,
      0.04888594523072243,
      -0.09683717787265778,
      -0.03910895437002182,
      -0.025457441806793213,
      0.007903982885181904,
      0.04041538015007973,
      0.020983656868338585,
      -0.04805828630924225,
      -0.060122065246105194,
      -0.07025324553251266,
      -0.08539046347141266,
      0.052828796207904816,
      0.018761981278657913,
      0.01499991212040186,
      0.04131322726607323,
      0.00809663999825716,
      0.020690038800239563,
      0.055997803807258606,
      0.005292050540447235,
      0.027589760720729828,
      0.036347731947898865,
      0.032185278832912445,
      0.06457486748695374,
      0.01186511479318142,
      0.02714962512254715,
      -0.009078376926481724,
      0.06947212666273117,
      0.04355873912572861,
      -0.08152623474597931,
      0.05896218493580818,
      -0.015028057619929314,
      -0.0071709188632667065,
      -0.023918984457850456,
      -0.03252227604389191,
      0.005591047927737236,
      -0.06077374890446663,
      0.023291831836104393,
      -0.05819423869252205,
      -0.019156288355588913,
      -0.06713640689849854,
      -0.03293730691075325,
      0.04112045466899872,
      -0.0605817511677742,
      -0.0661022961139679,
      -0.010326986201107502,
      -0.0004969347501173615,
      0.028532162308692932,
      -0.05153776705265045,
      -0.005075214430689812,
      0.06972508132457733,
      -0.14783988893032074,
      0.019021324813365936,
      0.03771788626909256,
      0.019773442298173904,
      0.024447374045848846,
      -0.06303343176841736,
      0.0563320517539978,
      -0.03283478692173958,
      0.005254652816802263,
      -0.07514417916536331,
      -0.03744236379861832,
      -0.06846318393945694,
      -0.02847249060869217,
      -0.07304093986749649,
      -0.022867709398269653,
      -0.03358703479170799,
      -0.06332271546125412,
      0.009694110602140427,
      0.04422251135110855,
      -0.053076982498168945,
      0.0090150972828269,
      0.0018232015427201986,
      -0.03801872208714485,
      0.0663270503282547,
      -0.008930819109082222,
      -0.06777866184711456,
      0.029528753831982613,
      0.045124441385269165,
      0.014489843510091305,
      -0.02082282118499279,
      -0.005665454547852278,
      -0.03391851484775543,
      -0.05373000353574753,
      0.001037346082739532,
      0.02627725340425968,
      -0.028805866837501526,
      0.04957977309823036,
      0.03948000818490982,
      -0.0482233390212059,
      -0.01491948589682579,
      0.01546140294522047,
      0.025005687028169632,
      -0.019214576110243797,
      -0.043688319623470306,
      0.037060532718896866,
      0.07462876290082932,
      -0.07032553851604462,
      -0.050222162157297134,
      -0.03941844776272774,
      -0.006431870628148317,
      0.016284974291920662,
      -0.03465929627418518,
      0.05103540048003197,
      -0.03762758523225784,
      -0.008823384530842304,
      0.11228010058403015,
      0.021035896614193916,
      0.04037654027342796,
      0.021175567060709,
      0.016841638833284378,
      -0.015211526304483414,
      0.005447537638247013,
      0.047114595770835876,
      -0.08488421887159348,
      0.03501681610941887,
      0.04312044009566307,
      0.04244181141257286,
      -0.03028036095201969,
      -0.028677241876721382,
      0.015640130266547203,
      0.08100020885467529,
      0.09718242287635803,
      -0.10379514843225479,
      -0.09658943861722946,
      0.06788486987352371,
      0.035046208649873734,
      0.03738193213939667,
      -0.027955064550042152,
      -0.01666182465851307,
      0.05564773455262184,
      0.050429124385118484,
      0.03953086584806442,
      -0.12130659073591232,
      -0.05213015899062157,
      0.022306041792035103,
      -0.016235435381531715,
      0.0024500901345163584,
      0.06021049991250038,
      -0.018192319199442863,
      0.02115386351943016,
      -0.00666561396792531,
      -0.06991001963615417,
      -0.02023306116461754,
      0.027717188000679016,
      -0.06366521865129471,
      -0.1154773086309433,
      0.014798292890191078,
      -0.0009043452446348965,
      -0.023802176117897034,
      -0.000641786667983979,
      0.0028026439249515533,
      0.010903135873377323,
      0.04021800309419632,
      -0.042136311531066895,
      0.054336052387952805,
      0.05465563386678696,
      0.04975612834095955,
      -0.10123150050640106,
      0.05925648286938667,
      0.03728624805808067,
      -0.02847941778600216,
      0.0503280870616436,
      -0.08521054685115814,
      -0.04526793956756592,
      -0.035514138638973236,
      -0.04422531649470329,
      0.04676566272974014,
      -0.03827226161956787,
      -0.09627065807580948,
      0.09990786761045456,
      0.02264639176428318,
      -0.0024330862797796726,
      0.015770290046930313,
      -0.017657099291682243,
      -0.043275557458400726,
      -0.10019463300704956,
      0.009234490804374218,
      0.056200601160526276,
      0.0891895443201065,
      -0.030181515961885452,
      0.01826898194849491,
      0.015259923413395882,
      0.0403808057308197,
      0.0162160936743021,
      0.01796034537255764,
      -0.0028231325559318066,
      -0.08964858204126358,
      0.04328367859125137,
      -0.015240686014294624,
      0.09180290997028351,
      0.0012201250065118074,
      0.009168311022222042,
      -0.03152518719434738,
      0.08438080549240112,
      0.00523119093850255,
      0.033835191279649734,
      -0.0309592355042696,
      -0.056805968284606934,
      -0.08065282553434372,
      0.034994009882211685,
      0.04159199818968773,
      -0.026870349422097206,
      0.010546878911554813,
      -0.009359519928693771,
      0.024699196219444275,
      -0.03377469629049301,
      -0.04644405096769333,
      0.04985915496945381,
      0.05113905295729637,
      -0.04981323331594467,
      -0.06331075727939606,
      -0.10554727166891098,
      0.044283170253038406,
      -0.03982013091444969,
      0.009612360037863255,
      0.0006982946651987731,
      -0.017514409497380257,
      -0.02512188069522381,
      0.012742959894239902,
      -0.05022374168038368,
      -0.006465475540608168,
      -0.02911623939871788,
      -0.06664758920669556,
      0.012269383296370506,
      0.03838906064629555,
      -0.00041255875839851797,
      0.011843034066259861,
      0.008417725563049316,
      -0.010523139499127865,
      0.07156722247600555,
      -0.01759885810315609,
      -0.04218728467822075,
      0.006428772117942572,
      0.011356019414961338,
      -0.004448327701538801,
      -0.004379592835903168,
      0.023825762793421745,
      0.03466973453760147,
      0.014668730087578297,
      0.02952328510582447,
      0.022667627781629562,
      0.002763961208984256,
      0.020551666617393494,
      0.03937526047229767,
      0.07369120419025421,
      -0.03293658047914505,
      -0.06973856687545776,
      -0.010853582061827183,
      -0.016591932624578476,
      -0.039810262620449066,
      -0.004740213975310326,
      0.11934298276901245,
      0.01103382371366024,
      -0.007050162646919489,
      0.0540873259305954,
      0.017676742747426033,
      0.08684241771697998,
      0.0352066233754158,
      0.02608492784202099,
      0.09036961197853088,
      -0.03144778683781624,
      -0.06313430517911911,
      0.040112439543008804,
      0.005864052101969719,
      -0.00033201067708432674,
      0.0314936637878418,
      0.031272485852241516,
      0.016696564853191376,
      0.04738856479525566,
      -0.11285815387964249,
      -0.01592881977558136,
      -0.016332846134901047,
      -0.01041930541396141,
      -0.09232208132743835,
      -0.01538889855146408,
      -0.011634847149252892,
      -0.01946282386779785,
      0.07290330529212952,
      0.10092806071043015,
      -0.032202426344156265,
      -0.027786483988165855,
      -0.11865179985761642,
      -0.0401034839451313,
      0.033332135528326035,
      0.028767310082912445,
      0.03365335240960121,
      0.044143542647361755,
      -0.05995819717645645,
      -0.002205012599006295,
      -0.05862131714820862,
      -0.11133269220590591,
      -0.0998481959104538,
      -0.04247184842824936,
      -0.0699022188782692,
      0.05696728825569153,
      0.026729727163910866,
      0.049412406980991364,
      -0.02308753877878189,
      0.06853093951940536,
      -0.014084340073168278,
      0.06691966950893402,
      0.05725930258631706,
      -0.04129567742347717,
      -0.039501842111349106,
      -0.049102138727903366,
      0.08998773992061615,
      0.005972809158265591,
      -0.07571820914745331,
      -0.033250804990530014,
      -0.057571977376937866,
      0.028362296521663666,
      0.0882912427186966,
      0.017108887434005737,
      0.09481574594974518,
      -0.029218286275863647,
      0.09690335392951965,
      0.03544490039348602,
      0.00646093999966979,
      -0.04972630366683006,
      0.0193729717284441,
      0.032603129744529724,
      0.030323894694447517,
      0.055368710309267044,
      -0.04122486338019371,
      0.05566035583615303,
      -4.278262713341974e-05,
      0.06687220185995102,
      0.03254156559705734,
      -0.027951231226325035,
      -0.005047291982918978,
      -0.042341262102127075,
      0.04136474058032036,
      -0.0076258257031440735,
      0.05613131448626518,
      0.040756773203611374,
      0.05922693759202957,
      -0.001651823171414435,
      0.06145033985376358,
      -0.05886675417423248,
      -0.06252075731754303,
      -0.05480331927537918,
      -0.017659146338701248,
      -0.004155580420047045,
      -0.03611947223544121,
      0.07160326838493347,
      0.0018052399391308427,
      -0.0028414542321115732,
      -0.017073696479201317,
      0.017899949103593826,
      0.059957753866910934,
      0.013545737601816654,
      -0.06406956911087036,
      0.015558640472590923,
      0.09645498543977737,
      -0.022832754999399185,
      0.02465047314763069,
      0.0708276703953743,
      0.0017418548231944442,
      -0.0329839326441288,
      -0.0197934377938509,
      0.027205023914575577,
      0.06406766921281815,
      -0.012935505248606205,
      -0.020353596657514572,
      0.00288605410605669,
      0.011606178246438503,
      -0.005973857827484608,
      -0.019858386367559433,
      0.05710301175713539,
      -0.009641665033996105,
      -0.0028079405892640352,
      -0.01691284030675888,
      0.007094089407473803,
      0.0767916813492775,
      -0.004512398969382048,
      0.04131358116865158,
      -0.049554016441106796,
      0.00509332912042737,
      -0.08944167196750641,
      -0.039050422608852386,
      0.012471641413867474,
      -0.039814550429582596,
      -0.042403947561979294,
      -0.051752954721450806,
      0.06866342574357986,
      0.02120915614068508,
      -0.06086624786257744,
      -0.05460933968424797,
      0.03563348576426506,
      0.036854639649391174,
      -0.0019208407029509544,
      -0.0010391221148893237,
      -0.054340098053216934,
      -0.04693101719021797,
      -0.02968994714319706,
      -0.005032933782786131,
      -0.0345233678817749,
      -0.03407915309071541,
      -0.008714384399354458,
      0.007744758855551481,
      -0.03280750662088394,
      -0.00522153964266181,
      -0.0433465950191021,
      0.05196418613195419,
      0.007522185333073139,
      0.02990029565989971,
      0.11638214439153671,
      -0.008919687941670418,
      -0.07123194634914398,
      -0.0427665077149868,
      -0.019373584538698196,
      -0.0489395447075367,
      -0.0978071317076683,
      -0.030561840161681175,
      -0.011034796945750713,
      0.00044341181637719274,
      -0.01662273146212101,
      -0.021936312317848206,
      -0.05644969642162323,
      -0.01953631453216076,
      0.08379790931940079,
      0.015499139204621315,
      -0.07658955454826355,
      -0.09765031933784485,
      0.010997187346220016,
      -0.0075650583021342754,
      -0.047076333314180374,
      -0.10164140909910202,
      -0.010750990360975266,
      -0.04981411620974541,
      0.11130478233098984,
      -0.006155557930469513,
      -0.02028614655137062,
      0.00697563961148262,
      0.10984153300523758,
      0.12184377759695053,
      -0.05032523721456528,
      0.10273245722055435,
      -0.009039797820150852,
      0.0314050130546093,
      0.04862118139863014,
      -0.07297243177890778,
      -0.024228254333138466,
      -0.016153300181031227,
      -0.017463508993387222,
      0.07102520763874054,
      -0.06432711333036423,
      -0.008597355335950851,
      -0.0292675718665123,
      -0.018024476245045662,
      -0.031639326363801956,
      0.017136631533503532,
      0.014592261984944344,
      -0.054469868540763855,
      0.03843434154987335,
      0.011952527798712254,
      -0.06275056302547455,
      0.03477199003100395,
      0.012544872239232063,
      0.044638730585575104,
      -0.08350224047899246,
      0.0005268777604214847,
      0.07337819784879684,
      0.04294999688863754,
      0.06988341361284256,
      0.015602970495820045,
      -0.020953161641955376,
      -0.05390869081020355,
      -0.010740797035396099,
      0.002968144603073597,
      -0.07774929702281952,
      0.029492584988474846,
      -0.015819571912288666,
      0.04429265856742859,
      0.01838267408311367,
      0.01670847274363041,
      -0.03554553911089897,
      -0.04042162001132965,
      -0.040550995618104935,
      -0.16042560338974
    ],
    [
      0.020047012716531754,
      0.026016397401690483,
      0.015509843826293945,
      0.0014380592620000243,
      0.058849532157182693,
      -0.005704880692064762,
      -0.03644423931837082,
      -0.03215450793504715,
      0.02856612578034401,
      0.05557992309331894,
      -0.041021306067705154,
      -0.0007796544232405722,
      -0.027999766170978546,
      -0.07160264253616333,
      0.01151231862604618,
      -0.024090588092803955,
      0.008026158437132835,
      -0.026135893538594246,
      0.03554142266511917,
      0.04940419644117355,
      0.033020589500665665,
      -0.06571177393198013,
      -0.05452533811330795,
      -0.05919889360666275,
      -0.11371219158172607,
      0.00034508720273151994,
      0.006502690725028515,
      -0.0002886630827561021,
      -0.025789890438318253,
      0.009424487128853798,
      0.05845935642719269,
      -0.034870170056819916,
      -0.05529286339879036,
      -0.1122346892952919,
      0.011039846576750278,
      -0.0025985115207731724,
      0.02544645592570305,
      -0.013259765692055225,
      0.01356431283056736,
      -0.0345974862575531,
      0.013065345585346222,
      -0.00841339398175478,
      0.09091604501008987,
      0.03791483864188194,
      -0.05883170664310455,
      -0.004283582791686058,
      0.015029111877083778,
      -0.037313755601644516,
      -0.050950706005096436,
      0.005639520939439535,
      0.021084565669298172,
      0.006934482604265213,
      0.03391927480697632,
      0.029117073863744736,
      -0.00290675344876945,
      0.07399548590183258,
      0.03060535341501236,
      -0.018841378390789032,
      -0.030203968286514282,
      0.060380201786756516,
      -0.010632004588842392,
      -0.017241286113858223,
      0.0286428090184927,
      0.006006818264722824,
      0.052630744874477386,
      0.005652803927659988,
      -0.028669076040387154,
      0.02251356467604637,
      -0.06567633897066116,
      -0.02685333788394928,
      -0.023817116394639015,
      0.06309718638658524,
      0.0021418596152216196,
      -0.0301672350615263,
      -0.026218492537736893,
      0.12673109769821167,
      0.04196956381201744,
      -0.027419747784733772,
      -0.02549244649708271,
      0.000597928767092526,
      0.011655857786536217,
      -0.00991799682378769,
      0.036872874945402145,
      0.06010088324546814,
      0.046542346477508545,
      0.055244795978069305,
      0.0074427779763937,
      -0.036789923906326294,
      0.004079247824847698,
      -0.02959790639579296,
      -0.034288112074136734,
      0.02555868774652481,
      0.07128036767244339,
      0.027497289702296257,
      -0.006605657748878002,
      0.04724512994289398,
      -0.04411246255040169,
      0.012655875645577908,
      -0.0577242486178875,
      -0.02091808430850506,
      -0.0163873340934515,
      0.057273223996162415,
      -0.02367706596851349,
      0.07902250438928604,
      -0.03498982638120651,
      0.01364847645163536,
      0.03511575236916542,
      0.008913260884582996,
      -0.08351827412843704,
      -0.02925432100892067,
      -0.020090775564312935,
      -0.06598933041095734,
      0.04378126189112663,
      0.06317659467458725,
      -0.05664917826652527,
      -0.08589315414428711,
      -0.019498128443956375,
      -0.13231192529201508,
      0.04232129827141762,
      0.0082352040335536,
      0.08726853877305984,
      0.07458391785621643,
      -0.030446438118815422,
      0.04437385872006416,
      0.016504833474755287,
      0.02659342624247074,
      -0.08662248402833939,
      -0.0217828880995512,
      -0.1251414567232132,
      -0.07049944996833801,
      -0.01462593674659729,
      0.10271620005369186,
      -0.08750375360250473,
      -0.0004387758090160787,
      -0.0355946384370327,
      0.008790765888988972,
      0.003490230068564415,
      -0.02844185009598732,
      -0.10396468639373779,
      0.040821623057127,
      -0.09026326984167099,
      0.022110087797045708,
      -0.005805410444736481,
      -0.03145812079310417,
      0.011167516000568867,
      -0.009324174374341965,
      0.06469427794218063,
      -0.018902018666267395,
      -0.007205173838883638,
      -0.02278245985507965,
      -0.06491802632808685,
      -0.02634403109550476,
      0.04539626091718674,
      -0.027117088437080383,
      -0.00663064606487751,
      0.00854748860001564,
      -0.03337224945425987,
      0.014415725134313107,
      -0.01224432047456503,
      0.024193838238716125,
      -0.0451555997133255,
      0.04451644793152809,
      0.006447268649935722,
      0.05723893642425537,
      -0.03012028895318508,
      -0.01903429441154003,
      0.0149221895262599,
      0.003394021186977625,
      -0.05000881105661392,
      0.006690178066492081,
      0.03021654114127159,
      0.007658889051526785,
      0.07209257036447525,
      -0.08276277035474777,
      -0.0291533712297678,
      -0.02755463868379593,
      -0.021256286650896072,
      0.027261968702077866,
      -0.02243148535490036,
      -0.06031288951635361,
      -0.05572225898504257,
      0.030284129083156586,
      -0.028669122606515884,
      0.0037832683883607388,
      0.02930149808526039,
      0.03293190896511078,
      0.017910242080688477,
      -0.03045652061700821,
      0.027690347284078598,
      0.058670442551374435,
      0.04665663093328476,
      0.060801610350608826,
      0.0365288183093071,
      -0.015700941905379295,
      -0.005538269877433777,
      -0.0005765381501987576,
      0.011711571365594864,
      0.057496875524520874,
      -0.02500072494149208,
      0.018728118389844894,
      -5.373867679736577e-05,
      0.025088492780923843,
      -0.0012015035608783364,
      -0.008711112663149834,
      -0.00018020375864580274,
      0.05861107259988785,
      0.017125669866800308,
      -0.08910149335861206,
      0.0747411698102951,
      0.06187132000923157,
      0.04969099164009094,
      0.006506927777081728,
      0.06740684807300568,
      0.02969539165496826,
      -0.02317737229168415,
      -0.01438185852020979,
      -0.001570842694491148,
      0.014179574325680733,
      -0.08909691870212555,
      0.06873884797096252,
      -0.009198510088026524,
      -0.0007042645593173802,
      0.05384787172079086,
      -0.028187517076730728,
      0.062447741627693176,
      -0.00392669765278697,
      0.013464218005537987,
      -0.010608992539346218,
      0.04504871740937233,
      -0.017097631469368935,
      -0.03068901039659977,
      0.005245706997811794,
      0.09773856401443481,
      -0.0023809652775526047,
      -0.030309660360217094,
      -0.045070379972457886,
      0.08636678755283356,
      -0.06716782599687576,
      -0.07203876972198486,
      -0.03686828166246414,
      -0.0007455825689248741,
      -0.04059523716568947,
      -0.025692492723464966,
      -0.031141115352511406,
      -0.0030342640820890665,
      -0.0869666337966919,
      0.04861283302307129,
      0.04832509160041809,
      -0.03574805706739426,
      0.022055311128497124,
      -0.04544674977660179,
      -0.005374846048653126,
      0.027408692985773087,
      -0.013574715703725815,
      -0.015002045780420303,
      -0.07054842263460159,
      -0.050940725952386856,
      0.001988813513889909,
      0.060481537133455276,
      -0.013775193132460117,
      0.0013487552059814334,
      0.0038564642891287804,
      -0.02812892198562622,
      -0.031063033267855644,
      0.030112305656075478,
      0.07500696927309036,
      -0.03641341254115105,
      0.021852193400263786,
      -0.017892450094223022,
      0.07164400070905685,
      0.051392585039138794,
      0.04433926194906235,
      0.03445708751678467,
      -0.02384847216308117,
      0.0015344790881499648,
      0.016864601522684097,
      0.0036091285292059183,
      -0.03900299593806267,
      -0.02537321113049984,
      -0.007366106379777193,
      -0.07520013302564621,
      0.034029364585876465,
      0.007948518730700016,
      0.09638182073831558,
      0.027787387371063232,
      -0.02916707657277584,
      0.04907376691699028,
      0.011201096698641777,
      -0.03794994205236435,
      0.06290662288665771,
      0.0004188138118479401,
      0.025488359853625298,
      0.020749472081661224,
      -0.029857978224754333,
      -0.021874219179153442,
      0.03138701617717743,
      -0.0501144602894783,
      0.0445670485496521,
      0.06387218832969666,
      0.03507210314273834,
      -0.057234037667512894,
      0.027075640857219696,
      0.006067538168281317,
      0.06661039590835571,
      -0.07379422336816788,
      -0.020716127008199692,
      0.03778177499771118,
      -0.017123306170105934,
      0.032524771988391876,
      -0.001668341807089746,
      -0.02609584666788578,
      -0.014551890082657337,
      -0.05993550643324852,
      0.003787476336583495,
      -0.0018691602163016796,
      0.023719806224107742,
      0.07179790735244751,
      -0.05716216564178467,
      0.0198699738830328,
      0.09073201566934586,
      0.08482677489519119,
      -0.024486878886818886,
      0.06238851323723793,
      -0.058244962245225906,
      0.06100195646286011,
      -0.04202182590961456,
      -0.01849277876317501,
      0.03798821195960045,
      0.09064704179763794,
      -0.025802096351981163,
      -0.08026646822690964,
      0.017346957698464394,
      -0.07566576451063156,
      -0.0049116164445877075,
      -0.03808743134140968,
      0.04217466339468956,
      0.005188441835343838,
      -0.038582220673561096,
      0.028635213151574135,
      -0.05244196206331253,
      0.07247786968946457,
      0.04185720160603523,
      0.04835795983672142,
      -0.005155578721314669,
      0.011252274736762047,
      0.0245172381401062,
      0.004996991250663996,
      0.054479844868183136,
      0.03719698265194893,
      -0.019665705040097237,
      0.04827776923775673,
      0.11066942662000656,
      0.04487508907914162,
      0.01111274491995573,
      -0.03924703225493431,
      -0.012655029073357582,
      0.05561261624097824,
      0.11101549863815308,
      0.046474188566207886,
      0.07158133387565613,
      -0.027282191440463066,
      0.03200098127126694,
      0.002230915939435363,
      -0.022616291418671608,
      -0.02766413800418377,
      -0.12193307280540466,
      -0.030407574027776718,
      0.011221637949347496,
      -0.07563022524118423,
      0.023548390716314316,
      0.044816263020038605,
      -0.04598222300410271,
      0.06346417218446732,
      0.024464266374707222,
      -0.034565769135951996,
      0.035001013427972794,
      0.05627034604549408,
      0.01634088158607483,
      -0.014147344045341015,
      0.047877077013254166,
      -0.06101541221141815,
      -0.0501197874546051,
      0.011619947850704193,
      -0.13256733119487762,
      -0.07005491107702255,
      0.0037639187648892403,
      -0.02781658060848713,
      0.017948372289538383,
      -0.0014432320604100823,
      -0.004457648377865553,
      0.08776061236858368,
      -0.04022372141480446,
      0.03700079023838043,
      -0.013367190025746822,
      -0.05690766125917435,
      -0.01417512446641922,
      0.003992342855781317,
      -0.051709890365600586,
      -0.04306083917617798,
      0.03398182988166809,
      -0.008379833772778511,
      -0.1071920245885849,
      0.01319610420614481,
      0.014142321422696114,
      -0.03511067479848862,
      0.11925503611564636,
      -0.03771797567605972,
      0.04111121594905853,
      0.04031176492571831,
      -0.05260865390300751,
      -0.03902857005596161,
      0.014529282227158546,
      -0.0759306401014328,
      -0.09810331463813782,
      0.06728218495845795,
      0.05261065810918808,
      -0.02173357829451561,
      0.013325966894626617,
      -0.0066505069844424725,
      -0.061893388628959656,
      -0.0580037385225296,
      -0.010199706070125103,
      0.006301945075392723,
      0.012532534077763557,
      -0.040479689836502075,
      0.01120662596076727,
      -0.0433940514922142,
      -0.046209752559661865,
      0.047380901873111725,
      0.04348316043615341,
      0.033113833516836166,
      -0.06692112982273102,
      0.02553124539554119,
      -0.048016272485256195,
      0.03884977847337723,
      0.07063311338424683,
      0.07546255737543106,
      0.004775078501552343,
      0.027152633294463158,
      0.0126957343891263,
      -0.035887688398361206,
      -0.025736479088664055,
      0.022842135280370712,
      0.05600050836801529,
      0.07341275364160538,
      0.03348015621304512,
      -0.0059515791945159435,
      -0.007237073499709368,
      -0.054559431970119476,
      -0.07734300196170807,
      -0.007509785704314709,
      -0.04438154399394989,
      -0.03246672824025154,
      0.0405619740486145,
      -0.07218167930841446,
      -0.06084231659770012,
      -0.06669314205646515,
      0.010515071451663971,
      0.004735378548502922,
      -0.05121876299381256,
      0.08478502929210663,
      -0.0021867631003260612,
      0.03366865590214729,
      -0.026825319975614548,
      -0.09879282861948013,
      -0.006247226614505053,
      0.0350993312895298,
      -0.02455764077603817,
      -0.043882545083761215,
      -0.04501444473862648,
      -0.000664634513668716,
      0.011464285664260387,
      0.07084891945123672,
      0.04480953514575958,
      -0.005106434691697359,
      -0.011338046751916409,
      -0.04375762864947319,
      0.05398314446210861,
      0.05069214105606079,
      0.010318967513740063,
      -0.005318854935467243,
      0.008555969223380089,
      0.0271010622382164,
      0.03551649674773216,
      0.051090437918901443,
      -0.0036553628742694855,
      -0.06496486067771912,
      0.05670446157455444,
      0.002177436603233218,
      -0.006690697744488716,
      0.000933187548071146,
      -0.03998018056154251,
      -0.0003274498740211129,
      -0.01742125302553177,
      -0.03359087556600571,
      -0.01580636203289032,
      0.028634272515773773,
      0.03659392148256302,
      0.0605260469019413,
      -0.03466249629855156,
      0.04619193077087402,
      0.010035891085863113,
      -0.006579108536243439
    ],
    [
      0.08247189968824387,
      -0.09041216969490051,
      -0.01772422343492508,
      -0.029803313314914703,
      -0.007422975730150938,
      0.02258719690144062,
      -0.0014496073126792908,
      -0.08735784888267517,
      -0.06196502968668938,
      0.04275764524936676,
      0.031046267598867416,
      -0.04297647252678871,
      0.0061356984078884125,
      0.015483160503208637,
      0.0068812016397714615,
      0.0068139079958200455,
      -0.10490009933710098,
      0.008827818557620049,
      -0.01800002157688141,
      -0.05330590903759003,
      0.015703514218330383,
      -0.0803276002407074,
      0.0714883953332901,
      0.05821916460990906,
      -0.07155699282884598,
      -0.008683598600327969,
      -0.015847114846110344,
      0.00945898238569498,
      -0.06672126799821854,
      0.020702315494418144,
      0.003244782565161586,
      -0.04437604919075966,
      0.011664527468383312,
      -0.014627926982939243,
      0.009487939067184925,
      -0.051643360406160355,
      -0.04063411056995392,
      -0.02907651476562023,
      0.0013507013209164143,
      -0.035452354699373245,
      0.027110464870929718,
      -0.08602544665336609,
      -0.0070345401763916016,
      -0.034428615123033524,
      0.042587220668792725,
      -0.11722657084465027,
      0.06871351599693298,
      -0.05987323448061943,
      -0.08420471101999283,
      0.0005350518622435629,
      0.045482415705919266,
      -0.005417092237621546,
      -0.011927041225135326,
      -0.021524831652641296,
      0.02080482803285122,
      0.01457100361585617,
      -0.10804196447134018,
      0.03122480772435665,
      -0.004819197114557028,
      0.05171661451458931,
      0.11463236808776855,
      -0.011234399862587452,
      0.004012088757008314,
      -0.0760776549577713,
      0.03084576316177845,
      -0.029402034357190132,
      -0.04618663713335991,
      0.04330969601869583,
      -0.01213129423558712,
      -0.03782191127538681,
      0.05398590490221977,
      0.043372612446546555,
      -0.03139645233750343,
      0.02019369602203369,
      -0.018754953518509865,
      -0.005792026408016682,
      -0.0828389823436737,
      -0.10668729245662689,
      0.03410372510552406,
      0.04354400932788849,
      0.05203425511717796,
      -0.06621427834033966,
      0.0639195516705513,
      0.029852215200662613,
      0.008608108386397362,
      0.004242339637130499,
      -0.10659279674291611,
      -0.06789663434028625,
      0.03835809603333473,
      0.023429667577147484,
      0.0050076451152563095,
      -0.0562390573322773,
      -0.017259230837225914,
      0.007375945337116718,
      -0.11076146364212036,
      0.02406167797744274,
      0.027806740254163742,
      -0.10203642398118973,
      0.0203984584659338,
      0.014958575367927551,
      -0.010347609408199787,
      -0.0751403346657753,
      -0.02489105798304081,
      0.008361317217350006,
      0.06484227627515793,
      0.0301972646266222,
      -0.02305213175714016,
      0.013667681254446507,
      -0.03195927292108536,
      -0.031215088441967964,
      -0.052796557545661926,
      -0.0023980389814823866,
      0.06784079968929291,
      -0.06329957395792007,
      0.016005806624889374,
      -0.011533000506460667,
      -0.04271562770009041,
      -0.018114423379302025,
      -0.059951022267341614,
      0.0025250210892409086,
      0.04712163284420967,
      0.0026830672286450863,
      -0.0031126681715250015,
      0.0037550823763012886,
      0.011205928400158882,
      -0.07872926443815231,
      0.019506007432937622,
      -0.0039117466658353806,
      -0.02996663562953472,
      0.03362312912940979,
      -0.02900625765323639,
      0.012816110625863075,
      0.045436374843120575,
      0.03083561360836029,
      0.055634308606386185,
      -0.007248536683619022,
      0.0295279398560524,
      0.05136784911155701,
      0.013723716139793396,
      -0.03295065462589264,
      -0.07683661580085754,
      0.0857037901878357,
      -0.03582368046045303,
      0.08442726731300354,
      0.016314281150698662,
      0.007915250957012177,
      -0.06371258944272995,
      0.04792947694659233,
      -0.0515998899936676,
      -0.04709881171584129,
      0.04459168389439583,
      -0.013938389718532562,
      0.019161218777298927,
      -0.002649125875905156,
      0.03356447070837021,
      0.05613170191645622,
      0.028289346024394035,
      -0.01439232099801302,
      -0.007771356496959925,
      0.027607737109065056,
      -0.022611768916249275,
      0.02136172540485859,
      -0.05989997088909149,
      -0.005602866876870394,
      -0.058833297342061996,
      -0.0490766279399395,
      -0.0056370035745203495,
      -0.019566329196095467,
      0.07088012248277664,
      0.06232557073235512,
      -0.004944676533341408,
      -0.06203511729836464,
      -0.044965509325265884,
      -0.030636373907327652,
      0.030128365382552147,
      -0.037575613707304,
      -0.03373695909976959,
      0.09777545928955078,
      0.03599752113223076,
      0.025186913087964058,
      0.04837103560566902,
      0.0014330294216051698,
      -0.013670909218490124,
      -0.005024293437600136,
      0.07216183841228485,
      -0.05963745713233948,
      0.016418343409895897,
      0.03134991601109505,
      0.00464223837479949,
      0.004369959235191345,
      0.017887860536575317,
      -0.014435014687478542,
      0.007520920131355524,
      0.07433254271745682,
      -0.022565757855772972,
      0.0008972598589025438,
      0.010583213530480862,
      0.04530724883079529,
      0.040545959025621414,
      0.03164485841989517,
      0.057854678481817245,
      0.03565657511353493,
      -0.03070819564163685,
      0.016537077724933624,
      0.0350799635052681,
      -0.03087526001036167,
      -0.016994038596749306,
      0.040263064205646515,
      0.05588239058852196,
      -0.05480685457587242,
      -0.005749341566115618,
      0.0373506098985672,
      0.030151065438985825,
      0.057636793702840805,
      0.03552273288369179,
      -0.07940173149108887,
      -0.015601664781570435,
      -0.021551430225372314,
      -0.03085245192050934,
      -0.02124122530221939,
      0.016004865989089012,
      -0.060572028160095215,
      -0.04952625930309296,
      -0.0018433481454849243,
      -0.06712651997804642,
      0.013061107136309147,
      -0.00560169480741024,
      -0.017728189006447792,
      -0.008144520223140717,
      0.01577446609735489,
      0.01951885223388672,
      -0.0379742830991745,
      0.01030632946640253,
      0.03250646963715553,
      -0.038299378007650375,
      -0.02353525348007679,
      -0.060698479413986206,
      0.011101181618869305,
      0.004055280238389969,
      -0.0314418263733387,
      0.06095273047685623,
      0.002920278115198016,
      0.010363832116127014,
      0.057743702083826065,
      -0.04309350997209549,
      0.059574566781520844,
      -0.016703808680176735,
      0.00833191815763712,
      -0.00041551003232598305,
      -0.06296824663877487,
      0.05283043906092644,
      0.055824264883995056,
      0.03880225867033005,
      -0.027849914506077766,
      0.03947512060403824,
      0.005844261031597853,
      -0.032594747841358185,
      0.03722048178315163,
      0.06978946924209595,
      0.012838071212172508,
      -0.05463514104485512,
      0.0066230567172169685,
      0.05774816498160362,
      -0.07519949227571487,
      -0.05925606191158295,
      -0.02340148203074932,
      -0.0053393603302538395,
      -0.05126255750656128,
      0.0169636569917202,
      -0.026241792365908623,
      0.04783235862851143,
      -0.011127782985568047,
      0.005886630620807409,
      0.03922232985496521,
      -0.018699128180742264,
      -0.021813534200191498,
      0.06683965772390366,
      -0.02457394450902939,
      -0.009577477350831032,
      0.01650020107626915,
      -0.006429692730307579,
      -0.03218396380543709,
      -0.04016295447945595,
      0.08487749844789505,
      -0.027187736704945564,
      0.01777917519211769,
      0.009865162894129753,
      0.03481939062476158,
      0.003095443593338132,
      -0.014444462023675442,
      0.05377619341015816,
      -0.0033159232698380947,
      -0.009920960292220116,
      -0.031735096126794815,
      0.0043304311111569405,
      0.05627485737204552,
      -0.11150460690259933,
      0.01975848525762558,
      -0.018668944016098976,
      -0.012460310943424702,
      -0.04328453913331032,
      -0.04766480624675751,
      0.0006482648896053433,
      -0.027702266350388527,
      -0.06897587329149246,
      0.014003103598952293,
      0.06695430725812912,
      0.037705544382333755,
      -0.01820364035665989,
      0.010393282398581505,
      0.027358194813132286,
      0.012515264563262463,
      0.009330179542303085,
      0.09580444544553757,
      -0.06283074617385864,
      0.04741677641868591,
      -0.017388245090842247,
      0.013724388554692268,
      0.040336478501558304,
      0.038495443761348724,
      -0.07829025387763977,
      -0.03333601728081703,
      -0.08630730211734772,
      0.037897542119026184,
      -0.031132571399211884,
      -0.03976406902074814,
      0.0803995132446289,
      0.07696624100208282,
      0.08741085976362228,
      0.025952665135264397,
      -0.0445011667907238,
      0.04905133694410324,
      -0.0242636539041996,
      -0.0431307852268219,
      0.05414590239524841,
      -0.0074225906282663345,
      -0.026899099349975586,
      -0.0689292624592781,
      0.01592165045440197,
      -0.08164232224225998,
      0.04483990743756294,
      -0.03873630240559578,
      0.034561723470687866,
      0.10268115252256393,
      -0.02992856875061989,
      0.050831813365221024,
      0.08041340112686157,
      0.02978479489684105,
      -0.017880786210298538,
      -0.02181830070912838,
      0.016779065132141113,
      -0.00024820651742629707,
      0.0892692357301712,
      -0.020493758842349052,
      0.024549150839447975,
      0.01823550835251808,
      -0.0447210930287838,
      -0.001682050758972764,
      -0.006353436969220638,
      0.0070801894180476665,
      -0.008711868897080421,
      -0.06199781224131584,
      0.003790354821830988,
      0.022987758740782738,
      0.05339142680168152,
      -0.014969900250434875,
      -0.057966168969869614,
      0.07045292854309082,
      -0.046358559280633926,
      0.019121868535876274,
      -0.06452872604131699,
      -0.017990481108427048,
      -0.020490368828177452,
      0.02312774583697319,
      0.024207990616559982,
      0.0025737066753208637,
      -0.03196021914482117,
      -0.06520078331232071,
      -0.0020400797948241234,
      0.0007600912940688431,
      0.02850661613047123,
      -0.04562000930309296,
      -0.0682753324508667,
      0.013452007435262203,
      0.03791613131761551,
      0.023156609386205673,
      -0.04527530446648598,
      0.06706564873456955,
      0.06738012284040451,
      -0.024481773376464844,
      0.019416632130742073,
      0.02471264824271202,
      0.05166422575712204,
      0.020534425973892212,
      -0.04008680582046509,
      -0.059050630778074265,
      0.010746395215392113,
      0.1528427004814148,
      -0.018357979133725166,
      -0.027766112238168716,
      0.050532739609479904,
      0.037846628576517105,
      0.01879517175257206,
      0.02526938170194626,
      0.013618693687021732,
      -0.0548202246427536,
      0.004847615957260132,
      -0.051985401660203934,
      0.011686943471431732,
      0.012840053997933865,
      0.026615047827363014,
      0.1111912727355957,
      0.008356973528862,
      -0.02596057392656803,
      -0.0560380183160305,
      0.018689755350351334,
      0.02472584880888462,
      -0.01698550209403038,
      0.10313386470079422,
      0.024659305810928345,
      0.06745755672454834,
      0.03482735902070999,
      -0.0640752986073494,
      0.024788379669189453,
      -0.042668916285037994,
      0.013393398374319077,
      -0.0008469148306176066,
      -0.05962832272052765,
      0.03314792737364769,
      0.046230681240558624,
      -0.04977014660835266,
      0.02294985204935074,
      0.056604329496622086,
      -0.005946509540081024,
      0.030202368274331093,
      -0.0674375593662262,
      0.052143342792987823,
      -0.01624947413802147,
      0.005391726735979319,
      -0.024085823446512222,
      -0.05469624325633049,
      -0.04995162785053253,
      -0.039276085793972015,
      -0.053742915391922,
      0.028681892901659012,
      0.03115871176123619,
      -0.00259094568900764,
      -0.027950718998908997,
      -0.07585382461547852,
      -0.06566757708787918,
      0.023923374712467194,
      0.08564966917037964,
      -0.05493072792887688,
      -0.020162736997008324,
      0.023510627448558807,
      -0.018022000789642334,
      0.02001526579260826,
      0.024675758555531502,
      0.0663134977221489,
      -0.007699835114181042,
      -0.046238671988248825,
      -0.03878974914550781,
      0.021265819668769836,
      -0.0613224133849144,
      0.04944288730621338,
      -0.05801878124475479,
      -0.0333394780755043,
      0.07685872912406921,
      0.047523241490125656,
      0.0065198722295463085,
      0.0876927375793457,
      0.041945576667785645,
      -0.03708725795149803,
      0.09553683549165726,
      -0.0589660219848156,
      0.06083289906382561,
      -0.07877099514007568,
      -0.013454781845211983,
      0.06090610846877098,
      -0.008243934251368046,
      0.008489299565553665,
      -0.009821530431509018,
      -0.01820324920117855,
      -0.05786925181746483,
      0.01656590774655342,
      -0.023885169997811317,
      0.029039321467280388,
      0.07930507510900497,
      -0.02039925754070282,
      -0.014187577180564404,
      -0.06332569569349289,
      0.02903442271053791,
      -0.062181256711483,
      -0.07456008344888687,
      -0.04690421745181084,
      0.004929151386022568,
      0.002932649338617921,
      -0.0209179874509573,
      0.02742909826338291,
      0.04108023643493652,
      -0.01198718510568142,
      -0.03875456377863884,
      0.028534485027194023
    ],
    [
      -0.01749296858906746,
      -0.023278281092643738,
      -0.07894851267337799,
      -0.02582237310707569,
      0.02302291803061962,
      -0.07558740675449371,
      -0.04532824456691742,
      -0.003630173159763217,
      0.051730554550886154,
      0.006466739345341921,
      -0.02385748364031315,
      -0.03964827582240105,
      -0.035768140107393265,
      0.03190046548843384,
      -0.008779803290963173,
      0.016689199954271317,
      0.040988095104694366,
      0.08046237379312515,
      -0.053228385746479034,
      -0.04595395550131798,
      0.06175023689866066,
      -0.014736166223883629,
      0.01363997533917427,
      0.02111971564590931,
      -0.05097462609410286,
      -0.02309488132596016,
      -0.0928862914443016,
      0.07974284887313843,
      0.03738705441355705,
      0.015689680352807045,
      -0.0679926797747612,
      0.04231066256761551,
      0.07773993909358978,
      0.011675986461341381,
      -0.04035701975226402,
      -0.014018128626048565,
      0.021762538701295853,
      -0.041304074227809906,
      -0.010499664582312107,
      -0.10416551679372787,
      -0.03124282695353031,
      0.031173964962363243,
      -0.02139109931886196,
      -0.05985451117157936,
      -0.041113536804914474,
      0.00514048058539629,
      0.04267996549606323,
      -0.08182144165039062,
      -0.018095577135682106,
      -0.05128994956612587,
      -0.07089009135961533,
      -0.03745950013399124,
      0.0015755204949527979,
      -0.05357220023870468,
      0.06402496248483658,
      0.02249824069440365,
      0.03766261041164398,
      -0.006220202427357435,
      -0.06858526170253754,
      0.03136340156197548,
      0.029459217563271523,
      0.03413029760122299,
      0.010656153783202171,
      0.08436453342437744,
      -0.0035957428626716137,
      0.009352593682706356,
      -0.11039514094591141,
      -0.09732992202043533,
      0.07187699526548386,
      0.09912139922380447,
      0.010946639813482761,
      0.0014602439478039742,
      0.010598731227219105,
      0.011905176565051079,
      0.06683975458145142,
      0.014068549498915672,
      0.10232038795948029,
      -0.07078330218791962,
      -0.018523354083299637,
      0.015868837013840675,
      0.009979519061744213,
      0.044005561619997025,
      -0.03658461943268776,
      0.08968289196491241,
      0.031578630208969116,
      -0.0051675536669790745,
      -0.06753205507993698,
      0.02933681383728981,
      0.08248602598905563,
      -0.017584746703505516,
      0.02447609230875969,
      -0.0003136736049782485,
      -0.04511972889304161,
      0.07120563089847565,
      -0.0531923845410347,
      -0.07648640871047974,
      0.053841885179281235,
      0.05076698586344719,
      -0.02403556928038597,
      0.02506985329091549,
      -0.05059643089771271,
      -0.06365286558866501,
      -0.08306266367435455,
      0.001241350662894547,
      0.01919720694422722,
      0.05421874299645424,
      -0.005613763350993395,
      0.040187183767557144,
      0.08036061376333237,
      0.012498843483626842,
      -0.04802277684211731,
      0.14238514006137848,
      0.020769193768501282,
      -0.07878149300813675,
      -0.05223660171031952,
      -0.0010210424661636353,
      -0.011085697449743748,
      -0.04498901963233948,
      -0.009587431326508522,
      0.03487313911318779,
      -0.007464532274752855,
      0.03343483433127403,
      0.00422133132815361,
      0.024852078408002853,
      -0.057561006397008896,
      0.017009001225233078,
      0.003507231594994664,
      0.11472683399915695,
      -0.0697571411728859,
      0.009806348010897636,
      -0.00872638076543808,
      -0.022456353530287743,
      -0.01344776526093483,
      -0.09110045433044434,
      -0.01927730068564415,
      -0.0036122496239840984,
      0.06864042580127716,
      0.033360555768013,
      0.07320142537355423,
      -0.06720509380102158,
      0.06734033674001694,
      0.04311104863882065,
      0.009517693892121315,
      -0.009615383110940456,
      0.042069025337696075,
      -0.01357355434447527,
      -0.02966644987463951,
      0.009825355373322964,
      0.01583300717175007,
      0.0043957969173789024,
      -0.003792801406234503,
      -0.03439750894904137,
      0.056117210537195206,
      0.05562545359134674,
      0.02220071107149124,
      -0.0394061803817749,
      -0.010069265030324459,
      0.004087756387889385,
      -0.016329990699887276,
      -0.012251275591552258,
      -0.03772200271487236,
      0.027778875082731247,
      0.0054483553394675255,
      0.056931883096694946,
      -0.023154420778155327,
      0.033758316189050674,
      -0.005103777162730694,
      0.06638071686029434,
      -0.005811218172311783,
      -0.021392617374658585,
      -0.005350923165678978,
      -0.01754814013838768,
      -0.01035025343298912,
      0.09928872436285019,
      0.015425264835357666,
      -0.011476483196020126,
      -0.09495974332094193,
      -0.026481876149773598,
      0.0013437505112960935,
      -0.016016077250242233,
      0.03468892723321915,
      0.11091875284910202,
      -0.07396961003541946,
      -0.06567375361919403,
      0.0075782788917422295,
      0.008222756907343864,
      -0.025364352390170097,
      0.032406292855739594,
      0.055932432413101196,
      -0.039420440793037415,
      0.052465833723545074,
      -0.01011506374925375,
      0.02304806001484394,
      -0.039206814020872116,
      0.001745083020068705,
      -0.05052500218153,
      -0.057389289140701294,
      0.015468889847397804,
      -0.025454964488744736,
      -0.023893125355243683,
      -0.04455618932843208,
      -0.04340783506631851,
      0.06534376740455627,
      -0.005520885344594717,
      0.10322821885347366,
      -0.05649096146225929,
      0.06487996876239777,
      -0.005099355708807707,
      0.08772211521863937,
      0.031445108354091644,
      0.02472035214304924,
      -0.08217789977788925,
      0.06259306520223618,
      -0.017293637618422508,
      -0.0003845703904516995,
      0.04277610406279564,
      -0.07061515748500824,
      -0.015074348077178001,
      0.018711192533373833,
      0.014190693385899067,
      -0.014115582220256329,
      0.0071822344325482845,
      -0.05099950358271599,
      0.0022246658336371183,
      0.044140513986349106,
      -0.06485691666603088,
      -0.05436539277434349,
      0.07711195945739746,
      0.022244298830628395,
      0.023485366255044937,
      0.05877210944890976,
      -0.04379716515541077,
      0.0509895384311676,
      0.012684103101491928,
      0.02401958592236042,
      -0.014281763695180416,
      -0.015952495858073235,
      0.0039784545078873634,
      0.005946098826825619,
      -0.022591939195990562,
      -0.07593122124671936,
      -0.07362190634012222,
      -0.05356151610612869,
      -0.005682767368853092,
      -0.008000349625945091,
      0.02867875061929226,
      -0.0692765936255455,
      -0.04626132547855377,
      -0.00935363955795765,
      -0.02998519130051136,
      0.04456479102373123,
      0.01780085638165474,
      0.06593231111764908,
      0.07378557324409485,
      -0.026313593611121178,
      -0.019551480188965797,
      0.061787597835063934,
      0.04310522601008415,
      0.013301660306751728,
      -0.03969966247677803,
      -0.028576530516147614,
      -0.06209784001111984,
      -0.07434163242578506,
      -0.03028140589594841,
      -0.05160798877477646,
      -0.006506732665002346,
      -0.04919039085507393,
      -0.0415298156440258,
      0.01877378299832344,
      -0.0034573618322610855,
      0.0760832354426384,
      0.05631555989384651,
      0.04269301891326904,
      0.04000290483236313,
      0.03459499031305313,
      -0.015541949309408665,
      -0.04719843342900276,
      -0.0047461967915296555,
      -0.023267580196261406,
      0.013409409672021866,
      -0.02160133793950081,
      0.05954457074403763,
      -0.05949654430150986,
      0.036558348685503006,
      -0.05345801264047623,
      -0.020133018493652344,
      -0.043337639421224594,
      0.03521094471216202,
      -0.00247718533501029,
      -0.012831158936023712,
      0.04962412267923355,
      0.05306142196059227,
      -0.09907647222280502,
      -0.005588083993643522,
      -0.016958028078079224,
      0.02972688153386116,
      -0.029340710490942,
      -0.024794554337859154,
      0.057473231106996536,
      -0.010399583727121353,
      -0.022545285522937775,
      -0.005088783334940672,
      -0.04627704620361328,
      -0.02381482534110546,
      -0.05025840550661087,
      0.043506965041160583,
      0.038997091352939606,
      0.002255660481750965,
      -0.00506253819912672,
      -0.020469948649406433,
      -0.068487748503685,
      0.006952189840376377,
      0.012737046927213669,
      0.02518061362206936,
      -0.009253227151930332,
      -0.03460608422756195,
      0.041652582585811615,
      0.047177162021398544,
      0.026899825781583786,
      0.030606990680098534,
      0.052850931882858276,
      0.016942888498306274,
      0.07913453131914139,
      -0.013813264667987823,
      -0.07188915461301804,
      0.0477496013045311,
      0.004208886064589024,
      0.0408952496945858,
      0.14760026335716248,
      0.11539711058139801,
      -0.04186546429991722,
      0.03922111168503761,
      0.019289057701826096,
      0.042815446853637695,
      0.01344242226332426,
      -0.030662380158901215,
      -0.050660084933042526,
      0.06457895040512085,
      0.023625684902071953,
      -0.032264165580272675,
      0.047090623527765274,
      -0.04168194159865379,
      -0.11196229606866837,
      0.005777793005108833,
      -0.0035794666036963463,
      0.022615546360611916,
      0.029160145670175552,
      0.03634319081902504,
      -0.047880884259939194,
      -0.02433234639465809,
      0.004143864382058382,
      -0.056563135236501694,
      0.02064872533082962,
      0.06838418543338776,
      0.0033931718207895756,
      0.08383095264434814,
      -0.03490303456783295,
      0.05287634953856468,
      -0.012047435157001019,
      -0.045275650918483734,
      -0.007384635508060455,
      -0.08289142698049545,
      -0.03442695364356041,
      -0.00744241289794445,
      -0.002080952050164342,
      0.0610370971262455,
      0.032516203820705414,
      0.018981751054525375,
      0.029127946123480797,
      0.033231500536203384,
      -0.06992162019014359,
      -0.018040692433714867,
      0.020046545192599297,
      -0.020594792440533638,
      0.07570437341928482,
      -0.04219507426023483,
      0.04394853115081787,
      0.022189773619174957,
      0.016317399218678474,
      -0.018078740686178207,
      -0.03461595997214317,
      -0.0355672761797905,
      -0.08117090165615082,
      -0.05367753282189369,
      0.01654025912284851,
      0.0786614939570427,
      -0.016523495316505432,
      0.05072011426091194,
      -0.017991390079259872,
      0.015384852886199951,
      -0.0209585502743721,
      0.03948656842112541,
      0.028054291382431984,
      0.026919575408101082,
      0.014225544407963753,
      0.0025728463660925627,
      0.004422944970428944,
      0.0001974857586901635,
      -0.10750828683376312,
      0.0006148331449367106,
      -0.029386674985289574,
      -0.0998135581612587,
      -0.049707576632499695,
      0.0473308339715004,
      -0.0007444602088071406,
      0.07745665311813354,
      0.03956003859639168,
      0.005487807095050812,
      0.033629611134529114,
      0.0895019993185997,
      0.012669485993683338,
      0.02251940779387951,
      -0.054142676293849945,
      0.005698968190699816,
      0.020785005763173103,
      -0.06472481787204742,
      -0.05316312611103058,
      0.06296743452548981,
      0.022987237200140953,
      0.003120236098766327,
      -0.06484128534793854,
      -0.017199452966451645,
      -0.0032422069925814867,
      0.052131567150354385,
      0.017008792608976364,
      -0.030043503269553185,
      0.07510662823915482,
      0.08659388870000839,
      0.03946075588464737,
      -0.04818269982933998,
      0.05658569186925888,
      -0.11607062816619873,
      0.08879706263542175,
      0.021819721907377243,
      0.04980464652180672,
      -0.052759915590286255,
      0.044550877064466476,
      0.038926269859075546,
      -0.0002624642220325768,
      -0.008950455114245415,
      0.04077218845486641,
      -0.018629509955644608,
      -0.02547173947095871,
      0.004480067174881697,
      0.004441586323082447,
      -0.03504471108317375,
      -0.04313870891928673,
      -0.040832169353961945,
      0.029469089582562447,
      0.029858052730560303,
      -0.08015748858451843,
      -0.04054992273449898,
      0.030794741585850716,
      -0.008179344236850739,
      0.05030814930796623,
      -0.06976034492254257,
      -0.06028595566749573,
      0.0061437408439815044,
      0.0310576893389225,
      -0.04993234947323799,
      -0.04599503427743912,
      -0.0748729556798935,
      0.058686770498752594,
      -0.07654842734336853,
      0.008780376054346561,
      -0.009921398013830185,
      0.060379158705472946,
      -0.021195407956838608,
      0.03739422559738159,
      -0.04545395448803902,
      0.07105235010385513,
      0.013886233791708946,
      0.048669494688510895,
      0.08948678523302078,
      -0.034194689244031906,
      -0.02265824005007744,
      -0.012189548462629318,
      -0.056287843734025955,
      -0.024704143404960632,
      -0.030456531792879105,
      -0.0158760454505682,
      -0.02397938258945942,
      0.08528554439544678,
      0.016829874366521835,
      0.010174055583775043,
      -0.054621726274490356,
      0.08341427147388458,
      0.0022745465394109488,
      -0.016965758055448532,
      -0.007551450747996569,
      0.004713453818112612,
      -0.04792468622326851,
      0.04693582281470299,
      -0.051360730081796646,
      -0.007585278712213039,
      -0.0016389249358326197,
      -0.007464906200766563,
      0.005525317974388599,
      -0.02708324044942856,
      -0.06591489911079407,
      0.029966266825795174,
      -0.07169695943593979,
      -0.03417900949716568
    ],
    [
      -0.001199114485643804,
      -0.0501265823841095,
      0.025240473449230194,
      -0.014698795042932034,
      0.03457190468907356,
      0.05452200397849083,
      -0.03944670408964157,
      0.006726623512804508,
      -0.003138212254270911,
      0.06157546490430832,
      0.03131965175271034,
      0.01052422821521759,
      0.006549694109708071,
      0.029075318947434425,
      -0.0010453019058331847,
      0.04761980473995209,
      0.024356570094823837,
      0.030664930120110512,
      -0.013107054866850376,
      -0.04028508439660072,
      -0.04411252960562706,
      -0.03132922574877739,
      -0.008378768339753151,
      0.018327144905924797,
      -0.04803459718823433,
      -0.0037939150352030993,
      0.005945757031440735,
      -0.06001151725649834,
      0.040197018533945084,
      0.05704942345619202,
      -0.0011876760981976986,
      -0.012523762881755829,
      0.08117656409740448,
      -0.07109886407852173,
      -0.03687118738889694,
      -0.006412968039512634,
      -0.0354057177901268,
      0.05798087269067764,
      0.07239841669797897,
      -0.008898154832422733,
      0.07546308636665344,
      0.10347755998373032,
      -0.016340691596269608,
      0.024661775678396225,
      -0.02440521866083145,
      0.00243077939376235,
      0.008806560188531876,
      0.03670048341155052,
      0.04544481635093689,
      -0.05069057643413544,
      -0.09380782395601273,
      -0.062100958079099655,
      0.0747845396399498,
      0.053562358021736145,
      0.06478235870599747,
      0.02271510660648346,
      0.038826677948236465,
      -0.013561864383518696,
      -0.020144013687968254,
      0.01653425022959709,
      0.023547686636447906,
      -0.02906862460076809,
      0.0006444617174565792,
      0.050988245755434036,
      0.05753694474697113,
      -0.0006488632643595338,
      -0.006155237089842558,
      0.05367719382047653,
      -0.008805121295154095,
      -0.04775127395987511,
      0.04128574952483177,
      0.028667796403169632,
      -0.0467974990606308,
      0.007902258075773716,
      0.004949003923684359,
      0.027152596041560173,
      0.03458759933710098,
      0.05227915942668915,
      -0.023982644081115723,
      0.00802980363368988,
      -0.10731171816587448,
      0.052480548620224,
      -0.027520235627889633,
      0.03082725964486599,
      -0.00860711932182312,
      -0.00495910132303834,
      -0.02800341136753559,
      0.04590665176510811,
      -0.039483316242694855,
      0.01924382522702217,
      0.07443101704120636,
      -0.04648302495479584,
      -0.00996532291173935,
      0.03155212476849556,
      -0.03146113455295563,
      -0.029059916734695435,
      0.06820255517959595,
      -0.012297367677092552,
      0.016758045181632042,
      0.008093921467661858,
      0.032829876989126205,
      0.028182867914438248,
      -0.035005081444978714,
      -0.05905279144644737,
      -0.043180108070373535,
      -0.003152285935357213,
      -0.045210883021354675,
      0.004218607675284147,
      0.01506158895790577,
      0.07063674181699753,
      0.04192015156149864,
      0.05169524997472763,
      0.001209456822834909,
      -0.012597297318279743,
      -0.04899727553129196,
      -0.08514399081468582,
      -0.0021552902180701494,
      0.0032524799462407827,
      0.0051171318627893925,
      -0.004424769897013903,
      0.04985387250781059,
      -0.006265346892178059,
      -0.061045464128255844,
      0.03620416671037674,
      -0.04516707360744476,
      -0.010065291076898575,
      -0.03239472582936287,
      0.06633535027503967,
      -0.11329521238803864,
      -0.04471365734934807,
      -0.061331674456596375,
      -0.02620912902057171,
      0.0328480564057827,
      0.01468008104711771,
      -0.040326766669750214,
      0.020584797486662865,
      0.0035323144402354956,
      0.038784515112638474,
      0.04576614126563072,
      0.024598464369773865,
      -0.007989407517015934,
      -0.06747477501630783,
      -0.028213266283273697,
      0.07426484674215317,
      0.025052513927221298,
      -0.025715215131640434,
      0.04754676669836044,
      -0.027460413053631783,
      0.09926488995552063,
      -0.09497590363025665,
      -0.06578512489795685,
      0.05764084309339523,
      0.0338432639837265,
      -0.01592891849577427,
      0.05684828758239746,
      0.03803672268986702,
      0.0540311224758625,
      0.07151228934526443,
      0.04449328035116196,
      -0.07585566490888596,
      -0.054067760705947876,
      0.002071481430903077,
      0.07150766253471375,
      0.05550481751561165,
      0.004632440861314535,
      0.03966517373919487,
      -0.027658244594931602,
      0.03905801475048065,
      -0.018032997846603394,
      -0.06854158639907837,
      0.0897066742181778,
      -0.022863546386361122,
      0.01656934805214405,
      -0.015509531833231449,
      -0.02902187779545784,
      -0.018033459782600403,
      -0.040766481310129166,
      0.004037885926663876,
      0.108828604221344,
      -0.020480811595916748,
      -0.010295837186276913,
      0.04693879187107086,
      -0.028197363018989563,
      0.003909685183316469,
      0.012360312975943089,
      0.05476262792944908,
      -0.05115726217627525,
      -0.008939629420638084,
      0.06736194342374802,
      0.04934943467378616,
      0.0087913628667593,
      -0.009610655717551708,
      -0.06301552057266235,
      -0.027767213061451912,
      -0.023722173646092415,
      -0.02225392311811447,
      -0.00399546604603529,
      0.03192024305462837,
      -0.01237652450799942,
      -0.0019951527938246727,
      0.06806837767362595,
      -0.016627052798867226,
      -0.06051518768072128,
      -0.03925217315554619,
      0.0573575533926487,
      0.014446934685111046,
      0.010834087617695332,
      -0.0012757513904944062,
      -0.030858909711241722,
      -0.04148454964160919,
      -0.0045568379573524,
      0.03931955248117447,
      -0.014935865066945553,
      0.0049237157218158245,
      -0.002050141105428338,
      0.03276464715600014,
      0.04037977755069733,
      0.014036732725799084,
      -0.03997368738055229,
      0.07755792886018753,
      0.004154386464506388,
      -0.055890731513500214,
      -0.002662691753357649,
      -0.03331167995929718,
      -0.10475325584411621,
      0.09125877916812897,
      -0.04831746220588684,
      0.053589049726724625,
      0.009460708126425743,
      -0.06721069663763046,
      0.041372448205947876,
      -0.09909629076719284,
      0.061183180660009384,
      -0.012033364735543728,
      0.07977764308452606,
      -0.014565723016858101,
      -0.001594432396814227,
      -0.04103798419237137,
      -0.002252906793728471,
      0.029038947075605392,
      -0.03268641233444214,
      0.016443967819213867,
      -0.03462279215455055,
      0.03815273940563202,
      0.0014904094859957695,
      -0.07871773838996887,
      -0.03151974081993103,
      0.04260996729135513,
      0.006043103989213705,
      -0.10552116483449936,
      -0.03333102539181709,
      -0.014909276738762856,
      0.0859236866235733,
      0.05900976061820984,
      0.004935925360769033,
      -0.08606040477752686,
      -0.033709585666656494,
      0.06003941595554352,
      0.02833613194525242,
      -0.005144977942109108,
      -0.07048642635345459,
      -0.02374013140797615,
      0.018612803891301155,
      0.04737188667058945,
      -0.009368544444441795,
      0.051034264266490936,
      0.0019206508295610547,
      0.03533109277486801,
      0.031399402767419815,
      0.03765317052602768,
      0.07048037648200989,
      0.09254017472267151,
      0.026918092742562294,
      -0.03386988118290901,
      -0.031829237937927246,
      0.06823813915252686,
      -0.08457521349191666,
      0.03801291435956955,
      -0.026117118075489998,
      -0.06178043410181999,
      0.05135168507695198,
      0.038149718195199966,
      0.0031546102836728096,
      -0.10677070170640945,
      0.029590778052806854,
      -0.04704584926366806,
      -0.02835475280880928,
      0.030701326206326485,
      0.0028798477724194527,
      0.04200321063399315,
      0.011234707199037075,
      -0.05092667415738106,
      0.001204514061100781,
      0.10139912366867065,
      0.0032771562691777945,
      0.01270672120153904,
      -0.05847908928990364,
      0.007599864155054092,
      -0.020849144086241722,
      0.03885464742779732,
      0.013853993266820908,
      0.05608959123492241,
      -0.09940868616104126,
      0.04064732417464256,
      0.06091566011309624,
      0.0006249371799640357,
      0.06379298865795135,
      0.016165796667337418,
      -0.0010205639991909266,
      0.021709371358156204,
      -0.045884933322668076,
      0.007689605001360178,
      -0.0019017308950424194,
      0.06658312678337097,
      -0.06504478305578232,
      -0.07580909132957458,
      -0.026731695979833603,
      0.010619285516440868,
      -0.020117035135626793,
      0.0022810879163444042,
      -0.0575551800429821,
      0.022427497431635857,
      -0.15760555863380432,
      0.046107664704322815,
      -0.0833454355597496,
      -0.02927614003419876,
      -0.0014459677040576935,
      0.03112318366765976,
      0.05589429289102554,
      -0.01357224676758051,
      0.03833027556538582,
      -0.01220589317381382,
      -0.03435591980814934,
      0.042234234511852264,
      -0.09322208166122437,
      0.05519680306315422,
      -0.04460075870156288,
      0.026102107018232346,
      -0.031398992985486984,
      0.014736704528331757,
      0.08442004770040512,
      0.02837037295103073,
      -0.01807187870144844,
      -0.010825783014297485,
      -0.012858457863330841,
      0.0765816792845726,
      0.04352294281125069,
      0.024065295234322548,
      -0.09296421706676483,
      0.007546114269644022,
      0.008609994314610958,
      0.1284024715423584,
      -0.02720492333173752,
      0.002176869660615921,
      -0.06294295936822891,
      -0.016941789537668228,
      0.026912255212664604,
      0.0727170929312706,
      0.056580621749162674,
      -0.05816984176635742,
      -0.0641314759850502,
      0.043282099068164825,
      0.07305815070867538,
      0.012743980623781681,
      0.01705123856663704,
      -0.01119070965796709,
      -0.10386458039283752,
      0.04289562627673149,
      0.008297624997794628,
      0.024114200845360756,
      0.1043136864900589,
      -0.0057691484689712524,
      -0.025341171771287918,
      -0.055924125015735626,
      -0.011135975830256939,
      0.06275340914726257,
      0.03490181639790535,
      -0.07207782566547394,
      -0.005791323259472847,
      -0.0029140212573111057,
      -0.022426748648285866,
      -0.07467994093894958,
      -0.04501320794224739,
      -0.030120495706796646,
      0.0024055414833128452,
      -0.023280911147594452,
      -0.0006862588925287127,
      -0.05771724879741669,
      -0.022615792229771614,
      0.0018042103620246053,
      -0.039122696965932846,
      0.08770007640123367,
      0.04989100992679596,
      0.03367745876312256,
      0.036618009209632874,
      0.04563300311565399,
      0.08949895203113556,
      -0.06528384238481522,
      0.05638103932142258,
      0.019819865003228188,
      -0.006491771899163723,
      0.007954532280564308,
      0.015258463099598885,
      0.017410198226571083,
      -0.0345231257379055,
      -0.08246053755283356,
      0.010301179252564907,
      0.039518967270851135,
      0.017994077876210213,
      -0.021200023591518402,
      0.09940513223409653,
      -0.012890984304249287,
      -0.059517282992601395,
      -0.03817127272486687,
      -0.06026971712708473,
      0.03737406060099602,
      -0.0036989387590438128,
      0.021599603816866875,
      0.058031171560287476,
      -0.055707212537527084,
      -0.03150879591703415,
      -0.020168520510196686,
      0.0604502335190773,
      -0.02306007407605648,
      -0.034671228379011154,
      -0.06715025007724762,
      -0.037239402532577515,
      0.035916924476623535,
      -0.04165353626012802,
      -0.03567662835121155,
      -0.04573646932840347,
      -0.03335212916135788,
      0.028422275558114052,
      0.04000367969274521,
      0.05168703943490982,
      0.017723262310028076,
      -0.036665525287389755,
      -0.005206792615354061,
      -0.0443488284945488,
      0.017274480313062668,
      0.04925991967320442,
      -0.008432316593825817,
      0.0008332062279805541,
      -0.027493000030517578,
      -0.04662995785474777,
      -0.006426863372325897,
      0.03895355015993118,
      -0.007987532764673233,
      -0.05796385928988457,
      -0.038564253598451614,
      0.013669407926499844,
      0.0017084304708987474,
      0.009176199324429035,
      -0.004655780270695686,
      0.05187718942761421,
      -0.04626878723502159,
      0.05100462958216667,
      -0.024683812633156776,
      -0.009251199662685394,
      0.03753276541829109,
      -0.047046463936567307,
      -0.03127378597855568,
      0.0057697356678545475,
      0.005222533829510212,
      0.004201589152216911,
      -0.013238325715065002,
      0.06106365844607353,
      -0.013038401491940022,
      -0.06011892482638359,
      -0.04066197946667671,
      0.001050235005095601,
      0.04782567918300629,
      -0.006930110044777393,
      -0.03232228755950928,
      -0.04806046560406685,
      -0.040301963686943054,
      0.024984287098050117,
      0.11459937691688538,
      0.03477028012275696,
      0.030150100588798523,
      0.016663426533341408,
      0.007824335247278214,
      -0.01649528369307518,
      0.01336000207811594,
      0.019696800038218498,
      0.005956694949418306,
      -0.03176622837781906,
      -0.03463197499513626,
      0.021934907883405685,
      0.03794023022055626,
      0.050784043967723846,
      0.01858113519847393,
      -0.0852607786655426,
      -0.07680141180753708,
      -0.03615672513842583,
      -0.09395310282707214,
      -0.03966524451971054,
      0.0038132667541503906,
      0.04679188132286072,
      -0.09804907441139221,
      0.046185463666915894,
      0.06797400861978531,
      -0.012198029085993767
    ],
    [
      -0.0017399678472429514,
      -0.04843911528587341,
      0.018434569239616394,
      -0.038057729601860046,
      0.11017731577157974,
      -0.011432617902755737,
      -0.007056626956909895,
      -0.013852065429091454,
      -0.03935422748327255,
      0.07100708782672882,
      -0.0526030994951725,
      0.0139598548412323,
      -0.050738830119371414,
      0.0866343304514885,
      0.02606870047748089,
      0.01126501988619566,
      -0.06953960657119751,
      0.008788980543613434,
      0.03345750272274017,
      -0.012383713386952877,
      -0.058222852647304535,
      -0.01599453203380108,
      -0.016662510111927986,
      -0.014774959534406662,
      -0.0591137558221817,
      0.008236991241574287,
      0.07805898785591125,
      0.006788344122469425,
      -0.025240449234843254,
      0.023909233510494232,
      -0.01138488482683897,
      0.03791932389140129,
      -0.01810300163924694,
      0.0134432977065444,
      -0.01598639227449894,
      0.0008271525730378926,
      -0.02748800627887249,
      0.02711699903011322,
      0.027170022949576378,
      0.04014221206307411,
      0.024961035698652267,
      -0.015350112691521645,
      -0.04205324128270149,
      0.03317372500896454,
      0.015185799449682236,
      -0.02411152608692646,
      -0.046493470668792725,
      -0.06331858783960342,
      0.06983262300491333,
      0.026973703876137733,
      -0.010673441924154758,
      -0.045756448060274124,
      -0.05382810905575752,
      -0.03228616341948509,
      0.05361991748213768,
      0.016748161986470222,
      -0.029429957270622253,
      -0.004974487703293562,
      -0.0134836845099926,
      -0.022413745522499084,
      0.044867079704999924,
      0.07235972583293915,
      0.013714365661144257,
      0.06396014988422394,
      -0.06818210333585739,
      -0.027787912636995316,
      -0.026869960129261017,
      -0.005595201626420021,
      0.0009479286381974816,
      -0.0014383842935785651,
      0.01672457531094551,
      0.07944197207689285,
      -0.026405094191432,
      0.024478863924741745,
      -0.048520803451538086,
      -0.019741039723157883,
      -0.043504662811756134,
      -0.003874828340485692,
      -0.03487097844481468,
      0.024218644946813583,
      -0.03135020658373833,
      0.08549497276544571,
      0.06055578589439392,
      0.019545476883649826,
      -0.08268842846155167,
      -0.006231389939785004,
      0.07541894167661667,
      0.07990714907646179,
      0.07171091437339783,
      0.023578854277729988,
      0.0196587722748518,
      -0.08430619537830353,
      -0.0601583831012249,
      0.043010178953409195,
      -0.053305674344301224,
      0.05161067098379135,
      -0.03521820530295372,
      0.012699464336037636,
      -0.06563642621040344,
      -0.07759130746126175,
      0.007375694811344147,
      0.06841963529586792,
      0.01823926717042923,
      -0.02926108054816723,
      -0.003018265124410391,
      0.04146677628159523,
      0.07158222049474716,
      -0.10065514594316483,
      0.01225520484149456,
      0.08072362095117569,
      -0.008024921640753746,
      0.007256320212036371,
      0.025160932913422585,
      0.03383689373731613,
      0.04603642597794533,
      -0.0633954256772995,
      0.07556135952472687,
      -0.09257324039936066,
      0.05761733651161194,
      -0.00524579593911767,
      0.04160918667912483,
      -0.05993426591157913,
      -0.07118194550275803,
      -0.04843493923544884,
      -0.07000314444303513,
      -0.030206477269530296,
      -0.0017523578135296702,
      0.11097167432308197,
      0.030872581526637077,
      -0.03188478201627731,
      -0.03397874906659126,
      0.04848484322428703,
      -0.018572034314274788,
      -0.007742957212030888,
      -0.09217619895935059,
      0.03262003883719444,
      0.027450384572148323,
      -0.0278354249894619,
      0.006635135039687157,
      0.041484199464321136,
      -0.022979699075222015,
      -0.062369249761104584,
      0.037629835307598114,
      -0.03432820364832878,
      0.021514281630516052,
      0.02494378760457039,
      -0.013531395234167576,
      -0.0006534623680636287,
      0.027166225016117096,
      -0.005035688169300556,
      -0.03923607990145683,
      0.04199820011854172,
      0.03855135291814804,
      0.023103296756744385,
      0.013588707894086838,
      0.02862279675900936,
      -0.04205498471856117,
      0.04716704785823822,
      -0.052139680832624435,
      -0.0575246661901474,
      -0.011094769462943077,
      -0.018393341451883316,
      0.026538535952568054,
      0.045216478407382965,
      -0.06108318641781807,
      0.005041186232119799,
      0.05870344117283821,
      -0.059640564024448395,
      0.05921795964241028,
      -0.032634999603033066,
      0.01867998205125332,
      -0.03961866721510887,
      0.06883100420236588,
      0.10927287489175797,
      0.01911238394677639,
      -0.04194173589348793,
      0.02369355596601963,
      0.007090867962688208,
      0.0021008283365517855,
      0.05216788873076439,
      -0.004926915280520916,
      -0.04859980568289757,
      -0.016009606420993805,
      -0.027076110243797302,
      -0.004887176211923361,
      -0.001633079256862402,
      0.01733480952680111,
      0.08457756787538528,
      -0.006497658789157867,
      0.0032712602987885475,
      -0.09747444838285446,
      0.003189717186614871,
      0.05788607895374298,
      -0.004740171134471893,
      -0.06523460894823074,
      -0.015430543571710587,
      -0.02963181957602501,
      -0.05062345787882805,
      0.009827534668147564,
      0.014769199304282665,
      0.09108274430036545,
      0.027199512347579002,
      0.03765343874692917,
      -0.038343824446201324,
      -0.05464952066540718,
      -0.01422884315252304,
      -0.026057755574584007,
      0.034123990684747696,
      0.10365427285432816,
      -0.09830919653177261,
      0.04097370430827141,
      -0.05300847440958023,
      0.0018181632040068507,
      0.02743522822856903,
      0.014765428379178047,
      -0.04245733469724655,
      0.008110428228974342,
      0.01942135952413082,
      0.026511678472161293,
      0.023030491545796394,
      0.020824747160077095,
      0.024281075224280357,
      -0.023816203698515892,
      -0.087490014731884,
      0.009683595038950443,
      0.03677200525999069,
      0.01359349675476551,
      0.07216992974281311,
      0.03710241615772247,
      0.03604846075177193,
      0.112743079662323,
      0.003766875946894288,
      0.015589299611747265,
      0.04177653789520264,
      0.0889195129275322,
      -0.05750098079442978,
      0.019345806911587715,
      0.017010677605867386,
      0.05170923098921776,
      -0.01941724494099617,
      0.04096125811338425,
      -0.02064662240445614,
      -0.04878212511539459,
      -0.039530109614133835,
      -0.005061861593276262,
      -0.03280852362513542,
      0.05085063353180885,
      -0.059147074818611145,
      0.0010552157182246447,
      -0.12819965183734894,
      -0.03766026347875595,
      0.06672597676515579,
      -0.04242739826440811,
      0.025357335805892944,
      -0.05828368663787842,
      -0.052157849073410034,
      -0.03616371750831604,
      0.027193304151296616,
      0.036989253014326096,
      -0.031662486493587494,
      0.02395745739340782,
      0.02418503724038601,
      0.06416524201631546,
      0.15690878033638,
      -0.08114059269428253,
      -0.0638752207159996,
      0.09182409942150116,
      0.05249305069446564,
      -0.028630243614315987,
      0.07561593502759933,
      0.02584073692560196,
      0.012469731271266937,
      -0.023795656859874725,
      0.001665637013502419,
      0.03836115449666977,
      0.020661339163780212,
      -0.049161046743392944,
      0.07245787233114243,
      0.004553354345262051,
      -0.0016774175455793738,
      -0.06052052974700928,
      0.03766815736889839,
      0.007247482892125845,
      -0.004138575401157141,
      -0.006203848868608475,
      0.047430165112018585,
      -0.05305333063006401,
      0.008068717084825039,
      -0.02012648992240429,
      0.053486377000808716,
      -0.011958670802414417,
      0.04014478251338005,
      0.027244875207543373,
      -0.057097405195236206,
      -0.05867765471339226,
      0.074583038687706,
      0.036236006766557693,
      0.0005799630889669061,
      0.029367612674832344,
      0.02028958685696125,
      0.010162140242755413,
      0.013852314092218876,
      -0.08615589886903763,
      -0.030671175569295883,
      0.04657146707177162,
      0.06203707307577133,
      -0.09081299602985382,
      -0.0757516473531723,
      -0.053738128393888474,
      0.02927277237176895,
      -0.033040136098861694,
      -0.03984055668115616,
      0.0012611958663910627,
      -0.049261610954999924,
      -0.050394296646118164,
      -0.02620764635503292,
      0.055909279733896255,
      0.039336904883384705,
      0.02541070617735386,
      -0.013663862831890583,
      -0.05843103304505348,
      0.008928165771067142,
      -0.03300940617918968,
      -0.002507508499547839,
      -0.049666304141283035,
      0.0028895640280097723,
      0.030216166749596596,
      -0.05575309321284294,
      0.04304146021604538,
      -0.011174219660460949,
      -0.03711048141121864,
      -0.02331259846687317,
      0.017393406480550766,
      -0.001974190818145871,
      -0.030935632064938545,
      0.048147305846214294,
      0.05355161428451538,
      -0.003072860650718212,
      0.038025226444005966,
      0.02084263414144516,
      0.07447763532400131,
      0.02255423367023468,
      -0.04362451657652855,
      -0.07174105942249298,
      -0.03838462010025978,
      -0.0020581709686666727,
      0.008853800594806671,
      -0.06399431079626083,
      0.031216617673635483,
      -0.03604704886674881,
      -0.04640769213438034,
      0.019711745902895927,
      -0.07017310708761215,
      -0.05872791260480881,
      -0.049060773104429245,
      0.05497102811932564,
      -0.0022666105069220066,
      0.07985469698905945,
      0.08747251331806183,
      -0.010176906362175941,
      -0.012806675396859646,
      -0.0361558236181736,
      -0.08874119818210602,
      0.008352554403245449,
      -0.0257380623370409,
      -0.02425146847963333,
      -0.005700162146240473,
      -0.01017226092517376,
      0.030777746811509132,
      -0.09300264716148376,
      -0.030370313674211502,
      0.028012635186314583,
      0.03957074508070946,
      0.00032854932942427695,
      -0.05505427345633507,
      0.053065769374370575,
      0.08197274804115295,
      -0.060980383306741714,
      -0.0381588377058506,
      -0.034923870116472244,
      0.005480319261550903,
      0.026505857706069946,
      -0.05518436059355736,
      0.11389851570129395,
      -0.009800326079130173,
      -0.017951378598809242,
      -0.034387361258268356,
      -0.024462707340717316,
      -0.015095544047653675,
      -0.031135307624936104,
      -0.03227607160806656,
      0.03601767122745514,
      -0.005319511983543634,
      0.023336609825491905,
      0.006345640402287245,
      -0.049509234726428986,
      -0.08181995898485184,
      -0.06159036606550217,
      0.04621000587940216,
      -0.015558510087430477,
      -0.004548416472971439,
      -0.0739486888051033,
      -0.04495604708790779,
      -0.08676206320524216,
      0.01818259060382843,
      0.012897259555757046,
      0.05048041045665741,
      -0.017790116369724274,
      0.06407266110181808,
      0.08795008808374405,
      0.0364302322268486,
      0.013528366573154926,
      -0.015868928283452988,
      -0.03290322795510292,
      -0.007527615875005722,
      -0.06426515430212021,
      0.008218140341341496,
      -0.03259432688355446,
      -0.0724545270204544,
      -0.029259465634822845,
      0.02566874958574772,
      -0.1038208156824112,
      0.03723335266113281,
      -0.008519995957612991,
      0.0034521406050771475,
      -0.008558815345168114,
      -0.03989561274647713,
      -0.02231529727578163,
      -0.008439763449132442,
      -0.015544864349067211,
      0.010215004906058311,
      -0.059818487614393234,
      -0.012513488531112671,
      0.02927141636610031,
      -0.03181828185915947,
      0.08054975420236588,
      -0.05080483853816986,
      0.004527526441961527,
      0.04416956380009651,
      -0.05041175335645676,
      0.03781166672706604,
      -0.044298846274614334,
      0.006170932203531265,
      -0.04922375828027725,
      -0.09183337539434433,
      0.1774395853281021,
      0.03457817807793617,
      -0.01644696667790413,
      -0.04883702099323273,
      0.04043155536055565,
      0.009725640527904034,
      -0.05635889619588852,
      0.08497811108827591,
      -0.022893449291586876,
      0.015593143180012703,
      -0.02105780504643917,
      -0.03464598208665848,
      0.06913992017507553,
      0.02235657535493374,
      0.01905851624906063,
      0.0426851287484169,
      0.03204923868179321,
      0.019147858023643494,
      -0.0012679226929321885,
      -0.08799339830875397,
      0.013102428056299686,
      -0.03256784752011299,
      0.004979901481419802,
      0.0497807040810585,
      0.026063045486807823,
      0.04666973650455475,
      0.0024705403484404087,
      0.07014193385839462,
      0.052723005414009094,
      -0.0463680773973465,
      -0.009072480723261833,
      0.0010514167370274663,
      0.05116189271211624,
      0.044009238481521606,
      0.023654254153370857,
      -0.0024907393380999565,
      -0.00990794412791729,
      0.04273303970694542,
      0.07271859794855118,
      0.0438230074942112,
      -0.0697186291217804,
      0.016447383910417557,
      -0.08056727796792984,
      -0.012575670145452023,
      0.030873892828822136,
      0.022895146161317825,
      -0.02412470243871212,
      0.021189633756875992,
      0.024893343448638916,
      -0.06964193284511566,
      -0.024209970608353615,
      -0.04571108520030975,
      -0.05505537986755371,
      -0.034835316240787506,
      -0.008972208946943283,
      -0.017086036503314972,
      -0.009349369443953037,
      0.010155148804187775
    ],
    [
      -0.042393557727336884,
      0.09541629999876022,
      -0.04077950865030289,
      0.06809647381305695,
      0.029458368197083473,
      -0.09157051146030426,
      -0.029368968680500984,
      0.03415074944496155,
      0.02881201170384884,
      0.02770814299583435,
      0.04967257380485535,
      0.04169275611639023,
      -0.08847765624523163,
      0.040976766496896744,
      -0.09003003686666489,
      0.004004351794719696,
      -0.0037220933008939028,
      -0.006240276154130697,
      0.0001817968877730891,
      -0.06324291229248047,
      0.02245524525642395,
      -0.04220188409090042,
      0.022975075989961624,
      0.030559483915567398,
      -0.03404175117611885,
      0.06220770627260208,
      -0.04381180927157402,
      -0.023657238110899925,
      0.07954945415258408,
      0.036407969892024994,
      0.0072013139724731445,
      0.05510575324296951,
      0.027480462566018105,
      0.04780494049191475,
      0.02728232927620411,
      0.03956307843327522,
      -0.039293963462114334,
      0.02206762321293354,
      -0.08273287117481232,
      -0.04750114306807518,
      -0.0027247474063187838,
      -0.12778839468955994,
      -0.031226124614477158,
      -0.022759275510907173,
      -0.11648274958133698,
      0.01728043705224991,
      -0.12872715294361115,
      0.07864702492952347,
      0.03486865386366844,
      0.05005033686757088,
      0.033727798610925674,
      -0.06259560585021973,
      -0.07500298321247101,
      0.07864685356616974,
      0.01469773706048727,
      0.08519670367240906,
      -0.06925833225250244,
      0.015803612768650055,
      -8.176718256436288e-05,
      -0.044647905975580215,
      0.03445114567875862,
      -0.04675326496362686,
      0.007062617223709822,
      -0.021889667958021164,
      0.0033795421477407217,
      0.022835904732346535,
      0.017789898440241814,
      -0.017045672982931137,
      -0.02244025655090809,
      0.0198826864361763,
      0.02923409827053547,
      0.01102607510983944,
      0.013208333402872086,
      0.09069079160690308,
      -0.027609162032604218,
      -0.021491724997758865,
      0.01053544133901596,
      0.027932878583669662,
      -0.00916652288287878,
      -0.002669485518708825,
      -0.021767865866422653,
      -0.04601586237549782,
      -0.07533421367406845,
      -0.019887428730726242,
      -0.11561558395624161,
      -0.027806533500552177,
      -0.08893198519945145,
      -0.08771486580371857,
      0.04367945343255997,
      -0.017824985086917877,
      -0.06472542136907578,
      -0.032317228615283966,
      -0.032621826976537704,
      -0.03188081085681915,
      0.06824745237827301,
      -0.007504783570766449,
      0.013733581639826298,
      0.0205773264169693,
      -0.02187218703329563,
      -0.0021071757655590773,
      0.05511670559644699,
      -0.012853391468524933,
      -0.03724340349435806,
      -0.022143112495541573,
      0.03321579843759537,
      -0.0542716309428215,
      -0.03108157031238079,
      -0.061887893825769424,
      -0.07300996780395508,
      -0.037847116589546204,
      -0.024595040827989578,
      -0.06489668786525726,
      -0.027253421023488045,
      -0.09592044353485107,
      0.041211653500795364,
      0.03555208817124367,
      0.1308138519525528,
      -0.05277450382709503,
      0.007007410749793053,
      0.05515337362885475,
      0.00242960499599576,
      0.09666863083839417,
      -0.06155714392662048,
      0.06080678105354309,
      -0.006661057006567717,
      -0.011318758130073547,
      0.020986171439290047,
      0.0594184435904026,
      0.026980265974998474,
      -0.06308089196681976,
      -0.008982215076684952,
      -0.0020075698848813772,
      0.03895905241370201,
      -0.02722245082259178,
      0.015835566446185112,
      -0.02112112194299698,
      -0.03966844081878662,
      -0.0073299952782690525,
      0.0558764785528183,
      0.017716895788908005,
      0.004017459694296122,
      0.014980257488787174,
      0.06868685781955719,
      0.023866849020123482,
      0.016850631684064865,
      0.06641880422830582,
      0.13876530528068542,
      -0.01606767252087593,
      0.01304225716739893,
      0.061923637986183167,
      0.0565437488257885,
      -0.1274297535419464,
      0.02998436987400055,
      0.04059863090515137,
      0.06326533854007721,
      -0.010075942613184452,
      -0.07184115052223206,
      0.016603266820311546,
      0.02231861837208271,
      0.0033212059643119574,
      0.023488380014896393,
      -0.1110435500741005,
      0.09275007992982864,
      -0.060457490384578705,
      0.03323211520910263,
      0.0008786507532931864,
      -0.010849551297724247,
      -0.02580931782722473,
      0.05342718958854675,
      -0.020198002457618713,
      0.07496516406536102,
      0.019969798624515533,
      -0.05926911160349846,
      -0.027116522192955017,
      0.030678799375891685,
      0.013931810855865479,
      0.04145451635122299,
      0.0008218159782700241,
      0.06698817759752274,
      0.046716343611478806,
      0.02583359181880951,
      0.01585252396762371,
      -0.04532279819250107,
      -0.10274049639701843,
      -0.01711723394691944,
      0.015333219431340694,
      0.04148058965802193,
      0.027442162856459618,
      0.03369036316871643,
      -0.021643804386258125,
      -0.014053949154913425,
      -0.03520459309220314,
      0.025328924879431725,
      0.0976640060544014,
      -0.026277024298906326,
      -0.051909010857343674,
      -0.007095281034708023,
      0.008177288807928562,
      -0.004241038579493761,
      -0.03177354484796524,
      0.11168835312128067,
      -0.02907240390777588,
      0.07834377139806747,
      -0.09790212661027908,
      -0.024471642449498177,
      -0.008646965026855469,
      -0.00871732085943222,
      0.05641215294599533,
      0.12963061034679413,
      -0.056464262306690216,
      -0.002612884622067213,
      0.0735359936952591,
      0.05664431303739548,
      -0.016858303919434547,
      0.0540727861225605,
      0.006114978343248367,
      0.02480463683605194,
      0.0476074144244194,
      -0.08838183432817459,
      0.06989149749279022,
      -0.009953722357749939,
      -0.009944949299097061,
      -0.01707003265619278,
      -0.00579959899187088,
      0.05732787400484085,
      0.08409876376390457,
      -0.027228062972426414,
      0.060648124665021896,
      0.003182586282491684,
      -0.05016423761844635,
      -0.05127449706196785,
      -0.06568512320518494,
      2.065353692159988e-05,
      0.052113961428403854,
      -0.10412725061178207,
      0.057535234838724136,
      0.00792671274393797,
      -0.016188470646739006,
      -0.008274117484688759,
      0.03605083003640175,
      0.007270611356943846,
      0.07406771183013916,
      0.03505595400929451,
      -0.030297063291072845,
      -0.09914220124483109,
      0.04512910544872284,
      0.020852873101830482,
      0.004281268920749426,
      0.0120775131508708,
      0.002659713150933385,
      -0.03729100897908211,
      0.019227860495448112,
      0.004333207383751869,
      0.04751911759376526,
      -0.008242247626185417,
      -0.02914121374487877,
      -0.06496208906173706,
      0.07427383214235306,
      -0.03268679603934288,
      0.010941875167191029,
      -0.07929248362779617,
      0.019154611974954605,
      -0.015310442075133324,
      0.03965391591191292,
      -0.022213833406567574,
      0.03386523202061653,
      0.036367032676935196,
      0.0700056329369545,
      0.008637377060949802,
      -0.03161928057670593,
      -1.98387133423239e-05,
      -0.09015335142612457,
      0.0656859427690506,
      -0.040683817118406296,
      -0.06985343992710114,
      -0.06318355351686478,
      0.02882516384124756,
      -0.022098002955317497,
      0.016103673726320267,
      0.006284667178988457,
      -0.006365797016769648,
      -0.040517743676900864,
      0.034805480390787125,
      -0.045740097761154175,
      0.03475465625524521,
      -0.0298310499638319,
      0.021664759144186974,
      0.08434457331895828,
      -0.03655414283275604,
      0.08094724267721176,
      0.05912794545292854,
      -0.022972021251916885,
      0.034364305436611176,
      0.06380091607570648,
      -0.025715293362736702,
      0.005745580419898033,
      -0.03445214778184891,
      0.0743887722492218,
      0.06351201236248016,
      0.02196354791522026,
      0.011773670092225075,
      0.02436898835003376,
      -0.061472903937101364,
      0.18545649945735931,
      0.012314457446336746,
      -9.483729809289798e-05,
      0.0037225433625280857,
      0.0004594423808157444,
      -0.0542321652173996,
      0.02189643308520317,
      0.0025606751441955566,
      -0.06375227868556976,
      0.0054089901968836784,
      -0.061628300696611404,
      4.463984805624932e-05,
      -0.03789162635803223,
      -0.005749429110437632,
      -0.041453227400779724,
      -0.003755227429792285,
      -0.05254596471786499,
      -0.048255015164613724,
      0.022005775943398476,
      0.006398612633347511,
      -0.01731119118630886,
      -0.017272865399718285,
      0.027484016492962837,
      0.005408572033047676,
      0.04588310047984123,
      0.013759489171206951,
      0.026043785735964775,
      0.0009677809430286288,
      -0.07561159878969193,
      -0.025345483794808388,
      -0.07572737336158752,
      -0.0005088809411972761,
      0.02701835334300995,
      0.04759520664811134,
      -0.09190983325242996,
      0.1513354629278183,
      0.010879343375563622,
      0.04280439764261246,
      0.06595604866743088,
      -0.03900669887661934,
      0.02814403735101223,
      0.032038282603025436,
      0.06367205083370209,
      0.08945812284946442,
      -0.005264970473945141,
      0.014391178265213966,
      0.017516562715172768,
      0.020598331466317177,
      0.03928116336464882,
      -0.030306022614240646,
      0.1374252587556839,
      0.024803385138511658,
      0.00955240149050951,
      -0.012331342324614525,
      0.06939718872308731,
      0.01677902787923813,
      0.049375008791685104,
      -0.0003404820745345205,
      -0.03915213420987129,
      -0.014509214088320732,
      0.02231367863714695,
      -0.05927155166864395,
      -0.020360067486763,
      -0.042350951582193375,
      0.017672687768936157,
      0.07084273546934128,
      -0.03193158656358719,
      -0.0528663769364357,
      0.0020436670165508986,
      0.051630496978759766,
      0.020228508859872818,
      -0.07967760413885117,
      -0.0500786192715168,
      0.039591748267412186,
      -0.026926320046186447,
      -0.03285260498523712,
      -0.03450668975710869,
      -0.004084914922714233,
      0.019278541207313538,
      -0.0034264156129211187,
      -0.03921287879347801,
      0.033079590648412704,
      -0.014811509288847446,
      -0.06763949245214462,
      0.05080795660614967,
      -0.02972360886633396,
      0.04380738362669945,
      0.03642325848340988,
      0.03181611746549606,
      -0.08626064658164978,
      0.027912966907024384,
      -0.023693671450018883,
      0.022124558687210083,
      0.0010655747028067708,
      0.021845092996954918,
      0.04164474457502365,
      0.083401620388031,
      -0.036302853375673294,
      -0.0713236853480339,
      -0.1127985268831253,
      -0.0012438963167369366,
      -0.035847656428813934,
      -0.039237603545188904,
      0.06764152646064758,
      -0.013524147681891918,
      0.05712234973907471,
      -0.04183424264192581,
      -0.014544696547091007,
      0.05239233374595642,
      0.05341261625289917,
      -0.016956757754087448,
      -0.07123337686061859,
      -0.04397009313106537,
      -0.002133903792127967,
      -0.06280554831027985,
      -0.008359328843653202,
      -0.020015809684991837,
      -0.050985679030418396,
      0.023095082491636276,
      0.017568638548254967,
      0.047693174332380295,
      -0.012496302835643291,
      0.03649402782320976,
      0.04978703707456589,
      0.010180913843214512,
      0.024935932829976082,
      0.008389695547521114,
      -0.04671686515212059,
      0.04570586234331131,
      -0.030504556372761726,
      -0.0011480731191113591,
      0.010802600532770157,
      -0.014990841038525105,
      -0.0019468055106699467,
      0.02888317219913006,
      -0.009089088067412376,
      0.006650486961007118,
      0.03174358606338501,
      0.02035655826330185,
      -0.06911977380514145,
      0.035890787839889526,
      -0.08393354713916779,
      -0.003080005757510662,
      0.0877809077501297,
      0.015137276612222195,
      -0.08442233502864838,
      0.04106060415506363,
      0.03918791934847832,
      -0.0419488251209259,
      -0.01285774540156126,
      0.014632076025009155,
      -0.04127706214785576,
      -0.010059411637485027,
      0.02574017457664013,
      0.041834063827991486,
      -0.04646855592727661,
      0.005276691634207964,
      -0.08336149156093597,
      -0.09823990613222122,
      -0.01946774683892727,
      0.055700402706861496,
      0.0016397446161136031,
      -0.05887697637081146,
      0.0273328498005867,
      -0.016604246571660042,
      0.019639961421489716,
      -0.014650772325694561,
      0.016532734036445618,
      -0.038648996502161026,
      0.041944511234760284,
      0.05128692835569382,
      0.04542423412203789,
      -0.06931448727846146,
      0.029530614614486694,
      -0.039471033960580826,
      0.045750945806503296,
      -0.011250033043324947,
      0.03209269419312477,
      -0.0008652799297124147,
      0.04966709017753601,
      0.08523743599653244,
      0.04607613384723663,
      0.03635777160525322,
      -0.0337831936776638,
      -0.019499918445944786,
      -0.0018050966318696737,
      0.07256744801998138,
      0.0338488332927227,
      0.010541335679590702,
      0.008102823980152607,
      0.02152501605451107,
      -0.0816018208861351,
      0.08106786012649536,
      0.03297470882534981,
      -0.07833728194236755,
      -0.0388503298163414,
      0.005855789873749018,
      0.014606148935854435,
      -0.06199527904391289,
      -0.04750167950987816
    ],
    [
      -0.045870423316955566,
      -0.06288120150566101,
      -0.03558063879609108,
      0.04224759712815285,
      0.016652395948767662,
      0.0015318526420742273,
      0.0387270487844944,
      0.06453090906143188,
      0.05624901503324509,
      -0.05545724183320999,
      -0.048963505774736404,
      -0.02512446790933609,
      0.025821173563599586,
      0.029892899096012115,
      -0.013818015344440937,
      0.06204384192824364,
      -0.07391898334026337,
      -0.028121253475546837,
      0.010693248361349106,
      0.03673698753118515,
      0.026540720835328102,
      -0.05767321214079857,
      -0.037325091660022736,
      -0.02838076837360859,
      -0.037974268198013306,
      0.02015979029238224,
      -0.016706418246030807,
      0.0036357815843075514,
      -0.029569752514362335,
      0.09519408643245697,
      -0.02075248211622238,
      -0.025740670040249825,
      0.05852420628070831,
      0.03304704651236534,
      -0.04826265200972557,
      -0.020353488624095917,
      -0.01943967491388321,
      0.06429395079612732,
      0.04577016457915306,
      0.07102888077497482,
      0.01826614700257778,
      -0.03367224335670471,
      0.04321860149502754,
      0.08442813903093338,
      0.014488289132714272,
      0.05070120468735695,
      -0.16547729074954987,
      0.03988061100244522,
      -0.02715805359184742,
      0.029680661857128143,
      0.032034486532211304,
      -0.15865430235862732,
      -0.023506956174969673,
      -0.04928700998425484,
      0.02606348693370819,
      0.009545800276100636,
      -0.08273240178823471,
      0.007766527123749256,
      0.060427431017160416,
      -0.028157107532024384,
      0.031086180359125137,
      0.06537635624408722,
      0.06487613916397095,
      -0.034061260521411896,
      -0.0018593448912724853,
      -0.06918828934431076,
      -0.012898305431008339,
      0.07773558795452118,
      0.028860362246632576,
      -0.06041724234819412,
      -0.018361328169703484,
      0.008425342850387096,
      -0.015149293467402458,
      -0.015002871863543987,
      -0.047255631536245346,
      0.02829110622406006,
      -0.043847642838954926,
      -0.03639499098062515,
      0.007618627976626158,
      0.027445340529084206,
      -0.06097422540187836,
      0.05795164033770561,
      0.044163186103105545,
      0.029932687059044838,
      0.003395011881366372,
      -0.025091735646128654,
      -0.009717737324535847,
      0.057377301156520844,
      0.04866781085729599,
      -0.04646208509802818,
      -0.03963102400302887,
      -0.07535754889249802,
      -0.04326915368437767,
      0.004921320825815201,
      -0.08873133361339569,
      -0.011656519025564194,
      0.03988011181354523,
      0.0565982349216938,
      -0.003229575464501977,
      -0.025912944227457047,
      0.011392411775887012,
      0.0693051889538765,
      -0.030029358342289925,
      -0.07369846105575562,
      -0.016449596732854843,
      0.025818312540650368,
      -0.04799573868513107,
      0.039009321480989456,
      0.006236372049897909,
      0.058089304715394974,
      -0.06172466278076172,
      -0.010835476219654083,
      -0.0025265675503760576,
      -0.02806999534368515,
      -0.07067915052175522,
      -0.08054864406585693,
      0.03975582495331764,
      -0.010124553926289082,
      0.03920092433691025,
      -0.07367131114006042,
      -0.06069609895348549,
      -0.062438227236270905,
      -0.03197842836380005,
      0.08602461218833923,
      -0.03766218200325966,
      -0.05112804099917412,
      0.011576450429856777,
      0.07116711139678955,
      -0.022539546713232994,
      -0.11934687197208405,
      -0.003020785516127944,
      0.02367653325200081,
      0.01176577527076006,
      -0.04830141365528107,
      -0.0487058199942112,
      0.07253601402044296,
      -0.011413827538490295,
      -0.15392246842384338,
      -0.03642040491104126,
      -0.008016251027584076,
      0.03737162426114082,
      0.0789201632142067,
      -0.05590696632862091,
      0.016772791743278503,
      -0.08171993494033813,
      0.0002266238006995991,
      -0.013815301470458508,
      -0.010265587829053402,
      0.04032498970627785,
      0.008147881366312504,
      -0.0009461568552069366,
      -0.019656753167510033,
      -0.008165666833519936,
      0.084892138838768,
      -0.047348279505968094,
      -0.01542271114885807,
      -0.05725715309381485,
      0.08965308964252472,
      0.032419364899396896,
      -0.026373716071248055,
      -0.052055712789297104,
      0.0070920116268098354,
      -0.04718508943915367,
      -0.0689559057354927,
      -0.05837539955973625,
      -0.042371753603219986,
      0.010099378414452076,
      -0.0403440035879612,
      0.06786934286355972,
      -0.02923186495900154,
      0.045800983905792236,
      -0.12207192927598953,
      -0.009254277683794498,
      -0.007271446753293276,
      -0.011611892841756344,
      -0.07772880047559738,
      -0.011962689459323883,
      -0.030683496966958046,
      -0.03768443688750267,
      0.009330051951110363,
      -0.023961631581187248,
      -0.04874694347381592,
      -0.05465026572346687,
      0.003540203208103776,
      -0.049044087529182434,
      0.022549530491232872,
      -0.03297315165400505,
      -0.043451689183712006,
      0.0009428337216377258,
      0.02109638787806034,
      0.04222411289811134,
      -0.0041811503469944,
      -0.03295908495783806,
      -0.07723814994096756,
      -0.008280693553388119,
      0.013655362650752068,
      -0.03365495800971985,
      0.014855578541755676,
      0.09259913861751556,
      0.00898369587957859,
      0.013568122871220112,
      -0.07567338645458221,
      0.058353547006845474,
      -0.00426934938877821,
      -0.038661208003759384,
      0.0024253488518297672,
      0.05634597688913345,
      0.0324532724916935,
      -0.055522624403238297,
      -0.0462498813867569,
      -0.014398576691746712,
      -0.03637978807091713,
      0.11562632024288177,
      -0.057637959718704224,
      0.03759995102882385,
      0.007137915585190058,
      0.018985331058502197,
      0.0003241715603508055,
      -0.062136225402355194,
      -0.08784210681915283,
      -0.04191107302904129,
      0.0034027777146548033,
      -0.09524249285459518,
      0.10686270147562027,
      -0.013648584485054016,
      -0.016634128987789154,
      -0.03914973884820938,
      0.038414131850004196,
      0.01518747117370367,
      0.03023078665137291,
      0.016066662967205048,
      -0.05394063889980316,
      0.038550905883312225,
      -0.005543356295675039,
      0.06215961277484894,
      0.05404204875230789,
      0.01114917453378439,
      0.025751905515789986,
      0.0448959581553936,
      -0.047761570662260056,
      -0.015730364248156548,
      -0.042574312537908554,
      -0.09746024757623672,
      -0.040169719606637955,
      -0.019223453477025032,
      -0.02153838984668255,
      -0.046488530933856964,
      -0.05423023924231529,
      0.03509726747870445,
      0.021783217787742615,
      0.059918638318777084,
      0.08531776815652847,
      0.018205799162387848,
      -0.03557119145989418,
      0.018913792446255684,
      0.001118347398005426,
      0.06905699521303177,
      0.06041344627737999,
      -0.09145636856555939,
      -0.0003240325895603746,
      -0.04832969978451729,
      -0.012402006424963474,
      -0.08781269937753677,
      0.005430763587355614,
      -0.025632193312048912,
      0.005565794184803963,
      0.009578438475728035,
      -0.010875687003135681,
      -0.013064519502222538,
      -0.029891259968280792,
      -0.016029976308345795,
      0.03889782354235649,
      0.0175927821546793,
      0.016164900735020638,
      0.02796381711959839,
      -0.023551203310489655,
      -0.040493279695510864,
      -0.0007517201593145728,
      0.004291173070669174,
      0.001155921258032322,
      0.04644962027668953,
      0.0021562092006206512,
      -0.03623452037572861,
      -0.07343507558107376,
      0.01763751730322838,
      -0.06577685475349426,
      -0.031243029981851578,
      0.011650667525827885,
      -0.008942888118326664,
      0.0036448948085308075,
      0.02574159950017929,
      0.025883803144097328,
      0.007009074557572603,
      -0.009312361478805542,
      0.016509104520082474,
      0.049275193363428116,
      0.054055340588092804,
      -0.02388380840420723,
      0.042197659611701965,
      -0.06989147514104843,
      -0.044830694794654846,
      0.019847504794597626,
      -0.032767534255981445,
      0.038131874054670334,
      -0.013781608082354069,
      0.03212965652346611,
      -0.01975168101489544,
      0.027761660516262054,
      -0.023429997265338898,
      0.002240194706246257,
      0.025344068184494972,
      -0.11169663071632385,
      0.005674573592841625,
      -0.053414635360240936,
      -0.03187089040875435,
      0.014917152002453804,
      0.014315668493509293,
      -0.020703138783574104,
      -0.024943126365542412,
      -0.04590616747736931,
      -0.030844323337078094,
      0.017548805102705956,
      -0.0358072891831398,
      0.022411715239286423,
      0.04374687373638153,
      -0.000851757824420929,
      -0.07467156648635864,
      -0.03746939077973366,
      0.028049038723111153,
      -0.1117096021771431,
      0.0002460526011418551,
      0.0869685634970665,
      0.003968927543610334,
      0.041487015783786774,
      0.009702793322503567,
      -0.01339536439627409,
      0.022351088002324104,
      0.08466202765703201,
      0.01796613819897175,
      -0.08484191447496414,
      -0.010212293826043606,
      -0.0051063126884400845,
      -0.08367902040481567,
      0.0609402060508728,
      0.01134849525988102,
      0.04045409709215164,
      0.08513262867927551,
      0.0050343107432127,
      0.012094532139599323,
      0.033812515437603,
      0.028713161125779152,
      0.03222716972231865,
      -0.011406478472054005,
      -0.007849998772144318,
      0.07241138070821762,
      0.1228095144033432,
      0.06557928025722504,
      0.0908115804195404,
      0.0989895761013031,
      0.023556355386972427,
      -0.006404312793165445,
      -0.02209111861884594,
      0.055625855922698975,
      -0.0038397132884711027,
      0.0017731590196490288,
      0.012172955088317394,
      0.021967865526676178,
      0.002812604885548353,
      0.03181936964392662,
      -0.00286617549136281,
      -0.0371679961681366,
      0.05291392654180527,
      0.08271386474370956,
      -0.035935308784246445,
      0.011271881870925426,
      0.1254301220178604,
      0.005748568568378687,
      -0.05143412947654724,
      -0.04860398918390274,
      -0.011465265415608883,
      0.02045707032084465,
      0.00686249602586031,
      -0.039338551461696625,
      -0.13282060623168945,
      -0.09454096108675003,
      0.03851009160280228,
      0.04161789268255234,
      0.06618379801511765,
      0.007719712797552347,
      -0.023009343072772026,
      -0.021794576197862625,
      0.07198402285575867,
      -0.034034404903650284,
      -0.08897706866264343,
      -0.10106880962848663,
      0.029758775606751442,
      -0.058822378516197205,
      0.003393191611394286,
      0.022351467981934547,
      -0.044464606791734695,
      0.0707673504948616,
      0.04047344997525215,
      -0.044747404754161835,
      -0.10350301861763,
      0.03430309146642685,
      -0.03520604595541954,
      0.05606674775481224,
      0.05269981920719147,
      0.06319371610879898,
      0.005983330309391022,
      0.0008999950368888676,
      0.0823710486292839,
      -0.034392792731523514,
      -0.045138824731111526,
      -0.024426177144050598,
      -0.061471059918403625,
      -0.07575733214616776,
      -0.03658001124858856,
      -0.05310932546854019,
      -0.11347983777523041,
      0.01940467208623886,
      -0.1106695830821991,
      0.08031265437602997,
      0.002544471062719822,
      -0.001812267815694213,
      0.03183633089065552,
      -0.011044689454138279,
      -0.021429790183901787,
      0.04464247077703476,
      0.006301517598330975,
      0.023449843749403954,
      0.013560297898948193,
      0.08913879096508026,
      -0.048824913799762726,
      -0.026907235383987427,
      -0.037285882979631424,
      -0.02874959260225296,
      -0.0072751292027533054,
      -0.034315355122089386,
      0.09667254984378815,
      -0.01083698682487011,
      -0.03290899097919464,
      -0.0025188259314745665,
      -0.061627134680747986,
      -0.009826852940022945,
      -0.005430876277387142,
      -0.05476319417357445,
      0.0944647565484047,
      -0.027330081909894943,
      -0.011828688904643059,
      0.029739806428551674,
      -0.0450168214738369,
      -0.07420377433300018,
      0.04385656863451004,
      -0.03246215358376503,
      0.002929616952314973,
      0.0865088552236557,
      -0.0026372240390628576,
      0.018189601600170135,
      0.05345040559768677,
      0.053764890879392624,
      -0.034907419234514236,
      -0.027202432975172997,
      -0.048603355884552,
      -0.06912261992692947,
      0.07355018705129623,
      0.008505585603415966,
      0.009134442545473576,
      0.012832596898078918,
      0.08596053719520569,
      -0.010496661998331547,
      0.006279404275119305,
      0.028217334300279617,
      -0.008508929051458836,
      0.0027148090302944183,
      -0.04260609298944473,
      0.005927680991590023,
      -0.03700976446270943,
      -0.07906084507703781,
      0.02604253776371479,
      0.04167322814464569,
      -0.055294789373874664,
      0.040919411927461624,
      0.0036382153630256653,
      -0.06271287053823471,
      0.030540745705366135,
      0.016332829371094704,
      0.05083061382174492,
      0.05695449560880661,
      -0.029603267088532448,
      0.06097574159502983,
      -0.023789506405591965,
      -0.0006820307462476194,
      -0.02472078427672386,
      0.0060380324721336365,
      -0.05000316724181175,
      -0.04291214048862457,
      -0.08144170045852661,
      0.07363119721412659,
      0.03919149562716484,
      0.035739727318286896,
      0.03730640560388565,
      -0.03823212906718254
    ],
    [
      0.028617167845368385,
      0.022015780210494995,
      0.02548530139029026,
      -0.027145452797412872,
      -0.02735043875873089,
      -0.007520503830164671,
      -0.03467122092843056,
      0.06606440246105194,
      0.05571073293685913,
      0.04828070476651192,
      -0.043877944350242615,
      -0.02089848741889,
      -0.020264210179448128,
      -0.016923952847719193,
      -0.01923202909529209,
      -0.04523476958274841,
      -0.0007590294117107987,
      -0.02700241655111313,
      -0.0028725843876600266,
      -0.03876359760761261,
      -0.006763620302081108,
      0.025148961693048477,
      -0.023569313809275627,
      0.07084120810031891,
      0.0510544627904892,
      -0.11503917723894119,
      0.0197751447558403,
      -0.007176829967647791,
      -0.05523572489619255,
      -0.017101872712373734,
      -0.07878302782773972,
      0.048867374658584595,
      0.0053384751081466675,
      -0.019689155742526054,
      -0.003565057646483183,
      -0.01661897636950016,
      0.013868813402950764,
      0.06972325593233109,
      -0.044551536440849304,
      -0.019856341183185577,
      0.06841336190700531,
      0.04859108477830887,
      -0.007968535646796227,
      0.024350877851247787,
      0.03061170130968094,
      -0.01165566686540842,
      -0.10287856310606003,
      -0.06686078011989594,
      0.0024733736645430326,
      0.0021054153330624104,
      -0.021098393946886063,
      -0.05156269297003746,
      -0.023899607360363007,
      -0.06025397777557373,
      0.009192509576678276,
      -0.03350291773676872,
      -0.020626617595553398,
      -0.04576585069298744,
      0.046411022543907166,
      0.023036137223243713,
      -0.0008249958627857268,
      0.024686187505722046,
      -0.06226738169789314,
      0.005226221866905689,
      0.05733926221728325,
      -0.0022024649661034346,
      -0.02635844610631466,
      -0.05169809237122536,
      -0.01014926377683878,
      0.009619663469493389,
      0.01219133660197258,
      -0.034514788538217545,
      -0.03724313899874687,
      -0.028907183557748795,
      0.05271860584616661,
      0.03995833173394203,
      0.055594224482774734,
      0.006112993229180574,
      0.058225952088832855,
      0.03256097063422203,
      -0.030754275619983673,
      0.025250094011425972,
      -0.021171119064092636,
      -0.021295960992574692,
      0.06457510590553284,
      -0.0011674698907881975,
      0.029451746493577957,
      0.058034371584653854,
      -0.051632367074489594,
      0.0425175316631794,
      -0.05160580202937126,
      -0.004088204354047775,
      -0.049252551048994064,
      -0.028162114322185516,
      -0.0743759348988533,
      -0.0107291080057621,
      0.13640117645263672,
      -0.01878599263727665,
      -0.01772269979119301,
      -0.048636410385370255,
      0.036136284470558167,
      0.013162144459784031,
      -0.023918988183140755,
      0.07736815512180328,
      0.03687844052910805,
      0.09073589742183685,
      -0.07870525866746902,
      0.05957195907831192,
      -0.0249486081302166,
      -0.03222883120179176,
      -0.04696618765592575,
      -0.06325400620698929,
      0.051986195147037506,
      -0.025154151022434235,
      0.038923632353544235,
      0.03313814476132393,
      0.03286856412887573,
      -0.06340144574642181,
      0.042558398097753525,
      0.002350892871618271,
      -0.0001393371494486928,
      0.010472944937646389,
      -0.04472804069519043,
      -0.04964359477162361,
      -0.004587301053106785,
      0.018441954627633095,
      0.05293669179081917,
      -0.02175276167690754,
      -0.02035127393901348,
      -0.0723721906542778,
      0.02565055526793003,
      0.028030039742588997,
      -0.030642477795481682,
      0.014930068515241146,
      -0.04739495366811752,
      0.012408246286213398,
      -0.033824045211076736,
      -0.021738950163125992,
      0.04212220013141632,
      0.013338930904865265,
      -0.027412712574005127,
      0.03443164378404617,
      0.04901398718357086,
      0.03455017879605293,
      0.01050456240773201,
      -0.041708074510097504,
      0.03126560524106026,
      0.06125333532691002,
      -0.008589327335357666,
      0.07829398661851883,
      -0.06431781500577927,
      0.05039167031645775,
      0.08594819158315659,
      0.01987178437411785,
      0.06778650730848312,
      -0.030260339379310608,
      0.012794014066457748,
      -0.018223274499177933,
      0.032716792076826096,
      0.04662017151713371,
      -0.03766852989792824,
      -0.01894601248204708,
      0.048518501222133636,
      0.007551141083240509,
      -0.02858702652156353,
      0.09765175729990005,
      0.03035486862063408,
      -0.0372866690158844,
      -0.0156844574958086,
      -0.0029441018123179674,
      0.07960645109415054,
      0.02013365924358368,
      -0.007045544218271971,
      0.031474415212869644,
      -0.06197366863489151,
      -0.0076855942606925964,
      -0.008492331020534039,
      -0.016390258446335793,
      -0.0633997991681099,
      0.0011038391385227442,
      0.02500236965715885,
      -0.0947708711028099,
      0.017061838880181313,
      -0.0526706837117672,
      -0.06735919415950775,
      -0.069002166390419,
      0.041794802993535995,
      0.024772794917225838,
      -0.0600721538066864,
      -0.0016899113543331623,
      0.03632215037941933,
      -0.004286529030650854,
      -0.04510334134101868,
      0.007061238866299391,
      0.030912593007087708,
      -0.01766146719455719,
      0.021944954991340637,
      -0.013650275766849518,
      0.040459055453538895,
      -0.015207158401608467,
      0.0719594657421112,
      0.01521547231823206,
      0.029687151312828064,
      0.043252989649772644,
      0.07395025342702866,
      -0.02714497223496437,
      -0.06771072000265121,
      -0.07014968991279602,
      0.017509831115603447,
      0.012768793851137161,
      -0.016344459727406502,
      0.0869830772280693,
      -0.036865100264549255,
      -0.12072321027517319,
      0.09347778558731079,
      -0.007580304052680731,
      -0.06828354299068451,
      0.07981979101896286,
      -0.02993224374949932,
      -0.03564096987247467,
      -0.01308208517730236,
      -0.03720109164714813,
      0.005956720560789108,
      0.07084736973047256,
      0.0171901173889637,
      0.034405071288347244,
      0.024541951715946198,
      0.06705599278211594,
      -0.03640693798661232,
      0.10187452286481857,
      -0.020454108715057373,
      -0.00359521247446537,
      0.016864817589521408,
      0.06045909225940704,
      -0.01802624575793743,
      0.020407268777489662,
      0.013247662223875523,
      0.016945281997323036,
      -0.0755133107304573,
      -0.05901068076491356,
      0.04368709400296211,
      0.059371888637542725,
      0.06258762627840042,
      -0.054779164493083954,
      -0.05093345418572426,
      0.04576285928487778,
      0.00019845191854983568,
      -0.0035166635643690825,
      -0.051979027688503265,
      -0.010130025446414948,
      0.03832089900970459,
      0.06056622043251991,
      -0.04580087587237358,
      0.03195737674832344,
      -0.06894837319850922,
      0.005397208500653505,
      -0.033940501511096954,
      0.0010708851041272283,
      -0.037858281284570694,
      0.011950990185141563,
      -0.03770475834608078,
      0.04024525731801987,
      -0.018604720011353493,
      -0.03850647062063217,
      -0.0014271228574216366,
      -0.018562626093626022,
      -0.0027290326543152332,
      0.0019300987478345633,
      -0.017154952511191368,
      0.10908369719982147,
      -0.021518301218748093,
      -0.08336351066827774,
      -0.008346420712769032,
      0.03610830008983612,
      -0.05709904432296753,
      0.052616894245147705,
      -0.05600972846150398,
      -0.01514600869268179,
      0.08852497488260269,
      -0.1280846744775772,
      0.04067147895693779,
      0.05380261316895485,
      -0.034261927008628845,
      -0.04585151746869087,
      -0.02147744782269001,
      -0.046186067163944244,
      -0.0526457205414772,
      0.04167526960372925,
      -0.07568302005529404,
      0.07356392592191696,
      -0.042798224836587906,
      0.09163375198841095,
      -0.022152770310640335,
      0.020002130419015884,
      -0.05851035192608833,
      0.029462549835443497,
      0.020423857495188713,
      0.033210497349500656,
      -0.007121519651263952,
      0.013481048867106438,
      0.042082320898771286,
      0.041246868669986725,
      0.027397215366363525,
      0.033021923154592514,
      0.05937701463699341,
      -0.04454710707068443,
      0.0444946363568306,
      0.035606496036052704,
      -0.01678313873708248,
      -0.02734823152422905,
      -0.04845566675066948,
      -0.03880007565021515,
      0.06739300489425659,
      -0.02830778807401657,
      0.05981580540537834,
      0.01080239750444889,
      -0.050002600997686386,
      -0.06204606220126152,
      -0.06122777238488197,
      -0.08652015775442123,
      -0.10459823906421661,
      -0.008327517658472061,
      0.03315022960305214,
      -0.003637593938037753,
      0.012980666011571884,
      0.09181718528270721,
      0.02216370962560177,
      -0.04205635190010071,
      0.05718303844332695,
      0.006377741228789091,
      0.11799192428588867,
      0.027927987277507782,
      0.004203314892947674,
      0.003502501407638192,
      -0.026113536208868027,
      -0.039271194487810135,
      0.037172604352235794,
      -0.01807619072496891,
      -0.0035204924643039703,
      -0.07823963463306427,
      -0.028026921674609184,
      -0.06101918965578079,
      0.018667511641979218,
      -0.02921484410762787,
      0.04973870515823364,
      -0.05137572064995766,
      0.05149126052856445,
      0.014069744385778904,
      -0.057416412979364395,
      -0.0016128671122714877,
      -0.03032073937356472,
      0.038794681429862976,
      -0.07503305375576019,
      -0.04741838574409485,
      0.0034863885957747698,
      -0.011831051670014858,
      0.06341265887022018,
      0.003720389213413,
      0.00869584921747446,
      -0.023225951939821243,
      -0.0092385932803154,
      0.07746754586696625,
      0.018540726974606514,
      0.034108392894268036,
      0.02529619261622429,
      0.06694160401821136,
      -0.016304485499858856,
      0.009951256215572357,
      0.0627719983458519,
      -0.019557705149054527,
      -0.061001311987638474,
      0.051674727350473404,
      0.050694115459918976,
      -0.13740046322345734,
      0.016383783891797066,
      0.04873581603169441,
      0.040222108364105225,
      -0.003102457383647561,
      0.04290710762143135,
      -0.0702030286192894,
      0.052326470613479614,
      0.00952677521854639,
      -0.018718501552939415,
      -0.01790553703904152,
      0.06612195819616318,
      -0.014932041987776756,
      0.06237240508198738,
      -0.049986984580755234,
      -0.018132666125893593,
      -0.0019093496957793832,
      -0.03480592742562294,
      -0.001056071138009429,
      -0.0035044807009398937,
      -0.06252886354923248,
      0.0122301634401083,
      -0.0007602457772009075,
      -0.10188646614551544,
      -0.020498143509030342,
      -0.0026757295709103346,
      0.020793069154024124,
      0.04958728700876236,
      -0.03951196372509003,
      -0.011671269312500954,
      -0.07831298559904099,
      -0.11607528477907181,
      -0.017819050699472427,
      0.03993600606918335,
      -0.045709799975156784,
      -0.007690461352467537,
      0.03160441294312477,
      -0.015495877712965012,
      -0.007641314994543791,
      0.03687133267521858,
      -0.024456486105918884,
      0.03005724772810936,
      -0.03950023651123047,
      0.04146772623062134,
      0.08985038846731186,
      -0.08148238062858582,
      -0.02639743871986866,
      -0.0847005695104599,
      -0.05484791472554207,
      0.07907507568597794,
      -0.065355584025383,
      -0.045852094888687134,
      0.028289010748267174,
      -0.005760960280895233,
      -0.014421897009015083,
      0.008675969205796719,
      -0.0311789158731699,
      -0.007609256077557802,
      -0.04219302162528038,
      -0.07558063417673111,
      0.020471591502428055,
      0.052594467997550964,
      -0.03582218289375305,
      0.06007944047451019,
      0.0073600341565907,
      0.0007647796883247793,
      0.08032449334859848,
      -0.06474419683218002,
      0.0012950714444741607,
      0.119631826877594,
      -0.0556764155626297,
      0.004030483774840832,
      -0.05202190950512886,
      -0.050428878515958786,
      -0.0013206182047724724,
      0.08286769688129425,
      -0.02374708093702793,
      -0.008286047726869583,
      0.04454865679144859,
      0.002934547606855631,
      0.007794027216732502,
      -0.0194381270557642,
      0.002863649744540453,
      0.006581063382327557,
      -0.006213668268173933,
      0.05852741748094559,
      -0.008700944483280182,
      -0.11317282915115356,
      -0.04743142053484917,
      0.023480553179979324,
      0.003781560342758894,
      0.026183702051639557,
      0.009038713760674,
      0.003964988514780998,
      -0.0940387099981308,
      -0.007974947802722454,
      -0.06639176607131958,
      -0.05392424017190933,
      -0.03523050621151924,
      0.038123052567243576,
      -0.12204860150814056,
      0.012232222594320774,
      0.012196917086839676,
      -0.00326852360740304,
      -0.03311658650636673,
      -0.035808030515909195,
      -0.04096200317144394,
      0.0318300686776638,
      0.01602751761674881,
      0.020771758630871773,
      0.02041897363960743,
      -0.0414801724255085,
      0.0023616389371454716,
      -0.035184744745492935,
      0.018572740256786346,
      -0.08017895370721817,
      0.009036107920110226,
      0.09223472326993942,
      0.039853695780038834,
      0.05669695511460304,
      -0.06800585985183716,
      0.002624735003337264,
      0.0070206779055297375,
      -0.02424522675573826,
      0.03779645264148712,
      0.01326342299580574,
      -0.0666281059384346,
      -0.0632086768746376,
      -0.014360006898641586,
      -0.009669429622590542
    ],
    [
      -0.08775286376476288,
      0.0006793049979023635,
      -0.04121064394712448,
      -0.034580644220113754,
      -0.0014267899096012115,
      -0.09410446137189865,
      -0.012223568744957447,
      -0.036066580563783646,
      0.07595720142126083,
      0.004041875712573528,
      0.04146979749202728,
      0.08044756948947906,
      -0.05395893380045891,
      0.023165617138147354,
      -0.03081127256155014,
      0.053489185869693756,
      -0.037145745009183884,
      -0.033222198486328125,
      0.04640048369765282,
      0.053265929222106934,
      0.037149056792259216,
      0.013374579139053822,
      0.009019436314702034,
      0.04452703893184662,
      -0.051647286862134933,
      0.04960844665765762,
      -0.056130822747945786,
      -0.04549022763967514,
      0.01216514129191637,
      -0.01702084019780159,
      0.013875072821974754,
      -0.08482780307531357,
      0.014865090139210224,
      0.0016546247061342,
      -0.06142053380608559,
      0.0318751223385334,
      0.01054285280406475,
      0.019505489617586136,
      0.09932742267847061,
      -0.021159294992685318,
      -0.011688536033034325,
      -0.027589356526732445,
      -0.07843036204576492,
      0.002276419196277857,
      0.030935291200876236,
      0.013961327262222767,
      -0.10859793424606323,
      -0.004321564920246601,
      0.025259822607040405,
      0.0035786915104836226,
      0.030213823541998863,
      -0.056772295385599136,
      0.00564839830622077,
      0.016306646168231964,
      0.008208854123950005,
      -0.07751372456550598,
      0.08081280440092087,
      -0.022044792771339417,
      0.010515882633626461,
      0.0479893684387207,
      -0.016233036294579506,
      -0.07842195779085159,
      0.003960133530199528,
      -0.01592002809047699,
      0.035399969667196274,
      -0.00728111807256937,
      -0.06574385613203049,
      0.02321784384548664,
      -0.012899290770292282,
      0.024539975449442863,
      0.024648508056998253,
      -0.04714376479387283,
      0.03881338611245155,
      -0.061925552785396576,
      0.04739023372530937,
      -0.03234154358506203,
      0.009615810588002205,
      -0.022052841261029243,
      0.014344949275255203,
      -0.015007782727479935,
      -0.007863440550863743,
      0.08877483010292053,
      -0.05455142259597778,
      -0.0713265910744667,
      0.03915124014019966,
      0.02029656060039997,
      -0.04051206633448601,
      -0.05288032814860344,
      0.07265668362379074,
      0.06158880889415741,
      -0.032794494181871414,
      0.08267442882061005,
      0.007115339860320091,
      -0.004751726984977722,
      -0.0678696408867836,
      0.023702090606093407,
      -0.0032943743281066418,
      0.03747737780213356,
      0.04215448349714279,
      0.03417988494038582,
      -0.015472783707082272,
      -0.015942303463816643,
      -0.023043276742100716,
      -0.05784378573298454,
      0.03440045937895775,
      0.017727091908454895,
      -0.014277195557951927,
      -0.0007985889096744359,
      -0.008465263992547989,
      0.005743562243878841,
      -0.05030854046344757,
      -0.06388391554355621,
      0.05557098984718323,
      -0.009363871067762375,
      -0.039842016994953156,
      0.07261507958173752,
      -0.03393825143575668,
      -0.01506727747619152,
      -0.02718578651547432,
      -0.07231208682060242,
      0.010811597108840942,
      -0.03364526107907295,
      0.028761880472302437,
      -0.03937334939837456,
      -0.023509472608566284,
      0.06796681880950928,
      0.03638327494263649,
      0.03182275965809822,
      0.03883914276957512,
      -0.014571458101272583,
      0.08027291297912598,
      0.06769491732120514,
      -0.006114151794463396,
      -0.06790405511856079,
      0.09473947435617447,
      0.011507744900882244,
      -0.02290133759379387,
      0.021826406940817833,
      0.016320420429110527,
      0.012783869169652462,
      0.08784613013267517,
      0.017776085063815117,
      0.022239772602915764,
      0.010494668036699295,
      0.07177316397428513,
      -0.0892544612288475,
      0.05926233530044556,
      0.00639613252133131,
      0.06379923969507217,
      0.01863809861242771,
      0.026653168722987175,
      0.004102373030036688,
      0.09784672409296036,
      -0.0018624735530465841,
      0.03024931252002716,
      -0.011658583767712116,
      0.052118245512247086,
      0.029868517071008682,
      0.03988584131002426,
      -0.07744702696800232,
      0.008225766010582447,
      -0.09599008411169052,
      0.04420703276991844,
      0.005703560076653957,
      -0.021307259798049927,
      -0.011823667213320732,
      0.008136148564517498,
      0.0005300764460116625,
      -0.03580263629555702,
      -0.03344263136386871,
      -0.02344517409801483,
      -0.05057057738304138,
      -0.07138672471046448,
      -0.016474373638629913,
      0.010586000978946686,
      -0.04651336744427681,
      0.0027639023028314114,
      0.03160599246621132,
      0.002401465317234397,
      0.017253423109650612,
      0.04966967925429344,
      -0.03230048343539238,
      0.034922610968351364,
      0.10993300378322601,
      0.025857102125883102,
      0.09482582658529282,
      0.033739250153303146,
      -0.06732761859893799,
      -0.01115080900490284,
      -0.015213158912956715,
      -0.026592642068862915,
      0.031746234744787216,
      -0.13499754667282104,
      -0.030380340293049812,
      0.03913184627890587,
      -0.00804734043776989,
      0.03189732879400253,
      -0.019038597121834755,
      0.03248979523777962,
      -0.0016732803778722882,
      0.04444194212555885,
      -0.014413539320230484,
      -0.012608759105205536,
      0.03816477209329605,
      0.0580349825322628,
      -0.0035841853823512793,
      -0.030671386048197746,
      0.015963265672326088,
      0.06529060751199722,
      0.02631503902375698,
      -0.07968994975090027,
      0.04575850069522858,
      0.05508527532219887,
      0.037142861634492874,
      0.03614579513669014,
      -0.0047712745144963264,
      -0.02862834371626377,
      0.067650206387043,
      -0.028676198795437813,
      -0.0544852614402771,
      0.033158209174871445,
      -0.011401754803955555,
      -0.04670071229338646,
      0.00011781362991314381,
      -0.0425204336643219,
      -0.07901016622781754,
      0.08332642912864685,
      0.007171410135924816,
      0.0074998922646045685,
      0.0749669224023819,
      -0.024930616840720177,
      -0.06919462233781815,
      -0.0009192435536533594,
      0.007231750525534153,
      -0.10558260977268219,
      -0.04684578999876976,
      -0.049361612647771835,
      0.03634617477655411,
      -0.0029715218115597963,
      -0.0035874489694833755,
      -0.03310723975300789,
      0.014200428500771523,
      -0.01704428344964981,
      0.0330437496304512,
      0.0011028498411178589,
      -0.007958284579217434,
      -0.0035560077521950006,
      0.02654837630689144,
      -0.09514163434505463,
      0.1175646111369133,
      -0.05077315866947174,
      0.08942554146051407,
      -0.02881816402077675,
      0.03270193561911583,
      0.05685937777161598,
      0.021495817229151726,
      0.0907403975725174,
      -0.05604756250977516,
      -0.015237008221447468,
      0.010260584764182568,
      -0.03846888244152069,
      0.0479329451918602,
      -0.02229985035955906,
      -0.014133982360363007,
      -0.0002757520996965468,
      0.029844852164387703,
      0.02070554904639721,
      -0.008161461912095547,
      0.015509393066167831,
      0.03548618406057358,
      0.04254983365535736,
      0.057192422449588776,
      -0.005921083968132734,
      0.014362904243171215,
      0.06273781508207321,
      0.00850998517125845,
      0.06864160299301147,
      0.018167583271861076,
      0.04701253026723862,
      -0.021293144673109055,
      0.09900034964084625,
      0.10984298586845398,
      -0.034674663096666336,
      -0.01508769765496254,
      -0.030448155477643013,
      -0.03497273847460747,
      0.05999371409416199,
      -0.04343697801232338,
      -0.028825417160987854,
      0.02343791350722313,
      0.059463098645210266,
      0.025750398635864258,
      0.025646943598985672,
      -0.01868375577032566,
      0.0734134316444397,
      -0.03899698704481125,
      -0.04138052836060524,
      0.027719663456082344,
      -0.04893772304058075,
      0.05775553360581398,
      -0.01864352636039257,
      0.01966296322643757,
      0.00218936032615602,
      0.01774091087281704,
      -0.05591131001710892,
      0.023430941626429558,
      -0.06763181835412979,
      -0.08273222297430038,
      0.0019781459122896194,
      -0.04936479777097702,
      0.09913124144077301,
      -0.042394593358039856,
      -0.013544478453695774,
      0.02283361367881298,
      -0.06648881733417511,
      -0.10968437045812607,
      0.0699077919125557,
      -0.00905974768102169,
      0.006355237681418657,
      0.056657664477825165,
      -0.040112368762493134,
      0.052377134561538696,
      -0.038316767662763596,
      0.047322239726781845,
      -0.033861055970191956,
      -0.010419879108667374,
      0.0009802577551454306,
      0.03063167817890644,
      0.03004932776093483,
      -0.05299001559615135,
      -0.03668289631605148,
      0.10032251477241516,
      -0.06744102388620377,
      0.016199462115764618,
      -0.009799612686038017,
      0.05848168581724167,
      0.056424517184495926,
      0.029620874673128128,
      0.012324519455432892,
      -0.010971322655677795,
      -0.02103271894156933,
      -0.03357402980327606,
      0.1006077453494072,
      0.007187455892562866,
      -0.03246157243847847,
      0.033085670322179794,
      0.04289984703063965,
      -0.08243732899427414,
      -0.05643775314092636,
      -0.04604199528694153,
      0.019385721534490585,
      0.03081286884844303,
      -0.03719485178589821,
      -0.12273792177438736,
      -0.03147820010781288,
      -0.052641864866018295,
      -0.03789724037051201,
      0.0021777446381747723,
      -0.031031174585223198,
      0.05694260448217392,
      0.03658862039446831,
      0.046290308237075806,
      -2.468509046593681e-05,
      0.06291433423757553,
      -0.005388182122260332,
      0.03099246323108673,
      -0.049377914518117905,
      -0.03227916359901428,
      -0.019865326583385468,
      -0.010882236063480377,
      -0.037755392491817474,
      0.005762349348515272,
      0.028240734711289406,
      -0.007800023537129164,
      0.02289600856602192,
      0.048900436609983444,
      0.018960120156407356,
      -0.029618296772241592,
      0.005093018524348736,
      0.035240523517131805,
      -0.05526581406593323,
      -0.050282202661037445,
      -0.012851549312472343,
      -0.014576911926269531,
      -0.04548551142215729,
      -0.07232055813074112,
      0.04093647003173828,
      -0.014502940699458122,
      0.005965725518763065,
      -0.018870197236537933,
      0.009409330785274506,
      0.09763582795858383,
      -0.029204394668340683,
      -0.03349125012755394,
      -0.09564006328582764,
      -0.02008969895541668,
      0.07630079984664917,
      -0.024460311979055405,
      0.016534684225916862,
      -0.007643505465239286,
      0.006844343617558479,
      -0.01984352059662342,
      0.0024736211635172367,
      -0.03182992711663246,
      0.05159498751163483,
      -0.07025893777608871,
      0.012702536769211292,
      -0.07292509078979492,
      0.0275735966861248,
      -0.04807831719517708,
      0.0777842178940773,
      -0.010032043792307377,
      -0.045800961554050446,
      -0.08349743485450745,
      0.017043227329850197,
      0.06073277071118355,
      0.010762324556708336,
      -0.002343861386179924,
      -0.04585440084338188,
      -0.05160703510046005,
      -0.021629761904478073,
      -0.05474633723497391,
      0.02099553309381008,
      -0.0014736606972292066,
      0.03151830658316612,
      -0.002725055906921625,
      -0.11430037021636963,
      -0.030901435762643814,
      0.056567057967185974,
      0.10814038664102554,
      0.022531436756253242,
      0.034382738173007965,
      -0.003520507365465164,
      0.003350113984197378,
      -0.03364107757806778,
      -0.03445582464337349,
      -0.0806867703795433,
      0.08505512028932571,
      0.025867855176329613,
      -0.0008410010486841202,
      0.020849011838436127,
      -0.04210083559155464,
      0.07958107441663742,
      0.04106736183166504,
      -0.008421412669122219,
      0.061546195298433304,
      0.03458907827734947,
      -0.042020995169878006,
      -0.04025166854262352,
      0.049813929945230484,
      0.004045417997986078,
      -0.03774316981434822,
      -0.04397981986403465,
      -0.015095260925590992,
      0.029409315437078476,
      -0.009514734148979187,
      -0.02968469262123108,
      0.030637217685580254,
      -0.04868253320455551,
      -0.03616440296173096,
      -0.021350426599383354,
      -0.012832033447921276,
      0.049009356647729874,
      0.1278323382139206,
      0.014492529444396496,
      0.005382004659622908,
      0.017838504165410995,
      -0.036951057612895966,
      -0.027265960350632668,
      -0.014954942278563976,
      0.0011488733580335975,
      0.041191551834344864,
      -0.03301691263914108,
      -0.0039140041917562485,
      -0.09342543035745621,
      0.015451418235898018,
      0.018759669736027718,
      -0.057215649634599686,
      0.058577705174684525,
      -0.04008530452847481,
      -0.002272274810820818,
      -0.03838811814785004,
      -0.005046343430876732,
      0.06755952537059784,
      -0.01767003908753395,
      -0.01059642806649208,
      0.015488361939787865,
      -0.08014808595180511,
      0.00013267522444948554,
      -0.08839263021945953,
      -0.0030683884397149086,
      -0.06762688606977463,
      0.09149069339036942,
      0.011367691680788994,
      -0.009710545651614666,
      0.014223062433302402,
      -0.05681348592042923,
      -0.009466604329645634,
      -0.07033491134643555,
      -0.05048629641532898,
      -0.00949594471603632,
      0.1393021047115326,
      -0.04091053083539009
    ],
    [
      -0.06630096584558487,
      0.012763435021042824,
      -0.01961437799036503,
      -0.09447797387838364,
      -0.031008606776595116,
      -0.0030232910066843033,
      0.07398708164691925,
      0.0563608817756176,
      0.07008330523967743,
      0.014783366583287716,
      0.007678384892642498,
      -0.05993517115712166,
      0.008133037015795708,
      -0.10181288421154022,
      0.029318802058696747,
      0.0492524579167366,
      -0.05785790830850601,
      0.011281069368124008,
      0.07963835448026657,
      -0.004184013232588768,
      -0.0174154881387949,
      -0.06689827889204025,
      0.025547640398144722,
      -0.00026025690021924675,
      0.027857979759573936,
      -0.03183886036276817,
      -0.02571536973118782,
      0.03302270174026489,
      -0.039259012788534164,
      -0.024716882035136223,
      -0.09552189707756042,
      -0.013623868115246296,
      -0.09561671316623688,
      -0.053128402680158615,
      0.030423030257225037,
      -0.017025044187903404,
      -0.10529795289039612,
      -0.04227825254201889,
      0.00753888301551342,
      -0.06903638690710068,
      0.0693855881690979,
      0.020127423107624054,
      0.0042486186139285564,
      -0.03708668053150177,
      -0.015435492619872093,
      -0.008995631709694862,
      -0.06740274280309677,
      -0.06800784170627594,
      -0.03253590315580368,
      0.018712470307946205,
      0.007012581918388605,
      -0.022848811000585556,
      -0.03127405047416687,
      0.01216915063560009,
      0.007916666567325592,
      -0.000645653810352087,
      -0.048263441771268845,
      -0.018903980031609535,
      0.042759381234645844,
      0.045808080583810806,
      0.02011510729789734,
      0.012742625549435616,
      0.04628204181790352,
      0.06859342008829117,
      0.04685831815004349,
      -0.03754555433988571,
      0.0355534628033638,
      -0.07956009358167648,
      0.02565566636621952,
      0.03589509055018425,
      -0.009690874256193638,
      -0.018843425437808037,
      0.035116735845804214,
      0.006240144371986389,
      0.07524432986974716,
      -0.009459048509597778,
      0.03305838257074356,
      0.01236420776695013,
      0.02971751056611538,
      0.06387748569250107,
      -0.0004124210390727967,
      -0.037558890879154205,
      0.01907438039779663,
      -0.05559229105710983,
      -0.01594379171729088,
      0.013850805349647999,
      -0.057436127215623856,
      0.05020781233906746,
      0.04281473532319069,
      0.04642610251903534,
      0.06594736874103546,
      -0.044164277613162994,
      0.030054014176130295,
      0.027607504278421402,
      -0.08017797023057938,
      -0.10454510897397995,
      -0.005029911175370216,
      -0.008708039298653603,
      0.022227227687835693,
      0.027006646618247032,
      0.01760130189359188,
      -0.024998655542731285,
      0.04199075326323509,
      0.05836950242519379,
      0.04245052486658096,
      -0.03569226339459419,
      0.06705087423324585,
      0.024885324761271477,
      -0.04364772513508797,
      0.0445893220603466,
      0.058091916143894196,
      0.0533829890191555,
      0.0009406155440956354,
      0.007017763331532478,
      0.041191693395376205,
      0.01985931396484375,
      -0.060008250176906586,
      -0.03812684491276741,
      -0.016044627875089645,
      0.035968467593193054,
      -0.010740507394075394,
      0.025557586923241615,
      -0.04925169423222542,
      0.07846144586801529,
      0.016021497547626495,
      -0.02974625863134861,
      0.058298707008361816,
      -0.009682949632406235,
      0.04698043689131737,
      -0.043602585792541504,
      -0.018318751826882362,
      -0.03722774609923363,
      0.07497716695070267,
      0.02170829474925995,
      0.0013017760356888175,
      0.011654219590127468,
      -0.03849378600716591,
      0.004684831947088242,
      0.007042577490210533,
      -0.040665093809366226,
      -0.008582224138081074,
      -0.05190752074122429,
      -0.04907539114356041,
      0.017816223204135895,
      -0.03820928931236267,
      -0.0003219748614355922,
      0.030684158205986023,
      0.051428359001874924,
      0.1473911702632904,
      -0.05122407153248787,
      -0.006628741044551134,
      -0.026790814474225044,
      0.09708651155233383,
      0.00891023688018322,
      0.014141025021672249,
      -0.0037094815634191036,
      -0.07435055077075958,
      0.032054997980594635,
      0.04659436643123627,
      -0.029759040102362633,
      0.016293950378894806,
      -0.03419823572039604,
      -0.0721324011683464,
      0.005857521668076515,
      0.029146593064069748,
      -0.04147421941161156,
      0.045236311852931976,
      -0.00823283102363348,
      0.04419095441699028,
      -0.06291978061199188,
      0.018339427188038826,
      -0.05179451033473015,
      0.013898110017180443,
      -0.021136699244379997,
      0.0005458007217384875,
      0.024017849937081337,
      0.04070412740111351,
      0.053447529673576355,
      -0.12399721145629883,
      0.08900923281908035,
      -0.02247622422873974,
      -0.031406186521053314,
      0.03096841275691986,
      0.029329821467399597,
      -0.052283089607954025,
      -0.003644561627879739,
      0.09609027206897736,
      0.00643180450424552,
      7.712197839282453e-05,
      0.07794103026390076,
      0.036328159272670746,
      -0.06321940571069717,
      -0.03800894692540169,
      -0.03846036270260811,
      -0.0491996593773365,
      -0.0013902186183258891,
      -0.0378568172454834,
      -0.10539829730987549,
      0.041843947023153305,
      -0.05210854858160019,
      0.04634563997387886,
      -0.0355713926255703,
      -0.010135133750736713,
      -0.049935467541217804,
      0.05459942668676376,
      -0.09511351585388184,
      -0.042687129229307175,
      -0.018247904255986214,
      0.08415583521127701,
      -0.034207459539175034,
      -0.03308592736721039,
      -0.00038146795122884214,
      0.01620475947856903,
      0.04474056884646416,
      -0.022229334339499474,
      -0.009682722389698029,
      -0.058510564267635345,
      0.06207965686917305,
      -0.04526366665959358,
      -0.015385432168841362,
      0.005317408591508865,
      -0.023334091529250145,
      -0.052925508469343185,
      0.05037438124418259,
      0.016069035977125168,
      -0.06511569023132324,
      0.023137571290135384,
      0.05052412673830986,
      0.03824446350336075,
      0.009525119327008724,
      -0.04143337160348892,
      0.08229351043701172,
      -0.005643501877784729,
      -0.03413588926196098,
      -0.02224966138601303,
      -0.016394995152950287,
      0.01410625595599413,
      0.09948141872882843,
      -0.08407119661569595,
      0.01144767552614212,
      -0.013124466873705387,
      -0.022409681230783463,
      -0.00917280837893486,
      0.0049875457771122456,
      0.06805115938186646,
      -0.017226925119757652,
      0.03511631488800049,
      -0.038311172276735306,
      0.02163461409509182,
      0.019608931615948677,
      -0.006847527343779802,
      0.013751065358519554,
      0.02542073279619217,
      0.010006573051214218,
      -0.028923336416482925,
      -0.03101632371544838,
      0.0006247248384170234,
      -0.0006140977493487298,
      0.0415472537279129,
      0.04492705315351486,
      -0.055855557322502136,
      0.014216950163245201,
      0.002899578772485256,
      0.005430864170193672,
      0.11045041680335999,
      -0.10783948749303818,
      -0.02256052941083908,
      -0.017604362219572067,
      -0.08769503235816956,
      -0.03210045397281647,
      -0.012302188202738762,
      -0.06805979460477829,
      0.005532892886549234,
      0.04972432926297188,
      0.10259956866502762,
      0.09983153641223907,
      -0.015093477442860603,
      -0.04267934337258339,
      0.015343797393143177,
      0.08759135007858276,
      0.021877767518162727,
      0.0787515863776207,
      -0.034265656024217606,
      -0.006746228318661451,
      -0.011121529154479504,
      -0.045455582439899445,
      -0.051931556314229965,
      0.04020483046770096,
      -0.04601660370826721,
      -0.03588898479938507,
      -0.034371424466371536,
      0.03499523550271988,
      -0.03760494291782379,
      -0.039459750056266785,
      -0.06282596290111542,
      -0.027990836650133133,
      0.04486449807882309,
      0.004911469295620918,
      -0.028590895235538483,
      -0.027337247505784035,
      0.01394814345985651,
      0.026590868830680847,
      0.04367901384830475,
      0.05130496621131897,
      -0.01194777898490429,
      0.009733330458402634,
      -0.01916341669857502,
      0.023145558312535286,
      0.06263834983110428,
      0.006523320451378822,
      0.003662134986370802,
      0.07861510664224625,
      -0.031246213242411613,
      -0.06063346564769745,
      0.06646847724914551,
      0.03574073687195778,
      -0.016629256308078766,
      0.041080426424741745,
      -0.0692167580127716,
      0.007312107365578413,
      -0.04369208589196205,
      -0.011633757501840591,
      0.09354422241449356,
      0.01820540428161621,
      0.010028185322880745,
      -0.009118571877479553,
      -0.01291925273835659,
      -0.047836076468229294,
      -0.04157150536775589,
      0.020245851948857307,
      0.023311495780944824,
      -0.037972159683704376,
      -0.010351176373660564,
      -0.010898050852119923,
      -0.0709768608212471,
      0.09884342551231384,
      0.04933444410562515,
      0.06264269351959229,
      -0.043131135404109955,
      -0.07393380254507065,
      -0.010700942017138004,
      0.07323438674211502,
      -0.05588842183351517,
      0.004756211303174496,
      -0.007744023110717535,
      -0.09320478141307831,
      0.029530098661780357,
      -0.0028483974747359753,
      -0.032065752893686295,
      0.013111633248627186,
      0.01224590465426445,
      0.11339610815048218,
      0.057225700467824936,
      -0.016391977667808533,
      -0.011951585300266743,
      0.021895233541727066,
      -0.03281005844473839,
      0.12130910903215408,
      -0.04831524193286896,
      0.003020051633939147,
      -0.012174471281468868,
      -0.002490400103852153,
      0.025047769770026207,
      -0.01902283914387226,
      0.02049274556338787,
      -0.03832613304257393,
      -0.0678626149892807,
      -0.025356721132993698,
      -0.010339687578380108,
      -0.07060743868350983,
      -0.01499373558908701,
      0.07933763414621353,
      0.01938791759312153,
      -0.034564096480607986,
      0.06398814171552658,
      -0.025912528857588768,
      0.05322899669408798,
      -0.050819918513298035,
      -0.02559361793100834,
      0.014245814643800259,
      -0.034358952194452286,
      -0.045980289578437805,
      -0.04705706983804703,
      0.05161890387535095,
      -0.027076248079538345,
      -0.025804800912737846,
      0.04274844005703926,
      0.11089088022708893,
      -0.02536502107977867,
      -0.023491311818361282,
      -0.005684506148099899,
      -0.0019473652355372906,
      -0.05530301108956337,
      0.030577829107642174,
      0.03531697392463684,
      -0.043658409267663956,
      0.0630190446972847,
      -0.0055198208428919315,
      -0.010154588147997856,
      -0.04403023421764374,
      0.053881119936704636,
      0.06019067019224167,
      -0.023654339835047722,
      0.05949776619672775,
      -0.022841723635792732,
      -0.043908342719078064,
      0.09546143561601639,
      0.02812902070581913,
      0.04486330226063728,
      0.04274159297347069,
      -0.008551869541406631,
      -0.04744895547628403,
      0.040350291877985,
      0.02333182469010353,
      -0.027163859456777573,
      -0.0025446342770010233,
      0.011976155452430248,
      -0.01928171142935753,
      -0.03174220025539398,
      -0.05338791012763977,
      0.020175892859697342,
      0.016984134912490845,
      0.027990402653813362,
      0.06780800223350525,
      -0.03629344329237938,
      -0.025768429040908813,
      -0.031072651967406273,
      -0.015783341601490974,
      -0.051806818693876266,
      0.0330880768597126,
      -0.07305564731359482,
      -0.05968227609992027,
      0.05145830288529396,
      -0.009666383266448975,
      0.010111283510923386,
      0.022194748744368553,
      0.01245842408388853,
      -0.010991904884576797,
      0.061296019703149796,
      0.01928170584142208,
      -0.003222889732569456,
      -0.012792028486728668,
      0.040607500821352005,
      0.023470792919397354,
      -0.024817079305648804,
      -0.03339993953704834,
      -0.01912853494286537,
      0.0049948482774198055,
      -0.037183381617069244,
      0.03430083394050598,
      0.07631494849920273,
      -0.029192248359322548,
      0.031282249838113785,
      -0.02129555121064186,
      -0.0168268121778965,
      -0.048015207052230835,
      -0.07432033121585846,
      0.1193738579750061,
      0.0540161058306694,
      -0.00672250846400857,
      0.002931242575868964,
      -0.008845355361700058,
      -0.013811025768518448,
      -0.04326416552066803,
      0.007807894144207239,
      -0.02911371737718582,
      -0.06163197383284569,
      0.044811882078647614,
      -0.044242482632398605,
      -0.031082022935152054,
      -0.03722407668828964,
      0.06679616868495941,
      0.07802300900220871,
      0.022085782140493393,
      -0.03166940435767174,
      -0.013189932331442833,
      -0.02784022130072117,
      0.008476036600768566,
      0.017008768394589424,
      -0.026643194258213043,
      -0.026475828140974045,
      -0.01640438847243786,
      0.032463137060403824,
      0.12495139241218567,
      -0.014462288469076157,
      -0.052069395780563354,
      -0.03907281160354614,
      -0.04197624698281288,
      0.06298187375068665,
      -0.07829888164997101,
      -0.058682363480329514,
      -0.024714145809412003,
      0.03206267207860947,
      -0.005192030221223831,
      -0.017848819494247437,
      0.0200150478631258,
      -0.06324005126953125,
      0.02678493782877922,
      -0.009616854600608349,
      -0.031002279371023178,
      0.013238305225968361,
      0.047803957015275955,
      -0.0003880161966662854
    ],
    [
      -0.04630434513092041,
      -0.027064690366387367,
      -0.04538537934422493,
      -0.030602244660258293,
      0.020611833781003952,
      0.08013627678155899,
      -0.025519371032714844,
      -0.019276607781648636,
      0.05462358891963959,
      0.03891235962510109,
      -0.022128183394670486,
      -0.04939614608883858,
      -0.021282237023115158,
      -0.031018652021884918,
      0.028571750968694687,
      0.04166887328028679,
      -0.014756272546947002,
      0.053698934614658356,
      -0.08696170896291733,
      -0.022834422066807747,
      -0.04863343387842178,
      -0.057075560092926025,
      -0.06045093014836311,
      0.06690176576375961,
      -0.040499407798051834,
      -0.0045900410041213036,
      0.03664247691631317,
      0.029840534552931786,
      -0.10667772591114044,
      0.004022579174488783,
      -0.01065830048173666,
      -0.07843643426895142,
      0.004256645683199167,
      -0.05561111494898796,
      -0.023714212700724602,
      -0.004694651812314987,
      -0.000910571834538132,
      -0.051203783601522446,
      -0.0010977060301229358,
      0.009467601776123047,
      -0.006221510004252195,
      0.012559567578136921,
      0.03755684196949005,
      0.013464879244565964,
      0.011937140487134457,
      0.059405092149972916,
      -0.009771542623639107,
      0.03779064118862152,
      0.014029969461262226,
      0.0015499527798965573,
      -0.019839966669678688,
      -0.03742867335677147,
      0.009947729296982288,
      0.04672684147953987,
      -0.013550358824431896,
      0.024131255224347115,
      -0.013420800678431988,
      -0.06524482369422913,
      -0.05731163173913956,
      0.020344246178865433,
      -0.01922610029578209,
      -0.0031468388624489307,
      -0.011116893030703068,
      0.05963446944952011,
      0.09216305613517761,
      0.022473473101854324,
      -0.04097229987382889,
      -0.04679622873663902,
      0.013299969956278801,
      0.022885115817189217,
      0.034314483404159546,
      0.0021737348288297653,
      0.027263576164841652,
      0.03039717860519886,
      0.02773101069033146,
      -0.02005842514336109,
      -0.024143386632204056,
      0.08219453692436218,
      0.020664986222982407,
      0.044002845883369446,
      -0.05583271011710167,
      0.02085050195455551,
      0.03207949548959732,
      -0.09472246468067169,
      0.006737129762768745,
      -0.04337313026189804,
      -0.026078613474965096,
      0.05261504277586937,
      0.02067759819328785,
      0.02163078263401985,
      -0.030027247965335846,
      -0.11940473318099976,
      -0.040056828409433365,
      0.003384827869012952,
      0.05301470682024956,
      -0.05086643248796463,
      -0.05751796439290047,
      -0.015247499570250511,
      0.018829485401511192,
      -0.01826552115380764,
      0.019376082345843315,
      0.01633249968290329,
      0.07683133333921432,
      0.050379443913698196,
      0.03501032292842865,
      -0.034232333302497864,
      -0.005915927700698376,
      -0.03466994687914848,
      0.05667802318930626,
      -0.017641326412558556,
      -0.02080465480685234,
      -0.006909030023962259,
      0.039561521261930466,
      0.006573077756911516,
      -0.04380468279123306,
      -0.02014121785759926,
      0.010139948688447475,
      0.048905495554208755,
      0.025185640901327133,
      -0.10902354121208191,
      0.022914070636034012,
      0.021224481984972954,
      -0.05385179445147514,
      -0.06140933558344841,
      -0.02650575153529644,
      0.012498486787080765,
      0.11785836517810822,
      0.04472006857395172,
      -0.021502627059817314,
      -0.02933421917259693,
      -0.04041682183742523,
      0.04146628826856613,
      -0.06981433928012848,
      0.0815371423959732,
      -0.01256195455789566,
      0.03497261181473732,
      -0.006805011536926031,
      0.009303745813667774,
      0.003280952339991927,
      -0.0033816827926784754,
      -0.03618349879980087,
      0.061071671545505524,
      -0.020977385342121124,
      0.045310668647289276,
      -0.03362267091870308,
      -0.03733796253800392,
      -0.06246444210410118,
      0.024390531703829765,
      -0.04412848502397537,
      0.007590664085000753,
      0.005557131487876177,
      0.03748169541358948,
      0.03243386000394821,
      0.06704635918140411,
      -0.015672747045755386,
      -0.03219122812151909,
      -0.024160919710993767,
      0.019982125610113144,
      0.10350408405065536,
      -0.011776595376431942,
      -0.09612078964710236,
      0.01678825542330742,
      -0.03138299286365509,
      -0.04591875523328781,
      0.004746961873024702,
      -0.02885412983596325,
      0.03548865765333176,
      -0.048097167164087296,
      -0.015411614440381527,
      -0.017817361280322075,
      0.023718848824501038,
      0.031882673501968384,
      -0.054184336215257645,
      -0.005232772324234247,
      -0.010225677862763405,
      -0.009553350508213043,
      -0.026682207360863686,
      0.07634139806032181,
      -0.020391685888171196,
      0.09333961457014084,
      0.029062414541840553,
      0.01821993850171566,
      -0.030629929155111313,
      -0.01285683922469616,
      0.023001281544566154,
      -0.01070055365562439,
      -0.03823273628950119,
      0.02737654745578766,
      -0.048238541930913925,
      0.01659281738102436,
      0.010106360539793968,
      -0.01811167597770691,
      0.001440458931028843,
      0.0342448391020298,
      0.016544805839657784,
      0.042479924857616425,
      0.07772377878427505,
      0.008039292879402637,
      -0.048077285289764404,
      -0.0015827190363779664,
      0.0315803624689579,
      -0.030619729310274124,
      0.01461072452366352,
      -0.03887861222028732,
      -0.03818178176879883,
      0.027223261073231697,
      0.013845840469002724,
      0.006587075535207987,
      -0.11046269536018372,
      -0.06837471574544907,
      0.02001083642244339,
      0.0038100024685263634,
      0.034978386014699936,
      0.033597126603126526,
      0.004783348646014929,
      -0.04503590241074562,
      -0.03848893940448761,
      -0.1010078489780426,
      0.012487380765378475,
      -0.04145059734582901,
      0.062499579042196274,
      0.050863511860370636,
      -0.02583209052681923,
      0.0697270855307579,
      -0.015352383255958557,
      0.0016973370220512152,
      0.04555695503950119,
      0.07176301628351212,
      0.09195603430271149,
      -0.022728290408849716,
      0.0014877681387588382,
      -0.027023466303944588,
      0.04575829580426216,
      -0.005089840851724148,
      0.0077361417934298515,
      0.022019464522600174,
      0.02782370336353779,
      -0.022713590413331985,
      0.056649256497621536,
      -0.06266119331121445,
      -0.049430012702941895,
      -0.009351497516036034,
      -0.002201491966843605,
      0.03929528594017029,
      -0.008061300963163376,
      -0.06069370359182358,
      0.036835771054029465,
      0.07668189704418182,
      -0.019280290231108665,
      0.02408450096845627,
      0.009912721812725067,
      0.04495882987976074,
      -0.05208105221390724,
      0.007950027473270893,
      -0.03952024132013321,
      0.023092497140169144,
      0.045422669500112534,
      -0.025599464774131775,
      -0.026007259264588356,
      0.09538178145885468,
      -0.07361052930355072,
      0.05409170314669609,
      -0.0774088129401207,
      0.019282128661870956,
      0.032989442348480225,
      0.04688538983464241,
      -0.00383190275169909,
      0.05390811711549759,
      0.015241522341966629,
      0.017403803765773773,
      0.017059171572327614,
      -0.004352545831352472,
      -0.10445662587881088,
      -0.07950036972761154,
      -0.009801806882023811,
      0.012544839642941952,
      -0.055268544703722,
      -0.07086753845214844,
      0.0015998593298718333,
      0.03271753713488579,
      -0.05646403133869171,
      0.08414193242788315,
      -0.05486636608839035,
      0.0038519282825291157,
      0.04261988028883934,
      -0.018476387485861778,
      -0.040903713554143906,
      -0.12633715569972992,
      -0.005664629861712456,
      -0.07796507328748703,
      0.009880748577415943,
      0.037965647876262665,
      -0.06888387352228165,
      0.03910874202847481,
      -0.01277954038232565,
      -0.030919361859560013,
      0.025786880403757095,
      0.042375415563583374,
      -0.015956906601786613,
      -0.021114276722073555,
      -0.0410262793302536,
      -0.006101814564317465,
      -0.047280989587306976,
      0.04964158311486244,
      -0.08219008147716522,
      -0.02269333228468895,
      0.02337363362312317,
      0.034302957355976105,
      -0.02130110003054142,
      -0.03031730093061924,
      -0.018231593072414398,
      0.009055822156369686,
      -0.06537967175245285,
      0.12566488981246948,
      0.025553511455655098,
      -0.002750259591266513,
      0.020468177273869514,
      -0.03970179334282875,
      -0.019407914951443672,
      -0.0048375423066318035,
      -0.0706070140004158,
      0.014824730344116688,
      0.05996987596154213,
      0.027352584525942802,
      -0.022266875952482224,
      0.041815321892499924,
      0.042919229716062546,
      -0.07112715393304825,
      -0.03197119012475014,
      0.07290053367614746,
      -0.04128127172589302,
      0.039812829345464706,
      -0.027190234512090683,
      0.0025171712040901184,
      0.02701457031071186,
      -0.04624253883957863,
      0.11398394405841827,
      0.009094004519283772,
      0.008624681271612644,
      -0.041411593556404114,
      -0.04274509847164154,
      -0.02445615828037262,
      0.023394037038087845,
      0.007855269126594067,
      -0.016702521592378616,
      0.021697496995329857,
      0.01060327235609293,
      0.02846653200685978,
      -0.00945497676730156,
      -0.0022089260164648294,
      -0.05226650834083557,
      -0.035980306565761566,
      -0.03774242848157883,
      0.008602458983659744,
      0.03486498445272446,
      -0.035851024091243744,
      0.0607876218855381,
      0.09224259108304977,
      0.034669000655412674,
      -0.03385702893137932,
      0.022805672138929367,
      0.08172126114368439,
      0.019343972206115723,
      0.02162800543010235,
      -0.004123356193304062,
      -0.041028108447790146,
      -0.012845171615481377,
      -0.023641973733901978,
      -0.009411925449967384,
      0.0692628026008606,
      -0.01133857574313879,
      0.04193389415740967,
      -0.0231451578438282,
      -0.04062512144446373,
      -0.038582611829042435,
      0.07320041954517365,
      0.042931828647851944,
      0.03526071459054947,
      -0.054415009915828705,
      -0.035354312509298325,
      -0.07459424436092377,
      -0.06250326335430145,
      0.02989785745739937,
      0.02363365702331066,
      -0.013285361230373383,
      -0.03658875450491905,
      0.007906751707196236,
      0.005794728174805641,
      0.02564571611583233,
      -0.05832513049244881,
      0.02625209093093872,
      0.01799888350069523,
      -0.04897681251168251,
      0.09597492963075638,
      -0.047389090061187744,
      -0.031225979328155518,
      0.02007439360022545,
      0.010867697186768055,
      -0.012811104767024517,
      -0.03790150210261345,
      -0.04054591432213783,
      0.02607313171029091,
      0.03576704487204552,
      0.03220126032829285,
      0.0017032274045050144,
      -0.056882113218307495,
      0.035303663462400436,
      -0.008057625964283943,
      0.03517184779047966,
      0.06144002079963684,
      -0.03533355891704559,
      0.021855702623724937,
      -0.003015330294147134,
      0.019122818484902382,
      0.027073515579104424,
      0.018845630809664726,
      0.13283689320087433,
      0.021369609981775284,
      -0.05643776059150696,
      -0.09130772203207016,
      -0.02528827078640461,
      -0.00708107091486454,
      -0.07132253050804138,
      -0.003914762753993273,
      -0.02931300178170204,
      0.034043002873659134,
      0.006468089297413826,
      -0.019999412819743156,
      0.05459202080965042,
      -0.0005967435427010059,
      -0.037387095391750336,
      -0.015622441656887531,
      -0.014403353445231915,
      -0.02141864225268364,
      -0.13838747143745422,
      -0.01412903144955635,
      -0.021298756822943687,
      -0.028542891144752502,
      0.0011373588349670172,
      0.0615384541451931,
      -0.00990027841180563,
      0.06391741335391998,
      -0.033287011086940765,
      0.00021699577337130904,
      0.06019667163491249,
      -0.03350512310862541,
      0.0028465185314416885,
      -0.04765135422348976,
      -0.0675964280962944,
      0.028361963108181953,
      -0.01787831448018551,
      0.05669645220041275,
      0.09021877497434616,
      0.048280347138643265,
      0.024017496034502983,
      -0.024585606530308723,
      0.007305340841412544,
      0.06463994830846786,
      0.04789385572075844,
      -0.012984024360775948,
      0.013626892119646072,
      -0.02877916395664215,
      0.08931127935647964,
      0.005572944413870573,
      -0.005614901427179575,
      0.018397437408566475,
      0.001124260132201016,
      -0.06632895767688751,
      0.023882102221250534,
      -0.03920161724090576,
      -0.0003348230675328523,
      0.04207560792565346,
      -0.08985577523708344,
      -0.09864528477191925,
      -0.032216861844062805,
      -0.0490095354616642,
      0.04580606892704964,
      0.0711570605635643,
      -0.06549537926912308,
      -0.02730744518339634,
      0.004927162081003189,
      -0.022690312936902046,
      -0.032866206020116806,
      0.05178875848650932,
      0.03005359321832657,
      -0.01337733119726181,
      0.02029958739876747,
      -0.030114123597741127,
      0.019412023946642876,
      -0.10078708082437515,
      0.04796547442674637,
      0.015613947995007038,
      0.018102549016475677,
      -0.005540879908949137,
      -0.06438697874546051,
      -0.039587583392858505,
      -0.007445913273841143,
      0.04406173154711723,
      -0.06491458415985107,
      -0.08002682030200958,
      -0.08699032664299011,
      -0.02416512556374073,
      0.04809132590889931
    ],
    [
      -0.05138577148318291,
      0.009876823984086514,
      -0.039049845188856125,
      -0.021439725533127785,
      -0.0016161808744072914,
      0.0028595973271876574,
      -0.0010737149277701974,
      -0.04996179789304733,
      -0.05171993747353554,
      0.0249452106654644,
      0.05728287249803543,
      0.05097775533795357,
      -0.031077010557055473,
      -0.05622636154294014,
      -0.016885681077837944,
      0.02734118141233921,
      -0.0006503968616016209,
      -0.022977693006396294,
      0.1355637013912201,
      0.015916530042886734,
      -0.1096428856253624,
      0.038865044713020325,
      -0.03357983008027077,
      0.004247874952852726,
      0.005969662684947252,
      -0.04838934540748596,
      -0.06540991365909576,
      -0.00945355650037527,
      0.02274765819311142,
      0.032347142696380615,
      -0.008005798794329166,
      0.03361467644572258,
      -0.04124879091978073,
      -0.09265892207622528,
      -0.024658430367708206,
      0.050172604620456696,
      0.08015674352645874,
      -0.10343163460493088,
      -0.00322962598875165,
      0.02044639363884926,
      0.04467108100652695,
      -0.05463515222072601,
      -0.02136933244764805,
      -0.007844793610274792,
      -0.024798009544610977,
      -0.027541184797883034,
      0.023166870698332787,
      -0.040022753179073334,
      -0.022891085594892502,
      -0.01681508496403694,
      -0.01966153457760811,
      0.03670884296298027,
      0.021311812102794647,
      0.008906419388949871,
      -0.04241489619016647,
      -0.0028249071910977364,
      -0.033279307186603546,
      -0.09752345830202103,
      -0.003005912294611335,
      -0.07342301309108734,
      -0.000990493455901742,
      0.03334449976682663,
      0.02639881521463394,
      0.042299073189496994,
      0.010176059789955616,
      0.0213751383125782,
      0.03901808708906174,
      0.0888935774564743,
      0.04884454980492592,
      0.05653230845928192,
      -0.08137025684118271,
      0.043226439505815506,
      0.009013279341161251,
      -0.046115875244140625,
      -0.05477418750524521,
      0.05375184863805771,
      0.07861679792404175,
      0.03641339763998985,
      -0.037359241396188736,
      -0.022262081503868103,
      0.042139120399951935,
      -0.05073586478829384,
      -0.013023222796618938,
      0.02745164930820465,
      -0.013112016953527927,
      -0.00018978722800966352,
      0.01261152420192957,
      0.04872588813304901,
      0.002428206382319331,
      -0.050548240542411804,
      0.002036789432168007,
      -0.001117319450713694,
      -0.007681448012590408,
      0.03557979688048363,
      -0.010923575609922409,
      0.01293625496327877,
      -0.05524437129497528,
      0.07433570176362991,
      -0.02611595392227173,
      -0.052738018333911896,
      -0.09508763998746872,
      0.029839567840099335,
      0.06600503623485565,
      0.007640322204679251,
      -0.02712712623178959,
      0.02814633399248123,
      0.009594926610589027,
      0.007484682835638523,
      0.04353053495287895,
      -0.013826965354382992,
      0.024310490116477013,
      -0.025119168683886528,
      -0.04471544176340103,
      0.012325339950621128,
      0.06587954610586166,
      0.006478010211139917,
      0.0024986998178064823,
      0.04267801344394684,
      -0.03591843694448471,
      0.04195499047636986,
      0.03605102747678757,
      -0.06631991267204285,
      0.017125803977251053,
      0.040674902498722076,
      -0.006423038896173239,
      -0.03284811973571777,
      0.057529594749212265,
      -0.0008410486625507474,
      0.0048337471671402454,
      0.01161994319409132,
      -0.012418990023434162,
      -0.04448952525854111,
      0.04682505130767822,
      0.01940043829381466,
      -0.02368861436843872,
      0.023802924901247025,
      0.07053493708372116,
      0.009799743071198463,
      0.051559314131736755,
      0.0037405204493552446,
      0.02076628990471363,
      0.0037306882441043854,
      -0.08418793231248856,
      -0.005295380484312773,
      -0.0070804511196911335,
      0.03410940617322922,
      -0.0013707041507586837,
      0.10814523696899414,
      0.03509046882390976,
      0.008483987301588058,
      0.021337756887078285,
      0.1275942325592041,
      0.015075277537107468,
      0.009821833111345768,
      -0.08458951860666275,
      0.0667560026049614,
      -0.01952921226620674,
      0.00881929975003004,
      0.015187272801995277,
      -0.05125615373253822,
      -0.018003303557634354,
      -0.046412985771894455,
      -0.007673464249819517,
      -0.013714347966015339,
      0.03735049441456795,
      -0.0724751427769661,
      0.03679252043366432,
      -0.014758013188838959,
      0.06320421397686005,
      -0.009840871207416058,
      0.02357792854309082,
      0.03187272325158119,
      -0.0458749383687973,
      -0.0355188362300396,
      0.01571284979581833,
      0.009661538526415825,
      -0.02546810172498226,
      0.0033265843521803617,
      -0.04106724262237549,
      0.03698571026325226,
      -0.02303328737616539,
      0.010512054897844791,
      0.04820572957396507,
      0.02343461848795414,
      -0.0583236962556839,
      -0.045675601810216904,
      -0.06566496193408966,
      0.02222987823188305,
      0.02208571881055832,
      -0.014914228580892086,
      -0.04451006278395653,
      0.01762249879539013,
      0.0040830583311617374,
      0.00718578090891242,
      -0.014091101475059986,
      -0.024272602051496506,
      -0.049556173384189606,
      0.0097212428227067,
      0.07308755069971085,
      0.013148642145097256,
      -0.039331886917352676,
      -0.011643670499324799,
      0.0100786704570055,
      -0.04919250309467316,
      0.008108924143016338,
      -0.05859701707959175,
      0.024183131754398346,
      -0.01980251632630825,
      -0.007111974060535431,
      -0.08983505517244339,
      -0.05045061931014061,
      -0.05370176210999489,
      0.03563321754336357,
      0.09168589860200882,
      -0.0673348531126976,
      0.056710824370384216,
      0.015260745771229267,
      0.0027044538874179125,
      0.01517236977815628,
      0.010099858045578003,
      0.04902784898877144,
      0.013687023892998695,
      -0.002143275924026966,
      0.0007659654947929084,
      -0.00761058833450079,
      -0.07392372190952301,
      0.024819720536470413,
      -0.004394521936774254,
      -0.030504535883665085,
      0.023938994854688644,
      -0.016043175011873245,
      0.03187846392393112,
      0.12145286798477173,
      -0.05203041061758995,
      -0.010669147595763206,
      -0.08052517473697662,
      -0.008230670355260372,
      0.06519804894924164,
      -0.07073629647493362,
      -0.003057476133108139,
      0.03665716201066971,
      0.08484488725662231,
      0.09116335958242416,
      0.016929002478718758,
      0.07026156783103943,
      0.021160906180739403,
      -0.0455043651163578,
      -0.010850527323782444,
      0.00685246754437685,
      0.010758570395410061,
      -0.03662992641329765,
      0.03053085319697857,
      0.03202344849705696,
      -0.08097054064273834,
      -0.008132015354931355,
      -0.008016109466552734,
      -0.019501375034451485,
      0.012518203817307949,
      0.0008950333576649427,
      -0.004369610920548439,
      -0.01953192800283432,
      0.0313318595290184,
      -0.014430558308959007,
      0.02088589407503605,
      0.01150480005890131,
      0.02595740370452404,
      -0.05599469318985939,
      0.1103515774011612,
      0.04432032257318497,
      0.07252789288759232,
      0.06416907906532288,
      0.04127249866724014,
      0.09598708152770996,
      0.06380777060985565,
      0.052135203033685684,
      0.04022114723920822,
      -0.06959618628025055,
      -0.07767397165298462,
      -0.02339405007660389,
      -0.07452064007520676,
      0.009961399249732494,
      -0.005201419349759817,
      -0.05078516900539398,
      0.00839188601821661,
      -0.016275502741336823,
      0.07601163536310196,
      -0.07121945172548294,
      0.025019725784659386,
      0.03188259154558182,
      0.09332755208015442,
      0.01900075003504753,
      0.04805285111069679,
      0.016556575894355774,
      0.0022546492982655764,
      -0.005757738370448351,
      0.012065229006111622,
      0.08492614328861237,
      0.028529465198516846,
      0.027009254321455956,
      0.06414194405078888,
      -0.03442445024847984,
      0.03812307119369507,
      -0.010058283805847168,
      -0.014288777485489845,
      -0.06833624839782715,
      -0.03277779743075371,
      0.07377634942531586,
      0.06836164742708206,
      0.052251171320676804,
      -0.06926007568836212,
      -0.05002620071172714,
      0.015735415741801262,
      -0.0038235397078096867,
      0.051675811409950256,
      -0.0035386779345571995,
      -0.03724218159914017,
      -0.024019524455070496,
      0.022512495517730713,
      -0.005303471814841032,
      0.02744167111814022,
      -0.014437922276556492,
      0.05222146213054657,
      -0.0745808482170105,
      0.09735243022441864,
      -0.03391680493950844,
      0.07917477190494537,
      0.007758628576993942,
      0.00322827510535717,
      0.019264457747340202,
      0.05828023701906204,
      0.009070998057723045,
      0.040488824248313904,
      0.02579738013446331,
      0.020358098670840263,
      0.011190569028258324,
      0.01830385811626911,
      -0.02415509708225727,
      -0.049647703766822815,
      -0.06690464168787003,
      -0.037727389484643936,
      0.030167926102876663,
      -0.020639469847083092,
      -0.014685838483273983,
      -0.0032728242222219706,
      0.0031602871604263783,
      0.02746049501001835,
      0.07128170877695084,
      -0.03480292856693268,
      -0.024336516857147217,
      0.06625546514987946,
      -0.05895044654607773,
      0.01364496722817421,
      -0.036971092224121094,
      -0.06209997087717056,
      -0.056315336376428604,
      -0.03857196122407913,
      0.0005424023838713765,
      0.033478036522865295,
      -0.011017019860446453,
      0.04856555536389351,
      -0.0005363266682252288,
      0.06945116817951202,
      0.03388655185699463,
      -0.016016483306884766,
      0.006110928952693939,
      -0.08039329946041107,
      -0.009861191734671593,
      0.038937367498874664,
      -0.015282193198800087,
      0.03820345550775528,
      -0.025197617709636688,
      -0.03337192162871361,
      0.030969839543104172,
      0.01565849594771862,
      -0.016432715579867363,
      0.06933730840682983,
      0.025594044476747513,
      -0.09663284569978714,
      -0.0028848086949437857,
      -0.016747906804084778,
      0.006389003247022629,
      -0.041505202651023865,
      0.0020268952939659357,
      -0.00780914630740881,
      0.05614055320620537,
      0.007368824444711208,
      0.00751855131238699,
      -0.00029076958890073,
      -0.005466307047754526,
      -0.01502707228064537,
      0.05174374207854271,
      -0.04665128141641617,
      0.01716950722038746,
      0.013430745340883732,
      -0.11792384088039398,
      -0.05344303697347641,
      0.05323396623134613,
      0.013745634816586971,
      0.004557181615382433,
      0.018748125061392784,
      0.08248605579137802,
      0.01747424341738224,
      -0.01763004995882511,
      0.030313633382320404,
      -0.0012944458285346627,
      -0.014764809980988503,
      0.01718127354979515,
      -0.06106090545654297,
      0.11230240762233734,
      0.042989034205675125,
      0.014048031531274319,
      0.02555616945028305,
      0.034024376422166824,
      0.003103588242083788,
      0.044798001646995544,
      0.03515762463212013,
      0.009838119149208069,
      -0.08825460076332092,
      7.143734546843916e-05,
      -0.021507686004042625,
      -0.010229377076029778,
      -0.04279042407870293,
      -0.010783538222312927,
      -0.04108197242021561,
      0.07921791076660156,
      -0.012947991490364075,
      0.04063249006867409,
      -0.04546919837594032,
      0.060168854892253876,
      0.0790085569024086,
      0.07168937474489212,
      0.031220655888319016,
      -0.04070398956537247,
      0.03710097074508667,
      0.009144043549895287,
      -0.05856158211827278,
      -0.0183402132242918,
      -0.06230025738477707,
      0.09706692397594452,
      0.11269375681877136,
      -0.040488746017217636,
      -0.03626405447721481,
      -0.03691558167338371,
      0.03549846261739731,
      0.0353575199842453,
      -0.012232951819896698,
      -0.035653311759233475,
      -0.03532116487622261,
      0.032751623541116714,
      0.02016322873532772,
      -0.06611677259206772,
      -0.01086673978716135,
      0.0796084851026535,
      0.017386524006724358,
      -0.025912731885910034,
      -0.04003506898880005,
      0.011259651742875576,
      -0.07431268692016602,
      -0.008858396671712399,
      -0.02406039461493492,
      -0.08130842447280884,
      -0.05424168333411217,
      -0.0016673360951244831,
      0.013293541967868805,
      0.0616377592086792,
      0.06557561457157135,
      -0.11436410993337631,
      0.06166467443108559,
      -0.02935016341507435,
      -0.09323704242706299,
      0.00915339682251215,
      -0.002211975632235408,
      -0.010686307214200497,
      -0.020710395649075508,
      0.05784084275364876,
      -0.08048198372125626,
      0.020954031497240067,
      -0.06551054120063782,
      -0.02129521407186985,
      0.006173880770802498,
      -0.017808379605412483,
      0.010276430286467075,
      -0.02948581799864769,
      0.014273777604103088,
      0.0015128798549994826,
      -0.045366071164608,
      0.009866044856607914,
      0.031182343140244484,
      -0.049786459654569626,
      0.04780382663011551,
      -0.037051972001791,
      0.11984744668006897,
      0.07146774977445602,
      -0.007049653213471174,
      0.04862040653824806,
      -0.09458423405885696,
      -0.0034864700865000486,
      0.04036445915699005,
      0.001315619214437902,
      -0.07092442363500595,
      -0.004024624824523926,
      -0.07176709175109863,
      0.0018539098091423512
    ],
    [
      -0.09468697011470795,
      0.010917491279542446,
      0.05908272787928581,
      0.010794633068144321,
      0.03049463778734207,
      0.02320045419037342,
      -0.008436005562543869,
      -0.040893808007240295,
      -0.05216587334871292,
      0.027133526280522346,
      -0.039888303726911545,
      0.032072849571704865,
      -0.00828692875802517,
      -0.009795183315873146,
      -0.03420555219054222,
      0.003232249291613698,
      -0.018436264246702194,
      -0.002149248030036688,
      -0.08802769333124161,
      0.0308236014097929,
      0.0852542594075203,
      0.04368460550904274,
      -0.017836738377809525,
      -0.006211018655449152,
      -0.016896501183509827,
      0.042675189673900604,
      -0.01501007191836834,
      0.06228905916213989,
      0.02515544928610325,
      0.06299390643835068,
      -0.04890773817896843,
      -0.037894852459430695,
      0.08429165929555893,
      -0.01053089089691639,
      0.00958017073571682,
      -0.012853369116783142,
      0.012517198920249939,
      -0.03522172197699547,
      -0.034388236701488495,
      -0.016172073781490326,
      0.048379119485616684,
      -0.027136558666825294,
      0.05354221910238266,
      -0.021322445943951607,
      -0.0640873834490776,
      -0.07329989224672318,
      0.03219492360949516,
      0.023520877584815025,
      0.003354262560606003,
      -0.019708100706338882,
      0.006129552144557238,
      -0.017567360773682594,
      0.020901257172226906,
      0.045370180159807205,
      -0.07867398113012314,
      0.054330937564373016,
      -0.059150416404008865,
      -0.050518810749053955,
      -0.0290475282818079,
      0.04014305770397186,
      -0.00886443816125393,
      -0.014627188444137573,
      -0.03559316694736481,
      -0.011618991382420063,
      0.048890676349401474,
      0.03473362699151039,
      -0.0631108433008194,
      -0.11440274864435196,
      0.05822901800274849,
      -0.0009599493350833654,
      0.1151178777217865,
      0.043464113026857376,
      -0.009226099587976933,
      0.028416374698281288,
      -0.04660685360431671,
      0.04760419949889183,
      -0.031002558767795563,
      -0.051951032131910324,
      0.005977679509669542,
      -0.04251178726553917,
      -0.08853089064359665,
      0.018505999818444252,
      0.06387857347726822,
      -0.029057476669549942,
      -0.04826723784208298,
      0.01609043963253498,
      -0.04741588979959488,
      -0.006328371819108725,
      -0.0372377410531044,
      0.05199244245886803,
      -0.038886670023202896,
      -0.04772305488586426,
      0.03640013560652733,
      -0.0694560781121254,
      -0.037926796823740005,
      0.007660922594368458,
      -0.07398980855941772,
      -0.009843866340816021,
      -0.05555405467748642,
      0.020292388275265694,
      -0.011824717745184898,
      -0.13923701643943787,
      0.03251078724861145,
      0.05737004056572914,
      -0.044831328094005585,
      0.015166160650551319,
      -0.020239990204572678,
      0.08233346790075302,
      0.08141645044088364,
      0.01656283438205719,
      -0.04581168293952942,
      -0.021464107558131218,
      -0.02497006021440029,
      0.015650082379579544,
      -0.08085265010595322,
      0.010448016226291656,
      0.014578028582036495,
      0.039914097636938095,
      -0.010259013622999191,
      -0.041504014283418655,
      0.02489328943192959,
      -0.024965766817331314,
      -0.06306646764278412,
      0.03922504559159279,
      0.07032020390033722,
      -0.0441000871360302,
      0.007791920565068722,
      -0.008624937385320663,
      0.045218948274850845,
      -0.009792091324925423,
      -0.054085761308670044,
      -0.006118696182966232,
      0.02218296192586422,
      -0.017329437658190727,
      0.028478378430008888,
      0.03645838424563408,
      0.06398946791887283,
      0.027508018538355827,
      0.011890312656760216,
      -0.035531528294086456,
      0.004346347413957119,
      -0.008825472556054592,
      0.04639703035354614,
      -0.0063500674441456795,
      0.0804668515920639,
      -0.02212587371468544,
      -0.05515163764357567,
      -0.017164526507258415,
      -0.029154974967241287,
      0.06921303272247314,
      -0.03273451328277588,
      0.022203786298632622,
      0.05355290696024895,
      -0.05164603143930435,
      0.03980579972267151,
      -0.08500821143388748,
      0.05205976590514183,
      -0.005228258203715086,
      0.013208973221480846,
      0.008984479121863842,
      -0.04536846652626991,
      0.0500224269926548,
      0.037202026695013046,
      0.055545561015605927,
      0.025234634056687355,
      -0.018557103350758553,
      -0.01876917853951454,
      -0.00797833502292633,
      0.030544860288500786,
      -0.04877094179391861,
      0.0624300017952919,
      -0.0742218941450119,
      -0.07005848735570908,
      0.031902015209198,
      0.03703399747610092,
      -0.07297107577323914,
      -0.02486603520810604,
      -0.03847947716712952,
      0.03582679480314255,
      -0.020135952159762383,
      -0.02046480029821396,
      0.056021880358457565,
      -0.02834835648536682,
      -0.009288989007472992,
      0.034379687160253525,
      -0.054911356419324875,
      0.058732107281684875,
      -0.006652328185737133,
      -0.03171168640255928,
      0.025280185043811798,
      0.04166892543435097,
      0.04283038526773453,
      0.020815884694457054,
      -0.0042810444720089436,
      0.08901010453701019,
      -0.030008988454937935,
      0.023665299639105797,
      -0.012367159128189087,
      0.029688173905014992,
      0.019148385152220726,
      0.010248138569295406,
      0.0414777509868145,
      -0.05943894013762474,
      0.029597388580441475,
      -0.037589918822050095,
      -0.016517670825123787,
      0.09158491343259811,
      -0.013951785862445831,
      0.01042740698903799,
      0.04318580403923988,
      0.05402921140193939,
      0.01686675101518631,
      -0.015787184238433838,
      0.08535467088222504,
      0.020502397790551186,
      -0.03602031245827675,
      -0.0612686388194561,
      0.032113734632730484,
      0.029627900570631027,
      -0.01606261171400547,
      0.026553547009825706,
      0.0037944018840789795,
      0.04542640224099159,
      0.05486755445599556,
      0.016607707366347313,
      0.05059404298663139,
      0.024900149554014206,
      -0.022472012788057327,
      0.014753231778740883,
      0.007198739796876907,
      -0.06258454918861389,
      -0.056210990995168686,
      0.06879757344722748,
      -0.03260574862360954,
      0.0024994704872369766,
      -0.07598329335451126,
      -0.031081808730959892,
      -0.06427282094955444,
      0.008538791909813881,
      0.03694821521639824,
      -0.06778670102357864,
      -0.06488210707902908,
      -0.005594274494796991,
      -0.06938976049423218,
      0.023261649534106255,
      0.036391861736774445,
      0.0005563318845815957,
      -0.06208263337612152,
      -0.03406595438718796,
      0.02921360172331333,
      0.09130576252937317,
      -0.048559803515672684,
      -0.004802822135388851,
      -0.0022586823906749487,
      0.02139940857887268,
      0.05898261070251465,
      0.013899201527237892,
      0.09824158996343613,
      -0.03322913125157356,
      0.00041207813774235547,
      0.08199141919612885,
      0.08528019487857819,
      0.0023357137106359005,
      0.07181944698095322,
      0.026663994416594505,
      -0.036680806428194046,
      -0.06853147596120834,
      0.026577280834317207,
      -0.04278034716844559,
      0.03440868854522705,
      -0.10014323145151138,
      -0.03486113250255585,
      -0.05819542706012726,
      0.07428685575723648,
      -0.05148792266845703,
      -0.04911889508366585,
      0.009209465235471725,
      -0.09147518873214722,
      0.07022672891616821,
      0.06221964582800865,
      -0.01941821537911892,
      0.05036374554038048,
      0.03681717813014984,
      0.03688061237335205,
      0.033318035304546356,
      -0.008453156799077988,
      0.05017818510532379,
      -0.044475968927145004,
      -0.10985396057367325,
      -0.007451521698385477,
      -0.02683081477880478,
      0.07833753526210785,
      -0.06033816188573837,
      -0.025591274723410606,
      0.04731717333197594,
      -0.03553381189703941,
      -0.011799217201769352,
      -0.08998110890388489,
      -0.06352999806404114,
      -0.06343571096658707,
      0.029542583972215652,
      -0.009204696863889694,
      -0.03684920817613602,
      -0.02283650077879429,
      -0.027119863778352737,
      -0.023816440254449844,
      -0.06446757912635803,
      -0.006194250658154488,
      0.01966005191206932,
      -0.009049052372574806,
      0.04255365952849388,
      -0.059812840074300766,
      -0.018948841840028763,
      -0.026854172348976135,
      0.041068900376558304,
      -0.06815952807664871,
      0.006265798583626747,
      -0.028113557025790215,
      0.017829179763793945,
      0.015485134907066822,
      0.03879483789205551,
      0.053516633808612823,
      0.02058812789618969,
      0.016709202900528908,
      0.0885327085852623,
      -0.06248261779546738,
      0.03420301526784897,
      0.012289137579500675,
      0.026337360963225365,
      0.10107018053531647,
      0.0023500698152929544,
      0.03609544411301613,
      -0.02101023867726326,
      -0.039606641978025436,
      -0.02671312727034092,
      0.019319431856274605,
      0.056642044335603714,
      -0.0332275815308094,
      0.0015180996851995587,
      -0.07353020459413528,
      -0.09649545699357986,
      0.009506440721452236,
      0.016452325507998466,
      -0.005108904559165239,
      0.05496220290660858,
      0.04933113232254982,
      0.07508933544158936,
      -0.046430718153715134,
      -0.05459306389093399,
      -0.04768078401684761,
      -0.09225200861692429,
      -0.0743190348148346,
      0.030998030677437782,
      0.0342787504196167,
      -0.009319540113210678,
      0.013818599283695221,
      -0.07470079511404037,
      -0.04152083024382591,
      0.03228828310966492,
      -0.004491108004003763,
      -0.07599718868732452,
      -0.10129953920841217,
      0.03694765269756317,
      0.022106902673840523,
      -0.05219181254506111,
      -0.02299163118004799,
      -0.022089209407567978,
      -0.020970411598682404,
      0.02890090085566044,
      0.055461153388023376,
      0.051690030843019485,
      0.11317839473485947,
      0.060620516538619995,
      -0.01991136372089386,
      -0.05216240510344505,
      0.044585973024368286,
      -0.004888748284429312,
      0.010595983825623989,
      0.07628177851438522,
      0.1027558445930481,
      0.05302853137254715,
      -0.05182038992643356,
      0.004908367060124874,
      0.005891096778213978,
      -0.04261701554059982,
      -0.03581438213586807,
      0.008811643347144127,
      0.010519877076148987,
      0.012254633940756321,
      0.031428202986717224,
      -0.025737324729561806,
      0.04261163994669914,
      -0.02404407598078251,
      -0.05766117945313454,
      0.05840352550148964,
      -0.07445036619901657,
      -0.04137382283806801,
      -0.049175627529621124,
      0.003969814628362656,
      0.021897640079259872,
      0.009089295752346516,
      0.023523101583123207,
      -0.05571357160806656,
      -0.04340505227446556,
      0.04822934418916702,
      -0.002749576000496745,
      0.03866856172680855,
      -0.07240964472293854,
      -0.007705801632255316,
      -0.03295939788222313,
      -0.03713374212384224,
      0.05873970314860344,
      -0.012784954160451889,
      -0.06970315426588058,
      0.005908639170229435,
      0.060476165264844894,
      0.017429962754249573,
      -0.020649772137403488,
      0.10306023061275482,
      -0.012164255604147911,
      -0.03206009417772293,
      -0.01400195062160492,
      -0.012859243899583817,
      0.062306035310029984,
      0.04146060720086098,
      -0.01145907025784254,
      0.06227635592222214,
      -0.05657846853137016,
      0.031306713819503784,
      -0.030523287132382393,
      0.0555344820022583,
      0.08905408531427383,
      -0.0741778314113617,
      -0.0013416772708296776,
      -0.00014667540381196886,
      0.054525312036275864,
      -0.06743082404136658,
      0.015573187731206417,
      -0.09743107110261917,
      0.07023361325263977,
      -0.06481695175170898,
      -0.024680152535438538,
      0.05676991865038872,
      0.07533055543899536,
      -0.009366868063807487,
      0.009456740692257881,
      0.01130934152752161,
      0.007725762668997049,
      -0.042375825345516205,
      -0.019203536212444305,
      -0.009096944704651833,
      0.04226071387529373,
      0.07324886322021484,
      0.043366678059101105,
      -0.03728625178337097,
      -0.009691257029771805,
      0.03855559974908829,
      0.10629760473966599,
      -0.0014514296781271696,
      0.014904032461345196,
      -0.03607601672410965,
      -0.005063745658844709,
      -0.02382337860763073,
      -0.046330273151397705,
      -0.041984833776950836,
      0.06692294776439667,
      -0.028350086882710457,
      -0.01826292648911476,
      0.02322414144873619,
      -0.04476778209209442,
      0.0522044375538826,
      -0.028761643916368484,
      -0.021730879321694374,
      0.02011222206056118,
      0.055231399834156036,
      -0.0034126192331314087,
      0.05301668494939804,
      0.05764269828796387,
      0.04313778132200241,
      -0.042756207287311554,
      -0.029261302202939987,
      0.04970920830965042,
      0.01652776449918747,
      0.0332394503057003,
      0.02082086354494095,
      0.0034431107342243195,
      -0.031553078442811966,
      0.05643373727798462,
      -0.009635944850742817,
      -0.020836584270000458,
      -0.02552003413438797,
      -0.10267309844493866,
      0.10995256900787354,
      0.042601075023412704,
      -0.08207739889621735,
      0.048777986317873,
      -0.008071928285062313,
      0.028801433742046356,
      0.060656119138002396,
      0.00022286396415438503,
      0.025343501940369606,
      0.02961765043437481,
      -0.04954616725444794
    ],
    [
      -0.027950681746006012,
      -0.020801810547709465,
      0.11305920779705048,
      -0.08343110978603363,
      -0.014707595109939575,
      -0.04889021813869476,
      0.028622876852750778,
      0.032961513847112656,
      0.08621382713317871,
      -0.0591435581445694,
      0.02279409021139145,
      -0.006501996889710426,
      0.07232929021120071,
      0.06932325661182404,
      0.04721706733107567,
      -0.06022166088223457,
      -0.001489397487603128,
      -0.05564551800489426,
      0.0100224819034338,
      0.01895706169307232,
      -0.016334235668182373,
      -0.03820439800620079,
      -0.009970064274966717,
      0.009353541769087315,
      -0.06627680361270905,
      -0.04896024614572525,
      0.023248545825481415,
      0.06730260699987411,
      0.023572679609060287,
      -0.014599990099668503,
      -0.0018843722064048052,
      0.07426541298627853,
      -0.003867865540087223,
      -0.03758084774017334,
      0.06429851055145264,
      0.01638110913336277,
      0.08917263150215149,
      -0.061190664768218994,
      -0.07740607857704163,
      -0.021122490987181664,
      -0.011478174477815628,
      0.008758427575230598,
      0.012131832540035248,
      -0.013082383200526237,
      0.012033769860863686,
      -0.03427350893616676,
      -0.026851793751120567,
      0.036429278552532196,
      -0.06204997003078461,
      -0.0023697055876255035,
      -0.019154002889990807,
      -0.04386814683675766,
      0.01772409863770008,
      0.028101563453674316,
      0.05262022465467453,
      0.034095291048288345,
      0.07087144255638123,
      0.03011181764304638,
      -0.01751280575990677,
      0.039863500744104385,
      -0.08229727298021317,
      -0.00044501552474685013,
      -0.004255836363881826,
      0.0023559648543596268,
      0.06279724836349487,
      0.0009569329558871686,
      -0.09358356893062592,
      -0.06570817530155182,
      -0.023209942504763603,
      0.01938251592218876,
      -0.04305526614189148,
      -0.036042433232069016,
      0.07351097464561462,
      -0.10668681561946869,
      -0.0025703394785523415,
      0.0643397644162178,
      0.008523127064108849,
      0.06663403660058975,
      -0.02234768681228161,
      -0.0036233263090252876,
      -0.06554222851991653,
      -0.013756529428064823,
      0.012330250814557076,
      -0.019616013392806053,
      -0.035875529050827026,
      0.03567076101899147,
      -0.024436088278889656,
      -0.014687021262943745,
      -0.027896961197257042,
      -0.018864084035158157,
      0.021224310621619225,
      -0.058069244027137756,
      -0.0034958310425281525,
      -0.0017499795649200678,
      0.03441515937447548,
      0.026958011090755463,
      -0.05289715901017189,
      0.05469036474823952,
      0.018068240955471992,
      -0.0022764764726161957,
      -0.061782415956258774,
      0.007183428853750229,
      -0.003100458299741149,
      0.026213137432932854,
      -0.018276488408446312,
      0.019003883004188538,
      0.0004507674020715058,
      -0.05535472556948662,
      -0.015790048986673355,
      0.07636696845293045,
      0.018987121060490608,
      -0.0027632098644971848,
      -0.01738593354821205,
      -0.008159559220075607,
      0.04263400286436081,
      0.07351793348789215,
      -0.029423082247376442,
      -0.0066690873354673386,
      0.02180246077477932,
      0.002194264903664589,
      -0.008594037033617496,
      0.06447382271289825,
      -0.008966951631009579,
      0.027585910633206367,
      -0.012485172599554062,
      -0.07071626931428909,
      -0.03039686568081379,
      -0.0264327060431242,
      -0.039936814457178116,
      -0.017263267189264297,
      0.013998609967529774,
      -0.022899460047483444,
      0.0689280554652214,
      -0.06225098669528961,
      0.00620108749717474,
      -0.024931130930781364,
      0.02580246701836586,
      -0.005816606339067221,
      0.027130356058478355,
      -0.058087147772312164,
      -0.049341488629579544,
      -0.058579184114933014,
      -0.026528028771281242,
      -0.020267808809876442,
      0.005294152535498142,
      0.029737573117017746,
      0.02914927527308464,
      0.03642435744404793,
      0.044062044471502304,
      0.004583954811096191,
      0.02958127111196518,
      0.0977315679192543,
      -0.01663392409682274,
      0.05571391060948372,
      0.01967453770339489,
      0.05982242897152901,
      0.053279388695955276,
      0.005549347493797541,
      0.03086821362376213,
      0.04961656406521797,
      -0.005434185266494751,
      -0.015846604481339455,
      0.01684851013123989,
      0.07333720475435257,
      0.0004575854982249439,
      -0.0377776101231575,
      0.06316442042589188,
      0.0038313933182507753,
      0.05512970685958862,
      -0.09137235581874847,
      -0.062258947640657425,
      -0.024229558184742928,
      -0.028516186401247978,
      -0.058462612330913544,
      0.07466178387403488,
      0.07986056059598923,
      0.011130708269774914,
      0.017933277413249016,
      -0.01805548369884491,
      0.045261505991220474,
      -0.05473152920603752,
      0.036623481661081314,
      0.00907289981842041,
      -0.04379039630293846,
      -0.05191564932465553,
      -0.0050450838170945644,
      0.0017387381521984935,
      -0.04178554564714432,
      -0.023399753496050835,
      -0.018045654520392418,
      0.030748462304472923,
      0.0919148251414299,
      -0.04323374852538109,
      0.06782969832420349,
      -0.0042025200091302395,
      0.03799426928162575,
      -0.017267420887947083,
      -0.00393316987901926,
      -0.07320688664913177,
      -0.08773114532232285,
      0.11898210644721985,
      -0.003427465446293354,
      0.06088932976126671,
      -0.005579240620136261,
      0.004859820939600468,
      0.011274084448814392,
      0.04106786474585533,
      0.03464474901556969,
      0.06787797808647156,
      0.0022539282217621803,
      -0.040075939148664474,
      -0.06211481988430023,
      0.071957528591156,
      0.043984852731227875,
      0.01612124964594841,
      -0.03802838176488876,
      0.018276667222380638,
      0.03257342055439949,
      0.035842858254909515,
      0.03084542788565159,
      0.0385235995054245,
      -0.05458379164338112,
      0.03081141598522663,
      -0.05953660607337952,
      0.0336671806871891,
      0.008305995725095272,
      0.06769808381795883,
      -0.03467695415019989,
      0.06063586473464966,
      -0.013654044829308987,
      0.004592828452587128,
      0.0014881662791594863,
      -0.07536845654249191,
      -0.05246571823954582,
      -0.03835801035165787,
      0.005470290780067444,
      0.02699463441967964,
      -0.12420003116130829,
      0.06547925621271133,
      -0.01999817229807377,
      -0.04184456914663315,
      0.02242635004222393,
      -0.011022310703992844,
      0.07819432020187378,
      0.03677036613225937,
      -0.03244997188448906,
      0.07556010037660599,
      -0.08709798008203506,
      -0.029291454702615738,
      0.06286203861236572,
      -0.06738614290952682,
      0.038869842886924744,
      0.06106455996632576,
      0.004386537242680788,
      -0.03030971810221672,
      -0.05005033686757088,
      0.03901822119951248,
      0.039393603801727295,
      -0.038271334022283554,
      -0.033793844282627106,
      -0.07635610550642014,
      -0.05578381195664406,
      -0.02484254166483879,
      0.06166255474090576,
      0.042837198823690414,
      0.03706929460167885,
      -0.11465973407030106,
      0.05794787034392357,
      -0.02383134886622429,
      -0.054921530187129974,
      -0.04242262244224548,
      0.012842935509979725,
      0.052293770015239716,
      0.023556414991617203,
      -0.013405043631792068,
      -0.015483676455914974,
      -0.0049520437605679035,
      -0.025124479085206985,
      0.0795786902308464,
      0.031449735164642334,
      -0.0879015177488327,
      0.09092185646295547,
      -0.001897070207633078,
      -0.029657771810889244,
      -0.05398407205939293,
      0.06562759727239609,
      0.02773340418934822,
      0.004356068093329668,
      0.08943652361631393,
      -0.032019585371017456,
      0.004250328056514263,
      -0.03853021189570427,
      -0.010539926588535309,
      -0.055890586227178574,
      -0.07221907377243042,
      -0.05523156747221947,
      -0.003534648334607482,
      -0.026390187442302704,
      -0.03942818194627762,
      0.010290068574249744,
      0.023804057389497757,
      -0.04621126130223274,
      -0.03650239109992981,
      0.011451824568212032,
      -0.03409193083643913,
      0.010202486999332905,
      0.0026558812242001295,
      0.045644160360097885,
      -0.0571572408080101,
      0.009254427626729012,
      0.04389112815260887,
      -0.08333811163902283,
      0.07877558469772339,
      -0.009264981374144554,
      0.010897171683609486,
      0.09683188050985336,
      0.022040976211428642,
      -0.0016213644994422793,
      0.02614818513393402,
      -0.00476960651576519,
      -0.0017058945959433913,
      0.017331965267658234,
      -0.027903785929083824,
      0.044030122458934784,
      -0.019655531272292137,
      0.07738165557384491,
      0.009381747804582119,
      -0.0803333967924118,
      0.029404450207948685,
      -0.016465123742818832,
      -0.10875483602285385,
      0.0017348641995340586,
      -0.022419428452849388,
      0.0012482366291806102,
      -0.057828139513731,
      0.024720795452594757,
      0.013833296485245228,
      -0.033781345933675766,
      0.061060573905706406,
      0.012796232476830482,
      -0.06444095820188522,
      -0.026376234367489815,
      -0.04939870163798332,
      0.03381846100091934,
      -0.04251381754875183,
      -0.048925451934337616,
      0.05976060777902603,
      0.028109578415751457,
      0.12019093334674835,
      -0.02899342216551304,
      -0.04104873538017273,
      0.02119801752269268,
      0.008077897131443024,
      0.05058427155017853,
      -0.01912526786327362,
      -0.047472111880779266,
      0.10740455985069275,
      0.008395968936383724,
      0.011952188797295094,
      -0.09551777690649033,
      0.03591978922486305,
      -0.020100869238376617,
      -0.03890880197286606,
      -0.03850231692194939,
      0.06550739705562592,
      0.05300489068031311,
      -0.03622471168637276,
      -0.01583828218281269,
      -0.044426437467336655,
      0.049418363720178604,
      0.05479970574378967,
      0.03285132721066475,
      0.024663224816322327,
      -0.04333443194627762,
      -0.0009214688907377422,
      0.08486884087324142,
      0.02167282998561859,
      0.024685252457857132,
      0.0007184788701124489,
      0.08167445659637451,
      0.008960752747952938,
      0.026445308700203896,
      -0.0423809215426445,
      0.022402118891477585,
      0.026081157848238945,
      -0.025225887075066566,
      0.04354646056890488,
      0.01447794958949089,
      -0.10920295864343643,
      -0.015819339081645012,
      0.011707880534231663,
      0.042760372161865234,
      -0.04276138171553612,
      -0.06061845272779465,
      -0.11068757623434067,
      -0.05651341751217842,
      0.04763634875416756,
      -0.07679642736911774,
      0.023166824132204056,
      0.03794894367456436,
      -0.01819388009607792,
      -0.009414928033947945,
      -0.06419559568166733,
      0.04839614778757095,
      0.027895614504814148,
      -0.0344451367855072,
      -0.011286281049251556,
      -0.006045619957149029,
      -0.02219136618077755,
      0.02750176377594471,
      0.018009299412369728,
      0.055892959237098694,
      0.05163739249110222,
      -0.06257577985525131,
      0.064146026968956,
      0.011848259717226028,
      -0.019841602072119713,
      -0.008659186773002148,
      -0.012176096439361572,
      -0.012394185177981853,
      0.027757538482546806,
      0.007316636852920055,
      -0.002081311773508787,
      -0.0029274553526192904,
      0.0037793719675391912,
      -0.029177267104387283,
      0.07721450924873352,
      0.005375787615776062,
      0.03281077370047569,
      0.09346802532672882,
      0.019648542627692223,
      0.0210520401597023,
      -0.12137573212385178,
      -0.07102139294147491,
      0.13362842798233032,
      0.03323037922382355,
      -0.053219012916088104,
      -0.004964726511389017,
      0.021218905225396156,
      -0.01564565859735012,
      -0.051796648651361465,
      0.07816259562969208,
      -0.02657243423163891,
      -0.05891619250178337,
      0.02020026370882988,
      -0.016717275604605675,
      0.0036720065400004387,
      0.006936389487236738,
      0.034140173345804214,
      -0.0062501514330506325,
      -0.017281433567404747,
      -0.06075288727879524,
      0.021418496966362,
      -0.05103321745991707,
      0.0734112337231636,
      0.02890406921505928,
      -0.03629174456000328,
      -0.020629946142435074,
      0.03257213532924652,
      -0.023501474410295486,
      -0.07114678621292114,
      0.030375037342309952,
      0.020760558545589447,
      -0.0010973612079396844,
      0.03470912575721741,
      -0.0021094796247780323,
      -0.010807116515934467,
      0.047059185802936554,
      0.018291955813765526,
      0.06489735096693039,
      0.05234464630484581,
      0.01708921417593956,
      0.0692942664027214,
      -0.024100422859191895,
      -0.02198115549981594,
      0.03558848425745964,
      -0.06522506475448608,
      -0.019741065800189972,
      -0.04990265145897865,
      0.016354190185666084,
      0.033434778451919556,
      0.006911827251315117,
      0.020590489730238914,
      -0.04823056608438492,
      -0.03602232038974762,
      -0.03615742176771164,
      -0.07952258735895157,
      0.08664382994174957,
      -0.026251891627907753,
      0.01351263839751482,
      -0.012899276800453663,
      0.011826879344880581,
      -0.027161037549376488,
      0.0011428651632741094,
      -0.03685028851032257,
      0.0585540235042572,
      -0.04682517051696777,
      0.061447348445653915,
      0.0418158620595932,
      0.07721967995166779,
      -0.027281546965241432,
      0.014166465029120445,
      -0.05905040726065636
    ],
    [
      -0.04281040281057358,
      0.0652402937412262,
      -0.020961441099643707,
      -0.0354917012155056,
      0.049369554966688156,
      0.004787342622876167,
      -0.036261435598134995,
      0.005999133922159672,
      0.0211633387953043,
      -0.03679661080241203,
      -0.03372134640812874,
      0.021610327064990997,
      -0.005385161843150854,
      -0.003150166245177388,
      0.08485598862171173,
      -0.03650706633925438,
      -0.024286653846502304,
      0.0077295140363276005,
      0.05261627212166786,
      0.0010016444139182568,
      -0.05398321524262428,
      -0.029157962650060654,
      0.0162055641412735,
      0.039333704859018326,
      0.011603225953876972,
      0.011415957473218441,
      0.02382936142385006,
      0.014130240306258202,
      -0.009237919002771378,
      -0.02685047872364521,
      0.03161022812128067,
      0.07869534194469452,
      0.05453793331980705,
      -0.06273797154426575,
      0.039365701377391815,
      0.008892208337783813,
      -0.006742069963365793,
      -0.033435922116041183,
      0.003882155753672123,
      -0.08540632575750351,
      0.023352736607193947,
      0.032549209892749786,
      -0.008175389841198921,
      0.04915701597929001,
      0.007861402817070484,
      -0.0512126088142395,
      -0.024367433041334152,
      -0.050519853830337524,
      -0.014255785383284092,
      -0.06117868423461914,
      0.05423324927687645,
      -0.0468917042016983,
      -0.0156954787671566,
      -0.02121112495660782,
      -0.02578279748558998,
      0.0011097280075773597,
      -0.025207675993442535,
      0.019462009891867638,
      0.02580343931913376,
      -0.012623588554561138,
      -0.08307003229856491,
      0.025288723409175873,
      -0.0699872225522995,
      -0.025323163717985153,
      -0.008102522231638432,
      -0.034526169300079346,
      -0.02958076447248459,
      0.01198128703981638,
      -0.030837278813123703,
      0.011210063472390175,
      0.05863581597805023,
      -0.019316988065838814,
      0.030691951513290405,
      0.024273717775940895,
      0.013426914811134338,
      0.003959120251238346,
      0.05903066322207451,
      0.008816544897854328,
      -0.0615939199924469,
      0.035360176116228104,
      0.04363589361310005,
      0.06386029720306396,
      -0.00021447309700306505,
      0.041686005890369415,
      -0.017308441922068596,
      -0.045344769954681396,
      -0.16471368074417114,
      -0.03356808051466942,
      0.03219011798501015,
      0.04057549312710762,
      0.0036975473631173372,
      0.07017258554697037,
      -0.07507152855396271,
      0.05413586273789406,
      0.02990235947072506,
      -0.0061073899269104,
      0.046596355736255646,
      -0.025014130398631096,
      0.0024804871063679457,
      0.04380479082465172,
      -0.055187005549669266,
      -0.04762473702430725,
      -0.06060011684894562,
      0.023828968405723572,
      -0.0635487288236618,
      0.02135539799928665,
      0.00848887488245964,
      -0.06347712129354477,
      -0.009161705151200294,
      -0.008161273784935474,
      -0.029785867780447006,
      0.09000636637210846,
      -0.04581465944647789,
      0.0074662500992417336,
      -0.003971597645431757,
      0.08238948881626129,
      -0.07226528972387314,
      0.11172941327095032,
      -0.009995722211897373,
      -0.034306515008211136,
      -0.04888058453798294,
      0.043539807200431824,
      -0.023515867069363594,
      -0.026368392631411552,
      -0.029559148475527763,
      -0.01014347281306982,
      -0.11355556547641754,
      0.045432183891534805,
      0.030527492985129356,
      -0.012555350549519062,
      0.05523016303777695,
      0.08252876996994019,
      0.013580141589045525,
      0.016066698357462883,
      -0.04364394024014473,
      -0.04585158824920654,
      0.05703466013073921,
      0.13874207437038422,
      0.012253649532794952,
      -0.10722614824771881,
      0.00032821553759276867,
      0.048269856721162796,
      0.021205203607678413,
      0.051561400294303894,
      -0.04732217267155647,
      -0.04552922770380974,
      0.04799722135066986,
      -0.021706435829401016,
      -0.035645756870508194,
      -0.0014352627331390977,
      0.04969555139541626,
      0.06859651952981949,
      0.09852712601423264,
      0.04280679672956467,
      -0.04323441535234451,
      -0.007089058868587017,
      -0.0030598698649555445,
      -0.027179211378097534,
      -0.024273479357361794,
      -0.04770762845873833,
      0.03601786121726036,
      -0.02152407355606556,
      0.0038494353648275137,
      -0.005008260253816843,
      0.05973397195339203,
      0.05410105362534523,
      -0.012425507418811321,
      0.019155655056238174,
      -0.00815783254802227,
      -0.003871849039569497,
      -0.07087628543376923,
      0.0010875100269913673,
      -0.031080206856131554,
      0.0045353504829108715,
      -0.045924458652734756,
      0.055461227893829346,
      -0.05550440400838852,
      -0.042541779577732086,
      0.07615417242050171,
      0.03662700951099396,
      0.04393167048692703,
      0.09101946651935577,
      -0.018649527803063393,
      -0.03958990052342415,
      -0.08105708658695221,
      0.03356773406267166,
      0.037403300404548645,
      -0.04315629228949547,
      0.06241856887936592,
      -0.0478002168238163,
      0.08397910743951797,
      0.0850294902920723,
      -0.07111823558807373,
      0.024444404989480972,
      -0.05093575268983841,
      0.01711183413863182,
      0.04178769513964653,
      0.029907969757914543,
      0.03316367417573929,
      0.04373960196971893,
      0.012166423723101616,
      0.03410033509135246,
      0.05023127421736717,
      0.02548849582672119,
      -0.10480930656194687,
      0.03574884310364723,
      0.030518824234604836,
      0.02353057637810707,
      -0.031416647136211395,
      -0.05517600104212761,
      -0.048130251467227936,
      0.03194331377744675,
      0.01849052496254444,
      0.026263421401381493,
      -0.044511739164590836,
      -0.003940801601856947,
      -0.03911278769373894,
      0.06190204992890358,
      -0.055131442844867706,
      0.039683904498815536,
      -0.05384483188390732,
      0.01574942283332348,
      0.015766430646181107,
      0.03127358481287956,
      -0.0249620508402586,
      -0.05201692506670952,
      0.021839188411831856,
      0.061693742871284485,
      0.021365687251091003,
      -0.02249988541007042,
      0.012173696421086788,
      0.004376264754682779,
      -0.015497604385018349,
      -0.007158514577895403,
      0.025839881971478462,
      -0.04230573773384094,
      0.026253921911120415,
      -0.08889230340719223,
      -0.029125655069947243,
      -0.05106431618332863,
      -0.009144159965217113,
      0.03375760838389397,
      0.027056211605668068,
      -0.02024412900209427,
      0.0820387527346611,
      -0.00018542686302680522,
      -0.10090136528015137,
      0.025958813726902008,
      0.02155979909002781,
      -0.041631780564785004,
      0.023034434765577316,
      0.05823551490902901,
      -0.04096705839037895,
      -0.0446348674595356,
      0.019054749980568886,
      0.03973110765218735,
      -0.017085934057831764,
      0.05498400703072548,
      0.07672069221735,
      0.011760578490793705,
      -0.036059897392988205,
      0.03208823874592781,
      -0.00909926276654005,
      0.04704847186803818,
      -0.009070868603885174,
      0.015509141609072685,
      -0.02904599905014038,
      -0.029614480212330818,
      0.04253502935171127,
      -0.046969782561063766,
      -0.026485780254006386,
      -0.03837447986006737,
      0.03297172859311104,
      -0.03878636285662651,
      -0.05918920040130615,
      0.024639016017317772,
      -0.08295600861310959,
      -0.01205085776746273,
      0.013813981786370277,
      -0.00869174674153328,
      -0.007954271510243416,
      -0.028059454634785652,
      0.006688590161502361,
      0.058826953172683716,
      0.02378763258457184,
      -0.012346706353127956,
      0.09487054497003555,
      -0.045796092599630356,
      -0.09090316295623779,
      0.016659589484333992,
      -0.07498838752508163,
      -0.08892078697681427,
      -0.0025058388710021973,
      0.05353658273816109,
      -0.012686124071478844,
      0.0972316712141037,
      0.11160018295049667,
      -0.047605834901332855,
      0.01197869423776865,
      0.01675332337617874,
      -0.05519925802946091,
      -0.03278593346476555,
      -0.04904855042695999,
      0.050260938704013824,
      -0.04763982445001602,
      -0.006468143779784441,
      -0.05035668611526489,
      -0.050241731107234955,
      0.025256620720028877,
      -0.021402157843112946,
      0.0547344945371151,
      0.07027684152126312,
      0.013034695759415627,
      -0.017082924023270607,
      0.0038566794246435165,
      -0.02394905872642994,
      0.05920527130365372,
      0.040667612105607986,
      -0.012615825980901718,
      0.016160456463694572,
      -0.05624477192759514,
      -0.030571598559617996,
      0.04680253565311432,
      0.03063693642616272,
      0.04754038155078888,
      0.008079580962657928,
      0.028383053839206696,
      0.09215407073497772,
      -0.021404670551419258,
      0.0123743936419487,
      0.017989296466112137,
      0.01626504398882389,
      -0.026135031133890152,
      0.03639233857393265,
      0.09115900844335556,
      0.04963592067360878,
      -0.0297101903706789,
      0.0365237221121788,
      -0.02244889736175537,
      -0.06810399144887924,
      -0.0935286432504654,
      -0.08419091999530792,
      -0.030852245166897774,
      -0.02233060821890831,
      -0.14590616524219513,
      0.05255954712629318,
      0.050893932580947876,
      -0.032455455511808395,
      0.015827227383852005,
      -0.07439678907394409,
      -0.046510688960552216,
      0.03421516716480255,
      0.01437905989587307,
      0.05063994228839874,
      0.018839450553059578,
      -0.035496290773153305,
      -0.01323855109512806,
      0.0033686498645693064,
      -0.045035406947135925,
      -0.07932502776384354,
      -0.064910389482975,
      0.07083465158939362,
      -0.046326614916324615,
      -0.04737530276179314,
      0.03369889408349991,
      -0.021010596305131912,
      0.03781796619296074,
      0.00289385998621583,
      0.009895412251353264,
      0.01724565215408802,
      -0.009227113798260689,
      -0.04169267416000366,
      0.1328345686197281,
      -0.019141264259815216,
      -0.06514272838830948,
      -0.001267023617401719,
      0.027967648580670357,
      -0.022630678489804268,
      -0.024668032303452492,
      -0.05932581424713135,
      0.06513727456331253,
      -0.004239278379827738,
      0.0756450816988945,
      -0.05415238440036774,
      0.04764564335346222,
      0.0657205656170845,
      0.001928547746501863,
      0.081818588078022,
      -0.09509117156267166,
      -0.08887409418821335,
      0.02106180600821972,
      0.03475385531783104,
      -0.06800228357315063,
      -0.12850743532180786,
      0.02323276363313198,
      -0.03624120354652405,
      -0.06298761814832687,
      -0.02023307792842388,
      0.0916462391614914,
      -0.0345066674053669,
      -0.011534315533936024,
      -0.04722319543361664,
      0.05910315364599228,
      -0.02315557561814785,
      -0.05145740509033203,
      -0.019446104764938354,
      0.01846451498568058,
      -0.03938386216759682,
      -0.04055348038673401,
      -0.063414566218853,
      -0.020331645384430885,
      -0.045230817049741745,
      -0.04205095022916794,
      0.042443517595529556,
      0.0002891676558647305,
      0.045496392995119095,
      0.050746068358421326,
      0.013873584568500519,
      0.03909823298454285,
      -0.037154532968997955,
      -0.005927298218011856,
      0.026723917573690414,
      0.059235166758298874,
      -0.004140468314290047,
      -0.011155745945870876,
      -0.03416318818926811,
      0.034666165709495544,
      0.05080685392022133,
      0.016775352880358696,
      0.024557922035455704,
      -0.028652066364884377,
      0.046671465039253235,
      -0.06869383156299591,
      -0.08671804517507553,
      -0.08307645469903946,
      0.00032877910416573286,
      -0.004717935808002949,
      -0.046917323023080826,
      0.026423631235957146,
      -0.011542612686753273,
      0.040148742496967316,
      -0.0074775624088943005,
      0.04205984249711037,
      0.06913552433252335,
      -0.023885853588581085,
      -0.01815219596028328,
      0.018448352813720703,
      -0.03687303513288498,
      -0.02800908498466015,
      -0.005076093599200249,
      -0.06733641028404236,
      0.008511971682310104,
      0.09704724699258804,
      0.04345867410302162,
      0.08457674831151962,
      -0.03914900869131088,
      -0.036720674484968185,
      0.07063605636358261,
      0.011578953824937344,
      -0.018130995333194733,
      -0.013327065855264664,
      0.0019797109998762608,
      -0.125463604927063,
      0.001677315216511488,
      0.03010493889451027,
      -0.04306749254465103,
      -0.06164465844631195,
      0.025194313377141953,
      -0.005097281187772751,
      0.0161198191344738,
      -0.019756024703383446,
      0.05654680356383324,
      -0.014796871691942215,
      -0.027447698637843132,
      -0.041075337678194046,
      0.10903528332710266,
      0.009017221629619598,
      -0.02902672253549099,
      0.040359366685152054,
      0.014851843006908894,
      -0.019123775884509087,
      0.07720940560102463,
      -0.03276514261960983,
      -0.045573871582746506,
      -0.009606661275029182,
      -0.030256763100624084,
      -0.03635287284851074,
      0.028264183551073074,
      -0.03338748216629028,
      0.012908696196973324,
      0.002751000924035907,
      0.010195443406701088,
      0.0133788688108325,
      -0.06740892678499222,
      0.04115544632077217,
      0.01005020085722208,
      0.033425796777009964,
      -0.011330556124448776,
      -0.06000654399394989,
      0.10165277868509293,
      -0.06425443291664124,
      -0.06255675107240677,
      0.012423962354660034
    ],
    [
      0.05834203585982323,
      -0.0309895146638155,
      0.03783870488405228,
      0.046799153089523315,
      -0.031194239854812622,
      0.03978586569428444,
      0.035225726664066315,
      -0.07098899036645889,
      0.09827616810798645,
      0.03433255851268768,
      0.05211411789059639,
      0.027593722566962242,
      -0.004239121917635202,
      -0.02466672472655773,
      0.0361817367374897,
      -0.013582760468125343,
      -0.024598989635705948,
      0.0033239356707781553,
      0.05144476145505905,
      -0.07474890351295471,
      -0.030722782015800476,
      0.004541147034615278,
      0.004753016401082277,
      0.09453897923231125,
      -0.011498489417135715,
      -0.03459057956933975,
      -0.023797117173671722,
      -0.03623925894498825,
      0.04278039187192917,
      0.06167878583073616,
      -0.08369448781013489,
      -0.06215556710958481,
      0.017844347283244133,
      -0.04895889759063721,
      0.02549073100090027,
      -0.06583821028470993,
      -0.03817838802933693,
      -0.06238711625337601,
      0.035372745245695114,
      0.04705536738038063,
      0.006714298389852047,
      0.026491178199648857,
      0.06394942849874496,
      0.040828313678503036,
      0.039523590356111526,
      -0.03838566690683365,
      0.09664115309715271,
      -0.002976154675707221,
      -0.012364694848656654,
      0.019481075927615166,
      0.014367320574820042,
      -0.054214805364608765,
      0.046032581478357315,
      0.036668114364147186,
      0.020003849640488625,
      -0.026804357767105103,
      -0.027246680110692978,
      0.05233445391058922,
      0.033331919461488724,
      0.017555344849824905,
      -0.019005082547664642,
      0.040073979645967484,
      -0.09443128854036331,
      -0.006923028267920017,
      -0.02014700137078762,
      0.014720022678375244,
      -0.020784886553883553,
      0.1145358607172966,
      0.0005484904395416379,
      -0.08844579011201859,
      -0.0004878785402979702,
      -0.02997300587594509,
      0.03994196653366089,
      0.035928767174482346,
      -0.03317338973283768,
      0.010189732536673546,
      0.06476224213838577,
      -0.03919980674982071,
      -0.1133873462677002,
      -0.06648717820644379,
      0.03919515758752823,
      -0.03134509548544884,
      -0.0021232899744063616,
      0.049692265689373016,
      -0.0020054560154676437,
      0.04410095885396004,
      0.013414344750344753,
      -0.02051135152578354,
      -0.057722341269254684,
      -0.012994056567549706,
      -0.05182965472340584,
      0.0013150329468771815,
      -0.04988911375403404,
      -0.01991492509841919,
      0.0595424622297287,
      -0.011374176479876041,
      -0.03187205269932747,
      -0.01667609252035618,
      -0.07883469760417938,
      -0.02640698105096817,
      0.014516325667500496,
      -0.004182442557066679,
      -0.009845267981290817,
      -0.020711243152618408,
      0.04605981335043907,
      -0.033053524792194366,
      0.060813482850790024,
      0.024683261290192604,
      0.04079282283782959,
      -0.050173625349998474,
      0.0830879881978035,
      0.06923722475767136,
      -0.02338303253054619,
      0.036377664655447006,
      -0.05230634659528732,
      -0.011449801735579967,
      0.02358093298971653,
      -0.04398694261908531,
      0.04052559286355972,
      0.017876598984003067,
      0.02311527542769909,
      0.0689820796251297,
      0.009611766785383224,
      -0.022591209039092064,
      -0.019462421536445618,
      -0.017244568094611168,
      -0.0463901087641716,
      -0.07781261950731277,
      -0.05187497287988663,
      0.07812024652957916,
      -0.06277172267436981,
      -0.012766360305249691,
      -0.03693666309118271,
      -0.021220970898866653,
      0.043942924588918686,
      -0.02757090888917446,
      0.01670069247484207,
      0.02919950522482395,
      -0.025803087279200554,
      0.007912344299256802,
      0.014509640634059906,
      0.01701476238667965,
      0.060272444039583206,
      -0.02999291941523552,
      0.06704112142324448,
      -0.016372695565223694,
      0.02292928472161293,
      0.05123653635382652,
      0.049339715391397476,
      0.07444564253091812,
      -0.01379472203552723,
      -0.032413240522146225,
      -0.027293147519230843,
      0.03998902440071106,
      0.025974594056606293,
      0.01751527190208435,
      0.029685983434319496,
      0.05983570218086243,
      0.02123214863240719,
      -0.021654734387993813,
      -0.0364055298268795,
      0.013721979223191738,
      0.02147694118320942,
      -0.11055735498666763,
      0.04033137485384941,
      -0.02883780747652054,
      0.01746361516416073,
      -0.010236870497465134,
      -0.10301085561513901,
      0.024807503446936607,
      -0.014775564894080162,
      0.052062027156353,
      -0.030993856489658356,
      0.013624442741274834,
      -0.01123419776558876,
      -0.04132512956857681,
      -0.05427630618214607,
      0.04088163003325462,
      -0.020578255876898766,
      0.0032417690381407738,
      -0.029117094352841377,
      0.031081127002835274,
      0.03196949511766434,
      -0.05695252865552902,
      0.026157131418585777,
      -0.05318024381995201,
      -0.04222019761800766,
      0.04800162464380264,
      0.024240702390670776,
      -0.022319849580526352,
      0.04797370731830597,
      0.01902218908071518,
      0.0307085569947958,
      0.004224168136715889,
      0.010393021628260612,
      0.09645980596542358,
      0.031914010643959045,
      0.028425080701708794,
      0.026588592678308487,
      0.0007947643171064556,
      0.05718228965997696,
      0.04792872443795204,
      0.025072205811738968,
      -0.07102733105421066,
      0.029418157413601875,
      0.0016477217432111502,
      -0.042769644409418106,
      0.00795501098036766,
      0.10906114429235458,
      -0.0603867769241333,
      0.08328919857740402,
      0.040963951498270035,
      0.04534127935767174,
      0.07324539124965668,
      0.010040363296866417,
      -0.02748863585293293,
      0.0934106707572937,
      -0.01804105006158352,
      -0.08130983263254166,
      -0.05071932449936867,
      0.050592731684446335,
      0.015224519185721874,
      -0.001126650022342801,
      0.05252226069569588,
      -0.014432188123464584,
      0.03405506908893585,
      0.030223840847611427,
      -0.06471506506204605,
      0.01949983648955822,
      -0.017664413899183273,
      -0.041600070893764496,
      0.038516007363796234,
      -0.003236084710806608,
      -0.04536360502243042,
      0.004433663096278906,
      0.07444600760936737,
      -0.05716922506690025,
      0.008410414680838585,
      -0.0009867582703009248,
      -0.03737673535943031,
      0.05557304620742798,
      -0.022461578249931335,
      0.0639800950884819,
      -0.02838897332549095,
      0.012105165980756283,
      -0.021083856001496315,
      0.030163858085870743,
      -0.029193170368671417,
      -0.08339209109544754,
      0.020368477329611778,
      0.000581260013859719,
      0.04144994542002678,
      0.03986479714512825,
      -0.005598657298833132,
      -0.012057649902999401,
      -0.030048882588744164,
      0.023114735260605812,
      -0.023259062319993973,
      0.011757277883589268,
      0.0023424618411809206,
      -0.08257891982793808,
      0.012642578221857548,
      0.00743905222043395,
      -0.042549554258584976,
      -0.09150705486536026,
      -0.006875731982290745,
      0.06881486624479294,
      -0.04597882926464081,
      0.11235896497964859,
      0.018076276406645775,
      -0.05151258409023285,
      0.005682395305484533,
      -0.01910507306456566,
      0.11436934024095535,
      -0.027770401909947395,
      0.06302142888307571,
      0.09998562932014465,
      -0.04971570149064064,
      -0.08023406565189362,
      0.04911473020911217,
      0.02328535169363022,
      0.05496888980269432,
      0.015917133539915085,
      0.06873805075883865,
      -0.032719936221838,
      0.015468583442270756,
      0.020369283854961395,
      -0.03405926004052162,
      -0.13608530163764954,
      0.024883678182959557,
      0.033015478402376175,
      0.0217412319034338,
      0.05253361165523529,
      0.01777048595249653,
      0.05368034169077873,
      0.03312944993376732,
      -0.07019706815481186,
      0.048712074756622314,
      0.013733765110373497,
      0.01930883526802063,
      -0.019113747403025627,
      0.028065811842679977,
      -0.0956641137599945,
      0.005038294475525618,
      0.03086606226861477,
      -0.008534830994904041,
      0.059118516743183136,
      0.024301350116729736,
      0.021854598075151443,
      0.013826999813318253,
      0.05087559297680855,
      0.03659431263804436,
      0.02117953449487686,
      0.013620401732623577,
      -0.03654451295733452,
      0.09749257564544678,
      -0.03628101572394371,
      -0.05151810124516487,
      -0.022644923999905586,
      0.022726451978087425,
      0.02798071689903736,
      0.08175165206193924,
      -0.09468994289636612,
      0.07921911776065826,
      0.09360093623399734,
      -0.04442761093378067,
      0.03703903406858444,
      -0.03298278898000717,
      -0.011048638261854649,
      0.010478142648935318,
      0.0024073636159300804,
      -0.032825928181409836,
      -0.045051734894514084,
      -0.09245980530977249,
      0.03844192996621132,
      0.013423780910670757,
      0.05038560926914215,
      -0.015393988229334354,
      -0.004219368100166321,
      -0.05690813809633255,
      0.027738388627767563,
      -0.04368467256426811,
      -0.009879627265036106,
      -0.08317489176988602,
      0.04817283898591995,
      -0.02782781794667244,
      0.08503825962543488,
      -0.021347494795918465,
      0.0831507071852684,
      0.09523721039295197,
      0.011005120351910591,
      -0.09699855744838715,
      0.02095654234290123,
      -0.06436336040496826,
      -0.016409873962402344,
      -0.029918981716036797,
      0.06765339523553848,
      0.030103769153356552,
      0.009086296893656254,
      -0.010277925059199333,
      -0.0668971836566925,
      0.060696445405483246,
      -0.008374991826713085,
      -0.021514635533094406,
      -0.08206284046173096,
      -0.08950221538543701,
      -0.006210184656083584,
      -0.02185370773077011,
      -0.04932698234915733,
      -0.04217070713639259,
      -0.0386473573744297,
      -0.029384316876530647,
      0.08752261102199554,
      0.0008048196905292571,
      0.06308571994304657,
      -0.01340483594685793,
      -0.046781282871961594,
      -0.05332696810364723,
      -0.04267793521285057,
      0.028730783611536026,
      0.04046479985117912,
      0.01872439682483673,
      0.056179478764534,
      -0.01851525530219078,
      -0.004129497334361076,
      0.022135773673653603,
      -0.06927269697189331,
      0.005922306329011917,
      -0.045634567737579346,
      0.021488111466169357,
      -0.08747276663780212,
      0.025405652821063995,
      -0.010365362279117107,
      -0.021965112537145615,
      0.031759217381477356,
      0.0012678321218118072,
      0.07884705066680908,
      -0.03920324891805649,
      0.012439658865332603,
      -0.004072345327585936,
      0.0965682864189148,
      -0.016422560438513756,
      0.03467703238129616,
      -0.025761175900697708,
      0.03574872389435768,
      0.07477815449237823,
      -0.029233349487185478,
      0.05585983023047447,
      0.01200157031416893,
      0.005422387737780809,
      -0.004314990248531103,
      0.09578704833984375,
      0.004977337084710598,
      -0.032013826072216034,
      0.015482167713344097,
      0.024938512593507767,
      -0.05541727691888809,
      -0.008947539143264294,
      0.04681814834475517,
      -0.06492137908935547,
      0.04864239692687988,
      0.022915760055184364,
      -0.019188400357961655,
      0.0830158069729805,
      0.04666658490896225,
      0.03785327821969986,
      0.06538354605436325,
      0.042865291237831116,
      0.07144088298082352,
      -0.00935134943574667,
      -0.05592835322022438,
      0.004048040136694908,
      0.06479037553071976,
      0.03694184869527817,
      0.011690003797411919,
      0.11204732954502106,
      -0.0334247425198555,
      -0.04348839446902275,
      -0.047736331820487976,
      -0.054753899574279785,
      0.03149872273206711,
      0.0005243957275524735,
      0.010263520292937756,
      0.014627475291490555,
      0.022551096975803375,
      -0.010805646888911724,
      0.016828512772917747,
      -0.005865068174898624,
      -0.08175870031118393,
      -0.0602346733212471,
      0.0411531999707222,
      -0.06041510030627251,
      0.056547533720731735,
      -0.016052475199103355,
      -0.03183872625231743,
      0.04971635341644287,
      0.03657577186822891,
      0.022184353321790695,
      -0.007381167728453875,
      0.011710903607308865,
      0.014427972957491875,
      -0.03898683190345764,
      0.016202906146645546,
      0.00512399896979332,
      -0.039492059499025345,
      0.058361805975437164,
      -0.07564614713191986,
      -0.08981022238731384,
      -0.013598182238638401,
      0.06956902891397476,
      0.06170522794127464,
      0.05537591874599457,
      -0.022462397813796997,
      0.016202006489038467,
      0.019655829295516014,
      -0.05853214114904404,
      0.017082836478948593,
      0.012196620926260948,
      -0.062018390744924545,
      -0.06011146679520607,
      -0.0003741432446986437,
      -0.0075176483951509,
      0.052294377237558365,
      -0.06704631447792053,
      -0.043930985033512115,
      -0.00791731383651495,
      0.0004266919568181038,
      0.02923160418868065,
      0.07819855958223343,
      -0.10653462260961533,
      -0.030902720987796783,
      -0.00430322764441371,
      -0.047646455466747284,
      -0.0857408195734024,
      -0.05035051330924034,
      0.03614453971385956,
      -0.05995902046561241,
      0.08075632154941559,
      -0.06140916794538498,
      -0.018014246597886086,
      -0.0017068431479856372,
      -0.03492648899555206,
      0.014766104519367218
    ],
    [
      -0.009941508062183857,
      -0.044902268797159195,
      0.0635872408747673,
      0.00016257875540759414,
      0.02640955150127411,
      -0.006672902964055538,
      0.051118023693561554,
      -0.05115501210093498,
      0.015408587642014027,
      -0.02445709891617298,
      0.026107778772711754,
      0.007410512771457434,
      0.049156732857227325,
      -0.04513637721538544,
      -0.03113250806927681,
      0.03487830609083176,
      0.0307302363216877,
      0.012603110633790493,
      -0.01460595615208149,
      -0.00816556066274643,
      0.025650188326835632,
      0.0032630551140755415,
      0.06973166018724442,
      0.045003000646829605,
      0.11279178410768509,
      -0.038816437125205994,
      0.03981942683458328,
      0.003293749410659075,
      0.04569586366415024,
      0.042656492441892624,
      -0.029725641012191772,
      0.0884263664484024,
      -0.037525832653045654,
      -0.07288144528865814,
      -0.006997464690357447,
      -0.10040616244077682,
      -0.006195484194904566,
      -0.03203551098704338,
      -0.025714892894029617,
      0.037675533443689346,
      -0.037535086274147034,
      0.024681244045495987,
      0.03408225625753403,
      -0.004012526478618383,
      -0.04194958508014679,
      -0.0014349180273711681,
      -0.039556290954351425,
      -0.054449182003736496,
      0.03678081929683685,
      0.061449844390153885,
      -0.011105591431260109,
      -0.06362799555063248,
      0.04448307678103447,
      0.015443086624145508,
      -0.02219792827963829,
      0.0026162038557231426,
      0.06975822150707245,
      -0.0071614510379731655,
      0.04203829541802406,
      0.027460338547825813,
      -0.024597905576229095,
      -0.054295770823955536,
      -0.08510996401309967,
      0.11413262039422989,
      0.06811096519231796,
      -0.024163275957107544,
      -0.054676346480846405,
      -0.07834492623806,
      -0.04178425669670105,
      0.04573807492852211,
      -0.005667536053806543,
      0.05506259202957153,
      -0.04961850494146347,
      0.03362320736050606,
      -0.011103658005595207,
      -0.05321778357028961,
      -0.006208191625773907,
      -0.013555284589529037,
      -0.028286270797252655,
      -0.023807911202311516,
      0.0478302463889122,
      0.04122020676732063,
      0.0419948436319828,
      0.04704848676919937,
      0.04700615629553795,
      0.03467109054327011,
      0.04758113622665405,
      -0.03610653430223465,
      0.04340992867946625,
      0.012205756269395351,
      -0.021748442202806473,
      0.014251640997827053,
      -0.027997154742479324,
      -0.0425746850669384,
      0.0505092479288578,
      0.03852766379714012,
      -0.006571236997842789,
      0.011511504650115967,
      -0.030639728531241417,
      0.014365781098604202,
      0.003698446089401841,
      0.029559670016169548,
      0.003587618935853243,
      0.010630081407725811,
      0.043476831167936325,
      -0.04888590797781944,
      0.016261251643300056,
      -0.013952190987765789,
      0.007862142287194729,
      0.010605523362755775,
      -0.0016947404947131872,
      0.05029822885990143,
      -0.03699609637260437,
      0.10720708221197128,
      0.07164289057254791,
      -0.0031535569578409195,
      0.05100623145699501,
      0.045936502516269684,
      -0.060878366231918335,
      0.0005892436602152884,
      0.08384088426828384,
      0.05484766513109207,
      -0.0072129955515265465,
      0.011780478991568089,
      -0.027190029621124268,
      0.03800974786281586,
      -0.01615799404680729,
      -0.05743243545293808,
      -0.015743490308523178,
      0.036490581929683685,
      0.014000351540744305,
      -0.019355377182364464,
      0.02533595822751522,
      -0.0252750962972641,
      0.031892962753772736,
      -0.01817477121949196,
      0.08397480100393295,
      -0.07092701643705368,
      -0.011956851929426193,
      -0.049895912408828735,
      0.044862084090709686,
      -0.000419342250097543,
      0.04250289127230644,
      0.037038952112197876,
      -0.07435815036296844,
      -0.05168725550174713,
      0.06914125382900238,
      -0.010794505476951599,
      -0.004025209229439497,
      -0.12053028494119644,
      -0.008993814699351788,
      0.043609995394945145,
      0.023988045752048492,
      0.009530813433229923,
      0.03380003571510315,
      -0.04789284989237785,
      -0.009810023009777069,
      0.009480413049459457,
      0.03539402410387993,
      -0.057971466332674026,
      0.007776245474815369,
      -0.004113791510462761,
      0.09304814040660858,
      -0.01602000743150711,
      -0.003956459928303957,
      -0.03016526624560356,
      -0.052026450634002686,
      -0.020504651591181755,
      0.013047558255493641,
      -0.04442741349339485,
      -0.07663720846176147,
      -0.0014135275268927217,
      0.09998612850904465,
      -0.0167042538523674,
      0.024233447387814522,
      -0.020003100857138634,
      0.007101255934685469,
      0.04935833066701889,
      -0.09383518248796463,
      -0.040844403207302094,
      0.02264828234910965,
      -0.005406636744737625,
      -0.019389212131500244,
      -0.022497763857245445,
      -0.003280248958617449,
      -0.025805478915572166,
      -0.0401512011885643,
      -0.053053468465805054,
      0.016381168738007545,
      0.023203475400805473,
      0.03247816115617752,
      -0.018021175637841225,
      -0.005330088082700968,
      0.03786075487732887,
      0.03154546022415161,
      -0.006056423764675856,
      -0.10296151041984558,
      -0.04379088431596756,
      0.03147256746888161,
      0.028949853032827377,
      -0.01574324257671833,
      0.0027597518637776375,
      0.021378226578235626,
      -0.08023328334093094,
      -0.0057572899386286736,
      0.024002695456147194,
      0.019656337797641754,
      -0.009296029806137085,
      0.0014922583941370249,
      -0.013996966183185577,
      0.013054988346993923,
      0.036073237657547,
      0.07992398738861084,
      0.0892501100897789,
      0.039194755256175995,
      0.04322265461087227,
      0.10279438644647598,
      0.008203119970858097,
      0.031454041600227356,
      0.03895486146211624,
      -0.07564067840576172,
      -0.09293215721845627,
      0.013466128148138523,
      -0.05829918757081032,
      0.024481549859046936,
      0.04976145178079605,
      -0.0288300309330225,
      -0.06262286752462387,
      0.0566461943089962,
      -0.026648227125406265,
      0.010977852158248425,
      0.03077741339802742,
      0.004406788852065802,
      -0.06171289086341858,
      -0.028581786900758743,
      -0.05388129875063896,
      -0.011772442609071732,
      -0.002043093554675579,
      0.07742355763912201,
      0.093410924077034,
      0.028910983353853226,
      0.024282097816467285,
      -0.0016048800898715854,
      0.005015562754124403,
      0.057409267872571945,
      -0.0777210220694542,
      -0.07729021459817886,
      -0.013391555286943913,
      0.014607032760977745,
      -0.028882402926683426,
      0.06970665603876114,
      -0.017764778807759285,
      -0.022362327203154564,
      -0.051870085299015045,
      0.014739266596734524,
      -0.044973645359277725,
      0.022506820037961006,
      0.05572628229856491,
      -0.012582404538989067,
      0.10037536174058914,
      -0.01742892898619175,
      0.005773720797151327,
      -0.0335654653608799,
      0.022740444168448448,
      -0.02454276569187641,
      0.014988996088504791,
      0.01075185090303421,
      0.0712781697511673,
      0.011869746260344982,
      -0.018915843218564987,
      0.005639401730149984,
      -0.06885376572608948,
      -0.039328284561634064,
      -0.008196085691452026,
      0.03580700233578682,
      0.04796014726161957,
      -0.018989069387316704,
      0.04007306322455406,
      0.027337251231074333,
      -0.02078942023217678,
      -0.021800920367240906,
      0.0016645530704408884,
      0.034439556300640106,
      -0.0975932627916336,
      -0.007719276938587427,
      0.010403336957097054,
      -0.032461464405059814,
      -0.005117369350045919,
      -0.04675653576850891,
      -0.071682870388031,
      -0.03317956253886223,
      0.003981788642704487,
      0.036961738020181656,
      -0.04681100323796272,
      0.008024998940527439,
      0.057399917393922806,
      -0.016678322106599808,
      -0.04690062999725342,
      0.018830109387636185,
      -0.00974032562226057,
      0.06550629436969757,
      0.06971534341573715,
      -0.0068755787797272205,
      0.0028669671155512333,
      -0.043011728674173355,
      -0.0820474773645401,
      -0.05388600379228592,
      0.003584408201277256,
      0.010043061338365078,
      -0.0009237276972271502,
      0.062221694737672806,
      0.024054598063230515,
      0.03516298532485962,
      0.025981146842241287,
      -0.04341951012611389,
      -0.04425082355737686,
      -0.01915307715535164,
      -0.09563611447811127,
      0.04740525037050247,
      0.028801200911402702,
      -0.10060358047485352,
      -0.03396310657262802,
      -0.015140010975301266,
      0.003885610494762659,
      0.05035507306456566,
      0.007599172182381153,
      0.04018967226147652,
      -0.030396562069654465,
      -0.0018109475495293736,
      0.06202955171465874,
      0.06619627773761749,
      -0.008292238228023052,
      0.005030733998864889,
      0.04234642535448074,
      0.04331985488533974,
      -0.021050995215773582,
      -0.010409493930637836,
      0.0485459603369236,
      -0.08631949871778488,
      0.018963230773806572,
      0.00877583771944046,
      0.07620589435100555,
      0.0036597384605556726,
      0.005393786821514368,
      0.004530842415988445,
      0.07638828456401825,
      0.060630857944488525,
      -0.05444137379527092,
      0.016064627096056938,
      -0.017457980662584305,
      0.003788699395954609,
      0.05662290006875992,
      -0.03252739831805229,
      -0.05425098165869713,
      -0.03715389594435692,
      -0.04962783679366112,
      -0.001130384742282331,
      -0.01976115256547928,
      -0.035371746867895126,
      0.056782159954309464,
      -0.07879634201526642,
      -0.0032451353035867214,
      -0.06786403805017471,
      0.004072796553373337,
      -0.0007756110862828791,
      0.0028533420991152525,
      0.04425919055938721,
      -0.11420266330242157,
      -0.020130664110183716,
      0.08693470805883408,
      -0.022321129217743874,
      0.013212958350777626,
      0.04178786650300026,
      0.03782167285680771,
      -0.051566362380981445,
      0.028870195150375366,
      -0.05090468376874924,
      -0.025423873215913773,
      -0.005204321350902319,
      0.045559562742710114,
      0.05843086540699005,
      -0.051470961421728134,
      -0.03771548345685005,
      -0.03078882209956646,
      -0.03838825970888138,
      -0.01666753739118576,
      0.021870650351047516,
      -0.009234389290213585,
      -0.020454928278923035,
      -0.029446616768836975,
      0.03173178434371948,
      -0.06273818016052246,
      -0.008874728344380856,
      0.018373869359493256,
      -0.0674639344215393,
      -0.06238966062664986,
      -0.04618942365050316,
      -0.048586271703243256,
      -0.07251358777284622,
      0.010430807247757912,
      -0.000429254345363006,
      -0.032706368714571,
      0.00798199512064457,
      0.03122217208147049,
      0.005387381184846163,
      0.01132873073220253,
      -0.05174015462398529,
      0.05918319523334503,
      -0.09424260258674622,
      -0.00214511645026505,
      0.037407197058200836,
      -0.052142009139060974,
      -0.0007889568223617971,
      -0.014552881009876728,
      0.00067633151775226,
      0.0030624347273260355,
      -0.07196005433797836,
      -0.038543932139873505,
      -0.030480321496725082,
      -0.042278844863176346,
      -0.051242973655462265,
      -0.07931873947381973,
      -0.05514685809612274,
      0.017999090254306793,
      0.013215498067438602,
      -0.018623173236846924,
      0.047263797372579575,
      -0.02850671485066414,
      0.06245168298482895,
      0.01133385207504034,
      -0.02626590058207512,
      0.056446824222803116,
      -0.07703718543052673,
      0.020545655861496925,
      -0.02542577125132084,
      0.013476761057972908,
      0.03168874233961105,
      -0.034529540687799454,
      -0.030063774436712265,
      -0.019804198294878006,
      -0.026994287967681885,
      -0.014742095954716206,
      -0.00655916566029191,
      -0.0021378318779170513,
      -0.036947302520275116,
      -0.04661346599459648,
      0.022136259824037552,
      -0.06825235486030579,
      0.02234230563044548,
      -0.02504676580429077,
      0.0455106683075428,
      -0.07124456763267517,
      0.1127651184797287,
      0.051664747297763824,
      0.06059664487838745,
      0.02587912604212761,
      -0.031066013500094414,
      0.02607320249080658,
      0.004113310482352972,
      -0.02417205274105072,
      -0.007826562970876694,
      -0.01825433410704136,
      -0.012062275782227516,
      -0.03492790833115578,
      0.0129097830504179,
      0.01952221989631653,
      -0.04860808700323105,
      -0.0036298930644989014,
      0.023562511429190636,
      0.021796444430947304,
      -0.022761592641472816,
      -0.018435915932059288,
      0.009529565460979939,
      0.023129742592573166,
      0.009962852112948895,
      0.005743339657783508,
      0.040317028760910034,
      -0.05075796693563461,
      -0.024543562904000282,
      0.007515579462051392,
      -0.031592052429914474,
      -0.07526233792304993,
      -0.037407685071229935,
      -0.04851037636399269,
      0.05346816033124924,
      -0.03773164004087448,
      -0.04356100410223007,
      0.06369548290967941,
      0.01567932404577732,
      -0.003487236797809601,
      -0.036467697471380234,
      -0.03289986029267311,
      -0.030211294069886208,
      0.0593184232711792,
      -0.03429108485579491,
      -0.011208443902432919,
      0.04741485416889191,
      2.771751951513579e-06,
      -0.04264706000685692,
      0.012398569844663143,
      0.027497759088873863,
      -0.04084993153810501,
      -0.05326405167579651
    ],
    [
      -0.016778573393821716,
      -0.04655507579445839,
      -0.00034206497366540134,
      0.020390920341014862,
      -0.027089161798357964,
      0.016110925003886223,
      -0.008025706745684147,
      0.0440855547785759,
      0.0815630629658699,
      0.0810324028134346,
      0.0736311674118042,
      -0.0461365208029747,
      -0.02462192066013813,
      -0.07548340409994125,
      -0.02340998873114586,
      -0.09266989678144455,
      0.12659023702144623,
      0.0014087747549638152,
      0.020099889487028122,
      0.04095751419663429,
      0.019718017429113388,
      -0.06007583066821098,
      -0.049273502081632614,
      0.1190958097577095,
      -0.012159433215856552,
      -0.026205889880657196,
      -0.03473883122205734,
      0.038536250591278076,
      0.05320705100893974,
      -0.017266809940338135,
      0.023991761729121208,
      -0.028134509921073914,
      -0.1003124862909317,
      -0.09304289519786835,
      -0.0041295611299574375,
      0.03780375048518181,
      0.03633919730782509,
      -0.10929332673549652,
      -0.053074758499860764,
      0.018011238425970078,
      -0.051522720605134964,
      0.01970052532851696,
      0.017165767028927803,
      -0.05298013240098953,
      0.0038350552786141634,
      -0.04781440272927284,
      -0.007986271753907204,
      -0.02964182011783123,
      0.09937125444412231,
      0.052506137639284134,
      0.027007637545466423,
      -0.12074872851371765,
      -0.010213863104581833,
      -0.030262257903814316,
      -0.053774621337652206,
      0.03876567631959915,
      -0.05173509567975998,
      -0.0033754457253962755,
      -0.0002979521523229778,
      0.04936230182647705,
      -0.06336864829063416,
      -0.053015414625406265,
      -0.050295233726501465,
      -0.04195690155029297,
      0.0022459004539996386,
      0.08159639686346054,
      0.056098613888025284,
      -0.02714165300130844,
      -0.056983109563589096,
      0.015359674580395222,
      0.03195398300886154,
      0.0679682195186615,
      -0.030363181605935097,
      0.022541381418704987,
      -0.04789666086435318,
      0.04122254624962807,
      0.03180171549320221,
      -0.03213820233941078,
      -0.005612414330244064,
      -0.05054681375622749,
      0.02066575363278389,
      -0.020965436473488808,
      -0.016065295785665512,
      -0.01045315247029066,
      0.012459085322916508,
      -0.05616864189505577,
      -0.03660701587796211,
      -0.049552612006664276,
      -0.027131255716085434,
      0.013827691785991192,
      0.0066480375826358795,
      0.007142721675336361,
      -0.006817440502345562,
      0.01892690546810627,
      -0.04280069097876549,
      -0.027915770187973976,
      -0.012761370278894901,
      0.06170755252242088,
      -0.0017226524651050568,
      0.040230266749858856,
      0.0026900041848421097,
      0.05090467259287834,
      -0.00675022229552269,
      0.0337752141058445,
      -0.04501791670918465,
      0.027407949790358543,
      0.01994291879236698,
      -0.0049171485006809235,
      0.03301943466067314,
      -0.0065197027288377285,
      -0.07646474242210388,
      -0.05267880856990814,
      -0.02957332134246826,
      -0.07788070291280746,
      0.01532444916665554,
      -0.027517255395650864,
      -0.0659155547618866,
      -0.087155781686306,
      -0.029628563672304153,
      -0.017682276666164398,
      0.028767094016075134,
      0.08486030995845795,
      0.044884949922561646,
      0.0215220395475626,
      0.05433831363916397,
      0.012771112844347954,
      -0.05577615648508072,
      -0.04155755788087845,
      -0.029706688597798347,
      0.021964173763990402,
      0.0655817985534668,
      -0.01907462254166603,
      -0.004956620279699564,
      0.01397857815027237,
      -0.03059770166873932,
      0.02932954952120781,
      0.024932220578193665,
      -0.026355037465691566,
      0.050192076712846756,
      -0.02924283966422081,
      -0.048984721302986145,
      -0.036685798317193985,
      -0.07856534421443939,
      0.05367293953895569,
      0.05751344934105873,
      0.0571676529943943,
      -0.04010249674320221,
      -0.02641562931239605,
      0.025942547246813774,
      0.020428288727998734,
      -0.04844384640455246,
      0.06302685290575027,
      0.08611560612916946,
      -0.012589388526976109,
      -0.0191620122641325,
      -0.0105343172326684,
      0.03620431572198868,
      0.01526420097798109,
      0.06508138030767441,
      0.035900529474020004,
      -0.05525292828679085,
      -0.021149447187781334,
      0.008413231000304222,
      -0.04224510118365288,
      -0.07559038698673248,
      0.002497307723388076,
      -0.018582047894597054,
      0.07925586402416229,
      0.04854859411716461,
      -0.033661287277936935,
      -0.0010436134180054069,
      0.031130805611610413,
      -0.035249266773462296,
      -0.07010838389396667,
      -0.016765864565968513,
      0.047473303973674774,
      0.013419779017567635,
      0.0778999850153923,
      -0.040176503360271454,
      -0.07136374711990356,
      -0.017913853749632835,
      0.011753564700484276,
      0.023687049746513367,
      0.04735207185149193,
      -0.019195135682821274,
      -0.06434091925621033,
      -0.056137584149837494,
      -0.03348216041922569,
      0.06520663946866989,
      -0.04218745604157448,
      -0.061958201229572296,
      0.011413083411753178,
      0.01959052123129368,
      0.05948825180530548,
      0.001261976663954556,
      -0.016165953129529953,
      -0.021867504343390465,
      -0.0021333324257284403,
      -0.02317967638373375,
      -0.012508369982242584,
      0.023779412731528282,
      -0.004347524605691433,
      0.06012063845992088,
      -0.03078504279255867,
      0.040124837309122086,
      -0.04796331003308296,
      -0.006907481700181961,
      -0.05469143018126488,
      0.0045968336053192616,
      0.06646163016557693,
      0.024858439341187477,
      -0.006065831054002047,
      -0.0021767832804471254,
      0.03091038577258587,
      0.026206478476524353,
      0.013724403455853462,
      -0.02056266926229,
      0.03752470016479492,
      -0.0015231299912557006,
      0.004437652882188559,
      -0.056152913719415665,
      -0.07843677699565887,
      -0.01664615236222744,
      0.018116818740963936,
      -0.08571088314056396,
      -0.03499283269047737,
      0.008164070546627045,
      0.02463456057012081,
      -0.02860121615231037,
      0.04785274714231491,
      0.028866881504654884,
      -0.036246661096811295,
      -0.03466873615980148,
      -0.020491503179073334,
      0.005614005029201508,
      0.006659788079559803,
      -0.07906226813793182,
      -0.057389624416828156,
      -0.04438314586877823,
      0.12782643735408783,
      0.03831547126173973,
      0.04137653112411499,
      -0.04899423196911812,
      -0.08903530240058899,
      -0.031259503215551376,
      0.025641458109021187,
      0.05525996536016464,
      0.013385157100856304,
      -0.09010852873325348,
      -0.03434181585907936,
      -0.03949962556362152,
      0.0009747996227815747,
      -0.029096141457557678,
      0.01069110631942749,
      -0.006002248264849186,
      0.0029838532209396362,
      -0.08169171214103699,
      -0.01807626523077488,
      0.05846141651272774,
      -0.017516862601041794,
      0.025652442127466202,
      0.06050728261470795,
      0.04566903039813042,
      -0.01778203621506691,
      -0.013567780144512653,
      -0.03944968804717064,
      0.037150945514440536,
      -0.03802650421857834,
      -0.000681654957588762,
      -0.0016038949834182858,
      -0.07450814545154572,
      -0.07429549843072891,
      -0.033499136567115784,
      0.02486797235906124,
      0.048896823078393936,
      -0.028255175799131393,
      -0.019432391971349716,
      0.013294683769345284,
      -0.11154571175575256,
      0.062421489506959915,
      0.01116339210420847,
      0.04380723088979721,
      -0.0006737483781762421,
      0.025836482644081116,
      0.009355436079204082,
      0.08584093302488327,
      -0.010836147703230381,
      0.12139028310775757,
      0.009982102550566196,
      -0.060628168284893036,
      -0.011508993804454803,
      0.02727598138153553,
      -0.007683731149882078,
      0.009574775584042072,
      0.04455031082034111,
      0.046908073127269745,
      0.01073313970118761,
      0.09770967066287994,
      -0.06456654518842697,
      0.02864498645067215,
      -0.03608335182070732,
      0.047989603132009506,
      -0.014260835014283657,
      0.013077177107334137,
      0.018679006025195122,
      0.057312577962875366,
      0.03738170862197876,
      -0.023253461346030235,
      -0.033095307648181915,
      0.009473662823438644,
      -0.05386190861463547,
      0.008752468973398209,
      0.06246323511004448,
      0.05384932458400726,
      0.008101495914161205,
      0.0221712626516819,
      -0.030265050008893013,
      0.0005947559839114547,
      -0.006764635443687439,
      0.023770006373524666,
      -0.015475899912416935,
      0.029248759150505066,
      -0.09651140868663788,
      -0.020921846851706505,
      -0.00297359935939312,
      -0.02111395262181759,
      -0.06801682710647583,
      -0.043933186680078506,
      0.009616469964385033,
      5.51200719201006e-05,
      -0.014129465445876122,
      -0.03387826308608055,
      -0.010414285585284233,
      -0.03161490336060524,
      0.040398068726062775,
      -0.03432166576385498,
      -0.044480327516794205,
      0.01457283366471529,
      0.018597157672047615,
      0.06667555868625641,
      -0.006036716978996992,
      -0.023279890418052673,
      0.028370754793286324,
      0.02469812147319317,
      0.017429715022444725,
      -0.011397802270948887,
      0.06294131278991699,
      0.009892978705465794,
      -0.012127452529966831,
      0.026672139763832092,
      -0.023344051092863083,
      0.017426718026399612,
      -0.09421492367982864,
      0.0030633944552391768,
      -0.00500140804797411,
      -0.0330544151365757,
      0.06438770145177841,
      -0.018580595031380653,
      -0.07926778495311737,
      0.028818564489483833,
      0.006988822482526302,
      0.028634637594223022,
      -0.046827830374240875,
      0.053999897092580795,
      0.039478741586208344,
      0.02033132314682007,
      0.005279146134853363,
      -0.0017975529190152884,
      -0.04094544053077698,
      0.028285255655646324,
      -0.00213194009847939,
      -0.1314365565776825,
      0.07333911955356598,
      0.07711343467235565,
      0.0241971667855978,
      0.0454706996679306,
      0.018060294911265373,
      -0.014847534708678722,
      -0.0004155054048169404,
      0.003942389972507954,
      -0.010311025194823742,
      0.08178018778562546,
      0.015714922919869423,
      -0.06107441708445549,
      0.010579409077763557,
      -0.08148122578859329,
      -0.011005967855453491,
      0.00618296442553401,
      -0.0015452400548383594,
      0.013568480499088764,
      -0.04553831368684769,
      -0.005791993346065283,
      -0.04321923851966858,
      -0.06802155077457428,
      0.06992428004741669,
      0.002654109615832567,
      -0.008645478636026382,
      0.046651601791381836,
      0.01571374200284481,
      0.0302194282412529,
      -0.06889264285564423,
      0.04761888086795807,
      0.028747374191880226,
      0.024516530334949493,
      0.0037815708201378584,
      0.024299396201968193,
      -0.03897413611412048,
      0.0019318420672789216,
      -0.024535246193408966,
      -0.043828804045915604,
      -0.00762212835252285,
      -0.007923315279185772,
      0.03849807754158974,
      0.07687480002641678,
      -0.016697973012924194,
      0.023781530559062958,
      0.009848472662270069,
      -0.021350746974349022,
      0.008200734853744507,
      -0.09616314619779587,
      -0.00189547473564744,
      0.0037388387136161327,
      -0.03840027377009392,
      0.03719554841518402,
      0.02539837546646595,
      0.004925481043756008,
      0.018105797469615936,
      -0.009230958297848701,
      0.017084529623389244,
      0.07969508320093155,
      0.011990846134722233,
      -0.0035342813935130835,
      -0.023712970316410065,
      -0.007832439616322517,
      0.03176368400454521,
      0.0013223945861682296,
      -0.039647504687309265,
      0.0005548360059037805,
      -0.040829043835401535,
      0.01876101829111576,
      -0.051244042813777924,
      0.032868899405002594,
      -0.058854565024375916,
      -0.004165892954915762,
      -0.006375601515173912,
      -0.09516439586877823,
      0.03386545181274414,
      0.01855260133743286,
      -0.039089202880859375,
      0.01213151216506958,
      -0.013078945688903332,
      -0.013993318192660809,
      0.03333454206585884,
      0.005895444191992283,
      0.034256234765052795,
      -0.02346108667552471,
      0.03609186038374901,
      -0.04729422181844711,
      0.0563996396958828,
      -0.05919608473777771,
      -0.0340682752430439,
      -0.041475530713796616,
      0.008540079928934574,
      -0.0038473682943731546,
      -0.007100176997482777,
      -0.08677104115486145,
      -0.05295133590698242,
      -0.08490683138370514,
      -0.002035546349361539,
      -0.004611771088093519,
      0.06707917898893356,
      -0.05460048094391823,
      0.0015811159973964095,
      0.03324116766452789,
      -0.018007485195994377,
      -0.03753512725234032,
      0.05103638768196106,
      -0.03233977407217026,
      -0.010145002044737339,
      0.0053711761720478535,
      0.0844067633152008,
      0.01574164442718029,
      0.05031301826238632,
      0.02703600749373436,
      0.04521814361214638,
      -0.0528101809322834,
      0.03396008908748627,
      0.005616335663944483,
      0.031018517911434174,
      0.05784812942147255,
      0.04206474870443344,
      -0.01741030253469944,
      0.028765613213181496,
      0.0017538777319714427,
      0.009681436233222485,
      -0.007691269740462303,
      -0.018877310678362846,
      0.0875491201877594,
      -0.03779899328947067,
      -0.040485914796590805,
      -0.0442068912088871,
      -0.05560026317834854,
      0.002544594928622246
    ],
    [
      -0.07065039873123169,
      -0.030524717643857002,
      0.0029797826427966356,
      -0.016960429027676582,
      0.0599253810942173,
      0.016563141718506813,
      0.01822316087782383,
      -0.017974860966205597,
      -0.02692302316427231,
      -0.022434283047914505,
      0.0031822146847844124,
      0.020772023126482964,
      0.07130224257707596,
      -0.0901576578617096,
      0.031015509739518166,
      0.026174573227763176,
      0.09616058319807053,
      -0.08027508854866028,
      0.016060611233115196,
      -0.08163385838270187,
      -0.005246090702712536,
      -0.03390219435095787,
      0.037969350814819336,
      0.029346710070967674,
      -0.015058176591992378,
      -0.04709768667817116,
      -0.05604876950383186,
      -0.04096587002277374,
      -0.007463261019438505,
      -0.02025601640343666,
      -0.05543549731373787,
      -0.014175478368997574,
      -0.03936681151390076,
      0.02606026642024517,
      -0.0015980126336216927,
      0.01321090292185545,
      0.0558248795568943,
      -0.050431326031684875,
      0.005081168841570616,
      -0.012115641497075558,
      -0.09506203979253769,
      -0.07003317028284073,
      -0.006467267405241728,
      0.007138695102185011,
      -0.0361831858754158,
      -0.04092513024806976,
      0.022054729983210564,
      0.07178846746683121,
      0.036279622465372086,
      0.019742930307984352,
      0.05126126483082771,
      0.015102202072739601,
      0.017516357824206352,
      0.01919129118323326,
      -0.09958545863628387,
      0.01899729296565056,
      0.00909944623708725,
      0.049746155738830566,
      0.006614672485738993,
      -0.006515289191156626,
      0.015419108793139458,
      -0.0553959496319294,
      -0.0038976294454187155,
      0.056266821920871735,
      0.06758148223161697,
      0.09200355410575867,
      -0.021787161007523537,
      -0.039080459624528885,
      -0.03880639374256134,
      0.0337638221681118,
      0.041374970227479935,
      -0.044918302446603775,
      0.02581559307873249,
      0.020232871174812317,
      0.05671783536672592,
      0.007323721423745155,
      0.08429037779569626,
      0.09017429500818253,
      0.05967089161276817,
      0.030856600031256676,
      0.04261716455221176,
      -0.0434442013502121,
      -0.06947959959506989,
      -0.000934339826926589,
      0.026556290686130524,
      0.010547462850809097,
      -0.06221591308712959,
      0.05844900757074356,
      -0.030332397669553757,
      0.10614006221294403,
      -0.003973846323788166,
      -0.03206748887896538,
      -0.01905103772878647,
      0.05765779688954353,
      -0.012341209687292576,
      -0.0028232145123183727,
      -0.0302559994161129,
      0.03212735056877136,
      -0.007366630248725414,
      -0.042777661234140396,
      0.033606018871068954,
      0.021905751898884773,
      -0.0568731427192688,
      -0.046993158757686615,
      -0.03236723318696022,
      0.007516729645431042,
      -0.020703110843896866,
      -0.028721779584884644,
      0.06089625880122185,
      -0.04933936521410942,
      0.003586888313293457,
      -0.0692770704627037,
      0.03814147785305977,
      -0.02170340158045292,
      0.060680780559778214,
      -0.0026682568714022636,
      -0.013779928907752037,
      -0.05623312667012215,
      0.057672906666994095,
      0.02775184065103531,
      0.03682609647512436,
      0.024908537045121193,
      -0.004603564273566008,
      -0.02748229168355465,
      -0.04211568087339401,
      0.011566530913114548,
      -0.05856948718428612,
      0.0005891090841032565,
      -0.03223882615566254,
      -0.03552676737308502,
      -0.0004879918124061078,
      0.027795160189270973,
      0.024216696619987488,
      -0.011464769951999187,
      0.0033591208048164845,
      -0.02843502350151539,
      0.008573471568524837,
      0.021653441712260246,
      -0.024425437673926353,
      -0.005076571833342314,
      0.0264494176954031,
      -0.0011188933858647943,
      0.014005660079419613,
      0.0017364753875881433,
      0.10002968460321426,
      -0.003457859857007861,
      0.033853914588689804,
      -0.020455699414014816,
      0.04873301461338997,
      -0.006479376461356878,
      -0.03358246386051178,
      0.03639230504631996,
      -0.05441633611917496,
      0.035303156822919846,
      0.03226013109087944,
      -0.05129233002662659,
      -0.011982574127614498,
      0.04385732114315033,
      -0.040763214230537415,
      -0.038976531475782394,
      0.023572757840156555,
      0.029666000977158546,
      -0.02079193852841854,
      -0.04782537370920181,
      -0.033321626484394073,
      -0.03673893213272095,
      0.010989490896463394,
      -0.0018222966464236379,
      0.09455617517232895,
      -0.034153249114751816,
      -0.012682626955211163,
      0.03383001312613487,
      -0.009795129299163818,
      -0.008186846040189266,
      -0.05208524316549301,
      0.06252719461917877,
      -0.010335221886634827,
      0.03312619403004646,
      -0.012892501428723335,
      0.06700637191534042,
      0.04564864933490753,
      -0.12462887167930603,
      0.06560606509447098,
      0.001871719490736723,
      0.023500941693782806,
      0.0018917977577075362,
      -0.02877858281135559,
      -0.04214610531926155,
      0.03880950063467026,
      -0.042308542877435684,
      0.045557815581560135,
      0.018674148246645927,
      0.03930661827325821,
      0.0059669045731425285,
      0.07970645278692245,
      0.07541964203119278,
      -0.019018037244677544,
      -0.007394109852612019,
      -0.019434118643403053,
      0.07847044616937637,
      -0.03802246227860451,
      0.014614764600992203,
      -0.03422003239393234,
      -0.08621573448181152,
      0.017433272674679756,
      -0.02266867645084858,
      -0.012958859093487263,
      -0.02988956682384014,
      0.015350399538874626,
      0.048289187252521515,
      0.0860714390873909,
      -0.0007162047550082207,
      0.04850614070892334,
      0.06874307245016098,
      0.010679105296730995,
      -0.04320840165019035,
      -0.053502313792705536,
      0.033890534192323685,
      -0.0232600849121809,
      0.0266326442360878,
      0.011576465331017971,
      -0.006487542763352394,
      0.0641859769821167,
      0.015064611099660397,
      0.04697801172733307,
      0.01872916705906391,
      0.030409423634409904,
      0.01678846776485443,
      0.07018450647592545,
      0.08029183745384216,
      0.002972189337015152,
      0.03268653154373169,
      -0.003825668478384614,
      -0.0264122411608696,
      0.00234315637499094,
      -0.01441795565187931,
      -0.018033355474472046,
      -0.00340562267228961,
      0.0843019112944603,
      -0.01136715617030859,
      -0.040420036762952805,
      -0.02795286476612091,
      0.0417967364192009,
      0.014319664798676968,
      0.07098962366580963,
      0.01320274081081152,
      0.08201750367879868,
      -0.009067652747035027,
      -0.07709827274084091,
      -0.00592259643599391,
      -0.014341547153890133,
      0.032394926995038986,
      0.007614030968397856,
      -0.01000302191823721,
      0.0469672828912735,
      0.034152984619140625,
      -0.0013723265146836638,
      0.002852386562153697,
      0.057273052632808685,
      -0.05253693088889122,
      -0.021844428032636642,
      0.13686037063598633,
      0.11952381581068039,
      0.0937519297003746,
      0.02936452254652977,
      0.06748343259096146,
      0.006897980347275734,
      0.024015018716454506,
      0.004289605189114809,
      -0.025890665128827095,
      -0.015789413824677467,
      -0.04876973107457161,
      0.0010036195162683725,
      0.01421243604272604,
      -0.031957171857357025,
      -0.036539338529109955,
      0.0017642618622630835,
      -0.011813227087259293,
      0.033301208168268204,
      -0.0093684121966362,
      0.02851366437971592,
      -0.017037885263562202,
      0.0707683265209198,
      -0.03194272518157959,
      0.06164202094078064,
      0.05731659382581711,
      0.03898308798670769,
      -0.027038956061005592,
      0.010948539711534977,
      -0.010402754880487919,
      -0.028473971411585808,
      -0.10510461777448654,
      -0.014530552551150322,
      0.053994111716747284,
      -0.06866979598999023,
      0.10995922237634659,
      0.01737561635673046,
      0.022287806496024132,
      -0.013666781596839428,
      -0.05765301361680031,
      0.014018058776855469,
      0.026402121409773827,
      -0.019368857145309448,
      0.10933516919612885,
      -0.027506183832883835,
      0.05752945318818092,
      0.0192674919962883,
      0.049997542053461075,
      0.022409237921237946,
      -0.032356515526771545,
      -0.003874649992212653,
      0.02388131245970726,
      0.08912724256515503,
      0.006097159814089537,
      -0.04104958102107048,
      -0.024263834580779076,
      0.06480683386325836,
      0.05995982512831688,
      -0.01199090201407671,
      -0.03065567836165428,
      -0.05031697452068329,
      -0.01536372397094965,
      -0.048895213752985,
      0.04899358004331589,
      -0.1218908503651619,
      -0.009048199281096458,
      -0.02310311049222946,
      -0.0014600764261558652,
      -0.013497790321707726,
      0.022723907604813576,
      -0.024435950443148613,
      -0.055555861443281174,
      -0.018545307219028473,
      0.008650765754282475,
      -0.012213082052767277,
      0.07904963940382004,
      0.0015943445032462478,
      0.044133950024843216,
      0.0036480228882282972,
      -0.033552978187799454,
      -0.07576031237840652,
      0.006460115779191256,
      0.028210867196321487,
      -0.016130290925502777,
      0.025157596915960312,
      -0.010302995331585407,
      0.08029382675886154,
      -0.025781942531466484,
      -0.06326425820589066,
      0.011093156412243843,
      0.04867095872759819,
      -0.015919310972094536,
      -0.000754516979213804,
      -0.0702180340886116,
      -0.09014710038900375,
      -0.03343424201011658,
      0.010260484181344509,
      -0.025082169100642204,
      -0.030131302773952484,
      -0.006511963903903961,
      0.043028753250837326,
      -0.0722668319940567,
      -0.04046550393104553,
      -0.019134465605020523,
      0.020848847925662994,
      -0.008330835960805416,
      -0.02444741502404213,
      0.0706862285733223,
      0.06658416986465454,
      -0.006312164478003979,
      0.08608806878328323,
      -0.07983314245939255,
      -0.013745772652328014,
      0.060759034007787704,
      -0.0866367518901825,
      0.006054713856428862,
      -0.07280579954385757,
      -0.04334577918052673,
      0.01366322860121727,
      -0.05207955464720726,
      0.05733196809887886,
      -0.08903282135725021,
      0.04766394570469856,
      -0.03982938826084137,
      -0.018979541957378387,
      -0.009202488698065281,
      0.03538310155272484,
      -0.011260361410677433,
      0.06303470581769943,
      0.0059195831418037415,
      -0.03292449936270714,
      -0.01951071061193943,
      -0.008744165301322937,
      -0.01346240472048521,
      0.041859064251184464,
      -0.01725771650671959,
      0.023012451827526093,
      0.008088281378149986,
      0.047724079340696335,
      -0.009920797310769558,
      -0.02544708549976349,
      -0.007604533340781927,
      -0.01848016120493412,
      -0.0381401889026165,
      0.04521320387721062,
      0.04712645709514618,
      0.028430594131350517,
      0.10451820492744446,
      0.04251275956630707,
      0.0042582410387694836,
      -0.0012172157876193523,
      -0.04243280366063118,
      0.0016760737635195255,
      0.0048831431195139885,
      -0.046397898346185684,
      0.007501673419028521,
      -0.04749049246311188,
      0.008332520723342896,
      0.06984906643629074,
      -0.04879313334822655,
      -0.06008749455213547,
      -0.02465270459651947,
      -0.014547912403941154,
      0.027578385546803474,
      -0.0015697083435952663,
      0.049846041947603226,
      0.041110727936029434,
      0.06250744313001633,
      0.013358925469219685,
      0.08331730961799622,
      0.002269383054226637,
      0.06669694185256958,
      0.003179528284817934,
      -0.026330575346946716,
      -0.03883868455886841,
      0.012388747185468674,
      -0.002381120342761278,
      0.056325122714042664,
      -0.055203381925821304,
      -0.01705385372042656,
      -0.04966355487704277,
      -0.02084549143910408,
      -0.059774093329906464,
      0.06991368532180786,
      -0.0328180193901062,
      0.02982095070183277,
      0.0013540282379835844,
      -0.04442816600203514,
      -0.07534191757440567,
      -0.01797311007976532,
      -0.12293972820043564,
      -0.06419637799263,
      -0.06335395574569702,
      0.042994000017642975,
      0.03221236541867256,
      0.08253628760576248,
      -0.00926706101745367,
      -0.01591075025498867,
      1.131840417656349e-05,
      -0.014006166718900204,
      -0.05124281719326973,
      -0.04898042231798172,
      0.01795784756541252,
      -0.07476624846458435,
      0.02050868235528469,
      -0.012253227643668652,
      -0.02420952543616295,
      0.000678933341987431,
      0.015321467071771622,
      0.0752931609749794,
      0.085500568151474,
      0.005284804385155439,
      0.03426935523748398,
      -0.007781787309795618,
      -0.006948416121304035,
      -0.016461916267871857,
      0.030133889988064766,
      0.033678118139505386,
      0.05702027678489685,
      0.04806281998753548,
      -0.08563423901796341,
      -0.010474839247763157,
      0.01306723989546299,
      0.05621809884905815,
      -0.01508908998221159,
      0.016637450084090233,
      0.022886639460921288,
      -0.011235681362450123,
      0.029643170535564423,
      0.05520649254322052,
      -0.04848874360322952,
      -0.04947526380419731,
      0.009989462792873383,
      -0.00848808791488409,
      0.12950432300567627,
      -0.012272318825125694,
      -0.05683239549398422,
      0.05116136744618416,
      0.004647335968911648,
      -0.009063869714736938,
      0.036892008036375046,
      0.012102194130420685,
      -0.00023473624605685472
    ],
    [
      -0.047550175338983536,
      -0.043662235140800476,
      -0.06677977740764618,
      0.05698857456445694,
      -0.03727152198553085,
      -0.06165056675672531,
      0.0637827068567276,
      -0.0910320058465004,
      0.027022216469049454,
      0.005854026414453983,
      -0.0351899079978466,
      0.016358958557248116,
      -0.026496335864067078,
      -0.030964771285653114,
      -0.03109515644609928,
      0.04861196503043175,
      0.047442466020584106,
      0.021286245435476303,
      0.017167644575238228,
      -0.019543617963790894,
      0.006190369836986065,
      -0.005258587654680014,
      0.045786917209625244,
      -0.0199834406375885,
      -0.003591473214328289,
      -0.0561351552605629,
      -0.01085689291357994,
      0.011421382427215576,
      0.07134600728750229,
      0.000347090361174196,
      0.03889428824186325,
      -0.0033421521075069904,
      -0.026021873578429222,
      -0.017765343189239502,
      -0.04096674546599388,
      0.012877382338047028,
      0.012454490177333355,
      -0.04434783011674881,
      0.03519230708479881,
      -0.062114398926496506,
      0.08977143466472626,
      0.03131444379687309,
      -0.04116888344287872,
      -0.026845693588256836,
      -0.05688810721039772,
      -0.009709712117910385,
      0.02985544130206108,
      0.021354584023356438,
      0.03359300270676613,
      -0.04767820984125137,
      0.05322856083512306,
      -0.026981310918927193,
      -0.003963256720453501,
      0.04899242892861366,
      -0.10268009454011917,
      0.05486130341887474,
      -0.055861957371234894,
      -0.00552754383534193,
      -0.019767213612794876,
      0.031039871275424957,
      -0.023618223145604134,
      -0.027700109407305717,
      -0.03614894300699234,
      0.014249916188418865,
      -0.06495370715856552,
      -0.003992450423538685,
      -0.007829270325601101,
      -0.0013811065582558513,
      0.01691223680973053,
      -0.00991524662822485,
      -0.08325061947107315,
      -0.0034073134884238243,
      0.0480632483959198,
      0.029206890612840652,
      0.05582967773079872,
      -0.008168634958565235,
      0.09570616483688354,
      0.022096121683716774,
      -0.10441053658723831,
      0.06847649067640305,
      0.027797523885965347,
      -0.002569311298429966,
      -0.026030344888567924,
      0.06311631947755814,
      0.033393941819667816,
      0.009469293989241123,
      0.10276684165000916,
      0.03815700486302376,
      0.011194628663361073,
      0.009967580437660217,
      0.06339927017688751,
      -0.004504031036049128,
      -0.11034607887268066,
      -0.10639257729053497,
      0.029035044834017754,
      0.014524753205478191,
      -0.06787125766277313,
      0.014642381109297276,
      -0.03705756366252899,
      0.021517543122172356,
      -0.03686191886663437,
      0.09143425524234772,
      0.00444281380623579,
      0.06702068448066711,
      0.00985447783023119,
      0.0028436416760087013,
      -0.022039515897631645,
      0.06487155705690384,
      0.09723047912120819,
      -0.04934583604335785,
      0.015166462399065495,
      0.044881660491228104,
      -0.02310134470462799,
      -0.03157138451933861,
      0.004481561481952667,
      0.007608580868691206,
      -0.05281718075275421,
      0.028764326125383377,
      -0.07350020110607147,
      0.026022743433713913,
      -0.055098988115787506,
      0.038008708506822586,
      -0.0022596977651119232,
      -0.0024409748148173094,
      0.01359659805893898,
      -0.006695528049021959,
      0.0572875440120697,
      -0.11848726868629456,
      -0.02158460207283497,
      0.008388909511268139,
      0.03700665757060051,
      0.09058118611574173,
      -0.052682533860206604,
      -0.05650178715586662,
      0.0606062151491642,
      -0.023800833150744438,
      0.020015444606542587,
      0.025258801877498627,
      0.06199900433421135,
      -0.017078550532460213,
      0.027137380093336105,
      -0.03916580229997635,
      -0.11208384484052658,
      0.02976594679057598,
      0.023919081315398216,
      -0.03285450488328934,
      0.12064388394355774,
      0.008092840202152729,
      -0.06821327656507492,
      0.00020562924328260124,
      -0.04003455489873886,
      -0.04472661018371582,
      0.04402364045381546,
      0.07176519930362701,
      0.027433693408966064,
      0.0382453128695488,
      0.02043829672038555,
      -0.006112829316407442,
      0.03266540914773941,
      0.05619526281952858,
      0.01580634154379368,
      -0.10559608042240143,
      -0.031476445496082306,
      -0.030732490122318268,
      0.03607495129108429,
      0.0075928508304059505,
      0.006688978988677263,
      0.017582586035132408,
      0.0037831151857972145,
      0.03419503569602966,
      -0.0921880304813385,
      -0.03513970971107483,
      -0.044336672872304916,
      0.0074579487554728985,
      -0.027168795466423035,
      0.0011809483403339982,
      -0.000695300695952028,
      0.0024991934187710285,
      0.01944439671933651,
      -0.029888562858104706,
      0.07990714907646179,
      0.03299003094434738,
      -0.033911220729351044,
      0.07088205218315125,
      0.004789917729794979,
      -0.034906622022390366,
      0.0478634312748909,
      -0.005489028058946133,
      0.06311202794313431,
      0.024213816970586777,
      -0.023946339264512062,
      -0.023662198334932327,
      0.020760340616106987,
      0.020697034895420074,
      0.06368427723646164,
      0.05533537641167641,
      -0.10605922341346741,
      -0.08818087726831436,
      0.0018104390474036336,
      0.053640205413103104,
      -0.030237793922424316,
      0.027365176007151604,
      0.052467942237854004,
      0.005857507698237896,
      -0.003388869808986783,
      0.008106592111289501,
      -0.08630397170782089,
      -0.10478568077087402,
      0.04641927033662796,
      -0.02748047560453415,
      0.03582463413476944,
      0.012634406797587872,
      -0.05057578533887863,
      -0.0033573179971426725,
      -0.006023325491696596,
      0.04664798825979233,
      0.04247806593775749,
      -0.006034522317349911,
      -0.026882417500019073,
      -0.028565173968672752,
      0.10493673384189606,
      -0.09579841047525406,
      -0.02838849648833275,
      -0.03371334820985794,
      0.02632622793316841,
      -0.008276687003672123,
      -0.010136940516531467,
      0.004330717958509922,
      0.05327003076672554,
      0.10187787562608719,
      -0.03403099998831749,
      0.08013690263032913,
      -0.009670034050941467,
      -0.013266920112073421,
      0.09287335723638535,
      0.040281523019075394,
      0.016255730763077736,
      -0.08672302216291428,
      0.02584804594516754,
      0.0012260004878044128,
      -0.008311205543577671,
      -0.04501254856586456,
      -0.024157673120498657,
      -0.030491791665554047,
      -0.030307095497846603,
      0.03385850414633751,
      -0.034789834171533585,
      0.0454590730369091,
      -0.02486131526529789,
      -0.023541906848549843,
      -0.08870715647935867,
      0.0259074904024601,
      0.00751462671905756,
      -0.12138990312814713,
      0.007929881103336811,
      -0.020951522514224052,
      0.09551801532506943,
      0.011262692511081696,
      0.06782638281583786,
      -0.00792845617979765,
      -0.027463259175419807,
      0.044722992926836014,
      -0.09180554002523422,
      0.03027874231338501,
      -0.04935671016573906,
      -0.004556306172162294,
      -0.02144639752805233,
      0.013670934364199638,
      0.036126960068941116,
      -0.03579233959317207,
      -0.030031226575374603,
      0.01918169856071472,
      -0.021361155435442924,
      -0.009365090169012547,
      0.07960064709186554,
      0.014106633141636848,
      -0.007060781121253967,
      0.004076099023222923,
      -0.05003676936030388,
      0.0878872349858284,
      0.0081147700548172,
      -0.005078831221908331,
      0.013851436786353588,
      -0.04935593903064728,
      0.05163848027586937,
      0.01888272352516651,
      0.00995346438139677,
      0.045100510120391846,
      -0.0199473574757576,
      0.048378631472587585,
      -0.027274861931800842,
      -0.04920345917344093,
      -0.04464432969689369,
      -0.05971469730138779,
      0.07269130647182465,
      0.01612062379717827,
      0.07323502749204636,
      0.0023921560496091843,
      -0.02356073632836342,
      0.044294655323028564,
      0.02370806410908699,
      -0.06297048926353455,
      0.08579539507627487,
      0.025353701785206795,
      -0.017101723700761795,
      0.044067323207855225,
      -0.0950092300772667,
      -0.013328755274415016,
      0.024776319041848183,
      0.012112490832805634,
      0.06476873904466629,
      0.0036389806773513556,
      0.04506535828113556,
      -0.012709683738648891,
      0.008145676925778389,
      0.02482564188539982,
      0.001906644320115447,
      -0.07305815070867538,
      -0.028245827183127403,
      0.006488691549748182,
      -0.013554690405726433,
      0.021553706377744675,
      -0.05647232010960579,
      -0.007463766727596521,
      -0.02967512421309948,
      0.03984973579645157,
      0.02048380672931671,
      -0.011409864760935307,
      0.029702231287956238,
      -0.004358700942248106,
      -0.09740713238716125,
      -0.053843170404434204,
      -0.07640862464904785,
      -0.005591700319200754,
      -0.018331946805119514,
      0.05405344441533089,
      -0.01585405133664608,
      0.004253338556736708,
      0.058263782411813736,
      -0.008483489975333214,
      0.016378315165638924,
      -0.013497261330485344,
      0.0164435263723135,
      -0.08630095422267914,
      -0.018647395074367523,
      0.02346106991171837,
      0.05250001698732376,
      -0.02054985798895359,
      -0.09902448952198029,
      0.033086780458688736,
      -0.002411483321338892,
      0.031116720288991928,
      0.09400385618209839,
      0.00799613632261753,
      -0.10137616842985153,
      -0.046383846551179886,
      0.037558723241090775,
      -0.1011158749461174,
      -0.03237888962030411,
      0.008522137999534607,
      0.034060146659612656,
      -0.04103196784853935,
      -0.06243596971035004,
      0.0872766375541687,
      -0.050163209438323975,
      -0.03913009911775589,
      -0.10722341388463974,
      -0.03177089989185333,
      -0.006766968872398138,
      0.03328276798129082,
      -0.06123720481991768,
      0.06701556593179703,
      0.05988733097910881,
      0.011045590043067932,
      -0.0407370999455452,
      -0.023033620789647102,
      -0.061817511916160583,
      -0.013584221713244915,
      0.0783262699842453,
      -0.013215461745858192,
      0.038499850779771805,
      -0.021197116002440453,
      -0.04093698412179947,
      0.032384905964136124,
      -0.012714242562651634,
      -0.025513777509331703,
      0.06383580714464188,
      -0.003754574805498123,
      0.02766735665500164,
      -0.031221244484186172,
      0.08365055173635483,
      -0.0033883966971188784,
      0.0006712423637509346,
      0.042221248149871826,
      0.11123506724834442,
      0.05047697573900223,
      -0.014564291574060917,
      0.002815923420712352,
      -0.0065144868567585945,
      0.021725721657276154,
      0.1220405250787735,
      -0.019371140748262405,
      -0.0472133606672287,
      0.015547661110758781,
      -0.06739647686481476,
      -0.04016954079270363,
      -0.0460548922419548,
      -0.019371317699551582,
      -0.07133427262306213,
      -0.0040877461433410645,
      0.01960756443440914,
      -0.03285153955221176,
      0.009105496108531952,
      -0.0010299383429810405,
      -0.0068885223008692265,
      0.005006487015634775,
      -0.027740759775042534,
      0.023936409503221512,
      0.02666541561484337,
      -0.039599284529685974,
      -0.07522283494472504,
      0.09528961032629013,
      0.0150348711758852,
      0.007471529301255941,
      -0.03270213305950165,
      0.03768112137913704,
      -0.02522697113454342,
      -0.03467207029461861,
      0.08573751151561737,
      0.029398949816823006,
      0.013838846236467361,
      -0.007762458641082048,
      0.01183952484279871,
      -0.037611134350299835,
      -0.0037754876539111137,
      0.010491498745977879,
      -0.07828447222709656,
      0.02455926313996315,
      0.05381740257143974,
      -0.05371318757534027,
      -0.007181996013969183,
      -0.03523355349898338,
      -0.027811750769615173,
      -0.001465503708459437,
      -0.007206279318779707,
      -0.07358160614967346,
      -0.04481131210923195,
      0.02152300626039505,
      -0.03440704569220543,
      -0.008825644850730896,
      0.008962126448750496,
      0.03458643704652786,
      0.021610502153635025,
      -0.009683676064014435,
      -0.03485872223973274,
      0.02059079147875309,
      0.02837175875902176,
      -0.017594942823052406,
      0.007081005722284317,
      -0.027719881385564804,
      -0.022002123296260834,
      0.0812113881111145,
      -0.03209255263209343,
      0.015938932076096535,
      -0.020660528913140297,
      -0.0666675716638565,
      -0.0033357248175889254,
      -0.03992946073412895,
      0.053470272570848465,
      -0.07823063433170319,
      -0.00251360097900033,
      -0.08254688233137131,
      0.07059023529291153,
      -0.0013418325688689947,
      0.005006860941648483,
      -0.06792370975017548,
      -0.046730343252420425,
      0.02030852437019348,
      -0.06011277437210083,
      -0.03515434265136719,
      -0.006816995330154896,
      -0.029318446293473244,
      0.00018780397658701986,
      0.0851866602897644,
      -0.0023861913941800594,
      -0.06849164515733719,
      0.006004389841109514,
      -0.007807656656950712,
      0.029180342331528664,
      -0.0767899602651596,
      -0.04943658038973808,
      0.01452618557959795,
      -0.014182248152792454,
      0.006227048579603434,
      -0.008436372503638268,
      -0.06816022843122482,
      0.05041665956377983,
      0.0002037078229477629,
      0.0162897277623415,
      -0.027419036254286766,
      0.017945313826203346,
      0.01895907334983349,
      -0.03282216191291809
    ],
    [
      -0.0449686199426651,
      -0.04765469208359718,
      -0.004052957985550165,
      -0.010698080994188786,
      0.018503913655877113,
      0.019425950944423676,
      -0.02491503208875656,
      0.09500046074390411,
      -0.028081433847546577,
      -0.02483121119439602,
      0.0010766140185296535,
      0.03338869288563728,
      -0.06072847545146942,
      0.022169839590787888,
      0.054278772324323654,
      0.037255823612213135,
      -0.03594367951154709,
      -0.007029418833553791,
      0.039574865251779556,
      -0.05021577700972557,
      -0.013511907309293747,
      -0.011735092848539352,
      0.009752282872796059,
      -0.04777834564447403,
      0.07492263615131378,
      -0.08251655846834183,
      0.04640081524848938,
      -0.020992912352085114,
      -0.05425162613391876,
      -0.019912084564566612,
      -0.04660562053322792,
      0.035313088446855545,
      0.03137814253568649,
      -0.008806787431240082,
      0.025787945836782455,
      0.049756769090890884,
      0.047334957867860794,
      0.009309913031756878,
      -0.04492621868848801,
      0.019562337547540665,
      0.03708934783935547,
      -0.07062426954507828,
      -0.03140334412455559,
      -0.05010765790939331,
      0.03783971071243286,
      -0.034664336591959,
      0.02947806380689144,
      0.003688207594677806,
      -0.0530739389359951,
      -0.11962806433439255,
      -0.018071018159389496,
      -0.008738311007618904,
      0.009235824458301067,
      -0.01753610000014305,
      0.06753882020711899,
      0.0018375393701717257,
      -0.04199281334877014,
      0.011111350730061531,
      0.11707409471273422,
      -0.03869330883026123,
      0.03164786472916603,
      -0.06537673622369766,
      0.008926593698561192,
      -0.009237474761903286,
      -0.025840014219284058,
      0.011236731894314289,
      -0.040997594594955444,
      -0.0350080169737339,
      0.03834868595004082,
      0.04672422260046005,
      -0.018265696242451668,
      0.0029972116462886333,
      0.06654161214828491,
      0.04779363051056862,
      0.08288595825433731,
      0.02480289898812771,
      0.02593378722667694,
      -0.017395760864019394,
      0.016552269458770752,
      -0.005101889371871948,
      0.026845688000321388,
      -0.09411688894033432,
      0.04442237317562103,
      0.027851395308971405,
      -0.009358558803796768,
      -0.02616751752793789,
      -0.002184143755584955,
      0.004975683521479368,
      0.03507670760154724,
      0.03561249375343323,
      0.007869359105825424,
      -0.08118836581707001,
      0.024609161540865898,
      -0.03697853907942772,
      0.044002898037433624,
      0.05004217475652695,
      0.01998429000377655,
      -0.0016138856299221516,
      -0.004272558260709047,
      0.013224400579929352,
      0.02807297371327877,
      -0.020223090425133705,
      -0.086403988301754,
      -0.06913744658231735,
      0.031180214136838913,
      -0.03714710846543312,
      -0.06960030645132065,
      -0.03496013209223747,
      0.006452720146626234,
      -0.08979026228189468,
      0.06615469604730606,
      -0.023317430168390274,
      0.023799464106559753,
      0.01467813178896904,
      0.014036043547093868,
      -0.004709047265350819,
      0.0643920972943306,
      0.014944835565984249,
      -0.017735200002789497,
      -0.008682974614202976,
      0.06293649226427078,
      -0.005784704349935055,
      -0.0822690799832344,
      -0.012643302790820599,
      0.06936608254909515,
      0.029649721458554268,
      -0.09686624258756638,
      -0.1121925562620163,
      -0.03661452233791351,
      -0.07880771905183792,
      -0.08094295114278793,
      -0.10601392388343811,
      0.0665913000702858,
      -0.0036254844162613153,
      0.023166978731751442,
      0.014817873947322369,
      0.04062431678175926,
      0.010255196131765842,
      -0.017483370378613472,
      -0.01969602331519127,
      0.06543532758951187,
      0.021715296432375908,
      -0.03000735305249691,
      -0.07293365150690079,
      -0.08172387629747391,
      -0.04244218021631241,
      -0.06370627135038376,
      0.008394419215619564,
      -0.07024017721414566,
      0.0009275165502913296,
      0.014068493619561195,
      -0.021759970113635063,
      -0.05226700007915497,
      0.00022973604791332036,
      0.061679963022470474,
      0.004286220762878656,
      -0.01616337150335312,
      -0.003082477254793048,
      0.0043627237901091576,
      0.02015993557870388,
      -0.06396505981683731,
      0.07663540542125702,
      0.044310495257377625,
      0.0066439383663237095,
      -0.004200151655822992,
      0.06631411612033844,
      -0.012182453647255898,
      0.04724869877099991,
      -0.042689695954322815,
      -0.08495303243398666,
      -0.05268390476703644,
      0.023535069078207016,
      -0.09318007528781891,
      0.07373902946710587,
      -0.08294520527124405,
      -0.046410661190748215,
      0.019599201157689095,
      -0.05287432670593262,
      0.07590029388666153,
      -0.020159684121608734,
      0.02206571400165558,
      0.06979075074195862,
      0.05364871397614479,
      -0.0659608468413353,
      0.08861886709928513,
      -0.03643840178847313,
      -0.09653015434741974,
      -0.06857340782880783,
      0.008562345057725906,
      0.023825187236070633,
      0.004826112184673548,
      -0.01754349283874035,
      0.027998358011245728,
      -0.06073559820652008,
      0.036503538489341736,
      0.034259408712387085,
      -0.007390560582280159,
      0.022646021097898483,
      0.028016554191708565,
      0.0261035468429327,
      -0.043199118226766586,
      -0.003489695955067873,
      -0.039400264620780945,
      -0.007729230914264917,
      0.013553496450185776,
      0.020950285717844963,
      0.11605695635080338,
      0.024166271090507507,
      -0.003281580749899149,
      0.02877785824239254,
      0.02686765231192112,
      0.039676882326602936,
      -0.04554823413491249,
      0.005808979272842407,
      0.0018158828606829047,
      -0.036152198910713196,
      -0.03804780915379524,
      -0.017543520778417587,
      0.06062009930610657,
      0.11279253661632538,
      0.02043069712817669,
      -0.10125306993722916,
      -0.0008360259816981852,
      0.027333738282322884,
      0.06146993488073349,
      0.014898961409926414,
      0.05767817422747612,
      -0.002331611467525363,
      0.008707615546882153,
      0.023589201271533966,
      -0.10084321349859238,
      -0.004663536325097084,
      0.020525755360722542,
      0.04387836903333664,
      -0.03470734879374504,
      0.05808785930275917,
      0.0014242122415453196,
      -0.0025603333488106728,
      -0.03796103224158287,
      0.014159997925162315,
      -0.03865073621273041,
      0.028905140236020088,
      0.030757961794734,
      0.02276657707989216,
      -0.02055441588163376,
      0.09233138710260391,
      -0.09510793536901474,
      -0.04490453004837036,
      -0.0165534820407629,
      -0.030020778998732567,
      -0.039524517953395844,
      0.027788016945123672,
      -0.05777744948863983,
      0.046965185552835464,
      -0.05279320478439331,
      0.008677306585013866,
      -0.0007700336864218116,
      0.006040762644261122,
      -0.012605725787580013,
      -0.028166016563773155,
      -0.030862687155604362,
      -0.017355892807245255,
      -0.03165080398321152,
      0.04049091786146164,
      -0.02003842033445835,
      0.011763004586100578,
      0.014011324383318424,
      -0.02008027955889702,
      0.023878173902630806,
      -0.01479404792189598,
      0.07415152341127396,
      -0.018522463738918304,
      0.0080021433532238,
      0.0024935302790254354,
      -0.0034315292723476887,
      0.09485916048288345,
      -0.05571829900145531,
      -0.03714047744870186,
      0.00017882768588606268,
      -0.04478021338582039,
      0.05201590433716774,
      0.07417049258947372,
      0.0011004238622263074,
      -0.06638464331626892,
      -0.00147891859523952,
      0.06472858786582947,
      -0.05418772250413895,
      0.0013466052478179336,
      0.04174604266881943,
      -0.012931125238537788,
      -0.0833166167140007,
      -0.023810846731066704,
      -0.08813508599996567,
      -0.0071076638996601105,
      0.03399012237787247,
      -0.024357525631785393,
      -0.015516044571995735,
      0.03911711275577545,
      -0.014383773319423199,
      0.08047599345445633,
      -0.03845923766493797,
      0.05964713916182518,
      -0.016566690057516098,
      0.01602029614150524,
      -0.010340886190533638,
      -0.06391729414463043,
      0.02337079867720604,
      0.0805826187133789,
      0.01024868618696928,
      0.04399525746703148,
      0.03571079298853874,
      0.011558184400200844,
      0.04031594470143318,
      0.024120168760418892,
      -0.006802775431424379,
      0.024003511294722557,
      -0.02900264412164688,
      0.034092191606760025,
      0.053469218313694,
      -0.0632319301366806,
      -0.02247670106589794,
      0.01687656342983246,
      0.04309460148215294,
      -0.04466807097196579,
      0.015902990475296974,
      0.04330265522003174,
      -0.05118964985013008,
      0.06652490794658661,
      -0.003306095954030752,
      -0.03390909358859062,
      -0.12673448026180267,
      -0.01954583078622818,
      -0.04323636367917061,
      -0.026328720152378082,
      -0.022065455093979836,
      0.06703465431928635,
      -0.10403803735971451,
      -0.05178387463092804,
      -0.052162811160087585,
      -0.031222311779856682,
      0.021455643698573112,
      0.06900839507579803,
      0.012236187234520912,
      -0.003885505022481084,
      -0.07848083227872849,
      -0.06266999244689941,
      0.04148811101913452,
      -0.012355100363492966,
      -0.024112140759825706,
      -0.00016283780860248953,
      0.005461596883833408,
      -0.023024557158350945,
      0.018512988463044167,
      0.03238651156425476,
      -0.048895884305238724,
      0.04532801732420921,
      0.016382191330194473,
      0.004738055169582367,
      0.07192956656217575,
      0.031641703099012375,
      0.07428020983934402,
      0.05083838105201721,
      -0.10990194976329803,
      0.09030251204967499,
      0.060570165514945984,
      0.01202956959605217,
      -0.08068682998418808,
      -0.10860446840524673,
      0.02364828623831272,
      0.05059988051652908,
      -0.012291752733290195,
      -0.004759577102959156,
      -0.01489835325628519,
      0.02524368092417717,
      0.004529987461864948,
      0.02652398869395256,
      0.08085448294878006,
      -0.022464312613010406,
      0.04235085844993591,
      0.050519462674856186,
      -0.04898395761847496,
      0.017130598425865173,
      -0.04670479893684387,
      -0.060073986649513245,
      -0.05214577168226242,
      -0.15581285953521729,
      0.05912027135491371,
      -0.009490899741649628,
      -0.06946156173944473,
      -0.03359535336494446,
      -0.013740617781877518,
      -0.0014832639135420322,
      0.020780334249138832,
      -0.049092940986156464,
      0.01421412080526352,
      -0.007912855595350266,
      -0.07193993031978607,
      -0.0330510176718235,
      -0.03132883086800575,
      -0.01210340578109026,
      -0.06813820451498032,
      -0.05516867712140083,
      0.034555718302726746,
      -0.06637502461671829,
      0.06377242505550385,
      0.008873960934579372,
      0.07976989448070526,
      0.003295928006991744,
      0.0322929210960865,
      0.0525834821164608,
      0.010466945357620716,
      0.009353067725896835,
      0.004694214556366205,
      -0.007018316071480513,
      0.0028448135126382113,
      -0.001322308904491365,
      -0.040764570236206055,
      0.007614917121827602,
      0.0018631612183526158,
      -0.06185086816549301,
      0.039187368005514145,
      0.020981622859835625,
      -0.05441420525312424,
      0.011510434560477734,
      0.010960586369037628,
      0.05347992479801178,
      0.01983432099223137,
      0.05832011252641678,
      -0.004437554627656937,
      0.005150878801941872,
      -0.0774877741932869,
      0.06191493198275566,
      -0.03344757854938507,
      0.06370603293180466,
      -0.04301021620631218,
      -0.02786330133676529,
      -0.10161580890417099,
      0.013840788044035435,
      -0.012948616407811642,
      -0.03030235320329666,
      0.0096170948818326,
      -0.05375371500849724,
      0.00805057305842638,
      0.01956070587038994,
      0.04386318102478981,
      -0.019932450726628304,
      -0.037794217467308044,
      -0.0004443254438228905,
      -0.03982575610280037,
      -0.03736176714301109,
      -0.027632860466837883,
      0.0006706418353132904,
      -0.03807621821761131,
      0.045672059059143066,
      -0.02699793316423893,
      -0.0414319783449173,
      0.07222576439380646,
      -0.06473252922296524,
      0.014660523273050785,
      0.03912094980478287,
      0.10448609292507172,
      -0.011188234202563763,
      0.08363066613674164,
      -0.021464070305228233,
      0.05003366991877556,
      -0.005372352432459593,
      0.013469181954860687,
      -0.001388810109347105,
      0.11136633902788162,
      0.1166030690073967,
      0.0018264978425577283,
      0.018691938370466232,
      0.1246269941329956,
      -0.027643734589219093,
      -0.03679070249199867,
      0.010353936813771725,
      -0.053582530468702316,
      -0.060723576694726944,
      -0.014898980036377907,
      0.014428268186748028,
      -0.01824922300875187,
      0.009207377210259438,
      0.02330833300948143,
      0.006322389468550682,
      -0.02601984702050686,
      -0.09120043367147446,
      -0.015247363597154617,
      0.014754952862858772,
      0.01974753849208355,
      -0.010527716018259525,
      -0.005299473647028208,
      -0.035320304334163666,
      0.07106078416109085,
      -0.10272631794214249,
      0.07164543122053146,
      -0.011672851629555225,
      -0.02448694221675396,
      -0.041634831577539444,
      0.0179577823728323,
      -0.021945515647530556,
      0.01297278143465519,
      -0.05966074764728546,
      0.10671291500329971
    ],
    [
      -0.012691445648670197,
      0.05487799644470215,
      -0.03373236954212189,
      0.007929468527436256,
      -0.009302114136517048,
      0.048506543040275574,
      0.027194716036319733,
      0.034512508660554886,
      -0.0260799340903759,
      -0.006221478339284658,
      0.06657338887453079,
      0.035532865673303604,
      -0.017967958003282547,
      -0.03389840945601463,
      0.014531828463077545,
      -0.014791992492973804,
      -0.06255877763032913,
      0.02486204169690609,
      -0.02948792092502117,
      -0.07411428540945053,
      0.01951419934630394,
      -0.013737281784415245,
      -0.08220627903938293,
      -0.045846082270145416,
      0.03193921223282814,
      -0.030016403645277023,
      0.010639289394021034,
      -0.04959812015295029,
      0.050037454813718796,
      0.008192735724151134,
      -0.028895478695631027,
      -0.040882617235183716,
      -0.022332360967993736,
      0.0009941973257809877,
      0.03593508526682854,
      -0.008153954520821571,
      0.049202919006347656,
      0.026099173352122307,
      -0.044621653854846954,
      -0.04892520233988762,
      -0.0029218525160104036,
      -0.03359711915254593,
      0.07526150345802307,
      0.0443374402821064,
      0.005640061106532812,
      -0.04121672362089157,
      0.04213254526257515,
      -0.04793037846684456,
      0.011888677254319191,
      -0.008929493837058544,
      -0.025876017287373543,
      0.0026852714363485575,
      0.011909056454896927,
      -0.03231402859091759,
      -0.07614759355783463,
      -0.08618547767400742,
      0.029279924929142,
      0.06747020781040192,
      0.0392252653837204,
      0.0024215634912252426,
      -0.11602969467639923,
      0.03783648833632469,
      -0.049124445766210556,
      0.005647335667163134,
      -0.07177136838436127,
      -0.020415276288986206,
      0.0006595588056370616,
      -0.009621194563806057,
      -0.02927001379430294,
      -0.02624843642115593,
      -0.024310821667313576,
      -0.09340744465589523,
      -0.021990608423948288,
      -0.011278482154011726,
      -0.003329063765704632,
      0.0082673579454422,
      -0.03313273563981056,
      0.10052652657032013,
      -0.020609857514500618,
      0.04472871497273445,
      -0.034450456500053406,
      0.07603318244218826,
      -0.058946043252944946,
      0.0006903456524014473,
      -0.03599613159894943,
      0.05298358201980591,
      0.03034104034304619,
      -0.024344878271222115,
      -0.04842134192585945,
      0.013157673180103302,
      0.0348205529153347,
      -0.021600347012281418,
      0.08057276904582977,
      0.015514533966779709,
      0.10233984887599945,
      -0.06297191977500916,
      0.03168193995952606,
      -0.005922652781009674,
      -0.007765428628772497,
      0.09730933606624603,
      0.0115725789219141,
      -0.040886297821998596,
      0.0363394133746624,
      0.021992715075612068,
      0.015426651574671268,
      -0.008527454920113087,
      0.045650824904441833,
      0.07026276737451553,
      -0.053335025906562805,
      -0.01936952956020832,
      0.036770958453416824,
      -0.010422983206808567,
      0.04967625066637993,
      0.04258669540286064,
      -0.0831039771437645,
      -0.06891258805990219,
      0.0007418285822495818,
      0.0717896893620491,
      -0.024938521906733513,
      0.025829436257481575,
      -0.007357724942266941,
      0.020040104165673256,
      -0.035854633897542953,
      -0.0004174234054517001,
      -0.02471442148089409,
      -0.08416622877120972,
      0.017898542806506157,
      -0.009890605695545673,
      -0.022189641371369362,
      -0.014129403978586197,
      -0.016737887635827065,
      0.0036251042038202286,
      0.026050640270113945,
      0.011203260160982609,
      0.013969999738037586,
      -0.02177894301712513,
      -0.07463769614696503,
      0.005039431154727936,
      0.04570474103093147,
      0.008938022889196873,
      -0.015247608534991741,
      0.10648694634437561,
      0.06218080222606659,
      -0.04773363098502159,
      0.06243053823709488,
      0.09945446252822876,
      0.05042048916220665,
      0.026156282052397728,
      -0.019852647557854652,
      0.00247359462082386,
      0.03638897091150284,
      -0.0008590518846176565,
      -0.004415617324411869,
      -0.030252758413553238,
      0.03164219483733177,
      0.02230515517294407,
      0.008219764567911625,
      0.042712341994047165,
      0.08727169781923294,
      -0.06742851436138153,
      0.0202072411775589,
      -0.05653049424290657,
      0.018680009990930557,
      -0.05398256704211235,
      -0.07674363255500793,
      -0.009882562793791294,
      0.006623513996601105,
      -0.10218420624732971,
      -0.01235889084637165,
      0.010093758814036846,
      0.004270955454558134,
      0.016065094619989395,
      -0.0600382424890995,
      0.06881791353225708,
      -0.009984046220779419,
      0.01643308252096176,
      0.006742945872247219,
      -0.011747228913009167,
      0.039733003824949265,
      -0.032965369522571564,
      -0.10295528918504715,
      0.0035621447023004293,
      0.03448446840047836,
      -0.007015371695160866,
      -0.04362881928682327,
      0.04946143925189972,
      -0.01829581893980503,
      0.006364603992551565,
      -0.01157321222126484,
      -0.12263322621583939,
      -0.018009575083851814,
      -0.07396290451288223,
      0.00030721226357854903,
      0.06451147794723511,
      -0.03177783265709877,
      -0.047698840498924255,
      0.039701007306575775,
      -0.03708285838365555,
      -0.04522629454731941,
      -0.020575007423758507,
      0.024938860908150673,
      -0.048143837600946426,
      -0.0025054861325770617,
      -0.000993396039120853,
      0.04306280240416527,
      0.09131863713264465,
      0.024711906909942627,
      0.006530269980430603,
      -0.025565465912222862,
      -0.11429769545793533,
      -0.004781949333846569,
      -0.03801805526018143,
      -0.08812695741653442,
      0.025917494669556618,
      0.014061224646866322,
      0.038503240793943405,
      0.0067775193601846695,
      -0.0721401795744896,
      -0.012087595649063587,
      0.0436733141541481,
      -0.0319167859852314,
      -0.030495744198560715,
      0.045293185859918594,
      -0.0783088430762291,
      -0.021241657435894012,
      0.019952021539211273,
      -0.08525242656469345,
      0.03566772863268852,
      -0.021462127566337585,
      -0.008395478129386902,
      -0.0677482932806015,
      -0.017410892993211746,
      0.017019998282194138,
      0.04847145080566406,
      -0.024435849860310555,
      -0.07622994482517242,
      0.048574984073638916,
      0.04020533710718155,
      0.04086329787969589,
      -0.055998824536800385,
      0.06179472804069519,
      0.04042893648147583,
      -0.014705839566886425,
      0.033326681703329086,
      0.034232575446367264,
      -0.05302315205335617,
      -0.029232949018478394,
      -0.03742604702711105,
      -0.026001278311014175,
      -0.05329963192343712,
      0.002784771379083395,
      0.03541307523846626,
      -0.028501147404313087,
      0.035596102476119995,
      0.012520932592451572,
      0.022859400138258934,
      0.012414278462529182,
      0.08279716968536377,
      -0.007271541748195887,
      -0.004101648926734924,
      0.02812931127846241,
      0.015414606779813766,
      -0.021696927025914192,
      0.026708144694566727,
      0.0788392648100853,
      -0.10313350707292557,
      -0.05411986634135246,
      -0.0855049192905426,
      0.09869467467069626,
      -0.03351091966032982,
      0.1069316491484642,
      0.047800756990909576,
      -0.01357497088611126,
      -0.025014862418174744,
      0.045552514493465424,
      0.0032835514284670353,
      0.052423566579818726,
      0.02292727679014206,
      -0.03240931034088135,
      0.03467186912894249,
      -0.07509654015302658,
      -0.008353370241820812,
      -0.007134188897907734,
      -0.038112904876470566,
      0.007160380482673645,
      0.034438230097293854,
      -0.06271487474441528,
      -0.038396935909986496,
      -0.030073825269937515,
      0.047964613884687424,
      -0.037783704698085785,
      0.01084621250629425,
      0.03634826838970184,
      -0.1126285269856453,
      0.01312821451574564,
      0.0002789151039905846,
      -0.016136417165398598,
      -0.0013640080578625202,
      0.004536028951406479,
      0.017461447045207024,
      -0.016900060698390007,
      0.04697464779019356,
      0.052300725132226944,
      0.020562391728162766,
      -0.09560032188892365,
      -0.04946192726492882,
      0.009653185494244099,
      0.02721494622528553,
      0.028777413070201874,
      -0.07179028540849686,
      0.010661136358976364,
      0.0035639351699501276,
      0.07834722846746445,
      -0.016393164172768593,
      -0.0031393272802233696,
      -0.01866517774760723,
      0.023090673610568047,
      0.03342635557055473,
      0.03634653612971306,
      0.0074775791727006435,
      -0.03379585221409798,
      0.05968119576573372,
      -0.028753474354743958,
      0.015469565987586975,
      0.06127548962831497,
      -0.02796279825270176,
      -0.005168046336621046,
      -0.00403235387057066,
      0.10027451813220978,
      0.044249020516872406,
      0.04587218165397644,
      0.07446999102830887,
      -0.0845823585987091,
      -0.014566456899046898,
      0.032113898545503616,
      -0.05638393014669418,
      -0.05152485519647598,
      0.004399905446916819,
      0.029273461550474167,
      0.02951306849718094,
      0.0025204550474882126,
      0.04028382897377014,
      0.04711825028061867,
      0.114702969789505,
      -0.05455676466226578,
      0.00613084901124239,
      -0.013897606171667576,
      0.032656241208314896,
      -0.001197097939439118,
      -0.041441306471824646,
      0.034608714282512665,
      0.06677933037281036,
      -0.008031533099710941,
      0.06140023469924927,
      0.059918757528066635,
      -0.04868796095252037,
      -0.05523961782455444,
      -0.0526605024933815,
      -0.014204745180904865,
      -0.00896076112985611,
      -0.0070474459789693356,
      -0.07287434488534927,
      0.003038999857380986,
      0.030183713883161545,
      0.016741562634706497,
      0.018492281436920166,
      0.05220426619052887,
      -0.0045203231275081635,
      -0.007475623860955238,
      0.04795699194073677,
      0.055176593363285065,
      -0.0004931057919748127,
      -0.051270224153995514,
      0.041085220873355865,
      0.06177341565489769,
      -0.0018623117357492447,
      0.012356611900031567,
      -0.019079698249697685,
      -0.01728232577443123,
      0.03911600634455681,
      0.016604503616690636,
      0.008293054066598415,
      0.05395820364356041,
      -0.0017745059449225664,
      -0.016944516450166702,
      -0.052378442138433456,
      0.0011138557456433773,
      -0.06165548041462898,
      0.004611575044691563,
      0.027828285470604897,
      -0.0464957095682621,
      0.022604884579777718,
      0.007188671268522739,
      0.017661822959780693,
      0.027481677010655403,
      -0.0027306692209094763,
      -0.03177530691027641,
      0.028456471860408783,
      -0.01993030682206154,
      0.036228246986866,
      0.04392540827393532,
      0.007509488612413406,
      0.03248710557818413,
      -0.00647856667637825,
      -0.00968401599675417,
      0.04962845519185066,
      -0.06244229897856712,
      0.042138680815696716,
      -0.04020257666707039,
      -0.0231756791472435,
      0.026387805119156837,
      0.10855473577976227,
      0.002649569185450673,
      -0.07275664806365967,
      -0.06382663547992706,
      -0.007607899606227875,
      -0.011487508192658424,
      0.03093169815838337,
      -0.08388783037662506,
      -0.08780214190483093,
      0.01054560486227274,
      -0.02664775960147381,
      -0.027990223839879036,
      0.02103588543832302,
      -0.0928449034690857,
      -0.10064254701137543,
      0.06213779374957085,
      0.002063818508759141,
      0.03594493865966797,
      -0.08134884387254715,
      0.005408554803580046,
      0.08008377254009247,
      -0.09068217873573303,
      -0.009073316119611263,
      0.01622265763580799,
      0.007478027138859034,
      -0.0604192353785038,
      -0.07123006135225296,
      -0.06944829225540161,
      -0.0359935387969017,
      -0.06234382838010788,
      -0.06008189916610718,
      0.06662772595882416,
      0.020127538591623306,
      -0.0117114232853055,
      0.0008663993794471025,
      -0.031155956909060478,
      -0.04948173463344574,
      0.0014184345491230488,
      -0.06601765006780624,
      -0.0144580053165555,
      -0.012988480739295483,
      -0.022733984515070915,
      0.02230137586593628,
      0.004731311462819576,
      0.027647631242871284,
      -0.08013299852609634,
      -0.053924135863780975,
      0.016788961365818977,
      -0.010595055297017097,
      -0.0230672899633646,
      0.001370539190247655,
      -0.05277571082115173,
      -0.0831109806895256,
      0.0015761362155899405,
      0.07274983078241348,
      -0.044202372431755066,
      -0.029644770547747612,
      0.07241704314947128,
      0.007912050932645798,
      -0.003690724028274417,
      -0.13943663239479065,
      0.0038309756200760603,
      0.09951960295438766,
      -0.0039491658098995686,
      0.046737488359212875,
      -0.019047707319259644,
      0.056452251970767975,
      0.06151806190609932,
      -0.007055189926177263,
      0.00185358093585819,
      0.017290128394961357,
      0.03840102255344391,
      0.0019340182188898325,
      -0.05238919332623482,
      0.007888659834861755,
      -0.030238233506679535,
      0.09782769531011581,
      0.0391664132475853,
      -0.003342734882608056,
      0.006265377625823021,
      -0.027617966756224632,
      0.02205696888267994,
      0.04190566763281822,
      0.022098099812865257,
      -0.1194099560379982,
      0.001352405291981995,
      0.0008594890823587775,
      0.004894826095551252,
      -0.007269958034157753,
      -0.024197548627853394,
      -0.04920851066708565,
      -0.03592181205749512
    ],
    [
      -0.06619936972856522,
      0.06535512208938599,
      0.0969947949051857,
      0.07881173491477966,
      -0.07074562460184097,
      -0.02148590236902237,
      0.02334042638540268,
      -0.11479829996824265,
      0.009501147083938122,
      -0.043050333857536316,
      0.014895138330757618,
      0.030871817842125893,
      -0.03630776330828667,
      -0.033034805208444595,
      0.10201773047447205,
      0.07865969836711884,
      0.054100483655929565,
      0.03834110498428345,
      0.027396906167268753,
      0.091398686170578,
      0.08499839901924133,
      0.034630995243787766,
      -0.01698719710111618,
      -0.01329210214316845,
      0.006498374976217747,
      0.008908668532967567,
      -0.03300787881016731,
      -0.00581591110676527,
      -0.02842555195093155,
      -0.007162102032452822,
      -0.016261126846075058,
      -0.06250253319740295,
      0.05820710211992264,
      -0.07247355580329895,
      -0.05952177941799164,
      -0.04081033915281296,
      0.01150528620928526,
      -0.07791405916213989,
      -0.02497832477092743,
      0.042296603322029114,
      0.0020629428327083588,
      0.04570288583636284,
      0.049980174750089645,
      -0.015808431431651115,
      -0.0001293558452744037,
      -0.0331382192671299,
      -0.0029358835890889168,
      0.0532497875392437,
      -0.015830541029572487,
      0.016310162842273712,
      0.094228096306324,
      0.02364514209330082,
      0.027615848928689957,
      0.087032251060009,
      0.0025449013337492943,
      -0.06800418347120285,
      -0.036225978285074234,
      0.022865068167448044,
      0.05774962529540062,
      0.03247752785682678,
      0.005836897995322943,
      -0.06765443086624146,
      0.06008227542042732,
      0.028738275170326233,
      -0.01764407381415367,
      -0.03768063709139824,
      -0.05510643869638443,
      0.02475031651556492,
      -0.04821506515145302,
      0.010612834244966507,
      -0.009214425459504128,
      0.03807934373617172,
      0.024982286617159843,
      0.01380741223692894,
      0.0010329640936106443,
      0.04053039476275444,
      0.04293852299451828,
      0.04339431971311569,
      -0.05156330019235611,
      0.008430229499936104,
      0.11485153436660767,
      0.011835362762212753,
      -0.010956508107483387,
      0.019188562408089638,
      -0.005638400092720985,
      0.0013744549360126257,
      0.06648528575897217,
      0.0012605179799720645,
      -0.022577499970793724,
      0.060879144817590714,
      -0.012513031251728535,
      -0.020226603373885155,
      0.023355333134531975,
      -0.026380721479654312,
      -0.011943431571125984,
      0.016728239133954048,
      0.0007151092286221683,
      0.03233844041824341,
      -0.039489004760980606,
      -0.021094202995300293,
      0.018587034195661545,
      -0.001993677346035838,
      0.035527512431144714,
      0.025585241615772247,
      0.00513736205175519,
      0.032372891902923584,
      0.10207933932542801,
      0.030209016054868698,
      0.03425035625696182,
      -0.047732628881931305,
      0.016841096803545952,
      -0.0301984716206789,
      -0.005926407873630524,
      -0.1271328330039978,
      0.008252657949924469,
      -0.04515399783849716,
      0.062453217804431915,
      0.005349908024072647,
      0.09732432663440704,
      -0.059099990874528885,
      -0.030972667038440704,
      0.05600558593869209,
      0.005697816610336304,
      0.07992137223482132,
      0.030584437772631645,
      -0.052529435604810715,
      -0.023519935086369514,
      -0.1019439771771431,
      -0.04050307348370552,
      0.027540257200598717,
      -0.006983489729464054,
      -0.024302950128912926,
      -0.04349495843052864,
      -0.11090612411499023,
      0.02502375654876232,
      0.014279144816100597,
      0.010534737259149551,
      -0.0794513151049614,
      0.0077400957234203815,
      0.01725033111870289,
      0.03187540918588638,
      0.014054583385586739,
      0.028054283931851387,
      -0.005263414233922958,
      0.007515930105000734,
      -0.027176115661859512,
      0.023928934708237648,
      -0.04325846955180168,
      -0.009938164614140987,
      -0.05249200761318207,
      0.09001495689153671,
      -0.04884221777319908,
      0.023914268240332603,
      7.636506779817864e-05,
      0.02479243464767933,
      -0.027000172063708305,
      0.027444472536444664,
      -0.018233558163046837,
      0.028585277497768402,
      0.05253158509731293,
      0.045610517263412476,
      0.0014292065752670169,
      0.019484177231788635,
      -0.017904363572597504,
      0.0557168610394001,
      -0.021313486620783806,
      -0.00047907227417454123,
      -0.013662598095834255,
      -0.016261998564004898,
      0.013217705301940441,
      -0.08928634226322174,
      0.019769005477428436,
      0.02255859225988388,
      0.024892183020710945,
      0.02909855544567108,
      -0.0076534016989171505,
      -0.035110555589199066,
      0.02679949253797531,
      0.04253256693482399,
      -0.004414341412484646,
      -0.005949609912931919,
      0.017660794779658318,
      0.010110863484442234,
      0.024922816082835197,
      -0.026020096614956856,
      0.028167765587568283,
      0.05736934021115303,
      0.031122004613280296,
      -0.015861637890338898,
      -0.03109331987798214,
      -0.005576033610850573,
      -0.0032523698173463345,
      0.01039163675159216,
      -0.03723171725869179,
      -0.03222217410802841,
      0.014646768569946289,
      -0.06509123742580414,
      -0.06862164288759232,
      0.04062976315617561,
      0.05239243432879448,
      0.001141461543738842,
      -0.03183616325259209,
      0.002276355167850852,
      0.10470888018608093,
      -0.018420148640871048,
      0.03698929026722908,
      -0.026642359793186188,
      -0.01911788061261177,
      -0.034291334450244904,
      0.024407997727394104,
      0.014978689141571522,
      -0.007021123077720404,
      0.025877948850393295,
      -0.011588936671614647,
      -0.004883917979896069,
      -0.02769768051803112,
      0.07079862803220749,
      0.08853506296873093,
      0.02992011420428753,
      -0.013112857937812805,
      0.015450188890099525,
      -0.0895191952586174,
      0.06360829621553421,
      0.0451640784740448,
      -0.02237713895738125,
      -0.01127072423696518,
      -0.06409400701522827,
      -0.019749727100133896,
      0.011414824984967709,
      0.11119597405195236,
      -0.03863862529397011,
      -0.02067754790186882,
      0.027863848954439163,
      0.03014788217842579,
      0.045778270810842514,
      0.09776446223258972,
      0.03402892127633095,
      -0.031198695302009583,
      -0.05237817019224167,
      0.04260862246155739,
      -0.03307647258043289,
      0.024917200207710266,
      0.032204899936914444,
      0.003046070458367467,
      0.06073378399014473,
      -0.022751469165086746,
      -0.08453530818223953,
      -0.06715381145477295,
      -0.012576165609061718,
      -0.06854339689016342,
      -0.06095241382718086,
      -9.90254120551981e-05,
      0.06699513643980026,
      -0.06697187572717667,
      0.0009986384538933635,
      0.023053301498293877,
      0.0320427305996418,
      0.08838409930467606,
      0.02310621552169323,
      0.031587354838848114,
      0.015863997861742973,
      -0.022875359281897545,
      -0.019952574744820595,
      0.007276371121406555,
      0.05019518360495567,
      -0.02718733809888363,
      -0.034265145659446716,
      0.009809871204197407,
      -0.021508250385522842,
      -0.03560713306069374,
      0.07557053864002228,
      0.005577913951128721,
      -0.028364647179841995,
      0.024328844621777534,
      -0.022771595045924187,
      -0.021093148738145828,
      0.0013127990532666445,
      -0.006960016675293446,
      0.03716863691806793,
      -0.0003103013732470572,
      -0.002790698315948248,
      -0.02870151400566101,
      -0.034650322049856186,
      -0.07153508067131042,
      -0.04312090575695038,
      -0.0295833982527256,
      -0.011100266128778458,
      -0.0015414954395964742,
      0.031037501990795135,
      0.05734303966164589,
      -0.08971106261014938,
      -0.03959313780069351,
      -0.03388763219118118,
      0.03529645502567291,
      -0.02510601095855236,
      0.07841625809669495,
      0.05964867025613785,
      0.051147907972335815,
      -0.024178018793463707,
      -0.08203351497650146,
      -0.05367961898446083,
      -0.022388076409697533,
      0.05995725840330124,
      0.002435233909636736,
      0.017029166221618652,
      -0.04012348875403404,
      -0.048926256597042084,
      -0.011692872270941734,
      0.013099024072289467,
      0.08820189535617828,
      0.0008077321108430624,
      -0.018061751499772072,
      -0.04076573625206947,
      -0.05123324319720268,
      0.08061414957046509,
      -0.0197299774736166,
      0.09768272936344147,
      0.012124217115342617,
      -0.006386796943843365,
      -0.08287833631038666,
      0.05164942145347595,
      0.07149717956781387,
      0.04222233593463898,
      -0.12035025656223297,
      0.04407704249024391,
      -0.021354658529162407,
      0.04237188398838043,
      0.09072108566761017,
      0.01191459596157074,
      0.01354999840259552,
      0.039399947971105576,
      0.028729746118187904,
      0.04427482187747955,
      0.03442034497857094,
      -0.06069999560713768,
      0.005492862779647112,
      0.0066704293712973595,
      0.029169395565986633,
      0.023610521107912064,
      -0.032563649117946625,
      0.02206312119960785,
      -0.0185206551104784,
      0.01673307828605175,
      -0.074946328997612,
      0.009732961654663086,
      0.02204037643969059,
      0.013095065020024776,
      0.04827660322189331,
      0.005776699632406235,
      0.013363114558160305,
      0.05114133656024933,
      -0.060297947376966476,
      -0.0297055933624506,
      -0.04182695597410202,
      -0.014840497635304928,
      0.006320420652627945,
      -0.08679920434951782,
      -0.042006492614746094,
      0.055070970207452774,
      0.0811489149928093,
      0.008080984465777874,
      -0.02124876156449318,
      -0.003256295807659626,
      0.05425508692860603,
      -0.020890330895781517,
      -0.005666268523782492,
      -0.026340777054429054,
      -0.05550414323806763,
      0.0041290270164608955,
      0.04055453836917877,
      0.019345881417393684,
      -0.0031597325578331947,
      0.062355779111385345,
      0.032828573137521744,
      -0.01071029994636774,
      0.0695839375257492,
      -0.03578367829322815,
      -0.01991850882768631,
      0.04773480072617531,
      0.016766918823122978,
      -0.06706131249666214,
      0.13132210075855255,
      0.04170641303062439,
      0.010845435783267021,
      -0.02651687152683735,
      -0.0037136205937713385,
      -0.0015212781727313995,
      -0.03473841771483421,
      0.06437079608440399,
      -0.019709721207618713,
      0.05856776610016823,
      -0.05663890019059181,
      0.10676660388708115,
      0.08715061843395233,
      -0.033892422914505005,
      0.023303203284740448,
      0.058944907039403915,
      0.010879245586693287,
      0.02886010706424713,
      -0.018021592870354652,
      -0.002503639319911599,
      -0.03279490768909454,
      -0.056927215307950974,
      -0.06633419543504715,
      0.05367501452565193,
      0.059951625764369965,
      -0.06394175440073013,
      0.03355202451348305,
      0.04957503080368042,
      -0.05208813399076462,
      -0.031590595841407776,
      -0.021384362131357193,
      0.037802934646606445,
      -0.04206332564353943,
      -0.012591710314154625,
      -0.036164186894893646,
      0.01268922258168459,
      -0.004036824218928814,
      0.059554681181907654,
      0.023600203916430473,
      0.06924974173307419,
      0.01204441674053669,
      -0.007406667806208134,
      0.08950928598642349,
      0.01010476890951395,
      -0.010261774994432926,
      -0.01932203397154808,
      -0.02039029449224472,
      0.07819000631570816,
      0.022384662181138992,
      -0.014346066862344742,
      -0.023768285289406776,
      0.00678425095975399,
      0.003887043334543705,
      -0.00335700367577374,
      0.014278500340878963,
      0.015484333969652653,
      0.04854318127036095,
      0.046562645584344864,
      0.03629668802022934,
      -0.04187845438718796,
      0.04228807985782623,
      0.0236714705824852,
      0.03888895362615585,
      -0.018215520307421684,
      0.08309243619441986,
      0.03341860696673393,
      0.0330236554145813,
      -0.01885632611811161,
      -0.015449536964297295,
      -0.020178532227873802,
      -0.04252185672521591,
      -0.035135313868522644,
      -0.021085958927869797,
      0.01528967171907425,
      0.09320387244224548,
      0.046431299299001694,
      -0.04446568712592125,
      -0.0016410875832661986,
      -0.06311410665512085,
      -0.07366622984409332,
      0.05313092842698097,
      0.037381935864686966,
      -0.01882982812821865,
      -0.03681901469826698,
      0.04440459609031677,
      -0.0033787910360842943,
      -0.04238990321755409,
      0.032494205981492996,
      0.023096906021237373,
      -0.026684775948524475,
      0.011913483962416649,
      -0.0716492086648941,
      -0.038184862583875656,
      0.04625803232192993,
      0.0522005558013916,
      -0.039085034281015396,
      0.06987139582633972,
      -0.06108217313885689,
      -0.015308819711208344,
      0.036424074321985245,
      -0.01923738792538643,
      0.06138736009597778,
      0.051336511969566345,
      0.01109367236495018,
      -0.011281616054475307,
      -0.014192041009664536,
      0.02555113472044468,
      0.03821435570716858,
      0.014440362341701984,
      0.0595698356628418,
      0.04169917851686478,
      -0.012795530259609222,
      -0.0040354481898248196,
      -0.06674343347549438,
      -0.018231384456157684,
      0.002248652745038271,
      0.0153587581589818,
      0.03444430232048035,
      -0.06426605582237244,
      0.054873690009117126,
      0.04453745484352112,
      0.022165939211845398
    ],
    [
      -0.0677301436662674,
      -0.03477886691689491,
      -0.08063599467277527,
      -0.04040658101439476,
      -0.1152893453836441,
      0.04972855746746063,
      0.0425477959215641,
      0.050054360181093216,
      -0.03275228664278984,
      -0.015733694657683372,
      0.0024050618521869183,
      0.0013643046841025352,
      0.03788010776042938,
      -0.03985776752233505,
      0.002413699636235833,
      -0.05460578203201294,
      -0.013200923800468445,
      0.03418553248047829,
      -0.02244909666478634,
      -0.07165057212114334,
      -0.012083355337381363,
      0.07770967483520508,
      -0.02329331822693348,
      -0.03774164617061615,
      0.04097484424710274,
      -0.13882587850093842,
      0.028958788141608238,
      -0.03914820775389671,
      0.006275331135839224,
      -0.004898016806691885,
      -0.03817064315080643,
      -0.0176128838211298,
      -0.049505580216646194,
      -0.004805002827197313,
      -0.03049216978251934,
      0.059701107442379,
      0.1113574206829071,
      0.00810419674962759,
      0.06760644167661667,
      0.026581084355711937,
      -0.0661921426653862,
      0.005424502771347761,
      -0.09776992350816727,
      -0.028892185539007187,
      -0.007383677177131176,
      0.024231016635894775,
      0.06143910810351372,
      0.0168976541608572,
      -0.018223971128463745,
      -0.035851024091243744,
      -0.010986597277224064,
      0.025885483250021935,
      -0.08940864354372025,
      0.016527431085705757,
      -0.011567838490009308,
      0.01656194217503071,
      0.017733484506607056,
      0.007356694433838129,
      0.038959018886089325,
      -0.0012452342780306935,
      -0.00026704647461883724,
      0.1150493323802948,
      0.06164092198014259,
      0.008803386241197586,
      -0.057939715683460236,
      -0.005873790010809898,
      0.018278328701853752,
      0.04388554394245148,
      0.04512730613350868,
      0.0017417570343241096,
      -0.05510979890823364,
      0.06264036893844604,
      0.08403075486421585,
      -0.010420807637274265,
      -0.09036864340305328,
      0.019280895590782166,
      0.046552084386348724,
      0.013294409960508347,
      0.009282249957323074,
      0.08718057721853256,
      0.029302936047315598,
      0.029532773420214653,
      -0.10916770249605179,
      0.017003338783979416,
      -0.02058967761695385,
      -0.05456632748246193,
      0.0023567413445562124,
      0.07125870883464813,
      -0.06323928385972977,
      0.04337344691157341,
      -0.01597622036933899,
      0.0203460194170475,
      0.020141443237662315,
      0.029130103066563606,
      0.03614543378353119,
      -0.010576989501714706,
      0.06462569534778595,
      0.002493919339030981,
      0.015277838334441185,
      0.05069296434521675,
      -0.00987333431839943,
      0.013041476719081402,
      -0.045624688267707825,
      0.008138167671859264,
      0.043555643409490585,
      -0.027984920889139175,
      -0.014039477333426476,
      0.06206328794360161,
      0.03911636024713516,
      0.05183647200465202,
      -0.029156554490327835,
      0.04073576256632805,
      -0.014188648201525211,
      -0.014997885562479496,
      -0.05123881995677948,
      0.02753635309636593,
      0.009231248870491982,
      -0.022938644513487816,
      -0.01087221410125494,
      0.027522757649421692,
      -0.02937155030667782,
      0.03231942281126976,
      -0.003487439127638936,
      -0.06701894849538803,
      0.006961639504879713,
      -0.01965552568435669,
      0.05638269707560539,
      -0.0005339712952263653,
      -0.05041990429162979,
      0.09754493832588196,
      -0.01841624081134796,
      0.05774829909205437,
      -0.048443593084812164,
      -0.015387220308184624,
      -0.0005293170688673854,
      -0.059826672077178955,
      -0.03728131204843521,
      0.002436283277347684,
      -0.034255024045705795,
      0.006004157941788435,
      -0.014334444887936115,
      0.0929294005036354,
      -0.002569576958194375,
      -0.03987430781126022,
      0.04821902886033058,
      0.03638225793838501,
      0.09864233434200287,
      -0.04324332997202873,
      -0.06029277294874191,
      -0.044483382254838943,
      -0.021267738193273544,
      -0.12793083488941193,
      -0.02867894433438778,
      0.03139388561248779,
      0.030981581658124924,
      0.017031695693731308,
      0.04856942594051361,
      -0.029287660494446754,
      -0.01790415681898594,
      0.07671418786048889,
      -0.02152925916016102,
      0.05783972144126892,
      -0.016948454082012177,
      -0.023235853761434555,
      0.03640664368867874,
      0.0823536291718483,
      -0.02575187012553215,
      0.004842629190534353,
      -0.02091914229094982,
      0.050343919545412064,
      -0.008281219750642776,
      -0.04616788402199745,
      -0.00976080633699894,
      -0.012448498979210854,
      -0.04664851352572441,
      0.0353669673204422,
      0.03591123968362808,
      -0.11311251670122147,
      -0.08499450981616974,
      -0.03874485194683075,
      0.010530643165111542,
      -0.0252094529569149,
      0.03190874680876732,
      0.019455034285783768,
      -0.038120120763778687,
      0.012597811408340931,
      -0.05284764990210533,
      0.004155097063630819,
      -0.031774383038282394,
      -0.03572864085435867,
      -0.0753609910607338,
      -0.07767777144908905,
      -0.02766256034374237,
      -0.024522988125681877,
      0.05548543855547905,
      -0.025374561548233032,
      -0.07491687685251236,
      -0.0038482844829559326,
      0.06813262403011322,
      -0.050994258373975754,
      0.00713479146361351,
      0.0799979642033577,
      0.007442226633429527,
      -0.053568147122859955,
      -0.01239576656371355,
      -0.045587681233882904,
      -0.022637302055954933,
      0.05883526802062988,
      -0.037245333194732666,
      -0.09001925587654114,
      -0.010781626217067242,
      -0.014099043793976307,
      -0.06976363062858582,
      0.05039376765489578,
      0.07597022503614426,
      -0.06373104453086853,
      0.04342270269989967,
      0.03222612291574478,
      0.0029155949596315622,
      0.08216608315706253,
      -0.03429904952645302,
      0.02866886742413044,
      0.058722205460071564,
      -0.011076238937675953,
      0.013956744223833084,
      0.05777602642774582,
      -0.0032912064343690872,
      -0.05852530524134636,
      0.07663969695568085,
      0.014696884900331497,
      0.026789721101522446,
      0.0005470877513289452,
      0.04381529241800308,
      -0.006485550198704004,
      0.041262008249759674,
      0.044192492961883545,
      -0.11983240395784378,
      0.0015414210502058268,
      0.001725011388771236,
      0.002932197879999876,
      0.0031754581723362207,
      0.004546663258224726,
      0.004235637374222279,
      -0.04422543942928314,
      0.08007444441318512,
      -0.04521387070417404,
      -0.026957467198371887,
      -0.01692895032465458,
      0.08205151557922363,
      0.006341614294797182,
      -0.034803226590156555,
      -0.08548325300216675,
      0.0062824757769703865,
      -0.0043091238476336,
      -0.025023337453603745,
      0.013396590016782284,
      -0.04364047199487686,
      0.08110270649194717,
      0.011562108062207699,
      -0.06421178579330444,
      0.03728713095188141,
      0.042012836784124374,
      -0.03145243227481842,
      0.009209675714373589,
      0.017154546454548836,
      -0.0017337751341983676,
      0.029483536258339882,
      -0.10716572403907776,
      -0.017940707504749298,
      -0.06190621480345726,
      0.08676081895828247,
      -0.00029406865360215306,
      -0.03319631889462471,
      -0.03663644194602966,
      0.005340134259313345,
      0.17122918367385864,
      0.003232560120522976,
      0.020163681358098984,
      0.03136514872312546,
      -0.03560469299554825,
      -0.05186998471617699,
      -0.06595706194639206,
      0.05714903399348259,
      0.018369508907198906,
      -0.0023536882363259792,
      -0.08071288466453552,
      0.019181568175554276,
      0.023066164925694466,
      0.022308310493826866,
      -0.042374759912490845,
      -0.07033244520425797,
      0.005967171862721443,
      0.006495161447674036,
      -0.01931934244930744,
      -0.07361157238483429,
      -0.045473963022232056,
      -0.03625217825174332,
      0.01651565544307232,
      -0.005814677570015192,
      -0.0024467988405376673,
      -0.03169447183609009,
      0.0028182852547615767,
      0.05401838198304176,
      0.03276839107275009,
      -0.006055585108697414,
      -0.02269585430622101,
      -0.09276716411113739,
      -0.016205964609980583,
      0.02346695214509964,
      0.051379717886447906,
      0.012300707399845123,
      0.09949936717748642,
      0.01661762036383152,
      -0.08155553042888641,
      -0.010440104641020298,
      -0.009827986359596252,
      0.12643355131149292,
      0.02226954884827137,
      -0.03770254924893379,
      -0.08018136769533157,
      0.010666893795132637,
      -0.04874103516340256,
      0.04091925173997879,
      0.023378213867545128,
      0.021223554387688637,
      -0.05086420848965645,
      0.06842982769012451,
      0.029393518343567848,
      -0.09640998393297195,
      -0.046853192150592804,
      -0.010064066387712955,
      0.03631553426384926,
      -0.0854230597615242,
      0.1221928745508194,
      0.03057708404958248,
      0.007437351159751415,
      0.02919621393084526,
      -0.022566774860024452,
      -0.011921186000108719,
      -0.046399835497140884,
      0.029768263921141624,
      -0.06536099314689636,
      0.03275590017437935,
      0.013840142637491226,
      -0.071879543364048,
      0.0008143397280946374,
      -0.05096996948122978,
      -0.015761742368340492,
      -0.016524408012628555,
      -0.05657999590039253,
      0.012284866534173489,
      0.01466208603233099,
      -0.05341648310422897,
      -0.015548482537269592,
      -0.04816185683012009,
      -0.027590133249759674,
      -0.026249391958117485,
      -0.13314412534236908,
      -0.11521629244089127,
      -0.08085420727729797,
      -0.04435928165912628,
      0.05399029701948166,
      0.048222798854112625,
      -0.00868154689669609,
      0.02004292421042919,
      -0.025480175390839577,
      -0.005844245199114084,
      0.03008834272623062,
      -0.028806190937757492,
      0.04099048301577568,
      -0.037748437374830246,
      0.02838847041130066,
      0.017519237473607063,
      -0.001358061796054244,
      0.033609405159950256,
      0.014770297333598137,
      -0.05207712948322296,
      -0.0019553815945982933,
      0.06309357285499573,
      -0.04678879678249359,
      -0.08853904157876968,
      -0.016679270192980766,
      -0.020169543102383614,
      -0.014380937442183495,
      -0.0155585752800107,
      -0.03940180689096451,
      0.01398753747344017,
      -0.031131945550441742,
      -0.0390816405415535,
      0.08403771370649338,
      -0.020459137856960297,
      0.027397125959396362,
      0.014685625210404396,
      -0.00967636238783598,
      -0.049964550882577896,
      -0.01540179830044508,
      0.006549504119902849,
      -0.008785034529864788,
      0.01945679634809494,
      -0.02911127172410488,
      0.028406770899891853,
      0.04885789006948471,
      -0.039029572159051895,
      -0.02663218230009079,
      0.01078322995454073,
      -0.00803570356220007,
      -0.026956504210829735,
      0.00013527253759093583,
      -0.0264328271150589,
      -0.06263629347085953,
      -0.03476012125611305,
      -0.00847669132053852,
      -0.003177315928041935,
      0.026266880333423615,
      -0.00892964843660593,
      0.03412839397788048,
      -0.014324387535452843,
      0.027270114049315453,
      0.04274515062570572,
      0.020877577364444733,
      -0.07444103807210922,
      0.01083970908075571,
      -0.01474272832274437,
      0.039266932755708694,
      0.007161385379731655,
      0.05224676430225372,
      -0.062229692935943604,
      0.051599398255348206,
      -0.08786451816558838,
      0.10945641994476318,
      0.014084599912166595,
      0.09081454575061798,
      -0.0252360999584198,
      0.011648659594357014,
      0.020145626738667488,
      0.03849269449710846,
      0.11351184546947479,
      -0.0867333710193634,
      0.01124016847461462,
      -0.0013402831973508,
      0.04621558263897896,
      0.023225776851177216,
      0.03519595414400101,
      -0.01811123825609684,
      0.02895270101726055,
      0.03299134969711304,
      0.016243262216448784,
      -0.014858569949865341,
      0.006224213633686304,
      0.04577291011810303,
      0.007183481473475695,
      -0.08565835654735565,
      -0.0007543581305071712,
      -0.0319659523665905,
      -0.021577611565589905,
      0.03932182118296623,
      -0.020410064607858658,
      0.06005128100514412,
      -0.051689568907022476,
      -0.03694221377372742,
      0.023647453635931015,
      0.004558933898806572,
      0.03923586755990982,
      -0.0010834012646228075,
      -0.017638085409998894,
      -0.002307158662006259,
      -0.042934756726026535,
      -0.07107258588075638,
      0.09467479586601257,
      -0.0620599240064621,
      0.06297578662633896,
      0.011107646860182285,
      -0.04457824304699898,
      -0.0350588783621788,
      0.004802448209375143,
      0.03307429328560829,
      -0.016770528629422188,
      -0.013347075320780277,
      0.013417835347354412,
      -0.030499475076794624,
      0.03871944919228554,
      -0.007386757060885429,
      0.023753143846988678,
      0.0023530758917331696,
      0.04099408537149429,
      0.03126929700374603,
      -0.009799149818718433,
      -0.09297845512628555,
      0.1348375827074051,
      -0.007467411924153566,
      0.003979944158345461,
      -0.04591410979628563,
      -0.03728622570633888,
      0.07197445631027222,
      -0.037843603640794754,
      0.030630512163043022,
      0.006874969694763422,
      0.026306267827749252,
      0.035630252212285995,
      -0.05246038734912872,
      0.008685843087732792,
      -0.04915773123502731,
      -0.006947287358343601
    ],
    [
      0.042085912078619,
      -0.058044638484716415,
      -0.031502701342105865,
      -0.011559140868484974,
      0.08601529151201248,
      -0.03414329141378403,
      0.0004897139151580632,
      0.004728675354272127,
      0.0023708543740212917,
      -0.058684006333351135,
      0.029808655381202698,
      0.03590349480509758,
      -0.02029469422996044,
      0.05088520050048828,
      -0.054647527635097504,
      -0.041694678366184235,
      0.04174305126070976,
      -0.12660515308380127,
      -0.05428331345319748,
      0.08135783672332764,
      -0.02115880511701107,
      -0.05541584640741348,
      -0.0863676443696022,
      -0.050586801022291183,
      0.07730592787265778,
      -0.04277597367763519,
      0.021233869716525078,
      -0.020095081999897957,
      0.045077983289957047,
      -0.0031982194632291794,
      0.014967115595936775,
      -0.0016126836417242885,
      -0.0259233471006155,
      -0.05931057780981064,
      -0.034124862402677536,
      -0.030184615403413773,
      -0.040012165904045105,
      -0.03548711910843849,
      -0.046354442834854126,
      -0.016760695725679398,
      -0.03999170660972595,
      -0.07780881971120834,
      -0.06625600904226303,
      0.014033365994691849,
      -0.004275115672498941,
      0.006736332550644875,
      0.09639044106006622,
      0.051951054483652115,
      -0.06672877818346024,
      0.012044589035212994,
      0.03648902848362923,
      0.04114651307463646,
      -0.06327325105667114,
      0.10186348110437393,
      -0.012223361991345882,
      0.09188153594732285,
      -0.009977661073207855,
      0.027176016941666603,
      0.0013596462085843086,
      -0.022536521777510643,
      0.04407995194196701,
      -0.04341599717736244,
      0.023964334279298782,
      -0.004266493022441864,
      0.005482797510921955,
      -0.039347585290670395,
      -0.09848848730325699,
      -0.07815524935722351,
      -0.003963955212384462,
      0.014060058631002903,
      -0.07009631395339966,
      0.005569247994571924,
      -0.03495865687727928,
      0.041071999818086624,
      0.03893763944506645,
      0.030582914128899574,
      -0.061548661440610886,
      0.017594583332538605,
      -0.022329390048980713,
      0.03739906847476959,
      -0.04254483804106712,
      -0.01238805241882801,
      0.004991563502699137,
      0.006044280249625444,
      -0.056323472410440445,
      0.0230032280087471,
      0.07539800554513931,
      0.0075148590840399265,
      0.006088586989790201,
      -0.04708583280444145,
      0.10613921284675598,
      0.03528236597776413,
      0.03438970819115639,
      0.0046102022752165794,
      -0.08591078966856003,
      0.01833339035511017,
      -0.04233551025390625,
      -0.01847340166568756,
      -0.03812168911099434,
      -0.07832025736570358,
      0.013736824505031109,
      0.02529928833246231,
      -0.021048489958047867,
      -0.009781740605831146,
      0.001346337958239019,
      0.04266851022839546,
      -0.038003530353307724,
      -0.01994236372411251,
      -0.08397804945707321,
      -0.0487605556845665,
      0.0028819721192121506,
      -0.008638965897262096,
      0.049447186291217804,
      0.0009931401582434773,
      0.07364556938409805,
      -0.06842587888240814,
      -0.07407790422439575,
      0.0162273608148098,
      -0.05039773881435394,
      -0.062344878911972046,
      0.03929486870765686,
      -0.04443598911166191,
      -0.05573485419154167,
      0.042155858129262924,
      -0.04919327422976494,
      -0.050902027636766434,
      -0.06376136094331741,
      0.0471564419567585,
      -0.04151711240410805,
      0.10905085504055023,
      0.08117379993200302,
      -0.011842706240713596,
      0.034898754209280014,
      0.011929863132536411,
      0.04092644155025482,
      0.023622462525963783,
      -0.0030184858478605747,
      -0.09037994593381882,
      -0.01600305549800396,
      -0.05712493136525154,
      -0.003584965830668807,
      0.02391134575009346,
      0.020553015172481537,
      0.050725314766168594,
      0.06085965037345886,
      -0.028323542326688766,
      -0.023487195372581482,
      0.01646343059837818,
      -0.09652912616729736,
      -0.05222726985812187,
      -0.0011621484300121665,
      -0.040378715842962265,
      -0.0023481545504182577,
      -0.0633869394659996,
      0.09425155073404312,
      0.03262568637728691,
      0.015092764981091022,
      0.0023153272923082113,
      -0.06150176748633385,
      -0.028271593153476715,
      0.01733422465622425,
      0.009105224162340164,
      0.09075766056776047,
      0.014098147861659527,
      0.005858138203620911,
      -0.03992787003517151,
      -0.06744267791509628,
      -0.021217811852693558,
      0.01679241843521595,
      0.0054051224142313,
      0.009084058925509453,
      0.036963775753974915,
      -0.007787896320223808,
      -0.056878551840782166,
      0.035020940005779266,
      0.05693436786532402,
      -0.0548337884247303,
      0.021644340828061104,
      -0.10708595812320709,
      -0.05083966627717018,
      -0.006907295901328325,
      0.03039085492491722,
      -0.013657691888511181,
      0.0393826924264431,
      0.01827828958630562,
      0.0020633460953831673,
      -0.04257640987634659,
      -0.007192923221737146,
      0.006654714234173298,
      -0.022698812186717987,
      -0.04487724229693413,
      0.03214693441987038,
      0.016584698110818863,
      -0.055114906281232834,
      -0.031082313507795334,
      -0.01779715158045292,
      0.029398079961538315,
      -0.033146657049655914,
      0.022846391424536705,
      0.006372332107275724,
      -0.05424332991242409,
      0.01965915970504284,
      0.03569493815302849,
      -0.0248334351927042,
      -0.005745211150497198,
      0.02982550486922264,
      -0.04762835428118706,
      0.0007122545503079891,
      -0.02722100540995598,
      0.025967057794332504,
      0.06286494433879852,
      -0.01582125946879387,
      -0.013315713964402676,
      -0.006572745740413666,
      0.07090866565704346,
      -0.10412759333848953,
      0.03021477721631527,
      -0.04025571793317795,
      0.019713591784238815,
      0.02156510204076767,
      0.05580322444438934,
      -0.0318538174033165,
      0.07118318974971771,
      0.02938775345683098,
      -0.05230017751455307,
      -0.0966019406914711,
      -0.06169756129384041,
      -0.020845936611294746,
      0.006610080134123564,
      0.08364171534776688,
      0.013452497310936451,
      -0.04624044522643089,
      -0.024487921968102455,
      0.0020306550431996584,
      -0.013218889012932777,
      -0.043790075927972794,
      -0.018347466364502907,
      -0.06506124883890152,
      6.462338933488354e-05,
      0.01864977553486824,
      0.03153359144926071,
      -0.04364042356610298,
      0.005266363732516766,
      0.05219746753573418,
      0.05649791285395622,
      0.04524629935622215,
      0.017995648086071014,
      -0.04518316686153412,
      -0.013928167521953583,
      -0.06770065426826477,
      0.059430081397295,
      0.027950255200266838,
      -0.021211454644799232,
      -0.00036914751399308443,
      -0.0619078204035759,
      0.029430868104100227,
      -0.08116555958986282,
      -0.015574694611132145,
      -0.0116984061896801,
      -0.0016395384445786476,
      0.04038922116160393,
      -0.03952445462346077,
      -0.0011406337143853307,
      -0.021177133545279503,
      -0.10365203768014908,
      0.0015909674111753702,
      -0.004428653512150049,
      0.05425639450550079,
      -0.012134790420532227,
      -0.014916155487298965,
      0.05715428292751312,
      -0.06231784075498581,
      0.003532527945935726,
      0.05182106792926788,
      -0.0384707897901535,
      -0.03915422782301903,
      -0.0635455921292305,
      0.040506165474653244,
      0.07889283448457718,
      0.00551894074305892,
      -0.00826684944331646,
      -0.036525607109069824,
      0.02959400974214077,
      -0.028986917808651924,
      0.029405562207102776,
      0.0068178484216332436,
      0.009744198992848396,
      0.0055498359724879265,
      0.03400114178657532,
      -0.005615212954580784,
      -0.11598557978868484,
      -0.056204479187726974,
      -0.039832405745983124,
      0.02895076386630535,
      -0.03152305260300636,
      0.09271255880594254,
      0.0031732625793665648,
      -0.03858894854784012,
      0.030193518847227097,
      0.011000890284776688,
      0.01124648004770279,
      0.03440608084201813,
      -0.00500961858779192,
      -0.04653427377343178,
      0.03833290934562683,
      -0.024817418307065964,
      0.06855574250221252,
      -0.06355945765972137,
      -0.07211802154779434,
      0.004666800145059824,
      -0.051796507090330124,
      -0.010118808597326279,
      0.03599448874592781,
      0.013389714993536472,
      -0.03153648599982262,
      0.013000925071537495,
      0.05471309646964073,
      -0.057589590549468994,
      0.04064954072237015,
      -0.012909039855003357,
      -0.017726555466651917,
      -0.009746215306222439,
      0.09347129613161087,
      -0.017920300364494324,
      0.037836335599422455,
      -0.05681774392724037,
      0.0021846683230251074,
      -0.015659743919968605,
      -0.029705971479415894,
      -0.0351296029984951,
      -0.00034970114938914776,
      -0.015083624050021172,
      -0.038537051528692245,
      0.0129398750141263,
      -0.053294919431209564,
      -0.0011407763231545687,
      -0.02205035649240017,
      -0.0339311808347702,
      -0.04511028528213501,
      0.13810011744499207,
      -0.05061476677656174,
      -0.03393412381410599,
      0.03162664547562599,
      -0.09566960483789444,
      0.027461891993880272,
      0.03946855291724205,
      0.04264669492840767,
      0.045011021196842194,
      -0.1066228523850441,
      0.05616513267159462,
      0.03445228189229965,
      0.03372751548886299,
      -0.02170358970761299,
      0.04051728919148445,
      0.060258328914642334,
      -0.029589978978037834,
      0.01839779131114483,
      0.04770057275891304,
      -0.04363573342561722,
      0.03983534872531891,
      -0.022456848993897438,
      -0.02428571879863739,
      0.0013986179837957025,
      -0.03225398063659668,
      -0.01810470223426819,
      -0.0032023731619119644,
      -0.002046073554083705,
      -0.007479596883058548,
      -0.03507716581225395,
      0.07373631745576859,
      0.006745305377990007,
      -0.04460770636796951,
      0.0060327616520226,
      0.08109745383262634,
      0.07682939618825912,
      -0.014745074324309826,
      -0.0655461996793747,
      0.006690566428005695,
      -0.009620629251003265,
      0.045171432197093964,
      0.010118817910552025,
      -0.043203506618738174,
      -0.018762607127428055,
      -0.0572076216340065,
      0.018751749768853188,
      -0.024102678522467613,
      -0.01645466685295105,
      0.0031524316873401403,
      -0.02967952936887741,
      0.06289996206760406,
      0.05371818691492081,
      -0.08404944837093353,
      -0.011010928079485893,
      0.025773217901587486,
      0.035665109753608704,
      -0.01936953514814377,
      -0.012114387936890125,
      -0.05611854046583176,
      0.07855476438999176,
      0.04136420041322708,
      -0.017168262973427773,
      -0.09189929813146591,
      0.021294966340065002,
      -0.003092322265729308,
      0.037712112069129944,
      -0.061905160546302795,
      -0.03641790524125099,
      -0.02495565637946129,
      0.008549561724066734,
      -0.05425290763378143,
      -0.07299964129924774,
      0.054810721427202225,
      -0.022275954484939575,
      0.014561396092176437,
      0.009388962760567665,
      -0.11861288547515869,
      0.07212356477975845,
      -0.04839209467172623,
      -0.047536130994558334,
      0.08912263065576553,
      0.07914989441633224,
      0.019998181611299515,
      0.03382923826575279,
      -0.009954971261322498,
      -0.00034850332303903997,
      0.010148551315069199,
      0.06756185740232468,
      0.008213970810174942,
      0.011415185406804085,
      0.012825991027057171,
      0.02964171953499317,
      -0.10453913360834122,
      0.00801846943795681,
      0.03551936149597168,
      0.002445682417601347,
      -0.04010816290974617,
      -0.02080024965107441,
      0.002411517547443509,
      0.06342717260122299,
      0.026054752990603447,
      0.05674314871430397,
      -0.047521788626909256,
      0.022042743861675262,
      -0.06513848900794983,
      0.035146865993738174,
      0.07139168679714203,
      0.06297898292541504,
      -0.05763209983706474,
      0.07691148668527603,
      -0.015264295041561127,
      0.05575622618198395,
      0.02016250416636467,
      -0.010063590481877327,
      0.030468249693512917,
      0.016668442636728287,
      0.024459153413772583,
      -0.0899103432893753,
      0.07780476659536362,
      -0.07761203497648239,
      -0.04455455392599106,
      -0.046482764184474945,
      -0.04471788927912712,
      -0.00018650924903340638,
      -0.007270265836268663,
      0.06468865275382996,
      0.023023029789328575,
      -0.04280903562903404,
      0.004065960180014372,
      0.010171594098210335,
      0.10173097252845764,
      -0.024170445278286934,
      0.05036001279950142,
      0.010983005166053772,
      -0.017774900421500206,
      -0.06876248866319656,
      -0.03981223329901695,
      0.02007153257727623,
      0.0011784278322011232,
      -0.0032977587543427944,
      -0.0899897888302803,
      -0.04269314557313919,
      -0.048137325793504715,
      0.04082174226641655,
      0.015875978395342827,
      0.028476277366280556,
      0.06592532992362976,
      -0.01633092574775219,
      0.00482854014262557,
      0.01161655317991972,
      -0.08829183131456375,
      -0.02179366536438465,
      0.006414329633116722,
      0.00624231668189168,
      0.03737134486436844,
      -0.007264465093612671,
      -0.001080547459423542,
      -0.012234160676598549,
      0.01944512128829956,
      -0.014821084216237068,
      0.009125002659857273,
      -0.03639610856771469,
      -0.06988680362701416,
      -0.0015657320618629456
    ],
    [
      -0.029130160808563232,
      -0.013141878880560398,
      -0.008452017791569233,
      0.010138151235878468,
      6.180024502100423e-05,
      0.012099148705601692,
      0.07672613114118576,
      0.04543135687708855,
      0.040736123919487,
      -0.00666835019364953,
      0.050351597368717194,
      -0.028314584866166115,
      -0.11237325519323349,
      -0.03992050886154175,
      0.028601963073015213,
      -0.019528158009052277,
      -0.01634250022470951,
      -0.0224420465528965,
      -0.014743121340870857,
      -0.0706690177321434,
      -0.008062062785029411,
      0.0033036398235708475,
      -0.002659281948581338,
      0.016633007675409317,
      0.06641370803117752,
      -0.020482616499066353,
      -0.017671136185526848,
      0.014505496248602867,
      -0.037335626780986786,
      0.03004678525030613,
      0.03560890629887581,
      0.0459916852414608,
      0.025636645033955574,
      0.0203695185482502,
      -0.06580830365419388,
      -0.04496355727314949,
      0.06167994439601898,
      -0.059368547052145004,
      -0.024448765441775322,
      -0.05729219317436218,
      -0.06517248600721359,
      -0.01278096903115511,
      -0.013642331585288048,
      -0.012228155508637428,
      0.0015717120841145515,
      -0.044954393059015274,
      0.04079657420516014,
      -0.02491910010576248,
      0.0972694382071495,
      0.06298874318599701,
      0.016500620171427727,
      -0.025987979024648666,
      0.005739937070757151,
      -0.016515573486685753,
      0.036439720541238785,
      0.014289318583905697,
      0.04711364209651947,
      -0.02525477111339569,
      0.04575471580028534,
      0.009488842450082302,
      -0.07146531343460083,
      0.012855828739702702,
      0.02454368770122528,
      -0.05335128307342529,
      0.016581600531935692,
      0.037645891308784485,
      0.04024743661284447,
      -0.007600502576678991,
      -0.03627089783549309,
      -0.018640555441379547,
      -0.024875303730368614,
      -0.03599952533841133,
      0.052060797810554504,
      -0.03786219283938408,
      0.08047378063201904,
      -0.03796694800257683,
      -0.02861059084534645,
      0.03289814293384552,
      0.03643948212265968,
      0.05184667930006981,
      0.03370263800024986,
      0.021596010774374008,
      0.06548207998275757,
      -0.03704133257269859,
      0.024562863633036613,
      -0.02276991680264473,
      0.01934134215116501,
      0.01767905242741108,
      -0.037309642881155014,
      0.007560966536402702,
      0.03790806978940964,
      0.06393665820360184,
      -0.03649860993027687,
      -0.08792464435100555,
      0.013580184429883957,
      0.007982952520251274,
      0.06039619818329811,
      -0.03830396384000778,
      0.028123533353209496,
      -0.07381359487771988,
      -0.03676318749785423,
      -0.0028175031766295433,
      -0.07475786656141281,
      0.13177432119846344,
      -0.010301947593688965,
      -0.024394692853093147,
      0.007733725477010012,
      -0.10158791393041611,
      -0.04715766757726669,
      0.02036096714437008,
      0.01149730198085308,
      0.03144899383187294,
      0.038194987922906876,
      -0.06769756227731705,
      -0.059721626341342926,
      0.08362524211406708,
      -0.004733758978545666,
      0.05387115478515625,
      -0.07937885075807571,
      -0.007138880435377359,
      0.043558862060308456,
      0.042680926620960236,
      -0.03215520828962326,
      0.007551806978881359,
      0.12652461230754852,
      -0.046198323369026184,
      0.015317997895181179,
      0.01785111613571644,
      -0.008466825820505619,
      -0.019736547023057938,
      -0.009444033727049828,
      -0.041982993483543396,
      -0.04636780545115471,
      0.0398784838616848,
      0.01981305330991745,
      -0.013631708920001984,
      -0.016976861283183098,
      0.03938915207982063,
      -0.03714057058095932,
      0.003562695812433958,
      0.01530148833990097,
      -0.011564159765839577,
      0.07696705311536789,
      0.03352997824549675,
      -0.04080332815647125,
      0.018696991726756096,
      0.00835397094488144,
      0.0015571245457977057,
      -0.02492489665746689,
      -0.025531701743602753,
      -0.04971509426832199,
      -0.06259572505950928,
      -0.03930576890707016,
      -0.025453263893723488,
      0.07829537242650986,
      -0.04985702037811279,
      0.03015906549990177,
      0.08157151192426682,
      0.014831074513494968,
      0.020420316606760025,
      -0.0434754453599453,
      0.024308279156684875,
      -0.01670931652188301,
      -0.03955669701099396,
      0.04657980427145958,
      -0.051827408373355865,
      0.02354961819946766,
      0.039537180215120316,
      0.025739192962646484,
      -0.036095090210437775,
      -0.019512638449668884,
      -0.008029380813241005,
      -0.01032195519655943,
      -0.007159126456826925,
      0.02058778516948223,
      0.0035246671177446842,
      -0.04946977272629738,
      0.03046003170311451,
      -0.010501272976398468,
      -0.052723389118909836,
      -0.03153206408023834,
      0.016794444993138313,
      0.03789282962679863,
      -0.092727892100811,
      -0.016526248306035995,
      -0.02223242074251175,
      -0.10909561812877655,
      0.07018068432807922,
      0.04739755019545555,
      0.04647155851125717,
      -0.0024142740294337273,
      0.01969628408551216,
      -0.03305447846651077,
      0.027450302615761757,
      0.000770687242038548,
      -0.021296566352248192,
      0.013855098746716976,
      0.07447028905153275,
      -0.030541742220520973,
      0.027100032195448875,
      0.014422307722270489,
      -0.03396386280655861,
      -0.02937477082014084,
      0.008930887095630169,
      0.0026018053758889437,
      -0.05308422073721886,
      -0.01629061810672283,
      0.06009899079799652,
      -0.012096266262233257,
      0.10985296964645386,
      0.028496086597442627,
      -0.0755581259727478,
      -0.011048804968595505,
      0.09745452553033829,
      0.01307142898440361,
      0.015077666379511356,
      4.324641849962063e-05,
      -0.06714396178722382,
      0.04209406301379204,
      0.04629247635602951,
      -0.007123956922441721,
      -0.09423178434371948,
      0.05623171478509903,
      0.044657424092292786,
      -0.028510883450508118,
      0.025195373222231865,
      -0.055148422718048096,
      -0.024409297853708267,
      0.012429004535079002,
      -0.03064769320189953,
      -0.010457776486873627,
      0.0057759713381528854,
      0.05789772793650627,
      0.005362791009247303,
      -0.06923113763332367,
      -0.02893589437007904,
      0.019012073054909706,
      0.03109556809067726,
      -0.0030411742627620697,
      0.011973158456385136,
      -0.006086052395403385,
      -0.04527055099606514,
      -0.027961624786257744,
      -0.016528833657503128,
      0.003939997870475054,
      0.01949022337794304,
      -0.01728220283985138,
      0.006105723790824413,
      -0.06932675093412399,
      0.0173651110380888,
      0.05663967877626419,
      0.007238069083541632,
      0.08764981478452682,
      0.026867924258112907,
      0.06299400329589844,
      -0.07182013243436813,
      0.02470753900706768,
      0.06609489768743515,
      0.05047731101512909,
      -0.07942916452884674,
      0.07982923835515976,
      -0.028642086312174797,
      0.010816464200615883,
      -0.0024222368374466896,
      -0.020712723955512047,
      -0.03129206597805023,
      0.06644322723150253,
      0.05093243718147278,
      -0.054204344749450684,
      -0.02895338460803032,
      0.058635737746953964,
      0.046202369034290314,
      -0.00650669913738966,
      -0.052535153925418854,
      -0.020870661363005638,
      -0.08934080600738525,
      0.04977857694029808,
      0.01314971037209034,
      0.01143590733408928,
      0.010452959686517715,
      0.0030506288167089224,
      0.08405005931854248,
      0.0686895027756691,
      -0.005700976587831974,
      0.023615609854459763,
      -0.07015248388051987,
      -0.027191905304789543,
      0.07039486616849899,
      0.01647879369556904,
      -0.002152422908693552,
      0.00599087867885828,
      -0.0494677796959877,
      -0.0454191118478775,
      0.03959895670413971,
      -0.06302840262651443,
      0.06176544353365898,
      0.04643723741173744,
      0.052050840109586716,
      0.001888195751234889,
      0.09655379503965378,
      -0.028195589780807495,
      0.0456475131213665,
      0.02411917969584465,
      0.07221774756908417,
      -0.02051054872572422,
      0.033185526728630066,
      0.01617664471268654,
      0.1089961975812912,
      0.057829804718494415,
      0.013565421104431152,
      -0.04005596414208412,
      0.02390432544052601,
      0.021028976887464523,
      0.03450421243906021,
      -0.0032540042884647846,
      0.03186895698308945,
      0.05283033475279808,
      0.005428860429674387,
      -0.031717054545879364,
      -0.04669561609625816,
      -0.001233553746715188,
      -0.06825278699398041,
      -0.005975234787911177,
      0.023737765848636627,
      -0.014644341543316841,
      -0.006982963532209396,
      0.016281962394714355,
      0.009467670693993568,
      -0.015130477026104927,
      -0.07165788114070892,
      0.049166128039360046,
      -0.021781789138913155,
      0.029680002480745316,
      -0.03306505084037781,
      0.005394980311393738,
      0.01680580899119377,
      -0.0220110472291708,
      -0.028924610465765,
      -0.03704918548464775,
      0.06845298409461975,
      -0.029522135853767395,
      -0.026270586997270584,
      0.008336090482771397,
      -0.017726687714457512,
      0.027518177404999733,
      0.05157534405589104,
      0.03539048880338669,
      0.042769141495227814,
      -0.02927032671868801,
      0.027774449437856674,
      0.019628245383501053,
      0.011074523441493511,
      -0.024179300293326378,
      -0.007480660453438759,
      -0.024873515591025352,
      -0.05248274654150009,
      -0.0011490609031170607,
      -0.05514977499842644,
      -0.03489872068166733,
      -0.010506152175366879,
      -0.06237674877047539,
      -0.03236336261034012,
      -0.04752014949917793,
      0.008113277144730091,
      -0.021753700450062752,
      -0.015230856835842133,
      -0.03975454717874527,
      -0.021854324266314507,
      -0.020869523286819458,
      0.011546107940375805,
      0.0358857586979866,
      -0.06168341636657715,
      0.011848323978483677,
      0.11591245234012604,
      0.005747020710259676,
      0.0444616824388504,
      0.01582365296781063,
      0.005059745628386736,
      0.013227309100329876,
      -0.024682536721229553,
      0.02158105932176113,
      0.026184719055891037,
      0.036458201706409454,
      0.06234350800514221,
      0.033468324691057205,
      0.022337088361382484,
      0.013872935436666012,
      0.02309776283800602,
      -0.01468301098793745,
      0.05151486024260521,
      0.024597225710749626,
      -0.02300599403679371,
      0.09587298333644867,
      0.09429146349430084,
      0.002718778792768717,
      0.02105931006371975,
      0.018639404326677322,
      0.0007791220559738576,
      -0.022196803241968155,
      0.01655140519142151,
      -0.03930990770459175,
      -0.060926683247089386,
      0.046775951981544495,
      0.05898746848106384,
      -0.0280215535312891,
      0.09910284727811813,
      -0.005032577086240053,
      0.021737346425652504,
      0.08274639397859573,
      -0.021208180114626884,
      -0.04607124626636505,
      0.027799097821116447,
      -0.026131417602300644,
      0.053500525653362274,
      -0.027484452351927757,
      -0.04832538217306137,
      -0.018042676150798798,
      -0.1157137081027031,
      0.04456927627325058,
      0.038358885794878006,
      0.04003524407744408,
      -0.05336926504969597,
      -0.003498462727293372,
      -0.0053629688918590546,
      -0.021182499825954437,
      -0.03175825998187065,
      0.005170758813619614,
      0.010180074721574783,
      0.053365062922239304,
      -0.006050169467926025,
      -0.01882772706449032,
      -0.02804151549935341,
      0.011630387045443058,
      0.007253449875861406,
      0.005561967380344868,
      0.016098627820611,
      -0.031425073742866516,
      -0.07336179912090302,
      -0.03368101269006729,
      -0.006074286997318268,
      0.033354926854372025,
      -0.06799010187387466,
      -0.029943114146590233,
      -0.05014351010322571,
      0.03574880212545395,
      -0.03790717571973801,
      -0.032975807785987854,
      -0.04909783601760864,
      -0.058888934552669525,
      0.04165877774357796,
      -0.06895772367715836,
      -0.006151747424155474,
      0.07934869080781937,
      -0.06416246294975281,
      -0.01749277673661709,
      -0.012651951983571053,
      0.07821004092693329,
      0.020696958526968956,
      0.03384161368012428,
      -0.0007816434954293072,
      -0.05498071759939194,
      0.09996341913938522,
      0.047887153923511505,
      0.020549604669213295,
      -0.08380692452192307,
      -0.07451845705509186,
      0.011037202551960945,
      0.05245134234428406,
      0.05177346616983414,
      0.041104480624198914,
      -0.00027131784008815885,
      0.09819992631673813,
      0.05542316287755966,
      -0.013289159163832664,
      0.02489505149424076,
      0.031340647488832474,
      -0.0573650598526001,
      -0.03820284083485603,
      0.03204726427793503,
      -0.028330016881227493,
      0.038104280829429626,
      0.01705295778810978,
      -0.0487942174077034,
      0.011155391111969948,
      -0.03176186606287956,
      0.08955035358667374,
      0.03624800965189934,
      0.016944462433457375,
      0.010166339576244354,
      0.06936398893594742,
      0.08968581259250641,
      -0.059290602803230286,
      -0.024157332256436348,
      -0.09069076925516129,
      -0.02836456336081028,
      -0.0023348915856331587,
      0.018179915845394135,
      -0.056989021599292755,
      0.05153384059667587,
      -0.030042193830013275,
      -0.011651563458144665,
      -0.02852998487651348,
      -0.039141908288002014
    ],
    [
      -0.009401329793035984,
      0.025836976245045662,
      0.009934036061167717,
      0.030723486095666885,
      0.018803779035806656,
      -0.018133219331502914,
      0.0457189567387104,
      0.024800799787044525,
      0.0641268715262413,
      -0.11451956629753113,
      0.04632564261555672,
      -0.007470446638762951,
      0.08353538066148758,
      -0.10938253998756409,
      -0.018783271312713623,
      -0.10346804559230804,
      0.010133527219295502,
      0.0257932897657156,
      0.036276400089263916,
      -0.01767101138830185,
      -0.04601918160915375,
      0.06365027278661728,
      -0.015766505151987076,
      0.029260266572237015,
      -0.04288358613848686,
      0.017684761434793472,
      0.04147619009017944,
      -0.047716137021780014,
      -0.12303610146045685,
      0.08218710869550705,
      -0.02547905594110489,
      -0.007969013415277004,
      -0.08257394284009933,
      -0.0333390086889267,
      0.045863762497901917,
      -0.004085811786353588,
      0.027417993173003197,
      -0.011878990568220615,
      -0.057228170335292816,
      0.012052318081259727,
      -0.051817286759614944,
      0.038297928869724274,
      0.03641970828175545,
      0.014414705336093903,
      0.047833871096372604,
      -0.061640918254852295,
      0.08554922789335251,
      -0.05221720412373543,
      0.0023098995443433523,
      -0.031248953193426132,
      0.09672169387340546,
      -0.04879237711429596,
      -0.03349652886390686,
      0.0433102548122406,
      0.023047711700201035,
      0.041106440126895905,
      -0.009765081107616425,
      -0.01675744168460369,
      -0.037173520773649216,
      0.07613731175661087,
      -0.017444273456931114,
      -0.022267181426286697,
      -0.03335317224264145,
      0.024503936991095543,
      -0.05670519173145294,
      0.09160838276147842,
      0.02295013517141342,
      0.0037760960403829813,
      0.036253929138183594,
      0.039734646677970886,
      0.05068063735961914,
      -0.03774942457675934,
      -0.025885427370667458,
      0.016955753788352013,
      -0.051265403628349304,
      -0.002029010560363531,
      -0.005119666922837496,
      0.011252758093178272,
      -0.05461488664150238,
      0.07291346043348312,
      0.08047420531511307,
      0.07640867680311203,
      0.010731776244938374,
      -0.03845691308379173,
      0.08440496027469635,
      0.028544750064611435,
      0.08863472938537598,
      -0.03125615790486336,
      0.04926985502243042,
      0.023919593542814255,
      0.005002619232982397,
      -0.0413576140999794,
      -0.0032251039519906044,
      0.05509340018033981,
      -0.011858749203383923,
      -0.004248480312526226,
      0.07234134525060654,
      -0.044997747987508774,
      -0.03636202588677406,
      -0.02207075245678425,
      -0.024811625480651855,
      -0.014029393903911114,
      0.0598992258310318,
      -0.05665955692529678,
      0.035404905676841736,
      0.026300538331270218,
      -0.02590949274599552,
      0.02364250458776951,
      -0.04293757304549217,
      -0.02212945558130741,
      -0.09510339796543121,
      -0.039519280195236206,
      -0.06884655356407166,
      -0.0049624270759522915,
      -0.036212388426065445,
      0.004089934751391411,
      -0.024235760793089867,
      0.02261815033853054,
      0.04652439430356026,
      -0.010415589436888695,
      0.0693892166018486,
      0.04627522453665733,
      -0.04949205368757248,
      0.049663130193948746,
      0.05154010280966759,
      -0.11019451171159744,
      0.06571147590875626,
      -0.08011595904827118,
      -0.09902070462703705,
      -0.06099240481853485,
      -0.028823627158999443,
      -0.11630025506019592,
      0.003945619333535433,
      0.014002896845340729,
      -0.03599982708692551,
      -0.0077551002614200115,
      -0.06679857522249222,
      0.014927530661225319,
      0.012303885072469711,
      0.04317077249288559,
      0.0458647757768631,
      -0.038138799369335175,
      0.015581685118377209,
      0.08526081591844559,
      -0.011507132090628147,
      0.04551508650183678,
      -0.04104509949684143,
      -0.011441783979535103,
      -0.016327885910868645,
      -0.00014504673890769482,
      0.028067786246538162,
      0.009118220768868923,
      -0.003976484760642052,
      0.04741508513689041,
      -0.05893583595752716,
      -0.020333729684352875,
      0.037633344531059265,
      0.03022012487053871,
      0.0025169497821480036,
      0.019303862005472183,
      -0.03798626735806465,
      -0.0005722275818698108,
      -0.032759733498096466,
      -0.0791734829545021,
      0.050175685435533524,
      0.01934455707669258,
      0.043161120265722275,
      0.054234690964221954,
      -0.09235822409391403,
      0.052412454038858414,
      -0.014797985553741455,
      0.04427867755293846,
      -0.0981593132019043,
      -0.03141217678785324,
      -0.006516358349472284,
      0.05686310678720474,
      -0.11904492974281311,
      0.003350401995703578,
      0.010835335589945316,
      -0.07785333693027496,
      0.0023143815342336893,
      0.05599461495876312,
      0.017076561227440834,
      0.02762804739177227,
      -0.04688156768679619,
      -0.08688217401504517,
      -0.025384020060300827,
      0.03326963260769844,
      -0.047487299889326096,
      -0.009433078579604626,
      -0.016313986852765083,
      0.02524706907570362,
      -0.008945171721279621,
      0.004070010501891375,
      -0.011133899912238121,
      0.048864156007766724,
      -0.039556022733449936,
      -0.030333684757351875,
      0.0025546764954924583,
      0.08018721640110016,
      0.01646387204527855,
      0.06373230367898941,
      0.008342009969055653,
      -0.0922447144985199,
      -0.02778119407594204,
      -0.09634842723608017,
      0.0365203320980072,
      -0.0198664627969265,
      -0.06307599693536758,
      -0.05559230595827103,
      -0.03303765133023262,
      -0.00031840772135183215,
      0.0428021103143692,
      -0.004598973784595728,
      -0.06685128808021545,
      -0.014719263650476933,
      0.014988027513027191,
      0.03233618289232254,
      0.07165466994047165,
      -0.006409834139049053,
      -0.022041425108909607,
      0.06042608991265297,
      0.02669042907655239,
      -0.037563323974609375,
      0.009720890782773495,
      0.03093285672366619,
      0.027637643739581108,
      -0.026767197996377945,
      -0.001895503024570644,
      0.028452275320887566,
      0.030926670879125595,
      0.06103428453207016,
      0.023762967437505722,
      0.04631785675883293,
      0.020482180640101433,
      0.07082773745059967,
      0.013302388601005077,
      -0.05202093347907066,
      -0.029222967103123665,
      0.04772574082016945,
      0.03516775742173195,
      -0.004315703641623259,
      0.08210358768701553,
      -0.058945756405591965,
      0.012890229932963848,
      -0.035780590027570724,
      0.05081133171916008,
      0.019450491294264793,
      -0.03572327271103859,
      0.0020728199742734432,
      -0.04477934166789055,
      -0.019126038998365402,
      -0.023632721975445747,
      -0.018956385552883148,
      0.047369785606861115,
      -0.06574644893407822,
      0.021632442250847816,
      0.04734349623322487,
      0.0410657674074173,
      -0.005512479227036238,
      0.026704318821430206,
      0.016160110011696815,
      -0.01223865244537592,
      0.001961166737601161,
      0.020111363381147385,
      0.005804083310067654,
      0.03423655405640602,
      0.038332048803567886,
      0.050785135477781296,
      0.013676196336746216,
      0.03906629607081413,
      0.027809198945760727,
      0.005748807918280363,
      -0.0031443778425455093,
      0.056150250136852264,
      0.06131502613425255,
      -0.0336838997900486,
      0.0905303880572319,
      0.027956325560808182,
      -0.030993608757853508,
      0.006860018707811832,
      -0.10347386449575424,
      0.0184953436255455,
      0.001552707515656948,
      0.07146704196929932,
      0.010164547711610794,
      -0.015207318589091301,
      -0.0059105041436851025,
      0.005186297930777073,
      0.038986653089523315,
      0.025123007595539093,
      0.0007243247819133103,
      -0.009745262563228607,
      -0.010730350390076637,
      0.02317873015999794,
      0.06654123961925507,
      0.00759410485625267,
      0.027010850608348846,
      0.02099856361746788,
      0.02589147910475731,
      -0.05188539996743202,
      0.03442725911736488,
      0.012765455059707165,
      0.008672294206917286,
      -0.015066058374941349,
      -0.002404313301667571,
      -0.04472675547003746,
      0.021698294207453728,
      -0.006510445848107338,
      0.0589476153254509,
      0.0935191959142685,
      0.01235539186745882,
      -0.002684009028598666,
      -0.026356888934969902,
      -0.06797704100608826,
      0.012061753310263157,
      0.16325779259204865,
      -0.0392921082675457,
      -0.02736707404255867,
      -0.07405725866556168,
      0.07190582156181335,
      0.10259580612182617,
      0.01660148799419403,
      0.018327342346310616,
      -0.03667411953210831,
      -0.010060540400445461,
      0.023800477385520935,
      -0.011077304370701313,
      0.08120086789131165,
      0.04961748421192169,
      0.023326268419623375,
      -0.0411699153482914,
      -0.11193428188562393,
      -0.05727455019950867,
      0.0337340347468853,
      -0.011081645265221596,
      -0.024560028687119484,
      -0.005948633886873722,
      0.0952230915427208,
      0.0028005624189972878,
      -0.03445807099342346,
      0.0035399554762989283,
      0.02128332480788231,
      0.0824151337146759,
      0.018961412832140923,
      -0.09606216102838516,
      0.03458330035209656,
      0.0019339279970154166,
      0.053536489605903625,
      -0.05214230343699455,
      0.017726704478263855,
      -0.01995079033076763,
      0.03969214856624603,
      -0.015200280584394932,
      -0.028289619833230972,
      -0.006700113881379366,
      0.012760448269546032,
      -0.008263099007308483,
      -0.010611132718622684,
      0.016085075214505196,
      -0.08632362633943558,
      -0.05085524544119835,
      -0.04896014556288719,
      0.02756955288350582,
      0.0359925851225853,
      -0.07120704650878906,
      0.010209772735834122,
      -0.011582296341657639,
      -0.05827366188168526,
      -0.007713305298238993,
      -0.04843682423233986,
      -0.00589674012735486,
      0.03491351380944252,
      -0.055414214730262756,
      -0.04744606092572212,
      -0.014151944778859615,
      -0.027699492871761322,
      -0.06254266947507858,
      0.03053933195769787,
      -0.1284894198179245,
      0.039780836552381516,
      0.008684121072292328,
      -0.041701529175043106,
      0.015645654872059822,
      0.02137991040945053,
      -0.05433567613363266,
      0.046003226190805435,
      -0.05094265937805176,
      0.04069443419575691,
      0.07088807225227356,
      -0.03241985663771629,
      -0.0005399767542257905,
      0.03291942924261093,
      -0.0005935742519795895,
      0.0613376721739769,
      0.04573976621031761,
      0.05780516564846039,
      -0.05181410536170006,
      0.007115263491868973,
      -0.0332128144800663,
      0.019826050847768784,
      -0.05701858550310135,
      -0.01599787548184395,
      -0.06875915080308914,
      -0.09031964093446732,
      0.0013973393943160772,
      -0.01522249635308981,
      -0.03487754240632057,
      0.032776299864053726,
      0.02307596616446972,
      0.01576637104153633,
      -0.0010718844132497907,
      0.02323094755411148,
      -0.005384746007621288,
      0.024694981053471565,
      0.0014479291858151555,
      0.011071617715060711,
      -0.022675881162285805,
      0.007975040934979916,
      -0.11058594286441803,
      0.08745260536670685,
      0.03587469831109047,
      0.031369611620903015,
      0.029072821140289307,
      -0.014358394779264927,
      0.038908522576093674,
      0.03560566529631615,
      0.06523299962282181,
      0.0323467031121254,
      0.06156640499830246,
      0.021125376224517822,
      0.025349434465169907,
      -0.036333777010440826,
      -0.032131899148225784,
      -0.04820597916841507,
      0.08325949311256409,
      -0.0035888829734176397,
      0.05110970884561539,
      0.025359613820910454,
      -0.019092388451099396,
      0.008712174370884895,
      0.013695926405489445,
      0.009623751975595951,
      0.004339928273111582,
      0.0820198804140091,
      -0.010295330546796322,
      0.029701292514801025,
      0.09773856401443481,
      0.028666244819760323,
      0.017468072474002838,
      -0.11520923674106598,
      -0.03414474055171013,
      -0.08245977014303207,
      -0.03342875838279724,
      -0.059269800782203674,
      0.01748455874621868,
      0.03355778753757477,
      0.06972301751375198,
      0.009339197538793087,
      0.014090845361351967,
      0.027082718908786774,
      -0.0025938476901501417,
      0.08291390538215637,
      -0.09449645131826401,
      0.16815738379955292,
      -0.07438746839761734,
      -0.014037099666893482,
      0.014394097961485386,
      0.07113617658615112,
      0.00820582453161478,
      -0.09969470649957657,
      0.057022109627723694,
      -0.010592510923743248,
      -0.023236820474267006,
      -0.02471349947154522,
      -0.07450306415557861,
      -0.01678837463259697,
      0.023852091282606125,
      -0.006439025979489088,
      0.017233211547136307,
      -0.013984303921461105,
      -0.03346409276127815,
      0.058388929814100266,
      -0.02947072871029377,
      -0.09734329581260681,
      -0.008120306767523289,
      0.012748693116009235,
      0.011607742868363857,
      -0.03100818209350109,
      0.0010453857248649001,
      -0.040012579411268234,
      0.054024580866098404,
      0.04035784676671028,
      -0.010066116228699684,
      -0.014380954205989838,
      0.024116739630699158,
      0.039943207055330276,
      0.08543753623962402,
      0.01762429252266884,
      -0.038406435400247574,
      0.0013322472805157304,
      -0.07483433187007904,
      0.050393298268318176
    ],
    [
      -0.029192978516221046,
      0.0450945682823658,
      -0.005367875099182129,
      0.08051987737417221,
      0.04919847846031189,
      0.007518869359046221,
      0.057367946952581406,
      -0.015650609508156776,
      0.04123940318822861,
      -0.0020756616722792387,
      -0.010205544531345367,
      0.02903282269835472,
      -0.011035236530005932,
      -0.051432374864816666,
      -0.05618765577673912,
      0.019336650148034096,
      -0.00966657605022192,
      0.10484244674444199,
      -0.03471720963716507,
      0.010165520943701267,
      -0.041361793875694275,
      0.011065972037613392,
      0.028293324634432793,
      0.0880529135465622,
      -0.02127813547849655,
      0.0453476645052433,
      0.004978664685040712,
      -0.02884882315993309,
      -0.012247283942997456,
      0.0068462262861430645,
      -0.02369276061654091,
      0.030621645972132683,
      0.0034411821980029345,
      -0.09041447937488556,
      0.042338281869888306,
      -0.037611015141010284,
      -0.019746137782931328,
      0.023120038211345673,
      0.014398899860680103,
      -0.04348274692893028,
      0.04080672189593315,
      -0.012414330616593361,
      0.0009355498477816582,
      -0.021537955850362778,
      -0.02074066549539566,
      -0.0019389413064345717,
      0.09238064289093018,
      -0.01298530399799347,
      0.009287526831030846,
      -0.058436594903469086,
      0.004714522510766983,
      0.005433512385934591,
      0.017029041424393654,
      0.01760868914425373,
      0.011137758381664753,
      -0.0008035398204810917,
      -0.016262177377939224,
      -0.05960125848650932,
      0.024943117052316666,
      -0.015641115605831146,
      -0.030313659459352493,
      0.07117650657892227,
      -0.04347624629735947,
      0.02240522764623165,
      -0.015678931027650833,
      0.05709333345293999,
      -0.06316150724887848,
      -0.07612762600183487,
      -0.017212586477398872,
      0.014952167868614197,
      -0.07392023503780365,
      -0.0760115534067154,
      -0.03313422203063965,
      -0.04280957952141762,
      0.003960658796131611,
      0.0031299167312681675,
      0.0346713550388813,
      -0.0033793554175645113,
      -0.0012550115352496505,
      0.039492134004831314,
      -0.09397128224372864,
      0.039196502417325974,
      0.009975644759833813,
      -0.0036987015046179295,
      0.03522883355617523,
      0.05980346351861954,
      0.022808479145169258,
      -0.0886678546667099,
      -0.01619052328169346,
      -0.055605363100767136,
      0.03970007225871086,
      0.06089351326227188,
      0.02821214497089386,
      -0.008771942928433418,
      -0.01230123732239008,
      -0.01851026900112629,
      0.034174416214227676,
      0.020651260390877724,
      0.06329204142093658,
      -0.02629219926893711,
      0.07599589973688126,
      -0.024410128593444824,
      0.08029911667108536,
      0.062429264187812805,
      0.0634012520313263,
      0.03844590485095978,
      -0.002964711980894208,
      0.03801589459180832,
      0.07965542376041412,
      -0.009374766610562801,
      6.638073682552204e-05,
      -0.050886183977127075,
      -0.043437208980321884,
      0.06087527051568031,
      0.04058858007192612,
      -0.03182293847203255,
      -0.04690853878855705,
      0.023573266342282295,
      0.009686281904578209,
      0.01672784984111786,
      -0.011255467310547829,
      -0.008783811703324318,
      0.001529691624455154,
      0.07225289940834045,
      -0.027425160631537437,
      -0.003495048964396119,
      -0.04563039913773537,
      -0.022849543020129204,
      0.04571688547730446,
      -0.03810102120041847,
      -0.0043763029389083385,
      0.034884754568338394,
      0.008916336111724377,
      0.006151856854557991,
      -0.07455887645483017,
      0.0598590187728405,
      0.010966661386191845,
      0.04871999844908714,
      -0.06175355240702629,
      -0.05087744817137718,
      0.0243912972509861,
      0.05271449685096741,
      0.037816666066646576,
      0.05226856842637062,
      0.011921467259526253,
      -0.03469899296760559,
      -0.04490165412425995,
      0.03534137085080147,
      -0.011481352150440216,
      0.07770285755395889,
      0.03294586017727852,
      0.05413827300071716,
      0.022296905517578125,
      -0.055784888565540314,
      0.0013345653424039483,
      -0.01563454046845436,
      -0.03258029744029045,
      -0.0926983654499054,
      -0.019018281251192093,
      0.03391943871974945,
      -0.019544145092368126,
      0.055689889937639236,
      0.09208360314369202,
      0.007612499874085188,
      0.01590917259454727,
      -0.0722653940320015,
      -0.02247859537601471,
      0.0672995075583458,
      -0.015330597758293152,
      -0.0616629421710968,
      -0.02606634981930256,
      0.08871760219335556,
      -0.07000905275344849,
      0.015530451200902462,
      -0.10149233788251877,
      -0.009128727950155735,
      0.03804505616426468,
      -0.013587181456387043,
      -0.002502662828192115,
      -0.08285584300756454,
      -0.008295184932649136,
      0.03594297170639038,
      -0.006385617423802614,
      -0.01813950017094612,
      -0.03344167396426201,
      0.02859501540660858,
      0.026449739933013916,
      0.0223295446485281,
      -0.030784867703914642,
      0.025236237794160843,
      0.012475830502808094,
      0.07903294265270233,
      0.012255320325493813,
      -0.006208708044141531,
      -0.02882460318505764,
      -0.009447368793189526,
      0.029260780662298203,
      -0.025297190994024277,
      -0.021279584616422653,
      0.04095759987831116,
      0.09618024528026581,
      0.008041176944971085,
      0.013394728302955627,
      -0.01716986857354641,
      -0.10253744572401047,
      -0.01573515124619007,
      -0.027184411883354187,
      -0.04430701956152916,
      0.017290659248828888,
      -0.04364323616027832,
      0.02614869363605976,
      -0.032586414366960526,
      0.012458994053304195,
      -0.017478516325354576,
      0.06704359501600266,
      0.1420009434223175,
      -0.020175889134407043,
      0.03793512284755707,
      0.029366247355937958,
      0.04982111230492592,
      0.06678839772939682,
      -0.059200651943683624,
      0.006853504106402397,
      0.10529971867799759,
      -0.07072717696428299,
      -0.037824250757694244,
      0.012684828601777554,
      -0.08838397264480591,
      -0.060069408267736435,
      -0.011754325591027737,
      -0.04473984241485596,
      0.03353022783994675,
      -0.012929398566484451,
      -0.05816604942083359,
      0.022012976929545403,
      0.07816076278686523,
      -0.02701595053076744,
      0.03263715282082558,
      0.04063499718904495,
      -0.07789787650108337,
      0.034517552703619,
      -0.01694466918706894,
      0.017136644572019577,
      -0.1022479310631752,
      -0.06958650052547455,
      -0.0514862947165966,
      0.027939090505242348,
      0.04298818111419678,
      -0.06591174006462097,
      -0.023565907031297684,
      -0.001331568113528192,
      0.0028259537648409605,
      -0.10111334174871445,
      -0.056325241923332214,
      -0.1235368475317955,
      0.026867635548114777,
      -0.05410390719771385,
      0.012775735929608345,
      -0.051349230110645294,
      0.00943677593022585,
      -0.005049861501902342,
      0.032581351697444916,
      0.004511982202529907,
      -0.007519892416894436,
      -0.02542838081717491,
      0.046051815152168274,
      0.05204528570175171,
      0.05634963884949684,
      -0.08097892254590988,
      0.0328323096036911,
      0.049086201936006546,
      -0.0029144356958568096,
      -0.02228851243853569,
      0.06112005189061165,
      -0.028507590293884277,
      -0.020989853888750076,
      -0.007192665711045265,
      0.03811916336417198,
      -0.006245112046599388,
      -0.009276796132326126,
      -0.05123361200094223,
      -0.0587284117937088,
      -0.04703417420387268,
      0.04354928433895111,
      -0.038054563105106354,
      0.03830959275364876,
      -0.03825891390442848,
      -0.014328120276331902,
      -0.03871186450123787,
      -0.0391065888106823,
      0.06499595940113068,
      -0.04072853922843933,
      0.053927116096019745,
      0.009886546991765499,
      -0.023312292993068695,
      0.042144108563661575,
      0.022458652034401894,
      0.025335606187582016,
      -0.07706639915704727,
      0.06292256712913513,
      0.051609236747026443,
      0.00895458459854126,
      0.0043425969779491425,
      0.05301215872168541,
      -0.02883204258978367,
      -0.07710868120193481,
      0.013202307745814323,
      0.0047389608807861805,
      0.04296064376831055,
      -0.008934920653700829,
      -8.477127994410694e-05,
      -0.024764666333794594,
      -0.03912889584898949,
      -0.050254110246896744,
      0.030112242326140404,
      -0.01995350793004036,
      0.10895790159702301,
      -0.013308430090546608,
      -0.006376645527780056,
      0.024931512773036957,
      -0.006939987652003765,
      0.016558503732085228,
      -0.038477372378110886,
      -0.010787636041641235,
      -0.016375796869397163,
      -0.010491590946912766,
      0.038165852427482605,
      0.017027253285050392,
      -0.017420439049601555,
      -0.06420501321554184,
      0.002244900446385145,
      -0.05957360565662384,
      0.041476644575595856,
      0.025133104994893074,
      0.05340266972780228,
      0.03688390180468559,
      -0.02389974519610405,
      0.03857171908020973,
      0.01644084043800831,
      0.007805636152625084,
      -0.049989596009254456,
      -0.0030821191612631083,
      0.0247651357203722,
      -0.026331156492233276,
      0.027456708252429962,
      0.016504129394888878,
      0.02204791083931923,
      -0.0027352417819201946,
      -0.031038640066981316,
      -0.026578418910503387,
      -0.01770132966339588,
      -0.07814708352088928,
      0.06916206330060959,
      -0.045325733721256256,
      0.06446807086467743,
      0.0530126690864563,
      0.039749208837747574,
      -0.006235489621758461,
      0.005606039427220821,
      -0.0014719574246555567,
      0.07012005895376205,
      0.006681971717625856,
      0.04456726834177971,
      -0.000859163876157254,
      0.03238970786333084,
      -0.052472103387117386,
      0.12166143208742142,
      -0.027182765305042267,
      -0.005366866942495108,
      0.087825246155262,
      0.024853844195604324,
      -0.049904994666576385,
      0.011051476933062077,
      0.017333729192614555,
      -0.001433508936315775,
      -0.02698792889714241,
      0.05728801339864731,
      0.061712272465229034,
      0.033066149801015854,
      -0.046779144555330276,
      0.015080103650689125,
      -0.05173078551888466,
      -0.036158837378025055,
      -0.014797577634453773,
      -0.033118534833192825,
      -0.045045915991067886,
      -0.024624519050121307,
      0.033245235681533813,
      0.03160416707396507,
      0.015804922208189964,
      0.011831259354948997,
      0.04845475032925606,
      0.04841906577348709,
      -0.052656956017017365,
      -0.043016217648983,
      -0.09726245701313019,
      -0.06492748111486435,
      0.041156116873025894,
      -0.00013224242138676345,
      -0.07085743546485901,
      0.06745875626802444,
      -0.0411820188164711,
      -0.08457566052675247,
      -0.0039006085135042667,
      -0.027156086638569832,
      0.08512411266565323,
      0.04787997528910637,
      -0.08355682343244553,
      -0.02621706947684288,
      -0.056262895464897156,
      -0.0023767820093780756,
      -0.042353179305791855,
      -0.02628970704972744,
      0.05282766371965408,
      0.001863939454779029,
      0.02850046008825302,
      0.036446500569581985,
      0.03992317244410515,
      -0.05134833976626396,
      -0.05837974324822426,
      0.004011525306850672,
      0.02553236484527588,
      -0.017245395109057426,
      -0.13075321912765503,
      -0.08416127413511276,
      -0.050960056483745575,
      -0.03137297183275223,
      0.02184118889272213,
      0.044293057173490524,
      0.02753177098929882,
      0.07109440863132477,
      0.0012358414242044091,
      0.010183797217905521,
      -0.020995954051613808,
      0.01919381134212017,
      0.0012799397809430957,
      0.05079789087176323,
      -0.034334566444158554,
      -0.06537146866321564,
      -0.03910725563764572,
      -0.04003286361694336,
      0.014276452362537384,
      -0.0416913665831089,
      -0.01595083624124527,
      -0.061746444553136826,
      -0.034313879907131195,
      0.04057268053293228,
      -0.09277031570672989,
      0.031436737626791,
      0.0011410965817049146,
      0.010495954193174839,
      -0.06096794828772545,
      0.029775472357869148,
      -0.0013937882613390684,
      -0.02976069785654545,
      0.006770237814635038,
      0.005825894884765148,
      -0.015542404726147652,
      0.02662769891321659,
      -0.11295663565397263,
      0.007166059222072363,
      -0.015298600308597088,
      -0.05280231311917305,
      -0.07196927070617676,
      -0.047605931758880615,
      0.014221826568245888,
      -0.03970278427004814,
      0.004471282474696636,
      -0.035352323204278946,
      -0.0003935406857635826,
      0.02738419733941555,
      -0.03100171498954296,
      0.009612590074539185,
      -0.015514986589550972,
      0.024453366175293922,
      -0.05540429800748825,
      0.017630480229854584,
      -0.003906354308128357,
      -0.018803611397743225,
      -0.09863129258155823,
      0.019400902092456818,
      0.005293665453791618,
      -0.029697734862565994,
      0.014472348615527153,
      -0.04680348560214043,
      -0.012877955101430416,
      -0.06700552254915237,
      -0.009346838109195232,
      0.06442977488040924,
      0.036274224519729614,
      0.05447440966963768,
      -0.012294154614210129,
      0.00962517037987709,
      -0.008553584106266499,
      0.04695679247379303,
      0.024374479427933693,
      -0.001365046831779182,
      0.06467535346746445,
      0.020610017701983452,
      -0.025560099631547928,
      0.002137820003554225,
      0.10217935591936111,
      0.03219999372959137
    ],
    [
      -0.08782600611448288,
      -0.042276494204998016,
      -0.0014574781525880098,
      -0.021590176969766617,
      0.018036682158708572,
      -0.0029318914748728275,
      0.021316638216376305,
      0.012970960699021816,
      0.01657196134328842,
      -0.07407908141613007,
      0.06323496997356415,
      -0.00120760896243155,
      0.005006240215152502,
      -0.09804791957139969,
      0.014577523805201054,
      0.0037197915371507406,
      -0.02209659293293953,
      -0.02412041835486889,
      0.03817116841673851,
      0.012740304693579674,
      -0.06272556632757187,
      0.0034476546570658684,
      -0.039704255759716034,
      -0.04567151144146919,
      0.01071016676723957,
      -0.029720019549131393,
      0.04259173944592476,
      0.04339461401104927,
      -0.024368442595005035,
      -0.04192176088690758,
      0.09630671888589859,
      -0.00674687884747982,
      0.03280726820230484,
      -0.025414614006876945,
      0.08288165181875229,
      0.07259377837181091,
      -0.08470381796360016,
      0.0008680500322952867,
      0.0025956665631383657,
      0.056912440806627274,
      0.01922166533768177,
      -0.033396631479263306,
      0.005170488730072975,
      0.05349107086658478,
      0.06037265434861183,
      0.00048282521311193705,
      0.04385275021195412,
      0.016459817066788673,
      0.04892079159617424,
      0.020004069432616234,
      0.02068229764699936,
      -0.043030235916376114,
      -0.05353584140539169,
      -0.06650745868682861,
      0.04106837511062622,
      0.006361871026456356,
      0.04817382991313934,
      0.029429247602820396,
      0.0016910179983824492,
      -0.007436215877532959,
      -0.058948539197444916,
      0.017306003719568253,
      0.022126000374555588,
      0.030968492850661278,
      -0.03175267577171326,
      0.0022776874247938395,
      0.017022179439663887,
      -0.019519329071044922,
      0.0012598145985975862,
      -0.03179730102419853,
      -0.0759192407131195,
      -0.061445023864507675,
      -0.018541309982538223,
      -0.02613171376287937,
      0.043913133442401886,
      -0.045613646507263184,
      -0.029097262769937515,
      0.0027176421135663986,
      0.015279848128557205,
      -0.0038048094138503075,
      0.00027600035537034273,
      0.041299063712358475,
      -0.03603146970272064,
      0.02028423175215721,
      0.0015507512725889683,
      0.0157463476061821,
      -0.04449927434325218,
      -0.02222965843975544,
      -0.0025625554844737053,
      -0.1087321788072586,
      0.09585859626531601,
      -0.02150566875934601,
      -0.05668819323182106,
      -0.005484943278133869,
      -0.05558967590332031,
      -0.07633290439844131,
      0.06885150820016861,
      0.03787560760974884,
      -0.04097137600183487,
      0.01810433343052864,
      0.0034801242873072624,
      0.05797697231173515,
      0.0002770965511444956,
      0.01955440640449524,
      0.07365037500858307,
      -0.028862424194812775,
      0.01306844037026167,
      0.020724236965179443,
      -0.03415033593773842,
      0.018531566485762596,
      0.044854842126369476,
      0.031048865988850594,
      0.03905102238059044,
      0.05149848014116287,
      0.09892025589942932,
      0.032828643918037415,
      0.057693351060152054,
      -0.06196969002485275,
      0.10035109519958496,
      -0.07537799328565598,
      0.05728742107748985,
      0.0032102717086672783,
      -0.03017876297235489,
      0.013684111647307873,
      -0.0875244140625,
      -0.007070935796946287,
      -0.07365421205759048,
      0.011862714774906635,
      0.010100720450282097,
      -0.03433742746710777,
      0.009123141877353191,
      -0.00852214265614748,
      -0.017299819737672806,
      -0.015538002364337444,
      -0.006285916082561016,
      0.03708571195602417,
      0.014882820658385754,
      -0.01883932761847973,
      0.020887965336441994,
      -0.09735265374183655,
      0.03152545914053917,
      0.04556141421198845,
      0.022989556193351746,
      0.030519019812345505,
      0.06402932107448578,
      -0.007824437692761421,
      0.0037817698903381824,
      0.004368474707007408,
      0.03269490227103233,
      0.058653414249420166,
      -0.061520546674728394,
      -0.05556905269622803,
      -0.04568039998412132,
      0.028742464259266853,
      -0.013481046073138714,
      0.042287517338991165,
      -0.008958764374256134,
      -0.020384784787893295,
      -0.0009306533029302955,
      0.021404698491096497,
      -0.025102702900767326,
      -0.014317305758595467,
      -0.024132220074534416,
      -0.034707579761743546,
      0.017592111602425575,
      0.004517491906881332,
      -0.11789167672395706,
      -0.07246401906013489,
      0.011656597256660461,
      0.00033529038773849607,
      -0.010823347605764866,
      -0.03159197419881821,
      0.0026581475976854563,
      0.027226220816373825,
      -0.016293207183480263,
      0.0801854282617569,
      0.05209220200777054,
      0.028520002961158752,
      -0.051961373537778854,
      -0.02627294883131981,
      -0.09046687930822372,
      0.06267703324556351,
      -0.016545994207262993,
      -0.002666435670107603,
      -0.0232650525867939,
      -0.0013552219606935978,
      0.04199129343032837,
      -0.045885611325502396,
      0.011803057976067066,
      0.019793592393398285,
      -0.028290722519159317,
      -0.08833659440279007,
      0.038758281618356705,
      -0.05456403270363808,
      -0.022371619939804077,
      0.043335482478141785,
      -0.026434969156980515,
      0.022237630560994148,
      -0.02538471110165119,
      0.02724476344883442,
      0.0032244904432445765,
      0.052600689232349396,
      -0.06334051489830017,
      0.012443621642887592,
      0.009052588604390621,
      0.03743898123502731,
      -0.010976203717291355,
      0.03295919671654701,
      -0.049581002444028854,
      0.04126588627696037,
      -0.05480089411139488,
      -0.07563970983028412,
      0.03862227872014046,
      0.09884053468704224,
      -0.000641132181044668,
      -0.05266191065311432,
      0.07904919981956482,
      0.06169547513127327,
      0.0056504011154174805,
      0.08394944667816162,
      -0.012075345031917095,
      -0.07325076311826706,
      0.0223026555031538,
      0.03355645760893822,
      -0.042614374309778214,
      0.02098919451236725,
      0.03269889950752258,
      -0.06095478683710098,
      -0.023852242156863213,
      0.017874250188469887,
      -0.02891531214118004,
      -0.01732327975332737,
      -0.04717528074979782,
      0.021684259176254272,
      -0.03265602886676788,
      0.03935876861214638,
      0.060610342770814896,
      -0.017784766852855682,
      0.0012801161501556635,
      -0.0626661628484726,
      -0.010087703354656696,
      -0.03202352300286293,
      0.035894978791475296,
      -0.013940392062067986,
      -0.015824470669031143,
      -0.017917321994900703,
      -0.025577988475561142,
      -0.02651134878396988,
      0.013654160313308239,
      -0.0603826642036438,
      0.05363062024116516,
      0.0783010870218277,
      -0.03971099853515625,
      0.03398068621754646,
      0.029809247702360153,
      -0.13325123488903046,
      0.011279582045972347,
      -0.034149836748838425,
      0.1222226694226265,
      -0.030067427083849907,
      0.05310088023543358,
      0.0268360935151577,
      0.028132550418376923,
      0.04238652065396309,
      -0.002420450560748577,
      -0.058301154524087906,
      0.027059560641646385,
      0.03228999674320221,
      0.036496683955192566,
      0.04118238016963005,
      -0.0024468963965773582,
      -0.005651295650750399,
      -0.04592088237404823,
      0.04411507025361061,
      0.02719580940902233,
      0.044670891016721725,
      -0.006978191435337067,
      -0.007853707298636436,
      0.12731435894966125,
      -0.04766107350587845,
      -0.00924201961606741,
      -0.03346262872219086,
      0.04448026046156883,
      -0.010163920931518078,
      -0.047895647585392,
      -0.10486751794815063,
      0.01123202033340931,
      -0.029662393033504486,
      -0.0290850643068552,
      0.05370260030031204,
      0.015225708484649658,
      -0.07201901823282242,
      -0.004440269898623228,
      0.005372163373976946,
      0.03919745981693268,
      0.0798778310418129,
      -0.008598657324910164,
      0.010203656740486622,
      -0.023086223751306534,
      -0.022099917754530907,
      -0.04048313573002815,
      0.0009665350080467761,
      0.038677603006362915,
      -0.012879085727036,
      -0.052572548389434814,
      0.012534634210169315,
      0.022657087072730064,
      -0.06289325654506683,
      -0.009519346989691257,
      -0.033455464988946915,
      0.08619552105665207,
      0.007822255603969097,
      0.017013562843203545,
      -0.0208999365568161,
      0.022172916680574417,
      -0.03851257264614105,
      0.02712254226207733,
      0.010271339677274227,
      0.026325205340981483,
      0.012785025872290134,
      0.029686016961932182,
      0.07230118662118912,
      -0.002745914738625288,
      0.0840788334608078,
      -0.016866791993379593,
      0.05529971048235893,
      0.04523225873708725,
      0.006339889019727707,
      0.019391145557165146,
      -0.04831909015774727,
      -0.029804622754454613,
      -0.037458039820194244,
      0.03365378454327583,
      0.0561770424246788,
      0.0075398278422653675,
      0.044122882187366486,
      -0.0763252004981041,
      0.0037135439924895763,
      0.07048837840557098,
      0.13813820481300354,
      -0.0254394318908453,
      0.037546928972005844,
      0.012422485277056694,
      -0.01522370520979166,
      -0.07528291642665863,
      -0.03479158133268356,
      0.028280695900321007,
      0.11176466941833496,
      -0.03908105194568634,
      -0.011327756568789482,
      -0.020852969959378242,
      -0.00041042547672986984,
      0.009405956603586674,
      0.08522548526525497,
      0.034435760229825974,
      0.0501728281378746,
      0.021295493468642235,
      0.06493033468723297,
      -0.00212071998976171,
      -0.0509725846350193,
      -0.02066376619040966,
      0.05193299427628517,
      -0.018057405948638916,
      -0.08392827957868576,
      -0.06014677882194519,
      0.007662544492632151,
      0.014383445493876934,
      0.06078178435564041,
      0.002657170407474041,
      0.005292552523314953,
      0.08798303455114365,
      -0.01934061571955681,
      -0.023826463147997856,
      0.009623350575566292,
      -0.056031763553619385,
      0.037384871393442154,
      0.06423068046569824,
      0.05354318395256996,
      -0.026625623926520348,
      -0.014827373437583447,
      0.017961611971259117,
      -0.06274628639221191,
      0.13916431367397308,
      0.015223080292344093,
      -0.041670430451631546,
      -0.004922077059745789,
      0.054594673216342926,
      0.03598901629447937,
      0.03610992804169655,
      -0.027852337807416916,
      -0.06493792682886124,
      0.030502207577228546,
      0.07739944756031036,
      -0.0515146479010582,
      0.018347766250371933,
      -0.006345496978610754,
      -0.04874167591333389,
      0.0040911342948675156,
      0.04348481819033623,
      0.00762831000611186,
      -0.02904915064573288,
      -0.019414281472563744,
      0.07531051337718964,
      0.016984988003969193,
      -0.041478294879198074,
      -0.010753647424280643,
      0.017477091401815414,
      -0.043255168944597244,
      0.0009824291337281466,
      -0.02874671295285225,
      -0.09116887301206589,
      0.02300790697336197,
      0.0016620592214167118,
      0.08538977801799774,
      0.037671707570552826,
      -0.021906301379203796,
      -0.021288663148880005,
      -0.0035681873559951782,
      -0.03669263795018196,
      0.06190386414527893,
      -0.04998338595032692,
      0.07728123664855957,
      0.018340593203902245,
      -0.01380531769245863,
      0.012184124439954758,
      0.012391051277518272,
      0.05341320112347603,
      -0.0002210142120020464,
      -0.049286335706710815,
      0.1266937106847763,
      0.03981698304414749,
      0.08407395333051682,
      -0.024516599252820015,
      -0.03616342693567276,
      -0.0021747988648712635,
      -0.05822185426950455,
      -0.017679043114185333,
      -0.08232516050338745,
      0.05126863345503807,
      -0.003920065704733133,
      -0.031287115067243576,
      -0.042641740292310715,
      0.044043462723493576,
      -0.0206289142370224,
      -0.030018910765647888,
      0.015220731496810913,
      -0.011192330159246922,
      0.04438260197639465,
      -0.017754286527633667,
      0.03367440029978752,
      0.024288926273584366,
      -0.05997924134135246,
      -0.05484010651707649,
      0.03871018812060356,
      -0.02008240297436714,
      0.06334428489208221,
      -0.039308879524469376,
      0.0456119142472744,
      -0.02609163150191307,
      0.016005627810955048,
      -0.049175992608070374,
      -0.08305570483207703,
      0.03372293710708618,
      -0.024042883887887,
      0.010516257025301456,
      -0.012966543436050415,
      0.007293598260730505,
      -0.01402395311743021,
      0.015930037945508957,
      0.024989351630210876,
      0.04908917844295502,
      -0.024390647187829018,
      -0.02454218454658985,
      0.017702274024486542,
      -0.056073401123285294,
      0.03689582645893097,
      -0.05062372237443924,
      0.0025261829141527414,
      0.0043648965656757355,
      -0.023313188925385475,
      0.00416587432846427,
      -0.0006471555097959936,
      -0.025521377101540565,
      0.05017047002911568,
      0.01921822689473629,
      0.09121935814619064,
      -0.036924395710229874,
      0.0226860661059618,
      -0.021973971277475357,
      -0.06269790232181549,
      0.013020364567637444,
      -0.010532465763390064,
      0.0018880902789533138,
      0.013000729493796825,
      0.017276238650083542,
      -0.0495111458003521,
      -0.07886823266744614,
      0.003348416183143854,
      0.01675501838326454,
      -0.005788828246295452,
      -0.004432325717061758,
      -0.03134949132800102,
      -0.030894208699464798
    ],
    [
      0.012197805568575859,
      -0.0292404443025589,
      0.01836945302784443,
      -0.016975581645965576,
      -0.011506441049277782,
      0.01158395316451788,
      0.09688750654459,
      0.010842254385352135,
      0.055185459554195404,
      -0.0064664739184081554,
      0.07715184986591339,
      0.06273923814296722,
      -0.04731232672929764,
      -0.0034463435877114534,
      -0.023535815998911858,
      0.03853963315486908,
      -0.06541938334703445,
      -0.06420416384935379,
      0.005931070540100336,
      -0.024278542026877403,
      0.03402337431907654,
      -0.0042594731785357,
      0.01831306330859661,
      0.010661359876394272,
      -0.008168051019310951,
      -0.02518666908144951,
      0.005639307666569948,
      0.04720466211438179,
      0.005712891463190317,
      0.13737361133098602,
      -0.004957765806466341,
      0.01716039702296257,
      -0.0033987662754952908,
      -0.0753803476691246,
      -0.034495409578084946,
      -0.030570659786462784,
      0.014826321974396706,
      0.006221378687769175,
      0.04550785571336746,
      0.00889305304735899,
      0.07056106626987457,
      0.012556091882288456,
      -0.013902498409152031,
      0.05272310599684715,
      0.13349546492099762,
      0.027656475082039833,
      0.09522903710603714,
      0.04529152438044548,
      0.0008952209609560668,
      -0.03026873618364334,
      0.0013094897149130702,
      -0.062441349029541016,
      -0.002285449532791972,
      0.01970391720533371,
      0.032060038298368454,
      -0.049311213195323944,
      0.02707364410161972,
      0.007478260435163975,
      -0.14060872793197632,
      -0.009080173447728157,
      0.027900736778974533,
      0.06733798235654831,
      0.04453043267130852,
      0.054054029285907745,
      -0.0034092660062015057,
      0.05293317511677742,
      0.026571987196803093,
      -0.0012271780287846923,
      -0.04358093440532684,
      0.0653451532125473,
      -0.025600166991353035,
      -0.02255082130432129,
      0.033002402633428574,
      -0.06442057341337204,
      0.07741264998912811,
      0.026987267658114433,
      -0.01479372475296259,
      -0.027310695499181747,
      -0.012857184745371342,
      -0.01012797374278307,
      0.07812725007534027,
      0.031148644164204597,
      -0.03227673098444939,
      -0.020030563697218895,
      -0.018500950187444687,
      -0.002323524095118046,
      -0.02184555120766163,
      -0.0715622678399086,
      -0.027545375749468803,
      -0.030741067603230476,
      0.003181551117449999,
      -0.027924852445721626,
      0.008884457871317863,
      -0.06377945095300674,
      -0.0875936821103096,
      0.020499229431152344,
      0.04750216007232666,
      -0.02186732552945614,
      0.02496444061398506,
      0.021909018978476524,
      0.01539017353206873,
      -0.024167118594050407,
      -0.05759198218584061,
      0.018671344965696335,
      -0.040963999927043915,
      0.007929126732051373,
      -0.05252603441476822,
      0.02812143787741661,
      -0.051071178168058395,
      0.023441916331648827,
      0.03522699698805809,
      0.030329832807183266,
      0.025833720341324806,
      -0.02692437916994095,
      -0.07879050076007843,
      -0.07326331734657288,
      0.061623767018318176,
      0.06907850503921509,
      -0.03797329589724541,
      0.009793597273528576,
      0.060802992433309555,
      -0.017944416031241417,
      0.11843793839216232,
      0.019856108352541924,
      -0.03304535150527954,
      -0.015864744782447815,
      0.030780335888266563,
      0.005567285697907209,
      0.020563749596476555,
      0.008175224997103214,
      0.0045820726081728935,
      0.0316586010158062,
      -0.01744021475315094,
      0.012705234810709953,
      0.03866641968488693,
      -0.010972579941153526,
      0.03506510332226753,
      -0.041750162839889526,
      -0.018840888515114784,
      0.005266737658530474,
      -0.03595948591828346,
      -0.00039794525946490467,
      -0.027872378006577492,
      -0.014156322926282883,
      0.038703933358192444,
      0.001083899987861514,
      -0.03371129557490349,
      -0.08855482935905457,
      -0.05803704261779785,
      -0.08669838309288025,
      -0.023577608168125153,
      -0.0043647210113704205,
      0.025368183851242065,
      -0.0193207785487175,
      0.04816127568483353,
      -0.006488308776170015,
      -0.08764111250638962,
      0.014448480680584908,
      0.015725696459412575,
      -0.12199893593788147,
      -0.05608316883444786,
      -0.0008361766231246293,
      0.026331646367907524,
      0.017679397016763687,
      -0.010694069787859917,
      0.026119541376829147,
      -0.028061969205737114,
      -0.07873567938804626,
      -0.024811044335365295,
      -0.017600154504179955,
      -0.03342009335756302,
      -0.026126287877559662,
      -0.12745104730129242,
      -0.09984693676233292,
      0.01211458072066307,
      0.10648781061172485,
      -0.07026221603155136,
      0.06365964561700821,
      -0.03941236063838005,
      -0.024038365110754967,
      -0.08377303183078766,
      -0.020512640476226807,
      -0.03270474448800087,
      0.01958707720041275,
      -0.0208580382168293,
      0.05577079951763153,
      -0.052100952714681625,
      -0.08217601478099823,
      0.01024395227432251,
      0.022599056363105774,
      0.07420908659696579,
      -0.00490556238219142,
      0.02686062827706337,
      0.06234971806406975,
      0.029335591942071915,
      0.03168117254972458,
      -0.05443701893091202,
      0.00997069664299488,
      0.024725301191210747,
      -0.0013102821540087461,
      -0.049750737845897675,
      -0.0030370214954018593,
      0.07104194164276123,
      -0.0074378191493451595,
      0.005825644358992577,
      -0.001255034701898694,
      0.01887914165854454,
      -0.07029155641794205,
      -0.05522220954298973,
      -0.042515628039836884,
      0.038067374378442764,
      0.013686980120837688,
      -0.017462214455008507,
      0.0034840283915400505,
      -0.022877326235175133,
      -0.023399677127599716,
      0.0016794553957879543,
      0.0648670643568039,
      -0.08013623207807541,
      -0.056063927710056305,
      0.017858877778053284,
      0.0027051668148487806,
      0.06055770814418793,
      0.025036253035068512,
      0.07327055186033249,
      0.07007552683353424,
      0.01748097687959671,
      0.040121983736753464,
      -0.0034698836971074343,
      -0.017365146428346634,
      0.046923499554395676,
      -0.07117220759391785,
      -0.03532034903764725,
      -0.01394993532449007,
      -0.014942973852157593,
      0.016989558935165405,
      0.0016901547787711024,
      0.06745573878288269,
      -0.012563024647533894,
      -0.029960395768284798,
      -0.045421287417411804,
      -0.03433234989643097,
      0.060073040425777435,
      -0.013505731709301472,
      -0.048263005912303925,
      -0.030000241473317146,
      0.0785594955086708,
      -0.030290350317955017,
      -0.014777476899325848,
      -0.0008931852644309402,
      0.0692627802491188,
      0.07051291316747665,
      -0.010124857537448406,
      -0.07088067382574081,
      0.11642877012491226,
      -0.0808614119887352,
      -0.019366364926099777,
      0.03783397004008293,
      -0.03449929505586624,
      0.01561379712074995,
      -0.027246860787272453,
      -0.019921308383345604,
      0.0378648079931736,
      -0.059411827474832535,
      0.04337240755558014,
      -0.03519269824028015,
      -0.01341887004673481,
      0.02400391735136509,
      -0.07351520657539368,
      0.049773480743169785,
      -0.001864234684035182,
      -0.01167122833430767,
      -0.09259428083896637,
      0.05975758284330368,
      0.04354500398039818,
      -0.03659553825855255,
      -0.012487581931054592,
      0.006640227977186441,
      0.05036044493317604,
      0.02897150069475174,
      -0.028574272990226746,
      -0.024364646524190903,
      -0.02393230050802231,
      -0.040565405040979385,
      0.02541937306523323,
      -0.011195391416549683,
      0.1273275464773178,
      -0.015935538336634636,
      -0.10904745757579803,
      0.022834857925772667,
      -0.03585465997457504,
      0.009058339521288872,
      -0.045889001339673996,
      -0.06095490977168083,
      0.006424658466130495,
      0.07270373404026031,
      -0.0023461217060685158,
      -0.024479879066348076,
      0.055164534598588943,
      0.039077140390872955,
      -0.038042642176151276,
      0.03618573397397995,
      -0.021526139229536057,
      0.09878135472536087,
      -0.10109928995370865,
      0.0040742321871221066,
      0.0907663106918335,
      -0.00300513650290668,
      0.042054109275341034,
      -0.004217841662466526,
      0.006841817405074835,
      0.023240311071276665,
      -0.013269134797155857,
      -0.003165445290505886,
      0.05193683132529259,
      0.10314732044935226,
      0.0418001189827919,
      0.012138365767896175,
      -0.09761736541986465,
      0.01971328817307949,
      0.0025057438760995865,
      0.038080982863903046,
      -0.026985326781868935,
      -0.02290860377252102,
      -0.02524998039007187,
      0.010842465795576572,
      0.03181440383195877,
      0.06577406823635101,
      0.07226619869470596,
      -0.01048603467643261,
      0.03824669122695923,
      -0.06875703483819962,
      -0.027254775166511536,
      -0.019693907350301743,
      0.01032100711017847,
      -0.007493262644857168,
      0.07061871141195297,
      0.01930072344839573,
      0.11833655834197998,
      -0.09236512333154678,
      0.03787028416991234,
      -0.030691085383296013,
      -0.028371768072247505,
      -0.019301148131489754,
      -0.015739167109131813,
      0.017171921208500862,
      0.10547512024641037,
      0.034075722098350525,
      -0.02386728674173355,
      0.0034387358464300632,
      0.010479271411895752,
      0.021184135228395462,
      0.05873078107833862,
      -0.024492785334587097,
      -0.012935811653733253,
      0.0618043877184391,
      -0.056006334722042084,
      -0.02205331064760685,
      -0.03161757439374924,
      -0.01770869642496109,
      -0.07488300651311874,
      0.03149924799799919,
      0.04318619519472122,
      0.11093689501285553,
      0.04317891225218773,
      -0.08424058556556702,
      0.020270919427275658,
      -0.02125004678964615,
      -0.04456974193453789,
      0.02895187772810459,
      -0.018664412200450897,
      -0.055921200662851334,
      0.01448094006627798,
      0.09393748641014099,
      -0.04744086042046547,
      -0.035260897129774094,
      -0.039811454713344574,
      0.047110073268413544,
      -0.01685187965631485,
      -0.04212566837668419,
      0.03748980164527893,
      0.01100087445229292,
      0.020451197400689125,
      -0.07490882277488708,
      -0.022442061454057693,
      -0.06470079720020294,
      -0.01892838068306446,
      0.011182991787791252,
      0.015263504348695278,
      -0.04324038326740265,
      0.03809235244989395,
      0.014159366488456726,
      0.017712023109197617,
      -0.06874722242355347,
      -0.034916747361421585,
      0.06464803963899612,
      -0.023735076189041138,
      -0.059344276785850525,
      0.08654201775789261,
      0.05397787317633629,
      0.03813529387116432,
      0.00017671246314421296,
      -0.09795159846544266,
      0.03266684338450432,
      -0.043652672320604324,
      -0.026595337316393852,
      -0.012899979948997498,
      -0.10592501610517502,
      -0.015373671427369118,
      -0.06549640744924545,
      -0.09801848977804184,
      -0.02607235126197338,
      0.08162233978509903,
      0.0013406286016106606,
      -0.041980210691690445,
      0.0026260404847562313,
      0.01752050220966339,
      -0.012484110891819,
      -0.0038619250990450382,
      -0.04595539718866348,
      -0.031240755692124367,
      0.060799721628427505,
      -0.04046886786818504,
      -0.004785084631294012,
      0.023168569430708885,
      0.03501254320144653,
      0.03161704167723656,
      -0.018872663378715515,
      -0.02917069010436535,
      -0.014162213541567326,
      0.024313388392329216,
      0.008661511354148388,
      0.0013369998196139932,
      -0.0007255152449943125,
      0.01353990938514471,
      0.028827836737036705,
      -0.023421602323651314,
      0.014255385845899582,
      -0.03990362957119942,
      -0.060850758105516434,
      -0.031999994069337845,
      0.014739669859409332,
      -0.028136977925896645,
      0.0070003941655159,
      0.006412230432033539,
      0.05760260671377182,
      -0.017737977206707,
      0.006102127954363823,
      -0.00018714420730248094,
      0.0018952131504192948,
      0.023589758202433586,
      0.03637409210205078,
      0.0034690990578383207,
      -0.02959483675658703,
      0.011019849218428135,
      -0.0035707817878574133,
      -0.03434930369257927,
      0.05424869805574417,
      0.034932732582092285,
      0.0604720376431942,
      -0.02234814316034317,
      -0.03226751089096069,
      -0.011973944492638111,
      0.06989210098981857,
      -0.08724237978458405,
      0.017408423125743866,
      -0.06305845081806183,
      0.09807612746953964,
      0.009481544606387615,
      0.0026617206167429686,
      0.026333393529057503,
      0.07092034071683884,
      0.03804762661457062,
      0.003680146997794509,
      -0.0741267278790474,
      0.08410104364156723,
      -0.09961611777544022,
      -0.046358611434698105,
      0.0456978902220726,
      -0.031169015914201736,
      0.032196663320064545,
      0.051300689578056335,
      -0.02437051571905613,
      -0.067934051156044,
      -0.05237561836838722,
      0.037205956876277924,
      0.0535055436193943,
      0.03940736502408981,
      0.08353143185377121,
      -0.020516876131296158,
      0.013014169409871101,
      -0.02556922659277916,
      0.007497487589716911,
      0.005817694123834372,
      0.018565330654382706,
      -0.01701977476477623,
      -0.021220853552222252,
      -0.07310527563095093,
      0.034947458654642105,
      -0.022179653868079185,
      -0.03332187607884407,
      -0.077243871986866,
      -0.04888056963682175
    ],
    [
      -0.014627250842750072,
      -0.05671257525682449,
      -0.0018004941521212459,
      0.016784438863396645,
      0.003126354655250907,
      -0.031085973605513573,
      0.07361406832933426,
      -0.004019467625766993,
      -0.0723966732621193,
      0.022632481530308723,
      0.05041337013244629,
      0.05092312768101692,
      -0.04905284568667412,
      0.0063491156324744225,
      -0.01699790731072426,
      0.0018968733493238688,
      -0.017925025895237923,
      -0.06567227095365524,
      0.04458560794591904,
      -0.010983873158693314,
      -0.007707561366260052,
      0.05513831228017807,
      0.03320387750864029,
      0.02899453416466713,
      -0.055449821054935455,
      -0.06136106699705124,
      0.013664351776242256,
      -0.06869646161794662,
      -0.014234703034162521,
      -0.03421568125486374,
      0.015265865251421928,
      -0.02110956609249115,
      -0.022893713787198067,
      -0.04787084460258484,
      -0.0008782644872553647,
      -0.0346975214779377,
      0.029553499072790146,
      0.12379754334688187,
      0.01252798642963171,
      -0.00845002755522728,
      -0.021140506491065025,
      -0.013143706135451794,
      -0.03835202753543854,
      0.07983038574457169,
      0.11356890201568604,
      -0.03855578973889351,
      0.04884233698248863,
      0.007779723033308983,
      0.017591947689652443,
      0.02824793942272663,
      0.024752287194132805,
      0.02808261103928089,
      -0.05375683680176735,
      -0.051637470722198486,
      -0.007919286377727985,
      -0.04260416701436043,
      -0.03140577673912048,
      0.004269686061888933,
      -0.000741088530048728,
      -0.0047654882073402405,
      -0.01867225021123886,
      -0.030810870230197906,
      0.0594443716108799,
      0.030845945701003075,
      0.0061815157532691956,
      -0.0579339973628521,
      -0.015159225091338158,
      0.04289931803941727,
      -0.04187840595841408,
      -0.02553914487361908,
      0.0008482817211188376,
      -0.0015698017086833715,
      -0.013628017157316208,
      -0.0507940948009491,
      0.09390183538198471,
      -0.05021697282791138,
      -0.07771702110767365,
      -0.06563324481248856,
      0.035293638706207275,
      0.01504724845290184,
      0.08032038062810898,
      -0.06845876574516296,
      -0.04440125450491905,
      0.03618927672505379,
      -0.038018763065338135,
      0.029810676351189613,
      0.018822941929101944,
      0.0824061930179596,
      0.011148437857627869,
      0.023721866309642792,
      -0.017391297966241837,
      -0.006092599127441645,
      -0.03317848592996597,
      0.05932033434510231,
      -0.12812186777591705,
      -0.021612556651234627,
      0.0686274915933609,
      -0.010772394947707653,
      -0.04790564626455307,
      0.023062027990818024,
      0.027110179886221886,
      0.011975766159594059,
      -0.033364422619342804,
      0.10039152204990387,
      -0.02413778007030487,
      0.11576317995786667,
      -0.062201566994190216,
      -0.001727526425383985,
      -0.007336956914514303,
      0.03619924932718277,
      0.015428676269948483,
      -0.005708117503672838,
      0.08402564376592636,
      0.058759864419698715,
      4.1082366806222126e-05,
      -0.010471493005752563,
      0.017280807718634605,
      0.046621762216091156,
      0.014137673191726208,
      -0.022618921473622322,
      -0.012104700319468975,
      0.06127398833632469,
      -0.011869746260344982,
      -0.019585363566875458,
      0.01042982004582882,
      0.015769112855196,
      0.01085576880723238,
      0.06462065875530243,
      -0.05351128429174423,
      0.0653316006064415,
      -0.0014053836930543184,
      0.0775902047753334,
      -0.004851754754781723,
      0.018337642773985863,
      -0.012625091709196568,
      0.021226925775408745,
      0.00428491597995162,
      -0.026344850659370422,
      -0.042316995561122894,
      0.04535688832402229,
      0.010530608706176281,
      0.0715407207608223,
      0.04906538128852844,
      0.033079635351896286,
      0.0664219930768013,
      0.07897337526082993,
      -0.046515315771102905,
      0.020779216662049294,
      0.05965060368180275,
      0.05723579227924347,
      0.00889978464692831,
      0.0868009701371193,
      -0.0188057329505682,
      -0.013768334873020649,
      -0.013572064228355885,
      -0.07083183526992798,
      0.004869828931987286,
      -0.06983960419893265,
      0.0009613358997739851,
      -0.04148641601204872,
      -0.010949255898594856,
      0.00011339403863530606,
      0.012749884277582169,
      0.04541076719760895,
      0.01538793370127678,
      -0.03045906499028206,
      -0.05714312568306923,
      -0.06806158274412155,
      -0.07095320522785187,
      0.08007382601499557,
      0.07316074520349503,
      -0.001723867142572999,
      0.016713188961148262,
      -0.0068149259313941,
      0.021502785384655,
      -0.03772193565964699,
      -0.10048943012952805,
      0.0046880003064870834,
      -0.047189921140670776,
      -0.018810098990797997,
      0.03705175593495369,
      -0.02053099311888218,
      -0.031045785173773766,
      -0.016046909615397453,
      -0.0378231406211853,
      0.026688801124691963,
      0.09447121620178223,
      -0.05397063493728638,
      -0.03590450808405876,
      0.00407209899276495,
      -0.071741484105587,
      -0.030297167599201202,
      -0.006954893935471773,
      0.030877025797963142,
      -0.04698309302330017,
      -0.060066334903240204,
      0.0659201368689537,
      0.06391347944736481,
      0.02246382273733616,
      0.08710391819477081,
      0.005582259967923164,
      0.09486913681030273,
      -0.000711564440280199,
      -0.050930947065353394,
      -0.013731202110648155,
      -0.005078732036054134,
      0.12088087201118469,
      0.022771436721086502,
      0.02498782053589821,
      -0.03494124487042427,
      -0.011106531135737896,
      0.0271547082811594,
      0.020723791792988777,
      -0.04654702916741371,
      0.02475755661725998,
      0.013137193396687508,
      -0.004753224551677704,
      0.024337098002433777,
      0.012464446946978569,
      0.049758169800043106,
      0.000943011837080121,
      0.00022454827558249235,
      0.08342058956623077,
      -0.014466221444308758,
      0.04658394679427147,
      0.04798949882388115,
      -0.04075797274708748,
      -0.029611116275191307,
      -0.024192053824663162,
      0.039756204932928085,
      -0.0004923870437778533,
      0.027583302929997444,
      0.019484275951981544,
      -0.033085744827985764,
      -0.01258661039173603,
      -0.034192055463790894,
      0.014972031116485596,
      -0.07156945765018463,
      0.08502435684204102,
      -0.01770951971411705,
      -0.035747919231653214,
      -0.07329311221837997,
      0.055104371160268784,
      -0.02460349351167679,
      -0.04162321239709854,
      -0.02624833583831787,
      -0.025862785056233406,
      -0.030412742868065834,
      0.03851001337170601,
      -0.06404237449169159,
      -0.052589770406484604,
      0.03554610162973404,
      -0.03515961021184921,
      -0.03212692588567734,
      -0.052089858800172806,
      -0.027828790247440338,
      0.025803569704294205,
      0.02032587304711342,
      0.08377527445554733,
      0.03129720315337181,
      0.07723606377840042,
      -0.05843291059136391,
      -0.055507563054561615,
      0.030448857694864273,
      0.020258545875549316,
      -0.006854366976767778,
      -0.07135947793722153,
      0.026967152953147888,
      0.061931394040584564,
      0.049258626997470856,
      0.0019456467125564814,
      0.06786886602640152,
      0.026801224797964096,
      -0.06346628814935684,
      -0.03861554339528084,
      -0.0030938303098082542,
      -0.011996340937912464,
      -0.04004497826099396,
      0.010756686329841614,
      -0.02140899933874607,
      -0.047669846564531326,
      0.011509145610034466,
      -0.018887069076299667,
      -0.008157475851476192,
      -0.045174721628427505,
      0.06266136467456818,
      -0.012737439014017582,
      0.021258071064949036,
      0.015945332124829292,
      0.027657991275191307,
      0.002385704778134823,
      -0.05505871772766113,
      -0.09348142892122269,
      0.048481203615665436,
      0.0085449805483222,
      0.05684047192335129,
      0.03847942128777504,
      -0.043760038912296295,
      0.027523310855031013,
      -0.012450957670807838,
      -0.0025963634252548218,
      -0.08016158640384674,
      0.004110966343432665,
      0.006497862283140421,
      -0.018079202622175217,
      -0.016179459169507027,
      0.0032713052351027727,
      -0.0416378378868103,
      -0.013262809254229069,
      0.05360168591141701,
      -0.009129093028604984,
      -0.10178695619106293,
      -0.003958027344197035,
      -0.03878270089626312,
      0.0008022225229069591,
      -0.05206785351037979,
      0.019612500444054604,
      -0.00021577521692961454,
      0.06293274462223053,
      0.035830311477184296,
      -0.037443019449710846,
      0.03690826892852783,
      -0.017367420718073845,
      0.0787564143538475,
      -0.05054234340786934,
      0.013209600001573563,
      0.0882650837302208,
      -0.06012069061398506,
      0.017365574836730957,
      -0.09340479969978333,
      -0.05980295315384865,
      0.04887840524315834,
      0.013225343078374863,
      -0.002804121933877468,
      0.11862555891275406,
      0.03678914159536362,
      -0.015678035095334053,
      -0.02128862775862217,
      0.052518557757139206,
      0.04209039360284805,
      -0.03649933263659477,
      0.015433240681886673,
      0.012266677804291248,
      0.005411703139543533,
      0.059407155960798264,
      -0.013998246751725674,
      0.024642229080200195,
      0.0334361232817173,
      -0.059170592576265335,
      0.005597353912889957,
      0.06693501025438309,
      -0.02920432575047016,
      0.08059779554605484,
      -0.02911856770515442,
      0.023445529863238335,
      0.0008385860128328204,
      -0.014003138057887554,
      0.0855405330657959,
      -0.07396187633275986,
      0.048250339925289154,
      0.00635504350066185,
      -0.029319828376173973,
      0.00973829347640276,
      0.01441812701523304,
      -0.004371865186840296,
      0.05728264898061752,
      0.04083522409200668,
      0.023298410698771477,
      0.008191129192709923,
      0.026455635204911232,
      -0.031862106174230576,
      0.05867902934551239,
      -0.012371256947517395,
      0.022993825376033783,
      -0.08678635954856873,
      -0.004860633052885532,
      -0.008503624238073826,
      -0.014757187105715275,
      0.06943950802087784,
      -0.011425158008933067,
      -0.018946073949337006,
      -0.10888411849737167,
      0.02630186825990677,
      -0.0709567740559578,
      -0.00698544317856431,
      0.04419059678912163,
      0.07899586111307144,
      0.00217159534804523,
      0.022020192816853523,
      -0.04252893105149269,
      0.007209763862192631,
      -0.002457756781950593,
      -0.0014473740011453629,
      0.03644143044948578,
      -0.01678796112537384,
      0.030344286933541298,
      -0.05537167936563492,
      0.010742885060608387,
      -0.022279705852270126,
      -0.03182473033666611,
      0.045407604426145554,
      0.03305057808756828,
      0.06111059710383415,
      0.05439721792936325,
      -0.0820641815662384,
      -0.03692770376801491,
      0.00048599872388876975,
      -0.0529269203543663,
      0.12227141857147217,
      0.06676805019378662,
      -0.17112161219120026,
      0.013859362341463566,
      0.03982861712574959,
      -0.009157332591712475,
      0.014248338527977467,
      0.024406464770436287,
      -0.011546588502824306,
      0.03225293010473251,
      0.02731841243803501,
      -0.0036928087938576937,
      -0.06376808136701584,
      -0.06286301463842392,
      -0.011928973719477654,
      8.934911602409557e-05,
      0.05382256954908371,
      0.028239738196134567,
      -0.008643840439617634,
      -0.048242442309856415,
      -0.039488423615694046,
      -0.014236637391149998,
      0.03907490894198418,
      0.008710645139217377,
      -0.00014999325503595173,
      0.041711971163749695,
      -0.003182865446433425,
      -0.048904865980148315,
      -0.08015470951795578,
      0.04549840837717056,
      0.07631493359804153,
      -0.039186570793390274,
      0.04104325547814369,
      -0.017396429553627968,
      -0.004293152131140232,
      -0.05960306152701378,
      0.05974797531962395,
      0.04207555204629898,
      -0.06352747976779938,
      0.04875362664461136,
      -0.03647824749350548,
      -0.0852503851056099,
      0.03400461748242378,
      0.04226476326584816,
      0.043806884437799454,
      0.027634765952825546,
      -0.03608110919594765,
      0.0006499108858406544,
      -0.05057119205594063,
      0.03678916394710541,
      0.014809772372245789,
      0.055188585072755814,
      -0.02090010978281498,
      -0.048490073531866074,
      0.0333421528339386,
      0.0481090247631073,
      0.002631605137139559,
      -0.02804681658744812,
      -0.06632045656442642,
      -0.02175973169505596,
      0.04526557773351669,
      0.03769145533442497,
      0.0075410823337733746,
      -0.031200937926769257,
      0.05245935916900635,
      -0.05630640685558319,
      -0.02003399096429348,
      -0.046641282737255096,
      -0.027935698628425598,
      0.04526504501700401,
      0.13696016371250153,
      -0.01642073690891266,
      0.041235100477933884,
      0.0404355451464653,
      -0.02225959300994873,
      -0.034038785845041275,
      0.030237853527069092,
      -0.0015821015695109963,
      -0.03362427279353142,
      0.011456829495728016,
      0.024499041959643364,
      0.03678636997938156,
      -0.045480672270059586,
      0.03937999904155731,
      -0.009816319681704044,
      -0.02639704942703247,
      0.019580848515033722,
      -0.016098858788609505,
      0.026907913386821747,
      -0.01348283514380455,
      -0.08919349312782288,
      -0.018307309597730637,
      0.0557120144367218,
      0.01744162105023861,
      -0.06382695585489273
    ],
    [
      -0.05121267959475517,
      -0.0598609633743763,
      0.03009791485965252,
      -0.002768916543573141,
      -0.030249426141381264,
      -0.010447202250361443,
      -0.023235971108078957,
      0.009524826891720295,
      0.02522730454802513,
      0.01462183240801096,
      0.0037675080820918083,
      0.006579739972949028,
      -0.01234506070613861,
      -0.061530210077762604,
      -0.07209974527359009,
      0.026055578142404556,
      -0.028440898284316063,
      -0.07440979033708572,
      -0.052603013813495636,
      0.05789469555020332,
      -0.04270847886800766,
      -0.016709238290786743,
      0.02721143141388893,
      -0.006132768001407385,
      -0.01953491009771824,
      -0.05055668205022812,
      -0.050342582166194916,
      0.04070330783724785,
      -0.0014550324995070696,
      0.03343527019023895,
      -0.015778258442878723,
      -0.02012546919286251,
      0.06262730062007904,
      -0.017556581646203995,
      0.007805713918060064,
      0.012938314117491245,
      0.043895021080970764,
      0.02356484904885292,
      -0.0004506862023845315,
      -0.005300753749907017,
      0.050996698439121246,
      0.023405728861689568,
      0.0753534585237503,
      -0.046997953206300735,
      0.052943967282772064,
      -0.09284020960330963,
      0.05008739233016968,
      0.0063060312531888485,
      0.0013075938913971186,
      0.024985421448946,
      -0.11171144992113113,
      -0.08254839479923248,
      -0.01780160330235958,
      0.02652091719210148,
      -0.005503240041434765,
      0.002775496570393443,
      -0.014800136908888817,
      0.0420667864382267,
      0.017998818308115005,
      0.038964033126831055,
      0.0529785230755806,
      0.0034020303282886744,
      0.04824673756957054,
      0.08850157260894775,
      0.025227908045053482,
      -0.0024921318981796503,
      -0.07729066163301468,
      0.051044490188360214,
      -0.006998766679316759,
      -0.07538671791553497,
      -0.04964222013950348,
      -0.08827923983335495,
      0.0003561830089893192,
      -0.017745789140462875,
      -0.029443519189953804,
      0.06858345866203308,
      0.011880995705723763,
      -0.05749746412038803,
      -0.0258354339748621,
      0.05831307917833328,
      0.01389487273991108,
      0.05097262188792229,
      -0.018553800880908966,
      0.022328125312924385,
      0.06263839453458786,
      0.00843646191060543,
      0.002094118157401681,
      -0.016969870775938034,
      0.1194644570350647,
      0.0677674189209938,
      0.026829972863197327,
      -0.020017188042402267,
      -0.049458276480436325,
      0.006899628788232803,
      -0.0358317531645298,
      0.0058330511674284935,
      -0.018613314256072044,
      0.05452045798301697,
      -0.03399721160531044,
      -0.02573101595044136,
      0.04475114867091179,
      0.0326840914785862,
      0.06478402018547058,
      -0.014158107340335846,
      -0.030368123203516006,
      -0.020677920430898666,
      -0.07892398536205292,
      0.011809492483735085,
      -0.05448280647397041,
      -0.01288742944598198,
      -0.028419777750968933,
      -0.020793242380023003,
      0.05825933441519737,
      -0.06001824140548706,
      -0.0013481976930052042,
      0.07071150094270706,
      0.011818207800388336,
      -0.008455720730125904,
      0.07559911906719208,
      0.010383962653577328,
      0.15146230161190033,
      -0.11368267983198166,
      -0.04487604275345802,
      0.004229592625051737,
      -0.05787155032157898,
      -0.0679778903722763,
      0.0926145538687706,
      0.06984268873929977,
      -0.11241680383682251,
      -0.05292097479104996,
      0.06919936090707779,
      -0.03235914185643196,
      0.02704567275941372,
      0.026465296745300293,
      -0.01637081988155842,
      -0.08181238919496536,
      -0.017568474635481834,
      -0.020237525925040245,
      0.12542937695980072,
      -0.010598029009997845,
      0.032729074358940125,
      0.05587872490286827,
      0.043635059148073196,
      0.022785160690546036,
      0.08574599027633667,
      0.046688225120306015,
      0.011212164536118507,
      0.00980721227824688,
      -0.00865508895367384,
      0.07129058986902237,
      -0.037697430700063705,
      0.03361218795180321,
      -0.05116613954305649,
      0.015121477656066418,
      0.02477308176457882,
      0.05744944140315056,
      0.01895441859960556,
      -0.07400505244731903,
      -0.021675197407603264,
      -0.05124378576874733,
      0.036170799285173416,
      0.04509248211979866,
      -0.022045480087399483,
      -0.0016469414113089442,
      0.048660483211278915,
      -0.01088752318173647,
      -0.02496836706995964,
      -0.053412556648254395,
      -0.014234487898647785,
      0.0336119681596756,
      -0.05190013721585274,
      0.014125283807516098,
      0.01271163672208786,
      0.052319228649139404,
      0.05234527960419655,
      -0.07825669646263123,
      0.0033138853032141924,
      0.04245222359895706,
      0.0740056186914444,
      -0.05681758373975754,
      0.06512436270713806,
      0.054705195128917694,
      -0.050113048404455185,
      0.036502595990896225,
      -0.03088531456887722,
      0.0031623723916709423,
      -0.019847173243761063,
      -0.020663073286414146,
      -0.04232393950223923,
      -0.039661578834056854,
      -0.06465524435043335,
      0.0033505884930491447,
      0.07308997958898544,
      0.032497815787792206,
      -0.07707088440656662,
      -0.06679166108369827,
      0.01846650429069996,
      -0.07147206366062164,
      -0.036103297024965286,
      -0.012908851727843285,
      0.0028159955982118845,
      0.004453324247151613,
      -0.0034505152143538,
      0.04703816398978233,
      -0.06631384789943695,
      -0.04450390860438347,
      0.012619851157069206,
      0.019922927021980286,
      0.028810791671276093,
      -0.04165629670023918,
      -0.08210057020187378,
      -0.0014001078670844436,
      0.09579750150442123,
      0.05546064302325249,
      -0.04535418003797531,
      0.04074278473854065,
      -0.1109032928943634,
      -0.01298833079636097,
      0.01883886009454727,
      0.008452762849628925,
      -0.058534618467092514,
      0.0003491008828859776,
      0.10508511960506439,
      0.10895600914955139,
      -0.020685382187366486,
      -0.05203504115343094,
      -0.01059093326330185,
      0.007422603201121092,
      0.022609509527683258,
      -0.013201220892369747,
      0.049423571676015854,
      -0.05174936354160309,
      -0.027302643284201622,
      0.021132510155439377,
      0.07725930958986282,
      0.013880382291972637,
      0.007701170165091753,
      0.05582922697067261,
      -0.03590981289744377,
      0.07033707201480865,
      -0.015098119154572487,
      0.03664183244109154,
      -0.06578761339187622,
      0.02186104655265808,
      -0.0009824056178331375,
      0.027066059410572052,
      -0.08911947906017303,
      -0.04627910628914833,
      0.005349167622625828,
      -0.04006406292319298,
      -0.11838804930448532,
      -0.047644104808568954,
      -0.054387591779232025,
      0.015481899492442608,
      -0.0025871414691209793,
      -0.053686439990997314,
      -0.01709168404340744,
      0.029240595176815987,
      0.06339782476425171,
      -0.05582117289304733,
      0.005262733902782202,
      -0.017821364104747772,
      0.0008632022072561085,
      -0.005437416490167379,
      -0.009703821502625942,
      -0.020921021699905396,
      0.008430298417806625,
      -0.01689876616001129,
      -0.06214453652501106,
      0.09131086617708206,
      0.0029030877631157637,
      0.047192588448524475,
      0.00756050692871213,
      0.1005205363035202,
      -0.09990101307630539,
      -0.002215693471953273,
      -0.013226478360593319,
      0.131435364484787,
      0.033695515245199203,
      -0.04368976503610611,
      0.023459991440176964,
      0.05693279206752777,
      0.08342145383358002,
      -0.038935303688049316,
      -0.028249286115169525,
      -0.12194250524044037,
      -0.005856707692146301,
      -0.015895552933216095,
      -0.05365057289600372,
      0.05993504077196121,
      0.013428575359284878,
      -0.0356806144118309,
      -0.06456811726093292,
      0.048154059797525406,
      0.010716123506426811,
      0.054593443870544434,
      0.013822321780025959,
      -0.03617067635059357,
      0.014466535300016403,
      0.09492452442646027,
      -0.04149600490927696,
      -0.12547965347766876,
      0.017247550189495087,
      -0.002216104418039322,
      -0.02033984288573265,
      0.047853145748376846,
      -0.11170011758804321,
      -0.08525721728801727,
      0.016385335475206375,
      0.07410585135221481,
      0.03315894678235054,
      0.039606884121894836,
      -0.00016138168575707823,
      -0.0004923974047414958,
      -0.009014012292027473,
      0.06815280020236969,
      0.029325153678655624,
      -0.032968562096357346,
      0.033697616308927536,
      -0.02414262667298317,
      -0.029353296384215355,
      -0.0037843871396034956,
      0.003770030103623867,
      0.06339936703443527,
      -0.015994489192962646,
      0.05199667066335678,
      0.08411460369825363,
      -0.04231160506606102,
      0.092628113925457,
      0.0186767615377903,
      0.01481352373957634,
      0.03564425930380821,
      -0.02910279482603073,
      -0.05521027743816376,
      -0.041183922439813614,
      0.028924748301506042,
      -0.018713349476456642,
      0.05814342945814133,
      0.04397904872894287,
      -0.004757770337164402,
      0.024480249732732773,
      0.013404237106442451,
      -0.004901370499283075,
      -0.05343194678425789,
      -0.01591239683330059,
      -0.022903453558683395,
      -0.10146656632423401,
      0.05648688226938248,
      -0.03296155110001564,
      0.08918938785791397,
      0.029619047418236732,
      -0.047731708735227585,
      -0.0309430044144392,
      -0.06834916025400162,
      -0.04474981129169464,
      0.0427229106426239,
      -0.03532659262418747,
      -0.025805708020925522,
      -0.02585793100297451,
      -0.04542262852191925,
      -0.0037278905510902405,
      -0.06419692933559418,
      0.032982006669044495,
      -0.04023844748735428,
      -0.0407407209277153,
      0.05203467607498169,
      -0.017504729330539703,
      0.05898021534085274,
      0.027749238535761833,
      0.0027387142181396484,
      0.026626115664839745,
      0.06115392968058586,
      0.024075372144579887,
      -0.011552518233656883,
      -0.08836079388856888,
      0.070976123213768,
      -0.04717087373137474,
      -0.03025154396891594,
      0.09870760142803192,
      -0.0569954439997673,
      -0.08069567382335663,
      0.016090024262666702,
      0.007250073831528425,
      -0.012498462572693825,
      0.019621312618255615,
      -0.02744433656334877,
      0.04844876378774643,
      -0.0018410978373140097,
      -0.026557130739092827,
      -0.03211991861462593,
      0.0708199292421341,
      -0.008895007893443108,
      -0.010722271166741848,
      -0.05262090265750885,
      -0.08384384959936142,
      0.057321999222040176,
      0.01614752784371376,
      -0.05091765150427818,
      -0.03185276687145233,
      -0.0638536661863327,
      0.038804393261671066,
      -0.01616228185594082,
      0.020105278119444847,
      -0.02022572234272957,
      -0.07502356171607971,
      0.02562069334089756,
      -0.01758381351828575,
      0.06398014724254608,
      -0.020123835653066635,
      0.032544832676649094,
      0.006207695230841637,
      0.09360792487859726,
      -0.0355326347053051,
      0.061653006821870804,
      -0.0009113198611885309,
      0.06500199437141418,
      0.02295500971376896,
      -0.030015336349606514,
      0.006490328814834356,
      0.03652526065707207,
      0.06387922167778015,
      -0.06926041841506958,
      0.02405967190861702,
      -0.040493156760931015,
      0.002423909492790699,
      -0.03702812269330025,
      -0.0030855578370392323,
      0.02193031832575798,
      0.03229079768061638,
      0.03273383527994156,
      0.0038755859714001417,
      0.06065691262483597,
      -0.057328756898641586,
      0.052978064864873886,
      -0.0413857139647007,
      -0.05800006166100502,
      -0.01720363274216652,
      -0.003985884599387646,
      0.03967830538749695,
      -0.06006984785199165,
      -0.06689561903476715,
      -0.03933852165937424,
      -0.09194949269294739,
      0.042586881667375565,
      -0.027178533375263214,
      -0.06200413033366203,
      -0.06903133541345596,
      0.02021549642086029,
      -0.019174782559275627,
      -0.037992652505636215,
      -0.021504878997802734,
      -0.05058823153376579,
      0.03267340362071991,
      -0.024434369057416916,
      0.04694431275129318,
      0.011566962115466595,
      -0.01781679503619671,
      -0.02298407256603241,
      -0.03760460391640663,
      -0.043847788125276566,
      -0.04714273288846016,
      0.023038413375616074,
      0.015647102147340775,
      0.0029058840591460466,
      -0.009571815840899944,
      0.03386502340435982,
      -0.03894497826695442,
      0.013679801486432552,
      -0.018145892769098282,
      -0.04742138460278511,
      0.012715335935354233,
      0.04619007557630539,
      0.07951950281858444,
      -0.04108628258109093,
      0.00013245147420093417,
      0.033453527837991714,
      0.005571713205426931,
      -0.0008897325024008751,
      0.044122401624917984,
      0.0005762763903476298,
      -0.01378288771957159,
      0.047062382102012634,
      0.02633662335574627,
      -0.09232190996408463,
      -0.016526183113455772,
      0.06745754927396774,
      -0.030253292992711067,
      0.024129291996359825,
      0.007019085809588432,
      0.053495585918426514,
      0.03758522495627403,
      -0.0014355769380927086,
      -0.034211285412311554,
      -0.046432528644800186,
      -0.045362167060375214,
      -0.05503792315721512,
      0.08955968916416168,
      0.06413236260414124,
      -0.05399499088525772,
      -0.000821029010694474,
      0.044211678206920624,
      -0.010195765644311905,
      -0.014375772327184677
    ],
    [
      -0.05986553430557251,
      -0.018923936411738396,
      0.012676562182605267,
      -0.027853962033987045,
      0.05146769434213638,
      -0.019852016121149063,
      0.027258235961198807,
      -0.03972227871417999,
      0.03344942256808281,
      -0.0004678382829297334,
      0.08493028581142426,
      0.07234513014554977,
      -0.006878749001771212,
      0.06863142549991608,
      0.030535168945789337,
      -0.01717313565313816,
      -0.08236481994390488,
      -0.01713700406253338,
      -0.08364584296941757,
      0.020958902314305305,
      -0.04604658856987953,
      -0.011694790795445442,
      -0.0009682535310275853,
      0.06593208014965057,
      -0.05699879303574562,
      -0.037541504949331284,
      0.0018777373479679227,
      0.07623331993818283,
      0.0991496592760086,
      0.014899862930178642,
      -0.06131585314869881,
      0.0006704433471895754,
      -0.047665152698755264,
      -0.05125725269317627,
      0.028643399477005005,
      -0.06530418992042542,
      0.06628136336803436,
      0.009044121019542217,
      -0.023833341896533966,
      -0.024760708212852478,
      0.07092277705669403,
      -0.08579830080270767,
      -0.019664369523525238,
      -0.04431305080652237,
      0.021465081721544266,
      -0.02296411246061325,
      -0.022732146084308624,
      -0.05097198113799095,
      -0.0268400851637125,
      -0.020603012293577194,
      0.09631017595529556,
      -0.07420647889375687,
      -0.10801015049219131,
      0.0025528925471007824,
      -0.05146705359220505,
      -0.009654895402491093,
      0.004561716224998236,
      0.06719515472650528,
      0.0228850357234478,
      -0.0042772372253239155,
      0.05880802869796753,
      0.0054949200712144375,
      -0.027492646127939224,
      0.06100279837846756,
      -0.08640361577272415,
      0.030133768916130066,
      -0.058977045118808746,
      -0.028153879567980766,
      0.00613844720646739,
      -0.05821843072772026,
      -0.09065541625022888,
      -0.01330078300088644,
      0.021778780966997147,
      -0.026362819597125053,
      -0.036765456199645996,
      0.021291887387633324,
      0.023943878710269928,
      -0.03282158821821213,
      -0.018729833886027336,
      0.002336037578061223,
      0.008355225436389446,
      0.0077589270658791065,
      0.0680788978934288,
      -0.03185311332345009,
      0.02993457391858101,
      -0.016947058960795403,
      0.0045097763650119305,
      -0.030051153153181076,
      -0.009575125761330128,
      -0.03530745208263397,
      0.059332385659217834,
      -0.07341168820858002,
      -0.035748109221458435,
      -0.008848809637129307,
      -0.02501596324145794,
      -0.023928984999656677,
      0.08012596517801285,
      0.02918047085404396,
      0.012514671310782433,
      -0.0059730252251029015,
      0.042320627719163895,
      -0.034099653363227844,
      -0.008888659067451954,
      0.07061660289764404,
      0.016143551096320152,
      0.05450357124209404,
      0.03396888077259064,
      -0.023007601499557495,
      -0.008295241743326187,
      0.015697676688432693,
      -0.068430595099926,
      0.024238944053649902,
      -0.005374606233090162,
      0.02693948522210121,
      0.02200951799750328,
      0.03512772172689438,
      -0.0774371474981308,
      -0.00971774198114872,
      0.0043836552649736404,
      -0.00661485968157649,
      -0.010342646390199661,
      -0.022947190329432487,
      0.03183484449982643,
      0.047236327081918716,
      0.02277294546365738,
      0.002540611894801259,
      0.07348611205816269,
      -0.04035193845629692,
      0.010182784870266914,
      0.04009466990828514,
      0.07275939732789993,
      0.07291237264871597,
      0.0032491006422787905,
      -0.03344215825200081,
      -0.030086399987339973,
      0.06801515072584152,
      -0.010216809809207916,
      -0.015795236453413963,
      -0.012834852561354637,
      -0.02075524814426899,
      0.016804883256554604,
      -0.026542142033576965,
      0.05490128695964813,
      -0.03269869089126587,
      0.06536801159381866,
      -0.019955316558480263,
      0.008970818482339382,
      -0.00462638633325696,
      -0.040714964270591736,
      -0.05039318650960922,
      -0.023895278573036194,
      -0.02150515653192997,
      -0.037056855857372284,
      -0.043268296867609024,
      -0.04282062128186226,
      -0.1353924423456192,
      0.03737636283040047,
      -0.06480014324188232,
      -0.025963986292481422,
      0.023930083960294724,
      -0.009974580258131027,
      0.03566644340753555,
      -0.009470665827393532,
      -0.013989939354360104,
      -0.06837118417024612,
      0.0027395039796829224,
      0.04532872512936592,
      0.035150494426488876,
      -0.09428928792476654,
      0.020260337740182877,
      0.02908111736178398,
      0.007387601304799318,
      -0.03668559715151787,
      -0.044632818549871445,
      0.0321989431977272,
      0.03395191207528114,
      -0.02332448959350586,
      -0.006142353639006615,
      0.02222583442926407,
      0.016400428488850594,
      -0.04308631643652916,
      0.028560731559991837,
      -0.04740810766816139,
      -0.017266612499952316,
      0.011012567207217216,
      -0.052389174699783325,
      0.02328852191567421,
      -0.052311211824417114,
      -0.026651935651898384,
      0.018535176292061806,
      0.00015778964734636247,
      0.004242739174515009,
      0.021587740629911423,
      -0.03012298420071602,
      -0.029912181198596954,
      0.050367921590805054,
      0.05073580518364906,
      0.026475340127944946,
      -0.014187382534146309,
      -0.04671483114361763,
      -0.000185057710041292,
      -0.0267442986369133,
      -0.11732764542102814,
      0.03234069421887398,
      0.059621408581733704,
      0.039912961423397064,
      0.018835516646504402,
      -0.007369800470769405,
      0.05638463422656059,
      -0.08564726263284683,
      0.014553229324519634,
      -0.14038288593292236,
      0.03854818642139435,
      -0.050718750804662704,
      0.09904751181602478,
      -0.011501175351440907,
      0.008484763093292713,
      0.029480597004294395,
      -0.013799130916595459,
      0.021959425881505013,
      -0.07963442802429199,
      0.04893916845321655,
      0.013714339584112167,
      0.07766112685203552,
      -0.025716785341501236,
      0.03961743414402008,
      -0.053462572395801544,
      -0.03945758938789368,
      -0.021331246942281723,
      -0.025293556973338127,
      0.07631611824035645,
      -0.003562385216355324,
      0.08162137866020203,
      0.02342669852077961,
      0.0826926976442337,
      0.05798138305544853,
      0.02545035630464554,
      0.03870190307497978,
      0.02933015674352646,
      -0.029552960768342018,
      0.02739630453288555,
      -0.009542535990476608,
      -0.03545239195227623,
      -0.0676480233669281,
      0.041525911539793015,
      0.04881444573402405,
      -0.060002055019140244,
      0.029107527807354927,
      -0.01703893579542637,
      -0.11275240033864975,
      0.0573338083922863,
      -0.0008104738080874085,
      -0.0004934761673212051,
      -0.031520478427410126,
      0.02930082194507122,
      -0.009370813146233559,
      -0.016552802175283432,
      -0.026342421770095825,
      0.003363861469551921,
      -0.035795096307992935,
      -0.05520891398191452,
      0.0031639747321605682,
      0.03506370633840561,
      0.025510935112833977,
      0.015149074606597424,
      -0.04323435202240944,
      -0.0263726394623518,
      0.02211911417543888,
      0.005361879710108042,
      -0.025934146717190742,
      -0.0441216416656971,
      0.019691143184900284,
      -0.004265428055077791,
      0.005892941262573004,
      -0.05450611189007759,
      0.05702153220772743,
      -0.03031131997704506,
      0.0272783525288105,
      0.024513082578778267,
      0.002513793995603919,
      -0.03766155242919922,
      0.11286315321922302,
      -0.013576744124293327,
      0.0015061547746881843,
      -0.027966389432549477,
      -0.01507712621241808,
      0.059462208300828934,
      0.04343996196985245,
      0.019697032868862152,
      0.050344135612249374,
      0.04566429555416107,
      -0.0003029674990102649,
      0.05787801742553711,
      -0.012738244608044624,
      0.05210193246603012,
      0.0014847101410850883,
      -0.066108837723732,
      0.00872529111802578,
      0.0031455138232558966,
      -0.004987979307770729,
      -0.06381501257419586,
      -0.03889358416199684,
      -0.011167647317051888,
      -0.0005244148196652532,
      -0.04818077012896538,
      0.026467325165867805,
      0.04137818142771721,
      -0.02490575984120369,
      0.08574122935533524,
      0.041588857769966125,
      0.028468426316976547,
      0.004500914830714464,
      0.05318106338381767,
      0.032523978501558304,
      0.008573271334171295,
      -0.005923584569245577,
      0.03958132490515709,
      0.026487240567803383,
      -0.07819906622171402,
      0.03057814948260784,
      0.024862641468644142,
      -0.025431223213672638,
      -0.0241800919175148,
      0.011489647440612316,
      0.021155711263418198,
      -0.05543264374136925,
      0.0010912041179835796,
      -0.029916459694504738,
      0.0982188954949379,
      -0.08099311590194702,
      -0.028326140716671944,
      -0.0019132527522742748,
      0.054430533200502396,
      0.012121767736971378,
      -0.017522161826491356,
      -0.03753965348005295,
      -0.031726036220788956,
      -0.014513455331325531,
      -0.00807846151292324,
      0.04035308584570885,
      -0.038499511778354645,
      -0.02709384821355343,
      0.0328044667840004,
      -0.056244198232889175,
      0.07085629552602768,
      -0.011197101324796677,
      0.006709975190460682,
      0.004679383244365454,
      -0.04017135873436928,
      -0.041778210550546646,
      0.0313083715736866,
      0.0006474590627476573,
      -0.04012184590101242,
      -0.03338338062167168,
      -0.044711414724588394,
      0.03959796950221062,
      -0.05899205058813095,
      0.025034412741661072,
      0.022253600880503654,
      -0.059396516531705856,
      -0.012786338105797768,
      -0.043742794543504715,
      0.034765858203172684,
      0.011049962602555752,
      0.012376903556287289,
      -0.012983007356524467,
      -0.008587450720369816,
      0.017798854038119316,
      0.05043741688132286,
      0.0368163175880909,
      -0.08233015984296799,
      0.037943609058856964,
      0.09340248256921768,
      0.03313905745744705,
      0.006280726287513971,
      0.01902157999575138,
      0.07479555904865265,
      -0.06965213268995285,
      0.06564240902662277,
      0.04313097149133682,
      0.015853922814130783,
      -0.01070297509431839,
      0.026485031470656395,
      0.023585788905620575,
      0.03159239515662193,
      -0.0023148502223193645,
      -0.03931998461484909,
      -0.07603000849485397,
      0.004795373883098364,
      -0.02361636981368065,
      0.034493062645196915,
      0.07070537656545639,
      0.08217822015285492,
      -0.03344361484050751,
      0.04075471684336662,
      -0.028354497626423836,
      0.09035316854715347,
      -0.0023157447576522827,
      0.004330028779804707,
      -0.04192834720015526,
      0.0003327566955704242,
      -0.10477855801582336,
      0.02064192108809948,
      -0.0003544222272466868,
      0.09479714930057526,
      -0.044503942131996155,
      0.023685272783041,
      -0.04651322215795517,
      0.03437897562980652,
      -0.04362167790532112,
      -0.053683310747146606,
      0.028529908508062363,
      0.09644190967082977,
      -0.037485890090465546,
      -0.051494698971509933,
      0.04085036739706993,
      0.04562214016914368,
      -0.01830533891916275,
      -0.0177907794713974,
      -0.03763824701309204,
      0.009119812399148941,
      0.01706734672188759,
      0.027578914538025856,
      -0.08955297619104385,
      -0.03245356306433678,
      0.006209379993379116,
      0.028072377666831017,
      0.03547276183962822,
      0.006356376688927412,
      -0.01229033898562193,
      -0.04718625918030739,
      0.05213068425655365,
      0.017352238297462463,
      0.02492809109389782,
      -0.0174407958984375,
      -0.06997409462928772,
      0.031152531504631042,
      0.03364215046167374,
      -0.02401045709848404,
      0.021026210859417915,
      -0.021138055250048637,
      -0.03822030499577522,
      0.004420218523591757,
      -0.03982017934322357,
      0.0018945714691653848,
      -0.03846289962530136,
      0.007305113598704338,
      -0.0003014530520886183,
      -0.0910828486084938,
      0.0036884304136037827,
      0.0665804073214531,
      -0.024988573044538498,
      -0.05888985097408295,
      -0.0036857195664197206,
      0.049127399921417236,
      -0.09107527881860733,
      0.035502489656209946,
      0.012999686412513256,
      0.009835716336965561,
      -0.022709796205163002,
      -0.049623988568782806,
      -0.042159952223300934,
      -0.0813123807311058,
      -0.00014402616943698376,
      0.011047129519283772,
      0.024769581854343414,
      0.014123133383691311,
      -0.023862188681960106,
      0.0049195680767297745,
      0.016165519133210182,
      -0.005616154987365007,
      0.08455301076173782,
      0.02309025451540947,
      -0.07790672779083252,
      0.04340317100286484,
      0.04248768091201782,
      -0.0484372153878212,
      0.02123788557946682,
      0.015242993831634521,
      -0.039619095623493195,
      -0.013787029311060905,
      0.0037980172783136368,
      -0.05458937957882881,
      0.04992756247520447,
      0.05543246865272522,
      -0.03412162885069847,
      0.013109645806252956,
      0.00868819747120142,
      -0.029735473915934563,
      -0.055298320949077606,
      -0.034260042011737823,
      0.0827348604798317,
      0.016334960237145424,
      -0.009036822244524956,
      -0.03311261162161827,
      -0.08489019423723221,
      0.030634064227342606,
      -0.008000420406460762,
      -0.04535466060042381,
      0.028361598029732704,
      0.06806885451078415,
      0.05071696266531944,
      -0.049173422157764435
    ],
    [
      0.0014922099653631449,
      -0.008620901964604855,
      0.004811644554138184,
      -0.004008323419839144,
      -0.015285996720194817,
      -0.0352880097925663,
      -0.021147826686501503,
      -0.007364044897258282,
      0.012794669717550278,
      0.010417514480650425,
      0.06604228913784027,
      -0.05739204213023186,
      -0.02318534627556801,
      0.0695279985666275,
      0.017740599811077118,
      -0.019119327887892723,
      0.0037597527261823416,
      -0.04511677846312523,
      -0.0724882036447525,
      0.016383156180381775,
      -0.0018167016096413136,
      -0.06347263604402542,
      -0.0047591920010745525,
      -0.020787203684449196,
      0.0015613219002261758,
      -0.01892063021659851,
      0.02603994309902191,
      0.018703170120716095,
      -0.005811398383229971,
      -0.06837556511163712,
      -0.02573409117758274,
      0.05884839966893196,
      0.00622956408187747,
      -0.0691768079996109,
      0.0009548345115035772,
      -0.00677247392013669,
      -0.010088866576552391,
      -0.009718413464725018,
      0.015919657424092293,
      -0.052061207592487335,
      0.0013204587157815695,
      -0.03813200816512108,
      0.05028501898050308,
      0.06167451664805412,
      -0.012830364517867565,
      -0.006513964384794235,
      -0.04604822397232056,
      0.007725629955530167,
      -0.0379045344889164,
      -0.0025678137317299843,
      -0.04752189293503761,
      0.015152094885706902,
      0.0009158001630567014,
      -0.044660840183496475,
      0.013224562630057335,
      0.07364197820425034,
      -0.012693055905401707,
      0.04725143685936928,
      0.04890723526477814,
      -0.04127870872616768,
      0.029833845794200897,
      -0.02742350660264492,
      0.022863328456878662,
      0.013138672336935997,
      0.04331682249903679,
      0.013196599669754505,
      -0.08243981003761292,
      -0.03052493929862976,
      0.027890553697943687,
      0.015056340023875237,
      -0.03927047550678253,
      -0.009014773182570934,
      0.009559796191751957,
      -0.01954108662903309,
      -0.06722375005483627,
      0.004477172624319792,
      -0.03907625749707222,
      0.013576192781329155,
      -0.01354712713509798,
      -0.00968664139509201,
      0.012501857243478298,
      0.013716158457100391,
      -0.0018851233180612326,
      0.022205451503396034,
      0.06522143632173538,
      -0.0664658471941948,
      -0.009644245728850365,
      -0.006607415154576302,
      0.008243662305176258,
      0.00645297858864069,
      -0.0017900236416608095,
      -0.0015497112181037664,
      -0.006668659392744303,
      0.08728675544261932,
      -0.01135797519236803,
      -0.050732724368572235,
      -0.03504820913076401,
      -0.02040446735918522,
      0.028480390086770058,
      0.00971859972923994,
      0.05131552368402481,
      0.003987074829638004,
      0.011511935852468014,
      0.06733039021492004,
      0.019335467368364334,
      -0.028933163732290268,
      -0.06098861247301102,
      0.01712924800813198,
      -0.032168198376894,
      -0.029681991785764694,
      0.005443750414997339,
      -0.022510435432195663,
      0.010370890609920025,
      -0.00592269841581583,
      0.02624453976750374,
      -0.016340244561433792,
      0.020310383290052414,
      0.013669553212821484,
      0.03172534704208374,
      -0.053507328033447266,
      0.0803707093000412,
      -0.04054900258779526,
      0.08508080244064331,
      -0.011992271989583969,
      0.0071665882132947445,
      0.08688834309577942,
      0.02886582352221012,
      -0.006676287855952978,
      -0.04251372814178467,
      -0.0714123398065567,
      -0.021387146785855293,
      0.06067618727684021,
      -0.023580683395266533,
      -0.042922552675008774,
      -0.011802361346781254,
      -0.04608430713415146,
      -0.020124508067965508,
      0.0687764510512352,
      0.01439023856073618,
      -0.07656791061162949,
      0.0015724148834124207,
      0.00801558792591095,
      -0.012204162776470184,
      -0.024927161633968353,
      0.003741855500265956,
      0.011277178302407265,
      0.07281941920518875,
      -0.035092420876026154,
      0.01023897435516119,
      0.02887077070772648,
      -0.0019814767874777317,
      -0.03542975336313248,
      0.012804262340068817,
      -0.016778793185949326,
      0.06697708368301392,
      0.04078560322523117,
      -0.07448597252368927,
      -0.02213916927576065,
      -0.02603956311941147,
      -0.06244863569736481,
      0.035831302404403687,
      -0.049713585525751114,
      0.035137876868247986,
      0.048041678965091705,
      -0.030260009691119194,
      0.08694813400506973,
      0.008274436928331852,
      0.04426855966448784,
      -0.05600510910153389,
      0.02927897498011589,
      0.049699556082487106,
      -0.02432585507631302,
      -0.012234309688210487,
      0.04010864347219467,
      -0.04264068603515625,
      0.08774852007627487,
      -0.04243352636694908,
      0.042225852608680725,
      -0.028530685231089592,
      0.06431245803833008,
      -0.0324864462018013,
      -0.027207650244235992,
      -0.030303964391350746,
      0.01775510609149933,
      -0.028648491948843002,
      -0.030335504561662674,
      0.03648003935813904,
      -0.03781101480126381,
      0.027833303436636925,
      0.07335329055786133,
      0.0078508872538805,
      -0.07646019011735916,
      -0.007311068009585142,
      -0.00236686272546649,
      -0.021004145964980125,
      -0.027223439887166023,
      0.05250222980976105,
      -0.00040502057527191937,
      0.037380386143922806,
      -0.04135017842054367,
      0.08369731903076172,
      0.030729258432984352,
      0.1325431615114212,
      -0.08933226019144058,
      0.04441574960947037,
      0.026268262416124344,
      0.053300146013498306,
      -0.010611694306135178,
      -0.031086795032024384,
      0.05936465784907341,
      -0.05906852334737778,
      0.05999324098229408,
      0.14025065302848816,
      -0.027974195778369904,
      -0.02798343263566494,
      0.011095823720097542,
      0.017943687736988068,
      -0.07564874738454819,
      -0.05319322273135185,
      -0.014278669841587543,
      0.019149044528603554,
      -0.04452919960021973,
      0.06045451760292053,
      -0.000168027967447415,
      -0.003982815425843,
      -0.021099815145134926,
      -0.06919305771589279,
      0.016230985522270203,
      -0.02901593968272209,
      0.037708837538957596,
      -0.007133179809898138,
      -0.07299932837486267,
      0.06747883558273315,
      0.13349048793315887,
      0.013142810203135014,
      -0.002628142014145851,
      0.044801659882068634,
      0.013795366510748863,
      -0.016068166121840477,
      -0.09579169750213623,
      0.01112549938261509,
      0.014052747748792171,
      -0.03877609595656395,
      -0.04605094715952873,
      0.0717695876955986,
      0.027484221383929253,
      0.024239161983132362,
      0.023799151182174683,
      0.0353766605257988,
      -0.021757591515779495,
      -0.044184278696775436,
      0.05426779389381409,
      0.017399676144123077,
      -0.09048212319612503,
      -0.07053238898515701,
      -0.030329154804348946,
      -0.0032427036203444004,
      -0.057989999651908875,
      -0.07523195445537567,
      -0.005876731593161821,
      -0.020670214667916298,
      -0.05227019637823105,
      -0.022438744083046913,
      0.04910391941666603,
      0.0015561007894575596,
      -0.03134467825293541,
      0.031125551089644432,
      0.08338752388954163,
      -0.04851819574832916,
      0.04900427535176277,
      -0.0594303198158741,
      0.005913446191698313,
      -0.04339505359530449,
      -0.02160503715276718,
      -0.011399514973163605,
      0.03276137635111809,
      0.021747775375843048,
      0.003529790323227644,
      0.007737114559859037,
      0.01734204590320587,
      -0.01139936875551939,
      -0.006553954910486937,
      0.029745683073997498,
      0.02941177226603031,
      0.031655825674533844,
      -0.026899635791778564,
      0.0095700453966856,
      -0.025342663750052452,
      0.04516002535820007,
      0.054072242230176926,
      0.018823273479938507,
      0.029453694820404053,
      -0.05378015711903572,
      -0.007317781448364258,
      -0.04146037623286247,
      -0.0068864827044308186,
      -0.025333227589726448,
      -0.043300218880176544,
      0.004350077360868454,
      -0.0778357982635498,
      -0.027046842500567436,
      -0.020125651732087135,
      -0.055117446929216385,
      -0.015003116801381111,
      -0.09423927962779999,
      -0.04908324033021927,
      0.11609295755624771,
      -0.05219404399394989,
      -0.04271090403199196,
      0.027415253221988678,
      -0.028008664026856422,
      -0.11047294735908508,
      -0.0148575184866786,
      -0.05359848216176033,
      -0.06319683790206909,
      0.03844805061817169,
      0.03636924549937248,
      0.0026581573765724897,
      -0.033578138798475266,
      -0.023175440728664398,
      -0.026010438799858093,
      0.05190705507993698,
      -0.048900771886110306,
      -0.02542075142264366,
      0.02861819602549076,
      -0.03457029536366463,
      0.025446217507123947,
      0.0778389498591423,
      -0.0783291906118393,
      0.00739066768437624,
      -0.028436845168471336,
      -0.008144279941916466,
      -0.08490802347660065,
      -0.023520395159721375,
      -0.11202523857355118,
      -0.031172562390565872,
      -0.000189077909453772,
      0.09515035897493362,
      0.024319080635905266,
      -0.014896419830620289,
      -0.004250308033078909,
      0.034153420478105545,
      -0.0006905808695591986,
      -0.04170495644211769,
      0.05700412392616272,
      0.028897026553750038,
      0.02704698219895363,
      -0.029712224379181862,
      0.03829752653837204,
      -0.015248985961079597,
      -0.020762769505381584,
      0.07404980808496475,
      -0.016655711457133293,
      0.02118910290300846,
      -0.01493426226079464,
      0.0316663458943367,
      0.024695510044693947,
      0.012468453496694565,
      -0.019383462145924568,
      0.0922711044549942,
      -0.035592220723629,
      0.029652830213308334,
      0.08387824892997742,
      -0.027134932577610016,
      0.03377614542841911,
      0.02031899429857731,
      0.0328906886279583,
      -0.053668420761823654,
      -0.06337567418813705,
      0.005983804818242788,
      -0.11314567923545837,
      0.030339829623699188,
      0.06483639031648636,
      -0.010414734482765198,
      0.001046141260303557,
      0.0432148315012455,
      -0.00677561154589057,
      -0.04760684818029404,
      -0.04071678966283798,
      0.08059719204902649,
      -0.036572109907865524,
      -0.05097686126828194,
      -0.00475352443754673,
      -0.031273238360881805,
      -0.0542331263422966,
      0.040680497884750366,
      0.05893082171678543,
      0.00915701873600483,
      -0.0683329775929451,
      0.01752726547420025,
      -0.037635982036590576,
      0.01075323112308979,
      0.00516132777556777,
      0.009424319490790367,
      -0.036925576627254486,
      0.023298151791095734,
      0.008100789040327072,
      -0.012624163180589676,
      -0.014081173576414585,
      -0.09826485812664032,
      -0.03478897362947464,
      -0.04661039263010025,
      -0.024430064484477043,
      0.04570343345403671,
      0.01229766197502613,
      0.030127856880426407,
      -0.07167612016201019,
      -0.010128691792488098,
      0.034221041947603226,
      -0.039578620344400406,
      -0.0568278506398201,
      -0.06052175909280777,
      -0.0858541801571846,
      -0.021932363510131836,
      -0.033306051045656204,
      0.06793642789125443,
      0.059052180498838425,
      0.03053922951221466,
      -0.013487922959029675,
      -0.0008913622004911304,
      -0.05492944270372391,
      -0.033316291868686676,
      0.018855798989534378,
      -0.060322266072034836,
      0.0025627247523516417,
      -0.06525244563817978,
      0.01912403665482998,
      -0.0488775260746479,
      0.00921974889934063,
      0.06560172885656357,
      -0.05201924964785576,
      -0.015020799823105335,
      -0.024275312200188637,
      0.07297317683696747,
      -0.0016381246969103813,
      0.00967495609074831,
      0.011880582198500633,
      0.005877514835447073,
      0.010107387788593769,
      0.02490127459168434,
      0.05271212011575699,
      -0.04681449756026268,
      0.02730877697467804,
      0.03948783874511719,
      0.010576288215816021,
      0.09974920004606247,
      0.05028556287288666,
      -0.057162705808877945,
      -0.02048228681087494,
      0.022603485733270645,
      -0.011940235272049904,
      0.09590748697519302,
      0.001216900534927845,
      -0.01755461096763611,
      0.026734760031104088,
      0.0005253193085081875,
      -0.028611406683921814,
      0.036123182624578476,
      0.01117065642029047,
      -0.08660076558589935,
      -0.026065167039632797,
      0.0764910951256752,
      -0.019289681687951088,
      -0.011055104434490204,
      0.059075698256492615,
      0.032987628132104874,
      -0.06212586537003517,
      -0.0323559045791626,
      -0.013946969993412495,
      0.04453074559569359,
      -0.02810516208410263,
      -0.05424245074391365,
      0.07237064093351364,
      -0.005075955297797918,
      0.016662321984767914,
      -0.015317250043153763,
      0.03204362466931343,
      0.015059199184179306,
      -0.008916767314076424,
      -0.027867956086993217,
      0.020969746634364128,
      -0.00769132561981678,
      -0.06567796319723129,
      0.08366629481315613,
      -0.021760065108537674,
      -0.012892044149339199,
      0.026149839162826538,
      0.004837442189455032,
      -0.07022777199745178,
      -0.04237643629312515,
      -0.03835010528564453,
      -0.048782434314489365,
      -0.012714833952486515,
      0.010943586006760597,
      0.06908157467842102,
      -0.0658196434378624,
      0.07405335456132889,
      -0.03684564307332039,
      -0.09724856168031693,
      -0.03689296916127205,
      0.004567408002912998,
      0.0338139608502388,
      0.016434064134955406
    ],
    [
      -0.054430726915597916,
      -0.024926502257585526,
      -0.0622478723526001,
      -0.01565427891910076,
      0.007604961283504963,
      0.08667983114719391,
      -0.030359383672475815,
      -0.008240638300776482,
      -0.05969768017530441,
      0.018218448385596275,
      -0.022246742621064186,
      -0.04503428190946579,
      0.028948713093996048,
      0.027169376611709595,
      0.06041592359542847,
      -0.00736796110868454,
      0.007079069036990404,
      -0.015676597133278847,
      -0.035188596695661545,
      -0.007162983994930983,
      -0.012276891618967056,
      0.054641444236040115,
      -0.06127927079796791,
      0.07094906270503998,
      -0.048200029879808426,
      0.017282262444496155,
      -0.030306078493595123,
      0.03393072634935379,
      -0.07893604040145874,
      0.08189358562231064,
      -0.023948553949594498,
      -0.02916586771607399,
      0.037304505705833435,
      0.021260786801576614,
      0.022650957107543945,
      -0.023059232160449028,
      -0.046032920479774475,
      -0.015596898272633553,
      -0.010195164009928703,
      0.05129251256585121,
      -0.024939915165305138,
      -0.0812884271144867,
      0.03903992101550102,
      -0.01733347959816456,
      -0.04729998856782913,
      -0.0005345007521100342,
      -0.05231847986578941,
      -0.0028351182118058205,
      0.033354271203279495,
      -0.09227075427770615,
      0.018078625202178955,
      -0.12487774342298508,
      0.033039141446352005,
      -0.021329153329133987,
      0.044319555163383484,
      -0.04869096353650093,
      0.036716073751449585,
      0.05729377269744873,
      -0.011667581275105476,
      -0.06750933825969696,
      0.014336795546114445,
      -0.02945571020245552,
      -0.031492266803979874,
      0.054814599454402924,
      0.022430334240198135,
      -0.023097896948456764,
      -0.03503086045384407,
      0.03539085015654564,
      0.011383142322301865,
      0.0033182029146701097,
      0.014034434221684933,
      0.004384157247841358,
      0.09642119705677032,
      0.018195945769548416,
      0.026329034939408302,
      0.0025660989340394735,
      -0.02105816639959812,
      -0.06687590479850769,
      -0.036088455468416214,
      0.08114150911569595,
      -0.043623123317956924,
      -0.012750969268381596,
      -0.07908933609724045,
      0.0014932033373042941,
      0.028086163103580475,
      0.02329251356422901,
      -0.017092347145080566,
      -0.1192324236035347,
      0.01660255528986454,
      0.019950957968831062,
      0.009470873512327671,
      -0.027759941294789314,
      -0.08808005601167679,
      0.03200820833444595,
      -0.06725838035345078,
      0.04865379258990288,
      0.01583767682313919,
      0.05949420854449272,
      -0.053681015968322754,
      -0.08055920898914337,
      0.005377288907766342,
      -0.05452443286776543,
      0.02290690690279007,
      -0.051616568118333817,
      -0.019717074930667877,
      -0.0005583438323810697,
      0.009799075312912464,
      -0.03366336598992348,
      -0.06127895042300224,
      -0.028531646355986595,
      -0.04651125520467758,
      0.020266372710466385,
      0.009790273383259773,
      -0.041804373264312744,
      -0.052985984832048416,
      -0.029960907995700836,
      -0.008687692694365978,
      -0.00882838387042284,
      -0.0530676543712616,
      -0.0021139984019100666,
      0.024408914148807526,
      -0.061913467943668365,
      0.11064361035823822,
      -0.027932342141866684,
      -0.020238758996129036,
      -0.03198665753006935,
      -0.036826297640800476,
      0.01817876659333706,
      -0.052261825650930405,
      -0.09723170846700668,
      0.01145903579890728,
      -0.016248738393187523,
      -0.05428187921643257,
      -0.0003468928043730557,
      0.007282939273864031,
      0.018210699781775475,
      0.023960288614034653,
      -0.07468626648187637,
      -0.04798588156700134,
      -0.055289071053266525,
      -0.02829202264547348,
      -0.00971364974975586,
      -0.02240261808037758,
      0.02793898433446884,
      0.030851680785417557,
      0.03814316168427467,
      0.03164384886622429,
      -0.050339195877313614,
      -0.0008505227160640061,
      0.012272478081285954,
      -0.08167953044176102,
      0.09159620851278305,
      0.02777916006743908,
      0.032890837639570236,
      0.010197337716817856,
      0.09219546616077423,
      0.009729745797812939,
      -0.056263796985149384,
      0.02358385920524597,
      -0.0644073411822319,
      0.0051134563982486725,
      0.04964521527290344,
      -0.008146022446453571,
      -0.02267463691532612,
      0.03904907777905464,
      0.05252526327967644,
      -0.06149924919009209,
      -0.03182384744286537,
      0.0031678969971835613,
      0.0280337892472744,
      0.0523802749812603,
      -0.017822671681642532,
      -0.030655372887849808,
      0.03916022926568985,
      0.03244156390428543,
      -0.036541130393743515,
      0.05636760964989662,
      0.012237483635544777,
      0.06602036952972412,
      -0.05141836032271385,
      -0.011109844781458378,
      -0.055120717734098434,
      -0.019502267241477966,
      -0.04511766508221626,
      0.026088936254382133,
      -0.0276230089366436,
      0.018334032967686653,
      -0.021489886566996574,
      0.014329505153000355,
      0.01633593812584877,
      0.08973798155784607,
      -0.01175784319639206,
      -0.009976746514439583,
      -0.07185191661119461,
      -0.0296047143638134,
      0.08172743022441864,
      -0.053573187440633774,
      -0.05884908512234688,
      0.021341918036341667,
      -0.029229963198304176,
      0.13428933918476105,
      -0.05067887902259827,
      0.006233809050172567,
      -0.014186845161020756,
      -0.024429988116025925,
      0.037535201758146286,
      0.020780088379979134,
      0.020895030349493027,
      -0.022065818309783936,
      -0.07409243285655975,
      -0.07615359872579575,
      0.030092937871813774,
      0.0380866713821888,
      -0.05619357153773308,
      0.04343438893556595,
      0.059889279305934906,
      0.046140171587467194,
      0.014620685018599033,
      -0.05913279950618744,
      -0.019970914348959923,
      0.005197817925363779,
      -0.026858728379011154,
      0.041239961981773376,
      0.030918901786208153,
      -0.04598161578178406,
      -0.04408657178282738,
      -0.017247222363948822,
      -0.05029641464352608,
      0.007243051193654537,
      -0.017848096787929535,
      0.006458164658397436,
      0.021543679758906364,
      0.046877145767211914,
      -0.010809081606566906,
      -0.0013868595706298947,
      0.0019004344940185547,
      -0.07815176993608475,
      0.03154393285512924,
      0.035492271184921265,
      0.03159043937921524,
      -0.06763051450252533,
      0.04515582695603371,
      0.05540194734930992,
      -0.027966629713773727,
      0.025925546884536743,
      0.10501228272914886,
      0.0036257077008485794,
      0.03981367126107216,
      0.0007609741296619177,
      -0.05745188146829605,
      -0.02801191620528698,
      -0.015161637216806412,
      0.056235555559396744,
      0.010471770539879799,
      0.03539864346385002,
      -0.12609487771987915,
      0.009231981821358204,
      0.03666025772690773,
      0.030947228893637657,
      -0.033111147582530975,
      0.00691831111907959,
      0.011792660690844059,
      0.018307555466890335,
      0.011405149474740028,
      -0.02345912531018257,
      -0.010866420343518257,
      -0.08567194640636444,
      0.10347241908311844,
      -0.012084346264600754,
      0.03961194306612015,
      -0.05091116949915886,
      0.015566857531666756,
      0.02277456596493721,
      -0.02652784064412117,
      -0.0031669733580201864,
      -0.006635826546698809,
      -0.03189493715763092,
      0.03408341482281685,
      0.045173756778240204,
      0.03962359204888344,
      -0.10561876744031906,
      0.05117534473538399,
      -0.012263418175280094,
      0.11542738974094391,
      0.018203390762209892,
      -0.03557794913649559,
      -0.014818805269896984,
      0.1148921474814415,
      -0.0087761040776968,
      -0.043110448867082596,
      -0.021367575973272324,
      -0.0403284877538681,
      0.015941008925437927,
      0.0035795525182038546,
      0.08426351100206375,
      -0.04272032901644707,
      0.0004171934851910919,
      -0.016400080174207687,
      -0.04845680668950081,
      0.012468668632209301,
      -0.07935899496078491,
      -0.030405594035983086,
      0.01679849438369274,
      -0.015845851972699165,
      -0.05421201139688492,
      0.0008853155304677784,
      0.0664687305688858,
      0.0020324955694377422,
      -0.014413692057132721,
      -0.024551255628466606,
      -0.035158853977918625,
      0.07218416780233383,
      0.03114061802625656,
      0.03738846629858017,
      -0.016137730330228806,
      0.022008726373314857,
      -0.009552355855703354,
      0.04511253163218498,
      -0.024541957303881645,
      -0.04212651774287224,
      0.009893818758428097,
      0.09822205454111099,
      0.006200273986905813,
      -0.01936064474284649,
      0.0085813719779253,
      -0.007813779637217522,
      0.030219200998544693,
      -0.052261754870414734,
      -0.062450677156448364,
      -0.013713247142732143,
      0.10295092314481735,
      -0.023451605811715126,
      0.018920091912150383,
      -0.03255752846598625,
      -0.037216298282146454,
      0.03485260158777237,
      0.010939541272819042,
      -0.04864417761564255,
      0.03198115527629852,
      -0.010097185149788857,
      0.027669552713632584,
      0.05881877616047859,
      -0.058489296585321426,
      -0.036767568439245224,
      -0.04311009496450424,
      0.05745379254221916,
      0.039438676089048386,
      0.00010450834088260308,
      -0.0006709542940370739,
      0.04144164174795151,
      -0.07315528392791748,
      0.07426071912050247,
      0.01393034402281046,
      0.044446323066949844,
      0.014383426867425442,
      0.054049909114837646,
      -0.0006956452853046358,
      0.08395064622163773,
      -0.03356300666928291,
      -0.021506555378437042,
      -0.06166664510965347,
      -0.022946881130337715,
      -0.02917734906077385,
      0.08819156140089035,
      0.022344384342432022,
      -0.023198748007416725,
      -0.02632397599518299,
      -0.037468407303094864,
      0.027696046978235245,
      0.036313142627477646,
      -0.03959671035408974,
      0.0766647607088089,
      -0.02455245703458786,
      -0.004179227631539106,
      0.011972634121775627,
      0.09528492391109467,
      0.038682617247104645,
      0.07133136689662933,
      -0.03970867395401001,
      0.03620016574859619,
      -0.008094219490885735,
      0.012968513183295727,
      0.002398997312411666,
      0.021215954795479774,
      0.01879323646426201,
      0.004903361666947603,
      0.07232250273227692,
      -0.026503650471568108,
      0.04085495322942734,
      -0.046310678124427795,
      0.07335545122623444,
      -0.02477058582007885,
      0.003441144712269306,
      -0.13006220757961273,
      -0.010900643654167652,
      0.06293080002069473,
      -0.05563730001449585,
      0.02075708471238613,
      0.012219373136758804,
      -0.051351193338632584,
      -0.0871109813451767,
      -0.09078428894281387,
      0.0009163187933154404,
      0.06442556530237198,
      0.016430038958787918,
      0.017801042646169662,
      0.018127284944057465,
      -0.012088804505765438,
      0.005039151292294264,
      0.00466204434633255,
      -0.0839114561676979,
      0.06952068209648132,
      -0.022949138656258583,
      -0.011697536334395409,
      0.022610226646065712,
      0.034745145589113235,
      -0.050931621342897415,
      0.011608094908297062,
      0.009339128620922565,
      -0.09701640903949738,
      0.0005839031073264778,
      0.0011818852508440614,
      -0.016241837292909622,
      0.0664561316370964,
      -0.0771365538239479,
      -0.04117273539304733,
      0.07798222452402115,
      0.045356787741184235,
      0.018879160284996033,
      0.029777541756629944,
      -0.008091060444712639,
      -0.016527345404028893,
      0.0730150118470192,
      -0.006844944320619106,
      -0.03561371937394142,
      0.044913362711668015,
      -0.07628510892391205,
      0.01309950090944767,
      0.07302165776491165,
      0.04898633435368538,
      -0.07275742292404175,
      -0.011896944604814053,
      0.003613378619775176,
      0.0183863528072834,
      -0.007888616062700748,
      -0.021925752982497215,
      0.052818767726421356,
      0.023416126146912575,
      -0.010966728441417217,
      -0.02714887447655201,
      -0.036712873727083206,
      -0.018809527158737183,
      -0.043603911995887756,
      -0.08622576296329498,
      0.02528759464621544,
      -0.07868512719869614,
      0.05474868416786194,
      0.12239771336317062,
      -0.008609134703874588,
      -0.0046735117211937904,
      -0.0016210525063797832,
      -0.0049205562099814415,
      0.0959489718079567,
      -0.0242309607565403,
      0.011605084873735905,
      0.002435422269627452,
      0.0417013019323349,
      0.0226703230291605,
      -0.0038411319255828857,
      -0.01462043821811676,
      0.015119580551981926,
      0.010993387550115585,
      -0.0357644148170948,
      0.04218214377760887,
      0.02049456723034382,
      0.030631421133875847,
      0.05777914822101593,
      -0.014870641753077507,
      0.05070338770747185,
      -0.03190598636865616,
      0.04111665114760399,
      0.027351615950465202,
      -0.007254956755787134,
      0.07948474586009979,
      -0.06081870198249817,
      -9.745926217874512e-05,
      -0.038971953094005585,
      0.025439264252781868,
      -0.059981878846883774,
      -0.024419201537966728,
      -0.05762697011232376,
      0.04581621289253235,
      0.0536356084048748,
      -0.02440267987549305,
      -0.044327057898044586,
      0.034109801054000854,
      0.004138568881899118,
      -0.016471832990646362,
      0.005969530437141657,
      -0.026760367676615715,
      0.005186889320611954,
      -0.00841981079429388,
      0.021662812680006027
    ],
    [
      0.002080413745716214,
      0.06070134416222572,
      -0.016395431011915207,
      0.03277076408267021,
      0.02146786078810692,
      0.010488306172192097,
      -0.015265204943716526,
      -0.010512539185583591,
      -0.0254372525960207,
      -0.012304520234465599,
      -0.014696723781526089,
      -0.0105131221935153,
      -0.005331091117113829,
      -0.055180393159389496,
      0.007902948185801506,
      0.10122902691364288,
      -0.08352140337228775,
      0.04423116147518158,
      -0.09792810678482056,
      0.03397638350725174,
      0.08492202311754227,
      -0.011745419353246689,
      0.006172440014779568,
      -0.011141164228320122,
      -0.023650139570236206,
      -0.01339992880821228,
      0.002109161578118801,
      -0.016765600070357323,
      -0.05699479207396507,
      0.08188066631555557,
      0.05411485210061073,
      -0.004894760437309742,
      0.056923363357782364,
      0.005708762910217047,
      -0.04558119922876358,
      0.037934403866529465,
      0.023471416905522346,
      0.0033385083079338074,
      -0.02059796079993248,
      0.06019854545593262,
      0.06489752233028412,
      -0.012311562895774841,
      -0.015773184597492218,
      -0.02738342247903347,
      0.10674194246530533,
      -0.021436743438243866,
      -0.03125249221920967,
      -0.09790761768817902,
      0.0050260950811207294,
      -0.005754340905696154,
      0.025964917615056038,
      -0.14362023770809174,
      0.004910907242447138,
      0.05177867040038109,
      0.02912411466240883,
      0.004921888932585716,
      -0.008996278047561646,
      -0.034874077886343,
      -0.01428175438195467,
      0.05342014878988266,
      0.0694771483540535,
      -0.03922189772129059,
      -0.01000386755913496,
      0.03692319616675377,
      0.037711143493652344,
      -0.010814256966114044,
      -0.10401777178049088,
      0.05547093600034714,
      0.05616815388202667,
      0.033349573612213135,
      0.0700005292892456,
      0.026859525591135025,
      -0.08094422519207001,
      0.013501733541488647,
      0.06801620870828629,
      0.02534041553735733,
      -0.018184898421168327,
      -0.04560486599802971,
      0.11463506519794464,
      0.11649052053689957,
      -0.015839070081710815,
      0.0023393079172819853,
      -0.06378112733364105,
      -0.027153275907039642,
      -0.04065990447998047,
      -0.03428663685917854,
      -0.0672442838549614,
      0.0029742226470261812,
      -0.026887862011790276,
      -0.06940368562936783,
      0.0082602109760046,
      0.005692495498806238,
      -0.04087632894515991,
      0.03030998632311821,
      -0.04576389491558075,
      -0.006006211508065462,
      -0.014459382742643356,
      -0.024876810610294342,
      0.016919678077101707,
      -0.043702222406864166,
      0.06522732973098755,
      0.024790536612272263,
      -0.005067473743110895,
      -0.030531130731105804,
      0.028519632294774055,
      0.003943103365600109,
      -0.05282241106033325,
      0.019704759120941162,
      -0.03340835124254227,
      0.0031693591736257076,
      -0.012265661731362343,
      0.027788901701569557,
      -0.1089002937078476,
      0.08477318286895752,
      -0.00691657280549407,
      -0.03566575422883034,
      -0.08249743282794952,
      0.06241889297962189,
      0.056340381503105164,
      0.003950010519474745,
      0.13174735009670258,
      0.05506986007094383,
      0.04573044925928116,
      -0.0285218246281147,
      0.03946511447429657,
      -0.013780728913843632,
      0.045584965497255325,
      -0.010129489935934544,
      -0.0017251514364033937,
      -0.005842480808496475,
      0.02499099262058735,
      0.00020491692703217268,
      -0.011790256947278976,
      -0.016961807385087013,
      -0.049179065972566605,
      -0.03460652381181717,
      -0.018224548548460007,
      -0.007613774389028549,
      0.019090140238404274,
      0.01138669066131115,
      -0.038817793130874634,
      -0.050946567207574844,
      -0.051376279443502426,
      -0.028786489740014076,
      -0.01788005791604519,
      0.03421714901924133,
      -0.080460324883461,
      0.019134119153022766,
      -0.03387707471847534,
      -0.03872169926762581,
      -0.037466708570718765,
      -0.06868001073598862,
      0.08111263066530228,
      0.07818423956632614,
      0.014620468020439148,
      -0.013787335716187954,
      0.04829447716474533,
      0.0038791862316429615,
      0.01294991746544838,
      -0.009326771833002567,
      0.028337476775050163,
      0.04842778295278549,
      0.010901341214776039,
      0.07028108090162277,
      -0.017906155437231064,
      0.005156172439455986,
      -0.0025768131017684937,
      0.06214296445250511,
      0.020332975313067436,
      -0.00014295117580331862,
      0.0057411231100559235,
      -0.0997142419219017,
      -0.031556759029626846,
      -0.03321484103798866,
      -0.030695350840687752,
      0.018003331497311592,
      0.023202281445264816,
      -0.014628168195486069,
      -0.020634029060602188,
      0.028571557253599167,
      -0.02419142983853817,
      -0.009373578242957592,
      0.08819908648729324,
      -0.050869170576334,
      -0.029869647696614265,
      0.01863132417201996,
      0.06000404804944992,
      0.032758332788944244,
      0.022528503090143204,
      0.0014711561379954219,
      0.01480620913207531,
      0.009027508087456226,
      0.016654757782816887,
      -0.0004099111829418689,
      0.011000224389135838,
      -0.00690412474796176,
      0.05121763423085213,
      -0.07115969061851501,
      -0.01366076897829771,
      -0.0848238468170166,
      -0.041208699345588684,
      0.014994857832789421,
      0.1218336969614029,
      -0.036072589457035065,
      -0.017990542575716972,
      0.06332533061504364,
      0.029213478788733482,
      0.0274297297000885,
      0.011847400106489658,
      -0.060942936688661575,
      0.02993112988770008,
      0.051273833960294724,
      0.045666806399822235,
      0.07221757620573044,
      -0.06880614906549454,
      -0.02729552611708641,
      -0.025094859302043915,
      -0.004329185001552105,
      -0.02993713691830635,
      0.008202915079891682,
      -0.058906614780426025,
      -0.014978999271988869,
      0.01506535243242979,
      0.019524984061717987,
      -0.018961811438202858,
      -0.010544340126216412,
      -0.03462151065468788,
      -0.058871690183877945,
      -0.041216470301151276,
      -0.09903837740421295,
      -0.0680600181221962,
      -0.0027628918178379536,
      -0.018228551372885704,
      0.04890907555818558,
      0.024930983781814575,
      0.025072673335671425,
      -0.002254478633403778,
      0.05661965534090996,
      -0.08128587901592255,
      -0.06443162262439728,
      -0.05096187815070152,
      -0.006775335408747196,
      -0.05993242561817169,
      0.007517849560827017,
      -0.08151552826166153,
      0.11595345288515091,
      -0.051128800958395004,
      0.051036201417446136,
      -0.023969409987330437,
      -0.03824808821082115,
      0.005689946468919516,
      0.03008786402642727,
      -0.017022209241986275,
      0.021232258528470993,
      0.019049594178795815,
      0.0280905831605196,
      -0.07893094420433044,
      -0.044543247669935226,
      0.0055623846128582954,
      -0.07426826655864716,
      -0.04008826985955238,
      0.028829094022512436,
      0.022602170705795288,
      0.016777319833636284,
      -0.03734176978468895,
      -0.030010638758540154,
      -0.02779490128159523,
      -0.016747837886214256,
      0.020278366282582283,
      0.009847977198660374,
      0.007512098178267479,
      0.020385239273309708,
      0.08981546759605408,
      -0.007135291583836079,
      -0.03933124244213104,
      -0.020852837711572647,
      -0.01004420593380928,
      0.03809025511145592,
      0.043358463793992996,
      0.028829580172896385,
      0.01976809650659561,
      0.1038748174905777,
      0.02236211486160755,
      -0.042004335671663284,
      0.0365283377468586,
      -0.06624655425548553,
      0.006379399448633194,
      0.024566885083913803,
      -0.005768761038780212,
      0.03116801008582115,
      0.02853931300342083,
      0.012461060658097267,
      0.0037246618885546923,
      0.07883672416210175,
      -0.026844099164009094,
      -0.021342981606721878,
      0.022675491869449615,
      0.03448311984539032,
      0.09048088639974594,
      -0.04833301901817322,
      -0.03938710317015648,
      -0.011450274847447872,
      -0.08834395557641983,
      0.04133068770170212,
      0.05540132150053978,
      -0.004884073045104742,
      -0.03818881884217262,
      0.007887191139161587,
      -0.0480194054543972,
      -0.007187044247984886,
      -0.022633958607912064,
      -0.0193795133382082,
      0.03699953481554985,
      0.04133171588182449,
      -0.08954411745071411,
      0.036421630531549454,
      0.03529710695147514,
      -0.0025350803043693304,
      0.06778324395418167,
      0.025139907374978065,
      -0.022350257262587547,
      0.008982496336102486,
      0.029718786478042603,
      -0.013876853510737419,
      0.02319393679499626,
      0.04089200496673584,
      -0.023423030972480774,
      0.06832316517829895,
      0.03411061316728592,
      -0.08188928663730621,
      0.016623979434370995,
      0.015390188433229923,
      0.022900281473994255,
      -0.00866098701953888,
      -0.05118553712964058,
      -0.04902518540620804,
      0.07821401953697205,
      -0.01122821494936943,
      0.0457368865609169,
      -0.10143129527568817,
      -0.08195672184228897,
      0.03442463278770447,
      -0.01820409670472145,
      0.10371341556310654,
      0.03994584083557129,
      -0.014185694977641106,
      0.03994954004883766,
      0.02621532417833805,
      -0.012377273291349411,
      0.007505638059228659,
      -0.015592264011502266,
      0.053876228630542755,
      0.06796803325414658,
      -0.022822879254817963,
      0.10036732256412506,
      0.028590107336640358,
      0.022006802260875702,
      0.07009629905223846,
      0.02123199962079525,
      0.005593819543719292,
      0.01498355995863676,
      0.021635323762893677,
      0.033907342702150345,
      0.11325792968273163,
      0.003618075279518962,
      0.0369914211332798,
      -0.0236111581325531,
      0.03368375822901726,
      0.0008387697162106633,
      0.007239369675517082,
      0.001344529795460403,
      -0.013060428202152252,
      0.09623147547245026,
      -0.006898302584886551,
      0.02072661928832531,
      -0.03597332537174225,
      0.06525003165006638,
      -0.09060174971818924,
      -0.02807043306529522,
      -0.052970390766859055,
      -0.012974902056157589,
      -0.055449649691581726,
      0.047104112803936005,
      -0.036434490233659744,
      -0.02820512093603611,
      -0.07159621268510818,
      0.02605663798749447,
      -0.025562020018696785,
      -0.014771835878491402,
      0.007623239886015654,
      0.06898932158946991,
      0.040041547268629074,
      -0.035533081740140915,
      -0.014905399642884731,
      -0.020274028182029724,
      -0.04133713245391846,
      0.01391035970300436,
      -0.04342981055378914,
      0.03885084390640259,
      -0.02757101133465767,
      0.018999891355633736,
      -0.011516404338181019,
      -0.008533409796655178,
      -0.02346455492079258,
      0.014468517154455185,
      0.1123088076710701,
      0.008066747337579727,
      -5.905856960453093e-05,
      0.00435278145596385,
      -0.015141712501645088,
      0.07696221768856049,
      0.02222592383623123,
      0.009905939921736717,
      0.028089066967368126,
      -0.03598136082291603,
      0.058934587985277176,
      -0.0033724065870046616,
      -0.08671227097511292,
      0.05270502343773842,
      -0.03671092912554741,
      0.041326455771923065,
      -0.05445720627903938,
      -0.010921224020421505,
      0.014693588018417358,
      -0.024050217121839523,
      -0.02393450401723385,
      0.019577236846089363,
      -0.07001445442438126,
      0.013814869336783886,
      -0.024554317817091942,
      -0.07498080283403397,
      0.020465349778532982,
      0.014625856652855873,
      -0.093849778175354,
      0.026024136692285538,
      0.08028802275657654,
      -0.09146378934383392,
      0.015277131460607052,
      -0.023691197857260704,
      -0.01016279123723507,
      -0.028496963903307915,
      0.017932817339897156,
      -0.05001885071396828,
      0.03660736605525017,
      0.03521091490983963,
      -0.017635466530919075,
      0.0930195227265358,
      -0.002083382336422801,
      -0.0334160178899765,
      0.05246545374393463,
      -0.026158303022384644,
      -0.018515372648835182,
      -0.03369244188070297,
      -0.0180729441344738,
      -0.05905233323574066,
      -0.026075519621372223,
      0.03443542495369911,
      0.017958184704184532,
      -0.02050473727285862,
      -0.002908135764300823,
      0.05271970480680466,
      -0.09021786600351334,
      0.010044271126389503,
      -0.01808455027639866,
      -0.04758172109723091,
      -0.14105050265789032,
      -0.0661383718252182,
      -0.10813778638839722,
      0.023165643215179443,
      0.04422972723841667,
      -0.044042766094207764,
      -0.07041089981794357,
      0.04841280356049538,
      0.03732328489422798,
      0.02541317790746689,
      -0.007753117475658655,
      0.011069239117205143,
      -0.05970793962478638,
      0.041748467832803726,
      -0.027151068672537804,
      0.021976564079523087,
      0.03128739073872566,
      0.006784568540751934,
      0.014460766687989235,
      0.028801625594496727,
      -0.03052252158522606,
      -0.026553133502602577,
      0.02438203990459442,
      0.05382739007472992,
      0.00040796282701194286,
      -0.015200735069811344,
      0.08457664400339127,
      -0.037680137902498245,
      0.037858109921216965,
      -0.0475950725376606,
      0.058008864521980286,
      -0.033942293375730515,
      -0.04239899292588234,
      -0.037167955189943314,
      0.018080871552228928,
      0.09725235402584076,
      0.05183570459485054,
      -0.01110586617141962
    ],
    [
      -0.024191679432988167,
      0.008360069245100021,
      0.025824496522545815,
      -0.028519153594970703,
      0.04167601838707924,
      0.03545121103525162,
      -0.0732656717300415,
      0.043854523450136185,
      -0.02862955816090107,
      0.05996393412351608,
      -0.016417808830738068,
      -0.012652261182665825,
      -0.045646753162145615,
      -0.013401235453784466,
      -0.007119980175048113,
      0.08734683692455292,
      0.027485491707921028,
      -0.03153735399246216,
      0.05174810066819191,
      0.04566982761025429,
      0.024228358641266823,
      -0.021162940189242363,
      0.04586194083094597,
      0.050419267266988754,
      -0.04310307651758194,
      -0.03869366645812988,
      -0.0529947355389595,
      0.006920402869582176,
      0.027392448857426643,
      0.04485420882701874,
      0.03603135794401169,
      0.021882234141230583,
      -0.07535327225923538,
      0.015209767036139965,
      0.024175718426704407,
      0.05139967054128647,
      -0.014964218251407146,
      -0.027353310957551003,
      0.019655143842101097,
      -0.009436412714421749,
      0.03902767226099968,
      0.023324374109506607,
      -0.024705229327082634,
      0.026098595932126045,
      -0.003595276502892375,
      -0.027265893295407295,
      -0.055903416126966476,
      0.05263043940067291,
      0.04050926864147186,
      0.04598861560225487,
      -0.02202114462852478,
      -0.09654483199119568,
      -0.01048779021948576,
      0.05516209080815315,
      0.07865920662879944,
      0.010227053426206112,
      -0.03742534667253494,
      0.038773130625486374,
      0.006409707944840193,
      -0.02706693857908249,
      -0.06197759136557579,
      0.03300978243350983,
      -0.04122404754161835,
      -0.04178684949874878,
      -0.007651209831237793,
      -0.0676640123128891,
      -0.04357718303799629,
      0.01830746978521347,
      0.02525520883500576,
      -0.00452205678448081,
      -0.014730656519532204,
      0.052505701780319214,
      0.014364945702254772,
      0.024110402911901474,
      0.005188124254345894,
      0.04586748033761978,
      -0.04758085682988167,
      -0.05130099877715111,
      -0.02213507518172264,
      0.031379472464323044,
      -0.058581769466400146,
      0.026833433657884598,
      0.024218183010816574,
      0.025765052065253258,
      0.030847910791635513,
      -0.01587935909628868,
      -0.06471755355596542,
      0.06757506728172302,
      0.05945399031043053,
      0.09430883079767227,
      0.010846341960132122,
      -0.001850360888056457,
      -0.009716421365737915,
      0.0008989215712063015,
      -0.04738466441631317,
      0.044577259570360184,
      -0.00017386242689099163,
      0.006877906154841185,
      0.07063880562782288,
      0.028416497632861137,
      0.04608062282204628,
      -0.05152340978384018,
      -0.008333342149853706,
      0.04919411614537239,
      -0.02420477382838726,
      -0.02650584653019905,
      0.05521586537361145,
      0.036078475415706635,
      0.0030582912731915712,
      0.04270634427666664,
      0.0834667906165123,
      -0.03328617662191391,
      -0.019166309386491776,
      0.032736800611019135,
      0.02233739010989666,
      0.010153154842555523,
      -0.015530874952673912,
      -0.01919085532426834,
      -0.0074148960411548615,
      -0.04094637930393219,
      0.10289252549409866,
      -0.022450296208262444,
      -0.01007819827646017,
      0.0069340905174613,
      -0.04736173897981644,
      -0.009743613190948963,
      0.038596995174884796,
      0.027206873521208763,
      -0.06370017677545547,
      -0.03941456601023674,
      -0.022636251524090767,
      -0.0410415455698967,
      0.03666398301720619,
      -0.005552598275244236,
      0.06219521537423134,
      -0.04355727136135101,
      0.04993196576833725,
      0.03843943774700165,
      0.031388863921165466,
      -0.04073016345500946,
      -0.09914480149745941,
      0.05690745264291763,
      -0.018876198679208755,
      -0.012879753485321999,
      0.007941377349197865,
      -0.039816368371248245,
      0.03696473315358162,
      -0.02966758981347084,
      0.054657258093357086,
      0.046216122806072235,
      -0.011468438431620598,
      0.07297701388597488,
      0.033851586282253265,
      -0.061520278453826904,
      -0.03685349225997925,
      0.05374285206198692,
      0.01293347217142582,
      0.014414566569030285,
      0.040900200605392456,
      0.026439137756824493,
      0.01813986711204052,
      0.03522338718175888,
      -0.0026774562429636717,
      -0.04988102242350578,
      -0.02523507922887802,
      -0.03475600481033325,
      0.0913768783211708,
      -0.08338732272386551,
      0.04989044740796089,
      -0.04781249165534973,
      0.0008314126171171665,
      -0.06306325644254684,
      -0.028968462720513344,
      -0.021407464519143105,
      0.09026719629764557,
      -0.03864261507987976,
      0.04559054225683212,
      -0.0009389769984409213,
      -0.034610167145729065,
      0.007719847839325666,
      -0.011142604053020477,
      -0.021955285221338272,
      0.03698372468352318,
      0.0313277505338192,
      0.000988112878985703,
      0.036923427134752274,
      -0.057999249547719955,
      -0.05697453394532204,
      -0.10583686828613281,
      -0.04764427989721298,
      -0.007444858085364103,
      -0.0035357081796973944,
      0.06280822306871414,
      0.07628801465034485,
      0.05746208503842354,
      -0.0096652302891016,
      0.06365761160850525,
      0.02347494103014469,
      -0.0010818775044754148,
      -0.045305293053388596,
      0.05479256808757782,
      -0.03862249106168747,
      0.037260301411151886,
      0.03413970395922661,
      0.013579333201050758,
      0.01891445741057396,
      -0.0022481216583400965,
      0.07803881168365479,
      -0.06191372871398926,
      -0.051366083323955536,
      -0.0009693438187241554,
      0.0337664820253849,
      -0.04831573739647865,
      0.03756105154752731,
      -0.035055916756391525,
      -0.0033611946273595095,
      -0.005152481608092785,
      8.773930312599987e-05,
      0.03882734850049019,
      0.04350082576274872,
      -0.02650381438434124,
      -0.022562021389603615,
      0.03268112987279892,
      0.05818086862564087,
      -0.0054266429506242275,
      0.09563670307397842,
      -0.04145664721727371,
      0.04546530917286873,
      0.0010681762360036373,
      -0.00876498594880104,
      -0.01351004745811224,
      -0.013841595500707626,
      -0.0182491522282362,
      0.0835997611284256,
      -0.08485197275876999,
      0.02663145586848259,
      -0.08487971872091293,
      -0.019169950857758522,
      -0.0575745590031147,
      8.170913497451693e-05,
      -0.0024318862706422806,
      -0.011106658726930618,
      0.03660262003540993,
      -0.04580586776137352,
      -0.009114773012697697,
      -0.04709676653146744,
      -0.008859602734446526,
      0.0405014306306839,
      -0.08959968388080597,
      0.005522263236343861,
      0.07112376391887665,
      0.07836457341909409,
      4.59646362287458e-05,
      -0.06772062927484512,
      -0.08141101151704788,
      -0.06750734150409698,
      -0.04644354060292244,
      -0.05663960054516792,
      0.07356978952884674,
      -0.05086682364344597,
      -0.015459749847650528,
      0.07986196130514145,
      0.031766146421432495,
      0.011388352140784264,
      -0.047460079193115234,
      0.03958692401647568,
      0.04841102287173271,
      -0.03518111631274223,
      0.013911006040871143,
      -0.055560141801834106,
      0.008588911034166813,
      0.041062477976083755,
      -0.02697138674557209,
      0.014186639338731766,
      -0.04501420259475708,
      -0.00037353317020460963,
      -0.04060596972703934,
      0.01754007302224636,
      0.01738205924630165,
      0.0362965352833271,
      0.014037362299859524,
      0.0542815625667572,
      -0.0031352099031209946,
      -0.016039203852415085,
      0.06782275438308716,
      -0.07173435389995575,
      0.055242035537958145,
      0.033115170896053314,
      -0.03691462427377701,
      0.044779226183891296,
      -0.04598408192396164,
      0.03254104033112526,
      0.011702362447977066,
      -0.08713121712207794,
      0.07053246349096298,
      -0.0291200689971447,
      0.021556539461016655,
      0.01851491443812847,
      -0.07709304988384247,
      0.023271838203072548,
      -0.036411963403224945,
      0.011040780693292618,
      -0.005722310859709978,
      0.06927631795406342,
      0.009483383037149906,
      -0.02519702911376953,
      0.030330052599310875,
      0.012080459855496883,
      -0.04421403631567955,
      -0.017890695482492447,
      -0.05688762292265892,
      -0.09912507236003876,
      -0.021150242537260056,
      -0.0006190734566189349,
      0.007156539708375931,
      0.01760944165289402,
      -0.009535077959299088,
      -0.004294950049370527,
      0.050436265766620636,
      0.018357768654823303,
      0.01080232672393322,
      -0.042788486927747726,
      -0.005086297169327736,
      0.0400192029774189,
      0.05836038663983345,
      -0.010235143825411797,
      0.00932934507727623,
      -0.030871260911226273,
      0.03646202012896538,
      0.0442422553896904,
      -0.04419093579053879,
      -0.039794713258743286,
      -0.025459179654717445,
      -0.026645271107554436,
      -0.00045066920574754477,
      0.032726041972637177,
      0.05547700822353363,
      -0.038572799414396286,
      -0.016476714983582497,
      -0.11233804374933243,
      -0.05225309357047081,
      0.08992735296487808,
      -0.04592161998152733,
      -0.0009204722009599209,
      0.03357924893498421,
      -0.021236823871731758,
      0.06767022609710693,
      -0.03411603346467018,
      -0.07335489243268967,
      -0.05628588795661926,
      -0.013427694328129292,
      -0.03430847451090813,
      -0.04853598400950432,
      0.11424035578966141,
      -0.036186423152685165,
      -0.033016905188560486,
      0.009308099746704102,
      0.025119135156273842,
      -0.01207805797457695,
      0.0815620869398117,
      -0.046787772327661514,
      0.057208240032196045,
      0.04760010167956352,
      0.009988266043365002,
      -0.06537831574678421,
      0.057352904230356216,
      0.008195541799068451,
      0.03761710971593857,
      0.05460408329963684,
      0.023905590176582336,
      -0.05923811346292496,
      -0.0014038063818588853,
      0.05876867100596428,
      0.0030477421823889017,
      0.05073324590921402,
      0.026798203587532043,
      -0.012020684778690338,
      0.0166646596044302,
      -0.020500728860497475,
      -0.07041897624731064,
      -0.007187882903963327,
      0.03410807251930237,
      0.0541040264070034,
      -0.03311135247349739,
      -0.033466536551713943,
      -0.009437170810997486,
      0.00782699789851904,
      -0.018957072868943214,
      -0.010921440087258816,
      -0.03362064063549042,
      0.040211766958236694,
      0.005368105135858059,
      0.011879459954798222,
      0.0011950740590691566,
      -0.034181147813797,
      0.018558742478489876,
      -0.03751327842473984,
      0.010231618769466877,
      -0.005133379716426134,
      -0.01173404324799776,
      -0.06152684986591339,
      -0.04974431172013283,
      -0.04312707483768463,
      -0.03221633657813072,
      0.04448594152927399,
      -0.07651753723621368,
      0.02640305645763874,
      -0.11000731587409973,
      0.03119489923119545,
      -0.016237620264291763,
      -0.09756569564342499,
      -0.014542113058269024,
      0.0014223310863599181,
      0.04216737672686577,
      0.03335808217525482,
      0.053980499505996704,
      -0.003934882581233978,
      0.055664628744125366,
      0.024883801117539406,
      0.007599641568958759,
      -0.0025494133587926626,
      0.02941061556339264,
      -0.13294747471809387,
      -0.019640643149614334,
      0.03179654851555824,
      -0.05421661585569382,
      0.009824440814554691,
      -0.045607615262269974,
      0.07629424333572388,
      -0.02078837715089321,
      -0.007758798077702522,
      -0.00637152511626482,
      0.06900906562805176,
      0.009231064468622208,
      -0.005310866516083479,
      -0.03436760976910591,
      -0.014337095431983471,
      0.07110621780157089,
      0.011698026210069656,
      0.04258953034877777,
      0.015726599842309952,
      0.014940711669623852,
      -0.05137602612376213,
      -0.052752282470464706,
      -0.0024474298115819693,
      0.009468227624893188,
      0.047676607966423035,
      -0.04901597648859024,
      -0.03902845084667206,
      -0.037984415888786316,
      0.0009033152018673718,
      -0.031048476696014404,
      0.014968200586736202,
      -0.059859100729227066,
      0.1036897525191307,
      -0.05299859493970871,
      -0.07695195823907852,
      -0.053107768297195435,
      -0.07069224119186401,
      -0.0031360797584056854,
      -0.05069119110703468,
      -0.054662980139255524,
      -0.01996230147778988,
      0.038001518696546555,
      0.02370491251349449,
      -0.05032510682940483,
      0.0171430092304945,
      -0.018578294664621353,
      0.005127242300659418,
      0.025640077888965607,
      0.02936461754143238,
      0.0873572826385498,
      -0.01729322411119938,
      0.04921434074640274,
      -0.04272870346903801,
      0.04210909456014633,
      0.037409473210573196,
      0.014338076114654541,
      0.010976639576256275,
      0.030512258410453796,
      -0.012889858335256577,
      -0.07121660560369492,
      -0.041863806545734406,
      0.052685149013996124,
      0.06225373595952988,
      -0.09330917149782181,
      0.012354286387562752,
      -0.02781723067164421,
      -0.030847903341054916,
      -0.030477477237582207,
      -0.03901823237538338,
      0.06208036094903946,
      0.042458053678274155,
      -0.017946641892194748,
      -0.09231427311897278,
      -0.00015298998914659023,
      -0.01740403287112713,
      -0.10962699353694916,
      -0.00798258651047945,
      -0.0223569106310606,
      0.03496052324771881,
      -0.030620049685239792,
      -0.026600215584039688
    ],
    [
      -0.00904707983136177,
      -0.03780389949679375,
      0.01723327860236168,
      -0.04251623898744583,
      -0.01585490256547928,
      -0.06461310386657715,
      0.0017032372998073697,
      0.03134112060070038,
      0.09044352173805237,
      0.013553479686379433,
      0.07427729666233063,
      0.1010250449180603,
      -0.08448843657970428,
      -0.0010024112416431308,
      0.016695944592356682,
      0.0717339739203453,
      -0.03514716401696205,
      -0.07748135924339294,
      0.03314048424363136,
      -0.05582461133599281,
      0.002313331002369523,
      -0.017873341217637062,
      0.05401018261909485,
      0.030701564624905586,
      -0.03387477621436119,
      -0.08002903312444687,
      0.1089392751455307,
      -0.014383971691131592,
      -0.03034711629152298,
      -0.007901729084551334,
      -0.06419797241687775,
      0.11049720644950867,
      0.010109273716807365,
      -0.07759849727153778,
      0.03544451296329498,
      -0.03467567265033722,
      -0.026715993881225586,
      -0.005379531532526016,
      0.032953329384326935,
      -0.03639659658074379,
      0.012667374685406685,
      0.03157011419534683,
      -0.0009411884821020067,
      -0.02151457592844963,
      -0.05276546627283096,
      -0.026303881779313087,
      -0.01894744485616684,
      0.02867545560002327,
      -0.009689083322882652,
      0.06111763045191765,
      0.04912230372428894,
      -0.06799441576004028,
      -0.04210665076971054,
      -0.07126853615045547,
      0.09922420233488083,
      0.03260190412402153,
      0.04372424632310867,
      -0.04997197166085243,
      -0.010094302706420422,
      0.046718962490558624,
      0.02452583611011505,
      -0.015546073205769062,
      -0.05384204164147377,
      -0.060930535197257996,
      0.08438295125961304,
      0.04664761573076248,
      -0.013317138887941837,
      0.0072901505045592785,
      0.028305528685450554,
      0.044895950704813004,
      0.026644423604011536,
      0.04101158306002617,
      0.03476650267839432,
      -0.014782078564167023,
      0.035382844507694244,
      0.007743190508335829,
      -0.0096018360927701,
      0.06478661298751831,
      -0.0786566510796547,
      -0.058568935841321945,
      -0.043612100183963776,
      0.0796242281794548,
      -0.010311323218047619,
      0.06408243626356125,
      -0.025364456698298454,
      -0.06104191765189171,
      0.007009520195424557,
      0.02159246616065502,
      -0.054195940494537354,
      -0.03584448620676994,
      0.019792474806308746,
      -0.019390419125556946,
      -0.02783353626728058,
      0.020960083231329918,
      -0.10262519866228104,
      -0.045129016041755676,
      0.04387106001377106,
      -9.615910676075146e-05,
      0.007265076972544193,
      -0.03485075384378433,
      0.11138594150543213,
      -0.005206199828535318,
      0.01776282861828804,
      0.0006021296721883118,
      -0.015604745596647263,
      -0.011107316240668297,
      0.016619034111499786,
      0.08074884861707687,
      -0.05060306191444397,
      -0.0006611701683141291,
      0.051631178706884384,
      -0.04623069614171982,
      0.033461060374975204,
      -0.11923205107450485,
      0.0017982306890189648,
      -0.004014559555798769,
      -0.012821012176573277,
      -0.006002920214086771,
      0.031211255118250847,
      0.04334772750735283,
      -0.06798528134822845,
      -0.0009415088570676744,
      0.0520654059946537,
      0.025446226820349693,
      0.03657134622335434,
      -0.00844171829521656,
      -0.016069643199443817,
      -0.021664941683411598,
      -0.04257957637310028,
      -0.10312933474779129,
      0.000395861454308033,
      0.0807025283575058,
      -0.0300556942820549,
      -0.012370257638394833,
      -0.07784748077392578,
      -0.015754006803035736,
      0.035923201590776443,
      0.0264867153018713,
      -0.041850510984659195,
      -0.10319515317678452,
      -0.054317425936460495,
      -0.011009291745722294,
      -0.041906826198101044,
      -0.03931767866015434,
      -0.016435133293271065,
      -0.03489261120557785,
      -0.03919496759772301,
      0.014266356825828552,
      -0.04501117393374443,
      -0.027068862691521645,
      0.015009666793048382,
      -0.03505270555615425,
      0.011831136420369148,
      0.030577106401324272,
      0.059973008930683136,
      -0.013041747733950615,
      -0.032096683979034424,
      -0.07124754041433334,
      0.0379859022796154,
      0.00048792408779263496,
      0.06748314201831818,
      0.006328286603093147,
      0.045876629650592804,
      -0.04102090373635292,
      -0.09387297928333282,
      -0.009992053732275963,
      -0.011163841001689434,
      -0.03733719885349274,
      -0.004333957564085722,
      -0.04643593356013298,
      -0.04293756186962128,
      -0.01359570026397705,
      -0.009868498891592026,
      0.045053880661726,
      -0.01769157499074936,
      -0.060864727944135666,
      0.050566840916872025,
      0.034241773188114166,
      -0.06051342934370041,
      0.04280948266386986,
      0.022168723866343498,
      -0.014058860018849373,
      -0.055158693343400955,
      -0.06037115678191185,
      0.07597452402114868,
      0.09244067221879959,
      0.03228527307510376,
      0.005267208907753229,
      -0.04034319147467613,
      -0.0882810428738594,
      0.038769807666540146,
      0.06992337852716446,
      -0.0014997568214312196,
      0.04561682417988777,
      -0.0330524668097496,
      0.05458179488778114,
      0.0368366576731205,
      0.02548637054860592,
      0.017533304169774055,
      0.007561907637864351,
      -0.019592206925153732,
      -0.04734821245074272,
      0.028664585202932358,
      0.025136301293969154,
      0.022808056324720383,
      0.07106311619281769,
      -0.03509875386953354,
      -0.06152364984154701,
      0.06807243824005127,
      -0.043417904525995255,
      0.048737287521362305,
      -0.013365216553211212,
      -0.058404844254255295,
      -0.00856543704867363,
      -0.06785552948713303,
      0.04140210896730423,
      -0.015777967870235443,
      0.05113819241523743,
      -0.022333841770887375,
      0.03599734604358673,
      0.04410841688513756,
      -0.017633745446801186,
      -0.0368187390267849,
      0.08889012038707733,
      0.029956819489598274,
      -0.058104924857616425,
      0.050617389380931854,
      -0.005705521907657385,
      0.04176674783229828,
      0.043602991849184036,
      0.11624008417129517,
      0.03956661745905876,
      -0.08867152035236359,
      0.060128115117549896,
      -0.07862546294927597,
      -0.005076135043054819,
      -0.0028782289009541273,
      -0.08820487558841705,
      -0.01037686038762331,
      0.011922328732907772,
      -0.05927888676524162,
      -0.06726469844579697,
      0.051985688507556915,
      -0.00882694497704506,
      -0.023340795189142227,
      0.053585562855005264,
      -0.020769400522112846,
      -0.025030149146914482,
      0.07019669562578201,
      0.003614322515204549,
      0.023597458377480507,
      0.0795835554599762,
      -0.019061150029301643,
      -0.055816400796175,
      -0.014725872315466404,
      0.0009590569534339011,
      0.04011977091431618,
      -0.0046115051954984665,
      0.05945930629968643,
      -0.030573194846510887,
      0.021197877824306488,
      -0.018387865275144577,
      -0.0018294192850589752,
      0.027219872921705246,
      0.12202917039394379,
      -0.016975585371255875,
      -0.14597664773464203,
      0.01769469492137432,
      0.05424013361334801,
      0.014206360094249249,
      -0.01976730115711689,
      0.016340723261237144,
      -0.056650202721357346,
      -0.010253479704260826,
      -0.09336963295936584,
      0.03444700688123703,
      -0.02569897100329399,
      -0.04753056541085243,
      -0.0014539769617840648,
      0.0751463770866394,
      -0.049620822072029114,
      0.08865614980459213,
      0.013367362320423126,
      0.007341319229453802,
      0.0014872162137180567,
      -0.02404771000146866,
      -0.016655273735523224,
      0.06118221580982208,
      0.04736022651195526,
      0.037607673555612564,
      0.07853017002344131,
      0.11861336976289749,
      -0.02849091775715351,
      0.02790345810353756,
      0.021581077948212624,
      0.008480873890221119,
      -0.06571557372808456,
      0.017164716497063637,
      -0.03695086017251015,
      0.009006299078464508,
      0.004833783954381943,
      0.028615493327379227,
      0.008349352516233921,
      -0.047591231763362885,
      -0.05054665729403496,
      0.004840482957661152,
      -0.012692539021372795,
      -0.040830858051776886,
      -0.08492548763751984,
      -0.00818489771336317,
      -0.04066583141684532,
      0.007807697635143995,
      -0.02989581972360611,
      0.025924792513251305,
      -0.011774550192058086,
      0.01158815249800682,
      -0.039144981652498245,
      0.0004886112874373794,
      0.009257025085389614,
      -0.009696864522993565,
      0.008528484031558037,
      -0.08226486295461655,
      0.016185201704502106,
      -0.023983648046851158,
      0.03015453740954399,
      0.023366134613752365,
      0.06017205864191055,
      0.08233354240655899,
      -0.0050552114844322205,
      0.034149911254644394,
      -0.08243200182914734,
      -0.02848631702363491,
      0.013630456291139126,
      -0.0025989736896008253,
      -0.026939839124679565,
      0.06396356970071793,
      0.08466318994760513,
      0.006813520565629005,
      0.08045296370983124,
      0.021294374018907547,
      -0.007958730682730675,
      0.09154598414897919,
      -0.031918227672576904,
      -0.012559780851006508,
      0.04228780046105385,
      0.0077139283530414104,
      0.06409186869859695,
      -0.018499083817005157,
      -0.03831375390291214,
      0.04089919477701187,
      -0.07166692614555359,
      0.060747284442186356,
      0.049491725862026215,
      -0.02217639423906803,
      -0.013803811743855476,
      -0.024264918640255928,
      -0.017370330169796944,
      0.06398364901542664,
      0.11085660010576248,
      -0.007235131226480007,
      0.004727036226540804,
      0.0006534683052450418,
      0.06547602266073227,
      0.05662825331091881,
      0.0013027924578636885,
      0.0097815478220582,
      0.021577689796686172,
      -0.07768440991640091,
      0.04419920966029167,
      -0.027140162885189056,
      -0.017264558002352715,
      0.018848422914743423,
      0.02893030270934105,
      -0.02145450748503208,
      -0.04451477527618408,
      -0.002450026571750641,
      0.0023107363376766443,
      0.10747333616018295,
      -0.09032326936721802,
      -0.03595784306526184,
      -0.009713388048112392,
      0.009637192822992802,
      -0.0033026812598109245,
      0.05511986464262009,
      -0.05428224429488182,
      0.05871012434363365,
      0.04549787566065788,
      -0.04770752042531967,
      0.05811331421136856,
      -0.024543708190321922,
      0.02942042611539364,
      -0.030595198273658752,
      0.032884202897548676,
      -0.07827961444854736,
      0.009755667299032211,
      -0.041589245200157166,
      0.10412315279245377,
      0.025905366986989975,
      -0.06271740794181824,
      -0.025298012420535088,
      -0.02176070213317871,
      0.00846478994935751,
      -0.08123137801885605,
      -0.04195384308695793,
      -0.028723984956741333,
      -0.030156223103404045,
      0.004443405196070671,
      -0.05489392951130867,
      0.08031255006790161,
      -0.0035045542754232883,
      0.0219002366065979,
      0.031198348850011826,
      0.06846735626459122,
      -0.011339590884745121,
      0.01320258341729641,
      0.03149851784110069,
      0.024756932631134987,
      -0.1531880497932434,
      -0.010101991705596447,
      -0.007885413244366646,
      0.013306635431945324,
      -0.018492694944143295,
      -0.0017902023391798139,
      0.10134909301996231,
      0.012066341936588287,
      0.018268374726176262,
      -0.02374318614602089,
      -0.04082919657230377,
      -0.0027978788129985332,
      -0.023418797180056572,
      0.05574492737650871,
      0.08027064055204391,
      -0.04437655955553055,
      0.009731235913932323,
      0.06286685168743134,
      -0.044569555670022964,
      -0.04635888338088989,
      -0.029348094016313553,
      0.04927618429064751,
      -0.014908586628735065,
      0.031244415789842606,
      -0.042875874787569046,
      -0.02337094582617283,
      -0.020479846745729446,
      0.04137293994426727,
      0.04115580394864082,
      -0.015687396749854088,
      0.0036253859288990498,
      -0.03144465759396553,
      -0.028007492423057556,
      -0.007234626449644566,
      -0.0011268800590187311,
      -0.03287019953131676,
      0.05532285198569298,
      0.08980952203273773,
      -0.0012756985379382968,
      -0.07137235999107361,
      0.05113145709037781,
      0.053609639406204224,
      0.05308244377374649,
      0.03845230117440224,
      0.01471776980906725,
      0.025096984580159187,
      0.03046521544456482,
      -0.03732342645525932,
      0.011012506671249866,
      -0.046746715903282166,
      -0.013707487843930721,
      0.020594364032149315,
      0.05128452181816101,
      -0.08595024794340134,
      -0.014350024051964283,
      0.024245860055088997,
      -0.0011277318699285388,
      0.01906355656683445,
      0.016147851943969727,
      0.03215832635760307,
      0.07384531199932098,
      -0.020112331956624985,
      0.03814803808927536,
      0.04965982958674431,
      -0.012431167997419834,
      -0.05459338799118996,
      0.05141344666481018,
      -0.06096171215176582,
      -0.008363653905689716,
      0.00602917093783617,
      -0.01889912597835064,
      0.055356115102767944,
      0.05661613494157791,
      -0.026118582114577293,
      -0.06726692616939545,
      0.036038052290678024,
      -0.043111223727464676,
      -0.06923959404230118,
      -0.006566950585693121,
      -0.00040973289287649095,
      -0.020993061363697052,
      -0.0389239601790905,
      -0.07474759221076965,
      0.01558943185955286,
      0.04465550184249878,
      -0.05170163884758949
    ],
    [
      -0.04843053221702576,
      0.03376896679401398,
      0.040411531925201416,
      -0.04620201140642166,
      -0.01830279640853405,
      0.019649500027298927,
      -0.06141199171543121,
      0.0029097816441208124,
      0.05066612735390663,
      -0.044058818370103836,
      -0.03533858433365822,
      -0.04948179051280022,
      0.08520428836345673,
      0.016778957098722458,
      0.004488821141421795,
      -0.07148802280426025,
      0.01642083004117012,
      -0.0935700386762619,
      0.011159720830619335,
      0.020863624289631844,
      -0.038978222757577896,
      -0.058124102652072906,
      0.02611980400979519,
      -0.008353950455784798,
      -0.02029799297451973,
      -0.0026891015004366636,
      -0.025896277278661728,
      -0.02579130046069622,
      -0.011812382377684116,
      -0.03458995372056961,
      0.008701809681952,
      0.06371652334928513,
      -0.013472745195031166,
      -0.02323472499847412,
      0.0013986469712108374,
      0.043587345629930496,
      -0.02162308432161808,
      0.02700648456811905,
      -0.007674372289329767,
      -0.12125682830810547,
      0.05052374303340912,
      0.06923502683639526,
      -0.0001735676487442106,
      -0.07118958979845047,
      0.03554287180304527,
      0.017481325194239616,
      -0.023362504318356514,
      -0.019083255901932716,
      -0.035214200615882874,
      0.016444215551018715,
      0.01886909082531929,
      0.021015174686908722,
      0.0007297155680134892,
      -0.040829725563526154,
      0.009317868389189243,
      0.02759234420955181,
      -0.09750781953334808,
      0.05935627967119217,
      0.016829978674650192,
      0.04678119719028473,
      0.036824095994234085,
      0.03460462763905525,
      0.032848555594682693,
      0.003900705138221383,
      -0.042704273015260696,
      -0.05637698993086815,
      -0.03585771098732948,
      -0.012218570336699486,
      0.028540363535284996,
      -0.0626891702413559,
      0.01761777326464653,
      0.022619398310780525,
      0.008832783438265324,
      -0.008527993224561214,
      -0.04032766446471214,
      0.017651354894042015,
      0.060923945158720016,
      0.009533233940601349,
      0.023389233276247978,
      -0.02860046550631523,
      0.009371940977871418,
      -0.012405338697135448,
      0.10159224271774292,
      0.0731286108493805,
      -0.05502525344491005,
      0.014432626776397228,
      0.025666458532214165,
      -0.010674931108951569,
      -0.00332359760068357,
      -0.13837982714176178,
      -0.09034170210361481,
      -0.013897743076086044,
      -0.03363841399550438,
      -0.02445145510137081,
      -0.07742616534233093,
      -0.012742554768919945,
      0.039876796305179596,
      0.08854559808969498,
      0.032560862600803375,
      -0.054383937269449234,
      0.02713610976934433,
      0.026162108406424522,
      -0.0070685725659132,
      -0.017484191805124283,
      -0.07860416173934937,
      0.012101106345653534,
      -0.02368633821606636,
      0.01093582808971405,
      -0.04506432265043259,
      0.08483917266130447,
      -0.08918765932321548,
      -0.0344557985663414,
      -0.015644636005163193,
      0.0379624143242836,
      -0.011550812982022762,
      -0.03610029071569443,
      0.011843007057905197,
      -0.03954479470849037,
      -0.0044107744470238686,
      -0.027948616072535515,
      -0.030238240957260132,
      -0.04068896919488907,
      -0.028641000390052795,
      0.034021131694316864,
      -0.05804014578461647,
      0.01091726217418909,
      0.02933807112276554,
      0.01547711156308651,
      0.09072110056877136,
      -0.04364677518606186,
      0.07094705104827881,
      -0.01160428300499916,
      -0.008978418074548244,
      0.06554380059242249,
      -0.007552457042038441,
      0.008850079029798508,
      -0.03412729501724243,
      -0.12411954253911972,
      -0.0007192487246356905,
      -0.021312206983566284,
      0.03995304927229881,
      0.058241162449121475,
      0.02128751575946808,
      -0.09222356230020523,
      -0.025504095479846,
      -0.0932430550456047,
      0.10339905321598053,
      -0.14260852336883545,
      0.07348313182592392,
      0.004310980439186096,
      0.0630941390991211,
      -0.04075268283486366,
      0.014806277118623257,
      0.021973399445414543,
      0.006290004588663578,
      -0.0679466500878334,
      0.0379486083984375,
      0.055738069117069244,
      0.026498986408114433,
      0.012832517735660076,
      0.007724015973508358,
      -0.002804927993565798,
      0.007115224376320839,
      -0.10556457191705704,
      -0.012041894719004631,
      -0.030046433210372925,
      -0.03491930291056633,
      -0.02341478317975998,
      0.01160984206944704,
      -0.04828314483165741,
      0.10561182349920273,
      -0.055593810975551605,
      -0.005170156247913837,
      0.014331143349409103,
      -0.0396035797894001,
      -0.0030425661243498325,
      0.009825149551033974,
      0.004653010983020067,
      -0.08156275004148483,
      0.04650941491127014,
      -0.023103339597582817,
      0.03132897987961769,
      0.022491678595542908,
      -0.04423843324184418,
      -0.0299545731395483,
      0.013512742705643177,
      0.07719515264034271,
      0.020960615947842598,
      -0.02219339832663536,
      -0.0005900926771573722,
      -0.041193637996912,
      -0.06684767454862595,
      0.052482470870018005,
      -0.028519583866000175,
      -0.010945675894618034,
      -0.03620302677154541,
      0.03979485109448433,
      -0.006454247981309891,
      0.08063964545726776,
      0.10699640214443207,
      -0.023568270727992058,
      -0.030474552884697914,
      0.0677788183093071,
      0.0021463946904987097,
      0.026960063725709915,
      -0.008836936205625534,
      -0.064712755382061,
      0.005232705734670162,
      0.02110610343515873,
      -0.10901553928852081,
      -0.009546193294227123,
      -0.05708402022719383,
      0.08633223921060562,
      -0.12097619473934174,
      0.02231024205684662,
      0.030701471492648125,
      -0.05084105208516121,
      0.07649585604667664,
      -0.08955588191747665,
      -0.005779927596449852,
      0.06635663658380508,
      -0.02626228518784046,
      -0.002100759418681264,
      0.03282900154590607,
      0.0017522338312119246,
      -0.0006449749926105142,
      0.004185542464256287,
      0.02060834690928459,
      0.005158389452844858,
      -0.02628347836434841,
      -0.02270444855093956,
      0.009771405719220638,
      0.05171285569667816,
      0.08042486011981964,
      -0.025341197848320007,
      0.09477099031209946,
      0.025816217064857483,
      -0.03645322844386101,
      0.0899706557393074,
      -0.01833166740834713,
      -0.005385300610214472,
      0.06598681211471558,
      0.034461796283721924,
      0.045686986297369,
      -0.11773551255464554,
      0.04013589397072792,
      0.06619377434253693,
      0.0133479293435812,
      0.002366709755733609,
      -0.05091981217265129,
      -0.04903776943683624,
      0.09365028887987137,
      0.08121515065431595,
      -0.0012103572953492403,
      -0.02806408330798149,
      -0.02028825134038925,
      -0.007253441493958235,
      0.023802079260349274,
      -0.014124821871519089,
      0.004786909557878971,
      -0.07871650159358978,
      0.08917739987373352,
      -0.01832250878214836,
      0.00018619296315591782,
      0.03000100888311863,
      -0.00895198155194521,
      -0.02541857585310936,
      -0.03335080295801163,
      -0.048375386744737625,
      -0.00915976520627737,
      0.015309099107980728,
      0.07346903532743454,
      0.013188809156417847,
      0.025804713368415833,
      0.04836423322558403,
      -0.04180208221077919,
      -0.05445602536201477,
      -0.007500132080167532,
      0.01466565765440464,
      0.07028630375862122,
      0.0569583959877491,
      0.0014174369862303138,
      -0.0681341290473938,
      -0.0653659775853157,
      0.022343210875988007,
      -0.10846980661153793,
      0.01111691351979971,
      -0.00013561466766986996,
      -0.08806513994932175,
      -0.04955929145216942,
      0.055184606462717056,
      -0.0142214922234416,
      0.04229521006345749,
      0.0641295537352562,
      -0.03866628184914589,
      0.03213806450366974,
      0.0034924144856631756,
      0.011735682375729084,
      -0.050267647951841354,
      0.021353069692850113,
      -0.020121922716498375,
      0.03759637475013733,
      0.044539857655763626,
      0.013638155534863472,
      -0.06851406395435333,
      0.003286169609054923,
      -0.043542370200157166,
      0.03340200334787369,
      0.00936385989189148,
      -0.013002672232687473,
      0.04806509613990784,
      -0.011276436038315296,
      -0.037061747163534164,
      0.0002528418553993106,
      0.037678804248571396,
      -0.04634580388665199,
      0.015278436243534088,
      0.042097169905900955,
      -0.12365926802158356,
      0.018725834786891937,
      0.002765849232673645,
      0.011054660193622112,
      -0.044894613325595856,
      0.017223259434103966,
      -0.052575431764125824,
      0.05057937651872635,
      -0.08250833302736282,
      -0.020623760297894478,
      0.0495232418179512,
      0.07136504352092743,
      0.02481047436594963,
      -0.004612462129443884,
      0.026199910789728165,
      0.009808538481593132,
      0.012692173011600971,
      -0.03838370740413666,
      0.00451214425265789,
      -0.017463643103837967,
      -0.03944144397974014,
      0.01935829408466816,
      -0.022176174446940422,
      0.03851376101374626,
      0.055742956697940826,
      -0.07821027934551239,
      -0.009199588559567928,
      0.025036392733454704,
      0.005275320261716843,
      0.0019235319923609495,
      -0.018207287415862083,
      0.03504123538732529,
      0.03232371807098389,
      -0.01835961826145649,
      -0.03815775737166405,
      0.025808248668909073,
      0.018319394439458847,
      -0.011762629263103008,
      0.07920517027378082,
      0.07377880066633224,
      0.01592996157705784,
      0.023975495249032974,
      0.025837428867816925,
      0.005467584356665611,
      -0.07152368873357773,
      0.08584523946046829,
      -0.06455861777067184,
      0.016940295696258545,
      -0.13297110795974731,
      0.15329408645629883,
      -0.03864317759871483,
      -0.002367041539400816,
      -0.1016974076628685,
      0.0415106825530529,
      0.0028891591355204582,
      0.018712781369686127,
      -0.007819403894245625,
      0.08692824095487595,
      0.0395040363073349,
      0.04283652827143669,
      -0.04884753003716469,
      0.07720228284597397,
      0.008654303848743439,
      -0.07484360039234161,
      -0.01369212195277214,
      0.0030634982977062464,
      -0.05933648720383644,
      0.03790511563420296,
      0.015240107662975788,
      0.045579466968774796,
      0.0017980436095967889,
      -0.012132697738707066,
      -0.040851421654224396,
      0.09121030569076538,
      0.040694404393434525,
      -0.04148062318563461,
      -0.04359680041670799,
      0.011873871088027954,
      -0.011285791173577309,
      -0.02278200350701809,
      -0.04656033217906952,
      -0.013673609122633934,
      -0.0018641462083905935,
      -0.021828409284353256,
      -0.039417434483766556,
      0.0012373696081340313,
      0.04330175742506981,
      -0.04300406575202942,
      0.062480807304382324,
      -0.03244854137301445,
      0.02433001436293125,
      -0.0631955936551094,
      0.078256756067276,
      -0.020333776250481606,
      -0.034927818924188614,
      -0.0348309688270092,
      -0.0037665811832994223,
      -0.06571841239929199,
      0.04857790470123291,
      -0.007694588042795658,
      0.008674794808030128,
      -0.02852320857346058,
      -0.10066589713096619,
      -0.12485203891992569,
      0.03511783480644226,
      0.019382627680897713,
      -0.027508731931447983,
      0.002615264616906643,
      0.025558162480592728,
      0.033615842461586,
      -0.10915505141019821,
      0.013939541764557362,
      -0.002698065945878625,
      0.11781641840934753,
      -0.056054916232824326,
      -0.03920591622591019,
      0.02209835685789585,
      0.009194774553179741,
      -0.023719217628240585,
      -0.025352291762828827,
      0.005161589477211237,
      0.046382613480091095,
      0.03737439215183258,
      0.01580471731722355,
      0.012111461721360683,
      0.02994915284216404,
      -0.05009255185723305,
      0.040951862931251526,
      0.007468733470886946,
      -0.006874601356685162,
      -0.072842538356781,
      0.05827140808105469,
      0.012301856651902199,
      0.040413253009319305,
      0.055170413106679916,
      0.028627552092075348,
      0.006397071294486523,
      -0.016479404643177986,
      0.06551691889762878,
      0.09841278195381165,
      -0.08383302390575409,
      0.10856971889734268,
      -0.05362365022301674,
      0.03160081058740616,
      0.010435491800308228,
      -0.027966132387518883,
      0.04801025986671448,
      0.010441902093589306,
      -0.014394903555512428,
      0.05860923230648041,
      -0.03741440176963806,
      0.004798543639481068,
      0.009589264169335365,
      -0.010755692608654499,
      0.023048482835292816,
      0.04755999892950058,
      0.004949028603732586,
      -0.07732272893190384,
      -0.023922452703118324,
      0.008150354027748108,
      -0.05266423150897026,
      0.005348674952983856,
      -0.03421953320503235,
      0.026645932346582413,
      0.07258035242557526,
      0.0595686212182045,
      -0.09551455080509186,
      -0.01676260121166706,
      0.02155745029449463,
      -0.03052692487835884,
      0.010150294750928879,
      0.002303923014551401,
      0.004888750612735748,
      0.01968914270401001,
      -0.026035616174340248,
      -0.00041918386705219746,
      -0.06201741471886635,
      0.044121962040662766,
      0.0036502755247056484,
      -0.020440708845853806,
      -0.08819732069969177,
      0.036907147616147995,
      0.007102322764694691,
      0.016211170703172684,
      -0.09709891676902771
    ],
    [
      -0.029091881588101387,
      0.02885122410953045,
      -0.01671312004327774,
      -0.012702559120953083,
      0.029405752196907997,
      -0.013338065706193447,
      -0.010160505771636963,
      0.07043188810348511,
      0.029452616348862648,
      0.015091649256646633,
      0.05482380464673042,
      -0.005842359736561775,
      -0.040800757706165314,
      -0.015196284279227257,
      -0.04424954950809479,
      0.057073548436164856,
      -0.02256879396736622,
      0.03760141879320145,
      -0.023744145408272743,
      -0.01784730702638626,
      0.08556631207466125,
      0.00298028951510787,
      -0.0005693428684026003,
      0.10974753648042679,
      0.04731692373752594,
      0.005610824562609196,
      0.006262755487114191,
      0.06476251780986786,
      -0.03548182174563408,
      -0.02324804477393627,
      -0.05413740500807762,
      -0.02425415627658367,
      0.10006185621023178,
      -0.00023699675512034446,
      0.04256390780210495,
      0.048291247338056564,
      -0.05828039348125458,
      -0.0517280213534832,
      -0.00645418418571353,
      0.02235637605190277,
      -0.03330706059932709,
      -0.008333914913237095,
      0.03410714864730835,
      0.00769792590290308,
      0.024567674845457077,
      -0.06606999784708023,
      -0.09252017736434937,
      -0.06405439972877502,
      0.03099990449845791,
      -0.040404390543699265,
      -0.04335717111825943,
      0.013449510559439659,
      0.052362650632858276,
      0.021628741174936295,
      -0.05470511317253113,
      0.04894530400633812,
      -0.015628522261977196,
      -0.04557638615369797,
      -0.0873722955584526,
      -0.031194377690553665,
      0.0015683163655921817,
      0.055457912385463715,
      -0.0017851596930995584,
      -0.06854213029146194,
      0.01205816213041544,
      -0.027275526896119118,
      0.04485141113400459,
      0.014953890815377235,
      -0.06178967282176018,
      0.012418103404343128,
      0.0318555049598217,
      0.05488764867186546,
      0.035070717334747314,
      -0.04828260466456413,
      -0.04739401116967201,
      -0.030154183506965637,
      -0.005583676975220442,
      -0.08296004682779312,
      -0.036467310041189194,
      -0.00740049546584487,
      -0.04819023609161377,
      -0.031073350459337234,
      -0.06317953020334244,
      0.08829368650913239,
      0.019518014043569565,
      -0.05704859644174576,
      0.008712870068848133,
      -0.03299178183078766,
      0.03412451595067978,
      0.019309062510728836,
      -0.07963913679122925,
      -0.018573468551039696,
      -0.06200043857097626,
      -0.004991814959794283,
      -0.06218875199556351,
      0.015691455453634262,
      0.05896201357245445,
      0.03254503384232521,
      -0.07615335285663605,
      -0.017033230513334274,
      -0.012122507207095623,
      -0.02777552604675293,
      -0.011519866064190865,
      0.03299036994576454,
      -0.04236361384391785,
      0.09915334731340408,
      0.02843373641371727,
      -0.019294649362564087,
      -0.04736103489995003,
      0.04065261036157608,
      -0.00757083622738719,
      -0.04619253799319267,
      0.0609532967209816,
      0.00208584894426167,
      0.003562979632988572,
      -0.07414320856332779,
      -0.013021424412727356,
      -0.042503051459789276,
      0.05786614492535591,
      0.005387056153267622,
      -0.08061372488737106,
      -0.007287082727998495,
      -0.016318894922733307,
      0.06511163711547852,
      0.0486273467540741,
      0.009426216594874859,
      0.10849274694919586,
      0.015399673022329807,
      -0.024223793298006058,
      -0.05128539726138115,
      -0.02989715151488781,
      -0.007527728099375963,
      -0.018014905974268913,
      -0.016755664721131325,
      0.05925755575299263,
      0.05876176059246063,
      0.023318924009799957,
      0.055905237793922424,
      -0.08537477999925613,
      0.01894221641123295,
      -0.006606098730117083,
      -0.02818935178220272,
      -0.008856999687850475,
      -0.04506473243236542,
      -0.05994495004415512,
      -0.01709704101085663,
      -0.012554123997688293,
      0.0031017386354506016,
      0.003250088542699814,
      0.02745085209608078,
      0.028830476105213165,
      -0.05666991323232651,
      -0.04269862920045853,
      -0.03591069579124451,
      0.030908595770597458,
      -0.10160290449857712,
      -0.035959240049123764,
      -0.07672600448131561,
      -0.04110736772418022,
      -0.06469513475894928,
      -0.005109247285872698,
      -0.03747650608420372,
      -0.12884965538978577,
      0.014187823049724102,
      0.044322505593299866,
      -0.002348835114389658,
      0.012869780883193016,
      0.012750501744449139,
      -0.04134361445903778,
      0.00027220664196647704,
      -0.04502662271261215,
      -0.12080242484807968,
      -0.08202961832284927,
      -0.008194172754883766,
      -0.0032331969123333693,
      -0.09267669916152954,
      0.02182060480117798,
      0.03634941950440407,
      -0.023761149495840073,
      0.06897886097431183,
      -0.03324199467897415,
      -0.04580008238554001,
      0.02488648146390915,
      0.022871797904372215,
      -0.03972877562046051,
      -0.05026668682694435,
      -0.05667601153254509,
      0.03925659507513046,
      0.02118205465376377,
      0.06085905432701111,
      -0.05315496399998665,
      -0.064534991979599,
      -0.00463684368878603,
      -0.07426631450653076,
      -0.041588444262742996,
      0.0411960706114769,
      0.006577619351446629,
      -0.0411885567009449,
      -0.06912277638912201,
      0.07183926552534103,
      0.040760818868875504,
      -0.0172683447599411,
      0.07298889756202698,
      0.06996313482522964,
      0.04264787212014198,
      -0.06950303167104721,
      0.016438104212284088,
      0.05166095122694969,
      0.024128390476107597,
      -0.04911966994404793,
      0.015080604702234268,
      0.019273823127150536,
      0.01714218221604824,
      -0.0423969067633152,
      -0.031269270926713943,
      0.010766041465103626,
      -0.03211187571287155,
      0.08273913711309433,
      0.003604366211220622,
      0.0183872077614069,
      0.010172801092267036,
      -0.010623866692185402,
      -0.013211401179432869,
      0.004980791360139847,
      0.0660141333937645,
      0.03953023999929428,
      0.021410716697573662,
      0.06346602737903595,
      0.07588820159435272,
      0.005042554344981909,
      -0.009533639997243881,
      0.015094753354787827,
      -0.02941417135298252,
      0.07261445373296738,
      -0.0814530998468399,
      0.08984316140413284,
      0.13341079652309418,
      0.014467854052782059,
      0.05901014804840088,
      0.026711491867899895,
      -0.0653187707066536,
      0.002884599147364497,
      0.025594748556613922,
      -0.010919949971139431,
      -0.039488352835178375,
      0.06638126820325851,
      0.04281575605273247,
      0.02701706625521183,
      0.03562235087156296,
      0.04574219137430191,
      -0.0597318597137928,
      0.08454354852437973,
      0.005755043122917414,
      0.07664154469966888,
      -0.08260950446128845,
      -0.03483178839087486,
      0.07087463140487671,
      -0.013509617187082767,
      0.02807133086025715,
      -0.05128199979662895,
      -0.03543313592672348,
      0.05150693282485008,
      0.03991521894931793,
      0.009543098509311676,
      -0.00480023305863142,
      0.005984227638691664,
      -0.00019309310300741345,
      0.06548087298870087,
      0.021665602922439575,
      0.03073117882013321,
      0.00653262622654438,
      0.07449323683977127,
      -0.006377095356583595,
      -0.07815912365913391,
      0.016434891149401665,
      -0.05347374826669693,
      -0.02755545824766159,
      -0.0272658783942461,
      0.08910337090492249,
      0.005822683218866587,
      -0.0374210923910141,
      -0.027721086516976357,
      -0.022807054221630096,
      -0.01889922097325325,
      0.03989469259977341,
      -0.03373788297176361,
      0.02927597239613533,
      0.00071526545798406,
      0.03052004799246788,
      -0.068449966609478,
      0.046269528567790985,
      0.07752450555562973,
      -0.038317326456308365,
      0.0215806532651186,
      0.008920282125473022,
      -0.008538175374269485,
      -0.021991120651364326,
      -0.05931795760989189,
      0.10073099285364151,
      0.013305315747857094,
      0.013423535041511059,
      0.019422320649027824,
      0.0008169093052856624,
      0.019259251654148102,
      -0.09361414611339569,
      -0.011018040589988232,
      -0.053772639483213425,
      0.008444470353424549,
      -0.03140817955136299,
      0.13243164122104645,
      -0.02110745757818222,
      -0.013772733509540558,
      0.008093807846307755,
      -0.04932267591357231,
      -0.04713517799973488,
      0.02142411470413208,
      -0.02098262682557106,
      -0.045172255486249924,
      0.016042262315750122,
      -0.0002537445107009262,
      -0.16200768947601318,
      -0.09934469312429428,
      -0.012310998514294624,
      0.02556130662560463,
      0.06248515471816063,
      0.003952391911298037,
      0.01191680133342743,
      0.018379608169198036,
      0.037196576595306396,
      0.058650873601436615,
      -0.0784013643860817,
      -0.05866491049528122,
      0.01956993341445923,
      0.004560810513794422,
      -0.032899875193834305,
      0.04429081827402115,
      -0.03630386292934418,
      -0.060984667390584946,
      0.03692738339304924,
      -0.012990945018827915,
      -0.03407193720340729,
      -0.07745201140642166,
      -0.013619462959468365,
      -0.007913626730442047,
      0.07348106056451797,
      0.06122327595949173,
      0.062330543994903564,
      0.0036819721572101116,
      -0.007569810841232538,
      0.037357039749622345,
      -0.03179153800010681,
      0.0007416877779178321,
      0.016181133687496185,
      -0.05935229733586311,
      0.032950565218925476,
      -0.012321049347519875,
      -0.05556068941950798,
      0.03354183956980705,
      0.022914549335837364,
      0.0656171441078186,
      0.008257324807345867,
      -0.010456674732267857,
      0.05540512874722481,
      -0.020858582109212875,
      0.05173817649483681,
      -0.015609418042004108,
      -0.028455955907702446,
      0.07093954086303711,
      -0.031754594296216965,
      -0.0055870069190859795,
      -0.007376378867775202,
      -0.030201096087694168,
      -0.01649075374007225,
      -0.020035861060023308,
      0.021137813106179237,
      0.0961478129029274,
      0.03396880626678467,
      0.06363775581121445,
      -0.02752256765961647,
      -0.07117801159620285,
      -0.019088422879576683,
      -0.016564521938562393,
      -0.04540013149380684,
      0.011853470467031002,
      0.015366767533123493,
      0.011944519355893135,
      0.050634320825338364,
      0.027560068294405937,
      -0.013509002514183521,
      -0.034143511205911636,
      -0.06599870324134827,
      0.07678980380296707,
      -0.07411529123783112,
      -0.049140263348817825,
      0.08199413865804672,
      -0.007253853138536215,
      0.00801809597760439,
      0.017286177724599838,
      -0.045241858810186386,
      -0.04066763445734978,
      0.013367331586778164,
      -0.04921939969062805,
      0.027194391936063766,
      0.005555600393563509,
      0.08932138234376907,
      0.12415952980518341,
      0.004521534312516451,
      -0.1053793877363205,
      0.06590069085359573,
      0.04384186863899231,
      0.04561890289187431,
      0.1030292883515358,
      0.035656675696372986,
      -0.047716815024614334,
      -0.0007584353443235159,
      0.03005104884505272,
      0.026888761669397354,
      -0.029113251715898514,
      -0.0806218758225441,
      -0.050999537110328674,
      -0.04269027337431908,
      0.049448732286691666,
      -0.020676949992775917,
      -0.044235508888959885,
      -0.03840957209467888,
      -0.08156288415193558,
      0.023374401032924652,
      0.02011275663971901,
      0.0904935747385025,
      0.07025536894798279,
      0.0170583575963974,
      0.004708721302449703,
      -0.07612667232751846,
      -0.019318977370858192,
      0.04685749113559723,
      -0.059862446039915085,
      -0.0919119194149971,
      0.01667124778032303,
      0.009472462348639965,
      0.01793161779642105,
      -0.05250367522239685,
      -0.01830929145216942,
      -0.027048856019973755,
      -0.014186237938702106,
      -0.0019623758271336555,
      0.029346657916903496,
      0.09189284592866898,
      0.11407024413347244,
      0.11711763590574265,
      0.004406720399856567,
      0.007855510339140892,
      0.07909108698368073,
      0.0012310172896832228,
      -0.05944450944662094,
      -0.0036020490806549788,
      -0.00019211524340789765,
      -0.06985028088092804,
      0.01690077781677246,
      -0.02134489268064499,
      7.531133451266214e-05,
      0.017326582223176956,
      -0.007126229349523783,
      0.0727284699678421,
      0.07581647485494614,
      -0.01842581294476986,
      0.0005054482026025653,
      -0.008374756202101707,
      -0.07676749676465988,
      0.0038504484109580517,
      -0.0520196296274662,
      0.036768827587366104,
      -0.004806193523108959,
      0.0310245119035244,
      -0.0390135794878006,
      0.04106418788433075,
      -0.005315378308296204,
      0.046989813446998596,
      0.010412647388875484,
      -0.009255625307559967,
      -0.052430011332035065,
      -0.004440650809556246,
      0.0383550301194191,
      0.10470140725374222,
      0.040527183562517166,
      -0.012736913748085499,
      -0.008715208619832993,
      0.009171554818749428,
      0.017540346831083298,
      0.025727510452270508,
      0.027434492483735085,
      0.006883787922561169,
      -0.06408163160085678,
      -0.0017834649188444018,
      0.007662298623472452,
      -0.0233517587184906,
      0.01907534711062908,
      0.018834292888641357,
      -0.03589675948023796,
      0.016060756519436836,
      -0.0016020236071199179,
      -0.06919568032026291,
      -0.029940228909254074,
      -0.08089862763881683
    ],
    [
      0.010236824862658978,
      -0.0002893875353038311,
      -0.003722338005900383,
      0.001061891787685454,
      -0.00368156167678535,
      -0.06089271977543831,
      -0.06139340624213219,
      -0.05584215745329857,
      0.008039155043661594,
      -0.03823564574122429,
      -0.03483122959733009,
      -0.08785045146942139,
      0.025652896612882614,
      -0.05136936157941818,
      -0.013155240565538406,
      -0.02560272626578808,
      -0.06756950169801712,
      -0.04502851888537407,
      0.05023951828479767,
      -0.0037953404244035482,
      -0.03983168303966522,
      0.016688864678144455,
      -0.0683707445859909,
      0.04251483827829361,
      0.011852540075778961,
      0.03718855232000351,
      0.05353012681007385,
      0.005845631007105112,
      0.008840956725180149,
      0.04055766761302948,
      -0.013033078983426094,
      0.03503381088376045,
      -0.014761867932975292,
      -0.0066274311393499374,
      0.05883106589317322,
      0.06464043259620667,
      -0.015833301469683647,
      0.08086616545915604,
      0.05890645086765289,
      -0.12434682250022888,
      -0.001880705589428544,
      -0.055585917085409164,
      -0.016789818182587624,
      -0.0319528728723526,
      -0.03268640488386154,
      -0.03568779304623604,
      -0.062317878007888794,
      0.011716528795659542,
      0.0039806668646633625,
      -0.04291008412837982,
      -0.009325306862592697,
      -0.007832314819097519,
      0.00493899779394269,
      0.03012792021036148,
      -0.022327644750475883,
      -0.10525179654359818,
      -0.031032605096697807,
      -0.0044690403155982494,
      0.01655023731291294,
      -0.03253833204507828,
      0.08451680839061737,
      0.0403624027967453,
      -0.01749531552195549,
      0.06756956875324249,
      -0.02424495480954647,
      -0.0376429446041584,
      0.01749683916568756,
      0.029694324359297752,
      0.03448837250471115,
      0.02116980031132698,
      -0.09024631232023239,
      0.06806761771440506,
      0.00117253337521106,
      -0.06248565390706062,
      -0.03421427309513092,
      -0.028900790959596634,
      0.020604131743311882,
      0.1298999935388565,
      -0.08516813069581985,
      -0.006305772811174393,
      0.034820351749658585,
      -0.013636421412229538,
      -0.11351792514324188,
      0.004901496693491936,
      0.024405864998698235,
      0.06384555250406265,
      -0.04162485897541046,
      -0.05335800349712372,
      0.03048824891448021,
      0.025951899588108063,
      -0.10081306099891663,
      -0.006083150859922171,
      -0.04456936940550804,
      -0.06693023443222046,
      -0.0635983869433403,
      -0.027728118002414703,
      0.00872106198221445,
      -0.004026256967335939,
      0.06430761516094208,
      0.051129236817359924,
      -0.011851906776428223,
      -0.04039783775806427,
      0.022929366677999496,
      0.0785500705242157,
      -0.07508870214223862,
      0.07137080281972885,
      -0.0022700107656419277,
      0.02298516407608986,
      0.02748783864080906,
      -0.034595176577568054,
      -0.0014255531132221222,
      -0.08656031638383865,
      0.03887806087732315,
      -0.005723395384848118,
      0.034496210515499115,
      -0.05646795034408569,
      0.031172094866633415,
      0.07662231475114822,
      -0.07641048729419708,
      -0.013677136041224003,
      -0.017640246078372,
      0.03379551321268082,
      -0.02087366208434105,
      0.07091318815946579,
      -0.013086507096886635,
      -0.02247481793165207,
      0.161747545003891,
      -0.0004690307250712067,
      0.07913731038570404,
      0.005053987260907888,
      0.008012698963284492,
      0.027930859476327896,
      0.02356022596359253,
      -0.0704004168510437,
      0.06133851781487465,
      0.01650134101510048,
      0.04878820851445198,
      -0.04701446741819382,
      0.02886679396033287,
      -0.018128685653209686,
      0.01685902662575245,
      -0.020916923880577087,
      0.00022804824402555823,
      0.015240184031426907,
      -0.050360552966594696,
      -0.007149175740778446,
      0.032196044921875,
      0.022776078432798386,
      0.07956821471452713,
      -0.04569999501109123,
      -0.061396025121212006,
      -0.0015037691919133067,
      0.022644005715847015,
      0.018016543239355087,
      0.01832083985209465,
      0.011775392107665539,
      0.015632744878530502,
      0.06803539395332336,
      -0.06496528536081314,
      -0.04698995500802994,
      -0.08007246255874634,
      -0.011955871246755123,
      0.042514823377132416,
      -0.05172664672136307,
      0.05017800256609917,
      -0.0616898387670517,
      0.0879659503698349,
      -0.038036465644836426,
      -0.03236246481537819,
      -0.024902911856770515,
      -0.015445033088326454,
      -0.003640435403212905,
      -0.031975965946912766,
      0.047570545226335526,
      0.002379336627200246,
      -0.0590035654604435,
      0.005880715325474739,
      0.057875003665685654,
      0.006045880261808634,
      0.06572606414556503,
      0.08512146025896072,
      -0.0416678786277771,
      -0.01938045211136341,
      -0.08854294568300247,
      0.035745710134506226,
      -0.010656977072358131,
      -0.03279548138380051,
      -0.061769887804985046,
      -0.018680550158023834,
      0.035478152334690094,
      -0.004401892889291048,
      0.019112825393676758,
      0.05889904499053955,
      0.005386602133512497,
      -0.04472978413105011,
      0.04802211746573448,
      -0.02158120460808277,
      -0.0031083787325769663,
      0.03086620196700096,
      0.006241645198315382,
      0.049392957240343094,
      -0.07581647485494614,
      0.03487498313188553,
      -0.0684516578912735,
      0.07275696098804474,
      -0.008823050186038017,
      0.08052249252796173,
      -0.029699964448809624,
      -0.021184435114264488,
      -0.08605857193470001,
      -0.016362756490707397,
      0.02181132882833481,
      0.04537623003125191,
      0.08169202506542206,
      -0.04433191940188408,
      -0.05463140830397606,
      -0.002128192689269781,
      -0.016714124009013176,
      -0.11718206852674484,
      -0.02437884733080864,
      -0.03647470101714134,
      -0.0363326296210289,
      -0.005648735910654068,
      -0.057997673749923706,
      -0.06795469671487808,
      -0.020561834797263145,
      0.04322745278477669,
      0.09310688823461533,
      -0.014202886261045933,
      0.011555389501154423,
      0.06087846681475639,
      -0.006774177309125662,
      0.019617203623056412,
      -0.0982489287853241,
      -0.008074427023530006,
      -0.009985152631998062,
      0.07421272248029709,
      -0.01147201657295227,
      -0.03342977166175842,
      -0.056486934423446655,
      -0.013556892052292824,
      -0.05299808457493782,
      0.029109137132763863,
      -0.08495236188173294,
      -0.012475970201194286,
      0.04357604682445526,
      -0.005082804709672928,
      -0.027457645162940025,
      0.055013298988342285,
      -0.04251287877559662,
      -0.0023404578678309917,
      0.06218438595533371,
      0.04527956247329712,
      -0.09231044352054596,
      -0.006843775976449251,
      0.058285798877477646,
      -0.03735087811946869,
      0.012904880568385124,
      0.07242678105831146,
      -0.04428466781973839,
      0.004269452765583992,
      -0.017963998019695282,
      0.07805831730365753,
      -0.01345689594745636,
      0.01634303480386734,
      0.04087210074067116,
      0.007978901267051697,
      0.045694202184677124,
      -0.03934738412499428,
      0.009272372350096703,
      -0.041353609412908554,
      0.06783951073884964,
      -0.001451205345802009,
      -0.015016613528132439,
      0.0928870290517807,
      0.01316817943006754,
      -0.06464983522891998,
      -0.11282438784837723,
      0.0833730548620224,
      0.026944421231746674,
      0.036013562232255936,
      0.07643008977174759,
      -0.0418706052005291,
      0.03309525549411774,
      0.001981917303055525,
      -0.029955800622701645,
      -0.03379907086491585,
      0.0002766941615846008,
      -0.006561376620084047,
      -0.034574225544929504,
      0.07221479713916779,
      0.03150281310081482,
      0.026199154555797577,
      0.004790302831679583,
      -0.030915064737200737,
      -0.027302328497171402,
      0.056401576846838,
      -0.020084481686353683,
      -0.05665804073214531,
      -0.05437169969081879,
      -0.018683139234781265,
      -0.004257488995790482,
      -0.0003138420288451016,
      0.14157602190971375,
      0.0006580431363545358,
      -0.032841943204402924,
      -0.03258701041340828,
      0.03003501333296299,
      -0.02795458771288395,
      0.008273797109723091,
      0.02716144546866417,
      0.008545477874577045,
      -0.0036492522340267897,
      0.004646718967705965,
      -0.008543653413653374,
      -0.031084610149264336,
      0.03941693156957626,
      0.03492446616292,
      0.017060430720448494,
      -0.025687402114272118,
      -0.023083554580807686,
      -0.011606602929532528,
      0.023195812478661537,
      0.012834809720516205,
      -0.016056876629590988,
      -0.022705761715769768,
      0.03921150416135788,
      0.031885776668787,
      -0.001016051392070949,
      -0.026474526152014732,
      -0.04952274635434151,
      -0.019461356103420258,
      -0.051684245467185974,
      0.056737810373306274,
      0.1023024469614029,
      -0.03355846926569939,
      0.05748143792152405,
      0.03023540787398815,
      -0.004607615061104298,
      -0.10656924545764923,
      0.0058012413792312145,
      -0.05474080890417099,
      -0.07061898708343506,
      -0.05881015211343765,
      0.05503739416599274,
      0.02266300469636917,
      -0.008048729971051216,
      -0.022557979449629784,
      -0.008451427333056927,
      -0.019004905596375465,
      0.047531887888908386,
      -0.06034016236662865,
      -0.030115848407149315,
      -0.01766468584537506,
      -0.022793494164943695,
      0.004812679719179869,
      -0.021786585450172424,
      -0.0731424018740654,
      0.026798440143465996,
      0.05547845736145973,
      0.019320251420140266,
      0.02102069742977619,
      -0.03319178521633148,
      0.029364656656980515,
      -0.03418363258242607,
      0.07205507159233093,
      -0.009216799400746822,
      0.01942680962383747,
      -0.031040236353874207,
      0.04297703132033348,
      -0.13514769077301025,
      0.04004919156432152,
      0.002126205712556839,
      0.03879886120557785,
      0.029659835621714592,
      0.017530160024762154,
      -0.02577490173280239,
      0.09950655698776245,
      -0.011196707375347614,
      0.02253054641187191,
      -0.07918994128704071,
      -0.007914804853498936,
      -0.013496552594006062,
      -0.011939712800085545,
      0.009649783372879028,
      0.05495806783437729,
      -0.044182274490594864,
      -0.04022582992911339,
      0.023498570546507835,
      0.017022116109728813,
      0.05743103101849556,
      0.10395397245883942,
      -0.0907316729426384,
      -0.0434667132794857,
      0.06927797198295593,
      0.029155408963561058,
      -0.0806785523891449,
      0.012684216722846031,
      0.018368922173976898,
      0.06675952672958374,
      -0.010719955898821354,
      0.06059376895427704,
      0.031618036329746246,
      -0.028876081109046936,
      -0.020014654844999313,
      0.04685566946864128,
      0.006402901839464903,
      0.016718387603759766,
      0.005222804844379425,
      -0.060514602810144424,
      0.045133110135793686,
      0.012747063301503658,
      0.01997881568968296,
      -0.025733893737196922,
      0.027917932718992233,
      -0.010519707575440407,
      0.011994228698313236,
      0.010678265243768692,
      0.06155671179294586,
      -0.08119317144155502,
      -0.05691862106323242,
      0.005746048409491777,
      0.020537689328193665,
      -0.016601650044322014,
      0.001577097806148231,
      -0.012402783147990704,
      -0.05478987842798233,
      -0.031084513291716576,
      0.07256162166595459,
      0.01683049462735653,
      0.00358160980977118,
      0.004545491188764572,
      0.009923617355525494,
      -0.03799552470445633,
      -0.0026782164350152016,
      0.051453765481710434,
      -0.06708081811666489,
      -0.03469419479370117,
      -0.004652414936572313,
      -0.03537720441818237,
      0.05583428218960762,
      -0.02039322629570961,
      -0.0008690201793797314,
      0.036428384482860565,
      0.015001829713582993,
      -0.10427701473236084,
      0.03263285383582115,
      0.025174258276820183,
      0.011404100805521011,
      0.03752688318490982,
      0.020757118239998817,
      0.04216673970222473,
      0.00922464020550251,
      0.04150275141000748,
      0.00597649160772562,
      -0.0958208292722702,
      -0.011726833879947662,
      -0.013923637568950653,
      0.04510640352964401,
      0.028537694364786148,
      -0.027469465509057045,
      -0.0580056868493557,
      0.005399608518928289,
      0.04480214789509773,
      0.05803544074296951,
      0.03318293020129204,
      -0.06242015212774277,
      -0.06585720181465149,
      0.027807725593447685,
      0.016589129343628883,
      -0.047143250703811646,
      -0.053855180740356445,
      0.03673144429922104,
      0.008837243542075157,
      -0.02737715281546116,
      0.07354749739170074,
      -0.005309991538524628,
      0.02944423258304596,
      -0.0429387204349041,
      -0.013143387623131275,
      0.0028084623627364635,
      -0.037884898483753204,
      -0.016467463225126266,
      0.05709393694996834,
      -0.01077946089208126,
      0.016573084518313408,
      0.021741855889558792,
      -0.039054613560438156,
      0.07013151049613953,
      -0.011963438242673874,
      -0.05453389510512352,
      -0.027151109650731087,
      0.06578589230775833,
      -0.07196637243032455,
      -0.013771061785519123,
      -0.05066458880901337,
      0.07596102356910706,
      0.005444417241960764,
      -0.00834677740931511,
      0.02790922299027443,
      -0.001451699761673808,
      -0.07658914476633072,
      0.007250791881233454
    ],
    [
      0.03871350362896919,
      -0.05552475154399872,
      -0.038952555507421494,
      -0.047630831599235535,
      -0.010843390598893166,
      0.013254047371447086,
      -0.09187741577625275,
      -0.008174297399818897,
      0.04333222284913063,
      0.02142486348748207,
      -0.010286090895533562,
      -0.03945109248161316,
      -0.03218922019004822,
      -0.017936915159225464,
      0.02337762899696827,
      -0.026325348764657974,
      0.020292773842811584,
      -0.09889733046293259,
      0.003524560248479247,
      0.028955137357115746,
      0.023341510444879532,
      0.03636191040277481,
      0.07373613119125366,
      0.041306231170892715,
      -0.0017839219653978944,
      -0.009254313074052334,
      0.06273994594812393,
      0.0284214336425066,
      -0.007910972461104393,
      0.02702447585761547,
      0.01668718457221985,
      -0.040227994322776794,
      0.07498278468847275,
      -0.037596993148326874,
      -0.042314909398555756,
      0.012169442139565945,
      -0.05594340339303017,
      0.061457738280296326,
      -0.08084362000226974,
      0.015686681494116783,
      0.02976643666625023,
      -0.018488779664039612,
      -0.0345974899828434,
      0.03834991157054901,
      -0.04801042377948761,
      -0.005404430907219648,
      -0.08500363677740097,
      -0.04314735531806946,
      0.04571399465203285,
      0.04227010905742645,
      -0.04291688650846481,
      -0.028405364602804184,
      0.04003885015845299,
      -0.03875114768743515,
      -0.008388382382690907,
      0.007750579155981541,
      -0.042108625173568726,
      -0.10279116034507751,
      -0.014629519544541836,
      -0.024120312184095383,
      -0.009120969101786613,
      -0.004256092943251133,
      -0.02113736979663372,
      -0.04370376467704773,
      0.06649390608072281,
      0.04483607038855553,
      0.014364575035870075,
      -0.008936269208788872,
      0.027353955432772636,
      0.024043237790465355,
      -0.011536001227796078,
      0.05187423527240753,
      0.02751479484140873,
      -0.06788157671689987,
      -0.006720258854329586,
      -0.0067796483635902405,
      0.0730193480849266,
      0.040867794305086136,
      -0.007596045266836882,
      0.05992574989795685,
      -0.053998302668333054,
      0.06617169827222824,
      -0.0942167416214943,
      -0.043189723044633865,
      0.045544497668743134,
      0.014044235460460186,
      0.004989322740584612,
      0.04236746206879616,
      -0.009878270328044891,
      -0.03739394620060921,
      0.022364316508173943,
      -0.02122337743639946,
      -0.007458238396793604,
      -0.0583256296813488,
      -0.07203703373670578,
      -0.04050404950976372,
      0.07956477254629135,
      -0.08368556201457977,
      0.09059945493936539,
      -0.040170446038246155,
      -0.017691249027848244,
      -0.0011924131540581584,
      0.018201172351837158,
      -0.04410332441329956,
      -0.049901530146598816,
      0.03471691161394119,
      0.11055503040552139,
      0.032201819121837616,
      -0.022245122119784355,
      -0.004349461756646633,
      -0.006507392041385174,
      0.0427507720887661,
      0.004223459865897894,
      -0.05453375726938248,
      -0.04324980452656746,
      0.027249807491898537,
      -0.047289445996284485,
      -0.0478421151638031,
      0.030263734981417656,
      -0.012376734986901283,
      0.03916162997484207,
      -0.017123993486166,
      -0.02906803786754608,
      0.0666419118642807,
      -0.027827566489577293,
      0.031054913997650146,
      -0.056116972118616104,
      0.09812673181295395,
      0.00590561842545867,
      -0.00044216314563527703,
      -0.027344919741153717,
      -0.06280152499675751,
      -0.0064385696314275265,
      0.015828201547265053,
      0.11836471408605576,
      0.031274981796741486,
      0.08822306245565414,
      0.020412486046552658,
      -0.036834582686424255,
      -0.09552881866693497,
      -0.001428007846698165,
      0.11743110418319702,
      -0.013599404133856297,
      -0.03789062798023224,
      -0.12710510194301605,
      -0.004727358464151621,
      -0.00324899610131979,
      -0.05845886096358299,
      -0.030738022178411484,
      0.027279626578092575,
      0.0037411749362945557,
      -0.017891772091388702,
      -0.009553486481308937,
      -0.029648907482624054,
      -0.0324401929974556,
      -0.05402830243110657,
      -0.026074666529893875,
      0.06703963875770569,
      0.06630478799343109,
      0.021474186331033707,
      -0.06742416322231293,
      -0.014820828102529049,
      0.05923972278833389,
      0.018626557663083076,
      -0.0006246402626857162,
      -0.053277336061000824,
      -0.007801564410328865,
      -0.01656644605100155,
      0.09690824896097183,
      -0.08737174421548843,
      0.03430291637778282,
      0.0008401992381550372,
      0.024744728580117226,
      -0.02938525751233101,
      -0.06794637441635132,
      0.042686764150857925,
      0.025219649076461792,
      0.03538697212934494,
      -0.06573619693517685,
      -0.007176314014941454,
      0.016099752858281136,
      0.0513756163418293,
      -0.034659843891859055,
      -0.020488696172833443,
      -0.05866488441824913,
      -0.02851148322224617,
      0.03361421823501587,
      0.009910027496516705,
      -0.0027492372319102287,
      0.060278523713350296,
      0.05693612992763519,
      -0.027941202744841576,
      3.635058965301141e-05,
      0.07744055241346359,
      0.05886363238096237,
      -0.01461814995855093,
      0.03918442130088806,
      -0.02027924358844757,
      -0.05181185156106949,
      0.010906127281486988,
      0.042542554438114166,
      0.10707137733697891,
      0.0795402079820633,
      0.017101239413022995,
      -0.04043523594737053,
      -0.05421506240963936,
      -0.04476086422801018,
      -0.06402187049388885,
      -0.06091894209384918,
      0.05119209736585617,
      -0.09884104877710342,
      -0.005768263712525368,
      0.06708221137523651,
      0.0365699827671051,
      0.04649520665407181,
      0.003676922293379903,
      0.006464887876063585,
      -0.035379763692617416,
      -0.027812082320451736,
      0.024746622890233994,
      -0.0177468191832304,
      0.02390691265463829,
      0.07557829469442368,
      0.034234531223773956,
      -0.007668580859899521,
      0.015209455043077469,
      0.04331625998020172,
      0.05776083096861839,
      -0.014583609066903591,
      0.10004112124443054,
      -0.027281809598207474,
      0.01628851890563965,
      0.021215898916125298,
      -0.07574720680713654,
      -0.012707988731563091,
      -0.09461452811956406,
      0.040167856961488724,
      0.0369410365819931,
      -0.0449538417160511,
      -0.011534812860190868,
      0.07134552299976349,
      0.02355189248919487,
      -0.04896581172943115,
      -0.008405341766774654,
      -0.08289672434329987,
      -0.01781606674194336,
      -0.025828855112195015,
      0.026872927322983742,
      -0.0582054927945137,
      0.08977065235376358,
      0.002060254802927375,
      0.03023804910480976,
      0.022129574790596962,
      0.06374763697385788,
      -0.08978603035211563,
      -0.09865371882915497,
      0.07056253403425217,
      0.04800766706466675,
      -0.00844145379960537,
      -0.025248635560274124,
      -0.06087719649076462,
      0.03012159839272499,
      -0.0184196587651968,
      -0.006010683253407478,
      0.010312632657587528,
      0.002695714356377721,
      -0.012268380261957645,
      0.05449121817946434,
      -0.022840123623609543,
      0.032345715910196304,
      0.0134707847610116,
      0.08899754285812378,
      -0.0350312814116478,
      -0.012803846038877964,
      0.020271096378564835,
      0.03550908714532852,
      -0.0004165032005403191,
      -0.013242143206298351,
      0.004592317156493664,
      0.10359998047351837,
      0.05708356574177742,
      0.031481340527534485,
      0.015508019365370274,
      0.10865974426269531,
      0.02523893304169178,
      -0.00851654727011919,
      0.07237040996551514,
      -0.01342626940459013,
      -0.04204139485955238,
      -0.055989593267440796,
      0.009143843315541744,
      0.02939172275364399,
      -0.08420411497354507,
      0.013332150876522064,
      0.04086557775735855,
      -0.0053076003678143024,
      -0.053988322615623474,
      0.08326109498739243,
      0.007359417155385017,
      0.0254364050924778,
      -0.02581586316227913,
      -0.016338210552930832,
      -0.03331581503152847,
      0.06638266146183014,
      -0.019840991124510765,
      -0.008073670789599419,
      -0.005868545267730951,
      -0.03097630850970745,
      -0.020706452429294586,
      0.06354504823684692,
      -0.07080420851707458,
      -0.07228147983551025,
      0.04785465821623802,
      -0.025473112240433693,
      0.0010573593899607658,
      -0.028130164369940758,
      -0.08837679773569107,
      -0.033273179084062576,
      -0.021533193066716194,
      -0.021237049251794815,
      -0.00609794445335865,
      -0.04535107687115669,
      -0.02860136516392231,
      -0.08013036102056503,
      -0.03327527269721031,
      -0.012247239239513874,
      -0.009447162039577961,
      -0.07255790382623672,
      0.0323246568441391,
      0.1383659988641739,
      0.04104231297969818,
      0.053357116878032684,
      0.040435440838336945,
      -0.006953191943466663,
      0.011054680682718754,
      0.023640867322683334,
      -0.021001355722546577,
      -0.019429825246334076,
      -0.012179558165371418,
      0.057216741144657135,
      0.022818459197878838,
      -0.024907194077968597,
      0.0026846504770219326,
      -0.022744370624423027,
      -0.01214538887143135,
      0.015212221071124077,
      -0.015920454636216164,
      -0.04290285333991051,
      -0.04728538170456886,
      0.026759151369333267,
      -0.0013542332453653216,
      -0.04336889833211899,
      0.03597533702850342,
      -0.014199111610651016,
      0.02998194843530655,
      0.026561936363577843,
      -0.04168979823589325,
      0.05927749350667,
      0.0009613746660761535,
      -0.02376512624323368,
      -0.005564805585891008,
      0.038638535887002945,
      0.03261491656303406,
      -0.005139599088579416,
      0.01253313198685646,
      -0.013711589388549328,
      -0.037644557654857635,
      0.012033435516059399,
      0.027110042050480843,
      0.014098105020821095,
      0.05590331181883812,
      -0.05653683468699455,
      0.04129744693636894,
      0.08980213850736618,
      -0.007331468630582094,
      0.038286130875349045,
      0.024478236213326454,
      -0.0064198351465165615,
      0.05467124283313751,
      -0.028641385957598686,
      0.00728455139324069,
      0.005076379515230656,
      0.07180406898260117,
      0.021821489557623863,
      -0.0009512638207525015,
      0.06586632877588272,
      -0.0031712776981294155,
      -0.02514679543673992,
      0.002020348096266389,
      -0.029160844162106514,
      -0.06118549406528473,
      0.07146648317575455,
      -0.0755004733800888,
      -0.05178459733724594,
      -0.017295164987444878,
      0.01057321298867464,
      -0.04635508358478546,
      0.059244878590106964,
      0.03148868680000305,
      -0.0032382544595748186,
      0.0033323094248771667,
      0.03503097593784332,
      0.007688287179917097,
      -0.02877316251397133,
      -0.0405699796974659,
      -0.08190996944904327,
      0.019537970423698425,
      -0.028233403339982033,
      -0.10315566509962082,
      -0.019816935062408447,
      0.13289938867092133,
      -0.03336790204048157,
      0.09680298715829849,
      -0.035600416362285614,
      0.007663716096431017,
      -0.04842570796608925,
      -0.002181688556447625,
      -0.019253699108958244,
      0.0006655734614469111,
      -0.05229339376091957,
      0.0001557343202875927,
      -0.0794348269701004,
      -0.013617458753287792,
      0.0040201712399721146,
      0.0385265089571476,
      -0.03821282833814621,
      -0.0366290882229805,
      -0.062301360070705414,
      -0.03898601606488228,
      0.034768812358379364,
      0.0009337610681541264,
      0.03907090798020363,
      0.020813746377825737,
      0.04976522549986839,
      0.03369368985295296,
      0.023330047726631165,
      0.020659340545535088,
      -0.05900311842560768,
      0.04660281911492348,
      0.04263494536280632,
      -0.06364818662405014,
      0.005097056273370981,
      0.08363823592662811,
      0.09031007438898087,
      0.02989058755338192,
      0.05008138343691826,
      -0.020039912313222885,
      -0.07133307307958603,
      0.05045296251773834,
      -0.01887967623770237,
      0.05736105889081955,
      -0.04205447435379028,
      0.029300527647137642,
      0.02807721309363842,
      0.013247017748653889,
      0.032269611954689026,
      -0.004159654024988413,
      0.05381545424461365,
      0.008580656722187996,
      0.07287519425153732,
      0.055746790021657944,
      0.04230713099241257,
      0.06307274848222733,
      0.007735004182904959,
      -0.04588242247700691,
      -0.045856013894081116,
      -0.06655510514974594,
      -0.007467585615813732,
      0.004810807760804892,
      0.0008121521095745265,
      -0.016429979354143143,
      0.010451056063175201,
      0.026564037427306175,
      -0.05221380293369293,
      0.03298243135213852,
      0.06092562898993492,
      0.019086351618170738,
      0.042062606662511826,
      -0.010327760130167007,
      0.047814078629016876,
      0.020473746582865715,
      -0.003076400840654969,
      0.029313452541828156,
      0.05213477462530136,
      0.08337842673063278,
      -0.020437953993678093,
      -0.018509114161133766,
      0.004746395628899336,
      -0.015021602623164654,
      -0.0038866903632879257,
      -0.004245371092110872,
      0.024206222966313362,
      0.04238978773355484,
      -0.034069932997226715,
      0.04907645285129547,
      -0.045546215027570724,
      -0.05610334873199463,
      -0.012467509135603905,
      -0.004664300475269556,
      -0.023614687845110893,
      -0.013218236155807972,
      -0.07647612690925598,
      -0.07192372530698776
    ],
    [
      0.010207759216427803,
      -0.03725041449069977,
      0.07491596043109894,
      -0.03123481571674347,
      0.050952911376953125,
      0.0395541675388813,
      0.05654672160744667,
      0.030063163489103317,
      0.03307326138019562,
      0.04104728624224663,
      -0.0008980607963167131,
      -0.062400903552770615,
      0.032792940735816956,
      0.07525576651096344,
      0.01954067125916481,
      0.05814775079488754,
      -0.04329642653465271,
      0.007414764724671841,
      -0.0006361054256558418,
      -0.08714734017848969,
      0.042550228536129,
      -0.02795139141380787,
      0.03992553427815437,
      0.04057104513049126,
      -0.06239850074052811,
      -0.06027551740407944,
      -0.07991687208414078,
      -0.05105646327137947,
      0.08316312730312347,
      0.055081579834222794,
      -0.05535223335027695,
      0.0007305057370103896,
      0.043132755905389786,
      -0.038481395691633224,
      -0.013769243843853474,
      0.02076662704348564,
      -0.06303389370441437,
      -0.03908414766192436,
      -0.04784063994884491,
      -0.05065518245100975,
      0.03449692949652672,
      -0.02626890502870083,
      -0.07121329754590988,
      -0.032339099794626236,
      0.021101336926221848,
      -0.024844812229275703,
      -0.07782069593667984,
      -0.029670534655451775,
      0.017159478738904,
      -0.03964271768927574,
      -0.005620414391160011,
      -0.0036663899663835764,
      0.04758266359567642,
      -0.08275996148586273,
      0.05594489350914955,
      -0.0025649676099419594,
      -0.07023651897907257,
      0.003868744010105729,
      0.06965004652738571,
      0.019917795434594154,
      0.019269142299890518,
      -0.05903661251068115,
      -0.03712427616119385,
      -0.024533240124583244,
      0.030742157250642776,
      0.00019961006182711571,
      0.001465121516957879,
      0.04508695751428604,
      -0.05181370675563812,
      0.011560041457414627,
      -0.010273648425936699,
      -0.01789412461221218,
      0.03189009800553322,
      -0.04871000349521637,
      -0.01445065438747406,
      -0.01184798777103424,
      0.1044454351067543,
      0.013235197402536869,
      0.07554087787866592,
      -0.021158047020435333,
      0.038055501878261566,
      0.01785566657781601,
      -0.0029459784273058176,
      -0.0370587483048439,
      0.005481470841914415,
      -0.04906535893678665,
      -0.06299775093793869,
      0.027552299201488495,
      -0.023126598447561264,
      0.03297095745801926,
      -0.05244969576597214,
      0.0216149240732193,
      -0.05855810269713402,
      0.1158132329583168,
      -0.03420957922935486,
      0.010720808058977127,
      -0.006887935567647219,
      0.06336810439825058,
      0.023252859711647034,
      -0.009493304416537285,
      0.09711692482233047,
      0.046376489102840424,
      0.08895043283700943,
      0.054656822234392166,
      -0.0741189643740654,
      -0.030918899923563004,
      -0.0030090603977441788,
      -0.023886071518063545,
      -0.00926956906914711,
      0.029124466702342033,
      -0.009225749410688877,
      -0.0077340914867818356,
      -0.020343981683254242,
      -0.0701613500714302,
      -0.03883591294288635,
      -0.026945021003484726,
      -0.008443647995591164,
      0.0812380462884903,
      -0.0026929292362183332,
      0.010877382010221481,
      -0.05962101370096207,
      0.005952987354248762,
      0.024279238656163216,
      0.0167998019605875,
      -0.0004236080276314169,
      -0.05923709645867348,
      0.029964128509163857,
      0.05704265832901001,
      -0.006807197816669941,
      -0.07244044542312622,
      -0.038498684763908386,
      -0.06652887910604477,
      0.05901968851685524,
      -0.00959952361881733,
      -0.026018179953098297,
      -0.065806545317173,
      0.007246400695294142,
      -0.0008097110548987985,
      0.11036458611488342,
      -0.011500349268317223,
      0.0011049549793824553,
      -0.05818602442741394,
      -0.04921654611825943,
      -0.04324739798903465,
      -0.010749622248113155,
      -0.02102714218199253,
      0.0468953438103199,
      -0.042381804436445236,
      0.0031635521445423365,
      0.014752582646906376,
      0.045571040362119675,
      -0.02620745822787285,
      0.008305521681904793,
      0.028797153383493423,
      0.0024836701340973377,
      0.010699953883886337,
      0.08539298176765442,
      0.014082672074437141,
      0.045251522213220596,
      -0.02531193569302559,
      -0.02509857714176178,
      0.005613269284367561,
      0.05399210378527641,
      -0.058402564376592636,
      -0.07564049959182739,
      0.0016365337651222944,
      0.028904594480991364,
      0.023674173280596733,
      0.05787212401628494,
      0.0253964364528656,
      0.03915317729115486,
      -0.1029575988650322,
      -0.002380244666710496,
      0.001690755016170442,
      -0.05544574186205864,
      -0.018638629466295242,
      0.03673199564218521,
      0.0517217293381691,
      -0.028921129181981087,
      -0.005201314575970173,
      -0.033518943935632706,
      -0.0352175273001194,
      -0.04636315628886223,
      0.0363660529255867,
      0.024146659299731255,
      0.05330062657594681,
      0.029651718214154243,
      -0.019920699298381805,
      0.03798076882958412,
      0.06385310739278793,
      -0.010891901329159737,
      -0.12256751954555511,
      0.041466619819402695,
      -0.019380895420908928,
      0.0244126059114933,
      0.01684229075908661,
      0.006837006192654371,
      0.07465735822916031,
      -0.005867824424058199,
      0.068306565284729,
      0.04083843529224396,
      0.0629010945558548,
      -0.05605804920196533,
      -0.01246635802090168,
      -0.022530214861035347,
      -0.06622488051652908,
      0.10329954326152802,
      -0.016330480575561523,
      0.044278211891651154,
      -0.06180679798126221,
      -0.06433267891407013,
      0.0014530913904309273,
      0.04046231880784035,
      0.05338713154196739,
      -0.0036318600177764893,
      0.0011358773335814476,
      0.04042159393429756,
      -0.04629696533083916,
      -0.06598250567913055,
      0.0491311252117157,
      0.08778361231088638,
      0.003303918521851301,
      -0.015895145013928413,
      0.0110517218708992,
      0.08651076257228851,
      -0.020474569872021675,
      0.05253103747963905,
      -0.06270536035299301,
      -0.08355356752872467,
      -0.01113045308738947,
      -0.0775350034236908,
      0.05159229785203934,
      -0.012475261464715004,
      -0.026906542479991913,
      0.04664863273501396,
      0.022701509296894073,
      -0.020851409062743187,
      0.013368875719606876,
      -0.005588667467236519,
      0.022600047290325165,
      0.023830607533454895,
      -0.09951426833868027,
      -9.711638267617673e-05,
      0.03424839302897453,
      -0.048340167850255966,
      0.06334923952817917,
      -0.027524366974830627,
      -0.07781758904457092,
      0.04262705519795418,
      0.043711040169000626,
      0.005344423931092024,
      -0.0381401851773262,
      -0.03254270181059837,
      -0.04258546605706215,
      -0.010436370968818665,
      -0.03194175288081169,
      0.051467183977365494,
      0.020061684772372246,
      0.042969245463609695,
      -0.023539220914244652,
      -0.07124887406826019,
      0.03812629356980324,
      -0.02898671291768551,
      0.018693264573812485,
      0.0006118788151070476,
      0.05166463926434517,
      0.028740182518959045,
      -0.0015697084600105882,
      -0.08471010625362396,
      0.014794107526540756,
      0.022874416783452034,
      0.0782603919506073,
      -0.029400277882814407,
      0.06748846918344498,
      -0.05122638866305351,
      0.08250372111797333,
      0.049498550593853,
      -0.0017725061625242233,
      0.03681771084666252,
      -0.029739506542682648,
      0.0314766988158226,
      -0.02201213873922825,
      -0.03616482764482498,
      0.008269877173006535,
      -0.05014418438076973,
      -0.06111948937177658,
      -0.013494693674147129,
      -0.009135871194303036,
      0.06475777179002762,
      0.02974560298025608,
      -0.11754079163074493,
      -0.0014797198818996549,
      -0.011132696643471718,
      0.006481211166828871,
      0.0031724567525088787,
      -0.051202092319726944,
      -0.07488533109426498,
      0.09252738952636719,
      0.06454581767320633,
      0.10840195417404175,
      -0.042266085743904114,
      0.0033786713611334562,
      0.010429547168314457,
      0.08497665822505951,
      0.045755572617053986,
      0.02504243515431881,
      -0.02549097128212452,
      0.03990306332707405,
      0.03633156418800354,
      -0.04038529470562935,
      0.03446345031261444,
      -0.05657181888818741,
      -0.008588076569139957,
      -0.01380796730518341,
      -0.06695567816495895,
      0.03345072641968727,
      0.020463528111577034,
      -0.042497891932725906,
      -0.0077752345241606236,
      -0.06041669845581055,
      0.04524502530694008,
      0.018710944801568985,
      0.025261467322707176,
      0.02970500849187374,
      -0.022129863500595093,
      -0.027700653299689293,
      0.07514867186546326,
      -0.08073097467422485,
      0.01794043742120266,
      0.052332352846860886,
      0.04572654142975807,
      0.01784149929881096,
      0.01697560027241707,
      -0.044576216489076614,
      -0.00722581148147583,
      -0.010495715774595737,
      -0.046352073550224304,
      -0.015462728217244148,
      0.001932396087795496,
      -0.005529093090444803,
      -0.023599019274115562,
      -0.017998524010181427,
      -0.01741608791053295,
      0.0026392037980258465,
      0.02700793370604515,
      0.039727285504341125,
      0.055159423500299454,
      -0.005154368933290243,
      -0.0414159893989563,
      -0.03293536603450775,
      -0.019759466871619225,
      -0.007850914262235165,
      -0.0447879433631897,
      -0.05834061652421951,
      -0.001904130564071238,
      -0.056130144745111465,
      0.030129937455058098,
      0.0165043193846941,
      0.014609028585255146,
      -0.03380388766527176,
      -0.05203885957598686,
      -0.0025730892084538937,
      -0.0075630187056958675,
      -0.032129839062690735,
      -0.03721528872847557,
      -0.010869253426790237,
      0.007946422323584557,
      -0.01051829382777214,
      -0.023805096745491028,
      -0.0038439473137259483,
      0.0779472291469574,
      0.06330464780330658,
      -0.035620395094156265,
      -0.00020245013001840562,
      -0.012297217734158039,
      0.02220085635781288,
      -0.005702495574951172,
      0.017476899549365044,
      -0.038056012243032455,
      -0.009633935987949371,
      0.07301582396030426,
      0.002019492443650961,
      0.06377360224723816,
      -0.0005458079394884408,
      -0.021447885781526566,
      0.01572469435632229,
      -0.015967469662427902,
      -0.01701154001057148,
      0.055729322135448456,
      0.017008252441883087,
      0.03668007254600525,
      0.08422529697418213,
      -0.007790185045450926,
      0.06960302591323853,
      -0.04818812012672424,
      -0.07699356973171234,
      -0.047290608286857605,
      0.012989798560738564,
      0.016021044924855232,
      -0.025625236332416534,
      -0.06469915062189102,
      -0.05089670047163963,
      -0.05325615406036377,
      -0.03635998070240021,
      0.004654438700526953,
      -0.025907840579748154,
      -0.03589147701859474,
      0.01470291055738926,
      0.009036924690008163,
      0.026767103001475334,
      0.07933322340250015,
      0.02215724252164364,
      0.09876914322376251,
      -0.04253939911723137,
      0.043754566460847855,
      -0.033753301948308945,
      -0.10036543011665344,
      -0.03411228209733963,
      0.030222807079553604,
      -0.02591499127447605,
      0.08355273306369781,
      0.049130700528621674,
      -0.009307707659900188,
      -0.04211653023958206,
      0.0036329124122858047,
      -0.05820576101541519,
      0.0004329527437221259,
      -0.012599249370396137,
      0.017335234209895134,
      0.04701787605881691,
      -0.036396611481904984,
      0.018634997308254242,
      0.01599341817200184,
      0.0596679225564003,
      0.03257559984922409,
      0.05380614474415779,
      -0.06014473736286163,
      0.08776448667049408,
      -0.03496821969747543,
      -0.033371273428201675,
      -0.01141572929918766,
      0.07498105615377426,
      -0.05790184065699577,
      0.08489438891410828,
      0.02348398044705391,
      -0.04047161713242531,
      0.02719786949455738,
      0.013700051233172417,
      0.0033112920355051756,
      -0.02179029956459999,
      0.06810034066438675,
      0.028731469064950943,
      0.10716214776039124,
      0.045062728226184845,
      -0.09933304041624069,
      0.007657816633582115,
      -0.009772643446922302,
      -0.001246854430064559,
      -0.05978059768676758,
      -0.023186659440398216,
      -0.05368056148290634,
      -0.009268385358154774,
      0.04907471314072609,
      0.06076450273394585,
      -0.058489494025707245,
      0.041139353066682816,
      0.012581804767251015,
      0.004224855452775955,
      -0.11055081337690353,
      0.044756386429071426,
      -0.008927427232265472,
      0.04335654899477959,
      0.058412183076143265,
      -0.01757126674056053,
      -0.03061368688941002,
      0.009338664822280407,
      -0.040632668882608414,
      0.042669955641031265,
      0.021767256781458855,
      -0.06258053332567215,
      -0.030737366527318954,
      -0.01976129785180092,
      -0.012016458436846733,
      0.02552219107747078,
      0.04502159357070923,
      0.062416013330221176,
      -0.017289232462644577,
      0.013147175312042236,
      -0.019398415461182594,
      0.055893559008836746,
      0.07537207752466202,
      -0.005858742631971836,
      0.039004307240247726,
      -0.033653974533081055,
      -0.044086162000894547,
      0.030896492302417755,
      -0.01839933730661869,
      0.04933592677116394,
      -0.030457668006420135,
      -0.013521155342459679,
      -0.0029213610105216503,
      -0.03698106110095978,
      -0.042683087289333344
    ],
    [
      0.046755146235227585,
      -0.02748124487698078,
      0.030712904408574104,
      -0.023855863139033318,
      -0.014994668774306774,
      -0.029534175992012024,
      -0.0201918575912714,
      -0.02366533875465393,
      0.0625729039311409,
      0.0034999800845980644,
      -0.0005300294724293053,
      0.047395799309015274,
      0.052356038242578506,
      -0.015547511167824268,
      -0.009691341780126095,
      -0.008881502784788609,
      -0.005961982533335686,
      0.034955624490976334,
      -0.045630261301994324,
      -0.04026748612523079,
      -0.002269368153065443,
      -0.05655146762728691,
      0.07187425345182419,
      -0.039020001888275146,
      0.014537034556269646,
      -0.06703018397092819,
      -0.012249927036464214,
      0.029276132583618164,
      -0.042300835251808167,
      -0.10865198075771332,
      -0.04692493751645088,
      0.11083395034074783,
      -0.034925591200590134,
      -0.013797211460769176,
      0.004724760074168444,
      0.025509363040328026,
      0.010484184138476849,
      0.012217245064675808,
      -0.030307352542877197,
      -0.01844269037246704,
      0.00362243945710361,
      0.04514480009675026,
      -0.05714043602347374,
      0.02909228205680847,
      0.0011683048214763403,
      -0.11406507343053818,
      -0.01321166381239891,
      -0.05387425795197487,
      -0.07595767825841904,
      -0.014404450543224812,
      0.05168868228793144,
      0.03573274239897728,
      -0.04696387052536011,
      -0.03667542710900307,
      -0.03909547999501228,
      0.01527949795126915,
      -0.02287023700773716,
      0.005367399659007788,
      -0.014801722951233387,
      -0.07228550314903259,
      0.03273582085967064,
      0.020440267398953438,
      -0.04967127740383148,
      0.03445710614323616,
      0.06735958158969879,
      0.010624954476952553,
      -0.018756140023469925,
      0.06850641965866089,
      0.07578796148300171,
      -0.021793877705931664,
      -0.07200466096401215,
      -0.034570012241601944,
      -0.006131448317319155,
      -0.041816648095846176,
      0.03139234334230423,
      -0.03540769964456558,
      -0.001331219100393355,
      -0.07140285521745682,
      0.030605627223849297,
      0.04359287768602371,
      0.009963266551494598,
      -0.02391580119729042,
      -0.023873815312981606,
      0.0762873962521553,
      0.006259685382246971,
      -0.07094766199588776,
      -0.04904927685856819,
      0.06128854304552078,
      0.04278019070625305,
      0.042961906641721725,
      -0.03701099008321762,
      0.026368973776698112,
      -0.02249000407755375,
      0.003045253921300173,
      0.011450674384832382,
      0.015395445749163628,
      -0.016367051750421524,
      -0.022328931838274002,
      -0.003285020822659135,
      0.05080529674887657,
      -0.051056794822216034,
      -0.033721283078193665,
      -0.02270030789077282,
      0.04429255425930023,
      0.04578027129173279,
      -0.03930216655135155,
      0.038963381201028824,
      0.041361939162015915,
      0.07363741844892502,
      -0.03061572089791298,
      -0.0810447409749031,
      -0.023034561425447464,
      -0.013477600179612637,
      -0.07234784215688705,
      0.03788277879357338,
      0.0133512606844306,
      -0.06517768651247025,
      0.05258692428469658,
      0.006221001036465168,
      -0.049037035554647446,
      -0.04424118623137474,
      -0.02851666696369648,
      -0.08521351963281631,
      -0.007430075202137232,
      0.05845746397972107,
      0.07689218968153,
      -0.11074651032686234,
      -0.0031982711516320705,
      0.032231591641902924,
      -0.0593804195523262,
      -0.03782017529010773,
      0.041033245623111725,
      -0.01131054013967514,
      -0.09715578705072403,
      -0.04890311509370804,
      0.03561706095933914,
      -0.025369636714458466,
      0.002780201146379113,
      0.023446738719940186,
      -0.055273763835430145,
      0.056619610637426376,
      0.00045213830890133977,
      -0.06278806924819946,
      0.08006744831800461,
      0.003035635221749544,
      0.017423944547772408,
      0.02527402527630329,
      0.05279840901494026,
      0.05760085582733154,
      0.007849691435694695,
      0.06476154923439026,
      -0.09060608595609665,
      -0.013435043394565582,
      -0.009618893265724182,
      -0.013196023181080818,
      -0.04987833648920059,
      0.026253528892993927,
      0.009577995166182518,
      -0.014096683822572231,
      -0.016169967129826546,
      -0.009829972870647907,
      -0.03348510339856148,
      0.006812772713601589,
      0.04768743738532066,
      -0.011110872961580753,
      0.011732824146747589,
      -0.0002789318095892668,
      -0.024667996913194656,
      -0.03541204333305359,
      -0.051438190042972565,
      -0.026040149852633476,
      -0.08441100269556046,
      -0.0026443651877343655,
      0.10057232528924942,
      0.0409843772649765,
      -0.035907141864299774,
      -0.012348183430731297,
      0.050906505435705185,
      0.02780969813466072,
      0.11253774166107178,
      0.015842249616980553,
      0.015694934874773026,
      0.0031829974614083767,
      0.0061677140183746815,
      0.012893298640847206,
      -0.009341479279100895,
      -0.009363505989313126,
      -0.038189977407455444,
      0.03505802899599075,
      0.03676741197705269,
      -0.02943735383450985,
      -0.026393920183181763,
      0.062404368072748184,
      -0.08261299878358841,
      0.0011177604319527745,
      -0.03321704640984535,
      0.004136239644140005,
      -0.05479796603322029,
      -0.07232068479061127,
      -0.01626455970108509,
      0.043464962393045425,
      0.016300350427627563,
      0.045904483646154404,
      0.004266809672117233,
      -0.01732359267771244,
      0.05045805871486664,
      -0.010273586958646774,
      0.00704073766246438,
      0.012551801279187202,
      -0.013219735585153103,
      0.004192207008600235,
      0.02338550239801407,
      0.07035350054502487,
      0.030357923358678818,
      0.0013370050583034754,
      0.06728753447532654,
      0.06928981840610504,
      -0.01418600045144558,
      0.02605583518743515,
      0.02358933351933956,
      -0.03002205491065979,
      0.010520231910049915,
      0.09356483072042465,
      -0.0001389016688335687,
      0.025920338928699493,
      -0.032976213842630386,
      0.030200064182281494,
      0.05626147612929344,
      -0.0026902086101472378,
      0.013594813644886017,
      0.07570380717515945,
      0.03730403631925583,
      0.017749948427081108,
      -0.032348230481147766,
      -0.02062392234802246,
      -0.029581518843770027,
      0.024463625624775887,
      -0.041602972894907,
      0.055584002286195755,
      0.04278683289885521,
      -0.0743645429611206,
      0.02988392300903797,
      0.006689452566206455,
      -0.000596049299929291,
      -0.007252987008541822,
      -0.014260798692703247,
      0.014958064071834087,
      -0.03732623532414436,
      0.05292216315865517,
      0.01700543984770775,
      -0.01775599643588066,
      0.07928302139043808,
      0.03466632962226868,
      0.052581679075956345,
      0.06744113564491272,
      -0.0512191578745842,
      0.019447769969701767,
      0.055291902273893356,
      -0.01817988231778145,
      0.06398022919893265,
      -0.11436934024095535,
      0.05874362960457802,
      0.03459997847676277,
      0.00889030285179615,
      -0.04291936755180359,
      -0.03230160102248192,
      0.024860726669430733,
      -0.08578839898109436,
      0.06804976612329483,
      0.02888455055654049,
      -0.03812669217586517,
      -0.09846880286931992,
      0.09937992691993713,
      0.018006963655352592,
      -0.05913444980978966,
      -0.07728996872901917,
      -0.0010996421333402395,
      -0.007385952863842249,
      -0.01659530960023403,
      0.0659332126379013,
      -0.008370088413357735,
      -0.08082575350999832,
      -0.004664393607527018,
      0.11193966865539551,
      -0.021294936537742615,
      -0.0034400075674057007,
      0.012302512302994728,
      0.03767736256122589,
      0.03766467422246933,
      -0.010162035003304482,
      -0.03691156953573227,
      -0.044226933270692825,
      -0.009871713817119598,
      -0.06563331186771393,
      0.030721625313162804,
      -0.056711386889219284,
      -0.0716608315706253,
      0.014598526060581207,
      0.00097963388543576,
      0.12638963758945465,
      0.02764762192964554,
      0.044301629066467285,
      0.04213661700487137,
      0.010989882051944733,
      -0.0047208103351294994,
      0.06036234647035599,
      -0.02966836839914322,
      -0.02135579101741314,
      -0.009703678078949451,
      0.023032108321785927,
      0.01717855967581272,
      0.058680616319179535,
      -0.10406554490327835,
      -0.017066234722733498,
      0.009344439022243023,
      -0.013947337865829468,
      -0.03305494785308838,
      0.01786889135837555,
      0.026360370218753815,
      -0.01899099163711071,
      -0.04322566092014313,
      0.024861464276909828,
      0.021369988098740578,
      -0.0038971013855189085,
      -0.09526890516281128,
      -0.0003129807591903955,
      0.058029405772686005,
      -0.049446284770965576,
      -0.05605750158429146,
      0.040367428213357925,
      -0.04006052762269974,
      0.06510812789201736,
      0.06793158501386642,
      0.11076925694942474,
      0.06160977855324745,
      0.0307777039706707,
      0.06799519807100296,
      0.02054504305124283,
      0.0035034301690757275,
      -0.046849485486745834,
      -0.03876654803752899,
      -0.004374938551336527,
      0.05819528177380562,
      -0.006267036776989698,
      0.0033638470340520144,
      0.002019567182287574,
      -0.0027593597769737244,
      0.0509190671145916,
      0.01045774482190609,
      -0.03967846557497978,
      0.03655136749148369,
      -0.07550210505723953,
      -0.03354290872812271,
      -0.050850313156843185,
      0.031282298266887665,
      0.005438811611384153,
      -0.020071910694241524,
      -0.005838870070874691,
      -0.022794954478740692,
      -0.0624600350856781,
      -0.039854831993579865,
      -0.11316302418708801,
      -0.020313924178481102,
      0.06221795827150345,
      -0.017153728753328323,
      0.018543746322393417,
      -0.033713266253471375,
      -0.018538454547524452,
      -0.012735653668642044,
      -0.051035325974226,
      0.006024154834449291,
      0.012075405567884445,
      0.07334411144256592,
      -0.01704033836722374,
      0.07784406840801239,
      0.03651046007871628,
      0.025257235392928123,
      0.04967287555336952,
      -0.017140734940767288,
      0.05518001317977905,
      -0.03237764164805412,
      -0.09272407740354538,
      0.022598562762141228,
      0.005565853323787451,
      -0.054686449468135834,
      0.019724447280168533,
      0.10027607530355453,
      -0.027267852798104286,
      -0.024460820481181145,
      -0.04029782488942146,
      -0.010024981573224068,
      0.09426748752593994,
      0.006532218307256699,
      -0.02400163747370243,
      -0.06518808752298355,
      -0.07501780986785889,
      -0.02963751181960106,
      0.08093907684087753,
      0.028895478695631027,
      0.043427783995866776,
      0.004962327424436808,
      0.026137690991163254,
      0.04073614999651909,
      -0.03695700690150261,
      0.009348015300929546,
      -0.03805280476808548,
      0.05803549289703369,
      -0.03704434633255005,
      0.10541444271802902,
      0.01282450370490551,
      0.013917181640863419,
      0.0226373802870512,
      -0.011194700375199318,
      0.07558244466781616,
      -0.055918823927640915,
      -0.08573614805936813,
      0.02801772765815258,
      -0.06916414946317673,
      0.06592438369989395,
      -0.05976228788495064,
      -0.06408137083053589,
      -0.050208672881126404,
      0.0014410204021260142,
      0.02452743984758854,
      -0.0335870161652565,
      -0.021609075367450714,
      0.009542327374219894,
      -0.06313751637935638,
      0.05792121961712837,
      0.012187574990093708,
      0.020854182541370392,
      -0.03303094208240509,
      0.03402526304125786,
      0.022169997915625572,
      0.0195249542593956,
      0.030884668231010437,
      -0.06278645247220993,
      -0.030714979395270348,
      0.05799799785017967,
      0.05388091504573822,
      -0.07460250705480576,
      0.038707099854946136,
      -0.0657152384519577,
      0.008448206819593906,
      -0.0419633723795414,
      0.0008952111238613725,
      -0.0340200699865818,
      -0.013646015897393227,
      -0.01590481959283352,
      0.004058388061821461,
      -0.026094218716025352,
      -0.11539123952388763,
      -0.012704036198556423,
      0.026677265763282776,
      -0.030784688889980316,
      -0.05260371044278145,
      0.04103418067097664,
      -0.027037963271141052,
      -0.015258576720952988,
      0.044032756239175797,
      0.015168027952313423,
      0.014858197420835495,
      -0.01628229022026062,
      0.041868243366479874,
      -0.01414309162646532,
      -0.0326911062002182,
      -0.05383449047803879,
      -0.06020684167742729,
      0.049462903290987015,
      0.003833530703559518,
      0.08558130264282227,
      0.04028993844985962,
      0.024550553411245346,
      0.04842299222946167,
      0.07179803401231766,
      -0.058164991438388824,
      0.02754366770386696,
      -0.04916390776634216,
      -0.07770773023366928,
      -0.09182649850845337,
      0.030950479209423065,
      0.06541100889444351,
      0.0115300128236413,
      -0.07108869403600693,
      -0.01886744610965252,
      -0.044140640646219254,
      0.0660584345459938,
      0.0013216084334999323,
      -0.031903818249702454,
      0.06911346316337585,
      -0.020722633227705956,
      -0.06280306726694107,
      -0.031160028651356697,
      0.024056993424892426,
      -0.00704707857221365,
      -0.0018226061947643757,
      -0.004373001866042614,
      -0.00028880187892355025,
      0.02713962271809578,
      -0.03578731417655945,
      -0.04348490387201309,
      -0.0068360501900315285,
      -0.037549976259469986
    ],
    [
      0.05734575539827347,
      -0.031213009729981422,
      -0.031358134001493454,
      -0.01110950019210577,
      0.004828865174204111,
      0.0012877106200903654,
      0.004950649105012417,
      0.030405530706048012,
      0.025936024263501167,
      0.11212559044361115,
      -0.007285598665475845,
      0.017618950456380844,
      -0.06428124010562897,
      -0.010194226168096066,
      0.0884656235575676,
      0.015826167538762093,
      0.04977631941437721,
      -0.04081188142299652,
      0.0038476167246699333,
      0.004716362804174423,
      0.038389138877391815,
      0.012909305281937122,
      0.03710722178220749,
      -0.03484649211168289,
      -0.01348372083157301,
      -0.04493064060807228,
      -0.012601839378476143,
      0.06804630905389786,
      -0.08195286989212036,
      -0.015017935074865818,
      0.017907097935676575,
      0.028073735535144806,
      0.08090882003307343,
      -0.06851663440465927,
      -0.021972818300127983,
      0.00903947651386261,
      0.011576958931982517,
      0.03137172386050224,
      -0.052930690348148346,
      0.004847332835197449,
      0.004180439282208681,
      -0.08019012957811356,
      -0.011965065263211727,
      0.029253581538796425,
      0.013074025511741638,
      -0.05149368196725845,
      0.0020475653000175953,
      -0.0030093577224761248,
      -0.07836928963661194,
      0.048163387924432755,
      -0.03644401580095291,
      -0.007417168002575636,
      -0.0117771215736866,
      -0.0327443927526474,
      -0.02024739794433117,
      0.00685043865814805,
      -0.03800104558467865,
      -0.016803110018372536,
      0.0004958047647960484,
      -0.02159465104341507,
      -0.029845885932445526,
      0.046358153223991394,
      -0.061433061957359314,
      -0.034942131489515305,
      0.08304999768733978,
      -0.006324608810245991,
      0.007419271394610405,
      -0.03404169902205467,
      0.010106712579727173,
      -0.046550728380680084,
      0.009155990555882454,
      0.07138955593109131,
      -0.017310816794633865,
      -0.028771046549081802,
      0.046406541019678116,
      -0.024161161854863167,
      -0.03345821797847748,
      -0.07353116571903229,
      -0.01533741969615221,
      0.0304355900734663,
      0.0686035305261612,
      -0.056022386997938156,
      -0.04665233939886093,
      0.025735028088092804,
      0.03798411041498184,
      0.032268963754177094,
      -0.10539475828409195,
      -0.026525380089879036,
      0.05016002431511879,
      -0.018770731985569,
      -0.042235180735588074,
      -0.06673803925514221,
      -0.10762187838554382,
      0.05341336503624916,
      0.02558121643960476,
      -0.056612949818372726,
      0.018761783838272095,
      -0.059257134795188904,
      -0.039198216050863266,
      -0.061054471880197525,
      -0.006982310675084591,
      0.016310960054397583,
      0.09615784138441086,
      -0.03002047911286354,
      -0.0326375812292099,
      -0.01101691834628582,
      -0.015511914156377316,
      -0.0226327795535326,
      -0.004273021128028631,
      0.027104318141937256,
      -0.10994771867990494,
      -0.06941903382539749,
      -0.008816719055175781,
      0.03352639079093933,
      0.005662888754159212,
      -0.09650769829750061,
      -0.03488890081644058,
      -0.05955283343791962,
      -0.05299358442425728,
      0.0502375029027462,
      0.012201794423162937,
      -0.04355241730809212,
      0.029124928638339043,
      -0.0003696560161188245,
      -0.06990393996238708,
      0.02010979875922203,
      -0.057472411543130875,
      -0.030735323205590248,
      0.04491637647151947,
      0.052989959716796875,
      -0.055111926048994064,
      -0.01973479799926281,
      0.029628362506628036,
      0.0055459039285779,
      -0.0023158802650868893,
      -0.022361334413290024,
      0.01580915041267872,
      -0.0010172162437811494,
      -0.024074789136648178,
      -0.036990225315093994,
      0.05035032704472542,
      -0.0887180045247078,
      0.0172240249812603,
      -0.012058465741574764,
      0.050637438893318176,
      -0.10793595761060715,
      0.03864872083067894,
      0.05525277182459831,
      0.07682925462722778,
      0.05285026878118515,
      -0.0813329741358757,
      -0.033564645797014236,
      -0.01255203690379858,
      0.05437126010656357,
      0.05534319207072258,
      -0.035138484090566635,
      0.01947038806974888,
      -0.021784668788313866,
      -0.04540948569774628,
      0.033235397189855576,
      -0.08241081237792969,
      0.06942961364984512,
      0.030743921175599098,
      -0.005966829601675272,
      -0.06317751854658127,
      0.013404970988631248,
      0.025230366736650467,
      0.014296762645244598,
      -0.056321948766708374,
      0.030304528772830963,
      0.03153633698821068,
      0.008600203320384026,
      -0.024586642161011696,
      -0.0589851513504982,
      0.013946828432381153,
      0.07223869115114212,
      -0.041511863470077515,
      0.04427593573927879,
      0.0239479448646307,
      -0.026488710194826126,
      0.04976797103881836,
      0.05250559747219086,
      0.04522514343261719,
      0.015149212442338467,
      0.018851248547434807,
      -0.037968311458826065,
      0.031745295971632004,
      0.05410384014248848,
      0.04310091957449913,
      0.0424666628241539,
      0.01150522194802761,
      -0.023044127970933914,
      -0.04822712391614914,
      -0.06485728919506073,
      0.013109431602060795,
      0.014283574186265469,
      -0.0049456339329481125,
      -0.024260010570287704,
      0.07879836112260818,
      0.06109734997153282,
      0.061579566448926926,
      -0.00789294671267271,
      0.019201846793293953,
      -0.026878198608756065,
      0.02417318895459175,
      -0.008669648319482803,
      0.05954562872648239,
      -0.016134075820446014,
      0.028671804815530777,
      0.0008847274584695697,
      -0.11161579191684723,
      -0.01357069332152605,
      0.05124063789844513,
      0.0079342070966959,
      0.015442165546119213,
      0.06413336098194122,
      -0.01044231466948986,
      -0.035135768353939056,
      -0.05695713311433792,
      -0.014889627695083618,
      0.031874436885118484,
      -0.00721284095197916,
      0.05734099820256233,
      0.023709289729595184,
      0.017419535666704178,
      0.0024928217753767967,
      0.1007063165307045,
      0.022004319354891777,
      -0.0008217841386795044,
      -0.03348822146654129,
      -0.01751183718442917,
      0.042850252240896225,
      -0.08435097336769104,
      -0.10141931474208832,
      0.010297143831849098,
      -0.02422964572906494,
      -0.00024267360277008265,
      0.09578834474086761,
      -0.014399521052837372,
      0.04854157939553261,
      -0.0668799951672554,
      0.07575146853923798,
      -0.005585140082985163,
      0.04208777844905853,
      -0.030963275581598282,
      0.04034077003598213,
      0.020995989441871643,
      0.027924245223402977,
      0.06750056147575378,
      -0.042346641421318054,
      0.008067731745541096,
      0.018340900540351868,
      -0.07063952833414078,
      -0.0031080644112080336,
      -0.04685816168785095,
      -0.04149061068892479,
      0.017528945580124855,
      -0.004427043721079826,
      0.009547768160700798,
      0.037182897329330444,
      -0.014514422975480556,
      -0.01121433638036251,
      0.05295834317803383,
      0.05674107372760773,
      -0.0020204230677336454,
      0.017796333879232407,
      -0.03022834286093712,
      -0.009142446331679821,
      0.004871710203588009,
      -0.03321782127022743,
      -0.07326917350292206,
      -0.09293397516012192,
      -0.035229843109846115,
      -0.0030542993918061256,
      -0.0281785000115633,
      0.019049042835831642,
      0.03961759805679321,
      -0.006738458760082722,
      0.025167373940348625,
      0.016423184424638748,
      -0.0384950153529644,
      -0.0019450484542176127,
      -0.03320843353867531,
      -0.03192674741148949,
      -0.08924952149391174,
      0.015417292714118958,
      -0.0725596696138382,
      -0.02841237001121044,
      0.002725665457546711,
      0.05559016391634941,
      -0.018106425181031227,
      0.026645461097359657,
      -0.030239511281251907,
      -0.0037166238762438297,
      0.05096758157014847,
      0.017732132226228714,
      -0.028504876419901848,
      -0.04191542789340019,
      0.032019201666116714,
      0.030247887596488,
      -0.06691952049732208,
      0.04097672179341316,
      0.05336739867925644,
      0.01260882243514061,
      -0.04100823402404785,
      0.052808403968811035,
      -0.021520426496863365,
      -0.01703091897070408,
      -0.06270250678062439,
      0.014663618057966232,
      0.06765653938055038,
      -0.00954659003764391,
      0.06789441406726837,
      0.11147759109735489,
      -0.03939647227525711,
      0.009474380873143673,
      -0.02098890021443367,
      -0.00011775696475524455,
      0.03241436183452606,
      -0.009213006123900414,
      -0.057140156626701355,
      0.01894516311585903,
      -0.03340183198451996,
      -0.04040795564651489,
      -0.055057596415281296,
      0.002670037792995572,
      -0.022286172956228256,
      -0.036443617194890976,
      -0.06549851596355438,
      0.027802906930446625,
      0.02512756548821926,
      -0.06751836836338043,
      0.011416550725698471,
      0.006132232490926981,
      0.09111963212490082,
      0.045071080327034,
      0.08539581298828125,
      -0.02336612530052662,
      0.055702440440654755,
      0.007821311242878437,
      0.030362918972969055,
      -0.028346702456474304,
      0.03423771262168884,
      0.019115492701530457,
      0.06370727717876434,
      -0.1568969339132309,
      0.02059410698711872,
      0.04379116743803024,
      -0.06671875715255737,
      0.041710205376148224,
      -0.010015545412898064,
      -0.04003163054585457,
      -0.10663891583681107,
      -0.041486822068691254,
      -0.020960498601198196,
      -0.04227430745959282,
      -0.0155586963519454,
      -0.019351795315742493,
      -0.0024436062667518854,
      0.031117888167500496,
      0.016403350979089737,
      -0.038209497928619385,
      0.0378425270318985,
      0.03567156568169594,
      -0.03323620930314064,
      0.02834068424999714,
      -0.047788240015506744,
      -0.0031404243782162666,
      0.022231148555874825,
      -0.07604773342609406,
      0.021375441923737526,
      0.04323379322886467,
      0.012996552512049675,
      0.00381644768640399,
      0.02321477048099041,
      -0.020015140995383263,
      -0.06344687938690186,
      0.03466896340250969,
      -0.036500442773103714,
      -0.0438019260764122,
      0.04655315726995468,
      -0.04808557778596878,
      -0.06666682660579681,
      -0.11407195776700974,
      -0.01979752443730831,
      -0.008663136512041092,
      -0.027253754436969757,
      0.04886547103524208,
      0.069913849234581,
      -0.04175372049212456,
      -0.03608819469809532,
      0.04187740758061409,
      -0.045386530458927155,
      -0.04248692840337753,
      0.01898546703159809,
      -0.07193795591592789,
      0.017716756090521812,
      0.06957391649484634,
      0.03961775079369545,
      -0.04546249657869339,
      -0.017529288306832314,
      -0.054435014724731445,
      0.04585912078619003,
      0.029352907091379166,
      0.004022756591439247,
      -0.0009362754644826055,
      0.05730282887816429,
      -0.015827450901269913,
      0.027008498087525368,
      0.025373609736561775,
      0.028873275965452194,
      0.024601439014077187,
      0.037649691104888916,
      0.080833300948143,
      0.08575216680765152,
      0.05168844759464264,
      -0.06481363624334335,
      -0.05103436857461929,
      -0.015619570389389992,
      -0.04087527096271515,
      -0.008174637332558632,
      -0.030638515949249268,
      0.03317161649465561,
      -0.003886778373271227,
      -0.018273228779435158,
      0.023116162046790123,
      0.047020889818668365,
      -0.006023196037858725,
      0.01011035405099392,
      0.07902423292398453,
      0.05700325220823288,
      0.007450683042407036,
      0.0288991816341877,
      0.018614444881677628,
      -0.0002775649481918663,
      0.006252924911677837,
      0.002627359004691243,
      0.07032729685306549,
      0.019942933693528175,
      0.03482985123991966,
      0.0004300231521483511,
      0.04070492461323738,
      0.003667527576908469,
      0.020061761140823364,
      -0.014471245929598808,
      -0.012113049626350403,
      -0.06104498356580734,
      -0.034221112728118896,
      -0.003362269140779972,
      6.672366725979373e-05,
      0.01358791533857584,
      -0.010563556104898453,
      0.03393668681383133,
      -0.008793647401034832,
      -0.044256843626499176,
      -0.06419829279184341,
      0.008363735862076283,
      -0.039973318576812744,
      -0.09110155701637268,
      0.051322486251592636,
      -0.09507746249437332,
      -0.04217148944735527,
      0.03949851915240288,
      0.024409957230091095,
      -0.05386735498905182,
      -0.045295003801584244,
      0.10524585843086243,
      -0.011651930399239063,
      -0.11302899569272995,
      -0.024519076570868492,
      -0.005757180042564869,
      0.03784268721938133,
      -0.02660871483385563,
      0.03946415334939957,
      0.028464587405323982,
      0.10017301887273788,
      -0.06873642653226852,
      0.0441468246281147,
      -0.0993509367108345,
      -0.034258827567100525,
      0.06352434307336807,
      -0.001396177802234888,
      -0.006208750419318676,
      0.005796494893729687,
      -0.018748458474874496,
      -0.0007741230074316263,
      0.027086447924375534,
      0.028190014883875847,
      -0.021066030487418175,
      0.08232967555522919,
      0.03272401914000511,
      -0.005272027105093002,
      0.05169300734996796,
      0.026415834203362465,
      -0.028018571436405182,
      -0.04793186113238335,
      -0.0380040667951107,
      0.05568879842758179,
      -0.08861402422189713,
      -0.01961003802716732,
      -0.04010932520031929,
      -0.04871156066656113,
      -0.03602296859025955
    ],
    [
      -0.013499314896762371,
      0.02163083106279373,
      -0.03848624601960182,
      0.05792707949876785,
      -0.03277925029397011,
      -0.06271872669458389,
      -0.03050973266363144,
      -0.07698015123605728,
      0.05788594111800194,
      -0.010214550420641899,
      0.08801089972257614,
      0.027470773085951805,
      -0.06992931663990021,
      0.014617422595620155,
      0.05589441582560539,
      0.0009773204801604152,
      -0.08020283281803131,
      0.010525204241275787,
      -0.003987595904618502,
      0.08110745996236801,
      0.02648671716451645,
      -0.016112960875034332,
      -0.0016579843359068036,
      0.07166510820388794,
      -0.12569937109947205,
      0.08158168196678162,
      -0.0036310101859271526,
      0.04756077378988266,
      -0.006752773653715849,
      0.03855394572019577,
      0.04530647024512291,
      0.08170320093631744,
      0.06447996944189072,
      -0.03692518174648285,
      0.013820094056427479,
      0.017279071733355522,
      0.027341198176145554,
      -0.02189967967569828,
      -0.06417864561080933,
      -0.0725555494427681,
      0.00909594725817442,
      -0.044666390866041183,
      0.08854953944683075,
      0.05012427270412445,
      -0.017726493999361992,
      -0.019277608022093773,
      0.0497097410261631,
      0.047325070947408676,
      0.004291228950023651,
      0.0610094889998436,
      0.017223814502358437,
      -0.022050073370337486,
      -0.04129725694656372,
      0.011977571994066238,
      0.056146908551454544,
      -0.01681682839989662,
      0.02552066184580326,
      0.0238741897046566,
      0.02539355307817459,
      -0.029911618679761887,
      0.009770462289452553,
      -0.10923929512500763,
      0.0522942915558815,
      -0.02623170241713524,
      0.05381418764591217,
      -0.04146118089556694,
      -0.0982988253235817,
      0.01065794937312603,
      -0.02539381943643093,
      -0.003324745688587427,
      -0.03049551509320736,
      -0.048024389892816544,
      -0.0035982427652925253,
      7.760857261018828e-05,
      0.02645869180560112,
      0.03304619342088699,
      -0.0032636704854667187,
      0.01556166261434555,
      -0.027744324877858162,
      0.03332099691033363,
      0.019808372482657433,
      -0.003740923712030053,
      0.05312582105398178,
      -0.02704242244362831,
      0.025322100147604942,
      0.12664619088172913,
      -0.028736945241689682,
      -0.03877836465835571,
      0.010724370367825031,
      0.006442652549594641,
      -0.05192733556032181,
      -0.01992284692823887,
      0.009575404226779938,
      0.0450638011097908,
      -0.0180806964635849,
      -0.01681521162390709,
      -0.033768270164728165,
      0.023078832775354385,
      -0.006411293055862188,
      0.011887426488101482,
      0.07762729376554489,
      -0.05487333610653877,
      0.05990922823548317,
      0.08187206834554672,
      -0.014815684407949448,
      0.028343578800559044,
      -0.0580231212079525,
      0.01650046743452549,
      0.04545925557613373,
      -0.011319993995130062,
      -0.03568647429347038,
      -0.06749486923217773,
      0.01674809120595455,
      -0.029999537393450737,
      0.01599583774805069,
      0.04673474282026291,
      -0.011719324626028538,
      0.07033301889896393,
      0.047326281666755676,
      0.03595154359936714,
      0.007312207482755184,
      -0.035816315561532974,
      -0.02984071709215641,
      0.05249086767435074,
      0.04155789688229561,
      0.052377890795469284,
      0.0733383446931839,
      -0.011266907677054405,
      -0.035618312656879425,
      0.03880064934492111,
      -0.02282688021659851,
      0.005121214780956507,
      -0.051581330597400665,
      -0.05315014347434044,
      -0.022179564461112022,
      0.03775239735841751,
      -0.014172276481986046,
      -0.03201358765363693,
      -0.0004546027339529246,
      0.025457222014665604,
      -0.061560533940792084,
      0.022753391414880753,
      0.032686151564121246,
      0.007584169507026672,
      0.13361825048923492,
      -0.07846029102802277,
      0.00191580131649971,
      -0.028912503272294998,
      0.024220284074544907,
      -0.036156341433525085,
      0.011142699047923088,
      0.012412356212735176,
      -0.02039352059364319,
      0.021115770563483238,
      0.008844194002449512,
      0.01324303075671196,
      -0.018334917724132538,
      -0.04396659508347511,
      0.00843059178441763,
      0.06192086637020111,
      -0.0687359943985939,
      0.031444113701581955,
      -0.03206159174442291,
      -0.05635322630405426,
      -0.03438154608011246,
      0.06491763144731522,
      0.035817403346300125,
      -0.0660325437784195,
      0.01040875818580389,
      0.06201465055346489,
      0.023757074028253555,
      -0.029427465051412582,
      0.0035077885258942842,
      -0.04374266415834427,
      0.008726651780307293,
      0.029372969642281532,
      0.03093743324279785,
      0.10227811336517334,
      0.06655796617269516,
      -0.034577518701553345,
      0.07773292064666748,
      0.11160650104284286,
      0.013625388033688068,
      -0.0003432057274039835,
      -0.056128162890672684,
      0.03854058310389519,
      0.027422187849879265,
      -0.06479233503341675,
      0.04254862666130066,
      0.05412091687321663,
      -0.03978055715560913,
      -0.0599202997982502,
      -0.000755759421736002,
      -0.01604914851486683,
      -0.040517326444387436,
      0.004386119078844786,
      0.029082922264933586,
      -0.009437509812414646,
      -0.03220058232545853,
      0.057789482176303864,
      -0.026281334459781647,
      0.029174121096730232,
      0.03210485354065895,
      -0.06252197176218033,
      0.008855274878442287,
      -0.0325746089220047,
      0.07875719666481018,
      0.02111964114010334,
      -0.09795674681663513,
      0.07349832355976105,
      0.017788991332054138,
      0.0013785728951916099,
      -0.0038752825930714607,
      0.04449930787086487,
      0.0454346127808094,
      0.016551662236452103,
      0.008815120905637741,
      -0.053942278027534485,
      0.042010288685560226,
      -0.05677296593785286,
      0.002540528541430831,
      -0.03984508663415909,
      -0.03313253074884415,
      0.008709530346095562,
      0.022383278235793114,
      0.002837525447830558,
      0.06947886198759079,
      0.09679117053747177,
      -0.05745678395032883,
      0.03563908115029335,
      0.003415845101699233,
      -0.007958896458148956,
      0.03479563444852829,
      -0.007608601823449135,
      0.08574420213699341,
      -0.06432564556598663,
      0.009051955305039883,
      0.014325026422739029,
      -0.06014198064804077,
      0.030852921307086945,
      -0.017179235816001892,
      0.0030780232045799494,
      0.037049513310194016,
      -0.028949856758117676,
      0.027107732370495796,
      -0.04353383928537369,
      -0.041702453047037125,
      -0.03627500310540199,
      0.014942776411771774,
      -0.015299653634428978,
      -0.039419785141944885,
      0.00024440069682896137,
      0.051472458988428116,
      0.06896205246448517,
      -0.057379744946956635,
      -0.04552409425377846,
      -0.04021342843770981,
      -0.02698601968586445,
      -0.017771033570170403,
      -0.02552950009703636,
      -0.035935983061790466,
      0.019746556878089905,
      0.01889602094888687,
      0.03195043280720711,
      0.008323018439114094,
      0.006768872030079365,
      0.0470263697206974,
      -0.011445092037320137,
      -0.08303271979093552,
      -0.06814256310462952,
      -0.06292659044265747,
      -0.05612405017018318,
      -0.024523843079805374,
      -0.06443904340267181,
      0.0006412623333744705,
      -0.015462764538824558,
      0.05180346593260765,
      8.862214599503204e-05,
      0.03826609626412392,
      -0.03350751847028732,
      0.0637216866016388,
      0.017843492329120636,
      -0.08930840343236923,
      -0.028905397281050682,
      0.00762901408597827,
      -0.08220956474542618,
      -0.0011384255485609174,
      0.0006853056838735938,
      -0.04810416325926781,
      -0.06097302958369255,
      0.03580256551504135,
      -0.05287948250770569,
      0.016260143369436264,
      -0.06041977182030678,
      0.06030187010765076,
      -0.0017164769815281034,
      -0.01272717583924532,
      -0.023248953744769096,
      0.014847257174551487,
      0.004651989322155714,
      0.02213779091835022,
      -0.06791260093450546,
      0.04996156692504883,
      0.017364894971251488,
      -0.0322454534471035,
      0.04470454528927803,
      0.00510582746937871,
      -0.03206979110836983,
      0.053023811429739,
      0.004479491617530584,
      -0.042198579758405685,
      -0.0248579028993845,
      0.026109958067536354,
      -0.008109048008918762,
      0.030094515532255173,
      -0.054071810096502304,
      0.045026734471321106,
      -0.01094873622059822,
      -0.004403224680572748,
      -0.009094341658055782,
      0.020196909084916115,
      0.004987067077308893,
      -0.03689490631222725,
      0.12476567178964615,
      -0.003311192151159048,
      0.04268829897046089,
      0.05006399750709534,
      -0.016899557784199715,
      -0.01362363900989294,
      0.03551667556166649,
      0.037839606404304504,
      -0.09294034540653229,
      0.04260680079460144,
      0.010926051996648312,
      -0.05316130816936493,
      -0.031713638454675674,
      0.058634161949157715,
      0.09246847033500671,
      -0.01917046494781971,
      -0.041101325303316116,
      0.01797690987586975,
      0.061392247676849365,
      -0.001356396358460188,
      -0.034673720598220825,
      -0.059252362698316574,
      0.005024276208132505,
      0.020703909918665886,
      -0.06864740699529648,
      -0.0005523232975974679,
      0.09301795810461044,
      0.01806393824517727,
      0.016304444521665573,
      -0.03659691661596298,
      0.05901600047945976,
      0.023598359897732735,
      -0.033277787268161774,
      0.03656738996505737,
      0.002580467611551285,
      -0.040589988231658936,
      -0.0479043573141098,
      -0.03623603656888008,
      0.034959953278303146,
      -0.03996465355157852,
      0.04422560706734657,
      -0.011035134084522724,
      -0.005649816710501909,
      -0.033959001302719116,
      -0.023410717025399208,
      -0.03963156417012215,
      0.04447932168841362,
      0.0002770402352325618,
      0.03236350044608116,
      -0.04525279253721237,
      -0.03469110652804375,
      0.05169214680790901,
      0.013256560079753399,
      0.017424093559384346,
      -0.01975984312593937,
      0.04586373269557953,
      -0.03666079789400101,
      -0.004290980286896229,
      -0.08623052388429642,
      -0.04210197553038597,
      -0.004903322085738182,
      -0.016161547973752022,
      0.07662491500377655,
      -0.04309753701090813,
      -0.01802809350192547,
      -0.052685678005218506,
      -0.0403309240937233,
      -0.04602435603737831,
      -0.01795203797519207,
      0.026267731562256813,
      0.017047569155693054,
      0.00882382970303297,
      0.03311721235513687,
      -0.07712851464748383,
      0.018735641613602638,
      -0.0032983566634356976,
      -0.030601339414715767,
      -0.007710221689194441,
      -0.1342826634645462,
      0.04822589457035065,
      0.07047169655561447,
      0.07665862888097763,
      -0.10025368630886078,
      0.059585150331258774,
      -0.011158602312207222,
      -0.006484335754066706,
      -0.017384927719831467,
      -0.01366809569299221,
      0.03514593839645386,
      -0.09825366735458374,
      0.00993699487298727,
      0.05655912682414055,
      -0.01095099188387394,
      0.0709158405661583,
      0.02555249258875847,
      -0.008625362999737263,
      -0.08186673372983932,
      -0.02640393376350403,
      0.03194490447640419,
      0.03995802253484726,
      -0.014640994369983673,
      -0.054700370877981186,
      0.05830973759293556,
      -0.010656666941940784,
      -0.03432925418019295,
      0.0061764405108988285,
      0.10699930787086487,
      -0.003184692235663533,
      -0.026573197916150093,
      -0.005023714154958725,
      -0.06803976744413376,
      0.04625868424773216,
      -0.07445205003023148,
      -0.034003689885139465,
      0.016651302576065063,
      0.06162417307496071,
      0.05472481995820999,
      0.03371642157435417,
      -0.005040615331381559,
      -0.04885143041610718,
      0.00687306746840477,
      0.02949848212301731,
      -0.017801212146878242,
      -0.044852565973997116,
      0.061867643147706985,
      0.05526932701468468,
      -0.07738245278596878,
      0.03947954624891281,
      0.0404379740357399,
      -0.04973720759153366,
      0.02762165293097496,
      0.036892104893922806,
      0.02199164777994156,
      -0.12108344584703445,
      0.11053631454706192,
      0.029009290039539337,
      0.05028989911079407,
      0.06095069646835327,
      0.017167920246720314,
      -0.056000810116529465,
      0.0084901824593544,
      0.0026897129137068987,
      -0.0013380458112806082,
      -0.05971059948205948,
      -0.02279089204967022,
      -0.04967080056667328,
      -0.021062729880213737,
      0.08586997538805008,
      0.013040599413216114,
      0.018387189134955406,
      0.050140030682086945,
      0.0545557402074337,
      -0.017360474914312363,
      -0.020283203572034836,
      0.06697843223810196,
      -0.057332854717969894,
      -0.06172971427440643,
      0.07353127002716064,
      0.06765387952327728,
      0.031620316207408905,
      0.005522920284420252,
      0.10717426240444183,
      -0.015102863311767578,
      0.011500908993184566,
      0.04667958617210388,
      0.02930993214249611,
      0.06289297342300415,
      0.03012673370540142,
      -0.12001017481088638,
      0.007152157370001078,
      -0.021093564108014107,
      0.045730311423540115,
      0.09693649411201477,
      -0.03996020928025246,
      -0.04111813008785248,
      -0.05496242269873619,
      0.07126753032207489,
      -0.06122826784849167,
      -0.01883827894926071,
      0.030271410942077637
    ],
    [
      -0.03845946118235588,
      -0.006863064132630825,
      0.02971561811864376,
      -0.003613380016759038,
      -0.060975976288318634,
      -0.03619023784995079,
      -0.0055303568951785564,
      0.03285622596740723,
      -0.04055188223719597,
      0.06978494673967361,
      0.0042862556874752045,
      -0.009586869738996029,
      0.027843380346894264,
      -0.025822050869464874,
      -0.053905025124549866,
      0.06194132938981056,
      -0.029014216735959053,
      -0.006779452785849571,
      0.0791831836104393,
      -0.051484741270542145,
      0.05120032653212547,
      -0.027643095701932907,
      0.012241200543940067,
      0.021116849035024643,
      0.0333072692155838,
      0.008243998512625694,
      0.0038864300586283207,
      -0.001987906638532877,
      -0.00847217720001936,
      0.007770461030304432,
      -0.02103327587246895,
      -0.0012993874261155725,
      -0.003964505158364773,
      0.017664708197116852,
      -0.0046163685619831085,
      -0.018191557377576828,
      0.04967286437749863,
      -0.040355097502470016,
      0.008228480815887451,
      -0.08340907841920853,
      0.010836449451744556,
      -0.03786005824804306,
      0.021119236946105957,
      0.05154838413000107,
      -0.052985779941082,
      0.012534001842141151,
      -0.05333700403571129,
      -0.031478025019168854,
      -0.05531785264611244,
      -0.058620065450668335,
      0.008784327656030655,
      -0.06363516300916672,
      0.02903326414525509,
      -0.04555227607488632,
      -0.04686465486884117,
      0.0034491734113544226,
      -0.12583114206790924,
      -0.08022937923669815,
      0.008653148077428341,
      0.035514287650585175,
      0.0035567665472626686,
      -0.004862439353018999,
      0.045169297605752945,
      0.03580837324261665,
      -0.021423833444714546,
      -0.11499378830194473,
      -0.01461799256503582,
      0.03477859869599342,
      0.01152774877846241,
      -0.017861196771264076,
      0.027486562728881836,
      -0.008433744311332703,
      -0.057950399816036224,
      0.013423117808997631,
      0.08593681454658508,
      0.06945402175188065,
      0.007642967626452446,
      -0.0004082542145624757,
      -0.011350870132446289,
      0.014500976540148258,
      0.06819208711385727,
      0.09014809131622314,
      0.0485784187912941,
      0.027318989858031273,
      0.02842225506901741,
      0.06493432074785233,
      -0.029663849622011185,
      -0.009579196572303772,
      0.07140210270881653,
      0.0105860885232687,
      0.02558220736682415,
      -0.07361206412315369,
      0.03840373456478119,
      0.06888903677463531,
      -0.08518227189779282,
      -0.011562329716980457,
      0.06702282279729843,
      0.07329460233449936,
      0.03789764642715454,
      -0.007977161556482315,
      0.019362302497029305,
      -0.0693180114030838,
      -0.039140988141298294,
      -0.05482803285121918,
      -0.037951335310935974,
      -0.05880017578601837,
      0.006473257206380367,
      0.020825326442718506,
      -0.01928465999662876,
      -0.04280088469386101,
      -0.0004856462182942778,
      0.005872699897736311,
      0.03155675157904625,
      0.031011035665869713,
      0.03875774145126343,
      0.052252087742090225,
      0.021425124257802963,
      0.00150939985178411,
      -0.00279480149038136,
      0.03054005652666092,
      0.04141579568386078,
      -0.10526710748672485,
      -0.017248522490262985,
      0.027889052405953407,
      0.09626976400613785,
      0.07142826914787292,
      0.04742463305592537,
      0.004856904037296772,
      -0.017461298033595085,
      -0.0008190948865376413,
      -0.04149765521287918,
      -0.03329925984144211,
      -0.08762860298156738,
      0.05882783979177475,
      -0.007989618927240372,
      -0.008392111398279667,
      -0.03982056677341461,
      -0.05503598973155022,
      0.0664125308394432,
      -0.007033162284642458,
      0.015070840716362,
      0.01797962747514248,
      -0.011784245260059834,
      -0.006890652701258659,
      -0.0366860032081604,
      -0.008436493575572968,
      0.00387175683863461,
      0.029130633920431137,
      -0.06086048483848572,
      -0.0034165375400334597,
      -0.034609224647283554,
      0.0047899470664560795,
      0.09293344616889954,
      0.037191275507211685,
      -0.0702509731054306,
      -0.03027748502790928,
      -0.13494986295700073,
      -0.02155282348394394,
      0.011012636125087738,
      -0.026255963370203972,
      -0.021613625809550285,
      -0.022928917780518532,
      0.05453484505414963,
      -0.00709827896207571,
      0.0006438329583033919,
      -0.010168676264584064,
      0.034436050802469254,
      0.0639849603176117,
      -0.008795495145022869,
      0.0038216146640479565,
      -0.02451559528708458,
      0.053986694663763046,
      0.034788571298122406,
      0.016230881214141846,
      0.0497886948287487,
      0.03897566348314285,
      0.038600023835897446,
      0.08841360360383987,
      -0.04015827178955078,
      0.01992650330066681,
      -0.015353238210082054,
      -0.008661260828375816,
      0.023312821984291077,
      -0.048400282859802246,
      -0.009196107275784016,
      -0.006970570422708988,
      0.005581256467849016,
      0.03697004169225693,
      -0.06651800125837326,
      -0.011784201487898827,
      0.03180638700723648,
      -0.01762886345386505,
      -0.02949281595647335,
      -0.05026669055223465,
      -0.017972316592931747,
      0.034035731106996536,
      -0.03316856548190117,
      0.0354926697909832,
      -0.042681336402893066,
      0.018995296210050583,
      -0.02016323432326317,
      0.00496117165312171,
      0.032348182052373886,
      -0.03711489960551262,
      0.027339091524481773,
      0.018222671002149582,
      -0.0629177913069725,
      -0.03930950164794922,
      -0.05124290660023689,
      -0.02582346647977829,
      0.05805056169629097,
      0.01572192832827568,
      0.014638816937804222,
      0.06656884402036667,
      -0.0807313397526741,
      -0.06149233877658844,
      0.011680446565151215,
      -0.02024720422923565,
      -0.0011876759817823768,
      -0.10722188651561737,
      -0.05052446573972702,
      -0.03739681839942932,
      0.12074360251426697,
      -0.014407303184270859,
      0.0022780708968639374,
      -0.020943287760019302,
      0.06879591196775436,
      -0.03269611671566963,
      0.0625753104686737,
      -0.09073308855295181,
      -0.02514575608074665,
      -0.05319809913635254,
      0.004876528400927782,
      -0.008667157962918282,
      0.10401634126901627,
      0.011928245425224304,
      0.019227227196097374,
      -0.013193395920097828,
      0.02374934032559395,
      -0.03789244592189789,
      0.05224044993519783,
      0.019479384645819664,
      0.08553929626941681,
      0.013513979502022266,
      -0.008791730739176273,
      0.04514766111969948,
      -0.048294421285390854,
      0.027742478996515274,
      -0.017337601631879807,
      -0.011222809553146362,
      0.04311775043606758,
      -0.010832170024514198,
      -0.054563120007514954,
      0.018896061927080154,
      0.03755749762058258,
      0.09012395143508911,
      -0.06405743956565857,
      0.04950413852930069,
      -0.002418752759695053,
      0.013078558258712292,
      -0.06299852579832077,
      -0.014558013528585434,
      0.013807456009089947,
      -0.0500091090798378,
      0.052518539130687714,
      -0.03050614334642887,
      -0.022451620548963547,
      0.003304986981675029,
      -0.035569097846746445,
      -0.04451296851038933,
      -0.1526433825492859,
      0.004861915949732065,
      0.023333588615059853,
      -0.05552610754966736,
      -0.026639487594366074,
      -0.031076611950993538,
      -0.0594324916601181,
      0.012479383498430252,
      -0.012994606979191303,
      0.03053726814687252,
      0.03424668312072754,
      0.05164576694369316,
      -0.03168134391307831,
      0.07928372174501419,
      0.03597289323806763,
      -0.060847118496894836,
      0.03651644289493561,
      -0.03563205152750015,
      -0.08075416833162308,
      0.0299238171428442,
      -0.07518082857131958,
      -0.047254547476768494,
      0.10388331115245819,
      -0.06029750406742096,
      -0.013159534893929958,
      0.04406655579805374,
      0.09789499640464783,
      -0.03845534846186638,
      -0.005236096680164337,
      -0.007900678552687168,
      -0.14247456192970276,
      0.05129024758934975,
      -0.00931183248758316,
      0.04106637462973595,
      0.0371319018304348,
      0.06028769910335541,
      0.006976292934268713,
      0.06367196142673492,
      0.10830915719270706,
      -0.06659930944442749,
      0.07800828665494919,
      -0.044060032814741135,
      0.06141281872987747,
      0.014376580715179443,
      -0.012749946676194668,
      0.007897264324128628,
      0.06651505827903748,
      0.03234883025288582,
      -0.055944912135601044,
      0.020218709483742714,
      0.025781309232115746,
      0.07903080433607101,
      -0.051109429448843,
      0.10740751028060913,
      0.025733817368745804,
      0.07174281030893326,
      -0.04970063641667366,
      -0.06996782124042511,
      0.01037969347089529,
      0.08802448958158493,
      0.00046974484575912356,
      -0.01823241077363491,
      -0.010418020188808441,
      0.009520472958683968,
      -0.0610448494553566,
      -0.016487877815961838,
      0.030201168730854988,
      0.0655505582690239,
      0.012365886010229588,
      -0.03263845294713974,
      -0.0007960632792674005,
      -0.053881969302892685,
      -0.04504884034395218,
      -0.017914898693561554,
      0.04753102734684944,
      -0.00488466490060091,
      0.09241379052400589,
      -0.007790227886289358,
      -0.024210849776864052,
      0.0620492659509182,
      -0.04299689829349518,
      0.014998181723058224,
      0.07432448863983154,
      -0.0909089595079422,
      0.10854975134134293,
      -0.021194228902459145,
      -0.013484451919794083,
      0.05069441720843315,
      0.0074098375625908375,
      -0.009373256005346775,
      0.06913172453641891,
      -0.0535215400159359,
      -0.010822853073477745,
      -0.006650818977504969,
      -0.07232014089822769,
      0.04430996999144554,
      -0.0326680950820446,
      0.00646456191316247,
      -0.0066160536371171474,
      -0.03829469159245491,
      -0.006071710027754307,
      0.04127339646220207,
      -0.015055976808071136,
      -0.052921362221241,
      -0.0940771996974945,
      -0.040547050535678864,
      0.08350712805986404,
      -0.003703950671479106,
      -0.0498821884393692,
      0.0009242668165825307,
      -0.007037065457552671,
      -0.022796936333179474,
      0.04693237319588661,
      -0.019474973902106285,
      0.04890486225485802,
      0.002693616086617112,
      -0.0819554328918457,
      -0.04228704050183296,
      -0.03956148028373718,
      0.07357723265886307,
      -0.020051954314112663,
      0.04427717253565788,
      0.012339770793914795,
      0.019647564738988876,
      0.034213315695524216,
      -0.029709232971072197,
      -0.07550834119319916,
      -0.04093639925122261,
      -0.08125858753919601,
      0.12398986518383026,
      0.04438063129782677,
      -0.016845082864165306,
      -0.04259340092539787,
      0.09208961576223373,
      -0.0331897996366024,
      -0.014027155935764313,
      0.08921844512224197,
      0.02935163304209709,
      -0.016672642901539803,
      -0.12293107062578201,
      -0.03775935620069504,
      -0.0004155827045906335,
      0.003832664340734482,
      -0.1288854032754898,
      -0.11546197533607483,
      -0.03702574223279953,
      0.0801546722650528,
      -0.0003359300608281046,
      0.07378072291612625,
      -0.021253757178783417,
      0.02983216568827629,
      -0.06609708815813065,
      0.007857423275709152,
      -0.005446404218673706,
      0.055314771831035614,
      0.05226966366171837,
      0.012619405053555965,
      0.0064826468005776405,
      -0.017270267009735107,
      0.01974031887948513,
      -0.011073336005210876,
      0.054556865245103836,
      0.023194659501314163,
      -0.028739074245095253,
      0.007681414484977722,
      -0.03426125645637512,
      -0.007463100831955671,
      -0.08112267404794693,
      -0.1051202192902565,
      0.019866717979311943,
      0.0008140410645864904,
      0.0006520905299112201,
      -0.011577901430428028,
      -0.030068784952163696,
      0.07117744535207748,
      0.061660390347242355,
      -0.04222267493605614,
      0.01291719265282154,
      0.029327930882573128,
      0.10024401545524597,
      0.00554431788623333,
      0.05005374178290367,
      -0.04712071642279625,
      -0.01643570326268673,
      0.0400833860039711,
      0.03376547992229462,
      0.006851553916931152,
      0.03236215561628342,
      -0.05109637230634689,
      0.004844209179282188,
      0.02153325453400612,
      0.022434895858168602,
      -0.03616521134972572,
      0.03364228084683418,
      -0.01932298019528389,
      -0.04801645129919052,
      0.0008914249483495951,
      0.07434749603271484,
      0.08001881092786789,
      -0.019979193806648254,
      -0.006533470470458269,
      -0.0500614307820797,
      -0.010184024460613728,
      -0.04455556720495224,
      -0.06988970935344696,
      -0.0197201669216156,
      -0.09636692702770233,
      0.05287398397922516,
      -0.0168295931071043,
      -0.04813126474618912,
      -0.08328983932733536,
      -0.035835884511470795,
      -0.009700753726065159,
      0.049698784947395325,
      0.04084891453385353,
      0.012467637658119202,
      -0.02647063508629799,
      0.10683748126029968,
      -0.03362452611327171,
      -0.006162108853459358,
      0.06397368758916855,
      0.048788849264383316,
      0.005549784284085035,
      0.000997854513116181,
      -0.035707879811525345,
      0.022869162261486053,
      -0.01200611051172018,
      -0.057729970663785934,
      -0.008576497435569763,
      -0.04177452251315117,
      0.04779433831572533,
      -0.07251551002264023,
      -0.012376816011965275
    ],
    [
      0.07592921704053879,
      0.0032834974117577076,
      -0.022196747362613678,
      -0.006927028764039278,
      0.008475203067064285,
      0.020892683416604996,
      0.009729956276714802,
      0.0383463129401207,
      -0.013173829764127731,
      0.05721401423215866,
      0.03865407779812813,
      -0.03942469507455826,
      0.015388661064207554,
      0.01286846213042736,
      -0.03416917845606804,
      -0.05510517582297325,
      -0.09455743432044983,
      -0.06418005377054214,
      0.012010145001113415,
      0.008908343501389027,
      -0.0006425989558920264,
      0.0711960569024086,
      0.05074768513441086,
      0.0526929646730423,
      -0.026233267039060593,
      -0.03260771557688713,
      -0.06506294757127762,
      0.06677248328924179,
      -0.04713860899209976,
      0.05154699087142944,
      0.011575969867408276,
      0.020893655717372894,
      0.008135995827615261,
      0.029725736007094383,
      -0.005335063673555851,
      -0.022599875926971436,
      0.02264884114265442,
      0.04577456787228584,
      -0.06242366507649422,
      -0.019631311297416687,
      -0.06278345733880997,
      -0.032954007387161255,
      -0.00948312971740961,
      -0.0817197784781456,
      -0.06545259058475494,
      -0.009884648025035858,
      -0.04676605015993118,
      0.016213608905673027,
      -0.010581896640360355,
      0.01958935335278511,
      -0.03576241806149483,
      0.008273369632661343,
      0.03155745938420296,
      0.0008739769691601396,
      -0.09835775196552277,
      0.03244505077600479,
      -0.007948020473122597,
      -0.06483905017375946,
      0.00869569182395935,
      -0.01016971841454506,
      0.02298850193619728,
      -0.04026506841182709,
      0.0004562237299978733,
      -0.06602293252944946,
      -0.0479220375418663,
      0.05220483988523483,
      -0.08610817044973373,
      -0.07647497951984406,
      0.039504606276750565,
      0.11207903921604156,
      -0.004200988914817572,
      -0.036292098462581635,
      -0.00449266517534852,
      -0.015287241898477077,
      -0.04020392894744873,
      0.03584039956331253,
      0.03263263776898384,
      0.05562768504023552,
      0.06667876988649368,
      0.03647388890385628,
      0.00844272505491972,
      -0.0927472859621048,
      0.07077714800834656,
      -0.018546350300312042,
      0.045303355902433395,
      0.007061232812702656,
      -0.026841890066862106,
      0.030748406425118446,
      0.0017166947945952415,
      0.026095906272530556,
      0.030022980645298958,
      -0.02907547913491726,
      -0.0321681946516037,
      0.03655948117375374,
      -0.03823580592870712,
      0.03368372842669487,
      -0.04384664446115494,
      0.04618677496910095,
      0.04292817413806915,
      0.024092037230730057,
      0.015302459709346294,
      -0.00938905868679285,
      0.05837174504995346,
      0.050067536532878876,
      0.03432564064860344,
      0.0044279154390096664,
      -0.05093914270401001,
      0.037685368210077286,
      0.010960178449749947,
      0.017994213849306107,
      -0.013085681945085526,
      0.02069033682346344,
      -0.04416092112660408,
      0.04873363673686981,
      -0.04345349967479706,
      -0.10305454581975937,
      -0.04244005307555199,
      -0.026670271530747414,
      -0.03828439489006996,
      0.0731360986828804,
      0.037545207887887955,
      -0.040265683084726334,
      -0.03591331094503403,
      0.06729929894208908,
      0.026725390926003456,
      -0.005539454519748688,
      -0.08592842519283295,
      -0.04153303802013397,
      0.0134742371737957,
      0.011932543478906155,
      -0.021387364715337753,
      -0.03469109162688255,
      0.013441606424748898,
      -0.012153229676187038,
      -0.03504307195544243,
      -0.010200588963925838,
      0.01461411640048027,
      0.03326248750090599,
      0.04538996145129204,
      -0.07065199315547943,
      -0.0754157155752182,
      -0.008090902119874954,
      -0.011432139202952385,
      0.09062452614307404,
      0.0274550411850214,
      -0.03779124468564987,
      0.008486132137477398,
      -0.009156560525298119,
      -0.04358166083693504,
      -0.01999494805932045,
      0.03556273505091667,
      -0.0016560720978304744,
      0.023790724575519562,
      -0.002762499265372753,
      -0.022392485290765762,
      0.05053064972162247,
      0.0393955260515213,
      -0.013219757005572319,
      -0.0017904246924445033,
      -0.014891136437654495,
      -0.03230135887861252,
      -0.04998653754591942,
      0.05952067673206329,
      -0.014522280544042587,
      -0.012458421289920807,
      -0.043080803006887436,
      0.08155321329832077,
      -0.04318443313241005,
      -0.00183868408203125,
      0.0017028420697897673,
      -0.05959825590252876,
      -0.10243093222379684,
      -0.041018515825271606,
      -0.050718553364276886,
      -0.06835439056158066,
      0.029557300731539726,
      0.04404093697667122,
      0.03115636296570301,
      0.0018512987298890948,
      -0.06482728570699692,
      0.025361809879541397,
      -0.04825964570045471,
      0.05123322457075119,
      -0.023646799847483635,
      -0.009356309659779072,
      -0.020236263051629066,
      0.003910013008862734,
      -0.012167032808065414,
      0.06598006188869476,
      -0.03997413069009781,
      0.10344714671373367,
      -0.003907554317265749,
      0.00018770911265164614,
      -0.023627225309610367,
      0.08555281907320023,
      -0.024837207049131393,
      0.07678260654211044,
      -0.03464873880147934,
      -0.0014582794392481446,
      0.08397138118743896,
      0.004848715383559465,
      -0.0032771797850728035,
      0.051626481115818024,
      -0.03888644650578499,
      0.023299401625990868,
      0.019057879224419594,
      0.07336615025997162,
      0.04960842430591583,
      -0.03501152619719505,
      -0.021460480988025665,
      -0.014362644404172897,
      0.052608247846364975,
      0.12858183681964874,
      -0.0048052361235022545,
      -0.03195350617170334,
      0.03354158625006676,
      0.024287523701786995,
      0.04593433067202568,
      -0.05195120349526405,
      -0.02184280753135681,
      -0.02361031249165535,
      0.053727246820926666,
      0.045017533004283905,
      -0.01916857436299324,
      0.015640120953321457,
      -0.02518874779343605,
      0.03070877492427826,
      0.05508218705654144,
      0.05366049334406853,
      0.05696780979633331,
      -0.051583994179964066,
      0.009549640119075775,
      0.043723247945308685,
      -0.011499722488224506,
      -0.0715436264872551,
      -0.03299237787723541,
      -0.015774186700582504,
      0.005204245913773775,
      -0.0011027916334569454,
      -0.006592359393835068,
      0.008563878014683723,
      0.07079148292541504,
      0.07737759500741959,
      -0.01975802518427372,
      -0.057825718075037,
      -0.022865815088152885,
      0.053465187549591064,
      0.003077134257182479,
      -0.00569646991789341,
      0.021055171266198158,
      -0.07153613120317459,
      0.06864912062883377,
      0.0063828662969172,
      0.013277792371809483,
      0.018303606659173965,
      0.007167328614741564,
      0.0324578620493412,
      0.022334085777401924,
      -0.03319866582751274,
      0.012817371636629105,
      -0.04569634795188904,
      0.03468997776508331,
      -0.11410576850175858,
      -0.05008537694811821,
      0.003216670360416174,
      -0.0220877043902874,
      0.009777871891856194,
      -0.000648109707981348,
      0.01722920872271061,
      0.014952757395803928,
      -0.01962614245712757,
      0.025552377104759216,
      -0.054902952164411545,
      -0.02890128456056118,
      0.020317789167165756,
      0.01938130334019661,
      0.11973460018634796,
      -0.044684797525405884,
      -0.051444172859191895,
      -0.037873972207307816,
      0.01751415990293026,
      -0.01738095097243786,
      -0.02018107660114765,
      -0.026289010420441628,
      -0.019218439236283302,
      0.019819380715489388,
      0.05241338536143303,
      0.046525489538908005,
      0.003624469507485628,
      -0.039187103509902954,
      -0.10299479216337204,
      -0.02785467728972435,
      0.008872722275555134,
      0.0001377640146529302,
      0.006682923063635826,
      -0.011157725006341934,
      0.048190925270318985,
      0.032813772559165955,
      -0.03960605710744858,
      -0.046448152512311935,
      0.023687925189733505,
      -0.04122420772910118,
      0.006097758188843727,
      -0.010839482769370079,
      -0.03326921910047531,
      0.0016384474001824856,
      -0.029660407453775406,
      0.02640797756612301,
      0.030178580433130264,
      -0.06532356888055801,
      0.009102091193199158,
      -0.008086579851806164,
      0.022060682997107506,
      0.02980824001133442,
      0.09513168036937714,
      -0.03134771063923836,
      0.01228408794850111,
      -0.0685991570353508,
      -0.0007266347529366612,
      0.0594559945166111,
      0.036098677664995193,
      0.09371095150709152,
      -0.05770152434706688,
      0.027696656063199043,
      -0.09444265812635422,
      0.04681198298931122,
      -0.03472011908888817,
      0.005415968131273985,
      0.038218237459659576,
      0.05933506786823273,
      -0.030989399179816246,
      -0.05367860943078995,
      -0.01245590765029192,
      -0.03848440572619438,
      0.023666521534323692,
      -0.07133452594280243,
      0.01449695136398077,
      0.007978375069797039,
      -0.028478678315877914,
      0.03586459532380104,
      0.06702494621276855,
      0.04195118695497513,
      0.009532337076961994,
      -0.005094535183161497,
      0.05396612733602524,
      -0.018130362033843994,
      0.0904366672039032,
      -0.019277045503258705,
      -0.0010153248440474272,
      0.0018706980627030134,
      0.03836126625537872,
      -0.01412071194499731,
      0.02083628624677658,
      -0.043087635189294815,
      0.13257548213005066,
      -0.05575072392821312,
      0.06534278392791748,
      0.02212466113269329,
      -0.07066071033477783,
      0.012063516303896904,
      0.032569438219070435,
      -0.012250960804522038,
      0.06839901953935623,
      0.060601506382226944,
      -0.07515784353017807,
      -0.013043840415775776,
      -0.0010683087166398764,
      -0.027072517201304436,
      0.017594022676348686,
      -0.042018868029117584,
      0.01470177248120308,
      0.007403187453746796,
      -0.022196294739842415,
      -0.0409645140171051,
      -0.01380324736237526,
      0.028454875573515892,
      0.011283107101917267,
      0.017288202419877052,
      -0.013107324950397015,
      0.05486591160297394,
      -0.03844541683793068,
      -0.07127899676561356,
      0.07045989483594894,
      -0.02333715185523033,
      0.024827471002936363,
      0.05343170464038849,
      0.010528440587222576,
      0.006451468449085951,
      -0.003071324899792671,
      -0.03552757203578949,
      -0.010107213631272316,
      0.07915470749139786,
      -0.005994739010930061,
      -0.06526882946491241,
      0.008272887207567692,
      -0.04375700652599335,
      -0.024810416623950005,
      -0.004799329675734043,
      -0.01822645030915737,
      -0.008221905678510666,
      0.040157053619623184,
      -0.009201347827911377,
      -0.0034263168927282095,
      0.022929741069674492,
      0.044161614030599594,
      -0.020751619711518288,
      0.015785960480570793,
      0.023556433618068695,
      -0.06969151645898819,
      0.10852726548910141,
      -0.025618162006139755,
      0.013426319696009159,
      0.06270382553339005,
      -0.02427370846271515,
      -0.02319682203233242,
      0.016751699149608612,
      -0.02942100167274475,
      0.0027320394292473793,
      0.04396075755357742,
      -0.04428558796644211,
      -0.04812796413898468,
      0.0087916674092412,
      -0.03419867902994156,
      -0.0129381213337183,
      -0.033701203763484955,
      -0.005579210352152586,
      0.027132075279951096,
      0.02321241982281208,
      -0.018884925171732903,
      0.0881771370768547,
      -0.024549290537834167,
      -0.02314695715904236,
      0.03072519414126873,
      -0.01755623333156109,
      -0.01755276508629322,
      -0.009248749352991581,
      0.0169022586196661,
      -0.022062139585614204,
      0.006706926040351391,
      -0.029487168416380882,
      0.034470085054636,
      0.023122502490878105,
      0.05563663691282272,
      0.05763157457113266,
      0.07050874084234238,
      -0.021666189655661583,
      -0.0929885134100914,
      -0.0032947154249995947,
      0.02144780568778515,
      0.0032753210980445147,
      0.03933478519320488,
      -0.012865387834608555,
      0.013596770353615284,
      0.018470872193574905,
      -0.05819433182477951,
      0.06832696497440338,
      0.0044776336289942265,
      0.030444087460637093,
      0.02434377558529377,
      -0.07933076471090317,
      0.05713742598891258,
      0.01806698553264141,
      0.025966642424464226,
      0.057410143315792084,
      0.01882459782063961,
      -0.018603025004267693,
      -0.041661109775304794,
      -0.03672676533460617,
      0.02233836054801941,
      -0.04621392488479614,
      -0.004810634069144726,
      0.04793591424822807,
      0.03250778838992119,
      0.03923670947551727,
      -0.003531992668285966,
      0.026122741401195526,
      -0.022190766409039497,
      -0.021625518798828125,
      0.03802619129419327,
      -0.002658647019416094,
      -0.02149158902466297,
      0.03961137309670448,
      -0.04857473075389862,
      0.0070001608692109585,
      0.041922662407159805,
      0.03452616184949875,
      -0.015711089596152306,
      -0.05483261123299599,
      0.01542634703218937,
      -0.01621164008975029,
      0.0007400938775390387,
      0.04701853543519974,
      0.007758167572319508,
      -0.01739337667822838,
      0.050342898815870285,
      -0.02471148781478405,
      -0.06432647258043289,
      -0.003661728696897626,
      0.019795795902609825,
      0.02869945392012596,
      0.04348784685134888,
      0.017993414774537086,
      -0.1365947276353836
    ],
    [
      0.014811337925493717,
      -0.02098485827445984,
      0.0034021567553281784,
      0.03758503496646881,
      -0.02000940777361393,
      0.029789479449391365,
      0.045185718685388565,
      -0.0661662220954895,
      -0.02034168690443039,
      0.03206974267959595,
      0.056579891592264175,
      0.03184041753411293,
      -0.016815051436424255,
      0.016061583533883095,
      -0.05265910178422928,
      0.04410276561975479,
      -0.04902583360671997,
      -0.084469735622406,
      -0.018636060878634453,
      0.014584716409444809,
      0.01217607967555523,
      0.008228599093854427,
      -0.04648656025528908,
      -0.027206208556890488,
      0.07797999680042267,
      -0.034526482224464417,
      -0.0478268526494503,
      0.0348961241543293,
      -0.06423426419496536,
      -0.010475698858499527,
      -0.0008870256133377552,
      -0.0035105750430375338,
      0.029593853279948235,
      -0.03752720728516579,
      -0.054193854331970215,
      -0.038385532796382904,
      -0.005638750270009041,
      0.04553389176726341,
      0.03520035743713379,
      0.02017991803586483,
      -0.0014224713668227196,
      -0.042195554822683334,
      -0.06085839867591858,
      0.02807549014687538,
      -7.104306860128418e-05,
      0.06238783150911331,
      -0.002530460711568594,
      -0.027591941878199577,
      0.013925062492489815,
      0.017750436440110207,
      -0.05715756118297577,
      0.038040950894355774,
      -0.016309022903442383,
      -0.08256951719522476,
      -0.06634249538183212,
      0.0037874749395996332,
      0.03348042815923691,
      0.033218685537576675,
      0.05705587565898895,
      0.06633935123682022,
      0.051719050854444504,
      -0.050589174032211304,
      -0.008179550059139729,
      -0.023730669170618057,
      0.05355862155556679,
      -0.027762267738580704,
      0.04971969872713089,
      0.011262797750532627,
      0.024454662576317787,
      0.005105870775878429,
      0.017373761162161827,
      -0.04969823360443115,
      0.005996352527290583,
      0.04772510007023811,
      -0.029391448944807053,
      0.04461844265460968,
      0.01744314469397068,
      -0.0509302020072937,
      -0.00025447882944718003,
      0.00860689114779234,
      -0.005521710962057114,
      0.030621569603681564,
      -0.03769883140921593,
      0.015128902159631252,
      0.04691894352436066,
      -0.02303367294371128,
      -0.06222061812877655,
      0.002533550374209881,
      0.03796157240867615,
      -0.007877310737967491,
      -0.07960174232721329,
      -0.049574337899684906,
      0.028655266389250755,
      -0.010219448246061802,
      0.005043122451752424,
      -0.034293919801712036,
      -0.01848682202398777,
      -0.013029586523771286,
      -0.04902298375964165,
      -0.027444085106253624,
      0.042431071400642395,
      -0.0016363110626116395,
      -0.09524603933095932,
      0.03802447393536568,
      0.04634544625878334,
      0.04668411985039711,
      -0.12356068193912506,
      0.04446065425872803,
      0.09109466522932053,
      0.055468443781137466,
      0.029918793588876724,
      -0.08628340810537338,
      0.018338581547141075,
      -0.03569256141781807,
      0.012752633541822433,
      -0.010922685265541077,
      0.05143903195858002,
      0.026351071894168854,
      0.03223191946744919,
      0.042912159115076065,
      0.0031923153437674046,
      -0.03350274637341499,
      -0.03773518651723862,
      -0.017499003559350967,
      -0.09458243101835251,
      -0.03807015344500542,
      -0.018086519092321396,
      -0.028981616720557213,
      -0.010492481291294098,
      -0.04287180304527283,
      0.012334377504885197,
      0.0662294551730156,
      -0.07007324695587158,
      0.013849955052137375,
      0.054635755717754364,
      0.0015542227774858475,
      -0.04574189707636833,
      0.0037371469661593437,
      0.03389202058315277,
      -0.12026283144950867,
      -0.03525255247950554,
      -0.05699523538351059,
      0.036190569400787354,
      -0.024954328313469887,
      -0.024919036775827408,
      -0.00027286240947432816,
      0.04950571432709694,
      -0.03749280050396919,
      -0.07145248353481293,
      0.003553170245140791,
      0.03767632320523262,
      -0.030624469742178917,
      -0.0011024149134755135,
      0.011545024812221527,
      0.053167786449193954,
      -0.011903204955160618,
      -0.0038295588456094265,
      0.08804287761449814,
      0.06952783465385437,
      0.06445427238941193,
      -0.01870688423514366,
      -0.07776051014661789,
      -0.010959019884467125,
      -0.010886497795581818,
      -0.014205651357769966,
      0.00942153763025999,
      -0.006509583909064531,
      -0.028363628312945366,
      0.03920392319560051,
      0.027255864813923836,
      -0.13588961958885193,
      0.05475363880395889,
      -0.10443046689033508,
      0.029673118144273758,
      0.006232208106666803,
      0.020267734304070473,
      0.004670513328164816,
      -0.004274122882634401,
      0.0033578805159777403,
      0.029188575223088264,
      -0.009083508513867855,
      0.09054934233427048,
      -0.059362005442380905,
      0.034378472715616226,
      -0.0250871405005455,
      -0.004931433591991663,
      0.02881559357047081,
      0.013494341634213924,
      -0.01648532971739769,
      0.014267384074628353,
      0.004748068749904633,
      0.047696344554424286,
      -0.015839964151382446,
      0.00010090593423228711,
      0.008610639721155167,
      -0.045540839433670044,
      0.03128790482878685,
      -0.008041507564485073,
      -0.029093295335769653,
      -0.03466148301959038,
      -0.01846209727227688,
      -0.009438673034310341,
      0.018304940313100815,
      -0.06583400070667267,
      -0.041633713990449905,
      0.017914708703756332,
      -0.022793488577008247,
      -0.011207059025764465,
      0.13397856056690216,
      -0.03320500999689102,
      -0.03245541825890541,
      0.06709489226341248,
      0.03797966241836548,
      -0.07596580684185028,
      -0.040378138422966,
      -0.07434632629156113,
      -0.023685762658715248,
      -0.05878255143761635,
      0.022354543209075928,
      0.0346008837223053,
      0.0270468108355999,
      -0.06111472472548485,
      0.005007000640034676,
      -0.03587910532951355,
      -0.030472837388515472,
      0.006697649601846933,
      -0.06426604837179184,
      -0.04298386722803116,
      -0.0358460508286953,
      -0.0012275351909920573,
      0.023795945569872856,
      -0.013917352072894573,
      0.003983244765549898,
      0.025197764858603477,
      0.044920794665813446,
      0.0051982225850224495,
      -0.016606630757451057,
      0.003880601143464446,
      0.020216627046465874,
      0.07309974730014801,
      0.0208737775683403,
      -0.02253761515021324,
      0.10490798205137253,
      0.015838267281651497,
      -0.017897626385092735,
      0.035859234631061554,
      0.01837151125073433,
      -0.01811297796666622,
      0.007816062308847904,
      0.01997258886694908,
      -0.0018206874374300241,
      -0.04859987273812294,
      0.10196156799793243,
      -0.011719886213541031,
      0.02147240750491619,
      0.007485313806682825,
      0.06607533246278763,
      -0.012341818772256374,
      0.0021518501453101635,
      0.007791990414261818,
      -0.035465057939291,
      -0.02906152419745922,
      0.03508878871798515,
      0.04969118908047676,
      -0.0630398541688919,
      -0.0040542203933000565,
      0.05685952305793762,
      0.008412151597440243,
      -0.10115450620651245,
      -0.0914383977651596,
      -0.01124650426208973,
      0.04157853126525879,
      0.05437174811959267,
      0.030982721596956253,
      0.06553108245134354,
      -0.02033120021224022,
      0.02421739511191845,
      -0.04372493177652359,
      -0.06070049852132797,
      -0.046496421098709106,
      0.002669626148417592,
      -0.004799598827958107,
      0.08081284910440445,
      -0.012994769029319286,
      -0.008711026050150394,
      -0.021582724526524544,
      0.06621015071868896,
      0.06600751727819443,
      0.07664335519075394,
      0.026849620044231415,
      -0.03494174778461456,
      0.07184447348117828,
      -0.018440568819642067,
      0.02904912456870079,
      0.005751389544457197,
      0.018091637641191483,
      -0.0031349086202681065,
      0.01997762732207775,
      -0.009349120780825615,
      0.03836743161082268,
      -0.007950091734528542,
      -0.04882187768816948,
      -0.1200132668018341,
      -0.0440201573073864,
      -0.053822144865989685,
      -0.0725487619638443,
      -0.02805219031870365,
      0.01934630051255226,
      0.003564491169527173,
      0.02917075902223587,
      0.015450678765773773,
      0.09055999666452408,
      -0.018576838076114655,
      -0.05246106535196304,
      -0.005621720105409622,
      0.00938013382256031,
      -0.039945293217897415,
      -0.10997122526168823,
      0.08069348335266113,
      0.004138805903494358,
      -0.057742923498153687,
      -0.0921621099114418,
      -0.05485314130783081,
      -0.02351185493171215,
      0.03304433077573776,
      0.05199547857046127,
      0.03772151097655296,
      -0.08175001293420792,
      0.03746498376131058,
      0.001320464420132339,
      0.016566960141062737,
      -0.07841864228248596,
      0.004045560490339994,
      -0.007011132780462503,
      -0.003371736267581582,
      -0.04014018550515175,
      0.043870959430933,
      0.05400791019201279,
      0.035510189831256866,
      -0.0026180443819612265,
      0.04790573567152023,
      0.025830022990703583,
      -0.04398007318377495,
      0.052039772272109985,
      0.014963557943701744,
      -0.05759987235069275,
      0.04676135256886482,
      -0.06757687032222748,
      0.038925208151340485,
      0.08290242403745651,
      -0.04017910733819008,
      -0.03741799294948578,
      -0.10141480714082718,
      -0.054211732000112534,
      0.03771655634045601,
      -0.06059793010354042,
      0.0692383348941803,
      -0.036162059754133224,
      -0.020862167701125145,
      0.0009282776736654341,
      0.016294829547405243,
      0.006285420618951321,
      -0.11345745623111725,
      0.0540740080177784,
      0.042634978890419006,
      -0.008695213124155998,
      -0.07986611872911453,
      -0.02202611230313778,
      -0.020711932331323624,
      -0.006527481600642204,
      -0.04959294945001602,
      -0.01201540045440197,
      -0.026931580156087875,
      0.028767893090844154,
      0.02393103390932083,
      -0.005255557131022215,
      0.025386279448866844,
      0.026276687160134315,
      -0.023162201046943665,
      0.03865475207567215,
      -0.010743419639766216,
      0.020385779440402985,
      -0.01941564865410328,
      -0.02321668528020382,
      -0.07226762920618057,
      -0.054359957575798035,
      -0.006686560343950987,
      -0.031435687094926834,
      0.04093736410140991,
      0.01824484020471573,
      0.020725399255752563,
      -0.026663564145565033,
      0.07973019033670425,
      0.01518435962498188,
      0.023453200235962868,
      0.0032922704704105854,
      0.03704085573554039,
      0.06282000243663788,
      -0.009190622717142105,
      0.005549520719796419,
      -0.08015776425600052,
      -0.014716027304530144,
      -0.044064633548259735,
      -0.002831987803801894,
      0.011700743809342384,
      -0.06620258092880249,
      0.00829093437641859,
      0.03870714083313942,
      -0.009969761595129967,
      -0.049184855073690414,
      -0.035679589956998825,
      -0.07477205246686935,
      -0.008180937729775906,
      0.04538966715335846,
      0.08401106297969818,
      -0.0073409304022789,
      0.0181709174066782,
      -0.047617994248867035,
      -0.04900303855538368,
      -0.02163395844399929,
      0.0005888601881451905,
      -0.05960073694586754,
      -0.039974555373191833,
      0.00622602691873908,
      -0.013689636252820492,
      -0.004750919993966818,
      0.03320937231183052,
      -0.06594125181436539,
      0.11873020976781845,
      0.0855937972664833,
      -0.014331539161503315,
      -0.06715721637010574,
      0.011105635203421116,
      0.06742933392524719,
      -0.02086099423468113,
      0.032598476856946945,
      -0.12184517830610275,
      0.015436309389770031,
      -0.05332507565617561,
      -0.047958943992853165,
      -0.024359337985515594,
      0.044692788273096085,
      0.002535987412557006,
      0.002444157376885414,
      0.023230483755469322,
      -0.019055256620049477,
      0.012305687181651592,
      -0.04804093390703201,
      -0.013301290571689606,
      -0.018357062712311745,
      -0.04848554730415344,
      0.054891008883714676,
      0.08180281519889832,
      -0.035801276564598083,
      -0.024153219535946846,
      -0.022685596719384193,
      0.0011836598860099912,
      0.0642416849732399,
      0.015870552510023117,
      0.06688949465751648,
      0.0026077593211084604,
      0.001623361837118864,
      0.007590950932353735,
      0.072431281208992,
      0.05374706909060478,
      0.07049932330846786,
      0.011129159480333328,
      -0.008429571986198425,
      -0.01809115521609783,
      -0.025548996403813362,
      0.016436412930488586,
      0.03153113275766373,
      -0.044687218964099884,
      -0.08184211701154709,
      -0.0066221910528838634,
      0.017356248572468758,
      -0.018291553482413292,
      -0.05535319447517395,
      0.0487554557621479,
      0.009860235266387463,
      -0.03186953440308571,
      0.0408291332423687,
      -0.03413230925798416,
      -0.11164001375436783,
      0.00020627622143365443,
      0.032841794192790985,
      0.02029537782073021,
      0.0250741895288229,
      0.00931712705641985,
      -0.02713177539408207,
      0.015744216740131378,
      -0.0743134394288063,
      -0.030216623097658157,
      -0.12773025035858154,
      -0.02581240050494671,
      -0.029324516654014587,
      0.040816038846969604,
      0.049702007323503494,
      0.002517195651307702,
      -0.029938852414488792,
      -0.026543844491243362,
      0.0187571682035923,
      -0.005276895593851805
    ],
    [
      0.08540746569633484,
      -0.014369742944836617,
      0.012046482414007187,
      0.05356364697217941,
      0.003474584547802806,
      0.003846736392006278,
      -0.007262352388352156,
      0.014647189527750015,
      0.06139000132679939,
      0.019308162853121758,
      0.08502821624279022,
      -0.029196109622716904,
      0.009913233108818531,
      -0.04994775354862213,
      -0.07688586413860321,
      -0.04182844236493111,
      0.05179687961935997,
      0.030226191505789757,
      0.0346982479095459,
      -1.6293764929287136e-05,
      0.0008027945295907557,
      -0.06066643446683884,
      -0.004721348639577627,
      0.04354901611804962,
      -0.0010303235612809658,
      -0.022262921556830406,
      -0.0070082442834973335,
      0.02761581353843212,
      -0.015908824279904366,
      0.01015713345259428,
      0.02991652488708496,
      -0.03177172318100929,
      0.027926485985517502,
      0.011298081837594509,
      0.020174778997898102,
      -0.005534070543944836,
      -0.0011266429210081697,
      0.014410552568733692,
      -0.019411830231547356,
      -0.016833920031785965,
      0.001231739530339837,
      0.0396760031580925,
      0.12236572057008743,
      -0.0448872447013855,
      -0.06145279482007027,
      -0.012194415554404259,
      -0.015373842790722847,
      -0.00010994043259415776,
      0.03205062821507454,
      0.05870389565825462,
      0.04875302314758301,
      -0.035786423832178116,
      -0.008891154080629349,
      -0.06067218258976936,
      -0.050953470170497894,
      0.014156961813569069,
      0.04188532754778862,
      0.0013402547920122743,
      0.047275085002183914,
      -0.0460929237306118,
      -0.012595606036484241,
      0.01637723110616207,
      -0.02864244394004345,
      0.025694115087389946,
      -0.019726524129509926,
      0.06832990050315857,
      -0.0026718948502093554,
      -0.06247672438621521,
      0.07209938019514084,
      -0.013386333361268044,
      0.010418247431516647,
      -0.021459849551320076,
      -0.11378855258226395,
      -0.05645739659667015,
      0.008762093260884285,
      -0.03426913544535637,
      0.0015284166438505054,
      0.08650584518909454,
      0.08084826171398163,
      -0.08282981812953949,
      -0.015036439523100853,
      0.035379063338041306,
      0.0009855605894699693,
      -0.03032558783888817,
      0.0271139033138752,
      0.0856054350733757,
      0.08181104809045792,
      0.0036299654748290777,
      0.006518431473523378,
      0.05313536897301674,
      -0.11463864147663116,
      -0.1003253310918808,
      -0.04082449525594711,
      -0.029788313433527946,
      -0.0502227358520031,
      -0.0070139504969120026,
      0.0455305241048336,
      -0.044007252901792526,
      -0.012307976372539997,
      -0.07738383114337921,
      -0.013449426740407944,
      0.017255952581763268,
      -0.08502400666475296,
      -0.01322851236909628,
      0.018357977271080017,
      0.04016315937042236,
      0.04872128739953041,
      0.000627573230303824,
      -0.029854558408260345,
      -0.024443939328193665,
      -0.049860622733831406,
      -0.06970372051000595,
      -0.06248811259865761,
      0.011249789036810398,
      0.02645975351333618,
      -0.007200366817414761,
      -0.06636131554841995,
      -0.01030869409441948,
      -0.022863488644361496,
      -0.0397484116256237,
      -0.01729915849864483,
      0.033096976578235626,
      0.0063275909051299095,
      -0.07106313109397888,
      0.002664293861016631,
      0.04432070627808571,
      -0.013817790895700455,
      -0.04195573553442955,
      0.009710519574582577,
      0.007880174554884434,
      -0.12178818136453629,
      0.09111614525318146,
      -0.00587462680414319,
      0.035995930433273315,
      0.024905744940042496,
      -0.010351493954658508,
      0.02773471176624298,
      0.009947404265403748,
      0.007940738461911678,
      0.02084919437766075,
      0.026239167898893356,
      -0.029731400310993195,
      0.03950773924589157,
      0.002427472500130534,
      -0.03297306224703789,
      -0.0772806778550148,
      -0.028280140832066536,
      0.06288821995258331,
      0.032625626772642136,
      -0.0949484333395958,
      -0.011874820105731487,
      0.053275953978300095,
      0.031234756112098694,
      -0.018175894394516945,
      0.015820618718862534,
      0.010898292064666748,
      -0.018156182020902634,
      -0.0732327550649643,
      -0.016689203679561615,
      -0.025821330025792122,
      0.07560078054666519,
      0.026183392852544785,
      0.011211907491087914,
      0.02045166678726673,
      0.03451612964272499,
      -0.008154061622917652,
      0.02836504578590393,
      -0.06087236851453781,
      -0.058823440223932266,
      -0.020686130970716476,
      0.03675008565187454,
      -0.0350530780851841,
      -0.13111144304275513,
      -0.06478191912174225,
      0.09607088565826416,
      -0.01140272244811058,
      -0.015544746071100235,
      -0.017092552036046982,
      0.05070747062563896,
      0.027065236121416092,
      0.026417918503284454,
      0.001781392260454595,
      0.08068103343248367,
      -0.004524523857980967,
      -0.028995554894208908,
      0.020232195034623146,
      0.051267024129629135,
      -0.03878149017691612,
      -0.012378818355500698,
      -0.020016958937048912,
      0.0755142793059349,
      -0.0026203126180917025,
      -0.009560218080878258,
      -0.04098527133464813,
      -0.007538599893450737,
      0.06712628155946732,
      -0.025824911892414093,
      0.0010237029055133462,
      0.04300343990325928,
      0.03324270248413086,
      0.018363071605563164,
      0.004250633995980024,
      -0.05167476087808609,
      0.11091823130846024,
      0.007724029943346977,
      0.05106110870838165,
      -0.05218258872628212,
      0.04645932465791702,
      0.008779912255704403,
      0.004021966829895973,
      -0.017427189275622368,
      0.006269994657486677,
      -0.04386596009135246,
      -0.04996304586529732,
      -0.08752603083848953,
      0.01999969407916069,
      0.011283263564109802,
      -0.07863780111074448,
      0.04114566370844841,
      -0.0299394391477108,
      0.0010667310561984777,
      0.0015608490211889148,
      0.028182318434119225,
      0.0741986557841301,
      -0.024705670773983,
      -0.03881322592496872,
      0.03612058237195015,
      -0.001249211374670267,
      0.09740178287029266,
      -0.01337450835853815,
      -0.06824277341365814,
      -0.04836877062916756,
      -0.08325382322072983,
      -0.014637264423072338,
      -0.07936970889568329,
      0.023582560941576958,
      -0.03744162619113922,
      0.052619487047195435,
      -0.004604257643222809,
      -0.11289281398057938,
      0.05331156775355339,
      -0.03995804861187935,
      -0.03089415282011032,
      -0.012213933281600475,
      0.011902623809874058,
      -0.04821018502116203,
      0.04993955045938492,
      0.011118961498141289,
      0.004641242325305939,
      0.07498898357152939,
      -0.04222995415329933,
      0.019976768642663956,
      -0.05325053632259369,
      0.007151512429118156,
      -0.004212724510580301,
      0.05467282980680466,
      -0.039346061646938324,
      -0.0041387202218174934,
      0.01663834974169731,
      -0.05929039046168327,
      -0.020197400823235512,
      0.04614902287721634,
      0.05825610086321831,
      -0.055449388921260834,
      -0.028503132984042168,
      -0.025337809696793556,
      -0.04365348070859909,
      -0.026981055736541748,
      -0.020443512126803398,
      -0.005039427429437637,
      -0.05354156345129013,
      0.025526262819767,
      0.036981213837862015,
      -0.00965113379061222,
      0.02208668366074562,
      0.08557607978582382,
      -0.0783470869064331,
      0.03726973012089729,
      -0.03639881685376167,
      0.012048653326928616,
      -0.013759495690464973,
      -0.0438162200152874,
      -0.05942538008093834,
      -0.002314305631443858,
      -0.0003164852678310126,
      -0.07860562950372696,
      -0.08433220535516739,
      0.0419502854347229,
      -0.013675272464752197,
      -0.10346183180809021,
      0.019844871014356613,
      -0.0029222217854112387,
      0.05362487956881523,
      -0.03961225226521492,
      -0.01166674867272377,
      0.02766348235309124,
      -0.015967579558491707,
      -0.0382545180618763,
      0.04410116374492645,
      0.0509403795003891,
      0.0038326627109199762,
      -0.003341982839629054,
      -0.011770365759730339,
      0.0012174242874607444,
      -0.09545465558767319,
      0.0034381290897727013,
      -0.012373296543955803,
      -0.05741128697991371,
      0.0007091077277436852,
      -0.006017596926540136,
      0.02581825852394104,
      0.01590522564947605,
      -0.007380520459264517,
      -0.02921476401388645,
      0.02294740080833435,
      -0.021050160750746727,
      0.0008011719328351319,
      -0.03962400555610657,
      -0.015514560043811798,
      -0.03586248308420181,
      -0.0012852380750700831,
      -0.012552537955343723,
      0.040878549218177795,
      -0.009601335041224957,
      -0.002481637755408883,
      -0.036392707377672195,
      -0.008613195270299911,
      0.053685642778873444,
      0.07795360684394836,
      0.06098539009690285,
      0.030305851250886917,
      0.012865221127867699,
      0.11914122849702835,
      0.02082804962992668,
      0.03787800669670105,
      -0.02294805459678173,
      0.007652654778212309,
      0.004011821933090687,
      0.008352366276085377,
      0.005608722567558289,
      -0.008056692779064178,
      0.007285457104444504,
      -0.0017105544684454799,
      0.0027618370950222015,
      -0.025917137041687965,
      0.027365537360310555,
      0.029753418639302254,
      0.04309142008423805,
      0.022997809574007988,
      -0.03028532862663269,
      -0.01502880360931158,
      -0.0703151747584343,
      0.029882380738854408,
      -0.05953674390912056,
      0.051043551415205,
      0.054055873304605484,
      0.049254368990659714,
      -0.024890203028917313,
      0.03371937945485115,
      -0.039028916507959366,
      -0.003082023235037923,
      0.009429573081433773,
      -0.02591901272535324,
      0.026731068268418312,
      -0.030210906639695168,
      0.00898039061576128,
      0.0021507875062525272,
      0.0014827825361862779,
      -0.06266938149929047,
      -0.005163401830941439,
      -0.03568942844867706,
      0.014382189139723778,
      0.022995535284280777,
      0.04231448099017143,
      -0.0035268671344965696,
      -0.03554433956742287,
      0.022341609001159668,
      -0.15526002645492554,
      0.046481430530548096,
      0.009253774769604206,
      -0.05276449769735336,
      0.06628137081861496,
      -0.07031196355819702,
      -0.09847293794155121,
      -0.02032826654613018,
      -0.023582400754094124,
      0.03902008384466171,
      -0.08080543577671051,
      0.014847462065517902,
      0.006163983605802059,
      -0.014268382452428341,
      0.03169908747076988,
      -0.06593956053256989,
      0.0009586412343196571,
      -0.016535811126232147,
      -0.027645858004689217,
      0.07123148441314697,
      0.005835711490362883,
      0.04370000213384628,
      0.04689769819378853,
      0.03188140690326691,
      -0.0947474017739296,
      -0.019720634445548058,
      0.05062437430024147,
      -0.06129565089941025,
      0.029424302279949188,
      -0.008119789883494377,
      -0.006508926395326853,
      -0.01695965975522995,
      0.05372950807213783,
      -0.021888552233576775,
      0.05104781314730644,
      -0.002997930394485593,
      0.062270116060972214,
      0.04489685595035553,
      0.030513575300574303,
      -0.015327139757573605,
      0.04652141034603119,
      0.06131407245993614,
      -0.10528907179832458,
      -0.09648691117763519,
      0.0029177796095609665,
      -0.08202125132083893,
      0.010823861695826054,
      0.02721516601741314,
      -0.06791016459465027,
      0.04520154744386673,
      -0.05846714228391647,
      0.02292039804160595,
      -0.06660117208957672,
      -0.02045440301299095,
      0.028284424915909767,
      -0.05679064244031906,
      0.05669594183564186,
      0.06265539675951004,
      -0.023767616599798203,
      0.003284147009253502,
      0.04588554427027702,
      -0.033032942563295364,
      0.019700966775417328,
      0.07160153985023499,
      0.06454747915267944,
      0.03872387856245041,
      0.024348238483071327,
      -0.048287518322467804,
      0.024755530059337616,
      -0.06858085095882416,
      -0.052572935819625854,
      -0.004611657932400703,
      -0.017420027405023575,
      -0.03490615636110306,
      0.04837719351053238,
      -0.040135037153959274,
      0.03704381361603737,
      0.03191090002655983,
      0.05244052782654762,
      -0.03706765174865723,
      -0.00796402245759964,
      -0.013688408769667149,
      -0.04013191536068916,
      0.008398148231208324,
      -0.007949559949338436,
      -0.044514160603284836,
      -0.09252503514289856,
      0.007082735653966665,
      -0.032683733850717545,
      -0.00490386039018631,
      -0.048182640224695206,
      -0.01386354211717844,
      -0.03259832039475441,
      -0.0420394241809845,
      -0.008276310749351978,
      0.04471685364842415,
      -0.008884470909833908,
      0.04625345394015312,
      0.049930375069379807,
      0.004622855223715305,
      0.0007122330134734511,
      -0.09416491538286209,
      -0.0357811264693737,
      -0.09894827753305435,
      0.026626653969287872,
      -0.006907797884196043,
      -0.02258087508380413,
      0.0885295420885086,
      -0.08167137950658798,
      -0.015491862781345844,
      0.020992793142795563,
      9.038438292918727e-05,
      -0.12304888665676117,
      -0.0029526003636419773,
      0.017224250361323357,
      0.11050955951213837,
      0.020650025457143784,
      -0.051512107253074646,
      -0.038686707615852356,
      -0.058582548052072525,
      0.027107633650302887,
      -0.0028708071913570166,
      -0.05631132423877716,
      0.0385521836578846,
      0.030213186517357826,
      0.037264782935380936
    ],
    [
      -0.02256709709763527,
      0.023259829729795456,
      -0.04494801536202431,
      0.03894814848899841,
      0.052052803337574005,
      -0.0335245206952095,
      0.07403061538934708,
      0.04736096411943436,
      0.10698981583118439,
      0.0766703262925148,
      -0.03704136237502098,
      -0.05568656325340271,
      0.05273768678307533,
      0.020521188154816628,
      -0.05089854449033737,
      -0.006687790155410767,
      0.07685460150241852,
      0.01241922378540039,
      -0.017056209966540337,
      0.04430978745222092,
      0.08005721122026443,
      0.03990809991955757,
      0.017327576875686646,
      -0.05788702890276909,
      0.0851958766579628,
      0.06791403889656067,
      0.0012584722135215998,
      0.016525665298104286,
      0.0584527887403965,
      0.013099160976707935,
      0.02269197441637516,
      0.02894781529903412,
      -0.0224692914634943,
      0.0057823252864181995,
      0.013994946144521236,
      0.024909617379307747,
      -5.631579551845789e-05,
      -0.0479833260178566,
      -0.018260052427649498,
      0.0327409952878952,
      0.0017592284129932523,
      0.018248384818434715,
      -0.024245886132121086,
      -0.03254692256450653,
      -0.054594896733760834,
      -0.0007176537183113396,
      0.07633345574140549,
      0.07436325401067734,
      0.020082471892237663,
      -0.005525544751435518,
      -0.020272022113204002,
      -0.014512232504785061,
      0.04879247769713402,
      -0.015594209544360638,
      -0.017149148508906364,
      0.0287849809974432,
      -0.004124532453715801,
      0.03212679177522659,
      -0.04972319304943085,
      -0.03864753246307373,
      -0.013009914197027683,
      -0.006164217833429575,
      0.02824457176029682,
      -0.013542930595576763,
      -0.01748470962047577,
      -0.060403406620025635,
      -0.08145970106124878,
      -0.058474354445934296,
      -0.05657379329204559,
      0.05982980504631996,
      0.0371919684112072,
      0.02473679929971695,
      0.07868415117263794,
      0.03277885541319847,
      -0.01791759952902794,
      -0.005280486308038235,
      -0.019968897104263306,
      -0.02250201813876629,
      0.03184403106570244,
      -0.050171609967947006,
      0.02093316800892353,
      -0.010250537656247616,
      0.07773230969905853,
      0.019389959052205086,
      -0.05981423705816269,
      -0.05137813836336136,
      -0.002386243548244238,
      0.04147784039378166,
      -0.05732734501361847,
      0.029425615444779396,
      0.02402067929506302,
      -0.03236062452197075,
      -0.07073092460632324,
      0.042171306908130646,
      0.07152894884347916,
      0.026508649811148643,
      0.01964084804058075,
      0.028205135837197304,
      0.013599507510662079,
      0.05209368094801903,
      -0.03316798061132431,
      -0.021811092272400856,
      0.1083514466881752,
      -0.039487145841121674,
      0.0385751947760582,
      0.036432307213544846,
      -0.004174748435616493,
      -0.005227836314588785,
      0.027548445388674736,
      0.03598905727267265,
      -0.027853146195411682,
      -0.05810513719916344,
      -0.007893191650509834,
      -0.021164942532777786,
      -0.01587020233273506,
      0.06915140897035599,
      0.004663135856389999,
      -0.10239070653915405,
      0.06855468451976776,
      -0.08443833142518997,
      0.0027098446153104305,
      -0.007309866137802601,
      -0.011537043377757072,
      0.0577709898352623,
      -0.04763993248343468,
      0.007711751852184534,
      -0.00892816111445427,
      0.0020908925216645002,
      -0.08788787573575974,
      -0.018630580976605415,
      -0.021882619708776474,
      0.07718127965927124,
      0.06824220716953278,
      0.03346097841858864,
      0.11749082058668137,
      -0.01757407747209072,
      0.017206987366080284,
      0.04595011845231056,
      0.0015149570535868406,
      -0.01181483082473278,
      0.011908559128642082,
      0.07660813629627228,
      -0.022069165483117104,
      0.019227495416998863,
      0.003975065425038338,
      -0.05790557339787483,
      0.07787679135799408,
      0.06779404729604721,
      -5.4150455980561674e-05,
      0.011804620735347271,
      0.01648484356701374,
      -0.04289863631129265,
      0.06019556149840355,
      0.030611727386713028,
      -0.0017550922930240631,
      0.08910815417766571,
      -0.01199550461024046,
      -0.01039908267557621,
      0.018126746639609337,
      0.05875144153833389,
      -0.1122974380850792,
      0.029730234295129776,
      -0.15242242813110352,
      -0.014738370664417744,
      -0.023247387260198593,
      -0.031137915328145027,
      0.007524875923991203,
      0.008066917769610882,
      0.04591406136751175,
      -0.01882074400782585,
      -0.011077356524765491,
      -0.09341137111186981,
      -0.10016684234142303,
      -0.03312012925744057,
      0.05465366691350937,
      0.04581761360168457,
      0.06046397238969803,
      0.03907755762338638,
      0.06100674718618393,
      -0.06044863909482956,
      0.011959760449826717,
      -0.04841606318950653,
      0.08962412178516388,
      -0.01858607307076454,
      -0.06074095889925957,
      -0.02465915121138096,
      -0.012430530041456223,
      -0.05530598387122154,
      0.02453114651143551,
      0.05037030950188637,
      -0.04796075075864792,
      -0.06929702311754227,
      0.006412839982658625,
      0.0393437035381794,
      -0.03896308317780495,
      -0.03212793916463852,
      -0.01862889714539051,
      -0.017243053764104843,
      0.033861007541418076,
      0.028287339955568314,
      -0.0015007833717390895,
      -0.06887368857860565,
      -0.030726879835128784,
      0.02066867984831333,
      0.020552368834614754,
      0.08635136485099792,
      0.012518856674432755,
      0.041537392884492874,
      0.04384848475456238,
      -0.06094731390476227,
      0.01831025443971157,
      0.10045208036899567,
      -0.004834502469748259,
      0.03981563821434975,
      -0.054483912885189056,
      -0.02635880932211876,
      -0.013855648227036,
      -0.021672185510396957,
      -0.00598866818472743,
      -0.010280372574925423,
      -0.00927670206874609,
      -0.04941803589463234,
      0.05628596618771553,
      -0.08143436908721924,
      -0.0012162147322669625,
      -0.013478263281285763,
      -0.020984148606657982,
      -0.002363837556913495,
      0.02384171448647976,
      0.04598740488290787,
      0.057504478842020035,
      -0.005210828967392445,
      -0.025983178988099098,
      0.04051798954606056,
      0.009623751975595951,
      -0.008105292916297913,
      0.05157184973359108,
      -0.02286829799413681,
      0.009453154169023037,
      0.038327910006046295,
      0.009437564760446548,
      -0.013919607736170292,
      0.010558037087321281,
      0.008881551213562489,
      -0.032764069736003876,
      0.03154018893837929,
      0.03346363455057144,
      0.07785774767398834,
      0.01889595203101635,
      -0.014235314913094044,
      0.04112667217850685,
      -0.0074869426898658276,
      -0.02803990989923477,
      -0.049623601138591766,
      0.028874194249510765,
      0.009673766791820526,
      0.028660831972956657,
      -0.0035768558736890554,
      -0.03644033148884773,
      -0.005315023008733988,
      -0.009458495303988457,
      -0.07798195630311966,
      -0.06549215316772461,
      0.02274763025343418,
      0.07227703183889389,
      0.023251095786690712,
      -0.010623606853187084,
      -0.05162773281335831,
      -0.06505528092384338,
      0.020474079996347427,
      0.004209342412650585,
      -0.025684447959065437,
      -0.0753784254193306,
      -0.010392299853265285,
      -0.02442094497382641,
      -0.04010338708758354,
      0.015134967863559723,
      0.03053455799818039,
      -0.002246046671643853,
      0.08945824205875397,
      0.03917556628584862,
      0.019513633102178574,
      -0.05640261992812157,
      -0.09684516489505768,
      0.013232041150331497,
      0.005762734916061163,
      -0.11650723218917847,
      0.06977392733097076,
      0.02986627258360386,
      -0.040655918419361115,
      -0.05418438836932182,
      -0.004938601516187191,
      0.05738060176372528,
      -0.00691597955301404,
      -0.023718032985925674,
      -0.03286603093147278,
      0.026505563408136368,
      -0.025335870683193207,
      -0.08295371383428574,
      0.0020845176186412573,
      -0.01994970068335533,
      -0.031529851257801056,
      -0.07292227447032928,
      0.031576626002788544,
      0.007392142433673143,
      0.0016217355150729418,
      0.019424308091402054,
      -0.027418259531259537,
      0.02809988707304001,
      0.008561622351408005,
      0.0010171110043302178,
      -0.05357576906681061,
      0.04269767552614212,
      -0.019270556047558784,
      -0.0024687338154762983,
      0.014269212260842323,
      0.01829279027879238,
      0.022494887933135033,
      -0.019929824396967888,
      -0.0014211265370249748,
      0.10325327515602112,
      0.03046541102230549,
      0.0121865663677454,
      -0.020890360698103905,
      -0.07127105444669724,
      0.04684465378522873,
      -0.006469859275966883,
      0.011795243248343468,
      -0.03482145443558693,
      0.01258496567606926,
      -0.0583193339407444,
      -0.016292288899421692,
      0.03684987872838974,
      0.018708566203713417,
      -0.021623702719807625,
      0.02719072438776493,
      -0.09479617327451706,
      -0.0016903314972296357,
      0.0054066176526248455,
      0.016625652089715004,
      -0.04964571073651314,
      0.06484819948673248,
      -0.012628179974853992,
      0.044148270040750504,
      0.022751761600375175,
      0.029029376804828644,
      0.03062284365296364,
      0.02458881586790085,
      -0.0016014003194868565,
      -0.034340836107730865,
      -0.0933866873383522,
      -0.11099656671285629,
      -0.032951559871435165,
      -0.061966169625520706,
      0.0230296328663826,
      -0.005694816820323467,
      -0.041440609842538834,
      0.03298968821763992,
      -0.03276441618800163,
      0.06115110591053963,
      0.036268144845962524,
      0.021693844348192215,
      0.011508002877235413,
      0.050680845975875854,
      -0.006195841822773218,
      0.02379780076444149,
      -0.07099680602550507,
      0.012808061204850674,
      -0.024307161569595337,
      0.026397470384836197,
      0.007843717001378536,
      0.025650253519415855,
      0.058124251663684845,
      0.06737828254699707,
      -0.051523592323064804,
      0.008942700922489166,
      -0.07481804490089417,
      0.04937455430626869,
      -0.022870484739542007,
      -0.052129827439785004,
      0.058311574161052704,
      0.02118680253624916,
      -0.026429995894432068,
      -0.06007466837763786,
      -0.0006937443977221847,
      -0.07402843236923218,
      0.012244343757629395,
      -0.05557532608509064,
      0.1003909483551979,
      0.04218149930238724,
      -0.020788108929991722,
      0.017797913402318954,
      -0.029739011079072952,
      0.02034914307296276,
      -0.01968454010784626,
      0.14727900922298431,
      -0.019081564620137215,
      -0.007690489757806063,
      0.06177839636802673,
      0.05914964899420738,
      0.00417975103482604,
      0.014344061724841595,
      -0.059633202850818634,
      0.0784277468919754,
      -0.013122793287038803,
      0.10460824519395828,
      -0.000358023215085268,
      -0.005102149210870266,
      0.03352024033665657,
      0.005185282323509455,
      -0.023516841232776642,
      0.014917569234967232,
      -0.020571235567331314,
      -0.008054561913013458,
      -0.005255565978586674,
      0.015315470285713673,
      0.0004689010966103524,
      0.04539111629128456,
      0.01459456980228424,
      0.10468415170907974,
      -0.0036265989765524864,
      0.0022172825410962105,
      0.05527522414922714,
      -0.11847337335348129,
      -0.02156464196741581,
      0.02985873818397522,
      -0.009042673744261265,
      0.009155725128948689,
      0.02973846159875393,
      0.07250542938709259,
      0.015146010555326939,
      -0.011230610311031342,
      0.08117683976888657,
      -0.0010570441372692585,
      -0.0009579655015841126,
      0.04582812637090683,
      -0.03873162344098091,
      -0.039540912955999374,
      0.06714700907468796,
      0.03408046066761017,
      0.024422746151685715,
      -0.014820517972111702,
      0.04026584327220917,
      -0.02813095413148403,
      0.011880429461598396,
      -0.023255933076143265,
      -0.0411393940448761,
      0.011289386078715324,
      0.02698659524321556,
      0.043311670422554016,
      -0.013520765118300915,
      -0.06299804151058197,
      -0.024266162887215614,
      -0.009618387557566166,
      0.04433450847864151,
      -0.011882721446454525,
      0.08554832637310028,
      0.028187425807118416,
      0.03620274364948273,
      0.0723368227481842,
      -0.009471168741583824,
      0.030021976679563522,
      -0.036106403917074203,
      -0.048243895173072815,
      -0.079646036028862,
      -0.026645706966519356,
      0.017372669652104378,
      -0.009662508964538574,
      0.04430820047855377,
      -0.022609703242778778,
      0.07031755149364471,
      0.0025907002855092287,
      0.00479254312813282,
      -0.04292111471295357,
      -0.03644336760044098,
      -0.0261230506002903,
      0.010828831233084202,
      0.03445442393422127,
      -0.014116448350250721,
      0.006558873225003481,
      0.015739165246486664,
      -0.0445869043469429,
      -0.06657187640666962,
      0.041006382554769516,
      -0.04377412423491478,
      0.011247389949858189,
      0.03852807357907295,
      -0.0604633167386055,
      0.04424279183149338,
      -0.01176348514854908,
      -0.01445906050503254,
      -0.045432571321725845,
      0.0405164435505867,
      -0.01150230597704649,
      0.02354425936937332,
      -0.05013413354754448,
      -0.04532381147146225,
      -0.03407302498817444,
      -0.04002593457698822,
      0.06645527482032776,
      0.058530017733573914,
      -0.018498705700039864,
      -0.06237848103046417
    ],
    [
      -0.031072229146957397,
      -0.015943069010972977,
      -0.03916163370013237,
      -0.051712341606616974,
      -0.0007699932320974767,
      0.04413085803389549,
      0.0512876957654953,
      -0.06063366308808327,
      0.015161953866481781,
      0.0417543463408947,
      0.03616189956665039,
      -0.02135554514825344,
      0.04153541848063469,
      -0.022939186543226242,
      0.01164829358458519,
      -0.056161679327487946,
      0.0901644229888916,
      -0.008882975205779076,
      0.07263221591711044,
      -0.015282737091183662,
      0.04649127274751663,
      -0.013930737040936947,
      -0.04149981588125229,
      0.04833696037530899,
      0.026726512238383293,
      -0.0744914785027504,
      0.03672393038868904,
      0.019330408424139023,
      -0.02976413071155548,
      -0.05184316635131836,
      -0.04047970473766327,
      0.01477427314966917,
      -0.02623871900141239,
      -0.03360966593027115,
      0.025297066196799278,
      -0.0228878166526556,
      0.054480019956827164,
      -0.0063982089050114155,
      -0.06115749478340149,
      0.0896717831492424,
      -0.021513521671295166,
      0.050568871200084686,
      -0.04312057048082352,
      -0.0410010889172554,
      0.027811281383037567,
      0.06116417422890663,
      -0.04593668878078461,
      0.021398715674877167,
      0.0001902874791994691,
      -0.012923021800816059,
      -0.038852348923683167,
      0.018146799877285957,
      -0.05106978118419647,
      0.04506541043519974,
      -0.035594988614320755,
      0.06772966682910919,
      0.043174393475055695,
      0.013027360662817955,
      -0.0011419508373364806,
      -0.005394697654992342,
      -0.05702054128050804,
      -0.08164983242750168,
      -0.013928443193435669,
      -0.0480310320854187,
      -0.03584450110793114,
      -0.10936874151229858,
      -0.023989861831068993,
      -0.08812825381755829,
      0.0262600090354681,
      0.10342429578304291,
      -0.021486736834049225,
      0.013806107454001904,
      0.06272227317094803,
      0.019746677950024605,
      -0.024574708193540573,
      0.005681898910552263,
      -0.036374833434820175,
      0.010932682082057,
      -0.0505618080496788,
      0.010219378396868706,
      -0.015611423179507256,
      -0.0166651401668787,
      -0.038968149572610855,
      -0.026180192828178406,
      0.060243669897317886,
      -0.08750806003808975,
      0.014631952159106731,
      -0.003248891793191433,
      -0.050691861659288406,
      0.050194889307022095,
      0.02959739975631237,
      -0.07008381187915802,
      0.01912759616971016,
      -0.04639459401369095,
      -0.012296656146645546,
      0.040397729724645615,
      -0.00019484348013065755,
      -0.03453725948929787,
      -0.02346746250987053,
      -0.05694693326950073,
      0.06696746498346329,
      -0.017566440626978874,
      -0.047599393874406815,
      0.0093767661601305,
      -0.0034578966442495584,
      0.008489619009196758,
      0.0034174376633018255,
      -0.04671907052397728,
      -0.05074205622076988,
      -0.04356374591588974,
      0.03147250413894653,
      -0.03177717700600624,
      -0.08459567278623581,
      -0.0211066622287035,
      -0.019943885505199432,
      0.007339791860431433,
      0.00311272987164557,
      -0.029027650132775307,
      0.03352709859609604,
      -0.021730540320277214,
      -0.05273445323109627,
      0.05421963706612587,
      -0.058819230645895004,
      0.025241224095225334,
      0.05109064280986786,
      -0.0032008655834943056,
      0.04542578384280205,
      -0.052021268755197525,
      0.009163707494735718,
      -0.008763779886066914,
      0.003952211234718561,
      -0.034039005637168884,
      -0.07951106131076813,
      0.0008091374766081572,
      0.027973517775535583,
      -0.029131490737199783,
      0.07911471277475357,
      0.022975793108344078,
      0.012400814332067966,
      0.007533973082900047,
      -0.027067527174949646,
      0.12383808940649033,
      -0.10047909617424011,
      0.034206781536340714,
      -0.02820744551718235,
      -0.015862900763750076,
      0.055602557957172394,
      -0.036908213049173355,
      0.02174168825149536,
      -0.035018615424633026,
      -0.06585800647735596,
      -0.07309653609991074,
      -0.06484650820493698,
      0.059824150055646896,
      0.027188777923583984,
      0.04538637772202492,
      0.01726974919438362,
      0.025942791253328323,
      -0.061083998531103134,
      -0.028545517474412918,
      -0.030972804874181747,
      0.0579121895134449,
      -0.010133326053619385,
      0.04006164148449898,
      0.020143594592809677,
      0.01690581627190113,
      -0.01033973228186369,
      0.005613848567008972,
      0.03733917325735092,
      0.050025902688503265,
      -0.009909261018037796,
      0.08674024045467377,
      -0.016186397522687912,
      7.652414205949754e-05,
      0.027644654735922813,
      0.07017990946769714,
      0.06969520449638367,
      -0.0529029555618763,
      -0.026866145431995392,
      0.05885672569274902,
      0.023891419172286987,
      -0.010627363808453083,
      -0.0016521773068234324,
      -0.0007058289484120905,
      0.015498463995754719,
      0.0038397014141082764,
      -0.00872571486979723,
      0.007773351855576038,
      0.044353824108839035,
      -0.020985201001167297,
      0.14722855389118195,
      0.014890121296048164,
      0.012811693362891674,
      -0.044255997985601425,
      -0.03301648423075676,
      0.0036872157361358404,
      -0.06754185259342194,
      -0.021445056423544884,
      -0.053066693246364594,
      -0.005187029484659433,
      -0.062105562537908554,
      -0.01891537569463253,
      0.0713638886809349,
      0.0023295609280467033,
      0.022721828892827034,
      0.06073290854692459,
      0.033374518156051636,
      -0.1012677475810051,
      0.046667635440826416,
      -0.01645546965301037,
      0.033646054565906525,
      -0.017271924763917923,
      -0.062458377331495285,
      0.013128727674484253,
      0.03001752495765686,
      -0.027174722403287888,
      0.08063394576311111,
      -0.018574470654129982,
      0.049177102744579315,
      -6.144598592072725e-05,
      -0.014159555546939373,
      0.0242911484092474,
      -0.06766922771930695,
      0.07719600945711136,
      -0.04047209396958351,
      -0.005179219413548708,
      0.03106444701552391,
      -0.04447498172521591,
      0.08916456252336502,
      0.04201154038310051,
      0.0344945527613163,
      -0.04233766719698906,
      -0.06788481026887894,
      -0.02193019725382328,
      -0.0285164974629879,
      -0.004821707494556904,
      -0.024795103818178177,
      0.06112778186798096,
      0.0038656010292470455,
      0.04380623623728752,
      -0.0035418609622865915,
      -0.0473710373044014,
      0.016950542107224464,
      -0.022790687158703804,
      0.006250854581594467,
      -0.09374809265136719,
      0.06643469631671906,
      -0.04620814695954323,
      -0.012972553260624409,
      -0.021852904930710793,
      -0.03282211348414421,
      0.021040838211774826,
      0.026999633759260178,
      0.015597443096339703,
      -0.057844556868076324,
      -0.031073441728949547,
      0.05642663687467575,
      -0.007608809042721987,
      0.026819448918104172,
      -0.07334247976541519,
      -0.0056020887568593025,
      -0.049284614622592926,
      0.024144301190972328,
      -0.006153881549835205,
      0.04964553564786911,
      -0.06819135695695877,
      -0.006980019621551037,
      -0.031173232942819595,
      -0.0467415377497673,
      0.030315225943922997,
      -0.028353437781333923,
      -0.052688147872686386,
      -0.07084599882364273,
      0.0028107264079153538,
      -0.006395029369741678,
      -0.004258270375430584,
      -0.02023009955883026,
      0.006404913030564785,
      -0.03913676738739014,
      -0.045130401849746704,
      -0.02324158139526844,
      -0.014097589999437332,
      -0.025656813755631447,
      -0.002189021557569504,
      -0.07211213558912277,
      -0.030562814325094223,
      -0.057952165603637695,
      -0.06647951900959015,
      0.028940144926309586,
      -0.002512316918000579,
      0.0289623886346817,
      0.07847461104393005,
      -0.03769548609852791,
      0.0062012141570448875,
      0.012932062149047852,
      -0.06616851687431335,
      0.026910938322544098,
      0.046044643968343735,
      -0.11763063073158264,
      0.0024761371314525604,
      -0.059371210634708405,
      -0.018444282934069633,
      0.00939966831356287,
      0.06447716802358627,
      0.03355932980775833,
      0.013533988036215305,
      0.015880176797509193,
      -0.027267899364233017,
      0.013145494274795055,
      0.0590478852391243,
      0.10659457743167877,
      -0.03853225335478783,
      0.03590324893593788,
      0.026644958183169365,
      -0.009061574935913086,
      -0.017592428252100945,
      0.08793438971042633,
      0.019195957109332085,
      0.009256822057068348,
      0.017003878951072693,
      -0.07494594156742096,
      -0.01913866028189659,
      -0.00010668166214600205,
      0.049979954957962036,
      0.0017714218702167273,
      -0.019669074565172195,
      -0.020202532410621643,
      0.08945352584123611,
      -0.010640555992722511,
      0.03745880722999573,
      -0.03954767808318138,
      0.027242349460721016,
      0.0015840132255107164,
      -0.005828777328133583,
      0.005295125301927328,
      0.020411722362041473,
      -0.04817366227507591,
      -0.013136226683855057,
      0.02682836726307869,
      0.019710950553417206,
      -0.00223127962090075,
      0.0052475035190582275,
      -0.0033762671519070864,
      0.03684307634830475,
      -0.04216824471950531,
      -0.010806256905198097,
      -0.03137429803609848,
      0.037414178252220154,
      -0.057456862181425095,
      0.10578808188438416,
      -0.04124261438846588,
      -0.0427701473236084,
      0.03233565390110016,
      0.003855337155982852,
      0.03984473645687103,
      -0.01789798028767109,
      0.008817767724394798,
      0.022878725081682205,
      -0.0022003273479640484,
      0.010333777405321598,
      0.014152836054563522,
      0.007661441341042519,
      -0.029017744585871696,
      -0.041006285697221756,
      -0.06483300030231476,
      -0.00030960020376369357,
      0.003437513718381524,
      -0.014208352193236351,
      0.04839109256863594,
      0.023636065423488617,
      0.027531040832400322,
      -0.006598779931664467,
      0.020697398111224174,
      0.044861141592264175,
      -0.06747503578662872,
      0.0629216879606247,
      -0.0483962744474411,
      -0.05160340294241905,
      0.10544444620609283,
      0.004226364195346832,
      0.019370727241039276,
      -0.04490582272410393,
      0.05117498338222504,
      -0.04961123317480087,
      0.019269892945885658,
      0.04096541926264763,
      0.0489463210105896,
      0.011182095855474472,
      0.0818544328212738,
      -0.018727611750364304,
      0.009132036939263344,
      0.014539587311446667,
      -0.059358641505241394,
      0.013881240971386433,
      0.05792011320590973,
      -0.0496455579996109,
      -0.018941940739750862,
      -0.022801339626312256,
      0.04353833198547363,
      0.03744653984904289,
      -0.035630084574222565,
      -0.02897961623966694,
      0.04042886197566986,
      0.07336791604757309,
      -0.0006275982013903558,
      0.008908710442483425,
      0.017869919538497925,
      0.0011227303184568882,
      0.02587139792740345,
      -0.015815090388059616,
      0.028042340651154518,
      0.020922327414155006,
      0.01002038735896349,
      0.0005480234976857901,
      0.012294607236981392,
      -0.010862152092158794,
      0.0455857515335083,
      0.05544978007674217,
      -0.023553039878606796,
      0.06273055821657181,
      -0.03637464717030525,
      -0.11083536595106125,
      0.04534827172756195,
      -0.017038453370332718,
      -0.013800093904137611,
      -0.004673819988965988,
      -0.012590568512678146,
      -0.001929713413119316,
      0.0914422869682312,
      0.004388280678540468,
      -0.10691819339990616,
      -0.005623901728540659,
      0.025544432923197746,
      -0.07830745726823807,
      0.019239304587244987,
      -0.001152879442088306,
      0.0008562670554965734,
      0.0033143162727355957,
      0.00048620664165355265,
      -0.05978567898273468,
      0.0354197658598423,
      -0.03636142984032631,
      0.03412102535367012,
      0.008372115902602673,
      -0.000869829033035785,
      0.054015398025512695,
      -0.024393649771809578,
      -0.04209153726696968,
      -0.026205148547887802,
      -0.027097579091787338,
      -0.035667821764945984,
      -0.07262986898422241,
      -0.031142083927989006,
      0.0644969716668129,
      0.03990456461906433,
      -0.022984206676483154,
      -0.030200591310858727,
      -0.04249457269906998,
      0.008963732980191708,
      -0.006453550420701504,
      -0.015900351107120514,
      0.006386492867022753,
      0.03555351495742798,
      -0.034160859882831573,
      -0.04342330992221832,
      0.06890515983104706,
      -0.018101787194609642,
      0.04258441925048828,
      0.05325137451291084,
      -0.049333397299051285,
      0.025263100862503052,
      0.007168369367718697,
      0.02040756680071354,
      -0.003243597922846675,
      0.021348752081394196,
      -0.01824396289885044,
      -0.010901430621743202,
      -0.007202987093478441,
      -0.019810734316706657,
      -0.02911408245563507,
      -0.006635717116296291,
      0.08968494087457657,
      -0.04651971161365509,
      -0.08618050068616867,
      -0.004453651141375303,
      -0.04148472845554352,
      -0.009159911423921585,
      0.020416252315044403,
      -0.028303761035203934,
      0.005157995503395796,
      0.004504138138145208,
      0.042571716010570526,
      -0.009271386079490185,
      0.030569881200790405,
      -0.01138470321893692,
      -0.0008166683837771416,
      0.05545565485954285,
      0.025515522807836533,
      -0.012539432384073734,
      -0.06389226764440536,
      0.0033515754621475935,
      0.07039684057235718,
      -0.03411678597331047
    ],
    [
      -0.01998649165034294,
      0.047275375574827194,
      0.024236122146248817,
      0.08729527145624161,
      0.010557209141552448,
      0.008503701537847519,
      0.04522741958498955,
      0.04211876913905144,
      -0.014627531170845032,
      0.10230047255754471,
      0.03494611382484436,
      0.03490655496716499,
      0.015804337337613106,
      -0.022417191416025162,
      0.0710035115480423,
      -0.06337492913007736,
      -0.008446414023637772,
      -0.011459394358098507,
      -0.014768040738999844,
      -0.025566525757312775,
      -0.011816740036010742,
      -0.05186813324689865,
      0.014870163053274155,
      0.009194793179631233,
      -0.04682960361242294,
      -0.05905711650848389,
      -0.05932386964559555,
      -0.06139146164059639,
      -0.023836832493543625,
      -0.008304164744913578,
      0.021045438945293427,
      0.09460867941379547,
      0.017640944570302963,
      -0.033377666026353836,
      -0.03946844860911369,
      -0.044043928384780884,
      0.007770250551402569,
      -0.0883442610502243,
      -0.09361159801483154,
      -0.03643890097737312,
      0.05698442831635475,
      -0.004585465416312218,
      -0.016189517453312874,
      0.03710169345140457,
      0.057539764791727066,
      -0.04792215675115585,
      0.052702028304338455,
      0.11386837065219879,
      0.08149481564760208,
      0.02225608006119728,
      0.028607212007045746,
      0.02458934858441353,
      -0.026509247720241547,
      0.04166427254676819,
      -0.0285163763910532,
      0.04014253616333008,
      -0.0011026584543287754,
      -0.05507567152380943,
      0.017466951161623,
      -0.05897941440343857,
      0.024311428889632225,
      0.04793922230601311,
      0.04827803373336792,
      0.1113964170217514,
      -0.027471546083688736,
      0.000788811594247818,
      -0.0916614755988121,
      -0.01810399442911148,
      0.059336304664611816,
      -0.014743764884769917,
      0.10109180957078934,
      -0.07154124975204468,
      0.029812900349497795,
      -0.036845624446868896,
      -0.0493859201669693,
      0.0503021776676178,
      0.04732374846935272,
      0.0031436739955097437,
      -0.04363241419196129,
      0.0576740987598896,
      0.04938434436917305,
      0.05031208693981171,
      0.050373952835798264,
      0.034642886370420456,
      -0.02957846038043499,
      0.033451057970523834,
      0.022003337740898132,
      0.01910308562219143,
      -0.053029615432024,
      -0.03823916241526604,
      0.010478210635483265,
      -0.007929861545562744,
      0.054513201117515564,
      -0.0628267228603363,
      -0.03925972059369087,
      0.01045340858399868,
      -0.050463080406188965,
      0.004995835013687611,
      -0.01856156438589096,
      0.00545079680159688,
      0.05034860596060753,
      0.009940610267221928,
      -0.06635630875825882,
      0.06605455279350281,
      -0.00017621558799874038,
      -0.004052219912409782,
      0.06373634189367294,
      0.09307335317134857,
      -0.045544691383838654,
      -0.0513935312628746,
      0.036330610513687134,
      0.04471742734313011,
      -0.07898056507110596,
      -0.05270003154873848,
      -0.0909300297498703,
      0.07727108150720596,
      0.08331941813230515,
      -0.018981320783495903,
      0.06723253428936005,
      0.03141868859529495,
      0.055472563952207565,
      0.01758209988474846,
      -0.017039231956005096,
      0.039071183651685715,
      0.006332900375127792,
      0.008093084208667278,
      0.06489444524049759,
      0.015958990901708603,
      -0.02692825347185135,
      0.04144103080034256,
      -0.004266355652362108,
      0.01521365623921156,
      0.012822054326534271,
      0.014337686821818352,
      -0.050889987498521805,
      -0.0030604403000324965,
      -0.021686185151338577,
      0.07024210691452026,
      -0.012344163842499256,
      0.009941418655216694,
      0.04104450345039368,
      0.02237987145781517,
      0.014111675322055817,
      0.06584742665290833,
      -0.0003381374117452651,
      0.016067903488874435,
      -0.011114728637039661,
      0.043020013719797134,
      -0.013874785043299198,
      -0.04039651155471802,
      0.06903525441884995,
      0.051304496824741364,
      -0.04472094029188156,
      0.02318468876183033,
      -0.022479910403490067,
      0.004062021151185036,
      -0.005648988299071789,
      0.012911560013890266,
      -0.10823960602283478,
      -0.00364303938113153,
      -0.03173217177391052,
      0.06791190803050995,
      -0.06174374371767044,
      -0.003174633951857686,
      -0.04678522050380707,
      0.08622599393129349,
      -0.07587579637765884,
      0.017523756250739098,
      0.04921574145555496,
      0.007063412107527256,
      -0.007171910256147385,
      0.034210454672575,
      -0.05202551558613777,
      -0.057246264070272446,
      -0.04221034422516823,
      -0.096444271504879,
      0.03459525108337402,
      -0.020758071914315224,
      0.03395143896341324,
      -0.07548590749502182,
      -0.022926228120923042,
      -0.06620699167251587,
      -0.07743244618177414,
      0.029546208679676056,
      -0.03964568302035332,
      -0.042076580226421356,
      -0.016338977962732315,
      0.028379978612065315,
      -0.017638549208641052,
      -0.012698909267783165,
      -0.022510940209031105,
      0.000257521343883127,
      0.044988758862018585,
      -0.027150655165314674,
      0.010496477596461773,
      -0.029881151393055916,
      -0.043742354959249496,
      0.017291611060500145,
      0.045734189450740814,
      -0.02230893261730671,
      0.073270782828331,
      0.05955080687999725,
      -0.0027553141117095947,
      -0.08776161074638367,
      -0.0005886485450901091,
      0.009843372739851475,
      -0.010159729979932308,
      -0.03760874271392822,
      0.0549926795065403,
      0.0074798185378313065,
      -0.025860965251922607,
      -0.0031940536573529243,
      0.003582144621759653,
      0.021230118349194527,
      -0.0024903297889977694,
      0.11148452013731003,
      -0.0015748428413644433,
      -0.08944406360387802,
      -0.005144818220287561,
      0.048099055886268616,
      0.027436135336756706,
      -0.002743268385529518,
      0.02668997086584568,
      -0.0038607800379395485,
      -0.13590846955776215,
      -0.018395956605672836,
      -0.04078667238354683,
      -0.05919681489467621,
      -0.00043240023660473526,
      -0.04129049926996231,
      -0.0836789458990097,
      -0.061307404190301895,
      0.056141454726457596,
      -0.015466667711734772,
      -0.02957477979362011,
      -0.11449935287237167,
      -0.04884238541126251,
      0.05779300630092621,
      0.008349306881427765,
      -0.030275098979473114,
      0.039416275918483734,
      -0.011344176717102528,
      -0.0016380425076931715,
      -0.0510932020843029,
      -0.028058456256985664,
      0.00891667790710926,
      -0.05256542190909386,
      -0.015509690158069134,
      -0.013614494353532791,
      -0.02778817154467106,
      -0.05815744400024414,
      -0.045456621795892715,
      -0.09196154773235321,
      -0.0020143219735473394,
      -0.03212593123316765,
      -0.012661481276154518,
      0.001231282134540379,
      0.06607412546873093,
      0.010472044348716736,
      0.003163302084431052,
      -0.011443027295172215,
      0.038946595042943954,
      -0.05009470880031586,
      -0.028602836653590202,
      0.033340632915496826,
      -0.01779821701347828,
      0.046220678836107254,
      -0.02477654628455639,
      -0.011736219748854637,
      -0.01750984601676464,
      0.028945056721568108,
      0.06471232324838638,
      0.014972075819969177,
      0.013370659202337265,
      -0.004262201488018036,
      0.03355442360043526,
      0.07088575512170792,
      0.023361988365650177,
      0.06163795292377472,
      -0.07367166876792908,
      -0.010902691632509232,
      0.10365664213895798,
      -0.03564649075269699,
      -0.020055929198861122,
      -0.06490171700716019,
      0.022097088396549225,
      -0.0048958598636090755,
      -0.008825930766761303,
      -0.02684328518807888,
      0.05001332610845566,
      -0.006830267608165741,
      0.02887655794620514,
      0.07177109271287918,
      -0.021597832441329956,
      0.043063241988420486,
      0.06263110041618347,
      -0.039361272007226944,
      0.04592205584049225,
      0.02969265729188919,
      0.014404496178030968,
      0.04932919889688492,
      0.03088369034230709,
      0.028477534651756287,
      0.00400844682008028,
      -0.02873246930539608,
      -0.07319436222314835,
      -0.06661131978034973,
      -0.0212861318141222,
      -0.030275098979473114,
      -0.00881779845803976,
      -0.007389886770397425,
      0.020087309181690216,
      0.0034258724190294743,
      -0.015349195338785648,
      -0.01038123294711113,
      -0.02890906296670437,
      0.08081327378749847,
      0.05103589594364166,
      0.0009192111319862306,
      0.010493876412510872,
      -0.024564294144511223,
      -0.10684602707624435,
      0.0175225418061018,
      -0.015330561436712742,
      0.03503972664475441,
      0.025963222607970238,
      -0.06436052918434143,
      0.033120159059762955,
      0.011594383046030998,
      -0.03369244933128357,
      0.026524849236011505,
      -0.03972621262073517,
      -0.022562865167856216,
      0.015736278146505356,
      0.053380560129880905,
      -0.003216211451217532,
      0.01572076790034771,
      0.06938713043928146,
      0.06537885218858719,
      0.04732115566730499,
      -0.023980172351002693,
      -0.03318767622113228,
      0.009594518691301346,
      0.009165850467979908,
      0.023493215441703796,
      0.0497015118598938,
      -0.05243679881095886,
      0.08687474578619003,
      -0.025487538427114487,
      0.00598163390532136,
      -0.038711536675691605,
      0.02096833474934101,
      -0.014768071472644806,
      0.050151675939559937,
      0.06746943295001984,
      0.01109156385064125,
      -0.09548243135213852,
      -0.11642652750015259,
      -0.05784096196293831,
      0.10345875471830368,
      0.009858166798949242,
      0.036014024168252945,
      -0.0020095608197152615,
      0.022370096296072006,
      -0.013873939402401447,
      -0.04539654776453972,
      0.04919586703181267,
      -0.003751165233552456,
      -0.019864842295646667,
      -0.0011654760455712676,
      0.07577534765005112,
      0.004094182047992945,
      0.018283052369952202,
      0.07179324328899384,
      0.07287018746137619,
      -0.04410076141357422,
      -0.018179716542363167,
      -0.06557681411504745,
      0.03614874556660652,
      -0.07391037791967392,
      0.03381011262536049,
      -0.06363493949174881,
      -0.04857777804136276,
      -0.033317290246486664,
      -0.016369421035051346,
      0.06263630837202072,
      -0.08024348318576813,
      0.009089254774153233,
      0.015680674463510513,
      -0.02156778611242771,
      -0.048821888864040375,
      0.052379149943590164,
      0.029130933806300163,
      -0.007699903100728989,
      0.03796357661485672,
      -0.01940649375319481,
      -0.022820651531219482,
      -0.10170330107212067,
      0.030260246247053146,
      -0.041905999183654785,
      0.020383894443511963,
      0.06615550071001053,
      0.012838677503168583,
      0.03051368147134781,
      0.05293930694460869,
      0.0349707156419754,
      0.03794652596116066,
      0.03164755925536156,
      -0.05168464407324791,
      -0.0410613976418972,
      -0.048450861126184464,
      0.01589597575366497,
      0.007906467653810978,
      -0.004635735414922237,
      -0.016186246648430824,
      0.015561724081635475,
      -0.04240783303976059,
      -0.08445747196674347,
      0.022571928799152374,
      0.009217360056936741,
      0.05145122855901718,
      0.03706075996160507,
      -0.03358491510152817,
      -0.008333700709044933,
      -0.0030969404615461826,
      0.026068318635225296,
      0.008703595958650112,
      0.012122216634452343,
      0.03866729512810707,
      0.026494279503822327,
      0.00599380349740386,
      -0.016977962106466293,
      0.011070875450968742,
      0.0777256116271019,
      -0.07623742520809174,
      -0.004337395075708628,
      -0.011231323704123497,
      0.07862215489149094,
      0.01858871430158615,
      -0.04419142007827759,
      -0.0496470183134079,
      -0.02154374122619629,
      -0.028465915471315384,
      0.009783976711332798,
      0.007384882774204016,
      0.024936143308877945,
      -0.04823365435004234,
      -0.06952717155218124,
      -0.04314089193940163,
      -0.020193425938487053,
      0.006344492081552744,
      -0.05217420682311058,
      0.014081434346735477,
      -0.030384821817278862,
      0.04708518460392952,
      0.011307147331535816,
      -0.0700560063123703,
      -0.12032534182071686,
      -0.004152119625359774,
      -0.02193964086472988,
      -0.0825018584728241,
      0.05147527530789375,
      -0.028617367148399353,
      -0.031223002821207047,
      0.01333796139806509,
      0.07042452692985535,
      -0.05537548288702965,
      -0.07883809506893158,
      0.03861547261476517,
      0.04024335741996765,
      0.019333042204380035,
      0.061903584748506546,
      0.006003562361001968,
      0.03865163028240204,
      -0.0572935976088047,
      0.0878479853272438,
      -0.023250794038176537,
      0.05072357878088951,
      0.022225746884942055,
      -0.17570263147354126,
      -0.024340268224477768,
      0.009765218012034893,
      -0.07358988374471664,
      -0.03500540181994438,
      -0.08157828450202942,
      -0.09182276576757431,
      -0.02462613768875599,
      0.060983650386333466,
      -0.008307835087180138,
      -0.009321542456746101,
      0.0404064804315567,
      0.02840186469256878,
      -0.013225826434791088,
      0.07804162055253983,
      0.04077238589525223,
      0.015318776480853558,
      -0.04198610410094261,
      0.016298610717058182,
      -0.07621083408594131,
      -0.058611225336790085,
      0.04467654600739479,
      0.002629574853926897,
      0.04204438626766205
    ],
    [
      0.019640782848000526,
      -0.04100675880908966,
      -0.039755068719387054,
      0.029453426599502563,
      0.004849012941122055,
      0.016574449837207794,
      -0.006656454876065254,
      -0.024913491681218147,
      0.01534886471927166,
      -0.030440155416727066,
      0.010738544166088104,
      0.007959915325045586,
      0.08303477615118027,
      -0.024224834516644478,
      0.06562681496143341,
      -0.04988737031817436,
      0.06705906987190247,
      0.08152037113904953,
      0.0896807387471199,
      -0.025608748197555542,
      -0.017781546339392662,
      0.006534200627356768,
      -0.01190970093011856,
      0.0211655143648386,
      0.01915781758725643,
      -0.040716636925935745,
      0.02007054165005684,
      0.010343257337808609,
      0.11034983396530151,
      0.00437813438475132,
      -0.023776395246386528,
      -0.019938020035624504,
      0.04502881318330765,
      -0.12478789687156677,
      0.03429793566465378,
      -0.049037568271160126,
      0.0164489783346653,
      -0.06393357366323471,
      -0.08959252387285233,
      0.011116918176412582,
      -0.099716417491436,
      -0.026847846806049347,
      -0.04208369553089142,
      -0.01176099106669426,
      -0.035658098757267,
      -0.05011100694537163,
      0.12487613409757614,
      0.04183840751647949,
      0.05261705070734024,
      -0.025526195764541626,
      0.0967881977558136,
      0.1319614201784134,
      -0.08379297703504562,
      0.0642041340470314,
      0.002469348721206188,
      -0.06353805959224701,
      -0.033095765858888626,
      0.08772721141576767,
      -0.022260162979364395,
      0.03283504396677017,
      0.029084622859954834,
      -0.016802150756120682,
      -0.01617993786931038,
      0.05853725224733353,
      -0.08897002786397934,
      0.034899476915597916,
      0.01760268025100231,
      -0.06288457661867142,
      0.019568486139178276,
      0.030944693833589554,
      0.03321567177772522,
      0.02783704176545143,
      0.009312121197581291,
      0.11573326587677002,
      0.0017913231858983636,
      0.0103388000279665,
      -0.06733179092407227,
      -0.002686061430722475,
      0.002282168250530958,
      0.08039072155952454,
      -0.006111567839980125,
      -0.020156409591436386,
      -0.04686550050973892,
      -0.05445700138807297,
      -0.020726321265101433,
      -0.06987161934375763,
      0.07142888754606247,
      -0.00417922530323267,
      -0.07104811817407608,
      0.07186082005500793,
      0.004651105031371117,
      -0.002554209902882576,
      -0.022406192496418953,
      0.007304212544113398,
      0.01931404508650303,
      -0.05741056427359581,
      -0.03359455615282059,
      -0.038721900433301926,
      0.033315956592559814,
      -0.05223779380321503,
      0.098416268825531,
      0.016432300209999084,
      -0.0002095437521347776,
      0.03955971822142601,
      -0.01265636645257473,
      -0.03682943060994148,
      -0.013014477677643299,
      -0.005604856181889772,
      0.01730281673371792,
      -0.03552602231502533,
      0.03591068834066391,
      -0.004514864180237055,
      -0.048012007027864456,
      0.09967402368783951,
      -0.03222433477640152,
      0.023391110822558403,
      -0.0804455578327179,
      0.05702744051814079,
      -0.08488134294748306,
      -0.029174841940402985,
      -0.04482186585664749,
      0.07878720760345459,
      0.008152326568961143,
      0.07627831399440765,
      0.00509231211617589,
      -0.0014690764946863055,
      -0.04381117969751358,
      0.040084488689899445,
      0.0003869993379339576,
      -0.02459223009645939,
      0.1324177235364914,
      0.006643170025199652,
      -0.05272334814071655,
      0.07184186577796936,
      0.005206342786550522,
      0.019474763423204422,
      0.06502258777618408,
      -0.03327145054936409,
      0.02277827076613903,
      -0.04885800927877426,
      -0.05746592581272125,
      -0.0031881644390523434,
      -0.03760281577706337,
      0.025992875918745995,
      0.034114062786102295,
      -0.02773253805935383,
      -0.0020337991882115602,
      -0.0008364625391550362,
      -0.0385335236787796,
      0.06724958121776581,
      -0.028417613357305527,
      0.04441423714160919,
      0.0355018712580204,
      -0.014479364268481731,
      -0.00016761274309828877,
      -0.0536300465464592,
      0.09639613330364227,
      -0.049730829894542694,
      -0.004069411661475897,
      0.06754170358181,
      0.0036760454531759024,
      -0.06978194415569305,
      0.013565191067755222,
      0.05617835000157356,
      -0.048874326050281525,
      0.0761047974228859,
      -0.05023006349802017,
      -0.06493180245161057,
      0.024554673582315445,
      0.048085082322359085,
      0.0030532702803611755,
      -0.024288654327392578,
      -0.08361470699310303,
      0.06696915626525879,
      -0.08624435216188431,
      -0.15606267750263214,
      -0.006985003128647804,
      0.011944756843149662,
      -0.0001492793089710176,
      0.027185039594769478,
      0.01902337558567524,
      -0.0675341859459877,
      0.058300312608480453,
      0.002230266109108925,
      0.017993275076150894,
      0.008285390213131905,
      0.012320931069552898,
      -0.049589306116104126,
      0.04358077049255371,
      0.03206205740571022,
      0.010338560678064823,
      0.01780151017010212,
      -0.01975231245160103,
      0.008270345628261566,
      -0.05032438039779663,
      -0.0014794513117522001,
      -0.010604461655020714,
      -0.044111043214797974,
      0.006992844399064779,
      0.03901775926351547,
      -0.01684986613690853,
      0.009521952830255032,
      0.04323780909180641,
      -0.03021586686372757,
      0.056874267756938934,
      0.013968432322144508,
      0.02348356880247593,
      0.03242047131061554,
      -0.04532889276742935,
      -0.05876675248146057,
      0.067800372838974,
      0.030745454132556915,
      -0.0773610919713974,
      -0.08610303699970245,
      0.03322384133934975,
      -0.007485226262360811,
      -0.011749733239412308,
      -0.01920902729034424,
      -0.04171003773808479,
      0.012449463829398155,
      0.06518304347991943,
      0.04757949337363243,
      -0.02145092748105526,
      -0.007283759769052267,
      -0.013415182940661907,
      0.012794104404747486,
      0.054628923535346985,
      -0.013624431565403938,
      0.008292053826153278,
      0.002767950529232621,
      0.005334704648703337,
      -0.034156545996665955,
      -0.014715082943439484,
      -0.01908119209110737,
      0.028463060036301613,
      0.0009402185678482056,
      0.046699974685907364,
      0.029986504465341568,
      -0.044272828847169876,
      -0.06209846958518028,
      0.008498666808009148,
      -0.011084791272878647,
      0.056546952575445175,
      -0.06080658361315727,
      -0.0692191794514656,
      0.04294396936893463,
      0.0649591013789177,
      -0.01508224755525589,
      0.009415576234459877,
      -0.08575782924890518,
      -0.07301496714353561,
      -0.016401279717683792,
      0.0183145422488451,
      -0.007372574880719185,
      -0.04417479783296585,
      0.10610424727201462,
      0.04396160691976547,
      0.08163895457983017,
      0.007554843556135893,
      0.06422779709100723,
      0.01773291639983654,
      -0.04328739643096924,
      -0.0516071543097496,
      -0.028576303273439407,
      -0.09018102288246155,
      -0.019669795408844948,
      0.08414659649133682,
      -0.09434661269187927,
      -0.06623515486717224,
      -0.10208173096179962,
      0.022856958210468292,
      0.05753221735358238,
      0.007616812828928232,
      -0.03768370300531387,
      0.09156548976898193,
      -0.037644896656274796,
      -0.01591126248240471,
      -0.019688406959176064,
      0.021200131624937057,
      -0.010744872502982616,
      -0.008776461705565453,
      -0.0425075925886631,
      0.018389247357845306,
      0.0241971667855978,
      0.0038766050711274147,
      -0.04330294579267502,
      -0.008767780847847462,
      -0.0953117236495018,
      -0.005711424630135298,
      0.07703182101249695,
      0.0331723690032959,
      0.017218302935361862,
      0.019507065415382385,
      0.06521731615066528,
      -0.025698015466332436,
      0.04119861125946045,
      0.016133015975356102,
      0.05980078503489494,
      0.04956263303756714,
      0.028282277286052704,
      -0.013073521666228771,
      0.012693130411207676,
      -0.0029892504680901766,
      0.04894004762172699,
      -0.026832498610019684,
      0.05585651099681854,
      -0.030800344422459602,
      -0.014195993542671204,
      -0.035092271864414215,
      0.055706195533275604,
      0.04747413098812103,
      0.009783557616174221,
      -0.021429704502224922,
      0.03124723769724369,
      -0.10645965486764908,
      -0.01683908700942993,
      -0.004439536482095718,
      0.033652957528829575,
      0.050840117037296295,
      -0.03305065259337425,
      -0.035370778292417526,
      -0.031143708154559135,
      -0.14884480834007263,
      -0.04204615205526352,
      -0.06166839972138405,
      -0.08610129356384277,
      0.041925814002752304,
      0.004016074351966381,
      0.04935509338974953,
      0.00966782495379448,
      0.059845663607120514,
      -0.0354597344994545,
      0.014467425644397736,
      -0.04692446067929268,
      -0.018879322335124016,
      -0.05218079313635826,
      -0.07826334983110428,
      -0.030407527461647987,
      0.0023116201627999544,
      -0.022412650287151337,
      0.012659577652812004,
      -0.057134244590997696,
      0.012545136734843254,
      -0.00981925893574953,
      0.004605458118021488,
      0.02082093432545662,
      -0.059420499950647354,
      0.007411805912852287,
      0.08789514750242233,
      0.011202529072761536,
      0.07985399663448334,
      0.054692938923835754,
      -0.03687335550785065,
      -0.032490842044353485,
      0.020784035325050354,
      -0.043911635875701904,
      0.00498579116538167,
      0.01594441942870617,
      -0.02221592888236046,
      -0.08851315826177597,
      -0.08605175465345383,
      0.0363631509244442,
      -0.01672748290002346,
      -0.0016486988170072436,
      0.03400031849741936,
      -0.01637282222509384,
      0.0018537993310019374,
      -0.018680168315768242,
      0.06297550350427628,
      0.011965705081820488,
      0.06120894104242325,
      0.04263637214899063,
      0.03090951032936573,
      0.037083450704813004,
      -0.029414938762784004,
      0.004471173044294119,
      0.056362446397542953,
      0.01886523887515068,
      0.11160171777009964,
      -0.011391222476959229,
      0.031020388007164,
      0.06885963678359985,
      -0.011941744014620781,
      0.0173886027187109,
      0.02976837195456028,
      0.004609681200236082,
      0.006353419274091721,
      -0.007119744550436735,
      0.0397832877933979,
      0.05615390092134476,
      0.025111669674515724,
      0.010675360448658466,
      0.09877890348434448,
      -0.023084213957190514,
      -0.039796944707632065,
      0.020743392407894135,
      -0.02325938269495964,
      0.012311774305999279,
      0.045827072113752365,
      -0.0395573265850544,
      -0.06718900054693222,
      -0.028475413098931313,
      0.03189271315932274,
      -0.026622211560606956,
      -0.004047667142003775,
      -0.03720647096633911,
      0.04392202943563461,
      -0.0031864563934504986,
      -0.029453255236148834,
      -0.03695176541805267,
      0.037043340504169464,
      -0.03442785516381264,
      0.03256507217884064,
      -0.040385302156209946,
      0.04424746334552765,
      0.005556387826800346,
      -0.050322275608778,
      0.007132670376449823,
      0.0005410625599324703,
      0.029829543083906174,
      -0.04020906984806061,
      0.03056536614894867,
      0.048523202538490295,
      -0.03868459537625313,
      -0.07664533704519272,
      -0.030767880380153656,
      0.09112565219402313,
      0.029017675668001175,
      0.11048568040132523,
      0.044862017035484314,
      0.0012640842469409108,
      0.07003121078014374,
      -0.01766502857208252,
      0.05955837666988373,
      0.0008929870091378689,
      0.0035078537184745073,
      -0.044411201030015945,
      0.03587649390101433,
      0.05120595917105675,
      0.04424123093485832,
      -0.014053183607757092,
      -0.013976393267512321,
      0.040268879383802414,
      -0.07853519171476364,
      -0.013995085842907429,
      0.026648327708244324,
      0.041712574660778046,
      0.04381457716226578,
      -0.08867821097373962,
      -0.030653122812509537,
      -0.03510338068008423,
      -0.04798086732625961,
      -0.057463400065898895,
      0.03367772698402405,
      -0.005281776189804077,
      -0.0035502451937645674,
      -0.02305973693728447,
      -0.08117864280939102,
      0.015464909374713898,
      0.09515132755041122,
      -0.06805936992168427,
      0.08604598045349121,
      0.021167509257793427,
      -0.08641421794891357,
      0.0034958343021571636,
      0.08543088287115097,
      -0.02827306091785431,
      -0.10135293751955032,
      0.035450223833322525,
      0.030290469527244568,
      0.03475089371204376,
      0.07419592887163162,
      0.05371788889169693,
      -0.01817384362220764,
      0.0011371058644726872,
      -0.028777949512004852,
      0.024642549455165863,
      -0.04196125641465187,
      0.06767378747463226,
      0.016031693667173386,
      -0.007714700419455767,
      -0.06433681398630142,
      0.0938367024064064,
      -0.019831111654639244,
      -0.00444225175306201,
      -0.007916531525552273,
      -0.059520673006772995,
      -0.0020785084925591946,
      0.009578566066920757,
      -0.059465888887643814,
      0.006910276133567095,
      -0.010246396996080875,
      0.01300885621458292,
      -0.04634447023272514,
      -0.05149925872683525,
      0.007941403426229954,
      -0.031839363276958466,
      -0.002730288077145815,
      0.02308199182152748,
      -0.05570366978645325,
      -0.009267674759030342,
      -0.0008662129403091967,
      -0.0039460021071136
    ],
    [
      0.04002847149968147,
      -0.00602800864726305,
      -0.048402901738882065,
      0.006674229167401791,
      -0.033839672803878784,
      0.03045414760708809,
      0.005131549201905727,
      0.05237317085266113,
      0.013291772454977036,
      -0.06396336853504181,
      0.0459233894944191,
      0.02633829042315483,
      0.05682344362139702,
      0.10054205358028412,
      -0.045045193284749985,
      0.001861103344708681,
      -0.01690780371427536,
      -0.056711453944444656,
      -0.026521550491452217,
      0.04754941910505295,
      0.03662899509072304,
      -0.058351293206214905,
      0.0008982527651824057,
      -0.03669444099068642,
      -0.029933564364910126,
      -0.0337488017976284,
      0.024801038205623627,
      0.026422789320349693,
      0.02523348107933998,
      0.05059382691979408,
      0.026293223723769188,
      -0.029584752395749092,
      0.0664643794298172,
      0.03130246326327324,
      0.0272847767919302,
      -0.030628995969891548,
      -0.023570258170366287,
      0.012399116531014442,
      -0.023101162165403366,
      0.09513496607542038,
      0.04462134465575218,
      0.02748947963118553,
      0.04840799421072006,
      -0.0136026656255126,
      -0.009857146069407463,
      0.01254023052752018,
      0.0068376739509403706,
      0.04339168220758438,
      0.0990915447473526,
      0.0837496966123581,
      -0.0086881248280406,
      -0.022547347471117973,
      -0.011583945713937283,
      -0.009210274554789066,
      0.00892809871584177,
      0.06034046411514282,
      0.036681633442640305,
      0.04350887984037399,
      0.018600838258862495,
      -0.06088650971651077,
      -0.012898086570203304,
      -0.06389950215816498,
      0.023834604769945145,
      0.05315740779042244,
      -0.05642039701342583,
      -0.013216759078204632,
      0.02979345992207527,
      -0.08450044691562653,
      -0.05650606378912926,
      0.05626385658979416,
      -0.1214015856385231,
      -0.006768075283616781,
      9.163577487925068e-06,
      -0.017417551949620247,
      0.029939591884613037,
      -0.0033409467432647943,
      0.015833420678973198,
      0.0551610141992569,
      0.04481705650687218,
      -0.0024291027802973986,
      0.022273756563663483,
      -0.033746138215065,
      -0.01595950685441494,
      0.05208120867609978,
      0.09191384166479111,
      -0.025682134553790092,
      0.0076032779179513454,
      0.014933386817574501,
      -0.005246133077889681,
      0.01295754499733448,
      -0.03203558549284935,
      -0.042710766196250916,
      0.05018124356865883,
      -0.04934772104024887,
      0.03706873208284378,
      0.024386251345276833,
      0.0345403328537941,
      -0.02445686236023903,
      0.05052746459841728,
      -0.009313683956861496,
      -0.005805449094623327,
      -0.020473161712288857,
      -0.03920905292034149,
      0.010572564788162708,
      -0.07125949114561081,
      0.0054272799752652645,
      0.047707319259643555,
      -0.09747986495494843,
      -0.07391945272684097,
      -0.030723733827471733,
      -0.002395905088633299,
      0.004209898412227631,
      0.005696192383766174,
      -0.018792182207107544,
      -0.04234985634684563,
      -0.021627450361847878,
      0.007794352248311043,
      -0.08890481293201447,
      -0.09672203660011292,
      0.03316395729780197,
      0.04625580087304115,
      -0.08255435526371002,
      -0.014777283184230328,
      0.04623738303780556,
      0.01457693986594677,
      0.01337223220616579,
      -0.07141555845737457,
      -0.09853992611169815,
      -0.04149389639496803,
      0.03189777955412865,
      0.03727766498923302,
      0.027710286900401115,
      -0.05890120565891266,
      -0.016351498663425446,
      0.013920546509325504,
      -0.02634490467607975,
      -0.08936995267868042,
      0.017588887363672256,
      0.029770758002996445,
      0.06043137609958649,
      0.009392962791025639,
      -0.014991417527198792,
      0.058478955179452896,
      0.01212610024958849,
      0.050730325281620026,
      0.06216796487569809,
      -0.030026836320757866,
      0.01190255582332611,
      0.0009625122183933854,
      0.025750426575541496,
      -0.05072714015841484,
      -0.04051320254802704,
      -0.01887759007513523,
      -0.011899365112185478,
      0.07992786169052124,
      0.07054130733013153,
      -0.08631815016269684,
      -0.007802919950336218,
      -0.009232081472873688,
      0.009111356921494007,
      0.08960669487714767,
      -0.03076695278286934,
      -0.03481462225317955,
      0.04674384370446205,
      0.06761424988508224,
      -0.0029307061340659857,
      -0.004214367363601923,
      0.017947230488061905,
      0.004339156672358513,
      0.0016031386330723763,
      -0.06509192287921906,
      0.03903917968273163,
      -0.02562529593706131,
      -0.013589498586952686,
      0.020012062042951584,
      -0.035473767668008804,
      -0.0374295711517334,
      -0.06371117383241653,
      0.1003689169883728,
      -0.056176669895648956,
      -0.11492983996868134,
      0.012986613437533379,
      -0.008014224469661713,
      0.0400364063680172,
      0.10167769342660904,
      0.060951218008995056,
      -0.035845134407281876,
      -0.03151249885559082,
      0.001994140911847353,
      0.03671037405729294,
      0.02875450812280178,
      0.005398563574999571,
      0.004997347481548786,
      -0.013494748622179031,
      -0.0620274618268013,
      -0.01542323362082243,
      0.06826594471931458,
      -0.0756385400891304,
      0.0007275462849065661,
      -0.01049831137061119,
      -0.00638189073652029,
      -0.011142507195472717,
      -0.018781201913952827,
      -0.01658174768090248,
      -0.055110253393650055,
      0.12406307458877563,
      0.023426977917551994,
      0.008552336134016514,
      -0.022896038368344307,
      0.0030174546409398317,
      0.02082347869873047,
      0.04376230761408806,
      -0.07402950525283813,
      -0.07182010263204575,
      -0.008489448577165604,
      0.06981426477432251,
      0.030173897743225098,
      -0.01793207973241806,
      -0.030031925067305565,
      0.058093708008527756,
      0.016425123438239098,
      -0.050663694739341736,
      -0.0035632988438010216,
      0.04210377857089043,
      -0.015433619730174541,
      0.0013995385961607099,
      -0.08767402917146683,
      -0.08312870562076569,
      -0.03022652305662632,
      -0.007244824431836605,
      -0.06168197840452194,
      -0.08001462370157242,
      -0.07805725187063217,
      -0.011363948695361614,
      0.09219282120466232,
      -0.01763010397553444,
      -0.002243882045149803,
      -0.013711288571357727,
      -0.04263937473297119,
      -0.01197955384850502,
      -0.022246504202485085,
      0.029950812458992004,
      0.01057061180472374,
      -0.12097800523042679,
      -0.06631234288215637,
      -0.001969087403267622,
      0.011827070266008377,
      0.0016056272434070706,
      0.014931063167750835,
      0.010196484625339508,
      0.029170669615268707,
      0.04006415233016014,
      -0.08160445094108582,
      0.036344122141599655,
      0.08565251529216766,
      -0.01431240700185299,
      -0.0033769041765481234,
      0.04364586994051933,
      0.055462002754211426,
      -0.034655824303627014,
      0.020159078761935234,
      -0.09932706505060196,
      0.01023457944393158,
      -0.015895534306764603,
      -0.04388251155614853,
      0.04991110414266586,
      -0.03808210790157318,
      0.017707500606775284,
      0.03494281321763992,
      -0.05766346678137779,
      0.013024437241256237,
      -0.020918775349855423,
      -0.08464740216732025,
      -0.01938665471971035,
      -0.028134264051914215,
      0.011661653406918049,
      0.04276341572403908,
      0.05250297859311104,
      -0.0006035666447132826,
      0.01781017892062664,
      0.01350060012191534,
      -0.042036570608615875,
      -0.004940829239785671,
      -0.10926620662212372,
      -0.005121362861245871,
      -0.07220669090747833,
      -0.08342093229293823,
      0.08963476121425629,
      0.0964621976017952,
      -0.04886851832270622,
      0.008883098140358925,
      -0.0005972344079054892,
      -0.03825864940881729,
      0.05671139061450958,
      -0.00472612027078867,
      -0.00037554578739218414,
      -0.08228498697280884,
      0.07465493679046631,
      0.03359176218509674,
      0.019138365983963013,
      0.0763205885887146,
      -0.0026047667488455772,
      0.033256594091653824,
      -0.01730247214436531,
      0.01607591286301613,
      -0.08082521706819534,
      0.0584372878074646,
      -0.08817285299301147,
      0.024000313133001328,
      -0.015866803005337715,
      0.019055720418691635,
      0.03443598747253418,
      0.10204464197158813,
      -0.07384586334228516,
      0.01715366169810295,
      -0.006780422292649746,
      0.01876814477145672,
      -0.01037795003503561,
      0.02124113216996193,
      -0.010447446256875992,
      0.042103201150894165,
      0.061723511666059494,
      -0.04524710774421692,
      0.02410956285893917,
      0.01749894954264164,
      -0.035961564630270004,
      0.05909593030810356,
      -0.01606779731810093,
      0.03507387265563011,
      -0.014199553057551384,
      -0.06445532292127609,
      0.040132857859134674,
      -0.0038943011313676834,
      0.09696696698665619,
      -0.036500975489616394,
      -0.05994093418121338,
      -0.009619258344173431,
      0.006632796488702297,
      0.0651945173740387,
      -0.02820701152086258,
      -0.06366255134344101,
      -0.055076416581869125,
      -0.00899653509259224,
      -0.05600092187523842,
      0.05873880907893181,
      0.0056622931733727455,
      -0.06610114872455597,
      0.03544194623827934,
      -0.056032996624708176,
      0.025528181344270706,
      0.0814606100320816,
      0.03469878062605858,
      -0.04202907159924507,
      -0.019752345979213715,
      -0.03610937297344208,
      -0.0380813404917717,
      0.08855601400136948,
      -0.03991040214896202,
      0.09568174928426743,
      -0.011685941368341446,
      0.04980801045894623,
      -0.05939459800720215,
      -0.004623793065547943,
      -0.010457528755068779,
      0.050810012966394424,
      -0.03079763613641262,
      0.061397943645715714,
      0.004924688022583723,
      0.10618855059146881,
      -0.07986436039209366,
      0.0014650019584223628,
      -0.020311502739787102,
      0.0054409499280154705,
      -0.004567465744912624,
      0.04077164828777313,
      -0.023701071739196777,
      0.021689454093575478,
      -0.01716594025492668,
      -0.07408459484577179,
      -0.08782415091991425,
      0.04578220844268799,
      0.00589835224673152,
      0.02815474569797516,
      0.01122141070663929,
      -0.02601110376417637,
      0.0489526130259037,
      0.07396022975444794,
      0.09825021773576736,
      0.025942161679267883,
      0.01837736740708351,
      0.005257893353700638,
      -0.00623894389718771,
      -0.01605435088276863,
      0.029394134879112244,
      0.033069636672735214,
      -0.07309702783823013,
      -0.06158297881484032,
      0.037012580782175064,
      -0.0348215289413929,
      0.014828131534159184,
      0.015564256347715855,
      0.03887639194726944,
      0.026645643636584282,
      0.015115583315491676,
      0.031861811876297,
      0.04433335363864899,
      -0.0004141301033087075,
      0.024394936859607697,
      0.007152749691158533,
      -0.04468676820397377,
      -0.023784248158335686,
      -0.04909749701619148,
      0.026736781001091003,
      0.05848466604948044,
      0.08184095472097397,
      -0.029000552371144295,
      -0.011596208438277245,
      0.0024069282226264477,
      -0.016482055187225342,
      -0.005298348609358072,
      -0.07399383932352066,
      0.07033361494541168,
      0.015172298066318035,
      0.04512540251016617,
      0.03917154297232628,
      0.006689147558063269,
      0.011335980147123337,
      0.03881785273551941,
      0.02871336229145527,
      0.06629423052072525,
      -0.06418532133102417,
      0.05128513649106026,
      -0.021775146946310997,
      0.047356631606817245,
      0.010145481675863266,
      -0.008602931164205074,
      0.057323090732097626,
      0.013335272669792175,
      -0.025095036253333092,
      0.014877370558679104,
      -0.011693429201841354,
      -0.033622805029153824,
      0.015877384692430496,
      0.027691878378391266,
      -0.051755595952272415,
      -0.03427824005484581,
      -0.1164858266711235,
      0.05877186357975006,
      -0.0413699671626091,
      -0.024606412276625633,
      0.02402169443666935,
      0.015425056219100952,
      -0.0013665511505678296,
      -0.0050537800416350365,
      -0.0471775121986866,
      0.05748685821890831,
      0.03278503566980362,
      0.03180922195315361,
      -0.02768578566610813,
      0.030218597501516342,
      0.10528197139501572,
      0.006508141756057739,
      0.00031870591919869184,
      -0.054649826139211655,
      0.04853984713554382,
      -0.022251224145293236,
      -0.02285638265311718,
      0.03215552493929863,
      0.008942517451941967,
      0.00904021319001913,
      0.025679271668195724,
      0.0445123054087162,
      0.054167427122592926,
      -0.05195019021630287,
      -0.024770721793174744,
      0.1293816864490509,
      0.06168612092733383,
      0.03297640010714531,
      -0.039570119231939316,
      0.0766504555940628,
      -0.006125357933342457,
      0.05253994092345238,
      0.008179734461009502,
      0.01553498487919569,
      0.0036140435840934515,
      0.00400540791451931,
      0.013345948420464993,
      -0.018450602889060974,
      -0.018062137067317963,
      -0.0680936798453331,
      0.007472314406186342,
      0.049147505313158035,
      0.040974754840135574,
      -0.05647461861371994,
      0.05818377807736397,
      -0.048112042248249054,
      0.010401953011751175,
      -0.011370690539479256,
      -0.022800719365477562,
      0.025571851059794426,
      -0.0012641038047149777,
      -0.03340277075767517,
      -0.006052710115909576
    ],
    [
      0.02471487782895565,
      -0.037074603140354156,
      -0.01881430484354496,
      -0.04048461839556694,
      0.01586097478866577,
      0.0068344431929290295,
      0.01894255355000496,
      -0.01673409901559353,
      0.023492462933063507,
      -0.024594666436314583,
      -0.07649853080511093,
      0.015121753327548504,
      0.023984048515558243,
      0.05039753019809723,
      -0.05953206494450569,
      0.045957524329423904,
      -0.05680543929338455,
      -0.0035963263362646103,
      0.07306000590324402,
      -0.002119639189913869,
      -0.0034599322825670242,
      0.03173265606164932,
      0.034310389310121536,
      0.00965135358273983,
      0.08104363828897476,
      -0.059239961206912994,
      -0.045154836028814316,
      -0.043819282203912735,
      -0.0002329694398213178,
      0.0026615981478244066,
      -0.07190150767564774,
      -0.018256789073348045,
      0.052501942962408066,
      0.012188856489956379,
      -0.008993498049676418,
      0.056525930762290955,
      0.0055125122889876366,
      -0.03422924503684044,
      0.005583297926932573,
      0.026957716792821884,
      -0.0947192832827568,
      0.06583569943904877,
      0.029178602620959282,
      -0.02327660284936428,
      0.016561638563871384,
      0.07556859403848648,
      0.037057675421237946,
      -0.006154142320156097,
      0.06015869602560997,
      0.10907316207885742,
      0.06492775678634644,
      0.01762692630290985,
      -0.036054231226444244,
      -0.026282144710421562,
      0.06777675449848175,
      0.043503351509571075,
      0.06283291429281235,
      -0.0013531346339732409,
      -0.026654023677110672,
      -0.012827737256884575,
      0.01792730763554573,
      0.0695335865020752,
      0.06117773428559303,
      0.014550521038472652,
      0.002815077779814601,
      0.04769580811262131,
      0.05865718424320221,
      -0.003151258220896125,
      -0.014522339217364788,
      0.015080168843269348,
      0.0067599499598145485,
      0.057306770235300064,
      -0.06666817516088486,
      0.09817391633987427,
      -0.016023287549614906,
      0.07743620872497559,
      -0.003453101497143507,
      -0.044951409101486206,
      0.042369358241558075,
      -0.005407045129686594,
      0.0044984291307628155,
      -0.008138933219015598,
      -0.07987884432077408,
      0.061056025326251984,
      -0.034381408244371414,
      0.007409778889268637,
      0.04014156758785248,
      -0.0009269849979318678,
      -0.05109214037656784,
      0.01763562671840191,
      0.054779354482889175,
      -0.09669645130634308,
      -0.018911711871623993,
      0.057040221989154816,
      -0.023693792521953583,
      0.003088987898081541,
      -0.02960817515850067,
      -0.050178028643131256,
      -0.004660503473132849,
      -0.016396719962358475,
      -0.005137234926223755,
      0.023315247148275375,
      0.02139803022146225,
      0.0478326715528965,
      0.03693821653723717,
      -0.016265029087662697,
      0.00476339366286993,
      0.02921265736222267,
      -0.027542786672711372,
      -0.11377011984586716,
      -0.01299549825489521,
      -0.0980745404958725,
      -0.06518423557281494,
      0.04598467797040939,
      0.0038152600172907114,
      -0.013849498704075813,
      0.005466034170240164,
      0.007947536185383797,
      0.03767009824514389,
      0.021650943905115128,
      0.05203363671898842,
      -0.037054430693387985,
      -0.033001337200403214,
      0.017255686223506927,
      -4.86896897200495e-05,
      -0.04220127686858177,
      -0.033580031245946884,
      0.0743979811668396,
      0.06536351889371872,
      -0.1169879212975502,
      0.02276204526424408,
      0.04141196981072426,
      -0.014161059632897377,
      -0.06115332618355751,
      0.003504253923892975,
      -0.03393547236919403,
      0.03064039722084999,
      0.02847531996667385,
      -0.08387230336666107,
      0.02654137834906578,
      0.024228952825069427,
      0.03796081990003586,
      0.039526622742414474,
      -0.010594882071018219,
      0.012651624158024788,
      -0.05166657641530037,
      -0.024266885593533516,
      -0.015808338299393654,
      -0.0398937463760376,
      0.03671837970614433,
      0.09377337992191315,
      0.005564304068684578,
      -0.013916322961449623,
      -0.006492147222161293,
      0.027553535997867584,
      -0.02093164063990116,
      -0.03351689502596855,
      0.015352293848991394,
      0.03103902004659176,
      0.02423122711479664,
      0.02422173134982586,
      0.010037850588560104,
      0.09985318779945374,
      -0.02347211353480816,
      0.017394831404089928,
      0.005617455579340458,
      -0.02568741701543331,
      0.07098797708749771,
      0.044650305062532425,
      0.0917804092168808,
      -0.05679864063858986,
      0.036948833614587784,
      -0.030650630593299866,
      0.04677516594529152,
      -0.019122378900647163,
      -0.03462456539273262,
      0.06566639244556427,
      0.013932217843830585,
      0.03481348231434822,
      -0.059840213507413864,
      0.08778952062129974,
      0.05175016447901726,
      -0.0169454924762249,
      0.05414273217320442,
      -0.05442989617586136,
      0.014384107664227486,
      -0.0013785744085907936,
      -0.0138241620734334,
      -0.03599413111805916,
      -0.016445092856884003,
      -0.04173114523291588,
      -0.08970103412866592,
      0.04980556666851044,
      -0.055754873901605606,
      0.07852764427661896,
      0.08635113388299942,
      0.03426208719611168,
      0.0052066645585000515,
      -0.04314878210425377,
      0.01461806334555149,
      0.08823420852422714,
      0.010605722665786743,
      -0.015661444514989853,
      -0.06290626525878906,
      -0.04272045940160751,
      0.09720945358276367,
      0.030521074309945107,
      0.03716040030121803,
      -0.028580186888575554,
      0.08111146837472916,
      0.009938284754753113,
      -0.08397384732961655,
      -0.08741816133260727,
      -0.0689237043261528,
      0.0644448921084404,
      -0.03844107687473297,
      0.03523468226194382,
      -0.03239647299051285,
      -0.05696646869182587,
      0.07659569382667542,
      -0.06017012894153595,
      -0.0012146218214184046,
      0.029200449585914612,
      0.04505150392651558,
      0.011882464401423931,
      0.010346970520913601,
      -0.011620023287832737,
      -0.10507549345493317,
      0.049796078354120255,
      -0.027592578902840614,
      0.03984170779585838,
      -0.031894732266664505,
      0.022124145179986954,
      -0.023928845301270485,
      -0.06988687813282013,
      0.011936853639781475,
      -0.12439760565757751,
      0.0032643896993249655,
      0.0882667824625969,
      0.01731722615659237,
      -0.047813281416893005,
      0.054982442408800125,
      -0.01913750357925892,
      0.02151142805814743,
      -0.04458223283290863,
      -0.003796101314947009,
      -0.034791432321071625,
      0.01220792531967163,
      -0.016533056274056435,
      -0.004376708064228296,
      0.018861960619688034,
      0.0017704847268760204,
      -0.005396207794547081,
      -0.042448557913303375,
      -0.010914687067270279,
      -0.05174308642745018,
      0.009631067514419556,
      -0.03260590508580208,
      0.051915884017944336,
      -0.07827796041965485,
      0.005074992775917053,
      -0.08887511491775513,
      -0.06725382059812546,
      -0.07767153531312943,
      0.09621146321296692,
      0.018385643139481544,
      0.030265294015407562,
      0.029754895716905594,
      -0.0636974424123764,
      0.0084544587880373,
      0.04908474534749985,
      0.013129331171512604,
      0.057714059948921204,
      -0.04615551233291626,
      -0.021923834457993507,
      0.015462611801922321,
      -0.025382665917277336,
      -0.04095178470015526,
      0.03463660553097725,
      -0.033323585987091064,
      0.004460510332137346,
      -0.053639087826013565,
      0.02921784110367298,
      -0.015862511470913887,
      -0.07280570268630981,
      -0.042184047400951385,
      0.0023973213974386454,
      0.031939562410116196,
      -0.015680303797125816,
      0.017032815143465996,
      -0.1067497581243515,
      0.03527342155575752,
      -0.029194682836532593,
      0.0002731742861215025,
      0.0308909323066473,
      0.04309326037764549,
      -0.05742810294032097,
      0.03322216495871544,
      -0.012700902298092842,
      -0.07793585956096649,
      -0.03753805160522461,
      0.03209981322288513,
      0.009649707935750484,
      0.022279102355241776,
      0.02411353960633278,
      -0.0768406093120575,
      0.017319047823548317,
      0.011195768602192402,
      -0.05842004716396332,
      -0.013893294148147106,
      -0.038849830627441406,
      0.006518613081425428,
      0.046247340738773346,
      -0.03423890471458435,
      0.03269369900226593,
      0.005810427479445934,
      0.0063737500458955765,
      0.0037941166665405035,
      0.0002120167773682624,
      -0.00670281145721674,
      0.006038100924342871,
      0.04694810137152672,
      0.01921832375228405,
      0.04792330041527748,
      0.04327614977955818,
      -0.05072341859340668,
      -0.027164917439222336,
      0.042517632246017456,
      0.1000884547829628,
      0.012665617279708385,
      -0.027221107855439186,
      0.09760891646146774,
      -0.011061125434935093,
      -0.032266128808259964,
      0.05426163598895073,
      -0.02297215908765793,
      -0.0741804838180542,
      0.07360575348138809,
      -0.011623717844486237,
      0.06252477318048477,
      0.04002558812499046,
      0.01269030012190342,
      -0.013361838646233082,
      -0.0027102564927190542,
      0.03684830293059349,
      0.01843731664121151,
      -0.03328217193484306,
      0.06874165683984756,
      -0.0825468972325325,
      -0.04993727430701256,
      0.03442860767245293,
      -0.03422907367348671,
      0.08339326828718185,
      0.0033610837999731302,
      -0.04267716407775879,
      -0.03555767983198166,
      -0.008394382894039154,
      -0.0293293260037899,
      -0.022154830396175385,
      0.0073275016620755196,
      -0.08159392327070236,
      -0.02930542267858982,
      -0.003386935917660594,
      -0.01291234977543354,
      -0.028386296704411507,
      -0.02274741604924202,
      0.008788366802036762,
      0.029701320454478264,
      0.009587896056473255,
      0.06373251974582672,
      0.029096538200974464,
      -0.040237292647361755,
      -0.009649648331105709,
      0.11125346273183823,
      -0.05575823038816452,
      0.008382794447243214,
      0.003210350638255477,
      0.03640474006533623,
      -0.06509997695684433,
      0.10003341734409332,
      0.04450763016939163,
      -0.023496968671679497,
      0.04809160903096199,
      -0.016546102240681648,
      -0.010682246647775173,
      -0.014759616926312447,
      0.04107828438282013,
      -0.028310002759099007,
      -0.045632459223270416,
      0.024144213646650314,
      -0.022370314225554466,
      0.062431007623672485,
      0.003201691433787346,
      0.03896435350179672,
      -0.03791063651442528,
      -0.03870371729135513,
      -0.0382690466940403,
      0.023061104118824005,
      -0.02588989958167076,
      0.06839026510715485,
      -0.06912226229906082,
      -0.06001827120780945,
      -0.025654466822743416,
      0.09476423263549805,
      -0.050770338624715805,
      0.021493440493941307,
      -0.02998320385813713,
      0.049679625779390335,
      -0.014227218925952911,
      -0.08687274158000946,
      0.033992595970630646,
      -0.05883912742137909,
      0.00943720806390047,
      0.04256351664662361,
      -0.07234346121549606,
      -0.06902147829532623,
      -0.013787820003926754,
      -0.006408317480236292,
      0.043127190321683884,
      -0.00045145940384827554,
      -0.04825475439429283,
      0.06789546459913254,
      -0.004215389955788851,
      -0.022861935198307037,
      0.041313327848911285,
      0.02535906806588173,
      0.027891607955098152,
      0.046846047043800354,
      0.0029235444962978363,
      0.03701592981815338,
      -0.03807039558887482,
      0.004415543749928474,
      -0.017545662820339203,
      -0.09447558224201202,
      -0.07299214601516724,
      -0.042567361146211624,
      0.02877100370824337,
      0.07318191975355148,
      -0.015058748424053192,
      -0.0037236374337226152,
      -0.009286835789680481,
      0.04228588566184044,
      0.02286822348833084,
      -0.0652044266462326,
      0.1757390797138214,
      0.05572694540023804,
      -0.06672431528568268,
      -0.012636624276638031,
      -0.09678925573825836,
      -0.014148621819913387,
      0.03958394378423691,
      -0.0003128970565740019,
      -0.07048289477825165,
      -0.09942338615655899,
      -0.042289476841688156,
      0.08500895649194717,
      0.023541495203971863,
      -0.013608596287667751,
      0.05450281500816345,
      0.030613241717219353,
      0.033998485654592514,
      -0.011110163293778896,
      -0.016956014558672905,
      -0.024449707940220833,
      0.017293142154812813,
      -0.08829117566347122,
      -0.0010806394275277853,
      0.04970124736428261,
      0.06397882848978043,
      0.039878375828266144,
      -0.141257643699646,
      0.039964042603969574,
      -0.010769755579531193,
      0.0043345727026462555,
      -0.05707862228155136,
      0.021435942500829697,
      -0.012938842177391052,
      0.03146703913807869,
      0.06353691965341568,
      -0.010205555707216263,
      0.008958094753324986,
      -0.06296280771493912,
      -0.0553925558924675,
      0.013304926455020905,
      0.012472494505345821,
      -0.04211197793483734,
      -0.014521820470690727,
      -0.005914656911045313,
      -0.009097143076360226,
      0.0007130038575269282,
      0.08267708867788315,
      -0.008553599938750267,
      0.0179507527500391,
      -0.0033610323444008827,
      -0.013235125690698624,
      0.13744370639324188,
      0.020974820479750633,
      -0.016571596264839172,
      -0.03744741529226303,
      0.0032894222531467676,
      0.025022992864251137,
      0.0027534456457942724,
      0.022104930132627487
    ],
    [
      -0.0029682342428714037,
      -0.016684677451848984,
      -0.007950046099722385,
      0.008755271323025227,
      0.016112156212329865,
      -0.05927754193544388,
      0.0733097493648529,
      0.04485108330845833,
      -0.015627801418304443,
      -0.07367406040430069,
      -0.002703507198020816,
      0.10740452259778976,
      0.040785785764455795,
      -0.008445775136351585,
      -0.002030761446803808,
      0.014906797558069229,
      0.004354805685579777,
      0.003125928808003664,
      0.06101655587553978,
      0.04732050001621246,
      0.09646107256412506,
      0.029440300539135933,
      0.032162073999643326,
      0.037591587752103806,
      0.04007093235850334,
      -7.341561286011711e-05,
      -0.1016702726483345,
      -0.024027464911341667,
      0.013385247439146042,
      0.04812661185860634,
      -0.06832416355609894,
      -0.07516138255596161,
      0.0036223705392330885,
      -0.03790059685707092,
      -0.031565722078084946,
      -0.07823656499385834,
      -0.07716593891382217,
      0.09936663508415222,
      -0.013634176924824715,
      -0.0009565558866597712,
      -0.04065103083848953,
      0.060576021671295166,
      -0.013158655725419521,
      -0.03603271767497063,
      0.05520685017108917,
      -0.017574790865182877,
      0.07601512223482132,
      -0.03543580695986748,
      0.03033553808927536,
      -0.0024621719494462013,
      0.016908908262848854,
      -0.032369740307331085,
      0.07344359159469604,
      -0.01654108054935932,
      0.06488348543643951,
      0.08478917181491852,
      0.0017866389825940132,
      0.04602007567882538,
      0.060920730233192444,
      -0.025666583329439163,
      -0.006021179724484682,
      -0.019288549199700356,
      -0.0635078102350235,
      -0.05607886239886284,
      -0.041952744126319885,
      -0.05734878033399582,
      -0.019937613978981972,
      -0.052001748234033585,
      0.06283712387084961,
      0.02377226948738098,
      -0.029142605140805244,
      0.0420551560819149,
      -0.07642319053411484,
      -0.010722743347287178,
      0.03651898354291916,
      -0.027850588783621788,
      0.02259303629398346,
      -0.04766185209155083,
      0.0178948063403368,
      0.05811477452516556,
      0.009673924185335636,
      0.003618857590481639,
      0.07182388007640839,
      0.004179045557975769,
      -0.0009385411394760013,
      0.0657491385936737,
      0.0010830286191776395,
      0.1154172420501709,
      -0.11500761657953262,
      0.012973853386938572,
      0.07968241721391678,
      0.045677270740270615,
      0.018347829580307007,
      0.03902369737625122,
      -0.03289489820599556,
      -0.01838022656738758,
      -0.011765507981181145,
      -0.04298645257949829,
      -0.003940165042877197,
      0.021375909447669983,
      0.027912035584449768,
      -0.10553718358278275,
      -0.08166177570819855,
      -0.02539546974003315,
      0.0013718646951019764,
      0.09503670781850815,
      -0.016452636569738388,
      0.08294854313135147,
      0.02555783838033676,
      0.014357428066432476,
      -0.06506922841072083,
      -0.026018522679805756,
      -0.0335325226187706,
      0.020887402817606926,
      0.016299529001116753,
      0.07572507113218307,
      0.04260658845305443,
      0.026877939701080322,
      0.020329291000962257,
      0.003773963078856468,
      -0.04338588938117027,
      -0.01740122400224209,
      0.006421378348022699,
      0.018874311819672585,
      -0.02526572346687317,
      -0.08590885996818542,
      -0.0013161459937691689,
      -0.005654390901327133,
      0.02240622229874134,
      0.02424316667020321,
      -0.016278428956866264,
      0.03929628059267998,
      0.05490853637456894,
      -0.001585563994012773,
      -0.021230068057775497,
      -0.027871638536453247,
      -0.027865352109074593,
      0.06393085420131683,
      -0.02417927049100399,
      0.03160228207707405,
      0.037217877805233,
      -0.04405603185296059,
      -0.008379627019166946,
      0.06720972061157227,
      0.039819713681936264,
      -0.007269099820405245,
      -0.010371066629886627,
      0.052527036517858505,
      -0.01015606801956892,
      -0.016649585217237473,
      0.04363933578133583,
      0.0574432834982872,
      0.004299880936741829,
      0.06788217276334763,
      0.03069896250963211,
      0.09561441838741302,
      0.06464403122663498,
      -0.05806620791554451,
      0.04896290972828865,
      -0.042148180305957794,
      -0.03774326294660568,
      -0.027724720537662506,
      0.021707527339458466,
      -0.013300521299242973,
      0.003589850617572665,
      -0.014622441492974758,
      0.0015010747592896223,
      0.046147335320711136,
      0.02760869823396206,
      0.04036184027791023,
      -0.06377489864826202,
      -0.02320351079106331,
      0.013972464948892593,
      -0.0359485000371933,
      -0.046099767088890076,
      0.022279202938079834,
      0.003803232219070196,
      0.0564081184566021,
      0.004556008614599705,
      -0.04226270690560341,
      0.07855836302042007,
      0.08705037087202072,
      -0.009203673340380192,
      0.05437491461634636,
      -0.0030533166136592627,
      -0.07332749664783478,
      -0.0715470165014267,
      0.026537062600255013,
      -0.0348309688270092,
      -0.011451181955635548,
      0.021385231986641884,
      -0.012832632288336754,
      -0.0084400475025177,
      -0.059283580631017685,
      -0.002831618534401059,
      -0.008838044479489326,
      -0.02763938158750534,
      0.019217168912291527,
      0.015381431207060814,
      0.03035573475062847,
      -0.04955270141363144,
      0.03142683207988739,
      -0.04149630665779114,
      0.044359441846609116,
      -0.08391590416431427,
      -0.1220242977142334,
      -0.09433560818433762,
      0.03370414301753044,
      0.029264699667692184,
      0.015132090076804161,
      -0.030116446316242218,
      -0.10031891614198685,
      -0.012604952789843082,
      -0.04143878445029259,
      -0.06206723302602768,
      -0.022969776764512062,
      0.012567857280373573,
      0.003216815646737814,
      0.11733828485012054,
      0.03288160264492035,
      -0.03210059925913811,
      0.01395969558507204,
      0.06277068704366684,
      0.046824861317873,
      0.04098501801490784,
      0.05167054012417793,
      0.01796247810125351,
      0.04602397605776787,
      -0.05371671915054321,
      0.030588427558541298,
      0.0008430070010945201,
      -0.005097830668091774,
      -0.01680774986743927,
      -0.02758745849132538,
      0.029907982796430588,
      -0.031678058207035065,
      0.000581267464440316,
      -0.006639075465500355,
      0.020686199888586998,
      -0.0125885596498847,
      -0.04909463971853256,
      -0.01599215343594551,
      -0.024842115119099617,
      -0.03761925920844078,
      -0.05396762117743492,
      -0.052943017333745956,
      -0.07550398260354996,
      -0.0586317777633667,
      -0.004226479679346085,
      0.0539775975048542,
      -0.12472188472747803,
      -0.15708167850971222,
      -0.027593860402703285,
      0.0019036189187318087,
      -0.06112367659807205,
      -0.07603347301483154,
      -0.02354584075510502,
      0.030283123254776,
      0.0027611837722361088,
      0.011420739814639091,
      -0.04785057529807091,
      -0.06670156121253967,
      0.07124082744121552,
      0.00183381256647408,
      0.009312201291322708,
      0.022623959928750992,
      0.015088065527379513,
      -0.04638311639428139,
      -0.037898752838373184,
      0.045995280146598816,
      0.04339246451854706,
      0.052544005215168,
      0.009849494323134422,
      -0.04893869534134865,
      -0.01200838666409254,
      -0.020244956016540527,
      0.039171427488327026,
      0.038367144763469696,
      0.0893658697605133,
      -0.04560905322432518,
      0.01960468292236328,
      0.0725519210100174,
      0.0284561850130558,
      -0.02442631497979164,
      -0.03740004450082779,
      0.05092751607298851,
      -0.003995263949036598,
      0.061748355627059937,
      -0.08048085868358612,
      -0.03962396830320358,
      -0.059139184653759,
      -0.025218907743692398,
      -0.04976080730557442,
      0.08206132799386978,
      0.09113673865795135,
      0.02394636534154415,
      -0.0364353284239769,
      -0.03971531242132187,
      0.007358586881309748,
      0.027233408764004707,
      0.052699975669384,
      -0.05910542979836464,
      0.024837883189320564,
      -0.0008767821709625423,
      0.035640422254800797,
      0.014734311029314995,
      0.05077643319964409,
      0.03806157037615776,
      -0.00402368837967515,
      -0.0487554706633091,
      -0.026038886979222298,
      0.11243031173944473,
      0.05530749633908272,
      -0.011785641312599182,
      -0.09929302334785461,
      -0.061647795140743256,
      -0.025119008496403694,
      -0.058277446776628494,
      -0.011348793283104897,
      -0.04376944527029991,
      0.06545986235141754,
      0.001885557547211647,
      -0.02760738506913185,
      -0.0059630679897964,
      0.025798384100198746,
      -0.0482478030025959,
      0.029558978974819183,
      0.010003756731748581,
      -0.024209922179579735,
      -0.0797562524676323,
      -0.04325920715928078,
      0.05205449089407921,
      0.03184303641319275,
      0.014327644370496273,
      0.0030495396349579096,
      0.013967746868729591,
      0.03781098499894142,
      -0.007217323407530785,
      -0.0024395855143666267,
      -0.10141246020793915,
      -0.09754566848278046,
      0.043325990438461304,
      0.07021070271730423,
      -0.03738584369421005,
      -0.08723781257867813,
      0.04230605810880661,
      0.04116931930184364,
      0.01680714264512062,
      0.04169575124979019,
      0.011143053881824017,
      0.0708957090973854,
      -0.046019311994314194,
      -0.06427504122257233,
      0.04253461956977844,
      0.02212117612361908,
      0.0032294427510350943,
      -0.02710915170609951,
      -0.03553227707743645,
      0.020341118797659874,
      0.04279216751456261,
      0.02827199548482895,
      -0.012563162483274937,
      0.05395553633570671,
      0.0394618846476078,
      0.04772757366299629,
      0.022461943328380585,
      0.027131985872983932,
      0.05445626378059387,
      -0.010343009606003761,
      -0.004971057176589966,
      0.05836241692304611,
      -0.08667327463626862,
      0.0419049896299839,
      -0.028883371502161026,
      0.06858070194721222,
      -0.0017956902738660574,
      -0.012853965163230896,
      0.13284938037395477,
      0.002950600814074278,
      -0.016392914578318596,
      0.021506747230887413,
      -0.007803250104188919,
      0.04138077422976494,
      0.0048332419246435165,
      0.036469392478466034,
      -0.03086516074836254,
      -0.061167746782302856,
      0.04949742555618286,
      0.08062687516212463,
      0.046973034739494324,
      0.006033213809132576,
      -0.06968463957309723,
      0.029467497020959854,
      0.0021173227578401566,
      0.027134792879223824,
      -0.043446578085422516,
      -0.041215524077415466,
      0.01671145297586918,
      0.024487731978297234,
      -0.06742556393146515,
      0.06334792822599411,
      -0.05263596773147583,
      0.06806950271129608,
      -0.025245700031518936,
      -0.05921263247728348,
      0.02716839127242565,
      -0.012036534026265144,
      0.04212980344891548,
      0.006905688904225826,
      -0.006538683082908392,
      -0.1467200517654419,
      0.1295645534992218,
      -0.00503620645031333,
      -0.06426845490932465,
      -0.02224120683968067,
      0.06708632409572601,
      -0.048571959137916565,
      0.002939654979854822,
      0.04388085752725601,
      0.042684391140937805,
      0.08255242556333542,
      0.0961034819483757,
      0.04136704280972481,
      -0.031070131808519363,
      0.012450156733393669,
      -0.01790512353181839,
      -0.0066063725389540195,
      0.042526595294475555,
      -0.05582405626773834,
      0.030476585030555725,
      -0.03865472599864006,
      -0.012655523605644703,
      -0.06616443395614624,
      0.04379718005657196,
      -0.01925158128142357,
      -0.027619188651442528,
      -0.012292787432670593,
      0.030838128179311752,
      0.04223991930484772,
      -0.008431541733443737,
      -0.021755758672952652,
      0.04732109606266022,
      -0.051492493599653244,
      -0.0854751244187355,
      0.04949185997247696,
      -0.004486768040806055,
      0.00794996041804552,
      0.04812413826584816,
      -0.02878277190029621,
      -0.040166765451431274,
      -0.008661551401019096,
      0.03156200051307678,
      0.004593598656356335,
      0.03357084468007088,
      0.03441831097006798,
      0.014389067888259888,
      0.005700450856238604,
      -0.04881815239787102,
      0.02853861078619957,
      0.03099321573972702,
      0.07951324433088303,
      -0.09812510013580322,
      -0.05879626423120499,
      -0.045047640800476074,
      0.019914768636226654,
      0.021234173327684402,
      -0.05301443114876747,
      0.07544107735157013,
      0.08747439086437225,
      -0.00792469922453165,
      -0.051666099578142166,
      0.007221414241939783,
      -0.05344098433852196,
      -0.028864510357379913,
      -0.06513471156358719,
      0.08467686921358109,
      -0.1070975661277771,
      -0.006057063117623329,
      0.030734090134501457,
      -0.054970186203718185,
      0.014148896560072899,
      -0.050159115344285965,
      0.09436777234077454,
      -0.004160400480031967,
      -0.02462635189294815,
      -0.053014688193798065,
      -0.010961942374706268,
      -0.008274126797914505,
      -0.0010318448767066002,
      0.036502256989479065,
      -0.01127533707767725,
      -0.10145192593336105,
      0.030933866277337074,
      0.015210351906716824,
      0.03110346756875515,
      0.03482712432742119,
      -0.045021481812000275,
      -8.202309982152656e-05,
      -0.02587117999792099,
      0.06557609140872955,
      -0.13320228457450867,
      0.03789440542459488,
      -0.02335868962109089
    ],
    [
      -0.03324439749121666,
      -0.046953894197940826,
      -0.03109629638493061,
      0.12488775700330734,
      -0.09332221746444702,
      -0.005579929333180189,
      0.020104244351387024,
      0.004019398707896471,
      0.07088381052017212,
      -0.03002421371638775,
      0.014722252264618874,
      0.010928143747150898,
      0.055214736610651016,
      -0.020012276247143745,
      0.06125161796808243,
      0.040395766496658325,
      -0.022128025069832802,
      0.07082423567771912,
      0.04417390748858452,
      0.008668294176459312,
      0.02284056693315506,
      0.043469708412885666,
      -0.021487116813659668,
      -0.07571934908628464,
      0.05228676274418831,
      0.04033830389380455,
      0.022040581330657005,
      0.04348532855510712,
      0.05419371277093887,
      0.008141000755131245,
      -0.03293626755475998,
      0.040692199021577835,
      0.03358445689082146,
      -0.03633470833301544,
      -0.010207312181591988,
      0.06659234315156937,
      0.024290157482028008,
      0.022437244653701782,
      0.03473309427499771,
      0.039961621165275574,
      -0.01990891993045807,
      -0.005369015038013458,
      0.06134609133005142,
      0.006062678527086973,
      0.03846766799688339,
      -0.045725222676992416,
      0.06356675922870636,
      0.017159638926386833,
      0.04559428244829178,
      0.08002161979675293,
      0.09616051614284515,
      -0.021760104224085808,
      0.020378388464450836,
      0.08710981905460358,
      0.030196871608495712,
      -0.03620797395706177,
      0.00828714668750763,
      0.022448603063821793,
      0.08844145387411118,
      -0.015223530121147633,
      -0.05643782019615173,
      -0.07184810191392899,
      0.02412457764148712,
      -0.006316716782748699,
      0.04646627977490425,
      -0.027850376442074776,
      0.02445306070148945,
      0.041416894644498825,
      -0.005072088446468115,
      0.007446798961609602,
      0.05460241809487343,
      0.039264462888240814,
      -0.025248341262340546,
      -0.01485784724354744,
      0.020249994471669197,
      -0.039807144552469254,
      0.009026863612234592,
      -0.050527941435575485,
      0.02954760007560253,
      0.012746129184961319,
      -0.060281459242105484,
      -0.013137312605977058,
      0.002337171696126461,
      0.009543582797050476,
      0.037217773497104645,
      0.001059782225638628,
      0.012380317784845829,
      0.0918709859251976,
      -0.021810855716466904,
      -0.05373217910528183,
      -0.022948935627937317,
      0.020457787439227104,
      -0.058376844972372055,
      0.030411379411816597,
      -0.02336655929684639,
      -0.06917494535446167,
      -0.029117627069354057,
      0.041039302945137024,
      0.054603882133960724,
      -0.04630183428525925,
      0.002720633987337351,
      0.026779651641845703,
      -0.051041729748249054,
      0.04881866276264191,
      0.014151297509670258,
      -0.055235669016838074,
      0.025804797187447548,
      0.0695086270570755,
      0.06209450960159302,
      -0.06407017260789871,
      0.0056232307106256485,
      0.021492203697562218,
      0.001539509859867394,
      -0.007171432953327894,
      0.03345372527837753,
      -0.008992195129394531,
      0.04814694821834564,
      0.0748622789978981,
      -0.01250348798930645,
      0.038744110614061356,
      0.05908504128456116,
      -0.049534279853105545,
      -0.04638734459877014,
      -0.0678030252456665,
      -0.005325149279087782,
      0.0033997793216258287,
      -0.08228450268507004,
      0.09426944702863693,
      -0.04617365077137947,
      -0.05148801952600479,
      -0.023076456040143967,
      -0.03315258026123047,
      0.01781388372182846,
      0.01380171999335289,
      0.08660593628883362,
      0.04387183114886284,
      -0.04452841356396675,
      0.08133058995008469,
      -0.05592929199337959,
      -0.01603563502430916,
      -0.03427038714289665,
      -0.01505762618035078,
      -0.007039211690425873,
      0.0018110807286575437,
      0.023015471175312996,
      -0.04148326441645622,
      -0.04265129938721657,
      0.021430600434541702,
      0.03679751604795456,
      -0.028198299929499626,
      -0.04760169982910156,
      -0.011602379381656647,
      0.00649205083027482,
      -0.05479024350643158,
      -0.02431909553706646,
      0.055967871099710464,
      -0.04454592615365982,
      -0.020250612869858742,
      0.003745332593098283,
      0.028343679383397102,
      -0.06766249239444733,
      0.013012760318815708,
      0.0043514929711818695,
      0.030251335352659225,
      0.0384330153465271,
      0.000603488995693624,
      -0.024043593555688858,
      -0.007581824902445078,
      -0.04559791460633278,
      0.11774267256259918,
      -0.03228641301393509,
      -0.005808660760521889,
      -0.004020625725388527,
      -0.01424263697117567,
      0.01785893552005291,
      -0.061140865087509155,
      -0.06641706824302673,
      0.05870194733142853,
      0.004038931801915169,
      -0.022490449249744415,
      0.006407925859093666,
      0.0043418086133897305,
      0.027330292388796806,
      -0.007081925868988037,
      0.08618264645338058,
      0.06515228003263474,
      -0.0335555300116539,
      -0.005867420230060816,
      -0.04566975682973862,
      -0.0457853339612484,
      0.018444545567035675,
      -0.01718367449939251,
      0.061875540763139725,
      0.001962666166946292,
      0.05665981024503708,
      -0.009271467104554176,
      0.03736502677202225,
      -0.05083569884300232,
      0.0017538409447297454,
      -0.007242407649755478,
      -0.018676647916436195,
      -0.05326201394200325,
      -0.019570806995034218,
      0.03982839360833168,
      -0.02952282503247261,
      -0.005419448018074036,
      -0.0744500458240509,
      0.01763058453798294,
      0.008822530508041382,
      -0.010738536715507507,
      -0.006254039239138365,
      -0.02598421834409237,
      -0.008285416290163994,
      0.10318443924188614,
      0.04835790395736694,
      0.014651278033852577,
      -0.006161476019769907,
      0.05720844119787216,
      0.026555540040135384,
      0.07474014163017273,
      0.028047945350408554,
      -0.10135528445243835,
      0.07326770573854446,
      0.02943439409136772,
      -0.0031745624728500843,
      0.0053956229239702225,
      0.05795789510011673,
      -0.06205908581614494,
      -0.00909800548106432,
      0.0646195337176323,
      0.04508025571703911,
      -0.02156033180654049,
      0.033754054456949234,
      -0.02628190629184246,
      -0.05217815190553665,
      -0.08330051600933075,
      0.008191467262804508,
      0.005168295931071043,
      0.031732697039842606,
      -0.10125675052404404,
      0.004347002599388361,
      -0.07983683794736862,
      0.06904596835374832,
      0.04520421475172043,
      0.011902276426553726,
      -0.018847042694687843,
      0.022756563499569893,
      0.02827695570886135,
      -0.03879563882946968,
      0.06194895878434181,
      0.0706019252538681,
      -0.075233094394207,
      -0.10356258600950241,
      0.0068166982382535934,
      -0.023092245683073997,
      -0.023111451417207718,
      0.029392356052994728,
      -0.04233495518565178,
      0.0620972141623497,
      0.05431118234992027,
      0.037805646657943726,
      0.032853592187166214,
      0.06220562383532524,
      0.023704687133431435,
      -0.012491747736930847,
      -0.07304727286100388,
      0.052185386419296265,
      -0.017918424680829048,
      -0.06454052031040192,
      0.0055547356605529785,
      -0.0055471728555858135,
      -0.05758668854832649,
      0.02109895832836628,
      -0.005559481214731932,
      -0.037061214447021484,
      0.0321224108338356,
      0.07826252281665802,
      0.01692618615925312,
      -0.010985364206135273,
      0.05733530595898628,
      -0.0553116500377655,
      -0.057997606694698334,
      -0.019599054008722305,
      -0.02671227790415287,
      0.04101657494902611,
      0.03550185635685921,
      -0.006679125130176544,
      -0.018938351422548294,
      0.05124980956315994,
      0.004945565480738878,
      -0.12252950668334961,
      -0.033464089035987854,
      0.033341359347105026,
      0.013479829765856266,
      -0.07989072054624557,
      0.01663915626704693,
      0.03951248899102211,
      0.0008553261868655682,
      -0.008175528608262539,
      -0.01280722301453352,
      -0.02430822141468525,
      0.019365962594747543,
      -0.013693872839212418,
      0.04268212616443634,
      -0.026236552745103836,
      -0.03656729683279991,
      -0.03665690869092941,
      0.05100144073367119,
      0.04123843088746071,
      0.0057260459288954735,
      0.10523118078708649,
      -0.012510520406067371,
      0.03593794256448746,
      0.006735655479133129,
      -0.031297799199819565,
      0.03385414183139801,
      -0.009012546390295029,
      -0.08726382255554199,
      0.028338927775621414,
      0.03011910431087017,
      0.007407102733850479,
      -0.02583167888224125,
      0.07983829081058502,
      0.040411848574876785,
      -0.042940884828567505,
      -0.024145442992448807,
      -0.04048651084303856,
      0.0742756724357605,
      0.058331627398729324,
      -0.016113216057419777,
      0.023357803001999855,
      -0.048896126449108124,
      0.01652577333152294,
      -0.009944163262844086,
      -0.046987056732177734,
      0.0670287162065506,
      0.05932324379682541,
      -0.018579868599772453,
      0.0911150574684143,
      -0.026547402143478394,
      -0.03870818763971329,
      0.025920016691088676,
      -0.050958964973688126,
      0.016308816149830818,
      -0.011945722624659538,
      0.0030888961628079414,
      0.10256022959947586,
      0.013667440973222256,
      -0.010054437443614006,
      -0.04513854533433914,
      0.04061920940876007,
      0.021192766726017,
      -0.034433502703905106,
      -0.0022145924158394337,
      -0.035340312868356705,
      0.07944180816411972,
      -0.06521503627300262,
      0.06552264839410782,
      0.035077523440122604,
      0.014359429478645325,
      0.046889305114746094,
      -0.052563827484846115,
      -0.03775971010327339,
      -0.020582059398293495,
      -0.02641172707080841,
      0.036204539239406586,
      -0.009470546618103981,
      0.06187833100557327,
      0.07005389779806137,
      -0.07550276070833206,
      -0.05630973353981972,
      0.008840242400765419,
      0.0557541660964489,
      0.028389548882842064,
      -0.035021789371967316,
      -0.0031090581323951483,
      0.03841942921280861,
      0.02217743918299675,
      0.09291307628154755,
      0.007502088788896799,
      -0.05878673493862152,
      -0.04289621114730835,
      -0.003931546118110418,
      0.0028958092443645,
      -0.04518811032176018,
      -0.11236516386270523,
      -0.006882845424115658,
      -0.010476458817720413,
      -0.024506419897079468,
      -0.007488476112484932,
      -0.014241966418921947,
      -0.08269073814153671,
      0.01808147318661213,
      0.026209507137537003,
      0.06193091720342636,
      0.006586840376257896,
      -0.02399957925081253,
      -0.012551873922348022,
      0.06947106868028641,
      -0.021682051941752434,
      -0.026070626452565193,
      0.025837207213044167,
      -0.07450834661722183,
      -0.04577069729566574,
      -0.026189815253019333,
      2.0094086721655913e-05,
      -0.15565641224384308,
      0.06701678037643433,
      0.02188909612596035,
      -0.03861359506845474,
      -0.05735643580555916,
      0.08221787959337234,
      0.020152781158685684,
      -0.022901782765984535,
      -0.04339222609996796,
      0.07543754577636719,
      -0.09152195602655411,
      -0.032649293541908264,
      0.007921640761196613,
      -0.017209617421030998,
      0.01636584848165512,
      0.12142226099967957,
      0.038489919155836105,
      -0.01994277536869049,
      0.08587922155857086,
      0.03644934669137001,
      0.016523681581020355,
      0.008362876251339912,
      0.0235984418541193,
      -0.06106171756982803,
      -0.053844332695007324,
      -0.015574394725263119,
      0.04234779253602028,
      0.022278107702732086,
      -0.035039063543081284,
      0.013106085360050201,
      -0.06583340466022491,
      0.01566844992339611,
      0.02746446616947651,
      0.00399169884622097,
      -0.054280634969472885,
      -0.03812537342309952,
      -0.02870240993797779,
      -0.03054552525281906,
      0.04532856494188309,
      0.012148045934736729,
      -0.05191892758011818,
      0.06003963202238083,
      -0.02831452339887619,
      0.03841841220855713,
      -0.050021618604660034,
      0.06769870221614838,
      -0.048662107437849045,
      -0.008798808790743351,
      -0.010652254335582256,
      -0.038474392145872116,
      0.03152123838663101,
      0.07628423720598221,
      -0.0044456650502979755,
      -0.032904237508773804,
      -0.009381080977618694,
      0.01825270615518093,
      0.040282201021909714,
      0.006546445656567812,
      0.030937334522604942,
      0.02569507621228695,
      -0.011680043302476406,
      0.008151334710419178,
      0.07378001511096954,
      -0.04511680081486702,
      -0.015204289928078651,
      -0.014330768026411533,
      0.00014490153989754617,
      0.06545982509851456,
      0.10060659795999527,
      0.05025449022650719,
      -0.04790293425321579,
      0.048269517719745636,
      0.010439650155603886,
      -0.011873438954353333,
      0.039724260568618774,
      -0.004415350034832954,
      -0.008153287693858147,
      -0.05683362856507301,
      -0.07277974486351013,
      -0.024940481409430504,
      -0.02741553634405136,
      -0.019983071833848953,
      -0.021002234891057014,
      0.06740091741085052,
      -0.04485857114195824,
      -0.0037568071857094765,
      -0.05704440549015999,
      0.09160450100898743,
      -0.04594569280743599,
      0.03513697534799576,
      0.023414814844727516,
      0.04413219168782234,
      -0.03504479303956032,
      -0.07163742929697037,
      0.07779034972190857,
      -0.030922463163733482,
      -0.014546710066497326
    ],
    [
      -0.050088752061128616,
      0.04356410726904869,
      0.025400277227163315,
      0.029567329213023186,
      0.031047360971570015,
      -0.010009564459323883,
      -0.0030544183682650328,
      -0.014544080011546612,
      0.05772925168275833,
      -0.039773330092430115,
      0.023533647879958153,
      -0.0777638629078865,
      0.023290397599339485,
      -0.03018772229552269,
      -0.06100957840681076,
      -0.0007213276694528759,
      -0.02687619999051094,
      -0.00815541110932827,
      0.01097673736512661,
      -0.0012649053242057562,
      0.013101263903081417,
      0.009193919599056244,
      -0.012312470003962517,
      0.045964259654283524,
      -0.0054743350483477116,
      0.013224422000348568,
      -0.0026553315110504627,
      0.048406537622213364,
      0.039799489080905914,
      0.052167490124702454,
      0.0921032726764679,
      -0.03602059930562973,
      0.03369317948818207,
      0.012586970813572407,
      -0.022971918806433678,
      0.010394753888249397,
      0.03384925425052643,
      0.04213828593492508,
      0.03639408200979233,
      -0.0546039342880249,
      -0.05576355755329132,
      0.026094911620020866,
      -0.03557606413960457,
      0.08250901103019714,
      0.056685492396354675,
      -0.03752508759498596,
      0.008014248684048653,
      -0.07800649851560593,
      -0.021847890689969063,
      0.015981417149305344,
      0.10994088649749756,
      -0.014541389420628548,
      0.046830203384160995,
      0.05153233930468559,
      0.023374613374471664,
      0.06198590248823166,
      -0.04418927803635597,
      0.013558976352214813,
      0.03951864317059517,
      -0.022541692480444908,
      0.06754092127084732,
      0.007469115778803825,
      0.039517391473054886,
      0.017565563321113586,
      0.006402883678674698,
      0.07301092147827148,
      -0.020503191277384758,
      0.010449990630149841,
      0.0831504762172699,
      -0.05012095719575882,
      0.034023404121398926,
      -0.015690941363573074,
      -0.060391105711460114,
      -0.05257720127701759,
      0.008306396193802357,
      -0.034838270395994186,
      -0.008448300883173943,
      0.04963056370615959,
      0.053110066801309586,
      0.02958161197602749,
      -0.04600008577108383,
      -0.07476013153791428,
      -0.06385477632284164,
      -0.026330627501010895,
      0.05069563910365105,
      -0.015792790800333023,
      0.052839651703834534,
      -0.002290633274242282,
      0.020375831052660942,
      0.0094708651304245,
      0.05196591839194298,
      -0.11904093623161316,
      -0.0855514258146286,
      0.03659580647945404,
      -0.04938869923353195,
      -0.00476048281416297,
      -0.07913393527269363,
      -0.030137687921524048,
      -0.008749277330935001,
      -0.03394376114010811,
      0.03466189280152321,
      -0.09405408054590225,
      0.0015395928639918566,
      0.03488347679376602,
      0.08145149797201157,
      0.022148748859763145,
      0.011423802934587002,
      0.018848316743969917,
      -0.04654610902070999,
      -0.11323559284210205,
      0.008473718538880348,
      -0.04086492583155632,
      -0.003234033240005374,
      0.017286939546465874,
      -0.004171384032815695,
      -0.09105972945690155,
      -0.04790379852056503,
      0.007057942450046539,
      -0.0015445654280483723,
      0.0915638655424118,
      0.044484999030828476,
      -0.07176560163497925,
      -0.05978773161768913,
      -0.011829999275505543,
      -0.014029259793460369,
      0.037546489387750626,
      -0.06805109232664108,
      0.030862858518958092,
      -0.047882162034511566,
      -0.011352289468050003,
      0.0466802604496479,
      -0.008499699644744396,
      0.036204032599925995,
      0.013062299229204655,
      -0.04073767364025116,
      -0.08983705192804337,
      -0.03127771243453026,
      -0.013840989209711552,
      -0.007972165942192078,
      -0.012898530811071396,
      0.03896196186542511,
      0.06010204926133156,
      -0.01523694396018982,
      0.043748337775468826,
      0.023568229749798775,
      -0.01204987894743681,
      -0.058004193007946014,
      -0.02391965314745903,
      0.03013177029788494,
      -0.08236324042081833,
      -0.058509957045316696,
      0.007449278607964516,
      0.014814937487244606,
      0.04205702245235443,
      0.01535168755799532,
      0.044537972658872604,
      -0.037696629762649536,
      0.006696297787129879,
      0.05440983921289444,
      -0.047854695469141006,
      0.022081926465034485,
      0.051682889461517334,
      -0.0167707409709692,
      0.00029369062394835055,
      0.027567965909838676,
      -0.05178217962384224,
      0.0929616168141365,
      -0.015341455116868019,
      -0.019968189299106598,
      0.024696452543139458,
      -0.04283987358212471,
      0.04416390135884285,
      0.01244221068918705,
      -0.05335275083780289,
      0.008236479014158249,
      -0.001994393765926361,
      0.011680491268634796,
      -0.06642932444810867,
      0.05506414175033569,
      -0.017542626708745956,
      0.06568878889083862,
      -0.030018657445907593,
      0.034808557480573654,
      -0.03809604048728943,
      -0.02898237109184265,
      0.015342877246439457,
      -0.026770008727908134,
      0.017512893304228783,
      0.03011948987841606,
      0.002968559507280588,
      -0.04939481243491173,
      -0.02341129072010517,
      -0.00036986262421123683,
      -0.027183815836906433,
      -0.03555919975042343,
      -0.011892685666680336,
      -0.015953924506902695,
      0.026017209514975548,
      -0.032645586878061295,
      -0.014710103161633015,
      0.026869362220168114,
      0.027073290199041367,
      0.03801571577787399,
      0.015102277509868145,
      -0.011497648432850838,
      -0.02021733671426773,
      -0.05950324237346649,
      -0.05091843008995056,
      -0.02907278761267662,
      -0.06785494089126587,
      0.0216967836022377,
      -0.0442146472632885,
      -0.06360749155282974,
      0.006280914880335331,
      -0.012489991262555122,
      -0.07466869056224823,
      -0.015867603942751884,
      -0.02041926607489586,
      -0.01882280223071575,
      0.00836727861315012,
      0.002922874176874757,
      -0.046332214027643204,
      0.10878155380487442,
      0.017927970737218857,
      0.07475786656141281,
      -0.0325336828827858,
      0.03649047762155533,
      -0.14308565855026245,
      -0.007632039953023195,
      0.025226199999451637,
      0.04925737529993057,
      0.028768112882971764,
      0.01571337692439556,
      0.05715956911444664,
      -0.04518783465027809,
      0.03920500725507736,
      -0.02513241395354271,
      0.03264697641134262,
      -0.018003828823566437,
      0.019033795222640038,
      -0.03278638422489166,
      0.0043860310688614845,
      0.0015646578976884484,
      0.016644921153783798,
      -0.06752517819404602,
      -0.0721118301153183,
      -0.0006775989895686507,
      -0.1463952213525772,
      0.054418861865997314,
      0.016132067888975143,
      -0.045405857264995575,
      -0.054016903042793274,
      -0.03660951182246208,
      -0.05345987156033516,
      -0.12445956468582153,
      -0.009171359241008759,
      0.09040986001491547,
      -0.023983126506209373,
      0.02319192700088024,
      0.041506506502628326,
      0.05817527323961258,
      -0.031429797410964966,
      0.03275703638792038,
      -0.015515058301389217,
      0.010227193124592304,
      0.043511953204870224,
      -0.06417149305343628,
      0.030793461948633194,
      0.09513604640960693,
      0.020887531340122223,
      0.12952227890491486,
      0.12997686862945557,
      -0.0442625917494297,
      0.021286122500896454,
      0.045904740691185,
      0.026947373524308205,
      -0.06806755065917969,
      -0.037548232823610306,
      0.060036901384592056,
      0.02401035837829113,
      -0.03349137306213379,
      0.015780748799443245,
      -0.0799999013543129,
      0.01936245523393154,
      0.01484112348407507,
      -0.02755136787891388,
      -0.0013251368654891849,
      0.018684865906834602,
      -0.02233932539820671,
      0.13831251859664917,
      -0.046721648424863815,
      -0.026280462741851807,
      0.012055744417011738,
      0.0009445304749533534,
      0.012181208468973637,
      0.058187250047922134,
      -0.045160580426454544,
      -7.807624569977634e-06,
      0.016772856935858727,
      0.013931786641478539,
      -0.07907707244157791,
      0.019281454384326935,
      0.010375441052019596,
      0.10057520121335983,
      -0.012273134663701057,
      0.018128931522369385,
      0.04421946033835411,
      -0.036436527967453,
      0.016861066222190857,
      -0.032986775040626526,
      -0.005074820946902037,
      -0.051651544868946075,
      -0.0030863694846630096,
      -0.02523133158683777,
      0.07343187183141708,
      -0.04092790558934212,
      0.09430316835641861,
      0.03493783622980118,
      -0.0071927704848349094,
      0.0024519478902220726,
      0.020862460136413574,
      -0.07693903893232346,
      0.007412420120090246,
      0.0535489022731781,
      0.030933015048503876,
      -0.022074731066823006,
      0.055355992168188095,
      -0.01947803981602192,
      -0.01949019730091095,
      0.03280244767665863,
      0.028236661106348038,
      -0.035989634692668915,
      -0.04731987044215202,
      -0.00222213682718575,
      -0.0014910359168425202,
      -0.02535036951303482,
      0.01820262335240841,
      0.009352115914225578,
      -0.0258351881057024,
      -0.009701447561383247,
      -0.030018042773008347,
      0.020890532061457634,
      -0.019455304369330406,
      -0.04592617601156235,
      0.048000168055295944,
      -0.07851468026638031,
      0.0006338345701806247,
      0.0028924760408699512,
      -0.042563434690237045,
      0.00686255656182766,
      0.06579490751028061,
      0.015028174966573715,
      0.003915220033377409,
      -0.023223156109452248,
      -0.06597404927015305,
      -0.002725123893469572,
      -0.0018529954832047224,
      -0.061294976621866226,
      -0.014619731344282627,
      0.04445366933941841,
      -0.005256462376564741,
      0.0444190688431263,
      -0.01596950925886631,
      -0.05943014845252037,
      0.02551247738301754,
      0.02286279760301113,
      0.0194330345839262,
      0.009629136882722378,
      -0.07408840209245682,
      0.06089313328266144,
      0.06782420724630356,
      0.0719028115272522,
      0.029541077092289925,
      -0.031443867832422256,
      -0.01829586550593376,
      0.008592276833951473,
      -0.029652170836925507,
      0.054852571338415146,
      0.036141008138656616,
      0.018673153594136238,
      0.006134186405688524,
      -0.06475909799337387,
      0.059643395245075226,
      -0.059971872717142105,
      -0.04822591692209244,
      -0.038724739104509354,
      -0.03313230723142624,
      -0.005123453680425882,
      0.013871577568352222,
      -0.02759525366127491,
      -0.03003840520977974,
      0.12420973926782608,
      0.0444120317697525,
      -0.029007846489548683,
      -0.017253106459975243,
      0.006379332393407822,
      -0.03544655814766884,
      -0.06397338211536407,
      0.017879223451018333,
      0.023601911962032318,
      0.02514387108385563,
      -0.008013058453798294,
      0.052371930330991745,
      -0.05200745910406113,
      -0.02580108493566513,
      -0.04672803357243538,
      -0.0699227824807167,
      0.062602698802948,
      0.01877848245203495,
      -0.04580255597829819,
      -0.006716309580951929,
      -0.023143986240029335,
      -0.014365717768669128,
      -0.010346245020627975,
      -0.008123418316245079,
      0.021501854062080383,
      -0.0685848817229271,
      0.04389621317386627,
      -0.05900753661990166,
      -0.05919454246759415,
      -0.007867008447647095,
      0.03402241691946983,
      -0.042458802461624146,
      0.03352444991469383,
      -0.000978186260908842,
      0.023879848420619965,
      -0.07550365477800369,
      -0.04978414624929428,
      -0.006657388526946306,
      0.06929125636816025,
      0.05011087283492088,
      -0.010987224988639355,
      0.01841018535196781,
      -0.0009945700876414776,
      -0.046647895127534866,
      -0.010546954348683357,
      -0.05387888848781586,
      -0.03106066584587097,
      0.08251252770423889,
      0.07462354749441147,
      0.08206102252006531,
      0.013432697393000126,
      0.11618052423000336,
      -0.058634888380765915,
      0.0019844917114824057,
      0.01030394434928894,
      0.027305345982313156,
      0.027542714029550552,
      0.025422228500247,
      -0.055985111743211746,
      -0.01911400444805622,
      0.06300750374794006,
      0.03757363557815552,
      -0.030190089717507362,
      0.028602058067917824,
      0.040873944759368896,
      -0.03329572454094887,
      0.03904235363006592,
      -0.061185117810964584,
      -0.01921013370156288,
      -0.023562686517834663,
      -0.03931610658764839,
      -0.04309876635670662,
      -0.006994651630520821,
      0.025488242506980896,
      0.06166708096861839,
      -0.014321728609502316,
      0.03704720363020897,
      0.04764040932059288,
      -0.04209282994270325,
      0.02774057909846306,
      0.0021818820387125015,
      -0.05895315483212471,
      0.007963597774505615,
      -0.02525610476732254,
      0.006138473283499479,
      -0.06700631976127625,
      0.02346804551780224,
      0.01943940483033657,
      -0.08647648990154266,
      0.0002808647695928812,
      -0.08946671336889267,
      0.04676496982574463,
      0.12335769832134247,
      0.023340784013271332,
      0.015392852947115898,
      -0.01973181590437889,
      -0.08305136859416962,
      -0.05023889243602753,
      -0.08021274209022522,
      -0.04281654953956604,
      -0.0023582070134580135,
      -0.026069965213537216,
      -0.035311516374349594,
      0.019205758348107338,
      0.029489640146493912,
      0.06565573066473007,
      -0.019282076507806778,
      0.045615293085575104,
      -0.09594240039587021,
      0.0352364219725132,
      -0.009549200534820557,
      -0.07251180708408356
    ],
    [
      -0.001632126746699214,
      0.021503586322069168,
      0.034153103828430176,
      0.05993885546922684,
      0.042481400072574615,
      -0.01826835423707962,
      0.01137912180274725,
      -0.050095826387405396,
      -0.044436704367399216,
      0.00859617255628109,
      0.04162690043449402,
      -0.032961778342723846,
      -0.0008509109611622989,
      -0.0419326052069664,
      0.02152133733034134,
      -0.03893313929438591,
      0.002824399620294571,
      0.01884598843753338,
      0.01833917945623398,
      0.005680415313690901,
      -0.0003108653472736478,
      0.04285856708884239,
      0.015240698121488094,
      0.018354125320911407,
      0.024900538846850395,
      -0.025305747985839844,
      -0.03142956271767616,
      0.09080880135297775,
      -0.052260857075452805,
      0.015720780938863754,
      -0.09242989122867584,
      -0.06804510205984116,
      -0.07390424609184265,
      -0.04812270775437355,
      -0.04815412312746048,
      0.03976672515273094,
      -0.00023507923469878733,
      0.020572872832417488,
      0.07816015183925629,
      0.0020884366240352392,
      0.026072923094034195,
      -0.09808378666639328,
      0.0041967094875872135,
      0.01720823161303997,
      0.05237128213047981,
      0.03141270950436592,
      0.0732615739107132,
      -0.030903464183211327,
      0.06377657502889633,
      -0.003335607470944524,
      0.038382258266210556,
      0.008440712466835976,
      0.00463727954775095,
      -0.08174232393503189,
      0.01872415468096733,
      0.044825129210948944,
      -0.06415782868862152,
      0.09205320477485657,
      0.023609895259141922,
      -0.07445371896028519,
      0.030087953433394432,
      0.02554084174335003,
      0.0019793803803622723,
      0.00527411000803113,
      -0.0758635625243187,
      -0.05406797677278519,
      0.014380643144249916,
      -0.03139789029955864,
      0.00995783694088459,
      0.04012758657336235,
      -0.04915664717555046,
      -0.06439770758152008,
      -0.049340538680553436,
      0.002444840967655182,
      0.039472904056310654,
      -0.001939409296028316,
      -0.04413241520524025,
      0.05159369856119156,
      0.05154801532626152,
      0.0119090611115098,
      0.021684125065803528,
      -0.03767988085746765,
      -0.023239338770508766,
      0.037292804569005966,
      0.004981671925634146,
      0.006829041056334972,
      -0.05656832456588745,
      0.03247038275003433,
      0.004224746022373438,
      -0.019815603271126747,
      0.023171303793787956,
      -0.017071960493922234,
      0.07760128378868103,
      0.0426223948597908,
      -0.07825662940740585,
      -0.030458349734544754,
      -0.02209431305527687,
      0.04379120096564293,
      -0.048553116619586945,
      0.017657140269875526,
      0.018193477764725685,
      -0.0395711325109005,
      0.04016974940896034,
      -0.013293853960931301,
      0.02949616312980652,
      0.0008907882729545236,
      -0.0361657589673996,
      0.08157563209533691,
      -0.09693022072315216,
      -0.03958911448717117,
      -0.026247665286064148,
      0.02177577093243599,
      0.020615996792912483,
      0.02805374749004841,
      -0.02939826436340809,
      -0.10880932956933975,
      -0.0063186571933329105,
      0.0488751083612442,
      0.009778576903045177,
      0.003888710169121623,
      0.023920392617583275,
      -0.06285449117422104,
      0.03445599600672722,
      -0.03184138610959053,
      -0.06543422490358353,
      -0.041125062853097916,
      -0.048826802521944046,
      0.025773566216230392,
      0.003934625070542097,
      -0.011912195011973381,
      -0.03566262871026993,
      -0.011255568824708462,
      -0.01292981207370758,
      -0.06757044792175293,
      -0.0025190289597958326,
      -0.012591445818543434,
      0.06633450835943222,
      0.005422375630587339,
      0.044190071523189545,
      -0.027516314759850502,
      0.03271551802754402,
      0.03686783090233803,
      -0.02084926702082157,
      0.04967861622571945,
      -0.006985661573708057,
      -0.048924755305051804,
      -0.05281026288866997,
      0.0034770413767546415,
      0.024929286912083626,
      -0.04514874517917633,
      -0.02360859140753746,
      -0.036777786910533905,
      -0.013758744113147259,
      0.014813853427767754,
      -0.011397980153560638,
      -0.013669809326529503,
      0.01667521521449089,
      -0.02286912500858307,
      -0.0011823490494862199,
      0.01831692084670067,
      -0.00544318649917841,
      0.003436552593484521,
      0.044562119990587234,
      0.0647924542427063,
      -0.009079420939087868,
      0.05575210228562355,
      -0.0008045342983677983,
      -0.05575433745980263,
      -0.018685391172766685,
      -0.007432190701365471,
      0.015610197558999062,
      0.008291563019156456,
      -0.0016427668742835522,
      -0.03985339775681496,
      -0.033108215779066086,
      -0.03125055879354477,
      0.14949047565460205,
      0.005788813345134258,
      -0.07854712009429932,
      -0.04475728049874306,
      0.0020994599908590317,
      0.06633699685335159,
      0.05311973765492439,
      -0.015416823327541351,
      0.009622767567634583,
      0.0022909739054739475,
      -0.03816711902618408,
      -0.0600590780377388,
      -0.04786764457821846,
      0.008748426102101803,
      -0.00295317848213017,
      -0.014944382943212986,
      0.0779339000582695,
      0.09374378621578217,
      -0.045823365449905396,
      0.05996270850300789,
      0.030667366459965706,
      -0.003082660026848316,
      0.04792258143424988,
      0.051074523478746414,
      0.10082998871803284,
      0.012827781960368156,
      -0.06025244668126106,
      -0.06880173087120056,
      -0.03174859657883644,
      0.014122022315859795,
      0.02188127674162388,
      0.034809406846761703,
      -0.0662648156285286,
      0.028969265520572662,
      -0.0071822283789515495,
      -0.11535152792930603,
      -0.071855828166008,
      0.008977490477263927,
      -0.06726844608783722,
      0.01477618794888258,
      0.06030590087175369,
      0.1145990863442421,
      0.02795136347413063,
      -0.06600473821163177,
      0.04674778878688812,
      -0.0432400144636631,
      0.08021198958158493,
      0.02077326737344265,
      0.004287497606128454,
      0.1362381875514984,
      0.012433056719601154,
      -0.029130030423402786,
      -0.02791415899991989,
      0.04854373633861542,
      0.010357396677136421,
      -0.003967819735407829,
      0.03031516820192337,
      -0.0059103406965732574,
      -0.05290237069129944,
      -0.06818893551826477,
      -0.04988317936658859,
      0.004362708888947964,
      0.04148032143712044,
      -0.06386316567659378,
      -0.019408967345952988,
      -0.02163095772266388,
      0.016847634688019753,
      0.0370788499712944,
      -0.02032165229320526,
      -0.025676140561699867,
      0.05365746468305588,
      -0.020280860364437103,
      -0.03130190446972847,
      0.014968642964959145,
      0.0055636512115597725,
      0.06762409955263138,
      -0.07192350178956985,
      0.0002907468006014824,
      0.06693892925977707,
      0.06512753665447235,
      0.024050738662481308,
      0.009045821614563465,
      0.048890337347984314,
      0.08639582991600037,
      -0.019924402236938477,
      0.10365986078977585,
      -0.053002726286649704,
      0.022013723850250244,
      -0.02090572752058506,
      0.034206509590148926,
      -0.07633531838655472,
      -0.06501264125108719,
      -0.010511383414268494,
      0.018547633662819862,
      0.030172161757946014,
      0.0022962552029639482,
      0.029938440769910812,
      0.009046169929206371,
      -0.03251274302601814,
      0.002481344621628523,
      -0.003083420218899846,
      -0.0203323382884264,
      0.041577525436878204,
      -0.013181301765143871,
      0.053463246673345566,
      -0.013742835260927677,
      -0.014311781153082848,
      -0.044519197195768356,
      0.0010740116704255342,
      -0.03589041903614998,
      0.029013343155384064,
      0.0005917360540479422,
      -0.010276451706886292,
      0.060001056641340256,
      0.020640738308429718,
      -0.027461370453238487,
      0.0018436087993904948,
      0.030920354649424553,
      -0.02481401339173317,
      -0.026046112179756165,
      0.02561519667506218,
      0.0669797956943512,
      0.012525878846645355,
      0.037899550050497055,
      0.06182478740811348,
      0.041270796209573746,
      -0.020095456391572952,
      -0.023144032806158066,
      -0.012149249203503132,
      0.06166408956050873,
      0.07552667707204819,
      0.049678295850753784,
      0.01729261502623558,
      0.04640313610434532,
      -0.008889681659638882,
      -0.054738324135541916,
      -0.048899732530117035,
      0.05427222698926926,
      -0.02106713503599167,
      -0.06735824048519135,
      0.0977487564086914,
      -0.013799548149108887,
      0.006019612308591604,
      0.05542329326272011,
      -0.03508851304650307,
      -0.010500951670110226,
      0.04338914155960083,
      -0.011808020994067192,
      0.014234881848096848,
      -0.07985003292560577,
      0.012327157892286777,
      -0.025373294949531555,
      0.011463905684649944,
      0.037688691169023514,
      -0.07166293263435364,
      0.007966231554746628,
      0.044560506939888,
      0.0021809262689203024,
      0.005969610530883074,
      0.038214799016714096,
      -0.06440442055463791,
      -0.029829533770680428,
      -0.031673867255449295,
      -0.020237790420651436,
      0.010497785173356533,
      0.06422614306211472,
      -0.026467859745025635,
      0.014680629596114159,
      -0.030604910105466843,
      0.00698443315923214,
      0.0253000371158123,
      0.022367097437381744,
      0.05947491526603699,
      0.050268057733774185,
      -0.020841781049966812,
      -0.08239715546369553,
      -0.021636998280882835,
      -0.015438820235431194,
      -0.01854005828499794,
      0.057014670222997665,
      -0.04478723928332329,
      -0.0004136737552471459,
      0.03483118116855621,
      0.09608586877584457,
      -0.03556974604725838,
      0.1017712652683258,
      -0.0423395074903965,
      0.016607478260993958,
      -0.0228041373193264,
      -0.0006703638937324286,
      0.014142890460789204,
      0.05624626204371452,
      -0.010810005478560925,
      0.0758766233921051,
      -0.020559517666697502,
      -0.04307937994599342,
      -0.006500727031379938,
      -0.0019179261289536953,
      -0.042215779423713684,
      -0.026329057291150093,
      0.0403745137155056,
      0.05937790125608444,
      0.0020348120015114546,
      0.10187884420156479,
      -0.035068754106760025,
      0.01425720751285553,
      0.06297942250967026,
      -0.06627237051725388,
      -0.028745362535119057,
      0.01155870221555233,
      -0.039740703999996185,
      0.009008046239614487,
      0.01945796050131321,
      -0.02875165082514286,
      0.07228392362594604,
      0.020318349823355675,
      -0.08729130774736404,
      -0.043115489184856415,
      0.09597910195589066,
      0.03798815608024597,
      -0.02826777473092079,
      0.008450808934867382,
      -0.0060572754591703415,
      -0.008876264095306396,
      0.0018876312533393502,
      -0.026387372985482216,
      0.055570755153894424,
      -0.002098237629979849,
      -0.028324255719780922,
      0.03854479640722275,
      0.052270472049713135,
      -0.007815648801624775,
      0.0031757396645843983,
      -0.030584855005145073,
      0.04383315518498421,
      -0.03807177022099495,
      0.007768154609948397,
      0.0026866570115089417,
      -0.059331510215997696,
      0.02509452775120735,
      -0.035159558057785034,
      -0.02936014160513878,
      0.03236721456050873,
      -0.07089980691671371,
      -0.04854058101773262,
      -0.003087952034547925,
      -0.09043367207050323,
      -0.02389443665742874,
      -0.071784108877182,
      0.03306252881884575,
      0.010204819962382317,
      0.026174936443567276,
      -0.04512512683868408,
      0.009050167165696621,
      0.02989288978278637,
      0.060727961361408234,
      0.007434171158820391,
      -0.0440170057117939,
      0.00017518394452054054,
      -0.026553580537438393,
      0.06278453022241592,
      0.009601693600416183,
      -0.01202322356402874,
      -0.07369506359100342,
      -0.028929242864251137,
      0.017216455191373825,
      -0.05070843547582626,
      0.0517473928630352,
      0.03244311735033989,
      0.063132643699646,
      0.06197841465473175,
      -0.09464225172996521,
      0.0802115648984909,
      0.05534237250685692,
      0.042911678552627563,
      0.08916086703538895,
      0.007068439852446318,
      -0.03074032999575138,
      -0.05518355220556259,
      0.009662101045250893,
      -0.043217651546001434,
      0.027700146660208702,
      0.0164297204464674,
      0.046719349920749664,
      0.0719807893037796,
      -0.04864135757088661,
      -0.0023894368205219507,
      0.03221319988369942,
      0.03773987665772438,
      -0.036059364676475525,
      -0.09315497428178787,
      -0.04119391366839409,
      -0.06156769022345543,
      0.06619071215391159,
      -0.10727909952402115,
      -0.02226967178285122,
      0.0017905072309076786,
      -0.059088703244924545,
      0.0005149950738996267,
      -0.004446566570550203,
      0.014041753485798836,
      0.034660253673791885,
      -0.09270211309194565,
      0.0019670575857162476,
      -0.02265668287873268,
      0.005496881436556578,
      0.017340589314699173,
      0.04303710162639618,
      0.014806902036070824,
      0.07227250933647156,
      -0.03975818306207657,
      0.057401418685913086,
      0.014686523005366325,
      -0.032670799642801285,
      0.013606259599328041,
      -0.05891375616192818,
      0.006935497745871544,
      -0.004954021889716387,
      -0.035311222076416016,
      0.0636003166437149,
      -0.020208215340971947,
      0.04673391580581665,
      -0.019479956477880478,
      -0.10592874139547348,
      -0.01848258450627327,
      0.02059447206556797,
      -0.027347270399332047
    ],
    [
      0.040132682770490646,
      0.061216410249471664,
      0.029819853603839874,
      0.0007908308180049062,
      0.008645853959023952,
      0.03770167753100395,
      0.02305164746940136,
      -0.012299942784011364,
      0.03777548298239708,
      -0.10224554687738419,
      0.05239562317728996,
      0.025011230260133743,
      -0.05926641449332237,
      -0.02214343100786209,
      0.025130832567811012,
      -0.07146003097295761,
      0.005747570190578699,
      -0.07602377235889435,
      0.015419348143041134,
      -0.022880934178829193,
      -0.15656286478042603,
      0.018696075305342674,
      -0.06090966984629631,
      -0.05297764763236046,
      -0.009742219001054764,
      -0.03134064003825188,
      -0.039818231016397476,
      0.04335465654730797,
      0.04378857836127281,
      0.06831563264131546,
      0.04139222204685211,
      0.028036512434482574,
      -0.005738194566220045,
      -0.01619062013924122,
      -0.06516597419977188,
      -0.07718930393457413,
      0.05416347458958626,
      0.048477351665496826,
      -0.008184822276234627,
      0.029028937220573425,
      -0.04084943234920502,
      -0.08966100215911865,
      0.037027738988399506,
      0.008963275700807571,
      0.012696373276412487,
      -0.023484228178858757,
      0.021328017115592957,
      0.0003536948934197426,
      0.01737746223807335,
      0.08187679201364517,
      0.05621282756328583,
      -0.010015358217060566,
      0.01096191443502903,
      -0.047246094793081284,
      -0.016952205449342728,
      0.09714987128973007,
      0.012289357371628284,
      -0.02493279054760933,
      0.04564682021737099,
      0.015783600509166718,
      0.011819195933640003,
      0.049246832728385925,
      0.008817898109555244,
      0.01938171498477459,
      -0.021069305017590523,
      -0.010357300750911236,
      0.04286758229136467,
      0.017612362280488014,
      0.06502660363912582,
      0.03596016764640808,
      0.06377498060464859,
      0.004407375585287809,
      0.03212124481797218,
      -0.05502528324723244,
      0.03586839511990547,
      -0.029453884810209274,
      -0.017875244840979576,
      -0.029393741860985756,
      -0.030875129625201225,
      -0.04128099977970123,
      -0.037747714668512344,
      -0.010059166699647903,
      -0.021677186712622643,
      -0.03665698692202568,
      -0.0523035004734993,
      -0.050798241049051285,
      -0.029657432809472084,
      0.09794165939092636,
      0.02796386182308197,
      -0.043950844556093216,
      0.017749285325407982,
      -0.009176372550427914,
      -0.05572829023003578,
      0.051113493740558624,
      0.010258840397000313,
      0.0591856986284256,
      -0.07228696346282959,
      0.0547867976129055,
      -0.013882292434573174,
      -0.058747678995132446,
      0.019597169011831284,
      0.06841868907213211,
      -0.043976761400699615,
      -0.008345543406903744,
      0.014727639965713024,
      0.0518728569149971,
      -0.027129938825964928,
      0.005769988056272268,
      -0.05569134280085564,
      0.02208695001900196,
      -0.04818899929523468,
      -0.025282200425863266,
      0.020194433629512787,
      -0.007018801756203175,
      -0.023491017520427704,
      -0.015779774636030197,
      -0.012086638249456882,
      -0.022337518632411957,
      0.022283880040049553,
      0.004112865310162306,
      -0.008937841281294823,
      0.005424747243523598,
      0.05335047096014023,
      0.15830902755260468,
      -0.02787644788622856,
      -0.025544805452227592,
      -0.03897025063633919,
      0.061206456273794174,
      -0.0511762909591198,
      0.10392845422029495,
      0.06160266697406769,
      0.0019338405691087246,
      0.007574289571493864,
      0.001324022770859301,
      0.05003926157951355,
      -0.027231168001890182,
      0.03921699523925781,
      0.02764621190726757,
      -0.016870705410838127,
      -0.026800932362675667,
      -0.07366631180047989,
      0.022201253101229668,
      -0.007453371770679951,
      0.09496955573558807,
      0.05638299509882927,
      0.057581402361392975,
      -0.03024192340672016,
      0.031872835010290146,
      0.022545773535966873,
      0.06299030035734177,
      -0.02892841026186943,
      0.0991622805595398,
      0.0004540402442216873,
      -0.06663424521684647,
      -0.09440872818231583,
      -0.017053265124559402,
      -0.04002268612384796,
      0.03541358560323715,
      -0.054295893758535385,
      -0.0757938101887703,
      0.01102481409907341,
      0.023337503895163536,
      0.007973634637892246,
      -0.03229498490691185,
      -0.018472416326403618,
      -0.0037433404941111803,
      0.018168827518820763,
      0.02652542106807232,
      -0.0027218887116760015,
      0.0092617217451334,
      -0.017241474241018295,
      0.0059942156076431274,
      -0.06682634353637695,
      -0.042402178049087524,
      -0.01749500446021557,
      0.01653720997273922,
      -0.02670562081038952,
      0.06403794884681702,
      0.0590524822473526,
      0.006966928485780954,
      0.10938169062137604,
      0.03561253473162651,
      -0.040612295269966125,
      -0.024389343336224556,
      -0.028116775676608086,
      -0.0673530325293541,
      -0.004429555032402277,
      0.06494798511266708,
      0.021335529163479805,
      -0.04392958804965019,
      0.01630733162164688,
      -0.16446901857852936,
      0.06315139681100845,
      -0.04560495540499687,
      -0.022035660222172737,
      -0.10727139562368393,
      -0.03963310644030571,
      -0.03501506522297859,
      0.008122842758893967,
      0.013941950164735317,
      0.03355812281370163,
      -0.02446102909743786,
      0.006170088890939951,
      0.0013056329917162657,
      0.051684409379959106,
      0.05777774751186371,
      0.0109650744125247,
      -0.024971650913357735,
      -0.028100617229938507,
      0.04036414623260498,
      -0.036851175129413605,
      -0.03597341105341911,
      0.06923488527536392,
      0.05046648532152176,
      -0.025887522846460342,
      -0.0388384647667408,
      0.018223334103822708,
      0.014649173244833946,
      0.015044553205370903,
      0.051748260855674744,
      0.10305885225534439,
      0.018100425601005554,
      0.10447723418474197,
      -0.018003489822149277,
      0.041420675814151764,
      0.03203432634472847,
      0.07336834818124771,
      -0.11195620149374008,
      0.020285755395889282,
      0.041645560413599014,
      -0.05465569719672203,
      -0.05772923678159714,
      0.08308649063110352,
      -0.04777197912335396,
      -0.04706690460443497,
      -0.06000577285885811,
      0.01161298155784607,
      -0.08273746073246002,
      0.04916490986943245,
      0.031391993165016174,
      -0.06465902179479599,
      -0.03921319916844368,
      0.08270019292831421,
      -0.015479997731745243,
      -0.031240219250321388,
      -0.0024209104012697935,
      -0.05942893400788307,
      0.04260386899113655,
      0.010419667698442936,
      -0.03806283697485924,
      0.01908639818429947,
      0.07797005027532578,
      -0.008218386210501194,
      0.011743824928998947,
      0.020238902419805527,
      0.03007732890546322,
      0.0823926031589508,
      0.018211711198091507,
      0.0737868994474411,
      -0.00360505236312747,
      -0.0510723851621151,
      -0.015388524159789085,
      -0.0473061203956604,
      -0.01250388752669096,
      -0.04029002785682678,
      -0.002060566795989871,
      0.03136599436402321,
      0.023299286141991615,
      0.03304573521018028,
      0.03903631120920181,
      0.034217771142721176,
      0.05368191376328468,
      0.01632864959537983,
      -0.006502913311123848,
      -0.016753215342760086,
      -0.01205254253000021,
      -0.015919171273708344,
      -0.015343667939305305,
      0.019592469558119774,
      0.02002185769379139,
      -0.03188612312078476,
      0.009843209758400917,
      0.03756370395421982,
      -0.031133757904171944,
      0.09419827163219452,
      -0.059770289808511734,
      0.05622630566358566,
      0.0033843349665403366,
      -0.00230401917360723,
      0.07770358771085739,
      0.04199971631169319,
      -0.06037726253271103,
      -0.05590183660387993,
      0.017418621107935905,
      -0.029521211981773376,
      -0.009328017011284828,
      -0.022198520600795746,
      -0.0562986359000206,
      0.11614412069320679,
      0.029501846060156822,
      -0.013953396119177341,
      -0.0006716151838190854,
      -0.01695297658443451,
      -0.05946109816431999,
      -0.03617620840668678,
      0.019471049308776855,
      -0.015123359858989716,
      -0.014280357398092747,
      0.07115209847688675,
      0.010976319201290607,
      0.02440682239830494,
      0.006360189989209175,
      -0.05674396827816963,
      0.011859647929668427,
      -0.04921819269657135,
      -0.027683960273861885,
      0.052582673728466034,
      0.04793842136859894,
      -0.04748660698533058,
      0.03170005977153778,
      -0.04221933335065842,
      -0.08024463802576065,
      -0.037316516041755676,
      -0.009062444791197777,
      -0.0017211087979376316,
      -0.012061582878232002,
      0.11928610503673553,
      0.04219450801610947,
      0.04229309782385826,
      0.026528971269726753,
      0.05722125992178917,
      -0.03608834743499756,
      -0.07808295637369156,
      0.0820041298866272,
      0.011010395362973213,
      0.003200264647603035,
      -0.03613782674074173,
      -0.06841786205768585,
      -0.05868520960211754,
      0.01648787595331669,
      0.026652012020349503,
      0.0277220718562603,
      -0.00075391965219751,
      0.10129132866859436,
      0.024812469258904457,
      0.04461194947361946,
      -0.04979836568236351,
      0.08292364329099655,
      0.011230513453483582,
      -0.0631447285413742,
      0.00409741559997201,
      0.008025162853300571,
      0.010180318728089333,
      -0.04293803870677948,
      0.02112417481839657,
      -0.030966270714998245,
      -0.057204414159059525,
      -0.059218522161245346,
      -0.019782932475209236,
      0.03814065828919411,
      0.0004543632676359266,
      0.051696617156267166,
      0.008655226789414883,
      0.038347963243722916,
      0.06040219962596893,
      0.02727164700627327,
      0.05712128430604935,
      -0.018074359744787216,
      0.02451162599027157,
      -0.05291029065847397,
      0.022814292460680008,
      -0.024074681103229523,
      -0.0010519602801650763,
      -0.09004849195480347,
      0.010920400731265545,
      -0.0773162990808487,
      0.011225170455873013,
      0.01183126401156187,
      0.004052856937050819,
      -0.04753206670284271,
      0.02164861001074314,
      -0.036188334226608276,
      -0.043334297835826874,
      0.04313453286886215,
      0.060533903539180756,
      -0.005911335814744234,
      -0.02821812406182289,
      0.004280630964785814,
      -0.014259872026741505,
      -0.032761767506599426,
      0.04233141243457794,
      0.05273181200027466,
      -0.0182791855186224,
      -0.08287861198186874,
      -0.04440666362643242,
      0.017205728217959404,
      0.003729961346834898,
      -0.017254486680030823,
      0.017371265217661858,
      -0.04485742002725601,
      -0.010324257425963879,
      -0.03790600597858429,
      -0.04998856410384178,
      0.0303413774818182,
      -0.013554622419178486,
      0.008508795872330666,
      -0.06403325498104095,
      -0.028419075533747673,
      0.06028079241514206,
      -0.022061046212911606,
      -0.0056683700531721115,
      0.0662771537899971,
      0.08543654531240463,
      -0.009555348195135593,
      0.054112378507852554,
      -0.023031877353787422,
      0.028690945357084274,
      0.04479902237653732,
      -0.015789220109581947,
      -0.007760454434901476,
      -0.030316336080431938,
      -0.07198698818683624,
      -0.07741814106702805,
      -0.01826666295528412,
      0.010854944586753845,
      -0.0835832729935646,
      -0.1074603721499443,
      -0.021041080355644226,
      -0.03573497757315636,
      0.10517781972885132,
      0.05615980923175812,
      0.0034988175611943007,
      0.012113691307604313,
      -0.06105200946331024,
      -0.0029391227290034294,
      0.011433979496359825,
      -0.02550656348466873,
      -0.02414434589445591,
      0.0031521348282694817,
      -0.013423803262412548,
      0.030206482857465744,
      -0.05314009636640549,
      -0.03182045742869377,
      -0.03629982843995094,
      0.00012774317292496562,
      0.016535701230168343,
      -0.015058010816574097,
      0.03772573173046112,
      0.04618297889828682,
      0.03334425389766693,
      -0.06292038410902023,
      -0.01706213504076004,
      0.03285973146557808,
      -0.04364798218011856,
      0.06298421323299408,
      0.00859253853559494,
      0.03011772595345974,
      0.012930012308061123,
      0.08349444717168808,
      0.002336774254217744,
      0.04480193555355072,
      -0.07142331451177597,
      -0.010909229516983032,
      -0.005352135747671127,
      0.08136865496635437,
      0.025497600436210632,
      0.05686120688915253,
      -0.0044202134013175964,
      0.01913061924278736,
      -0.02236614003777504,
      0.07090675085783005,
      0.024397602304816246,
      0.04193371534347534,
      -0.01642424240708351,
      -0.0319027341902256,
      -0.0036538655404001474,
      -0.038702305406332016,
      0.05681341141462326,
      0.010155276395380497,
      -0.1052805483341217,
      -0.03213127702474594,
      0.010338345542550087,
      0.06676800549030304,
      0.032694049179553986,
      0.026146605610847473,
      -0.0653059184551239,
      0.004208642523735762,
      0.048061855137348175,
      0.016700340434908867,
      0.001068650744855404,
      -0.022083139047026634,
      -0.016088323667645454,
      0.02832581289112568,
      -0.05223339796066284,
      -0.042668890208005905,
      -0.018050814047455788,
      0.018214959651231766,
      -0.07122121751308441,
      0.0003266001003794372,
      -0.0628221184015274,
      -0.12047833204269409,
      -0.034332066774368286,
      -0.13401946425437927
    ],
    [
      -0.01225307211279869,
      0.012727760709822178,
      0.06746753305196762,
      -0.05186109244823456,
      0.035544928163290024,
      0.016213173046708107,
      0.02454792521893978,
      0.0018019818235188723,
      0.05317423492670059,
      -0.02562624216079712,
      -0.008037756197154522,
      -0.06676886230707169,
      -0.0419372096657753,
      -0.025040743872523308,
      -0.024335557594895363,
      -0.09218151122331619,
      -0.02852688543498516,
      0.006866746582090855,
      0.06772936135530472,
      -0.047060832381248474,
      0.012409824877977371,
      0.017132218927145004,
      0.06784563511610031,
      -0.0026481507811695337,
      -0.049787890166044235,
      0.03518137335777283,
      0.04817724600434303,
      0.01844804733991623,
      0.021925566717982292,
      0.03975864127278328,
      -0.03578541427850723,
      -0.02076759934425354,
      -0.015204538591206074,
      0.03214976191520691,
      -0.06874017417430878,
      -0.030074166133999825,
      0.08081422001123428,
      4.922073276247829e-05,
      0.035092197358608246,
      -0.0720592737197876,
      0.06326724588871002,
      -0.021680962294340134,
      -0.07171349972486496,
      0.003679784247651696,
      0.027913551777601242,
      -0.036293238401412964,
      0.026200581341981888,
      0.049888938665390015,
      -0.00039133758400566876,
      -0.0048469118773937225,
      -0.004882065113633871,
      -0.0949479267001152,
      0.02574029378592968,
      -0.029608456417918205,
      -0.0034156155306845903,
      0.06355144083499908,
      -0.04593935236334801,
      0.03134555369615555,
      0.05932675674557686,
      0.06847876310348511,
      -0.07774528861045837,
      0.046747636049985886,
      0.01470171194523573,
      -0.04320785775780678,
      -0.0715489462018013,
      -0.08361651003360748,
      -0.09584919363260269,
      0.029890388250350952,
      0.002072341740131378,
      0.05078968405723572,
      -0.004543904680758715,
      0.051221299916505814,
      0.056107454001903534,
      0.013168536126613617,
      -0.026711534708738327,
      -0.07893739640712738,
      -0.01693975180387497,
      0.008460409939289093,
      -0.025306785479187965,
      -0.08516809344291687,
      -0.09354401379823685,
      -0.0062559619545936584,
      0.04253963381052017,
      0.055052030831575394,
      0.11498512327671051,
      0.03310038521885872,
      -0.04397251084446907,
      0.008960659615695477,
      0.04468818008899689,
      -0.01316035632044077,
      0.00014446179557126015,
      -0.0066055296920239925,
      0.002407603897154331,
      0.0023090930189937353,
      -0.04486759006977081,
      0.01515213493257761,
      -0.01298688817769289,
      -0.029439248144626617,
      0.04182124137878418,
      0.049065690487623215,
      0.06635357439517975,
      -0.024651920422911644,
      -0.045719027519226074,
      -0.018663091585040092,
      0.056798771023750305,
      0.01692862994968891,
      -0.01325004268437624,
      -0.11985386908054352,
      0.03840751573443413,
      -0.01984557881951332,
      0.09128428250551224,
      0.03949977084994316,
      0.0039796228520572186,
      0.037162769585847855,
      0.007567824330180883,
      0.04379696398973465,
      -0.020525533705949783,
      0.05639992281794548,
      -0.02874176949262619,
      -0.06790269911289215,
      0.04517851769924164,
      0.020393578335642815,
      0.02341735176742077,
      0.047794584184885025,
      0.019485512748360634,
      -0.07181374728679657,
      0.06816078722476959,
      0.10828779637813568,
      -0.02318212203681469,
      -0.039599835872650146,
      -0.05058455094695091,
      -0.04704763740301132,
      0.03210363909602165,
      0.06496637314558029,
      0.0041956412605941296,
      -0.009138048626482487,
      0.0661117359995842,
      -0.03340030461549759,
      -0.037353068590164185,
      -0.06896360218524933,
      0.007138578686863184,
      0.044933125376701355,
      0.0070513030514121056,
      0.08445519953966141,
      -0.013904265128076077,
      0.009559436701238155,
      0.056740034371614456,
      -0.008980249054729939,
      -0.047150347381830215,
      0.01111467182636261,
      -0.0025578283239156008,
      -0.03431253135204315,
      0.02908959612250328,
      -0.05247844383120537,
      0.06904119998216629,
      -0.012198901735246181,
      0.024350527673959732,
      -0.015268239192664623,
      0.014728538691997528,
      -0.0917557030916214,
      -0.0524243526160717,
      -0.02737949602305889,
      0.05224671959877014,
      0.031356845051050186,
      -0.0064767953008413315,
      0.05215633288025856,
      -0.08147689700126648,
      -0.018830908462405205,
      -0.046407051384449005,
      -0.0010951721342280507,
      -0.022593140602111816,
      0.009249010123312473,
      0.06835867464542389,
      0.005816981662064791,
      -0.08577661216259003,
      -0.003260913537815213,
      -0.03864014893770218,
      0.08446803689002991,
      0.006919451057910919,
      0.039224185049533844,
      -0.0017620743019506335,
      0.0408400259912014,
      0.04005320742726326,
      0.05208021402359009,
      0.01764022186398506,
      0.029847638681530952,
      0.020085623487830162,
      0.05248292535543442,
      -0.03650069981813431,
      0.011882716789841652,
      0.016664927825331688,
      -0.04674176126718521,
      0.04545116424560547,
      0.003059912472963333,
      -0.03162375092506409,
      -0.008785178884863853,
      0.059054896235466,
      -0.02863430790603161,
      -0.019802697002887726,
      0.1059400662779808,
      0.00760301761329174,
      -0.025323670357465744,
      0.004218014422804117,
      0.05116800591349602,
      -0.044339608401060104,
      0.007315254304558039,
      0.031212646514177322,
      0.03568819537758827,
      -0.0323139913380146,
      -0.001321578398346901,
      -0.08101744204759598,
      -0.08774304389953613,
      -0.06640201807022095,
      0.0825931504368782,
      -0.020273538306355476,
      0.009411736391484737,
      -0.008850058540701866,
      0.11272094398736954,
      -0.011204567737877369,
      -0.02386491559445858,
      0.04852917790412903,
      -0.020386693999171257,
      0.07589813321828842,
      -0.04173002764582634,
      -0.1251164972782135,
      -0.02996600791811943,
      0.09232127666473389,
      -0.07197249680757523,
      -0.055132411420345306,
      0.007130265235900879,
      -0.03409358486533165,
      -0.010159922763705254,
      -0.05373083055019379,
      -0.0528259351849556,
      -0.05420592427253723,
      0.045410990715026855,
      -0.07565731555223465,
      -0.04795847088098526,
      0.034874077886343,
      0.0935039073228836,
      -0.003728248877450824,
      -0.022841788828372955,
      -0.012761612422764301,
      -0.02665960229933262,
      -0.051320988684892654,
      0.03404543921351433,
      -0.0605386458337307,
      0.03787913918495178,
      0.08905572444200516,
      0.06749377399682999,
      0.022343037649989128,
      -0.0035521402023732662,
      -0.013149377889931202,
      0.005783317144960165,
      -0.07191342860460281,
      0.061962492763996124,
      -0.05032701417803764,
      0.0881795585155487,
      0.036105141043663025,
      -0.0021371678449213505,
      0.008015887811779976,
      0.05608425289392471,
      0.08162462711334229,
      -0.00914949830621481,
      0.0012464747997000813,
      -0.007927650585770607,
      0.016190575435757637,
      -0.020210061222314835,
      0.0015352176269516349,
      0.02887534722685814,
      -0.02782377600669861,
      -0.04163318872451782,
      -0.0009163893992081285,
      0.10637301951646805,
      0.03475051745772362,
      -0.05045993626117706,
      0.02766283042728901,
      -0.031336914747953415,
      0.05687696486711502,
      0.08958684653043747,
      0.04313420504331589,
      0.04950162023305893,
      -0.007150270044803619,
      -0.07044188678264618,
      0.018120817840099335,
      -0.047580327838659286,
      -0.0374319925904274,
      0.050935279577970505,
      0.07629707455635071,
      0.04272386431694031,
      0.07590871304273605,
      0.0055365776643157005,
      0.055329542607069016,
      -0.06463775783777237,
      -0.027415841817855835,
      0.04727206379175186,
      -0.006184543017297983,
      0.03017096221446991,
      0.018872614949941635,
      0.01612265221774578,
      0.0014886544086039066,
      0.028427129611372948,
      0.09180597215890884,
      -0.02824845351278782,
      0.059511877596378326,
      0.0063327280804514885,
      -0.023092811927199364,
      0.00646232208237052,
      -0.018326444551348686,
      0.0037624416872859,
      0.04404343664646149,
      -0.06167380511760712,
      0.056609999388456345,
      -0.03447893634438515,
      0.016322171315550804,
      -0.03637497127056122,
      0.057679686695337296,
      -0.015504092909395695,
      -0.006804261356592178,
      0.025227336212992668,
      0.012353901751339436,
      0.008982955478131771,
      -0.07211015373468399,
      0.04506826773285866,
      -0.02109946310520172,
      -0.030468681827187538,
      -0.021041782572865486,
      0.0698358565568924,
      0.02384248562157154,
      -0.014740953221917152,
      0.0035217818804085255,
      0.062243349850177765,
      -0.06712222844362259,
      0.05131538212299347,
      -0.013675288297235966,
      0.07488210499286652,
      -0.05578923225402832,
      -0.023677486926317215,
      0.00033953931415453553,
      0.09719409793615341,
      0.01076554972678423,
      0.003527451306581497,
      -0.06333117187023163,
      0.036076869815588,
      -0.025315556675195694,
      -0.058546166867017746,
      -0.030270986258983612,
      0.01554036233574152,
      0.026035020127892494,
      0.006248652935028076,
      0.03313877433538437,
      -0.009987656027078629,
      -0.0468926727771759,
      0.03938828036189079,
      0.0590626485645771,
      -0.01461358368396759,
      -0.026597976684570312,
      0.02570887841284275,
      -0.013257627375423908,
      0.0879509374499321,
      -0.045520517975091934,
      -0.0071684978902339935,
      0.0229593925178051,
      -0.1038166880607605,
      0.018468989059329033,
      -0.04159790650010109,
      0.10579413175582886,
      0.04886825755238533,
      0.07763448357582092,
      -0.025878911837935448,
      0.0666024312376976,
      0.04039027541875839,
      0.014923620037734509,
      0.009634943678975105,
      -0.001997946761548519,
      -0.018161294981837273,
      -0.004743605386465788,
      -0.02043951116502285,
      -0.08364713191986084,
      -0.053426794707775116,
      0.02670462802052498,
      -0.02218136191368103,
      -0.07899230718612671,
      -0.05595533922314644,
      0.0010994725162163377,
      0.015066470950841904,
      -0.05638010427355766,
      0.01738722249865532,
      0.00457292515784502,
      -0.01654571108520031,
      -0.009250743314623833,
      0.0372496098279953,
      0.014165978878736496,
      -0.018000099807977676,
      0.03494098410010338,
      -0.038477588444948196,
      -0.029106533154845238,
      0.022304607555270195,
      -0.008013934828341007,
      0.04703541100025177,
      0.045062463730573654,
      0.06488309800624847,
      -0.011980488896369934,
      0.01092114020138979,
      0.05880888178944588,
      0.05298977717757225,
      -0.010091562755405903,
      -0.03659433126449585,
      0.09758546948432922,
      0.03553071618080139,
      -0.06658000499010086,
      0.12936119735240936,
      -0.010950896888971329,
      0.030281586572527885,
      -0.01419674139469862,
      -0.06734920293092728,
      -0.025562375783920288,
      0.012996604666113853,
      0.01770814135670662,
      0.001484970562160015,
      -0.05734501779079437,
      -0.0769265815615654,
      -0.04272426664829254,
      -0.004354133736342192,
      -0.057496264576911926,
      0.004371081478893757,
      -0.020590394735336304,
      -0.023302428424358368,
      0.0012065861374139786,
      0.05482205003499985,
      0.08449059724807739,
      0.06559480726718903,
      0.0460774190723896,
      -0.03487648442387581,
      -0.004012733697891235,
      -0.04822223633527756,
      -0.04566309228539467,
      -0.003622257150709629,
      0.07609298825263977,
      -0.00970560684800148,
      -0.04250773414969444,
      -0.05383232608437538,
      0.023019088432192802,
      -0.03177385777235031,
      0.054120320826768875,
      -0.05649864673614502,
      0.08556696027517319,
      0.04134133830666542,
      0.05467444658279419,
      -0.05038869008421898,
      -0.02486114203929901,
      -0.0652170479297638,
      0.010780096985399723,
      -0.10189320147037506,
      0.022779427468776703,
      0.00306238722987473,
      0.07051234692335129,
      0.05036380514502525,
      0.025972262024879456,
      0.019170232117176056,
      -0.03700714185833931,
      0.09878002107143402,
      0.029784249141812325,
      -0.01636366732418537,
      -0.00617574155330658,
      -0.0520038902759552,
      0.009735089726746082,
      0.018740007653832436,
      0.07716278731822968,
      0.02555745467543602,
      -0.04623689875006676,
      0.09641410410404205,
      -0.006392837967723608,
      -0.014944551512598991,
      -0.08794178813695908,
      -0.01878795400261879,
      -0.07814768701791763,
      -0.06357401609420776,
      0.04973246902227402,
      -0.0485239177942276,
      0.017505506053566933,
      0.0038600685074925423,
      -0.02786511555314064,
      0.051322318613529205,
      0.01994418166577816,
      -0.10085910558700562,
      -0.002635535318404436,
      0.07940219342708588,
      0.013826408423483372,
      0.03876769170165062,
      0.025693705305457115,
      -0.05706174671649933,
      0.015332045964896679,
      -0.053305305540561676,
      0.004808288533240557,
      -0.02730467915534973,
      -0.016818581148982048,
      0.05015955865383148,
      0.0342433899641037,
      -0.026964791119098663,
      -0.0008814185857772827,
      -0.010386569425463676,
      -0.08017951995134354
    ],
    [
      -0.055899668484926224,
      -0.006537843029946089,
      0.0228201262652874,
      -0.05268897861242294,
      0.05105893313884735,
      0.055963438004255295,
      0.03491915017366409,
      0.10132387280464172,
      0.030908335000276566,
      0.0037939278408885,
      -0.019389400258660316,
      -0.0020512777846306562,
      0.018614033237099648,
      -0.025193678215146065,
      0.014683916233479977,
      -0.003663727780804038,
      0.04194600507616997,
      0.05481439456343651,
      0.045998573303222656,
      -0.020543349906802177,
      -0.021318433806300163,
      0.04513397067785263,
      -0.00842114444822073,
      0.09844212234020233,
      -0.059329692274332047,
      -0.03543689846992493,
      -0.038473986089229584,
      -0.004180885385721922,
      0.04976833239197731,
      0.05111916735768318,
      0.04605674743652344,
      -0.030694907531142235,
      -0.014456153847277164,
      0.0294281505048275,
      -0.008225680328905582,
      -0.025463491678237915,
      0.047158606350421906,
      0.004762547556310892,
      0.03309892863035202,
      -0.04658724367618561,
      0.011724292300641537,
      -0.030731836333870888,
      -0.025356199592351913,
      0.03903370723128319,
      0.030815301463007927,
      0.06545133888721466,
      0.004896656144410372,
      -0.0648321583867073,
      -0.0034373768139630556,
      -0.005554709117859602,
      0.03414890170097351,
      -0.019678376615047455,
      -0.028835976496338844,
      0.05164775252342224,
      -0.08692063391208649,
      0.060777440667152405,
      -0.026432044804096222,
      0.01165856048464775,
      -0.024691853672266006,
      -0.01262164767831564,
      0.05561276525259018,
      -0.03401203081011772,
      0.011402874253690243,
      -0.025410685688257217,
      0.06164262071251869,
      -0.04612850770354271,
      0.07092393189668655,
      0.006745212245732546,
      0.07936899363994598,
      -0.05260157957673073,
      -0.019592830911278725,
      0.008299646899104118,
      0.039778463542461395,
      -0.07143161445856094,
      0.025743849575519562,
      -0.05994392931461334,
      -0.009538265876471996,
      0.055901698768138885,
      0.00569510692730546,
      0.09265398234128952,
      -0.058315906673669815,
      0.07519090920686722,
      -0.03512776643037796,
      0.04076597839593887,
      0.0022852334659546614,
      -0.034946590662002563,
      0.010485975071787834,
      -0.007229590322822332,
      -0.0252558421343565,
      0.038006871938705444,
      0.05009176582098007,
      -0.04308318346738815,
      -0.021404774859547615,
      -0.05657374858856201,
      0.01338369119912386,
      0.012930202297866344,
      0.001071592909283936,
      -0.035914864391088486,
      0.03517879173159599,
      -0.04563164338469505,
      0.003513315925374627,
      0.008036096580326557,
      0.028034882619976997,
      0.02458197809755802,
      0.02299192175269127,
      0.031455911695957184,
      -0.06063248589634895,
      0.026856817305088043,
      0.024157105013728142,
      0.00906921736896038,
      0.0006865063915029168,
      -0.02796589396893978,
      0.0828237533569336,
      0.07390694320201874,
      -0.03733053803443909,
      -0.03953665867447853,
      -0.020671693608164787,
      -0.016977384686470032,
      0.07788264751434326,
      -0.004111072979867458,
      0.09204012900590897,
      -0.06121630594134331,
      -0.014603313989937305,
      0.00891661923378706,
      0.005368806421756744,
      -0.09700118750333786,
      -0.0221293643116951,
      -0.019758356735110283,
      -0.11243230104446411,
      0.04032503068447113,
      -0.048159729689359665,
      0.041415583342313766,
      0.08729817718267441,
      0.02323991432785988,
      -0.05058145523071289,
      -0.025019396096467972,
      0.00861189141869545,
      -0.018554482609033585,
      0.013663756661117077,
      -0.009144180454313755,
      -0.0032377326861023903,
      0.03451135754585266,
      -0.004108315333724022,
      0.0036887957248836756,
      0.009421123191714287,
      -0.03118150681257248,
      -0.004826059099286795,
      0.02394934743642807,
      -0.10040150582790375,
      -0.04102485999464989,
      -0.013875165022909641,
      -0.015729498118162155,
      0.013446145690977573,
      -0.07262876629829407,
      -0.05415090173482895,
      -0.006582837551832199,
      -0.04585724323987961,
      0.06838924437761307,
      -0.01924608275294304,
      -0.00900192279368639,
      0.11859744042158127,
      0.03908764198422432,
      0.012772521004080772,
      -0.01344592310488224,
      0.02660253457725048,
      -0.0703035295009613,
      -0.012383759953081608,
      -0.054073553532361984,
      -0.0005737044848501682,
      -0.013798742555081844,
      -0.008385335095226765,
      0.015485044568777084,
      0.021461181342601776,
      -0.10075221210718155,
      0.03594275191426277,
      -0.024194400757551193,
      0.00866730511188507,
      -0.0051999823190271854,
      -0.045723807066679,
      -0.06357596814632416,
      -0.03230420872569084,
      0.02386823110282421,
      0.06384005397558212,
      0.011134828440845013,
      -0.017884623259305954,
      0.04464007169008255,
      -0.048974957317113876,
      0.013795462436974049,
      0.03633410856127739,
      0.06180939823389053,
      0.025903847068548203,
      0.07566853612661362,
      -0.0021677184849977493,
      -0.01352821383625269,
      0.005784375127404928,
      0.07359065860509872,
      0.07858278602361679,
      -0.09516215324401855,
      0.0036291226278990507,
      -0.030827926471829414,
      -0.03940551355481148,
      0.01900431141257286,
      0.10649324208498001,
      -0.029558587819337845,
      -0.061424970626831055,
      -0.005096100736409426,
      0.08149413764476776,
      0.0008955983794294298,
      -0.061242006719112396,
      0.01391463354229927,
      -0.07221285253763199,
      -0.046364545822143555,
      0.06645021587610245,
      -0.04290829598903656,
      0.07782208174467087,
      -0.06260299682617188,
      -0.018731379881501198,
      0.04804549366235733,
      0.059891603887081146,
      0.02958104759454727,
      0.02469010278582573,
      -0.009236466139554977,
      0.06003135070204735,
      0.006023087073117495,
      -0.07615111768245697,
      0.025957681238651276,
      -0.0028063426725566387,
      -0.040267881006002426,
      -0.02110726572573185,
      -0.04171903058886528,
      -0.035640474408864975,
      -0.00864255428314209,
      0.07125832140445709,
      0.06805940717458725,
      -0.034322451800107956,
      0.01870434731245041,
      0.054108042269945145,
      0.0227227583527565,
      0.07537544518709183,
      0.01800958625972271,
      -0.01791594736278057,
      -0.0005690348334610462,
      -0.03127099946141243,
      0.027376405894756317,
      0.0030288209673017263,
      -0.02610078454017639,
      -0.0049219331704080105,
      0.008142885752022266,
      0.040845274925231934,
      0.030258983373641968,
      0.036560580134391785,
      0.02899489551782608,
      -0.019162479788064957,
      0.03721296042203903,
      -0.007281596306711435,
      -0.12195861339569092,
      -0.06626588106155396,
      -0.025710860267281532,
      0.008728385902941227,
      -0.0137059036642313,
      -0.012576431967318058,
      -0.05541978403925896,
      0.10665988177061081,
      0.020191045477986336,
      -0.06177997589111328,
      -0.050654858350753784,
      0.0363081656396389,
      -0.021770134568214417,
      -0.05959010124206543,
      -0.09205008298158646,
      0.007386811543256044,
      0.07471242547035217,
      0.05150468647480011,
      -0.009148904122412205,
      0.007734066341072321,
      -0.04494915530085564,
      -0.014734726399183273,
      -0.04088257625699043,
      0.04684487357735634,
      0.04819886013865471,
      -0.0004976665368303657,
      -0.02397165820002556,
      0.11923141032457352,
      -0.030956197530031204,
      -0.0015851898351684213,
      -0.06938271969556808,
      0.009668146260082722,
      0.09830132871866226,
      0.007735422812402248,
      0.08147832006216049,
      0.0172882117331028,
      -0.09250743687152863,
      -0.036430083215236664,
      -0.03207826241850853,
      -0.01823239214718342,
      0.043428461998701096,
      -0.06043172627687454,
      0.0637749657034874,
      -0.044755786657333374,
      0.02102462574839592,
      0.03086014837026596,
      0.06175439432263374,
      0.045585520565509796,
      -0.03848704323172569,
      -0.005542486906051636,
      -0.04967344179749489,
      -0.02479594759643078,
      0.03610404208302498,
      -0.03619519993662834,
      -0.01966729387640953,
      0.00299077364616096,
      -0.10850607603788376,
      0.013752272352576256,
      -0.03628314286470413,
      -0.04625824838876724,
      0.012703103013336658,
      0.004015489015728235,
      0.04415438324213028,
      0.06396737694740295,
      -0.05139009281992912,
      -0.10118170082569122,
      0.007807685527950525,
      0.01303059607744217,
      -0.03595958277583122,
      -0.026549838483333588,
      0.007785400841385126,
      0.06796623766422272,
      0.006840552669018507,
      0.07908949255943298,
      0.03284258767962456,
      0.06108947470784187,
      0.041533030569553375,
      0.037804123014211655,
      0.028434908017516136,
      -0.03544001653790474,
      0.09058479964733124,
      -0.036553166806697845,
      -0.04638124629855156,
      0.015260355547070503,
      0.01870126836001873,
      -0.010716487653553486,
      0.007093339692801237,
      0.01919499598443508,
      0.00557051133364439,
      0.025521526113152504,
      0.0034524984657764435,
      0.006264828145503998,
      0.003660863498225808,
      0.02692723274230957,
      -0.03778630867600441,
      0.003038971684873104,
      0.018637582659721375,
      -0.040817856788635254,
      -0.0007078201742842793,
      -0.01621318981051445,
      -0.03430933505296707,
      0.049604009836912155,
      -0.010480674915015697,
      -0.005014700815081596,
      0.03482534736394882,
      -0.011887585744261742,
      0.0417296327650547,
      -0.058319542557001114,
      0.022039474919438362,
      -0.05198851227760315,
      -0.006279570050537586,
      0.0027110630180686712,
      -0.03416441008448601,
      0.007409053388983011,
      0.06851784884929657,
      -0.01688283309340477,
      -0.018517347052693367,
      0.023873042315244675,
      0.04618643596768379,
      0.024728188291192055,
      -0.039595067501068115,
      0.015623493120074272,
      -0.013628775253891945,
      0.024820812046527863,
      -0.021673699840903282,
      -0.052570801228284836,
      -0.014233316294848919,
      -0.10461799055337906,
      -0.039388950914144516,
      0.01265665888786316,
      -0.021383903920650482,
      0.041432447731494904,
      -0.0011528062168508768,
      -0.062342457473278046,
      0.001607004669494927,
      0.05159575492143631,
      0.021814890205860138,
      0.013094007037580013,
      0.014862586744129658,
      -0.06695472449064255,
      -0.06444503366947174,
      -0.01561750378459692,
      0.011070282198488712,
      -0.008219392038881779,
      -0.0029224262107163668,
      0.07516564428806305,
      -0.06525096297264099,
      -0.037888091057538986,
      0.02312372252345085,
      0.0218479186296463,
      -0.004583831410855055,
      0.026578601449728012,
      0.033748600631952286,
      0.050153736025094986,
      0.011444397270679474,
      -0.019281934946775436,
      0.11400976032018661,
      -0.035388026386499405,
      0.018641315400600433,
      0.00749307731166482,
      0.03408951684832573,
      -0.049283698201179504,
      0.02379339188337326,
      0.0030714464373886585,
      -0.002280673012137413,
      -0.006432648748159409,
      0.04750999063253403,
      -0.05127973482012749,
      0.017902955412864685,
      -0.002268908079713583,
      0.036860134452581406,
      0.0337374247610569,
      0.011379986070096493,
      -0.03812704607844353,
      0.006250057369470596,
      -0.08470258116722107,
      0.03907160460948944,
      -0.03287794068455696,
      0.058130912482738495,
      0.013144814409315586,
      0.0030452879145741463,
      -0.022915208712220192,
      0.034085310995578766,
      0.027872556820511818,
      -0.010941702872514725,
      -0.05089147016406059,
      -0.026773089542984962,
      0.08412160724401474,
      -0.013780424371361732,
      -0.05128752440214157,
      0.017385082319378853,
      -0.005764367524534464,
      -0.07625161111354828,
      0.022623326629400253,
      0.05257067084312439,
      0.024201389402151108,
      -0.041473764926195145,
      -0.008891640231013298,
      -0.02066410891711712,
      -0.03555141016840935,
      0.05572432652115822,
      -0.05264008417725563,
      -0.03686124086380005,
      0.056915007531642914,
      0.019185498356819153,
      0.0007809629896655679,
      -0.031851816922426224,
      0.011559040285646915,
      -0.06941652297973633,
      -0.06820227205753326,
      -0.0065686386078596115,
      0.026685234159231186,
      -0.0007241902058012784,
      0.047621678560972214,
      -0.016353033483028412,
      -0.02666250616312027,
      0.004325570538640022,
      -0.019541896879673004,
      -0.04204382374882698,
      0.057566531002521515,
      0.03397022560238838,
      0.026720767840743065,
      -0.04407203942537308,
      0.022453682497143745,
      0.040901511907577515,
      0.024621296674013138,
      -0.0015544710913673043,
      -0.029037728905677795,
      0.03674120083451271,
      -0.10415008664131165,
      -0.07330761104822159,
      0.006055797450244427,
      0.02666306681931019,
      0.06747926026582718,
      0.004077897407114506,
      -0.0003343244025018066,
      -0.041304171085357666,
      -0.013770249672234058,
      -0.08047784864902496,
      -0.042941752821207047,
      0.02961488626897335,
      0.0034822244197130203,
      0.01949133723974228,
      0.030420435592532158,
      0.025073951110243797,
      -0.03754262253642082,
      -0.029863163828849792,
      -0.011792088858783245
    ],
    [
      -0.07261551916599274,
      -0.025555064901709557,
      -0.07225070893764496,
      0.003150467062368989,
      -0.00011623775935731828,
      0.02107812836766243,
      0.009840447455644608,
      0.0981309786438942,
      0.04814738780260086,
      0.027681957930326462,
      0.037335481494665146,
      -0.02062940038740635,
      0.015026998706161976,
      0.0038937777280807495,
      0.0028950886335223913,
      0.02476830966770649,
      -0.033439185470342636,
      0.009974128566682339,
      0.0792405977845192,
      0.00034887506626546383,
      -0.05993110314011574,
      -0.007910668849945068,
      -0.003245850093662739,
      0.000864218280185014,
      0.102852463722229,
      0.02240998111665249,
      -0.006525289732962847,
      0.049468375742435455,
      -0.033731456845998764,
      0.009893708862364292,
      -0.019219180569052696,
      -0.04045379161834717,
      0.017173124477267265,
      0.015500446781516075,
      -0.017798569053411484,
      -0.06395088136196136,
      -0.010871151462197304,
      -0.08931244909763336,
      -0.06549295783042908,
      0.017100807279348373,
      -0.0075957272201776505,
      0.04240245744585991,
      -0.006629119627177715,
      0.013340924866497517,
      -0.005246980115771294,
      -0.057980168610811234,
      0.007596271112561226,
      0.05373663455247879,
      -0.06882631778717041,
      0.02285921759903431,
      0.06688643991947174,
      -0.050762519240379333,
      -0.04191601648926735,
      0.0033668281976133585,
      -0.02014108933508396,
      0.04419034719467163,
      -0.09106668829917908,
      -0.011092353612184525,
      -0.03609476983547211,
      0.06771992146968842,
      0.06672072410583496,
      0.025102440267801285,
      0.007816688157618046,
      -0.0017148888437077403,
      0.019075559452176094,
      -0.07028955966234207,
      -0.009262221865355968,
      -0.0256301611661911,
      0.003055597422644496,
      -0.021170184016227722,
      -0.05766252800822258,
      -0.008775780908763409,
      -0.03959622234106064,
      0.04536689817905426,
      -0.0010692982468754053,
      -0.012240305542945862,
      -0.041107624769210815,
      -0.030314678326249123,
      -0.00029528498998843133,
      0.049409545958042145,
      -0.07653026282787323,
      0.06461811065673828,
      0.02645830623805523,
      0.05191182717680931,
      0.0036452983040362597,
      0.03726202994585037,
      -0.1305692195892334,
      0.005621540360152721,
      0.0034667178988456726,
      -0.061661411076784134,
      -0.004781755153089762,
      0.07947389036417007,
      -0.011199027299880981,
      0.05793823301792145,
      0.11328504234552383,
      -6.931935786269605e-05,
      -0.0032139953691512346,
      -0.04525463283061981,
      0.03137104585766792,
      0.0016952446894720197,
      -0.0017415494658052921,
      -0.10901492834091187,
      -0.048878442496061325,
      0.07982270419597626,
      0.0627463161945343,
      0.007314309477806091,
      0.009119071997702122,
      -0.025637567043304443,
      -0.055895838886499405,
      -0.004980162251740694,
      0.014571783132851124,
      -0.015756119042634964,
      -0.0647386759519577,
      -0.026281701400876045,
      -0.04692148044705391,
      0.01936744898557663,
      -0.023349503055214882,
      0.06872627884149551,
      0.08226482570171356,
      0.005182440858334303,
      0.10227866470813751,
      -0.017589757218956947,
      0.0207306407392025,
      -0.016547948122024536,
      -0.056427568197250366,
      0.0757506787776947,
      0.013873821124434471,
      0.0018597652669996023,
      -0.015470774844288826,
      0.06411377340555191,
      0.017386579886078835,
      0.056437842547893524,
      -0.03987738490104675,
      -0.007337257731705904,
      -0.056635428220033646,
      0.03284236043691635,
      -0.0028171564918011427,
      0.04108942300081253,
      0.007520542945712805,
      -0.06998539716005325,
      0.023664630949497223,
      0.004950929433107376,
      0.010995364747941494,
      0.12801966071128845,
      0.05074680596590042,
      -0.0281226709485054,
      0.06113276258111,
      0.06362489610910416,
      0.012078192085027695,
      0.06026984378695488,
      0.052398260682821274,
      0.004885901231318712,
      0.050964243710041046,
      0.04661634564399719,
      0.03207581117749214,
      -0.012710001319646835,
      -0.007659980095922947,
      -0.01263921894133091,
      0.010059070773422718,
      0.008155194111168385,
      0.057715799659490585,
      0.005277134478092194,
      0.015996107831597328,
      -0.03610815480351448,
      -0.05977074056863785,
      0.021927189081907272,
      0.025994356721639633,
      -0.08616402000188828,
      -0.039130404591560364,
      -0.006760755088180304,
      0.03780185803771019,
      -0.05286196619272232,
      0.013391072861850262,
      -0.05507286638021469,
      -0.057818640023469925,
      0.03308269754052162,
      -0.040432948619127274,
      -0.04530400037765503,
      -0.0671592578291893,
      0.012994788587093353,
      -0.023648519068956375,
      0.01350528746843338,
      -0.021323272958397865,
      0.032427191734313965,
      -0.008930458687245846,
      0.032600272446870804,
      0.0013234561774879694,
      0.02451895736157894,
      -0.07933537662029266,
      -0.035444941371679306,
      -0.07159380614757538,
      -0.033769089728593826,
      -0.0028541646897792816,
      -0.03475228697061539,
      -0.003520919708535075,
      -0.01973406784236431,
      0.012472658418118954,
      -0.03960327059030533,
      0.06561180204153061,
      -0.05543218180537224,
      0.07064266502857208,
      0.06805656105279922,
      0.03099624067544937,
      0.0004106300475541502,
      0.048100244253873825,
      -0.02355007641017437,
      0.04448248818516731,
      -0.00896198395639658,
      -0.01045786403119564,
      -0.05183428153395653,
      0.054965075105428696,
      -0.09049024432897568,
      0.021479563787579536,
      0.03351587429642677,
      0.009955110028386116,
      -0.09123192727565765,
      0.01752667874097824,
      0.0232037752866745,
      0.06771743297576904,
      -0.020616816356778145,
      0.05129166692495346,
      0.0026724799536168575,
      -0.017847556620836258,
      0.047285549342632294,
      0.004820763133466244,
      -0.01171401422470808,
      -0.03209022060036659,
      -0.024381522089242935,
      0.008246776647865772,
      -0.08984317630529404,
      -0.005649389699101448,
      0.0413355827331543,
      0.021362649276852608,
      0.045244134962558746,
      0.013504290021955967,
      -0.04812289774417877,
      -0.12577563524246216,
      -0.013126782141625881,
      0.019720664247870445,
      -0.04037804901599884,
      0.01366399321705103,
      0.03061620518565178,
      -0.029639532789587975,
      0.09360761195421219,
      0.0028139196801930666,
      -0.051648642867803574,
      0.04189632087945938,
      0.00918489322066307,
      -0.057772453874349594,
      0.015088800340890884,
      0.033786591142416,
      0.0862070769071579,
      0.014643940143287182,
      0.014168145135045052,
      -0.007180451415479183,
      -0.008433250710368156,
      -0.006209330167621374,
      -0.0061850715428590775,
      0.006123678758740425,
      -0.024903595447540283,
      0.05094698816537857,
      0.012309922836720943,
      0.01756957173347473,
      0.009982471354305744,
      -0.024882446974515915,
      0.05690554529428482,
      0.08683592826128006,
      0.051143813878297806,
      -0.0448220893740654,
      0.02924535982310772,
      -0.05875431001186371,
      0.00860989186912775,
      -0.01497674360871315,
      -0.020367052406072617,
      0.04183750972151756,
      -0.038490403443574905,
      -0.13197676837444305,
      0.020531069487333298,
      0.05476481840014458,
      0.012284616008400917,
      -0.024597376585006714,
      0.0031062529888004065,
      0.02338356524705887,
      0.019626514986157417,
      0.06361674517393112,
      -0.0008204223704524338,
      -0.08362895250320435,
      -0.022453011944890022,
      0.0036426077131181955,
      0.04827158525586128,
      0.012478387914597988,
      0.07292445749044418,
      -0.002561023226007819,
      0.04271456599235535,
      0.006718311458826065,
      0.10626475512981415,
      0.004833539016544819,
      -0.05143499746918678,
      0.06209871917963028,
      0.011277573183178902,
      -0.026921171694993973,
      0.04921548068523407,
      -0.01542738638818264,
      -0.085904061794281,
      0.12399919331073761,
      0.008024856448173523,
      -0.023749537765979767,
      -0.03908621892333031,
      0.049740828573703766,
      0.06618014723062515,
      0.011168836615979671,
      -0.011759127490222454,
      0.07131724804639816,
      0.0003865906910505146,
      -0.02759038843214512,
      0.06336557865142822,
      0.01498081162571907,
      -0.005819291807711124,
      -0.058343127369880676,
      0.00913122110068798,
      0.021812720224261284,
      0.03871268406510353,
      0.0015313116600736976,
      -0.0011978772236034274,
      -0.11052890866994858,
      -0.06368526071310043,
      -0.010292740538716316,
      0.04066015034914017,
      -0.04032403603196144,
      -0.005661599338054657,
      0.03911321982741356,
      -0.013924685306847095,
      -0.03719424083828926,
      -0.03148866444826126,
      -0.03831706568598747,
      -0.006895581725984812,
      -0.08854907006025314,
      0.01161925122141838,
      0.0665845051407814,
      0.04333759844303131,
      -0.025262273848056793,
      -0.02038106508553028,
      -0.055330824106931686,
      -0.07836092263460159,
      -0.06540809571743011,
      -0.030115783214569092,
      0.01884009875357151,
      0.08480674773454666,
      0.006038021761924028,
      -0.017640067264437675,
      -0.022179270163178444,
      -0.0002576859260443598,
      -0.0698448196053505,
      -0.07342756539583206,
      -0.03615164756774902,
      0.04312665015459061,
      -0.025308117270469666,
      0.005996227730065584,
      0.03950772434473038,
      0.04331418499350548,
      0.029701681807637215,
      0.011068743653595448,
      0.00883153174072504,
      0.003326397854834795,
      -0.015897754579782486,
      0.029329882934689522,
      0.06880823522806168,
      0.04584614932537079,
      -0.02298101782798767,
      -0.008395743556320667,
      0.04196200519800186,
      -0.050106536597013474,
      0.04661225154995918,
      0.04676041007041931,
      -0.0525919571518898,
      0.06549869477748871,
      0.010643117129802704,
      -0.08716759830713272,
      0.018343133851885796,
      -0.08152037858963013,
      -0.06063692644238472,
      0.05974160507321358,
      0.03251803666353226,
      -0.009489709511399269,
      0.031199192628264427,
      0.03714148700237274,
      -0.02876344509422779,
      0.006286946125328541,
      -0.07643336802721024,
      -0.03807178512215614,
      -0.051375918090343475,
      0.09225674718618393,
      0.012575638480484486,
      0.029241103678941727,
      -0.01256162766367197,
      -0.03960063308477402,
      0.04540020972490311,
      -0.05374812334775925,
      -0.016433870419859886,
      -0.0029653459787368774,
      0.026992907747626305,
      -0.05257956683635712,
      -0.02502203732728958,
      -0.06224570423364639,
      0.01808498054742813,
      0.026065867394208908,
      -0.034231074154376984,
      0.03512343391776085,
      -0.0383666455745697,
      -0.010733326897025108,
      -0.006874658167362213,
      0.018361752852797508,
      0.003660539397969842,
      -0.021239526569843292,
      -0.053031887859106064,
      0.015277364291250706,
      0.01097495760768652,
      -0.003843138925731182,
      0.12345629930496216,
      -0.024223411455750465,
      -0.015834597870707512,
      -0.04973811283707619,
      -0.020881563425064087,
      0.00033095781691372395,
      -0.05368325114250183,
      0.02295876294374466,
      -0.05733419209718704,
      -0.03722991421818733,
      0.03697214275598526,
      -0.04182560369372368,
      0.04746443033218384,
      -0.0012571748811751604,
      -0.11743457615375519,
      -0.02156348153948784,
      0.029835514724254608,
      -0.03352890536189079,
      0.04036414995789528,
      0.08812247216701508,
      -0.00024790980387479067,
      -0.12079598754644394,
      0.025292251259088516,
      -0.00041802646592259407,
      0.03069337084889412,
      0.08313046395778656,
      0.04933115467429161,
      -0.005861201323568821,
      -0.0748150572180748,
      0.0292959064245224,
      0.010174130089581013,
      0.04619967192411423,
      -0.014182871207594872,
      0.0009030316723510623,
      -0.08949730545282364,
      -0.059415560215711594,
      0.002372194780036807,
      0.03265325352549553,
      -0.06018085405230522,
      -0.044340163469314575,
      -0.008311943151056767,
      -0.056340720504522324,
      -0.002302889944985509,
      -0.006809176877140999,
      0.012933528050780296,
      0.019193360581994057,
      -0.05631966516375542,
      0.10691841691732407,
      -0.019888216629624367,
      -0.07570107281208038,
      0.02764643356204033,
      0.04077054560184479,
      -0.06140412762761116,
      -0.036672040820121765,
      -0.027018489316105843,
      -0.051338162273168564,
      0.04960785433650017,
      -0.026220306754112244,
      -0.030389035120606422,
      0.02926572412252426,
      -0.04629632085561752,
      -0.05504719167947769,
      -0.04601744934916496,
      0.05611468479037285,
      0.042263589799404144,
      0.025146683678030968,
      -0.03114745393395424,
      0.02789785899221897,
      -0.04835755378007889,
      0.051956940442323685,
      -0.09623979777097702,
      -0.09259078651666641,
      -0.03393341973423958,
      -0.0386405810713768,
      -0.04496391490101814,
      -0.018298683688044548,
      -0.007938915863633156,
      -0.02565186098217964,
      -0.022867681458592415,
      0.01372995600104332,
      -0.032978832721710205,
      -0.034534256905317307,
      -0.07031465321779251,
      0.06304808706045151,
      -0.021324602887034416
    ],
    [
      -0.0793406143784523,
      0.027403222396969795,
      0.04431607201695442,
      -0.05982915684580803,
      -0.032878730446100235,
      -0.007452957797795534,
      -0.005161675158888102,
      0.03307994082570076,
      0.09824300557374954,
      -0.0013797457795590162,
      0.029267417266964912,
      -0.011283560656011105,
      0.015993410721421242,
      0.017466586083173752,
      -0.03329695016145706,
      0.013934065587818623,
      -0.06441502273082733,
      0.020275885239243507,
      -0.021647747606039047,
      -0.018728338181972504,
      0.02081671915948391,
      0.017878640443086624,
      -0.06693175435066223,
      -0.01604272425174713,
      -0.003699552034959197,
      -0.10244235396385193,
      -0.017876550555229187,
      -0.05386422947049141,
      -0.050617970526218414,
      0.0737970694899559,
      -0.00412161136046052,
      -0.06206553801894188,
      0.037097346037626266,
      0.006696076598018408,
      0.0015249578282237053,
      -0.01693558506667614,
      0.07445678114891052,
      0.05002019926905632,
      -0.06916601210832596,
      0.002480659633874893,
      0.034051816910505295,
      0.007675337139517069,
      -0.008725040592253208,
      -0.04383067041635513,
      0.05715090036392212,
      -0.019796859472990036,
      0.11852829158306122,
      -0.0671016126871109,
      -0.03721708059310913,
      0.010411050170660019,
      0.0380970798432827,
      -0.04776465892791748,
      -0.07508440315723419,
      0.03210892155766487,
      0.05682503804564476,
      -0.023276668041944504,
      -0.03418344259262085,
      0.03643680363893509,
      0.009438300505280495,
      0.0118948295712471,
      0.01326646190136671,
      -0.028074752539396286,
      -0.030219702050089836,
      0.0516866073012352,
      0.010423203930258751,
      -0.05729837715625763,
      -0.022471655160188675,
      0.05265861004590988,
      0.03746744990348816,
      -0.022291099652647972,
      -0.03872348740696907,
      -0.03221943601965904,
      0.006009028758853674,
      0.060869909822940826,
      0.0039811208844184875,
      -0.10724059492349625,
      0.09016171097755432,
      -0.005845405161380768,
      0.0034397232811897993,
      -0.06704917550086975,
      -0.0743088349699974,
      -0.015911215916275978,
      -0.060878023505210876,
      -0.013845310546457767,
      -0.05159900337457657,
      0.021746927872300148,
      0.022048525512218475,
      -0.07813046127557755,
      -0.008960017003118992,
      -0.05458786338567734,
      0.0005837046192027628,
      -0.03946015238761902,
      -0.006250031758099794,
      -0.011774389073252678,
      0.055846311151981354,
      -0.029044317081570625,
      0.011782824993133545,
      -0.011608286760747433,
      -0.05057937279343605,
      -0.025921212509274483,
      0.10721061378717422,
      0.0839839056134224,
      0.0046296087093651295,
      -0.0696161761879921,
      -0.061153292655944824,
      -0.030199309810996056,
      -0.02545672468841076,
      -0.0026923641562461853,
      -0.0835350751876831,
      0.006575449835509062,
      0.0021942458115518093,
      0.012832294218242168,
      0.010606496594846249,
      0.004283740650862455,
      -0.07906083762645721,
      0.08571140468120575,
      -0.010983048938214779,
      0.07462655007839203,
      0.02082226052880287,
      0.017442507669329643,
      0.010925130918622017,
      -0.027839967980980873,
      0.06981416046619415,
      0.014884685166180134,
      0.008238601498305798,
      0.010008405894041061,
      -0.03201930597424507,
      -0.044503889977931976,
      0.04379812255501747,
      -0.05636996030807495,
      -0.051326483488082886,
      -0.054416585713624954,
      -0.01998516358435154,
      0.015964316204190254,
      0.08563347160816193,
      0.062484242022037506,
      0.11463073641061783,
      0.023133113980293274,
      0.01102874893695116,
      -0.014052942395210266,
      -0.038550276309251785,
      -0.009642871096730232,
      0.0047587184235453606,
      0.01863471046090126,
      -0.08767231553792953,
      0.0707068145275116,
      -0.03590022027492523,
      0.029447223991155624,
      -0.0070867473259568214,
      0.006074222736060619,
      -0.019808245822787285,
      -0.06627592444419861,
      0.006156849209219217,
      -0.08866926282644272,
      0.039342932403087616,
      0.006215317640453577,
      0.021427996456623077,
      0.04732852429151535,
      -0.02124769613146782,
      -0.02156364917755127,
      -0.0829169973731041,
      0.015341589227318764,
      -0.0766967386007309,
      -0.014278036542236805,
      -0.05464552715420723,
      -0.04074547812342644,
      -0.014265009202063084,
      0.029372889548540115,
      -0.05484926700592041,
      0.011975063942372799,
      -0.011478622443974018,
      0.06454388052225113,
      0.0141246123239398,
      -0.04586605727672577,
      -0.0363064669072628,
      -0.04326130449771881,
      -0.051061395555734634,
      0.07632715255022049,
      -0.027026651427149773,
      -0.03354891389608383,
      0.07486637681722641,
      -0.0731310099363327,
      0.05620347335934639,
      0.025467438623309135,
      0.04484032467007637,
      0.014012322761118412,
      -0.028938105329871178,
      -0.018309427425265312,
      0.044049739837646484,
      0.04521023854613304,
      -0.018179958686232567,
      0.03457700088620186,
      -0.007624551188200712,
      0.0702897161245346,
      0.032101165503263474,
      -0.061845388263463974,
      -0.002744880272075534,
      0.013864042237401009,
      0.051005858927965164,
      -0.05718573182821274,
      0.01477632112801075,
      -0.05782867595553398,
      0.037508103996515274,
      0.11141921579837799,
      -0.03043520636856556,
      0.024936063215136528,
      0.05929183214902878,
      0.04746955260634422,
      -0.009602058678865433,
      -0.09730998426675797,
      -0.05707455798983574,
      0.03815184161067009,
      0.029303958639502525,
      -0.04547348991036415,
      0.00020732387201860547,
      0.03367563337087631,
      0.007007548585534096,
      0.055571507662534714,
      0.06031676381826401,
      0.00243493658490479,
      -0.029423808678984642,
      -0.07279618829488754,
      0.015109474770724773,
      -0.07138315588235855,
      -0.06833536177873611,
      -0.025470132008194923,
      0.018396830186247826,
      0.017525052651762962,
      -0.012119126506149769,
      0.043176472187042236,
      0.060743000358343124,
      -0.01760072633624077,
      0.05500784143805504,
      0.04113058000802994,
      0.025109466165304184,
      0.007181764114648104,
      -0.02098647505044937,
      0.057854361832141876,
      -0.03661694377660751,
      0.004214347805827856,
      -0.021209392696619034,
      0.011082541197538376,
      0.005003123078495264,
      -0.009944509714841843,
      0.03349429368972778,
      -0.021530868485569954,
      0.00568942679092288,
      -0.03425808250904083,
      0.01780085824429989,
      -0.01899607479572296,
      0.004783565644174814,
      0.02423628605902195,
      -0.04299136623740196,
      0.016114363446831703,
      0.03950928524136543,
      0.0556986927986145,
      0.050987500697374344,
      -0.02758876048028469,
      0.008240969851613045,
      0.048476412892341614,
      0.01781224086880684,
      -0.015575969591736794,
      -0.07288342714309692,
      0.039646636694669724,
      0.000681829173117876,
      0.018755903467535973,
      0.01804661564528942,
      -0.016114672645926476,
      -0.07186155021190643,
      0.03926960006356239,
      -0.04669139161705971,
      0.022677738219499588,
      -0.012281375005841255,
      -0.032894086092710495,
      0.020295871421694756,
      0.08191452920436859,
      0.030060209333896637,
      -0.0602385476231575,
      -0.012593480758368969,
      0.035941991955041885,
      -0.019710704684257507,
      0.028660330921411514,
      0.04286997765302658,
      0.0009361039847135544,
      0.01905118301510811,
      0.014685223810374737,
      -0.0030823845881968737,
      -0.07825646549463272,
      0.020189303904771805,
      0.0679863914847374,
      -0.023214755579829216,
      -0.02311687543988228,
      -0.010111150331795216,
      0.04873479902744293,
      -0.0065511842258274555,
      -0.023096883669495583,
      0.01603778265416622,
      0.01982475072145462,
      -0.009955823421478271,
      -0.02529062144458294,
      0.02543000504374504,
      -0.04172174632549286,
      0.0305989570915699,
      0.04598573222756386,
      -0.05894721671938896,
      0.01054809708148241,
      0.05075064301490784,
      0.037549905478954315,
      -0.021639861166477203,
      -0.012955788522958755,
      -0.025556812062859535,
      0.06865715235471725,
      0.02900405414402485,
      0.037575237452983856,
      0.07223531603813171,
      -0.04341091960668564,
      -0.01805071532726288,
      0.009164263494312763,
      -0.0051161921583116055,
      0.0006854574894532561,
      -0.02695312164723873,
      0.026153255254030228,
      0.03158284351229668,
      -0.037749167531728745,
      -0.04246705770492554,
      0.1042870357632637,
      -0.002872278681024909,
      0.009646784514188766,
      0.07278511673212051,
      0.047688405960798264,
      0.06470261514186859,
      0.03067869320511818,
      0.013254799880087376,
      0.002608322072774172,
      -0.027638744562864304,
      -0.0678001195192337,
      0.007998104207217693,
      -0.010616674087941647,
      0.02477155439555645,
      -0.013473568484187126,
      -0.008121083490550518,
      0.0032249093055725098,
      0.050524767488241196,
      -0.017219044268131256,
      -0.010054890997707844,
      -0.04343494772911072,
      0.045988477766513824,
      0.03769784793257713,
      0.03633718937635422,
      0.02551507018506527,
      0.07179141044616699,
      -0.02244439348578453,
      0.06529305130243301,
      0.0765291228890419,
      0.013077814131975174,
      0.019339395686984062,
      0.014685191214084625,
      0.07631706446409225,
      -0.07605799287557602,
      0.04806391894817352,
      0.008634937927126884,
      -0.0021817530505359173,
      0.10742177814245224,
      -0.03108047880232334,
      0.01458058413118124,
      -0.004179664421826601,
      -0.002196125453338027,
      0.014353037811815739,
      0.046729978173971176,
      -0.06586045771837234,
      0.040786262601614,
      0.03567919135093689,
      0.059730157256126404,
      0.01513894833624363,
      0.07337695360183716,
      0.022384637966752052,
      0.015240325592458248,
      -0.03915320336818695,
      -0.06152372434735298,
      0.022938698530197144,
      0.03816043213009834,
      0.03998693451285362,
      -0.050834596157073975,
      0.008101346902549267,
      -0.030630439519882202,
      0.0028984718956053257,
      -0.032560329884290695,
      -0.09072811901569366,
      -0.04180891066789627,
      -0.08923987299203873,
      -0.033398717641830444,
      0.05496622994542122,
      -0.010936040431261063,
      -0.025135856121778488,
      0.032425519078969955,
      0.020996177569031715,
      0.08042912930250168,
      0.07491257786750793,
      -0.017290839925408363,
      -0.05501583218574524,
      -0.04242529347538948,
      0.020140670239925385,
      0.05740537494421005,
      -0.006456255912780762,
      -0.0012379337567836046,
      0.12351583689451218,
      0.03335261344909668,
      0.013941184617578983,
      0.003069693921133876,
      -0.06679162383079529,
      -0.027753842994570732,
      0.06157178431749344,
      -0.035321708768606186,
      -0.024910902604460716,
      0.05337366834282875,
      0.028884826228022575,
      -0.062095556408166885,
      0.047740668058395386,
      -0.012873943895101547,
      -0.00591295026242733,
      -0.03598910570144653,
      -0.02840808965265751,
      0.01535733975470066,
      -0.037331342697143555,
      0.016804123297333717,
      0.05942688137292862,
      0.01584210805594921,
      0.012723607011139393,
      -0.006214038003236055,
      0.06360993534326553,
      0.06261543929576874,
      -0.04974783957004547,
      -0.07509983330965042,
      0.07289938628673553,
      0.035632938146591187,
      0.004981406964361668,
      -0.03526163101196289,
      -0.08927354216575623,
      0.025894174352288246,
      -0.024012001231312752,
      0.017423633486032486,
      0.032827142626047134,
      0.053189098834991455,
      -0.02159915864467621,
      -0.052322469651699066,
      -0.032771997153759,
      -0.048841483891010284,
      -0.030563173815608025,
      -0.07648643851280212,
      -0.01737413927912712,
      0.058748695999383926,
      0.011441483162343502,
      -0.07097866386175156,
      0.035358451306819916,
      -0.015501611866056919,
      0.06702471524477005,
      0.029114559292793274,
      0.07620889693498611,
      0.06944752484560013,
      0.05748942121863365,
      -0.02478761598467827,
      0.06558318436145782,
      -0.0026573576033115387,
      -0.018795397132635117,
      -0.05480195954442024,
      -0.02867385372519493,
      -0.012698343954980373,
      0.044619087129831314,
      -0.049883659929037094,
      -0.022219084203243256,
      0.03695789352059364,
      -0.03860336169600487,
      -0.0018551396206021309,
      -0.08044407516717911,
      0.022524578496813774,
      -0.029127757996320724,
      0.018724391236901283,
      -0.061552416533231735,
      -0.04255693033337593,
      -0.006405309773981571,
      -0.05846261978149414,
      -0.0067357756197452545,
      -0.05459332838654518,
      -0.055815160274505615,
      0.02390298992395401,
      -0.06297950446605682,
      -0.03699066862463951,
      -0.03138871490955353,
      -0.07714870572090149,
      -0.021913858130574226,
      0.08218757063150406,
      -0.009888386353850365,
      0.007595025468617678,
      0.04060759395360947,
      0.024058828130364418,
      -0.048951659351587296,
      -0.057349056005477905,
      0.045249007642269135,
      0.0332944393157959,
      -0.03387552127242088,
      0.045775577425956726,
      -0.012579341419041157,
      -0.019998760893940926
    ],
    [
      0.0270584374666214,
      -0.044762931764125824,
      -0.0002700342156458646,
      -0.049393169581890106,
      -0.0030222951900213957,
      0.02930706925690174,
      0.0017754967557266355,
      -0.02658582106232643,
      0.05704127624630928,
      0.05092721804976463,
      -0.00048119513667188585,
      -0.009052139706909657,
      0.034919481724500656,
      0.014004083350300789,
      -0.019014762714505196,
      -0.0021222643554210663,
      -0.017082124948501587,
      0.002316209953278303,
      -0.0062487246468663216,
      0.029061773791909218,
      0.04824884235858917,
      0.00862814299762249,
      -0.06457718461751938,
      0.00048318953486159444,
      0.03806905075907707,
      -0.02460392564535141,
      0.059225086122751236,
      0.01445029303431511,
      0.015261891297996044,
      0.05651170015335083,
      -0.03590485453605652,
      0.04142916202545166,
      -0.04013281688094139,
      0.04127226397395134,
      -0.07706926017999649,
      0.001391677069477737,
      -0.009711507707834244,
      -0.000659324461594224,
      -0.023389840498566628,
      -0.011790106073021889,
      -0.03021004982292652,
      -0.06119934841990471,
      0.04031721130013466,
      0.028828226029872894,
      -0.05317436531186104,
      0.008938333950936794,
      0.003301861695945263,
      -0.0399346724152565,
      0.058301862329244614,
      0.03355548530817032,
      -0.0011232806136831641,
      0.006439377553761005,
      -0.026300009340047836,
      -0.00811084359884262,
      -0.05350368469953537,
      0.142985999584198,
      -0.01509828306734562,
      -0.0010544050019234419,
      -0.07372704893350601,
      0.0012086615897715092,
      0.027158081531524658,
      0.012429485097527504,
      0.0821344181895256,
      0.0015726833371445537,
      -0.036170653998851776,
      0.008718266151845455,
      0.018934151157736778,
      0.0880812406539917,
      -0.03564181178808212,
      -0.009576249867677689,
      -0.04349273815751076,
      0.011723030358552933,
      -0.08233951032161713,
      -0.009422491304576397,
      -0.06379173696041107,
      0.028623629361391068,
      0.004873121157288551,
      -0.07950909435749054,
      0.016745351254940033,
      0.07721425592899323,
      -0.050922613590955734,
      0.05051666870713234,
      -0.017888886854052544,
      0.061309970915317535,
      -0.06513627618551254,
      0.024565836414694786,
      0.016557712107896805,
      -0.045700836926698685,
      0.05009882524609566,
      -0.009071686305105686,
      0.07261384278535843,
      -0.036307722330093384,
      -0.05478441342711449,
      -0.03240713104605675,
      0.041354335844516754,
      0.09436692297458649,
      0.03254253417253494,
      0.050716061145067215,
      -0.09741944819688797,
      -0.02621450275182724,
      0.03901989385485649,
      -0.015150673687458038,
      0.09047190845012665,
      0.03711282089352608,
      -0.004215756431221962,
      -0.06186876446008682,
      0.03867965191602707,
      0.027318423613905907,
      0.009272459894418716,
      0.009919914416968822,
      0.0787176638841629,
      -0.0354793407022953,
      -0.06313250958919525,
      0.012220829725265503,
      -0.06587578356266022,
      -0.12183360010385513,
      -0.07503025233745575,
      0.0009595535229891539,
      -0.014935734681785107,
      0.08865085244178772,
      0.06634655594825745,
      0.003154618199914694,
      0.06164674088358879,
      0.03163560852408409,
      -0.06526219844818115,
      -0.01168662216514349,
      0.01972002536058426,
      0.07115581631660461,
      0.023273754864931107,
      0.04035221040248871,
      -0.045224059373140335,
      0.06772833317518234,
      0.034698136150836945,
      0.1151217520236969,
      -0.008855794556438923,
      -0.013871537521481514,
      -0.033576615154743195,
      -0.08227023482322693,
      0.0013365029590204358,
      -0.06010286509990692,
      0.0381467230618,
      0.03794650733470917,
      -0.007362780626863241,
      0.0885109081864357,
      0.016659099608659744,
      -0.018863866105675697,
      0.06162068992853165,
      0.03922149911522865,
      -0.015474981628358364,
      -0.06370435655117035,
      -0.05393043905496597,
      -0.07904869318008423,
      -0.040007561445236206,
      0.035242002457380295,
      0.06638181954622269,
      -0.01768398843705654,
      -0.01423974335193634,
      0.022689415141940117,
      -0.07263967394828796,
      -0.03549050912261009,
      -0.06549640744924545,
      0.04854285344481468,
      -0.023207390680909157,
      0.03408155217766762,
      -0.038041528314352036,
      0.0777842104434967,
      -0.0298296045511961,
      -0.010402745567262173,
      0.007949989289045334,
      0.020226912572979927,
      -0.05414639785885811,
      -0.07050370424985886,
      0.01946626789867878,
      -0.051823075860738754,
      0.03092641569674015,
      0.033264778554439545,
      0.009049118496477604,
      0.06564132869243622,
      0.035228218883275986,
      -0.028952395543456078,
      -0.07378799468278885,
      0.020080944523215294,
      -0.009551960043609142,
      0.05172662064433098,
      -0.007048794534057379,
      0.002971555572003126,
      0.028044110164046288,
      -0.02165886014699936,
      0.023375216871500015,
      0.002484160242602229,
      -0.01541539840400219,
      -0.007815561257302761,
      0.02451896481215954,
      -0.01665407605469227,
      0.006333557423204184,
      -0.018381427973508835,
      -0.02710677869617939,
      0.0012066904455423355,
      -0.05494311824440956,
      -0.020522935315966606,
      -0.012026943266391754,
      0.004144618753343821,
      -0.04279974848031998,
      0.011358675546944141,
      -0.09416816383600235,
      0.0013578090583905578,
      -0.0014221224701032043,
      -0.11393316835165024,
      0.03203965723514557,
      -0.016211774200201035,
      0.013400605879724026,
      0.02184019237756729,
      0.03627675771713257,
      0.009482736699283123,
      0.016171762719750404,
      0.038565728813409805,
      -0.03869904950261116,
      0.05755637586116791,
      -0.04402122274041176,
      0.025220543146133423,
      0.020588982850313187,
      -0.0018418255494907498,
      -0.005114136729389429,
      0.06399610638618469,
      0.057262275367975235,
      -0.030862415209412575,
      0.01958865486085415,
      -0.002472436986863613,
      0.00047128667938522995,
      -0.12740884721279144,
      0.04630792886018753,
      0.031012190505862236,
      -0.03698097914457321,
      -0.006495446432381868,
      -0.005265174899250269,
      0.02780766785144806,
      -0.02770453505218029,
      0.01473238691687584,
      -0.029374582692980766,
      -0.061899904161691666,
      0.011444403789937496,
      0.002335430821403861,
      0.03664131835103035,
      0.006483021192252636,
      0.002926695393398404,
      0.021224649623036385,
      0.04124293848872185,
      0.01895790733397007,
      -0.05157504975795746,
      -0.010434175841510296,
      0.029691975563764572,
      0.034371763467788696,
      0.00020851010049227625,
      0.045145779848098755,
      -0.0851060152053833,
      0.02341410331428051,
      0.013709204271435738,
      0.037129778414964676,
      -0.03150239959359169,
      0.07700681686401367,
      0.0330950990319252,
      -0.0776122584939003,
      -0.01775950938463211,
      0.04267989471554756,
      0.016072461381554604,
      -0.041380058974027634,
      0.00858461856842041,
      0.058124665170907974,
      -0.07850056141614914,
      -0.023226812481880188,
      0.014071579091250896,
      0.0288747139275074,
      0.06283584982156754,
      0.030101655051112175,
      0.048991747200489044,
      -0.09815748780965805,
      0.040006428956985474,
      0.004255873616784811,
      -0.028511542826890945,
      0.020667677745223045,
      0.013376330025494099,
      -0.016617873683571815,
      0.011097234673798084,
      -0.005145959090441465,
      0.010855264030396938,
      0.0025412277318537235,
      0.016830790787935257,
      0.08099482208490372,
      0.006843504495918751,
      -0.005777896847575903,
      -0.06859389692544937,
      -0.06005925312638283,
      0.0011655351845547557,
      0.033935509622097015,
      -0.06829419732093811,
      -0.019717374816536903,
      -0.01297454908490181,
      0.08358678221702576,
      0.013584142550826073,
      0.024152373895049095,
      0.0551302507519722,
      -0.027142956852912903,
      -0.03685230016708374,
      0.01305207796394825,
      0.051591407507658005,
      0.04412044957280159,
      0.008538256399333477,
      -0.011591944843530655,
      -0.058522604405879974,
      -0.03455033898353577,
      0.057342905551195145,
      0.009732257574796677,
      0.04696565493941307,
      0.030161472037434578,
      -0.011793906800448895,
      0.008006629534065723,
      0.032946523278951645,
      -0.03410380333662033,
      -0.021504497155547142,
      0.05483359843492508,
      -0.06206812709569931,
      -0.07600175589323044,
      0.008874591439962387,
      0.036638010293245316,
      0.01726984791457653,
      -0.007472386583685875,
      -0.01810140535235405,
      0.018148312345147133,
      0.036442145705223083,
      -0.04818599298596382,
      -0.0278315506875515,
      -0.05269036442041397,
      -0.005292038433253765,
      0.07260730117559433,
      0.019159561023116112,
      0.05513154715299606,
      -0.0007035352173261344,
      -0.08527830243110657,
      0.0805734246969223,
      -0.07232832163572311,
      0.002341429004445672,
      -0.012041160836815834,
      0.0028930059634149075,
      -0.011271052062511444,
      -0.0025748424232006073,
      -0.02699488401412964,
      0.06917101889848709,
      0.03809954598546028,
      0.018108230084180832,
      -0.011857387609779835,
      0.018946681171655655,
      0.045881371945142746,
      -0.058714378625154495,
      0.062330398708581924,
      -0.015064566396176815,
      -0.03184584900736809,
      -0.0041639553382992744,
      0.030824491754174232,
      -0.03539911285042763,
      -0.09045623242855072,
      -0.008573305793106556,
      0.05460250750184059,
      -0.00862859282642603,
      0.041407812386751175,
      -0.06278037279844284,
      0.024544043466448784,
      -0.0013923731166869402,
      -0.007489147130399942,
      1.5808716852916405e-05,
      0.025636788457632065,
      -0.006404497195035219,
      0.006696339230984449,
      0.05344637483358383,
      -0.027894439175724983,
      0.014213916845619678,
      -0.009543326683342457,
      0.0425306037068367,
      -0.004576250445097685,
      0.018477188423275948,
      -0.043276961892843246,
      -0.0069797458127141,
      0.04188230633735657,
      -0.02673334814608097,
      -0.02678750455379486,
      0.018738653510808945,
      -0.014018986374139786,
      0.004207408055663109,
      0.036993276327848434,
      -0.011104658246040344,
      -0.04583257809281349,
      0.03823979198932648,
      0.019959593191742897,
      -0.009299003519117832,
      -0.0721583217382431,
      -0.07198933511972427,
      0.013020646758377552,
      -0.03617097809910774,
      -0.015638109296560287,
      0.026966765522956848,
      -0.05298244208097458,
      0.02103334292769432,
      -0.018729422241449356,
      -0.09636982530355453,
      0.016567690297961235,
      0.02509222738444805,
      -0.019304394721984863,
      -0.05120184272527695,
      -0.0030167684890329838,
      0.002613623160868883,
      -0.025165047496557236,
      -0.008591558784246445,
      -0.023651547729969025,
      -0.06669305264949799,
      0.012666556984186172,
      -0.017151707783341408,
      -0.046986173838377,
      -0.012639363296329975,
      0.007591712288558483,
      -0.03805737942457199,
      0.06069130823016167,
      -0.014507786370813847,
      -0.011342430487275124,
      -0.03192686289548874,
      -0.01961914263665676,
      -0.033495817333459854,
      0.06888440251350403,
      -0.05456102639436722,
      0.027303535491228104,
      0.0047158170491456985,
      0.033194638788700104,
      -0.023654485121369362,
      -0.02983783185482025,
      0.08106396347284317,
      -0.047818757593631744,
      -0.002268790965899825,
      -0.0785665437579155,
      0.03824862837791443,
      0.008483558893203735,
      -0.012857318855822086,
      -0.008089182898402214,
      -0.06042025238275528,
      0.027048973366618156,
      0.024354685097932816,
      0.08150939643383026,
      -0.04698227345943451,
      -0.06657715141773224,
      -0.014970866031944752,
      0.05633725970983505,
      -0.03199164941906929,
      -0.09741225838661194,
      -0.06734131276607513,
      -0.06200270354747772,
      -0.004018241539597511,
      -0.12299511581659317,
      0.001444957684725523,
      -0.04420243203639984,
      -0.07664603739976883,
      -0.006403262726962566,
      0.0018624583026394248,
      0.05549030378460884,
      -0.024936161935329437,
      -0.01169129740446806,
      0.04873814061284065,
      0.04546409100294113,
      0.011223914101719856,
      0.0414569117128849,
      -0.008625841699540615,
      0.03747428581118584,
      0.012351796962320805,
      -0.020764991641044617,
      0.01777433231472969,
      0.029024280607700348,
      0.05969325453042984,
      -0.031003134325146675,
      -0.03337644785642624,
      -0.0352698490023613,
      0.04502362012863159,
      -0.010453745722770691,
      -0.0022740669082850218,
      0.058980584144592285,
      -0.0028821961022913456,
      0.0009556559380143881,
      0.02505701407790184,
      -0.05347156152129173,
      0.055118393152952194,
      0.03410371020436287,
      -0.06240126118063927,
      0.050836820155382156,
      -0.016660060733556747,
      -0.016309676691889763,
      -0.04361836984753609,
      0.047826386988162994,
      -0.02328556589782238,
      0.036978065967559814,
      0.07829748094081879,
      -0.03422541171312332,
      -0.02595023810863495,
      -0.023745018988847733,
      -0.004373513162136078,
      -0.028435342013835907,
      0.05811075493693352,
      0.03283286467194557,
      -0.03871646896004677
    ],
    [
      -0.015091565437614918,
      -0.03845120966434479,
      -0.09101082384586334,
      -0.018209954723715782,
      0.09430889785289764,
      -0.046367097645998,
      -0.04450048506259918,
      -0.029475446790456772,
      0.025004753842949867,
      0.035057321190834045,
      -0.08499930799007416,
      0.02215140499174595,
      0.01973920688033104,
      0.021523280069231987,
      -0.0189083032310009,
      0.006749656982719898,
      -0.03207797184586525,
      -0.028751632198691368,
      -0.08457356691360474,
      0.013251939788460732,
      -0.04913992062211037,
      0.07504282891750336,
      -0.005627304315567017,
      0.092043936252594,
      -0.06750991195440292,
      -0.05058429017663002,
      -0.08130642026662827,
      -0.008174815215170383,
      -0.04276667535305023,
      -0.05168536305427551,
      0.023465583100914955,
      0.03496737778186798,
      -0.010278858244419098,
      0.010349110700190067,
      0.1063709706068039,
      -0.02737029641866684,
      -0.0671343207359314,
      -0.08653687685728073,
      -0.06433261930942535,
      -0.016287755221128464,
      0.04776424542069435,
      0.0016980278305709362,
      0.05224619433283806,
      -0.03929443284869194,
      0.03852386772632599,
      -0.022057807072997093,
      -0.07998473197221756,
      -0.034226711839437485,
      0.0024819723330438137,
      0.020966093987226486,
      0.014823110774159431,
      -0.025931058451533318,
      -0.036953940987586975,
      -0.030469052493572235,
      0.06253892928361893,
      0.05403547361493111,
      -0.006020969245582819,
      0.07357970625162125,
      -0.01292417198419571,
      0.04065624997019768,
      0.05218338966369629,
      0.0026038880459964275,
      -0.005594175774604082,
      -0.04184257239103317,
      -0.07369089871644974,
      0.019270921126008034,
      -0.08109886199235916,
      -0.02180476300418377,
      -0.03031109645962715,
      0.007380613591521978,
      -0.04936669394373894,
      -0.015935290604829788,
      0.0028807385824620724,
      -0.0369516983628273,
      0.006205655634403229,
      0.030715087428689003,
      -0.0017629250651225448,
      0.04162207990884781,
      -0.025434449315071106,
      -0.016486061736941338,
      -0.03449317067861557,
      0.04160049557685852,
      0.04423792287707329,
      0.00106238410808146,
      0.003030806314200163,
      0.006383328232914209,
      0.04684622958302498,
      0.04806138202548027,
      0.10250169783830643,
      0.061395298689603806,
      0.03388913720846176,
      0.025211088359355927,
      0.008345058187842369,
      0.033612728118896484,
      -0.09485653787851334,
      -0.024764923378825188,
      -0.00635662954300642,
      -0.05348110571503639,
      -0.050316642969846725,
      -0.00484237028285861,
      0.00747723039239645,
      0.07032181322574615,
      0.027765890583395958,
      0.05998858064413071,
      0.0044880700297653675,
      0.01880105957388878,
      0.015745213255286217,
      0.04269016906619072,
      -0.06659810245037079,
      0.07011908292770386,
      -0.04033462330698967,
      -0.031128602102398872,
      -0.01313445158302784,
      0.06145976856350899,
      -0.02485829032957554,
      0.02574281580746174,
      0.031148124486207962,
      -0.04378734529018402,
      -0.0406174510717392,
      -0.011298991739749908,
      -0.0046103219501674175,
      0.0592208132147789,
      0.016958029940724373,
      0.056946173310279846,
      0.01594257354736328,
      -0.024097826331853867,
      0.00892386119812727,
      0.03430414944887161,
      -0.08158455044031143,
      0.00899517722427845,
      0.017918679863214493,
      0.07519830763339996,
      -0.033233508467674255,
      -0.0035657600965350866,
      -0.045582495629787445,
      -0.0039801225066185,
      0.03678311035037041,
      0.06240140646696091,
      -0.022678619250655174,
      0.033047448843717575,
      -0.02802526205778122,
      -0.01419755071401596,
      -0.02601410262286663,
      -0.04256421700119972,
      0.015143737196922302,
      -0.020075783133506775,
      -0.049339521676301956,
      0.0779528021812439,
      -0.10954059660434723,
      -0.010534746572375298,
      0.003139779670163989,
      0.009120884351432323,
      0.00022889164392836392,
      0.03961079567670822,
      -0.0132360327988863,
      -0.009128717705607414,
      0.02012575976550579,
      -0.022228490561246872,
      -0.03766583651304245,
      -0.02244259975850582,
      0.06679651886224747,
      -0.019925100728869438,
      -0.008274528197944164,
      0.002527195028960705,
      -0.05931041017174721,
      -0.04894836246967316,
      -0.02801317535340786,
      -0.019269853830337524,
      -0.07908035814762115,
      0.011271045543253422,
      0.016299229115247726,
      -0.024626655504107475,
      0.01598120853304863,
      -0.07972388714551926,
      -0.006225937511771917,
      -0.005116695538163185,
      0.0722397193312645,
      0.03334712237119675,
      -0.05394810810685158,
      -0.013209806755185127,
      0.030369030311703682,
      -0.05009246990084648,
      -0.008594444021582603,
      0.02440057136118412,
      0.06325257569551468,
      0.026603158563375473,
      0.03020198456943035,
      0.012960854917764664,
      -0.054628271609544754,
      0.023089442402124405,
      -0.012266742065548897,
      -0.06536952406167984,
      -0.013777314685285091,
      -0.12305566668510437,
      -0.0068545411340892315,
      0.030869049951434135,
      0.0913652554154396,
      -0.02815389633178711,
      0.0757695809006691,
      -0.09743156284093857,
      0.0432368628680706,
      0.05412155017256737,
      0.02381851337850094,
      0.030500968918204308,
      0.05235850065946579,
      -0.010948090814054012,
      -0.0036533360835164785,
      -0.007434293627738953,
      0.03441016748547554,
      -0.05455205962061882,
      -0.04420991986989975,
      0.030794931575655937,
      0.002424279460683465,
      0.06964072585105896,
      0.08366911858320236,
      -0.014835424721240997,
      0.016445953398942947,
      0.007846719585359097,
      0.03055717796087265,
      0.019524995237588882,
      -0.06502241641283035,
      0.022466786205768585,
      0.03066648729145527,
      -0.005741099361330271,
      0.012043854221701622,
      0.022464999929070473,
      0.03900676965713501,
      0.1165572851896286,
      -0.050821561366319656,
      -0.03636655583977699,
      0.032998207956552505,
      0.0028500265907496214,
      0.0054991040378808975,
      0.0023986080195754766,
      -0.0497049018740654,
      0.04200393334031105,
      0.030759386718273163,
      0.022313924506306648,
      0.03563890606164932,
      -0.0290392879396677,
      0.008031095378100872,
      0.022833222523331642,
      -0.009311982430517673,
      0.014209412969648838,
      0.028666995465755463,
      -0.029247596859931946,
      0.05114307627081871,
      -0.03242862969636917,
      -0.026334300637245178,
      -0.012473189271986485,
      -0.007404491305351257,
      -0.04612526297569275,
      0.05473484843969345,
      -0.017058907076716423,
      -0.10366363823413849,
      0.00380474841222167,
      -0.03687610104680061,
      0.0028460994362831116,
      -0.04470717906951904,
      -0.01842418871819973,
      0.006249101832509041,
      0.07125746458768845,
      -0.04089676961302757,
      -0.03213225677609444,
      0.008717906661331654,
      0.057072948664426804,
      0.006048945710062981,
      -0.026023047044873238,
      0.08761429041624069,
      0.01327914372086525,
      -0.05797392502427101,
      -0.05598648265004158,
      0.05682060495018959,
      0.04084191471338272,
      0.008144570514559746,
      0.00897195190191269,
      -0.014444524422287941,
      0.05660073086619377,
      -0.0724577084183693,
      -0.031000405550003052,
      0.017423847690224648,
      0.0020513241179287434,
      -0.054155223071575165,
      -0.023333149030804634,
      -0.001023618970066309,
      0.032308269292116165,
      0.008729936555027962,
      0.04005804657936096,
      0.024729738011956215,
      0.006992953363806009,
      0.07390260696411133,
      -0.04751585051417351,
      -0.022935856133699417,
      0.01187700405716896,
      0.036180391907691956,
      0.0739748626947403,
      0.03274144232273102,
      0.0652497410774231,
      -0.02781008556485176,
      0.01703234389424324,
      0.007327096536755562,
      0.004678224213421345,
      0.06691373884677887,
      -0.03231526166200638,
      -0.037635091692209244,
      0.018846068531274796,
      -0.042605284601449966,
      0.054244011640548706,
      0.037143852561712265,
      -0.018483784049749374,
      0.05634128674864769,
      0.004874624311923981,
      0.0020072543993592262,
      -0.05142569914460182,
      -0.029837515205144882,
      -0.005294290836900473,
      -0.03391941264271736,
      0.05172228068113327,
      -0.02423860877752304,
      -0.010470741428434849,
      -0.09495799243450165,
      0.061607204377651215,
      -0.06599827855825424,
      -0.05472308024764061,
      -0.025878166779875755,
      -0.03508451581001282,
      -0.00039997868589125574,
      -0.031141962856054306,
      -0.01516051683574915,
      0.03831155225634575,
      0.041593752801418304,
      -0.017519637942314148,
      -0.002389125293120742,
      -0.047469645738601685,
      -0.11814267188310623,
      0.00965813547372818,
      -0.013752085156738758,
      -0.043533384799957275,
      0.09233293682336807,
      0.025816911831498146,
      -0.07726150006055832,
      0.01791549101471901,
      -0.014799017459154129,
      -0.028997384011745453,
      -0.04902442544698715,
      -0.014943400397896767,
      0.03791576623916626,
      0.005967720411717892,
      0.06396946310997009,
      -0.012637730687856674,
      -0.07768883556127548,
      -0.05645442754030228,
      0.08501414954662323,
      -0.01042370218783617,
      0.0014861790696159005,
      0.05769071355462074,
      0.04497338458895683,
      -0.05168237164616585,
      0.01041072141379118,
      0.0009168043034151196,
      0.018382536247372627,
      -0.05158836394548416,
      0.0008625798509456217,
      0.04502249136567116,
      -0.018960725516080856,
      0.01774318516254425,
      -0.009230575524270535,
      -0.11258737742900848,
      0.002803486306220293,
      -0.011839505285024643,
      -0.06980090588331223,
      0.05238675698637962,
      -0.00846647098660469,
      0.07221909612417221,
      0.022936442866921425,
      -0.04104568809270859,
      0.01145605742931366,
      -0.09553854912519455,
      -0.08063258975744247,
      0.035548776388168335,
      0.06997891515493393,
      -0.08525501191616058,
      0.02687782794237137,
      -0.0028990020509809256,
      -0.000657243886962533,
      0.007592080160975456,
      -0.02229759283363819,
      -0.08704981952905655,
      -0.025836125016212463,
      -0.020848814398050308,
      0.012666170485317707,
      0.05409705638885498,
      -0.02892579697072506,
      0.04310508072376251,
      -0.033262699842453,
      0.019885679706931114,
      -0.07016319781541824,
      -0.004649649374186993,
      0.041945237666368484,
      0.04241463169455528,
      -0.08913539350032806,
      -0.01859959587454796,
      -0.021319052204489708,
      0.03244743123650551,
      -0.011811683885753155,
      0.015922026708722115,
      0.05839408561587334,
      -0.014811187982559204,
      0.13738951086997986,
      0.10736414045095444,
      0.004986346233636141,
      -0.02271203324198723,
      0.040996380150318146,
      0.027533210813999176,
      0.005011023487895727,
      -0.020692601799964905,
      -0.06557037681341171,
      -0.0034395980183035135,
      0.012618724256753922,
      -0.08733159303665161,
      0.020403392612934113,
      -0.003994129132479429,
      0.008128929883241653,
      0.08947049826383591,
      -0.05213160812854767,
      0.014332549646496773,
      -0.02373754233121872,
      -0.05673963576555252,
      0.040336523205041885,
      -0.01803910732269287,
      0.03309950232505798,
      0.03455642983317375,
      0.030089953914284706,
      -0.04587268456816673,
      -0.06949441879987717,
      -0.031308114528656006,
      -0.04514430835843086,
      -0.06715404987335205,
      9.714110638014972e-05,
      0.017916442826390266,
      0.046168066561222076,
      -0.007909110747277737,
      -0.07322420179843903,
      -0.012081888504326344,
      0.044193390756845474,
      0.055385660380125046,
      -0.06651571393013,
      0.015254363417625427,
      0.026022037491202354,
      0.03876207023859024,
      0.04554232954978943,
      0.009918305091559887,
      -0.038048818707466125,
      0.004457718227058649,
      0.01622471958398819,
      -0.04206684231758118,
      0.08032277971506119,
      -0.01232191827148199,
      -0.005026326049119234,
      -0.03383587300777435,
      -0.012586374767124653,
      0.02750779315829277,
      0.04495155066251755,
      0.050691474229097366,
      -0.02234499901533127,
      -0.023449279367923737,
      -0.07849965244531631,
      0.07879982888698578,
      0.06584981828927994,
      -0.00998122151941061,
      0.01859365776181221,
      0.0063874260522425175,
      -0.05395299568772316,
      -0.03706348314881325,
      0.047817882150411606,
      -0.004244922194629908,
      -0.023448826745152473,
      -0.0005705822841264307,
      0.09734442085027695,
      -0.04767148569226265,
      -0.11700904369354248,
      -0.04195339232683182,
      0.04385686665773392,
      -0.019159672781825066,
      -0.014007712714374065,
      0.0761176273226738,
      0.027536191046237946,
      -0.10487352311611176,
      -0.054130375385284424,
      -0.07439308613538742,
      -0.027655908837914467,
      -0.041096944361925125,
      -0.0412682369351387,
      0.015296367928385735,
      -0.051823221147060394,
      0.008202501572668552,
      0.03320493549108505,
      0.0032432926818728447,
      -0.015978846698999405,
      0.016010163351893425,
      -0.02186564728617668,
      -0.012139775790274143,
      -0.058146506547927856
    ],
    [
      -0.060720302164554596,
      0.01954788528382778,
      0.018145859241485596,
      0.04549318179488182,
      0.06570915877819061,
      -0.04758940637111664,
      -0.08417873084545135,
      0.10096891969442368,
      -0.0757402777671814,
      -0.029702231287956238,
      0.004491615574806929,
      0.08061341196298599,
      -0.03010740876197815,
      0.050354428589344025,
      0.06681051105260849,
      -0.004869478289037943,
      0.025844000279903412,
      0.10589557141065598,
      0.10766956955194473,
      0.07100003212690353,
      -0.04956977441906929,
      0.07411859929561615,
      0.0334242582321167,
      0.008826106786727905,
      -0.025856997817754745,
      0.010647200979292393,
      0.09386316686868668,
      -0.00615703035145998,
      -0.06954888254404068,
      -0.07867438346147537,
      0.038706645369529724,
      0.004287639632821083,
      0.012832303531467915,
      -0.04341551661491394,
      0.007992912083864212,
      -0.024339186027646065,
      0.00222365977242589,
      -0.00951014831662178,
      -0.014561152085661888,
      0.0031578021589666605,
      0.020236091688275337,
      -0.05683395266532898,
      -0.05838967114686966,
      -0.0664195641875267,
      0.030087020248174667,
      0.04297131299972534,
      -0.09343758970499039,
      0.03521592542529106,
      0.021868467330932617,
      0.03857749328017235,
      0.031791895627975464,
      -0.09632450342178345,
      0.019219597801566124,
      -0.007161365356296301,
      0.09115464240312576,
      0.03494914248585701,
      0.011260390281677246,
      -0.045841120183467865,
      0.014117912389338017,
      0.04237650707364082,
      0.05108524113893509,
      -0.02880147285759449,
      -0.026497282087802887,
      -0.02213643118739128,
      -0.01103443093597889,
      0.015325441025197506,
      0.001980353146791458,
      0.03138471767306328,
      0.0058660972863435745,
      -0.017059683799743652,
      0.0011779522756114602,
      0.029756082221865654,
      -0.021196721121668816,
      0.021091334521770477,
      -0.03210234269499779,
      0.02994619496166706,
      0.039741575717926025,
      -0.03063828870654106,
      -0.06985118240118027,
      0.047293778508901596,
      -0.03277222812175751,
      -0.06323498487472534,
      -0.016797540709376335,
      -0.08999696373939514,
      -0.033291660249233246,
      0.00941203348338604,
      0.03623344749212265,
      0.031230663880705833,
      -0.0069176433607935905,
      0.11243070662021637,
      -0.04469763860106468,
      0.030486389994621277,
      -0.040627799928188324,
      -0.02077055722475052,
      -0.0759376585483551,
      -0.07508887350559235,
      -0.019015448167920113,
      -0.04734724014997482,
      0.030610380694270134,
      -0.009998710826039314,
      0.06382551044225693,
      0.014664104208350182,
      -0.01223332341760397,
      -0.03213406354188919,
      0.07720892876386642,
      0.015375002287328243,
      -0.06802453845739365,
      -0.00254845735616982,
      -1.5303190593840554e-05,
      0.047386534512043,
      0.03372630476951599,
      0.0820712074637413,
      0.03290778025984764,
      -0.021699832752346992,
      -0.033393487334251404,
      0.03591323643922806,
      0.04074859991669655,
      -0.006137593183666468,
      0.06698138266801834,
      0.034214314073324203,
      0.012081779539585114,
      -0.005311043933033943,
      0.06227187439799309,
      -0.05552627518773079,
      -0.0153731107711792,
      -0.020139526575803757,
      0.020660102367401123,
      -0.0004331591189838946,
      -0.06264844536781311,
      -0.020647307857871056,
      -0.041144199669361115,
      0.04822871461510658,
      -0.016212914139032364,
      0.03662913292646408,
      -0.07326216250658035,
      0.05243783816695213,
      -0.007748703472316265,
      0.013650776818394661,
      0.04792655259370804,
      -0.009824027307331562,
      -0.011551466770470142,
      -0.020458439365029335,
      0.08374150842428207,
      -0.04344157502055168,
      0.03775802254676819,
      -0.02163657546043396,
      0.01204816997051239,
      -0.04158327728509903,
      -0.04432636871933937,
      0.07303354144096375,
      -0.003984516952186823,
      0.009740835055708885,
      -0.11229763180017471,
      -0.005995120387524366,
      -0.03861727565526962,
      0.010965326800942421,
      0.0037142192013561726,
      -0.059037454426288605,
      -0.015805386006832123,
      0.023263590410351753,
      -0.038201089948415756,
      0.017402706667780876,
      0.013583133928477764,
      0.052967045456171036,
      0.031512994319200516,
      -0.10931761562824249,
      -0.012592592276632786,
      0.026058364659547806,
      -0.10883855819702148,
      -0.02863118425011635,
      0.010653802193701267,
      -0.04478932172060013,
      0.07849840819835663,
      0.01041205134242773,
      0.04068787395954132,
      0.04273334890604019,
      0.012431670911610126,
      0.027134185656905174,
      -0.03557147830724716,
      0.03401356190443039,
      -0.08729855716228485,
      0.02541523426771164,
      -0.0020172307267785072,
      -0.0637713372707367,
      0.0013967239065095782,
      0.0037954289000481367,
      0.06925830245018005,
      0.013021520338952541,
      -0.021389039233326912,
      0.051415856927633286,
      0.005548087880015373,
      -0.018797989934682846,
      -0.03627065569162369,
      0.08076491951942444,
      -0.014883022755384445,
      0.09669583290815353,
      0.03457023575901985,
      -0.06521899998188019,
      0.03566163033246994,
      0.0287267304956913,
      0.0850503146648407,
      -0.022769346833229065,
      0.08198621869087219,
      -0.0478389672935009,
      -0.004066217690706253,
      0.015954995527863503,
      0.038312483578920364,
      0.008138218894600868,
      0.06086340174078941,
      -0.0026597725227475166,
      -0.07615829259157181,
      -0.03411669656634331,
      -0.08046422153711319,
      -0.001214075949974358,
      0.1259831041097641,
      -0.10092218965291977,
      0.02097872644662857,
      0.040109097957611084,
      -0.02850266359746456,
      0.006506706587970257,
      -0.03647022321820259,
      -0.035249460488557816,
      -0.028676459565758705,
      -0.0023941255640238523,
      0.10535456240177155,
      0.008548098616302013,
      0.025236627086997032,
      0.06825857609510422,
      0.0060937050729990005,
      -0.03026859648525715,
      0.039722684770822525,
      0.0027972643729299307,
      0.00014713288692291826,
      0.027072085067629814,
      0.009963549673557281,
      0.04872874170541763,
      0.0054550133645534515,
      0.05981454998254776,
      0.029893411323428154,
      -0.051987458020448685,
      -0.015146041288971901,
      -0.015638068318367004,
      -0.02161674201488495,
      -0.05516999587416649,
      -0.07659746706485748,
      0.027957268059253693,
      0.057434067130088806,
      0.0034867411013692617,
      0.06182241439819336,
      0.019740242511034012,
      -0.021923726424574852,
      0.06341893970966339,
      -0.013715210370719433,
      0.0481283962726593,
      -0.01004194188863039,
      -0.016819393262267113,
      -0.03549499437212944,
      -0.029631461948156357,
      -0.06169001758098602,
      -0.030157947912812233,
      0.06946048140525818,
      0.05628557130694389,
      -0.0522279255092144,
      0.005229408852756023,
      0.053528402000665665,
      -0.012749863788485527,
      -0.033986207097768784,
      -0.03112724795937538,
      -0.027024157345294952,
      -0.0016335290856659412,
      0.0056838844902813435,
      0.006738190073519945,
      0.05359005182981491,
      0.03901875764131546,
      0.03349534422159195,
      -0.02868412807583809,
      -0.04091230034828186,
      0.04446444660425186,
      0.04925592243671417,
      0.03514862433075905,
      0.013263721950352192,
      0.1369720697402954,
      0.07415913045406342,
      0.006727728992700577,
      0.004763701930642128,
      0.021020233631134033,
      -0.04538678005337715,
      -0.028629902750253677,
      0.022310514003038406,
      0.016060296446084976,
      0.0019402954494580626,
      -0.027869319543242455,
      -0.06695020198822021,
      -0.03181998059153557,
      -0.07634052634239197,
      -0.029497869312763214,
      0.05857720598578453,
      0.019223779439926147,
      -0.008697498589754105,
      0.011261043138802052,
      -0.026598570868372917,
      -0.06518974900245667,
      0.03378978744149208,
      0.04082169011235237,
      -0.05387060344219208,
      0.01364841777831316,
      0.04267498850822449,
      -0.05377442389726639,
      0.0022488823160529137,
      0.03408287465572357,
      -0.06595217436552048,
      -0.04522749036550522,
      -0.007074475754052401,
      -0.13548780977725983,
      0.031842004507780075,
      0.03609979897737503,
      0.015571821480989456,
      -0.09993209689855576,
      0.015538925305008888,
      0.024034617468714714,
      -0.03463036194443703,
      -0.036606986075639725,
      -0.04277314618229866,
      0.012326871044933796,
      0.011157759465277195,
      0.020789550617337227,
      0.0046802908182144165,
      -0.0545533150434494,
      -0.00234296265989542,
      -0.03423789516091347,
      -0.05994845926761627,
      -0.034639857709407806,
      -0.08609429001808167,
      -0.02840890921652317,
      0.01339867152273655,
      0.0007670919876545668,
      0.026920439675450325,
      0.022832106798887253,
      0.027193456888198853,
      -0.013986983336508274,
      -0.04445263370871544,
      0.0014514763606712222,
      0.019712528213858604,
      -0.01870431751012802,
      0.022694701328873634,
      -0.02449192851781845,
      -0.017696037888526917,
      -0.004312945995479822,
      0.005010897759348154,
      -0.03280118480324745,
      -0.019971147179603577,
      0.0827687457203865,
      -0.003166866721585393,
      -0.03358669579029083,
      0.014882083982229233,
      0.0025849828962236643,
      -0.13098850846290588,
      0.07202517986297607,
      -0.04235947132110596,
      -0.05928345024585724,
      0.003754697972908616,
      0.034335844218730927,
      0.024406742304563522,
      0.023023998364806175,
      0.0036344551481306553,
      0.021659409627318382,
      -0.02639482542872429,
      0.012616734020411968,
      0.015308956615626812,
      0.012266569770872593,
      0.006015925668179989,
      0.04435459524393082,
      -0.05424664542078972,
      0.03535483404994011,
      0.03370362147688866,
      -0.03393009305000305,
      -0.009931802749633789,
      0.04115287959575653,
      0.04621844366192818,
      0.03344668820500374,
      -0.06128237396478653,
      -0.06652294099330902,
      0.05683194845914841,
      0.014733873307704926,
      0.018469272181391716,
      0.002013156656175852,
      0.00011117420217487961,
      0.08080188184976578,
      -0.0117521146312356,
      0.014917634427547455,
      -0.026146868243813515,
      0.003120667766779661,
      0.06493417918682098,
      -0.04118797928094864,
      0.009811087511479855,
      0.07299189269542694,
      -0.010945034213364124,
      0.01286837738007307,
      0.0414368212223053,
      -0.0564960278570652,
      0.012660921551287174,
      -0.05825211852788925,
      0.010618898086249828,
      -0.04454293102025986,
      -0.019178276881575584,
      -0.015474529936909676,
      0.06267291307449341,
      -0.04422972351312637,
      0.01863018050789833,
      -0.0681554302573204,
      0.009283614344894886,
      0.04341891035437584,
      0.04162585362792015,
      -0.08270644396543503,
      -0.005007282830774784,
      -0.012450764887034893,
      0.021004673093557358,
      -0.05152425169944763,
      0.012766513042151928,
      -0.027927543967962265,
      -0.041534435003995895,
      -0.023863455280661583,
      0.09059324860572815,
      -0.008856703527271748,
      -0.0004078650090377778,
      0.032753851264715195,
      -0.020994456484913826,
      -0.04606274142861366,
      0.01267332024872303,
      0.004881011322140694,
      0.012441621161997318,
      0.03200002759695053,
      0.05706297978758812,
      -0.05543244630098343,
      -0.03438286483287811,
      -0.039362505078315735,
      0.042998794466257095,
      -0.0202160757035017,
      0.00045406565186567605,
      0.0010882235364988446,
      -0.08490387350320816,
      0.049774251878261566,
      0.04835670813918114,
      0.007558269891887903,
      0.004080889280885458,
      -0.08229098469018936,
      -0.017514102160930634,
      -0.05375025048851967,
      0.08433979004621506,
      -0.0406041257083416,
      0.08120200037956238,
      -0.034433186054229736,
      -0.029562365263700485,
      -0.04027305170893669,
      -0.06817116588354111,
      0.04928264021873474,
      0.03668921813368797,
      -0.04241383448243141,
      0.04015077278017998,
      -0.07075589895248413,
      0.006937454454600811,
      -0.01579180918633938,
      0.0797961875796318,
      0.0728825256228447,
      -0.005302043166011572,
      -0.00030510363285429776,
      -0.07640266418457031,
      0.019413739442825317,
      -0.05377255752682686,
      0.0023241618182510138,
      -0.012823862954974174,
      3.999995897174813e-05,
      0.02958599291741848,
      -0.02646448276937008,
      -0.1021701768040657,
      0.02495683543384075,
      -0.002135760383680463,
      0.06556333601474762,
      0.0009034396498464048,
      0.036213282495737076,
      0.012438653968274593,
      0.06337936967611313,
      0.0197150819003582,
      -0.04877603426575661,
      0.02020873874425888,
      -0.030854593962430954,
      0.05657793581485748,
      -0.0024326418060809374,
      -0.0032980868127197027,
      0.024513304233551025,
      0.019634796306490898,
      0.07186742126941681,
      0.04809314012527466,
      -0.03256158530712128,
      0.0465053953230381,
      0.01300000213086605,
      -0.05311485752463341,
      0.009281029924750328,
      -0.04502752795815468,
      -0.0845583975315094,
      0.006249630823731422,
      -0.019054267555475235,
      -0.09095548838376999
    ],
    [
      -0.0016969809075817466,
      -0.018729235976934433,
      0.03443603217601776,
      -0.017025331035256386,
      -0.000580916297622025,
      -0.037946976721286774,
      -0.02253381349146366,
      0.005710206925868988,
      0.09464970231056213,
      0.08003702014684677,
      0.052953485399484634,
      -0.08091752976179123,
      0.13478447496891022,
      -0.016627104952931404,
      0.027143072336912155,
      -0.012346608564257622,
      -0.013921288773417473,
      -0.09649178385734558,
      -0.03237886726856232,
      0.052983783185482025,
      0.00520815746858716,
      0.04464581608772278,
      0.020321130752563477,
      -0.022229446098208427,
      0.08562800288200378,
      -0.08431426435709,
      -0.0033927257172763348,
      0.05431795120239258,
      0.03749474138021469,
      -0.07092488557100296,
      -0.035505205392837524,
      0.04342428594827652,
      -0.049458228051662445,
      -0.02940250374376774,
      -0.07073045521974564,
      0.026939954608678818,
      -0.048557668924331665,
      0.0468248687684536,
      -0.048909418284893036,
      -0.029324088245630264,
      0.009772704914212227,
      -0.0844663679599762,
      -0.006175644230097532,
      0.037229690700769424,
      -0.027313945814967155,
      -0.07915769517421722,
      -0.1014900952577591,
      0.0037138215266168118,
      -0.0865812748670578,
      0.028747055679559708,
      0.034295469522476196,
      -0.03676701709628105,
      0.020264778286218643,
      -0.05631287768483162,
      0.004340880550444126,
      -0.11361616849899292,
      -4.85777036374202e-06,
      -0.06664382666349411,
      -0.03265087306499481,
      -0.021610712632536888,
      0.05559994652867317,
      0.019219037145376205,
      0.03658255934715271,
      0.030600273981690407,
      0.004604790359735489,
      -0.05578208714723587,
      0.027938032522797585,
      0.022402752190828323,
      0.065111443400383,
      -0.04495437070727348,
      -0.022196071222424507,
      0.028583049774169922,
      0.00797007605433464,
      0.06133135035634041,
      0.023902850225567818,
      0.009073794819414616,
      -0.042137883603572845,
      -0.06944797188043594,
      0.019426699727773666,
      -0.09228988736867905,
      -0.05637028440833092,
      -0.02935022860765457,
      0.009730796329677105,
      -0.021528277546167374,
      -0.02703791670501232,
      -0.00044438010081648827,
      -0.0059733022935688496,
      -0.05588199570775032,
      -0.037806183099746704,
      0.03238273411989212,
      -0.022621771320700645,
      0.035047002136707306,
      0.0020787406247109175,
      0.01753338612616062,
      -0.04361401125788689,
      -0.01142558827996254,
      -0.07506895810365677,
      -0.039667759090662,
      0.01162042561918497,
      -0.09960225224494934,
      0.0201233122497797,
      0.056812938302755356,
      -0.015290982089936733,
      0.0028962334617972374,
      0.00013957796909380704,
      0.07133587449789047,
      -0.0679386556148529,
      0.08801290392875671,
      -0.06869228929281235,
      0.029485557228326797,
      -0.03501029685139656,
      0.020536264404654503,
      0.014220837503671646,
      0.11317906528711319,
      -0.009807052090764046,
      -0.054862696677446365,
      -0.028453735634684563,
      -0.010951276868581772,
      0.05552106723189354,
      0.019931787624955177,
      -0.08398335427045822,
      -0.07291746884584427,
      -0.015125754289329052,
      -0.04522191733121872,
      -0.06547711044549942,
      0.00558469770476222,
      0.022729404270648956,
      0.011024282313883305,
      -0.007002498954534531,
      -0.023454897105693817,
      0.0006443017045967281,
      0.11313482373952866,
      0.05178032070398331,
      0.0797230452299118,
      0.006935549899935722,
      -0.03473065048456192,
      -0.019982418045401573,
      -0.04268176108598709,
      0.004696435760706663,
      -0.009483174420893192,
      0.003113764338195324,
      -0.00923496950417757,
      0.000858676852658391,
      -0.04591512307524681,
      -0.0685281828045845,
      0.008895940147340298,
      0.02286144532263279,
      0.035423826426267624,
      -0.003084097057580948,
      0.021626433357596397,
      -0.04072657600045204,
      0.004051786847412586,
      -0.020804425701498985,
      0.0359409898519516,
      0.0805278867483139,
      -0.005932040512561798,
      0.0725337415933609,
      -0.038529351353645325,
      0.03676275163888931,
      0.01656349189579487,
      0.03922898322343826,
      0.02940458618104458,
      -0.004526993725448847,
      -0.08796200901269913,
      -0.028135305270552635,
      0.03571779280900955,
      -0.05712727829813957,
      0.05247978866100311,
      -0.027832990512251854,
      -0.07055557519197464,
      -0.044834550470113754,
      -0.018564077094197273,
      -0.06630372256040573,
      -0.04199633747339249,
      0.1382705271244049,
      0.0497116893529892,
      0.013841341249644756,
      -0.0014815627364441752,
      -0.041256118565797806,
      0.05395488440990448,
      -0.0524146594107151,
      -0.08678676933050156,
      0.014121566899120808,
      -0.055217377841472626,
      -0.023850658908486366,
      0.031883809715509415,
      -0.017205899581313133,
      -0.0676276758313179,
      -0.04858070984482765,
      0.017883937805891037,
      0.01845618523657322,
      0.004447561223059893,
      0.012610301375389099,
      -0.046291664242744446,
      -0.005605200305581093,
      0.02276076376438141,
      -0.031032009050250053,
      0.09070588648319244,
      -0.008782858029007912,
      -0.013064824044704437,
      0.10339271277189255,
      -0.014592996798455715,
      0.008477998897433281,
      0.0569724403321743,
      -0.02098114788532257,
      0.034748803824186325,
      -0.02117462456226349,
      0.006198097486048937,
      0.006800769828259945,
      -0.048386350274086,
      0.03473743796348572,
      -0.01349298283457756,
      -0.0029756417497992516,
      0.09455136209726334,
      -0.05906558036804199,
      -0.012759984470903873,
      -0.04374197497963905,
      0.0594586506485939,
      -0.05893669277429581,
      -0.04877858608961105,
      -0.029377344995737076,
      0.03589204326272011,
      0.04375625029206276,
      -0.008963356725871563,
      0.04413817077875137,
      -0.010625218972563744,
      -0.013952570036053658,
      0.07977021485567093,
      -0.06561071425676346,
      -0.004589143209159374,
      0.02827443554997444,
      -0.04256465658545494,
      -0.02071039192378521,
      0.019735079258680344,
      0.0005712798447348177,
      -0.025342755019664764,
      0.045330069959163666,
      0.07593744248151779,
      -0.059398531913757324,
      -0.06253116577863693,
      -0.013818182982504368,
      0.018387822434306145,
      -0.045810479670763016,
      -0.001506836386397481,
      0.006661378778517246,
      0.050428539514541626,
      0.010390572249889374,
      0.0720958560705185,
      -0.004824002273380756,
      0.01048839371651411,
      -0.023023806512355804,
      -0.02209392562508583,
      -0.046636778861284256,
      0.001019786694087088,
      0.003992782905697823,
      -0.07251276075839996,
      0.012657048180699348,
      -0.018102390691637993,
      0.04342610016465187,
      0.016580792143940926,
      -0.04263385757803917,
      -0.04649589955806732,
      0.04054321348667145,
      0.026516610756516457,
      0.027965214103460312,
      -0.05067860707640648,
      0.0060112192295491695,
      0.009698172099888325,
      -0.029987256973981857,
      0.0009815215598791838,
      -0.07810896635055542,
      0.02773245796561241,
      -0.003866038750857115,
      0.050486400723457336,
      0.041401736438274384,
      0.04161207377910614,
      0.037180736660957336,
      0.010786381550133228,
      0.011557992547750473,
      -0.000303474924294278,
      0.05219314619898796,
      0.02378619648516178,
      -0.014240613207221031,
      0.034989528357982635,
      0.04842124506831169,
      -0.02253636159002781,
      -0.037562280893325806,
      0.06895800679922104,
      -0.015755118802189827,
      -0.04057765007019043,
      -0.0054945070296525955,
      -0.05480572581291199,
      0.006546822376549244,
      0.03610861301422119,
      -0.017946317791938782,
      0.041205670684576035,
      -0.003373317187651992,
      -0.006192508153617382,
      -0.04736688360571861,
      0.0045868949964642525,
      0.08923784643411636,
      0.019511669874191284,
      0.01480659656226635,
      -0.0004029487899970263,
      0.04615608602762222,
      -0.022646887227892876,
      0.037561435252428055,
      0.00013105430116411299,
      0.03258312866091728,
      0.05463171750307083,
      -0.050262805074453354,
      -0.03116511180996895,
      -0.05445452407002449,
      0.03796792030334473,
      0.09018687158823013,
      0.0767597183585167,
      -0.11236464977264404,
      -0.05092642083764076,
      -0.05120285600423813,
      -0.043353136628866196,
      -0.040186863392591476,
      -0.0006459651631303132,
      0.015336203388869762,
      -0.04163447022438049,
      -0.03537730872631073,
      -0.03866979479789734,
      -0.026192400604486465,
      -0.00812231283634901,
      -0.016937237232923508,
      0.007088993676006794,
      -0.010678242892026901,
      -0.03977903351187706,
      0.05322761461138725,
      -0.1116471067070961,
      -0.02191724255681038,
      0.011558759026229382,
      -0.01462275069206953,
      -0.03313453122973442,
      0.08450745791196823,
      -0.08185120671987534,
      -0.010361014865338802,
      0.019897716119885445,
      -0.014209297485649586,
      -0.007051084190607071,
      0.018107233569025993,
      -0.07189089804887772,
      0.06222850829362869,
      0.004670414142310619,
      0.02634706161916256,
      -0.042212918400764465,
      -0.07014099508523941,
      0.050770267844200134,
      -0.025131095200777054,
      -0.06559301167726517,
      -0.05596611276268959,
      0.02671699970960617,
      0.020082009956240654,
      0.010788647457957268,
      -0.09636586159467697,
      0.020724989473819733,
      -0.08344367146492004,
      -0.00047256427933461964,
      -0.016569821164011955,
      -0.08994110673666,
      -0.07938472181558609,
      0.014550529420375824,
      0.0482865646481514,
      -0.011869672685861588,
      -0.08452557027339935,
      0.029652344062924385,
      -0.0582021027803421,
      0.008645851165056229,
      0.10076194256544113,
      0.042324248701334,
      0.03727282956242561,
      0.026940159499645233,
      -0.02736188843846321,
      -0.0786745473742485,
      0.008305657655000687,
      0.022047273814678192,
      -0.03380224108695984,
      -0.06760644167661667,
      0.008674561977386475,
      0.0363437756896019,
      0.02661900781095028,
      -0.03984289988875389,
      -0.049553729593753815,
      -0.011730412021279335,
      0.0012689746217802167,
      0.009599116630852222,
      0.0732916072010994,
      -0.0023583220317959785,
      0.05306808650493622,
      -0.08472760021686554,
      -0.032250918447971344,
      -0.01512710191309452,
      -0.0009834255324676633,
      -0.01940874382853508,
      -0.014520949684083462,
      0.06794058531522751,
      -0.031200610101222992,
      -0.009216793812811375,
      -0.009122611954808235,
      -0.0542772077023983,
      0.0866723582148552,
      0.00800247024744749,
      -0.07763410359621048,
      -0.004844622686505318,
      0.019703373312950134,
      0.01914915256202221,
      -0.01443914882838726,
      0.004502055700868368,
      -0.013586173765361309,
      0.004345758352428675,
      -0.0036795414052903652,
      0.0061197890900075436,
      -0.03800836578011513,
      -0.0973808690905571,
      0.06564926356077194,
      -0.0707717314362526,
      -0.0621834434568882,
      0.03713575750589371,
      -0.022521590813994408,
      0.009430444799363613,
      -0.003435663180425763,
      0.059593603014945984,
      -0.05278536304831505,
      -0.013438421301543713,
      0.04383198171854019,
      -0.06662777811288834,
      -0.07195209711790085,
      0.027280379086732864,
      0.024093667045235634,
      0.008120211772620678,
      -0.04092203453183174,
      -0.038076840341091156,
      -0.02034730091691017,
      0.0020830060821026564,
      0.05400778353214264,
      0.007070246152579784,
      0.06937334686517715,
      -0.00834018737077713,
      0.029258515685796738,
      -0.0001287632476305589,
      0.015519760549068451,
      -0.013034755364060402,
      -0.01069505512714386,
      0.027198567986488342,
      -0.05572656914591789,
      -0.054366253316402435,
      0.09404442459344864,
      0.005769719835370779,
      -0.03478175401687622,
      -0.012361543253064156,
      0.02527296543121338,
      -0.04475734382867813,
      0.002267278963699937,
      0.015614400617778301,
      -0.054201845079660416,
      -0.0303133986890316,
      -0.005113906227052212,
      -0.08416213095188141,
      0.09032569825649261,
      0.058233942836523056,
      0.03622477501630783,
      -0.019483324140310287,
      -0.04535454139113426,
      0.021074283868074417,
      -0.013127335347235203,
      -0.0022195917554199696,
      -0.043508995324373245,
      -0.03189247474074364,
      0.05075189471244812,
      0.007113974075764418,
      0.11803065985441208,
      0.010664989240467548,
      -0.0059182848781347275,
      0.04005758464336395,
      0.06289537996053696,
      0.01229471992701292,
      -0.039808932691812515,
      -0.026722772046923637,
      0.0263917725533247,
      -0.06410208344459534,
      0.04204870015382767,
      -0.0019888198003172874,
      0.02873731404542923,
      -0.1013413667678833,
      0.02581099048256874,
      0.03444763273000717,
      -0.036754004657268524,
      -0.09028127789497375,
      0.06806530058383942,
      -0.040814872831106186,
      -0.009095719084143639,
      0.09921666979789734,
      0.01642930880188942,
      -0.03061041794717312,
      -0.041584376245737076,
      0.010331464931368828,
      -0.02457483671605587,
      -0.014281868003308773,
      -0.04457227140665054
    ],
    [
      -0.054912127554416656,
      0.10513617098331451,
      0.07384011149406433,
      -0.013345586135983467,
      0.004369994159787893,
      -0.033783700317144394,
      -0.06385868787765503,
      0.05536952242255211,
      0.05425087362527847,
      -0.010071507655084133,
      -0.012409591116011143,
      -0.033774204552173615,
      0.02687249891459942,
      -0.04934367910027504,
      0.017678510397672653,
      -0.04070472717285156,
      0.051922913640737534,
      0.011038638651371002,
      0.030764348804950714,
      0.014936110936105251,
      -0.018536798655986786,
      0.02621583640575409,
      0.0018700668588280678,
      -0.022494103759527206,
      -0.008609236218035221,
      -0.092566579580307,
      -0.06186816841363907,
      -0.014390302821993828,
      -0.009837544523179531,
      0.014098365791141987,
      0.02935425005853176,
      0.016915490850806236,
      -0.030181683599948883,
      -0.04373897612094879,
      -0.025525078177452087,
      0.09088628739118576,
      0.0047441814094781876,
      -0.005024917423725128,
      -0.06036713346838951,
      0.024036018177866936,
      0.046050943434238434,
      -0.00976472720503807,
      -0.04470296949148178,
      -0.04073355719447136,
      0.04554754123091698,
      -0.018978608772158623,
      -0.056261565536260605,
      -0.03127162158489227,
      0.03471779450774193,
      -0.008740290999412537,
      -0.041059769690036774,
      -0.04500778391957283,
      -0.07900939881801605,
      -0.08279997110366821,
      -0.03124372847378254,
      -0.01643458567559719,
      -0.05328569561243057,
      0.10060115903615952,
      0.02736799791455269,
      0.06782938539981842,
      0.10080811381340027,
      -0.009942986071109772,
      0.03913794457912445,
      0.042625218629837036,
      0.07749960571527481,
      -0.0397893488407135,
      -0.0205832552164793,
      0.018822813406586647,
      0.026426423341035843,
      0.045929163694381714,
      -0.028168343007564545,
      0.01052981149405241,
      0.01762029156088829,
      0.0433957576751709,
      -0.02220778353512287,
      -0.008248601108789444,
      -0.021177232265472412,
      -0.02781844511628151,
      -0.026228098198771477,
      -0.008171293884515762,
      -0.04758920520544052,
      0.01608296111226082,
      0.05173228308558464,
      0.047728441655635834,
      -0.024717271327972412,
      0.08170746266841888,
      0.04554491117596626,
      0.052895642817020416,
      0.02280428074300289,
      -0.039069537073373795,
      -0.06831717491149902,
      -0.00025059233303181827,
      -0.08113673329353333,
      0.0022209708113223314,
      -0.025219565257430077,
      0.01560898870229721,
      -0.02757762186229229,
      -0.008505349047482014,
      0.05564001947641373,
      -0.06591007858514786,
      0.09683217853307724,
      0.010608688928186893,
      -0.0293378047645092,
      -0.04431043565273285,
      0.06233447417616844,
      -0.0683269277215004,
      -0.1100914403796196,
      0.02137475647032261,
      -0.03532365337014198,
      0.055618371814489365,
      -0.022808963432908058,
      -0.04360863193869591,
      0.12300999462604523,
      -0.0175835732370615,
      0.005224345717579126,
      -0.014770378358662128,
      -0.002171225380152464,
      -0.030029036104679108,
      -0.015117630362510681,
      -0.08565226942300797,
      0.03497881069779396,
      0.03681747242808342,
      -0.018746547400951385,
      0.08161301165819168,
      -0.007388207595795393,
      -0.05735863372683525,
      0.03076094202697277,
      0.028479229658842087,
      -0.03332491219043732,
      0.062197327613830566,
      0.0026049490552395582,
      -0.03882739692926407,
      -0.0021943130996078253,
      0.04089604318141937,
      -0.03639441728591919,
      0.005890211556106806,
      -0.03722914680838585,
      -0.03403910622000694,
      -0.005905594676733017,
      -0.037836574018001556,
      0.012865796685218811,
      0.007697914261370897,
      -0.010170803405344486,
      0.02285340242087841,
      0.017162345349788666,
      0.07081747055053711,
      -0.039293073117733,
      0.08249132335186005,
      -0.07400862127542496,
      0.0037139623891562223,
      0.03330780193209648,
      0.004069497808814049,
      0.031190747395157814,
      0.059172362089157104,
      0.10299932956695557,
      -0.005857769399881363,
      -0.07332470268011093,
      -0.08645689487457275,
      0.02079748921096325,
      0.0671129897236824,
      -0.04354338347911835,
      -0.08507698774337769,
      -0.04916483536362648,
      -0.11143946647644043,
      -0.009703703224658966,
      -0.012125317938625813,
      -0.02979959547519684,
      0.05204344913363457,
      0.022483378648757935,
      0.03758551925420761,
      0.061294108629226685,
      -0.02043888345360756,
      0.09324489533901215,
      -0.05493802949786186,
      0.0030198299791663885,
      -0.009332223795354366,
      -0.036309849470853806,
      0.028753146529197693,
      -0.035645559430122375,
      0.09406941384077072,
      0.029218876734375954,
      0.03339633718132973,
      -0.012674810364842415,
      -0.13652373850345612,
      0.06779085099697113,
      0.006137873977422714,
      0.05817335098981857,
      -0.04736940190196037,
      -0.08626995980739594,
      -0.004618840292096138,
      0.001068861223757267,
      0.02270909585058689,
      0.021986478939652443,
      -0.004428883083164692,
      -0.007265871856361628,
      0.00305210635997355,
      0.007728987839072943,
      -0.09069670736789703,
      -0.04522091895341873,
      0.032936982810497284,
      0.05071348696947098,
      -0.03117801435291767,
      0.025341907516121864,
      -0.07634144276380539,
      -0.00772120663896203,
      -0.04788826033473015,
      0.04033559188246727,
      -0.01968926563858986,
      0.016952408477663994,
      -0.09982343763113022,
      0.010865864343941212,
      -0.031194601207971573,
      0.058101896196603775,
      -0.05179557204246521,
      -0.06307996064424515,
      0.0561939962208271,
      -0.06280481815338135,
      -0.08904921263456345,
      -0.08522052317857742,
      -0.06977605074644089,
      0.043304506689310074,
      -0.0022059057373553514,
      -0.06661999970674515,
      0.07213858515024185,
      0.0004982090904377401,
      -0.005666967947036028,
      -0.0006568742683157325,
      0.059204816818237305,
      -0.008292507380247116,
      0.10640662163496017,
      0.09740947932004929,
      0.07065699249505997,
      -0.021186301484704018,
      -0.050926338881254196,
      -0.022879306226968765,
      -0.029936455190181732,
      0.03006572648882866,
      0.001645154319703579,
      -0.0029049322474747896,
      -0.015071925707161427,
      0.07227461785078049,
      -0.06006594002246857,
      -0.06934457272291183,
      -0.022913794964551926,
      -0.048268772661685944,
      -0.04444945231080055,
      0.040723323822021484,
      0.01568915694952011,
      0.054123468697071075,
      -0.05169187858700752,
      -0.05772508680820465,
      0.05108453333377838,
      0.019483985379338264,
      0.03151901066303253,
      -0.14104849100112915,
      -0.02821318805217743,
      0.05040290206670761,
      -0.051859766244888306,
      -0.05336831510066986,
      -0.040494125336408615,
      0.049612823873758316,
      0.048679422587156296,
      0.023383894935250282,
      -0.0751270055770874,
      -0.034461334347724915,
      -0.031437505036592484,
      -0.02515752613544464,
      -0.04574935883283615,
      -0.01675007864832878,
      0.028380941599607468,
      0.08010339736938477,
      0.012668497860431671,
      -0.04037468135356903,
      -0.0484369732439518,
      0.002145983511582017,
      0.028521955013275146,
      -0.009758150205016136,
      -0.02825118787586689,
      0.045474037528038025,
      -0.008551626466214657,
      0.02932479977607727,
      0.07206185907125473,
      0.0622154101729393,
      0.03298347815871239,
      -0.014354357495903969,
      0.09206884354352951,
      -0.004531014245003462,
      -0.10127148777246475,
      -0.01845121942460537,
      0.020508432760834694,
      0.0075908624567091465,
      -0.01659541390836239,
      0.06352613866329193,
      -0.10552222281694412,
      0.010229588486254215,
      -0.029485294595360756,
      0.03718971833586693,
      0.021358521655201912,
      -0.08336813747882843,
      0.06829420477151871,
      0.08961787819862366,
      0.018963739275932312,
      -0.006473626475781202,
      0.03171028941869736,
      0.044677820056676865,
      -0.03876155614852905,
      0.03927096351981163,
      -0.03001447394490242,
      -0.07168322801589966,
      -0.03069385699927807,
      -0.026351843029260635,
      -0.05091853067278862,
      -0.024106992408633232,
      0.02510748617351055,
      0.03242133557796478,
      0.008889456279575825,
      -0.06268423050642014,
      -0.011440926231443882,
      0.05642357096076012,
      0.024812953546643257,
      -0.0844905897974968,
      -0.007128060795366764,
      -0.06897389143705368,
      -0.05750994384288788,
      -0.011494018137454987,
      -0.05587290599942207,
      -0.014940676279366016,
      0.059062398970127106,
      -0.016140921041369438,
      0.04210098832845688,
      0.029057078063488007,
      0.07617798447608948,
      0.013473961502313614,
      0.014225366525352001,
      0.07834237068891525,
      -0.0028668553568422794,
      -0.01195653434842825,
      -0.06843091547489166,
      -0.035020824521780014,
      -0.033599212765693665,
      0.07395904511213303,
      -0.030301416292786598,
      -0.04776504635810852,
      -0.0013257763348519802,
      0.03064325638115406,
      -0.026563597843050957,
      0.040473178029060364,
      -0.04510724917054176,
      -0.08000969141721725,
      -0.03600189834833145,
      -0.031445279717445374,
      0.00582850631326437,
      0.06321202963590622,
      0.06581902503967285,
      -0.060342058539390564,
      -0.017577653750777245,
      -0.030788887292146683,
      0.0354808047413826,
      0.012913256883621216,
      -0.004975090268999338,
      -0.01743592694401741,
      -0.10313688963651657,
      0.039559707045555115,
      0.02725822664797306,
      -0.035320792347192764,
      0.0639614537358284,
      -0.05947527661919594,
      -0.00514140073210001,
      -0.018155932426452637,
      -0.026093050837516785,
      -0.009964196011424065,
      -0.030729250982403755,
      0.04292280972003937,
      -0.07132265716791153,
      -0.07306398451328278,
      0.037842731922864914,
      0.03599923849105835,
      0.005178624298423529,
      0.048308610916137695,
      -0.049219172447919846,
      -0.07590675354003906,
      -0.00239899312146008,
      -0.0445682518184185,
      -0.045316945761442184,
      -0.022307943552732468,
      -0.0035471408627927303,
      -0.03624621406197548,
      0.08475988358259201,
      -0.024366306141018867,
      0.0478019043803215,
      0.0009553554700687528,
      0.017793888226151466,
      -0.013933838345110416,
      0.018224196508526802,
      0.0738660916686058,
      0.012016589753329754,
      0.00248826970346272,
      -0.05820584297180176,
      0.051518283784389496,
      0.06285025924444199,
      0.08715292811393738,
      -0.017589500173926353,
      0.12534736096858978,
      -0.051856499165296555,
      -0.05560214817523956,
      -0.06501167267560959,
      0.06219766288995743,
      -0.030886540189385414,
      0.0054746656678617,
      -0.04677567631006241,
      0.01939874142408371,
      0.05439291149377823,
      -0.028735147789120674,
      -0.014328544028103352,
      0.06590848416090012,
      0.01827586442232132,
      -0.016917556524276733,
      -0.03900911286473274,
      -0.0032716572750359774,
      0.08483035862445831,
      0.00966049637645483,
      -0.025265032425522804,
      -0.020298805087804794,
      0.0066194250248372555,
      -0.05842466279864311,
      -0.0438920333981514,
      0.05702695623040199,
      -0.026151195168495178,
      -0.0708564817905426,
      0.03224354237318039,
      0.04003582522273064,
      -0.013315914198756218,
      -0.039457693696022034,
      0.010785172693431377,
      -0.08227274566888809,
      0.08519824594259262,
      -0.010496614500880241,
      -0.1082589328289032,
      -0.0006777545786462724,
      -0.04455791413784027,
      -0.028186095878481865,
      -0.027037382125854492,
      0.037271175533533096,
      -0.012780200690031052,
      -0.04792526736855507,
      0.08975324034690857,
      0.007275755517184734,
      -0.04445313662290573,
      0.01501772366464138,
      -0.007846799679100513,
      0.015604127198457718,
      0.07875292748212814,
      -0.04294757544994354,
      0.07752120494842529,
      0.015573170967400074,
      0.00840877927839756,
      -0.03524882346391678,
      0.026023808866739273,
      0.08096420019865036,
      -0.008782727643847466,
      0.009062487632036209,
      -0.006305998656898737,
      -0.019340071827173233,
      0.013651100918650627,
      0.00032080779783427715,
      -0.062334951013326645,
      -0.02203518897294998,
      -0.04352230578660965,
      -0.010848232544958591,
      -0.01680770330131054,
      0.0005687573575414717,
      -0.0029723206534981728,
      -0.02079167403280735,
      0.04709905385971069,
      -0.01943369396030903,
      -0.049543414264917374,
      0.03693736344575882,
      -0.04399941489100456,
      0.08763409405946732,
      0.10275404155254364,
      -0.047652244567871094,
      0.019304491579532623,
      0.02752639353275299,
      -0.020304759964346886,
      0.02486448734998703,
      -0.06723880767822266,
      -0.0004141235549468547,
      -0.06128337234258652,
      0.04063374921679497,
      0.03834754228591919,
      -0.02243920974433422,
      0.0369906909763813,
      0.0498880110681057,
      -0.086330346763134,
      0.05648712441325188,
      -0.021927177906036377,
      -0.10667029768228531,
      0.037655770778656006,
      -0.07950448989868164,
      -0.07754768431186676,
      0.008676952682435513,
      -0.0873815044760704,
      -0.06971518695354462
    ],
    [
      -0.0499294251203537,
      -0.009634814225137234,
      0.0169223602861166,
      -0.012992690317332745,
      -0.059527378529310226,
      -0.045394204556941986,
      0.040673207491636276,
      -0.11371804773807526,
      0.07415278255939484,
      0.004977173637598753,
      0.026625970378518105,
      0.07006798684597015,
      -0.0040550222620368,
      0.004747351165860891,
      -0.08353029191493988,
      -0.08584676682949066,
      0.025318119674921036,
      0.015443901531398296,
      0.00483110873028636,
      0.001033277832902968,
      -0.009184536524116993,
      0.04986068978905678,
      0.031197048723697662,
      -0.009204504080116749,
      -0.07680488377809525,
      -0.00959264487028122,
      -0.055599089711904526,
      0.037910014390945435,
      0.0529424324631691,
      0.010416577570140362,
      -0.030260439962148666,
      0.05573171749711037,
      0.0014757867902517319,
      0.04319097846746445,
      0.031652048230171204,
      -0.004979355726391077,
      -0.005936638917773962,
      0.03278128057718277,
      0.022508148103952408,
      -0.050192274153232574,
      0.023884518072009087,
      0.036551155149936676,
      -0.05000315606594086,
      0.006872205529361963,
      0.014018392190337181,
      0.03240777552127838,
      -0.11997285485267639,
      0.02451162040233612,
      -0.0041217501275241375,
      -0.019250920042395592,
      0.05489332601428032,
      -0.02802135981619358,
      -0.04897213354706764,
      0.04388340190052986,
      0.0651712417602539,
      -0.022010553628206253,
      -0.03611958026885986,
      -0.0033696165774017572,
      0.011198371648788452,
      -0.04540209472179413,
      -0.051689162850379944,
      -0.0009830626659095287,
      0.036899175494909286,
      0.03799547255039215,
      0.058837223798036575,
      0.013757781125605106,
      -0.07139061391353607,
      -0.04251399636268616,
      -0.06294441968202591,
      0.07279061526060104,
      0.006309227552264929,
      -0.050276510417461395,
      0.009595842100679874,
      -0.0034202609676867723,
      -0.004560832865536213,
      0.05382047966122627,
      -0.009017151780426502,
      -0.060390904545784,
      0.06051531061530113,
      0.06902389973402023,
      0.054516129195690155,
      -0.062043409794569016,
      0.024204585701227188,
      0.13535112142562866,
      -0.02657913975417614,
      -0.015647629275918007,
      -0.09198401123285294,
      -0.031644683331251144,
      0.04202493652701378,
      0.025397688150405884,
      -0.013149105943739414,
      0.034651294350624084,
      -0.009756473824381828,
      -0.044035185128450394,
      -0.09214045852422714,
      -0.052018359303474426,
      -0.032947566360235214,
      -0.05095755308866501,
      0.03051525540649891,
      -0.0003675391781143844,
      0.06019717454910278,
      -0.04576844722032547,
      0.0895690768957138,
      0.06435126066207886,
      0.059477463364601135,
      0.03072306141257286,
      -0.02422722987830639,
      0.0009723349940031767,
      -0.04786696657538414,
      0.005279905628412962,
      -0.053262315690517426,
      -0.09397908300161362,
      0.008757617324590683,
      -0.03877892717719078,
      0.055655691772699356,
      0.028086794540286064,
      -0.028396081179380417,
      -0.01885116845369339,
      0.004852431360632181,
      0.008166478015482426,
      -0.05496622994542122,
      0.035143133252859116,
      0.05311574414372444,
      0.03564703091979027,
      -0.0907873883843422,
      -0.07652190327644348,
      0.044016074389219284,
      -0.02542709931731224,
      -0.04696520045399666,
      -0.016590995714068413,
      -0.019716517999768257,
      -0.029013989493250847,
      0.03158365935087204,
      -0.04748627170920372,
      -0.08203485608100891,
      -0.04194047302007675,
      -0.015259112231433392,
      0.015055717900395393,
      -0.05167771130800247,
      -0.059527065604925156,
      -0.02365155704319477,
      -0.057268112897872925,
      -0.030277099460363388,
      -0.04447781294584274,
      -0.07811524718999863,
      -0.02187805250287056,
      -0.024547133594751358,
      -0.07233850657939911,
      0.06354590505361557,
      -0.03540085628628731,
      -0.04159398749470711,
      -0.013371027074754238,
      0.012149828486144543,
      -0.010447891429066658,
      0.04397207871079445,
      -0.029100455343723297,
      0.004990896210074425,
      -0.008856012485921383,
      0.012230453081429005,
      -0.054881662130355835,
      0.0348205529153347,
      -0.004213703330606222,
      0.032042134553194046,
      -0.020627789199352264,
      0.04601430520415306,
      -0.008694374933838844,
      0.09030307084321976,
      0.008900641463696957,
      0.009454579092562199,
      0.024154309183359146,
      -0.009381299838423729,
      -0.017467163503170013,
      -0.06743814051151276,
      -0.07501518726348877,
      -0.04126400128006935,
      -0.06496511399745941,
      0.06548765301704407,
      -0.026565004140138626,
      0.0007225799490697682,
      0.07997962087392807,
      0.027541743591427803,
      -0.04582236707210541,
      0.02794831432402134,
      -0.02281283400952816,
      -0.014036161825060844,
      -0.06475120782852173,
      0.030818065628409386,
      0.01296192966401577,
      0.036584824323654175,
      0.04075852781534195,
      0.06195032596588135,
      -0.03312866389751434,
      -0.005084378644824028,
      0.0007928645936772227,
      -0.012679412961006165,
      0.037551939487457275,
      0.0821344405412674,
      0.0032779155299067497,
      0.00859057530760765,
      0.07953773438930511,
      0.0651504248380661,
      -0.03474998101592064,
      0.008285418152809143,
      0.017281953245401382,
      0.04850450158119202,
      -0.024558255448937416,
      -0.004526631906628609,
      0.027908476069569588,
      0.1055205911397934,
      -0.008005628362298012,
      0.03920363262295723,
      0.020703112706542015,
      -0.05358107388019562,
      -0.015624376945197582,
      0.05425198748707771,
      0.044392991811037064,
      -0.04180893301963806,
      0.09014566242694855,
      0.014563114382326603,
      -0.030989496037364006,
      -0.09041725099086761,
      0.0644950419664383,
      0.012915029190480709,
      0.08890345692634583,
      -0.020975278690457344,
      -0.02155706100165844,
      0.08598129451274872,
      0.08821393549442291,
      0.014795731753110886,
      -0.03434688597917557,
      -0.07197323441505432,
      -0.07911673188209534,
      0.010492580011487007,
      -0.006978790275752544,
      0.025468045845627785,
      0.0009873174130916595,
      -0.021598108112812042,
      -0.026616040617227554,
      0.017096184194087982,
      -0.0700928270816803,
      -0.022836055606603622,
      0.0002334330347366631,
      -0.005724257789552212,
      0.026534197852015495,
      -0.06793173402547836,
      -0.07783924043178558,
      0.024541590362787247,
      0.03842829167842865,
      -0.025576116517186165,
      -0.03707226365804672,
      0.10710994899272919,
      0.06268735229969025,
      0.013164346106350422,
      0.0470055527985096,
      -0.0660339891910553,
      0.013924991711974144,
      0.02420605905354023,
      0.0004437972966115922,
      -0.00856717023998499,
      -0.10848607867956161,
      -0.06658178567886353,
      0.020757848396897316,
      0.06998959928750992,
      -0.04312211275100708,
      0.011316447518765926,
      -0.028637761250138283,
      0.014106948859989643,
      0.008291776292026043,
      0.05095347762107849,
      0.04431230202317238,
      -0.07809970527887344,
      -0.044286198914051056,
      0.034073133021593094,
      0.009954473003745079,
      -0.05447302386164665,
      0.013228071853518486,
      -0.047503162175416946,
      -0.018925238400697708,
      -0.03337011858820915,
      0.04222540184855461,
      0.040015652775764465,
      0.008354905992746353,
      -0.0881810113787651,
      0.025275466963648796,
      0.12187737971544266,
      -0.03181403875350952,
      0.08885789662599564,
      0.09723717719316483,
      -0.00483350595459342,
      0.027985498309135437,
      0.047023143619298935,
      -0.0006265751435421407,
      -0.06586941331624985,
      0.057071805000305176,
      -0.012452472932636738,
      0.008095724508166313,
      -0.04794365167617798,
      0.0642116591334343,
      0.042447563260793686,
      0.04763846471905708,
      -0.004080279730260372,
      -0.07573149353265762,
      -0.03262538090348244,
      0.07403113692998886,
      -0.05814557895064354,
      0.022864433005452156,
      -0.025341268628835678,
      -0.019052579998970032,
      0.007444270420819521,
      0.04556272178888321,
      0.06241407245397568,
      -0.04318833351135254,
      -0.05677486211061478,
      -0.04189031943678856,
      -0.09062401205301285,
      0.08133970201015472,
      -0.016256770119071007,
      -0.08664820343255997,
      0.04405719414353371,
      0.011395791545510292,
      -0.004456772934645414,
      -0.028366943821310997,
      0.03653852269053459,
      0.043334122747182846,
      -0.08362440019845963,
      0.12359222769737244,
      0.04671761021018028,
      0.02838393859565258,
      0.09593138098716736,
      -0.011767953634262085,
      -0.0595565140247345,
      0.013719981536269188,
      0.05762394517660141,
      0.036158595234155655,
      -0.023118065670132637,
      -0.08144489675760269,
      0.031047195196151733,
      -0.015757370740175247,
      0.07017578929662704,
      0.07737787067890167,
      0.005073265638202429,
      0.05185573175549507,
      0.02128254622220993,
      0.061220865696668625,
      -0.096775583922863,
      -0.02315518632531166,
      0.04034339636564255,
      0.004668549634516239,
      0.0098143070936203,
      -0.04422978684306145,
      0.025608448311686516,
      0.059630706906318665,
      -0.07193015515804291,
      0.03359396010637283,
      0.08909475058317184,
      0.06406471878290176,
      0.01598571613430977,
      0.003442266257479787,
      0.079723060131073,
      0.040657009929418564,
      0.013336880132555962,
      -0.016484059393405914,
      0.024464093148708344,
      -0.00020152151410002261,
      0.08387952297925949,
      -0.014175891876220703,
      -0.0099521828815341,
      0.01764873042702675,
      0.0554703027009964,
      0.06857111304998398,
      -0.06084655225276947,
      0.011529367417097092,
      -0.03091968037188053,
      0.022174086421728134,
      -0.08215070515871048,
      0.10883548110723495,
      0.02724345773458481,
      -0.029666271060705185,
      0.020794229581952095,
      0.07233154773712158,
      0.03844280540943146,
      -0.02007921412587166,
      -0.0864512175321579,
      -0.04605273902416229,
      0.04022364690899849,
      -0.027372779324650764,
      0.02572406455874443,
      0.0327879898250103,
      -0.02191353216767311,
      -4.677457400248386e-05,
      0.017691273242235184,
      0.12652155756950378,
      -0.04510510340332985,
      0.019173938781023026,
      0.041645802557468414,
      -0.06654868274927139,
      0.017740216106176376,
      0.016036638990044594,
      0.0102092195302248,
      -0.08420738577842712,
      -0.012265123426914215,
      0.0510890930891037,
      -0.03670266270637512,
      -0.02841123566031456,
      -0.022858688607811928,
      0.03592454269528389,
      -0.03721453994512558,
      -0.045751459896564484,
      -0.039250537753105164,
      -0.0057006594724953175,
      0.04325208440423012,
      -0.020127659663558006,
      -0.05902963504195213,
      -0.061280809342861176,
      -0.011391198262572289,
      -0.048985593020915985,
      -0.017706135287880898,
      -0.009733081795275211,
      -0.004686967469751835,
      -0.06220987066626549,
      -0.0021661091595888138,
      -0.0702536404132843,
      -0.007153783459216356,
      0.03930286318063736,
      -0.07361990958452225,
      -0.0020080157555639744,
      0.010800143703818321,
      -0.10920468717813492,
      0.0050292047671973705,
      -0.10296707600355148,
      -0.03548076003789902,
      -0.008240613155066967,
      0.024769406765699387,
      0.055440545082092285,
      -0.0510098859667778,
      -0.09595749527215958,
      -0.01838095113635063,
      -0.07987548410892487,
      -0.02648821286857128,
      0.07905714958906174,
      0.01552659086883068,
      0.037273142486810684,
      -0.05619189515709877,
      -0.03689998760819435,
      -0.07503359764814377,
      0.07089608162641525,
      0.08543577045202255,
      -0.011282665655016899,
      -0.04135240241885185,
      0.012286385521292686,
      0.022748982533812523,
      -0.028132902458310127,
      0.015365660190582275,
      0.03843894973397255,
      -0.002629551338031888,
      -0.01539879571646452,
      -0.06715641915798187,
      -0.047549761831760406,
      0.06311120092868805,
      0.016567539423704147,
      -0.005018588621169329,
      0.010577713139355183,
      0.004558483604341745,
      0.011464935727417469,
      -0.0360875129699707,
      0.12726883590221405,
      -0.015926267951726913,
      -0.017508018761873245,
      0.012866764329373837,
      -0.009394056163728237,
      -0.060376789420843124,
      -0.0003201875661034137,
      0.08469055593013763,
      -0.020037490874528885,
      0.06750041991472244,
      -0.03731582686305046,
      0.04766145721077919,
      0.02859637141227722,
      0.012953710742294788,
      -0.049967847764492035,
      -0.04317891225218773,
      -0.05564974248409271,
      0.0562262162566185,
      0.07436871528625488,
      0.00790498312562704,
      0.07159219682216644,
      -0.026849588379263878,
      -0.03425871953368187,
      0.017334962263703346,
      -0.0008674460113979876,
      -0.043102290481328964,
      0.038535334169864655,
      0.022237181663513184,
      -0.014956253580749035,
      0.02640523388981819,
      0.049901194870471954,
      0.04198227822780609,
      0.0016700660344213247,
      0.018257228657603264,
      0.046983230859041214,
      -0.02549062855541706,
      0.02767416648566723,
      -0.032153964042663574
    ],
    [
      -0.034026142209768295,
      0.007208185736089945,
      0.04542973265051842,
      0.00035999203100800514,
      0.06922470033168793,
      0.021152852103114128,
      -0.03908364474773407,
      -0.05878704786300659,
      -0.008897521533071995,
      -0.004165185149759054,
      0.03759784996509552,
      -0.017065688967704773,
      0.022010192275047302,
      0.04231015220284462,
      0.01496079470962286,
      0.028023984283208847,
      -0.007749163545668125,
      -0.007838602177798748,
      0.014422411099076271,
      0.014927092008292675,
      -0.0840381309390068,
      0.06949743628501892,
      -0.012469003908336163,
      0.06028536707162857,
      -0.033587221056222916,
      0.018380334600806236,
      -0.010771699249744415,
      0.1055828109383583,
      0.04697005823254585,
      0.08079708367586136,
      -0.06925444304943085,
      0.0037540746852755547,
      -0.010197104886174202,
      -0.008457733318209648,
      -0.014784576371312141,
      0.06563566625118256,
      0.023194603621959686,
      0.01708441972732544,
      0.03341465815901756,
      -0.07642214000225067,
      0.08718182146549225,
      0.006380659528076649,
      0.015584658831357956,
      0.051616400480270386,
      -0.0021137751173228025,
      0.010243530385196209,
      -0.13599242269992828,
      -0.012113852426409721,
      -0.08707353472709656,
      -0.02589057758450508,
      -0.014593265950679779,
      -0.06487513333559036,
      0.021338628605008125,
      0.01612311229109764,
      0.007308527361601591,
      -0.042143020778894424,
      -0.03740249201655388,
      0.010951790027320385,
      0.0019917599856853485,
      0.01868101768195629,
      -0.05527881160378456,
      -0.02166326344013214,
      0.053690191358327866,
      -0.08260823786258698,
      0.017687521874904633,
      0.029545875266194344,
      -0.09188512712717056,
      0.05809351056814194,
      0.0017130137421190739,
      -0.04321074113249779,
      0.04493560642004013,
      -0.018388377502560616,
      -0.0044196550734341145,
      0.048029448837041855,
      0.006925397086888552,
      0.1188252717256546,
      0.022666480392217636,
      -0.031051937490701675,
      0.0385824553668499,
      0.04634253680706024,
      -0.036454569548368454,
      -0.04446803405880928,
      -0.05520382151007652,
      0.024018337950110435,
      0.049288105219602585,
      -0.05133900046348572,
      -0.012198454700410366,
      -0.05268115922808647,
      -0.027018578723073006,
      -0.01856447570025921,
      -0.07143224030733109,
      0.1260117143392563,
      -0.06362392753362656,
      0.09989211708307266,
      -0.046957679092884064,
      0.008752201683819294,
      -0.03229086846113205,
      0.09192559868097305,
      -0.03409574180841446,
      0.014264787547290325,
      0.050881192088127136,
      -0.01656140573322773,
      0.030910348519682884,
      0.00129786622710526,
      0.024557719007134438,
      0.14029893279075623,
      -0.03771287947893143,
      0.07028771191835403,
      -0.03820454701781273,
      0.03928924351930618,
      -0.005725574214011431,
      0.0031727170571684837,
      -0.014543335884809494,
      -0.06190495938062668,
      -0.039308901876211166,
      -0.02093537710607052,
      0.05295534431934357,
      -0.059373948723077774,
      -0.041715458035469055,
      0.04613305628299713,
      0.03862321376800537,
      0.055615901947021484,
      -0.03703194856643677,
      0.06986355781555176,
      -0.03688966482877731,
      0.025291210040450096,
      -0.0003942818148061633,
      0.06600666046142578,
      0.04630797356367111,
      -0.07366353273391724,
      -0.046847615391016006,
      -0.047001369297504425,
      -0.03147606924176216,
      -0.008771571330726147,
      -0.026664134114980698,
      -0.015071570873260498,
      -0.0292018111795187,
      -0.006975153926759958,
      -0.05497262254357338,
      -0.010254370979964733,
      -0.009832074865698814,
      0.016244253143668175,
      0.040463849902153015,
      -0.05993805080652237,
      0.024859871715307236,
      0.04956922307610512,
      0.05155932158231735,
      0.0581372044980526,
      5.894156129215844e-05,
      -0.03558502346277237,
      0.042630985379219055,
      0.014387091621756554,
      0.016291223466396332,
      -0.01089733187109232,
      0.036055877804756165,
      -0.026488421484827995,
      -0.01967882364988327,
      -0.023645790293812752,
      -0.06498396396636963,
      -0.0446031279861927,
      0.04605456441640854,
      0.0003946814686059952,
      -0.025945086032152176,
      -0.044280052185058594,
      -0.08093174546957016,
      -0.0034998892806470394,
      0.0387783907353878,
      -0.023271866142749786,
      0.075101837515831,
      -0.05202215909957886,
      -0.004573946353048086,
      0.014041994698345661,
      -0.013982382602989674,
      -0.06994258612394333,
      -0.027297282591462135,
      -0.05446759611368179,
      -0.009556136094033718,
      0.10510756075382233,
      0.05168106034398079,
      0.019176002591848373,
      -0.07970378547906876,
      0.01669570989906788,
      -0.022486958652734756,
      -0.045070830732584,
      -0.010749605484306812,
      -0.020570624619722366,
      0.02717556059360504,
      0.00574351754039526,
      -0.05462286248803139,
      0.008179484866559505,
      0.04482981190085411,
      -0.07462374120950699,
      -0.04571365937590599,
      0.027105659246444702,
      0.04373219236731529,
      -0.0905969962477684,
      -0.07460656017065048,
      0.05405809357762337,
      -0.015159577131271362,
      -0.08650588989257812,
      0.06500130891799927,
      0.0058800233528018,
      0.11920691281557083,
      -0.03665733337402344,
      -0.07330977916717529,
      0.03999330848455429,
      -0.12101130187511444,
      -0.04682793468236923,
      0.003917038906365633,
      0.03149038925766945,
      -0.002024513203650713,
      0.0910755917429924,
      0.02163005620241165,
      -0.010658381506800652,
      -0.0028154694009572268,
      -0.001936615095473826,
      -0.021941756829619408,
      0.10677725076675415,
      -0.018288446590304375,
      -0.018936891108751297,
      0.058751292526721954,
      0.025801794603466988,
      -0.012633505277335644,
      -0.039895568042993546,
      -0.05686979740858078,
      -0.020576490089297295,
      0.11022346466779709,
      0.08347512036561966,
      0.021991997957229614,
      0.08125054836273193,
      -0.013192463666200638,
      0.04432898014783859,
      -0.005304896272718906,
      -0.008705799467861652,
      -0.08436258882284164,
      -0.1299000233411789,
      -0.027249343693256378,
      -0.012386600486934185,
      -0.008484860882163048,
      0.10259206593036652,
      0.02193048782646656,
      0.03260525315999985,
      0.01663382723927498,
      -0.017513316124677658,
      -0.045294493436813354,
      -0.038835156708955765,
      0.04196644574403763,
      0.08314376324415207,
      -0.01949452795088291,
      0.034932833164930344,
      0.04239117354154587,
      0.035914912819862366,
      0.0657045990228653,
      -0.023459896445274353,
      -0.05731296166777611,
      0.05964231491088867,
      0.022012313827872276,
      0.047545380890369415,
      -0.05917094647884369,
      -0.0448891818523407,
      0.0015274363104254007,
      0.08027677237987518,
      -0.06389478594064713,
      0.010093152523040771,
      -0.007571155671030283,
      -0.0022962698712944984,
      -0.0026026456616818905,
      -0.030236832797527313,
      0.025247052311897278,
      0.021515900269150734,
      0.035767942667007446,
      -0.011172291822731495,
      -0.025751030072569847,
      0.0017333654686808586,
      0.008154292590916157,
      -0.0507686622440815,
      -0.07757066935300827,
      -0.0035100479144603014,
      0.012448892928659916,
      -0.024317394942045212,
      -0.023115044459700584,
      -0.020188091322779655,
      -0.14555948972702026,
      0.05101489648222923,
      -0.055642347782850266,
      0.05984797328710556,
      0.09472914040088654,
      -0.008097975514829159,
      0.09158924967050552,
      0.04147429019212723,
      -0.013000660575926304,
      0.01868264190852642,
      -0.003747984068468213,
      0.020304881036281586,
      0.056237343698740005,
      0.04020509868860245,
      0.004493501037359238,
      0.03600544109940529,
      -0.0076677920296788216,
      0.06275087594985962,
      -0.055926788598299026,
      -0.05555402860045433,
      -0.045899566262960434,
      0.0612974539399147,
      -0.03743136674165726,
      -0.013903777115046978,
      0.01794149912893772,
      -0.03209333494305611,
      -0.07963716983795166,
      -0.04650469124317169,
      0.029533015564084053,
      -0.009192381985485554,
      0.009515292011201382,
      0.04236685857176781,
      0.013422968797385693,
      0.019436564296483994,
      0.018426692113280296,
      -0.044806066900491714,
      -0.028654878959059715,
      -0.05321356654167175,
      -0.0569019578397274,
      0.00013007692177779973,
      0.06916383653879166,
      0.05711955577135086,
      0.025564521551132202,
      0.04920165613293648,
      -0.021868402138352394,
      0.0956723764538765,
      0.00620518671348691,
      0.08751991391181946,
      -0.005703617352992296,
      -0.07416590303182602,
      0.0230405293405056,
      0.02952091209590435,
      -0.0186025258153677,
      -0.0032073291949927807,
      0.013464397750794888,
      0.051046889275312424,
      0.06161203607916832,
      -0.01928313635289669,
      -0.006587926298379898,
      0.07734451442956924,
      -0.08350056409835815,
      -0.04675823822617531,
      -0.059930603951215744,
      -0.04938601329922676,
      0.047620560973882675,
      -0.04072393849492073,
      0.07395417243242264,
      -0.020752614364027977,
      -0.0344734862446785,
      0.00631276099011302,
      -0.014959758147597313,
      0.0627569630742073,
      0.032755572348833084,
      0.018766311928629875,
      -0.026266004890203476,
      0.0039328536950051785,
      -0.010215389542281628,
      -0.019243203103542328,
      -0.004245372489094734,
      -0.06294272094964981,
      -0.06531070172786713,
      -0.011831589043140411,
      0.07071994245052338,
      -0.02635093964636326,
      -0.006903678644448519,
      0.0241400096565485,
      -0.02918398380279541,
      -0.020864641293883324,
      -0.05191757157444954,
      -0.029164837673306465,
      -0.0306907519698143,
      -0.01615036651492119,
      0.00944517832249403,
      0.1476590931415558,
      0.030819624662399292,
      -0.0029984007123857737,
      -0.020938992500305176,
      0.006670069415122271,
      0.03560931980609894,
      -0.008968999609351158,
      -0.00586847634986043,
      -0.03482900187373161,
      -0.05905354022979736,
      0.07486852258443832,
      -0.0016821548342704773,
      -0.002589705865830183,
      -0.0028638613875955343,
      -0.0037694862112402916,
      0.02531459927558899,
      0.014934565871953964,
      -0.012077528052031994,
      -0.03555216267704964,
      -0.03351379558444023,
      -0.049478866159915924,
      0.02655879035592079,
      -0.030123258009552956,
      -0.03293633833527565,
      0.05534232780337334,
      0.0011599324643611908,
      0.004646900575608015,
      -0.04658997803926468,
      0.014494734816253185,
      -0.0328795462846756,
      -0.02403653971850872,
      0.10917194187641144,
      -0.06276265531778336,
      0.017653293907642365,
      -0.00013481108180712909,
      0.02951565571129322,
      0.029152262955904007,
      0.0604858361184597,
      -0.056820888072252274,
      -0.018413875252008438,
      0.035760462284088135,
      0.06461462378501892,
      -0.061474982649087906,
      -0.011034325696527958,
      -0.001170209376141429,
      -0.022236289456486702,
      -0.0021021366119384766,
      -0.001452492899261415,
      0.01962909661233425,
      -0.0642857775092125,
      0.06530129164457321,
      -0.04475415498018265,
      -0.03253067657351494,
      -0.007496689446270466,
      0.017833059653639793,
      0.005958892405033112,
      0.023338397964835167,
      -0.04332215338945389,
      -0.051994748413562775,
      0.038637422025203705,
      -0.04209010675549507,
      -0.032708413898944855,
      -0.03555354103446007,
      -0.010992009192705154,
      0.1283501535654068,
      0.005718992557376623,
      -0.047805238515138626,
      0.034385375678539276,
      0.03604479506611824,
      0.05465269088745117,
      0.052685607224702835,
      0.04485754296183586,
      0.001340297982096672,
      0.013109085150063038,
      -0.02929987758398056,
      -0.00020537711679935455,
      -0.02651531808078289,
      0.044092703610658646,
      -0.02206319570541382,
      -0.0037368200719356537,
      -0.05318507179617882,
      0.005879516713321209,
      -0.07167329639196396,
      -0.007792625110596418,
      0.006885334383696318,
      0.012462213635444641,
      -0.041607800871133804,
      -0.08508669584989548,
      -0.12458260357379913,
      0.014328842051327229,
      0.007286878302693367,
      -0.033986255526542664,
      0.03600473329424858,
      0.034804102033376694,
      -0.02209705114364624,
      -0.0428118072450161,
      0.029383620247244835,
      -0.046016596257686615,
      0.03576970100402832,
      -0.0737590566277504,
      -0.004180832765996456,
      0.017139485105872154,
      0.019723698496818542,
      0.011713661253452301,
      -0.007165696006268263,
      0.06085159257054329,
      -0.04069675877690315,
      -0.0014452537288889289,
      -0.032792557030916214,
      0.0035473129246383905,
      0.03323851898312569,
      0.024058984592556953,
      -0.035768721252679825,
      0.009222370572388172,
      -0.004142307210713625,
      0.012903565540909767,
      -0.022725358605384827,
      0.03526747599244118,
      0.014021138660609722,
      -0.035438183695077896,
      -0.006181878503412008,
      -0.04358372092247009,
      -0.02338225208222866,
      0.047628775238990784,
      0.011403387412428856,
      -0.010641178116202354,
      0.0864458903670311,
      0.02356892079114914
    ],
    [
      -0.043347734957933426,
      -0.08117588609457016,
      0.03575765714049339,
      -0.0231777373701334,
      0.014878863468766212,
      0.03253207728266716,
      -0.004924410954117775,
      0.0007329701911658049,
      0.01807355135679245,
      0.030719585716724396,
      -0.0575735978782177,
      0.0272526778280735,
      -0.00322496029548347,
      -0.10571166127920151,
      -0.027507534250617027,
      0.017079971730709076,
      -0.037615999579429626,
      0.005985654424875975,
      0.027511699125170708,
      -0.013235253281891346,
      -0.06021692603826523,
      -0.05187467485666275,
      0.02054862678050995,
      0.05715453624725342,
      0.05432267114520073,
      0.020948387682437897,
      -0.0019574910402297974,
      -0.005556529853492975,
      -0.013253480195999146,
      0.014981336891651154,
      -0.021192660555243492,
      0.05130182206630707,
      0.05150192603468895,
      -0.06104324012994766,
      0.039592958986759186,
      -0.030993500724434853,
      0.05816512182354927,
      -0.005439355503767729,
      0.0013925319071859121,
      -0.09516270458698273,
      0.10989218205213547,
      -0.021394431591033936,
      0.05327199772000313,
      -0.056745659559965134,
      0.08296462893486023,
      -0.0296992976218462,
      -0.04389631375670433,
      0.016049502417445183,
      -0.019084462895989418,
      0.021348444744944572,
      -0.032197896391153336,
      -0.012737836688756943,
      0.010397800244390965,
      -0.028405308723449707,
      0.009781203232705593,
      0.014517046511173248,
      -0.07381683588027954,
      -0.05563432723283768,
      -0.022939153015613556,
      0.07165630906820297,
      -0.003833604510873556,
      0.007401040755212307,
      -0.00021428194304462522,
      -0.019055411219596863,
      0.01217025239020586,
      -0.011176465079188347,
      0.038877882063388824,
      -0.017028093338012695,
      0.03876848518848419,
      -0.03453593701124191,
      -0.05030544474720955,
      -0.04095036908984184,
      0.036353036761283875,
      -0.1004742830991745,
      0.0017202936578541994,
      -0.0006387142348103225,
      0.024100251495838165,
      9.351424523629248e-05,
      0.003339990973472595,
      -0.0015389835461974144,
      -0.0257419403642416,
      -0.014559867791831493,
      -0.046862103044986725,
      0.054302066564559937,
      -0.0015021084109321237,
      -0.06877364218235016,
      -0.01585223153233528,
      0.012074079364538193,
      0.030729912221431732,
      -0.05694306269288063,
      -0.02325325831770897,
      0.08296147733926773,
      -0.06185942888259888,
      0.007381287869066,
      -0.17383025586605072,
      -0.014119640924036503,
      0.06828606873750687,
      0.03949959576129913,
      -0.01869293302297592,
      -0.01887786202132702,
      0.010740868747234344,
      -0.013227549381554127,
      0.021087588742375374,
      0.030902575701475143,
      0.023855166509747505,
      0.05420466512441635,
      -0.07113305479288101,
      0.02267717383801937,
      -0.01609642244875431,
      -0.008790813386440277,
      -0.030650781467556953,
      0.011881918646395206,
      0.04130137339234352,
      0.013961901888251305,
      0.039457108825445175,
      0.024379072710871696,
      0.037284739315509796,
      0.01115039736032486,
      -0.059994667768478394,
      -0.03592784330248833,
      -0.08055691421031952,
      0.012873281724750996,
      -0.01815882883965969,
      -0.005166857037693262,
      -0.05717163532972336,
      0.005902501288801432,
      -0.04104846715927124,
      0.0096483388915658,
      -0.0021394556388258934,
      0.00512028718367219,
      0.005278580356389284,
      0.08157432824373245,
      -0.04702986031770706,
      0.015336555428802967,
      -0.0570937842130661,
      0.005759903695434332,
      0.08208931982517242,
      0.07035093754529953,
      -0.11984667927026749,
      -0.0506187304854393,
      0.08674856275320053,
      0.058389149606227875,
      -0.0010886022355407476,
      -0.03702573478221893,
      -0.0010219532996416092,
      0.08202829957008362,
      -0.016084037721157074,
      0.0076447161845862865,
      -0.025711197406053543,
      0.04510430991649628,
      -0.06911791861057281,
      -0.0782555341720581,
      0.014180123805999756,
      -0.02938048355281353,
      -0.0015073270769789815,
      0.00895251240581274,
      0.10194903612136841,
      -0.02900065667927265,
      0.015834767371416092,
      -0.015225373208522797,
      -0.14690326154232025,
      -0.004065399058163166,
      -0.020637013018131256,
      -0.07016640156507492,
      -0.09503009915351868,
      0.024442050606012344,
      0.001241008983924985,
      -0.010738400742411613,
      0.050199709832668304,
      0.01288633979856968,
      -0.03189730644226074,
      0.013382638804614544,
      -0.04716718941926956,
      -0.04773185774683952,
      -0.04752152040600777,
      -0.08440936356782913,
      -0.06505443155765533,
      0.014259916730225086,
      0.0031371708028018475,
      0.04899713024497032,
      0.018433524295687675,
      -0.0824262946844101,
      0.026856042444705963,
      -0.00016275467351078987,
      0.033447299152612686,
      -0.03702711686491966,
      0.0228758342564106,
      0.07027479261159897,
      0.007250837050378323,
      0.005646139848977327,
      -0.012129641138017178,
      -0.06180422753095627,
      0.00598728796467185,
      -0.010593878105282784,
      -0.07140692323446274,
      0.023989351466298103,
      0.06357285380363464,
      0.025875795632600784,
      -0.09071853756904602,
      -0.04025807976722717,
      0.026416173204779625,
      0.075717993080616,
      0.06197750195860863,
      -0.011794374324381351,
      0.0795859545469284,
      0.02104167640209198,
      0.05878238007426262,
      0.013490456156432629,
      -0.0539088174700737,
      -0.04061422869563103,
      0.030727339908480644,
      0.03112800233066082,
      0.03283024951815605,
      -0.01223778072744608,
      0.028187483549118042,
      -0.02531953528523445,
      -0.04564683511853218,
      0.10107146948575974,
      -0.0031412318348884583,
      -0.005054152105003595,
      0.06144654378294945,
      -0.026094142347574234,
      0.014993245713412762,
      -0.030309492722153664,
      -0.002715631853789091,
      -0.028037140145897865,
      0.01255012582987547,
      -0.016515785828232765,
      -0.012511754408478737,
      -0.07096823304891586,
      0.03503706678748131,
      -0.09880387037992477,
      0.0341031514108181,
      0.0018514306284487247,
      0.10847938805818558,
      -0.10296407341957092,
      -0.030665507540106773,
      -0.008474555797874928,
      -0.00973235722631216,
      -0.021853679791092873,
      -0.013254204764962196,
      -0.04353751242160797,
      -0.0086293276399374,
      -0.015936415642499924,
      -0.019619958475232124,
      -0.000269121490418911,
      -0.029877018183469772,
      0.04407911375164986,
      0.0577491894364357,
      0.04191891849040985,
      0.08183528482913971,
      0.08171825855970383,
      0.04051857814192772,
      -0.026390084996819496,
      -0.017005154862999916,
      -0.08963358402252197,
      -0.04538920149207115,
      -0.03413144499063492,
      0.02172735705971718,
      -0.025970574468374252,
      -0.035805843770504,
      -0.0016343514434993267,
      0.010661007836461067,
      0.04721199721097946,
      0.00598227558657527,
      -0.00946766510605812,
      -0.013765847310423851,
      -0.08569616824388504,
      -0.06361221522092819,
      0.025025105103850365,
      0.01747712306678295,
      0.054026950150728226,
      0.011621515266597271,
      0.07611091434955597,
      -0.02039780467748642,
      0.06500612944364548,
      0.034334946423769,
      -0.0013840127503499389,
      -0.017427528277039528,
      0.07487532496452332,
      -0.03771809861063957,
      0.0166013166308403,
      0.03634136915206909,
      0.008344117552042007,
      -0.05190742388367653,
      0.05976038798689842,
      0.015020820312201977,
      -0.02046574093401432,
      -0.10076595842838287,
      0.007433136459439993,
      0.030953388661146164,
      0.07922887057065964,
      -0.022063352167606354,
      0.006481077056378126,
      0.0017145213205367327,
      -0.049308113753795624,
      -0.04319491982460022,
      -0.005569163244217634,
      7.553730392828584e-05,
      -0.024890435859560966,
      -0.045308325439691544,
      0.031850043684244156,
      0.03476222977042198,
      0.04889020696282387,
      -0.07065168768167496,
      0.03526340425014496,
      -7.26489452063106e-05,
      0.044850561767816544,
      -0.038763172924518585,
      -0.020179294049739838,
      0.0858614593744278,
      -0.06614964455366135,
      0.02782471850514412,
      0.0037945574149489403,
      -0.01260468177497387,
      0.024921104311943054,
      0.05323271453380585,
      -0.04173417389392853,
      -0.035981304943561554,
      0.021387746557593346,
      -0.015639090910553932,
      -0.01828855089843273,
      -0.02276957780122757,
      0.009778591804206371,
      0.01911572553217411,
      -0.004701174329966307,
      0.09465668350458145,
      0.028645005077123642,
      -0.02566695399582386,
      0.0769842267036438,
      -0.02810078300535679,
      -0.04105805233120918,
      -0.004744232166558504,
      0.002108160173520446,
      -0.0007926356047391891,
      -0.0009078659350052476,
      -0.014052337035536766,
      -0.004367974121123552,
      -0.008012419566512108,
      0.02674942836165428,
      -0.0036995471455156803,
      -0.06941982358694077,
      0.04173208028078079,
      -0.09143268316984177,
      -0.09004632383584976,
      0.07254284620285034,
      0.040656812489032745,
      -0.03213905170559883,
      -0.047622136771678925,
      -0.03687947988510132,
      0.005361371673643589,
      0.019227679818868637,
      -0.009878301993012428,
      0.025884827598929405,
      -0.048885833472013474,
      0.005216789431869984,
      -0.009746726602315903,
      0.04009284824132919,
      0.06769416481256485,
      -0.010187337175011635,
      0.012065516784787178,
      0.04213207960128784,
      -0.07827907055616379,
      -0.0352027527987957,
      -0.06141971796751022,
      0.00918560940772295,
      -0.008211344480514526,
      -0.03272752836346626,
      -0.04412844777107239,
      0.0106422184035182,
      -0.060961779206991196,
      0.034998249262571335,
      -0.007574666757136583,
      -0.06150854751467705,
      0.049065668135881424,
      0.04113231226801872,
      0.008829625323414803,
      -0.037120528519153595,
      0.02921253815293312,
      -0.040800388902425766,
      0.014961752109229565,
      -0.00918483640998602,
      0.0024033181834965944,
      0.03900642693042755,
      0.025119749829173088,
      0.02294820360839367,
      -0.01533601712435484,
      0.014988492242991924,
      0.0006919797160662711,
      0.04594630375504494,
      -0.022633416578173637,
      0.05325070768594742,
      -0.016379447653889656,
      -0.03481888398528099,
      -0.026160266250371933,
      0.009302707388997078,
      0.0011897374643012881,
      0.02184172160923481,
      -0.038305897265672684,
      -0.021413523703813553,
      0.015041399747133255,
      0.056361179798841476,
      -0.002578576561063528,
      -0.02085975371301174,
      0.024247612804174423,
      0.024838263168931007,
      -0.029103906825184822,
      -0.08276641368865967,
      -0.04151949658989906,
      -0.0013286970788612962,
      -0.03847038000822067,
      0.05134642869234085,
      0.039075642824172974,
      -0.03899913281202316,
      -0.010338161140680313,
      0.01338113285601139,
      -0.0756412073969841,
      0.02129918895661831,
      -0.03399767726659775,
      -0.028100058436393738,
      -0.013036518357694149,
      -0.07288803160190582,
      0.04085369408130646,
      0.026563890278339386,
      0.06740018725395203,
      0.06043525040149689,
      -0.01499381847679615,
      -0.03163697570562363,
      -0.017339147627353668,
      -0.009171445854008198,
      -0.003938819281756878,
      0.016338514164090157,
      0.02452702820301056,
      0.009206760674715042,
      0.07846521586179733,
      -0.012196510098874569,
      -0.08750468492507935,
      -0.04866392910480499,
      0.00290910666808486,
      0.027406446635723114,
      0.012208056636154652,
      -0.004441889002919197,
      -0.08320385962724686,
      0.005825742147862911,
      0.0030725656542927027,
      -0.03573138639330864,
      -0.05015752092003822,
      -0.07209767401218414,
      -0.016914285719394684,
      0.07587865740060806,
      -0.015792259946465492,
      0.09499020874500275,
      -0.028051098808646202,
      0.0320882722735405,
      -0.054595381021499634,
      -0.03839491680264473,
      0.10963742434978485,
      0.016636276617646217,
      -0.023992061614990234,
      -0.005619404837489128,
      -0.03450755402445793,
      0.07256446778774261,
      -0.009423098526895046,
      0.04776952415704727,
      -0.01726161502301693,
      -0.08960895240306854,
      0.006026819348335266,
      0.017342189326882362,
      -0.12123282998800278,
      0.012834735214710236,
      -0.023602908477187157,
      0.01431364193558693,
      0.03023715689778328,
      0.026024922728538513,
      -0.013488717377185822,
      0.0002968868357129395,
      -0.044226985424757004,
      0.013970606960356236,
      -0.06778023391962051,
      0.009252171032130718,
      0.05215233936905861,
      0.011949998326599598,
      0.04872376471757889,
      -0.03245957940816879,
      -0.0714876651763916,
      0.0035031118895858526,
      0.04648608714342117,
      -0.02302228845655918,
      -0.0026280211750417948,
      0.011592546477913857,
      0.004773995373398066,
      -0.00689653167501092,
      0.04256702587008476,
      -0.026847586035728455,
      -0.10607855021953583,
      -0.02575778029859066,
      0.05977530777454376,
      0.11662551760673523,
      -0.07944130152463913,
      -0.02375941351056099,
      0.0859755352139473,
      0.06087153032422066,
      -0.014455527998507023
    ],
    [
      -0.11770881712436676,
      -0.0011122386204078794,
      -0.015898950397968292,
      -0.0755813866853714,
      -0.020152363926172256,
      0.05325460433959961,
      0.05796859413385391,
      0.01392367947846651,
      -0.05735960975289345,
      -0.0558193065226078,
      0.002741083037108183,
      0.0005460747051984072,
      0.018247876316308975,
      -0.12286534905433655,
      0.0004986192216165364,
      0.007559056859463453,
      0.02366955392062664,
      0.006372661329805851,
      0.005639276932924986,
      -0.09198307245969772,
      0.012120652943849564,
      -0.03826877474784851,
      -0.04565604031085968,
      -0.016805686056613922,
      -0.05423774570226669,
      -0.03931109234690666,
      -0.005252856761217117,
      0.06057433411478996,
      0.07142254710197449,
      -0.025024358183145523,
      0.12161780893802643,
      -0.017514869570732117,
      0.0035595607478171587,
      -0.042782023549079895,
      -0.0058937398716807365,
      -0.029614459723234177,
      -0.007508046925067902,
      0.018131321296095848,
      0.010329604148864746,
      0.009786151349544525,
      -0.03475043922662735,
      0.010231870226562023,
      -0.04251193627715111,
      0.026547886431217194,
      -0.009616300463676453,
      -0.03908864036202431,
      -0.03224317356944084,
      -0.03506987914443016,
      -0.05310080200433731,
      -0.04681597650051117,
      -0.024167081341147423,
      -0.01839444972574711,
      -0.029386190697550774,
      -0.02837330289185047,
      -0.025026706978678703,
      -0.014552866108715534,
      0.007865721359848976,
      -0.07058615237474442,
      0.02774704620242119,
      0.006791406776756048,
      -0.026626506820321083,
      0.01862390525639057,
      -0.08063891530036926,
      0.019234903156757355,
      0.02124105952680111,
      -0.07910417765378952,
      -0.08346328884363174,
      0.021115506067872047,
      0.017629746347665787,
      -0.036146942526102066,
      0.03423473611474037,
      -0.009272393770515919,
      0.035895876586437225,
      -0.02984650805592537,
      -0.04085154086351395,
      -0.023654280230402946,
      0.029716752469539642,
      -0.08869434893131256,
      0.05690582096576691,
      0.012543166987597942,
      0.008703958243131638,
      -0.03847623988986015,
      0.10706153512001038,
      0.04866929352283478,
      0.009700541384518147,
      -0.02179105207324028,
      -0.07832551747560501,
      0.05690108612179756,
      -0.008104388602077961,
      0.016414035111665726,
      -0.02514788880944252,
      0.0034610596485435963,
      0.03580811247229576,
      -0.021722663193941116,
      -0.024207111448049545,
      0.004713062662631273,
      -0.03515200316905975,
      -0.05940428748726845,
      0.02403002232313156,
      0.009026918560266495,
      0.03628392145037651,
      0.03725837543606758,
      0.010730727575719357,
      0.05671973526477814,
      -0.04110867530107498,
      -0.04569568857550621,
      -0.08281277865171432,
      0.045478977262973785,
      0.012182959355413914,
      0.008971289731562138,
      0.08686567842960358,
      -0.008938473649322987,
      -0.00997977890074253,
      -0.00851090531796217,
      -0.04391123726963997,
      0.06536472588777542,
      0.012909671291708946,
      0.03152397274971008,
      -0.020580586045980453,
      -0.031609002500772476,
      0.02113213576376438,
      0.005291862413287163,
      -0.014276735484600067,
      -0.03065507858991623,
      0.013840094208717346,
      0.08746404200792313,
      -0.02734752744436264,
      -0.026970839127898216,
      0.052089378237724304,
      -0.06697056442499161,
      0.020191114395856857,
      -0.010815032757818699,
      -0.02018398977816105,
      -0.032905519008636475,
      0.01380300335586071,
      -0.03516504168510437,
      0.053424637764692307,
      0.05024923384189606,
      0.03798573836684227,
      -0.03604748845100403,
      0.008585251867771149,
      0.005079991649836302,
      -0.053598448634147644,
      0.029999759048223495,
      0.0005279287579469383,
      -0.0018520895391702652,
      0.04830421879887581,
      0.005498067941516638,
      0.016775865107774734,
      0.0035748404916375875,
      0.043824732303619385,
      -0.06544364243745804,
      -0.07607092708349228,
      -0.02622058242559433,
      0.03452196344733238,
      0.06989533454179764,
      0.01139199361205101,
      0.025946874171495438,
      -0.04748431593179703,
      -0.05796544998884201,
      -0.06690476089715958,
      -0.070187047123909,
      0.06063256412744522,
      -0.06199845299124718,
      0.06737398356199265,
      0.005429168697446585,
      -0.02806766889989376,
      -0.03229628875851631,
      -0.004397389944642782,
      -0.033596307039260864,
      0.0708865225315094,
      -0.09384478628635406,
      -0.012679887004196644,
      -0.04634362831711769,
      0.007516480982303619,
      0.03216810151934624,
      0.07203994691371918,
      0.07539395242929459,
      0.02353205345571041,
      -0.025587256997823715,
      0.061865851283073425,
      -0.09973382204771042,
      0.06015271320939064,
      -0.05511265620589256,
      -0.04856037348508835,
      -0.01581917330622673,
      0.017163580283522606,
      0.012759124860167503,
      0.026723673567175865,
      0.09086278825998306,
      -0.033166732639074326,
      -0.006996946409344673,
      -0.05827701836824417,
      -0.04585309326648712,
      -0.006684598047286272,
      0.09134001284837723,
      0.1125202402472496,
      -0.0009244272951036692,
      0.02419140748679638,
      0.007908925414085388,
      0.06704555451869965,
      -0.021069929003715515,
      0.02743588574230671,
      -0.020538073033094406,
      0.05195903778076172,
      -0.015378567390143871,
      0.10512147843837738,
      0.012399205937981606,
      0.00948264915496111,
      -0.0073427073657512665,
      -0.06422177702188492,
      0.03675278276205063,
      -0.052556540817022324,
      0.08560271561145782,
      0.0017772646388038993,
      0.016953611746430397,
      -0.01513028796762228,
      0.06832398474216461,
      -0.009656302630901337,
      -0.08784975111484528,
      -0.027400091290473938,
      -0.007176317274570465,
      0.007564215920865536,
      0.003166980342939496,
      0.03471362218260765,
      0.011340903118252754,
      0.08209756761789322,
      -0.004592372104525566,
      -0.01940581575036049,
      0.002942023565992713,
      -0.006270506884902716,
      0.050389472395181656,
      0.06400283426046371,
      -0.02874976396560669,
      0.021395329385995865,
      0.028788387775421143,
      0.05708755925297737,
      0.06244569271802902,
      -0.03222158923745155,
      -0.012625647708773613,
      -0.07616478204727173,
      -0.004022741224616766,
      0.014827298931777477,
      -0.03614620491862297,
      -0.006817320827394724,
      0.05946981906890869,
      0.013863562606275082,
      0.059898555278778076,
      0.009547080844640732,
      -0.03005664050579071,
      0.04117686301469803,
      0.033665359020233154,
      0.0634075254201889,
      -0.01748986355960369,
      -0.025257498025894165,
      0.012846897356212139,
      -0.042595136910676956,
      0.06256867200136185,
      -0.03974707052111626,
      -0.03385123610496521,
      -0.022101668640971184,
      -0.018544046208262444,
      0.07381395995616913,
      0.0892380028963089,
      0.008095437660813332,
      -0.023593610152602196,
      0.07489532977342606,
      -0.036584172397851944,
      0.04363900423049927,
      0.06854546070098877,
      -0.0007741297595202923,
      0.027839237824082375,
      0.08921556174755096,
      -0.047779884189367294,
      0.05374515429139137,
      -0.011358112096786499,
      0.0033175162971019745,
      -0.06706010550260544,
      0.07100681215524673,
      -0.09020977467298508,
      -0.01632414199411869,
      0.09612532705068588,
      -0.036599405109882355,
      0.021154984831809998,
      -0.09651599824428558,
      0.0292576365172863,
      0.05345037579536438,
      -0.11079150438308716,
      -0.042657092213630676,
      -0.005231214687228203,
      0.06385355442762375,
      0.04703022539615631,
      0.04576095938682556,
      0.019059935584664345,
      0.03545372560620308,
      0.08014119416475296,
      0.0901893675327301,
      0.022648073732852936,
      -0.10942742973566055,
      -0.023834632709622383,
      -0.024757148697972298,
      0.1062188521027565,
      -0.10421285778284073,
      0.08277074992656708,
      0.00970175489783287,
      -0.016055913642048836,
      0.04358668625354767,
      0.004640011582523584,
      -0.042831409722566605,
      -0.034382760524749756,
      0.00684008514508605,
      -0.0021069571375846863,
      -0.028945500031113625,
      0.042904265224933624,
      0.06086243316531181,
      0.05803541839122772,
      -0.0393085777759552,
      0.012228516861796379,
      -0.016612384468317032,
      -0.00689797755330801,
      -0.03493453562259674,
      -0.07808014005422592,
      -0.062302298843860626,
      0.11777429282665253,
      -0.04859774559736252,
      -0.04206543415784836,
      -0.10103370249271393,
      -0.0106419762596488,
      0.03437890112400055,
      0.04200838878750801,
      0.00483747199177742,
      0.03730708360671997,
      0.04275179281830788,
      0.0009838511468842626,
      0.0034984678495675325,
      0.07159987092018127,
      0.057042546570301056,
      0.004896319005638361,
      0.03599917143583298,
      0.0044383094646036625,
      0.005278678145259619,
      0.05261409655213356,
      -0.007890156470239162,
      -0.06368878483772278,
      0.08597803115844727,
      0.03744424134492874,
      -0.027129527181386948,
      -0.08760859817266464,
      0.0379626490175724,
      0.04669564217329025,
      -0.04387303814291954,
      0.011252160184085369,
      0.0029329934623092413,
      0.012453089468181133,
      -0.0011916321236640215,
      -0.01714787818491459,
      -0.03652024269104004,
      -0.005887878593057394,
      0.043999794870615005,
      0.061043862253427505,
      0.024614963680505753,
      -0.040457259863615036,
      -0.039667245000600815,
      0.051392827183008194,
      0.04633553698658943,
      -0.07962052524089813,
      -0.01737150549888611,
      -0.10120229423046112,
      0.0024681363720446825,
      0.051307037472724915,
      -0.03038228489458561,
      0.013722928240895271,
      0.06620471924543381,
      -0.08383285254240036,
      0.0137264933437109,
      0.09223158657550812,
      0.06351807713508606,
      -0.015429629012942314,
      -0.08447088301181793,
      -0.07441378384828568,
      0.0666385143995285,
      0.050992146134376526,
      -0.022440215572714806,
      0.07482663542032242,
      0.012856539338827133,
      0.027418142184615135,
      0.028362078592181206,
      0.07545671612024307,
      0.0017355128657072783,
      0.001817110227420926,
      0.025067856535315514,
      0.04941357672214508,
      -0.04066859185695648,
      -0.04623167961835861,
      0.054128699004650116,
      0.05083160847425461,
      0.03229723498225212,
      -0.05568958446383476,
      -0.0738711878657341,
      0.00875177700072527,
      0.04340239241719246,
      0.07044801115989685,
      -0.09831828624010086,
      -0.08282479643821716,
      -0.005767097696661949,
      -0.009513594210147858,
      0.06642277538776398,
      -0.06030713766813278,
      0.12052367627620697,
      -0.0040004500187933445,
      0.000735179171897471,
      -0.04251011461019516,
      0.03131953254342079,
      0.0531095489859581,
      -0.07607369124889374,
      -0.024404292926192284,
      0.022945117205381393,
      -0.024614041671156883,
      -0.011983353644609451,
      -0.020362498238682747,
      0.012893746607005596,
      0.0027224519290030003,
      -0.02953997440636158,
      -0.04629502817988396,
      -0.06158871576189995,
      0.024022240191698074,
      0.028488073498010635,
      -0.060500387102365494,
      0.09891774505376816,
      0.0002157019916921854,
      -0.02026323787868023,
      0.012678288854658604,
      0.01753912679851055,
      0.05701536312699318,
      -0.052198875695466995,
      -0.060774754732847214,
      -0.028628919273614883,
      -0.09288536012172699,
      0.028533412143588066,
      -0.012504655867815018,
      0.06582064181566238,
      0.045384686440229416,
      0.00884500052779913,
      0.07785449177026749,
      -0.013343529775738716,
      -0.00470003904774785,
      -0.06834113597869873,
      0.03656935691833496,
      0.055984675884246826,
      0.04678906127810478,
      0.024354346096515656,
      0.09968473017215729,
      0.06340571492910385,
      0.03763974457979202,
      -0.047499630600214005,
      0.0006513380794785917,
      0.009782133623957634,
      -0.04731424152851105,
      0.057501379400491714,
      -0.034505438059568405,
      -0.07714742422103882,
      0.06387561559677124,
      -0.015187213197350502,
      0.025486746802926064,
      -0.09121343493461609,
      0.01992732100188732,
      -0.032864201813936234,
      -0.023000352084636688,
      0.01663171872496605,
      0.017770104110240936,
      -0.00793473981320858,
      0.040170565247535706,
      0.05802307650446892,
      -0.05982181802392006,
      -0.023089107125997543,
      0.017618246376514435,
      -0.07494524121284485,
      -0.035624656826257706,
      -0.010056372731924057,
      -0.005110600031912327,
      -0.07436983287334442,
      0.0006370499613694847,
      0.026440847665071487,
      0.0432434007525444,
      -0.03408854082226753,
      0.08198307454586029,
      -0.05272301658987999,
      -0.11954318732023239,
      -0.06789353489875793,
      0.08489923179149628,
      -0.008966835215687752,
      0.0022589233703911304,
      0.05611557140946388,
      -0.08834073692560196,
      0.020633134990930557,
      -0.023344114422798157,
      0.01926387846469879,
      0.028613511472940445,
      -0.060620129108428955,
      0.04920792207121849,
      0.050751928240060806,
      -0.007088506128638983,
      0.004287058021873236
    ],
    [
      0.0010834896238520741,
      0.010267714038491249,
      0.08737929910421371,
      -0.1282484382390976,
      -0.09505070745944977,
      -0.03689122572541237,
      0.030942402780056,
      0.025207972154021263,
      -0.05292734131217003,
      0.01160464622080326,
      -0.0373479500412941,
      -0.017792245373129845,
      -0.025331418961286545,
      -0.10583902895450592,
      -0.05006531625986099,
      -0.01061316765844822,
      0.024430299177765846,
      -0.04984264075756073,
      0.06500095874071121,
      -0.0564771331846714,
      -0.04115818440914154,
      0.003934913314878941,
      -0.013928321190178394,
      0.01972281187772751,
      -0.0003330807958263904,
      -0.024202890694141388,
      -0.04088876023888588,
      0.09630798548460007,
      0.048431865870952606,
      0.02393893152475357,
      0.04743922874331474,
      -0.012427477166056633,
      -0.03728841617703438,
      -0.005051511339843273,
      0.037013474851846695,
      0.03305619955062866,
      -0.030349791049957275,
      0.045186083763837814,
      -0.04375987872481346,
      0.043942224234342575,
      0.049218885600566864,
      -0.056603964418172836,
      -0.022605644538998604,
      -0.015914665535092354,
      -0.00021228734112810344,
      0.01849975995719433,
      -0.09872757643461227,
      0.014056471176445484,
      -0.03931290656328201,
      0.003083668416365981,
      -0.02911345101892948,
      -0.06825365126132965,
      0.11860381066799164,
      0.09218934923410416,
      -0.11092497408390045,
      -0.02110649086534977,
      -0.07343818247318268,
      0.006394138094037771,
      -0.03145046532154083,
      -0.02358425036072731,
      -0.03798344358801842,
      0.03462943807244301,
      -0.04979805648326874,
      0.014218435622751713,
      0.02551758661866188,
      0.04783690348267555,
      0.0026748650707304478,
      -0.036537230014801025,
      -0.006899924483150244,
      0.016723716631531715,
      -0.01960059441626072,
      0.008981341496109962,
      -0.03904489800333977,
      0.05295301228761673,
      0.025845782831311226,
      0.017798062413930893,
      0.00667966715991497,
      -0.05068552494049072,
      -0.015118603594601154,
      0.05020628869533539,
      -0.06525358557701111,
      -0.0457492433488369,
      0.03255627676844597,
      -0.00036679801996797323,
      -0.08493753522634506,
      -0.030916957184672356,
      -0.0354999415576458,
      -0.09070636332035065,
      0.07074658572673798,
      -0.06322271376848221,
      -0.014110429212450981,
      0.10559532791376114,
      -0.06342437863349915,
      -0.01844090223312378,
      -0.08504821360111237,
      -0.0773598775267601,
      0.0006329453317448497,
      0.04622373357415199,
      -0.029890934005379677,
      0.0499892495572567,
      0.012976476922631264,
      0.07840441912412643,
      -0.0049501582980155945,
      -0.025775527581572533,
      -0.01609092205762863,
      -0.016694514080882072,
      -0.04674796760082245,
      -0.03705275431275368,
      0.1184019148349762,
      -0.035804633051157,
      0.034402407705783844,
      -0.0410589762032032,
      -0.04384434223175049,
      -0.017015699297189713,
      -0.02516029216349125,
      -0.06320974230766296,
      0.01865002140402794,
      0.1012539342045784,
      0.018837854266166687,
      0.05598292872309685,
      -0.030200636014342308,
      -0.06640718132257462,
      -0.021310338750481606,
      0.009500971995294094,
      -0.06645907461643219,
      -0.031028788536787033,
      -0.02330164797604084,
      0.015586953610181808,
      0.06672315299510956,
      -0.021115031093358994,
      0.11085548996925354,
      0.004474867135286331,
      -0.0020833879243582487,
      0.06901401281356812,
      -0.0726858600974083,
      -0.022252487018704414,
      -0.0010807197540998459,
      -0.03207661211490631,
      -0.03654750809073448,
      -0.041281674057245255,
      0.01851717382669449,
      -0.017114736139774323,
      0.11046171933412552,
      0.012660430744290352,
      -0.05320516601204872,
      0.01770554855465889,
      0.03412139043211937,
      0.008268564008176327,
      0.004488252568989992,
      -0.004299390595406294,
      0.05345878005027771,
      0.09659293293952942,
      -0.07687725871801376,
      0.01550557091832161,
      -0.056385815143585205,
      0.03961750119924545,
      -0.010499837808310986,
      -0.010796249844133854,
      -0.06589685380458832,
      -0.018617771565914154,
      -0.0862756296992302,
      0.006250445730984211,
      -0.07233835756778717,
      -0.11098948866128922,
      0.005615921691060066,
      0.02427568845450878,
      0.0449519082903862,
      -0.029536040499806404,
      -0.05991446599364281,
      0.05630898103117943,
      0.021318266168236732,
      -0.08540467172861099,
      -0.043785449117422104,
      0.036680273711681366,
      -0.09405874460935593,
      0.014353301376104355,
      0.027130136266350746,
      0.07176246494054794,
      0.00311498180963099,
      -0.012994767166674137,
      0.09779728949069977,
      0.0002759481139946729,
      -0.05509374290704727,
      0.032394591718912125,
      -0.01280171424150467,
      0.049640100449323654,
      0.059960801154375076,
      -0.015432287938892841,
      -0.03460642695426941,
      -0.03865400329232216,
      -0.05307978391647339,
      0.016197199001908302,
      -0.002566224429756403,
      -0.039129868149757385,
      -0.03749966248869896,
      0.05605969950556755,
      -0.0027029297780245543,
      -0.042550913989543915,
      0.07479652017354965,
      -0.06970755010843277,
      -0.010478527285158634,
      -0.041699085384607315,
      0.06777723133563995,
      0.005900409072637558,
      -0.0001854093570727855,
      0.01710021123290062,
      0.016897136345505714,
      -0.010143127292394638,
      0.022158307954669,
      0.009366459213197231,
      0.01185018103569746,
      0.058144982904195786,
      0.014275326393544674,
      0.0984807163476944,
      -0.09192731976509094,
      0.016546253114938736,
      0.04043460264801979,
      0.0638510137796402,
      0.038492005318403244,
      -0.00457509933039546,
      -0.062034595757722855,
      -0.048134081065654755,
      0.0475505031645298,
      0.032465409487485886,
      -0.025707311928272247,
      -0.05753430724143982,
      0.026917224749922752,
      0.03380408138036728,
      -0.009469933807849884,
      -0.06309583783149719,
      -0.01954082027077675,
      -0.0011316543677821755,
      -0.039730772376060486,
      -0.021782057359814644,
      -0.006834549829363823,
      -0.046634774655103683,
      0.05692989379167557,
      -0.08651352673768997,
      -0.044553861021995544,
      -0.005894211120903492,
      -0.10314285010099411,
      -0.00034753314685076475,
      0.01646961271762848,
      0.006117779295891523,
      0.02182539738714695,
      0.04610489308834076,
      0.056886427104473114,
      -0.06733479350805283,
      0.011558338068425655,
      0.018444236367940903,
      0.018449412658810616,
      0.038961827754974365,
      -0.03853404521942139,
      0.053047358989715576,
      0.005877661518752575,
      0.039934590458869934,
      0.06669453531503677,
      -0.022939886897802353,
      -0.025306111201643944,
      0.03479414060711861,
      -0.03186919540166855,
      -0.03816995769739151,
      -0.024461399763822556,
      -0.05523375794291496,
      -0.06730104237794876,
      -0.002997411647811532,
      0.06220483034849167,
      0.0003177579492330551,
      0.05202597379684448,
      0.011688900180161,
      -0.0631428137421608,
      0.024107472971081734,
      0.0146104134619236,
      -0.02474006451666355,
      0.006578983273357153,
      -0.03704125061631203,
      -0.01995319500565529,
      -0.055110830813646317,
      0.007406608667224646,
      0.0451853908598423,
      -0.029635710641741753,
      0.08703133463859558,
      0.016311170533299446,
      -0.05150048807263374,
      -0.049118608236312866,
      0.031137941405177116,
      -0.050977278500795364,
      0.005172375123947859,
      -0.03247601166367531,
      0.005581374745815992,
      -0.00029699766309931874,
      -0.04405522719025612,
      0.03954486921429634,
      -0.03695700317621231,
      -0.03473657742142677,
      -0.030967455357313156,
      0.07353255152702332,
      -0.07718762755393982,
      -0.08652961254119873,
      0.018493274226784706,
      -0.043149057775735855,
      -0.08317704498767853,
      -0.021941063925623894,
      0.05832941457629204,
      0.07136299461126328,
      -0.005270176567137241,
      -0.017728565260767937,
      0.01909944787621498,
      0.011693176813423634,
      0.015333322808146477,
      -0.011571645736694336,
      0.010295138694345951,
      0.010779094882309437,
      0.005367398727685213,
      -0.01171762216836214,
      -0.03623129054903984,
      0.017666073516011238,
      -0.004672414157539606,
      -0.03416919708251953,
      -0.046331219375133514,
      -0.0018476038239896297,
      0.053537338972091675,
      0.01645321398973465,
      0.08862044662237167,
      -0.014481514692306519,
      0.07290907204151154,
      -0.06407604366540909,
      0.0368383564054966,
      -0.06049680709838867,
      -0.002899190876632929,
      -0.10229802876710892,
      0.00648603867739439,
      -0.0019723488949239254,
      0.03155522421002388,
      -0.04367973655462265,
      0.0649353563785553,
      0.01748672127723694,
      0.0018945219926536083,
      0.04802973195910454,
      -0.01159595511853695,
      -0.025979135185480118,
      0.05546198785305023,
      0.030879411846399307,
      0.004198256880044937,
      -0.006773118861019611,
      0.022996380925178528,
      0.017258651554584503,
      0.01168949343264103,
      0.08126208931207657,
      0.003899491624906659,
      -0.03540923446416855,
      -0.0032573326025158167,
      -0.08115025609731674,
      -0.10333901643753052,
      0.02940860018134117,
      -0.03638455644249916,
      -0.05918809026479721,
      0.029492175206542015,
      -0.07320903241634369,
      0.062152959406375885,
      0.027128539979457855,
      0.03790504112839699,
      -0.05984094738960266,
      0.02547430619597435,
      0.04681001976132393,
      -0.011656248942017555,
      -0.020182695239782333,
      0.018413683399558067,
      0.07712051272392273,
      -0.003490337636321783,
      0.05783061683177948,
      0.022643286734819412,
      -0.02748413197696209,
      -0.03297775238752365,
      0.005862448364496231,
      0.05362951382994652,
      0.0016477647004649043,
      0.0011902066180482507,
      0.016849054023623466,
      -0.040105391293764114,
      0.029439374804496765,
      -0.01651744917035103,
      -0.007132025435566902,
      -0.03447980433702469,
      0.0022084275260567665,
      -0.06889981031417847,
      0.03631798177957535,
      0.005808746442198753,
      0.009768577292561531,
      -0.040740255266427994,
      0.006396181415766478,
      0.07179761677980423,
      -0.0757155790925026,
      0.0011156457476317883,
      0.011553427204489708,
      0.03682492673397064,
      0.06458966434001923,
      -0.014186323620378971,
      -0.038545697927474976,
      0.04317839443683624,
      0.034915801137685776,
      -0.002617240184918046,
      -0.06366938352584839,
      0.08070550858974457,
      0.0074927592650055885,
      -0.031834036111831665,
      0.04950786009430885,
      -0.04936762899160385,
      0.034327346831560135,
      -0.03076668456196785,
      -0.014104736037552357,
      0.0019352727103978395,
      -0.06780790537595749,
      9.300966485170648e-05,
      0.048730526119470596,
      0.04239543527364731,
      0.05763566866517067,
      0.020515618845820427,
      0.07783669233322144,
      -0.06473295390605927,
      -0.011353960260748863,
      -0.049771010875701904,
      0.050354965031147,
      -0.017732707783579826,
      -0.06752131134271622,
      0.0017052587354555726,
      0.014869139529764652,
      -0.04414067789912224,
      0.004537918139249086,
      0.001400441862642765,
      0.016373254358768463,
      -0.009337415918707848,
      -0.018891679123044014,
      -0.003033590270206332,
      0.05087299272418022,
      -0.09526190906763077,
      -0.029237132519483566,
      -0.03908234089612961,
      0.05925659090280533,
      -0.055810343474149704,
      0.06317546963691711,
      -0.053423259407281876,
      0.024926140904426575,
      0.042479898780584335,
      -0.007029244676232338,
      -0.027560248970985413,
      0.07198860496282578,
      -0.03841979056596756,
      0.0045349374413490295,
      0.06585268676280975,
      0.05836319178342819,
      0.014835557900369167,
      -0.03524148836731911,
      0.048017628490924835,
      -0.020675646141171455,
      -0.004945607390254736,
      -0.07196609675884247,
      0.04446348920464516,
      -0.08676235377788544,
      -0.08437301963567734,
      -0.03749212995171547,
      -0.018564241006970406,
      -0.04869324713945389,
      -0.024482622742652893,
      0.056217264384031296,
      -0.038631223142147064,
      -0.015710413455963135,
      0.04234553128480911,
      -0.02051337994635105,
      -0.0257370974868536,
      -0.033833134919404984,
      -0.043507169932127,
      0.06861204653978348,
      0.05165966600179672,
      0.0010911889839917421,
      -0.04161372035741806,
      -0.03038436733186245,
      0.02505417913198471,
      -0.0004821867623832077,
      -0.03492913395166397,
      0.031988512724637985,
      0.0007962103700265288,
      0.006553702522069216,
      0.012388907372951508,
      0.007897039875388145,
      -0.05978793278336525,
      0.10109967738389969,
      0.10048238188028336,
      -0.03889188915491104,
      -0.028191691264510155,
      0.05502060428261757,
      0.030323263257741928,
      0.02752130664885044,
      0.05874158814549446,
      -0.07224318385124207,
      0.038867875933647156,
      0.044471628963947296,
      0.06177863851189613,
      -0.013787795789539814,
      -0.04693356156349182,
      -0.03868814557790756,
      0.0031946063973009586,
      0.0188231710344553
    ],
    [
      -0.01787855476140976,
      -0.012432114221155643,
      -0.0028878313023597,
      0.025750787928700447,
      0.0055257221683859825,
      0.05866130068898201,
      0.008035234175622463,
      0.031721800565719604,
      0.023738618940114975,
      0.05938129127025604,
      -0.030607257038354874,
      -0.04437132552266121,
      0.03396528214216232,
      -0.04421414062380791,
      0.05046463757753372,
      -0.0036447797901928425,
      -0.049892690032720566,
      0.09355497360229492,
      -0.012224506586790085,
      -0.01239021960645914,
      -0.059476349502801895,
      0.0396200455725193,
      0.0744929313659668,
      0.018033619970083237,
      -0.07705825567245483,
      -0.08134569972753525,
      0.01261674053966999,
      0.043647587299346924,
      -0.06046714633703232,
      -0.02136213332414627,
      -0.06251054257154465,
      -0.023344825953245163,
      0.019308745861053467,
      0.04318495839834213,
      0.029955463483929634,
      0.020293910056352615,
      0.03985327109694481,
      -0.020320747047662735,
      -0.04801115766167641,
      0.017770659178495407,
      -0.010447087697684765,
      -0.013440345413982868,
      -0.005923457909375429,
      0.018429730087518692,
      -0.03804009407758713,
      -0.030360011383891106,
      -0.002245619660243392,
      0.08330471068620682,
      0.008525973185896873,
      -0.04387279227375984,
      -0.010703016072511673,
      0.04491722211241722,
      -0.05588885024189949,
      -0.025689581409096718,
      0.009161556139588356,
      0.03969177231192589,
      -0.05125392600893974,
      -0.06008268892765045,
      0.04401961714029312,
      0.056055158376693726,
      0.011584833264350891,
      0.04379985108971596,
      0.05898401513695717,
      0.034490033984184265,
      0.1368817836046219,
      0.016114380210638046,
      -0.04987867921590805,
      -0.06886056065559387,
      -0.030886249616742134,
      -0.025775562971830368,
      0.03463422879576683,
      0.08948715031147003,
      -0.04884064197540283,
      0.03897726908326149,
      -0.014429361559450626,
      0.023281516507267952,
      -0.0010243846336379647,
      -0.00866474024951458,
      0.041074514389038086,
      0.052523620426654816,
      -0.07515561580657959,
      0.0005702153430320323,
      -0.05401746556162834,
      -0.029218317940831184,
      -0.018936945125460625,
      0.009142197668552399,
      -0.09701226651668549,
      -0.04665108025074005,
      0.11809497326612473,
      0.022738205268979073,
      -0.06385895609855652,
      0.09089258313179016,
      -0.14084281027317047,
      0.0019430088577792048,
      -0.01140600722283125,
      0.031626567244529724,
      -0.013157988898456097,
      0.10048140585422516,
      0.014366493560373783,
      -0.013060081750154495,
      -0.03569851815700531,
      0.021742621436715126,
      0.02650691755115986,
      0.029951322823762894,
      -0.09974587708711624,
      0.034934137016534805,
      -0.020769374445080757,
      -0.10487282276153564,
      0.005169755779206753,
      0.018563464283943176,
      -0.033494748175144196,
      0.0437447726726532,
      -0.012110627256333828,
      0.0012100255116820335,
      -0.0005048176390118897,
      0.11479193717241287,
      -0.008275018073618412,
      -0.03141436353325844,
      -0.004201857373118401,
      -0.012127285823225975,
      0.010205146856606007,
      0.035507816821336746,
      0.00946661364287138,
      0.10866926610469818,
      -0.021374257281422615,
      0.01752525009214878,
      -0.025857701897621155,
      0.01074245199561119,
      0.018636344000697136,
      -0.04895588010549545,
      0.010376857593655586,
      -0.008658926002681255,
      0.008057619445025921,
      -0.015846330672502518,
      0.054655175656080246,
      0.027785638347268105,
      -0.047275643795728683,
      0.01832428388297558,
      0.03797717019915581,
      0.01604953221976757,
      -0.014905720949172974,
      -0.03588709980249405,
      0.046906065195798874,
      0.030162036418914795,
      0.002499517984688282,
      0.03486744686961174,
      0.006370842456817627,
      0.028876209631562233,
      -0.02052474394440651,
      -0.011337888427078724,
      -0.09731075167655945,
      0.05205274373292923,
      0.017028089612722397,
      0.025403451174497604,
      0.009995441883802414,
      0.04941306263208389,
      -0.010025369934737682,
      0.02373211644589901,
      0.007939769886434078,
      0.04373694956302643,
      -0.078778475522995,
      -0.050937872380018234,
      0.02220260724425316,
      0.03202332556247711,
      -0.08333969116210938,
      -0.011611356399953365,
      0.01502049621194601,
      -0.07751134037971497,
      0.021873151883482933,
      -0.011118415743112564,
      0.019051650539040565,
      -0.06452114135026932,
      0.0010424378560855985,
      -0.07407635450363159,
      0.02352972887456417,
      0.034824129194021225,
      0.03299000486731529,
      -0.030188849195837975,
      0.012030751444399357,
      0.10150867700576782,
      -0.025669271126389503,
      0.036798372864723206,
      -0.05052566900849342,
      0.0571235753595829,
      -0.04086751490831375,
      0.010456389747560024,
      0.040353331714868546,
      -0.02345331199467182,
      -0.010563618503510952,
      -0.05371096730232239,
      0.021694861352443695,
      -0.07961834222078323,
      -0.01245869044214487,
      -0.00124441459774971,
      0.032124366611242294,
      -0.022559143602848053,
      0.046037957072257996,
      0.033645305782556534,
      -0.09124663472175598,
      0.03170758858323097,
      0.14195653796195984,
      -0.056653447449207306,
      0.075917549431324,
      0.006431390065699816,
      -0.03610749915242195,
      -0.04229514300823212,
      -0.04029722511768341,
      -0.0025137101765722036,
      0.027091708034276962,
      -0.01950492523610592,
      -0.032854437828063965,
      0.03167613595724106,
      0.09300925582647324,
      0.030652808025479317,
      -0.05176810547709465,
      0.05884438008069992,
      0.01545804925262928,
      0.023509232327342033,
      0.03848090022802353,
      0.020472196862101555,
      0.10017052292823792,
      -0.014310328289866447,
      0.05291631817817688,
      -0.004788623191416264,
      -0.009917587041854858,
      -0.0049840775318443775,
      0.06959941983222961,
      0.06706015020608902,
      -0.04182378947734833,
      0.004104580730199814,
      -0.03399786353111267,
      -0.06258602440357208,
      0.01641363836824894,
      -0.046672359108924866,
      0.050081122666597366,
      0.04711216688156128,
      0.006400087848305702,
      0.031540390104055405,
      -0.015211749821901321,
      -0.02584032714366913,
      -0.030765367671847343,
      -0.040846534073352814,
      0.011324906721711159,
      0.043103087693452835,
      0.004116453230381012,
      0.025166351348161697,
      0.0076087722554802895,
      0.07359060645103455,
      0.058247100561857224,
      0.029981058090925217,
      -0.014712494798004627,
      -0.0019381033489480615,
      -0.024581464007496834,
      0.021925440058112144,
      0.007231257390230894,
      0.08139586448669434,
      0.07773097604513168,
      -0.033466678112745285,
      -0.04484099894762039,
      0.06985938549041748,
      -0.022533008828759193,
      -0.057689111679792404,
      -0.0126624945551157,
      -0.10408131033182144,
      -0.031206808984279633,
      0.0738455206155777,
      -0.09739919006824493,
      -0.019258392974734306,
      0.034823331981897354,
      0.02004978433251381,
      0.007433819118887186,
      -0.044498369097709656,
      0.025383487343788147,
      -0.02980274148285389,
      0.0006472873501479626,
      -0.012475396506488323,
      0.01819443702697754,
      -0.01288103312253952,
      -0.008692287839949131,
      0.026546435430645943,
      0.017657551914453506,
      0.0015344201819971204,
      -0.08385932445526123,
      -0.06990519165992737,
      -0.03654230758547783,
      0.0013532366137951612,
      0.04908948019146919,
      -0.007764935027807951,
      0.029368193820118904,
      0.058315593749284744,
      0.04110562428832054,
      -0.06111859902739525,
      -0.06242440268397331,
      -0.021320180967450142,
      0.009856975637376308,
      -0.04257893189787865,
      0.03701107203960419,
      0.014200116507709026,
      -0.05173427239060402,
      0.04159754514694214,
      0.01111097913235426,
      -0.006656920537352562,
      0.026127871125936508,
      -0.06579477339982986,
      -0.0014330752892419696,
      -0.08422478288412094,
      -0.028595153242349625,
      -0.09972342103719711,
      0.08140233904123306,
      -0.019190635532140732,
      0.06922055035829544,
      -0.049794405698776245,
      -0.027303345501422882,
      0.0009365820442326367,
      0.028604408726096153,
      0.03424569591879845,
      0.02339312992990017,
      -0.016237786039710045,
      0.025131769478321075,
      -0.0272970013320446,
      0.01697610504925251,
      0.03569244593381882,
      -0.026763830333948135,
      -0.003583353478461504,
      0.020398247987031937,
      0.028269872069358826,
      0.003942626062780619,
      -0.029984528198838234,
      0.058113157749176025,
      0.03952972963452339,
      -0.07826744765043259,
      -0.02214859612286091,
      -0.0011555085657164454,
      -0.0011182940797880292,
      0.030347874388098717,
      0.016253182664513588,
      0.05213462561368942,
      0.06758922338485718,
      0.03401730954647064,
      -0.051354534924030304,
      -0.06079014763236046,
      0.02408170886337757,
      0.04174461588263512,
      0.01197152491658926,
      -0.04481230676174164,
      -0.0014288832899183035,
      0.03861212357878685,
      -0.06400544196367264,
      -0.041592445224523544,
      0.03774719685316086,
      0.013501133769750595,
      -0.023579683154821396,
      0.009116873145103455,
      0.0637926459312439,
      0.03581969067454338,
      -0.03829355537891388,
      0.02843480370938778,
      -0.054705630987882614,
      -0.03138931468129158,
      -0.016465377062559128,
      -0.030021291226148605,
      0.017823852598667145,
      -0.06484268605709076,
      0.0880020409822464,
      -0.027270467951893806,
      -0.04303630813956261,
      -0.0373106449842453,
      -0.028384646400809288,
      -0.07779243588447571,
      0.02381010353565216,
      0.05436280369758606,
      0.1342424899339676,
      -0.057363368570804596,
      -0.008192522451281548,
      -0.015748757869005203,
      0.03920441493391991,
      -0.018378805369138718,
      0.024128997698426247,
      0.030849169939756393,
      0.057598866522312164,
      0.00839465856552124,
      -0.06776305288076401,
      -0.0487745925784111,
      0.11804316192865372,
      0.030840961262583733,
      -0.03858461230993271,
      -0.016194984316825867,
      -0.04713541641831398,
      0.07879913598299026,
      0.028485199436545372,
      0.027274683117866516,
      0.0661637932062149,
      0.027378050610423088,
      -0.08313587307929993,
      -0.009882619604468346,
      -0.013813258148729801,
      -0.06453527510166168,
      0.06125691160559654,
      -0.07411130517721176,
      -0.01434718444943428,
      0.044227804988622665,
      -0.07295629382133484,
      0.052389536052942276,
      0.00942934863269329,
      -0.026911435648798943,
      0.019775304943323135,
      -0.01525682583451271,
      -0.08540526777505875,
      -0.0159210916608572,
      0.03176579624414444,
      0.0531502291560173,
      0.008764066733419895,
      0.04318038374185562,
      -0.09643325209617615,
      -0.0637049525976181,
      -0.037240710109472275,
      -0.01515043806284666,
      -0.017549851909279823,
      0.01967993564903736,
      -0.0514528788626194,
      -0.05521731451153755,
      -0.008271553553640842,
      0.03338179737329483,
      -0.04805237799882889,
      0.03804193437099457,
      0.03725440800189972,
      0.06146521866321564,
      -0.024069152772426605,
      0.037729956209659576,
      -0.08655956387519836,
      -0.01679699681699276,
      -0.0556505061686039,
      -0.018885383382439613,
      0.043403927236795425,
      0.013202060014009476,
      0.07576564699411392,
      0.011174582876265049,
      -0.02724923938512802,
      0.040755223482847214,
      -6.00341591052711e-05,
      0.06053456664085388,
      0.015532126650214195,
      0.016272759065032005,
      0.04032163321971893,
      -0.030478039756417274,
      0.020356539636850357,
      -0.026626382023096085,
      -0.046723369508981705,
      -0.0430927537381649,
      0.028991954401135445,
      -0.046177271753549576,
      0.021415792405605316,
      0.06448238343000412,
      0.0012280551018193364,
      -0.007171067409217358,
      0.004557698965072632,
      0.03018663451075554,
      -0.01837451197206974,
      0.00028100336203351617,
      0.0764869675040245,
      -0.008028599433600903,
      -0.009403056465089321,
      -0.015383441001176834,
      0.06193128228187561,
      -0.0930391177535057,
      0.021696506068110466,
      0.014794104732573032,
      0.03805761784315109,
      0.014133784919977188,
      -0.050473183393478394,
      0.030416153371334076,
      -0.061398204416036606,
      0.00026752465055324137,
      0.001244652783498168,
      0.016742562875151634,
      0.055895790457725525,
      0.036919981241226196,
      0.03565790131688118,
      -0.05178586766123772,
      0.07878969609737396,
      0.05887586250901222,
      -0.010986566543579102,
      0.0011334771988913417,
      0.06421256065368652,
      0.002926403423771262,
      -0.04831700399518013,
      -0.09411908686161041,
      -0.025585774332284927,
      0.0013635618379339576,
      0.03538687527179718,
      0.0324912890791893,
      -0.0046794479712843895,
      0.007034877315163612,
      -0.0938481017947197,
      0.0018755586352199316,
      0.02069918066263199,
      -0.023704394698143005,
      0.045899778604507446,
      -0.026916271075606346,
      -0.058050841093063354,
      -0.06878907233476639,
      0.025590764358639717,
      -0.011255919001996517
    ],
    [
      -0.009451974183321,
      0.012822172604501247,
      -0.03249208629131317,
      -0.09660691022872925,
      0.008519326336681843,
      0.05005388334393501,
      0.035366740077733994,
      0.060820553451776505,
      0.04235425963997841,
      0.02322632074356079,
      -0.013500820845365524,
      0.03327280282974243,
      -0.06521767377853394,
      -0.07678878307342529,
      -0.008277171291410923,
      -0.09717027097940445,
      0.00630352646112442,
      0.026388874277472496,
      -0.08202224969863892,
      -0.03458789363503456,
      0.016263434663414955,
      -0.018465863540768623,
      0.029134074226021767,
      -0.024824384599924088,
      -0.03487936407327652,
      -0.03181144967675209,
      0.034993331879377365,
      0.05796785652637482,
      -0.026236344128847122,
      -0.0855758935213089,
      0.011493472382426262,
      -0.04259676858782768,
      -0.01330635603517294,
      -0.05841010808944702,
      0.054246094077825546,
      -0.030996348708868027,
      0.046109639108181,
      -0.07181308418512344,
      0.03581320866942406,
      -0.030433207750320435,
      0.02346114069223404,
      0.010856036096811295,
      -0.017048349604010582,
      0.012779755517840385,
      0.048660337924957275,
      -0.0052881669253110886,
      -0.05124375596642494,
      0.06726977229118347,
      -0.03007304109632969,
      0.026980511844158173,
      0.02276817336678505,
      -0.06770340353250504,
      0.02725655399262905,
      0.02358008362352848,
      0.05991402268409729,
      0.026863686740398407,
      -0.037026260048151016,
      0.01780334860086441,
      0.05895126610994339,
      0.05791226774454117,
      -0.01761346310377121,
      -0.019734829664230347,
      -0.0534578301012516,
      0.04748294875025749,
      -0.009201684966683388,
      0.009760000742971897,
      -0.05382530763745308,
      0.10943488031625748,
      -0.012331919744610786,
      -0.03253590688109398,
      -0.04517120122909546,
      0.000336191151291132,
      -0.018068021163344383,
      0.014718849211931229,
      0.03491567075252533,
      -0.0239886324852705,
      -0.021272096782922745,
      -0.0033810704480856657,
      -0.013419684022665024,
      0.007023220881819725,
      -0.01973876915872097,
      -0.0226234570145607,
      0.03681657463312149,
      -0.029670821502804756,
      0.0355818048119545,
      0.005458376370370388,
      -0.05063333734869957,
      0.042373668402433395,
      0.0015798178501427174,
      0.027557985857129097,
      0.03270646184682846,
      0.03902645409107208,
      0.0015490652294829488,
      0.028832444921135902,
      -0.03385408595204353,
      -0.036424580961465836,
      -0.03523312136530876,
      0.013708597980439663,
      0.01381628680974245,
      0.019884031265974045,
      0.02278846688568592,
      0.04982108622789383,
      0.05013101547956467,
      0.029033800587058067,
      -0.024080904200673103,
      0.07172637432813644,
      0.005538039840757847,
      -0.019143659621477127,
      0.023061582818627357,
      -0.06264030933380127,
      0.029490603134036064,
      -0.058175746351480484,
      -0.03083811141550541,
      0.00876621063798666,
      0.008917270228266716,
      -0.024581216275691986,
      0.05041518062353134,
      -0.04045991972088814,
      -0.07439062744379044,
      0.00013947783736512065,
      -0.001750198658555746,
      0.019763728603720665,
      -0.044741563498973846,
      0.04688581824302673,
      -0.007906016893684864,
      -0.06241155415773392,
      -0.00485207699239254,
      0.04656974971294403,
      0.07085352391004562,
      0.000809044751804322,
      -0.025586163625121117,
      0.030440988019108772,
      0.012662198394536972,
      -0.007384009659290314,
      -0.014554752968251705,
      -0.005066842306405306,
      -0.044961243867874146,
      -0.04560358449816704,
      -0.0010324146132916212,
      -0.014994263648986816,
      -0.07589318603277206,
      -0.037024710327386856,
      -0.04528360068798065,
      0.06185397878289223,
      -0.014165583066642284,
      0.03664037585258484,
      0.056010689586400986,
      -0.03460407629609108,
      0.020296061411499977,
      0.0035128595773130655,
      0.004263728857040405,
      -0.04096507653594017,
      -0.04260781779885292,
      0.037514932453632355,
      -0.09485609829425812,
      0.03668415918946266,
      -0.01359606347978115,
      -0.06856466829776764,
      0.040410835295915604,
      0.02461763098835945,
      0.015294108539819717,
      -0.08308548480272293,
      -0.03733482584357262,
      -0.03161178156733513,
      -0.010179984383285046,
      0.06014607101678848,
      -0.03015638329088688,
      -0.0006458702846430242,
      -0.0009202820365317166,
      0.0749429315328598,
      0.02980276197195053,
      0.041558269411325455,
      -0.1461489349603653,
      -0.021966302767395973,
      -0.03506365045905113,
      0.010527228005230427,
      -0.0853225365281105,
      0.028185289353132248,
      0.033571865409612656,
      -0.060357656329870224,
      0.03961445018649101,
      0.03186686336994171,
      0.014114642515778542,
      0.02714606188237667,
      -0.053360603749752045,
      0.04646303877234459,
      0.05608503147959709,
      0.027287118136882782,
      -0.03341565281152725,
      0.04017443209886551,
      0.03195987641811371,
      0.026466812938451767,
      0.04586074501276016,
      -0.03467824310064316,
      -0.052001722157001495,
      -0.043047208338975906,
      0.0051831756718456745,
      -0.03774164989590645,
      0.04704460874199867,
      0.09008073061704636,
      0.07266167551279068,
      0.00630693044513464,
      0.027216067537665367,
      -0.0012091861572116613,
      -0.08955637365579605,
      -0.007866841740906239,
      0.0022471442352980375,
      -0.018044836819171906,
      -0.03440149873495102,
      -0.02754279039800167,
      0.04576405882835388,
      -0.024175556376576424,
      0.10236157476902008,
      -0.00341616477817297,
      0.049077171832323074,
      -0.04559861496090889,
      -0.029379170387983322,
      0.050566595047712326,
      0.07631151378154755,
      -0.02078492008149624,
      -0.028138654306530952,
      -0.001942097907885909,
      -0.06066858395934105,
      0.043490100651979446,
      -0.05620115250349045,
      -0.03725951910018921,
      0.11827871948480606,
      0.06888379901647568,
      0.05248275026679039,
      0.06931041181087494,
      -0.06292321532964706,
      0.031510502099990845,
      0.023140661418437958,
      -0.0146981505677104,
      -0.018125491216778755,
      -0.023197798058390617,
      0.06424735486507416,
      -0.02335134707391262,
      -0.10622669011354446,
      0.058427680283784866,
      -0.010270178318023682,
      0.018665490671992302,
      -0.01719081960618496,
      -0.04817824065685272,
      -0.008746803738176823,
      -0.08435177803039551,
      0.03848663717508316,
      -0.04839426279067993,
      0.009614294394850731,
      -0.008061695843935013,
      0.05546514689922333,
      0.024283014237880707,
      -0.05742828547954559,
      0.009689800441265106,
      -0.005164604168385267,
      0.08044366538524628,
      -0.03799080848693848,
      -0.07038086652755737,
      0.02395545318722725,
      2.5055609512492083e-05,
      0.03671994432806969,
      -0.050693660974502563,
      0.011149181984364986,
      -0.04976711422204971,
      -0.03845132142305374,
      0.042016904801130295,
      0.004464370664209127,
      -0.0051513067446649075,
      -0.02190198004245758,
      0.08469563722610474,
      -0.04961441457271576,
      -0.03581950441002846,
      -0.019327478483319283,
      -0.06545614451169968,
      0.04341377317905426,
      -0.016376091167330742,
      -0.00010624002607073635,
      -0.047185126692056656,
      -0.0763125866651535,
      0.04376981407403946,
      0.005314741749316454,
      -0.04020063206553459,
      0.027339491993188858,
      -0.06306768208742142,
      0.03355967253446579,
      0.0712239071726799,
      -0.02781759388744831,
      -0.09280665218830109,
      -0.04324067756533623,
      -0.02732780948281288,
      0.04801908880472183,
      0.034938134253025055,
      0.057680658996105194,
      -0.054305557161569595,
      0.0118868974968791,
      -0.045703593641519547,
      0.0004975550109520555,
      -0.0501289963722229,
      -0.03369062393903732,
      0.05077756568789482,
      -0.018561851233243942,
      0.005493015516549349,
      -0.049771618098020554,
      0.0002695444563869387,
      -0.026280153542757034,
      0.004234937485307455,
      -0.051053617149591446,
      -0.030945878475904465,
      -0.017977319657802582,
      -0.0070802257396280766,
      0.04131251201033592,
      0.004334914498031139,
      -0.08756444603204727,
      0.013533171266317368,
      -0.0693197175860405,
      -0.04554222524166107,
      0.04231662303209305,
      0.007800422143191099,
      -0.09446194022893906,
      -0.03716487064957619,
      0.07431257516145706,
      -0.017295902594923973,
      0.03777095302939415,
      -0.016102362424135208,
      -0.1163528561592102,
      -0.00300275138579309,
      0.06101950258016586,
      -0.05438658222556114,
      -0.00995330698788166,
      0.05719638243317604,
      -0.02989117056131363,
      0.05245174467563629,
      0.009455972351133823,
      -0.024520624428987503,
      -0.05671392381191254,
      -0.010535748675465584,
      -0.023557856678962708,
      -0.008608179166913033,
      0.0771404355764389,
      -0.07986564934253693,
      0.032311949878931046,
      -0.021040113642811775,
      0.04634621739387512,
      -0.12176920473575592,
      -0.024649975821375847,
      0.04298899322748184,
      -0.006185729522258043,
      -0.022651463747024536,
      0.025560392066836357,
      0.035581011325120926,
      -0.02257273904979229,
      -0.018300503492355347,
      -0.0024759983643889427,
      -0.05291491001844406,
      0.06805063039064407,
      -0.0441332645714283,
      0.004356051795184612,
      0.03981631249189377,
      -0.04646451771259308,
      -0.019249850884079933,
      -0.018165746703743935,
      -0.09305839240550995,
      -0.03400908783078194,
      -0.07204633206129074,
      0.021811269223690033,
      0.13427026569843292,
      0.008109305053949356,
      0.01810431107878685,
      -0.01938461884856224,
      0.06125270947813988,
      -0.0047959862276911736,
      0.0045874156057834625,
      0.0783299058675766,
      0.030979545786976814,
      0.05577891319990158,
      -0.06838434934616089,
      0.031549468636512756,
      0.0389438271522522,
      0.08585013449192047,
      -0.07993286848068237,
      -0.024142012000083923,
      0.022860147058963776,
      0.011975153349339962,
      -0.0480758398771286,
      -0.029041634872555733,
      0.06073187291622162,
      0.037239864468574524,
      0.07706178724765778,
      0.028849439695477486,
      -0.03608834743499756,
      -0.010805853642523289,
      0.06031262129545212,
      -0.008516876958310604,
      -0.016695162281394005,
      -0.033802758902311325,
      -0.03842942789196968,
      0.009142426773905754,
      -0.021647248417139053,
      0.00551843736320734,
      0.043300554156303406,
      0.054385870695114136,
      0.008380250073969364,
      -0.01985146850347519,
      -0.03513427451252937,
      0.018653927370905876,
      0.09935270994901657,
      -0.0413031168282032,
      -0.058323487639427185,
      -0.020745685324072838,
      0.028031038120388985,
      0.03266413137316704,
      -0.012641847133636475,
      0.018032703548669815,
      0.03680872544646263,
      -0.017739148810505867,
      0.013103965669870377,
      -0.06803464889526367,
      -0.05301303789019585,
      0.013096535578370094,
      -0.043088193982839584,
      -0.07034558057785034,
      -0.090598464012146,
      -0.0038574147038161755,
      0.009303243830800056,
      -0.05128674954175949,
      0.040983911603689194,
      -0.012798134237527847,
      0.028519997373223305,
      0.025649016723036766,
      0.011571625247597694,
      0.01123848557472229,
      -0.04618966579437256,
      0.00012314329796936363,
      0.006271283142268658,
      0.012930446304380894,
      -0.032236337661743164,
      0.04119343310594559,
      0.06608064472675323,
      -0.009886949323117733,
      0.018114687874913216,
      -0.04396029934287071,
      -0.0674850195646286,
      0.016649572178721428,
      -0.024098675698041916,
      -0.06638587266206741,
      0.030223624780774117,
      0.010857345536351204,
      -0.04914024472236633,
      0.014306274242699146,
      0.033528413623571396,
      0.07858648896217346,
      0.0007852196577005088,
      0.07336333394050598,
      0.0020445382688194513,
      -0.0379316620528698,
      0.01715639978647232,
      -0.07989543676376343,
      -0.05855564773082733,
      0.0556502491235733,
      -0.03975924104452133,
      -0.049906179308891296,
      0.07047882676124573,
      -0.045053932815790176,
      0.06548040360212326,
      0.020590314641594887,
      -0.032290082424879074,
      0.020875809714198112,
      -0.08016026765108109,
      -0.015059900470077991,
      0.0006854948005639017,
      -0.028325280174613,
      0.161057248711586,
      0.08537794649600983,
      -0.07970284670591354,
      0.031319402158260345,
      -6.533999840030447e-05,
      -0.08104856312274933,
      0.006380657199770212,
      -0.03392992913722992,
      -0.04330417886376381,
      0.0057389819994568825,
      0.0007104240357875824,
      -0.0028699382673949003,
      0.046485476195812225,
      0.0503510944545269,
      -0.054624516516923904,
      -0.007227794732898474,
      -0.023477016016840935,
      0.009619606658816338,
      0.011508619412779808,
      0.06389496475458145,
      -0.007299917284399271,
      0.05655822902917862,
      -0.012821756303310394,
      0.0018226458923891187,
      0.05771159008145332,
      0.07000991702079773,
      0.0339822918176651,
      -0.03165719285607338,
      -0.01364761684089899,
      -0.018144767731428146,
      -0.04788185656070709,
      0.015128573402762413
    ],
    [
      -0.02513694576919079,
      0.011305968277156353,
      -0.03185456246137619,
      -0.13337957859039307,
      -0.09685395658016205,
      0.06345595419406891,
      0.06740296632051468,
      0.04840021952986717,
      -0.004329037386924028,
      -6.979727186262608e-05,
      -0.04880790784955025,
      -0.008422773331403732,
      0.004539732821285725,
      -0.020668668672442436,
      -0.007233809679746628,
      -0.035723913460969925,
      0.024479426443576813,
      0.013253957964479923,
      -0.02429807372391224,
      0.01305723562836647,
      0.013164371252059937,
      0.029658515006303787,
      -0.03494749218225479,
      0.03560385853052139,
      -0.03832134231925011,
      -0.0068006073124706745,
      -0.06489960849285126,
      0.06371180713176727,
      0.06424102932214737,
      0.012615288607776165,
      -0.025437531992793083,
      0.017343811690807343,
      -0.021193746477365494,
      -0.11678764224052429,
      0.05034997686743736,
      -0.0029709688387811184,
      -0.056925445795059204,
      0.0675927922129631,
      -0.031002068892121315,
      -0.04763983190059662,
      -0.013266557827591896,
      -0.016908809542655945,
      -0.018940581008791924,
      0.0422784723341465,
      0.07561873644590378,
      -0.07254894077777863,
      -0.04426462575793266,
      -0.06722518801689148,
      -0.056536003947257996,
      -0.03776491805911064,
      0.017555054277181625,
      0.0032074509654194117,
      0.010865142568945885,
      0.0395536832511425,
      -0.041275814175605774,
      -0.07476756721735,
      0.07413928955793381,
      0.004039248451590538,
      0.06986392289400101,
      0.0672362670302391,
      -0.030510278418660164,
      0.027770593762397766,
      -0.031385768204927444,
      -0.009410779923200607,
      0.10145560652017593,
      0.0626475140452385,
      -0.04405659809708595,
      0.077508345246315,
      0.025347258895635605,
      -0.05467863008379936,
      -0.035515133291482925,
      -0.01996513269841671,
      -0.02334715612232685,
      0.028978772461414337,
      0.04946314916014671,
      -0.010769031010568142,
      -0.025849541649222374,
      0.03825554996728897,
      -0.013369016349315643,
      0.05789632722735405,
      0.028380943462252617,
      -0.02859811671078205,
      -0.02392236329615116,
      -0.0017274969723075628,
      0.004355317912995815,
      0.03970835357904434,
      0.006512745283544064,
      -0.024052701890468597,
      0.0054520852863788605,
      -0.016117695719003677,
      0.02469671331346035,
      -0.028461165726184845,
      -0.010537870228290558,
      -0.0007034807931631804,
      0.03036484867334366,
      0.03390337526798248,
      -0.05203348025679588,
      0.03456324711441994,
      -0.015672199428081512,
      0.026605140417814255,
      0.02926134318113327,
      0.002336102770641446,
      0.027935942634940147,
      -0.06859254091978073,
      -0.02718382701277733,
      0.03628717362880707,
      -0.017388110980391502,
      0.051489267498254776,
      0.06672653555870056,
      -0.011817196384072304,
      -0.060582235455513,
      0.06349445879459381,
      0.04568199813365936,
      0.0322641059756279,
      0.004763039294630289,
      -0.023534882813692093,
      0.054708901792764664,
      0.06323161721229553,
      -0.055182430893182755,
      -0.005910573527216911,
      -0.0010224245488643646,
      0.01941954903304577,
      0.038254257291555405,
      -0.08315104991197586,
      0.020051319152116776,
      -0.0006887609488330781,
      -0.08494189381599426,
      0.007945263758301735,
      0.03172086924314499,
      -0.01238336693495512,
      0.03127310425043106,
      0.006798533257097006,
      0.010978001169860363,
      -0.06698902696371078,
      -0.019986404106020927,
      0.020132072269916534,
      -0.05757247284054756,
      0.11906267702579498,
      0.04603208974003792,
      0.030751265585422516,
      0.040728382766246796,
      -0.04778515174984932,
      0.006608012598007917,
      0.026106085628271103,
      0.049450863152742386,
      0.03148530051112175,
      0.0071412185207009315,
      0.0017349665286019444,
      0.007129766047000885,
      -0.10127130895853043,
      0.005512751638889313,
      0.07034958153963089,
      -0.045926302671432495,
      0.08131831139326096,
      0.06738059967756271,
      0.02427256479859352,
      0.06663613766431808,
      0.017229843884706497,
      0.025672132149338722,
      0.04532177746295929,
      -0.015232503414154053,
      -0.023222967982292175,
      0.05611428990960121,
      0.044805094599723816,
      0.00471386918798089,
      0.038179829716682434,
      0.057516686618328094,
      0.03546314314007759,
      -0.06395034492015839,
      -0.04326790198683739,
      0.07439172267913818,
      -0.07171817123889923,
      0.038032207638025284,
      0.05642038956284523,
      -0.005175773520022631,
      0.02734486758708954,
      -0.03324047476053238,
      0.0970928892493248,
      0.013586665503680706,
      0.04839472100138664,
      0.06271985918283463,
      -0.018844375386834145,
      0.03386988118290901,
      0.02884511835873127,
      0.013728032819926739,
      -0.07082728296518326,
      -0.03639991581439972,
      -0.03269130736589432,
      -0.027074867859482765,
      -0.018987352028489113,
      0.05918578431010246,
      0.09027064591646194,
      -0.0369916707277298,
      -0.005589813459664583,
      -0.04807177931070328,
      0.021839112043380737,
      0.13402311503887177,
      -0.11554094403982162,
      0.05025409907102585,
      -0.01573237031698227,
      0.023690195754170418,
      0.02399461157619953,
      0.05987541750073433,
      -0.044432006776332855,
      0.011371254920959473,
      0.06856219470500946,
      0.06542859971523285,
      0.009935427457094193,
      -0.06469190865755081,
      0.025819187983870506,
      0.06082972511649132,
      0.020070696249604225,
      0.062419645488262177,
      0.10455144941806793,
      -0.04246360436081886,
      0.042731449007987976,
      0.039248257875442505,
      -0.04363958165049553,
      -0.007995973341166973,
      -0.09026724100112915,
      0.02992601878941059,
      -0.08150070905685425,
      0.09135385602712631,
      0.0735628679394722,
      -0.05061846598982811,
      -0.014708112925291061,
      0.022435370832681656,
      0.02483220212161541,
      0.01533544808626175,
      -0.07914172112941742,
      -0.04580257087945938,
      0.010885791853070259,
      0.11357833445072174,
      -0.11262255907058716,
      -0.005532555282115936,
      0.02678462490439415,
      -0.00547771854326129,
      0.004295408725738525,
      -0.0363532118499279,
      -0.01597820408642292,
      -0.06777703016996384,
      0.06790090352296829,
      -0.02524482272565365,
      -0.10386328399181366,
      0.005505509674549103,
      -0.019502947106957436,
      0.0007485331152565777,
      0.019561925902962685,
      0.023949705064296722,
      0.08514763414859772,
      -0.008142593316733837,
      0.02738552913069725,
      0.03618776425719261,
      0.004919703584164381,
      0.007732599042356014,
      -0.05174773186445236,
      -0.00857522338628769,
      0.040688708424568176,
      0.06181449815630913,
      -0.026108210906386375,
      0.0092964181676507,
      0.09751264750957489,
      -0.0041419146582484245,
      0.05556406453251839,
      0.023501524701714516,
      -0.10867392271757126,
      0.049530357122421265,
      0.03434320166707039,
      -0.04631685093045235,
      -0.013629847206175327,
      -0.11555937677621841,
      -0.014602757059037685,
      0.06979890912771225,
      -0.0030681199859827757,
      0.04210689291357994,
      -0.03416560962796211,
      -0.032339442521333694,
      0.022928286343812943,
      -0.038549069315195084,
      -0.005387464538216591,
      -0.03853235021233559,
      -0.020720770582556725,
      -0.013074112124741077,
      0.005553017370402813,
      0.026708409190177917,
      -0.008551854640245438,
      -0.0004902074579149485,
      -0.056942764669656754,
      -0.03494808450341225,
      -0.02909582108259201,
      -0.03037290833890438,
      0.010397056117653847,
      -0.04391321912407875,
      0.09874767065048218,
      0.001640069647692144,
      0.038155991584062576,
      -0.004308031871914864,
      -0.014575828798115253,
      -0.025111651048064232,
      -0.02742968685925007,
      0.008590394631028175,
      -0.0035968110896646976,
      -0.019855942577123642,
      -0.054329048842191696,
      -0.03907820209860802,
      0.04900813475251198,
      -0.005521610379219055,
      0.07233426719903946,
      0.05200878530740738,
      0.005666885059326887,
      0.030026661232113838,
      0.04826197773218155,
      0.014585536904633045,
      -0.017207760363817215,
      -0.0664871484041214,
      0.003356595989316702,
      0.063835009932518,
      0.020057126879692078,
      -0.06412364542484283,
      0.004990067798644304,
      -0.09640292078256607,
      -0.039071254432201385,
      0.07465945184230804,
      0.00831767451018095,
      -0.03599095717072487,
      0.0002297057944815606,
      0.035283129662275314,
      -0.029975350946187973,
      0.037650056183338165,
      0.0625225380063057,
      -0.06466228514909744,
      -0.019334347918629646,
      -0.013472693972289562,
      0.01435083243995905,
      -0.006380708888173103,
      -0.002879548352211714,
      -0.017713796347379684,
      0.022447459399700165,
      0.002014006255194545,
      -0.0001887204998638481,
      0.0870676040649414,
      -0.02525610290467739,
      0.003848279593512416,
      0.13732944428920746,
      -0.020972074940800667,
      0.019649149850010872,
      -0.021467046812176704,
      0.019502762705087662,
      -0.05630036070942879,
      0.06501881778240204,
      -0.013478513807058334,
      -0.04172844439744949,
      0.025228744372725487,
      -0.0049788146279752254,
      0.0808369368314743,
      0.015438364818692207,
      -0.017234832048416138,
      0.028745658695697784,
      -0.06502769887447357,
      0.07710931450128555,
      0.0382205992937088,
      -0.07178613543510437,
      -0.03401936963200569,
      0.03940436244010925,
      -0.004302467685192823,
      0.0888097733259201,
      -0.02181735262274742,
      0.09070311486721039,
      -0.012139450758695602,
      0.0805334821343422,
      -0.027241120114922523,
      0.019808454439044,
      0.026944709941744804,
      0.046445902436971664,
      -0.03711875528097153,
      -0.01590246707201004,
      -0.05733434855937958,
      -0.08820910006761551,
      -0.031571164727211,
      -0.011742142029106617,
      -0.030918153002858162,
      -0.10020880401134491,
      -0.039111364632844925,
      0.05766092613339424,
      -0.03627205267548561,
      -0.07284163683652878,
      0.03288468345999718,
      0.04858360439538956,
      -0.057697147130966187,
      -0.030610032379627228,
      0.04419252276420593,
      0.021912267431616783,
      -0.10273541510105133,
      0.06497345864772797,
      -0.02596157230436802,
      -0.043296925723552704,
      -0.015444154851138592,
      -0.026975050568580627,
      0.012818513438105583,
      -0.08569853007793427,
      -0.007927793078124523,
      0.054051920771598816,
      -0.005449170712381601,
      0.01794162206351757,
      0.036315008997917175,
      -0.020611532032489777,
      0.022902874276041985,
      -0.06416570395231247,
      0.04381462186574936,
      0.04418889805674553,
      -0.003669248428195715,
      -0.022381575778126717,
      -0.021027496084570885,
      0.0006615659804083407,
      0.020095260813832283,
      0.12046446651220322,
      -0.04277896136045456,
      -0.12803004682064056,
      0.0013289168709889054,
      -0.07064750790596008,
      -0.00182720844168216,
      -0.0668163150548935,
      0.029373398050665855,
      -0.02843860723078251,
      0.022515354678034782,
      -0.06836702674627304,
      0.024634575471282005,
      -0.03861754387617111,
      -0.03442421182990074,
      -0.012292079627513885,
      -0.030452417209744453,
      0.05640207603573799,
      0.014308836311101913,
      -0.026369942352175713,
      0.020001763477921486,
      0.06235229969024658,
      0.07525786012411118,
      -0.014865593053400517,
      0.03569507226347923,
      0.046579621732234955,
      0.01245406549423933,
      0.0033473395742475986,
      0.02951626293361187,
      -0.0053220391273498535,
      0.02731277607381344,
      0.06334996968507767,
      0.026965992525219917,
      0.01894463039934635,
      -0.0015071622328832746,
      0.01236849557608366,
      0.021812256425619125,
      0.05896272882819176,
      0.10840100049972534,
      -0.05376952514052391,
      0.008504301309585571,
      0.002950744703412056,
      6.794176442781463e-05,
      -0.07038459181785583,
      -0.057872820645570755,
      -0.02363552339375019,
      -0.04860633611679077,
      0.08783968538045883,
      -0.05723695084452629,
      -0.05111760273575783,
      0.039313849061727524,
      0.0011829739669337869,
      -0.08462469279766083,
      0.033179644495248795,
      0.015256853774189949,
      0.039202600717544556,
      -0.05950671806931496,
      -0.005291409324854612,
      0.05212230235338211,
      -0.003738833125680685,
      0.01706540770828724,
      0.0714862123131752,
      -0.056518182158470154,
      -0.06361634284257889,
      -0.07960794121026993,
      -0.07675791531801224,
      0.029759541153907776,
      -0.04100969806313515,
      -0.056116048246622086,
      0.07614868134260178,
      -0.024965617805719376,
      0.03268855810165405,
      -0.023150036111474037,
      -0.0012715149205178022,
      0.03471597656607628,
      -0.09058064222335815,
      -0.01832406595349312,
      0.011813183315098286,
      0.1438124030828476,
      -0.0511818490922451,
      -0.07433611154556274,
      0.016804954037070274,
      0.04954857751727104,
      0.05722714960575104,
      -0.059491321444511414,
      0.0478830561041832,
      0.04068153351545334,
      -0.012646849267184734,
      0.02310740388929844
    ],
    [
      -0.01713060401380062,
      -0.0550205372273922,
      -0.055130068212747574,
      -0.03112589754164219,
      0.006811483297497034,
      0.034105706959962845,
      -0.03116745874285698,
      -0.09803986549377441,
      0.02258177101612091,
      -0.05127228796482086,
      -0.004978370387107134,
      0.01727779395878315,
      0.010657516308128834,
      0.005688740871846676,
      -0.04697323217988014,
      -0.007923654280602932,
      -0.002745060482993722,
      0.005237925332039595,
      -0.012778782285749912,
      0.0235102828592062,
      -0.013823817484080791,
      0.06842931360006332,
      0.11084731668233871,
      0.01761702261865139,
      -0.07379170507192612,
      -0.04345906153321266,
      0.060728769749403,
      0.040432557463645935,
      -0.07003077864646912,
      0.002356764627620578,
      -0.053504664450883865,
      -0.04336172342300415,
      0.043839290738105774,
      -0.12679657340049744,
      -0.09131599217653275,
      -0.008348560892045498,
      0.06314032524824142,
      -0.024071669206023216,
      0.010068314149975777,
      -0.03255607187747955,
      0.008953078649938107,
      -0.006604740861803293,
      -0.0019562290981411934,
      -0.016376400366425514,
      0.016170652583241463,
      0.0016688735922798514,
      0.013788760639727116,
      -0.023976866155862808,
      0.017355773597955704,
      -0.019290581345558167,
      0.0005275857984088361,
      -0.017438389360904694,
      -0.036348506808280945,
      0.04389572516083717,
      -0.04133421927690506,
      -0.038461651653051376,
      0.02351900190114975,
      -0.024902481585741043,
      -0.08952421694993973,
      0.027443060651421547,
      -0.009578131139278412,
      0.00022548524430021644,
      -0.08687590807676315,
      -0.016787340864539146,
      0.03430992364883423,
      -0.045513126999139786,
      -0.12202290445566177,
      0.03618939220905304,
      0.0029222469311207533,
      0.0333227813243866,
      0.070864737033844,
      -0.001354413921944797,
      0.029660433530807495,
      -0.05624501779675484,
      0.04542532190680504,
      -0.02336813509464264,
      -0.08099144697189331,
      -0.04378669708967209,
      0.062207888811826706,
      -0.02586919255554676,
      -0.0016977230552583933,
      -0.05500413477420807,
      -0.06244431063532829,
      -0.08934321254491806,
      -0.02437513694167137,
      0.003854583716019988,
      -0.08045230060815811,
      0.026416508480906487,
      -0.013626493513584137,
      -0.061710257083177567,
      -0.061336010694503784,
      0.014555007219314575,
      -0.04950911924242973,
      -0.01649690419435501,
      -0.031041046604514122,
      0.023155001923441887,
      -0.024371422827243805,
      -0.005504066590219736,
      0.00021069061767775565,
      0.0018943955656141043,
      0.01586468704044819,
      -0.02566765435039997,
      0.03712543472647667,
      0.08500482887029648,
      -0.07686086744070053,
      0.012335896492004395,
      0.016725458204746246,
      -0.03223986551165581,
      0.02917885221540928,
      -0.020140061154961586,
      -0.025975054129958153,
      -0.10187957435846329,
      -0.011849337257444859,
      0.059007611125707626,
      0.00018596883455757052,
      -0.011840533465147018,
      -0.012433629482984543,
      -0.051264140754938126,
      0.026039909571409225,
      -0.028811519965529442,
      -0.044691525399684906,
      0.05999143794178963,
      -0.03706388175487518,
      -0.03312272951006889,
      0.02455129660665989,
      -0.0005395746557042003,
      -0.10428205132484436,
      0.03799302503466606,
      -0.004148558713495731,
      -0.03764393553137779,
      -0.05478008836507797,
      -0.009212388657033443,
      -0.011826543137431145,
      0.03704199194908142,
      -0.04634319618344307,
      -0.03325694054365158,
      -0.06758877635002136,
      -0.048198916018009186,
      0.025816749781370163,
      0.04412641376256943,
      0.020454224199056625,
      0.021860582754015923,
      0.018392907455563545,
      0.1314258724451065,
      -0.0021039722487330437,
      -0.010227709077298641,
      0.02280162274837494,
      -0.01714838482439518,
      0.008256928995251656,
      -0.001054262393154204,
      0.008836312219500542,
      -0.04422757402062416,
      -0.007924454286694527,
      0.03025858663022518,
      0.006149879656732082,
      -0.0515269860625267,
      0.0032672295346856117,
      -0.03184334561228752,
      -0.0719386637210846,
      0.014515448361635208,
      0.005556711461395025,
      -0.032751765102148056,
      0.0723508894443512,
      -0.02812189795076847,
      0.010741403326392174,
      0.03809407353401184,
      -0.003323699114844203,
      -0.03613854572176933,
      0.011775306425988674,
      -0.05005122721195221,
      0.05139392986893654,
      0.017523929476737976,
      0.01662769913673401,
      -0.024046150967478752,
      -0.0950850248336792,
      0.044004958122968674,
      0.03262873366475105,
      -0.07369907200336456,
      -0.0019166450947523117,
      0.03133814409375191,
      0.04482300579547882,
      -0.06545838713645935,
      0.027358215302228928,
      0.04413255676627159,
      0.009317595511674881,
      -0.06616459786891937,
      0.07297622412443161,
      0.010376246646046638,
      -0.0664653405547142,
      0.001124528469517827,
      -0.06105393171310425,
      -0.058239202946424484,
      -0.013955963775515556,
      0.029242204502224922,
      -0.01559645589441061,
      0.01824759878218174,
      -0.05958660691976547,
      -0.07778331637382507,
      0.05164802819490433,
      0.009831232950091362,
      0.12070441246032715,
      0.02621191367506981,
      0.057103175669908524,
      -0.03692159429192543,
      0.06269581615924835,
      -0.0026916018687188625,
      -0.033868271857500076,
      0.0322716161608696,
      0.0720304548740387,
      -0.000121005141409114,
      -0.046521104872226715,
      -0.003751541255041957,
      0.08430659025907516,
      0.07434364408254623,
      -0.00852812547236681,
      0.0018144040368497372,
      0.007850420661270618,
      0.044558167457580566,
      0.032013025134801865,
      -0.011986645869910717,
      0.023172372952103615,
      -0.004929185379296541,
      0.030185440555214882,
      -0.05112440139055252,
      0.0017465606797486544,
      0.014361792244017124,
      -0.06339839845895767,
      -0.0083401408046484,
      -0.038886021822690964,
      -0.03711907938122749,
      0.020997943356633186,
      0.03561008349061012,
      -0.034536998718976974,
      -0.0723910853266716,
      0.010288954712450504,
      0.03184448927640915,
      -0.029576484113931656,
      -0.00157068925909698,
      -0.03289102017879486,
      0.00023989466717466712,
      -0.02520665153861046,
      0.00948454812169075,
      0.016899077221751213,
      0.028683165088295937,
      0.03766081482172012,
      0.023591727018356323,
      -0.00993199460208416,
      0.0435282364487648,
      -0.030356522649526596,
      -0.043555013835430145,
      0.06333371251821518,
      0.0867990106344223,
      -0.05112685263156891,
      -0.05575239285826683,
      0.019572483375668526,
      0.019621526822447777,
      -0.04206769913434982,
      -0.07854710519313812,
      0.057620663195848465,
      0.008145657368004322,
      0.03469240292906761,
      -0.019696533679962158,
      -0.13286375999450684,
      0.050753019750118256,
      -0.051932938396930695,
      0.042992036789655685,
      0.002647144254297018,
      -0.022406285628676414,
      -0.050637394189834595,
      0.05160839855670929,
      -0.027272824198007584,
      0.03382895514369011,
      0.08788479119539261,
      0.005557467695325613,
      -0.08354432135820389,
      0.036242157220840454,
      0.0060990494675934315,
      0.03856226056814194,
      0.035598065704107285,
      0.08730851113796234,
      0.01601300574839115,
      -0.07124829292297363,
      -0.02815929427742958,
      0.00014260497118812054,
      0.03825414925813675,
      0.020814061164855957,
      -0.014008942060172558,
      -0.04243386536836624,
      0.03418833389878273,
      -0.03730850666761398,
      -0.0004427096573635936,
      0.010616740211844444,
      0.0007985616102814674,
      0.023657893761992455,
      0.020463189110159874,
      0.06060769408941269,
      -0.04001313075423241,
      0.0146489841863513,
      0.01110526267439127,
      0.09955603629350662,
      -0.04927504062652588,
      0.08579625189304352,
      0.01801416091620922,
      -0.005014604888856411,
      -0.012411120347678661,
      0.04334118589758873,
      -0.024604469537734985,
      -0.05237124487757683,
      0.010566682554781437,
      0.06460585445165634,
      0.04514584690332413,
      -0.014523133635520935,
      0.1566680520772934,
      -0.014169881120324135,
      -0.008599558845162392,
      -0.0710364356637001,
      0.01205816026777029,
      0.02502523362636566,
      -0.06353078782558441,
      -0.03644612431526184,
      -0.07186808437108994,
      0.0012428424088284373,
      -0.028401868417859077,
      -0.010483368299901485,
      -0.09136039763689041,
      0.0806887149810791,
      -0.02280355989933014,
      0.05157376080751419,
      0.10641796886920929,
      -0.021056581288576126,
      0.03260403499007225,
      0.010721616446971893,
      -0.014690585434436798,
      0.0024222456850111485,
      0.06472066044807434,
      0.051981229335069656,
      0.014471621252596378,
      0.02650885097682476,
      -0.0598037987947464,
      0.039871104061603546,
      0.10020666569471359,
      0.016122380271553993,
      0.027947038412094116,
      0.024814222007989883,
      -0.06210770830512047,
      -0.001961007947102189,
      0.01876225881278515,
      -0.03696970269083977,
      0.02837720699608326,
      -0.01949150860309601,
      0.015783701092004776,
      -0.019736461341381073,
      -0.05365070328116417,
      -0.059716902673244476,
      0.005524056497961283,
      0.004605232737958431,
      0.02353811077773571,
      0.04516512155532837,
      -0.035878825932741165,
      -0.015557261183857918,
      0.042872920632362366,
      -0.05389776453375816,
      -0.08608865737915039,
      0.0296208206564188,
      -0.03977040946483612,
      0.054070308804512024,
      -0.041066743433475494,
      -0.037511587142944336,
      0.07947863638401031,
      -0.03989012911915779,
      -0.046224020421504974,
      0.041257038712501526,
      -0.011600606143474579,
      0.06495783478021622,
      -0.09196432679891586,
      -0.002979096956551075,
      0.016613304615020752,
      0.022091617807745934,
      0.027314580976963043,
      0.056295495480298996,
      0.06646529585123062,
      -0.06026211008429527,
      -0.014156815595924854,
      -0.10840046405792236,
      -0.03331824392080307,
      -0.019396403804421425,
      0.08980866521596909,
      -0.026880433782935143,
      -0.009593539871275425,
      -0.035351935774087906,
      0.02921389974653721,
      0.031176403164863586,
      -0.037408892065286636,
      0.044150445610284805,
      0.031068013980984688,
      -0.020613079890608788,
      0.001625462668016553,
      -0.041775383055210114,
      -0.06346368044614792,
      -0.10170160233974457,
      -0.05896693840622902,
      -0.06598875671625137,
      0.06903430074453354,
      -0.03498472645878792,
      -0.10842779278755188,
      0.10854798555374146,
      -0.04044010117650032,
      -0.05466890707612038,
      0.03626622259616852,
      -0.01373980101197958,
      0.011905311606824398,
      -0.004775229841470718,
      0.0471348837018013,
      -0.06472831219434738,
      -0.02886892296373844,
      0.0015282449312508106,
      0.058161552995443344,
      -0.016461657360196114,
      -0.009330054745078087,
      -0.04980970174074173,
      -0.008274720050394535,
      0.026714257895946503,
      -0.0027142646722495556,
      0.021239036694169044,
      0.037103377282619476,
      0.018348393961787224,
      0.10539121180772781,
      0.057557106018066406,
      -0.044987741857767105,
      0.09604501724243164,
      0.040295906364917755,
      0.05393882468342781,
      0.05716618150472641,
      -0.020614685490727425,
      -0.041376493871212006,
      -0.0030348284635692835,
      -0.04155866056680679,
      -0.047455865889787674,
      0.02890310063958168,
      0.0341356098651886,
      -0.006730914115905762,
      0.07733453065156937,
      -0.03035219945013523,
      0.04015083983540535,
      -0.05181178078055382,
      -0.006088148336857557,
      -0.01770295575261116,
      -0.048971302807331085,
      0.014329602010548115,
      0.0011080432450398803,
      -0.07637473940849304,
      0.10526891052722931,
      -0.006515104789286852,
      -0.03339878469705582,
      0.05699684098362923,
      0.037367187440395355,
      0.0538925938308239,
      -0.01073659397661686,
      0.054911013692617416,
      -0.021309208124876022,
      -0.056528523564338684,
      0.05157444626092911,
      -0.028318334370851517,
      0.017241450026631355,
      0.011841402389109135,
      0.036754708737134933,
      -0.0308057963848114,
      0.0674978494644165,
      0.04105755314230919,
      -0.07694034278392792,
      0.008620161563158035,
      0.029282426461577415,
      -0.03543126955628395,
      -0.055744342505931854,
      0.013942264020442963,
      -0.005184897221624851,
      -0.03628166392445564,
      0.05932797119021416,
      0.002192566404119134,
      -0.041782185435295105,
      -0.01148389745503664,
      0.007465690840035677,
      0.00403370289131999,
      0.07312381267547607,
      0.01084420457482338,
      0.012851919047534466,
      0.029403813183307648,
      -0.01209882739931345,
      0.03478120267391205,
      -0.010390971787273884,
      0.02277146466076374,
      -0.07060408592224121,
      -0.011042432859539986,
      -0.045845527201890945,
      -0.01365749817341566,
      -0.014806420542299747,
      -0.016541333869099617,
      -0.016848716884851456,
      0.02458501234650612,
      0.02347557060420513,
      0.027072174474596977,
      0.013783669099211693,
      0.0075758532620966434
    ],
    [
      0.01879178360104561,
      -0.012419058009982109,
      -0.0839647576212883,
      -0.019525090232491493,
      -0.035687461495399475,
      0.05320059508085251,
      0.12563665211200714,
      0.11052632331848145,
      0.025245819240808487,
      -0.010010792873799801,
      0.004136246163398027,
      0.050085749477148056,
      0.00553167425096035,
      -0.024640433490276337,
      0.06916865706443787,
      -0.008346032351255417,
      -0.02282867021858692,
      -0.04234686493873596,
      0.0060968464240431786,
      -0.02741573378443718,
      -0.019706159830093384,
      0.0134119326248765,
      0.037060681730508804,
      0.08303305506706238,
      -0.05679938942193985,
      -0.000668249384034425,
      -0.0056119877845048904,
      0.03243420645594597,
      0.019120516255497932,
      0.04437973350286484,
      -0.0932612270116806,
      -0.06896670907735825,
      0.0043203700333833694,
      -0.014765847474336624,
      0.01988886669278145,
      0.05081223323941231,
      0.035154663026332855,
      -0.0135604627430439,
      -0.04365992546081543,
      -0.07499418407678604,
      0.03690057247877121,
      -0.02082470804452896,
      -0.019366184249520302,
      0.06627830862998962,
      -0.008470532484352589,
      0.09244325757026672,
      -0.030466441065073013,
      0.05612313002347946,
      0.11561266332864761,
      0.045290976762771606,
      0.08960049599409103,
      -0.07668118178844452,
      -0.012032238766551018,
      -0.06106960400938988,
      -0.0668325424194336,
      -0.03846025839447975,
      0.02111271768808365,
      -0.06725295633077621,
      -0.024105843156576157,
      -0.048223190009593964,
      -0.05853574350476265,
      -0.024534739553928375,
      -0.015533438883721828,
      -0.027986474335193634,
      0.015125522390007973,
      -0.053685933351516724,
      -0.028169291093945503,
      -0.004476381931453943,
      0.01869054324924946,
      0.00854671373963356,
      0.03748215362429619,
      0.052818041294813156,
      -0.058810748159885406,
      -0.04901746287941933,
      0.0029953636694699526,
      -0.041245829313993454,
      0.009164742194116116,
      -0.005688118282705545,
      0.06616993993520737,
      -0.01991523616015911,
      -0.013784768991172314,
      0.0007098790374584496,
      0.06611227989196777,
      0.05512283369898796,
      -0.010235965251922607,
      0.040014032274484634,
      -0.01226270105689764,
      -0.09800712019205093,
      0.010482956655323505,
      -0.010242883116006851,
      0.012471461668610573,
      0.01173565536737442,
      -0.03540393337607384,
      0.017794599756598473,
      0.008838534355163574,
      0.04608823359012604,
      0.05460882559418678,
      -0.07561168074607849,
      -0.025022603571414948,
      0.054908521473407745,
      0.03187205269932747,
      0.0015108244260773063,
      -0.02949550189077854,
      -0.00839474517852068,
      0.06522022187709808,
      0.023138532415032387,
      0.04660544544458389,
      -0.04995590075850487,
      0.06459338217973709,
      -0.07292311638593674,
      -0.006148630753159523,
      -0.10807321220636368,
      0.017079807817935944,
      -0.004880130290985107,
      -0.04442601650953293,
      -0.04919607192277908,
      -0.037384796887636185,
      0.12848205864429474,
      -0.08056571334600449,
      0.06267490237951279,
      0.08056624978780746,
      -0.019910376518964767,
      -0.009194235317409039,
      0.02164248749613762,
      0.04040667042136192,
      -0.09721261262893677,
      -0.02481955848634243,
      0.0672634169459343,
      -0.03930139169096947,
      -0.021932242438197136,
      -0.027326026931405067,
      0.02989966608583927,
      -0.054726965725421906,
      0.010680696927011013,
      -0.01882367581129074,
      -0.003466724418103695,
      0.0658206194639206,
      0.0005710401455871761,
      0.07125463336706161,
      -0.04398949444293976,
      0.03000815212726593,
      0.08197027444839478,
      -0.06445790082216263,
      -0.09051014482975006,
      0.035174936056137085,
      -0.02486058510839939,
      0.055041681975126266,
      0.03463347628712654,
      -0.08072933554649353,
      0.0335770808160305,
      0.005824352148920298,
      -0.016297755762934685,
      0.010777280665934086,
      0.07531433552503586,
      0.07734851539134979,
      -0.008873258717358112,
      -0.03325518220663071,
      -0.03500009700655937,
      -0.04179265722632408,
      0.008625996299088001,
      0.014176761731505394,
      -0.02859046682715416,
      0.053550586104393005,
      -0.006322110537439585,
      -0.022215725854039192,
      0.02448335848748684,
      0.015858305618166924,
      0.05024784803390503,
      0.061166830360889435,
      0.024393275380134583,
      0.03482495993375778,
      -0.023983998224139214,
      0.07555072009563446,
      0.0634661391377449,
      0.02821028046309948,
      -0.007077965885400772,
      -0.08796177804470062,
      -0.02482958883047104,
      0.0280999094247818,
      0.07011372596025467,
      -0.007102114148437977,
      0.06590727716684341,
      0.0038653905503451824,
      0.03115914575755596,
      0.03768056258559227,
      0.007589865475893021,
      0.02997583895921707,
      -0.04232698678970337,
      -0.11569349467754364,
      -0.048990584909915924,
      -0.040956705808639526,
      0.015524842776358128,
      0.10783831775188446,
      -0.09118995070457458,
      -0.00035131003824062645,
      0.04138597473502159,
      0.046263329684734344,
      0.052955858409404755,
      -0.011869003064930439,
      -0.025694066658616066,
      0.024561619386076927,
      -0.05568210408091545,
      0.07433899492025375,
      -0.035185571759939194,
      -0.03393730893731117,
      0.12000647932291031,
      -0.04274612292647362,
      -0.02917100116610527,
      0.030819764360785484,
      -0.07044381648302078,
      -0.04808923602104187,
      -0.0018390379846096039,
      0.07767163217067719,
      -0.008564770221710205,
      -0.07482820004224777,
      0.06559938192367554,
      -0.033067867159843445,
      -0.05509359389543533,
      0.022660844027996063,
      0.00037857427378185093,
      0.02706582471728325,
      -0.018416061997413635,
      -0.014866741374135017,
      0.00362867652438581,
      -0.02672622725367546,
      -0.030247455462813377,
      -0.0016494457377120852,
      0.025057662278413773,
      -0.017469892278313637,
      0.05213255435228348,
      0.017149802297353745,
      -0.026636730879545212,
      -0.07702474296092987,
      -0.05173005536198616,
      -0.016038281843066216,
      -0.03342503681778908,
      0.020456666126847267,
      0.031283650547266006,
      -0.012044500559568405,
      0.040869470685720444,
      -0.014494542963802814,
      -0.13057927787303925,
      0.023509997874498367,
      -0.0004144291451666504,
      0.1071339026093483,
      0.0841534435749054,
      -0.06365640461444855,
      0.010461289435625076,
      -0.0312703438103199,
      -0.005608209874480963,
      -0.03563948720693588,
      0.02840849943459034,
      -0.0071358028799295425,
      0.00710276048630476,
      -0.014010832644999027,
      0.016473349183797836,
      0.04137134179472923,
      0.010858653113245964,
      -0.06131015717983246,
      -0.08962146192789078,
      -0.018649445846676826,
      0.008330282755196095,
      -0.04825620353221893,
      0.004800642840564251,
      -0.03794174641370773,
      0.030206559225916862,
      0.05280538648366928,
      -0.00350547325797379,
      -0.0015118812443688512,
      -0.004717857576906681,
      -0.04054849222302437,
      -0.023644106462597847,
      -0.013850532472133636,
      -0.0075319926254451275,
      0.023991476744413376,
      0.024666180834174156,
      -0.09765143692493439,
      -0.033262379467487335,
      0.03824205696582794,
      0.049663446843624115,
      -0.009371042251586914,
      -0.023681778460741043,
      -0.08940000832080841,
      -0.08809743076562881,
      0.08580879867076874,
      -0.05408578738570213,
      0.002451186301186681,
      0.11120344698429108,
      0.05454805865883827,
      -0.03355368599295616,
      0.04223164543509483,
      0.01102042943239212,
      0.03483838960528374,
      0.024739932268857956,
      -0.07218863815069199,
      0.007605621591210365,
      -0.02867905981838703,
      -0.003646111348643899,
      -0.02033676952123642,
      -0.04403183236718178,
      -0.00019557130872271955,
      -0.04312824457883835,
      -0.0056268805637955666,
      -0.10412567108869553,
      -0.028249619528651237,
      -0.006411133334040642,
      0.03796730935573578,
      0.08392686396837234,
      0.05736062675714493,
      0.05422523245215416,
      0.05929375812411308,
      -0.008143913000822067,
      -0.03180389478802681,
      -0.0017886256100609899,
      -0.07385949790477753,
      0.012234046123921871,
      0.06261194497346878,
      -0.008395007811486721,
      -0.055484309792518616,
      -0.034747205674648285,
      -0.05122753977775574,
      -0.001804116414859891,
      -0.05152174457907677,
      0.05792202427983284,
      -0.04320351406931877,
      0.07866557687520981,
      0.011266430839896202,
      -0.017480917274951935,
      -0.06552404910326004,
      -0.014645610935986042,
      0.0010429761605337262,
      -0.0908389613032341,
      -0.01048535667359829,
      0.049904774874448776,
      -0.09135561436414719,
      0.018518229946494102,
      -0.01516917534172535,
      0.04311346635222435,
      -0.01375570148229599,
      0.027050580829381943,
      -0.03172492980957031,
      -0.04941791668534279,
      -0.00784632284194231,
      -0.03529401496052742,
      -0.03219868615269661,
      -0.0017716652946546674,
      0.0442303903400898,
      -0.019349656999111176,
      -0.010250038467347622,
      0.03889486566185951,
      -0.018643809482455254,
      -0.10436856001615524,
      0.06590696424245834,
      -0.0308113694190979,
      0.04361589998006821,
      -0.06956638395786285,
      0.03971130773425102,
      0.03875137120485306,
      -0.04987453669309616,
      -0.0019228397868573666,
      0.01214540284126997,
      0.01837839186191559,
      0.039383575320243835,
      0.039012376219034195,
      -0.00845384318381548,
      0.015849847346544266,
      -0.01914609782397747,
      -0.03593956306576729,
      -0.06016991287469864,
      -0.03869735449552536,
      0.003422308713197708,
      0.0430360846221447,
      0.039733197540044785,
      -0.00290185259655118,
      -0.04601147398352623,
      0.008812380023300648,
      -0.08558367937803268,
      0.007824914529919624,
      0.05920039489865303,
      -0.03875106945633888,
      -0.0653471127152443,
      -0.03760445863008499,
      0.02983975224196911,
      -0.02814565785229206,
      0.013497896492481232,
      0.08854688704013824,
      -0.025273621082305908,
      -0.003937226720154285,
      0.0037932773120701313,
      0.07457994669675827,
      0.047253597527742386,
      0.08025075495243073,
      -0.010468470863997936,
      -0.08591024577617645,
      -0.08458220213651657,
      0.09775164723396301,
      0.05241398885846138,
      0.05220780521631241,
      -0.009120446629822254,
      0.09134428203105927,
      0.012192631140351295,
      -0.030777184292674065,
      -0.013116966001689434,
      -0.06832384318113327,
      0.05277225002646446,
      -0.013217278756201267,
      -0.09971295297145844,
      -0.06505434960126877,
      0.020981959998607635,
      0.051000650972127914,
      -0.03468018025159836,
      0.024142572656273842,
      -0.020885339006781578,
      0.03778167441487312,
      0.056255098432302475,
      -0.05065496265888214,
      -0.07994901388883591,
      0.037373509258031845,
      -0.002082442631945014,
      -0.012589222751557827,
      -0.051157791167497635,
      -0.025403687730431557,
      0.05137762427330017,
      0.010588334873318672,
      0.028054216876626015,
      0.05514482408761978,
      0.01708732731640339,
      -0.017281539738178253,
      -0.034045565873384476,
      -0.06134124845266342,
      0.01716211438179016,
      -0.02340981550514698,
      0.03603454679250717,
      0.01148433517664671,
      0.017298894003033638,
      0.002563448855653405,
      -0.09037694334983826,
      -0.0845024362206459,
      -0.027991177514195442,
      -0.034183043986558914,
      -0.058390721678733826,
      -0.027375776320695877,
      -0.06385037302970886,
      -0.04594527184963226,
      0.008965004235506058,
      0.059583988040685654,
      -0.05763259530067444,
      0.018689287826418877,
      0.020603356882929802,
      -0.02967202104628086,
      0.060424696654081345,
      0.009088943712413311,
      -0.0032856182660907507,
      0.027397815138101578,
      0.05197877809405327,
      -0.05060778930783272,
      0.05464344844222069,
      -0.11724253743886948,
      -0.06629151105880737,
      -0.0614510253071785,
      0.03870962932705879,
      -0.000930635433178395,
      -0.001361137954518199,
      -0.034632131457328796,
      0.057161785662174225,
      0.0015926642809063196,
      -0.07011044770479202,
      0.020359134301543236,
      -0.00756634259596467,
      -0.043634142726659775,
      0.0638958066701889,
      0.028718501329421997,
      0.02184668742120266,
      -0.007715386338531971,
      -0.027331693097949028,
      -0.002696696203202009,
      -0.006312906742095947,
      0.010751020163297653,
      0.05738535150885582,
      0.02907782793045044,
      -0.03644853085279465,
      -0.026989666745066643,
      -0.018868261948227882,
      -0.010656616650521755,
      -0.06982943415641785,
      -0.021688900887966156,
      -0.0037839114665985107,
      -0.06658574938774109,
      0.1025538221001625,
      -0.0547526516020298,
      0.04072756692767143,
      0.06742668151855469,
      0.01302122138440609,
      -0.019205367192626,
      0.11151803284883499,
      -0.024767495691776276,
      0.08776845782995224,
      -0.05109867826104164,
      0.02231861837208271,
      0.029675591737031937,
      0.03321925178170204,
      -0.09682372957468033,
      -0.038118500262498856
    ],
    [
      -0.04610684514045715,
      0.011201834306120872,
      -0.025317545980215073,
      -0.016595754772424698,
      0.05531008914113045,
      0.039474718272686005,
      0.02541075460612774,
      0.10883083939552307,
      0.030442750081419945,
      -0.02402655780315399,
      0.005647833459079266,
      -0.046132829040288925,
      -0.02027067355811596,
      -0.005011760629713535,
      0.061492178589105606,
      0.025811849161982536,
      0.00557599077001214,
      0.012195281684398651,
      -0.014362446963787079,
      -0.06166960671544075,
      -0.0903974175453186,
      -0.0745883509516716,
      -0.03577940911054611,
      0.04058573395013809,
      -0.07390177249908447,
      -0.0038472048472613096,
      0.04815463721752167,
      -0.050260029733181,
      0.09498660266399384,
      -0.0024696311447769403,
      -0.047185759991407394,
      -0.02791167050600052,
      -0.04517154395580292,
      -0.002805269556120038,
      -0.04424811154603958,
      0.016093138605356216,
      0.020644566044211388,
      -0.05029294639825821,
      0.024735746905207634,
      0.005268688779324293,
      -0.023073777556419373,
      0.07285226881504059,
      0.04140564799308777,
      -0.011948857456445694,
      0.022272814065217972,
      -0.040781766176223755,
      0.10963501781225204,
      0.03356849029660225,
      -0.00364655046723783,
      0.039085790514945984,
      0.04387805983424187,
      0.031005917116999626,
      0.07486309856176376,
      -0.0406201146543026,
      0.010330806486308575,
      0.06851240992546082,
      0.01139216311275959,
      0.06263110041618347,
      -0.013759033754467964,
      -0.0025356379337608814,
      -0.032392021268606186,
      -0.051302600651979446,
      0.009727763012051582,
      -0.02742033079266548,
      -0.050342995673418045,
      -0.052406322211027145,
      -0.03985774517059326,
      -0.0037148224655538797,
      0.031565792858600616,
      0.06255323439836502,
      0.05061262473464012,
      -0.06547611206769943,
      0.06352601945400238,
      0.04888418689370155,
      0.0067069027572870255,
      -0.03865105286240578,
      0.05727147310972214,
      -0.019450288265943527,
      0.024677734822034836,
      0.057176101952791214,
      -0.02798566408455372,
      -0.017973121255636215,
      0.026497280225157738,
      0.03883480280637741,
      0.03915761038661003,
      -0.01809905469417572,
      -0.08507093787193298,
      0.036196790635585785,
      -0.005315277259796858,
      -0.09961641579866409,
      -0.014694594778120518,
      0.1076052114367485,
      -0.018107589334249496,
      0.038756173104047775,
      -0.009311493486166,
      -0.047544896602630615,
      -0.03148631379008293,
      0.017083218321204185,
      -0.014821459539234638,
      0.09699171036481857,
      -0.012199940159916878,
      0.003412417834624648,
      0.0069640460424125195,
      -0.0038748914375901222,
      -0.05254344642162323,
      0.017766447737812996,
      -0.015476485714316368,
      -0.006294322200119495,
      0.031178832054138184,
      0.05165174975991249,
      -0.003402405185624957,
      -0.09062068164348602,
      -0.10215681791305542,
      -0.05595136061310768,
      -0.02179417386651039,
      0.018682299181818962,
      -0.01898973248898983,
      -0.11687910556793213,
      0.06852614879608154,
      -0.03650790825486183,
      -0.0807293951511383,
      0.08156780898571014,
      -0.06919661909341812,
      0.005315115209668875,
      0.08189143985509872,
      -0.03818293288350105,
      -0.0420418456196785,
      0.061052098870277405,
      -0.04631981998682022,
      -0.0023376070894300938,
      0.030444135889410973,
      0.06055609881877899,
      0.04811587929725647,
      -0.006143450271338224,
      -0.012306333519518375,
      -0.003690107725560665,
      -0.020900702103972435,
      -0.043680042028427124,
      -0.002114174421876669,
      -0.0494631864130497,
      -0.027134088799357414,
      0.028480660170316696,
      -0.004875340964645147,
      -0.04875834286212921,
      -0.07751887291669846,
      0.033660512417554855,
      0.00043379576527513564,
      0.04620018228888512,
      0.028837207704782486,
      0.03510328009724617,
      0.019208695739507675,
      -0.008192434906959534,
      -0.030831221491098404,
      0.08329425752162933,
      -0.003755958518013358,
      -0.05880462005734444,
      0.09444486349821091,
      0.04015861079096794,
      -0.01662980392575264,
      0.07690533250570297,
      -0.0030492774676531553,
      -0.0039589847438037395,
      0.0036048595793545246,
      0.008526959456503391,
      0.10918872803449631,
      -0.020222468301653862,
      -0.08246831595897675,
      -0.035267796367406845,
      -0.04558659344911575,
      0.06178184971213341,
      0.03464864566922188,
      0.0336562804877758,
      0.038400307297706604,
      0.024090586230158806,
      -0.08394954353570938,
      -0.04665597528219223,
      0.04668350890278816,
      0.022585706785321236,
      0.003222537226974964,
      -0.011565307155251503,
      0.012677588500082493,
      -0.018302250653505325,
      -0.060659557580947876,
      0.01873968169093132,
      0.006189679726958275,
      -0.029123300686478615,
      0.03010399080812931,
      -0.021845411509275436,
      0.002436224604025483,
      -0.028020421043038368,
      -0.04567355662584305,
      -0.0042223259806632996,
      -0.043403591960668564,
      -0.010454794391989708,
      0.06773918122053146,
      0.08674003928899765,
      -0.07283706963062286,
      0.0894172266125679,
      0.013852493837475777,
      0.06537541002035141,
      -0.015819238498806953,
      0.02277575246989727,
      0.007107486017048359,
      -0.03621593490242958,
      -0.022248443216085434,
      0.007289506029337645,
      -0.10283086448907852,
      -0.07547591626644135,
      0.02829265035688877,
      0.0010864458745345473,
      -0.004466489888727665,
      0.004908971022814512,
      0.08116636425256729,
      -0.031302209943532944,
      -0.004439936485141516,
      -0.036965299397706985,
      -0.05604677274823189,
      -0.012052317149937153,
      0.01708979532122612,
      -0.006375767756253481,
      0.00028103910153731704,
      0.0016075539169833064,
      -0.023758746683597565,
      -0.0616074800491333,
      -0.10651092231273651,
      -0.008288617245852947,
      0.020740676671266556,
      0.0016259454423561692,
      0.07436252385377884,
      0.09127635508775711,
      -0.04553848132491112,
      -0.009679514914751053,
      -0.029320020228624344,
      -0.08220071345567703,
      -0.004466715268790722,
      0.047790683805942535,
      -0.051671870052814484,
      0.06970182806253433,
      -0.02540695108473301,
      -0.060906216502189636,
      0.039329078048467636,
      0.021075544878840446,
      -0.020600643008947372,
      -0.01912669837474823,
      0.03368945047259331,
      0.04623023420572281,
      -0.006096065975725651,
      0.03441059961915016,
      -0.05245985835790634,
      0.05852508917450905,
      0.0032681571319699287,
      0.024665914475917816,
      0.015547022223472595,
      -0.018235206604003906,
      -0.03513360768556595,
      0.07466496527194977,
      0.009330403991043568,
      -0.017860593274235725,
      -0.01528893318027258,
      -0.09569099545478821,
      -0.040706634521484375,
      -0.025275304913520813,
      0.018662042915821075,
      0.03867295756936073,
      -0.004569935146719217,
      -0.028716525062918663,
      0.0033154874108731747,
      0.06212887540459633,
      -0.036328915506601334,
      -0.015273883938789368,
      0.0056131761521101,
      -0.012465331703424454,
      -0.029198789969086647,
      0.04147756099700928,
      -0.10662980377674103,
      -0.01575453206896782,
      -0.031232168897986412,
      0.1068936213850975,
      0.039343688637018204,
      -0.024794042110443115,
      -0.021757422015070915,
      0.0622941255569458,
      -0.025146840140223503,
      -0.02522873692214489,
      -0.02014550380408764,
      0.045419324189424515,
      0.04674951359629631,
      0.00960636232048273,
      -0.09332772344350815,
      -0.01322074607014656,
      0.013498842716217041,
      -0.012735171243548393,
      -0.06241820007562637,
      -0.016000429168343544,
      0.054835908114910126,
      0.04896698519587517,
      -0.017303379252552986,
      -0.006743140518665314,
      -0.020240291953086853,
      -0.006591111421585083,
      -0.01563449390232563,
      0.0029541687108576298,
      -0.007642210461199284,
      0.035125214606523514,
      -0.03545307368040085,
      -0.02547125704586506,
      0.025891434401273727,
      -0.032899532467126846,
      0.01474064216017723,
      -0.06280932575464249,
      0.05310478061437607,
      -0.0539667084813118,
      0.009994112886488438,
      -0.05295246094465256,
      -0.013029255904257298,
      0.04627388343214989,
      -0.023809408769011497,
      -0.07258328050374985,
      0.040826618671417236,
      0.02296488732099533,
      0.005739318672567606,
      -0.06895951926708221,
      -0.06499376893043518,
      0.10200569778680801,
      -0.06460198014974594,
      -0.004521469585597515,
      0.01806844025850296,
      -0.010303634218871593,
      -0.001993281999602914,
      0.0182549636811018,
      -0.010340139269828796,
      0.07593074440956116,
      -0.07481663674116135,
      0.098237544298172,
      0.04526664689183235,
      0.034577660262584686,
      -0.032127346843481064,
      -0.0547395721077919,
      0.09259279072284698,
      0.021557742729783058,
      0.08954048156738281,
      0.03738723695278168,
      -0.032421670854091644,
      -0.001164988148957491,
      0.028595397248864174,
      -0.0039728619158267975,
      -0.0402744822204113,
      0.06024353578686714,
      -0.022715386003255844,
      0.04803142324090004,
      -0.08088752627372742,
      0.017358118668198586,
      0.018550068140029907,
      -0.06663936376571655,
      0.045113977044820786,
      -0.035908401012420654,
      -0.016622865572571754,
      0.07165592908859253,
      -0.0507526770234108,
      0.03644678369164467,
      -0.02843436226248741,
      0.015576701611280441,
      0.0872744545340538,
      0.10669702291488647,
      -0.010230747051537037,
      -0.028421573340892792,
      -0.07700222730636597,
      -0.05797181650996208,
      0.08631398528814316,
      0.028090700507164,
      0.024600837379693985,
      0.09071225672960281,
      0.02404191344976425,
      -0.013493061996996403,
      0.04912341386079788,
      0.013370734639465809,
      -0.018524665385484695,
      0.0063936361111700535,
      0.02299673669040203,
      0.04398297518491745,
      -0.025329770520329475,
      -0.046723369508981705,
      -0.007016183342784643,
      0.06659894436597824,
      -0.028554704040288925,
      0.03448707237839699,
      -0.06207358092069626,
      -0.03212776035070419,
      -0.02508670650422573,
      -0.013729074038565159,
      -0.0032642839942127466,
      -0.03712184354662895,
      -0.05345146358013153,
      -0.022858045995235443,
      -0.05448509752750397,
      -0.008953094482421875,
      0.039041876792907715,
      0.036627281457185745,
      -0.055758122354745865,
      -0.036152854561805725,
      0.017725815996527672,
      -0.04820264130830765,
      0.023052088916301727,
      -0.04977833479642868,
      0.09303943812847137,
      -0.013786647468805313,
      0.015739504247903824,
      0.02241748757660389,
      0.004554261919111013,
      -0.055688146501779556,
      0.029441507533192635,
      -0.030009757727384567,
      -0.03449789807200432,
      -0.05472322553396225,
      -0.02352573350071907,
      0.031499650329351425,
      0.011649888008832932,
      0.06452199816703796,
      0.05370958894491196,
      -0.019642330706119537,
      0.057955000549554825,
      -0.08191671222448349,
      0.07208716124296188,
      -0.05865670368075371,
      -0.04516296461224556,
      0.05056680738925934,
      -0.017299506813287735,
      0.03145866096019745,
      0.05734631419181824,
      0.008083848282694817,
      0.011307384818792343,
      0.04634127765893936,
      -0.04816826432943344,
      -0.05616734176874161,
      -0.006028344854712486,
      0.042960282415151596,
      -0.06576044112443924,
      -0.003632901469245553,
      0.005033994559198618,
      0.02112356759607792,
      -0.0727478489279747,
      0.006314239464700222,
      -0.018292183056473732,
      0.03618919476866722,
      -0.03596821427345276,
      -0.005705168936401606,
      0.04653967544436455,
      -0.011612579226493835,
      0.05097374692559242,
      -0.06774191558361053,
      0.07360605895519257,
      -0.07424710690975189,
      0.059369757771492004,
      -0.030032753944396973,
      -0.11222446709871292,
      -0.03223521262407303,
      -0.07799781113862991,
      0.0449909046292305,
      -0.0748848095536232,
      -0.0019152810564264655,
      -0.00523867504671216,
      -0.11785385012626648,
      0.016148632392287254,
      -0.02996884658932686,
      -0.05050637945532799,
      0.005547764711081982,
      -0.032305724918842316,
      -0.03203466162085533,
      -0.05112435668706894,
      0.039451759308576584,
      0.05631508305668831,
      0.013612007722258568,
      -0.017344865947961807,
      0.018707484006881714,
      0.015988780185580254,
      -0.02071913704276085,
      0.02236565388739109,
      -0.04260512813925743,
      0.03797377273440361,
      -0.01352848019450903,
      -0.047411661595106125,
      0.04881108179688454,
      0.008693733252584934,
      -0.00594722107052803,
      0.035133764147758484,
      0.04386735334992409,
      0.05791507661342621,
      -0.06770369410514832,
      -0.03312966972589493,
      0.04732678085565567,
      0.03919908031821251,
      -0.014144422486424446,
      0.039600107818841934,
      0.0036195216234773397,
      0.044710949063301086,
      -0.005483732558786869,
      0.03836360573768616,
      -0.05470556393265724,
      0.09349730610847473,
      0.04030955582857132,
      0.018131963908672333,
      0.021440299227833748,
      -0.036658331751823425
    ],
    [
      -0.0019592533353716135,
      0.026963092386722565,
      -0.025513021275401115,
      -0.015012087300419807,
      -0.04137975350022316,
      -0.01881098747253418,
      -0.0015659485943615437,
      -0.011483117938041687,
      0.016072023659944534,
      0.04959317296743393,
      -0.018306659534573555,
      -0.007318810559809208,
      0.09269917756319046,
      -0.0049637011252343655,
      0.053151290863752365,
      0.019929908215999603,
      -0.05266526713967323,
      0.033147841691970825,
      -0.03783974424004555,
      0.05263325199484825,
      0.04230140522122383,
      -0.013767003081738949,
      -0.05053868144750595,
      0.038624633103609085,
      0.06113828346133232,
      -0.08681543171405792,
      0.008857103064656258,
      0.038407571613788605,
      0.03853759914636612,
      0.04962751269340515,
      0.0023417826741933823,
      0.0453132726252079,
      -0.03359466418623924,
      -0.029691167175769806,
      0.1514889895915985,
      -0.036767058074474335,
      -0.02988521009683609,
      0.07636424899101257,
      -0.00599651038646698,
      0.0527120977640152,
      -0.016764795407652855,
      -0.0885281190276146,
      0.010544933378696442,
      -0.034980420023202896,
      0.08378081768751144,
      -0.02045052871108055,
      0.010792925022542477,
      0.0010571739403530955,
      0.0025286769960075617,
      0.11295535415410995,
      0.038918979465961456,
      0.035854700952768326,
      -0.025583868846297264,
      -0.02662949077785015,
      -0.024951178580522537,
      -0.011700620874762535,
      -0.034649308770895004,
      0.008641522377729416,
      -0.04254961758852005,
      0.039826322346925735,
      -0.05806012451648712,
      -0.008234169334173203,
      -0.0004452356952242553,
      -0.06314132362604141,
      0.06011934578418732,
      0.01629292592406273,
      0.014583402313292027,
      -0.0007937364862300456,
      0.0030246449168771505,
      0.04975399374961853,
      -2.917134406743571e-05,
      -0.021179918199777603,
      -0.018613623455166817,
      0.003271399298682809,
      0.03381587192416191,
      -0.05405840277671814,
      -0.013419011607766151,
      -0.011373051442205906,
      -0.013897144235670567,
      0.0500674732029438,
      0.030932631343603134,
      0.03509732708334923,
      0.03873085230588913,
      -0.06679198890924454,
      -0.06086069345474243,
      -0.00604219501838088,
      -0.008210746571421623,
      0.050163209438323975,
      -0.040022701025009155,
      -0.06534869223833084,
      -0.01575007475912571,
      -0.038976334035396576,
      0.037385955452919006,
      0.022223694249987602,
      -0.01052554976195097,
      -0.02851925976574421,
      0.022827668115496635,
      0.039171360433101654,
      -0.029333677142858505,
      0.047548092901706696,
      -0.10721635818481445,
      -0.07336259633302689,
      0.008433112874627113,
      0.04634076729416847,
      -0.028284288942813873,
      -0.02089870162308216,
      0.038273293524980545,
      -0.07538692653179169,
      0.05421846732497215,
      0.02184220962226391,
      0.013137811794877052,
      0.012317169457674026,
      0.019017891958355904,
      -0.0669705867767334,
      0.013810313306748867,
      0.025307003408670425,
      0.02858811803162098,
      0.005756155122071505,
      0.03924178704619408,
      -0.03348135948181152,
      -0.01370861753821373,
      0.032606396824121475,
      -0.08757814019918442,
      0.07592731714248657,
      0.04272840544581413,
      0.023185662925243378,
      -0.03021632321178913,
      -0.09888822585344315,
      -0.0625348761677742,
      -0.032398052513599396,
      -0.05249113589525223,
      0.04679734259843826,
      -0.07609867304563522,
      -0.016696270555257797,
      0.03332846239209175,
      0.0883496031165123,
      0.015339095145463943,
      0.0757770910859108,
      -0.0688323974609375,
      0.019322486594319344,
      0.03295385092496872,
      0.0997629165649414,
      -0.049656473100185394,
      -0.03178872540593147,
      0.009930664673447609,
      0.0244999248534441,
      0.038164034485816956,
      0.0990852415561676,
      -0.06195158138871193,
      0.021831074729561806,
      -0.02347271889448166,
      0.035860102623701096,
      0.028500298038125038,
      -0.0034906244836747646,
      -0.040909770876169205,
      -0.06250183284282684,
      0.027167154476046562,
      0.04260697960853577,
      -0.0396295003592968,
      -0.06658824533224106,
      0.11045881360769272,
      0.05821370333433151,
      0.022385669872164726,
      0.04829571023583412,
      -0.021729204803705215,
      -0.028399085626006126,
      0.03949480876326561,
      0.004698544275015593,
      -0.026847807690501213,
      -0.024679340422153473,
      -0.04307132214307785,
      -0.01139188464730978,
      -0.002893372206017375,
      0.0009616266470402479,
      0.006255312357097864,
      -0.034117285162210464,
      -0.059240974485874176,
      -0.01451264787465334,
      0.027095548808574677,
      0.03566007316112518,
      -0.009131832048296928,
      -0.01929115504026413,
      -0.005610017571598291,
      0.015161179937422276,
      -0.01680845394730568,
      0.036136310547590256,
      0.03999229520559311,
      -0.07088831812143326,
      0.01950518786907196,
      0.049100492149591446,
      -0.017994141206145287,
      -0.02210954949259758,
      -0.010052905417978764,
      0.004249906167387962,
      -0.009235907346010208,
      0.057159408926963806,
      -0.01452608872205019,
      0.03145488351583481,
      0.058851633220911026,
      0.022743050009012222,
      -0.01873694732785225,
      -0.014095109887421131,
      0.02438822202384472,
      -0.003997834865003824,
      -0.008191253058612347,
      0.02640574239194393,
      0.08030270040035248,
      -0.06504799425601959,
      -0.08437486737966537,
      0.04965147376060486,
      0.07281345874071121,
      0.04318365082144737,
      0.05741305276751518,
      -0.0004186600854154676,
      -0.00096753123216331,
      0.02684323489665985,
      0.0006699334480799735,
      0.03229769691824913,
      0.10839876532554626,
      0.07199015468358994,
      0.01356388721615076,
      -0.07473249733448029,
      0.026520049199461937,
      -0.015992721542716026,
      0.11045785993337631,
      0.037150584161281586,
      0.015488916076719761,
      -0.014832585118710995,
      -0.011494848877191544,
      0.04009164869785309,
      0.02844374254345894,
      -0.09804717451334,
      -0.05151176080107689,
      -0.009730488061904907,
      0.005323001183569431,
      0.04175916686654091,
      -0.00970128457993269,
      -0.015017262659966946,
      -0.042557597160339355,
      0.04032522067427635,
      -0.003919445443898439,
      0.03177648037672043,
      -0.05676218494772911,
      0.042130161076784134,
      -0.0016174239572137594,
      0.004189886152744293,
      -0.07385779172182083,
      -0.024988332763314247,
      0.05830810219049454,
      -0.058532390743494034,
      0.02517102099955082,
      0.009814885444939137,
      0.0530821867287159,
      0.006809593178331852,
      0.0319901742041111,
      0.08148057013750076,
      0.12597523629665375,
      0.07775872200727463,
      0.07672690600156784,
      0.018930982798337936,
      -0.03534671291708946,
      -0.07944568991661072,
      0.0011871878523379564,
      -0.11592092365026474,
      -0.008838404901325703,
      0.030651533976197243,
      0.041653599590063095,
      -0.015360071323812008,
      -0.011146890930831432,
      -0.0013584832195192575,
      -0.012182425707578659,
      -0.004962097387760878,
      0.02633383683860302,
      0.04266388341784477,
      0.04006938263773918,
      -0.0727664977312088,
      0.03888992592692375,
      0.07193540781736374,
      0.03831349313259125,
      -0.14725781977176666,
      -0.0023138863034546375,
      -0.009873848408460617,
      -0.06230800971388817,
      -0.07746006548404694,
      -0.01967868208885193,
      0.002177115064114332,
      -0.033047884702682495,
      0.05566386878490448,
      -0.03879501670598984,
      -0.02116251364350319,
      -0.056660689413547516,
      0.0030284058302640915,
      -0.0020904415287077427,
      -0.047867875546216965,
      -0.06274356693029404,
      0.0851597934961319,
      0.03233073651790619,
      -0.042524222284555435,
      0.0018103281036019325,
      -0.029332464560866356,
      -0.03261680528521538,
      0.008464040234684944,
      0.01997208595275879,
      -0.0127497473731637,
      0.07917943596839905,
      -0.1243147924542427,
      0.0739934891462326,
      0.08641201257705688,
      -0.045847535133361816,
      0.01903456076979637,
      0.032064251601696014,
      -0.043586499989032745,
      0.0045369938015937805,
      -0.03963808715343475,
      0.029856866225600243,
      -0.06411527842283249,
      -0.01100816298276186,
      0.024253809824585915,
      0.027079157531261444,
      0.010058584623038769,
      0.0003866377519443631,
      0.08463630825281143,
      -0.0906984806060791,
      -0.028228046372532845,
      -0.07025550305843353,
      0.009459570050239563,
      -0.06412778794765472,
      0.0025113809388130903,
      0.015857571735978127,
      0.06344138085842133,
      -0.0865524560213089,
      -0.010359727777540684,
      0.0024412258062511683,
      0.07355833053588867,
      -0.04730857536196709,
      -0.06886598467826843,
      0.039485957473516464,
      0.05305511876940727,
      0.034544676542282104,
      -0.010435049422085285,
      0.07038890570402145,
      -0.023754751309752464,
      0.009689398109912872,
      -0.023866787552833557,
      -0.01293920911848545,
      -0.051556456834077835,
      0.07946890592575073,
      0.0011183886090293527,
      -0.03644576668739319,
      0.05180520936846733,
      -0.05113455653190613,
      0.006510884966701269,
      -0.014160088263452053,
      -0.026578690856695175,
      0.006698615849018097,
      0.047171808779239655,
      -0.03908766433596611,
      0.06907935440540314,
      -0.025771036744117737,
      0.06196810305118561,
      0.07980683445930481,
      -0.06291148066520691,
      -0.03241609409451485,
      -0.04193856194615364,
      -0.015572968870401382,
      -0.03641054406762123,
      0.03096708282828331,
      0.026680417358875275,
      -0.04318639636039734,
      0.04182175546884537,
      0.0391576811671257,
      -0.035581205040216446,
      0.06573401391506195,
      0.019355954602360725,
      -0.026019828394055367,
      -0.0035273462999612093,
      0.003911751322448254,
      -0.05107460543513298,
      0.011710998602211475,
      0.07317580282688141,
      0.003883389523252845,
      -0.006351928226649761,
      -0.01538006030023098,
      -0.023506011813879013,
      0.0248562041670084,
      -0.022755706682801247,
      0.025933077558875084,
      -0.007177973166108131,
      -0.006907578557729721,
      -0.0647948831319809,
      0.03033745475113392,
      0.05573701485991478,
      0.04441528767347336,
      -0.03694992512464523,
      0.0438072569668293,
      -0.06454756110906601,
      0.019505484029650688,
      -0.014075489714741707,
      -0.06376348435878754,
      -0.05042008310556412,
      -0.024340858682990074,
      0.03872063010931015,
      -0.05600941926240921,
      0.07634080946445465,
      -0.016408566385507584,
      -0.0047880420461297035,
      0.018842564895749092,
      -0.026704153046011925,
      0.011173867620527744,
      0.003966944757848978,
      -0.025770558044314384,
      0.05757081136107445,
      -0.021214567124843597,
      0.022155534476041794,
      0.06251024454832077,
      0.005787545815110207,
      0.01893669180572033,
      -0.02433551661670208,
      -0.028064362704753876,
      -0.01086253672838211,
      -0.023771725594997406,
      0.06767444312572479,
      0.014781603589653969,
      -0.10484316945075989,
      -0.06309954822063446,
      -0.028646614402532578,
      -0.06458084285259247,
      -0.04758843034505844,
      0.0007685190648771822,
      0.018177058547735214,
      0.008828483521938324,
      0.0002792511659208685,
      -0.04736490175127983,
      0.0030205072835087776,
      -0.018018372356891632,
      0.02960284613072872,
      -0.09255415946245193,
      -0.0021331263706088066,
      0.10191865265369415,
      0.0025080228224396706,
      0.026693172752857208,
      -0.07839522510766983,
      0.008571364916861057,
      -0.013116134330630302,
      0.05184239149093628,
      -0.030016262084245682,
      0.03123733401298523,
      -0.022299913689494133,
      0.019167326390743256,
      0.009574053809046745,
      -0.028001688420772552,
      0.04565649479627609,
      0.03372754529118538,
      0.02662613056600094,
      -0.03072144277393818,
      0.010985842905938625,
      0.075521320104599,
      0.054346852004528046,
      -0.05591849610209465,
      -0.02724400907754898,
      0.0674450621008873,
      0.11776750534772873,
      -0.004111975897103548,
      0.0011215112172067165,
      -0.04971292242407799,
      0.040064625442028046,
      -0.06353192776441574,
      0.034081488847732544,
      0.05669916421175003,
      -0.052790574729442596,
      0.005785159766674042,
      0.010135523974895477,
      0.12045755237340927,
      0.052871543914079666,
      -0.000940177240408957,
      -0.01342675182968378,
      -0.028313668444752693,
      -0.06831605732440948,
      0.011112862266600132,
      -0.05006229132413864,
      0.047627221792936325,
      0.02134855091571808,
      0.00041723379399627447,
      -0.027244221419095993,
      0.08050524443387985,
      -0.009780466556549072,
      0.012854466214776039,
      0.057058777660131454,
      -0.027085350826382637,
      0.008825542405247688,
      -0.05912155658006668,
      0.04064464941620827,
      -0.011890055611729622,
      0.0496385432779789,
      0.05028916150331497,
      -0.11779462546110153,
      -0.03794362396001816,
      0.06261300295591354,
      0.03532782569527626,
      -0.01713302731513977,
      0.08665088564157486,
      -0.025270115584135056,
      0.03622933477163315
    ],
    [
      -0.04496174678206444,
      0.08593602478504181,
      0.042574189603328705,
      0.04329845681786537,
      -0.09893902391195297,
      -0.07767321169376373,
      -0.004966384265571833,
      0.06257336586713791,
      -0.027311701327562332,
      -0.026189880445599556,
      0.046077433973550797,
      0.0451953150331974,
      -0.04653589054942131,
      -0.057717595249414444,
      0.10359840095043182,
      -0.07908234000205994,
      0.057416319847106934,
      -0.09793191403150558,
      -0.008453862741589546,
      -0.03511158004403114,
      0.07235115021467209,
      0.0011068637249991298,
      -0.03866466134786606,
      -0.035127196460962296,
      0.011986438184976578,
      -0.11618761718273163,
      -0.03045138530433178,
      -0.0194436963647604,
      -0.030298631638288498,
      -0.041011303663253784,
      -0.003602280979976058,
      -0.06203283742070198,
      -0.024028826504945755,
      -0.025174885988235474,
      -0.0150063531473279,
      -0.024680310860276222,
      0.10352963954210281,
      -0.01804611273109913,
      -0.011535909958183765,
      -0.07306597381830215,
      -0.03899054974317551,
      0.049215517938137054,
      0.05683353170752525,
      -0.05034840479493141,
      -0.01916738785803318,
      0.044630266726017,
      -0.041924186050891876,
      0.050570227205753326,
      0.0027348785661160946,
      -0.047755155712366104,
      0.016316881403326988,
      -0.0327531136572361,
      -0.019200611859560013,
      0.010109988041222095,
      0.06738875806331635,
      -0.0034375255927443504,
      0.0040361457504332066,
      -0.03139348328113556,
      0.022536667063832283,
      0.03475341200828552,
      0.020592471584677696,
      -0.07443186640739441,
      -0.0334964320063591,
      0.01599014736711979,
      0.01890067756175995,
      -0.0007674146327190101,
      -0.04946614056825638,
      -0.07406799495220184,
      0.044867414981126785,
      -0.03629162907600403,
      0.03211390972137451,
      0.0051208375953137875,
      -0.0003729320887941867,
      0.0730649083852768,
      -0.013144689612090588,
      -0.03794180229306221,
      0.015884166583418846,
      0.015597167424857616,
      0.0009889960056170821,
      -0.01752900332212448,
      -0.041280608624219894,
      -0.004530645441263914,
      -0.011915097944438457,
      0.10971443355083466,
      0.009190354496240616,
      -0.026070773601531982,
      -0.020933207124471664,
      0.05686955153942108,
      -0.00932913739234209,
      -0.014450340531766415,
      -0.001573700807057321,
      0.07133802026510239,
      -0.05630432441830635,
      -0.013699089176952839,
      0.04982588067650795,
      0.03705507889389992,
      -0.0064361137337982655,
      -0.02622133679687977,
      0.002765895100310445,
      -0.030813321471214294,
      0.02541120909154415,
      -0.04700242355465889,
      -0.028358198702335358,
      0.0776180699467659,
      -0.04827117174863815,
      0.027855001389980316,
      -0.07941732555627823,
      0.00039319536881521344,
      -0.013279800303280354,
      -0.009250604547560215,
      0.04534555599093437,
      -0.04647161811590195,
      0.02683386765420437,
      -0.02139235846698284,
      -0.012455928139388561,
      -0.02967575192451477,
      -0.07000871747732162,
      0.01804857887327671,
      0.02727425843477249,
      0.03214491903781891,
      0.0774410143494606,
      0.04355339705944061,
      0.01514114998281002,
      0.01469789445400238,
      0.005485591944307089,
      0.006620013155043125,
      -0.10306593030691147,
      -0.07141945511102676,
      -0.160234272480011,
      -0.05043568089604378,
      0.04313283786177635,
      0.004679371602833271,
      0.007338173687458038,
      0.06750711053609848,
      -0.01827848330140114,
      -0.03486510366201401,
      0.0045983013696968555,
      0.061579812318086624,
      -0.005816745106130838,
      0.04728192090988159,
      -0.05497255548834801,
      -0.04442388564348221,
      -0.012184388935565948,
      -0.0045333015732467175,
      -0.020687872543931007,
      -0.055294010788202286,
      0.009184383787214756,
      -0.006043082568794489,
      0.06853179633617401,
      0.05543079227209091,
      -0.01847025565803051,
      -0.0007410210673697293,
      0.09289918839931488,
      -0.007593240588903427,
      -0.009924215264618397,
      -0.03457076847553253,
      0.04955276474356651,
      -0.06580326706171036,
      -0.028580382466316223,
      -0.016597356647253036,
      -0.07515149563550949,
      -0.12037400156259537,
      -0.005875101778656244,
      -0.06316417455673218,
      0.010147837921977043,
      0.04363761097192764,
      0.0027079011779278517,
      -0.0071937041357159615,
      0.02600804530084133,
      -0.009038125164806843,
      -0.05732785537838936,
      -0.05313907563686371,
      -0.011327716521918774,
      -0.005971858743578196,
      0.017111601307988167,
      0.011925783008337021,
      0.039770565927028656,
      0.028322255238890648,
      -0.011619520373642445,
      -0.06397990137338638,
      -0.051337920129299164,
      0.03250240162014961,
      -0.01675754226744175,
      0.07457447797060013,
      0.03610847145318985,
      0.043591514229774475,
      -0.023611679673194885,
      -0.019743328914046288,
      -0.028737977147102356,
      0.04087330400943756,
      -0.04717323184013367,
      0.08217480778694153,
      0.05401558429002762,
      -0.09605462849140167,
      0.05487671121954918,
      0.016927972435951233,
      -0.018595634028315544,
      -0.05362193286418915,
      -0.1515107899904251,
      0.06707172840833664,
      0.0009278844809159636,
      0.02848394215106964,
      0.07323333621025085,
      0.010282575152814388,
      0.06678327172994614,
      -0.01717361807823181,
      0.0834474191069603,
      -0.033212386071681976,
      -0.03907627984881401,
      -0.028645142912864685,
      0.006954261101782322,
      -0.0037172199226915836,
      0.007884463295340538,
      0.020167257636785507,
      0.04022228717803955,
      0.0014571879291906953,
      -0.01296344492584467,
      0.041514210402965546,
      -0.00911363959312439,
      -0.034956254065036774,
      0.038866061717271805,
      -0.0747106522321701,
      -0.005073468200862408,
      0.033134590834379196,
      -0.013557716272771358,
      -0.061985086649656296,
      0.07492566108703613,
      -0.021825551986694336,
      -0.013904223218560219,
      0.04260595887899399,
      0.04245021939277649,
      -0.06267677247524261,
      -0.06729962676763535,
      0.04343174397945404,
      -0.08908706903457642,
      0.04158110171556473,
      0.07504860311746597,
      0.0510566271841526,
      0.016649330034852028,
      0.01454162411391735,
      0.041559651494026184,
      0.011250662617385387,
      0.01684846170246601,
      0.025316962972283363,
      0.011226147413253784,
      -0.07849588245153427,
      0.047349199652671814,
      -0.040337201207876205,
      0.0058616953901946545,
      -0.03744242712855339,
      -0.02972572296857834,
      0.041260022670030594,
      0.052707869559526443,
      -0.039488304406404495,
      -0.07996457815170288,
      0.048331256955862045,
      -0.06294521689414978,
      0.06632345914840698,
      -0.022507963702082634,
      0.033863894641399384,
      -0.12428341805934906,
      -0.04621891677379608,
      -0.05204794928431511,
      0.01991264894604683,
      -0.05165435001254082,
      -0.03748960420489311,
      -0.07155856490135193,
      0.03813399374485016,
      0.06136109307408333,
      0.04007911682128906,
      0.042810097336769104,
      -0.025486821308732033,
      -0.007158038672059774,
      -0.0020233490504324436,
      0.005323753226548433,
      -0.01065718661993742,
      -0.045719631016254425,
      0.012316807173192501,
      0.010304381139576435,
      0.06831217557191849,
      -0.09350347518920898,
      -0.05734742432832718,
      -0.005659482441842556,
      0.07908984273672104,
      0.03637081757187843,
      -0.03524042293429375,
      -0.057076238095760345,
      0.042309749871492386,
      -0.003412797814235091,
      -0.0707012191414833,
      0.011137563735246658,
      -0.033558428287506104,
      -0.04669749736785889,
      -0.04328140988945961,
      -0.027319762855768204,
      0.08905869722366333,
      -0.01051387470215559,
      0.005872026085853577,
      -0.07551097869873047,
      -0.01767403818666935,
      0.017066029831767082,
      0.06922101974487305,
      -0.08393236249685287,
      -0.05617909133434296,
      -0.0063983784057199955,
      0.03974016755819321,
      0.014228261075913906,
      -0.008195352740585804,
      0.04067439213395119,
      -0.0003090761601924896,
      0.06527556478977203,
      -0.028282778337597847,
      -0.0690930038690567,
      0.01388841774314642,
      0.0758080706000328,
      -0.03700033575296402,
      -0.010081213898956776,
      -0.06658891588449478,
      -0.03346824645996094,
      0.06509918719530106,
      -0.035271838307380676,
      -0.0003753213968593627,
      0.0031652473844587803,
      0.007506949827075005,
      0.07736221700906754,
      0.02716219238936901,
      0.049723926931619644,
      -0.09842149168252945,
      -0.020553531125187874,
      0.06538354605436325,
      0.012507387436926365,
      -0.031700946390628815,
      0.05721940100193024,
      -0.0017926498549059033,
      0.0068860710598528385,
      0.019175764173269272,
      0.00031117998878471553,
      0.16767001152038574,
      -0.05628010258078575,
      -0.0034362610895186663,
      -0.03397563844919205,
      -0.01819612830877304,
      0.028789030387997627,
      0.005520366132259369,
      -0.03492382913827896,
      -0.033740609884262085,
      -0.0317947082221508,
      -0.025983233004808426,
      -0.026478873565793037,
      0.07803604006767273,
      -0.0666004866361618,
      0.060200273990631104,
      0.020189031958580017,
      -0.012758458033204079,
      -0.019472718238830566,
      -0.047173772007226944,
      -0.014417417347431183,
      0.08938156068325043,
      -0.09108814597129822,
      -0.0005977972177788615,
      0.06788451224565506,
      -0.01583367958664894,
      -0.04564281180500984,
      0.030676843598484993,
      -0.0009099742746911943,
      -0.009460537694394588,
      -0.05442926287651062,
      -0.07698066532611847,
      -0.06807345151901245,
      -0.010679258964955807,
      -0.08114892244338989,
      -0.05579971894621849,
      -0.027963634580373764,
      0.11680188030004501,
      0.034102801233530045,
      -0.0015256432816386223,
      -0.09136167913675308,
      -0.0791742354631424,
      -0.02034766972064972,
      0.03237446770071983,
      0.02269110642373562,
      -0.03035009652376175,
      -0.043295156210660934,
      -0.008702930063009262,
      -0.0352444052696228,
      0.009830410592257977,
      0.0016345403855666518,
      -0.011214898899197578,
      -0.046758368611335754,
      -0.021187767386436462,
      -0.017140084877610207,
      0.05573437735438347,
      0.06337214261293411,
      -0.07622969895601273,
      0.07928714156150818,
      0.020656196400523186,
      -0.02309258095920086,
      0.08864907920360565,
      0.08848722279071808,
      -0.05629238486289978,
      0.004668628331273794,
      -0.04216999560594559,
      -0.04103744402527809,
      0.01810501702129841,
      0.005723190493881702,
      -0.034225329756736755,
      0.011237974278628826,
      0.05692749470472336,
      -0.0015578976599499583,
      0.017858469858765602,
      -0.08792641013860703,
      0.056963589042425156,
      -0.055382758378982544,
      -0.017676208168268204,
      0.011629289947450161,
      0.03648918494582176,
      -0.005663994234055281,
      0.051818691194057465,
      0.02362976036965847,
      -0.018976790830492973,
      0.0011625078041106462,
      0.08301165699958801,
      -0.025041518732905388,
      -0.043290723115205765,
      0.07960920035839081,
      0.10110560804605484,
      0.020658014342188835,
      0.04633185267448425,
      0.07706945389509201,
      0.036643773317337036,
      -0.1080799251794815,
      0.023953046649694443,
      -0.03038976527750492,
      -0.06265969574451447,
      -0.026964470744132996,
      0.03536759689450264,
      -0.03557242080569267,
      -0.08431165665388107,
      0.04137188568711281,
      -0.07753311097621918,
      0.02307668887078762,
      -0.0628623515367508,
      -0.020674388855695724,
      -0.05254754051566124,
      -0.006304105278104544,
      0.05854148790240288,
      -0.03901470825076103,
      -0.05633394792675972,
      0.03617187216877937,
      -0.029731623828411102,
      0.03343893215060234,
      0.07031941413879395,
      0.04882091283798218,
      7.031415589153767e-05,
      -0.041703443974256516,
      0.0641556978225708,
      -0.08338979631662369,
      0.040077704936265945,
      0.014758813194930553,
      0.014812711626291275,
      -0.026724688708782196,
      0.027324745431542397,
      -0.006963934749364853,
      -0.028456643223762512,
      -0.061478424817323685,
      0.00431036064401269,
      -0.12702526152133942,
      -0.04530812054872513,
      -0.04402656853199005,
      0.05660594627261162,
      -0.034438956528902054,
      -0.00325933494605124,
      -0.04059801623225212,
      -0.008714213036000729,
      -0.024357404559850693,
      0.04365598410367966,
      0.03888024762272835,
      0.0262284055352211,
      0.07536173611879349,
      0.013639997690916061,
      -0.013667982071638107,
      0.011723118834197521,
      0.011520498432219028,
      -0.06669100373983383,
      -0.0624186247587204,
      -0.11913982033729553,
      0.0246852096170187,
      -0.01689557358622551,
      0.06038001924753189,
      0.0115813622251153,
      -0.03728280961513519,
      -0.05448204278945923,
      0.000862960412632674,
      0.04420243576169014,
      -0.018183104693889618,
      0.004173781722784042,
      -0.03335554152727127,
      0.04593163728713989,
      -0.04913073778152466,
      0.09835827350616455,
      0.06474172323942184,
      0.058745186775922775,
      0.05997888743877411
    ],
    [
      0.06778944283723831,
      -0.019627925008535385,
      -0.042154859751462936,
      0.04107644036412239,
      0.010003826580941677,
      0.03578205779194832,
      0.022184550762176514,
      -0.03607552498579025,
      -0.044593922793865204,
      0.012833619490265846,
      -0.05338664352893829,
      -0.0054283770732581615,
      -0.00947889219969511,
      -0.014179161749780178,
      0.03692837059497833,
      0.0050416747108101845,
      -0.012601189315319061,
      -0.024717291817069054,
      0.0348677821457386,
      0.025778869166970253,
      -0.05710742995142937,
      -0.005410661920905113,
      0.0020934066269546747,
      0.0023351069539785385,
      0.010559425689280033,
      0.06064227223396301,
      0.04057136923074722,
      0.06454098969697952,
      -0.02579466439783573,
      -0.04677736386656761,
      -0.01850046031177044,
      0.02825385332107544,
      0.015484942123293877,
      -0.006543021649122238,
      -0.03702215477824211,
      -0.028483711183071136,
      0.00394173339009285,
      -0.011160801164805889,
      -0.0039763906970620155,
      0.007071394007652998,
      0.012001977302134037,
      0.037996478378772736,
      0.05199918895959854,
      -0.06947340071201324,
      -0.07820656150579453,
      -0.0776556059718132,
      0.06799352169036865,
      -0.04010206460952759,
      0.011606023646891117,
      -0.015054725110530853,
      -0.0009227564441971481,
      0.007428111974149942,
      0.04524848982691765,
      0.03853956609964371,
      -0.01619800738990307,
      -0.004587825387716293,
      -0.01209021732211113,
      -0.04581524059176445,
      0.031576283276081085,
      0.011072639375925064,
      -0.01811446063220501,
      -0.06234196946024895,
      0.009624365717172623,
      -0.03215065225958824,
      -0.03598351776599884,
      -0.03766094520688057,
      -0.03919938579201698,
      0.05014394596219063,
      -0.014979497529566288,
      0.04099314659833908,
      0.09022634476423264,
      0.019637158140540123,
      -0.056268371641635895,
      0.13467562198638916,
      -0.06405889987945557,
      0.01425861194729805,
      -0.029995987191796303,
      0.04153434932231903,
      0.049907103180885315,
      -0.01011671032756567,
      0.02479768544435501,
      -0.06736193597316742,
      0.07468778640031815,
      0.016783202067017555,
      0.017344482243061066,
      -0.04560653120279312,
      0.11243227124214172,
      -0.0528433658182621,
      0.04947671666741371,
      0.00829987321048975,
      0.005001748912036419,
      -0.012291896156966686,
      0.029299212619662285,
      -0.013913102447986603,
      0.03778255358338356,
      0.020029399544000626,
      0.05698114261031151,
      -0.06418010592460632,
      -0.003896334208548069,
      0.01939685083925724,
      0.01612834818661213,
      0.02755013294517994,
      0.03741651028394699,
      0.0028584685642272234,
      -0.006540041416883469,
      -0.003071908140555024,
      -0.03732072934508324,
      0.001255410723388195,
      -0.0012673187302425504,
      0.027037331834435463,
      -0.023709600791335106,
      -0.07772903889417648,
      -0.09158629179000854,
      -0.10711709409952164,
      -0.118876151740551,
      0.05488967522978783,
      0.08515763282775879,
      0.01730814203619957,
      0.017435139045119286,
      -0.003653694177046418,
      -0.0008834070758894086,
      -0.04333064332604408,
      -0.027412373572587967,
      -0.014371130615472794,
      -0.03403911739587784,
      0.005261077545583248,
      0.04490997642278671,
      -0.02118760533630848,
      0.04114944860339165,
      0.016343459486961365,
      -0.05174117907881737,
      0.034757357090711594,
      -0.020801354199647903,
      -0.05926967039704323,
      -0.016794344410300255,
      0.0926283746957779,
      0.04504215344786644,
      0.0069752964191138744,
      -0.08552572131156921,
      0.0033603720366954803,
      -0.014029499143362045,
      -0.0022398773580789566,
      0.0435994528234005,
      0.004074620082974434,
      0.022876666858792305,
      0.02082882821559906,
      -0.032517798244953156,
      0.010139002464711666,
      -0.04633759334683418,
      -0.03935529664158821,
      0.009494811296463013,
      0.07391634583473206,
      0.007496523205190897,
      0.02403998002409935,
      0.03308757022023201,
      -0.023700525984168053,
      -0.03483705595135689,
      0.036560118198394775,
      -0.017707496881484985,
      0.051508765667676926,
      -0.07084871083498001,
      0.06143764406442642,
      0.05653497576713562,
      0.055996473878622055,
      -0.015127372927963734,
      -0.009444093331694603,
      -0.0026818066835403442,
      -0.08313193917274475,
      0.029600948095321655,
      0.0791563093662262,
      0.03163103014230728,
      -0.014178266748785973,
      -0.04510371759533882,
      0.016529887914657593,
      0.01622861437499523,
      0.023404642939567566,
      0.06706038862466812,
      -0.03381875157356262,
      0.023766355589032173,
      -0.11977743357419968,
      -0.03314684331417084,
      0.013937095180153847,
      -0.0017088355962187052,
      -0.060556426644325256,
      0.03393212705850601,
      0.060558825731277466,
      -0.02270001359283924,
      -0.06389588862657547,
      0.039850905537605286,
      0.03989575058221817,
      0.009559093043208122,
      0.08722502738237381,
      0.00496659753844142,
      0.010413031093776226,
      0.008890352211892605,
      0.004167729523032904,
      0.01176539808511734,
      0.0009546182118356228,
      -0.009565888904035091,
      0.04167108237743378,
      -0.1007421687245369,
      0.050515707582235336,
      0.0008296482847072184,
      -0.053223755210638046,
      0.019595814868807793,
      0.04330398142337799,
      0.000812120211776346,
      -0.0960395410656929,
      0.033983469009399414,
      0.04510683938860893,
      -0.01716943457722664,
      -0.014395812526345253,
      -0.03656668961048126,
      -0.023150816559791565,
      0.02342204749584198,
      0.00530588161200285,
      -0.0122757563367486,
      -0.021323563531041145,
      -0.0036040456034243107,
      -0.032201461493968964,
      0.061925288289785385,
      0.011745761148631573,
      -0.0022986887488514185,
      0.023175710812211037,
      -0.03836660832166672,
      0.00042277300963178277,
      0.04837273061275482,
      -0.0009493149700574577,
      0.020151352509856224,
      0.027517663314938545,
      0.051429376006126404,
      -0.017129316926002502,
      0.04738849774003029,
      -0.02552196756005287,
      -0.007624268066138029,
      -0.008009226992726326,
      0.015601643361151218,
      -0.034962061792612076,
      -0.01658964902162552,
      -0.041333358734846115,
      -0.023921268060803413,
      0.005168738774955273,
      0.031146788969635963,
      -0.017698174342513084,
      0.01763882488012314,
      0.030940240249037743,
      0.011327218264341354,
      -0.015385894104838371,
      0.015095756389200687,
      -0.027699537575244904,
      -0.08372781425714493,
      0.061031896620988846,
      0.002413251670077443,
      -0.019672805443406105,
      -0.04278142750263214,
      0.02558143250644207,
      -0.05779546499252319,
      0.12181276082992554,
      0.012901164591312408,
      0.03384367749094963,
      -0.01524455938488245,
      0.0471220538020134,
      -0.016569778323173523,
      0.02370130643248558,
      -0.05098673701286316,
      -0.010694851167500019,
      -0.04965812340378761,
      -0.05261612311005592,
      -0.0036776175256818533,
      -0.0337635837495327,
      -0.06636299937963486,
      -0.012608516030013561,
      0.06557829678058624,
      -0.04175667464733124,
      0.018406180664896965,
      -0.11117777973413467,
      -0.008996834047138691,
      0.006896776147186756,
      0.018517842516303062,
      -0.09222833812236786,
      -0.05006485432386398,
      0.010366275906562805,
      -0.05444376915693283,
      -0.0656106099486351,
      0.008140252903103828,
      0.061773840337991714,
      -0.03910750895738602,
      0.0408194325864315,
      0.0014097242383286357,
      -0.07814260572195053,
      -0.10659484565258026,
      0.013439604081213474,
      -0.029394017532467842,
      0.05892765149474144,
      0.027889810502529144,
      0.015574609860777855,
      0.03611725568771362,
      0.03241923823952675,
      -0.0362703911960125,
      0.02982258051633835,
      -0.040689267218112946,
      -0.0699157789349556,
      0.006407295819371939,
      -0.02106213942170143,
      0.034232236444950104,
      -0.06689116358757019,
      0.006608078721910715,
      -0.030628155916929245,
      0.015043538063764572,
      0.06897588074207306,
      -0.02635415457189083,
      -0.04877191781997681,
      -0.029122382402420044,
      -0.039077140390872955,
      0.03612378612160683,
      -0.027933189645409584,
      0.04274868965148926,
      -0.0504269152879715,
      0.09480520337820053,
      -0.0036597971338778734,
      -0.014299835078418255,
      0.0038919502403587103,
      -0.0837477371096611,
      0.021108947694301605,
      -0.023921171203255653,
      -0.016633808612823486,
      -0.048339325934648514,
      0.05675136297941208,
      0.05376765504479408,
      0.11294223368167877,
      -0.0108261127024889,
      0.04426147788763046,
      -0.023134833201766014,
      -0.03968318924307823,
      -0.11250179260969162,
      0.02141435816884041,
      -0.01891074702143669,
      0.04610738158226013,
      0.024480998516082764,
      0.0010260868584737182,
      0.006952867843210697,
      -0.02667902037501335,
      0.0018696869956329465,
      0.0031136798206716776,
      -0.022425945848226547,
      -0.10579141229391098,
      0.03203893452882767,
      0.019345253705978394,
      -0.041972171515226364,
      0.10599282383918762,
      -0.022115755826234818,
      0.008273431099951267,
      -0.09609629958868027,
      -0.06891074776649475,
      0.048359259963035583,
      -0.030007226392626762,
      -0.02163819782435894,
      0.04306144267320633,
      -0.03263600915670395,
      -0.0258158128708601,
      0.014247247017920017,
      -0.030424246564507484,
      -0.03512398898601532,
      0.009927911683917046,
      -0.0036926809698343277,
      0.01530837919563055,
      -0.13558445870876312,
      0.05073954910039902,
      -0.0071985055692493916,
      0.013963130302727222,
      0.0612170472741127,
      0.06316956132650375,
      -0.021563878282904625,
      0.08323724567890167,
      0.011370680294930935,
      0.027594469487667084,
      0.02186322584748268,
      -0.06140189990401268,
      -0.03177248686552048,
      -0.002608491340652108,
      0.027315519750118256,
      0.006771132815629244,
      -0.014250625856220722,
      0.07111503928899765,
      0.04055982083082199,
      -0.07670627534389496,
      0.0051091816276311874,
      0.07009618729352951,
      0.06541027128696442,
      0.019489701837301254,
      -0.10849831998348236,
      0.09642545133829117,
      -0.012340264394879341,
      -0.05811671167612076,
      0.010507112368941307,
      0.06033271923661232,
      -0.04768761247396469,
      0.038119636476039886,
      -0.03372175619006157,
      -0.04104539752006531,
      0.016101129353046417,
      0.03053261525928974,
      -0.07092396169900894,
      -0.04642491787672043,
      -0.0033114266116172075,
      -0.07772142440080643,
      -0.06305691599845886,
      -0.061539992690086365,
      0.027996011078357697,
      0.06814777851104736,
      0.014350173063576221,
      -0.030025191605091095,
      -0.0049593690782785416,
      0.08815105259418488,
      -0.026238150894641876,
      0.05173000693321228,
      -0.022408368065953255,
      0.02783164195716381,
      -0.0008645085617899895,
      -0.08615124970674515,
      -0.05213189870119095,
      0.014699496328830719,
      -0.023981602862477303,
      0.024515710771083832,
      0.060078028589487076,
      0.017005715519189835,
      -0.016902050003409386,
      -0.038075920194387436,
      0.05138680338859558,
      -0.02834698185324669,
      0.0607629269361496,
      0.04423810541629791,
      0.05497816950082779,
      -0.017161237075924873,
      0.07742179930210114,
      0.03201330453157425,
      0.022462017834186554,
      0.040441978722810745,
      0.08231762051582336,
      -0.009439561516046524,
      0.04472799599170685,
      -0.0005053884233348072,
      -0.014749572612345219,
      0.04397077485918999,
      0.014051148667931557,
      -0.05443183705210686,
      -0.00548998499289155,
      0.017779601737856865,
      -0.007563784718513489,
      -0.03647417575120926,
      -0.06116655468940735,
      -0.04801453277468681,
      -0.05210045725107193,
      -0.021562645211815834,
      -0.13979579508304596,
      0.0413789339363575,
      0.06763661652803421,
      0.04259553924202919,
      0.03341343253850937,
      0.0312290471047163,
      -0.02617277018725872,
      -0.06054487079381943,
      0.0318094827234745,
      -0.028408369049429893,
      0.015408082865178585,
      -0.025416167452931404,
      -0.01622115634381771,
      -0.03477269411087036,
      0.0507042333483696,
      0.013541428372263908,
      0.07576509565114975,
      -0.06343674659729004,
      0.022870266810059547,
      -0.03351056948304176,
      -0.022085612639784813,
      -0.05097249895334244,
      -0.0374259389936924,
      -0.05847574770450592,
      -0.03605242818593979,
      0.0005672588595189154,
      0.017973529174923897,
      -0.03797150030732155,
      0.04616136848926544,
      0.12165945768356323,
      -0.03635786101222038,
      -0.042027510702610016,
      0.0024163255002349615,
      0.06304708868265152,
      0.020241187885403633,
      0.0690130740404129,
      -0.025225285440683365,
      -0.018868589773774147,
      0.0018326662248000503,
      -0.05656129866838455,
      0.0016566229751333594,
      0.020952792838215828,
      0.010370827279984951,
      -0.013562547974288464,
      0.018698733299970627,
      0.06763213872909546,
      -0.03632224723696709,
      0.04243072122335434,
      0.000755464076064527
    ],
    [
      0.032400473952293396,
      0.01162074226886034,
      -0.012360069900751114,
      0.09239667654037476,
      0.06289874762296677,
      -0.025651630014181137,
      0.02071075700223446,
      0.019877048209309578,
      -0.023449014872312546,
      -0.09334257990121841,
      -0.019569555297493935,
      0.0027613267302513123,
      -0.03325480967760086,
      -0.001258531236089766,
      -0.06462603062391281,
      -0.02885793149471283,
      -0.03030889667570591,
      0.08440200984477997,
      0.044749610126018524,
      0.07945757359266281,
      -0.0472729355096817,
      -0.013804318383336067,
      -0.01623663492500782,
      -0.03471465781331062,
      -0.003411952406167984,
      -0.03730562701821327,
      -0.017466723918914795,
      -0.0028050721157342196,
      0.03964018076658249,
      0.00812313612550497,
      -0.05412957817316055,
      -0.012382135726511478,
      -0.03859163075685501,
      -0.037768058478832245,
      0.06386566162109375,
      -0.006740426644682884,
      0.07813388854265213,
      -0.061297278851270676,
      0.02531830221414566,
      0.037454716861248016,
      -0.021699311211705208,
      -0.032844819128513336,
      0.03513496741652489,
      0.02396892011165619,
      0.02631366066634655,
      -0.08305598050355911,
      0.010387882590293884,
      0.02564656361937523,
      0.015925278887152672,
      0.016815809532999992,
      0.01876411773264408,
      0.04930566996335983,
      -0.008482038043439388,
      -0.05930565297603607,
      0.07994142919778824,
      0.024425223469734192,
      0.007039931137114763,
      0.017637433484196663,
      0.013578381389379501,
      -0.04570552706718445,
      -0.12392013520002365,
      -0.06537443399429321,
      0.0089035639539361,
      0.017562136054039,
      -0.040108680725097656,
      -0.011399989016354084,
      -0.05284831300377846,
      -0.005787570495158434,
      0.0005004376289434731,
      0.010394461452960968,
      -0.014242501929402351,
      -0.04327716305851936,
      0.0054689995013177395,
      0.022691676393151283,
      -0.01177399605512619,
      0.03617803752422333,
      -0.029043246060609818,
      0.05070224031805992,
      -0.03633742779493332,
      0.030701322481036186,
      0.021117230877280235,
      -0.11610719561576843,
      -0.0576748326420784,
      0.024798300117254257,
      0.00819463562220335,
      0.02403166890144348,
      -0.006231117993593216,
      0.032625097781419754,
      -0.061823293566703796,
      -0.01761510968208313,
      0.006693017669022083,
      0.060730915516614914,
      -0.009631154127418995,
      -0.028312524780631065,
      0.08012636750936508,
      -0.09753227978944778,
      -0.014427353627979755,
      0.011851909570395947,
      0.0072432165034115314,
      -0.007570126559585333,
      0.006379134953022003,
      0.0027322915848344564,
      -0.03657574579119682,
      -0.0010819811141118407,
      -0.030868005007505417,
      0.06469511985778809,
      -0.014914308674633503,
      0.02066430076956749,
      0.09051956981420517,
      0.08709783852100372,
      -0.004258880391716957,
      -0.03876643627882004,
      0.06292009353637695,
      0.01596684381365776,
      0.04007401689887047,
      0.06661739200353622,
      0.02605135180056095,
      -0.05353018268942833,
      -0.08536787331104279,
      0.018992314115166664,
      -0.010625367052853107,
      -0.012358931824564934,
      -0.060536280274391174,
      -0.012802435085177422,
      0.003880572970956564,
      -0.06289038807153702,
      -0.007737760432064533,
      -0.0029179619159549475,
      0.02190932258963585,
      0.025599217042326927,
      0.005602002609521151,
      -0.013229512609541416,
      -0.011112087406218052,
      -0.00021293079771567136,
      -0.017592662945389748,
      -0.10365544259548187,
      -0.051539115607738495,
      0.0013383431360125542,
      -0.046675924211740494,
      0.03461560979485512,
      -0.004202238749712706,
      0.048426996916532516,
      0.057387515902519226,
      0.007232701405882835,
      0.01885564625263214,
      0.04307685047388077,
      -0.006856520194560289,
      -0.0451175682246685,
      -0.004458565264940262,
      0.05930048227310181,
      0.005014283582568169,
      -0.029740214347839355,
      0.09508568048477173,
      -0.0095957787707448,
      0.06553805619478226,
      -0.02911871299147606,
      -0.018370937556028366,
      -0.047472719103097916,
      -0.02983194962143898,
      -0.04690169543027878,
      0.03161745145916939,
      -0.023075373843312263,
      -0.016332240775227547,
      0.028469383716583252,
      0.028767183423042297,
      -0.009727968834340572,
      -0.02373717725276947,
      0.011001411825418472,
      -0.0361960269510746,
      0.0649716705083847,
      0.004510500468313694,
      0.049919892102479935,
      0.06306774914264679,
      -0.047646600753068924,
      0.006700554396957159,
      0.06857214868068695,
      -0.018375203013420105,
      0.09246844798326492,
      0.049038808792829514,
      0.06389777362346649,
      0.008750654757022858,
      0.02723734639585018,
      0.0521768294274807,
      -0.03717406466603279,
      -0.047962289303541183,
      -0.07715226709842682,
      0.03732898086309433,
      0.019164206460118294,
      0.025549383834004402,
      0.030469674617052078,
      0.05086834356188774,
      -0.02783583477139473,
      -0.01148831844329834,
      -0.01382896676659584,
      0.016844503581523895,
      -0.03659046068787575,
      0.05345107987523079,
      -0.06121114641427994,
      -0.07400386780500412,
      0.05132358893752098,
      -0.05761083960533142,
      -0.06873269379138947,
      0.043279096484184265,
      0.09917110949754715,
      0.014134389348328114,
      0.04023114964365959,
      -0.031999316066503525,
      -0.010091645643115044,
      0.04906819388270378,
      -0.010217837058007717,
      -0.03318944200873375,
      -0.0008092814241535962,
      0.06462971866130829,
      -0.008135274052619934,
      -0.022181978449225426,
      0.02950848639011383,
      0.016724390909075737,
      0.007791879586875439,
      -0.03782499209046364,
      -0.05210443586111069,
      0.055540118366479874,
      0.002925144275650382,
      0.040635984390974045,
      0.04929604381322861,
      0.01936010830104351,
      0.009004669263958931,
      0.011508376337587833,
      -0.04868673160672188,
      0.02144143544137478,
      0.024909401312470436,
      0.020276976749300957,
      -0.049918096512556076,
      -0.006373793352395296,
      0.06646625697612762,
      -0.015696734189987183,
      0.00473187118768692,
      -0.028030183166265488,
      -0.003622503485530615,
      -0.06573028862476349,
      0.005504509899765253,
      -0.02620566636323929,
      0.04622379317879677,
      -0.055590707808732986,
      0.0492791086435318,
      0.008515618741512299,
      0.028810596093535423,
      -0.05791759863495827,
      -0.10300223529338837,
      0.037628717720508575,
      -0.03230862691998482,
      0.01099744439125061,
      0.017949873581528664,
      0.006455417722463608,
      -0.0004021467175334692,
      0.010141195729374886,
      -0.036690834909677505,
      0.09796710312366486,
      0.009244358167052269,
      -0.06717804819345474,
      0.007736152969300747,
      0.013552424497902393,
      0.008282531052827835,
      0.05337042734026909,
      0.008446000516414642,
      0.044490329921245575,
      0.01683562994003296,
      0.05012565106153488,
      0.05653795227408409,
      0.04521404951810837,
      0.043886974453926086,
      -0.002014396945014596,
      -0.07713451981544495,
      -0.025629449635744095,
      0.04725942388176918,
      0.01932787150144577,
      -0.01574043184518814,
      0.07012882083654404,
      -0.035590119659900665,
      0.06479169428348541,
      -0.06280007213354111,
      -0.009544651955366135,
      -0.055223166942596436,
      -0.015842262655496597,
      -0.01918845996260643,
      0.04987630248069763,
      -0.060647375881671906,
      0.06300853937864304,
      0.003099187044426799,
      -0.04999101534485817,
      -0.08769456297159195,
      -0.06460996717214584,
      -0.04400679096579552,
      0.014422934502363205,
      0.045560531318187714,
      -0.03876101225614548,
      -0.009531364776194096,
      0.0343078188598156,
      0.0019649479072541,
      0.03703784570097923,
      0.016075195744633675,
      0.06472837924957275,
      0.0025156987830996513,
      0.06806415319442749,
      0.008295204490423203,
      0.0440501868724823,
      -0.057724155485630035,
      0.046498771756887436,
      -0.011965420097112656,
      -0.01831742748618126,
      -0.010644391179084778,
      0.07397875189781189,
      0.01903911679983139,
      0.10030318796634674,
      0.028604235500097275,
      0.04641605541110039,
      0.05685475468635559,
      0.027430016547441483,
      -0.021037042140960693,
      0.0015034571988508105,
      -0.10922618210315704,
      -0.030891157686710358,
      0.12213338911533356,
      -0.08189303427934647,
      0.03570694476366043,
      -0.030874183401465416,
      -0.06271906942129135,
      -0.004672628361731768,
      0.020731652155518532,
      0.0113142728805542,
      0.039111070334911346,
      0.041275136172771454,
      -0.011727269738912582,
      -0.0053693633526563644,
      0.025671694427728653,
      0.0030799531377851963,
      -0.017400864511728287,
      -0.04402411729097366,
      0.011897699907422066,
      -0.06588511168956757,
      -0.0861496776342392,
      0.049940187484025955,
      0.03460613638162613,
      0.0545014850795269,
      -0.07220279425382614,
      -0.08465665578842163,
      0.023749645799398422,
      0.09708821028470993,
      -0.03865895792841911,
      -0.04435718059539795,
      -0.004670244175940752,
      0.02678823284804821,
      -0.014356096275150776,
      -0.0695299357175827,
      -0.024591725319623947,
      0.04944073036313057,
      -0.032816119492053986,
      -0.04224144294857979,
      -0.010300662368535995,
      0.01322624459862709,
      0.037689272314310074,
      -0.05307196453213692,
      -0.020849885419011116,
      -0.05342647805809975,
      -0.06832868605852127,
      0.062391497194767,
      0.039191871881484985,
      -0.01334343384951353,
      -0.039814673364162445,
      0.012551496736705303,
      -0.033769041299819946,
      0.039763834327459335,
      0.008269419893622398,
      0.07659142464399338,
      -0.002729381900280714,
      -0.012467129155993462,
      -0.009470959194004536,
      0.03797977790236473,
      -0.03216638043522835,
      -0.031021125614643097,
      0.046123482286930084,
      0.035634059458971024,
      -0.017231876030564308,
      0.017432885244488716,
      0.01777377538383007,
      0.0003203378582838923,
      0.01936209388077259,
      -0.07015559822320938,
      0.03537064418196678,
      -0.0023225476033985615,
      -0.06361470371484756,
      -0.08107063174247742,
      0.03975174203515053,
      0.044969506561756134,
      0.025833815336227417,
      -0.000806388386990875,
      -0.061666663736104965,
      -0.007212125696241856,
      0.03573451563715935,
      0.03633081167936325,
      -0.1468273550271988,
      0.0490485280752182,
      -0.04634491354227066,
      0.0009957988513633609,
      0.04815281927585602,
      0.027421463280916214,
      0.06642664223909378,
      -0.022542649880051613,
      0.06403975188732147,
      0.022276587784290314,
      -0.02612369693815708,
      0.023784928023815155,
      0.08239660412073135,
      0.024403652176260948,
      0.057115450501441956,
      -0.0022129458375275135,
      -0.02475913055241108,
      0.032499462366104126,
      0.08238480985164642,
      -0.04553544148802757,
      0.02198435179889202,
      0.0019884284120053053,
      0.013537010177969933,
      -0.058446004986763,
      0.09188763052225113,
      0.0016823619371280074,
      0.10933078080415726,
      0.03378792852163315,
      0.01432552095502615,
      0.07624989748001099,
      0.007348914630711079,
      0.011769545264542103,
      0.09921929240226746,
      -0.05015655606985092,
      0.0006136852316558361,
      -0.019397620111703873,
      0.038494814187288284,
      -0.039640046656131744,
      0.05396654084324837,
      0.027893608435988426,
      -0.05802474543452263,
      -0.09206845611333847,
      -0.01949825882911682,
      0.0404803492128849,
      0.03048834204673767,
      0.043615587055683136,
      0.05111968517303467,
      0.03280649334192276,
      -0.08648109436035156,
      -0.03798118606209755,
      0.046764075756073,
      0.02344176173210144,
      0.005884996149688959,
      0.0608711801469326,
      0.017185423523187637,
      -0.07362426072359085,
      0.07749252766370773,
      -0.08037678152322769,
      -0.04798615351319313,
      -0.03311527147889137,
      -0.01021919772028923,
      0.04390670731663704,
      0.07945214956998825,
      0.09010513871908188,
      -0.052096281200647354,
      0.02640773169696331,
      -0.06468050926923752,
      -0.06113453581929207,
      0.008126337081193924,
      0.05580505356192589,
      -0.06898183375597,
      -0.0531451590359211,
      0.008276800625026226,
      0.020681820809841156,
      -0.017473122105002403,
      -0.0069842892698943615,
      0.030170561745762825,
      0.08935938030481339,
      -0.016088366508483887,
      -0.0001233175426023081,
      -0.025139816105365753,
      0.0227012038230896,
      0.04921828210353851,
      -0.016371270641684532,
      0.04758377745747566,
      0.10868596285581589,
      -0.09009160846471786,
      0.018065545707941055,
      -0.037499625235795975,
      0.014420030638575554,
      0.05490535870194435,
      0.02519662119448185,
      0.04687058553099632,
      -0.04988617077469826,
      0.015478063374757767,
      0.00752736022695899,
      -0.01566217467188835,
      0.0014107432216405869,
      -0.007174331694841385,
      -0.043651033192873,
      -0.022965969517827034,
      0.11203145980834961,
      0.06097431108355522,
      0.06756272166967392
    ],
    [
      0.011630278080701828,
      0.05654457211494446,
      -0.03059176169335842,
      -0.012247506529092789,
      -0.029584866017103195,
      -0.07870154082775116,
      0.04855038598179817,
      0.048555899411439896,
      0.04682501032948494,
      -0.08111117035150528,
      -0.00014544081932399422,
      -0.04069599136710167,
      0.050903912633657455,
      0.02296827733516693,
      -0.001965572126209736,
      -0.01966182142496109,
      -0.007217010948807001,
      -0.09814874082803726,
      -0.015959283336997032,
      0.007948808372020721,
      0.041287846863269806,
      -0.005630319472402334,
      0.005927793215960264,
      -0.07038476318120956,
      0.046886689960956573,
      -0.11705439537763596,
      -0.00790878664702177,
      0.06798494607210159,
      0.047344550490379333,
      -0.04156634584069252,
      -0.014644098468124866,
      0.039925601333379745,
      -0.03461245074868202,
      -0.03386782482266426,
      0.07422374933958054,
      -0.010726597160100937,
      -0.025100765749812126,
      -0.0038108504377305508,
      0.04515942931175232,
      -0.03921303153038025,
      -0.04511908441781998,
      0.04478353261947632,
      0.05519910901784897,
      -0.009488309733569622,
      -0.001981349429115653,
      -4.9546411901246756e-05,
      0.005880823824554682,
      0.013299510814249516,
      -0.0022514297161251307,
      -0.010922420769929886,
      -0.015098937787115574,
      0.06263050436973572,
      0.0631202980875969,
      0.060175083577632904,
      0.021252606064081192,
      -0.06318581849336624,
      0.04459620639681816,
      0.07074141502380371,
      0.04806678369641304,
      0.0726773664355278,
      0.0362059623003006,
      -0.05322445556521416,
      0.003944890107959509,
      -0.06017596274614334,
      -0.04380984604358673,
      0.016361968591809273,
      -0.04007362946867943,
      -0.048598453402519226,
      -0.057494424283504486,
      0.0546976663172245,
      0.04691658914089203,
      -0.03884732723236084,
      0.01005830243229866,
      0.05600356683135033,
      0.06742961704730988,
      0.00313888443633914,
      -0.025129584595561028,
      -0.010541372932493687,
      -0.06634236127138138,
      0.004198840819299221,
      0.01242529135197401,
      -0.03242645040154457,
      0.02849709428846836,
      -0.011550157330930233,
      -0.022830242291092873,
      -0.03427337482571602,
      0.003269122214987874,
      0.03644455224275589,
      -0.026616988703608513,
      -0.06469517201185226,
      -0.03587515652179718,
      0.014793479815125465,
      -0.024582143872976303,
      -0.0009992443956434727,
      0.05878341197967529,
      -0.008746865205466747,
      0.004744561389088631,
      -0.010725903324782848,
      0.049662910401821136,
      0.08243689686059952,
      -0.06855808943510056,
      -0.05803254619240761,
      0.01862797513604164,
      -0.02551976777613163,
      0.004132638685405254,
      0.03572361171245575,
      0.00627268897369504,
      0.018657155334949493,
      -0.07093117386102676,
      -0.029375575482845306,
      -0.030929118394851685,
      0.005238060373812914,
      0.0022580542135983706,
      -0.029812240973114967,
      -0.001354128122329712,
      0.03501599282026291,
      -0.05669129639863968,
      -0.035403061658144,
      0.051799118518829346,
      0.0073364051058888435,
      -0.008832830935716629,
      0.09446127712726593,
      -0.02873811684548855,
      -0.033184777945280075,
      0.023365851491689682,
      0.04847811535000801,
      0.038540422916412354,
      -0.08917218446731567,
      -0.07217668741941452,
      0.02998322807252407,
      0.050831712782382965,
      0.02466156706213951,
      0.018204184249043465,
      0.017470000311732292,
      0.003321490017697215,
      -0.031491219997406006,
      0.10716985166072845,
      0.07407435029745102,
      -0.03448661044239998,
      -0.02765570767223835,
      0.07172124832868576,
      -0.009671678766608238,
      0.06491844356060028,
      0.003456205129623413,
      0.02614310011267662,
      0.041913244873285294,
      -0.017955459654331207,
      -0.029454456642270088,
      -0.08448892831802368,
      -0.04485071450471878,
      0.01527397334575653,
      0.0678742304444313,
      0.05591860041022301,
      -0.05903257057070732,
      0.01806623488664627,
      -0.0013674854999408126,
      0.0370800606906414,
      -0.034070614725351334,
      0.01895371824502945,
      -0.08201854676008224,
      -0.010384729132056236,
      0.016206542029976845,
      0.010329197160899639,
      -0.03113480843603611,
      -0.01063148956745863,
      0.07077255845069885,
      0.029449939727783203,
      0.010627315379679203,
      -0.057438433170318604,
      -0.04860059171915054,
      -0.022236596792936325,
      0.026407698169350624,
      -0.019375663250684738,
      -0.030898991972208023,
      -0.01736866682767868,
      -0.013527348637580872,
      -0.03315616026520729,
      0.00139576755464077,
      0.08673761039972305,
      -0.0045203822664916515,
      -0.03127852827310562,
      0.018490901216864586,
      0.07970934361219406,
      -0.06564505398273468,
      0.032892998307943344,
      0.05430487170815468,
      -0.032095350325107574,
      0.1309361755847931,
      -0.012975843623280525,
      -0.02476726472377777,
      0.017956452444195747,
      -0.11507768929004669,
      -0.047790102660655975,
      -0.0714055746793747,
      -0.030993031337857246,
      0.01101179700344801,
      -0.11024410277605057,
      0.020724978297948837,
      -0.04717256873846054,
      0.029979711398482323,
      0.02735041454434395,
      -0.02382798120379448,
      -0.0450730137526989,
      -0.05686550214886665,
      -0.029175322502851486,
      0.028418682515621185,
      0.13219721615314484,
      0.006204130128026009,
      -0.010918674059212208,
      0.024070970714092255,
      -0.07377822697162628,
      -0.023907819762825966,
      -0.022478675469756126,
      -0.04161694273352623,
      0.08712046593427658,
      -0.006499236449599266,
      0.01679248921573162,
      0.07799502462148666,
      0.07026577740907669,
      0.007541447877883911,
      -0.005060451105237007,
      -0.041402217000722885,
      -0.005103019997477531,
      0.026853160932660103,
      -0.026051854714751244,
      0.00933145359158516,
      0.020159462466835976,
      -0.020275412127375603,
      -0.03201630711555481,
      -0.00624872138723731,
      0.051709748804569244,
      0.02454845979809761,
      0.021637240424752235,
      0.005391613580286503,
      -0.008557025343179703,
      -0.04466964676976204,
      -0.020971080288290977,
      -0.06375584006309509,
      -0.05592386797070503,
      -0.004703857004642487,
      -0.04941145330667496,
      0.03539585322141647,
      -0.027795061469078064,
      -0.0452142059803009,
      -0.016037937253713608,
      -0.09911444783210754,
      0.016293257474899292,
      -0.006666756700724363,
      0.0010013266000896692,
      0.06058105453848839,
      -0.008616458624601364,
      -0.005752787925302982,
      0.003657012712210417,
      -0.00319080357439816,
      -0.07289700210094452,
      0.059630099684000015,
      -0.008030904456973076,
      0.039125967770814896,
      -0.013501269742846489,
      0.04191264137625694,
      0.08246093988418579,
      -0.025099411606788635,
      0.054217010736465454,
      -0.017818531021475792,
      0.05878452584147453,
      -0.020448852330446243,
      -0.007328025996685028,
      -0.014225631020963192,
      -0.02291584201157093,
      0.03487064689397812,
      -0.07055073231458664,
      -0.009164602495729923,
      -0.03244171664118767,
      -0.01690848544239998,
      -0.06353642791509628,
      0.006459198892116547,
      -0.009926585480570793,
      0.009233626537024975,
      0.01580604538321495,
      -0.0038341607432812452,
      -0.041325584053993225,
      0.026328912004828453,
      -0.033185798674821854,
      -0.0059732962399721146,
      -0.0015333471819758415,
      0.016095323488116264,
      -0.006781227421015501,
      0.04555801674723625,
      -0.007995263673365116,
      0.005780430044978857,
      0.009359396994113922,
      -0.05576838552951813,
      -0.07680227607488632,
      -0.048627279698848724,
      -0.015969812870025635,
      0.045265909284353256,
      0.09560057520866394,
      0.05333760753273964,
      -0.059631526470184326,
      -0.022816304117441177,
      -0.0016542079392820597,
      -0.024495279416441917,
      0.03868328779935837,
      0.05231008306145668,
      0.02282574214041233,
      -0.00524130929261446,
      0.08720947057008743,
      0.008582057431340218,
      0.010112028568983078,
      -0.0006008959608152509,
      -0.021174604073166847,
      0.02282806858420372,
      0.020198361948132515,
      -0.0023041414096951485,
      -0.07204136252403259,
      -0.011880430392920971,
      0.08456030488014221,
      0.00273365480825305,
      0.04035153612494469,
      0.028677837923169136,
      0.018269050866365433,
      -0.013422048650681973,
      0.02301182597875595,
      -0.03096342459321022,
      -0.002131419023498893,
      -0.1108916699886322,
      0.05761868879199028,
      0.09701213240623474,
      0.028277890756726265,
      0.06313139945268631,
      -0.015591810457408428,
      -0.008404374122619629,
      -0.04511437937617302,
      -0.05538167804479599,
      -0.0618300661444664,
      0.07722177356481552,
      0.012710608541965485,
      -0.029294202104210854,
      0.0741986632347107,
      0.05648219957947731,
      0.039313945919275284,
      0.002562850946560502,
      0.09415732324123383,
      -0.026903774589300156,
      -0.06772475689649582,
      -0.051493506878614426,
      -0.03458574414253235,
      -0.04151248559355736,
      -0.04829641059041023,
      -0.03070201724767685,
      0.06420388072729111,
      0.0028626318089663982,
      -0.04220672324299812,
      -0.05718047916889191,
      0.03927639499306679,
      -0.044418200850486755,
      -0.06130126863718033,
      -0.018592150881886482,
      0.008678670041263103,
      0.06361556053161621,
      0.034658271819353104,
      -0.09816038608551025,
      -0.011658997274935246,
      0.007082562427967787,
      -0.004504267126321793,
      0.003402719274163246,
      -0.037415239959955215,
      0.02855854481458664,
      -0.0710025504231453,
      -0.0033304160460829735,
      0.10609877854585648,
      -0.044381387531757355,
      0.008201921358704567,
      -0.013935163617134094,
      0.002534247701987624,
      0.023777460679411888,
      -0.002490113489329815,
      -0.02333281747996807,
      0.03341688588261604,
      -0.07674584537744522,
      -0.00402537826448679,
      0.005721250083297491,
      0.014450307004153728,
      -0.010436415672302246,
      -0.05281132459640503,
      -0.0019700112752616405,
      -0.05749363824725151,
      -0.023538941517472267,
      0.048748284578323364,
      0.016800491139292717,
      -0.0421503409743309,
      -0.042762063443660736,
      0.11541149765253067,
      0.06646441668272018,
      0.011609584093093872,
      -0.038701776415109634,
      0.03939655423164368,
      -0.03970911726355553,
      -0.0489707849919796,
      0.09511254727840424,
      0.055192943662405014,
      -0.0014803262893110514,
      -0.008824307471513748,
      -0.026625387370586395,
      0.05699620395898819,
      -0.02183356322348118,
      0.04030357673764229,
      -0.06459041684865952,
      0.02835555002093315,
      -0.031334374099969864,
      0.08240307867527008,
      -0.007056049536913633,
      0.03479987010359764,
      -0.029491109773516655,
      -0.02511187456548214,
      -0.04946305602788925,
      -0.01639147475361824,
      -0.05455728620290756,
      0.024736814200878143,
      -0.049511075019836426,
      0.03691469132900238,
      -0.08677536249160767,
      0.02559601329267025,
      -0.014317418448626995,
      -0.009188949130475521,
      0.04377037286758423,
      -0.019330384209752083,
      0.013326062820851803,
      0.023235216736793518,
      -0.055862389504909515,
      -0.028542982414364815,
      -0.06300833821296692,
      -0.0005212107789702713,
      -0.054030511528253555,
      -0.015375894494354725,
      -0.0034694327041506767,
      0.05097842961549759,
      0.09823285043239594,
      0.05022154003381729,
      0.03762207552790642,
      0.010269932448863983,
      0.018155276775360107,
      -0.09200747311115265,
      0.019250059500336647,
      -0.014119592495262623,
      -0.0001657012035138905,
      -0.04467972740530968,
      -0.037423282861709595,
      -0.056286055594682693,
      0.058315765112638474,
      -0.004984664265066385,
      -0.04188624396920204,
      0.0282791405916214,
      0.03028629720211029,
      -0.04102802649140358,
      -0.023000076413154602,
      -0.020956501364707947,
      -0.011182306334376335,
      0.07912809401750565,
      0.02567199245095253,
      -0.0010575467022135854,
      -0.04401352256536484,
      0.0230573657900095,
      -0.0635274201631546,
      -0.0910930335521698,
      -0.04167962446808815,
      -0.07425659149885178,
      -0.01120176911354065,
      0.09025018662214279,
      -0.019611172378063202,
      0.034579936414957047,
      -0.018865998834371567,
      -0.04594898596405983,
      -0.0515630841255188,
      -0.05551016703248024,
      -0.06813827157020569,
      0.028097445145249367,
      0.09386778622865677,
      -0.055589135736227036,
      -0.036949124187231064,
      -0.041991107165813446,
      0.07054325938224792,
      0.027810564264655113,
      0.08165598660707474,
      0.04467589780688286,
      -0.04259089007973671,
      -0.06059989705681801,
      0.013446176424622536,
      -0.006163515150547028,
      0.03039947897195816,
      -0.06837280839681625,
      -0.0785583108663559,
      0.06539098173379898,
      0.12313976883888245,
      -0.010667433962225914,
      0.058789461851119995,
      0.0610465481877327,
      0.012477531097829342,
      0.026122938841581345,
      -0.02406105026602745,
      0.07004766166210175,
      0.055226366966962814,
      -0.015709465369582176
    ],
    [
      0.0413905568420887,
      0.0052479966543614864,
      0.0746319517493248,
      0.07263549417257309,
      -0.008677268400788307,
      -0.0516575425863266,
      0.026379680261015892,
      0.0485832579433918,
      -0.09391667693853378,
      -0.06529578566551208,
      0.026187105104327202,
      -0.042058032006025314,
      0.018712447956204414,
      -0.056884944438934326,
      -0.03318053483963013,
      0.0342741496860981,
      0.0648929625749588,
      0.0602874793112278,
      0.023778924718499184,
      -0.028945907950401306,
      0.001997062237933278,
      -0.05251394212245941,
      0.018006032332777977,
      -0.04157714545726776,
      0.04005185142159462,
      -0.03413238748908043,
      -0.016022080555558205,
      -0.03859729692339897,
      -0.025968004018068314,
      0.007824370637536049,
      -0.027941733598709106,
      0.021110987290740013,
      0.08050314337015152,
      0.05027781426906586,
      -0.016530435532331467,
      -0.11491898447275162,
      -0.04381410777568817,
      -0.0024460172280669212,
      -0.011304928921163082,
      0.09145970642566681,
      -0.03859560564160347,
      0.0373200885951519,
      -0.04605166241526604,
      0.010669887997210026,
      0.06409208476543427,
      0.04166174679994583,
      0.08726785331964493,
      -0.04410673305392265,
      0.010923582129180431,
      0.053796567022800446,
      0.025969840586185455,
      0.04249952733516693,
      0.008499926887452602,
      -0.02490776777267456,
      -0.03167097643017769,
      -0.023176779970526695,
      0.07280147075653076,
      -0.02532634511590004,
      0.10584327578544617,
      -0.0045453524217009544,
      -0.007209038361907005,
      -0.013673362322151661,
      -0.024096129462122917,
      0.04817565903067589,
      0.032822318375110626,
      -0.006411897484213114,
      -0.10553016513586044,
      -0.02080322615802288,
      0.01619928702712059,
      0.01991669461131096,
      0.01296831201761961,
      -0.03660575672984123,
      -0.016836903989315033,
      0.008108719252049923,
      0.01695144549012184,
      0.0567481592297554,
      -0.056152667850255966,
      0.0293373242020607,
      0.08841007947921753,
      0.10374733060598373,
      -0.050487205386161804,
      0.056382402777671814,
      -0.002828510943800211,
      -0.03128252923488617,
      0.03684141859412193,
      -0.04034699127078056,
      0.08182673901319504,
      0.05457710474729538,
      -0.032722339034080505,
      -0.10144885629415512,
      -0.02166811376810074,
      0.006551675498485565,
      -0.025171440094709396,
      0.02242889627814293,
      0.002774375258013606,
      0.033722635358572006,
      -0.049521490931510925,
      -0.036711033433675766,
      -0.051958099007606506,
      0.01100106630474329,
      0.013264315202832222,
      -0.007958363741636276,
      0.06828147172927856,
      0.009051142260432243,
      -0.07457106560468674,
      0.015038215555250645,
      0.06829385459423065,
      -0.013420677743852139,
      -0.003384653013199568,
      -0.0369696244597435,
      0.009235826320946217,
      -0.051898740231990814,
      -0.10128965228796005,
      0.05346006900072098,
      -0.007949631661176682,
      -0.019900094717741013,
      -0.04786638543009758,
      0.04893025755882263,
      -0.01779523864388466,
      0.07019519805908203,
      0.04793946444988251,
      -0.008396418765187263,
      0.02450782246887684,
      0.03268967568874359,
      0.009166555479168892,
      0.0012031401274725795,
      0.025473400950431824,
      -0.05293969064950943,
      -0.017731627449393272,
      0.0005544067244045436,
      0.034874290227890015,
      -0.05587885528802872,
      -0.02592567168176174,
      0.0371275395154953,
      -0.01825319603085518,
      -0.014450033195316792,
      -0.026041021570563316,
      0.0775526762008667,
      0.030943110585212708,
      0.023973535746335983,
      -0.09440556913614273,
      0.03128433600068092,
      -0.03025854006409645,
      0.01479000598192215,
      -0.014019598253071308,
      0.006650188937783241,
      0.04934168979525566,
      0.009595618583261967,
      -0.05182022228837013,
      -0.0801558718085289,
      0.026548270136117935,
      0.07819423079490662,
      0.019119566306471825,
      0.03131154552102089,
      0.021380547434091568,
      0.011486510746181011,
      0.003420291468501091,
      0.011184467934072018,
      0.04208808019757271,
      -0.013266961090266705,
      0.013775995932519436,
      -0.11733301728963852,
      0.005084700416773558,
      -0.03492605313658714,
      0.028759781271219254,
      -0.009004239924252033,
      0.013485501520335674,
      -0.03190319612622261,
      0.03730698674917221,
      0.07694337517023087,
      0.050831783562898636,
      0.04930242896080017,
      0.053958311676979065,
      -0.0464058518409729,
      0.08301644027233124,
      -0.06899337470531464,
      0.014346514828503132,
      -0.04449594020843506,
      0.02104891836643219,
      -0.0198482908308506,
      0.03962942585349083,
      0.090168796479702,
      0.04385428875684738,
      -0.005170227959752083,
      0.047789566218853,
      -0.04489998519420624,
      -0.04326997697353363,
      0.03881751373410225,
      -0.009867860935628414,
      0.07661622017621994,
      0.08480482548475266,
      0.0396810844540596,
      -0.04527824744582176,
      0.009244313463568687,
      0.06431262195110321,
      0.02063736878335476,
      0.01843884028494358,
      -0.09472335129976273,
      -0.015058383345603943,
      0.03610992059111595,
      0.08537126332521439,
      -0.014443178661167622,
      -0.036115869879722595,
      0.028996244072914124,
      -0.032188091427087784,
      0.007371421903371811,
      0.051661595702171326,
      0.016500722616910934,
      0.0021245311945676804,
      -0.02239440381526947,
      -0.011565867811441422,
      -0.046101003885269165,
      -0.04276454821228981,
      -0.07664432376623154,
      0.004023791290819645,
      -0.009808151982724667,
      0.04090255871415138,
      0.038124214857816696,
      0.008763758465647697,
      0.022480905055999756,
      0.027121353894472122,
      -0.03567592054605484,
      0.06583593040704727,
      -0.03859766945242882,
      -0.049989115446805954,
      0.057056453078985214,
      0.049854062497615814,
      -0.004263672977685928,
      0.07857013493776321,
      0.056950513273477554,
      0.0051041836850345135,
      0.034591592848300934,
      -0.03269415348768234,
      0.015546192415058613,
      -0.08236424624919891,
      -0.006441436242312193,
      0.017759887501597404,
      0.011280244216322899,
      -0.03976910561323166,
      0.0016948918346315622,
      -0.060719165951013565,
      0.08085441589355469,
      0.020102499052882195,
      0.03248723968863487,
      -0.0009530971292406321,
      -0.054695386439561844,
      -0.06899769604206085,
      -0.04657028988003731,
      0.01778397522866726,
      -0.020587317645549774,
      -0.09292098134756088,
      0.042099710553884506,
      -0.019099336117506027,
      0.030567334964871407,
      0.015782300382852554,
      0.022652164101600647,
      0.02212820015847683,
      -0.04527425765991211,
      -0.0019480419578030705,
      0.08549340069293976,
      -0.047001950442790985,
      -0.0554119348526001,
      -0.029681645333766937,
      0.033676546066999435,
      -0.006252332124859095,
      -0.010881759226322174,
      -0.013098390772938728,
      0.013251952826976776,
      0.016001606360077858,
      0.028205163776874542,
      0.06675026565790176,
      -0.06165565177798271,
      -3.7009198422310874e-05,
      0.08233651518821716,
      -0.027958588674664497,
      0.04539286717772484,
      -0.014131489209830761,
      -0.015057649463415146,
      -0.020259717479348183,
      0.003960348665714264,
      -0.005790329072624445,
      0.008096659556031227,
      -0.004418875556439161,
      -0.06346318125724792,
      -0.029631884768605232,
      0.012842985801398754,
      -0.025427773594856262,
      -0.018556471914052963,
      0.07833253592252731,
      0.03718017786741257,
      -0.020455559715628624,
      0.011150567792356014,
      -0.052736397832632065,
      0.04569537192583084,
      0.026148155331611633,
      0.000602684507612139,
      0.010942356660962105,
      0.06431981921195984,
      -0.015543675981462002,
      -0.05993397533893585,
      -0.032847508788108826,
      -0.029503120109438896,
      0.036935608834028244,
      -0.03145337849855423,
      0.0009410800994373858,
      0.014952004887163639,
      -0.042634498327970505,
      0.03324415907263756,
      0.022239992395043373,
      0.01911768689751625,
      -0.01632697507739067,
      0.03353525698184967,
      -0.004743906669318676,
      0.036931056529283524,
      0.005860674194991589,
      0.01559812668710947,
      -0.009615235030651093,
      -0.03483161702752113,
      0.02449560910463333,
      -0.018410900607705116,
      -0.11299201101064682,
      0.01427148561924696,
      -0.001628883183002472,
      0.001544597907923162,
      0.005415077321231365,
      0.03430744633078575,
      0.031634192913770676,
      -0.01956820674240589,
      0.014770611189305782,
      0.02707233652472496,
      -0.07552435994148254,
      0.09939005970954895,
      -0.012897762469947338,
      0.016991958022117615,
      -0.005657604429870844,
      0.0858461931347847,
      0.07478305697441101,
      0.00179288478102535,
      0.017789063975214958,
      -0.05355297029018402,
      0.009066789411008358,
      0.017258549109101295,
      0.0072824242524802685,
      0.03790875896811485,
      0.029723064973950386,
      -0.012177882716059685,
      -0.012946324422955513,
      -0.03437754139304161,
      -0.014256710186600685,
      0.051765114068984985,
      0.011646208353340626,
      0.03609073534607887,
      0.02789262682199478,
      0.026441285386681557,
      -0.030500931665301323,
      0.0025934232398867607,
      -0.0050378888845443726,
      -0.07254191488027573,
      0.013326982967555523,
      -0.02584686689078808,
      -0.004499403759837151,
      0.06029883027076721,
      -0.052873171865940094,
      -0.009350305423140526,
      -0.057368598878383636,
      0.027191948145627975,
      -0.042688194662332535,
      -0.02042408287525177,
      0.04456500709056854,
      -0.01093581784516573,
      0.01128276064991951,
      -0.10351777821779251,
      -0.021651996299624443,
      -0.059983961284160614,
      -0.03655421361327171,
      -0.042614132165908813,
      0.00048634796985425055,
      0.01777360402047634,
      0.06451563537120819,
      -0.0843457281589508,
      -0.0487387478351593,
      -0.003153344849124551,
      0.003725487971678376,
      -0.04519033804535866,
      -0.02982885017991066,
      -0.0032396921887993813,
      0.004078091587871313,
      0.0015009429771453142,
      0.10338743031024933,
      0.0031432565301656723,
      -0.03790866583585739,
      -0.016149384900927544,
      0.05671679973602295,
      0.0454440638422966,
      -0.06230554357171059,
      -0.04144534468650818,
      -0.062423814088106155,
      -0.05420801416039467,
      0.008466782048344612,
      0.01553049311041832,
      -0.03245355933904648,
      -0.03784828260540962,
      -0.013205109164118767,
      -0.05087621882557869,
      0.04608742892742157,
      0.023052845150232315,
      0.04789333790540695,
      0.04244512692093849,
      0.05181983858346939,
      0.010139095596969128,
      -0.04119185358285904,
      0.002362765371799469,
      -0.019796287640929222,
      -0.07320694625377655,
      0.004915474448353052,
      0.03529598191380501,
      0.11704479157924652,
      0.09000115096569061,
      -0.03441745787858963,
      0.029935546219348907,
      0.08928216248750687,
      0.025803377851843834,
      0.0062494585290551186,
      0.044289857149124146,
      -0.07033472508192062,
      -0.015804089605808258,
      -0.008806067518889904,
      0.05047786235809326,
      -0.0324728824198246,
      0.05575928837060928,
      0.011382041499018669,
      -0.03521108254790306,
      -0.0776357352733612,
      0.013297525234520435,
      0.008961371146142483,
      0.016324076801538467,
      0.013302893377840519,
      -0.033794157207012177,
      0.0013809623196721077,
      -0.01975313387811184,
      0.07977661490440369,
      -0.0741230845451355,
      0.05896330252289772,
      -0.04274407774209976,
      0.04484432935714722,
      -0.021238276734948158,
      0.005552574060857296,
      -0.002378957811743021,
      0.06852138787508011,
      -0.08831595629453659,
      -0.11731350421905518,
      0.007169350981712341,
      0.04880319535732269,
      0.01312835793942213,
      0.0019828476943075657,
      0.06012237071990967,
      0.027650445699691772,
      0.00553263770416379,
      -0.013759956695139408,
      -0.008077622391283512,
      -0.1027085930109024,
      -0.016159767284989357,
      -0.010678015649318695,
      -0.04352881759405136,
      0.0003394428640604019,
      0.0012301142560318112,
      -0.06558588892221451,
      0.09487394243478775,
      0.02724304236471653,
      0.02591758966445923,
      -0.04252462461590767,
      0.02026679366827011,
      0.03232148289680481,
      -0.08519009500741959,
      -0.04679395258426666,
      -0.00034733733627945185,
      -0.029971985146403313,
      0.008119254373013973,
      -0.009119230322539806,
      -0.0235068891197443,
      -0.03149551525712013,
      -0.04374578222632408,
      -0.0381244532763958,
      0.033159684389829636,
      0.043801940977573395,
      0.006120626349002123,
      -0.007746783550828695,
      -0.015765149146318436,
      0.08921869099140167,
      0.01964542083442211,
      -0.06708820909261703,
      -0.027894657105207443,
      0.0044849771074950695,
      -0.06539911031723022,
      0.024400360882282257,
      0.030840715393424034,
      0.04207257181406021,
      0.016278740018606186,
      0.04417999088764191,
      -0.0769573450088501,
      -0.04741615056991577,
      0.04064616560935974,
      0.019647957757115364
    ],
    [
      -0.016048219054937363,
      0.08241865783929825,
      -0.017774464562535286,
      0.0188490878790617,
      -0.0448799654841423,
      0.04097903147339821,
      -0.02610919438302517,
      0.024915412068367004,
      0.05123334378004074,
      -0.022237956523895264,
      0.10297191888093948,
      -0.0448843315243721,
      0.007919350638985634,
      0.06521160155534744,
      -0.06611993163824081,
      -0.0043897246941924095,
      0.0034560502972453833,
      -0.06920043379068375,
      -0.053822509944438934,
      0.0501139760017395,
      -0.0018510310910642147,
      -0.07019379734992981,
      -0.012034337967634201,
      0.0004879911430180073,
      0.05317634716629982,
      -0.07254708558320999,
      0.002629778580740094,
      0.10620135813951492,
      -0.038416702300310135,
      0.03697553277015686,
      -0.04927857220172882,
      0.001034847809933126,
      -0.016436530277132988,
      -0.04251378774642944,
      0.03745488449931145,
      -0.0016129909781739116,
      -0.06944897770881653,
      -0.005927461665123701,
      -0.0034606170374900103,
      0.0076810684986412525,
      0.0024334352929145098,
      -0.0005017159273847938,
      -0.08541572839021683,
      -0.0017340409103780985,
      -0.05601073056459427,
      0.021139247342944145,
      0.07567097246646881,
      0.07136129587888718,
      0.009707668796181679,
      -0.0944862961769104,
      0.10932308435440063,
      0.03245314583182335,
      0.006740326061844826,
      0.02441221848130226,
      0.01285710372030735,
      0.005063280463218689,
      0.05089188739657402,
      0.005102652125060558,
      0.08734234422445297,
      0.007192834746092558,
      -0.0012292887549847364,
      0.07617667317390442,
      0.039131585508584976,
      -0.0975126400589943,
      -0.012911473400890827,
      -0.09532171487808228,
      -0.024821987375617027,
      0.013722945004701614,
      -0.029017815366387367,
      0.00039868082967586815,
      -0.01902761496603489,
      -0.0567893385887146,
      0.028705012053251266,
      0.08360671252012253,
      -0.05144413560628891,
      -0.04528149589896202,
      0.020862746983766556,
      -0.05164194852113724,
      -0.018344292417168617,
      -0.011213745921850204,
      0.036149609833955765,
      -0.029441844671964645,
      -0.03416157886385918,
      0.06422121822834015,
      -0.04407019168138504,
      -0.029545770958065987,
      -0.000663906626868993,
      0.03640146553516388,
      -0.03709746152162552,
      -0.04438099265098572,
      0.019257239997386932,
      0.04805736243724823,
      0.09894709289073944,
      0.031667716801166534,
      -0.05246821790933609,
      0.028616994619369507,
      0.043039821088314056,
      -0.02314562350511551,
      0.0608721487224102,
      -0.04737020656466484,
      -0.016065992414951324,
      -0.009549463167786598,
      -0.05448876693844795,
      0.006137698423117399,
      0.014483463019132614,
      -0.04318305477499962,
      0.05171928182244301,
      -0.04182089492678642,
      -0.06864949315786362,
      0.06865653395652771,
      0.030132606625556946,
      -0.012269729748368263,
      -0.041809841990470886,
      -0.04941893741488457,
      -0.04187777265906334,
      0.027652692049741745,
      0.0701025053858757,
      0.024399226531386375,
      -0.017936868593096733,
      -0.04428008943796158,
      -0.06302689015865326,
      -0.0005765310488641262,
      0.03211383894085884,
      0.03678412362933159,
      0.06951051205396652,
      -0.040446147322654724,
      -0.0361298993229866,
      -0.05023076385259628,
      0.032408636063337326,
      0.008922134526073933,
      0.014698723331093788,
      -0.008244450204074383,
      0.0001930191065184772,
      0.06495433300733566,
      0.1336928904056549,
      0.0057469927705824375,
      0.0036737711634486914,
      0.022305482998490334,
      -0.02353358455002308,
      0.03678525611758232,
      -0.02220718190073967,
      0.04984087869524956,
      0.045708250254392624,
      0.013793038204312325,
      0.06957288086414337,
      0.08682852983474731,
      0.06727001070976257,
      0.037056732922792435,
      -0.025933001190423965,
      -0.008893203921616077,
      -0.05181495472788811,
      -0.015330104157328606,
      0.020691538229584694,
      0.05621037259697914,
      0.05137747898697853,
      -0.03398486226797104,
      0.04419213533401489,
      0.0006413923110812902,
      -0.06850754469633102,
      -0.02997746877372265,
      0.010667391121387482,
      0.08299978822469711,
      0.014891276136040688,
      -0.060276564210653305,
      -0.025003202259540558,
      -0.06157505512237549,
      -0.07959584146738052,
      0.014915978536009789,
      -0.0024390791077166796,
      0.024885103106498718,
      -0.058698058128356934,
      0.03335459157824516,
      0.02468242682516575,
      0.05936083942651749,
      0.01858908124268055,
      0.016113359481096268,
      -0.009945161640644073,
      -0.03546930104494095,
      -0.030978558585047722,
      -0.019655197858810425,
      0.007224739529192448,
      0.06399862468242645,
      0.04986963048577309,
      -0.028806135058403015,
      0.04203552380204201,
      -0.016382109373807907,
      -0.019101429730653763,
      0.004974882584065199,
      0.08607414364814758,
      -0.07464977353811264,
      -0.020437613129615784,
      -0.031088540330529213,
      -0.04122055321931839,
      -0.017041314393281937,
      -0.009759151376783848,
      0.017294494435191154,
      -0.010630258359014988,
      0.08598324656486511,
      -0.03412694111466408,
      -0.011857347562909126,
      -0.0023478807415813208,
      -0.04721025377511978,
      0.04214435815811157,
      -0.01825089380145073,
      -0.056665826588869095,
      -0.01251059491187334,
      0.05395291745662689,
      0.0003379813570063561,
      0.053320907056331635,
      0.006476912181824446,
      0.0916488841176033,
      0.0006257824134081602,
      -0.06747472286224365,
      -0.015241504646837711,
      0.08645312488079071,
      -0.01071596797555685,
      -0.005951080936938524,
      0.04425788298249245,
      0.09151186048984528,
      -0.038112230598926544,
      0.021921370178461075,
      -0.024486102163791656,
      0.06609131395816803,
      -0.05629238113760948,
      -0.019736817106604576,
      0.036970194429159164,
      0.046044714748859406,
      0.02966664917767048,
      0.009097314439713955,
      0.03035912476480007,
      -0.06341876089572906,
      0.022618761286139488,
      -0.02487584762275219,
      0.058759745210409164,
      -0.0465146079659462,
      0.0386936329305172,
      0.06419847160577774,
      0.003502152394503355,
      -0.04928656667470932,
      -0.09433736652135849,
      0.0396696999669075,
      0.09039714932441711,
      0.05886949598789215,
      0.032534003257751465,
      0.07439331710338593,
      -0.09676366299390793,
      -0.1083357036113739,
      -0.011040719226002693,
      0.015415512956678867,
      0.03320471569895744,
      0.0037755058147013187,
      0.04079447314143181,
      -0.007767289411276579,
      -0.030387286096811295,
      0.007119287736713886,
      -0.11052477359771729,
      0.046712860465049744,
      0.014911183156073093,
      0.026169223710894585,
      -0.1066146045923233,
      0.0005517641548067331,
      0.03610064461827278,
      -0.012497278861701488,
      0.016735881567001343,
      -0.09399426728487015,
      -0.0867447704076767,
      0.04875980690121651,
      -0.0040182434022426605,
      -0.014444617554545403,
      0.058566439896821976,
      -0.0027458725962787867,
      -0.029146796092391014,
      -0.0037971821147948503,
      -0.020847296342253685,
      -0.08725269883871078,
      0.04682308807969093,
      0.07914510369300842,
      0.07312609255313873,
      0.0817066878080368,
      0.03126860409975052,
      -0.002886832458898425,
      -0.016625799238681793,
      0.055015679448843,
      -0.007903940975666046,
      0.07269234210252762,
      0.03983081877231598,
      -0.05839527025818825,
      -0.04038229584693909,
      0.04714434966444969,
      0.03251061215996742,
      0.04124262183904648,
      0.02802940458059311,
      0.05973627418279648,
      0.05122150480747223,
      -0.037705663591623306,
      0.08485253155231476,
      0.010901371948421001,
      -0.014569553546607494,
      0.03389836475253105,
      -0.053448621183633804,
      -0.04807909205555916,
      0.014031454920768738,
      0.009364305064082146,
      0.008227638900279999,
      -0.039786387234926224,
      -0.0005625692429021001,
      -0.01909938082098961,
      -0.01545934658497572,
      -0.03351782262325287,
      0.11791706830263138,
      -0.0007128227152861655,
      0.05798638239502907,
      0.0013083168305456638,
      0.015452609397470951,
      -0.0941782221198082,
      -0.058517102152109146,
      -0.017060326412320137,
      -0.00860104151070118,
      -0.035263486206531525,
      -0.04050339013338089,
      0.11042280495166779,
      0.00504401745274663,
      0.08213736116886139,
      0.051664769649505615,
      -0.054349981248378754,
      -0.02841287851333618,
      0.02289201319217682,
      0.018528003245592117,
      0.014489310793578625,
      -0.07595463842153549,
      -0.0466943196952343,
      -0.008655223064124584,
      -0.015628116205334663,
      0.014129189774394035,
      0.03729825094342232,
      -0.03537696972489357,
      0.021680939942598343,
      -0.038198791444301605,
      0.05349445715546608,
      0.03687966987490654,
      -0.04644392058253288,
      -0.06183227151632309,
      -0.023793254047632217,
      0.03069390542805195,
      -0.025722676888108253,
      -0.011337710544466972,
      0.0027148618828505278,
      0.052745476365089417,
      -0.0534171536564827,
      0.13939131796360016,
      0.06524123251438141,
      -0.03957147151231766,
      0.057854846119880676,
      -0.007722158450633287,
      -0.05017894133925438,
      0.05140739306807518,
      -0.0728050097823143,
      0.043653346598148346,
      -0.033454593271017075,
      -0.01783195696771145,
      -0.015087711624801159,
      0.045291218906641006,
      -0.017283959314227104,
      0.026622062548995018,
      0.10089150071144104,
      -0.07609867304563522,
      0.0017696393188089132,
      0.026023326441645622,
      -0.013138381764292717,
      0.03255528584122658,
      0.03654514253139496,
      0.01002760324627161,
      -0.0036240138579159975,
      -0.003027893602848053,
      -0.058019984513521194,
      -0.000629759393632412,
      -0.022889353334903717,
      0.03641266003251076,
      -0.01150642242282629,
      -0.06557857990264893,
      -0.021916471421718597,
      0.013071483932435513,
      0.064344123005867,
      -0.028163090348243713,
      0.043860722333192825,
      -0.05169447883963585,
      0.002022666623815894,
      0.025012560188770294,
      -0.04263315722346306,
      0.0014122192515060306,
      -0.08326440304517746,
      -0.005348964128643274,
      0.01406906545162201,
      -0.01816082000732422,
      -0.020371004939079285,
      0.060494229197502136,
      0.02459041401743889,
      0.0030190066900104284,
      -0.01909658871591091,
      -0.07096094638109207,
      0.05141015723347664,
      -0.020639806985855103,
      -0.08531192690134048,
      0.040176089853048325,
      -0.028469108045101166,
      0.045744020491838455,
      0.055173441767692566,
      0.05206136032938957,
      0.01834200881421566,
      0.0073417359963059425,
      -0.05678398907184601,
      -0.057977236807346344,
      -0.06576473265886307,
      -0.029518449679017067,
      0.05878392606973648,
      0.04052914306521416,
      -0.005382598843425512,
      -0.0010459001641720533,
      0.07287783175706863,
      0.059125643223524094,
      0.00643721641972661,
      0.044367268681526184,
      0.0608714334666729,
      -0.045526739209890366,
      0.00657255994156003,
      0.004923119675368071,
      -0.006591922603547573,
      -0.03256841376423836,
      -0.07459881901741028,
      0.05603702366352081,
      0.07836554199457169,
      0.05539329722523689,
      -0.08853840082883835,
      -0.0419468879699707,
      0.02613385021686554,
      0.019360225647687912,
      -0.01915939897298813,
      0.007604023441672325,
      0.011953999288380146,
      0.001865579281002283,
      0.13701528310775757,
      -0.051014453172683716,
      -0.04256579652428627,
      -0.03971506655216217,
      0.028343958780169487,
      -0.011709174141287804,
      -0.01772920787334442,
      -0.028964845463633537,
      0.037889380007982254,
      -0.015421583317220211,
      -0.062012169510126114,
      0.1565214991569519,
      -0.09019065648317337,
      -0.048950955271720886,
      -0.007532554678618908,
      0.060521215200424194,
      0.0025019701570272446,
      -0.0673525333404541,
      -0.011981654912233353,
      0.005572371184825897,
      0.07295016199350357,
      0.004650364629924297,
      -0.026905613020062447,
      -0.1283409297466278,
      0.04709022864699364,
      0.023334605619311333,
      0.0724310576915741,
      -0.029792316257953644,
      0.01575043983757496,
      -0.009827480651438236,
      0.06477350741624832,
      -0.002331986790522933,
      -0.01907627284526825,
      -0.059735722839832306,
      0.013003386557102203,
      0.025446707382798195,
      0.05032334849238396,
      -0.03578260913491249,
      0.012022777460515499,
      0.0014925339492037892,
      0.04648708179593086,
      -0.0016307535115629435,
      0.03154582530260086,
      0.05171649903059006,
      0.030935248360037804,
      0.033579278737306595,
      -0.044954463839530945,
      0.08015818148851395,
      0.014770854264497757,
      0.022324590012431145,
      -0.003490188391879201,
      -0.01778382621705532,
      -0.05993744358420372,
      -0.017913779243826866,
      -0.007441317196935415,
      -0.014682161621749401,
      0.052984751760959625,
      0.012294268235564232,
      0.024339882656931877,
      -0.045100413262844086,
      -0.03606542572379112,
      -0.05661184340715408,
      -0.06516249477863312
    ],
    [
      -0.03981657326221466,
      -0.04465760290622711,
      -0.02204003371298313,
      0.06344880908727646,
      0.054827891290187836,
      0.04846673458814621,
      0.043044351041316986,
      -0.02392253652215004,
      -0.0058029466308653355,
      -0.05578619986772537,
      -0.005498360842466354,
      -0.013762302696704865,
      -0.006004795897752047,
      0.010023250244557858,
      0.0298872422426939,
      0.011594615876674652,
      0.025270773097872734,
      -0.04291108250617981,
      -0.08465699851512909,
      -0.009456912986934185,
      0.016084248200058937,
      -0.061159975826740265,
      -0.02889183908700943,
      0.0207742340862751,
      0.09979762881994247,
      0.014718043617904186,
      -0.031744133681058884,
      -0.004792914725840092,
      0.052052680402994156,
      0.062015652656555176,
      -0.06139570102095604,
      -0.0779777467250824,
      0.038801100105047226,
      -0.003958415240049362,
      -0.030561616644263268,
      0.021688563749194145,
      0.04369400814175606,
      -0.07533774524927139,
      -0.015695035457611084,
      -0.04720872640609741,
      -0.03597733750939369,
      0.03968778997659683,
      0.017992883920669556,
      0.00714437011629343,
      -0.08613000810146332,
      0.019687414169311523,
      0.05510690435767174,
      0.04335957393050194,
      -0.06649855524301529,
      0.057962413877248764,
      0.01269492693245411,
      0.008189357817173004,
      -0.044799838215112686,
      -0.08603774011135101,
      0.07394632697105408,
      0.058076389133930206,
      -0.018282026052474976,
      0.11853310465812683,
      0.03128949925303459,
      -0.051319658756256104,
      -0.04608483240008354,
      0.003404260380193591,
      -0.07711482793092728,
      -0.0628919005393982,
      0.028811801224946976,
      0.01905483566224575,
      -0.022606149315834045,
      0.032600414007902145,
      0.009103809483349323,
      -0.02549935318529606,
      -0.005065922625362873,
      0.04368779808282852,
      0.09167861193418503,
      -0.04240397363901138,
      -0.03786643594503403,
      -0.012980291619896889,
      0.024760866537690163,
      -0.0001772436371538788,
      0.028968047350645065,
      -0.01658204384148121,
      0.04730984941124916,
      0.06910789012908936,
      -0.008948919363319874,
      0.05467687547206879,
      -0.027657508850097656,
      0.08200237154960632,
      0.0292186476290226,
      -0.009511185809969902,
      -0.0973491296172142,
      0.034783415496349335,
      0.08251859992742538,
      0.019465645775198936,
      -0.06877905875444412,
      -0.056618496775627136,
      -0.02800877019762993,
      0.014221128076314926,
      0.020452916622161865,
      0.07031449675559998,
      -0.004433924797922373,
      -0.011266585439443588,
      0.011310487054288387,
      0.04832795634865761,
      -0.028428059071302414,
      0.09170646965503693,
      0.013750881887972355,
      -0.015463117510080338,
      0.027466652914881706,
      0.01511542871594429,
      0.0050538587383925915,
      -0.06050087884068489,
      0.009563800878822803,
      -0.04884083941578865,
      -0.04525177925825119,
      0.012905494309961796,
      0.056057389825582504,
      0.03602089360356331,
      0.014988599345088005,
      -0.006067773327231407,
      -0.027604660019278526,
      -0.02407762221992016,
      -0.04931042343378067,
      -0.042692095041275024,
      -0.08015652000904083,
      -0.08133704215288162,
      0.05276528745889664,
      0.0027553876861929893,
      -0.026098964735865593,
      -0.05696196109056473,
      -0.06077888235449791,
      0.07122787833213806,
      0.0003441165026742965,
      -0.02454417385160923,
      0.06238601729273796,
      0.028230158612132072,
      0.03062484785914421,
      0.029926208779215813,
      -0.08555331826210022,
      -0.0035598527174443007,
      0.028689613565802574,
      -0.06882645934820175,
      -0.014893847517669201,
      -0.08600424230098724,
      0.017383666709065437,
      0.06509099900722504,
      0.05469708889722824,
      -0.03223792836070061,
      0.014567901380360126,
      0.0414695106446743,
      0.022055525332689285,
      -0.048643775284290314,
      0.07681164145469666,
      0.06212693825364113,
      -0.06419530510902405,
      -0.035086698830127716,
      0.009910437278449535,
      -0.015619920566678047,
      -0.021448710933327675,
      -0.012337821535766125,
      0.017015352845191956,
      -0.10462478548288345,
      0.05978218466043472,
      0.016617951914668083,
      0.036336082965135574,
      0.06018407270312309,
      0.0026367632672190666,
      -0.004591470584273338,
      0.01574007235467434,
      0.007027979474514723,
      0.009777924045920372,
      -0.0389159619808197,
      -0.025966906920075417,
      -0.024037763476371765,
      0.04415341094136238,
      -0.04162411391735077,
      0.02125030942261219,
      0.09249017387628555,
      0.015381524339318275,
      0.024165233597159386,
      -0.04207252711057663,
      -0.008170442655682564,
      0.06114555522799492,
      -0.027615273371338844,
      0.06718244403600693,
      0.07830309867858887,
      -0.025530721992254257,
      -0.013392074964940548,
      -0.0525377094745636,
      -0.053366344422101974,
      0.06742643564939499,
      0.0032952420879155397,
      0.13102111220359802,
      0.06519264727830887,
      -0.0038524186238646507,
      0.03217848762869835,
      0.05283207818865776,
      -0.10352078825235367,
      -0.03742910176515579,
      0.05740775167942047,
      0.06574662774801254,
      -0.010204121470451355,
      0.011578794568777084,
      0.03239612281322479,
      -0.06225019320845604,
      0.026434311643242836,
      -0.04588790982961655,
      -0.031447868794202805,
      0.03873322531580925,
      -0.05621142312884331,
      -0.061158280819654465,
      -0.00197656755335629,
      -0.03273259475827217,
      -0.11077969521284103,
      0.025394750759005547,
      -0.07650311291217804,
      0.08253325521945953,
      0.03959525376558304,
      0.05034836754202843,
      0.04482966661453247,
      -0.09850319474935532,
      0.04179041460156441,
      -0.015507438220083714,
      -0.12048033624887466,
      0.0035983333364129066,
      -0.008557509630918503,
      -0.057360246777534485,
      0.015991441905498505,
      -0.06280918419361115,
      0.002756978152319789,
      -0.00984327495098114,
      -0.0008679648162797093,
      0.09844598174095154,
      -0.023455049842596054,
      0.011361698620021343,
      0.09762215614318848,
      0.06391541659832001,
      -0.005959885660558939,
      0.011631986126303673,
      -0.0007829373935237527,
      0.009513822384178638,
      -0.011232270859181881,
      -0.016279971227049828,
      0.0017890981398522854,
      -0.03843383491039276,
      0.0480959452688694,
      -0.04104137793183327,
      -0.07865151762962341,
      0.03295019641518593,
      -0.05346978083252907,
      0.05050002038478851,
      -0.007331663742661476,
      0.0070985243655741215,
      -0.030881177634000778,
      -0.04044592007994652,
      -0.053241197019815445,
      -0.034779373556375504,
      -0.040268778800964355,
      0.03042765147984028,
      -0.002962639322504401,
      0.07295665889978409,
      -0.011150633916258812,
      0.08712958544492722,
      0.04075433686375618,
      0.02452198788523674,
      -0.011296002194285393,
      -0.010078342631459236,
      -0.024260973557829857,
      0.1128174439072609,
      0.08137520402669907,
      0.015099186450242996,
      -0.007800520397722721,
      -0.008924730122089386,
      -0.09477785229682922,
      0.051793526858091354,
      -0.023868756368756294,
      0.019752273336052895,
      -0.006701079197227955,
      -0.011612051166594028,
      0.006420922465622425,
      -0.00905375275760889,
      -0.01374568697065115,
      -0.003583686426281929,
      -0.015998953953385353,
      0.05481669306755066,
      0.02371358498930931,
      0.026621129363775253,
      -0.07061363011598587,
      0.0370853990316391,
      -0.1027517020702362,
      0.018309999257326126,
      0.007408370263874531,
      -0.012884638272225857,
      0.02057681977748871,
      0.004935272969305515,
      0.04473065212368965,
      -0.02917768806219101,
      -0.04054001346230507,
      0.016448436304926872,
      0.07938814908266068,
      -0.007348841987550259,
      -0.013080952689051628,
      -0.03309817984700203,
      -0.004998653661459684,
      -0.06960900127887726,
      -0.04917241260409355,
      -0.006308552343398333,
      -0.01609552465379238,
      -0.05773450434207916,
      -0.042492274194955826,
      0.011385730467736721,
      0.009528281167149544,
      0.05103558301925659,
      0.022578487172722816,
      0.04618564993143082,
      0.027238590642809868,
      0.011846663430333138,
      -0.05021074041724205,
      0.0725422129034996,
      0.008862304501235485,
      -0.0017172484658658504,
      -0.06514947861433029,
      0.02317197620868683,
      0.034746672958135605,
      0.0046911099925637245,
      0.0007449566619470716,
      0.027947448194026947,
      0.004334233235567808,
      0.04809330403804779,
      -0.013172484934329987,
      -0.03545209392905235,
      -0.011206449009478092,
      -0.06390795111656189,
      -0.03105575032532215,
      0.020980030298233032,
      0.018700771033763885,
      0.010356683284044266,
      0.04430917650461197,
      -0.00048269316903315485,
      0.03507991135120392,
      -0.04980716109275818,
      0.019635703414678574,
      -0.052804358303546906,
      0.06998807936906815,
      -0.0765063539147377,
      0.01916234754025936,
      0.02830006182193756,
      0.06694373488426208,
      -0.023921506479382515,
      0.03467300906777382,
      0.03587137162685394,
      -0.013583253137767315,
      0.04545091465115547,
      -0.010477463714778423,
      -0.06657461822032928,
      0.04085627570748329,
      -0.00469402177259326,
      0.006006656680256128,
      0.03422447293996811,
      -0.01991085894405842,
      0.017223160713911057,
      0.05092864856123924,
      0.01035304181277752,
      -0.021936768665909767,
      0.007131346967071295,
      -0.0592682808637619,
      0.09068410843610764,
      -0.0620240718126297,
      0.11616569012403488,
      -0.043666351586580276,
      -0.0038590645417571068,
      -0.02623533457517624,
      0.07484316825866699,
      -0.05153260380029678,
      0.005926767364144325,
      0.003924732096493244,
      -0.01439725048840046,
      -0.036140456795692444,
      -0.0573185496032238,
      0.05911674723029137,
      -0.011127146892249584,
      -0.04350769519805908,
      0.030952882021665573,
      0.04100572690367699,
      -0.004254510626196861,
      -0.04741056263446808,
      -0.05793043226003647,
      -0.05116009712219238,
      -0.011505740694701672,
      0.04471550136804581,
      0.06137565150856972,
      -0.058172762393951416,
      0.031439658254384995,
      -0.11185180395841599,
      0.03164535388350487,
      0.04569728299975395,
      0.0038775019347667694,
      -0.036312516778707504,
      0.044991541653871536,
      0.04895399138331413,
      0.008774377405643463,
      0.030380498617887497,
      -0.02617928758263588,
      -0.04181544855237007,
      0.024449381977319717,
      0.054081715643405914,
      -0.0324660949409008,
      -0.011986443772912025,
      -0.018805095925927162,
      0.03550664708018303,
      -0.010436467826366425,
      -0.02892020344734192,
      -0.11600033938884735,
      0.06809910386800766,
      0.019988708198070526,
      0.08818204700946808,
      0.009566119872033596,
      -0.003078953130170703,
      -0.019183281809091568,
      0.0798695906996727,
      -0.0067232465371489525,
      0.031982891261577606,
      0.04835513234138489,
      -0.021030548959970474,
      -0.011108510196208954,
      -0.050496906042099,
      -0.06716883182525635,
      -0.003151868935674429,
      0.010146279819309711,
      0.012350163422524929,
      0.07415129244327545,
      0.05731838569045067,
      0.06724853068590164,
      0.04993071407079697,
      -0.013468391261994839,
      -0.07808626443147659,
      -0.010196642950177193,
      0.009491459466516972,
      -0.027342526242136955,
      0.01050751656293869,
      0.03988056257367134,
      0.07027919590473175,
      0.04080219194293022,
      -0.14030393958091736,
      -0.029006794095039368,
      0.02375974878668785,
      0.00038449125713668764,
      0.03642700985074043,
      0.00955892726778984,
      -0.058753304183483124,
      0.005863525904715061,
      -0.0007483825320377946,
      0.00841460283845663,
      -0.09284186363220215,
      -0.0018944870680570602,
      0.054183389991521835,
      0.016252385452389717,
      0.08642467856407166,
      -0.01736937277019024,
      0.01765160821378231,
      0.10346295684576035,
      0.06861851364374161,
      0.061856795102357864,
      -0.0257137268781662,
      -0.016439849510788918,
      -0.02929953671991825,
      0.04401196911931038,
      -0.019033949822187424,
      -0.02738003619015217,
      -0.12409647554159164,
      0.008211692795157433,
      0.013889708556234837,
      -0.01529212761670351,
      -0.011127747595310211,
      0.04965062066912651,
      0.00696943374350667,
      -0.009714140556752682,
      0.007008514832705259,
      -0.10575085878372192,
      -0.012909358367323875,
      0.01153428852558136,
      -0.11790651828050613,
      0.02124621905386448,
      -0.09407204389572144,
      0.020830435678362846,
      0.06769111007452011,
      0.028761837631464005,
      0.07769036293029785,
      0.03510096296668053,
      -0.04383684694766998,
      0.032986339181661606,
      -0.00869248528033495,
      0.018019482493400574,
      -0.028240911662578583,
      -0.020779628306627274,
      -0.002092737704515457,
      -0.08228050172328949,
      4.011817873106338e-05,
      0.01913658156991005,
      -0.027717702090740204,
      0.027539551258087158,
      0.03056301176548004,
      0.03616267815232277,
      -0.03467733785510063,
      0.08028794825077057
    ],
    [
      -0.025452742353081703,
      0.015349302440881729,
      -0.01832374557852745,
      0.06947996467351913,
      0.02716773934662342,
      -0.012455900199711323,
      -0.016546590253710747,
      -0.053856875747442245,
      0.03027905710041523,
      0.09334030002355576,
      0.007132144179195166,
      0.03142286092042923,
      -0.011541525833308697,
      -0.022922977805137634,
      -0.05192505195736885,
      -0.09235304594039917,
      -0.0248077604919672,
      0.020351290702819824,
      -0.0037164981476962566,
      -0.030217362567782402,
      0.001800745609216392,
      0.030226942151784897,
      0.008728217333555222,
      -0.03456579148769379,
      0.0272994264960289,
      -0.06376941502094269,
      0.015621252357959747,
      -0.040758583694696426,
      -0.07871522009372711,
      0.024610674008727074,
      -0.015356549061834812,
      -0.03564358130097389,
      -0.0215039923787117,
      -0.018686911091208458,
      -0.016201043501496315,
      0.011997218243777752,
      -0.01635454036295414,
      0.05172351375222206,
      -0.011464200913906097,
      -0.07286764681339264,
      0.020041514188051224,
      0.04087361693382263,
      -0.030372504144906998,
      -0.03862355649471283,
      0.05478960648179054,
      -0.03396220505237579,
      0.0722610279917717,
      0.0953863114118576,
      0.03242606669664383,
      -0.05490042641758919,
      -0.029967332258820534,
      -0.04483842849731445,
      0.1445755660533905,
      -0.00582636846229434,
      0.023513955995440483,
      -0.0606621615588665,
      -0.026333972811698914,
      -0.010298360139131546,
      0.04827715829014778,
      -0.046226050704717636,
      -0.04297119751572609,
      -0.017129501327872276,
      0.08270811289548874,
      0.02389461360871792,
      0.0013295868411660194,
      0.016202595084905624,
      -0.03778514266014099,
      -0.025680525228381157,
      0.07935512810945511,
      -0.004190428648144007,
      -0.04740387946367264,
      -0.03283561393618584,
      -0.05762561038136482,
      -0.03674165904521942,
      0.052154701203107834,
      0.015910319983959198,
      -0.022477643564343452,
      -0.05841876566410065,
      -0.01251306850463152,
      -0.0009721441892907023,
      -0.04623103886842728,
      -0.022211678326129913,
      0.008382170461118221,
      -0.07653681188821793,
      -0.004220460541546345,
      -0.038288507610559464,
      0.012109433300793171,
      0.012491447851061821,
      -0.016073571518063545,
      -0.04433852434158325,
      0.06256139278411865,
      0.02645946480333805,
      -0.018766995519399643,
      -0.014932859688997269,
      -0.04467087984085083,
      0.0727081447839737,
      0.010658858343958855,
      0.01109149120748043,
      0.027734730392694473,
      -0.047103431075811386,
      -0.11123418062925339,
      -0.07883089035749435,
      -0.04085012152791023,
      -0.0009493542602285743,
      -0.015433339402079582,
      -0.023032356053590775,
      -0.08399197459220886,
      0.04024418070912361,
      -0.03978823870420456,
      -0.039387304335832596,
      -0.002099281642585993,
      0.0171860009431839,
      -0.08733485639095306,
      -0.02039276622235775,
      -0.0014535033842548728,
      -0.010503355413675308,
      0.01643093302845955,
      -0.054257720708847046,
      0.09573016315698624,
      -0.016208956018090248,
      0.09026724100112915,
      0.011352062225341797,
      0.022097181528806686,
      -0.004915174096822739,
      0.022317206487059593,
      0.04393023997545242,
      -0.029423819854855537,
      -0.002552660182118416,
      -0.05312527343630791,
      0.044235408306121826,
      0.03460990637540817,
      -0.02364671789109707,
      0.04733388125896454,
      0.02662755362689495,
      0.07158541679382324,
      0.02482101321220398,
      -0.007322218269109726,
      0.02224084734916687,
      -0.015444694086909294,
      0.062145791947841644,
      0.016489360481500626,
      -0.010567311197519302,
      0.006064750719815493,
      0.04503917321562767,
      0.032405026257038116,
      -0.052398595958948135,
      0.05129362270236015,
      0.026233850046992302,
      -0.015036828815937042,
      0.05147773027420044,
      0.041719771921634674,
      0.005295748822391033,
      0.01217823289334774,
      0.02866004966199398,
      0.03400222584605217,
      -0.013832397758960724,
      0.010701634921133518,
      -0.049512166529893875,
      -0.03616411238908768,
      0.03915427625179291,
      -0.054737284779548645,
      -0.008305657655000687,
      -0.02001914381980896,
      0.002964946674183011,
      0.05555982515215874,
      0.01807221956551075,
      -0.021935604512691498,
      0.03171816095709801,
      -0.08571738004684448,
      0.001630566082894802,
      0.0021891542710363865,
      0.016497669741511345,
      -0.014620472677052021,
      -0.13549058139324188,
      -0.058748941868543625,
      -0.03896155208349228,
      0.07432246953248978,
      0.02151486836373806,
      -0.058294158428907394,
      -0.035826176404953,
      0.0483054555952549,
      0.07131891697645187,
      -0.048014819622039795,
      -0.04162352904677391,
      0.04126559942960739,
      0.02513701468706131,
      0.0074595073238015175,
      0.015441534109413624,
      -0.023080401122570038,
      -0.05196167156100273,
      0.013859095051884651,
      0.008884228765964508,
      0.03044990263879299,
      0.061668768525123596,
      0.05551045760512352,
      0.03768172860145569,
      0.09462007135152817,
      0.05835188552737236,
      0.012421851977705956,
      0.025862853974103928,
      0.01087141502648592,
      0.004747374448925257,
      0.05869178846478462,
      -0.09136581420898438,
      -0.06189730390906334,
      -0.01029970869421959,
      0.0060336110182106495,
      -0.03321702033281326,
      -0.026376647874712944,
      -0.01800110563635826,
      0.024490714073181152,
      -0.029214031994342804,
      -0.05643210560083389,
      -0.03232329711318016,
      0.0048256972804665565,
      0.0035299602895975113,
      -0.025103606283664703,
      0.0441182404756546,
      0.028317194432020187,
      -0.030141593888401985,
      0.017348138615489006,
      -0.021602222695946693,
      0.031124869361519814,
      -0.031328268349170685,
      0.008141760714352131,
      0.006471422966569662,
      0.02114660106599331,
      -0.04050225764513016,
      0.05131928622722626,
      0.09701543301343918,
      -0.038202691823244095,
      0.051649827510118484,
      0.04158369451761246,
      -0.04149436205625534,
      -0.06467926502227783,
      -0.09580323845148087,
      0.0076629905961453915,
      -0.012001989409327507,
      -0.03784894570708275,
      0.01628727652132511,
      0.05867563933134079,
      -0.06569696962833405,
      -0.0561930313706398,
      -0.0009565888321958482,
      0.003067478770390153,
      -0.05726226046681404,
      -0.03449907898902893,
      -0.009762978181242943,
      -0.035217490047216415,
      -0.00994044542312622,
      -0.01603723131120205,
      -0.07037850469350815,
      -0.05448140576481819,
      0.058009497821331024,
      0.005137230269610882,
      0.049530964344739914,
      0.045692987740039825,
      0.02894396334886551,
      -0.03234027326107025,
      -0.06481464952230453,
      -0.003026168094947934,
      -0.037164293229579926,
      0.017818443477153778,
      -0.11198358237743378,
      0.030389580875635147,
      -0.04209567978978157,
      -0.1048332080245018,
      0.10329058766365051,
      -0.023693403229117393,
      0.04820668697357178,
      -0.01740763522684574,
      0.13898755609989166,
      -0.032681904733181,
      0.007572823669761419,
      0.03616776689887047,
      -0.03150666505098343,
      0.0017534884391352534,
      0.05532490462064743,
      0.08005966991186142,
      0.00016035458247642964,
      -0.06609601527452469,
      -0.06301534920930862,
      -0.044086962938308716,
      -0.04478840529918671,
      0.1047295480966568,
      0.033851947635412216,
      0.015229306183755398,
      -0.04457639530301094,
      0.028090449050068855,
      -0.08479071408510208,
      -0.06146247684955597,
      -0.11670906096696854,
      0.03538817912340164,
      -0.0845693051815033,
      -0.017220446839928627,
      -0.01584482751786709,
      -0.07692936807870865,
      -0.008264489471912384,
      -0.03593411669135094,
      -0.07495524734258652,
      0.05560934543609619,
      0.07835566997528076,
      0.030584685504436493,
      -0.03513242304325104,
      -0.002896709833294153,
      0.0008244800847023726,
      0.056388337165117264,
      -0.07681883871555328,
      0.023047881200909615,
      -0.04081578180193901,
      0.03323861211538315,
      0.07125028222799301,
      -0.0029459460638463497,
      -0.044725243002176285,
      0.05277513340115547,
      -0.013175277039408684,
      0.06981761753559113,
      0.009408478625118732,
      -0.009535010904073715,
      0.022111177444458008,
      -0.0021860969718545675,
      -0.01999531500041485,
      -0.02433726191520691,
      0.008908990770578384,
      -0.03851925954222679,
      0.0008334643789567053,
      0.0010758687276393175,
      0.004334073513746262,
      -0.07666170597076416,
      -0.008976738899946213,
      0.02070012129843235,
      -0.005147031042724848,
      -0.028432412073016167,
      -0.05593853443861008,
      0.006131671369075775,
      -0.0037913566920906305,
      -0.05779191479086876,
      0.047543708235025406,
      0.043090686202049255,
      0.0161132849752903,
      -0.03977343440055847,
      -0.031051019206643105,
      -0.031001534312963486,
      0.03687256947159767,
      0.013207126408815384,
      -0.059478797018527985,
      -0.037497974932193756,
      0.049453623592853546,
      -0.05341717228293419,
      -0.025711653754115105,
      0.0686740130186081,
      0.04685619845986366,
      -0.031750425696372986,
      -0.00672171451151371,
      0.0018397135427221656,
      0.05818178132176399,
      -0.015836674720048904,
      -0.04393627494573593,
      -0.07770217955112457,
      -0.03454042971134186,
      -0.004333852790296078,
      0.01346641220152378,
      0.030453508719801903,
      -0.03540526330471039,
      -0.03670142590999603,
      -0.08757919073104858,
      0.0642465129494667,
      0.03674079850316048,
      0.0023835026659071445,
      -0.04097625985741615,
      0.012233376502990723,
      -0.0807255357503891,
      0.017284616827964783,
      0.029630251228809357,
      0.01839503087103367,
      -0.005334336776286364,
      0.018339062109589577,
      0.031037503853440285,
      -0.06270720064640045,
      -0.02092757262289524,
      -0.048047032207250595,
      0.07385959476232529,
      -0.08271029591560364,
      -0.02219509333372116,
      0.033052925020456314,
      0.07330513745546341,
      -0.06780260801315308,
      -0.05206798017024994,
      0.08113742619752884,
      -0.017817087471485138,
      0.04297032952308655,
      0.033703695982694626,
      -0.07822325825691223,
      0.05820339918136597,
      -0.02944173850119114,
      -0.053061097860336304,
      0.056990526616573334,
      0.07048123329877853,
      0.021869972348213196,
      0.031202396377921104,
      -0.024627702310681343,
      0.02032981626689434,
      0.041777603328228,
      -0.014681556262075901,
      -0.050356242805719376,
      -0.060056887567043304,
      0.0040923478081822395,
      -0.06506101042032242,
      0.09752217680215836,
      -0.007310486864298582,
      0.017849087715148926,
      -0.0320134274661541,
      -0.012002321891486645,
      0.04242788627743721,
      0.017811298370361328,
      0.006792609114199877,
      0.009330036118626595,
      0.0031533041037619114,
      0.02710004709661007,
      -0.029222872108221054,
      -0.04527169466018677,
      0.05761808156967163,
      0.08943835645914078,
      0.02736043557524681,
      -0.014012038707733154,
      -0.025886941701173782,
      0.013564656488597393,
      0.05409650877118111,
      0.027072027325630188,
      0.09495273232460022,
      -0.053066156804561615,
      0.07055683434009552,
      -0.06751079112291336,
      0.1160958781838417,
      0.037840694189071655,
      -0.06301447004079819,
      -0.02403518743813038,
      0.06510312855243683,
      -0.059317827224731445,
      0.01860564574599266,
      -0.07949316501617432,
      0.02240479737520218,
      -0.06624478101730347,
      -0.0310700461268425,
      -0.00022662192350253463,
      0.010539030656218529,
      -0.057619404047727585,
      -0.05366784706711769,
      0.0010282723233103752,
      -0.08781222254037857,
      0.0006157110328786075,
      -0.00805769395083189,
      -0.006399177946150303,
      0.034089189022779465,
      -0.09585942327976227,
      0.04128653183579445,
      -0.027096007019281387,
      -0.00930064357817173,
      -0.08734064549207687,
      0.060282979160547256,
      -0.00896152202039957,
      0.027261076495051384,
      -0.04433504864573479,
      -0.018771497532725334,
      0.04035075381398201,
      0.07035334408283234,
      0.028249764814972878,
      -0.07261823862791061,
      0.17877168953418732,
      -0.010803792625665665,
      -0.02118406444787979,
      -0.0015654417220503092,
      -0.03353310003876686,
      0.002623863285407424,
      0.0675254538655281,
      0.00233808858320117,
      -0.045041732490062714,
      0.04283646121621132,
      0.024649670347571373,
      0.004557730630040169,
      -0.07080157846212387,
      -0.03791195526719093,
      0.059905845671892166,
      -0.029041016474366188,
      -0.007432312238961458,
      -0.0474981814622879,
      -0.025211306288838387,
      -0.08211619406938553,
      0.03217359632253647,
      -0.03763866052031517,
      0.06137971580028534,
      -0.08388611674308777,
      -0.047637246549129486,
      0.004550820682197809,
      0.030880464240908623,
      0.03660637140274048,
      -0.02601342462003231,
      0.033087313175201416,
      0.006230191793292761,
      -0.02112761326134205,
      -0.01657639816403389,
      0.0428071953356266,
      -0.019168872386217117
    ],
    [
      0.01794704608619213,
      -0.025390783324837685,
      -0.01145539153367281,
      0.03150564432144165,
      -0.012374034151434898,
      -0.04495968669652939,
      0.004688868299126625,
      -0.022093171253800392,
      -0.027204539626836777,
      -0.07168515771627426,
      0.027844073250889778,
      0.0011886017164215446,
      0.1505269855260849,
      0.020763346925377846,
      -0.013087478466331959,
      0.007829560898244381,
      -0.017900874838232994,
      0.062263403087854385,
      0.014896023087203503,
      0.012748702429234982,
      -0.06698455661535263,
      0.0048464941792190075,
      0.030344631522893906,
      0.005457127001136541,
      0.008234834298491478,
      -0.0017400742508471012,
      0.028223641216754913,
      -0.0015809027245268226,
      -0.014027462340891361,
      0.07458063960075378,
      0.004429973661899567,
      0.013223255053162575,
      -0.008956722915172577,
      -0.052761781960725784,
      0.012698149308562279,
      -0.013351347297430038,
      0.02009318768978119,
      -0.009838263504207134,
      -0.030472587794065475,
      0.006921153515577316,
      -0.02266835793852806,
      0.028475061058998108,
      -0.016154110431671143,
      -0.005615421570837498,
      -0.016734449192881584,
      -0.021710854023694992,
      0.029641935601830482,
      -0.0256037637591362,
      -0.04790220782160759,
      0.06169545650482178,
      -0.027544880285859108,
      -0.038922492414712906,
      0.06982458382844925,
      0.011981912888586521,
      -0.09026654809713364,
      0.0034818309359252453,
      -0.015701934695243835,
      0.04389331117272377,
      0.009660235606133938,
      -0.007043807301670313,
      -0.021602345630526543,
      -0.03704216703772545,
      0.04120558872818947,
      0.02732457034289837,
      -0.052511073648929596,
      0.02896467037498951,
      -0.043629638850688934,
      0.03635145723819733,
      -0.04372010752558708,
      -0.024736659601330757,
      0.10877001285552979,
      0.05830616503953934,
      0.02051440067589283,
      0.008297959342598915,
      -0.008261720649898052,
      -0.005743011832237244,
      -0.012318678200244904,
      -0.027684025466442108,
      0.002164278645068407,
      0.07211309671401978,
      -0.05284469202160835,
      0.010456083342432976,
      0.028321536257863045,
      0.023860348388552666,
      -0.11082951724529266,
      -0.023086218163371086,
      -0.06732124835252762,
      -0.050996746867895126,
      -0.0579642727971077,
      0.015901075676083565,
      -0.043135207146406174,
      0.01678861677646637,
      0.020872944965958595,
      0.07290715724229813,
      0.025069355964660645,
      -0.03635444864630699,
      0.033133454620838165,
      -0.022704903036355972,
      0.05155419558286667,
      0.04704516381025314,
      -0.01849842444062233,
      -0.0605832003057003,
      0.02713996358215809,
      -0.004653511103242636,
      0.07851310074329376,
      -0.1300327479839325,
      0.006719832308590412,
      -0.06106896325945854,
      0.0017796242609620094,
      0.06952910870313644,
      0.03061995841562748,
      -0.02073816955089569,
      0.020837029442191124,
      0.06373275816440582,
      0.010035587474703789,
      0.007363544777035713,
      -0.018529308959841728,
      0.03434351459145546,
      -0.017290281131863594,
      0.037076015025377274,
      0.04707568511366844,
      0.0071004703640937805,
      0.026968229562044144,
      0.010066828690469265,
      -0.07396882772445679,
      0.02281460165977478,
      0.003920533694326878,
      0.030520912259817123,
      -0.05786658078432083,
      -0.054470352828502655,
      0.013136661611497402,
      0.06233271211385727,
      0.008963258937001228,
      0.03446555882692337,
      0.05185317620635033,
      0.009379723109304905,
      -0.024811770766973495,
      -0.023259300738573074,
      0.04513490945100784,
      -0.03959391638636589,
      0.002617379417642951,
      0.04271242767572403,
      -0.04994048550724983,
      0.04888050630688667,
      0.02574699930846691,
      -0.014583319425582886,
      -0.09034693241119385,
      -0.015636097639799118,
      -0.023438315838575363,
      0.030565816909074783,
      0.0013262926368042827,
      0.11355867981910706,
      -0.03546116501092911,
      0.0035216244868934155,
      0.08203040808439255,
      0.028487205505371094,
      0.034507330507040024,
      0.025531070306897163,
      -0.03642605245113373,
      0.004565475974231958,
      -0.02378995344042778,
      -0.014212016016244888,
      -0.007309458218514919,
      -0.0021149376407265663,
      0.049241840839385986,
      0.08191320300102234,
      0.011019195429980755,
      0.02884235605597496,
      -0.01699971966445446,
      -0.014549119397997856,
      0.017906416207551956,
      -0.042210064828395844,
      0.06474488228559494,
      -0.05064145848155022,
      -0.04156208783388138,
      0.03294745832681656,
      0.022199219092726707,
      0.0749744400382042,
      0.012324266135692596,
      -0.0894121304154396,
      0.016338331624865532,
      0.024763323366642,
      0.09764629602432251,
      0.024027734994888306,
      -0.0012689126888290048,
      -0.0226554274559021,
      -0.0897684395313263,
      0.08474726229906082,
      -0.012713557109236717,
      0.0170060433447361,
      -0.013406342826783657,
      -0.03623690456151962,
      0.058041639626026154,
      -0.01636083796620369,
      0.03573333099484444,
      -0.038563575595617294,
      0.030331220477819443,
      0.006859596353024244,
      -0.0008832718594931066,
      -0.0046026986092329025,
      0.1082562506198883,
      -0.012766062282025814,
      0.08326657116413116,
      -0.1389312595129013,
      -0.05290529131889343,
      -0.10539618879556656,
      -0.04514541104435921,
      0.01972627080976963,
      -0.04006743058562279,
      -0.004476587288081646,
      -0.11926385760307312,
      -0.005272988695651293,
      0.030513571575284004,
      0.004959415644407272,
      -0.03427847847342491,
      0.023932065814733505,
      0.049371879547834396,
      0.00745728425681591,
      -0.02040749602019787,
      -0.11882613599300385,
      0.017658807337284088,
      -0.042353518307209015,
      0.060011640191078186,
      -0.005151043180376291,
      0.042356349527835846,
      0.05131244659423828,
      -0.025563247501850128,
      0.048593901097774506,
      -0.003988260868936777,
      0.08401065319776535,
      0.05774572491645813,
      0.023859379813075066,
      0.04472268000245094,
      -0.004852825775742531,
      0.005767619237303734,
      0.03793630003929138,
      -0.013695948757231236,
      -0.13695140182971954,
      -0.015941565856337547,
      -0.016974935308098793,
      -0.05864306539297104,
      -0.05582107603549957,
      -0.018687812611460686,
      -0.06252686679363251,
      -0.00060281902551651,
      -0.05626862496137619,
      -0.0064151897095143795,
      -0.10020729899406433,
      0.01119014248251915,
      0.007400752045214176,
      -0.04129701480269432,
      0.04309460520744324,
      -0.06172218173742294,
      -0.07800401002168655,
      0.07165270298719406,
      -0.06328366696834564,
      -0.04841375723481178,
      0.10893362760543823,
      0.01959560625255108,
      0.012715020217001438,
      0.01908055879175663,
      -0.049988240003585815,
      -0.059470284730196,
      0.006168846506625414,
      -0.031187355518341064,
      -0.02497953549027443,
      0.08143511414527893,
      -0.0005263355560600758,
      -0.02167409658432007,
      -0.03900665417313576,
      0.027819562703371048,
      -0.029353538528084755,
      0.012133772484958172,
      0.013717412948608398,
      0.06804190576076508,
      -0.03076331689953804,
      -2.391347697994206e-05,
      -0.028150655329227448,
      -0.00013664699508808553,
      0.005266781430691481,
      -0.007326054386794567,
      0.017985600978136063,
      -0.026738854125142097,
      -0.09563342481851578,
      -0.013526485301554203,
      -0.14262811839580536,
      -0.006267013493925333,
      -0.038324132561683655,
      -0.031150327995419502,
      0.030844122171401978,
      -0.00903741642832756,
      -0.07726528495550156,
      0.01194737758487463,
      -0.013820725493133068,
      -0.006557639688253403,
      0.031420283019542694,
      -0.08913014829158783,
      -0.05237454175949097,
      0.03339796140789986,
      0.10523344576358795,
      0.0034321178682148457,
      -0.033174626529216766,
      0.02345454879105091,
      -0.02531180903315544,
      -0.009189695119857788,
      0.02315463498234749,
      -0.021898727864027023,
      0.04436912387609482,
      0.04200507327914238,
      -0.09796443581581116,
      -0.04019610956311226,
      0.036509979516267776,
      0.005553048104047775,
      -0.009398589842021465,
      0.004652650561183691,
      0.018338192254304886,
      0.044790416955947876,
      -0.0125194750726223,
      0.029251039028167725,
      -0.011377161368727684,
      -0.07483434677124023,
      0.0023903767578303814,
      0.014114519581198692,
      0.04816339537501335,
      0.009673415683209896,
      -0.02609219029545784,
      0.0292840376496315,
      0.051908597350120544,
      -0.016181521117687225,
      -0.04885242134332657,
      0.03149321675300598,
      0.001443157671019435,
      -0.017553230747580528,
      0.047983262687921524,
      0.045338768512010574,
      -0.0553494393825531,
      -0.024310894310474396,
      -0.0917370542883873,
      0.06725860387086868,
      0.044283676892519,
      -0.10564824938774109,
      -0.04358815774321556,
      0.03855578973889351,
      -0.0820959284901619,
      -0.03302678093314171,
      0.05192077159881592,
      0.025636354461312294,
      0.060516491532325745,
      -0.022387854754924774,
      0.026776958256959915,
      0.012813069857656956,
      0.030746957287192345,
      0.04809452220797539,
      0.0643836259841919,
      -0.0020841353107243776,
      -0.00524968421086669,
      0.011197654530405998,
      0.03747827559709549,
      -0.13419565558433533,
      0.08193667232990265,
      -0.039358749985694885,
      0.010450143367052078,
      0.022095272317528725,
      0.003197481157258153,
      0.05137142539024353,
      -0.014967034570872784,
      0.054182253777980804,
      0.009790590964257717,
      0.012915018014609814,
      0.08611027896404266,
      0.031149234622716904,
      -0.025454437360167503,
      0.021468674764037132,
      -0.03006581962108612,
      -0.041838083416223526,
      -0.056409802287817,
      -0.09357854723930359,
      0.07707677036523819,
      0.01916932500898838,
      -0.030422508716583252,
      -0.019199876114726067,
      -0.031684510409832,
      0.06972247362136841,
      0.008216263726353645,
      0.045962296426296234,
      0.015179465524852276,
      0.016177410259842873,
      0.02423701249063015,
      0.019634544849395752,
      0.014660859480500221,
      0.03966525197029114,
      -0.002187277190387249,
      0.052577465772628784,
      -0.08169170469045639,
      0.04768873378634453,
      -0.06414786726236343,
      0.0025403297040611506,
      -0.045551225543022156,
      -0.07104364037513733,
      -0.01809873804450035,
      -0.0018307239515706897,
      -0.024051129817962646,
      0.030663203448057175,
      -0.03887057304382324,
      0.012153669260442257,
      -0.022366158664226532,
      -0.009178358130156994,
      0.057293057441711426,
      -0.017719829455018044,
      0.043852224946022034,
      -0.015169981867074966,
      0.0382428877055645,
      -0.044432707130908966,
      0.050158414989709854,
      0.07396216690540314,
      -0.010221363045275211,
      0.045696601271629333,
      0.03426250070333481,
      0.03942890465259552,
      0.0546797513961792,
      0.039531733840703964,
      0.020317070186138153,
      -0.03375404700636864,
      0.014444297179579735,
      -0.0301990769803524,
      0.014648279175162315,
      -0.05405261367559433,
      0.002318168757483363,
      -0.029699286445975304,
      0.06717195361852646,
      0.053918179124593735,
      -0.03182056546211243,
      -0.015870871022343636,
      0.042055658996105194,
      -0.0015146918594837189,
      0.056110091507434845,
      -0.03791214898228645,
      0.0029061315581202507,
      -0.00923269521445036,
      0.012882644310593605,
      0.0020805506501346827,
      -0.004069166257977486,
      -0.03934924677014351,
      0.08350350707769394,
      -0.003886786289513111,
      0.025847623124718666,
      -0.0025615941267460585,
      -0.003225077874958515,
      -0.04749307408928871,
      -0.02265559323132038,
      0.009372674860060215,
      -0.029404975473880768,
      -0.01734713651239872,
      -0.052931785583496094,
      0.0255193542689085,
      0.0615726038813591,
      -0.03246395289897919,
      0.06383337825536728,
      0.05427516996860504,
      -0.004186933394521475,
      0.000921618368010968,
      0.006581942550837994,
      -0.0013750552898272872,
      -0.024023331701755524,
      -0.004032612312585115,
      0.006025515031069517,
      -0.030469978228211403,
      -0.009088282473385334,
      0.019048256799578667,
      0.005365211982280016,
      -0.0011924202553927898,
      0.09041626006364822,
      -0.05232331156730652,
      0.012060246430337429,
      -0.020227784290909767,
      0.04529428482055664,
      -0.07331714034080505,
      -0.04388924315571785,
      0.13114483654499054,
      -0.035411685705184937,
      -0.038054510951042175,
      -0.035971637815237045,
      -0.03808159381151199,
      -0.0073912180960178375,
      0.11937979608774185,
      0.024616090580821037,
      0.029044803231954575,
      0.012228635139763355,
      0.030441639944911003,
      -0.013753211125731468,
      -0.026822615414857864,
      0.03933754190802574,
      -0.01318451575934887,
      0.01827595941722393,
      0.02580217830836773,
      0.14177562296390533,
      0.006329293828457594,
      -0.06610207259654999,
      -0.059871185570955276,
      0.03405527025461197,
      0.043858930468559265,
      0.018444636836647987,
      -0.03747830167412758
    ],
    [
      0.0249958299100399,
      0.13627254962921143,
      0.010540313087403774,
      0.03106222301721573,
      0.02057553268969059,
      0.09239739924669266,
      0.01252507884055376,
      0.049614645540714264,
      0.09672784805297852,
      -0.03550788760185242,
      0.026686888188123703,
      0.006451159715652466,
      0.020802481099963188,
      0.07014698535203934,
      0.003362322924658656,
      0.05173836275935173,
      0.018211696296930313,
      0.0011411969317123294,
      -0.019836219027638435,
      -0.16516803205013275,
      0.027751483023166656,
      0.01014880184084177,
      0.011367110535502434,
      0.06346109509468079,
      0.043329525738954544,
      -0.035533491522073746,
      -0.0441269725561142,
      -0.03835812211036682,
      -0.040598247200250626,
      -0.007113956846296787,
      0.09118914604187012,
      -0.04966915026307106,
      0.10318651050329208,
      -0.03216886892914772,
      0.018125390633940697,
      -0.006006922107189894,
      -0.11551514267921448,
      0.04712974280118942,
      -0.011141542345285416,
      0.021249890327453613,
      0.0264741163700819,
      0.11514906585216522,
      0.00923197716474533,
      0.04123900458216667,
      0.043768055737018585,
      -0.11153598874807358,
      0.08683166652917862,
      0.041857581585645676,
      0.04114197939634323,
      -0.03935651853680611,
      0.01818111166357994,
      0.0205683596432209,
      -0.020420333370566368,
      -0.017827223986387253,
      0.047111302614212036,
      0.09488796442747116,
      -0.003413574071601033,
      -0.06443247944116592,
      0.14364179968833923,
      -0.05754135176539421,
      -0.017193220555782318,
      -0.012572971172630787,
      0.022738246247172356,
      0.0042189424857497215,
      -0.06432508677244186,
      0.000465563585748896,
      0.01206876989454031,
      -0.06226746737957001,
      0.0007417802116833627,
      -0.05160852521657944,
      0.03632688522338867,
      -0.034846220165491104,
      0.05341693013906479,
      -0.03588331490755081,
      -0.06477779895067215,
      0.08622981607913971,
      0.027582267299294472,
      0.0333828330039978,
      0.056868989020586014,
      -0.004005507566034794,
      0.08478439599275589,
      0.015342256054282188,
      -0.04727417603135109,
      -0.005854782648384571,
      -0.0022217773366719484,
      -0.016754450276494026,
      0.009679628536105156,
      -0.016718976199626923,
      0.02088409662246704,
      0.038296960294246674,
      0.011100450530648232,
      0.06141708791255951,
      -0.051101747900247574,
      0.005509254056960344,
      -0.07450015097856522,
      -0.014818801544606686,
      0.05640045180916786,
      0.04945911094546318,
      -0.030796892940998077,
      -0.004291716031730175,
      -0.03341793641448021,
      0.029780423268675804,
      -0.06960084289312363,
      0.05574692040681839,
      0.0825604721903801,
      0.02123224176466465,
      0.016712630167603493,
      0.06659527868032455,
      0.04942335560917854,
      -0.11682833731174469,
      -0.12018655985593796,
      -0.011499213054776192,
      0.02692219614982605,
      -0.03979867324233055,
      0.05095736309885979,
      0.04579858481884003,
      0.09587687253952026,
      0.05069727078080177,
      0.021755849942564964,
      0.0049854158423841,
      0.06439783424139023,
      0.05723908171057701,
      -0.0038936641067266464,
      0.09076708555221558,
      0.00513925077393651,
      0.011800036765635014,
      -0.0012976749567314982,
      -0.037066906690597534,
      -0.0008497426169924438,
      0.008413384668529034,
      -0.04308905079960823,
      0.055078912526369095,
      -0.0043669953010976315,
      0.011538075283169746,
      -0.03210287168622017,
      -0.0708363875746727,
      -0.012838992290198803,
      -0.07064861059188843,
      -0.06976129114627838,
      -0.03862917050719261,
      0.0013267681933939457,
      0.01418396271765232,
      -0.019227204844355583,
      0.07167982310056686,
      0.02762189880013466,
      0.023376790806651115,
      -0.04311191290616989,
      -0.0076133678667247295,
      -0.06479834765195847,
      0.010593577288091183,
      -0.018769370391964912,
      0.09811759740114212,
      -0.006237069144845009,
      0.04125382751226425,
      0.07272390276193619,
      0.04393543675541878,
      -0.028856785967946053,
      0.0297922994941473,
      -0.03898466005921364,
      -0.006279533263295889,
      0.013024251908063889,
      0.056793343275785446,
      0.021994803100824356,
      0.00324628921225667,
      -0.07029706984758377,
      -0.01260467991232872,
      0.009178997948765755,
      -0.04746083542704582,
      -0.038042571395635605,
      0.07320306450128555,
      0.058505669236183167,
      -0.02634039893746376,
      0.03966370224952698,
      -0.06791790574789047,
      0.0034623006358742714,
      0.08713031560182571,
      -0.0011458552908152342,
      0.0213528610765934,
      -0.012025240808725357,
      -0.00325423339381814,
      -0.01616380177438259,
      -0.028056666254997253,
      -0.04418489336967468,
      0.0836915448307991,
      0.00040044885827228427,
      -0.061354395002126694,
      0.017298096790909767,
      0.011236952617764473,
      -0.004305551294237375,
      0.01946367137134075,
      0.08333056420087814,
      -0.008738857693970203,
      0.04607062414288521,
      -0.003755192970857024,
      -0.03969357907772064,
      -0.0649867057800293,
      -0.09416632354259491,
      0.019760098308324814,
      -0.04298575222492218,
      0.06760258227586746,
      -0.03969883173704147,
      -0.03923409804701805,
      0.05951252579689026,
      -0.04578549042344093,
      0.11159886419773102,
      0.017803017050027847,
      0.001972553553059697,
      -0.10980889201164246,
      -0.04407522827386856,
      0.007463525049388409,
      -0.0544193759560585,
      -0.08172107487916946,
      0.06246491149067879,
      -0.016133638098835945,
      -0.03703763708472252,
      -0.051760464906692505,
      -0.015207061544060707,
      0.007678918074816465,
      0.03447476029396057,
      0.0054705506190657616,
      0.03333047777414322,
      -0.07213305681943893,
      0.03541111573576927,
      0.06747813522815704,
      0.029097482562065125,
      -0.03475075960159302,
      0.04207060486078262,
      -0.06730080395936966,
      0.04506118223071098,
      -0.04654138162732124,
      0.05393216013908386,
      0.058799345046281815,
      0.01943739876151085,
      -0.07663308084011078,
      -0.0034499773755669594,
      -0.04806513339281082,
      -0.02370874024927616,
      -0.07530918717384338,
      -0.0462927371263504,
      -0.010101383551955223,
      -0.07327520847320557,
      -0.016413040459156036,
      -0.08048160374164581,
      -0.027604246512055397,
      -0.03982855752110481,
      -0.012025129050016403,
      0.04650377109646797,
      -0.04382504150271416,
      0.0021637501195073128,
      -0.0015544976340606809,
      0.041439805179834366,
      -0.05894327908754349,
      0.06652205437421799,
      0.019102895632386208,
      0.019585339352488518,
      0.003843858139589429,
      0.0011341329663991928,
      -0.06999286264181137,
      0.009156571701169014,
      0.039056044071912766,
      -0.004795222543179989,
      -0.09869688004255295,
      0.06694408506155014,
      0.0721082016825676,
      -0.04205717891454697,
      -0.009417722001671791,
      0.037103090435266495,
      0.07267862558364868,
      -0.035400185734033585,
      -0.003704753005877137,
      0.018438398838043213,
      0.01955958642065525,
      -0.03932412341237068,
      -0.056669604033231735,
      0.020190613344311714,
      0.01068871933966875,
      0.002922070911154151,
      -0.0038078632205724716,
      0.020487353205680847,
      -0.039627186954021454,
      -0.030501985922455788,
      0.02223299816250801,
      0.03007419779896736,
      -0.03958142176270485,
      -0.13201841711997986,
      0.03201073408126831,
      -0.005289408843964338,
      0.016202855855226517,
      -0.024942664429545403,
      -0.011319898068904877,
      -0.044051315635442734,
      -0.03946252539753914,
      -0.06133966147899628,
      -0.0031644415576010942,
      0.030428128316998482,
      -0.06408543884754181,
      0.03158488869667053,
      -0.06818178296089172,
      -0.07797821611166,
      0.008273473009467125,
      0.10771320015192032,
      0.0482499897480011,
      0.06273598968982697,
      -0.041482966393232346,
      0.06757694482803345,
      -0.06462716311216354,
      -0.022702127695083618,
      -0.04961622133851051,
      0.05909068137407303,
      0.03926125168800354,
      0.02874753065407276,
      -0.009037052281200886,
      -0.044562164694070816,
      -0.07999521493911743,
      0.02680443413555622,
      -0.05170636624097824,
      0.05282433331012726,
      -0.023516155779361725,
      0.016405612230300903,
      -0.022310597822070122,
      -0.08346589654684067,
      0.06820955872535706,
      -0.04734598100185394,
      -0.01994447410106659,
      0.009291511960327625,
      -0.12795618176460266,
      0.09659693390130997,
      -0.053594402968883514,
      0.05738695338368416,
      -0.050036825239658356,
      0.02166752889752388,
      0.08542091399431229,
      0.037817202508449554,
      0.019413765519857407,
      0.04418386518955231,
      -0.01267334446310997,
      -0.07287120819091797,
      0.012696377001702785,
      0.010760603472590446,
      0.04854055866599083,
      0.032921500504016876,
      0.030510636046528816,
      -0.025603394955396652,
      -0.04398500174283981,
      0.0375698022544384,
      -0.08221618831157684,
      0.02016768790781498,
      -0.02476349286735058,
      -0.07790914922952652,
      -0.08976475149393082,
      -0.03175945207476616,
      -0.007200900930911303,
      -0.014916028827428818,
      0.005100166890770197,
      -0.019916681572794914,
      -0.02915855310857296,
      -0.054298751056194305,
      -0.08101700246334076,
      0.051463305950164795,
      0.06645382940769196,
      -0.0025167237035930157,
      0.044273741543293,
      0.07468049228191376,
      -0.03980721905827522,
      0.013712967745959759,
      0.02302609384059906,
      0.018659640103578568,
      -0.03477190062403679,
      -0.024801185354590416,
      0.062240149825811386,
      0.044098734855651855,
      -0.03437376394867897,
      0.00661054253578186,
      0.040227845311164856,
      -0.07780437916517258,
      -0.07954879850149155,
      0.05880965664982796,
      0.03583765774965286,
      0.03365197032690048,
      -0.03046366572380066,
      0.04080280661582947,
      -0.048497237265110016,
      -0.020319513976573944,
      0.0077774301171302795,
      -0.0020126095041632652,
      0.04100286588072777,
      0.0016039017355069518,
      0.016722097992897034,
      -0.015286959707736969,
      -0.011046243831515312,
      0.028043057769536972,
      0.04322023689746857,
      0.03325909376144409,
      -0.00039024106808938086,
      0.002875560661777854,
      0.05958199128508568,
      0.021733833476901054,
      -0.05184747651219368,
      0.08350193500518799,
      0.026220353320240974,
      0.01175474002957344,
      -0.001342333503998816,
      -0.02909628488123417,
      -0.07616554945707321,
      0.05658070370554924,
      -0.010592244565486908,
      0.01793082058429718,
      0.06579253077507019,
      0.02963997982442379,
      0.01787949539721012,
      -0.0359732024371624,
      0.02298845909535885,
      0.05185139551758766,
      -0.028371183201670647,
      -0.01853550784289837,
      -0.03824003040790558,
      -0.05414043366909027,
      -0.059372786432504654,
      0.05894695594906807,
      0.015019992366433144,
      0.07947689294815063,
      0.017642440274357796,
      0.00985115859657526,
      -0.07110746204853058,
      -0.002523875329643488,
      -0.07425818592309952,
      0.000595851568505168,
      -0.016224045306444168,
      -0.003664282849058509,
      0.05175791680812836,
      -0.021137788891792297,
      0.015757810324430466,
      -0.013089995831251144,
      0.020706921815872192,
      -0.035235363990068436,
      -0.02730707824230194,
      -0.01604071632027626,
      0.030910903587937355,
      -0.03432851284742355,
      0.03066735900938511,
      -0.019838685169816017,
      -0.06539962440729141,
      -0.013667258433997631,
      -0.019639646634459496,
      -0.07212076336145401,
      0.059903834015131,
      0.07419051975011826,
      0.020875688642263412,
      0.027973463758826256,
      0.036952655762434006,
      -0.04727531224489212,
      -0.04154917597770691,
      -0.06295683979988098,
      0.0487264022231102,
      -0.01790333166718483,
      0.08470579981803894,
      0.03149056062102318,
      -0.09939497709274292,
      -0.021747920662164688,
      0.0030022035352885723,
      0.03829755634069443,
      -0.029012074694037437,
      -0.054720718413591385,
      0.04452363774180412,
      0.07506892830133438,
      -0.005569579545408487,
      -0.04161657392978668,
      0.011249194853007793,
      0.052274323999881744,
      -0.0012565695215016603,
      -0.03322507068514824,
      0.020806383341550827,
      -0.06324013322591782,
      0.009214670397341251,
      -0.0014079702086746693,
      -0.0375833697617054,
      -0.031677186489105225,
      0.016781024634838104,
      0.06512577086687088,
      0.028876861557364464,
      0.05149223282933235,
      0.007750764489173889,
      -0.0011357185430824757,
      -0.07496681064367294,
      -0.047322459518909454,
      -0.010987112298607826,
      -0.06759706139564514,
      -0.016602301970124245,
      -0.029664896428585052,
      -0.04913469776511192,
      0.008238792419433594,
      0.10595908015966415,
      -0.01214842963963747,
      -0.031769730150699615,
      -0.009546857327222824,
      -0.058020319789648056,
      0.00261822366155684,
      0.012469173409044743,
      0.0557708740234375,
      0.04729568958282471,
      -0.00832478329539299,
      0.0002326021931366995,
      -0.10929932445287704
    ],
    [
      -0.017171073704957962,
      -0.07794016599655151,
      0.03231526166200638,
      -0.03738415241241455,
      -0.026203036308288574,
      0.026860712096095085,
      0.04245362430810928,
      0.03814303129911423,
      0.003189574694260955,
      0.014918599277734756,
      -0.004662712104618549,
      0.03516140580177307,
      -0.14373809099197388,
      0.006380762904882431,
      -0.05873691290616989,
      -0.018449220806360245,
      0.009508985094726086,
      -0.02234623394906521,
      0.02759663574397564,
      -0.01129294652491808,
      0.04855936020612717,
      -0.0011490564793348312,
      -0.03986680507659912,
      0.06697988510131836,
      -0.02229466661810875,
      -0.023906737565994263,
      -0.017767179757356644,
      -0.06078231334686279,
      -7.02564102539327e-06,
      0.14044289290905,
      0.011051111854612827,
      0.023567844182252884,
      0.013241499662399292,
      0.057232003659009933,
      0.06088860705494881,
      -0.04782899469137192,
      -0.014898151159286499,
      0.0538092665374279,
      0.027917830273509026,
      -0.012139687314629555,
      -0.004638629034161568,
      0.03459756448864937,
      -0.03962131217122078,
      -0.054098132997751236,
      0.04162701591849327,
      -0.02095012553036213,
      0.051218703389167786,
      -0.012560814619064331,
      0.05139537528157234,
      -0.037562087178230286,
      -0.05202404037117958,
      -0.010747815482318401,
      0.006620469968765974,
      -0.025146769359707832,
      0.06997596472501755,
      -0.01284467987716198,
      0.04127821698784828,
      0.03163270652294159,
      -0.01173019502311945,
      0.01793188974261284,
      0.010831780731678009,
      0.04551011696457863,
      -0.019462380558252335,
      0.036461226642131805,
      -0.0715804472565651,
      -0.03483889624476433,
      -0.050977885723114014,
      -0.03512226417660713,
      -0.07779490202665329,
      -0.020541025325655937,
      0.01568673737347126,
      -0.04785728454589844,
      0.0016682030400261283,
      -0.01671915501356125,
      0.020939625799655914,
      -0.043874241411685944,
      -0.01901182346045971,
      -0.03852997347712517,
      -0.03493533283472061,
      0.00999747309833765,
      -0.026052352041006088,
      -0.07404930889606476,
      0.04901077225804329,
      -0.07326506823301315,
      0.0333738774061203,
      0.009480549953877926,
      0.038271304219961166,
      0.070742167532444,
      0.05457465350627899,
      -0.06972198188304901,
      -0.039187997579574585,
      0.008828394114971161,
      -0.0567379854619503,
      -0.08159440755844116,
      0.02579164318740368,
      -0.0006147572421468794,
      -0.03606383502483368,
      -0.03205370530486107,
      0.04015275463461876,
      0.03150450810790062,
      0.014331177808344364,
      0.021243425086140633,
      -0.02852567471563816,
      -0.018756944686174393,
      0.006213829852640629,
      0.11230345070362091,
      -0.049740981310606,
      -0.07084088772535324,
      0.03168821707367897,
      -0.09644091874361038,
      -0.004449168220162392,
      0.025985274463891983,
      -0.022496627643704414,
      0.11527565866708755,
      0.04464703053236008,
      0.027473850175738335,
      0.017173219472169876,
      -0.05570283532142639,
      -0.031158264726400375,
      -0.005717783700674772,
      0.03667696192860603,
      0.052657097578048706,
      0.01515671331435442,
      0.10829173028469086,
      -0.020968370139598846,
      0.051724839955568314,
      -0.017487188801169395,
      0.056165583431720734,
      -0.03417185693979263,
      -0.018878063187003136,
      -0.03179957717657089,
      0.06625331193208694,
      0.08968988806009293,
      -0.02616794779896736,
      0.0005591054796241224,
      0.04768172279000282,
      0.06107904016971588,
      0.0031400970183312893,
      -0.05113730952143669,
      -0.05976495146751404,
      -0.039919011294841766,
      -0.010071142576634884,
      -0.007168269716203213,
      0.048036545515060425,
      -0.0373510979115963,
      0.04269810765981674,
      -0.03414812684059143,
      -0.03134005516767502,
      0.02014010399580002,
      0.03622546046972275,
      -0.02607090398669243,
      0.02975727804005146,
      -0.05119635537266731,
      -0.026039017364382744,
      -0.012547514401376247,
      0.04407930001616478,
      -0.07500401139259338,
      0.04349580407142639,
      0.03517335653305054,
      0.018503278493881226,
      0.004988434724509716,
      -0.00330938957631588,
      0.0513489693403244,
      0.0011420901864767075,
      -0.11789759993553162,
      -0.05230698361992836,
      -0.020199932157993317,
      0.0008783783996477723,
      -0.0680939182639122,
      0.032774750143289566,
      0.0011125310556963086,
      0.09205169230699539,
      -0.04327232018113136,
      -0.027013082057237625,
      -0.03975220397114754,
      0.011854314245283604,
      0.041119370609521866,
      -0.04174324497580528,
      -0.02669261395931244,
      -0.010926509276032448,
      -0.013711478561162949,
      -0.015949435532093048,
      -0.06365504115819931,
      0.009124918840825558,
      0.016808079555630684,
      -0.06176397204399109,
      0.016273746266961098,
      0.011037744581699371,
      0.05599703639745712,
      0.015395695343613625,
      0.049948450177907944,
      -0.01795259676873684,
      -0.01660415343940258,
      0.0315844751894474,
      0.016520483419299126,
      -0.025532113388180733,
      0.0037026468198746443,
      -0.034935109317302704,
      0.12683159112930298,
      0.04201948270201683,
      -0.017960846424102783,
      0.0319133922457695,
      0.05925925448536873,
      0.04469193145632744,
      -0.10358558595180511,
      -0.12162980437278748,
      -0.06230580806732178,
      -0.041965290904045105,
      -0.028107190504670143,
      0.010415284894406796,
      0.028912296518683434,
      0.07945480942726135,
      0.010448182933032513,
      0.030053282156586647,
      -0.08024278283119202,
      0.01961958408355713,
      0.05403973534703255,
      0.05835830047726631,
      0.04532509297132492,
      0.030644085258245468,
      0.01631968468427658,
      0.032367538660764694,
      0.04275284335017204,
      -0.01883259043097496,
      -0.03252999857068062,
      0.12755145132541656,
      0.07887448370456696,
      -0.03546837344765663,
      0.05394194647669792,
      0.010219955816864967,
      -0.06258842349052429,
      -0.008027080446481705,
      0.0250545721501112,
      -0.07989982515573502,
      -0.0766325518488884,
      0.011509828269481659,
      -0.0005427811411209404,
      -0.033472027629613876,
      -0.06421003490686417,
      0.02443857677280903,
      -0.07663815468549728,
      0.05729808285832405,
      0.007846670225262642,
      -0.02040981501340866,
      -0.0003836577234324068,
      -0.003958845976740122,
      0.05062972381711006,
      4.24601421400439e-05,
      0.012941572815179825,
      -0.035922907292842865,
      -0.05158941447734833,
      0.06515789777040482,
      -0.0388135090470314,
      -0.001639113062992692,
      -0.02368747815489769,
      -0.06327033042907715,
      0.02585628256201744,
      0.032783541828393936,
      -0.019435951486229897,
      0.05007939785718918,
      0.014138485305011272,
      -0.01392058003693819,
      0.03158922120928764,
      -0.0007133539183996618,
      0.010104580782353878,
      -0.024939190596342087,
      -0.007123436313122511,
      -0.0440153032541275,
      -0.03877295181155205,
      -0.07218717783689499,
      -0.05738154053688049,
      0.014156035147607327,
      0.036109041422605515,
      -0.07404918968677521,
      -0.05816895142197609,
      -0.013664448633790016,
      0.01241726242005825,
      0.016371840611100197,
      0.04083288460969925,
      0.03324532136321068,
      -0.11672225594520569,
      0.018638812005519867,
      0.05196074768900871,
      0.04739517346024513,
      -0.014658289961516857,
      0.03097057156264782,
      -0.05891276150941849,
      0.03702106326818466,
      -0.06136023998260498,
      0.0070577459409832954,
      -0.0048736813478171825,
      -0.06463155150413513,
      -0.00430689100176096,
      0.06032230332493782,
      0.05136445537209511,
      -0.026969706639647484,
      -0.07362940162420273,
      -0.030098488554358482,
      -0.009198990650475025,
      -0.017983902245759964,
      -0.07025863975286484,
      0.020563948899507523,
      0.01856793463230133,
      -0.015182153321802616,
      0.017633268609642982,
      -0.036551810801029205,
      -0.0004934480530209839,
      -0.02490057982504368,
      0.002218622248619795,
      0.11808963119983673,
      0.06866579502820969,
      -0.02191990241408348,
      0.025195617228746414,
      -0.032930560410022736,
      -0.03471853956580162,
      0.004743329714983702,
      0.019993018358945847,
      0.057665687054395676,
      -0.05365798622369766,
      -0.024321436882019043,
      -0.019815921783447266,
      -0.00037827566848136485,
      -0.005643581040203571,
      0.01762419193983078,
      -0.02621460147202015,
      -0.062044162303209305,
      0.00277261552400887,
      0.027048233896493912,
      -0.010257337242364883,
      -0.06865876168012619,
      0.03464008495211601,
      0.047866079956293106,
      -0.0207957960665226,
      0.11035210639238358,
      0.019295386970043182,
      0.02225356735289097,
      0.011103188619017601,
      0.0464177131652832,
      0.06175687164068222,
      -0.06000630557537079,
      0.009316178038716316,
      0.06851911544799805,
      0.0507347472012043,
      0.00785298552364111,
      0.058164387941360474,
      0.028753014281392097,
      0.03637858107686043,
      0.05219245329499245,
      0.013267410919070244,
      -0.008831722661852837,
      0.027914797887206078,
      0.08713286370038986,
      0.05306342989206314,
      0.03221486881375313,
      0.010797780938446522,
      -0.01495175901800394,
      0.014049303717911243,
      -0.03800749406218529,
      -0.05881552770733833,
      0.008450182154774666,
      0.05174922198057175,
      0.0037826213520020247,
      -0.045917097479104996,
      0.022227933630347252,
      0.08783674985170364,
      -0.04937717318534851,
      -0.05752198398113251,
      -0.02578955702483654,
      0.04036020115017891,
      -0.0394296832382679,
      0.012075240723788738,
      0.038274284452199936,
      -0.05664200708270073,
      0.02583557926118374,
      -0.001391532365232706,
      -0.06743396818637848,
      -0.10138779133558273,
      -0.028424611315131187,
      0.04844506457448006,
      -0.0034235604107379913,
      0.05306606739759445,
      -0.07739537209272385,
      0.010317300446331501,
      -0.025882160291075706,
      0.004131282214075327,
      -0.045895904302597046,
      -0.025689400732517242,
      -0.06287363171577454,
      0.03903522342443466,
      -0.03340524435043335,
      0.08157072961330414,
      -0.06013716757297516,
      -0.06465432047843933,
      -0.028001271188259125,
      -0.016324415802955627,
      0.006781728472560644,
      0.041619397699832916,
      -3.335344808874652e-05,
      -0.03413229063153267,
      0.01877305656671524,
      0.008707579225301743,
      -0.05037063732743263,
      -0.002846618415787816,
      -0.010593515820801258,
      -0.037649475038051605,
      0.09002016484737396,
      0.02026335708796978,
      -0.06611088663339615,
      0.015063250437378883,
      0.06728099286556244,
      0.01888832449913025,
      0.039052002131938934,
      0.00927569530904293,
      -0.03912407532334328,
      -0.01266967412084341,
      0.04832562431693077,
      -0.06115053966641426,
      0.047645002603530884,
      -0.06271039694547653,
      0.016813546419143677,
      0.0043549262918531895,
      -0.021330004557967186,
      0.008307238109409809,
      -0.037172526121139526,
      0.014622657559812069,
      -0.015215648338198662,
      -0.03680866211652756,
      0.10048825293779373,
      -0.015013059601187706,
      -0.0028415201231837273,
      0.11523282527923584,
      -0.0012094436679035425,
      -0.06015993282198906,
      -0.020542800426483154,
      0.002089751185849309,
      0.01944982446730137,
      0.004452626686543226,
      0.005919069517403841,
      0.046919260174036026,
      0.01485365442931652,
      0.05012894794344902,
      -0.01818985678255558,
      0.019039172679185867,
      0.02077142521739006,
      0.013205280527472496,
      -3.270307934144512e-05,
      0.020878899842500687,
      -0.030478589236736298,
      -0.02863107994198799,
      -0.039668384939432144,
      0.046028755605220795,
      -0.06704292446374893,
      0.01597529649734497,
      0.0773908868432045,
      -0.03903979808092117,
      0.038470279425382614,
      0.033233433961868286,
      0.024987459182739258,
      0.0038485336117446423,
      -0.05901429057121277,
      -0.0024234254378825426,
      -0.06319868564605713,
      0.01790733076632023,
      0.01105881854891777,
      -0.008834416046738625,
      0.020494507625699043,
      0.053147923201322556,
      0.04897160828113556,
      -0.008978741243481636,
      -0.05051717162132263,
      -0.06126483529806137,
      -0.003694107523187995,
      0.008450583554804325,
      0.019191818311810493,
      0.04371796175837517,
      0.03465002030134201,
      -0.06079127639532089,
      -0.13249517977237701,
      -0.14980879426002502,
      -0.03125041723251343,
      0.028848888352513313,
      0.017447689548134804,
      -0.03496137633919716,
      -0.037081312388181686,
      -0.017529811710119247,
      -0.02954213134944439,
      0.05513596907258034,
      -0.10793635994195938,
      -0.013575217686593533,
      0.05302894860506058,
      0.04492025077342987,
      -0.04519796743988991,
      0.12454535067081451,
      0.020043516531586647,
      0.0008963802247308195,
      2.0937073713866994e-05,
      0.026249175891280174,
      -0.013762492686510086,
      0.009442522190511227,
      0.061456531286239624,
      -0.03194013610482216,
      0.007308867760002613,
      -0.045016057789325714
    ],
    [
      -0.0800667479634285,
      0.08147123456001282,
      -0.042861100286245346,
      -0.04751984402537346,
      0.03384720906615257,
      0.017557289451360703,
      -0.00585966557264328,
      0.03932657092809677,
      -0.03533843904733658,
      0.06167116016149521,
      -0.0070110345259308815,
      -0.035109907388687134,
      0.005629656370729208,
      0.007314898539334536,
      0.01944590173661709,
      -0.029873697087168694,
      0.024482041597366333,
      -0.06007707118988037,
      -0.005930405575782061,
      -0.029931947588920593,
      0.03780326619744301,
      -0.00039499817648902535,
      0.06470751017332077,
      -0.029154622927308083,
      0.021843496710062027,
      0.01854751445353031,
      -0.05866355821490288,
      0.02345992997288704,
      0.006673433817923069,
      0.05331629514694214,
      -0.0935787558555603,
      0.003390698693692684,
      -0.0030894530937075615,
      -0.0387546569108963,
      -0.05716598778963089,
      0.014756588265299797,
      0.05193950980901718,
      0.02928909659385681,
      -0.0038139568641781807,
      -0.05764353275299072,
      0.01466887816786766,
      0.04513097554445267,
      -0.041699882596731186,
      -0.011147420853376389,
      0.037207383662462234,
      -0.05987830460071564,
      0.016104435548186302,
      0.06587787717580795,
      0.03180946782231331,
      -0.059251781553030014,
      -0.05459500104188919,
      -0.03433946147561073,
      -0.06550947576761246,
      -0.01366314385086298,
      0.027261359617114067,
      -0.010535569861531258,
      0.021411826834082603,
      0.0552145391702652,
      -0.05650235339999199,
      0.04643077030777931,
      0.0047965324483811855,
      0.03319413959980011,
      0.035026244819164276,
      2.226451033493504e-05,
      -0.048596013337373734,
      -0.027654046192765236,
      -0.008361319079995155,
      -0.0170053169131279,
      0.0351819209754467,
      0.05128823593258858,
      -0.018679799512028694,
      -0.04451388493180275,
      0.0317360982298851,
      0.012667745351791382,
      0.008625566028058529,
      -0.004215682856738567,
      -0.04695024713873863,
      0.013796581886708736,
      -0.03965684399008751,
      -0.05257092043757439,
      -0.029797038063406944,
      0.027973268181085587,
      -0.036007143557071686,
      0.008378464728593826,
      -0.05252460017800331,
      0.022736014798283577,
      -0.03675968199968338,
      -0.0068067885003983974,
      0.04660213366150856,
      -0.016485758125782013,
      -0.056483250111341476,
      0.0063674598932266235,
      0.031120358034968376,
      -0.07330576330423355,
      0.02870548702776432,
      0.07829225808382034,
      -0.0048203542828559875,
      -0.04151935130357742,
      -0.00955742597579956,
      -0.0011006755521520972,
      0.019822601228952408,
      0.020117323845624924,
      -0.011472857557237148,
      -0.048313207924366,
      0.08185349404811859,
      0.06286805868148804,
      -0.007296733092516661,
      -0.04898378625512123,
      -0.01965421810746193,
      -0.01240163017064333,
      0.040382642298936844,
      -0.016736187040805817,
      -0.046050604432821274,
      -0.0042544943280518055,
      -0.05351349338889122,
      -0.07366708666086197,
      -0.0660955160856247,
      -0.02805897779762745,
      0.022156191989779472,
      -0.04766669124364853,
      -0.024058373644948006,
      -0.0017438377253711224,
      0.016933022066950798,
      0.03708963468670845,
      -0.004921176005154848,
      0.02106389030814171,
      0.03386512026190758,
      -0.035905513912439346,
      0.0546661913394928,
      -0.02180628292262554,
      0.022617803886532784,
      -0.013260397128760815,
      -0.04955783486366272,
      0.021526329219341278,
      -0.015717171132564545,
      -0.04275180399417877,
      0.010445908643305302,
      0.05164632201194763,
      -0.017094826325774193,
      -0.0019431241089478135,
      -0.07875504344701767,
      0.006119140423834324,
      0.01784791424870491,
      0.06291365623474121,
      -0.02784603275358677,
      -0.011333566159009933,
      0.034073784947395325,
      0.0440143384039402,
      0.03137267008423805,
      0.06986379623413086,
      0.02439904771745205,
      0.08643964678049088,
      -0.05729953199625015,
      -0.018391523510217667,
      -0.025143539533019066,
      0.03916569799184799,
      -0.006497426424175501,
      -0.005286560859531164,
      -0.005052913911640644,
      -0.012266579084098339,
      0.04511234536767006,
      -0.02993372082710266,
      0.04896089807152748,
      -0.02030949480831623,
      -0.030964164063334465,
      -0.05702796205878258,
      0.0032307410147041082,
      -0.012678302824497223,
      -0.022531110793352127,
      0.020720960572361946,
      0.012206406332552433,
      -0.0863022580742836,
      -0.0062290458008646965,
      0.0006330038304440677,
      0.020348431542515755,
      0.019630320370197296,
      -0.0019755333196371794,
      0.10224755108356476,
      0.02570696361362934,
      0.03478718921542168,
      0.03687070682644844,
      0.012511087581515312,
      -0.06797961890697479,
      -0.05294756963849068,
      0.017678169533610344,
      0.004087837412953377,
      0.03220880776643753,
      0.005839681718498468,
      -0.02767818421125412,
      0.016188647598028183,
      0.06694953143596649,
      0.007699353154748678,
      0.006186793092638254,
      -0.083011195063591,
      -0.013039651326835155,
      -0.0034642990212887526,
      0.04303852468729019,
      0.0021035242825746536,
      -0.07052686810493469,
      -0.01373109221458435,
      -0.04828702658414841,
      0.024520941078662872,
      -0.03574089705944061,
      -0.002886863425374031,
      0.01603686437010765,
      -0.0930781364440918,
      0.027242261916399002,
      0.04578440636396408,
      -0.036721985787153244,
      0.03410964831709862,
      -0.025760464370250702,
      0.04203592985868454,
      -0.051035791635513306,
      0.07277899235486984,
      0.059737492352724075,
      -0.06138138845562935,
      0.03670892119407654,
      -0.005010055843740702,
      0.0614582821726799,
      0.028770549222826958,
      0.061059121042490005,
      0.028864262625575066,
      0.04748426750302315,
      -0.023406514897942543,
      0.054961852729320526,
      -0.049659717828035355,
      -0.012011717073619366,
      -0.01936805248260498,
      -0.011769212782382965,
      -0.0005963976145721972,
      0.06592056155204773,
      0.002748362720012665,
      0.06336154043674469,
      -0.004005758557468653,
      -0.05010003596544266,
      0.038052842020988464,
      -0.0034467391669750214,
      -0.006794783286750317,
      -0.06685234606266022,
      0.025827357545495033,
      -0.06804195791482925,
      0.0146819232031703,
      0.028163835406303406,
      0.017537185922265053,
      0.013136656954884529,
      -0.07594913989305496,
      0.039201851934194565,
      0.0148154953494668,
      0.024414554238319397,
      -0.03654736280441284,
      -0.004901915788650513,
      0.046794429421424866,
      -0.01721065118908882,
      -0.05230830982327461,
      -0.022613011300563812,
      -0.022881804034113884,
      0.010270848870277405,
      -0.10069630295038223,
      -0.07771234214305878,
      0.014250223524868488,
      0.034955162554979324,
      0.09293593466281891,
      0.011165741831064224,
      -0.03610137477517128,
      0.0017700039315968752,
      0.008108130656182766,
      -0.02187216654419899,
      0.10836581140756607,
      0.03785627335309982,
      0.003456798614934087,
      0.01367963757365942,
      -0.020752213895320892,
      -0.008707175962626934,
      -0.12604977190494537,
      -0.014610299840569496,
      0.022330785170197487,
      0.03601154312491417,
      0.01312212273478508,
      0.12002173066139221,
      0.01917707733809948,
      -0.00656366441398859,
      0.035282716155052185,
      0.0024945572949945927,
      0.015343512408435345,
      -0.04459405317902565,
      -0.008314876817166805,
      0.0722607970237732,
      0.018085626885294914,
      -0.010997883044183254,
      -0.08834558725357056,
      0.04993921518325806,
      -0.03809979930520058,
      -0.007378039415925741,
      0.03894251585006714,
      0.006444442551583052,
      -0.012990782037377357,
      -0.009197845123708248,
      0.07243523001670837,
      0.0064602205529809,
      -0.03949763998389244,
      -0.020739203318953514,
      -0.024328753352165222,
      0.03377964347600937,
      0.05850621312856674,
      -0.042597007006406784,
      0.027310660108923912,
      -0.1042758971452713,
      0.03010951355099678,
      0.028913643211126328,
      0.08116321265697479,
      0.030451621860265732,
      0.09442336112260818,
      0.10181228071451187,
      -0.04287756606936455,
      0.023050151765346527,
      0.0627700611948967,
      -0.03633042424917221,
      0.058952879160642624,
      0.06601408123970032,
      -0.03157844766974449,
      -0.029512695968151093,
      -0.003129144199192524,
      -0.0537886843085289,
      -0.016944237053394318,
      -0.024280494078993797,
      0.011362507939338684,
      0.07349015027284622,
      -0.04965309053659439,
      0.03283189982175827,
      0.013626026920974255,
      -0.03998250886797905,
      0.010387181304395199,
      -0.035491909831762314,
      -0.006028585135936737,
      -0.07780657708644867,
      0.05474371463060379,
      0.03804221376776695,
      -0.03371787071228027,
      0.07616092264652252,
      0.041121307760477066,
      -0.08995146304368973,
      0.0749075710773468,
      0.02406981959939003,
      -0.08161367475986481,
      0.0049815005622804165,
      -0.044701527804136276,
      0.034415021538734436,
      -0.031083665788173676,
      -0.007763415575027466,
      0.08785417675971985,
      -0.04278511181473732,
      0.09049351513385773,
      -0.045003924518823624,
      0.004079260863363743,
      -0.054461799561977386,
      -0.018105100840330124,
      -0.05110912397503853,
      0.055551089346408844,
      0.050756148993968964,
      -0.008323783054947853,
      0.008674184791743755,
      0.004546305164694786,
      0.01031288132071495,
      0.05287102237343788,
      -0.008226178586483002,
      0.0535624660551548,
      0.03192392736673355,
      -0.008733022026717663,
      0.02803640440106392,
      0.01262239832431078,
      -0.004107564687728882,
      -0.002942917635664344,
      -0.042267072945833206,
      -0.03307744860649109,
      0.014599526301026344,
      0.01054873876273632,
      -0.004568691831082106,
      -0.05190231278538704,
      -0.07013662159442902,
      -0.01763247884809971,
      -0.015990259125828743,
      -0.014849402010440826,
      -0.06339678168296814,
      -0.010971726849675179,
      0.0555683933198452,
      -0.017992723733186722,
      0.05165848881006241,
      -0.04518282040953636,
      0.021077835932374,
      0.012723470106720924,
      -0.004350512754172087,
      -0.060320526361465454,
      -0.0028990628197789192,
      -0.0032530315220355988,
      0.02655120939016342,
      -0.03261024132370949,
      0.06630827486515045,
      0.024104921147227287,
      -0.017279192805290222,
      0.09084700793027878,
      0.0006678334320895374,
      -0.022588156163692474,
      -0.046123504638671875,
      0.01842440664768219,
      -0.048883192241191864,
      0.11805443465709686,
      -0.015303820371627808,
      -0.0028695608489215374,
      -0.03051689825952053,
      0.007940630428493023,
      -0.045126646757125854,
      0.06861390918493271,
      -0.004073005169630051,
      0.10465051233768463,
      -0.0433732308447361,
      -0.0010689474875107408,
      0.033827271312475204,
      0.06793857365846634,
      0.02968994341790676,
      0.024875350296497345,
      0.0007180155953392386,
      -0.03688414394855499,
      -0.055042993277311325,
      0.04475575312972069,
      -0.05525685101747513,
      0.0025860904715955257,
      0.08029290288686752,
      -0.06686154752969742,
      -0.0339844636619091,
      -0.03632459044456482,
      -0.02825400047004223,
      0.0028285884764045477,
      -0.08630470931529999,
      0.021959707140922546,
      -0.019501138478517532,
      0.0075173405930399895,
      0.026023074984550476,
      0.030649924650788307,
      -0.05219808220863342,
      0.019777951762080193,
      0.07366620749235153,
      -0.014974207617342472,
      0.025969240814447403,
      -0.025372853502631187,
      0.07143229246139526,
      -0.04328625649213791,
      -0.012742298655211926,
      -0.017686057835817337,
      0.034018415957689285,
      -0.03254983201622963,
      -0.03315344080328941,
      0.0011706976220011711,
      -0.08446880429983139,
      -0.019154882058501244,
      -0.11951440572738647,
      0.0612274594604969,
      -0.010379917919635773,
      -0.030579032376408577,
      0.0027447862084954977,
      -0.0006669879658147693,
      -0.062330201268196106,
      -0.04475395008921623,
      0.0236312597990036,
      0.006768064107745886,
      -0.0060247392393648624,
      -0.05700244754552841,
      -0.010864301584661007,
      -0.015315305441617966,
      0.009252241812646389,
      -0.019204916432499886,
      -0.05451861768960953,
      -0.016080331057310104,
      -0.013724356889724731,
      -0.009296256117522717,
      0.04412529617547989,
      -0.07002352178096771,
      -0.007010616362094879,
      0.04602987691760063,
      0.06832776218652725,
      -0.08608736097812653,
      -0.006379082333296537,
      0.08364416658878326,
      -0.09616608917713165,
      -0.05990835651755333,
      -0.008988392539322376,
      0.04307287931442261,
      0.05343131721019745,
      -0.06642536073923111,
      0.031756963580846786,
      -0.0326431505382061,
      -0.007633446715772152,
      -0.03611403703689575,
      0.04416203498840332,
      0.02415079064667225,
      -0.06185431778430939,
      -0.02747170254588127,
      0.01835930533707142,
      -0.007732498459517956,
      0.04942356422543526,
      -0.037828609347343445,
      0.006001466419547796,
      0.053602203726768494,
      -0.04784529283642769
    ],
    [
      -0.06938358396291733,
      0.017630305141210556,
      0.015676861628890038,
      -0.012630895711481571,
      0.03214786946773529,
      -0.11017339676618576,
      -0.014139573089778423,
      -0.008121421560645103,
      0.07592035830020905,
      0.06041658669710159,
      -0.054929766803979874,
      -0.010208796709775925,
      0.09171395003795624,
      -0.007561671547591686,
      -0.028698734939098358,
      0.020003175362944603,
      0.001448415219783783,
      0.04939493164420128,
      -0.0951010212302208,
      -0.006915669422596693,
      -0.06672380864620209,
      0.030977586284279823,
      -0.013894941657781601,
      0.0003476467973086983,
      -0.08731891214847565,
      0.006001356523483992,
      -0.009075254201889038,
      0.06342203915119171,
      0.015739787369966507,
      -0.08348114788532257,
      0.022583605721592903,
      0.03427199646830559,
      0.09165789932012558,
      -0.051048535853624344,
      -0.015727398917078972,
      -0.07676535844802856,
      0.019408367574214935,
      0.012033756822347641,
      0.06097210943698883,
      -0.052610818296670914,
      0.006594451609998941,
      0.01940089277923107,
      -0.055199023336172104,
      0.003377649700269103,
      -0.008080693893134594,
      -0.08219205588102341,
      -0.019787902012467384,
      -0.0171283558011055,
      0.024014204740524292,
      0.015701187774538994,
      -0.029385052621364594,
      -0.009923579171299934,
      -0.03927398845553398,
      -0.013182704336941242,
      -0.0041965581476688385,
      -0.009696779772639275,
      0.01338876411318779,
      -0.01397044025361538,
      0.0022713819053024054,
      -0.04373246803879738,
      -0.014250761829316616,
      0.06406442821025848,
      0.019730860367417336,
      -0.14969447255134583,
      0.000177962429006584,
      -0.018851345404982567,
      -0.12315600365400314,
      0.026488428935408592,
      0.025460440665483475,
      -0.01837196573615074,
      -0.03547598421573639,
      -0.07104291766881943,
      0.0685029923915863,
      -0.009523129090666771,
      0.047821421176195145,
      -0.030419737100601196,
      -0.0723550096154213,
      -0.024400033056735992,
      -0.015099444426596165,
      -0.10290934890508652,
      -0.05942853167653084,
      -0.014258773997426033,
      0.03830987587571144,
      0.042907554656267166,
      -0.05283234268426895,
      -0.045885905623435974,
      0.002661800943315029,
      0.10306384414434433,
      0.06351915001869202,
      0.024623673409223557,
      -0.0005266807274892926,
      0.03606868535280228,
      -0.06812943518161774,
      -0.0015577584272250533,
      -0.026675065979361534,
      0.013859101571142673,
      0.018232090398669243,
      -0.001271397341042757,
      0.017750004306435585,
      0.03521013259887695,
      -0.06995991617441177,
      0.053476009517908096,
      -0.11585330218076706,
      0.060929618775844574,
      -0.030923841521143913,
      0.032524775713682175,
      -0.04110069200396538,
      -0.04506547749042511,
      -0.01055985502898693,
      0.027306705713272095,
      0.042252980172634125,
      0.013201633468270302,
      -0.046872299164533615,
      0.08120562881231308,
      0.048592906445264816,
      -0.06231391802430153,
      -0.004089667461812496,
      0.030683454126119614,
      0.046859219670295715,
      0.009934770874679089,
      0.04217633977532387,
      -0.09992264211177826,
      0.030933305621147156,
      0.02976687066257,
      0.0454413965344429,
      0.01385632436722517,
      0.008193320594727993,
      -0.028973542153835297,
      -0.08382906764745712,
      0.0005770774441771209,
      -0.005505420733243227,
      0.07382753491401672,
      -0.019508184865117073,
      0.04050983116030693,
      0.046343836933374405,
      0.016250215470790863,
      0.03876940533518791,
      -0.01691531017422676,
      -0.09035678207874298,
      -0.03671756759285927,
      -0.0027652359567582607,
      0.05449966713786125,
      0.06729425489902496,
      -0.023118488490581512,
      0.01088098157197237,
      0.03623691946268082,
      0.0026083935517817736,
      -0.012501386925578117,
      0.005330306477844715,
      -0.021446334198117256,
      0.009780545718967915,
      -0.006954608019441366,
      0.027001267299056053,
      -0.019831746816635132,
      0.026375576853752136,
      0.03931982442736626,
      -0.0739767998456955,
      0.0255271103233099,
      0.01731005683541298,
      0.07993794977664948,
      -0.028149474412202835,
      -0.0032758775632828474,
      -0.010799569077789783,
      -0.08040031045675278,
      -0.06718041002750397,
      -0.006589485332369804,
      -0.06286715716123581,
      -0.023352401331067085,
      -0.04212569817900658,
      -0.03536572679877281,
      0.00037471315590664744,
      -0.03466492518782616,
      0.03925492241978645,
      0.022497283294796944,
      -0.043964482843875885,
      0.07584113627672195,
      -0.015455161221325397,
      -0.009670828469097614,
      0.02839699201285839,
      -0.10524731874465942,
      0.035015348345041275,
      0.07311025261878967,
      -0.07717912644147873,
      -0.04209466278553009,
      0.0047751558013260365,
      0.005777370184659958,
      -0.11964654177427292,
      -0.04675881564617157,
      -0.06531958281993866,
      -0.04248091205954552,
      0.014647128991782665,
      0.023375770077109337,
      -0.024114910513162613,
      -0.003756482619792223,
      0.038645680993795395,
      -0.0002592082310002297,
      0.07024116814136505,
      -0.09991247206926346,
      -0.04981117695569992,
      0.04085370898246765,
      0.0030626130755990744,
      0.04148537665605545,
      -0.010369310155510902,
      -0.0077701136469841,
      -0.02268446609377861,
      -0.04208912327885628,
      -0.03870636224746704,
      0.03559357672929764,
      -0.09378180652856827,
      0.03752334788441658,
      0.03263351321220398,
      -0.05387268215417862,
      0.07729999721050262,
      0.11428684741258621,
      0.0339990109205246,
      0.045578911900520325,
      0.06700451672077179,
      0.026513198390603065,
      -0.010434496216475964,
      -0.03350117802619934,
      -0.024720339104533195,
      0.009522957727313042,
      0.004236928187310696,
      -0.004021820146590471,
      -0.04653142765164375,
      0.027501346543431282,
      -0.08438943326473236,
      0.026212897151708603,
      0.008416502736508846,
      -0.026392461732029915,
      -0.042198631912469864,
      -0.0179307758808136,
      0.08730407059192657,
      0.022021286189556122,
      0.02684035338461399,
      0.018463829532265663,
      -0.00034371085348539054,
      -0.012900767847895622,
      0.058435071259737015,
      0.05629490688443184,
      0.056765757501125336,
      -0.016685688868165016,
      0.001755672274157405,
      -0.02638922445476055,
      0.03421347960829735,
      -0.05679633468389511,
      0.05034109577536583,
      0.045792654156684875,
      -0.03366132080554962,
      -0.0484081469476223,
      0.06568112969398499,
      -0.01569623313844204,
      -0.015317842364311218,
      -0.04593171551823616,
      0.0082470728084445,
      0.059690337628126144,
      -0.007749311625957489,
      -0.0859660878777504,
      -0.02746068313717842,
      0.030325347557663918,
      0.023405592888593674,
      0.010931962169706821,
      -0.03934435173869133,
      0.076217420399189,
      -0.05910128727555275,
      -0.04241368547081947,
      0.05440201610326767,
      -0.020337702706456184,
      -0.046503886580467224,
      0.016332436352968216,
      -0.06468438357114792,
      0.07865770161151886,
      0.029955951496958733,
      0.037836864590644836,
      -0.016398224979639053,
      -0.01625598594546318,
      -0.050531525164842606,
      -0.02346968837082386,
      -0.03254362568259239,
      0.017179010435938835,
      0.022478103637695312,
      -0.03657122701406479,
      0.0743401050567627,
      -0.0019044025102630258,
      0.03176002576947212,
      0.025500882416963577,
      -0.0642431378364563,
      -0.011624621227383614,
      -0.06740245968103409,
      -0.019863186404109,
      0.05051375553011894,
      -0.014377580024302006,
      -0.04152332618832588,
      -0.06705722957849503,
      0.020528210327029228,
      0.11678966134786606,
      0.00998108834028244,
      -0.04526705667376518,
      0.017058296129107475,
      0.004382602870464325,
      -0.01181482058018446,
      0.009935682639479637,
      0.04250996932387352,
      0.055001769214868546,
      -0.04037861526012421,
      0.014292165637016296,
      -0.02151295728981495,
      0.00680802296847105,
      0.03214540705084801,
      0.01373491995036602,
      0.0226289052516222,
      -0.11412782222032547,
      -0.011308958753943443,
      -0.05768774822354317,
      -0.030047718435525894,
      -0.012433241121470928,
      -0.008792707696557045,
      -0.02345387265086174,
      0.011432841420173645,
      -0.003068480873480439,
      0.016897156834602356,
      -0.013131891377270222,
      -0.02627236396074295,
      0.0005479935207404196,
      0.06480459868907928,
      -0.003658933099359274,
      0.03049452230334282,
      0.022289860993623734,
      0.040190208703279495,
      0.024251632392406464,
      0.08310166746377945,
      -0.030651146546006203,
      -0.06365791708230972,
      -0.02860376611351967,
      -0.046731434762477875,
      0.05616545304656029,
      -0.009114320389926434,
      -0.02774766832590103,
      0.015217222273349762,
      0.04857625439763069,
      0.004567369818687439,
      0.07410488277673721,
      0.036593057215213776,
      -0.03392783924937248,
      0.014797172509133816,
      0.009879684075713158,
      0.013488897122442722,
      0.03725537285208702,
      0.0036215733271092176,
      0.026948021724820137,
      0.03407455235719681,
      0.13150951266288757,
      -0.0030743356328457594,
      -0.07166196405887604,
      -0.059130266308784485,
      -0.002809858648106456,
      0.006809089798480272,
      -0.02710288017988205,
      0.09922555088996887,
      -0.015083461068570614,
      -0.06289536505937576,
      0.008670961484313011,
      -0.04365801066160202,
      -0.0006573969731107354,
      -0.026635104790329933,
      0.033206015825271606,
      0.05042726546525955,
      -0.0084898816421628,
      -0.0666920617222786,
      0.05728748440742493,
      -0.01976197212934494,
      0.025318292900919914,
      -0.041661813855171204,
      0.04938054457306862,
      0.00347994570620358,
      0.06821050494909286,
      -0.09612036496400833,
      0.043181661516427994,
      -0.07336034625768661,
      0.03889519348740578,
      -0.04042584449052811,
      -0.017915494740009308,
      0.04190012067556381,
      0.03106093220412731,
      -0.0009106246288865805,
      -0.04550556838512421,
      0.07255057245492935,
      -0.033422376960515976,
      -0.03930686414241791,
      0.01988300122320652,
      0.01589062623679638,
      -0.03654012829065323,
      -0.0901447981595993,
      -0.08584118634462357,
      0.002482800744473934,
      0.03668788820505142,
      -0.07834012061357498,
      0.04750668257474899,
      -0.03282630443572998,
      -0.06659208983182907,
      0.07147073745727539,
      -0.011068243533372879,
      0.027282122522592545,
      -0.02670351229608059,
      -0.02057686075568199,
      -0.008546247147023678,
      -0.03390369564294815,
      0.02595660090446472,
      0.006644961889833212,
      0.03328551724553108,
      -0.013518395833671093,
      -0.010894019156694412,
      -0.006043678615242243,
      0.05086661875247955,
      -0.0452631339430809,
      -0.03009539656341076,
      0.039557382464408875,
      0.0839739590883255,
      0.07227513194084167,
      0.033525727689266205,
      -0.01638178527355194,
      -0.008223450742661953,
      -0.07059448957443237,
      0.01581088826060295,
      0.03633616119623184,
      -0.04414275288581848,
      -0.05732470005750656,
      0.07601268589496613,
      -0.034272000193595886,
      0.024095812812447548,
      0.04849759489297867,
      -0.016977710649371147,
      0.06130429729819298,
      -0.018658757209777832,
      0.06938186287879944,
      -0.0010232009226456285,
      -0.06139477714896202,
      -0.05230336636304855,
      -0.0791030302643776,
      -0.018069956451654434,
      0.040893442928791046,
      -0.07004284858703613,
      -0.04611712321639061,
      -0.03678762912750244,
      -0.046065472066402435,
      -0.04081113263964653,
      0.0244715865701437,
      0.026217583566904068,
      -0.044796425849199295,
      -0.022855006158351898,
      0.029896356165409088,
      0.08998007327318192,
      -0.0030466823372989893,
      0.05514094978570938,
      0.015339372679591179,
      0.061966463923454285,
      0.0014734590658918023,
      0.02763233706355095,
      0.03100690431892872,
      -0.06658291816711426,
      -0.04023246839642525,
      -0.03016739897429943,
      0.035608645528554916,
      -0.019050046801567078,
      -0.019758841022849083,
      0.0023557767271995544,
      -0.017044536769390106,
      -0.08260054141283035,
      -0.0170816108584404,
      0.040035102516412735,
      -0.02610575407743454,
      0.06764998286962509,
      0.0860963985323906,
      -0.06788209825754166,
      0.06151479110121727,
      0.036963317543268204,
      0.012811004184186459,
      0.026908308267593384,
      -0.013095702044665813,
      -0.06680047512054443,
      -0.014081240631639957,
      -0.03238938748836517,
      -0.03284141793847084,
      -0.08818435668945312,
      -0.05913583189249039,
      0.014104831032454967,
      0.01341995969414711,
      0.018164582550525665,
      -0.022773828357458115,
      -0.04268969967961311,
      -0.038153041154146194,
      0.011230007745325565,
      -0.01113932579755783,
      -0.014646847732365131,
      0.07999769598245621,
      0.02923031710088253,
      0.01327535230666399,
      0.007036473136395216,
      0.003911142703145742,
      0.03307802602648735,
      0.001491901814006269,
      0.0013144699623808265,
      -0.018776873126626015
    ],
    [
      -0.048707496374845505,
      0.10035310685634613,
      0.016159750521183014,
      -0.022436652332544327,
      0.0020820475183427334,
      0.007433499675244093,
      -0.01901071146130562,
      0.04751109704375267,
      -0.07632755488157272,
      0.04609488695859909,
      0.052485447376966476,
      0.0906272903084755,
      -0.053573571145534515,
      0.0026798956096172333,
      -0.010279963724315166,
      0.0375671312212944,
      0.06214658170938492,
      0.012859218753874302,
      -0.015791453421115875,
      0.005194318480789661,
      -0.010260911658406258,
      -0.0360146127641201,
      -0.06944901496171951,
      -0.01331375353038311,
      -0.06695857644081116,
      -0.007131750695407391,
      -0.013334373943507671,
      0.051076266914606094,
      0.036892279982566833,
      -0.05356577783823013,
      0.001390833524055779,
      0.01770697347819805,
      -0.015584724023938179,
      0.04996059834957123,
      0.05913674458861351,
      0.04303113743662834,
      -0.04973895102739334,
      -0.10132032632827759,
      -0.015758614987134933,
      -0.07582032680511475,
      0.00456120353192091,
      0.0597720667719841,
      -0.054507769644260406,
      -0.05514069274067879,
      -0.013502207584679127,
      -0.012824784964323044,
      0.06599240750074387,
      0.025845110416412354,
      0.04909172281622887,
      0.0059540001675486565,
      -0.028242357075214386,
      0.0077583566308021545,
      -0.03256256505846977,
      -0.0517490990459919,
      0.026775876060128212,
      0.03257322683930397,
      0.06368638575077057,
      -0.08233083039522171,
      0.07900305837392807,
      0.0536956861615181,
      0.008579093031585217,
      -0.009401123970746994,
      -0.00608054967597127,
      -0.02166665717959404,
      0.02746630646288395,
      0.015546084381639957,
      0.057078320533037186,
      -0.07474017143249512,
      0.012415613979101181,
      -0.032630763947963715,
      -0.025014137849211693,
      0.012056278064846992,
      -0.007185089867562056,
      -0.07595363259315491,
      0.05458599701523781,
      0.03477945551276207,
      0.0158573929220438,
      0.03746890276670456,
      -0.013299849815666676,
      0.06536177545785904,
      -0.06051222234964371,
      0.02329622022807598,
      -0.058136582374572754,
      -0.012334244325757027,
      -0.06519806385040283,
      -0.09919659048318863,
      0.03606532886624336,
      -0.07297840714454651,
      0.0721888616681099,
      0.0413883738219738,
      0.028120089322328568,
      0.0709904208779335,
      -0.10378841310739517,
      -0.03144439309835434,
      0.007927305065095425,
      0.050488367676734924,
      -0.010142001323401928,
      0.048003118485212326,
      0.03705437853932381,
      -0.026188921183347702,
      -0.010321537032723427,
      -0.08455758541822433,
      -0.0887463316321373,
      0.012351376935839653,
      -0.01860840432345867,
      0.00730566680431366,
      -0.03339679539203644,
      -0.022901564836502075,
      -0.029652927070856094,
      -0.01700882986187935,
      0.04169469326734543,
      0.035353243350982666,
      -0.018726574257016182,
      0.03419884294271469,
      0.009100595489144325,
      -0.04550028219819069,
      -0.037821393460035324,
      -0.028183041140437126,
      0.02908303216099739,
      0.025725724175572395,
      -0.02448003552854061,
      0.025887083262205124,
      0.04098949581384659,
      0.05380624905228615,
      0.041437678039073944,
      0.0602928064763546,
      0.04489881545305252,
      0.010919635184109211,
      -0.025031615048646927,
      -0.00714685395359993,
      -0.05841066688299179,
      -0.017552733421325684,
      -0.023944556713104248,
      0.0378761850297451,
      -0.026358649134635925,
      -0.0047738756984472275,
      0.02978064864873886,
      0.07549476623535156,
      0.007824738509953022,
      0.018276290968060493,
      0.02990376204252243,
      -0.07006798684597015,
      0.06438141316175461,
      -0.01060582883656025,
      0.02141459845006466,
      -0.030868764966726303,
      -0.07376096397638321,
      0.02883816882967949,
      0.02985367365181446,
      0.005411035381257534,
      0.010060044936835766,
      0.013984457589685917,
      0.01815362088382244,
      -0.06669986993074417,
      -0.029754934832453728,
      0.016617601737380028,
      0.00789045263081789,
      -0.06347381323575974,
      0.07749482244253159,
      -0.013460046611726284,
      0.05749654024839401,
      -0.0005000410019420087,
      -0.003809765912592411,
      -0.017133453860878944,
      -0.020114323124289513,
      -0.06810306012630463,
      0.001189322443678975,
      -0.01945917122066021,
      -0.02981249988079071,
      0.04732313007116318,
      -0.06639136373996735,
      0.003013892797753215,
      -0.00546660739928484,
      -0.05226034298539162,
      -0.019790416583418846,
      0.002445099176838994,
      -0.042121920734643936,
      0.03397248312830925,
      -0.03845885768532753,
      0.02113620564341545,
      0.027314724400639534,
      -0.04217032343149185,
      -0.016161398962140083,
      0.009608432650566101,
      0.08054930716753006,
      0.01104037556797266,
      -0.09637077897787094,
      0.03679665923118591,
      0.061757951974868774,
      -0.08163342624902725,
      0.0585913322865963,
      -0.013301277533173561,
      -0.07098355144262314,
      0.002496276283636689,
      -0.010315648280084133,
      0.02477264776825905,
      -0.014990723691880703,
      0.049176689237356186,
      -0.09558115899562836,
      -0.04300975799560547,
      0.08136604726314545,
      0.01722545735538006,
      -0.044139668345451355,
      -0.06847250461578369,
      0.03682110086083412,
      0.04045204445719719,
      -0.021550415083765984,
      -0.05859461426734924,
      -0.016767414286732674,
      0.05217071622610092,
      -0.05035848543047905,
      0.04066974297165871,
      0.057220444083213806,
      -0.028358757495880127,
      -0.05260876193642616,
      -0.05882655084133148,
      0.09125760942697525,
      0.0875643789768219,
      -0.04294447600841522,
      0.03184792771935463,
      0.14129303395748138,
      -0.07859494537115097,
      0.07492504268884659,
      -0.0033694906160235405,
      -0.08000936359167099,
      -0.031230615451931953,
      -0.0028068777173757553,
      0.029061416164040565,
      0.031621068716049194,
      0.02863536961376667,
      0.03985489159822464,
      0.09207107871770859,
      0.008870908990502357,
      0.02834136225283146,
      -0.12309045344591141,
      -0.007201826199889183,
      -0.009303110651671886,
      0.06130401790142059,
      0.02436748892068863,
      0.05376135930418968,
      -0.018928060308098793,
      0.04299263656139374,
      -0.006206103600561619,
      -0.03333088383078575,
      0.02999979443848133,
      -0.014139746315777302,
      0.022163260728120804,
      0.06562463939189911,
      -0.013989858329296112,
      -0.05087953433394432,
      0.0037638030480593443,
      -0.029563451185822487,
      0.011616924777626991,
      0.031318049877882004,
      -0.009115302935242653,
      0.015864942222833633,
      -0.03767272084951401,
      -0.08851497620344162,
      -0.07612445950508118,
      -0.00014053240010980517,
      0.007945681922137737,
      -0.09806254506111145,
      0.0029857412446290255,
      0.0004672881623264402,
      0.05659552663564682,
      0.045093048363924026,
      0.03018268384039402,
      -0.02245294861495495,
      -0.01843874156475067,
      0.10935492813587189,
      -0.02605537325143814,
      0.09402722865343094,
      0.09243729710578918,
      0.054963961243629456,
      0.08189132809638977,
      0.031253643333911896,
      -0.01750047318637371,
      0.025825629010796547,
      -0.05842697620391846,
      -0.028658676892518997,
      -0.01871245540678501,
      -0.016552111133933067,
      0.040393125265836716,
      0.010678675025701523,
      0.03395337238907814,
      0.024836143478751183,
      -0.013269076123833656,
      0.07696927338838577,
      -0.007444092072546482,
      -0.012664994224905968,
      0.108734630048275,
      -0.1036292240023613,
      -0.044780608266592026,
      -0.0458921454846859,
      -0.07429950684309006,
      0.08198858052492142,
      0.0943494364619255,
      -0.009600598365068436,
      0.07715307176113129,
      -0.0034288589376956224,
      0.01323890034109354,
      0.0645764023065567,
      0.024523796513676643,
      -0.029018253087997437,
      0.02994735911488533,
      -0.0008337468025274575,
      0.002929043723270297,
      0.06750902533531189,
      -0.009194363839924335,
      0.0015740030212327838,
      0.05171425640583038,
      0.01983155682682991,
      -0.002347018104046583,
      0.018568022176623344,
      0.08100811392068863,
      0.014766338281333447,
      0.035811956971883774,
      0.03476932272315025,
      -0.05518857389688492,
      -0.08752798289060593,
      -0.06260650604963303,
      -0.020563334226608276,
      -0.07773967832326889,
      0.05256155878305435,
      -0.026314660906791687,
      0.0724673792719841,
      0.0384538359940052,
      0.11262214928865433,
      0.022076724097132683,
      -0.04943719878792763,
      0.0015298579819500446,
      -0.024248864501714706,
      0.06913615018129349,
      0.006992843933403492,
      -0.03658274561166763,
      -0.0015981707256287336,
      0.06927049160003662,
      -0.008987330831587315,
      0.097677081823349,
      -0.002110529225319624,
      -0.044673264026641846,
      0.00182260200381279,
      0.05436916649341583,
      -0.015233496204018593,
      -0.06236203759908676,
      0.012472971342504025,
      0.04278775677084923,
      0.0028523129876703024,
      -0.035255320370197296,
      -0.02689785696566105,
      0.05105697736144066,
      -0.01583581231534481,
      0.008789260871708393,
      -0.004491110797971487,
      -0.012749942019581795,
      -0.03508251532912254,
      0.004774957429617643,
      -0.040290676057338715,
      -0.10217621922492981,
      -0.04342559725046158,
      -0.07591159641742706,
      0.028623618185520172,
      -0.0021041734144091606,
      0.06424374878406525,
      0.015839803963899612,
      -0.0294342041015625,
      -0.0036600125022232533,
      -0.011732595041394234,
      -0.013945113867521286,
      -0.0018231270369142294,
      -0.0847892090678215,
      0.017104612663388252,
      -0.058025799691677094,
      0.008501173928380013,
      0.04017780348658562,
      0.017322011291980743,
      -0.013421482406556606,
      0.005573478993028402,
      -0.04086095839738846,
      0.01078099012374878,
      0.015973282977938652,
      0.011739196255803108,
      0.04751342907547951,
      0.01866883970797062,
      -0.00805483479052782,
      0.002493735635653138,
      -0.005005295388400555,
      -0.04335072636604309,
      -0.040487173944711685,
      -0.07050507515668869,
      0.05688101425766945,
      0.02293981797993183,
      -0.04116866737604141,
      -0.062470242381095886,
      0.04542642459273338,
      0.008395369164645672,
      0.016600335016846657,
      0.05093989148736,
      0.05072275176644325,
      0.04750640317797661,
      0.009558577090501785,
      -0.06387394666671753,
      0.023178501054644585,
      -0.06064004823565483,
      -0.025013567879796028,
      0.06561090052127838,
      0.015732577070593834,
      0.04175283759832382,
      -0.040107350796461105,
      -0.048792764544487,
      0.049061018973588943,
      0.012694602832198143,
      -0.1008029580116272,
      0.046523626893758774,
      -0.0466027595102787,
      0.01975133642554283,
      0.01084886770695448,
      0.07169502973556519,
      0.0612931065261364,
      -0.010591463185846806,
      -0.07495123893022537,
      -0.0340011902153492,
      0.02559690922498703,
      0.0011226749047636986,
      0.056477807462215424,
      0.013614424504339695,
      -0.0462881363928318,
      0.017384404316544533,
      -0.07288017868995667,
      0.0230916328728199,
      -0.03059399127960205,
      0.029250338673591614,
      -0.07325752079486847,
      -0.02815037965774536,
      0.0064519415609538555,
      0.007654828019440174,
      -0.03745264187455177,
      -0.02089538797736168,
      -0.042633138597011566,
      -0.047008804976940155,
      0.09308362752199173,
      -0.021612336859107018,
      0.05203833803534508,
      0.05232325941324234,
      -0.009510807693004608,
      0.0730513334274292,
      -0.03470500186085701,
      -0.010648218914866447,
      0.011704452335834503,
      0.06287679821252823,
      0.07475677877664566,
      -0.023625003173947334,
      -0.034143030643463135,
      -0.047128599137067795,
      -0.042135678231716156,
      0.01303255744278431,
      -0.00541826244443655,
      0.0529768206179142,
      0.056048981845378876,
      0.02381712570786476,
      0.011949609033763409,
      -0.07936641573905945,
      0.07480712234973907,
      0.07431626319885254,
      0.04423133656382561,
      0.027805428951978683,
      0.04659397900104523,
      -0.05611151084303856,
      0.02974284254014492,
      0.06960836052894592,
      -0.04040808230638504,
      0.04320817068219185,
      -0.029723938554525375,
      0.03146227449178696,
      -0.023192845284938812,
      -0.0485868938267231,
      -0.042575787752866745,
      0.02253112755715847,
      -0.019575132057070732,
      -0.02486380934715271,
      0.0108547518029809,
      0.07442612200975418,
      -0.025739146396517754,
      0.0669061467051506,
      -0.011101692914962769,
      0.028026100248098373,
      -0.013878919184207916,
      0.06307338178157806,
      -0.04073195531964302,
      -0.033545080572366714,
      0.0326911136507988,
      -0.06617898494005203,
      -0.01353298220783472,
      -0.07532572746276855,
      0.041708242148160934,
      0.08333469182252884,
      -0.042697321623563766,
      0.030782772228121758,
      0.05372515320777893,
      -0.06534337252378464,
      0.0004244253796059638,
      0.029530096799135208,
      0.011826923117041588
    ],
    [
      -0.002351394621655345,
      0.009633606299757957,
      -0.05170542746782303,
      -0.03500497341156006,
      0.03657844290137291,
      -0.024670640006661415,
      0.07064971327781677,
      0.017320767045021057,
      0.12533220648765564,
      0.01869414933025837,
      0.011485183611512184,
      -0.0037328130565583706,
      -0.023475755006074905,
      -0.0009607214597053826,
      -0.031011134386062622,
      0.011466232128441334,
      -0.03599008545279503,
      0.03964155167341232,
      0.03362749144434929,
      0.0358361080288887,
      -0.005216944497078657,
      -0.004960061050951481,
      -0.03838503733277321,
      -0.030420295894145966,
      0.022379757836461067,
      -0.011248425580561161,
      0.01846855878829956,
      0.0476088672876358,
      -0.02454092912375927,
      0.003134162398055196,
      0.12001579999923706,
      0.014554275199770927,
      -0.06917727738618851,
      -0.05149983614683151,
      -0.047502413392066956,
      0.09382177889347076,
      0.048874013125896454,
      0.0343194380402565,
      0.062123626470565796,
      0.012013617902994156,
      0.005644544027745724,
      -0.007035217247903347,
      0.10965793579816818,
      -0.0003320522664580494,
      0.033113010227680206,
      0.07327085733413696,
      -0.02312077023088932,
      -0.06113601103425026,
      -0.042463406920433044,
      -0.04575170949101448,
      0.009980311617255211,
      0.008149636909365654,
      0.04006855934858322,
      0.02425334043800831,
      -0.018846379593014717,
      -0.0487554632127285,
      -0.054697755724191666,
      0.04834337159991264,
      -0.050818610936403275,
      0.008535503409802914,
      -0.0007140312227420509,
      -0.028870636597275734,
      0.014619139954447746,
      0.011144351214170456,
      0.01211090013384819,
      0.01131603866815567,
      -0.0648060217499733,
      0.06886544078588486,
      0.000340671103913337,
      -0.02820555306971073,
      0.006392332725226879,
      -0.036643821746110916,
      -0.015103913843631744,
      -0.002051497809588909,
      -0.032108865678310394,
      0.06462731212377548,
      0.037505701184272766,
      -0.016647862270474434,
      0.0430959053337574,
      -0.033668894320726395,
      0.013336241245269775,
      0.028201719745993614,
      -0.023164844140410423,
      0.028884882107377052,
      0.06731057167053223,
      -0.025672264397144318,
      -0.10915572196245193,
      0.012737439014017582,
      0.06994911283254623,
      0.04472958669066429,
      0.14276131987571716,
      0.06485754251480103,
      -0.032570526003837585,
      0.032746151089668274,
      -0.04577868804335594,
      -0.04631583392620087,
      -0.09817869961261749,
      -0.03859776630997658,
      0.05446309223771095,
      0.05546799302101135,
      0.0743216723203659,
      -0.07064071297645569,
      0.036216385662555695,
      -0.02651405520737171,
      0.008937939070165157,
      -0.030210839584469795,
      -0.08149898797273636,
      0.043035704642534256,
      -0.04038848727941513,
      -0.007354981265962124,
      -0.058795083314180374,
      -0.07265578955411911,
      -0.03145802766084671,
      0.014888020232319832,
      0.08005504310131073,
      0.02041385881602764,
      -0.07133880257606506,
      0.02025572583079338,
      0.08711596578359604,
      -0.027273256331682205,
      0.08766482770442963,
      0.03680215775966644,
      0.0051474301144480705,
      -0.05342337489128113,
      -0.020029595121741295,
      0.0654963031411171,
      0.07527817785739899,
      0.03503058850765228,
      -0.028496088460087776,
      0.029262034222483635,
      -0.002397296717390418,
      0.04086172953248024,
      0.04824838414788246,
      0.0034291783813387156,
      -0.02275131084024906,
      -0.006754078436642885,
      0.048025161027908325,
      -0.01345253735780716,
      -0.022849414497613907,
      -0.09232261031866074,
      -0.03288717195391655,
      -0.015248081646859646,
      -0.031118173152208328,
      0.03600066155195236,
      0.09285473078489304,
      0.08889288455247879,
      0.04948689788579941,
      0.04724625125527382,
      -0.026388272643089294,
      -0.053988970816135406,
      0.015276528894901276,
      0.07169735431671143,
      -0.02401968464255333,
      -0.06924419105052948,
      0.14535996317863464,
      0.014578836970031261,
      -0.011040955781936646,
      0.04104640334844589,
      0.006254144478589296,
      -0.07633605599403381,
      0.027296993881464005,
      -0.008988588117063046,
      0.016607772558927536,
      -0.03317192196846008,
      0.002695853589102626,
      -0.014547465369105339,
      0.04114242270588875,
      -0.008648271672427654,
      -0.058260802179574966,
      -0.07170184701681137,
      0.05078955739736557,
      -0.05057490989565849,
      0.0006008123164065182,
      -0.04269042983651161,
      -0.030185909941792488,
      0.05382976308465004,
      -0.025393515825271606,
      -0.020749440416693687,
      0.0003409045748412609,
      -0.027825264260172844,
      0.040888670831918716,
      0.025304395705461502,
      -0.014805741608142853,
      0.007635736837983131,
      -0.04909122735261917,
      -0.011860706843435764,
      -0.0669490322470665,
      0.016570912674069405,
      0.07204660773277283,
      0.019774237647652626,
      -0.017444025725126266,
      0.037975288927555084,
      -0.03486816957592964,
      -0.018758468329906464,
      0.013148819096386433,
      -0.009367162361741066,
      0.04402408003807068,
      0.08430756628513336,
      -0.018501821905374527,
      -0.08779134601354599,
      -0.024954387918114662,
      0.012129446491599083,
      0.050137005746364594,
      -0.05447995290160179,
      0.07393132150173187,
      -0.10820319503545761,
      0.020604820922017097,
      -0.03197383135557175,
      -0.01734982244670391,
      0.008426319807767868,
      0.0512639544904232,
      -0.012516375631093979,
      0.005494221579283476,
      0.0031752276699990034,
      0.015839839354157448,
      -0.03606082499027252,
      0.029478497803211212,
      0.059823568910360336,
      0.030612409114837646,
      -0.059893909841775894,
      -0.04989936202764511,
      0.00022013363195583224,
      -0.003400621935725212,
      0.02762235514819622,
      -0.01714560203254223,
      0.057465821504592896,
      0.019471002742648125,
      -0.008016446605324745,
      -0.0670415535569191,
      -0.005754564888775349,
      -0.05575389415025711,
      0.06873447448015213,
      0.07801655679941177,
      -0.03241167217493057,
      -0.002144177444279194,
      0.015885477885603905,
      -0.017052998766303062,
      -0.008424919098615646,
      0.049294229596853256,
      0.0693473219871521,
      0.016558300703763962,
      0.02363097295165062,
      0.09718102961778641,
      0.010516563430428505,
      -0.03201150894165039,
      0.013598528690636158,
      -0.0053447578102350235,
      -0.04833542928099632,
      -0.03276074305176735,
      -0.043514423072338104,
      0.005159854423254728,
      -0.03982090950012207,
      -0.051658984273672104,
      0.0386299230158329,
      0.0027118639554828405,
      0.04693404212594032,
      -0.015034444630146027,
      -0.056420233100652695,
      0.04399270936846733,
      -0.010518081486225128,
      0.03437797352671623,
      0.04211362451314926,
      0.05713586509227753,
      0.015003439970314503,
      0.03589076176285744,
      0.005465139169245958,
      0.012472366914153099,
      0.014659744687378407,
      -0.016265112906694412,
      0.021756090223789215,
      0.06570907682180405,
      0.01521890889853239,
      0.006892325356602669,
      -0.032339148223400116,
      -0.0498109795153141,
      -0.10782691091299057,
      -0.05729861557483673,
      -0.030273498967289925,
      0.018925843760371208,
      -0.05435348302125931,
      -0.0518789142370224,
      0.012323022820055485,
      0.0929657518863678,
      0.005549297202378511,
      -0.01742442138493061,
      0.08844383805990219,
      -0.016483230516314507,
      0.09992504119873047,
      0.039957813918590546,
      0.06806091964244843,
      -0.028510328382253647,
      0.049496378749608994,
      0.014429746195673943,
      0.019750533625483513,
      0.02938120812177658,
      -0.04843350499868393,
      -0.041329916566610336,
      -0.013398664072155952,
      -0.03610076382756233,
      0.01426497008651495,
      0.04829065501689911,
      0.09828657656908035,
      -0.005755321588367224,
      0.01675225980579853,
      0.00029616933898068964,
      0.0003753464843612164,
      0.021405717357993126,
      0.06744936853647232,
      0.023112375289201736,
      -0.03062579035758972,
      -0.05906592682003975,
      -0.021489188075065613,
      0.016562240198254585,
      -0.02926594205200672,
      0.02458455041050911,
      0.03251940757036209,
      -0.11193665862083435,
      -0.03886380046606064,
      -0.016348911449313164,
      -0.010336506180465221,
      -0.11357709020376205,
      0.11401236802339554,
      -0.050267983227968216,
      -0.014801395125687122,
      -0.022288234904408455,
      0.024622885510325432,
      0.0754256471991539,
      0.07526238262653351,
      -0.03136734664440155,
      0.032433200627565384,
      0.01397736556828022,
      -0.037625737488269806,
      0.028665276244282722,
      -0.016517091542482376,
      -0.03325442597270012,
      0.02456599473953247,
      -0.016512230038642883,
      0.07491271197795868,
      0.037067148834466934,
      0.03734597563743591,
      0.02770092338323593,
      0.029910845682024956,
      0.006567780859768391,
      0.06528915464878082,
      -0.003110973397269845,
      0.037530265748500824,
      0.026904674246907234,
      0.03419581055641174,
      -0.014867296442389488,
      -0.014545726589858532,
      0.022314364090561867,
      0.0667722150683403,
      0.00022941091447137296,
      0.02283334918320179,
      0.0036746361292898655,
      -0.03732245787978172,
      0.001325880759395659,
      0.04259885102510452,
      -0.08911344408988953,
      0.05042333900928497,
      0.004786500707268715,
      -0.02876732498407364,
      -0.033174704760313034,
      0.00862904079258442,
      -0.04557619243860245,
      -0.029854748398065567,
      0.043426018208265305,
      -0.017782751470804214,
      -0.02738286182284355,
      -0.009671162813901901,
      -0.008378524333238602,
      -0.034793321043252945,
      0.02290777675807476,
      0.029409456998109818,
      -0.004059747792780399,
      0.03087613917887211,
      0.0012184149818494916,
      0.002191037405282259,
      0.009580264799296856,
      -0.024955477565526962,
      0.026792379096150398,
      0.021834596991539,
      0.030988004058599472,
      -0.013122858479619026,
      0.0680328905582428,
      -0.05666618421673775,
      -0.007068489212542772,
      -1.5721394447609782e-05,
      -0.03791410103440285,
      -0.061031267046928406,
      0.03168055787682533,
      0.003268479835242033,
      -0.06337638944387436,
      0.02927919290959835,
      0.06521347910165787,
      -0.035745516419410706,
      -0.08106320351362228,
      0.0882154032588005,
      0.012056604959070683,
      -0.0043176449835300446,
      -0.05590792000293732,
      -0.03134135156869888,
      -0.02637995220720768,
      0.04620391130447388,
      0.0504157692193985,
      0.04649242013692856,
      -0.07459432631731033,
      0.027122892439365387,
      0.0056432257406413555,
      0.09483318030834198,
      -0.0248789694160223,
      -0.028292719274759293,
      -0.028348654508590698,
      -0.031520258635282516,
      -0.01273846160620451,
      -0.046677399426698685,
      0.0486861951649189,
      0.04863327369093895,
      0.05513635277748108,
      -0.06249093636870384,
      -0.06187772378325462,
      -0.092242032289505,
      0.015606359578669071,
      0.034263379871845245,
      0.03048376925289631,
      -0.020215369760990143,
      0.0021827726159244776,
      -0.046995315700769424,
      -0.03020978718996048,
      0.023829927667975426,
      -0.05098508670926094,
      0.009903525933623314,
      0.007359423208981752,
      0.02536984160542488,
      -0.012319258414208889,
      0.00973016768693924,
      0.00851257424801588,
      -0.03510325402021408,
      0.08522593230009079,
      -0.05891738831996918,
      -0.05148794502019882,
      0.03974718600511551,
      0.010174127295613289,
      -0.04956623539328575,
      -0.022692682221531868,
      -0.026692230254411697,
      0.09450933337211609,
      -0.05878155305981636,
      -0.001635813619941473,
      0.0706494003534317,
      0.0048645562492311,
      -0.043766796588897705,
      -0.05712084844708443,
      0.04256560653448105,
      -0.06261550635099411,
      -0.02586291916668415,
      0.025457486510276794,
      -0.000686461164150387,
      0.022149452939629555,
      0.09951695054769516,
      -0.07263829559087753,
      -0.06414517015218735,
      -0.013500940054655075,
      0.04353224113583565,
      0.03094564564526081,
      0.004102424252778292,
      -0.00046425190521404147,
      -0.019317030906677246,
      0.0008415285847149789,
      -0.0298899058252573,
      -0.0848081111907959,
      -0.005073344334959984,
      -0.04125406593084335,
      -0.04334413632750511,
      0.009969193488359451,
      0.04018716141581535,
      0.03931477665901184,
      -0.035784151405096054,
      0.07688047736883163,
      -0.053967397660017014,
      0.02385333552956581,
      0.07325610518455505,
      0.047648195177316666,
      -0.03346048295497894,
      0.03300454095005989,
      0.034744855016469955,
      0.025821074843406677,
      0.0011790062999352813,
      0.05002305656671524,
      -0.034153785556554794,
      0.07944745570421219,
      0.05691356956958771,
      -0.04083287715911865,
      -0.011187435127794743,
      0.015000822022557259,
      -0.014039273373782635,
      -0.038040898740291595,
      -0.01929696463048458,
      -0.015392942354083061,
      -0.02407362312078476,
      -0.04357215017080307,
      -0.0027447999455034733,
      -0.06080179288983345
    ],
    [
      -0.029447609558701515,
      -0.04033105447888374,
      -0.05176632106304169,
      -0.024447379633784294,
      -0.05650936812162399,
      0.05492178350687027,
      -0.004225824028253555,
      -0.07448001950979233,
      0.020108023658394814,
      0.04842090606689453,
      0.04786849394440651,
      0.02820834331214428,
      -0.012729588896036148,
      0.008702126331627369,
      0.015138295479118824,
      0.06443050503730774,
      -0.027025599032640457,
      -0.06932716071605682,
      -0.0025711606722325087,
      0.06688693910837173,
      0.02841166965663433,
      0.034363679587841034,
      -0.016338443383574486,
      0.020227566361427307,
      -0.10576493293046951,
      -0.05068095773458481,
      -0.03247300162911415,
      0.005217663012444973,
      0.07468995451927185,
      -0.033616553992033005,
      0.05557239428162575,
      -0.017076916992664337,
      -0.0020411843433976173,
      -0.0014148426707834005,
      -0.07628859579563141,
      0.10584525018930435,
      -0.015840454027056694,
      0.029080970212817192,
      0.0015715946210548282,
      0.036151427775621414,
      -0.007559142075479031,
      0.12708614766597748,
      -0.041581034660339355,
      0.03227562457323074,
      0.031266577541828156,
      0.04671625420451164,
      -0.062464598566293716,
      -0.020319664850831032,
      0.10232385247945786,
      0.0203888937830925,
      0.054670099169015884,
      -0.04676917940378189,
      0.0011725270887836814,
      0.010120881721377373,
      -0.045725077390670776,
      0.09830211848020554,
      0.04853153973817825,
      -0.023741481825709343,
      0.025606388226151466,
      -0.07836125046014786,
      -0.0034636149648576975,
      0.007890786044299603,
      -0.008182495832443237,
      0.04769118130207062,
      -0.007302132435142994,
      -0.03463222086429596,
      0.026729045435786247,
      0.0202044565230608,
      -0.049480099231004715,
      -0.059637024998664856,
      -0.04523635283112526,
      0.028549302369356155,
      -0.02231791242957115,
      -0.03026389144361019,
      -0.07706306874752045,
      0.0637107864022255,
      -0.006323178764432669,
      -0.04611247405409813,
      -0.00839032232761383,
      0.03574654459953308,
      -0.0453265979886055,
      -0.018401166424155235,
      0.012357925064861774,
      0.036182109266519547,
      -0.0015687381383031607,
      -0.10291222482919693,
      -0.0035308306105434895,
      -0.018065722659230232,
      -0.0012182951904833317,
      0.02077305316925049,
      -0.05179901793599129,
      0.09257784485816956,
      0.03049112856388092,
      0.06105068325996399,
      0.011711616069078445,
      0.009964202530682087,
      -0.023164043202996254,
      -0.008041011169552803,
      0.002424837788566947,
      0.009190090931952,
      0.03291979804635048,
      0.05642339214682579,
      -0.05893566086888313,
      0.035113267600536346,
      0.05119910463690758,
      0.02828449383378029,
      0.029412293806672096,
      0.03607949614524841,
      0.062475211918354034,
      -0.009699465706944466,
      0.018566913902759552,
      -0.014307905919849873,
      0.05270644277334213,
      0.04007567837834358,
      -0.05588742718100548,
      0.02754245698451996,
      0.017907671630382538,
      0.013006425462663174,
      -0.0013719693524762988,
      -0.056813038885593414,
      0.07020369172096252,
      -0.013841141946613789,
      -0.0001052405423251912,
      0.02303859032690525,
      -0.054839685559272766,
      -0.02304292842745781,
      0.031219443306326866,
      0.054096151143312454,
      0.06618168205022812,
      0.02591134048998356,
      -0.04135752469301224,
      -0.014198653399944305,
      0.00917914416640997,
      0.054345980286598206,
      0.023778263479471207,
      0.04639595001935959,
      -0.0029102517291903496,
      0.009001262485980988,
      -0.011095582507550716,
      0.004859179258346558,
      -0.04805944859981537,
      0.03798289969563484,
      0.008102591149508953,
      0.018513262271881104,
      -0.05512544512748718,
      0.03439867123961449,
      -0.01573372632265091,
      0.017254989594221115,
      0.015416469424962997,
      -0.015173769555985928,
      0.04112818092107773,
      0.021963177248835564,
      0.035416897386312485,
      -0.041468482464551926,
      0.012991775758564472,
      -0.03747907280921936,
      -0.0071671707555651665,
      0.021891167387366295,
      0.008608830161392689,
      0.0077523039653897285,
      -0.038365088403224945,
      -0.03374171257019043,
      0.07017678767442703,
      -0.01127172913402319,
      -0.01849531941115856,
      -0.03821573406457901,
      0.024025989696383476,
      0.09003037214279175,
      -0.03911598399281502,
      0.052465926855802536,
      -0.04135710000991821,
      -0.027605408802628517,
      0.030491745099425316,
      -0.034566521644592285,
      0.020514894276857376,
      0.008403640240430832,
      -0.07141823321580887,
      0.012572364881634712,
      -0.01677575707435608,
      -0.04184838384389877,
      -0.023960551247000694,
      0.016989240422844887,
      -0.06186377629637718,
      0.022851329296827316,
      0.04149416834115982,
      0.03938673064112663,
      0.008634490892291069,
      0.002019584644585848,
      0.06852711737155914,
      -0.053395602852106094,
      0.054345399141311646,
      0.020387273281812668,
      -0.0468619242310524,
      0.05404173210263252,
      -0.013567760586738586,
      0.03944773226976395,
      -0.09783690422773361,
      -0.020863134413957596,
      -0.09142622351646423,
      -0.026452334597706795,
      -0.007933194749057293,
      0.10796047747135162,
      0.02061314322054386,
      -0.026509344577789307,
      -0.021878467872738838,
      0.020268693566322327,
      0.007680506911128759,
      -0.09097601473331451,
      0.03137416020035744,
      0.0027274154126644135,
      0.027196142822504044,
      0.004976019263267517,
      0.03808946907520294,
      0.014991788193583488,
      0.024315182119607925,
      0.013627494685351849,
      -0.021510550752282143,
      0.01793072372674942,
      -0.040727850049734116,
      0.0073944516479969025,
      0.06027812510728836,
      0.0112273208796978,
      -0.023312479257583618,
      -0.01855725422501564,
      -0.02946714498102665,
      0.03783285990357399,
      0.0362350270152092,
      -0.00782347097992897,
      0.009584209881722927,
      -0.07627763599157333,
      0.009634304791688919,
      0.08791199326515198,
      0.03223085030913353,
      -0.006665034685283899,
      -0.015383077785372734,
      -0.10276611894369125,
      0.04287667199969292,
      -0.018509240821003914,
      0.02282068505883217,
      -0.09649255871772766,
      0.17039388418197632,
      0.046882934868335724,
      -0.04554709047079086,
      -0.010295530781149864,
      -0.059652265161275864,
      0.037535153329372406,
      -0.05823409929871559,
      0.06457407772541046,
      -0.041281264275312424,
      -0.047180239111185074,
      0.03663134574890137,
      -0.004996949806809425,
      -0.07616069912910461,
      0.034999214112758636,
      0.018360072746872902,
      -0.04932703822851181,
      0.01683323085308075,
      -0.06096894294023514,
      -0.007519483100622892,
      -0.0011726522352546453,
      0.08030994981527328,
      -0.1129128560423851,
      -0.027890782803297043,
      0.05189866945147514,
      -0.003310062922537327,
      0.069043830037117,
      0.0008473893976770341,
      -0.027444247156381607,
      -0.0003179262566845864,
      0.04288530349731445,
      0.030781980603933334,
      0.029464799910783768,
      0.030706748366355896,
      0.05054360255599022,
      0.010084535926580429,
      -0.077385812997818,
      0.042870260775089264,
      0.05425324663519859,
      0.07492781430482864,
      0.04674764350056648,
      -0.016801709309220314,
      -0.0457024909555912,
      0.04312095791101456,
      -0.0451836921274662,
      -0.06166892498731613,
      -0.03561970219016075,
      0.00654461607336998,
      0.03732042759656906,
      -0.021563874557614326,
      0.013387477956712246,
      -0.0349089689552784,
      -0.041881583631038666,
      0.005407888442277908,
      -0.0577806681394577,
      -0.005874917842447758,
      -0.019303634762763977,
      -0.018582958728075027,
      0.006409748457372189,
      0.03203960135579109,
      -0.03453674539923668,
      0.0333954393863678,
      -0.0010496940230950713,
      -0.0381915345788002,
      -0.011069299653172493,
      0.007748366799205542,
      0.033510707318782806,
      -0.06151315197348595,
      0.11256788671016693,
      0.03927188739180565,
      0.036946024745702744,
      -0.009304597042500973,
      -0.14135383069515228,
      0.011803253553807735,
      0.07211736589670181,
      0.005504162050783634,
      0.016565052792429924,
      0.05096300691366196,
      -0.02164471335709095,
      0.034203577786684036,
      0.0037399008870124817,
      -0.07911640405654907,
      0.012121542356908321,
      0.11346292495727539,
      0.0841127559542656,
      0.04987478256225586,
      0.016064144670963287,
      0.0007763105677440763,
      0.012699885293841362,
      0.03690779581665993,
      -0.031162185594439507,
      -0.047674983739852905,
      0.008964918553829193,
      -0.032588742673397064,
      -0.01539565809071064,
      -0.06452415883541107,
      0.01621379517018795,
      -0.07745886594057083,
      0.09934511035680771,
      0.010383278131484985,
      0.04320518299937248,
      0.022617174312472343,
      0.018720729276537895,
      -0.03219977021217346,
      -0.059095486998558044,
      -0.04455285519361496,
      0.07102742791175842,
      0.03042898327112198,
      -0.012906071729958057,
      0.022196944802999496,
      -0.055374182760715485,
      -0.016273438930511475,
      0.027702828869223595,
      -0.10429874062538147,
      -0.04014704003930092,
      0.03465878963470459,
      -0.026782119646668434,
      0.004834170453250408,
      0.06609988957643509,
      -0.017856894060969353,
      -0.05371566861867905,
      -0.047527726739645004,
      -0.05666385963559151,
      -0.027170948684215546,
      -0.03545643389225006,
      -0.0103191202506423,
      -0.10408464074134827,
      0.018484536558389664,
      0.08037165552377701,
      0.015013965778052807,
      -0.06899125874042511,
      -0.06720525026321411,
      0.021598661318421364,
      -0.03223219886422157,
      0.003496151650324464,
      -0.018052902072668076,
      0.026324806734919548,
      0.07033666223287582,
      -0.04575767740607262,
      0.030585844069719315,
      -0.08151271939277649,
      -0.0017297744052484632,
      0.03852606937289238,
      0.03274118900299072,
      0.02893889509141445,
      -0.07711993157863617,
      0.06545645743608475,
      0.044989150017499924,
      -0.03130669146776199,
      0.0024143278133124113,
      -0.09215840697288513,
      0.04033665731549263,
      0.033947717398405075,
      0.04986722022294998,
      -0.024394383653998375,
      -0.014994258061051369,
      -0.10316633433103561,
      0.10294730961322784,
      -0.03583500534296036,
      0.0335228256881237,
      -0.03783399611711502,
      -0.08830398321151733,
      -0.010880976915359497,
      0.0639185756444931,
      -0.013173770159482956,
      0.02243000827729702,
      -0.024017253890633583,
      -0.05784175172448158,
      0.06900911778211594,
      0.05304594337940216,
      -0.0989934653043747,
      0.0198721531778574,
      0.0642196387052536,
      0.02854986861348152,
      0.04342801868915558,
      0.01691594161093235,
      -0.01405358500778675,
      0.010440281592309475,
      -0.016003508120775223,
      -0.0049217501655220985,
      -0.06027133762836456,
      -0.047389622777700424,
      0.02387024462223053,
      0.026691114529967308,
      0.003962915390729904,
      0.08365067839622498,
      0.027868056669831276,
      -0.0348350964486599,
      -0.007083261851221323,
      -0.0642034262418747,
      -0.04524090513586998,
      -0.05556846037507057,
      0.035438090562820435,
      -0.012433034367859364,
      0.007937622256577015,
      -0.10102017968893051,
      0.05643239989876747,
      0.00412047328427434,
      0.06479813903570175,
      -0.05352352559566498,
      -0.01840028539299965,
      -0.038371242582798004,
      -0.00764058344066143,
      -0.03301461786031723,
      0.01954730600118637,
      0.018148507922887802,
      -0.05394190549850464,
      0.037352629005908966,
      0.07535691559314728,
      -0.011328761465847492,
      -0.07366199791431427,
      0.011711182072758675,
      0.050319090485572815,
      0.009557652287185192,
      0.04164610058069229,
      -0.018718183040618896,
      -0.044853873550891876,
      0.0006900953012518585,
      -0.010240772739052773,
      -0.011373432353138924,
      -0.04339459538459778,
      -0.009505038149654865,
      -0.02076769433915615,
      -0.014809847809374332,
      0.04725088179111481,
      0.025435538962483406,
      0.020775364711880684,
      0.017286308109760284,
      0.02003677375614643,
      0.08400151878595352,
      0.0010160148376598954,
      -0.02319316752254963,
      0.037880975753068924,
      -0.031306542456150055,
      -0.010462787002325058,
      -0.01413787342607975,
      -6.480141746578738e-05,
      0.013542050495743752,
      0.0017570419004186988,
      0.16389888525009155,
      0.028811344876885414,
      -0.06464031338691711,
      -0.01027952041476965,
      0.03933575004339218,
      -0.028819076716899872,
      -0.06548205763101578,
      0.016125818714499474,
      0.038392096757888794,
      -0.013890421949326992,
      -0.01681509055197239,
      0.042022496461868286,
      -0.1098194271326065,
      0.0634402483701706,
      -0.024445666000247,
      -0.01883808895945549,
      -0.01479649543762207,
      -0.036781683564186096,
      0.0399961918592453,
      -0.018924737349152565,
      0.0669475719332695,
      0.0037648577708750963,
      0.0655585452914238,
      0.08009753376245499,
      -0.026439260691404343
    ],
    [
      0.0469890832901001,
      0.04133673012256622,
      0.047811705619096756,
      -0.05283987894654274,
      -8.32307487144135e-05,
      0.025736168026924133,
      -0.12614528834819794,
      -0.017805585637688637,
      0.03009018674492836,
      0.0839163139462471,
      0.04533202946186066,
      0.05112648010253906,
      0.032961972057819366,
      -0.06130257248878479,
      -0.04498778656125069,
      -0.005677368957549334,
      -0.004998913500458002,
      -0.04721495509147644,
      0.02786315605044365,
      -0.008194902911782265,
      -0.02105088159441948,
      0.04706113040447235,
      0.04934166371822357,
      -0.025246132165193558,
      0.050570156425237656,
      0.05100283771753311,
      -0.01663023792207241,
      -0.013067837804555893,
      0.04948761686682701,
      0.04320766031742096,
      -0.020300621166825294,
      0.001505876425653696,
      -0.026208505034446716,
      0.01260526292026043,
      -0.0008033727644942701,
      -0.024053897708654404,
      -0.04168672487139702,
      -0.0225572157651186,
      -0.037335775792598724,
      -0.054436568170785904,
      0.07804911583662033,
      -0.04923539608716965,
      0.05253937095403671,
      -0.034259650856256485,
      -0.016320649534463882,
      0.018455540761351585,
      0.035209350287914276,
      0.028070854023098946,
      0.08327101171016693,
      -0.06264546513557434,
      0.010523983277380466,
      -0.043320946395397186,
      -0.07543279230594635,
      0.042139504104852676,
      -0.0009254917385987937,
      0.001973363570868969,
      0.08591750264167786,
      -0.046848442405462265,
      0.017500029876828194,
      0.000661123194731772,
      -0.0012261694064363837,
      0.013684187084436417,
      -0.0010574590414762497,
      0.023371178656816483,
      0.0444173663854599,
      -0.002957202959805727,
      -0.045763272792100906,
      -0.02405717968940735,
      -0.0004976115887984633,
      -0.0028577554039657116,
      -0.030564671382308006,
      -0.022078339010477066,
      0.0937955304980278,
      -0.02003609389066696,
      0.02767268754541874,
      0.01720711775124073,
      0.0079491026699543,
      -0.05264531821012497,
      0.007449717726558447,
      0.0558297373354435,
      -0.022137925028800964,
      0.060764532536268234,
      -0.03311638906598091,
      0.050996679812669754,
      0.01062054093927145,
      0.024592282250523567,
      0.05768117681145668,
      0.1002735048532486,
      -0.07726538181304932,
      -0.033102139830589294,
      0.07468219846487045,
      -0.002468216698616743,
      -0.07572504878044128,
      0.08741649985313416,
      -0.0011745650554075837,
      -0.04836060106754303,
      0.01772916689515114,
      0.049176763743162155,
      0.05222485959529877,
      -0.027627810835838318,
      -0.03292229026556015,
      0.03696354851126671,
      0.05438927933573723,
      0.004248976241797209,
      0.06523577868938446,
      0.05558173358440399,
      -0.038267239928245544,
      0.03344132378697395,
      0.016849830746650696,
      0.1115904301404953,
      -0.06637903302907944,
      -0.019790945574641228,
      -0.025286685675382614,
      -0.034809503704309464,
      0.0018186131492257118,
      -0.05047804117202759,
      -0.04689902439713478,
      0.0001026918544084765,
      -0.0555906742811203,
      -0.018794069066643715,
      0.0544830746948719,
      -0.009411124512553215,
      0.010814611800014973,
      -0.008881144225597382,
      -0.002881251974031329,
      -0.018877409398555756,
      0.01882147043943405,
      -0.04339708387851715,
      -0.01695450395345688,
      -0.05912839621305466,
      0.018690187484025955,
      -0.008852639235556126,
      -0.043368108570575714,
      -0.0005929768667556345,
      0.01941370591521263,
      -0.052371494472026825,
      -0.05110916122794151,
      0.034337807446718216,
      -0.1390359103679657,
      -0.06774921715259552,
      -0.02403528243303299,
      0.03721088171005249,
      -0.059612005949020386,
      0.07587531208992004,
      0.06670812517404556,
      0.03653470426797867,
      -0.024022508412599564,
      -0.08904283493757248,
      -0.03293831646442413,
      0.0254353117197752,
      -0.05930938571691513,
      -0.10454856604337692,
      0.09434305131435394,
      -0.052282512187957764,
      -0.002420842181891203,
      -0.023927588015794754,
      -0.04450301453471184,
      -0.11967814713716507,
      -0.06535632163286209,
      0.012685317546129227,
      0.048220325261354446,
      -2.1584062778856605e-05,
      0.030663713812828064,
      -0.016434594988822937,
      0.04645974934101105,
      0.021007033064961433,
      0.0023988857865333557,
      -0.08449391275644302,
      -0.0695786252617836,
      0.0587066151201725,
      0.054886333644390106,
      -0.013935420662164688,
      0.018167734146118164,
      -0.008027897216379642,
      0.06412248313426971,
      -0.04345288127660751,
      -0.028697004541754723,
      0.03525662049651146,
      -0.0035084914416074753,
      -0.014046341180801392,
      0.009350505657494068,
      0.05303254723548889,
      0.05340760573744774,
      -0.12770071625709534,
      0.006429701577872038,
      -0.04290223866701126,
      0.01021772064268589,
      0.024198662489652634,
      -0.03724008798599243,
      0.020451020449399948,
      0.008356200531125069,
      0.027677632868289948,
      -0.027671216055750847,
      -0.059206776320934296,
      0.05061030760407448,
      0.02433868683874607,
      0.0031720150727778673,
      -0.04558037593960762,
      0.025601554661989212,
      0.0011168626369908452,
      -0.007118620444089174,
      -0.027067195624113083,
      0.05955084413290024,
      0.03624660149216652,
      -0.04239290952682495,
      -0.002819447312504053,
      0.0451456680893898,
      0.012308353558182716,
      0.003397689200937748,
      -0.04608502611517906,
      -0.04222488775849342,
      0.08395549654960632,
      0.024710409343242645,
      -0.014922445639967918,
      -0.0040380218997597694,
      -0.07779064029455185,
      -0.026894399896264076,
      -0.017531059682369232,
      0.009997163899242878,
      0.018076101318001747,
      -0.04907446354627609,
      0.04657011106610298,
      0.009953203611075878,
      -0.07314497977495193,
      -0.040455397218465805,
      0.01728714443743229,
      0.0016733815427869558,
      -0.012630479410290718,
      -0.04692145809531212,
      -0.007183545269072056,
      -0.016596445813775063,
      0.014148296788334846,
      0.0037312586791813374,
      -0.0025871077086776495,
      0.0313541516661644,
      -0.05127749219536781,
      0.058036576956510544,
      -0.047519803047180176,
      -0.05003317445516586,
      -0.0551946684718132,
      0.026489216834306717,
      0.022150546312332153,
      0.0004654493823181838,
      0.01661575213074684,
      -0.06641700863838196,
      0.023736998438835144,
      -0.03654791787266731,
      0.018801918253302574,
      -0.013532265089452267,
      -0.09036687761545181,
      -0.0237978957593441,
      0.05386892706155777,
      0.024427277967333794,
      -0.06099250167608261,
      0.0015639369376003742,
      0.018885033205151558,
      -0.0035869504790753126,
      -0.0009218963095918298,
      -0.045597564429044724,
      -0.006813201121985912,
      0.0022376221604645252,
      0.001394349499605596,
      0.016090231016278267,
      -0.003357570618391037,
      0.06384803354740143,
      0.05104231834411621,
      -0.032606806606054306,
      0.05079023540019989,
      -0.018785351887345314,
      0.16383466124534607,
      -0.01529483962804079,
      0.07676814496517181,
      0.01272803544998169,
      -0.01053317729383707,
      -0.025913378223776817,
      0.035504475235939026,
      0.02728997729718685,
      0.0010596216889098287,
      0.019071783870458603,
      0.08411481231451035,
      0.0041348435916006565,
      0.05097164958715439,
      -0.029385719448328018,
      0.04173291474580765,
      0.025383038446307182,
      -0.04396561160683632,
      -0.030167007818818092,
      0.04757732152938843,
      0.033127669245004654,
      0.015318585559725761,
      0.07218576222658157,
      -0.03620555251836777,
      -0.014451786875724792,
      -0.02985215373337269,
      -0.015414983965456486,
      0.034936271607875824,
      -0.06247818097472191,
      -0.022547638043761253,
      0.02963157929480076,
      -0.023720191791653633,
      0.030046312138438225,
      -0.010215207934379578,
      -0.04280465096235275,
      0.024914536625146866,
      0.030574988573789597,
      -0.0008224421180784702,
      -0.011890282854437828,
      -0.0010173629270866513,
      0.0033256877213716507,
      0.015599067322909832,
      -0.0556982085108757,
      0.056764695793390274,
      0.048621732741594315,
      -0.0432019904255867,
      -0.012785677798092365,
      -0.03098662570118904,
      0.03367878496646881,
      -0.03373963385820389,
      0.007241257932037115,
      -0.030420806258916855,
      -0.02241411618888378,
      -0.029245594516396523,
      0.01543701533228159,
      -0.016298212110996246,
      -0.05408173054456711,
      -0.08491510152816772,
      -0.01501479186117649,
      -0.054216884076595306,
      -0.010326584801077843,
      0.08192089200019836,
      -0.03025091253221035,
      -0.010946189984679222,
      0.008797447197139263,
      0.02488560415804386,
      -0.04318246617913246,
      -0.029571568593382835,
      -0.01687466911971569,
      0.059169068932533264,
      0.009764926508069038,
      -0.0010956735350191593,
      -0.01488103810697794,
      0.038665398955345154,
      0.015111446380615234,
      -0.0878484696149826,
      0.03579116612672806,
      0.026916706934571266,
      0.077982597053051,
      -0.011545133776962757,
      -0.04812043160200119,
      -0.005915842950344086,
      -0.04351470619440079,
      -0.04037053883075714,
      0.0115388548001647,
      0.0004918785416521132,
      0.010614623315632343,
      -0.08811784535646439,
      -0.005864435341209173,
      0.07222598046064377,
      -0.0675916075706482,
      -0.0004758402646984905,
      0.02086700312793255,
      0.03260616958141327,
      -0.08663198351860046,
      0.0853840559720993,
      -0.0024580801837146282,
      -0.03425486385822296,
      0.027302175760269165,
      -0.0060870461165905,
      -0.05403989180922508,
      0.03881300613284111,
      -0.054381344467401505,
      -0.020069507881999016,
      0.051604434847831726,
      0.03093828074634075,
      -0.02773606777191162,
      0.03510487452149391,
      -0.002369627356529236,
      -0.08239708840847015,
      -0.004137936048209667,
      -0.026024343445897102,
      -0.06740499287843704,
      -0.03939121589064598,
      -0.006105522159487009,
      -0.014432531781494617,
      -0.12162953615188599,
      -0.0065357862040400505,
      -0.053332678973674774,
      -0.016916412860155106,
      0.014354363083839417,
      -0.03067397139966488,
      -0.021453436464071274,
      0.050486333668231964,
      -0.06103232130408287,
      -0.012890522368252277,
      6.496995047200471e-05,
      0.0012878593988716602,
      0.005906786769628525,
      -0.02733713760972023,
      0.058175984770059586,
      0.06861577928066254,
      0.03923486918210983,
      -0.018583329394459724,
      -0.050691742449998856,
      -0.03835434466600418,
      -0.004975930787622929,
      -0.02114712819457054,
      -0.012556692585349083,
      0.04767412319779396,
      -0.025808099657297134,
      0.06972512602806091,
      0.01719840057194233,
      0.0031829015351831913,
      0.004439390264451504,
      -0.009654993191361427,
      0.004146671388298273,
      -0.021313229575753212,
      -0.005758577957749367,
      -0.07055686414241791,
      0.08628048747777939,
      0.0237501822412014,
      0.062427692115306854,
      -0.07955987006425858,
      0.06214093416929245,
      -0.030337108299136162,
      -0.044267162680625916,
      -0.0030883909203112125,
      0.07153991609811783,
      -0.07208595424890518,
      0.03396293520927429,
      -0.005096635781228542,
      0.03924088180065155,
      -0.030077742412686348,
      -0.01275363378226757,
      -0.06911814957857132,
      0.002364668995141983,
      0.10205279290676117,
      0.07801690697669983,
      -0.03342039883136749,
      -0.03446026146411896,
      0.029560809955000877,
      0.05208783224225044,
      0.0413631871342659,
      0.009862269274890423,
      -0.05745571851730347,
      0.010507490485906601,
      -0.05604716017842293,
      0.017362162470817566,
      0.03582876920700073,
      -0.03292679414153099,
      -0.0032102884724736214,
      -0.05878521874547005,
      -0.042844414710998535,
      -0.0905764251947403,
      0.08074938505887985,
      -0.030025703832507133,
      -0.028468824923038483,
      0.06168140843510628,
      0.04630131274461746,
      0.06220562756061554,
      -0.01587715931236744,
      -0.0031357049010694027,
      -0.09790845215320587,
      0.01805518940091133,
      0.07280734926462173,
      -0.013664201833307743,
      0.057887520641088486,
      -0.032634176313877106,
      -0.0006358733517117798,
      -0.01464475505053997,
      0.012999850325286388,
      -0.021266615018248558,
      0.024505307897925377,
      -0.014580078423023224,
      -0.10206660628318787,
      -0.019989782944321632,
      -0.011241331696510315,
      0.002949133049696684,
      -0.03304635360836983,
      -0.05770624801516533,
      -0.04068871960043907,
      -0.019277319312095642,
      0.02102883718907833,
      0.0015098134754225612,
      3.3913320294232108e-06,
      -0.0289886686950922,
      0.030467849224805832,
      0.04285963252186775,
      0.015079730190336704,
      -0.019033122807741165,
      0.018412087112665176,
      -0.0012684152461588383,
      -0.032422129064798355,
      -0.009059091098606586,
      0.019557852298021317,
      0.005043477285653353,
      0.01057363674044609,
      0.037562046200037,
      0.08957742154598236,
      0.02233094349503517,
      0.03869107365608215,
      -0.0022177856881171465,
      -0.02340075559914112,
      -0.037700727581977844
    ],
    [
      -0.04351981356739998,
      -0.0350404717028141,
      0.050875015556812286,
      -0.018026502802968025,
      0.012992520816624165,
      0.06124793365597725,
      0.03655898571014404,
      -0.020635219290852547,
      -0.012772358022630215,
      0.04923558235168457,
      0.00019916896417271346,
      -0.026581676676869392,
      0.020027367398142815,
      0.046391528099775314,
      -0.006849158555269241,
      0.018912306055426598,
      -0.05987248569726944,
      -0.009403878822922707,
      0.015408242121338844,
      0.010175964795053005,
      -0.04607568308711052,
      0.04102012887597084,
      -0.007223584223538637,
      0.10172510147094727,
      -0.07307760417461395,
      -0.051924459636211395,
      0.0002734505687840283,
      -0.004197610542178154,
      0.01061804685741663,
      0.02912622131407261,
      0.028724398463964462,
      -0.04290403798222542,
      0.020183183252811432,
      0.03019041195511818,
      0.026008211076259613,
      -0.033586904406547546,
      -0.01778499409556389,
      0.05564257130026817,
      -0.003446771763265133,
      0.023240672424435616,
      0.05912107229232788,
      0.0016497221076861024,
      0.06850418448448181,
      0.06918859481811523,
      -0.024750757962465286,
      -0.005409132223576307,
      -0.07907754927873611,
      -0.019432421773672104,
      -0.025182507932186127,
      -0.0039655836299061775,
      -0.10898613184690475,
      0.01648595742881298,
      -0.009563545696437359,
      0.004948196467012167,
      0.09808351844549179,
      0.03385530039668083,
      -0.014354404993355274,
      -0.026838181540369987,
      0.04658089578151703,
      0.004771790001541376,
      -0.04025324434041977,
      0.11877138912677765,
      -0.0033251717686653137,
      0.02131287381052971,
      -0.010054281912744045,
      0.07507214695215225,
      -0.027355387806892395,
      0.022675134241580963,
      -0.05129239708185196,
      -0.11714616417884827,
      -0.05339604616165161,
      0.07081212103366852,
      -0.026522863656282425,
      -0.04986532777547836,
      0.0624970942735672,
      -0.06355196982622147,
      -0.039412666112184525,
      -0.010181855410337448,
      -0.012983457185328007,
      0.009718108922243118,
      -0.008552740328013897,
      0.019022969529032707,
      -0.02082616649568081,
      0.0028571162838488817,
      0.04629085585474968,
      -0.03605649992823601,
      0.0014122910797595978,
      -0.06004845350980759,
      0.009686189703643322,
      -0.049878936260938644,
      0.040092457085847855,
      0.08875368535518646,
      0.03487410768866539,
      0.04419747367501259,
      0.004249539226293564,
      0.07277452200651169,
      0.11966397613286972,
      0.009224074892699718,
      -0.00845285877585411,
      -0.024609439074993134,
      0.004798574838787317,
      -0.026794929057359695,
      -0.013216809369623661,
      0.004661071579903364,
      0.02833622880280018,
      -0.044508229941129684,
      -0.003480049315840006,
      -0.04484010115265846,
      0.03374152258038521,
      0.04013456404209137,
      0.02843742072582245,
      -0.08136224001646042,
      -0.02832181565463543,
      0.02511032670736313,
      -0.022044982761144638,
      0.08566921949386597,
      0.016622338443994522,
      -0.02352549508213997,
      0.07822604477405548,
      -0.0003890048828907311,
      -0.034089621156454086,
      0.01167213637381792,
      -0.010407168418169022,
      0.13665097951889038,
      0.005289419554173946,
      0.025157485157251358,
      0.07558891922235489,
      -0.08521641790866852,
      0.014230448752641678,
      -0.022234514355659485,
      -0.028419138863682747,
      0.007839176803827286,
      0.0026032407768070698,
      0.011301725171506405,
      0.03678712248802185,
      0.12525834143161774,
      -0.021504495292901993,
      -0.030585743486881256,
      0.020306559279561043,
      0.00731273740530014,
      0.016655728220939636,
      0.018279878422617912,
      -0.00243769190274179,
      -0.03684030473232269,
      0.010430270805954933,
      -0.015325904823839664,
      0.028988320380449295,
      -0.04639405757188797,
      -0.010384284891188145,
      -0.10314849764108658,
      0.0111340107396245,
      -0.06461110711097717,
      0.06649845093488693,
      0.05020882934331894,
      -0.03393954038619995,
      -0.030859075486660004,
      0.0443066731095314,
      0.025984052568674088,
      0.07388034462928772,
      0.015074583701789379,
      0.0004470950225368142,
      0.022487623617053032,
      -0.004321458283811808,
      -0.07322341203689575,
      0.07678616046905518,
      -0.004832754377275705,
      0.0438983328640461,
      -0.08561287075281143,
      -0.02406756766140461,
      0.047750718891620636,
      0.020517054945230484,
      0.040772244334220886,
      -0.005578737240284681,
      -0.02604174055159092,
      0.01809549145400524,
      -0.05530063062906265,
      0.020840324461460114,
      0.0520341694355011,
      0.01688063144683838,
      0.05376273766160011,
      -0.040270231664180756,
      0.028039943426847458,
      0.0839124321937561,
      0.03418196737766266,
      -0.07140573859214783,
      0.03407471999526024,
      0.04461660236120224,
      -0.055074624717235565,
      -0.031809695065021515,
      -0.023098593577742577,
      -0.00632468331605196,
      0.02921157144010067,
      -0.04639580100774765,
      0.05387302115559578,
      -0.06037302687764168,
      0.04063336178660393,
      -0.03454459086060524,
      -0.031769365072250366,
      0.11659695208072662,
      -0.09643055498600006,
      -0.05195999890565872,
      0.06710225343704224,
      -0.0021542322356253862,
      0.03560556471347809,
      0.05506294593214989,
      -0.030453559011220932,
      -0.009699756279587746,
      -0.034526288509368896,
      0.10770720988512039,
      0.0419202521443367,
      0.014515114016830921,
      -0.04671407863497734,
      0.03667212277650833,
      0.009735532104969025,
      0.06297682225704193,
      0.02193026803433895,
      -0.008387083187699318,
      -0.007450195029377937,
      -0.02035420760512352,
      0.05632103979587555,
      0.004550134763121605,
      -0.009171027690172195,
      0.09320336580276489,
      -0.006404374726116657,
      0.02307274378836155,
      -0.00016251052147708833,
      0.0372045561671257,
      0.02920747734606266,
      0.055007435381412506,
      -0.056485094130039215,
      0.015546946786344051,
      -0.02025672420859337,
      -0.1360470950603485,
      0.0475257933139801,
      -0.026481859385967255,
      -0.01754937507212162,
      -0.025402110069990158,
      -0.02094135619699955,
      0.05360132083296776,
      -0.016767792403697968,
      0.015464856289327145,
      -0.020263217389583588,
      -0.03088385984301567,
      0.0025313806254416704,
      -0.05437897890806198,
      -0.015042915940284729,
      0.0500330850481987,
      0.03699411079287529,
      -0.07089988887310028,
      -0.04939480125904083,
      -0.021832216531038284,
      0.03983529284596443,
      0.025803588330745697,
      -0.07022473216056824,
      -0.04675174877047539,
      0.013503585010766983,
      0.059956103563308716,
      -0.0043424139730632305,
      0.019709479063749313,
      -0.032527435570955276,
      0.0006946562207303941,
      -0.03533904254436493,
      -0.024654248729348183,
      -0.09443404525518417,
      0.006605228409171104,
      0.02968943864107132,
      0.032674383372068405,
      -0.054634299129247665,
      0.02430722303688526,
      0.023892836645245552,
      0.019054211676120758,
      0.05439657345414162,
      0.023845156654715538,
      -0.024498147889971733,
      0.03020597994327545,
      0.020022206008434296,
      0.03438558429479599,
      -0.053979698568582535,
      -0.014435648918151855,
      0.03869614377617836,
      -0.016488751396536827,
      -0.05286169797182083,
      -0.012202008627355099,
      0.07285201549530029,
      -0.021334918215870857,
      -0.045547954738140106,
      -0.042033273726701736,
      0.034868523478507996,
      0.014913830906152725,
      -0.04175080731511116,
      0.000720455776900053,
      0.014378998428583145,
      0.031671032309532166,
      -0.04778458550572395,
      -0.016356302425265312,
      -0.054913148283958435,
      -0.11827387660741806,
      0.015561279840767384,
      -0.01800934225320816,
      -0.0002416090137558058,
      -0.013601500540971756,
      0.0374327227473259,
      -0.045935772359371185,
      -0.00020710111130028963,
      0.014475224539637566,
      0.01575964130461216,
      -0.0361330509185791,
      -0.010804083198308945,
      -0.010691274888813496,
      0.04276704788208008,
      0.025389593094587326,
      0.02980775572359562,
      -0.015722936019301414,
      0.04209095239639282,
      -0.023792583495378494,
      0.012942809611558914,
      -0.02192608080804348,
      0.05507349222898483,
      0.0050666662864387035,
      -0.008176994509994984,
      -0.04565001279115677,
      0.049498241394758224,
      -0.07330308109521866,
      -0.012325678020715714,
      -0.01225791685283184,
      0.04535093158483505,
      0.0037500488106161356,
      0.11652620136737823,
      0.08117539435625076,
      0.001363444491289556,
      -0.08112429082393646,
      -0.04153349623084068,
      0.00940142385661602,
      0.01321637723594904,
      0.02714577317237854,
      -0.007668087724596262,
      -0.011288084089756012,
      -0.07988026738166809,
      -0.05625968798995018,
      -0.008792135864496231,
      0.021192293614149094,
      0.04856673255562782,
      -0.01238791923969984,
      0.09480851143598557,
      -0.04274776577949524,
      -0.0024652949068695307,
      0.03460288420319557,
      -0.06390174478292465,
      -0.050643954426050186,
      -0.036829184740781784,
      -0.01026628352701664,
      0.03218359127640724,
      0.028405092656612396,
      0.045245829969644547,
      -0.02916247397661209,
      0.003005774226039648,
      -0.020648090168833733,
      0.09758172184228897,
      0.004538970533758402,
      -0.020265832543373108,
      0.01857958920300007,
      -0.009397868998348713,
      0.0009217540500685573,
      -0.026112742722034454,
      -0.10389651358127594,
      -0.02282842807471752,
      -0.05908654257655144,
      0.07119645178318024,
      0.010622723028063774,
      -0.029471520334482193,
      0.0030661204364150763,
      0.043383508920669556,
      0.005159747786819935,
      -0.05557995289564133,
      0.012310928665101528,
      0.04757295548915863,
      0.054237887263298035,
      -0.002151217544451356,
      -0.04253821820020676,
      0.05028822273015976,
      0.03608061745762825,
      0.016794368624687195,
      -0.05138254165649414,
      -0.0170089453458786,
      0.013241369277238846,
      0.06661546975374222,
      0.04819934815168381,
      -0.008152006193995476,
      -0.06246472895145416,
      -0.11337070912122726,
      -0.022343626245856285,
      -0.05427723750472069,
      0.08874353766441345,
      0.04262259230017662,
      -0.05310676246881485,
      -0.025820858776569366,
      -0.06833124905824661,
      0.071822889149189,
      0.07171173393726349,
      -0.029006827622652054,
      -0.02017250284552574,
      0.05077327415347099,
      -0.12149932980537415,
      0.0547495000064373,
      0.03813486546278,
      0.05407924950122833,
      0.05113634839653969,
      -0.06627495586872101,
      0.06970503181219101,
      0.03556917980313301,
      0.003900307696312666,
      0.003716900246217847,
      0.0888214036822319,
      0.050007082521915436,
      -0.027263402938842773,
      0.029004961252212524,
      0.05425386130809784,
      -0.035672031342983246,
      0.038172680884599686,
      -0.024767480790615082,
      0.016238590702414513,
      -0.026890292763710022,
      0.04668205603957176,
      -0.07115864008665085,
      0.024971045553684235,
      0.05658326297998428,
      -0.019371826201677322,
      -0.03414019197225571,
      -0.02521117776632309,
      -0.05617101117968559,
      -0.017400745302438736,
      0.030925901606678963,
      -0.014653496444225311,
      -0.010605249553918839,
      0.004403534345328808,
      -0.04091668128967285,
      0.0014467017026618123,
      0.07623641937971115,
      -0.01946028135716915,
      0.046597450971603394,
      0.055017903447151184,
      -0.014290345832705498,
      0.030544595792889595,
      0.007009187247604132,
      0.07865522801876068,
      0.028459275141358376,
      -0.012747274711728096,
      0.03199007362127304,
      -0.04262262582778931,
      0.012555427849292755,
      0.009452924132347107,
      -0.009723854251205921,
      -0.06285665184259415,
      0.05794310197234154,
      0.027902396395802498,
      -0.03156077489256859,
      -0.010713774710893631,
      -0.0038699812721461058,
      0.03550924360752106,
      -0.010101676918566227,
      -0.02938595972955227,
      0.03772468492388725,
      0.03564105182886124,
      0.14912138879299164,
      0.0698586255311966,
      -0.006798414047807455,
      -0.0591227188706398,
      0.06750781834125519,
      0.03294498473405838,
      0.03967565670609474,
      -0.04689239338040352,
      0.025194291025400162,
      -0.02134568989276886,
      0.01612231321632862,
      -0.05562800541520119,
      0.0005316674942150712,
      0.02600008249282837,
      0.0007871271809563041,
      -0.02074761688709259,
      0.02477116882801056,
      0.05548349767923355,
      0.02546408772468567,
      0.005012730602174997,
      -0.018977472558617592,
      -0.034044958651065826,
      0.03190396726131439,
      0.06428098678588867,
      -0.049677424132823944,
      -0.05534182861447334,
      -0.0253327414393425,
      -0.026528077200055122,
      -0.02511918731033802,
      -0.026068001985549927,
      -0.014287266880273819,
      -0.062401432543992996,
      0.05784774199128151,
      0.007226751651614904,
      -0.014207401312887669,
      0.028585512191057205,
      -0.022870725020766258,
      0.12282079458236694,
      -0.04708103463053703,
      0.09157513082027435
    ],
    [
      0.037540413439273834,
      0.05207449197769165,
      -0.020870205014944077,
      0.013979273848235607,
      -0.01710212044417858,
      -0.009677066467702389,
      -0.08795227855443954,
      0.027681861072778702,
      0.00048523221630603075,
      -0.03634435310959816,
      0.0377248153090477,
      -0.0010104558896273375,
      0.011692130006849766,
      0.0012666502734646201,
      -0.02652857080101967,
      0.06076355278491974,
      -0.04009976238012314,
      -0.01702718622982502,
      -0.02245475724339485,
      0.05599629506468773,
      -0.047142818570137024,
      0.02886361815035343,
      -0.03904901072382927,
      0.021438222378492355,
      -0.07474905252456665,
      -0.049172308295965195,
      0.03760684281587601,
      0.014021765440702438,
      0.006061058957129717,
      0.014754106290638447,
      0.033624570816755295,
      0.0293964222073555,
      0.07943984866142273,
      -0.0031745366286486387,
      -0.001431356999091804,
      0.014936039224267006,
      0.014433040283620358,
      0.029494384303689003,
      0.07179490476846695,
      0.0023100171238183975,
      -0.07056720554828644,
      0.04674345627427101,
      -0.1033829003572464,
      0.03373050317168236,
      -0.06522583961486816,
      0.0186762772500515,
      -0.02939118631184101,
      -0.019755417481064796,
      0.06709155440330505,
      -0.0003201279032509774,
      0.02580653876066208,
      -0.11606229841709137,
      0.04679373651742935,
      -0.02656359039247036,
      0.04569363221526146,
      -0.03776588290929794,
      0.020841587334871292,
      -0.03495479375123978,
      -0.012410268187522888,
      0.10149358212947845,
      0.007961802184581757,
      -0.06459323316812515,
      0.0180609542876482,
      -0.02224438264966011,
      -0.003953862003982067,
      0.001269238768145442,
      -0.07428447157144547,
      -0.007896838709712029,
      0.024063607677817345,
      0.03503828123211861,
      -0.09854529798030853,
      0.017847584560513496,
      -0.0041530560702085495,
      -0.026764828711748123,
      0.001323452452197671,
      0.012550418265163898,
      0.015407318249344826,
      0.02561188116669655,
      0.14464884996414185,
      -0.04423064738512039,
      -0.05188804119825363,
      0.03344065696001053,
      0.001478423597291112,
      0.005712475161999464,
      0.008193700574338436,
      -0.009022253565490246,
      -0.057652395218610764,
      0.02691219002008438,
      0.06803123652935028,
      -0.02698696404695511,
      0.008906547911465168,
      0.040095649659633636,
      -0.015217975713312626,
      0.0030556346755474806,
      -0.0658273845911026,
      -0.023767489939928055,
      0.026021240279078484,
      -0.004952165298163891,
      -0.05105514079332352,
      0.03370865061879158,
      0.03296831622719765,
      0.005375708919018507,
      -0.07105065137147903,
      -0.008852468803524971,
      0.12831716239452362,
      0.02197682484984398,
      -0.018101897090673447,
      -0.003916814457625151,
      0.054286710917949677,
      0.047423165291547775,
      0.012636716477572918,
      0.05842270702123642,
      0.003144103568047285,
      -0.0028213486075401306,
      -0.009684961289167404,
      -0.03750314190983772,
      0.014390743337571621,
      -0.027812443673610687,
      -0.04983946681022644,
      0.007306376937776804,
      -0.0031491925474256277,
      0.05284658446907997,
      -0.009002206847071648,
      -0.022726578637957573,
      -0.09991813451051712,
      -0.0035810600966215134,
      -0.0263465978205204,
      -0.001779333222657442,
      -0.030414652079343796,
      -0.06409668922424316,
      -0.02998376451432705,
      -0.008609245531260967,
      0.057991232722997665,
      -0.0474313385784626,
      0.007216442842036486,
      -0.01332879438996315,
      -0.008711026050150394,
      -0.1259392499923706,
      0.053210582584142685,
      -0.06099049374461174,
      -0.039559219032526016,
      -0.0045988126657903194,
      -0.013130922801792622,
      -0.04518551006913185,
      0.03185572475194931,
      -0.06861612945795059,
      0.042847175151109695,
      0.01698928512632847,
      0.024485666304826736,
      0.01301638875156641,
      0.035153985023498535,
      -0.04894515126943588,
      -0.03799612447619438,
      -0.04489080235362053,
      0.0004875014128629118,
      -0.007245573215186596,
      0.005051886197179556,
      0.018423330038785934,
      -0.013206093572080135,
      0.06057093292474747,
      -0.043358318507671356,
      0.07036646455526352,
      0.015534746460616589,
      -0.03442914038896561,
      -0.031870581209659576,
      -0.07903198897838593,
      0.012885402888059616,
      0.024283617734909058,
      -0.050859034061431885,
      -0.032920822501182556,
      -0.009949450381100178,
      -0.0495690256357193,
      0.022771760821342468,
      0.0016602178802713752,
      0.039250120520591736,
      -0.014227683655917645,
      0.04908336326479912,
      0.04117641597986221,
      0.01836475543677807,
      -0.03938353434205055,
      0.023020867258310318,
      0.008481495082378387,
      -0.07307135313749313,
      -0.04563972353935242,
      -0.018828770145773888,
      0.03546561300754547,
      0.053724393248558044,
      0.04870009422302246,
      -0.02220868319272995,
      -0.030539192259311676,
      -0.00962960347533226,
      0.027692247182130814,
      0.0433865524828434,
      -0.011518469080328941,
      0.04525505751371384,
      -0.00456241425126791,
      0.02931324578821659,
      -0.07014476507902145,
      0.01203785091638565,
      -0.027276407927274704,
      -0.021791508421301842,
      0.051081109791994095,
      0.034213658422231674,
      -0.014427165500819683,
      0.06286942958831787,
      -0.007954467087984085,
      0.07647707313299179,
      0.026158466935157776,
      0.08048209547996521,
      -0.09014499187469482,
      -0.05789152905344963,
      0.0347600094974041,
      0.06093146651983261,
      0.026722924783825874,
      -0.048527222126722336,
      0.02709481492638588,
      0.025735585018992424,
      0.002476779045537114,
      -0.060187842696905136,
      0.007377497851848602,
      -0.026675185188651085,
      -0.0016298402333632112,
      -0.05581648647785187,
      0.026352861896157265,
      -0.05331050977110863,
      0.028961285948753357,
      0.033143624663352966,
      0.069107785820961,
      -0.1793321669101715,
      -0.009075538255274296,
      0.006638781633228064,
      -0.02324073202908039,
      0.0009571483242325485,
      0.022557752206921577,
      0.014928639866411686,
      -0.010690172202885151,
      0.01722436398267746,
      -0.05487633869051933,
      -0.025386134162545204,
      -0.05557429790496826,
      0.04648948088288307,
      0.05046464875340462,
      -0.10551576316356659,
      -0.030459048226475716,
      -0.0285600908100605,
      0.06922851502895355,
      0.014008608646690845,
      0.009199155494570732,
      -0.056277647614479065,
      -0.06360567361116409,
      -0.013317853212356567,
      0.06291241198778152,
      -0.015587271191179752,
      -0.05604776740074158,
      0.03802439197897911,
      -0.06754805147647858,
      0.04755668714642525,
      0.04628414660692215,
      0.013549674302339554,
      -0.03688959404826164,
      0.01421310007572174,
      0.009950618259608746,
      0.048075295984745026,
      -0.010035611689090729,
      0.05245046690106392,
      0.0018959922017529607,
      -0.056664030998945236,
      0.01647375337779522,
      0.009583870880305767,
      0.07462868839502335,
      -0.015084519051015377,
      0.05650228261947632,
      -0.056320831179618835,
      -0.014982299879193306,
      0.016903815791010857,
      0.041697558015584946,
      0.07389476895332336,
      0.016950087621808052,
      -0.04231371358036995,
      0.052455827593803406,
      -0.005965724587440491,
      -0.006671370938420296,
      -0.002079574391245842,
      -0.009284607134759426,
      -0.0018667061813175678,
      -0.007203490473330021,
      0.027305470779538155,
      0.07911558449268341,
      0.03983360156416893,
      0.01971813663840294,
      0.041001368314027786,
      -0.0287562794983387,
      -0.0476272888481617,
      0.07807960361242294,
      -0.0037410338409245014,
      0.016188926994800568,
      0.008776374161243439,
      -0.05836213752627373,
      0.047025930136442184,
      0.09893648326396942,
      0.0026757759042084217,
      -0.025223642587661743,
      0.04828507453203201,
      0.024945605546236038,
      0.0001839036267483607,
      -0.028928140178322792,
      0.06656455993652344,
      -0.10320283472537994,
      0.07766822725534439,
      0.09307212382555008,
      0.029429633170366287,
      -0.02182682603597641,
      -0.0002766213146969676,
      0.030159851536154747,
      -0.03959951549768448,
      -0.03921559080481529,
      -0.03212251886725426,
      -0.03885826840996742,
      0.0004969771835021675,
      0.08663185685873032,
      0.01937989518046379,
      0.007444068789482117,
      -0.04495590180158615,
      -0.051282402127981186,
      -0.004066170658916235,
      0.058802057057619095,
      -0.031393494457006454,
      0.027701031416654587,
      -0.029475552961230278,
      -0.04104537144303322,
      0.061960767954587936,
      0.004975650459527969,
      0.005030115135014057,
      -0.012421561405062675,
      0.02434798702597618,
      -0.021537140011787415,
      -0.055912088602781296,
      0.021192464977502823,
      -0.0027247874531894922,
      -0.06876614689826965,
      -0.053327541798353195,
      -0.01452404074370861,
      0.0123508395627141,
      0.05935855582356453,
      0.057553041726350784,
      0.03398965299129486,
      0.03190654516220093,
      -0.026564398780465126,
      0.056057803332805634,
      -0.0027084602043032646,
      -0.02946271374821663,
      0.031047863885760307,
      -2.7075640900875442e-05,
      -0.08374633640050888,
      0.05195392295718193,
      0.008528804406523705,
      -0.03778175637125969,
      0.07253795117139816,
      -0.056549735367298126,
      0.010315992869436741,
      0.012577172368764877,
      -0.01897638663649559,
      -0.03473680093884468,
      -0.043471284210681915,
      -0.018289992585778236,
      -0.024742526933550835,
      -0.0007993430481292307,
      0.010427556931972504,
      -0.07573453336954117,
      -0.014600512571632862,
      -0.010640125721693039,
      0.008034031838178635,
      0.00562570383772254,
      -0.011756209656596184,
      -0.06830526143312454,
      -0.02114116959273815,
      -0.03859269246459007,
      0.011363256722688675,
      -0.062421638518571854,
      0.028792716562747955,
      0.0017120377160608768,
      -0.0307684987783432,
      0.00026945286663249135,
      0.06162649020552635,
      0.022249944508075714,
      -0.022511733695864677,
      -0.005650505423545837,
      0.06510376185178757,
      -0.05607113242149353,
      0.04022926837205887,
      0.0319623127579689,
      -0.02848045527935028,
      -0.08890442550182343,
      0.053328175097703934,
      -0.049322083592414856,
      -0.009369230829179287,
      -0.00867523718625307,
      -0.02077949233353138,
      -0.03207865357398987,
      -0.08564720302820206,
      -0.026239752769470215,
      0.05540361627936363,
      -0.09057856351137161,
      -0.020877134054899216,
      -0.020692842081189156,
      0.016020702198147774,
      -0.03693355619907379,
      -0.06397359818220139,
      -0.006324580870568752,
      -0.02356322482228279,
      0.02684616856276989,
      -0.012330894358456135,
      -0.04635901749134064,
      -0.06909315288066864,
      -0.048762112855911255,
      0.06495176255702972,
      0.02043779194355011,
      0.007139957044273615,
      -0.050266630947589874,
      -0.0367584191262722,
      -0.024439925327897072,
      -0.06098592281341553,
      0.025192566215991974,
      0.05253659933805466,
      -0.0359157957136631,
      0.004686054773628712,
      -0.043576233088970184,
      -0.059006210416555405,
      0.11669058352708817,
      -0.039208002388477325,
      0.011684251949191093,
      -0.10786816477775574,
      -0.0006083299522288144,
      -0.003156441729515791,
      0.029847146943211555,
      0.03496192395687103,
      0.04116372764110565,
      0.06653245538473129,
      -0.05522160977125168,
      -0.007168793585151434,
      0.054240062832832336,
      -0.055429328233003616,
      0.03324536606669426,
      -0.03462476283311844,
      0.00330291036516428,
      -0.0622137114405632,
      -0.03143766522407532,
      -0.047685977071523666,
      -0.028238005936145782,
      0.06268589943647385,
      0.010741371661424637,
      -0.03571789339184761,
      0.0028056444134563208,
      -0.06791726499795914,
      0.022194266319274902,
      -0.04621153697371483,
      -0.027918759733438492,
      0.012407146394252777,
      -0.03059578128159046,
      -0.059808578342199326,
      -0.06216757744550705,
      -0.04270267114043236,
      -0.0088629936799407,
      0.019677355885505676,
      -0.06389476358890533,
      -0.05830083042383194,
      -0.011746802367269993,
      0.04154160991311073,
      -0.033443305641412735,
      0.06728386878967285,
      -0.05926513671875,
      0.04409307613968849,
      0.02639780379831791,
      0.02340012788772583,
      0.0634101927280426,
      0.01474562007933855,
      -0.05866329371929169,
      -0.04808691516518593,
      0.04158264771103859,
      -0.012610191479325294,
      -0.013992847874760628,
      -0.018117742612957954,
      -0.04551149159669876,
      -0.008007503114640713,
      0.049238171428442,
      0.00554655073210597,
      0.07193797081708908,
      0.04786455258727074,
      -0.009765489026904106,
      0.010026122443377972,
      0.030998356640338898,
      0.019495869055390358,
      0.008980823680758476,
      -0.009112998843193054,
      0.056504324078559875,
      -0.03199569508433342,
      -0.026587236672639847,
      -0.040415115654468536,
      0.08547020703554153,
      -0.016895368695259094,
      0.004694203846156597,
      0.05205539986491203,
      -0.06170232221484184
    ],
    [
      -0.06536538898944855,
      0.05355275422334671,
      0.014101079665124416,
      0.028127755969762802,
      0.04176417738199234,
      0.018359920009970665,
      0.06129264086484909,
      -0.015266074799001217,
      0.07322435826063156,
      0.09595898538827896,
      -0.03648480027914047,
      0.04902900010347366,
      0.03084760159254074,
      0.019856758415699005,
      0.10437268018722534,
      0.07379869371652603,
      -0.04517533257603645,
      0.04451284930109978,
      -0.01708114519715309,
      -0.0006050451775081456,
      0.07355907559394836,
      -0.02753468230366707,
      -0.02519095689058304,
      -0.02097933739423752,
      -0.06814177334308624,
      0.04031003639101982,
      -0.12331719696521759,
      -0.04322277009487152,
      0.021861033514142036,
      -0.07628745585680008,
      -0.05236358568072319,
      -0.030853167176246643,
      -0.056830644607543945,
      0.0106200547888875,
      -0.057144783437252045,
      -0.025144940242171288,
      -0.055616628378629684,
      0.02033628709614277,
      -0.023290539160370827,
      0.042902808636426926,
      0.00562884658575058,
      -0.0037678279913961887,
      0.027858931571245193,
      -0.017883028835058212,
      -0.039873406291007996,
      -0.06214744597673416,
      0.014816708862781525,
      -0.03194950148463249,
      0.027572495862841606,
      0.001121535780839622,
      -0.03404073789715767,
      -0.015587393194437027,
      -0.12272492051124573,
      -0.007779864128679037,
      0.020513784140348434,
      -0.04962204024195671,
      0.000507712597027421,
      0.0391831174492836,
      0.010834426619112492,
      0.002847404917702079,
      0.01795812137424946,
      0.025754045695066452,
      -0.03715982660651207,
      -0.043265827000141144,
      -0.05938916653394699,
      -0.11904986202716827,
      -0.017808806151151657,
      0.021371860057115555,
      0.06822177022695541,
      -0.1519157588481903,
      0.01744692772626877,
      0.018283661454916,
      -0.017876559868454933,
      0.06926614046096802,
      0.059172771871089935,
      -0.019521497189998627,
      -0.026380272582173347,
      -0.08232017606496811,
      0.04476848989725113,
      -0.053727876394987106,
      -0.0715319886803627,
      0.021274594590067863,
      -0.0006006589392200112,
      0.003521751845255494,
      -0.05425334721803665,
      0.06009752303361893,
      -0.04487435519695282,
      0.039677638560533524,
      0.05505906045436859,
      0.05890580266714096,
      -0.08847817033529282,
      0.010505760088562965,
      -0.08866152912378311,
      -0.038251571357250214,
      -0.035101518034935,
      -0.026050802320241928,
      0.03844936564564705,
      -0.046061646193265915,
      -0.03800465166568756,
      0.09475605934858322,
      -0.018216459080576897,
      0.04795439541339874,
      0.1157316341996193,
      -0.002390828914940357,
      -0.038031965494155884,
      0.06081991270184517,
      -0.02347269095480442,
      -0.03498734533786774,
      0.03270644322037697,
      0.030565669760107994,
      -0.03265714272856712,
      -0.08383986353874207,
      -0.08512698858976364,
      -0.07573040574789047,
      -0.041092466562986374,
      -0.14421921968460083,
      0.014778167009353638,
      0.017100263386964798,
      0.00676055159419775,
      -0.04047972336411476,
      2.6929748855764046e-05,
      0.0020598166156560183,
      0.03084239549934864,
      0.013573916628956795,
      -0.008955814875662327,
      -0.002616316080093384,
      -0.03645862638950348,
      0.05619601905345917,
      -0.07916062325239182,
      -0.016362281516194344,
      -0.022219760343432426,
      0.007396725472062826,
      -0.047721732407808304,
      -0.042578425258398056,
      0.027498260140419006,
      -0.00030601874459534883,
      0.015336784534156322,
      -0.05638047307729721,
      -0.028941869735717773,
      0.041703324764966965,
      -0.010834628716111183,
      0.07186144590377808,
      0.0006830295897088945,
      0.011243700981140137,
      -0.047457221895456314,
      0.027476070448756218,
      -0.05326268821954727,
      -0.007247135043144226,
      0.015550173819065094,
      -0.07190341502428055,
      0.04020246863365173,
      0.019512178376317024,
      0.054563943296670914,
      0.003325799945741892,
      0.05879504233598709,
      0.019229786470532417,
      0.01841120235621929,
      -0.01733694039285183,
      0.11689253896474838,
      -0.0016981714870780706,
      -0.027249015867710114,
      -0.016034072265028954,
      -0.010468405671417713,
      0.01794534921646118,
      0.022534972056746483,
      0.015992525964975357,
      -0.058008573949337006,
      0.03423421084880829,
      -0.13064853847026825,
      0.03299393877387047,
      0.029202362522482872,
      -0.004101849161088467,
      -0.04667789116501808,
      -0.027430221438407898,
      0.018325790762901306,
      -0.06837107241153717,
      0.02900056354701519,
      -0.0578869953751564,
      0.0026151512283831835,
      -0.04032248631119728,
      -0.019422128796577454,
      0.011196479201316833,
      -0.03887341171503067,
      -0.051098693162202835,
      0.08661197125911713,
      0.010813171975314617,
      0.04569561034440994,
      -0.011033507063984871,
      0.030100611969828606,
      -0.035259220749139786,
      -0.005693196319043636,
      -0.011669344268739223,
      0.028414113447070122,
      0.0024706758558750153,
      0.02865695022046566,
      -0.021384768187999725,
      0.007511990610510111,
      -0.013707668520510197,
      -0.09543713182210922,
      -0.03514186292886734,
      0.026461385190486908,
      0.039079003036022186,
      0.13269545137882233,
      -0.019818205386400223,
      -0.025885479524731636,
      -0.029117072001099586,
      0.002378687262535095,
      -0.025648856535553932,
      0.05110396072268486,
      0.018740037456154823,
      0.07312535494565964,
      0.034460727125406265,
      0.05893950164318085,
      0.03455070033669472,
      -0.07622280716896057,
      0.0001115606864914298,
      -0.0026904188562184572,
      0.01692074164748192,
      0.007976299151778221,
      0.04853273928165436,
      0.02894909307360649,
      -0.06313414126634598,
      -0.02827218733727932,
      0.030053116381168365,
      0.04352906346321106,
      -0.030977362766861916,
      0.010810278356075287,
      0.0526241734623909,
      -0.03234602138400078,
      -0.05732617527246475,
      0.035321153700351715,
      0.04505591839551926,
      -0.02860843762755394,
      -0.06590704619884491,
      -0.007342884317040443,
      0.026001665741205215,
      0.04725755751132965,
      0.0377458855509758,
      -0.0016086831456050277,
      0.015791555866599083,
      0.019931815564632416,
      0.03620851784944534,
      -0.005568433552980423,
      0.07868169248104095,
      0.003196298610419035,
      0.029339734464883804,
      -0.04242146387696266,
      0.07143228501081467,
      0.005321285221725702,
      -0.08093378692865372,
      0.017411449924111366,
      -0.013877521269023418,
      0.03615788742899895,
      0.069298155605793,
      0.003482063999399543,
      -0.07558269798755646,
      0.026254285126924515,
      0.008598237298429012,
      -0.043046362698078156,
      -0.009758411906659603,
      -0.009998826310038567,
      0.028163716197013855,
      -0.036146316677331924,
      0.014383980073034763,
      -0.04921864718198776,
      0.020264817401766777,
      0.0021956495475023985,
      0.04083901643753052,
      0.009473350830376148,
      0.019780823960900307,
      -0.11351826041936874,
      0.0721823126077652,
      -0.022959282621741295,
      -0.03569211810827255,
      -0.03755432739853859,
      -0.1050177738070488,
      0.07227550446987152,
      0.005774062126874924,
      0.07635382562875748,
      0.014998623169958591,
      -0.06203169375658035,
      -0.04391703009605408,
      0.005104344338178635,
      0.040498632937669754,
      0.00046534539433196187,
      -0.01196132693439722,
      -0.028248127549886703,
      0.06061428785324097,
      -0.0331294871866703,
      0.025167986750602722,
      0.09613466262817383,
      -0.01953510195016861,
      0.03661833330988884,
      0.01959799975156784,
      -0.0070757572539150715,
      -0.07237940281629562,
      0.05475270748138428,
      0.07483469694852829,
      -0.03415704518556595,
      -0.002752851927652955,
      0.0009003595332615077,
      0.04188894107937813,
      -0.05463860183954239,
      0.007509563118219376,
      -0.057699352502822876,
      -0.027009759098291397,
      -0.01783265545964241,
      -0.028840582817792892,
      -0.0278339646756649,
      0.0008819516515359282,
      -0.01648232713341713,
      -0.030094660818576813,
      0.08450180292129517,
      -0.006495265290141106,
      0.07006334513425827,
      -0.00466235913336277,
      0.047367580235004425,
      -0.09599167853593826,
      -0.043485768139362335,
      -0.017155513167381287,
      -0.015608115121722221,
      -0.05064902827143669,
      0.019989581778645515,
      0.013241423293948174,
      -0.04111938551068306,
      -0.027357082813978195,
      0.05884870886802673,
      -0.015721071511507034,
      0.039825160056352615,
      0.014918769709765911,
      0.016978930681943893,
      0.03270801901817322,
      0.012203973717987537,
      0.05731616169214249,
      0.03129244223237038,
      -0.025987401604652405,
      0.03414300084114075,
      -0.022306064143776894,
      -0.03730807453393936,
      -0.021881433203816414,
      -0.0023195494432002306,
      0.037678398191928864,
      0.05888380482792854,
      0.0027943607419729233,
      0.016184229403734207,
      0.03748002275824547,
      0.07276003807783127,
      0.07332455366849899,
      0.014613554812967777,
      -0.02502436563372612,
      0.0710691586136818,
      0.09317466616630554,
      -0.015824181959033012,
      -0.017098313197493553,
      -0.021296773105859756,
      0.06865747272968292,
      -0.023737598210573196,
      0.08404168486595154,
      0.0015830955235287547,
      -0.06567367166280746,
      -0.022952020168304443,
      -0.004066595807671547,
      -0.012426303699612617,
      0.035030659288167953,
      0.024437876418232918,
      0.010667282156646252,
      0.013870703056454659,
      -0.0160263329744339,
      0.03000763989984989,
      -0.05725027248263359,
      -0.023384878411889076,
      -0.0006130609544925392,
      -0.019162585958838463,
      0.012256390415132046,
      -0.010984299704432487,
      0.09958741813898087,
      -0.03540930896997452,
      -0.05547827109694481,
      0.10487893968820572,
      -0.061131786555051804,
      -0.014431873336434364,
      0.06527764350175858,
      -0.08043844997882843,
      -0.06780447065830231,
      -0.07787135243415833,
      0.014371467754244804,
      -0.04135866463184357,
      -0.03412280231714249,
      0.0005015688948333263,
      0.03839615359902382,
      0.06676693260669708,
      0.05636090412735939,
      0.002482078270986676,
      0.028565742075443268,
      -0.050816088914871216,
      -0.019156353548169136,
      0.045417554676532745,
      -0.015267889015376568,
      -0.02103862538933754,
      0.09079016745090485,
      0.023908155038952827,
      0.018558327108621597,
      0.010820828378200531,
      -0.034454844892024994,
      0.025214791297912598,
      0.036629434674978256,
      0.09151557087898254,
      0.009010101668536663,
      0.008191683329641819,
      0.02226080931723118,
      0.10177680850028992,
      -0.011694573797285557,
      0.048943158239126205,
      0.018487654626369476,
      0.06379619985818863,
      0.006652602460235357,
      0.06299402564764023,
      -0.04188661649823189,
      -0.04222503677010536,
      0.06353450566530228,
      0.04483664780855179,
      -0.048726487904787064,
      0.007755265571177006,
      -0.03716869279742241,
      -0.0618438646197319,
      -0.005450703203678131,
      0.07433909922838211,
      0.07178568840026855,
      0.028672626242041588,
      0.023709367960691452,
      -0.007647293154150248,
      -0.0006428711349144578,
      -0.016988422721624374,
      -0.023729050531983376,
      -0.027999138459563255,
      -0.03053191863000393,
      -0.03978417068719864,
      -0.007233843673020601,
      0.0633845329284668,
      -0.0037130112759768963,
      0.029314730316400528,
      0.005821729078888893,
      0.06409218162298203,
      -0.03545749559998512,
      -0.025988910347223282,
      -0.014742591418325901,
      0.008224256336688995,
      0.06566306948661804,
      0.09036596864461899,
      0.0002139591088052839,
      -0.03311675414443016,
      0.06117074564099312,
      0.04680623114109039,
      0.018915658816695213,
      -0.005224523600190878,
      0.0069892690517008305,
      0.04410886764526367,
      -0.02835727110505104,
      0.03396304324269295,
      0.014475847594439983,
      -0.017857622355222702,
      -0.04247600585222244,
      0.011820106767117977,
      0.09413111954927444,
      -0.004538037348538637,
      0.04315066337585449,
      0.038042739033699036,
      -0.10448871552944183,
      0.09424376487731934,
      -0.008468382991850376,
      -0.008690860122442245,
      0.006663145963102579,
      0.0062645007856190205,
      0.016050703823566437,
      -0.07138561457395554,
      0.026192884892225266,
      0.04436036944389343,
      -0.015762504190206528,
      -0.046156998723745346,
      -0.04919774830341339,
      -0.014337986707687378,
      0.003386965487152338,
      -0.13521277904510498,
      -0.004982630722224712,
      -0.026300033554434776,
      -0.023467229679226875,
      0.03350607305765152,
      0.0014540228294208646,
      0.02611527591943741,
      0.0227272417396307,
      -0.03580678254365921,
      -0.009313288144767284,
      0.07767947018146515,
      0.02903866209089756,
      -0.031223254278302193,
      0.1088440865278244,
      0.014912008307874203,
      0.06929733604192734,
      -0.03822401911020279,
      -0.06448838859796524,
      0.0388256199657917,
      -0.03003234975039959,
      -0.04479557275772095
    ],
    [
      0.038777779787778854,
      0.011544917710125446,
      0.03924720361828804,
      -0.09125198423862457,
      0.008636380545794964,
      0.009359577670693398,
      -0.009843124076724052,
      0.0008410743321292102,
      0.05268487706780434,
      0.04705553501844406,
      -0.005242082756012678,
      0.06518406420946121,
      -0.038943100720644,
      -0.016699859872460365,
      -0.0639149472117424,
      0.035225868225097656,
      -0.12213551253080368,
      -0.01630386896431446,
      -0.030162032693624496,
      -0.014838012866675854,
      -0.14515043795108795,
      0.02190602757036686,
      -0.03470658138394356,
      -0.027464592829346657,
      -0.09129206836223602,
      -0.0007032219436950982,
      0.023065537214279175,
      0.016450969502329826,
      0.026714930310845375,
      0.02293475717306137,
      0.03946470469236374,
      0.03114810399711132,
      0.03220074251294136,
      -0.0732165053486824,
      0.008577002212405205,
      0.00056739168940112,
      0.09658657759428024,
      0.07826929539442062,
      0.019802242517471313,
      -0.009923419915139675,
      0.012086044065654278,
      0.044502753764390945,
      -0.05956808850169182,
      0.09142884612083435,
      0.015015389770269394,
      -0.03772403672337532,
      -0.04646410420536995,
      -0.011378103867173195,
      0.05594399943947792,
      -0.01391749456524849,
      0.022288747131824493,
      -0.02392284758388996,
      0.0028162002563476562,
      0.034830741584300995,
      -0.039554040879011154,
      0.007279715966433287,
      -0.10258305817842484,
      -0.047865770757198334,
      -0.01633353903889656,
      -0.07259266823530197,
      -0.012940230779349804,
      0.046278759837150574,
      -0.01084661390632391,
      -0.058994561433792114,
      -0.01668281853199005,
      -0.06779380142688751,
      0.06523914635181427,
      -0.02277306839823723,
      0.035051923245191574,
      0.04884795472025871,
      0.023256924003362656,
      0.034660760313272476,
      0.10709482431411743,
      -0.05375170707702637,
      0.041729655116796494,
      -0.006861990317702293,
      0.08985591679811478,
      0.042982395738363266,
      0.02933058701455593,
      0.06380800902843475,
      0.010075693018734455,
      0.029922427609562874,
      -0.0786563903093338,
      0.11156824231147766,
      -0.005561016034334898,
      0.018107343465089798,
      -0.15172404050827026,
      0.03882501274347305,
      -0.008304047398269176,
      -0.09707088023424149,
      0.020480073988437653,
      0.021314729005098343,
      -0.05790948122739792,
      -0.008352834731340408,
      -0.033130746334791183,
      -0.013799302279949188,
      0.0005963803268969059,
      -0.04942498356103897,
      0.004384621046483517,
      0.0317288413643837,
      0.05929376557469368,
      -0.055125780403614044,
      0.0064926245249807835,
      0.004747946280986071,
      -0.042025189846754074,
      -0.04131429269909859,
      0.025118166580796242,
      0.051836781203746796,
      -0.004123681224882603,
      0.005770413670688868,
      -0.06908151507377625,
      -0.010038770735263824,
      -0.020393628627061844,
      0.05130704864859581,
      -0.029352208599448204,
      0.06953287124633789,
      0.017577268183231354,
      -0.10956523567438126,
      -0.0067830472253263,
      0.0018674163147807121,
      0.09060447663068771,
      0.027551494538784027,
      0.04227953776717186,
      0.009611241519451141,
      -0.033423490822315216,
      0.030004648491740227,
      -0.07457655668258667,
      -0.05480961501598358,
      0.10263463109731674,
      -0.009859949350357056,
      0.054255831986665726,
      -0.002481230301782489,
      0.009966236539185047,
      -0.018041452392935753,
      -0.03577430546283722,
      0.04434499517083168,
      0.07792728394269943,
      -0.0107160285115242,
      -0.05570139363408089,
      -0.016133122146129608,
      0.07595178484916687,
      0.02539973333477974,
      -0.0012062526075169444,
      -0.03630349412560463,
      -0.06937922537326813,
      -0.07122160494327545,
      -0.06393887102603912,
      -0.018549783155322075,
      0.057901233434677124,
      -0.03275743126869202,
      -0.00338627677410841,
      0.0023890400771051645,
      0.0778668224811554,
      0.014445924200117588,
      -0.014924641698598862,
      0.0069704060442745686,
      0.016391323879361153,
      -0.009364624507725239,
      0.01347168069332838,
      -0.07811500877141953,
      0.029132554307579994,
      -0.06837842613458633,
      0.01657949574291706,
      0.017984909936785698,
      -0.05777628347277641,
      -0.018574897199869156,
      0.02354690618813038,
      -0.08058012276887894,
      0.01474063005298376,
      0.013236424885690212,
      0.007778618484735489,
      0.014274879358708858,
      0.0313807912170887,
      0.007845127955079079,
      0.052259400486946106,
      -0.0039529395289719105,
      0.028720777481794357,
      0.008167397230863571,
      0.06414889544248581,
      0.02339075691998005,
      -0.04558800160884857,
      -0.07238376885652542,
      0.019186027348041534,
      -0.02858520671725273,
      -0.06383511424064636,
      0.011859899386763573,
      0.08096366375684738,
      0.058641958981752396,
      0.005837609525769949,
      -0.004469387233257294,
      -0.03735148906707764,
      0.026069343090057373,
      0.02715699002146721,
      0.006408543325960636,
      0.05783095583319664,
      0.021092556416988373,
      0.006446138955652714,
      0.04746047407388687,
      0.0050201029516756535,
      0.04802794009447098,
      0.012028022669255733,
      0.10246263444423676,
      0.02058134786784649,
      0.008248060010373592,
      0.03437921777367592,
      -0.1134699285030365,
      -0.013415572233498096,
      0.056166015565395355,
      0.025188181549310684,
      -0.04079660400748253,
      0.05717065930366516,
      0.015359400771558285,
      0.007507240399718285,
      -0.013924196362495422,
      -0.010257882066071033,
      0.05644134804606438,
      -0.018947068601846695,
      0.04475989565253258,
      -0.03952132537961006,
      0.007782298605889082,
      -0.012669065035879612,
      0.01757495291531086,
      -0.04226067289710045,
      0.03849858418107033,
      0.04893768951296806,
      -0.09371950477361679,
      0.05507267266511917,
      0.02656310237944126,
      -0.01829828880727291,
      0.01406112127006054,
      0.0420161671936512,
      0.017978549003601074,
      -0.02892385609447956,
      -0.000915982760488987,
      0.02450329065322876,
      -0.012907098978757858,
      -0.01026769820600748,
      0.030097899958491325,
      0.050817158073186874,
      0.06239691004157066,
      -0.007971457205712795,
      0.04328872635960579,
      0.04695149511098862,
      -0.10275140404701233,
      -0.02451830543577671,
      0.02039724960923195,
      -0.03603023290634155,
      0.010283838026225567,
      0.039924319833517075,
      0.04143337160348892,
      -0.004708532243967056,
      0.027163377031683922,
      0.013457569293677807,
      0.029163243249058723,
      -0.036615703254938126,
      -0.03842233866453171,
      -0.008357326500117779,
      -0.03878504037857056,
      -0.042837273329496384,
      0.0016959108179435134,
      0.04500256106257439,
      -0.034762948751449585,
      -0.015324583277106285,
      0.08148564398288727,
      0.07017605006694794,
      0.011659348383545876,
      -0.0682937428355217,
      -0.013171515427529812,
      -0.02715003676712513,
      0.08146703988313675,
      -0.02317141182720661,
      0.024727076292037964,
      -0.0421987846493721,
      -0.05412255972623825,
      -0.026094017550349236,
      0.014628962613642216,
      -0.016347210854291916,
      -0.02902618795633316,
      0.00020213029347360134,
      0.0011499689426273108,
      0.040489133447408676,
      -0.04408415034413338,
      -0.08299596607685089,
      -0.010010654106736183,
      0.083390973508358,
      -0.04089779034256935,
      0.04654420167207718,
      -0.02154526114463806,
      -0.032969869673252106,
      -0.03769553825259209,
      0.01316081639379263,
      -0.045582547783851624,
      0.057587821036577225,
      -0.024146731942892075,
      0.005326913669705391,
      -0.061880048364400864,
      0.018899716436862946,
      -0.02383219078183174,
      -0.05249974504113197,
      0.0772823691368103,
      -0.01416723895817995,
      0.045732319355010986,
      -0.0725940465927124,
      -0.10049110651016235,
      -0.010505309328436852,
      0.024875028058886528,
      -0.08352568000555038,
      0.0187093336135149,
      -0.007850341498851776,
      0.04070018231868744,
      0.025585653260350227,
      -0.00039616841240786016,
      0.04327456280589104,
      0.009776818566024303,
      0.03903096914291382,
      -0.0008893852937035263,
      -0.08908019959926605,
      0.06261544674634933,
      -0.020908324047923088,
      -0.003199006663635373,
      -0.006017059553414583,
      -0.012268722988665104,
      0.09197896718978882,
      0.01747286692261696,
      -0.056188568472862244,
      0.007497130427509546,
      0.011771287769079208,
      0.0104025574401021,
      -0.08858127892017365,
      0.035778239369392395,
      -0.09646254777908325,
      -0.00550942262634635,
      0.010986694134771824,
      0.006384733598679304,
      0.027910808101296425,
      -0.010504323057830334,
      0.0037630051374435425,
      0.017886340618133545,
      -0.007643275894224644,
      -0.0055487435311079025,
      0.04288893938064575,
      0.012667551636695862,
      0.08927194029092789,
      0.006720056291669607,
      0.029574710875749588,
      0.016896765679121017,
      0.11952067166566849,
      0.01029167789965868,
      0.0077822222374379635,
      0.005088326521217823,
      -0.014352508820593357,
      -0.02722717635333538,
      0.04655548185110092,
      -0.0354272834956646,
      -0.1089366227388382,
      0.03691776096820831,
      0.04147907719016075,
      0.025178929790854454,
      -0.00650474289432168,
      -0.01714501716196537,
      0.0838395282626152,
      -0.036277495324611664,
      -0.03910916671156883,
      0.09772880375385284,
      0.08142448216676712,
      0.04997871443629265,
      0.04277923330664635,
      -0.036809295415878296,
      -0.009755570441484451,
      0.016786206513643265,
      -0.03087592124938965,
      -0.0069796619936823845,
      -0.0054671885445714,
      -0.050044406205415726,
      0.012318382039666176,
      -0.02678411826491356,
      0.14774523675441742,
      -0.019038483500480652,
      0.016697688028216362,
      0.042651038616895676,
      0.03649740666151047,
      -0.027091844007372856,
      0.07033064216375351,
      0.01662633568048477,
      0.07790310680866241,
      0.03864279016852379,
      0.0335872508585453,
      -0.007447660900652409,
      -0.03466196730732918,
      -0.05960996076464653,
      0.0026307536754757166,
      0.0034319437108933926,
      -0.060315798968076706,
      -0.036254093050956726,
      0.017850106582045555,
      -0.03531668707728386,
      -0.0529782772064209,
      0.027471493929624557,
      -0.028322633355855942,
      0.00937581341713667,
      0.07107633352279663,
      0.04050084203481674,
      -0.04608600214123726,
      -0.06851468235254288,
      0.01676233299076557,
      -0.007182911038398743,
      0.07590087503194809,
      -0.025628501549363136,
      0.07021088153123856,
      0.006576359737664461,
      0.023508023470640182,
      0.03049762360751629,
      0.06670579314231873,
      -0.059013526886701584,
      0.008135182783007622,
      -0.004799403715878725,
      -0.054903749376535416,
      0.025238605216145515,
      0.005208242684602737,
      0.027210330590605736,
      -0.016695063561201096,
      0.05218924954533577,
      0.030923182144761086,
      0.0326634980738163,
      -0.009498407132923603,
      -0.02344573847949505,
      -0.06685423105955124,
      -0.09902720153331757,
      -0.03145808354020119,
      0.04030892252922058,
      0.016628369688987732,
      -0.03533010929822922,
      -0.014260574243962765,
      -0.0012545553036034107,
      -0.01382963452488184,
      0.02417389117181301,
      -0.05689483880996704,
      0.004311741795390844,
      0.04233566299080849,
      -0.005861806217581034,
      -0.02287440374493599,
      -0.0015095268609002233,
      0.07734482735395432,
      0.020590627565979958,
      0.045132335275411606,
      0.017974194139242172,
      -0.033390551805496216,
      0.0125891650095582,
      -0.003192222910001874,
      0.06999687105417252,
      -0.03144390508532524,
      0.009589643217623234,
      -0.052636660635471344,
      -0.03104703314602375,
      0.040350284427404404,
      -0.059897854924201965,
      0.01988232135772705,
      0.027400726452469826,
      0.021208858117461205,
      0.017097951844334602,
      0.0011597286211326718,
      -0.009035353548824787,
      -0.04909150302410126,
      0.0399470180273056,
      -0.05204131454229355,
      -0.04382963106036186,
      -0.047329433262348175,
      0.0097565408796072,
      -0.04545553773641586,
      -0.026582473888993263,
      0.010304313153028488,
      -0.05136199668049812,
      0.04527417942881584,
      -0.03412727639079094,
      0.0005888856831006706,
      -0.02518467605113983,
      -0.03242189809679985,
      -0.011897869408130646,
      0.02132808044552803,
      0.055369265377521515,
      0.004133712034672499,
      -0.047083545476198196,
      0.06332651525735855,
      0.011362829245626926,
      -0.011722500436007977,
      -0.07066641747951508,
      0.10482165962457657,
      -0.03277377411723137,
      0.07380854338407516,
      0.01663924567401409,
      0.0639667958021164,
      0.06932537257671356,
      0.009372588247060776,
      0.015760110691189766,
      0.04163992777466774,
      0.021068057045340538,
      0.009546359069645405,
      -0.006921703927218914,
      0.010177116841077805,
      -0.06995119899511337,
      -0.0238704401999712,
      -0.0026216162368655205,
      -0.1369178742170334
    ],
    [
      -0.05403480306267738,
      -0.029306693002581596,
      0.09061696380376816,
      -0.14301413297653198,
      -0.055522263050079346,
      0.032553981989622116,
      0.03626207262277603,
      -0.03427770733833313,
      0.056885525584220886,
      0.046793270856142044,
      0.04613826051354408,
      -0.027466436848044395,
      -0.05927036330103874,
      -0.04810921102762222,
      -0.01120700128376484,
      0.024386145174503326,
      0.0063382419757544994,
      -0.09390970319509506,
      -0.021096553653478622,
      0.01188997458666563,
      0.00643544876947999,
      0.06525605171918869,
      -0.008901231922209263,
      0.017873404547572136,
      -0.04405602440237999,
      -0.04214200749993324,
      0.05805214121937752,
      -0.009832190349698067,
      -0.018351031467318535,
      0.024500854313373566,
      0.03685455769300461,
      0.003573051420971751,
      0.027777662500739098,
      0.008618200197815895,
      -0.06861229985952377,
      0.011502068489789963,
      -0.027983304113149643,
      0.022914251312613487,
      0.0942494347691536,
      -0.03366697579622269,
      0.0760471448302269,
      0.010028248652815819,
      -0.06786974519491196,
      -0.036879897117614746,
      -0.02696753293275833,
      0.13840386271476746,
      -0.05014503747224808,
      -0.015509235672652721,
      -0.05101800337433815,
      0.039533935487270355,
      -0.035473816096782684,
      -0.07480357587337494,
      0.012015861459076405,
      0.09168015420436859,
      0.06566035747528076,
      0.02007933333516121,
      0.041554972529411316,
      -0.01617244817316532,
      -0.016745293512940407,
      -0.03764250501990318,
      0.003276906441897154,
      0.012403844855725765,
      0.012269650585949421,
      -0.05726228654384613,
      -0.05903928726911545,
      -0.05373113602399826,
      -0.06967393308877945,
      -0.051794663071632385,
      0.036622434854507446,
      -0.03988368436694145,
      -0.04022059217095375,
      0.0818777084350586,
      0.009690233506262302,
      -0.06044672429561615,
      0.01415181439369917,
      0.019604796543717384,
      0.042122602462768555,
      -0.06143910065293312,
      0.04721894860267639,
      -0.05821498855948448,
      -0.01563102938234806,
      0.011515543796122074,
      0.015252848155796528,
      -0.021433642134070396,
      0.02356012724339962,
      0.021903391927480698,
      -0.09161075949668884,
      0.029947228729724884,
      -0.011659450829029083,
      -0.01574784331023693,
      0.0024849274195730686,
      -0.021721042692661285,
      -0.02603624016046524,
      0.054661016911268234,
      -0.07346931844949722,
      0.05650424584746361,
      0.0043754070065915585,
      0.036003801971673965,
      0.0013702483847737312,
      -0.006202565040439367,
      -0.034778524190187454,
      0.09287035465240479,
      0.07610630244016647,
      0.0693531408905983,
      -0.03425110876560211,
      0.034306850284338,
      0.051018547266721725,
      -0.0798940658569336,
      0.04719346761703491,
      0.04673727601766586,
      -0.08419530838727951,
      -0.015503112226724625,
      -0.06525031477212906,
      0.027668748050928116,
      0.03234424069523811,
      -0.013983885757625103,
      0.035963449627161026,
      -0.01720600202679634,
      -0.04281047731637955,
      0.005049293860793114,
      0.023135976865887642,
      -0.017697686329483986,
      0.05552888661623001,
      0.02991931140422821,
      0.007549638394266367,
      0.027653314173221588,
      0.1156439334154129,
      0.009719295427203178,
      0.03388025611639023,
      -0.08922099322080612,
      -0.05773469805717468,
      0.09456834197044373,
      0.04191224277019501,
      0.04956533759832382,
      0.050102800130844116,
      0.033738069236278534,
      0.028191888704895973,
      -0.04635877162218094,
      -0.04234544187784195,
      0.06543438881635666,
      0.012433165684342384,
      0.02546868845820427,
      -0.034627508372068405,
      -0.04406633600592613,
      -0.02851252257823944,
      -0.02455902099609375,
      -0.04692663624882698,
      -0.006535328458994627,
      -0.004895802121609449,
      -0.050113167613744736,
      -0.010190126486122608,
      -0.012667912058532238,
      0.019506393000483513,
      -0.029079385101795197,
      -0.03191377967596054,
      -0.0004996026982553303,
      0.061799924820661545,
      -0.010416088625788689,
      -0.04606904089450836,
      0.024098224937915802,
      0.042661380022764206,
      -0.043848276138305664,
      0.06390257179737091,
      -0.04040437191724777,
      0.011970513500273228,
      0.03820302337408066,
      -0.0682738721370697,
      -0.0053862216882407665,
      0.03104938007891178,
      0.025459639728069305,
      -0.03153298422694206,
      -0.028194939717650414,
      -0.03439640253782272,
      -0.07292940467596054,
      -0.09814756363630295,
      -0.027083849534392357,
      -0.1070450022816658,
      -0.06621283292770386,
      -0.00014655657287221402,
      0.12555187940597534,
      0.04036007821559906,
      0.025721320882439613,
      -0.04752175882458687,
      -0.0018725924892351031,
      0.027545074000954628,
      -0.014741058461368084,
      0.07802482694387436,
      -0.004635050427168608,
      0.0525180846452713,
      0.036180295050144196,
      -0.01285772304981947,
      0.06549632549285889,
      0.09729333966970444,
      0.064703568816185,
      0.018777864053845406,
      -0.009382043033838272,
      0.004265701863914728,
      0.04204733669757843,
      0.018367918208241463,
      -0.02149730548262596,
      0.023013828322291374,
      -0.0008374606841243804,
      -0.06322541832923889,
      0.05139928311109543,
      -0.10288466513156891,
      0.002226458163931966,
      -0.07949545234441757,
      0.06699401885271072,
      -0.018862547352910042,
      0.026406465098261833,
      0.00560427363961935,
      0.024301966652274132,
      0.1027444526553154,
      -0.05874637886881828,
      0.00024229867267422378,
      -0.08004050701856613,
      -0.024708392098546028,
      -0.00433496106415987,
      0.03265432268381119,
      -0.006548529956489801,
      0.0011209753574803472,
      0.0005775662139058113,
      -0.047356970608234406,
      -0.03975704684853554,
      -0.05466303601861,
      -0.00463911471888423,
      0.11012828350067139,
      0.08862939476966858,
      0.08482661843299866,
      -0.0020747375674545765,
      -0.033235784620046616,
      0.027360789477825165,
      -0.0179050974547863,
      -0.04785619676113129,
      0.0675218477845192,
      -0.06826663762331009,
      -0.046885423362255096,
      0.07849679887294769,
      -0.020522084087133408,
      -0.02691885270178318,
      -0.1097634807229042,
      -0.026731321588158607,
      0.007657041773200035,
      -0.00677497498691082,
      -0.026467682793736458,
      -0.0391400009393692,
      -0.08884578198194504,
      0.007066232617944479,
      0.005012507550418377,
      0.04471515491604805,
      0.07385773956775665,
      0.008638435043394566,
      0.04464724659919739,
      0.0015397827373817563,
      -0.0675862729549408,
      0.0032189227640628815,
      0.05834012106060982,
      0.027514472603797913,
      0.02839474007487297,
      -0.009315659292042255,
      0.03433237969875336,
      0.06420232355594635,
      -0.01722685433924198,
      0.019048532471060753,
      -0.007561468984931707,
      -0.029684627428650856,
      0.008193732239305973,
      0.03260768949985504,
      -0.024738749489188194,
      0.06899236142635345,
      0.06067827716469765,
      0.09560917317867279,
      -0.008316363207995892,
      0.03848399594426155,
      0.0277733001857996,
      0.04138974845409393,
      -0.009779578074812889,
      -0.09110355377197266,
      0.05604865029454231,
      0.010011618956923485,
      -0.04844514653086662,
      -0.016457468271255493,
      -0.06675636023283005,
      -0.03025398589670658,
      -0.008290084078907967,
      -0.0174359492957592,
      -0.011196843348443508,
      0.0033595466520637274,
      -0.030993731692433357,
      -0.04236367344856262,
      0.04219856858253479,
      0.04840707778930664,
      0.06819433718919754,
      0.01906413771212101,
      -0.013558304868638515,
      0.05100097134709358,
      -0.026705723255872726,
      -0.07233798503875732,
      -0.006655103527009487,
      0.10534155368804932,
      0.0550975427031517,
      0.035841990262269974,
      0.00915518682450056,
      0.06163431331515312,
      -0.0029066179413348436,
      0.04144781082868576,
      -0.004505585413426161,
      -0.07572798430919647,
      0.017607076093554497,
      0.03505704551935196,
      -0.027194146066904068,
      -0.02184145897626877,
      0.054030947387218475,
      -0.07898927479982376,
      -0.014844672754406929,
      0.004013501573354006,
      -0.03330882266163826,
      -0.1343548744916916,
      0.0011353041045367718,
      -0.054098159074783325,
      -0.023815691471099854,
      0.05395941808819771,
      -0.023004602640867233,
      0.012958107516169548,
      0.004225080367177725,
      -0.04860587418079376,
      -0.06342414021492004,
      -0.00633196160197258,
      0.054129455238580704,
      0.033075135201215744,
      -0.04815356805920601,
      0.03518553823232651,
      -0.09834672510623932,
      -0.06471855938434601,
      0.08874724060297012,
      0.04009418562054634,
      -0.028016773983836174,
      -0.029691992327570915,
      0.053003232926130295,
      -0.027995962649583817,
      -0.029907861724495888,
      0.02908291108906269,
      0.010092834010720253,
      0.0008533297805115581,
      0.10587084293365479,
      -0.06306641548871994,
      -0.03132256492972374,
      0.0017519645625725389,
      0.024687306955456734,
      0.0004610399482771754,
      0.01822272501885891,
      -0.05041942372918129,
      -0.0113932890817523,
      -0.03997759893536568,
      0.006820485927164555,
      -0.00140795495826751,
      -0.021893959492444992,
      0.024989765137434006,
      -0.047952666878700256,
      0.08014903962612152,
      -0.06672707945108414,
      -0.017064930871129036,
      0.053039733320474625,
      -0.015866102650761604,
      0.03576701134443283,
      -0.06092330440878868,
      -0.08057719469070435,
      -0.0867428183555603,
      -0.0030048408079892397,
      0.010068293660879135,
      0.009694556705653667,
      0.0372699499130249,
      0.041193269193172455,
      -0.029156005010008812,
      0.011583761312067509,
      0.03606065362691879,
      0.07379737496376038,
      0.03609447181224823,
      -0.024651028215885162,
      -0.12381104379892349,
      0.012840953655540943,
      -0.02665802836418152,
      -0.03100959025323391,
      0.04981560632586479,
      -0.01920580118894577,
      0.01119825430214405,
      -0.020473886281251907,
      0.015911312773823738,
      -0.06873885542154312,
      -0.0041558160446584225,
      0.07597923278808594,
      0.0697549507021904,
      -0.11172176897525787,
      -0.13046784698963165,
      0.02344011515378952,
      0.02342786267399788,
      0.09640822559595108,
      0.033934276551008224,
      0.0021331156603991985,
      0.03015793114900589,
      -0.013987276703119278,
      0.01568717509508133,
      -0.025090156123042107,
      0.05260581150650978,
      0.0037190609145909548,
      0.04152265563607216,
      0.0334172248840332,
      -0.07390065491199493,
      0.015841428190469742,
      0.019111283123493195,
      0.002711301203817129,
      0.008889916352927685,
      0.06857690960168839,
      0.017017275094985962,
      0.014909747987985611,
      0.02090461365878582,
      -0.011866878718137741,
      0.020281437784433365,
      0.002812472637742758,
      0.02754795178771019,
      0.007854466326534748,
      -0.055325787514448166,
      0.04845055192708969,
      -0.03291753679513931,
      0.021538322791457176,
      -0.021352753043174744,
      -0.05258616432547569,
      -0.02615136094391346,
      -0.016453633084893227,
      0.022673064842820168,
      -0.0052318568341434,
      0.09062295407056808,
      -0.0014002504758536816,
      0.001666909083724022,
      -0.04567113518714905,
      -0.05766370892524719,
      -0.07053939253091812,
      -0.012978928163647652,
      0.07708676904439926,
      -0.024324404075741768,
      -0.006313290446996689,
      -0.058361880481243134,
      -0.012307059019804,
      0.014314076863229275,
      -0.010786373168230057,
      0.05281629413366318,
      -0.05965714529156685,
      0.057048309594392776,
      -0.08100101351737976,
      0.046407684683799744,
      0.0466078445315361,
      0.022254567593336105,
      0.02114029787480831,
      -0.00180023571010679,
      -0.03042510710656643,
      -0.02342214062809944,
      0.015092248097062111,
      -0.06012982875108719,
      0.10286851972341537,
      0.038473453372716904,
      -0.08047177642583847,
      0.03099040314555168,
      0.011462513357400894,
      0.049497153609991074,
      -0.022178377956151962,
      0.0037080878391861916,
      0.06625599414110184,
      0.04130955785512924,
      0.010068995878100395,
      0.0345022939145565,
      0.0023193100932985544,
      0.028526533395051956,
      0.007963479496538639,
      -0.019962579011917114,
      -0.020173577591776848,
      0.00020416270126588643,
      0.061087001115083694,
      0.001984537811949849,
      -0.10695118457078934,
      -0.01363776158541441,
      0.05932258814573288,
      -0.0030280472710728645,
      -0.0009558836463838816,
      -0.09530509263277054,
      -0.011418847367167473,
      -1.2731967217405327e-05,
      -0.015063981525599957,
      -0.008414296433329582,
      -0.020981214940547943,
      0.0011604580795392394,
      0.0026045599952340126,
      0.014772098511457443,
      -0.008786586113274097,
      -0.05697697028517723,
      -0.026250438764691353,
      0.005733626428991556,
      -0.036933377385139465,
      0.0015703866956755519,
      0.04750931262969971,
      -0.059945665299892426,
      -0.0966435894370079,
      0.03362865746021271,
      -0.08741379529237747
    ],
    [
      -0.007781432010233402,
      0.02711663581430912,
      -0.051856279373168945,
      0.061863742768764496,
      0.05257444456219673,
      0.021396011114120483,
      0.06852424144744873,
      0.013169698417186737,
      0.06409019231796265,
      -0.01934695802628994,
      -0.020743198692798615,
      -0.013693792745471,
      0.05033208057284355,
      0.02400105632841587,
      -0.059218376874923706,
      -0.015066159889101982,
      -0.03135014325380325,
      -0.014058573171496391,
      -0.0381375215947628,
      -0.11709559708833694,
      -0.06023823842406273,
      -0.046744074672460556,
      0.024625396355986595,
      -0.018955476582050323,
      -0.042243603616952896,
      0.022281209006905556,
      0.012951595708727837,
      0.018970703706145287,
      0.0046721892431378365,
      0.02353024296462536,
      -0.028176821768283844,
      -0.021208850666880608,
      -0.03751671686768532,
      -0.030622214078903198,
      -0.060058340430259705,
      -0.04599882289767265,
      0.0076108165085315704,
      -0.028072761371731758,
      0.031076496466994286,
      -0.05092058703303337,
      0.01753227412700653,
      -0.00588913494721055,
      0.03716602176427841,
      -0.057265326380729675,
      0.06397351622581482,
      0.048502471297979355,
      -0.07408364862203598,
      -0.007254739757627249,
      0.008499138057231903,
      0.01931566558778286,
      -0.019929789006710052,
      0.04137571528553963,
      -0.04209739342331886,
      -0.05446872487664223,
      0.00029515166534110904,
      0.032758679240942,
      0.02514760196208954,
      0.034961383789777756,
      0.03250527381896973,
      0.08577203005552292,
      -0.010676199570298195,
      -0.014103693887591362,
      -0.045653678476810455,
      -0.004572303965687752,
      0.06248043477535248,
      -0.03040209412574768,
      0.009226959198713303,
      0.0008475577342323959,
      0.0034108778927475214,
      0.049012813717126846,
      -0.03240855410695076,
      -0.026325559243559837,
      -0.11848588287830353,
      0.025639064610004425,
      -0.06498933583498001,
      0.05960429087281227,
      0.007431519217789173,
      -0.07008454948663712,
      3.502787876641378e-05,
      0.006310411728918552,
      0.002695576986297965,
      -0.06564904004335403,
      0.010536260902881622,
      0.05512278154492378,
      -0.03424198180437088,
      0.030448079109191895,
      -0.14569266140460968,
      0.008245155215263367,
      0.02698965184390545,
      -0.03541313111782074,
      -0.07670243829488754,
      0.04798855260014534,
      0.033196866512298584,
      0.0558963343501091,
      0.06644625216722488,
      0.06049707159399986,
      0.001964650582522154,
      -0.06585325300693512,
      0.003106626681983471,
      0.018371302634477615,
      0.03512543439865112,
      -0.05782380327582359,
      -0.02028866671025753,
      -0.05230898782610893,
      -0.08120494335889816,
      0.046096816658973694,
      -0.03754241764545441,
      0.03545502573251724,
      0.033047862350940704,
      -0.013384700752794743,
      -0.056306928396224976,
      0.02120690792798996,
      -0.06742221862077713,
      0.0027519098948687315,
      -0.086910679936409,
      0.07269012182950974,
      0.06608884036540985,
      -0.047107938677072525,
      0.014176815748214722,
      -0.0232317466288805,
      -0.00819381419569254,
      0.0370270237326622,
      -0.015094444155693054,
      0.03301388770341873,
      0.0062872301787137985,
      -0.01999278925359249,
      -0.06107334420084953,
      0.048367224633693695,
      -0.02233547903597355,
      0.0037408056668937206,
      0.04007970914244652,
      0.09811902791261673,
      -0.006009211763739586,
      -0.006832225248217583,
      0.0068084425292909145,
      -0.05595186725258827,
      0.008100096136331558,
      0.02573070488870144,
      -0.010982362553477287,
      0.03364066034555435,
      0.037851955741643906,
      0.06438636779785156,
      -0.08700500428676605,
      0.06283918768167496,
      -0.01800226792693138,
      -0.0734952911734581,
      0.0019546211697161198,
      0.049671415239572525,
      0.06150534003973007,
      -0.016461480408906937,
      -0.07054213434457779,
      -0.023441288620233536,
      -0.00648684985935688,
      0.0032558331731706858,
      0.005698601249605417,
      -0.016945555806159973,
      -0.038779329508543015,
      -0.025157446041703224,
      0.07523733377456665,
      -0.008043459616601467,
      -0.03289250656962395,
      0.015128891915082932,
      0.06891074031591415,
      -0.004104862455278635,
      -0.03192669525742531,
      0.04165108874440193,
      0.02432745136320591,
      -0.0048550795763731,
      -0.0960325077176094,
      0.1088508740067482,
      0.09510564059019089,
      -0.05429110303521156,
      0.09933654963970184,
      -0.06434734165668488,
      -0.06935799866914749,
      -0.04766537994146347,
      0.04480982571840286,
      -0.015268740244209766,
      -0.021935010328888893,
      0.027180442586541176,
      0.010645400732755661,
      0.07839834690093994,
      -0.0841611698269844,
      0.053490813821554184,
      0.004917164798825979,
      -0.033422552049160004,
      0.08500627428293228,
      -0.036278970539569855,
      0.005856222473084927,
      0.016927631571888924,
      0.0329183004796505,
      -0.0785253569483757,
      0.008462741039693356,
      0.01025437843054533,
      0.008422435261309147,
      0.11842119693756104,
      -0.029326321557164192,
      -0.0003044182958547026,
      0.0042999619618058205,
      0.004997220356017351,
      0.08024914562702179,
      -0.08556567877531052,
      0.03560185804963112,
      0.030522679910063744,
      -0.001903967116959393,
      0.021797453984618187,
      0.031628113240003586,
      -0.02239406108856201,
      0.025659317150712013,
      -0.00988473929464817,
      -0.011963295750319958,
      0.011037235148251057,
      0.01006314903497696,
      0.04072725772857666,
      0.005432847887277603,
      -0.018523726612329483,
      -0.10625585913658142,
      0.07771113514900208,
      -0.012517525814473629,
      0.06848014146089554,
      0.04174071177840233,
      0.018267203122377396,
      0.017102966085076332,
      0.04618731513619423,
      0.010488327592611313,
      -0.009077607654035091,
      0.06674886494874954,
      0.03469748795032501,
      -0.018300678580999374,
      -0.024845242500305176,
      -0.10256413370370865,
      -0.025374313816428185,
      -0.00604571308940649,
      -0.05070790648460388,
      -0.044279612600803375,
      0.03993338719010353,
      -0.06366647034883499,
      0.04269631952047348,
      0.00016851691179908812,
      -0.001368580386042595,
      0.006604647263884544,
      0.015832941979169846,
      0.021324822679162025,
      0.06857521831989288,
      -0.03962826728820801,
      0.04157550632953644,
      -0.043893273919820786,
      0.05785447359085083,
      -0.034764841198921204,
      0.012025464326143265,
      0.0025305727031081915,
      -0.03009309060871601,
      -0.016749875620007515,
      0.009823568165302277,
      0.05898044630885124,
      0.01846039667725563,
      0.07546347379684448,
      -0.04310645908117294,
      -0.0643775686621666,
      0.04147937148809433,
      0.017604239284992218,
      0.02262137643992901,
      0.007800457999110222,
      0.05172966793179512,
      -0.13149642944335938,
      -0.026477482169866562,
      0.05947433039546013,
      0.0608036182820797,
      -0.02822030335664749,
      0.03273887187242508,
      0.0015264281537383795,
      0.016244307160377502,
      -0.005363703239709139,
      -0.03462906926870346,
      -0.013756029307842255,
      0.01221442874521017,
      0.019385680556297302,
      0.021647082641720772,
      0.003920848481357098,
      -0.006180289201438427,
      -0.04101702198386192,
      0.008548714220523834,
      -0.0037939369212836027,
      -0.04675397649407387,
      0.01492988783866167,
      -0.06776003539562225,
      0.05640573054552078,
      0.029997557401657104,
      -0.017150619998574257,
      -0.02766270563006401,
      0.07288085669279099,
      -0.04390895739197731,
      -0.022126920521259308,
      -0.031412482261657715,
      0.051430873572826385,
      0.09620900452136993,
      -0.033728040754795074,
      -0.03175851330161095,
      -0.009059306234121323,
      0.04588295891880989,
      0.007102584931999445,
      -0.04884859547019005,
      0.004161118995398283,
      0.09584828466176987,
      -0.02069607563316822,
      0.0058875358663499355,
      -0.046941258013248444,
      0.056223854422569275,
      -0.002828411990776658,
      0.028113344684243202,
      0.07557613402605057,
      0.031687814742326736,
      0.006397454999387264,
      -0.0014878319343551993,
      0.024880141019821167,
      -0.020115040242671967,
      0.0038468718994408846,
      0.00235901796258986,
      -0.024829287081956863,
      -0.02680387534201145,
      0.00748992245644331,
      -0.03589068725705147,
      -0.017069827765226364,
      0.07263974845409393,
      0.10284679383039474,
      0.052333567291498184,
      -0.0670655220746994,
      0.007241895422339439,
      0.008257845416665077,
      0.042029738426208496,
      -0.02524954453110695,
      -0.007562014739960432,
      0.03563883155584335,
      0.09235385060310364,
      -0.032405901700258255,
      -0.10190445929765701,
      -0.03581656888127327,
      -0.044022612273693085,
      -0.0923231989145279,
      -0.022872647270560265,
      0.015027860179543495,
      -0.05289312079548836,
      0.020682618021965027,
      0.022688334807753563,
      -0.07501623034477234,
      -0.07401308417320251,
      0.08562985807657242,
      -0.08737628161907196,
      0.025220634415745735,
      0.027843257412314415,
      -0.09735473990440369,
      -0.05488210543990135,
      -0.033920370042324066,
      -0.09590575844049454,
      -0.04128570854663849,
      0.02752208709716797,
      -0.04147334396839142,
      0.08287635445594788,
      0.06270506232976913,
      -0.019409870728850365,
      -0.026666007936000824,
      -0.04660220816731453,
      -0.02798726037144661,
      0.10339408367872238,
      -0.0579092912375927,
      0.027251550927758217,
      -0.02089931257069111,
      -0.008602377027273178,
      0.02514125034213066,
      -0.010290179401636124,
      0.029478108510375023,
      0.09361310303211212,
      0.030894523486495018,
      0.012811838649213314,
      0.016386477276682854,
      -0.010776733048260212,
      -0.00645899074152112,
      -0.03083224408328533,
      0.09872350841760635,
      -0.027057019993662834,
      0.05237651616334915,
      -0.06743751466274261,
      -0.08537899702787399,
      -0.06706544756889343,
      0.03252624347805977,
      0.01444336213171482,
      -0.015039965510368347,
      -0.04904283210635185,
      -0.01649845764040947,
      0.032509300857782364,
      0.021404854953289032,
      0.148382768034935,
      -0.002247030846774578,
      -0.07719743996858597,
      -0.05754509195685387,
      -0.001788361114449799,
      -0.09284072369337082,
      0.03487737104296684,
      -0.04957544058561325,
      -0.008846723474562168,
      0.07952829450368881,
      -0.022975042462348938,
      -0.003802947234362364,
      0.0016047239769250154,
      0.07776779681444168,
      0.02143426612019539,
      0.05655083805322647,
      0.002751110354438424,
      0.003330186940729618,
      0.036707013845443726,
      0.030637094751000404,
      0.024494227021932602,
      0.0062772901728749275,
      -0.07691695541143417,
      0.0353524275124073,
      0.029850322753190994,
      0.04743896424770355,
      0.05257750302553177,
      -0.034488651901483536,
      0.04090074449777603,
      -0.06688512861728668,
      0.0015686642145738006,
      0.02466614358127117,
      0.031819067895412445,
      -0.03618954122066498,
      0.023364540189504623,
      0.012305163778364658,
      -0.007507013622671366,
      0.06894239038228989,
      -0.009142467752099037,
      -0.0001093379469239153,
      -0.04907052591443062,
      -0.035826656967401505,
      0.002829780336469412,
      0.022492174059152603,
      0.07441291958093643,
      -0.0033879103139042854,
      0.02899063006043434,
      0.0004551945603452623,
      -0.011537500657141209,
      -0.06685999780893326,
      -0.004956815857440233,
      0.0075574880465865135,
      0.012165569700300694,
      0.03197728842496872,
      -0.008675631135702133,
      0.011477638967335224,
      0.05382062867283821,
      0.006519834045320749,
      -0.07010678946971893,
      0.0008472814806737006,
      0.03455426171422005,
      0.0797002837061882,
      0.12599094212055206,
      0.011889008805155754,
      -0.01608644239604473,
      -0.036960847675800323,
      0.016711100935935974,
      0.048078618943691254,
      0.01989789493381977,
      -0.014379383996129036,
      -0.07051531225442886,
      -0.05651179328560829,
      0.04961859807372093,
      0.011026330292224884,
      0.049353815615177155,
      -0.01678137667477131,
      0.004337392747402191,
      -0.0037963709328323603,
      -0.0105293495580554,
      -0.10479649156332016,
      0.03765207529067993,
      0.14033472537994385,
      0.054890576750040054,
      0.01789413020014763,
      -0.061265330761671066,
      0.02834951877593994,
      0.03398623690009117,
      0.029359430074691772,
      -0.020482681691646576,
      0.03077106736600399,
      0.06767648458480835,
      0.017985474318265915,
      0.032236721366643906,
      -0.004780033603310585,
      0.0009132765117101371,
      0.07330172508955002,
      0.0911678671836853,
      0.05564753711223602,
      -0.006383458152413368,
      -0.009052642621099949,
      0.04187951609492302,
      -0.04743042215704918,
      -0.018708014860749245,
      -0.01403054315596819,
      0.03936596214771271,
      0.08483762294054031,
      0.05278267711400986,
      0.03096528723835945,
      0.018702371045947075,
      0.0025810925289988518,
      -0.005286009516566992,
      -0.027413832023739815
    ],
    [
      -0.026872456073760986,
      -0.046592120081186295,
      -0.024830490350723267,
      -0.015666713938117027,
      0.010552283376455307,
      -0.07147624343633652,
      0.043342359364032745,
      -0.013026361353695393,
      -0.011919376440346241,
      0.0729893296957016,
      -0.07680707424879074,
      0.05488784611225128,
      0.011819619685411453,
      -0.07312348484992981,
      -0.06187635287642479,
      -0.0179680697619915,
      -0.022642165422439575,
      0.02614702470600605,
      -0.025783538818359375,
      -0.024269219487905502,
      0.01221937220543623,
      -0.016873711720108986,
      0.043641332536935806,
      -0.03115774132311344,
      -0.10361050069332123,
      0.012373900040984154,
      -0.030537130311131477,
      0.07857075333595276,
      -0.016569966450333595,
      0.022955622524023056,
      0.020006345584988594,
      -0.04190513491630554,
      0.020384106785058975,
      -0.031337715685367584,
      -0.018608612939715385,
      -0.07387135177850723,
      -0.03533792868256569,
      -0.048970211297273636,
      -0.01529985386878252,
      0.004254720639437437,
      0.014300184324383736,
      -0.037366174161434174,
      -0.005302270874381065,
      -0.039042435586452484,
      0.05457482114434242,
      0.005606530699878931,
      -0.020841144025325775,
      0.031522154808044434,
      0.05114537850022316,
      -0.03168082237243652,
      -0.016710970550775528,
      -0.035174012184143066,
      0.02931748703122139,
      0.0829172357916832,
      0.03191899135708809,
      0.014748189598321915,
      -0.03176115080714226,
      -0.033935584127902985,
      0.11662377417087555,
      0.011745844036340714,
      0.017216363921761513,
      -0.05043985694646835,
      0.04739361256361008,
      -0.03430910408496857,
      0.08771028369665146,
      -0.024327032268047333,
      -0.025632701814174652,
      0.1032910868525505,
      -0.07406595349311829,
      0.017466219142079353,
      -0.010888739489018917,
      -0.016338007524609566,
      -0.006370821502059698,
      0.04111550748348236,
      0.010929749347269535,
      -0.010175058618187904,
      0.09113045781850815,
      -0.029399054124951363,
      0.0248450618237257,
      0.07326918095350266,
      -0.011274345219135284,
      -0.050269316881895065,
      -0.06642872840166092,
      -0.011348060332238674,
      0.023539938032627106,
      0.03438573330640793,
      -0.11484269052743912,
      -0.10921353846788406,
      0.03712385520339012,
      0.020459624007344246,
      0.07916197925806046,
      0.05343013256788254,
      -0.045114412903785706,
      0.08135638386011124,
      0.012492923997342587,
      0.049867693334817886,
      0.05224476382136345,
      -0.046825967729091644,
      -0.09419240057468414,
      0.03749330714344978,
      0.03676433488726616,
      0.010966893285512924,
      0.017958350479602814,
      -0.01603548228740692,
      0.050117045640945435,
      0.02591814287006855,
      0.03011857531964779,
      0.03335412219166756,
      -0.004634336102753878,
      -0.04533850774168968,
      0.011650378815829754,
      -0.006164587568491697,
      -0.08679535239934921,
      0.00975487008690834,
      -0.12445282191038132,
      -0.04167254641652107,
      0.020561549812555313,
      0.07606584578752518,
      -0.08514678478240967,
      0.012901632115244865,
      -0.08491969853639603,
      0.04544598236680031,
      0.04941456764936447,
      -0.050759438425302505,
      -0.013338764198124409,
      -0.01639304682612419,
      -0.020675867795944214,
      0.08908837288618088,
      0.02201959863305092,
      -0.020921580493450165,
      -0.07400838285684586,
      -0.011646761558949947,
      -0.009774070233106613,
      0.00023763389617670327,
      0.04697335138916969,
      0.056419651955366135,
      0.005558475386351347,
      0.020975826308131218,
      0.048221901059150696,
      -0.07612263411283493,
      0.06897450983524323,
      0.02173229120671749,
      -0.028769703581929207,
      -0.05526583269238472,
      -0.06143338605761528,
      0.034598056226968765,
      0.016825329512357712,
      0.04968452826142311,
      0.07310822606086731,
      0.016197629272937775,
      -0.019930865615606308,
      0.049405939877033234,
      0.04436212778091431,
      0.018410274758934975,
      -0.039040930569171906,
      0.024762708693742752,
      0.04324967786669731,
      0.05672868341207504,
      -0.05476430803537369,
      0.006649420130997896,
      0.011236016638576984,
      -0.11394624412059784,
      -0.09263500571250916,
      -0.09614698588848114,
      0.08139688521623611,
      -0.015762288123369217,
      0.009645740501582623,
      -0.035427723079919815,
      -0.050897419452667236,
      -0.026208586990833282,
      0.07083125412464142,
      -0.04302417114377022,
      -0.040482889860868454,
      0.013329348526895046,
      0.06048143282532692,
      -0.043310221284627914,
      0.027860580012202263,
      -0.09667864441871643,
      -0.005433643702417612,
      0.030627422034740448,
      -0.0011753494618460536,
      0.019409194588661194,
      0.11965372413396835,
      -0.07729697972536087,
      -0.04019007459282875,
      0.05241895839571953,
      -0.04549861699342728,
      -0.01088797114789486,
      0.022792955860495567,
      -0.06305082887411118,
      0.061734821647405624,
      0.019588349387049675,
      -0.08907374739646912,
      -0.0051695420406758785,
      0.03221956267952919,
      -0.023621447384357452,
      -0.014449089765548706,
      0.04681027680635452,
      0.002656278433278203,
      -0.0007537972996942699,
      -0.053381431847810745,
      -0.03034026548266411,
      0.06934793293476105,
      -0.04255421459674835,
      0.05241837725043297,
      -0.04739363119006157,
      -0.08085481077432632,
      -0.00813615694642067,
      0.023850496858358383,
      -0.024784483015537262,
      0.0546269416809082,
      0.05116009712219238,
      -0.00647693919017911,
      -0.011501464992761612,
      -0.03558630496263504,
      -0.032318804413080215,
      -0.03182847797870636,
      0.0514664351940155,
      0.05594554543495178,
      0.04261106997728348,
      0.004419585224241018,
      0.05315854400396347,
      0.06155533716082573,
      0.020248986780643463,
      0.018210632726550102,
      0.08513757586479187,
      -0.02470754086971283,
      0.052593547850847244,
      -0.08286869525909424,
      0.058197952806949615,
      0.05009883642196655,
      0.06485095620155334,
      -0.05384456738829613,
      -0.0013572985772043467,
      -0.07753126323223114,
      0.007197523955255747,
      -0.034903738647699356,
      0.010374471545219421,
      -0.054538268595933914,
      0.018882570788264275,
      0.012387389317154884,
      -0.015175607055425644,
      0.02059297077357769,
      -0.011981047689914703,
      0.07540629804134369,
      0.005010498687624931,
      -0.04474002495408058,
      0.013848880305886269,
      0.04295675829052925,
      0.008350369520485401,
      -0.07867524772882462,
      -0.004311887081712484,
      0.003841235302388668,
      -0.018654972314834595,
      -0.047466300427913666,
      -0.010494403541088104,
      0.031799882650375366,
      0.015765098854899406,
      -0.03819556161761284,
      -0.055107854306697845,
      -0.002463607583194971,
      -0.03914513811469078,
      -0.016646357253193855,
      0.020960427820682526,
      -0.045023079961538315,
      -0.001098275650292635,
      -0.009734592400491238,
      0.018806543201208115,
      -0.029645927250385284,
      -0.023776769638061523,
      -0.08136662095785141,
      -0.05458378046751022,
      -0.05455220863223076,
      -0.060108963400125504,
      0.03254475072026253,
      0.0016796250129118562,
      -0.023986713960766792,
      -0.0254435446113348,
      0.027599738910794258,
      -0.00619636382907629,
      0.03266691789031029,
      -0.03429499268531799,
      -0.0013866662047803402,
      0.025247594341635704,
      -0.06366670876741409,
      0.003335924819111824,
      -0.05068139359354973,
      0.050573453307151794,
      0.05420824512839317,
      -0.02934850938618183,
      0.003008499275892973,
      -0.1205819845199585,
      -0.0021201628260314465,
      -0.05640929564833641,
      -0.011939813382923603,
      0.08980195969343185,
      -0.030897948890924454,
      -0.04187222197651863,
      -0.015866653993725777,
      0.041707612574100494,
      -0.017288213595747948,
      -0.09332268685102463,
      -0.024016574025154114,
      0.015897562727332115,
      -0.0025675452779978514,
      -0.06712282449007034,
      -0.07221180945634842,
      -0.017963629215955734,
      0.010324201546609402,
      -0.0362076498568058,
      -0.007469466887414455,
      -0.05250472575426102,
      0.025317402556538582,
      0.0008100889972411096,
      -0.04415332153439522,
      0.012088412418961525,
      -0.060846857726573944,
      -0.01550364401191473,
      -0.08997650444507599,
      0.0075917551293969154,
      0.0030713146552443504,
      -0.017497485503554344,
      -0.01452783402055502,
      0.023001139983534813,
      -0.03104858472943306,
      -0.06224221736192703,
      -0.02014831453561783,
      -0.07071389257907867,
      0.1289834827184677,
      0.07197412848472595,
      0.08523911982774734,
      -0.022005824372172356,
      0.060933955013751984,
      -0.019428826868534088,
      -0.004546274431049824,
      -0.015091479755938053,
      0.04197797179222107,
      -0.03513256087899208,
      -0.002775148255750537,
      -0.037575267255306244,
      -0.03450104966759682,
      0.05206763371825218,
      -0.0145637895911932,
      0.00816617626696825,
      0.0821450874209404,
      -0.06666123121976852,
      0.02024022862315178,
      -0.04516256973147392,
      0.019958792254328728,
      0.003192644100636244,
      0.007200811058282852,
      -0.06871389597654343,
      -0.013772671110928059,
      -0.01467793807387352,
      -0.005394186824560165,
      -0.05521564558148384,
      -0.050989482551813126,
      0.014522714540362358,
      -0.03166315704584122,
      -0.04435848817229271,
      0.034562792629003525,
      0.02035738341510296,
      0.039268504828214645,
      0.0426756925880909,
      0.007181752473115921,
      -0.026392066851258278,
      0.03216607868671417,
      -0.05248083919286728,
      -0.07448412477970123,
      0.04604547098278999,
      -0.024129778146743774,
      0.03617468103766441,
      0.04446347802877426,
      0.00727069191634655,
      0.02882816456258297,
      -0.05185331776738167,
      0.06123381853103638,
      -0.07462575286626816,
      0.00458346726372838,
      0.02545773983001709,
      -0.017190413549542427,
      -0.02086576819419861,
      -0.014283101074397564,
      0.009153429418802261,
      0.003892038483172655,
      -0.07513996958732605,
      0.04013809189200401,
      0.012984242290258408,
      -0.012416220270097256,
      -0.001238327706232667,
      -0.04489579051733017,
      0.031426724046468735,
      0.02373250387609005,
      -0.053955987095832825,
      -0.06927217543125153,
      -0.04928731173276901,
      0.02678203023970127,
      0.024500703439116478,
      -0.03692542389035225,
      -0.057774875313043594,
      0.008485177531838417,
      -0.10448138415813446,
      -0.003439594293013215,
      1.8433373043080792e-05,
      -0.02291925810277462,
      0.008345899172127247,
      0.033758506178855896,
      -0.12770956754684448,
      0.04180019721388817,
      -0.023922862485051155,
      0.04477499797940254,
      0.01767135038971901,
      0.017409196123480797,
      -0.002320458646863699,
      0.06643310189247131,
      -0.010802602395415306,
      0.0086071090772748,
      0.03913241624832153,
      -0.02054631896317005,
      -0.0585876889526844,
      0.051420941948890686,
      -0.05911165103316307,
      -0.023699650540947914,
      0.03935837000608444,
      0.03990336135029793,
      0.007586257066577673,
      0.008927646093070507,
      -0.02338525652885437,
      0.06293010711669922,
      0.03893974423408508,
      -0.02394915744662285,
      -0.01963404007256031,
      -0.03988320007920265,
      -0.026019617915153503,
      0.012897257693111897,
      -0.047397635877132416,
      -0.07402606308460236,
      -0.06070701405405998,
      -0.013876902870833874,
      -0.06437399238348007,
      -0.0029085823334753513,
      0.024097656831145287,
      0.03114049695432186,
      0.059755418449640274,
      -0.019226185977458954,
      -0.026080531999468803,
      -0.04435495659708977,
      -0.0045457324013113976,
      -0.04007546603679657,
      0.02056998200714588,
      0.028459399938583374,
      -0.030555201694369316,
      0.03474494442343712,
      0.04479851946234703,
      -0.012253066524863243,
      -0.11608398705720901,
      0.029096951708197594,
      0.02340388484299183,
      0.02304597571492195,
      0.012071536853909492,
      0.005433503072708845,
      -0.07203871756792068,
      -0.019985705614089966,
      0.11939717829227448,
      -0.013045022264122963,
      -0.0249931700527668,
      -0.02707689255475998,
      0.0272360909730196,
      0.04855772480368614,
      0.023721177130937576,
      0.06321757286787033,
      0.013821111060678959,
      -0.02983163110911846,
      0.08838526159524918,
      -0.030723482370376587,
      0.023235883563756943,
      -0.06546685099601746,
      -0.02579500712454319,
      0.0061125787906348705,
      -0.09750793874263763,
      -0.07404696196317673,
      -0.041293915361166,
      0.020496821030974388,
      0.03747348487377167,
      0.006391989998519421,
      0.040926963090896606,
      -0.03785659000277519,
      0.0030034093651920557,
      -0.07762882858514786,
      -0.03785756230354309,
      -0.06793827563524246,
      0.00025777058908715844,
      0.04816153645515442,
      -0.025706298649311066,
      -0.022514082491397858,
      0.040898505598306656,
      0.08973843604326248,
      -0.010201716795563698,
      0.0026493959594517946,
      0.01951073482632637,
      -0.057652585208415985,
      0.003370417980477214,
      0.0489775687456131
    ],
    [
      -0.03848743811249733,
      -0.046271275728940964,
      0.028601927682757378,
      -0.023656753823161125,
      -0.037558212876319885,
      -0.0418085902929306,
      -0.04191417992115021,
      0.02584288828074932,
      -0.051936376839876175,
      0.03509373217821121,
      0.0017008024733513594,
      0.03134407475590706,
      0.09867918491363525,
      0.07048184424638748,
      -0.017793741077184677,
      0.014176852069795132,
      -0.04165838286280632,
      -0.05179131031036377,
      -0.028348997235298157,
      -0.027698194608092308,
      -0.005667889025062323,
      0.07418342679738998,
      0.015694180503487587,
      0.08084369450807571,
      0.0084381690248847,
      -0.003409493248909712,
      -0.09160437434911728,
      0.04010570049285889,
      -0.07658250629901886,
      0.007824602536857128,
      -0.09062085300683975,
      0.009786931797862053,
      -0.008864219300448895,
      -0.008517666719853878,
      0.05873970687389374,
      -0.02610792964696884,
      0.006162717007100582,
      0.043245866894721985,
      0.008898794651031494,
      -0.06706225126981735,
      0.05992855131626129,
      -0.09441886842250824,
      -0.016889413818717003,
      -0.028046363964676857,
      -0.03401530906558037,
      0.05972792208194733,
      0.031144892796874046,
      0.01699843816459179,
      -0.0019699265249073505,
      0.010169927962124348,
      0.018925808370113373,
      0.08413023501634598,
      -0.07036487758159637,
      -0.09017368406057358,
      0.008568309247493744,
      0.05544377490878105,
      -0.04886221885681152,
      -0.011339213699102402,
      -0.12400585412979126,
      0.07680954039096832,
      -0.04549296200275421,
      -0.013326486572623253,
      -0.021528221666812897,
      0.008569534868001938,
      0.0664994940161705,
      0.05761708691716194,
      -0.06447307020425797,
      -0.02477916143834591,
      0.06990829110145569,
      0.040889542549848557,
      -0.057506829500198364,
      -0.04496484249830246,
      0.014849044382572174,
      0.012792391702532768,
      0.010644882917404175,
      -0.032291486859321594,
      0.050231777131557465,
      0.011581454426050186,
      0.008680510334670544,
      -0.08792457729578018,
      0.05039958283305168,
      -0.04416804015636444,
      0.038764387369155884,
      -0.039573539048433304,
      0.021831562742590904,
      0.07419724762439728,
      -0.017607424408197403,
      -0.045775528997182846,
      -0.10320740193128586,
      0.017830653116106987,
      -0.0005773214506916702,
      -0.005512002855539322,
      0.010453596711158752,
      -0.04149836674332619,
      0.013834899291396141,
      -0.05056392773985863,
      0.035717759281396866,
      0.0456324927508831,
      -0.003514228155836463,
      -0.05925136059522629,
      -0.06993411481380463,
      0.0002218767476733774,
      0.023336756974458694,
      0.0036046267487108707,
      -0.03365230932831764,
      -0.05817171931266785,
      -0.03539323806762695,
      -0.007501050364226103,
      0.04771158844232559,
      -0.03902360051870346,
      0.019449306651949883,
      -0.016583282500505447,
      -0.05862252786755562,
      0.030746763572096825,
      -0.004490378778427839,
      -0.006251003127545118,
      -0.002199595794081688,
      -0.028936417773365974,
      -0.03656553849577904,
      0.00953415036201477,
      0.001383479218930006,
      -0.008057261817157269,
      0.011313730850815773,
      0.04781918227672577,
      -0.05242099240422249,
      -0.033682964742183685,
      0.00030494079692289233,
      -0.013019395992159843,
      0.06895633786916733,
      -0.08810770511627197,
      -0.05014239624142647,
      -0.01671523042023182,
      0.02544485032558441,
      -0.025469869375228882,
      0.008414712734520435,
      -0.017789455130696297,
      0.041194457560777664,
      -0.013556618243455887,
      -0.010668248869478703,
      -0.007446825969964266,
      -0.06133817881345749,
      -0.04197460785508156,
      0.03433339670300484,
      0.0517953522503376,
      0.017926989123225212,
      0.04823713004589081,
      -0.030239513143897057,
      -0.036684177815914154,
      0.014945265837013721,
      0.04036400094628334,
      0.018957149237394333,
      0.026819545775651932,
      -0.016035931184887886,
      0.0919138640165329,
      -0.002142914803698659,
      0.0312827043235302,
      -0.023645352572202682,
      -0.0017784475348889828,
      0.03212267905473709,
      0.010721785016357899,
      0.024929111823439598,
      -0.02368268556892872,
      0.025052720680832863,
      -0.017148541286587715,
      0.04215038940310478,
      -0.021525057032704353,
      -0.009314561262726784,
      0.004606879316270351,
      0.045182496309280396,
      0.007356027141213417,
      0.03523346036672592,
      -0.06517969816923141,
      -0.011019107885658741,
      -0.019755611196160316,
      -0.03488563373684883,
      0.01385511551052332,
      0.0014018011279404163,
      0.004902569577097893,
      0.00994289480149746,
      0.09338361024856567,
      0.02095424011349678,
      0.02135825902223587,
      0.006878129672259092,
      0.008576820604503155,
      -0.0891212522983551,
      -0.04658612236380577,
      -0.022405851632356644,
      -0.011932016350328922,
      0.023830628022551537,
      -0.004849247634410858,
      0.04849682003259659,
      -0.006013050675392151,
      0.022571377456188202,
      -0.013617917895317078,
      -0.001094886683858931,
      -0.012607969343662262,
      0.005417598411440849,
      0.022362397983670235,
      -0.018067048862576485,
      0.057325273752212524,
      0.1054115816950798,
      0.09798742085695267,
      -0.013688018545508385,
      0.011175155639648438,
      -0.0319872610270977,
      -0.009563421830534935,
      0.04480155184864998,
      0.016684681177139282,
      0.0029339082539081573,
      -0.10305514931678772,
      0.020588083192706108,
      -0.026789894327521324,
      0.009125293232500553,
      0.14201363921165466,
      -0.09089134633541107,
      -0.06839729100465775,
      -0.043697111308574677,
      0.0068809716030955315,
      -0.03529375046491623,
      -0.0074654752388596535,
      -0.07583518326282501,
      -0.08977850526571274,
      -0.06847891956567764,
      -0.015542937442660332,
      0.060253117233514786,
      0.00845507811754942,
      0.11153515428304672,
      0.0564398467540741,
      -0.011509503237903118,
      0.041277699172496796,
      0.06430740654468536,
      0.025634199380874634,
      0.010647278279066086,
      -0.08228062093257904,
      -0.05065305158495903,
      0.035948850214481354,
      -0.007620006334036589,
      -0.021233132109045982,
      0.07822426408529282,
      0.03035309724509716,
      -0.03217851370573044,
      0.030312782153487206,
      0.024764657020568848,
      0.03136535733938217,
      -0.07480370253324509,
      0.07697591185569763,
      0.01447063498198986,
      -0.05005649849772453,
      -0.04574451968073845,
      -0.000674692855682224,
      0.06361504644155502,
      -0.05689621716737747,
      -0.022770512849092484,
      0.02752029336988926,
      0.012272906489670277,
      0.01636076346039772,
      0.04580476135015488,
      -0.047737956047058105,
      0.012071100994944572,
      -0.0029573345091193914,
      0.0014203224563971162,
      -0.01593664102256298,
      0.1175088882446289,
      0.030333157628774643,
      0.06353786587715149,
      -0.14523638784885406,
      0.0036073680967092514,
      0.025940176099538803,
      -0.03338748589158058,
      -0.12083642184734344,
      -0.06462456285953522,
      -0.023834381252527237,
      0.030275341123342514,
      -0.002808770863339305,
      0.03058491460978985,
      -0.01823623850941658,
      0.08023612201213837,
      -0.06514865905046463,
      -0.006629561074078083,
      0.08034638315439224,
      0.03202969208359718,
      0.061400510370731354,
      -0.11454059183597565,
      0.04654427617788315,
      0.022944001480937004,
      -0.046767234802246094,
      0.05114869028329849,
      -0.10815702378749847,
      -0.03418165072798729,
      -0.05770391970872879,
      0.0052016666159033775,
      -0.04539133608341217,
      0.02723359689116478,
      -0.07492265850305557,
      0.011422218754887581,
      0.013554593548178673,
      0.00647857366129756,
      0.034958481788635254,
      -0.08500541001558304,
      -4.357944999355823e-05,
      0.018157916143536568,
      0.06085265427827835,
      0.004619276151061058,
      0.04875322803854942,
      -0.031340908259153366,
      0.05558505281805992,
      -0.03770921751856804,
      0.033804696053266525,
      0.0213156808167696,
      0.0013140677474439144,
      -0.01600341685116291,
      0.029789362102746964,
      -0.0019186289282515645,
      0.021597877144813538,
      -0.020424630492925644,
      0.024551350623369217,
      0.05440531298518181,
      0.022704781964421272,
      -0.02041514590382576,
      -0.014183717779815197,
      -0.06327226012945175,
      0.07885460555553436,
      -0.015222461894154549,
      -0.010542523115873337,
      0.011602351441979408,
      -0.056322772055864334,
      0.034545741975307465,
      -0.04454835131764412,
      0.00753732118755579,
      0.009415295906364918,
      0.03270313888788223,
      -0.05114886164665222,
      0.013526150956749916,
      -0.02262776531279087,
      0.00824509933590889,
      0.016709797084331512,
      -0.0005402254755608737,
      -0.020531751215457916,
      -0.01332444604486227,
      0.013154255226254463,
      -0.023837091401219368,
      0.037491146475076675,
      -0.07459855079650879,
      0.05661224573850632,
      0.03359133005142212,
      0.009465981274843216,
      0.07739398628473282,
      -0.016014153137803078,
      0.011969329789280891,
      0.019793594256043434,
      -0.010029496625065804,
      0.02687680535018444,
      -0.05312633886933327,
      -0.01457070279866457,
      -0.050707023590803146,
      0.04841791093349457,
      -0.021485162898898125,
      0.021341290324926376,
      0.004604801069945097,
      -0.09439001232385635,
      0.041491758078336716,
      -0.04588882997632027,
      0.005337981041520834,
      0.0640665590763092,
      -0.017123142257332802,
      -0.05047900974750519,
      -0.05126684159040451,
      0.005746730603277683,
      0.05163293704390526,
      0.05971093475818634,
      0.0026213042438030243,
      0.018808986991643906,
      -0.06798732280731201,
      -0.02848980575799942,
      -0.014759796671569347,
      0.053687963634729385,
      0.028100566938519478,
      -0.0305415540933609,
      0.07256238907575607,
      -0.03749978542327881,
      -0.055798739194869995,
      -0.08687441051006317,
      -0.005215127021074295,
      0.03128566965460777,
      -0.03440118581056595,
      0.01794997788965702,
      -0.003545036306604743,
      0.037535104900598526,
      0.04209207743406296,
      -0.016556430608034134,
      0.051483605057001114,
      0.02976386435329914,
      -0.06828490644693375,
      -0.012479881756007671,
      -0.0655759871006012,
      -0.01394098624587059,
      -0.03286505118012428,
      0.009990409947931767,
      -0.007826929911971092,
      -0.020986979827284813,
      0.03785080462694168,
      -0.01601831428706646,
      -0.04854949563741684,
      -0.031546782702207565,
      -0.0032679950818419456,
      0.0417322963476181,
      0.07770957052707672,
      -0.057564541697502136,
      -0.05062049999833107,
      0.0008317765314131975,
      0.11288996785879135,
      0.05402746796607971,
      0.02303246036171913,
      0.0030707381665706635,
      -0.08638469874858856,
      0.07992251962423325,
      0.10313627868890762,
      -0.0016379450680688024,
      -0.03925778716802597,
      -0.04536671191453934,
      -0.061607155948877335,
      -0.1187034323811531,
      0.027564235031604767,
      0.049456603825092316,
      0.027535704895853996,
      0.056083809584379196,
      0.032847363501787186,
      -0.004796725697815418,
      0.05136025696992874,
      0.008166882209479809,
      -0.07010050117969513,
      0.020892931148409843,
      0.04794740304350853,
      0.01749647967517376,
      -0.04178464412689209,
      0.1013997346162796,
      -0.048139788210392,
      -0.015328697860240936,
      0.008991788141429424,
      -0.0067376187071204185,
      0.037732578814029694,
      -0.052377887070178986,
      0.012984693981707096,
      0.005046278238296509,
      -0.03403032198548317,
      -0.024120522662997246,
      -0.09238188713788986,
      -0.058083001524209976,
      0.01193879172205925,
      0.01326033566147089,
      0.008705172687768936,
      -0.026656262576580048,
      -0.04386286437511444,
      0.023780865594744682,
      -0.024196850135922432,
      -0.02859368361532688,
      0.0748179629445076,
      -0.09566226601600647,
      0.03302576765418053,
      -0.006725895218551159,
      -0.02144159935414791,
      -0.11741328239440918,
      -0.033199507743120193,
      0.014721422456204891,
      0.08176020532846451,
      -0.06304923444986343,
      0.008996405638754368,
      0.02317449077963829,
      0.03795751929283142,
      0.0011965580051764846,
      -0.02583671174943447,
      -0.037929121404886246,
      0.05910254269838333,
      0.005047329235821962,
      0.0058057308197021484,
      0.035470906645059586,
      -0.013213961385190487,
      -0.014307325705885887,
      -0.03193746134638786,
      0.03004376031458378,
      -0.015182869508862495,
      -0.002502464223653078,
      0.004553779494017363,
      0.08336988091468811,
      -0.10621729493141174,
      -0.01355799101293087,
      -0.00829223170876503,
      -0.05181128904223442,
      0.0015304419212043285,
      -0.004578069318085909,
      0.0010847558733075857,
      0.028406362980604172,
      0.07169326394796371,
      0.06315240263938904,
      0.06559160351753235,
      -0.04792339354753494,
      -0.039122357964515686,
      -0.011474273167550564,
      0.016683204099535942,
      -0.024520110338926315,
      -0.032957810908555984,
      0.050429657101631165,
      -0.001566914259456098
    ],
    [
      0.05842437967658043,
      -0.005346099380403757,
      0.025230087339878082,
      -0.059971243143081665,
      -0.04857182875275612,
      -0.03805215656757355,
      -0.010836259461939335,
      0.007078241091221571,
      -0.006482670083642006,
      0.005878958385437727,
      -0.02786143310368061,
      0.02528378926217556,
      0.028430422767996788,
      -0.002564794849604368,
      0.02157450281083584,
      -0.09500554949045181,
      0.0012585760559886694,
      0.045415397733449936,
      0.07274729758501053,
      -0.015081879682838917,
      0.013008098118007183,
      -0.014872170053422451,
      0.015365404076874256,
      0.0558198019862175,
      -0.06229281798005104,
      -0.02055489830672741,
      -0.0521094836294651,
      0.04651260003447533,
      0.0025093406438827515,
      0.04034008830785751,
      -0.044788509607315063,
      0.06050236523151398,
      -0.030493557453155518,
      -0.01759863644838333,
      -0.060439810156822205,
      -0.07126522064208984,
      0.004170349799096584,
      0.012134400196373463,
      -0.04758983850479126,
      -0.038824412971735,
      0.016903255134820938,
      -0.03896832466125488,
      0.0633377805352211,
      -0.0698980912566185,
      0.036389466375112534,
      -0.026479145511984825,
      -0.02429603971540928,
      -0.0033158089499920607,
      -0.047987110912799835,
      -0.04742196947336197,
      -0.0382811538875103,
      -0.024148080497980118,
      -0.019588299095630646,
      -0.022953305393457413,
      -0.04046124219894409,
      -0.04010362550616264,
      -0.0024822866544127464,
      0.005282513797283173,
      -0.06671270728111267,
      0.046552129089832306,
      0.0021233572624623775,
      0.023450490087270737,
      -0.0450785830616951,
      0.03379235044121742,
      0.04005002602934837,
      -0.07590937614440918,
      0.1333012878894806,
      -0.06954013556241989,
      0.02739136479794979,
      -0.0012390610063448548,
      0.0677637979388237,
      -0.03854735568165779,
      -0.055433761328458786,
      -0.014980754815042019,
      0.08920927345752716,
      0.02665187045931816,
      0.0555465929210186,
      0.016405658796429634,
      0.019587282091379166,
      0.01884782873094082,
      0.009153361432254314,
      -0.0193499606102705,
      0.025639450177550316,
      -0.011302012018859386,
      -0.10776408761739731,
      -0.05460040271282196,
      -0.0705811157822609,
      -0.018995782360434532,
      0.014520606957376003,
      0.0027694825548678637,
      -0.0066617196425795555,
      -0.05750786513090134,
      -0.04171465337276459,
      0.02679724618792534,
      -0.04061942547559738,
      -0.03739364445209503,
      0.032220855355262756,
      -0.06407037377357483,
      0.01826345920562744,
      -0.04532330483198166,
      -0.007270656526088715,
      -0.02055097371339798,
      0.06780494749546051,
      0.029921049252152443,
      0.003964149858802557,
      -0.07478763163089752,
      -0.024591002613306046,
      -0.0013900906778872013,
      -0.05364394932985306,
      0.06915738433599472,
      -0.006326836533844471,
      0.034865569323301315,
      0.019160492345690727,
      -0.03176851570606232,
      -0.02310504950582981,
      -0.005131513345986605,
      0.019764088094234467,
      0.05361531302332878,
      0.025993479415774345,
      -0.015161153860390186,
      -0.021569885313510895,
      -0.05279063805937767,
      0.0898459330201149,
      -0.00665277848020196,
      -0.04336157813668251,
      0.05176239088177681,
      0.012359622865915298,
      0.0014887128490954638,
      0.04360214248299599,
      -0.008156443014740944,
      -0.07728012651205063,
      0.05936484411358833,
      0.007687699981033802,
      -0.06675919890403748,
      0.051853880286216736,
      -0.090137779712677,
      -0.0942261815071106,
      0.006934966892004013,
      -0.08810560405254364,
      0.016422107815742493,
      -0.041877567768096924,
      0.02162436954677105,
      -0.009505503810942173,
      -0.038788456469774246,
      -0.0824393779039383,
      0.017720775678753853,
      0.0709608793258667,
      0.002372133545577526,
      0.021177547052502632,
      -0.0650448426604271,
      -0.024810990318655968,
      0.07289372384548187,
      -0.03780572488903999,
      0.027697814628481865,
      -0.0035798612516373396,
      0.010097747668623924,
      0.026527099311351776,
      -0.02369186095893383,
      -0.005125920753926039,
      0.008198418654501438,
      0.013088494539260864,
      0.04776908829808235,
      -0.030410628765821457,
      0.004059727769345045,
      -0.013970647938549519,
      -0.030740519985556602,
      0.0016252368222922087,
      0.00763343833386898,
      0.0069169821217656136,
      0.039531756192445755,
      0.04509600251913071,
      -0.04649622365832329,
      0.0302833691239357,
      0.008165929466485977,
      -0.033498458564281464,
      -0.017679255455732346,
      -0.03954571858048439,
      0.004686127882450819,
      -0.05849427729845047,
      -0.008304141461849213,
      0.07368329912424088,
      -0.07835172116756439,
      -0.02646627463400364,
      0.00940561480820179,
      0.0006647810223512352,
      -0.007408871781080961,
      0.10899936407804489,
      -0.016540158540010452,
      0.03304845094680786,
      0.005291779991239309,
      0.06019246205687523,
      0.006028585601598024,
      -0.04470762982964516,
      0.11140742897987366,
      -0.011476963758468628,
      0.005151266697794199,
      -0.03160514682531357,
      0.009326057508587837,
      0.024228693917393684,
      0.025778628885746002,
      0.05167558789253235,
      -0.055995356291532516,
      0.07528762519359589,
      0.008376557379961014,
      -0.05896848440170288,
      0.05224481225013733,
      -0.06369752436876297,
      -0.028681349009275436,
      -0.015959065407514572,
      0.018158545717597008,
      0.0034288244787603617,
      0.017499949783086777,
      0.06867074221372604,
      0.07372836768627167,
      -0.053346432745456696,
      -0.06426100432872772,
      -0.07865189015865326,
      -0.09348523616790771,
      0.00724253011867404,
      -0.027451569214463234,
      0.011290512979030609,
      0.05307425186038017,
      0.02654140070080757,
      0.03254687041044235,
      -0.024426087737083435,
      0.04214882478117943,
      0.1167699545621872,
      0.007695983629673719,
      -0.07479193061590195,
      0.04797849804162979,
      -0.061747048050165176,
      0.03681867942214012,
      0.006392274517565966,
      -0.053562063723802567,
      0.06199828162789345,
      0.07242721319198608,
      0.02407454326748848,
      0.0387212298810482,
      -0.04021870344877243,
      -0.05629592016339302,
      -0.029668089002370834,
      -0.017190782353281975,
      0.08682360500097275,
      0.01748722605407238,
      -0.03920359909534454,
      0.05814606323838234,
      -0.008967230096459389,
      -0.041497476398944855,
      0.031016312539577484,
      0.002687198342755437,
      -0.027454504743218422,
      -0.030302375555038452,
      -0.025992456823587418,
      0.030515756458044052,
      0.07423263788223267,
      0.027818594127893448,
      0.03869577497243881,
      -0.048728421330451965,
      0.04509837552905083,
      0.06631886214017868,
      -0.05985519662499428,
      0.06022336706519127,
      0.012259222567081451,
      -0.03289702162146568,
      0.06667568534612656,
      0.07897268980741501,
      0.02492590807378292,
      0.023353368043899536,
      -0.018487699329853058,
      -0.029909586533904076,
      -0.02965736761689186,
      -0.046569596976041794,
      -0.024699518457055092,
      -0.06766985356807709,
      0.08826474845409393,
      -0.01693703606724739,
      0.13375869393348694,
      0.0021058686543256044,
      0.07952892780303955,
      -0.0023700143210589886,
      -0.05958515778183937,
      0.038321901112794876,
      -0.0469808392226696,
      -0.014651129953563213,
      0.014258637093007565,
      -0.06448981910943985,
      -0.008128178305923939,
      -0.023431824520230293,
      0.02349826693534851,
      0.04370966926217079,
      0.047377921640872955,
      -0.09001285582780838,
      -0.01471634116023779,
      -0.055184222757816315,
      -0.0615963339805603,
      0.03268997743725777,
      -0.05177118629217148,
      0.045713409781455994,
      -0.06386174261569977,
      0.0014120592968538404,
      -0.014037992805242538,
      0.041738759726285934,
      0.050449781119823456,
      -0.061547938734292984,
      0.027165720239281654,
      0.11887545883655548,
      0.015652934089303017,
      0.006104034371674061,
      0.004723904188722372,
      0.005219538230448961,
      -0.019310269504785538,
      -0.01248979102820158,
      -0.09045672416687012,
      -0.035863764584064484,
      0.005144490860402584,
      -0.0016829840606078506,
      0.07011554390192032,
      -0.06424015015363693,
      -0.008015218190848827,
      -0.00658218702301383,
      0.01172985602170229,
      0.009512477554380894,
      0.015698419883847237,
      0.07674260437488556,
      0.030414532870054245,
      -0.03289458528161049,
      0.03174387663602829,
      -0.027177661657333374,
      -0.08348361402750015,
      -0.002072062576189637,
      0.030430087819695473,
      -0.07181340456008911,
      0.030910760164260864,
      -0.02535848878324032,
      -0.00038133125053718686,
      -0.017952820286154747,
      0.010393247939646244,
      -0.03781586140394211,
      -0.051037322729825974,
      -0.02651267871260643,
      -0.016142791137099266,
      0.08138763159513474,
      0.016031913459300995,
      0.042546242475509644,
      -0.010627283714711666,
      0.01247750036418438,
      0.019629880785942078,
      -0.07909882813692093,
      -0.028218761086463928,
      0.06279657781124115,
      -0.0431334413588047,
      0.008159185759723186,
      -0.01551993191242218,
      -0.04583384841680527,
      0.024566583335399628,
      -0.0063730766996741295,
      0.013684806413948536,
      0.057700857520103455,
      -0.03252909705042839,
      -0.03192909434437752,
      0.014774858020246029,
      0.00633269315585494,
      -0.06499814987182617,
      -0.03617439046502113,
      0.008865182287991047,
      -0.058451443910598755,
      0.006455451250076294,
      0.04019066318869591,
      0.03312735632061958,
      0.04471680521965027,
      0.04819079488515854,
      0.05778127163648605,
      -0.05010445415973663,
      -0.04963330551981926,
      -0.013398733921349049,
      0.027804305776953697,
      0.0023456793278455734,
      0.07902789115905762,
      -0.06150815635919571,
      -0.09490351378917694,
      -0.01414160430431366,
      -0.07832778245210648,
      -0.023312808945775032,
      -0.0015767986187711358,
      0.015405474230647087,
      -0.030924025923013687,
      0.06576494127511978,
      -0.004178411792963743,
      0.016776325181126595,
      -0.09339829534292221,
      -0.0009384087170474231,
      -0.005378688685595989,
      -0.01736297272145748,
      -0.033903319388628006,
      0.023448316380381584,
      -0.0604841373860836,
      -0.017221515998244286,
      0.05460434406995773,
      0.005611950997263193,
      -0.06817874312400818,
      -0.017664888873696327,
      -0.05617350712418556,
      -0.03450436890125275,
      0.0670648142695427,
      0.07563827186822891,
      0.006529832724481821,
      0.02162744477391243,
      -0.04761682078242302,
      0.05111493542790413,
      0.04205402731895447,
      -0.019509509205818176,
      0.08131678402423859,
      -0.02835851162672043,
      -0.04242994636297226,
      -0.022993791848421097,
      0.0660436600446701,
      -0.034674692898988724,
      0.031603164970874786,
      -0.019108381122350693,
      -0.013642306439578533,
      -0.01954818144440651,
      -0.05645732581615448,
      -0.014328571036458015,
      0.062266185879707336,
      0.04458366706967354,
      0.016493244096636772,
      -0.06640584766864777,
      -0.015356611460447311,
      0.0400129072368145,
      -0.01064388733357191,
      0.0921163558959961,
      0.037692807614803314,
      0.031064145267009735,
      -0.027742454782128334,
      0.08612439781427383,
      -0.0460575632750988,
      -0.0811188668012619,
      0.04805421456694603,
      0.0347200483083725,
      -0.0768342837691307,
      0.08552161604166031,
      -0.038179486989974976,
      -0.025579754263162613,
      0.0007747202180325985,
      0.01270960085093975,
      -0.044181205332279205,
      -0.12272606045007706,
      -0.028046004474163055,
      0.007725781295448542,
      0.003034056629985571,
      -0.0016878932947292924,
      0.048540495336055756,
      0.05503644794225693,
      -0.004693431779742241,
      -0.05459975078701973,
      0.004622915294021368,
      -0.0809847041964531,
      -0.021672863513231277,
      -0.006972263567149639,
      0.04167553409934044,
      0.0005941464914940298,
      -0.0005542841390706599,
      0.07872045785188675,
      0.0004046183021273464,
      0.0683499276638031,
      0.03794851526618004,
      -0.02587050013244152,
      -0.02679082378745079,
      -0.029990697279572487,
      0.02810257486999035,
      0.0037408466450870037,
      0.03203712776303291,
      0.03913930431008339,
      0.0015207809628918767,
      0.034690212458372116,
      0.0021009219344705343,
      -0.034975890070199966,
      -0.03547094762325287,
      0.016873490065336227,
      0.03179464861750603,
      0.03335762023925781,
      0.048437390476465225,
      -0.014215122908353806,
      0.013871164992451668,
      -0.027387909591197968,
      -0.07921887934207916,
      -0.0020565101876854897,
      0.0149508873000741,
      0.018294785171747208,
      -0.006288317963480949,
      -0.030718088150024414,
      0.02286061830818653,
      0.06085940822958946,
      -0.020351525396108627,
      -0.04584834352135658,
      0.09916287660598755,
      -0.040952324867248535,
      -0.0415826253592968,
      -0.00464180251583457,
      -0.051445335149765015,
      0.06181344762444496,
      0.01904810406267643,
      -0.02805793657898903
    ],
    [
      0.03602968528866768,
      0.005164430011063814,
      0.00930481031537056,
      -0.022862007841467857,
      -0.05372884124517441,
      -0.1420023888349533,
      0.002422782126814127,
      -0.03520195558667183,
      -0.006838234141469002,
      -0.015414129942655563,
      0.05276346951723099,
      0.013703951612114906,
      0.01703360490500927,
      0.008142080157995224,
      0.010983850806951523,
      -0.012053011916577816,
      -0.09952103346586227,
      -0.07181423902511597,
      0.043342847377061844,
      0.0605425201356411,
      0.020532533526420593,
      0.028441300615668297,
      -0.08880151063203812,
      -0.04073464125394821,
      0.008004001341760159,
      0.017658445984125137,
      0.08646482974290848,
      0.02508128061890602,
      0.005493156611919403,
      0.0030635101720690727,
      0.0027905991300940514,
      -0.04564449191093445,
      -0.0407995730638504,
      -0.021282264962792397,
      0.007985933683812618,
      -0.016696004197001457,
      0.053904276341199875,
      -0.007423175498843193,
      0.02066582813858986,
      -0.07651395350694656,
      7.535705663030967e-05,
      -0.010323530994355679,
      0.013482154347002506,
      -0.002094933995977044,
      0.06078876182436943,
      0.0016191016184166074,
      0.0900627076625824,
      -0.001579795847646892,
      -0.004316471982747316,
      0.006906943861395121,
      0.044713351875543594,
      -0.0009013245580717921,
      0.020744631066918373,
      0.004013905767351389,
      0.002276873216032982,
      0.003752808552235365,
      -0.03156207501888275,
      0.03641152381896973,
      -0.043104659765958786,
      0.02198203280568123,
      -0.10293076932430267,
      0.00680682435631752,
      0.055119793862104416,
      -0.08619773387908936,
      0.022507596760988235,
      0.05438205227255821,
      -0.006184146273881197,
      -0.0010348804062232375,
      0.03586786240339279,
      0.00518087949603796,
      -0.03600940853357315,
      0.03770527243614197,
      0.11744888126850128,
      -0.03894862160086632,
      -0.018907621502876282,
      -0.05662830173969269,
      0.043236542493104935,
      -0.022891823202371597,
      0.061376094818115234,
      -0.01947176456451416,
      0.045629434287548065,
      0.04416387528181076,
      0.03443484008312225,
      0.029866360127925873,
      -0.0004072489682585001,
      -0.040790244936943054,
      -0.12209679186344147,
      -0.07973107695579529,
      0.03322581946849823,
      -0.03625476732850075,
      0.036357469856739044,
      -0.06967076659202576,
      -0.035530030727386475,
      -0.03822089731693268,
      0.011981356889009476,
      0.07471141219139099,
      0.03507791459560394,
      -0.03320186957716942,
      0.03123885579407215,
      0.0935756117105484,
      0.05978338420391083,
      -0.040884047746658325,
      -0.015033076517283916,
      0.05285058170557022,
      0.03485894203186035,
      -0.01063111238181591,
      0.03675767779350281,
      0.07965371012687683,
      0.03125275671482086,
      -0.054556529968976974,
      -0.03183041140437126,
      -0.04450327530503273,
      0.061872448772192,
      0.09322527050971985,
      -0.04810178279876709,
      -0.047481220215559006,
      -0.013259959407150745,
      -0.00877576507627964,
      0.02292614057660103,
      -0.06603313237428665,
      -0.04856066033244133,
      -0.0769614726305008,
      0.03413495048880577,
      -0.020765380933880806,
      -0.018627246841788292,
      -0.022872278466820717,
      0.06361328810453415,
      0.07463765889406204,
      -0.021754687651991844,
      -0.018968811258673668,
      0.084309421479702,
      -0.033780958503484726,
      -0.00036523054586723447,
      -0.0012337060179561377,
      -0.04445221275091171,
      -0.09585583209991455,
      0.10226799547672272,
      0.020769290626049042,
      -0.024161480367183685,
      0.06316196918487549,
      0.00736784003674984,
      0.06926559656858444,
      -0.026810375973582268,
      -0.027476176619529724,
      0.04100163280963898,
      0.055502429604530334,
      0.0360085666179657,
      0.03453897684812546,
      -0.008341111242771149,
      -0.029300633817911148,
      0.05607311800122261,
      0.014881815761327744,
      0.03122212365269661,
      0.018526341766119003,
      0.00453378539532423,
      -0.022853940725326538,
      0.10533639043569565,
      -0.023181606084108353,
      0.005712803453207016,
      0.020187418907880783,
      -0.09121395647525787,
      0.03696837276220322,
      -0.0339873805642128,
      -0.022707903757691383,
      -0.013441677205264568,
      -0.03711896389722824,
      -0.06610841304063797,
      0.006745107471942902,
      0.015196947380900383,
      -0.023025088012218475,
      0.06252644211053848,
      0.0411081425845623,
      -0.01986788399517536,
      -0.014679607935249805,
      0.005782759748399258,
      -0.026847420260310173,
      -0.05949455499649048,
      0.01072540134191513,
      -0.026962902396917343,
      0.07248083502054214,
      0.0008369680726900697,
      0.08812049776315689,
      -0.06849552690982819,
      0.04488246142864227,
      -0.04695796221494675,
      -0.01850499026477337,
      0.06291483342647552,
      0.00793098658323288,
      0.00893913209438324,
      0.05514448508620262,
      0.0014822439989075065,
      0.03551352769136429,
      -0.04697740077972412,
      -0.023106621578335762,
      -0.01594025269150734,
      0.011625517159700394,
      -0.021155280992388725,
      -0.00427543418481946,
      0.10680518299341202,
      0.02835868112742901,
      -0.01118599995970726,
      0.014150387607514858,
      -0.03966674581170082,
      -0.026148438453674316,
      -0.06073616072535515,
      -0.020894214510917664,
      -0.031859081238508224,
      0.022261155769228935,
      0.048687923699617386,
      0.04286991432309151,
      0.06833750754594803,
      -0.02719898521900177,
      0.06335467845201492,
      0.08062040060758591,
      0.07194435596466064,
      0.01382999587804079,
      -0.07424700260162354,
      0.061507537961006165,
      0.0653342753648758,
      -0.032271094620227814,
      0.07861443608999252,
      -0.029369346797466278,
      -0.06925390660762787,
      -0.06328641623258591,
      0.019434163346886635,
      0.01696246676146984,
      0.022485438734292984,
      -0.00441987719386816,
      0.08595582842826843,
      0.05587100610136986,
      0.006652296520769596,
      0.03729304298758507,
      -0.01654362678527832,
      -0.05060768872499466,
      0.07644014060497284,
      -0.038612306118011475,
      -0.04380078241229057,
      0.03425685316324234,
      0.026965321972966194,
      -0.011152982711791992,
      -0.009150220081210136,
      -0.01554372813552618,
      -0.002486147917807102,
      0.07881958782672882,
      0.01783010922372341,
      0.015931395813822746,
      -0.002133506117388606,
      -0.04269610717892647,
      -0.022362379357218742,
      0.006685391068458557,
      0.02896275743842125,
      0.05408962443470955,
      0.0792008712887764,
      -0.014288623817265034,
      -0.01744144596159458,
      0.061355385929346085,
      0.02477729134261608,
      -0.01049440260976553,
      0.04404634237289429,
      0.03248419240117073,
      -0.007378464564681053,
      -0.023327747359871864,
      -0.042896971106529236,
      0.04753202572464943,
      -0.02309110388159752,
      0.0018038995331153274,
      0.012116914615035057,
      0.0010615887586027384,
      0.02313036471605301,
      -0.006963844411075115,
      0.005962953437119722,
      -0.02790375053882599,
      0.02373174950480461,
      -0.024368856102228165,
      0.020170487463474274,
      0.013330244459211826,
      0.02023916505277157,
      -0.04502326622605324,
      0.03175615891814232,
      0.0036832145415246487,
      -0.015476002357900143,
      -0.0442342609167099,
      -0.05483391880989075,
      0.005198516882956028,
      0.07929858565330505,
      0.02823258563876152,
      0.009144539944827557,
      -0.015013855881989002,
      0.013551794923841953,
      -0.014684593304991722,
      0.049631357192993164,
      -0.02666659466922283,
      0.029909713193774223,
      -0.004126187413930893,
      -0.05083851516246796,
      -0.004118726123124361,
      0.023170700296759605,
      -0.04699418321251869,
      0.021108172833919525,
      -0.072842538356781,
      -0.10358928143978119,
      -0.05114217847585678,
      0.051131103187799454,
      -0.11122094094753265,
      0.0565132275223732,
      0.003698731306940317,
      -0.0035376811865717173,
      0.02199755236506462,
      -0.08227180689573288,
      0.03609272837638855,
      -0.009764661081135273,
      0.06232507899403572,
      0.06199046969413757,
      0.049826983362436295,
      -0.04152281582355499,
      0.021660342812538147,
      -0.025938717648386955,
      -0.042309898883104324,
      0.01711488515138626,
      -0.053806040436029434,
      -0.05224746838212013,
      0.0007749140495434403,
      -0.027984412387013435,
      0.05965641140937805,
      -0.036036550998687744,
      0.0396985188126564,
      0.1336030215024948,
      -0.02485162951052189,
      -0.02304701693356037,
      0.0802418440580368,
      0.012549737468361855,
      0.07023217529058456,
      0.03779144957661629,
      -0.03917119279503822,
      0.006222332362085581,
      0.06612291932106018,
      -0.006031835917383432,
      0.001465217093937099,
      0.02316964790225029,
      0.014329539611935616,
      -0.031086571514606476,
      -0.06771258264780045,
      -0.014035305008292198,
      -0.06592027097940445,
      -0.011251228861510754,
      -0.010109219700098038,
      -0.0318002887070179,
      -0.0760096088051796,
      0.013597548007965088,
      0.03563826531171799,
      -0.006317771505564451,
      0.015876872465014458,
      0.03341188654303551,
      -0.05543395131826401,
      -0.032817207276821136,
      -0.031368374824523926,
      -0.0527460053563118,
      0.0147537337616086,
      0.05536840111017227,
      0.07320834696292877,
      -0.02255292795598507,
      -0.05808473750948906,
      0.012627065181732178,
      0.007371898740530014,
      -0.03299526870250702,
      -0.05803519859910011,
      0.01299037504941225,
      -0.026070011779665947,
      -0.03344274312257767,
      0.013589448295533657,
      -0.009451250545680523,
      -0.04435982182621956,
      -0.06825427711009979,
      0.06854692846536636,
      0.04438894987106323,
      0.06624927371740341,
      0.04744783788919449,
      0.030892251059412956,
      0.11041448265314102,
      -0.013273612596094608,
      0.035895079374313354,
      -0.003296735929325223,
      0.03681580722332001,
      0.04739779606461525,
      -0.01246105320751667,
      0.04605717957019806,
      0.0032623016741126776,
      0.03810662031173706,
      -0.04802728071808815,
      -0.0264995489269495,
      0.12759685516357422,
      0.1078253835439682,
      0.0010548029094934464,
      -0.010972809977829456,
      -0.041315291076898575,
      -0.023342519998550415,
      -0.01572662591934204,
      -0.05659186467528343,
      -0.056575626134872437,
      -0.010730831883847713,
      0.08227622509002686,
      -0.03409605473279953,
      -0.006088679190725088,
      -0.03943851217627525,
      -0.004843746777623892,
      0.02678218111395836,
      0.02824447490274906,
      -0.02757045067846775,
      -0.03910616412758827,
      -0.026046348735690117,
      0.07706951349973679,
      0.0675588995218277,
      0.060292214155197144,
      -0.018834948539733887,
      0.017609557136893272,
      0.0700438842177391,
      -0.0001315373374382034,
      0.04879239946603775,
      -0.009732603095471859,
      -0.0680190771818161,
      -0.05056791380047798,
      -0.04130508005619049,
      -0.041559260338544846,
      -0.019908364862203598,
      0.0049531059339642525,
      0.03079581819474697,
      -0.009939487092196941,
      -0.06784599274396896,
      0.025134265422821045,
      0.011798582039773464,
      0.04105134308338165,
      0.02032533474266529,
      0.023225121200084686,
      -0.03577021509408951,
      -0.005153760779649019,
      0.019082793965935707,
      0.0613117590546608,
      0.014359132386744022,
      -0.0034585895482450724,
      0.08343106508255005,
      0.023316718637943268,
      0.09658325463533401,
      -0.031187521293759346,
      0.017845315858721733,
      0.08317747712135315,
      -0.028921518474817276,
      -0.03078237920999527,
      0.04529515281319618,
      -0.06448065489530563,
      0.035724759101867676,
      -0.02677769772708416,
      0.02777842991054058,
      0.045084692537784576,
      0.019786447286605835,
      -0.08709772676229477,
      0.0035404020454734564,
      -0.06643165647983551,
      -0.015146982856094837,
      -0.02182178385555744,
      0.008082147687673569,
      -0.053794607520103455,
      -0.08225544542074203,
      -0.02593345008790493,
      0.02425972744822502,
      0.013333178125321865,
      0.04873785376548767,
      0.02643168717622757,
      -0.05337563902139664,
      0.02330072782933712,
      -0.03284620866179466,
      -0.0639910101890564,
      -0.008566487580537796,
      0.1146390438079834,
      0.01936190202832222,
      -0.024193979799747467,
      0.024095499888062477,
      0.016451947391033173,
      -0.008875923231244087,
      -0.03559340536594391,
      0.04503575339913368,
      0.016553884372115135,
      0.0776713639497757,
      0.028473982587456703,
      0.011529947631061077,
      0.03802650049328804,
      -0.002666136948391795,
      -0.022669294849038124,
      -0.06551796197891235,
      -0.030584709718823433,
      0.06561270356178284,
      -0.025005720555782318,
      0.0768972784280777,
      0.07047855854034424,
      -0.004587399773299694,
      0.032658446580171585,
      0.024634812027215958,
      0.050043802708387375,
      -0.017272213473916054,
      -0.037915896624326706,
      -0.030214844271540642,
      0.04165612906217575,
      -0.028162823989987373,
      0.013014569878578186
    ],
    [
      0.009324097074568272,
      -0.08545852452516556,
      -0.09271454811096191,
      0.009264769963920116,
      -0.040693655610084534,
      0.0238859374076128,
      -0.06748978793621063,
      0.04628055542707443,
      -0.058755505830049515,
      -0.05864854156970978,
      0.005995860788971186,
      -0.042357802391052246,
      0.058999691158533096,
      -0.05861745402216911,
      0.009469530545175076,
      -0.04646212235093117,
      0.05595921725034714,
      0.015409370884299278,
      0.041428644210100174,
      0.11033762246370316,
      -0.008263050578534603,
      -0.037501171231269836,
      0.0640127956867218,
      0.09164892882108688,
      0.0655398890376091,
      -0.05273507535457611,
      0.03962522745132446,
      -0.001031826832331717,
      0.05857020616531372,
      0.023161938413977623,
      -0.01290062628686428,
      -0.020000489428639412,
      -0.10952530056238174,
      -0.004747721832245588,
      0.03515293076634407,
      -0.006661586929112673,
      -0.02167397364974022,
      0.03436577692627907,
      -0.026172194629907608,
      0.050486285239458084,
      -0.04351060092449188,
      -0.0003388784534763545,
      0.10997875034809113,
      -0.034418851137161255,
      0.021097945049405098,
      -0.04479263722896576,
      0.015284186229109764,
      -0.010258950293064117,
      0.020323840901255608,
      0.009432505816221237,
      -0.02685605362057686,
      -0.04212874919176102,
      0.07124324887990952,
      -0.007358027622103691,
      0.0021507213823497295,
      -0.06946863979101181,
      0.005897981580346823,
      -0.035461630672216415,
      0.10770361125469208,
      0.034443896263837814,
      0.037518687546253204,
      0.019303539767861366,
      0.02194712869822979,
      -0.019331451505422592,
      0.026211930438876152,
      -0.13774780929088593,
      -0.0036080696154385805,
      -0.025062954053282738,
      -0.0536397285759449,
      0.06873344630002975,
      0.033160120248794556,
      -0.033025845885276794,
      -0.08954072743654251,
      -0.00373473740182817,
      0.05757571756839752,
      -0.0038919146172702312,
      0.016813237220048904,
      0.086784727871418,
      0.015044540166854858,
      0.021954184398055077,
      0.015947455540299416,
      -0.01412846427410841,
      0.04136676341295242,
      -0.03671960532665253,
      0.017730938270688057,
      0.006702534854412079,
      0.0784662738442421,
      0.06901818513870239,
      0.038355715572834015,
      0.001418056432157755,
      -0.06353133171796799,
      0.051849815994501114,
      0.03142588213086128,
      -0.12496933341026306,
      -0.021363934502005577,
      -0.003019993891939521,
      0.08330750465393066,
      0.03299731761217117,
      -0.01986812800168991,
      -0.017267584800720215,
      0.07156972587108612,
      -0.036912307143211365,
      0.04058440029621124,
      0.008924337103962898,
      -0.021606333553791046,
      0.0036144640762358904,
      -0.06227662414312363,
      -0.029856950044631958,
      0.03905582055449486,
      0.022040031850337982,
      0.03223654627799988,
      -0.01995423436164856,
      0.0348883792757988,
      -0.019285989925265312,
      0.08368422836065292,
      -0.036260854452848434,
      0.06928694248199463,
      0.052257634699344635,
      -0.03148125112056732,
      -0.0035259651485830545,
      -0.021358180791139603,
      -0.03459915146231651,
      -0.0036127958446741104,
      0.005920744966715574,
      -0.09316716343164444,
      -0.0271281935274601,
      -0.1066470742225647,
      0.023152261972427368,
      -0.03087218664586544,
      0.04665236920118332,
      -0.006205701269209385,
      0.04258177429437637,
      0.04526617005467415,
      -0.02517375349998474,
      0.04893723875284195,
      -0.022104909643530846,
      -0.001295537338592112,
      0.015189861878752708,
      0.05101504921913147,
      -0.024026034399867058,
      0.021319197490811348,
      0.048013582825660706,
      -0.022995058447122574,
      -0.06500858068466187,
      -0.001459757681004703,
      -0.010196601040661335,
      -0.0325700044631958,
      0.008766810409724712,
      -0.006166547071188688,
      -0.0537262037396431,
      -0.0003310364263597876,
      -0.007629194296896458,
      -0.039312392473220825,
      0.03891695663332939,
      0.016943827271461487,
      0.05276317521929741,
      0.07709461450576782,
      0.023792356252670288,
      -0.021591205149888992,
      0.009771891869604588,
      0.004472130443900824,
      0.0223664753139019,
      -0.046000078320503235,
      0.04718107730150223,
      -0.006509185303002596,
      0.008441410027444363,
      0.004017052240669727,
      0.035312309861183167,
      0.08691960573196411,
      0.02300843596458435,
      0.08098851144313812,
      -0.008735897950828075,
      0.08712442964315414,
      0.025833692401647568,
      -0.05393453687429428,
      0.05066594481468201,
      0.043556999415159225,
      0.028940297663211823,
      0.008604838512837887,
      0.06512579321861267,
      0.01204393059015274,
      0.09859427064657211,
      -0.012738910503685474,
      -0.0002537771069910377,
      0.03145621716976166,
      0.01290380023419857,
      -0.027950875461101532,
      -0.00814866740256548,
      0.0692470446228981,
      -0.06596468389034271,
      -0.018210476264357567,
      -0.008370128460228443,
      -0.029788412153720856,
      0.027693146839737892,
      -0.076751209795475,
      0.0028884834609925747,
      -0.06677568703889847,
      0.02092793397605419,
      0.07200869917869568,
      -0.03391977772116661,
      0.007747496943920851,
      -0.0010303381131961942,
      -0.028886424377560616,
      0.010333874262869358,
      0.04144541174173355,
      -0.005746595561504364,
      -0.02558758482336998,
      0.019352545961737633,
      0.031402695924043655,
      0.0061332955956459045,
      -0.0053231618367135525,
      0.04788563773036003,
      0.16325195133686066,
      0.05631295219063759,
      0.041163671761751175,
      -0.014244204387068748,
      0.025867167860269547,
      0.02301180176436901,
      0.005090663209557533,
      -0.02376297302544117,
      0.06465727090835571,
      0.018183136358857155,
      0.011988257989287376,
      0.001806336222216487,
      0.023252571001648903,
      -0.06053870543837547,
      0.04833468794822693,
      0.0059516397304832935,
      0.02546369843184948,
      -0.05367501825094223,
      -0.004769638646394014,
      0.012505465187132359,
      -0.009625476785004139,
      -0.04603954777121544,
      -0.062435366213321686,
      0.065619096159935,
      0.026437662541866302,
      0.048855382949113846,
      -0.013782854191958904,
      -0.05447133257985115,
      -0.019638897851109505,
      -0.07685456424951553,
      0.07209710031747818,
      0.0535682775080204,
      -0.04208118095993996,
      0.03711263835430145,
      0.01028203871101141,
      -0.054976314306259155,
      0.016215408220887184,
      -0.0694742351770401,
      -0.019951481372117996,
      0.10218237340450287,
      0.006264817900955677,
      -0.02569110505282879,
      -0.03365638107061386,
      -0.03783279284834862,
      0.015423561446368694,
      -0.06354399770498276,
      0.04559353366494179,
      -0.014893678016960621,
      0.027662862092256546,
      -0.04634111747145653,
      -0.045793317258358,
      0.11719156056642532,
      0.07958891242742538,
      0.055171769112348557,
      0.021642429754137993,
      -0.0013934294693171978,
      -0.041306134313344955,
      -0.02307722344994545,
      0.022910788655281067,
      0.08576029539108276,
      0.02912639081478119,
      -0.03429552912712097,
      0.0014505141880363226,
      0.009281324222683907,
      0.024975642561912537,
      0.030177436769008636,
      0.010563807561993599,
      0.003274111542850733,
      -0.08863811194896698,
      -0.05226658284664154,
      0.054258543998003006,
      0.01792580634355545,
      0.02960968390107155,
      0.010335410945117474,
      -0.012998686172068119,
      0.043787918984889984,
      -0.013595246709883213,
      0.03737049549818039,
      -0.0001074599422281608,
      0.0020029107108712196,
      0.07518740743398666,
      -0.00522927101701498,
      -0.034649018198251724,
      -0.004335085395723581,
      -0.11102050542831421,
      0.07380609959363937,
      0.0022488264366984367,
      -0.027050573378801346,
      -0.06649326533079147,
      -0.014913496561348438,
      0.04756584018468857,
      -0.020575275644659996,
      -0.001083147944882512,
      -0.08998221158981323,
      -0.001677447697147727,
      0.026518456637859344,
      -0.11559096723794937,
      0.01886315830051899,
      -0.0012290587183088064,
      0.04202819615602493,
      -0.10274800658226013,
      -0.06854360550642014,
      -0.0236483346670866,
      0.0014958292013034225,
      0.08125279098749161,
      -0.03567451611161232,
      -0.00941120833158493,
      0.031100569292902946,
      0.004676492419093847,
      0.06085807830095291,
      0.01870194636285305,
      0.06418201327323914,
      -0.03749172389507294,
      0.023216325789690018,
      -0.003036665031686425,
      -0.06284091621637344,
      0.07400497049093246,
      0.06672796607017517,
      -0.01403621956706047,
      -0.08084885776042938,
      -0.003334506182000041,
      0.015721619129180908,
      0.0035820251796394587,
      -0.0799001157283783,
      0.020789409056305885,
      -0.021037578582763672,
      -0.013250338844954967,
      -0.006491930224001408,
      0.07109800726175308,
      0.0008765750098973513,
      -0.04463181272149086,
      0.005108477082103491,
      -0.009669340215623379,
      -0.03574259579181671,
      0.0840989276766777,
      0.002951329806819558,
      0.003855356015264988,
      0.050539035350084305,
      0.0033845764119178057,
      0.037071287631988525,
      -0.06590095907449722,
      -0.002041570609435439,
      -0.036453332751989365,
      0.021249232813715935,
      -0.10842236876487732,
      0.047971464693546295,
      -0.03591322898864746,
      -0.02092302218079567,
      0.06063922122120857,
      0.04617195576429367,
      0.028460560366511345,
      0.04071132466197014,
      0.051553577184677124,
      0.04945852607488632,
      -0.05951904505491257,
      0.024213477969169617,
      0.05952685698866844,
      -0.019994055852293968,
      0.04007410258054733,
      -0.00841451808810234,
      0.04369854927062988,
      0.04876691475510597,
      -0.026138676330447197,
      -0.01953008398413658,
      -0.12328094244003296,
      -0.02137092500925064,
      0.04329545423388481,
      -0.0054648094810545444,
      0.002896480495110154,
      -0.01322141382843256,
      -0.011203465051949024,
      -0.02616473101079464,
      -0.020236195996403694,
      0.030016139149665833,
      -0.025767499580979347,
      0.0491873063147068,
      0.05414483696222305,
      0.015581640414893627,
      -0.055826347321271896,
      0.06551344692707062,
      0.002338036894798279,
      -0.08318190276622772,
      0.03797248750925064,
      -0.09731677919626236,
      0.03639312461018562,
      0.027682039886713028,
      0.04487884044647217,
      -0.07004569470882416,
      0.027074722573161125,
      -0.045145925134420395,
      0.06950042396783829,
      0.0029163456056267023,
      0.033240046352148056,
      -0.051001694053411484,
      0.003001152304932475,
      0.019904207438230515,
      0.063691645860672,
      0.03269745409488678,
      -0.016378870233893394,
      0.025731995701789856,
      0.0003535560972522944,
      0.08592819422483444,
      -0.05582085996866226,
      0.03289294242858887,
      -0.08240687847137451,
      0.06060498207807541,
      0.020302994176745415,
      -0.07166833430528641,
      -0.06630591303110123,
      -0.040456388145685196,
      0.08760101348161697,
      0.008851169608533382,
      -0.004825549200177193,
      0.07978887856006622,
      -0.016752716153860092,
      -0.035347189754247665,
      -0.004441993311047554,
      -0.04503916576504707,
      -0.020480282604694366,
      -0.06025305390357971,
      0.008652465417981148,
      -0.022303717210888863,
      -0.0019991020672023296,
      -0.07226402312517166,
      -0.020387647673487663,
      -0.02217327058315277,
      -0.00024313668836839497,
      -0.008432412520051003,
      -0.031357090920209885,
      -0.0017790872370824218,
      0.07127340137958527,
      0.0069289845414459705,
      -0.007673837244510651,
      -0.008607755415141582,
      -0.049180641770362854,
      -0.03280270844697952,
      -0.06882660835981369,
      0.06319288909435272,
      0.0466400682926178,
      -0.05155636742711067,
      0.0006193714798428118,
      -0.10091336071491241,
      -0.02288118004798889,
      0.04433557763695717,
      -0.018952343612909317,
      0.07734833657741547,
      0.028843682259321213,
      -0.015246294438838959,
      -0.031520769000053406,
      0.03623080626130104,
      -0.052900929003953934,
      -0.0019036676967516541,
      -0.005544639192521572,
      -0.03467836230993271,
      -0.03170139342546463,
      -0.0041388156823813915,
      -0.042888093739748,
      -0.006627485156059265,
      0.01812232844531536,
      -0.030453458428382874,
      0.04309782758355141,
      0.08908873796463013,
      0.06539441645145416,
      0.005987996701151133,
      0.06201871857047081,
      -0.0018131246324628592,
      0.0006177586619742215,
      -0.03959238901734352,
      -0.011193478479981422,
      -0.0784747451543808,
      -0.05051301419734955,
      0.09153932332992554,
      0.04547785222530365,
      0.03851892799139023,
      0.041094180196523666,
      0.00214896397665143,
      0.009057765826582909,
      0.007794963661581278,
      0.0040069627575576305,
      -0.07423579692840576,
      0.04205077886581421,
      0.0015676803886890411,
      -0.04317177087068558,
      0.022028332576155663,
      -0.0066598993726074696,
      -0.017517395317554474,
      -0.053209953010082245,
      0.012682899832725525,
      0.09927080571651459,
      0.04799125716090202,
      -0.04824814945459366
    ],
    [
      -0.04518871381878853,
      -0.011205548420548439,
      0.05185239017009735,
      0.032455604523420334,
      0.00932521652430296,
      -0.046551283448934555,
      0.04423324763774872,
      0.0073283975943923,
      0.07614950835704803,
      -0.019487770274281502,
      0.012396130710840225,
      0.017828015610575676,
      -0.018332745879888535,
      0.00856986828148365,
      0.11668913811445236,
      0.02526017650961876,
      0.06376585364341736,
      0.03115726448595524,
      -0.011910551227629185,
      0.047087833285331726,
      0.07562319934368134,
      0.020116614177823067,
      -0.007571119349449873,
      0.06884390860795975,
      -0.012060962617397308,
      -0.02717302367091179,
      0.04550938680768013,
      0.05930148810148239,
      -0.058571312576532364,
      0.05322074517607689,
      -0.02205042354762554,
      0.02521522529423237,
      0.058555666357278824,
      -0.01410596538335085,
      0.01712227798998356,
      -0.03275793045759201,
      0.02826901711523533,
      -0.00752311060205102,
      -0.05792625620961189,
      0.059616848826408386,
      0.02812189608812332,
      0.03235653042793274,
      -0.017029764130711555,
      -0.027193836867809296,
      0.10120611637830734,
      -0.023719538003206253,
      0.039399635046720505,
      -0.016654588282108307,
      0.02382677234709263,
      -0.00997297652065754,
      -0.051118239760398865,
      -0.04141419008374214,
      0.021493811160326004,
      -0.08390939235687256,
      0.04535027965903282,
      0.004644996952265501,
      0.03985532745718956,
      -0.02037401869893074,
      0.04627372696995735,
      0.09970282018184662,
      -0.028469638898968697,
      -0.003835729556158185,
      -0.04322198033332825,
      -0.01269770972430706,
      -0.005627056118100882,
      0.02310897968709469,
      0.06885387748479843,
      -0.06298674643039703,
      -0.03244590014219284,
      0.0677889809012413,
      -0.04979346692562103,
      0.10622577369213104,
      -0.029599491506814957,
      0.011161581613123417,
      0.019580181688070297,
      0.07543961703777313,
      -1.2584717296704184e-05,
      -0.030801912769675255,
      -0.010687410831451416,
      -0.08752970397472382,
      0.025715751573443413,
      0.07683788239955902,
      -0.03642970696091652,
      -0.002306655514985323,
      0.010242518968880177,
      -0.0870862826704979,
      -0.0367797315120697,
      0.004827232100069523,
      -0.015633350238204002,
      -0.019296282902359962,
      -0.07884597778320312,
      0.01745019666850567,
      -0.015234985388815403,
      -0.06488458812236786,
      -0.0706094354391098,
      -0.04552684724330902,
      -0.08355367183685303,
      0.0054137143306434155,
      0.0701136514544487,
      0.024448702111840248,
      -0.006493506953120232,
      -0.012889886274933815,
      0.06260813027620316,
      0.053077034652233124,
      0.028572238981723785,
      0.008288958109915257,
      0.05975623428821564,
      -0.08296659588813782,
      -0.07061434537172318,
      0.03799162432551384,
      -0.02358432672917843,
      0.08619385212659836,
      0.03772621601819992,
      0.03211619704961777,
      -0.004368351306766272,
      0.028807686641812325,
      0.028957389295101166,
      0.09505825489759445,
      -0.020210454240441322,
      0.0538189634680748,
      -0.020657511427998543,
      0.049150001257658005,
      0.03130903095006943,
      -0.06545083969831467,
      -0.02015656605362892,
      0.03996806964278221,
      -0.05928100273013115,
      -0.00737567525357008,
      -0.012481676414608955,
      -0.00010835782450158149,
      -0.06202679127454758,
      0.032617516815662384,
      -0.04348627105355263,
      0.059294506907463074,
      0.01499869767576456,
      -0.07112973928451538,
      0.051461879163980484,
      0.020110348239541054,
      -0.08673109114170074,
      0.0024313710164278746,
      0.0879436582326889,
      0.030027419328689575,
      0.05260562524199486,
      -0.016673384234309196,
      -0.0634308010339737,
      -0.06716405600309372,
      -0.039739418774843216,
      -0.05088510736823082,
      0.03054792992770672,
      -0.002680224133655429,
      0.05690944939851761,
      0.09810356795787811,
      -0.034412600100040436,
      -0.023080728948116302,
      -0.03927823156118393,
      -0.1035296618938446,
      -0.08342932164669037,
      -0.005303357727825642,
      0.047525789588689804,
      -0.02930854819715023,
      0.06394989043474197,
      -0.02352898195385933,
      0.05188232287764549,
      -0.06748945266008377,
      -0.06412303447723389,
      -0.014450357295572758,
      0.0015448626363649964,
      0.00942804105579853,
      0.13339616358280182,
      -0.03919888660311699,
      0.08087599277496338,
      -0.03047015517950058,
      0.014483537524938583,
      -0.09372618049383163,
      0.05656342953443527,
      0.06388461589813232,
      -0.01586531661450863,
      0.03344160318374634,
      -0.008537857793271542,
      0.09696685522794724,
      0.03595428913831711,
      -0.026081537827849388,
      0.003979245200753212,
      -0.0648355558514595,
      0.010689422488212585,
      0.05321774631738663,
      0.1068904921412468,
      0.0040269168093800545,
      0.020317917689681053,
      -0.007645452860742807,
      -0.10256443917751312,
      -0.00952059868723154,
      0.018373040482401848,
      -0.0510723814368248,
      -0.05706118047237396,
      -0.06127045676112175,
      0.004593101795762777,
      0.06024783477187157,
      -0.01202145405113697,
      -0.05721195042133331,
      0.021675391122698784,
      -0.024952035397291183,
      0.066240593791008,
      -0.04737182706594467,
      -0.00337379053235054,
      0.055012430995702744,
      -0.009631481021642685,
      -0.06488208472728729,
      0.02775353379547596,
      -0.004006288945674896,
      -0.04351569712162018,
      0.003879313124343753,
      -0.027523862197995186,
      -0.053795672953128815,
      -0.06165295094251633,
      -0.03755497187376022,
      -0.027292171493172646,
      -0.004096526652574539,
      -0.04570771008729935,
      -0.03475059196352959,
      -0.04867227375507355,
      -0.10955549031496048,
      0.009472940117120743,
      0.0011497159721329808,
      0.026329122483730316,
      0.030970802530646324,
      0.013571280054748058,
      -0.03208552300930023,
      0.02757750079035759,
      -0.07127495110034943,
      0.009249379858374596,
      0.10734455287456512,
      -0.05971796065568924,
      -0.0213309396058321,
      0.06010589748620987,
      0.011506796814501286,
      0.011768962256610394,
      -0.045684970915317535,
      -0.030351681634783745,
      -0.03206229582428932,
      0.02360430173575878,
      0.043513402342796326,
      0.1114395260810852,
      -0.023938661441206932,
      0.026577910408377647,
      -0.0015052836388349533,
      0.013088800944387913,
      -0.03896341100335121,
      0.005259343888610601,
      -0.06633546203374863,
      0.05169748142361641,
      0.048047345131635666,
      0.054696954786777496,
      0.008212793618440628,
      0.05177508294582367,
      0.046665940433740616,
      0.06360418349504471,
      0.07919647544622421,
      -0.018339745700359344,
      0.012205149978399277,
      -0.047421421855688095,
      0.0029738834127783775,
      0.04069354385137558,
      0.01802069880068302,
      -0.025002211332321167,
      0.020503243431448936,
      0.10198292136192322,
      0.012041907757520676,
      0.052324630320072174,
      -0.005086724180728197,
      0.013975976034998894,
      0.036683350801467896,
      -0.03267176076769829,
      0.04062557965517044,
      -0.0001592967746546492,
      -0.004197714850306511,
      -0.02055785059928894,
      -0.02721281163394451,
      -0.02536378987133503,
      -0.012518087401986122,
      -0.05234166234731674,
      -0.016807395964860916,
      0.02153749205172062,
      -0.02449132315814495,
      -0.04267951846122742,
      -0.03309330344200134,
      -0.05532242730259895,
      -0.03265431523323059,
      -0.01000448502600193,
      0.03256673365831375,
      -0.0340714268386364,
      0.004800273105502129,
      -0.026050876826047897,
      -0.03543933480978012,
      -0.09452153742313385,
      -0.01591922901570797,
      0.008141209371387959,
      0.002302831504493952,
      -0.0007586892461404204,
      0.05461804196238518,
      -0.028868436813354492,
      0.0025418559089303017,
      0.028912100940942764,
      0.09306452423334122,
      -0.022976569831371307,
      0.017707109451293945,
      0.0116384606808424,
      0.012028670869767666,
      -0.03371807187795639,
      -0.029952863231301308,
      0.08647266030311584,
      -0.005770594347268343,
      -0.008130032569169998,
      -0.017784779891371727,
      0.009057018905878067,
      0.0005318356561474502,
      0.07435942441225052,
      -0.07892993837594986,
      -0.04389481246471405,
      -0.04412206634879112,
      -0.054646071046590805,
      0.04678842052817345,
      -0.023915914818644524,
      0.0196958277374506,
      -0.031231176108121872,
      0.09286448359489441,
      0.04269588738679886,
      -0.02707548998296261,
      -0.0008034880156628788,
      0.05215376242995262,
      0.061605848371982574,
      0.017944756895303726,
      -0.06364311277866364,
      -0.04493113234639168,
      0.037355534732341766,
      0.009393902495503426,
      -0.014764893800020218,
      -0.05902310833334923,
      0.012910026125609875,
      -0.00330690061673522,
      0.0457172654569149,
      -0.026310129091143608,
      0.04089706018567085,
      -0.07027130573987961,
      -0.02770763635635376,
      0.06281223148107529,
      0.001732578850351274,
      0.039945006370544434,
      0.07261235266923904,
      0.06391128152608871,
      0.009125372394919395,
      -0.015085059218108654,
      0.10039400309324265,
      -0.00884533952921629,
      0.013006245717406273,
      -0.01527124922722578,
      0.052015405148267746,
      -0.10843424499034882,
      0.08048275113105774,
      -0.05140179395675659,
      0.043936990201473236,
      -0.0873391181230545,
      -0.008142459206283092,
      0.049287788569927216,
      -0.0221080482006073,
      0.027478286996483803,
      0.025206828489899635,
      -0.02360648289322853,
      -0.02348950505256653,
      -0.0017583939479663968,
      -0.06437027454376221,
      0.028888007625937462,
      -0.05069595202803612,
      0.09233495593070984,
      -0.052204981446266174,
      -0.010608146898448467,
      -0.07052037119865417,
      0.0005242594052106142,
      0.04356764256954193,
      -0.023648690432310104,
      -0.004740593023598194,
      -0.04269280657172203,
      -0.01298307441174984,
      -0.0008929235627874732,
      -0.06309811770915985,
      0.02933993935585022,
      -0.01402792427688837,
      0.006134262308478355,
      -0.0572240874171257,
      -0.09531509876251221,
      0.010160506702959538,
      0.01929824985563755,
      -0.016323257237672806,
      -0.030384493991732597,
      0.00269751506857574,
      -0.056213460862636566,
      -0.04938962310552597,
      -0.08760584145784378,
      0.017207926139235497,
      -0.04540335386991501,
      -0.0514388307929039,
      0.052657172083854675,
      0.04360892251133919,
      0.03188971430063248,
      -0.020628245547413826,
      0.008973522111773491,
      -0.11060445010662079,
      -0.041639089584350586,
      -0.02755609154701233,
      -0.028231460601091385,
      0.07519634813070297,
      0.02142906002700329,
      0.034673456102609634,
      -0.008961286395788193,
      -0.01634339429438114,
      0.004290573298931122,
      0.023263221606612206,
      0.011144561693072319,
      0.029210809618234634,
      -0.1083410233259201,
      -0.046675216406583786,
      0.01262771338224411,
      -0.018394768238067627,
      0.06719912588596344,
      -0.020988155156373978,
      0.07784412801265717,
      0.0036606700159609318,
      0.019955502822995186,
      -0.12463147193193436,
      0.07992721349000931,
      0.013794307596981525,
      -0.027549365535378456,
      0.0882306694984436,
      -0.008927370421588421,
      -0.05162905156612396,
      0.023520953953266144,
      -0.04644237831234932,
      0.050634514540433884,
      -0.017031095921993256,
      -0.006487462669610977,
      -0.015981214120984077,
      0.009798826649785042,
      -0.07112859189510345,
      0.006019210908561945,
      0.05687951296567917,
      -0.03977581858634949,
      -0.008668079972267151,
      0.0400623194873333,
      0.04015817493200302,
      0.014391115866601467,
      -0.019687097519636154,
      -0.042538076639175415,
      0.025445686653256416,
      0.06038550287485123,
      -0.022765392437577248,
      0.06551355868577957,
      -0.10494937002658844,
      0.026514355093240738,
      0.015509601682424545,
      0.06611453741788864,
      0.03390341252088547,
      0.04781421646475792,
      -0.057307735085487366,
      0.09135023504495621,
      -0.006555336527526379,
      -0.02208145335316658,
      -0.15109783411026,
      -0.04412873834371567,
      -0.026800749823451042,
      0.055162493139505386,
      0.0010199517710134387,
      0.03493611887097359,
      -0.06600296497344971,
      0.04337874427437782,
      -0.029619481414556503,
      0.011812967248260975,
      -0.002325852634385228,
      -0.019574223086237907,
      -0.10399286448955536,
      0.021763769909739494,
      0.07265593111515045,
      -0.053610894829034805,
      0.012056776322424412,
      0.004991770721971989,
      -0.05847688764333725,
      0.02971983700990677,
      0.053709425032138824,
      -0.018696285784244537,
      0.020712966099381447,
      0.11311443150043488,
      -0.011715568602085114,
      0.05411336198449135,
      -0.04560450464487076,
      0.0018535559065639973,
      0.0005432621692307293,
      -0.08990742266178131,
      0.040666259825229645,
      -0.04119347780942917,
      0.03878771513700485,
      -0.0018398043466731906,
      -0.0031543713994324207,
      -0.04207178205251694,
      0.027299046516418457
    ],
    [
      -0.021788759157061577,
      0.009294789284467697,
      -0.01456747017800808,
      -0.04947333782911301,
      -0.0029552001506090164,
      -0.049738574773073196,
      -0.06540655344724655,
      -0.061014290899038315,
      0.024692879989743233,
      -0.021951820701360703,
      0.03858497366309166,
      0.054486069828271866,
      0.021718457341194153,
      -0.047257497906684875,
      0.06635796278715134,
      -0.0012109261006116867,
      -0.006972496397793293,
      0.06148118898272514,
      0.08126436173915863,
      -0.04787004366517067,
      0.027519196271896362,
      0.022304512560367584,
      0.022613974288105965,
      0.04404909908771515,
      -0.017925137653946877,
      -0.017767414450645447,
      -0.029034942388534546,
      0.07471531629562378,
      0.015830321237444878,
      -0.0326676145195961,
      -0.023616613820195198,
      -0.01641763560473919,
      -0.0549546517431736,
      -0.07171562314033508,
      0.0301760695874691,
      -0.0663006380200386,
      -0.008143696933984756,
      -0.007976471446454525,
      0.01007470116019249,
      0.04543308913707733,
      0.047535236924886703,
      -0.03567343205213547,
      -0.02271404303610325,
      -0.04993346333503723,
      -0.018114471808075905,
      0.02790175750851631,
      -0.040534645318984985,
      -0.0044887736439704895,
      -0.07624541968107224,
      0.0014490828616544604,
      0.02020758017897606,
      0.0007305936887860298,
      0.04939829930663109,
      -0.0018499057041481137,
      -0.04851599410176277,
      0.06558056175708771,
      0.043588753789663315,
      -0.005793908145278692,
      -0.0326739139854908,
      0.01618393510580063,
      -0.05890127643942833,
      0.04709554836153984,
      -0.04929640516638756,
      -0.057304009795188904,
      0.06392902880907059,
      0.027718238532543182,
      0.06862027198076248,
      0.0016926415264606476,
      0.07456037402153015,
      -0.04159989580512047,
      -0.005937239620834589,
      -0.04234195128083229,
      -0.10146616399288177,
      0.06850336492061615,
      -0.06686875224113464,
      0.0073954700492322445,
      -0.03794139251112938,
      0.02846931666135788,
      -0.022584905847907066,
      -0.036898333579301834,
      -0.04138551652431488,
      -0.030105503275990486,
      -0.0007208350580185652,
      0.042824387550354004,
      0.05954195559024811,
      -0.03153436258435249,
      -0.09907216578722,
      -0.06578828394412994,
      -0.012214797548949718,
      0.07630884647369385,
      -0.059484366327524185,
      0.0020670858211815357,
      -0.04545986279845238,
      0.0015058984281495214,
      -0.04050351679325104,
      -0.06618759781122208,
      -0.10897018015384674,
      0.04048255458474159,
      -0.04094664007425308,
      -0.0656650960445404,
      -0.036350566893815994,
      0.031194215640425682,
      0.031944528222084045,
      0.07522980123758316,
      0.06433973461389542,
      0.030079077929258347,
      -0.00305198528803885,
      -0.004090433474630117,
      0.005133833736181259,
      0.06100898236036301,
      0.049780819565057755,
      0.00419873371720314,
      -0.012738560326397419,
      0.04595925286412239,
      0.041852761059999466,
      -0.022545604035258293,
      -0.04701046645641327,
      0.06688405573368073,
      -0.051598794758319855,
      -0.024439247325062752,
      0.03545275330543518,
      -0.0197717547416687,
      -0.019513962790369987,
      -0.006340743042528629,
      0.0209178626537323,
      0.019108684733510017,
      -0.0473872609436512,
      -0.020161565393209457,
      0.031785860657691956,
      -0.00750522967427969,
      -0.03114127926528454,
      0.015914708375930786,
      0.014640174806118011,
      -0.0224943608045578,
      -0.0458260141313076,
      -0.02349023148417473,
      -0.02883576601743698,
      0.002761800540611148,
      0.04889756068587303,
      -0.1007026880979538,
      0.051012273877859116,
      -0.014512951485812664,
      -0.032981615513563156,
      -0.02163606695830822,
      0.008595437742769718,
      -0.03250123932957649,
      0.04492240399122238,
      -0.017293615266680717,
      -0.002799378242343664,
      0.03234483674168587,
      0.01853371411561966,
      -0.04226458817720413,
      -0.02530607022345066,
      0.008203917182981968,
      -0.006610148586332798,
      0.0023189426865428686,
      0.05596546083688736,
      -0.010710061527788639,
      -0.01756950281560421,
      0.027528733015060425,
      0.018437793478369713,
      -0.006463011726737022,
      -0.04639744386076927,
      -0.01807524636387825,
      0.016879498958587646,
      0.04812934994697571,
      -0.004574600141495466,
      0.042713604867458344,
      -0.06851351261138916,
      0.05466562509536743,
      0.021603167057037354,
      -0.032157883048057556,
      -0.006720551755279303,
      -0.0355687141418457,
      -0.052267685532569885,
      0.02733035758137703,
      -0.0629647746682167,
      0.018339117988944054,
      0.041158974170684814,
      0.0594671331346035,
      0.010863902978599072,
      0.05810390040278435,
      -0.0036381620448082685,
      -0.007951962761580944,
      0.07685459405183792,
      0.0021554753184318542,
      0.03208748251199722,
      -0.09157374501228333,
      0.041262030601501465,
      0.05890689790248871,
      -0.08565901219844818,
      -0.006104115396738052,
      0.02676139585673809,
      0.07047991454601288,
      0.020583631470799446,
      -0.05352859944105148,
      -0.04766146466135979,
      -0.034913625568151474,
      -0.0018360150279477239,
      -0.0018701148219406605,
      -0.011373376473784447,
      -0.04095460847020149,
      -0.05490844324231148,
      -0.012594812549650669,
      0.005948405712842941,
      0.018616119399666786,
      -0.06652215123176575,
      0.035125650465488434,
      0.08260907977819443,
      0.03147953376173973,
      -0.06454168260097504,
      0.02203700691461563,
      0.06675110012292862,
      0.043032094836235046,
      0.04541413113474846,
      -0.03419462591409683,
      -0.01778540387749672,
      -0.028019137680530548,
      -0.0751231238245964,
      -0.05579128488898277,
      0.03206447511911392,
      -0.004976747091859579,
      0.03285247087478638,
      -0.03912058100104332,
      -0.1061021089553833,
      0.05902792885899544,
      0.06309723854064941,
      0.01743042655289173,
      0.06389032304286957,
      0.05769549310207367,
      -0.03778689354658127,
      -0.07727976143360138,
      0.017144691199064255,
      -0.037397779524326324,
      -0.041370686143636703,
      -0.1030825674533844,
      -0.039597220718860626,
      -0.018907906487584114,
      0.06363087147474289,
      -0.047837547957897186,
      0.08842924982309341,
      -0.04674965888261795,
      0.06461440026760101,
      0.021464074030518532,
      0.04579395428299904,
      0.05074099451303482,
      -0.03529626503586769,
      -0.04530930519104004,
      -0.014225001446902752,
      0.03918170928955078,
      -0.0045320140197873116,
      0.10034599155187607,
      -0.043021656572818756,
      0.03719878941774368,
      -0.017586523666977882,
      0.022861115634441376,
      -0.004117381293326616,
      -0.03460269048810005,
      -0.04762876778841019,
      0.06840594112873077,
      -0.11995670199394226,
      -0.014492128975689411,
      0.01658579334616661,
      -0.0020462071988731623,
      -0.03832899034023285,
      0.01910010166466236,
      -0.07134836912155151,
      -0.033761750906705856,
      -0.030249083414673805,
      -0.03305948153138161,
      -0.02453276328742504,
      0.003212854964658618,
      0.013129103928804398,
      -0.011219359003007412,
      -0.045760344713926315,
      -0.023547817021608353,
      0.048545077443122864,
      -0.04962955042719841,
      -0.023900873959064484,
      -0.038265522569417953,
      0.023100275546312332,
      0.07357046008110046,
      0.005117583088576794,
      -0.003736244048923254,
      -0.0023775093723088503,
      -0.05044105276465416,
      -0.05395832657814026,
      0.03860338777303696,
      -0.01703130453824997,
      -0.022360876202583313,
      -0.021044747903943062,
      0.04750313609838486,
      -0.05662805959582329,
      0.04070308804512024,
      -0.04319438710808754,
      0.007053514942526817,
      0.03834810480475426,
      0.03672938048839569,
      -0.008019976317882538,
      0.015602639876306057,
      0.04108631610870361,
      0.020603785291314125,
      -0.08881919085979462,
      0.027606233954429626,
      0.03054627776145935,
      -0.06470801681280136,
      -0.035310063511133194,
      -0.014918549917638302,
      -0.0038222847506403923,
      0.08539183437824249,
      -0.03368474170565605,
      -0.03188459947705269,
      0.04329235106706619,
      0.012181584723293781,
      0.008168539963662624,
      0.0038290894590318203,
      0.02774870954453945,
      -0.031740959733724594,
      0.0036944292951375246,
      -0.013895711861550808,
      0.07323353737592697,
      -0.013397621922194958,
      -0.0318024568259716,
      0.024007216095924377,
      0.018069997429847717,
      0.07671936601400375,
      0.02687370777130127,
      -0.02345995418727398,
      0.03749426454305649,
      0.0393487922847271,
      -0.0589272603392601,
      -0.01737809367477894,
      0.0076932404190301895,
      0.06649128347635269,
      -0.0048692310228943825,
      -0.036000773310661316,
      0.05981459841132164,
      0.06831234693527222,
      -0.019595405086874962,
      0.007896880619227886,
      0.04378151521086693,
      0.05321545526385307,
      -0.047834016382694244,
      -0.01762983947992325,
      0.09093768894672394,
      -0.0686771497130394,
      0.06069062277674675,
      0.012009235098958015,
      -0.09930316358804703,
      -0.012859481386840343,
      -0.030720965936779976,
      0.006340125575661659,
      -0.012881661765277386,
      -0.01493085641413927,
      -0.03282218053936958,
      -0.01687215082347393,
      0.046053286641836166,
      0.06395614892244339,
      0.004393349401652813,
      -0.04047801345586777,
      0.027310743927955627,
      -0.03159622848033905,
      -0.05678817629814148,
      0.011417982168495655,
      -0.0020051973406225443,
      -0.10039512068033218,
      -0.004971869755536318,
      0.06100253760814667,
      -0.01189679466187954,
      0.012365218251943588,
      0.0659950003027916,
      0.005787384696304798,
      -0.06916788220405579,
      0.004889948759227991,
      0.022362807765603065,
      -0.004984541796147823,
      0.025511229410767555,
      0.020272936671972275,
      0.039594270288944244,
      0.006981507409363985,
      -0.09619195014238358,
      -0.0665065199136734,
      -0.024136198684573174,
      0.045748330652713776,
      -0.03899521380662918,
      -0.06154969334602356,
      0.06928512454032898,
      -0.031630802899599075,
      -0.03381463512778282,
      -0.012420374900102615,
      0.0199755672365427,
      -0.019736433401703835,
      0.07516998797655106,
      -0.005086701828986406,
      0.03830355405807495,
      -0.01579166203737259,
      0.00443282350897789,
      -0.0691201388835907,
      0.0754426047205925,
      -0.005612802226096392,
      0.03170229494571686,
      0.008025972172617912,
      -0.060183074325323105,
      0.0621824637055397,
      0.02277999185025692,
      0.010935771279036999,
      -0.03301923722028732,
      -0.018344346433877945,
      -0.019183862954378128,
      0.03130635991692543,
      -0.0015971000539138913,
      -0.07429134845733643,
      0.030275166034698486,
      0.06788261234760284,
      -0.02067113108932972,
      0.01817205175757408,
      -0.050262488424777985,
      -0.06021330505609512,
      -0.07658969610929489,
      -0.015612318180501461,
      -0.06773579865694046,
      -0.07035402953624725,
      0.0088322339579463,
      0.05123493820428848,
      0.03239752724766731,
      0.055957190692424774,
      -0.002238168613985181,
      0.0170079804956913,
      -0.0044831326231360435,
      0.04338160902261734,
      -0.06391047686338425,
      0.008386182598769665,
      0.005946877412497997,
      0.07587946206331253,
      -0.0857815146446228,
      0.10653002560138702,
      -0.00024150956596713513,
      0.03521209955215454,
      0.057993918657302856,
      -0.07638351619243622,
      -0.051636338233947754,
      -0.024532489478588104,
      0.016041478142142296,
      0.024147771298885345,
      0.02945832908153534,
      0.011992299929261208,
      0.01877441443502903,
      -0.06608963012695312,
      -0.08842163532972336,
      -0.014517371542751789,
      0.03500252217054367,
      0.04191082715988159,
      -0.025832509621977806,
      0.008474824018776417,
      0.03779720887541771,
      0.026797926053404808,
      -0.051834750920534134,
      -0.020159173756837845,
      0.04001364856958389,
      -0.07408376038074493,
      -0.0492311529815197,
      0.07862702757120132,
      0.036857716739177704,
      0.06478387862443924,
      -0.0751945823431015,
      0.006306533236056566,
      0.05976424366235733,
      0.0853966698050499,
      -0.010709255933761597,
      -0.025377221405506134,
      0.00018996755534317344,
      -0.11722741276025772,
      -0.013239753432571888,
      -0.02478189207613468,
      0.029075060039758682,
      0.07115606963634491,
      -0.038629934191703796,
      0.010099903680384159,
      -0.021667705848813057,
      -0.0842917189002037,
      -0.04732288792729378,
      0.03602009639143944,
      0.02182493731379509,
      0.04920608177781105,
      -0.014255914837121964,
      -0.030978983268141747,
      -0.02705366536974907,
      -0.0631483867764473,
      -0.00563098443672061,
      0.02143646962940693,
      -0.05002918839454651,
      0.07822944968938828,
      0.031248483806848526,
      -0.004488838370889425,
      -0.03466242179274559,
      0.010439387522637844,
      -0.09659986943006516,
      0.009937998838722706,
      0.02003355696797371,
      -0.012590319849550724,
      0.06977023929357529,
      0.012648627161979675,
      -0.003407145384699106
    ],
    [
      -0.02827412448823452,
      0.06240871921181679,
      -0.023004835471510887,
      -0.0006712099420838058,
      0.027605295181274414,
      -0.05800751969218254,
      -0.000959929486270994,
      0.008781622163951397,
      -0.011258677579462528,
      0.018668565899133682,
      0.045990947633981705,
      0.01673683524131775,
      0.0015662616351619363,
      -0.00831978116184473,
      -0.019077708944678307,
      -0.054043225944042206,
      0.015375945717096329,
      0.05546940490603447,
      -0.011665528640151024,
      -0.006136865355074406,
      -0.0390162393450737,
      -0.004946235101670027,
      0.015761999413371086,
      -0.015258371829986572,
      0.06700081378221512,
      0.006010203622281551,
      -0.10501133650541306,
      0.0018129225354641676,
      0.0107651362195611,
      0.011895857751369476,
      -0.07598470896482468,
      -0.048783622682094574,
      0.004965477157384157,
      -0.01282711885869503,
      -0.02663205936551094,
      0.059711504727602005,
      0.04322400689125061,
      0.04201909154653549,
      -0.022546157240867615,
      0.004597554914653301,
      -0.029545515775680542,
      0.02508700266480446,
      -0.040740516036748886,
      -0.02348761074244976,
      0.022814614698290825,
      -0.09453117847442627,
      -0.0039046388119459152,
      -0.0028890001121908426,
      -0.041296880692243576,
      0.030823128297924995,
      0.028230853378772736,
      -0.07334795594215393,
      -0.0020698625594377518,
      0.0041583809070289135,
      0.0268480833619833,
      0.0008182192104868591,
      0.05311678349971771,
      0.037569187581539154,
      0.020594114437699318,
      0.029275154694914818,
      -0.1291957050561905,
      0.013250045478343964,
      -0.08284842222929001,
      -0.00014178255514707416,
      0.06021035835146904,
      0.12276801466941833,
      -0.03440864384174347,
      -0.033482834696769714,
      -0.0226553063839674,
      0.0676831603050232,
      0.038024336099624634,
      -0.02876656875014305,
      -0.04771362617611885,
      -0.025042811408638954,
      0.0013490838464349508,
      -0.007836651988327503,
      0.018595511093735695,
      -0.016666078940033913,
      0.01613532565534115,
      0.04439125955104828,
      -0.09868437796831131,
      0.0020885891281068325,
      -0.014601523987948895,
      -0.12566454708576202,
      0.03641829639673233,
      0.043859802186489105,
      0.011236065998673439,
      0.041949622333049774,
      0.035049427300691605,
      0.018024463206529617,
      -0.019485607743263245,
      0.021093418821692467,
      0.001971810357645154,
      -0.016940880566835403,
      -0.06021098047494888,
      -0.027730168774724007,
      -0.03382226079702377,
      -0.09025697410106659,
      0.013932578265666962,
      -0.0036714840680360794,
      0.014957815408706665,
      0.059407711029052734,
      -0.025420239195227623,
      0.13817492127418518,
      0.00451545650139451,
      0.1097942441701889,
      -0.036233965307474136,
      0.022105591371655464,
      0.041015006601810455,
      0.03310253471136093,
      -0.03113861195743084,
      0.03174395114183426,
      0.02039031870663166,
      0.06583090871572495,
      0.02849908173084259,
      -0.005134701728820801,
      0.04321146383881569,
      -0.08935372531414032,
      0.06478603184223175,
      -0.016838906332850456,
      -0.03194498270750046,
      0.028926556929945946,
      -0.06101363152265549,
      0.02148042805492878,
      0.013921369798481464,
      0.005050732754170895,
      -0.005430986173450947,
      -0.038502488285303116,
      -0.08305952697992325,
      -0.04702253267168999,
      -0.03543591871857643,
      0.06521467119455338,
      0.021213380619883537,
      0.053961534053087234,
      0.06667822599411011,
      0.031062858179211617,
      -0.014855345711112022,
      0.034460555762052536,
      -0.015583226457238197,
      -0.05112506076693535,
      0.023925645276904106,
      -0.01481019426137209,
      -0.0008874604827724397,
      0.017453547567129135,
      0.03151857480406761,
      -0.01245936844497919,
      -0.007892032153904438,
      0.049053799360990524,
      0.035692572593688965,
      0.050136517733335495,
      0.04173191264271736,
      0.01671525090932846,
      -0.02498307265341282,
      0.10482678562402725,
      0.04752649739384651,
      0.02173365093767643,
      -0.01034510973840952,
      0.08847120404243469,
      0.056723322719335556,
      -0.07009327411651611,
      -0.07215053588151932,
      -0.045044295489788055,
      0.0637063980102539,
      0.04618505388498306,
      -0.0022507228422909975,
      0.10274355113506317,
      0.05671016871929169,
      0.010838105343282223,
      -0.0009415650856681168,
      -0.08842842280864716,
      0.0906740128993988,
      -0.030364133417606354,
      -0.04343252629041672,
      -0.0641215592622757,
      0.013073855079710484,
      0.032447751611471176,
      0.07716208696365356,
      0.005985958967357874,
      -0.0020141142886132,
      0.058106303215026855,
      0.03699834272265434,
      0.035761505365371704,
      0.01051377784460783,
      -0.04916643723845482,
      -0.016303468495607376,
      -0.01627686247229576,
      0.06369541585445404,
      -0.01711304858326912,
      0.06474021077156067,
      -0.04104635491967201,
      -0.0425412692129612,
      0.016353826969861984,
      0.013593899086117744,
      0.021296866238117218,
      -0.101324662566185,
      -0.013596655800938606,
      -0.0426785908639431,
      -0.018811075016856194,
      -0.011791651137173176,
      -0.08970008045434952,
      0.013531522825360298,
      0.0006193996523506939,
      0.0640287920832634,
      -0.01262596808373928,
      0.02442391961812973,
      -0.02447754330933094,
      0.09824887663125992,
      -0.07746872305870056,
      -0.03909609466791153,
      -0.023601295426487923,
      0.04317869618535042,
      0.006511140614748001,
      -0.03617400676012039,
      0.05428997427225113,
      0.03367467597126961,
      0.036364004015922546,
      0.09831655025482178,
      0.05696066841483116,
      -0.09138322621583939,
      -0.008916071616113186,
      0.029143454506993294,
      0.07856576144695282,
      -0.018954478204250336,
      -0.03423818200826645,
      -0.0738099217414856,
      -0.03292924165725708,
      0.017852654680609703,
      0.03929577022790909,
      -0.03643579035997391,
      -0.03078547492623329,
      -0.0006881177541799843,
      -0.02436501905322075,
      -0.06550227105617523,
      -0.019484050571918488,
      -0.02309465780854225,
      -0.003201540093868971,
      0.11179842054843903,
      0.025896985083818436,
      0.03546973690390587,
      0.04320652410387993,
      -0.08493991941213608,
      -0.024834349751472473,
      0.020310472697019577,
      -0.05318049341440201,
      0.009158897213637829,
      -0.017466716468334198,
      0.05907289683818817,
      0.008570462465286255,
      -0.0425894632935524,
      -0.07761018723249435,
      -0.07441361993551254,
      0.02021033503115177,
      -0.04307985305786133,
      -0.07466334104537964,
      0.0033991611562669277,
      -0.02314276620745659,
      -0.05049604922533035,
      0.022911064326763153,
      0.0014868250582367182,
      0.0607522688806057,
      -0.016394956037402153,
      -0.00422166520729661,
      0.04700725898146629,
      0.012023821473121643,
      0.0021056202240288258,
      0.003771454794332385,
      -0.05288786441087723,
      0.04133770242333412,
      0.04643386974930763,
      -0.03926809877157211,
      -0.05023201182484627,
      -0.021302547305822372,
      0.062381673604249954,
      0.028347115963697433,
      0.010343247093260288,
      0.007544985972344875,
      -0.03111208789050579,
      -0.013379530981183052,
      0.04362541437149048,
      -0.05532718449831009,
      0.05910390987992287,
      -0.005271426402032375,
      -0.04072266444563866,
      0.046326637268066406,
      -0.049729250371456146,
      0.012911682948470116,
      -0.007115722633898258,
      0.000832271936815232,
      0.0155138885602355,
      0.0009404445299878716,
      -0.04811996594071388,
      0.007544276304543018,
      0.021075231954455376,
      0.11969241499900818,
      -0.036089036613702774,
      0.05555243790149689,
      0.00799616426229477,
      0.02213059365749359,
      0.004856331739574671,
      0.020395830273628235,
      0.07397851347923279,
      -0.08706842362880707,
      -0.07658573240041733,
      0.02165737934410572,
      -0.04278339818120003,
      -0.03427969291806221,
      -0.01469494216144085,
      0.09382951259613037,
      0.05735880881547928,
      -0.02554713375866413,
      -0.018710898235440254,
      -0.04196132346987724,
      0.04280621185898781,
      -0.03150263428688049,
      0.009626942686736584,
      -0.0264580100774765,
      0.07926340401172638,
      0.056763626635074615,
      -0.05328734964132309,
      0.04377409815788269,
      0.06061652675271034,
      0.04839859902858734,
      -0.08474027365446091,
      0.05283253639936447,
      -0.0312710702419281,
      0.027426419779658318,
      -0.04118463024497032,
      0.034708380699157715,
      0.04945077374577522,
      -0.027540432289242744,
      0.015700720250606537,
      -0.035370320081710815,
      0.006262004841119051,
      -0.0221747737377882,
      -0.07718200981616974,
      6.336990918498486e-05,
      0.02737223356962204,
      -0.0036052220966666937,
      -0.00019250325567554682,
      2.156326263502706e-05,
      0.06614682078361511,
      -0.022573387250304222,
      0.05620798096060753,
      -0.06107255443930626,
      -0.03638128191232681,
      0.005838054697960615,
      0.02851998247206211,
      -0.0017971944762393832,
      -0.009884274564683437,
      -0.000651095702778548,
      0.021224047988653183,
      0.02673155628144741,
      0.012906341813504696,
      -0.06153358146548271,
      -0.06170724704861641,
      -0.01093974243849516,
      -0.014493586495518684,
      -0.01717350445687771,
      -0.0620407909154892,
      0.037441786378622055,
      -0.05377376824617386,
      0.03737383708357811,
      0.043218158185482025,
      8.650028030388057e-05,
      -0.004712189082056284,
      -0.0023437312338501215,
      0.06904591619968414,
      0.04944603145122528,
      -0.009540699422359467,
      -0.023063955828547478,
      -0.04013751819729805,
      -0.014969611540436745,
      -0.03821449726819992,
      0.035259347409009933,
      -0.03560434281826019,
      -0.03905776888132095,
      -0.009703872725367546,
      -0.01956704631447792,
      -0.01056199986487627,
      0.011446783319115639,
      -0.003759798826649785,
      0.0006653492455370724,
      -0.06167774647474289,
      -0.008009480312466621,
      -0.0051114545203745365,
      0.035450104624032974,
      0.039758969098329544,
      -0.08109378069639206,
      -0.05133674293756485,
      -0.0027903555892407894,
      0.0008089003385975957,
      0.04932627081871033,
      -0.035653792321681976,
      0.008312850259244442,
      -0.05209793895483017,
      0.011695323511958122,
      0.056909311562776566,
      -0.10650227963924408,
      -0.0888664573431015,
      -0.008334512822329998,
      0.05715895816683769,
      -0.02977745048701763,
      0.011839230544865131,
      0.06215085834264755,
      0.03257177770137787,
      0.017419256269931793,
      0.04406750202178955,
      -0.028334688395261765,
      -0.0764455795288086,
      0.0459955595433712,
      -0.029977943748235703,
      0.0013001331826671958,
      -0.018460087478160858,
      -0.061488181352615356,
      0.05895671620965004,
      0.005384091287851334,
      0.024213455617427826,
      -0.013518888503313065,
      -0.0013434255961328745,
      0.054099906235933304,
      0.052094388753175735,
      -0.056539468467235565,
      -0.013777022249996662,
      0.02736138552427292,
      0.017208075150847435,
      0.016578366979956627,
      -0.050839863717556,
      -0.04793684557080269,
      0.11004583537578583,
      0.039635732769966125,
      0.029313640668988228,
      0.009314004331827164,
      -0.0005430130986496806,
      0.0286792553961277,
      -0.07076075673103333,
      -0.034510765224695206,
      0.03280908614397049,
      -0.01173823606222868,
      0.04862651601433754,
      0.011042592115700245,
      0.023078633472323418,
      0.03628890961408615,
      -0.023145336657762527,
      -0.049372609704732895,
      -0.04484869912266731,
      0.06317796558141708,
      0.03186182305216789,
      -0.02083056978881359,
      0.028078317642211914,
      0.03087967075407505,
      -0.040444567799568176,
      0.023921271786093712,
      0.0325462743639946,
      -0.05052400380373001,
      -0.005479699466377497,
      0.016947835683822632,
      -0.02258693240582943,
      0.043308015912771225,
      0.018554270267486572,
      0.028118466958403587,
      -0.032345354557037354,
      0.036839913576841354,
      -0.049424994736909866,
      -0.06629900634288788,
      0.004634091164916754,
      0.04804764315485954,
      -0.06370154768228531,
      -0.01772955432534218,
      -0.01542411744594574,
      -0.0191266480833292,
      -0.0738702043890953,
      0.007737875450402498,
      -0.05581838637590408,
      -0.06607313454151154,
      0.00016087330004666,
      0.03771226108074188,
      0.00223568850196898,
      -0.006228070240467787,
      -0.09585776180028915,
      0.04195502772927284,
      -0.047328151762485504,
      0.039131518453359604,
      0.03046543337404728,
      -0.026974976062774658,
      0.01949489861726761,
      -0.04405960068106651,
      0.03559037670493126,
      -0.04022502154111862,
      -0.05366408824920654,
      0.01459987461566925,
      -0.05637591704726219,
      -0.04165562614798546,
      0.03372444957494736,
      -0.1086777001619339,
      0.024766085669398308,
      0.010112430900335312,
      0.008314044214785099,
      -0.011977758258581161,
      -0.03273153305053711,
      0.04709437116980553,
      0.012209339067339897,
      0.026630477979779243,
      -0.0905759185552597
    ],
    [
      0.050534140318632126,
      0.006803113967180252,
      0.04420696198940277,
      -0.08599583059549332,
      -0.07514096796512604,
      0.057481799274683,
      0.026922697201371193,
      0.0026013462338596582,
      -0.016570955514907837,
      0.006535903085023165,
      -0.051874224096536636,
      -0.04049311950802803,
      0.035439711064100266,
      -0.058052945882081985,
      -0.03972858190536499,
      0.00851457193493843,
      -0.034917231649160385,
      0.02483535371720791,
      0.029271438717842102,
      -0.007298617623746395,
      -0.010993504896759987,
      -0.019746631383895874,
      -0.014469841495156288,
      0.008489014580845833,
      0.01975744031369686,
      -0.018573572859168053,
      0.05561339855194092,
      0.06993759423494339,
      0.018222836777567863,
      0.07183080911636353,
      -0.05510787293314934,
      0.004446478094905615,
      -0.008168596774339676,
      0.04471060633659363,
      0.020375223830342293,
      -0.040028166025877,
      -0.00047093024477362633,
      0.006061773747205734,
      0.05458809435367584,
      0.018295293673872948,
      0.0333629809319973,
      -0.010765047743916512,
      0.003697542706504464,
      0.05070208013057709,
      -0.028639372438192368,
      -0.018876560032367706,
      0.00968312006443739,
      -0.024345582351088524,
      0.05667875334620476,
      0.022413017228245735,
      -0.07788528501987457,
      -0.0069464147090911865,
      -0.03964170068502426,
      0.05249777063727379,
      -0.051265500485897064,
      -0.032849863171577454,
      0.03425978124141693,
      0.06313008069992065,
      0.008621769957244396,
      -0.020631903782486916,
      -0.08086688816547394,
      -0.0024617197923362255,
      0.05303569883108139,
      -0.03551625832915306,
      -0.003265530336648226,
      -0.05023948475718498,
      0.017871886491775513,
      -0.09122032672166824,
      0.061055224388837814,
      0.04428926482796669,
      0.061674199998378754,
      -0.004448289982974529,
      -0.01730331778526306,
      -0.020172080025076866,
      0.03743983805179596,
      -0.08167998492717743,
      0.03102664090692997,
      -0.023625869303941727,
      -0.013192067854106426,
      0.020412947982549667,
      0.005383051000535488,
      -0.008003619499504566,
      0.0047438256442546844,
      0.016092034056782722,
      0.004463274031877518,
      -0.05743839219212532,
      0.0048891338519752026,
      -0.061409611254930496,
      -0.028565332293510437,
      -0.05240140110254288,
      0.05557379499077797,
      -0.04994840919971466,
      -0.06587617844343185,
      -0.002640604507178068,
      -0.10307272523641586,
      0.019025789573788643,
      -0.03844457492232323,
      0.06797350943088531,
      0.05837797001004219,
      0.019416145980358124,
      0.008781793527305126,
      -0.026206467300653458,
      -0.02662137895822525,
      -0.02396814152598381,
      0.05099628493189812,
      0.041747454553842545,
      0.05422605946660042,
      -0.03413091599941254,
      -0.004582604859024286,
      -0.014834609813988209,
      -0.03774358704686165,
      0.03725798800587654,
      -0.038431987166404724,
      0.008526583202183247,
      -0.07120323926210403,
      0.037862397730350494,
      -0.026699567213654518,
      -0.010408105328679085,
      0.04414375126361847,
      -0.012070665135979652,
      -0.05075652897357941,
      0.047254957258701324,
      -0.10295615345239639,
      0.009428598918020725,
      0.007495954167097807,
      -0.019207477569580078,
      -0.005832602269947529,
      0.05263841524720192,
      -0.0363672636449337,
      0.006758550647646189,
      0.06005614623427391,
      0.043478164821863174,
      -0.05253981798887253,
      0.00785820186138153,
      -0.02961837314069271,
      -0.07297166436910629,
      -0.09181231260299683,
      0.08862211555242538,
      0.02189958654344082,
      0.008826131001114845,
      0.036008261144161224,
      -0.05990290641784668,
      0.07071057707071304,
      -0.01422959752380848,
      -0.03322330117225647,
      -0.028104547411203384,
      0.00964098796248436,
      -0.032510582357645035,
      -0.054294977337121964,
      -0.07474199682474136,
      0.0012025510659441352,
      0.005772429518401623,
      0.005596659611910582,
      -0.016211088746786118,
      0.07823871076107025,
      0.03973601013422012,
      0.04681791737675667,
      0.05087203159928322,
      0.021048353984951973,
      0.017465325072407722,
      0.007675001863390207,
      0.06922905892133713,
      0.043977923691272736,
      -0.07501648366451263,
      -0.017836207523941994,
      -0.038795918226242065,
      0.12367308139801025,
      0.0028009205125272274,
      0.08673064410686493,
      -0.015401292592287064,
      -0.07725795358419418,
      0.012651831842958927,
      0.10425107181072235,
      -0.030760126188397408,
      -0.02207989990711212,
      0.05025353655219078,
      0.03634437918663025,
      0.00540394801646471,
      -0.06771120429039001,
      0.032826706767082214,
      0.06464627385139465,
      -0.018430430442094803,
      0.09842503070831299,
      -0.05410163849592209,
      -0.015427282080054283,
      0.010283743031322956,
      0.00654985336586833,
      -0.08235719799995422,
      -0.010011972859501839,
      0.03521525859832764,
      0.027374589815735817,
      -0.004593946505337954,
      -0.03861699253320694,
      -0.04365011304616928,
      0.06722722202539444,
      -0.025177324190735817,
      -0.01396692544221878,
      0.0008154179668053985,
      0.03508586063981056,
      -0.0056030708365142345,
      0.07915907353162766,
      0.018787063658237457,
      -0.04429462552070618,
      -0.008205441758036613,
      0.04325081408023834,
      -0.00831838883459568,
      0.0789087638258934,
      0.010275250300765038,
      0.057175736874341965,
      0.011925000697374344,
      -0.03469735383987427,
      0.06465524435043335,
      0.09085358679294586,
      0.028631385415792465,
      -0.059588536620140076,
      0.02592778019607067,
      0.06986638158559799,
      -0.0010787317296490073,
      -0.006239116657525301,
      0.05804862827062607,
      0.09594792872667313,
      0.011657635681331158,
      0.032078661024570465,
      -0.010041899047791958,
      0.05678744614124298,
      -0.05952766537666321,
      0.010123349726200104,
      -0.02514510415494442,
      0.010496333241462708,
      0.08691109716892242,
      0.07963159680366516,
      0.04478979483246803,
      -0.02329370751976967,
      0.05811590328812599,
      -0.04500015079975128,
      -0.04387180507183075,
      0.06537996977567673,
      -0.024713747203350067,
      0.014277305454015732,
      0.004610818810760975,
      -0.03213803470134735,
      -0.010134200565516949,
      0.05518461763858795,
      0.009818441234529018,
      0.02379101701080799,
      -0.04222986102104187,
      -0.09011968970298767,
      -0.08617635816335678,
      0.03959963843226433,
      -0.0012819059193134308,
      -0.023796074092388153,
      -0.05355574190616608,
      -0.0839262306690216,
      -0.05560564994812012,
      -0.07088618725538254,
      0.013633972965180874,
      -0.06953034549951553,
      0.018027884885668755,
      -0.05316540598869324,
      -0.09748952835798264,
      -0.01180964708328247,
      -0.01997155137360096,
      0.008961477316915989,
      0.01144927367568016,
      -0.01065581850707531,
      -0.08512523025274277,
      -0.033473797142505646,
      0.00024047703482210636,
      -0.05069129541516304,
      0.017026454210281372,
      -0.05039786547422409,
      -0.03148576244711876,
      0.04571427404880524,
      -0.016834843903779984,
      -0.0038611164782196283,
      0.006170938257128,
      -0.022998055443167686,
      -0.007279181387275457,
      -0.04739515110850334,
      -0.020950153470039368,
      -0.11676125228404999,
      0.003712942125275731,
      0.005100294016301632,
      -0.024030059576034546,
      -0.0718415379524231,
      0.06675892323255539,
      -0.0023788565304130316,
      0.001088818651624024,
      -0.04638831689953804,
      -0.028362011536955833,
      0.024142790585756302,
      0.03891254961490631,
      0.038214024156332016,
      -0.01715509220957756,
      0.021430231630802155,
      0.023959128186106682,
      -0.026325616985559464,
      0.06531697511672974,
      -0.04031600430607796,
      0.0742558017373085,
      0.027758190408349037,
      -0.007389129139482975,
      0.01755221001803875,
      -0.09253406524658203,
      -0.04297551140189171,
      -0.008560258895158768,
      -0.027092935517430305,
      -0.07834847271442413,
      0.01831492781639099,
      0.07688214629888535,
      0.0496542789041996,
      0.06201228126883507,
      -0.006774556823074818,
      -0.02207237109541893,
      0.014396190643310547,
      -0.00921238400042057,
      -0.003139749402180314,
      -0.0026735852006822824,
      -0.05207933112978935,
      0.04753784090280533,
      0.007775159552693367,
      -0.036416903138160706,
      -0.03596971184015274,
      0.024543672800064087,
      0.008823694661259651,
      -0.017292041331529617,
      -0.016147039830684662,
      0.06304968148469925,
      0.06446035951375961,
      0.09269297122955322,
      -0.008825717493891716,
      -0.039311014115810394,
      0.06598878651857376,
      0.019562149420380592,
      -0.027607977390289307,
      -0.04661969840526581,
      -0.05771771818399429,
      0.08944588154554367,
      -0.0015110437525436282,
      -0.008333196863532066,
      0.020783642306923866,
      -0.008949261158704758,
      0.00467440253123641,
      -0.039056118577718735,
      -0.00048510549822822213,
      0.0029696612618863583,
      0.01293690875172615,
      -0.017802370712161064,
      0.008741863071918488,
      0.013110486790537834,
      -0.0507601723074913,
      0.039804160594940186,
      0.07055217772722244,
      -0.04185991734266281,
      0.01779388077557087,
      -0.006957751698791981,
      0.06624928116798401,
      0.15107104182243347,
      -0.010141715407371521,
      0.01688365824520588,
      0.07810118794441223,
      0.010957645252346992,
      -0.02555782161653042,
      0.1009008064866066,
      -0.031232327222824097,
      0.014060530811548233,
      -0.04777688905596733,
      -0.04285132884979248,
      -0.04845462366938591,
      -0.01036828849464655,
      0.022488972172141075,
      0.03440031409263611,
      0.019838163629174232,
      0.025474464520812035,
      -0.018137328326702118,
      0.08749944716691971,
      -0.03804105147719383,
      -0.07777049392461777,
      -0.017957448959350586,
      0.006993320304900408,
      0.04298493638634682,
      -0.005376532208174467,
      0.054767925292253494,
      0.056312743574380875,
      0.004172773566097021,
      -0.002774118445813656,
      -0.013553989119827747,
      -0.08441409468650818,
      0.02750552073121071,
      0.053773775696754456,
      -0.0017663843464106321,
      0.06767692416906357,
      0.06526091694831848,
      0.06542433053255081,
      0.0387919582426548,
      -0.01931896060705185,
      0.06887499988079071,
      0.028014948591589928,
      -0.0512596033513546,
      0.03253413736820221,
      -0.028233880177140236,
      0.022469056770205498,
      -0.025567632168531418,
      -0.002318059094250202,
      0.0941135361790657,
      0.02842891588807106,
      -0.007455992046743631,
      0.07746572047472,
      0.016811558976769447,
      -0.08821570873260498,
      0.006230762694031,
      0.023563295602798462,
      -0.04160973057150841,
      -0.018824627622961998,
      -0.004920278210192919,
      0.008464775048196316,
      -0.029988741502165794,
      0.01239496935158968,
      -0.012742315419018269,
      0.03867020085453987,
      0.0009922663448378444,
      -0.027286671102046967,
      -0.000498914101626724,
      -0.030762942507863045,
      0.012978225946426392,
      -0.04401611164212227,
      0.016756940633058548,
      0.061533283442258835,
      0.033230870962142944,
      0.013613306917250156,
      0.0592658556997776,
      0.054193511605262756,
      0.03283298760652542,
      0.004591699223965406,
      -0.040090665221214294,
      0.000487294775666669,
      0.017390331253409386,
      -0.012035060673952103,
      0.008285153657197952,
      0.10660635679960251,
      -0.036167241632938385,
      0.018170243129134178,
      -0.037497423589229584,
      -0.001116799539886415,
      0.054818760603666306,
      -0.05568099766969681,
      -0.00505500053986907,
      0.006586656905710697,
      0.005789752118289471,
      0.033089011907577515,
      0.07414953410625458,
      -0.027216218411922455,
      0.0457003228366375,
      -0.10401972383260727,
      0.03379984200000763,
      -0.02068566158413887,
      0.01428944244980812,
      -0.07049435377120972,
      0.03782522678375244,
      -0.08233566582202911,
      -0.03178839385509491,
      -0.03279253840446472,
      -0.04819761961698532,
      -0.04848426952958107,
      -0.06107639893889427,
      -0.008067667484283447,
      -0.07909248769283295,
      -0.006692963652312756,
      0.005298053380101919,
      0.08205688744783401,
      -0.06061903014779091,
      -0.01592583954334259,
      -0.04592754319310188,
      -0.05618474259972572,
      -0.030551942065358162,
      0.05882204324007034,
      0.0104141840711236,
      0.015523500740528107,
      -0.11207392811775208,
      -0.01163879968225956,
      -0.040888283401727676,
      0.013640975579619408,
      0.002010137075558305,
      -0.012542951852083206,
      -0.012489722110331059,
      0.00977272354066372,
      0.03663137927651405,
      -0.037772323936223984,
      0.0032061415258795023,
      0.05544799566268921,
      -0.055058665573596954,
      0.06693968176841736,
      -0.01221995335072279,
      0.04280034452676773,
      -0.013727539218962193,
      0.0048407441936433315,
      0.06023610755801201,
      -0.03201410546898842,
      0.009779924526810646,
      -0.02247421070933342,
      0.1064743846654892,
      -0.02161707729101181,
      -0.004249176476150751
    ],
    [
      -0.029532626271247864,
      0.023858288303017616,
      0.08004002273082733,
      0.032858822494745255,
      0.05265785753726959,
      -0.06347054243087769,
      -0.03536013886332512,
      -0.04277295991778374,
      -0.003432026831433177,
      0.0004545965348370373,
      0.03607352450489998,
      0.017845049500465393,
      -0.0709570124745369,
      -0.04632352665066719,
      -0.048285987228155136,
      -0.029463673010468483,
      0.04564160853624344,
      0.0006887514027766883,
      0.005126130301505327,
      0.011326596140861511,
      0.023417111486196518,
      0.018136125057935715,
      0.006288131233304739,
      -0.041024982929229736,
      0.012560952454805374,
      -0.024908240884542465,
      0.08338653296232224,
      0.017148204147815704,
      -0.005807673558592796,
      -0.13554179668426514,
      -0.020589554682374,
      -0.03633623197674751,
      0.01910236105322838,
      0.0018835325026884675,
      -0.03460393846035004,
      0.018072836101055145,
      -0.027559751644730568,
      -0.0001884198863990605,
      0.008430808782577515,
      0.08088624477386475,
      0.0603581927716732,
      0.06284922361373901,
      -0.06380858272314072,
      -0.03361646085977554,
      0.06979350745677948,
      -0.06468856334686279,
      -0.003998664673417807,
      -0.06139232963323593,
      -0.08981835097074509,
      0.01922944188117981,
      0.05863767862319946,
      0.0034088368993252516,
      -0.11025536060333252,
      0.13390131294727325,
      0.04314413666725159,
      0.035558659583330154,
      0.04201341047883034,
      0.012308225966989994,
      0.09977288544178009,
      -0.026416370645165443,
      -0.0011021036189049482,
      -0.08563826233148575,
      -0.03708269074559212,
      -0.014699139632284641,
      0.020999904721975327,
      0.05570510774850845,
      -0.04247962683439255,
      0.0223789494484663,
      -0.03257736563682556,
      -0.0007418295717798173,
      0.054965440183877945,
      -0.05843212082982063,
      -0.03664221987128258,
      0.003070713486522436,
      -0.0033410415053367615,
      -0.04501830413937569,
      0.025400444865226746,
      -0.04245927557349205,
      0.039827052503824234,
      -0.017048191279172897,
      0.04077359661459923,
      -0.05424165353178978,
      0.0267396979033947,
      0.02161264792084694,
      -0.04158801957964897,
      0.011720399372279644,
      0.048323240131139755,
      0.0032788675744086504,
      0.0063976203091442585,
      -0.005900615826249123,
      -0.002829601988196373,
      0.026074938476085663,
      0.0611286386847496,
      0.026347309350967407,
      0.030474333092570305,
      9.960277384379879e-05,
      -0.0075826565735042095,
      0.027904754504561424,
      0.04615915194153786,
      -0.030336633324623108,
      0.017450744286179543,
      0.17525090277194977,
      -0.015376240015029907,
      -0.00322382221929729,
      0.027949843555688858,
      0.03234633803367615,
      -0.010171889327466488,
      0.061194416135549545,
      -0.02448934316635132,
      -0.019484028220176697,
      -0.058409299701452255,
      -0.10574941337108612,
      0.007418223191052675,
      -0.07407166808843613,
      -0.03140181303024292,
      -0.03018026612699032,
      -0.010723733343183994,
      0.0055849128402769566,
      -0.008330022916197777,
      0.007804248947650194,
      0.01968483254313469,
      -0.07663746178150177,
      -0.09249342232942581,
      0.019404007121920586,
      0.021862724795937538,
      0.05561995506286621,
      0.010016906075179577,
      -0.051151636987924576,
      0.01686766929924488,
      -0.01986709050834179,
      -0.033978842198848724,
      0.02184733748435974,
      0.021052096039056778,
      -0.008917300961911678,
      0.02250414341688156,
      0.014384492300450802,
      0.0016197455115616322,
      0.062013108283281326,
      0.016558509320020676,
      -0.04867495223879814,
      0.013122281059622765,
      0.06521645188331604,
      0.013314257375895977,
      0.021715017035603523,
      0.029201816767454147,
      -0.028117943555116653,
      0.0048315501771867275,
      0.023497942835092545,
      0.03432383015751839,
      -0.06270072609186172,
      -0.04956945776939392,
      0.01648418977856636,
      -0.004910554736852646,
      0.012632366269826889,
      0.0329287014901638,
      -0.004881871398538351,
      -0.06917489320039749,
      0.05414000153541565,
      0.04709206894040108,
      -0.027355898171663284,
      -0.024936595931649208,
      -0.0015443686861544847,
      -0.001592913526110351,
      0.028892384842038155,
      -0.045424699783325195,
      0.020882343873381615,
      0.012685480527579784,
      0.00281274295412004,
      -0.009571841917932034,
      0.014251167885959148,
      0.06188112124800682,
      -0.10008704662322998,
      0.047367144376039505,
      -0.03415399789810181,
      0.033177971839904785,
      -0.009726918302476406,
      -0.07155141234397888,
      -0.01564447209239006,
      -0.013533097691833973,
      -0.04619690775871277,
      -0.031040044501423836,
      0.02875353954732418,
      0.011746341362595558,
      -0.004464888479560614,
      0.04884934797883034,
      -0.022110983729362488,
      0.09169846773147583,
      0.06553123891353607,
      0.014257772825658321,
      0.015652058646082878,
      0.06853609532117844,
      -0.049600813537836075,
      -0.020367054268717766,
      0.030311236158013344,
      0.0015387637540698051,
      -0.09723565727472305,
      0.10599570721387863,
      0.0005477649974636734,
      -0.015791554003953934,
      -0.04408847168087959,
      -0.017222369089722633,
      0.0015177603345364332,
      -0.014999928884208202,
      0.0013375210110098124,
      -0.044901199638843536,
      0.0019837773870676756,
      -0.013948448933660984,
      -0.005143118556588888,
      -0.01777137629687786,
      -0.023804085329174995,
      -0.012699425220489502,
      -0.07464668899774551,
      -0.009756171144545078,
      0.0002489351318217814,
      -0.002342477673664689,
      0.029557205736637115,
      0.003253736300393939,
      0.0049464707262814045,
      -0.015927938744425774,
      0.001814434421248734,
      -0.018482012674212456,
      -0.001401230925694108,
      0.05430483818054199,
      0.012903380207717419,
      0.026499489322304726,
      -0.048613592982292175,
      -0.03919580206274986,
      0.022706331685185432,
      0.02518332004547119,
      -0.0028264871798455715,
      -0.012661142274737358,
      -0.017064828425645828,
      -0.007034598849713802,
      -0.005348799284547567,
      -0.02140660025179386,
      0.017256250604987144,
      0.0044176713563501835,
      0.07067373394966125,
      0.09771796315908432,
      0.045630067586898804,
      -0.027743268758058548,
      0.014138182625174522,
      0.05828719586133957,
      0.03741142153739929,
      0.028280029073357582,
      -0.0768861174583435,
      -0.018890751525759697,
      0.029563646763563156,
      0.0504828616976738,
      0.09473196417093277,
      -0.051651183515787125,
      0.042731769382953644,
      0.031134719029068947,
      0.004543296992778778,
      0.03667625039815903,
      0.053773727267980576,
      -0.05164390802383423,
      0.04701165854930878,
      -0.008684627711772919,
      -0.04041722044348717,
      -0.08940911293029785,
      0.06294525414705276,
      0.01173093356192112,
      -0.06282448023557663,
      -0.06537337601184845,
      0.033852651715278625,
      -0.038737550377845764,
      0.07048195600509644,
      0.017155956476926804,
      -0.0636228621006012,
      -0.08454299718141556,
      0.08446763455867767,
      0.0007668386679142714,
      -0.06292372941970825,
      0.0036511518992483616,
      0.03774207830429077,
      0.02555667795240879,
      0.046277157962322235,
      -0.06493272632360458,
      -0.03955559805035591,
      0.024798961356282234,
      0.05736745148897171,
      0.041371166706085205,
      0.0017526342999190092,
      -0.02071979083120823,
      0.036311473697423935,
      0.08752989023923874,
      -0.01388672273606062,
      0.0056319041177630424,
      0.008424930274486542,
      -0.10146576166152954,
      0.02392895147204399,
      -0.04497143626213074,
      -0.0434156134724617,
      -0.015698499977588654,
      0.04223919287323952,
      0.027808215469121933,
      0.06163077428936958,
      -0.0018202026840299368,
      0.004206163343042135,
      0.0513121522963047,
      0.006632458418607712,
      -0.02365853264927864,
      0.029721328988671303,
      -0.03146052733063698,
      -0.009012507274746895,
      0.047011714428663254,
      -0.0949774906039238,
      0.024204246699810028,
      -0.02688766084611416,
      0.03891407325863838,
      -0.0484420582652092,
      0.08480896800756454,
      0.010112197138369083,
      0.015950249508023262,
      -0.09233848005533218,
      0.03778494521975517,
      -0.033974070101976395,
      -0.03922874107956886,
      0.02496340498328209,
      0.01483871415257454,
      -0.042879339307546616,
      -0.05174967646598816,
      0.00494404649361968,
      0.019918393343687057,
      0.020257484167814255,
      0.06788840889930725,
      -0.056856971234083176,
      0.06516391038894653,
      0.0734454095363617,
      -0.09852777421474457,
      -0.007526654750108719,
      0.07026427984237671,
      0.050119392573833466,
      -0.07800298929214478,
      0.054728034883737564,
      -0.01939251273870468,
      0.050720036029815674,
      0.04120425879955292,
      -0.010780800133943558,
      -0.04963500797748566,
      0.030147774145007133,
      0.00867010373622179,
      -0.06589535623788834,
      -0.05353263393044472,
      0.040948957204818726,
      0.04197937995195389,
      -0.00035556830698624253,
      -0.04339871555566788,
      0.03398196026682854,
      -0.012974521145224571,
      0.0836390033364296,
      -0.007786774076521397,
      -0.012423400767147541,
      0.04275542497634888,
      -0.06535259634256363,
      -0.1130104586482048,
      0.02994784526526928,
      0.009889170527458191,
      0.06261793524026871,
      0.029003608971834183,
      -0.000306960369925946,
      -0.017792392522096634,
      -0.015303482301533222,
      -0.024368517100811005,
      -7.845828804420307e-06,
      0.0015925788320600986,
      0.027554409578442574,
      0.05660458281636238,
      -0.06674079596996307,
      -0.033131446689367294,
      -0.04111902788281441,
      -0.004662739112973213,
      0.027916423976421356,
      0.06928154081106186,
      0.026304971426725388,
      0.007484970148652792,
      0.046486180275678635,
      -0.0396377369761467,
      -0.061056580394506454,
      0.1038210541009903,
      0.02570275217294693,
      -0.018535669893026352,
      -0.011998823843896389,
      0.03669380769133568,
      -0.062462709844112396,
      0.02503056824207306,
      0.010603240691125393,
      0.028819134458899498,
      0.06858404725790024,
      0.009383583441376686,
      0.017703473567962646,
      -0.03290340676903725,
      -0.047146230936050415,
      0.03740733489394188,
      0.0300375334918499,
      0.030758963897824287,
      0.054561976343393326,
      -0.03379754722118378,
      -0.033272646367549896,
      -0.06593861430883408,
      0.06354024261236191,
      -0.004365535918623209,
      0.06382673978805542,
      -0.05782749503850937,
      -0.004061814397573471,
      -0.006220717914402485,
      0.040558550506830215,
      0.017081471160054207,
      -0.03279350325465202,
      0.05226593092083931,
      0.008614033460617065,
      0.04084054008126259,
      0.033438459038734436,
      -0.009825455956161022,
      -0.03739587962627411,
      0.007328472100198269,
      0.034623969346284866,
      0.00544382818043232,
      -0.041933976113796234,
      -0.031005455181002617,
      -0.05753229185938835,
      -0.02961142547428608,
      0.04150119051337242,
      -0.04775049164891243,
      -0.03561616688966751,
      -0.058449991047382355,
      -0.04903237521648407,
      0.044275280088186264,
      0.037125151604413986,
      -0.02384406141936779,
      0.04210443049669266,
      -0.013880150392651558,
      -0.035283368080854416,
      -0.03094056434929371,
      0.008054973557591438,
      -0.017025060951709747,
      0.011018254794180393,
      0.016345320269465446,
      0.0015100021846592426,
      0.0011602436425164342,
      0.04578789696097374,
      0.01364328246563673,
      0.02648783102631569,
      0.049716752022504807,
      -0.12580642104148865,
      -0.004083535168319941,
      0.007436440791934729,
      -0.018491607159376144,
      -0.043903883546590805,
      -0.061748817563056946,
      0.05726849287748337,
      -0.15288905799388885,
      -0.040383923798799515,
      0.053739458322525024,
      -0.03861687704920769,
      -0.01997477002441883,
      0.060569968074560165,
      0.004224089439958334,
      -0.0634630098938942,
      -0.010083692148327827,
      0.06626325845718384,
      0.084915392100811,
      0.01148848608136177,
      0.037696439772844315,
      0.03813605383038521,
      -0.0806785449385643,
      0.047315627336502075,
      0.041304122656583786,
      -0.0385550782084465,
      0.017828742042183876,
      -0.05173429101705551,
      0.0652066320180893,
      0.02130035124719143,
      0.013288083486258984,
      0.08167211711406708,
      -0.06111271306872368,
      0.050794798880815506,
      0.01001834124326706,
      -0.007586367893964052,
      -0.012252523563802242,
      -0.04122759401798248,
      -0.019357288256287575,
      -0.04938079044222832,
      -0.07311258465051651,
      0.03505915403366089,
      0.06787822395563126,
      -0.07652584463357925,
      -0.0006078540463931859,
      0.00690059270709753,
      -0.008907048031687737,
      0.0822182148694992,
      -0.03192882612347603,
      0.10554103553295135,
      -0.01841985620558262,
      -0.07280149310827255,
      -0.030047209933400154,
      0.07890621572732925,
      -0.011722316034138203,
      0.15744969248771667,
      0.026379862800240517,
      -0.01388999167829752,
      -0.028057314455509186
    ],
    [
      -0.0388474240899086,
      0.03435888513922691,
      0.009212350472807884,
      -0.05890543386340141,
      0.04225953668355942,
      0.05249189957976341,
      0.03287680819630623,
      0.03527938574552536,
      -0.013729402795433998,
      -0.005077525041997433,
      -0.01723647676408291,
      -0.051897283643484116,
      0.0027704916428774595,
      0.01017070934176445,
      0.008352618664503098,
      0.0027413268107920885,
      -0.04696807265281677,
      -0.05769431218504906,
      -0.03499947860836983,
      0.03404032438993454,
      0.02631177008152008,
      -0.0750727653503418,
      -0.06015469506382942,
      -0.014658557251095772,
      0.012240334413945675,
      0.015061031095683575,
      -0.0693804994225502,
      0.02646268717944622,
      0.054850492626428604,
      0.07061868160963058,
      -0.0009097556467168033,
      -0.016651278361678123,
      0.03325776755809784,
      -0.012902629561722279,
      -0.0367770753800869,
      -0.01414756290614605,
      0.04841459542512894,
      -0.05145406350493431,
      -0.02919732965528965,
      -0.042407240718603134,
      0.06129208207130432,
      -0.037144679576158524,
      0.06997242569923401,
      0.10002348572015762,
      -0.0058578611351549625,
      -0.06568147987127304,
      0.02098020166158676,
      -0.05890575051307678,
      0.02716985158622265,
      0.08763296157121658,
      -0.005480130203068256,
      -0.015179384499788284,
      -0.0009014371316879988,
      -0.03223147988319397,
      -0.00946295727044344,
      -0.028143471106886864,
      -0.004460150841623545,
      0.016361286863684654,
      0.0078052012249827385,
      0.005651462357491255,
      -0.067049540579319,
      -0.07895302772521973,
      -0.018718721345067024,
      0.01503076683729887,
      -0.039909571409225464,
      -0.11097817867994308,
      0.01907297410070896,
      -0.019344793632626534,
      -0.07543608546257019,
      0.017836889252066612,
      0.021749870851635933,
      -0.04036087170243263,
      0.03777262568473816,
      -0.02010514587163925,
      -0.013594930991530418,
      -0.032665353268384933,
      0.01108428556472063,
      -0.019935529679059982,
      0.072755366563797,
      0.020880011841654778,
      -0.0007030246197246015,
      0.017404695972800255,
      -0.008705236949026585,
      -0.044311556965112686,
      -0.02021983452141285,
      -0.05214772745966911,
      -0.014832613989710808,
      -0.031202111393213272,
      -0.012072295881807804,
      -0.012221889570355415,
      -0.024854497984051704,
      0.07742058485746384,
      0.07278701663017273,
      -0.02516895718872547,
      -0.02980884537100792,
      0.058461785316467285,
      0.08626392483711243,
      0.015564286150038242,
      -0.0450206995010376,
      0.0710933580994606,
      -0.033864911645650864,
      0.02252090908586979,
      0.03365185111761093,
      0.0020695009734481573,
      -0.07422026246786118,
      -0.046107761561870575,
      0.06954973936080933,
      0.029954373836517334,
      -0.060701049864292145,
      -0.054754674434661865,
      -0.04557636380195618,
      -0.04006795212626457,
      0.024609984830021858,
      0.03360089287161827,
      0.08523280918598175,
      0.03726758435368538,
      0.015909478068351746,
      -0.06147461757063866,
      -0.011831676587462425,
      0.005263116676360369,
      -0.009911619126796722,
      -0.013366667553782463,
      -0.07317112386226654,
      0.042974356561899185,
      -0.06172157824039459,
      -0.040674708783626556,
      0.056804899126291275,
      -0.08396685123443604,
      -0.03760477900505066,
      -0.08502879738807678,
      0.018874522298574448,
      -0.018411602824926376,
      -0.06493517011404037,
      -0.029699113219976425,
      0.044258471578359604,
      -0.05356622487306595,
      0.011878916062414646,
      -0.042251188308000565,
      -0.049395851790905,
      -0.05344250425696373,
      -0.002439373405650258,
      -0.018016962334513664,
      0.1097002774477005,
      0.02112860232591629,
      0.001066360855475068,
      0.054210398346185684,
      0.020103828981518745,
      -0.03443862870335579,
      -0.002930184593424201,
      0.03012438490986824,
      0.05783087760210037,
      -0.06911728531122208,
      -0.020533407106995583,
      -0.039999619126319885,
      0.055309414863586426,
      0.004549129866063595,
      0.023439690470695496,
      -0.0047934213653206825,
      0.020776504650712013,
      -0.022296525537967682,
      0.015636492520570755,
      0.05838356167078018,
      0.029894018545746803,
      0.0368979386985302,
      -0.06591874361038208,
      -0.09707701206207275,
      0.0032840834464877844,
      -0.019272515550255775,
      0.04821830242872238,
      -0.04262180253863335,
      -0.031254351139068604,
      -0.033887289464473724,
      0.018761079758405685,
      -0.03322295472025871,
      0.011042957194149494,
      0.03439439460635185,
      0.0673188716173172,
      -0.006640351843088865,
      -0.0022878306917846203,
      0.004420013166964054,
      -0.004712029825896025,
      -0.07953423261642456,
      -0.002086012391373515,
      -0.05818427726626396,
      -0.007961592637002468,
      -0.07398892939090729,
      -0.010064630769193172,
      0.043450310826301575,
      -0.008884039707481861,
      -0.004701734520494938,
      -0.02589339204132557,
      -0.00282025127671659,
      0.009052806533873081,
      0.028818098828196526,
      -0.027961231768131256,
      -0.06342680752277374,
      -0.012429694645106792,
      -0.009798409417271614,
      -0.03860398754477501,
      -0.03616055101156235,
      -0.023273399099707603,
      -0.02018994837999344,
      0.020569318905472755,
      0.031851429492235184,
      0.05583783984184265,
      -0.012509413994848728,
      0.10804396122694016,
      -0.06075074523687363,
      0.058179114013910294,
      -0.027760425582528114,
      0.04074098914861679,
      -0.01029953733086586,
      0.009979055263102055,
      -0.10804545879364014,
      -0.04568891227245331,
      -0.023141516372561455,
      0.025844955816864967,
      0.07538459450006485,
      0.06561143696308136,
      -0.031332969665527344,
      -0.044556427747011185,
      0.0842924639582634,
      0.009489311836659908,
      -0.05947088822722435,
      0.01073769386857748,
      -0.0025485632941126823,
      -0.02390836551785469,
      0.024057496339082718,
      0.0221702978014946,
      0.017106380313634872,
      7.705268217250705e-05,
      -0.1101495772600174,
      -0.0227041058242321,
      0.04781239107251167,
      0.08404014259576797,
      -0.00187767599709332,
      -0.005908841732889414,
      0.028341015800833702,
      -0.04374232888221741,
      -0.002946358174085617,
      0.020408419892191887,
      -0.0304014403373003,
      0.0887783095240593,
      -0.062206465750932693,
      0.02066562883555889,
      -0.05590103566646576,
      0.04427698999643326,
      0.04384937509894371,
      0.05086793377995491,
      0.025059539824724197,
      -0.0352894626557827,
      -0.046198368072509766,
      -0.03542150929570198,
      0.11393163353204727,
      -0.0702761635184288,
      0.023907208815217018,
      0.010274216532707214,
      -0.08173682540655136,
      0.02618006430566311,
      -0.09667910635471344,
      -0.1055852547287941,
      -0.021540526300668716,
      -0.04406125470995903,
      -0.052818071097135544,
      -0.05043854936957359,
      -0.08281087130308151,
      0.004899024963378906,
      0.009840697981417179,
      -0.02932583913207054,
      0.011114238761365414,
      0.04111862555146217,
      -0.050275202840566635,
      -0.025336122140288353,
      0.023146314546465874,
      0.020630108192563057,
      0.009059876203536987,
      -0.025442788377404213,
      0.01249490212649107,
      0.027384810149669647,
      -0.04919322952628136,
      -0.0008778787450864911,
      0.03176895156502724,
      -0.06306064128875732,
      -0.024262117221951485,
      0.06635717302560806,
      -0.0831567794084549,
      0.04125550016760826,
      -0.05253161862492561,
      0.00610903138294816,
      0.005483132321387529,
      -0.01769345812499523,
      0.02915049158036709,
      0.0025437793228775263,
      0.036396436393260956,
      0.056670475751161575,
      0.016852624714374542,
      -0.008539097383618355,
      -0.012053308077156544,
      0.09036072343587875,
      0.05472557619214058,
      -0.07446253299713135,
      -0.0038985684514045715,
      0.01173882745206356,
      0.018858497962355614,
      0.0879187062382698,
      0.10114773362874985,
      0.013260447420179844,
      0.022306790575385094,
      -0.009701671078801155,
      0.1311456710100174,
      -0.05156290903687477,
      -0.07027766853570938,
      0.028018131852149963,
      -0.008969367481768131,
      -0.012846297584474087,
      -0.027973759919404984,
      -0.026903817430138588,
      0.12405960261821747,
      0.05607350170612335,
      -0.0479467511177063,
      -0.04885386675596237,
      0.04491525888442993,
      -0.09095808118581772,
      0.037728291004896164,
      -0.03653362765908241,
      -0.08280763775110245,
      0.025572525337338448,
      0.005713631398975849,
      0.02100251615047455,
      -0.015871647745370865,
      -0.055339913815259933,
      0.009861440397799015,
      0.04564899206161499,
      -0.02304769866168499,
      -0.10335969179868698,
      -0.03416894003748894,
      0.001814891118556261,
      0.013372823596000671,
      -0.0009825433371588588,
      -0.05747438967227936,
      -0.022421343252062798,
      0.033437516540288925,
      0.08528731018304825,
      0.032455798238515854,
      -0.052157919853925705,
      0.03910557180643082,
      -0.00573256378993392,
      -4.787581565324217e-05,
      -0.08190254867076874,
      0.022756259888410568,
      0.02416018210351467,
      0.03295695409178734,
      -0.08567435294389725,
      -0.05152194947004318,
      0.015198421664536,
      -0.054687753319740295,
      -0.023535819724202156,
      -0.029340237379074097,
      0.02430991642177105,
      -0.01202247105538845,
      0.0701863169670105,
      0.0016522802179679275,
      0.05462081357836723,
      -0.02649439312517643,
      0.05056258663535118,
      0.002517188899219036,
      -0.038185615092515945,
      0.06731804460287094,
      -0.04103017598390579,
      -0.014553329907357693,
      0.025393031537532806,
      -0.024671562016010284,
      -0.013815861195325851,
      -0.01689189113676548,
      0.10110624879598618,
      0.01149273756891489,
      0.0029651119839400053,
      -0.08546212315559387,
      0.011785621754825115,
      0.015027167275547981,
      0.05097892880439758,
      0.07333357632160187,
      -0.01275459211319685,
      -0.019060231745243073,
      -0.06130726635456085,
      -0.07491243630647659,
      -0.056278470903635025,
      0.06941184401512146,
      -0.036875173449516296,
      -0.04019347205758095,
      -0.061945874243974686,
      0.035442229360342026,
      -0.042271360754966736,
      -0.015192601829767227,
      0.03387419134378433,
      -0.024147259071469307,
      0.014709768816828728,
      0.08521609008312225,
      0.04699333384633064,
      0.05176282301545143,
      0.0411565788090229,
      -0.01275019720196724,
      -0.07066279649734497,
      0.010769931599497795,
      0.04563009738922119,
      0.043460384011268616,
      0.0324152372777462,
      -0.005527575500309467,
      -0.08211951702833176,
      0.003401600057259202,
      -0.03701254352927208,
      0.01610410399734974,
      -0.05325397476553917,
      -0.044578198343515396,
      0.05769267678260803,
      -0.06588739156723022,
      0.05607762932777405,
      -0.08689010143280029,
      -0.08370435237884521,
      0.03470225632190704,
      0.07719175517559052,
      -0.09145296365022659,
      0.03986648842692375,
      0.0069280955940485,
      -0.02560720220208168,
      0.09336881339550018,
      0.005849155597388744,
      0.018459821119904518,
      0.010588178411126137,
      0.022556183859705925,
      0.06901855021715164,
      0.06571482121944427,
      -0.03968547657132149,
      -0.006388754118233919,
      0.017181314527988434,
      -0.028787726536393166,
      -0.027989741414785385,
      0.010391993448138237,
      0.037376727908849716,
      0.008565336465835571,
      -0.0782087966799736,
      0.010576285421848297,
      -0.02382027357816696,
      -0.0031908401288092136,
      -0.014929265715181828,
      0.009385338053107262,
      0.030767928808927536,
      0.006024523172527552,
      -0.005846284795552492,
      -0.06320782005786896,
      0.05176498740911484,
      0.04165516421198845,
      0.03944525495171547,
      0.009351626969873905,
      0.0187880489975214,
      -0.030413823202252388,
      -0.038350027054548264,
      -0.056440047919750214,
      0.024744389578700066,
      -0.04786563292145729,
      -0.030749507248401642,
      -0.007476679980754852,
      0.03969201073050499,
      0.05675015598535538,
      -0.030444016680121422,
      -0.01574713923037052,
      0.04802236706018448,
      -0.05953681468963623,
      0.020886598154902458,
      0.09495670348405838,
      -0.039135802537202835,
      -0.01551287341862917,
      0.017345188185572624,
      0.01318380981683731,
      0.051185790449380875,
      -0.014707054942846298,
      0.014776075258851051,
      -0.07046709209680557,
      0.020119817927479744,
      -0.024442533031105995,
      -0.07430078089237213,
      -0.03681501746177673,
      0.03930681198835373,
      0.04179159924387932,
      0.025162480771541595,
      0.0017003347165882587,
      -0.011085912585258484,
      0.04013720527291298,
      -0.030743181705474854,
      -0.021129950881004333,
      0.02933088131248951,
      0.025676805526018143,
      0.10535567253828049,
      0.010374989360570908,
      0.01611485332250595,
      0.05079273134469986,
      0.0596892274916172,
      -0.04562211036682129,
      -0.028162557631731033,
      0.011071591638028622,
      0.039629433304071426,
      0.04902435094118118,
      -0.08054541796445847
    ],
    [
      0.030219923704862595,
      -0.04718497395515442,
      0.06547366827726364,
      0.007867849431931973,
      -0.015320993959903717,
      0.06460988521575928,
      0.052045997232198715,
      0.010973072610795498,
      0.006569267250597477,
      0.003421981818974018,
      -0.010996353812515736,
      -0.06911172717809677,
      -0.03587424010038376,
      0.05953073874115944,
      -0.08769255131483078,
      -0.03404998406767845,
      -0.0123797208070755,
      -0.07768131047487259,
      0.05741752311587334,
      0.0018395005026832223,
      -0.015700753778219223,
      -0.036651138216257095,
      -0.06421267241239548,
      -0.06177782267332077,
      -0.025926243513822556,
      0.000537490239366889,
      -0.07187867164611816,
      -0.012301892042160034,
      0.02714163437485695,
      -0.04421873763203621,
      -0.04368382319808006,
      -0.06171458587050438,
      0.006323906593024731,
      0.0022521675564348698,
      -0.03851887211203575,
      -0.012438979931175709,
      -0.03971971943974495,
      0.0063540334813296795,
      -0.04618512839078903,
      -0.09672100096940994,
      0.0010736673139035702,
      -0.00911374855786562,
      -0.02422950230538845,
      -0.0024907009210437536,
      -0.0005593908135779202,
      0.0019129941938444972,
      0.008315919898450375,
      -0.02932094968855381,
      -0.019956910982728004,
      -0.0004348060756456107,
      -0.02832197956740856,
      -0.04516253247857094,
      0.008358018472790718,
      -0.04482460394501686,
      -0.050660207867622375,
      -0.038044046610593796,
      0.04908505454659462,
      0.006894758436828852,
      0.01697981171309948,
      0.01385875791311264,
      -0.010305095463991165,
      0.07225071638822556,
      0.02611836977303028,
      -0.03347131237387657,
      0.042145054787397385,
      0.06467178463935852,
      -0.06031530722975731,
      -0.00870028231292963,
      0.002503214171156287,
      0.05621832236647606,
      0.05365453287959099,
      0.03321465477347374,
      -0.04783555120229721,
      -0.00889816228300333,
      -0.03157200291752815,
      -0.005214547738432884,
      0.017603836953639984,
      0.005707012489438057,
      -0.050336457788944244,
      0.01833929494023323,
      0.019396144896745682,
      -0.00040734378853812814,
      0.034163232892751694,
      0.03521629050374031,
      0.0463559664785862,
      -0.01815981976687908,
      0.054268766194581985,
      0.03780579939484596,
      0.035538267344236374,
      0.029620204120874405,
      0.04534789174795151,
      0.004116573836654425,
      -0.014337902888655663,
      -0.0669257864356041,
      0.09362143278121948,
      -0.001496384502388537,
      0.0017898156074807048,
      0.021858541294932365,
      0.03266475349664688,
      0.03138124570250511,
      -0.01669866032898426,
      0.01275026984512806,
      0.031081965193152428,
      -0.03175900876522064,
      0.06444234400987625,
      -0.015389894135296345,
      0.009355001151561737,
      0.06707624346017838,
      -0.0557691864669323,
      -0.052893076092004776,
      0.035056404769420624,
      -0.08608114719390869,
      0.021776264533400536,
      0.06118088588118553,
      -0.05171583592891693,
      -0.02086961269378662,
      0.01614423096179962,
      -0.0008393707685172558,
      -0.06486024707555771,
      -0.0004460139898583293,
      0.06800644844770432,
      0.045281995087862015,
      -0.011030392721295357,
      -0.023986004292964935,
      0.0228106752038002,
      -0.05394298583269119,
      0.010486837476491928,
      -0.00944441556930542,
      -0.1271553486585617,
      -0.030042901635169983,
      -0.021220963448286057,
      0.04470058158040047,
      -0.021226173266768456,
      -0.04143418371677399,
      -0.017782460898160934,
      -0.004885359667241573,
      0.03311026096343994,
      -0.042104482650756836,
      0.06850190460681915,
      -0.05083869397640228,
      0.08705195039510727,
      0.019439471885561943,
      0.09638163447380066,
      0.007613035384565592,
      0.01335684023797512,
      0.002063690684735775,
      0.03747708722949028,
      0.022536559030413628,
      -0.12553292512893677,
      -0.018898291513323784,
      0.01105835847556591,
      -0.03920739144086838,
      0.008044392801821232,
      0.030729634687304497,
      -0.022111384198069572,
      -0.033468883484601974,
      -0.02440876141190529,
      0.057058185338974,
      0.05981163680553436,
      0.018237661570310593,
      0.03698563575744629,
      -0.023647768422961235,
      0.005253879819065332,
      0.033694759011268616,
      -0.02460119128227234,
      -0.06122283264994621,
      -0.035267043858766556,
      0.033403415232896805,
      -0.04053327068686485,
      0.016758691519498825,
      -0.002927271416410804,
      0.04665587842464447,
      -0.0906909629702568,
      -0.08196134865283966,
      0.0009083899203687906,
      0.031232673674821854,
      -0.042592957615852356,
      0.06884065270423889,
      0.03422956168651581,
      -0.03039400838315487,
      -0.008999448269605637,
      0.021803002804517746,
      0.009276468306779861,
      -0.0036818375810980797,
      0.1000373512506485,
      -0.027898836880922318,
      -0.003388322191312909,
      0.0795440599322319,
      0.06937185674905777,
      0.04072568193078041,
      0.005881111137568951,
      -0.023052042350172997,
      0.14186519384384155,
      -0.005530648864805698,
      -0.03052719496190548,
      0.04576636850833893,
      -0.05809503048658371,
      -0.10959874838590622,
      -0.04914405196905136,
      0.06044765189290047,
      -0.00805969163775444,
      0.00681157223880291,
      0.022048424929380417,
      -0.0035206512548029423,
      -0.02933736890554428,
      0.00779053196310997,
      -0.05877666547894478,
      -0.01206957921385765,
      0.0039062320720404387,
      0.041781459003686905,
      0.02687816508114338,
      0.05707116425037384,
      0.012811131775379181,
      0.008059156127274036,
      0.01705048605799675,
      0.027086623013019562,
      -0.043206047266721725,
      0.05619004741311073,
      0.0134268244728446,
      0.08855251967906952,
      -0.10107256472110748,
      -0.0316082201898098,
      -0.005645190831273794,
      0.042868103832006454,
      0.005127206444740295,
      0.10213539749383926,
      -0.07805926352739334,
      0.030650975182652473,
      -0.033896151930093765,
      -0.04523352533578873,
      0.08331382274627686,
      0.003275404218584299,
      0.019035140052437782,
      -0.022166190668940544,
      0.005690570920705795,
      -0.03540137782692909,
      -0.04728610813617706,
      -0.045318324118852615,
      0.043868083506822586,
      -0.0028126812539994717,
      -0.06391854584217072,
      -0.06912549585103989,
      0.07018622756004333,
      -0.05362395569682121,
      -0.0861767902970314,
      -0.03478863835334778,
      -0.018361691385507584,
      -0.052394986152648926,
      0.03751521185040474,
      0.05551254004240036,
      -0.012659497559070587,
      -0.02505246177315712,
      -0.04513592645525932,
      -0.055519863963127136,
      0.004605751484632492,
      0.02116488292813301,
      -0.05940936878323555,
      0.1752980351448059,
      -0.04252440854907036,
      -0.015964802354574203,
      -0.05233292654156685,
      -0.032233644276857376,
      -0.004619660321623087,
      -0.018461745232343674,
      -0.04537557065486908,
      -0.09426431357860565,
      -0.015618868172168732,
      -0.0008908856543712318,
      0.030880369246006012,
      0.009665895253419876,
      0.04865781217813492,
      -0.05942969396710396,
      0.022409677505493164,
      0.005184332840144634,
      0.02349216863512993,
      0.0846824198961258,
      -0.06168331578373909,
      -0.046277038753032684,
      -0.00660117669031024,
      -0.01406177133321762,
      0.0021914015524089336,
      -0.02741178683936596,
      -0.05653855949640274,
      -0.07997959107160568,
      0.03752958029508591,
      -0.033476900309324265,
      -0.0712926909327507,
      0.03084634616971016,
      0.02246311865746975,
      -0.04116538166999817,
      -0.038849178701639175,
      -0.049698568880558014,
      -0.04106413573026657,
      -0.08425605297088623,
      0.05315794050693512,
      0.008071258664131165,
      -0.06575854867696762,
      -0.03435623273253441,
      -0.06417272239923477,
      -0.013168913312256336,
      0.0975695326924324,
      -0.05209972709417343,
      0.04727276787161827,
      -0.019096890464425087,
      0.03441883996129036,
      0.06905911862850189,
      0.055157676339149475,
      0.029944956302642822,
      0.026920607313513756,
      0.021736910566687584,
      0.049746520817279816,
      -0.05573501065373421,
      0.019146736711263657,
      -0.001365247298963368,
      -0.010173615999519825,
      0.04638410732150078,
      -0.01881740242242813,
      0.08913049846887589,
      0.04618328437209129,
      0.037725143134593964,
      -0.09042514115571976,
      0.04224972799420357,
      -0.0014543109573423862,
      0.03135175630450249,
      0.0059263212606310844,
      -0.0033990421798080206,
      0.017246469855308533,
      -0.01212526299059391,
      0.036373402923345566,
      0.06606850028038025,
      0.04476536437869072,
      -0.014497783966362476,
      0.006269663572311401,
      0.04150974005460739,
      0.010472727008163929,
      0.022427616640925407,
      -0.013369971886277199,
      -0.07228323817253113,
      0.06963545083999634,
      -0.05083511769771576,
      -0.012853438965976238,
      -0.014998499304056168,
      -0.04242978245019913,
      -0.013601182028651237,
      -0.10135657340288162,
      0.03648371994495392,
      -0.03219931572675705,
      0.02011347934603691,
      0.015197455883026123,
      0.07850491255521774,
      0.04663824290037155,
      0.031625788658857346,
      -0.05877077579498291,
      0.03373498097062111,
      0.06741797178983688,
      0.032863788306713104,
      -0.04441124573349953,
      -0.07205145806074142,
      -0.10300954431295395,
      -0.00399896502494812,
      0.015226403251290321,
      -0.005968653596937656,
      0.03105749562382698,
      0.014958560466766357,
      0.06746175140142441,
      -0.011476658284664154,
      -0.0354958176612854,
      -0.052338145673274994,
      -5.542603321373463e-05,
      -0.046256788074970245,
      0.09248723089694977,
      -0.08720794320106506,
      0.003269256791099906,
      -0.027775732800364494,
      0.004429019056260586,
      -0.02086918242275715,
      -0.027770107612013817,
      0.04011049121618271,
      0.015621110796928406,
      -0.0031292864587157965,
      0.03036281280219555,
      0.05637577176094055,
      -0.008850504644215107,
      0.057674769312143326,
      -0.03771848976612091,
      -0.046878185123205185,
      -0.025455627590417862,
      -0.04671718180179596,
      -0.0423433817923069,
      0.0026938661467283964,
      0.00454813614487648,
      -0.01916232332587242,
      -0.028150778263807297,
      -0.025286966934800148,
      0.045848846435546875,
      -0.05935293436050415,
      0.03757272660732269,
      0.13827861845493317,
      0.0033505146857351065,
      -0.01977199874818325,
      0.04356381297111511,
      -0.08081270009279251,
      0.03499399498105049,
      -0.037732455879449844,
      0.012207292951643467,
      0.014613996259868145,
      -0.03377758339047432,
      0.05463169142603874,
      0.04351744055747986,
      0.06771834939718246,
      -0.01066731009632349,
      -0.049660421907901764,
      -0.019307339563965797,
      0.01245780847966671,
      -0.09042376279830933,
      0.020447442308068275,
      0.03659699484705925,
      -0.03957023471593857,
      -0.04651752486824989,
      0.03581687808036804,
      0.051168929785490036,
      -0.027046743780374527,
      0.10147114843130112,
      -0.02385103516280651,
      0.04465475305914879,
      -0.0009656703914515674,
      -0.017498111352324486,
      -0.01902042329311371,
      0.024547914043068886,
      0.017838777974247932,
      -0.15345564484596252,
      0.0347636416554451,
      -0.0690537691116333,
      0.0559832826256752,
      0.025627927854657173,
      0.044430531561374664,
      -0.06942634284496307,
      -0.03792239725589752,
      -0.013586239889264107,
      -0.06508024781942368,
      0.07990602403879166,
      0.03110150434076786,
      -0.012967252172529697,
      0.034405071288347244,
      0.0073598697781562805,
      -0.020068487152457237,
      -0.020005227997899055,
      -0.013576709665358067,
      0.046518608927726746,
      0.05182654410600662,
      -0.02919992431998253,
      -0.006773808505386114,
      0.0197061188519001,
      0.03603968024253845,
      -0.0029907100833952427,
      0.07980629801750183,
      -0.07279430329799652,
      -0.028353529050946236,
      -0.07244659960269928,
      -0.018865177407860756,
      0.07997894287109375,
      0.05634656921029091,
      -0.006242221686989069,
      -0.03128630295395851,
      0.006293143145740032,
      -0.05205535143613815,
      -0.02174583077430725,
      -0.007664231117814779,
      0.03022710606455803,
      -0.015245800837874413,
      0.06957356631755829,
      -0.019543569535017014,
      0.009916295297443867,
      -0.05140545219182968,
      -0.012108833529055119,
      -0.012055166997015476,
      -0.10195332765579224,
      0.062494680285453796,
      0.05753691866993904,
      -0.011428574100136757,
      0.019999809563159943,
      -0.03876285254955292,
      0.0321260429918766,
      -0.02948739007115364,
      -0.031047867611050606,
      -0.034431587904691696,
      -0.036411482840776443,
      0.05771826207637787,
      -0.0773855596780777,
      -0.005461185239255428,
      0.06563370674848557,
      -0.004871528595685959,
      -0.055261071771383286,
      0.057816799730062485,
      -0.0789138600230217,
      0.03888843581080437,
      -0.01574370451271534,
      0.026269765570759773,
      -0.026479670777916908,
      -0.030917495489120483,
      0.011434754356741905,
      0.0439227931201458,
      -0.01995394378900528
    ],
    [
      -0.06603269279003143,
      -0.018836842849850655,
      -0.0064203692600131035,
      -0.017780346795916557,
      -0.020207174122333527,
      -0.0359918475151062,
      0.03542042151093483,
      0.02153833955526352,
      0.04642229154706001,
      -0.04624614864587784,
      0.028928132727742195,
      0.04969446733593941,
      -0.08312753587961197,
      -0.017288971692323685,
      0.09087546169757843,
      -0.029623383656144142,
      0.005244415253400803,
      -0.06278958916664124,
      -0.03128010407090187,
      0.0006293333135545254,
      0.006257967557758093,
      -0.08092440664768219,
      -0.034262023866176605,
      -0.07517404854297638,
      -0.021941684186458588,
      -0.053490933030843735,
      0.030415449291467667,
      -0.006493167951703072,
      -0.0011854891199618578,
      0.08840557187795639,
      -0.017784358933568,
      -0.04131130874156952,
      -0.003140852088108659,
      -0.026248596608638763,
      -0.060882583260536194,
      -0.01931951940059662,
      0.07057106494903564,
      0.026693720370531082,
      0.022710705175995827,
      0.017667600885033607,
      -0.05483755096793175,
      0.02135441266000271,
      -0.03960195928812027,
      -0.04191670939326286,
      0.015401150099933147,
      0.00012446549953892827,
      -0.02870369888842106,
      0.0026420806534588337,
      -0.02286623790860176,
      -0.016257034614682198,
      0.048942629247903824,
      -0.03412945196032524,
      0.04346485808491707,
      0.04145402833819389,
      -0.02119266986846924,
      0.014445585198700428,
      0.04113352298736572,
      0.08022148907184601,
      0.04170846939086914,
      0.11150624603033066,
      -0.06849092245101929,
      -0.020659862086176872,
      -0.034217849373817444,
      0.02576383948326111,
      0.05227228254079819,
      -0.016903100535273552,
      -0.0044356053695082664,
      0.019676122814416885,
      -0.021224789321422577,
      0.005031528417021036,
      -0.01569271646440029,
      -0.010053974576294422,
      -0.0626586526632309,
      -0.0390021987259388,
      -0.010794625617563725,
      0.06526394933462143,
      -0.056068312376737595,
      -0.03628194332122803,
      -0.010750353336334229,
      0.01685098186135292,
      0.0375058688223362,
      0.016010718420147896,
      -0.13528411090373993,
      -0.0014077355153858662,
      0.04816713556647301,
      -0.04181499034166336,
      0.0865655317902565,
      0.016303494572639465,
      0.0562271922826767,
      -0.06622010469436646,
      0.09038512408733368,
      0.034898266196250916,
      0.0325097031891346,
      0.04687120020389557,
      0.05102642625570297,
      0.04445159435272217,
      -0.006929858587682247,
      -0.03452228009700775,
      0.08025216311216354,
      0.035063087940216064,
      -0.008584891445934772,
      -0.015023409388959408,
      0.06127709522843361,
      -0.04541158676147461,
      -0.0045108734630048275,
      -0.0005330576677806675,
      -0.01802743598818779,
      0.0018896587425842881,
      0.021813735365867615,
      -0.0727434754371643,
      0.015359190292656422,
      -0.035035863518714905,
      -0.024681856855750084,
      -0.019792191684246063,
      0.07386514544487,
      0.04620116576552391,
      -0.0463639497756958,
      -0.05478030443191528,
      -0.013073410838842392,
      0.03360358998179436,
      0.03136065974831581,
      -0.05499803274869919,
      -0.011671210639178753,
      -0.017554370686411858,
      0.048159632831811905,
      0.05257686227560043,
      0.012113581411540508,
      -0.024142149835824966,
      -0.027883494272828102,
      0.1093708872795105,
      0.021935714408755302,
      -0.04335014894604683,
      0.029442543163895607,
      -0.027886580675840378,
      -0.020973844453692436,
      0.0392928384244442,
      -0.03186793625354767,
      -0.06429184228181839,
      0.03702433779835701,
      -0.03188953176140785,
      -0.057779356837272644,
      0.06057978421449661,
      0.011573312804102898,
      -0.058997463434934616,
      0.026044633239507675,
      -0.012232918292284012,
      -0.05968702957034111,
      -0.006254799198359251,
      -0.02039763517677784,
      0.01673261821269989,
      -0.07976844161748886,
      0.009685899131000042,
      0.027471892535686493,
      -0.038453441113233566,
      0.009458372369408607,
      -0.001753075746819377,
      -0.020724747329950333,
      -0.059972893446683884,
      0.005536247976124287,
      0.03135695680975914,
      -0.06159813329577446,
      -0.00265469285659492,
      0.0685824528336525,
      0.005364757031202316,
      -0.029389332979917526,
      -0.006079870276153088,
      0.0902675986289978,
      0.05656930059194565,
      -0.009451978839933872,
      -0.025567026808857918,
      -0.01892719231545925,
      0.003039591945707798,
      0.04714491218328476,
      0.008925511501729488,
      -0.014261986128985882,
      0.025546960532665253,
      0.0012551049003377557,
      0.05041460692882538,
      0.054465435445308685,
      -0.017609070986509323,
      0.03507770970463753,
      -0.036207977682352066,
      0.08744428306818008,
      -0.016249924898147583,
      0.084492526948452,
      0.05767880007624626,
      0.0016746025066822767,
      -0.024165816605091095,
      0.044330235570669174,
      0.016053389757871628,
      0.05111170560121536,
      0.04367606341838837,
      0.033270884305238724,
      -0.033342164009809494,
      -0.03324407339096069,
      -0.033306535333395004,
      -0.040297482162714005,
      -0.05420869216322899,
      0.0486295260488987,
      0.04229546710848808,
      0.001577332615852356,
      -0.04580485075712204,
      0.04157591983675957,
      -0.04462198540568352,
      0.0221925787627697,
      -0.0014007296413183212,
      -0.044298890978097916,
      -0.01757131703197956,
      -0.003968422766774893,
      -0.036398984491825104,
      -0.044314902275800705,
      -0.02305808663368225,
      -0.015556109137833118,
      -0.05775658041238785,
      0.061371564865112305,
      -0.06141919642686844,
      0.08412390947341919,
      0.06444792449474335,
      0.04986810311675072,
      0.07612528651952744,
      0.03351208195090294,
      0.010101701132953167,
      0.046699218451976776,
      -0.026058169081807137,
      0.02574675716459751,
      -0.003046867437660694,
      -0.026469798758625984,
      0.018607348203659058,
      -0.009604697115719318,
      -0.03154749050736427,
      0.06576833128929138,
      0.016068601980805397,
      -0.02445903792977333,
      -0.011334989219903946,
      -0.03385134041309357,
      0.060741011053323746,
      0.03485174477100372,
      0.012718675658106804,
      0.034033115953207016,
      0.03899991512298584,
      -0.034589845687150955,
      -0.0568683035671711,
      0.0033732797019183636,
      -0.016370082274079323,
      -0.0012316395295783877,
      -0.05654112622141838,
      -0.03369028493762016,
      -0.008113272488117218,
      -0.06737788766622543,
      0.013456047512590885,
      0.06358884274959564,
      0.03375547006726265,
      -0.01603255793452263,
      -0.048832885921001434,
      -0.04308202490210533,
      0.030885271728038788,
      -0.036045148968696594,
      0.0730394497513771,
      -0.07979612797498703,
      0.01596096158027649,
      0.025204617530107498,
      0.002792765386402607,
      -0.041136953979730606,
      0.0051906900480389595,
      -0.005734200589358807,
      -0.03250303491950035,
      0.03959934040904045,
      0.01023061666637659,
      -0.0014922976261004806,
      0.08471425622701645,
      -0.014990388415753841,
      -0.04909030720591545,
      0.008787590079009533,
      0.0749436616897583,
      0.026395700871944427,
      0.05541113018989563,
      -0.016474945470690727,
      0.031008049845695496,
      -0.03519487380981445,
      -0.010915741324424744,
      0.07217321544885635,
      -0.043008532375097275,
      0.06264244019985199,
      -0.028198854997754097,
      0.03861837089061737,
      -0.0072380537167191505,
      -0.03319737687706947,
      0.08779893815517426,
      0.011289181187748909,
      0.04853891581296921,
      -0.008140825666487217,
      0.02695392817258835,
      -0.12274231016635895,
      -0.06543321162462234,
      -0.08916806429624557,
      -0.026734881103038788,
      -0.08755352348089218,
      0.019530216231942177,
      0.03637591376900673,
      -0.015897810459136963,
      0.01004344504326582,
      -0.008889332413673401,
      0.045040566474199295,
      0.025309961289167404,
      0.013412554748356342,
      -0.009928601793944836,
      0.04679456353187561,
      -0.031464941799640656,
      -0.004525458440184593,
      0.009104130789637566,
      -0.010872242972254753,
      -0.0008856651838868856,
      0.0268686693161726,
      -0.029303360730409622,
      -0.07086014002561569,
      -0.13752587139606476,
      -0.02955871820449829,
      0.0013533560559153557,
      -0.054809924215078354,
      0.015144787728786469,
      -0.058783769607543945,
      0.09679772704839706,
      -0.03081287071108818,
      0.025903647765517235,
      0.07161310315132141,
      0.03214292600750923,
      -0.012947004288434982,
      -0.057684704661369324,
      0.07018224895000458,
      0.024005673825740814,
      0.03437173366546631,
      -0.012789224274456501,
      -0.03331818804144859,
      -0.011312248185276985,
      0.005977684631943703,
      -0.02096262015402317,
      -0.11191366612911224,
      -0.013990778475999832,
      0.12156535685062408,
      -0.01343995239585638,
      0.06626779586076736,
      0.008992566727101803,
      -0.025563592091202736,
      0.026714589446783066,
      -0.017022887244820595,
      -0.029235826805233955,
      0.014253051020205021,
      0.12722750008106232,
      -0.04453935846686363,
      -0.08229661732912064,
      0.055820923298597336,
      -0.07106579840183258,
      -0.023326169699430466,
      -0.07250040769577026,
      -0.04833550006151199,
      -0.04886367544531822,
      0.046489398926496506,
      -0.014157713390886784,
      -0.0012597821187227964,
      -0.020883403718471527,
      -0.0558154322206974,
      -0.05134924501180649,
      0.003199696773663163,
      -0.03602663055062294,
      0.004295720718801022,
      0.0948207825422287,
      -0.06474078446626663,
      -0.025081833824515343,
      0.057776570320129395,
      -0.04751221835613251,
      0.006936486344784498,
      0.03164466470479965,
      -0.029136130586266518,
      -0.017741413787007332,
      0.0002380179794272408,
      -0.08372882753610611,
      0.004012436605989933,
      -0.021353235468268394,
      -0.026009200140833855,
      -0.062007613480091095,
      -0.04967153072357178,
      -0.06906957179307938,
      -0.01802731119096279,
      -0.012748152948915958,
      -0.03433233126997948,
      -0.011241649277508259,
      -0.07998530566692352,
      0.017524830996990204,
      0.03239041939377785,
      -0.0051192124374210835,
      0.010527219623327255,
      -0.00045822575339116156,
      -0.003994386177510023,
      -0.013367431238293648,
      -0.02342868782579899,
      0.0010390302632004023,
      -0.025977840647101402,
      -0.05050256848335266,
      -0.07417892664670944,
      -0.01106761023402214,
      0.02425294555723667,
      0.013467158190906048,
      0.04484206810593605,
      0.04268413409590721,
      0.022538308054208755,
      0.03196580708026886,
      0.09546143561601639,
      0.03598039224743843,
      -0.020345564931631088,
      -0.003868428058922291,
      0.05429268255829811,
      0.10002511739730835,
      0.04645528271794319,
      -0.048582497984170914,
      0.009431594051420689,
      0.05938238278031349,
      -0.00852996576577425,
      -0.016640108078718185,
      -0.00012182739737909287,
      -0.034510500729084015,
      0.025228872895240784,
      -0.026418743655085564,
      -0.00962307769805193,
      0.035310275852680206,
      -0.04850764572620392,
      0.028079720214009285,
      -0.08398476243019104,
      0.02070446126163006,
      -0.030404990538954735,
      0.04261806607246399,
      0.04316554591059685,
      0.04027782008051872,
      0.06599248200654984,
      0.05260050296783447,
      0.03833626210689545,
      -0.041801560670137405,
      0.014347992837429047,
      0.03999435529112816,
      -0.04560649394989014,
      0.07156230509281158,
      -0.06399331986904144,
      -0.022222131490707397,
      0.049667682498693466,
      -0.006931825540959835,
      -0.07794201374053955,
      0.025786500424146652,
      -0.054277203977108,
      -0.06380560994148254,
      -0.021813901141285896,
      -0.08084961026906967,
      -0.0529123954474926,
      0.00033389165764674544,
      0.05286851525306702,
      -0.06424461305141449,
      0.01795458421111107,
      0.08709844201803207,
      -0.01791856437921524,
      -0.029953980818390846,
      -0.05384308099746704,
      0.022194527089595795,
      -0.06606867909431458,
      -0.002233105478808284,
      -0.04819001629948616,
      0.03018384613096714,
      -0.04327632859349251,
      -0.02607777528464794,
      0.02190677635371685,
      0.03378697857260704,
      0.07447198778390884,
      0.0003738421655725688,
      -0.018148334696888924,
      -0.029152875766158104,
      0.037970080971717834,
      0.010976072400808334,
      0.02109079249203205,
      -0.052408695220947266,
      0.02615760825574398,
      0.09454501420259476,
      0.09680511802434921,
      -0.03006708063185215,
      -0.0062065087258815765,
      -0.04018649086356163,
      0.04910844564437866,
      0.060047537088394165,
      -0.03649994730949402,
      -0.011297374032437801,
      -0.03847774118185043,
      0.03473984822630882,
      0.016542742028832436,
      0.005934468470513821,
      0.03894023969769478,
      0.031132934615015984,
      -0.0004978482029400766,
      0.01731155440211296,
      -0.06562211364507675,
      -0.07658636569976807,
      -0.043767377734184265,
      0.023810619488358498,
      0.0016297482652589679,
      -0.06125812232494354,
      0.019989635795354843,
      0.010516130365431309,
      -0.07691028714179993
    ],
    [
      -0.09296874701976776,
      0.04650646820664406,
      0.008329866454005241,
      0.04896163567900658,
      0.0033829514868557453,
      -0.0062050349079072475,
      0.03965482860803604,
      0.07227730005979538,
      -0.0405053049325943,
      -0.08774091303348541,
      -0.03870569169521332,
      0.07827838510274887,
      0.020914923399686813,
      -0.021981965750455856,
      0.009745028801262379,
      -0.028298724442720413,
      -0.017011845484375954,
      -0.04562638700008392,
      -0.0020637225825339556,
      0.08602342754602432,
      -0.03457934036850929,
      -0.015306750312447548,
      0.012104255147278309,
      0.020734630525112152,
      0.06770472228527069,
      0.01033862680196762,
      0.029944084584712982,
      0.004016092512756586,
      -0.06058889999985695,
      -0.04767965152859688,
      -0.0778489038348198,
      0.019673308357596397,
      -0.0335671566426754,
      -0.05478402599692345,
      -0.0005475860089063644,
      -0.022635798901319504,
      0.021450567990541458,
      0.005712923593819141,
      0.020837385207414627,
      0.026581114158034325,
      0.07588768750429153,
      0.047416914254426956,
      -0.10214501619338989,
      0.04267805442214012,
      0.010250951163470745,
      -0.04249824211001396,
      0.03893651068210602,
      0.0193877425044775,
      -0.03380586951971054,
      0.05351296067237854,
      -0.04225226491689682,
      0.03035801835358143,
      0.06177437677979469,
      0.06954128295183182,
      -0.008556694723665714,
      0.024652797728776932,
      0.08497585356235504,
      0.04750446975231171,
      0.056294068694114685,
      0.054574210196733475,
      -0.03154719993472099,
      -0.05752703174948692,
      0.020942576229572296,
      -0.03434987738728523,
      0.027794139459729195,
      -0.05528877675533295,
      -0.03877384960651398,
      0.001292953616939485,
      -0.00247906893491745,
      -0.005721259396523237,
      0.06230362877249718,
      -0.01162688434123993,
      0.07735400646924973,
      0.009567545726895332,
      -0.01807267777621746,
      0.02326788567006588,
      -0.000549936608877033,
      0.06264885514974594,
      -0.018070369958877563,
      -0.05745971202850342,
      0.02391640841960907,
      0.020353928208351135,
      0.0673976019024849,
      0.06636077910661697,
      -0.001873783185146749,
      -0.010011511854827404,
      -0.032983627170324326,
      -0.008114234544336796,
      0.013506559655070305,
      -0.08495976030826569,
      0.022231150418519974,
      -0.002675037132576108,
      0.005732559598982334,
      0.004958190489560366,
      0.02249467745423317,
      -0.011605494655668736,
      0.02340206876397133,
      0.01882472075521946,
      0.04194891080260277,
      -0.033211588859558105,
      -0.02493046596646309,
      0.06617419421672821,
      -0.05627162382006645,
      0.06738681346178055,
      0.004901384003460407,
      -0.030678652226924896,
      0.0726340040564537,
      -0.03555572032928467,
      0.09278559684753418,
      -0.09118062257766724,
      0.05509331822395325,
      -0.0020486258435994387,
      -0.0033031634520739317,
      -0.01428993884474039,
      -0.015077941119670868,
      0.010655001737177372,
      0.00856817327439785,
      0.006694702431559563,
      -0.08473740518093109,
      -0.05281809717416763,
      -0.06069827079772949,
      0.05553525686264038,
      -0.04397448152303696,
      -0.02050369419157505,
      -0.05868169292807579,
      -0.06515087187290192,
      -0.044641003012657166,
      -0.04434824362397194,
      -0.035305168479681015,
      -0.0019579012878239155,
      -0.068746417760849,
      -0.033369358628988266,
      0.019839178770780563,
      -0.01651727594435215,
      -0.03638969361782074,
      -0.041179921478033066,
      0.07717492431402206,
      0.037910815328359604,
      -0.02887318655848503,
      -0.027847228571772575,
      0.014113232493400574,
      0.02582627162337303,
      0.15434938669204712,
      0.0051439800299704075,
      0.04670456796884537,
      0.06200998276472092,
      -0.04374665394425392,
      0.019613109529018402,
      1.555961716803722e-06,
      -0.004316643346101046,
      0.008930659852921963,
      -0.05198565125465393,
      -0.009641434997320175,
      -0.11069496721029282,
      0.03654509782791138,
      0.0014806874096393585,
      0.028442546725273132,
      -0.07473099231719971,
      -0.008334529586136341,
      0.002071056980639696,
      -0.008295809850096703,
      0.0293654166162014,
      -0.07813198864459991,
      0.04377460479736328,
      -0.044523097574710846,
      0.014153731055557728,
      -0.03134443238377571,
      0.02557283826172352,
      0.007208622992038727,
      -0.10573872923851013,
      0.03976049646735191,
      0.013900301419198513,
      -0.024554289877414703,
      0.01450837217271328,
      0.02792450040578842,
      0.006795009132474661,
      -0.02512924186885357,
      0.01769225299358368,
      -0.019280781969428062,
      -0.015563617460429668,
      0.057454921305179596,
      -0.021342774853110313,
      0.0557950921356678,
      0.0017706631915643811,
      -0.036352429538965225,
      0.022233769297599792,
      -0.04352337121963501,
      -0.03250252082943916,
      0.05945032462477684,
      -0.01874660514295101,
      -0.01513372641056776,
      -0.0010751833906397223,
      0.03811614587903023,
      -0.0462346188724041,
      -0.0019066891400143504,
      0.06013430655002594,
      0.02285853587090969,
      0.0015750530874356627,
      0.06820675730705261,
      0.01872987300157547,
      0.1364237368106842,
      -0.03037579171359539,
      0.001609521801583469,
      -0.006545291282236576,
      -0.01972109265625477,
      0.06892991811037064,
      0.07271382212638855,
      0.07983998954296112,
      -0.049917176365852356,
      0.014085444621741772,
      -0.029716918244957924,
      0.015697499737143517,
      -0.06711027771234512,
      -0.06482549011707306,
      0.01558284368366003,
      0.007176888175308704,
      0.01600837893784046,
      -0.07331248372793198,
      0.01730842888355255,
      -0.005542621482163668,
      0.017380395904183388,
      -0.025901297107338905,
      0.01942916214466095,
      0.09675437211990356,
      -0.03876979649066925,
      -0.06184825301170349,
      0.01921043172478676,
      -0.07902910560369492,
      0.06253008544445038,
      0.030500881373882294,
      0.10695523768663406,
      0.001226284308359027,
      -0.0069235325790941715,
      0.007993480190634727,
      -0.002768871607258916,
      -0.08762180805206299,
      0.042228199541568756,
      0.013165970332920551,
      0.03377837315201759,
      0.027182623744010925,
      0.022227119654417038,
      0.04457814246416092,
      0.006072042975574732,
      -0.016444608569145203,
      0.08076581358909607,
      -0.06444566696882248,
      0.04243406653404236,
      -0.02608715556561947,
      0.013035977259278297,
      -0.03672369197010994,
      -0.03671702370047569,
      0.022018058225512505,
      0.060396455228328705,
      -0.06951223313808441,
      0.10489073395729065,
      0.02549884468317032,
      0.07458868622779846,
      0.02288113161921501,
      0.019353583455085754,
      -0.04634784907102585,
      0.08176636695861816,
      -0.11845412105321884,
      -0.04505550116300583,
      -0.03050667978823185,
      0.022097015753388405,
      -0.013506255112588406,
      0.05788011848926544,
      0.05713292956352234,
      -0.011065082624554634,
      -0.05211157724261284,
      0.04085361585021019,
      -0.02996569685637951,
      -0.04256270453333855,
      0.05627835914492607,
      -0.004616912920027971,
      -0.045605748891830444,
      0.02480410970747471,
      0.013520716689527035,
      0.07761320471763611,
      -0.012247717007994652,
      0.052096474915742874,
      -0.010846070945262909,
      -0.048382557928562164,
      -0.07164453715085983,
      -0.09018725156784058,
      0.0029666339978575706,
      0.01703488454222679,
      -0.08080382645130157,
      -0.02225683629512787,
      0.03431323915719986,
      -0.004278174135833979,
      0.03644855320453644,
      0.01964631862938404,
      -0.02324477583169937,
      0.01391132827848196,
      -0.009044937789440155,
      0.07937309145927429,
      0.020719097927212715,
      0.057894740253686905,
      0.12839511036872864,
      -0.018118631094694138,
      0.001961664529517293,
      -0.04185815528035164,
      -0.03205094859004021,
      -0.11655588448047638,
      0.03271006420254707,
      -0.02913154475390911,
      0.05642424896359444,
      0.020779021084308624,
      0.0659283772110939,
      0.05340532585978508,
      -0.008620941080152988,
      0.03557857871055603,
      0.0021390351466834545,
      0.02081340178847313,
      0.012196209281682968,
      0.006228586658835411,
      -0.041619714349508286,
      -0.005093468353152275,
      -0.0613708421587944,
      -0.005676676984876394,
      0.013038086704909801,
      -0.013244527392089367,
      -0.01019312534481287,
      -0.03966372460126877,
      -0.0206002164632082,
      0.026950271800160408,
      -0.016241703182458878,
      -0.02619636058807373,
      0.08084254711866379,
      0.0350697822868824,
      0.007823840714991093,
      0.04770100116729736,
      0.07879213988780975,
      0.05045921728014946,
      -0.03550582379102707,
      0.011923585087060928,
      0.07425721734762192,
      0.04313600808382034,
      -0.00715292152017355,
      0.05461600795388222,
      -0.01975703239440918,
      -0.006953143049031496,
      0.03557772561907768,
      0.007081927731633186,
      0.001919555477797985,
      -0.056720759719610214,
      0.07572874426841736,
      -0.02797212265431881,
      0.07221619784832001,
      0.06494764238595963,
      0.016331937164068222,
      -0.031490255147218704,
      -0.05579196289181709,
      -0.01628168113529682,
      0.005756605416536331,
      0.01479386631399393,
      0.010083720088005066,
      0.013733207248151302,
      0.05983299762010574,
      0.042999472469091415,
      0.007701513357460499,
      0.01132481824606657,
      0.022477788850665092,
      -0.06463848054409027,
      0.07297328859567642,
      0.05043329671025276,
      0.006072720512747765,
      0.02023404650390148,
      -0.04177140071988106,
      0.03653460741043091,
      0.027896801009774208,
      0.025312473997473717,
      0.023647356778383255,
      0.02374323457479477,
      -0.012982125394046307,
      0.04959049075841904,
      -0.06255877763032913,
      -0.013456566259264946,
      0.002815860789269209,
      -0.044431790709495544,
      0.05158908665180206,
      0.04134374484419823,
      0.05736403167247772,
      0.032904401421546936,
      -0.009506851434707642,
      0.04363805428147316,
      -0.039438627660274506,
      0.0007030533161014318,
      0.018303530290722847,
      -0.0426657609641552,
      -0.04028273746371269,
      -0.06876696646213531,
      -0.04029885679483414,
      0.0051390500739216805,
      -0.026505358517169952,
      -0.01935943216085434,
      -0.035374145954847336,
      0.061552248895168304,
      0.05635545402765274,
      -0.045876987278461456,
      0.0030877278186380863,
      0.018378112465143204,
      -0.058340609073638916,
      0.013340964913368225,
      0.04557598754763603,
      0.06810880452394485,
      -0.01596967689692974,
      -0.03649720922112465,
      0.0796588584780693,
      -0.041643865406513214,
      0.0510127916932106,
      0.0008141709840856493,
      0.030177505686879158,
      -0.00916068535298109,
      -0.011852864176034927,
      -0.030882451683282852,
      -0.008078576996922493,
      0.019937993958592415,
      0.050171300768852234,
      0.03747041895985603,
      0.018424464389681816,
      -0.08001336455345154,
      -0.023240679875016212,
      -0.03623710572719574,
      0.01768581196665764,
      -0.012268327176570892,
      -0.05703698843717575,
      0.0234662014991045,
      -0.057267170399427414,
      0.03481847792863846,
      0.01419590599834919,
      -0.038053128868341446,
      -0.0003390285128261894,
      -0.01134075690060854,
      0.0175556018948555,
      -0.03913616016507149,
      -0.014838882721960545,
      -0.04053901880979538,
      -0.016742566600441933,
      -0.018641680479049683,
      0.018462805077433586,
      -0.06264521926641464,
      0.0046362364664673805,
      -0.013543659821152687,
      -0.013646570965647697,
      0.017513088881969452,
      0.011826626025140285,
      0.04428750276565552,
      -0.023209409788250923,
      0.0010516357142478228,
      0.010122968815267086,
      -0.05186561122536659,
      -0.07993144541978836,
      -0.006799362134188414,
      0.06390739977359772,
      0.08530590683221817,
      0.06606746464967728,
      0.028007827699184418,
      -0.06630618125200272,
      -0.08165339380502701,
      -0.035969607532024384,
      0.057389404624700546,
      -0.04885055869817734,
      0.007266880478709936,
      -0.0827740952372551,
      -0.07787037640810013,
      -0.0006929939263500273,
      0.019980713725090027,
      -0.01081763207912445,
      0.029637297615408897,
      -0.02076573669910431,
      -0.0403902493417263,
      -0.004077894147485495,
      -0.03095475770533085,
      -0.065300852060318,
      0.03570286184549332,
      -0.016086144372820854,
      -0.03154158964753151,
      -0.0809551253914833,
      -0.02072739414870739,
      0.018110038712620735,
      -0.03024594485759735,
      -0.07089534401893616,
      -0.035680141299963,
      -0.01945926994085312,
      -0.06555546820163727,
      0.0810963436961174,
      0.014814500696957111,
      0.01888173632323742,
      -0.028304878622293472,
      0.003917017485946417,
      0.0074493782594799995,
      0.04017088562250137,
      -0.008027631789445877,
      -0.056532058864831924,
      0.07204228639602661,
      0.027163101360201836,
      -0.010120190680027008,
      -0.01832100749015808,
      -0.0406864657998085,
      0.026405522599816322,
      -0.001807995024137199
    ],
    [
      -0.0032882217783480883,
      -0.030334044247865677,
      0.026202848181128502,
      0.04433128610253334,
      -0.003923184238374233,
      -0.06419892609119415,
      0.0036362744867801666,
      0.010193845257163048,
      0.037551168352365494,
      -0.003784971544519067,
      -0.004889057017862797,
      -0.010204729624092579,
      0.00576392374932766,
      0.03422117605805397,
      -0.08560483902692795,
      0.05742327868938446,
      0.03125070407986641,
      0.007281277794390917,
      0.010015967302024364,
      0.01810821145772934,
      0.01138315349817276,
      -0.05059374123811722,
      -0.04600236937403679,
      -0.008797068148851395,
      0.0186168160289526,
      0.0009540345636196434,
      -0.05643310770392418,
      0.07806980609893799,
      -0.035496845841407776,
      -0.018364649266004562,
      -0.010195251554250717,
      0.05566612631082535,
      0.013802890665829182,
      0.060714706778526306,
      -0.05504818260669708,
      -0.05603896081447601,
      0.01464812457561493,
      0.018438635393977165,
      0.05096305161714554,
      -0.05139466002583504,
      -0.02428792044520378,
      -0.07478884607553482,
      -0.05614497512578964,
      -0.07321435958147049,
      0.0363464429974556,
      -0.02714141272008419,
      -0.015775810927152634,
      -0.054040685296058655,
      0.0644187182188034,
      -0.0147078363224864,
      -0.028981897979974747,
      0.06419946998357773,
      -0.12274465709924698,
      -0.10561660677194595,
      0.023099876940250397,
      0.13782508671283722,
      0.03140442073345184,
      -0.015162333846092224,
      0.04607606306672096,
      0.0026616095565259457,
      -0.0949740931391716,
      -0.011183308437466621,
      0.0014281905023381114,
      -0.05095555633306503,
      -0.0034625993575900793,
      0.007608495187014341,
      0.048885639756917953,
      -0.040153708308935165,
      -0.0003431260120123625,
      -0.05417514964938164,
      0.023676058277487755,
      -0.10001315176486969,
      -0.053244125097990036,
      0.0477730967104435,
      -0.03372274339199066,
      -0.04390992224216461,
      -0.04903901740908623,
      -0.05150829255580902,
      0.025558480992913246,
      0.001098831300623715,
      -0.025069626048207283,
      -0.03144657984375954,
      0.0062447162345051765,
      0.01208414789289236,
      -0.04146156832575798,
      0.0483613945543766,
      0.02251962199807167,
      0.028530137613415718,
      -0.07673007249832153,
      -0.00940802413970232,
      0.022524766623973846,
      0.028273334726691246,
      0.016407787799835205,
      -0.06966803967952728,
      0.02060295268893242,
      0.013848353177309036,
      0.015878135338425636,
      -0.05244225636124611,
      -0.10632553696632385,
      -0.0810202807188034,
      -0.06147056818008423,
      -0.02488081529736519,
      0.016849230974912643,
      0.04298347979784012,
      0.05380614101886749,
      0.04042051360011101,
      0.018562491983175278,
      -0.018951062113046646,
      -0.0824916735291481,
      -0.13709968328475952,
      0.05074623227119446,
      -0.04522871598601341,
      0.08944816142320633,
      -0.000987046048976481,
      -0.02765107899904251,
      0.01593911647796631,
      0.03093089535832405,
      0.050442639738321304,
      0.04852239787578583,
      -0.10439006984233856,
      -0.0038523783441632986,
      -0.07382738590240479,
      0.023077839985489845,
      0.0005718618631362915,
      -0.09861249476671219,
      -0.09464504569768906,
      -0.05353163182735443,
      -0.009724325500428677,
      0.02118132822215557,
      0.08159332722425461,
      0.005934302229434252,
      0.046801239252090454,
      -0.008524022996425629,
      0.0002867216244339943,
      0.10260943323373795,
      -0.025102248415350914,
      -0.05828849598765373,
      -0.04531681910157204,
      -0.04100969806313515,
      0.008324570953845978,
      0.029639223590493202,
      0.12335536628961563,
      -0.06567538529634476,
      0.050918132066726685,
      0.009342808276414871,
      0.03700801730155945,
      0.01661836728453636,
      0.032068196684122086,
      -0.07543644309043884,
      0.08841713517904282,
      -0.01426530722528696,
      -0.036689627915620804,
      -0.05628687143325806,
      0.006325978320091963,
      0.07938110083341599,
      0.020344184711575508,
      -0.009119049645960331,
      0.011058401316404343,
      0.035954203456640244,
      0.044852565973997116,
      -0.024810802191495895,
      -0.011388950049877167,
      -0.0752471536397934,
      0.09890784323215485,
      0.03884333372116089,
      0.02317957580089569,
      -0.0008895350038073957,
      -0.027936793863773346,
      -0.061758484691381454,
      0.016426531597971916,
      -0.05652546510100365,
      -0.08742645382881165,
      -0.06271149218082428,
      -0.05950967222452164,
      0.055605921894311905,
      -0.030251922085881233,
      0.06372889131307602,
      0.06856200844049454,
      -0.025342989712953568,
      -0.1355445384979248,
      0.015461057424545288,
      0.04279537871479988,
      -0.014275859110057354,
      0.03384882211685181,
      -0.03688424825668335,
      0.020874081179499626,
      -0.01570398174226284,
      -0.02388113923370838,
      -0.009081592783331871,
      0.05389351397752762,
      0.07071077078580856,
      -0.044386014342308044,
      0.06245332583785057,
      -0.058647651225328445,
      -0.013597225770354271,
      0.05618891492486,
      0.07298558950424194,
      0.03753937780857086,
      0.03878318518400192,
      -0.09795733541250229,
      -0.04802297428250313,
      -0.022063445299863815,
      -0.015440147370100021,
      0.0158854890614748,
      0.00652769859880209,
      -0.05855487659573555,
      0.002609145361930132,
      0.0385516956448555,
      -0.06091991439461708,
      0.016623787581920624,
      0.03486403822898865,
      0.055693160742521286,
      0.002408647211268544,
      -0.05851636081933975,
      0.03905206173658371,
      -0.03851133584976196,
      -0.0804159864783287,
      0.08843275159597397,
      0.06686819344758987,
      0.057573236525058746,
      -0.019180351868271828,
      -0.11594831198453903,
      0.07553447037935257,
      0.0041748397052288055,
      -0.06971611827611923,
      -0.020535359159111977,
      -0.022571852430701256,
      0.014494037255644798,
      0.038802001625299454,
      -0.013878155499696732,
      0.12815862894058228,
      -0.014425435103476048,
      -0.002641097642481327,
      0.01823510229587555,
      -0.017127498984336853,
      0.02627326175570488,
      -0.017573010176420212,
      0.04444527253508568,
      0.005275973584502935,
      0.034740205854177475,
      0.06935401260852814,
      -0.008628160692751408,
      0.02760474756360054,
      -0.0662231296300888,
      0.02189912460744381,
      -0.10525419563055038,
      -0.060636308044195175,
      0.040671590715646744,
      -0.004638806451112032,
      -0.024215923622250557,
      -0.06257723271846771,
      -0.11625182628631592,
      -0.0772281289100647,
      -0.04516497254371643,
      -0.049367696046829224,
      -0.03928462788462639,
      -0.06387079507112503,
      0.07760481536388397,
      0.08344540745019913,
      0.02766280248761177,
      -0.062087059020996094,
      0.006471661385148764,
      -0.017575858160853386,
      0.05816521495580673,
      -0.007406671065837145,
      0.0003924043267033994,
      0.008319701999425888,
      0.06634059548377991,
      -0.05822436138987541,
      -0.0137275205925107,
      0.08540256321430206,
      -0.01740773767232895,
      0.0065376171842217445,
      -0.056706927716732025,
      0.017443286255002022,
      -0.028521554544568062,
      -0.002399050397798419,
      0.03607636317610741,
      0.0772511437535286,
      -0.0014349437551572919,
      -0.053736213594675064,
      -0.03728938102722168,
      0.016803544014692307,
      0.013172981329262257,
      0.06174715608358383,
      -0.009668136946856976,
      -0.027946889400482178,
      -0.12189944833517075,
      0.0029351699631661177,
      0.07429628074169159,
      0.0255981907248497,
      -0.05431031808257103,
      -0.015031291171908379,
      0.001977870473638177,
      -0.005938602611422539,
      0.021998483687639236,
      0.002720257733017206,
      0.022684181109070778,
      -0.019550690427422523,
      -0.024603942409157753,
      0.079192154109478,
      0.06466791033744812,
      -0.00533876521512866,
      0.01696074567735195,
      -0.0400811992585659,
      -0.026768064126372337,
      -0.04444088786840439,
      0.01859387569129467,
      0.019671257585287094,
      0.007394697517156601,
      0.0339292511343956,
      -0.00939409714192152,
      0.035460107028484344,
      -0.04976985231041908,
      0.09970495849847794,
      0.004341511055827141,
      0.08825737237930298,
      -0.0009745437419041991,
      -0.03666575998067856,
      0.004066638182848692,
      0.014427220448851585,
      -0.02757338434457779,
      0.011761048808693886,
      -0.07981327176094055,
      -0.018143175169825554,
      -0.07456880807876587,
      0.0016960216453298926,
      0.0018145873909816146,
      0.08440615236759186,
      -0.00567652890458703,
      0.0049482183530926704,
      0.028396347537636757,
      0.013903399929404259,
      -0.01814662292599678,
      0.0531165786087513,
      0.04345385730266571,
      -0.0265613105148077,
      -0.07937447726726532,
      0.04491240158677101,
      -0.04824858903884888,
      -0.10354790836572647,
      0.015233212150633335,
      -0.03321242704987526,
      0.07380416989326477,
      -0.03946441039443016,
      -0.012819585390388966,
      0.021894734352827072,
      -0.034777797758579254,
      -0.057740211486816406,
      -0.057946186512708664,
      0.050628285855054855,
      -0.016281597316265106,
      0.05726361647248268,
      -0.06032804772257805,
      0.034526173025369644,
      0.014836807735264301,
      0.10372654348611832,
      -0.0007101322989910841,
      0.010839799419045448,
      0.039025239646434784,
      -0.030878545716404915,
      0.008736827410757542,
      -0.0077918642200529575,
      -0.04379595071077347,
      -0.03054129146039486,
      -0.028705593198537827,
      0.035124167799949646,
      0.026546625420451164,
      -0.02730605937540531,
      0.027988383546471596,
      0.05239042267203331,
      -0.011722907423973083,
      -0.02396528050303459,
      0.043814294040203094,
      0.0780162513256073,
      -0.02345239743590355,
      -0.016172252595424652,
      -0.0017729770625010133,
      -0.016548125073313713,
      -0.03958630934357643,
      -0.03208174183964729,
      -0.005447646137326956,
      -0.05214015766978264,
      -0.057587288320064545,
      -0.04208236560225487,
      -0.06163729354739189,
      -0.11830238252878189,
      -0.0387033149600029,
      0.0021018076222389936,
      -0.009178326465189457,
      -0.011268588714301586,
      0.04260659217834473,
      0.017105180770158768,
      0.07493047416210175,
      0.03774441033601761,
      -0.014922110363841057,
      0.033093325793743134,
      0.1315779834985733,
      -0.01261182688176632,
      -0.010477962903678417,
      -0.016759585589170456,
      -0.013269484974443913,
      0.10358268767595291,
      0.05377233773469925,
      -0.05165373161435127,
      0.06404814124107361,
      -0.0013896914897486567,
      0.0059892479330301285,
      -0.08627331256866455,
      -0.02654067426919937,
      -0.08340847492218018,
      0.029422102496027946,
      0.004615307319909334,
      0.058272652328014374,
      0.0009161100606434047,
      -0.041530538350343704,
      -0.07505929470062256,
      -0.0024441033601760864,
      0.04349443316459656,
      -0.0663762241601944,
      -0.011137777008116245,
      0.01160812471061945,
      0.019009552896022797,
      -0.03606512397527695,
      0.025477983057498932,
      0.014544998295605183,
      0.03408048301935196,
      0.026067635044455528,
      -0.06691040098667145,
      0.007382480893284082,
      -0.021176720038056374,
      0.05594459921121597,
      0.026957960799336433,
      -0.016676459461450577,
      -0.03219546750187874,
      0.04856089502573013,
      0.051360245794057846,
      0.03697745501995087,
      0.08251575380563736,
      -0.030106335878372192,
      -0.012542166747152805,
      -0.10493675619363785,
      0.016836894676089287,
      -0.028000960126519203,
      0.04652220383286476,
      0.03918075934052467,
      -0.028088079765439034,
      0.02635197900235653,
      -0.02296745777130127,
      0.013729425147175789,
      0.012853098101913929,
      0.01860334724187851,
      0.060417793691158295,
      0.042937345802783966,
      0.0031145939137786627,
      0.07459063827991486,
      -0.07415300607681274,
      -0.01380686555057764,
      -0.05791260302066803,
      0.014056794345378876,
      -0.011620752513408661,
      -0.019006192684173584,
      -0.05336578190326691,
      -0.014663292095065117,
      -0.029786454513669014,
      0.031010005623102188,
      -0.036241352558135986,
      -0.000870325427968055,
      0.05117780715227127,
      0.00815288070589304,
      -0.041469741612672806,
      -0.04325312748551369,
      -0.0454835407435894,
      0.024561645463109016,
      -0.004886887036263943,
      0.05616069585084915,
      0.013374492526054382,
      0.017540978267788887,
      0.03637069836258888,
      -0.03769325464963913,
      0.04348233342170715,
      -0.021843697875738144,
      0.03438820317387581,
      -0.04135878011584282,
      0.0190169345587492,
      0.09106544405221939,
      -0.06373611837625504,
      0.02021227777004242,
      0.0176387969404459,
      0.029323598369956017,
      0.03178445249795914,
      -0.002433860208839178,
      -0.027744904160499573,
      0.026260856539011,
      -0.012859275564551353,
      0.018318835645914078,
      0.01259560789912939,
      0.04546020179986954,
      -0.012171872891485691,
      0.028864828869700432,
      0.0841078832745552,
      0.043737251311540604,
      -0.0718197375535965
    ],
    [
      0.006305882707238197,
      -0.012090999633073807,
      -0.01965791918337345,
      -0.0830892026424408,
      0.032023943960666656,
      0.01548145804554224,
      0.07529021054506302,
      -0.004460391588509083,
      -0.035469502210617065,
      -0.031108222901821136,
      0.0794561430811882,
      -0.09054301679134369,
      0.03911445289850235,
      -0.039340198040008545,
      0.062210071831941605,
      -0.01502247340977192,
      0.06786566972732544,
      -0.01719812862575054,
      0.08091972768306732,
      0.001532782451249659,
      0.01563328132033348,
      -0.062001485377550125,
      0.029677346348762512,
      -0.03964196518063545,
      0.03093661181628704,
      -0.019298367202281952,
      0.015852676704525948,
      -0.09575062990188599,
      -0.006783333607017994,
      -0.0555366575717926,
      0.03314296528697014,
      0.061475448310375214,
      -0.03855366259813309,
      -0.06733441352844238,
      0.029624247923493385,
      0.05250125378370285,
      -0.0007123261457309127,
      0.02399273030459881,
      -0.02600349485874176,
      -0.01654575578868389,
      0.006688599940389395,
      -0.006163933780044317,
      0.013153200969099998,
      0.026133621111512184,
      0.00454946793615818,
      0.03290240094065666,
      0.0781194344162941,
      -0.05359979718923569,
      -0.05590197071433067,
      -0.08040890097618103,
      0.05131753906607628,
      0.012044287286698818,
      -0.0630282312631607,
      0.00792818982154131,
      0.05573873594403267,
      0.08663516491651535,
      -0.007219128776341677,
      0.0982864648103714,
      0.03243207931518555,
      -0.0023163824807852507,
      -0.06032027676701546,
      0.048123136162757874,
      0.021232658997178078,
      -0.1087118610739708,
      -0.010858926922082901,
      -0.016847003251314163,
      -0.017448697239160538,
      0.054230816662311554,
      0.030337335541844368,
      0.04638323932886124,
      -0.06614332646131516,
      0.0023020145017653704,
      0.006206983234733343,
      0.07307249307632446,
      0.021297883242368698,
      0.014685121364891529,
      -0.01608271896839142,
      -0.041705287992954254,
      0.07334514707326889,
      0.02652336284518242,
      0.002021627500653267,
      0.028575990349054337,
      0.031830042600631714,
      -0.004886210896074772,
      -0.012726802378892899,
      0.012350293807685375,
      0.10783961415290833,
      -0.01286351215094328,
      -0.005496445577591658,
      -0.02062305063009262,
      -0.01990997977554798,
      -0.05010681971907616,
      0.06886906176805496,
      -0.05473969876766205,
      0.08203917741775513,
      -0.04136121645569801,
      -0.08638124167919159,
      0.014303313568234444,
      -0.037210218608379364,
      0.0580386258661747,
      0.025722242891788483,
      -0.020107809454202652,
      -0.018010053783655167,
      0.11788273602724075,
      0.027806483209133148,
      0.02868758514523506,
      0.008953944779932499,
      -0.03164934739470482,
      0.0031599372159689665,
      -0.05585935711860657,
      -0.0008359379135072231,
      -0.020276909694075584,
      0.03602715581655502,
      0.03187539428472519,
      0.004505838267505169,
      0.05097556486725807,
      -0.0030265003442764282,
      0.044674210250377655,
      0.11085387319326401,
      0.06376843899488449,
      0.025437140837311745,
      0.05574773624539375,
      -0.0195144210010767,
      0.05125389248132706,
      0.023533960804343224,
      0.005184854846447706,
      -0.016592521220445633,
      0.0804128348827362,
      0.006731791887432337,
      0.04214410111308098,
      -0.08765266090631485,
      0.04931546747684479,
      0.093612901866436,
      0.07132542878389359,
      0.0188796054571867,
      0.009359097108244896,
      -0.0041286940686404705,
      0.02170863188803196,
      0.0442519448697567,
      -0.032425377517938614,
      -0.051916204392910004,
      -0.013152039609849453,
      -0.020823145285248756,
      -0.008668963797390461,
      -0.027732104063034058,
      0.11889374256134033,
      0.04198981821537018,
      -0.04083193466067314,
      -0.09302539378404617,
      0.0023225306067615747,
      -0.08497398346662521,
      0.030485523864626884,
      -0.0037961730267852545,
      0.021611083298921585,
      0.01267851609736681,
      -0.03884264454245567,
      -0.002191483275964856,
      0.007590045221149921,
      0.021356023848056793,
      -0.07389006018638611,
      -0.014129080809652805,
      -0.022210536524653435,
      8.734864968573675e-05,
      -0.05542495474219322,
      -0.0043487162329256535,
      -0.01397642120718956,
      0.11376600712537766,
      0.026905791833996773,
      -0.013920850120484829,
      0.015933901071548462,
      -0.04697513207793236,
      0.05038217455148697,
      -0.04526428133249283,
      0.07573461532592773,
      0.002491084160283208,
      0.07154884934425354,
      0.04273035004734993,
      0.031095443293452263,
      -0.05783231928944588,
      -0.06653480976819992,
      0.06412292271852493,
      0.04190114513039589,
      0.07256898283958435,
      0.08109644055366516,
      0.00932736974209547,
      0.061555877327919006,
      -0.12471704185009003,
      0.051193974912166595,
      0.006325994618237019,
      0.07067473977804184,
      0.023856407031416893,
      -0.009813991375267506,
      -0.0053522163070738316,
      0.03325287625193596,
      0.04400409385561943,
      0.012852822430431843,
      -0.05120314657688141,
      0.04515457898378372,
      -0.037824686616659164,
      -0.0030346026178449392,
      0.03159034624695778,
      -0.036930330097675323,
      0.004218150861561298,
      0.006307700183242559,
      -0.11160262674093246,
      0.04409478232264519,
      0.016651513054966927,
      -0.08482073992490768,
      0.028167668730020523,
      0.006032004952430725,
      -0.1224931925535202,
      -0.08133186399936676,
      -0.11383204907178879,
      -0.050997234880924225,
      -0.017749495804309845,
      0.00979514978826046,
      0.03326081484556198,
      -0.01903362385928631,
      0.0029457237105816603,
      0.14593419432640076,
      0.054344385862350464,
      -0.012697979807853699,
      0.03812933713197708,
      -0.009973397478461266,
      -0.002348700538277626,
      -0.03301879018545151,
      -0.015604851767420769,
      0.007653168402612209,
      0.039980143308639526,
      0.007239190861582756,
      0.10899505764245987,
      -0.0098282964900136,
      0.04346857964992523,
      0.0844721794128418,
      0.08539478480815887,
      0.02898561954498291,
      -0.045473016798496246,
      0.006691777613013983,
      -0.033930107951164246,
      -0.08543381839990616,
      -0.042736925184726715,
      0.07671131193637848,
      0.001218511606566608,
      -0.01807582564651966,
      0.048335764557123184,
      0.038013771176338196,
      0.04761303588747978,
      -0.050508882850408554,
      0.054188597947359085,
      -0.01508539542555809,
      -0.045521147549152374,
      0.031942326575517654,
      0.04238664731383324,
      0.05742287263274193,
      0.05551838502287865,
      -0.046530842781066895,
      -0.10425195097923279,
      0.026853447780013084,
      0.05002473667263985,
      0.06079652160406113,
      0.012970326468348503,
      0.018603483214974403,
      0.0696706771850586,
      0.029008125886321068,
      -0.02531478740274906,
      -0.010329913347959518,
      -0.019897636026144028,
      0.08824043720960617,
      0.0047600711695849895,
      -0.024593651294708252,
      0.15062429010868073,
      0.03973926231265068,
      -0.023544806987047195,
      -0.045151617377996445,
      0.03330136090517044,
      0.06301393359899521,
      0.014474830590188503,
      -0.013282508589327335,
      0.10101278126239777,
      0.024265149608254433,
      0.015356890857219696,
      0.0032612555660307407,
      0.07360868155956268,
      0.05335720628499985,
      0.07977727055549622,
      0.019046861678361893,
      -0.08693616837263107,
      -0.04190972074866295,
      0.0485389344394207,
      0.010424502193927765,
      0.04243858531117439,
      0.04075523093342781,
      0.053090423345565796,
      0.09344428777694702,
      0.003812663722783327,
      -0.015326217748224735,
      -0.04519287496805191,
      -0.01904420368373394,
      -0.015837162733078003,
      -0.015590441413223743,
      -0.08069804310798645,
      -0.04900705814361572,
      -0.025533834472298622,
      -0.0020056229550391436,
      -0.026393238455057144,
      -0.045008886605501175,
      -0.07644419372081757,
      -0.02249211072921753,
      0.0203613992780447,
      -0.059412743896245956,
      0.001975352643057704,
      0.060101259499788284,
      0.010059350170195103,
      -0.10089077800512314,
      -0.05620893836021423,
      0.04455852881073952,
      0.03519805148243904,
      0.008468907326459885,
      -0.0033991544041782618,
      0.014577844180166721,
      0.024312671273946762,
      0.07031399756669998,
      0.022763917222619057,
      0.03716898709535599,
      0.008976444602012634,
      -0.06440430879592896,
      0.034374773502349854,
      -0.06089525297284126,
      0.012740003876388073,
      0.02926689386367798,
      0.036115702241659164,
      0.06882436573505402,
      0.027527181431651115,
      -0.03493316099047661,
      0.03463999181985855,
      0.03550051525235176,
      -0.017804594710469246,
      -0.05421081930398941,
      0.004118337761610746,
      -0.05446842312812805,
      -0.00288247293792665,
      0.004413191229104996,
      -0.01902643032371998,
      -0.020791275426745415,
      0.03240383043885231,
      -0.018855517730116844,
      0.0005782367079518735,
      0.01977718062698841,
      0.02189057320356369,
      -0.005096900276839733,
      0.11405016481876373,
      -0.12873761355876923,
      -0.010540669783949852,
      -0.011335087940096855,
      0.0475434772670269,
      -0.13658507168293,
      -0.005847249645739794,
      -0.03919030353426933,
      -0.03974812477827072,
      0.016543813049793243,
      -0.0246761254966259,
      0.06399212032556534,
      -0.06609489023685455,
      -0.048805803060531616,
      -0.009221595712006092,
      -0.0042197322472929955,
      0.04889819771051407,
      0.005777243059128523,
      -0.09737706184387207,
      -0.03315411135554314,
      0.034156303852796555,
      0.011104864068329334,
      0.047898128628730774,
      -0.013876483775675297,
      -0.02126813679933548,
      -0.0033436173107475042,
      0.028360266238451004,
      0.10441794246435165,
      -0.026235612109303474,
      -0.03203859180212021,
      0.03762843832373619,
      0.00941621232777834,
      0.018760360777378082,
      0.06587672233581543,
      0.017429927363991737,
      -0.07174213975667953,
      -0.008890948258340359,
      0.015490896068513393,
      0.08681003004312515,
      0.053173188120126724,
      -0.06815867871046066,
      -0.046373602002859116,
      -0.002798511413857341,
      0.05908152461051941,
      0.023105556145310402,
      -0.08882416784763336,
      0.0176637414842844,
      0.04758274182677269,
      0.03375517949461937,
      -0.027313925325870514,
      -0.001995901810005307,
      -0.004479450173676014,
      -0.024319659918546677,
      0.03533332049846649,
      -0.09141768515110016,
      -0.020956696942448616,
      -0.04515714570879936,
      0.0036187474615871906,
      -0.01244801003485918,
      -0.07519575953483582,
      -0.06519792973995209,
      0.035689424723386765,
      -0.029193878173828125,
      0.0080998744815588,
      0.042074646800756454,
      -0.08110954612493515,
      -0.026103099808096886,
      -0.04056893289089203,
      -0.06301373243331909,
      0.013376795686781406,
      -0.05150594934821129,
      0.03670118749141693,
      -0.0036170093808323145,
      -0.07162237167358398,
      -0.020070848986506462,
      -0.02496005967259407,
      -0.12896810472011566,
      0.038753680884838104,
      -0.026084907352924347,
      0.054078359156847,
      -0.036346569657325745,
      0.03808337450027466,
      -0.05772895738482475,
      -0.0053656394593417645,
      -0.05949440598487854,
      0.0939301997423172,
      -0.06658946722745895,
      -0.04939175024628639,
      -0.021000152453780174,
      -0.016981003805994987,
      -0.003556997748091817,
      -0.019660709425807,
      -0.0170783381909132,
      0.00641676178202033,
      0.022544564679265022,
      0.020702561363577843,
      -0.04065438732504845,
      0.05582841485738754,
      -0.10467792302370071,
      0.02481422759592533,
      -0.007485547568649054,
      -0.0064003001898527145,
      0.02551523596048355,
      -0.004843143280595541,
      -0.08478467911481857,
      0.02487407810986042,
      0.036572836339473724,
      -0.026505153626203537,
      -0.09046473354101181,
      -0.0710759237408638,
      0.0019250010373070836,
      -0.043329302221536636,
      -0.04581650346517563,
      -0.021431561559438705,
      0.015206372365355492,
      -0.015875723212957382,
      -0.04921584576368332,
      0.03567948192358017,
      0.05635068193078041,
      0.06526491045951843,
      -0.016816789284348488,
      0.02691601775586605,
      0.0723017081618309,
      -0.003823355073109269,
      0.017372341826558113,
      -0.10952592641115189,
      0.0014826908009126782,
      0.0290242787450552,
      -0.05114736407995224,
      -0.0377165786921978,
      -0.003094771411269903,
      -0.05538284406065941,
      0.03400729224085808,
      0.15370088815689087,
      0.05831995978951454,
      -0.01850353740155697,
      0.01767086610198021,
      0.021703381091356277,
      -0.013098949566483498,
      0.01697978936135769,
      -0.03020130842924118,
      -0.013894308358430862,
      0.06864778697490692,
      -0.07657378911972046,
      -0.029553711414337158,
      -0.011259365826845169,
      0.00661441870033741,
      0.04651258885860443,
      -0.028392434120178223,
      -0.02706075832247734,
      -0.11316565424203873,
      -0.02302272990345955,
      -0.06256411969661713
    ],
    [
      -0.11882729083299637,
      0.038780130445957184,
      -0.03652201592922211,
      -0.00910772755742073,
      -0.06715543568134308,
      -0.004980301018804312,
      -0.05368907004594803,
      0.05451482534408569,
      0.013379786163568497,
      -0.011919660493731499,
      0.06721345335245132,
      -0.002150349086150527,
      0.010583651252090931,
      0.005127822048962116,
      -0.019052425399422646,
      -0.023111911490559578,
      0.0077346679754555225,
      -0.059009671211242676,
      0.00821108091622591,
      0.01811828836798668,
      -0.024650076404213905,
      -0.02649315446615219,
      0.040857650339603424,
      -0.08193130791187286,
      0.09516985714435577,
      0.00421487120911479,
      0.07012198865413666,
      0.059028491377830505,
      -0.06464473158121109,
      0.08031144738197327,
      0.019063249230384827,
      0.01982732303440571,
      0.036757539957761765,
      0.03022344969213009,
      -0.03171082213521004,
      0.04108588397502899,
      -0.007817638106644154,
      0.011426825076341629,
      0.012975310906767845,
      0.05426797643303871,
      0.02171318419277668,
      -0.04466666281223297,
      0.030369358137249947,
      0.10412973910570145,
      0.028008248656988144,
      0.028579596430063248,
      0.10361582785844803,
      -0.08814903348684311,
      0.03609897941350937,
      -0.03709081560373306,
      -0.03885206580162048,
      0.040786951780319214,
      -0.004468552302569151,
      -0.013841926120221615,
      0.0012807913590222597,
      0.07402941584587097,
      -0.03211943060159683,
      0.07416784018278122,
      0.02165398932993412,
      0.033338580280542374,
      -0.09708958119153976,
      0.02190995402634144,
      0.0077947708778083324,
      -0.0283463504165411,
      -0.08975662291049957,
      0.05485279858112335,
      -0.00591569347307086,
      0.01314275898039341,
      0.012735075317323208,
      0.08958709985017776,
      -0.0494999885559082,
      -0.006121982354670763,
      -0.0674356073141098,
      -0.061601754277944565,
      -0.008810562081634998,
      0.11534419655799866,
      0.0064717847853899,
      -0.004134719725698233,
      0.029179519042372704,
      0.019950021058321,
      -0.024102944880723953,
      -0.04781264066696167,
      0.0045839217491447926,
      0.038436856120824814,
      -0.04198704659938812,
      -0.026071155443787575,
      0.09336643666028976,
      0.0660269483923912,
      -0.0023546030279248953,
      -0.050495583564043045,
      -0.0012050893856212497,
      -0.019123127683997154,
      -0.10421710461378098,
      0.0007985654519870877,
      0.02367047406733036,
      0.0016778282588347793,
      -0.024346161633729935,
      -0.00026265482301823795,
      -0.05037854239344597,
      -0.025826146826148033,
      -0.1800353229045868,
      0.03747362643480301,
      -0.029405968263745308,
      0.03319735452532768,
      -0.004648556467145681,
      -0.021611882373690605,
      0.04718245193362236,
      -0.009634794667363167,
      -0.009790085256099701,
      -0.028354613110423088,
      0.10101659595966339,
      0.0617435984313488,
      -0.12438089400529861,
      -0.01360136829316616,
      0.001985848881304264,
      0.06865578144788742,
      0.02074749767780304,
      0.057414304465055466,
      0.02202041819691658,
      -0.00637187110260129,
      0.04495653882622719,
      -0.13286468386650085,
      -0.022404223680496216,
      0.0017766092205420136,
      -0.03481794148683548,
      0.011417031288146973,
      0.006308448035269976,
      0.011948761530220509,
      -0.06949518620967865,
      -0.0028895947616547346,
      0.002224591327831149,
      0.01318233460187912,
      -0.0889945700764656,
      -0.02602832391858101,
      -0.006222786381840706,
      0.06327024102210999,
      -0.14788420498371124,
      0.05138121172785759,
      -0.07325167953968048,
      0.022357970476150513,
      0.01364295557141304,
      0.058545082807540894,
      0.020318739116191864,
      -0.0033071893267333508,
      0.02265373058617115,
      -0.04931392893195152,
      0.16931352019309998,
      -0.016282232478260994,
      -0.09267200529575348,
      0.07166239619255066,
      -0.0066321371123194695,
      0.0544368140399456,
      -0.025883089751005173,
      0.035467904061079025,
      -0.009403440169990063,
      -0.045603375881910324,
      0.004252573940902948,
      0.056482840329408646,
      -0.014650050550699234,
      -0.00835537351667881,
      0.013485650531947613,
      0.03490438312292099,
      0.058657653629779816,
      -0.011282811872661114,
      0.04313161224126816,
      0.03862542286515236,
      0.004461233038455248,
      0.03269093856215477,
      0.055043984204530716,
      0.02576661854982376,
      0.01088237576186657,
      0.017498072236776352,
      0.054019395262002945,
      0.06053942069411278,
      -0.0374012216925621,
      -0.019247407093644142,
      -0.02840925194323063,
      0.06012272089719772,
      0.05378764122724533,
      -0.03902335837483406,
      0.08988020569086075,
      0.017599154263734818,
      0.05901050567626953,
      -0.01038636825978756,
      0.12025050818920135,
      -0.0485101044178009,
      -0.024704793468117714,
      -0.05191609635949135,
      -0.022860506549477577,
      0.05499134212732315,
      0.025007115676999092,
      0.03100026585161686,
      0.032972950488328934,
      -0.041994187980890274,
      0.015606453642249107,
      0.009909785352647305,
      0.01541529968380928,
      -0.00613413704559207,
      -0.010837795212864876,
      -0.009912315756082535,
      -0.05900230258703232,
      -0.015326625667512417,
      0.005211838521063328,
      -0.014779962599277496,
      -0.06067294254899025,
      0.03426704555749893,
      -0.005709163378924131,
      0.016946326941251755,
      0.002147902734577656,
      0.043391451239585876,
      -0.010465997271239758,
      -0.024060001596808434,
      -0.05657917261123657,
      -0.010303650982677937,
      0.01525354478508234,
      0.021342240273952484,
      -0.027776917442679405,
      -0.03555259108543396,
      0.00820929929614067,
      -0.02337278425693512,
      0.004565812181681395,
      -0.08768557012081146,
      0.0910910815000534,
      0.045551054179668427,
      -0.017527177929878235,
      0.061564311385154724,
      0.014802990481257439,
      -0.04501332715153694,
      0.012890372425317764,
      -0.057818397879600525,
      0.056606847792863846,
      0.05624949932098389,
      0.048052914440631866,
      0.0481131449341774,
      -0.04185221716761589,
      -0.03713180869817734,
      -0.015142609365284443,
      0.024941446259617805,
      0.02227400802075863,
      -0.02328178845345974,
      -0.0182803887873888,
      0.00678251450881362,
      0.01305277831852436,
      -0.031064791604876518,
      0.0427798368036747,
      -0.08432372659444809,
      -0.01466559711843729,
      -0.02005767822265625,
      0.029301410540938377,
      -0.02026570402085781,
      0.03140196576714516,
      -0.07543092221021652,
      0.031017685309052467,
      0.018287742510437965,
      0.028393134474754333,
      0.019713271409273148,
      -0.020066827535629272,
      0.043316736817359924,
      0.042403992265462875,
      -0.034508030861616135,
      0.0808504968881607,
      0.0796964094042778,
      -0.011512154713273048,
      -0.01995631493628025,
      0.02903331257402897,
      0.04031302407383919,
      -0.08346880227327347,
      -0.020212458446621895,
      -0.009072531014680862,
      0.009477338753640652,
      0.11182516068220139,
      0.09335915744304657,
      -0.003372727893292904,
      -0.04508013278245926,
      -0.08385282009840012,
      -0.0027621036861091852,
      -0.043463390320539474,
      0.04120832309126854,
      0.018268700689077377,
      -0.025101112201809883,
      -0.11680428683757782,
      -0.11714321374893188,
      0.058590393513441086,
      -0.11596539616584778,
      0.0002591197262518108,
      -0.016907447949051857,
      -0.049366142600774765,
      0.1573859453201294,
      -0.00335791171528399,
      -0.005034251604229212,
      -0.014387121424078941,
      0.029697956517338753,
      -0.11441579461097717,
      0.022463247179985046,
      0.006119730416685343,
      -0.028660038486123085,
      0.014782963320612907,
      0.03176170215010643,
      0.021985696628689766,
      -0.046207405626773834,
      0.013337741605937481,
      -0.030426641926169395,
      -0.014672290533781052,
      -0.010738775134086609,
      0.08072520047426224,
      -0.004220290575176477,
      -0.07641088217496872,
      -0.05675404891371727,
      -0.014138606376945972,
      -0.06270889937877655,
      0.011850034818053246,
      -0.00019054154108744115,
      0.027942117303609848,
      0.06555957347154617,
      0.008550151251256466,
      -0.11191845685243607,
      0.1059650406241417,
      0.007878730073571205,
      0.10835576057434082,
      -0.026771334931254387,
      0.04839802905917168,
      -0.09761931747198105,
      -2.6092926418641582e-05,
      0.048643648624420166,
      0.037547409534454346,
      -0.0250968299806118,
      0.0331978015601635,
      0.042078401893377304,
      0.04467451199889183,
      0.00321584683842957,
      -0.06263438612222672,
      -0.017543457448482513,
      0.0001107926364056766,
      -0.030787324532866478,
      0.04703933745622635,
      -0.06449934095144272,
      0.03158039227128029,
      0.06221815571188927,
      0.03255528584122658,
      0.015360205434262753,
      -0.0037040060851722956,
      -0.016933107748627663,
      0.041518472135066986,
      -0.06336274743080139,
      0.01563454605638981,
      -0.01865500956773758,
      -0.0488281175494194,
      -0.006560536101460457,
      0.09129200875759125,
      -0.002809088211506605,
      -0.06476414948701859,
      -0.005287633743137121,
      -0.009599041193723679,
      -0.03792617470026016,
      0.004423459991812706,
      0.05626477673649788,
      -0.09061432629823685,
      -0.04622269049286842,
      -0.017446432262659073,
      0.09542078524827957,
      -0.014560635201632977,
      -0.03128531947731972,
      -0.028978249058127403,
      0.04552638530731201,
      -0.08663298934698105,
      -0.02574857883155346,
      -0.02233191207051277,
      0.003630203427746892,
      0.02240077033638954,
      0.06849060952663422,
      -3.2744625059422106e-05,
      -0.0231454037129879,
      0.03005627915263176,
      0.0210651233792305,
      0.062641441822052,
      -0.04740508273243904,
      -0.04687683284282684,
      -0.04133343696594238,
      -0.010012869723141193,
      -0.010382816195487976,
      -0.015059567987918854,
      -0.058746177703142166,
      0.05632646754384041,
      0.026808489114046097,
      0.01623484306037426,
      0.021225033327937126,
      -0.03361258655786514,
      -0.061268556863069534,
      0.008811756037175655,
      0.004199862014502287,
      -0.0057320864871144295,
      -0.004136497154831886,
      0.07580500096082687,
      -0.042983267456293106,
      0.014061559922993183,
      -0.0224167313426733,
      0.016898315399885178,
      0.008444867096841335,
      -0.040030114352703094,
      0.039437633007764816,
      -0.02333197556436062,
      -0.09209755808115005,
      0.012349376454949379,
      -0.06033618003129959,
      0.03191238269209862,
      -0.032545771449804306,
      -0.008758305571973324,
      0.049002666026353836,
      -0.13849149644374847,
      -0.0027503021992743015,
      0.033283308148384094,
      0.05013210326433182,
      -0.020547129213809967,
      0.06649406254291534,
      0.01722443848848343,
      -0.02902272157371044,
      -0.022142156958580017,
      -0.08065954595804214,
      -0.027187516912817955,
      -0.008685648441314697,
      0.0805911272764206,
      0.06149081885814667,
      -0.02122938260436058,
      0.02596912905573845,
      0.07263872772455215,
      -0.04118300601840019,
      0.014927087351679802,
      0.028569182381033897,
      0.06349045038223267,
      0.002939850091934204,
      -0.00967242568731308,
      -0.03178301826119423,
      -0.010540992021560669,
      -0.017405163496732712,
      -0.004571053199470043,
      -0.014894858933985233,
      0.004231480415910482,
      0.02479979209601879,
      0.00789120327681303,
      0.08561630547046661,
      0.03361515700817108,
      -0.07045599073171616,
      -0.09237264841794968,
      -0.05798998847603798,
      -0.00569132762029767,
      -0.014568012207746506,
      -0.004492626991122961,
      0.03729335218667984,
      0.020397275686264038,
      0.03775958716869354,
      -0.021832063794136047,
      0.10680154711008072,
      0.06551223993301392,
      0.0433950275182724,
      -0.011814167723059654,
      0.036196570843458176,
      -0.08182860165834427,
      0.021935930475592613,
      0.004793485626578331,
      0.06121951341629028,
      0.06275170296430588,
      0.047542545944452286,
      -0.0091592688113451,
      -0.03966424986720085,
      -0.015525110997259617,
      -0.021885763853788376,
      -0.055442750453948975,
      -0.004939596634358168,
      -0.02700197510421276,
      0.008709785528481007,
      -0.019592326134443283,
      0.028898034244775772,
      -0.10163631290197372,
      -0.0022150638978928328,
      -0.07394763082265854,
      0.020385300740599632,
      0.08719533681869507,
      0.03169025480747223,
      -0.030452081933617592,
      0.018655741587281227,
      -0.07236060500144958,
      0.08987283706665039,
      0.006749349180608988,
      -0.058248914778232574,
      -0.06090666726231575,
      0.07041291892528534,
      -0.01801934652030468,
      0.03399161994457245,
      -0.06396719068288803,
      0.029638346284627914,
      0.013261854648590088,
      -0.04345674812793732,
      0.012410279363393784,
      -0.03084031119942665,
      0.02902335859835148,
      0.006757440976798534,
      -0.0019080251222476363,
      -0.02701644040644169,
      0.0358135849237442,
      -0.05018603801727295,
      0.006685228087007999,
      0.004711287096142769,
      -0.08279873430728912
    ],
    [
      -0.04619733616709709,
      -0.06582233309745789,
      -0.019638104364275932,
      0.02015850320458412,
      0.03694361820816994,
      0.013801059685647488,
      0.053813617676496506,
      -0.05804400518536568,
      0.07027525454759598,
      0.0909440666437149,
      0.06561743468046188,
      -0.0396839939057827,
      0.03064703568816185,
      0.039964135736227036,
      -0.057191506028175354,
      -0.034621596336364746,
      -0.02539229765534401,
      0.023949284106492996,
      -0.0011182803427800536,
      -0.03306863456964493,
      -0.0015907278284430504,
      -0.036562953144311905,
      0.05349138379096985,
      -0.031261932104825974,
      -0.031322181224823,
      0.0628124251961708,
      -0.007771515753120184,
      -0.022557003423571587,
      -0.006163406185805798,
      0.0017236319836229086,
      -0.05645432323217392,
      -0.0025796599220484495,
      -0.011184319853782654,
      0.00872130785137415,
      0.029565822333097458,
      0.025036675855517387,
      -0.04560244828462601,
      -0.09941220283508301,
      -0.03957941010594368,
      0.021714579313993454,
      -0.026523256674408913,
      0.05451417714357376,
      -0.009325213730335236,
      0.00816575437784195,
      -0.04529935121536255,
      -0.02376685105264187,
      0.06137169525027275,
      -0.006286759860813618,
      0.012680989690124989,
      -0.03773593530058861,
      0.009935669600963593,
      0.004847966134548187,
      -0.01943161152303219,
      0.009322439320385456,
      -0.010804406367242336,
      0.00903763622045517,
      -0.008200851269066334,
      -0.01684481091797352,
      0.06986907124519348,
      0.014516076073050499,
      -0.01831265166401863,
      -0.06294301897287369,
      -0.007821561768651009,
      -0.03518279269337654,
      0.03098158910870552,
      0.010908713564276695,
      -0.035222768783569336,
      -0.030271129682660103,
      0.024161407724022865,
      -0.02497168257832527,
      -0.01571393571794033,
      -0.05557038262486458,
      -0.01878941059112549,
      0.011858745478093624,
      0.015557941049337387,
      -0.0003673577157314867,
      -0.0014233728870749474,
      0.023662351071834564,
      0.014248079620301723,
      0.010994741693139076,
      0.00962077546864748,
      0.017996923997998238,
      -0.022579871118068695,
      0.07169811427593231,
      0.01504011731594801,
      0.00727930199354887,
      0.013576416298747063,
      0.029351750388741493,
      -0.05904846265912056,
      -0.029125431552529335,
      0.0634581670165062,
      -0.001986698480322957,
      0.055331043899059296,
      0.03701432794332504,
      -0.05427144467830658,
      0.04394693300127983,
      -0.036181189119815826,
      -0.01222673337906599,
      0.04383348673582077,
      -0.03896377608180046,
      0.09090030193328857,
      -0.0150065366178751,
      0.036695919930934906,
      0.02304520644247532,
      0.06272226572036743,
      0.003872861620038748,
      -0.0852774977684021,
      -0.018170978873968124,
      0.004419261589646339,
      -0.059880536049604416,
      0.011719812639057636,
      -0.09937267005443573,
      0.02095990628004074,
      -0.02305193804204464,
      -0.02847251668572426,
      -0.034187957644462585,
      -0.009429434314370155,
      0.048575546592473984,
      0.04557200148701668,
      -0.02617332525551319,
      0.04305078089237213,
      0.04916805028915405,
      0.07978660613298416,
      -0.030277695506811142,
      -0.01867314800620079,
      -0.03830229863524437,
      0.03934312239289284,
      -0.01794649288058281,
      0.011522946879267693,
      -0.04620286822319031,
      0.009850508533418179,
      -0.03414536640048027,
      0.0766301304101944,
      -0.07811658084392548,
      -0.020972423255443573,
      -0.05265767499804497,
      0.0019220716785639524,
      0.0022834232077002525,
      0.0074420892633497715,
      -0.00982300192117691,
      -0.03661694750189781,
      -0.03851349651813507,
      0.005145872011780739,
      0.049755413085222244,
      0.048841606825590134,
      -0.06184796616435051,
      0.024125931784510612,
      0.028784774243831635,
      0.05034643039107323,
      0.0010786635102704167,
      0.00926219206303358,
      0.06351684033870697,
      -0.04752982407808304,
      -0.005592291243374348,
      0.005199071019887924,
      -0.015948105603456497,
      0.01108462829142809,
      -0.023507440462708473,
      0.035769227892160416,
      0.04040203616023064,
      -0.0968463346362114,
      -0.07656176388263702,
      -0.035581912845373154,
      -0.02969161607325077,
      -0.05979575589299202,
      -0.06479191780090332,
      -0.019790522754192352,
      0.0019277104875072837,
      -0.08349330723285675,
      0.028406454250216484,
      -0.09518503397703171,
      0.02191106043756008,
      -0.01817094348371029,
      0.06015081703662872,
      0.00963267870247364,
      -0.006086957640945911,
      -0.013975560665130615,
      0.04378217086195946,
      -0.0817873403429985,
      0.01461553294211626,
      -0.07173630595207214,
      0.021927230060100555,
      -0.03440609946846962,
      0.02745715156197548,
      -0.09672806411981583,
      -0.027560867369174957,
      -0.002124651800841093,
      0.043160080909729004,
      0.1154412180185318,
      -0.04255826398730278,
      -0.0270493496209383,
      -0.008473649621009827,
      -0.0247208122164011,
      0.05178207904100418,
      -0.017729032784700394,
      0.02521732822060585,
      0.03682922199368477,
      0.05590182542800903,
      0.0626562088727951,
      0.08788369596004486,
      -0.008568054996430874,
      0.02061181701719761,
      -0.013836589641869068,
      0.02406657487154007,
      -0.11631797254085541,
      0.0351485051214695,
      -0.011178478598594666,
      -0.015985893085598946,
      -0.014905267395079136,
      -0.01751234196126461,
      0.0357973612844944,
      0.026596151292324066,
      0.02404671162366867,
      -0.00842289999127388,
      0.06439337879419327,
      -0.018523555248975754,
      0.0034864223562180996,
      0.09521766006946564,
      0.017931900918483734,
      -0.0683661475777626,
      0.01649284176528454,
      -0.06408461183309555,
      -0.056603770703077316,
      -0.02685893140733242,
      -0.0349072702229023,
      0.006351623684167862,
      0.022095218300819397,
      -0.02289889007806778,
      -0.03218977525830269,
      0.084747813642025,
      0.07943172007799149,
      -0.02306157350540161,
      -0.023354314267635345,
      -0.052838053554296494,
      0.016855383291840553,
      0.06549420207738876,
      -0.022843267768621445,
      -0.05734681710600853,
      -0.03304460644721985,
      0.04321254789829254,
      0.030875200405716896,
      0.03529348969459534,
      -0.0685930848121643,
      -0.007898075506091118,
      -0.11564011871814728,
      0.019938914105296135,
      0.044394515454769135,
      0.0383506640791893,
      0.052354685962200165,
      -0.044483404606580734,
      0.02690919302403927,
      0.004855071660131216,
      0.006068849936127663,
      -0.07144232094287872,
      0.02790665440261364,
      0.025248896330595016,
      0.025080233812332153,
      -0.015319800935685635,
      0.03898691013455391,
      -0.057489775121212006,
      -0.01658385805785656,
      0.04599957540631294,
      -0.032461706548929214,
      -0.005964341573417187,
      -0.052052631974220276,
      0.04108990356326103,
      0.02056734822690487,
      -0.048127058893442154,
      0.04417399689555168,
      -0.03984462842345238,
      -0.020029384642839432,
      0.009763644076883793,
      -0.007097555790096521,
      0.02201058715581894,
      -0.027491647750139236,
      0.009999259375035763,
      -0.012757844291627407,
      -0.07244502753019333,
      0.0026824092492461205,
      0.0514461025595665,
      0.0029037590138614178,
      -0.017185069620609283,
      0.05693244934082031,
      -0.02723780646920204,
      0.032491885125637054,
      0.030296243727207184,
      -0.04957332834601402,
      0.025318078696727753,
      0.005602444522082806,
      -0.010630236938595772,
      -0.031083641573786736,
      -0.016123026609420776,
      -0.031213223934173584,
      0.0717230886220932,
      -0.11278112232685089,
      -0.02440229430794716,
      0.014047021046280861,
      0.049018021672964096,
      -0.021549690514802933,
      0.046125900000333786,
      0.02465062402188778,
      0.08518221229314804,
      -0.03876486420631409,
      -0.07498548179864883,
      -0.0626809373497963,
      -0.015105427242815495,
      0.008721033111214638,
      -0.02726070210337639,
      0.03338105231523514,
      0.014110853895545006,
      0.005436879117041826,
      -0.015413833782076836,
      0.01231458317488432,
      0.03798208758234978,
      -0.059108614921569824,
      0.005424016155302525,
      0.054130297154188156,
      0.01215173676609993,
      0.023431388661265373,
      -0.08016355335712433,
      0.11369830369949341,
      -0.05333283171057701,
      -0.02257463149726391,
      -0.015948586165905,
      -0.02793068438768387,
      -0.08434489369392395,
      -0.005815930664539337,
      0.08890330791473389,
      -0.010680546052753925,
      0.06140629202127457,
      0.008488841354846954,
      -0.03868164122104645,
      0.08155125379562378,
      0.037909913808107376,
      0.02071049064397812,
      0.013618907891213894,
      0.03652308136224747,
      0.009741635993123055,
      -0.07284129410982132,
      0.07667677849531174,
      -0.03685333952307701,
      -0.039547983556985855,
      -0.0033057311084121466,
      -0.06188366934657097,
      0.01702558808028698,
      0.033780910074710846,
      0.007251496892422438,
      -0.028103984892368317,
      -0.024208905175328255,
      0.01709815487265587,
      0.05094097554683685,
      -0.029485363513231277,
      -0.019066041335463524,
      0.016899896785616875,
      -0.01687866821885109,
      -0.01633489690721035,
      0.017670439556241035,
      -0.0055100941099226475,
      -0.02865506149828434,
      -0.04071469604969025,
      -0.016739537939429283,
      0.03227490931749344,
      -0.006889347918331623,
      0.036538466811180115,
      0.005964118055999279,
      -0.08942465484142303,
      0.04074322059750557,
      -0.005323946475982666,
      0.0033485323656350374,
      -0.014898456633090973,
      -0.030438998714089394,
      -0.08783616125583649,
      0.009150942787528038,
      0.018955277279019356,
      0.0021727513521909714,
      0.08854559063911438,
      -0.0023325467482209206,
      -0.042777471244335175,
      0.028348274528980255,
      -0.053566742688417435,
      -0.02252594195306301,
      -0.012675529345870018,
      -0.044018104672431946,
      -0.0055966186337172985,
      0.03879702091217041,
      -0.011934405192732811,
      0.04291820898652077,
      -0.020143795758485794,
      0.0901929959654808,
      -0.08489576727151871,
      -0.027387192472815514,
      -0.0698835700750351,
      0.001291416003368795,
      0.010992283932864666,
      -0.009283117949962616,
      -0.03806176781654358,
      0.02236235700547695,
      -0.09366677701473236,
      0.017968202009797096,
      0.005888377781957388,
      0.0004616735386662185,
      0.05294753238558769,
      -0.008606582880020142,
      -0.059211235493421555,
      -0.042559005320072174,
      0.008574568666517735,
      -0.023862522095441818,
      0.018438143655657768,
      0.03302399441599846,
      -0.04856402054429054,
      -0.1015024483203888,
      0.06807149201631546,
      0.04684678837656975,
      -0.0802261009812355,
      0.03922734782099724,
      -0.04262303560972214,
      0.02896222658455372,
      -0.0485847108066082,
      -0.006211510859429836,
      -0.004623013082891703,
      -0.040087487548589706,
      -0.04616408050060272,
      -0.08468954265117645,
      -0.05828176066279411,
      0.005664220079779625,
      0.04693977162241936,
      -0.06080419942736626,
      -0.019038718193769455,
      -0.08291508257389069,
      0.03012344054877758,
      -0.022813044488430023,
      0.1005069687962532,
      -0.01058936957269907,
      -0.05175303295254707,
      -0.13018730282783508,
      -0.07812370359897614,
      0.011294744908809662,
      0.015366531908512115,
      0.063084676861763,
      0.009570112451910973,
      0.006005966570228338,
      -0.003598695620894432,
      -0.02042529359459877,
      0.03236891329288483,
      0.08645066618919373,
      -0.03015848435461521,
      -0.025299925357103348,
      0.06471993029117584,
      0.0053348662331700325,
      -0.059512678533792496,
      0.07867341488599777,
      -0.09737859666347504,
      -0.04665049538016319,
      -0.025322873145341873,
      -0.028738608583807945,
      0.01147341076284647,
      0.03204287216067314,
      -0.010030770674347878,
      0.009256906807422638,
      -0.0019319462589919567,
      0.020274650305509567,
      -0.028302649036049843,
      0.07068867236375809,
      -0.04028739035129547,
      -0.0065885307267308235,
      -0.03187423199415207,
      -0.02649744041264057,
      0.0256512388586998,
      0.05907773971557617,
      -0.0072466712445020676,
      0.04166916757822037,
      0.018086619675159454,
      -0.026239976286888123,
      0.05894402787089348,
      -0.044241029769182205,
      -0.023801373317837715,
      -0.10606637597084045,
      -0.014970427379012108,
      0.008606079034507275,
      -0.03199361264705658,
      -0.014097725972533226,
      -0.08118127286434174,
      0.07129456847906113,
      -0.0020947272423654795,
      0.014162490144371986,
      0.02935994789004326,
      0.005059865303337574,
      -0.016928628087043762,
      -0.04227703809738159,
      0.004919559694826603,
      -0.043615277856588364,
      -0.06272319704294205,
      0.048996150493621826,
      -0.014673218131065369,
      -0.023644646629691124,
      0.006742962170392275,
      0.0042301989160478115,
      -0.001469964161515236,
      0.0186485443264246,
      0.0010192623594775796,
      -0.06519179046154022,
      0.028651529923081398,
      -0.03318359702825546
    ],
    [
      0.018502306193113327,
      -0.06520693004131317,
      0.0038884521927684546,
      -0.07760817557573318,
      0.03664419427514076,
      -0.054190658032894135,
      -0.030606446787714958,
      -0.03843396529555321,
      0.047198131680488586,
      -0.06357205659151077,
      0.004902015905827284,
      -0.0019121458753943443,
      0.01728559285402298,
      0.036898527294397354,
      -0.006696970667690039,
      0.009393056854605675,
      -0.013311837799847126,
      -0.09628333151340485,
      -0.0703946202993393,
      0.06047819182276726,
      0.07499974220991135,
      0.04138285666704178,
      0.027250343933701515,
      0.06907990574836731,
      -0.02662641741335392,
      0.0385587103664875,
      -0.014873773790895939,
      0.041982073336839676,
      0.018825717270374298,
      -0.033048707991838455,
      0.08375710248947144,
      -0.04969378188252449,
      -0.09013285487890244,
      -0.08139360696077347,
      0.11776746064424515,
      0.05933283641934395,
      -0.01162573229521513,
      0.060270849615335464,
      0.00258011557161808,
      0.03404280170798302,
      -0.04780398681759834,
      0.015520427376031876,
      -0.034034363925457,
      -0.011078174225986004,
      0.032622598111629486,
      0.03602668642997742,
      0.0277343038469553,
      0.020793044939637184,
      0.043117787688970566,
      -0.023932289332151413,
      0.046545375138521194,
      -0.012451007962226868,
      -0.011456705629825592,
      -0.06995522975921631,
      0.10420258343219757,
      0.031048424541950226,
      0.006356633733958006,
      0.03617861866950989,
      0.051271069794893265,
      0.05735950544476509,
      -0.006937926169484854,
      -0.00575957540422678,
      0.06856924295425415,
      0.0018791890470311046,
      -0.012276842258870602,
      -0.014214921742677689,
      0.08000460267066956,
      0.040250588208436966,
      -0.017677005380392075,
      0.0626407265663147,
      0.005352914333343506,
      -0.028140205889940262,
      -0.08953966945409775,
      0.09808112680912018,
      0.04777674376964569,
      -0.0950188860297203,
      0.022596225142478943,
      0.03236661106348038,
      -0.013872490264475346,
      0.03485351428389549,
      -0.02290508709847927,
      -0.07712096720933914,
      0.018524305894970894,
      0.021097667515277863,
      0.09866069257259369,
      -0.03958810865879059,
      0.0776669904589653,
      0.0056357658468186855,
      0.04830154404044151,
      0.05455783009529114,
      0.03409580513834953,
      -0.019365141168236732,
      -0.024290090426802635,
      0.03170975670218468,
      0.04027262330055237,
      0.034788988530635834,
      0.03429189696907997,
      -0.055052366107702255,
      0.05717015266418457,
      0.031565938144922256,
      -0.02021739073097706,
      -0.005392332095652819,
      -0.05196020379662514,
      0.06399936228990555,
      0.09405484795570374,
      -0.036122094839811325,
      -0.05348225310444832,
      -0.04163884371519089,
      -0.025326520204544067,
      -0.04190269112586975,
      -0.009801646694540977,
      0.041039206087589264,
      0.05561308562755585,
      -0.05463118478655815,
      0.01499822549521923,
      -0.004222320392727852,
      -0.040247585624456406,
      0.02450585924088955,
      0.045768920332193375,
      0.05690820515155792,
      0.0702347680926323,
      -0.04206347465515137,
      0.032588403671979904,
      0.013968277722597122,
      -0.01422807015478611,
      0.10813634842634201,
      0.01714615896344185,
      0.08246029168367386,
      -0.03857342153787613,
      0.03222443535923958,
      -0.050162699073553085,
      -0.04844271019101143,
      -0.02276490069925785,
      0.015389545820653439,
      0.012549704872071743,
      -0.08325867354869843,
      0.02174868807196617,
      -0.021662522107362747,
      0.09321022033691406,
      -0.05236491560935974,
      -0.010209822095930576,
      0.11003129929304123,
      0.02691010944545269,
      0.07507944107055664,
      0.039641935378313065,
      0.050566576421260834,
      -0.03615822270512581,
      -0.05287066102027893,
      -0.0706305056810379,
      -0.024676788598299026,
      0.03356962651014328,
      0.04306632652878761,
      -0.010207665152847767,
      -0.050974585115909576,
      -0.03735334426164627,
      -0.024345379322767258,
      0.027439961209893227,
      -0.005023627541959286,
      0.01819148100912571,
      0.11108742654323578,
      -0.06301582604646683,
      0.06772559136152267,
      0.03700794652104378,
      0.039935339242219925,
      -0.017655212432146072,
      0.0339471697807312,
      -0.04770546779036522,
      -0.004877013619989157,
      -0.019206851720809937,
      -0.0391218475997448,
      -0.050466109067201614,
      0.05720628425478935,
      0.06052222102880478,
      -0.04888823628425598,
      -0.010599378496408463,
      -0.03583626076579094,
      0.013612302951514721,
      0.009557673707604408,
      -0.040436431765556335,
      -0.016088418662548065,
      0.044639986008405685,
      0.020279347896575928,
      0.07953012734651566,
      0.043188612908124924,
      -0.04119330272078514,
      0.05115661025047302,
      -0.01220371201634407,
      0.03988922759890556,
      0.04020491614937782,
      0.026690682396292686,
      -0.005695907399058342,
      -0.01202635932713747,
      0.009865297004580498,
      -0.06471392512321472,
      -0.046800173819065094,
      0.05722026899456978,
      0.0032361492048949003,
      -0.004831147845834494,
      0.0004006096569355577,
      0.005489185452461243,
      0.02342560887336731,
      0.07342381775379181,
      -0.005444417707622051,
      0.0007330116350203753,
      0.00597004871815443,
      -0.004257073160260916,
      0.05225459113717079,
      -0.06935834139585495,
      0.006285598035901785,
      0.022251350805163383,
      -0.05167057737708092,
      -0.022999869659543037,
      -0.04489615559577942,
      -0.009696731343865395,
      0.06122174113988876,
      -0.04151930660009384,
      0.07654371857643127,
      0.0644427239894867,
      0.007822519168257713,
      0.10082954168319702,
      -0.061837293207645416,
      0.02404029294848442,
      0.03921583667397499,
      -0.04112088307738304,
      -0.03242998942732811,
      0.01610512100160122,
      -0.029880128800868988,
      0.007355023175477982,
      0.07681526988744736,
      0.008834858424961567,
      0.053779486566782,
      -0.009735221043229103,
      0.00980901625007391,
      -0.022277314215898514,
      0.034810081124305725,
      -0.04445521906018257,
      0.015356521122157574,
      -0.011115845292806625,
      -0.03237868472933769,
      -0.054749440401792526,
      -0.05459585040807724,
      -0.038289282470941544,
      0.03718773275613785,
      0.014100741595029831,
      -0.008031414821743965,
      0.03903811424970627,
      0.03739729896187782,
      -0.024521276354789734,
      -0.007895822636783123,
      -0.05297879874706268,
      -0.032233402132987976,
      -0.032384082674980164,
      0.04949503391981125,
      -0.02599230781197548,
      -0.07747571915388107,
      0.03216627985239029,
      0.04307946935296059,
      0.027067314833402634,
      -0.0178383719176054,
      0.07370781153440475,
      -0.035023465752601624,
      0.004174634348601103,
      0.06335661560297012,
      -0.04015021026134491,
      -0.006630240473896265,
      0.011702700518071651,
      -0.012608756311237812,
      -0.026547646149992943,
      0.02698514237999916,
      0.02656746655702591,
      0.03274189680814743,
      0.09677661955356598,
      0.028969449922442436,
      -0.016825689002871513,
      -0.001838010037317872,
      0.060042787343263626,
      -0.011085600592195988,
      -0.03055574558675289,
      0.013176978565752506,
      -0.011362620629370213,
      0.06455735862255096,
      -0.036859180778265,
      0.05334349721670151,
      0.07721222937107086,
      0.016412802040576935,
      -0.03862111642956734,
      0.006147847976535559,
      -0.0067178611643612385,
      0.11201543360948563,
      0.03326738625764847,
      0.055942244827747345,
      0.04044279083609581,
      -0.010332059115171432,
      0.004862965550273657,
      0.03177578002214432,
      0.012056570500135422,
      0.0316653773188591,
      0.059293732047080994,
      -0.018902480602264404,
      0.011068003252148628,
      -0.06116136536002159,
      0.0011411309242248535,
      -0.08043765276670456,
      0.034398868680000305,
      -0.0851219967007637,
      0.002655109390616417,
      0.0017289480892941356,
      -0.02163657546043396,
      0.043164853006601334,
      0.03239766135811806,
      -0.06961576640605927,
      0.006517163943499327,
      0.045361924916505814,
      -0.06335699558258057,
      -0.05427123233675957,
      -0.03187054395675659,
      0.07776539027690887,
      -0.057133737951517105,
      -0.004414629656821489,
      0.0012620928464457393,
      0.020154625177383423,
      -0.04503553733229637,
      -0.04439632594585419,
      0.05284232273697853,
      0.034333486109972,
      0.039418432861566544,
      0.030192529782652855,
      -0.004102368373423815,
      0.08959176391363144,
      0.06113724410533905,
      0.0350070595741272,
      0.03446013107895851,
      0.05990380048751831,
      0.01878204569220543,
      -0.014614230021834373,
      -0.0019567785784602165,
      -0.008279601112008095,
      -0.0848257914185524,
      0.012661546468734741,
      -0.0050224424339830875,
      0.004582459572702646,
      -0.08467049896717072,
      -0.08534804731607437,
      -0.015128889121115208,
      -0.05156069993972778,
      0.015166073106229305,
      -0.0029246259946376085,
      0.040256232023239136,
      0.07438158988952637,
      -0.0373908095061779,
      0.09643810987472534,
      -0.0074036605656147,
      0.00011251337127760053,
      -0.02603095769882202,
      0.006804931443184614,
      -0.01093266624957323,
      -0.023109635338187218,
      -0.03932010382413864,
      -0.03904139623045921,
      0.05216691642999649,
      -0.009348529390990734,
      0.028587890788912773,
      -0.01251284871250391,
      0.07824849337339401,
      0.11510913819074631,
      0.027334822341799736,
      0.027966154739260674,
      -0.020769400522112846,
      -0.08192138373851776,
      0.01180726382881403,
      -0.09614283591508865,
      -0.0375053733587265,
      -0.049614403396844864,
      0.001879651565104723,
      0.04890257120132446,
      -0.006117378827184439,
      0.007420912850648165,
      0.01562834344804287,
      -0.0175815150141716,
      0.0402006134390831,
      0.07901017367839813,
      0.05850851908326149,
      -0.06029326841235161,
      0.03936975821852684,
      -0.009590071626007557,
      0.0033153104595839977,
      -0.033538270741701126,
      -0.054323308169841766,
      0.076617531478405,
      -0.03068699687719345,
      -0.00926905032247305,
      -0.010675248689949512,
      -0.08768001198768616,
      -0.040936414152383804,
      0.018522348254919052,
      -0.0072557260282337666,
      0.007679410744458437,
      -0.07643026858568192,
      -0.05905352160334587,
      -0.016163300722837448,
      0.01703649014234543,
      0.02058243378996849,
      -0.08943725377321243,
      0.03082098625600338,
      -0.05111328512430191,
      0.04404396936297417,
      0.06341998279094696,
      0.0304096806794405,
      -0.0733630359172821,
      -0.03849366307258606,
      0.031322624534368515,
      0.021553844213485718,
      -0.009112304076552391,
      0.03507411107420921,
      0.02395293116569519,
      0.0058441138826310635,
      -0.033841077238321304,
      -0.04027954861521721,
      0.010485521517693996,
      -0.01687481254339218,
      0.03500084578990936,
      -0.020949112251400948,
      -0.014069727621972561,
      0.0913027748465538,
      -0.0067395600490272045,
      -0.04626241326332092,
      0.0795958936214447,
      -0.0999210774898529,
      -0.01388608943670988,
      -0.036806609481573105,
      -0.04946526139974594,
      -0.015794700011610985,
      -0.06514602154493332,
      -0.004471944645047188,
      0.02434290200471878,
      -0.03972044587135315,
      0.06464401632547379,
      -0.01326831802725792,
      0.06920960545539856,
      0.03824849799275398,
      0.07706437259912491,
      0.0030458574183285236,
      -0.07644671946763992,
      -0.005750769749283791,
      -0.007848899811506271,
      0.053074728697538376,
      0.010715560987591743,
      0.005162419751286507,
      0.027544138953089714,
      0.013266890309751034,
      0.02362740971148014,
      -0.003380642971023917,
      -0.028635235503315926,
      0.00346956099383533,
      0.012669670395553112,
      0.08513887226581573,
      -0.004196433816105127,
      0.009162873029708862,
      -0.026957232505083084,
      0.03241638466715813,
      -0.02964942343533039,
      0.0034273804631084204,
      0.042507752776145935,
      -0.07679275423288345,
      0.015124336816370487,
      0.02763894386589527,
      -0.055507030338048935,
      0.02848714590072632,
      0.005324587691575289,
      0.02718055434525013,
      -0.013756739906966686,
      0.06799173355102539,
      0.032591644674539566,
      -0.017932267859578133,
      -0.010962755419313908,
      -0.006135513540357351,
      0.023882001638412476,
      -0.04393624886870384,
      0.08361345529556274,
      0.03275652229785919,
      0.017912449315190315,
      0.05682695657014847,
      0.08495302498340607,
      -0.06653956323862076,
      0.018914109095931053,
      0.08434955030679703,
      -0.007273250725120306,
      0.013749061152338982,
      0.0680510550737381,
      0.028687074780464172,
      -0.06789110600948334,
      0.029141444712877274,
      0.05183657631278038,
      -0.04004376381635666,
      -0.0048082140274345875,
      0.053313400596380234,
      -0.052585143595933914,
      0.06376729905605316,
      0.08279754221439362,
      -0.04258749634027481,
      -0.09984289109706879,
      0.029789205640554428,
      0.04254312068223953
    ],
    [
      -0.05269130691885948,
      0.03468179702758789,
      -0.016913268715143204,
      -0.035614773631095886,
      0.013265765272080898,
      -0.057318251579999924,
      0.012299980968236923,
      -0.013341933488845825,
      -0.0383371040225029,
      -0.003577203955501318,
      -0.013555791229009628,
      0.031836945563554764,
      0.08570430427789688,
      -0.026059307157993317,
      -0.04293282702565193,
      0.047446366399526596,
      -0.018785402178764343,
      0.013495871797204018,
      -0.009748508222401142,
      -0.042730364948511124,
      -0.0010139149380847812,
      0.05303371325135231,
      0.08247754722833633,
      0.013645781204104424,
      -0.09164585173130035,
      -0.03324172645807266,
      -0.02428152598440647,
      0.07137925177812576,
      -0.010893802158534527,
      0.03711956366896629,
      0.07343198359012604,
      0.014067193493247032,
      0.04785662889480591,
      -0.032459959387779236,
      0.013492265716195107,
      -0.048215728253126144,
      0.026955053210258484,
      0.03185674175620079,
      0.017253218218684196,
      0.03827240318059921,
      -0.0060693370178341866,
      0.04194040596485138,
      -0.0372057743370533,
      -0.0010957521153613925,
      0.11290079355239868,
      -0.03701440617442131,
      0.014298906549811363,
      0.038088422268629074,
      0.026108961552381516,
      0.005857902113348246,
      -0.045968204736709595,
      -0.14121845364570618,
      -0.0956144705414772,
      -0.0654001459479332,
      -0.0009969898965209723,
      0.011961488053202629,
      0.009845392778515816,
      0.12495964020490646,
      -0.046086233109235764,
      0.07555738091468811,
      0.026693405583500862,
      0.010933544486761093,
      -0.010480018332600594,
      -0.03643658757209778,
      -0.022436020895838737,
      -0.08357242494821548,
      -0.02961544878780842,
      0.05761624500155449,
      -0.008444119244813919,
      -2.340844730497338e-06,
      -0.08026103675365448,
      -0.02905770018696785,
      -0.024183254688978195,
      -0.025968089699745178,
      -0.02437383309006691,
      0.07501735538244247,
      -0.007436380721628666,
      -0.05278386175632477,
      -0.03669990226626396,
      0.0024880163837224245,
      -0.027559347450733185,
      -0.09090611338615417,
      -0.030365822836756706,
      -0.01184579636901617,
      -0.08514463901519775,
      -0.05406160280108452,
      0.00930812768638134,
      0.0030155235435813665,
      0.0354260578751564,
      0.01854952983558178,
      0.0751560628414154,
      -0.012703238986432552,
      0.027596881613135338,
      0.02747955732047558,
      0.017953697592020035,
      -0.01489598210901022,
      -0.0003178110346198082,
      0.010381892323493958,
      0.020684316754341125,
      -0.047552600502967834,
      -0.007110036443918943,
      -0.06421896815299988,
      0.02983284182846546,
      0.08880908787250519,
      0.03418745845556259,
      -0.023482264950871468,
      -0.02340208925306797,
      -0.010314109735190868,
      0.011389924213290215,
      0.01519652921706438,
      0.04447025805711746,
      -0.03144208714365959,
      0.003595626214519143,
      0.013697555288672447,
      0.0411263182759285,
      0.012642450630664825,
      8.58399744174676e-06,
      -0.004347625188529491,
      0.03226848691701889,
      0.034458547830581665,
      0.02034829743206501,
      0.009700560942292213,
      0.02398853749036789,
      0.011899868957698345,
      -0.023050582036376,
      0.14379717409610748,
      -0.030270950868725777,
      0.02074280194938183,
      0.012793827801942825,
      -0.01438204012811184,
      0.016035597771406174,
      -0.06256675720214844,
      0.009560229256749153,
      0.06484813243150711,
      0.05804178863763809,
      0.03242187574505806,
      -0.013844192959368229,
      -0.07784848660230637,
      0.009469018317759037,
      -0.006919541396200657,
      -0.06277581304311752,
      -0.0329357348382473,
      0.01933358609676361,
      -0.05437397211790085,
      0.08541370183229446,
      -0.06338823586702347,
      0.03442952036857605,
      0.009064329788088799,
      -0.020147552713751793,
      0.029072893783450127,
      0.013072959147393703,
      0.013934806920588017,
      0.049827300012111664,
      -0.015704095363616943,
      0.00016920726920943707,
      -0.04635320603847504,
      0.09617052227258682,
      -0.01611032895743847,
      -0.003118278691545129,
      0.021012961864471436,
      0.012813802808523178,
      0.04416275396943092,
      -0.01760362833738327,
      -0.05197181925177574,
      -0.06769014149904251,
      -0.01912674307823181,
      0.01672748662531376,
      0.03765879198908806,
      -0.011095214635133743,
      -0.010783691890537739,
      -0.028118329122662544,
      0.018510686233639717,
      0.013635649345815182,
      0.05538589134812355,
      0.023112356662750244,
      -0.042683400213718414,
      -0.04875437915325165,
      0.07470444589853287,
      -0.0681614875793457,
      -0.04493991658091545,
      -0.04696829989552498,
      0.03477631136775017,
      -0.027358941733837128,
      0.07130243629217148,
      0.00820432510226965,
      -0.0006177914910949767,
      -5.022519690101035e-05,
      0.06364302337169647,
      0.06881831586360931,
      -0.038079019635915756,
      -0.05503520369529724,
      -0.0038017029874026775,
      0.04844403639435768,
      -0.014944343827664852,
      -0.008155632764101028,
      0.1050226166844368,
      0.005770473275333643,
      0.010903507471084595,
      -0.05862227454781532,
      -0.0022416948340833187,
      -0.00956630613654852,
      -0.05043346807360649,
      0.006830901373177767,
      -0.01925624907016754,
      -0.027556564658880234,
      0.007288328371942043,
      0.045209214091300964,
      0.028094466775655746,
      -0.00817106943577528,
      0.044171713292598724,
      0.004219916183501482,
      -0.07388947904109955,
      -0.02281852811574936,
      0.010274749249219894,
      0.009811349213123322,
      -0.01893828809261322,
      0.00809431727975607,
      -0.017068980261683464,
      -0.008911042474210262,
      0.0032355303410440683,
      0.03815191984176636,
      -0.035310085862874985,
      -0.0701226070523262,
      0.07337329536676407,
      0.03782001882791519,
      0.047269344329833984,
      0.044677939265966415,
      -0.00987523514777422,
      0.030913056805729866,
      -0.07712474465370178,
      0.02627745270729065,
      -0.10667695850133896,
      -0.05979244410991669,
      0.03902558237314224,
      0.05905661731958389,
      -0.03495418652892113,
      -0.02636217698454857,
      0.009124403819441795,
      0.04248269647359848,
      0.0008360814535990357,
      -0.03873380273580551,
      -0.05570352450013161,
      0.0352463573217392,
      -0.01572726108133793,
      -0.03906453400850296,
      0.0457577258348465,
      0.07451561093330383,
      -0.03835215047001839,
      0.05205610394477844,
      -0.053593095391988754,
      -0.055615056306123734,
      0.05661454796791077,
      -0.09387008845806122,
      0.07150151580572128,
      0.09084109961986542,
      -0.036302536725997925,
      -0.023067135363817215,
      -0.007764550391584635,
      -0.039062440395355225,
      -0.0250270776450634,
      0.0813150405883789,
      -0.03879854083061218,
      -0.10017874836921692,
      0.10417493432760239,
      -0.01107893418520689,
      0.010929470881819725,
      0.016327034682035446,
      -0.06063956767320633,
      0.005788774229586124,
      -0.02576659992337227,
      0.06963718682527542,
      0.031365975737571716,
      -0.016857367008924484,
      -0.006172529887408018,
      -0.0069526988081634045,
      -0.04891181364655495,
      0.014855747111141682,
      -0.038005612790584564,
      0.016433365643024445,
      4.416064984980039e-05,
      0.0375542975962162,
      -0.04328173026442528,
      -0.015584541484713554,
      -0.012128816917538643,
      -0.006689629051834345,
      -0.07387955486774445,
      -0.003102769609540701,
      -0.018692219629883766,
      0.08177616447210312,
      0.019705599173903465,
      0.038287531584501266,
      0.02094465121626854,
      -0.05590703710913658,
      0.004456063732504845,
      -0.07061178982257843,
      -0.0339156910777092,
      0.014095262624323368,
      -0.04575417563319206,
      0.02297906018793583,
      0.018984032794833183,
      -0.05824359506368637,
      -0.029905788600444794,
      -0.0353177972137928,
      -0.07139984518289566,
      0.007096577901393175,
      0.058459747582674026,
      -0.02570377290248871,
      -0.019429728388786316,
      0.026453077793121338,
      0.0502016507089138,
      -8.68377901497297e-05,
      0.017744511365890503,
      0.08401380479335785,
      0.0698188841342926,
      -0.04880813509225845,
      -0.06273599714040756,
      -0.055501844733953476,
      0.05466500669717789,
      -0.016266997903585434,
      0.07111269980669022,
      0.0011512786149978638,
      -0.009034617803990841,
      -0.012208663858473301,
      0.1019415557384491,
      -0.048764120787382126,
      -0.01227750163525343,
      0.1394820362329483,
      0.03292238712310791,
      0.018231740221381187,
      0.030072472989559174,
      0.05556315928697586,
      -0.028647564351558685,
      -0.09056143462657928,
      -0.016562340781092644,
      0.04490284621715546,
      0.0666855201125145,
      0.005244214087724686,
      0.03476135805249214,
      -0.0030256153549999,
      0.028400998562574387,
      -0.005089918151497841,
      0.06618326902389526,
      0.08232251554727554,
      0.019900117069482803,
      0.02481445111334324,
      0.005566687323153019,
      -0.029927171766757965,
      -0.08141842484474182,
      -0.013428566046059132,
      -0.04948921501636505,
      0.015143170021474361,
      -0.011757050640881062,
      4.026874012197368e-05,
      -0.016223544254899025,
      0.0001505926629761234,
      -0.018392739817500114,
      0.01246524229645729,
      0.03527100384235382,
      0.0372929684817791,
      0.12074658274650574,
      -0.01646237075328827,
      0.01109044998884201,
      0.05760151147842407,
      -0.04870610684156418,
      -0.044891323894262314,
      -0.060126807540655136,
      0.01645498163998127,
      0.030401913449168205,
      -0.011258944869041443,
      0.03358630836009979,
      0.01599051244556904,
      -0.07979138940572739,
      0.057220324873924255,
      0.06568464636802673,
      0.015916911885142326,
      -0.0066416459158062935,
      0.014531874097883701,
      -0.03907747194170952,
      -0.005944373551756144,
      0.006673300638794899,
      -0.006932061165571213,
      0.017953669652342796,
      -0.024719106033444405,
      -0.07954103499650955,
      -0.0007195924990810454,
      0.033191338181495667,
      -0.07271730154752731,
      -0.008639423176646233,
      -0.0327213816344738,
      -0.019855713471770287,
      -0.08450322598218918,
      0.026677098125219345,
      -0.017734676599502563,
      0.026477914303541183,
      -0.01573938876390457,
      0.05673255771398544,
      0.04894612357020378,
      -0.0011366772232577205,
      0.02075110748410225,
      0.053416330367326736,
      0.042121656239032745,
      0.05383899807929993,
      0.042663976550102234,
      0.015375228598713875,
      -0.0005377343622967601,
      -0.050511643290519714,
      -0.023065736517310143,
      -0.034018855541944504,
      0.014986474066972733,
      0.02516714669764042,
      0.009900304488837719,
      0.01725349947810173,
      0.09744509309530258,
      -0.02827952429652214,
      0.02515549398958683,
      0.0038948480505496264,
      -0.031662486493587494,
      0.005304132122546434,
      -0.044497299939394,
      0.008574482053518295,
      -0.034418847411870956,
      0.008575680665671825,
      0.03120981529355049,
      0.0648672878742218,
      -0.012197224423289299,
      -0.04679667949676514,
      0.024900412186980247,
      -0.027113771066069603,
      0.10481979697942734,
      -0.056948963552713394,
      0.006704424042254686,
      0.0001947489654412493,
      0.03798772767186165,
      -0.10330726206302643,
      0.028068428859114647,
      0.024305876344442368,
      0.02020784467458725,
      0.05252530053257942,
      0.015230538323521614,
      0.00015467152115888894,
      0.013481300324201584,
      0.00032145853037945926,
      0.022339612245559692,
      0.0048966603353619576,
      -0.008890937082469463,
      -0.033800579607486725,
      -0.005029520485550165,
      0.02119646593928337,
      -0.0122061837464571,
      0.011729682795703411,
      -0.03506314009428024,
      -0.024675527587532997,
      -0.03352976217865944,
      0.07870809733867645,
      0.02500309608876705,
      0.021307403221726418,
      -0.04456603154540062,
      0.0014781809877604246,
      -0.04275397211313248,
      0.017652126029133797,
      -0.0700836330652237,
      -0.06065673753619194,
      0.021578863263130188,
      -0.057127222418785095,
      0.07106812298297882,
      -0.002023272681981325,
      0.007362519856542349,
      -0.020039349794387817,
      -0.042492806911468506,
      -0.04435056075453758,
      0.02797602489590645,
      -0.03128776699304581,
      -0.0526568628847599,
      0.04700302705168724,
      -0.08027585595846176,
      -0.00562226353213191,
      0.007500562351197004,
      -0.016434622928500175,
      -0.0044162096455693245,
      -0.07960199564695358,
      0.037644848227500916,
      0.008993163704872131,
      -0.03148859366774559,
      -0.008660253137350082,
      -0.048252999782562256,
      0.015517883002758026,
      -0.01661081239581108,
      -0.014839278534054756,
      -0.002062278799712658,
      0.005151854362338781,
      0.014413353055715561,
      0.0420655682682991,
      0.00011694902059389278,
      0.0178524162620306,
      0.007814472541213036,
      0.09617086499929428,
      -0.03683139756321907,
      0.11309412121772766,
      0.0014573490479961038,
      -0.0426524393260479,
      0.07045484334230423,
      0.03701448813080788,
      -0.05635556951165199
    ],
    [
      -0.03698616474866867,
      -0.004425836727023125,
      0.04207007959485054,
      -0.01577688753604889,
      -0.015814755111932755,
      -0.06948952376842499,
      0.1008320152759552,
      0.0692664086818695,
      0.007797492668032646,
      0.03164730221033096,
      0.016765672713518143,
      0.005953865125775337,
      0.006473319139331579,
      0.09242068231105804,
      -0.01789977215230465,
      0.005193228367716074,
      0.12454009801149368,
      0.004240156151354313,
      0.03693331405520439,
      -0.032949306070804596,
      -0.02590451017022133,
      -0.009307973086833954,
      0.02964968979358673,
      -0.024068553000688553,
      0.10457983613014221,
      0.0005523184663616121,
      -0.00016040020273067057,
      0.003125616582110524,
      -0.06733433902263641,
      0.005230202805250883,
      -0.0494643859565258,
      0.0032580173574388027,
      0.02422182261943817,
      0.026875317096710205,
      -0.03083880804479122,
      0.011545216664671898,
      0.028594868257641792,
      -0.03546706959605217,
      0.04647529125213623,
      -0.03135606646537781,
      0.028359776362776756,
      -0.07877196371555328,
      -0.05874524265527725,
      0.007171764969825745,
      0.020188087597489357,
      0.01871347799897194,
      0.022715389728546143,
      -0.055215734988451004,
      0.07789784669876099,
      -0.008750918321311474,
      0.011096413247287273,
      0.01507447101175785,
      0.007254463154822588,
      -0.02623365819454193,
      0.028552617877721786,
      0.07861121743917465,
      -0.07375803589820862,
      0.07133734226226807,
      -0.05056167393922806,
      0.0019113627495244145,
      -0.06818695366382599,
      0.03849039599299431,
      0.04038552939891815,
      0.021248741075396538,
      0.0038833555299788713,
      -0.008900392800569534,
      0.007799935527145863,
      -0.019424879923462868,
      -0.06199732422828674,
      0.04672008752822876,
      0.008041894994676113,
      0.04339845851063728,
      -0.03850867226719856,
      0.027140269055962563,
      0.034170396625995636,
      -0.06960523128509521,
      0.010945168323814869,
      -0.09105762094259262,
      0.07792332023382187,
      -0.007735567633062601,
      -0.022855520248413086,
      -0.023577703163027763,
      0.03558554872870445,
      0.01856832578778267,
      -0.02824288047850132,
      -0.019446248188614845,
      0.06731576472520828,
      0.03911133110523224,
      -0.0013962561497464776,
      0.031053559854626656,
      0.012438969686627388,
      -0.11318682134151459,
      0.07540891319513321,
      -0.04199947416782379,
      0.025578591972589493,
      -0.007009425200521946,
      -0.0023970836773514748,
      0.019889675080776215,
      -0.016633696854114532,
      -0.07717204838991165,
      0.00217903102748096,
      -0.021742427721619606,
      -0.03716075420379639,
      0.014503237791359425,
      0.018241023644804955,
      0.04625263810157776,
      0.006275148596614599,
      -0.05651022493839264,
      -0.036486513912677765,
      0.02597207948565483,
      0.07941165566444397,
      -0.002695899922400713,
      0.03963558375835419,
      -0.0036811786703765392,
      0.03647074103355408,
      -0.09293186664581299,
      0.023045938462018967,
      0.04465089365839958,
      0.05550146847963333,
      -0.05578716844320297,
      -0.07577207684516907,
      -0.018517130985856056,
      0.030540991574525833,
      -0.03138124570250511,
      -0.022989043965935707,
      -0.04145120829343796,
      -0.01297258771955967,
      0.004172471351921558,
      -0.03549804538488388,
      -0.011958754621446133,
      -0.07026130706071854,
      0.004786144010722637,
      -0.02611449547111988,
      0.02110102027654648,
      -0.006787275429815054,
      0.005260608624666929,
      -0.009435034357011318,
      -0.06843976676464081,
      -0.006249436642974615,
      0.00634355004876852,
      -0.08982836455106735,
      -0.023228179663419724,
      0.05811794474720955,
      0.04671249911189079,
      -0.08671007305383682,
      0.017507584765553474,
      -0.04141760990023613,
      -0.061391446739435196,
      0.023463478311896324,
      -0.03782173618674278,
      0.027189649641513824,
      0.053465958684682846,
      -0.08071619272232056,
      0.09332215040922165,
      -0.00416986970230937,
      0.00849114265292883,
      -0.04375762119889259,
      0.012442480772733688,
      0.05742742493748665,
      -0.028809471055865288,
      0.014957817271351814,
      -0.006834120023995638,
      0.02006537839770317,
      -0.09844759106636047,
      -0.008603395894169807,
      0.04390622302889824,
      0.0021038069389760494,
      0.044230926781892776,
      0.021281773224473,
      -0.0009937628637999296,
      0.016939138993620872,
      0.039616987109184265,
      0.03086588904261589,
      0.008497025817632675,
      -0.03148030862212181,
      -0.03193044289946556,
      -0.021181030198931694,
      0.06589187681674957,
      -0.034294869750738144,
      0.0014740292681381106,
      -0.017027953639626503,
      0.01871856302022934,
      0.05094342306256294,
      0.053836800158023834,
      -0.06100289896130562,
      -0.06621050834655762,
      0.03518346697092056,
      0.020066222175955772,
      0.06582554429769516,
      0.01065055187791586,
      0.03675920143723488,
      -0.03583957999944687,
      0.040636032819747925,
      -0.0617746040225029,
      0.022542649880051613,
      -0.014490120112895966,
      0.023582668974995613,
      -0.030360693112015724,
      0.033648040145635605,
      0.07408364862203598,
      0.02131759189069271,
      0.01879185065627098,
      0.03598575294017792,
      -0.02357916720211506,
      -0.0031891255639493465,
      0.011913012713193893,
      -0.007790896110236645,
      -0.014164527878165245,
      -0.023295069113373756,
      0.07891777902841568,
      -0.03827584907412529,
      -0.07939237356185913,
      0.0110184820368886,
      0.027739539742469788,
      0.023268457502126694,
      -0.004712739028036594,
      -0.0016740565188229084,
      -0.016386274248361588,
      -0.007292240392416716,
      -0.002923003863543272,
      0.021361038088798523,
      0.03016224317252636,
      -0.006325258407741785,
      -0.047155141830444336,
      0.06449225544929504,
      -0.03586076572537422,
      -0.008874338120222092,
      -0.04353916645050049,
      0.030200568959116936,
      -0.041199710220098495,
      0.050713635981082916,
      0.07333660870790482,
      0.004439238924533129,
      -0.018533287569880486,
      -0.02330796979367733,
      -0.06311822682619095,
      0.04345536604523659,
      0.012379057705402374,
      -0.0350918248295784,
      -0.024781502783298492,
      0.032808031886816025,
      0.07346854358911514,
      0.015576043166220188,
      -0.0409897044301033,
      0.012396203354001045,
      0.004391414579004049,
      0.08261814713478088,
      0.049058493226766586,
      -0.03134994953870773,
      -0.07859594374895096,
      -0.02392079308629036,
      0.03040364198386669,
      0.005824556574225426,
      -0.022109882906079292,
      -0.017876602709293365,
      -0.09176722913980484,
      0.025689585134387016,
      -0.05752890929579735,
      -0.06578152626752853,
      -0.011622765101492405,
      0.006959486287087202,
      0.04208225756883621,
      -0.03329073265194893,
      -0.004766611382365227,
      -0.037021562457084656,
      -0.040281590074300766,
      0.03631182387471199,
      0.025484221056103706,
      0.046889062970876694,
      0.06674767285585403,
      -0.0327623188495636,
      0.04527692124247551,
      0.001698634005151689,
      0.018767157569527626,
      -0.013030280359089375,
      -0.001100381021387875,
      0.0029452908784151077,
      -0.001185981440357864,
      -0.022614138200879097,
      0.016978725790977478,
      -0.04044247046113014,
      0.0267865639179945,
      0.03914177417755127,
      0.020851442590355873,
      0.050464775413274765,
      -0.026745647192001343,
      0.00787368044257164,
      0.019809124991297722,
      0.07497613877058029,
      -0.003725858638063073,
      0.01822821795940399,
      -0.046405129134655,
      0.006712751928716898,
      -0.0752212181687355,
      0.02716241031885147,
      0.00894120242446661,
      -0.015051908791065216,
      0.04881596192717552,
      0.02701299637556076,
      -0.008245021104812622,
      0.06830748170614243,
      0.013975337147712708,
      -0.052078742533922195,
      -0.03737559914588928,
      -0.02332032285630703,
      -0.05365854874253273,
      -0.0314524881541729,
      -0.04705139622092247,
      -0.0679011195898056,
      -0.027551373466849327,
      0.012121688574552536,
      -0.008824332617223263,
      -0.04684412106871605,
      0.0884435772895813,
      0.13773146271705627,
      -0.01510140672326088,
      0.04481940343976021,
      0.022227145731449127,
      -0.06891412287950516,
      -0.012265132740139961,
      -0.04082381725311279,
      -0.01531963236629963,
      -0.01425891648977995,
      -0.0038209082558751106,
      -0.04428298398852348,
      -0.05753292515873909,
      0.012743884697556496,
      0.003013105131685734,
      0.0733281597495079,
      -0.04472973570227623,
      -0.043803419917821884,
      0.054195698350667953,
      -0.01628851518034935,
      0.0211337897926569,
      0.003426050301641226,
      -0.035027749836444855,
      0.12004157900810242,
      0.001539583201520145,
      0.04688388481736183,
      -0.06890749931335449,
      -0.032266564667224884,
      -0.011504565365612507,
      0.06572572141885757,
      -0.01003906037658453,
      0.04602738842368126,
      0.03382861986756325,
      0.026586972177028656,
      0.023904219269752502,
      -0.019568713381886482,
      0.015321333892643452,
      -0.02826756425201893,
      0.07572713494300842,
      0.009753605350852013,
      0.03136585280299187,
      -0.0024927675258368254,
      0.03482862189412117,
      -0.0030824446585029364,
      -0.024817030876874924,
      0.01325573492795229,
      0.05472364276647568,
      -0.04086240008473396,
      -0.03464147821068764,
      -0.0016201820690184832,
      0.02748977392911911,
      -0.07278995215892792,
      0.01858009211719036,
      0.003797353943809867,
      0.06540052592754364,
      -0.032310646027326584,
      0.014500956051051617,
      -0.05564203858375549,
      -0.04541036859154701,
      -0.03569088876247406,
      0.014390354976058006,
      0.04112708196043968,
      -0.009301767684519291,
      -0.021205533295869827,
      -0.04784247279167175,
      -0.0396256297826767,
      0.07809852063655853,
      0.06484996527433395,
      0.00043891422683373094,
      -0.014994162134826183,
      -0.046108826994895935,
      -0.01615085080265999,
      0.05639428645372391,
      -0.025248393416404724,
      0.03884401172399521,
      0.03561944141983986,
      -0.050208572298288345,
      -0.028505386784672737,
      0.03771030157804489,
      0.020552998408675194,
      0.008689154870808125,
      0.0005792900337837636,
      -0.011835937388241291,
      -0.10854830592870712,
      0.038621991872787476,
      0.012271915562450886,
      -0.01857762597501278,
      -0.042547620832920074,
      7.904789526946843e-05,
      0.06256117671728134,
      -0.02405412495136261,
      0.03569925203919411,
      -0.047475576400756836,
      -0.03700751066207886,
      0.03081255778670311,
      -0.049001626670360565,
      -0.06157509610056877,
      -0.024750156328082085,
      -0.025238242000341415,
      0.020355017855763435,
      -0.013631120324134827,
      0.03142206370830536,
      -0.02983800694346428,
      -0.008358290418982506,
      -0.05392609164118767,
      -0.007213194854557514,
      -0.02466074377298355,
      0.019712548702955246,
      0.022472819313406944,
      0.044711288064718246,
      0.004686578176915646,
      -0.03379298001527786,
      0.005441054701805115,
      0.022411039099097252,
      0.003264565719291568,
      0.0492558553814888,
      0.05854162201285362,
      -0.027670668438076973,
      -0.011750133708119392,
      -0.06485232710838318,
      0.03128565102815628,
      -0.024364115670323372,
      -0.08060312271118164,
      0.08880044519901276,
      0.0009284262196160853,
      -0.025453783571720123,
      0.003548004664480686,
      -0.0023428702261298895,
      0.06539178639650345,
      0.06687042862176895,
      -0.027807286009192467,
      -0.0376364029943943,
      -0.09250786900520325,
      0.019075937569141388,
      0.018640512600541115,
      -0.0066809081472456455,
      0.03421822562813759,
      -0.05520998314023018,
      -0.007842100225389004,
      0.00550014479085803,
      -0.0016910373233258724,
      -0.03652065992355347,
      0.025698339566588402,
      0.013301272876560688,
      -0.004025002475827932,
      0.035161860287189484,
      0.06861981004476547,
      -0.05197833478450775,
      0.10744190216064453,
      -0.028171846643090248,
      -0.003487150650471449,
      -0.029615303501486778,
      -0.019540809094905853,
      -0.0023539038375020027,
      -0.018199192360043526,
      0.10171856731176376,
      -0.06366318464279175,
      -0.025624971836805344,
      0.04033093899488449,
      -0.04466349631547928,
      -0.0594351626932621,
      0.007274238858371973,
      -0.06797625869512558,
      -0.04112517088651657,
      -0.03082890249788761,
      0.08989466726779938,
      -0.09754136204719543,
      -0.024551093578338623,
      0.0027213753201067448,
      0.011092795990407467,
      -0.03482229635119438,
      -0.04919639602303505,
      -0.044959906488657,
      0.018325533717870712,
      0.037900131195783615,
      0.026391778141260147,
      0.11261310428380966,
      0.01027099508792162,
      0.0017999211559072137,
      0.06339701265096664,
      0.05365608260035515,
      -0.01751869171857834,
      0.010241849347949028,
      -0.02890811488032341,
      -0.01293810922652483,
      -0.005115944892168045,
      0.021785560995340347,
      -0.005180689971894026,
      0.03494679182767868,
      0.02909848466515541
    ],
    [
      0.009800293482840061,
      -0.05176103487610817,
      -0.050508253276348114,
      0.02030058018863201,
      -0.005421179812401533,
      -0.015282221138477325,
      -0.005748261697590351,
      0.049844108521938324,
      -0.05021987855434418,
      0.007632207591086626,
      -0.045504216104745865,
      0.07309936732053757,
      -0.02943645976483822,
      0.024558601900935173,
      -0.07982669025659561,
      -0.04062553122639656,
      -0.060327645391225815,
      -0.044418029487133026,
      -0.045980311930179596,
      -0.05264876410365105,
      0.023407118394970894,
      0.019255606457591057,
      -0.05446910485625267,
      -0.011540158651769161,
      0.020014537498354912,
      0.024729304015636444,
      -0.018371475860476494,
      0.054936520755290985,
      -0.07066835463047028,
      0.033776503056287766,
      0.06493260711431503,
      0.0244296807795763,
      0.04075850173830986,
      -0.03868294134736061,
      0.03091088868677616,
      0.014637952670454979,
      0.026201514527201653,
      -0.07202834635972977,
      0.009759518317878246,
      0.05686906725168228,
      -0.02322455681860447,
      -0.011608466506004333,
      -0.07405329495668411,
      0.0036428370513021946,
      0.037167374044656754,
      0.04515672102570534,
      -0.07403213530778885,
      -0.01690066047012806,
      0.02961580455303192,
      0.07171742618083954,
      -0.03626127541065216,
      -0.01821710169315338,
      0.001680414890870452,
      0.04456903785467148,
      0.018391037359833717,
      0.02172408252954483,
      0.0025137607008218765,
      0.005133143160492182,
      -0.08396310359239578,
      -0.06946821510791779,
      0.0416165255010128,
      0.02680082619190216,
      0.03459605947136879,
      -0.013253193348646164,
      -0.004112065304070711,
      -0.01998511701822281,
      -0.003854309441521764,
      -0.05306071788072586,
      -0.008002973161637783,
      -0.048745039850473404,
      0.07738836109638214,
      -0.005714955274015665,
      0.029922373592853546,
      -0.06516734510660172,
      -0.021869027987122536,
      0.029221821576356888,
      -0.10153660178184509,
      -0.019189370796084404,
      0.04347550868988037,
      -0.06153874471783638,
      0.05519337207078934,
      -0.00343684502877295,
      0.04374762997031212,
      -0.01034501288086176,
      -0.058520812541246414,
      -0.03784237056970596,
      -0.007678075227886438,
      -0.05626658722758293,
      0.011306197382509708,
      -0.06708208471536636,
      0.03587684780359268,
      0.025162922218441963,
      0.021144935861229897,
      0.03190431743860245,
      -0.06244223937392235,
      0.10727281123399734,
      0.0742141604423523,
      -0.007425920106470585,
      -0.0357678085565567,
      0.004681399557739496,
      0.07537291944026947,
      -0.007812061812728643,
      0.024783745408058167,
      -0.019183285534381866,
      -0.02617177926003933,
      -0.02532644011080265,
      -0.08700533211231232,
      0.02293425053358078,
      -0.06664735078811646,
      0.07268431037664413,
      0.01709931716322899,
      -0.07749993354082108,
      0.06550314277410507,
      -0.012496472336351871,
      -0.02462165430188179,
      0.051314618438482285,
      0.05088675022125244,
      0.02947916090488434,
      -0.01663864217698574,
      -0.039924003183841705,
      -0.005987089592963457,
      -0.07269801944494247,
      -0.012724027037620544,
      -0.0007166691939346492,
      0.022139450535178185,
      -0.006442115176469088,
      0.02575690485537052,
      -0.07085886597633362,
      -0.003974648658186197,
      -0.03829566016793251,
      0.017111090943217278,
      -0.040777210146188736,
      0.0672738254070282,
      0.022806325927376747,
      -0.00010047154501080513,
      0.06981532275676727,
      0.02476775273680687,
      0.02900717221200466,
      0.025531424209475517,
      0.015701910480856895,
      0.05257885158061981,
      -0.04234882444143295,
      0.035785429179668427,
      0.021870678290724754,
      -0.021893881261348724,
      -0.07095450907945633,
      -0.05226911976933479,
      0.014802959747612476,
      -0.02573474310338497,
      0.0234542116522789,
      0.01690020225942135,
      0.002419469179585576,
      -0.12504176795482635,
      0.004675136413425207,
      -0.019341982901096344,
      0.01853286847472191,
      0.07498310506343842,
      -0.016352154314517975,
      -0.039118360728025436,
      0.034305449575185776,
      0.0008731933194212615,
      0.03449642285704613,
      -0.01649424061179161,
      -0.11549896001815796,
      -0.08075763285160065,
      0.00573757104575634,
      -0.013998976908624172,
      0.02500312030315399,
      -0.13372094929218292,
      -0.05505973845720291,
      -0.011144713498651981,
      -0.009788267314434052,
      -0.02204138971865177,
      0.08973682671785355,
      -0.03628150001168251,
      0.014334411360323429,
      0.006897705141454935,
      0.0018360537942498922,
      0.07512715458869934,
      -0.06516595184803009,
      -0.05957075580954552,
      -0.04020026698708534,
      0.041353125125169754,
      0.08156326413154602,
      0.02295457199215889,
      0.025181790813803673,
      0.0010340646840631962,
      -0.008992505259811878,
      -0.0749443769454956,
      -0.04150555282831192,
      -0.009923968464136124,
      0.05782493203878403,
      0.11059174686670303,
      -0.005657755304127932,
      0.04593419283628464,
      0.05031226575374603,
      0.03689524158835411,
      -0.06815540790557861,
      0.08102254569530487,
      -0.0421968512237072,
      -0.08456256240606308,
      -0.015560331754386425,
      0.007117458153516054,
      0.03327903151512146,
      0.036068208515644073,
      0.010166690684854984,
      0.02084469050168991,
      0.008748588152229786,
      -0.0196696687489748,
      -0.023692360147833824,
      -0.05104018747806549,
      0.04375624284148216,
      0.013825182802975178,
      0.02052089385688305,
      -0.046380188316106796,
      0.02978905662894249,
      0.078916534781456,
      0.04265708476305008,
      0.04142722859978676,
      0.0009656341862864792,
      -0.10961092263460159,
      -0.021545516327023506,
      0.04298514127731323,
      0.03316130489110947,
      -0.03128288686275482,
      0.010846084915101528,
      -0.08439891785383224,
      -0.014910660684108734,
      -0.029256721958518028,
      -0.058894891291856766,
      0.0012145970249548554,
      0.1344725489616394,
      0.03329475596547127,
      -0.017499370500445366,
      0.013613066636025906,
      -0.07989168912172318,
      -0.010649084113538265,
      0.06216925010085106,
      -0.001894752262160182,
      0.04520394653081894,
      0.006614292040467262,
      -0.013161439448595047,
      -0.0063468157313764095,
      -0.08221033215522766,
      -0.01583690382540226,
      0.041339173913002014,
      0.032364070415496826,
      0.01972162537276745,
      -0.0560033954679966,
      -0.04428545758128166,
      0.013329959474503994,
      -0.002991222310811281,
      0.011570807546377182,
      0.004357784520834684,
      -0.046434223651885986,
      0.009624240919947624,
      -0.030782965943217278,
      0.03835389390587807,
      -0.010523765347898006,
      -0.014068382792174816,
      0.05756889656186104,
      -0.038233209401369095,
      0.00453224778175354,
      -0.03258192166686058,
      0.04032326117157936,
      0.08827756345272064,
      -0.024594949558377266,
      -0.045757047832012177,
      -0.007507592439651489,
      -0.06268573552370071,
      0.03249349817633629,
      0.03409305959939957,
      -0.02447652630507946,
      0.028748225420713425,
      0.09455090016126633,
      -0.008218762464821339,
      -0.008974364027380943,
      0.05167723819613457,
      0.0025624404661357403,
      0.06378885358572006,
      -0.007412126287817955,
      -0.022301793098449707,
      -0.021105144172906876,
      0.031632233411073685,
      -0.0678316205739975,
      0.04758286103606224,
      -0.03724890947341919,
      0.051236122846603394,
      -0.012992220930755138,
      -0.041364073753356934,
      0.010858207009732723,
      -0.03299088031053543,
      -0.02870171144604683,
      -0.03944559022784233,
      -0.06939413398504257,
      -0.03385533392429352,
      -0.048552267253398895,
      0.06863369047641754,
      0.14762327075004578,
      0.027845673263072968,
      -0.010407093912363052,
      0.04273514077067375,
      -0.030938630923628807,
      0.011455287225544453,
      0.05876406654715538,
      -0.009857947938144207,
      -0.1253034770488739,
      -0.048875849694013596,
      0.008934221230447292,
      0.04739011079072952,
      0.05177658796310425,
      -0.04956607520580292,
      0.09568050503730774,
      0.004618678241968155,
      -0.05470597371459007,
      -0.05416632816195488,
      -0.007255903910845518,
      -0.11004221439361572,
      -0.08115783333778381,
      0.04801207035779953,
      0.08031153678894043,
      -0.016169348731637,
      -0.06273732334375381,
      -0.019617995247244835,
      -0.03644706681370735,
      0.0069904206320643425,
      0.08040829747915268,
      0.1172703206539154,
      -0.028543496504426003,
      0.057308293879032135,
      0.009810243733227253,
      0.016360238194465637,
      -0.003056081011891365,
      -0.08422975242137909,
      0.04262694716453552,
      0.06395135819911957,
      0.040144678205251694,
      -0.08124655485153198,
      -0.010968119837343693,
      0.0527716763317585,
      0.04259861633181572,
      0.042455997318029404,
      0.042563896626234055,
      -0.031798191368579865,
      0.02408720925450325,
      0.024106329306960106,
      0.02274061180651188,
      -0.01240974199026823,
      0.03151325508952141,
      -0.1010865569114685,
      0.054484084248542786,
      -0.044612012803554535,
      0.04089611396193504,
      0.018544776365160942,
      0.0211909469217062,
      0.02719421125948429,
      -0.06768892705440521,
      0.01123689953237772,
      -0.055346738547086716,
      0.07232215255498886,
      0.03094683401286602,
      0.02511146105825901,
      -0.06435757130384445,
      -0.03887603059411049,
      -0.052790477871894836,
      0.0173847284168005,
      -0.07394877076148987,
      -0.07857538759708405,
      0.0597660094499588,
      0.038485851138830185,
      0.04523672163486481,
      -0.040686964988708496,
      -0.033031053841114044,
      -0.03230729326605797,
      -0.0560043565928936,
      -0.019996244460344315,
      0.07476326078176498,
      -0.02774263173341751,
      0.029098030179739,
      0.03755318000912666,
      0.011032740585505962,
      -0.04529733583331108,
      -0.04061805456876755,
      0.02585405297577381,
      -0.012711910530924797,
      0.03602130338549614,
      -0.07235850393772125,
      -0.015520804561674595,
      -0.0219267625361681,
      0.003217014018446207,
      0.05918459966778755,
      0.0518760159611702,
      -0.07967839390039444,
      0.03799626976251602,
      -0.022107070311903954,
      -0.009554163552820683,
      -0.031694766134023666,
      -0.011327848769724369,
      0.01659959740936756,
      0.03814731910824776,
      -0.002226881915703416,
      0.036111220717430115,
      -0.03337406739592552,
      -0.028409410268068314,
      0.07794536650180817,
      0.054903287440538406,
      0.001609295723028481,
      -0.016762007027864456,
      0.011748027987778187,
      -0.07884233444929123,
      -0.04774203151464462,
      -0.004311001859605312,
      0.05993220955133438,
      0.00931775663048029,
      -0.0763576477766037,
      -0.014655161648988724,
      0.004294011276215315,
      0.04029419273138046,
      0.01746072806417942,
      0.007264970801770687,
      -0.07081639766693115,
      0.04024891182780266,
      0.01633346639573574,
      -0.05343804135918617,
      -0.037441056221723557,
      0.006125167477875948,
      -0.07108432054519653,
      -0.01558877993375063,
      0.062028102576732635,
      -0.0009241651860065758,
      0.011741329915821552,
      -0.036961331963539124,
      0.015491288155317307,
      0.059048090130090714,
      0.048020873218774796,
      0.0763639509677887,
      0.02834589220583439,
      0.0564906969666481,
      -0.05348178371787071,
      -0.027641812339425087,
      -0.030982838943600655,
      -0.055720847100019455,
      -0.010760354809463024,
      0.007715953513979912,
      0.053710706532001495,
      -0.03589896857738495,
      -0.04173646122217178,
      -0.0651906207203865,
      -0.034369096159935,
      0.038010839372873306,
      0.009194623678922653,
      0.013450486585497856,
      0.027919450774788857,
      -0.005996590480208397,
      -0.011448599398136139,
      0.027682462707161903,
      -0.045366957783699036,
      -0.025966929271817207,
      -0.011794076301157475,
      0.029369251802563667,
      -0.10429438948631287,
      -0.13063815236091614,
      -0.0004479717754293233,
      0.02010236121714115,
      0.056874215602874756,
      -0.028982212767004967,
      -0.0569358691573143,
      0.049390651285648346,
      -0.027347607538104057,
      -0.03313562646508217,
      -0.026743697002530098,
      -0.004690900444984436,
      0.014738807454705238,
      -0.04929168149828911,
      0.010798372328281403,
      0.032289303839206696,
      -0.06534919887781143,
      -0.0592479333281517,
      -0.02228722907602787,
      -0.053467631340026855,
      0.020398860797286034,
      0.06793611496686935,
      0.004628569353371859,
      0.01756058633327484,
      0.04615319147706032,
      -0.0403737910091877,
      0.029284298419952393,
      -0.03362329676747322,
      -0.08785440027713776,
      0.028994034975767136,
      0.09111601859331131,
      -0.0725696012377739,
      -0.006079551298171282,
      0.027013028040528297,
      -0.05996502563357353,
      -0.02630300261080265,
      0.03684459254145622,
      -0.00731751648709178,
      -0.018393851816654205,
      0.0367329865694046,
      0.06396017223596573,
      0.05890904739499092,
      -0.028165513649582863
    ],
    [
      0.004806314595043659,
      -0.02051568776369095,
      -0.02673465386033058,
      0.07485714554786682,
      -0.006357182748615742,
      -0.09323780983686447,
      -0.04725552350282669,
      0.0367109477519989,
      0.02990100346505642,
      -0.049187708646059036,
      -0.02222507819533348,
      0.04944382607936859,
      0.01914980448782444,
      -0.04713458567857742,
      0.01871652714908123,
      0.035781022161245346,
      -0.07601461559534073,
      0.02707749418914318,
      -0.015465805307030678,
      -0.09053657203912735,
      0.04232916235923767,
      0.029378971084952354,
      -0.006944363471120596,
      0.03200040012598038,
      -0.02146339975297451,
      -0.017310483381152153,
      -0.013394022360444069,
      -0.055626481771469116,
      -0.04722677171230316,
      -0.036856211721897125,
      0.004508337937295437,
      0.010031226091086864,
      0.08535032719373703,
      0.014559680595993996,
      -0.03652200475335121,
      -0.013017210178077221,
      0.06996762752532959,
      0.03241465240716934,
      -0.010261310264468193,
      -0.014221001416444778,
      0.01984158158302307,
      0.06697938591241837,
      -0.05088036507368088,
      0.027235284447669983,
      0.04514047130942345,
      -0.0043571460992097855,
      0.0050035445019602776,
      -0.04578745737671852,
      0.014496729709208012,
      0.00014903243572916836,
      0.07580633461475372,
      -0.061084259301424026,
      0.023448171094059944,
      -0.04281390830874443,
      0.012717385776340961,
      0.039079438894987106,
      0.033947937190532684,
      0.04605819284915924,
      -0.039674095809459686,
      -0.0038077239878475666,
      0.0024040015414357185,
      -0.05918186902999878,
      0.021457094699144363,
      -0.01822463609278202,
      -0.012060275301337242,
      0.0014334304723888636,
      -0.03469317778944969,
      0.0370507650077343,
      -0.08146783709526062,
      -0.060469187796115875,
      -0.030397379770874977,
      0.040390077978372574,
      -0.02909490093588829,
      -0.00496629998087883,
      0.010331661440432072,
      -0.020083680748939514,
      0.011121119372546673,
      0.03738342970609665,
      0.022450605407357216,
      -0.002691407222300768,
      -0.06992032378911972,
      -0.002954266732558608,
      0.03291788324713707,
      -0.0048531582579016685,
      0.03786996006965637,
      -0.0978146344423294,
      -0.036657657474279404,
      0.04384435713291168,
      0.025019710883498192,
      -0.023850996047258377,
      0.07036573439836502,
      0.014321801252663136,
      -0.0026536856312304735,
      0.04652722552418709,
      -0.05579562485218048,
      -0.024355681613087654,
      0.03828989714384079,
      -0.004223562777042389,
      -0.04617921635508537,
      0.01798568107187748,
      0.07692713290452957,
      -0.007342176511883736,
      -0.015500570647418499,
      0.013225281611084938,
      0.09331105649471283,
      0.012406133115291595,
      0.03567265346646309,
      0.03880329057574272,
      -0.008435794152319431,
      -0.03031937964260578,
      -0.006681636907160282,
      -0.03075169026851654,
      0.001748825190588832,
      0.030100803822278976,
      -0.023100240156054497,
      -0.10311707109212875,
      0.093675896525383,
      -0.0058578248135745525,
      -0.012576473876833916,
      0.007436980027705431,
      0.024820499122142792,
      -0.03011893481016159,
      0.040286920964717865,
      -0.01818813383579254,
      -0.05191417410969734,
      0.014090017415583134,
      0.005208283197134733,
      0.02251269482076168,
      0.01945061981678009,
      -0.04017273709177971,
      0.023824572563171387,
      0.030052712187170982,
      -0.07457686960697174,
      -0.06352786719799042,
      -1.0334366606912226e-06,
      -0.08839794248342514,
      0.11216545104980469,
      0.006337023805826902,
      0.014069439843297005,
      0.07442545890808105,
      0.016528645530343056,
      -0.058770373463630676,
      0.024035362526774406,
      0.061691995710134506,
      0.004475344903767109,
      0.10894553363323212,
      0.005850972142070532,
      -0.035958413034677505,
      0.08216409385204315,
      0.03785216063261032,
      0.0008615816477686167,
      0.01908404380083084,
      0.0001864428340923041,
      -0.03648243099451065,
      0.012687137350440025,
      -0.032944533973932266,
      0.024702655151486397,
      0.01646575890481472,
      -0.060825422406196594,
      0.038934871554374695,
      0.061951495707035065,
      0.017662834376096725,
      -0.05988748371601105,
      -0.07564093172550201,
      0.020030297338962555,
      -0.08494579046964645,
      0.027018515393137932,
      0.07331269979476929,
      -0.04194510355591774,
      -0.03852686658501625,
      -0.0011745730880647898,
      -0.054891619831323624,
      -0.04486902803182602,
      0.057539619505405426,
      -0.04037272930145264,
      -0.011757932603359222,
      -0.06085797771811485,
      0.04512898996472359,
      0.08094871789216995,
      -0.08012962341308594,
      0.014427914284169674,
      -0.0011167534394189715,
      -0.043978843837976456,
      0.035956334322690964,
      -0.0011685863137245178,
      0.0475994311273098,
      0.008340593427419662,
      -0.045817773789167404,
      -0.001826080959290266,
      -0.007226766087114811,
      0.048451557755470276,
      0.026184411719441414,
      -0.026577657088637352,
      -0.03737105801701546,
      0.022660210728645325,
      0.015194691717624664,
      -0.032348114997148514,
      0.03517588973045349,
      -0.008060374297201633,
      -0.007052504923194647,
      -0.04826075956225395,
      0.07296930253505707,
      -0.04351373761892319,
      0.08157958090305328,
      -0.06925218552350998,
      0.03151914104819298,
      -0.05578628554940224,
      0.026145901530981064,
      -0.04794573411345482,
      -0.006528498139232397,
      -0.021060798317193985,
      -0.042030174285173416,
      0.04094475507736206,
      0.050106436014175415,
      0.044761501252651215,
      0.025822684168815613,
      -0.07871690392494202,
      -0.033688124269247055,
      -0.04409527778625488,
      -0.022075731307268143,
      0.06403005868196487,
      0.02352987229824066,
      0.03161332756280899,
      -0.0007648098981007934,
      -0.018405860289931297,
      -0.05581626668572426,
      -0.048557475209236145,
      -0.059959929436445236,
      -0.0037019315641373396,
      -0.011506946757435799,
      -0.01154395006597042,
      -0.02799992263317108,
      0.0206929799169302,
      -0.02810809202492237,
      -0.002579344902187586,
      -0.019266648218035698,
      0.03594272956252098,
      -0.04128686338663101,
      0.005854626651853323,
      -0.056771278381347656,
      -0.02025996893644333,
      -0.00047104404075071216,
      0.05061908811330795,
      -0.012454049661755562,
      -0.08756305277347565,
      0.015562325716018677,
      0.058863263577222824,
      -0.006641814950853586,
      -0.014989457093179226,
      -0.019847014918923378,
      -0.0156778022646904,
      0.008913986384868622,
      0.015572059899568558,
      -0.006190540734678507,
      0.02244342863559723,
      -0.02070334367454052,
      0.09359052032232285,
      0.04197987541556358,
      0.017768539488315582,
      0.08469128608703613,
      -0.0313461571931839,
      0.056505050510168076,
      -0.017551157623529434,
      -0.06875525414943695,
      -0.031992241740226746,
      0.04319736734032631,
      0.06699647009372711,
      0.0261404886841774,
      -0.028127258643507957,
      0.03756054863333702,
      -0.05920818820595741,
      -0.036402445286512375,
      0.019428536295890808,
      -0.08224719762802124,
      0.06761448830366135,
      0.026540391147136688,
      0.03466787561774254,
      0.05055012181401253,
      0.0021229053381830454,
      0.010878059081733227,
      -0.09610854089260101,
      0.017499960958957672,
      0.06115850433707237,
      0.03737140819430351,
      -0.020104113966226578,
      0.058018557727336884,
      0.03323883190751076,
      0.05609075725078583,
      0.01448327861726284,
      -0.017177101224660873,
      0.04618917033076286,
      -0.027308478951454163,
      0.03908227011561394,
      0.01639055646955967,
      -0.008441022597253323,
      0.050268225371837616,
      0.024611888453364372,
      -0.011166637763381004,
      -0.015335268341004848,
      0.09002920240163803,
      0.04353920370340347,
      -0.014819229021668434,
      -0.023315103724598885,
      -0.058545101433992386,
      -0.0033176890574395657,
      0.043520912528038025,
      -0.06182382255792618,
      0.04709412902593613,
      -0.03267619386315346,
      -0.045724812895059586,
      -0.07054568082094193,
      0.02324649877846241,
      0.04216668754816055,
      -0.06109745427966118,
      0.005491666961461306,
      -0.029696902260184288,
      0.014075800776481628,
      -0.013026051223278046,
      -0.057926956564188004,
      -0.017333636060357094,
      0.02916017547249794,
      0.1226310208439827,
      -0.011923791840672493,
      -0.08091885596513748,
      0.0548037588596344,
      -0.0481581948697567,
      0.10880274325609207,
      0.0026346377562731504,
      -0.004291994962841272,
      -0.05189262330532074,
      0.027477465569972992,
      0.06518806517124176,
      0.08668389916419983,
      0.008417855948209763,
      -0.029748015105724335,
      0.09613595902919769,
      0.025024713948369026,
      0.010793198831379414,
      -0.03747539594769478,
      0.08697745949029922,
      -0.039207667112350464,
      -0.024924680590629578,
      0.07316361367702484,
      -0.07336276024580002,
      -0.028773251920938492,
      -0.010018128901720047,
      0.03748081624507904,
      0.002144617261365056,
      0.007413174491375685,
      -0.05074320361018181,
      0.02043241634964943,
      -0.004512812476605177,
      0.00624863849952817,
      -0.017158176749944687,
      0.06157168745994568,
      0.05558379739522934,
      -0.0922200083732605,
      0.06419020146131516,
      -0.017060184851288795,
      -0.05181657522916794,
      0.016808204352855682,
      -0.04876687005162239,
      0.024995148181915283,
      -0.010372824035584927,
      0.02382499910891056,
      -0.06512103974819183,
      -0.038904305547475815,
      0.03898487985134125,
      0.0075892009772360325,
      -0.00810090359300375,
      -0.012276243418455124,
      -0.026137061417102814,
      0.005646967329084873,
      0.028012216091156006,
      0.029323086142539978,
      0.017028270289301872,
      0.006718944292515516,
      0.01819748990237713,
      0.006735627539455891,
      0.015642419457435608,
      -0.009060641750693321,
      -0.025249755010008812,
      -0.033635348081588745,
      -0.009809840470552444,
      -0.024823490530252457,
      -0.0014979270054027438,
      -0.030236950144171715,
      -0.0070655690506100655,
      0.08478094637393951,
      0.02151254005730152,
      -0.01868959702551365,
      0.15460078418254852,
      0.020378846675157547,
      0.03320072218775749,
      0.0001898762711789459,
      -0.026317080482840538,
      0.11110510677099228,
      0.030040478333830833,
      0.004302585031837225,
      0.06321580708026886,
      -0.021921439096331596,
      -0.023206328973174095,
      -0.044266507029533386,
      0.015595335513353348,
      -0.08319040387868881,
      0.006000198423862457,
      0.015303153544664383,
      -0.05495084449648857,
      0.10226345807313919,
      -0.009686651639640331,
      0.014646857976913452,
      -0.015475180931389332,
      0.00447149807587266,
      0.04347936809062958,
      0.022089948877692223,
      0.02057993970811367,
      0.0004086549743078649,
      -0.05247391015291214,
      0.02363976463675499,
      0.037301234900951385,
      4.8249905375996605e-05,
      -0.011942332610487938,
      0.029058178886771202,
      0.04414040595293045,
      -0.026121798902750015,
      -0.05935605987906456,
      0.004178438801318407,
      0.05041993409395218,
      0.009674984961748123,
      0.015523435547947884,
      0.0014481409452855587,
      0.0642881765961647,
      -0.00956669356673956,
      0.036594655364751816,
      0.02393665909767151,
      0.03484705090522766,
      0.004372184630483389,
      0.06936733424663544,
      0.03731916844844818,
      -0.019079169258475304,
      0.0314534567296505,
      -0.04941549524664879,
      -0.022638315334916115,
      0.08842553198337555,
      0.02039875090122223,
      -0.019843855872750282,
      0.07038219273090363,
      0.04601813480257988,
      -0.0051825628615915775,
      -0.004808998201042414,
      -0.0010704901069402695,
      0.023146364837884903,
      0.008930842392146587,
      -0.06743379682302475,
      0.046896498650312424,
      0.0036332786548882723,
      -0.021887363865971565,
      0.0773470476269722,
      0.03287632763385773,
      0.031108111143112183,
      -0.020477386191487312,
      -0.02222304977476597,
      -0.010213915258646011,
      0.10884109139442444,
      -0.020834211260080338,
      0.02552681602537632,
      -0.04390624165534973,
      -0.028099248185753822,
      0.03871483355760574,
      0.06433159112930298,
      -0.08267424255609512,
      0.0178938377648592,
      -0.011531462892889977,
      0.018721116706728935,
      -0.03346667438745499,
      0.01672702096402645,
      -0.009076561778783798,
      0.024089675396680832,
      0.011958280578255653,
      0.053685106337070465,
      -0.05002822354435921,
      0.034107133746147156,
      -0.061868730932474136,
      -0.04223795607686043,
      0.07524508982896805,
      0.03141294792294502,
      -0.022858740761876106,
      0.0845610722899437,
      0.004207936115562916,
      0.03395726904273033,
      0.1021004170179367,
      0.013481340371072292,
      -0.00948898121714592,
      -0.026771018281579018,
      0.0491771437227726,
      0.05520010367035866,
      0.010593456216156483,
      0.019384723156690598,
      0.012840881943702698,
      0.04859084263443947,
      -0.019555171951651573,
      0.027032826095819473,
      0.05517654865980148
    ],
    [
      -0.03761366382241249,
      -0.016058992594480515,
      0.004071569070219994,
      -0.02758174203336239,
      -0.03936106339097023,
      -0.05999542400240898,
      0.024364635348320007,
      0.01824915036559105,
      0.05935294181108475,
      0.03439079225063324,
      -0.038666412234306335,
      -0.026621926575899124,
      0.07456257939338684,
      -0.04918748512864113,
      -0.01016224455088377,
      -0.04602668061852455,
      -0.005310526583343744,
      0.006569807417690754,
      0.060451146215200424,
      0.042883820831775665,
      -0.004539801739156246,
      0.014852890744805336,
      0.06320063024759293,
      -0.0248380359262228,
      -0.01868640072643757,
      0.03474190831184387,
      0.006301822140812874,
      -0.032386671751737595,
      0.001452247379347682,
      0.024981239810585976,
      0.04270622879266739,
      -0.00758633017539978,
      0.05804821476340294,
      0.039447009563446045,
      0.02246706560254097,
      -0.04725532606244087,
      0.06253669410943985,
      -0.038650643080472946,
      -0.10983177274465561,
      -0.03791918233036995,
      0.051446542143821716,
      0.02883056551218033,
      -0.02881508879363537,
      0.022705862298607826,
      0.04102875664830208,
      0.062094300985336304,
      -0.02875136025249958,
      -0.01658388040959835,
      0.019956247881054878,
      0.06166227534413338,
      -0.013388863764703274,
      -0.03926672413945198,
      0.0171686839312315,
      -0.09118883311748505,
      0.00014840356016065925,
      0.02172045409679413,
      0.002101765712723136,
      -0.018231095746159554,
      0.027844738215208054,
      -0.01644768752157688,
      -0.04833132401108742,
      0.0028243614360690117,
      -0.0012607142562046647,
      0.028422202914953232,
      0.005565158557146788,
      0.07216237485408783,
      0.014568207785487175,
      0.010583295486867428,
      0.018867025151848793,
      0.004912410397082567,
      -0.0033577587455511093,
      -0.006042500026524067,
      -0.040415577590465546,
      -0.0687779039144516,
      0.019152717664837837,
      -0.017132695764303207,
      0.05176811292767525,
      -0.025115247815847397,
      0.08419156819581985,
      -0.01369805634021759,
      0.04889563098549843,
      -0.009723063558340073,
      0.0353836752474308,
      0.03101040981709957,
      -0.08530500531196594,
      -0.09215483069419861,
      0.011591626331210136,
      -0.03348845615983009,
      -0.05707334354519844,
      -0.0917617455124855,
      0.04768272861838341,
      0.002411276800557971,
      -0.007645422592759132,
      -0.07473316788673401,
      -0.02090621553361416,
      -0.0037546176463365555,
      0.011837092228233814,
      -0.031675711274147034,
      -0.06270687282085419,
      -0.03047413006424904,
      0.0051604462787508965,
      0.06999893486499786,
      -0.0546083003282547,
      0.056734632700681686,
      -0.019814977422356606,
      0.06350083649158478,
      0.027250349521636963,
      0.006486440077424049,
      -0.018541764467954636,
      0.029455099254846573,
      0.09439852088689804,
      0.005823381245136261,
      0.025953803211450577,
      0.03234230726957321,
      0.061388127505779266,
      0.0074987453408539295,
      0.06051954999566078,
      0.09235764294862747,
      0.07958738505840302,
      0.047960832715034485,
      0.09732718020677567,
      0.016787176951766014,
      0.027918271720409393,
      -0.01046499703079462,
      0.02369077131152153,
      0.13743723928928375,
      0.1325499713420868,
      -0.0056205554865300655,
      -0.04509067162871361,
      -0.02705896832048893,
      -0.05943633243441582,
      -0.007358401082456112,
      -0.024963127449154854,
      0.04577146843075752,
      -0.019844109192490578,
      -0.04422976076602936,
      -0.023105809465050697,
      -0.016141165047883987,
      -0.021040529012680054,
      0.08359234035015106,
      0.08899925649166107,
      0.08345635235309601,
      0.00035264575853943825,
      0.008120543323457241,
      0.008379906415939331,
      0.03484698385000229,
      0.04250459000468254,
      -0.04119279608130455,
      -0.003044768236577511,
      -0.03071654587984085,
      0.07945000380277634,
      0.05452052131295204,
      -0.04800836741924286,
      0.023677466437220573,
      -0.024875648319721222,
      0.010438427329063416,
      -0.05679688602685928,
      0.0629168152809143,
      -0.04695893079042435,
      0.005015341565012932,
      0.02051711454987526,
      -0.009771525859832764,
      -0.08393894135951996,
      -0.0678907111287117,
      -0.048240698873996735,
      0.0029283580370247364,
      -0.0920415073633194,
      0.005093791056424379,
      0.04028469696640968,
      0.0402287095785141,
      -0.05232378467917442,
      -0.004677922464907169,
      0.006367653142660856,
      -0.027035584673285484,
      0.025205209851264954,
      0.0007198895909823477,
      -0.034213509410619736,
      0.04015412926673889,
      0.025474771857261658,
      0.06509391218423843,
      0.024654602631926537,
      0.0783146396279335,
      -0.05509277805685997,
      -0.02486669085919857,
      -0.010159933939576149,
      0.00952837336808443,
      -0.020415619015693665,
      -0.035837143659591675,
      0.004168548621237278,
      0.031139744445681572,
      0.006505658850073814,
      -0.030497200787067413,
      0.04286357760429382,
      0.038492925465106964,
      0.032398223876953125,
      -0.05589629337191582,
      -0.06385638564825058,
      0.041416630148887634,
      0.046179916709661484,
      -0.05759543925523758,
      -0.0006780562689527869,
      -0.007147656287997961,
      -0.010776686482131481,
      0.05500209704041481,
      -0.033985480666160583,
      -0.07262936979532242,
      -0.04818949103355408,
      -0.04277098551392555,
      -0.02316143363714218,
      -0.006690108682960272,
      -0.04022323712706566,
      -0.01898685283958912,
      0.048696111887693405,
      -0.010109465569257736,
      -0.03322611004114151,
      -0.014125245623290539,
      0.023565446957945824,
      0.04711398109793663,
      -0.02450791373848915,
      -0.01846305839717388,
      0.0815616250038147,
      -0.020475920289754868,
      -0.026829883456230164,
      -0.054290737956762314,
      0.0391460545361042,
      0.0320013128221035,
      0.02956673875451088,
      -0.046580199152231216,
      0.002883339999243617,
      -0.04587823525071144,
      0.0747544914484024,
      0.016504401341080666,
      0.022379809990525246,
      -0.04385392740368843,
      -0.0009971467079594731,
      -0.014337481930851936,
      -0.08965634554624557,
      -0.0625004768371582,
      0.07740756124258041,
      -0.022203415632247925,
      0.057355847209692,
      -0.032909490168094635,
      0.0017899078084155917,
      -0.11592631042003632,
      -0.06286350637674332,
      -0.04369989037513733,
      -0.04063236713409424,
      -0.01386518869549036,
      -0.0705730989575386,
      -0.016946984454989433,
      -0.0009736967622302473,
      -0.05014677345752716,
      -0.020825380459427834,
      0.03725150600075722,
      0.028095612302422523,
      0.046871960163116455,
      -0.04127512127161026,
      -0.020031986758112907,
      -8.283818897325546e-05,
      -0.02538108639419079,
      -0.03697928786277771,
      -0.02617400512099266,
      0.09952379763126373,
      0.04912106692790985,
      -0.01468348503112793,
      0.033285804092884064,
      0.018401702865958214,
      0.006578932050615549,
      0.08617021888494492,
      0.01671208068728447,
      0.02989458478987217,
      0.05618230253458023,
      -0.024733811616897583,
      -0.06616684794425964,
      -0.0602441243827343,
      -0.022133098915219307,
      -0.03042447380721569,
      -0.02130173332989216,
      -7.036751412670128e-06,
      0.11607706546783447,
      0.003922795411199331,
      0.005737343803048134,
      -0.003746729576960206,
      0.0428340807557106,
      -0.03170843422412872,
      -0.07753577828407288,
      -0.002639147685840726,
      0.06899679452180862,
      0.011607887223362923,
      -0.03671613335609436,
      -0.010258116759359837,
      0.01829204335808754,
      0.0333576574921608,
      -0.09546786546707153,
      0.02138645388185978,
      -0.025524547323584557,
      -0.003928563557565212,
      0.018901169300079346,
      -0.010044557973742485,
      -0.002639125334098935,
      -0.057285960763692856,
      -0.015837667509913445,
      0.011666444130241871,
      0.009599459357559681,
      0.07148007303476334,
      0.011215414851903915,
      -0.014935528859496117,
      0.0035950997844338417,
      -0.031129861250519753,
      -0.004191120155155659,
      0.03163390979170799,
      -0.10508168488740921,
      0.07643727958202362,
      0.002447041915729642,
      0.019946493208408356,
      0.055352624505758286,
      0.035487525165081024,
      -0.02723582461476326,
      -0.04361611232161522,
      0.03129924088716507,
      -0.08196180313825607,
      0.022392937913537025,
      0.05791185423731804,
      0.03363114967942238,
      -0.07301376014947891,
      0.053371232002973557,
      0.0633295327425003,
      0.044923730194568634,
      -0.08167178183794022,
      -0.06437825411558151,
      -0.01570083573460579,
      -0.03646417334675789,
      -0.014465013518929482,
      0.04086920619010925,
      -0.025658706203103065,
      0.07127662748098373,
      0.039874088019132614,
      0.03107675164937973,
      0.07379628717899323,
      0.05781140923500061,
      -0.059207484126091,
      0.03613608703017235,
      0.027697058394551277,
      0.07109778374433517,
      -0.0626198798418045,
      -0.04476526379585266,
      0.004365488421171904,
      -0.02703499235212803,
      0.07001147419214249,
      0.020439503714442253,
      0.08368407934904099,
      0.043504469096660614,
      0.02065252512693405,
      0.17093384265899658,
      0.03735845908522606,
      0.028704164549708366,
      -0.024654623121023178,
      -0.028359469026327133,
      -0.07177037745714188,
      0.015494324266910553,
      0.07097245752811432,
      -0.03516511246562004,
      -0.019528720527887344,
      -0.00519314082339406,
      -0.004156891256570816,
      0.026792515069246292,
      -0.08676434308290482,
      0.006680332124233246,
      0.053974974900484085,
      0.032866220921278,
      -0.03412124514579773,
      0.005452129524201155,
      0.11447083950042725,
      -0.050092220306396484,
      -0.016924791038036346,
      -0.05007537081837654,
      0.002231986727565527,
      -0.04897446185350418,
      -0.07427765429019928,
      0.0020303765777498484,
      -0.007448340300470591,
      0.06785561889410019,
      0.017837107181549072,
      -0.029679514467716217,
      0.03713318705558777,
      -0.03658890351653099,
      -0.040080346167087555,
      0.033184099942445755,
      0.015134386718273163,
      0.0056182690896093845,
      -0.10834284871816635,
      0.0020430879667401314,
      -0.04345042258501053,
      -0.050919048488140106,
      -0.00557753024622798,
      -0.025110293179750443,
      0.02724749594926834,
      -0.04206712543964386,
      -0.00984139647334814,
      0.009476901032030582,
      0.08448883891105652,
      0.08430007845163345,
      0.014834019355475903,
      0.04413748160004616,
      -0.03338221460580826,
      0.11941162496805191,
      -0.019039154052734375,
      0.00461998488754034,
      -8.581300789956003e-06,
      0.01800297386944294,
      0.02508344128727913,
      0.023751357570290565,
      0.06686392426490784,
      0.0023651125375181437,
      -0.05764647573232651,
      -0.015224537812173367,
      -0.008976698853075504,
      -0.03354194387793541,
      -0.015890957787632942,
      -0.048252545297145844,
      -0.03903467580676079,
      -0.022692928090691566,
      -0.1074090376496315,
      -0.028495868667960167,
      -0.006722745019942522,
      -0.02703149989247322,
      0.0616767443716526,
      0.02554880827665329,
      0.07496422529220581,
      0.049923125654459,
      0.0002466736186761409,
      -0.006908610463142395,
      0.009297564625740051,
      -0.04177449643611908,
      0.07759705930948257,
      0.026896018534898758,
      0.04971706494688988,
      0.028546325862407684,
      0.06506085395812988,
      0.05663405358791351,
      -0.01236754097044468,
      -0.062052324414253235,
      -0.016375571489334106,
      0.031493429094552994,
      0.016026416793465614,
      -0.06180337071418762,
      0.029751552268862724,
      0.02959628961980343,
      -0.05925231799483299,
      0.025821221992373466,
      0.009923327714204788,
      -0.0059888227842748165,
      -0.009774165228009224,
      -0.034073930233716965,
      0.011949855834245682,
      -0.010722192004323006,
      -0.008458271622657776,
      0.08254089206457138,
      0.013223267160356045,
      0.007123942952603102,
      0.015570925548672676,
      0.02257167175412178,
      -0.06698063760995865,
      0.04954089969396591,
      -0.0027419086545705795,
      -0.022989187389612198,
      -0.024473557248711586,
      -0.10124553740024567,
      -0.0028998202178627253,
      0.009852210991084576,
      -0.0038307637441903353,
      -0.06648927927017212,
      -0.021594243124127388,
      -0.018104316666722298,
      0.0452214777469635,
      0.0027799620293080807,
      0.004592157434672117,
      0.030294252559542656,
      -0.032990727573633194,
      0.06106145679950714,
      -0.0040970174595713615,
      0.004687262233346701,
      0.010774458758533001,
      -0.011350545100867748,
      -0.019090790301561356,
      0.005555538926273584,
      0.09065014868974686,
      -0.015400157310068607,
      -0.03286021947860718,
      -0.05701553076505661,
      0.030147947371006012,
      -0.05503528192639351,
      0.06787959486246109,
      0.06669273227453232,
      -0.03863488510251045,
      0.006764382589608431,
      0.0336197204887867,
      -0.03666314482688904,
      0.009257475845515728,
      0.011029195040464401,
      -0.03975757211446762,
      -0.042212195694446564,
      0.015755487605929375
    ],
    [
      -0.08257674425840378,
      -0.02586008422076702,
      -0.04786704480648041,
      -0.08967415988445282,
      0.04154672101140022,
      0.009780900552868843,
      -0.04789676144719124,
      0.04388536140322685,
      -0.0448635071516037,
      0.020832976326346397,
      0.00949541013687849,
      0.0014149745693430305,
      -0.0072969431057572365,
      0.018099429085850716,
      -0.003497997298836708,
      -0.0877564400434494,
      0.027747515588998795,
      0.06692030280828476,
      -0.0554841086268425,
      0.021320784464478493,
      -0.04672647640109062,
      -0.07242883741855621,
      0.07907935976982117,
      0.09209535270929337,
      -0.025449620559811592,
      -0.02565808966755867,
      -0.01102368626743555,
      0.058577556163072586,
      -0.018930498510599136,
      -0.006206340156495571,
      0.10539479553699493,
      0.017471298575401306,
      0.009484106674790382,
      0.0077017745934426785,
      -0.018688032403588295,
      -0.013741186819970608,
      -0.0016048100078478456,
      0.0130300372838974,
      0.08852096647024155,
      -0.10066018998622894,
      0.05500035732984543,
      0.02499454841017723,
      -0.06167528033256531,
      0.030203167349100113,
      0.0608273409307003,
      0.026152707636356354,
      -0.08147996664047241,
      -0.10437130182981491,
      0.08339591324329376,
      -0.0044125160202383995,
      -0.05316654592752457,
      -0.005977982189506292,
      -0.027661239728331566,
      -0.09540872275829315,
      0.06304793804883957,
      -0.04278725013136864,
      -0.04686712473630905,
      0.038407422602176666,
      0.05469709634780884,
      0.01295520830899477,
      0.056356944143772125,
      0.054030437022447586,
      0.06371413171291351,
      -0.10282454639673233,
      0.04846549406647682,
      -0.03398407995700836,
      -0.08615828305482864,
      -0.053562622517347336,
      0.06151564419269562,
      0.05367566645145416,
      0.032976262271404266,
      0.008391773328185081,
      0.010210111737251282,
      -0.0011573536321520805,
      -0.07840877771377563,
      -0.07437007129192352,
      -0.02842881716787815,
      -0.007149974349886179,
      0.05722955986857414,
      -0.02431204915046692,
      -0.08183522522449493,
      -0.007171432953327894,
      -0.038777172565460205,
      0.04662454128265381,
      -0.11834496259689331,
      0.061163827776908875,
      0.008026113733649254,
      -0.04877424240112305,
      0.10015111416578293,
      -0.039117198437452316,
      0.03920093923807144,
      0.026666494086384773,
      -0.09479054808616638,
      -0.009874079376459122,
      0.014572805725038052,
      -0.013775342144072056,
      0.006101734004914761,
      -0.001898771384730935,
      -0.003978744149208069,
      -0.028597446158528328,
      -0.0667770653963089,
      0.03997093066573143,
      -0.07871631532907486,
      0.018576515838503838,
      -0.006577586755156517,
      0.05457356944680214,
      -0.01517028920352459,
      -0.025999411940574646,
      0.0019473659340292215,
      0.05281645432114601,
      -0.002267681062221527,
      0.03671152517199516,
      -0.0119129978120327,
      -0.06270983070135117,
      -0.055051859468221664,
      -0.008512391708791256,
      -0.0038341262843459845,
      0.02668866701424122,
      0.0841556042432785,
      -0.06687527894973755,
      0.011304696090519428,
      0.010518857277929783,
      0.015386898070573807,
      -0.02718479000031948,
      0.04863714426755905,
      0.025289099663496017,
      0.08740647882223129,
      -0.025260815396904945,
      -0.03163939341902733,
      0.04695715755224228,
      0.03257180005311966,
      0.013895236887037754,
      -0.05460377782583237,
      0.009481391869485378,
      -0.019131764769554138,
      -0.023711055517196655,
      0.01738114096224308,
      0.0008899017702788115,
      -0.03610509634017944,
      0.044364046305418015,
      -0.005330492276698351,
      -0.04631343483924866,
      -0.0856122225522995,
      0.03870820254087448,
      -0.010053523816168308,
      0.022282006219029427,
      0.03897544741630554,
      -0.032112542539834976,
      0.03571288660168648,
      0.05365164950489998,
      0.03851950541138649,
      -0.01803438365459442,
      0.0955752432346344,
      -0.037122517824172974,
      -0.054910119622945786,
      0.040163952857255936,
      -0.034314028918743134,
      -0.0025250704493373632,
      0.062386784702539444,
      0.02953502908349037,
      0.0430261604487896,
      0.037690285593271255,
      -0.026841141283512115,
      -0.025281641632318497,
      -0.010216894559562206,
      -0.0005310407723300159,
      0.02883061394095421,
      0.027921510860323906,
      -0.05853626877069473,
      0.0754869356751442,
      -0.015802228823304176,
      0.007389461621642113,
      -0.02730831690132618,
      -0.038683172315359116,
      0.0020654862746596336,
      0.013680375181138515,
      -0.005977221764624119,
      -0.0655977874994278,
      -0.08218810707330704,
      -0.004554251208901405,
      0.01501433365046978,
      0.017143644392490387,
      0.05332404747605324,
      -0.020879466086626053,
      -0.004139715805649757,
      -0.052576206624507904,
      0.08916832506656647,
      -0.06834431737661362,
      -0.0038053507450968027,
      0.059766583144664764,
      -0.013846369460225105,
      -0.0836353525519371,
      -0.03673011064529419,
      -0.011260244064033031,
      -0.06370217353105545,
      0.0024160819593816996,
      0.004787581041455269,
      -0.009970317594707012,
      0.06415902078151703,
      -0.026302866637706757,
      0.003915340639650822,
      0.13052041828632355,
      -0.004785182885825634,
      -0.00804487057030201,
      0.01609349250793457,
      -0.029740316793322563,
      0.03318527713418007,
      -0.030476031824946404,
      -0.02004329115152359,
      -0.004262788686901331,
      -0.1158740296959877,
      -0.048677556216716766,
      9.673431486589834e-05,
      0.01628241129219532,
      -0.01342706847935915,
      0.00785735808312893,
      -0.07837235182523727,
      0.04744976758956909,
      0.06693287938833237,
      -0.014001837000250816,
      -0.048470743000507355,
      -0.014740260317921638,
      -0.055388860404491425,
      -0.00915686134248972,
      0.03914402425289154,
      -0.010395469143986702,
      0.04654697701334953,
      -0.006864084862172604,
      0.03076522797346115,
      -0.015631016343832016,
      0.0452728234231472,
      0.0097349863499403,
      0.046678055077791214,
      -0.05100828781723976,
      0.014710146002471447,
      0.05364624783396721,
      -0.03155824542045593,
      0.00407849857583642,
      -0.053121548146009445,
      -0.02595699019730091,
      0.0870213434100151,
      -0.03518656641244888,
      -0.011172045022249222,
      -0.03674483299255371,
      -0.024339428171515465,
      -0.04877541586756706,
      -0.024412434548139572,
      -0.002240650588646531,
      0.03722500428557396,
      -0.09358705580234528,
      -0.06723104417324066,
      0.06137869507074356,
      -0.027692746371030807,
      -0.004573162645101547,
      0.014786737971007824,
      -0.04195694252848625,
      -0.03894292190670967,
      -0.05340433493256569,
      0.03340921923518181,
      0.040780626237392426,
      0.06601811945438385,
      -0.026514211669564247,
      0.007677611894905567,
      0.043896060436964035,
      -0.007675587199628353,
      -0.013430011458694935,
      -0.03239680081605911,
      0.010759757831692696,
      -0.005315097514539957,
      0.049914389848709106,
      -0.05030073598027229,
      0.006594318430870771,
      0.10293827205896378,
      -0.01693832315504551,
      0.042324889451265335,
      0.04954048991203308,
      -0.09012500196695328,
      -0.060780324041843414,
      0.0009090701350942254,
      -0.058033425360918045,
      0.026450496166944504,
      0.07820488512516022,
      0.011868364177644253,
      0.007295379880815744,
      -0.06385736167430878,
      0.02993128076195717,
      -0.045991115272045135,
      0.04766897112131119,
      0.013414292596280575,
      0.025288626551628113,
      -0.021165354177355766,
      0.014917268417775631,
      -0.06251406669616699,
      0.024837585166096687,
      0.005993677768856287,
      -0.015587628819048405,
      0.013533798977732658,
      -0.013810488395392895,
      0.029978254809975624,
      -0.01797487959265709,
      -0.018695726990699768,
      0.016739847138524055,
      -0.026358801871538162,
      0.045652344822883606,
      -0.040501903742551804,
      -0.04046851396560669,
      -0.04201433062553406,
      -0.001465948880650103,
      0.03483562916517258,
      -0.039444658905267715,
      0.052538592368364334,
      -0.043628592044115067,
      -0.0033662060741335154,
      0.0036916802637279034,
      -0.004969509784132242,
      0.004869339521974325,
      0.05533068627119064,
      0.01296964567154646,
      -0.041881971061229706,
      0.025847405195236206,
      -0.04227332025766373,
      -0.08079593628644943,
      0.041756268590688705,
      -0.07004036754369736,
      0.03463808447122574,
      0.01727169379591942,
      0.03960830345749855,
      -0.03653135895729065,
      0.02104777842760086,
      0.035137273371219635,
      -0.004123312886804342,
      0.01080131996423006,
      -0.08908426761627197,
      -0.035516589879989624,
      0.031579844653606415,
      0.071617491543293,
      0.08281202614307404,
      0.046737994998693466,
      0.02932269126176834,
      0.040724609047174454,
      0.01709691621363163,
      0.050366926938295364,
      0.12854315340518951,
      0.006995397619903088,
      -0.0559961274266243,
      0.0034258109517395496,
      -0.013972836546599865,
      -1.1934097528865095e-05,
      0.03376248478889465,
      -0.06095614284276962,
      -0.032888978719711304,
      0.020963439717888832,
      0.007099935784935951,
      0.019489893689751625,
      -0.026536675170063972,
      0.057461995631456375,
      -0.047351788729429245,
      0.015367121435701847,
      0.04954449459910393,
      -0.049734748899936676,
      0.008099360391497612,
      -0.0005038860836066306,
      0.02958882786333561,
      0.01345909759402275,
      -0.04637397080659866,
      -0.046314604580402374,
      -0.08938512951135635,
      0.09813880175352097,
      -0.06905132532119751,
      0.017606928944587708,
      -0.0026888935826718807,
      -0.022504132241010666,
      -0.05002709478139877,
      0.033485379070043564,
      0.04117865487933159,
      0.0027000464033335447,
      0.018807481974363327,
      0.05920084938406944,
      0.06073290854692459,
      0.01863912120461464,
      0.05220978707075119,
      -0.060448095202445984,
      0.020562779158353806,
      -0.07177922129631042,
      -0.02614031359553337,
      0.005793516058474779,
      0.07995671033859253,
      -0.09103693813085556,
      0.029647942632436752,
      -0.009118991903960705,
      0.04896725341677666,
      0.09309591352939606,
      0.006404496263712645,
      -0.03482597693800926,
      0.07590368390083313,
      0.03980815038084984,
      0.00905595999211073,
      0.018541809171438217,
      0.02098030038177967,
      0.07808857411146164,
      0.01600158028304577,
      0.02686668187379837,
      0.04953330010175705,
      -0.024562882259488106,
      0.09273562580347061,
      0.011997189372777939,
      0.04986824840307236,
      -0.043001651763916016,
      0.03296460583806038,
      -0.019392164424061775,
      0.0801374763250351,
      -0.0802144855260849,
      0.013965162448585033,
      0.007995296269655228,
      -0.06308843940496445,
      0.03890615329146385,
      0.045335642993450165,
      -0.006879941560328007,
      -0.04840627685189247,
      0.0022189966402947903,
      -0.0440378300845623,
      -0.04308705776929855,
      -0.03560427576303482,
      0.021673785522580147,
      0.01692040078341961,
      0.025883695110678673,
      0.02275707572698593,
      -0.04707808420062065,
      0.02972414903342724,
      -0.014507278800010681,
      -0.07302979379892349,
      -0.011645354330539703,
      -0.08591592311859131,
      -0.06377250701189041,
      -0.0828997790813446,
      -0.09348896890878677,
      -0.07950145751237869,
      -0.00444426666945219,
      -0.04088955372571945,
      0.014546492137014866,
      -0.04541521519422531,
      -0.024411356076598167,
      0.16222502291202545,
      -0.024977857246994972,
      -0.038772255182266235,
      0.021781103685498238,
      0.06484737992286682,
      -0.03337812051177025,
      0.058238305151462555,
      -0.028285373002290726,
      0.021608931943774223,
      -0.05231265723705292,
      0.03011196292936802,
      0.001346162403933704,
      0.022484615445137024,
      -0.01828184723854065,
      0.010690174996852875,
      0.026876626536250114,
      0.02856311947107315,
      0.0004095103358849883,
      0.004509341903030872,
      -0.020642202347517014,
      -0.004910808987915516,
      0.015396705828607082,
      -0.002075393684208393,
      -0.03377045318484306,
      -0.013630425557494164,
      0.00011357757466612384,
      0.07104629278182983,
      -0.004145459737628698,
      -0.0494019016623497,
      0.04068044200539589,
      0.027697453275322914,
      0.013231080956757069,
      0.05286012962460518,
      0.012849933467805386,
      -0.0031861562747508287,
      0.02319597825407982,
      0.02609768882393837,
      -0.04298941045999527,
      -0.0016367356292903423,
      0.030127596110105515,
      0.026634732261300087,
      -0.036064859479665756,
      -0.002651214599609375,
      -0.028689540922641754,
      0.05224015563726425,
      0.0066889128647744656,
      0.0920044407248497,
      -0.07496391236782074,
      0.023642539978027344,
      0.013967448845505714,
      -0.06259676069021225,
      -0.028843749314546585,
      0.020374467596411705,
      0.004874469246715307,
      0.0034467563964426517,
      0.04329616203904152,
      0.126212015748024,
      0.03768708184361458,
      0.019545357674360275,
      0.004209132865071297,
      0.06233803182840347
    ],
    [
      -0.001697824103757739,
      0.002255120547488332,
      0.03821421414613724,
      -0.022004706785082817,
      0.02373572811484337,
      0.013769340701401234,
      -0.10741013288497925,
      -0.018026772886514664,
      0.032769571989774704,
      0.019116446375846863,
      -0.011638221330940723,
      -0.009321782737970352,
      0.028986062854528427,
      -0.05719981715083122,
      0.06360416114330292,
      -0.059303198009729385,
      -0.02668623812496662,
      0.05720317363739014,
      0.10271617025136948,
      0.03131112456321716,
      -0.02911166474223137,
      0.03773124888539314,
      0.014144649729132652,
      -0.08896343410015106,
      -0.02848230116069317,
      0.017241494730114937,
      0.09669538587331772,
      0.01063723023980856,
      0.012915420345962048,
      0.045097313821315765,
      0.024402199313044548,
      -0.03233248367905617,
      -0.02158307656645775,
      0.047301873564720154,
      0.010753997601568699,
      -0.0013212033081799746,
      -0.02097880095243454,
      0.01685480773448944,
      0.07493660598993301,
      -0.0055813053622841835,
      0.05181809142231941,
      -0.02769082970917225,
      -0.015790479257702827,
      0.016542071476578712,
      0.09093213081359863,
      0.065767303109169,
      -0.0381452701985836,
      -0.036328766494989395,
      -0.05029184743762016,
      0.014246407896280289,
      0.09401397407054901,
      0.06313814222812653,
      -0.02023726888000965,
      0.052969593554735184,
      0.02431218884885311,
      -0.03682740405201912,
      -0.008467106148600578,
      0.007733356207609177,
      -0.007686734665185213,
      0.044947534799575806,
      0.003928042016923428,
      0.0012899519642814994,
      0.008808542042970657,
      0.05521022155880928,
      0.009990984573960304,
      -0.025554966181516647,
      -0.032253604382276535,
      0.03925711661577225,
      0.004577981308102608,
      -0.018215853720903397,
      0.07302581518888474,
      -0.029669208452105522,
      -0.024867646396160126,
      0.014951540157198906,
      0.013617368414998055,
      -0.005920153111219406,
      -0.007889287546277046,
      -0.014942524954676628,
      -0.033098701387643814,
      -0.10445316135883331,
      -0.026739971712231636,
      -0.050613027065992355,
      -0.01950765587389469,
      0.025730518624186516,
      0.05793018639087677,
      0.04845571517944336,
      -0.059130698442459106,
      0.07854606956243515,
      0.05930216982960701,
      0.030726848170161247,
      -0.05361473932862282,
      0.019800402224063873,
      -0.0043272296898067,
      0.09457042068243027,
      -0.03886149823665619,
      0.023038925603032112,
      -0.037155114114284515,
      -0.03329876437783241,
      -0.06428719311952591,
      -0.047641634941101074,
      -0.019634494557976723,
      0.016891133040189743,
      -0.06468693912029266,
      -0.011614806950092316,
      0.05170361325144768,
      0.07008785009384155,
      0.012081720866262913,
      -0.006593693047761917,
      -0.0031136958859860897,
      0.069216787815094,
      -0.02893194928765297,
      -0.07381509989500046,
      0.12315431237220764,
      0.013581427745521069,
      0.020683353766798973,
      -0.05819255858659744,
      0.01943141594529152,
      0.053118351846933365,
      -0.07315149903297424,
      0.0630262941122055,
      -0.016668858006596565,
      0.05513198673725128,
      0.03441556170582771,
      -0.018913893029093742,
      -0.03683106601238251,
      0.003570550587028265,
      0.028454920276999474,
      -0.016918551176786423,
      0.03682055324316025,
      -0.036411941051483154,
      -0.07537639141082764,
      -0.0334693007171154,
      -0.0016617366345599294,
      0.0020503108389675617,
      -0.013410407118499279,
      0.036745037883520126,
      0.019530022516846657,
      -0.0691012591123581,
      -0.051091816276311874,
      0.07249317318201065,
      -0.05566391721367836,
      -0.08072378486394882,
      0.07530194520950317,
      -0.046854835003614426,
      -0.06351736932992935,
      -0.04589103162288666,
      0.014062304981052876,
      0.06471588462591171,
      0.0013724426971748471,
      0.060014743357896805,
      0.003597305156290531,
      -0.004955027252435684,
      -0.008789344690740108,
      0.006000508554279804,
      0.0031561285723000765,
      0.04420900717377663,
      0.07317271828651428,
      -0.06041135638952255,
      0.005492572207003832,
      0.007106870412826538,
      0.0068064285442233086,
      0.025635728612542152,
      0.04071984067559242,
      -0.006502619944512844,
      -0.06247888505458832,
      -0.04066856577992439,
      -0.019274724647402763,
      0.05356449633836746,
      -0.043027427047491074,
      0.041967567056417465,
      0.01090717501938343,
      -0.03214915469288826,
      -0.024671951308846474,
      0.046498823910951614,
      0.043073620647192,
      -0.03717324510216713,
      -0.04729710519313812,
      -0.008936825208365917,
      0.0008945600711740553,
      0.06330661475658417,
      -0.023057417944073677,
      -0.029420606791973114,
      -0.02733806148171425,
      0.041700564324855804,
      0.06234506517648697,
      -0.013734248466789722,
      -0.03162144124507904,
      0.0888490304350853,
      -0.06839537620544434,
      0.016047274693846703,
      -0.051134441047906876,
      0.05183655396103859,
      0.030595628544688225,
      0.054956819862127304,
      0.014695391058921814,
      0.028333613649010658,
      -0.0600934736430645,
      0.030546104535460472,
      -0.005845756735652685,
      0.01575377956032753,
      0.025533465668559074,
      -0.027174267917871475,
      0.02964833192527294,
      0.012696817517280579,
      0.022779954597353935,
      -0.06478272378444672,
      0.04540761560201645,
      0.00313354074023664,
      0.026701901108026505,
      0.01146356575191021,
      -0.007581822108477354,
      0.024119317531585693,
      0.027783513069152832,
      -0.0015994732966646552,
      -0.0017411992885172367,
      -0.01568331941962242,
      -0.04716073349118233,
      0.06171784549951553,
      -0.022867362946271896,
      -0.03662307932972908,
      0.008338321931660175,
      0.09649227559566498,
      -0.026080524548888206,
      0.052112702280282974,
      0.02621542476117611,
      -0.004888727329671383,
      -0.00027903864975087345,
      -0.03280270844697952,
      0.08315922319889069,
      -0.028279636055231094,
      0.05660739913582802,
      0.01831725612282753,
      -0.00879012793302536,
      -0.029131226241588593,
      -0.011390254832804203,
      -0.04213609918951988,
      -0.024978693574666977,
      -0.054316066205501556,
      -0.024118885397911072,
      -0.04807228967547417,
      -0.11125251650810242,
      -0.07930777966976166,
      0.021957842633128166,
      -0.06262097507715225,
      -0.03450784087181091,
      -0.07021518796682358,
      0.04521182179450989,
      0.016574574634432793,
      0.04546815901994705,
      -0.08048304915428162,
      -0.0367950014770031,
      0.0008990823989734054,
      -0.07366818189620972,
      -0.03530660644173622,
      0.005976390093564987,
      0.005184438079595566,
      -0.039468955248594284,
      -0.025084082037210464,
      0.018397053703665733,
      -0.02003655955195427,
      0.005747634917497635,
      -0.08142738044261932,
      0.06850124150514603,
      0.03212598338723183,
      0.0006081472965888679,
      0.053079213947057724,
      0.03608705848455429,
      -0.0016721697757020593,
      0.006208091974258423,
      -0.06556429713964462,
      -0.06409923732280731,
      -0.06407734006643295,
      -0.034744102507829666,
      0.0713648870587349,
      -0.08994162827730179,
      0.01417321152985096,
      -0.025636767968535423,
      0.0035533460322767496,
      -0.019081586971879005,
      -0.04160378873348236,
      0.03305359184741974,
      0.027455518022179604,
      0.06809157133102417,
      -0.08051466941833496,
      0.00752029474824667,
      -0.010130874812602997,
      0.009182528592646122,
      0.05006631091237068,
      0.04597805440425873,
      -0.005778678227216005,
      -0.05492635443806648,
      0.01407083123922348,
      0.005356235895305872,
      0.012695852667093277,
      -0.01048695482313633,
      -0.00499785877764225,
      0.003359002061188221,
      -0.04349978640675545,
      -0.05782177299261093,
      0.051418378949165344,
      -0.04325307533144951,
      -0.012988903559744358,
      -0.0010048282565549016,
      0.007314527407288551,
      0.00871607568114996,
      0.012223299592733383,
      0.04103466868400574,
      -0.09247184544801712,
      -0.027508443221449852,
      -0.0817125216126442,
      0.06280925124883652,
      -0.03745625168085098,
      -0.03176822513341904,
      -0.05046486109495163,
      -0.07570575922727585,
      0.019220812246203423,
      -0.029927685856819153,
      0.01700349897146225,
      0.012615448795258999,
      -0.0508403517305851,
      -0.05574314668774605,
      -0.03606532886624336,
      0.05150928720831871,
      -0.028174201026558876,
      0.05775665491819382,
      0.06581950932741165,
      -0.03440038114786148,
      0.016250649467110634,
      -0.07978992164134979,
      -0.041639506816864014,
      0.0021551663521677256,
      0.0015001411084085703,
      0.05032016336917877,
      -0.031857699155807495,
      0.024884244427084923,
      0.0006891146185807884,
      0.011022491380572319,
      -0.09600283205509186,
      0.022037548944354057,
      0.08330877870321274,
      0.008670799434185028,
      -0.047978851944208145,
      0.0318353995680809,
      0.018123775720596313,
      -0.025817956775426865,
      0.022103501483798027,
      0.003914477303624153,
      0.02188214473426342,
      0.08152089267969131,
      -0.04496235400438309,
      0.04298378527164459,
      0.058637410402297974,
      -0.02294522151350975,
      -0.030978532508015633,
      -0.011453898623585701,
      -0.08875586837530136,
      0.04937714338302612,
      0.010233786888420582,
      -0.02441960759460926,
      -0.019497230648994446,
      0.12496200203895569,
      0.024033794179558754,
      0.01717224530875683,
      0.07411487400531769,
      -0.06445175409317017,
      0.07933294773101807,
      -0.017426537349820137,
      -0.0035027251578867435,
      -0.034181419759988785,
      0.005740273743867874,
      -0.10087009519338608,
      0.07284342497587204,
      0.038423728197813034,
      -0.04415798559784889,
      0.0064491699449718,
      -0.10402610898017883,
      0.021828942000865936,
      -0.04560759291052818,
      0.05587475374341011,
      -0.0415957048535347,
      0.02076646126806736,
      0.0021713837049901485,
      -0.017107466235756874,
      -0.04483979940414429,
      0.019309023395180702,
      -0.014053719118237495,
      0.013886208645999432,
      0.04582591354846954,
      -0.04664488509297371,
      -0.08131512254476547,
      -0.041084401309490204,
      -0.004162016790360212,
      -0.04326922073960304,
      0.012852397747337818,
      0.02203960344195366,
      0.08011113852262497,
      0.0030721656512469053,
      0.024522466585040092,
      -0.04274343326687813,
      0.03138664364814758,
      -0.05359044298529625,
      0.034435562789440155,
      0.0023989661131054163,
      -0.07986446470022202,
      -0.0801178365945816,
      -0.007274795789271593,
      -0.01419608574360609,
      0.003728242591023445,
      0.004180490970611572,
      0.03934964910149574,
      -0.011591040529310703,
      0.024714026600122452,
      -0.02047523483633995,
      0.1122475415468216,
      -0.031320977956056595,
      0.054149918258190155,
      -0.02545035257935524,
      0.021240731701254845,
      -0.040211841464042664,
      -0.02606166899204254,
      -0.03406380116939545,
      -0.06952778249979019,
      -0.07168562710285187,
      -0.01046607457101345,
      -0.01860080473124981,
      -0.06343982368707657,
      -0.027609318494796753,
      -0.03472521901130676,
      -0.04850459471344948,
      0.032287418842315674,
      -0.09003027528524399,
      -0.08960928022861481,
      0.040627144277095795,
      -0.0035113708581775427,
      -0.03688295930624008,
      0.027349507436156273,
      -0.09389402717351913,
      7.189485586422961e-06,
      -0.035558201372623444,
      0.07205580174922943,
      0.08024437725543976,
      0.0745684951543808,
      -0.034755535423755646,
      -0.08352447301149368,
      -0.05532069131731987,
      -0.05608046427369118,
      0.0152136767283082,
      0.10434223711490631,
      0.023003166541457176,
      0.005342212039977312,
      0.06344395875930786,
      0.019878851249814034,
      -0.11180223524570465,
      0.007724336814135313,
      0.059777431190013885,
      0.023573122918605804,
      0.08311469107866287,
      -0.021733298897743225,
      -0.06209736689925194,
      0.038840584456920624,
      -0.005788279697299004,
      -0.026957884430885315,
      0.010516675189137459,
      0.033225346356630325,
      -0.00908594112843275,
      -0.03597613796591759,
      0.011740133166313171,
      -0.023784248158335686,
      0.07244443893432617,
      0.007782960310578346,
      -0.02414659596979618,
      -0.0009443317539989948,
      0.07920906692743301,
      -0.06995446234941483,
      0.03614071384072304,
      0.002746045356616378,
      -0.0006155797746032476,
      0.01448933593928814,
      0.00031997880432754755,
      0.03904378041625023,
      0.04616694152355194,
      0.028531024232506752,
      0.017895076423883438,
      -0.011597954668104649,
      0.01703830249607563,
      -0.04251638799905777,
      -0.0024728048592805862,
      -0.011809480376541615,
      -0.04276977851986885,
      0.02250680699944496,
      0.029109418392181396,
      0.021425001323223114,
      0.04216359928250313,
      0.04401199519634247,
      -0.007166855502873659,
      0.05483122542500496,
      -0.04906091094017029,
      -0.044325850903987885,
      0.028051288798451424,
      0.007970588281750679,
      -0.03604762256145477,
      0.03740650415420532,
      -0.06514307111501694
    ],
    [
      0.027914023026823997,
      0.007317511830478907,
      0.03515348955988884,
      0.02035590447485447,
      0.005006282590329647,
      -0.06807783991098404,
      0.011951064690947533,
      -0.00919389259070158,
      0.02946910820901394,
      0.03303765505552292,
      -0.020727764815092087,
      0.011366127990186214,
      -0.015037826262414455,
      0.012043342925608158,
      0.056595344096422195,
      0.06461743265390396,
      0.11714214086532593,
      -0.020544737577438354,
      0.004259621724486351,
      -0.015087530948221684,
      -0.016344843432307243,
      0.06612229347229004,
      0.031402066349983215,
      0.022624829784035683,
      -0.05877729877829552,
      0.019001726061105728,
      -0.0338631309568882,
      -0.006008901167660952,
      -0.026120975613594055,
      -0.08056079596281052,
      0.04375696927309036,
      -0.022403715178370476,
      -0.00570980878546834,
      -0.023984665051102638,
      0.06766305863857269,
      0.08417044579982758,
      0.006911579053848982,
      -0.05874884873628616,
      -0.016874641180038452,
      -0.0484791062772274,
      -0.02453821711242199,
      -0.028999241068959236,
      0.05835089087486267,
      -0.02546311728656292,
      0.006717432755976915,
      0.048494238406419754,
      -0.07309305667877197,
      0.017472028732299805,
      -0.012637590989470482,
      -0.022210143506526947,
      -0.11105719953775406,
      -0.02864837646484375,
      0.08140109479427338,
      -0.03655588999390602,
      0.040861453860998154,
      0.011819792911410332,
      0.03294335678219795,
      -0.03648798167705536,
      -0.09208976477384567,
      -0.13471055030822754,
      -0.03089093044400215,
      0.049627356231212616,
      0.08552533388137817,
      -0.012172195129096508,
      -0.02059653215110302,
      0.00497503811493516,
      -0.05275099724531174,
      -0.0053316568955779076,
      -0.017576556652784348,
      -0.0001356380234938115,
      0.0418415330350399,
      0.0524662546813488,
      -0.0038157107774168253,
      -0.00443078251555562,
      0.025861134752631187,
      0.03105541504919529,
      -0.032710205763578415,
      -0.05394304171204567,
      0.003959592431783676,
      -0.03861140087246895,
      -0.03853356093168259,
      0.115863136947155,
      0.026875624433159828,
      0.028742745518684387,
      0.03260020539164543,
      -0.028450900688767433,
      -0.08509592711925507,
      0.01688319258391857,
      -0.07468562573194504,
      -0.035412367433309555,
      -0.0731160044670105,
      -0.042793478816747665,
      -0.0024792731273919344,
      -0.0018702531233429909,
      -0.009830096736550331,
      0.02151610516011715,
      0.022680453956127167,
      0.023063333705067635,
      0.008297889493405819,
      -0.019867612048983574,
      0.057517267763614655,
      -0.02999688871204853,
      0.02724253386259079,
      -0.022578062489628792,
      0.02126290835440159,
      -0.033224329352378845,
      -0.03914232924580574,
      -0.03323297202587128,
      0.020746616646647453,
      0.04140366613864899,
      0.0457259938120842,
      0.026390481740236282,
      -0.019528428092598915,
      0.10386884212493896,
      -0.03928317502140999,
      0.029633749276399612,
      0.05979103222489357,
      0.04273667931556702,
      0.06171400472521782,
      0.012114868499338627,
      0.07123874127864838,
      -0.05356035754084587,
      -0.03509575501084328,
      -0.04710182920098305,
      0.07648978382349014,
      0.033138908445835114,
      0.03684656694531441,
      0.012455068528652191,
      0.07371518760919571,
      0.017743105068802834,
      0.010711826384067535,
      0.061925455927848816,
      -0.036315325647592545,
      0.031152740120887756,
      -0.032924722880125046,
      0.046250224113464355,
      -0.02961488626897335,
      -0.02399921417236328,
      -0.07228532433509827,
      -0.06633025407791138,
      -0.08571889996528625,
      0.004763507284224033,
      -0.06424836069345474,
      -0.022935764864087105,
      -0.0884077399969101,
      0.10036645829677582,
      -0.03544699400663376,
      0.022407744079828262,
      -0.07624772936105728,
      -0.07196599990129471,
      -0.04467247799038887,
      0.03935841843485832,
      -0.03750372305512428,
      0.07644660025835037,
      0.022844696417450905,
      0.04735315963625908,
      -0.03804774209856987,
      -0.02623586729168892,
      -0.021517131477594376,
      0.02129199542105198,
      -0.02400459535419941,
      0.02359011396765709,
      -0.008266071788966656,
      -0.0551660917699337,
      0.018820280209183693,
      -0.0020302417688071728,
      0.034093666821718216,
      -0.01877215877175331,
      -0.023092497140169144,
      0.044084127992391586,
      -0.005457840394228697,
      -0.03626883029937744,
      0.022096317261457443,
      0.011884007602930069,
      0.09383966028690338,
      -0.03858132287859917,
      -0.011614898219704628,
      0.018785059452056885,
      -0.008409402333199978,
      0.08897387981414795,
      -0.035793185234069824,
      0.05663095414638519,
      0.08521715551614761,
      -0.04294769465923309,
      -0.004524643998593092,
      -0.04762936756014824,
      0.00516560347750783,
      -0.060986123979091644,
      -0.055837132036685944,
      -0.05786782130599022,
      -0.02423708513379097,
      -0.025643285363912582,
      -0.002405846491456032,
      -0.01216316781938076,
      -0.025913747027516365,
      0.003509250469505787,
      -0.05414217710494995,
      -0.052722178399562836,
      0.012747705914080143,
      -0.08342387527227402,
      -0.017840521410107613,
      0.011016816832125187,
      0.042730312794446945,
      -0.03119579516351223,
      0.03571511432528496,
      0.012583241797983646,
      0.05714581161737442,
      -0.001124009140767157,
      0.03798909857869148,
      -0.003576393472030759,
      -0.08160857111215591,
      -0.011005417443811893,
      0.03144346922636032,
      0.02321600168943405,
      -0.021390583366155624,
      -0.049516670405864716,
      -0.0473523885011673,
      0.05424417182803154,
      0.05533831939101219,
      -0.028173845261335373,
      0.0032596036326140165,
      0.05917276814579964,
      -0.03214939311146736,
      -0.04846454784274101,
      -0.05953514575958252,
      -0.027564750984311104,
      0.06905706971883774,
      0.06276722252368927,
      0.02220275066792965,
      -0.0605764202773571,
      0.03484714403748512,
      0.10908263921737671,
      -0.03953075408935547,
      0.03706393018364906,
      0.043624602258205414,
      -0.050447896122932434,
      0.018780812621116638,
      0.06931307166814804,
      -0.011689229868352413,
      -0.05507664382457733,
      0.016554078087210655,
      -0.01872776821255684,
      -0.015234173275530338,
      0.07532833516597748,
      -0.011738481931388378,
      0.04331138730049133,
      -0.054837338626384735,
      0.035679515451192856,
      0.005223587621003389,
      -0.03678283467888832,
      -0.004293994978070259,
      -0.00039066129829734564,
      -0.021607989445328712,
      -0.028429901227355003,
      -0.026325426995754242,
      -0.008654865436255932,
      0.04431355744600296,
      0.02928391471505165,
      -0.05225207656621933,
      -0.00878863874822855,
      -0.028403397649526596,
      0.04492798447608948,
      -0.009847632609307766,
      -0.04120713844895363,
      0.07389432191848755,
      -0.020339353010058403,
      -0.03554356098175049,
      0.010205388069152832,
      -0.01428743451833725,
      0.024715228006243706,
      -0.0348811037838459,
      0.0498015359044075,
      4.094047108083032e-05,
      0.011124217882752419,
      0.016504507511854172,
      0.07451175153255463,
      0.029316779226064682,
      0.011370943859219551,
      -0.007381516043096781,
      0.05409608036279678,
      -0.02453027293086052,
      0.039022449404001236,
      0.0011763419024646282,
      0.03269314393401146,
      0.006622535642236471,
      -0.015026356093585491,
      0.07566314190626144,
      -0.0174395814538002,
      -0.06617705523967743,
      0.015501551330089569,
      0.062005966901779175,
      -0.02765549346804619,
      -0.04836783558130264,
      0.047420453280210495,
      0.015051092952489853,
      0.030552031472325325,
      -0.03607705608010292,
      -0.02965529076755047,
      0.0287322998046875,
      0.0786319226026535,
      0.047441136091947556,
      -0.00963699072599411,
      -0.08025757223367691,
      -0.011355050839483738,
      0.01153983362019062,
      0.06490209698677063,
      -0.0835779532790184,
      -0.02071193978190422,
      -0.03297901526093483,
      0.0074378447607159615,
      -0.009895887225866318,
      -0.039027780294418335,
      -0.06865151226520538,
      -0.03910446912050247,
      -0.06645143032073975,
      0.05573910474777222,
      -0.04098498821258545,
      -0.02753487415611744,
      0.004777869209647179,
      0.04235747829079628,
      -0.03807355836033821,
      0.033497147262096405,
      0.04062601178884506,
      0.024356644600629807,
      -0.010906573385000229,
      -0.004353164229542017,
      -0.020147880539298058,
      0.017445338889956474,
      -0.036668453365564346,
      -0.013512262143194675,
      0.044526562094688416,
      0.0006419201381504536,
      -0.049782007932662964,
      -0.049687132239341736,
      -0.029907947406172752,
      0.0013954653404653072,
      -0.026109689846634865,
      -0.01715182699263096,
      -0.03048652596771717,
      -0.054893121123313904,
      0.055546291172504425,
      0.009903422556817532,
      -0.04192741587758064,
      0.018617849797010422,
      0.05885711684823036,
      0.02167605049908161,
      0.007095735985785723,
      -0.025867193937301636,
      0.011485110968351364,
      0.02969464287161827,
      0.012125201523303986,
      0.04445800185203552,
      -0.05263358727097511,
      0.05493677034974098,
      -0.011205427348613739,
      0.06174267828464508,
      0.022443337365984917,
      0.032419316470623016,
      -0.1036127358675003,
      -0.04770641773939133,
      -0.014707857742905617,
      -0.008026672527194023,
      -0.036061037331819534,
      0.06793589144945145,
      0.051161400973796844,
      0.01955324038863182,
      0.013149208389222622,
      -0.004361945670098066,
      0.06515292078256607,
      0.08696340769529343,
      -0.11144085228443146,
      0.023249931633472443,
      -0.006212788634002209,
      0.07235737144947052,
      0.026435893028974533,
      0.03148864582180977,
      0.071434386074543,
      -0.02969449758529663,
      0.02377561293542385,
      0.03798764571547508,
      -0.07915990799665451,
      -0.034424059092998505,
      -0.018406249582767487,
      0.0057146199978888035,
      -0.0412529781460762,
      0.05564809963107109,
      0.03761430084705353,
      -0.000525308889336884,
      0.024367384612560272,
      -0.009858500212430954,
      -0.013176267966628075,
      0.017784249037504196,
      -0.04961569607257843,
      -0.022025616839528084,
      -0.0385136678814888,
      -0.07176520675420761,
      -0.04133692383766174,
      -0.013971540145576,
      -0.01064741425216198,
      0.008220938965678215,
      -0.033894967287778854,
      0.027557283639907837,
      -0.0582086443901062,
      -0.045826155692338943,
      0.050301820039749146,
      0.003908517770469189,
      -0.04571458697319031,
      -0.07598695158958435,
      0.0342678464949131,
      0.06752574443817139,
      -0.05882616713643074,
      0.08995987474918365,
      0.00975621398538351,
      -0.0783158466219902,
      -0.0008253856794908643,
      0.01005865540355444,
      -0.06727738678455353,
      0.009714432992041111,
      -0.05830560252070427,
      0.028529034927487373,
      0.0033772713504731655,
      -0.015200253576040268,
      -0.0030242057982832193,
      -0.003394465195015073,
      0.04393092542886734,
      -0.002606939058750868,
      -0.01914219744503498,
      0.04368077591061592,
      -0.004855062812566757,
      0.04467083141207695,
      -0.004719981923699379,
      0.02000410482287407,
      -0.045908745378255844,
      -0.09339777380228043,
      0.0011055017821490765,
      0.03274652361869812,
      -0.005461325403302908,
      0.0006791525520384312,
      0.04711548238992691,
      0.00041105414857156575,
      -0.06161146238446236,
      -0.03283308818936348,
      -0.031389713287353516,
      -0.0318789966404438,
      0.032113682478666306,
      -0.030784158036112785,
      0.06120801344513893,
      0.054023537784814835,
      -0.0260233785957098,
      0.0009286554413847625,
      0.03123888000845909,
      -0.08493533730506897,
      -0.061844468116760254,
      -0.014695889316499233,
      0.04959641769528389,
      0.006792180705815554,
      -0.038399841636419296,
      0.031147550791502,
      0.03868940845131874,
      0.03174653276801109,
      -0.12708653509616852,
      0.06904537975788116,
      0.04678819701075554,
      -0.07463215291500092,
      -0.00891315471380949,
      -0.09532496333122253,
      0.07943864911794662,
      0.006029682699590921,
      0.005982471164315939,
      -0.035146571695804596,
      -0.026363430544734,
      -0.03084617108106613,
      0.07456378638744354,
      0.04018496721982956,
      -0.08076161891222,
      0.011081739328801632,
      -0.024302400648593903,
      -0.032912325114011765,
      -0.02385587990283966,
      -0.06121791899204254,
      0.05789574608206749,
      -0.011446868069469929,
      0.06636466085910797,
      -0.04036891460418701,
      0.010209266096353531,
      -0.020234981551766396,
      -0.017469841986894608,
      -0.011027077212929726,
      0.11395576596260071,
      0.06355217099189758,
      0.03209226205945015,
      0.03217189386487007,
      -0.016261179000139236,
      -0.008393154479563236,
      -0.007986440323293209,
      -0.004624202847480774,
      0.0008584485040046275,
      -0.11666487902402878,
      -0.03634732961654663,
      -0.06052590534090996,
      0.08538085967302322,
      0.10980892926454544,
      0.01630902849137783
    ],
    [
      -0.042589567601680756,
      -0.027592666447162628,
      -0.04243180528283119,
      -0.049322403967380524,
      0.07770809531211853,
      0.058457668870687485,
      -0.021640893071889877,
      0.03844060003757477,
      0.05752310901880264,
      -0.06384149193763733,
      -0.008707842789590359,
      -0.087196946144104,
      0.031689394265413284,
      0.022280411794781685,
      -0.019750289618968964,
      -0.07521617412567139,
      -0.03154345601797104,
      0.0053080180659890175,
      0.07411137223243713,
      -0.05434248223900795,
      -0.05280549079179764,
      -0.06962943822145462,
      0.051272161304950714,
      -0.052982646971940994,
      -0.016911247745156288,
      -0.07314153015613556,
      0.011764559894800186,
      0.04657575488090515,
      0.00040562183130532503,
      -0.034738536924123764,
      0.006101963575929403,
      -0.00020478102669585496,
      -0.024543674662709236,
      -0.06480111181735992,
      0.03530091792345047,
      -0.0022985495161265135,
      0.06912783533334732,
      0.05330400541424751,
      0.019953934475779533,
      0.008492275141179562,
      0.11061817407608032,
      -0.012244557030498981,
      -0.02996978722512722,
      0.04417959973216057,
      0.056736867874860764,
      0.009632578119635582,
      -0.1744612157344818,
      0.017804114148020744,
      0.017210865393280983,
      -0.010156815871596336,
      -0.006927113514393568,
      -0.10213541984558105,
      0.06196647509932518,
      -0.006095254793763161,
      0.026213057339191437,
      0.08625596016645432,
      0.03955681994557381,
      0.023086851462721825,
      -0.11506935954093933,
      0.01909818686544895,
      -0.003054290311411023,
      0.006253310479223728,
      -0.00111758045386523,
      -0.01691337116062641,
      -0.02903282269835472,
      0.0019516063621267676,
      -0.02365134283900261,
      -0.042240485548973083,
      -0.0340329147875309,
      -0.028072064742445946,
      -0.05386349558830261,
      -0.009027538821101189,
      0.02893756702542305,
      -0.0322805754840374,
      -0.012916726991534233,
      0.010110804811120033,
      -0.08331961929798126,
      -0.04658198729157448,
      0.028682943433523178,
      -0.03490401431918144,
      -0.08638104796409607,
      -0.008604011498391628,
      -0.027913054451346397,
      -0.016336988657712936,
      0.042398665100336075,
      -0.04140026867389679,
      0.0011829024879261851,
      -0.030436856672167778,
      0.011541450396180153,
      0.0029945129062980413,
      -0.04869959130883217,
      -0.0013479623012244701,
      -0.12624306976795197,
      -0.060647688806056976,
      -0.047614529728889465,
      0.011015503667294979,
      -0.013181820511817932,
      0.05418417230248451,
      -0.004295623395591974,
      0.012493942864239216,
      0.025250807404518127,
      -0.00959786493331194,
      -0.042786698788404465,
      0.015605763532221317,
      -0.018951134756207466,
      0.07981601357460022,
      0.008406915701925755,
      0.03597428277134895,
      -0.029201775789260864,
      0.03562973067164421,
      0.0623919814825058,
      -0.029308337718248367,
      0.026110857725143433,
      0.04466941952705383,
      -0.03550132364034653,
      0.021669670939445496,
      -0.09099387377500534,
      -0.009617297910153866,
      -0.05264659970998764,
      0.030513577163219452,
      0.077796071767807,
      -0.012886431999504566,
      -0.05747407674789429,
      0.09367462247610092,
      -0.04616503044962883,
      -0.0009785848669707775,
      0.03660273924469948,
      0.024136897176504135,
      0.030730804428458214,
      -0.020344121381640434,
      0.024318477138876915,
      0.005231357179582119,
      0.019320696592330933,
      0.04614747688174248,
      -0.05081450566649437,
      0.005715906620025635,
      0.08458729833364487,
      -0.002465439960360527,
      -0.007621236145496368,
      -0.09155551344156265,
      0.03320210799574852,
      -0.03934057056903839,
      -0.07021353393793106,
      0.027337949723005295,
      -0.004728549160063267,
      0.054724469780921936,
      -0.05724404379725456,
      0.030738145112991333,
      -0.06618727743625641,
      0.03510948270559311,
      -0.024702148512005806,
      0.003962407354265451,
      -0.03892252966761589,
      -0.06308013945817947,
      -0.01799667626619339,
      0.0199446864426136,
      -0.09583281725645065,
      0.010834038257598877,
      0.09827473759651184,
      -0.015401366166770458,
      0.029017968103289604,
      -0.04522813856601715,
      0.053558915853500366,
      -0.01612892933189869,
      0.08388232439756393,
      -0.02581782080233097,
      0.048429396003484726,
      0.051445502787828445,
      -0.05769629031419754,
      -0.05184159800410271,
      -0.02746940404176712,
      -0.08941300958395004,
      0.025140810757875443,
      0.04609249159693718,
      0.02024688571691513,
      -0.06246699020266533,
      -0.017122061923146248,
      -0.03080056421458721,
      -0.018137266859412193,
      0.03601804003119469,
      0.022226959466934204,
      0.019218215718865395,
      -0.023007038980722427,
      -0.020910993218421936,
      -0.002342452760785818,
      -0.04698129743337631,
      0.06556572765111923,
      0.04940813407301903,
      -0.06529159098863602,
      -0.02063789777457714,
      -0.029548266902565956,
      0.001917600748129189,
      0.019853897392749786,
      0.035162873566150665,
      -0.009706058539450169,
      0.012434807606041431,
      0.0030424247961491346,
      0.07188892364501953,
      0.043619465082883835,
      -0.08167529106140137,
      0.012386584654450417,
      0.06813157349824905,
      0.03581590950489044,
      0.013373264111578465,
      0.06196599081158638,
      0.032501667737960815,
      -0.009424317628145218,
      -0.003992030862718821,
      0.0937696099281311,
      0.04939734935760498,
      -0.01658961921930313,
      0.0393289290368557,
      0.051344241946935654,
      -0.015310336835682392,
      -0.002447915030643344,
      0.045587990432977676,
      -0.08200983703136444,
      -0.032649312168359756,
      -0.11265765130519867,
      0.044919565320014954,
      0.047126881778240204,
      -0.036791156977415085,
      -0.035438861697912216,
      -0.008042198605835438,
      -0.017999202013015747,
      0.06204527989029884,
      0.08269166201353073,
      0.007564302068203688,
      0.00725547457113862,
      -0.05688520520925522,
      0.03369376063346863,
      0.06053062900900841,
      -0.004223518073558807,
      0.025296207517385483,
      -0.02275685966014862,
      -0.04367595538496971,
      0.011192637495696545,
      -0.06467839330434799,
      0.021359363570809364,
      0.028030985966324806,
      0.016323203220963478,
      0.030455851927399635,
      -0.026287740096449852,
      -0.05053605139255524,
      0.06327575445175171,
      0.06668539345264435,
      -0.0064545366913080215,
      0.08870165795087814,
      -0.0555766299366951,
      -0.02453223429620266,
      -0.01660507544875145,
      0.04542406275868416,
      0.018473709002137184,
      -0.09766381233930588,
      -0.1313798874616623,
      0.020339444279670715,
      0.07881288975477219,
      -0.015351928770542145,
      -0.03671130910515785,
      0.0116276228800416,
      -0.04572016000747681,
      0.014611841179430485,
      -0.04302545636892319,
      -0.0010461865458637476,
      0.02571607008576393,
      0.02834155596792698,
      0.015445086173713207,
      0.05000219866633415,
      0.025293070822954178,
      0.03887498006224632,
      0.01784473843872547,
      0.021943461149930954,
      -0.02352387085556984,
      0.03754043951630592,
      0.06410779058933258,
      -0.04706526920199394,
      0.005731822922825813,
      -0.06760676950216293,
      0.07820182293653488,
      -0.06384053081274033,
      -0.04884491115808487,
      0.009344046004116535,
      0.004071867559105158,
      -0.05168428644537926,
      0.0035416563041508198,
      0.004591522738337517,
      -0.06570356339216232,
      -0.03805961459875107,
      -0.07990112900733948,
      -0.04458816349506378,
      -0.0035249139182269573,
      0.014196367934346199,
      0.01030631922185421,
      0.092475026845932,
      0.04921456798911095,
      0.006812462583184242,
      0.0010412279516458511,
      -0.05080127343535423,
      -0.014234267175197601,
      0.0707489550113678,
      0.001222324906848371,
      0.029407117515802383,
      -0.05053388699889183,
      0.022450387477874756,
      -0.07150309532880783,
      -0.019771097227931023,
      0.005908220540732145,
      -0.005757981911301613,
      0.009816841222345829,
      -0.0003568781539797783,
      -0.02971910685300827,
      0.012251747772097588,
      0.04481182247400284,
      -0.01913468725979328,
      -0.02010929025709629,
      -0.00607770262286067,
      -0.035593222826719284,
      -0.02206898294389248,
      -0.05281712859869003,
      0.02486523427069187,
      -0.00956418365240097,
      -0.04715009406208992,
      0.01900937221944332,
      0.05971498414874077,
      -0.029524676501750946,
      0.010765768587589264,
      -0.05153799057006836,
      -0.022569019347429276,
      -0.0071515291929244995,
      -0.016256682574748993,
      0.03437904268503189,
      -0.02236955426633358,
      0.01581639237701893,
      0.04812373220920563,
      0.0492032989859581,
      -0.010518081486225128,
      0.058601900935173035,
      -0.02209041640162468,
      -8.733290451345965e-05,
      0.032861437648534775,
      -0.02374516800045967,
      -0.026923293247818947,
      -0.050672486424446106,
      0.07675199955701828,
      0.05656875669956207,
      -0.06395147740840912,
      0.033527787774801254,
      -0.006765264552086592,
      -0.0020401105284690857,
      -0.0019537252373993397,
      0.01525980606675148,
      0.09103841334581375,
      -0.07838141173124313,
      -0.007333472836762667,
      0.009349326603114605,
      0.0033883957657963037,
      -0.05736924335360527,
      0.05445631965994835,
      0.03604598715901375,
      -0.011149007827043533,
      0.040384046733379364,
      -0.06330839544534683,
      0.020520636811852455,
      -0.020034972578287125,
      0.048181965947151184,
      -0.08600062876939774,
      0.000631343515124172,
      -0.034247852861881256,
      0.00809607096016407,
      0.1188443973660469,
      -0.08705650269985199,
      0.05784947797656059,
      0.058095723390579224,
      -0.004344344604760408,
      -0.053208284080028534,
      -0.014649106189608574,
      -0.07969671487808228,
      0.035849858075380325,
      0.02004503272473812,
      0.01215496938675642,
      0.046014200896024704,
      -0.09082525968551636,
      0.04132814705371857,
      0.11926360428333282,
      0.03373533859848976,
      0.002674894407391548,
      0.05145473778247833,
      0.03256390616297722,
      -0.04719304293394089,
      0.021062863990664482,
      -0.058080043643713,
      0.048398811370134354,
      0.0075636194087564945,
      -0.02780167944729328,
      0.024422429502010345,
      0.014405958354473114,
      -0.012704248540103436,
      0.003924740944057703,
      -0.03856264054775238,
      0.08874065428972244,
      -0.12370545417070389,
      0.044264186173677444,
      0.0778592899441719,
      0.014174039475619793,
      -0.02084721438586712,
      0.024617690593004227,
      -0.01535843126475811,
      -0.057949185371398926,
      0.028536781668663025,
      0.057953692972660065,
      0.042596060782670975,
      -0.0007528348360210657,
      -0.048937421292066574,
      -0.07430482655763626,
      0.015059457160532475,
      0.05189194530248642,
      -0.06760488450527191,
      0.03211693465709686,
      0.010745611973106861,
      0.05565318092703819,
      -0.006108769681304693,
      -0.05131930857896805,
      0.055893197655677795,
      0.028719181194901466,
      -0.024014649912714958,
      -0.019516905769705772,
      -0.012489667162299156,
      -0.023343190550804138,
      -0.025713616982102394,
      0.07912226021289825,
      -0.007390656508505344,
      0.021005546674132347,
      -0.007024917285889387,
      0.013157974928617477,
      -0.020571796223521233,
      -0.018641741946339607,
      0.004490151070058346,
      0.053875770419836044,
      0.006392825860530138,
      0.0011972261127084494,
      -0.04987986758351326,
      0.030752025544643402,
      -0.025436028838157654,
      -0.037064455449581146,
      0.10280098766088486,
      -0.011277249082922935,
      -0.06378798186779022,
      0.08186864107847214,
      0.029676103964447975,
      0.03142828121781349,
      0.07014024257659912,
      -0.03924553468823433,
      -0.010045217350125313,
      -0.021472781896591187,
      0.01657571829855442,
      0.027628181502223015,
      0.03615661710500717,
      -0.01450038980692625,
      0.03823063522577286,
      -0.022909322753548622,
      -0.019845247268676758,
      -0.018513742834329605,
      -0.02074953354895115,
      0.03250468522310257,
      -0.0028426507487893105,
      0.06722714751958847,
      -0.033293716609478,
      0.005350630264729261,
      0.04919092357158661,
      -0.03232087567448616,
      0.02977520041167736,
      0.016288531944155693,
      0.02275785431265831,
      -0.03546301648020744,
      -0.016687767580151558,
      0.0189947672188282,
      -0.029516320675611496,
      -0.12093876302242279,
      -0.03681604936718941,
      0.031139463186264038,
      0.07571252435445786,
      -0.019913962110877037,
      0.048316389322280884,
      -0.005910403095185757,
      0.07224033772945404,
      0.07026699930429459,
      0.06396695226430893,
      0.007698304485529661,
      -0.026548227295279503,
      0.025536181405186653,
      0.012017575092613697,
      -0.08945046365261078,
      -0.014614259824156761,
      0.03838086500763893,
      0.025379423052072525,
      0.0010222613345831633,
      -0.023382144048810005,
      -0.01296467799693346,
      0.035933639854192734,
      0.030350307002663612,
      0.031236490234732628,
      0.0017629642970860004,
      -0.015439155511558056
    ],
    [
      -0.05857512727379799,
      0.06622322648763657,
      0.041371893137693405,
      -0.06486093252897263,
      0.035547614097595215,
      -0.00622621551156044,
      -0.040021803230047226,
      0.007397335022687912,
      0.036335065960884094,
      0.013823329471051693,
      -0.07736646384000778,
      0.002639208221808076,
      -0.02972417324781418,
      0.03782924264669418,
      0.04128586873412132,
      0.02304873801767826,
      -0.08663980662822723,
      0.0128837451338768,
      0.01197976153343916,
      0.05405839905142784,
      -0.011602835729718208,
      0.04323649778962135,
      -0.01248975470662117,
      0.04711121693253517,
      -0.04185602813959122,
      -0.028629576787352562,
      0.008942358195781708,
      0.012683689594268799,
      0.06734412163496017,
      -0.03008035384118557,
      -0.02090311236679554,
      0.06745033711194992,
      -0.04654280096292496,
      -0.02452845871448517,
      -0.051687393337488174,
      0.025179894641041756,
      0.025585303083062172,
      0.003061192575842142,
      0.016236132010817528,
      0.030840592458844185,
      -0.015486553311347961,
      0.040415991097688675,
      0.03151117265224457,
      0.1041562408208847,
      -0.04750184342265129,
      0.07749457657337189,
      -0.006049572490155697,
      -0.023288115859031677,
      0.019490187987685204,
      0.007201986853033304,
      -0.04320092499256134,
      -0.0482046976685524,
      0.04106619954109192,
      -0.017766742035746574,
      -0.08511435985565186,
      -0.037050943821668625,
      0.013507090508937836,
      -0.007390483748167753,
      -0.03259342908859253,
      0.0246944148093462,
      -0.043781135231256485,
      0.010675712488591671,
      -0.021183883771300316,
      -0.04442992061376572,
      -0.058226656168699265,
      0.0331677570939064,
      0.03191394358873367,
      0.05332864075899124,
      -0.08027813583612442,
      -0.009059138596057892,
      0.05568340793251991,
      -0.06321560591459274,
      0.008657673373818398,
      0.031020401045680046,
      -0.013705008663237095,
      0.012487636879086494,
      0.03704697638750076,
      0.020875511690974236,
      0.008503847755491734,
      -0.08207117021083832,
      -0.08469751477241516,
      -0.019538234919309616,
      0.029151510447263718,
      -0.014107503928244114,
      -0.049863044172525406,
      0.02818145602941513,
      -0.010265112854540348,
      0.010450558736920357,
      0.11992163211107254,
      0.05672379210591316,
      -0.00885678268969059,
      0.07969215512275696,
      0.03882549703121185,
      -0.0025468964595347643,
      -0.053591735661029816,
      -0.04331592097878456,
      -0.0006658330676145852,
      0.08483092486858368,
      0.10516716539859772,
      0.020199356600642204,
      0.03462633490562439,
      -0.056978244334459305,
      0.001295951777137816,
      -0.05327646806836128,
      0.05380600690841675,
      0.0705399364233017,
      0.016940541565418243,
      0.10602129250764847,
      -0.023556264117360115,
      0.102873295545578,
      -0.016431357711553574,
      0.021082179620862007,
      0.05615471303462982,
      0.07083949446678162,
      -0.06362361460924149,
      -0.04629497602581978,
      -0.04682713374495506,
      0.000818596628960222,
      -0.013587488792836666,
      -0.012283559888601303,
      -0.007964476011693478,
      -0.012366853654384613,
      0.05441666394472122,
      -0.0018013836815953255,
      -0.05310880020260811,
      0.07424047589302063,
      0.030977968126535416,
      -0.04511043801903725,
      -0.010012373328208923,
      -0.06519684940576553,
      -0.030749285593628883,
      0.053869012743234634,
      -0.003146126866340637,
      -0.007718460634350777,
      -0.025057222694158554,
      -0.07863639295101166,
      0.07465805858373642,
      -0.03016034886240959,
      0.046270858496427536,
      0.06720518320798874,
      0.026891343295574188,
      -0.02061459980905056,
      -0.0471959114074707,
      0.05885454639792442,
      0.06135542318224907,
      0.010340731590986252,
      0.03758341446518898,
      0.08611581474542618,
      0.032486602663993835,
      -0.031652361154556274,
      0.05908823758363724,
      -0.020326687023043633,
      0.06237858161330223,
      -0.09060175716876984,
      -0.02975155971944332,
      0.027475828304886818,
      -0.11178261786699295,
      -0.0024724402464926243,
      0.03378358483314514,
      -0.06619972735643387,
      0.0395832285284996,
      0.06405037641525269,
      -0.0670817568898201,
      0.0005914165521971881,
      0.006114539690315723,
      -0.03519561141729355,
      0.0016726800240576267,
      0.010443350300192833,
      -0.006364644039422274,
      -0.004006643779575825,
      0.03718600049614906,
      -0.025708790868520737,
      0.025383716449141502,
      -0.002872044686228037,
      -0.023207303136587143,
      -0.07317284494638443,
      -0.040692251175642014,
      -0.0012377871898934245,
      0.037263862788677216,
      0.028482332825660706,
      0.05624691769480705,
      -0.0034543254878371954,
      -0.03112359717488289,
      -0.09837768226861954,
      0.007226719055324793,
      -0.12210820615291595,
      -0.05398910865187645,
      0.03940088301897049,
      -0.0930740237236023,
      -0.05088130012154579,
      -0.032021984457969666,
      -0.028383631259202957,
      0.08438772708177567,
      -0.009745895862579346,
      -0.025417577475309372,
      -0.023395616561174393,
      -0.007205978501588106,
      0.05045778304338455,
      0.007818272337317467,
      0.0010978614445775747,
      0.04373414069414139,
      -0.017820613458752632,
      0.04741517826914787,
      0.007856782525777817,
      0.010366145521402359,
      0.03994692862033844,
      0.003279168624430895,
      0.0003748500603251159,
      0.07410851120948792,
      0.04966694116592407,
      -0.04284975677728653,
      0.06428729742765427,
      0.06183544173836708,
      0.013606179505586624,
      0.12282263487577438,
      -0.014971381053328514,
      -0.044998280704021454,
      0.02370329201221466,
      -0.026972157880663872,
      0.03369716927409172,
      -0.0007674029329791665,
      -0.01152101345360279,
      0.047802019864320755,
      0.036155086010694504,
      -0.01604446955025196,
      0.016985174268484116,
      -0.06693273037672043,
      0.038303159177303314,
      -0.057807981967926025,
      -0.026815200224518776,
      -0.049208130687475204,
      0.001325876102782786,
      0.010959302075207233,
      0.014952603727579117,
      0.03791226074099541,
      0.016732819378376007,
      -0.028678931295871735,
      -0.003786800429224968,
      -0.020375078544020653,
      0.06689733266830444,
      0.01742303930222988,
      -0.05760485678911209,
      0.05024418979883194,
      -0.038783539086580276,
      0.006724258419126272,
      0.010815481655299664,
      0.004521702881902456,
      0.10927022248506546,
      0.010247651487588882,
      -0.03456446900963783,
      -0.028919117525219917,
      -0.025396304205060005,
      -0.054735682904720306,
      0.060899004340171814,
      -0.10362042486667633,
      -0.06548898667097092,
      -0.07053877413272858,
      -0.04748430475592613,
      -0.07331518828868866,
      0.001817911397665739,
      -0.032347116619348526,
      -0.023522887378931046,
      0.05618013069033623,
      0.06704767793416977,
      0.029714176431298256,
      -0.08311166614294052,
      0.045863911509513855,
      0.03851650655269623,
      -0.010693920776247978,
      2.6668891223380342e-05,
      -0.06754661351442337,
      0.09981774538755417,
      -0.025858020409941673,
      0.04286228120326996,
      -0.05597194284200668,
      -0.046870771795511246,
      -0.06989583373069763,
      -0.017866836860775948,
      -0.06104717403650284,
      0.06238655000925064,
      -0.03559477999806404,
      0.014281131327152252,
      -0.04999721422791481,
      0.0020882461685687304,
      0.06181104853749275,
      -0.006345116999000311,
      -0.012624540366232395,
      -0.0062470813281834126,
      0.030000966042280197,
      0.01951364427804947,
      0.006354125682264566,
      -0.006760980002582073,
      -0.008415752090513706,
      -0.03151373565196991,
      -0.02047945186495781,
      -0.018454041332006454,
      0.01661600172519684,
      -0.095108263194561,
      -0.09467430412769318,
      0.08948782086372375,
      0.02492585778236389,
      0.05183025449514389,
      -0.03350148722529411,
      -0.034344371408224106,
      -0.03111669421195984,
      -0.025986049324274063,
      -0.06658138334751129,
      -0.001508984249085188,
      -0.0672951266169548,
      -0.03472912684082985,
      -0.02701900340616703,
      -0.020529326051473618,
      -0.006643584929406643,
      -0.06669016182422638,
      0.14600075781345367,
      0.0050069671124219894,
      0.07104220986366272,
      0.05277535319328308,
      -0.004398828838020563,
      0.03959651663899422,
      -0.0912439152598381,
      -0.08837557584047318,
      -0.006540903355926275,
      -0.026677386835217476,
      -0.03892749175429344,
      0.08534282445907593,
      0.006898608058691025,
      0.015213812701404095,
      -0.04173280671238899,
      -0.06058993190526962,
      0.07060835510492325,
      0.04836493358016014,
      -0.01844518445432186,
      -0.059048302471637726,
      -0.017310524359345436,
      -0.0027761487290263176,
      0.010190805420279503,
      0.005076549015939236,
      0.06632351875305176,
      -0.0264121163636446,
      -0.05759503319859505,
      -0.020282413810491562,
      0.02562139928340912,
      0.04170340672135353,
      -0.09147688746452332,
      -0.008552209474146366,
      0.04631538689136505,
      -0.0022370603401213884,
      0.01280458178371191,
      -0.01540293451398611,
      -0.008597115986049175,
      0.015483944676816463,
      0.03245877847075462,
      -0.08570259064435959,
      -0.019687490537762642,
      0.013402334414422512,
      -0.04760783165693283,
      0.04433896020054817,
      0.03513446822762489,
      -0.008699691854417324,
      0.008390315808355808,
      0.03317868337035179,
      -0.007576402742415667,
      -0.056751757860183716,
      0.05304363742470741,
      -0.10990210622549057,
      0.04381938278675079,
      0.013272330164909363,
      0.04274292290210724,
      0.0373133160173893,
      -0.04168037697672844,
      0.06712192296981812,
      0.08516725152730942,
      0.00569160608574748,
      -0.05943376198410988,
      0.0470990389585495,
      0.009894296526908875,
      0.05025358870625496,
      -0.11040254682302475,
      -0.017706505954265594,
      0.01983821764588356,
      -0.005258839577436447,
      0.01632115989923477,
      -0.0281907357275486,
      0.023658860474824905,
      0.0423203743994236,
      0.04292295128107071,
      0.016632957383990288,
      -0.022122222930192947,
      0.008111992850899696,
      -0.04818291589617729,
      0.07549775391817093,
      -0.07222071290016174,
      0.04734358191490173,
      0.021498411893844604,
      -0.04846859350800514,
      -0.014248603023588657,
      -0.002617507940158248,
      -0.03209614008665085,
      0.02632017619907856,
      0.07401508837938309,
      0.0269574373960495,
      -0.07864417880773544,
      0.019176846370100975,
      0.050178851932287216,
      0.05993247032165527,
      -0.09479324519634247,
      0.030550282448530197,
      -0.05246611312031746,
      0.005223151296377182,
      0.04398791491985321,
      0.08046812564134598,
      0.009487793780863285,
      0.07287430763244629,
      -0.10431385785341263,
      -0.024181023240089417,
      0.08327805250883102,
      0.04927806183695793,
      -0.09734128415584564,
      -0.010205227881669998,
      -0.015497644431889057,
      -0.021291587501764297,
      0.08063632994890213,
      -0.03862745314836502,
      0.007527279667556286,
      -0.018211377784609795,
      -0.013622729107737541,
      0.011133970692753792,
      0.07343432307243347,
      -0.005100927781313658,
      -0.10596832633018494,
      0.012744510546326637,
      -0.07616595923900604,
      0.08290919661521912,
      -0.053780220448970795,
      0.00273041520267725,
      0.018156642094254494,
      0.022297943010926247,
      0.02412348799407482,
      0.047405388206243515,
      -0.025922825559973717,
      0.03703765571117401,
      -0.02513829991221428,
      -0.022389136254787445,
      -0.042271681129932404,
      0.04577329009771347,
      0.05919051170349121,
      0.03606033697724342,
      -0.022211328148841858,
      0.020073363557457924,
      -0.06677384674549103,
      -0.030735798180103302,
      0.010999837890267372,
      0.050158362835645676,
      -0.06783253699541092,
      -0.05751786381006241,
      0.022553130984306335,
      -0.008515816181898117,
      0.06278584152460098,
      -0.11696049571037292,
      -0.029362259432673454,
      0.0007345837657339871,
      0.029887938871979713,
      0.13357968628406525,
      -0.04139561951160431,
      -0.020453808829188347,
      -0.0488717257976532,
      -0.00831923820078373,
      0.0590532049536705,
      0.008441262878477573,
      0.0319487638771534,
      -0.05997469276189804,
      0.05130781605839729,
      0.0006158690666779876,
      -0.0013557255733758211,
      0.024897241964936256,
      0.012773643247783184,
      -0.007074257358908653,
      -0.024375321343541145,
      -0.005467829294502735,
      0.021189995110034943,
      0.011345022358000278,
      -0.009777853265404701,
      -0.011688929982483387,
      0.004678788594901562,
      -0.028609000146389008,
      0.061382949352264404,
      0.03071819245815277,
      -0.03953349590301514,
      -0.0017875934718176723,
      0.01828872226178646,
      0.04379338398575783,
      -0.01569983921945095,
      0.024627549573779106,
      0.033762913197278976,
      0.00588702829554677,
      0.0021327449940145016,
      0.04051803797483444,
      0.01975993998348713,
      0.0399014987051487,
      0.07152081280946732,
      -0.024108974263072014,
      -0.04794144630432129
    ],
    [
      0.04602072015404701,
      -0.011837221682071686,
      -0.0066261026076972485,
      -0.0666714608669281,
      -0.010984559543430805,
      -0.0018577666487544775,
      0.024960938841104507,
      -0.0326041653752327,
      0.05009100213646889,
      0.030330976471304893,
      0.03394404426217079,
      0.011420371010899544,
      0.017527732998132706,
      0.015264890156686306,
      0.015273338183760643,
      -0.03342631086707115,
      0.042426083236932755,
      0.05877005308866501,
      0.06877218931913376,
      0.04218882694840431,
      0.00648031709715724,
      -0.008536245673894882,
      0.0226499754935503,
      -0.009131007827818394,
      -0.04666301608085632,
      0.018907930701971054,
      -0.02276415191590786,
      0.06207723170518875,
      0.07096343487501144,
      0.006124566774815321,
      -0.027286680415272713,
      -0.02584884501993656,
      0.025543510913848877,
      -0.01078948937356472,
      0.055407505482435226,
      -0.042423930019140244,
      0.034093450754880905,
      0.013790435157716274,
      -0.0024414556100964546,
      -0.002893366850912571,
      0.017938654869794846,
      0.019128968939185143,
      0.06304965168237686,
      -0.02134428732097149,
      -0.03165719658136368,
      0.06254157423973083,
      -0.14025363326072693,
      -0.026142071932554245,
      -0.013533319346606731,
      0.03519774600863457,
      0.03668239712715149,
      -0.035287339240312576,
      0.054564058780670166,
      0.13469292223453522,
      0.09031867980957031,
      0.01662503369152546,
      -0.0369931124150753,
      -0.05878087505698204,
      0.023429302498698235,
      0.1460435539484024,
      0.03238801658153534,
      0.005789418239146471,
      -0.05697334185242653,
      -0.01967681758105755,
      -0.07337949424982071,
      -0.012701913714408875,
      0.011042095720767975,
      0.05168016999959946,
      0.04804213345050812,
      -0.03532366827130318,
      -0.007057437673211098,
      0.004269226919859648,
      0.018883349373936653,
      -0.08476407825946808,
      -0.013346285559237003,
      0.010513068176805973,
      0.02573431096971035,
      -0.0020277679432183504,
      0.0825289934873581,
      -0.026446670293807983,
      -0.0024213388096541166,
      0.010790450498461723,
      -0.018984831869602203,
      -0.0331026092171669,
      -0.04372204467654228,
      -0.022661730647087097,
      -0.0190086979418993,
      0.08010777086019516,
      0.054782088845968246,
      0.030531950294971466,
      -0.06691459566354752,
      -0.05167001485824585,
      -0.07475069165229797,
      0.10038018226623535,
      -0.03679891675710678,
      0.03390001505613327,
      -0.004463118501007557,
      0.043455708771944046,
      -0.0012096725404262543,
      0.0045322696678340435,
      0.08058350533246994,
      0.021966343745589256,
      0.047035329043865204,
      -0.0472964271903038,
      -0.024548275396227837,
      0.03659101948142052,
      0.06161095201969147,
      -0.029146108776330948,
      -0.038058485835790634,
      0.07830548286437988,
      -0.017298437654972076,
      0.01156592182815075,
      -0.0008823849493637681,
      -0.005697683431208134,
      0.0693972110748291,
      0.020369650796055794,
      -0.015488747507333755,
      -0.005932690110057592,
      0.06215236708521843,
      0.01723487116396427,
      0.016598744317889214,
      0.018850963562726974,
      0.0566905215382576,
      -0.028429631143808365,
      -0.11859224736690521,
      -0.028284341096878052,
      0.052613794803619385,
      0.04463914781808853,
      0.09158796072006226,
      -0.023934654891490936,
      0.032254017889499664,
      0.021014811471104622,
      0.0002080118574667722,
      0.03667387738823891,
      0.003085474483668804,
      0.020096784457564354,
      0.07111846655607224,
      0.11213455349206924,
      0.02563130296766758,
      0.024937476962804794,
      -0.005065079778432846,
      -0.03942744806408882,
      0.000643776380456984,
      0.004401479382067919,
      -0.0926600769162178,
      0.025608181953430176,
      0.08505180478096008,
      -0.03892866149544716,
      0.018855299800634384,
      -0.006800292991101742,
      0.006864309776574373,
      0.10241987556219101,
      0.05427023395895958,
      -0.00666902307420969,
      0.0006948270020075142,
      0.07323665916919708,
      0.09494338929653168,
      -0.013629069551825523,
      0.00045140404836274683,
      -0.07488898932933807,
      -0.02467920631170273,
      0.007569842040538788,
      -0.0691944882273674,
      -0.07207246124744415,
      0.017357252538204193,
      0.010838347487151623,
      -0.034319620579481125,
      -0.043431807309389114,
      -0.04034178704023361,
      0.04975036904215813,
      -0.015592187643051147,
      -0.052738454192876816,
      0.10096413642168045,
      -0.010816231369972229,
      0.015232681296765804,
      0.0022805281914770603,
      0.03451356291770935,
      0.02453487366437912,
      0.0007450116099789739,
      -0.04851502552628517,
      -0.06782127171754837,
      -0.0793721079826355,
      -0.0463891364634037,
      -0.014448289759457111,
      -0.043919313699007034,
      -0.0009181375498883426,
      0.030242659151554108,
      0.09987962245941162,
      -0.025807594880461693,
      0.06675601005554199,
      -0.016309969127178192,
      0.0201918613165617,
      -0.02227860316634178,
      -0.01636166125535965,
      0.0545552633702755,
      0.018153207376599312,
      0.03701210767030716,
      0.08975692838430405,
      0.02873382531106472,
      -0.013583860360085964,
      -0.05515747889876366,
      -0.027266554534435272,
      0.008938652463257313,
      -0.026471123099327087,
      -0.002185867866501212,
      -0.029328947886824608,
      -0.07365381717681885,
      -0.022911079227924347,
      0.06672302633523941,
      -0.013827542774379253,
      0.028725529089570045,
      -0.016617488116025925,
      -0.03698812797665596,
      0.06267481297254562,
      -0.017834434285759926,
      -0.01972554624080658,
      0.0093174884095788,
      0.055349115282297134,
      -0.06496942788362503,
      0.022036487236618996,
      0.013921908102929592,
      0.04371371120214462,
      0.008516442961990833,
      0.02069067768752575,
      0.023761240765452385,
      -0.036729421466588974,
      0.06712689250707626,
      0.10790951550006866,
      -0.00011178231216035783,
      0.026129843667149544,
      -0.031030546873807907,
      -0.04410010203719139,
      -0.006629005074501038,
      -0.04622863233089447,
      -0.030958596616983414,
      -0.043375413864851,
      0.036625951528549194,
      0.08594179153442383,
      0.06551115959882736,
      0.01677953451871872,
      0.006796976085752249,
      0.013276612386107445,
      -0.010561208240687847,
      0.02192424237728119,
      -0.012104536406695843,
      0.06731466203927994,
      -0.11126366257667542,
      0.04365195706486702,
      0.0657075047492981,
      -0.004092371091246605,
      -0.026125097647309303,
      0.019096119329333305,
      -0.025553038343787193,
      -0.0250529907643795,
      -0.0006792094791308045,
      0.012382852844893932,
      0.05048634111881256,
      0.06731929630041122,
      0.016329729929566383,
      -0.015890786424279213,
      0.018094511702656746,
      0.028863130137324333,
      0.05812043696641922,
      -0.04101540893316269,
      -0.05274581164121628,
      -0.0015166174853220582,
      0.017857320606708527,
      -0.05866996571421623,
      0.0391898974776268,
      0.08067703247070312,
      0.02290145494043827,
      0.008832145482301712,
      -0.05999003350734711,
      0.05338439717888832,
      0.0018426690949127078,
      -0.03706014156341553,
      0.003401373978704214,
      -0.05689171701669693,
      0.04759819805622101,
      -0.026814261451363564,
      -0.0339072123169899,
      0.03526946157217026,
      0.03620617464184761,
      -0.009164571762084961,
      -0.0005590359796769917,
      -0.0074973334558308125,
      0.0182245634496212,
      0.02864936552941799,
      -0.006524725817143917,
      -0.01309688575565815,
      -0.03652625530958176,
      0.0022324672900140285,
      -0.046005044132471085,
      -0.05967715010046959,
      -0.032659124583005905,
      0.06167346611618996,
      -0.02665598690509796,
      -0.04968176409602165,
      0.05597761273384094,
      0.11031179130077362,
      0.04366864264011383,
      0.028987428173422813,
      0.03508857637643814,
      0.10690950602293015,
      0.00937840435653925,
      0.014664601534605026,
      0.0604071319103241,
      0.11047815531492233,
      -0.023745249956846237,
      -0.026391401886940002,
      0.028116105124354362,
      -0.020507914945483208,
      -0.0021934036631137133,
      -0.0740765854716301,
      0.015453329309821129,
      -0.05968033894896507,
      -0.000594883575104177,
      -0.02113117277622223,
      0.0014335750602185726,
      -0.034820567816495895,
      -0.006341019179672003,
      0.05176630616188049,
      0.04569905251264572,
      -8.235245331889018e-05,
      0.016686953604221344,
      0.08933470398187637,
      -0.00857529416680336,
      -0.0023703775368630886,
      0.0404004342854023,
      -0.014409723691642284,
      -0.09770654141902924,
      -0.045164257287979126,
      -0.08942552655935287,
      0.029846766963601112,
      0.019915545359253883,
      0.04722216725349426,
      0.02008584327995777,
      -0.0002848917793016881,
      0.01835760474205017,
      -0.11174165457487106,
      0.028541527688503265,
      0.020492663607001305,
      -0.005611433181911707,
      -0.04169365391135216,
      0.049507129937410355,
      -0.0845145434141159,
      -0.0308817308396101,
      -0.02651880495250225,
      0.07901853322982788,
      -0.04260941967368126,
      -0.007032305933535099,
      -0.03323214128613472,
      -0.04150635376572609,
      0.08841986954212189,
      -0.022614886984229088,
      -0.04510540887713432,
      0.008957622572779655,
      0.033243365585803986,
      -0.03621451556682587,
      0.027839988470077515,
      0.021587831899523735,
      0.017379088327288628,
      0.02552480809390545,
      0.06428979337215424,
      -0.023995323106646538,
      0.005165781360119581,
      0.017089029774069786,
      0.02585493214428425,
      0.03321823105216026,
      -0.004731512162834406,
      -0.030302146449685097,
      0.016414228826761246,
      -0.04049922898411751,
      0.0069571929052472115,
      -0.042838964611291885,
      0.024219956248998642,
      0.029702266678214073,
      -0.050666432827711105,
      0.005231233313679695,
      0.0047541349194943905,
      -0.010479536838829517,
      -0.004447326995432377,
      0.07239881902933121,
      0.04318006709218025,
      -0.0056625609286129475,
      0.016626868396997452,
      0.032725196331739426,
      0.042856913059949875,
      -0.054469186812639236,
      -0.06500405073165894,
      0.037144649773836136,
      0.007093845866620541,
      -0.04819018021225929,
      -0.0217200368642807,
      0.005180006846785545,
      0.0002893950731959194,
      0.04488373175263405,
      -0.1356908082962036,
      -0.06883379817008972,
      -0.010368699207901955,
      0.02661781944334507,
      -0.09015596657991409,
      -0.059513259679079056,
      -0.02885957434773445,
      -0.06821108609437943,
      0.10843707621097565,
      0.05793236568570137,
      -0.05290716513991356,
      -0.02437824197113514,
      -0.03143622353672981,
      0.0831177681684494,
      0.04780591279268265,
      0.02035079151391983,
      0.04111528396606445,
      0.02019667439162731,
      0.041815366595983505,
      0.0349118709564209,
      -0.06319484859704971,
      0.056339461356401443,
      -0.032777171581983566,
      -0.0610060840845108,
      -0.03618134185671806,
      -0.015964077785611153,
      -0.0475618839263916,
      0.03624170646071434,
      0.02959883213043213,
      0.01181353721767664,
      0.012571634724736214,
      0.033339228481054306,
      -0.06641785055398941,
      0.010009830817580223,
      0.0070199863985180855,
      -0.018524033948779106,
      0.025420045480132103,
      -0.04426482319831848,
      0.08487805724143982,
      -0.020617499947547913,
      0.02261885441839695,
      -0.003987425472587347,
      -0.023664698004722595,
      0.014482135884463787,
      -0.06337413936853409,
      -0.01915626786649227,
      0.054798662662506104,
      0.025121411308646202,
      0.04180837422609329,
      -0.11641325056552887,
      0.0417134054005146,
      -0.02957329712808132,
      -0.003763183718547225,
      -0.0022318228147923946,
      0.0036317300982773304,
      0.04554634541273117,
      -0.042752090841531754,
      -0.07589972019195557,
      0.0682118833065033,
      -0.04532165825366974,
      0.08785043656826019,
      0.03371207416057587,
      0.018109025433659554,
      0.03491003438830376,
      -0.05021829158067703,
      -0.0075143612921237946,
      0.051160890609025955,
      0.0007987446733750403,
      0.053302131593227386,
      0.04733509570360184,
      0.03927886113524437,
      -0.049665238708257675,
      -0.028736839070916176,
      0.0029981445986777544,
      0.040727678686380386,
      0.03348151221871376,
      0.05488608777523041,
      0.06797115504741669,
      -0.03693098574876785,
      -0.027160348370671272,
      -0.05880274996161461,
      -0.06253904104232788,
      0.008612249977886677,
      -0.024783384054899216,
      -0.03604336082935333,
      -0.0038246160838752985,
      -0.03715832903981209,
      0.03595110774040222,
      0.004931615665555,
      -0.004467364866286516,
      -0.020506184548139572,
      0.008108186535537243,
      -0.0067368196323513985,
      -0.059064459055662155,
      0.030356112867593765,
      0.037920981645584106,
      -0.07618993520736694,
      0.07329080253839493,
      0.024533754214644432,
      -0.00800003670156002,
      0.01869129203259945,
      0.0013350096996873617,
      0.012597499415278435,
      0.039572663605213165,
      0.01912652514874935,
      0.012881940230727196
    ],
    [
      -0.08072908967733383,
      0.025973159819841385,
      -0.07778331637382507,
      -0.07222025096416473,
      -0.07818005979061127,
      0.01643841527402401,
      -0.023150788620114326,
      0.0648433268070221,
      -0.018876640126109123,
      -0.043562211096286774,
      0.03590153157711029,
      -0.05193821340799332,
      0.04501316696405411,
      0.000910451402887702,
      -0.03488101065158844,
      -0.025617362931370735,
      -0.005332726053893566,
      0.019315483048558235,
      -0.0660683736205101,
      -0.11568847298622131,
      0.008393658325076103,
      0.003944383468478918,
      -0.03250410407781601,
      -0.039759278297424316,
      -0.10419633984565735,
      0.023429153487086296,
      0.005230690352618694,
      -0.0023903739638626575,
      0.03541914001107216,
      0.06387820094823837,
      0.0013585732085630298,
      0.014052305370569229,
      0.00045790610602125525,
      0.0111957136541605,
      0.050273675471544266,
      -0.009125133976340294,
      0.04987497255206108,
      0.09685228019952774,
      0.046054668724536896,
      0.003671585349366069,
      0.04619096219539642,
      -0.08907904475927353,
      0.028636647388339043,
      -0.04526017606258392,
      0.008554142899811268,
      0.02559094876050949,
      -0.08628479391336441,
      -0.021900838240981102,
      -0.06070283055305481,
      -0.06366848945617676,
      0.013816041871905327,
      -0.08991396427154541,
      0.024040544405579567,
      -0.011967375874519348,
      0.01682855375111103,
      0.04036445915699005,
      -0.016418075188994408,
      0.0054581440053880215,
      0.03668031096458435,
      -0.011928260326385498,
      -0.04046553000807762,
      -0.02569127082824707,
      0.04509071633219719,
      -0.00597079424187541,
      0.06676846742630005,
      0.02670506201684475,
      -0.004854146856814623,
      -0.04687916859984398,
      0.0251301396638155,
      -0.032251808792352676,
      -0.02573438733816147,
      0.004697911906987429,
      0.03447374701499939,
      0.030944345518946648,
      0.028322752565145493,
      -0.07005361467599869,
      0.07001321762800217,
      0.020041294395923615,
      0.10618741065263748,
      0.027562472969293594,
      0.0002712028508540243,
      -0.10414353758096695,
      0.001753876218572259,
      0.016012517735362053,
      0.07332118600606918,
      0.04713292047381401,
      -0.047185372561216354,
      -0.017792416736483574,
      -5.018925548938569e-06,
      -0.00032122639822773635,
      -0.05259015038609505,
      0.0267234705388546,
      -0.10041947662830353,
      -0.06300469487905502,
      0.025989295914769173,
      -0.00595880439504981,
      -0.09294873476028442,
      0.0067252893932163715,
      -0.04093250259757042,
      -0.0502210333943367,
      0.08704932034015656,
      0.03574700653553009,
      0.008721067570149899,
      0.010098829865455627,
      0.09264865517616272,
      0.048327695578336716,
      0.01583828032016754,
      0.05479016155004501,
      0.03541760891675949,
      -0.027375690639019012,
      -0.09663542360067368,
      0.01309391763061285,
      0.06133382394909859,
      -0.05633046478033066,
      -0.019867565482854843,
      0.055924177169799805,
      -0.012805165722966194,
      -0.04046661779284477,
      0.018134983256459236,
      0.017265450209379196,
      -0.03751255199313164,
      0.11215988546609879,
      0.08061856776475906,
      0.04757515341043472,
      0.04047151282429695,
      0.046168241649866104,
      0.0903465747833252,
      0.05548662692308426,
      0.050119269639253616,
      0.0031173957977443933,
      0.017233606427907944,
      0.04315195232629776,
      0.00740147614851594,
      0.012444542720913887,
      0.08424447476863861,
      0.032008878886699677,
      0.0033564413897693157,
      0.11898977309465408,
      -0.07944653928279877,
      -0.011637569405138493,
      -0.05305548384785652,
      0.00142870272975415,
      -0.01963219791650772,
      0.10946910083293915,
      -0.02884821407496929,
      0.048232655972242355,
      0.023672552779316902,
      -0.04665909335017204,
      0.030708998441696167,
      0.032748255878686905,
      -0.05489334464073181,
      0.04144660010933876,
      0.021038582548499107,
      -0.04210199788212776,
      -0.07029282301664352,
      0.007452082820236683,
      -0.012416243553161621,
      0.028628267347812653,
      0.0005596971604973078,
      -0.003919611219316721,
      -0.08545497059822083,
      -0.021315576508641243,
      0.013264532200992107,
      0.00040542613714933395,
      -0.004031443502753973,
      0.007290530949831009,
      0.08464261144399643,
      0.07461176812648773,
      0.05302939936518669,
      -0.025178436189889908,
      0.07165196537971497,
      0.002330722752958536,
      -0.02248011901974678,
      -0.031233804300427437,
      -0.014183399267494678,
      -0.05069005489349365,
      -0.02092633955180645,
      0.001451889518648386,
      -0.050551317632198334,
      0.02163129299879074,
      0.030236741527915,
      -0.027687862515449524,
      0.01167591568082571,
      -0.010209822095930576,
      -0.024004589766263962,
      -0.08511523902416229,
      -0.03930700570344925,
      -0.011021018028259277,
      -0.008730619214475155,
      0.009677723981440067,
      0.016173668205738068,
      -0.007938453927636147,
      -0.011831366457045078,
      -0.07018724828958511,
      0.0784134641289711,
      0.0034213431645184755,
      -0.14995037019252777,
      0.07311716675758362,
      -0.05632924661040306,
      -0.08065236359834671,
      0.02802862413227558,
      0.019588923081755638,
      0.09843119978904724,
      -0.02140083722770214,
      0.058816615492105484,
      -0.07505989074707031,
      0.041435789316892624,
      -0.05318481847643852,
      -0.0082504041492939,
      -0.018224842846393585,
      0.0065376041457057,
      0.06457134336233139,
      0.041798047721385956,
      0.004451137036085129,
      0.06294342875480652,
      0.08104874193668365,
      0.03262031823396683,
      0.0006845990428701043,
      0.0811154767870903,
      -0.002949632005766034,
      0.026581211015582085,
      -0.02702113799750805,
      0.04088632017374039,
      0.07019651681184769,
      -0.01978917233645916,
      -0.014929963275790215,
      -0.011188956908881664,
      0.041147731244564056,
      -0.004927093628793955,
      -0.07531997561454773,
      -0.06163745000958443,
      -0.052935194224119186,
      0.06332791596651077,
      -0.0882539451122284,
      0.02545744739472866,
      0.025694718584418297,
      -0.0073582809418439865,
      0.04018568992614746,
      -0.12713342905044556,
      0.053831618279218674,
      0.017309382557868958,
      -0.014469444751739502,
      0.025167416781187057,
      -0.035798341035842896,
      0.028524426743388176,
      0.022943176329135895,
      0.047913093119859695,
      0.01561830099672079,
      -0.014462891966104507,
      -0.013420513831079006,
      0.012988748960196972,
      0.002164742909371853,
      0.04147413372993469,
      0.05248832702636719,
      0.011642230674624443,
      0.005247823428362608,
      0.06211240962147713,
      -0.011199409142136574,
      -0.004527930170297623,
      0.012359201908111572,
      0.030850179493427277,
      0.04457524046301842,
      0.027649996802210808,
      -0.006680926773697138,
      0.03779694810509682,
      0.02157471515238285,
      0.07434739172458649,
      -0.0015659788623452187,
      0.06619827449321747,
      0.02798440493643284,
      0.02854393608868122,
      0.010291864164173603,
      0.05399462953209877,
      0.05246798321604729,
      0.06534144282341003,
      -0.037878524512052536,
      0.07651212066411972,
      -0.02525799535214901,
      0.07442314177751541,
      -0.05756944790482521,
      0.036382101476192474,
      -0.02889985218644142,
      0.023609377443790436,
      0.037963755428791046,
      0.016754332929849625,
      -0.04644644260406494,
      -0.019760580733418465,
      -0.033244747668504715,
      -0.027117179706692696,
      -0.004616189748048782,
      0.04236205667257309,
      0.03803950548171997,
      0.0617176778614521,
      0.06016972288489342,
      -0.05629744380712509,
      -0.0213076900690794,
      -0.04170507565140724,
      0.0020472442265599966,
      0.013145726174116135,
      0.047608017921447754,
      -0.05349262058734894,
      -0.005300817079842091,
      0.01221497729420662,
      0.0846230685710907,
      -0.007320672273635864,
      0.052577774971723557,
      -0.010838139802217484,
      0.03721900284290314,
      -0.028204504400491714,
      -0.017655570060014725,
      0.0333598256111145,
      -0.06403101235628128,
      -0.033393386751413345,
      0.01353759877383709,
      -0.03932761773467064,
      -0.029102694243192673,
      -0.014449162408709526,
      -0.013402505777776241,
      -0.06072302535176277,
      0.020577725023031235,
      0.0877993106842041,
      -0.02286204695701599,
      -0.08923672139644623,
      0.0183390025049448,
      -0.035663213580846786,
      0.1042841300368309,
      0.03035319782793522,
      0.06189225986599922,
      -0.04470912367105484,
      0.008094397373497486,
      -0.02357153780758381,
      -0.010592876002192497,
      0.027715522795915604,
      -0.028849732130765915,
      0.009933835826814175,
      0.021325312554836273,
      -0.014154594391584396,
      -0.021221589297056198,
      -0.013343961909413338,
      -0.030466269701719284,
      0.029226483777165413,
      0.0485425628721714,
      0.004202827345579863,
      0.029859766364097595,
      -0.01339996512979269,
      0.034789834171533585,
      0.030171453952789307,
      0.0002393176400801167,
      -0.004801630973815918,
      -0.014740126207470894,
      0.023776264861226082,
      0.002960615325719118,
      -0.04475261643528938,
      0.02381444349884987,
      -0.014844008721411228,
      -0.027543075382709503,
      0.06714784353971481,
      0.0632668286561966,
      0.04955792427062988,
      0.037012979388237,
      -0.0004732313100248575,
      -0.02185467816889286,
      -0.018723109737038612,
      -0.03822353854775429,
      0.039357900619506836,
      0.034572117030620575,
      -0.040259428322315216,
      0.03531849756836891,
      -0.0602189339697361,
      0.025569887831807137,
      0.014090979471802711,
      0.054652098566293716,
      -0.008680733852088451,
      0.03153808414936066,
      -0.022517502307891846,
      -0.014985195361077785,
      0.03556835278868675,
      0.040838904678821564,
      -0.006127703934907913,
      0.022183716297149658,
      0.029890676960349083,
      -0.0034013341646641493,
      -0.01666950061917305,
      0.06661268323659897,
      0.03417427837848663,
      0.07232378423213959,
      0.01803848333656788,
      0.018680905923247337,
      -0.03494231402873993,
      0.03669438511133194,
      -0.0017998551484197378,
      0.07670927792787552,
      0.013709723949432373,
      -0.057796213775873184,
      -0.06808523088693619,
      -0.08653395622968674,
      -0.07413987070322037,
      0.0016049600671976805,
      -0.0016020929906517267,
      0.0029144003055989742,
      0.04964405670762062,
      0.026941267773509026,
      -0.0404464490711689,
      -0.1049109473824501,
      0.09133341163396835,
      -0.07043120265007019,
      0.038332629948854446,
      -0.06851161271333694,
      0.032052330672740936,
      0.031073814257979393,
      0.026105808094143867,
      0.06756213307380676,
      -0.04968588799238205,
      -0.11170946061611176,
      0.058591146022081375,
      -0.0014029484009370208,
      0.11398640275001526,
      0.08818934857845306,
      -0.013383915647864342,
      -0.019856896251440048,
      -0.03604467958211899,
      -0.029799534007906914,
      0.0063361190259456635,
      0.04382249340415001,
      0.013735736720263958,
      0.04779590293765068,
      -0.03772939741611481,
      -0.015393939800560474,
      -0.04497566819190979,
      0.03712523728609085,
      0.03950145095586777,
      -0.029231619089841843,
      -0.06240267679095268,
      0.10612868517637253,
      0.037533026188611984,
      0.01783430017530918,
      -0.08403405547142029,
      -0.08969736844301224,
      0.020244764164090157,
      0.033992208540439606,
      0.041516441851854324,
      -0.07953149825334549,
      -0.0369863361120224,
      -0.028292935341596603,
      0.03002224862575531,
      0.006884464994072914,
      0.011204374954104424,
      0.018894532695412636,
      -0.020482387393712997,
      -0.010608011856675148,
      -0.0018116810824722052,
      -0.04383115842938423,
      0.05962260812520981,
      0.01674600876867771,
      0.0013195591745898128,
      -0.046040941029787064,
      -0.022343555465340614,
      -0.010167981497943401,
      0.082340769469738,
      -0.08103445172309875,
      0.00015033491945359856,
      0.011833250522613525,
      0.051580533385276794,
      0.04971340671181679,
      -0.0025633606128394604,
      0.028170699253678322,
      0.0006384499138221145,
      -0.030716722831130028,
      -0.008490001782774925,
      -0.0024495108518749475,
      0.01668461225926876,
      -0.01422777958214283,
      0.03414206951856613,
      0.020540928468108177,
      -0.0037987588439136744,
      -0.020414132624864578,
      -0.003730421420186758,
      0.02008523978292942,
      0.03322439640760422,
      -0.016601096838712692,
      0.013817735016345978,
      0.0470706932246685,
      -0.02472529374063015,
      -0.019471948966383934,
      -0.057406265288591385,
      0.041600894182920456,
      0.0359097495675087,
      -0.006864030845463276,
      0.03600781783461571,
      -0.004592744633555412,
      0.0029234772082418203,
      0.020156370475888252,
      -0.006199362687766552,
      0.006897487677633762,
      -0.01744936592876911,
      0.04368413984775543,
      -0.07958327233791351,
      0.031034355983138084,
      0.004567579831928015,
      0.005939549766480923,
      0.03520921245217323,
      -0.04750438407063484,
      -0.1215263083577156
    ],
    [
      0.06940313428640366,
      -0.07421636581420898,
      -0.02147524058818817,
      -0.07049671560525894,
      -0.029988832771778107,
      0.0019177283393219113,
      0.01638328842818737,
      -0.025928355753421783,
      0.012307978235185146,
      -0.017581699416041374,
      -0.009540853090584278,
      0.043304651975631714,
      -0.002178667578846216,
      -0.09009138494729996,
      0.049181800335645676,
      0.014722621999680996,
      -0.023328576236963272,
      0.005837140139192343,
      -0.1180073693394661,
      0.008617927320301533,
      -0.01412119623273611,
      -0.04839636757969856,
      0.06523985415697098,
      0.01173569355159998,
      0.06282581388950348,
      0.08257918059825897,
      -0.02410353161394596,
      0.0895572304725647,
      0.015295780263841152,
      -0.002460433868691325,
      -0.04163419082760811,
      -0.04374139755964279,
      -0.06285572052001953,
      -0.03657255321741104,
      -0.06061016395688057,
      0.00025148698478005826,
      -0.02140694297850132,
      -0.02331622689962387,
      -0.009259642101824284,
      -0.07387680560350418,
      0.019235169515013695,
      -0.08529598265886307,
      -0.05973146855831146,
      0.0023048878647387028,
      -0.03395369276404381,
      0.0012192842550575733,
      -0.09321077167987823,
      -0.029608694836497307,
      0.09213487803936005,
      -0.013107920065522194,
      0.0005830945447087288,
      -0.05225983262062073,
      -0.02278914488852024,
      0.016603490337729454,
      0.0006022758316248655,
      0.008617835119366646,
      -0.014446638524532318,
      0.029276307672262192,
      0.02304144576191902,
      0.029299559071660042,
      -0.07827333360910416,
      -0.025608183816075325,
      0.009884665720164776,
      0.02841857820749283,
      0.061457205563783646,
      0.018501833081245422,
      -0.027628201991319656,
      0.011904437094926834,
      0.0374700091779232,
      -0.06598617136478424,
      -0.00892415177077055,
      -0.023067407310009003,
      -0.03348301351070404,
      0.021903181448578835,
      0.00854486133903265,
      -0.01954447291791439,
      -0.01996828429400921,
      -0.0019355474505573511,
      -0.0345604307949543,
      0.07208994030952454,
      0.02842262201011181,
      0.06510291248559952,
      -0.0787830725312233,
      0.01787753403186798,
      -0.04776440188288689,
      -0.06927282363176346,
      -0.06592664122581482,
      -0.025015922263264656,
      0.14362427592277527,
      0.00551504036411643,
      -0.023906083777546883,
      0.025652632117271423,
      -0.013419495895504951,
      0.06706368178129196,
      -0.0653906762599945,
      -0.03776048868894577,
      -0.011388655751943588,
      0.05860661715269089,
      -0.037510402500629425,
      0.08457707613706589,
      0.05466673523187637,
      0.09594987332820892,
      0.07971648126840591,
      0.06764455139636993,
      -0.07247000932693481,
      -0.053398508578538895,
      -0.06051035597920418,
      -0.060144830495119095,
      0.04385528340935707,
      0.010914615355432034,
      0.030879037454724312,
      -0.049439989030361176,
      -0.044471293687820435,
      0.09898576885461807,
      -0.05277650058269501,
      -0.001463069231249392,
      0.06098531559109688,
      0.025814678519964218,
      -0.00807792879641056,
      -0.01081270445138216,
      0.007970470935106277,
      -0.04824545606970787,
      -0.011213228106498718,
      0.022057972848415375,
      -0.05385471507906914,
      0.0016974053578451276,
      -0.045614223927259445,
      -0.018165703862905502,
      -0.040678154677152634,
      -0.03847331181168556,
      0.032274503260850906,
      -0.015082801692187786,
      0.057802218943834305,
      -0.051549337804317474,
      0.0071112713776528835,
      0.025232411921024323,
      0.027697406709194183,
      -0.027837345376610756,
      -0.012137722223997116,
      -0.007212563883513212,
      -0.018502406775951385,
      -0.0990288257598877,
      -0.04541337117552757,
      -0.02401101402938366,
      0.014505773782730103,
      0.047919485718011856,
      0.021809929981827736,
      -0.02869788557291031,
      0.07184624671936035,
      0.014609169214963913,
      0.04098350927233696,
      0.06645579636096954,
      -0.01997608132660389,
      0.03012281283736229,
      -0.0156214889138937,
      0.04144912585616112,
      -0.04365577548742294,
      0.058889832347631454,
      0.04896119609475136,
      -0.009936539456248283,
      0.01375548169016838,
      0.007707122713327408,
      -0.03259016573429108,
      0.047128431499004364,
      0.042945925146341324,
      -0.02110559307038784,
      -0.061875760555267334,
      -0.012455083429813385,
      0.0017710999818518758,
      0.004415216390043497,
      0.09533041715621948,
      0.008702022023499012,
      0.050282031297683716,
      0.004763851873576641,
      -0.0743984580039978,
      0.030256032943725586,
      -0.026312995702028275,
      -0.0031869318336248398,
      -0.05822134390473366,
      0.006947988644242287,
      -0.04431137442588806,
      0.008012347854673862,
      0.013092170469462872,
      0.035296037793159485,
      -0.04226900264620781,
      -0.004593907855451107,
      0.0494452528655529,
      0.06821324676275253,
      -0.03946048393845558,
      0.006701417732983828,
      -0.02725973352789879,
      -0.014204369857907295,
      -0.028180280700325966,
      -0.014689348638057709,
      -0.05297853797674179,
      -0.02476360835134983,
      0.00046380964340642095,
      0.01494411751627922,
      -0.03951653093099594,
      -0.024045759811997414,
      -0.019415659829974174,
      0.12199824303388596,
      -0.002631042618304491,
      -0.07254815101623535,
      0.009132514707744122,
      -0.010931824333965778,
      -0.008225727826356888,
      -0.11764191836118698,
      -0.02154947817325592,
      -0.016631240025162697,
      -0.06515949219465256,
      0.02996465563774109,
      0.07598908245563507,
      0.0703587457537651,
      0.07704111188650131,
      0.0030303176026791334,
      -0.08442771434783936,
      -0.025490740314126015,
      0.03935839235782623,
      -0.07714591175317764,
      -0.0058923205360770226,
      -0.0038818512111902237,
      0.021681683138012886,
      -0.05674608051776886,
      -0.010477258823812008,
      -0.08672697842121124,
      -0.028925210237503052,
      0.0926198661327362,
      -0.006828288547694683,
      -0.02531156688928604,
      -0.00010453960567247123,
      0.07098670303821564,
      -0.009191545657813549,
      0.04145399481058121,
      0.00804053246974945,
      0.014716506004333496,
      0.06411512196063995,
      -0.012830578722059727,
      -0.10446173697710037,
      -0.02071991190314293,
      -0.0346178337931633,
      0.05211871117353439,
      0.03457298502326012,
      -0.043354909867048264,
      0.03525577113032341,
      0.033261869102716446,
      -0.03490392118692398,
      0.03886720538139343,
      0.011907435022294521,
      -0.0024776486679911613,
      -0.09672576934099197,
      0.018217872828245163,
      -0.0004957763012498617,
      -0.06223863735795021,
      -0.06821782141923904,
      0.007137082051485777,
      0.04221363738179207,
      0.04860236495733261,
      -0.0025725364685058594,
      0.02890741266310215,
      0.004760586656630039,
      0.046040311455726624,
      -0.04258786886930466,
      0.024393979460000992,
      0.07252185791730881,
      0.011500462889671326,
      0.03443790227174759,
      0.016578929498791695,
      -0.02351824752986431,
      0.015032808296382427,
      -0.04132188856601715,
      -0.007898320443928242,
      -0.06232314184308052,
      -0.0020272640977054834,
      0.037642497569322586,
      -0.019051654264330864,
      0.021180303767323494,
      0.024037500843405724,
      0.06609370559453964,
      0.08121112734079361,
      -0.011110115796327591,
      0.01185846421867609,
      -0.1008613109588623,
      0.007615830283612013,
      0.07788945734500885,
      -0.045866046100854874,
      0.005164228845387697,
      0.02467300184071064,
      -0.036065857857465744,
      0.03803079202771187,
      0.032999444752931595,
      0.006101244129240513,
      0.02903090789914131,
      0.013237004168331623,
      -0.047446735203266144,
      0.051016245037317276,
      -0.061861637979745865,
      0.015508798882365227,
      0.010718785226345062,
      -0.09731942415237427,
      0.028791358694434166,
      0.03347240388393402,
      -0.0022032244596630335,
      0.0759432390332222,
      0.021950511261820793,
      0.04725358262658119,
      -0.01367308758199215,
      -0.008112831972539425,
      -0.10767463594675064,
      -0.0650869607925415,
      0.03955363854765892,
      -0.014192710630595684,
      -0.0321291908621788,
      0.006296571809798479,
      0.07142423093318939,
      -0.00283028488047421,
      -0.03402777016162872,
      -0.03311706334352493,
      -0.010654573328793049,
      -0.03262224793434143,
      0.0027031151112169027,
      0.05701633542776108,
      0.0033929862547665834,
      0.05965269356966019,
      -0.0983891636133194,
      0.016604924574494362,
      0.026752736419439316,
      0.057112134993076324,
      -0.037195783108472824,
      0.0917787179350853,
      -0.033751633018255234,
      0.04597969725728035,
      0.02060169167816639,
      -0.030863698571920395,
      -0.06924394518136978,
      -0.027266083285212517,
      -0.024835366755723953,
      -0.046317510306835175,
      0.0022054926957935095,
      0.05685194954276085,
      0.037003420293331146,
      -0.03491032123565674,
      -0.058508362621068954,
      0.02206086739897728,
      -0.007432030979543924,
      0.009066477417945862,
      -0.014605638571083546,
      -0.03341112658381462,
      -0.032029617577791214,
      -0.005892815999686718,
      -0.019649365916848183,
      -0.0003150688426103443,
      0.030600670725107193,
      -0.0042839194647967815,
      0.06435459852218628,
      0.09056933969259262,
      0.019820991903543472,
      0.0696091577410698,
      0.03684046491980553,
      0.059915948659181595,
      -0.01754496805369854,
      -0.010957571677863598,
      -0.002763879718258977,
      -0.029425030574202538,
      0.03190803527832031,
      0.05113255977630615,
      -0.04132821410894394,
      -0.03682167828083038,
      0.013322778977453709,
      0.04277339205145836,
      0.00687833409756422,
      0.04988645762205124,
      0.029246415942907333,
      0.012253488413989544,
      -0.012372184544801712,
      0.006247063633054495,
      -0.03327569365501404,
      0.019720174372196198,
      0.11802178621292114,
      -0.06291931867599487,
      0.02293803170323372,
      0.007235947996377945,
      -0.09933643043041229,
      -9.567167580826208e-05,
      -0.035465311259031296,
      0.005782207474112511,
      -0.03553358465433121,
      0.023053305223584175,
      0.02449728175997734,
      0.02870366722345352,
      -0.03196138143539429,
      0.010916142724454403,
      -0.026216527447104454,
      0.03703276813030243,
      0.005117811728268862,
      -0.01615385338664055,
      0.05032508820295334,
      -0.019523749127984047,
      -0.032970208674669266,
      0.0009715521591715515,
      0.02471177466213703,
      -0.07987381517887115,
      -0.038597337901592255,
      -0.02633257769048214,
      -0.03521672636270523,
      0.03638732433319092,
      0.0038071884773671627,
      0.009291001595556736,
      0.007024124264717102,
      -0.013209452852606773,
      0.04406662657856941,
      0.05767790228128433,
      0.019754737615585327,
      0.0017746856901794672,
      0.05675511807203293,
      -0.001956613501533866,
      0.013146359473466873,
      -0.0026572970673441887,
      0.08849921822547913,
      -0.01891627535223961,
      -0.07247121632099152,
      -0.052409958094358444,
      -0.02657276764512062,
      -0.006121492013335228,
      -0.0603693388402462,
      0.004648356232792139,
      -0.02231197990477085,
      -0.0863148495554924,
      -0.10624171048402786,
      -0.04350082948803902,
      -0.021666893735527992,
      0.0234377384185791,
      0.036661241203546524,
      0.010488258674740791,
      0.03514723479747772,
      0.0011882740072906017,
      0.03910810500383377,
      0.029375778511166573,
      -0.03836164250969887,
      -0.08226887881755829,
      0.06360040605068207,
      0.020702658221125603,
      0.060174692422151566,
      0.05149407312273979,
      0.03424569591879845,
      -0.03871127590537071,
      -0.020568592473864555,
      0.07721108943223953,
      0.008448775857686996,
      0.02046247571706772,
      -0.012884222902357578,
      0.00428166426718235,
      0.04753774404525757,
      -0.018115676939487457,
      -0.01787826605141163,
      -0.03716249391436577,
      0.023669594898819923,
      0.08132940530776978,
      0.01392731536179781,
      -0.003964649047702551,
      0.01495426520705223,
      -0.018430622294545174,
      -0.0366249680519104,
      -0.003535937052220106,
      -0.06520406156778336,
      0.050668865442276,
      0.011274165473878384,
      0.010081241838634014,
      0.0002032139163929969,
      0.024621473625302315,
      0.02496967278420925,
      0.017034851014614105,
      0.11309193074703217,
      0.02997695282101631,
      -0.014077356085181236,
      -0.04969906434416771,
      -0.01146975439041853,
      0.06120333448052406,
      -0.03804781287908554,
      0.008543026633560658,
      -0.0324777327477932,
      0.05951014906167984,
      0.07523096352815628,
      0.01985069550573826,
      -0.01994124799966812,
      0.05113209784030914,
      0.002083096420392394,
      -0.09404763579368591,
      0.015117858536541462,
      -0.05952851474285126,
      0.09966615587472916,
      0.02239438332617283,
      -0.009187248535454273,
      -0.02417801320552826,
      0.03368087485432625,
      -0.03136323019862175,
      -0.0188517477363348,
      0.012249937281012535,
      0.009446393698453903,
      -0.03305162116885185,
      -0.03131542727351189,
      0.04101114347577095,
      -0.024016981944441795
    ],
    [
      -0.031852416694164276,
      0.0488627664744854,
      -0.010819373652338982,
      -0.021997129544615746,
      -0.04462629184126854,
      0.06037861481308937,
      0.016212575137615204,
      0.04976078122854233,
      -0.010241163894534111,
      -0.035673126578330994,
      -0.09282272309064865,
      0.021517695859074593,
      -0.018444446846842766,
      0.006099633406847715,
      -0.010451065376400948,
      -0.01826658844947815,
      0.012521455064415932,
      0.0020091740880161524,
      0.08732645958662033,
      -0.048707906156778336,
      -0.02280651405453682,
      0.02798675373196602,
      -0.013692527078092098,
      0.020772160962224007,
      0.03251419961452484,
      0.10698526352643967,
      0.017873886972665787,
      0.004906720947474241,
      0.012765368446707726,
      -0.01564287394285202,
      0.0016739274142310023,
      0.03679218143224716,
      0.015304566361010075,
      -0.055601827800273895,
      0.031126374378800392,
      -0.026413993909955025,
      0.11760447174310684,
      -0.049802266061306,
      0.06745836138725281,
      0.007054463028907776,
      0.0131431445479393,
      0.005854318384081125,
      -0.010727010667324066,
      0.09020242840051651,
      0.04270797595381737,
      0.006903961766511202,
      0.005396395921707153,
      -0.09903089702129364,
      0.020899547263979912,
      -0.03877684101462364,
      0.02533799223601818,
      0.03122246079146862,
      -0.0372135154902935,
      -0.056012511253356934,
      0.00565561605617404,
      -0.008563562296330929,
      0.016979461535811424,
      -0.07685701549053192,
      0.10008174926042557,
      -0.07966169714927673,
      -0.03747733309864998,
      0.05253959447145462,
      -0.016371678560972214,
      0.0004397332959342748,
      0.08994650095701218,
      0.06180037558078766,
      0.02510152943432331,
      -0.029971588402986526,
      -0.011350211687386036,
      -0.015531091019511223,
      -0.031752295792102814,
      0.007995261810719967,
      0.054418452084064484,
      -0.0089137377217412,
      -0.022555731236934662,
      0.03019026666879654,
      0.003560425713658333,
      0.024412812665104866,
      0.02974933758378029,
      0.024912672117352486,
      0.02190941944718361,
      -0.03881402686238289,
      0.023133020848035812,
      -0.044049423187971115,
      -0.03535453602671623,
      0.033376503735780716,
      -0.06775818765163422,
      0.0047927615232765675,
      0.03629083186388016,
      -0.034165360033512115,
      -0.0668252483010292,
      -0.04555528238415718,
      -0.05661442130804062,
      0.03038526512682438,
      -0.03297285735607147,
      -0.026619376614689827,
      -0.0587175153195858,
      -0.006981352809816599,
      -0.0033881566487252712,
      -0.044018205255270004,
      0.06880782544612885,
      -0.0294329896569252,
      -0.008090331219136715,
      0.04148850962519646,
      0.0298728346824646,
      -0.02272568643093109,
      0.004958001431077719,
      -0.06035727635025978,
      -0.00359453447163105,
      -0.07499394565820694,
      -0.02059137634932995,
      -0.01437463890761137,
      0.011166565120220184,
      -0.02272387221455574,
      -0.002547092968598008,
      0.00963257160037756,
      -0.058341994881629944,
      -0.029343875125050545,
      -0.02620886079967022,
      -0.017201630398631096,
      0.03924071416258812,
      -0.07839890569448471,
      0.020304884761571884,
      0.01627526618540287,
      0.03194007650017738,
      -0.010470414534211159,
      -0.034859973937273026,
      -0.03113238513469696,
      0.07768312096595764,
      -0.010279208421707153,
      -0.0008819791837595403,
      -0.03165644034743309,
      0.047799743711948395,
      0.012634148821234703,
      0.039334360510110855,
      -0.08336519449949265,
      -0.0089208222925663,
      0.0458107590675354,
      -0.018760640174150467,
      0.04924432933330536,
      -0.010146860033273697,
      -0.07660361379384995,
      -0.018063504248857498,
      0.020607218146324158,
      -0.023382240906357765,
      -0.014054151251912117,
      -0.0073972102254629135,
      -0.012465191073715687,
      0.026643941178917885,
      0.07212800532579422,
      -0.01052083633840084,
      -0.037677403539419174,
      -0.024153802543878555,
      -0.020651603117585182,
      0.0937117263674736,
      -0.0039383708499372005,
      0.049273740500211716,
      0.04678761214017868,
      0.017930015921592712,
      0.01958717592060566,
      0.0059983148239552975,
      -0.08594799786806107,
      -0.007509758695960045,
      -0.024489933624863625,
      0.0578526072204113,
      0.005430798511952162,
      0.02799070067703724,
      -0.0313892662525177,
      0.022803951054811478,
      0.10445871949195862,
      -0.03482693433761597,
      0.04743039235472679,
      0.08670534938573837,
      0.052342478185892105,
      0.04963066428899765,
      -0.052115969359874725,
      0.011376634240150452,
      0.02595868892967701,
      0.02110098861157894,
      0.016753222793340683,
      -0.048442281782627106,
      -0.04313916340470314,
      0.08837306499481201,
      -0.021526286378502846,
      0.12069220840930939,
      -0.017925238236784935,
      0.036106839776039124,
      -0.023526102304458618,
      0.0025261659175157547,
      0.061365168541669846,
      -0.0021231675054877996,
      -0.00029850853024981916,
      0.041578128933906555,
      -0.0011505555594339967,
      0.05757242813706398,
      0.010495848953723907,
      0.035790350288152695,
      0.013013513758778572,
      -0.00992704275995493,
      -0.07209912687540054,
      -0.06333503872156143,
      0.10319198668003082,
      0.001063444186002016,
      0.017268894240260124,
      -0.0028940923511981964,
      -0.07807065546512604,
      0.01718839816749096,
      -0.08454549312591553,
      0.028485430404543877,
      0.008405579254031181,
      -0.020087409764528275,
      0.07648146152496338,
      0.14269192516803741,
      0.08717536926269531,
      0.04917217418551445,
      -0.06836110353469849,
      0.04368824139237404,
      -0.016680024564266205,
      -0.006290803197771311,
      -0.036354243755340576,
      0.024063987657427788,
      0.07186330854892731,
      0.05317423492670059,
      0.0022388091310858727,
      0.09469489008188248,
      -0.005934440065175295,
      0.006113593932241201,
      0.02913667820394039,
      0.06894352287054062,
      -0.060145143419504166,
      -0.039839111268520355,
      0.03572099655866623,
      0.011170132085680962,
      -0.15831810235977173,
      0.07445503026247025,
      0.06905585527420044,
      0.05421838164329529,
      0.08625365793704987,
      -0.01268372219055891,
      -0.08678585290908813,
      -0.04726532846689224,
      0.010714762844145298,
      -0.007753163110464811,
      -0.024544551968574524,
      0.009938498958945274,
      -0.14515869319438934,
      0.02249264530837536,
      -0.0158995408564806,
      -0.000520754256285727,
      -0.039193350821733475,
      -0.03440385311841965,
      0.03758629783987999,
      -0.023873237892985344,
      -0.05692619830369949,
      -0.07209664583206177,
      0.017306115478277206,
      0.09384895116090775,
      0.013182034716010094,
      0.013215542770922184,
      0.09461836516857147,
      -0.0011788414558395743,
      0.008605727925896645,
      0.004891511518508196,
      -0.012607431039214134,
      0.03911459073424339,
      0.01654282584786415,
      0.011834019795060158,
      -0.028243212029337883,
      0.03599323704838753,
      0.06287197768688202,
      -0.01379524078220129,
      -0.021776309236884117,
      0.03846033662557602,
      0.019121438264846802,
      -0.0021331754978746176,
      0.026494614779949188,
      -0.02017315663397312,
      0.0703120082616806,
      -0.013403735123574734,
      -0.02442985028028488,
      -0.03587498515844345,
      0.027097046375274658,
      -0.09191856533288956,
      0.1145552545785904,
      0.0441385880112648,
      -0.02298762835562229,
      0.05642499774694443,
      0.05988403782248497,
      -0.003565410617738962,
      0.0005710453260689974,
      0.08826093375682831,
      0.02209494262933731,
      0.038545966148376465,
      -0.036953188478946686,
      -0.039670079946517944,
      0.0009177335887216032,
      -0.10432139039039612,
      -0.00739096337929368,
      0.03763163462281227,
      0.0258660726249218,
      0.011112121865153313,
      0.04733572155237198,
      0.019162988290190697,
      0.03104727528989315,
      -0.01278578583151102,
      -0.04139972850680351,
      -0.0744016021490097,
      -0.020742295309901237,
      0.03675665706396103,
      0.032060641795396805,
      0.08694109320640564,
      -0.07090505957603455,
      0.030368877574801445,
      -0.019917218014597893,
      0.022358380258083344,
      -0.017451094463467598,
      -0.02414393052458763,
      0.021030396223068237,
      -0.08723665028810501,
      0.025764623656868935,
      -0.023370662704110146,
      0.011143418960273266,
      -0.035446856170892715,
      0.07549651712179184,
      -0.060532715171575546,
      0.08169974386692047,
      -0.03793802857398987,
      -0.0458921454846859,
      0.047562725841999054,
      0.0733012706041336,
      0.029157457873225212,
      -0.1158185601234436,
      -0.06716956198215485,
      -0.03662478178739548,
      0.006344655994325876,
      -0.023717395961284637,
      0.029391493648290634,
      -0.020648472011089325,
      0.011935243383049965,
      -0.019644908607006073,
      -0.005206931848078966,
      -0.005581731908023357,
      0.029331864789128304,
      -0.03917619213461876,
      0.025263475254178047,
      -0.022893598303198814,
      0.03256692737340927,
      -0.010283411480486393,
      0.05164641886949539,
      0.05963767692446709,
      0.04305952787399292,
      0.05085652694106102,
      -0.005436209961771965,
      -0.009734882973134518,
      -0.04728848487138748,
      0.00655494024977088,
      -0.00689137214794755,
      0.03498733788728714,
      0.02441444620490074,
      0.028229650110006332,
      -0.07988929003477097,
      -0.058307599276304245,
      0.016702992841601372,
      0.01934032328426838,
      0.016101183369755745,
      -0.08285543322563171,
      -0.04018883407115936,
      0.04080744832754135,
      0.048539258539676666,
      0.03777296468615532,
      -0.03297445550560951,
      -0.06797043979167938,
      -0.06554953008890152,
      0.010780228301882744,
      0.02156125195324421,
      0.009140552021563053,
      -0.019807422533631325,
      -0.061804480850696564,
      -0.013881598599255085,
      -0.061299074441194534,
      0.03948875516653061,
      -0.0324212871491909,
      0.015031307004392147,
      0.016625864431262016,
      0.021784324198961258,
      -0.021816808730363846,
      0.04897705838084221,
      -0.030407920479774475,
      -0.06291791051626205,
      0.01417356263846159,
      0.023144667968153954,
      0.03932499885559082,
      -0.043827805668115616,
      -0.0056061772629618645,
      -0.012403160333633423,
      -0.009426059201359749,
      -0.0011808155104517937,
      0.010465757921338081,
      0.015030132606625557,
      0.0850205346941948,
      -0.007082480005919933,
      0.009853370487689972,
      0.08285743743181229,
      0.08344563096761703,
      -0.026634080335497856,
      -0.020045625045895576,
      0.07504144310951233,
      -0.05104302987456322,
      -0.013271459378302097,
      0.13904240727424622,
      0.018976498395204544,
      0.04490286856889725,
      0.00991140678524971,
      -0.09035637229681015,
      -0.04692336171865463,
      0.04890621453523636,
      -0.037006061524152756,
      -0.044893015176057816,
      -0.033161599189043045,
      0.05060960724949837,
      -0.1025170162320137,
      -0.11485899239778519,
      -0.08316713571548462,
      0.05937451869249344,
      0.01051460113376379,
      -0.016428129747509956,
      -0.02393031306564808,
      0.0036091397050768137,
      0.06840191781520844,
      0.04825462028384209,
      -0.03513183817267418,
      0.04929029941558838,
      -0.03293677419424057,
      0.11006183922290802,
      0.06518973410129547,
      -0.04394574463367462,
      -0.06028347834944725,
      -0.0028665221761912107,
      -0.015480166301131248,
      -0.010958921164274216,
      -0.13041536509990692,
      -0.007805304601788521,
      -0.04012259468436241,
      0.018608691170811653,
      -0.05814899876713753,
      0.04508987441658974,
      0.07577093690633774,
      -0.06977348029613495,
      0.03428772836923599,
      0.03206384554505348,
      0.009055029600858688,
      -0.019843030720949173,
      0.035124532878398895,
      -0.05351027846336365,
      -0.04473192244768143,
      -0.12177322059869766,
      -0.06961735337972641,
      0.03401896357536316,
      -0.051273468881845474,
      0.022689592093229294,
      -0.021210094913840294,
      -0.04471783712506294,
      -0.029112040996551514,
      0.06805022805929184,
      -0.042426589876413345,
      -0.04892512410879135,
      -0.09399408102035522,
      0.0059421490877866745,
      -0.00829367246478796,
      0.06398586928844452,
      0.02930215559899807,
      -0.015597234480082989,
      0.07231618463993073,
      0.006155453622341156,
      -0.05280567705631256,
      -0.05141763016581535,
      0.020435040816664696,
      0.06907159835100174,
      -0.002654665382578969,
      0.05135652795433998,
      -0.013238382525742054,
      -0.011425307020545006,
      -0.04598204419016838,
      0.01305166445672512,
      -0.04799948260188103,
      -0.023498650640249252,
      -0.013042213395237923,
      -0.001201644423417747,
      -0.003478078171610832,
      0.04972568899393082,
      0.11770360171794891,
      0.020277293398976326,
      -0.024662230163812637,
      0.009245235472917557,
      0.0034639958757907152,
      -0.022339172661304474,
      0.016570275649428368,
      0.07796595245599747,
      -0.05507584288716316,
      -0.057018205523490906,
      -0.007551924791187048,
      0.01863352581858635,
      0.022701052948832512
    ],
    [
      0.016806932166218758,
      0.04425615444779396,
      0.05882510170340538,
      -0.018047040328383446,
      -0.0533171221613884,
      0.05964997783303261,
      -0.020225685089826584,
      0.05624530091881752,
      -0.04198313504457474,
      0.01529699843376875,
      -0.06201375275850296,
      0.025021854788064957,
      -0.025249402970075607,
      0.013529797084629536,
      -0.007944384589791298,
      -0.06563480943441391,
      0.06158755347132683,
      -0.023495236411690712,
      -0.0006345411529764533,
      -0.00029751306283287704,
      0.07821297645568848,
      0.01870512217283249,
      -0.028553923591971397,
      0.021714409813284874,
      0.028937462717294693,
      0.024089552462100983,
      0.0438721664249897,
      0.06718228757381439,
      -0.050281282514333725,
      -0.0235438235104084,
      0.001249811495654285,
      0.04682242125272751,
      0.019970789551734924,
      0.08848877996206284,
      -0.049719590693712234,
      0.0540446899831295,
      0.002148016821593046,
      0.024771936237812042,
      -0.08246580511331558,
      -0.08882231265306473,
      -0.01232810877263546,
      -0.0708538219332695,
      -0.008810373023152351,
      -0.01973617635667324,
      0.04369312524795532,
      -0.05932227894663811,
      -0.002996225608512759,
      -0.0021645096130669117,
      0.01601577363908291,
      -0.07511670142412186,
      0.10168597102165222,
      -0.00014659286534879357,
      -0.03823355212807655,
      0.0332956537604332,
      -0.021406162530183792,
      -0.04460775479674339,
      -0.05391920357942581,
      0.05285954847931862,
      -0.026272181421518326,
      -0.05009477213025093,
      -0.05711614340543747,
      -0.016818435862660408,
      -0.03520018979907036,
      -0.02596481889486313,
      0.09147123992443085,
      0.03138755261898041,
      0.04005839303135872,
      -0.03357629477977753,
      -0.047911301255226135,
      -0.08374578505754471,
      -0.0524996817111969,
      0.05857335776090622,
      -0.028748275712132454,
      -0.048216596245765686,
      0.04580981656908989,
      -0.08278407901525497,
      0.0808790773153305,
      -0.0010279566049575806,
      -0.03470027446746826,
      -0.0519285723567009,
      0.03535115346312523,
      -0.03677528351545334,
      0.014815397560596466,
      -0.06040090695023537,
      0.05297178030014038,
      0.00975560862571001,
      -0.07307261228561401,
      -0.04991631954908371,
      -0.03818255662918091,
      -0.07206487655639648,
      -0.016093598678708076,
      0.10126756876707077,
      -0.08473514765501022,
      0.02012944221496582,
      -0.05300810560584068,
      0.01909124292433262,
      -0.02211291529238224,
      0.02550366148352623,
      0.017682969570159912,
      0.06487387418746948,
      0.004911800380796194,
      0.0443299226462841,
      -0.0028915705624967813,
      -0.05358543619513512,
      -0.07668369263410568,
      -0.06483355909585953,
      -0.036342065781354904,
      -0.03335793688893318,
      0.006173263769596815,
      0.02750718593597412,
      -0.04948149994015694,
      -0.03214030712842941,
      -0.006347088608890772,
      -0.014309221878647804,
      0.02717001736164093,
      0.08010649681091309,
      -0.026576831936836243,
      -0.0312094334512949,
      -0.05862341448664665,
      -0.0046212985180318356,
      0.011050614528357983,
      0.028263412415981293,
      0.07107149809598923,
      -0.007868929766118526,
      -0.033703673630952835,
      -0.04836655408143997,
      0.08986836671829224,
      0.00354053289629519,
      0.0050661079585552216,
      0.011300252750515938,
      0.05669580027461052,
      -0.02054568938910961,
      -0.015771713107824326,
      -0.010177094489336014,
      0.016317544505000114,
      0.017792919650673866,
      -0.04457816854119301,
      0.0004423310747370124,
      -0.018491294234991074,
      0.0652439147233963,
      0.0247100330889225,
      0.03357439860701561,
      0.012932833284139633,
      0.038815684616565704,
      -0.04797615855932236,
      -0.08697386831045151,
      0.016516825184226036,
      -0.05554532632231712,
      -0.00025725728482939303,
      0.06493270397186279,
      0.05938205495476723,
      -0.03570012003183365,
      -0.009014289826154709,
      -0.04976832494139671,
      0.009869272820651531,
      -0.05374716594815254,
      -0.03978556767106056,
      0.01363573968410492,
      -0.023816026747226715,
      0.08756280690431595,
      0.0008094961522147059,
      0.03522498160600662,
      0.03808870539069176,
      0.02689293771982193,
      0.022886626422405243,
      0.008926288224756718,
      -0.021352503448724747,
      0.07587087899446487,
      0.009393109008669853,
      -0.07336337119340897,
      -0.0013849585084244609,
      -0.03001629374921322,
      0.03859349340200424,
      -0.0488460436463356,
      0.012911253608763218,
      -0.02208516374230385,
      -0.023771770298480988,
      0.012582745403051376,
      0.004081496503204107,
      -0.0026646112091839314,
      -0.004260177258402109,
      0.0940156951546669,
      0.003693357342854142,
      0.017606405541300774,
      -0.012294425629079342,
      0.04006800428032875,
      0.04827332869172096,
      0.025414790958166122,
      0.036047909408807755,
      -0.05654573068022728,
      0.01847667433321476,
      -0.03537677228450775,
      0.010595520958304405,
      0.012388501316308975,
      -0.032668739557266235,
      0.018554873764514923,
      -0.07404053956270218,
      0.02568197064101696,
      -0.012021256610751152,
      -0.04271231219172478,
      0.00896273273974657,
      -0.04560140147805214,
      0.02426055632531643,
      0.039976511150598526,
      -0.0034730182960629463,
      -0.04918939992785454,
      -0.03362779691815376,
      -0.07669901102781296,
      -0.026123134419322014,
      0.0010320525616407394,
      -0.052148595452308655,
      0.0073752929456532,
      0.08739033341407776,
      0.041293807327747345,
      0.07605119794607162,
      -0.031356487423181534,
      -0.012860869988799095,
      0.013660076074302197,
      0.016582505777478218,
      0.00012198178592370823,
      0.08626000583171844,
      -0.07259275764226913,
      -0.07371430844068527,
      -0.04633377119898796,
      -0.01324133388698101,
      0.004985226783901453,
      0.08713462948799133,
      0.010119599290192127,
      -0.06763651221990585,
      -0.007767819799482822,
      -0.040535394102334976,
      -0.009767343290150166,
      -0.0043996344320476055,
      -0.05537407845258713,
      0.021728651598095894,
      0.016581209376454353,
      -0.03348669037222862,
      0.06128060072660446,
      -0.01262668613344431,
      0.02472003363072872,
      0.0883423462510109,
      -0.04697374626994133,
      0.02986982837319374,
      0.028475569561123848,
      -0.02614273875951767,
      0.08276188373565674,
      0.061477918177843094,
      -0.055314309895038605,
      -0.054892636835575104,
      -0.03519390523433685,
      -0.022858332842588425,
      0.0190993994474411,
      -0.009084194898605347,
      0.05772111192345619,
      0.0456203930079937,
      0.024097450077533722,
      0.03179905191063881,
      0.06556663662195206,
      -0.03018706478178501,
      -0.032971348613500595,
      0.01889832690358162,
      0.0012938639847561717,
      0.03218512609601021,
      -0.0070503768511116505,
      0.01964002288877964,
      0.01311406958848238,
      -0.07631267607212067,
      -0.06537149101495743,
      -0.001151468139141798,
      -0.015625564381480217,
      -0.021776994690299034,
      0.04116787388920784,
      -0.09428062289953232,
      -0.06554841995239258,
      0.05123303830623627,
      -0.006087352521717548,
      -0.019139360636472702,
      -0.05108882859349251,
      0.02327149733901024,
      0.016672363504767418,
      0.033688634634017944,
      0.014420249499380589,
      -0.059542350471019745,
      0.014794714748859406,
      -0.021312188357114792,
      0.019007472321391106,
      0.04780787229537964,
      -0.0476449690759182,
      -0.0014831612352281809,
      -0.055090244859457016,
      0.027324989438056946,
      0.04492398351430893,
      -0.02863604761660099,
      0.01675768382847309,
      -0.03945666551589966,
      0.06770004332065582,
      0.049860481172800064,
      -0.02456549182534218,
      0.04382781684398651,
      0.06264214217662811,
      -0.06758333742618561,
      0.07579924911260605,
      -0.012207015417516232,
      -0.04025088995695114,
      0.010569633916020393,
      0.006701644975692034,
      0.0028606364503502846,
      0.00020963908173143864,
      0.02748638391494751,
      0.09002219885587692,
      0.005038315895944834,
      -0.047516174614429474,
      -0.06187276914715767,
      -0.05834324285387993,
      0.016215171664953232,
      -0.062050960958004,
      0.05358843505382538,
      0.005573704838752747,
      -0.04431293159723282,
      0.007502585183829069,
      -0.035380832850933075,
      0.017329012975096703,
      -0.009733353741466999,
      0.04593190923333168,
      -0.004166784696280956,
      -0.004936507437378168,
      0.0176061000674963,
      0.013158182613551617,
      0.0693303793668747,
      -0.006241016089916229,
      0.021285412833094597,
      -0.023246081545948982,
      -0.014864047057926655,
      0.004124006722122431,
      -0.03479500859975815,
      -0.006262843031436205,
      0.09854242205619812,
      -0.02079894207417965,
      0.050155509263277054,
      -0.10171126574277878,
      0.0716315433382988,
      -0.011710498481988907,
      0.03046562895178795,
      -0.012988559901714325,
      -0.0203741192817688,
      0.01109650731086731,
      0.10127219557762146,
      -0.03797924146056175,
      0.006744033191353083,
      -0.08935406059026718,
      0.04525946453213692,
      0.06736252456903458,
      0.04826939105987549,
      -0.05303293094038963,
      -0.0002550446952227503,
      0.0354047529399395,
      -0.03450426459312439,
      0.020279977470636368,
      -0.03850610554218292,
      -0.021429317072033882,
      0.004264874383807182,
      -0.02163824997842312,
      0.02404485084116459,
      -0.0518302358686924,
      0.04351844638586044,
      0.05775076523423195,
      -0.09951252490282059,
      -0.0250643752515316,
      -0.013006765395402908,
      0.03762274980545044,
      0.0584552064538002,
      0.0125559251755476,
      0.11746056377887726,
      -0.03837262839078903,
      -0.02438351884484291,
      0.01964022032916546,
      0.027171488851308823,
      -0.00860194955021143,
      0.04475182667374611,
      0.0013042845530435443,
      -0.020191319286823273,
      -0.04166703671216965,
      -0.014805629849433899,
      0.016388507559895515,
      0.03213679417967796,
      0.046053171157836914,
      0.03817969933152199,
      0.04656922072172165,
      -0.0531083419919014,
      0.07868603616952896,
      -0.03738528862595558,
      0.03870236873626709,
      0.017902880907058716,
      -0.051798298954963684,
      -0.05208907648921013,
      -0.03180975466966629,
      0.022011345252394676,
      0.033085379749536514,
      0.058015573769807816,
      -0.021508021280169487,
      0.011121905408799648,
      0.029425853863358498,
      -0.05104728415608406,
      -0.024400953203439713,
      -0.010694588534533978,
      -0.040366031229496,
      -0.0385073721408844,
      0.04371322691440582,
      0.1045076921582222,
      0.016800478100776672,
      -0.0008747343090362847,
      -0.009266522713005543,
      0.04826158657670021,
      -0.09820771962404251,
      0.037143267691135406,
      0.018321014940738678,
      -0.04664158076047897,
      0.07840714603662491,
      -0.017973655834794044,
      0.03314489871263504,
      -0.035358913242816925,
      -0.012905413284897804,
      -0.08573267608880997,
      0.04412143677473068,
      0.02836100198328495,
      0.04631267488002777,
      -0.03883574903011322,
      -0.028569594025611877,
      0.012415303848683834,
      -0.008428867906332016,
      -0.004276134539395571,
      -0.05528552457690239,
      -0.12241147458553314,
      -0.01631021499633789,
      0.0684785470366478,
      -0.021113255992531776,
      0.03478085622191429,
      -0.04190812632441521,
      -0.06387701630592346,
      -0.042678102850914,
      0.02117287740111351,
      -0.004066790919750929,
      0.04441853240132332,
      0.0814870223402977,
      -0.03502338007092476,
      0.06372027844190598,
      0.014608806930482388,
      -0.018973222002387047,
      0.0014801383949816227,
      -0.03794177621603012,
      -0.009866096079349518,
      0.06366847455501556,
      0.00855020061135292,
      0.008779957890510559,
      -0.08170966804027557,
      -0.0116988280788064,
      0.0024993447586894035,
      0.012686708942055702,
      -0.0378260612487793,
      0.08821087330579758,
      0.06372244656085968,
      -0.0035746069625020027,
      0.042706385254859924,
      0.03956041485071182,
      -0.06196831539273262,
      -0.048471663147211075,
      -0.05365584045648575,
      -0.03921329975128174,
      -0.05260233208537102,
      -0.009598911739885807,
      0.0962214469909668,
      0.07892966270446777,
      -0.035393379628658295,
      -0.004262365400791168,
      -0.022537827491760254,
      0.07710054516792297,
      -0.13329194486141205,
      0.0004257130203768611,
      -0.0066936626099050045,
      -0.014608749188482761,
      0.0018190370174124837,
      0.022606058046221733,
      -0.032054588198661804,
      0.013364146463572979,
      -0.0362028069794178,
      0.04531759023666382,
      -0.04276035353541374,
      0.03095475770533085,
      0.03477112948894501,
      -0.021792439743876457,
      -0.028951657935976982,
      0.0006518925074487925,
      -0.00400938093662262,
      0.012007678858935833,
      -0.017001790925860405,
      0.0007872694404795766,
      0.06478004157543182,
      0.040267590433359146,
      0.04673603177070618,
      -0.05568547174334526,
      0.022867277264595032,
      -0.10775239765644073,
      -0.00881929136812687
    ],
    [
      0.00020862824749201536,
      -0.05432368814945221,
      0.005478360690176487,
      -0.052024222910404205,
      0.0010013401042670012,
      0.017121871933341026,
      0.01474364846944809,
      -0.031809598207473755,
      -0.028005218133330345,
      0.007336622569710016,
      -0.012134529650211334,
      0.07123343646526337,
      -0.04749314486980438,
      -0.057090047746896744,
      -0.020758280530571938,
      0.04525507614016533,
      0.04243704304099083,
      0.01801390014588833,
      0.040183842182159424,
      0.06909394264221191,
      0.03649793937802315,
      -0.014702819287776947,
      0.06958319246768951,
      0.051382604986429214,
      -0.04765675961971283,
      -0.03667932003736496,
      -0.008848193101584911,
      0.11100015044212341,
      0.03259943425655365,
      0.08056893199682236,
      0.025498785078525543,
      -0.003172720782458782,
      0.029990840703248978,
      -0.022899366915225983,
      -0.03643372654914856,
      -0.011306223459541798,
      -0.02982676587998867,
      -0.005671015474945307,
      0.01067289523780346,
      -0.054797910153865814,
      0.02738657034933567,
      -0.011088643223047256,
      -0.025044459849596024,
      0.026278220117092133,
      0.027886446565389633,
      -0.08954441547393799,
      0.020244261249899864,
      -0.04196610301733017,
      -0.03948438912630081,
      0.005931515712291002,
      0.0366617813706398,
      -0.01972683146595955,
      -0.0005653747357428074,
      0.1033649891614914,
      -0.006570426281541586,
      -0.012274855747818947,
      0.012109379284083843,
      -0.05019023269414902,
      -0.062288012355566025,
      0.03092539682984352,
      -0.05847300589084625,
      0.023878812789916992,
      0.062382470816373825,
      0.030109599232673645,
      0.08767354488372803,
      -0.031639084219932556,
      0.01966061070561409,
      0.0809578001499176,
      -0.017341451719403267,
      0.01621364615857601,
      -0.023659972473978996,
      -0.07298129051923752,
      -0.012184997089207172,
      0.10009367763996124,
      -0.09497732669115067,
      -0.06518393009901047,
      0.04315364360809326,
      -0.036857910454273224,
      -0.059453949332237244,
      0.025413280352950096,
      0.04372921213507652,
      -0.058339525014162064,
      0.027826620265841484,
      -0.015500661917030811,
      0.07218340039253235,
      0.06186513602733612,
      0.014197688549757004,
      -0.02900504507124424,
      -0.04552266001701355,
      0.017894906923174858,
      0.036459606140851974,
      0.04465100169181824,
      0.0036927296314388514,
      -0.021981479600071907,
      -0.05666020140051842,
      0.015337564051151276,
      0.03791940584778786,
      0.04972623288631439,
      -0.041608888655900955,
      0.06756238639354706,
      0.006751591805368662,
      -0.08979938179254532,
      0.04732435196638107,
      -0.04875847324728966,
      -0.0049645123071968555,
      -0.0013038980541750789,
      0.018062889575958252,
      -0.04348315671086311,
      -0.04210146889090538,
      -0.04636174812912941,
      -0.09047868102788925,
      -0.06887757033109665,
      0.05984226241707802,
      -0.007203875575214624,
      -0.022441495209932327,
      0.008633221499621868,
      -0.023085879161953926,
      -0.006906808819621801,
      0.02717028744518757,
      -0.009560039266943932,
      -0.0215953066945076,
      0.001441311091184616,
      -0.020108522847294807,
      0.0145242465659976,
      -0.04770207777619362,
      0.06559884548187256,
      0.09291170537471771,
      -0.04954870790243149,
      -0.06718770414590836,
      -0.006574440747499466,
      0.03897424414753914,
      0.00011830717994598672,
      0.011946277692914009,
      0.02313828468322754,
      -0.018614910542964935,
      0.0608447790145874,
      -0.04474448785185814,
      -0.04309792071580887,
      0.018084252253174782,
      -0.005882934667170048,
      -0.030201293528079987,
      -0.03904319927096367,
      -0.0330161489546299,
      0.011678497307002544,
      0.037995561957359314,
      -0.007800727151334286,
      0.07041982561349869,
      -0.018666476011276245,
      0.036930616945028305,
      0.0786542221903801,
      0.0034907066728919744,
      -0.0009748876327648759,
      -0.06169746443629265,
      0.0015707010170444846,
      0.03389107808470726,
      0.07695816457271576,
      0.012996501289308071,
      -0.045746367424726486,
      -0.023304207250475883,
      -0.09141761809587479,
      0.019133931025862694,
      -0.05787314474582672,
      0.05546605959534645,
      -0.02085307240486145,
      0.0010619645472615957,
      -0.008150024339556694,
      -0.02147519588470459,
      0.002117232419550419,
      -0.03661179915070534,
      -0.05066355690360069,
      0.09887678176164627,
      -0.03745369240641594,
      -0.08180329948663712,
      -0.036613255739212036,
      -0.0052949911914765835,
      -0.03249422833323479,
      -0.024352652952075005,
      0.04755980148911476,
      0.0332743301987648,
      -0.03421623632311821,
      0.0932590663433075,
      -0.010675350204110146,
      0.060190942138433456,
      0.06432563066482544,
      0.03598935529589653,
      -0.03661533445119858,
      -0.04039745032787323,
      -0.07930992543697357,
      -0.09790230542421341,
      0.04166393727064133,
      0.0032365969382226467,
      0.04942943900823593,
      0.040315788239240646,
      -0.0027055097743868828,
      -0.03454233333468437,
      0.031770531088113785,
      -0.011634302325546741,
      0.07825116813182831,
      -0.07999366521835327,
      -0.004500514827668667,
      0.07767420262098312,
      -0.031212033703923225,
      0.10180846601724625,
      -0.007829799316823483,
      -0.029735488817095757,
      0.026129664853215218,
      0.010752740316092968,
      -0.015614712610840797,
      -0.05430351942777634,
      0.002261448185890913,
      -0.001109910081140697,
      0.03925983980298042,
      0.04678718000650406,
      0.09144777804613113,
      0.015340923331677914,
      -0.006378267426043749,
      0.0389898307621479,
      -0.020917419344186783,
      -0.03956145420670509,
      -0.05088776350021362,
      0.0010385180357843637,
      0.011040621437132359,
      0.0680643618106842,
      0.02628045342862606,
      -0.008895847015082836,
      0.00125178974121809,
      0.13617728650569916,
      0.013074029237031937,
      -0.01765255257487297,
      -0.007733686361461878,
      -0.1282738596200943,
      -0.011640315875411034,
      -0.003657433670014143,
      -0.09279036521911621,
      0.05402907356619835,
      -0.019161971285939217,
      -0.004544520750641823,
      0.03701084852218628,
      -0.05932000279426575,
      -0.015943313017487526,
      0.025824900716543198,
      0.010671129450201988,
      0.039336029440164566,
      0.01524969469755888,
      -0.037339385598897934,
      -0.01291688159108162,
      0.016786416992545128,
      0.03694286569952965,
      -0.01470990851521492,
      0.0073368120938539505,
      0.08807196468114853,
      -0.08298281580209732,
      0.05033862218260765,
      -0.000542963738553226,
      0.028921155259013176,
      -0.05295570567250252,
      0.01057623140513897,
      0.02144164778292179,
      0.054850704967975616,
      0.035383325070142746,
      -0.04062816873192787,
      0.04954404756426811,
      -0.013807512819766998,
      0.01910589635372162,
      0.06068824604153633,
      0.11431045830249786,
      -0.04593983292579651,
      0.053943272680044174,
      -0.031936634331941605,
      0.08026416599750519,
      -0.14173445105552673,
      -0.00790310837328434,
      0.048937082290649414,
      0.014655391685664654,
      0.01600220985710621,
      0.004807084798812866,
      -0.08746972680091858,
      0.025165703147649765,
      0.08418579399585724,
      -0.00915569718927145,
      0.048195239156484604,
      -0.024416759610176086,
      0.003076018765568733,
      -0.023296846076846123,
      -0.01957673579454422,
      -0.014536270871758461,
      0.0749424397945404,
      0.0029065131675451994,
      -0.024282017722725868,
      -0.06880194693803787,
      -0.04150618985295296,
      0.006944121327251196,
      0.050153519958257675,
      -0.08980286121368408,
      0.007625971455127001,
      -0.014179437421262264,
      -0.020263977348804474,
      0.012236173264682293,
      0.028958570212125778,
      -0.002526903757825494,
      -0.03363270312547684,
      -0.0010218709940090775,
      0.026263389736413956,
      0.02682485617697239,
      0.019841250032186508,
      -0.007884158752858639,
      0.013205417431890965,
      -0.02668721228837967,
      0.04904380440711975,
      0.0131358178332448,
      -0.023337559774518013,
      -0.03517262265086174,
      0.012816496193408966,
      0.09726864844560623,
      -0.0449201725423336,
      -0.003276111790910363,
      0.02371056377887726,
      -0.016669057309627533,
      -0.10674457997083664,
      0.050376616418361664,
      0.012330866418778896,
      -0.0622263140976429,
      -0.006401535123586655,
      0.05083280801773071,
      0.012285186909139156,
      -0.02643861249089241,
      0.032439470291137695,
      -0.011166706681251526,
      0.03264952078461647,
      -0.01653989776968956,
      0.020578021183609962,
      -0.011676128953695297,
      0.014246253296732903,
      -0.015676366165280342,
      0.004124263767153025,
      -0.010960745625197887,
      0.05023324117064476,
      0.02859935536980629,
      0.038539838045835495,
      -0.05495718494057655,
      -0.06397378444671631,
      0.07809330523014069,
      0.037771690636873245,
      0.0034541618078947067,
      0.002454381203278899,
      -0.011152723804116249,
      0.08009976893663406,
      -0.008617624640464783,
      -0.014391629956662655,
      -0.04787173122167587,
      -0.017968015745282173,
      -0.01340411975979805,
      0.044123709201812744,
      0.06150831654667854,
      -0.04588795080780983,
      0.018601246178150177,
      0.018784966319799423,
      -0.012276125140488148,
      0.00743072060868144,
      0.01706579513847828,
      0.04320263862609863,
      0.011760985478758812,
      -0.0067660133354365826,
      0.038730498403310776,
      0.12846429646015167,
      -0.002503631403669715,
      -0.06974411010742188,
      0.00017812203441280872,
      0.0033639464527368546,
      -0.05941738560795784,
      0.01113149058073759,
      -0.018744980916380882,
      0.005613164510577917,
      -0.0924183651804924,
      0.04087356850504875,
      0.03709020838141441,
      -0.06915603578090668,
      -0.08333517611026764,
      -0.03383264318108559,
      0.0017341363709419966,
      0.03999379649758339,
      -0.020448092371225357,
      0.01701882667839527,
      0.026281336322426796,
      -0.06957806646823883,
      0.10840313136577606,
      0.03376537188887596,
      0.08856312185525894,
      -0.06699518859386444,
      -0.018994400277733803,
      0.015914205461740494,
      -0.041376300156116486,
      -0.020643681287765503,
      -0.028447510674595833,
      -0.10815843194723129,
      -0.028192635625600815,
      0.09333106130361557,
      -0.09198671579360962,
      0.030014626681804657,
      0.02067752368748188,
      -0.004932754673063755,
      -0.009587012231349945,
      -0.017469557002186775,
      -0.10777585208415985,
      -0.03942335397005081,
      0.016615916043519974,
      -0.0008101389394141734,
      -0.09035032242536545,
      -0.02407976984977722,
      -0.014013252221047878,
      0.07012167572975159,
      0.052799466997385025,
      -0.03552565723657608,
      -0.028554076328873634,
      -0.02069943957030773,
      9.996953303925693e-05,
      0.10668998211622238,
      -0.007929788902401924,
      -0.04416656494140625,
      -0.040978215634822845,
      -0.011956028640270233,
      0.028310570865869522,
      0.03793054074048996,
      0.03418406471610069,
      -0.026998791843652725,
      0.05789008364081383,
      -0.05033166706562042,
      -0.07373892515897751,
      0.000679763441439718,
      0.051473066210746765,
      -0.059836599975824356,
      -0.047764603048563004,
      0.0424342006444931,
      0.020893553271889687,
      -0.01220051757991314,
      0.021921493113040924,
      0.0008383075473830104,
      0.039502475410699844,
      0.017356108874082565,
      0.08091612905263901,
      -0.020144231617450714,
      0.012005781754851341,
      0.02450135350227356,
      -0.03226359188556671,
      -0.04057035222649574,
      -0.03381193056702614,
      0.02214002050459385,
      0.0202835313975811,
      0.0794098898768425,
      -0.0057088532485067844,
      0.003404707880690694,
      -0.041992638260126114,
      0.030543122440576553,
      -0.008323384448885918,
      0.03838655725121498,
      0.0005099328118376434,
      0.009934143163263798,
      -0.013496236875653267,
      -0.0233790073543787,
      0.02001705765724182,
      -0.03314386308193207,
      -0.08318530768156052,
      0.09516838192939758,
      0.005540524143725634,
      -0.052200447767972946,
      -0.00805518589913845,
      -0.010444069281220436,
      0.07615622133016586,
      -0.010904771275818348,
      -0.03889580816030502,
      0.10861743986606598,
      0.057443272322416306,
      0.035325873643159866,
      -0.04702673479914665,
      0.1443328857421875,
      0.04146694391965866,
      0.07851817458868027,
      -0.05717923492193222,
      -0.02197670191526413,
      -0.014881943352520466,
      0.0751633420586586,
      -0.018331963568925858,
      -0.021803028881549835,
      0.09447125345468521,
      0.045353904366493225,
      -0.031114285811781883,
      -0.053056348115205765,
      0.051704633980989456,
      -0.006146775558590889,
      0.07344262301921844,
      -0.05046694725751877,
      0.018966522067785263,
      0.01889920048415661,
      -0.02900554984807968,
      -0.051551345735788345,
      -0.011748132295906544,
      -0.011360669508576393,
      -0.06094086170196533,
      -0.022239618003368378,
      0.004530217032879591,
      0.05441230535507202,
      -0.025859205052256584,
      -0.011279514990746975
    ],
    [
      0.022036144509911537,
      0.0025719054974615574,
      0.04790173098444939,
      -0.05562669411301613,
      -0.045450977981090546,
      0.025897888466715813,
      -0.00854951236397028,
      -0.0368349514901638,
      0.00929707009345293,
      0.021010464057326317,
      -0.0162466112524271,
      0.04008220136165619,
      0.0299749206751585,
      0.0828678086400032,
      0.003928087186068296,
      0.008427505381405354,
      0.03324388712644577,
      -0.02249855175614357,
      0.0036830261815339327,
      0.05911967158317566,
      -0.022608717903494835,
      0.11261854320764542,
      0.076432965695858,
      -0.049254436045885086,
      -0.08178549259901047,
      0.03971806913614273,
      -0.03250441700220108,
      0.05976887047290802,
      -0.0788247138261795,
      0.017430229112505913,
      0.06884749978780746,
      -0.026570260524749756,
      0.05075955018401146,
      -0.013345102779567242,
      0.04585408791899681,
      -0.07048553228378296,
      0.032556917518377304,
      -0.010420793667435646,
      0.05830246955156326,
      -0.019165189936757088,
      -0.0805961862206459,
      -0.060987260192632675,
      -0.06557729095220566,
      -0.03374950587749481,
      -0.02271874248981476,
      -0.013390614651143551,
      0.050236549228429794,
      -0.0045852214097976685,
      -0.08513494580984116,
      -0.0228845477104187,
      0.053745612502098083,
      -0.02400846593081951,
      -0.01281361747533083,
      -0.022714074701070786,
      0.04179065674543381,
      -0.0005828785942867398,
      0.020639067515730858,
      -0.026302991434931755,
      0.028712637722492218,
      -0.019638031721115112,
      0.014244780875742435,
      0.050642333924770355,
      -0.003268504748120904,
      -0.0025346949696540833,
      0.013860979117453098,
      -0.012334082275629044,
      -0.06272248178720474,
      0.006627381779253483,
      -0.020287111401557922,
      0.01737119071185589,
      -0.054298803210258484,
      -0.0197845958173275,
      0.05597769469022751,
      0.021327288821339607,
      -0.029196441173553467,
      0.012137661688029766,
      -0.059837523847818375,
      0.01158617902547121,
      -0.11007853597402573,
      -0.059180352836847305,
      0.0552053265273571,
      0.03250588849186897,
      -0.041984859853982925,
      -0.02292056754231453,
      0.03186138719320297,
      -0.04530053958296776,
      -0.03382303938269615,
      -0.09207373112440109,
      -0.029559947550296783,
      0.030066804960370064,
      0.041506532579660416,
      -0.06778377294540405,
      -0.005000198259949684,
      -0.03647148609161377,
      0.012055274099111557,
      -0.003950848244130611,
      -0.030088352039456367,
      0.08248995989561081,
      -0.04654427617788315,
      0.04978262260556221,
      -0.0046992735005915165,
      -0.10846424847841263,
      0.00536244036629796,
      -0.04656612128019333,
      -0.06517433375120163,
      0.005479998886585236,
      -0.06226969137787819,
      -0.013129310682415962,
      -0.012427975423634052,
      0.008881540037691593,
      -0.056587621569633484,
      -0.030360378324985504,
      -0.003918148577213287,
      0.01886359229683876,
      0.051443763077259064,
      -0.02043604850769043,
      -0.03850049152970314,
      0.019268490374088287,
      -0.00942190457135439,
      -0.018172146752476692,
      -0.0344025157392025,
      -0.08604685962200165,
      -0.032570768147706985,
      0.07323047518730164,
      0.018578629940748215,
      0.10709715634584427,
      -0.05905713140964508,
      -0.028142087161540985,
      0.043097514659166336,
      -0.02557334490120411,
      0.0400129072368145,
      0.025689776986837387,
      0.030825519934296608,
      -0.09228304773569107,
      0.034256380051374435,
      0.035421572625637054,
      0.02934940718114376,
      -0.03258375823497772,
      0.002128051593899727,
      0.027124790474772453,
      -0.08307531476020813,
      0.03045203723013401,
      0.06367086619138718,
      -0.026773715391755104,
      0.08011304587125778,
      -0.07194837927818298,
      -0.03332965821027756,
      0.05239417031407356,
      0.009310388006269932,
      0.019499901682138443,
      0.025700530037283897,
      -0.014107820577919483,
      0.04006581008434296,
      0.011728496290743351,
      0.014149130322039127,
      -0.03620060533285141,
      -0.062106020748615265,
      0.03606581687927246,
      0.07051002234220505,
      -0.013784841634333134,
      -0.0273145642131567,
      0.03569730743765831,
      0.03287787735462189,
      -0.02342742681503296,
      0.018917014822363853,
      -0.010300938971340656,
      -0.01251269318163395,
      -0.04436752572655678,
      0.07058602571487427,
      -0.01743459887802601,
      0.052016131579875946,
      0.005927079822868109,
      0.05355898290872574,
      -0.059911008924245834,
      -0.04056983068585396,
      -0.012676985003054142,
      0.06450195610523224,
      0.02471359632909298,
      0.048740070313215256,
      -0.02743460051715374,
      0.023730743676424026,
      -0.020091358572244644,
      -0.026495670899748802,
      0.015289225615561008,
      -0.008729333989322186,
      0.08604750037193298,
      0.09024091809988022,
      0.03253176435828209,
      -0.02357378602027893,
      -0.00813144538551569,
      -0.02275550551712513,
      -0.06510864943265915,
      -0.013501539826393127,
      -0.013684093952178955,
      0.09046019613742828,
      -0.0049153841100633144,
      0.006274242419749498,
      -0.0011571630602702498,
      0.025029581040143967,
      0.00525988545268774,
      0.0455344133079052,
      0.001463724416680634,
      -0.02268226630985737,
      -0.025182466953992844,
      0.02005630172789097,
      -0.06483612954616547,
      0.06564030796289444,
      0.02905142679810524,
      0.06950107216835022,
      -0.005414384882897139,
      -0.0037577073089778423,
      0.0687980055809021,
      0.09365899115800858,
      0.028860464692115784,
      -0.03630935773253441,
      -0.05656348913908005,
      -0.012937812134623528,
      0.012409452348947525,
      0.07440979033708572,
      -0.0391097255051136,
      -0.0180747602134943,
      -0.05797327682375908,
      0.05775655806064606,
      -0.0012169001856818795,
      -0.013490688055753708,
      0.025772012770175934,
      0.06942033022642136,
      0.027620449662208557,
      0.04651528224349022,
      -0.0023831170983612537,
      0.018311453983187675,
      0.01830788515508175,
      -0.046744730323553085,
      -0.008590718731284142,
      0.05603059381246567,
      0.028398297727108,
      0.0046363044530153275,
      -0.008583012968301773,
      -0.00449382746592164,
      -0.023593490943312645,
      -0.0752633661031723,
      0.017142603173851967,
      -0.000847755407448858,
      0.0012599506881088018,
      -0.0536988265812397,
      0.04060603305697441,
      -0.08074641227722168,
      -0.034887898713350296,
      -0.06249381601810455,
      0.08101873099803925,
      -0.08057217299938202,
      0.07041651010513306,
      -0.018008960410952568,
      0.055053021758794785,
      -0.051667701452970505,
      0.03228219971060753,
      0.07078216969966888,
      -0.027351925149559975,
      -0.03106221929192543,
      0.015915464609861374,
      -0.05540822446346283,
      -0.011746839620172977,
      2.7782618417404592e-05,
      0.007992012426257133,
      0.00277118943631649,
      -0.013352280482649803,
      -0.06977272033691406,
      -0.01440306194126606,
      0.023193323984742165,
      0.031223691999912262,
      -0.009438888169825077,
      -0.05159471929073334,
      0.02034529484808445,
      0.03350796177983284,
      0.02793729677796364,
      0.007402811665087938,
      -0.005797078832983971,
      -0.015736768022179604,
      -0.007419099565595388,
      0.05751956254243851,
      0.0005963390576653183,
      0.02859674207866192,
      0.046981845051050186,
      0.031212467700242996,
      -0.025402653962373734,
      0.01101492065936327,
      -0.030059438198804855,
      0.04956946521997452,
      -0.09583162516355515,
      -0.016668016090989113,
      -0.01812557503581047,
      0.002769243670627475,
      -0.02388969250023365,
      0.028711726889014244,
      0.04802456498146057,
      -0.024376338347792625,
      -0.023962508887052536,
      -0.01927681639790535,
      -0.05594015121459961,
      -0.014489161781966686,
      -0.09130066633224487,
      0.038746628910303116,
      0.005462999455630779,
      0.017896117642521858,
      -0.01565656252205372,
      -0.04611891880631447,
      -0.059257712215185165,
      0.06019821763038635,
      0.05053086578845978,
      0.029242737218737602,
      -0.02863922342658043,
      -0.07069601863622665,
      0.008833850733935833,
      -0.03445503115653992,
      -0.0007810933166183531,
      0.024404004216194153,
      0.022402163594961166,
      0.027371490374207497,
      0.005873221438378096,
      -0.014520429074764252,
      0.012683335691690445,
      0.058927133679389954,
      0.03600909933447838,
      0.018486564978957176,
      -0.021802298724651337,
      0.07650400698184967,
      -0.03421425074338913,
      -0.007927117869257927,
      -0.0045931097120046616,
      0.03781772032380104,
      -0.07056570053100586,
      0.008540734648704529,
      -0.005686830263584852,
      -0.021101223304867744,
      -0.008946705609560013,
      0.007452427409589291,
      0.04918266460299492,
      0.09304264932870865,
      0.018071599304676056,
      -0.02590622752904892,
      0.034371085464954376,
      -0.02406640537083149,
      -0.037507314234972,
      -0.12291964888572693,
      -0.03197012469172478,
      -0.07228019833564758,
      0.034922219812870026,
      -0.09936562925577164,
      -0.025122614577412605,
      -0.01494094729423523,
      -0.09806032478809357,
      -0.05056856945157051,
      -0.018871620297431946,
      -0.01288610603660345,
      -0.01768013834953308,
      0.013204164803028107,
      0.037591490894556046,
      0.08284634351730347,
      0.016417553648352623,
      0.024609854444861412,
      -0.056131988763809204,
      0.025440476834774017,
      -0.011468697339296341,
      -0.10084569454193115,
      0.030738428235054016,
      -0.010826773010194302,
      0.04466332867741585,
      -0.016231615096330643,
      0.035744231194257736,
      0.03575935214757919,
      -0.032858557999134064,
      0.03406749665737152,
      0.006354073993861675,
      -0.025957513600587845,
      -0.06648167222738266,
      0.027374403551220894,
      -0.008942406624555588,
      -0.024779338389635086,
      -0.02383364923298359,
      -0.04982679337263107,
      -0.028445564210414886,
      -0.045852404087781906,
      -0.04236266762018204,
      0.006656701676547527,
      0.02147931233048439,
      -0.0432637482881546,
      -0.03544062748551369,
      0.061529818922281265,
      -0.11200707405805588,
      -0.03252317011356354,
      -0.00837192963808775,
      0.006418970879167318,
      -0.03289620205760002,
      -0.14053574204444885,
      -0.08341152220964432,
      -0.02027907222509384,
      -0.055086649954319,
      0.007871555164456367,
      0.09060871601104736,
      -0.0011483405251055956,
      0.08347134292125702,
      0.021716633811593056,
      -0.02681470848619938,
      0.006162703037261963,
      -0.14559406042099,
      0.013699106872081757,
      0.045197997242212296,
      0.04686352610588074,
      0.030706530436873436,
      0.03247641772031784,
      0.12390641868114471,
      -0.015211801044642925,
      0.0867108553647995,
      -0.03863419592380524,
      -0.04351048171520233,
      -0.08988068997859955,
      0.017341969534754753,
      0.04041523113846779,
      -0.036099422723054886,
      -0.0004591738688759506,
      0.01599576137959957,
      0.028056956827640533,
      0.012870287522673607,
      0.04666176065802574,
      0.038501955568790436,
      0.002756700152531266,
      0.0815676748752594,
      -0.06441737711429596,
      -0.016273492947220802,
      0.004613987170159817,
      -0.05435647442936897,
      0.038649287074804306,
      -0.019222261384129524,
      -0.04706607758998871,
      0.1090964525938034,
      -0.03753020986914635,
      0.013684334233403206,
      0.018075671046972275,
      -0.03391685709357262,
      -0.014050515368580818,
      -0.03431751951575279,
      0.06575451046228409,
      0.08384064584970474,
      -0.041177019476890564,
      -0.0005984811577945948,
      -0.0177328959107399,
      0.04038897529244423,
      -0.03709959238767624,
      -0.04175266996026039,
      -0.023536400869488716,
      0.010384808294475079,
      -0.001188702997751534,
      -0.03919748216867447,
      -0.04882011190056801,
      -0.017992909997701645,
      -0.0012329228920862079,
      -0.046627216041088104,
      -0.052811238914728165,
      0.01979326456785202,
      0.018272964283823967,
      0.05027434229850769,
      0.030844852328300476,
      0.016668526455760002,
      0.021373789757490158,
      0.012718438170850277,
      -0.021677501499652863,
      -0.014589935541152954,
      -0.002098137978464365,
      -0.0404653325676918,
      0.09248953312635422,
      -0.01637117937207222,
      -0.02485799603164196,
      -0.03615373745560646,
      -0.039293088018894196,
      -0.030936578288674355,
      0.05705318972468376,
      -0.0764421597123146,
      0.028309280052781105,
      0.017688119783997536,
      -0.036858588457107544,
      0.016635313630104065,
      0.0046599083580076694,
      0.08631131798028946,
      0.0756073147058487,
      0.02536286599934101,
      -0.04184655100107193,
      0.02558213844895363,
      -0.028327707201242447,
      0.01299247331917286,
      -0.06462664902210236,
      -0.0676494687795639,
      0.04127547889947891,
      0.038034360855817795,
      -0.08703182637691498,
      -0.015476828441023827,
      0.023930799216032028,
      0.04216764494776726,
      -0.0030535277910530567,
      -0.006832129787653685,
      -0.003085836535319686,
      0.03950708359479904,
      -0.022227464243769646,
      0.009846764616668224
    ],
    [
      -0.03580491617321968,
      0.02254604734480381,
      0.04471863433718681,
      0.040915556252002716,
      -0.07375431060791016,
      0.029304223135113716,
      -0.006210680119693279,
      0.08317902684211731,
      -0.10791843384504318,
      0.01608353853225708,
      0.05684547871351242,
      0.05607036501169205,
      0.043431662023067474,
      -0.033038679510354996,
      -0.0015250464202836156,
      0.061958037316799164,
      -0.06287138909101486,
      0.007199003826826811,
      -0.06086315959692001,
      -0.05366107076406479,
      -0.017505580559372902,
      -0.01703241653740406,
      0.04002741724252701,
      -0.0008447293075732887,
      -0.02681826241314411,
      0.019714953377842903,
      0.06137079373002052,
      0.0005665202043019235,
      -0.0873776227235794,
      0.005324636120349169,
      -0.06583119183778763,
      0.021631501615047455,
      -0.027040498331189156,
      -0.07636072486639023,
      0.002671096008270979,
      -0.011571918614208698,
      -0.03980863466858864,
      -0.037491533905267715,
      -0.027579719200730324,
      0.008060676045715809,
      0.03729737177491188,
      -0.015320147387683392,
      -0.0005708695389330387,
      -0.0324678048491478,
      0.060615409165620804,
      0.07579729706048965,
      -0.030549827963113785,
      -0.0048072016797959805,
      -0.029737604781985283,
      -0.03233627602458,
      -0.07683977484703064,
      -0.018709460273385048,
      -0.03331857919692993,
      -0.04318277910351753,
      -0.02552095428109169,
      0.0004699714481830597,
      0.002160526579245925,
      -0.019564760848879814,
      0.029927236959338188,
      -0.0306329894810915,
      0.043748144060373306,
      -0.009271507151424885,
      -0.028563814237713814,
      -0.021547799929976463,
      -0.008914400823414326,
      -0.005970440339297056,
      -0.040294256061315536,
      0.030019095167517662,
      -0.13060405850410461,
      -0.05371489375829697,
      0.03610306978225708,
      0.0387239046394825,
      0.08453257381916046,
      -5.318871990311891e-05,
      -0.06676968187093735,
      0.0244127344340086,
      0.02853495068848133,
      -0.03018075041472912,
      -0.025271927937865257,
      -0.01699325069785118,
      -0.1472199410200119,
      -0.04288844019174576,
      -0.046036142855882645,
      -0.039974357932806015,
      -0.07216370850801468,
      0.003260624362155795,
      -0.06465613842010498,
      -0.012714228592813015,
      -0.05613872781395912,
      0.05011509358882904,
      -0.01750282384455204,
      -0.03610147163271904,
      -0.030032312497496605,
      0.016034521162509918,
      0.012374959886074066,
      -0.04085009917616844,
      0.026472052559256554,
      0.09268137812614441,
      0.02046096697449684,
      0.01070427056401968,
      0.036747053265571594,
      0.026318620890378952,
      -0.07584396749734879,
      0.09601205587387085,
      -0.04671257734298706,
      -0.03306714445352554,
      0.06712854653596878,
      -0.014176050201058388,
      0.06479734182357788,
      -0.036036260426044464,
      -0.02042454481124878,
      -0.05861987546086311,
      -0.017448939383029938,
      0.07420879602432251,
      0.007840419188141823,
      0.008561751805245876,
      -0.052622828632593155,
      0.0617370642721653,
      -0.06807778775691986,
      0.016413424164056778,
      -0.07597347348928452,
      -0.004101269878447056,
      -0.04636466130614281,
      0.02576109953224659,
      -0.04585029184818268,
      -0.05338793620467186,
      -0.07447980344295502,
      0.019520467147231102,
      0.05050050467252731,
      -0.07752041518688202,
      -0.03779054433107376,
      -0.031877923756837845,
      -0.06395788490772247,
      0.04942316189408302,
      0.025044726207852364,
      -0.0026099681854248047,
      -0.041804149746894836,
      0.04515423625707626,
      0.0008346277172677219,
      0.03383442386984825,
      -0.031099772080779076,
      0.05373615399003029,
      -0.06684676557779312,
      -0.03504228591918945,
      -0.010664565488696098,
      -0.05208352953195572,
      -0.007718206848949194,
      -0.011902105994522572,
      0.0063377320766448975,
      -0.06982851028442383,
      0.016967134550213814,
      -0.06637143343687057,
      -0.04837946593761444,
      0.025134477764368057,
      -0.04522978886961937,
      0.1078558936715126,
      -0.037105441093444824,
      -0.016337860375642776,
      -0.06636188924312592,
      0.04692002758383751,
      0.03694282844662666,
      -0.032585322856903076,
      0.02234329842031002,
      0.0649828165769577,
      -0.031452834606170654,
      0.018235284835100174,
      0.0274056326597929,
      -0.04446852207183838,
      -0.02709667570888996,
      0.012210234068334103,
      -0.015721337869763374,
      -0.03520577400922775,
      -0.002400018507614732,
      -0.039864443242549896,
      -0.0012761142570525408,
      0.002887123730033636,
      0.04326481372117996,
      0.0494978092610836,
      -0.07880029827356339,
      0.017057258635759354,
      -0.07882560789585114,
      -0.05667198821902275,
      0.027852600440382957,
      0.05145162343978882,
      -0.09284940361976624,
      0.019179945811629295,
      0.007827362045645714,
      -0.016740404069423676,
      -0.014922836795449257,
      -0.04621157795190811,
      0.0011416897177696228,
      -0.01980433613061905,
      0.04580037668347359,
      0.010549473576247692,
      -0.050504427403211594,
      -0.03659403324127197,
      -0.008740556426346302,
      -0.05122162774205208,
      -0.06711025536060333,
      0.035082753747701645,
      0.005473930388689041,
      0.0017508255550637841,
      0.02752123773097992,
      -0.06966954469680786,
      0.02771081030368805,
      -0.04648353531956673,
      -0.02482466772198677,
      -0.060165636241436005,
      0.04325682297348976,
      0.017126744613051414,
      0.04460905119776726,
      -0.07012607157230377,
      -0.03764786943793297,
      -0.025100069120526314,
      0.02659221552312374,
      -0.08722446858882904,
      0.0621211975812912,
      -0.11290299147367477,
      -0.03425765782594681,
      0.0034608521964401007,
      -0.021081851795315742,
      -0.03980215638875961,
      -0.040937863290309906,
      -0.02913692593574524,
      -0.0072221835143864155,
      -0.05092930048704147,
      0.056512899696826935,
      -0.024944202974438667,
      0.10933498293161392,
      -0.0627257227897644,
      -0.031209034845232964,
      -0.015431937761604786,
      0.004821793641895056,
      -0.020017294213175774,
      -0.006392350420355797,
      -0.04398132115602493,
      0.023855730891227722,
      -0.005552178248763084,
      0.05897917598485947,
      -0.03874402865767479,
      0.02916325069963932,
      -0.038468632847070694,
      -0.03559713065624237,
      -0.06515336036682129,
      -0.030424045398831367,
      -0.005730865988880396,
      -0.01607576757669449,
      -0.04252612218260765,
      0.01979120634496212,
      -0.012697550468146801,
      -0.04843724146485329,
      0.047245092689991,
      -0.01248643733561039,
      -0.006124868057668209,
      -0.029422905296087265,
      -0.0074379658326506615,
      -0.044752947986125946,
      0.07551129907369614,
      -0.0025668705347925425,
      -0.013845136389136314,
      -0.025147298350930214,
      -0.03447716683149338,
      -0.020983999595046043,
      0.07718897610902786,
      -0.027396580204367638,
      -0.04021570086479187,
      0.0008647366776131094,
      -0.04385354369878769,
      0.010134106501936913,
      -0.006173025816679001,
      -0.05011896416544914,
      0.022166864946484566,
      -0.009303940460085869,
      0.00413223123177886,
      -0.12672656774520874,
      0.08299931138753891,
      0.020492350682616234,
      0.0024936122354120016,
      0.0007332816603593528,
      0.0917530506849289,
      -0.004568307660520077,
      0.05269293487071991,
      -0.025233475491404533,
      0.03251602128148079,
      -0.028294216841459274,
      -0.056125156581401825,
      0.06509242951869965,
      -0.03115529753267765,
      0.024802429601550102,
      -0.0382968932390213,
      -0.024601953104138374,
      -0.12077727913856506,
      0.007559832651168108,
      0.09164772927761078,
      -0.027483467012643814,
      0.02071661874651909,
      -0.049487821757793427,
      0.07557336241006851,
      -0.004015316721051931,
      -0.0032506014686077833,
      -0.060347795486450195,
      0.008521631360054016,
      -0.0016942216316238046,
      0.0852801650762558,
      -0.00018595499568618834,
      -0.010445855557918549,
      -0.07443662732839584,
      -0.023188479244709015,
      0.0807587057352066,
      0.025510193780064583,
      0.028888335451483727,
      0.06695694476366043,
      -0.014795789495110512,
      -0.021329162642359734,
      -0.016124164685606956,
      0.04400496929883957,
      -0.059659965336322784,
      -0.021485529839992523,
      0.0015181335620582104,
      -0.03374068811535835,
      -0.006861838046461344,
      -0.01119538489729166,
      0.06231230869889259,
      0.1370130479335785,
      -0.04965828359127045,
      0.0711006373167038,
      0.04389213025569916,
      0.08568324893712997,
      -0.021404394879937172,
      0.09213060885667801,
      0.027752786874771118,
      -0.04246054217219353,
      0.04254629835486412,
      0.061036236584186554,
      -0.06789930909872055,
      -0.021136851981282234,
      0.034227173775434494,
      -0.017350802198052406,
      0.12627655267715454,
      -0.04096574708819389,
      0.1104191392660141,
      -0.10457473993301392,
      -0.05396977812051773,
      0.016662968322634697,
      0.1409158855676651,
      0.10680350661277771,
      0.03654629737138748,
      -0.07450975477695465,
      -0.022758299484848976,
      -0.0010009962134063244,
      0.009585797786712646,
      -0.059769876301288605,
      0.0007168164011090994,
      -0.05431991070508957,
      -0.02094651572406292,
      -0.034703683108091354,
      0.074217289686203,
      -0.047979891300201416,
      0.007633893750607967,
      -0.03504885733127594,
      0.0923464223742485,
      0.017623383551836014,
      0.08751997351646423,
      7.146765710785985e-06,
      -0.04571961238980293,
      -0.07083623111248016,
      0.051529787480831146,
      0.04182145372033119,
      -0.014767979271709919,
      -0.03319929167628288,
      -0.0024283803068101406,
      0.11048917472362518,
      0.014161324128508568,
      -0.01385378185659647,
      -0.029302377253770828,
      0.013092786073684692,
      -0.07404027879238129,
      -0.01096899714320898,
      -0.08864053338766098,
      0.10342208296060562,
      -0.04694174602627754,
      -0.06004241853952408,
      -0.019643954932689667,
      0.013979805633425713,
      0.012947997078299522,
      -0.0252704918384552,
      0.04286503046751022,
      -0.02081642486155033,
      -0.0603308230638504,
      -0.0467725433409214,
      0.030452165752649307,
      0.02963155135512352,
      -0.004297473933547735,
      -0.05424707010388374,
      0.035123370587825775,
      -0.021077565848827362,
      -0.041144780814647675,
      -0.021237248554825783,
      -0.08722586929798126,
      -0.04998057708144188,
      0.041601650416851044,
      0.03246726840734482,
      -0.009124860167503357,
      -0.02134712040424347,
      -0.004165084101259708,
      -0.08016145974397659,
      0.054617565125226974,
      -0.08130274713039398,
      0.05025985836982727,
      -0.014884615316987038,
      0.025170186534523964,
      0.0298018679022789,
      0.04262283071875572,
      -0.0374944731593132,
      -0.10902568697929382,
      0.029050162062048912,
      -0.07446373254060745,
      0.04932789504528046,
      0.01615954376757145,
      -0.03671712428331375,
      -0.022351419553160667,
      0.02713301219046116,
      -0.07556501775979996,
      -0.026466129347682,
      -0.015009891241788864,
      -0.004103417508304119,
      0.044857800006866455,
      0.040108293294906616,
      0.0019251516787335277,
      0.06619340926408768,
      0.07125630974769592,
      -0.008987572975456715,
      -0.01713419333100319,
      0.012842048890888691,
      0.03538227453827858,
      0.05942144617438316,
      -0.056126855313777924,
      -0.08945552259683609,
      -0.06012614443898201,
      -0.005650803446769714,
      0.03581899031996727,
      -0.0004731823573820293,
      0.11487135291099548,
      -0.011995336040854454,
      -0.060031626373529434,
      0.044594451785087585,
      -0.002635382115840912,
      -0.06020977720618248,
      0.027370670810341835,
      0.04988054186105728,
      -0.014230839908123016,
      0.04858078435063362,
      -0.08393200486898422,
      -0.017879990860819817,
      0.006931179203093052,
      0.025238817557692528,
      0.06618309766054153,
      -0.009415972977876663,
      0.04470076411962509,
      0.0028077198658138514,
      0.02772902324795723,
      -0.02577361837029457,
      -0.034019991755485535,
      -0.016264408826828003,
      0.014678907580673695,
      -0.007901362143456936,
      -0.046145979315042496,
      0.0270695723593235,
      -0.06371854990720749,
      -0.015578710474073887,
      0.0226193405687809,
      -0.01351387519389391,
      -0.01609453186392784,
      0.007663124240934849,
      -0.07943428307771683,
      -0.008733942173421383,
      0.00531122786924243,
      0.0045338040217757225,
      0.0038143035490065813,
      -0.06264612823724747,
      -0.08876216411590576,
      0.007462949957698584,
      -0.013814568519592285,
      0.022289495915174484,
      0.06856989115476608,
      -0.004678929690271616,
      -0.016815707087516785,
      0.06099499762058258,
      0.03807533532381058,
      0.0515800416469574,
      -0.010327167809009552,
      -0.031544581055641174,
      0.02744995430111885,
      -0.03948230668902397,
      -0.004627253860235214,
      0.023823490366339684,
      -0.07399377971887589,
      0.025100842118263245,
      -0.024132024496793747,
      0.022668655961751938,
      0.04234505817294121,
      -0.00830009300261736,
      0.013267367146909237
    ],
    [
      0.06152506545186043,
      0.0019293581135571003,
      0.06138576939702034,
      0.012162192724645138,
      -0.004100257530808449,
      0.060657136142253876,
      0.0028887230437248945,
      0.007769458461552858,
      -0.004451444372534752,
      0.053430087864398956,
      0.03039497323334217,
      0.020557023584842682,
      0.024210184812545776,
      -0.0351291261613369,
      -0.04743172973394394,
      0.011002941988408566,
      0.027321230620145798,
      0.03715456277132034,
      0.0015939314616844058,
      -0.018674220889806747,
      0.031456876546144485,
      -0.08332479745149612,
      -0.02227696403861046,
      0.09435710310935974,
      -0.006237206980586052,
      0.05102681741118431,
      -0.08777192234992981,
      0.05847853049635887,
      0.004342465661466122,
      -0.01956312544643879,
      -0.019988033920526505,
      -0.09613741934299469,
      -0.10068574547767639,
      -0.014416187070310116,
      0.0008980198763310909,
      0.013836161233484745,
      -0.06307593733072281,
      0.00828289333730936,
      -0.042670149356126785,
      -0.019663028419017792,
      0.021681493148207664,
      -0.010237999260425568,
      -0.03971286118030548,
      -0.03003997541964054,
      -0.04149110987782478,
      -0.003706535091623664,
      0.010514972731471062,
      0.05106920748949051,
      0.024922966957092285,
      0.006095994729548693,
      0.027002379298210144,
      -0.06390520930290222,
      -0.012810250744223595,
      -0.03429538384079933,
      -0.016202032566070557,
      0.03267164155840874,
      -0.023563511669635773,
      -0.05582301318645477,
      0.043478433042764664,
      0.011646931990981102,
      0.010303075425326824,
      -0.054782718420028687,
      -0.04713539034128189,
      0.03986063972115517,
      0.0372052900493145,
      0.04245331510901451,
      0.03921947255730629,
      -0.022087987512350082,
      0.0028529439587146044,
      -0.009488778188824654,
      0.01482515037059784,
      -0.056093376129865646,
      -0.05029548704624176,
      -0.09585842490196228,
      0.05685386061668396,
      -0.059546034783124924,
      -0.011622367426753044,
      -0.005498846061527729,
      -0.027447352185845375,
      0.0721784457564354,
      0.02621043659746647,
      -0.007636647671461105,
      -0.023078802973031998,
      -0.041593123227357864,
      0.023674340918660164,
      0.02938806265592575,
      -0.05734378099441528,
      0.019238991662859917,
      -0.011154135689139366,
      0.023324033245444298,
      -0.020277025178074837,
      0.04731777682900429,
      0.0645383670926094,
      0.04196278005838394,
      0.046489037573337555,
      -0.07842472195625305,
      -0.08599744737148285,
      0.04217248782515526,
      0.009023475460708141,
      -0.061214033514261246,
      0.02165728621184826,
      -0.022035451605916023,
      0.012234129011631012,
      -0.0001989238808164373,
      -0.012696200050413609,
      0.023222479969263077,
      -0.024948475882411003,
      -0.00962072517722845,
      0.0605425126850605,
      0.015695296227931976,
      -0.02780209109187126,
      0.07907772064208984,
      0.023916853591799736,
      0.013868298381567001,
      -0.0016187557484954596,
      -0.021757761016488075,
      -0.04743451997637749,
      0.025048423558473587,
      0.015505633316934109,
      0.02061438374221325,
      0.08185675740242004,
      0.015498432330787182,
      0.030926313251256943,
      0.06009413301944733,
      0.00854677613824606,
      0.041412219405174255,
      0.02712392807006836,
      -0.014531979337334633,
      0.011431459337472916,
      -0.06590645015239716,
      0.018312029540538788,
      -0.02452201582491398,
      0.012427533976733685,
      0.05154574289917946,
      -0.10803788900375366,
      -0.07253122329711914,
      0.04740123823285103,
      -0.02355814166367054,
      0.041135698556900024,
      -0.007722161710262299,
      0.013024434447288513,
      0.05794503167271614,
      0.0017558205872774124,
      -0.08170726150274277,
      0.03258335590362549,
      -0.005855919793248177,
      -0.008712664246559143,
      0.02020910382270813,
      -0.03868379071354866,
      -0.029533112421631813,
      -0.07109147310256958,
      -0.040201909840106964,
      0.055677756667137146,
      -0.002960163401439786,
      0.03390498831868172,
      0.06966570019721985,
      0.041493091732263565,
      -0.020039191469550133,
      0.02408691868185997,
      0.08916420489549637,
      0.051962561905384064,
      -0.048597514629364014,
      0.033674903213977814,
      0.026052391156554222,
      -0.02837820164859295,
      -0.04617520049214363,
      0.022531097754836082,
      0.0705859586596489,
      0.05270572006702423,
      0.11003988981246948,
      0.032166577875614166,
      -0.02255932427942753,
      0.0361647866666317,
      0.04904736950993538,
      0.05122269690036774,
      -0.09542953222990036,
      0.014526165090501308,
      0.07881294190883636,
      0.005235482472926378,
      -0.031349703669548035,
      0.019720636308193207,
      0.048097025603055954,
      -0.033329159021377563,
      0.03213585168123245,
      -0.013072414323687553,
      0.002537919906899333,
      -0.01987282745540142,
      -0.036824677139520645,
      0.016538439318537712,
      0.00171355449128896,
      0.006410219240933657,
      -0.02046697773039341,
      -0.05541815236210823,
      0.005781024228781462,
      -0.01578746736049652,
      0.003459732048213482,
      -0.029034310951828957,
      -0.0924333781003952,
      -0.026123976334929466,
      0.09839646518230438,
      -0.04005546495318413,
      0.02266089804470539,
      0.009997783228754997,
      -0.028029656037688255,
      -0.008281583897769451,
      -0.09740632027387619,
      0.006272238213568926,
      0.009458191692829132,
      0.025349264964461327,
      -0.01735050603747368,
      0.03421199694275856,
      0.027395611628890038,
      -0.0013027334352955222,
      0.03920186311006546,
      -0.018462248146533966,
      -0.05882329121232033,
      0.02261791005730629,
      0.04151520878076553,
      -8.144943421939388e-05,
      0.04052054509520531,
      -0.010387979447841644,
      -0.04530077800154686,
      0.04687008261680603,
      -0.07779840379953384,
      0.03535449132323265,
      0.010350200347602367,
      -0.009988399222493172,
      -0.011517574079334736,
      0.04826435074210167,
      0.04702305793762207,
      0.028716927394270897,
      -0.0288450438529253,
      -0.009380497969686985,
      -0.04736220836639404,
      0.020677484571933746,
      0.02011292614042759,
      0.06070095673203468,
      0.03668120130896568,
      -0.008814011700451374,
      -0.049687985330820084,
      0.009078328497707844,
      -0.01763916015625,
      -0.021205827593803406,
      0.05152453854680061,
      -0.06600211560726166,
      0.002827896736562252,
      0.09259021282196045,
      0.0670822337269783,
      -0.049513738602399826,
      -0.006594468839466572,
      -0.03923363983631134,
      0.06289198994636536,
      0.029049120843410492,
      -0.019986024126410484,
      -0.07741961628198624,
      -0.004059859085828066,
      -0.007352175656706095,
      0.016627304255962372,
      0.06363650411367416,
      -0.01538560539484024,
      -0.0184249896556139,
      0.020215356722474098,
      -0.0014883819967508316,
      -0.01613187976181507,
      -0.027703091502189636,
      -0.02952146902680397,
      -0.15737666189670563,
      -0.05226890370249748,
      -0.01031517144292593,
      0.00024042795121204108,
      0.03118712641298771,
      -0.04845433682203293,
      0.030955106019973755,
      0.016456834971904755,
      -0.10361862927675247,
      -0.031145747750997543,
      0.0263669490814209,
      0.010540002025663853,
      -0.005188304465264082,
      0.017691677436232567,
      -0.03630302846431732,
      -0.03603273257613182,
      -0.07720302790403366,
      -0.010664519853889942,
      -0.027334995567798615,
      0.02038842812180519,
      0.023459037765860558,
      -0.03222137317061424,
      0.012310436926782131,
      0.025671280920505524,
      0.053469814360141754,
      -0.028208255767822266,
      0.034612130373716354,
      -0.0316963791847229,
      0.0020616883412003517,
      -0.013754607178270817,
      0.025036171078681946,
      -0.016414307057857513,
      0.06503628194332123,
      0.019559241831302643,
      -0.047662172466516495,
      0.01653345115482807,
      -0.03269962593913078,
      0.013403224758803844,
      0.06511388719081879,
      -0.02126952074468136,
      0.08601190149784088,
      0.016301024705171585,
      0.026664910838007927,
      0.03816382959485054,
      -0.06758080422878265,
      -0.00014204005128704011,
      -0.02594137005507946,
      0.06985903531312943,
      -0.045540228486061096,
      0.014514933340251446,
      -0.026179589331150055,
      0.012511949986219406,
      -0.01863517425954342,
      0.01898830756545067,
      0.03075224906206131,
      0.0005055400542914867,
      0.041014164686203,
      -0.06967700272798538,
      -0.03992341831326485,
      -0.007101590279489756,
      -0.0056838952004909515,
      -0.017847450450062752,
      -0.03297915682196617,
      0.05675841122865677,
      0.027011683210730553,
      0.03223567456007004,
      -0.0039462679997086525,
      0.02267398312687874,
      -0.06687068194150925,
      0.03201247751712799,
      0.010029838420450687,
      0.008034304715692997,
      0.03921974450349808,
      -0.04521699249744415,
      -0.01071255374699831,
      0.01562439277768135,
      -0.03289831429719925,
      -0.03716235235333443,
      0.0064976830035448074,
      -0.0014067771844565868,
      0.04621059447526932,
      -0.08671372383832932,
      0.019549435004591942,
      0.05725514143705368,
      -0.042250748723745346,
      0.021350674331188202,
      0.007546217646449804,
      -0.024203559383749962,
      0.04709753394126892,
      -0.02940555289387703,
      0.02410471811890602,
      0.0022137481719255447,
      -0.04201952740550041,
      -0.03802310675382614,
      -0.022731963545084,
      0.005178555846214294,
      -0.00807241816073656,
      0.04707055911421776,
      -0.020095549523830414,
      -0.026501331478357315,
      -0.026959281414747238,
      0.0117952274158597,
      -0.009628656320273876,
      -0.011348523199558258,
      -0.04682089015841484,
      0.004214663989841938,
      0.0018039739225059748,
      0.049758538603782654,
      0.12031286954879761,
      0.03275036811828613,
      -0.0325959287583828,
      -0.050104398280382156,
      0.02831786498427391,
      -0.023611875250935555,
      -0.06930578500032425,
      -0.032716747373342514,
      -0.02954922430217266,
      -0.00459646712988615,
      0.020361486822366714,
      0.004699843470007181,
      -0.025998443365097046,
      -0.011974954046308994,
      -0.01809220388531685,
      0.04642687365412712,
      0.020952526479959488,
      0.040120430290699005,
      0.00839507021009922,
      0.03094569593667984,
      -0.050170719623565674,
      0.12301657348871231,
      -0.025609644129872322,
      -0.05984213203191757,
      -0.04233114793896675,
      0.012675012461841106,
      0.00700279138982296,
      -0.03257434442639351,
      0.01267941016703844,
      0.031209252774715424,
      -0.08224941045045853,
      0.018262486904859543,
      0.025097202509641647,
      0.03163926303386688,
      0.07168656587600708,
      -0.04908258467912674,
      0.06018517166376114,
      0.019062792882323265,
      0.04475061595439911,
      0.03283851593732834,
      0.04697360098361969,
      -0.02725701965391636,
      -0.056844379752874374,
      0.04900208115577698,
      -0.040624331682920456,
      0.04261970520019531,
      -0.06329958885908127,
      -0.07095611840486526,
      -0.06831909716129303,
      -0.013439430855214596,
      0.052087023854255676,
      0.040817853063344955,
      -0.005719420034438372,
      -0.09915062040090561,
      -0.06468167155981064,
      0.018149930983781815,
      -0.07591131329536438,
      0.1303236484527588,
      0.0410764217376709,
      0.0037537990137934685,
      0.06075023114681244,
      -0.059255193918943405,
      -0.024973612278699875,
      0.04652004688978195,
      -0.0106981685385108,
      -0.038695767521858215,
      -0.058235183358192444,
      0.006876823026686907,
      -0.09664254635572433,
      -0.04895557463169098,
      0.024429328739643097,
      0.031323473900556564,
      0.07302241027355194,
      -0.00872998870909214,
      -0.039247069507837296,
      0.09845072776079178,
      -0.0016974088503047824,
      -0.05181746929883957,
      0.04893936216831207,
      0.031819701194763184,
      -0.008306698873639107,
      0.10723595321178436,
      0.040782056748867035,
      -0.00427180714905262,
      0.01334984041750431,
      0.0340961329638958,
      0.0026153973303735256,
      0.09772925823926926,
      -0.047317154705524445,
      -0.026989871636033058,
      -0.008615159429609776,
      -0.059498339891433716,
      -0.042167771607637405,
      -0.032384369522333145,
      -0.020622553303837776,
      0.007831357419490814,
      0.013334866613149643,
      0.04115131497383118,
      0.036347895860672,
      0.004032050725072622,
      -0.029718885198235512,
      0.044818706810474396,
      0.033461228013038635,
      -0.054295096546411514,
      -0.036630384624004364,
      -0.010580673813819885,
      -0.02357221581041813,
      0.022666005417704582,
      0.07207779586315155,
      0.06327039003372192,
      0.015411189757287502,
      0.06283006817102432,
      0.004707598127424717,
      -0.020652396604418755,
      0.01634625345468521,
      0.020662181079387665,
      0.002567243529483676,
      -0.0238362904638052,
      0.012510368600487709,
      -0.03161681070923805,
      -0.014494257979094982,
      -0.03711841255426407,
      0.028132623061537743,
      0.03730614483356476,
      0.1067781150341034,
      0.003235290525481105,
      0.04454497992992401,
      0.001445294008590281,
      0.037492021918296814
    ],
    [
      -0.062372639775276184,
      -0.0672699436545372,
      -0.06079258769750595,
      -0.05097842216491699,
      -0.027174975723028183,
      -0.018436701968312263,
      -0.018807852640748024,
      -0.01420068833976984,
      -0.08848707377910614,
      0.011851842515170574,
      0.09100087732076645,
      -0.04859912022948265,
      -0.008859511464834213,
      -0.00754915364086628,
      0.046237461268901825,
      0.022154053673148155,
      -0.018564600497484207,
      0.003555005183443427,
      0.02354014851152897,
      0.006410938687622547,
      -0.04400487616658211,
      -0.023013100028038025,
      -0.003662967588752508,
      -0.03026125207543373,
      -0.018029451370239258,
      0.02490903064608574,
      0.008462119847536087,
      0.008645161055028439,
      0.004064986482262611,
      -0.08173062652349472,
      -0.00928125437349081,
      0.03280700370669365,
      -0.022038284689188004,
      0.0026872530579566956,
      -0.05030317232012749,
      0.034977566450834274,
      0.030582930892705917,
      0.027118397876620293,
      0.030048057436943054,
      -0.08788811415433884,
      0.04005942866206169,
      -0.04032554849982262,
      0.0012133405543863773,
      0.058892007917165756,
      -0.005040609277784824,
      0.05657264217734337,
      0.08421225845813751,
      -0.04295021668076515,
      0.02842484973371029,
      -0.0440213680267334,
      0.06444957107305527,
      0.039168622344732285,
      -0.008661369793117046,
      0.046049341559410095,
      -0.03226578235626221,
      -0.011175992898643017,
      -0.014275743626058102,
      -0.03931307792663574,
      0.03941546380519867,
      0.01585797406733036,
      -0.01171653252094984,
      -0.06039315089583397,
      -0.05123128741979599,
      -0.08010944724082947,
      0.008283630013465881,
      0.05422007665038109,
      0.07199699431657791,
      -0.0490189753472805,
      0.05426741763949394,
      -0.016389308497309685,
      0.05168573558330536,
      0.0554809644818306,
      -0.05631044879555702,
      -0.08699445426464081,
      0.014750965870916843,
      -0.05255481228232384,
      -0.06297242641448975,
      0.02379700168967247,
      -0.03834054619073868,
      -0.0849551260471344,
      -0.03958532586693764,
      0.036312930285930634,
      0.07720016688108444,
      0.02370366081595421,
      0.12949465215206146,
      0.019946729764342308,
      -0.0721282809972763,
      -0.024067895486950874,
      -0.05786111578345299,
      0.061352670192718506,
      0.04142391309142113,
      0.02007121779024601,
      -0.017065279185771942,
      0.013446839526295662,
      0.025740288197994232,
      0.021492432802915573,
      -0.03649399057030678,
      -0.042371977120637894,
      0.013296171091496944,
      0.008546543307602406,
      -0.06260956078767776,
      -0.008688359521329403,
      -0.012617860920727253,
      0.000842061301227659,
      -0.07522130012512207,
      0.0015995180001482368,
      -0.03979644179344177,
      0.018253127112984657,
      0.03896814584732056,
      0.032572343945503235,
      -0.032396651804447174,
      0.05718838423490524,
      0.08317013084888458,
      -0.04292946308851242,
      -0.026109524071216583,
      -0.023356527090072632,
      0.05834292992949486,
      -0.04456514120101929,
      0.015316598117351532,
      0.035733673721551895,
      0.09718260914087296,
      -0.03377288207411766,
      0.04122728854417801,
      -0.037675924599170685,
      -0.05023973435163498,
      0.00853813998401165,
      -0.026814552024006844,
      -0.01387709565460682,
      -0.036173347383737564,
      -0.0042031751945614815,
      -0.038250748068094254,
      0.03774617612361908,
      -0.050735991448163986,
      0.05952850356698036,
      0.015061864629387856,
      0.05884379521012306,
      -0.06469570845365524,
      -0.10379000008106232,
      0.012286550365388393,
      -0.013966605998575687,
      0.03567242622375488,
      -0.03189728781580925,
      -0.07274048775434494,
      0.010136731900274754,
      0.010077407583594322,
      0.048660699278116226,
      -0.03907815366983414,
      -0.030586108565330505,
      -0.055418964475393295,
      -0.039889149367809296,
      0.007334251422435045,
      0.07569897174835205,
      0.061016082763671875,
      0.0919589027762413,
      0.007939564064145088,
      -0.024204358458518982,
      -0.05252884328365326,
      0.010790428146719933,
      0.038818951696157455,
      0.08215682953596115,
      0.015637213364243507,
      -0.06251092255115509,
      0.024026386439800262,
      -0.032640933990478516,
      -0.04755518212914467,
      0.03251005709171295,
      0.14818458259105682,
      -0.049555834382772446,
      0.05266900733113289,
      -0.005080280359834433,
      -0.0343303456902504,
      0.018844319507479668,
      -0.041228003799915314,
      0.0023055975325405598,
      -0.022746309638023376,
      -0.054656874388456345,
      -0.022826695814728737,
      0.04959319159388542,
      -0.021214094012975693,
      -0.11424655467271805,
      -0.013458441011607647,
      0.07167466729879379,
      -0.008832292631268501,
      0.022682001814246178,
      0.011332007125020027,
      0.034100160002708435,
      0.0638807862997055,
      -0.011237790808081627,
      -0.05107983946800232,
      -0.026299405843019485,
      0.015206231735646725,
      -0.05638051778078079,
      0.05750076100230217,
      -0.02430660091340542,
      -0.027772240340709686,
      0.020349279046058655,
      -0.02034817636013031,
      -0.011832567863166332,
      0.007832007482647896,
      0.026770232245326042,
      -0.034026313573122025,
      -0.0693034678697586,
      0.06992138922214508,
      -0.010468377731740475,
      -0.0604892335832119,
      0.08876154571771622,
      0.019721752032637596,
      -0.025837140157818794,
      0.0008430610760115087,
      -0.02439570054411888,
      0.04706486314535141,
      0.0015669530257582664,
      0.059110961854457855,
      0.007543887943029404,
      0.005072665400803089,
      -0.015247276984155178,
      -0.004864491522312164,
      -0.011584477499127388,
      0.027359381318092346,
      -0.02256017178297043,
      -0.051611874252557755,
      -0.019697869196534157,
      0.001039775088429451,
      0.03177010640501976,
      -0.03785194456577301,
      -0.033714596182107925,
      0.012198163196444511,
      0.10026227682828903,
      0.041622575372457504,
      -0.002783501520752907,
      -0.008226977661252022,
      -0.01697486825287342,
      -0.027317684143781662,
      -0.042942341417074203,
      -0.02738324925303459,
      0.0028638867661356926,
      0.011404857970774174,
      0.06882568448781967,
      0.01186825055629015,
      -0.020758187398314476,
      -0.011194114573299885,
      -0.07712715864181519,
      -0.03629355877637863,
      -0.027099942788481712,
      -0.026481179520487785,
      0.015683576464653015,
      0.010074932128190994,
      -0.012505268678069115,
      0.0051094163209199905,
      0.017269494011998177,
      0.16194546222686768,
      -0.0009029331267811358,
      0.00929949339479208,
      0.01056074257940054,
      -0.03321385756134987,
      -0.008612221106886864,
      0.0637352466583252,
      0.000573037366848439,
      0.03140086680650711,
      0.08070637285709381,
      -0.0076178815215826035,
      -0.0779905840754509,
      -0.049374017864465714,
      0.06793149560689926,
      0.05650414898991585,
      -0.0161919966340065,
      0.04537704586982727,
      -0.1144767701625824,
      -0.08103785663843155,
      -0.02197805605828762,
      -0.00478329649195075,
      0.013578755781054497,
      -0.012608424760401249,
      0.08263597637414932,
      -0.0372573621571064,
      -0.016358420252799988,
      -0.03714417666196823,
      0.07420092076063156,
      -0.023267176002264023,
      -0.030597984790802002,
      0.009649565443396568,
      -0.03591162711381912,
      -0.045479293912649155,
      0.0653640404343605,
      -0.007823924534022808,
      0.026515740901231766,
      -0.01221771351993084,
      -0.0007973604369908571,
      0.03834037110209465,
      0.008478180505335331,
      -0.0006637408514507115,
      0.002126262756064534,
      0.0020944694988429546,
      0.0062152608297765255,
      -0.06979920715093613,
      0.013190798461437225,
      0.01837781071662903,
      -0.03256507217884064,
      -0.03789592906832695,
      -0.0023760513868182898,
      0.04596899077296257,
      0.015470383688807487,
      -0.04833158105611801,
      -0.011666292324662209,
      -0.03221527859568596,
      0.02665848098695278,
      0.03225608915090561,
      -0.04949653521180153,
      0.007995854131877422,
      0.0338243693113327,
      -0.0084372628480196,
      0.04423197731375694,
      0.008990042842924595,
      0.09486489742994308,
      -0.08708370476961136,
      0.030959822237491608,
      0.07179707288742065,
      0.02645351178944111,
      0.011124706827104092,
      0.005452221725136042,
      0.09903033822774887,
      0.012531542219221592,
      0.013476655818521976,
      0.0924953818321228,
      -0.05307978019118309,
      0.008299880661070347,
      -0.018246321007609367,
      0.02116810716688633,
      0.03545491024851799,
      0.03128422796726227,
      0.00677036726847291,
      -0.015874823555350304,
      0.06514580547809601,
      -0.0633273646235466,
      0.013057805597782135,
      -0.0012130967807024717,
      0.043656039983034134,
      -0.0569634884595871,
      0.022187314927577972,
      -0.040806472301483154,
      0.08635435253381729,
      -0.07373364269733429,
      0.0508541464805603,
      0.01471788715571165,
      0.02181766740977764,
      0.05895770713686943,
      0.1138857826590538,
      0.050723738968372345,
      -0.010870066471397877,
      -0.0035429163835942745,
      -0.02685101330280304,
      -0.06387200951576233,
      0.01877521723508835,
      -0.011821283958852291,
      0.02564525045454502,
      0.026166187599301338,
      0.008840538561344147,
      0.09439616650342941,
      -0.02093319594860077,
      -0.023008646443486214,
      0.06656163930892944,
      0.04436063766479492,
      0.07233590632677078,
      0.018002621829509735,
      0.041882339864969254,
      -0.000299523351714015,
      0.04119294881820679,
      -0.06979421526193619,
      0.01787380687892437,
      0.006632598116993904,
      0.03528600186109543,
      0.010980158112943172,
      0.10464651882648468,
      -0.01791056990623474,
      -0.0012196771567687392,
      0.07625733315944672,
      0.07306995987892151,
      -0.0608663335442543,
      0.041985560208559036,
      -0.0776359811425209,
      0.03603378310799599,
      0.002671757945790887,
      0.04164022579789162,
      -0.046712592244148254,
      -0.10736669600009918,
      0.07515539228916168,
      -0.027718985453248024,
      -0.043046221137046814,
      -0.007050196174532175,
      -0.018161851912736893,
      0.006029569078236818,
      0.02690383978188038,
      0.007079990580677986,
      -0.0690985843539238,
      0.03767937421798706,
      -0.03858503699302673,
      0.08087913691997528,
      -0.0269270371645689,
      -0.0025491523556411266,
      -0.0009597856551408768,
      0.08703166246414185,
      -0.06136621534824371,
      0.007634984329342842,
      0.012814555317163467,
      0.05737128481268883,
      -0.044463180005550385,
      -0.027829306200146675,
      0.09454721212387085,
      0.01371708232909441,
      0.0723087340593338,
      0.025313451886177063,
      -0.007304176688194275,
      0.034975867718458176,
      -0.05672598257660866,
      -0.031540077179670334,
      0.027379274368286133,
      -0.022870052605867386,
      0.06950610131025314,
      -0.06308567523956299,
      -0.038486547768116,
      -0.01104829739779234,
      -0.06830769032239914,
      -0.018497373908758163,
      -0.0541105791926384,
      -0.012089824303984642,
      -0.01051421370357275,
      0.01622258499264717,
      -0.047639667987823486,
      -0.05217571556568146,
      0.03465476259589195,
      0.0603005550801754,
      -0.08560312539339066,
      0.0604843944311142,
      0.01738092117011547,
      0.003450761316344142,
      0.013306733220815659,
      -0.042295459657907486,
      -0.07103181630373001,
      0.0015731363091617823,
      -0.03745954856276512,
      0.008846733719110489,
      -0.004304004367440939,
      0.010115682147443295,
      -0.0006509892409667373,
      0.0469093956053257,
      0.018388809636235237,
      -0.018250850960612297,
      0.012734887190163136,
      -0.0188099704682827,
      -0.013254395686089993,
      0.00891252513974905,
      0.01620180904865265,
      0.07701485604047775,
      0.11837143450975418,
      -0.004850664641708136,
      -0.015405811369419098,
      0.006768869236111641,
      -0.010041874833405018,
      -0.025327719748020172,
      0.09603502601385117,
      0.008574873208999634,
      0.03344010189175606,
      0.0829213559627533,
      0.031006621196866035,
      0.032770704478025436,
      0.018971599638462067,
      -0.11934392899274826,
      0.025704264640808105,
      -0.0028306571766734123,
      -0.07853315025568008,
      0.04215068742632866,
      -0.01432336401194334,
      -0.025168737396597862,
      -0.11321527510881424,
      0.01547626405954361,
      0.019221216440200806,
      0.07990382611751556,
      -0.08931443840265274,
      -0.0006140570621937513,
      0.09338322281837463,
      0.09448324143886566,
      -0.01435384526848793,
      -0.009867971763014793,
      0.011507218703627586,
      -0.04174916073679924,
      0.014200449921190739,
      0.10878948867321014,
      0.09450186043977737,
      -0.028032556176185608,
      -0.057513028383255005,
      -0.019071178510785103,
      -0.07584112137556076,
      0.04540397599339485,
      0.007460478227585554,
      -0.07026374340057373,
      -0.061297062784433365,
      0.05899836868047714,
      -0.01245503406971693,
      0.01665247231721878,
      0.027976438403129578,
      0.026477491483092308,
      0.013212884776294231,
      -0.08167283982038498
    ],
    [
      -0.06736093759536743,
      0.04043044149875641,
      -0.06258881837129593,
      0.00994030199944973,
      -0.03020472265779972,
      -0.016933176666498184,
      -0.01381292100995779,
      0.013685607351362705,
      0.01109593827277422,
      -0.007111516781151295,
      -0.0192207433283329,
      -0.0154423201456666,
      -0.019875140860676765,
      -0.003951179329305887,
      0.06267722696065903,
      -0.01668313890695572,
      -0.04114474728703499,
      -0.03265663981437683,
      0.06855670362710953,
      -0.04450364038348198,
      -0.0007620726828463376,
      -0.008104261942207813,
      -0.06042800098657608,
      -0.044495850801467896,
      -0.06868191808462143,
      0.040344949811697006,
      -0.03296792134642601,
      0.004499900154769421,
      0.0372747927904129,
      -0.04836641252040863,
      -0.05202241986989975,
      -0.004923817701637745,
      0.03216727823019028,
      0.05607762932777405,
      -0.007722530048340559,
      0.1318066567182541,
      -0.047506049275398254,
      -0.08147075772285461,
      -0.09146241098642349,
      -0.009590845555067062,
      0.019050385802984238,
      -0.031329862773418427,
      -0.036890916526317596,
      -0.07318799197673798,
      -0.02110319398343563,
      -0.03939902409911156,
      -0.02848740667104721,
      -0.08361854404211044,
      0.0550585612654686,
      0.040987253189086914,
      0.031149592250585556,
      0.03436291962862015,
      0.044978681951761246,
      0.03148062154650688,
      0.01667279563844204,
      0.023644432425498962,
      -0.015433821827173233,
      0.0628548264503479,
      -0.03857998549938202,
      0.11336318403482437,
      -0.013566059060394764,
      -0.03090801276266575,
      0.09496819972991943,
      0.08811274915933609,
      -0.0027158691082149744,
      -0.043478596955537796,
      0.012379243038594723,
      0.005282432306557894,
      -0.02065187692642212,
      0.013818936422467232,
      0.026822715997695923,
      0.01374343503266573,
      -0.07087461650371552,
      -0.05237920209765434,
      0.06699080765247345,
      0.011088535189628601,
      -0.017310116440057755,
      -0.024163613095879555,
      -0.004628739319741726,
      -0.04894072562456131,
      0.08670197427272797,
      -0.056326724588871,
      0.07628494501113892,
      -0.037247657775878906,
      -0.05819421261548996,
      0.05849991366267204,
      -0.042717911303043365,
      -0.0025268809404224157,
      -0.04914790764451027,
      0.0510120689868927,
      -0.028930291533470154,
      0.024516955018043518,
      -0.08398699015378952,
      0.04337811842560768,
      0.01691567152738571,
      -0.009274798445403576,
      -0.06056945025920868,
      0.01578117161989212,
      -0.02963380515575409,
      0.06482516974210739,
      0.06676540523767471,
      -0.03409412503242493,
      0.007681648712605238,
      -0.026585513725876808,
      -0.015855327248573303,
      -0.039160460233688354,
      -0.020473595708608627,
      0.016840679571032524,
      -0.015155254863202572,
      -0.03560928627848625,
      -0.011153505183756351,
      -0.005696699023246765,
      -0.018440058454871178,
      -0.08456961810588837,
      -0.047473035752773285,
      0.007539432030171156,
      -0.06868376582860947,
      -0.07888078689575195,
      -0.00037828562199138105,
      -0.00632231030613184,
      -0.04176788404583931,
      -0.010676090605556965,
      0.007096854504197836,
      -0.07401358336210251,
      -0.035639483481645584,
      -0.011048451066017151,
      -0.025417568162083626,
      -0.005203471053391695,
      -0.10424698889255524,
      0.027405813336372375,
      0.06517940014600754,
      0.017332125455141068,
      -0.07076415419578552,
      -0.007920920848846436,
      -0.032524749636650085,
      0.06805532425642014,
      0.002557199215516448,
      -0.05960901454091072,
      0.030219605192542076,
      -0.014961324632167816,
      0.02939058095216751,
      0.0008919460815377533,
      -0.0899912640452385,
      0.01434309221804142,
      0.03763282671570778,
      -0.004329576622694731,
      0.052094750106334686,
      -0.06520852446556091,
      0.01358155906200409,
      -0.04293009638786316,
      -0.00837607029825449,
      0.050648320466279984,
      -0.06404411792755127,
      0.028070824220776558,
      -0.03891560062766075,
      0.028295718133449554,
      0.06540264934301376,
      0.09485708922147751,
      0.04069266468286514,
      -0.0004837791493628174,
      0.026993807405233383,
      0.004915018565952778,
      -0.03818582370877266,
      0.004525310825556517,
      0.006030017510056496,
      0.007795589044690132,
      -0.05009894818067551,
      0.06266514211893082,
      0.012545258738100529,
      -0.03963802009820938,
      0.01984497345983982,
      -0.05611257255077362,
      -0.03316417336463928,
      -0.04936381056904793,
      0.033649224787950516,
      0.003278300864621997,
      -0.03749022260308266,
      0.032594162970781326,
      0.0065139587968587875,
      0.0020699030719697475,
      0.015517208725214005,
      -0.024815460667014122,
      0.011769721284508705,
      -0.09203556925058365,
      -0.0317990668118,
      -0.07445775717496872,
      0.01715955324470997,
      0.006743292789906263,
      -0.012936004437506199,
      0.030112862586975098,
      -0.03268296644091606,
      -0.0724642425775528,
      -0.031841907650232315,
      -0.005521599669009447,
      -0.011907664127647877,
      0.05005243048071861,
      0.026069046929478645,
      -0.03631334379315376,
      -0.0326668806374073,
      0.06366556137800217,
      0.036995772272348404,
      0.03669634461402893,
      0.05674900859594345,
      0.047853752970695496,
      -0.05230712890625,
      0.00733507564291358,
      0.07069309800863266,
      0.041657138615846634,
      0.009785640984773636,
      -0.05929119512438774,
      -0.006818409077823162,
      0.009562655352056026,
      -0.017179220914840698,
      0.034336432814598083,
      0.004599845036864281,
      -0.0001339561422355473,
      0.027011718600988388,
      -0.0037837012205272913,
      0.0456170029938221,
      -0.007641505915671587,
      -0.01162261888384819,
      -0.0582817941904068,
      0.022921191528439522,
      -0.018513401970267296,
      -0.082618348300457,
      0.014554099179804325,
      0.03487352281808853,
      0.023417510092258453,
      0.03511785343289375,
      0.007979810237884521,
      0.03982505574822426,
      0.05961736664175987,
      -0.015141710638999939,
      0.09169983118772507,
      0.02373451367020607,
      0.0019504505908116698,
      -0.022455088794231415,
      0.10302769392728806,
      0.011527701281011105,
      0.028234286233782768,
      0.005465736147016287,
      0.029456637799739838,
      0.00509333610534668,
      0.049646832048892975,
      0.004153337329626083,
      0.06131969764828682,
      -0.0628119632601738,
      -0.11639038473367691,
      -0.0036103460006415844,
      0.05590461567044258,
      0.02643303945660591,
      0.013593661598861217,
      0.0025835931301116943,
      -0.019083172082901,
      -0.06892718374729156,
      -0.05689501389861107,
      -0.042457159608602524,
      0.04378347098827362,
      0.057778500020504,
      0.08016423881053925,
      0.027664093300700188,
      0.026925664395093918,
      0.025869665667414665,
      -0.03625832125544548,
      0.03993842005729675,
      -0.03982340171933174,
      -0.0236183013767004,
      -0.06810513138771057,
      -0.06465550512075424,
      0.05664259195327759,
      -0.008677789941430092,
      -0.007904577068984509,
      -0.0388009287416935,
      -0.000149166866322048,
      -0.04315304756164551,
      0.02051968313753605,
      0.06490225344896317,
      0.02615077793598175,
      -0.034004684537649155,
      0.07437258958816528,
      0.03221607208251953,
      -0.043530337512493134,
      -0.0063607776537537575,
      0.016492251306772232,
      -0.015833411365747452,
      0.06271494925022125,
      0.01654198206961155,
      0.02547992207109928,
      0.053185537457466125,
      0.007012936752289534,
      0.027851399034261703,
      -0.046549852937459946,
      0.013785877265036106,
      -0.008332179859280586,
      0.034147538244724274,
      -0.0432279147207737,
      0.004853321239352226,
      0.03440281003713608,
      0.007695952896028757,
      0.050929129123687744,
      -0.08371526002883911,
      0.002916900906711817,
      -0.04615452513098717,
      0.0075760940089821815,
      -0.05047697573900223,
      -0.06507173180580139,
      -0.040780503302812576,
      0.023140858858823776,
      -0.07824952900409698,
      0.02878699265420437,
      0.03343266621232033,
      0.04904042184352875,
      0.0042600566521286964,
      -0.00239804619923234,
      -0.004420869518071413,
      0.030380114912986755,
      -0.04363865405321121,
      -0.06279744952917099,
      -0.015678616240620613,
      -0.05745372548699379,
      0.016635799780488014,
      -0.016093183308839798,
      -0.014798692427575588,
      0.028072349727153778,
      -0.004935387056320906,
      -0.032878607511520386,
      0.04479609802365303,
      0.04034193605184555,
      -0.02198421023786068,
      0.04348409175872803,
      0.01454280037432909,
      -0.023556441068649292,
      0.02422521263360977,
      -0.0188494510948658,
      0.06074552237987518,
      0.035648591816425323,
      -0.025799423456192017,
      -0.030488939955830574,
      0.0347936637699604,
      0.06327030807733536,
      0.01751193217933178,
      0.023411370813846588,
      0.0337737500667572,
      0.033450037240982056,
      -0.016052400693297386,
      -0.005744743160903454,
      -0.0447005070745945,
      -0.03099026530981064,
      -0.03288554400205612,
      0.03784014657139778,
      0.028447866439819336,
      -0.006335013546049595,
      -0.0407392643392086,
      0.014452188275754452,
      0.06427192687988281,
      -0.06139190495014191,
      0.07576748728752136,
      0.0819246843457222,
      0.00027167642838321626,
      0.08021092414855957,
      0.03351432457566261,
      0.08732087165117264,
      -0.0371554009616375,
      0.09131820499897003,
      0.03760996833443642,
      0.01450886856764555,
      -0.02099352516233921,
      0.08750997483730316,
      -0.06323761492967606,
      -0.05874280631542206,
      0.038764458149671555,
      0.047888804227113724,
      0.015884749591350555,
      0.008616933599114418,
      -0.04349797964096069,
      0.04830875247716904,
      -0.05744684487581253,
      0.0183731559664011,
      -0.05230109766125679,
      -0.06468569487333298,
      0.03523753210902214,
      0.008216207846999168,
      0.021362973377108574,
      -0.032285694032907486,
      0.03859817236661911,
      0.0026213082019239664,
      0.05474289879202843,
      0.021070636808872223,
      -0.023249132558703423,
      -0.06345660984516144,
      -0.049869511276483536,
      0.008641482330858707,
      0.01632574386894703,
      -0.008118579164147377,
      -0.007177661173045635,
      0.009970384649932384,
      -0.00851192232221365,
      -0.03125149756669998,
      0.0022359101567417383,
      0.001069237943738699,
      0.05729946121573448,
      -0.026880184188485146,
      0.007891112938523293,
      -0.032331887632608414,
      -0.05209732800722122,
      0.042685698717832565,
      -0.08802687376737595,
      0.06385897845029831,
      -0.030880650505423546,
      -0.007554803509265184,
      0.03792548179626465,
      -0.01731586456298828,
      -0.019238639622926712,
      -0.05340037867426872,
      0.020184027031064034,
      0.006255768239498138,
      0.03176579624414444,
      0.07129237800836563,
      0.0708814188838005,
      -0.02171219512820244,
      0.02547539211809635,
      -0.02267896756529808,
      0.07986748963594437,
      0.006985937710851431,
      -0.03382841870188713,
      0.06362903863191605,
      -0.013938319869339466,
      -0.052789732813835144,
      0.007865631021559238,
      -0.024772904813289642,
      0.06494578719139099,
      0.10385143011808395,
      0.034982290118932724,
      0.056650009006261826,
      0.010550773702561855,
      -0.0007254820084199309,
      0.029912281781435013,
      -0.02569902315735817,
      0.010276230983436108,
      -0.0526428148150444,
      -0.04132893308997154,
      -0.048991501331329346,
      0.024313241243362427,
      -0.05262533202767372,
      0.017199218273162842,
      -0.09098917990922928,
      0.04589330032467842,
      -0.03486824780702591,
      0.05019638314843178,
      -0.019141510128974915,
      -0.0029206823091953993,
      0.06754710525274277,
      -0.05435087904334068,
      -0.03205769881606102,
      0.008433684706687927,
      -0.02636081352829933,
      -0.02590162120759487,
      -0.0082100173458457,
      -0.028220590204000473,
      -0.05361238494515419,
      -0.05789553001523018,
      0.039571624249219894,
      -0.05876927077770233,
      -0.046766702085733414,
      -0.013132022693753242,
      0.011882155202329159,
      -0.020250339061021805,
      0.01492430828511715,
      -0.04365679994225502,
      -0.041340045630931854,
      0.001527460408397019,
      -0.02687293291091919,
      0.007448113523423672,
      -0.03149824216961861,
      -0.05476094409823418,
      -0.13325487077236176,
      -0.06470321863889694,
      -0.022293755784630775,
      -0.011313996277749538,
      0.05517486110329628,
      -0.027479061856865883,
      -0.04325997084379196,
      -0.056299276649951935,
      -0.049420349299907684,
      -0.016026696190238,
      0.030377952381968498,
      0.057659897953271866,
      0.008849072270095348,
      0.05606168881058693,
      0.026598474010825157,
      -0.03357834741473198,
      -0.017254136502742767,
      0.022878242656588554,
      0.013790001161396503,
      0.022212015464901924,
      -0.08236456662416458,
      0.05084799602627754,
      -0.076146200299263,
      0.039248283952474594,
      0.029322022572159767,
      7.955272303661332e-05,
      -0.05523760989308357,
      0.04939623177051544
    ],
    [
      0.007890678942203522,
      -0.031188415363430977,
      -0.007443639449775219,
      -0.013700407929718494,
      -0.049132078886032104,
      -0.027224095538258553,
      0.025237886235117912,
      -0.021214108914136887,
      -0.00474883895367384,
      0.07912325859069824,
      0.0625709667801857,
      -0.03635816648602486,
      -0.0505148246884346,
      -0.04756487160921097,
      0.03859969228506088,
      0.08775928616523743,
      -0.029212694615125656,
      0.007194222882390022,
      0.04495237395167351,
      -0.003302657511085272,
      -0.07018879055976868,
      -0.048616088926792145,
      -0.044456783682107925,
      0.06146400794386864,
      -0.03294647857546806,
      -0.003592207795009017,
      0.08020998537540436,
      -0.022443197667598724,
      0.04568782076239586,
      0.12342626601457596,
      0.05130978301167488,
      -0.10258232802152634,
      0.033805061131715775,
      -0.015458067879080772,
      0.03757055476307869,
      0.06858216971158981,
      -0.0800042524933815,
      -0.022840052843093872,
      0.01549439039081335,
      -0.06348372995853424,
      -0.01752620004117489,
      -0.03894361853599548,
      0.04774022102355957,
      -0.022861814126372337,
      0.017741790041327477,
      0.0004967204295098782,
      -0.016367232427001,
      -0.0020493504125624895,
      0.00941423512995243,
      -0.034027837216854095,
      0.048046160489320755,
      0.02196093276143074,
      -0.0492127500474453,
      0.012896165251731873,
      -0.04260450229048729,
      -0.015563545748591423,
      0.0025285936426371336,
      0.057288508862257004,
      -0.015335510484874249,
      -0.05196024104952812,
      -0.060222696512937546,
      0.013179918751120567,
      -0.06462221592664719,
      -0.02194019965827465,
      0.005400178022682667,
      -0.03534223511815071,
      0.014760361984372139,
      0.04379359632730484,
      -0.0005154105019755661,
      -0.01196366362273693,
      -0.014225436374545097,
      -0.0025994067545980215,
      0.036373816430568695,
      -0.009393499232828617,
      0.03307943046092987,
      0.04551047086715698,
      0.04464063420891762,
      -0.008260988630354404,
      0.014757996425032616,
      0.011820214800536633,
      -0.0019717318937182426,
      -0.06829732656478882,
      0.10725602507591248,
      0.01394954975694418,
      -0.07983117550611496,
      -0.060271620750427246,
      -0.005733111873269081,
      0.030296560376882553,
      -0.029472243040800095,
      0.0679878517985344,
      -0.03809497877955437,
      -0.006159478798508644,
      -0.07380924373865128,
      -0.017206786200404167,
      0.0026187715120613575,
      -0.01600380800664425,
      -0.0010023824870586395,
      0.0868615135550499,
      0.022899772971868515,
      0.08224280178546906,
      -0.014394160360097885,
      -0.06208779662847519,
      0.06983035802841187,
      0.008754615671932697,
      -0.061958976089954376,
      -0.06835413724184036,
      0.042644601315259933,
      0.011800668202340603,
      0.017774587497115135,
      -0.058890026062726974,
      0.07799182087182999,
      -0.013151045888662338,
      -0.030004549771547318,
      0.014975997619330883,
      -0.0013089142739772797,
      0.006576122250407934,
      0.017032518982887268,
      -0.04770595580339432,
      -0.11236532032489777,
      -0.018842704594135284,
      0.023851759731769562,
      0.09877295792102814,
      0.08937937021255493,
      -0.02242349646985531,
      0.030650287866592407,
      0.030026476830244064,
      -0.01840963028371334,
      0.009947111830115318,
      -0.07913566380739212,
      0.07517100870609283,
      -0.010128701105713844,
      0.07240475714206696,
      0.032216958701610565,
      -0.06034909561276436,
      0.02148534543812275,
      0.027832049876451492,
      -0.004866340663284063,
      0.020784776657819748,
      -0.0754963606595993,
      -0.05670320987701416,
      0.007990695536136627,
      -0.019315021112561226,
      0.01576629839837551,
      -0.05189578980207443,
      0.07139178365468979,
      0.08546647429466248,
      0.023160209879279137,
      0.05415571108460426,
      -0.025660593062639236,
      -0.015481104142963886,
      -0.025743968784809113,
      -0.08245373517274857,
      -0.028644876554608345,
      -0.024302981793880463,
      -0.061994053423404694,
      0.03627321124076843,
      0.029088634997606277,
      -0.005225313827395439,
      -0.03937966376543045,
      -0.00721563957631588,
      -0.029849786311388016,
      0.023396823555231094,
      0.07295722514390945,
      0.001984088681638241,
      0.06427266448736191,
      -0.032725535333156586,
      -0.05414290726184845,
      0.05364462360739708,
      0.00947649497538805,
      0.038627613335847855,
      -0.01606890931725502,
      0.037082090973854065,
      -0.06474360823631287,
      0.011575975455343723,
      -0.02639218233525753,
      -0.04220741614699364,
      0.06443675607442856,
      0.0675397738814354,
      0.016845853999257088,
      -0.03350106626749039,
      0.018396561965346336,
      -0.015460232272744179,
      0.020902039483189583,
      -0.027280084788799286,
      -0.06687034666538239,
      0.04689120873808861,
      0.00027825235156342387,
      0.013046268373727798,
      0.051674239337444305,
      -0.03657888248562813,
      0.01093345321714878,
      0.02220890484750271,
      0.04221788048744202,
      -0.023840250447392464,
      -0.08005055785179138,
      -0.054500531405210495,
      0.09754561632871628,
      -0.01407577097415924,
      -0.03440720587968826,
      0.005567655432969332,
      -0.01542618963867426,
      -0.08241477608680725,
      0.05019088834524155,
      -0.011471926234662533,
      -0.006249641068279743,
      0.03460478410124779,
      0.03886144608259201,
      -0.05311279743909836,
      -0.03760213777422905,
      0.013861995190382004,
      0.0044913142919540405,
      0.013215151615440845,
      -7.703684968873858e-05,
      0.03719017282128334,
      0.0014620261499658227,
      0.03233044221997261,
      0.05069375038146973,
      -0.020646775141358376,
      0.02838459610939026,
      0.08001929521560669,
      -0.005324064753949642,
      0.025637701153755188,
      -0.07823271304368973,
      -0.011828768067061901,
      0.116705521941185,
      -0.11690717190504074,
      -0.019936658442020416,
      -0.023368090391159058,
      0.02540362998843193,
      0.04269161820411682,
      -0.029194137081503868,
      0.047120023518800735,
      0.020614206790924072,
      -0.07928857952356339,
      0.053314123302698135,
      -0.02412262186408043,
      0.007795205805450678,
      -0.04022956266999245,
      0.05572981387376785,
      -0.08990602940320969,
      0.008328570984303951,
      0.050497498363256454,
      -0.05397826433181763,
      -0.018010156229138374,
      -0.04344628006219864,
      0.0388169139623642,
      0.03833110257983208,
      0.03441600874066353,
      0.026446320116519928,
      0.04583284258842468,
      -0.0011946521699428558,
      0.006689299363642931,
      -0.007755811791867018,
      0.04901622235774994,
      0.030705280601978302,
      -0.06737086176872253,
      0.02878662571310997,
      0.03010512888431549,
      0.0262016411870718,
      -0.03149375319480896,
      -0.013479751534759998,
      0.08449951559305191,
      -0.043792691081762314,
      -0.0010205056751146913,
      0.09495751559734344,
      -0.004716940689831972,
      -0.01827635057270527,
      0.0048033492639660835,
      -0.059131015092134476,
      -0.08724891394376755,
      0.02341214008629322,
      -0.026654144749045372,
      0.04332766681909561,
      -0.016750430688261986,
      0.0976373553276062,
      -0.0006137527525424957,
      -0.04786015301942825,
      0.05896011367440224,
      -0.07025719434022903,
      -0.0017990098567679524,
      -0.04268617555499077,
      0.09608372300863266,
      -0.014193574897944927,
      -0.030674872919917107,
      0.06229264661669731,
      0.02832247130572796,
      -0.027311701327562332,
      -0.03270053490996361,
      0.029178395867347717,
      -0.05865173414349556,
      0.00857012439519167,
      -0.07851134240627289,
      -0.09621434658765793,
      0.032048311084508896,
      -0.03189365938305855,
      0.002062225015833974,
      -0.03668009489774704,
      -0.04014867544174194,
      -0.041919756680727005,
      0.0006066658534109592,
      0.027782419696450233,
      -0.04129564389586449,
      -0.05248847231268883,
      0.011981035582721233,
      -0.06778256595134735,
      0.02230648323893547,
      -0.01617450825870037,
      -0.05528337135910988,
      0.03940794616937637,
      -0.018364110961556435,
      0.0627124086022377,
      0.012200625613331795,
      -0.02820323035120964,
      -0.0591835081577301,
      -0.05374839901924133,
      0.01068685483187437,
      0.018970075994729996,
      -0.00812256708741188,
      0.051460206508636475,
      0.029120445251464844,
      -0.06664132326841354,
      -0.02122478000819683,
      -0.012196921743452549,
      -0.0018330190796405077,
      -0.06015785038471222,
      -0.042686328291893005,
      -0.08619587868452072,
      0.019989406690001488,
      -0.07360201328992844,
      0.05248349905014038,
      0.030425114557147026,
      -0.04400867596268654,
      0.05352790653705597,
      0.016908224672079086,
      -0.028607496991753578,
      0.00572799751535058,
      -0.012375080026686192,
      0.019693247973918915,
      0.06309809535741806,
      -0.05091163516044617,
      0.048420052975416183,
      -0.0023869809228926897,
      0.00252911145798862,
      -0.002810354344546795,
      -0.06427180767059326,
      0.016093920916318893,
      0.06549791246652603,
      0.03694456070661545,
      -0.10034740716218948,
      0.02971002086997032,
      0.01950468122959137,
      -0.011843563057482243,
      -0.035639166831970215,
      0.009305158630013466,
      0.033549051731824875,
      0.02805362083017826,
      0.06319969147443771,
      -0.042017173022031784,
      -0.03562551364302635,
      -0.030049262568354607,
      -0.0064854491502046585,
      0.07168184220790863,
      -0.054848812520504,
      0.07357825338840485,
      -0.01092795841395855,
      0.09780994057655334,
      -0.056625183671712875,
      0.029868636280298233,
      -0.03218253701925278,
      -0.01654304750263691,
      -0.03256303444504738,
      -0.027316952124238014,
      -0.014577595517039299,
      -0.026163216680288315,
      0.009121504612267017,
      0.08043485879898071,
      0.017503146082162857,
      -0.04045270383358002,
      -0.07206033170223236,
      0.009734741412103176,
      0.014361221343278885,
      0.009550582617521286,
      0.007607428357005119,
      0.019388347864151,
      -0.02407819777727127,
      0.035148512572050095,
      0.004027409013360739,
      -0.010407127439975739,
      -0.02505713887512684,
      -0.08048148453235626,
      0.06783504784107208,
      0.017885606735944748,
      0.09464766830205917,
      0.024662407115101814,
      -0.07238415628671646,
      -0.025293221697211266,
      -0.06753284484148026,
      -0.02847493253648281,
      -0.01443373691290617,
      -0.10012730211019516,
      0.05344374105334282,
      0.038644686341285706,
      0.00836106576025486,
      -0.03519349917769432,
      -0.011897243559360504,
      -0.039650268852710724,
      0.07875894755125046,
      0.047114212065935135,
      -0.0354105681180954,
      -0.02936597727239132,
      0.03516516461968422,
      0.04527535289525986,
      -0.01032327488064766,
      -0.006983635947108269,
      -0.013319014571607113,
      -0.013797866180539131,
      0.005005430895835161,
      -0.06814786046743393,
      0.04027315601706505,
      -0.07162247598171234,
      0.038971878588199615,
      -0.028770674020051956,
      0.007508361712098122,
      0.10442080348730087,
      -0.03149561211466789,
      -0.009685372933745384,
      0.006222554948180914,
      -0.07901254296302795,
      0.0016202994156628847,
      0.054314494132995605,
      0.038556475192308426,
      0.068715400993824,
      -0.005785166751593351,
      0.03818806633353233,
      0.04592660441994667,
      -0.04069197177886963,
      -0.05478997528553009,
      0.024874303489923477,
      -0.051714710891246796,
      -0.05276889726519585,
      -0.00932865310460329,
      0.004340178798884153,
      0.049464985728263855,
      -0.0642525926232338,
      0.06989013403654099,
      0.023431265726685524,
      -0.06154439598321915,
      0.006923076696693897,
      -0.009753656573593616,
      0.06744974106550217,
      0.05512614548206329,
      0.023648977279663086,
      -0.029050465673208237,
      -0.029085176065564156,
      -0.03442183509469032,
      0.01540680043399334,
      0.0012610196135938168,
      -0.0028295847587287426,
      0.08688078075647354,
      -0.001960821682587266,
      -0.034277189522981644,
      0.04304985702037811,
      -0.03673017770051956,
      -0.045886535197496414,
      0.04544529318809509,
      -0.03126382827758789,
      -0.053227707743644714,
      0.007156542036682367,
      -0.049289971590042114,
      -0.026340454816818237,
      -0.0009954877896234393,
      -0.028980907052755356,
      -0.05450395867228508,
      -0.0068900203332304955,
      -0.002800285117700696,
      0.007795275188982487,
      0.04125978797674179,
      -0.024586593732237816,
      0.05360861122608185,
      -0.01881900429725647,
      0.05807763338088989,
      0.035177309066057205,
      0.0053525217808783054,
      0.05461782589554787,
      -0.038030706346035004,
      -0.048499878495931625,
      0.044209640473127365,
      0.02885742112994194,
      0.05163067951798439,
      -0.05677885189652443,
      0.0522586889564991,
      -0.0016656882362440228,
      0.017216935753822327,
      0.020766891539096832,
      -0.07668359577655792,
      -0.04003164917230606,
      -0.004299881402403116,
      0.04488402232527733,
      0.0008892291225492954,
      -0.062362343072891235,
      0.010415198281407356,
      -0.020305752754211426,
      -0.05056357383728027
    ],
    [
      -0.05271565914154053,
      -0.015812207013368607,
      -0.018694549798965454,
      0.0532706119120121,
      0.08396495878696442,
      0.02490132674574852,
      0.10484111309051514,
      -0.04240870475769043,
      -0.08364196121692657,
      -0.12056098878383636,
      0.017954563722014427,
      -0.07745465636253357,
      -0.024611659348011017,
      -0.033438872545957565,
      0.01554108690470457,
      -0.022495806217193604,
      0.03767035901546478,
      -0.00044875938328914344,
      -0.0007556543569080532,
      0.017794501036405563,
      0.0799017921090126,
      0.020358102396130562,
      -0.06132221594452858,
      0.014325092546641827,
      0.03913802653551102,
      -0.040023066103458405,
      0.03021460399031639,
      0.057470597326755524,
      0.01642289012670517,
      0.008339371532201767,
      -0.0358903631567955,
      -0.09047190845012665,
      -0.044355619698762894,
      0.03609418869018555,
      -0.040889039635658264,
      -0.04030647501349449,
      0.02525905892252922,
      0.0011576316319406033,
      0.011396241374313831,
      -0.053290583193302155,
      -0.03436048701405525,
      -0.08262034505605698,
      -0.04164579510688782,
      -0.011285996995866299,
      0.0576440766453743,
      0.03238684684038162,
      0.04878153279423714,
      0.051292479038238525,
      0.025948774069547653,
      -0.022749369964003563,
      0.044080618768930435,
      0.044988855719566345,
      -0.0765504464507103,
      -0.0026188045740127563,
      -0.047130465507507324,
      0.031484056264162064,
      0.02416459657251835,
      0.016279399394989014,
      0.0457441508769989,
      -0.019351517781615257,
      0.01813926175236702,
      -0.05219189450144768,
      0.03645249083638191,
      0.02432282269001007,
      0.020721985027194023,
      -0.02062719501554966,
      -0.0595254972577095,
      0.035417575389146805,
      0.028901612386107445,
      -0.01540509331971407,
      0.005112003069370985,
      -0.019141053780913353,
      -0.04352889582514763,
      -0.023348640650510788,
      0.015538849867880344,
      -0.006089678965508938,
      -0.07926936447620392,
      0.06226613372564316,
      -0.06689155846834183,
      0.030880499631166458,
      -0.0019259272376075387,
      -0.01518737617880106,
      0.024274498224258423,
      -0.016973266378045082,
      0.02846318669617176,
      -0.009448567405343056,
      0.05407314375042915,
      0.11247648298740387,
      0.042163267731666565,
      -0.03345919027924538,
      0.020727016031742096,
      0.03208111599087715,
      0.00903087668120861,
      0.01755686290562153,
      0.03492249548435211,
      0.05162851884961128,
      0.023569831624627113,
      0.03071887418627739,
      -0.0053026736713945866,
      0.021767636761069298,
      0.0252638328820467,
      0.017055515199899673,
      -0.032783810049295425,
      0.0018842522986233234,
      -0.06413575261831284,
      -0.048660676926374435,
      -0.032298848032951355,
      -0.00927949883043766,
      0.016760380938649178,
      -0.02100541815161705,
      0.060458600521087646,
      0.03417591750621796,
      0.03288959339261055,
      0.08893205970525742,
      -0.03253152221441269,
      -0.01984870620071888,
      -0.009805712848901749,
      -0.040041692554950714,
      -0.06852615624666214,
      0.006571663077920675,
      -0.0547986663877964,
      0.04284605383872986,
      -0.1315007507801056,
      -0.029572460800409317,
      -0.0404827706515789,
      -0.07622499018907547,
      -0.053504060953855515,
      0.05484673008322716,
      -0.02184971049427986,
      -0.05434533208608627,
      0.07465524971485138,
      0.01646052487194538,
      0.0238137599080801,
      0.07478942722082138,
      0.044854745268821716,
      0.009024062193930149,
      -0.018315507099032402,
      -0.009912179782986641,
      0.013898135162889957,
      -0.01322907768189907,
      0.061923421919345856,
      -0.023752447217702866,
      0.009939754381775856,
      -0.011059939861297607,
      -0.01160107459872961,
      0.03042105957865715,
      0.032211560755968094,
      0.05636794492602348,
      -0.030276361852884293,
      0.027740368619561195,
      0.038145072758197784,
      -0.08773037791252136,
      0.01851310394704342,
      -0.04254032298922539,
      0.005865119397640228,
      0.00682591600343585,
      0.03645123168826103,
      -0.023277653381228447,
      -0.026996398344635963,
      -0.04490822181105614,
      0.01178603246808052,
      -0.03534367308020592,
      0.058486804366111755,
      -0.02466648630797863,
      -0.04922569543123245,
      -0.012025345116853714,
      0.008379871025681496,
      -0.039173465222120285,
      -0.014898739755153656,
      0.031879425048828125,
      0.017985595390200615,
      0.029319196939468384,
      0.026883399114012718,
      -0.021828584372997284,
      0.0037020116578787565,
      0.0566738061606884,
      0.0064660548232495785,
      0.05685622617602348,
      0.07889217883348465,
      -0.010632826946675777,
      0.0006154351285658777,
      0.0012938871514052153,
      -0.004488260950893164,
      -0.02493489719927311,
      -0.05337159335613251,
      -0.0320776142179966,
      -0.00645521841943264,
      -0.021183347329497337,
      -0.0036880287807434797,
      0.08891375362873077,
      0.015697427093982697,
      0.027210088446736336,
      0.040260832756757736,
      -0.12781645357608795,
      -0.055640511214733124,
      0.05120595917105675,
      -0.01722906529903412,
      0.057648252695798874,
      -0.010357208549976349,
      0.01867533288896084,
      0.03970974311232567,
      -0.02540476992726326,
      -0.009578586556017399,
      0.00623102393001318,
      0.01605231873691082,
      0.013934670016169548,
      0.0023215136025100946,
      -0.023141248151659966,
      0.013776291161775589,
      0.07750201225280762,
      0.003436933970078826,
      -0.003319553565233946,
      -0.023242028430104256,
      -0.006417655851691961,
      0.043612342327833176,
      -0.04140040650963783,
      -0.08115720748901367,
      0.0618290901184082,
      0.03405945375561714,
      -0.02702479623258114,
      0.04964834451675415,
      0.012394566088914871,
      0.013415234163403511,
      0.04629157856106758,
      -0.016140537336468697,
      0.02449866756796837,
      0.009753932245075703,
      0.08611591160297394,
      0.026234980672597885,
      0.007536965422332287,
      0.04048649221658707,
      -0.016896774992346764,
      -0.051041129976511,
      0.01063643116503954,
      0.019197601824998856,
      0.026702959090471268,
      -0.0632566511631012,
      -0.06976302713155746,
      0.0010175538482144475,
      -0.008590461686253548,
      0.04812769591808319,
      -0.04726717248558998,
      0.028803041204810143,
      -0.06390971690416336,
      0.00694233039394021,
      -0.09690281748771667,
      -0.0037478450685739517,
      -0.028573302552103996,
      -0.07696652412414551,
      0.022809091955423355,
      0.011692133732140064,
      0.028168871998786926,
      -0.010055582970380783,
      -0.03442331776022911,
      -0.033885881304740906,
      -0.028992630541324615,
      0.035862334072589874,
      0.0376591794192791,
      -0.006781589239835739,
      0.04111096262931824,
      0.03969356045126915,
      -0.026665901765227318,
      0.034995853900909424,
      -0.04014701023697853,
      0.02531784027814865,
      -0.0001804701896617189,
      -0.0372222475707531,
      0.012644035741686821,
      0.033157363533973694,
      -0.05573098734021187,
      -0.04582256078720093,
      -0.062091656029224396,
      -0.04789523407816887,
      0.009313143789768219,
      0.055364999920129776,
      0.06257426738739014,
      -0.01954635977745056,
      -0.048375483602285385,
      0.018361588940024376,
      0.07233832031488419,
      -0.01460833940654993,
      0.007280989550054073,
      -0.0095143411308527,
      0.0023911711759865284,
      0.0020272766705602407,
      -0.01163504272699356,
      -0.017021657899022102,
      0.02211148478090763,
      0.01994495280086994,
      0.03009663335978985,
      -0.03580092266201973,
      0.006392642855644226,
      0.010480227880179882,
      -0.007748478092253208,
      -0.02366076596081257,
      -0.016588760539889336,
      -0.011665134690701962,
      0.027426164597272873,
      -0.020934730768203735,
      0.07832704484462738,
      -0.06750825047492981,
      -0.0044621690176427364,
      0.08678693324327469,
      -0.03383128345012665,
      0.06319697201251984,
      0.04048118740320206,
      0.0521271675825119,
      0.024935191497206688,
      0.05621052533388138,
      0.03545467182993889,
      0.02373083494603634,
      -0.00018770364113152027,
      0.003559916513040662,
      -0.04006394371390343,
      -0.02460000105202198,
      0.010626118630170822,
      -0.04164963588118553,
      0.011740639805793762,
      -0.04901914671063423,
      -0.03242199867963791,
      -0.030295317992568016,
      -0.03865614905953407,
      0.06610766053199768,
      0.0643642321228981,
      -0.04648467153310776,
      0.043139901012182236,
      -0.005552399903535843,
      -0.033090345561504364,
      -0.021955877542495728,
      0.08514439314603806,
      -0.01186299603432417,
      -0.039163071662187576,
      -0.08860372006893158,
      0.00417450163513422,
      -0.03135006129741669,
      0.06153830885887146,
      -0.04904508218169212,
      0.013366980478167534,
      0.0003498659934848547,
      -0.014723511412739754,
      0.022532440721988678,
      0.02123216725885868,
      0.0369090661406517,
      0.008956280536949635,
      -0.02859562262892723,
      0.017186636105179787,
      0.06725957244634628,
      0.008026761934161186,
      -0.052397891879081726,
      -0.02859812043607235,
      -0.026650413870811462,
      0.03230750560760498,
      0.056159406900405884,
      -0.025608664378523827,
      -0.010895741172134876,
      0.009915860369801521,
      0.08781533688306808,
      0.018134869635105133,
      0.0323026180267334,
      0.019103486090898514,
      0.04349665716290474,
      -0.05740893632173538,
      -0.009677216410636902,
      0.04056139290332794,
      0.05926305428147316,
      -0.03415293246507645,
      0.016056140884757042,
      -0.010752038098871708,
      -0.02075149677693844,
      -0.019010452553629875,
      0.02786468341946602,
      0.03277982026338577,
      -0.01977834664285183,
      0.05132221058011055,
      0.012121129781007767,
      -0.03905682638287544,
      0.0778571292757988,
      0.04628169536590576,
      -0.03712770715355873,
      -0.05467363819479942,
      0.05861171334981918,
      -0.011341755278408527,
      -0.027805235236883163,
      0.012120301835238934,
      0.10296590626239777,
      -0.007088103797286749,
      -0.018392158672213554,
      0.06232942268252373,
      0.006911357864737511,
      0.05334220826625824,
      0.004661389160901308,
      -0.0422096885740757,
      -0.0018011069623753428,
      -0.046086300164461136,
      -0.0010960112558677793,
      0.00769208837300539,
      0.014603563584387302,
      -0.059150710701942444,
      0.00010532484884606674,
      -0.059342119842767715,
      0.06397110968828201,
      -0.02991502545773983,
      -0.0008244159398600459,
      -0.041393671184778214,
      0.020941970869898796,
      0.09428980201482773,
      -0.026304563507437706,
      0.13398735225200653,
      -0.07552716881036758,
      0.008745871484279633,
      -0.02441398985683918,
      -0.025818947702646255,
      0.0003510712704155594,
      -0.05420601740479469,
      0.0868445411324501,
      0.002039397833868861,
      0.013238241896033287,
      -0.016755860298871994,
      0.00027933300589211285,
      -0.03736170753836632,
      0.022813618183135986,
      -0.015096458606421947,
      0.028728926554322243,
      -0.015049075707793236,
      -0.07700850069522858,
      -0.03677394241094589,
      0.03838668763637543,
      -0.022629987448453903,
      0.02850911393761635,
      -0.011184566654264927,
      0.049269214272499084,
      -0.03939748927950859,
      0.1176971048116684,
      -0.04015924781560898,
      0.0598972924053669,
      -0.04048965498805046,
      -0.0906989574432373,
      -0.0029117115773260593,
      0.002752378350123763,
      0.008608890697360039,
      -0.00567108066752553,
      -0.009523323737084866,
      0.006844670511782169,
      0.02410437911748886,
      -0.0012894232058897614,
      -0.0008899255190044641,
      -0.058094572275877,
      0.024849049746990204,
      -0.06249229982495308,
      -0.010704920627176762,
      0.17250476777553558,
      -0.04341540113091469,
      -0.1132109984755516,
      0.07156062126159668,
      0.07505763322114944,
      0.008472778834402561,
      -0.031724803149700165,
      -0.0357135571539402,
      0.00950700230896473,
      -0.028884008526802063,
      -0.04648866131901741,
      -0.01907454803586006,
      -0.008403055369853973,
      0.05012410506606102,
      0.0024575525894761086,
      -0.023738505318760872,
      -0.031468309462070465,
      0.05361746996641159,
      0.06227117404341698,
      -0.07503218203783035,
      -0.07404737919569016,
      -0.08420460671186447,
      0.036511290818452835,
      -0.11689116805791855,
      0.08966318517923355,
      0.039773743599653244,
      -0.04200206696987152,
      -0.035030048340559006,
      0.034137070178985596,
      0.007868614047765732,
      -0.0030983102042227983,
      -0.05059434100985527,
      0.0007123815594241023,
      0.00548091996461153,
      -0.03727439045906067,
      -0.00858986284583807,
      -0.011323951184749603,
      -0.06228097155690193,
      0.07532207667827606,
      0.02485218457877636,
      0.055211201310157776,
      -0.05916929244995117,
      0.038831450045108795,
      -0.02651154436171055,
      -0.007567399647086859,
      0.05541208013892174,
      0.04540155827999115,
      0.04633908346295357,
      -0.033423762768507004,
      0.07411079108715057,
      -0.014862257055938244,
      -0.04462077096104622,
      -0.06735673546791077
    ],
    [
      -0.051487281918525696,
      -0.007491751108318567,
      -0.05274886265397072,
      -0.06476906687021255,
      -0.030094508081674576,
      -0.012936806306242943,
      0.04623633623123169,
      0.06855043023824692,
      0.013768128119409084,
      -0.0020913765765726566,
      -0.01222973968833685,
      -0.04270254448056221,
      0.10396013408899307,
      0.07367444038391113,
      -0.011624413542449474,
      -0.06440212577581406,
      0.0720520094037056,
      -0.00995981227606535,
      -0.049631327390670776,
      0.017866596579551697,
      -0.0005492280470207334,
      -0.049085650593042374,
      0.0315944068133831,
      -0.04359179362654686,
      0.0097893001511693,
      0.06618621945381165,
      -0.08575902879238129,
      -0.008336583152413368,
      -0.0498790442943573,
      -0.0013108408311381936,
      0.04134003445506096,
      -0.03672615811228752,
      -0.005826870445162058,
      -0.051056817173957825,
      -0.062351472675800323,
      0.0372646227478981,
      -0.03731129691004753,
      -0.03448732569813728,
      -0.02402697317302227,
      0.011588030494749546,
      0.014694090001285076,
      0.003860108321532607,
      -0.027556179091334343,
      0.03565668314695358,
      -0.012854402884840965,
      -0.02931978926062584,
      -0.07415436208248138,
      0.008975021541118622,
      0.06497801840305328,
      -0.017936941236257553,
      0.018753811717033386,
      -0.0092350197955966,
      -0.04125260189175606,
      0.08957875519990921,
      0.031796324998140335,
      -0.03613948076963425,
      -0.050810761749744415,
      -0.08388978242874146,
      -0.0017587949987500906,
      -0.00992054957896471,
      -0.00212270044721663,
      -0.04952140152454376,
      0.03991547226905823,
      -0.0444956012070179,
      -0.08485355973243713,
      0.018383963033556938,
      -0.007043774239718914,
      0.0662996768951416,
      -0.10648899525403976,
      -0.01010019052773714,
      0.003217698074877262,
      0.040878776460886,
      -0.007645752746611834,
      0.011911904439330101,
      0.011432813480496407,
      -0.013523470610380173,
      0.03545796126127243,
      -0.057001128792762756,
      -0.012884471565485,
      -0.055002957582473755,
      0.032778460532426834,
      -0.028866568580269814,
      0.07585489004850388,
      0.037668392062187195,
      -0.02284911833703518,
      -0.03636163845658302,
      0.001316809211857617,
      0.0762941837310791,
      -0.024489235132932663,
      -0.03396289423108101,
      0.008298013359308243,
      0.016678975895047188,
      -0.09644578397274017,
      0.04333404451608658,
      -0.016015803441405296,
      -0.025337599217891693,
      0.01936478354036808,
      -0.06621372699737549,
      0.03438851609826088,
      0.033079665154218674,
      0.010973723605275154,
      -0.016237109899520874,
      -0.013405545614659786,
      0.0405220091342926,
      -0.05060132220387459,
      0.045120853930711746,
      -0.03669510409235954,
      -0.016329260542988777,
      -0.06060966104269028,
      0.033193401992321014,
      -0.03941543027758598,
      0.04733651131391525,
      -0.04991821199655533,
      0.0548090822994709,
      0.008264291100203991,
      0.011808693408966064,
      0.08371660858392715,
      -0.002397785661742091,
      -0.02033887431025505,
      -0.02448290027678013,
      -0.003255064133554697,
      -0.06398884207010269,
      -0.032684944570064545,
      0.03441983088850975,
      -0.018689438700675964,
      0.0598040446639061,
      -0.006885793525725603,
      -0.045948710292577744,
      -0.06696520745754242,
      0.023347580805420876,
      0.048294492065906525,
      0.009927168488502502,
      -0.024247122928500175,
      0.03196189925074577,
      -0.04231632128357887,
      -0.046534065157175064,
      -0.03466830775141716,
      0.02155051939189434,
      -0.007322369609028101,
      0.014621175825595856,
      -0.04253755137324333,
      0.05012828856706619,
      -0.05768672749400139,
      -0.036947574466466904,
      -0.06293463706970215,
      0.025051267817616463,
      0.025370415300130844,
      0.010884827934205532,
      -0.06380437314510345,
      -0.007802405394613743,
      -0.06354894489049911,
      0.06503333151340485,
      -0.004203095566481352,
      0.02745615877211094,
      -0.018964668735861778,
      0.09057560563087463,
      -0.023454461246728897,
      -0.021890753880143166,
      -0.06962351500988007,
      -0.05985758826136589,
      0.021445617079734802,
      -0.03894086927175522,
      -0.04154358059167862,
      -0.009547483175992966,
      0.002330248709768057,
      0.05718839541077614,
      0.0036803223192691803,
      -0.007270147558301687,
      -0.03435974940657616,
      0.007594888564199209,
      0.02413732185959816,
      -0.030697142705321312,
      0.028762828558683395,
      -0.03901718184351921,
      -0.018666226416826248,
      -0.061708949506282806,
      -0.0368124283850193,
      -0.05737878009676933,
      0.023074230179190636,
      -0.11316324025392532,
      0.003646482015028596,
      0.05145222693681717,
      0.08976765722036362,
      0.10876544564962387,
      -0.02741122990846634,
      -0.05025654286146164,
      0.021058339625597,
      -0.023472975939512253,
      -0.08286476880311966,
      -0.017821861431002617,
      -0.0632530003786087,
      -0.10974445194005966,
      0.08324822783470154,
      0.06494197249412537,
      -0.07076776772737503,
      0.04304208606481552,
      0.10314176976680756,
      0.024896880611777306,
      0.05838155746459961,
      0.09000997245311737,
      -0.06621886789798737,
      0.019835392013192177,
      0.0860554501414299,
      0.021938802674412727,
      0.05789884924888611,
      0.019597485661506653,
      0.025531379505991936,
      -0.045972734689712524,
      0.014342757873237133,
      -0.02310364693403244,
      0.0011930172331631184,
      -0.01973666250705719,
      5.090538616059348e-05,
      -0.07165291160345078,
      -0.04051830619573593,
      0.0033908323384821415,
      0.004771428182721138,
      -0.046826526522636414,
      -0.04006628692150116,
      0.031467877328395844,
      -0.006809978745877743,
      0.03990711271762848,
      0.08459938317537308,
      0.003204496344551444,
      0.020599674433469772,
      0.07838303595781326,
      -0.0049352399073541164,
      -0.062239255756139755,
      0.036601729691028595,
      -0.011098846793174744,
      0.06203896179795265,
      -0.031067341566085815,
      -0.04209863021969795,
      -0.007619326002895832,
      -0.08056334406137466,
      -0.0311258714646101,
      0.001328927231952548,
      0.01135185919702053,
      0.02095358446240425,
      0.005358911119401455,
      -0.00489919725805521,
      -0.05695682391524315,
      0.016391322016716003,
      -0.04536697641015053,
      -0.0014147115871310234,
      -0.04085678234696388,
      0.05443953350186348,
      -0.008417309261858463,
      -0.011631286703050137,
      0.07493634521961212,
      0.007193537894636393,
      -0.020121190696954727,
      0.03944180905818939,
      0.13159149885177612,
      -0.01874174177646637,
      0.021304912865161896,
      -0.06567515432834625,
      0.10258162766695023,
      -0.034423571079969406,
      0.003430501325055957,
      0.028861932456493378,
      -0.03885229676961899,
      0.021468941122293472,
      0.024289458990097046,
      -0.05784011632204056,
      -0.04364524036645889,
      -0.045383356511592865,
      0.008774634450674057,
      0.08695590496063232,
      0.023015737533569336,
      -0.04923360049724579,
      -0.07964085042476654,
      0.09007216989994049,
      0.04541167989373207,
      -0.056434448808431625,
      -0.09486351162195206,
      -0.0022785114124417305,
      0.0552646666765213,
      0.014190943911671638,
      -0.1260984241962433,
      -0.00098186987452209,
      0.025304267182946205,
      -0.026800254359841347,
      0.052731964737176895,
      0.12057263404130936,
      -0.002778667723760009,
      0.015436852350831032,
      0.022751208394765854,
      0.07950304448604584,
      -0.044013068079948425,
      -0.03492806851863861,
      -0.052938830107450485,
      -0.02138347737491131,
      -0.13271386921405792,
      -0.004980743397027254,
      0.031467076390981674,
      0.02554265223443508,
      0.028711579740047455,
      -0.018446486443281174,
      0.04352328181266785,
      0.010189452208578587,
      -0.03952811658382416,
      0.0026564220897853374,
      -0.017450394108891487,
      -0.02018808200955391,
      -0.056922558695077896,
      0.02631133235991001,
      -0.01306080725044012,
      0.058831021189689636,
      0.020745228976011276,
      0.038257431238889694,
      0.043117210268974304,
      -0.014728406444191933,
      -0.038694679737091064,
      0.0068988376297056675,
      0.017955726012587547,
      -0.055639222264289856,
      0.04621773213148117,
      -0.009569483809173107,
      0.06651514768600464,
      -0.010322242975234985,
      -0.02657223306596279,
      -0.043341223150491714,
      0.022424379363656044,
      -0.0013274041702970862,
      -0.020049050450325012,
      -0.04044046252965927,
      0.07638323307037354,
      0.01377205178141594,
      0.0765962228178978,
      -0.05387948825955391,
      0.008894754573702812,
      -0.01271879207342863,
      0.07104235887527466,
      0.022192569449543953,
      0.03251307085156441,
      -0.029819726943969727,
      0.08351524919271469,
      0.035148221999406815,
      -0.09144435077905655,
      0.020603900775313377,
      0.003258880227804184,
      0.009826385416090488,
      0.06180952116847038,
      -0.017223259434103966,
      -0.049255140125751495,
      0.011222978122532368,
      0.04195530712604523,
      0.010961382649838924,
      -0.03980619087815285,
      -0.011828583665192127,
      -0.002831872785463929,
      -0.017405817285180092,
      0.07897485047578812,
      -0.0026791198179125786,
      0.03563878312706947,
      -0.008650990203022957,
      0.006730654276907444,
      0.019218122586607933,
      0.06030522286891937,
      -0.03371365740895271,
      -0.0647006556391716,
      -0.09371613711118698,
      -0.03560655191540718,
      0.02300502359867096,
      -0.0003908812068402767,
      -0.023679938167333603,
      0.03371507674455643,
      -0.018517253920435905,
      -0.04266168549656868,
      -0.0007655335939489305,
      0.0741744413971901,
      0.02368755452334881,
      0.040131937712430954,
      -0.01496444083750248,
      0.012808313593268394,
      0.0015466921031475067,
      -0.03052602708339691,
      -0.06259100139141083,
      -0.07048683613538742,
      0.005331844091415405,
      0.003722219727933407,
      -0.08455770462751389,
      0.06261622905731201,
      -0.002100888639688492,
      0.05270497128367424,
      -0.0006215162575244904,
      -0.07689112424850464,
      -0.014111896976828575,
      0.04725348949432373,
      -0.03785567730665207,
      0.022523701190948486,
      -0.017722273245453835,
      0.052475448697805405,
      0.05584672465920448,
      -0.026508666574954987,
      0.020066898316144943,
      -0.006027678027749062,
      0.025414543226361275,
      0.007527200039476156,
      0.020701294764876366,
      0.019581511616706848,
      -0.012888073921203613,
      0.08279313147068024,
      0.07560224831104279,
      -0.02773294970393181,
      -0.07746710628271103,
      0.06688781827688217,
      -0.042498376220464706,
      0.005922875367105007,
      -0.04124994948506355,
      0.058358531445264816,
      -0.01946522481739521,
      0.009032807312905788,
      -0.011864131316542625,
      -0.04360213130712509,
      0.010241069830954075,
      0.020772850140929222,
      -0.052753984928131104,
      -0.02627522312104702,
      0.056262169033288956,
      -0.10422815382480621,
      0.05000105872750282,
      0.026569733396172523,
      -0.018711214885115623,
      -0.004305707756429911,
      0.015215164981782436,
      0.086395263671875,
      0.07504574209451675,
      -0.022756630554795265,
      0.019068822264671326,
      0.007566126994788647,
      -0.06862378865480423,
      -0.0634065493941307,
      -0.029318951070308685,
      -0.002135791117325425,
      -0.032237134873867035,
      -0.07095600664615631,
      -0.04018985480070114,
      -0.09337342530488968,
      -0.021914169192314148,
      -0.011352361179888248,
      0.05192220211029053,
      -0.04720139503479004,
      0.017506899312138557,
      -0.010062183253467083,
      0.02000138908624649,
      0.02738930843770504,
      0.034843914210796356,
      0.02495483309030533,
      0.03585448116064072,
      -0.025683898478746414,
      0.004083262290805578,
      0.025003790855407715,
      0.004015232902020216,
      0.04982427880167961,
      -0.03106888011097908,
      -0.08387972414493561,
      0.00027795214555226266,
      -0.019351759925484657,
      0.017644910141825676,
      0.02214275673031807,
      -0.05614503100514412,
      -0.057369157671928406,
      -0.08967939019203186,
      -0.0459681861102581,
      -0.06028769910335541,
      -0.07757938653230667,
      -0.06265638768672943,
      -0.04044724255800247,
      -0.04413774237036705,
      -0.060861315578222275,
      -0.002452903427183628,
      -0.04499470442533493,
      -0.0001923463132698089,
      0.10346663743257523,
      -0.006679893005639315,
      -0.03957356512546539,
      0.04286729916930199,
      0.04810984060168266,
      0.010930310934782028,
      -0.05356299877166748,
      -0.02395748719573021,
      0.023683777078986168,
      -0.002726151840761304,
      -0.045219097286462784,
      0.01621422730386257,
      0.035482633858919144,
      0.036856070160865784,
      0.039261188358068466,
      -0.028581295162439346,
      0.046401455998420715,
      0.04933255538344383,
      -0.016557997092604637,
      -0.0219603031873703,
      0.04186120256781578,
      0.055592652410268784,
      0.04763936996459961,
      0.02616332285106182,
      0.024866316467523575,
      -0.010909028351306915,
      0.02829653210937977,
      -0.02139541134238243
    ],
    [
      -0.027917642146348953,
      0.0031220372766256332,
      0.022096065804362297,
      -0.02090710587799549,
      0.029143208637833595,
      0.059920534491539,
      -0.012814770452678204,
      0.04676154628396034,
      -0.020574064925312996,
      -0.04359778016805649,
      -0.058073632419109344,
      -0.01707291044294834,
      0.05833165720105171,
      0.012331564910709858,
      -0.03682443127036095,
      0.009550672955811024,
      0.010591496713459492,
      0.0566130205988884,
      0.026894833892583847,
      -0.05048217251896858,
      -0.011549428105354309,
      -0.02079611085355282,
      0.04293910786509514,
      -0.09099622070789337,
      0.011915402486920357,
      -0.002359955105930567,
      0.1059553399682045,
      -0.04560054466128349,
      0.05466041713953018,
      0.01211165264248848,
      -0.05803779140114784,
      -0.013173037208616734,
      0.03752119466662407,
      0.09129295498132706,
      -0.014475737698376179,
      -0.0435597226023674,
      0.0610683374106884,
      -0.023382071405649185,
      -0.00032650207867845893,
      -0.011622676625847816,
      0.0013412402477115393,
      0.004309128504246473,
      -0.04386027902364731,
      0.014495358802378178,
      -0.05412719026207924,
      -0.04158858582377434,
      0.030835777521133423,
      -0.003729789284989238,
      0.03276791051030159,
      -0.00975341908633709,
      0.05512750893831253,
      -0.07323036342859268,
      -0.006712393369525671,
      0.028504030779004097,
      0.04292752593755722,
      0.07482725381851196,
      0.09082649648189545,
      0.04212772846221924,
      0.04027426987886429,
      -0.11540067195892334,
      0.06738340854644775,
      -0.015523748472332954,
      0.06113504618406296,
      -0.03586390241980553,
      0.029467202723026276,
      0.0457264706492424,
      0.054123785346746445,
      -0.025341400876641273,
      -0.017005231231451035,
      -0.06965294480323792,
      -0.03337989002466202,
      0.02209910750389099,
      0.01583573967218399,
      -0.009985782206058502,
      0.011304046027362347,
      -0.0074431984685361385,
      -0.014100467786192894,
      -0.08786017447710037,
      -0.052572041749954224,
      0.03346477821469307,
      -0.007910007610917091,
      -0.11345573514699936,
      0.016350578516721725,
      0.06335887312889099,
      0.07061240822076797,
      0.03314071148633957,
      0.026939745992422104,
      0.022588202729821205,
      0.005278991535305977,
      0.04693359136581421,
      -0.09780066460371017,
      -0.014474541880190372,
      -0.040452491492033005,
      0.014561926014721394,
      0.0818832740187645,
      0.0303508173674345,
      -0.01085001602768898,
      -0.04826637730002403,
      0.006270824000239372,
      0.05468202754855156,
      -0.03133165091276169,
      0.01748061366379261,
      0.03425014019012451,
      0.040150053799152374,
      0.020191313698887825,
      -0.03637496009469032,
      0.035592202097177505,
      0.007815600372850895,
      -0.016304796561598778,
      -0.008451608009636402,
      0.015874983742833138,
      0.04308975115418434,
      -0.023291893303394318,
      0.009774957783520222,
      0.0025485914666205645,
      -0.05218612775206566,
      -0.04398137703537941,
      0.03989225998520851,
      0.05464862287044525,
      -0.03311116620898247,
      -0.011845703236758709,
      -0.06919074058532715,
      0.03165559098124504,
      0.04462418705224991,
      -0.060206059366464615,
      0.06122840940952301,
      -0.03207215666770935,
      -0.06928117573261261,
      0.04085149988532066,
      0.09601041674613953,
      0.012108881957828999,
      -0.02366727404296398,
      -0.014229358173906803,
      -0.05195791646838188,
      -0.010377664119005203,
      -0.043898891657590866,
      0.03991178050637245,
      -0.012847415171563625,
      0.0375131294131279,
      -0.030991243198513985,
      -0.0583355687558651,
      -0.06160706654191017,
      0.08109311014413834,
      0.058947451412677765,
      0.044421643018722534,
      -0.022989865392446518,
      0.04569494351744652,
      -0.005270921625196934,
      -0.011448421515524387,
      -0.003603581106290221,
      0.026412291452288628,
      0.038452256470918655,
      -0.04391760379076004,
      0.011092912405729294,
      0.025100693106651306,
      -0.021333985030651093,
      0.048286836594343185,
      -0.04134669899940491,
      0.05396091938018799,
      0.0492914654314518,
      0.0005820737569592893,
      0.03501084819436073,
      -0.047580309212207794,
      0.036772847175598145,
      0.06954507529735565,
      0.004451486747711897,
      -0.015083497390151024,
      -0.009038794785737991,
      -0.012529436498880386,
      0.03453989699482918,
      -0.01630772091448307,
      -0.014021394774317741,
      -0.02208702079951763,
      -0.017946284264326096,
      0.06881232559680939,
      -0.09101258218288422,
      0.12365733087062836,
      0.0934472307562828,
      0.008698574267327785,
      -0.07008103281259537,
      0.010721172206103802,
      -0.02914651297032833,
      -0.030337728559970856,
      -0.03364374116063118,
      0.004967673681676388,
      -0.09657926857471466,
      0.01930049993097782,
      0.00593196926638484,
      0.06174475699663162,
      -0.010978179052472115,
      -0.01932487264275551,
      -0.04553130641579628,
      0.02118537761271,
      0.014297760091722012,
      -0.00914266612380743,
      0.0028261097613722086,
      0.006453213281929493,
      0.016826538369059563,
      0.013936230912804604,
      0.010308929719030857,
      0.0188787542283535,
      -0.027345804497599602,
      -0.06572012603282928,
      0.04056358337402344,
      -0.012631822377443314,
      0.0906718298792839,
      -0.003948715049773455,
      -0.025766339153051376,
      0.059037741273641586,
      -0.05593303591012955,
      -0.0163775272667408,
      0.05486522242426872,
      -0.0276297926902771,
      -0.06435540318489075,
      -0.019311431795358658,
      -0.07689765840768814,
      0.026101281866431236,
      0.00273311254568398,
      -0.01906404457986355,
      -0.005128222052007914,
      0.020456455647945404,
      -0.012851161882281303,
      -0.006050480529665947,
      0.0371428020298481,
      0.03657790645956993,
      -0.0011691581457853317,
      0.03651752695441246,
      0.013160779140889645,
      0.019842777401208878,
      0.0332016758620739,
      -0.020888539031147957,
      0.01800839975476265,
      0.03758658841252327,
      0.01612851396203041,
      0.03945458307862282,
      0.014482601545751095,
      -0.04904015362262726,
      0.08104033023118973,
      -0.08175947517156601,
      0.030296361073851585,
      -0.036408502608537674,
      0.051158059388399124,
      0.003430615644901991,
      0.09555241465568542,
      -0.027844861149787903,
      -0.04167919605970383,
      -0.0030111747328191996,
      -0.11808206140995026,
      0.012702208943665028,
      -0.006674501113593578,
      -0.03307963162660599,
      -0.04079926386475563,
      -0.005240174476057291,
      0.013293868862092495,
      -0.06223161891102791,
      -0.0029044989496469498,
      0.025507448241114616,
      0.031031331047415733,
      -0.014156438410282135,
      0.01936027966439724,
      -0.005314510315656662,
      0.10494846105575562,
      -0.0471753254532814,
      -0.018068701028823853,
      0.08053044229745865,
      0.03913762420415878,
      0.01691352017223835,
      -0.08150307089090347,
      -0.06283774226903915,
      -0.04921137914061546,
      0.008611478842794895,
      -0.07708694040775299,
      -0.0841323658823967,
      0.014898737892508507,
      0.07625986635684967,
      -0.020789120346307755,
      -0.034220557659864426,
      -0.04934132844209671,
      0.034278035163879395,
      0.02688656374812126,
      0.022306201979517937,
      0.026164958253502846,
      0.010498039424419403,
      -0.03519270569086075,
      0.031093791127204895,
      0.04807431250810623,
      0.024555029347538948,
      0.07146434485912323,
      -0.042671795934438705,
      -0.013627490028738976,
      -0.04962180182337761,
      0.01324552483856678,
      -0.03908388316631317,
      -0.03944533318281174,
      0.05734389275312424,
      0.035961829125881195,
      -0.0037446203641593456,
      0.06089641526341438,
      0.026907574385404587,
      0.03564237803220749,
      -0.044983427971601486,
      -0.00582960294559598,
      0.007515176199376583,
      -0.06412187963724136,
      -0.04437725245952606,
      -0.043863482773303986,
      -0.014623216353356838,
      -0.019167382270097733,
      -0.07539773732423782,
      0.0452948734164238,
      0.05264334753155708,
      0.031789619475603104,
      -0.03228097781538963,
      0.05592947453260422,
      0.060995686799287796,
      0.07096835970878601,
      0.0028999762143939734,
      0.025050662457942963,
      -0.015975328162312508,
      0.0785374641418457,
      -0.06253969669342041,
      0.009927571751177311,
      -0.10557667911052704,
      -0.04805756360292435,
      -0.09566488862037659,
      0.041269298642873764,
      0.043825533241033554,
      -0.001871586195193231,
      -0.03485020995140076,
      -0.03408021107316017,
      -0.024876601994037628,
      0.011316066607832909,
      0.1050155907869339,
      0.06986016035079956,
      -0.02508101798593998,
      0.023186711594462395,
      -0.07647793740034103,
      0.001750102499499917,
      0.06681647151708603,
      0.05017983540892601,
      0.06317973136901855,
      0.06614060699939728,
      -0.0035244408063590527,
      -0.04462475702166557,
      0.04078434035181999,
      0.06785314530134201,
      0.033627867698669434,
      0.06425963342189789,
      -0.0640004351735115,
      -0.001642097719013691,
      0.06781116873025894,
      -0.05552925541996956,
      -0.011155824176967144,
      -0.05328824743628502,
      -0.016456808894872665,
      -0.018900994211435318,
      0.0321173220872879,
      0.012069441378116608,
      0.04007069393992424,
      0.03276251256465912,
      0.00530153326690197,
      -0.1089501902461052,
      0.016263503581285477,
      0.05767779052257538,
      0.05162582919001579,
      0.027003690600395203,
      0.014272882603108883,
      -0.030528252944350243,
      -0.011262494139373302,
      0.10589784383773804,
      0.04121571034193039,
      -0.0068362196907401085,
      0.10118336230516434,
      0.06013231351971626,
      0.06087784096598625,
      0.08988635241985321,
      -0.04745418205857277,
      -0.011030128225684166,
      -0.010682303458452225,
      0.07231111824512482,
      -0.06235711649060249,
      -0.08127903938293457,
      0.027729429304599762,
      -0.024979067966341972,
      -0.025140751153230667,
      0.011310010217130184,
      -0.008926157839596272,
      0.015337568707764149,
      0.05163634568452835,
      0.04689149558544159,
      -0.010044930502772331,
      0.009472706355154514,
      0.02868521586060524,
      0.03239981830120087,
      -0.0579674132168293,
      0.07986266165971756,
      0.07753829658031464,
      -0.013116996735334396,
      -0.014590376056730747,
      0.006060833111405373,
      0.004104687832295895,
      0.025468051433563232,
      -0.0034445428755134344,
      0.0641329437494278,
      0.0009708439465612173,
      0.17451083660125732,
      0.039973512291908264,
      0.02903570979833603,
      -0.04130396619439125,
      0.05264391005039215,
      0.01976432278752327,
      -0.013095851987600327,
      -0.02442115731537342,
      0.03240855410695076,
      -0.007230385206639767,
      0.02133171260356903,
      -0.0845494195818901,
      -0.04885299503803253,
      -0.056683506816625595,
      -0.018008891493082047,
      0.05180216208100319,
      -0.010629482567310333,
      -0.0700380876660347,
      0.0859406515955925,
      0.004708495456725359,
      -0.11571101099252701,
      -0.06562791764736176,
      0.0008542730938643217,
      -0.006111589260399342,
      0.006947452202439308,
      -0.0526692233979702,
      0.03998623788356781,
      -0.0423821359872818,
      0.0697169378399849,
      -0.01680849678814411,
      0.011180038563907146,
      -0.04431119188666344,
      0.0581461638212204,
      0.010839355178177357,
      0.035915348678827286,
      0.018672287464141846,
      0.0002214856503996998,
      -0.0714908242225647,
      0.07318521291017532,
      0.00955644529312849,
      0.04685674607753754,
      -0.025580858811736107,
      0.07813960313796997,
      -0.050710279494524,
      -0.16064545512199402,
      0.053080108016729355,
      -0.024282582104206085,
      -0.054666291922330856,
      0.053964465856552124,
      0.026361748576164246,
      0.01578075811266899,
      0.04667261242866516,
      0.01228426769375801,
      0.037049341946840286,
      0.03334902599453926,
      -0.04854902997612953,
      0.00015641884237993509,
      -0.020272957161068916,
      0.02578124776482582,
      0.0887349471449852,
      -0.0009810093324631453,
      -0.005461948458105326,
      -0.014102710410952568,
      0.011759856715798378,
      0.02951650321483612,
      -0.04313505440950394,
      0.044307559728622437,
      -0.029482094570994377,
      -0.0796455517411232,
      -0.036032747477293015,
      -0.06107339262962341,
      0.09405262768268585,
      -0.05578253045678139,
      0.026201281696558,
      -0.06137678399682045,
      0.07152773439884186,
      0.010709372349083424,
      -0.05101693794131279,
      -0.01281113363802433,
      -0.016260329633951187,
      0.011065681464970112,
      -0.004235295113176107,
      -0.04380388557910919,
      0.030977945774793625,
      0.051066432148218155,
      -0.029843876138329506,
      0.03478666767477989,
      0.034580983221530914,
      0.020691683515906334,
      -0.01925656385719776,
      0.06803203374147415,
      -0.02921980991959572,
      -0.05732596665620804,
      0.06964488327503204,
      0.010489510372281075,
      -0.06815581023693085,
      0.04711683467030525,
      -0.05501201003789902
    ],
    [
      -0.10808867961168289,
      -0.02807432971894741,
      0.015479945577681065,
      -0.009428300894796848,
      -0.06585953384637833,
      0.08809611946344376,
      -0.04731299728155136,
      0.029777072370052338,
      -0.01427630614489317,
      -0.0025158219505101442,
      0.010671603493392467,
      0.03295525908470154,
      0.030789067968726158,
      0.009464954026043415,
      -0.034980449825525284,
      -0.04452870413661003,
      -0.00041715631959959865,
      -0.03653229773044586,
      0.028439879417419434,
      -0.04584180936217308,
      0.0024022189900279045,
      -0.04722918942570686,
      0.046168453991413116,
      -0.1170070692896843,
      0.05163245648145676,
      -0.04832609370350838,
      -0.06324335187673569,
      -0.03985016420483589,
      0.025396794080734253,
      0.042168665677309036,
      -0.05064719170331955,
      0.07915987819433212,
      0.021277181804180145,
      -0.03040309250354767,
      -0.01118673849850893,
      0.04590695723891258,
      0.021398212760686874,
      0.0073959119617938995,
      0.017381200566887856,
      -0.026848478242754936,
      -0.08280106633901596,
      0.12774619460105896,
      -0.0020034569315612316,
      0.010336698032915592,
      -0.04146624729037285,
      0.028670772910118103,
      0.05028471350669861,
      -0.05522894114255905,
      -0.08731725066900253,
      0.03666933625936508,
      0.02958531863987446,
      0.06292089819908142,
      0.021425746381282806,
      0.0020798342302441597,
      0.006805481389164925,
      -0.029935210943222046,
      -0.03980951011180878,
      0.0358707420527935,
      0.05564736947417259,
      -0.01732134073972702,
      -0.04609054699540138,
      -0.01366789173334837,
      0.01587649993598461,
      0.01993159018456936,
      -0.028512315824627876,
      0.01615847833454609,
      -0.019506946206092834,
      0.08515650033950806,
      0.03575744479894638,
      0.009427966549992561,
      0.12108840048313141,
      0.014297589659690857,
      -0.009538132697343826,
      -0.017084743827581406,
      0.026771945878863335,
      0.012133574113249779,
      -0.03217490389943123,
      0.06786150485277176,
      0.0896390974521637,
      0.026758268475532532,
      0.01176666934043169,
      -0.06889357417821884,
      -0.06650672107934952,
      0.0621342658996582,
      -0.0020307789091020823,
      0.03303467109799385,
      0.014585715718567371,
      -0.07548923790454865,
      -0.06107261776924133,
      -0.0010158326476812363,
      0.09390939772129059,
      4.8671845434000716e-05,
      -0.08613644540309906,
      -0.017416754737496376,
      -0.009299724362790585,
      -0.06547382473945618,
      0.06492383033037186,
      -0.08696439862251282,
      -0.01967371255159378,
      -0.03903936967253685,
      0.0637429729104042,
      -0.03875434026122093,
      0.000427051680162549,
      0.050761379301548004,
      -0.03072580322623253,
      -0.12576480209827423,
      -0.0234130397439003,
      0.011653988622128963,
      -0.008199053816497326,
      -0.1371208280324936,
      -0.008244260214269161,
      0.025432782247662544,
      0.02860305644571781,
      0.048680368810892105,
      0.019499339163303375,
      0.038114454597234726,
      -0.013926493003964424,
      -0.011989965103566647,
      -0.1266157180070877,
      -0.03897835686802864,
      0.08604746311903,
      -0.052624598145484924,
      -0.031799450516700745,
      0.07434062659740448,
      -0.03713765740394592,
      -0.018596382811665535,
      0.0023607565090060234,
      -0.016618549823760986,
      0.0003907043719664216,
      0.04405876621603966,
      -0.013359199278056622,
      -0.030510708689689636,
      0.08918444812297821,
      -0.012325752526521683,
      -0.07549718767404556,
      -0.13525442779064178,
      -0.011540084145963192,
      0.007201614324003458,
      -0.067650705575943,
      0.00826774351298809,
      -0.05174737423658371,
      0.004765174351632595,
      -0.02090561017394066,
      -0.05876638740301132,
      -0.009511694312095642,
      0.024620678275823593,
      0.04672468826174736,
      -0.011495459824800491,
      0.012007005512714386,
      0.03269267827272415,
      0.05033780261874199,
      -0.0837077647447586,
      -0.02723069116473198,
      0.005339422728866339,
      0.08130940794944763,
      0.06174912303686142,
      -0.005432672332972288,
      0.020607689395546913,
      -0.07740745693445206,
      -0.000900624378118664,
      -0.05592906102538109,
      0.0234992578625679,
      0.02181519940495491,
      0.020551737397909164,
      0.01758967526257038,
      -0.03303780034184456,
      -0.012544583529233932,
      0.012292854487895966,
      0.021464180201292038,
      -0.017477424815297127,
      -0.004532904829829931,
      0.05655127391219139,
      -0.08209068328142166,
      0.06555061042308807,
      0.029745813459157944,
      0.029097316786646843,
      0.02589663863182068,
      -0.004808420315384865,
      0.0034769142512232065,
      0.021410848945379257,
      0.0348331592977047,
      0.023365799337625504,
      -0.06646732240915298,
      -0.07890423387289047,
      0.011902461759746075,
      -0.06396399438381195,
      -0.007139838300645351,
      0.03802800923585892,
      0.015957007184624672,
      0.023341815918684006,
      0.024212701246142387,
      -0.033191584050655365,
      0.01124514639377594,
      -0.09533193707466125,
      -0.054898686707019806,
      0.032178524881601334,
      -0.08445427566766739,
      -0.0058459811843931675,
      -0.011184683069586754,
      -0.025702225044369698,
      0.003369920654222369,
      -0.0022227275185287,
      0.05661134421825409,
      -0.008550432510674,
      0.0030908226035535336,
      -0.0035582331474870443,
      -0.0010586577700451016,
      0.06221224367618561,
      0.022837694734334946,
      -0.10005804151296616,
      0.04311862960457802,
      -0.12291847914457321,
      -0.018005918711423874,
      0.09207531064748764,
      0.04476427659392357,
      -0.029640154913067818,
      0.03811701387166977,
      -0.03437383472919464,
      -0.07633598893880844,
      0.04054678976535797,
      -0.008466404862701893,
      -0.05762072280049324,
      -0.014800934121012688,
      0.09601908922195435,
      0.06728146225214005,
      -0.051801323890686035,
      -0.04259659722447395,
      -0.05097031593322754,
      -0.004886670969426632,
      0.018110351637005806,
      0.02417491190135479,
      -0.014554531313478947,
      0.080128513276577,
      -0.152698814868927,
      -0.010561509057879448,
      -0.011864927597343922,
      0.019186271354556084,
      0.006433304864913225,
      -0.018323346972465515,
      -0.023731056600809097,
      -0.07502511143684387,
      -0.016712043434381485,
      -0.025284692645072937,
      0.03376234322786331,
      -0.07509531080722809,
      0.012288336642086506,
      0.0014092028141021729,
      0.000822911795694381,
      -0.015344827435910702,
      0.09972621500492096,
      0.02242470718920231,
      0.00010997614299412817,
      -0.010742156766355038,
      -0.08859317749738693,
      -0.008904917165637016,
      -0.004511065781116486,
      0.08365243673324585,
      0.058550115674734116,
      0.019242646172642708,
      0.01640457659959793,
      0.0017856464255601168,
      -0.015556705184280872,
      -0.010873496532440186,
      0.025638757273554802,
      0.056636661291122437,
      -0.0674344003200531,
      0.007267309818416834,
      0.017203912138938904,
      0.04436732456088066,
      0.0012614327715709805,
      -0.03678852692246437,
      0.04471028223633766,
      -0.08177206665277481,
      0.0817125216126442,
      0.03911773860454559,
      -0.02136414684355259,
      0.012488113716244698,
      -0.012435718439519405,
      0.08175802230834961,
      0.027119264006614685,
      -0.018126314505934715,
      -0.027452116832137108,
      0.041529782116413116,
      0.0175313837826252,
      -0.01771216280758381,
      0.02622584067285061,
      -0.021761393174529076,
      -0.01498173177242279,
      0.05958342179656029,
      -0.06694138795137405,
      -0.0754673182964325,
      -0.0034085987135767937,
      -0.020926911383867264,
      -0.0024768190924078226,
      -0.006268209777772427,
      -0.04849051684141159,
      -0.0360194556415081,
      0.036107342690229416,
      0.009197681210935116,
      0.015037162229418755,
      0.043882280588150024,
      -0.05222226306796074,
      0.001482949242927134,
      0.007480226457118988,
      0.035885944962501526,
      0.05762945115566254,
      -0.0022901061456650496,
      -0.0211635772138834,
      -0.0071431915275752544,
      -0.02477455697953701,
      0.04331545531749725,
      0.008305133320391178,
      -0.05224141851067543,
      0.0009086118079721928,
      0.02179456688463688,
      0.08866114914417267,
      0.11863360553979874,
      0.027980443090200424,
      -0.00038007559487596154,
      0.056828390806913376,
      0.0685381069779396,
      -0.006292261183261871,
      -0.043574340641498566,
      -0.01348851341754198,
      -0.10116421431303024,
      -0.03356504812836647,
      0.11578907072544098,
      0.024880515411496162,
      0.03766399994492531,
      0.03705941513180733,
      -0.03235911577939987,
      0.05923392251133919,
      0.04074402526021004,
      -0.008844271302223206,
      0.007549453526735306,
      0.03061184659600258,
      0.009451036341488361,
      0.026738345623016357,
      -0.006740373559296131,
      0.00491443183273077,
      -0.04207768663764,
      -0.09129222482442856,
      0.018137376755475998,
      -0.03420519456267357,
      0.10581580549478531,
      0.021660739555954933,
      -0.03096911869943142,
      0.0678168311715126,
      0.007384594064205885,
      -0.11512820422649384,
      0.006596075836569071,
      -0.04180992394685745,
      -0.02746976912021637,
      0.033966075628995895,
      -0.06463377922773361,
      -0.056380223482847214,
      0.0430450513958931,
      0.0015290329465642571,
      0.028321027755737305,
      -0.00144777016248554,
      -0.0006803395226597786,
      0.08501316606998444,
      -0.03691423311829567,
      0.05022338777780533,
      0.008126779459416866,
      -0.025289714336395264,
      0.010221468284726143,
      0.04201547056436539,
      0.03522070124745369,
      -0.040051884949207306,
      0.007215832360088825,
      0.04474722966551781,
      -0.004682884085923433,
      0.0315389521420002,
      -0.021459611132740974,
      0.09233500063419342,
      -0.003912636544555426,
      -0.029344571754336357,
      -0.07841058075428009,
      0.04154922813177109,
      0.042025964707136154,
      -0.011058596894145012,
      -0.028879867866635323,
      0.05533207207918167,
      0.06325247883796692,
      -0.03533286973834038,
      0.02366221509873867,
      -0.028230197727680206,
      0.0401461236178875,
      0.036321405321359634,
      0.0238802470266819,
      0.034977540373802185,
      0.07781684398651123,
      0.07633952796459198,
      0.06565037369728088,
      0.008174747228622437,
      -0.04574134945869446,
      0.007202633656561375,
      0.06422777473926544,
      0.04242676496505737,
      -0.03188832849264145,
      -0.04741859808564186,
      -0.06336890906095505,
      -0.012142137624323368,
      0.020786061882972717,
      0.05127135291695595,
      0.056431837379932404,
      0.008440527133643627,
      0.020129643380641937,
      0.060076359659433365,
      0.02462250366806984,
      0.04231652617454529,
      0.030303511768579483,
      -0.060617413371801376,
      -0.05633773282170296,
      0.02271159365773201,
      0.024644548073410988,
      0.04479437693953514,
      0.05198539420962334,
      -0.022948892787098885,
      0.01023009605705738,
      0.04266689717769623,
      0.004345267545431852,
      -0.07877399772405624,
      0.02003525011241436,
      0.09028416126966476,
      -0.05079573765397072,
      -0.00864769984036684,
      0.14617598056793213,
      -0.0881459042429924,
      0.004701406694948673,
      0.05207616835832596,
      -0.16189008951187134,
      -0.004262215457856655,
      0.05300762504339218,
      -0.03739527240395546,
      -0.033107686787843704,
      0.06119122356176376,
      0.12126787006855011,
      -0.018883168697357178,
      0.04946883022785187,
      0.006530603859573603,
      -0.007721155881881714,
      -0.09684139490127563,
      0.012505896389484406,
      -0.010141837410628796,
      -0.08686702698469162,
      -0.03569122031331062,
      -0.11300218850374222,
      -0.0507984459400177,
      0.021195154637098312,
      0.020009981468319893,
      -0.027429278939962387,
      -0.037553735077381134,
      0.04834391921758652,
      0.10811158269643784,
      0.006061480846256018,
      0.07224972546100616,
      0.0419231541454792,
      -0.08339167386293411,
      -0.029927324503660202,
      0.013697014190256596,
      -0.006958783604204655,
      0.011759848333895206,
      0.007498240564018488,
      -0.08516034483909607,
      -0.0871545821428299,
      0.0024191311094909906,
      -0.0269156526774168,
      -0.04408955201506615,
      -0.01306264940649271,
      0.034632354974746704,
      -0.025459986180067062,
      -0.10643316805362701,
      0.033513303846120834,
      0.006430492270737886,
      -0.060364723205566406,
      -0.01967514306306839,
      -0.010890951380133629,
      -0.011987417005002499,
      0.0004579598898999393,
      -0.0505651980638504,
      -0.05532287061214447,
      0.0783863365650177,
      -0.03119061514735222,
      0.013391193002462387,
      -0.01392863318324089,
      -0.03522896021604538,
      -0.020008128136396408,
      -0.01924910582602024,
      0.05372997373342514,
      -0.045081768184900284,
      0.008398488163948059,
      0.01748311147093773,
      0.009896442294120789,
      0.08132342994213104,
      -0.04552029073238373,
      -0.008741253055632114,
      -0.012907855212688446,
      -0.026347804814577103,
      0.09806696325540543,
      0.009272096678614616,
      -0.011546486057341099
    ],
    [
      -0.06538090109825134,
      -0.03139055147767067,
      -0.016124246641993523,
      0.07855253666639328,
      -0.013555094599723816,
      0.015007543377578259,
      0.02609558030962944,
      -0.031060773879289627,
      -0.007250139489769936,
      0.005194679368287325,
      0.04357282072305679,
      0.019486434757709503,
      0.07500291615724564,
      -0.03569912537932396,
      -0.029214583337306976,
      -0.04579515755176544,
      -0.06287355720996857,
      -0.03582529351115227,
      0.01972964219748974,
      -0.01698567159473896,
      0.011098513379693031,
      0.0182473286986351,
      0.02933427505195141,
      0.02197788842022419,
      -0.002049970906227827,
      -0.021119466051459312,
      0.10956143587827682,
      -0.051813121885061264,
      -0.05531247332692146,
      -0.004854175262153149,
      -0.011016042903065681,
      -0.06571250408887863,
      0.04915836080908775,
      -0.0006027593044564128,
      -0.003091821214184165,
      0.05971929803490639,
      -0.021568702533841133,
      0.003241698257625103,
      0.004873931407928467,
      -0.008574236184358597,
      -0.031969159841537476,
      -0.031043969094753265,
      0.09999252110719681,
      -0.01432937104254961,
      -0.00861158687621355,
      0.023452740162611008,
      0.045304831117391586,
      0.007073550950735807,
      0.08225629478693008,
      -0.02795911580324173,
      0.0279754139482975,
      -0.01237894780933857,
      0.019813260063529015,
      0.04940285533666611,
      0.047295939177274704,
      -0.07390080392360687,
      0.07707314193248749,
      -0.00017082245904020965,
      -0.05770859867334366,
      -0.06248703598976135,
      -0.09597887098789215,
      -0.026851138100028038,
      -0.009850077331066132,
      -0.042655959725379944,
      -0.061696793884038925,
      -0.08717238903045654,
      -0.02111540362238884,
      -0.01765882782638073,
      -0.030372465029358864,
      0.007065474987030029,
      0.0048710061237216,
      -0.04682479798793793,
      0.01302158460021019,
      -0.020329337567090988,
      -0.006490845233201981,
      -0.058221541345119476,
      0.04601640999317169,
      0.03372727334499359,
      0.00544116273522377,
      0.10322833061218262,
      -0.04648355022072792,
      0.07827448099851608,
      -0.006705837789922953,
      -0.03520630672574043,
      -0.05598333477973938,
      -0.013553045690059662,
      0.05865968391299248,
      0.048718079924583435,
      -0.044628553092479706,
      -0.011873521842062473,
      0.03059442900121212,
      -0.005460812710225582,
      -0.047638919204473495,
      0.044003233313560486,
      -0.0658789798617363,
      0.0023036999627947807,
      0.009183456189930439,
      -0.04427415877580643,
      0.010671937838196754,
      -0.034403592348098755,
      -0.05036567896604538,
      0.04351020231842995,
      -0.006495155394077301,
      -0.10970558971166611,
      0.0016179142985492945,
      -0.03808129206299782,
      -0.03121218830347061,
      0.03681588172912598,
      -0.07690883427858353,
      -0.08220479637384415,
      -0.04346625134348869,
      -0.05275605991482735,
      -0.03575723245739937,
      -0.015369579195976257,
      -0.04684694483876228,
      -0.006436341907829046,
      -0.02829926088452339,
      -0.040987562388181686,
      -0.005900776479393244,
      -0.00939430482685566,
      0.04494423046708107,
      -0.02404567413032055,
      0.03489149361848831,
      0.013673851266503334,
      0.06660640239715576,
      0.008448981679975986,
      0.03783074766397476,
      -0.10150562226772308,
      -0.02226158045232296,
      -0.023733478039503098,
      -0.011595146730542183,
      0.02861328050494194,
      -0.09663385897874832,
      -0.02693885564804077,
      -0.024487514048814774,
      -0.015208570286631584,
      -0.030863726511597633,
      -0.012657924555242062,
      0.060386430472135544,
      0.056929584592580795,
      0.034033868461847305,
      0.06108170375227928,
      -0.017164187505841255,
      -0.05040537565946579,
      0.08331488817930222,
      0.03026551380753517,
      0.12081056088209152,
      -0.008834781125187874,
      0.03375440835952759,
      0.04595649242401123,
      -0.05694762244820595,
      -0.08434825390577316,
      -0.005978272762149572,
      0.02465088851749897,
      0.043781280517578125,
      0.06971482932567596,
      0.029477179050445557,
      -0.03361610323190689,
      -0.02936733514070511,
      0.036659691482782364,
      -0.03407014533877373,
      0.00014071787882130593,
      0.041450630873441696,
      -0.0012914430117234588,
      -0.02869594283401966,
      0.0032792098354548216,
      0.02553708106279373,
      -0.03255195543169975,
      -0.003474030876532197,
      0.022175803780555725,
      -0.04467609152197838,
      -0.007142264395952225,
      -0.003768992144614458,
      -0.056259214878082275,
      -0.035888321697711945,
      0.014164293184876442,
      -0.021727966144680977,
      0.01172140147536993,
      -0.025359803810715675,
      -0.033438146114349365,
      -0.03585619106888771,
      0.06990236043930054,
      0.03245541825890541,
      -0.026864154264330864,
      -0.028127219527959824,
      -0.01965106464922428,
      0.04626859351992607,
      -0.0773753747344017,
      0.011551551520824432,
      0.0163708683103323,
      -0.02486194111406803,
      -0.011013896204531193,
      -0.02081608958542347,
      0.03557049110531807,
      0.03434215486049652,
      0.10415971279144287,
      0.0641055777668953,
      0.05724527686834335,
      -0.02239357866346836,
      0.07844801992177963,
      -0.04924076795578003,
      0.018594209104776382,
      0.01688120700418949,
      0.07020890712738037,
      -0.011004593223333359,
      -0.03788326680660248,
      0.05358138307929039,
      0.024230994284152985,
      0.009247494861483574,
      0.031092317774891853,
      -0.024365954101085663,
      -0.025783546268939972,
      0.018542800098657608,
      0.007114694453775883,
      -0.003816672833636403,
      0.009959293529391289,
      0.11303892731666565,
      -0.04732148349285126,
      -0.024996353313326836,
      -0.021334746852517128,
      -0.04560381919145584,
      -0.03449011966586113,
      -0.04732462018728256,
      0.022908039391040802,
      -0.045160923153162,
      0.053997524082660675,
      0.02968406304717064,
      -0.041082028299570084,
      0.02903669700026512,
      0.0032520312815904617,
      -0.0009440003777854145,
      0.0675475150346756,
      -0.010351067408919334,
      0.04939570650458336,
      0.00888038706034422,
      -0.007081883493810892,
      -0.06929438561201096,
      0.009347592480480671,
      0.002634989097714424,
      0.06564678251743317,
      0.004832923412322998,
      -0.023681776598095894,
      0.08537670969963074,
      0.012250528670847416,
      -0.020710129290819168,
      -0.011585225351154804,
      -0.009359846822917461,
      0.03541574254631996,
      0.09462778270244598,
      0.09805428236722946,
      -0.04170698672533035,
      -0.025543609634041786,
      0.030284833163022995,
      0.047343455255031586,
      -0.017638009041547775,
      0.011389325372874737,
      -0.06316260993480682,
      0.04395012557506561,
      0.049086347222328186,
      0.05345934256911278,
      0.032467614859342575,
      -0.1067284643650055,
      0.02648705430328846,
      0.09642336517572403,
      0.0275273397564888,
      -0.03519269451498985,
      -0.05661386623978615,
      0.0012561687035486102,
      0.053915321826934814,
      0.007445144932717085,
      0.04075385630130768,
      -0.005605794955044985,
      0.06954427063465118,
      0.00550657557323575,
      -0.007103944197297096,
      0.0154306935146451,
      -0.0015302153769880533,
      0.050106558948755264,
      0.03533653914928436,
      -0.07115281373262405,
      -0.057368796318769455,
      -0.03249579295516014,
      0.003868161467835307,
      0.03022168204188347,
      -0.028121663257479668,
      0.06131918355822563,
      -0.025998523458838463,
      0.028752867132425308,
      0.01829746551811695,
      0.029866468161344528,
      -0.008895796723663807,
      -0.021557634696364403,
      -0.04769472777843475,
      0.01509502436965704,
      0.04580254852771759,
      0.04463548585772514,
      0.017238421365618706,
      0.14864857494831085,
      -0.00013601560203824192,
      -0.03970935940742493,
      0.021587379276752472,
      0.02437722124159336,
      0.03159208968281746,
      0.07857245206832886,
      -0.008901577442884445,
      0.057276006788015366,
      0.035451266914606094,
      0.03097209893167019,
      0.010453985072672367,
      0.03296589106321335,
      0.05976613983511925,
      0.029569417238235474,
      0.07334639877080917,
      0.045802365988492966,
      -0.03820968046784401,
      0.10231322050094604,
      -0.02431158535182476,
      0.004413692746311426,
      0.029506128281354904,
      0.10382983088493347,
      -0.028634941205382347,
      -0.03369763493537903,
      -0.02211514674127102,
      -0.0818038284778595,
      -0.09795328229665756,
      0.01956239715218544,
      0.08216425031423569,
      0.018442347645759583,
      -0.011548347771167755,
      0.033725302666425705,
      -0.10735524445772171,
      0.04881638288497925,
      0.015982473269104958,
      0.0020822591613978148,
      0.002618595724925399,
      0.051972243934869766,
      0.0026247173082083464,
      0.07957350462675095,
      0.057499829679727554,
      -0.002287498442456126,
      0.008014016784727573,
      0.10570229589939117,
      0.008617415092885494,
      0.008286530151963234,
      -0.012533777393400669,
      -0.054560959339141846,
      -0.018235692754387856,
      0.008463473990559578,
      0.03315318748354912,
      0.00030620559118688107,
      0.013971776701509953,
      0.024111077189445496,
      -0.030109234154224396,
      0.030794648453593254,
      -0.03032171167433262,
      -0.09033098071813583,
      0.0210166797041893,
      -0.07359173148870468,
      -0.028519418090581894,
      -0.09149640053510666,
      -0.010570854879915714,
      -0.08072163909673691,
      0.020362762734293938,
      0.05112821236252785,
      -0.043478626757860184,
      -0.010239453054964542,
      0.03700316324830055,
      0.0077477991580963135,
      -0.109759122133255,
      0.03965069353580475,
      0.011407513171434402,
      -0.046731483191251755,
      -0.06663244217634201,
      0.05630222707986832,
      -0.026341751217842102,
      0.02118968777358532,
      0.06996460258960724,
      -0.06426810473203659,
      0.011137941852211952,
      -0.08000226318836212,
      0.07766468822956085,
      0.018488874658942223,
      -0.03086240589618683,
      0.01731215789914131,
      -0.018211975693702698,
      -0.035298604518175125,
      -0.0313318632543087,
      -0.02905578725039959,
      -0.0192718505859375,
      0.05531607195734978,
      -0.03046877682209015,
      0.046603571623563766,
      0.044053591787815094,
      0.03473770618438721,
      -0.023712052032351494,
      -0.0053685251623392105,
      0.047060541808605194,
      0.03570745885372162,
      0.0003087130025960505,
      -0.05521398037672043,
      0.02171284146606922,
      -0.07470090687274933,
      -0.008264300413429737,
      0.026216989383101463,
      0.10178258270025253,
      0.023589301854372025,
      -0.012140789069235325,
      -0.009014278650283813,
      -0.06226896867156029,
      -0.08592540770769119,
      0.056223832070827484,
      0.07059291005134583,
      -0.05039030686020851,
      -0.015340755693614483,
      -0.04190047085285187,
      0.0657496452331543,
      0.0004173463967163116,
      0.0447072796523571,
      -0.05277657508850098,
      0.012821830809116364,
      -0.016051005572080612,
      0.0004629653412848711,
      0.07793254405260086,
      -0.014454788528382778,
      -0.033037297427654266,
      -0.029230229556560516,
      -0.04865865781903267,
      0.0012885332107543945,
      0.10810432583093643,
      -0.03916970267891884,
      0.053237102925777435,
      -0.04790912941098213,
      -0.0058053843677043915,
      0.018170442432165146,
      -0.00281029031611979,
      0.003080864669755101,
      0.013020442798733711,
      0.12013332545757294,
      0.006605126429349184,
      -0.06341968476772308,
      0.07078418880701065,
      -0.01778382994234562,
      -0.06855487823486328,
      0.037502631545066833,
      -0.018828442320227623,
      -0.06999879330396652,
      0.04923981428146362,
      -0.0028438859153538942,
      0.025151602923870087,
      0.07937510311603546,
      0.10424840450286865,
      -0.007923747412860394,
      -0.04368206486105919,
      0.04094814509153366,
      -0.07586965709924698,
      0.04816144332289696,
      -0.042820464819669724,
      -0.0535612553358078,
      0.006685858126729727,
      -0.06320797652006149,
      0.011200802400708199,
      0.02082224190235138,
      -0.01017975527793169,
      -0.06137314811348915,
      0.02191891334950924,
      -0.010005928575992584,
      -0.12495166063308716,
      0.015748480334877968,
      -0.028966939076781273,
      -0.02939494326710701,
      -0.013030007481575012,
      -0.04393286257982254,
      -0.04201770946383476,
      -0.08183419704437256,
      -0.0038028210401535034,
      -0.06557124108076096,
      -0.05201410502195358,
      -0.05352446436882019,
      -0.0015328520676121116,
      -0.0791664719581604,
      -0.14160825312137604,
      -0.037186093628406525,
      0.02781040035188198,
      0.01601499505341053,
      0.026822280138731003,
      0.04367975518107414,
      -0.01034783385694027,
      0.03044472075998783,
      0.039284758269786835,
      -0.004054314456880093,
      -0.01219827588647604,
      0.0019517227774485946,
      0.039351049810647964,
      0.018827145919203758,
      -0.05983413755893707,
      -0.03808625042438507,
      0.06556671112775803,
      -0.030790280550718307,
      0.021849971264600754,
      -0.027178527787327766,
      -0.02668203040957451,
      0.0180036723613739,
      0.04790215566754341
    ],
    [
      0.055749356746673584,
      -0.028348594903945923,
      0.05880194902420044,
      -0.0003983954666182399,
      -0.008475818671286106,
      0.048321258276700974,
      0.08419111371040344,
      -0.04279189184308052,
      4.0797276597004384e-05,
      -0.008423850871622562,
      -0.003991800360381603,
      0.03349991515278816,
      0.09210871905088425,
      -0.0026728608645498753,
      -0.06570502370595932,
      0.020090147852897644,
      -0.01584695279598236,
      0.031247567385435104,
      -0.08431892096996307,
      -0.012656986713409424,
      0.05820884928107262,
      -0.0432257205247879,
      0.04409685358405113,
      -0.004631225019693375,
      0.14315612614154816,
      -0.018995225429534912,
      -0.009653893299400806,
      -0.07612423598766327,
      -0.026472169905900955,
      -0.10181142389774323,
      -0.00571049377322197,
      -0.017274783924221992,
      0.017045220360159874,
      -0.054288350045681,
      -0.021282903850078583,
      -0.11350598186254501,
      0.010307809337973595,
      0.006519594695419073,
      -0.03867962956428528,
      0.008627519942820072,
      0.02297765202820301,
      -0.08049292117357254,
      0.053421154618263245,
      0.009816624224185944,
      -0.05558213219046593,
      -0.010027583688497543,
      -0.01368408277630806,
      0.04564124718308449,
      -0.042319826781749725,
      -0.055197183042764664,
      0.020642772316932678,
      0.057501643896102905,
      -0.05220060795545578,
      0.019342541694641113,
      0.015868106856942177,
      0.07147134840488434,
      0.03383771330118179,
      -0.0415567085146904,
      0.005339602939784527,
      0.02637310139834881,
      0.02480919472873211,
      0.05134103074669838,
      -0.02417824976146221,
      -0.054575271904468536,
      0.009477255865931511,
      0.03369641304016113,
      -0.09264341741800308,
      0.0028148097917437553,
      -0.0034394997637718916,
      0.008952343836426735,
      -0.040929269045591354,
      0.0036094391252845526,
      -0.05103530362248421,
      -0.04198603704571724,
      -0.02544914186000824,
      0.032362908124923706,
      0.043763767927885056,
      -0.03760301694273949,
      0.02874644286930561,
      0.01587776653468609,
      0.019331861287355423,
      0.0402093380689621,
      -0.05781913548707962,
      -0.0018265460385009646,
      -0.010629984550178051,
      0.008638555184006691,
      0.07816082239151001,
      -0.012644646689295769,
      0.022177256643772125,
      -0.030204270035028458,
      0.04907858371734619,
      -0.017919229343533516,
      0.07472352683544159,
      -0.05220741033554077,
      -0.0012567393714562058,
      0.0018402853747829795,
      -0.05675220489501953,
      0.04262214154005051,
      0.10996146500110626,
      0.06840071082115173,
      -0.015526467934250832,
      -0.010562717914581299,
      -0.05996142327785492,
      0.11354951560497284,
      -0.03322458267211914,
      0.0002938713878393173,
      -0.043117158114910126,
      0.03632572665810585,
      0.057514388114213943,
      -0.08020011335611343,
      -0.01616906002163887,
      -0.014243482612073421,
      0.025784362107515335,
      0.09396925568580627,
      0.037446279078722,
      0.02672906406223774,
      0.028541287407279015,
      0.05027511343359947,
      -0.019481537863612175,
      0.01581081561744213,
      0.0029368118848651648,
      -0.09309346973896027,
      -0.08620232343673706,
      -0.03619464114308357,
      0.02882414124906063,
      0.034991342574357986,
      -0.008769798092544079,
      -0.015594827942550182,
      -0.055444106459617615,
      0.0017499752575531602,
      0.01203279010951519,
      -0.018910156562924385,
      -0.024612145498394966,
      -0.02841355837881565,
      0.02507856674492359,
      -0.016986167058348656,
      -0.08444537967443466,
      -0.04574120044708252,
      0.03499743342399597,
      0.014382301829755306,
      -0.03611794486641884,
      0.04242188110947609,
      -0.047722119837999344,
      -0.019497187808156013,
      -0.020613234490156174,
      0.019749799743294716,
      0.06411436200141907,
      -0.030019570142030716,
      0.032737355679273605,
      0.018574438989162445,
      0.06823155283927917,
      0.034692369401454926,
      0.001671633217483759,
      -0.011942164972424507,
      0.01677667163312435,
      -0.06051088869571686,
      0.009093280881643295,
      0.054168909788131714,
      0.0019073991570621729,
      0.0327305793762207,
      -0.012725401669740677,
      -0.0088349012658,
      -0.0024448265321552753,
      -0.021988019347190857,
      0.03484749794006348,
      0.003419853514060378,
      0.0047906022518873215,
      -0.07389746606349945,
      -0.10142633318901062,
      0.007558081299066544,
      -0.0883263498544693,
      0.04782816767692566,
      0.0028168875724077225,
      -0.016086990013718605,
      -0.09042216837406158,
      0.0960555151104927,
      -0.02585655450820923,
      -0.02601778320968151,
      0.021528027951717377,
      -0.07008686661720276,
      0.0503990538418293,
      -0.05719488859176636,
      0.04187310114502907,
      0.11075830459594727,
      0.03702458739280701,
      -0.02030298300087452,
      0.020221984013915062,
      0.06626012176275253,
      0.035094164311885834,
      -0.04021879658102989,
      -0.03642427921295166,
      0.017924297600984573,
      0.013201284222304821,
      0.031157534569501877,
      -0.022505445405840874,
      -0.029198044911026955,
      0.029426608234643936,
      0.07245243340730667,
      0.06821298599243164,
      0.04044225811958313,
      0.030948834493756294,
      -0.005224492866545916,
      -0.036640290170907974,
      0.04809064045548439,
      -0.008710929192602634,
      0.022755611687898636,
      0.017069948837161064,
      0.01767444796860218,
      -0.046904612332582474,
      -0.011908906511962414,
      -0.021372275426983833,
      -0.08130218833684921,
      -0.028272761031985283,
      -0.045670706778764725,
      0.04527631402015686,
      -0.0677151083946228,
      0.015184814110398293,
      -0.029407616704702377,
      -0.016584407538175583,
      0.10677625238895416,
      0.04422914981842041,
      0.029129888862371445,
      0.06634427607059479,
      0.029554106295108795,
      -0.012962750159204006,
      0.02058771252632141,
      -0.009231646545231342,
      -0.029572052881121635,
      0.04231114685535431,
      0.030749140307307243,
      0.01547515019774437,
      -0.014480647630989552,
      0.05559142678976059,
      -0.03351162374019623,
      0.049821678549051285,
      -0.07146705687046051,
      -0.1694573014974594,
      -0.02133430354297161,
      -0.07052245736122131,
      0.010028133168816566,
      -0.009883626364171505,
      -0.10918144881725311,
      0.016904395073652267,
      0.013023708015680313,
      -0.011287600733339787,
      -0.04291367903351784,
      -0.04115493223071098,
      -0.028166912496089935,
      -0.026644151657819748,
      -0.03665291517972946,
      0.0926482155919075,
      -0.05150408670306206,
      0.04886678606271744,
      0.007194195874035358,
      -0.09119831770658493,
      0.03281587362289429,
      -0.032074011862277985,
      0.14028596878051758,
      -0.0077665639109909534,
      0.03234533220529556,
      0.024234216660261154,
      -0.014781213365495205,
      0.030668698251247406,
      0.0021803234703838825,
      0.024116484448313713,
      -0.08883962035179138,
      0.02812553383409977,
      0.03890436142683029,
      0.0756118968129158,
      -0.06376315653324127,
      0.06342760473489761,
      -0.07543657720088959,
      -0.023944692686200142,
      0.07788919657468796,
      0.025624316185712814,
      -0.07621940970420837,
      0.002149980515241623,
      0.04134803265333176,
      0.07938054949045181,
      0.09669622778892517,
      -0.021392416208982468,
      0.0077672600746154785,
      -0.05854259803891182,
      -0.003212453331798315,
      0.02175174467265606,
      -0.02398095652461052,
      0.009758636355400085,
      0.013377569615840912,
      -0.03311668336391449,
      0.057103920727968216,
      0.011516681872308254,
      0.08581040799617767,
      0.05575373396277428,
      0.01805717870593071,
      -0.08948957175016403,
      -0.017701387405395508,
      0.01078062504529953,
      -0.014113147743046284,
      0.06720417737960815,
      -0.019498305395245552,
      0.04497003182768822,
      -0.061992302536964417,
      0.009338554926216602,
      0.034092191606760025,
      0.016557732596993446,
      0.08776580542325974,
      0.012189273722469807,
      0.018679961562156677,
      -0.048813849687576294,
      0.024149201810359955,
      -0.040139712393283844,
      0.02130983956158161,
      0.0881611555814743,
      0.027320466935634613,
      -0.06017212197184563,
      -0.033989790827035904,
      0.0401998832821846,
      0.025121966376900673,
      0.06647398322820663,
      -0.029754512012004852,
      -0.07550836354494095,
      0.09639986604452133,
      -0.02917620725929737,
      0.0005983572918921709,
      0.010278841480612755,
      -0.02557641826570034,
      0.04118148237466812,
      -0.06278888881206512,
      -0.031158341094851494,
      0.06853340566158295,
      -0.023627104237675667,
      0.04645923152565956,
      0.008501298725605011,
      -0.028678199276328087,
      0.06134933605790138,
      -0.019775493070483208,
      -0.06650813668966293,
      -0.020527075976133347,
      0.11496713012456894,
      -0.014704858884215355,
      0.012580025009810925,
      -0.04044776409864426,
      -0.008148015476763248,
      0.07541845738887787,
      -0.09696852415800095,
      -0.031728461384773254,
      0.015658916905522346,
      -0.0526912622153759,
      0.02186589129269123,
      0.0132770836353302,
      -0.015595681965351105,
      -0.00873702485114336,
      0.04359989985823631,
      -0.005923237651586533,
      0.04298011213541031,
      0.04165495187044144,
      0.06452160328626633,
      0.02627641335129738,
      0.02834937907755375,
      0.09758840501308441,
      -0.022447174414992332,
      -0.008488897234201431,
      -0.04412994161248207,
      -0.0018576334696263075,
      0.03746778517961502,
      -0.019086774438619614,
      -0.009401241317391396,
      0.007371327839791775,
      -0.09567643702030182,
      0.0034227995201945305,
      -0.0002889085153583437,
      -0.020580455660820007,
      0.02006923407316208,
      0.07415856420993805,
      0.031177885830402374,
      0.023852918297052383,
      -0.010542686097323895,
      0.06541072577238083,
      0.033788349479436874,
      0.07073160260915756,
      -0.07343224436044693,
      0.01563698798418045,
      0.01839563250541687,
      -0.04877679422497749,
      0.011721945367753506,
      -0.007490496151149273,
      0.08314622938632965,
      -0.03784462437033653,
      0.00569442193955183,
      -0.011105246841907501,
      -0.044752176851034164,
      -0.020146628841757774,
      -0.06992939114570618,
      -0.04700896143913269,
      0.0604526549577713,
      0.03491724282503128,
      -0.07745283097028732,
      -0.055910587310791016,
      0.0596962608397007,
      -0.03496229648590088,
      0.027216346934437752,
      0.009066137485206127,
      -0.04594549536705017,
      -0.01826455630362034,
      -0.0743778720498085,
      0.0019993663299828768,
      -0.036257676780223846,
      0.021813932806253433,
      -0.02357148751616478,
      -0.030500909313559532,
      -0.08396638929843903,
      -0.003514906857162714,
      -0.11237616091966629,
      0.007054003421217203,
      0.03238428384065628,
      -0.03170908987522125,
      0.004809331148862839,
      -0.049713075160980225,
      0.004128019791096449,
      -0.0033559103030711412,
      0.026992831379175186,
      0.04247308149933815,
      -0.007339262403547764,
      0.1066037192940712,
      -0.015236160717904568,
      0.027793988585472107,
      -0.08820176869630814,
      0.0017254181439056993,
      2.6512287149671465e-05,
      0.0020393121521919966,
      0.027769319713115692,
      0.07306047528982162,
      -0.0026621907018125057,
      -0.07207971811294556,
      -0.03194977715611458,
      -0.03216909244656563,
      -0.04524894058704376,
      0.02047443762421608,
      0.009393149055540562,
      0.07236383855342865,
      0.015197739005088806,
      -0.022154076024889946,
      -0.11562861502170563,
      -0.015126092359423637,
      0.11631787568330765,
      -0.02747226692736149,
      0.07570789754390717,
      0.05596185103058815,
      -0.02618231438100338,
      0.06672579795122147,
      -0.010734910145401955,
      -0.024765444919466972,
      -0.06701309978961945,
      -0.0191977359354496,
      -0.035731635987758636,
      0.06458888947963715,
      -0.030439188703894615,
      -0.04581223800778389,
      0.006429166067391634,
      0.010502160526812077,
      0.029366806149482727,
      0.09970447421073914,
      0.028024405241012573,
      -0.04069279506802559,
      0.0005135686369612813,
      -0.003117613261565566,
      -0.13242901861667633,
      0.025575486943125725,
      0.03806734085083008,
      -0.0928642526268959,
      -0.012635242193937302,
      0.07255330681800842,
      0.020347245037555695,
      0.016195151954889297,
      -0.020163092762231827,
      0.006158032920211554,
      0.02585243247449398,
      0.010796095244586468,
      0.01121285930275917,
      0.04708106070756912,
      -0.023601198568940163,
      -0.05057164281606674,
      0.04909579083323479,
      -0.042304620146751404,
      -0.043414998799562454,
      -0.06214815750718117,
      0.035224832594394684,
      0.016341140493750572,
      -0.0028117792680859566,
      0.06620754301548004,
      0.019150832667946815,
      0.018490800634026527,
      -0.016551366075873375,
      -0.008007079362869263,
      0.07877669483423233,
      0.06188123673200607,
      -0.00750544061884284,
      0.0045477598905563354,
      0.0012888972414657474,
      -0.040954943746328354,
      0.007298966869711876,
      -0.0007531300070695579,
      0.022923780605196953
    ],
    [
      -0.041440367698669434,
      0.039795611053705215,
      0.05852527171373367,
      -0.04873526096343994,
      0.05102820694446564,
      0.07035474479198456,
      -0.005250042770057917,
      -0.09727025032043457,
      0.021984536200761795,
      0.00474443007260561,
      -0.05536452308297157,
      0.00415833992883563,
      0.06084667891263962,
      0.05668557062745094,
      0.05332859233021736,
      0.015260918997228146,
      -0.03604285418987274,
      -0.040382590144872665,
      0.03008076921105385,
      0.03136270120739937,
      0.04492952302098274,
      0.10017196089029312,
      -0.022238384932279587,
      -0.004881055559962988,
      0.10602690279483795,
      0.01289307326078415,
      -0.00034114078152924776,
      0.014555355533957481,
      0.002097205724567175,
      0.005887757986783981,
      0.08425036817789078,
      -0.05078825354576111,
      0.06271994113922119,
      -0.004108069464564323,
      -0.005631393287330866,
      0.07320433855056763,
      0.08960875123739243,
      -0.13122577965259552,
      0.08164912462234497,
      0.00324808806180954,
      -0.023430196568369865,
      0.08481726795434952,
      -0.03729468584060669,
      0.01681463047862053,
      -0.030353359878063202,
      0.02047446183860302,
      0.05241028591990471,
      0.011554340831935406,
      0.07919877767562866,
      0.030220016837120056,
      0.014799044467508793,
      0.04375791177153587,
      0.09608180075883865,
      0.09974805265665054,
      -0.051900215446949005,
      0.054019439965486526,
      0.013878019526600838,
      0.06720618903636932,
      0.03256076201796532,
      0.05961509048938751,
      0.007600816432386637,
      -0.038763031363487244,
      0.048916567116975784,
      0.0150497080758214,
      -0.0014730984112247825,
      0.01800207793712616,
      0.02633991464972496,
      -0.10270541906356812,
      0.05529920756816864,
      0.00930218119174242,
      -0.04357096180319786,
      0.0503600612282753,
      -0.00341433915309608,
      0.06186911463737488,
      -0.06622298061847687,
      -0.029033860191702843,
      -0.015881415456533432,
      0.00728282704949379,
      0.046130694448947906,
      -0.016041018068790436,
      -0.006103944964706898,
      -0.04906512424349785,
      -0.04735542833805084,
      0.014816607348620892,
      -0.06133877485990524,
      -0.0018728268332779408,
      0.045066121965646744,
      0.028317052870988846,
      -0.03551848232746124,
      -0.047457512468099594,
      -0.03979194909334183,
      -0.009397044777870178,
      -0.002740901429206133,
      0.02943638525903225,
      0.0057078516110777855,
      0.09838423132896423,
      -0.0030187969096004963,
      -0.05986318737268448,
      -0.0028151560109108686,
      -0.029886523261666298,
      -0.0965164452791214,
      0.019991138949990273,
      -0.048448823392391205,
      0.06044632941484451,
      0.0339428074657917,
      -0.04229885712265968,
      -0.04581797495484352,
      0.10515562444925308,
      -0.04173576831817627,
      -0.08548033237457275,
      -0.010653798468410969,
      0.005592793226242065,
      -0.02960182912647724,
      0.05116467922925949,
      0.033736493438482285,
      -0.029764756560325623,
      -0.03314075618982315,
      0.03132016956806183,
      -0.013335668481886387,
      -0.03192075341939926,
      -0.06629480421543121,
      0.10207713395357132,
      0.025661343708634377,
      0.017927134409546852,
      0.002913398202508688,
      -0.040123164653778076,
      0.07067187875509262,
      0.052907709032297134,
      -0.009773745201528072,
      0.0103703448548913,
      -0.07223861664533615,
      -0.046492330729961395,
      -0.03397168964147568,
      -0.017768720164895058,
      -0.035891178995370865,
      -0.02813272550702095,
      -0.012686720117926598,
      0.008344379253685474,
      0.028661392629146576,
      -0.022124551236629486,
      0.01049839984625578,
      -0.002316370140761137,
      0.017048517242074013,
      0.022508256137371063,
      0.0006117068696767092,
      -0.011664772406220436,
      -0.003837927943095565,
      0.06972268223762512,
      0.004699940327554941,
      0.04307711869478226,
      -0.01801740936934948,
      0.02153724804520607,
      0.017506081610918045,
      0.0823289230465889,
      0.002676196862012148,
      0.00016321516886819154,
      0.010857201181352139,
      0.031537335366010666,
      0.026570918038487434,
      0.04815302789211273,
      0.030717747285962105,
      -0.005120882764458656,
      0.02128242887556553,
      0.04518307000398636,
      -0.023910224437713623,
      0.037401724606752396,
      0.01636931113898754,
      -0.00018526840722188354,
      0.046193044632673264,
      -0.021703630685806274,
      -0.06749878078699112,
      0.05129554495215416,
      -0.05351300537586212,
      -0.010254302062094212,
      -0.016878090798854828,
      0.04958035424351692,
      -0.00546684255823493,
      -0.09694957733154297,
      -0.054193802177906036,
      -0.025441447272896767,
      -0.028085054829716682,
      0.0548582449555397,
      -0.037882573902606964,
      0.014590829610824585,
      0.031489644199609756,
      0.03420811519026756,
      -0.0596516877412796,
      0.023615172132849693,
      -0.021769335493445396,
      0.037272557616233826,
      0.05479957163333893,
      0.017795711755752563,
      0.024349795654416084,
      -0.0022885873913764954,
      0.009636133909225464,
      -0.02234639972448349,
      0.0552205815911293,
      -0.008947024121880531,
      -0.03452696651220322,
      -0.0010656241793185472,
      0.02384193241596222,
      0.009347042068839073,
      0.03624388575553894,
      0.01804845780134201,
      0.017615895718336105,
      0.05429995059967041,
      0.016804242506623268,
      -0.0391695573925972,
      0.0062365238554775715,
      -0.04758152365684509,
      0.0005874146008864045,
      0.014917408116161823,
      0.004489299841225147,
      0.014039834029972553,
      -0.04883301258087158,
      0.012946640141308308,
      0.01806611381471157,
      -0.0029895503539592028,
      0.019802657887339592,
      0.08204666525125504,
      0.004750892985612154,
      -0.0936834067106247,
      0.10271437466144562,
      -0.022709982469677925,
      0.00447715912014246,
      0.07193411141633987,
      -0.060100656002759933,
      -0.021691376343369484,
      -0.001780819846317172,
      0.04943720996379852,
      -0.04098095744848251,
      0.009371533989906311,
      0.011975608766078949,
      0.03830507770180702,
      0.011127516627311707,
      0.019332073628902435,
      -0.008274420164525509,
      -0.01860157400369644,
      0.05910113826394081,
      0.0545150488615036,
      -0.06156957894563675,
      0.014207275584340096,
      0.051251500844955444,
      -0.03816628083586693,
      0.01225914154201746,
      -0.16090698540210724,
      -0.005702780093997717,
      -0.01738445833325386,
      0.020307710394263268,
      0.00574489776045084,
      0.054855845868587494,
      -0.10585314780473709,
      0.02264290302991867,
      -0.012387095019221306,
      0.07627798616886139,
      -0.008654268458485603,
      0.00012508085637819022,
      -0.007986576296389103,
      -0.01257724966853857,
      0.04458453878760338,
      0.0035946988500654697,
      0.09166837483644485,
      0.013308360241353512,
      0.011446422897279263,
      0.07791443914175034,
      0.02790761925280094,
      -0.025794019922614098,
      -0.02257367968559265,
      -0.05255572870373726,
      -0.07172750681638718,
      0.0933513343334198,
      -0.012562796473503113,
      0.033441636711359024,
      -0.05902605876326561,
      -0.020331483334302902,
      -0.0034203622490167618,
      0.019785339012742043,
      0.0025845931377261877,
      -0.0026645848993211985,
      -0.026657095178961754,
      0.015127599239349365,
      0.04814087972044945,
      0.04547055810689926,
      -0.04236332327127457,
      0.010707367211580276,
      0.04538150131702423,
      0.04592490941286087,
      -0.02575519122183323,
      -0.027747564017772675,
      0.03649609535932541,
      0.03599691018462181,
      -0.055465564131736755,
      0.005336519796401262,
      0.04879278317093849,
      -0.06708898395299911,
      -0.001169515075162053,
      -0.02748267725110054,
      -0.0120190205052495,
      0.07552263885736465,
      -0.013320536352694035,
      0.05796463415026665,
      0.006277276203036308,
      -0.011809729039669037,
      -0.01533917523920536,
      -0.0147701445966959,
      0.03834964707493782,
      0.09213148802518845,
      -0.028810681775212288,
      -0.018177196383476257,
      -0.0028900739271193743,
      -0.03073572926223278,
      0.05299573764204979,
      -0.021708937361836433,
      0.012784713879227638,
      -0.056672967970371246,
      0.004880235996097326,
      0.026562176644802094,
      0.032456278800964355,
      -0.018344542011618614,
      -0.0789400190114975,
      0.043919917196035385,
      0.002100155455991626,
      -0.0333913192152977,
      -0.010640660300850868,
      -0.053148578852415085,
      -0.06304171681404114,
      -0.0202782079577446,
      -0.0770239531993866,
      0.0064308554865419865,
      0.03748073801398277,
      0.016818439587950706,
      -0.02926628850400448,
      0.002896199468523264,
      -0.026916682720184326,
      -0.09698757529258728,
      -0.02454330213367939,
      0.020886337384581566,
      -0.0154637536033988,
      0.06514616310596466,
      0.0023959579411894083,
      0.012616829946637154,
      0.03290534019470215,
      -0.0018787402659654617,
      0.0332268662750721,
      -0.024263868108391762,
      0.007418307475745678,
      0.02005765028297901,
      -0.09768754988908768,
      -0.03229106590151787,
      -0.010860774666070938,
      0.0691891610622406,
      -0.02866380475461483,
      0.03600525110960007,
      0.054427891969680786,
      -0.018659615889191628,
      -0.042793720960617065,
      -0.005424609873443842,
      0.001404610462486744,
      0.014144686050713062,
      0.04298550263047218,
      -0.06808274984359741,
      0.0631568431854248,
      -0.0110301049426198,
      0.07697176188230515,
      -0.01540335826575756,
      0.0602240189909935,
      -0.014114167541265488,
      0.0038620266132056713,
      0.00662005553022027,
      0.013075580820441246,
      0.013157140463590622,
      0.027063805609941483,
      -0.06303412467241287,
      0.02099010720849037,
      -0.0013083228841423988,
      0.01959037594497204,
      -0.013871564529836178,
      0.014692875556647778,
      0.06726495921611786,
      -0.00858214683830738,
      -0.0030760278459638357,
      0.0525488406419754,
      0.00325670070014894,
      -0.05994756519794464,
      -0.011341210454702377,
      -0.03466520830988884,
      -0.10261350125074387,
      -0.03713654354214668,
      0.00018449142226018012,
      -0.0064583104103803635,
      0.050447430461645126,
      -0.006992504000663757,
      -0.060544710606336594,
      -0.004701038356870413,
      0.04681337624788284,
      0.0227071400731802,
      -0.020636195316910744,
      0.045020200312137604,
      0.01977403275668621,
      -0.02224905602633953,
      0.08170139044523239,
      0.02662111446261406,
      0.03824454918503761,
      -0.10001366585493088,
      0.01738564483821392,
      -0.05767100676894188,
      0.007411617320030928,
      -0.07047396153211594,
      0.05043664202094078,
      -0.0027095100376755,
      0.0147507069632411,
      -0.0020554743241518736,
      -0.00856720469892025,
      0.06457456946372986,
      -0.02951781265437603,
      -0.11589034646749496,
      0.009339571930468082,
      0.04100411385297775,
      0.08251947164535522,
      -0.016164839267730713,
      0.07304147630929947,
      -0.013134059496223927,
      -0.027857718989253044,
      0.01543422695249319,
      -0.07897590845823288,
      -0.017991743981838226,
      -0.015981821343302727,
      0.05185994878411293,
      0.10553986579179764,
      0.06869663298130035,
      0.029671061784029007,
      -0.010161258280277252,
      0.022542152553796768,
      -0.03135194256901741,
      0.042518436908721924,
      -0.09068608283996582,
      0.049400970339775085,
      0.04557151347398758,
      0.014504450373351574,
      0.02301918715238571,
      -0.014746662229299545,
      0.011589563451707363,
      0.02365688420832157,
      0.06724432110786438,
      0.10480176657438278,
      0.0076235090382397175,
      0.013249212875962257,
      0.06607328355312347,
      -0.07646548748016357,
      0.0016298252157866955,
      -0.022056804969906807,
      0.020085960626602173,
      -0.023978136479854584,
      0.01468590646982193,
      -0.05075376480817795,
      0.03652049973607063,
      -0.007979420945048332,
      -0.04482625424861908,
      0.004577544517815113,
      0.026635928079485893,
      -0.100047267973423,
      0.023311089724302292,
      0.006378123536705971,
      0.027698945254087448,
      0.06122458726167679,
      0.01930975914001465,
      0.03071480616927147,
      -0.07834716886281967,
      -0.028617996722459793,
      0.016976086422801018,
      0.015774603933095932,
      0.016033034771680832,
      -0.05679270252585411,
      -0.03403180092573166,
      0.05510836839675903,
      0.01643749698996544,
      -0.0003031108935829252,
      -0.0353286974132061,
      -0.016029557213187218,
      -0.021418238058686256,
      0.011531902477145195,
      -0.04959177225828171,
      -0.007407225668430328,
      -0.004461870063096285,
      -0.10049409419298172,
      0.016611317172646523,
      0.05218370258808136,
      -0.002105371095240116,
      0.010953654535114765,
      -0.015744086354970932,
      0.017859702929854393,
      0.008891350589692593,
      -0.07745198160409927,
      -0.043360717594623566,
      0.010805096477270126,
      0.0463581308722496,
      0.10813064128160477,
      -0.028845559805631638,
      -0.002290177159011364,
      -0.024591457098722458,
      0.06211543828248978,
      0.016920719295740128,
      -0.014659374952316284
    ],
    [
      -0.022739414125680923,
      0.012925365008413792,
      -0.08601081371307373,
      -0.03693003952503204,
      0.03115706704556942,
      -0.05441906303167343,
      -0.034954529255628586,
      -0.00930779054760933,
      -0.027618246152997017,
      0.044768184423446655,
      -0.04440533369779587,
      0.036586079746484756,
      -0.008058415725827217,
      -0.061630502343177795,
      -0.055740613490343094,
      -0.059674087911844254,
      0.013769750483334064,
      -0.00956355407834053,
      -0.01739071123301983,
      0.028051113709807396,
      0.003736512502655387,
      0.013929035514593124,
      0.053727198392152786,
      0.009178713895380497,
      -0.014213163405656815,
      0.027044355869293213,
      0.026866460219025612,
      -0.034065619111061096,
      -0.04075120761990547,
      -0.04927654564380646,
      -0.05382799729704857,
      -0.04138242080807686,
      -0.03242570534348488,
      0.03980910778045654,
      0.09432433545589447,
      -0.0493701733648777,
      0.06737953424453735,
      0.08649039268493652,
      -0.0007032788125798106,
      -0.021150417625904083,
      -0.03416901081800461,
      0.037962429225444794,
      -0.011968933045864105,
      0.07297799736261368,
      0.02032112143933773,
      -0.05752411484718323,
      0.027889909222722054,
      -0.06705178320407867,
      -0.018299734219908714,
      -0.0007063909433782101,
      0.042313236743211746,
      -0.0615287609398365,
      0.02024570107460022,
      0.01667148619890213,
      -0.046319812536239624,
      0.01790262758731842,
      -0.05993333458900452,
      0.020692847669124603,
      0.007445344235748053,
      -0.03025905042886734,
      -0.02886984311044216,
      0.026466693729162216,
      0.021109607070684433,
      -0.022433973848819733,
      4.655727025237866e-06,
      -0.032230183482170105,
      -0.02310567907989025,
      -0.0340244434773922,
      -0.0417286716401577,
      -0.020638521760702133,
      -0.048981137573719025,
      0.024209847673773766,
      0.019398557022213936,
      -0.07388941943645477,
      -0.016043957322835922,
      0.023383835330605507,
      0.03365965560078621,
      -0.041216135025024414,
      0.010281992144882679,
      0.04908720776438713,
      0.04766634851694107,
      -0.043926700949668884,
      -0.03479122743010521,
      0.011302678845822811,
      -0.07326196879148483,
      0.029513822868466377,
      -0.015126924030482769,
      0.001213415409438312,
      -0.03057924099266529,
      0.04198971763253212,
      0.12367706000804901,
      -0.027347799390554428,
      -0.02584121562540531,
      0.05291738733649254,
      -0.025918731465935707,
      -0.09872347116470337,
      0.011104319244623184,
      0.019266406074166298,
      0.02549644559621811,
      -0.01676098443567753,
      -0.03618161752820015,
      -0.016897279769182205,
      -0.02335793897509575,
      0.005851853173226118,
      0.010553430765867233,
      -0.06379416584968567,
      0.0691567063331604,
      0.06428845226764679,
      -0.0725596472620964,
      -0.09069939702749252,
      0.028139254078269005,
      -0.043103668838739395,
      0.08422847092151642,
      0.014863625168800354,
      -0.0434238538146019,
      0.003461218671873212,
      0.02395559661090374,
      -0.000370224064681679,
      -0.01589967869222164,
      0.0013838678132742643,
      0.07888313382863998,
      -0.06452424824237823,
      -0.016074687242507935,
      -0.03553636744618416,
      0.04254840686917305,
      -0.06440180540084839,
      0.0025179695803672075,
      -0.03223874792456627,
      -0.014628453180193901,
      0.038445137441158295,
      -0.04808037355542183,
      0.05324850603938103,
      0.039254479110240936,
      0.00902992021292448,
      -0.018470020964741707,
      0.014124535955488682,
      0.08801834285259247,
      0.055392079055309296,
      -0.03757986053824425,
      -0.029884880408644676,
      0.007130663841962814,
      -0.004992265719920397,
      0.008083760738372803,
      0.11621574312448502,
      -0.100795216858387,
      -0.04871394857764244,
      0.0011282583000138402,
      0.03050258569419384,
      -0.053193558007478714,
      -0.08147957175970078,
      0.034972671419382095,
      0.05178385600447655,
      0.05731348320841789,
      0.041085392236709595,
      0.06137276813387871,
      0.06356152147054672,
      -0.05653008073568344,
      0.04099986329674721,
      -0.026202186942100525,
      0.02665116637945175,
      -0.018714403733611107,
      0.005402236245572567,
      -0.021453412249684334,
      -0.04216073453426361,
      0.0017748766113072634,
      -0.0654195100069046,
      0.0036145311314612627,
      -0.007062622811645269,
      0.018032602965831757,
      0.04725038632750511,
      -0.1262785643339157,
      0.039066847413778305,
      0.07501377910375595,
      0.07655823975801468,
      -0.0035290480591356754,
      -0.03175218403339386,
      0.02310023084282875,
      -0.1019606962800026,
      0.07807938754558563,
      0.007879221811890602,
      0.01173807866871357,
      -0.032592643052339554,
      -0.021972065791487694,
      -0.021383842453360558,
      -0.004732248838990927,
      -0.0591118298470974,
      0.025306349620223045,
      -0.018520349636673927,
      -0.011678660288453102,
      0.01750885136425495,
      0.025210069492459297,
      -0.0942998081445694,
      0.07778065651655197,
      -0.14586596190929413,
      0.062404878437519073,
      -0.00968826375901699,
      -0.06821408122777939,
      0.059039052575826645,
      -0.02394774742424488,
      -0.042579807341098785,
      -0.008360320702195168,
      -0.016913672909140587,
      -0.09650221467018127,
      -0.004773409571498632,
      -0.037450771778821945,
      0.028635304421186447,
      0.03141963109374046,
      0.037457287311553955,
      -0.02818843349814415,
      -0.045777734369039536,
      0.009000802412629128,
      0.08014078438282013,
      -0.06326509267091751,
      0.0034864647313952446,
      0.02205570973455906,
      -0.10248679667711258,
      -0.0009633628069423139,
      -0.02481384575366974,
      -0.013582438230514526,
      0.029156118631362915,
      0.01277797482907772,
      -0.056959882378578186,
      0.1053958609700203,
      -0.032047875225543976,
      0.013042081147432327,
      -0.03603758290410042,
      0.005215809214860201,
      0.0037894672714173794,
      0.000627275905571878,
      -0.005543756764382124,
      0.0743115022778511,
      -0.007515671197324991,
      0.006038338411599398,
      0.12078358978033066,
      -0.014679212123155594,
      -0.04449160397052765,
      0.02836182527244091,
      -0.006025450769811869,
      0.006814097054302692,
      -0.017907243221998215,
      0.01960662193596363,
      0.017490753903985023,
      -0.042250633239746094,
      0.02554580196738243,
      0.022358274087309837,
      -0.029081197455525398,
      0.00016964816313702613,
      0.007718781009316444,
      0.00826895609498024,
      -0.06732812523841858,
      0.09433761984109879,
      -0.07489520311355591,
      0.028483550995588303,
      -0.024904020130634308,
      0.03095441684126854,
      0.021973876282572746,
      0.061807308346033096,
      0.051788873970508575,
      -0.07924885302782059,
      -0.00988780241459608,
      0.06890583038330078,
      -0.010067371651530266,
      -0.030251121148467064,
      -0.07053346931934357,
      -0.050767336040735245,
      -0.057698097079992294,
      -0.031215505674481392,
      0.0077138785272836685,
      -0.01678086817264557,
      -0.05987805500626564,
      -0.019462455064058304,
      0.061018206179142,
      -0.025999832898378372,
      0.04464227706193924,
      -0.014055334031581879,
      -0.013644466176629066,
      -0.10190838575363159,
      -0.04833633825182915,
      0.04568876326084137,
      -0.05740898475050926,
      0.000982976402156055,
      0.04379730299115181,
      0.13742658495903015,
      0.05791661515831947,
      0.028148619458079338,
      -0.03791521489620209,
      0.05604630336165428,
      -0.03199584037065506,
      0.01994730904698372,
      0.0639110878109932,
      -0.041758231818675995,
      0.028700651600956917,
      -0.04558354616165161,
      0.0428883358836174,
      0.043476808816194534,
      0.03255925327539444,
      0.03734314814209938,
      -0.039266034960746765,
      0.05746174976229668,
      -0.020998423919081688,
      -0.09119544178247452,
      -0.0252202358096838,
      -0.026383928954601288,
      0.036479026079177856,
      -0.04152127727866173,
      0.012055422179400921,
      -0.041343022137880325,
      0.02039705589413643,
      0.0264500230550766,
      -0.0386589951813221,
      -0.10620709508657455,
      -0.016333574429154396,
      0.01284076552838087,
      0.03979005292057991,
      0.08612321317195892,
      -0.013420541770756245,
      0.053166963160037994,
      0.02806144580245018,
      0.015544794499874115,
      -0.02729196287691593,
      0.0330372080206871,
      0.025927430018782616,
      -0.07781486213207245,
      -0.04118787869811058,
      0.01739628054201603,
      0.03361324965953827,
      0.04787157103419304,
      -0.027540408074855804,
      0.03291220963001251,
      -0.021480511873960495,
      0.04704820737242699,
      -0.03990368917584419,
      0.08724231272935867,
      0.08703500032424927,
      0.03201858326792717,
      0.042290765792131424,
      0.03710850328207016,
      0.05870587006211281,
      -0.03218696266412735,
      0.003380639711394906,
      0.011898331344127655,
      -0.04297607019543648,
      -0.030905157327651978,
      0.0038102047983556986,
      -0.011639023199677467,
      -0.003066327655687928,
      -0.006238650530576706,
      0.04326396435499191,
      0.04723777249455452,
      -0.025636281818151474,
      0.043574556708335876,
      0.013643070124089718,
      -0.04338851943612099,
      -0.04219232499599457,
      -0.03955131396651268,
      0.001032541273161769,
      0.007497891783714294,
      0.008561259135603905,
      0.0015991916880011559,
      0.070988729596138,
      -0.009555776603519917,
      -0.08159244060516357,
      -0.06261443346738815,
      -0.003519220743328333,
      -0.0042632585391402245,
      0.016745520755648613,
      0.018854845315217972,
      0.037642817944288254,
      0.03457605466246605,
      0.03762504458427429,
      0.02489580772817135,
      -0.005441498942673206,
      0.0372781902551651,
      -0.020946333184838295,
      0.05467015504837036,
      -0.00844777375459671,
      -0.02831641398370266,
      -0.021200310438871384,
      -0.06552255898714066,
      0.03640289232134819,
      0.024236470460891724,
      -0.008584890514612198,
      0.05926986038684845,
      0.009994174353778362,
      -0.02519342675805092,
      0.04627842456102371,
      0.035669516772031784,
      0.03322052210569382,
      0.03481891751289368,
      0.10797040909528732,
      -0.023174181580543518,
      -0.08520732074975967,
      -0.08895131945610046,
      0.03293251618742943,
      0.03507349267601967,
      0.010088196024298668,
      0.011167168617248535,
      -0.06902766972780228,
      -0.0144758690148592,
      0.005064701661467552,
      0.01901271939277649,
      0.04275050014257431,
      -0.07744284719228745,
      0.04031243547797203,
      -0.038862984627485275,
      -0.032958026975393295,
      -0.008259703405201435,
      0.03675238788127899,
      -0.070027194917202,
      0.20175832509994507,
      -0.037691760808229446,
      0.018729230388998985,
      0.11510865390300751,
      0.05279899761080742,
      -0.07566056400537491,
      0.028657538816332817,
      0.08862613141536713,
      0.016126852482557297,
      0.04812198877334595,
      0.04634353518486023,
      0.03006226010620594,
      -0.00266435113735497,
      -0.05422132462263107,
      0.003732207929715514,
      0.006735412869602442,
      0.03494869917631149,
      -0.007430213503539562,
      0.02258278802037239,
      0.047001127153635025,
      0.0034605860710144043,
      0.06127273663878441,
      -0.0744028389453888,
      0.09179636836051941,
      0.035919349640607834,
      0.07451620697975159,
      0.06523033231496811,
      -0.04413088411092758,
      0.06782763451337814,
      -0.02943602204322815,
      0.08262033015489578,
      -0.054537829011678696,
      0.04175190255045891,
      0.012102361768484116,
      0.017714889720082283,
      -0.040450774133205414,
      0.06233882158994675,
      0.04741152003407478,
      0.11277147382497787,
      -0.04054877534508705,
      -0.03589407354593277,
      0.06565073877573013,
      -0.011527341790497303,
      0.01568685658276081,
      0.019254853948950768,
      0.06710345298051834,
      -0.05764062702655792,
      -0.046992722898721695,
      0.00011863801046274602,
      -0.027321448549628258,
      -0.025040337815880775,
      0.0010925375390797853,
      0.06860806792974472,
      0.04956876114010811,
      -0.034574758261442184,
      0.017650775611400604,
      0.08032312244176865,
      -0.007081979885697365,
      -0.038001999258995056,
      0.009155252017080784,
      -0.011721395887434483,
      -0.013696121983230114,
      0.045256394892930984,
      -0.04637554660439491,
      -0.04903798922896385,
      -0.03393781557679176,
      -0.07475770264863968,
      -0.023898666724562645,
      -0.015699898824095726,
      0.028031349182128906,
      -0.09002787619829178,
      0.021387608721852303,
      0.012950045987963676,
      -0.037224918603897095,
      0.04427158087491989,
      -0.023689132183790207,
      -0.01962539367377758,
      0.05905313789844513,
      -0.06503233313560486,
      -0.04152356833219528,
      0.056839488446712494,
      -0.00489334249868989,
      0.006085147149860859,
      -2.197945832449477e-05,
      0.052167460322380066,
      0.038964103907346725,
      -0.0013329422799870372,
      0.0591493584215641,
      0.01314528938382864,
      0.07587860524654388,
      -0.04499681293964386,
      -0.008018902502954006,
      0.056523848325014114,
      0.012253998778760433
    ],
    [
      -0.018523378297686577,
      0.027621569111943245,
      0.0006258500507101417,
      0.0028135364409536123,
      -0.011019468307495117,
      -0.05705481767654419,
      -0.01155988872051239,
      0.06137777119874954,
      -0.10694519430398941,
      0.011309507302939892,
      0.009010800160467625,
      -0.06837667524814606,
      -0.012812362052500248,
      0.02873905748128891,
      0.040293920785188675,
      -0.034105829894542694,
      0.04079793021082878,
      -0.06389383226633072,
      0.027405310422182083,
      0.0930715799331665,
      -0.005663712043315172,
      -0.05803669989109039,
      0.02030121348798275,
      -0.0003813014773186296,
      0.003827352775260806,
      -0.01774531416594982,
      0.041795119643211365,
      -0.03191874548792839,
      0.043646782636642456,
      -0.00491140503436327,
      -0.0295379888266325,
      -0.06913669407367706,
      -0.007197120692580938,
      -0.01198942307382822,
      0.0344751812517643,
      -0.012095342390239239,
      -0.03334634378552437,
      -0.0533931590616703,
      -0.03148522228002548,
      0.07232924550771713,
      -0.022562075406312943,
      -0.0387241505086422,
      0.02165876515209675,
      -0.09149391204118729,
      0.08839687705039978,
      0.04742181673645973,
      0.05745973810553551,
      0.016495171934366226,
      -0.05596640706062317,
      0.03839978203177452,
      -0.018115606158971786,
      -0.008051957935094833,
      -0.04968946799635887,
      -0.07834021002054214,
      0.012395620346069336,
      0.05388588085770607,
      -0.06397098302841187,
      0.0716015174984932,
      -0.018421722576022148,
      0.034351978451013565,
      0.0037798553239554167,
      0.06947147846221924,
      0.04200435429811478,
      0.046639565378427505,
      0.04460211843252182,
      0.04902036115527153,
      -0.003606532234698534,
      -0.0378463976085186,
      0.024557063356041908,
      0.058973245322704315,
      0.017295323312282562,
      0.041078343987464905,
      -0.008763072080910206,
      -0.016588671132922173,
      0.03720317780971527,
      -0.03854545205831528,
      -0.011674067005515099,
      0.015760080888867378,
      0.006059490609914064,
      0.007398655638098717,
      0.019662974402308464,
      -0.01721075549721718,
      0.042540293186903,
      0.0064223469235002995,
      -0.03664828836917877,
      0.022672034800052643,
      0.08749090880155563,
      -0.015447670593857765,
      -0.059779759496450424,
      0.0015501267043873668,
      0.07279844582080841,
      0.0591127835214138,
      -0.006706452928483486,
      0.011249290779232979,
      0.014714188873767853,
      -0.018583469092845917,
      -0.024561898782849312,
      -0.07415232062339783,
      -0.006183268968015909,
      -0.14235135912895203,
      -0.052620839327573776,
      -0.01624172180891037,
      -0.055208347737789154,
      -0.0528225302696228,
      -0.0012962863547727466,
      0.014925736002624035,
      -0.09217710793018341,
      0.013159982860088348,
      -0.032743483781814575,
      0.011245413683354855,
      -0.008164247497916222,
      0.003016212023794651,
      -0.0005430282326415181,
      0.005699874833226204,
      -0.040909383445978165,
      0.022174449637532234,
      -0.04769309610128403,
      -0.009508379735052586,
      0.04585932195186615,
      -0.004836719017475843,
      0.04514521360397339,
      -0.011119025759398937,
      -0.028802452608942986,
      0.04971646890044212,
      0.03493219614028931,
      0.038001492619514465,
      0.0466984286904335,
      -0.04138190299272537,
      -0.01378186047077179,
      0.023261791095137596,
      0.018897652626037598,
      0.06550667434930801,
      -0.019092822447419167,
      -0.0060712979175150394,
      -0.036578912287950516,
      0.017433011904358864,
      -0.07316485792398453,
      -0.014766574837267399,
      0.02262522652745247,
      -0.03980047255754471,
      -0.04178823530673981,
      0.006225361954420805,
      0.03790200129151344,
      0.00025676132645457983,
      -0.03626243397593498,
      -0.07313080877065659,
      0.053797002881765366,
      -0.06170953810214996,
      -0.03996376693248749,
      -0.045297130942344666,
      -0.01871410571038723,
      0.017563344910740852,
      0.04903481900691986,
      0.042223650962114334,
      0.004252219572663307,
      -0.04148958623409271,
      0.00845698919147253,
      -0.021955881267786026,
      0.06512598693370819,
      -0.0006914872792549431,
      0.012907673604786396,
      -0.032721277326345444,
      -0.12370840460062027,
      -0.03432999923825264,
      -0.012814653106033802,
      -0.035132575780153275,
      0.0029664034955203533,
      0.07664463669061661,
      0.042835138738155365,
      -0.024038435891270638,
      -0.08118604868650436,
      0.024629108607769012,
      -0.04821435362100601,
      0.0016344900941476226,
      -0.021754952147603035,
      0.053605832159519196,
      0.06525921821594238,
      -0.09347953647375107,
      -0.09619525820016861,
      0.07706142961978912,
      0.02256891317665577,
      0.0606289878487587,
      -0.01750374771654606,
      0.04125519469380379,
      -0.019946059212088585,
      0.0406186543405056,
      -0.010572854429483414,
      -0.039359934628009796,
      -0.019675644114613533,
      -0.022698448970913887,
      -0.04875815659761429,
      -0.09554170072078705,
      0.012766053900122643,
      -0.018661774694919586,
      0.013992351479828358,
      -0.02281162329018116,
      0.017398180440068245,
      0.025800658389925957,
      -0.02805694006383419,
      -0.07228858768939972,
      -0.04296881705522537,
      0.017765125259757042,
      0.04248681291937828,
      -0.012369079515337944,
      0.030262993648648262,
      0.007410288788378239,
      0.02469576895236969,
      -0.03626297786831856,
      -0.1064063236117363,
      0.06238577887415886,
      0.0025412295944988728,
      -0.008617064915597439,
      0.03559105470776558,
      -0.0732077807188034,
      -0.07544630020856857,
      -0.006104739848524332,
      0.0820295438170433,
      0.02973562851548195,
      0.031538210809230804,
      0.03646385669708252,
      0.01616647094488144,
      0.012823053635656834,
      0.07221751660108566,
      -0.0293404683470726,
      0.040554363280534744,
      -0.07416388392448425,
      0.09568970650434494,
      0.018329305574297905,
      0.022509310394525528,
      -0.060018785297870636,
      0.01629425399005413,
      0.04982390254735947,
      0.06937573850154877,
      0.03820870816707611,
      -0.014404967427253723,
      0.038406919687986374,
      -0.11649997532367706,
      0.0258098766207695,
      -0.02361573651432991,
      -0.03376200795173645,
      0.030114423483610153,
      -0.025095870718359947,
      0.022544624283909798,
      -0.03036624565720558,
      0.04205542430281639,
      -0.14885368943214417,
      -0.03722560033202171,
      0.037685178220272064,
      0.04391844570636749,
      -0.03257840871810913,
      0.006201825570315123,
      -0.035423558205366135,
      0.0309583880007267,
      0.03975295275449753,
      0.022567328065633774,
      0.07117806375026703,
      0.021608907729387283,
      0.05782303214073181,
      0.015594929456710815,
      -0.010874482803046703,
      0.0960126742720604,
      -0.017837287858128548,
      -0.0049004387110471725,
      -0.08488969504833221,
      0.03410422429442406,
      -0.025546303018927574,
      0.022848738357424736,
      -0.05714014172554016,
      -0.01227114349603653,
      0.026268359273672104,
      -0.03801039606332779,
      -0.01137254573404789,
      -0.037052322179079056,
      -0.07216760516166687,
      -0.03475172817707062,
      0.005781192798167467,
      0.08953163027763367,
      -0.022501925006508827,
      0.023539135232567787,
      0.04668005183339119,
      -0.00811410415917635,
      -0.042113304138183594,
      -0.0016778920544311404,
      0.06817130744457245,
      -0.02716022916138172,
      0.004053943324834108,
      0.05370680242776871,
      -0.018258117139339447,
      -0.06368162482976913,
      -0.03104342706501484,
      -0.002650720765814185,
      -0.08257157355546951,
      -0.03957065939903259,
      0.017594750970602036,
      0.04529920220375061,
      -0.10046306252479553,
      0.07322922348976135,
      0.01834557019174099,
      6.618866609642282e-05,
      -0.04974259063601494,
      -0.0005811107694171369,
      0.002151242922991514,
      0.042873725295066833,
      -0.0035928941797465086,
      -0.03515532612800598,
      -0.04581320285797119,
      -0.023320557549595833,
      -0.03506144508719444,
      0.00037342964787967503,
      -0.002569763222709298,
      -0.018204709514975548,
      -0.02615644969046116,
      0.09595674276351929,
      -0.04135721176862717,
      -0.035336121916770935,
      -0.11942192912101746,
      0.009946745820343494,
      -0.006287751253694296,
      0.042003463953733444,
      0.01353132352232933,
      0.0633145421743393,
      0.03321486711502075,
      0.01503300666809082,
      0.08313443511724472,
      -0.05518985912203789,
      -0.04573187604546547,
      0.07398200780153275,
      -0.04429880902171135,
      0.07066626846790314,
      0.015740560367703438,
      0.0011112539796158671,
      -0.005516012664884329,
      -0.02935206890106201,
      -0.11862030625343323,
      0.03550614044070244,
      0.07956268638372421,
      0.04129089042544365,
      -0.06195485591888428,
      -0.025587547570466995,
      0.050483521074056625,
      -0.009792027063667774,
      0.013707546517252922,
      -0.01359039917588234,
      0.0006696165073662996,
      0.044728491455316544,
      0.007589893415570259,
      0.0339435413479805,
      -0.03595809265971184,
      0.006979235447943211,
      -0.009092831052839756,
      0.012328924611210823,
      0.020316237583756447,
      0.04328606277704239,
      0.06887082755565643,
      0.03200288489460945,
      0.08015503734350204,
      -0.029595501720905304,
      0.06756291538476944,
      0.04196491837501526,
      -0.029266567900776863,
      0.016640933230519295,
      -0.02073054388165474,
      0.007339591160416603,
      0.015467344783246517,
      -0.04368055984377861,
      0.053365375846624374,
      -0.042138658463954926,
      0.02896302379667759,
      -0.10477068275213242,
      -0.05894053354859352,
      -0.04976121336221695,
      -0.03354329615831375,
      0.04000389948487282,
      0.03504481911659241,
      -0.016811614856123924,
      0.01820051670074463,
      0.02868635021150112,
      0.0012459030840545893,
      -0.05699643865227699,
      -0.059909526258707047,
      0.08247573673725128,
      -0.011102872900664806,
      -0.05983630567789078,
      -0.05003965646028519,
      -0.010536945424973965,
      0.06310120970010757,
      0.07304305583238602,
      -0.0201913770288229,
      -0.0002726753009483218,
      -0.022802073508501053,
      0.03237487003207207,
      0.020723968744277954,
      0.05364338681101799,
      -0.0022094931919127703,
      -0.012936980463564396,
      0.03315840661525726,
      0.04916538670659065,
      0.05596715584397316,
      0.03681381419301033,
      -0.04089466109871864,
      -0.03144035115838051,
      0.01016190741211176,
      0.0928293839097023,
      -0.08030448853969574,
      0.01649363338947296,
      0.018685922026634216,
      -0.006997899617999792,
      0.01811334118247032,
      -0.030229145660996437,
      -0.0511193685233593,
      -0.010046139359474182,
      -0.016237499192357063,
      0.007147314492613077,
      -0.04228014126420021,
      -0.12904934585094452,
      0.002999256132170558,
      0.007350387051701546,
      0.020188642665743828,
      -0.0002800591755658388,
      0.0026871373411267996,
      -0.052409399300813675,
      -0.015512443147599697,
      0.03117113560438156,
      0.0008967605535872281,
      0.06447871774435043,
      -0.051271289587020874,
      0.036720965057611465,
      0.07397035509347916,
      0.049062468111515045,
      0.05727057158946991,
      -0.037586841732263565,
      -0.005536317825317383,
      0.0529308021068573,
      -0.041420578956604004,
      -0.034120406955480576,
      -0.02476731315255165,
      0.03061765618622303,
      0.008258098736405373,
      -0.04447401314973831,
      0.06193222478032112,
      0.010548221878707409,
      -0.0076876007951796055,
      0.08982036262750626,
      -0.0235646590590477,
      -0.07172201573848724,
      -0.06344111263751984,
      0.03677859157323837,
      -0.003583492711186409,
      -0.0025745555758476257,
      0.030336853116750717,
      -0.04618723690509796,
      -0.02891319803893566,
      0.014951132237911224,
      -0.04925537109375,
      -0.020398512482643127,
      -0.003405231749638915,
      0.0002773355518002063,
      -0.025184450671076775,
      0.019813740625977516,
      -0.043669894337654114,
      -0.014451036229729652,
      -0.07686752825975418,
      0.004528116434812546,
      0.08271035552024841,
      0.03165271878242493,
      -0.004087441600859165,
      0.021859612315893173,
      0.1301768571138382,
      0.0721234455704689,
      -0.0022523007355630398,
      -0.01419683638960123,
      -0.023717237636446953,
      0.04091997444629669,
      0.07969330996274948,
      -0.022952981293201447,
      0.0004989198059774935,
      0.00493176095187664,
      -0.01783776842057705,
      0.0474000945687294,
      0.007066295016556978,
      -0.020496418699622154,
      -0.009002160280942917,
      -0.006282517220824957,
      -0.005524813663214445,
      -0.018828729167580605,
      0.0020253804977983236,
      0.017156703397631645,
      0.0656379833817482,
      -0.0699230432510376,
      -0.017651639878749847,
      -0.019382767379283905,
      0.05879756435751915,
      -0.04845336824655533,
      0.06213616579771042,
      0.021798379719257355,
      0.0746062844991684,
      -0.059944137930870056,
      -0.004311378579586744,
      0.01837211288511753,
      0.03362762928009033,
      -0.07743540406227112,
      -0.02192489430308342,
      -0.056861620396375656
    ],
    [
      -0.0008518897229805589,
      0.038497526198625565,
      0.07178010791540146,
      0.016532396897673607,
      0.017068354412913322,
      0.032331015914678574,
      0.051742784678936005,
      0.008383359760046005,
      0.07970165461301804,
      -0.028386952355504036,
      0.05664250627160072,
      -0.05234880745410919,
      0.06863874197006226,
      -0.07662300765514374,
      0.02341831848025322,
      0.018080931156873703,
      -0.032821618020534515,
      0.0939430296421051,
      0.03631042689085007,
      -0.04381876438856125,
      -0.01074432022869587,
      -0.034157127141952515,
      -0.025980595499277115,
      -0.0008235424757003784,
      -0.05908351019024849,
      0.041975896805524826,
      -0.012808321975171566,
      0.03613346442580223,
      -0.06117844209074974,
      0.039601024240255356,
      0.04338805004954338,
      -0.04146808385848999,
      -0.019725490361452103,
      -0.010561160743236542,
      0.0007505062385462224,
      0.007713526487350464,
      -0.03238557651638985,
      -0.0428568534553051,
      -0.02611498534679413,
      0.025994358584284782,
      -0.06469982117414474,
      -0.022244982421398163,
      -0.014569871127605438,
      0.03022291325032711,
      -0.014372779056429863,
      -0.00679394556209445,
      0.036650288850069046,
      0.014454423449933529,
      -0.0027145391795784235,
      -0.06028468534350395,
      -0.02958986535668373,
      -0.03638167679309845,
      -0.1046157032251358,
      -0.00028603163082152605,
      0.07183179259300232,
      0.04663481190800667,
      -0.017722299322485924,
      -0.005457844585180283,
      -0.008693471550941467,
      -0.06296202540397644,
      -0.05477386713027954,
      -0.009765821509063244,
      -0.08434484153985977,
      0.07368669658899307,
      0.03708988428115845,
      0.034524280577898026,
      -0.07824348658323288,
      0.037052106112241745,
      -0.00212326692417264,
      -0.11412466317415237,
      -0.017366327345371246,
      -0.028947003185749054,
      -0.04275507107377052,
      0.028155772015452385,
      0.10735978931188583,
      0.03948438912630081,
      0.03649638965725899,
      0.00215586693957448,
      -0.006840404588729143,
      0.01305842399597168,
      -0.08547521382570267,
      -0.042976632714271545,
      0.07089940458536148,
      0.07527010142803192,
      0.004488455597311258,
      -0.05885915830731392,
      0.037413448095321655,
      0.01363309659063816,
      -0.052069175988435745,
      -0.04501848295331001,
      -0.08316541463136673,
      -0.0017663899343460798,
      0.06194141507148743,
      -0.06723485887050629,
      0.02085254155099392,
      -0.050036586821079254,
      -0.0842214971780777,
      -0.0041972496546804905,
      -0.01207783818244934,
      -0.02773129940032959,
      -0.028415625914931297,
      0.029192160815000534,
      -0.013863621279597282,
      -0.08761300891637802,
      -0.01267105434089899,
      0.006943436339497566,
      -0.11523082852363586,
      -0.010866791009902954,
      -0.029489753767848015,
      0.008836068212985992,
      0.011588568799197674,
      0.09095508605241776,
      -0.05294814333319664,
      0.044467370957136154,
      0.008818338625133038,
      -0.0853506475687027,
      -0.031801771372556686,
      -0.04605424031615257,
      0.07165629416704178,
      0.015084411948919296,
      -0.028989389538764954,
      -0.05862860009074211,
      0.05539160966873169,
      0.05737251415848732,
      0.0033661769703030586,
      -0.01965847797691822,
      0.014211469329893589,
      -0.048818670213222504,
      -0.005758688785135746,
      -0.04197761043906212,
      0.08839769661426544,
      -0.06639020889997482,
      0.0053521753288805485,
      -0.016926107928156853,
      -0.060494497418403625,
      0.07273523509502411,
      0.029210180044174194,
      0.02902987040579319,
      0.010016465559601784,
      0.03778248652815819,
      0.024039726704359055,
      0.022114505991339684,
      0.005613878834992647,
      0.0111373420804739,
      -0.01164207048714161,
      0.043265946209430695,
      0.026815230026841164,
      0.0004868633986916393,
      0.10783571004867554,
      0.009389479644596577,
      0.02107509970664978,
      -0.021695006638765335,
      0.0447063073515892,
      0.027220604941248894,
      -0.01694122515618801,
      -0.032168298959732056,
      -0.00431458093225956,
      -0.01987203024327755,
      0.025320980697870255,
      0.032011836767196655,
      0.04255938157439232,
      -0.04943172633647919,
      0.026971779763698578,
      -0.025816714391112328,
      -0.00951743870973587,
      -0.08219251781702042,
      0.0038581378757953644,
      0.07599310576915741,
      -0.04193010553717613,
      -0.10285169631242752,
      0.017373038455843925,
      0.00691197719424963,
      0.007978858426213264,
      0.036053672432899475,
      0.03320573270320892,
      0.04130019247531891,
      0.05858750268816948,
      0.002536072162911296,
      0.007370335515588522,
      -0.03885025903582573,
      -0.03339816629886627,
      -0.04601162299513817,
      0.02950366400182247,
      0.11290612071752548,
      0.005075628403574228,
      0.029531924054026604,
      0.060334090143442154,
      0.00861844327300787,
      0.021515341475605965,
      0.03790992125868797,
      0.008223765529692173,
      0.012323355302214622,
      0.043451618403196335,
      -0.037039391696453094,
      0.10422586649656296,
      -0.0004788669175468385,
      0.017427343875169754,
      -0.04049874469637871,
      0.04458887502551079,
      -0.013628383167088032,
      0.03417383134365082,
      0.0549737811088562,
      0.00939910113811493,
      -0.009722888469696045,
      0.04667441546916962,
      -0.002884647576138377,
      0.02535400539636612,
      0.055082693696022034,
      -0.05144616961479187,
      0.02888362854719162,
      0.03446772322058678,
      -0.01233639381825924,
      0.06383830308914185,
      0.05288805440068245,
      0.001159784384071827,
      -0.016335126012563705,
      0.03477635607123375,
      0.011478537693619728,
      0.011979922652244568,
      0.04800047352910042,
      -0.043911244720220566,
      0.009441533125936985,
      -0.05314451828598976,
      -0.01751970872282982,
      -0.03976355120539665,
      -0.07225437462329865,
      0.012229464948177338,
      -0.00396049814298749,
      -0.00037102779606357217,
      -0.010981094092130661,
      0.004179326817393303,
      0.03772079572081566,
      0.05856279656291008,
      0.06517604738473892,
      -0.02475658990442753,
      0.013774214312434196,
      -0.11973873525857925,
      0.02989477477967739,
      -0.04506809636950493,
      0.037739790976047516,
      -0.0021045575849711895,
      -0.07166249305009842,
      0.05557573214173317,
      0.013380272313952446,
      -0.09432675689458847,
      -0.032894909381866455,
      0.07510798424482346,
      0.04949590563774109,
      0.07602816075086594,
      0.11506126821041107,
      -0.035007596015930176,
      -0.03266353905200958,
      -0.034875091165304184,
      -0.011746927164494991,
      0.04168718680739403,
      0.08795219659805298,
      0.01413783710449934,
      0.03445999696850777,
      -0.013826238922774792,
      0.016842694953083992,
      -0.009744726121425629,
      -0.048510678112506866,
      -0.0328093059360981,
      -0.0669935867190361,
      -0.00018199876649305224,
      -0.024615777656435966,
      -0.012160290032625198,
      -0.030598511919379234,
      0.06645417213439941,
      0.05841726437211037,
      -0.03423767536878586,
      0.03221774101257324,
      0.016216091811656952,
      -0.04371814429759979,
      -0.04305902495980263,
      0.02611396834254265,
      -0.011446130461990833,
      -0.018693849444389343,
      0.08106576651334763,
      -0.04361137002706528,
      0.014792690984904766,
      0.06885232031345367,
      0.049984730780124664,
      -0.04277108982205391,
      -0.02062813937664032,
      -0.011363816447556019,
      0.037507954984903336,
      0.026215635240077972,
      0.07967208325862885,
      0.07798478752374649,
      -0.041051819920539856,
      0.0057599195279181,
      0.01684718206524849,
      -0.05545374006032944,
      -0.026533177122473717,
      0.06415248662233353,
      -0.02328435517847538,
      -0.020195795223116875,
      0.02742484211921692,
      0.007128414232283831,
      -0.014018341898918152,
      0.03248085081577301,
      0.08101276308298111,
      0.021821441128849983,
      0.05213472992181778,
      -0.001976174535229802,
      0.04780515283346176,
      -0.013385839760303497,
      -0.0015921365702524781,
      -0.04447838291525841,
      0.018205856904387474,
      0.038205862045288086,
      0.014163453131914139,
      -0.005343641620129347,
      -0.018338985741138458,
      -0.020422838628292084,
      0.03968953713774681,
      0.014251667074859142,
      -0.10095958411693573,
      0.0027388702146708965,
      0.01026763767004013,
      -0.021616149693727493,
      -0.06416907906532288,
      -0.0109788216650486,
      0.014497984200716019,
      0.003743532346561551,
      0.00563879543915391,
      0.02434007078409195,
      0.005728351883590221,
      -0.036265790462493896,
      -0.03604213148355484,
      -0.06874142587184906,
      0.04518761858344078,
      -0.03716842457652092,
      0.02511974237859249,
      0.01073444914072752,
      0.062224503606557846,
      0.00015884332242421806,
      -0.060992974787950516,
      5.544999658013694e-05,
      0.02855212427675724,
      -0.008088001981377602,
      0.041518040001392365,
      0.006885093171149492,
      0.038834404200315475,
      0.10307105630636215,
      0.01692509651184082,
      -0.037807121872901917,
      -0.03583752363920212,
      0.06608687341213226,
      0.07136894762516022,
      -0.004073218442499638,
      -0.015215267427265644,
      -0.01785777509212494,
      0.007170564495027065,
      -0.025683144107460976,
      -0.017966119572520256,
      -0.010891856625676155,
      -0.03332953155040741,
      -0.0343841053545475,
      0.02339622937142849,
      -0.025109758600592613,
      -0.04137361794710159,
      0.01513311080634594,
      -0.00039180010207928717,
      -0.017491186037659645,
      0.016697010025382042,
      0.03750256821513176,
      -0.062994085252285,
      -0.023318925872445107,
      -0.01167276594787836,
      -0.016216941177845,
      0.033811211585998535,
      -0.0018294301116839051,
      0.023315537720918655,
      -0.07658175379037857,
      0.0418095663189888,
      0.0759672224521637,
      0.05098101124167442,
      -0.11386550217866898,
      0.029505226761102676,
      0.035183124244213104,
      0.042955316603183746,
      0.00516417296603322,
      0.00011330706183798611,
      0.0091598741710186,
      0.03771702200174332,
      0.022988218814134598,
      0.04612691327929497,
      0.022198263555765152,
      0.03003181517124176,
      -0.03309185057878494,
      -0.04894702881574631,
      0.045123789459466934,
      0.0714413970708847,
      0.01635829173028469,
      0.004364976659417152,
      0.02587045356631279,
      -0.012061426416039467,
      -0.014439097605645657,
      -0.010243136435747147,
      0.00482325442135334,
      0.04457593336701393,
      -0.03157791495323181,
      -0.014182662591338158,
      -0.09716486185789108,
      0.02332925796508789,
      -0.029838217422366142,
      0.012711255811154842,
      -0.004262877162545919,
      -0.04909776151180267,
      -0.04135949909687042,
      -0.049125976860523224,
      -0.009963411837816238,
      0.04758809134364128,
      -0.0722775012254715,
      -0.04460826516151428,
      0.003263652790337801,
      0.007431247271597385,
      -0.017446409910917282,
      0.02545088715851307,
      -0.03657935932278633,
      -0.008771151304244995,
      -0.014110695570707321,
      -0.059098728001117706,
      -0.038406990468502045,
      0.003730552038177848,
      -0.08058598637580872,
      0.007221844978630543,
      0.06808552145957947,
      -0.05664912983775139,
      -0.08003431558609009,
      -0.04161399230360985,
      -0.031312357634305954,
      -0.037134356796741486,
      0.008642823435366154,
      0.027736589312553406,
      0.00450702803209424,
      -0.03141969442367554,
      -0.07443644106388092,
      -0.02959483116865158,
      0.002925063716247678,
      -0.0165399257093668,
      0.03453976660966873,
      0.008420323021709919,
      0.011591664515435696,
      -0.047717612236738205,
      0.098440982401371,
      -0.05548742040991783,
      0.009935545735061169,
      -0.021328603848814964,
      0.03795798867940903,
      -0.07340647280216217,
      -0.011571557261049747,
      0.03316989541053772,
      0.03305082023143768,
      0.04787726327776909,
      0.019158510491251945,
      -0.04504906386137009,
      0.03728007897734642,
      0.04615573212504387,
      0.03882734850049019,
      0.0401415079832077,
      0.08086735755205154,
      0.005540289916098118,
      0.002396219875663519,
      -0.02061036229133606,
      -0.018234461545944214,
      0.05909605324268341,
      0.0011724415235221386,
      0.03826995566487312,
      -0.051666583865880966,
      -0.03736654296517372,
      -0.033096540719270706,
      -0.008662438951432705,
      -0.058246221393346786,
      -0.07690823823213577,
      -0.0888531282544136,
      0.0005339353810995817,
      -0.06851409375667572,
      0.07083474099636078,
      0.09546385705471039,
      0.005289842374622822,
      0.002870966214686632,
      -0.06925351172685623,
      -0.010657469742000103,
      -0.0043985266238451,
      -0.007879883982241154,
      -0.03222831338644028,
      -0.02798253484070301,
      -0.0685778558254242,
      -0.07182188332080841,
      -0.056089311838150024,
      0.05233806371688843,
      -0.004511535633355379,
      -0.016507640480995178,
      -0.006041703280061483,
      -0.0115633774548769,
      0.03685787692666054,
      -0.046040963381528854,
      -0.012906397692859173,
      -0.05651047080755234,
      -0.0027009707409888506
    ],
    [
      0.03318462148308754,
      0.07056868076324463,
      -0.01783902756869793,
      -0.028336912393569946,
      0.03404231369495392,
      0.023625990375876427,
      0.023327047005295753,
      0.09112276136875153,
      -0.031861547380685806,
      0.030992431566119194,
      -0.03152031823992729,
      0.007731609512120485,
      -0.0308527834713459,
      -0.06220540776848793,
      -0.08951981365680695,
      -0.01880757138133049,
      -0.036813922226428986,
      -0.05031457543373108,
      -0.0851764902472496,
      -0.08009553700685501,
      -0.049496959894895554,
      0.03528902679681778,
      0.02090059034526348,
      -0.013385034166276455,
      -0.07448090612888336,
      -0.06320763379335403,
      -0.03504646196961403,
      0.09998590499162674,
      0.029149172827601433,
      -0.049135901033878326,
      -0.03645782917737961,
      -0.028304969891905785,
      -0.01220381073653698,
      -0.014688091352581978,
      -0.023060115054249763,
      -0.016951311379671097,
      -0.09112013131380081,
      -0.01090139988809824,
      -0.018533926457166672,
      -0.08067876100540161,
      0.021890677511692047,
      0.012129631824791431,
      0.0250691045075655,
      0.008111361414194107,
      0.08677464723587036,
      0.04388818517327309,
      0.010385929606854916,
      0.03193584084510803,
      -0.006284050177782774,
      0.013159533962607384,
      -0.1017749235033989,
      -0.05362531170248985,
      0.00584492227062583,
      0.031083084642887115,
      -0.020188309252262115,
      -0.0023105244617909193,
      0.036093149334192276,
      0.03350061923265457,
      -0.029545994475483894,
      0.01442465465515852,
      0.03650239482522011,
      0.062039364129304886,
      0.05890807509422302,
      0.0007893189322203398,
      0.015372490510344505,
      0.06830982118844986,
      0.054621029645204544,
      0.005725948605686426,
      0.010554838925600052,
      -0.012104930356144905,
      0.0589769072830677,
      0.019778484478592873,
      -0.03402993083000183,
      0.0029198487754911184,
      0.009212150238454342,
      0.04880817234516144,
      -0.001502207014709711,
      -0.02090456709265709,
      0.0045469943434000015,
      -0.03374677896499634,
      -0.051783494651317596,
      -0.010811181738972664,
      -0.06448347866535187,
      0.007132054306566715,
      0.0003798511461354792,
      -0.0007730603683739901,
      -0.047254376113414764,
      0.011361302807927132,
      -0.004560071974992752,
      0.01969534531235695,
      0.01575896330177784,
      0.07079339772462845,
      -0.04873066395521164,
      -0.016192495822906494,
      -0.03534281998872757,
      -0.0014665264170616865,
      0.010581456124782562,
      -0.023263772949576378,
      -0.059246260672807693,
      -0.01027082372456789,
      -0.008829440921545029,
      -0.023185692727565765,
      0.04660223051905632,
      0.06950397789478302,
      0.013800155371427536,
      -0.0023304475471377373,
      0.05277574062347412,
      -0.01207237970083952,
      0.015120267868041992,
      -0.03889771178364754,
      -0.008385229855775833,
      0.05135868862271309,
      0.05259125307202339,
      -0.011025819927453995,
      -0.02779492363333702,
      0.029256192967295647,
      -0.023922258988022804,
      0.06529245525598526,
      -0.014457392506301403,
      0.045072879642248154,
      0.04547123983502388,
      0.030953016132116318,
      0.020522741600871086,
      -0.011136316694319248,
      0.006383509840816259,
      0.07744599133729935,
      0.02488868497312069,
      0.05106399580836296,
      -0.05120519921183586,
      -0.05352535843849182,
      -0.03833930566906929,
      -0.03492169827222824,
      -0.05536559596657753,
      -0.02844051457941532,
      -0.017121916636824608,
      -0.0014028094010427594,
      0.049669645726680756,
      -0.009251365438103676,
      -0.01789448782801628,
      0.05748436227440834,
      -0.04932921379804611,
      0.06514332443475723,
      -0.030984148383140564,
      0.05985823646187782,
      -0.028272971510887146,
      0.01382174901664257,
      0.020330248400568962,
      0.03539533540606499,
      -0.016139809042215347,
      -0.050033606588840485,
      0.0009648217237554491,
      -0.05268830060958862,
      -0.026374131441116333,
      0.001168153597973287,
      0.0369756743311882,
      -0.026513217017054558,
      -0.044892940670251846,
      -0.047953128814697266,
      0.07189305126667023,
      -0.028661802411079407,
      0.017261484637856483,
      -0.07543498277664185,
      -0.007421528920531273,
      0.003864649683237076,
      -0.04720783606171608,
      0.034596238285303116,
      -0.015913378447294235,
      -0.019249115139245987,
      -0.03221164643764496,
      -0.04238458722829819,
      0.03900936618447304,
      -0.07299216836690903,
      -0.0250770952552557,
      0.045815277844667435,
      -0.019695738330483437,
      0.05025601387023926,
      0.077144555747509,
      0.005247306078672409,
      0.0315542072057724,
      -0.041228536516427994,
      -0.08042866736650467,
      -0.07789245992898941,
      -0.07788717746734619,
      0.03346491605043411,
      -0.0307628121227026,
      0.0089707151055336,
      0.056357014924287796,
      -0.02473544143140316,
      -0.08330149203538895,
      0.004241930786520243,
      -0.021302295848727226,
      -0.005079795606434345,
      -0.02282875031232834,
      -0.06151121109724045,
      -0.00806044414639473,
      0.03948964551091194,
      0.007983015850186348,
      -0.0460159033536911,
      -0.0590464286506176,
      -0.06693315505981445,
      -0.020159222185611725,
      0.03182031214237213,
      0.03091670200228691,
      -0.03636348247528076,
      -0.041611798107624054,
      0.01147778145968914,
      0.006212929263710976,
      -0.07359597831964493,
      -0.06180817261338234,
      -0.03702637553215027,
      -0.012906075455248356,
      0.06491916626691818,
      0.024123357608914375,
      0.03958987072110176,
      0.024063000455498695,
      0.026162071153521538,
      -0.03226213529706001,
      0.01689426228404045,
      0.02500695362687111,
      -0.006760347168892622,
      0.08615372329950333,
      0.05934487655758858,
      0.03188363462686539,
      0.05347975343465805,
      0.032719217240810394,
      -0.0940854474902153,
      -0.025390053167939186,
      -0.07018972933292389,
      0.034536875784397125,
      0.012161921709775925,
      -0.034006375819444656,
      0.044038839638233185,
      0.059918634593486786,
      0.0336194708943367,
      0.015615224838256836,
      -0.029293283820152283,
      0.14591579139232635,
      -0.028212854638695717,
      -0.002579003106802702,
      -0.010277995839715004,
      -0.03639707341790199,
      0.02360418438911438,
      0.03341878205537796,
      0.0039184060879051685,
      0.043321818113327026,
      -0.11248208582401276,
      0.09840444475412369,
      0.02578878216445446,
      -0.035579171031713486,
      -0.018658503890037537,
      0.0029923664405941963,
      0.006204079836606979,
      0.044159118086099625,
      -0.03220062702894211,
      -0.013547023758292198,
      0.01265011727809906,
      -0.048826321959495544,
      0.02118399366736412,
      0.0038321027532219887,
      -0.004715398885309696,
      0.005001184064894915,
      0.01206717174500227,
      0.05214986950159073,
      0.03523732349276543,
      -0.06922293454408646,
      -0.041750673204660416,
      -0.054215870797634125,
      0.025487413629889488,
      0.03666561841964722,
      0.017138924449682236,
      -0.03502180799841881,
      0.11715462058782578,
      0.030958183109760284,
      -0.0877411887049675,
      -0.07740171998739243,
      -0.04186415299773216,
      -0.048695508390665054,
      0.034711066633462906,
      -0.05915464460849762,
      -0.134810671210289,
      0.028158022090792656,
      0.060520075261592865,
      0.05736473575234413,
      -0.00884861871600151,
      -0.05759194493293762,
      -0.020814992487430573,
      0.0020513611380010843,
      -0.0767873004078865,
      -0.058115068823099136,
      -0.018742958083748817,
      -0.10687549412250519,
      -0.04775858297944069,
      0.016560692340135574,
      0.0026732266414910555,
      0.017457667738199234,
      0.013413682579994202,
      -0.0030001557897776365,
      -0.018949013203382492,
      -0.04290645942091942,
      0.037379056215286255,
      0.0859919935464859,
      -0.021279320120811462,
      -0.03485552966594696,
      0.10282053798437119,
      0.006117852870374918,
      0.06697268784046173,
      -0.013811659067869186,
      -0.026054851710796356,
      -0.0399126298725605,
      -0.0029690032824873924,
      -0.005906812380999327,
      0.041046954691410065,
      0.007398560177534819,
      -0.001757498481310904,
      0.005816923454403877,
      0.017472010105848312,
      -0.004156213253736496,
      -0.032682351768016815,
      -0.031495388597249985,
      0.0006964844069443643,
      0.056610673666000366,
      -0.031524062156677246,
      -0.01403713971376419,
      -0.026519782841205597,
      -0.05826247110962868,
      -0.0016008055536076427,
      0.079469233751297,
      0.08864911645650864,
      -0.1029050275683403,
      -0.0008171209483407438,
      -0.024233706295490265,
      -0.040264278650283813,
      0.02637157216668129,
      -0.03982071205973625,
      0.009244047105312347,
      -0.054521214216947556,
      -0.04698764160275459,
      0.028144504874944687,
      -0.04326567053794861,
      -0.0280059352517128,
      0.044466037303209305,
      -0.012655607424676418,
      -0.006459728814661503,
      -0.0612063892185688,
      -0.05060075968503952,
      -0.01132020354270935,
      -0.05646668002009392,
      0.0611514151096344,
      0.051889821887016296,
      -0.011616887524724007,
      0.03654782474040985,
      -0.0110648013651371,
      0.012765925377607346,
      -0.002593657001852989,
      0.07063610106706619,
      0.014596443623304367,
      0.020560016855597496,
      0.0016634211642667651,
      0.010289243422448635,
      0.00662322249263525,
      0.057761892676353455,
      -0.0031964676454663277,
      -0.015774531289935112,
      -0.02392376773059368,
      -0.04726085811853409,
      -0.037600431591272354,
      -0.002784468699246645,
      0.039024367928504944,
      -0.049985550343990326,
      -0.025671469047665596,
      0.024945665150880814,
      -0.0573122538626194,
      -0.09077770262956619,
      0.03776099532842636,
      -0.007457456551492214,
      -0.010637810453772545,
      -0.00937703624367714,
      -0.04041823372244835,
      0.05021171271800995,
      0.02591506764292717,
      0.006941875442862511,
      0.004808366764336824,
      -0.07048103213310242,
      0.028975917026400566,
      -0.010660406202077866,
      -0.08299645036458969,
      -0.03482707589864731,
      0.027202142402529716,
      0.005440994631499052,
      -0.04369087889790535,
      0.058329202234745026,
      0.003867402207106352,
      -0.16511304676532745,
      0.005227127578109503,
      -0.05186999589204788,
      0.004671414848417044,
      0.043975815176963806,
      0.05219455435872078,
      0.005674984306097031,
      0.03867246210575104,
      -0.020944904536008835,
      0.00447535514831543,
      0.023720823228359222,
      -0.040610019117593765,
      -0.019076185300946236,
      -0.011595678515732288,
      0.028112372383475304,
      0.023907843977212906,
      -0.029754241928458214,
      -0.049875225871801376,
      -0.00026897608768194914,
      0.016743876039981842,
      0.03429485112428665,
      0.008194204419851303,
      0.03558696061372757,
      -0.017088623717427254,
      0.019876964390277863,
      0.03509126231074333,
      0.035066038370132446,
      0.09363153576850891,
      -0.00864735059440136,
      -0.12258449196815491,
      0.04027186706662178,
      -0.015607889741659164,
      -0.05956355854868889,
      0.01082982774823904,
      0.04810021445155144,
      0.08459480106830597,
      0.01778934895992279,
      0.05113022029399872,
      0.012454938143491745,
      0.017979303374886513,
      0.03882075473666191,
      0.04303738474845886,
      0.012690206058323383,
      -0.01835288107395172,
      0.07812667638063431,
      0.032305724918842316,
      -0.050379153341054916,
      0.0852620005607605,
      0.04199960455298424,
      -0.007069064769893885,
      -0.020698806270956993,
      0.012604440562427044,
      0.024399247020483017,
      -0.02979649044573307,
      0.042008452117443085,
      0.00512659689411521,
      0.07030688226222992,
      0.07600817084312439,
      -0.07349146157503128,
      0.057669054716825485,
      0.04990174248814583,
      0.024383103474974632,
      0.044888485223054886,
      0.06602837145328522,
      -0.017311979085206985,
      -0.0053042517974972725,
      0.04092206433415413,
      0.01530484389513731,
      -0.026612229645252228,
      -0.009638232178986073,
      -0.024608703330159187,
      0.004162807948887348,
      0.039672285318374634,
      0.11551368981599808,
      -0.005008751526474953,
      0.06888066977262497,
      0.06108595430850983,
      -0.002493382664397359,
      -0.11576404422521591,
      0.029104823246598244,
      0.049591273069381714,
      0.0161142535507679,
      0.023178018629550934,
      0.012102813459932804,
      -0.01402765978127718,
      0.0022677360102534294,
      0.0368032269179821,
      -0.022330913692712784,
      -0.04489180073142052,
      -0.012636198662221432,
      0.012179468758404255,
      -0.04438915476202965,
      0.04660016670823097,
      -0.04241316765546799,
      0.0925569012761116,
      0.012722779996693134,
      -0.008625919930636883,
      0.02192753739655018,
      0.007825671695172787,
      -0.12333693355321884,
      0.08493049442768097,
      -0.003933328669518232,
      0.010752076283097267,
      -0.009192130528390408,
      0.05381087586283684,
      0.007982378825545311,
      -0.045400120317935944,
      0.006202476564794779,
      0.031270358711481094,
      0.006805578246712685,
      -0.08547081053256989
    ],
    [
      0.027025682851672173,
      0.04335181787610054,
      0.07747475057840347,
      -0.04701780155301094,
      0.00013768805365543813,
      -0.00215607020072639,
      -0.05569647625088692,
      -0.026664234697818756,
      0.11592205613851547,
      0.05206937715411186,
      -0.019301887601614,
      -0.023565271869301796,
      0.02572261542081833,
      -0.020461484789848328,
      -0.021890437230467796,
      0.04849403724074364,
      -0.02792472019791603,
      -0.04707795009016991,
      0.021320423111319542,
      0.03248284012079239,
      0.028497440740466118,
      -0.04993085190653801,
      -0.07048214226961136,
      -0.016021372750401497,
      -0.061769504100084305,
      0.012294045649468899,
      -0.03274211660027504,
      0.11573304980993271,
      -0.07133032381534576,
      0.016193857416510582,
      -0.026696819812059402,
      -0.02315964177250862,
      -0.010177161544561386,
      0.015562372282147408,
      0.010208689607679844,
      0.05564069747924805,
      -0.025348717346787453,
      0.05520295724272728,
      0.008694699965417385,
      -0.04367448762059212,
      0.0016669166507199407,
      -0.00875609926879406,
      -0.023509100079536438,
      -0.006331362761557102,
      0.026440385729074478,
      -0.008077587932348251,
      0.04221457242965698,
      -0.04994681105017662,
      0.014852890744805336,
      -0.055329665541648865,
      0.0015594552969560027,
      0.017480840906500816,
      -0.009305349551141262,
      0.008897319436073303,
      -0.029884325340390205,
      0.047852497547864914,
      0.009797954931855202,
      0.03163977712392807,
      -0.05221601203083992,
      -0.04093552380800247,
      -0.009409699589014053,
      0.04973495006561279,
      0.007967089302837849,
      0.019652536138892174,
      0.01937340945005417,
      0.033920008689165115,
      0.02511669509112835,
      -0.06385419517755508,
      0.04926314204931259,
      -0.0343722328543663,
      0.08797385543584824,
      -0.027426820248365402,
      0.005746975541114807,
      -0.05517491698265076,
      0.08452911674976349,
      -0.0651949793100357,
      0.02592925727367401,
      -0.0797138512134552,
      0.037594787776470184,
      0.03599035367369652,
      -0.028124023228883743,
      -0.03813360258936882,
      -0.08317093551158905,
      0.005334846209734678,
      0.018831918016076088,
      0.006790452636778355,
      0.03635350242257118,
      0.05548480898141861,
      0.06419423967599869,
      -0.05975182726979256,
      0.053130894899368286,
      -0.06724516302347183,
      -0.011903224512934685,
      -0.0405738428235054,
      -0.023557359352707863,
      0.010005570948123932,
      -0.039732933044433594,
      -0.06911376863718033,
      0.04501810297369957,
      -0.054825492203235626,
      -0.019984137266874313,
      0.0071832905523478985,
      0.06790657341480255,
      -0.00962646584957838,
      -0.04381880536675453,
      -0.0429067499935627,
      0.0627640038728714,
      0.03210665285587311,
      0.06596944481134415,
      0.02274085022509098,
      0.06047287955880165,
      0.016740042716264725,
      -0.028438353911042213,
      -0.07272584736347198,
      0.008912522345781326,
      0.08640335500240326,
      -0.0019899236503988504,
      0.05343072861433029,
      0.0021956872660666704,
      -0.032684437930583954,
      0.08406909555196762,
      -0.014224362559616566,
      0.04822791740298271,
      -0.01040735375136137,
      -0.008489160798490047,
      0.010573443956673145,
      0.02823728881776333,
      0.005623264703899622,
      -0.09817910194396973,
      -0.003496966091915965,
      -0.015044407919049263,
      0.00948344636708498,
      -0.04535884037613869,
      0.014867335557937622,
      -0.003585231024771929,
      -0.03969014808535576,
      0.058234404772520065,
      0.03150017932057381,
      -0.0225000511854887,
      0.012179986573755741,
      -0.032068412750959396,
      0.0452176034450531,
      0.03406299278140068,
      0.004189338069409132,
      -0.05904209986329079,
      -0.035953592509031296,
      -0.020591823384165764,
      -0.037060242146253586,
      0.04570842161774635,
      -0.034542255103588104,
      -0.06542221456766129,
      -0.038495540618896484,
      -0.0011815429897978902,
      0.045854467898607254,
      0.054476331919431686,
      0.0015097521245479584,
      0.02541046030819416,
      -0.04212820529937744,
      0.010748829692602158,
      0.0785953477025032,
      0.028375621885061264,
      0.06403045356273651,
      0.04597990959882736,
      -0.00957623589783907,
      0.020024128258228302,
      0.03860461711883545,
      0.010570175014436245,
      -0.016655148938298225,
      0.03748035430908203,
      0.004461013711988926,
      0.02129286341369152,
      0.008074287325143814,
      -0.08316980302333832,
      -0.011270225048065186,
      -0.00021789960737805814,
      0.03514397144317627,
      -0.07710862904787064,
      -0.0917198583483696,
      -0.017720825970172882,
      -0.06280943751335144,
      0.02215251699090004,
      0.040143247693777084,
      0.0694725438952446,
      -0.0052411784417927265,
      -0.08471336215734482,
      -0.09615708142518997,
      -0.04565950110554695,
      0.018140289932489395,
      0.008868376724421978,
      -0.05443882942199707,
      0.02574862353503704,
      -0.031057575717568398,
      -0.014994234777987003,
      0.012401490472257137,
      0.012875701300799847,
      -0.01919673942029476,
      -0.00978240929543972,
      -0.021231435239315033,
      0.006315022241324186,
      0.0002568765194155276,
      -0.03461959958076477,
      -0.016580307856202126,
      -0.05689225345849991,
      0.05529451370239258,
      -0.00862966850399971,
      -0.03916708007454872,
      0.09056643396615982,
      0.006561532616615295,
      -0.11764325946569443,
      -0.007685877848416567,
      0.0030058547854423523,
      -0.07396519929170609,
      -0.031893663108348846,
      0.040598273277282715,
      -0.05331461504101753,
      -0.04671742767095566,
      0.04621870070695877,
      -0.02477339096367359,
      0.06634127348661423,
      -0.019075030460953712,
      0.07747417688369751,
      0.027749307453632355,
      -0.0023124366998672485,
      -0.03431513532996178,
      0.014201334677636623,
      -0.005388982128351927,
      0.03722922131419182,
      0.029810089617967606,
      -0.010641369968652725,
      0.028026500716805458,
      0.08341164141893387,
      0.046566441655159,
      0.05669244006276131,
      0.007493400946259499,
      0.01396951824426651,
      -0.00549647631123662,
      -0.024792667478322983,
      -0.0047522070817649364,
      -0.03825739398598671,
      0.01622978039085865,
      0.03052155300974846,
      0.026939671486616135,
      0.009334761649370193,
      -0.0350937694311142,
      -0.05709837004542351,
      -0.06447936594486237,
      -0.001225416548550129,
      0.05941874906420708,
      0.019894937053322792,
      -0.07358969002962112,
      0.0016862174961715937,
      -0.014563115313649178,
      -0.0702325627207756,
      -0.1318228840827942,
      0.04337308928370476,
      -0.0006801396375522017,
      -0.012045444920659065,
      0.02796449512243271,
      -0.06105814129114151,
      -0.10230816155672073,
      0.022149143740534782,
      0.06201834976673126,
      0.013003968633711338,
      0.05276864394545555,
      0.09058703482151031,
      0.046997327357530594,
      -0.02229963056743145,
      0.04912716522812843,
      0.0615682378411293,
      0.04543332755565643,
      -0.01800689287483692,
      -0.08702116459608078,
      0.034301623702049255,
      -0.0013854182325303555,
      0.021276088431477547,
      0.005895682610571384,
      -0.013659006915986538,
      -0.03959314897656441,
      -0.06969860196113586,
      -0.05365341156721115,
      -0.01804490014910698,
      -0.028478849679231644,
      -0.02576339989900589,
      -0.04472920671105385,
      -0.019990893080830574,
      -0.06480998545885086,
      0.06954647600650787,
      0.02160898968577385,
      0.017125705257058144,
      -0.00869810115545988,
      0.019324103370308876,
      -0.04381886497139931,
      0.009236980229616165,
      -0.010592710226774216,
      -0.004138949327170849,
      -0.0005176194244995713,
      -0.002278337487950921,
      0.0012697638012468815,
      -0.01642114855349064,
      -0.07091780006885529,
      0.046392861753702164,
      0.04612808674573898,
      0.025621304288506508,
      -0.027162272483110428,
      1.5051298760226928e-05,
      0.0006641578511334956,
      -0.020880747586488724,
      -0.023348240181803703,
      0.029932664707303047,
      -0.023591531440615654,
      -0.03550706058740616,
      -0.05061747878789902,
      0.07830724120140076,
      -0.005178945604711771,
      -0.023867232725024223,
      0.08163714408874512,
      -0.045047320425510406,
      -0.0031388720963150263,
      0.001100951456464827,
      -0.005635760724544525,
      -0.10880159586668015,
      0.0036775728221982718,
      0.016161900013685226,
      0.037273652851581573,
      -0.012678288854658604,
      -0.0027981367893517017,
      0.08347329497337341,
      0.06923006474971771,
      0.006447404623031616,
      -0.06053122133016586,
      -0.054618507623672485,
      0.03192627429962158,
      -0.013591861352324486,
      0.0993436649441719,
      0.03246096521615982,
      -0.05017798766493797,
      0.07885430753231049,
      -0.04952331632375717,
      -0.03766105696558952,
      -0.04639493674039841,
      -0.024843716993927956,
      0.05483756586909294,
      0.06190608814358711,
      -0.015770940110087395,
      -0.07932697981595993,
      0.006948114838451147,
      -0.016303973272442818,
      0.009275314398109913,
      0.024925263598561287,
      -0.06232428923249245,
      0.07414460182189941,
      0.025864165276288986,
      0.008884499780833721,
      -0.11963888257741928,
      -0.013781378045678139,
      0.044495172798633575,
      0.036508575081825256,
      -0.0019541422370821238,
      -0.016671501100063324,
      0.027264583855867386,
      -0.03981563821434975,
      -0.06353236734867096,
      -0.06657595932483673,
      0.08300837129354477,
      -0.09246869385242462,
      0.04106074944138527,
      -0.09314575791358948,
      0.06514852494001389,
      -0.028871068730950356,
      -0.028586428612470627,
      0.022980598732829094,
      -0.07177340239286423,
      0.06319786608219147,
      -0.005590007174760103,
      0.024112414568662643,
      -0.10249833017587662,
      -0.04038674384355545,
      0.002409129636362195,
      0.0774950310587883,
      0.00011256612924626097,
      0.05599382519721985,
      -0.07620441168546677,
      0.038274601101875305,
      0.02905484475195408,
      -0.020827466621994972,
      -0.03549736365675926,
      -0.05345524474978447,
      -0.06813319772481918,
      0.009756875224411488,
      0.034230057150125504,
      0.058405227959156036,
      0.05389171838760376,
      -0.05077209323644638,
      0.020430436357855797,
      0.006488296203315258,
      0.023113084957003593,
      -0.014948753640055656,
      -0.026489688083529472,
      -0.01823197677731514,
      -0.0169289018958807,
      0.0430060513317585,
      -0.00803912803530693,
      0.020098285749554634,
      0.0332791805267334,
      -0.048715706914663315,
      0.03481963276863098,
      0.059874992817640305,
      0.03969242423772812,
      -0.019734902307391167,
      0.07288959622383118,
      -0.004149628337472677,
      0.006556592416018248,
      0.04286433756351471,
      -0.0499814935028553,
      0.03491772338747978,
      -0.0002972052898257971,
      -0.04015372693538666,
      0.08133621513843536,
      -0.02074657380580902,
      0.09418467432260513,
      -0.04546958580613136,
      0.08841793239116669,
      0.006397956050932407,
      -0.023473910987377167,
      -0.01621163636445999,
      -0.050968293100595474,
      0.03150142729282379,
      0.03705110400915146,
      -0.05001259222626686,
      0.04721831902861595,
      0.0672139823436737,
      0.013199049048125744,
      -0.03254273533821106,
      0.00477640749886632,
      -0.0033237466122955084,
      -0.09439941495656967,
      -0.032347358763217926,
      0.05352237820625305,
      -0.07810647785663605,
      0.04084307327866554,
      -0.018747052177786827,
      0.042783208191394806,
      0.01429714635014534,
      -0.02421821840107441,
      -0.03635481372475624,
      0.03520814701914787,
      -0.03602491691708565,
      -0.015981504693627357,
      -0.024709010496735573,
      0.05301270633935928,
      -0.035287100821733475,
      0.0290213655680418,
      -0.03743349015712738,
      -0.007901335135102272,
      0.020987488329410553,
      0.0432121604681015,
      -0.03346414864063263,
      0.05230027064681053,
      0.015524554066359997,
      -0.006265480536967516,
      -0.0069053093902766705,
      -0.06566382944583893,
      -0.00479824747890234,
      0.008146876469254494,
      -0.05989822745323181,
      0.07741118222475052,
      -0.0340898223221302,
      0.010090317577123642,
      -0.02308374084532261,
      -0.030797535553574562,
      -0.04223380610346794,
      0.01733700931072235,
      0.007235853932797909,
      0.06141853332519531,
      0.026280155405402184,
      0.003138478845357895,
      0.021486366167664528,
      0.03395094349980354,
      -0.020785849541425705,
      -0.13557709753513336,
      -0.04138564318418503,
      -0.01588374376296997,
      -0.016351070255041122,
      -0.02985701896250248,
      0.11474334448575974,
      0.010458759032189846,
      0.014457772485911846,
      0.03782982751727104,
      -0.07464858889579773,
      0.04610365256667137,
      0.05334557965397835,
      0.06570698320865631,
      0.017138203606009483,
      0.06013894081115723,
      -0.07502228021621704,
      -0.03791634365916252,
      0.05694061145186424,
      0.022317994385957718,
      0.04838767647743225,
      0.11243195086717606,
      -0.017596561461687088,
      -0.024474749341607094,
      -0.017399905249476433
    ],
    [
      -0.04661327600479126,
      -0.07496791332960129,
      0.11104691028594971,
      -0.013792583718895912,
      -0.0008242168696597219,
      0.020694272592663765,
      -0.03178883343935013,
      -0.0073633803986012936,
      0.03087586909532547,
      0.04552242159843445,
      -0.0235116146504879,
      -0.0021285288967192173,
      -0.01589825563132763,
      0.04270618036389351,
      0.029239395633339882,
      0.013268587179481983,
      -0.009568349458277225,
      -0.028525488451123238,
      -0.0173404049128294,
      0.004425917286425829,
      -0.03624257072806358,
      0.02089303731918335,
      -0.030582979321479797,
      0.03533939644694328,
      -0.013750243932008743,
      0.03945481777191162,
      -0.03697790950536728,
      0.028560243546962738,
      0.04556567221879959,
      0.03396314010024071,
      0.05002901703119278,
      -0.026547875255346298,
      -0.02542220614850521,
      -0.006182330660521984,
      0.005285326391458511,
      -0.006402492057532072,
      -0.024731697514653206,
      0.0167122483253479,
      0.06519638001918793,
      0.03887171298265457,
      -0.03826310485601425,
      0.015124639496207237,
      -0.03704925253987312,
      -0.011883940547704697,
      0.0009187210234813392,
      0.07534071058034897,
      0.025213923305273056,
      -0.020900359377264977,
      0.07439007610082626,
      -0.02306709811091423,
      0.023268312215805054,
      0.020548798143863678,
      -0.011288171634078026,
      -0.03231353312730789,
      0.04020141065120697,
      0.08654509484767914,
      0.06707827746868134,
      -0.04537677764892578,
      0.009913318790495396,
      0.038971494883298874,
      0.010070889256894588,
      0.020629404112696648,
      0.03984057158231735,
      0.0029905568808317184,
      0.06424278765916824,
      0.03963993862271309,
      -0.054416850209236145,
      -0.0222800150513649,
      -0.07608108222484589,
      -0.06089024245738983,
      0.036193493753671646,
      -0.01841043308377266,
      0.02380705066025257,
      0.03494725003838539,
      -0.0998850092291832,
      -0.07956452667713165,
      -0.013167398981750011,
      -0.04406331107020378,
      -0.034130826592445374,
      -0.024173567071557045,
      0.07096811383962631,
      -0.05510714277625084,
      0.04229528084397316,
      0.035030294209718704,
      -0.014497190713882446,
      0.05852478742599487,
      0.05428512394428253,
      -0.03925188258290291,
      -0.001631857710890472,
      -0.04517124593257904,
      0.01360834576189518,
      0.045171186327934265,
      0.012963551096618176,
      0.010134592652320862,
      0.022324316203594208,
      0.03315244987607002,
      0.03412214294075966,
      -0.02170443721115589,
      0.017889434471726418,
      -0.03239345923066139,
      0.03705490007996559,
      -0.030167069286108017,
      -0.01488193217664957,
      -0.058174554258584976,
      0.05350339412689209,
      0.08269062638282776,
      -0.048210594803094864,
      0.0355893038213253,
      -0.017832066863775253,
      0.05412130057811737,
      -0.008290451020002365,
      0.006010166369378567,
      0.05003787577152252,
      0.02346004918217659,
      -0.007107049226760864,
      0.05621015653014183,
      0.01654096692800522,
      0.006346701644361019,
      0.01126681175082922,
      0.0002193656109739095,
      0.10320887714624405,
      -0.03805917873978615,
      -0.054604217410087585,
      -0.07349108159542084,
      0.029158271849155426,
      0.051700200885534286,
      0.014422429725527763,
      -0.0059798043221235275,
      -0.054356515407562256,
      -0.04344770684838295,
      -0.011399291455745697,
      -0.0009098346345126629,
      0.016265861690044403,
      -0.013450462371110916,
      -0.020283430814743042,
      0.08517176657915115,
      0.024042509496212006,
      -0.03460708260536194,
      -0.017385870218276978,
      -0.0016843866324052215,
      -0.06860259175300598,
      0.011525391601026058,
      -0.02745264209806919,
      0.030773354694247246,
      0.056532032787799835,
      -0.03426171839237213,
      -0.06614024192094803,
      0.0014576232060790062,
      0.04045379161834717,
      -0.05271167308092117,
      0.06638149172067642,
      0.040738001465797424,
      -0.03794204816222191,
      -0.02594599314033985,
      -0.05930542200803757,
      0.016974417492747307,
      0.04858463630080223,
      0.060064978897571564,
      -0.0024096497800201178,
      0.06455797702074051,
      0.03555656224489212,
      0.00038738540024496615,
      -0.006786570884287357,
      -0.00458409171551466,
      0.07642465829849243,
      -0.038441549986600876,
      0.0030131922103464603,
      0.028534749522805214,
      -0.0387459434568882,
      -0.025043558329343796,
      -0.02949432097375393,
      0.10430430620908737,
      -0.1354948878288269,
      -0.03594619035720825,
      -0.014896349981427193,
      -0.0411694161593914,
      0.013809761963784695,
      0.02742948569357395,
      0.022548580542206764,
      -0.01365583948791027,
      -0.014387178234755993,
      0.08537379652261734,
      -0.006205942016094923,
      0.07307548075914383,
      -0.11131435632705688,
      0.04284992069005966,
      -0.0158221535384655,
      0.03426647558808327,
      -0.08464742451906204,
      0.026682795956730843,
      0.0006816235836595297,
      0.0048292395658791065,
      0.014844653196632862,
      -0.06690530478954315,
      -0.01781289651989937,
      -0.004432873334735632,
      -0.001331770559772849,
      -0.06040758639574051,
      -0.03284985572099686,
      -0.0513867624104023,
      -0.06046491488814354,
      0.03715343400835991,
      -0.03638541325926781,
      0.04635133594274521,
      0.04441002011299133,
      -0.04860006272792816,
      0.05948372557759285,
      -0.04835609346628189,
      0.04152838885784149,
      0.031867772340774536,
      -0.04638756811618805,
      -0.03498253598809242,
      -0.006304201204329729,
      -0.03709325194358826,
      -0.008733745664358139,
      -0.006896127946674824,
      0.011796610429883003,
      0.07953070104122162,
      -0.021214623004198074,
      0.012812778353691101,
      0.031214987859129906,
      0.04655031859874725,
      -0.01921980455517769,
      0.03734291344881058,
      0.0554194301366806,
      -0.040765851736068726,
      -0.01588224433362484,
      0.11420164257287979,
      -0.020627353340387344,
      0.013699612580239773,
      0.052350662648677826,
      -0.01584072969853878,
      0.04280143603682518,
      -0.08415724337100983,
      0.00898132473230362,
      -0.04583895951509476,
      -0.03434673696756363,
      -0.061700668185949326,
      -0.04937707260251045,
      -0.03604552149772644,
      -0.0203261598944664,
      0.07890797406435013,
      0.05158470571041107,
      -0.003061177907511592,
      -0.07366376370191574,
      0.044625796377658844,
      -0.025983845815062523,
      -0.0664387121796608,
      -0.02045225352048874,
      -0.07433793693780899,
      0.0012288929428905249,
      0.0033951590303331614,
      -0.007321109063923359,
      -0.01980048231780529,
      0.02481657639145851,
      -0.037347447127103806,
      -0.03183896094560623,
      -0.03712842985987663,
      -0.012363635003566742,
      -0.06396356225013733,
      0.001261050347238779,
      -0.04440087825059891,
      0.02769211120903492,
      -0.03103114850819111,
      -0.026537630707025528,
      0.02474755421280861,
      0.006161688361316919,
      -0.024767618626356125,
      -0.023388540372252464,
      0.05666483938694,
      -0.04755874350667,
      0.0030686804093420506,
      0.05623781308531761,
      -0.00949064176529646,
      0.04373965039849281,
      -0.030679356306791306,
      0.022282198071479797,
      0.012807594612240791,
      -0.037196893244981766,
      0.024344129487872124,
      0.010131952352821827,
      -0.0028281384147703648,
      0.061669036746025085,
      0.10320263355970383,
      0.020755548030138016,
      -0.02437262423336506,
      -0.024712394922971725,
      -0.06449007242918015,
      0.06811849027872086,
      -0.012234869413077831,
      -0.07783263176679611,
      0.0007156016654334962,
      -0.0382692888379097,
      0.0462459921836853,
      0.043182484805583954,
      0.05903269350528717,
      0.11335412412881851,
      0.05799181014299393,
      0.10483848303556442,
      -0.019870996475219727,
      0.0044534290209412575,
      -0.013327626511454582,
      -0.038074519485235214,
      0.06021967902779579,
      -0.09086015820503235,
      0.07446391880512238,
      0.002313510049134493,
      -0.02219209261238575,
      0.03554416075348854,
      -0.02103297971189022,
      0.03066832199692726,
      0.0184002798050642,
      -0.0630519762635231,
      -0.022650277242064476,
      -0.04878857731819153,
      0.04699907824397087,
      0.036205172538757324,
      -0.04948810487985611,
      -0.027643047273159027,
      0.053831566125154495,
      -0.01000063493847847,
      0.010575140826404095,
      0.01226151455193758,
      -0.029161829501390457,
      0.0148172527551651,
      -0.027575593441724777,
      0.14455348253250122,
      0.05101080983877182,
      0.062467265874147415,
      -0.07635101675987244,
      -0.06724236905574799,
      -0.1266421675682068,
      -0.04231072589755058,
      -0.00824350118637085,
      -0.0064480178989470005,
      0.004273624625056982,
      0.0035693764220923185,
      -0.055706895887851715,
      -0.007731998339295387,
      0.03116510808467865,
      0.005297637544572353,
      -0.021013502031564713,
      0.0214174035936594,
      0.07322537899017334,
      -0.06302624940872192,
      -0.02536449395120144,
      -0.035066504031419754,
      0.13702312111854553,
      0.05318210646510124,
      0.04287846013903618,
      0.04263223335146904,
      0.018196623772382736,
      -0.07236583530902863,
      0.031148536130785942,
      -0.0395633690059185,
      0.004940932150930166,
      -0.022875206544995308,
      -0.04621288925409317,
      -0.032476235181093216,
      0.007552675437182188,
      -0.013021907769143581,
      0.08187052607536316,
      0.09639796614646912,
      0.07963453233242035,
      0.04078692942857742,
      -0.0015575515571981668,
      0.03746367245912552,
      -0.027654483914375305,
      -0.021355241537094116,
      -0.0413365475833416,
      -0.03891583904623985,
      0.030019205063581467,
      -0.04854018613696098,
      -0.007462834008038044,
      0.030019307509064674,
      -0.08601735532283783,
      0.03761252388358116,
      -0.047642938792705536,
      -0.04135475307703018,
      -0.05225319787859917,
      0.021023474633693695,
      -0.058821119368076324,
      0.11795211583375931,
      -0.016156909987330437,
      0.05436229333281517,
      -0.02586204558610916,
      0.03329174965620041,
      -0.03647216036915779,
      0.020085055381059647,
      -0.04016094282269478,
      0.0420573353767395,
      0.03377329185605049,
      0.032855357974767685,
      0.01647278480231762,
      0.049934662878513336,
      -0.01723397895693779,
      0.00542838592082262,
      0.03999454528093338,
      0.016840115189552307,
      0.026305081322789192,
      -0.032678455114364624,
      -0.030255159363150597,
      -0.05227762833237648,
      0.07570282369852066,
      -0.013347945176064968,
      -0.0073577649891376495,
      0.043029848486185074,
      -0.06199658289551735,
      0.00701174046844244,
      -0.009375791996717453,
      -0.047209084033966064,
      0.08069439977407455,
      0.06360769271850586,
      -0.026189664378762245,
      0.021907059475779533,
      0.029134243726730347,
      0.00471468223258853,
      -0.026690663769841194,
      -0.03924758359789848,
      0.0031036364380270243,
      -0.02777351625263691,
      -0.004999650176614523,
      -0.049277957528829575,
      -0.009107457473874092,
      0.023684626445174217,
      -0.08557657897472382,
      0.056580111384391785,
      -0.0022708596661686897,
      0.07466547936201096,
      -0.01144375279545784,
      0.012992700561881065,
      -0.037078581750392914,
      -0.05534791573882103,
      -0.013418806716799736,
      -0.0008901340188458562,
      -0.03740934655070305,
      0.03613794967532158,
      -0.004769597668200731,
      -0.04321999102830887,
      0.03154671564698219,
      -0.01414917316287756,
      0.047825828194618225,
      -0.0052490439265966415,
      -0.010270385071635246,
      0.02243407443165779,
      0.013651113025844097,
      0.0314885675907135,
      0.055330052971839905,
      -0.0806514099240303,
      -0.0042625125497579575,
      0.035273388028144836,
      -0.04672141373157501,
      -0.04467972368001938,
      0.04350960999727249,
      0.026681575924158096,
      0.018016686663031578,
      -0.10439946502447128,
      -0.03121957555413246,
      -0.0047126999124884605,
      -0.01983913779258728,
      0.04086954891681671,
      -0.009035265073180199,
      -0.03829163312911987,
      -0.04391428083181381,
      0.0539790540933609,
      0.08399497717618942,
      0.009117469191551208,
      -0.017848309129476547,
      -0.01666087843477726,
      0.0016270302003249526,
      -0.1298687756061554,
      0.029392141848802567,
      0.02712165005505085,
      0.025217901915311813,
      -0.0703536719083786,
      0.0050470782443881035,
      -0.0014372833538800478,
      0.056911367923021317,
      -0.023042947053909302,
      -0.05970258265733719,
      0.030859341844916344,
      -0.009607579559087753,
      0.019406339153647423,
      -0.02419314719736576,
      -0.07151617854833603,
      -0.01933892071247101,
      -0.06656516343355179,
      0.005256513133645058,
      0.03969644755125046,
      0.06084489822387695,
      -0.05031309276819229,
      0.018709048628807068,
      0.0008208464132621884,
      0.0004862616187892854,
      0.022050298750400543,
      -0.07867862284183502,
      -0.06251826882362366,
      0.03918867185711861,
      -0.01147413533180952,
      0.0003211975272279233,
      0.009557346813380718,
      -0.03331747651100159,
      -0.06532406806945801
    ],
    [
      0.005296461749821901,
      0.008236981928348541,
      0.03503544628620148,
      0.024662552401423454,
      0.09794161468744278,
      -0.066108338534832,
      -0.04969862848520279,
      -0.06980432569980621,
      0.08028408885002136,
      -0.0019108193228021264,
      0.02298014983534813,
      -0.09248325973749161,
      -0.04413970187306404,
      -0.05594682693481445,
      -0.010507533326745033,
      -0.02023429609835148,
      -0.059086475521326065,
      -0.01908506453037262,
      -0.014858411625027657,
      -0.004191472195088863,
      -0.02549581602215767,
      0.010428141802549362,
      0.030903667211532593,
      -0.013422469608485699,
      -0.11915469169616699,
      0.03735269606113434,
      0.06370671093463898,
      0.028699155896902084,
      0.03390910476446152,
      -0.04739157482981682,
      0.07126785814762115,
      -0.07360200583934784,
      0.08189459145069122,
      0.03996581211686134,
      -0.05907946452498436,
      -0.008419839665293694,
      0.0732104554772377,
      0.025484252721071243,
      0.003954832907766104,
      -0.0018956323619931936,
      0.00027624773792922497,
      -0.08642221242189407,
      -0.03711608052253723,
      0.010607384145259857,
      0.09288952499628067,
      -0.03644263371825218,
      0.0050156982615590096,
      0.04524020478129387,
      0.02008977346122265,
      0.036383237689733505,
      0.00946082267910242,
      -0.07273011654615402,
      0.06535305082798004,
      -0.012231276370584965,
      -0.031345710158348083,
      -0.032768234610557556,
      0.0497157946228981,
      0.058935537934303284,
      0.015196727588772774,
      -0.019050730392336845,
      0.062153883278369904,
      -0.011345558799803257,
      -0.026464127004146576,
      -0.011479674838483334,
      -0.06995774060487747,
      -0.035691555589437485,
      -0.00912316795438528,
      -0.06329783797264099,
      -0.024424154311418533,
      -0.020520754158496857,
      -0.014733154326677322,
      -0.07871706783771515,
      0.08082138001918793,
      0.07362246513366699,
      -0.053128618746995926,
      0.05238356813788414,
      0.00476810522377491,
      -0.018925800919532776,
      0.04345822334289551,
      0.01019603293389082,
      -0.018955977633595467,
      -0.021579407155513763,
      -0.0334283672273159,
      0.036373455077409744,
      0.06097773090004921,
      0.0027163547929376364,
      -0.022459929808974266,
      -0.00307319313287735,
      0.045945871621370316,
      0.041642311960458755,
      0.03188140317797661,
      -0.0423220619559288,
      -0.06494002044200897,
      -0.012230958789587021,
      0.06974400579929352,
      -0.043981198221445084,
      -0.029913267120718956,
      0.017373310402035713,
      0.017276355996727943,
      0.009270922280848026,
      0.1118483915925026,
      0.0008881266112439334,
      -0.03487628698348999,
      0.09133277088403702,
      0.006059611681848764,
      0.03357776254415512,
      -0.02607608027756214,
      0.0314294695854187,
      -0.038738176226615906,
      -0.029536157846450806,
      0.04856766387820244,
      -0.08181565999984741,
      -0.0789821594953537,
      0.045337095856666565,
      -0.0833873450756073,
      -0.08376815170049667,
      -0.028238922357559204,
      0.019525671377778053,
      0.010986939072608948,
      0.025257540866732597,
      0.07211147248744965,
      0.03361247107386589,
      0.025492209941148758,
      0.06725117564201355,
      0.012995176948606968,
      0.06232965365052223,
      -0.0879354178905487,
      0.03758048638701439,
      -0.03254648298025131,
      0.03754173591732979,
      -0.022402215749025345,
      0.007244163192808628,
      0.007740681059658527,
      0.06675521284341812,
      0.012820839881896973,
      -0.056182507425546646,
      0.012557264417409897,
      0.036598287522792816,
      -0.03580309450626373,
      0.019120167940855026,
      -0.03631467744708061,
      0.03156610578298569,
      0.03023422323167324,
      0.03341907262802124,
      -0.00429579708725214,
      0.05532617121934891,
      -0.018647238612174988,
      0.03321165218949318,
      0.0026199230924248695,
      -0.08847761154174805,
      -0.04743879660964012,
      -0.034306008368730545,
      0.022026579827070236,
      -0.02870260924100876,
      0.020595936104655266,
      -0.02906070277094841,
      0.044099122285842896,
      -0.02144458331167698,
      0.02307925373315811,
      0.002477985108271241,
      0.03301733359694481,
      -0.016264505684375763,
      -0.00934048742055893,
      0.0007027312531135976,
      -0.008757288567721844,
      0.04125309735536575,
      -0.026842743158340454,
      0.01298443228006363,
      -0.05836629495024681,
      0.009993580169975758,
      -0.07249139994382858,
      0.022926852107048035,
      -0.01732267253100872,
      0.02502640336751938,
      -0.029193930327892303,
      -0.04202265664935112,
      0.019771726801991463,
      -0.01704985462129116,
      -0.09624411165714264,
      0.007377321831882,
      0.014657210558652878,
      0.03318129852414131,
      -0.06061188131570816,
      0.07248662412166595,
      -0.020875271409749985,
      -0.00523336511105299,
      -0.0516512505710125,
      -0.0449398048222065,
      -0.0025927142705768347,
      0.0025656160432845354,
      0.018063075840473175,
      -0.031101083382964134,
      0.010975507088005543,
      -0.008359277620911598,
      -0.010699830949306488,
      -0.07310570776462555,
      0.014296071603894234,
      -0.0660797730088234,
      0.07516747713088989,
      0.023616300895810127,
      -0.006837896071374416,
      -0.06734365224838257,
      0.02810538001358509,
      0.0581817626953125,
      0.0345015749335289,
      -0.021835878491401672,
      -0.057563260197639465,
      -0.014235047623515129,
      -0.00970613956451416,
      -0.006814117077738047,
      -0.0612713061273098,
      -0.013968212530016899,
      0.024868158623576164,
      0.05961380526423454,
      -0.07628709822893143,
      0.04251387342810631,
      0.026952192187309265,
      0.03511115536093712,
      0.0010832579573616385,
      0.0034017753787338734,
      0.03785309940576553,
      -0.036950744688510895,
      0.019977601245045662,
      0.059041738510131836,
      0.01590065099298954,
      0.07708386331796646,
      -0.027073610574007034,
      -0.0245168786495924,
      0.03499952331185341,
      -0.06020228937268257,
      0.07829257100820541,
      -0.0012433036463335156,
      -0.04620320349931717,
      0.045760490000247955,
      -0.022300368174910545,
      -0.0027542421594262123,
      -0.03948187828063965,
      -0.011295007541775703,
      0.02176133543252945,
      -0.00777848856523633,
      0.08693976700305939,
      -0.011769833974540234,
      0.036786794662475586,
      0.011219121515750885,
      0.06808429211378098,
      -0.0338301882147789,
      0.03797956928610802,
      0.02175923064351082,
      -0.05344989523291588,
      -0.06051289662718773,
      -0.018439972773194313,
      0.03726021945476532,
      -0.0010954105528071523,
      -0.04783286526799202,
      0.0376066155731678,
      0.037661198526620865,
      -0.03727737441658974,
      0.06194005161523819,
      -0.023262986913323402,
      0.045233067125082016,
      0.06973957270383835,
      0.003152629127725959,
      0.07592225074768066,
      0.0548548586666584,
      -0.006875887513160706,
      -0.024474581703543663,
      0.07245799899101257,
      -0.007527210284024477,
      -0.09892623871564865,
      0.002393343485891819,
      0.01994355209171772,
      0.03808074817061424,
      0.0362372100353241,
      -0.018880808725953102,
      -0.00482045067474246,
      0.10655900090932846,
      0.02559659816324711,
      -0.08590101450681686,
      0.03712429478764534,
      -0.028656328096985817,
      -0.03097432479262352,
      0.01881152205169201,
      0.03569986671209335,
      0.034295376390218735,
      -0.039255887269973755,
      0.031180083751678467,
      0.01951766572892666,
      0.0968998447060585,
      0.06440523266792297,
      0.054173462092876434,
      0.0067902496084570885,
      -0.027223484590649605,
      -0.012479935772716999,
      0.10263480991125107,
      0.02036663517355919,
      -0.021826766431331635,
      0.06147788465023041,
      0.025108743458986282,
      0.02406361699104309,
      -0.03674352914094925,
      -0.0372091643512249,
      0.023634670302271843,
      -0.03213650360703468,
      0.022525468841195107,
      -0.013513894751667976,
      -0.018745483830571175,
      0.0350225493311882,
      -0.028995687142014503,
      0.03560086712241173,
      0.009352070279419422,
      -0.009223241358995438,
      -0.060619235038757324,
      0.005040394142270088,
      0.040701545774936676,
      0.034764230251312256,
      -0.027613211423158646,
      0.07411079108715057,
      -0.0233005303889513,
      -0.013470313511788845,
      0.06827545166015625,
      0.03614690899848938,
      -0.02456740476191044,
      0.046576373279094696,
      -0.03621777892112732,
      -0.032861169427633286,
      -0.053956616669893265,
      0.04151513800024986,
      0.022163376212120056,
      -0.005923919379711151,
      -0.05302787572145462,
      -0.0757935643196106,
      -0.015351014211773872,
      -0.06593502312898636,
      0.04750436916947365,
      -0.06557267904281616,
      -0.0047815111465752125,
      0.04895545914769173,
      -0.007664300035685301,
      0.0022092433646321297,
      0.09747155755758286,
      0.003527712542563677,
      0.06601687520742416,
      0.017881149426102638,
      0.007069514598697424,
      0.008665948174893856,
      -0.033175040036439896,
      -0.04447444528341293,
      -0.06308650970458984,
      -0.007060237228870392,
      -0.023118095472455025,
      0.032262567430734634,
      0.05288013443350792,
      0.004868412856012583,
      0.07531709223985672,
      -0.054758839309215546,
      0.08361547440290451,
      0.01475790236145258,
      0.060277581214904785,
      -0.04483835771679878,
      -0.032649926841259,
      -0.009809858165681362,
      0.025365600362420082,
      0.08090610057115555,
      -0.026211898773908615,
      0.023882875218987465,
      -0.010098431259393692,
      0.07807977497577667,
      -0.0516149066388607,
      -0.057764120399951935,
      -0.06068561226129532,
      0.00528191402554512,
      0.0031118823681026697,
      -0.01516795065253973,
      -0.07079572975635529,
      0.048241570591926575,
      -0.04384341090917587,
      -0.08657480031251907,
      -0.009943729266524315,
      -0.07054027915000916,
      0.0032613452058285475,
      0.04488829895853996,
      0.010455046780407429,
      -0.0005504447617568076,
      0.02071339637041092,
      -0.021367622539401054,
      -0.044412761926651,
      -0.03322761505842209,
      0.06592290848493576,
      -0.029874779284000397,
      -0.04340191185474396,
      -0.028870807960629463,
      0.07419825345277786,
      -0.05139859393239021,
      -0.007236868143081665,
      -0.03386644273996353,
      -0.09457194805145264,
      -0.006340161431580782,
      0.040877897292375565,
      0.03537900000810623,
      0.04092009738087654,
      -0.010543742217123508,
      0.026989933103322983,
      0.03340182825922966,
      0.03426042199134827,
      -0.010164800100028515,
      0.03918461501598358,
      0.000732462212909013,
      -0.01188492402434349,
      -0.02846861258149147,
      0.03162648156285286,
      0.025456812232732773,
      -0.02884332649409771,
      0.011236830614507198,
      0.02269153483211994,
      -0.03691742196679115,
      -0.01334305852651596,
      -0.0011362888617441058,
      0.0036982835736125708,
      -0.024734411388635635,
      0.015133457258343697,
      0.027188217267394066,
      0.03566286340355873,
      0.009543394669890404,
      0.014729753136634827,
      -0.0185043066740036,
      0.08288078010082245,
      0.001857226132415235,
      0.04043157771229744,
      -0.040789052844047546,
      -0.04311971366405487,
      -0.05748976767063141,
      -0.033729635179042816,
      0.06983207166194916,
      0.019450388848781586,
      0.018017400056123734,
      -0.013498828746378422,
      0.10693985223770142,
      0.07842769473791122,
      0.016094017773866653,
      0.11077824234962463,
      0.009673142805695534,
      0.03835483640432358,
      -0.00861035380512476,
      0.04655194655060768,
      0.042180273681879044,
      -0.025113610550761223,
      -0.03568078577518463,
      -0.027133354917168617,
      0.005392574239522219,
      -0.022634951397776604,
      -0.04345962405204773,
      -0.029016638174653053,
      -0.024266762658953667,
      0.06790655106306076,
      -0.011954556219279766,
      0.009750586934387684,
      -0.022929135710000992,
      0.01197311282157898,
      0.11916915327310562,
      -0.0824967622756958,
      0.0005521487910300493,
      0.014861621893942356,
      0.025268016383051872,
      0.08324135839939117,
      0.04599756374955177,
      0.025445224717259407,
      -0.018365832045674324,
      -0.013961481861770153,
      -0.0036789404693990946,
      0.048542242497205734,
      -0.027842922136187553,
      -0.02654140070080757,
      0.023126991465687752,
      -0.04015297442674637,
      0.008852610364556313,
      -0.07955068349838257,
      -0.051068879663944244,
      -0.04502691701054573,
      -0.03031238541007042,
      0.0036027622409164906,
      0.007121511735022068,
      0.024772711098194122,
      -0.004652457311749458,
      0.013490140438079834,
      -0.07808616012334824,
      0.03397810459136963,
      -0.04734627529978752,
      -0.026606978848576546,
      0.040971122682094574,
      -0.06522955000400543,
      0.013645547442138195,
      0.04896237701177597,
      -0.018120793625712395,
      0.06955725699663162,
      0.03365630656480789,
      -0.006256760563701391,
      0.07324571162462234,
      -0.009303834289312363,
      0.004043199121952057,
      -0.08136267215013504,
      -0.06244290992617607,
      0.04528316482901573,
      0.02757061831653118
    ],
    [
      0.08835963159799576,
      -0.04518917575478554,
      -0.0014976590173318982,
      0.012871050275862217,
      -0.017389947548508644,
      -0.027262385934591293,
      -0.008837824687361717,
      -0.03916844353079796,
      -0.02233421988785267,
      -0.0028980057686567307,
      -0.0865430161356926,
      -0.0329948365688324,
      -0.007833939045667648,
      -0.03156980127096176,
      -0.014092156663537025,
      0.07522246241569519,
      0.0017975500086322427,
      0.027918314561247826,
      0.02887141704559326,
      0.04028312861919403,
      -0.01208493672311306,
      0.02480732463300228,
      -0.010670390911400318,
      0.041981033980846405,
      -0.03314701467752457,
      0.06408224254846573,
      0.017051953822374344,
      0.05960196629166603,
      0.031022338196635246,
      0.018074190244078636,
      0.03635265305638313,
      -0.02760346606373787,
      -0.007826064713299274,
      0.016020584851503372,
      0.07318826764822006,
      0.016847854480147362,
      0.008504563942551613,
      -0.040717437863349915,
      0.013909807428717613,
      0.029180217534303665,
      0.012860722839832306,
      0.08956991136074066,
      0.012882896699011326,
      -0.13842865824699402,
      0.12527687847614288,
      -0.005625366698950529,
      0.026827497407794,
      -0.09944858402013779,
      0.0001459214254282415,
      0.014207735657691956,
      -0.03477320075035095,
      -0.05072743073105812,
      0.041855067014694214,
      -0.05869074910879135,
      -0.06853432208299637,
      0.017277412116527557,
      0.013124536722898483,
      0.018823206424713135,
      -0.07651367038488388,
      0.052028365433216095,
      0.08181008696556091,
      0.11414184421300888,
      0.02574019320309162,
      0.05817634239792824,
      -0.10043096542358398,
      0.07371709495782852,
      -0.06576626002788544,
      0.04108315333724022,
      0.01026178803294897,
      0.053107548505067825,
      -0.00734615558758378,
      -0.06349252909421921,
      -0.013945701532065868,
      0.04433528333902359,
      0.031147116795182228,
      0.04824911057949066,
      0.031201818957924843,
      0.0018855576636269689,
      -0.002110357629135251,
      -0.009874695912003517,
      -0.00799187459051609,
      -0.004685520194470882,
      0.01888658106327057,
      0.019299309700727463,
      0.04475301876664162,
      -0.09963081777095795,
      0.019227897748351097,
      0.013485726900398731,
      0.10210786759853363,
      0.0008631089003756642,
      -0.05036560073494911,
      -0.02958589978516102,
      0.020104767754673958,
      -0.043875984847545624,
      -0.012733976356685162,
      0.026441151276230812,
      0.033646486699581146,
      0.04583778977394104,
      -0.03108806535601616,
      -0.06508570909500122,
      0.040194910019636154,
      -0.04382890835404396,
      0.032741397619247437,
      -0.03825739398598671,
      0.005462850909680128,
      0.10336285084486008,
      -0.008777129463851452,
      0.02843673899769783,
      -0.01878596656024456,
      0.04385145753622055,
      0.03370584174990654,
      -0.05305902287364006,
      0.0217191893607378,
      0.13096658885478973,
      0.04601067677140236,
      0.10062281787395477,
      0.03460973501205444,
      0.03209571912884712,
      0.06595829874277115,
      0.03611627221107483,
      -0.02834099531173706,
      0.008589881472289562,
      0.04477415233850479,
      -0.060035109519958496,
      -0.04991668462753296,
      -0.035301774740219116,
      0.0706661269068718,
      0.005055707413703203,
      -0.02439749240875244,
      0.026298651471734047,
      0.0258480217307806,
      0.07819262146949768,
      0.004918690770864487,
      -0.05985305458307266,
      -0.033973466604948044,
      0.007484956178814173,
      -0.03828536346554756,
      0.039194244891405106,
      -0.020106155425310135,
      0.006623185705393553,
      0.04781590402126312,
      0.02741178497672081,
      -0.06840245425701141,
      -0.03328714892268181,
      0.0009598985780030489,
      -0.005518054123967886,
      0.000721525982953608,
      -0.0077528259716928005,
      0.01662611775100231,
      -0.01155488658696413,
      0.00886894017457962,
      0.02300928346812725,
      0.04024219885468483,
      -0.032205261290073395,
      -0.020770050585269928,
      -0.057250749319791794,
      0.036969516426324844,
      0.05964116007089615,
      0.01857106015086174,
      0.04962064325809479,
      -0.07375641167163849,
      0.09719549119472504,
      -0.09263350069522858,
      -0.06603805720806122,
      0.012053423561155796,
      -0.008998571895062923,
      0.008392442017793655,
      -0.09933985024690628,
      0.020392093807458878,
      -0.06276267021894455,
      0.004074985161423683,
      -0.03789370879530907,
      -0.0505489781498909,
      -0.024656400084495544,
      0.006161564029753208,
      -0.0391019731760025,
      0.005833063740283251,
      0.055134117603302,
      0.04176432639360428,
      -0.04178149625658989,
      0.04544505849480629,
      -0.07419095933437347,
      -0.010060788132250309,
      0.020684542134404182,
      0.04464425519108772,
      -0.014644301496446133,
      -0.03710684925317764,
      -0.02867281623184681,
      -0.02794763818383217,
      0.061834610998630524,
      0.09403502196073532,
      -0.02542671374976635,
      -0.03107934072613716,
      0.005203552078455687,
      -0.016394317150115967,
      -0.007549569010734558,
      0.01980116404592991,
      0.0013007476227357984,
      0.03658966347575188,
      -0.019060811027884483,
      0.016215307638049126,
      0.10168810188770294,
      -0.06004539504647255,
      0.0347854308784008,
      -0.004620349034667015,
      0.022809317335486412,
      0.0017694559646770358,
      0.008835025131702423,
      0.08465894311666489,
      -0.016105428338050842,
      0.03143082186579704,
      0.013094136491417885,
      0.022688673809170723,
      0.03238554671406746,
      0.020258674398064613,
      -0.041106440126895905,
      -0.010920151136815548,
      0.04618128016591072,
      0.03341957926750183,
      -0.00609256885945797,
      0.05016690120100975,
      -0.03742337226867676,
      0.03628458455204964,
      -0.0027722532395273447,
      0.025831514969468117,
      -0.005203987937420607,
      -0.0033706771209836006,
      0.03394753485918045,
      -0.05475056171417236,
      -0.06127256900072098,
      -0.015962768346071243,
      0.028137389570474625,
      0.008219546638429165,
      0.0006911240052431822,
      -0.011602092534303665,
      0.027443543076515198,
      0.0029060859233140945,
      -0.0027607299853116274,
      -0.031012458726763725,
      -0.03849533572793007,
      0.01111117098480463,
      -0.0129518648609519,
      0.019949859008193016,
      -0.009736444801092148,
      0.03895746171474457,
      0.08639836311340332,
      -0.01928807981312275,
      0.0185608621686697,
      0.08185912668704987,
      0.008806873112916946,
      0.012127237394452095,
      -0.057559505105018616,
      -0.03498397395014763,
      -0.04108191281557083,
      -0.032997291535139084,
      -0.01269589364528656,
      -0.04522065073251724,
      0.016093604266643524,
      -0.0615607351064682,
      -0.023130031302571297,
      0.018236014991998672,
      0.026131829246878624,
      0.014168797992169857,
      -0.031210456043481827,
      0.0349600687623024,
      0.06904958933591843,
      -0.03892787545919418,
      -0.028309360146522522,
      0.011471744626760483,
      -0.02938780002295971,
      -0.11099287867546082,
      0.03948914632201195,
      -0.013435651548206806,
      -0.00937643926590681,
      -0.01630735769867897,
      0.09049472957849503,
      0.07690666615962982,
      0.022654913365840912,
      -0.037388451397418976,
      0.062245290726423264,
      -0.01286245696246624,
      0.019204389303922653,
      0.03151185065507889,
      -0.03897668793797493,
      0.03628497198224068,
      -0.0018694691825658083,
      0.036497678607702255,
      0.04460994526743889,
      -0.015866423025727272,
      0.030475370585918427,
      -0.045124080032110214,
      0.003631610656157136,
      0.013880060985684395,
      0.013838175684213638,
      0.029431290924549103,
      -0.006490709260106087,
      0.04628754034638405,
      -0.0012271840823814273,
      -0.005807990208268166,
      -0.009749125689268112,
      -0.023005487397313118,
      -0.0707278773188591,
      -0.05094793438911438,
      0.06258070468902588,
      -0.07183385640382767,
      -0.03621165081858635,
      -0.09339036792516708,
      -0.021573763340711594,
      -0.054218895733356476,
      0.013236208818852901,
      0.0004209351318422705,
      -0.05468938127160072,
      0.05850067362189293,
      0.049582161009311676,
      -0.04775448143482208,
      -0.0786655992269516,
      0.03992509841918945,
      -0.035305675119161606,
      -0.049694355577230453,
      0.057913221418857574,
      -0.04471554607152939,
      -0.057017408311367035,
      0.002490572165697813,
      -0.006094241514801979,
      -0.03649355471134186,
      -0.013242566026747227,
      -0.017741475254297256,
      0.0914243757724762,
      -0.016209078952670097,
      -0.017630210146307945,
      0.010060795582830906,
      0.014838813804090023,
      0.0008478683303110301,
      0.048662856221199036,
      0.04991702735424042,
      -0.051368821412324905,
      0.041325684636831284,
      0.03510211780667305,
      0.14329250156879425,
      0.023699503391981125,
      0.1024010181427002,
      -0.05541575700044632,
      -0.09192683547735214,
      0.1117955893278122,
      0.0017917549703270197,
      -0.026834990829229355,
      -0.06165226921439171,
      0.07519503682851791,
      0.03343350440263748,
      -0.037705130875110626,
      0.11146215349435806,
      0.043005622923374176,
      0.03324407339096069,
      -0.010344475507736206,
      0.002572017954662442,
      -0.01467104535549879,
      -0.06095490977168083,
      -0.0007527862908318639,
      0.052369989454746246,
      0.04584762454032898,
      0.04800816625356674,
      0.007579438854008913,
      0.015362861566245556,
      -0.01463740412145853,
      0.017234722152352333,
      0.04217945784330368,
      0.015693940222263336,
      -0.0010862102499231696,
      -0.009561230428516865,
      0.022141236811876297,
      -0.025253567844629288,
      0.044077809900045395,
      -0.029705585911870003,
      0.029448429122567177,
      -0.012684949673712254,
      -0.013888761401176453,
      0.04122951254248619,
      0.07178244739770889,
      0.09111709892749786,
      0.02425587922334671,
      0.002196836518123746,
      -0.03589436411857605,
      0.06438276171684265,
      0.06348447501659393,
      -0.051391586661338806,
      0.004260594956576824,
      0.04950685799121857,
      0.028264770284295082,
      0.07889179140329361,
      0.024490678682923317,
      -0.010575586929917336,
      -0.024254826828837395,
      0.052407156676054,
      -0.00709920609369874,
      0.021407999098300934,
      -0.029006121680140495,
      -0.00979174766689539,
      -0.0332990325987339,
      0.016574986279010773,
      -0.015889165922999382,
      0.017766982316970825,
      -0.0004615665238816291,
      0.05240033566951752,
      -0.032248739153146744,
      0.031588923186063766,
      -0.03198578953742981,
      -0.06377153843641281,
      -0.020436642691493034,
      0.053811196237802505,
      0.03949207440018654,
      -0.042101915925741196,
      0.04744875431060791,
      0.010697025805711746,
      0.05289876461029053,
      -0.0423891544342041,
      0.01488415990024805,
      -0.028890078887343407,
      -0.04593750834465027,
      0.019779829308390617,
      -0.005232608877122402,
      -0.01656651496887207,
      -0.014440654776990414,
      -0.004997584968805313,
      -0.07511911541223526,
      -0.04346239194273949,
      0.026404740288853645,
      0.011907720938324928,
      -0.023602081462740898,
      -0.004649685230106115,
      0.0377531573176384,
      -0.0012314787600189447,
      -0.0042169950902462006,
      -0.006814083084464073,
      0.039242882281541824,
      -0.0182704646140337,
      0.02297193370759487,
      -0.00720258429646492,
      -0.018709050491452217,
      -0.058286651968955994,
      -0.033539045602083206,
      0.009841070510447025,
      -0.029887065291404724,
      -0.096365787088871,
      -0.009916482493281364,
      0.04354047775268555,
      -0.051560111343860626,
      0.0533512718975544,
      0.03841511905193329,
      0.04815847799181938,
      0.0019835701677948236,
      -0.0012748594162985682,
      0.01359481643885374,
      -0.01866045966744423,
      -0.038010116666555405,
      0.027398835867643356,
      0.044128865003585815,
      0.057057544589042664,
      0.05727110058069229,
      0.03138583153486252,
      0.024125421419739723,
      -0.015608053654432297,
      -0.04878077656030655,
      -0.049832336604595184,
      0.0043708765879273415,
      -0.01105669979006052,
      -0.04244231432676315,
      -0.004267703276127577,
      0.0405251570045948,
      0.07178653031587601,
      0.010977585799992085,
      0.021517205983400345,
      -0.023729272186756134,
      -0.016320623457431793,
      0.04862391948699951,
      -0.052665144205093384,
      0.015957191586494446,
      0.01083247922360897,
      0.03764500096440315,
      0.03364410623908043,
      -0.05721932649612427,
      0.06883488595485687,
      0.04490641504526138,
      0.0813722088932991,
      0.0670413225889206,
      -0.014438292942941189,
      -0.029746701940894127,
      -0.06033162400126457,
      -0.016407359391450882,
      0.10804220288991928,
      0.058538686484098434,
      -0.027873452752828598,
      -0.05113118514418602,
      -0.03042873926460743,
      -0.06913696974515915,
      0.061750467866659164,
      -0.05942938104271889,
      0.07128766924142838,
      0.021587064489722252,
      -0.024423012509942055,
      0.08188878744840622,
      -0.020236866548657417,
      0.005603504367172718,
      -0.05736074596643448
    ],
    [
      0.0780908614397049,
      0.049098141491413116,
      -0.028562122955918312,
      -0.03536178916692734,
      0.01660526916384697,
      0.009655505418777466,
      0.014559316448867321,
      -0.020411411300301552,
      0.05221526697278023,
      0.060040127485990524,
      0.03766722232103348,
      0.043301910161972046,
      -0.022615160793066025,
      0.027767030522227287,
      -0.005651453509926796,
      -0.0084962984547019,
      -0.06079409271478653,
      -0.015265218913555145,
      -0.035552751272916794,
      0.02882220968604088,
      -0.017201218754053116,
      0.0028191471938043833,
      -0.006254717707633972,
      -0.037959273904561996,
      -0.07224299758672714,
      0.05053219944238663,
      0.09922877699136734,
      -0.023910701274871826,
      0.03801174834370613,
      -0.0014067842857912183,
      0.04234118014574051,
      -0.0005266728112474084,
      0.021325835958123207,
      0.03661220148205757,
      -0.03192296624183655,
      -0.03109639510512352,
      -0.030136335641145706,
      0.02981802076101303,
      0.07296805083751678,
      -0.007794763892889023,
      0.039558082818984985,
      -0.06973191350698471,
      0.02630232647061348,
      -0.006600119639188051,
      -0.03247499465942383,
      0.06735649704933167,
      0.039611052721738815,
      -0.02182205207645893,
      -0.05034881830215454,
      0.0021202201023697853,
      0.027321819216012955,
      0.02629822865128517,
      -0.043758150190114975,
      -0.03466639667749405,
      -0.023940281942486763,
      0.011166500858962536,
      -0.0543270967900753,
      -0.021175391972064972,
      -0.057648785412311554,
      -0.016807662323117256,
      -0.06269831955432892,
      -0.018193963915109634,
      0.0010280878050252795,
      0.010207577608525753,
      0.04780225083231926,
      -0.04436798021197319,
      -0.07862329483032227,
      -0.012198533862829208,
      0.017198286950588226,
      0.004508487414568663,
      0.01902802102267742,
      0.02853075973689556,
      -0.06747064739465714,
      0.046485286206007004,
      -0.03834621608257294,
      0.0056755682453513145,
      0.009718034416437149,
      -0.14064429700374603,
      -0.04720553383231163,
      0.00127869111020118,
      -0.08907392621040344,
      -0.033830173313617706,
      -0.06784842908382416,
      -0.05749230831861496,
      0.017537513747811317,
      0.02951900102198124,
      0.06791806221008301,
      0.050348278135061264,
      0.04857182502746582,
      0.01368258148431778,
      0.031296249479055405,
      -0.015041862614452839,
      0.032835379242897034,
      0.02792317233979702,
      0.10471250116825104,
      -0.04269874840974808,
      -0.0271243117749691,
      -0.054732728749513626,
      -0.00631967606022954,
      0.02613246627151966,
      0.06031281501054764,
      -0.08998937159776688,
      0.021653709933161736,
      -0.009286957792937756,
      0.04299912974238396,
      0.00791032612323761,
      -0.041343629360198975,
      0.04575817286968231,
      -0.04665730893611908,
      0.024753443896770477,
      -0.063227578997612,
      0.04073144495487213,
      0.023098470643162727,
      0.01409070286899805,
      -0.00461550010368228,
      0.04638337716460228,
      0.03883690387010574,
      -0.03841995447874069,
      0.042094528675079346,
      -0.016019955277442932,
      0.07625517249107361,
      -0.03037378005683422,
      0.08483850955963135,
      -0.010835832916200161,
      0.11732892692089081,
      0.009419454261660576,
      0.014125742949545383,
      0.07464741915464401,
      0.007475174032151699,
      -0.05199073255062103,
      -0.035797394812107086,
      -0.032267890870571136,
      -0.01933501660823822,
      -0.03671983629465103,
      -0.06603316962718964,
      -0.020435428246855736,
      -0.052175600081682205,
      0.004885358735918999,
      -0.005745994858443737,
      0.0500042550265789,
      -0.02183777093887329,
      0.050700366497039795,
      0.05651993304491043,
      0.0005821727099828422,
      -0.02020428515970707,
      0.027277402579784393,
      -0.07026790082454681,
      0.046169478446245193,
      -0.015681566670536995,
      -0.01253389474004507,
      -0.01609337329864502,
      -0.0393512137234211,
      -0.031683940440416336,
      -0.01259000413119793,
      0.008564104326069355,
      -0.03625980764627457,
      -0.10097944736480713,
      0.047885097563266754,
      0.003018780378624797,
      -0.04630333557724953,
      -0.03723682835698128,
      -0.0162789449095726,
      -0.07732044905424118,
      -0.0174571480602026,
      0.03829549252986908,
      0.030728742480278015,
      0.032840169966220856,
      0.020027773454785347,
      0.04149853065609932,
      -0.004886070266366005,
      -0.04293167218565941,
      0.06175863370299339,
      -0.0637628585100174,
      -0.02692493051290512,
      0.026995372027158737,
      -0.0309137050062418,
      -0.08163481950759888,
      0.09753654152154922,
      0.03367844596505165,
      0.047917310148477554,
      0.005723544396460056,
      0.019755655899643898,
      -0.03030484914779663,
      0.0786927193403244,
      0.13454975187778473,
      -0.09784232825040817,
      0.04725160449743271,
      0.01561551634222269,
      -0.11547576636075974,
      0.03423067554831505,
      -0.07058776170015335,
      0.033786021173000336,
      -0.05866049975156784,
      0.04313996061682701,
      -0.09284842014312744,
      0.001407467876560986,
      0.01439007930457592,
      -0.08019185811281204,
      0.01919044367969036,
      -0.02628619596362114,
      -0.04193747043609619,
      -0.042358800768852234,
      -0.03201577067375183,
      0.015818685293197632,
      -0.022164370864629745,
      -0.0754021555185318,
      0.03912123292684555,
      0.015291177667677402,
      0.06619513034820557,
      -0.036325857043266296,
      0.03888743370771408,
      0.023385755717754364,
      0.05090092867612839,
      -0.004030101001262665,
      -0.06944387406110764,
      -0.0764491930603981,
      0.03995184600353241,
      0.07582665234804153,
      -0.0292484350502491,
      -0.01752065122127533,
      -0.014426962472498417,
      0.06896462291479111,
      0.037645287811756134,
      0.023851685225963593,
      0.04367106407880783,
      0.020016424357891083,
      0.03554115816950798,
      0.05707421153783798,
      -0.020284472033381462,
      -0.04186085984110832,
      0.02947397343814373,
      0.07800884544849396,
      -0.06325185298919678,
      -0.011047273874282837,
      0.055552177131175995,
      -0.022165246307849884,
      0.007596651092171669,
      -0.033031366765499115,
      -0.04728863015770912,
      0.00156313122715801,
      -0.07495495676994324,
      -0.02269330993294716,
      -0.01752251200377941,
      -0.008064107038080692,
      -0.036600511521101,
      0.11443296819925308,
      0.07892496138811111,
      0.033028725534677505,
      0.04093699902296066,
      -0.007042429875582457,
      0.02947755716741085,
      0.008326594717800617,
      -0.03082416020333767,
      0.012617465108633041,
      -0.08952353149652481,
      -0.0267376359552145,
      -0.006300363223999739,
      -0.025217285379767418,
      -0.0550953634083271,
      -0.02700299210846424,
      -0.00480483565479517,
      0.015825241804122925,
      0.08233002573251724,
      0.018418550491333008,
      -0.060553524643182755,
      0.06292377412319183,
      0.054559048265218735,
      -0.010447653941810131,
      0.026278294622898102,
      -0.049523428082466125,
      0.018984533846378326,
      0.027771785855293274,
      -0.005111792590469122,
      0.038676850497722626,
      0.03948219120502472,
      -0.08428917080163956,
      -0.06302828341722488,
      -0.04585795849561691,
      0.014092957600951195,
      0.015880491584539413,
      -0.016700508072972298,
      0.03795300051569939,
      -0.01463613472878933,
      0.044325217604637146,
      0.016767365857958794,
      -0.007472367491573095,
      0.04023011028766632,
      0.016077885404229164,
      -0.04542052373290062,
      -0.01265182625502348,
      -0.06279709935188293,
      -0.09442958235740662,
      0.0023171030916273594,
      0.02124183066189289,
      0.04921003803610802,
      0.056534864008426666,
      -0.06574345380067825,
      0.017043184489011765,
      -0.02008724957704544,
      0.0053859674371778965,
      0.004289116710424423,
      -0.003085831645876169,
      0.039102062582969666,
      0.007292567286640406,
      0.024977117776870728,
      -0.02625536359846592,
      0.00039780314546078444,
      -0.09928753972053528,
      0.05247759073972702,
      -0.017826125025749207,
      0.0306862685829401,
      -0.07598647475242615,
      -0.07985104620456696,
      -0.040226638317108154,
      -0.025294771417975426,
      0.1263941377401352,
      -0.061948783695697784,
      -0.05097665265202522,
      -0.05777670815587044,
      0.06030718609690666,
      -0.04327212646603584,
      0.002561301225796342,
      -0.0625179260969162,
      0.03861585259437561,
      -0.03578335791826248,
      -0.03957443684339523,
      -0.03071388602256775,
      0.09123317897319794,
      0.011642778292298317,
      0.009760639630258083,
      0.05381157249212265,
      0.0016105512622743845,
      -0.08646171540021896,
      0.06654384732246399,
      0.06605369597673416,
      -0.026026403531432152,
      0.02948492020368576,
      -0.041588254272937775,
      0.042792368680238724,
      0.03471658006310463,
      0.017750930041074753,
      0.014748598448932171,
      -0.052960142493247986,
      -0.09536827355623245,
      0.023108256980776787,
      0.003880247939378023,
      -0.003665406722575426,
      0.0695946216583252,
      0.07466647773981094,
      -0.07080796360969543,
      0.007097007241100073,
      -0.00611090799793601,
      0.06149717792868614,
      -0.009141386486589909,
      -0.06630628556013107,
      0.02284436672925949,
      0.02807360514998436,
      -0.006621763575822115,
      -0.027244005352258682,
      -0.04808866232633591,
      0.04091189429163933,
      0.0348195806145668,
      -0.040648650377988815,
      0.07057549804449081,
      -0.0010145205305889249,
      0.027884531766176224,
      0.04321784898638725,
      0.0261808093637228,
      0.005485281348228455,
      0.031385794281959534,
      0.06115081161260605,
      -0.03793719783425331,
      -0.008057478815317154,
      -0.07954880595207214,
      -0.002803788287565112,
      -0.09997180104255676,
      -0.014603604562580585,
      -0.00831027701497078,
      -0.026913298293948174,
      -0.027553612366318703,
      -0.003578101983293891,
      0.0167555995285511,
      -1.1898658158315811e-05,
      0.005954893305897713,
      0.03241695091128349,
      -0.010048440657556057,
      0.024500856176018715,
      0.014992150478065014,
      0.047663215547800064,
      -0.07892707735300064,
      0.0021579312160611153,
      0.0003694480110425502,
      -0.019583093002438545,
      -0.04951077699661255,
      -0.02314532734453678,
      -0.13570213317871094,
      -0.02237829566001892,
      0.011433316394686699,
      0.06233340874314308,
      0.007378151174634695,
      -0.020413659512996674,
      0.07876282185316086,
      0.04221517965197563,
      -0.035116374492645264,
      -0.03788697347044945,
      0.03794540837407112,
      -0.0434759259223938,
      0.02190263383090496,
      0.05580697953701019,
      0.05638163536787033,
      0.015558742918074131,
      -0.0179293192923069,
      0.08832191675901413,
      0.04761631786823273,
      -0.022660860791802406,
      -0.06429165601730347,
      -0.008904754184186459,
      0.007358615752309561,
      0.040096551179885864,
      0.015506920404732227,
      0.006605175789445639,
      0.02608528360724449,
      0.04728533327579498,
      0.03176248073577881,
      0.09745396673679352,
      -0.05927525833249092,
      -0.00611732667312026,
      0.0069208466447889805,
      0.06924380362033844,
      -0.10260390490293503,
      0.022445809096097946,
      -0.10286325216293335,
      -0.07499512284994125,
      0.031877122819423676,
      -0.003451391588896513,
      -0.057202666997909546,
      -0.026493921875953674,
      0.07056757062673569,
      0.059378668665885925,
      -0.002437108661979437,
      -0.055124782025814056,
      0.02517043799161911,
      -0.02664114721119404,
      0.009468101896345615,
      0.02895684354007244,
      -0.016096940264105797,
      0.01358755212277174,
      0.005951749626547098,
      -0.033699363470077515,
      0.017785461619496346,
      0.0320185124874115,
      0.03175568953156471,
      -0.023936808109283447,
      0.045826978981494904,
      0.07124200463294983,
      -0.03913770616054535,
      0.013033326715230942,
      -0.010861415416002274,
      -0.0348854698240757,
      -0.08001628518104553,
      0.023241255432367325,
      -0.055367372930049896,
      -0.043811533600091934,
      0.058081887662410736,
      -0.04574423283338547,
      0.015624413266777992,
      0.0019971069414168596,
      -0.0024003772996366024,
      0.029566658660769463,
      0.05955994501709938,
      0.0750313401222229,
      -0.02911989763379097,
      0.022425612434744835,
      -0.0681658610701561,
      -0.03550681844353676,
      -0.07593876868486404,
      0.02293296717107296,
      0.07157763838768005,
      -0.030646810308098793,
      -0.055690620094537735,
      0.0024827311281114817,
      -0.044133104383945465,
      -0.04650646075606346,
      0.017573347315192223,
      0.03481181338429451,
      -0.009370092302560806,
      0.02253488451242447,
      -0.0078200064599514,
      0.08903741091489792,
      -0.07044146955013275,
      -0.036996014416217804,
      -0.024669628590345383,
      0.07144995778799057,
      -0.06445468217134476,
      0.044126518070697784,
      0.062364380806684494,
      0.04216134175658226,
      0.08880449831485748,
      0.02611071616411209,
      -0.008294455707073212,
      0.06411649286746979,
      0.019170908257365227,
      0.023942796513438225
    ],
    [
      0.03483153134584427,
      -0.02952800691127777,
      0.05780560150742531,
      -0.04050876945257187,
      -0.005051868036389351,
      -0.012087859213352203,
      -0.01494707353413105,
      0.015037636272609234,
      0.017529653385281563,
      0.01746823824942112,
      -0.043667443096637726,
      -0.07181347161531448,
      0.010174917057156563,
      0.05100574344396591,
      -0.07007040828466415,
      -0.07431399822235107,
      0.06418661028146744,
      -0.01341535709798336,
      -0.045478302985429764,
      -0.008835120126605034,
      0.019288988783955574,
      -0.009980376809835434,
      -0.0074068657122552395,
      -0.0064781904220581055,
      -0.02486986108124256,
      0.04749404639005661,
      -0.03575127199292183,
      0.07671747356653214,
      0.03267678618431091,
      0.13467828929424286,
      0.0745343491435051,
      -0.06688197702169418,
      -0.005750299897044897,
      -0.008857582695782185,
      -0.006027895957231522,
      -0.07183430343866348,
      0.11410113424062729,
      -0.002645668340846896,
      -0.011450286954641342,
      0.004790459293872118,
      -0.003828400978818536,
      -0.0172727070748806,
      0.03639620915055275,
      -0.018340758979320526,
      -0.00022878222807776183,
      -0.09544607996940613,
      -0.10894898325204849,
      -0.05398412048816681,
      0.0865967720746994,
      -0.012877218425273895,
      0.03605210781097412,
      -0.08002512156963348,
      -0.06570293009281158,
      0.010553790256381035,
      0.03092935122549534,
      0.008206748403608799,
      0.11624256521463394,
      0.008908356539905071,
      0.030595047399401665,
      -0.011814926750957966,
      0.02390664629638195,
      -0.025228630751371384,
      0.08793531358242035,
      -0.007530380040407181,
      0.06365524232387543,
      0.015351811423897743,
      -0.08089344948530197,
      -0.022563472390174866,
      0.01229601725935936,
      0.022789854556322098,
      0.03339962288737297,
      0.01932283490896225,
      0.028362184762954712,
      -0.05654162913560867,
      -0.10163090378046036,
      -0.03900307044386864,
      0.03653588891029358,
      -0.041664302349090576,
      0.010489067062735558,
      -0.016968563199043274,
      -0.0001664937735768035,
      -0.025956371799111366,
      -0.10891623795032501,
      0.03891497850418091,
      -0.006235502660274506,
      -0.008612536825239658,
      -0.10687510669231415,
      -0.044964179396629333,
      0.09288745373487473,
      -0.048536404967308044,
      -0.0035739836748689413,
      -0.012903259135782719,
      -0.009700399823486805,
      -0.09172722697257996,
      0.012017595581710339,
      -0.02172006666660309,
      -0.02987067401409149,
      -0.0015340453246608377,
      -0.06455223262310028,
      -7.646634185221046e-05,
      0.05942075699567795,
      -0.0049181142821908,
      -0.03693726286292076,
      -0.05640499293804169,
      0.05263864994049072,
      -0.0237258430570364,
      -0.007103615440428257,
      0.005818680394440889,
      0.030871057882905006,
      -0.010648737661540508,
      0.03464512154459953,
      -0.07607425004243851,
      0.02506253868341446,
      -0.0985138863325119,
      0.06563106924295425,
      0.004008290357887745,
      -0.009833413176238537,
      0.00012185733794467524,
      0.06011655554175377,
      -0.01704239659011364,
      0.07064034789800644,
      0.015809856355190277,
      0.027232224121689796,
      0.020216545090079308,
      0.032484542578458786,
      0.04202492535114288,
      0.0774061381816864,
      0.06151633337140083,
      -0.010244016535580158,
      -0.04107900336384773,
      -0.009319151751697063,
      -0.000967921398114413,
      -0.023033881559967995,
      0.01856840029358864,
      -0.13146600127220154,
      -0.05091346427798271,
      0.06063865125179291,
      -0.037375401705503464,
      0.09626222401857376,
      0.04965045675635338,
      -0.03095044195652008,
      0.012451710179448128,
      0.017506195232272148,
      0.023479482159018517,
      0.02005496807396412,
      -0.010572836734354496,
      -0.020119307562708855,
      -0.025915905833244324,
      0.005000606179237366,
      0.07341402769088745,
      0.0785990059375763,
      -0.03930526226758957,
      0.0021047990303486586,
      -0.028199607506394386,
      0.061821725219488144,
      -0.08256689459085464,
      0.025460321456193924,
      -0.01533502247184515,
      0.01206919364631176,
      0.030709637328982353,
      -0.07023698091506958,
      -0.03539794310927391,
      -0.09402845054864883,
      0.0075965565629303455,
      0.1252727061510086,
      0.018441177904605865,
      -0.0442054346203804,
      -0.036731258034706116,
      0.0015903445892035961,
      0.048790063709020615,
      0.09501732885837555,
      0.043763697147369385,
      -0.06078191474080086,
      0.08974659442901611,
      0.013357238844037056,
      -0.022884732112288475,
      -0.007491765543818474,
      0.006276996806263924,
      -0.011836636811494827,
      0.000597813748754561,
      -0.011587044224143028,
      0.12646625936031342,
      -0.029307980090379715,
      0.03164024278521538,
      -0.006308313459157944,
      -0.015800368040800095,
      0.0071443431079387665,
      0.07798890769481659,
      -0.053967881947755814,
      -0.01571318693459034,
      -0.05012766271829605,
      0.048627037554979324,
      -0.00448188791051507,
      -0.026362577453255653,
      0.07790893316268921,
      0.008890901692211628,
      0.014669002965092659,
      0.06966103613376617,
      -0.037914954125881195,
      -0.014273040927946568,
      -0.08165717124938965,
      0.05062660947442055,
      0.07680103182792664,
      0.027062850072979927,
      0.02408956177532673,
      0.04072754830121994,
      -0.05998340621590614,
      -0.08686964958906174,
      -0.012352433055639267,
      -0.07523927092552185,
      0.054346367716789246,
      0.021381748840212822,
      0.07351204752922058,
      0.04023436829447746,
      0.0419725701212883,
      -0.006140952464193106,
      -0.070570208132267,
      0.0722709372639656,
      -0.06384392082691193,
      -0.07734446972608566,
      0.061677902936935425,
      -0.0654653012752533,
      -0.031241239979863167,
      -0.043790675699710846,
      -0.05946824327111244,
      -0.035012420266866684,
      -0.020087894052267075,
      -0.07816234230995178,
      -0.09362459182739258,
      0.012781107798218727,
      0.023560766130685806,
      0.06224492937326431,
      -0.00850012805312872,
      0.02223425917327404,
      0.03458684682846069,
      -0.06640434265136719,
      0.02896145172417164,
      -0.07353384047746658,
      0.040196742862463,
      0.07092073559761047,
      0.0057997796684503555,
      -0.05889315903186798,
      -0.06476134061813354,
      -0.04241354763507843,
      -0.10056854784488678,
      -0.03361661359667778,
      -0.11960405856370926,
      0.007558536250144243,
      0.004360084887593985,
      -0.07670682668685913,
      -0.014336232095956802,
      0.0068951500579714775,
      -0.017311759293079376,
      -0.03649233654141426,
      0.0060246651992201805,
      0.009235138073563576,
      -0.02401094324886799,
      0.001472989795729518,
      -0.016673406586050987,
      0.01843865029513836,
      -0.056877490133047104,
      0.057551685720682144,
      0.016826678067445755,
      0.013394325040280819,
      0.050469718873500824,
      -0.05251535028219223,
      0.06903871148824692,
      0.025981727987527847,
      0.014018933288753033,
      0.034456100314855576,
      0.05328325927257538,
      0.06114477664232254,
      -0.016015615314245224,
      -0.0533192977309227,
      0.013226369395852089,
      -0.005221159663051367,
      0.038235943764448166,
      0.007618803530931473,
      -0.029546650126576424,
      -0.00914775487035513,
      0.02701335772871971,
      0.04857679829001427,
      0.029165256768465042,
      0.028441045433282852,
      -0.024929635226726532,
      -0.0659334734082222,
      -0.024388616904616356,
      -0.04946426302194595,
      -0.0054931663908064365,
      -0.013244173489511013,
      0.043439410626888275,
      -0.024138132110238075,
      -0.007879065349698067,
      -0.06625840812921524,
      -0.012188348919153214,
      0.0119856558740139,
      0.01791146770119667,
      -0.03492561727762222,
      -0.0179239921271801,
      0.0052011762745678425,
      -0.060774657875299454,
      0.08033950626850128,
      -0.004082015715539455,
      0.033466607332229614,
      0.043863989412784576,
      -0.07248369604349136,
      0.007403760217130184,
      -0.05321700870990753,
      0.09816648811101913,
      0.007426493335515261,
      -0.0006460754084400833,
      0.046174053102731705,
      -0.016334448009729385,
      0.058682069182395935,
      -0.03586135804653168,
      -0.07350261509418488,
      0.07361505180597305,
      -0.01174592413008213,
      -0.0002158577408408746,
      -0.0827704519033432,
      0.040027692914009094,
      -0.08595167100429535,
      -0.036454711109399796,
      -0.019455913454294205,
      0.007387440651655197,
      -0.06626172363758087,
      -0.0252327062189579,
      0.08023135364055634,
      -0.04494404420256615,
      -0.02887634187936783,
      0.0011791978031396866,
      0.025498101487755775,
      -0.0785437822341919,
      0.023068493232131004,
      -0.010876171290874481,
      0.025379318743944168,
      0.06932240724563599,
      0.0009959281887859106,
      0.026439404115080833,
      -0.002335937926545739,
      -0.044559985399246216,
      0.03631618618965149,
      0.13509146869182587,
      0.02610239014029503,
      -0.03580149635672569,
      -0.04483085870742798,
      -0.008885855786502361,
      0.13043458759784698,
      0.0553857646882534,
      0.037072744220495224,
      -0.009095542132854462,
      -0.0445864275097847,
      -0.02044973522424698,
      0.08289845287799835,
      -0.06926077604293823,
      -0.041285350918769836,
      -0.0026366000529378653,
      -0.026819899678230286,
      -0.046653423458337784,
      0.003857400268316269,
      -0.008052976801991463,
      -0.06570221483707428,
      0.03660450875759125,
      -0.015208708122372627,
      -0.030514048412442207,
      -0.033175136893987656,
      -0.03712688758969307,
      0.0227121002972126,
      0.055175475776195526,
      -0.0899372398853302,
      -0.03313140571117401,
      -0.0163362305611372,
      -0.06961555778980255,
      0.03839573264122009,
      0.04987107589840889,
      0.014797039330005646,
      -0.04917250573635101,
      -0.012341517955064774,
      0.06041775271296501,
      0.016647832468152046,
      -0.015822645276784897,
      -0.027665816247463226,
      -0.034049030393362045,
      -0.007608393207192421,
      -0.06764166057109833,
      -0.014584501273930073,
      0.028032444417476654,
      -0.11332506686449051,
      0.020183732733130455,
      -0.08941977471113205,
      0.006643547210842371,
      -0.043689973652362823,
      -0.008103170432150364,
      0.03860217332839966,
      0.024729479104280472,
      0.014493130147457123,
      -0.0468214750289917,
      -0.04201819375157356,
      0.008593476377427578,
      -0.014547541737556458,
      -0.030094023793935776,
      0.05521333962678909,
      0.0140302749350667,
      -0.04277488589286804,
      -0.034768637269735336,
      0.019954726099967957,
      -0.038658782839775085,
      -0.041176166385412216,
      -0.025378312915563583,
      -0.00799294002354145,
      0.04745052382349968,
      0.01747078262269497,
      0.03779077157378197,
      0.09309686720371246,
      -0.036855168640613556,
      -0.062483932822942734,
      -0.019352836534380913,
      -0.023994777351617813,
      -0.01746968738734722,
      0.07127818465232849,
      -0.04423544555902481,
      0.004815658088773489,
      -0.012984372675418854,
      -0.08081988245248795,
      0.004056851379573345,
      0.03876720741391182,
      0.0008579223067499697,
      -0.01668339967727661,
      0.05344187468290329,
      0.03070194274187088,
      0.010218357667326927,
      0.007296337746083736,
      0.06437141448259354,
      -0.047902971506118774,
      -0.0385746955871582,
      -0.029764803126454353,
      0.01585705392062664,
      0.006808181293308735,
      -0.025753680616617203,
      0.025722693651914597,
      0.02346506342291832,
      -0.04194270819425583,
      0.016721738502383232,
      -0.06827346235513687,
      -0.053611062467098236,
      -0.0722794309258461,
      -0.024036020040512085,
      -0.013908371329307556,
      -0.03236217424273491,
      0.05952594801783562,
      -0.041267529129981995,
      -0.03342288359999657,
      -0.04409743472933769,
      -0.14617067575454712,
      0.05782940983772278,
      -0.07607093453407288,
      0.0613376684486866,
      -0.0011676405556499958,
      0.00045900122495368123,
      0.01835455931723118,
      0.03497067466378212,
      -0.0951528400182724,
      -0.05999750271439552,
      0.04419855773448944,
      -0.05466775223612785,
      -0.02962176874279976,
      -0.0004884519730694592,
      -0.08293641358613968,
      -0.022544197738170624,
      0.01894252374768257,
      0.023179039359092712,
      -0.00034851720556616783,
      0.03136897087097168,
      -0.041637156158685684,
      0.04705546423792839,
      -0.09438909590244293,
      0.03493687883019447,
      -0.029453851282596588,
      -0.030710849910974503,
      0.004967246204614639,
      -0.00016372073150705546,
      0.02100973017513752,
      -0.10554920881986618,
      -0.05365854129195213,
      0.040544819086790085,
      -0.0675135999917984,
      0.011200486682355404,
      -0.010880745016038418,
      -0.08305732905864716,
      -0.06458639353513718,
      0.029613720253109932,
      -0.02606149949133396,
      0.025950133800506592,
      0.062294043600559235,
      0.06567932665348053,
      -0.043024126440286636,
      0.09226004034280777,
      -0.00490479776635766,
      -0.05199421942234039,
      0.05346575379371643,
      0.05843726545572281,
      0.0075721596367657185,
      0.04753716662526131
    ],
    [
      -0.003695717779919505,
      0.0711185559630394,
      -0.02416939102113247,
      -0.07753577083349228,
      0.053933143615722656,
      -0.018967295065522194,
      -0.02322245016694069,
      -0.059608425945043564,
      -0.004419013392180204,
      0.054401807487010956,
      -0.059925928711891174,
      0.017299005761742592,
      0.020196177065372467,
      0.014433031901717186,
      0.1063426285982132,
      0.09122242778539658,
      0.024157818406820297,
      -0.11267579346895218,
      0.060112062841653824,
      0.031977713108062744,
      0.04304075986146927,
      0.07333661615848541,
      0.01409604586660862,
      -0.08230865746736526,
      -0.03148789703845978,
      0.09811897575855255,
      -0.03583449497818947,
      0.005156208295375109,
      0.03044813685119152,
      -0.011213364079594612,
      0.13286557793617249,
      -0.009895477443933487,
      0.008546968922019005,
      0.0019600510131567717,
      -0.017420578747987747,
      -0.023172838613390923,
      0.020767074078321457,
      -0.01777368038892746,
      -0.02111244387924671,
      0.017104849219322205,
      0.07651744037866592,
      0.046235911548137665,
      0.08548814058303833,
      -0.04533952847123146,
      0.03274716064333916,
      0.10407248139381409,
      -0.04923640191555023,
      0.03042178973555565,
      0.03010270930826664,
      -0.047742605209350586,
      -0.018853770568966866,
      -0.05136971175670624,
      -0.041394077241420746,
      0.021292157471179962,
      0.08073464781045914,
      0.08786268532276154,
      0.053944870829582214,
      -0.011565538123250008,
      -0.035100068897008896,
      0.050700776278972626,
      -0.012384124100208282,
      -0.02109369821846485,
      -0.01866382732987404,
      0.00832392182201147,
      -0.031014397740364075,
      0.02939785085618496,
      -0.053983885794878006,
      -0.021024372428655624,
      -0.03161988407373428,
      0.011671195738017559,
      -0.05524769797921181,
      0.030067170038819313,
      -0.013291512615978718,
      -0.07502204179763794,
      -0.06311124563217163,
      0.009003182873129845,
      0.11343423277139664,
      -0.09508059173822403,
      0.05555366724729538,
      0.005793460179120302,
      -0.057595063000917435,
      0.005333441775292158,
      0.010244162753224373,
      -0.07949864119291306,
      -0.013402114622294903,
      0.004948052577674389,
      0.03486841544508934,
      -0.1123792976140976,
      0.012215724214911461,
      0.04848521575331688,
      -0.022317299619317055,
      -0.04333974048495293,
      0.015019034035503864,
      0.016782142221927643,
      -0.003131392877548933,
      0.023229409009218216,
      0.024746717885136604,
      -0.05822959169745445,
      0.033961791545152664,
      0.019817009568214417,
      0.018742075189948082,
      -0.03784548118710518,
      0.017824701964855194,
      -0.05997380614280701,
      0.026626674458384514,
      -0.00531168794259429,
      -0.07444369047880173,
      -0.09734970331192017,
      0.006812558975070715,
      -0.03140399977564812,
      -0.0775841474533081,
      0.03861035034060478,
      0.07005342096090317,
      0.06294691562652588,
      -0.030210189521312714,
      -0.09294136613607407,
      0.0007254031370393932,
      -0.025300776585936546,
      -0.0010142950341105461,
      -0.027726581320166588,
      0.04014277458190918,
      0.016048632562160492,
      0.013757073320448399,
      0.0021902688313275576,
      -0.047224260866642,
      0.02517777495086193,
      0.0019790767692029476,
      0.08686705678701401,
      -0.050645723938941956,
      -0.01650775410234928,
      -0.013947769068181515,
      0.07409081608057022,
      0.026293156668543816,
      -0.02237623557448387,
      -0.06900721788406372,
      0.010572192259132862,
      0.035224635154008865,
      0.0028192936442792416,
      -0.0021238450426608324,
      0.013171627186238766,
      0.034444887191057205,
      -0.019104378297924995,
      -0.0401177778840065,
      -0.007129076402634382,
      -0.020465753972530365,
      -0.01435883529484272,
      -0.06595782190561295,
      0.025229908525943756,
      -0.010314954444766045,
      0.018431872129440308,
      -0.0308602936565876,
      0.033889446407556534,
      0.06428077816963196,
      -0.052596841007471085,
      0.0598563514649868,
      -0.023663988336920738,
      0.006370261777192354,
      -0.012257573194801807,
      -0.09426289796829224,
      0.016738347709178925,
      -0.0041828700341284275,
      -0.05657843127846718,
      0.003155449638143182,
      0.06589508801698685,
      -0.07868405431509018,
      0.08912716805934906,
      -0.0429634265601635,
      0.0551847442984581,
      -0.018191980198025703,
      -0.08967193216085434,
      -0.04221830144524574,
      -0.06245749071240425,
      -0.004193974658846855,
      -0.003044529352337122,
      0.022896993905305862,
      -0.024049831554293633,
      -0.028563328087329865,
      -0.037621285766363144,
      0.05165015161037445,
      -0.020162303000688553,
      0.08085503429174423,
      0.04551072046160698,
      0.04952888563275337,
      0.05947647616267204,
      -0.019377833232283592,
      0.05169674754142761,
      0.03532848507165909,
      -0.004127016291022301,
      0.03158986568450928,
      0.019161773845553398,
      -0.02429649606347084,
      0.0553232803940773,
      0.07813791930675507,
      0.026225373148918152,
      -0.026367980986833572,
      -0.05873926728963852,
      -0.04929620027542114,
      0.04083408787846565,
      -0.09799976646900177,
      0.0212372075766325,
      -0.01602032594382763,
      -0.0977640151977539,
      0.04433179274201393,
      0.04631993547081947,
      0.04467953369021416,
      -0.04398125782608986,
      -0.037206731736660004,
      -0.0023158174008131027,
      -0.03342674672603607,
      0.028020348399877548,
      -0.04722993075847626,
      0.01618749089539051,
      0.04193937033414841,
      -0.06631634384393692,
      0.0589638389647007,
      -0.07859839498996735,
      -0.03845475614070892,
      -0.0205547958612442,
      -0.044680267572402954,
      -0.018702922388911247,
      0.08552480489015579,
      0.0070377918891608715,
      0.024137558415532112,
      0.029198337346315384,
      0.005570901557803154,
      0.00477641960605979,
      0.030360860750079155,
      -0.037663716822862625,
      -0.07602259516716003,
      -0.05576532706618309,
      0.024167178198695183,
      -0.0072255521081388,
      0.06254349648952484,
      0.03179462254047394,
      0.05926433578133583,
      -0.055928830057382584,
      0.021844705566763878,
      -0.024959644302725792,
      0.051268402487039566,
      -0.05009843409061432,
      0.001046372577548027,
      0.005961343180388212,
      0.0049372389912605286,
      -0.027919737622141838,
      0.050704702734947205,
      0.02924630045890808,
      -0.013104568235576153,
      0.014510874636471272,
      0.015377090312540531,
      -0.01085600070655346,
      0.016530025750398636,
      -0.08388515561819077,
      0.04924483597278595,
      0.0327044315636158,
      -0.043183598667383194,
      -0.08589994162321091,
      -0.006958502344787121,
      0.045933760702610016,
      0.03163616359233856,
      -0.0672142282128334,
      -0.018993612378835678,
      0.002238494111225009,
      -0.01798626407980919,
      -0.051190175116062164,
      -0.0474049374461174,
      -0.02983619086444378,
      0.02557467482984066,
      0.035396758466959,
      0.04773395135998726,
      -0.03341525048017502,
      -0.12101136147975922,
      0.016962692141532898,
      -0.0031704511493444443,
      0.032102104276418686,
      -0.030069882050156593,
      -0.02888844721019268,
      0.08329439908266068,
      -0.0007442057831212878,
      0.025317413732409477,
      -0.010525773279368877,
      0.017904741689562798,
      0.048405613750219345,
      0.055353499948978424,
      -0.012139655649662018,
      -0.05720685422420502,
      -0.11169452220201492,
      0.022005653008818626,
      -0.013335161842405796,
      0.004197199363261461,
      -0.027234597131609917,
      0.03350174427032471,
      -0.07228546589612961,
      -0.08452986180782318,
      -0.01855406165122986,
      0.027884839102625847,
      -0.008323969319462776,
      0.07588852941989899,
      -0.027262872084975243,
      0.03256281837821007,
      -0.10353431105613708,
      -0.0171466413885355,
      0.000536535691935569,
      -0.02330031804740429,
      0.08275828510522842,
      0.0017583280568942428,
      -0.033317871391773224,
      -0.02908254787325859,
      -0.029324157163500786,
      -0.028182456269860268,
      -0.030888602137565613,
      -0.050027236342430115,
      0.05646708235144615,
      0.10509927570819855,
      -0.04553055018186569,
      -0.026653673499822617,
      0.009061258286237717,
      -0.05309393256902695,
      0.012154723517596722,
      0.0013280929997563362,
      0.008173801004886627,
      0.03068525157868862,
      0.03798147663474083,
      -0.013621598482131958,
      0.09440184384584427,
      -0.0867391899228096,
      0.0010824609780684114,
      -0.05600203201174736,
      0.006932530086487532,
      0.02089172787964344,
      0.03419455513358116,
      -0.05321561545133591,
      -0.05405586212873459,
      0.07290578633546829,
      -0.036145132035017014,
      -0.007357166614383459,
      -0.05448312312364578,
      0.03447176143527031,
      0.03980583697557449,
      0.026440011337399483,
      0.028312785550951958,
      0.019331373274326324,
      0.0020666359923779964,
      -0.021721841767430305,
      0.009691153652966022,
      0.10608523339033127,
      -0.08721964061260223,
      -0.02868683636188507,
      0.003086445853114128,
      0.004309266339987516,
      0.012350132688879967,
      -8.70352378115058e-05,
      0.055939774960279465,
      0.07658825814723969,
      0.02387685887515545,
      0.09172695875167847,
      -0.015276960097253323,
      -0.0023924207780510187,
      0.030872225761413574,
      0.0006394654046744108,
      -0.08176206797361374,
      -0.053552139550447464,
      -0.06999647617340088,
      -0.042070552706718445,
      -0.05146275833249092,
      -0.06315519660711288,
      -0.0472748726606369,
      -0.00125162024050951,
      -0.09928763657808304,
      0.039751436561346054,
      0.04784492403268814,
      -0.07221072912216187,
      -0.012183989398181438,
      0.04864901676774025,
      0.048409949988126755,
      0.014412341639399529,
      0.10583741962909698,
      -0.013273541815578938,
      0.04068521410226822,
      -0.035535603761672974,
      -0.006279703229665756,
      0.07552902400493622,
      -0.02038208208978176,
      -0.1328885555267334,
      0.04642212390899658,
      -0.035437338054180145,
      -0.03898110240697861,
      0.04148191213607788,
      -0.05371944233775139,
      -0.039905935525894165,
      0.08514489233493805,
      0.027799241244792938,
      -0.04789695516228676,
      0.025325939059257507,
      -0.09556666761636734,
      0.031801436096429825,
      0.03091401234269142,
      0.001411096891388297,
      0.02152181789278984,
      0.03464436158537865,
      0.017403805628418922,
      -0.025121362879872322,
      0.08192510157823563,
      -0.06823724508285522,
      0.018556607887148857,
      0.014096292667090893,
      0.0008874610066413879,
      0.037736210972070694,
      -0.03129187971353531,
      0.03674492612481117,
      -0.09808113425970078,
      -0.005467894021421671,
      0.008467516861855984,
      0.0023374001029878855,
      -0.005955751985311508,
      0.014751016162335873,
      0.03432796150445938,
      0.0043637980706989765,
      0.005775257479399443,
      -0.038805846124887466,
      0.029952025040984154,
      -0.05674651637673378,
      -0.04699625074863434,
      -0.04382301867008209,
      0.02898676134645939,
      0.04327933117747307,
      0.07653555274009705,
      0.14912542700767517,
      0.02121843956410885,
      0.06397043168544769,
      -0.10809513181447983,
      -0.03200451284646988,
      -0.042350947856903076,
      0.04661751538515091,
      -0.025692367926239967,
      0.010720633901655674,
      0.014394313097000122,
      0.015575438737869263,
      -0.032883234322071075,
      -0.060591649264097214,
      0.08434640616178513,
      0.017004484310746193,
      -0.0657016783952713,
      0.005280822981148958,
      0.0037849019281566143,
      -0.019518379122018814,
      -0.04134204983711243,
      0.027951516211032867,
      0.0674220472574234,
      0.0918673574924469,
      -0.011241349391639233,
      -0.03523377701640129,
      0.02341700904071331,
      0.029620986431837082,
      -0.022395312786102295,
      -0.06303441524505615,
      -0.012531707063317299,
      -0.015368849970400333,
      0.0029206338804215193,
      0.05873599648475647,
      -0.02786881849169731,
      -0.07710549980401993,
      0.03380625322461128,
      -0.020214572548866272,
      -0.003937283530831337,
      -0.08196278661489487,
      0.02009412832558155,
      -0.0007345769554376602,
      0.05780238285660744,
      -0.016211185604333878,
      -0.012514971196651459,
      0.044138748198747635,
      0.02804054692387581,
      0.05276244506239891,
      -0.009833435527980328,
      -0.005415128543972969,
      -0.04935816675424576,
      -0.025172943249344826,
      -0.00013676022354047745,
      -0.02064608968794346,
      0.05252845957875252,
      0.059224121272563934,
      0.11024530977010727,
      -0.025844130665063858,
      -0.0418383851647377,
      -0.04172203317284584,
      0.00713891489431262,
      0.04108494147658348,
      -0.04610387608408928,
      0.030993560329079628,
      -0.029072346165776253,
      0.1069553941488266,
      0.04272898659110069,
      0.016070622950792313,
      -0.11042989790439606,
      -0.017184454947710037,
      0.040862180292606354,
      -0.06816020607948303,
      0.0522143617272377,
      0.07212944328784943,
      0.003591306507587433,
      0.03237205371260643,
      0.09690763056278229,
      -0.03776479512453079
    ],
    [
      -0.01091338787227869,
      2.530533674871549e-05,
      0.041093677282333374,
      -0.03298385068774223,
      0.0648091584444046,
      -0.05305233970284462,
      0.06210971623659134,
      0.07288731634616852,
      0.060096677392721176,
      0.11005643755197525,
      0.008561855182051659,
      0.06716899573802948,
      0.04871787875890732,
      -0.08113842457532883,
      -0.060748372226953506,
      0.014354993589222431,
      0.008432555943727493,
      0.022084161639213562,
      -0.034235045313835144,
      -0.006810816470533609,
      -0.029238155111670494,
      0.03860735520720482,
      -0.09495341032743454,
      0.041682168841362,
      -0.06269408762454987,
      0.05328679084777832,
      0.0034031749237328768,
      0.07126139104366302,
      0.050784364342689514,
      0.07159380614757538,
      0.15057595074176788,
      0.03002760373055935,
      -0.052774712443351746,
      0.008656734600663185,
      -0.045661088079214096,
      -0.011891093105077744,
      0.010371290147304535,
      0.0074632251635193825,
      -0.006635464262217283,
      0.03306691721081734,
      0.053248047828674316,
      0.0028612178284674883,
      -0.08257552236318588,
      0.005654776468873024,
      0.04277927428483963,
      0.05526145547628403,
      -0.019646400585770607,
      0.006671091075986624,
      9.11164388526231e-05,
      -0.005694602150470018,
      -0.06470763683319092,
      -0.09062253683805466,
      -0.12210902571678162,
      0.03427663445472717,
      -0.056688934564590454,
      0.060882192105054855,
      0.07217873632907867,
      0.06524112820625305,
      -0.05451400950551033,
      -0.007522725500166416,
      -0.01847933419048786,
      -0.05232272297143936,
      -0.0100255087018013,
      0.04411441087722778,
      0.04264642298221588,
      -0.06006079539656639,
      0.022021211683750153,
      0.008397809229791164,
      0.014798048883676529,
      -0.04369267448782921,
      0.011178242973983288,
      0.0021104186307638884,
      -0.0338120199739933,
      -0.05807666853070259,
      0.04435129091143608,
      0.04096611216664314,
      -0.031681206077337265,
      -0.06570424884557724,
      -0.01972445286810398,
      -0.12549544870853424,
      -0.08883029967546463,
      0.04715332016348839,
      0.035191114991903305,
      0.013517061248421669,
      -0.04058736562728882,
      0.05042951926589012,
      -0.018792230635881424,
      0.036759719252586365,
      -0.04167727008461952,
      -0.026727473363280296,
      -0.002983887679874897,
      -0.0008954191580414772,
      0.006099123042076826,
      0.02898235432803631,
      -0.059337176382541656,
      -0.004741321317851543,
      -0.05979030951857567,
      0.03881700709462166,
      -0.1086423322558403,
      -0.00389061844907701,
      0.017458081245422363,
      0.06034009903669357,
      -0.1057194322347641,
      -0.02387436106801033,
      0.052346788346767426,
      -0.04679718241095543,
      -0.05597240477800369,
      -0.04772627726197243,
      0.04567025601863861,
      0.09528768062591553,
      -0.02225273661315441,
      0.04755660519003868,
      0.010395465418696404,
      -0.023192452266812325,
      -0.059167057275772095,
      0.014952411875128746,
      0.04273894801735878,
      -0.005766268353909254,
      0.04278046265244484,
      -0.020524300634860992,
      0.003992732148617506,
      0.029306301847100258,
      0.007049498148262501,
      0.012520750053226948,
      0.013358823955059052,
      0.020224587991833687,
      0.052311256527900696,
      0.008776456117630005,
      -0.06316130608320236,
      0.03247551992535591,
      0.04074353724718094,
      0.03556971997022629,
      -0.047179821878671646,
      0.0035838778130710125,
      -0.030105752870440483,
      -0.03075602650642395,
      -0.05249202996492386,
      -0.01347387209534645,
      0.005149473436176777,
      0.04584050178527832,
      0.037998903542757034,
      0.04442211240530014,
      -0.0030453999061137438,
      0.02542446367442608,
      -0.018620915710926056,
      0.025482462719082832,
      0.053732167929410934,
      0.04333300516009331,
      0.009270614013075829,
      -0.038542844355106354,
      -0.12597344815731049,
      0.06269985437393188,
      -0.005670954007655382,
      -0.040717534720897675,
      -0.0260672215372324,
      0.05643778294324875,
      0.043804366141557693,
      0.029491202905774117,
      0.011002735234797001,
      0.08415164053440094,
      -0.03131559118628502,
      0.03637579455971718,
      -0.061147499829530716,
      0.023307427763938904,
      -0.058078471571207047,
      0.054572977125644684,
      0.1458173543214798,
      -0.008664269931614399,
      -0.051635488867759705,
      0.024383507668972015,
      0.022598855197429657,
      0.031446218490600586,
      0.02570747211575508,
      -0.01120162196457386,
      0.03982047364115715,
      -0.024116605520248413,
      0.027424141764640808,
      0.04869239032268524,
      -0.1166064664721489,
      0.031325552612543106,
      0.05629701912403107,
      -0.0019543333910405636,
      0.018053991720080376,
      -0.0288055632263422,
      -0.009479600004851818,
      0.0014360614586621523,
      0.06763298064470291,
      0.00874575786292553,
      0.03364613279700279,
      -0.10725610703229904,
      -0.02469075284898281,
      0.012129579670727253,
      -0.057357095181941986,
      0.06975328922271729,
      -0.055668167769908905,
      -0.05429799109697342,
      0.018416035920381546,
      0.11463404446840286,
      -0.0008507347665727139,
      -0.025311429053544998,
      -0.0441126823425293,
      -0.039526164531707764,
      0.08214497566223145,
      0.043856844305992126,
      0.02336833067238331,
      0.03358529508113861,
      0.055382464081048965,
      -0.006001078989356756,
      -0.021128913387656212,
      0.02911687269806862,
      -0.015062520280480385,
      -0.005232400260865688,
      0.0403769426047802,
      0.028494447469711304,
      0.013380945660173893,
      -0.025697780773043633,
      0.004640158265829086,
      0.14225035905838013,
      0.013280857354402542,
      -0.055537011474370956,
      0.017702987417578697,
      -0.016473034396767616,
      -0.034418653696775436,
      -0.0074510276317596436,
      -0.047245096415281296,
      0.05594063177704811,
      0.010072294622659683,
      -0.020843729376792908,
      -0.007649196777492762,
      -0.00028243131237104535,
      -0.015131968073546886,
      0.01221279613673687,
      0.03785831481218338,
      -0.009003262966871262,
      0.018717024475336075,
      0.012795540504157543,
      0.10003326833248138,
      -0.04672516509890556,
      0.038930922746658325,
      -0.0514996238052845,
      -0.0899951383471489,
      -0.05740620195865631,
      -0.04376952722668648,
      0.014013078063726425,
      0.005735807120800018,
      -0.00020091292390134186,
      0.026094233617186546,
      0.013212867081165314,
      -0.027120621874928474,
      -0.0227137953042984,
      0.0045373933389782906,
      0.027670057490468025,
      0.02901528961956501,
      -0.03140171989798546,
      -0.05376178398728371,
      0.012789307162165642,
      0.03540069982409477,
      -0.020916640758514404,
      -0.08794159442186356,
      -0.09963580220937729,
      -0.06024499237537384,
      0.05081436410546303,
      0.07821466773748398,
      0.0031060671899467707,
      -0.011930106207728386,
      0.0674777552485466,
      0.06138994172215462,
      0.044385455548763275,
      -0.02783174254000187,
      -0.0032850666902959347,
      -0.010012977756559849,
      0.013599628582596779,
      -0.042648982256650925,
      -0.07273866981267929,
      0.06234009191393852,
      -0.0010080145439133048,
      0.06388609111309052,
      -0.006669614929705858,
      0.10730376839637756,
      -0.011463673785328865,
      0.04527340084314346,
      0.08144378662109375,
      0.0010687366593629122,
      0.012316594831645489,
      -0.018844332545995712,
      -0.020075833424925804,
      -0.043450187891721725,
      0.018518391996622086,
      0.019110675901174545,
      -0.019420795142650604,
      0.09629155695438385,
      -0.03927970305085182,
      -0.04591947793960571,
      -0.04649868607521057,
      0.01248741615563631,
      0.027724577113986015,
      -0.0157990213483572,
      0.0370086170732975,
      -0.0452643446624279,
      0.04671844094991684,
      -0.019916540011763573,
      -0.06200108304619789,
      -0.03034687601029873,
      -0.011962030082941055,
      -0.11164399981498718,
      0.024761449545621872,
      0.007905003614723682,
      -0.003820928279310465,
      0.008608100935816765,
      -0.059059616178274155,
      0.023734403774142265,
      -0.023367753252387047,
      -0.01158222183585167,
      -0.022633425891399384,
      -0.0117855379357934,
      -0.01355098094791174,
      0.01272137276828289,
      0.01532670482993126,
      -0.06880393624305725,
      0.0408058762550354,
      0.007221665233373642,
      0.0639343336224556,
      0.0030395318754017353,
      0.0032512182369828224,
      0.0654636025428772,
      -0.051877547055482864,
      0.010813042521476746,
      0.028994206339120865,
      -0.10254126042127609,
      -0.00652692886069417,
      -0.07319115847349167,
      0.059676337987184525,
      -0.003282734891399741,
      -0.0024135522544384003,
      -0.025993026793003082,
      -0.05138099193572998,
      0.017234431579709053,
      0.05408709868788719,
      0.0073820012621581554,
      0.004534125328063965,
      0.0025196063797920942,
      -0.04268757998943329,
      0.12013601511716843,
      -0.07750993221998215,
      -0.10004647821187973,
      -0.0541999414563179,
      -0.02736750990152359,
      0.03679392486810684,
      0.03636232390999794,
      0.02567964233458042,
      -0.009769346565008163,
      0.04016994684934616,
      0.05538701266050339,
      0.11425726115703583,
      0.03131712228059769,
      0.03423812985420227,
      0.08510468900203705,
      -0.05672438070178032,
      -0.03984122723340988,
      -0.01834702491760254,
      0.03509635850787163,
      0.0800357237458229,
      -0.01894012652337551,
      0.04621032625436783,
      -0.09314999729394913,
      -0.03885786235332489,
      0.0079276654869318,
      -0.03786204010248184,
      -0.024085022509098053,
      0.029042020440101624,
      -0.0384230799973011,
      -0.01074097491800785,
      0.05723438784480095,
      0.08228597044944763,
      -0.002392154885455966,
      0.05424514040350914,
      -5.832792885485105e-05,
      -0.08954492211341858,
      0.07089962065219879,
      0.019386976957321167,
      -0.038897804915905,
      0.026150187477469444,
      0.007278518285602331,
      0.06916799396276474,
      -0.024585608392953873,
      0.03742332383990288,
      0.03245794028043747,
      -0.03181722015142441,
      -0.08972001075744629,
      -0.06781475245952606,
      0.05614496394991875,
      -0.009579777717590332,
      -0.004360440652817488,
      0.03919335827231407,
      0.011354543268680573,
      -0.019834697246551514,
      -0.03291286900639534,
      0.027082575485110283,
      0.022809196263551712,
      0.0089860325679183,
      -0.07091119140386581,
      -0.0033647865056991577,
      -0.05175979807972908,
      0.09138298779726028,
      -0.03658492863178253,
      -0.015207065269351006,
      -0.05156325176358223,
      0.022394532337784767,
      0.0855809897184372,
      -0.03491349145770073,
      0.0018247530097141862,
      -0.07412645220756531,
      0.013133778236806393,
      -0.05692695453763008,
      -0.0130769656971097,
      0.03973779454827309,
      -0.044754136353731155,
      0.048055846244096756,
      0.018873335793614388,
      -0.014320642687380314,
      -0.0338350273668766,
      -0.014011947438120842,
      -0.05884116515517235,
      -0.03544195368885994,
      0.01915682852268219,
      -0.058503005653619766,
      0.05742408707737923,
      0.007821985520422459,
      0.01613001525402069,
      -0.01038856990635395,
      -0.08776739239692688,
      -0.05613541230559349,
      0.011074546724557877,
      0.008303972892463207,
      -0.02094700001180172,
      -0.06241035461425781,
      -0.0525304339826107,
      0.034122951328754425,
      0.005667436867952347,
      -0.10702462494373322,
      -0.019073622301220894,
      -0.00689050555229187,
      0.11959395557641983,
      0.03421702980995178,
      -0.04575583338737488,
      0.012070601806044579,
      0.06432696431875229,
      0.009693930856883526,
      -0.0018256878247484565,
      0.044251348823308945,
      0.016195105388760567,
      0.0005000223754905164,
      -0.012613650411367416,
      0.011364664882421494,
      0.07091399282217026,
      -0.037885647267103195,
      -0.003436619183048606,
      -0.024936195462942123,
      -0.014653513208031654,
      0.008462788537144661,
      -0.03559282794594765,
      -0.05150328949093819,
      0.05385321378707886,
      -0.01047132071107626,
      -0.04752596467733383,
      0.04277338460087776,
      0.07852993160486221,
      -0.08438873291015625,
      -0.08322661370038986,
      -0.10157562792301178,
      0.03280158340930939,
      0.03823688626289368,
      0.07259507477283478,
      -0.01258748397231102,
      0.05965990200638771,
      0.01609870418906212,
      0.03967348113656044,
      0.03377562388777733,
      0.014282293617725372,
      0.03779154270887375,
      -0.0617515929043293,
      0.030767519026994705,
      0.03392921760678291,
      -0.05739417299628258,
      0.0018421898130327463,
      0.07687750458717346,
      -0.06456539034843445,
      0.009420068934559822,
      0.045674994587898254,
      0.03150959685444832,
      0.003801420098170638,
      0.017190255224704742,
      -0.03650752454996109,
      -0.004740712232887745,
      0.01651441492140293,
      0.007782645523548126,
      -0.059028711169958115,
      0.06159530580043793,
      0.007727947551757097,
      0.015329931862652302,
      0.09273871034383774,
      0.041039202362298965,
      -0.003699325490742922
    ],
    [
      0.01610581949353218,
      -0.03988923132419586,
      -0.013875598087906837,
      -0.04307999461889267,
      0.0061371661722660065,
      -0.02130492962896824,
      -0.025250906124711037,
      0.1234496533870697,
      0.1002846360206604,
      -0.019018717110157013,
      -0.0868217945098877,
      -0.005747344344854355,
      0.0007056875620037317,
      -0.04271556809544563,
      0.005731826648116112,
      -0.025248395279049873,
      -0.008932877331972122,
      -0.048640176653862,
      0.007819549180567265,
      0.1027931496500969,
      0.05707116425037384,
      -0.030821476131677628,
      -0.009921223856508732,
      0.07119344174861908,
      -0.10344357788562775,
      0.03715170919895172,
      -0.008253494277596474,
      0.07395049929618835,
      0.07190590351819992,
      0.01953175850212574,
      0.07432673126459122,
      0.03584873303771019,
      -0.03874456137418747,
      0.023838244378566742,
      -0.0457318089902401,
      -0.04302457720041275,
      0.010184121318161488,
      -0.04964565485715866,
      0.007391450461000204,
      -0.02993384562432766,
      -0.02192595787346363,
      -0.0705723986029625,
      -0.02561165578663349,
      0.004572620149701834,
      -0.02815759927034378,
      0.0014965517912060022,
      -0.07468144595623016,
      0.038702815771102905,
      0.023816121742129326,
      -0.05541063845157623,
      0.009296124801039696,
      -0.03909284248948097,
      -0.002398451091721654,
      -0.011924938298761845,
      -0.02399429678916931,
      0.01694367453455925,
      0.025546249002218246,
      0.06710328906774521,
      -0.032432183623313904,
      0.007161816582083702,
      0.05707133188843727,
      0.05159804970026016,
      0.041955891996622086,
      -0.04838194698095322,
      -0.02309309132397175,
      0.05271458998322487,
      0.020255884155631065,
      0.015259461477398872,
      -0.02700200304389,
      -0.05457880720496178,
      -0.008717373944818974,
      -0.0026786276139318943,
      -0.0032770594116300344,
      -0.03123246319591999,
      0.033637430518865585,
      -0.06731986999511719,
      0.06890358030796051,
      -0.0791407898068428,
      0.0727224349975586,
      -0.049733348190784454,
      0.016330964863300323,
      -0.011083012446761131,
      -0.058261603116989136,
      -0.010769288055598736,
      0.056250978261232376,
      0.0008064215071499348,
      -0.012790737673640251,
      -0.007039257325232029,
      0.023482395336031914,
      -0.020781537517905235,
      -0.010817175731062889,
      0.014757397584617138,
      0.0019068742403760552,
      0.018615903332829475,
      -0.012323611415922642,
      0.048529013991355896,
      -0.04949840530753136,
      -0.07724688947200775,
      0.03528805077075958,
      -0.05017225444316864,
      0.05722149461507797,
      -0.01438915729522705,
      0.02038690447807312,
      0.02253887616097927,
      -0.022776827216148376,
      0.02339857630431652,
      -0.07373984158039093,
      -0.02948051691055298,
      0.05083923786878586,
      0.11604359745979309,
      0.06811477243900299,
      0.0016576221678406,
      -0.08811240643262863,
      -0.06018882989883423,
      -0.013855695724487305,
      0.04933411628007889,
      -0.017342278733849525,
      0.009702149778604507,
      -0.03252263739705086,
      -0.020987646654248238,
      0.013575932011008263,
      0.02705325558781624,
      -0.029803836718201637,
      -0.04447495937347412,
      -0.02171477861702442,
      -0.05586128309369087,
      0.017533650621771812,
      0.011068644933402538,
      0.0344567596912384,
      0.08132922649383545,
      -0.028902189806103706,
      0.04937309771776199,
      -0.0041374522261321545,
      -0.051658857613801956,
      0.03352256119251251,
      -0.051281899213790894,
      0.05169885978102684,
      0.045849382877349854,
      -0.003317120485007763,
      -0.011177508160471916,
      0.03277561813592911,
      -0.04871094599366188,
      -0.10700470209121704,
      -0.004639788065105677,
      -0.06112363934516907,
      -0.050685640424489975,
      -0.08101174980401993,
      -0.045024771243333817,
      0.0069239274598658085,
      -0.011583497747778893,
      0.03620663285255432,
      -0.03347066417336464,
      -0.020300954580307007,
      -0.06357947736978531,
      -0.04625745117664337,
      0.03174727410078049,
      0.06773389130830765,
      -0.016592010855674744,
      0.0057286755181849,
      0.011594010517001152,
      0.04704705998301506,
      0.036135606467723846,
      -0.0038187638856470585,
      0.020968038588762283,
      0.003657425055280328,
      0.024115022271871567,
      -0.0976712554693222,
      -0.04225179925560951,
      -0.008846400305628777,
      0.025640690699219704,
      0.01701018214225769,
      -0.07859454303979874,
      0.031159454956650734,
      0.04414473846554756,
      0.014332790859043598,
      0.04725212603807449,
      -0.04263933748006821,
      0.04376468434929848,
      -0.07470488548278809,
      0.047959111630916595,
      0.0025435236748307943,
      -0.029352650046348572,
      0.03999842703342438,
      -0.05232597514986992,
      0.021636638790369034,
      0.05509776622056961,
      0.03932904452085495,
      0.04197554290294647,
      0.010314421728253365,
      0.023631427437067032,
      0.03717370331287384,
      -0.031231127679347992,
      -0.06486370414495468,
      0.04486186429858208,
      0.007673864718526602,
      -0.016302375122904778,
      -0.05198005586862564,
      -0.0035816060844808817,
      0.014702923595905304,
      -0.061126865446567535,
      0.023004041984677315,
      -0.002816651249304414,
      0.012043312191963196,
      -0.04596378281712532,
      0.020630819723010063,
      0.053492940962314606,
      0.01793053187429905,
      0.03288192301988602,
      0.000393172464100644,
      0.0052801622077822685,
      0.026111476123332977,
      0.07268911600112915,
      0.10179031640291214,
      -0.030597973614931107,
      0.04363222047686577,
      -0.021925300359725952,
      -0.06798269599676132,
      0.05394773930311203,
      -0.01781569980084896,
      0.017261197790503502,
      -0.035723429173231125,
      0.06803764402866364,
      0.008512141183018684,
      0.0214865542948246,
      -0.03523167222738266,
      -0.10965761542320251,
      0.08691386878490448,
      0.043453533202409744,
      0.0005333138396963477,
      0.12047781050205231,
      -0.0015895023243501782,
      0.01409036573022604,
      -0.02001466229557991,
      -0.056039679795503616,
      -0.046706683933734894,
      0.05149787291884422,
      0.025968682020902634,
      0.03143083304166794,
      0.03776495158672333,
      -0.012882430106401443,
      -0.01596567966043949,
      0.025490105152130127,
      -0.046413637697696686,
      0.04379260540008545,
      -0.0478094145655632,
      0.052505701780319214,
      0.02543729729950428,
      0.07275862991809845,
      -0.05919894203543663,
      0.013332116417586803,
      -0.0377470888197422,
      0.05789342522621155,
      0.06709562987089157,
      0.01789700984954834,
      -0.05812617018818855,
      -0.021089032292366028,
      7.35354915377684e-05,
      -0.09654869884252548,
      0.014508586376905441,
      0.02611202374100685,
      -0.0015481356531381607,
      -0.03289242088794708,
      -0.006651668809354305,
      0.056505560874938965,
      0.09495925903320312,
      -0.04470760002732277,
      -0.06396272778511047,
      -0.02166581153869629,
      0.08586462587118149,
      -0.05145089700818062,
      0.10190819948911667,
      0.019746756181120872,
      0.06719198822975159,
      -0.051853060722351074,
      0.030982928350567818,
      5.08776938659139e-05,
      -0.013882661238312721,
      0.027762871235609055,
      0.010931963101029396,
      -0.03524564579129219,
      0.019613686949014664,
      0.048796359449625015,
      -0.11144979298114777,
      -0.023206153884530067,
      -0.03359643369913101,
      -0.014252295717597008,
      -0.01569334790110588,
      0.018772002309560776,
      -0.0388605073094368,
      -0.08733976632356644,
      -0.004200700204819441,
      -0.044409383088350296,
      0.03796708956360817,
      -0.024646049365401268,
      0.03991169109940529,
      0.02249518409371376,
      0.0015934023540467024,
      0.029678722843527794,
      -0.010107619687914848,
      0.02192925475537777,
      -0.006366549991071224,
      -0.020086055621504784,
      -0.03170141577720642,
      0.010291436687111855,
      -0.015920184552669525,
      -0.03557008132338524,
      0.02342783473432064,
      0.016194377094507217,
      0.0031006867066025734,
      -0.0732305496931076,
      0.016558093950152397,
      0.07674811035394669,
      -0.10265296697616577,
      -0.11563803255558014,
      -0.07441949099302292,
      0.030716655775904655,
      -0.01825544238090515,
      -0.02674204856157303,
      0.002087421016767621,
      -0.019234713166952133,
      -0.07880834490060806,
      -0.02970174327492714,
      0.0033261587377637625,
      0.06528820842504501,
      0.0244545117020607,
      0.03579595312476158,
      0.06500063836574554,
      -0.0065652658231556416,
      -0.0025430081877857447,
      0.02160792611539364,
      0.009055747650563717,
      -0.023944297805428505,
      -0.08346184343099594,
      -0.10679303109645844,
      -0.0008803189848549664,
      0.013591797091066837,
      0.03371182084083557,
      0.0445256307721138,
      -0.11193625628948212,
      -0.07481905817985535,
      -0.06628179550170898,
      -0.02687566913664341,
      0.03352074325084686,
      -0.03634865581989288,
      -0.02176603488624096,
      0.05148715153336525,
      -0.05326429009437561,
      0.03502447530627251,
      0.05352867767214775,
      0.04849429056048393,
      0.030579419806599617,
      0.04846698045730591,
      -0.07300738990306854,
      0.009801269508898258,
      -0.020062187686562538,
      0.02395440824329853,
      0.030645588412880898,
      0.002846317132934928,
      -0.07180246710777283,
      -0.022680211812257767,
      -0.09701219201087952,
      0.047611016780138016,
      0.009922944940626621,
      0.001442151376977563,
      -0.015054566785693169,
      -0.05242051184177399,
      0.03122018836438656,
      0.08681394159793854,
      0.1086304560303688,
      0.04509832337498665,
      0.016684342175722122,
      -0.04439399391412735,
      0.01093963347375393,
      -0.03616643324494362,
      0.013759789988398552,
      0.00017982062126975507,
      -0.022008592262864113,
      0.02255578339099884,
      -0.0413859523832798,
      -0.02272375300526619,
      -0.04329117015004158,
      0.0028445569332689047,
      -0.11800000071525574,
      -0.03351898118853569,
      -0.05619996041059494,
      -0.0023180993739515543,
      -0.015696220099925995,
      0.072503961622715,
      0.04728894308209419,
      -0.019635742530226707,
      -0.10444478690624237,
      0.03699611499905586,
      -0.0678749531507492,
      -0.051541659981012344,
      -0.09303394705057144,
      0.04078340157866478,
      0.08288545906543732,
      -0.012718893587589264,
      -0.04647071659564972,
      -0.025204913690686226,
      -0.007044325582683086,
      -0.02908898890018463,
      -0.05676407366991043,
      0.03955291211605072,
      0.03822196274995804,
      -0.03369676694273949,
      -0.005864306818693876,
      -0.02153977006673813,
      0.0031958564650267363,
      0.020072298124432564,
      0.007849224843084812,
      0.007879565469920635,
      0.02561121992766857,
      0.018303731456398964,
      -0.023236701264977455,
      -0.05669576674699783,
      0.06238233298063278,
      -0.05271083861589432,
      -0.004611411597579718,
      0.08571705222129822,
      0.0017740180483087897,
      -0.011799215339124203,
      0.04054902866482735,
      -0.048993248492479324,
      0.0036753222811967134,
      -0.021533291786909103,
      0.05318130552768707,
      -0.0369652695953846,
      -0.004432890564203262,
      -0.11752741038799286,
      0.040180593729019165,
      0.004910419695079327,
      -0.028768042102456093,
      -0.01695963740348816,
      0.0002655640128068626,
      0.001791748683899641,
      -0.01854388229548931,
      -0.02096429467201233,
      -0.03543546423316002,
      0.04442664980888367,
      0.05159653350710869,
      -0.02123473957180977,
      0.05384409800171852,
      0.04014582931995392,
      0.043271247297525406,
      0.010251271538436413,
      -0.020849093794822693,
      0.05362441763281822,
      -0.05579293891787529,
      0.007575335446745157,
      0.007524057757109404,
      0.01478861179202795,
      0.039532311260700226,
      -0.05113375559449196,
      0.02890513278543949,
      0.1132405549287796,
      -0.009626789949834347,
      -0.04459135979413986,
      -0.008819231763482094,
      -0.04359878599643707,
      -0.049297887831926346,
      -0.1537819802761078,
      0.011026648804545403,
      0.11502246558666229,
      -0.09333524852991104,
      -0.0433344691991806,
      0.0032663100864738226,
      -0.0016912103164941072,
      -0.013641273602843285,
      -0.023016594350337982,
      0.039294492453336716,
      0.0545472614467144,
      0.02139509655535221,
      0.015761423856019974,
      0.04778869450092316,
      -0.003892076201736927,
      0.016445238143205643,
      -0.03416091576218605,
      0.042455434799194336,
      -0.026402035728096962,
      -0.06996101886034012,
      0.07615949958562851,
      -0.004143378231674433,
      0.025223275646567345,
      0.07064160704612732,
      -0.01297815702855587,
      -0.053909797221422195,
      0.035883236676454544,
      -0.040211766958236694,
      -0.007279371377080679,
      0.006036113016307354,
      -0.03099908120930195,
      -0.03764790669083595,
      -0.04132518172264099,
      0.00886424072086811,
      0.02598048932850361,
      -0.06356989592313766,
      0.06083812192082405,
      -0.028576266020536423,
      -0.04704021289944649,
      0.005076487548649311,
      -0.04367911443114281,
      -0.03578769788146019
    ],
    [
      0.030731644481420517,
      -0.008063591085374355,
      0.015335552394390106,
      -0.02774239331483841,
      -0.014527389779686928,
      0.09731551259756088,
      -0.001434632227756083,
      -0.06616540253162384,
      0.0716744139790535,
      -0.03162744268774986,
      -0.04961826652288437,
      0.07114291936159134,
      0.002871439326554537,
      -0.021167075261473656,
      0.03473139554262161,
      0.04726489633321762,
      -0.006071106530725956,
      -0.04096890613436699,
      0.009820039384067059,
      -0.09501124173402786,
      -0.08496882766485214,
      0.02173922397196293,
      0.057544298470020294,
      -0.008923759683966637,
      -0.04212016984820366,
      -0.009429161436855793,
      0.01694541610777378,
      0.04673483595252037,
      -0.007305094972252846,
      -0.08404918760061264,
      -0.02346707321703434,
      0.004956026095896959,
      0.014401148073375225,
      -0.035313837230205536,
      0.021558795124292374,
      0.0519716776907444,
      0.007130757439881563,
      -0.061527516692876816,
      0.028254041448235512,
      -0.056600429117679596,
      0.05528769642114639,
      0.0555742122232914,
      -0.061529286205768585,
      0.014304522424936295,
      0.06083168461918831,
      -0.007272060494869947,
      -0.027796553447842598,
      -0.004663702100515366,
      -0.010342939756810665,
      0.08055805414915085,
      -0.08556447178125381,
      0.0002876684593502432,
      0.02460644394159317,
      -0.021242782473564148,
      0.04688051715493202,
      0.006217079237103462,
      -0.014712834730744362,
      0.023926867172122,
      -0.019973576068878174,
      -0.09694969654083252,
      0.016218407079577446,
      0.06095171719789505,
      -0.05653323605656624,
      0.02045930176973343,
      -0.013663372956216335,
      -0.013522605411708355,
      -0.018688861280679703,
      0.0033068202901631594,
      0.06630678474903107,
      -0.05571182817220688,
      -0.0034271248150616884,
      0.04038582369685173,
      0.07868780940771103,
      0.007760272826999426,
      0.0036440840922296047,
      -0.042397934943437576,
      0.0056457482278347015,
      0.0015780898975208402,
      0.07361158728599548,
      -0.060076504945755005,
      -0.0217285193502903,
      -0.05393551290035248,
      0.03841117396950722,
      0.035752516239881516,
      0.020660584792494774,
      0.018734175711870193,
      0.008454340510070324,
      -0.025768499821424484,
      0.05661606416106224,
      0.021436316892504692,
      -0.08785396814346313,
      0.06600749492645264,
      0.00963004119694233,
      -0.03386132791638374,
      -0.013455689884722233,
      -0.06291288137435913,
      -0.03852516785264015,
      0.013385418802499771,
      0.01086507085710764,
      -0.018121667206287384,
      0.08094750344753265,
      0.04761316627264023,
      -0.020484937354922295,
      -0.010740003548562527,
      -0.041676945984363556,
      0.05484601855278015,
      -0.02291402406990528,
      -0.01855386607348919,
      0.027257820591330528,
      0.015469701960682869,
      -0.02172098681330681,
      -0.03148522973060608,
      0.024724256247282028,
      0.05595437437295914,
      0.09590879082679749,
      -0.004147212952375412,
      0.007478231564164162,
      -0.03931139409542084,
      -0.001252868678420782,
      0.0547911636531353,
      -0.022843489423394203,
      -0.054673682898283005,
      0.027010124176740646,
      0.02246345765888691,
      -0.06775691360235214,
      0.003029713872820139,
      0.003288131207227707,
      0.05477187782526016,
      0.006206001155078411,
      -0.01636286824941635,
      0.017673557624220848,
      -0.00798990111798048,
      0.0019046934321522713,
      -0.06797037273645401,
      0.046504076570272446,
      -0.05174913629889488,
      -0.03385122865438461,
      0.010603571310639381,
      -0.06480860710144043,
      0.02491038478910923,
      0.011923099867999554,
      0.027803337201476097,
      -0.03737825155258179,
      -0.062466446310281754,
      -0.07985321432352066,
      -0.025967447087168694,
      -0.07552168518304825,
      -0.026571277529001236,
      -0.0007186330622062087,
      -0.09051191061735153,
      -0.04209454357624054,
      -0.027566829696297646,
      -0.0026097423397004604,
      0.08153892308473587,
      -0.05953427031636238,
      -0.0018244405509904027,
      0.023916805163025856,
      -0.009169346652925014,
      -0.04353486746549606,
      -0.019419299438595772,
      0.01641448773443699,
      -0.0022254923824220896,
      -0.046509258449077606,
      0.008554911240935326,
      -0.009118970483541489,
      0.04151038080453873,
      0.033181942999362946,
      0.03856619447469711,
      -0.01760794222354889,
      -0.06498658657073975,
      0.001351935905404389,
      -0.015529468655586243,
      -0.007429363206028938,
      -0.10106295347213745,
      0.0350659005343914,
      -0.032647378742694855,
      -0.04070476442575455,
      -0.043074414134025574,
      -0.04004562646150589,
      0.006439110729843378,
      -0.01395473163574934,
      0.0003888302017003298,
      0.039730146527290344,
      -0.04775279015302658,
      -0.027528967708349228,
      -0.010376266203820705,
      0.03086356818675995,
      -0.01731761172413826,
      -0.017259208485484123,
      0.035416945815086365,
      0.09821446985006332,
      0.0010439614998176694,
      -0.0401335172355175,
      0.07081592082977295,
      0.03775843605399132,
      -0.07911315560340881,
      0.03108309954404831,
      -0.005208328831940889,
      0.029993737116456032,
      -0.03459955006837845,
      0.04091794043779373,
      0.025441275909543037,
      0.03363802656531334,
      -0.015501108951866627,
      0.06736000627279282,
      -0.019526854157447815,
      -0.010755135677754879,
      0.008650395087897778,
      0.08394318073987961,
      0.036692872643470764,
      0.0017616130644455552,
      0.04556304216384888,
      0.019754204899072647,
      -0.0022704419679939747,
      -0.020118582993745804,
      0.03307430446147919,
      -0.08979063481092453,
      0.08170196413993835,
      0.06557270884513855,
      0.01938832364976406,
      0.05348125100135803,
      0.01090636570006609,
      0.0385475717484951,
      0.06079644709825516,
      0.05084298923611641,
      0.002093784511089325,
      0.02815280482172966,
      -0.01281472947448492,
      0.049590256065130234,
      -0.016997020691633224,
      -0.057012904435396194,
      0.03016120009124279,
      0.014587823301553726,
      0.05165434256196022,
      0.09478259086608887,
      0.06266675889492035,
      0.024837523698806763,
      0.04024367406964302,
      -0.07784054428339005,
      -0.030694417655467987,
      -0.05402129516005516,
      -0.043424662202596664,
      0.04698622599244118,
      -0.02283770963549614,
      0.014957386068999767,
      -0.01116815023124218,
      0.09616883099079132,
      0.0151765001937747,
      4.29843166784849e-05,
      0.04709280654788017,
      0.0010037071770057082,
      0.04243505373597145,
      -0.10393104702234268,
      0.021910443902015686,
      -0.043009039014577866,
      -0.031275663524866104,
      -0.012309914454817772,
      -0.04337751492857933,
      -0.023171257227659225,
      -0.1231132373213768,
      0.00381911126896739,
      -0.006013990845531225,
      -0.019133441150188446,
      0.006782009266316891,
      -0.07905922830104828,
      0.006000123452395201,
      -0.023425212129950523,
      -0.026260139420628548,
      0.10055484622716904,
      -0.0030043767765164375,
      -0.005072331987321377,
      0.05954264849424362,
      0.0352461002767086,
      0.02476787194609642,
      -0.03318034112453461,
      -0.05713677033782005,
      0.04645119607448578,
      -0.04743156582117081,
      -0.019702529534697533,
      0.0386677123606205,
      -0.03654904291033745,
      -0.013800044544041157,
      0.02513647824525833,
      0.014990401454269886,
      0.00038882993976585567,
      0.02116583287715912,
      0.008456635288894176,
      -0.030867191031575203,
      -0.05583827197551727,
      0.01507508009672165,
      0.018235940486192703,
      -0.10282735526561737,
      -0.019733402878046036,
      -0.10957759618759155,
      0.05503377318382263,
      0.02542966417968273,
      0.0010214068461209536,
      -0.051079895347356796,
      0.010146458633244038,
      -0.02737259306013584,
      0.008496073074638844,
      0.01937939040362835,
      -0.05577044561505318,
      -0.02032172679901123,
      -0.0924023687839508,
      0.0244846660643816,
      -0.031262267380952835,
      0.07252872735261917,
      0.014291956089437008,
      0.07708924263715744,
      0.07323896884918213,
      -0.004778600763529539,
      0.05487719550728798,
      -0.01125643402338028,
      0.04660361260175705,
      0.007249865680932999,
      -0.016923949122428894,
      -0.06082089990377426,
      -0.01619449444115162,
      0.026471206918358803,
      -0.08146439492702484,
      -0.005166955757886171,
      -0.04143407195806503,
      0.04335189238190651,
      0.03378976881504059,
      -0.008915579877793789,
      0.009307499043643475,
      0.02546994760632515,
      0.02703898586332798,
      -0.021077709272503853,
      -0.05871957913041115,
      0.027910271659493446,
      0.005112695973366499,
      0.03144372999668121,
      0.00615656329318881,
      0.018231460824608803,
      -0.01603715494275093,
      0.052838586270809174,
      -0.011283976957201958,
      -0.03735964372754097,
      0.047081198543310165,
      0.0008309761178679764,
      0.01612294837832451,
      0.013484353199601173,
      -0.003953632898628712,
      0.029071390628814697,
      -0.012403895147144794,
      -0.05844339728355408,
      0.011516360566020012,
      -0.015606394968926907,
      -0.012226157821714878,
      -0.023655656725168228,
      0.05494241416454315,
      -0.03189380466938019,
      0.011011877097189426,
      -0.09546207636594772,
      -0.010893195867538452,
      -0.023202119395136833,
      0.017113521695137024,
      -0.008957060985267162,
      -0.006923122331500053,
      0.04895495995879173,
      -0.0029823314398527145,
      -2.6965242795995437e-05,
      -0.0015804865397512913,
      0.09110517799854279,
      0.00020604430756065995,
      -0.005727383773773909,
      -0.028292398899793625,
      0.025408659130334854,
      -0.1448564976453781,
      0.026361068710684776,
      -0.008169292472302914,
      -0.00563217094168067,
      0.0010145030682906508,
      0.0070436932146549225,
      -0.01726771704852581,
      0.007481561973690987,
      -0.04784137383103371,
      -0.02115398272871971,
      -0.05600649490952492,
      -0.12384989112615585,
      -0.034958094358444214,
      -0.018993783742189407,
      0.04565746709704399,
      -0.03201793506741524,
      -0.07367262244224548,
      -0.04234772175550461,
      0.00423353910446167,
      -0.01583375222980976,
      -0.005335228983312845,
      -0.04199835658073425,
      -0.1838323473930359,
      -0.10412634909152985,
      0.03240229934453964,
      0.0099757956340909,
      -0.08063309639692307,
      0.030928585678339005,
      -0.04293143376708031,
      0.01202175859361887,
      0.036265917122364044,
      -0.040441159158945084,
      -0.019300999119877815,
      -0.04266643151640892,
      0.0713401585817337,
      -0.027202049270272255,
      0.05124504491686821,
      -0.0524282269179821,
      -0.053629666566848755,
      0.022071633487939835,
      0.0244122501462698,
      -0.03348761796951294,
      0.027194883674383163,
      0.01881360076367855,
      0.008055591024458408,
      -0.040055498480796814,
      0.030759230256080627,
      0.048809800297021866,
      0.006845531519502401,
      0.04416637495160103,
      0.009247185662388802,
      -0.05638841539621353,
      0.012905819341540337,
      -0.07604150474071503,
      -0.06921425461769104,
      0.05780765414237976,
      0.04131646826863289,
      -0.047773391008377075,
      0.05815335735678673,
      0.03460192680358887,
      -0.014663384296000004,
      -0.03889663144946098,
      0.07014407962560654,
      -0.018643980845808983,
      -0.01823524385690689,
      0.00432566087692976,
      0.008765303529798985,
      -0.010784199461340904,
      0.017034703865647316,
      -0.04403567314147949,
      0.01689080521464348,
      0.054912712424993515,
      0.04095185175538063,
      0.075811468064785,
      -0.024551929906010628,
      0.015377137809991837,
      -0.006228927988559008,
      0.0026566071901470423,
      -0.060524310916662216,
      0.009603040292859077,
      -0.0012844704324379563,
      -0.02225901372730732,
      0.038974080234766006,
      -0.016156889498233795,
      -0.06936375051736832,
      0.006915977690368891,
      0.01623273640871048,
      -0.032532867044210434,
      -0.08432544022798538,
      0.014291507191956043,
      0.04475104436278343,
      -0.04749413952231407,
      0.0072007630951702595,
      0.08181701600551605,
      0.008361795917153358,
      -0.07187783718109131,
      -0.051465969532728195,
      -0.008405636064708233,
      0.021512065082788467,
      -0.016638580709695816,
      -0.049376681447029114,
      0.031338293105363846,
      -0.010436677373945713,
      -0.05521395802497864,
      0.0038808740209788084,
      -0.11165442317724228,
      0.0012326700380071998,
      -0.055624283850193024,
      -0.01883585937321186,
      0.003164628054946661,
      -0.011744200251996517,
      0.05847964063286781,
      0.04532840475440025,
      0.004751423839479685,
      0.02632186748087406,
      0.047826286405324936,
      0.044134970754384995,
      0.006490868516266346,
      0.040501680225133896,
      -0.04077420011162758,
      0.03546838089823723,
      -0.015513048507273197,
      0.005660541821271181,
      -0.015373767353594303,
      0.023332148790359497,
      -0.0002061933046206832,
      -0.015815414488315582,
      0.05959375947713852,
      0.011297231540083885,
      0.04842497035861015,
      -0.0010209833271801472,
      0.02440483868122101,
      0.010507139377295971
    ],
    [
      0.0033885010052472353,
      -0.012648019008338451,
      0.006243924610316753,
      -0.027332879602909088,
      -0.03946685045957565,
      0.014048080891370773,
      -0.08783863484859467,
      -0.0036177332513034344,
      0.10277888178825378,
      0.04820605739951134,
      0.07463647425174713,
      -0.03855019807815552,
      0.008129249326884747,
      0.05610044673085213,
      0.06448165327310562,
      0.025088300928473473,
      -0.10287082195281982,
      -0.012972582131624222,
      -0.029531512409448624,
      0.011183223687112331,
      -0.02423330955207348,
      -0.04233511537313461,
      0.05164777860045433,
      0.038453489542007446,
      -0.04039027914404869,
      0.07883456349372864,
      -0.03328419476747513,
      -0.06587792187929153,
      0.02142953686416149,
      -0.036469634622335434,
      -0.033566467463970184,
      -0.07573477923870087,
      0.08052816987037659,
      0.019112853333353996,
      -0.00979564618319273,
      -0.060167618095874786,
      -0.006078490987420082,
      -0.038110170513391495,
      -0.011935701593756676,
      -0.014677795581519604,
      -0.003887756261974573,
      -0.05553755164146423,
      0.06068450212478638,
      -0.03542439267039299,
      0.023559296503663063,
      0.09885764867067337,
      -0.0767289474606514,
      -0.000497962988447398,
      0.04359162971377373,
      -0.04975738748908043,
      0.022563951089978218,
      -0.02387257106602192,
      0.03265270218253136,
      0.0070886737667024136,
      0.013036579824984074,
      -0.050405893474817276,
      -0.1126578226685524,
      -0.09691499918699265,
      -0.017986368387937546,
      0.05353245139122009,
      -0.023559458553791046,
      0.07241003215312958,
      0.05928264930844307,
      -0.01924709603190422,
      0.03672197833657265,
      0.016109852120280266,
      0.021200209856033325,
      -0.005280220881104469,
      -0.0359414666891098,
      -0.06763056665658951,
      -0.07948850840330124,
      0.0007994842599146068,
      -0.03206899017095566,
      -0.07927446067333221,
      -0.061108946800231934,
      -0.05101661756634712,
      -0.024114012718200684,
      0.00887168850749731,
      -0.032666221261024475,
      -0.00838287454098463,
      -0.006265584845095873,
      -0.02264558896422386,
      -0.01824469305574894,
      0.057635169476270676,
      0.0024976697750389576,
      -0.007233758457005024,
      -0.002335313707590103,
      -0.12489810585975647,
      -0.033386487513780594,
      0.008522991091012955,
      -0.09186609089374542,
      0.05946135148406029,
      -0.11882778257131577,
      -0.08245348930358887,
      0.04190026968717575,
      -0.0212223120033741,
      -0.034119799733161926,
      0.0679963082075119,
      0.0446438267827034,
      0.058009155094623566,
      -0.021882575005292892,
      0.010943043977022171,
      0.0030359847005456686,
      0.02255329303443432,
      -0.0299251526594162,
      -0.0511351153254509,
      -0.08151913434267044,
      -0.020292557775974274,
      0.041293952614068985,
      -0.03038042224943638,
      -0.05956996977329254,
      0.0329456552863121,
      -0.012478886172175407,
      0.03123386576771736,
      -0.0004062164225615561,
      -0.04493425413966179,
      -0.0810873880982399,
      0.02902819775044918,
      0.07470886409282684,
      0.018463820219039917,
      0.03134322166442871,
      -0.018913045525550842,
      0.10922838002443314,
      0.021041061729192734,
      0.008542979136109352,
      0.002124066697433591,
      0.006064105778932571,
      -0.011202748864889145,
      0.030036866664886475,
      -0.007751160766929388,
      -0.035190895199775696,
      0.002890052506700158,
      -0.08099351823329926,
      0.0870831310749054,
      0.008617836982011795,
      0.02134464867413044,
      0.017195118591189384,
      0.05502341315150261,
      0.0029780699405819178,
      0.032371144741773605,
      0.037303730845451355,
      0.028823893517255783,
      -0.06448252499103546,
      0.061597466468811035,
      0.08802416175603867,
      -0.012065203860402107,
      0.008129655383527279,
      -0.041733864694833755,
      0.0036554140970110893,
      -0.004447401035577059,
      -0.031178995966911316,
      0.03319914638996124,
      0.06911081820726395,
      0.021422099322080612,
      -0.06930941343307495,
      -0.02397364377975464,
      0.054857708513736725,
      -0.00415867380797863,
      0.061043184250593185,
      -0.026559673249721527,
      -0.07114608585834503,
      -0.01698669046163559,
      -0.08060836046934128,
      -0.050219565629959106,
      -0.015486117452383041,
      0.10184508562088013,
      0.004217394627630711,
      -0.02679874747991562,
      -0.07000534981489182,
      -0.006659563630819321,
      0.021987710148096085,
      -0.05415576696395874,
      0.007644229102879763,
      -0.07178094238042831,
      0.02906760945916176,
      -0.04305198788642883,
      -0.0493311733007431,
      0.0529143251478672,
      0.037419393658638,
      -0.06946417689323425,
      0.014111046679317951,
      0.05194869637489319,
      -0.006634438876062632,
      -0.14350193738937378,
      0.01624833233654499,
      0.019097546115517616,
      0.045535508543252945,
      0.023687759414315224,
      0.00869446899741888,
      -0.08263838291168213,
      -0.05195406824350357,
      0.059578437358140945,
      0.03685257211327553,
      0.038423214107751846,
      0.011303668841719627,
      -0.017011748626828194,
      0.012467646971344948,
      -0.02487567812204361,
      -0.06159770488739014,
      0.0441085621714592,
      0.013490328565239906,
      0.04424537345767021,
      -0.026566848158836365,
      0.08119385689496994,
      -0.007857488468289375,
      -0.06894385814666748,
      -0.002200271002948284,
      0.03431762754917145,
      -0.03278176113963127,
      -0.045685429126024246,
      -0.05243396386504173,
      0.04156250134110451,
      0.11619608104228973,
      0.017266223207116127,
      -0.04885160177946091,
      0.05487813055515289,
      -0.06302373856306076,
      0.025622505694627762,
      -0.06490196287631989,
      0.008153192698955536,
      -0.04003351554274559,
      -0.025691714137792587,
      -0.05272647365927696,
      0.08193730562925339,
      0.01319871935993433,
      0.10939369350671768,
      0.12141258269548416,
      -0.0032945703715085983,
      -0.011727829463779926,
      -0.030844906345009804,
      -0.05319869890809059,
      0.006536244880408049,
      -0.04576902836561203,
      0.027026643976569176,
      0.007253129500895739,
      0.02474542148411274,
      0.031444255262613297,
      -0.04130411520600319,
      -0.06574784219264984,
      -0.06046983227133751,
      0.015395752154290676,
      0.035867929458618164,
      0.026981014758348465,
      -0.03821080923080444,
      -0.1156916469335556,
      0.004635978955775499,
      0.050943780690431595,
      0.03859301283955574,
      0.017956620082259178,
      0.040667541325092316,
      0.0105705251917243,
      0.015157751739025116,
      0.01265079714357853,
      0.004820563830435276,
      -0.08998007327318192,
      0.006251504644751549,
      0.028009340167045593,
      -0.01742030307650566,
      -0.008799340575933456,
      -0.011580589227378368,
      -0.0534575954079628,
      -0.04497571289539337,
      0.05354177579283714,
      0.0581047460436821,
      -0.0015857839025557041,
      0.012952539138495922,
      0.015266979113221169,
      0.00320609612390399,
      0.03793974220752716,
      -0.00998377613723278,
      -0.05264873430132866,
      0.007474017329514027,
      -0.04207568243145943,
      0.02634424716234207,
      -0.0283307246863842,
      -0.02946699596941471,
      0.03022700361907482,
      -0.035863328725099564,
      -0.02719874307513237,
      -0.11473824828863144,
      -0.00674227811396122,
      0.09081108868122101,
      -0.02957957424223423,
      -0.03626735880970955,
      -0.006932527292519808,
      -0.022761275991797447,
      0.05925425514578819,
      0.027658727020025253,
      -0.007180866785347462,
      -0.02891540341079235,
      0.01640857569873333,
      -0.06284355372190475,
      0.0794210359454155,
      -0.008322753943502903,
      -0.024085192009806633,
      -0.06277324259281158,
      -0.000188223464647308,
      0.0021558997686952353,
      -0.05379827320575714,
      0.07621245086193085,
      -0.048790160566568375,
      0.008318831212818623,
      0.033640798181295395,
      -0.009828666225075722,
      -0.04657241329550743,
      -0.006359288468956947,
      0.052692633122205734,
      0.016729852184653282,
      0.015728488564491272,
      -0.056324977427721024,
      0.06676081568002701,
      -0.04254338517785072,
      -0.001159770879894495,
      0.03984753042459488,
      -0.038289330899715424,
      0.018928762525320053,
      -0.0015649920096620917,
      0.01970660127699375,
      -0.007134916260838509,
      -0.08383433520793915,
      -0.08662566542625427,
      -0.03209373354911804,
      0.05935303866863251,
      0.06629113852977753,
      -0.0010182391852140427,
      0.07487612962722778,
      0.027592478320002556,
      -0.015137693844735622,
      0.026832226663827896,
      -0.01999153196811676,
      0.08401275426149368,
      0.00691099651157856,
      0.09057873487472534,
      0.044197674840688705,
      -0.04848422110080719,
      -0.007763969711959362,
      -0.0031741096172481775,
      0.022308478131890297,
      -0.02989828772842884,
      0.007920337840914726,
      0.010047816671431065,
      -0.01026791799813509,
      0.03719136118888855,
      -0.010172570124268532,
      0.0031694669742137194,
      0.051370080560445786,
      -0.010099652223289013,
      -0.025530975311994553,
      0.042175956070423126,
      -0.02083749510347843,
      -0.02058899775147438,
      -0.027557700872421265,
      -0.0873335748910904,
      0.05126412212848663,
      -0.06227462738752365,
      -0.026076700538396835,
      0.015573138371109962,
      -0.032686758786439896,
      0.05380052700638771,
      -0.06551948189735413,
      0.016293520107865334,
      0.008683979511260986,
      0.04497562348842621,
      -0.03230360522866249,
      0.04652206227183342,
      0.04810136556625366,
      0.03343876078724861,
      -0.06733117997646332,
      -0.04124806076288223,
      0.06832466274499893,
      0.007638146635144949,
      0.006286043208092451,
      -0.015769390389323235,
      0.04445187747478485,
      -0.004607006441801786,
      0.021809492260217667,
      0.012255927547812462,
      -0.04929877817630768,
      -0.029984034597873688,
      0.04016169533133507,
      0.047017890959978104,
      0.04140304774045944,
      -0.08505026996135712,
      -0.07254242897033691,
      0.031338855624198914,
      -0.055896081030368805,
      0.06581418961286545,
      -0.07580357789993286,
      -0.05040454491972923,
      -0.04679502546787262,
      -0.018366748467087746,
      -0.001116627943702042,
      0.0012167799286544323,
      -0.05238758772611618,
      -0.05384736508131027,
      -0.03504018113017082,
      0.00991163868457079,
      0.06636838614940643,
      -0.08503430336713791,
      0.01302533783018589,
      0.008864889852702618,
      0.011374115012586117,
      0.024997249245643616,
      -0.010633920319378376,
      0.02968335710465908,
      -0.08418425172567368,
      0.006059463135898113,
      -0.05846455693244934,
      0.09071725606918335,
      -0.018359489738941193,
      0.008624040521681309,
      -0.09331641346216202,
      -0.019452542066574097,
      0.02038292959332466,
      0.014077775180339813,
      0.05685994029045105,
      0.037735845893621445,
      -0.01938415877521038,
      0.0512034110724926,
      -0.02960328198969364,
      -0.07034426927566528,
      0.00755891390144825,
      -0.020376332104206085,
      0.035165928304195404,
      0.012392343953251839,
      0.014028958976268768,
      -0.020206961780786514,
      0.022714000195264816,
      0.0024813171476125717,
      -0.021960671991109848,
      -0.06972968578338623,
      0.04675668850541115,
      0.028697488829493523,
      -0.05135548487305641,
      6.822172144893557e-05,
      0.014793495647609234,
      -0.012973085977137089,
      -0.09453337639570236,
      -0.029109150171279907,
      -0.03006024844944477,
      -0.01913885958492756,
      -0.026991907507181168,
      0.0069023314863443375,
      -0.05260426923632622,
      -0.04730924591422081,
      -0.012429461814463139,
      -0.09110216051340103,
      0.054389506578445435,
      0.03345521539449692,
      -0.0014182266313582659,
      0.06106403470039368,
      0.04654714837670326,
      0.04031797870993614,
      0.0278322771191597,
      0.029791340231895447,
      -0.004658897873014212,
      0.007788998540490866,
      0.006665378343313932,
      -0.008551208302378654,
      -0.04215496778488159,
      -0.07610471546649933,
      -0.012767438776791096,
      -0.02659837156534195,
      0.09187325090169907,
      -0.08732251077890396,
      -0.06169687956571579,
      0.06116918846964836,
      0.07719220221042633,
      -0.030369095504283905,
      -0.03311581537127495,
      -0.07518462836742401,
      -0.03950902447104454,
      0.03924126550555229,
      0.0351526141166687,
      -0.058016855269670486,
      0.07058414816856384,
      -0.02268848940730095,
      -0.008074627257883549,
      0.004379295278340578,
      -0.024396328255534172,
      0.020000742748379707,
      0.030180763453245163,
      0.00877567008137703,
      -0.08263659477233887,
      -0.04088851064443588,
      0.05184236541390419,
      0.03716116026043892,
      0.00526441028341651,
      0.07020494341850281,
      0.05771419405937195,
      0.05096631124615669,
      -0.013307536952197552,
      0.16574713587760925,
      0.06723097711801529,
      0.059485264122486115,
      -0.035401180386543274,
      -0.044297996908426285,
      0.030738169327378273,
      -0.05214688926935196,
      0.04861460253596306,
      -0.012731458060443401,
      0.0032016043551266193,
      0.10999931395053864
    ],
    [
      0.094126857817173,
      -0.04550144076347351,
      0.032540734857320786,
      -0.025653567165136337,
      -0.04743948206305504,
      0.016097357496619225,
      -0.02715623378753662,
      -0.03335142880678177,
      0.1062721535563469,
      0.05316800996661186,
      0.02248694933950901,
      0.03855011612176895,
      -0.022701356559991837,
      -0.020140361040830612,
      -0.015998996794223785,
      0.011521974578499794,
      0.02870684675872326,
      -0.07792654633522034,
      0.08218181878328323,
      0.0926695168018341,
      -0.02758174203336239,
      -0.016422724351286888,
      -0.06263066828250885,
      -0.016547296196222305,
      -0.04217883571982384,
      0.05741729214787483,
      -0.056648336350917816,
      0.09810248762369156,
      0.03674919158220291,
      -0.009496440179646015,
      -0.0062279836274683475,
      0.04120699316263199,
      0.08970696479082108,
      -0.0036794098559767008,
      -0.0305438544601202,
      -0.05924806371331215,
      0.013248307630419731,
      -0.02762567065656185,
      -0.099387027323246,
      -0.06022936478257179,
      0.08158951252698898,
      -0.05165882036089897,
      0.010611632838845253,
      0.016875620931386948,
      -0.02496797777712345,
      0.05453304573893547,
      -0.037424974143505096,
      -0.022251376882195473,
      0.06524038314819336,
      0.04354258254170418,
      -0.023342449218034744,
      0.058325689285993576,
      0.04558907449245453,
      -0.004891248885542154,
      0.02046574465930462,
      0.08506059646606445,
      -0.03688594326376915,
      -0.0482049398124218,
      -0.06477176398038864,
      0.045998714864254,
      0.049515701830387115,
      -0.09757886081933975,
      -0.05415065586566925,
      -0.00603626761585474,
      0.06212279573082924,
      0.12274527549743652,
      -0.008709622547030449,
      -0.03003860078752041,
      -0.07544101029634476,
      0.005421258509159088,
      0.012207096442580223,
      0.1263541579246521,
      -0.062475815415382385,
      -0.060879629105329514,
      -0.04738165810704231,
      0.03055746853351593,
      0.005251010879874229,
      0.10348731279373169,
      0.0249212384223938,
      0.012347242794930935,
      -0.04625793918967247,
      0.018554259091615677,
      0.05651570111513138,
      0.02556263841688633,
      -0.052874960005283356,
      0.0557963103055954,
      -0.080881766974926,
      0.03272493928670883,
      0.07534749060869217,
      -0.019109880551695824,
      0.007384798023849726,
      0.02138685993850231,
      0.023367030546069145,
      -0.01285258587449789,
      -0.035699110478162766,
      -0.0715913400053978,
      -0.038329895585775375,
      -0.05440683662891388,
      0.05939376354217529,
      -0.02655947394669056,
      -0.06829437613487244,
      -0.04763376712799072,
      0.07767219841480255,
      0.043313946574926376,
      0.048247329890728,
      -0.030726676806807518,
      -0.053365446627140045,
      -0.05393581837415695,
      0.00263859611004591,
      0.03975588083267212,
      -0.06139514967799187,
      -0.06863916665315628,
      0.05867743864655495,
      0.00023152514768298715,
      -0.04722736403346062,
      -0.018169410526752472,
      0.02000945247709751,
      0.11557838320732117,
      -0.017793407663702965,
      0.0018286184640601277,
      0.021286167204380035,
      0.023652536794543266,
      0.008736032992601395,
      -0.12162023782730103,
      -0.026248691603541374,
      0.017126193270087242,
      0.07627610117197037,
      -0.08986347168684006,
      -0.05527972802519798,
      -0.033334340900182724,
      0.03066405840218067,
      -0.09511731564998627,
      0.02276534028351307,
      0.05507747083902359,
      -0.040248073637485504,
      -0.0818219855427742,
      -0.036616481840610504,
      -0.04329106956720352,
      0.040713801980018616,
      0.0016603281255811453,
      0.0037827682681381702,
      -0.006059968378394842,
      0.005256976932287216,
      -0.019390355795621872,
      0.0019445086363703012,
      0.036335211247205734,
      -0.05167456716299057,
      0.03552449122071266,
      0.020080184563994408,
      0.04428088665008545,
      0.03295048698782921,
      0.04355763643980026,
      0.008721794001758099,
      0.06766188889741898,
      -0.037868302315473557,
      0.06811479479074478,
      -0.030309926718473434,
      -0.04422462359070778,
      -0.07112599909305573,
      0.021777018904685974,
      -0.0339159220457077,
      -0.061710868030786514,
      0.04337247461080551,
      0.06205327436327934,
      -0.02641364559531212,
      -0.01237442810088396,
      -0.007416617590934038,
      -0.02027076669037342,
      0.06563882529735565,
      -0.008436426520347595,
      0.041610389947891235,
      0.014902623370289803,
      0.0011011003516614437,
      -0.03618809953331947,
      -0.039986610412597656,
      0.028848497197031975,
      -0.05831657350063324,
      0.025239797309041023,
      0.004678545985370874,
      0.04785505309700966,
      -0.009117107838392258,
      0.010352042503654957,
      0.06600111722946167,
      0.05098938196897507,
      -0.03108035959303379,
      0.00019065600645262748,
      0.11312390118837357,
      -0.04352646693587303,
      -0.05199147388339043,
      -0.012773534283041954,
      0.013877594843506813,
      0.055603377521038055,
      -0.07675959169864655,
      -0.044656287878751755,
      -0.007297929376363754,
      -0.04358392581343651,
      0.0006193657172843814,
      0.03029559925198555,
      -0.06126443296670914,
      0.0024908040650188923,
      0.011960536241531372,
      0.007583301048725843,
      0.051218610256910324,
      -0.05835308879613876,
      0.006392193492501974,
      -0.014698381535708904,
      0.05265297740697861,
      0.035379815846681595,
      -0.025941750034689903,
      0.04356909543275833,
      -0.12950874865055084,
      0.03662553057074547,
      0.0324433259665966,
      0.002596781123429537,
      0.00440174387767911,
      0.026597460731863976,
      0.00036242982605472207,
      -0.0320984311401844,
      0.061057236045598984,
      -0.04810471460223198,
      0.04508930444717407,
      -0.020625723525881767,
      0.007846234366297722,
      0.032548531889915466,
      0.016006771475076675,
      -0.05847405269742012,
      -0.019142167642712593,
      0.010184792801737785,
      -0.05085905268788338,
      0.0499979667365551,
      -0.007192112505435944,
      0.01534245815128088,
      -0.05754394456744194,
      -0.06350080668926239,
      -0.0003349012404214591,
      -0.052887387573719025,
      0.012638268060982227,
      -0.03615894541144371,
      -0.03914235532283783,
      -0.03646526858210564,
      0.05590542405843735,
      -0.02639114484190941,
      0.04137517884373665,
      0.07869894802570343,
      -0.0829613208770752,
      0.03186077997088432,
      0.00798769760876894,
      -0.0401352122426033,
      0.0005651313695125282,
      0.13699771463871002,
      -0.03326376900076866,
      -0.023756347596645355,
      0.04972158744931221,
      0.06432382017374039,
      0.0236501581966877,
      -0.007589839398860931,
      0.0878373235464096,
      0.05461643263697624,
      -0.01726880483329296,
      0.04199989512562752,
      -0.055277809500694275,
      0.04046669229865074,
      -0.012457738630473614,
      0.018662799149751663,
      0.011753696948289871,
      0.0032228913623839617,
      -0.052100181579589844,
      -0.01029668003320694,
      -0.0009578432072885334,
      -0.06204766780138016,
      -0.051779184490442276,
      0.04261639341711998,
      -0.024427972733974457,
      0.010229486040771008,
      -0.006911792792379856,
      -0.037750132381916046,
      -0.022594772279262543,
      -0.008956288918852806,
      -0.002059267135336995,
      0.0255887471139431,
      -0.04694196581840515,
      0.023596754297614098,
      -0.02249828912317753,
      0.034234169870615005,
      -0.05839889869093895,
      0.01961226761341095,
      0.019704613834619522,
      0.023761292919516563,
      -0.053988322615623474,
      -0.014122267253696918,
      0.04072447493672371,
      -0.029967643320560455,
      -0.0805727019906044,
      0.012877249158918858,
      -0.04439448192715645,
      -0.02396712638437748,
      -0.058736126869916916,
      -0.024555284529924393,
      0.057252757251262665,
      0.040580183267593384,
      -0.02994026429951191,
      0.0321539044380188,
      -0.12649409472942352,
      -0.018878912553191185,
      -0.0557793565094471,
      -0.07746519148349762,
      0.021185345947742462,
      0.021167118102312088,
      -0.005571542773395777,
      0.04844606667757034,
      0.025521963834762573,
      -0.08863230794668198,
      -0.016429465264081955,
      -0.02727118320763111,
      -0.08266763389110565,
      0.06775400042533875,
      -0.03527236357331276,
      -0.001946902833878994,
      -0.07160457968711853,
      -0.034675780683755875,
      -0.014180541038513184,
      0.11163534969091415,
      -0.04222317412495613,
      -0.008835972286760807,
      -0.04888148978352547,
      0.04122021421790123,
      0.00159386673476547,
      -0.04230243340134621,
      -0.0722089409828186,
      0.010591992177069187,
      -0.059180110692977905,
      0.008074701763689518,
      0.005271836183965206,
      -0.02436368353664875,
      -0.012639656662940979,
      -0.03288070112466812,
      0.009691382758319378,
      0.024518830701708794,
      -0.016259530559182167,
      -0.0016840753378346562,
      -0.03628649562597275,
      -0.07467512786388397,
      -0.05317556485533714,
      0.05497588962316513,
      0.03309641033411026,
      0.06257861852645874,
      0.07722581177949905,
      -0.058384478092193604,
      0.0007286745239980519,
      0.0836949348449707,
      0.027011428028345108,
      -0.03107672557234764,
      -0.030241848900914192,
      -0.054363034665584564,
      -0.034481510519981384,
      0.07268412411212921,
      0.016223279759287834,
      -0.006246502045542002,
      0.002822035225108266,
      -0.030835650861263275,
      0.03167199715971947,
      0.020476151257753372,
      0.006714311428368092,
      -0.0013357973657548428,
      0.04685791954398155,
      0.1107349693775177,
      -0.04996396601200104,
      -0.035615473985672,
      0.03617173433303833,
      -0.05064184218645096,
      0.03461895138025284,
      0.010157042182981968,
      0.05338704586029053,
      0.009331644512712955,
      0.04665368050336838,
      0.02949908934533596,
      0.019392892718315125,
      -0.049149349331855774,
      -0.09890825301408768,
      0.0015407592291012406,
      0.02473834715783596,
      0.03636527061462402,
      -0.0017057617660611868,
      -0.01733408309519291,
      -0.04921965301036835,
      -0.030697347596287727,
      0.004340686369687319,
      -0.07125608623027802,
      0.00026270191301591694,
      -0.04429414123296738,
      0.011501630768179893,
      0.048671573400497437,
      -0.06147218123078346,
      0.028783444315195084,
      -0.012327020987868309,
      -0.036738380789756775,
      -0.003962456714361906,
      0.016903597861528397,
      -0.017552150413393974,
      -0.09148342162370682,
      -0.005331085994839668,
      -0.0024846168234944344,
      -0.043823204934597015,
      -0.07230651378631592,
      0.016880983486771584,
      -0.02675873041152954,
      0.014612056314945221,
      0.013050613924860954,
      0.06461234390735626,
      0.017929410561919212,
      0.021375108510255814,
      -0.031283166259527206,
      0.059600479900836945,
      -0.04673491418361664,
      -0.03418612852692604,
      0.024260733276605606,
      0.004661389626562595,
      -0.03703514486551285,
      0.008995470590889454,
      -0.06552977859973907,
      -0.09598837047815323,
      -0.07295407354831696,
      0.03702980652451515,
      0.03668683022260666,
      -0.04401358589529991,
      0.021595237776637077,
      -0.03419111296534538,
      -0.03913649544119835,
      0.03108941949903965,
      -0.05358165502548218,
      0.017559651285409927,
      0.01286136731505394,
      0.0554572269320488,
      0.005566874518990517,
      0.014821715652942657,
      0.0739772841334343,
      0.05944499745965004,
      0.024547617882490158,
      -0.012197119183838367,
      0.046911418437957764,
      0.07639890909194946,
      -0.07251201570034027,
      0.09134003520011902,
      0.08136435598134995,
      -0.05061186105012894,
      0.011516599915921688,
      -0.0776766985654831,
      0.058161716908216476,
      -0.03586912900209427,
      -0.00863560289144516,
      -0.052276819944381714,
      -0.06857384741306305,
      0.016353975981473923,
      -0.009719367139041424,
      -0.03923816606402397,
      -0.11322488635778427,
      -0.07077497243881226,
      -0.0008403739193454385,
      0.008452213369309902,
      0.011607210151851177,
      -0.06306912004947662,
      -0.07603615522384644,
      0.008328390307724476,
      0.05003281310200691,
      0.010519447736442089,
      -0.01157124899327755,
      0.0712549239397049,
      -0.039924658834934235,
      6.92487446940504e-05,
      0.008902248926460743,
      -0.07505499571561813,
      0.05954939126968384,
      -0.02217772975564003,
      -0.056257884949445724,
      0.054745059460401535,
      -0.010297244414687157,
      -0.04582821577787399,
      -0.040780652314424515,
      -0.06010570377111435,
      0.031075743958353996,
      -0.045862291008234024,
      0.060384225100278854,
      -0.034788504242897034,
      -0.0460980050265789,
      -0.019158927723765373,
      0.06490235030651093,
      -0.04012058675289154,
      0.013266904279589653,
      0.06707349419593811,
      0.06079963967204094,
      -0.04111417382955551,
      0.018319513648748398,
      0.10184244811534882,
      -0.029399564489722252,
      0.03689069673418999,
      -0.027141965925693512,
      0.015870986506342888,
      0.10974138230085373,
      -0.09025359898805618,
      0.06988511979579926,
      0.03291979804635048,
      0.033587273210287094,
      0.03023112192749977
    ],
    [
      0.029421629384160042,
      -0.0072076283395290375,
      0.028377477079629898,
      -0.05465463548898697,
      -0.034816838800907135,
      0.028576459735631943,
      -0.026023736223578453,
      0.01814481057226658,
      0.003024317091330886,
      -0.05784805864095688,
      0.0059706843458116055,
      0.014965949580073357,
      -0.04926413670182228,
      -0.046530790627002716,
      -0.024800077080726624,
      0.04944556951522827,
      -0.025803403928875923,
      0.005374706815928221,
      0.02153201214969158,
      -0.030886882916092873,
      0.05137648433446884,
      -0.011503909714519978,
      0.007114417850971222,
      -0.026886561885476112,
      0.022779375314712524,
      -0.04250446334481239,
      -0.011273931711912155,
      0.11874374002218246,
      0.0742390900850296,
      0.056303247809410095,
      0.03924805670976639,
      -0.06238974258303642,
      -0.006056260317564011,
      -0.005695792380720377,
      -0.0028102772776037455,
      -0.0126185342669487,
      -0.014781995676457882,
      0.0297534316778183,
      -0.0013386443024501204,
      -0.029937222599983215,
      0.1559024602174759,
      0.06312578171491623,
      -0.04572932794690132,
      0.11153650283813477,
      0.005910496227443218,
      -0.010817795991897583,
      -0.05222893878817558,
      0.03906070441007614,
      0.09141732007265091,
      0.0006651092553511262,
      -0.0028830247465521097,
      -0.031819622963666916,
      -0.03166194632649422,
      0.012184442020952702,
      0.04927966743707657,
      -0.006593302823603153,
      -0.09298094362020493,
      -0.02674141526222229,
      -0.03963605314493179,
      -0.02857181802392006,
      0.048452042043209076,
      -0.0025460231117904186,
      -0.036641914397478104,
      0.03808434307575226,
      0.03992222994565964,
      -0.02033701166510582,
      0.0006899808649905026,
      -0.02910425513982773,
      0.0381668284535408,
      0.0759446769952774,
      0.06189175695180893,
      0.001458495738916099,
      -0.03437414392828941,
      0.027892202138900757,
      0.02107168547809124,
      0.0348096638917923,
      0.0117512671276927,
      0.0019230589969083667,
      0.03603067249059677,
      0.02585204690694809,
      -0.018641557544469833,
      -0.01182076707482338,
      -0.0010553284082561731,
      0.001444903900846839,
      -0.06395382434129715,
      -0.028029778972268105,
      -0.03998120501637459,
      0.07815811783075333,
      -0.021801814436912537,
      -0.09984762966632843,
      -0.055389679968357086,
      -0.008241456001996994,
      -0.01843717135488987,
      -0.11537835747003555,
      0.016141226515173912,
      -0.015409792773425579,
      -0.06002170965075493,
      -0.039183951914310455,
      -0.027738181874155998,
      0.12334875762462616,
      0.010663767345249653,
      0.13880448043346405,
      -0.016492417082190514,
      0.09974882006645203,
      0.031666290014982224,
      -0.02854793705046177,
      -0.0012409277260303497,
      -0.017267568036913872,
      -0.030764054507017136,
      0.02674211375415325,
      -0.003126537660136819,
      -0.01837180368602276,
      0.006945264991372824,
      0.011012064293026924,
      0.10562656819820404,
      0.03426641225814819,
      -0.0009066168568097055,
      0.0017284393543377519,
      0.036944858729839325,
      -0.0031832719687372446,
      -0.05801130458712578,
      -0.041550952941179276,
      -0.01159442588686943,
      -0.024983158335089684,
      -0.018454037606716156,
      0.014698182232677937,
      -0.001323069678619504,
      0.08676798641681671,
      -0.004529946018010378,
      -0.021580025553703308,
      -0.007399103604257107,
      -0.010476043447852135,
      0.012917407788336277,
      0.018635662272572517,
      0.043243955820798874,
      -0.045309048146009445,
      -0.04194046929478645,
      0.0048291319981217384,
      -0.01519411988556385,
      0.028820985928177834,
      -0.07074366509914398,
      -0.011552635580301285,
      -0.008793918415904045,
      0.04718416929244995,
      0.015457045286893845,
      0.00892636738717556,
      -0.037887103855609894,
      -0.023203106597065926,
      0.002354838652536273,
      -0.06353256851434708,
      0.05095101520419121,
      -0.0270199216902256,
      0.00801303144544363,
      0.030599478632211685,
      -0.04023636877536774,
      -9.211947326548398e-05,
      0.032675113528966904,
      -0.01844361610710621,
      0.08092169463634491,
      -0.018043790012598038,
      0.00900428369641304,
      0.04107839614152908,
      0.08609075844287872,
      0.03162379935383797,
      -0.0016304790042340755,
      0.02137053944170475,
      0.02188723534345627,
      0.010749894194304943,
      0.051156528294086456,
      -0.01678863726556301,
      0.07809954136610031,
      0.02509532868862152,
      -0.015045862644910812,
      -0.004897586535662413,
      -0.03895096108317375,
      0.017331190407276154,
      -0.07120072096586227,
      0.012285628356039524,
      0.0006721696117892861,
      0.04254898428916931,
      0.033316999673843384,
      0.016156146302819252,
      -0.06877148896455765,
      -0.0309771616011858,
      0.006808137055486441,
      -0.04638800770044327,
      0.04781019687652588,
      0.0035486400593072176,
      -0.004409736953675747,
      0.0018103193724527955,
      -0.03228560835123062,
      -0.05621061846613884,
      0.009139852598309517,
      -0.021338602527976036,
      -0.1066126897931099,
      0.007779417559504509,
      -0.04907442629337311,
      -0.06415244191884995,
      -0.03921231999993324,
      -0.03412260487675667,
      -0.037937480956315994,
      0.018158983439207077,
      0.042813532054424286,
      -0.012922603636980057,
      -0.014399404637515545,
      -0.008200435899198055,
      0.0030149503145366907,
      -0.029534554108977318,
      -0.07983867079019547,
      0.015449770726263523,
      -0.010020594112575054,
      -0.006941362749785185,
      -0.029765993356704712,
      0.027058454230427742,
      -0.07170233875513077,
      0.03546922653913498,
      0.008619122207164764,
      -0.03565942496061325,
      -0.0006583308568224311,
      0.02232418954372406,
      -0.029560809955000877,
      0.03480067104101181,
      -0.033531174063682556,
      0.03090522065758705,
      -0.015032654628157616,
      -0.07395016402006149,
      0.009412193670868874,
      0.057121679186820984,
      0.05835746228694916,
      -0.06295521557331085,
      -0.06547826528549194,
      0.06475318223237991,
      0.016771022230386734,
      0.01738944835960865,
      0.020288335159420967,
      0.009580223821103573,
      0.04566800966858864,
      -0.004459198564291,
      -0.022150477394461632,
      0.01015776488929987,
      -0.04654400795698166,
      -0.023865235969424248,
      0.03873829171061516,
      0.12642104923725128,
      0.027601903304457664,
      0.0239576268941164,
      -0.010218404233455658,
      0.02356322668492794,
      0.010905694216489792,
      0.010013979859650135,
      0.006972531788051128,
      0.09377099573612213,
      -0.042450617998838425,
      0.010750566609203815,
      0.015461833216249943,
      0.01668378710746765,
      0.020746339112520218,
      -0.05521796643733978,
      -0.004697307012975216,
      -0.032251544296741486,
      -0.019272731617093086,
      0.029999861493706703,
      0.05349418520927429,
      0.03213426098227501,
      0.0605078861117363,
      -0.018525797873735428,
      0.035049088299274445,
      -0.06562647968530655,
      0.001479892060160637,
      0.09500786662101746,
      -0.05878989025950432,
      0.0009870958747342229,
      -0.028635945171117783,
      0.014179952442646027,
      -0.030229803174734116,
      0.00018050509970635176,
      -0.05425148457288742,
      0.004180549643933773,
      -0.02147485315799713,
      0.016638517379760742,
      0.09586691111326218,
      -0.06556152552366257,
      -0.03302231431007385,
      0.06901974976062775,
      -0.08775189518928528,
      -0.01917141303420067,
      -0.0027302606031298637,
      -0.008381558582186699,
      0.005618616938591003,
      -0.012222843244671822,
      0.05421534925699234,
      0.04294133558869362,
      0.04907878115773201,
      0.025832397863268852,
      0.09198086708784103,
      0.0244288332760334,
      0.023674240335822105,
      -0.038138654083013535,
      0.010109164752066135,
      -0.019145186990499496,
      0.07387766987085342,
      0.036697715520858765,
      0.026353290304541588,
      0.05254019424319267,
      0.03656848520040512,
      0.0013010448310524225,
      -0.03394395112991333,
      0.05208377167582512,
      -0.007099222857505083,
      -0.016256770119071007,
      0.020412016659975052,
      -0.03197250887751579,
      0.06011350452899933,
      0.023253943771123886,
      -0.008775971829891205,
      -0.013616910204291344,
      0.03069394826889038,
      -0.06772496551275253,
      -0.07140371948480606,
      0.08457192778587341,
      0.010128303430974483,
      -0.0310987439006567,
      0.008722253143787384,
      0.04675770178437233,
      -0.0398809090256691,
      -0.010555519722402096,
      -0.043761301785707474,
      -0.06576555222272873,
      -0.028330795466899872,
      -0.030036024749279022,
      -0.0033903922885656357,
      0.0852939784526825,
      0.04798375815153122,
      -0.023433445021510124,
      0.05704815313220024,
      0.06453049927949905,
      0.06394729763269424,
      -0.01999121531844139,
      0.0026835480239242315,
      -0.0138749023899436,
      0.04872336983680725,
      0.032906170934438705,
      0.08093857765197754,
      -0.014505489729344845,
      0.09668832272291183,
      0.02481687068939209,
      0.04159834608435631,
      -0.08290234953165054,
      0.033276718109846115,
      -0.027510203421115875,
      -0.06946371495723724,
      0.04952770099043846,
      0.016853230074048042,
      0.021210068836808205,
      0.004247481003403664,
      -0.06787403672933578,
      0.01402971986681223,
      0.03550226613879204,
      -0.02844393625855446,
      -0.025723367929458618,
      -0.0172139760106802,
      -0.06657248735427856,
      -0.07723677903413773,
      -0.09809811413288116,
      0.017850499600172043,
      -0.0010174679337069392,
      0.021670524030923843,
      -0.04925950616598129,
      0.00044778644223697484,
      -0.03617647662758827,
      -0.004764428827911615,
      0.011500031687319279,
      0.025935973972082138,
      0.061169955879449844,
      0.0036582567263394594,
      -0.011594611220061779,
      -0.02625448815524578,
      0.019615640863776207,
      -0.0810941532254219,
      0.0019940310157835484,
      0.01317643467336893,
      -0.0491870641708374,
      0.025145545601844788,
      -0.005880800075829029,
      0.08235449343919754,
      -0.023395322263240814,
      0.07909977436065674,
      -0.015352579765021801,
      -0.04112370312213898,
      -0.009350435808300972,
      -0.020713919773697853,
      0.016803191974759102,
      0.021942779421806335,
      -0.07203017175197601,
      -0.0446353442966938,
      0.02826116606593132,
      0.04389049857854843,
      -0.052492134273052216,
      -0.053990595042705536,
      0.06787554174661636,
      0.011689443141222,
      0.019029052928090096,
      0.06211255118250847,
      -0.06680567562580109,
      -0.03372611477971077,
      0.016151292249560356,
      -0.023087404668331146,
      0.04601583257317543,
      0.047071851789951324,
      0.0573929063975811,
      0.028737181797623634,
      -0.008475587703287601,
      -0.013045143336057663,
      -0.04639284685254097,
      -4.085721593583003e-05,
      -0.04602917283773422,
      0.0680009126663208,
      -0.03185535594820976,
      -0.0458030067384243,
      -0.06875390559434891,
      -0.07107501477003098,
      0.06319218128919601,
      -0.039811648428440094,
      -0.010530591011047363,
      0.05665285140275955,
      0.03983905911445618,
      0.016891274601221085,
      0.11096407473087311,
      -0.044910117983818054,
      0.013107726350426674,
      -0.0184700358659029,
      -0.005157550796866417,
      0.013462572358548641,
      -0.01798197627067566,
      -0.06852880120277405,
      0.006461345124989748,
      -0.02197905071079731,
      0.00445011630654335,
      -0.07753078639507294,
      -0.0264060590416193,
      0.031699612736701965,
      0.06767064332962036,
      0.0015914204996079206,
      0.03095199726521969,
      0.011523721739649773,
      -0.04746776074171066,
      0.027842331677675247,
      -0.04441670700907707,
      -0.015197044238448143,
      -0.0802709087729454,
      0.10673759877681732,
      -0.05548125505447388,
      0.06392023712396622,
      -0.017250869423151016,
      -0.02674240618944168,
      -0.04760592803359032,
      -0.01798989810049534,
      0.06977353990077972,
      0.03430371358990669,
      0.09335646033287048,
      -0.11523986607789993,
      0.013815267942845821,
      0.02970510721206665,
      0.07508004456758499,
      0.04319121316075325,
      -0.04499071463942528,
      0.034149765968322754,
      0.04189468175172806,
      0.01477754581719637,
      -0.0018726886482909322,
      0.019995981827378273,
      -0.03877192363142967,
      -0.019808264449238777,
      0.011223739944398403,
      -0.022954989224672318,
      -0.0024854380171746016,
      -0.003978603053838015,
      0.038885198533535004,
      0.01164479274302721,
      0.07057862728834152,
      0.0128287049010396,
      0.015586303547024727,
      -0.007914736866950989,
      0.036857347935438156,
      -0.031139420345425606,
      0.056216660887002945,
      0.0940743237733841,
      -0.039689529687166214,
      0.005893105641007423,
      0.07151947170495987,
      -0.015218577347695827,
      -0.12299739569425583,
      0.051676101982593536,
      0.01690816879272461,
      0.02023334801197052,
      -0.07047853618860245,
      -0.09074342995882034,
      0.05275457352399826,
      -0.011258588172495365,
      -0.04750524461269379,
      -0.020643051713705063,
      0.03313203901052475,
      0.0188914742320776
    ],
    [
      0.06623753905296326,
      0.05810843035578728,
      0.024539414793252945,
      0.0116084860637784,
      0.024167470633983612,
      -0.021019885316491127,
      -0.03542384132742882,
      0.012361968867480755,
      0.041026294231414795,
      -0.015973949804902077,
      0.03610655665397644,
      -0.0015344865387305617,
      -0.1247839704155922,
      -0.04599672183394432,
      -0.06619177013635635,
      -0.04903816804289818,
      -0.01148936152458191,
      -0.020110633224248886,
      -0.022074967622756958,
      0.007489565294235945,
      0.0753197893500328,
      0.029629524797201157,
      -0.07826567441225052,
      0.015104459598660469,
      0.044749222695827484,
      -0.050563082098960876,
      0.06461500376462936,
      0.022893013432621956,
      0.04204978793859482,
      0.08765536546707153,
      0.0002257075102534145,
      0.00423308415338397,
      0.016541918739676476,
      -0.024104967713356018,
      0.058694783598184586,
      0.04746764898300171,
      0.03401316702365875,
      0.06089559942483902,
      0.04944254457950592,
      0.05406913161277771,
      0.0123332804068923,
      0.04909246787428856,
      -0.05163126066327095,
      0.03873978555202484,
      0.017916239798069,
      -0.03810251131653786,
      -0.05225463956594467,
      0.025953933596611023,
      0.03087727725505829,
      -0.03302714228630066,
      -0.03671334683895111,
      0.04324449598789215,
      0.041462648659944534,
      -0.010802858509123325,
      -0.040178216993808746,
      -0.059266842901706696,
      0.022469226270914078,
      -0.03829313442111015,
      -0.050551511347293854,
      -0.011534349992871284,
      0.05876073241233826,
      0.03824365884065628,
      -0.09356432408094406,
      0.01708494871854782,
      0.08744675666093826,
      0.04373273253440857,
      -0.0725262239575386,
      0.008053668774664402,
      0.007777934893965721,
      -0.0355769582092762,
      0.012501507997512817,
      0.043476346880197525,
      -0.002814729232341051,
      -0.006518425885587931,
      -0.03863244876265526,
      0.06209418550133705,
      -0.04175637289881706,
      -0.0015708828577771783,
      0.058366067707538605,
      -0.06388271600008011,
      -0.039744459092617035,
      -0.08132123947143555,
      0.007260601967573166,
      -0.030052781105041504,
      -0.07273489981889725,
      -0.013977915048599243,
      -0.055541351437568665,
      0.011782228946685791,
      0.11356337368488312,
      -0.028665099292993546,
      -0.03252452239394188,
      0.0892767608165741,
      -0.018849177286028862,
      -0.02512729912996292,
      -0.04811060428619385,
      0.05511939153075218,
      -0.06526046991348267,
      0.03597059100866318,
      0.030543526634573936,
      0.005761445965617895,
      0.002747067715972662,
      -0.054272811859846115,
      0.011703592725098133,
      0.036252010613679886,
      -0.08955271542072296,
      0.016793332993984222,
      -0.0289080198854208,
      -0.041642095893621445,
      -0.007187010254710913,
      -0.017811717465519905,
      -0.043641675263643265,
      -0.004945447668433189,
      -0.01798173226416111,
      0.054154329001903534,
      -0.004130844492465258,
      -0.06480507552623749,
      -0.09177743643522263,
      -0.020277272909879684,
      0.08037815243005753,
      0.027519330382347107,
      0.011588850058615208,
      -0.003613310167565942,
      -0.005326167214661837,
      0.0022414408158510923,
      -0.021609771996736526,
      0.009293457493185997,
      -0.056276172399520874,
      0.09635662287473679,
      0.04515724629163742,
      0.011552976444363594,
      -0.019375093281269073,
      -0.018273817375302315,
      -0.008825309574604034,
      0.029218152165412903,
      0.00011460052337497473,
      -0.00042872148333117366,
      0.08860886842012405,
      -0.021780474111437798,
      -0.03998387232422829,
      -0.023056510835886,
      0.009318423457443714,
      0.039140500128269196,
      -0.02891254983842373,
      -0.041067883372306824,
      0.04026447981595993,
      -0.02653292566537857,
      0.12063640356063843,
      -0.0025331941433250904,
      0.05237971991300583,
      0.030899468809366226,
      0.012780359946191311,
      0.1177070215344429,
      0.10699626803398132,
      -0.0631670281291008,
      0.02893827296793461,
      -0.013625397346913815,
      -0.01053220871835947,
      -0.004744814708828926,
      -0.0034163903910666704,
      -0.056026704609394073,
      -0.0700523778796196,
      0.03533362224698067,
      0.01356250885874033,
      -0.048392582684755325,
      0.01913359761238098,
      -0.03227204456925392,
      -0.04917754605412483,
      -0.05489436164498329,
      -0.08645769208669662,
      -0.029954610392451286,
      0.0855177715420723,
      -0.032985225319862366,
      -0.0877867192029953,
      -0.03454573452472687,
      -0.05919891595840454,
      -0.019825484603643417,
      0.06685308367013931,
      0.09311925619840622,
      0.024455277249217033,
      0.0006747092120349407,
      0.0035822042264044285,
      0.004537635482847691,
      0.010759844444692135,
      0.017935512587428093,
      -0.011416109278798103,
      -0.0036901843268424273,
      0.00399168161675334,
      0.03444930911064148,
      -0.035303059965372086,
      -0.012486995197832584,
      0.030248066410422325,
      -0.021327799186110497,
      -0.06835683435201645,
      0.03706156089901924,
      -0.038458891212940216,
      0.04951488971710205,
      -0.02092144452035427,
      0.08253872394561768,
      -0.052969418466091156,
      -0.04952848702669144,
      -0.0501544363796711,
      -0.04127996414899826,
      0.07269187271595001,
      -0.00408333633095026,
      -0.031373415142297745,
      0.013875477015972137,
      -0.05740324780344963,
      0.0019933446310460567,
      0.012765605933964252,
      0.08871918171644211,
      0.06367257982492447,
      0.03311384096741676,
      0.048586420714855194,
      0.05439258739352226,
      0.03571464493870735,
      0.018172120675444603,
      0.07259858399629593,
      -0.03464212268590927,
      -0.08950630575418472,
      -0.059872910380363464,
      0.022096510976552963,
      0.05018557235598564,
      -0.019157573580741882,
      -0.02333717793226242,
      -0.03723999485373497,
      -0.012553838081657887,
      -0.03411431238055229,
      0.04335648566484451,
      0.04456273466348648,
      -0.04415415599942207,
      0.010647987946867943,
      0.06385491043329239,
      -0.03521820530295372,
      -0.03219109773635864,
      -0.07250501960515976,
      0.036708708852529526,
      0.04829242080450058,
      0.049028895795345306,
      0.04224640130996704,
      -0.004380555357784033,
      0.03656869754195213,
      -0.07426492869853973,
      -0.05834424868226051,
      -0.0566011443734169,
      0.024937719106674194,
      0.004597170744091272,
      -0.04232104867696762,
      0.03563523665070534,
      0.01495856698602438,
      0.048180460929870605,
      0.05111485719680786,
      -0.01659287139773369,
      0.04405225068330765,
      0.05873002111911774,
      -0.05016052722930908,
      0.041414543986320496,
      0.0038833427242934704,
      -0.050787653774023056,
      -0.05802160128951073,
      0.008518842980265617,
      -0.019880447536706924,
      -0.0815480425953865,
      0.00730492826551199,
      0.03207891061902046,
      0.04493185505270958,
      0.020774228498339653,
      0.012389913201332092,
      -0.05822955444455147,
      0.05554543808102608,
      0.0680679976940155,
      -0.04426923021674156,
      -0.04748429358005524,
      -0.06641513854265213,
      -0.08817834407091141,
      -0.08486365526914597,
      0.027277708053588867,
      -0.05458952486515045,
      0.008780823089182377,
      -0.04462788999080658,
      0.0670757070183754,
      0.06412798166275024,
      -0.014500630088150501,
      0.03608691692352295,
      -0.036276258528232574,
      0.01322704367339611,
      -0.04054907336831093,
      0.04930858314037323,
      -0.01399375218898058,
      -0.050712354481220245,
      0.03403070569038391,
      0.00256993155926466,
      -0.020761732012033463,
      -0.02539980597794056,
      0.05964412912726402,
      0.04986219480633736,
      0.024200597777962685,
      -0.004545559640973806,
      -0.004516215063631535,
      -0.05999975651502609,
      0.016654616221785545,
      0.0004691212088800967,
      -0.023421918973326683,
      -0.027059903368353844,
      0.07468338310718536,
      0.06066204607486725,
      -0.01822802983224392,
      0.014567377977073193,
      0.03370577469468117,
      0.03969515860080719,
      0.023255830630660057,
      0.07560307532548904,
      -0.021115899085998535,
      0.02623170241713524,
      -0.05233703926205635,
      0.06371770799160004,
      0.06793539971113205,
      -0.04475115239620209,
      -0.02458713948726654,
      -0.027581658214330673,
      0.030560335144400597,
      -0.06812510639429092,
      -0.07598811388015747,
      0.005593025591224432,
      0.06634905934333801,
      -0.00536021264269948,
      0.047188520431518555,
      -0.019219480454921722,
      -0.056789372116327286,
      -0.016337089240550995,
      0.041973572224378586,
      0.06080866605043411,
      0.015675414353609085,
      0.058131102472543716,
      -0.07149188220500946,
      -0.04754234477877617,
      -0.03533042222261429,
      0.04786328226327896,
      0.027850870043039322,
      0.026050366461277008,
      0.004458576440811157,
      -0.02828466333448887,
      0.004154355730861425,
      -0.010458791628479958,
      -0.09289032220840454,
      0.043891362845897675,
      0.10880203545093536,
      0.02042102999985218,
      -0.0585029162466526,
      0.025859078392386436,
      -0.012246483936905861,
      0.06243396922945976,
      0.014972669072449207,
      0.02937835454940796,
      -0.05456772819161415,
      -0.020068828016519547,
      0.022394441068172455,
      0.040384430438280106,
      0.035632383078336716,
      -0.09071440994739532,
      0.033893514424562454,
      -0.020428288727998734,
      0.014802474528551102,
      0.014197126030921936,
      -0.05809818580746651,
      0.06734230369329453,
      0.07558513432741165,
      0.028225259855389595,
      -0.04385135695338249,
      -0.08483132719993591,
      -0.011008000932633877,
      -0.11016782373189926,
      0.07609797269105911,
      -0.0965159460902214,
      -0.03016091324388981,
      -0.03596295043826103,
      -0.03148484230041504,
      0.0912986472249031,
      0.02541990764439106,
      -0.00923596415668726,
      -0.027222219854593277,
      -0.013054602779448032,
      -0.016540544107556343,
      0.004661851562559605,
      -0.0002908409805968404,
      0.04355061799287796,
      -0.03149309754371643,
      -0.016428720206022263,
      -0.015210578218102455,
      0.038503360003232956,
      -0.04370753467082977,
      -0.02759932540357113,
      -0.0031972480937838554,
      0.01819893904030323,
      -0.020996037870645523,
      -0.06548263877630234,
      -0.02373781055212021,
      -0.035718925297260284,
      0.007612668909132481,
      -0.014700349420309067,
      0.04988383501768112,
      0.016320349648594856,
      -0.010077053681015968,
      -0.04167146980762482,
      0.003424143884330988,
      0.06096602976322174,
      0.018449226394295692,
      0.008018278516829014,
      0.044887810945510864,
      0.042915813624858856,
      -0.060376718640327454,
      0.13904863595962524,
      -0.022783983498811722,
      0.032694011926651,
      -0.052296336740255356,
      -0.010264111682772636,
      0.10191763192415237,
      0.08387917280197144,
      0.012593979947268963,
      -0.029541078954935074,
      -0.08221711218357086,
      -0.029355281963944435,
      0.018603647127747536,
      -0.02681678719818592,
      -0.030213909223675728,
      0.05299421772360802,
      -0.014709408394992352,
      -0.03806886076927185,
      -0.06164593622088432,
      -0.05811107158660889,
      0.018194530159235,
      -0.013937453739345074,
      0.017401892691850662,
      0.014023610390722752,
      -0.012564625591039658,
      -0.0025219623930752277,
      -0.02449943497776985,
      -0.004839635919779539,
      -0.027974171563982964,
      0.03118574433028698,
      0.01358182355761528,
      -0.012975477613508701,
      -0.1069505512714386,
      0.03972943499684334,
      0.004085123538970947,
      -0.02987000159919262,
      -0.08764975517988205,
      0.0011752401478588581,
      -0.028246507048606873,
      -0.01397064607590437,
      0.022144440561532974,
      0.019033432006835938,
      0.03887822479009628,
      0.040237706154584885,
      0.018853897228837013,
      -0.009520621038973331,
      0.05210022255778313,
      0.0762639045715332,
      0.006200388073921204,
      -0.0029750908724963665,
      0.042089056223630905,
      0.05802662670612335,
      0.02287830226123333,
      -0.014244959689676762,
      0.003910527564585209,
      -0.026625219732522964,
      0.08629647642374039,
      0.035488732159137726,
      0.07325167953968048,
      0.0542663149535656,
      0.04693513363599777,
      0.0776820108294487,
      0.0003231773152947426,
      -0.05298636108636856,
      0.05941528454422951,
      0.0012372153578326106,
      0.05674828961491585,
      -0.02081957645714283,
      0.026406364515423775,
      0.014712370000779629,
      -0.006921229884028435,
      -0.0462646409869194,
      -0.001772227929905057,
      0.015061907470226288,
      -0.07539944350719452,
      0.00549507699906826,
      -0.002055024029687047,
      0.0199962817132473,
      0.0524447038769722,
      0.04076575115323067,
      -0.048739366233348846,
      -0.018530191853642464,
      0.025707904249429703,
      -0.025881372392177582,
      -0.0464540533721447,
      -0.03345891460776329,
      -0.07435781508684158,
      0.004175024107098579,
      0.03685596212744713,
      -0.05642315000295639,
      -0.06157577037811279,
      -0.0734185054898262,
      -0.04838697984814644,
      -0.04617984592914581
    ],
    [
      0.024333732202649117,
      0.038814373314380646,
      0.05387290194630623,
      -0.014479385688900948,
      0.004216445609927177,
      0.02630026638507843,
      0.0364682711660862,
      0.027760731056332588,
      0.004638310056179762,
      0.049027830362319946,
      0.01998380944132805,
      -0.03483841195702553,
      -0.02308071032166481,
      -0.03560657054185867,
      -0.04432293027639389,
      0.0018009226769208908,
      -0.02369837835431099,
      -0.0021243824157863855,
      0.0626317635178566,
      -0.003041584510356188,
      0.05125883221626282,
      0.020405089482665062,
      -0.0433499701321125,
      -0.04142724350094795,
      0.0018462648149579763,
      -0.03634180501103401,
      -0.005474352743476629,
      0.01328681968152523,
      0.04395395144820213,
      0.05806415528059006,
      -0.026472516357898712,
      -0.005521844141185284,
      -0.05462328717112541,
      -0.06423945724964142,
      -0.035683780908584595,
      -0.027203714475035667,
      -0.06601990759372711,
      0.02956491708755493,
      0.08659785240888596,
      0.010916569270193577,
      0.05463128909468651,
      0.05544514209032059,
      0.01239513885229826,
      -0.00391986733302474,
      -0.02434692531824112,
      -0.008037637919187546,
      0.021676741540431976,
      0.010344177484512329,
      -0.005783324129879475,
      -0.008034230209887028,
      -0.04526758939027786,
      -0.03636828064918518,
      -0.01033835019916296,
      -0.02841535210609436,
      0.07138628512620926,
      0.008426835760474205,
      -0.028443850576877594,
      -0.06635068356990814,
      0.03860119357705116,
      -0.07820304483175278,
      -0.02695814147591591,
      -0.0013843225315213203,
      -0.0414467379450798,
      -0.00969691202044487,
      0.0035650378558784723,
      -0.029129736125469208,
      -0.039885591715574265,
      0.003513915231451392,
      0.018445733934640884,
      -0.027872592210769653,
      0.015800876542925835,
      0.033778686076402664,
      -0.07484608143568039,
      -0.04084101691842079,
      0.05278344079852104,
      -0.008853654377162457,
      0.009439201094210148,
      0.019982118159532547,
      -0.09228487312793732,
      -0.03406504541635513,
      0.005039978306740522,
      0.03767375648021698,
      -0.06710232049226761,
      -0.05388008803129196,
      -0.030632534995675087,
      0.03440525010228157,
      -0.019691379740834236,
      -0.0010940571082755923,
      -0.03792472183704376,
      -0.0031984110828489065,
      -0.026016971096396446,
      -0.016214119270443916,
      -0.03899728134274483,
      -0.015176858752965927,
      -0.137391597032547,
      0.030272429808974266,
      -0.0014060967369005084,
      0.07779961824417114,
      -0.018204953521490097,
      0.09471555799245834,
      0.05062887817621231,
      0.010675214231014252,
      0.04895210638642311,
      0.11561772972345352,
      -0.024876700714230537,
      -0.027566617354750633,
      0.03120114468038082,
      -0.026696637272834778,
      -0.051742926239967346,
      0.035479314625263214,
      0.02140728384256363,
      -0.008185889571905136,
      0.05635374039411545,
      -0.02329191565513611,
      0.035089071840047836,
      0.005825520027428865,
      0.06355572491884232,
      0.007721215486526489,
      0.013460058718919754,
      -0.035711612552404404,
      -0.02823098935186863,
      -0.01780722290277481,
      -0.055966973304748535,
      0.060035884380340576,
      -0.07940202206373215,
      0.06879859417676926,
      0.08454020321369171,
      -0.0033249228727072477,
      -0.039872411638498306,
      -0.039347365498542786,
      -0.05815155804157257,
      0.0599733330309391,
      -0.010206131264567375,
      -0.026935094967484474,
      -0.016293680295348167,
      0.0478365458548069,
      -0.031697824597358704,
      0.04282256215810776,
      -0.019609415903687477,
      -0.032587215304374695,
      0.03268750011920929,
      -0.02713463082909584,
      -0.023483799770474434,
      -0.0042907497845590115,
      0.016533056274056435,
      -0.09835048019886017,
      0.06660913676023483,
      -0.0234732236713171,
      0.06339081376791,
      -0.07316359132528305,
      0.00015929147775750607,
      0.09158404916524887,
      -0.04536759480834007,
      0.019818905740976334,
      0.007889268919825554,
      0.04377260059118271,
      -0.020495206117630005,
      -0.1044006422162056,
      0.013892369344830513,
      -0.007233609911054373,
      -0.08707953244447708,
      0.03471389412879944,
      -0.04621238261461258,
      -0.05780896544456482,
      0.03213909640908241,
      -0.008285430260002613,
      0.03502495586872101,
      0.022437920793890953,
      -0.01260490994900465,
      0.007897866889834404,
      0.05975676700472832,
      -0.060376085340976715,
      0.023441599681973457,
      0.022212201729416847,
      -0.003748101182281971,
      -0.05208384245634079,
      0.008096994832158089,
      -0.04766672104597092,
      -0.0268116258084774,
      -0.008731242269277573,
      -0.019260115921497345,
      0.003917201887816191,
      -0.004480308853089809,
      0.020056242123246193,
      -0.008102447725832462,
      0.011221501976251602,
      0.07741695642471313,
      0.02501409314572811,
      -0.05497634783387184,
      -0.008674983866512775,
      0.027436217293143272,
      0.0280963983386755,
      -0.006550890859216452,
      0.003219170030206442,
      0.03037712536752224,
      0.03964366391301155,
      0.06643429398536682,
      0.04618159309029579,
      0.0009606713429093361,
      -0.04738570749759674,
      0.02232157066464424,
      -0.030201677232980728,
      0.0006478096474893391,
      -0.01167256012558937,
      0.05459284782409668,
      -0.049670640379190445,
      0.053169868886470795,
      0.020174263045191765,
      0.02541542984545231,
      0.003231769660487771,
      0.08109388500452042,
      -0.028384270146489143,
      0.09214761853218079,
      0.06157684326171875,
      0.04550401493906975,
      -0.009801337495446205,
      -0.0488334558904171,
      0.014491462148725986,
      -0.11120252311229706,
      0.007713585626333952,
      0.0417291522026062,
      -0.004460636060684919,
      -0.037710972130298615,
      -0.044675711542367935,
      -0.03915209323167801,
      0.005463339388370514,
      0.009846320375800133,
      0.0684996023774147,
      0.031328096985816956,
      -0.010648605413734913,
      -0.03003835491836071,
      0.03022109903395176,
      -0.04525182023644447,
      -0.09758162498474121,
      -0.07936682552099228,
      0.02634989656507969,
      0.02440468966960907,
      0.001044697593897581,
      0.07984741032123566,
      0.04014335945248604,
      -0.03875494748353958,
      -0.026813287287950516,
      -0.005800055805593729,
      0.005425603128969669,
      0.0764506459236145,
      0.01863950490951538,
      0.05389256030321121,
      -0.046285372227430344,
      0.04008627310395241,
      0.05677869915962219,
      -0.021113993600010872,
      0.020376700907945633,
      0.0320475809276104,
      -0.024162866175174713,
      -0.06504251807928085,
      -0.08801193535327911,
      -0.02672266773879528,
      -0.0543006993830204,
      0.015771064907312393,
      0.008733400143682957,
      0.061275046318769455,
      0.02898571640253067,
      -0.011347387917339802,
      -0.03351005166769028,
      -0.014877726323902607,
      0.046206917613744736,
      -0.07712021470069885,
      -0.02136080153286457,
      -0.024096744135022163,
      0.03017650917172432,
      -0.040086958557367325,
      -0.014454065822064877,
      0.028589719906449318,
      0.005867503117769957,
      0.009464546106755733,
      0.05316421389579773,
      -0.011102138087153435,
      0.03170175105333328,
      0.016761768609285355,
      -0.058264538645744324,
      0.0032670418731868267,
      0.022077474743127823,
      -0.04388723522424698,
      0.05642671138048172,
      -0.00786955188959837,
      -0.0061021773144602776,
      -0.020135359838604927,
      -0.02568988688290119,
      0.009257731959223747,
      -0.048968199640512466,
      0.05906413868069649,
      -0.011488438583910465,
      0.007109824568033218,
      0.053876884281635284,
      0.03170027956366539,
      -0.016006527468562126,
      0.08043251931667328,
      -0.013060691766440868,
      0.10222844034433365,
      0.08895101398229599,
      0.0009760071407072246,
      -0.014376059174537659,
      -0.0725264623761177,
      -0.04078396409749985,
      0.038884200155735016,
      -0.04724110662937164,
      0.013631535694003105,
      0.021262213587760925,
      0.10378843545913696,
      0.004691361449658871,
      -0.010710309259593487,
      0.05473930016160011,
      -0.017339376732707024,
      0.046107567846775055,
      0.030644435435533524,
      -0.030093684792518616,
      -0.02633763663470745,
      0.04209752380847931,
      -0.0498218759894371,
      -0.06612011790275574,
      -0.027569325640797615,
      -0.037516042590141296,
      -0.0406784862279892,
      0.05607999116182327,
      -0.007970951497554779,
      -0.0006257658242247999,
      0.00017343115177936852,
      -0.039250850677490234,
      -0.04176509752869606,
      0.06260170787572861,
      -0.03688884526491165,
      0.014901307411491871,
      -0.07167776674032211,
      -0.0072475033812224865,
      -0.016558533534407616,
      -0.005155924707651138,
      0.0179316233843565,
      -0.04361352324485779,
      -0.008766261860728264,
      -0.0014233412221074104,
      0.020889101549983025,
      -0.0009563394705764949,
      -0.026739103719592094,
      -0.06292828917503357,
      -0.01359053049236536,
      0.07840253412723541,
      0.03275439515709877,
      -0.021994788199663162,
      0.04623323678970337,
      -0.002558309119194746,
      -0.0647650957107544,
      -0.10355561971664429,
      0.04872192069888115,
      -0.05922560393810272,
      0.03370123729109764,
      -0.04520353674888611,
      0.023627102375030518,
      0.03326081857085228,
      0.0023535520303994417,
      0.020908866077661514,
      -0.07397670298814774,
      -0.05221215635538101,
      0.004900066647678614,
      -0.013003699481487274,
      0.03211669251322746,
      0.0029564762953668833,
      -0.01647310145199299,
      -0.07934770733118057,
      -0.017820166423916817,
      -0.0007229684269987047,
      0.04965441673994064,
      0.009252045303583145,
      -0.05969924479722977,
      0.02683136612176895,
      0.02014865353703499,
      -0.0028135443571954966,
      -0.05307338014245033,
      -0.10655544698238373,
      0.012966847978532314,
      -0.01958457939326763,
      0.055333200842142105,
      -0.06367725133895874,
      0.002434695838019252,
      -0.07912340015172958,
      -0.059864338487386703,
      -0.0544402115046978,
      -0.017286887392401695,
      0.0029079720843583345,
      0.013550952076911926,
      -0.05104542896151543,
      0.018163910135626793,
      0.025790465995669365,
      -0.015890982002019882,
      -0.033214036375284195,
      0.061513129621744156,
      -0.03473383188247681,
      0.008822725154459476,
      0.01494392566382885,
      0.01789853349328041,
      0.06414399296045303,
      -0.021098028868436813,
      -0.013947639614343643,
      0.052469026297330856,
      -0.03564540669322014,
      0.002294850768521428,
      -0.025715505704283714,
      -0.0023014144971966743,
      -0.0881442278623581,
      0.004606662318110466,
      0.001290612039156258,
      0.018078206107020378,
      -0.02222009189426899,
      -0.029894722625613213,
      -0.11289974302053452,
      -0.036792658269405365,
      0.012501087971031666,
      0.026739314198493958,
      -0.06452120095491409,
      -0.07257632911205292,
      -0.03128299117088318,
      0.0034492879640311003,
      0.02270553633570671,
      0.031696658581495285,
      0.03605223447084427,
      0.008374991826713085,
      -0.056719835847616196,
      -0.03026091866195202,
      -0.041145965456962585,
      -0.009713473729789257,
      0.10427029430866241,
      -0.01208630669862032,
      0.06809580326080322,
      -0.03480670973658562,
      -0.07845278829336166,
      -0.0017623311141505837,
      0.026536259800195694,
      -0.09011416882276535,
      -0.007525026332587004,
      0.007674037013202906,
      0.05702737718820572,
      0.047688137739896774,
      -0.04024162143468857,
      -0.03728872537612915,
      -0.05477583408355713,
      0.0323365218937397,
      0.01578589528799057,
      0.09474059194326401,
      -0.042673930525779724,
      0.037511538714170456,
      -0.04640498757362366,
      -0.004338244441896677,
      -0.004050265531986952,
      0.02089625410735607,
      -0.02109786495566368,
      0.03299805894494057,
      0.03861910477280617,
      0.007467441726475954,
      0.1343889683485031,
      -0.015195964835584164,
      0.0111258989199996,
      -0.014482722617685795,
      -0.0040422845631837845,
      -0.018086975440382957,
      0.02312525175511837,
      0.01661987602710724,
      -0.020392941311001778,
      -0.03456032648682594,
      -0.021770713850855827,
      -0.044337909668684006,
      -0.027267614379525185,
      0.02150193229317665,
      0.005429286044090986,
      -0.024602500721812248,
      0.005374927073717117,
      0.032190240919589996,
      -0.02950221486389637,
      -0.054023049771785736,
      -0.008446578867733479,
      -0.010839980095624924,
      0.0021839290857315063,
      0.0008736946037970483,
      0.00022865795472171158,
      0.03920479118824005,
      0.04598966985940933,
      -0.04448486119508743,
      0.1075422614812851,
      -0.007183718495070934,
      0.07794605940580368,
      0.07844259589910507,
      -0.09526832401752472,
      -0.018238818272948265,
      -0.03201613575220108,
      0.0036587787326425314,
      0.020222168415784836,
      -0.014848356135189533,
      -0.03441901132464409,
      -0.07055765390396118,
      0.06677472591400146,
      0.050766125321388245,
      0.01679554395377636,
      -0.05311686918139458,
      0.03472807630896568,
      0.12685805559158325
    ],
    [
      -0.06903748959302902,
      -0.04839986190199852,
      -0.03181823715567589,
      -0.08475429564714432,
      0.05739751085639,
      0.005532615818083286,
      -0.025538280606269836,
      -0.05184599757194519,
      -0.006826377473771572,
      -0.0009157498134300113,
      -0.046323467046022415,
      -0.029228907078504562,
      -0.014279958792030811,
      -0.015342908911406994,
      -0.04083385318517685,
      0.030844788998365402,
      -0.04106173664331436,
      -0.008202754892408848,
      0.01999177224934101,
      0.040330685675144196,
      0.061276309192180634,
      -0.007335949689149857,
      0.0423087403178215,
      0.03342704474925995,
      0.01435021124780178,
      0.021689729765057564,
      -0.03812224045395851,
      -0.0036598488222807646,
      -0.057733140885829926,
      0.021399544551968575,
      -0.015927355736494064,
      0.016991307958960533,
      -0.03943333029747009,
      0.013895338401198387,
      -0.008563421666622162,
      -0.05898520350456238,
      -0.05232615023851395,
      0.04695209860801697,
      0.04739774391055107,
      0.01917158253490925,
      0.04518892616033554,
      -0.08702242374420166,
      -0.09245212376117706,
      0.0032555460929870605,
      -0.020927928388118744,
      -0.0058300187811255455,
      0.03812328353524208,
      -0.007497002836316824,
      -0.03823656216263771,
      0.02652115747332573,
      0.027166591957211494,
      0.0332246758043766,
      -0.12431245297193527,
      0.08127189427614212,
      0.043549876660108566,
      -0.014809416607022285,
      -0.02846069447696209,
      -0.03935563191771507,
      -0.053566839545965195,
      -0.029279537498950958,
      -0.030587680637836456,
      -0.025593489408493042,
      0.011412369087338448,
      0.043209273368120193,
      0.0479743629693985,
      0.046406228095293045,
      -0.053224071860313416,
      0.001025351812131703,
      0.020353026688098907,
      0.09672917425632477,
      -0.06447014957666397,
      -0.03476804867386818,
      0.04711037129163742,
      -0.01802297867834568,
      0.007562059909105301,
      -0.052158523350954056,
      0.0018234323943033814,
      0.09050936996936798,
      -0.03402038291096687,
      -0.0008254490094259381,
      -0.04527319595217705,
      0.01472976990044117,
      -0.05322544649243355,
      0.001404551207087934,
      -0.011324563063681126,
      0.03263857588171959,
      -0.07837240397930145,
      -0.017394207417964935,
      -0.037238959223032,
      0.03612660616636276,
      0.014603451825678349,
      0.043524619191884995,
      -0.03734346479177475,
      -0.03546885401010513,
      -0.039832938462495804,
      0.08150111883878708,
      0.010607479140162468,
      0.06772356480360031,
      -0.014535840600728989,
      -0.031121043488383293,
      0.05150133743882179,
      -0.03871685266494751,
      0.03730476275086403,
      -0.023639090359210968,
      0.0023512609768658876,
      0.01393317524343729,
      -0.003027449594810605,
      0.02419639192521572,
      0.043619755655527115,
      -0.06833882629871368,
      -0.0049775573424994946,
      0.02197185344994068,
      0.1447981297969818,
      -0.011033803224563599,
      0.026721464470028877,
      0.02675512246787548,
      -0.008481482975184917,
      -0.07001637667417526,
      -0.009849123656749725,
      0.00545834144577384,
      0.0019567536655813456,
      -0.0857444480061531,
      0.03995687887072563,
      -0.01964918151497841,
      -0.03677503019571304,
      0.04399440065026283,
      0.05556943267583847,
      0.025224842131137848,
      0.08784087002277374,
      -0.03010079450905323,
      -0.002963266335427761,
      0.1118084266781807,
      -0.0009492913959547877,
      -0.024294085800647736,
      0.018366055563092232,
      0.01989448443055153,
      0.07165548950433731,
      0.005610713269561529,
      0.00220550992526114,
      0.023481810465455055,
      -0.04757707566022873,
      -0.004589574411511421,
      0.04097732901573181,
      -0.008840799331665039,
      -0.03513353317975998,
      -0.008740254677832127,
      -0.03562523424625397,
      -0.015609166584908962,
      0.01563704013824463,
      0.08393993973731995,
      0.07518356293439865,
      -0.10521350055932999,
      -0.0529622919857502,
      -0.02975662797689438,
      0.007892231456935406,
      -0.01011686585843563,
      -0.09948824346065521,
      0.06338053196668625,
      0.03492102771997452,
      0.04483534395694733,
      -0.05332797020673752,
      -0.0686880350112915,
      0.05820294842123985,
      -0.031151900067925453,
      0.04347146302461624,
      -0.06788719445466995,
      -0.030768755823373795,
      0.05461113899946213,
      -0.022878721356391907,
      0.01301325298845768,
      -0.028244826942682266,
      -0.12350866198539734,
      -0.030316215008497238,
      0.05575723573565483,
      0.01412961259484291,
      0.00950064416974783,
      0.015729378908872604,
      0.14800076186656952,
      0.03727200999855995,
      0.062307752668857574,
      0.043092917650938034,
      -0.006184275262057781,
      0.021860094740986824,
      -0.03255131468176842,
      0.04710571840405464,
      -0.07380429655313492,
      0.12099994719028473,
      -0.00025111768627539277,
      0.057432543486356735,
      0.01824721321463585,
      -0.006501519586890936,
      0.05356834828853607,
      0.03753427788615227,
      -0.0938102975487709,
      -0.010065983049571514,
      0.042284395545721054,
      -0.012042175978422165,
      -0.019045542925596237,
      -0.09462030231952667,
      0.019086547195911407,
      -0.023924637585878372,
      -0.05266328528523445,
      -0.00794778298586607,
      0.05081469193100929,
      0.013113665394484997,
      0.017017440870404243,
      0.03126717358827591,
      -0.009433094412088394,
      0.012594453059136868,
      0.04117722064256668,
      0.07446879893541336,
      0.08993282914161682,
      0.10546419024467468,
      0.08526164293289185,
      -0.08352921158075333,
      0.04024747014045715,
      -0.024319907650351524,
      -0.053429149091243744,
      0.0014557046815752983,
      -0.01860283873975277,
      -0.07582420855760574,
      0.0031849839724600315,
      0.03785164654254913,
      0.011533663608133793,
      0.10063838213682175,
      0.008719658479094505,
      0.019943147897720337,
      0.03941015899181366,
      0.046804264187812805,
      0.04951852187514305,
      0.007002901751548052,
      0.0828905999660492,
      -0.03990180045366287,
      -0.07829399406909943,
      0.02829221822321415,
      0.004272810183465481,
      0.003939616959542036,
      -0.02042205072939396,
      0.021682720631361008,
      0.02085123397409916,
      -0.031507331877946854,
      0.03474465012550354,
      -0.01899632439017296,
      0.029141193255782127,
      -0.00888985674828291,
      0.045175205916166306,
      -0.06917869299650192,
      -0.0002765001554507762,
      0.006772602908313274,
      0.023038174957036972,
      0.08043195307254791,
      0.007028860505670309,
      0.0049855830147862434,
      -0.01563841849565506,
      -0.03372230380773544,
      0.04454951360821724,
      -0.024662671610713005,
      -0.043495144695043564,
      -0.07087471336126328,
      -0.025133972987532616,
      -0.03018300235271454,
      -0.0323210135102272,
      0.070159412920475,
      -0.005678952671587467,
      -0.05819922685623169,
      -0.04479217901825905,
      0.08119779825210571,
      0.020152440294623375,
      -0.06713048368692398,
      0.019767576828598976,
      0.034427281469106674,
      0.10148443281650543,
      -0.01456814631819725,
      -0.0696486085653305,
      0.00014493403432425112,
      0.041823290288448334,
      -0.04062609747052193,
      -0.02056247740983963,
      -0.07363151758909225,
      0.038106705993413925,
      -0.05899685248732567,
      0.019217384979128838,
      0.017374135553836823,
      -0.049288179725408554,
      0.0256575345993042,
      0.01432538591325283,
      -0.06549476087093353,
      0.0021217360626906157,
      -0.005744549911469221,
      0.047159984707832336,
      0.0588182769715786,
      -0.05183498188853264,
      -0.0858379676938057,
      -0.02506845071911812,
      0.01168910600244999,
      -0.02768911048769951,
      -0.04229224473237991,
      -0.03248514235019684,
      0.0847182497382164,
      -0.02805427648127079,
      0.04476206749677658,
      -0.03661823645234108,
      0.006934361066669226,
      0.03898640349507332,
      0.01731552928686142,
      0.0027168344240635633,
      0.02289624512195587,
      -0.0023117754608392715,
      0.03249046951532364,
      0.014117619954049587,
      0.02379203774034977,
      0.08290385454893112,
      -0.05049986392259598,
      -0.07655572146177292,
      0.04052848741412163,
      -0.011776101775467396,
      0.0910719707608223,
      0.07407116889953613,
      -0.018675487488508224,
      0.01637331023812294,
      0.0872303694486618,
      -0.009345031343400478,
      -0.06944198906421661,
      0.013716887682676315,
      -0.06529370695352554,
      -0.029116282239556313,
      -0.030788585543632507,
      -0.03953792527318001,
      0.021584993228316307,
      -0.03792634978890419,
      -0.10575664788484573,
      0.0028209583833813667,
      0.024578528478741646,
      -0.05355563759803772,
      0.09888549894094467,
      -0.030722463503479958,
      -0.008164570666849613,
      0.060806047171354294,
      -0.049869246780872345,
      -0.0030073863454163074,
      -0.011805710382759571,
      -0.07096270471811295,
      -0.0037304486613720655,
      0.04418337717652321,
      -0.020932888612151146,
      -0.018514325842261314,
      -0.004327898379415274,
      0.006116495467722416,
      -0.05221061408519745,
      -0.03049774281680584,
      -0.09354054927825928,
      0.06740021705627441,
      -0.04382023587822914,
      0.02756044827401638,
      0.007715562824159861,
      0.001646574237383902,
      0.015804527327418327,
      -0.022671839222311974,
      -0.020739920437335968,
      -0.05038091540336609,
      0.03400211036205292,
      -0.00962967798113823,
      0.002906974870711565,
      0.022716311737895012,
      -0.023002678528428078,
      0.08253710716962814,
      -0.048492077738046646,
      0.002535171341150999,
      0.08153997361660004,
      -0.043607160449028015,
      -0.021036643534898758,
      -0.02385101653635502,
      0.022123713046312332,
      0.07318630814552307,
      -0.006351171061396599,
      -0.04062722995877266,
      0.021522196009755135,
      -0.07226495444774628,
      0.06459136307239532,
      0.09305807948112488,
      -0.012391706928610802,
      -0.026269515976309776,
      0.04141581803560257,
      0.024952515959739685,
      -0.02865263819694519,
      0.07345502078533173,
      -0.00946695078164339,
      0.03503304719924927,
      0.01482382882386446,
      -0.013869267888367176,
      -0.0032650430221110582,
      0.0717376247048378,
      -0.025406770408153534,
      0.02422010339796543,
      -0.10804060846567154,
      0.009561929851770401,
      0.022675352171063423,
      0.019805602729320526,
      -0.0744195282459259,
      -0.06937124580144882,
      0.0057713040150702,
      0.01523155253380537,
      -0.06934753060340881,
      0.014509309083223343,
      0.007981698028743267,
      -0.01686233840882778,
      0.00016830122331157327,
      0.008030436001718044,
      -0.05873997136950493,
      -0.003051906591281295,
      -0.03518179431557655,
      -0.020136374980211258,
      0.07822775095701218,
      0.01752277836203575,
      -0.019224978983402252,
      0.014974404126405716,
      0.06729838252067566,
      0.020213671028614044,
      0.012027782388031483,
      -0.059052105993032455,
      -0.043941546231508255,
      -0.04155318811535835,
      0.06595298647880554,
      -0.032809384167194366,
      -0.03260395675897598,
      0.021977046504616737,
      -0.052382998168468475,
      -0.11306212842464447,
      0.009304772131145,
      0.03488907590508461,
      -0.017865508794784546,
      0.013052044436335564,
      0.03500829264521599,
      0.007660191971808672,
      -0.10360360890626907,
      -0.04049447178840637,
      -0.04483986645936966,
      -0.01409738976508379,
      -0.05069301277399063,
      0.07520908117294312,
      0.07378653436899185,
      -0.02959986962378025,
      0.018863437697291374,
      0.036905091255903244,
      -0.019810814410448074,
      0.068117156624794,
      -0.003840547287836671,
      0.00643024779856205,
      0.07330236583948135,
      0.0054571302607655525,
      -0.057523827999830246,
      0.030308781191706657,
      0.054292961955070496,
      0.03415445238351822,
      -0.014444747008383274,
      0.0375763364136219,
      -0.027344955131411552,
      0.005762603133916855,
      0.02993403747677803,
      0.00013752150698564947,
      -0.099210225045681,
      0.042301297187805176,
      -0.025579333305358887,
      0.08626683056354523,
      -0.0970143973827362,
      -0.09124516695737839,
      0.019480593502521515,
      -0.019927313551306725,
      0.07699806988239288,
      -0.03212892264127731,
      0.03260205686092377,
      -0.0320773646235466,
      -0.0336514413356781,
      -0.004776211455464363,
      -0.07123112678527832,
      -0.05203752592206001,
      -0.059638861566782,
      0.10844151675701141,
      -0.05849548056721687,
      -0.0603080578148365,
      -0.025324031710624695,
      0.046671029180288315,
      -0.034483253955841064,
      0.06476707756519318,
      0.005829670000821352,
      -0.03852011635899544,
      0.03160492703318596,
      0.001678207190707326,
      0.03272011876106262,
      -0.05174301192164421,
      0.06185879558324814,
      0.03965853527188301,
      -0.03032791242003441,
      0.010729411616921425,
      0.011993702501058578,
      0.016449477523565292,
      0.04876066744327545,
      0.04251691699028015,
      0.0062360577285289764,
      0.04325719550251961,
      0.03343065083026886,
      9.446759941056371e-05,
      0.014373116195201874
    ],
    [
      0.01218143105506897,
      0.0987766683101654,
      0.05951941758394241,
      -0.09481445699930191,
      0.012822533026337624,
      0.006318962667137384,
      0.03151421248912811,
      -0.05949104204773903,
      -0.05134483426809311,
      0.045676667243242264,
      0.01280796155333519,
      -0.018994228914380074,
      -0.04329048842191696,
      0.006802523974329233,
      0.05768441781401634,
      -0.048979099839925766,
      0.0306101031601429,
      0.013791440986096859,
      -0.006189464125782251,
      0.03998207673430443,
      -0.03796844184398651,
      -0.024946145713329315,
      0.02156917005777359,
      0.021367019042372704,
      0.05350484699010849,
      -0.011644542217254639,
      -0.0323457233607769,
      0.03417113050818443,
      0.010563654825091362,
      0.016384975984692574,
      -0.04513479769229889,
      -0.0010037751635536551,
      -0.060863297432661057,
      0.016629531979560852,
      -0.050946496427059174,
      -0.10173410922288895,
      -0.0194556787610054,
      0.01085829921066761,
      -0.03133435547351837,
      -0.029891956597566605,
      0.01689891703426838,
      0.02317097596824169,
      0.04243671894073486,
      -0.028266018256545067,
      0.038500770926475525,
      -0.058150120079517365,
      -0.03863146901130676,
      -0.0732618123292923,
      -0.04007643833756447,
      0.005875743925571442,
      0.05104069039225578,
      0.02669895999133587,
      -0.05839687958359718,
      -0.02570606768131256,
      0.024239152669906616,
      0.013546397909522057,
      -0.06844174861907959,
      0.026614826172590256,
      -0.012678450904786587,
      -0.05092831701040268,
      -0.02699890173971653,
      -0.12106548994779587,
      -0.06207895278930664,
      -0.05091401934623718,
      -0.07131515443325043,
      -0.07213835418224335,
      -0.0034316133242100477,
      -0.016656551510095596,
      -0.01945662498474121,
      -0.06988590955734253,
      0.002638834062963724,
      0.023031441494822502,
      0.04795065149664879,
      -0.007466658018529415,
      0.04185950383543968,
      0.00486741354689002,
      0.14332374930381775,
      0.03697012737393379,
      0.054299984127283096,
      0.017245564609766006,
      -0.005042816512286663,
      -0.04259404167532921,
      0.012638669461011887,
      -0.009140592999756336,
      -0.04284494370222092,
      0.03664890304207802,
      -0.03328048810362816,
      -0.05680020526051521,
      0.08306975662708282,
      -0.054804474115371704,
      0.004772322718054056,
      0.09089622646570206,
      -0.03670405223965645,
      -0.03848612308502197,
      0.043254826217889786,
      -0.012018916197121143,
      -0.02377854846417904,
      -0.07576863467693329,
      0.024514498189091682,
      -0.01881343498826027,
      -0.01197462435811758,
      0.005970486905425787,
      -0.1022748202085495,
      0.08240069448947906,
      0.0017193416133522987,
      -0.032801151275634766,
      0.009579943493008614,
      0.0024064055178314447,
      0.018300270661711693,
      -0.05468859151005745,
      -0.016593672335147858,
      0.061361730098724365,
      -0.029500199481844902,
      0.001166343572549522,
      0.02183917909860611,
      0.04277809336781502,
      -0.049065474420785904,
      -0.007429738063365221,
      0.030951088294386864,
      -0.019354593008756638,
      0.0017306216759607196,
      0.006990482565015554,
      -0.06501990556716919,
      -0.02439727820456028,
      -0.045347873121500015,
      -0.02004399523139,
      -0.1200660690665245,
      0.029561618342995644,
      0.07864034920930862,
      0.12626740336418152,
      -0.014669024385511875,
      0.0039500645361840725,
      0.031209038570523262,
      0.00676201656460762,
      0.04122854396700859,
      0.017472686246037483,
      0.0033548378851264715,
      0.0912356823682785,
      0.010060573928058147,
      0.0397336445748806,
      0.12285374850034714,
      0.0394149012863636,
      -0.04716586321592331,
      -0.009379055351018906,
      -0.02263692580163479,
      -0.035479266196489334,
      0.02262728102505207,
      -0.03311137482523918,
      -0.03558247536420822,
      0.09468283504247665,
      -0.016321932896971703,
      -0.04387214034795761,
      -0.007581119891256094,
      0.01819211058318615,
      -0.011551396921277046,
      -0.022032050415873528,
      -0.013890272937715054,
      0.041449420154094696,
      0.06625883281230927,
      0.022786756977438927,
      0.03188867121934891,
      -0.011425813660025597,
      0.04552961513400078,
      -0.028370769694447517,
      0.008262617513537407,
      -0.09126150608062744,
      0.02126166969537735,
      -0.03463270515203476,
      0.010480349883437157,
      -0.041814908385276794,
      0.025428371503949165,
      0.08793158084154129,
      -0.08026903122663498,
      -0.05214155465364456,
      -0.03618727996945381,
      -0.04523156210780144,
      -0.04782570153474808,
      0.03244122862815857,
      0.048337824642658234,
      0.10264496505260468,
      -0.020472867414355278,
      0.012208626605570316,
      -0.03574903681874275,
      -0.06484009325504303,
      0.001673209946602583,
      -0.001766462461091578,
      0.1325509250164032,
      0.07115884870290756,
      -0.032861754298210144,
      -0.025390733033418655,
      0.05661590024828911,
      -0.05065840855240822,
      0.06931673735380173,
      0.12124834954738617,
      -0.06281808018684387,
      -0.009311605244874954,
      0.02803206257522106,
      -0.01055812556296587,
      -0.03962511941790581,
      -0.021586686372756958,
      0.062186338007450104,
      0.04122833535075188,
      0.01952117681503296,
      -0.028369376435875893,
      -0.07718893885612488,
      0.031057022511959076,
      0.048880331218242645,
      0.021572161465883255,
      0.0007096249028109014,
      -0.02068064734339714,
      -0.008408726193010807,
      0.0004571601457428187,
      0.039624281227588654,
      -0.021871130913496017,
      0.03574158623814583,
      0.018136506900191307,
      0.013491939753293991,
      -0.03164719417691231,
      0.0443931445479393,
      -0.07476676255464554,
      -0.029936693608760834,
      -0.06532885134220123,
      -0.0024112267419695854,
      -0.05875731259584427,
      -0.05700301751494408,
      0.012545679695904255,
      0.016985269263386726,
      0.11522737890481949,
      0.07054444402456284,
      0.005857045296579599,
      0.0065065245144069195,
      -0.0019043440697714686,
      -0.03565866872668266,
      -0.048239290714263916,
      -0.05553149804472923,
      0.07931685447692871,
      -0.022897128015756607,
      -0.02121756225824356,
      0.02556748315691948,
      0.03130105137825012,
      -0.00594051368534565,
      0.0201508030295372,
      -0.11120465397834778,
      -0.0015245263930410147,
      0.036632053554058075,
      0.12675635516643524,
      0.003234446980059147,
      -0.012980422005057335,
      -0.0031006736680865288,
      0.005570937413722277,
      -0.016990208998322487,
      0.043417684733867645,
      -0.006584046874195337,
      -0.035002484917640686,
      0.007294818758964539,
      0.015057787299156189,
      -0.030060898512601852,
      -0.01304791308939457,
      0.010987156070768833,
      0.024354632943868637,
      0.040936242789030075,
      -0.03614559397101402,
      0.06475639343261719,
      -0.005070534534752369,
      0.009985611774027348,
      0.04289359599351883,
      -0.05845083296298981,
      0.030253885313868523,
      0.014172532595694065,
      0.01585707999765873,
      -0.062054090201854706,
      0.03130168467760086,
      -0.0291889738291502,
      0.05311324819922447,
      0.04303434118628502,
      -0.048784371465444565,
      0.07132235914468765,
      0.06701123714447021,
      0.039082642644643784,
      0.023080699145793915,
      -0.0497024729847908,
      -0.013387279585003853,
      -0.01974409818649292,
      -0.06660119444131851,
      0.01606796495616436,
      0.02397654764354229,
      -0.02919294498860836,
      0.01832083985209465,
      -0.06018785759806633,
      0.030604025349020958,
      -0.012422308325767517,
      -0.06362240761518478,
      0.027978302910923958,
      0.01231471635401249,
      0.020000619813799858,
      0.06646034121513367,
      0.008869955316185951,
      -0.0020075507927685976,
      -0.022305430844426155,
      0.013285215012729168,
      -0.027265140786767006,
      0.03697556257247925,
      -0.030589213594794273,
      -0.02322857826948166,
      0.05616889148950577,
      0.013510878197848797,
      0.034114666283130646,
      0.0011161668226122856,
      0.0386553518474102,
      -0.027733320370316505,
      -0.08645614236593246,
      0.022399647161364555,
      -0.03382723033428192,
      -0.06499309092760086,
      0.05023772642016411,
      -0.013946226797997952,
      0.0870150774717331,
      0.04515775665640831,
      0.003976533655077219,
      -0.02469112165272236,
      0.07278041541576385,
      -0.06066123768687248,
      0.056438643485307693,
      -0.06163393706083298,
      -0.08143473416566849,
      -0.004773788619786501,
      0.024988286197185516,
      -0.004705775063484907,
      0.055512409657239914,
      0.02420712448656559,
      0.038456451147794724,
      -0.056744422763586044,
      -0.024810966104269028,
      -0.03181750327348709,
      0.019271312281489372,
      0.08261284977197647,
      -0.10880699008703232,
      0.058656640350818634,
      0.046833306550979614,
      0.014793365262448788,
      0.06853221356868744,
      -0.028243517503142357,
      0.08697757869958878,
      -0.03560670092701912,
      0.030601607635617256,
      0.03330862522125244,
      0.05960112810134888,
      -0.006145665422081947,
      0.06689560413360596,
      0.031111745163798332,
      0.06722378730773926,
      0.017914826050400734,
      -0.03905794769525528,
      0.044835422188043594,
      -0.04183277487754822,
      0.028260570019483566,
      0.010473839938640594,
      0.0005745699163526297,
      -0.005611394066363573,
      -0.011546945199370384,
      0.03423633053898811,
      -0.013462169095873833,
      -0.016272712498903275,
      -0.0653989166021347,
      0.018021132797002792,
      -0.10487029701471329,
      -0.07755948603153229,
      -0.007190195843577385,
      0.04793157801032066,
      0.06758664548397064,
      -0.031473539769649506,
      -0.02520863711833954,
      0.004448642488569021,
      -0.026045991107821465,
      0.01131521537899971,
      -0.014331331476569176,
      0.020165152847766876,
      0.0016515800962224603,
      -0.0157710462808609,
      -0.00928574986755848,
      -0.00899031013250351,
      0.07411555200815201,
      -0.03362075239419937,
      -0.019227171316742897,
      0.04869186133146286,
      0.04598205164074898,
      -0.0882914811372757,
      0.025346310809254646,
      -0.0520818755030632,
      0.05654526501893997,
      0.01705648936331272,
      0.04108687862753868,
      -0.02046484872698784,
      0.07817844301462173,
      -0.06846878677606583,
      -0.031072108075022697,
      -0.0005704646464437246,
      -0.02163625881075859,
      0.022011397406458855,
      0.012658254243433475,
      0.05841115489602089,
      -0.009797919541597366,
      0.028411122038960457,
      -0.005516881123185158,
      -0.044774144887924194,
      -0.007796464022248983,
      0.014324639923870564,
      -0.0035139410756528378,
      0.0031639880035072565,
      0.03557375445961952,
      -0.03621899336576462,
      0.026735836640000343,
      -0.04879970848560333,
      0.009974999353289604,
      0.029064642265439034,
      0.016339845955371857,
      -0.04374174401164055,
      -0.024995578452944756,
      -0.015869174152612686,
      -0.07925550639629364,
      -0.06856055557727814,
      -0.02879232168197632,
      0.00830798503011465,
      0.015253805555403233,
      0.07831915467977524,
      -0.013791576027870178,
      0.017328640446066856,
      -0.014036928303539753,
      0.06162476912140846,
      0.02708272449672222,
      -0.051551178097724915,
      0.015694933012127876,
      -0.07421370595693588,
      -0.05692259222269058,
      -0.034777481108903885,
      0.05133099853992462,
      0.054757121950387955,
      -0.03176526725292206,
      -0.06590354442596436,
      0.006696092430502176,
      0.08393488824367523,
      0.028856320306658745,
      0.015368936583399773,
      0.04661892354488373,
      -0.0866173505783081,
      0.05591490864753723,
      -0.0430285818874836,
      -0.12124700844287872,
      0.023620018735527992,
      0.014357348904013634,
      0.0026082394178956747,
      0.03827011212706566,
      0.045605070888996124,
      0.04723476618528366,
      -0.09301584213972092,
      0.04858231544494629,
      -0.058267176151275635,
      -0.0040032463148236275,
      0.043321605771780014,
      -0.010530944913625717,
      -0.12505017220973969,
      0.05468558520078659,
      -0.0029707574285566807,
      -0.007507378235459328,
      -0.010025297291576862,
      -0.04089267551898956,
      0.06276771426200867,
      -0.044157858937978745,
      -0.03836937993764877,
      0.05019772797822952,
      0.07730475068092346,
      -0.025582415983080864,
      -0.06293407827615738,
      -0.005988077260553837,
      0.06511691957712173,
      -0.057610008865594864,
      -0.0032935438212007284,
      0.013009323738515377,
      -0.10185583680868149,
      0.031607042998075485,
      0.012128069996833801,
      0.02771509438753128,
      0.009118592366576195,
      0.029053950682282448,
      -0.032514236867427826,
      0.05102383345365524,
      -0.025155292823910713,
      -0.033495742827653885,
      -0.10705951601266861,
      0.02006707340478897,
      -0.02084873430430889,
      -0.026993390172719955,
      -0.06103351712226868,
      -0.02861943654716015,
      0.012983228079974651,
      0.013471235521137714,
      -0.02291579730808735,
      0.0559636726975441,
      -0.01566493697464466,
      0.043859999626874924,
      0.03365377336740494,
      0.016937613487243652,
      -0.07442332804203033
    ],
    [
      -0.04255637526512146,
      -0.02596292458474636,
      0.008011985570192337,
      -0.004224690143018961,
      9.097267320612445e-05,
      0.010446453467011452,
      0.00028728123288601637,
      -0.0793144702911377,
      -0.03728047013282776,
      0.023006632924079895,
      0.03609010577201843,
      0.030790532007813454,
      -0.027768803760409355,
      -0.03692437708377838,
      0.011895154602825642,
      -0.02090073749423027,
      0.017428942024707794,
      -0.054680462926626205,
      0.03363453969359398,
      -0.04333175718784332,
      0.024605629965662956,
      0.00018874180386774242,
      0.02842618152499199,
      0.060879021883010864,
      -0.05133714899420738,
      -0.12059326469898224,
      -0.05519552156329155,
      0.019660361111164093,
      -0.009414657019078732,
      -0.020798105746507645,
      -0.0774577334523201,
      0.005179887171834707,
      0.07344476878643036,
      -0.0023562186397612095,
      -0.014178368262946606,
      -0.03254856914281845,
      0.03664800897240639,
      0.09444058686494827,
      -0.009883424267172813,
      0.04414824768900871,
      0.023055966943502426,
      0.08668249100446701,
      -0.018448105081915855,
      0.002324953442439437,
      0.002316815545782447,
      0.010155204683542252,
      -0.026835879310965538,
      0.02195163257420063,
      -0.042265649884939194,
      0.02203388512134552,
      0.046166371554136276,
      0.011192155070602894,
      0.053704939782619476,
      0.055439453572034836,
      0.008746391162276268,
      0.01991885155439377,
      0.06254226714372635,
      0.009631512686610222,
      -0.06945155560970306,
      0.008912495337426662,
      0.04054193198680878,
      -0.06991327553987503,
      -0.030163411051034927,
      0.012293823063373566,
      0.006143737118691206,
      -0.029762249439954758,
      -0.07969057559967041,
      -0.08106227964162827,
      -0.010561655275523663,
      -0.003449915675446391,
      -0.060731399804353714,
      -0.02738756313920021,
      -0.03932700678706169,
      0.044894810765981674,
      -0.03525029122829437,
      0.020518992096185684,
      0.08044405281543732,
      0.042871054261922836,
      -0.011087030172348022,
      -0.003048873506486416,
      -0.04486805200576782,
      0.10540857166051865,
      -0.04729458689689636,
      -0.048506028950214386,
      -0.023199111223220825,
      0.004111115355044603,
      0.019155284389853477,
      -0.10396429151296616,
      -0.033394426107406616,
      -0.020522499457001686,
      0.001581234741024673,
      -0.039952293038368225,
      0.021607456728816032,
      -0.03471457213163376,
      0.005457056220620871,
      -0.029521329328417778,
      -0.08922968804836273,
      0.07267442345619202,
      -0.053326405584812164,
      -0.013387711718678474,
      0.07244579493999481,
      -0.038563236594200134,
      0.02246706746518612,
      0.006562687456607819,
      -0.0169648639857769,
      0.009384267963469028,
      -0.05382147058844566,
      -0.023854443803429604,
      0.05582217872142792,
      -0.08009625226259232,
      -0.021049754694104195,
      0.04420885816216469,
      -0.02547973021864891,
      0.025046225637197495,
      -0.07668419927358627,
      0.000529204960912466,
      -0.0062230173498392105,
      0.0012702640378847718,
      -0.07813158631324768,
      -0.004293313715606928,
      -0.0026029120199382305,
      0.05471594259142876,
      -0.0595284029841423,
      0.06869767606258392,
      -0.05077396705746651,
      0.09253497421741486,
      -0.10130440443754196,
      -0.039027925580739975,
      -0.054410550743341446,
      -0.028954925015568733,
      0.008271203376352787,
      0.06155553087592125,
      0.00715646194294095,
      0.025851277634501457,
      -0.016155438497662544,
      -0.02655910514295101,
      0.12310462445020676,
      0.05361480638384819,
      0.006398610770702362,
      0.029171355068683624,
      -0.0019464199431240559,
      0.05304615944623947,
      -0.05857323110103607,
      0.04901110380887985,
      0.05270655080676079,
      0.058995623141527176,
      0.031373582780361176,
      -0.038537465035915375,
      0.0005766212707385421,
      -0.036803968250751495,
      -0.02177652157843113,
      -0.03702864423394203,
      -0.030356893315911293,
      -0.005104785319417715,
      -0.0048675574362277985,
      -0.005687985103577375,
      -0.032883211970329285,
      0.12318328022956848,
      -0.08272679895162582,
      0.06101807579398155,
      -0.019479939714074135,
      -0.02393445372581482,
      0.11632934212684631,
      0.04025685414671898,
      -0.026499154046177864,
      0.042842183262109756,
      -0.020820720121264458,
      0.018141578882932663,
      0.0004982759710401297,
      0.0917975902557373,
      0.009557928889989853,
      -0.05921131744980812,
      0.010015210136771202,
      -0.04469204321503639,
      0.0383872352540493,
      0.029137128964066505,
      0.09252388775348663,
      0.06299985200166702,
      0.008435353636741638,
      0.011159376241266727,
      0.007179767359048128,
      0.032349612563848495,
      0.016268212348222733,
      -0.0026449018623679876,
      -0.002651613438501954,
      -0.04881216213107109,
      0.03165276348590851,
      -0.01893427222967148,
      0.004310942254960537,
      -0.03618690371513367,
      -0.012111864984035492,
      -0.02551669254899025,
      0.00550391199067235,
      -0.04199191927909851,
      0.042888812720775604,
      0.02861136384308338,
      0.06603853404521942,
      -0.022423401474952698,
      -0.05455509200692177,
      -0.047560904175043106,
      0.016817159950733185,
      0.03321157023310661,
      -0.06120377779006958,
      0.06184422969818115,
      0.03997711464762688,
      0.07507482171058655,
      0.0028174370527267456,
      -0.10815557837486267,
      -0.03214544057846069,
      -0.03435820713639259,
      0.001150264753960073,
      -0.029473919421434402,
      -0.06581510603427887,
      -0.0631110668182373,
      -0.03471528738737106,
      -0.01764693111181259,
      0.0040961517952382565,
      0.02676381543278694,
      0.05591154471039772,
      -0.04135024920105934,
      -0.017874732613563538,
      -0.0027684099040925503,
      -0.021205183118581772,
      -0.0047271898947656155,
      0.002698303898796439,
      0.01952528953552246,
      0.02042504958808422,
      -0.0016680267872288823,
      0.030068473890423775,
      -0.0071235597133636475,
      -0.02263258397579193,
      -0.026811329647898674,
      -0.06617899984121323,
      -0.08812462538480759,
      0.011034839786589146,
      0.01937493309378624,
      -0.0012718004873022437,
      0.08709703385829926,
      0.005161650478839874,
      -0.011908120475709438,
      -0.04214886948466301,
      0.00395340146496892,
      -0.0021524440962821245,
      0.0011336859315633774,
      -0.03710805997252464,
      -0.08609171211719513,
      0.13035541772842407,
      -0.03644070774316788,
      0.004869333002716303,
      0.017816344276070595,
      0.0010067063849419355,
      0.0771593376994133,
      -0.05249577388167381,
      -0.058283064514398575,
      0.0016559637151658535,
      0.004196904134005308,
      -0.003470976836979389,
      0.014158125035464764,
      0.017006205394864082,
      0.01648096740245819,
      -0.1080389991402626,
      -0.10436221957206726,
      -0.047767944633960724,
      -0.09065204113721848,
      -0.04370072856545448,
      0.023510124534368515,
      -0.050934601575136185,
      -0.005768589675426483,
      -0.007353675551712513,
      -0.03336624056100845,
      -0.020121494308114052,
      -0.024026183411478996,
      -0.0533774308860302,
      -0.017297066748142242,
      0.048305705189704895,
      -0.016427328810095787,
      0.035549361258745193,
      -0.03954058140516281,
      -0.02382749319076538,
      0.09310305118560791,
      0.02555553987622261,
      0.02597416192293167,
      -0.00790353398770094,
      0.0182324405759573,
      0.018550613895058632,
      0.0479888953268528,
      -0.10296911001205444,
      0.010668601840734482,
      -0.08536547422409058,
      -0.045434966683387756,
      0.07706595957279205,
      0.07005199790000916,
      -0.03736037760972977,
      0.009151865728199482,
      0.012890432961285114,
      -0.000573615194298327,
      -0.004425083752721548,
      0.07118148356676102,
      -0.01572987623512745,
      0.02875249832868576,
      0.012458426877856255,
      0.03128523752093315,
      0.039330996572971344,
      -0.0456836074590683,
      -0.006095307879149914,
      0.018355775624513626,
      0.02988544851541519,
      0.053327351808547974,
      -0.005818747915327549,
      0.04766860976815224,
      0.014447275549173355,
      -0.08827633410692215,
      0.006506112404167652,
      0.03276759386062622,
      0.024441761896014214,
      -0.11942000687122345,
      0.024577243253588676,
      -0.014685273170471191,
      0.012139164842665195,
      -0.013333181850612164,
      -0.04586135596036911,
      -0.014632624574005604,
      -0.01822923868894577,
      -0.0015728441067039967,
      0.09245331585407257,
      -0.0027842449489980936,
      0.008193984627723694,
      0.053484879434108734,
      0.02535279281437397,
      -0.005379996728152037,
      -0.012406997382640839,
      0.024359403178095818,
      -0.017846152186393738,
      -0.022684330120682716,
      -0.00989905372262001,
      0.044835515320301056,
      -0.09387100487947464,
      0.0026161649730056524,
      -0.038999397307634354,
      -0.036216121166944504,
      -0.024678809568285942,
      -0.05968119576573372,
      -0.027852237224578857,
      -0.08124538511037827,
      -0.005856756120920181,
      -0.016274403780698776,
      0.03963964805006981,
      -0.04443887248635292,
      0.008301500231027603,
      -0.0028458309825509787,
      -0.00041921527008526027,
      -0.005134176462888718,
      -0.03389272838830948,
      -0.013208960182964802,
      -0.056809209287166595,
      0.030447794124484062,
      -0.040115322917699814,
      0.017237383872270584,
      0.008383435197174549,
      -0.025733010843396187,
      0.03445671126246452,
      -0.04938507825136185,
      0.05892783775925636,
      0.04866135120391846,
      0.043568361550569534,
      -0.04372593015432358,
      0.04780295863747597,
      -0.008498882874846458,
      0.004627079237252474,
      0.005008023232221603,
      -0.020903829485177994,
      0.06227884069085121,
      -0.0625409260392189,
      -0.018367398530244827,
      -0.05003289505839348,
      0.004752837121486664,
      0.08486127853393555,
      0.03652873635292053,
      -0.0710948035120964,
      0.019252141937613487,
      0.034836381673812866,
      -0.022663218900561333,
      0.059779416769742966,
      -0.05637959763407707,
      0.03130190074443817,
      0.020685996860265732,
      -0.021642282605171204,
      -0.023914767429232597,
      0.02199523337185383,
      0.02191932499408722,
      0.04155353084206581,
      0.08365856111049652,
      0.02827267162501812,
      0.012005865573883057,
      -0.042954470962285995,
      -0.0008664820343255997,
      -0.024768570438027382,
      -0.08543983846902847,
      -0.07627610117197037,
      0.05755262076854706,
      0.04177810251712799,
      -0.05326991528272629,
      0.038118716329336166,
      -0.030309366062283516,
      -0.051155976951122284,
      0.06283493340015411,
      0.04175784811377525,
      -0.003531750524416566,
      0.018169138580560684,
      -0.022414851933717728,
      0.028359930962324142,
      0.02710072509944439,
      -0.04316191002726555,
      0.00881502777338028,
      -0.05229804292321205,
      0.029278164729475975,
      -0.020909098908305168,
      0.05116262659430504,
      -0.0216611847281456,
      0.002345751039683819,
      0.05216134712100029,
      -0.03030308336019516,
      -0.029849980026483536,
      0.012176105752587318,
      0.03757123276591301,
      0.022472746670246124,
      -0.023088736459612846,
      0.020481936633586884,
      0.042123954743146896,
      0.1350335031747818,
      -0.04971719905734062,
      -0.004646047949790955,
      0.00012616220919881016,
      -0.019601983949542046,
      0.03098486363887787,
      0.03876806050539017,
      0.02023450843989849,
      -0.02102777175605297,
      -0.0005427456926554441,
      0.012876077555119991,
      0.039093758910894394,
      0.011829194612801075,
      -0.028017114847898483,
      0.009581749327480793,
      -0.05919097363948822,
      -0.03651902824640274,
      -0.03675797954201698,
      -0.05771810561418533,
      -0.03034832328557968,
      0.03409038111567497,
      -0.061532147228717804,
      0.013812853023409843,
      0.14972847700119019,
      0.02113654837012291,
      -0.03899117931723595,
      0.014531973749399185,
      0.002479330636560917,
      -0.030853595584630966,
      0.006206893362104893,
      -0.00428576348349452,
      0.049945466220378876,
      -0.0011504272697493434,
      -0.023143433034420013,
      -0.005991678684949875,
      0.010341358371078968,
      -0.026793241500854492,
      0.02685830183327198,
      -0.028277339413762093,
      0.08850597590208054,
      -0.08801574259996414,
      0.01472522597759962,
      -0.058652181178331375,
      0.029071668162941933,
      -0.0008692567353136837,
      0.06266815215349197,
      0.013442312367260456,
      0.030517542734742165,
      -0.036631014198064804,
      -0.025959471240639687,
      -0.031382739543914795,
      -0.04544387757778168,
      -0.024445336312055588,
      -0.018355626612901688,
      0.018303168937563896,
      -0.03305559605360031,
      -0.008060293272137642,
      -0.04198295995593071,
      -0.033521946519613266,
      -0.006028085481375456,
      0.03185094892978668,
      -0.00911456998437643,
      -0.01680634543299675,
      0.00970414374023676,
      -0.04520616680383682,
      0.05712325498461723,
      0.024854427203536034,
      0.02401408925652504,
      0.011041109450161457,
      -0.026373205706477165,
      0.05050237476825714,
      0.04730803892016411,
      0.0022151730954647064,
      0.0537305548787117
    ],
    [
      0.003645679447799921,
      -0.022112049162387848,
      -0.011481348425149918,
      0.008577276021242142,
      0.07108557224273682,
      -0.007919974625110626,
      0.034442465752363205,
      0.017288824543356895,
      0.0782516747713089,
      0.023132318630814552,
      0.016886353492736816,
      0.015166521072387695,
      -0.05859580263495445,
      -0.05781865864992142,
      0.030617762356996536,
      0.000554075522813946,
      -0.011909169144928455,
      -0.07268188893795013,
      -0.03630397096276283,
      0.03762544319033623,
      0.005464180372655392,
      -0.042524728924036026,
      0.004608911462128162,
      -0.022982895374298096,
      0.018751049414277077,
      0.015538468956947327,
      0.039515819400548935,
      0.028559761121869087,
      0.09143562614917755,
      -0.006137816235423088,
      0.02493831142783165,
      0.003906469792127609,
      -0.05923354625701904,
      0.02369461953639984,
      -0.07421469688415527,
      -0.037436291575431824,
      0.010143889114260674,
      0.006931267213076353,
      -0.007046913728117943,
      -0.07098245620727539,
      0.023415574803948402,
      -0.017183195799589157,
      -0.03231380507349968,
      0.06026051938533783,
      0.0055743250995874405,
      0.02964889630675316,
      0.012505377642810345,
      -0.02864997833967209,
      0.015316096134483814,
      -0.0537535585463047,
      -0.06063041463494301,
      0.04038025438785553,
      -0.09711923450231552,
      0.011517474427819252,
      -0.06531570851802826,
      0.0946221649646759,
      0.01703602634370327,
      0.05134189873933792,
      0.04924214631319046,
      0.015509159304201603,
      0.12685056030750275,
      0.04510178044438362,
      -0.05876975879073143,
      0.008513386361300945,
      -0.015812482684850693,
      -0.01710885763168335,
      -0.04139914736151695,
      0.045757733285427094,
      -0.006099645048379898,
      -0.0005783851956948638,
      0.07381156831979752,
      -0.02946784906089306,
      0.02742839977145195,
      -0.048361051827669144,
      -0.008968169800937176,
      -0.014506992883980274,
      -0.041669320315122604,
      -0.0231016892939806,
      0.011037827469408512,
      -0.006907209753990173,
      0.025596288964152336,
      -0.08770214766263962,
      -0.020310092717409134,
      0.06206068396568298,
      0.05283207818865776,
      0.046308938413858414,
      0.0216962993144989,
      0.026169732213020325,
      -0.07097925245761871,
      -0.01982545107603073,
      0.09222940355539322,
      -0.03139426186680794,
      0.03631773963570595,
      -0.07372903823852539,
      0.03593388944864273,
      0.006043617147952318,
      -0.006804901175200939,
      0.009267604909837246,
      0.10539157688617706,
      0.09842415153980255,
      -0.005044145509600639,
      -0.007035655435174704,
      0.054929494857788086,
      -0.0172683447599411,
      -0.020298510789871216,
      0.01774485409259796,
      0.012560293078422546,
      0.04486558958888054,
      0.026119958609342575,
      -0.017034204676747322,
      -0.029201555997133255,
      -0.08588213473558426,
      -0.007100967690348625,
      -0.011834473349153996,
      -0.05885796248912811,
      -0.0404144749045372,
      -0.01312120258808136,
      -0.04405565187335014,
      -0.0830737054347992,
      0.020779304206371307,
      -0.0145436841994524,
      -0.041494764387607574,
      -0.09020168334245682,
      0.01398435141891241,
      0.07528668642044067,
      -0.02196447178721428,
      0.007288099732249975,
      0.03875732421875,
      -0.009799934923648834,
      0.032063934952020645,
      -0.020673057064414024,
      -0.04922826960682869,
      0.026357606053352356,
      0.03939344361424446,
      0.04058651626110077,
      0.021611809730529785,
      0.03251512348651886,
      0.002765908371657133,
      0.12652212381362915,
      0.0063673872500658035,
      0.02648143097758293,
      -0.014380726963281631,
      -0.04336974397301674,
      -0.009504378773272038,
      0.05748447775840759,
      0.028748124837875366,
      0.032954487949609756,
      -0.013817290775477886,
      -0.01843833178281784,
      -0.08958309888839722,
      -0.003964155446738005,
      -0.05149444192647934,
      -0.02111663483083248,
      -0.0044135600328445435,
      0.04245898872613907,
      -0.0030040231067687273,
      -0.04404468089342117,
      0.002884769579395652,
      0.032869141548871994,
      -0.005425360519438982,
      0.020009296014904976,
      0.0096395593136549,
      0.012160886079072952,
      0.0129317007958889,
      0.006614662706851959,
      0.07622601091861725,
      0.06506461650133133,
      -0.0059218015521764755,
      0.011584515683352947,
      0.012320649810135365,
      0.0020117745734751225,
      0.020132072269916534,
      0.028374914079904556,
      0.044360771775245667,
      -0.05985087901353836,
      -0.022053124383091927,
      -0.05394766479730606,
      -0.014331021346151829,
      0.04635009169578552,
      0.020780140534043312,
      -0.01419456023722887,
      0.05671125650405884,
      0.03865427151322365,
      0.03245141729712486,
      -0.03768447786569595,
      0.08110294491052628,
      0.059851955622434616,
      -0.0461893193423748,
      -0.09127810597419739,
      -0.03307390585541725,
      0.012575137428939342,
      0.010618936270475388,
      -0.01379106380045414,
      0.0218734759837389,
      0.033872541040182114,
      0.04154142737388611,
      0.005449443124234676,
      0.029166413471102715,
      0.048065025359392166,
      0.044175658375024796,
      0.06695601344108582,
      -0.042816318571567535,
      -0.008330781944096088,
      0.03641221299767494,
      -0.10554822534322739,
      0.01342419721186161,
      0.07205867022275925,
      -0.0726306289434433,
      0.03483760729432106,
      -0.03744247183203697,
      0.007301603443920612,
      -0.06831882894039154,
      -0.002001554472371936,
      0.09068867564201355,
      0.01439481507986784,
      -0.02902051992714405,
      0.027956902980804443,
      -0.0022968947887420654,
      0.05790037661790848,
      -0.06722041219472885,
      -0.01828504353761673,
      0.07711214572191238,
      -0.03099345788359642,
      0.0287941787391901,
      -0.009101014584302902,
      -0.03457094728946686,
      -0.034429408609867096,
      0.03337421268224716,
      0.08420850336551666,
      -0.021167604252696037,
      -0.04961152747273445,
      0.07451730966567993,
      -0.02531438134610653,
      -0.03996382653713226,
      -0.06745781749486923,
      0.02025698684155941,
      -0.011556712910532951,
      -0.04827510938048363,
      0.03658461943268776,
      0.034314803779125214,
      0.03190718591213226,
      -0.020030321553349495,
      0.035923197865486145,
      0.013772435486316681,
      -0.008763031102716923,
      -0.0042189499363303185,
      -0.007503965869545937,
      -0.09167472273111343,
      -0.02524886652827263,
      0.03393453732132912,
      0.024457937106490135,
      0.0504128634929657,
      0.024596426635980606,
      -0.013471164740622044,
      0.01975187659263611,
      -0.022996021434664726,
      0.03119671531021595,
      -0.0801658034324646,
      -0.014591828919947147,
      -0.02354002743959427,
      -0.01814836822450161,
      -0.11863625049591064,
      0.016037385910749435,
      -0.005354365333914757,
      0.0007824699860066175,
      0.05141691118478775,
      -0.011840784922242165,
      -0.044908031821250916,
      0.012195954099297523,
      0.01462267804890871,
      -0.03816354647278786,
      0.010099256411194801,
      0.042679522186517715,
      -0.037064217031002045,
      0.030188562348484993,
      0.03231315314769745,
      -0.050473444163799286,
      0.007229698821902275,
      0.005917554255574942,
      -0.0032921326346695423,
      0.026489531621336937,
      -0.02041138894855976,
      -0.07675256580114365,
      0.03612450510263443,
      0.0005910368636250496,
      -0.02897455543279648,
      0.0765308141708374,
      -0.048026882112026215,
      0.04012129828333855,
      0.0034913921263068914,
      -0.009116423316299915,
      0.08523298799991608,
      -0.040146876126527786,
      0.03677569702267647,
      0.015289933420717716,
      0.02585086040198803,
      -0.025378279387950897,
      -0.014869500882923603,
      -0.050206419080495834,
      -0.10721203684806824,
      0.001908587059006095,
      -0.06388981640338898,
      -0.03294232487678528,
      -0.05827490985393524,
      -0.02542530745267868,
      0.029056575149297714,
      0.07217919826507568,
      -0.028231138363480568,
      -0.005096332170069218,
      0.04677204415202141,
      0.06398627907037735,
      -0.011389331892132759,
      0.060010407119989395,
      0.03183864429593086,
      -0.014595085754990578,
      0.0005051386542618275,
      0.033806364983320236,
      0.0187727939337492,
      0.04636978358030319,
      -0.023758014664053917,
      -0.08005385845899582,
      0.03503001108765602,
      -0.00033043063012883067,
      -0.030758924782276154,
      -0.013874783180654049,
      0.031111862510442734,
      0.04598073288798332,
      -0.0065583172254264355,
      -0.042482610791921616,
      -0.016426431015133858,
      -0.04491733759641647,
      0.07090238481760025,
      0.0034195983316749334,
      0.010489581152796745,
      -0.013981131836771965,
      0.015088701620697975,
      -0.040550995618104935,
      0.03509228676557541,
      -0.018323052674531937,
      0.025256220251321793,
      -0.03358660265803337,
      0.0042559304274618626,
      0.0336817130446434,
      0.01274618785828352,
      -0.009123798459768295,
      -0.01684476062655449,
      0.004305495414882898,
      -0.07259728759527206,
      -0.019482683390378952,
      0.013820165768265724,
      0.00989197101444006,
      -0.08284494280815125,
      0.006031977944076061,
      0.05828401446342468,
      0.0058292062021791935,
      -0.042600665241479874,
      0.00248279538936913,
      0.04649331048130989,
      -0.0073747397400438786,
      -0.04436729475855827,
      -0.042219534516334534,
      0.009689876809716225,
      -0.049322713166475296,
      0.01245425920933485,
      -0.03271234408020973,
      -0.010043147020041943,
      -0.10692337900400162,
      0.008516265079379082,
      -0.06504154205322266,
      0.045847732573747635,
      0.026688724756240845,
      -0.10319017618894577,
      0.028054237365722656,
      -0.021621979773044586,
      -0.06896048039197922,
      -0.03670831769704819,
      0.019045930355787277,
      -0.013119378127157688,
      -0.028400195762515068,
      0.07155466824769974,
      -0.019934067502617836,
      0.12027209997177124,
      0.020112713798880577,
      -0.003404792631044984,
      -0.005650035105645657,
      -0.006074467673897743,
      -0.01955278217792511,
      0.01365536917001009,
      0.037533778697252274,
      -0.018690772354602814,
      0.004572105593979359,
      0.041573621332645416,
      0.05775410681962967,
      0.0037203957326710224,
      -0.03951888158917427,
      -0.0237783994525671,
      -0.016317220404744148,
      -0.062342364341020584,
      -0.07365728169679642,
      0.01685701124370098,
      0.036327607929706573,
      -0.03332491219043732,
      -0.036698583513498306,
      0.0006416143151000142,
      0.034310098737478256,
      0.03142358362674713,
      0.054308757185935974,
      0.008778063580393791,
      -0.008729196153581142,
      -0.0028621756937354803,
      -0.015732618048787117,
      0.07447352260351181,
      0.0944518893957138,
      -0.10728154331445694,
      0.0024839446414262056,
      0.06113390251994133,
      -0.042126692831516266,
      0.009765103459358215,
      -0.007573421578854322,
      -0.004009247291833162,
      -0.016119055449962616,
      -0.042717345058918,
      0.07047335058450699,
      0.03935883939266205,
      -0.021998591721057892,
      0.010940867476165295,
      0.04561862722039223,
      -0.0690697431564331,
      0.057533204555511475,
      0.05749683454632759,
      0.011807986535131931,
      -0.00457634124904871,
      -0.04775514081120491,
      -0.1098487451672554,
      0.02947199158370495,
      -0.03251583129167557,
      -0.02408434823155403,
      -0.026487313210964203,
      0.023629020899534225,
      -0.04491545259952545,
      -0.021016966551542282,
      0.06841657310724258,
      0.00689934566617012,
      0.07191374152898788,
      -0.007882689125835896,
      0.02964211255311966,
      -0.04895088076591492,
      -0.012644447386264801,
      -0.010836287401616573,
      -0.009354397654533386,
      0.012271365150809288,
      0.048519477248191833,
      0.07617998868227005,
      -0.01846347190439701,
      -0.05982290953397751,
      0.017012083902955055,
      0.0150570347905159,
      0.037424758076667786,
      -0.009335569106042385,
      0.060744401067495346,
      -0.003982522990554571,
      0.08130165934562683,
      -0.0009837799007073045,
      -0.012197495438158512,
      -0.0633600503206253,
      -0.07599351555109024,
      -0.012104417197406292,
      0.04071327671408653,
      0.012884835712611675,
      0.017275433987379074,
      -0.025881431996822357,
      0.028389280661940575,
      -0.00792056042701006,
      0.013366159982979298,
      -0.04429848864674568,
      -0.03303296118974686,
      -0.06430888175964355,
      -0.06413759291172028,
      -0.03734811022877693,
      -0.09481252729892731,
      -0.04819892346858978,
      0.038332194089889526,
      -0.026244143024086952,
      0.07413356006145477,
      0.001890872255899012,
      0.03730194643139839,
      -0.027475018054246902,
      -0.06333696097135544,
      -0.04950585961341858,
      -0.05931888893246651,
      -0.014594507403671741,
      0.044183194637298584,
      0.04997626692056656,
      0.006601168308407068,
      -0.026610909029841423,
      0.023405183106660843,
      0.028464751318097115,
      0.02297278866171837,
      -0.0026339797768741846,
      -0.046588338911533356,
      0.0015597608871757984,
      0.010275231674313545,
      -0.011682374402880669
    ],
    [
      -0.006228978745639324,
      0.09579290449619293,
      0.025337951257824898,
      -0.014993362128734589,
      0.0421624518930912,
      0.009162216447293758,
      0.04919648915529251,
      -0.08383961021900177,
      -0.11984683573246002,
      0.08099017292261124,
      0.057967595756053925,
      -0.013969028368592262,
      -0.004054392222315073,
      0.11361101269721985,
      0.050256554037332535,
      0.08889585733413696,
      0.048290327191352844,
      -0.051966384053230286,
      0.09350838512182236,
      0.008889742195606232,
      -0.0031347700860351324,
      0.012826630845665932,
      0.05945400148630142,
      0.005646855570375919,
      -0.07037988305091858,
      0.04270586743950844,
      -0.01562302652746439,
      0.04376157000660896,
      -0.02490498125553131,
      -0.05706281214952469,
      -0.057853132486343384,
      0.001228359411470592,
      0.02707981877028942,
      -0.0027025032322853804,
      -0.03602321818470955,
      0.017215996980667114,
      0.04988245293498039,
      -0.028528055176138878,
      0.038954541087150574,
      0.03001406043767929,
      -0.015193508937954903,
      0.04089958220720291,
      0.03631649538874626,
      0.04677128791809082,
      0.006548197474330664,
      -0.06232083588838577,
      0.07451001554727554,
      -0.03851114958524704,
      -0.02481992542743683,
      0.07584775984287262,
      0.02571675553917885,
      0.010110986419022083,
      0.040183138102293015,
      -0.02279955893754959,
      -0.0894169807434082,
      0.005208004731684923,
      0.008452137932181358,
      -0.07738224416971207,
      -0.017023537307977676,
      -0.03884754329919815,
      0.026711348444223404,
      0.008822361007332802,
      0.005288079380989075,
      -0.03057624027132988,
      0.027563942596316338,
      0.0191387590020895,
      -0.03774005547165871,
      0.10744745284318924,
      -0.01876898854970932,
      0.010749085806310177,
      0.015602962113916874,
      -0.035567756742239,
      -0.05073215439915657,
      0.03755319118499756,
      0.0032180091366171837,
      -0.06981076300144196,
      -0.09854797273874283,
      0.02447805553674698,
      -0.0788017138838768,
      0.01315199676901102,
      0.033272743225097656,
      0.023635461926460266,
      0.03981528431177139,
      0.08344478160142899,
      0.06758110225200653,
      0.06089453399181366,
      -0.04117130860686302,
      -0.0004040725762024522,
      -0.0228620246052742,
      0.026866387575864792,
      -0.030675716698169708,
      -0.020875966176390648,
      -0.06310608983039856,
      -0.01825110800564289,
      -0.06424553692340851,
      0.023231523111462593,
      -0.07901886850595474,
      0.00653176149353385,
      -0.045408446341753006,
      0.056173380464315414,
      0.02680249884724617,
      -0.01895808055996895,
      -0.07551854103803635,
      -0.004937492311000824,
      -0.07355177402496338,
      -0.015622664242982864,
      0.018993422389030457,
      0.05547768622636795,
      0.027719827368855476,
      0.012831807136535645,
      0.022663412615656853,
      0.04878716170787811,
      -0.02041119523346424,
      0.011442255228757858,
      0.004841231275349855,
      0.0940198227763176,
      0.020577281713485718,
      -0.05097902938723564,
      0.053050730377435684,
      -0.017437107861042023,
      -0.024847179651260376,
      -0.011335227638483047,
      -0.04498537257313728,
      -0.003519882448017597,
      -0.05267607420682907,
      -0.02161150611937046,
      0.0008377356571145356,
      -0.029152989387512207,
      0.000553494319319725,
      0.05440028756856918,
      0.03469665348529816,
      -0.08082879334688187,
      -0.03098246082663536,
      0.02924141101539135,
      -0.011984926648437977,
      -0.024188421666622162,
      -0.041709065437316895,
      -0.006848204880952835,
      -0.055829018354415894,
      0.06595028191804886,
      0.07739083468914032,
      0.0013193238992244005,
      -0.025413960218429565,
      -0.027665697038173676,
      0.019752617925405502,
      0.06328939646482468,
      0.06115476414561272,
      -0.01235175970941782,
      0.02328471839427948,
      0.006481732241809368,
      -0.06784587353467941,
      0.0708322674036026,
      -0.042462460696697235,
      0.06136889383196831,
      0.003543534781783819,
      0.051763612776994705,
      -0.0030420830007642508,
      -0.003909881692379713,
      0.0037117931060492992,
      0.062371425330638885,
      0.004562519025057554,
      0.05048571899533272,
      -0.055272385478019714,
      -0.016476333141326904,
      -0.023388169705867767,
      0.04765786975622177,
      -0.0050489879213273525,
      -0.0012974535347893834,
      -0.0031287292949855328,
      0.054505787789821625,
      0.012677712365984917,
      0.03802258148789406,
      0.002113998169079423,
      0.003807162633165717,
      -0.04049946367740631,
      0.025080231949687004,
      0.06923452764749527,
      0.0012979983584955335,
      -0.05423616245388985,
      -0.09251049906015396,
      -0.04368056729435921,
      0.034985966980457306,
      0.022442538291215897,
      -0.10823459178209305,
      -0.04878101125359535,
      0.018471313640475273,
      0.1477094441652298,
      -0.08324029296636581,
      -0.01103886216878891,
      -0.011416368186473846,
      0.02259356901049614,
      0.00021396904776338488,
      -0.08359847962856293,
      0.01240379735827446,
      -0.021089188754558563,
      0.049418531358242035,
      -0.11620058119297028,
      0.0708465427160263,
      -0.02213916927576065,
      -0.05329658463597298,
      -0.039406850934028625,
      0.024035155773162842,
      0.058419909328222275,
      0.019756803289055824,
      0.01067688874900341,
      -0.05903417244553566,
      -0.0338403694331646,
      -0.029501179233193398,
      -0.04050465300679207,
      0.05257982015609741,
      -0.007259095087647438,
      0.09316536039113998,
      0.011339663527905941,
      -0.0016250460175797343,
      -0.027770210057497025,
      -0.02815968357026577,
      0.04134111851453781,
      0.07507245987653732,
      0.05739351361989975,
      0.0008026552386581898,
      0.0016308610793203115,
      -0.03233056142926216,
      0.044763728976249695,
      -0.053223684430122375,
      -0.02007022313773632,
      0.002389723900705576,
      0.018545525148510933,
      0.04930220916867256,
      0.012136806733906269,
      0.033762089908123016,
      0.012553760781884193,
      0.0005999641725793481,
      0.028050698339939117,
      0.05010942369699478,
      0.09741410613059998,
      0.05675302818417549,
      0.014215919189155102,
      0.027348078787326813,
      -0.022937536239624023,
      -0.04869437962770462,
      -0.03218434751033783,
      0.03459290415048599,
      0.01906074583530426,
      0.0106135793030262,
      0.029296984896063805,
      0.07837232947349548,
      -0.06661801785230637,
      0.0046159373596310616,
      0.025637095794081688,
      -0.0006456804112531245,
      0.03776858374476433,
      0.09729069471359253,
      0.03266007453203201,
      -0.012980207800865173,
      -0.032668717205524445,
      0.07230221480131149,
      -0.008776484057307243,
      0.03698576241731644,
      0.05076626315712929,
      -0.010289816185832024,
      -0.02937581203877926,
      -0.11756303161382675,
      -0.05782761797308922,
      0.027519240975379944,
      -0.011113121174275875,
      -0.022458871826529503,
      -0.03993983566761017,
      -0.0926976203918457,
      -0.021802742034196854,
      0.08467929065227509,
      0.022582365199923515,
      -0.084731824696064,
      -0.04048089683055878,
      -0.01504023838788271,
      -0.0051396857015788555,
      0.018584735691547394,
      -0.013903675600886345,
      -0.007310609798878431,
      -0.06284283101558685,
      -0.023230263963341713,
      -0.03480054438114166,
      -0.03479884937405586,
      -0.059615619480609894,
      0.007019645534455776,
      -0.03580382838845253,
      -0.014060609973967075,
      0.03449543938040733,
      0.021009067073464394,
      -0.023466836661100388,
      0.059161290526390076,
      0.0340237095952034,
      0.12692061066627502,
      -0.01858370564877987,
      0.03542599827051163,
      -0.024979719892144203,
      -0.02342122420668602,
      0.022550910711288452,
      -0.002573962090536952,
      0.00952366553246975,
      -0.032303303480148315,
      0.04174969345331192,
      -0.09401536732912064,
      0.008473383262753487,
      -0.05754483863711357,
      -0.0024020301643759012,
      0.019023021683096886,
      -0.007694917265325785,
      -0.035120781511068344,
      0.04142889380455017,
      0.04007678106427193,
      0.10019727051258087,
      -0.01774643361568451,
      -0.005796539131551981,
      0.004295845981687307,
      -0.029808036983013153,
      0.04628528654575348,
      0.02586650848388672,
      -0.034612517803907394,
      0.012981457635760307,
      0.042569179087877274,
      -0.03124556876718998,
      -0.0855744332075119,
      -0.09610452502965927,
      -0.11119840294122696,
      0.0309993177652359,
      0.037338729947805405,
      -0.07249680161476135,
      -0.03561561927199364,
      -0.011238900013267994,
      0.042901672422885895,
      0.051186881959438324,
      0.0487188845872879,
      0.02128634788095951,
      0.0302790105342865,
      0.029245978221297264,
      0.013138567097485065,
      0.01860729232430458,
      -0.00856024120002985,
      0.009815732948482037,
      -0.003910193219780922,
      0.03231775388121605,
      -0.03179681673645973,
      0.05035468935966492,
      0.04184684902429581,
      -0.03467981144785881,
      -0.005449512507766485,
      -0.009883410297334194,
      -0.020353782922029495,
      -0.008235286921262741,
      0.0324334017932415,
      0.030762730166316032,
      0.08255477994680405,
      -0.02259417250752449,
      0.029010608792304993,
      0.05269595980644226,
      -0.04494468495249748,
      -0.068799689412117,
      0.025939321145415306,
      0.0234410110861063,
      -0.01730385608971119,
      0.05000273510813713,
      0.058607589453458786,
      0.06855112314224243,
      0.009780215099453926,
      0.03678756207227707,
      -0.04678446426987648,
      -0.028121834620833397,
      -0.02521810680627823,
      0.045397449284791946,
      -0.02507384680211544,
      0.028811857104301453,
      -0.02642788365483284,
      -0.025182371959090233,
      0.021476861089468002,
      0.033167120069265366,
      0.0098125534132123,
      -0.03675486519932747,
      0.041675277054309845,
      -0.034168440848588943,
      -0.06008489429950714,
      -0.034405358135700226,
      0.02707746997475624,
      -0.030684545636177063,
      0.028026020154356956,
      0.055150702595710754,
      0.04482188820838928,
      0.03866609185934067,
      -0.00740214716643095,
      -0.042265575379133224,
      -0.05279421806335449,
      0.08374392241239548,
      0.009850011207163334,
      -0.022062668576836586,
      -0.07361192256212234,
      -0.06151467561721802,
      -0.02832355909049511,
      -0.03314972296357155,
      -0.058101169764995575,
      -0.03656262159347534,
      0.05876133218407631,
      -0.01058907900005579,
      0.013378762640058994,
      -0.034172940999269485,
      0.036282069981098175,
      -0.04325449466705322,
      0.043814223259687424,
      -0.032424651086330414,
      0.008085334673523903,
      -0.04015135020017624,
      0.029687734320759773,
      0.05749453976750374,
      -0.06388795375823975,
      -0.06144287809729576,
      0.00015721400268375874,
      -0.03496177867054939,
      -0.056378208100795746,
      -0.019096000120043755,
      -0.025410179048776627,
      -0.059768084436655045,
      -0.03251178190112114,
      -0.01692344807088375,
      -0.021331146359443665,
      0.08593609184026718,
      0.010665599256753922,
      0.0005975953536108136,
      -0.005356067791581154,
      0.09835755079984665,
      0.06424401700496674,
      -0.016739457845687866,
      -0.023709598928689957,
      0.06683877855539322,
      -0.004439387004822493,
      -0.014409973286092281,
      -0.015366722829639912,
      0.008450011722743511,
      0.01185269933193922,
      0.03255745396018028,
      -0.051040925085544586,
      -0.0498189814388752,
      -0.005261256359517574,
      0.03156379610300064,
      0.07692039012908936,
      -0.057811882346868515,
      0.0014062521513551474,
      -0.00016105271060951054,
      -0.06279976665973663,
      0.07893073558807373,
      0.005441125016659498,
      0.008050750941038132,
      -0.04356655478477478,
      -0.05423934757709503,
      0.029500801116228104,
      0.017368316650390625,
      -0.07040871679782867,
      -0.03328289836645126,
      -0.00971890240907669,
      -0.02260447107255459,
      0.04823770374059677,
      -0.04905003309249878,
      -0.009875820949673653,
      -0.08252321183681488,
      0.03297611325979233,
      0.01952306739985943,
      -0.05504388362169266,
      -0.030524881556630135,
      0.04089547321200371,
      -0.01031551230698824,
      0.0156500656157732,
      0.10436423122882843,
      0.05962128937244415,
      -0.0011139175621792674,
      0.05340287834405899,
      0.033554013818502426,
      -0.016896001994609833,
      0.06646569073200226,
      0.003051360370591283,
      0.026906607672572136,
      -0.03872234746813774,
      -0.023206040263175964,
      -0.036438118666410446,
      -0.03841532766819,
      0.014009159058332443,
      0.05955450236797333,
      0.022532837465405464,
      0.03134077042341232,
      0.04004957899451256,
      -0.02534507028758526,
      0.03870445117354393,
      0.011567101813852787,
      -0.017269186675548553,
      -0.019389010965824127,
      -0.01996181719005108,
      0.0850837454199791,
      0.0313752144575119,
      -0.046937134116888046,
      0.1592216044664383,
      0.008124991320073605,
      -0.017455488443374634,
      -0.006270143669098616,
      -0.0492536835372448,
      0.056972749531269073,
      -0.05510588735342026,
      -0.002613052725791931
    ],
    [
      -0.04166002944111824,
      0.027899378910660744,
      0.0012360650580376387,
      -0.04690933972597122,
      0.006787835620343685,
      0.001832614652812481,
      0.03794295713305473,
      0.014597713947296143,
      0.09244953095912933,
      0.08337882906198502,
      0.08536054193973541,
      0.02116977795958519,
      0.0054334914311766624,
      0.030583400279283524,
      -0.03380749747157097,
      -0.02393009327352047,
      0.027241725474596024,
      -0.04948558285832405,
      0.028512340039014816,
      -0.024226732552051544,
      -0.03403469920158386,
      0.03173255920410156,
      0.037926942110061646,
      -0.0036480610724538565,
      -0.04204805940389633,
      0.08689676970243454,
      -0.08688516914844513,
      -0.06452355533838272,
      0.025899838656187057,
      0.06574340164661407,
      0.0591270849108696,
      0.010690204799175262,
      0.007481854874640703,
      0.04453672096133232,
      -0.011974080465734005,
      0.0638764351606369,
      -0.018123207613825798,
      0.06961259245872498,
      -0.026316799223423004,
      0.05017359182238579,
      -0.07165028154850006,
      -0.0025490124244242907,
      -0.000273356941761449,
      -0.03810647502541542,
      -0.02750827558338642,
      -0.007590655703097582,
      0.0026223009917885065,
      -0.025208376348018646,
      0.011156844906508923,
      0.03817996010184288,
      0.06055512651801109,
      0.08651285618543625,
      -0.020839873701334,
      0.013762895949184895,
      -0.09114878624677658,
      -0.08696716278791428,
      -0.029325921088457108,
      0.039591748267412186,
      0.02745022252202034,
      -0.05131302773952484,
      0.11428046971559525,
      0.08963096886873245,
      0.02451224811375141,
      0.004943027626723051,
      -0.038744598627090454,
      -0.010826606303453445,
      0.037725549191236496,
      -0.02644166350364685,
      -0.05553141236305237,
      -0.022402729839086533,
      -0.06158081814646721,
      -0.03564451262354851,
      0.08481201529502869,
      -0.035323914140462875,
      -0.00601568166166544,
      0.010381529107689857,
      0.051218416541814804,
      -0.01113405916839838,
      0.02907135710120201,
      -0.05618645250797272,
      -0.09052985161542892,
      0.003574614878743887,
      0.03224417567253113,
      -0.019524691626429558,
      -0.06027014181017876,
      -0.020467760041356087,
      -0.03993258252739906,
      -0.020950060337781906,
      -0.03698299825191498,
      0.05811972916126251,
      0.16050484776496887,
      0.006911599542945623,
      -0.08289498835802078,
      0.12053228914737701,
      0.029072245582938194,
      0.029838213697075844,
      -0.02614743262529373,
      -0.002858600113540888,
      0.022005347535014153,
      -0.030291782692074776,
      0.055992670357227325,
      -0.04642561823129654,
      0.008453847840428352,
      0.05267868563532829,
      -0.004711761139333248,
      0.02724870666861534,
      0.01067053247243166,
      -0.0636172816157341,
      -0.0015556946163997054,
      0.023474426940083504,
      -0.01878328062593937,
      0.011686133220791817,
      -0.04308082163333893,
      0.00683977548032999,
      0.0028359415009617805,
      -0.051346927881240845,
      -0.019337905570864677,
      -0.016815457493066788,
      0.08792660385370255,
      -0.11041396111249924,
      0.09177220612764359,
      0.048622630536556244,
      -0.030460303649306297,
      0.021675435826182365,
      -0.031201550737023354,
      -0.019662607461214066,
      -0.02061355859041214,
      0.01879958249628544,
      -0.011666150763630867,
      -0.009999584406614304,
      -0.04894997552037239,
      0.07928735017776489,
      0.03226308152079582,
      0.11205469816923141,
      -0.04452528432011604,
      -0.04588070511817932,
      -0.0537603422999382,
      0.07717078179121017,
      -0.002097315853461623,
      -0.06314156204462051,
      0.012362895533442497,
      -0.007510267198085785,
      -0.008559903129935265,
      -0.0005484176799654961,
      -0.007591803092509508,
      0.05136888846755028,
      0.02877204306423664,
      -0.07429751008749008,
      0.0620163157582283,
      0.05310468375682831,
      -0.03622622787952423,
      -0.0036924600135535,
      -0.04472297802567482,
      -0.06236517056822777,
      -0.05042974650859833,
      0.022659655660390854,
      -0.06650219857692719,
      -0.01804719679057598,
      0.08031105995178223,
      0.017855284735560417,
      -0.07628872245550156,
      -0.035788506269454956,
      -0.02318393625319004,
      -0.0024758423678576946,
      0.005740404594689608,
      0.009746439754962921,
      -0.005198953207582235,
      -0.08711516112089157,
      0.030014151707291603,
      0.008840582333505154,
      -0.010422967374324799,
      0.025157874450087547,
      -0.0025482242926955223,
      -0.054957419633865356,
      0.008362054824829102,
      -0.06047382950782776,
      0.008026264607906342,
      0.01437025424093008,
      0.023283926770091057,
      -0.050042442977428436,
      -0.038974858820438385,
      0.020396556705236435,
      0.017593607306480408,
      0.031074076890945435,
      0.015836436301469803,
      0.04479782655835152,
      0.06476645171642303,
      0.005926061421632767,
      0.004171291831880808,
      0.041120775043964386,
      0.03639596700668335,
      0.025225097313523293,
      0.06746786087751389,
      0.006187281105667353,
      0.05614154785871506,
      0.02551373466849327,
      -0.0018635046435520053,
      -0.04543684795498848,
      -0.005469572264701128,
      0.018032215535640717,
      -0.052775755524635315,
      0.036776311695575714,
      0.03628143295645714,
      0.016610832884907722,
      -0.0003816539538092911,
      -0.03773421421647072,
      -0.029649769887328148,
      0.039137113839387894,
      0.05281488969922066,
      0.034968722611665726,
      0.07913453876972198,
      0.058123696595430374,
      -0.036846477538347244,
      0.06456542015075684,
      0.013185018673539162,
      -0.021048568189144135,
      0.08076716214418411,
      0.07526516169309616,
      0.03266124054789543,
      -0.0211306381970644,
      -0.0032229830976575613,
      -0.015286200679838657,
      0.014196958392858505,
      -0.022853190079331398,
      -0.029078451916575432,
      0.03520042449235916,
      -0.12985479831695557,
      0.0789121463894844,
      -0.030115950852632523,
      0.014674902893602848,
      -0.049992699176073074,
      0.005867204163223505,
      -0.051005106419324875,
      0.03174060210585594,
      0.06906892359256744,
      0.021468013525009155,
      -0.015542976558208466,
      -0.0640137568116188,
      0.004057035315781832,
      0.0638943538069725,
      -0.009939804673194885,
      0.11078064888715744,
      -0.018798287957906723,
      -0.09463655948638916,
      0.004098288249224424,
      0.03143574297428131,
      -0.0251459963619709,
      -0.0024236615281552076,
      0.0019821508321911097,
      -0.03452495113015175,
      0.02453675866127014,
      0.041433773934841156,
      0.0058603184297680855,
      0.015980076044797897,
      0.05411249399185181,
      -0.008933023549616337,
      -0.0026345395017415285,
      0.04561280086636543,
      0.10780861973762512,
      0.06131121516227722,
      0.03572008013725281,
      -0.06161658465862274,
      0.02161548286676407,
      -0.019109144806861877,
      -0.013879910111427307,
      -0.03679686784744263,
      0.07172591984272003,
      -0.0009045703918673098,
      -0.04074037820100784,
      0.07013954967260361,
      0.041603587567806244,
      0.03037058748304844,
      -0.03357171267271042,
      0.0734463483095169,
      -0.03434433415532112,
      -0.00785920675843954,
      -0.04982665181159973,
      -0.0328599251806736,
      -0.06269347667694092,
      -0.08743888884782791,
      0.030956143513321877,
      0.03419434651732445,
      0.041056402027606964,
      0.018233774229884148,
      0.04501718655228615,
      -0.024751506745815277,
      0.013339316472411156,
      0.04657956585288048,
      -0.037389714270830154,
      0.03367558866739273,
      0.027166208252310753,
      0.010572045110166073,
      0.002622409490868449,
      -0.00483850808814168,
      0.049140699207782745,
      0.07095859199762344,
      -0.044721819460392,
      -0.026443522423505783,
      -0.05002984032034874,
      -0.019761651754379272,
      -0.016399454325437546,
      -0.1132727712392807,
      0.0227508582174778,
      0.008195635862648487,
      -0.006225913763046265,
      -0.00705291610211134,
      -0.004636762198060751,
      -0.015228427946567535,
      0.0245614405721426,
      0.04473712667822838,
      0.036839671432971954,
      0.054571036249399185,
      0.05714446306228638,
      -0.028929317370057106,
      -0.027408331632614136,
      0.05641736090183258,
      0.03125949576497078,
      0.0011008076835423708,
      -0.03215475752949715,
      0.024240288883447647,
      0.023991385474801064,
      0.058838266879320145,
      0.011976361274719238,
      -0.023894522339105606,
      -0.05174767225980759,
      0.07990827411413193,
      0.04197917878627777,
      0.05069580301642418,
      -0.012210613116621971,
      0.07155721634626389,
      0.045573994517326355,
      0.008569575846195221,
      0.057696256786584854,
      0.04187227040529251,
      -0.09780978411436081,
      -0.04794643819332123,
      -0.1114053800702095,
      0.03553062304854393,
      -0.016649803146719933,
      -0.0005634627887047827,
      0.033319540321826935,
      -0.01959950476884842,
      -0.019912846386432648,
      0.03224540129303932,
      -0.034700751304626465,
      -0.015441351570189,
      -0.01969505287706852,
      -0.10282447934150696,
      -0.02339019626379013,
      -0.08697374165058136,
      0.0018726146081462502,
      0.033391404896974564,
      0.0558694563806057,
      -0.09654311090707779,
      0.06555890291929245,
      -0.00688096834346652,
      -0.019822100177407265,
      0.07127667218446732,
      -0.014918112196028233,
      0.04075480252504349,
      -0.024121560156345367,
      -0.03389621898531914,
      -0.05008365213871002,
      0.045612625777721405,
      0.09252548217773438,
      -0.045449886471033096,
      0.023000670596957207,
      0.010982749983668327,
      0.05963974446058273,
      0.03821723908185959,
      -0.025590354576706886,
      0.024051625281572342,
      0.00870522204786539,
      -0.03370027616620064,
      0.01657191477715969,
      -0.10044624656438828,
      -0.07448123395442963,
      0.0845130980014801,
      0.008961379528045654,
      -0.06414560973644257,
      -0.018279660493135452,
      0.018680734559893608,
      -0.012998323887586594,
      0.06505721807479858,
      -0.025085238739848137,
      -0.0293972659856081,
      0.03390755504369736,
      0.01361942756921053,
      0.022639213129878044,
      -0.11687325686216354,
      -0.011281277984380722,
      -0.008724954910576344,
      -0.07987937331199646,
      0.010134255513548851,
      -0.05245611071586609,
      -0.036037493497133255,
      0.023072239011526108,
      -0.026512345299124718,
      -0.042670462280511856,
      0.006507284939289093,
      -0.02825281210243702,
      -0.046156324446201324,
      0.0016310535138472915,
      0.036066267639398575,
      -0.0032041852828115225,
      0.015939535573124886,
      0.021593661978840828,
      0.025280186906456947,
      0.02720431052148342,
      0.059711478650569916,
      -0.04749242216348648,
      0.040630508214235306,
      0.004408692009747028,
      0.0021934385877102613,
      0.03513559326529503,
      0.03298688679933548,
      0.05399065092206001,
      0.02181866392493248,
      -0.039127230644226074,
      0.05098845809698105,
      0.034107036888599396,
      -0.004167236853390932,
      -0.008614844642579556,
      -0.008439508266746998,
      -0.0514359176158905,
      -0.05621045455336571,
      0.02885965257883072,
      0.002849535783752799,
      -0.06612910330295563,
      -0.00545142125338316,
      -0.06927614659070969,
      -0.016601700335741043,
      -0.02125285565853119,
      -0.033064380288124084,
      -0.02064625173807144,
      -0.03324657306075096,
      -0.03902977705001831,
      -0.04383473098278046,
      0.05705071613192558,
      0.10794167220592499,
      -0.030592115595936775,
      -0.054729998111724854,
      0.05518324673175812,
      0.055888425558805466,
      0.008123169653117657,
      0.004182920325547457,
      0.03773466497659683,
      -0.030976058915257454,
      0.00813279952853918,
      -0.049774277955293655,
      -0.03466730937361717,
      -0.01702019013464451,
      -0.0191650390625,
      0.03899393230676651,
      -0.008219012059271336,
      0.04496516287326813,
      -0.02861653082072735,
      -0.009767862036824226,
      -0.008935983292758465,
      0.008291470818221569,
      0.04797537624835968,
      0.014540399424731731,
      0.03680049255490303,
      -0.05843610689043999,
      0.020145952701568604,
      -0.03924105316400528,
      -0.045396219938993454,
      0.03748732805252075,
      -0.07479309290647507,
      -0.03443621098995209,
      -0.050768446177244186,
      0.04816974326968193,
      -0.009636154398322105,
      0.027587490156292915,
      -0.0036096784751862288,
      0.04263044521212578,
      0.0315551832318306,
      -0.041986241936683655,
      -0.02210209146142006,
      -0.06326621025800705,
      -0.0821319967508316,
      0.012256327085196972,
      -0.018934927880764008,
      -0.02058853581547737,
      -0.029048990458250046,
      0.025775546208024025,
      -0.02284453809261322,
      -0.01891324669122696,
      0.03871868550777435,
      -0.050460875034332275,
      0.032815732061862946,
      0.026328757405281067,
      0.03764236718416214,
      -0.028684481978416443,
      -0.03276308998465538,
      0.0010483969235792756,
      0.03542617708444595,
      -0.01864144578576088,
      -0.0041596777737140656,
      -0.06110642850399017,
      -0.02684607356786728,
      -0.008772040717303753,
      0.05706855282187462
    ],
    [
      -0.02814723365008831,
      -0.040114812552928925,
      0.043000172823667526,
      0.036188170313835144,
      -0.01068136841058731,
      -0.05689176917076111,
      -0.001370619866065681,
      -0.04634856432676315,
      0.049353621900081635,
      0.010698115453124046,
      0.050560735166072845,
      0.03112628497183323,
      -0.048317160457372665,
      0.004670649766921997,
      0.04954233020544052,
      0.019036488607525826,
      0.12958736717700958,
      0.014716661535203457,
      -0.013706783764064312,
      0.034589286893606186,
      0.02622493915259838,
      -0.03674694523215294,
      -0.03534591197967529,
      0.029609709978103638,
      0.03388512507081032,
      0.0640651136636734,
      -0.015843819826841354,
      -0.04500191658735275,
      -0.012981261126697063,
      0.011440814472734928,
      -0.00012534156849142164,
      -0.007672098930925131,
      0.00848016794770956,
      0.023542260751128197,
      0.01451131608337164,
      0.01721554808318615,
      -0.024345779791474342,
      0.04539014399051666,
      0.02523334138095379,
      -0.022719426080584526,
      0.012246813625097275,
      0.04746932163834572,
      0.032528072595596313,
      -0.022607890889048576,
      0.026454821228981018,
      0.018894048407673836,
      -0.008748186752200127,
      0.03194967657327652,
      0.00611434830352664,
      0.006419880781322718,
      0.02065395377576351,
      -0.07724672555923462,
      0.06468374282121658,
      0.009163065813481808,
      -0.028409184888005257,
      0.06555412709712982,
      0.05749424919486046,
      0.042848117649555206,
      -0.046069350093603134,
      0.004151533357799053,
      0.04431364685297012,
      -0.006730267312377691,
      -0.01935298554599285,
      0.0126884700730443,
      0.03704278543591499,
      0.029177669435739517,
      0.011162618175148964,
      0.027627814561128616,
      -0.0004755862755700946,
      -0.020549416542053223,
      -0.025425085797905922,
      0.0784720778465271,
      0.06671573966741562,
      0.02248557284474373,
      -0.06327614188194275,
      -0.03883082792162895,
      0.021739261224865913,
      0.08704054355621338,
      0.09339377284049988,
      0.026404479518532753,
      0.0010912114521488547,
      0.03826139122247696,
      -0.012675391510128975,
      0.057939883321523666,
      0.01332379225641489,
      -0.03812169283628464,
      0.039022643119096756,
      -0.01822080835700035,
      -0.016544360667467117,
      0.012983201071619987,
      0.011689434759318829,
      -0.01863604411482811,
      -0.031127767637372017,
      0.07689203321933746,
      -0.015195094980299473,
      0.0025324926245957613,
      -0.03990325331687927,
      -0.03270735219120979,
      -0.0035240876022726297,
      0.037117697298526764,
      0.029444841668009758,
      0.020451834425330162,
      0.07858279347419739,
      -0.016447771340608597,
      -0.009316356852650642,
      0.029020290821790695,
      0.02145962417125702,
      0.012631142511963844,
      0.026883088052272797,
      0.06553618609905243,
      0.007927477359771729,
      0.01534499041736126,
      -0.03319639712572098,
      0.06708650290966034,
      -0.02125927433371544,
      0.05117662996053696,
      0.016448382288217545,
      -0.006875673308968544,
      -0.03235124796628952,
      0.006996918469667435,
      0.035551100969314575,
      -0.0002854440244846046,
      0.009993580169975758,
      -0.007418396417051554,
      -0.04062024503946304,
      0.0012396194506436586,
      -0.02673453278839588,
      -0.023948751389980316,
      -0.004725742619484663,
      0.017436068505048752,
      0.0644034668803215,
      -0.02103106491267681,
      -0.013672643341124058,
      -0.04621652513742447,
      -0.04703343287110329,
      0.0027448460459709167,
      0.018559465184807777,
      -0.01418865006417036,
      -0.03609238564968109,
      -0.05312839522957802,
      0.036463942378759384,
      -0.09892407059669495,
      -0.007980854250490665,
      -0.06835272163152695,
      -0.05000423267483711,
      -0.016658976674079895,
      -0.034009482711553574,
      0.011703966185450554,
      0.015412100590765476,
      -0.007337640970945358,
      0.02576572634279728,
      -0.07870113849639893,
      0.010426212102174759,
      0.035529639571905136,
      -0.02449696697294712,
      0.027043255046010017,
      0.04867062717676163,
      -0.014838277362287045,
      -0.03916891664266586,
      -0.005083064548671246,
      0.03331628069281578,
      -0.03277529776096344,
      0.07946132123470306,
      0.008953251875936985,
      0.12640489637851715,
      -0.003465800080448389,
      -0.03713817894458771,
      0.015960121527314186,
      -0.08805329352617264,
      -0.024184202775359154,
      -0.057399630546569824,
      -0.08545708656311035,
      0.01138293743133545,
      0.00789960939437151,
      0.04722295701503754,
      0.06380514800548553,
      0.03877714276313782,
      0.02306508459150791,
      0.042118463665246964,
      -0.02950211986899376,
      -0.02696998044848442,
      -0.037266749888658524,
      0.05270915478467941,
      -0.05938921496272087,
      0.01593020372092724,
      -0.005395511165261269,
      0.016501687467098236,
      -0.041646212339401245,
      0.018565094098448753,
      -0.012748988345265388,
      -0.10328511148691177,
      0.011717724613845348,
      0.03796900808811188,
      -0.021013924852013588,
      0.00010750172077678144,
      -0.06370437145233154,
      0.05839330703020096,
      -0.00681262044236064,
      0.073513463139534,
      0.05021851509809494,
      -0.059050820767879486,
      0.05212213844060898,
      0.04705838859081268,
      -0.05999999865889549,
      -0.06958974897861481,
      -0.08497153967618942,
      0.05002562329173088,
      -0.04724351316690445,
      -0.013596788980066776,
      -0.049884919077157974,
      -0.003915864508599043,
      -0.018783997744321823,
      -0.003110655816271901,
      -0.011756645515561104,
      -0.01053511444479227,
      0.01741679199039936,
      0.06261245906352997,
      -0.09020950645208359,
      -0.01856142096221447,
      0.003930993378162384,
      0.011702039279043674,
      0.03127308562397957,
      0.07118088006973267,
      -0.028772590681910515,
      -0.044990286231040955,
      0.0018795154755935073,
      0.04105621203780174,
      0.011966203339397907,
      0.03494216501712799,
      0.03128445893526077,
      -0.039261430501937866,
      -0.041979145258665085,
      -0.003507705871015787,
      0.04717200621962547,
      0.005355932749807835,
      -0.02271392196416855,
      0.0031379747670143843,
      -0.007864557206630707,
      0.012793811038136482,
      -0.017497098073363304,
      -0.028753003105521202,
      -0.004406379070132971,
      -0.04301229119300842,
      0.022796951234340668,
      0.035768773406744,
      -0.023494254797697067,
      -0.09311258792877197,
      0.01690671592950821,
      -0.016568848863244057,
      -0.06314611434936523,
      -0.019267776980996132,
      -0.02061440609395504,
      -0.011574070900678635,
      0.07965442538261414,
      0.03958219289779663,
      -0.062016382813453674,
      0.026794016361236572,
      -0.023109018802642822,
      0.02700251154601574,
      -0.018330398947000504,
      0.07312162965536118,
      -0.11782015860080719,
      0.010355142876505852,
      0.01723293773829937,
      0.010606674477458,
      0.020854972302913666,
      0.005312311928719282,
      0.004267449490725994,
      -0.03267567604780197,
      0.024029776453971863,
      -0.007362913805991411,
      0.006818419322371483,
      -0.005608610808849335,
      0.027935080230236053,
      -0.029232827946543694,
      -0.007474221289157867,
      0.014103427529335022,
      -0.025159182026982307,
      -0.010836528614163399,
      0.016158072277903557,
      0.03391888365149498,
      -0.013467189855873585,
      -0.031942036002874374,
      -0.007677835412323475,
      0.0012610199628397822,
      -0.015782341361045837,
      0.017059119418263435,
      0.038689229637384415,
      -0.010999416932463646,
      0.009119047783315182,
      0.07486693561077118,
      0.0167474914342165,
      0.09504818171262741,
      -0.05644926056265831,
      0.04811840131878853,
      0.011484065093100071,
      0.015487550757825375,
      0.10412267595529556,
      0.01973739080131054,
      0.03843213990330696,
      0.005556759890168905,
      0.01174567174166441,
      0.043967485427856445,
      0.05725543573498726,
      -0.02584204077720642,
      0.006975689437240362,
      0.010112728923559189,
      -0.016294380649924278,
      0.07621520012617111,
      0.02901836298406124,
      0.06815385073423386,
      -0.040039923042058945,
      0.05803556367754936,
      -0.026650050655007362,
      0.030350668355822563,
      0.04836088791489601,
      0.0330292172729969,
      0.06207815930247307,
      -0.012174363248050213,
      -0.03167090192437172,
      0.06580957025289536,
      -0.0019235331565141678,
      0.010493013076484203,
      -0.04892916604876518,
      0.018216118216514587,
      -0.03519497811794281,
      -0.00037649134173989296,
      0.0531141571700573,
      0.034497883170843124,
      0.0951889380812645,
      0.041417669504880905,
      -0.011350953951478004,
      0.0012360680848360062,
      -0.005213381256908178,
      -0.053854573518037796,
      -0.03962146118283272,
      0.05731520429253578,
      -0.016423074528574944,
      -0.022347891703248024,
      -0.016851158812642097,
      0.05615472421050072,
      0.0025015936698764563,
      -0.012698289938271046,
      0.013370362110435963,
      -0.01854538545012474,
      0.04921138659119606,
      0.01588878594338894,
      -0.043634600937366486,
      -0.017476854845881462,
      0.02459695003926754,
      -0.03839339688420296,
      0.01012431550770998,
      0.0011459573870524764,
      -0.0326288603246212,
      -0.05394074693322182,
      -0.012157202698290348,
      -0.04461739957332611,
      -0.047549277544021606,
      -0.12469758093357086,
      0.03683057427406311,
      -0.03744480386376381,
      0.012416207231581211,
      0.05321662127971649,
      0.06541009247303009,
      0.04862266033887863,
      0.06039642542600632,
      0.05609411373734474,
      -0.06329703330993652,
      -0.004232395906001329,
      -0.04544118046760559,
      0.007758483290672302,
      0.024987610056996346,
      0.04004232585430145,
      -0.03297220543026924,
      -0.056975968182086945,
      0.06946367770433426,
      0.012377447448670864,
      -0.01571521908044815,
      0.006731848232448101,
      -0.01686536706984043,
      0.06513247638940811,
      -0.027557596564292908,
      -0.04941478371620178,
      0.014973333105444908,
      0.049503833055496216,
      0.03645029664039612,
      0.03446934372186661,
      0.01602720096707344,
      0.05922544375061989,
      0.022104980424046516,
      0.006826404482126236,
      0.07877500355243683,
      0.011006545275449753,
      0.0021875803358852863,
      0.09318271279335022,
      -0.04604141786694527,
      0.030600251629948616,
      0.05716269463300705,
      0.07365307956933975,
      0.01430275198072195,
      0.0769781619310379,
      0.06840803474187851,
      0.0170868169516325,
      0.007744882721453905,
      -0.01774977520108223,
      -0.08148390799760818,
      -0.050584740936756134,
      -0.0738552138209343,
      0.06845381110906601,
      0.013643641956150532,
      -0.0016017711022868752,
      0.04123916104435921,
      0.03558828681707382,
      -0.033217981457710266,
      -0.002438443945720792,
      0.027438946068286896,
      0.010734250769019127,
      0.014085287228226662,
      -0.0504436194896698,
      -0.10448426753282547,
      0.017793232575058937,
      -0.01511918380856514,
      0.057372741401195526,
      0.034792110323905945,
      0.022819504141807556,
      0.07041332870721817,
      -0.0001000768315861933,
      -0.009322536177933216,
      0.05174659565091133,
      -0.055420856922864914,
      -0.02635798417031765,
      0.026895228773355484,
      -0.020989472046494484,
      -0.01728595793247223,
      0.028267763555049896,
      0.03142578899860382,
      0.011111030355095863,
      -0.04421304911375046,
      -0.041377533227205276,
      -0.09683888405561447,
      -0.051244691014289856,
      0.014889698475599289,
      0.050195373594760895,
      0.05683271959424019,
      -0.015516901388764381,
      -0.019767900928854942,
      0.0600878968834877,
      -0.06646885722875595,
      -0.07881460338830948,
      -0.03910847380757332,
      -0.050706785172224045,
      0.007179854437708855,
      0.052382808178663254,
      0.012876608408987522,
      0.013307451270520687,
      0.06335105746984482,
      -0.0010128953726962209,
      -0.03166170045733452,
      -0.0040990570560097694,
      -0.11367125064134598,
      0.023487068712711334,
      0.012160287238657475,
      0.06979691982269287,
      -0.018494626507163048,
      0.08151160925626755,
      0.00848168134689331,
      0.004020388238132,
      0.024895470589399338,
      -0.08038558065891266,
      -0.003902523312717676,
      0.00845908373594284,
      0.029178975149989128,
      -0.05293777212500572,
      -0.05199304223060608,
      0.0072235227562487125,
      0.05165446922183037,
      0.028834382072091103,
      -0.004902744200080633,
      -0.02526012621819973,
      -0.04603816196322441,
      -0.05328461155295372,
      -0.054859377443790436,
      -0.06266317516565323,
      -0.006841339636594057,
      0.0028823893517255783,
      -0.025138169527053833,
      0.015551906079053879,
      0.10909868776798248,
      -0.026219701394438744,
      -0.02806016243994236,
      -0.007898570969700813,
      0.13683763146400452,
      0.00912252627313137,
      -0.05005720257759094,
      -0.086194708943367,
      -0.010777479968965054,
      -0.07313765585422516,
      -0.0408361591398716,
      -0.04564553126692772,
      -0.019374661147594452,
      0.013740213587880135,
      0.04218173399567604,
      0.01366789173334837
    ],
    [
      -0.009099972434341908,
      0.01567060500383377,
      0.08843643218278885,
      0.08106924593448639,
      -0.03352966904640198,
      -0.05949034169316292,
      -0.07239065319299698,
      0.04442961886525154,
      0.05989892780780792,
      0.018139755353331566,
      0.03404766693711281,
      -0.04031350836157799,
      0.021732747554779053,
      0.013575835153460503,
      -0.052605096250772476,
      0.04012281447649002,
      0.08823230862617493,
      0.03413262590765953,
      -0.00691677862778306,
      -0.03890504315495491,
      -0.022867711260914803,
      -0.02284645289182663,
      0.04208028316497803,
      0.019644631072878838,
      -0.0558362752199173,
      0.011976717039942741,
      -0.0925300121307373,
      0.07929037511348724,
      -0.0379447266459465,
      -0.0447494238615036,
      -0.05764314532279968,
      -0.06892932206392288,
      -0.019516564905643463,
      -0.007909969426691532,
      -0.003993517719209194,
      -0.03739187866449356,
      -0.04222746565937996,
      0.08180702477693558,
      -0.008276203647255898,
      0.019343161955475807,
      -0.04650050774216652,
      -0.014467204920947552,
      -0.03577107563614845,
      -0.03778327256441116,
      -0.0017047395231202245,
      0.021920060738921165,
      0.024915074929594994,
      0.01908346824347973,
      -0.014144430868327618,
      0.030955761671066284,
      0.020045990124344826,
      0.01454728189855814,
      0.06969602406024933,
      0.040381018072366714,
      -0.06525225192308426,
      -0.02035006321966648,
      0.060863982886075974,
      0.06133125722408295,
      0.03744974359869957,
      0.007123851682990789,
      -0.10328426212072372,
      -0.03784240037202835,
      0.034130584448575974,
      0.02303892932832241,
      0.05384042486548424,
      -0.047238513827323914,
      -0.030916845425963402,
      0.04379117116332054,
      0.042253896594047546,
      0.05442783981561661,
      0.0425468385219574,
      0.013863842003047466,
      0.10527635365724564,
      -0.06290121376514435,
      -0.02511788159608841,
      -0.0019434671849012375,
      0.07179316878318787,
      -0.031443119049072266,
      0.006869461387395859,
      0.0033872381318360567,
      0.023673411458730698,
      -0.019464712589979172,
      0.060027725994586945,
      -0.011852025985717773,
      0.06203248351812363,
      0.05364397168159485,
      0.019977733492851257,
      0.06187278404831886,
      0.05557816103100777,
      0.024799058213829994,
      0.02035346068441868,
      -0.10660337656736374,
      -0.03157768025994301,
      0.007478903513401747,
      0.03240751102566719,
      -0.08041833341121674,
      -0.0007331157685257494,
      -0.025808360427618027,
      0.0684046670794487,
      0.029548989608883858,
      0.0023424699902534485,
      -0.05730965733528137,
      -0.009314230643212795,
      0.02587972581386566,
      -0.09254847466945648,
      0.03021322563290596,
      0.04164685681462288,
      0.03545679897069931,
      0.08264973014593124,
      -0.0076920953579247,
      0.07997754961252213,
      -0.060810670256614685,
      -0.04578711465001106,
      -0.021166764199733734,
      0.00013296681572683156,
      -0.06022198125720024,
      0.021601472049951553,
      -0.005521808750927448,
      -0.04929890111088753,
      -0.021144984290003777,
      0.05563079193234444,
      0.07497167587280273,
      -0.010843283496797085,
      0.04930931329727173,
      -0.03637897968292236,
      0.0344780832529068,
      -0.059255655854940414,
      -0.08257988840341568,
      -0.031461335718631744,
      0.020322799682617188,
      -0.014943179674446583,
      0.04274185374379158,
      0.05331971123814583,
      -0.06398185342550278,
      0.04947768896818161,
      -0.016402630135416985,
      0.036876752972602844,
      -0.008906625211238861,
      -0.0012344670249149203,
      0.01742437481880188,
      0.0021187053062021732,
      0.051229074597358704,
      0.027688022702932358,
      -0.033128704875707626,
      0.03075324185192585,
      -0.006064114160835743,
      0.0663776770234108,
      0.016815073788166046,
      0.02932514064013958,
      0.013672024011611938,
      -0.00451021920889616,
      -0.06582573056221008,
      -0.02944904752075672,
      -0.024332640692591667,
      0.07494132965803146,
      0.048325806856155396,
      0.012424012646079063,
      -0.05094750598073006,
      -0.002025561174377799,
      -0.03489549830555916,
      -0.00042026632581837475,
      0.06111176684498787,
      -0.0014690369134768844,
      -0.0402374230325222,
      -0.03816543146967888,
      -0.05775071680545807,
      -0.01181774865835905,
      -0.03394191339612007,
      0.018159545958042145,
      0.0050257230177521706,
      0.02583436854183674,
      -0.04620281979441643,
      0.014478127472102642,
      -0.002612171694636345,
      0.03977328538894653,
      -0.04720241576433182,
      0.1541527658700943,
      0.009060408920049667,
      0.006728084292262793,
      0.0231249388307333,
      -0.02916080318391323,
      -0.040176860988140106,
      -0.035665594041347504,
      0.1028345376253128,
      0.03182582929730415,
      -0.00447984179481864,
      0.024540072306990623,
      0.011379094794392586,
      0.10720942169427872,
      0.026308448985219002,
      0.05874689295887947,
      -0.03691229224205017,
      0.09600777924060822,
      0.03821179270744324,
      0.025992540642619133,
      -0.0021831535268574953,
      0.04713986814022064,
      -0.034569818526506424,
      -0.01954476535320282,
      0.10812244564294815,
      -0.03348375856876373,
      -0.011789409443736076,
      -0.09230522811412811,
      -0.028023602440953255,
      -0.01065069530159235,
      0.00039040239062160254,
      0.03272373229265213,
      -0.04006987065076828,
      0.03622853010892868,
      -0.05952728912234306,
      -0.050954874604940414,
      0.056199733167886734,
      -0.037008754909038544,
      -0.041467417031526566,
      0.03900967910885811,
      -0.07909587025642395,
      0.013232585974037647,
      -0.03815978020429611,
      -0.03678549453616142,
      -0.02604551799595356,
      0.021254390478134155,
      0.0014252662658691406,
      0.06672463566064835,
      0.0849112868309021,
      -0.008016995154321194,
      -0.0030405500438064337,
      0.09536468982696533,
      0.0670776218175888,
      0.057543329894542694,
      0.0030907574109733105,
      -0.006674569565802813,
      -0.01700662449002266,
      0.03361895680427551,
      -0.055066440254449844,
      0.008657274767756462,
      -0.004466651473194361,
      0.03568137064576149,
      0.015208429656922817,
      -0.008958053775131702,
      -0.05341944098472595,
      -0.01060014870017767,
      0.020983099937438965,
      0.025545788928866386,
      -0.013232872821390629,
      0.03042612224817276,
      -0.034882187843322754,
      0.07196158170700073,
      0.02518446370959282,
      -0.013590527698397636,
      -0.003136059734970331,
      0.024922890588641167,
      -0.02946159616112709,
      0.0726432353258133,
      0.037607599049806595,
      -0.02301466278731823,
      0.025452470406889915,
      0.041332557797431946,
      0.033323563635349274,
      -0.03338312357664108,
      -0.055909689515829086,
      0.02610253170132637,
      -0.00020790862618014216,
      -0.054448626935482025,
      0.02610066533088684,
      -0.0297718346118927,
      0.029674626886844635,
      -0.015165578573942184,
      -0.05490308627486229,
      -0.06710962951183319,
      -0.03447983041405678,
      0.01911989413201809,
      0.010973777621984482,
      0.010875150561332703,
      0.004947056528180838,
      0.05559413135051727,
      0.03981098160147667,
      -0.05403786897659302,
      -0.06844057142734528,
      -0.03015761449933052,
      0.010853173211216927,
      -0.012805121019482613,
      0.017524054273962975,
      -0.03316665440797806,
      0.08637288957834244,
      0.008278979919850826,
      0.005706150084733963,
      0.006182309240102768,
      0.00760618643835187,
      0.044500768184661865,
      0.04636344686150551,
      0.08980822563171387,
      0.07530295848846436,
      0.08690597116947174,
      0.009426063857972622,
      0.012831569649279118,
      0.03515123203396797,
      0.011203224770724773,
      0.058601051568984985,
      -0.025553060695528984,
      -0.04475223273038864,
      0.09636438637971878,
      0.027275944128632545,
      0.030832400545477867,
      -0.016341770067811012,
      -0.03366207703948021,
      -0.007424482610076666,
      0.06143515184521675,
      -0.036219071596860886,
      -0.06023184955120087,
      0.03994422033429146,
      0.08370770514011383,
      -0.04087916761636734,
      0.06919971853494644,
      -0.049111492931842804,
      0.043742287904024124,
      0.05445023626089096,
      0.009899401105940342,
      -0.026435011997818947,
      0.024836884811520576,
      0.049608014523983,
      0.048994798213243484,
      0.0022507610265165567,
      -0.037754759192466736,
      0.017855873331427574,
      0.033506929874420166,
      -0.05046427994966507,
      -0.017381910234689713,
      -0.01700403541326523,
      -0.042904939502477646,
      -0.13183514773845673,
      0.08704834431409836,
      -0.033177491277456284,
      -0.09222366660833359,
      0.018549717962741852,
      0.017401238903403282,
      0.020159007981419563,
      -0.04636030271649361,
      0.00017435870540793985,
      0.028026998043060303,
      0.01473226211965084,
      -0.07391799986362457,
      -0.030150219798088074,
      0.02491159364581108,
      0.0073658195324242115,
      -0.011563710868358612,
      -0.041615717113018036,
      0.088911272585392,
      -0.015708908438682556,
      0.02761266380548477,
      -0.07859155535697937,
      -0.03447489067912102,
      0.010946877300739288,
      -0.06886380165815353,
      0.05087284743785858,
      0.07281463593244553,
      -0.008793470449745655,
      0.038527656346559525,
      -0.03735378012061119,
      -0.0020193285308778286,
      0.031088752672076225,
      -0.02360539883375168,
      -0.01178879477083683,
      -0.04640519618988037,
      0.057626862078905106,
      0.028225405141711235,
      0.04782775789499283,
      0.021535145118832588,
      -0.09241349250078201,
      0.020677613094449043,
      -0.02644735760986805,
      0.0023882524110376835,
      -0.036684103310108185,
      0.02468728832900524,
      0.07456514984369278,
      -0.016453539952635765,
      0.0187524426728487,
      0.018396586179733276,
      0.008887438103556633,
      -0.07957743853330612,
      -0.06274966895580292,
      -0.0531010776758194,
      -0.0016844547353684902,
      -0.007384615018963814,
      -0.03182769566774368,
      0.01740330643951893,
      -0.01154481153935194,
      6.78874712320976e-05,
      0.09918296337127686,
      -0.019912032410502434,
      -0.008676160126924515,
      0.014353576116263866,
      0.026365622878074646,
      -0.023184748366475105,
      0.030805600807070732,
      0.055506981909275055,
      0.03934852406382561,
      0.03756869211792946,
      -0.00579215819016099,
      0.05480305477976799,
      -0.06423398107290268,
      -0.05586238577961922,
      -0.11851860582828522,
      -0.018087664619088173,
      -0.06933974474668503,
      0.043718062341213226,
      -0.019007142633199692,
      -0.022859910503029823,
      -0.028578998520970345,
      -0.0028933954890817404,
      0.001887530437670648,
      0.004050084855407476,
      0.06805181503295898,
      -0.09440384805202484,
      -0.06319143623113632,
      0.023145675659179688,
      -0.047825537621974945,
      0.021194396540522575,
      0.05537756159901619,
      0.02080385573208332,
      -0.025382982566952705,
      0.02713642083108425,
      -0.029265377670526505,
      -0.0032000376377254725,
      -0.034180838614702225,
      -0.034375887364149094,
      -0.06182028353214264,
      -0.05555916577577591,
      0.007422052323818207,
      0.02539803832769394,
      -0.060164038091897964,
      0.015808068215847015,
      -0.04748198017477989,
      0.005099424626678228,
      0.04905962198972702,
      0.017983805388212204,
      0.10012416541576385,
      0.04725278541445732,
      0.0067307897843420506,
      0.017271464690566063,
      -0.054367758333683014,
      0.004118780139833689,
      0.03520641848444939,
      -0.011960434727370739,
      0.06062042713165283,
      -0.012386023998260498,
      -0.07585253566503525,
      -0.006876982748508453,
      0.01033057551831007,
      -0.06705566495656967,
      0.034621380269527435,
      0.03282135725021362,
      -0.021300407126545906,
      -0.038826774805784225,
      0.022891107946634293,
      0.037379346787929535,
      -0.052674368023872375,
      -0.011157327331602573,
      -0.016417132690548897,
      -0.06330369412899017,
      0.04495810717344284,
      0.02742995135486126,
      0.0015269082505255938,
      -0.03714033216238022,
      -0.04632125422358513,
      -0.022032970562577248,
      0.01613077148795128,
      -0.04856099188327789,
      0.006069958209991455,
      0.0031277628149837255,
      -0.021886732429265976,
      0.040007904171943665,
      0.043251968920230865,
      -0.017464010044932365,
      0.012704910710453987,
      0.06572972983121872,
      -0.06479880213737488,
      0.006830503698438406,
      0.0487099327147007,
      -0.05723876878619194,
      0.03663287311792374,
      -0.08475828170776367,
      -0.03451027348637581,
      -0.0963626503944397,
      -0.027088718488812447,
      -0.04470588266849518,
      -0.058189768344163895,
      -0.029029633849859238,
      -0.01619693450629711,
      -0.052726905792951584,
      0.021928511559963226,
      0.023347174748778343,
      -0.02389618568122387,
      -0.01993250474333763,
      -0.01910235360264778,
      -0.021274534985423088,
      0.03394456207752228,
      -0.03250475972890854,
      0.03410167619585991,
      0.04240920767188072,
      0.02442888729274273,
      0.010590151883661747,
      -0.00823040958493948,
      -0.01436785701662302
    ],
    [
      -0.02461632527410984,
      -0.01622183434665203,
      -0.047972530126571655,
      0.033460959792137146,
      -0.008361443877220154,
      -0.03095228411257267,
      0.01386379823088646,
      -0.02303687483072281,
      0.021888339892029762,
      -0.05692111700773239,
      -0.10526290535926819,
      -0.023901721462607384,
      0.005819026846438646,
      0.09251777082681656,
      -0.040707048028707504,
      -0.002631882904097438,
      0.051559899002313614,
      0.034568119794130325,
      0.06827898323535919,
      -0.06543534249067307,
      -0.00032953781192190945,
      -0.004166674800217152,
      -0.009791085496544838,
      -0.06592229753732681,
      0.06147081404924393,
      0.013665041886270046,
      -0.025179138407111168,
      0.046234238892793655,
      0.002867195289582014,
      0.01649174839258194,
      0.04902411997318268,
      -0.029436107724905014,
      -0.0504135936498642,
      -0.12497343122959137,
      0.024653350934386253,
      -0.0294586643576622,
      0.07307500392198563,
      0.06786773353815079,
      -0.0022916793823242188,
      0.030992606654763222,
      -0.0014331075362861156,
      -0.023621736094355583,
      -0.02902057394385338,
      -0.0064483447931706905,
      -0.05623799189925194,
      -0.017729543149471283,
      -0.001966277603060007,
      -0.008639712817966938,
      0.03044474497437477,
      0.031409017741680145,
      -0.00825696624815464,
      0.02953905053436756,
      -0.05189017951488495,
      -0.010996411554515362,
      -0.009177242405712605,
      -0.04762769863009453,
      0.06744325160980225,
      0.07369616627693176,
      -0.02180204540491104,
      0.029085099697113037,
      -0.09352456778287888,
      -0.009356752969324589,
      -0.013428661972284317,
      -0.02420090138912201,
      -0.04957294836640358,
      -0.06054412201046944,
      0.017729032784700394,
      -0.14389079809188843,
      0.004120252560824156,
      -0.006031527183949947,
      -0.00269676954485476,
      0.08207586407661438,
      0.036191243678331375,
      0.01623249053955078,
      -0.02429918758571148,
      0.001741688814945519,
      0.019989922642707825,
      -0.0053393105044960976,
      0.0477781668305397,
      0.01335319597274065,
      -0.1560453176498413,
      -0.017487363889813423,
      0.11599262058734894,
      0.008589126169681549,
      -0.005965519696474075,
      -0.03883460909128189,
      -0.0773962214589119,
      0.041377030313014984,
      0.006975806783884764,
      0.014656368643045425,
      0.01346715446561575,
      0.0074800834991037846,
      0.036790817975997925,
      -0.03140505775809288,
      -0.010237956419587135,
      -0.02249922603368759,
      0.043667983263731,
      0.0481816902756691,
      -0.036878328770399094,
      0.007026465609669685,
      -0.004122852347791195,
      -0.0507284551858902,
      0.026163574308156967,
      0.005296558141708374,
      0.08220939338207245,
      0.006554108113050461,
      -0.019593186676502228,
      0.05815170705318451,
      0.02796061523258686,
      0.03049333207309246,
      -0.02922550030052662,
      -0.008130393922328949,
      0.10990965366363525,
      0.07068556547164917,
      -0.026678314432501793,
      -0.02012532576918602,
      -0.03442959859967232,
      -0.057124972343444824,
      0.07738863676786423,
      -0.00502897659316659,
      0.014897020533680916,
      -0.037632495164871216,
      -0.03917503356933594,
      0.10651669651269913,
      0.12549768388271332,
      0.039034873247146606,
      -0.01606730930507183,
      0.05976450443267822,
      0.0036086211912333965,
      0.02588590234518051,
      0.007793048396706581,
      -0.034579042345285416,
      -0.06423203647136688,
      0.07759671658277512,
      0.005166972056031227,
      -0.04502465948462486,
      0.012279612012207508,
      0.007621739991009235,
      -0.0108038866892457,
      -0.03052630089223385,
      0.04950840771198273,
      0.0014084249269217253,
      -0.06920322775840759,
      -0.025545058771967888,
      0.0007432381389662623,
      -0.015297625213861465,
      0.03020814061164856,
      -0.062326375395059586,
      -0.07780157029628754,
      0.06340957432985306,
      -0.015496939420700073,
      -0.06982241570949554,
      -0.02270759828388691,
      -0.027024997398257256,
      -0.007980113849043846,
      -0.048708368092775345,
      0.09868679195642471,
      -0.048871006816625595,
      -0.019809624180197716,
      0.005835274700075388,
      -0.027035659179091454,
      -0.002355261705815792,
      -0.02349920943379402,
      0.016943572089076042,
      0.001949146157130599,
      -0.018106086179614067,
      0.03960788622498512,
      -0.009174601174890995,
      0.020775998011231422,
      0.05074227228760719,
      -0.005277072545140982,
      0.024972621351480484,
      -0.01221884973347187,
      0.07990903407335281,
      -0.023662205785512924,
      -0.07839469611644745,
      0.01847921498119831,
      0.050970714539289474,
      0.09804695844650269,
      -0.01674296334385872,
      -0.009670129977166653,
      0.04904932901263237,
      0.002464577555656433,
      0.02427840419113636,
      0.022425983101129532,
      -0.03356008231639862,
      -0.033950015902519226,
      -0.026573816314339638,
      0.03941337764263153,
      0.05316663160920143,
      0.07224975526332855,
      0.017422709614038467,
      0.08584290742874146,
      0.05414372310042381,
      -0.02322792261838913,
      0.014175010845065117,
      -0.08630742132663727,
      0.02497928775846958,
      0.04435263201594353,
      0.052165813744068146,
      0.01784539595246315,
      -0.02876151166856289,
      0.009388801641762257,
      -0.017749575898051262,
      -0.043415751308202744,
      0.01245854701846838,
      -0.014583969488739967,
      0.06314200162887573,
      -0.026688985526561737,
      0.00041471095755696297,
      -0.035933718085289,
      0.0030703525990247726,
      0.057288575917482376,
      0.010097886435687542,
      0.09330100566148758,
      0.008045583963394165,
      0.022778384387493134,
      0.02552041783928871,
      0.02673175372183323,
      -0.008952843956649303,
      0.037321630865335464,
      0.07371360063552856,
      0.05466720834374428,
      0.015095659531652927,
      -0.02890748903155327,
      -0.005760271567851305,
      -0.02053818292915821,
      -0.018897462636232376,
      0.014225604943931103,
      0.04319602623581886,
      0.011272822506725788,
      -0.0020787741523236036,
      -0.009725921787321568,
      -0.008157272823154926,
      0.017633091658353806,
      -0.0092580271884799,
      0.03877456113696098,
      -0.005754406563937664,
      -0.01959739811718464,
      0.004068676382303238,
      0.03951362520456314,
      0.015271429903805256,
      0.00950653851032257,
      0.04944588616490364,
      0.09504970908164978,
      0.03166409581899643,
      -0.0005544230225495994,
      -0.06573787331581116,
      -0.024012891575694084,
      -0.00926614087074995,
      0.05782013013958931,
      -0.011024166829884052,
      -0.026202311739325523,
      -0.09847719967365265,
      -0.007127243559807539,
      0.035895317792892456,
      -0.0031645833514630795,
      0.019794611260294914,
      0.008302247151732445,
      0.0822567269206047,
      0.09453393518924713,
      -0.08951699733734131,
      -0.03942039608955383,
      0.00525880279019475,
      -0.0006211862782947719,
      -0.053123004734516144,
      -0.012562157586216927,
      0.09572549909353256,
      -0.06827288866043091,
      0.05232265591621399,
      -0.07020056247711182,
      -0.02698960155248642,
      -0.0016860595205798745,
      0.01148068718612194,
      -0.089678555727005,
      -0.03875422105193138,
      -0.033043816685676575,
      0.0440988689661026,
      0.04636083170771599,
      -0.06758143752813339,
      0.022720957174897194,
      0.031933706253767014,
      -0.08959377557039261,
      -0.0461370013654232,
      -0.059063639491796494,
      -0.015815747901797295,
      0.014992037788033485,
      -0.029733160510659218,
      -0.00795990601181984,
      0.04600581154227257,
      -0.03040267713367939,
      -0.010686319321393967,
      0.015730934217572212,
      -0.03664363920688629,
      0.01295672357082367,
      -0.031061926856637,
      -0.005385070573538542,
      -0.0010901468340307474,
      0.018512364476919174,
      0.003955777268856764,
      -0.0008449692977592349,
      0.045583631843328476,
      0.03219495713710785,
      -0.006262855138629675,
      0.02739994414150715,
      -0.005021801684051752,
      0.007964414544403553,
      -0.014284797012805939,
      0.047279536724090576,
      -0.0038190169725567102,
      0.019707342609763145,
      0.005186355207115412,
      0.07206617295742035,
      0.010902179405093193,
      0.07292737066745758,
      0.01893727295100689,
      0.02619176171720028,
      -0.07390394061803818,
      -0.026437629014253616,
      -0.032750874757766724,
      -0.028080573305487633,
      0.05501793697476387,
      0.014101829379796982,
      0.0029690111987292767,
      -0.0006162699428386986,
      -0.013069200329482555,
      0.020326891914010048,
      0.023793725296854973,
      -0.024714427068829536,
      0.02956344187259674,
      -0.01723737269639969,
      0.07677730917930603,
      0.034880466759204865,
      -0.032000862061977386,
      0.02912125177681446,
      -0.058583810925483704,
      0.0038216132670640945,
      -0.022883590310811996,
      -0.03414233773946762,
      0.044106580317020416,
      0.04250338673591614,
      0.05040260776877403,
      -0.05927414447069168,
      -0.0757085531949997,
      -0.038264647126197815,
      0.058554768562316895,
      0.033806681632995605,
      0.057410843670368195,
      -0.051964569836854935,
      -0.015241959132254124,
      -0.05356486514210701,
      -0.06482487916946411,
      0.06586539000272751,
      0.04157167673110962,
      -0.07301114499568939,
      0.04071278125047684,
      -0.02336142584681511,
      0.047848764806985855,
      -0.04224197939038277,
      -0.010863024741411209,
      -0.003451238153502345,
      0.09147235751152039,
      0.10242564976215363,
      0.01714097522199154,
      0.015263467095792294,
      -0.02098153904080391,
      0.0018485547043383121,
      0.010547969490289688,
      -0.012722699902951717,
      -0.077809639275074,
      0.012557519599795341,
      0.03926900029182434,
      0.006606107112020254,
      0.018393229693174362,
      -0.02456643618643284,
      0.047801706939935684,
      0.03519638627767563,
      0.08367179334163666,
      -0.044515661895275116,
      -0.015570046380162239,
      0.05916059762239456,
      0.016188358888030052,
      -0.013540358282625675,
      0.0013718511909246445,
      0.022687802091240883,
      -0.00809930544346571,
      -0.02253863960504532,
      0.053359709680080414,
      -0.037313155829906464,
      -0.020649148151278496,
      0.0966440737247467,
      0.01944258250296116,
      -0.048523854464292526,
      -0.033565375953912735,
      0.01791197806596756,
      0.004288257099688053,
      0.008626781404018402,
      -0.011669855564832687,
      0.004525329917669296,
      0.04586968570947647,
      -0.003950662910938263,
      -0.05846293270587921,
      -0.004673942923545837,
      0.02738909237086773,
      0.011288860812783241,
      -0.03844758868217468,
      -0.012326535768806934,
      0.07359443604946136,
      -0.057734742760658264,
      -0.07192949950695038,
      0.05187038704752922,
      0.027926374226808548,
      0.0704096332192421,
      0.0019838621374219656,
      0.02591756172478199,
      0.05897299572825432,
      0.009482491761446,
      0.0071581825613975525,
      0.046859487891197205,
      0.031113985925912857,
      -0.03982742875814438,
      0.13835254311561584,
      -0.025586487725377083,
      0.04021547734737396,
      0.009103117510676384,
      -0.011859673075377941,
      0.0012498496798798442,
      0.04263006150722504,
      -0.01542756613343954,
      0.07651337236166,
      0.09693612903356552,
      0.0085257887840271,
      0.0007519442006014287,
      0.08156345784664154,
      0.05929902195930481,
      -0.03389580920338631,
      0.04267832636833191,
      -0.051289986819028854,
      -0.047726184129714966,
      0.025809872895479202,
      -0.08934988081455231,
      -0.03782540187239647,
      0.09164943546056747,
      5.632238389807753e-05,
      -0.04598980024456978,
      -0.026088852435350418,
      -0.03667600080370903,
      -0.04337554797530174,
      -0.03783515468239784,
      0.0766209214925766,
      -0.0050219283439219,
      -0.08246037364006042,
      -0.01057332381606102,
      0.053833477199077606,
      -0.06376347690820694,
      0.058086708188056946,
      0.00054645084310323,
      0.012448074296116829,
      0.027132228016853333,
      0.0017327036475762725,
      -0.09570582211017609,
      -0.0441555418074131,
      0.042682450264692307,
      -0.05067779868841171,
      -0.0271003395318985,
      0.02261575125157833,
      -0.04379323869943619,
      0.025707876309752464,
      0.08119495958089828,
      -0.0443565770983696,
      -0.055516235530376434,
      -0.01809587888419628,
      0.002704488579183817,
      0.0011584635358303785,
      -0.029515046626329422,
      0.00884854793548584,
      0.02417627163231373,
      0.011964351870119572,
      -0.0437430813908577,
      0.10498078912496567,
      -0.02423408254981041,
      -0.04363856464624405,
      0.03681407868862152,
      0.10188805311918259,
      -0.016758356243371964,
      0.03659725934267044,
      0.06353524327278137,
      0.02634560689330101,
      0.000258894229773432,
      -0.022109879180788994,
      -0.006613288074731827,
      0.0670047178864479,
      0.015641259029507637,
      -0.0025042498018592596,
      -0.017177285626530647,
      0.006839183624833822,
      0.03011440299451351,
      0.005700194742530584,
      -0.003444075584411621,
      0.030921559780836105,
      -0.02337004616856575,
      0.022033724933862686,
      -0.0036815728526562452
    ],
    [
      0.12270598113536835,
      -0.02616608887910843,
      -0.05917663127183914,
      0.0014983502915129066,
      0.03375393897294998,
      -0.14430542290210724,
      0.02841291017830372,
      -0.015552844852209091,
      0.05695400387048721,
      0.05699147284030914,
      0.06120643392205238,
      -0.012774718925356865,
      0.03592512384057045,
      0.0867619439959526,
      -0.05008471757173538,
      0.06745953112840652,
      -0.01029852032661438,
      -0.004212178755551577,
      0.022829215973615646,
      0.020486993715167046,
      -0.14133086800575256,
      -0.03211193159222603,
      0.0033092161174863577,
      0.01271452009677887,
      0.055624447762966156,
      -0.030985292047262192,
      0.12142656743526459,
      0.051210712641477585,
      -0.03602011874318123,
      -0.012590508908033371,
      -0.023722538724541664,
      0.09460137039422989,
      -0.11366443336009979,
      -0.07243678718805313,
      -0.037383221089839935,
      0.05486107990145683,
      0.015063134953379631,
      -0.009929967112839222,
      0.04223901778459549,
      0.047916579991579056,
      -0.0034063358325511217,
      -0.06319430470466614,
      -0.069972462952137,
      0.009982588700950146,
      -0.08978445827960968,
      -0.0733332484960556,
      0.05243461951613426,
      -0.0762164443731308,
      -0.03139444440603256,
      0.013608608394861221,
      0.0449245348572731,
      0.0018343004630878568,
      -0.06030702963471413,
      -0.016952967271208763,
      -0.06144821643829346,
      -0.04424538090825081,
      -0.04202302172780037,
      -0.052889853715896606,
      0.05993473902344704,
      -0.012734439224004745,
      0.04979327321052551,
      -0.07936310023069382,
      -0.041980382055044174,
      -0.010328243486583233,
      0.004117520526051521,
      -0.06648191064596176,
      -0.043015412986278534,
      0.036255866289138794,
      -0.017770875245332718,
      0.016874361783266068,
      0.038143694400787354,
      -0.011667360551655293,
      -0.03208247199654579,
      -0.047360945492982864,
      0.1218218207359314,
      -0.034120794385671616,
      -0.08708933740854263,
      0.06153156980872154,
      0.018030783161520958,
      0.0055112093687057495,
      0.06268395483493805,
      -0.0930984690785408,
      -0.059350643306970596,
      0.03875826671719551,
      -0.010306224226951599,
      -0.005643853917717934,
      0.012134118005633354,
      0.08255777508020401,
      -0.018650218844413757,
      -0.02664957381784916,
      0.06566860526800156,
      0.037436436861753464,
      -0.09866219758987427,
      0.05304424837231636,
      -0.0388406403362751,
      -0.03883414342999458,
      0.03707316145300865,
      -0.012538687326014042,
      0.013636601157486439,
      -0.024705704301595688,
      0.008742704056203365,
      -0.08284685015678406,
      -0.07742735743522644,
      -0.09290779381990433,
      0.007985145784914494,
      0.029442662373185158,
      -0.08582080155611038,
      0.01517184916883707,
      -0.0021353645715862513,
      0.08545733988285065,
      -0.03254785016179085,
      0.059593915939331055,
      -0.014840928837656975,
      -0.052432235330343246,
      -0.058789610862731934,
      -0.051405999809503555,
      -0.04127185791730881,
      0.04500441998243332,
      0.001751922769472003,
      -0.09477487951517105,
      -0.0012550336541607976,
      -0.024066142737865448,
      -0.04925968497991562,
      -0.030415141955018044,
      -0.016717251390218735,
      -0.11097976565361023,
      -0.03729663044214249,
      -0.047361355274915695,
      0.01697869785130024,
      0.07472634315490723,
      -0.10212042182683945,
      0.010674245655536652,
      -0.01630283147096634,
      -0.015972303226590157,
      -0.058788467198610306,
      -0.08694801479578018,
      0.029306259006261826,
      -0.01258763950318098,
      0.023830391466617584,
      0.019218022003769875,
      0.038139957934617996,
      -0.024292776361107826,
      0.01354143489152193,
      -0.06624387204647064,
      -0.025148678570985794,
      0.07081328332424164,
      0.01353800855576992,
      0.0882747545838356,
      -0.05786267668008804,
      0.0014433639589697123,
      -0.015624145045876503,
      -0.0012749226298183203,
      -0.0440969318151474,
      -0.010881531983613968,
      0.013380373828113079,
      0.012080155313014984,
      0.013940846547484398,
      0.009770377539098263,
      -0.05683650076389313,
      0.05140642449259758,
      0.038127072155475616,
      0.0835714116692543,
      0.0032265905756503344,
      0.04539763182401657,
      0.001191821414977312,
      -0.020548006519675255,
      -0.09205934405326843,
      0.0029891172889620066,
      -0.05373651906847954,
      -0.03313035890460014,
      0.029422268271446228,
      0.04497992992401123,
      0.06819058209657669,
      -0.01935405097901821,
      0.0002848224830813706,
      0.03091183491051197,
      0.024963244795799255,
      0.04803989827632904,
      -0.009705617092549801,
      -0.010838313028216362,
      -0.03967640921473503,
      0.016708172857761383,
      0.0566081777215004,
      -0.02944309636950493,
      -0.017092585563659668,
      0.06038886308670044,
      0.014497333206236362,
      -0.024312438443303108,
      0.09832645952701569,
      0.06567852944135666,
      -0.041129592806100845,
      0.02198570780456066,
      0.0376645028591156,
      -0.05971531942486763,
      -0.021010655909776688,
      0.012606125324964523,
      -0.04424469172954559,
      -0.05085478723049164,
      -0.029216649010777473,
      0.010349626652896404,
      0.033509302884340286,
      -0.11491430550813675,
      0.02760101482272148,
      0.08055030554533005,
      -0.023988252505660057,
      -0.03534949943423271,
      -0.08089013397693634,
      -0.013389518484473228,
      0.004088119138032198,
      0.01905639097094536,
      0.027492521330714226,
      0.028149010613560677,
      -0.12146984040737152,
      -0.005594312213361263,
      0.02831449545919895,
      -0.06523828208446503,
      -0.0037957599852234125,
      0.06562897562980652,
      0.000652008515316993,
      0.02746875211596489,
      0.03897295892238617,
      0.013163193129003048,
      0.03190900385379791,
      0.007863910868763924,
      -0.056909672915935516,
      0.09028910100460052,
      0.017966583371162415,
      0.07669568061828613,
      0.03264645114541054,
      -0.0034915003925561905,
      0.014048164710402489,
      -0.016346465796232224,
      -0.07148687541484833,
      -0.002164873294532299,
      0.03925740718841553,
      -0.029272088780999184,
      0.040299031883478165,
      -0.06139758229255676,
      0.02518104389309883,
      0.024981286376714706,
      0.046359073370695114,
      0.10869929194450378,
      0.01728449948132038,
      0.04072382673621178,
      0.09629412740468979,
      -0.043321866542100906,
      -0.03287147730588913,
      -0.017823049798607826,
      0.005133539438247681,
      -0.02298291027545929,
      0.047872450202703476,
      0.0018672819714993238,
      -0.00998850166797638,
      0.13090874254703522,
      -0.04215238243341446,
      0.04172080382704735,
      0.044470809400081635,
      0.15676698088645935,
      -0.058838147670030594,
      -0.043531082570552826,
      0.03551574796438217,
      0.03133556246757507,
      -0.06032555177807808,
      0.03741265833377838,
      0.0797819271683693,
      -0.010570027865469456,
      0.09235844016075134,
      0.008572246879339218,
      -0.010948438197374344,
      -0.014522947371006012,
      0.049987953156232834,
      0.07390446960926056,
      0.021168701350688934,
      -0.037447914481163025,
      0.04981767013669014,
      -0.019768284633755684,
      -0.016310404986143112,
      -0.003400031942874193,
      0.06823817640542984,
      0.0434267558157444,
      0.0705137550830841,
      0.01937013491988182,
      0.0026194348465651274,
      0.01589236967265606,
      -0.08375898748636246,
      0.024862132966518402,
      0.009453785605728626,
      0.02233215793967247,
      0.007983580231666565,
      0.030671147629618645,
      0.04165106639266014,
      0.002437715185806155,
      -0.016956143081188202,
      0.0248478464782238,
      -0.08039572089910507,
      0.020258432254195213,
      0.013558556325733662,
      0.04101601243019104,
      0.002873067744076252,
      0.037856899201869965,
      0.00258292886428535,
      -0.0039448183961212635,
      -0.02754921279847622,
      0.015010388568043709,
      -0.018886849284172058,
      -0.03295349329710007,
      0.027060413733124733,
      -0.02748776227235794,
      0.035331740975379944,
      0.04482794925570488,
      -0.03827238827943802,
      -0.020889118313789368,
      0.05544288828969002,
      -0.002814644481986761,
      0.021422341465950012,
      0.024743592366576195,
      -0.055921975523233414,
      0.004421195015311241,
      0.01723668910562992,
      0.01628393866121769,
      -0.12247294187545776,
      -0.05197485163807869,
      0.057286396622657776,
      0.053975872695446014,
      0.017262324690818787,
      -0.10595753788948059,
      0.009432901628315449,
      0.012320742011070251,
      -0.052223119884729385,
      0.06544771790504456,
      0.0009129918762482703,
      -0.08638322353363037,
      -0.003488325048238039,
      0.04395684599876404,
      -0.05551513656973839,
      -0.00020475742348935455,
      -0.05253267660737038,
      0.08063522726297379,
      -0.03555721789598465,
      0.0944933369755745,
      0.010165481828153133,
      -0.005055638030171394,
      -0.04855254292488098,
      0.00285028456710279,
      -0.008112995885312557,
      -0.07340515404939651,
      -0.03263716399669647,
      0.068882055580616,
      -0.053399696946144104,
      -0.05412045493721962,
      0.037910498678684235,
      -0.026405904442071915,
      -0.1515565812587738,
      0.009090876206755638,
      0.006594040431082249,
      -0.03734998777508736,
      -0.02604769356548786,
      -0.002825002418830991,
      -0.035876937210559845,
      0.00515069579705596,
      0.02455972135066986,
      -0.06860451400279999,
      0.042620401829481125,
      -0.06683370471000671,
      -0.045416560024023056,
      -0.012071310542523861,
      -0.051661428064107895,
      -0.031140083447098732,
      -0.03694412112236023,
      -0.03491618484258652,
      0.005292391404509544,
      -0.03272873908281326,
      0.0164948683232069,
      0.029257865622639656,
      -0.02573125995695591,
      -0.0020350522827357054,
      0.04442393034696579,
      -0.019999293610453606,
      -0.05688333138823509,
      -0.03604951873421669,
      0.0163187887519598,
      0.0823611319065094,
      0.041270650923252106,
      0.05154861882328987,
      -0.029117053374648094,
      -0.03457530587911606,
      0.021335888653993607,
      -0.02424352802336216,
      -0.07493943721055984,
      -0.056819040328264236,
      0.036032792180776596,
      0.05666084587574005,
      0.010005553252995014,
      -0.013186012394726276,
      0.02208758145570755,
      0.04051623493432999,
      -0.04957721009850502,
      -0.06515219062566757,
      0.022694140672683716,
      0.03530936688184738,
      -0.03219858556985855,
      -0.05101849138736725,
      0.0220869742333889,
      0.021188974380493164,
      0.001402247347868979,
      0.0364539697766304,
      -0.00456544803455472,
      -0.06035180017352104,
      -0.11484120041131973,
      -0.007004324812442064,
      -0.02064797468483448,
      -0.08188368380069733,
      0.09526953846216202,
      0.03634123131632805,
      -0.041022058576345444,
      -0.03651808202266693,
      -0.03990790620446205,
      -0.003830533940345049,
      -0.004265328403562307,
      0.02687675692141056,
      0.04772578179836273,
      0.028829200193285942,
      0.05535849556326866,
      0.08108019083738327,
      -0.004838854074478149,
      -0.05759482458233833,
      0.004614551551640034,
      0.023030057549476624,
      0.004404360894113779,
      0.020782366394996643,
      0.04930306226015091,
      0.04625869542360306,
      0.00140695262234658,
      0.0015486263437196612,
      0.010356099344789982,
      -0.010121854953467846,
      -0.0274497140198946,
      0.04901904612779617,
      0.024643493816256523,
      -0.0588667057454586,
      -0.0021404263097792864,
      -0.033543892204761505,
      0.008859497494995594,
      0.017036449164152145,
      -0.0704938992857933,
      0.07791272550821304,
      0.02649671956896782,
      -0.006032438483089209,
      0.04604354500770569,
      -0.023984858766198158,
      0.00368982064537704,
      0.0682622492313385,
      -0.028951430693268776,
      -0.04546355456113815,
      -0.0032803553622215986,
      -0.03272441029548645,
      -0.010798156261444092,
      -0.058730438351631165,
      -0.04406723752617836,
      -0.028788596391677856,
      -0.05583776533603668,
      0.06317751109600067,
      -0.01494455337524414,
      -0.03675736486911774,
      0.04110860079526901,
      0.03794698417186737,
      -0.025038760155439377,
      -0.06249523162841797,
      -0.02318675071001053,
      -0.029101571068167686,
      -0.07509162276983261,
      -0.01120858732610941,
      -0.03976862505078316,
      0.04308902472257614,
      0.0471675805747509,
      0.04298911988735199,
      0.01647098734974861,
      0.007662237621843815,
      -0.012558501213788986,
      -0.03521881625056267,
      -0.0024291095323860645,
      -0.08097593486309052,
      0.01756264641880989,
      0.10232273489236832,
      -0.01151213888078928,
      -0.0513090081512928,
      -0.10427946597337723,
      0.03808789327740669,
      -0.05660315230488777,
      0.06574181467294693,
      0.06774690747261047,
      0.07073573023080826,
      0.016329923644661903,
      -0.03419589251279831,
      -0.0643777921795845,
      -0.013641328550875187,
      0.007437483407557011,
      -0.05821476876735687,
      0.04636780917644501,
      -0.011279657483100891,
      0.03984972834587097,
      -0.06552708148956299,
      -0.041763365268707275
    ],
    [
      -0.022958863526582718,
      0.02461353689432144,
      0.014076624065637589,
      0.07010554522275925,
      0.03736426681280136,
      -0.0628376305103302,
      0.030430113896727562,
      0.006871479097753763,
      0.012100071646273136,
      0.02219988778233528,
      0.06576893478631973,
      -0.02378309704363346,
      0.007776602637022734,
      0.039041388779878616,
      0.025593576952815056,
      0.0815054252743721,
      0.018341168761253357,
      -0.03371358662843704,
      -0.019568925723433495,
      0.033173639327287674,
      -0.01775105483829975,
      0.03943071886897087,
      0.053768135607242584,
      -0.05615922436118126,
      -0.010083521716296673,
      -0.02025935798883438,
      0.001378280925564468,
      -0.02068394236266613,
      0.05582498386502266,
      -0.05689067766070366,
      0.014364782720804214,
      0.06394842267036438,
      -0.05178225412964821,
      -0.037564195692539215,
      -0.07522494345903397,
      0.06096828728914261,
      -0.03142683953046799,
      0.0022756706457585096,
      -0.03194256126880646,
      0.0344461090862751,
      0.010977678000926971,
      0.03041383996605873,
      -0.008368568494915962,
      -0.022485654801130295,
      0.021544359624385834,
      -0.04374958574771881,
      0.009029189124703407,
      0.004973856266587973,
      -0.0017372979782521725,
      0.03385911136865616,
      0.02092828042805195,
      0.02435487136244774,
      0.016751723363995552,
      -0.04681643471121788,
      -0.030172692611813545,
      -0.03216623142361641,
      0.0697636753320694,
      0.05944163724780083,
      -0.00367047474719584,
      -0.0330081544816494,
      0.004028303548693657,
      -0.013763749971985817,
      -0.021993551403284073,
      0.02606789954006672,
      -0.0444011427462101,
      -0.05382410064339638,
      0.03398458659648895,
      0.0631241500377655,
      0.013175077736377716,
      0.04108142480254173,
      -0.018969234079122543,
      -0.057459134608507156,
      0.03385137394070625,
      0.003193618729710579,
      -0.03384767100214958,
      0.05316448211669922,
      -0.03664058446884155,
      0.011422533541917801,
      -0.03333311155438423,
      0.041170209646224976,
      -0.016024747863411903,
      0.06188853457570076,
      -0.018303126096725464,
      -0.011081434786319733,
      -0.014468674547970295,
      0.018907703459262848,
      0.007736791390925646,
      -0.028633683919906616,
      0.007086410187184811,
      0.007229982875287533,
      0.004217966925352812,
      0.00849187932908535,
      -0.013262641616165638,
      -0.020015010610222816,
      -0.03642040491104126,
      0.10179303586483002,
      0.039300814270973206,
      0.01958642713725567,
      -0.003300329204648733,
      -0.059465207159519196,
      -0.06468193233013153,
      0.03739657625555992,
      -0.0933644250035286,
      0.004003437701612711,
      -0.04865967109799385,
      -0.010831810534000397,
      0.02051621675491333,
      -0.0018994307611137629,
      -0.059549931436777115,
      -0.049348536878824234,
      0.08388278633356094,
      -0.048743706196546555,
      -0.012597448192536831,
      0.005701020359992981,
      -0.05999424681067467,
      -0.00037221203092485666,
      0.029337527230381966,
      0.049576517194509506,
      -0.07505610585212708,
      -0.0611470527946949,
      0.05420125275850296,
      -0.11057193577289581,
      -0.048916809260845184,
      0.05771641805768013,
      0.03570874035358429,
      -0.03187800198793411,
      -0.0758899375796318,
      -0.02683030068874359,
      -0.0016692732460796833,
      0.024296985939145088,
      -0.0013896140735596418,
      -0.039978157728910446,
      -0.006129695102572441,
      0.02238132432103157,
      -0.05355647951364517,
      -0.03305928781628609,
      -0.028864286839962006,
      0.031180843710899353,
      -0.043739911168813705,
      -0.05677463114261627,
      -0.022053180262446404,
      -0.012679150328040123,
      -0.0066221728920936584,
      0.022822091355919838,
      -0.017069801688194275,
      -0.024766791611909866,
      -0.005911907181143761,
      0.05496583506464958,
      -0.06742219626903534,
      -0.056897252798080444,
      0.020460346713662148,
      -0.020611489191651344,
      0.04426989331841469,
      -0.06229112297296524,
      0.0017000314546748996,
      0.027935335412621498,
      0.014755448326468468,
      -0.027077611535787582,
      -0.029364315792918205,
      -0.012920735403895378,
      -0.04175879433751106,
      -0.016407139599323273,
      -0.01363066490739584,
      0.00300779240205884,
      -0.016607368364930153,
      -0.05153561756014824,
      -0.050477635115385056,
      -0.014831695705652237,
      0.0877661257982254,
      0.05112135037779808,
      -0.03980059549212456,
      0.045321617275476456,
      0.11321993172168732,
      -0.05703343451023102,
      -0.01126633957028389,
      -0.04341721162199974,
      0.06572410464286804,
      0.03137349709868431,
      0.0644165500998497,
      -0.07440462708473206,
      0.04658105596899986,
      -0.02381712757050991,
      0.03773307800292969,
      0.08983897417783737,
      0.07075930386781693,
      0.032306037843227386,
      0.024872345849871635,
      0.08790501952171326,
      -0.0053123547695577145,
      -0.09095332026481628,
      -0.0320475772023201,
      0.09030254185199738,
      0.047721147537231445,
      -0.01781916432082653,
      0.012099030427634716,
      -0.055726420134305954,
      0.061638906598091125,
      -0.020665375515818596,
      0.048459552228450775,
      -0.013882586732506752,
      -0.04572141915559769,
      0.018792513757944107,
      -0.10454922914505005,
      0.04478354752063751,
      -0.011149773374199867,
      0.05510414391756058,
      -0.024356506764888763,
      0.0005127381882630289,
      0.027699558064341545,
      -0.02830718271434307,
      0.0036431613843888044,
      -0.034350402653217316,
      -0.09977898001670837,
      0.07047134637832642,
      -0.05461724102497101,
      -0.01798315718770027,
      0.04307571426033974,
      0.03149307519197464,
      0.1104809120297432,
      0.07568207383155823,
      0.03987066075205803,
      0.05708491429686546,
      0.05184348672628403,
      -0.06986942142248154,
      -0.1576501578092575,
      0.015982767567038536,
      0.036785900592803955,
      -0.09686188399791718,
      -0.0010679441038519144,
      0.049598466604948044,
      -0.05347919464111328,
      -0.0812552273273468,
      0.03140537068247795,
      -0.04537826031446457,
      -0.05465495213866234,
      0.07957013696432114,
      0.04708942025899887,
      0.03155196085572243,
      0.05761091038584709,
      0.014852684922516346,
      -0.039118699729442596,
      0.03523097559809685,
      0.01383545808494091,
      0.016304288059473038,
      0.0029784580692648888,
      0.038659535348415375,
      0.04985901713371277,
      -0.023298753425478935,
      -0.0210226122289896,
      0.0035591302439570427,
      0.019004544243216515,
      -0.0593683198094368,
      0.04454741254448891,
      -0.04525125026702881,
      -0.020374035462737083,
      -0.00018412202189210802,
      -0.07511014491319656,
      0.044758331030607224,
      0.06368037313222885,
      0.032791104167699814,
      0.008620926178991795,
      -0.08295415341854095,
      -0.03722463548183441,
      -0.07218656688928604,
      -0.04747702181339264,
      0.021099817007780075,
      -0.04808829724788666,
      0.029111452400684357,
      0.008758243173360825,
      0.029747309163212776,
      0.009584407322108746,
      -0.07379035651683807,
      -0.039278797805309296,
      -0.005000418052077293,
      0.04909318685531616,
      0.03294442221522331,
      -0.04287145286798477,
      0.022009605541825294,
      -0.05681660771369934,
      -0.06522462517023087,
      0.030101507902145386,
      0.008739846758544445,
      -0.007950727827847004,
      -0.028992125764489174,
      0.03405569866299629,
      -0.0029507549479603767,
      0.003951778169721365,
      -0.03945399820804596,
      -0.005584863480180502,
      -0.06158684939146042,
      0.04527965933084488,
      -0.04941806569695473,
      0.042906504124403,
      0.010177814401686192,
      0.10800047963857651,
      -0.010739695280790329,
      -0.01582825742661953,
      0.04514579847455025,
      -0.07674054801464081,
      0.01787738874554634,
      -0.008618010208010674,
      -0.019175296649336815,
      0.04426774010062218,
      0.039384834468364716,
      0.09776041656732559,
      -0.04679100960493088,
      -0.0423835813999176,
      0.09977179020643234,
      -0.00755354156717658,
      0.013313120231032372,
      -0.02203298546373844,
      -0.02890652045607567,
      0.05663714185357094,
      0.07037476450204849,
      -0.005740316119045019,
      -0.06886507570743561,
      0.015328940935432911,
      0.0568847581744194,
      0.0646006390452385,
      -0.009916230104863644,
      0.029271768406033516,
      -0.031001506373286247,
      -0.026994546875357628,
      -0.08704866468906403,
      0.004674525465816259,
      0.07344195991754532,
      -0.06397617608308792,
      0.04593433439731598,
      0.033062152564525604,
      0.03199964389204979,
      -0.0659080222249031,
      -0.10125325620174408,
      -0.027766544371843338,
      -0.01222096849232912,
      0.033065926283597946,
      -0.06887070089578629,
      -0.020455211400985718,
      0.0508280023932457,
      0.009306714870035648,
      0.06647753715515137,
      0.03441528603434563,
      -0.07289499789476395,
      -0.08075004070997238,
      0.01962556689977646,
      -0.014179147779941559,
      -0.07667776942253113,
      0.05208660662174225,
      -0.008476329036056995,
      -0.0022725642193108797,
      -0.047379329800605774,
      -0.049756113439798355,
      0.07603563368320465,
      -0.05045223981142044,
      0.06470252573490143,
      0.01755191572010517,
      0.10152500867843628,
      -0.03565910458564758,
      -0.05542372539639473,
      -0.025930849835276604,
      0.061946071684360504,
      -0.017238818109035492,
      -0.059882793575525284,
      0.041942670941352844,
      -0.01303336676210165,
      -0.01046452485024929,
      0.0034555811434984207,
      0.03558534383773804,
      -0.02901412919163704,
      0.05215547978878021,
      -0.02588305063545704,
      -0.11196326464414597,
      0.017426716163754463,
      0.08013228327035904,
      0.08936097472906113,
      -0.032635997980833054,
      -0.022760897874832153,
      0.029888726770877838,
      0.04004180058836937,
      0.026785319671034813,
      -0.05406207591295242,
      -0.02497199736535549,
      0.000421367323724553,
      -0.04715941101312637,
      0.036272428929805756,
      -0.012289862148463726,
      0.03435221314430237,
      -0.014581790193915367,
      -0.03317595645785332,
      0.03354962170124054,
      0.026558823883533478,
      0.06139780208468437,
      0.049366533756256104,
      0.0267740860581398,
      -0.04537753388285637,
      8.934178185882047e-05,
      0.0768401175737381,
      0.02244492992758751,
      -0.061063386499881744,
      -0.016572633758187294,
      0.03377214074134827,
      0.02508523315191269,
      0.04054274782538414,
      -0.0003646035911515355,
      -0.014286649413406849,
      0.0013259766856208444,
      0.11686255782842636,
      0.020196642726659775,
      -0.010704584419727325,
      0.011473232880234718,
      0.09021588414907455,
      0.029064001515507698,
      -0.04418052360415459,
      0.056713055819272995,
      0.03888167813420296,
      0.008828364312648773,
      -0.017116019502282143,
      0.03706131875514984,
      -0.10082043707370758,
      -0.04715561866760254,
      0.0528329536318779,
      -0.04913357272744179,
      -0.02631409652531147,
      -0.019099611788988113,
      -0.004591117147356272,
      -0.017228176817297935,
      -0.010676922276616096,
      -0.010800120420753956,
      0.10334432870149612,
      0.03461605682969093,
      0.15713445842266083,
      0.0423547588288784,
      0.09512253850698471,
      0.0465158075094223,
      -0.06422209739685059,
      -0.05903429910540581,
      0.0006277490174397826,
      0.024846000596880913,
      0.04440505802631378,
      -0.057284943759441376,
      0.02299141138792038,
      -0.002780064707621932,
      -0.029414260759949684,
      -0.05742185562849045,
      0.021598804742097855,
      0.023753905668854713,
      0.04368631914258003,
      -0.013961199671030045,
      0.012005514465272427,
      0.0019266802119091153,
      0.032376497983932495,
      -0.008447472006082535,
      -0.02579365484416485,
      0.01823541522026062,
      -0.04346984997391701,
      0.023105092346668243,
      0.06846754997968674,
      -0.04390560835599899,
      -0.01069296058267355,
      -0.03187251463532448,
      0.0316564105451107,
      0.03492501750588417,
      0.0715937539935112,
      0.03401356190443039,
      0.07082615047693253,
      0.04439811781048775,
      0.03501062095165253,
      -0.061556920409202576,
      -0.034420982003211975,
      -0.05026036873459816,
      0.03362823650240898,
      -0.07265220582485199,
      0.003454840276390314,
      0.009141365066170692,
      -0.035045821219682693,
      0.06283708661794662,
      -0.015892617404460907,
      0.029540644958615303,
      -0.004201867617666721,
      0.070856973528862,
      -0.05130521208047867,
      -0.060926709324121475,
      -0.04860128089785576,
      -0.05078856274485588,
      0.023455793038010597,
      -0.015922708436846733,
      0.08070479333400726,
      0.0904039740562439,
      0.048674095422029495,
      0.07475002110004425,
      0.059461139142513275,
      0.017198573797941208,
      0.04097786545753479,
      -0.010706542991101742,
      -0.027730289846658707,
      0.003752796445041895,
      0.017613690346479416,
      0.04037690907716751,
      0.03003699705004692,
      -0.03608320653438568,
      0.021303873509168625,
      -0.022261865437030792,
      -0.007936348207294941,
      -0.029008518904447556
    ],
    [
      -0.03382439538836479,
      -0.027844319120049477,
      -0.01222290936857462,
      0.05667240545153618,
      -0.01731076091527939,
      0.015546595677733421,
      0.06616044789552689,
      0.035585470497608185,
      -0.05654789134860039,
      0.04426724836230278,
      -0.07778807729482651,
      0.03876321017742157,
      -0.07908269017934799,
      0.023594021797180176,
      -0.06837203353643417,
      -0.009544667787849903,
      -0.03898032009601593,
      -0.03776954114437103,
      -0.01895039714872837,
      0.01623750850558281,
      0.05547797307372093,
      0.03314903751015663,
      0.02430751547217369,
      -0.015527389012277126,
      0.06854113191366196,
      0.05885592848062515,
      0.01493070088326931,
      -0.02974800020456314,
      0.034042082726955414,
      -0.00788064580410719,
      0.08324883878231049,
      0.0030764183029532433,
      -0.0225610863417387,
      0.05395069718360901,
      -0.013523421250283718,
      -0.00734166894108057,
      -0.05934963375329971,
      0.0263865664601326,
      -0.003052774351090193,
      -0.043170154094696045,
      -0.08856111764907837,
      -0.020624807104468346,
      0.0755074992775917,
      0.03944842889904976,
      0.005068547558039427,
      0.0073301419615745544,
      0.09098329395055771,
      -0.036111075431108475,
      -0.005205751396715641,
      0.02827574871480465,
      0.027473561465740204,
      -0.005380684044212103,
      -0.029111040756106377,
      -0.021544046700000763,
      -0.021902989596128464,
      -0.06653917580842972,
      0.0061192200519144535,
      -0.0493970662355423,
      0.00556221092119813,
      0.014314956031739712,
      0.01445403229445219,
      -0.05308344587683678,
      0.023633943870663643,
      0.01587255299091339,
      -0.09887699782848358,
      -0.009420005604624748,
      0.011703403666615486,
      -0.02812315709888935,
      0.016851721331477165,
      0.05264078080654144,
      -0.002788559766486287,
      0.06600473821163177,
      -0.03447112813591957,
      -0.018290463835000992,
      0.023834995925426483,
      0.03902297466993332,
      -0.09509248286485672,
      -0.0320710726082325,
      -0.010211990214884281,
      -0.01439235731959343,
      -0.0015221870271489024,
      -0.058794185519218445,
      0.038336995989084244,
      0.05659148842096329,
      -0.0010849152458831668,
      0.03651953861117363,
      0.031602777540683746,
      0.02348979003727436,
      -0.13075406849384308,
      0.12727360427379608,
      0.0457594208419323,
      0.019333697855472565,
      -0.01567293331027031,
      0.024826357141137123,
      0.041828930377960205,
      -0.05632200464606285,
      0.01955013908445835,
      -0.07854977995157242,
      0.04771057143807411,
      -0.008724009618163109,
      -0.018574370071291924,
      0.07149267196655273,
      0.016648288816213608,
      0.027925679460167885,
      0.04671354591846466,
      0.022941533476114273,
      -0.08378540724515915,
      0.04591108486056328,
      -0.021257851272821426,
      -0.09913510084152222,
      0.016575654968619347,
      -0.022745603695511818,
      0.012360315769910812,
      -0.06654790788888931,
      -0.033654361963272095,
      0.12478746473789215,
      0.03326038271188736,
      0.04103631153702736,
      0.0031874116975814104,
      -0.08848389983177185,
      0.09780954569578171,
      0.0216049961745739,
      0.014488372020423412,
      -0.019837001338601112,
      -0.02922680228948593,
      -0.0673493891954422,
      -0.009367802180349827,
      0.04652811214327812,
      -0.02880825847387314,
      0.0032484454568475485,
      0.0693618506193161,
      -0.09892527014017105,
      0.0353609137237072,
      0.023047545924782753,
      0.048400603234767914,
      -0.03804916888475418,
      -0.040435340255498886,
      0.011687139980494976,
      -0.06570607423782349,
      -0.07361216843128204,
      -0.08289150893688202,
      0.042505472898483276,
      -0.02872605249285698,
      0.06500692665576935,
      0.003944819327443838,
      -0.004573734477162361,
      0.10085184872150421,
      0.009240212850272655,
      -0.05608702078461647,
      -0.021654825657606125,
      0.07966780662536621,
      -0.015324883162975311,
      -0.015554221346974373,
      -0.022802293300628662,
      0.0201713964343071,
      -0.02904328517615795,
      -0.08356726169586182,
      0.01930377073585987,
      0.008952808566391468,
      0.024188755080103874,
      0.042724329978227615,
      0.002798710949718952,
      0.04184417799115181,
      -0.0568959042429924,
      0.07797424495220184,
      -0.02904442325234413,
      -0.021082693710923195,
      0.019066065549850464,
      -0.08358849585056305,
      0.026601893827319145,
      -0.013127517886459827,
      -0.020017262548208237,
      0.039541322737932205,
      0.08302335441112518,
      0.01193221751600504,
      0.07799720764160156,
      0.05091503635048866,
      -0.006860000547021627,
      -0.00019974171300418675,
      0.002143651945516467,
      0.0051993574015796185,
      0.020814023911952972,
      0.011191532015800476,
      -0.07398483157157898,
      -0.012921555899083614,
      -0.08305513858795166,
      0.018889915198087692,
      -0.0003801757993642241,
      0.03181137144565582,
      0.005324807018041611,
      0.04415353760123253,
      0.025403283536434174,
      0.05199084058403969,
      -0.0335809551179409,
      0.015205621719360352,
      0.020104359835386276,
      -0.016469791531562805,
      0.03663955628871918,
      0.0568876676261425,
      0.07220778614282608,
      0.00931737944483757,
      0.0039342367090284824,
      0.03955617919564247,
      0.07917606085538864,
      0.08405805379152298,
      0.014067751355469227,
      0.024607371538877487,
      0.012330161407589912,
      -0.04831204563379288,
      0.03914673998951912,
      -0.021380847319960594,
      -0.05023527890443802,
      0.07087301462888718,
      0.010466771200299263,
      0.027243411168456078,
      -0.061661023646593094,
      0.07064834982156754,
      -0.04076419398188591,
      0.08843033760786057,
      0.008463220670819283,
      -4.967901259078644e-05,
      -0.003262493060901761,
      0.042122870683670044,
      0.03915397822856903,
      -0.09054825454950333,
      0.019421547651290894,
      0.05153152719140053,
      -0.045394714921712875,
      0.06748434156179428,
      0.006204964127391577,
      -0.007145353127270937,
      0.009247896261513233,
      -0.016618428751826286,
      0.05930251628160477,
      -0.04762362316250801,
      -0.03803776204586029,
      0.04758162796497345,
      0.04716254025697708,
      0.0045203533954918385,
      0.0027677540201693773,
      0.025657404214143753,
      -0.041205961257219315,
      -0.010802416130900383,
      0.08650726079940796,
      -0.02393052913248539,
      -0.054205041378736496,
      0.03304920718073845,
      0.0877489373087883,
      0.009297332726418972,
      -0.035540420562028885,
      0.014493649825453758,
      -0.020209122449159622,
      0.015411893837153912,
      -0.04647037014365196,
      0.11435320228338242,
      -0.048112183809280396,
      -0.03633679077029228,
      0.11466735601425171,
      0.014650792814791203,
      -0.05007146671414375,
      -0.046415168792009354,
      -0.011843683198094368,
      0.0022033569402992725,
      0.09188098460435867,
      -0.04808956757187843,
      -0.007981868460774422,
      0.06747278571128845,
      0.06323753297328949,
      0.08545006811618805,
      0.00602611294016242,
      -0.06060515344142914,
      -0.03756213188171387,
      -0.012845033779740334,
      0.007405700162053108,
      0.002208123914897442,
      0.006415961310267448,
      -0.048100363463163376,
      0.060860104858875275,
      0.030419165268540382,
      0.016234252601861954,
      -0.005471790209412575,
      -0.0652262344956398,
      0.08159326761960983,
      -0.053973421454429626,
      -0.004541383590549231,
      -0.021489474922418594,
      -0.0874207466840744,
      0.02595401555299759,
      -0.026128964498639107,
      0.015435216017067432,
      -0.05361275374889374,
      0.04018133506178856,
      -0.055094197392463684,
      0.03381316363811493,
      0.018699953332543373,
      0.04055114462971687,
      0.08177626878023148,
      -0.053638990968465805,
      0.0465206578373909,
      0.03088989667594433,
      -0.041590478271245956,
      0.008918732404708862,
      0.049010563641786575,
      -0.046093642711639404,
      0.015194779261946678,
      0.03864019736647606,
      0.008729624561965466,
      -0.04179864376783371,
      -0.0287553071975708,
      -0.02089877426624298,
      -0.033834926784038544,
      -0.038308434188365936,
      0.016763092949986458,
      0.004854898899793625,
      -0.014731477946043015,
      -0.08257852494716644,
      0.00026377555332146585,
      -0.006589711178094149,
      -0.09433332830667496,
      -0.11977393180131912,
      0.04466445744037628,
      -0.005986914504319429,
      0.026442402973771095,
      0.02144244872033596,
      -0.035428017377853394,
      0.016798164695501328,
      -0.07729781419038773,
      0.03901971876621246,
      -0.03662308305501938,
      0.02540508471429348,
      0.05975702404975891,
      -0.014133556745946407,
      -0.021629298105835915,
      0.0676354244351387,
      -0.010611549019813538,
      0.08181748539209366,
      0.01933518424630165,
      -0.008277805522084236,
      0.03451547771692276,
      0.012364254333078861,
      -0.04236745089292526,
      -0.041272737085819244,
      -0.01714792288839817,
      -0.028064386919140816,
      -0.004925526212900877,
      -0.06199951097369194,
      -0.00851979199796915,
      0.07711289823055267,
      -0.03444313257932663,
      0.02800307795405388,
      0.007437263615429401,
      -0.058147963136434555,
      -0.04076660796999931,
      -0.01960678957402706,
      0.02432667836546898,
      0.05135870352387428,
      0.00823415257036686,
      -0.012522216886281967,
      0.06380306929349899,
      0.040147099643945694,
      0.014566407538950443,
      0.04449053481221199,
      -0.0424000509083271,
      0.03998353332281113,
      -0.08637131005525589,
      -0.009521977044641972,
      -0.04949521645903587,
      0.06066393107175827,
      -0.10091850161552429,
      -0.02284044399857521,
      0.00422759959474206,
      -0.10292298346757889,
      0.004523274023085833,
      0.03339378535747528,
      0.019923660904169083,
      -0.023272423073649406,
      -0.07854028046131134,
      0.06329552829265594,
      -0.015725908800959587,
      -0.022997159510850906,
      -0.04104432836174965,
      0.05749749019742012,
      0.055942635983228683,
      -0.0365496464073658,
      -0.026917200535535812,
      -0.10498163849115372,
      -0.04892024025321007,
      -0.007959604263305664,
      -0.0682637020945549,
      -0.0028187730349600315,
      -0.062008392065763474,
      0.016265761107206345,
      0.006369600538164377,
      -0.09669028967618942,
      0.01593988761305809,
      -0.03674623742699623,
      -0.04303813725709915,
      0.03370315581560135,
      -0.029882412403821945,
      0.020910387858748436,
      0.02590017020702362,
      0.024431169033050537,
      -0.06123194843530655,
      0.007005170453339815,
      0.013795735314488411,
      -0.0022888516541570425,
      0.014417198486626148,
      -0.067310631275177,
      0.027753936126828194,
      -0.02604469656944275,
      -0.04433026537299156,
      0.07201023399829865,
      0.010381487198174,
      -0.0423450730741024,
      -0.057775553315877914,
      -0.016299469396471977,
      -0.015303444117307663,
      0.004305985290557146,
      0.0870419293642044,
      -0.0019104036036878824,
      0.025470398366451263,
      0.07576767355203629,
      -0.03489023074507713,
      0.03945881873369217,
      0.008015214465558529,
      -0.03392968326807022,
      -0.024081645533442497,
      0.03003842942416668,
      -0.057625725865364075,
      0.06473760306835175,
      -0.04273487627506256,
      -0.00012627345859073102,
      -0.03470218554139137,
      -0.0096187274903059,
      0.06930790096521378,
      -0.023354554548859596,
      0.07386624068021774,
      -0.06298550963401794,
      -0.09616055339574814,
      0.06070193648338318,
      0.007501235231757164,
      0.08877121657133102,
      -0.07489760220050812,
      0.03203291818499565,
      0.01975344866514206,
      0.006005385424941778,
      0.04143718257546425,
      -0.059454698115587234,
      -0.02398071065545082,
      0.024748411029577255,
      -0.018890904262661934,
      -0.053463373333215714,
      -0.10123246908187866,
      0.0921483114361763,
      0.035098470747470856,
      0.017386572435498238,
      0.04619642719626427,
      0.07607436180114746,
      0.04236641526222229,
      -0.0562264621257782,
      -0.047843024134635925,
      -0.0914597362279892,
      -0.048887599259614944,
      0.020605240017175674,
      0.012149907648563385,
      0.004776443354785442,
      -0.06039860099554062,
      -0.02822628989815712,
      0.01668744906783104,
      -0.0083220936357975,
      -0.039074793457984924,
      0.022322511300444603,
      -0.003086189040914178,
      -0.07555288821458817,
      0.07713238894939423,
      -0.058536600321531296,
      -0.043021492660045624,
      0.05433809012174606,
      -0.024472039192914963,
      0.038121215999126434,
      0.0601079985499382,
      -0.060034509748220444,
      0.04043919965624809,
      0.01174109149724245,
      -0.008605355396866798,
      0.005372628569602966,
      0.05642032250761986,
      0.03532158583402634,
      0.008072974160313606,
      -0.09186931699514389,
      -0.064878910779953,
      -0.01902775652706623,
      0.0412520207464695,
      0.005725950468331575,
      0.01439515221863985,
      0.07557527720928192,
      0.012386765331029892,
      -0.05578460171818733,
      0.012516153044998646,
      -0.07757100462913513,
      -0.02684803307056427,
      0.026020057499408722,
      -0.08260629326105118
    ],
    [
      0.0010852876584976912,
      -0.07297196239233017,
      -0.08988367021083832,
      0.017519928514957428,
      0.05788256600499153,
      -0.03910961002111435,
      0.0370999276638031,
      -0.029451781883835793,
      0.11974923312664032,
      -0.05329781025648117,
      -3.4287577364011668e-06,
      0.007893860340118408,
      0.03602824732661247,
      0.031597353518009186,
      -0.013999992050230503,
      0.06440109759569168,
      0.0008147835615091026,
      0.05673026293516159,
      -0.04628831148147583,
      0.024843797087669373,
      0.043792955577373505,
      0.021582311019301414,
      -0.02514810301363468,
      0.04630454629659653,
      -0.018030675128102303,
      0.030523279681801796,
      -0.04731455072760582,
      0.06577502191066742,
      0.024663953110575676,
      0.07664068043231964,
      0.024718668311834335,
      0.010881103575229645,
      -0.02769269421696663,
      0.014329222030937672,
      -0.028630420565605164,
      0.013095004484057426,
      0.0149906687438488,
      0.01185657549649477,
      -0.07213430106639862,
      0.042209427803754807,
      -0.05267186462879181,
      0.0033295012544840574,
      0.08316491544246674,
      -0.016298888251185417,
      -0.019477389752864838,
      -0.028176266700029373,
      0.06992965191602707,
      0.022040652111172676,
      0.011096671223640442,
      0.07453589886426926,
      -0.004081621300429106,
      -0.018108604475855827,
      0.006011855788528919,
      -0.00406237505376339,
      0.015987901017069817,
      -0.0542932003736496,
      -0.013337112963199615,
      -0.026981865987181664,
      0.06417719274759293,
      -0.017690083011984825,
      0.015087812207639217,
      -0.0006479690782725811,
      0.020492244511842728,
      0.016705235466361046,
      0.04941951856017113,
      -0.04286419600248337,
      0.043440379202365875,
      -0.023990683257579803,
      -0.0390934981405735,
      0.024129072204232216,
      0.043894875794649124,
      -0.017296722158789635,
      -0.03353891894221306,
      -0.04350340738892555,
      -0.08859828859567642,
      -0.023790694773197174,
      0.08451501280069351,
      -0.02570406347513199,
      0.026625748723745346,
      -0.04114482179284096,
      -0.0076180859468877316,
      -0.01948891393840313,
      0.027444103732705116,
      -0.029667843133211136,
      0.0012030319776386023,
      -0.07238754630088806,
      -0.019361093640327454,
      0.07940005511045456,
      0.004601437598466873,
      0.0638311356306076,
      0.023779751732945442,
      0.038517631590366364,
      -0.02343389019370079,
      -0.038164716213941574,
      -0.06210852041840553,
      0.006814276799559593,
      0.07841429114341736,
      0.021791836246848106,
      -0.0032435862813144922,
      0.0224076509475708,
      -0.0401037223637104,
      0.03988000750541687,
      -0.0418587401509285,
      -0.009013607166707516,
      0.06367740780115128,
      -0.025290727615356445,
      0.06902457028627396,
      0.005522273946553469,
      0.04583713039755821,
      -0.02829151228070259,
      0.04692598059773445,
      0.04119472950696945,
      0.03326261043548584,
      -0.06432182341814041,
      -0.004733519162982702,
      -0.009754165075719357,
      -0.09370626509189606,
      -0.04533357545733452,
      0.028616031631827354,
      -0.002421374199911952,
      -0.02086419053375721,
      -0.015914954245090485,
      -0.02326546050608158,
      0.014750933274626732,
      0.04694347083568573,
      0.03728470578789711,
      -0.031799908727407455,
      -0.030130067840218544,
      0.07258448749780655,
      0.01126764714717865,
      -0.057428084313869476,
      -0.03111942484974861,
      -0.0026458657812327147,
      -0.007575760129839182,
      -0.029174430295825005,
      -0.0025172876194119453,
      -0.011074872687458992,
      -0.012441487982869148,
      0.009600217454135418,
      0.0736628845334053,
      -0.02856757678091526,
      -0.00889501441270113,
      0.07466776669025421,
      0.050031378865242004,
      -0.015886453911662102,
      0.03677138686180115,
      0.025481456890702248,
      0.004271989222615957,
      -0.0430801659822464,
      0.052998725324869156,
      0.010040015913546085,
      0.02120763063430786,
      0.026885272935032845,
      -0.02220863476395607,
      0.05561963841319084,
      0.06010898947715759,
      0.08865117281675339,
      0.027015428990125656,
      -0.09657218307256699,
      -0.01009727269411087,
      -0.021399060264229774,
      -0.039848826825618744,
      -0.036736540496349335,
      0.05717789754271507,
      -0.02809995226562023,
      0.031768862158060074,
      0.042923782020807266,
      -0.05934237316250801,
      0.028788769617676735,
      -0.004367608577013016,
      -0.08301272243261337,
      0.033558644354343414,
      0.02804512344300747,
      -0.06094230338931084,
      -0.015520895831286907,
      0.0234946571290493,
      -0.0200673695653677,
      -0.024708211421966553,
      -0.04185090214014053,
      -0.015188646502792835,
      -0.03924686461687088,
      -0.04217086359858513,
      -0.014193059876561165,
      0.0955924391746521,
      0.03979945555329323,
      -0.012457665055990219,
      -0.02138150855898857,
      0.023531710729002953,
      0.07544109225273132,
      0.10825147479772568,
      0.0828554555773735,
      -0.05586843937635422,
      -0.008528005331754684,
      -0.030687151476740837,
      0.03180473670363426,
      -0.03653375059366226,
      0.019328119233250618,
      0.0003787880123127252,
      0.047531623393297195,
      -0.005019286181777716,
      -0.019101206213235855,
      0.04378422349691391,
      -0.017301926389336586,
      0.023728059604763985,
      0.036591317504644394,
      -0.010450501926243305,
      0.040110405534505844,
      0.0313166044652462,
      -0.042985059320926666,
      0.04216160997748375,
      -0.043948933482170105,
      -0.0035968583542853594,
      -0.03219524398446083,
      0.06961240619421005,
      -0.011932681314647198,
      -0.09908194094896317,
      -0.07041416317224503,
      -0.022952020168304443,
      0.04900292679667473,
      0.08076812326908112,
      -0.04538988322019577,
      -0.037742648273706436,
      -0.027388522401452065,
      0.0017796310130506754,
      0.039634205400943756,
      -0.01286543719470501,
      -0.04195813834667206,
      0.027806954458355904,
      0.02186538465321064,
      0.008372068405151367,
      0.01212854404002428,
      -0.10792302340269089,
      0.041357725858688354,
      0.04455055296421051,
      0.029254157096147537,
      0.006480085663497448,
      -0.06306541711091995,
      0.03861357271671295,
      -0.008122098632156849,
      0.010805008001625538,
      0.00908811204135418,
      -0.07971834391355515,
      0.05181514471769333,
      0.012190663255751133,
      0.07842440903186798,
      0.015079249627888203,
      0.05750684440135956,
      -0.01114871259778738,
      0.037373412400484085,
      -0.0751948133111,
      -0.0052542597986757755,
      0.016422484070062637,
      0.08635057508945465,
      -0.020408494397997856,
      0.039943877607584,
      0.0030927155166864395,
      -0.0011282548075541854,
      0.05598457530140877,
      0.05963410064578056,
      0.02494191564619541,
      0.007823895663022995,
      -0.04873676225543022,
      0.02743811160326004,
      -0.030447959899902344,
      0.00013221908011473715,
      -0.00012112564581912011,
      -0.025898797437548637,
      0.031543657183647156,
      -0.045206375420093536,
      0.005113539285957813,
      0.013838443905115128,
      0.07158692926168442,
      -0.06111041083931923,
      0.05957714468240738,
      -0.003834072733297944,
      -0.010059208609163761,
      0.006420652847737074,
      0.004595136269927025,
      -0.012826614081859589,
      0.06125425547361374,
      -0.05553894490003586,
      -0.04755953699350357,
      -0.04330623522400856,
      -0.017744511365890503,
      0.04705777019262314,
      0.03306993469595909,
      -0.03666139766573906,
      -0.007422838360071182,
      -0.06439679116010666,
      0.023848282173275948,
      -0.07271019369363785,
      0.027919961139559746,
      -0.05580520257353783,
      -0.005905620753765106,
      0.00893909391015768,
      -0.02058631367981434,
      0.003927931189537048,
      -0.004500342067331076,
      0.040257543325424194,
      -0.025556176900863647,
      0.004934607073664665,
      -0.009894204325973988,
      0.016145063564181328,
      0.0028691936749964952,
      0.002510238438844681,
      0.00393832940608263,
      0.002069746144115925,
      0.05065533146262169,
      -0.027824120596051216,
      -0.04609008505940437,
      -0.031653039157390594,
      -0.03227029740810394,
      0.01913999207317829,
      0.03940413519740105,
      -0.031569402664899826,
      -0.01823517680168152,
      0.08976254612207413,
      -0.03576255962252617,
      0.031691018491983414,
      0.027611685916781425,
      0.01703658327460289,
      0.03150813281536102,
      0.04643002152442932,
      0.042245615273714066,
      -0.08059360831975937,
      0.004502885974943638,
      0.005654561333358288,
      -0.029845623299479485,
      0.08086682111024857,
      0.0022234581410884857,
      -0.007866623811423779,
      -0.021986588835716248,
      -0.02203914150595665,
      -0.022604143247008324,
      0.043903108686208725,
      -0.05711926147341728,
      -0.03909953311085701,
      -0.023021187633275986,
      -0.0065632532350718975,
      -0.0416778102517128,
      -0.07394833117723465,
      0.03202332556247711,
      0.03158530965447426,
      0.0013867922825738788,
      -0.05626418814063072,
      -0.06034234166145325,
      0.0656142383813858,
      -0.025390809401869774,
      0.0253323782235384,
      -0.049869854003190994,
      0.05351924151182175,
      -0.013645005412399769,
      0.058918312191963196,
      0.03448163717985153,
      0.005497475154697895,
      0.009077871218323708,
      0.05344416946172714,
      0.015493386425077915,
      0.00430680625140667,
      -0.00847751833498478,
      -0.030288584530353546,
      -0.0662512257695198,
      0.025284256786108017,
      -0.03862506523728371,
      -0.11130194365978241,
      0.013032035902142525,
      -0.009212043136358261,
      -0.0028790778014808893,
      -0.0621047206223011,
      0.004376829136162996,
      -0.021913466975092888,
      -0.06466000527143478,
      0.023438312113285065,
      -0.031562428921461105,
      -0.005076014436781406,
      -0.06395532190799713,
      -0.03948364779353142,
      -0.017363915219902992,
      -0.0717535987496376,
      -0.022023972123861313,
      -0.027663422748446465,
      0.02229241281747818,
      -0.07925356924533844,
      0.024836912751197815,
      0.026518356055021286,
      0.05577296018600464,
      0.0760493203997612,
      0.08112595975399017,
      0.009671042673289776,
      -0.06202441453933716,
      -0.0004073489981237799,
      -0.033463481813669205,
      0.02027943916618824,
      -0.017245041206479073,
      0.05388053134083748,
      -0.011204365640878677,
      0.07166193425655365,
      0.04669317975640297,
      -0.019094295799732208,
      0.07066065818071365,
      0.030847497284412384,
      0.045252725481987,
      -0.04475226253271103,
      -0.016963692381978035,
      0.031168200075626373,
      -0.06542946398258209,
      -0.049165062606334686,
      -0.009634480811655521,
      0.07940994948148727,
      0.04148018732666969,
      -0.014974222518503666,
      -0.007255224045366049,
      0.033710796386003494,
      0.03373122960329056,
      0.022291654720902443,
      -0.02815426141023636,
      -0.0073642185889184475,
      -0.0458642803132534,
      0.04999172315001488,
      0.008865930140018463,
      0.06698853522539139,
      0.03059646673500538,
      0.030973374843597412,
      -0.036110665649175644,
      -0.0029995914082974195,
      -0.07109459489583969,
      -0.003976916428655386,
      -0.04696957394480705,
      0.07379210740327835,
      -0.025078019127249718,
      -0.007510222494602203,
      0.08386829495429993,
      0.02713756449520588,
      -0.06403587013483047,
      -0.013624316081404686,
      -0.01967748813331127,
      -0.016169773414731026,
      0.06664038449525833,
      0.09883959591388702,
      -0.02537301369011402,
      -0.015316057950258255,
      0.056915055960416794,
      -0.07156629860401154,
      -0.009325365535914898,
      -0.04580015316605568,
      0.05629180744290352,
      0.045363448560237885,
      -0.08575964719057083,
      0.013979535549879074,
      -0.0026879615616053343,
      -0.02594648487865925,
      -0.0021186175290495157,
      -0.01890765130519867,
      -0.06532064825296402,
      -0.11861321330070496,
      0.09793471544981003,
      -0.0817536860704422,
      -0.026467379182577133,
      0.027833105996251106,
      -0.043659888207912445,
      0.02872558869421482,
      0.04252307489514351,
      -0.04171401262283325,
      -0.008711247704923153,
      0.046901993453502655,
      -0.06337235122919083,
      0.06052563712000847,
      -0.05861766263842583,
      0.02570943534374237,
      0.019957929849624634,
      -0.001194619107991457,
      0.06546498835086823,
      -0.08410849422216415,
      -0.008750705979764462,
      0.0941944345831871,
      0.022571321576833725,
      0.02987496927380562,
      0.03904839605093002,
      0.021227138116955757,
      -0.02065126784145832,
      0.04577072337269783,
      0.06332627683877945,
      0.02299519255757332,
      -0.00466947490349412,
      -0.024526529014110565,
      -0.02181377448141575,
      -0.003859642893075943,
      -0.012787343934178352,
      0.00407017394900322,
      -0.08708924055099487,
      0.018290892243385315,
      0.035199061036109924,
      -0.08716373145580292,
      0.09600768983364105,
      -0.001110415323637426,
      -0.09132754802703857,
      -0.0504109263420105,
      -0.017365530133247375,
      0.05245010182261467,
      0.01665852963924408,
      0.02545662224292755,
      0.007078119553625584,
      -0.03556990623474121
    ],
    [
      -0.09950120747089386,
      0.060529500246047974,
      -0.05745753273367882,
      -0.006207168102264404,
      -0.04027514532208443,
      0.04026667773723602,
      0.02467956766486168,
      0.022126737982034683,
      0.018294526264071465,
      0.048275310546159744,
      -0.027725303545594215,
      0.02335619181394577,
      -0.0037658163346350193,
      -0.04084912687540054,
      -0.015564988367259502,
      -0.02131739817559719,
      -0.06598743051290512,
      0.03174871578812599,
      0.016451189294457436,
      0.008836098946630955,
      0.0164115522056818,
      0.030210068449378014,
      0.038600604981184006,
      -0.025375129655003548,
      0.018230192363262177,
      0.04588305950164795,
      -0.04835076257586479,
      0.09731031954288483,
      -0.027012556791305542,
      -0.05123309791088104,
      0.054634686559438705,
      0.03812701255083084,
      -0.032178111374378204,
      0.0433531329035759,
      -0.03407730534672737,
      -0.023991037160158157,
      -0.012826989404857159,
      -0.10253985971212387,
      0.06681971251964569,
      0.022343972697854042,
      -0.0658269077539444,
      0.06496713310480118,
      -0.07863857597112656,
      -0.013661134988069534,
      -0.01670015975832939,
      0.04744753614068031,
      0.06580331176519394,
      0.06433744728565216,
      -0.0502394363284111,
      -0.016522079706192017,
      -0.07702921330928802,
      0.04730736091732979,
      -0.00458509149029851,
      0.01670803315937519,
      0.11993315815925598,
      0.0536666102707386,
      0.019951175898313522,
      0.006389706861227751,
      0.04642045870423317,
      0.0007452786085195839,
      0.004359299782663584,
      -0.013113521039485931,
      -0.10559812933206558,
      0.024894319474697113,
      0.014073733240365982,
      -0.013587280176579952,
      -0.0030794141348451376,
      0.05979485809803009,
      -0.023427903652191162,
      -0.013546654023230076,
      -0.03682568669319153,
      0.01088892761617899,
      -0.0537431575357914,
      -0.031084466725587845,
      -0.03984130546450615,
      0.024307137355208397,
      -0.0010194106725975871,
      -0.07493889331817627,
      0.007250952068716288,
      0.030942244455218315,
      0.1092880591750145,
      -0.012663262896239758,
      0.07123919576406479,
      -0.12432025372982025,
      -0.010415725409984589,
      0.005687245633453131,
      0.06625068187713623,
      0.04379308223724365,
      0.008675997145473957,
      -0.06120846047997475,
      -0.001604702090844512,
      0.018928583711385727,
      0.014057474210858345,
      0.08993735909461975,
      0.061943672597408295,
      0.03691653907299042,
      0.025755589827895164,
      0.01682715117931366,
      -0.019858647137880325,
      0.048742640763521194,
      -0.035891879349946976,
      0.09947066754102707,
      0.010381706058979034,
      -0.02099195495247841,
      -0.03536346182227135,
      -0.013992471620440483,
      -0.01661631651222706,
      -0.027045974507927895,
      0.09366454929113388,
      -0.00883680023252964,
      0.05437186732888222,
      0.027249203994870186,
      0.04423874244093895,
      -0.005748441908508539,
      -0.005840822588652372,
      0.03716926649212837,
      0.025545712560415268,
      -0.007700756657868624,
      0.10324881225824356,
      -0.048279665410518646,
      0.05578574165701866,
      0.06589997559785843,
      -0.016297774389386177,
      0.08643687516450882,
      0.037898991256952286,
      -0.010621308349072933,
      0.012505598366260529,
      -0.011111733503639698,
      0.01284542866051197,
      -0.03245116025209427,
      -0.08897139132022858,
      -0.033677104860544205,
      -0.016343193128705025,
      0.0006966933724470437,
      0.012780161574482918,
      0.07344985753297806,
      -0.06166047230362892,
      0.01507793739438057,
      0.022044045850634575,
      0.03586507961153984,
      0.005420112516731024,
      -0.010804329998791218,
      0.07107486575841904,
      0.04581243172287941,
      -0.00526379095390439,
      0.04050891101360321,
      -0.05714863911271095,
      -0.10672088712453842,
      -0.0579148530960083,
      0.06112375482916832,
      0.016798922792077065,
      0.12123414129018784,
      0.039744894951581955,
      -0.04412176460027695,
      -0.002668506931513548,
      -0.003926622681319714,
      0.03815428540110588,
      0.012379191815853119,
      -0.09873614460229874,
      -0.03980801999568939,
      -0.060514505952596664,
      -0.03899684548377991,
      0.033091723918914795,
      -0.04509750381112099,
      0.009935599751770496,
      0.016525816172361374,
      0.019995877519249916,
      -0.013195338658988476,
      -0.033514585345983505,
      -0.03424973413348198,
      -0.021190380677580833,
      0.038015518337488174,
      0.06624285131692886,
      -0.0016733345109969378,
      0.017275992780923843,
      0.0384921059012413,
      0.0023733009584248066,
      0.0037206534761935472,
      0.015961579978466034,
      0.004393043462187052,
      -0.024834875017404556,
      -0.030045196413993835,
      0.02422168105840683,
      0.057201873511075974,
      0.008461877703666687,
      -0.021930838003754616,
      -0.039154041558504105,
      0.006544949021190405,
      0.020806537941098213,
      -0.005872920621186495,
      0.005974541883915663,
      0.04767093434929848,
      -0.012562411837279797,
      -0.019215067848563194,
      0.09582231938838959,
      -0.07861237972974777,
      0.06344584375619888,
      -0.072137251496315,
      0.01843671128153801,
      -0.006876414176076651,
      -0.011581258848309517,
      -0.006159733049571514,
      -0.0036266653332859278,
      0.002212977735325694,
      -0.03291439637541771,
      0.013961649499833584,
      0.019742591306567192,
      -0.02879338525235653,
      -0.0602036714553833,
      0.029166776686906815,
      0.002222729381173849,
      -0.04120860621333122,
      -0.0017434883629903197,
      0.00765828276053071,
      -0.010329988785088062,
      -0.03509373962879181,
      -0.11715240031480789,
      -0.004932371433824301,
      -0.0077755325473845005,
      0.07535422593355179,
      0.07887537032365799,
      -0.06018321216106415,
      0.05300310626626015,
      -0.017131825909018517,
      0.0573948509991169,
      0.02751157619059086,
      -0.042807068675756454,
      0.0015238361665979028,
      -0.009990720078349113,
      0.012640773318707943,
      0.07501087337732315,
      -0.0024487897753715515,
      0.08293522149324417,
      0.03777455911040306,
      0.013937171548604965,
      0.039160799235105515,
      0.04838142544031143,
      -0.02949792891740799,
      0.012689279392361641,
      -0.002007066272199154,
      -0.05206799879670143,
      -0.04340843856334686,
      0.0793202593922615,
      -0.040234751999378204,
      0.028120487928390503,
      0.021155398339033127,
      0.04695576801896095,
      0.01494115125387907,
      0.016859157010912895,
      0.0215535219758749,
      0.0475870817899704,
      -0.0003407605108805001,
      0.02404814213514328,
      -0.04295689985156059,
      0.0417865626513958,
      -0.003038588212803006,
      -0.1179865300655365,
      0.03112865425646305,
      0.06382152438163757,
      0.004585565533488989,
      0.022735951468348503,
      -0.00749913090839982,
      0.037875544279813766,
      -0.015639418736100197,
      0.02900647185742855,
      0.02262408286333084,
      0.012705772183835506,
      0.0016921237111091614,
      -0.01780826225876808,
      -0.022189892828464508,
      0.03491997346282005,
      0.13629990816116333,
      0.0033099250867962837,
      -0.07856913655996323,
      -0.03764300420880318,
      0.003931369632482529,
      0.07054629176855087,
      -0.0694374144077301,
      0.04480131343007088,
      0.008477270603179932,
      0.011760932393372059,
      0.08658446371555328,
      0.04758589714765549,
      -0.013230671174824238,
      -0.023140383884310722,
      0.0040994733572006226,
      -0.019559266045689583,
      -0.033292993903160095,
      0.025522466748952866,
      -0.00031755620148032904,
      -0.032728299498558044,
      -0.003413465805351734,
      0.05609625205397606,
      -0.03159257024526596,
      0.020039403811097145,
      -0.011612601578235626,
      -0.010218441486358643,
      -0.01155786495655775,
      0.010093318298459053,
      -0.0066889505833387375,
      0.07212862372398376,
      -0.02021675929427147,
      -0.0038344499189406633,
      -0.03409776836633682,
      -0.07258302718400955,
      0.0498366542160511,
      0.006321257445961237,
      0.01184177678078413,
      0.048935338854789734,
      -0.051095783710479736,
      -0.030886739492416382,
      0.07543254643678665,
      0.08482234179973602,
      -0.0725102350115776,
      -0.005294447764754295,
      0.022982101887464523,
      -0.05876985937356949,
      -0.0007312842062674463,
      0.04674731194972992,
      -0.0022956691682338715,
      -0.08340588212013245,
      0.025348450988531113,
      -0.043957218527793884,
      0.008435324765741825,
      0.13097749650478363,
      -0.08159327507019043,
      -0.022669920697808266,
      0.02527477778494358,
      0.006206733640283346,
      -0.036337703466415405,
      -0.005772994365543127,
      -0.02229483611881733,
      0.09127773344516754,
      -0.06931842118501663,
      -0.05861691012978554,
      -0.042251575738191605,
      0.04155486449599266,
      0.05679222196340561,
      -0.08408278971910477,
      0.05323880538344383,
      -0.023167509585618973,
      0.0290223378688097,
      -0.020716620609164238,
      -0.05001188814640045,
      0.0046403491869568825,
      0.019288670271635056,
      0.08927962929010391,
      0.0017422010423615575,
      -0.02935800701379776,
      0.00100945052690804,
      0.03850462660193443,
      0.008613999001681805,
      -0.012954264879226685,
      0.061866264790296555,
      0.04363301023840904,
      0.0824534073472023,
      0.007594613824039698,
      -0.021379437297582626,
      0.05843404680490494,
      0.02131119929254055,
      -0.0357959158718586,
      0.022687163203954697,
      0.019867656752467155,
      0.0031336855608969927,
      -0.04116262495517731,
      0.000945602310821414,
      -0.01775268092751503,
      0.058427173644304276,
      0.037704676389694214,
      0.019169460982084274,
      -0.05760272219777107,
      -0.09530620276927948,
      0.1092085912823677,
      -0.051070649176836014,
      0.050047826021909714,
      -0.11643296480178833,
      -0.02457134984433651,
      -0.013152457773685455,
      0.04458620399236679,
      0.05758059024810791,
      0.041017450392246246,
      0.032345205545425415,
      -0.0010190971661359072,
      -0.06308089196681976,
      -0.09452162683010101,
      -0.03332691639661789,
      -0.10156767815351486,
      0.022604556754231453,
      0.003619707887992263,
      -0.03463972732424736,
      0.029317287728190422,
      -0.03395956754684448,
      0.04109824448823929,
      -0.022611046209931374,
      -0.02030188962817192,
      -0.026943963021039963,
      -0.02985541522502899,
      -0.05430615320801735,
      0.05202263221144676,
      0.03296566754579544,
      -0.06487443298101425,
      -0.01377095840871334,
      0.005892000626772642,
      0.00574134336784482,
      -0.12319181859493256,
      0.01803099550306797,
      -0.0491391122341156,
      0.037107791751623154,
      -0.01501590944826603,
      0.028008535504341125,
      -0.04260004311800003,
      0.0228432510048151,
      0.04547999054193497,
      -0.001041107578203082,
      -0.005702839232981205,
      -0.08635540306568146,
      0.02793736755847931,
      -0.02767382375895977,
      0.03379473835229874,
      0.07808427512645721,
      -0.02559584565460682,
      0.017831753939390182,
      0.07304511219263077,
      -0.010135549120604992,
      0.01236589066684246,
      0.0326547808945179,
      0.04521015286445618,
      0.012351189740002155,
      -0.023321356624364853,
      0.011940410360693932,
      -0.03855232894420624,
      -0.021842610090970993,
      0.030650395900011063,
      0.019131703302264214,
      0.02964242920279503,
      -0.0597408227622509,
      -0.0332399345934391,
      0.0010598847875371575,
      -0.03462526574730873,
      -0.04675349220633507,
      -0.061721593141555786,
      0.00011864494445035234,
      -0.041523415595293045,
      -0.03747974708676338,
      -0.009428558871150017,
      -0.10423488169908524,
      0.0056695169769227505,
      0.05313181132078171,
      0.07177847623825073,
      -0.056254152208566666,
      -0.019295521080493927,
      0.038128700107336044,
      0.039198633283376694,
      0.05378366634249687,
      -0.03627961501479149,
      0.07545047998428345,
      0.005739705637097359,
      0.051741037517786026,
      -0.09903667867183685,
      0.048240870237350464,
      0.05955079570412636,
      0.02365569956600666,
      0.02413148060441017,
      -0.06543029844760895,
      0.06723248213529587,
      -0.007646829821169376,
      -0.010901602916419506,
      0.038137830793857574,
      0.06193983927369118,
      -0.12184042483568192,
      -0.08876227587461472,
      -0.02896801196038723,
      -0.01313752494752407,
      -0.01858861930668354,
      0.05124269053339958,
      0.03760543465614319,
      -0.020487306639552116,
      0.04216181859374046,
      0.06803387403488159,
      0.005128210410475731,
      -0.03606938198208809,
      0.04870901629328728,
      0.09974314272403717,
      0.0886208564043045,
      0.021014278754591942,
      -0.03165845572948456,
      -0.019066598266363144,
      0.031115585938096046,
      -0.03772332891821861,
      0.02135339379310608,
      -0.06690718233585358,
      0.026346491649746895,
      -0.04603001847863197,
      0.013371368870139122,
      0.05081617459654808,
      -0.04207264259457588,
      -0.0888524204492569,
      0.09059638530015945,
      -0.028772924095392227,
      -0.03241286426782608,
      -0.05514365807175636,
      -0.085194893181324,
      -0.006348470691591501
    ],
    [
      -0.04449600726366043,
      0.05220695957541466,
      0.0034100308548659086,
      0.053035538643598557,
      -0.05517321825027466,
      -0.008861473761498928,
      0.04835725575685501,
      0.042613934725522995,
      0.03304322063922882,
      -0.001620921422727406,
      0.005477582570165396,
      -0.027553245425224304,
      -0.036351144313812256,
      0.05607946962118149,
      -0.023099340498447418,
      -0.07335192710161209,
      -0.028470221906900406,
      -0.025208571925759315,
      0.023663287982344627,
      -0.025955848395824432,
      0.11006475239992142,
      -0.04108240455389023,
      -0.03362823277711868,
      -0.01001154724508524,
      0.0283731110394001,
      -0.007431235630065203,
      -0.00361426267772913,
      0.03785203397274017,
      0.011937158182263374,
      0.03335310146212578,
      -0.008333558216691017,
      -0.08215589821338654,
      0.08548535406589508,
      -0.05536719411611557,
      0.07648829370737076,
      0.04307597130537033,
      -0.02873922325670719,
      -0.029016295447945595,
      0.011372747831046581,
      0.0036010914482176304,
      0.0006346204318106174,
      0.06324391812086105,
      0.0034167736303061247,
      0.04541522264480591,
      -0.03437713906168938,
      0.005905988626182079,
      0.02667154185473919,
      -0.03833819180727005,
      -0.03477942943572998,
      0.06944861263036728,
      -0.027144506573677063,
      -0.023949919268488884,
      0.02385677769780159,
      -0.036437299102544785,
      0.019286543130874634,
      -0.017570137977600098,
      0.05678410828113556,
      -0.044827938079833984,
      0.03896420821547508,
      -0.03560924530029297,
      0.0105516342446208,
      -0.00906524620950222,
      0.0469096340239048,
      0.029019895941019058,
      0.03316788002848625,
      -0.011593010276556015,
      -0.02997659705579281,
      -0.034961771219968796,
      -0.06273951381444931,
      0.009630400687456131,
      0.05058899521827698,
      -0.00856381468474865,
      -0.046052444726228714,
      -0.01658780127763748,
      -0.011707877740263939,
      -0.002421855228021741,
      -0.010189046151936054,
      -0.09255687892436981,
      -0.06817421317100525,
      0.022530095651745796,
      0.04323509708046913,
      0.04075811058282852,
      0.009492116048932076,
      -0.012589162215590477,
      0.14039543271064758,
      0.02037193439900875,
      0.06803301721811295,
      -0.019387297332286835,
      -0.007368456106632948,
      0.018180176615715027,
      0.008255784399807453,
      -0.01676389016211033,
      0.060964979231357574,
      0.03615310788154602,
      0.048618387430906296,
      -0.04501672089099884,
      0.009679112583398819,
      0.04696109890937805,
      0.0807415246963501,
      0.0013722936855629086,
      -0.00010510313586564735,
      -0.003522814018651843,
      -0.026235265657305717,
      0.0005504966829903424,
      0.04573274776339531,
      -0.054195478558540344,
      0.042805831879377365,
      -0.05643703415989876,
      0.01147476490586996,
      0.01482472475618124,
      0.037873443216085434,
      0.0415351428091526,
      -0.032020196318626404,
      0.029806025326251984,
      -0.04413820058107376,
      0.046088118106126785,
      0.05054998770356178,
      -0.04364968091249466,
      -0.0238737054169178,
      0.10152100771665573,
      0.03364510089159012,
      -0.014915511012077332,
      0.003877290291711688,
      -0.02810848131775856,
      0.044001009315252304,
      -0.11455915868282318,
      0.006853740196675062,
      0.03472384437918663,
      0.03560960292816162,
      0.008180418983101845,
      0.09433582425117493,
      -0.057572007179260254,
      0.06524721533060074,
      0.01550760492682457,
      -0.008423198945820332,
      0.0013545245165005326,
      -0.003350985236465931,
      -0.09020049124956131,
      0.06984275579452515,
      0.034192390739917755,
      -0.030988819897174835,
      -0.03406604751944542,
      0.1019444614648819,
      0.0884479433298111,
      0.0268431156873703,
      -0.027947604656219482,
      -0.043838560581207275,
      -0.028740989044308662,
      -0.03935691714286804,
      -0.01116122119128704,
      -0.06356581300497055,
      0.048409756273031235,
      -0.07142023742198944,
      -0.07822996377944946,
      -0.08218642324209213,
      0.0712619423866272,
      0.04111240804195404,
      0.03345934674143791,
      -0.012615482322871685,
      0.007956755347549915,
      0.027918776497244835,
      -0.010951109230518341,
      -0.07895531505346298,
      -0.022553278133273125,
      -0.007657104171812534,
      -0.03167867287993431,
      -0.027283206582069397,
      -0.02700832486152649,
      0.045029960572719574,
      -0.006727860774844885,
      0.016712209209799767,
      -0.030842093750834465,
      0.010307589545845985,
      0.023657428100705147,
      -0.061348628252744675,
      -0.05677587166428566,
      0.052186425775289536,
      0.053118038922548294,
      -0.018922964110970497,
      -0.05222329497337341,
      0.058506473898887634,
      -0.01459331065416336,
      -0.02863847278058529,
      -0.05575890466570854,
      -0.006505629979074001,
      0.008686095476150513,
      -0.09595247358083725,
      -0.03501545265316963,
      -0.035820987075567245,
      0.06044692173600197,
      0.017288532108068466,
      -0.0427502803504467,
      -0.09530345350503922,
      0.00968215148895979,
      -0.005026476923376322,
      -0.01512724719941616,
      0.01606649160385132,
      -0.0007429226534441113,
      -0.02863754704594612,
      0.0488116629421711,
      0.010691753588616848,
      -0.005054274573922157,
      -0.02324269339442253,
      -0.02065460756421089,
      -0.07304657995700836,
      -0.10949181765317917,
      0.019062966108322144,
      0.06288338452577591,
      -0.05017458274960518,
      -0.04086700826883316,
      -0.07763691246509552,
      0.020473415032029152,
      0.009374923072755337,
      0.05157439410686493,
      0.020522207021713257,
      -0.014835520647466183,
      0.009964711032807827,
      -0.04115373268723488,
      0.012245940044522285,
      0.03102303482592106,
      0.0224891509860754,
      -0.04412870109081268,
      0.003954078536480665,
      0.02893504872918129,
      -0.00026022447855211794,
      0.021830903366208076,
      0.037482138723134995,
      -0.019428800791502,
      0.036460381001234055,
      0.016748731955885887,
      -0.046991460025310516,
      -0.025865603238344193,
      0.043421365320682526,
      0.024095680564641953,
      -0.020393170416355133,
      0.026221806183457375,
      0.011276922188699245,
      0.0049111489206552505,
      -0.011767497286200523,
      0.07015033811330795,
      -0.05954505503177643,
      -0.05007249861955643,
      0.052878234535455704,
      0.0641423910856247,
      -0.03498521447181702,
      -0.0011788768460974097,
      0.007748561445623636,
      0.01137502584606409,
      0.06065617874264717,
      0.045865826308727264,
      0.045866601169109344,
      -0.009336029179394245,
      0.011974279768764973,
      0.036340564489364624,
      -0.012019654735922813,
      -0.04569466784596443,
      -0.034946735948324203,
      0.03661544620990753,
      -0.02779180184006691,
      -0.0559609979391098,
      0.016097281128168106,
      0.04756998270750046,
      0.06398379057645798,
      -0.011198580265045166,
      -0.01660868711769581,
      0.06711869686841965,
      0.04539213329553604,
      -0.06765483319759369,
      0.0007704970776103437,
      -0.009551430121064186,
      0.019549906253814697,
      0.0038629837799817324,
      -0.022691162303090096,
      -0.009241722524166107,
      0.022979365661740303,
      0.10074526071548462,
      -0.013040335848927498,
      0.02512078918516636,
      0.05842830613255501,
      -0.006366790272295475,
      -0.006656057201325893,
      0.011308781802654266,
      0.041894856840372086,
      0.06304582953453064,
      0.01991826854646206,
      0.10864787548780441,
      0.08057035505771637,
      -0.0015038574347272515,
      -0.008381926454603672,
      0.020005997270345688,
      0.035335905849933624,
      0.0209830179810524,
      -0.02611064352095127,
      -0.14506080746650696,
      0.057138968259096146,
      0.02822568081319332,
      0.00935166422277689,
      -0.042644888162612915,
      -0.04490850120782852,
      0.05316479876637459,
      0.016137953847646713,
      0.07476265728473663,
      0.035063084214925766,
      -0.020502706989645958,
      0.04310331493616104,
      0.022791292518377304,
      0.049722686409950256,
      -0.04705703631043434,
      -0.04060663282871246,
      0.03047126531600952,
      -0.08792587369680405,
      0.0016797676216810942,
      0.06388092786073685,
      -0.011127457022666931,
      0.010238738730549812,
      0.009530508890748024,
      0.004014903213828802,
      0.08064860850572586,
      -0.04987203702330589,
      -0.00950925424695015,
      -0.061729662120342255,
      -0.0841122567653656,
      0.027716927230358124,
      0.07346651703119278,
      0.016904959455132484,
      -0.07014569640159607,
      0.0003264133702032268,
      0.02165953628718853,
      0.01985425315797329,
      -0.09960755705833435,
      0.028515588492155075,
      0.05648456886410713,
      0.07217767089605331,
      -0.007539324928075075,
      0.04448673129081726,
      0.004142708145081997,
      0.052835945039987564,
      -0.03389418125152588,
      0.003538302145898342,
      -0.020873425528407097,
      -0.01919606514275074,
      -0.04244422912597656,
      -0.018626447767019272,
      0.10564330965280533,
      -0.04647845774888992,
      -0.029578983783721924,
      0.0035276913549751043,
      -0.06315479427576065,
      0.04764416441321373,
      -0.017243679612874985,
      0.01256430335342884,
      0.06621168553829193,
      0.06844872236251831,
      -0.05090847238898277,
      0.025683803483843803,
      0.029029466211795807,
      -0.04633192718029022,
      -0.03956913575530052,
      -0.06929720938205719,
      0.02272375300526619,
      -0.005639339331537485,
      0.03266916051506996,
      0.10298457741737366,
      -0.06545177847146988,
      0.011089272797107697,
      0.01490035094320774,
      0.05262156203389168,
      0.0810883417725563,
      0.031497687101364136,
      0.032616499811410904,
      -0.01806074008345604,
      0.024439847096800804,
      -0.09390944987535477,
      0.026583034545183182,
      -0.053812455385923386,
      -0.08853181451559067,
      -0.04286407306790352,
      -0.06377682089805603,
      -0.018228888511657715,
      0.02515362948179245,
      0.028298890218138695,
      0.0933629497885704,
      -0.018593020737171173,
      0.006346740759909153,
      -0.05551939457654953,
      0.040881745517253876,
      -0.038102585822343826,
      -0.0016422586049884558,
      0.015838313847780228,
      -0.026819655671715736,
      -0.09164317697286606,
      0.0625821202993393,
      -0.07313739508390427,
      -0.028133714571595192,
      0.01867491379380226,
      -0.012530392035841942,
      0.06226327642798424,
      0.03392471373081207,
      0.026411179453134537,
      -0.0633082315325737,
      -0.0032610923517495394,
      0.008754199370741844,
      -0.005698997527360916,
      0.03140688315033913,
      0.07453162968158722,
      -0.018260370939970016,
      -0.039546430110931396,
      0.050725750625133514,
      0.003663053037598729,
      0.012103539891541004,
      -0.011702977120876312,
      -0.06216373294591904,
      -0.018993515521287918,
      0.012576926499605179,
      -0.07201919704675674,
      0.10802121460437775,
      -0.008141221478581429,
      0.007486910559237003,
      0.005774066783487797,
      0.029950987547636032,
      0.01633491925895214,
      0.06114549562335014,
      -0.05644509941339493,
      0.05920807272195816,
      0.00044058775529265404,
      -0.06757407635450363,
      0.03852442651987076,
      0.02165498584508896,
      0.0001541490200906992,
      0.018259741365909576,
      0.05917328596115112,
      -0.005777893587946892,
      -0.03173203393816948,
      -0.006390028167515993,
      -0.08182357251644135,
      0.027631297707557678,
      0.02437201887369156,
      -0.07553832232952118,
      0.040759071707725525,
      -0.06016533076763153,
      -0.0565638430416584,
      -0.07805122435092926,
      -0.10938359797000885,
      0.06781493127346039,
      0.017575180158019066,
      -0.06662733852863312,
      0.017023757100105286,
      -0.011721051298081875,
      -0.05778845027089119,
      -0.07202800363302231,
      -0.03966667130589485,
      -0.05655713379383087,
      0.0013183580012992024,
      -0.018413126468658447,
      -0.033100757747888565,
      0.04831886291503906,
      0.07887572795152664,
      -0.0768861249089241,
      -0.018190113827586174,
      0.05308153107762337,
      -0.008965760469436646,
      0.007801502011716366,
      0.010436123237013817,
      0.0033649043180048466,
      -0.045233506709337234,
      -0.06768612563610077,
      -0.0980965718626976,
      0.11521758884191513,
      0.08344496041536331,
      0.015660222619771957,
      -0.040261510759592056,
      0.023765940219163895,
      -0.004862760193645954,
      0.0327058881521225,
      -0.017101863399147987,
      0.009577042423188686,
      -0.051473308354616165,
      -0.04411458969116211,
      -0.006106120068579912,
      -0.026422427967190742,
      0.022519243881106377,
      0.0554862841963768,
      -0.017411962151527405,
      -0.07197985053062439,
      -0.037780217826366425,
      -0.046668656170368195,
      -0.14045414328575134,
      0.07442957907915115,
      0.055836088955402374,
      -0.04337532818317413,
      0.03010042943060398,
      0.07640117406845093,
      -0.01947692595422268,
      -0.005168149247765541,
      -0.008810113184154034,
      0.026052819564938545,
      0.045443095266819,
      0.07493333518505096,
      0.07633882015943527,
      0.02068665809929371,
      -0.03533133491873741,
      -0.015218181535601616,
      0.10131309926509857
    ],
    [
      0.027936717495322227,
      -0.04542269557714462,
      -0.043322425335645676,
      0.04095113277435303,
      -0.016127804294228554,
      0.05691036581993103,
      0.025854039937257767,
      0.04268336668610573,
      -0.03447314351797104,
      -0.06136033311486244,
      -0.07624094933271408,
      0.05466395989060402,
      -0.03810978680849075,
      0.03288822993636131,
      -0.006627775263041258,
      -0.025352511554956436,
      -0.022996146231889725,
      0.10125178843736649,
      -0.035642027854919434,
      -0.05526979640126228,
      -0.03066388890147209,
      0.05227069929242134,
      -0.01668868213891983,
      -0.016907859593629837,
      0.018441522493958473,
      0.023885512724518776,
      -0.025363324210047722,
      0.06085263192653656,
      -0.02661583200097084,
      0.0011076072696596384,
      0.017791587859392166,
      0.02583606168627739,
      0.02790919505059719,
      0.0034170986618846655,
      -0.08909926563501358,
      0.08767668902873993,
      -0.06459415704011917,
      -0.027331920340657234,
      -0.104282446205616,
      -0.10464035719633102,
      0.017332030460238457,
      0.08548346906900406,
      -0.031144198030233383,
      0.02251412160694599,
      -0.01079219114035368,
      -0.08194254338741302,
      0.0233981404453516,
      -0.02550947107374668,
      0.06700512021780014,
      -0.04283541440963745,
      0.02484813705086708,
      -0.024501103907823563,
      -0.060771968215703964,
      0.037927381694316864,
      -0.039827294647693634,
      0.0351695790886879,
      0.010539260692894459,
      0.029762467369437218,
      0.02187974937260151,
      -0.025696413591504097,
      -0.04510574787855148,
      0.0342167504131794,
      0.01745571568608284,
      0.019329529255628586,
      -0.007515274453908205,
      -0.019472356885671616,
      0.00804638396948576,
      0.00045776242041029036,
      0.0025763793382793665,
      0.011249896138906479,
      0.015933025628328323,
      -0.019778212532401085,
      -0.0881957858800888,
      0.0811627209186554,
      -0.025306103751063347,
      0.01126832701265812,
      -0.0029832394793629646,
      0.045813046395778656,
      -0.08338390290737152,
      0.04384752735495567,
      -0.01971629075706005,
      -0.020622767508029938,
      -0.025455832481384277,
      0.009901901707053185,
      -0.03439436852931976,
      -0.05810214951634407,
      0.06765833497047424,
      0.03781508654356003,
      -0.018215307965874672,
      0.0651322677731514,
      -0.010658848099410534,
      0.04792185500264168,
      0.019996406510472298,
      -0.017717299982905388,
      -0.027291450649499893,
      -0.0290264580398798,
      -0.007412702310830355,
      -0.009053537622094154,
      0.061201173812150955,
      -0.0842941403388977,
      0.047480564564466476,
      0.049299970269203186,
      0.012848429381847382,
      0.04657847806811333,
      -0.011827207170426846,
      -0.01710071787238121,
      -0.017542578279972076,
      -0.04264784976840019,
      -0.043617960065603256,
      -0.02827596291899681,
      0.028372272849082947,
      -0.012481036596000195,
      0.06355341523885727,
      0.07792790979146957,
      -0.045887913554906845,
      0.03770466893911362,
      -0.017676692456007004,
      0.07119249552488327,
      0.0361030250787735,
      -0.026416512206196785,
      0.041912879794836044,
      -0.028342105448246002,
      0.1136460155248642,
      0.0070121875032782555,
      0.031147433444857597,
      0.017729055136442184,
      0.052746523171663284,
      0.03997080400586128,
      -0.11148373037576675,
      0.02409742958843708,
      -0.0327012836933136,
      0.00920539628714323,
      0.09219630062580109,
      -0.04701543226838112,
      0.09521981328725815,
      0.014341752976179123,
      0.059808943420648575,
      0.07217688858509064,
      0.024042129516601562,
      -0.02441178448498249,
      -0.020810311660170555,
      0.06127677485346794,
      -0.03583524748682976,
      0.05160566419363022,
      -0.062243618071079254,
      -0.0004058227641507983,
      0.001364652649499476,
      0.010379137471318245,
      0.04967286437749863,
      0.03594737872481346,
      -0.008993923664093018,
      0.022521190345287323,
      0.022283362224698067,
      0.05023110285401344,
      0.044228941202163696,
      -0.05350344255566597,
      -0.024463560432195663,
      0.050955258309841156,
      -0.021762870252132416,
      -0.0036179653834551573,
      -0.02006865106523037,
      0.003460857318714261,
      -0.018851885572075844,
      -0.11392183601856232,
      0.03648518770933151,
      0.025576556101441383,
      -0.02964148484170437,
      0.010554483160376549,
      -0.0028206082060933113,
      0.0036561968736350536,
      -0.011671255342662334,
      -0.07420781999826431,
      -0.05944496765732765,
      -0.04486911743879318,
      0.041765786707401276,
      0.025842592120170593,
      -0.06665480136871338,
      -0.10119786113500595,
      -0.0240546353161335,
      -0.06948923319578171,
      -0.047087639570236206,
      -0.03415076062083244,
      -0.02814139984548092,
      -0.011668730527162552,
      -0.012783650308847427,
      -0.03208195045590401,
      0.0007739040302112699,
      0.05294228717684746,
      -0.002227821620181203,
      0.031525444239377975,
      0.02826608717441559,
      0.08711178600788116,
      -0.015568574890494347,
      -0.0185355544090271,
      0.011731334030628204,
      0.0125280087813735,
      -0.03710341081023216,
      -0.04227404296398163,
      0.03108108974993229,
      -0.040284089744091034,
      0.05401008203625679,
      0.09340269863605499,
      -0.020685220137238503,
      0.09095991402864456,
      -0.024973064661026,
      -0.03776464983820915,
      0.015672989189624786,
      -0.0767749473452568,
      -0.07312576472759247,
      0.02121201902627945,
      0.05976130813360214,
      -0.012267997488379478,
      -0.003712283680215478,
      0.010258780792355537,
      0.0711904838681221,
      0.05109887942671776,
      0.006540539674460888,
      0.003863007528707385,
      0.03912581875920296,
      0.01812361553311348,
      0.04826375097036362,
      0.06397347897291183,
      0.029174700379371643,
      0.013691022992134094,
      0.02887812629342079,
      -0.024880578741431236,
      -0.09060294926166534,
      -0.0482020303606987,
      0.054725341498851776,
      0.022204777225852013,
      -0.023852968588471413,
      -0.0032831504940986633,
      0.05952218174934387,
      -0.05585478991270065,
      -0.06417692452669144,
      0.01465925294905901,
      -0.0034495906438678503,
      -0.07965599000453949,
      0.009175840765237808,
      0.011477525345981121,
      0.04532650113105774,
      -0.023487042635679245,
      -0.028100380674004555,
      0.048599932342767715,
      0.06041842699050903,
      -0.009424589574337006,
      0.06636974215507507,
      0.00957988016307354,
      0.052429284900426865,
      -0.04061342775821686,
      -0.04456338286399841,
      0.015416310168802738,
      0.07902713119983673,
      0.0709562823176384,
      -0.028448646888136864,
      0.04171102121472359,
      0.011743560433387756,
      4.554539918899536e-05,
      -0.05836663022637367,
      -0.03178946301341057,
      0.05991024151444435,
      0.034691620618104935,
      0.04147575423121452,
      0.0166914165019989,
      0.015868457034230232,
      0.017473945394158363,
      -0.004048895090818405,
      0.05478749796748161,
      0.046711113303899765,
      0.03569561988115311,
      -0.0031945505179464817,
      0.031123021617531776,
      0.007703648880124092,
      -0.037568289786577225,
      0.048994097858667374,
      0.0030795508064329624,
      0.049834925681352615,
      0.03522517532110214,
      -0.06655169278383255,
      -0.021765155717730522,
      -0.04752696678042412,
      0.06388986110687256,
      0.06154605373740196,
      0.03263132646679878,
      0.011727508157491684,
      -0.020742159336805344,
      -0.09913529455661774,
      0.032507967203855515,
      0.03366611897945404,
      0.03417586535215378,
      -0.00549212796613574,
      -0.016409706324338913,
      0.006532712839543819,
      0.07511978596448898,
      0.031869009137153625,
      -0.016277173534035683,
      -0.07363636046648026,
      0.01596733182668686,
      0.05544213578104973,
      -0.01574638858437538,
      -0.022143153473734856,
      -0.015725091099739075,
      0.0023701530881226063,
      -0.03779752552509308,
      0.0015211703721433878,
      -0.06374911218881607,
      0.03928911313414574,
      -0.013354445807635784,
      -0.030083922669291496,
      0.022171443328261375,
      -0.04287796840071678,
      0.057640936225652695,
      0.03487521782517433,
      -0.03162134066224098,
      -0.004097913857549429,
      0.006339682266116142,
      0.00835970789194107,
      0.03405313566327095,
      -0.06010312959551811,
      0.053102850914001465,
      0.009336975403130054,
      -0.02059025689959526,
      0.1015605628490448,
      0.08677498996257782,
      0.044850364327430725,
      -0.08518008142709732,
      0.02898697927594185,
      0.023385297507047653,
      -0.051910363137722015,
      -0.0264058206230402,
      -0.02376040443778038,
      0.0053432113490998745,
      0.014178937301039696,
      0.08775173872709274,
      -0.03302052617073059,
      -0.03600924834609032,
      0.011046613566577435,
      -0.016634726896882057,
      0.011865568347275257,
      0.06847649812698364,
      -0.048437830060720444,
      0.06668959558010101,
      -0.013150318525731564,
      0.005201605148613453,
      -0.04002329334616661,
      0.015135213732719421,
      -0.06170125678181648,
      -0.043430883437395096,
      -0.01866985857486725,
      -0.017721012234687805,
      0.05569733679294586,
      0.038052551448345184,
      0.028112182393670082,
      -0.02165219932794571,
      -0.02731435000896454,
      -0.033604320138692856,
      0.06680385768413544,
      -0.13908322155475616,
      -0.005540199112147093,
      0.0013786007184535265,
      0.01676211878657341,
      0.019213596358895302,
      -0.07263774424791336,
      -0.05790766328573227,
      -0.012106520123779774,
      -0.01058370154350996,
      0.0021609249524772167,
      -0.03388587757945061,
      -0.022636618465185165,
      -0.05004952475428581,
      -0.026449624449014664,
      0.03576722368597984,
      -0.00375517550855875,
      0.03458480164408684,
      -0.0015420361887663603,
      -0.04409506916999817,
      -0.05241076648235321,
      -0.023611614480614662,
      -0.011754312552511692,
      0.1237873062491417,
      0.03675020486116409,
      -0.07877165824174881,
      0.017946481704711914,
      -0.00493614561855793,
      0.012793018482625484,
      0.08610828220844269,
      0.06672937422990799,
      -0.003136775689199567,
      0.058485809713602066,
      0.011867483146488667,
      0.024191970005631447,
      -0.0705975890159607,
      -0.0384262390434742,
      0.062067337334156036,
      0.041841521859169006,
      0.10142066329717636,
      0.011006886139512062,
      0.03330482915043831,
      -0.04532305896282196,
      0.07736428081989288,
      -0.055469829589128494,
      -0.01566866599023342,
      0.017082329839468002,
      0.04307274520397186,
      0.020292971283197403,
      -0.04628964513540268,
      0.04657541587948799,
      -0.006235345732420683,
      0.011339307762682438,
      0.011936232447624207,
      -0.05692815035581589,
      0.012093987315893173,
      0.01850668527185917,
      0.02112647332251072,
      -0.01930791139602661,
      -0.024327078834176064,
      0.0019736438989639282,
      -0.01324793603271246,
      -0.019514000043272972,
      0.08999980241060257,
      0.03484008088707924,
      0.035667359828948975,
      0.031371138989925385,
      0.02464214339852333,
      -0.037442270666360855,
      -0.06661570817232132,
      -0.028765128925442696,
      0.05229157954454422,
      0.0008349160198122263,
      0.0031042946502566338,
      -0.041986867785453796,
      -0.15728245675563812,
      0.006542770657688379,
      -0.03501328453421593,
      0.007562987040728331,
      -0.002665117383003235,
      0.027472833171486855,
      -0.0019763740710914135,
      0.015836825594305992,
      8.363003144040704e-05,
      -0.04161886125802994,
      -0.08185863494873047,
      0.02169426530599594,
      -0.027841724455356598,
      -0.01004390325397253,
      -0.08148834109306335,
      -0.043747130781412125,
      -0.09908109158277512,
      -0.03803868591785431,
      -0.010024572722613811,
      -0.04021482169628143,
      0.020462429150938988,
      -0.03321359306573868,
      -0.017455965280532837,
      0.026927128434181213,
      -0.030210569500923157,
      0.03140190243721008,
      -0.027768436819314957,
      0.04712242633104324,
      0.035891108214855194,
      0.03232193365693092,
      -0.045138563960790634,
      -0.035245973616838455,
      0.019827205687761307,
      -0.10570138692855835,
      -0.021749146282672882,
      0.007024497725069523,
      -0.011694290675222874,
      0.016446124762296677,
      0.028137287124991417,
      -0.016475442796945572,
      0.005890762899070978,
      0.02370860055088997,
      0.004185602068901062,
      0.0056690131314098835,
      -0.0781538337469101,
      0.026162635535001755,
      0.07678412646055222,
      -0.002344876993447542,
      -0.016168853268027306,
      0.009098900482058525,
      0.007403884548693895,
      -0.04092840477824211,
      -0.03716197982430458,
      0.010035247541964054,
      -0.09618153423070908,
      0.020932000130414963,
      0.010138874873518944,
      0.01675265282392502,
      -0.03355662152171135,
      0.0758046805858612,
      0.003060457529500127,
      0.036524105817079544,
      -0.0038077745120972395,
      -0.0033478885889053345,
      0.0002709189720917493,
      -0.01148256752640009,
      0.04659023508429527,
      0.08226840198040009,
      -0.06052932143211365,
      -0.0007824207423254848,
      0.03379011154174805,
      -0.023184802383184433
    ],
    [
      0.0464123971760273,
      -0.008784105069935322,
      0.017040476202964783,
      -0.06014031916856766,
      0.013954236172139645,
      0.00015219055057968944,
      0.0820661336183548,
      0.07340408116579056,
      0.05709443241357803,
      -0.08696038275957108,
      0.015014815144240856,
      0.08067665994167328,
      0.06264567375183105,
      -0.041016045957803726,
      0.038933489471673965,
      -0.043549083173274994,
      -0.03890770301222801,
      -0.002692362992092967,
      -0.02902943082153797,
      -0.06635576486587524,
      0.03777698054909706,
      -0.029658755287528038,
      -0.044955529272556305,
      0.059093739837408066,
      -0.002612914890050888,
      0.03713933378458023,
      0.02818836271762848,
      -0.06169256195425987,
      -0.03609462082386017,
      -0.03459254279732704,
      -0.021954286843538284,
      0.020022789016366005,
      -0.0597146712243557,
      0.020570114254951477,
      -0.021993542090058327,
      -0.032963111996650696,
      -0.01522751059383154,
      -0.026982160285115242,
      -0.0009785739239305258,
      0.012849724851548672,
      0.018811441957950592,
      0.006436767987906933,
      -0.06453873962163925,
      0.03466067090630531,
      -0.02006329968571663,
      0.042090367525815964,
      0.04135729745030403,
      0.030044106766581535,
      -0.018621861934661865,
      -0.026697784662246704,
      0.007945144549012184,
      0.12112737447023392,
      -0.06305693089962006,
      -0.04801122844219208,
      0.058634113520383835,
      0.0936366617679596,
      0.008850786834955215,
      -0.04495702683925629,
      0.02621839940547943,
      0.034743089228868484,
      -0.04045535624027252,
      0.015849096700549126,
      0.005695206578820944,
      0.031614143401384354,
      0.0739254504442215,
      -0.04476042464375496,
      -0.026265399530529976,
      -0.0070345052517950535,
      -0.024425139650702477,
      0.024971462786197662,
      -0.05493544787168503,
      -0.10728845745325089,
      0.005905897356569767,
      -0.0009534272830933332,
      0.02840231917798519,
      0.009398826397955418,
      -0.04719851538538933,
      -0.06302940100431442,
      -0.04917748272418976,
      0.037237104028463364,
      0.04029844328761101,
      -0.024565232917666435,
      0.012973412871360779,
      0.05595283955335617,
      -0.015169777907431126,
      0.05493517220020294,
      0.13305197656154633,
      0.051913321018218994,
      0.04145433381199837,
      -0.002971423789858818,
      0.01876087114214897,
      0.015649130567908287,
      0.06471983343362808,
      -0.05537891387939453,
      -0.0014831653097644448,
      -0.116444893181324,
      0.02766377665102482,
      -0.041212547570466995,
      0.01754133775830269,
      -0.020174676552414894,
      0.08787363022565842,
      -0.016091028228402138,
      0.07013500481843948,
      -0.041591234505176544,
      -0.0033843412529677153,
      0.04547598212957382,
      -0.044724397361278534,
      0.004006748087704182,
      0.035387828946113586,
      0.024165315553545952,
      0.05813034996390343,
      0.020880598574876785,
      -0.02487485110759735,
      -0.07826635241508484,
      0.010026682168245316,
      -0.008030116558074951,
      0.05164165422320366,
      -0.052717018872499466,
      0.03308115527033806,
      -0.008100433275103569,
      -0.0035913612227886915,
      0.041598279029130936,
      0.035906706005334854,
      -0.035345409065485,
      0.03562476858496666,
      0.04516424611210823,
      -0.0006746880244463682,
      0.07206438481807709,
      0.03226097300648689,
      -0.10756651312112808,
      0.004657500423491001,
      0.04599081352353096,
      -0.053435735404491425,
      -0.03262797370553017,
      0.0026097644586116076,
      -0.008886100724339485,
      -0.04792522266507149,
      -0.005231095477938652,
      0.00030473305378109217,
      -0.02451464906334877,
      0.08955975621938705,
      -0.014874842949211597,
      -0.06446129828691483,
      0.03656763955950737,
      -0.03726494312286377,
      -0.016570141538977623,
      0.005605746526271105,
      -0.009614363312721252,
      -0.05102407559752464,
      -0.010675604455173016,
      0.05101122707128525,
      -0.019708270207047462,
      -0.007709830068051815,
      0.027366159483790398,
      -0.02678435854613781,
      0.06985949724912643,
      -0.06629504263401031,
      0.031082168221473694,
      -0.0003957709122914821,
      -0.09260216355323792,
      0.07236190140247345,
      0.04717375338077545,
      -0.022197924554347992,
      -0.034411001950502396,
      -0.026159603148698807,
      -0.008350444957613945,
      -0.0629999116063118,
      0.0411374606192112,
      -0.060501161962747574,
      0.04560258239507675,
      -0.018736114725470543,
      -0.008881050162017345,
      -0.043637730181217194,
      0.02017083391547203,
      -0.026898115873336792,
      0.011839627288281918,
      -0.041336316615343094,
      0.11161790788173676,
      -0.0191000085324049,
      0.031246401369571686,
      -0.08612405508756638,
      -0.04810839518904686,
      -0.01478440873324871,
      0.0814942717552185,
      -0.006856999825686216,
      -0.05140204355120659,
      -0.0398845337331295,
      0.023738674819469452,
      0.032436735928058624,
      0.004618407692760229,
      -0.045075055211782455,
      0.034939687699079514,
      -0.046728767454624176,
      0.09613590687513351,
      0.04074601083993912,
      -0.03716091439127922,
      0.06479953974485397,
      -0.040060222148895264,
      -0.013804705813527107,
      0.03566320240497589,
      -0.019046995788812637,
      -0.012435943819582462,
      -0.0068093882873654366,
      -0.08584532141685486,
      0.06378553062677383,
      -0.009767100214958191,
      -0.017761506140232086,
      -0.07006112486124039,
      -0.050585921853780746,
      -0.05556352809071541,
      -0.05133074149489403,
      -0.04308130964636803,
      -0.01736454665660858,
      -0.03291041776537895,
      0.028385402634739876,
      0.004693570081144571,
      0.005348216276615858,
      0.09936492145061493,
      -0.025699404999613762,
      0.07863166183233261,
      0.07696026563644409,
      -0.002432196168228984,
      -0.037130940705537796,
      0.008205528371036053,
      -0.006165259517729282,
      0.005291457753628492,
      0.006665508262813091,
      0.07706865668296814,
      -0.03491578623652458,
      0.03443312272429466,
      -0.019637826830148697,
      -0.058837343007326126,
      0.03159463778138161,
      0.013531593605875969,
      -0.01598631590604782,
      -0.04319918528199196,
      -0.007059954106807709,
      0.026870209723711014,
      -0.08667387813329697,
      0.026290079578757286,
      0.04899316653609276,
      -0.010469387285411358,
      0.07246864587068558,
      0.036864377558231354,
      0.02379428781569004,
      -0.03083203174173832,
      -0.01934027299284935,
      0.03533779829740524,
      -0.047059252858161926,
      -0.07923131436109543,
      -0.006239377427846193,
      -0.021294184029102325,
      -0.009035289287567139,
      0.028737381100654602,
      -0.09951921552419662,
      0.0069483197294175625,
      -0.02357230894267559,
      0.007835393771529198,
      0.04281570762395859,
      0.10368014127016068,
      0.07053525000810623,
      -0.0485403910279274,
      0.02920292317867279,
      0.05862946808338165,
      -0.08042263239622116,
      0.004712122026830912,
      -0.004028178285807371,
      0.024548452347517014,
      0.01177377812564373,
      -0.007218402344733477,
      -0.023700127378106117,
      -0.023060133680701256,
      -0.05948982015252113,
      0.025588905438780785,
      0.05236811563372612,
      -0.055244024842977524,
      -0.01981933042407036,
      0.011906841769814491,
      -0.03655799850821495,
      -0.008578768000006676,
      -0.045088425278663635,
      0.05068483576178551,
      0.07561655342578888,
      0.07551180571317673,
      0.03595936670899391,
      0.0500866174697876,
      0.017222875729203224,
      0.06477003544569016,
      0.09121110290288925,
      -0.03485685586929321,
      -0.030307361856102943,
      -0.02585550770163536,
      -0.042387280613183975,
      0.050773512572050095,
      0.0771244540810585,
      -0.015005196444690228,
      -0.06065184623003006,
      0.026362992823123932,
      -0.08475715667009354,
      0.02645375020802021,
      -0.10150006413459778,
      -0.02518555335700512,
      -0.016148079186677933,
      0.01606251299381256,
      0.0025913307908922434,
      -0.06210363283753395,
      -0.06843335926532745,
      0.010355970822274685,
      -0.018203800544142723,
      0.028674710541963577,
      0.04047289118170738,
      0.02978408895432949,
      0.06552866846323013,
      0.03925969824194908,
      0.09277399629354477,
      0.030853718519210815,
      0.0018561751348897815,
      0.036879200488328934,
      0.00666736951097846,
      0.023570653051137924,
      -0.011944582685828209,
      0.009207108989357948,
      0.017058970406651497,
      -0.03029930591583252,
      0.0008559741545468569,
      -0.10992003977298737,
      -0.018125193193554878,
      -0.027914639562368393,
      -0.06034993752837181,
      -0.14425595104694366,
      -0.09659478068351746,
      0.0038347768131643534,
      0.033566225320100784,
      0.020699840039014816,
      0.002296718768775463,
      -0.006261538714170456,
      0.13373298943042755,
      -0.04203944280743599,
      0.05319923534989357,
      -0.023040756583213806,
      0.014187498018145561,
      -0.033793386071920395,
      -0.05211976543068886,
      0.03358505666255951,
      0.004193549044430256,
      0.001068812096491456,
      -0.03002004325389862,
      0.024592963978648186,
      0.04999159276485443,
      -0.0393596775829792,
      0.04495742917060852,
      0.03917740657925606,
      -0.06388483196496964,
      -0.012517026625573635,
      -0.04458506032824516,
      0.08157271146774292,
      0.04815289005637169,
      -0.04788006469607353,
      -0.034900739789009094,
      0.023602977395057678,
      -0.007562527898699045,
      0.05689743161201477,
      0.00849212147295475,
      0.006847274024039507,
      -0.049713216722011566,
      0.05233452469110489,
      0.022913752123713493,
      -0.019066430628299713,
      0.08327328413724899,
      0.0221975427120924,
      -0.019408106803894043,
      -0.04426223784685135,
      -0.05912378430366516,
      0.09134399890899658,
      0.08354052901268005,
      -0.11489740014076233,
      -0.08492043614387512,
      0.09039666503667831,
      0.04993952438235283,
      0.03719720244407654,
      -0.053960926830768585,
      -0.046221647411584854,
      -0.005368323065340519,
      -0.010657683946192265,
      0.05939742550253868,
      -0.037440426647663116,
      -0.05297300964593887,
      -0.05638401210308075,
      -0.027064340189099312,
      0.05719464272260666,
      0.04099326580762863,
      -0.06237470358610153,
      0.01066588331013918,
      -0.01261752936989069,
      -0.0331699401140213,
      -0.08928779512643814,
      -0.04174394905567169,
      0.047426994889974594,
      0.018698344007134438,
      0.006888283882290125,
      0.03749233856797218,
      -0.04385487362742424,
      -0.0014356739120557904,
      0.04118747636675835,
      0.09228356927633286,
      0.0030875441152602434,
      -0.06219521537423134,
      -0.0011879010125994682,
      -0.009044940583407879,
      -0.009522968903183937,
      -0.011708357371389866,
      0.0406591035425663,
      -0.01763717643916607,
      0.05200774595141411,
      -0.011602191254496574,
      -0.005769751500338316,
      0.06750429421663284,
      -0.053303312510252,
      0.0018554048147052526,
      0.0329810231924057,
      0.015652786940336227,
      0.07054895907640457,
      -0.030811861157417297,
      -0.03498905152082443,
      0.043917328119277954,
      -0.004920321982353926,
      0.03349978104233742,
      0.07188151776790619,
      0.038800910115242004,
      0.005465769674628973,
      0.044914111495018005,
      0.00461876904591918,
      -0.12031029164791107,
      -0.0036715029273182154,
      -0.039785172790288925,
      0.07833442091941833,
      -0.038475554436445236,
      -0.005447888746857643,
      0.021496523171663284,
      -0.0881698727607727,
      0.012352325022220612,
      -0.0008596386178396642,
      -0.02868586592376232,
      0.01164898183196783,
      -0.06350098550319672,
      -0.03790502995252609,
      0.047590527683496475,
      0.022460008040070534,
      -0.002639166312292218,
      0.02089843899011612,
      -0.055980343371629715,
      -0.023182056844234467,
      0.021216310560703278,
      0.07776696234941483,
      0.03060373105108738,
      0.05328620225191116,
      0.022530030459165573,
      -0.042615506798028946,
      0.08253548294305801,
      0.09135233610868454,
      0.041746411472558975,
      -0.07981625944375992,
      0.07886827737092972,
      -0.04073157161474228,
      0.038889020681381226,
      -0.09358374774456024,
      -0.04431305080652237,
      0.050725437700748444,
      0.0631808191537857,
      -0.03902216628193855,
      0.04834723100066185,
      0.01145070232450962,
      -0.020384661853313446,
      -0.014131704345345497,
      0.01992115192115307,
      0.06329318135976791,
      -0.07527918368577957,
      0.04801222309470177,
      0.059927407652139664,
      0.046884845942258835,
      -0.015526458621025085,
      -0.0032161278650164604,
      0.01523569691926241,
      -0.054766517132520676,
      0.015106230974197388,
      0.0371953547000885,
      -0.005822991486638784,
      -0.018649054691195488,
      0.11211953312158585,
      -0.020734943449497223,
      -0.044339295476675034,
      -0.03502850979566574,
      0.06561537832021713,
      -0.013519776053726673,
      0.012009182013571262,
      0.015941910445690155,
      -0.03480329364538193,
      0.026895388960838318,
      0.00821662973612547,
      0.009604687802493572,
      0.018194053322076797,
      -0.0159490704536438,
      0.04353222995996475
    ],
    [
      0.030311433598399162,
      0.023556170985102654,
      -0.04732948914170265,
      0.01649685576558113,
      -0.01459522545337677,
      -0.02202976681292057,
      0.05545974150300026,
      0.09079737216234207,
      0.005233943462371826,
      0.027539895847439766,
      -0.024158041924238205,
      0.0899643674492836,
      0.031548477709293365,
      -0.012116070836782455,
      0.04930928349494934,
      0.07347983121871948,
      0.07569827139377594,
      0.026337655261158943,
      -0.0213741734623909,
      -0.02204703539609909,
      0.04154519364237785,
      -0.04114241525530815,
      -0.07105572521686554,
      -0.02207763120532036,
      -0.0711197629570961,
      0.07339610904455185,
      0.04665132984519005,
      0.04698747768998146,
      0.05822598561644554,
      0.018121102824807167,
      0.03355441987514496,
      -0.023374458774924278,
      -0.04375229775905609,
      0.06890670210123062,
      0.0012320411624386907,
      0.06991377472877502,
      -0.04342246428132057,
      -0.04565204679965973,
      -0.02399616502225399,
      0.034676555544137955,
      -0.021580224856734276,
      -0.004457508213818073,
      -0.03154223784804344,
      0.029978269711136818,
      0.0026085905265063047,
      0.05853111669421196,
      0.0008615339174866676,
      0.018313702195882797,
      -0.0454859584569931,
      -0.03741876035928726,
      -0.03350302204489708,
      0.03655879572033882,
      -0.04402632638812065,
      -0.02004406601190567,
      0.0610596127808094,
      0.07901972532272339,
      -0.013205219991505146,
      -0.01248894538730383,
      0.01003064215183258,
      -0.014465468935668468,
      0.05252812057733536,
      0.025827016681432724,
      0.02747233211994171,
      0.037101373076438904,
      -0.03984007611870766,
      0.1095227375626564,
      0.017204752191901207,
      0.043551042675971985,
      -0.04121781140565872,
      -0.013395240530371666,
      0.04141497611999512,
      0.02932007983326912,
      0.030802421271800995,
      -0.005867393221706152,
      -0.020897550508379936,
      0.043480753898620605,
      0.057971712201833725,
      -0.005210868548601866,
      -0.0013658939860761166,
      0.01260519027709961,
      0.06029896065592766,
      -0.03265617415308952,
      0.03136159107089043,
      -0.039143532514572144,
      0.000112602770968806,
      0.06247183680534363,
      0.03436901047825813,
      0.01620933786034584,
      0.044064961373806,
      0.05455353111028671,
      0.04939670115709305,
      -0.04204748570919037,
      0.006897478364408016,
      -0.029829086735844612,
      -0.012034628540277481,
      -0.07097453624010086,
      0.02070881612598896,
      -0.02890685759484768,
      0.04656938835978508,
      0.01190715841948986,
      -0.0009398260735906661,
      0.0739891454577446,
      0.09676500409841537,
      0.08867918699979782,
      0.039339255541563034,
      -0.028793156147003174,
      -0.06977386772632599,
      0.09395920485258102,
      -0.04909421503543854,
      0.01696890965104103,
      0.0009167632670141757,
      -0.014210028573870659,
      0.005908666178584099,
      -0.0033138187136501074,
      0.046309951692819595,
      -0.029672933742403984,
      -0.007745097856968641,
      0.017602799460291862,
      0.008638398721814156,
      -0.10226356983184814,
      0.01886869966983795,
      0.0029298407025635242,
      0.047040559351444244,
      0.007374970242381096,
      -0.07514059543609619,
      -0.0620134063065052,
      -0.0004543949034996331,
      0.03921465575695038,
      -0.02202695794403553,
      -0.01028432510793209,
      -0.06315220892429352,
      0.013285083696246147,
      -0.014831004664301872,
      0.024410303682088852,
      -0.013101664371788502,
      -0.012004371732473373,
      -0.011592759750783443,
      -0.05603134632110596,
      -0.01666022092103958,
      -0.08580715209245682,
      -0.02253364399075508,
      -0.057720866054296494,
      0.004896774422377348,
      -0.054072871804237366,
      -0.027602141723036766,
      -0.05005606263875961,
      0.026485996320843697,
      -0.044505249708890915,
      0.02837512642145157,
      -0.007872293703258038,
      -0.02138640359044075,
      0.05044809356331825,
      -0.0012001388240605593,
      -0.042899567633867264,
      0.04642634093761444,
      0.04823870211839676,
      0.15671873092651367,
      0.029930487275123596,
      0.020243143662810326,
      0.0770849734544754,
      -0.004744337871670723,
      -0.030573571100831032,
      -0.04833340272307396,
      0.019184263423085213,
      0.052950870245695114,
      0.0638062059879303,
      0.025074345991015434,
      -0.05555380880832672,
      0.015524204820394516,
      -0.038405656814575195,
      0.08650045841932297,
      0.013725223019719124,
      0.005372103303670883,
      -0.0013628890737891197,
      0.04420878365635872,
      -0.08025507628917694,
      -0.055296529084444046,
      -0.016326429322361946,
      0.0002773073792923242,
      0.051122620701789856,
      -0.08029159903526306,
      -0.04022092744708061,
      0.05591181293129921,
      0.007324328180402517,
      0.0401628203690052,
      0.026142319664359093,
      -0.02918674424290657,
      0.00038702989695593715,
      0.014573599211871624,
      0.03561040759086609,
      -0.02897777035832405,
      0.00817547831684351,
      -0.022174330428242683,
      0.03422525152564049,
      -0.017537692561745644,
      0.028889838606119156,
      -0.04271303117275238,
      -0.015744583681225777,
      -0.06543365120887756,
      0.0281390231102705,
      -0.037396591156721115,
      0.02755090408027172,
      -0.04994191229343414,
      -0.06165829300880432,
      0.019556701183319092,
      -0.032529670745134354,
      0.031817857176065445,
      -0.04628007486462593,
      -0.07020388543605804,
      -0.08369136601686478,
      0.004443912301212549,
      -0.05937571823596954,
      -0.10162795335054398,
      -0.008201240561902523,
      0.04473162814974785,
      -0.041114285588264465,
      -0.015569780021905899,
      0.023435045033693314,
      0.0052797854878008366,
      -0.03692370280623436,
      -0.0095104044303298,
      -0.037412140518426895,
      -0.027203800156712532,
      -0.09373155981302261,
      0.00912592001259327,
      -0.019959701225161552,
      -0.02705063670873642,
      0.03558696061372757,
      -0.0074418336153030396,
      -0.03708486631512642,
      0.044959619641304016,
      0.1426253467798233,
      0.0178813599050045,
      -0.026319703087210655,
      0.014544290490448475,
      0.05320104956626892,
      0.059910960495471954,
      0.0223381407558918,
      0.03436078131198883,
      0.03378315642476082,
      0.12112803757190704,
      -0.04496951773762703,
      0.039717257022857666,
      0.019531892612576485,
      0.05619307607412338,
      -0.02861609309911728,
      0.008066765032708645,
      -0.00044578753295354545,
      0.039233069866895676,
      -0.011505364440381527,
      0.01906762458384037,
      -0.07908248901367188,
      0.02140285260975361,
      0.0020393405575305223,
      0.037092965096235275,
      -0.00985900778323412,
      -0.009395682252943516,
      -0.02208550274372101,
      -0.02503347396850586,
      0.013589606620371342,
      0.0074469344690442085,
      0.015425760298967361,
      0.002110513160005212,
      -0.0014775908784940839,
      0.020833514630794525,
      0.0655948743224144,
      0.09143391251564026,
      -0.021995337679982185,
      -0.043912000954151154,
      -0.043335746973752975,
      -0.05211004987359047,
      0.025314081460237503,
      -0.026402223855257034,
      0.05581889674067497,
      -0.08609170466661453,
      0.023943811655044556,
      0.018140604719519615,
      -0.09451022744178772,
      0.035734713077545166,
      -0.03238103166222572,
      0.09609946608543396,
      -0.006094778422266245,
      0.0338137149810791,
      -0.041848089545965195,
      0.06884542852640152,
      -0.057419802993535995,
      -0.02351483516395092,
      0.03358910605311394,
      0.06047239899635315,
      0.051444828510284424,
      -0.0071210409514606,
      -0.009167601354420185,
      -0.04632418602705002,
      -0.00040397094562649727,
      0.04925663396716118,
      0.009849420748651028,
      -0.02840101160109043,
      -0.054618239402770996,
      0.0034660478122532368,
      -0.06896460056304932,
      0.06606362015008926,
      -0.01570451445877552,
      0.0014556818641722202,
      -0.02608860656619072,
      -0.001478570164181292,
      -0.039294470101594925,
      0.06816862523555756,
      -0.08026376366615295,
      -0.04753701388835907,
      0.04023965075612068,
      0.026093648746609688,
      -0.0011943788267672062,
      0.011643343605101109,
      0.04518739506602287,
      0.004663006868213415,
      -0.033019501715898514,
      -0.028584090992808342,
      -0.020806625485420227,
      -0.01704062893986702,
      0.020964017137885094,
      0.01769299991428852,
      0.07030929625034332,
      0.013151312246918678,
      0.056482601910829544,
      -0.019874954596161842,
      -0.060614317655563354,
      0.02721969224512577,
      0.07423557341098785,
      -0.028019577264785767,
      -0.07504554837942123,
      -0.029092734679579735,
      -0.015901491045951843,
      -0.037581123411655426,
      0.005109058693051338,
      -0.02860785648226738,
      0.02459113299846649,
      -0.0184177216142416,
      -0.010145854204893112,
      0.08381479978561401,
      -0.0023938503582030535,
      0.027364319190382957,
      -0.0025579524226486683,
      -0.020513826981186867,
      0.017035381868481636,
      -0.0255587138235569,
      -0.046907465904951096,
      0.046230509877204895,
      -0.015135846100747585,
      0.06439371407032013,
      0.05366368591785431,
      0.13324056565761566,
      0.009032796137034893,
      -0.03664807230234146,
      -0.02269713021814823,
      -0.014553236775100231,
      0.015699312090873718,
      0.015207383781671524,
      -0.03481132164597511,
      0.00561078917235136,
      -0.017465978860855103,
      -0.023180969059467316,
      -0.04208427295088768,
      0.016866035759449005,
      -0.04564569145441055,
      -0.0478690080344677,
      0.02637854591012001,
      -0.018373066559433937,
      0.001201049773953855,
      -0.00020728363597299904,
      -0.010504142381250858,
      0.04344826191663742,
      0.03879745304584503,
      -0.02331436425447464,
      -0.0421038456261158,
      0.02789720520377159,
      0.000584538618568331,
      0.0189859326928854,
      -0.04513291269540787,
      -0.008591227233409882,
      0.06040129065513611,
      -0.015799229964613914,
      0.051357537508010864,
      -0.07229327410459518,
      0.0033154310658574104,
      0.0328812301158905,
      -0.04275562986731529,
      -0.033050693571567535,
      -0.005696472711861134,
      -0.037691861391067505,
      0.04125026613473892,
      0.055944185703992844,
      -0.06519205123186111,
      0.004933025687932968,
      0.005664740223437548,
      0.0020500430837273598,
      0.03347369283437729,
      0.034844957292079926,
      0.0034591907169669867,
      -0.02063625305891037,
      -0.05252089351415634,
      0.02162918448448181,
      -0.011155200190842152,
      -0.013077479787170887,
      0.022328829392790794,
      -0.03507978096604347,
      0.05219242349267006,
      -0.004855796694755554,
      -0.021260319277644157,
      0.09528741240501404,
      -0.04055943712592125,
      -0.0006574941799044609,
      0.03446453437209129,
      0.044582974165678024,
      -0.025255236774683,
      0.009046477265655994,
      -0.002921498380601406,
      -0.01662926748394966,
      0.0016656152438372374,
      -0.03444488346576691,
      -0.03062126785516739,
      0.03188636526465416,
      0.044029366225004196,
      0.0012265545083209872,
      0.05596486106514931,
      -0.01608230359852314,
      -0.05030084028840065,
      -0.01947108469903469,
      -0.042600393295288086,
      -0.0771157369017601,
      0.042436785995960236,
      0.0071489280089735985,
      -0.02579905278980732,
      0.09829594194889069,
      -0.004565358627587557,
      -0.05417700856924057,
      0.02644462138414383,
      0.018488403409719467,
      -0.03954848647117615,
      -0.03006647899746895,
      -0.05025116726756096,
      0.096966452896595,
      0.011438257992267609,
      -0.06865818798542023,
      0.035362742841243744,
      -0.049484360963106155,
      0.07635746896266937,
      0.07304538786411285,
      0.0917268693447113,
      0.04211987182497978,
      -0.031709808856248856,
      0.023481212556362152,
      0.048277534544467926,
      -0.008259144611656666,
      -0.03850100189447403,
      0.01755957491695881,
      0.0014717926969751716,
      -0.0124973738566041,
      -0.005183548200875521,
      0.03879331797361374,
      -0.004894885700196028,
      -0.08478493988513947,
      -0.013299926184117794,
      -0.0165400467813015,
      0.03993677347898483,
      0.06352410465478897,
      0.01849186234176159,
      -0.09417735785245895,
      -0.033773262053728104,
      -0.019808555021882057,
      -0.02146517112851143,
      -0.012959427200257778,
      0.014885185286402702,
      -0.012182021513581276,
      -0.02474978379905224,
      0.013695592992007732,
      -0.03152697905898094,
      0.005305932834744453,
      -0.007640975993126631,
      0.08531035482883453,
      0.015006249770522118,
      -0.014645105227828026,
      -0.024817993864417076,
      -0.04056383669376373,
      0.03688400983810425,
      0.05820893123745918,
      0.0038503091782331467,
      0.02109154872596264,
      0.07198596745729446,
      0.03152143210172653,
      -0.01737167313694954,
      -0.019099097698926926,
      -0.04543749615550041,
      0.03002030961215496,
      0.022163135930895805,
      -0.0454396978020668,
      0.0632866695523262,
      0.07111430168151855,
      0.07114759832620621,
      0.00228152540512383,
      -0.08702623844146729,
      -0.07063229382038116,
      0.03270269185304642,
      0.041719261556863785
    ],
    [
      0.025598328560590744,
      0.08099144697189331,
      0.05377698317170143,
      0.0020292638801038265,
      -0.08829279989004135,
      0.03922156244516373,
      -0.06907007098197937,
      0.05755406245589256,
      -0.009113653562963009,
      0.03605836629867554,
      0.06478119641542435,
      -0.04014526680111885,
      -0.051507141441106796,
      -0.07206232845783234,
      0.09307005256414413,
      0.0112455477938056,
      -0.026951391249895096,
      -0.014698121696710587,
      -0.07432600110769272,
      0.007030941545963287,
      0.022382702678442,
      0.052028119564056396,
      -0.035540368407964706,
      0.017086660489439964,
      0.06570397317409515,
      0.005456147249788046,
      0.03331417217850685,
      0.0593561977148056,
      -0.01864716410636902,
      0.021128740161657333,
      0.014019803144037724,
      -0.036356132477521896,
      0.04442613944411278,
      0.036948736757040024,
      -0.019620010629296303,
      -0.01788371428847313,
      0.08070909976959229,
      -0.06974069774150848,
      -0.014461609534919262,
      0.050840117037296295,
      0.03710905835032463,
      0.04100767523050308,
      0.0060496567748487,
      0.023457806557416916,
      0.06552416831254959,
      0.0735059529542923,
      0.0038675880059599876,
      0.017727220430970192,
      -0.00783975888043642,
      -0.011410331353545189,
      0.06658407300710678,
      0.017589440569281578,
      -0.03594539687037468,
      0.006627849768847227,
      -0.010318674147129059,
      0.023809319362044334,
      0.06051413714885712,
      0.0319078154861927,
      0.07697855681180954,
      -0.03531692922115326,
      0.006902468390762806,
      -0.002604912966489792,
      -0.022446289658546448,
      0.05348602682352066,
      -0.06912228465080261,
      -0.049814630299806595,
      -0.04408632591366768,
      0.01568821258842945,
      0.007261693477630615,
      -0.01720394939184189,
      0.08120064437389374,
      0.02807018905878067,
      -0.002804588293656707,
      0.014502269215881824,
      0.03494061157107353,
      0.041488468647003174,
      0.013186132535338402,
      -0.00907798670232296,
      -0.013390478678047657,
      0.058182425796985626,
      -0.03972848877310753,
      -0.02858673594892025,
      0.04352923110127449,
      0.0004194648936390877,
      0.007349234074354172,
      0.06082034111022949,
      -0.011496531777083874,
      0.012953252531588078,
      0.06062795966863632,
      -0.011297943070530891,
      0.052342336624860764,
      -0.01869013160467148,
      0.019089961424469948,
      -0.02875058352947235,
      0.038637902587652206,
      -0.03749136999249458,
      -0.053202174603939056,
      -0.0266922228038311,
      0.05639916658401489,
      -0.0013077351031824946,
      0.06045888736844063,
      -0.031794339418411255,
      -0.03254004567861557,
      0.05243755504488945,
      0.024416981264948845,
      -0.01918540708720684,
      0.016481881961226463,
      -0.004974303301423788,
      -0.010071120224893093,
      0.0006143958307802677,
      0.026332465931773186,
      -0.08291451632976532,
      0.023927655071020126,
      -0.02313615009188652,
      0.01706322655081749,
      0.01434938982129097,
      0.08581232279539108,
      0.09307893365621567,
      0.02052069455385208,
      0.06802493333816528,
      0.008871573954820633,
      0.033273812383413315,
      -0.043377384543418884,
      0.0258086696267128,
      0.04102106764912605,
      -0.033342134207487106,
      0.07012268155813217,
      0.03309089317917824,
      -0.04428819939494133,
      -0.07876245677471161,
      -0.06690239906311035,
      -0.05167648568749428,
      0.05349286273121834,
      -0.04893821105360985,
      -0.00697307800874114,
      -0.020051561295986176,
      2.1665643998858286e-06,
      -0.04765937477350235,
      -0.019234418869018555,
      0.03408881276845932,
      0.048591285943984985,
      -0.03725074604153633,
      -0.044277530163526535,
      0.06515554338693619,
      -0.09310910850763321,
      -0.006520531140267849,
      -0.06576361507177353,
      0.015765011310577393,
      -0.009966609068214893,
      0.012147863395512104,
      -0.0041251457296311855,
      0.026870332658290863,
      -0.05088328197598457,
      0.08906648308038712,
      -0.06195170059800148,
      -0.006365989334881306,
      -0.04670577868819237,
      0.06045825779438019,
      -0.03595631569623947,
      -0.0480390340089798,
      0.011647447012364864,
      0.029301950708031654,
      0.044955525547266006,
      -0.07092031836509705,
      -0.06161006540060043,
      -0.06423906236886978,
      0.06633920967578888,
      -0.06690292060375214,
      -0.003705345094203949,
      -0.012225809507071972,
      -0.09693239629268646,
      0.057581692934036255,
      0.020027948543429375,
      -0.011303526349365711,
      -0.014865166507661343,
      -0.0028964790981262922,
      -0.027519047260284424,
      0.07642460614442825,
      -0.08466335386037827,
      -0.025465279817581177,
      0.039445068687200546,
      -0.05799011141061783,
      -0.017518913373351097,
      0.012589738704264164,
      -0.009321118704974651,
      0.008247234858572483,
      0.014935914427042007,
      0.020117543637752533,
      -0.10165583342313766,
      0.04988580197095871,
      -0.02843642607331276,
      -0.013004706241190434,
      -0.016784600913524628,
      0.011239062994718552,
      -0.06249719485640526,
      0.04960589110851288,
      0.007008196320384741,
      0.04982198774814606,
      -0.022607874125242233,
      -0.004626037087291479,
      -0.02088776044547558,
      0.03572218492627144,
      -0.09540862590074539,
      0.029345080256462097,
      0.022441240027546883,
      -0.006762000732123852,
      0.08902180194854736,
      0.051186319440603256,
      -0.0313938707113266,
      -0.015351701527833939,
      -0.04285617917776108,
      -0.032850079238414764,
      -0.024947460740804672,
      0.0036102125886827707,
      -0.044316764920949936,
      0.02585294283926487,
      -0.057703468948602676,
      0.022285252809524536,
      -0.03221871331334114,
      -0.02888190560042858,
      0.06978143006563187,
      0.02871013432741165,
      0.04609327018260956,
      -0.03721898794174194,
      -0.03278163820505142,
      -0.05561556667089462,
      -0.017409851774573326,
      0.034420643001794815,
      0.015963179990649223,
      -0.031203430145978928,
      0.03627929091453552,
      -0.010285661555826664,
      0.03849141299724579,
      0.029145879670977592,
      -0.04287916421890259,
      0.053657278418540955,
      -0.05368337780237198,
      0.049149420112371445,
      0.094106525182724,
      0.051575642079114914,
      0.04311767593026161,
      -0.012047390453517437,
      0.07212520390748978,
      -0.014213517308235168,
      -0.006842235568910837,
      -0.074480339884758,
      0.017179029062390327,
      0.024912029504776,
      -0.05138968303799629,
      0.022946646437048912,
      -0.007778273429721594,
      0.04424066096544266,
      0.030198635533452034,
      -0.03568194434046745,
      0.028623418882489204,
      0.035306625068187714,
      -0.07484858483076096,
      -0.018401937559247017,
      -0.030452614650130272,
      -0.010038545355200768,
      -0.011880926787853241,
      0.0553700290620327,
      -0.03442365676164627,
      -0.05485451966524124,
      -0.002483720425516367,
      -0.015476173721253872,
      0.043593112379312515,
      0.060593534260988235,
      0.05602327734231949,
      0.06065098196268082,
      -0.014900063164532185,
      -0.0030719791539013386,
      0.02837204933166504,
      0.006551448721438646,
      -0.0301002599298954,
      -0.06105129048228264,
      -0.01839984767138958,
      0.022212794050574303,
      0.01980075240135193,
      0.006138707511126995,
      -0.007616247981786728,
      -0.008863133378326893,
      0.04314134269952774,
      0.03801219165325165,
      0.0037885745987296104,
      -0.04174149036407471,
      0.046643368899822235,
      0.02600003033876419,
      0.08815242350101471,
      -0.0004719392745755613,
      0.03505399450659752,
      -0.05003754422068596,
      -0.05031779780983925,
      0.05244194716215134,
      0.0008720644400455058,
      -0.03831462562084198,
      -0.03713563084602356,
      -0.0020410199649631977,
      -0.02514518052339554,
      0.005696936976164579,
      -0.06187652423977852,
      0.10779080539941788,
      0.025623127818107605,
      -0.042356185615062714,
      0.005619826726615429,
      -0.05871390923857689,
      -0.00912619661539793,
      -0.05085762217640877,
      0.03112197481095791,
      -0.009366237558424473,
      0.04789784178137779,
      0.02872820943593979,
      0.0361909382045269,
      -0.055199116468429565,
      -0.04864957183599472,
      -0.019336523488163948,
      -0.04115661606192589,
      0.047034282237291336,
      -0.006812667474150658,
      0.036277689039707184,
      -0.0841483324766159,
      -0.04014848917722702,
      0.014249653555452824,
      -0.032835498452186584,
      -0.013778988271951675,
      -0.02966604195535183,
      0.08490072935819626,
      -0.022426005452871323,
      -0.010248363018035889,
      0.000752771447878331,
      -0.0006827313918620348,
      0.00039574524271301925,
      -0.02713692933320999,
      -0.07384070754051208,
      0.03586292266845703,
      8.708153472980484e-05,
      0.011551962234079838,
      -0.012608059681952,
      -0.021786175668239594,
      1.3218885214882903e-05,
      0.05795103311538696,
      0.027375763282179832,
      -0.03397219255566597,
      -0.010490830056369305,
      0.019872300326824188,
      -0.02463953010737896,
      0.011552703566849232,
      -0.03718515858054161,
      -0.02231515944004059,
      0.015256816521286964,
      0.0375983789563179,
      0.08078300952911377,
      0.01561727188527584,
      -0.0037806075997650623,
      0.02592592127621174,
      -0.001364146824926138,
      -0.026672380045056343,
      -0.029796309769153595,
      0.02729557827115059,
      -0.09100484848022461,
      -0.039139412343502045,
      -0.0560247078537941,
      -0.08043386042118073,
      0.01604309305548668,
      -0.058784980326890945,
      -0.035395726561546326,
      0.04688561335206032,
      0.018924493342638016,
      -0.0021789097227156162,
      -0.05762208625674248,
      0.03800192475318909,
      0.005321720149368048,
      -0.04318181425333023,
      0.039040014147758484,
      0.03934599459171295,
      -0.09342943876981735,
      -0.04012018442153931,
      -0.0399920791387558,
      0.07818885892629623,
      -0.03671678528189659,
      0.04745097458362579,
      -0.0009674049215391278,
      0.0052613262087106705,
      -0.06365334242582321,
      0.06468822062015533,
      -0.048001036047935486,
      0.02167532593011856,
      0.059132855385541916,
      0.011784059926867485,
      0.011741479858756065,
      -0.007942554540932178,
      -0.03758614882826805,
      0.010528801009058952,
      -0.035483382642269135,
      0.04393840581178665,
      0.020017769187688828,
      0.0517590157687664,
      0.11607126891613007,
      0.06463567167520523,
      -0.03377484530210495,
      0.018936218693852425,
      -0.037632670253515244,
      -0.025815187022089958,
      -0.08100280910730362,
      0.03863869234919548,
      0.03433834761381149,
      -0.012349594384431839,
      0.020624171942472458,
      -0.003124051261693239,
      -0.07729744166135788,
      -0.04363672062754631,
      -0.017384614795446396,
      0.032474957406520844,
      -0.02584134414792061,
      -0.015674645081162453,
      0.1202290952205658,
      5.3973839385434985e-05,
      0.04012491554021835,
      0.022628847509622574,
      0.1304321438074112,
      -0.016179699450731277,
      -0.042304813861846924,
      -0.022784143686294556,
      0.0295963566750288,
      0.05266827717423439,
      -0.026452818885445595,
      -0.01937851309776306,
      0.05410643294453621,
      -0.01824195124208927,
      -0.053185395896434784,
      -0.043414510786533356,
      -0.005138336215168238,
      -0.0543200857937336,
      0.03671392798423767,
      -0.04542430117726326,
      0.012098293751478195,
      0.026480434462428093,
      0.04631485417485237,
      0.0223942082375288,
      0.0408703051507473,
      -0.07730191946029663,
      -0.09619888663291931,
      0.043984849005937576,
      -0.014581034891307354,
      -0.009156840853393078,
      -0.028762949630618095,
      -0.0002609682269394398,
      -0.0025167949497699738,
      -0.06653966009616852,
      -0.08217175304889679,
      0.05823564529418945,
      0.03613188490271568,
      0.004960245452821255,
      0.00859260093420744,
      -0.008993810042738914,
      -0.007226366084069014,
      0.06057819351553917,
      -0.05654606223106384,
      0.04658135771751404,
      -0.04397694766521454,
      -0.007823406718671322,
      -0.01055707037448883,
      0.021266238763928413,
      0.04575284942984581,
      -0.0406111404299736,
      0.11335623264312744,
      0.062153223901987076,
      0.013762185350060463,
      0.02151358872652054,
      -0.05193055421113968,
      -0.11076204478740692,
      0.016843771561980247,
      -0.07054176926612854,
      -0.009816857986152172,
      -0.09729326516389847,
      0.006177379284054041,
      0.019544685259461403,
      -0.02978350594639778,
      0.035523343831300735,
      -0.0414159782230854,
      -0.09286542236804962,
      0.05268482118844986,
      -0.029934464022517204,
      0.07492027431726456,
      0.10565824806690216,
      0.02280145138502121,
      -0.021527577191591263,
      0.014278924092650414,
      0.008669503964483738,
      0.005304258782416582,
      0.0046135252341628075,
      0.0295396838337183,
      -0.0030446245800703764,
      0.04079413414001465,
      0.001927279052324593,
      -0.025929899886250496,
      -0.0799599140882492,
      0.023715322837233543,
      -0.04591529816389084,
      0.048010557889938354,
      -0.0568326860666275,
      -0.060452237725257874,
      0.053399402648210526
    ],
    [
      0.07703769207000732,
      -2.9861797656849376e-07,
      0.05690356343984604,
      -0.029373886063694954,
      0.0730643942952156,
      0.042372748255729675,
      9.785674774320796e-05,
      -0.06854703277349472,
      0.0779954344034195,
      -0.036861635744571686,
      -0.03625725209712982,
      0.06645889580249786,
      0.039435502141714096,
      0.007673146668821573,
      0.05696934089064598,
      0.02855292148888111,
      -0.06171547248959541,
      0.005085255950689316,
      0.009606730192899704,
      -0.06897551566362381,
      0.011742597445845604,
      0.031664200127124786,
      -0.05984000489115715,
      0.09643678367137909,
      -0.009465406648814678,
      -0.0343516506254673,
      -0.02064293809235096,
      -0.07818054407835007,
      -0.0371166355907917,
      -0.01335358340293169,
      0.039181992411613464,
      -0.01999223791062832,
      0.023005018010735512,
      0.02328229323029518,
      -0.016887370496988297,
      0.017187729477882385,
      0.032857898622751236,
      0.024790991097688675,
      -0.00987077597528696,
      0.010285433381795883,
      -0.10234452039003372,
      -0.09646277129650116,
      -0.05595972016453743,
      0.0754842609167099,
      -0.01776963286101818,
      0.02356910891830921,
      0.035008251667022705,
      0.010604043491184711,
      0.000248136930167675,
      0.03856375440955162,
      -0.03904450684785843,
      -0.014493115246295929,
      0.05068871006369591,
      -0.03754081949591637,
      -0.05018146336078644,
      0.056010652333498,
      0.0651620626449585,
      -0.004516751505434513,
      -0.026672055944800377,
      0.0027683668304234743,
      0.05935697630047798,
      0.13202397525310516,
      -0.09010721743106842,
      -0.061076704412698746,
      -0.05360716953873634,
      0.018723908811807632,
      -0.08037137985229492,
      0.10979381948709488,
      -0.02527032606303692,
      -0.10046958923339844,
      -0.07407688349485397,
      -0.04279144108295441,
      -0.03280732408165932,
      0.05252496898174286,
      -0.0062502408400177956,
      0.010729066096246243,
      0.006677716039121151,
      -0.0200350321829319,
      0.012105760164558887,
      -0.010135418735444546,
      -0.06090220808982849,
      -0.01690073311328888,
      -0.006476026494055986,
      -0.047614987939596176,
      0.02842993102967739,
      0.018103230744600296,
      0.040626976639032364,
      0.05940407142043114,
      0.02272624708712101,
      -0.02807467058300972,
      0.009554259479045868,
      -0.02713257074356079,
      0.004376646596938372,
      0.048869237303733826,
      0.024810882285237312,
      -0.03312709927558899,
      0.07286159694194794,
      -0.029378071427345276,
      -0.00879716221243143,
      -0.027626601979136467,
      -0.07419337332248688,
      0.05510082468390465,
      0.009704242460429668,
      -0.02979431115090847,
      0.029227474704384804,
      -0.01504993624985218,
      0.031078418716788292,
      -0.05370233952999115,
      -0.037642303854227066,
      0.057146769016981125,
      -0.038865894079208374,
      0.012633302249014378,
      -0.05188702791929245,
      -0.005390035919845104,
      0.07889018207788467,
      0.00488243205472827,
      0.05582055449485779,
      0.04442708194255829,
      0.028965294361114502,
      0.0947810634970665,
      -0.06503292918205261,
      -0.018653608858585358,
      -0.005589781329035759,
      0.007699959445744753,
      -0.07560578733682632,
      -0.007908555679023266,
      0.043436892330646515,
      -0.025321703404188156,
      -0.07245247066020966,
      -0.038586754351854324,
      0.0695844441652298,
      -0.06366223096847534,
      -0.03221433237195015,
      0.06161009892821312,
      -0.04829324409365654,
      0.010239936411380768,
      0.012292511761188507,
      0.025040144100785255,
      0.05399717018008232,
      0.08568662405014038,
      0.008267547003924847,
      -0.008132644928991795,
      -0.013240264728665352,
      -0.030407484620809555,
      -0.026083655655384064,
      -0.03227526694536209,
      -0.07713314890861511,
      0.013243633322417736,
      0.04572949558496475,
      0.034165557473897934,
      -0.057434532791376114,
      -0.026340816169977188,
      0.009015841409564018,
      -0.04891815036535263,
      -0.06873051077127457,
      -0.0339563749730587,
      -0.008970199152827263,
      0.06914635002613068,
      -0.06656016409397125,
      0.005610348656773567,
      0.04052656516432762,
      -0.0036162734031677246,
      0.03956102952361107,
      -0.025633936747908592,
      -0.10423316061496735,
      -0.01690123975276947,
      0.015834836289286613,
      -0.0011239589657634497,
      -0.012136494740843773,
      0.024681705981492996,
      -0.05086752399802208,
      0.04267803207039833,
      -0.027978025376796722,
      0.024778490886092186,
      -0.07717087119817734,
      -0.04672721400856972,
      -0.023616019636392593,
      0.0061913360841572285,
      -0.06418904662132263,
      0.007688991725444794,
      0.041534245014190674,
      -0.011847119778394699,
      0.00010576908971415833,
      0.04623191058635712,
      0.05903906375169754,
      -0.056867118924856186,
      -0.05039815604686737,
      -0.019328134134411812,
      -0.06424956023693085,
      0.01657363958656788,
      -0.0441819503903389,
      0.008302401751279831,
      -0.024473540484905243,
      -0.04689565300941467,
      0.021893173456192017,
      -0.00685117207467556,
      0.03453152999281883,
      -0.025896552950143814,
      -0.038625068962574005,
      -0.06258859485387802,
      -0.047088585793972015,
      0.004596812650561333,
      -0.0005951720522716641,
      -0.006815625820308924,
      -0.014991345815360546,
      -0.05128766596317291,
      0.09464319050312042,
      0.07839777320623398,
      -0.03961038589477539,
      -0.025226665660738945,
      -0.028893696144223213,
      0.04125656932592392,
      0.0038662697188556194,
      0.012247097678482533,
      -0.03307785838842392,
      0.03388633206486702,
      -0.10993869602680206,
      0.08851288259029388,
      -0.05064787715673447,
      -0.009743191301822662,
      0.0846727266907692,
      0.03574744239449501,
      0.0009328044252470136,
      -0.02908770553767681,
      0.014720306731760502,
      0.030897632241249084,
      0.03437115252017975,
      0.009315727278590202,
      -0.026124054566025734,
      0.010314476676285267,
      -0.03440963849425316,
      -0.012014510110020638,
      -0.014188673347234726,
      -0.03659224137663841,
      0.04671412333846092,
      -0.04087076708674431,
      0.11228729039430618,
      -0.10564897954463959,
      -0.04933531582355499,
      0.05238201469182968,
      0.018335485830903053,
      0.022750765085220337,
      -0.04176943376660347,
      -0.003596851835027337,
      -0.022005705162882805,
      0.023103198036551476,
      0.05810363218188286,
      0.02661891281604767,
      -0.012002039700746536,
      -0.0140168322250247,
      0.055439189076423645,
      0.018783997744321823,
      0.06385965645313263,
      -0.08304817974567413,
      -0.03627989813685417,
      -0.04964926466345787,
      -0.015340134501457214,
      0.010776366107165813,
      0.07176779955625534,
      -0.017415715381503105,
      -0.01127537339925766,
      0.031833626329898834,
      -0.04268566519021988,
      0.0449826680123806,
      0.014757753349840641,
      -0.0530676543712616,
      -0.05692249909043312,
      -0.006525273900479078,
      -0.05658522620797157,
      0.07078728824853897,
      0.09396907687187195,
      0.04121774062514305,
      0.014496833086013794,
      0.028334930539131165,
      0.009754362516105175,
      0.019731098785996437,
      0.05993082746863365,
      -0.022170376032590866,
      0.0190555602312088,
      0.01747170090675354,
      -0.0018208033870905638,
      0.009212013334035873,
      0.027139702811837196,
      0.06644444912672043,
      0.015665138140320778,
      -0.03775583952665329,
      0.03093581274151802,
      0.021670473739504814,
      0.004927458707243204,
      0.03571124002337456,
      -0.038460876792669296,
      -0.03542985022068024,
      0.06299332529306412,
      -0.0039466628804802895,
      0.03277289867401123,
      0.05023660510778427,
      -0.026354730129241943,
      -0.031000282615423203,
      -0.02305145189166069,
      0.028525933623313904,
      0.03978367894887924,
      -0.006338843144476414,
      0.007072404958307743,
      -0.016431011259555817,
      -0.05108819901943207,
      0.010799032635986805,
      0.07083241641521454,
      -0.01231220830231905,
      -0.04209177568554878,
      0.03689694032073021,
      0.01392790861427784,
      0.02180209383368492,
      -0.07493949681520462,
      -0.012304684147238731,
      -0.05117262154817581,
      0.02243521809577942,
      0.006092507857829332,
      -0.008319501765072346,
      -0.013573497533798218,
      -0.08100461214780807,
      -0.08242443203926086,
      0.03166402131319046,
      0.0700652226805687,
      0.02380763553082943,
      0.04372462257742882,
      -0.06381764262914658,
      0.07817696779966354,
      0.013943420723080635,
      0.008194055408239365,
      -0.025867054238915443,
      -0.008221516385674477,
      -0.06463299691677094,
      0.046921152621507645,
      -0.04763966426253319,
      -0.06546908617019653,
      -0.012874243780970573,
      0.06655303388834,
      -0.007361887022852898,
      0.017814960330724716,
      -0.03648659586906433,
      0.11602181196212769,
      -0.0011836789781227708,
      0.021374953910708427,
      -0.029699092730879784,
      0.03199583664536476,
      -0.07919935882091522,
      -0.0053265951573848724,
      0.0533062182366848,
      0.04213854297995567,
      -0.0962892472743988,
      0.03750546649098396,
      -0.020767930895090103,
      -0.038267724215984344,
      -0.005528820212930441,
      -0.05335647240281105,
      -0.04855817183852196,
      -0.004993359558284283,
      -0.018801599740982056,
      0.031697649508714676,
      0.07044828683137894,
      0.0019394860137254,
      0.03265176713466644,
      -0.04000650346279144,
      0.012375865131616592,
      -0.06334225088357925,
      0.02793700620532036,
      0.015095100738108158,
      0.058997951447963715,
      -0.06307721138000488,
      -0.04094742238521576,
      0.04385208338499069,
      -0.02980409935116768,
      -0.0022479407489299774,
      -0.004686408676207066,
      -0.04908986762166023,
      -0.08079863339662552,
      0.12004058808088303,
      -0.07180417329072952,
      0.006464906502515078,
      -0.07043915241956711,
      0.05637861043214798,
      0.036820825189352036,
      -0.03749910369515419,
      0.01158433873206377,
      -0.009144362062215805,
      -0.008319465443491936,
      0.07629085332155228,
      0.06693194061517715,
      -0.05766145884990692,
      -0.06075076758861542,
      -0.04195333644747734,
      0.034588199108839035,
      -0.07899052649736404,
      -0.03275565430521965,
      -0.011965654790401459,
      -0.03997836261987686,
      0.021931301802396774,
      0.0628952607512474,
      -0.09928064793348312,
      -0.03733665496110916,
      0.005521845072507858,
      -0.012029380537569523,
      0.03309160843491554,
      0.023322951048612595,
      -0.012339834123849869,
      -0.024200841784477234,
      -0.08394352346658707,
      -0.02006317861378193,
      -0.001642269198782742,
      0.023094620555639267,
      0.04996522516012192,
      0.032829564064741135,
      -0.02697939984500408,
      0.008332228288054466,
      0.05096771568059921,
      -0.003397648222744465,
      0.026883848011493683,
      0.010843346826732159,
      -0.05294746533036232,
      0.04862331971526146,
      0.060932181775569916,
      0.034606050699949265,
      0.02309364825487137,
      -0.02314263954758644,
      -0.028203729540109634,
      0.07517494261264801,
      -0.013133085332810879,
      0.0012945637572556734,
      -0.010968692600727081,
      -0.003143488662317395,
      0.058973927050828934,
      0.016817661002278328,
      -0.10133101791143417,
      0.07338625937700272,
      -0.052446044981479645,
      -0.03030445985496044,
      -0.003332890570163727,
      0.12864698469638824,
      0.08328987658023834,
      0.03593425080180168,
      -0.0047434475272893906,
      -0.009270116686820984,
      -0.006948255468159914,
      -0.06320390105247498,
      0.07543173432350159,
      -0.017497513443231583,
      0.02342715486884117,
      0.02196040190756321,
      -0.011206057853996754,
      -0.02206500805914402,
      -0.028063254430890083,
      0.0052566612139344215,
      0.021273097023367882,
      -0.05143153667449951,
      0.1349165290594101,
      -0.008637858554720879,
      0.05877989903092384,
      0.0069245281629264355,
      0.0976870134472847,
      0.03486070781946182,
      0.049611467868089676,
      0.010385422967374325,
      0.026469334959983826,
      -0.021899303421378136,
      0.006119879428297281,
      0.04209058731794357,
      -0.05069100111722946,
      0.018709292635321617,
      0.03864224627614021,
      -0.040371112525463104,
      -0.08138293027877808,
      -0.014683968387544155,
      0.06131290644407272,
      -0.04086485877633095,
      -0.02987959422171116,
      0.01527873333543539,
      0.023204520344734192,
      -0.011382085271179676,
      0.02052588015794754,
      -0.00276072695851326,
      0.03362463787198067,
      0.022059334442019463,
      -0.005441803019493818,
      0.0001720538130030036,
      -0.010677631944417953,
      -0.020609145984053612,
      0.032686784863471985,
      -0.033425793051719666,
      0.05297219753265381,
      0.041574299335479736,
      -0.05050724744796753,
      0.010962161235511303,
      0.06794965267181396,
      -0.03026004508137703,
      -0.018921170383691788,
      -0.007308124098926783,
      -0.08520550280809402,
      0.05594116821885109,
      0.07819892466068268,
      -0.015889979898929596,
      0.015338845551013947,
      0.017042625695466995,
      0.0158005952835083
    ],
    [
      0.02626504935324192,
      0.04461459442973137,
      0.04089212045073509,
      -0.09082739800214767,
      -0.007357633672654629,
      -0.020234674215316772,
      0.07661156356334686,
      -0.037262097001075745,
      0.09402395784854889,
      0.029782302677631378,
      0.021415401250123978,
      -0.0015452768420800567,
      -0.011384416371583939,
      0.06961410492658615,
      0.04491468518972397,
      -0.016837328672409058,
      -0.036692071706056595,
      0.026931392028927803,
      -0.02478482760488987,
      0.013237113133072853,
      0.08817378431558609,
      0.015769096091389656,
      -0.08645688742399216,
      0.009173708036541939,
      -0.005305249709635973,
      -0.026479244232177734,
      -0.0340898260474205,
      0.029619399458169937,
      -0.04838525131344795,
      0.03527659922838211,
      0.08791229873895645,
      -0.006616688333451748,
      0.010918057523667812,
      -0.042611222714185715,
      0.12079467624425888,
      -0.010501743294298649,
      0.024642158299684525,
      -0.02522420324385166,
      -0.026375945657491684,
      -0.020750215277075768,
      0.0035696502309292555,
      -0.07673737406730652,
      -0.06719771027565002,
      0.04607873782515526,
      -0.04041771590709686,
      0.019965114071965218,
      0.08417010307312012,
      0.08668498694896698,
      0.00019369863730389625,
      0.07086633890867233,
      0.03250875324010849,
      0.01462751068174839,
      0.011932824738323689,
      0.01168708223849535,
      0.028070520609617233,
      -0.06035330519080162,
      0.025146985426545143,
      0.022231509909033775,
      -0.036775268614292145,
      0.049840934574604034,
      -0.026962464675307274,
      0.04395056888461113,
      -0.06446629017591476,
      -0.007045130245387554,
      0.052201803773641586,
      -0.04214378818869591,
      -0.03265489265322685,
      0.0412672720849514,
      -0.07362362742424011,
      -0.0923476293683052,
      0.04809761047363281,
      0.08936011046171188,
      0.004058739636093378,
      0.05187840014696121,
      0.05550128221511841,
      0.0001749188086250797,
      -0.015614808537065983,
      -0.09068816900253296,
      0.023817213252186775,
      0.013240662403404713,
      -0.010225714184343815,
      -0.04862653464078903,
      0.003395031439140439,
      -0.03273383900523186,
      -0.044099073857069016,
      0.018620291724801064,
      0.001021197997033596,
      -0.03576033562421799,
      -0.05366209149360657,
      -0.03396470472216606,
      0.004580147098749876,
      0.05311821028590202,
      -0.023758837953209877,
      0.029102541506290436,
      0.030905576422810555,
      0.000828542048111558,
      0.08423342555761337,
      0.006319838110357523,
      0.013127326965332031,
      -0.0645190104842186,
      0.02434425614774227,
      -0.07886553555727005,
      0.044109951704740524,
      0.01681029237806797,
      0.07262521237134933,
      -0.06297116726636887,
      0.047176651656627655,
      -0.051511432975530624,
      -0.040763016790151596,
      0.09302671998739243,
      0.06762304902076721,
      0.032020799815654755,
      0.03923226147890091,
      0.01908084936439991,
      0.06257938593626022,
      -0.005420586559921503,
      -0.0028802561573684216,
      -0.03477683290839195,
      0.05738602578639984,
      0.03502994403243065,
      -0.045427415519952774,
      0.010580398142337799,
      0.12013223022222519,
      0.014577961526811123,
      -0.041829291731119156,
      -0.028748294338583946,
      0.10874147713184357,
      0.03366098552942276,
      -0.09139502793550491,
      -0.13506543636322021,
      -0.09209465235471725,
      0.060741573572158813,
      -0.059516191482543945,
      0.08287962526082993,
      0.0561031848192215,
      -0.00048101795255206525,
      -0.05357150733470917,
      -0.011158174835145473,
      -0.028118127956986427,
      -0.01285969465970993,
      0.020060991868376732,
      -0.006766423117369413,
      0.014185053296387196,
      0.03372688964009285,
      -0.01033019833266735,
      -0.03785093501210213,
      -0.13027159869670868,
      0.06240057572722435,
      -0.001734266523271799,
      0.06750564277172089,
      0.06993439793586731,
      0.04984182119369507,
      -0.046388331800699234,
      -0.037647705525159836,
      -0.01231419574469328,
      0.042754922062158585,
      0.017099834978580475,
      -0.00784729328006506,
      -0.01854243129491806,
      -0.01784101501107216,
      0.0066071245819330215,
      0.02333502657711506,
      -0.04190337657928467,
      0.010784235782921314,
      -0.05332392454147339,
      -0.0015335018979385495,
      0.03097584843635559,
      -0.016845915466547012,
      -0.05901156738400459,
      -0.017217835411429405,
      -0.0020926438737660646,
      0.031010247766971588,
      0.10702505707740784,
      -0.007838658057153225,
      0.025686392560601234,
      -0.0498494878411293,
      -0.042528390884399414,
      -0.04955824837088585,
      6.880568253109232e-05,
      0.09252234548330307,
      -0.030894611030817032,
      0.03301094099879265,
      0.016520848497748375,
      0.08152738213539124,
      0.0678744688630104,
      0.02129950560629368,
      -0.05154433846473694,
      -0.010815498419106007,
      0.008736385963857174,
      -0.05534989759325981,
      -0.03055470623075962,
      -0.036954496055841446,
      0.0018880941206589341,
      -0.04870500788092613,
      -0.008025838993489742,
      0.05689774453639984,
      -0.003997241146862507,
      0.012471971102058887,
      -0.04308036342263222,
      -0.03886564075946808,
      -0.030034799128770828,
      0.01868099346756935,
      -0.0704353079199791,
      0.13784408569335938,
      -0.0550067313015461,
      -0.00755515368655324,
      0.0985640361905098,
      -0.0119811175391078,
      0.012918873690068722,
      0.06613459438085556,
      -0.05654282867908478,
      0.12257089465856552,
      0.0016969903372228146,
      -0.07593879848718643,
      -0.04674055427312851,
      -0.01842675358057022,
      -0.03328676149249077,
      0.05297764018177986,
      -0.05341263487935066,
      -0.07289951294660568,
      0.03839556500315666,
      -0.0020542440470308065,
      -2.929867696366273e-05,
      -0.09186878800392151,
      0.12311648577451706,
      0.06839591264724731,
      0.007908189669251442,
      0.0772496685385704,
      -0.06727433949708939,
      -0.11058104038238525,
      0.0815509483218193,
      0.020664436742663383,
      0.006952221505343914,
      -0.086917445063591,
      -0.024892374873161316,
      -0.01494366955012083,
      0.06753450632095337,
      -0.09192895889282227,
      -0.038781244307756424,
      0.07397173345088959,
      0.0036037075333297253,
      0.01739695481956005,
      0.03074302338063717,
      0.014920621179044247,
      -0.06348615139722824,
      0.037861283868551254,
      -0.02268768474459648,
      0.04854140430688858,
      0.0482562780380249,
      -0.04325859621167183,
      0.06832964718341827,
      0.0268505048006773,
      0.038536664098501205,
      0.04848694056272507,
      -0.007601105608046055,
      -0.06656831502914429,
      0.0395958237349987,
      -0.0496271476149559,
      0.04342345520853996,
      0.009877586737275124,
      -0.032779283821582794,
      0.0012105721980333328,
      0.00943764578551054,
      -0.03724757581949234,
      0.02745593711733818,
      -0.03693787381052971,
      0.020648127421736717,
      0.0198525320738554,
      0.02861892804503441,
      0.04298389330506325,
      -0.046163611114025116,
      -0.004318803548812866,
      -0.04666581749916077,
      0.03259522467851639,
      -0.02858218550682068,
      0.039246946573257446,
      0.0001118901782319881,
      -0.01551627553999424,
      0.00485739903524518,
      -0.06081349030137062,
      0.10183820128440857,
      0.014956779778003693,
      -0.007923765107989311,
      0.11910540610551834,
      -0.027052637189626694,
      0.03654865175485611,
      0.045236263424158096,
      0.055070966482162476,
      0.05439629405736923,
      -0.041958630084991455,
      -0.008397102355957031,
      0.015458507463335991,
      -0.07667812705039978,
      -0.013127489946782589,
      0.0009417325491085649,
      -0.04883610084652901,
      0.007540243677794933,
      -0.029276803135871887,
      0.04752683639526367,
      -0.03393145278096199,
      -0.005055840127170086,
      0.037094417959451675,
      0.05105026438832283,
      0.044325560331344604,
      -0.03639868274331093,
      -0.045164987444877625,
      -0.02919938787817955,
      0.015767788514494896,
      0.06149739772081375,
      -0.029365360736846924,
      -0.07663383334875107,
      0.0097791263833642,
      -0.11645305156707764,
      0.07841524481773376,
      -0.06454792618751526,
      0.04039726033806801,
      0.01698167435824871,
      -0.0606088861823082,
      -0.006564070470631123,
      0.03089994005858898,
      -0.011643466539680958,
      -0.007647923659533262,
      -0.003247648710384965,
      -0.07114294171333313,
      -0.11404957622289658,
      -0.019777610898017883,
      -0.0021505416370928288,
      -0.04971778020262718,
      -0.004858909174799919,
      0.005069584585726261,
      0.09860584139823914,
      0.027321824803948402,
      0.0011547192698344588,
      -0.030919060111045837,
      -0.04231395199894905,
      -0.02588328905403614,
      0.01981216110289097,
      -0.032910555601119995,
      -0.05018454045057297,
      0.06336875259876251,
      -0.023532040417194366,
      -0.009556140750646591,
      0.050160106271505356,
      -0.013655168004333973,
      0.1109989732503891,
      0.014669520780444145,
      -0.01888910122215748,
      -0.0208112932741642,
      0.034934189170598984,
      -0.09794291108846664,
      0.0048716408200562,
      0.06516184657812119,
      -0.013455900363624096,
      -0.05735505744814873,
      -0.036402378231287,
      -0.011325785890221596,
      -0.017558105289936066,
      -0.04049626737833023,
      -0.019135748967528343,
      0.025876721367239952,
      -0.0041319881565868855,
      -0.007463637739419937,
      -0.037696484476327896,
      -0.05111632123589516,
      0.04640015587210655,
      0.035692017525434494,
      -0.04016520082950592,
      0.06609811633825302,
      -0.013405028730630875,
      -0.015282283537089825,
      0.010207143612205982,
      -0.10835105925798416,
      -0.025142144411802292,
      0.019081106409430504,
      -0.008311071433126926,
      0.024817394092679024,
      -0.03330184146761894,
      0.05196331813931465,
      0.01882265694439411,
      0.026137810200452805,
      -0.013296619057655334,
      -0.012918832711875439,
      -0.04962531104683876,
      0.0019373823888599873,
      -0.030843675136566162,
      0.047518305480480194,
      -0.048854801803827286,
      0.03558320179581642,
      -0.001591031439602375,
      0.011400447227060795,
      -0.05230730399489403,
      -0.014570609666407108,
      -0.050054360181093216,
      0.09281744807958603,
      -0.08418677002191544,
      0.017959514632821083,
      -0.02800041064620018,
      0.06142161041498184,
      -0.0018096978310495615,
      -0.023264341056346893,
      0.007749597076326609,
      0.040310997515916824,
      -0.03354187309741974,
      0.09579875320196152,
      -0.0650201290845871,
      0.04824072867631912,
      -0.09977103024721146,
      -0.02464853785932064,
      0.023940779268741608,
      -0.025374868884682655,
      0.06366672366857529,
      0.05387566238641739,
      -0.06956954300403595,
      0.01137789897620678,
      0.03188224881887436,
      0.009934655390679836,
      -0.06586817651987076,
      0.1135246753692627,
      -0.031811054795980453,
      -0.049961816519498825,
      0.03095526434481144,
      -0.088101327419281,
      0.05944843217730522,
      0.031876858323812485,
      0.004216139670461416,
      0.06769624352455139,
      0.10001961141824722,
      0.08977186679840088,
      -0.0649271160364151,
      -0.06390433013439178,
      -0.037457454949617386,
      0.00914377998560667,
      0.05388589948415756,
      -0.00652042031288147,
      0.028517071157693863,
      -0.045544225722551346,
      -0.005195893347263336,
      0.05935334414243698,
      -0.07215547561645508,
      0.012261172756552696,
      0.005712419282644987,
      0.03633705899119377,
      -0.025174643844366074,
      -0.04879155382514,
      -0.026333153247833252,
      0.0077916476875543594,
      0.04405532404780388,
      0.0489947646856308,
      -0.034847863018512726,
      -0.0653267502784729,
      0.07019331306219101,
      0.024927077814936638,
      -0.008292866870760918,
      -0.03680455684661865,
      0.038719888776540756,
      0.01364090945571661,
      0.02762378752231598,
      0.019212642684578896,
      0.0339788943529129,
      -0.0568791888654232,
      -0.04904022067785263,
      -0.09233947098255157,
      -0.033910851925611496,
      0.009995199739933014,
      0.029797444120049477,
      -0.04130718484520912,
      0.05629513040184975,
      0.009023332968354225,
      -0.01750662736594677,
      0.014026874676346779,
      0.023660434409976006,
      -0.04985511675477028,
      -0.0003754055651370436,
      -0.030626880005002022,
      0.09872521460056305,
      -0.006247690413147211,
      -0.09574178606271744,
      0.0554070770740509,
      0.05368858575820923,
      0.034628622233867645,
      0.058978136628866196,
      0.007018986623734236,
      0.08521421998739243,
      0.013610760681331158,
      -0.003815995529294014,
      -0.056592777371406555,
      0.006815348286181688,
      0.0069557661190629005,
      0.05456579849123955,
      0.017056599259376526,
      -0.05949399992823601,
      -0.006853921338915825,
      0.009554538875818253,
      0.001321067102253437,
      0.022028924897313118,
      0.04295986890792847,
      0.009009882807731628,
      -0.03160385414958,
      0.022897545248270035,
      -0.02566513977944851,
      0.041768915951251984,
      -0.05293264612555504,
      -0.02764829434454441
    ],
    [
      0.03388941287994385,
      0.02405996434390545,
      -0.0009813723154366016,
      -0.028228439390659332,
      0.02496553212404251,
      0.06885723024606705,
      0.008166232146322727,
      0.02820262685418129,
      -0.031163854524493217,
      -0.08108800649642944,
      -0.08953111618757248,
      0.0067067318595945835,
      -0.025151757523417473,
      -0.009262630715966225,
      -0.05456658825278282,
      0.06348291784524918,
      -0.08568742126226425,
      0.0013634931528940797,
      0.04280683398246765,
      -0.023427072912454605,
      -0.03424137458205223,
      -0.028936104848980904,
      -0.015270992182195187,
      -0.020172500982880592,
      -0.009429890662431717,
      -0.05608922243118286,
      0.05407731980085373,
      0.044042810797691345,
      -0.021752791479229927,
      -0.006456282921135426,
      -0.004630032926797867,
      0.03194156661629677,
      -0.08334484696388245,
      -0.021213006228208542,
      0.03418990597128868,
      -0.06840856373310089,
      0.007132697384804487,
      0.024016903713345528,
      0.03684651106595993,
      -0.00264431512914598,
      0.0027256908360868692,
      0.0012698228238150477,
      -0.05170729383826256,
      0.07032937556505203,
      -0.0065340641885995865,
      -0.056088048964738846,
      -0.054764408618211746,
      -0.039148010313510895,
      0.051940251141786575,
      0.015427613630890846,
      -0.022968055680394173,
      -0.09970671683549881,
      0.04224448651075363,
      -0.047009408473968506,
      0.027681909501552582,
      0.037875160574913025,
      0.03308398276567459,
      0.01439745631068945,
      0.04474332928657532,
      0.02259138412773609,
      -0.039719998836517334,
      -0.0669780820608139,
      -0.027547717094421387,
      -0.005620379000902176,
      0.020442578941583633,
      0.0799160897731781,
      -0.041124626994132996,
      0.008219446986913681,
      -0.050854556262493134,
      -0.016234872862696648,
      0.03319890797138214,
      0.030911466106772423,
      0.024321433156728745,
      0.04156440868973732,
      0.013764383271336555,
      -0.005330394953489304,
      -0.0037922984920442104,
      -0.07158994674682617,
      0.07051738351583481,
      0.06820755451917648,
      -0.0934186801314354,
      -0.0737142413854599,
      -0.03600924462080002,
      -0.07410366833209991,
      -0.07245985418558121,
      0.006812002509832382,
      -0.03931134194135666,
      -0.051865044981241226,
      -0.007980254478752613,
      0.029057243838906288,
      -0.04961096867918968,
      0.023175356909632683,
      0.030026230961084366,
      -0.011746514588594437,
      0.07379687577486038,
      0.0033710969146341085,
      0.05575481429696083,
      -0.04316353052854538,
      0.024605177342891693,
      -0.13816243410110474,
      -0.018564078956842422,
      0.050564270466566086,
      0.029925109818577766,
      0.03205422684550285,
      0.034879010170698166,
      0.042725976556539536,
      -0.04975977912545204,
      -0.024288015440106392,
      -0.008712928742170334,
      0.0908694714307785,
      -0.016939906403422356,
      0.003598029725253582,
      0.03168123587965965,
      -0.07751685380935669,
      0.038366422057151794,
      0.005379743408411741,
      -0.07611165195703506,
      0.06001244857907295,
      0.012034292332828045,
      -0.12093445658683777,
      0.09630706906318665,
      -0.018896160647273064,
      0.012552275322377682,
      -0.017034007236361504,
      -0.03211076185107231,
      0.02376452647149563,
      0.003922468516975641,
      0.0758676752448082,
      0.001587128615938127,
      -0.0012170993722975254,
      0.03675829619169235,
      -0.04854163900017738,
      0.03127522021532059,
      -0.026665253564715385,
      -0.10579536855220795,
      0.03775736689567566,
      0.015308864414691925,
      0.025744566693902016,
      0.015932653099298477,
      -0.023016799241304398,
      -0.042532823979854584,
      0.047484543174505234,
      0.08950604498386383,
      -0.041317861527204514,
      0.04501504823565483,
      -0.033584389835596085,
      0.017761021852493286,
      -0.05053052678704262,
      0.07577446848154068,
      -0.05333124101161957,
      -0.024076564237475395,
      -0.05057382583618164,
      0.059748634696006775,
      -0.04145747795701027,
      0.02674774080514908,
      -0.013139866292476654,
      -0.0645257830619812,
      0.003035321831703186,
      0.07671137899160385,
      -0.01451267022639513,
      0.04099084809422493,
      -0.01792955957353115,
      -0.06526367366313934,
      -0.08057616651058197,
      0.004495841916650534,
      -0.005169019568711519,
      0.011177404783666134,
      -0.007300242781639099,
      0.01088245864957571,
      0.013647517189383507,
      0.03212497755885124,
      0.04101129621267319,
      0.05569569393992424,
      0.0076703475788235664,
      0.03993318974971771,
      -0.05162712186574936,
      -0.026881622150540352,
      -0.0526709258556366,
      -0.07049692422151566,
      0.0005815872573293746,
      0.0063392831943929195,
      0.011758667416870594,
      -0.05641699582338333,
      0.09163889288902283,
      0.03624499961733818,
      -0.0040221912786364555,
      -0.025695333257317543,
      -0.002680825535207987,
      -0.0875861719250679,
      -0.058120906352996826,
      0.01502251997590065,
      0.0776938647031784,
      -0.020722879096865654,
      0.006958551239222288,
      0.0639006718993187,
      0.02686244063079357,
      0.025441305711865425,
      -0.005543169099837542,
      -0.002059689024463296,
      -0.10012897104024887,
      0.05599335581064224,
      -9.976625005947426e-05,
      0.010218538343906403,
      -0.0013239928521215916,
      0.01600187085568905,
      -0.010216102004051208,
      0.007062275428324938,
      -0.05267820879817009,
      0.017815016210079193,
      -0.07930784672498703,
      0.01654651202261448,
      0.10861759632825851,
      0.032741058617830276,
      -0.008055578917264938,
      0.05398055911064148,
      0.010807779617607594,
      0.07578681409358978,
      0.11693625152111053,
      -0.0832962617278099,
      -0.05907543748617172,
      0.04128289222717285,
      -0.02725069783627987,
      0.01806817762553692,
      -0.08635741472244263,
      -0.002148489700630307,
      -0.05645205080509186,
      0.07973597198724747,
      -0.06180642172694206,
      -0.02840477228164673,
      -0.07199385017156601,
      0.023921385407447815,
      -0.00991477444767952,
      0.050424668937921524,
      -0.02068137191236019,
      0.032995861023664474,
      0.06362050771713257,
      0.08055178076028824,
      0.021419605240225792,
      -0.03019089438021183,
      0.028387539088726044,
      -0.039661187678575516,
      0.011698897927999496,
      0.011530806310474873,
      0.025812765583395958,
      -0.05568350851535797,
      0.04605504125356674,
      -0.0042198835872113705,
      0.02708307094871998,
      0.06279784440994263,
      -0.08656120300292969,
      -0.02787833847105503,
      0.07692299038171768,
      0.10349445790052414,
      0.003755605546757579,
      0.0019165214616805315,
      -0.0418582521378994,
      -0.05370554327964783,
      -0.020855842158198357,
      -0.034619398415088654,
      -0.009293127804994583,
      0.0197665486484766,
      0.05028747022151947,
      -0.06913812458515167,
      -7.541354716522619e-05,
      -0.005623997189104557,
      0.014320680871605873,
      -0.04638073965907097,
      -0.03176838904619217,
      -0.02835002914071083,
      0.0012629549019038677,
      -0.0006715798517689109,
      0.07859945297241211,
      0.05475858598947525,
      -0.10944470763206482,
      0.020265182480216026,
      0.018223242834210396,
      0.0004685120948124677,
      -0.07544383406639099,
      -0.008226451463997364,
      0.053722988814115524,
      -0.0231159720569849,
      0.05864189937710762,
      0.07163213193416595,
      -0.022983131930232048,
      0.018679512664675713,
      -0.05598536133766174,
      -0.04356617107987404,
      -0.011176269501447678,
      -0.02558097243309021,
      0.03015478327870369,
      -0.07333740592002869,
      -0.06512917578220367,
      0.012558108195662498,
      -0.08217710256576538,
      0.08255817741155624,
      -0.003358040004968643,
      -0.07889387011528015,
      -0.03227465599775314,
      0.01530767884105444,
      0.034324172884225845,
      0.05227924883365631,
      0.05328241363167763,
      0.03604014590382576,
      0.06202593818306923,
      -0.015689469873905182,
      -0.08225968480110168,
      0.032212838530540466,
      0.08718421310186386,
      -0.05606283247470856,
      0.031886447221040726,
      0.04064006730914116,
      -0.05314943566918373,
      0.007977813482284546,
      0.02593645080924034,
      0.04381589591503143,
      0.1310003250837326,
      -0.07738262414932251,
      -0.03495855629444122,
      0.03724787384271622,
      -0.018795209005475044,
      0.021213572472333908,
      0.057990167289972305,
      0.05361082777380943,
      0.00499725341796875,
      -0.050084881484508514,
      -0.042282141745090485,
      -0.049010466784238815,
      0.0058427657932043076,
      0.024989427998661995,
      -0.017283199355006218,
      0.015105417929589748,
      -0.04929810389876366,
      0.06312373280525208,
      0.04978839308023453,
      0.018245048820972443,
      0.03162171691656113,
      0.0881107896566391,
      -0.048543624579906464,
      -0.03312859311699867,
      0.023852920159697533,
      0.025092266499996185,
      0.1217237114906311,
      -0.024808406829833984,
      -0.0031651249155402184,
      -0.023068252950906754,
      -0.04607657715678215,
      0.06153641641139984,
      0.04054375737905502,
      -0.02728024311363697,
      -0.028811847791075706,
      0.008788501843810081,
      0.03401074558496475,
      -0.08879901468753815,
      -0.08765146881341934,
      -0.0012116284342482686,
      0.0022866264916956425,
      -0.06415344029664993,
      -0.03070872463285923,
      -0.018125154078006744,
      -0.03437298536300659,
      -0.05448054149746895,
      -0.030814342200756073,
      -0.05742887780070305,
      0.026385068893432617,
      -0.019386764615774155,
      -0.061439163982868195,
      -0.04791435971856117,
      0.029622137546539307,
      0.020423943176865578,
      -0.06522471457719803,
      0.06585060060024261,
      -0.0386139452457428,
      0.003908949438482523,
      -0.06294167786836624,
      -0.0011211676755920053,
      -0.07534683495759964,
      0.013151174411177635,
      0.006598216015845537,
      -0.021269913762807846,
      -0.04187889024615288,
      0.0029950712341815233,
      0.029347818344831467,
      -0.026769062504172325,
      0.019823303446173668,
      -0.005937855690717697,
      0.12330932915210724,
      -0.020898401737213135,
      0.0005646648351103067,
      0.03025011718273163,
      -0.008892752230167389,
      -0.004870035219937563,
      0.004839460831135511,
      -0.012662594206631184,
      -0.0017647164640948176,
      0.0438656359910965,
      -0.00681232800707221,
      -0.01296679861843586,
      -0.010136635974049568,
      -0.04788100719451904,
      0.04894460737705231,
      -0.0016164621338248253,
      -0.03221505507826805,
      -0.012215832248330116,
      0.042162757366895676,
      -0.04537969082593918,
      -0.01064017042517662,
      -0.03595922514796257,
      0.021835196763277054,
      -0.006466963794082403,
      0.051689133048057556,
      -0.05001985654234886,
      0.046730976551771164,
      0.04607498273253441,
      0.08252203464508057,
      0.00658807996660471,
      -0.033788010478019714,
      -0.002567262388765812,
      -0.08440583944320679,
      -0.08349284529685974,
      0.06729741394519806,
      -0.03615402430295944,
      -0.00609445059671998,
      0.06787220388650894,
      -0.04941042140126228,
      0.03611937537789345,
      0.041696250438690186,
      0.029265467077493668,
      0.10046492516994476,
      0.03512322157621384,
      0.042785994708538055,
      -0.047087330371141434,
      -0.024725591763854027,
      -0.04043051227927208,
      0.04737274348735809,
      -0.0038843126967549324,
      0.026465902104973793,
      -0.09038012474775314,
      0.07208424806594849,
      0.03306480869650841,
      0.011197241954505444,
      -0.0008365189423784614,
      -0.008672491647303104,
      -0.033006034791469574,
      -0.000652030692435801,
      -0.031401295214891434,
      0.0021733688190579414,
      -0.014704510569572449,
      0.03209435194730759,
      0.02403203956782818,
      -0.03435027226805687,
      0.02283494360744953,
      -0.007051628082990646,
      -0.07166023552417755,
      -0.055951133370399475,
      0.03145840764045715,
      -0.04078853130340576,
      -0.033080894500017166,
      0.017866237089037895,
      0.020216165110468864,
      -0.016693584620952606,
      -0.0024093706160783768,
      -0.03846456855535507,
      -0.018034374341368675,
      0.03389592096209526,
      0.04593908041715622,
      -0.045160744339227676,
      0.010325148701667786,
      0.029297327622771263,
      -0.04215265065431595,
      0.03521161526441574,
      -0.05267401784658432,
      -0.04775907099246979,
      0.020019207149744034,
      0.013557235710322857,
      0.02143676020205021,
      0.02137049473822117,
      -0.003472590586170554,
      -0.0067923907190561295,
      -0.0018986905924975872,
      -0.046106427907943726,
      -0.045470885932445526,
      -0.0051430268213152885,
      -0.05950700119137764,
      0.015375505201518536,
      0.03255735710263252,
      -0.0012020590947940946,
      -0.0534784160554409,
      -0.0022289271000772715,
      0.0008041890687309206,
      0.008107856847345829,
      0.060454968363046646,
      -0.020719503983855247,
      0.03776279836893082,
      0.025468161329627037,
      0.022357286885380745,
      -0.005918791983276606,
      -0.008907821029424667,
      -0.007436751388013363,
      0.027260886505246162,
      -0.05752979591488838,
      0.05019308254122734,
      -0.04441512003540993,
      0.027849292382597923
    ],
    [
      -0.009088568389415741,
      -0.027723096311092377,
      -0.03860440105199814,
      -0.051356565207242966,
      -0.06870883703231812,
      0.024596504867076874,
      -0.00982555840164423,
      -0.04116439446806908,
      0.06825906038284302,
      -0.01128897164016962,
      0.03666148707270622,
      -0.0698116347193718,
      -0.08599675446748734,
      0.014606470242142677,
      -0.03372075408697128,
      -0.022071322426199913,
      0.009604196064174175,
      -0.019714511930942535,
      0.04257792606949806,
      -0.11638997495174408,
      0.06844103336334229,
      -0.046978484839200974,
      0.0746724009513855,
      -0.026742134243249893,
      -0.0660807192325592,
      0.01004078984260559,
      0.05133812874555588,
      0.04314593970775604,
      0.02106466330587864,
      -0.006101257633417845,
      -0.013816418126225471,
      -0.07172568142414093,
      -0.05787069723010063,
      0.02630595862865448,
      0.07165847718715668,
      0.00343306758441031,
      0.036787696182727814,
      -0.020447779446840286,
      -0.0017955845687538385,
      0.004359313286840916,
      0.01162948738783598,
      0.057920992374420166,
      0.017118392512202263,
      0.014518563635647297,
      0.06980634480714798,
      0.007967888377606869,
      0.12903578579425812,
      -0.03524443134665489,
      0.018772626295685768,
      0.02221190743148327,
      0.07242657989263535,
      -0.017664233222603798,
      -0.004185441881418228,
      -0.014917390421032906,
      -0.012095062993466854,
      0.07607217878103256,
      -0.010255254805088043,
      -0.00032280455343425274,
      -0.08443842083215714,
      0.08486617356538773,
      -0.019349772483110428,
      0.03849244862794876,
      -0.07708746194839478,
      0.11650824546813965,
      0.0011943449499085546,
      0.06893528252840042,
      -0.00527867441996932,
      0.0004795273707713932,
      -0.045793723315000534,
      0.0552668496966362,
      0.07828184962272644,
      -0.008701453916728497,
      -0.06757520884275436,
      -0.03681300953030586,
      -0.013438943773508072,
      0.023423269391059875,
      -0.012651304714381695,
      0.025859825313091278,
      -0.015406221151351929,
      0.03785618022084236,
      -0.04525056481361389,
      0.03307369723916054,
      -0.06164025142788887,
      -0.02999546192586422,
      -0.008725892752408981,
      -0.10457748919725418,
      -0.11021950840950012,
      -0.07985835522413254,
      0.01720373146235943,
      -0.007075008936226368,
      -0.10266543179750443,
      -0.038187846541404724,
      -0.022756773978471756,
      0.035330016165971756,
      0.03782806918025017,
      -0.00011203743633814156,
      0.018901227042078972,
      0.06205984577536583,
      0.06549420952796936,
      0.02709471806883812,
      0.06006314605474472,
      0.00564594054594636,
      -0.01588757149875164,
      0.04312209412455559,
      -0.047335680574178696,
      -0.07067983597517014,
      0.023840008303523064,
      -0.03937913477420807,
      -0.06528767198324203,
      0.06106698140501976,
      0.023364106193184853,
      0.03113262914121151,
      -0.013536883518099785,
      0.017452336847782135,
      -0.04315550997853279,
      0.02765347808599472,
      0.029552120715379715,
      -0.05778327211737633,
      -0.020976753905415535,
      -0.007656899280846119,
      0.024079831317067146,
      0.04017391428351402,
      -0.01043352298438549,
      -0.04376739263534546,
      0.03811484947800636,
      0.004982288461178541,
      0.035372521728277206,
      0.004307921044528484,
      -0.005388063378632069,
      -0.023281875997781754,
      -0.08051307499408722,
      -0.012467114254832268,
      0.012818807736039162,
      0.01285445038229227,
      -0.005421391222625971,
      0.010538388974964619,
      0.019342828541994095,
      -0.00938063021749258,
      0.017385853454470634,
      0.019986053928732872,
      0.03867339342832565,
      -0.04128621518611908,
      0.05755883827805519,
      -0.02751186490058899,
      0.07786163687705994,
      0.035558056086301804,
      -0.14478078484535217,
      0.06645701825618744,
      0.03142710030078888,
      0.11597299575805664,
      0.011498961597681046,
      -0.09747420996427536,
      -0.004736765753477812,
      -0.025900928303599358,
      0.029698366299271584,
      0.01245934795588255,
      -0.011583010666072369,
      -0.07592008262872696,
      -0.09319683909416199,
      0.01603195071220398,
      0.07825248688459396,
      -0.025901690125465393,
      -0.05978858843445778,
      -0.0688367411494255,
      0.01899552159011364,
      -0.047492168843746185,
      0.04975900426506996,
      -0.006424747407436371,
      -0.042439114302396774,
      0.015217818319797516,
      -0.07063740491867065,
      0.03363964334130287,
      -0.06378595530986786,
      0.002335797995328903,
      -0.011809158138930798,
      -0.000985599122941494,
      0.03731647506356239,
      0.10651637613773346,
      -0.0739050880074501,
      0.05980059131979942,
      0.02421651966869831,
      0.0891791358590126,
      0.023747041821479797,
      0.03459632396697998,
      -0.03530827537178993,
      -0.11619382351636887,
      0.055992718786001205,
      -0.015386901795864105,
      -0.033643048256635666,
      -0.04392547160387039,
      -0.007796790916472673,
      0.021507389843463898,
      0.029345285147428513,
      0.0893106460571289,
      -0.05929120257496834,
      -0.028961852192878723,
      -0.003379722824320197,
      0.016659453511238098,
      0.004374592565000057,
      -0.020153023302555084,
      -0.008962150663137436,
      0.03861499950289726,
      -0.0030597196891903877,
      0.08245549350976944,
      0.014413041062653065,
      0.005931464489549398,
      0.004739290568977594,
      0.018566319718956947,
      -0.035682711750268936,
      0.04688144847750664,
      0.03868100419640541,
      0.026540633291006088,
      0.049400340765714645,
      -0.012413176707923412,
      0.07380954176187515,
      -0.04205672815442085,
      -0.01483909785747528,
      0.0010989031288772821,
      -0.05836649611592293,
      -0.08877085149288177,
      -0.010218541137874126,
      0.030262909829616547,
      -0.08080702275037766,
      0.009292563423514366,
      -0.08240354061126709,
      -0.09542142599821091,
      -0.059873227030038834,
      -0.09297523647546768,
      -0.09951844811439514,
      -0.0277472585439682,
      0.01404621172696352,
      -0.014640272594988346,
      -0.016451774165034294,
      0.010864568874239922,
      0.056047890335321426,
      -0.04446331411600113,
      0.05615520104765892,
      -0.017404017969965935,
      -0.011099977418780327,
      -0.01073321420699358,
      0.060348574072122574,
      -0.12027294188737869,
      0.005203542299568653,
      0.019415607675909996,
      -0.052302148193120956,
      -0.06832659244537354,
      -0.0505838468670845,
      -0.08191347867250443,
      -0.0160966906696558,
      -0.04084726795554161,
      -0.03818894177675247,
      -0.021282624453306198,
      -0.06094645708799362,
      0.05297527089715004,
      -0.10315921157598495,
      -0.006639225874096155,
      0.055098190903663635,
      -0.00720091862604022,
      -0.0006554349674843252,
      -0.03917247802019119,
      0.036072611808776855,
      -0.007257598452270031,
      -0.0486229844391346,
      -0.046438347548246384,
      0.02481171488761902,
      -0.03097001276910305,
      -0.011890987865626812,
      0.06589046120643616,
      0.0040793027728796005,
      0.08727117627859116,
      -0.05560670420527458,
      0.01493456494063139,
      -0.04621054232120514,
      -0.0016030558617785573,
      0.042615607380867004,
      0.05388616397976875,
      -0.0006874324171803892,
      0.03933859243988991,
      -0.06773824244737625,
      0.01609833352267742,
      0.05789085477590561,
      0.14781594276428223,
      0.050342854112386703,
      0.06354807317256927,
      -0.019462333992123604,
      0.04630205035209656,
      0.07328630238771439,
      0.015560312196612358,
      -0.06383766978979111,
      -0.04367648437619209,
      0.04959253966808319,
      0.002441687509417534,
      0.031173521652817726,
      0.010380775667726994,
      -0.02677690051496029,
      0.006849959492683411,
      0.03335830941796303,
      -0.04438742622733116,
      -0.044361457228660583,
      0.07013500481843948,
      -0.06687349826097488,
      -0.00867353193461895,
      -0.020608477294445038,
      -0.012765730731189251,
      -0.05190887674689293,
      -0.013776371255517006,
      0.0776958242058754,
      0.05508280545473099,
      0.006941592786461115,
      -0.001999265979975462,
      0.008153053931891918,
      -0.06025141477584839,
      0.008738086558878422,
      -0.053850628435611725,
      0.011820206418633461,
      0.002204188145697117,
      -0.031432099640369415,
      -0.011214355006814003,
      0.02696879953145981,
      -0.0011064748978242278,
      -0.0335925817489624,
      -0.10904332250356674,
      -0.004804171621799469,
      -0.025632981210947037,
      -0.0509730763733387,
      0.023419855162501335,
      -0.006554433610290289,
      0.07510299980640411,
      -0.02453981153666973,
      -0.012792790308594704,
      0.00988570973277092,
      -0.07822970300912857,
      0.01163391675800085,
      -0.03555796295404434,
      -0.02677829936146736,
      -0.01871686615049839,
      0.022831078618764877,
      0.019029149785637856,
      -0.06265529245138168,
      0.05211532488465309,
      -0.03952161595225334,
      0.02735947072505951,
      -0.0666109248995781,
      0.0719139575958252,
      0.01474277675151825,
      -0.058469995856285095,
      0.007215211633592844,
      -0.008947872556746006,
      -0.011356283910572529,
      0.03191841021180153,
      -0.04068808630108833,
      0.04494985565543175,
      -0.06923826783895493,
      -0.07294797897338867,
      -0.06020292267203331,
      0.0432245209813118,
      -0.04143931716680527,
      0.029485400766134262,
      0.000860378728248179,
      -0.044593263417482376,
      0.0203706007450819,
      0.038040731102228165,
      0.01622813567519188,
      -0.0617944672703743,
      -0.08782842010259628,
      -0.029976079240441322,
      -0.0016129378927871585,
      -0.009999275207519531,
      0.047451384365558624,
      0.022437864914536476,
      -0.01085648313164711,
      0.04777922108769417,
      -0.012409274466335773,
      -0.0027166949585080147,
      -0.05153355747461319,
      -0.013440274633467197,
      -0.004033316392451525,
      0.032925501465797424,
      -0.05502551421523094,
      -0.08874540030956268,
      -0.07467709481716156,
      0.03707761690020561,
      -0.1103026270866394,
      0.029708152636885643,
      -0.09427302330732346,
      0.024556666612625122,
      0.0398324653506279,
      -0.017712952569127083,
      0.023641176521778107,
      0.003413878846913576,
      0.018030691891908646,
      -0.004385897424072027,
      0.006741636898368597,
      0.07355280220508575,
      -0.04023288935422897,
      -0.06245129555463791,
      0.044853534549474716,
      0.04746648669242859,
      0.06400147825479507,
      -0.06544796377420425,
      -0.05176858603954315,
      -0.042481135576963425,
      -0.04862027242779732,
      0.04261554405093193,
      0.023218100890517235,
      0.02591359056532383,
      -0.04454725608229637,
      -0.05447712913155556,
      0.04762472212314606,
      0.014924706891179085,
      -0.07017301768064499,
      0.07556957751512527,
      -0.021065158769488335,
      0.0931866466999054,
      -0.04591333866119385,
      -0.007747349329292774,
      0.005389849655330181,
      -0.06933540105819702,
      0.06918663531541824,
      0.0007411015685647726,
      0.0002681450278032571,
      -0.030087575316429138,
      0.04641188308596611,
      0.01543305441737175,
      0.03796586021780968,
      -0.01212986558675766,
      -0.03372221440076828,
      -0.003553831484168768,
      -0.018610600382089615,
      -0.026517702266573906,
      -0.007713236380368471,
      -0.06881418824195862,
      -0.043074604123830795,
      -0.04876874014735222,
      0.004944873508065939,
      0.03648904711008072,
      0.08260977268218994,
      0.05263913422822952,
      -0.05154441297054291,
      0.044197168201208115,
      -0.04560420289635658,
      -0.06645465642213821,
      0.00560787133872509,
      0.03210759162902832,
      -0.03650479018688202,
      -0.029269786551594734,
      -0.09397371113300323,
      -0.03321642428636551,
      -0.0753791332244873,
      0.03771235793828964,
      0.032235484570264816,
      0.056264568120241165,
      0.04988326504826546,
      -0.07249440997838974,
      0.027379920706152916,
      0.014350361190736294,
      -0.07879176735877991,
      0.03828008845448494,
      0.008479506708681583,
      0.04667598381638527,
      0.031395323574543,
      -0.005100858863443136,
      0.03361184149980545,
      0.05697362497448921,
      0.020216036587953568,
      0.06106008216738701,
      0.07585763931274414,
      -0.07022658735513687,
      0.03420916944742203,
      -0.0014233854599297047,
      -0.0921325832605362,
      0.11743394285440445,
      -0.03562552109360695,
      -0.012921871617436409,
      0.009986693039536476,
      0.017497720196843147,
      0.026841692626476288,
      0.013668104074895382,
      0.003651644103229046,
      -0.010746500454843044,
      0.014262460172176361,
      0.07975471764802933,
      -0.009388498030602932,
      -0.006737534422427416,
      -0.007100766059011221,
      -0.044486094266176224,
      0.02221922017633915,
      0.044675566256046295,
      -0.014044802635908127,
      0.09322595596313477,
      -0.003009225009009242,
      -0.07393957674503326,
      0.038562946021556854,
      0.03143094480037689,
      -0.05657273903489113,
      0.016035545617341995,
      -0.027507519349455833,
      -0.013549827970564365,
      0.017291847616434097,
      -0.013573243282735348,
      0.009852351620793343,
      -0.09739390760660172,
      0.02178947627544403
    ],
    [
      0.020383231341838837,
      -0.013030731119215488,
      0.0007595343049615622,
      0.06750614941120148,
      0.0323241651058197,
      0.0034751971252262592,
      -0.0038868661504238844,
      -0.015877841040492058,
      0.09641151875257492,
      0.002183836419135332,
      -0.034816257655620575,
      -0.10393799096345901,
      -0.005819182377308607,
      -0.016728747636079788,
      0.06772726029157639,
      -0.01705055497586727,
      -0.01996879279613495,
      -0.06378932297229767,
      -0.022244257852435112,
      0.0591314360499382,
      -0.038257136940956116,
      0.07738783210515976,
      -0.010810263454914093,
      -0.1019173115491867,
      -0.13131944835186005,
      0.01223826315253973,
      0.009697150439023972,
      0.025357067584991455,
      -0.056743163615465164,
      0.05045974627137184,
      0.07670699059963226,
      -0.013582349754869938,
      -0.002102336846292019,
      -0.043591782450675964,
      -0.024312462657690048,
      -0.026198873296380043,
      0.058572880923748016,
      0.062133487313985825,
      0.0041482215747237206,
      0.0042388723231852055,
      0.08254757523536682,
      0.017383629456162453,
      -0.06026638299226761,
      0.031027795746922493,
      0.038430508226156235,
      0.03206251561641693,
      0.09853975474834442,
      -0.04391325265169144,
      -0.04207102581858635,
      -0.010272633284330368,
      0.03136860579252243,
      -0.005333778914064169,
      -0.022211836650967598,
      0.04004228487610817,
      0.04343637451529503,
      -0.03751358762383461,
      -0.02000131830573082,
      -0.03626634553074837,
      0.011817545630037785,
      0.02645491249859333,
      -0.01264423131942749,
      0.04638814553618431,
      0.0129909822717309,
      -0.05180821567773819,
      -0.03503884747624397,
      -0.018001800402998924,
      -0.0374162532389164,
      -0.04172816500067711,
      -0.009253286756575108,
      -0.050090476870536804,
      0.07273606956005096,
      -0.09419246017932892,
      -0.052589356899261475,
      -0.014179181307554245,
      -0.04327835515141487,
      0.041286811232566833,
      0.00724157877266407,
      -0.04215637221932411,
      0.012322355061769485,
      -0.08623354882001877,
      0.0008959328988566995,
      0.10763999074697495,
      0.007415733300149441,
      -0.00017462090181652457,
      0.0115024633705616,
      -0.029640860855579376,
      0.034659333527088165,
      -0.049946971237659454,
      0.06216473877429962,
      0.02258540317416191,
      -0.023733505979180336,
      0.0029704223852604628,
      0.029362838715314865,
      -0.11198437213897705,
      0.026912180706858635,
      0.04756882041692734,
      -0.03537733480334282,
      -0.032593823969364166,
      -0.01434517651796341,
      -0.019507616758346558,
      0.09083414077758789,
      -0.038388025015592575,
      0.016645198687911034,
      0.04951240122318268,
      -0.11247249692678452,
      0.06602557003498077,
      -0.005264097824692726,
      -0.03882119059562683,
      -0.05113745480775833,
      0.11601465195417404,
      -0.04727665334939957,
      0.025097763165831566,
      -0.014240087941288948,
      0.018337396904826164,
      0.0008440722594968975,
      0.005920870695263147,
      0.03512617200613022,
      0.00030477490508928895,
      -0.02317335084080696,
      -0.01756470650434494,
      0.06329840421676636,
      -0.00982111319899559,
      0.1027875691652298,
      0.053681980818510056,
      0.03283524140715599,
      -0.061732348054647446,
      0.006495512556284666,
      -0.09209077060222626,
      -0.0007658932008780539,
      0.03811382129788399,
      -0.026586441323161125,
      -0.023195629939436913,
      -0.028251465409994125,
      -0.02329236827790737,
      -0.0031128027476370335,
      -0.04490004852414131,
      -0.10252268612384796,
      -0.07960589975118637,
      -0.006893489044159651,
      -0.02190030924975872,
      -0.00904562696814537,
      -0.031939439475536346,
      -0.0626748576760292,
      0.011615133844316006,
      -0.03214559704065323,
      -0.05065011605620384,
      -0.019309816882014275,
      0.020719895139336586,
      0.016221486032009125,
      0.01708196848630905,
      -0.057519782334566116,
      -0.014028185978531837,
      -0.02466525323688984,
      0.008099359460175037,
      0.01867084950208664,
      -0.05212617293000221,
      0.020098265260457993,
      0.023990988731384277,
      0.03231235221028328,
      -0.04680440574884415,
      0.06033303216099739,
      0.012250591069459915,
      0.02953931875526905,
      -0.03958442434668541,
      0.0020156267564743757,
      0.04659548029303551,
      -0.014901908114552498,
      -0.04684733971953392,
      0.051960546523332596,
      0.028405040502548218,
      0.025005152449011803,
      -0.06975845247507095,
      0.005192044656723738,
      -0.057778917253017426,
      -0.08225855231285095,
      -0.0011860796948894858,
      -0.002477264031767845,
      0.07336737960577011,
      -0.025216417387127876,
      0.1016509011387825,
      -0.016728630289435387,
      -0.07392572611570358,
      0.019759219139814377,
      -0.04916344955563545,
      0.0020951107144355774,
      -0.009132500737905502,
      0.019375886768102646,
      0.0030947525519877672,
      0.007789306808263063,
      -0.020871691405773163,
      -0.1013515293598175,
      -0.05650663003325462,
      -0.09352187812328339,
      -0.054835740476846695,
      0.009077625349164009,
      -0.061603132635354996,
      -0.02031901851296425,
      0.004832914099097252,
      0.012598121538758278,
      -0.010418780148029327,
      0.026027947664260864,
      0.008288617245852947,
      0.01921193115413189,
      0.0376051627099514,
      0.05907971039414406,
      -0.011279611848294735,
      -0.024259505793452263,
      0.013757151551544666,
      0.009812016040086746,
      0.020540740340948105,
      -0.016655001789331436,
      0.004963018465787172,
      0.0700618252158165,
      0.002430692780762911,
      -0.05734773725271225,
      0.05415050685405731,
      0.006522500887513161,
      0.037156615406274796,
      -0.00893720518797636,
      -0.01642853207886219,
      -0.05569670349359512,
      0.07147223502397537,
      -0.03547351434826851,
      -0.07658273726701736,
      0.032994888722896576,
      -0.011791405268013477,
      0.0076074968092143536,
      0.03583742678165436,
      -0.006846348289400339,
      0.010121735744178295,
      -0.010560617782175541,
      -0.033063098788261414,
      0.01033873949199915,
      0.059584107249975204,
      -0.019007446244359016,
      -0.10296203196048737,
      0.011018349789083004,
      -0.01665470562875271,
      -0.004995214287191629,
      0.013481722213327885,
      0.04126323014497757,
      -0.003237051423639059,
      -0.06480205059051514,
      0.03542432561516762,
      -0.11524086445569992,
      0.046133510768413544,
      0.0647067278623581,
      0.0801200121641159,
      0.031406138092279434,
      -0.002122581237927079,
      0.04046742990612984,
      -0.05485687777400017,
      -0.031093914061784744,
      -0.03250366076827049,
      -0.09947242587804794,
      0.014427945017814636,
      -0.02716018818318844,
      -0.008960657753050327,
      -0.02009737305343151,
      0.0008141775615513325,
      0.07890982925891876,
      -0.03460674732923508,
      -0.021378254517912865,
      -0.020909231156110764,
      -0.002552587538957596,
      0.011821911670267582,
      0.005336842965334654,
      0.0015399472322314978,
      -0.021894430741667747,
      0.027213720604777336,
      -0.005627964623272419,
      0.011836600489914417,
      0.003459979547187686,
      -0.003341972129419446,
      0.04277512803673744,
      -0.024724602699279785,
      -0.05401620268821716,
      0.002523365430533886,
      -0.01019035093486309,
      0.0002676156291272491,
      0.011060803197324276,
      0.026066839694976807,
      0.10219558328390121,
      0.012010432779788971,
      -0.13228696584701538,
      -0.011388508602976799,
      -0.03962318226695061,
      0.09640643000602722,
      0.04278884083032608,
      0.046196989715099335,
      0.04424353316426277,
      0.02085256017744541,
      0.014429040253162384,
      0.04702067747712135,
      -0.001139377010986209,
      -0.024889294058084488,
      0.020410461351275444,
      0.0047279223799705505,
      -0.00334401847794652,
      0.00429747998714447,
      -0.02913360297679901,
      -0.028129475191235542,
      0.02473250962793827,
      0.036770664155483246,
      -0.06578270345926285,
      -0.011911571025848389,
      -0.04302845522761345,
      -0.048935066908597946,
      -0.0926005020737648,
      -0.008808950893580914,
      0.06533142179250717,
      -0.050125379115343094,
      -0.07505391538143158,
      -0.007362964563071728,
      -0.06783503293991089,
      0.00330558349378407,
      -0.07517009228467941,
      -0.03250446170568466,
      -0.025662090629339218,
      -0.03456922248005867,
      -0.006254099775105715,
      0.027734676375985146,
      -0.03128254413604736,
      0.011663701385259628,
      -0.05292089283466339,
      0.04613294079899788,
      -0.06513197720050812,
      0.0354858860373497,
      -0.011743968352675438,
      -0.012461891397833824,
      -0.06503207981586456,
      0.05914111062884331,
      0.06019403785467148,
      -0.05740923807024956,
      -0.0027047337498515844,
      0.026105308905243874,
      0.05468490347266197,
      -0.03567841276526451,
      0.014646030031144619,
      -0.033390432596206665,
      -0.033787745982408524,
      -0.0941389948129654,
      0.04249982908368111,
      0.10129272937774658,
      0.027727367356419563,
      -0.00025439957971684635,
      -0.029248462989926338,
      0.09155631065368652,
      -0.030466970056295395,
      -0.08011747151613235,
      0.024753740057349205,
      0.06370991468429565,
      0.06924894452095032,
      -0.05055654048919678,
      0.05542852729558945,
      -0.06804076582193375,
      -0.04262576252222061,
      0.018511643633246422,
      -0.033475957810878754,
      -0.017572080716490746,
      -0.033869847655296326,
      -0.02392297238111496,
      -0.03645511716604233,
      -0.018749548122286797,
      -0.02205321379005909,
      -0.07642095535993576,
      0.056480951607227325,
      0.04024827852845192,
      -0.04600122943520546,
      -0.03437208756804466,
      -0.015199405141174793,
      -0.002372179413214326,
      -0.029032086953520775,
      -0.014431565068662167,
      0.027008768171072006,
      -0.02946595288813114,
      -0.050793059170246124,
      0.02435476705431938,
      0.01761409267783165,
      0.05443190410733223,
      -0.014323834329843521,
      -0.07019933313131332,
      -0.03210671618580818,
      -0.016601141542196274,
      -0.07272439450025558,
      0.005392836406826973,
      -0.017627447843551636,
      -0.010208604857325554,
      0.06946724653244019,
      0.044761572033166885,
      -0.015982478857040405,
      0.0666675940155983,
      -0.016979292035102844,
      -0.08414362370967865,
      -0.04376170411705971,
      -0.02835286781191826,
      -0.009854697622358799,
      0.03652661293745041,
      -0.03747354820370674,
      0.0092740748077631,
      0.015832168981432915,
      0.06582275778055191,
      -0.134430930018425,
      0.11125370860099792,
      -0.008700823411345482,
      0.04541714861989021,
      0.003485417924821377,
      0.032567158341407776,
      0.013253503479063511,
      -0.04129922762513161,
      0.03935328871011734,
      -0.04053018242120743,
      0.05492795631289482,
      0.06574048101902008,
      0.02031068131327629,
      -0.0882759690284729,
      0.0016685074660927057,
      -0.06191478297114372,
      -0.024065015837550163,
      0.015142406336963177,
      -0.0421922393143177,
      0.005860181991010904,
      -0.014379210770130157,
      0.0465109646320343,
      0.044712215662002563,
      -0.0018068625358864665,
      0.012153377756476402,
      0.0007725150790065527,
      0.019546667113900185,
      -0.040859609842300415,
      -0.01571919582784176,
      0.013350347988307476,
      0.01051129586994648,
      0.013829316943883896,
      -0.01145082525908947,
      -0.004244172014296055,
      0.043788399547338486,
      0.06096532568335533,
      -0.014601027593016624,
      -0.057411666959524155,
      -0.010067149996757507,
      -0.011686429381370544,
      0.07703656703233719,
      0.02528826706111431,
      0.03408091515302658,
      -0.02960827574133873,
      -0.006274860352277756,
      0.021215902641415596,
      0.035403717309236526,
      -0.05391005054116249,
      0.0348808728158474,
      -0.016538649797439575,
      -0.11087227612733841,
      -0.043961599469184875,
      0.007589623332023621,
      -0.001624257885850966,
      -0.09963493794202805,
      0.0039465948939323425,
      -0.0070052919909358025,
      -0.01910618133842945,
      -0.0010240771807730198,
      -0.07251644879579544,
      -0.08649859577417374,
      0.010547417216002941,
      0.0029077513609081507,
      -0.036271415650844574,
      -0.01855224370956421,
      -0.0799546018242836,
      -0.031360264867544174,
      -0.01833285577595234,
      0.043029140681028366,
      0.020855357870459557,
      -0.0054507688619196415,
      0.011850887909531593,
      0.03582063317298889,
      -0.019109176471829414,
      0.006787285208702087,
      -0.0460362508893013,
      0.040285009890794754,
      -0.04166419804096222,
      0.0106011051684618,
      0.06247292831540108,
      -0.006556074600666761,
      -0.05956475809216499,
      -0.015296373516321182,
      0.04691969230771065,
      0.004840056877583265,
      -0.0041928584687411785,
      -0.030335742980241776,
      0.06257478147745132,
      0.0315021388232708,
      -0.07688694447278976,
      0.02043457143008709,
      -0.0021294537000358105,
      -0.02924356423318386,
      0.03935618698596954,
      0.019401084631681442,
      0.028177762404084206,
      0.07755506783723831,
      0.011945882812142372,
      0.03716876730322838,
      -0.0022124266251921654,
      0.04906347393989563
    ],
    [
      -0.0022781004663556814,
      0.008040121756494045,
      0.0933280661702156,
      -0.06781300902366638,
      -0.0068456563167274,
      0.02787630818784237,
      -0.0633464977145195,
      0.04122142866253853,
      0.04506325349211693,
      0.02179296314716339,
      -0.011721509508788586,
      0.10833489149808884,
      0.10808670520782471,
      -0.025517120957374573,
      0.006106954999268055,
      -0.028517620638012886,
      -0.0907164067029953,
      -0.028671398758888245,
      -0.03461853042244911,
      -0.0077528138644993305,
      -0.0313488245010376,
      0.030669376254081726,
      0.048641741275787354,
      -0.026027435436844826,
      -0.08736523240804672,
      0.08873478323221207,
      0.02205328270792961,
      -0.041042108088731766,
      0.03586293011903763,
      -0.011086779646575451,
      0.026995083317160606,
      0.015300816856324673,
      -0.020707665011286736,
      0.007547804154455662,
      0.006061106454581022,
      -0.01290950272232294,
      -0.06687565892934799,
      -0.06465988606214523,
      -0.02551223337650299,
      -0.031905513256788254,
      0.03764907643198967,
      0.07048983126878738,
      0.0344538576900959,
      0.042278699576854706,
      0.029917076230049133,
      0.08050666004419327,
      -0.011580940335988998,
      -0.01248213928192854,
      0.022441331297159195,
      -0.07490251213312149,
      0.014970466494560242,
      -0.0767463818192482,
      -0.03020027093589306,
      -0.003704390488564968,
      0.021103432402014732,
      0.035427507013082504,
      0.009969021193683147,
      0.003346828743815422,
      0.04717915505170822,
      -0.02681751921772957,
      0.08405689150094986,
      0.06545495986938477,
      0.01852378621697426,
      -0.03904292732477188,
      0.004546269774436951,
      -0.029632747173309326,
      -0.04681355133652687,
      -0.08968513458967209,
      -0.007258657366037369,
      -0.0503709651529789,
      -0.01180641446262598,
      -0.014614831656217575,
      -0.023713786154985428,
      0.06139947474002838,
      -0.06561800837516785,
      -0.00029633622034452856,
      0.043024174869060516,
      -0.009920552372932434,
      -0.029798028990626335,
      0.05837647244334221,
      0.023416507989168167,
      -0.020999561995267868,
      -0.016791874542832375,
      -0.01609049178659916,
      0.05475684255361557,
      -0.019437383860349655,
      0.006436492782086134,
      -0.06383946537971497,
      0.06392739713191986,
      0.02216915972530842,
      -0.00013224345457274467,
      0.026977233588695526,
      0.011296759359538555,
      0.11270193010568619,
      -0.02554834820330143,
      0.018813928589224815,
      -0.050325218588113785,
      0.009291140362620354,
      0.021437713876366615,
      -0.021850986406207085,
      0.056085601449012756,
      0.007220068015158176,
      0.039645589888095856,
      -0.07219983637332916,
      0.041095390915870667,
      -0.029395192861557007,
      0.046589598059654236,
      0.03824494406580925,
      0.010684310458600521,
      -0.006332031916826963,
      -0.01614237017929554,
      -0.03302706778049469,
      0.003368890145793557,
      0.04536541923880577,
      -0.02228756621479988,
      -0.10004935413599014,
      -0.03301483765244484,
      0.02896115742623806,
      0.0026213163509964943,
      0.03549277037382126,
      0.046297844499349594,
      -0.010555206798017025,
      0.001026859157718718,
      -0.09140130877494812,
      0.06319055706262589,
      0.005403297487646341,
      0.061961036175489426,
      0.02855445072054863,
      0.015353158116340637,
      0.05261966586112976,
      -0.018875952810049057,
      0.05241411179304123,
      -0.01606355980038643,
      -0.015538504347205162,
      -0.036513619124889374,
      0.03204808756709099,
      0.07864852994680405,
      0.06449614465236664,
      0.06516305357217789,
      0.06527993828058243,
      -0.027063842862844467,
      0.01001320406794548,
      -0.0029715762939304113,
      0.05121179297566414,
      0.0011939781252294779,
      0.0620717853307724,
      0.010718519799411297,
      0.088872030377388,
      0.006131135392934084,
      0.0717242956161499,
      0.01522595714777708,
      -0.04109526053071022,
      0.013619912788271904,
      -0.05024353787302971,
      -0.0028275700751692057,
      -0.016487911343574524,
      -0.07068508118391037,
      -0.016340674832463264,
      0.05950593203306198,
      0.07382854074239731,
      -0.017698446288704872,
      -0.004545870702713728,
      0.025008324533700943,
      0.05101778358221054,
      -0.012308099307119846,
      -0.015123480930924416,
      -0.06262525171041489,
      -0.028483515605330467,
      0.004665412474423647,
      -0.026322105899453163,
      -0.013156328350305557,
      0.05830656364560127,
      -0.06448386609554291,
      -0.061804067343473434,
      -0.0084594851359725,
      -0.02816433273255825,
      0.007863341830670834,
      -0.002211451530456543,
      0.050437528640031815,
      0.03804066404700279,
      -0.026562774553894997,
      0.02613079361617565,
      0.01906300149857998,
      -0.02254302054643631,
      0.05816026031970978,
      0.0719815194606781,
      0.03866142779588699,
      0.014842214994132519,
      0.027606843039393425,
      0.08873531967401505,
      0.03709617629647255,
      0.0038939963560551405,
      0.023089174181222916,
      0.010854105465114117,
      0.10216078162193298,
      -0.009906532242894173,
      0.028705745935440063,
      -0.005151674151420593,
      -0.025938911363482475,
      -0.045975975692272186,
      0.020288819447159767,
      0.015016186982393265,
      0.034969013184309006,
      0.04156167805194855,
      -0.004696056246757507,
      0.03257386386394501,
      -0.07331844419240952,
      -0.008638269267976284,
      -0.056079037487506866,
      0.0350409597158432,
      0.000910602742806077,
      0.042060237377882004,
      0.08334921300411224,
      0.05450662598013878,
      -0.06356481462717056,
      -0.01848592795431614,
      0.012753145769238472,
      0.06413962692022324,
      -0.029467757791280746,
      -0.036594457924366,
      0.006876474712044001,
      0.01788654550909996,
      -0.024333762004971504,
      -0.0020358434412628412,
      0.03136565163731575,
      0.010115856304764748,
      0.057959407567977905,
      -0.004625860136002302,
      0.031032906845211983,
      -0.06610779464244843,
      -0.036871571093797684,
      0.012595297768712044,
      0.001896403729915619,
      -0.043631669133901596,
      0.10558608919382095,
      -0.013110432773828506,
      -0.005925568751990795,
      -0.044517818838357925,
      -0.014832301996648312,
      -0.007100587245076895,
      0.047078028321266174,
      -0.023851968348026276,
      0.05494549870491028,
      -0.022816961631178856,
      0.02277550660073757,
      0.07781734317541122,
      0.07570938766002655,
      -0.007004300598055124,
      -0.04173271358013153,
      0.040780529379844666,
      0.044793352484703064,
      -0.009813094511628151,
      -0.014489296823740005,
      0.029054967686533928,
      -0.00033982505556195974,
      -0.009408192709088326,
      -0.07450518012046814,
      -0.0054576159454882145,
      0.023017412051558495,
      0.014994284138083458,
      -0.0389559231698513,
      0.038282569497823715,
      -0.048471689224243164,
      -0.005284816492348909,
      -0.01410765666514635,
      -0.0164211243391037,
      -0.01640980690717697,
      -0.06200648471713066,
      0.06698483973741531,
      -0.026567421853542328,
      -0.07673593610525131,
      0.02098052203655243,
      -0.011946994811296463,
      0.04113546386361122,
      -0.06570252776145935,
      -0.04414082691073418,
      -0.011601711623370647,
      0.006537615787237883,
      -0.05996762961149216,
      0.0529722236096859,
      0.016546713188290596,
      0.05181696265935898,
      0.03538189083337784,
      -0.003518566256389022,
      -0.01783136837184429,
      -0.013082374818623066,
      0.018114488571882248,
      0.12382552772760391,
      -0.01641344092786312,
      -0.0043120356276631355,
      0.005655586253851652,
      -0.06706735491752625,
      0.022113988175988197,
      -0.02018231526017189,
      -0.008633342571556568,
      0.00011966016609221697,
      -0.0003702262765727937,
      -0.10415806621313095,
      -0.005380976013839245,
      0.035139601677656174,
      -0.04676196351647377,
      0.024750005453824997,
      0.011385195888578892,
      0.0019942251965403557,
      -0.055103402584791183,
      -0.015325124375522137,
      0.018244875594973564,
      -0.04996344447135925,
      -0.04915396124124527,
      0.05885777249932289,
      0.018312418833374977,
      -0.051282696425914764,
      0.004613566678017378,
      0.06656251847743988,
      -0.03358235955238342,
      0.03510797396302223,
      -0.06997203826904297,
      -0.05808160826563835,
      -0.03940004110336304,
      0.006992280017584562,
      -0.050421591848134995,
      0.025145210325717926,
      0.01044875755906105,
      0.017308557406067848,
      0.0645623579621315,
      0.037717193365097046,
      -0.08060670644044876,
      0.061431724578142166,
      0.11792030185461044,
      0.018739961087703705,
      0.019389646127820015,
      0.10975813865661621,
      0.043330639600753784,
      -0.05277273431420326,
      0.052365612238645554,
      0.03351902589201927,
      0.08324000239372253,
      -0.08566595613956451,
      -0.03671572357416153,
      0.012684440240263939,
      0.047763925045728683,
      -0.028143594041466713,
      -0.01394774578511715,
      -0.011927357874810696,
      0.019430095329880714,
      -0.015845200046896935,
      -0.008997410535812378,
      -0.03829244151711464,
      0.04776981472969055,
      -0.042842499911785126,
      -0.01052743662148714,
      0.028904303908348083,
      -0.02093951217830181,
      -0.0220125000923872,
      0.0492524690926075,
      0.07280001789331436,
      0.01419508270919323,
      -0.04337770864367485,
      -0.008667374961078167,
      -0.1002337709069252,
      0.06395602226257324,
      -0.04471911862492561,
      -0.028452515602111816,
      -0.019848572090268135,
      -0.029153665527701378,
      0.0897790789604187,
      -0.03047572262585163,
      -0.027086695656180382,
      0.0045962040312588215,
      -0.027205491438508034,
      -0.012477075681090355,
      -0.022348733618855476,
      -0.035422783344984055,
      0.03225315362215042,
      0.029913829639554024,
      -0.0029363129287958145,
      0.021442752331495285,
      0.0313226617872715,
      -0.056465256959199905,
      -0.025173550471663475,
      0.00938585214316845,
      -0.06744449585676193,
      0.04554294794797897,
      0.04883306846022606,
      0.030326329171657562,
      -0.0770973488688469,
      -0.03235660120844841,
      0.049481891095638275,
      0.032451119273900986,
      0.003020290983840823,
      -0.00012212462024763227,
      0.005944383796304464,
      -0.006435767747461796,
      0.023186109960079193,
      0.030829107388854027,
      0.004799571819603443,
      -0.015987666323781013,
      0.03972916677594185,
      0.02915920875966549,
      -0.08141420036554337,
      -0.042682379484176636,
      -0.06222056224942207,
      -0.030240800231695175,
      -0.00981700886040926,
      -0.020874034613370895,
      -0.03452710062265396,
      -0.012150794267654419,
      0.0017045210115611553,
      0.012944900430738926,
      0.002402376849204302,
      0.01907346025109291,
      -0.013910851441323757,
      0.03575071319937706,
      -0.0783541277050972,
      0.0043283021077513695,
      0.06464778631925583,
      0.007734514772891998,
      0.006733780261129141,
      0.010446544736623764,
      0.04477386176586151,
      -0.05644139274954796,
      -0.02592412382364273,
      0.026769686490297318,
      0.12780258059501648,
      -0.018093889579176903,
      0.02311966009438038,
      0.017587430775165558,
      0.06983954459428787,
      0.033894896507263184,
      0.04576880484819412,
      0.004798850044608116,
      -0.048972681164741516,
      0.031246516853570938,
      0.01524428091943264,
      0.0518031120300293,
      0.028194693848490715,
      -0.05326198413968086,
      0.011480036191642284,
      0.018696140497922897,
      -0.003862811950966716,
      0.015450934879481792,
      0.029975000768899918,
      -0.028590455651283264,
      -0.05626126006245613,
      0.0038194232620298862,
      -0.04442473500967026,
      0.03142416849732399,
      0.06423243135213852,
      0.053108617663383484,
      0.015563896857202053,
      0.048052091151475906,
      -0.06085018068552017,
      -0.03017236851155758,
      -0.023232553154230118,
      -0.04097798094153404,
      -0.1052025854587555,
      0.015053300186991692,
      0.03663663566112518,
      0.007831824943423271,
      -0.0023737719748169184,
      0.06406494230031967,
      0.05626973509788513,
      -0.04143429547548294,
      0.06870047003030777,
      -0.028064412996172905,
      0.00440718699246645,
      -0.00423215888440609,
      -0.02654452435672283,
      -0.007926217280328274,
      0.04641195014119148,
      0.02710716240108013,
      0.04433825984597206,
      0.00410742312669754,
      0.057608477771282196,
      0.02242296189069748,
      -0.05091967433691025,
      0.027598589658737183,
      0.04773890599608421,
      -0.039696887135505676,
      -0.056925609707832336,
      0.016095247119665146,
      0.1165030375123024,
      -0.013944258913397789,
      0.026886025443673134,
      -0.06745271384716034,
      0.0029759055469185114,
      0.07282541692256927,
      0.01189564447849989,
      -0.03927908092737198,
      -0.06867891550064087,
      0.05550849810242653,
      0.010823912918567657,
      0.053242795169353485,
      -0.010478216223418713,
      0.016256321221590042,
      0.04198511317372322,
      0.027079137042164803,
      0.10783837735652924,
      0.02876690775156021,
      -0.017238875851035118,
      0.05628780648112297,
      -0.035068579018116,
      0.04508815333247185
    ],
    [
      0.013208541087806225,
      -0.019651157781481743,
      -0.009638069197535515,
      -0.017002373933792114,
      -0.013618167489767075,
      -0.011483286507427692,
      0.01755249872803688,
      0.05364641919732094,
      0.08832543343305588,
      0.03494628891348839,
      0.015306536108255386,
      -0.09116657078266144,
      0.07085435092449188,
      -0.05187555402517319,
      0.050761669874191284,
      -0.0015114282723516226,
      -0.046362023800611496,
      -0.05465250089764595,
      0.015819087624549866,
      -0.020747166126966476,
      -0.010857808403670788,
      0.003007194260135293,
      0.055567361414432526,
      -0.02241997979581356,
      -0.01771439239382744,
      -0.022158371284604073,
      0.04320487752556801,
      0.11524291336536407,
      0.04378519207239151,
      -0.042158279567956924,
      0.044706787914037704,
      -0.054598208516836166,
      -0.06924397498369217,
      0.003115940373390913,
      0.062377627938985825,
      0.003969068638980389,
      -0.013416126370429993,
      0.09059660136699677,
      -0.06235390529036522,
      0.033102404326200485,
      0.0015568670351058245,
      0.03111364133656025,
      0.055228978395462036,
      -0.018153760582208633,
      -0.05914630740880966,
      0.0007483225781470537,
      -0.02442445419728756,
      0.021792078390717506,
      0.014941154047846794,
      0.05683159455657005,
      0.06759604811668396,
      0.015591372735798359,
      0.018629757687449455,
      -0.013900605961680412,
      0.02775624394416809,
      0.024542521685361862,
      -0.08329188823699951,
      -0.034241896122694016,
      -0.12548314034938812,
      -0.01416037231683731,
      0.004485389217734337,
      -0.051349058747291565,
      -0.01578752137720585,
      -0.0805557370185852,
      0.050407472997903824,
      -0.07021047919988632,
      -0.020982366055250168,
      0.018921315670013428,
      0.025220837444067,
      -0.04340527951717377,
      -0.0415709987282753,
      0.01894204318523407,
      0.019662776961922646,
      -0.09763096272945404,
      0.02114444598555565,
      -0.011110099963843822,
      0.023838195949792862,
      0.02983626164495945,
      -0.06180766597390175,
      -0.04369835928082466,
      0.011817238293588161,
      0.0394282303750515,
      0.02614719048142433,
      0.018445385619997978,
      0.06068943440914154,
      0.07577631622552872,
      -0.015399003401398659,
      0.057441696524620056,
      -0.002026946982368827,
      -0.0138468649238348,
      -0.00796082429587841,
      0.014252172783017159,
      -0.030041120946407318,
      -0.057015810161828995,
      0.10893531888723373,
      -0.037655845284461975,
      -0.036862943321466446,
      -0.0074922675266861916,
      0.011233328841626644,
      0.001981680281460285,
      -0.042443256825208664,
      0.038383230566978455,
      -0.021679827943444252,
      -0.03592449799180031,
      0.03924539312720299,
      0.0504346638917923,
      -0.03965555503964424,
      -0.002945674816146493,
      0.0498415008187294,
      0.02339920587837696,
      0.000663895858451724,
      -0.02290574088692665,
      -0.03165405988693237,
      -0.004332970827817917,
      0.0453619621694088,
      0.01014776062220335,
      -0.04740886017680168,
      -0.04130760207772255,
      0.02634235844016075,
      -0.0073727332055568695,
      0.05972738191485405,
      -0.018144629895687103,
      0.07525306940078735,
      -0.04271506145596504,
      0.01071256771683693,
      -0.021836472675204277,
      0.04948435723781586,
      0.0481705367565155,
      0.02366052009165287,
      0.01816856674849987,
      -0.06331872940063477,
      -0.0006738389492966235,
      0.0513327531516552,
      -0.02700294367969036,
      -0.00839810911566019,
      -0.01760137267410755,
      0.01947360672056675,
      -0.008693264797329903,
      0.0061137499287724495,
      -0.04468202590942383,
      0.056279271841049194,
      -0.007219263818114996,
      -0.009517408907413483,
      -0.04174238443374634,
      -0.06251249462366104,
      0.024278070777654648,
      -0.06152744218707085,
      -0.035729873925447464,
      0.002120732795447111,
      0.02833251841366291,
      0.00173175148665905,
      0.04070918262004852,
      -0.03873876482248306,
      -0.011628192849457264,
      0.012074322439730167,
      -0.01633584126830101,
      0.03221658244729042,
      -0.021513324230909348,
      -0.04702441394329071,
      -0.07349132746458054,
      0.009425422176718712,
      0.07770247757434845,
      0.034659210592508316,
      0.07077807188034058,
      0.04707182198762894,
      -0.02787140943109989,
      0.05101023241877556,
      -0.05790482088923454,
      -0.008248236030340195,
      0.08710717409849167,
      -0.060187842696905136,
      0.004389910027384758,
      0.0672789067029953,
      0.012476861476898193,
      0.08952745795249939,
      -0.012739019468426704,
      0.06756826490163803,
      -0.008892388083040714,
      -0.015645699575543404,
      0.023172087967395782,
      -0.035328060388565063,
      0.009698660112917423,
      0.003984794020652771,
      -0.024369345977902412,
      0.05301534757018089,
      -0.018688641488552094,
      -0.01430139783769846,
      -0.0059509542770683765,
      0.05355406180024147,
      0.053844209760427475,
      0.032308489084243774,
      -0.007256474811583757,
      -0.05316635221242905,
      0.01524554193019867,
      -0.04866791144013405,
      0.008840666152536869,
      0.000483829207951203,
      -0.0027824630960822105,
      -0.1229800134897232,
      -0.11697954684495926,
      0.03382660821080208,
      -0.01977202109992504,
      0.018627995625138283,
      0.017614811658859253,
      0.025198880583047867,
      0.005070700775831938,
      -0.019127195701003075,
      -0.03978660702705383,
      -0.12385140359401703,
      0.04201991856098175,
      0.010102933272719383,
      -0.02875610813498497,
      0.0975317433476448,
      -0.014037210494279861,
      -0.0016605916898697615,
      0.047875795513391495,
      0.0266865324229002,
      0.11313463747501373,
      0.012728316709399223,
      -0.07744210958480835,
      -0.0444885790348053,
      0.04324192926287651,
      0.015244660899043083,
      -0.08168899267911911,
      -0.08833076059818268,
      0.06879568099975586,
      0.06709654629230499,
      0.025742925703525543,
      -0.018218617886304855,
      -0.0375748910009861,
      -0.05161634087562561,
      0.018690289929509163,
      -0.0043108100071549416,
      -0.024438846856355667,
      -0.034693799912929535,
      0.035767924040555954,
      0.033582545816898346,
      0.002506756456568837,
      0.001561131444759667,
      0.006315484177321196,
      -0.002722671488299966,
      -0.10299146175384521,
      0.036755647510290146,
      0.01941271685063839,
      0.0252468790858984,
      -0.06650379300117493,
      -0.07954487204551697,
      0.030384935438632965,
      0.016352616250514984,
      -0.002472424879670143,
      0.029639512300491333,
      0.0479917898774147,
      0.0027711144648492336,
      0.03761626407504082,
      -0.09272352606058121,
      0.0036207628436386585,
      -0.0021166850347071886,
      0.010921561159193516,
      0.039583735167980194,
      -0.0799752026796341,
      -0.0906442254781723,
      0.12858258187770844,
      -0.030019884929060936,
      -0.03221634775400162,
      0.01993938721716404,
      -0.023119427263736725,
      -0.014055153355002403,
      -0.0022286386229097843,
      -0.012447820976376534,
      -0.031624820083379745,
      -0.04089868813753128,
      0.04159924015402794,
      0.010325735434889793,
      0.045664507895708084,
      -0.025647029280662537,
      -0.04792295768857002,
      0.02382812462747097,
      -0.018017740920186043,
      0.07518830895423889,
      0.029915906488895416,
      -0.0448722243309021,
      0.039204590022563934,
      -0.0075927842408418655,
      0.10701608657836914,
      0.022614391520619392,
      0.059218741953372955,
      -0.056031979620456696,
      -0.017685461789369583,
      -0.06949753314256668,
      0.043610237538814545,
      0.0595858208835125,
      -0.014561773277819157,
      0.028596952557563782,
      0.00888829492032528,
      0.06883823871612549,
      0.014534341171383858,
      -0.0245661623775959,
      0.02185259386897087,
      0.06261242926120758,
      -0.006839110516011715,
      0.03893495351076126,
      -0.01789095811545849,
      0.022080019116401672,
      0.014489461667835712,
      -0.034702856093645096,
      -0.03222214803099632,
      -0.04245356097817421,
      -0.052750114351511,
      -0.10716661810874939,
      0.010414602234959602,
      0.01646062172949314,
      -0.006654052063822746,
      -0.037145137786865234,
      -0.012763640843331814,
      -0.0621061697602272,
      -0.007878448814153671,
      -0.12494117766618729,
      -0.019636936485767365,
      0.012467533349990845,
      0.024862349033355713,
      -0.09348013997077942,
      0.07152269035577774,
      0.05298498645424843,
      0.015420904383063316,
      0.023225152865052223,
      -0.03988252952694893,
      0.026115339249372482,
      -0.07020433992147446,
      -0.015408793464303017,
      -0.08309613913297653,
      -0.006813725922256708,
      0.05496446043252945,
      0.05047105252742767,
      -0.003504397813230753,
      0.043061383068561554,
      0.05035989731550217,
      0.05153033137321472,
      -0.0018235769821330905,
      0.08141319453716278,
      -0.044185370206832886,
      0.0445246584713459,
      0.018740341067314148,
      -0.013511987403035164,
      0.09486852586269379,
      0.01626475155353546,
      -0.05008042976260185,
      -0.05151498690247536,
      0.017414893954992294,
      -0.03123433142900467,
      -0.060527995228767395,
      0.026806006208062172,
      -0.08466017991304398,
      0.04030716419219971,
      -0.046966955065727234,
      0.021032677963376045,
      0.028472570702433586,
      0.027339912950992584,
      -0.04945773258805275,
      -0.012158769182860851,
      -0.03416123241186142,
      -0.09204616397619247,
      0.09926389902830124,
      -0.04311678186058998,
      -0.061290863901376724,
      0.024326466023921967,
      -0.06053060665726662,
      0.03542819246649742,
      0.11284437030553818,
      -0.004562716465443373,
      0.017212877050042152,
      -0.00851308461278677,
      0.024808600544929504,
      0.0793849527835846,
      -0.04308994486927986,
      0.07692648470401764,
      0.0019215934444218874,
      0.0009412075742147863,
      -0.012775921262800694,
      -0.01501579862087965,
      0.015589343383908272,
      0.022473491728305817,
      -0.0028347705956548452,
      0.03993174433708191,
      0.03706790879368782,
      -0.05706338211894035,
      0.04068100452423096,
      0.028564924374222755,
      -0.07114026695489883,
      -0.03337215259671211,
      0.008786552585661411,
      0.020279480144381523,
      0.09546874463558197,
      -0.06006438657641411,
      0.04536452889442444,
      0.09345601499080658,
      0.11125798523426056,
      0.059661295264959335,
      0.03039073944091797,
      0.00978319626301527,
      -0.0026852251030504704,
      0.06898204237222672,
      -0.01494262833148241,
      -0.0314127542078495,
      0.03087267279624939,
      -0.05516882985830307,
      -0.027292976155877113,
      -0.028056446462869644,
      0.05578644201159477,
      0.06520994752645493,
      0.015416796319186687,
      0.010765274055302143,
      -0.005717819090932608,
      0.021236956119537354,
      -0.07187798619270325,
      0.1294652819633484,
      0.02364431694149971,
      -0.03062417171895504,
      -0.00894535519182682,
      -0.049126505851745605,
      -0.01854405365884304,
      -0.004347205627709627,
      -0.04173355922102928,
      0.00915655866265297,
      0.07032164931297302,
      0.03174503892660141,
      -0.002541246125474572,
      0.005728303920477629,
      0.06560647487640381,
      -0.05534471571445465,
      -0.02880634367465973,
      0.07775408029556274,
      0.012401231564581394,
      -0.019108742475509644,
      -0.008507944643497467,
      -0.06239353120326996,
      -0.047771163284778595,
      -0.03526163473725319,
      -0.014447676949203014,
      0.04561563581228256,
      -0.03949347510933876,
      0.06577715277671814,
      -0.055225271731615067,
      -0.05141065642237663,
      -0.05532733350992203,
      0.030976293608546257,
      -0.05439596250653267,
      -0.01748722419142723,
      0.0031342352740466595,
      0.01519791129976511,
      0.09841281920671463,
      0.02066669426858425,
      0.00726676918566227,
      0.018192896619439125,
      -0.0023368357215076685,
      0.04131460189819336,
      -0.0452849343419075,
      0.015208112075924873,
      -0.0009531035902909935,
      0.021641137078404427,
      0.004881863947957754,
      0.017312319949269295,
      -0.060728784650564194,
      0.0034309118054807186,
      0.038134459406137466,
      -0.02572190761566162,
      -0.06815735250711441,
      0.002769320271909237,
      -0.037983447313308716,
      -0.049652714282274246,
      -0.04192698001861572,
      0.059633102267980576,
      0.028895698487758636,
      -0.0313752144575119,
      -0.11133044958114624,
      0.009341184981167316,
      -0.03872029855847359,
      -0.01683126389980316,
      -0.021637434139847755,
      -0.012211649678647518,
      -0.049471862614154816,
      -0.030760791152715683,
      0.02607191540300846,
      0.034235745668411255,
      -0.0854632705450058,
      0.06617631018161774,
      -0.029067128896713257,
      0.08711154013872147,
      0.0016826316714286804,
      -0.010202604345977306,
      0.023031102493405342,
      -0.09740506112575531,
      -0.018865475431084633,
      0.01368209719657898,
      0.01551029086112976,
      0.12461429834365845,
      -0.12128770351409912,
      0.06213079020380974,
      -0.023935561999678612,
      -0.01957128383219242,
      -0.01985154114663601,
      0.12743087112903595,
      0.07366017252206802,
      0.033603452146053314
    ],
    [
      0.020730704069137573,
      -0.005285095889121294,
      -0.003724505193531513,
      0.0484304279088974,
      -0.07221391797065735,
      -0.02793147787451744,
      -5.4051070037530735e-05,
      0.02812955155968666,
      0.035240668803453445,
      0.03058146871626377,
      -0.06191306933760643,
      0.09507644921541214,
      -0.09408564120531082,
      -0.009033625945448875,
      0.029143132269382477,
      -0.03439207002520561,
      -0.06934580951929092,
      0.03369960933923721,
      0.006257486995309591,
      -0.030910495668649673,
      0.01958806812763214,
      -0.0013310464564710855,
      -0.032543305307626724,
      0.060704052448272705,
      0.024125734344124794,
      0.08655615150928497,
      -0.025450365617871284,
      -0.020450981333851814,
      -0.015734786167740822,
      0.011744463816285133,
      0.10824063420295715,
      -0.09879431128501892,
      0.046964094042778015,
      -0.026472697034478188,
      -0.011813592165708542,
      0.027740005403757095,
      0.04513897746801376,
      -0.0029300199821591377,
      0.03369756415486336,
      -0.0532163642346859,
      0.030147595331072807,
      -0.004971330985426903,
      0.016322050243616104,
      -0.022445306181907654,
      0.07352082431316376,
      -0.02931010164320469,
      -0.04278101399540901,
      -0.053495172411203384,
      0.01022934727370739,
      -0.010990696959197521,
      -0.07681488990783691,
      0.024259410798549652,
      0.06133492290973663,
      0.0012408150359988213,
      -0.05243496596813202,
      0.09383959323167801,
      -0.06731218844652176,
      -0.04997648671269417,
      -0.04523787274956703,
      -0.03986659273505211,
      -0.026987135410308838,
      -0.04265318810939789,
      0.08878193795681,
      -0.11743877828121185,
      -0.02333504520356655,
      -0.08132635056972504,
      -0.06089535728096962,
      -0.022648949176073074,
      -0.03257053717970848,
      -0.05494387820363045,
      0.048922453075647354,
      0.049616094678640366,
      -0.07587716728448868,
      -0.08463062345981598,
      0.0266160536557436,
      0.07164336740970612,
      -0.02000267244875431,
      -0.003125877119600773,
      -0.05733323097229004,
      -0.041140567511320114,
      -0.03613433241844177,
      0.013813990168273449,
      -0.00824613869190216,
      -0.07249501347541809,
      0.05168112367391586,
      0.019500110298395157,
      0.09350742399692535,
      0.007663991767913103,
      0.024455739185214043,
      0.01749008521437645,
      0.05476650223135948,
      -0.031166110187768936,
      -0.07792690396308899,
      -0.0021152717527002096,
      0.0029689923394471407,
      -0.00872444175183773,
      0.009290640242397785,
      0.10120555013418198,
      -0.04140130430459976,
      -0.017974577844142914,
      -0.015069440007209778,
      -0.04899418726563454,
      -0.0586211197078228,
      -0.0027243695221841335,
      -0.019604938104748726,
      0.03407801315188408,
      -0.04847685620188713,
      -0.00357557600364089,
      -0.08723313361406326,
      0.028036125004291534,
      0.06540566682815552,
      -0.038915641605854034,
      -0.029484357684850693,
      0.08950018882751465,
      0.06808408349752426,
      -0.03455832973122597,
      0.04370322823524475,
      0.005249490030109882,
      -0.015202228911221027,
      -0.04166026413440704,
      0.024831507354974747,
      -0.03364310786128044,
      0.06140166521072388,
      -0.11035757511854172,
      -0.04023869335651398,
      0.06607729196548462,
      0.0390283539891243,
      0.03330497071146965,
      0.005575460381805897,
      0.033800672739744186,
      -0.08217400312423706,
      -0.07987777888774872,
      0.007435839157551527,
      -0.07077829539775848,
      -0.005750261712819338,
      0.07230101525783539,
      -0.01712797023355961,
      0.0073137287981808186,
      -0.00570696173235774,
      0.013571166433393955,
      0.010741173289716244,
      -0.02648983895778656,
      -0.11669021844863892,
      -0.035604752600193024,
      -0.09049007296562195,
      0.023257462307810783,
      0.0496821254491806,
      -0.010259420610964298,
      -0.023950936272740364,
      -0.07981940358877182,
      0.062300704419612885,
      -0.015595677308738232,
      -0.007645048201084137,
      0.002127193147316575,
      0.026193080469965935,
      0.10565441846847534,
      -0.03253879398107529,
      0.06835301965475082,
      -0.04163381829857826,
      0.03985000401735306,
      -0.02606012485921383,
      -0.029682209715247154,
      0.01005836296826601,
      0.026799403131008148,
      -0.03273181617259979,
      0.02605595625936985,
      0.09060101211071014,
      -0.04551021754741669,
      -0.030065882951021194,
      -0.02791234478354454,
      0.06067558377981186,
      -0.007337629795074463,
      -0.06941316276788712,
      -0.021874865517020226,
      -0.023091033101081848,
      -0.013554645702242851,
      -0.10314391553401947,
      0.01981406658887863,
      0.02487856149673462,
      0.0661599189043045,
      0.02249733731150627,
      0.02228364907205105,
      0.009646258316934109,
      0.053124427795410156,
      0.018046287819743156,
      0.026286857202649117,
      0.04012855142354965,
      -0.0323384590446949,
      -0.018668217584490776,
      0.01901978626847267,
      0.02044348046183586,
      -0.019150812178850174,
      -0.015974247828125954,
      -0.012318230234086514,
      0.006353035569190979,
      0.0205555260181427,
      -0.04392106831073761,
      -0.001604014658369124,
      0.03411644697189331,
      0.0327635183930397,
      0.014178459532558918,
      0.083525650203228,
      -0.00771679962053895,
      0.0035506843123584986,
      0.06606447696685791,
      -0.033134061843156815,
      0.043071284890174866,
      -0.007014765869826078,
      -0.067960225045681,
      0.049466557800769806,
      0.01038439106196165,
      -0.017171062529087067,
      0.08449920266866684,
      0.060745127499103546,
      0.04727337881922722,
      0.00032481265952810645,
      0.04680381715297699,
      0.036377038806676865,
      0.049090951681137085,
      -0.011195609346032143,
      -0.03386036306619644,
      0.023270035162568092,
      -0.058374516665935516,
      -0.031895529478788376,
      -0.04620147496461868,
      -0.02343549020588398,
      -0.018959077075123787,
      -0.013343109749257565,
      -0.04491722956299782,
      0.03718779981136322,
      -0.03872434422373772,
      0.04445762559771538,
      0.10995924472808838,
      0.00443872669711709,
      0.025255810469388962,
      0.01315737422555685,
      0.003389587625861168,
      0.08554545789957047,
      -0.04382752627134323,
      0.003932740539312363,
      0.0010356552666053176,
      -0.04499687999486923,
      0.03418050333857536,
      0.050672631710767746,
      -0.07608093321323395,
      -0.0240827314555645,
      0.01646854355931282,
      0.09997591376304626,
      -0.02141670323908329,
      0.09081605076789856,
      0.0033231282141059637,
      0.040728356689214706,
      0.041730478405952454,
      0.06256139278411865,
      -0.03452501446008682,
      -0.08281642943620682,
      0.009256365709006786,
      -0.024612843990325928,
      -0.036468639969825745,
      -0.05312911048531532,
      0.020623669028282166,
      0.013623745180666447,
      0.0548379011452198,
      -0.03222217783331871,
      -0.026443785056471825,
      -0.052548885345458984,
      0.07235926389694214,
      -0.04163694754242897,
      -0.08171816915273666,
      -0.0013112187152728438,
      0.006980160716921091,
      -0.02808358520269394,
      0.03193090111017227,
      -0.00042838367517106235,
      -0.013918306678533554,
      -0.014147257432341576,
      0.07504887878894806,
      0.05019615218043327,
      -0.01820790022611618,
      -0.021755333989858627,
      -0.06866341084241867,
      -0.07065735757350922,
      0.01821316033601761,
      0.0014999221311882138,
      0.06051638349890709,
      -0.006706729531288147,
      -0.08431625366210938,
      -0.06446550786495209,
      -0.07687018066644669,
      -0.038492538034915924,
      -0.010738554410636425,
      -0.015034247189760208,
      0.038800761103630066,
      0.02261250466108322,
      -0.005867863539606333,
      0.01444605365395546,
      -0.06932485848665237,
      -0.05280341953039169,
      0.0004306522896513343,
      0.04495316371321678,
      -0.02688268944621086,
      0.005582433193922043,
      0.042444225400686264,
      -0.029082495719194412,
      -0.07781065255403519,
      0.010550346225500107,
      -0.03660515323281288,
      0.011164781637489796,
      0.02956656739115715,
      0.04438747465610504,
      -0.054891809821128845,
      0.005118062254041433,
      0.07818908989429474,
      0.03204300254583359,
      -0.07202240824699402,
      -0.054106224328279495,
      -0.010899311862885952,
      0.0011369341518729925,
      -0.055275801569223404,
      -0.05794128403067589,
      -0.020817549899220467,
      0.09601181745529175,
      0.03091212920844555,
      0.015599742531776428,
      -0.0023952024057507515,
      -0.04059622064232826,
      -0.03564678877592087,
      0.029608163982629776,
      0.06565451622009277,
      0.02576262876391411,
      -0.015538150444626808,
      -0.05152299627661705,
      0.0005534027586691082,
      -0.042116351425647736,
      0.0037309317849576473,
      0.02090715616941452,
      0.05745955929160118,
      -0.1273067444562912,
      -0.04802728071808815,
      -0.002972020534798503,
      -0.053993936628103256,
      0.000813156773801893,
      0.0655522271990776,
      0.02693830616772175,
      -0.03388015180826187,
      0.07127231359481812,
      -0.08693811297416687,
      0.021671395748853683,
      -0.020784001797437668,
      0.02216116525232792,
      -0.01717664673924446,
      -0.015168901532888412,
      0.02543286792933941,
      -0.020934583619236946,
      -0.025204483419656754,
      -0.049821916967630386,
      -0.033026374876499176,
      -0.050669845193624496,
      -0.04601075500249863,
      0.03569449484348297,
      -0.020248830318450928,
      0.011650297790765762,
      0.0473131388425827,
      -0.03278116509318352,
      -0.0004168944724369794,
      -0.019718553870916367,
      0.04784096032381058,
      0.036885567009449005,
      -0.03570099174976349,
      0.04769301414489746,
      -0.06349392235279083,
      -0.030062709003686905,
      0.019868208095431328,
      -0.08475259691476822,
      0.010751350782811642,
      0.029094194993376732,
      -0.04082174971699715,
      -0.0008315931190736592,
      0.023375464603304863,
      -0.021598640829324722,
      -0.010078933089971542,
      0.0058295270428061485,
      0.014089168980717659,
      -0.03504900261759758,
      0.05346072092652321,
      0.05657222867012024,
      -0.021633800119161606,
      0.08199232816696167,
      -0.07812076807022095,
      0.0580633245408535,
      -0.055941931903362274,
      -0.005400046240538359,
      0.06915820389986038,
      0.012693234719336033,
      -0.05355508625507355,
      0.02799619920551777,
      -0.01221262663602829,
      0.01830647699534893,
      -0.026459796354174614,
      0.0017151487991213799,
      0.005566705483943224,
      0.09657172858715057,
      -0.07920115441083908,
      -0.015668442472815514,
      0.029191825538873672,
      -0.06473458558320999,
      -0.002619217149913311,
      0.017091676592826843,
      0.005114010535180569,
      0.03536481782793999,
      -0.02598336711525917,
      0.0014399433275684714,
      0.00766872288659215,
      0.03857637196779251,
      -0.04148723557591438,
      -0.0076218582689762115,
      0.03066263720393181,
      0.07678285241127014,
      0.023071058094501495,
      -0.010956330224871635,
      -0.09938251972198486,
      -0.07761907577514648,
      -0.009613565169274807,
      0.004955796524882317,
      -0.00941375456750393,
      -0.029278941452503204,
      -0.06301741302013397,
      0.04204821586608887,
      -0.005793346557766199,
      -0.046480707824230194,
      0.0020050769671797752,
      0.007977328263223171,
      0.07782118022441864,
      -0.008516496978700161,
      -0.0002615354605950415,
      0.022274695336818695,
      -0.028108449652791023,
      0.04384242743253708,
      -0.009567398577928543,
      0.051726870238780975,
      -0.12709110975265503,
      -0.03904866427183151,
      0.013823878951370716,
      -4.7479548811679706e-05,
      -0.04045494273304939,
      -0.011809433810412884,
      0.011855863966047764,
      0.01537411194294691,
      -0.01184388343244791,
      0.022351514548063278,
      0.051476653665304184,
      0.11114009469747543,
      -0.009982337243855,
      0.07144271582365036,
      -0.004944948945194483,
      -0.025495918467640877,
      0.06647561490535736,
      -0.037139736115932465,
      -0.04310207813978195,
      0.0496184341609478,
      -0.016266169026494026,
      0.025248108431696892,
      -0.0163887906819582,
      0.04277551546692848,
      -0.012483925558626652,
      -0.02470061369240284,
      -0.03327839821577072,
      -0.012304319068789482,
      -0.0482858307659626,
      0.008835670538246632,
      0.030305130407214165,
      -0.024168582633137703,
      0.10172445327043533,
      -0.06366492807865143,
      0.07944643497467041,
      0.09535236656665802,
      0.08616339415311813,
      -0.023335697129368782,
      0.0008515096269547939,
      0.004475319292396307,
      -0.03869909793138504,
      0.038823630660772324,
      -0.031509168446063995,
      0.007355946581810713,
      0.05133888125419617,
      0.008055685088038445,
      0.0253633800894022,
      0.010018509812653065,
      -0.02262362278997898,
      0.040286023169755936,
      -0.050948865711688995,
      -0.04837816208600998,
      0.026132112368941307,
      -0.02718544565141201,
      -0.03469780087471008,
      0.04561971127986908,
      0.06886923313140869,
      0.06849685311317444,
      -0.0031204335391521454,
      -0.03374254330992699,
      -0.03371689096093178,
      0.017538031563162804,
      0.016825789585709572
    ],
    [
      -0.020808259025216103,
      0.009028766304254532,
      0.020084239542484283,
      -0.0146557055413723,
      -0.03434314206242561,
      -0.033612508326768875,
      -0.06099183112382889,
      -0.018118519335985184,
      0.09347295761108398,
      -0.019001787528395653,
      -0.04989361763000488,
      -0.018911248072981834,
      0.03263961151242256,
      -0.021159399300813675,
      0.06692370027303696,
      0.04223546013236046,
      -0.02947801724076271,
      -0.06490259617567062,
      0.03500523045659065,
      -0.05248915031552315,
      0.00975677277892828,
      -0.08415313065052032,
      -0.0483541265130043,
      -0.025464845821261406,
      -0.0706908255815506,
      0.04742995277047157,
      0.047711439430713654,
      0.04295751824975014,
      -0.0451228953897953,
      0.08295910060405731,
      -0.022421428933739662,
      -0.026509389281272888,
      -0.03848220780491829,
      0.017520062625408173,
      -0.04069855436682701,
      0.03930108994245529,
      -0.036260370165109634,
      -0.044762950390577316,
      -0.020775195211172104,
      -0.03056289069354534,
      0.061535000801086426,
      -0.016714707016944885,
      -0.06438040733337402,
      0.011862751096487045,
      0.050752170383930206,
      -0.012463144026696682,
      -0.06463348120450974,
      0.01492499653249979,
      0.06545127928256989,
      -0.021173467859625816,
      -0.08457890152931213,
      -0.034393247216939926,
      0.05083860084414482,
      -0.0919545367360115,
      -0.06560041010379791,
      -0.038778480142354965,
      -0.005434291437268257,
      0.0748499259352684,
      0.013071959838271141,
      -0.03386610001325607,
      0.043551910668611526,
      0.023529579862952232,
      0.093022920191288,
      0.045543789863586426,
      0.06224571540951729,
      -0.08401602506637573,
      -0.020899886265397072,
      -0.04014676436781883,
      -0.03421478345990181,
      0.0006286560674197972,
      -0.043983619660139084,
      -0.097348652780056,
      -0.03671032562851906,
      -0.0030897411052137613,
      -0.06057881563901901,
      -0.017646649852395058,
      0.00307914474979043,
      -0.04541365057229996,
      0.019394176080822945,
      -0.08319079875946045,
      0.014013099484145641,
      -0.058616019785404205,
      0.05890698730945587,
      -0.05462189018726349,
      0.0009755811188369989,
      0.03589119389653206,
      -0.06625015288591385,
      -0.07124162465333939,
      0.053419750183820724,
      0.013266143389046192,
      -0.06785377115011215,
      -0.008103794418275356,
      -0.04942028969526291,
      0.029361188411712646,
      0.0890999436378479,
      0.04733869805932045,
      -0.09491050243377686,
      -0.07503508031368256,
      0.02163895219564438,
      0.004386326298117638,
      -0.01616242341697216,
      -0.013772637583315372,
      -0.0009752480546012521,
      -0.027802232652902603,
      -0.03159244358539581,
      0.014663022942841053,
      -0.026205454021692276,
      0.02199193462729454,
      0.11100788414478302,
      0.05160455405712128,
      -0.030218804255127907,
      0.025472145527601242,
      0.05460398644208908,
      0.07850680500268936,
      0.03797782212495804,
      0.03595632687211037,
      0.023719554767012596,
      0.0059381150640547276,
      0.04900715872645378,
      -0.01773594506084919,
      0.006082025356590748,
      -0.03476102650165558,
      -0.05090976133942604,
      0.038612350821495056,
      -0.00829544197767973,
      -0.030603431165218353,
      -0.0266785379499197,
      0.025058817118406296,
      0.003716937731951475,
      -0.04148898646235466,
      -0.033241648226976395,
      0.013304621912539005,
      -0.022447118535637856,
      -0.03245767951011658,
      -0.02401861548423767,
      0.03411150723695755,
      0.05045647174119949,
      0.04561412334442139,
      0.05706162005662918,
      -0.03882396221160889,
      0.014114315621554852,
      0.008445517159998417,
      -0.06774052232503891,
      0.023918619379401207,
      0.03872057422995567,
      0.023961668834090233,
      0.05931830406188965,
      -0.03273525461554527,
      0.14038711786270142,
      0.004345209803432226,
      -0.0501529723405838,
      0.025808323174715042,
      -0.033888544887304306,
      -0.04371116682887077,
      -0.0765242725610733,
      -0.050787489861249924,
      -0.018176482990384102,
      -0.024325590580701828,
      0.007816425524652004,
      0.00835443940013647,
      -0.029162511229515076,
      -0.004336888901889324,
      -0.03391440212726593,
      0.00751909613609314,
      0.08225669711828232,
      -0.029316933825612068,
      -0.08007774502038956,
      -0.01737469621002674,
      0.014431484043598175,
      -0.01145021989941597,
      0.008178453892469406,
      -0.03685043379664421,
      0.09506075829267502,
      -0.037412285804748535,
      -0.005855407565832138,
      0.007285733241587877,
      0.05407186225056648,
      0.04041275009512901,
      -0.03041994199156761,
      0.020240673795342445,
      0.011449565179646015,
      0.013820999301970005,
      -0.07813695073127747,
      -0.12310883402824402,
      -0.06499932706356049,
      0.02021704614162445,
      0.07264138013124466,
      -0.034338049590587616,
      -0.07100911438465118,
      0.03357034549117088,
      0.014245495200157166,
      0.0392267070710659,
      -0.05688481405377388,
      0.0660635381937027,
      -0.009061328135430813,
      -0.026084022596478462,
      -0.04102448374032974,
      -0.04727267473936081,
      0.015285373665392399,
      -0.03039798140525818,
      -0.033183496445417404,
      0.07835160940885544,
      0.023600254207849503,
      -0.011250043287873268,
      -0.011271931231021881,
      0.02119317278265953,
      0.05286157876253128,
      -0.031025711447000504,
      -0.006208223290741444,
      0.050992608070373535,
      0.004671156872063875,
      0.08589193224906921,
      0.08641836792230606,
      0.027717163786292076,
      0.03346128389239311,
      0.02738596871495247,
      -0.021701036021113396,
      0.0007273415103554726,
      -0.03859427571296692,
      0.028339456766843796,
      0.05493919178843498,
      0.05608604848384857,
      0.0015176408924162388,
      -0.017206644639372826,
      -0.02221117541193962,
      -0.06080125644803047,
      0.0611238069832325,
      -0.03431440517306328,
      -0.037961628288030624,
      0.01366891898214817,
      0.010049240663647652,
      -0.045259084552526474,
      0.0726451724767685,
      -0.0036735765170305967,
      0.0612187497317791,
      -0.04614594578742981,
      0.024098286405205727,
      0.005330794025212526,
      0.02486097440123558,
      0.028150781989097595,
      0.034919194877147675,
      0.1123470813035965,
      0.01999001018702984,
      -0.011735464446246624,
      -0.028733283281326294,
      0.05152735486626625,
      -0.009796924889087677,
      0.004186919890344143,
      -0.03693489357829094,
      0.07687729597091675,
      -0.07526413351297379,
      0.051677994430065155,
      -0.06081954389810562,
      0.029576612636446953,
      -0.055718615651130676,
      -0.058362022042274475,
      0.01136205717921257,
      -0.060015108436346054,
      -0.04831589013338089,
      -0.09290794283151627,
      -0.01387578435242176,
      0.0025446817744523287,
      0.009979660622775555,
      0.10956044495105743,
      0.03261036053299904,
      0.02427697367966175,
      0.02479013428092003,
      -0.057822104543447495,
      0.00551375700160861,
      0.07573626190423965,
      -0.04752596467733383,
      0.047878969460725784,
      -0.03471998870372772,
      0.02698701061308384,
      -0.0018836758099496365,
      0.016954932361841202,
      0.02438897080719471,
      -0.06650230288505554,
      -0.05332909896969795,
      0.027435610070824623,
      0.06829387694597244,
      0.06383345276117325,
      0.04688584432005882,
      -0.014717661775648594,
      0.0389813669025898,
      -0.050919223576784134,
      0.10150304436683655,
      -0.0013798812869936228,
      -0.042260464280843735,
      0.026525819674134254,
      0.061462488025426865,
      0.024189971387386322,
      -0.006253262050449848,
      -0.053189635276794434,
      -0.042991507798433304,
      0.0058637638576328754,
      0.00534324673935771,
      0.01751173473894596,
      0.009391624480485916,
      0.031041894108057022,
      -0.02036278136074543,
      -0.05049674212932587,
      0.007135952357202768,
      0.022041229531168938,
      -0.02306467480957508,
      -0.041895411908626556,
      0.004864065442234278,
      0.027272557839751244,
      -0.023407934233546257,
      -0.07306350767612457,
      0.06797730922698975,
      -0.026623720303177834,
      0.061205919831991196,
      -0.08331289887428284,
      0.008975676260888577,
      -0.05311175435781479,
      -0.0212439876049757,
      -0.08373915404081345,
      0.012147788889706135,
      -0.015162010677158833,
      -0.024308493360877037,
      0.04445406049489975,
      0.06083444133400917,
      0.0819789245724678,
      -0.06573153287172318,
      0.05363889038562775,
      -0.068785659968853,
      -0.026289066299796104,
      -0.016032150015234947,
      0.025833329185843468,
      -0.020269932225346565,
      0.09033895283937454,
      -0.017377223819494247,
      -0.02950596809387207,
      -0.04732896015048027,
      0.035456810146570206,
      -0.0053934925235807896,
      -0.022845322266221046,
      0.05572148784995079,
      0.01916325092315674,
      0.026738032698631287,
      0.028088152408599854,
      -0.07413288950920105,
      0.0499936006963253,
      0.04251628369092941,
      0.024988997727632523,
      0.019749997183680534,
      -0.045056045055389404,
      0.011051037348806858,
      0.011355550028383732,
      0.020424600690603256,
      0.13765111565589905,
      -0.11118527501821518,
      0.09400302916765213,
      -0.044444918632507324,
      -0.03661463409662247,
      0.09698662161827087,
      0.014901440590620041,
      -0.05269157513976097,
      -0.012455587275326252,
      0.02995123900473118,
      -0.06320344656705856,
      -0.013875718228518963,
      -0.043064914643764496,
      0.04830523580312729,
      -0.09644203633069992,
      -0.03460012376308441,
      0.08092643320560455,
      -0.05638265982270241,
      -0.01391261164098978,
      0.01994415558874607,
      0.05356822907924652,
      -0.008682983927428722,
      0.038994502276182175,
      -0.010197740979492664,
      -0.0010146991116926074,
      -0.049123577773571014,
      0.00865480862557888,
      -0.040773503482341766,
      -0.03073868900537491,
      -0.0010109380818903446,
      0.011151334270834923,
      0.08601758629083633,
      -0.018714850768446922,
      0.06347339600324631,
      0.12577654421329498,
      -0.034173332154750824,
      -0.015459941700100899,
      0.03510384261608124,
      -0.0027002866845577955,
      -0.01201551128178835,
      0.023988226428627968,
      0.033760085701942444,
      0.004004037007689476,
      0.02983332984149456,
      -0.04734211415052414,
      -0.008542359806597233,
      0.00310552422888577,
      -0.0015917866257950664,
      -0.04521455615758896,
      0.007000765763223171,
      0.05261147767305374,
      0.03309409320354462,
      0.011628332547843456,
      0.029851827770471573,
      0.007691820617765188,
      -0.09899663180112839,
      0.06660640239715576,
      0.05260704830288887,
      -0.03971429169178009,
      0.023626262322068214,
      -0.10594087094068527,
      0.020627258345484734,
      -0.08106973767280579,
      0.015097972936928272,
      -0.033777497708797455,
      -0.018453124910593033,
      -0.01567668281495571,
      -0.06390101462602615,
      -0.0344853512942791,
      -0.05275052785873413,
      -0.052964795380830765,
      0.02586672455072403,
      0.0015023675514385104,
      0.019782528281211853,
      -0.07612352818250656,
      -0.04912782460451126,
      -0.06459971517324448,
      -0.008350536227226257,
      0.02894938923418522,
      0.00738461222499609,
      0.012805972248315811,
      -0.010231193155050278,
      -0.052315983921289444,
      -0.002548160497099161,
      -0.03426037356257439,
      0.058261338621377945,
      -0.021004941314458847,
      -0.06584035605192184,
      0.009813512675464153,
      -0.03238102048635483,
      -0.02786431275308132,
      -0.054643869400024414,
      -0.018764011561870575,
      0.005718168802559376,
      -0.04818805307149887,
      0.0031911381520330906,
      0.04582665488123894,
      0.03926055133342743,
      0.06690574437379837,
      0.012923937290906906,
      0.02748769335448742,
      -0.00797327607870102,
      -0.03949025645852089,
      0.015500392764806747,
      -0.0031684611458331347,
      -0.09189675003290176,
      0.04580743983387947,
      -0.03324183449149132,
      -0.0005488116294145584,
      -0.022337153553962708,
      0.00325220194645226,
      0.06181066855788231,
      0.061847735196352005,
      0.06086169555783272,
      0.034829262644052505,
      0.05490701645612717,
      -0.04309184104204178,
      0.05424684286117554,
      -0.013560966588556767,
      0.04586372524499893,
      0.028958840295672417,
      0.06790398061275482,
      0.09788778424263,
      0.05743242800235748,
      -0.09846899658441544,
      -0.1372840851545334,
      -0.03074795752763748,
      -0.036068134009838104,
      0.016178427264094353,
      -0.03874676674604416,
      0.02525402046740055,
      -0.011171686463057995,
      -0.01221919059753418,
      -0.032843977212905884,
      -0.00720588443800807,
      0.03319256752729416,
      -0.002502941060811281,
      -0.014488665387034416,
      -0.015135224908590317,
      -0.01547583844512701,
      -0.038861263543367386,
      -0.04412083700299263,
      -0.0402558408677578,
      0.03440757468342781,
      0.031780607998371124,
      0.034563783556222916,
      0.14267869293689728,
      0.002480156486853957,
      -0.015570979565382004,
      -0.014194019138813019,
      -0.02855083718895912,
      0.037131961435079575
    ],
    [
      0.05390879511833191,
      0.015034005045890808,
      0.053662776947021484,
      -0.01592491753399372,
      -0.03699222207069397,
      0.04871281981468201,
      -0.040281422436237335,
      -0.011982793919742107,
      0.08455701172351837,
      -0.030243119224905968,
      0.037821535021066666,
      -0.012114991433918476,
      0.03540659323334694,
      -0.0196042712777853,
      0.03504862263798714,
      0.026832828298211098,
      -0.006356787867844105,
      0.06242598220705986,
      0.041570570319890976,
      -0.01069827564060688,
      -0.00990099273622036,
      -0.019717706367373466,
      0.0018158960156142712,
      0.03195313736796379,
      -0.07934282720088959,
      0.020798129960894585,
      -0.0065370602533221245,
      0.0117424838244915,
      0.0433708056807518,
      0.021666450425982475,
      -0.019427558407187462,
      -0.0813131108880043,
      -0.011314709670841694,
      0.053246233612298965,
      -0.07625823467969894,
      0.010805772617459297,
      0.025667935609817505,
      0.07625181972980499,
      -0.06396318972110748,
      -0.01863468624651432,
      -0.033595334738492966,
      0.000846205570269376,
      0.016041791066527367,
      0.054774209856987,
      0.06114775314927101,
      -0.03015274927020073,
      0.02091042511165142,
      -0.018998581916093826,
      0.08028511703014374,
      -0.01721956953406334,
      -0.0940089300274849,
      -0.038269151002168655,
      0.013690139167010784,
      -0.036332085728645325,
      -0.011538221500813961,
      -0.06770116835832596,
      -0.11158444732427597,
      -0.01212787814438343,
      -0.002999975113198161,
      -0.006204694043844938,
      -0.02995842695236206,
      0.018280601128935814,
      -0.017254233360290527,
      0.023730287328362465,
      0.11995232850313187,
      -0.04309222474694252,
      0.00674400245770812,
      0.09480638802051544,
      -0.004459247924387455,
      -0.06805739551782608,
      0.007683739066123962,
      -0.03412885218858719,
      0.03910401836037636,
      -0.032359808683395386,
      0.020740147680044174,
      0.006479552481323481,
      0.0901259183883667,
      0.062289152294397354,
      -0.05277206376194954,
      0.018784360960125923,
      0.0125172920525074,
      -0.04478922113776207,
      0.016031809151172638,
      0.028325608000159264,
      -0.03353453800082207,
      -0.029041510075330734,
      -0.027658293023705482,
      0.024261439219117165,
      0.02461426332592964,
      -0.060151200741529465,
      -0.009753450751304626,
      0.025949226692318916,
      -0.10069988667964935,
      -0.024995867162942886,
      0.0566931888461113,
      -0.00649672094732523,
      -0.0619005411863327,
      0.04189320653676987,
      -0.0555444099009037,
      0.04622042551636696,
      -0.054796408861875534,
      0.05783209204673767,
      -0.04395236447453499,
      0.05896550044417381,
      -0.01795952208340168,
      -0.01135263592004776,
      -0.01286988239735365,
      0.022597258910536766,
      0.010952725075185299,
      0.15414518117904663,
      -0.04273311793804169,
      -0.006658910308033228,
      -0.05444275960326195,
      -0.0841817557811737,
      0.02180774137377739,
      -0.004882118199020624,
      0.02054596319794655,
      -0.043591685593128204,
      0.030102740973234177,
      -0.06777913123369217,
      0.05986753851175308,
      -0.028458692133426666,
      0.024788295850157738,
      -0.045475177466869354,
      -0.004422749392688274,
      0.1141325905919075,
      -0.002559640444815159,
      -0.028613923117518425,
      0.0034249904565513134,
      -0.07035205513238907,
      -0.10824814438819885,
      -0.0013092536246404052,
      -0.08108959347009659,
      0.028732581064105034,
      -0.0017259074375033379,
      -0.0184775497764349,
      0.017969656735658646,
      -0.04636403173208237,
      0.04003079608082771,
      0.023373594507575035,
      -0.04244494065642357,
      0.025042591616511345,
      -0.07759655267000198,
      0.05227045342326164,
      -4.728637941298075e-05,
      -0.055368196219205856,
      0.06893566250801086,
      -0.00032654707320034504,
      0.007006242871284485,
      -0.027314525097608566,
      -0.039898015558719635,
      -0.07696353644132614,
      0.04716360569000244,
      -0.002058564219623804,
      -0.039627961814403534,
      -0.023244868963956833,
      -0.022862166166305542,
      -0.059574857354164124,
      0.0481681264936924,
      0.07657703757286072,
      0.024786153808236122,
      0.0960121899843216,
      0.06660111993551254,
      -0.034094929695129395,
      -0.045694466680288315,
      -0.006544230040162802,
      0.004408759530633688,
      0.057080090045928955,
      -0.01882624253630638,
      0.0861833244562149,
      0.06698819249868393,
      -0.030167773365974426,
      0.03311247006058693,
      -0.02487148903310299,
      0.01846262253820896,
      0.016104498878121376,
      0.025656050071120262,
      -0.04346831515431404,
      0.038489535450935364,
      -0.0003508570371195674,
      0.11631276458501816,
      -0.07114045321941376,
      -0.03701421245932579,
      0.020773593336343765,
      0.010777429677546024,
      -0.08246046304702759,
      0.07769522070884705,
      0.026266759261488914,
      -0.019521605223417282,
      0.052973341196775436,
      0.03733452782034874,
      0.024326186627149582,
      0.02117491140961647,
      -0.00399126997217536,
      0.03242882341146469,
      0.10778836905956268,
      -0.00949577335268259,
      -0.047104980796575546,
      0.05536853149533272,
      0.031986989080905914,
      0.026483457535505295,
      0.07167727500200272,
      -0.0037513691931962967,
      -0.012943891808390617,
      0.0219117384403944,
      0.048892050981521606,
      0.00846012495458126,
      -0.04153307527303696,
      -0.049081698060035706,
      -0.0651770606637001,
      -0.08350423723459244,
      0.0018095977138727903,
      0.10991602391004562,
      0.026015425100922585,
      -0.0274499524384737,
      -0.0026837142650038004,
      -0.06912492960691452,
      -0.024964148178696632,
      -0.057280756533145905,
      -0.06798863410949707,
      -0.022808294743299484,
      0.056151822209358215,
      -0.026146139949560165,
      -0.05424553155899048,
      0.05152764543890953,
      0.0032478380016982555,
      0.03254815191030502,
      0.04140377417206764,
      -0.026191264390945435,
      -0.013169828802347183,
      0.06544550508260727,
      0.0660410076379776,
      -0.06299290806055069,
      -0.06799082458019257,
      0.04510001465678215,
      0.03583770990371704,
      0.04689028859138489,
      0.03184027969837189,
      0.022952975705266,
      0.05572740361094475,
      0.043529901653528214,
      -0.07216671854257584,
      -0.0400809720158577,
      0.0449223555624485,
      0.06317232549190521,
      -0.00867019034922123,
      -0.004798330366611481,
      -0.014975804835557938,
      -0.017581144347786903,
      0.032380327582359314,
      -0.001543031889013946,
      -0.008185187354683876,
      0.04502435028553009,
      0.02664800174534321,
      0.01437404751777649,
      0.014486110769212246,
      0.10662822425365448,
      -0.08063703030347824,
      -0.01652376912534237,
      0.03087633103132248,
      -0.024109607562422752,
      -0.048411719501018524,
      -0.0020602887962013483,
      -0.016016704961657524,
      0.024903705343604088,
      0.052571624517440796,
      -0.00012270512524992228,
      -0.01945488713681698,
      0.08925323933362961,
      0.04193849861621857,
      0.007901474833488464,
      -0.058188360184431076,
      0.04713675379753113,
      0.03026789240539074,
      -0.008646308444440365,
      -0.07170538604259491,
      -0.016272496432065964,
      -0.06993265450000763,
      0.08695397526025772,
      0.07104654610157013,
      0.02005905471742153,
      0.03632692992687225,
      0.0147267896682024,
      0.06456070393323898,
      0.028047922998666763,
      0.03529660776257515,
      0.005314836744219065,
      0.00260640075430274,
      -0.024998212233185768,
      0.023248696699738503,
      0.032445937395095825,
      0.017641954123973846,
      0.04540487751364708,
      0.017955996096134186,
      0.06248987093567848,
      0.025066137313842773,
      0.01702113263309002,
      0.0005536266835406423,
      0.05179331824183464,
      -0.020821111276745796,
      0.02324480563402176,
      0.017288299277424812,
      -0.012704468332231045,
      -0.021459518000483513,
      0.053242288529872894,
      -0.06351727992296219,
      0.07556691020727158,
      0.006296142470091581,
      0.06844798475503922,
      -0.01366130169481039,
      0.05517765134572983,
      -0.034674592316150665,
      -0.016561465337872505,
      -0.131027489900589,
      -0.0277326051145792,
      0.0059388852678239346,
      0.051354553550481796,
      -0.03310389816761017,
      -0.03587127849459648,
      0.004342959728091955,
      -0.02719261310994625,
      -0.0734446719288826,
      0.06064038351178169,
      0.1052565649151802,
      0.01943144202232361,
      0.05864069238305092,
      -0.004412629641592503,
      -0.02840999886393547,
      0.04125498980283737,
      0.02181309461593628,
      -0.003345202887430787,
      -0.0693516656756401,
      0.034043919295072556,
      -0.058499451726675034,
      -0.02765410579741001,
      -0.023617887869477272,
      0.06938592344522476,
      -0.023827385157346725,
      -0.0690142959356308,
      0.01588040590286255,
      0.001631812658160925,
      0.057571735233068466,
      0.07750172913074493,
      0.03929112106561661,
      0.036966074258089066,
      0.042077478021383286,
      0.028732355684041977,
      0.017505111172795296,
      -0.03043411485850811,
      0.027561785653233528,
      -0.0308615043759346,
      0.05162705108523369,
      0.014607785269618034,
      -0.005233170464634895,
      0.006518541369587183,
      -0.08955258876085281,
      0.019905637949705124,
      0.08491885662078857,
      0.027932172641158104,
      -0.029504749923944473,
      -0.09373212605714798,
      -0.013182590715587139,
      0.009217916056513786,
      -0.006468770559877157,
      -0.020122671499848366,
      -0.01915733516216278,
      0.032272789627313614,
      -0.0615171417593956,
      0.00466621620580554,
      -0.0017846694681793451,
      0.023160839453339577,
      -0.012229064479470253,
      0.023001303896307945,
      -0.022388309240341187,
      0.015256612561643124,
      -0.019887227565050125,
      -0.01960204727947712,
      -0.04280772805213928,
      -0.03218640014529228,
      -0.06170300766825676,
      0.06525677442550659,
      0.08508365601301193,
      0.011332005262374878,
      -0.013676527887582779,
      0.011676661670207977,
      -0.004009529482573271,
      0.10179208219051361,
      -0.05267873406410217,
      0.008852663449943066,
      -0.13101397454738617,
      -0.005078120157122612,
      0.08461722731590271,
      -0.08155826479196548,
      0.03735384717583656,
      -0.04286076873540878,
      0.029072854667901993,
      -0.01576031558215618,
      0.04166891798377037,
      -0.03456167131662369,
      -0.0030025411397218704,
      0.020680129528045654,
      -0.08983667939901352,
      0.061375223100185394,
      0.026720242574810982,
      -0.002732852939516306,
      -9.721214883029461e-05,
      -0.0022800166625529528,
      0.04057043045759201,
      0.08012926578521729,
      -0.03185487911105156,
      -0.012218919582664967,
      0.010099292732775211,
      0.03318518027663231,
      -0.004858869593590498,
      -0.014416396617889404,
      0.025550924241542816,
      0.027904024347662926,
      0.010264992713928223,
      0.0035044525284320116,
      0.04066033661365509,
      0.0009970268001779914,
      -0.03808585926890373,
      0.015325825661420822,
      0.07372158765792847,
      -0.06265712529420853,
      -0.0979071706533432,
      -0.019844073802232742,
      -0.0205423254519701,
      -0.028628533706068993,
      0.05386877804994583,
      -0.07444079965353012,
      -0.009275284595787525,
      -0.08224815130233765,
      0.03090479038655758,
      0.003236841643229127,
      0.00688754441216588,
      -0.0666743591427803,
      -0.06128152832388878,
      0.059059999883174896,
      0.01648019813001156,
      0.018115907907485962,
      0.03280806168913841,
      0.02505636215209961,
      -0.0659986063838005,
      -0.04468219727277756,
      0.07023267447948456,
      -0.005666805896908045,
      0.03488638624548912,
      0.032911960035562515,
      0.018216438591480255,
      -0.036846499890089035,
      -0.02362549491226673,
      -0.014004208147525787,
      0.10705918073654175,
      0.040649209171533585,
      0.04809776693582535,
      -0.00560787133872509,
      0.008457353338599205,
      -0.024687526747584343,
      -0.011538758873939514,
      0.004508638754487038,
      0.06602393090724945,
      -0.017623387277126312,
      0.01919054426252842,
      0.08482423424720764,
      -0.06521032750606537,
      -0.013223271816968918,
      0.10574355721473694,
      0.05732988938689232,
      -0.01228989940136671,
      -0.04088534414768219,
      -0.010954129509627819,
      0.038708411157131195,
      0.018659761175513268,
      0.008274886757135391,
      -0.09572955965995789,
      0.043055493384599686,
      0.019807443022727966,
      0.016410747542977333,
      -0.09192021191120148,
      0.01918930932879448,
      0.027441630139946938,
      -0.05222184956073761,
      -0.023477520793676376,
      -0.03720247000455856,
      0.012809794396162033,
      0.07376348972320557,
      -0.012418227270245552,
      0.03748564422130585,
      0.0104688024148345,
      -0.055155038833618164,
      -0.05135778710246086,
      0.03497254103422165,
      -0.03137784078717232,
      0.009833693504333496,
      -0.01985738053917885,
      0.11378777027130127,
      0.03285786509513855,
      0.009624958969652653,
      -0.024176988750696182,
      0.016764024272561073,
      -0.005076047498732805,
      0.11012411117553711
    ],
    [
      0.05208209529519081,
      -0.008036871440708637,
      -0.028296470642089844,
      -0.06115747615695,
      -0.032942336052656174,
      0.07980867475271225,
      -0.024601027369499207,
      -0.006547458004206419,
      -0.0776720941066742,
      -0.025501659139990807,
      -0.1874816119670868,
      0.009875888004899025,
      -0.018640894442796707,
      -0.07170633971691132,
      -0.03027476742863655,
      -0.006853328552097082,
      0.0004403134807944298,
      0.013824841007590294,
      -0.08455771207809448,
      -0.05578865483403206,
      0.01752873696386814,
      0.04278669133782387,
      0.013151020742952824,
      -0.03909636661410332,
      -0.03476099297404289,
      0.029922135174274445,
      -0.020657775923609734,
      0.020402226597070694,
      0.021854661405086517,
      0.01394963450729847,
      0.03476300835609436,
      0.08269208669662476,
      0.012865460477769375,
      -0.03476126492023468,
      0.015503733418881893,
      -0.020904777571558952,
      -0.013007954694330692,
      -0.017541199922561646,
      -0.06198109686374664,
      -0.04607284814119339,
      -0.04053386673331261,
      -0.07172811031341553,
      0.022466449066996574,
      0.05166099965572357,
      -0.044650811702013016,
      0.0020325155928730965,
      -0.014019418507814407,
      -0.0402623675763607,
      -0.02212507277727127,
      -0.004639516584575176,
      -0.07192976027727127,
      -0.050523146986961365,
      -0.0013776824343949556,
      -0.011552258394658566,
      -0.0221747737377882,
      -0.038086142390966415,
      -0.054689109325408936,
      -0.06684491783380508,
      -0.02970113977789879,
      -0.11765122413635254,
      -0.021260496228933334,
      0.012286367826163769,
      0.016195304691791534,
      -0.045038774609565735,
      0.031453900039196014,
      -0.03593266010284424,
      0.0039116390980780125,
      -0.059910308569669724,
      -0.029767194762825966,
      -0.04362577199935913,
      0.0008932383498176932,
      0.0034429971128702164,
      0.03437471389770508,
      -0.015349253080785275,
      0.0411723293364048,
      0.021133525297045708,
      0.039644304662942886,
      0.024280661717057228,
      -0.0967751145362854,
      -0.04097766801714897,
      0.03617716580629349,
      0.07165845483541489,
      -0.05716148391366005,
      -0.01656976155936718,
      -0.05314340814948082,
      -0.037841543555259705,
      -0.06350928544998169,
      0.009366671554744244,
      -7.686828030273318e-05,
      -0.013503589667379856,
      -0.03286208584904671,
      -0.03632533922791481,
      0.008435375988483429,
      -0.02302638068795204,
      0.0033289361745119095,
      -0.002980152377858758,
      -0.047745514661073685,
      0.023839710280299187,
      0.06006811186671257,
      0.01649457775056362,
      -0.022503618150949478,
      -0.056286998093128204,
      0.05789876729249954,
      -0.053744491189718246,
      -0.04837530106306076,
      0.009822271764278412,
      -0.01987144537270069,
      -0.024134017527103424,
      -0.005664315074682236,
      0.05705946311354637,
      -0.06394823640584946,
      0.1107836589217186,
      0.011697822250425816,
      0.03217291459441185,
      -0.10350172221660614,
      -0.04903591424226761,
      -0.046244874596595764,
      -0.05664846673607826,
      0.01745705120265484,
      -0.015714701265096664,
      -0.028836797922849655,
      -0.023611318320035934,
      0.014831841923296452,
      -0.012585707008838654,
      -0.003117925487458706,
      0.02114604227244854,
      0.07816390693187714,
      0.006811735685914755,
      -0.024454522877931595,
      -0.018624072894454002,
      0.018394606187939644,
      -0.0710245743393898,
      0.02680767886340618,
      0.06980041414499283,
      0.07497315108776093,
      0.02413640357553959,
      -0.026915712282061577,
      0.013906067237257957,
      0.003858820302411914,
      -0.020663440227508545,
      0.022008122876286507,
      -0.017412681132555008,
      -0.02877364307641983,
      0.0026462371461093426,
      0.12125445157289505,
      -0.017461108043789864,
      -0.017040912061929703,
      -0.04353669658303261,
      0.1121324673295021,
      -0.05615418776869774,
      -0.027494043111801147,
      0.013833731412887573,
      0.010036986321210861,
      0.002402405021712184,
      0.08285156637430191,
      0.0011616551782935858,
      0.030351843684911728,
      0.02958117425441742,
      0.014372574165463448,
      0.07498553395271301,
      -0.04531697556376457,
      0.03729359433054924,
      -0.020828083157539368,
      -0.03745172545313835,
      0.049203068017959595,
      -0.003044669283553958,
      -0.021977264434099197,
      -0.08898287266492844,
      -0.0555492527782917,
      -0.006997939199209213,
      -0.028470657765865326,
      -0.06333403289318085,
      -0.01101090107113123,
      -0.050878025591373444,
      -0.020270196720957756,
      0.002224123803898692,
      0.052225857973098755,
      0.05440511927008629,
      0.017040546983480453,
      0.06116282194852829,
      -0.060099028050899506,
      -0.000726838770788163,
      0.021189987659454346,
      -0.06326532363891602,
      -0.02007143199443817,
      0.005156750790774822,
      0.08623353391885757,
      0.018203996121883392,
      0.006983336992561817,
      0.017403310164809227,
      -0.08365735411643982,
      0.010121387429535389,
      -0.037744928151369095,
      0.009093819186091423,
      0.058513157069683075,
      0.010151018388569355,
      -0.04628756642341614,
      -0.038063377141952515,
      0.010319650173187256,
      -0.02967417798936367,
      0.10289011150598526,
      0.032537516206502914,
      0.021220175549387932,
      -0.08751405775547028,
      -0.0010124315740540624,
      -0.02767585776746273,
      -0.03034115955233574,
      0.02590387873351574,
      -0.055440373718738556,
      0.027875933796167374,
      0.041111622005701065,
      -0.032649509608745575,
      0.1039491668343544,
      0.032231029123067856,
      0.016505667939782143,
      -0.07504161447286606,
      -0.00029483926482498646,
      -0.01885511912405491,
      -0.02182258851826191,
      -0.03267395496368408,
      -0.017170455306768417,
      0.01900266855955124,
      -0.00816410593688488,
      -0.07873718440532684,
      0.035608965903520584,
      0.02561325393617153,
      -0.019235169515013695,
      0.048960983753204346,
      -0.013545408844947815,
      0.06105509400367737,
      -0.01595710776746273,
      -0.06163962557911873,
      0.025316596031188965,
      -0.01905478723347187,
      -0.008238892070949078,
      -0.01499590091407299,
      0.010707599110901356,
      -0.04479612410068512,
      -0.008368682116270065,
      -0.03613422065973282,
      -0.037960413843393326,
      -0.04000728949904442,
      0.04562763124704361,
      -0.021668104454874992,
      -0.060193490236997604,
      -0.023870477452874184,
      -0.02721833996474743,
      -0.005659743677824736,
      -0.06869301199913025,
      0.014690599404275417,
      -0.021604185923933983,
      0.06836901605129242,
      0.005791264120489359,
      -0.013510595075786114,
      0.03491467610001564,
      0.03411848470568657,
      -0.019027741625905037,
      0.016503917053341866,
      -0.08707630634307861,
      -0.0007503193337470293,
      0.05962764099240303,
      0.010607810690999031,
      6.663785461569205e-05,
      0.020257936790585518,
      0.028332965448498726,
      0.012338360771536827,
      0.01772080361843109,
      -0.0385376513004303,
      -0.02923685871064663,
      0.0499957799911499,
      -0.049395523965358734,
      0.027850033715367317,
      0.002244404284283519,
      0.02442440576851368,
      0.0042912401258945465,
      -0.00640792865306139,
      0.026772573590278625,
      -0.006638727150857449,
      0.0418270081281662,
      0.022484658285975456,
      0.08220988512039185,
      0.027841825038194656,
      -0.03542592003941536,
      0.05666031688451767,
      -0.00268412078730762,
      -0.013503690250217915,
      0.028878092765808105,
      -0.005924905650317669,
      0.002078491263091564,
      -0.1034911498427391,
      0.02472490444779396,
      0.014304900541901588,
      -0.03330043703317642,
      -0.016333742067217827,
      -0.025434916839003563,
      0.03499588370323181,
      -0.020202025771141052,
      -0.08498561382293701,
      0.052804600447416306,
      0.040304385125637054,
      0.08138313889503479,
      0.05006502941250801,
      0.026675840839743614,
      0.045893747359514236,
      -0.060155704617500305,
      0.0486452542245388,
      -0.008916093036532402,
      -0.011677577160298824,
      -0.009721462614834309,
      0.02334076352417469,
      -0.07054383307695389,
      -0.06219054386019707,
      0.014628147706389427,
      -0.010437232442200184,
      -0.02234715409576893,
      0.02384636551141739,
      -0.03683086484670639,
      -0.024446673691272736,
      0.05471540614962578,
      0.021808233112096786,
      -0.058967262506484985,
      0.02012983150780201,
      -0.05175313353538513,
      0.09267698228359222,
      -0.017439987510442734,
      -0.03339504450559616,
      -0.021040719002485275,
      -0.12957358360290527,
      0.03726020082831383,
      -0.09819264709949493,
      -0.02913746051490307,
      0.013014585711061954,
      0.0057613239623606205,
      0.03614773601293564,
      0.005776966456323862,
      -0.07264335453510284,
      -0.01780092529952526,
      -0.04065652936697006,
      -0.0406453013420105,
      -0.004705222323536873,
      -0.016042811796069145,
      -0.0128173204138875,
      -0.08922170102596283,
      0.011870997957885265,
      -0.08755630254745483,
      -0.05160147696733475,
      0.08198799192905426,
      -0.012767178006470203,
      0.021302655339241028,
      -0.08746252954006195,
      -0.11299079656600952,
      0.01799086667597294,
      0.07352431863546371,
      0.01851600781083107,
      0.00428001256659627,
      -0.05017668008804321,
      0.11612568795681,
      0.02574940398335457,
      -0.07320243120193481,
      0.05489714816212654,
      -0.005913108587265015,
      -0.09013007581233978,
      0.010529245249927044,
      -0.022678883746266365,
      0.0056392052210867405,
      0.006407102104276419,
      0.012303173542022705,
      0.05383020266890526,
      0.07162977755069733,
      -0.009965580888092518,
      -0.02395624667406082,
      0.01932237297296524,
      -0.08289541304111481,
      -0.005861159414052963,
      -0.014291943982243538,
      0.04985931143164635,
      -0.0755842849612236,
      -0.0804855078458786,
      0.04730135202407837,
      0.01703549176454544,
      -0.03308264911174774,
      -0.03686194866895676,
      -0.0457116924226284,
      0.06858644634485245,
      -0.09293980896472931,
      -0.0008252323605120182,
      -0.032566990703344345,
      0.012404197826981544,
      0.025885777547955513,
      -0.05966094136238098,
      0.020192401483654976,
      -0.08444398641586304,
      -0.07093417644500732,
      -0.0605439692735672,
      -0.005972580518573523,
      0.04182959720492363,
      -0.06192974001169205,
      0.06283130496740341,
      0.00448227021843195,
      0.05560503527522087,
      -0.000531619181856513,
      0.009643367491662502,
      -0.029223022982478142,
      0.009928040206432343,
      0.01284376997500658,
      0.026367148384451866,
      -0.026654794812202454,
      -0.029027612879872322,
      -0.015207130461931229,
      -0.011788363568484783,
      -0.015103341080248356,
      0.029252586886286736,
      -0.0146138621494174,
      -0.005052671302109957,
      0.0822581946849823,
      -0.03911321610212326,
      0.0241995882242918,
      0.03878346085548401,
      -0.010116982273757458,
      -0.06790139526128769,
      -0.05542769283056259,
      -0.022323468700051308,
      0.004603455774486065,
      -0.036368343979120255,
      -0.033520616590976715,
      -0.0058472719974815845,
      0.044725291430950165,
      -0.07243266701698303,
      0.011277398094534874,
      0.010764198377728462,
      -0.028067098930478096,
      -0.04926479607820511,
      0.04171561822295189,
      -0.0023220551665872335,
      -0.04338792711496353,
      0.002687020692974329,
      0.03548966348171234,
      0.01276011485606432,
      0.005166324321180582,
      0.04835069179534912,
      0.08624693751335144,
      0.04763702675700188,
      0.060535043478012085,
      0.021454598754644394,
      0.010334606282413006,
      -0.01526304055005312,
      0.03949989750981331,
      0.014833714812994003,
      -0.027281511574983597,
      0.011131680570542812,
      0.038599733263254166,
      0.016378695145249367,
      0.051383838057518005,
      -0.06215480715036392,
      0.02946268580853939,
      -0.08268371969461441,
      -0.016052337363362312,
      0.04221290349960327,
      -0.001679060747846961,
      -0.005669461563229561,
      0.008573788218200207,
      -0.0469404011964798,
      0.017802920192480087,
      0.15698057413101196,
      -0.014613830484449863,
      0.025030631572008133,
      0.040264327079057693,
      -0.013489896431565285,
      -0.05172285437583923,
      -0.04477761313319206,
      -0.04017545282840729,
      -0.05648614466190338,
      0.007925225421786308,
      0.030937520787119865,
      0.012175241485238075,
      0.011782987974584103,
      -0.0969630554318428,
      -0.02589048631489277,
      -0.0967855304479599,
      -0.08245406299829483,
      0.010608524084091187,
      0.11370296031236649,
      0.06966843456029892,
      0.03828512877225876,
      0.005323921795934439,
      0.023937871679663658,
      -0.061088886111974716,
      -0.046601660549640656,
      0.03580207750201225,
      -0.08087097853422165,
      -0.017759300768375397,
      0.043224167078733444,
      0.019149206578731537,
      0.041553694754838943,
      0.00019644576241262257,
      0.019747599959373474,
      -0.000660248682834208,
      0.03697814792394638,
      -0.03801272436976433,
      0.02440909668803215,
      -0.019905254244804382,
      -0.07134503871202469,
      0.040031395852565765
    ],
    [
      0.03945354372262955,
      -0.04338876157999039,
      -0.024806786328554153,
      0.04075130447745323,
      -0.03850030526518822,
      -0.0798356682062149,
      -0.028087068349123,
      0.06507571041584015,
      -0.04648277908563614,
      -0.030510740354657173,
      -0.04256557673215866,
      0.030750250443816185,
      -0.03664301335811615,
      -0.037195153534412384,
      0.06386067718267441,
      -0.014585702680051327,
      0.0017674907576292753,
      0.049764104187488556,
      -0.041896969079971313,
      -0.02996845357120037,
      0.012382843531668186,
      0.013498237356543541,
      -0.09014952182769775,
      -0.03201383352279663,
      -0.0008886074065230787,
      0.0639294907450676,
      -0.03950805217027664,
      0.006964666303247213,
      0.08728528022766113,
      0.013386129401624203,
      0.02396119199693203,
      -0.019123245030641556,
      -0.01695631444454193,
      0.04166414588689804,
      -0.01086481288075447,
      0.08516991138458252,
      -0.05147426947951317,
      -0.11426053941249847,
      0.0001449635747121647,
      -0.008115812204778194,
      0.06684332340955734,
      0.07097706943750381,
      -0.03151017799973488,
      -0.02460801601409912,
      0.1045866534113884,
      -0.04281053692102432,
      -0.0792088732123375,
      0.03336981311440468,
      0.04205185920000076,
      -0.08424446731805801,
      0.05960055813193321,
      -0.07076742500066757,
      -0.013362630270421505,
      -0.009858270175755024,
      -0.03959202393889427,
      0.02199169434607029,
      -0.03143017739057541,
      -0.09299121797084808,
      0.025726815685629845,
      -0.043782979249954224,
      0.015899399295449257,
      -0.049759719520807266,
      0.08763907849788666,
      -0.009895993396639824,
      0.023134643211960793,
      -0.004173090681433678,
      -0.012593814171850681,
      0.0391039177775383,
      0.0872732475399971,
      -0.11938688904047012,
      0.01619495078921318,
      -0.049755651503801346,
      0.11555510014295578,
      0.014622540213167667,
      0.00923822820186615,
      -0.0987359881401062,
      0.006844920571893454,
      0.037517186254262924,
      -0.01577047072350979,
      0.039396826177835464,
      -0.05529173091053963,
      0.09054545313119888,
      -0.0025207283906638622,
      0.04376532882452011,
      0.03552808240056038,
      0.0762685015797615,
      -0.06913406401872635,
      0.0721374899148941,
      0.045762110501527786,
      0.02200319431722164,
      -0.035801731050014496,
      0.0388023778796196,
      -0.17974865436553955,
      -0.03635722026228905,
      0.025262268260121346,
      0.04001237452030182,
      0.007289330009371042,
      0.029610564932227135,
      0.01896614022552967,
      0.0394584983587265,
      0.0611824207007885,
      0.006583172362297773,
      0.003816274693235755,
      0.04408052936196327,
      -0.06455022096633911,
      -0.038456812500953674,
      0.009086618199944496,
      -0.047437313944101334,
      0.028762897476553917,
      0.09353729337453842,
      -0.04062860086560249,
      -0.043553102761507034,
      0.0015472366940230131,
      0.0024388511665165424,
      0.045258279889822006,
      0.033847931772470474,
      0.023230120539665222,
      -0.0026108946185559034,
      0.04491434618830681,
      0.05800089240074158,
      0.006999981123954058,
      0.05383945629000664,
      -0.0014156186953186989,
      0.017357297241687775,
      0.005608398001641035,
      -0.02041480876505375,
      -0.04041717201471329,
      0.017319422215223312,
      -0.02449711412191391,
      -0.07502558082342148,
      -0.02773994207382202,
      -0.08787643909454346,
      -0.029781505465507507,
      0.003777534933760762,
      -0.04823485016822815,
      0.024573368951678276,
      0.03641362860798836,
      -0.0024891472421586514,
      0.013685626909136772,
      -0.008758213371038437,
      -0.0981798991560936,
      0.05511893704533577,
      0.03674286603927612,
      0.04904527962207794,
      -0.043049681931734085,
      0.03807754069566727,
      -0.016734430566430092,
      0.026043515652418137,
      0.033427201211452484,
      -0.005360171664506197,
      -0.028687091544270515,
      0.018509110435843468,
      -0.14177672564983368,
      0.033800650388002396,
      -0.020740142092108727,
      0.020724087953567505,
      -0.04845291003584862,
      -0.02435772307217121,
      -0.010073934681713581,
      0.03811320289969444,
      -0.1030002012848854,
      0.0023830838035792112,
      0.025836287066340446,
      0.06129937618970871,
      -0.036733753979206085,
      -0.08009852468967438,
      -0.012984922155737877,
      0.024962181225419044,
      0.03083379752933979,
      -0.03343291953206062,
      0.020789416506886482,
      -0.05953150987625122,
      -0.013712056912481785,
      -0.008467894047498703,
      0.01639297977089882,
      -0.08466752618551254,
      0.008914420381188393,
      0.03623872250318527,
      -0.08200480043888092,
      -0.002755954163148999,
      -0.10095585882663727,
      0.14025288820266724,
      0.020177830010652542,
      0.008405307307839394,
      0.025395499542355537,
      0.005622900556772947,
      0.011077764444053173,
      0.03119073249399662,
      0.03082614578306675,
      -0.02504049427807331,
      -0.06545595079660416,
      -0.018640246242284775,
      -0.07322683185338974,
      0.048593536019325256,
      0.03764158859848976,
      -0.018973855301737785,
      -0.007401392329484224,
      -0.052897121757268906,
      0.006625321693718433,
      -0.015142742544412613,
      0.05342375859618187,
      -0.008331370539963245,
      -0.055434685200452805,
      0.051397379487752914,
      -0.04846876859664917,
      0.022153673693537712,
      -0.038172658532857895,
      0.07164312154054642,
      0.008233905769884586,
      0.04829154908657074,
      -0.03461417928338051,
      0.037100665271282196,
      0.09225895255804062,
      0.024214131757616997,
      0.0022398694418370724,
      0.02353379875421524,
      0.002916141413152218,
      -0.016958508640527725,
      0.10645118355751038,
      -0.051172152161598206,
      0.050552960485219955,
      0.05614546313881874,
      -0.06026395037770271,
      0.0005161112057976425,
      0.0014251524116843939,
      0.04161185026168823,
      0.013373917900025845,
      0.01565888337790966,
      0.04582030326128006,
      -0.01750764809548855,
      -0.10222157090902328,
      -0.028493236750364304,
      -0.005567430518567562,
      -0.013506395742297173,
      0.038616031408309937,
      0.016441673040390015,
      -0.032014150172472,
      -0.10774951428174973,
      -0.024437522515654564,
      0.0991315022110939,
      0.058542609214782715,
      -0.018053537234663963,
      0.04794005677103996,
      0.037339068949222565,
      -0.017748845741152763,
      0.04395778849720955,
      -0.017566798254847527,
      0.026578912511467934,
      -0.023958468809723854,
      0.016623646020889282,
      -0.11713360249996185,
      0.040455084294080734,
      0.015975678339600563,
      -0.0410829521715641,
      0.06154455617070198,
      -0.02437601424753666,
      0.06742461770772934,
      -0.0994139090180397,
      0.018657837063074112,
      -0.02054191194474697,
      0.04179598391056061,
      0.028089312836527824,
      -0.02332029864192009,
      -0.014960276894271374,
      0.029958482831716537,
      -0.0358704999089241,
      0.009470249526202679,
      -0.05998866260051727,
      0.025934865698218346,
      0.0331486277282238,
      -0.004467438440769911,
      0.06881226599216461,
      0.028815286234021187,
      -0.05695466324687004,
      0.03566008433699608,
      -0.060476094484329224,
      -0.043262187391519547,
      -0.05013379082083702,
      0.03915950283408165,
      0.08745342493057251,
      -0.017885100096464157,
      0.0001978754298761487,
      -0.10680545121431351,
      0.10995043069124222,
      0.039300594478845596,
      -0.07099848240613937,
      0.0006213274318724871,
      0.09879401326179504,
      -0.06879660487174988,
      -0.004740068223327398,
      0.020810404792428017,
      0.043937720358371735,
      9.464206959819421e-05,
      0.0029690840747207403,
      -0.01958392933011055,
      0.034921109676361084,
      0.030008915811777115,
      0.00011019918019883335,
      0.05320986732840538,
      0.001858978415839374,
      -0.020156363025307655,
      0.04896086826920509,
      0.04932180047035217,
      -0.025115912780165672,
      -0.021470969542860985,
      0.03969589248299599,
      -0.0380256213247776,
      0.004316424485296011,
      -0.1204313188791275,
      -0.05011488497257233,
      0.03135868161916733,
      -0.0029506327118724585,
      0.035473648458719254,
      0.035303451120853424,
      0.029940679669380188,
      0.051129117608070374,
      0.08445160835981369,
      0.04510283097624779,
      0.05454987287521362,
      -0.02516651153564453,
      0.047972775995731354,
      -0.06105394661426544,
      0.059689536690711975,
      -0.019882209599018097,
      0.09017563611268997,
      0.015236656181514263,
      -0.008301813155412674,
      -0.039789821952581406,
      -0.058658018708229065,
      0.0016533430898562074,
      0.08651885390281677,
      0.012634155340492725,
      0.032016415148973465,
      -0.0618707574903965,
      -0.01229451596736908,
      -0.05100478231906891,
      -0.008054908365011215,
      -0.07702985405921936,
      -0.03400774672627449,
      0.01640430837869644,
      0.008653838187456131,
      0.030530590564012527,
      -0.047403059899806976,
      0.03472097963094711,
      -0.010926173999905586,
      0.01590850204229355,
      -0.013476206921041012,
      0.007499620318412781,
      -0.006315143313258886,
      0.058239296078681946,
      0.05117296800017357,
      -0.039771173149347305,
      0.008363625034689903,
      -0.030097831040620804,
      -0.012362189590930939,
      0.0545182004570961,
      -0.02282065339386463,
      0.014767564833164215,
      -0.003242728766053915,
      -0.04159741476178169,
      -0.0799131914973259,
      -0.018206384032964706,
      -0.08312136679887772,
      -0.031987469643354416,
      0.05883878096938133,
      0.01576763391494751,
      -0.10292989760637283,
      -0.02012207917869091,
      0.016087882220745087,
      -0.004155702888965607,
      -0.011330666020512581,
      0.032809242606163025,
      -0.06261790543794632,
      -0.041138626635074615,
      0.10120254009962082,
      0.03422011062502861,
      -0.05948095768690109,
      -0.026950405910611153,
      -0.020613383501768112,
      0.029055844992399216,
      -0.012517130002379417,
      -0.012598064728081226,
      -0.025820564478635788,
      0.062136679887771606,
      -0.0041016340255737305,
      -0.024115605279803276,
      -0.09441466629505157,
      -0.014530590735375881,
      0.001957515487447381,
      0.006094951182603836,
      -0.0904015600681305,
      0.008244659751653671,
      -0.06301731616258621,
      0.007171757519245148,
      -0.10787095129489899,
      0.026298804208636284,
      -0.07485254853963852,
      -0.0026915925554931164,
      -0.0310843326151371,
      -0.06154812127351761,
      0.1264168620109558,
      0.06375837326049805,
      -0.020876415073871613,
      0.02465209737420082,
      -0.035789020359516144,
      0.027612708508968353,
      0.06778661161661148,
      0.012989389710128307,
      -0.019923705607652664,
      0.012086255475878716,
      0.05055883154273033,
      0.014033842831850052,
      -0.022879257798194885,
      -0.02457601949572563,
      -0.004437286872416735,
      0.028853559866547585,
      -0.00606674887239933,
      -0.0674467384815216,
      0.017706917598843575,
      0.026637107133865356,
      0.004076102282851934,
      -0.03667719289660454,
      0.006778336130082607,
      0.024728959426283836,
      -0.009196726605296135,
      0.035508088767528534,
      0.022062361240386963,
      -0.05689337104558945,
      -0.056648898869752884,
      0.0738789513707161,
      0.023992430418729782,
      -0.015147478319704533,
      -0.05656632408499718,
      -0.05788588151335716,
      0.022467533126473427,
      -0.0609116367995739,
      -0.028620358556509018,
      -0.09262634068727493,
      0.031134432181715965,
      0.019407521933317184,
      0.0015813312493264675,
      -0.10120335966348648,
      -0.02981199137866497,
      0.03797319903969765,
      -0.0493648461997509,
      -0.07336142659187317,
      0.007056062109768391,
      -0.015487915836274624,
      -0.0573343001306057,
      -0.010566488839685917,
      -0.07998192310333252,
      -0.0424916036427021,
      -0.04014904424548149,
      -0.028481928631663322,
      -0.10623113065958023,
      -0.09300697594881058,
      -0.08294831216335297,
      0.08410106599330902,
      0.061394102871418,
      -0.09814796596765518,
      -0.034595418721437454,
      -0.028059644624590874,
      0.04716572165489197,
      0.08200088888406754,
      -0.005141529720276594,
      -0.06394040584564209,
      -0.08500193804502487,
      0.042070914059877396,
      0.02319316379725933,
      -0.008315620943903923,
      -0.01694103516638279,
      -0.021934671327471733,
      0.0115200849249959,
      -0.026645278558135033,
      0.0015308150323107839,
      0.013494019396603107,
      -0.03417590260505676,
      0.03892656788229942,
      0.014730233699083328,
      -0.049404602497816086,
      0.029972711578011513,
      -0.015474840998649597,
      0.021945739164948463,
      0.03257761895656586,
      -0.012822872959077358,
      0.09050987660884857,
      0.00022125538089312613,
      -0.06463088095188141,
      0.031129786744713783,
      0.01155824400484562,
      0.013523689471185207,
      -0.02849983051419258,
      0.01590614765882492,
      0.0070110950618982315,
      0.04453529044985771,
      0.0014708895469084382,
      -0.05325561761856079,
      0.09738244861364365,
      0.037525974214076996,
      -0.004078947473317385,
      -0.06857088953256607,
      -0.013026667758822441,
      -0.04844002425670624
    ],
    [
      0.01635560765862465,
      0.0446002371609211,
      -0.010907738469541073,
      0.00331544759683311,
      -0.0239309873431921,
      0.003524256870150566,
      0.006012588273733854,
      0.04584572836756706,
      -0.057046324014663696,
      -0.012367716990411282,
      0.008923311717808247,
      -0.006137038115411997,
      -0.001882047625258565,
      -0.030130047351121902,
      -0.03159903734922409,
      0.048884280025959015,
      -0.05953211337327957,
      0.05297790840268135,
      -0.02759231999516487,
      0.044538307934999466,
      0.030826380476355553,
      0.055664461106061935,
      0.06589163839817047,
      0.008889389224350452,
      -0.019497467204928398,
      0.028829799965023994,
      -0.055654484778642654,
      0.07518146932125092,
      0.051492590457201004,
      0.018529754132032394,
      -0.012789157219231129,
      0.03605794161558151,
      0.045829907059669495,
      -0.03882651776075363,
      -0.018157614395022392,
      0.0027628433890640736,
      0.007009113673120737,
      -0.040466535836458206,
      -0.0643879696726799,
      0.007073929067701101,
      -0.02764909528195858,
      0.06534739583730698,
      0.030724842101335526,
      0.030618341639637947,
      0.05394013971090317,
      0.052694883197546005,
      -0.009220768697559834,
      0.0034700955729931593,
      -0.08254973590373993,
      -0.018512466922402382,
      0.025204097852110863,
      0.026101524010300636,
      0.04428017884492874,
      -0.03940148279070854,
      -0.029980674386024475,
      -0.014975297264754772,
      -0.041755422949790955,
      0.004881575703620911,
      0.016540169715881348,
      0.0020477473735809326,
      -0.015328805893659592,
      -0.0013804839691147208,
      -0.07426433265209198,
      0.05837084352970123,
      0.13887359201908112,
      0.06448756903409958,
      0.029783084988594055,
      -0.03271040692925453,
      0.1072138324379921,
      0.053770944476127625,
      -0.058029696345329285,
      0.013170858845114708,
      0.02245229482650757,
      0.014197757467627525,
      0.006206927355378866,
      -0.005334183573722839,
      0.018348457291722298,
      0.0097379544749856,
      0.05598221346735954,
      0.004908976145088673,
      -0.05365360900759697,
      0.006779967807233334,
      0.0031770907808095217,
      0.02095690928399563,
      0.022399673238396645,
      -0.04391364008188248,
      -0.030018148943781853,
      -0.008324590511620045,
      0.028038399294018745,
      -0.04228602722287178,
      -0.040603771805763245,
      0.06370788812637329,
      -0.0482180193066597,
      0.038972459733486176,
      0.08521295338869095,
      0.035471003502607346,
      -0.0376347154378891,
      0.06513325124979019,
      0.04166349396109581,
      0.036129631102085114,
      0.0031376562546938658,
      0.008296620100736618,
      0.03856455162167549,
      -0.08162748068571091,
      0.07183621823787689,
      -0.04803816229104996,
      -0.05053063482046127,
      0.07730626314878464,
      -0.013315832242369652,
      0.05613779276609421,
      0.040086086839437485,
      0.017013777047395706,
      -0.059826601296663284,
      -0.09320980310440063,
      -0.017828214913606644,
      0.00594442430883646,
      -0.06515118479728699,
      0.044554322957992554,
      0.04240136593580246,
      0.0043603237718343735,
      0.025586973875761032,
      -0.048129770904779434,
      0.08094022423028946,
      0.006141908001154661,
      0.022699927911162376,
      0.052993595600128174,
      -0.00027812019106931984,
      -0.04588937759399414,
      0.030098704621195793,
      0.026270385831594467,
      -0.00331307714805007,
      0.07148835062980652,
      -0.015886178240180016,
      0.03601887822151184,
      -0.002686109161004424,
      -0.01812276430428028,
      -0.07212100923061371,
      0.018605517223477364,
      -0.029297402128577232,
      -0.03682401776313782,
      0.08544091880321503,
      -0.012414593249559402,
      -0.09501490741968155,
      -0.04075796902179718,
      -0.014441763050854206,
      0.0323285274207592,
      -0.04735520854592323,
      0.05139896646142006,
      0.09931974112987518,
      0.014782248064875603,
      -0.053706005215644836,
      -0.01204622257500887,
      -0.12326036393642426,
      -0.0056753684766590595,
      -0.013017821125686169,
      0.016500534489750862,
      -0.040381453931331635,
      -0.006976272910833359,
      -0.08414547145366669,
      0.03176422044634819,
      -0.12284019589424133,
      -0.0429786741733551,
      0.07022526860237122,
      -0.02904534712433815,
      0.01411744486540556,
      0.10017076879739761,
      -0.028829451650381088,
      0.014777714386582375,
      0.0609562061727047,
      0.06374797224998474,
      -0.07684934884309769,
      0.018245616927742958,
      0.019406838342547417,
      -0.012622612528502941,
      -0.06509975343942642,
      -0.02406279183924198,
      -0.008480047807097435,
      0.03734780102968216,
      0.03708527609705925,
      -0.13022162020206451,
      -0.04536404833197594,
      0.03881213441491127,
      0.03282301500439644,
      -0.05652323365211487,
      -0.004177749156951904,
      -0.029216283932328224,
      0.062008846551179886,
      0.013447124511003494,
      0.027322940528392792,
      -0.07785418629646301,
      0.04266902431845665,
      -0.10798598825931549,
      0.1340259462594986,
      -0.05352150648832321,
      -0.014407258480787277,
      -0.0008968107285909355,
      -0.0010601396206766367,
      0.07692224532365799,
      0.03640293702483177,
      0.012672373093664646,
      -0.0024037195835262537,
      0.06865669786930084,
      0.0170073751360178,
      0.03604494407773018,
      0.025986947119235992,
      -0.016643693670630455,
      -0.014827454462647438,
      0.02991941012442112,
      0.020208297297358513,
      -0.019965948536992073,
      0.0030290421564131975,
      -0.010727131739258766,
      0.008859599940478802,
      0.07701101154088974,
      0.06085295230150223,
      -0.01476811058819294,
      0.012478203512728214,
      -0.07399462908506393,
      0.011725420132279396,
      -0.02059958502650261,
      -0.03964357450604439,
      -0.04720817133784294,
      0.07708598673343658,
      -0.04608531668782234,
      -0.018018022179603577,
      0.04995338246226311,
      0.00540853152051568,
      0.05852789804339409,
      0.014974459074437618,
      -0.06771758198738098,
      -0.025656135752797127,
      0.031207047402858734,
      -0.002210763515904546,
      -0.018394090235233307,
      -0.06345592439174652,
      -0.0019867680966854095,
      0.029041247442364693,
      0.03363041952252388,
      -0.047052424401044846,
      0.0053117298521101475,
      -0.023210039362311363,
      -0.05581147223711014,
      0.009830261580646038,
      -0.014133336022496223,
      -0.01217566803097725,
      0.05781558156013489,
      0.01896316558122635,
      -0.04605488479137421,
      -0.08806372433900833,
      0.026342863216996193,
      0.07885139435529709,
      -0.0033442031126469374,
      -0.04346097633242607,
      0.01906306855380535,
      -0.0010844391072168946,
      0.05325964465737343,
      -0.07987552881240845,
      0.06220167875289917,
      0.06888933479785919,
      -0.00704061146825552,
      -0.06854676455259323,
      -0.02288997918367386,
      0.03665865585207939,
      0.07117462903261185,
      -0.003110448131337762,
      0.01151854544878006,
      0.10149886459112167,
      -0.003177759936079383,
      0.011740054003894329,
      0.046500224620103836,
      -0.04608738422393799,
      0.01150064542889595,
      0.059281591325998306,
      -0.0247205700725317,
      -0.0319710448384285,
      0.044466692954301834,
      0.017335830256342888,
      -0.042822740972042084,
      0.016098855063319206,
      0.029405327513813972,
      0.025136016309261322,
      0.050898242741823196,
      0.04007018357515335,
      0.0443694181740284,
      0.03826707601547241,
      -0.021371960639953613,
      -0.07557173818349838,
      -0.062033336609601974,
      -0.0765836089849472,
      0.02080717496573925,
      0.006397353019565344,
      0.008791657164692879,
      0.03369027003645897,
      -0.07810208946466446,
      -0.031358081847429276,
      -0.0030343392863869667,
      -0.02189652994275093,
      -0.020780416205525398,
      -0.023365700617432594,
      0.050501421093940735,
      0.0178314708173275,
      -0.00449978094547987,
      -0.10979985445737839,
      0.0033411539625376463,
      -0.03248516842722893,
      0.03862059861421585,
      -0.07742056250572205,
      0.009622558020055294,
      -0.005926250480115414,
      -0.0006655360921286047,
      -0.013148629106581211,
      0.03328659385442734,
      0.002677381969988346,
      -0.010059827007353306,
      0.001101983361877501,
      -0.03651116415858269,
      0.04061664640903473,
      -0.06654001027345657,
      0.06830255687236786,
      -0.07198069244623184,
      -0.018722083419561386,
      0.006364980712532997,
      -0.04628901556134224,
      -0.017358064651489258,
      -0.05443602427840233,
      0.03798243775963783,
      0.0471123568713665,
      -0.003550151363015175,
      -0.02677939273416996,
      0.04609612375497818,
      -0.01910599134862423,
      -0.020529698580503464,
      -0.003346158191561699,
      0.03378213942050934,
      0.11110436171293259,
      0.0005337000475265086,
      -0.05777318775653839,
      0.040059592574834824,
      0.01686573401093483,
      0.008834578096866608,
      -0.021377451717853546,
      0.055571749806404114,
      -0.027296097949147224,
      0.0015069725923240185,
      -0.04091715067625046,
      -0.02937169559299946,
      0.03007981926202774,
      -0.021441016346216202,
      0.05624203383922577,
      0.03527325019240379,
      0.026065591722726822,
      0.009878193959593773,
      0.07539966702461243,
      0.0006434106035158038,
      0.02092454954981804,
      -0.004561129026114941,
      0.020995883271098137,
      0.07884806394577026,
      0.012881944887340069,
      -0.07547696679830551,
      0.023087603971362114,
      0.0602249950170517,
      -0.012978934682905674,
      0.044784948229789734,
      0.027045130729675293,
      0.050653357058763504,
      -0.017879819497466087,
      0.06379035860300064,
      -0.022418754175305367,
      -0.08615343272686005,
      0.07354065030813217,
      0.031667571514844894,
      -0.015269676223397255,
      -0.0028166703414171934,
      -0.02466505952179432,
      -0.0632089227437973,
      0.029320212081074715,
      0.06914840638637543,
      -0.03765420615673065,
      -0.011616848409175873,
      0.005363801494240761,
      -0.01603241078555584,
      -0.027228068560361862,
      -0.023910196498036385,
      -0.0627012848854065,
      -0.038601089268922806,
      0.05906905233860016,
      -0.02276269905269146,
      -0.015476379543542862,
      0.06777102500200272,
      -0.06388573348522186,
      0.03986986353993416,
      -0.031938690692186356,
      -0.010821265168488026,
      -0.09317217022180557,
      0.05068643391132355,
      -0.040755853056907654,
      -0.004122636280953884,
      -0.04080861434340477,
      0.07485930621623993,
      0.03384033963084221,
      -0.01876605674624443,
      0.01244497112929821,
      0.046771030873060226,
      -0.009907192550599575,
      -9.270833106711507e-05,
      -0.007566498592495918,
      0.028502007946372032,
      0.03436851501464844,
      -0.03516296297311783,
      0.03992928937077522,
      0.052684057503938675,
      -0.020797282457351685,
      0.002191439736634493,
      -0.01320565864443779,
      -0.09577538818120956,
      -0.04412153363227844,
      -0.027675334364175797,
      -0.022502532228827477,
      -0.0025072323624044657,
      -0.10653702914714813,
      0.014090239070355892,
      -0.06117583066225052,
      0.05362255126237869,
      -0.054778341203927994,
      -0.06606984883546829,
      -0.03623003885149956,
      -0.07231111824512482,
      -0.05303291231393814,
      -0.03651297464966774,
      -0.015603959560394287,
      -0.0073333317413926125,
      0.03187907487154007,
      0.010743912309408188,
      -0.0803556814789772,
      0.025632422417402267,
      -0.05324256792664528,
      -0.016883721575140953,
      0.04174918681383133,
      -0.02598746493458748,
      -0.07843361049890518,
      -0.03902075067162514,
      -0.02399980090558529,
      0.08142545819282532,
      -0.11417192965745926,
      -0.034495461732149124,
      0.020991500467061996,
      -0.020986877381801605,
      0.00636335089802742,
      -0.021756155416369438,
      0.011485281400382519,
      0.027145931497216225,
      0.0581732802093029,
      0.022818200290203094,
      0.012000172398984432,
      0.012897556647658348,
      -0.019212085753679276,
      -0.04285465180873871,
      -0.061286672949790955,
      -0.0017983575817197561,
      -0.01795465685427189,
      -0.004059016704559326,
      0.03786754608154297,
      0.02324230968952179,
      -0.04353908449411392,
      0.04605832323431969,
      0.011254940181970596,
      -0.013332496397197247,
      -0.041778646409511566,
      0.005519455298781395,
      -0.07025142014026642,
      -0.012209833599627018,
      -0.040978092700242996,
      0.02933286502957344,
      -0.03865706920623779,
      -0.040808357298374176,
      -0.0027724902611225843,
      -0.07594230026006699,
      -0.0020894173067063093,
      -0.0031220815144479275,
      0.016239073127508163,
      -0.03923653066158295,
      -0.022053327411413193,
      0.06460104882717133,
      -0.03853210061788559,
      -0.004519562236964703,
      -0.003733666380867362,
      -0.04884763062000275,
      0.026945188641548157,
      -0.07719309628009796,
      0.01892206445336342,
      -0.08681855350732803,
      -0.00997912511229515,
      0.00426157983019948,
      0.04865430295467377,
      -0.07387003302574158,
      -0.0443447083234787,
      0.011358306743204594,
      0.010825635865330696,
      -0.03424176573753357,
      -0.04877501353621483,
      0.02420845255255699,
      -0.0074226632714271545
    ],
    [
      0.023416616022586823,
      0.011599518358707428,
      -0.05842490494251251,
      0.00933368131518364,
      -0.05509695038199425,
      0.010278267785906792,
      -0.030531374737620354,
      0.027966540306806564,
      -0.044516898691654205,
      0.05346222594380379,
      -0.08444919437170029,
      -0.08350342512130737,
      -0.024980375543236732,
      0.0510098971426487,
      -0.023360244929790497,
      0.009905301034450531,
      -0.006821025162935257,
      -0.06894069910049438,
      -0.03469453006982803,
      -0.003977108746767044,
      -0.09475106000900269,
      -0.018141744658350945,
      -0.055154651403427124,
      0.08065608888864517,
      -0.005446686875075102,
      0.018479667603969574,
      -0.07202976197004318,
      0.07141564041376114,
      0.005336109083145857,
      0.06281648576259613,
      -0.004479110706597567,
      -0.05707023665308952,
      -0.02133304253220558,
      0.020219383761286736,
      0.009138968773186207,
      0.12408440560102463,
      0.006904209032654762,
      -0.05974111333489418,
      0.011134352535009384,
      -0.06870231032371521,
      0.037612516433000565,
      0.02988993376493454,
      0.05244943127036095,
      0.008535780012607574,
      -0.02633586898446083,
      0.011519008316099644,
      0.005518089048564434,
      -0.0003319296520203352,
      0.008892516605556011,
      0.0024601744953542948,
      0.025176530703902245,
      -0.02983882650732994,
      -0.03679380193352699,
      -0.023447619751095772,
      -0.04013413190841675,
      -0.0017099332762882113,
      -0.04758278280496597,
      -0.07655779272317886,
      -0.11205566674470901,
      0.027936918660998344,
      0.1336248517036438,
      -0.007721231319010258,
      -0.04178973287343979,
      0.04541322961449623,
      0.09871494024991989,
      -0.017614958807826042,
      0.027652891352772713,
      -0.0005035984795540571,
      0.001769421505741775,
      0.037050675600767136,
      0.05662659928202629,
      -0.04510911926627159,
      0.020884454250335693,
      0.026112878695130348,
      0.01894521154463291,
      0.014186692424118519,
      0.05903177708387375,
      0.12322387099266052,
      -0.05909488722681999,
      -0.007144173141568899,
      -0.05661943554878235,
      -0.04999048635363579,
      0.021238716319203377,
      -0.0512390099465847,
      0.05112820118665695,
      0.05182591825723648,
      -0.10207439213991165,
      -0.01340169832110405,
      0.05059222877025604,
      0.0019360912265256047,
      -0.005980977788567543,
      -0.016752207651734352,
      -0.02112628147006035,
      -0.049806173890829086,
      -0.0004250654310453683,
      0.004059739410877228,
      0.05891752243041992,
      0.019137505441904068,
      0.032761022448539734,
      0.022120051085948944,
      -0.002497117966413498,
      -0.02602931670844555,
      -0.0258872639387846,
      -0.024428101256489754,
      -0.019602641463279724,
      -0.05626430734992027,
      0.07657508552074432,
      0.012788484804332256,
      0.02948383428156376,
      0.03405898064374924,
      0.056836314499378204,
      0.02387377806007862,
      -0.03315537050366402,
      0.01868511736392975,
      -0.013132101856172085,
      0.03440150246024132,
      0.01653985120356083,
      -0.0008817149209789932,
      0.0877818763256073,
      -0.014702558517456055,
      -0.009469480253756046,
      0.023234331980347633,
      0.03300941735506058,
      0.002483786316588521,
      0.04942992702126503,
      0.038413092494010925,
      -0.042118243873119354,
      0.07667391747236252,
      -0.05088883265852928,
      0.011916887946426868,
      0.04438774287700653,
      0.022884920239448547,
      -0.01929800771176815,
      0.02908584475517273,
      0.0018513317918404937,
      0.024073401466012,
      0.05340069532394409,
      0.05299408733844757,
      0.007030941545963287,
      -0.06270522624254227,
      0.0537152923643589,
      0.00019869065727107227,
      0.02057652920484543,
      -0.12286245077848434,
      0.06113756448030472,
      -0.0074486806988716125,
      0.07698284834623337,
      0.006155025213956833,
      0.004457954317331314,
      -0.006994258612394333,
      -0.060814954340457916,
      0.032506801187992096,
      -0.003961360082030296,
      -0.027087312191724777,
      0.06869460642337799,
      -0.02185560017824173,
      0.030849535018205643,
      -0.017504967749118805,
      0.025882147252559662,
      0.03755234181880951,
      -0.05427587032318115,
      0.023347456008195877,
      0.027267497032880783,
      0.017015665769577026,
      0.010615969076752663,
      0.04234393313527107,
      0.010763389989733696,
      -0.01676650159060955,
      0.08770278841257095,
      0.039986707270145416,
      0.0544520728290081,
      0.05450765788555145,
      -0.04061683639883995,
      -0.012870900332927704,
      0.024992605671286583,
      -0.036397144198417664,
      0.042250242084264755,
      0.0008494643261656165,
      0.0733267292380333,
      -0.056863825768232346,
      0.126670241355896,
      -0.018396511673927307,
      0.013905313797295094,
      0.06005872040987015,
      -0.036377642303705215,
      -0.051567140966653824,
      0.011187594383955002,
      -0.0026471309829503298,
      -0.05892745032906532,
      -0.04723169282078743,
      -0.0605119951069355,
      -0.012052423320710659,
      0.030145809054374695,
      0.035493090748786926,
      0.023766381666064262,
      -0.006839119829237461,
      0.057105652987957,
      -0.027001405134797096,
      -0.037198495119810104,
      0.02687029168009758,
      0.014340889640152454,
      -0.01699797250330448,
      0.023431720212101936,
      -0.05523714795708656,
      0.029866011813282967,
      -0.003194379387423396,
      -0.00686053279787302,
      -0.013489412143826485,
      -0.019098304212093353,
      -0.020510610193014145,
      0.05232178047299385,
      -0.044369060546159744,
      0.12607964873313904,
      0.07520820945501328,
      0.04933301359415054,
      0.005839562974870205,
      0.01609981246292591,
      -0.008451552130281925,
      0.003901151241734624,
      0.11394822597503662,
      -0.00898806657642126,
      0.02854948677122593,
      -0.04825974255800247,
      0.011833885684609413,
      -0.0475781224668026,
      0.08701109141111374,
      0.02272080071270466,
      0.05650912970304489,
      -0.015132129192352295,
      0.009394302032887936,
      -0.000868549628648907,
      -0.0509454645216465,
      -0.016397951170802116,
      -0.09498028457164764,
      0.07190263271331787,
      -0.015010025352239609,
      0.04735278710722923,
      0.1140686497092247,
      -0.059099964797496796,
      -0.02212986722588539,
      0.030879667028784752,
      -0.011049166321754456,
      0.10087228566408157,
      0.0076931798830628395,
      -0.05792829021811485,
      -0.03685176745057106,
      0.07621389627456665,
      -0.06385455280542374,
      -0.09955649077892303,
      -0.024579420685768127,
      -0.0488581545650959,
      0.0292255450040102,
      -0.019312633201479912,
      0.018530067056417465,
      0.07454495131969452,
      -0.06518958508968353,
      -0.013454877771437168,
      0.0720609650015831,
      0.009839636273682117,
      -0.01006598025560379,
      -0.04262087121605873,
      -0.10669536888599396,
      0.014440576545894146,
      -0.010686988942325115,
      -0.05310957506299019,
      -0.00761102931573987,
      -0.004234337713569403,
      0.06442228704690933,
      -0.04766218736767769,
      -0.016576940193772316,
      -0.0824894905090332,
      0.047514136880636215,
      0.04050254449248314,
      -0.04349743202328682,
      0.01666635274887085,
      -0.026632769033312798,
      -0.007986588403582573,
      0.08813042938709259,
      0.029835399240255356,
      0.036898721009492874,
      0.062655508518219,
      0.021941980347037315,
      0.0013919426128268242,
      -0.0005144921015016735,
      0.0360502265393734,
      0.0002889756578952074,
      0.01580733433365822,
      0.0597638338804245,
      0.13682013750076294,
      -0.03501507639884949,
      0.012895586900413036,
      -0.03384699672460556,
      0.0046433801762759686,
      -0.11298669129610062,
      0.009171673096716404,
      -0.059538550674915314,
      -0.0148186469450593,
      -0.09615976363420486,
      0.03156746178865433,
      0.1530790776014328,
      0.03992810100317001,
      -0.04071011766791344,
      0.03736382722854614,
      -0.023474449291825294,
      0.0038190921768546104,
      0.00447074742987752,
      -0.04125029966235161,
      0.02085966430604458,
      -0.010535165667533875,
      0.036479707807302475,
      0.007798521313816309,
      0.026925276964902878,
      -0.041369419544935226,
      -0.005808159708976746,
      -0.06392300128936768,
      -0.0369260273873806,
      0.05783473700284958,
      -0.024227431043982506,
      0.03156417980790138,
      -0.06944569200277328,
      0.0572957843542099,
      0.0042433361522853374,
      -0.08780382573604584,
      0.06579363346099854,
      -0.047442492097616196,
      0.006050401832908392,
      0.06630220264196396,
      -0.0706322193145752,
      -0.039207037538290024,
      -0.004031653981655836,
      -0.06627900153398514,
      0.048883210867643356,
      0.004139794036746025,
      0.006388674024492502,
      0.03917766734957695,
      -0.025274671614170074,
      0.023964986205101013,
      -0.03731287643313408,
      0.00838321540504694,
      -0.029271738603711128,
      0.007967675104737282,
      0.029018139466643333,
      -0.05133618786931038,
      -0.062322065234184265,
      -0.002744256518781185,
      0.05106539651751518,
      0.05339781194925308,
      -0.007252699229866266,
      -0.02045007236301899,
      0.05707903578877449,
      0.07461106777191162,
      0.026632830500602722,
      -0.044067781418561935,
      -0.026194889098405838,
      0.017310261726379395,
      -0.0532815158367157,
      0.0699031874537468,
      -0.03364698961377144,
      -0.045518215745687485,
      -0.051682546734809875,
      -0.05217762291431427,
      0.02461078204214573,
      -0.052042581140995026,
      0.01953773945569992,
      0.07152093201875687,
      0.040591899305582047,
      0.05711446702480316,
      -0.05956960842013359,
      -0.008338917046785355,
      0.008595149032771587,
      0.003394311759620905,
      -0.010703910142183304,
      0.0047021545469760895,
      0.03521522134542465,
      0.008426293730735779,
      -0.011793705634772778,
      0.011545873247087002,
      -0.05177747458219528,
      -0.08587928861379623,
      -0.01529757771641016,
      0.019201062619686127,
      0.049831822514534,
      -0.0658160001039505,
      0.02094792015850544,
      -0.05274103954434395,
      0.06325630098581314,
      0.12837226688861847,
      -0.04784503951668739,
      -0.03456200286746025,
      0.09792470932006836,
      0.00025687372544780374,
      -0.04185802862048149,
      -0.01473211869597435,
      -0.056168027222156525,
      -0.02137272246181965,
      0.007236802019178867,
      0.0308120995759964,
      0.020228059962391853,
      0.03288445994257927,
      0.017377426847815514,
      0.020679302513599396,
      -0.005569972097873688,
      -0.0055407690815627575,
      0.09605927765369415,
      -0.006229959428310394,
      -0.028339363634586334,
      -0.052439820021390915,
      -0.021260295063257217,
      0.04885849356651306,
      -0.04971016198396683,
      0.06509757786989212,
      -0.008982053026556969,
      0.034643903374671936,
      0.08200322836637497,
      -0.03637878969311714,
      -0.04925374314188957,
      0.02162775956094265,
      -0.05891888216137886,
      0.09796898066997528,
      0.032124973833560944,
      -0.07103648781776428,
      0.04782818257808685,
      -0.03343415632843971,
      0.006817939225584269,
      0.06940936297178268,
      -0.03145935386419296,
      -0.04704418033361435,
      -0.05869564414024353,
      0.03735726699233055,
      -0.008895928971469402,
      0.005412894766777754,
      0.023867759853601456,
      0.03551017493009567,
      -0.003826211905106902,
      0.11664702743291855,
      0.045201078057289124,
      0.07364635914564133,
      -0.08035119622945786,
      -0.03227519243955612,
      0.013035777024924755,
      0.06809748709201813,
      -0.055234964936971664,
      -0.10095664858818054,
      0.02696101740002632,
      -0.07167769968509674,
      0.005313552916049957,
      0.07255660742521286,
      0.01625097543001175,
      -0.055994778871536255,
      -0.025347214192152023,
      -0.03232302516698837,
      0.021710457280278206,
      0.0153533685952425,
      0.051955945789813995,
      -0.05022139474749565,
      -0.07633768022060394,
      0.013605883345007896,
      0.05204938352108002,
      0.07418432086706161,
      -0.018922753632068634,
      -0.012898875400424004,
      0.030279386788606644,
      -0.037091076374053955,
      0.013728583231568336,
      -0.08541838824748993,
      -0.08226699382066727,
      0.05214071646332741,
      -0.023470070213079453,
      -0.050299979746341705,
      0.058160994201898575,
      0.06878074258565903,
      -0.023398982360959053,
      -0.016523391008377075,
      0.004891049582511187,
      0.03493202477693558,
      0.09250796586275101,
      0.03656624257564545,
      -0.03093225136399269,
      -0.04060082510113716,
      0.006744758691638708,
      0.03645440563559532,
      -0.11527453362941742,
      0.07454680651426315,
      0.03794928267598152,
      0.008863676339387894,
      -0.07680102437734604,
      -0.024363430216908455,
      0.06315876543521881,
      -0.029814019799232483,
      0.012996403500437737,
      -0.0031355656683444977,
      0.0812862440943718,
      0.050030265003442764,
      -0.036020319908857346,
      0.08620330691337585,
      -0.10764724016189575,
      0.007681786548346281,
      -0.06594890356063843,
      -0.02971608191728592,
      0.006234101951122284,
      0.018409300595521927,
      0.06748180836439133,
      0.00023244878684636205
    ],
    [
      0.04238485172390938,
      0.002381007419899106,
      -0.04861479252576828,
      0.07201424986124039,
      0.02633582055568695,
      0.012392556294798851,
      0.01295914500951767,
      -0.014583772979676723,
      0.042278457432985306,
      0.037560537457466125,
      -0.006466463208198547,
      0.022749556228518486,
      -0.05304703861474991,
      0.03150702267885208,
      0.033567652106285095,
      0.031131844967603683,
      0.009081765078008175,
      0.0428079217672348,
      -0.005099766422063112,
      0.029847174882888794,
      0.026014739647507668,
      -0.019500013440847397,
      -0.051230717450380325,
      0.06911052018404007,
      -0.026115424931049347,
      -0.06545057892799377,
      -0.06732464581727982,
      0.07274248450994492,
      -0.04082264006137848,
      -0.03935934603214264,
      -0.07741312682628632,
      0.07526576519012451,
      0.027133841067552567,
      0.07206229120492935,
      -0.007784539833664894,
      0.0955105572938919,
      0.04222428798675537,
      -0.10644098371267319,
      -0.01402396522462368,
      0.006517760921269655,
      -0.0001429609110346064,
      0.05548246577382088,
      -0.017366845160722733,
      -0.07942692935466766,
      -0.007528833579272032,
      -0.010982475243508816,
      -0.014474369585514069,
      -0.029631761834025383,
      0.12224159389734268,
      -0.00045764961396344006,
      0.07694185525178909,
      -0.05268106609582901,
      0.03339030593633652,
      0.019295090809464455,
      0.021709373220801353,
      0.01733889989554882,
      0.025139112025499344,
      -0.01431495975703001,
      0.02332337386906147,
      -0.023687951266765594,
      -0.0016737669939175248,
      -0.04525997117161751,
      0.005434663500636816,
      -0.036078885197639465,
      0.009887932799756527,
      0.02167908102273941,
      -0.0037740839179605246,
      -0.00990618858486414,
      -0.01858999766409397,
      0.006108345463871956,
      -0.0946023017168045,
      -0.04851740598678589,
      0.0407777801156044,
      0.0383010171353817,
      -0.006073341704905033,
      -0.027054669335484505,
      -0.0006027664639987051,
      0.0431666262447834,
      -0.05694148316979408,
      -0.03787148371338844,
      -0.04120194911956787,
      -0.033415671437978745,
      0.012542540207505226,
      0.04455037787556648,
      0.045176468789577484,
      -0.0032865814864635468,
      -0.03409041836857796,
      -0.04932313412427902,
      -0.012785801663994789,
      -0.047807492315769196,
      -0.0026176453102380037,
      -1.6998101273202337e-05,
      -0.03722810745239258,
      -0.006140451412647963,
      0.013918424025177956,
      0.06838946044445038,
      0.04185860976576805,
      0.004745629150420427,
      -0.03220289573073387,
      -0.029884714633226395,
      -0.0014930894831195474,
      -0.04084811732172966,
      0.02777276374399662,
      -0.009613982401788235,
      0.03694355487823486,
      -0.040570348501205444,
      -0.01706366240978241,
      -0.10378090292215347,
      -0.019988030195236206,
      -0.09436237066984177,
      0.006422319915145636,
      -0.05823161453008652,
      -0.022529831156134605,
      0.028830500319600105,
      0.04029172658920288,
      0.036408551037311554,
      -0.015739111229777336,
      0.024376126006245613,
      0.03492255508899689,
      -0.021042050793766975,
      0.0016692864010110497,
      0.016349783167243004,
      -0.07734450697898865,
      0.004246878903359175,
      0.03741103783249855,
      -0.028545308858156204,
      -0.03985995799303055,
      0.013506541959941387,
      0.006458003539592028,
      -0.020887235179543495,
      -0.007343518082052469,
      -0.005545643623918295,
      0.09268438071012497,
      0.005508256144821644,
      -0.05744698643684387,
      -0.02851852774620056,
      0.018375538289546967,
      0.04305468872189522,
      0.028703847900032997,
      -0.0664355456829071,
      -0.04940280318260193,
      -0.03392321243882179,
      -0.03398337960243225,
      0.08873455226421356,
      -0.05183085426688194,
      0.04062391817569733,
      0.019537631422281265,
      -0.004324164241552353,
      0.07941385358572006,
      0.024691421538591385,
      0.06602411717176437,
      -0.02796892076730728,
      0.05588368698954582,
      0.06494742631912231,
      0.026112305000424385,
      -0.012708853930234909,
      -0.010799271054565907,
      0.08242521435022354,
      -0.021726857870817184,
      -0.02147807739675045,
      -0.0037480422761291265,
      -0.061405446380376816,
      -0.009045395068824291,
      -0.013832208700478077,
      0.030381640419363976,
      -0.048895832151174545,
      -0.048737335950136185,
      -0.03314537927508354,
      -0.02750270441174507,
      0.04895271733403206,
      0.047394927591085434,
      0.061782918870449066,
      -0.02883126586675644,
      0.004696259740740061,
      -0.0308268740773201,
      -0.008769277483224869,
      -0.1148950457572937,
      0.10744193941354752,
      -0.02412867173552513,
      0.023295443505048752,
      0.04147475212812424,
      0.0506579652428627,
      0.047651708126068115,
      0.09558452665805817,
      -0.032433897256851196,
      0.002252756617963314,
      0.0005311329732649028,
      -0.022749949246644974,
      -0.057977914810180664,
      -0.10159692913293839,
      0.042882006615400314,
      -0.009330090135335922,
      -0.011201675049960613,
      -0.03650879114866257,
      0.02272469736635685,
      0.020097365602850914,
      -0.051828958094120026,
      -0.003613910870626569,
      -0.0173566285520792,
      0.016551412642002106,
      -0.058981094509363174,
      -0.004440727643668652,
      0.050670478492975235,
      -0.04557475447654724,
      0.04135342687368393,
      -0.02432607114315033,
      0.04503297433257103,
      -0.019747579470276833,
      -0.053145647048950195,
      -0.03581702336668968,
      0.1265404224395752,
      0.023540416732430458,
      0.06438560783863068,
      0.004803870804607868,
      -0.03185798227787018,
      0.012521729804575443,
      0.0422305166721344,
      -0.03967916965484619,
      0.013859293423593044,
      -0.013832589611411095,
      -0.0501435324549675,
      -0.03285156562924385,
      -0.004041976761072874,
      0.04369540512561798,
      0.0590304359793663,
      0.009789565578103065,
      -0.03552867844700813,
      0.05600394681096077,
      -0.027647776529192924,
      0.01285536028444767,
      -0.022460592910647392,
      0.0039916867390275,
      -0.0245344378054142,
      -0.04046244919300079,
      -0.1549752950668335,
      0.03699979931116104,
      -0.0002965991443488747,
      -0.03811769559979439,
      -0.030192479491233826,
      -0.04616842418909073,
      -0.036615170538425446,
      0.04791957885026932,
      0.042045075446367264,
      -0.013989023864269257,
      0.05310529097914696,
      0.0052393157966434956,
      0.03373352438211441,
      0.014843853190541267,
      -0.020034946501255035,
      0.0743497833609581,
      -0.039539095014333725,
      -0.061161089688539505,
      0.02377951331436634,
      0.014368023723363876,
      -0.017021680250763893,
      -0.0136388735845685,
      -0.01823554001748562,
      -0.04752979055047035,
      -0.07758614420890808,
      -0.05283654108643532,
      -0.001248599961400032,
      -0.026601046323776245,
      0.0347258485853672,
      -0.022478943690657616,
      0.020430879667401314,
      0.004619889426976442,
      -0.02487533912062645,
      -0.05370001494884491,
      -0.013153910636901855,
      -0.01779215596616268,
      0.019658274948596954,
      0.042961157858371735,
      0.056163039058446884,
      -0.06361005455255508,
      -0.11415057629346848,
      0.04963376373052597,
      0.0342133529484272,
      0.0024480961728841066,
      -0.035271055996418,
      -0.051441553980112076,
      0.027942752465605736,
      0.005053805187344551,
      0.048938240855932236,
      0.015684720128774643,
      -0.09835129976272583,
      0.058956630527973175,
      0.028540082275867462,
      0.04043836519122124,
      -0.07044975459575653,
      0.06960811465978622,
      0.01689208298921585,
      -0.021910956129431725,
      -0.01617487147450447,
      0.003710708115249872,
      0.13256984949111938,
      0.0005124572198837996,
      -0.01168042328208685,
      0.019764157012104988,
      -0.008866718038916588,
      0.047825027257204056,
      -0.04141818359494209,
      0.034739620983600616,
      -0.01975451596081257,
      0.012212562374770641,
      -0.004499560687690973,
      0.08021269738674164,
      -0.015284362249076366,
      -0.053319212049245834,
      0.0639021173119545,
      -0.004900793079286814,
      -0.05877355858683586,
      -0.005748509429395199,
      -0.06364449113607407,
      -0.052922479808330536,
      0.00853671319782734,
      -0.044554855674505234,
      0.07361176609992981,
      0.08525823056697845,
      0.058372508734464645,
      0.0467384047806263,
      0.019771801307797432,
      -0.03290943056344986,
      -0.035387132316827774,
      0.008488989435136318,
      0.004323233384639025,
      0.051967084407806396,
      -0.07310314476490021,
      0.03600041940808296,
      0.02419419027864933,
      -0.01855429634451866,
      0.02460787445306778,
      0.059134092181921005,
      -0.00993284396827221,
      0.03916466608643532,
      0.04006494581699371,
      0.02098843641579151,
      -0.10909832268953323,
      -0.02862389199435711,
      0.006585190538316965,
      -0.02316652424633503,
      0.05806064233183861,
      0.034396473318338394,
      0.04533778503537178,
      -0.04292336478829384,
      0.04099678993225098,
      -0.024309827014803886,
      0.03651536628603935,
      -0.02293260209262371,
      0.012991879135370255,
      -0.03761160373687744,
      -0.015136655420064926,
      0.004029765259474516,
      0.022197693586349487,
      0.008800794370472431,
      -0.0021032369695603848,
      0.006046538706868887,
      -0.039065536111593246,
      0.05839327722787857,
      0.005950446240603924,
      0.040266647934913635,
      -0.0004865524242632091,
      -0.010105407796800137,
      0.029258878901600838,
      0.03912881389260292,
      -0.0719391331076622,
      0.08753224462270737,
      -0.08421575278043747,
      -0.08008907735347748,
      -0.07367759197950363,
      -0.08167199045419693,
      0.010031753219664097,
      0.028021398931741714,
      -0.09889239817857742,
      0.022122323513031006,
      0.07802243530750275,
      0.027422737330198288,
      0.02191704697906971,
      -0.0329761728644371,
      0.03537597507238388,
      0.015135652385652065,
      0.019834881648421288,
      0.021290838718414307,
      0.0013829999370500445,
      -0.03684230521321297,
      -0.005763604771345854,
      -0.04361113905906677,
      0.0465715155005455,
      -0.017278000712394714,
      -0.05687078833580017,
      0.047072671353816986,
      -0.03583212196826935,
      0.029109036549925804,
      -0.011613491922616959,
      0.009259376674890518,
      -0.13343729078769684,
      -0.0241928081959486,
      -0.05695201829075813,
      -0.020316092297434807,
      0.051932111382484436,
      -0.055695489048957825,
      0.03931836783885956,
      -0.028202593326568604,
      0.06970200687646866,
      0.0372648686170578,
      -0.02591891586780548,
      0.03405635058879852,
      -0.016291797161102295,
      -0.0038912445306777954,
      0.0592394694685936,
      0.05190190300345421,
      0.10463930666446686,
      -0.04650060832500458,
      -0.001702581997960806,
      0.049398016184568405,
      -0.014325800351798534,
      0.0345802940428257,
      0.02655886486172676,
      0.0668545588850975,
      -0.031442731618881226,
      -0.07993810623884201,
      -0.038349296897649765,
      0.012160373851656914,
      0.0067746699787676334,
      0.018402881920337677,
      0.01453633327037096,
      -0.020228609442710876,
      -0.01586318016052246,
      -0.03549153357744217,
      0.02154824323952198,
      -0.0548073872923851,
      0.04523187503218651,
      -0.0478246808052063,
      -0.10977407544851303,
      -0.051036152988672256,
      0.09881040453910828,
      -0.05150226503610611,
      -0.010221417993307114,
      -0.08503022789955139,
      0.09486008435487747,
      0.0036996162962168455,
      0.03917194530367851,
      -0.018609512597322464,
      -0.052847083657979965,
      -0.0028222696855664253,
      0.008444046601653099,
      -0.023122865706682205,
      -0.06870754808187485,
      -0.017001215368509293,
      -0.07054972648620605,
      -0.0333368144929409,
      0.04079381749033928,
      0.06459853798151016,
      -0.012619239278137684,
      0.030770193785429,
      0.03858083859086037,
      0.031439539045095444,
      0.03974016010761261,
      0.06330791860818863,
      -0.05241481214761734,
      0.04808240383863449,
      0.00927882269024849,
      0.04843512922525406,
      -0.1401604264974594,
      -0.024374037981033325,
      -0.0613660104572773,
      -0.08434093743562698,
      0.004990510176867247,
      -0.06376338005065918,
      0.060909122228622437,
      0.005860010627657175,
      -0.0037569941487163305,
      -0.01631784625351429,
      0.023649128153920174,
      -0.09000712633132935,
      -0.002279905369505286,
      -0.044231925159692764,
      0.02721080556511879,
      -0.04449749365448952,
      -0.06756483018398285,
      -0.04296163469552994,
      0.032881561666727066,
      -0.017841307446360588,
      -0.054105885326862335,
      0.08968373388051987,
      0.01382082886993885,
      -0.07666154205799103,
      -0.022591684013605118,
      0.03367674723267555,
      0.013499484397470951,
      -0.052942465990781784,
      -0.013185398653149605,
      -0.024274569004774094,
      -0.05697013810276985,
      -0.010962577536702156,
      -0.01391744427382946,
      -0.0036077057011425495,
      -0.004441555123776197,
      -0.032692801207304,
      -0.07835232466459274,
      0.01613454706966877,
      0.06037678197026253,
      0.005923386197537184,
      0.016355060040950775
    ],
    [
      0.09268853813409805,
      0.059946123510599136,
      -0.009690860286355019,
      -0.04445083811879158,
      0.048518288880586624,
      0.0096589932218194,
      -0.0013455913867801428,
      0.06029174104332924,
      -0.0009816298261284828,
      -0.019663967192173004,
      0.010446803644299507,
      0.00846754852682352,
      0.004645575303584337,
      -0.038213543593883514,
      0.07662803679704666,
      -0.028787877410650253,
      0.01848789118230343,
      -0.016737986356019974,
      0.04682822898030281,
      -0.035251449793577194,
      0.0403161384165287,
      0.06255953758955002,
      -0.010824989527463913,
      0.07925058156251907,
      -0.031887032091617584,
      0.01985980197787285,
      0.024105535820126534,
      0.04874662309885025,
      -0.061154644936323166,
      0.013377634808421135,
      -0.07129687815904617,
      0.013632230460643768,
      -0.006062300410121679,
      -0.01690227910876274,
      0.023805217817425728,
      0.060744982212781906,
      0.029942108318209648,
      -0.007931383326649666,
      -0.0687187910079956,
      0.006966921966522932,
      0.04149094596505165,
      0.006716536357998848,
      0.07874993234872818,
      -0.00629915576428175,
      -0.0434052050113678,
      -0.03722011670470238,
      -0.063782699406147,
      -0.04160384088754654,
      0.07568322122097015,
      0.002974538365378976,
      0.020149841904640198,
      0.0010755880502983928,
      0.11806996911764145,
      -0.005689538549631834,
      -0.09413406252861023,
      -0.055164843797683716,
      0.007726048119366169,
      -0.06916835159063339,
      -0.045732107013463974,
      0.014580019749701023,
      0.004335071425884962,
      0.016340143978595734,
      0.05083109810948372,
      0.0051240697503089905,
      -0.020787939429283142,
      -0.034862250089645386,
      0.04521598666906357,
      -0.07843590527772903,
      -0.007486050482839346,
      0.02704821527004242,
      -0.03242864832282066,
      0.024014746770262718,
      0.006329377181828022,
      -0.03131098672747612,
      0.01035616360604763,
      0.0658387541770935,
      0.07563511282205582,
      -0.031024891883134842,
      -0.02048116736114025,
      -0.028194377198815346,
      0.03638656437397003,
      -0.05455279350280762,
      -0.01906055025756359,
      0.06775672733783722,
      -0.04274313151836395,
      -0.015169880352914333,
      -0.009436693973839283,
      -0.09180931001901627,
      0.01580827310681343,
      -0.02362131141126156,
      0.07671405375003815,
      0.05373997986316681,
      -0.05533064529299736,
      0.019417045637965202,
      -0.005652250722050667,
      -0.09581960737705231,
      0.0034547830000519753,
      -0.040769629180431366,
      -0.061169542372226715,
      0.04249503090977669,
      0.04194202646613121,
      -0.01903347484767437,
      -0.002751505235210061,
      0.007132875267416239,
      -0.05545980483293533,
      0.04803536832332611,
      -0.01851245015859604,
      0.009572334587574005,
      0.03221368417143822,
      0.035638146102428436,
      0.020704073831439018,
      -0.044947702437639236,
      0.018900781869888306,
      0.008397367782890797,
      0.002681291662156582,
      -0.029754793271422386,
      -0.033739130944013596,
      -0.05134434998035431,
      0.07012788206338882,
      0.009529871866106987,
      0.045918386429548264,
      -0.005025697872042656,
      0.016179624944925308,
      0.01065979152917862,
      0.020947817713022232,
      0.05053587257862091,
      0.03129930421710014,
      -0.007901201955974102,
      0.023339955136179924,
      0.03074810281395912,
      -0.08421680331230164,
      0.11618775874376297,
      0.04668319597840309,
      0.0073198857717216015,
      0.027723615989089012,
      -0.029637474566698074,
      -0.008419597521424294,
      0.05931243300437927,
      0.04243500530719757,
      0.032262496650218964,
      -0.09095915406942368,
      -0.003144175047054887,
      -0.05779465287923813,
      -0.004570437595248222,
      0.03943830728530884,
      0.036209989339113235,
      -0.02495214156806469,
      0.01113770343363285,
      0.1619747281074524,
      0.07363782078027725,
      -0.017228078097105026,
      0.02966214530169964,
      -0.01782378740608692,
      -0.016532720997929573,
      0.0689958706498146,
      -0.05398178473114967,
      0.004867255222052336,
      -0.06241075322031975,
      -0.053313959389925,
      -0.03309684246778488,
      -0.01808110997080803,
      -0.030339296907186508,
      0.021275613456964493,
      -0.051303256303071976,
      -0.022921549156308174,
      0.014015678316354752,
      -0.026648838073015213,
      -0.03879864886403084,
      -0.027845464646816254,
      -0.04474096745252609,
      0.06435323506593704,
      0.06594832986593246,
      -0.017389297485351562,
      -0.0022805873304605484,
      0.01147789042443037,
      -0.021903052926063538,
      -0.03954550251364708,
      0.04245737940073013,
      -0.021065527573227882,
      0.033402420580387115,
      -0.0871971994638443,
      -0.05365726351737976,
      0.09871988743543625,
      -0.11107712239027023,
      -0.029019378125667572,
      -0.04068303853273392,
      0.08948495984077454,
      -0.061505917459726334,
      0.003612564643844962,
      -0.03867511451244354,
      -0.09200210869312286,
      0.046655647456645966,
      0.07958202064037323,
      0.05347027629613876,
      -0.02964264154434204,
      0.07169666141271591,
      -0.015170831233263016,
      0.03561365231871605,
      0.05180110037326813,
      -0.004837885964661837,
      0.02751527912914753,
      -0.000152045045979321,
      0.010685975663363934,
      -0.01377087365835905,
      -0.02208898961544037,
      0.007471688091754913,
      -0.03330102190375328,
      -0.06990664452314377,
      -0.03618648648262024,
      0.011207851581275463,
      -0.010544095188379288,
      0.041233714669942856,
      0.09781470894813538,
      0.020594891160726547,
      0.043006014078855515,
      -0.013147631660103798,
      0.026911841705441475,
      -0.09180053323507309,
      -0.06735411286354065,
      0.026834238320589066,
      -0.10139389336109161,
      0.02908538654446602,
      -0.07678069174289703,
      -0.0026260712184011936,
      0.04889043793082237,
      0.020466074347496033,
      -0.017647521570324898,
      0.05093269422650337,
      -0.025294631719589233,
      0.007349404040724039,
      -0.029799405485391617,
      -0.04455454275012016,
      -0.03156327083706856,
      -0.026241395622491837,
      0.06735190749168396,
      -0.07920394837856293,
      -0.09012608230113983,
      -0.0560130812227726,
      0.047095369547605515,
      -0.04157256707549095,
      -0.01332041621208191,
      0.06165279820561409,
      0.034593965858221054,
      0.02508668415248394,
      0.049558721482753754,
      0.05337422713637352,
      0.026414504274725914,
      -0.01744997315108776,
      -0.028071684762835503,
      0.06714676320552826,
      0.0077784424647688866,
      0.019388532266020775,
      0.025525439530611038,
      -0.01050642505288124,
      0.03537340089678764,
      -0.007577608339488506,
      -0.04858773201704025,
      -0.014924732968211174,
      0.040638599544763565,
      -0.010795004665851593,
      0.016052478924393654,
      -0.048078279942274094,
      0.017974523827433586,
      -0.08570083975791931,
      0.033869314938783646,
      -0.0003828708140645176,
      -0.04331459850072861,
      -0.05764215812087059,
      -0.04107990860939026,
      -0.025111090391874313,
      -0.0987800806760788,
      -0.0545869879424572,
      -0.001906659104861319,
      -0.014026336371898651,
      0.008680971339344978,
      0.012815773487091064,
      -0.07388755679130554,
      0.006009274628013372,
      -0.058879293501377106,
      0.0023647828493267298,
      0.00031620837398804724,
      -0.027980346232652664,
      -0.06531978398561478,
      -0.08080950379371643,
      -0.0359070748090744,
      -0.0007090661092661321,
      0.00865752063691616,
      0.049045078456401825,
      0.0702904760837555,
      0.017700744792819023,
      0.006904245354235172,
      -0.0015319283120334148,
      0.001987386727705598,
      -0.00019315698591526598,
      -0.01697409525513649,
      -0.027873972430825233,
      0.01357034221291542,
      0.002738290000706911,
      0.00187662651296705,
      -0.003757916623726487,
      0.02456406131386757,
      -0.011222755536437035,
      0.025018831714987755,
      0.0300785843282938,
      -0.08377832919359207,
      0.014451510272920132,
      0.03436886891722679,
      -0.0342334546148777,
      -0.01390509307384491,
      0.0966460183262825,
      -0.03191157430410385,
      0.02086879499256611,
      -0.052756279706954956,
      0.10138750821352005,
      -0.015222071669995785,
      0.04081282019615173,
      -0.07254213094711304,
      -0.02841082587838173,
      -0.008573834784328938,
      -0.07009454071521759,
      -0.014247836545109749,
      0.03540036454796791,
      0.04150237515568733,
      -0.019461702555418015,
      0.05909869447350502,
      -0.01884661428630352,
      -0.05099242553114891,
      -0.07488245517015457,
      -0.03939638286828995,
      0.04223516210913658,
      0.03637523949146271,
      -0.06951220333576202,
      -0.06988289952278137,
      -0.009791380725800991,
      0.027956711128354073,
      -0.00955572072416544,
      0.027881726622581482,
      0.008064553141593933,
      0.044923752546310425,
      0.04097941145300865,
      0.0008918402018025517,
      -0.024913474917411804,
      -0.02594580315053463,
      -0.0034961870405822992,
      -0.03048558719456196,
      0.058646202087402344,
      0.09455151110887527,
      -0.07343681901693344,
      0.04575974494218826,
      0.028650199994444847,
      0.02660398744046688,
      -0.06177305802702904,
      -0.008676025085151196,
      0.014682458713650703,
      0.03420666977763176,
      -0.030446676537394524,
      -0.07064145058393478,
      0.013515067286789417,
      -0.05773548781871796,
      0.043693702667951584,
      -0.044147662818431854,
      0.07322856038808823,
      0.022957997396588326,
      0.023643504828214645,
      0.004895138554275036,
      -0.06304821372032166,
      -0.030449790880084038,
      -0.03653892129659653,
      0.014332921244204044,
      0.06369724124670029,
      -0.05526411533355713,
      0.06547997146844864,
      0.09199860692024231,
      0.017129799351096153,
      -0.02811582013964653,
      0.033130038529634476,
      0.031590092927217484,
      -0.00814317911863327,
      0.02235308103263378,
      0.03941187262535095,
      0.016359945759177208,
      0.005917816888540983,
      0.038707904517650604,
      0.020607376471161842,
      0.09317934513092041,
      -0.000481658848002553,
      -0.028885912150144577,
      0.037906792014837265,
      -0.013104524463415146,
      -0.0016492801951244473,
      0.10322979092597961,
      -0.00364325731061399,
      -0.01162788737565279,
      0.05087713152170181,
      0.00920364260673523,
      -0.06184311956167221,
      0.04585393890738487,
      -0.038408517837524414,
      -0.05089445039629936,
      0.05856896936893463,
      0.047543466091156006,
      -0.0063064428977668285,
      0.09290191531181335,
      0.0019398850854486227,
      0.020772606134414673,
      -0.08468666672706604,
      0.04422113299369812,
      -0.013284370303153992,
      0.023236021399497986,
      0.04364488273859024,
      -0.004715909715741873,
      -0.022319070994853973,
      0.016961632296442986,
      0.04702118784189224,
      -0.0023610503412783146,
      -0.024030819535255432,
      0.05095478519797325,
      -0.07315119355916977,
      -0.08545352518558502,
      -0.03521962836384773,
      -0.06221902742981911,
      -0.0500684529542923,
      -0.021603643894195557,
      0.04971788451075554,
      0.014815746806561947,
      -0.13138099014759064,
      -0.0036928115878254175,
      -0.009264454245567322,
      0.05559295043349266,
      -0.054918017238378525,
      -0.06707839667797089,
      0.025466838851571083,
      -0.053440775722265244,
      0.08052969723939896,
      0.08289525657892227,
      -0.00030752032762393355,
      -0.006912698969244957,
      -0.006108047906309366,
      0.03802914544939995,
      -0.07227934896945953,
      -0.008272537961602211,
      -0.04205898195505142,
      -0.05196015164256096,
      -0.0410514734685421,
      0.059757888317108154,
      -0.06321648508310318,
      -0.0360197089612484,
      -0.020159605890512466,
      0.04813574254512787,
      -0.025267623364925385,
      0.03938337042927742,
      -0.01796194724738598,
      -0.042656660079956055,
      0.009104039520025253,
      0.007695689797401428,
      0.011062237434089184,
      -0.01043420098721981,
      0.0019254782237112522,
      0.07177316397428513,
      -0.07494647800922394,
      0.019969571381807327,
      0.01647832803428173,
      -0.06150395795702934,
      0.06406491994857788,
      -0.06303583085536957,
      -0.0016332597006112337,
      0.06630074977874756,
      0.04885561019182205,
      -0.028471702709794044,
      -0.00047151686158031225,
      -0.03115956299006939,
      -0.009567550383508205,
      -0.0010035318555310369,
      0.006858430337160826,
      -0.05301400274038315,
      0.053879886865615845,
      -0.05519094318151474,
      0.06344504654407501,
      -0.005273906514048576,
      0.002983636921271682,
      -0.029334167018532753,
      -0.005306083243340254,
      0.006021367385983467,
      0.0007038185722194612,
      0.07224107533693314,
      -0.012977959588170052,
      0.03517954796552658,
      -0.03651213273406029,
      0.014981742016971111,
      -0.01546518225222826,
      0.024184400215744972,
      -0.04871928691864014,
      0.022488949820399284,
      -0.039583563804626465,
      -0.026541471481323242,
      0.017712391912937164,
      -0.04204827547073364,
      -0.02854789048433304,
      -0.007155020721256733,
      -0.03883698210120201,
      0.017705310136079788,
      -0.04740781709551811,
      0.08676785230636597
    ],
    [
      0.06634120643138885,
      0.010679690167307854,
      0.025583529844880104,
      -0.019725199788808823,
      -0.03149290755391121,
      -0.0111621655523777,
      0.009922156110405922,
      -0.027170799672603607,
      -0.05379908159375191,
      -0.04005103185772896,
      -0.0514557808637619,
      -0.0018605595687404275,
      0.011631941422820091,
      -0.011414676904678345,
      -0.03108622133731842,
      0.05882148817181587,
      0.03216922655701637,
      -0.03771474212408066,
      -0.03439006581902504,
      -0.02843460813164711,
      0.038560446351766586,
      -0.07800136506557465,
      0.006542098708450794,
      0.06574723869562149,
      -0.03166750445961952,
      0.02655627578496933,
      0.0249605905264616,
      0.030524853616952896,
      0.07335007190704346,
      -0.011885655112564564,
      -0.026508281007409096,
      -0.0256235022097826,
      -0.05234278738498688,
      0.055546727031469345,
      -0.0239565409719944,
      -0.050574835389852524,
      -0.014079893007874489,
      -0.039779648184776306,
      0.03463909775018692,
      0.011785474605858326,
      -0.052999019622802734,
      -0.019297365099191666,
      -0.03181379288434982,
      -0.01547303143888712,
      -0.013010193593800068,
      -0.1083962544798851,
      0.007257083896547556,
      -0.024267738685011864,
      0.060313720256090164,
      -0.042949870228767395,
      0.04081891104578972,
      -0.041544344276189804,
      -0.008487183600664139,
      -0.02285061776638031,
      0.050239019095897675,
      0.0468720979988575,
      -0.003377579152584076,
      0.03938428685069084,
      0.007614342495799065,
      -0.03179478272795677,
      -0.05167936161160469,
      -0.03002188913524151,
      0.058068759739398956,
      0.009381046518683434,
      -0.019436320289969444,
      0.014897464774549007,
      -0.008898631669580936,
      -0.02322555147111416,
      0.021555202081799507,
      0.0007132549653761089,
      -0.019238345324993134,
      -0.040581777691841125,
      -0.018194416537880898,
      0.02334936335682869,
      -0.11538873612880707,
      -0.06490883976221085,
      0.07125327736139297,
      0.02470448985695839,
      -0.0005427172873169184,
      -0.03641809895634651,
      0.01708725094795227,
      -0.0269404798746109,
      -0.04040716215968132,
      -0.10998599231243134,
      -0.06330890953540802,
      -0.04252205044031143,
      -0.05486255884170532,
      0.010310356505215168,
      -0.03892812505364418,
      -0.03133738413453102,
      -0.04215506836771965,
      -0.04409142956137657,
      -0.09362520277500153,
      0.06831642985343933,
      -0.010177802294492722,
      -0.04124277085065842,
      0.007999024353921413,
      0.025502661243081093,
      -0.04030752182006836,
      0.03567171096801758,
      0.003163923742249608,
      0.017899250611662865,
      -0.00045999750727787614,
      -0.018836837261915207,
      0.04865915700793266,
      0.11708487570285797,
      -0.0036278560291975737,
      0.025169741362333298,
      -0.0008667682413943112,
      -0.055278122425079346,
      -0.07336624711751938,
      -0.02536415308713913,
      -0.07157279551029205,
      -0.01913505233824253,
      -0.02333330363035202,
      0.02898198738694191,
      -0.00331865600310266,
      0.039008334279060364,
      -0.04614982381463051,
      0.07707774639129639,
      -0.025003822520375252,
      -0.0219668447971344,
      0.031002730131149292,
      0.1120162159204483,
      0.019649749621748924,
      0.07889172434806824,
      -0.024792920798063278,
      0.01100282371044159,
      0.03174275904893875,
      -0.06449735909700394,
      -0.0018605764489620924,
      -0.04220298305153847,
      0.058825403451919556,
      -0.03937382251024246,
      0.032025840133428574,
      -0.0827326849102974,
      0.013108531013131142,
      -0.030928974971175194,
      0.015535431914031506,
      -0.05861203745007515,
      0.015735860913991928,
      -0.051642388105392456,
      -0.03237191215157509,
      -0.014379767701029778,
      0.0004151023458689451,
      -0.07511968910694122,
      0.03498094901442528,
      0.020502151921391487,
      0.027083922177553177,
      0.1616673469543457,
      -0.08751682937145233,
      0.04422280937433243,
      0.009248665533959866,
      -0.01774504780769348,
      0.02557695284485817,
      -0.0029193356167525053,
      -0.02153264731168747,
      -0.019930073991417885,
      -0.04715290293097496,
      -0.03378414735198021,
      0.010325848124921322,
      -0.026745039969682693,
      -0.028257446363568306,
      0.0017536574741825461,
      0.02671291120350361,
      0.024119911715388298,
      0.03148600086569786,
      0.05667724832892418,
      0.017959291115403175,
      0.007604466751217842,
      -0.009258802980184555,
      -0.02764853462576866,
      0.0506548210978508,
      0.031145736575126648,
      -0.057032261043787,
      -0.009313342161476612,
      -0.011173577979207039,
      -0.04262564703822136,
      -0.01088340301066637,
      0.002940496662631631,
      -0.04892265424132347,
      0.002821875037625432,
      0.06770768016576767,
      -0.0014255306450650096,
      -0.008207748644053936,
      0.007168900221586227,
      0.04640534147620201,
      0.0004586146678775549,
      -0.002188642043620348,
      -0.01598024182021618,
      -0.02473320998251438,
      -0.02896473929286003,
      0.04985782876610756,
      -0.06839161366224289,
      -0.0108698271214962,
      -0.06521441787481308,
      0.004045429173856974,
      -0.0319339744746685,
      -0.001749923569150269,
      -0.101856529712677,
      0.06829860806465149,
      -0.007031568791717291,
      0.029267584905028343,
      -0.0683591365814209,
      0.029080497100949287,
      -0.02117774449288845,
      0.006254215724766254,
      -0.04317207261919975,
      -0.050383299589157104,
      0.018199604004621506,
      -0.01808064989745617,
      0.011100121773779392,
      -0.0016284305602312088,
      0.01409954484552145,
      0.030458761379122734,
      -0.02893805503845215,
      0.004369712900370359,
      -0.015065322630107403,
      0.010747811757028103,
      0.03908982127904892,
      -0.015517066232860088,
      0.04086728021502495,
      -0.01889120601117611,
      0.03770116716623306,
      0.05971473455429077,
      -0.02997015044093132,
      0.023917660117149353,
      0.01734994165599346,
      0.01936575397849083,
      -0.0570150762796402,
      -0.03023657016456127,
      0.018428461626172066,
      -0.031108343973755836,
      -0.021847616881132126,
      -0.027487849816679955,
      -0.03007800132036209,
      -0.01024947315454483,
      0.028864892199635506,
      -0.0028329561464488506,
      -0.019712869077920914,
      0.04399153217673302,
      0.032166723161935806,
      -0.019838154315948486,
      0.005063964519649744,
      0.02308560535311699,
      -0.01397966779768467,
      0.029946453869342804,
      -0.04782956466078758,
      0.01696150004863739,
      0.03668339177966118,
      -0.08458538353443146,
      0.013353455811738968,
      -0.02446313016116619,
      -0.0699988529086113,
      -0.004939822014421225,
      -0.006314252503216267,
      0.0254958588629961,
      0.006520960479974747,
      0.0587691031396389,
      0.02713535912334919,
      -0.04189487174153328,
      -0.012377307750284672,
      -0.00418062461540103,
      0.02963637188076973,
      0.028087865561246872,
      0.08686476200819016,
      -0.061386656016111374,
      -0.0319991409778595,
      0.021857917308807373,
      0.05540422350168228,
      0.028051156550645828,
      0.035025548189878464,
      0.08896048367023468,
      -0.0073097385466098785,
      -0.09233027696609497,
      -0.01106890756636858,
      -0.07751135528087616,
      0.09937315434217453,
      -0.04490697756409645,
      -0.003924604970961809,
      0.08943244069814682,
      0.001744961948134005,
      -0.03691296651959419,
      -0.030979098752141,
      0.05386999994516373,
      0.13888730108737946,
      -0.021484484896063805,
      0.07063276320695877,
      0.0013094122987240553,
      -0.007090254221111536,
      0.008708784356713295,
      -0.061292197555303574,
      0.07318533957004547,
      0.00527183385565877,
      0.015591749921441078,
      -0.0352945551276207,
      -0.029809314757585526,
      0.043900370597839355,
      0.0009007872431538999,
      0.016774199903011322,
      -0.06014617159962654,
      0.01453996729105711,
      -0.018499629572033882,
      -0.0393059216439724,
      0.0542062409222126,
      0.03988617658615112,
      0.06739402562379837,
      -0.019569111987948418,
      -0.04343090206384659,
      -0.00086165004177019,
      0.036809034645557404,
      0.0398159995675087,
      0.05029865354299545,
      0.004039173014461994,
      0.06625916808843613,
      -0.030896542593836784,
      0.03133236989378929,
      0.07652553915977478,
      0.0009094900451600552,
      -0.05719030648469925,
      0.01999203860759735,
      -0.0032006907276809216,
      -0.0204361230134964,
      -0.01517031341791153,
      0.007039294578135014,
      0.006940965075045824,
      -0.04615649953484535,
      -0.020881593227386475,
      0.014930648729205132,
      0.021614622324705124,
      0.04424514248967171,
      -0.036813847720623016,
      0.0175870843231678,
      0.01482207328081131,
      0.0003415479732211679,
      -0.0020952282939106226,
      -0.03754854202270508,
      0.0777246430516243,
      -0.050634779036045074,
      -0.034609388560056686,
      -0.020248500630259514,
      -0.07483940571546555,
      -0.0074925911612808704,
      0.061550967395305634,
      0.0008614423568360507,
      0.043397776782512665,
      0.03247600421309471,
      -0.02877267450094223,
      0.05394864082336426,
      0.06742220371961594,
      0.01677330955862999,
      -0.024612873792648315,
      -0.06726180016994476,
      0.002478641690686345,
      0.03580234944820404,
      0.036356907337903976,
      -0.02422131784260273,
      0.018449261784553528,
      -0.03084344044327736,
      -0.0056093488819897175,
      -0.009158124215900898,
      0.06851162761449814,
      -0.03397001326084137,
      -0.007602140307426453,
      0.0254664309322834,
      -0.026009706780314445,
      -0.009917094372212887,
      0.025960205122828484,
      0.029081150889396667,
      0.0126890167593956,
      -0.04132985696196556,
      0.051958415657281876,
      -0.005079497583210468,
      0.015103938058018684,
      -0.023904789239168167,
      0.03088308684527874,
      -0.0015075529227033257,
      -0.09437939524650574,
      -0.03433066979050636,
      0.032902538776397705,
      -0.039002060890197754,
      0.002431834116578102,
      -0.01405715849250555,
      -0.0478055439889431,
      -0.0015761356335133314,
      0.07064229995012283,
      0.02505352534353733,
      0.03116663172841072,
      -0.04126551002264023,
      0.1278693526983261,
      0.06535965204238892,
      0.03725820407271385,
      0.015326453372836113,
      0.0466981902718544,
      0.029079239815473557,
      -0.03587720915675163,
      0.04819035902619362,
      -0.0016451713163405657,
      0.02029530517756939,
      -0.0024670667480677366,
      0.0018204257357865572,
      0.02518627792596817,
      0.05720989778637886,
      0.04246564581990242,
      -0.08494189381599426,
      -0.01896490342915058,
      -0.026844477280974388,
      -0.0002487465098965913,
      -0.042364004999399185,
      0.05733287334442139,
      0.06516542285680771,
      -0.009474368765950203,
      -0.03546490892767906,
      -0.009403129108250141,
      -0.007676228880882263,
      0.022465551272034645,
      0.02305120974779129,
      -0.13570046424865723,
      -0.10048982501029968,
      -0.006771786138415337,
      0.04088858887553215,
      0.036475688219070435,
      0.02851679176092148,
      0.03963419049978256,
      0.026852775365114212,
      -0.03424234688282013,
      -0.011494243517518044,
      -0.0440821647644043,
      0.051490385085344315,
      0.04775272682309151,
      0.002051923656836152,
      0.04653925448656082,
      0.04946618154644966,
      0.03284401074051857,
      -0.053311243653297424,
      -0.054161738604307175,
      0.05673975870013237,
      0.01095560472458601,
      0.014021554961800575,
      -0.010942991822957993,
      -0.0432918481528759,
      0.04655652865767479,
      0.021732579916715622,
      -0.02972322516143322,
      -0.0036053459625691175,
      0.03288686275482178,
      -0.08282210677862167,
      0.027446888387203217,
      -0.04249158129096031,
      0.017025157809257507,
      0.010651206597685814,
      0.0869114026427269,
      -0.022247202694416046,
      0.03947989642620087,
      0.030001716688275337,
      0.09095169603824615,
      0.0802399292588234,
      -0.09399382770061493,
      0.026245150715112686,
      -0.039650313556194305,
      0.04090940207242966,
      0.04583181440830231,
      0.0003941382165066898,
      -0.061318349093198776,
      0.030547166243195534,
      0.04826228320598602,
      0.01782987453043461,
      0.054456714540719986,
      0.03363677114248276,
      0.025361230596899986,
      -0.017595238983631134,
      -0.08198913931846619,
      0.06733650714159012,
      -0.030712531879544258,
      -0.027576660737395287,
      -0.043699875473976135,
      -0.027581095695495605,
      -0.028204295784235,
      -0.10015100240707397,
      0.03758249059319496,
      0.011600636877119541,
      -0.038659993559122086,
      0.01572411321103573,
      -0.03792504593729973,
      -0.00403546541929245,
      -0.07234925031661987,
      -0.012908581644296646,
      0.05980437994003296,
      0.020986482501029968,
      -4.462015567696653e-05,
      0.04428042471408844,
      0.04447423294186592,
      0.011018538847565651,
      0.023380514234304428,
      -0.042974792420864105,
      -0.0014206258347257972,
      -0.008375665172934532,
      -0.019767312332987785,
      0.05613896995782852,
      -0.045605309307575226,
      0.017470508813858032,
      -0.03398347645998001,
      -0.05704319104552269
    ],
    [
      -0.05558834969997406,
      0.05719001963734627,
      0.022165754809975624,
      0.004595272243022919,
      -0.037899404764175415,
      -0.050413861870765686,
      0.002046933164820075,
      -0.003989274613559246,
      -0.04921897500753403,
      -0.026868611574172974,
      0.004268159158527851,
      -0.017302751541137695,
      -0.06753575801849365,
      0.047607146203517914,
      0.020664840936660767,
      0.015453184954822063,
      0.0015864920569583774,
      0.06337214261293411,
      -0.039944227784872055,
      -0.009860429912805557,
      0.028811553493142128,
      -0.016530590131878853,
      -0.05396533012390137,
      0.024303171783685684,
      -0.04421839118003845,
      -0.044858936220407486,
      -0.0451287217438221,
      -0.03851361945271492,
      -0.050998754799366,
      0.011566344648599625,
      -0.05889143422245979,
      -0.019697202369570732,
      -0.06447112560272217,
      -0.010415710508823395,
      -0.0871037170290947,
      -0.00468710670247674,
      -0.025281183421611786,
      0.009879063814878464,
      0.009968386963009834,
      -0.006065144669264555,
      -0.07072949409484863,
      -0.013604250736534595,
      -0.014474695548415184,
      0.004244707524776459,
      -0.012544378638267517,
      -0.12241338938474655,
      0.05325402691960335,
      -0.030471734702587128,
      0.0012432015500962734,
      -0.01865900121629238,
      -0.037220969796180725,
      -0.016946539282798767,
      -0.03566109761595726,
      0.03432942181825638,
      0.08608877658843994,
      -0.046033825725317,
      -0.005426703952252865,
      0.09897804260253906,
      0.020604876801371574,
      -0.019697703421115875,
      -0.023266002535820007,
      -0.055049821734428406,
      -0.02618466690182686,
      0.04715930297970772,
      -0.07348452508449554,
      -0.007958833128213882,
      -0.05570269003510475,
      0.020986583083868027,
      -0.004444372374564409,
      -0.007239846047013998,
      -0.03685043007135391,
      0.050751693546772,
      0.03110256977379322,
      0.049511250108480453,
      -0.04831521585583687,
      0.015495557337999344,
      -0.051417864859104156,
      0.04416639357805252,
      -0.08323973417282104,
      -0.025580214336514473,
      -0.0012487677158787847,
      0.009934590198099613,
      -0.010342704132199287,
      0.039544396102428436,
      0.055039651691913605,
      -0.039800599217414856,
      -0.014041010290384293,
      -0.0923951268196106,
      -0.004301161505281925,
      -0.0099805798381567,
      0.05189398303627968,
      -0.01738787442445755,
      -0.056207939982414246,
      0.0016016256995499134,
      -0.010932812467217445,
      0.018481459468603134,
      0.029964907094836235,
      -0.037636201828718185,
      0.004262538626790047,
      0.003919594921171665,
      0.011721266433596611,
      0.016833944246172905,
      -0.03931085392832756,
      0.0117352195084095,
      -0.021359112113714218,
      0.002565649338066578,
      -0.043637264519929886,
      -0.009749083779752254,
      0.00865512527525425,
      -0.05460379272699356,
      -0.03328905999660492,
      0.02381233125925064,
      -0.013559921644628048,
      -0.045361511409282684,
      0.05424513295292854,
      0.0447404570877552,
      0.016599254682660103,
      -0.03518260642886162,
      0.025373978540301323,
      0.014834573492407799,
      -0.03420351818203926,
      -0.03815755993127823,
      -0.07679560780525208,
      0.056188613176345825,
      0.0045324875973165035,
      -0.05771670117974281,
      0.0051575000397861,
      0.006534455344080925,
      -0.04457346722483635,
      0.00946040265262127,
      0.003197267185896635,
      0.04508306086063385,
      0.07152849435806274,
      0.028786255046725273,
      0.014734366908669472,
      0.08581412583589554,
      -0.05066246539354324,
      -0.015014871023595333,
      0.023153120651841164,
      0.032698094844818115,
      0.005896685179322958,
      0.0345744714140892,
      0.02799679897725582,
      -0.01958344876766205,
      -0.035494182258844376,
      -0.04746472090482712,
      0.04928312450647354,
      -0.027024200186133385,
      0.07352953404188156,
      0.03608556091785431,
      0.027826273813843727,
      -0.06325893104076385,
      0.09466738253831863,
      0.08025181293487549,
      0.12547321617603302,
      -0.08560846000909805,
      0.06232288479804993,
      0.0330100953578949,
      0.015487834811210632,
      -0.044857535511255264,
      0.020888105034828186,
      0.06184084340929985,
      -0.026382148265838623,
      0.016291506588459015,
      -0.07982009649276733,
      -0.0764138475060463,
      0.016657860949635506,
      -0.025757858529686928,
      -0.03142233565449715,
      -0.005989840254187584,
      0.00026954960776492953,
      0.04469050467014313,
      -0.023332921788096428,
      -0.03938911482691765,
      0.10605126619338989,
      -0.01566418632864952,
      -0.07101909816265106,
      -0.0008167437044903636,
      -0.008456530049443245,
      0.004031653981655836,
      -0.02362906187772751,
      0.02021169103682041,
      -0.045418303459882736,
      -0.068116195499897,
      -0.03828094154596329,
      -0.01815877854824066,
      0.014741006307303905,
      0.0634402334690094,
      -0.022475142031908035,
      0.12242427468299866,
      0.05017872899770737,
      -0.005648667458444834,
      0.0009614034206606448,
      0.03566618636250496,
      0.024638453498482704,
      -0.051314182579517365,
      0.02677232027053833,
      -0.035999689251184464,
      -0.058940816670656204,
      0.03696225583553314,
      -0.01615726575255394,
      -0.0788063332438469,
      -0.09671233594417572,
      -0.003419197630137205,
      0.09040485322475433,
      -0.12047351896762848,
      0.056816063821315765,
      0.016359567642211914,
      0.037168581038713455,
      -0.012697705067694187,
      0.030469855293631554,
      -0.02131664752960205,
      0.015525665134191513,
      -0.07285063713788986,
      0.049992386251688004,
      0.04359247535467148,
      0.021789394319057465,
      0.021875236183404922,
      -0.04161803424358368,
      -0.022499410435557365,
      -0.09755074977874756,
      0.011223963461816311,
      -0.04137532785534859,
      -0.02590182237327099,
      -0.04454012215137482,
      0.01046306174248457,
      0.032022345811128616,
      0.03041587397456169,
      0.029992593452334404,
      -0.03925738111138344,
      -0.007109061349183321,
      0.03705945238471031,
      -0.11686456203460693,
      -0.02342020906507969,
      0.11346124112606049,
      0.056235022842884064,
      -0.04987603425979614,
      0.0745328888297081,
      -2.395949195488356e-05,
      0.09312568604946136,
      -0.01917436346411705,
      -0.034397322684526443,
      0.007605694234371185,
      -0.05438802018761635,
      0.027787385508418083,
      -0.021469954401254654,
      0.007562167476862669,
      -0.021376589313149452,
      -0.01658480614423752,
      0.015766214579343796,
      -0.08068583905696869,
      -0.009295864962041378,
      0.04149223119020462,
      -0.07897508144378662,
      0.05005631968379021,
      -0.0035354490391910076,
      -0.004701254423707724,
      -0.019418535754084587,
      0.02488297037780285,
      0.045873042196035385,
      -0.03633645176887512,
      -0.07643911987543106,
      -0.06822646409273148,
      -0.057362619787454605,
      0.015128363855183125,
      -0.00737424474209547,
      0.05308351293206215,
      -0.0013804604532197118,
      0.05893867090344429,
      -0.02870027720928192,
      -0.09901431947946548,
      0.004095915239304304,
      -0.0472254753112793,
      -0.031997136771678925,
      0.004591935779899359,
      -0.002149176551029086,
      0.005090555641800165,
      -0.07994606345891953,
      0.021199610084295273,
      -0.025071915239095688,
      -0.04523678123950958,
      0.1000988781452179,
      -0.053431879729032516,
      0.026975061744451523,
      -0.008824612013995647,
      -0.004909280687570572,
      0.05003921687602997,
      -0.025713643059134483,
      0.006308214273303747,
      -0.10848358273506165,
      -0.021710531786084175,
      0.0016644509742036462,
      0.037602078169584274,
      -0.03264665603637695,
      0.055964767932891846,
      0.02140931598842144,
      -0.019854295998811722,
      -0.04296455159783363,
      -0.05832128971815109,
      -0.05741790309548378,
      0.03876951336860657,
      -0.000979804084636271,
      0.01942005753517151,
      0.050645358860492706,
      -0.0039826082065701485,
      0.08918397128582001,
      0.004269218537956476,
      -0.018198752775788307,
      0.07710263878107071,
      -0.001004738500341773,
      0.06805814802646637,
      0.010288055986166,
      0.05597923323512077,
      -0.011128975078463554,
      -0.042784981429576874,
      0.07517983764410019,
      0.008836659602820873,
      -0.03418668732047081,
      0.007240176200866699,
      -0.029616452753543854,
      0.008058955892920494,
      0.033991795033216476,
      -0.0739666223526001,
      0.022182906046509743,
      -0.068311408162117,
      -0.01653013937175274,
      -0.07498158514499664,
      -0.009293107315897942,
      0.004018296487629414,
      -0.002363977488130331,
      0.011164732277393341,
      0.019609197974205017,
      -0.029029155150055885,
      0.002751479623839259,
      0.0321829579770565,
      -0.005399121902883053,
      -0.0685458704829216,
      -0.06899569928646088,
      0.008008457720279694,
      -0.0024548673536628485,
      -0.05906078219413757,
      0.0077402167953550816,
      -0.007214817684143782,
      -0.020283343270421028,
      -0.014056858606636524,
      -0.11613542586565018,
      -0.01845231093466282,
      0.04945284500718117,
      0.030902238562703133,
      -0.012117346748709679,
      0.08466310799121857,
      -0.01594444364309311,
      0.011717635206878185,
      -0.018280554562807083,
      -0.07318047434091568,
      -0.0166071355342865,
      -0.0068142530508339405,
      0.052229877561330795,
      0.019610794261097908,
      0.02290654368698597,
      -0.008715402334928513,
      0.027740715071558952,
      0.009882867336273193,
      0.028891367837786674,
      -0.017450161278247833,
      0.06386017799377441,
      -0.024701256304979324,
      -0.019911140203475952,
      -0.01246872078627348,
      0.015307756140828133,
      0.01335767563432455,
      0.04716626554727554,
      0.0012197881005704403,
      0.004223331343382597,
      0.004938439466059208,
      0.013075410388410091,
      -0.06560617685317993,
      -0.01650572195649147,
      0.0721929520368576,
      -0.016790494322776794,
      0.01691795140504837,
      -0.06376968324184418,
      -0.06324812769889832,
      0.059632763266563416,
      0.03918422386050224,
      0.005436355713754892,
      0.0007115934859029949,
      0.061117447912693024,
      0.016118817031383514,
      -0.04436838626861572,
      -0.12715749442577362,
      -0.028147228062152863,
      0.04319360852241516,
      -0.007939347065985203,
      0.012289653532207012,
      0.02989952452480793,
      0.07144711166620255,
      0.00922226533293724,
      0.00027429431793279946,
      -0.04435253515839577,
      0.0558946430683136,
      -0.015434939414262772,
      -0.010093512944877148,
      -0.001019090530462563,
      0.06575356423854828,
      0.08193828165531158,
      0.03377077728509903,
      0.015495260246098042,
      0.0020750092808157206,
      -9.971957479137927e-05,
      0.030188405886292458,
      -0.04575146362185478,
      0.01301314402371645,
      0.004065856337547302,
      0.004878194537013769,
      -0.04625215753912926,
      0.003699006512761116,
      -0.013362381607294083,
      0.12204258143901825,
      0.008375747129321098,
      -0.0241672545671463,
      -0.1318293958902359,
      0.005686865653842688,
      -0.10560178756713867,
      -0.02253001183271408,
      0.018119661137461662,
      -0.019560011103749275,
      -0.004176012240350246,
      -0.03140852227807045,
      0.000560288259293884,
      -0.017892872914671898,
      0.0992448478937149,
      -0.005850494839251041,
      0.07332029938697815,
      -0.03515002876520157,
      0.022807057946920395,
      0.03985711932182312,
      -0.052466318011283875,
      -0.05214284360408783,
      0.003026783000677824,
      -0.06390354782342911,
      0.003313513472676277,
      -0.0009363054996356368,
      -0.02874288707971573,
      0.015532951802015305,
      0.048503678292036057,
      0.028835991397500038,
      -0.036038126796483994,
      -0.07161886990070343,
      0.016229897737503052,
      -0.03195055201649666,
      -0.016483182087540627,
      0.015616009011864662,
      0.08526822924613953,
      0.016347132623195648,
      0.09078802168369293,
      0.0645044669508934,
      0.03691386431455612,
      0.00959809124469757,
      -0.009600753895938396,
      -0.0653582513332367,
      0.0764717385172844,
      -0.022346755489706993,
      0.05092939734458923,
      0.013668260537087917,
      0.07174928486347198,
      0.0396677665412426,
      -0.028841957449913025,
      -0.04848954454064369,
      -0.06024628132581711,
      -0.040084294974803925,
      -0.08324255049228668,
      0.08564429730176926,
      0.01275369431823492,
      0.0024399959947913885,
      0.029384465888142586,
      0.03739716485142708,
      0.06359920650720596,
      0.021855853497982025,
      -0.008022714406251907,
      -0.014402047730982304,
      -0.0063776117749512196,
      0.006878154817968607,
      -0.05127543583512306,
      0.041467830538749695,
      -0.04896547645330429,
      0.013257743790745735,
      0.04954657331109047,
      -0.050574835389852524,
      -0.031208010390400887,
      -0.03555801510810852,
      0.011216605082154274,
      -0.004517386667430401,
      0.012147973291575909,
      -0.13012893497943878,
      -0.0563979409635067,
      0.010086268186569214,
      0.08001825958490372,
      0.0006669321446679533,
      0.00788011122494936,
      0.06291328370571136,
      0.036214880645275116,
      -0.024289656430482864
    ],
    [
      -0.007869775407016277,
      -0.036447711288928986,
      0.0630882978439331,
      0.01361280120909214,
      -0.053065571933984756,
      -0.03759240359067917,
      0.016945991665124893,
      0.06444303691387177,
      0.0551711730659008,
      0.0153777115046978,
      0.06248907744884491,
      -0.0045739696361124516,
      -0.08954270929098129,
      -0.08531954884529114,
      -0.0798380970954895,
      -0.029248058795928955,
      0.011168824508786201,
      0.03226533904671669,
      -0.013294484466314316,
      -0.02338697575032711,
      -0.0056005120277404785,
      -0.010349196381866932,
      -0.004580847453325987,
      0.005727672483772039,
      0.013752602972090244,
      -0.02918555773794651,
      0.023418448865413666,
      -0.11632853001356125,
      -0.04796210303902626,
      -0.0008535663364455104,
      -0.008419811725616455,
      -0.014480424113571644,
      0.06330768018960953,
      0.016064884141087532,
      -0.029333068057894707,
      -0.036516349762678146,
      0.0684867724776268,
      0.03255072236061096,
      -0.009474738501012325,
      -0.029703360050916672,
      0.013929106295108795,
      0.022417381405830383,
      -0.05185333266854286,
      0.13186681270599365,
      0.021038398146629333,
      -0.034403786063194275,
      -0.03499721363186836,
      -0.04047248885035515,
      -0.055856212973594666,
      0.04361993446946144,
      0.007562790997326374,
      -0.03335034102201462,
      -0.03095703385770321,
      0.011368032544851303,
      -0.014703174121677876,
      0.033490125089883804,
      0.016418416053056717,
      0.0523291639983654,
      0.044517382979393005,
      0.027024082839488983,
      0.0014070585602894425,
      -0.036725372076034546,
      -0.04547163471579552,
      9.810096526052803e-05,
      -0.011453204788267612,
      -0.010650844313204288,
      -0.01488930732011795,
      -0.08582396805286407,
      0.05416391044855118,
      0.0627468079328537,
      -0.007651345804333687,
      0.038407549262046814,
      -0.009652645327150822,
      0.04828496649861336,
      0.08383659273386002,
      -0.003764754394069314,
      0.018609877675771713,
      0.027429282665252686,
      0.005930806044489145,
      -0.06875082105398178,
      -0.012335088104009628,
      0.04406470060348511,
      0.06658469885587692,
      -0.039511989802122116,
      -0.09547342360019684,
      -0.0029958102386444807,
      0.01984887197613716,
      0.03511206805706024,
      0.0848979726433754,
      0.024686267599463463,
      -0.011518459767103195,
      0.02347293123602867,
      -0.04267192631959915,
      -0.06995539367198944,
      -0.06057000160217285,
      0.044271934777498245,
      -0.044237520545721054,
      0.003079554997384548,
      -0.024331657215952873,
      0.02091638743877411,
      0.09942083060741425,
      0.017057498916983604,
      0.05063548684120178,
      -0.04986938461661339,
      0.004864878486841917,
      0.04479433223605156,
      0.0033487819600850344,
      0.052390601485967636,
      0.05837676674127579,
      0.08581991493701935,
      -0.0667409598827362,
      -0.04123331606388092,
      0.016319841146469116,
      -0.035036128014326096,
      0.011918711476027966,
      -0.0033458725083619356,
      0.018998004496097565,
      -0.06709803640842438,
      -0.07691114395856857,
      -0.006166949402540922,
      -0.042007602751255035,
      -0.005498227663338184,
      -0.021560586988925934,
      0.04153018817305565,
      0.009170600213110447,
      -0.03458283469080925,
      -0.009808972477912903,
      -0.038383420556783676,
      -0.002745274221524596,
      0.04938768967986107,
      -0.07647493481636047,
      0.0033706645481288433,
      0.04046306759119034,
      -0.024166036397218704,
      0.002659146673977375,
      0.02551654912531376,
      0.03304276615381241,
      0.06503427028656006,
      -0.00397542305290699,
      0.02368544228374958,
      0.007907252758741379,
      0.04691172391176224,
      0.003257462754845619,
      -0.008040041662752628,
      0.03241565451025963,
      0.034857600927352905,
      -0.037026938050985336,
      0.038301993161439896,
      -0.014799721539020538,
      -0.05923498794436455,
      -0.012907657772302628,
      0.032488174736499786,
      0.03510883450508118,
      -0.05110391229391098,
      0.06479595601558685,
      0.0014257289003580809,
      0.038148071616888046,
      -0.043561793863773346,
      0.03153332695364952,
      -0.045018382370471954,
      -0.04659264534711838,
      0.009378609247505665,
      -0.02491513080894947,
      0.009859132580459118,
      0.0191446952521801,
      -0.012767215259373188,
      -0.04275868460536003,
      -0.05420500785112381,
      0.018925366923213005,
      -0.06627380102872849,
      -0.05242428556084633,
      -0.008146903477609158,
      0.07288558781147003,
      -0.005368220154196024,
      0.0063967048190534115,
      0.07452769577503204,
      0.06739704310894012,
      0.0233939029276371,
      0.041238561272621155,
      0.02901642583310604,
      0.028868773952126503,
      0.06927688419818878,
      0.027260778471827507,
      -0.0035551174078136683,
      0.06742031872272491,
      0.07517837733030319,
      -0.018231060355901718,
      0.05778653547167778,
      0.049698058515787125,
      -0.023299409076571465,
      -0.016104716807603836,
      0.044363029301166534,
      0.01050595659762621,
      -0.013201569207012653,
      -0.009822830557823181,
      -0.04640180617570877,
      0.014432374387979507,
      -0.0013594339834526181,
      -0.018002351745963097,
      -0.004324999637901783,
      -0.025154028087854385,
      0.025856459513306618,
      0.032679617404937744,
      -0.02324916049838066,
      -0.014151949435472488,
      0.043955639004707336,
      0.013210288248956203,
      0.01787698082625866,
      0.0653265118598938,
      0.0031990758143365383,
      0.0016628543380647898,
      -0.03629186749458313,
      -0.04174259305000305,
      -0.019084284082055092,
      -0.0023965449072420597,
      0.05115613713860512,
      0.035850800573825836,
      -0.010012988932430744,
      0.05736145004630089,
      0.022563373669981956,
      -0.06010819599032402,
      0.022323021665215492,
      0.03319035470485687,
      -0.016213782131671906,
      -0.12573519349098206,
      0.016568047925829887,
      0.001775693497620523,
      -0.09283843636512756,
      0.018870554864406586,
      0.02877761796116829,
      -0.04322527348995209,
      -0.018032623454928398,
      0.08989749103784561,
      -0.06398375332355499,
      0.03175205737352371,
      -0.05879248306155205,
      0.02693440206348896,
      -0.024561483412981033,
      0.026538852602243423,
      -0.06258095055818558,
      0.08446692675352097,
      -0.06427968293428421,
      0.04752075672149658,
      -0.011287613771855831,
      0.0011262829648330808,
      0.06261156499385834,
      0.010666347108781338,
      0.056599777191877365,
      0.058757293969392776,
      -0.011662298813462257,
      -0.0647393986582756,
      -0.011406129226088524,
      -0.03568067029118538,
      0.08666254580020905,
      0.04719022661447525,
      -0.04355497285723686,
      0.013192743062973022,
      -0.04893730208277702,
      0.022573774680495262,
      0.030207553878426552,
      -0.03164883330464363,
      -0.11247409880161285,
      -0.027727527543902397,
      -0.05320753902196884,
      -0.04147573187947273,
      -0.03382841497659683,
      0.015425779856741428,
      0.005363232921808958,
      0.05225474759936333,
      -0.00397439394146204,
      -0.030858319252729416,
      0.034834034740924835,
      0.004148918204009533,
      0.048321064561605453,
      -0.028268994763493538,
      -0.010250421240925789,
      0.022589385509490967,
      0.0530850850045681,
      0.003544565523043275,
      -0.004544452298432589,
      0.04769699648022652,
      -0.03793129697442055,
      -0.012552094645798206,
      0.009669479914009571,
      -0.06936439871788025,
      -0.007552077528089285,
      -0.03218461200594902,
      -0.01380417961627245,
      0.052042342722415924,
      -0.01174132525920868,
      -0.07319191098213196,
      -0.011786594055593014,
      0.06831446290016174,
      -0.047426603734493256,
      0.013982715085148811,
      -0.02717781625688076,
      0.021807556971907616,
      -0.051771778613328934,
      -0.019991833716630936,
      -0.03392425924539566,
      -0.07195764780044556,
      -0.0189064834266901,
      -0.03878999873995781,
      -0.06078161671757698,
      0.00026138158864341676,
      0.02402469329535961,
      -0.06446218490600586,
      -0.03810838982462883,
      0.04925060272216797,
      0.040049828588962555,
      0.02354436181485653,
      -0.039373550564050674,
      0.004449621774256229,
      -0.03570084646344185,
      0.05767492577433586,
      -0.008914601057767868,
      -0.0435841865837574,
      -0.009064843878149986,
      -0.003983733244240284,
      0.019317815080285072,
      0.04515561833977699,
      0.02219626121222973,
      0.011559871025383472,
      -0.0429038368165493,
      -0.04107454791665077,
      0.010095077566802502,
      0.014706113375723362,
      -0.010320116765797138,
      0.05818340927362442,
      0.042226146906614304,
      -0.0025300513952970505,
      0.02719094604253769,
      0.019077546894550323,
      0.05772580951452255,
      0.02621709182858467,
      -0.01441408134996891,
      -0.006121437530964613,
      0.013757899403572083,
      0.023424513638019562,
      0.03072619065642357,
      0.011440328322350979,
      -0.0046152896247804165,
      -0.06944702565670013,
      0.01344312448054552,
      -0.07262291014194489,
      -0.03547678515315056,
      0.02839055471122265,
      0.008502060547471046,
      -0.03606586903333664,
      -0.029823454096913338,
      0.06028379127383232,
      0.023842087015509605,
      -0.01662028208374977,
      0.02013273723423481,
      0.03693147376179695,
      0.020121056586503983,
      0.02989598736166954,
      0.07342609763145447,
      0.051506079733371735,
      0.03408543020486832,
      -0.05326736718416214,
      0.040734048932790756,
      -0.01935962401330471,
      0.022907214239239693,
      0.06165864318609238,
      0.05159905552864075,
      -0.086640864610672,
      0.0028041373006999493,
      -0.03524595871567726,
      -0.021773751825094223,
      -0.06315038353204727,
      -0.042819321155548096,
      -0.02979155443608761,
      -0.011349889449775219,
      -0.062251925468444824,
      0.028489192947745323,
      0.07553300261497498,
      -0.03618394583463669,
      -0.03127337619662285,
      0.0006919145816937089,
      -0.035797350108623505,
      0.041081685572862625,
      0.04782384634017944,
      -0.0003897949354723096,
      -0.0679592490196228,
      0.04722264036536217,
      0.026115460321307182,
      0.01085064746439457,
      0.09383675456047058,
      -0.003991720266640186,
      -0.014870132319629192,
      0.05907180905342102,
      0.0235144030302763,
      0.0431995652616024,
      -0.018878184258937836,
      -0.07884243875741959,
      -0.004830404184758663,
      0.04795263335108757,
      0.026403339579701424,
      0.005246378481388092,
      0.09283196926116943,
      0.05547967180609703,
      0.024714414030313492,
      0.014631346799433231,
      0.018746472895145416,
      0.013845319859683514,
      0.03114183619618416,
      0.04208587482571602,
      0.028525426983833313,
      -0.004899348597973585,
      -0.09980985522270203,
      0.023042909801006317,
      -0.053268883377313614,
      -0.04205003380775452,
      -0.036500122398138046,
      -0.0004711437795776874,
      0.048680610954761505,
      -0.018237492069602013,
      0.028559517115354538,
      -0.03254871070384979,
      -0.014479383826255798,
      0.045810483396053314,
      0.034137532114982605,
      0.019535066559910774,
      0.054316192865371704,
      0.0024889211636036634,
      0.02388940192759037,
      -0.043584518134593964,
      0.004501753952354193,
      0.10164433717727661,
      0.007926148362457752,
      0.017453966662287712,
      0.02375197783112526,
      -0.020049532875418663,
      0.04486512020230293,
      -0.04336824640631676,
      -0.0495574064552784,
      -0.05835616588592529,
      -0.011829271912574768,
      -0.0033671667333692312,
      -0.11152872443199158,
      -0.041586022824048996,
      -0.008057566359639168,
      0.04517364501953125,
      -0.0675455629825592,
      -0.03911636397242546,
      -0.06815089285373688,
      0.03321614861488342,
      -0.010913915000855923,
      0.019482159987092018,
      -0.043436139822006226,
      -0.01027372945100069,
      -0.024471549317240715,
      0.03365730494260788,
      -0.05641317740082741,
      -0.013684791512787342,
      0.018204549327492714,
      -0.04008197411894798,
      -0.00423080800101161,
      -0.06441453099250793,
      0.03810802474617958,
      -0.004871286451816559,
      0.0033124766778200865,
      0.01782331056892872,
      -0.008069364354014397,
      -0.07578148692846298,
      -0.02190392278134823,
      0.004066759254783392,
      -0.05169816315174103,
      -0.010015327483415604,
      -0.04866939038038254,
      -0.044897254556417465,
      0.028537414968013763,
      0.03421063348650932,
      -0.009614712558686733,
      0.053996697068214417,
      0.0041177948005497456,
      0.0852714404463768,
      -0.04446931928396225,
      0.04237576946616173,
      -0.04001850634813309,
      0.06534808874130249,
      -0.02411927655339241,
      -0.005577819421887398,
      -0.014800437726080418,
      -0.03189912810921669,
      0.024607161059975624,
      0.013211052864789963,
      -0.027913996949791908,
      -0.08782736957073212,
      -0.015303532592952251,
      0.08343642204999924,
      -0.024424979463219643,
      0.016369113698601723,
      -0.019058631733059883,
      0.0032139166723936796,
      0.008000601083040237,
      -0.05931786820292473,
      -0.0516359768807888,
      -0.03481430187821388,
      -0.012143202126026154,
      -0.030709275975823402,
      0.007315733935683966
    ],
    [
      -0.048086076974868774,
      -0.05792903155088425,
      -0.031567540019750595,
      -0.031621817499399185,
      -0.09591175615787506,
      0.05361420661211014,
      0.033909305930137634,
      -0.013531261123716831,
      0.031050721183419228,
      0.0995374545454979,
      0.06655754148960114,
      -0.01546457689255476,
      -0.04560672864317894,
      0.0012136180885136127,
      0.04236569628119469,
      0.06337679177522659,
      0.009744586423039436,
      -0.006178164388984442,
      -0.03490094840526581,
      0.0032790349796414375,
      0.05142457038164139,
      -0.09947505593299866,
      0.016351008787751198,
      0.033842671662569046,
      0.06330635398626328,
      0.004384956322610378,
      0.01558325719088316,
      0.04358817636966705,
      0.012374567799270153,
      0.010507040657103062,
      -0.07227037101984024,
      -0.016744134947657585,
      0.00010785699123516679,
      0.04851542413234711,
      0.06495107710361481,
      0.026292553171515465,
      0.0005995305255055428,
      0.017502421513199806,
      0.02148710936307907,
      -0.03151623159646988,
      0.035142429172992706,
      -0.0226999893784523,
      0.06313557922840118,
      0.01696796342730522,
      -0.05205151438713074,
      0.13672232627868652,
      0.02832927741110325,
      0.008450821042060852,
      -0.05391755700111389,
      -0.007977224886417389,
      0.07017052173614502,
      -0.014701098203659058,
      0.009383387863636017,
      -0.03555573895573616,
      -0.010990485548973083,
      -0.023388899862766266,
      0.11624380201101303,
      0.024257861077785492,
      0.012419823557138443,
      -0.014537942595779896,
      -0.06173970550298691,
      0.060121022164821625,
      -0.06364797800779343,
      -0.01024616975337267,
      0.0043745217844843864,
      -0.06505270302295685,
      0.01869903691112995,
      0.0012329993769526482,
      0.02516474947333336,
      0.021079542115330696,
      0.01812550611793995,
      0.02784937433898449,
      0.013880974613130093,
      0.060739707201719284,
      -0.036989666521549225,
      0.03547060862183571,
      -0.06121348589658737,
      -0.021996457129716873,
      0.00625320989638567,
      -0.0306063462048769,
      0.030079051852226257,
      0.009792492724955082,
      0.038315217941999435,
      -0.10497080534696579,
      0.029275255277752876,
      -0.061756446957588196,
      0.010310654528439045,
      0.00608026934787631,
      0.028414756059646606,
      0.0640631839632988,
      0.10546179115772247,
      0.0013213491765782237,
      -0.030778801068663597,
      0.07745437324047089,
      -0.06141849234700203,
      0.023818934336304665,
      -0.030920714139938354,
      -0.014981018379330635,
      -0.05233825370669365,
      -0.03045124001801014,
      -0.01649947091937065,
      -0.04872163385152817,
      0.04677285999059677,
      -0.0025855209678411484,
      0.038319021463394165,
      -0.0009833341464400291,
      -0.008762002922594547,
      0.03480369597673416,
      -0.05999799817800522,
      0.08832182735204697,
      -0.015679797157645226,
      0.07226020842790604,
      0.014019805938005447,
      -0.03310659900307655,
      -0.03556051105260849,
      -0.026995504274964333,
      0.026884857565164566,
      -0.019185693934559822,
      0.022009070962667465,
      0.01862775720655918,
      -0.02840755321085453,
      -0.0487985797226429,
      0.003338549053296447,
      0.012989744544029236,
      0.04621490463614464,
      0.03689282014966011,
      -0.07240316271781921,
      -0.04431423172354698,
      0.07976461201906204,
      0.04481922835111618,
      -0.05686357244849205,
      0.06301948428153992,
      0.07775221765041351,
      0.06471382081508636,
      0.021479370072484016,
      -0.0666983425617218,
      0.0005524561856873333,
      -0.06094826012849808,
      -0.07858438044786453,
      0.02490140311419964,
      -0.011638476513326168,
      0.061238210648298264,
      -0.002214751671999693,
      0.02153126150369644,
      0.011965888552367687,
      0.012000730261206627,
      0.03943697735667229,
      0.012520183809101582,
      -0.018986448645591736,
      0.007997365668416023,
      0.012769505381584167,
      0.0037368701305240393,
      0.02396869659423828,
      -0.05196846276521683,
      0.03448166325688362,
      -0.021861301735043526,
      -0.022453472018241882,
      0.013158437795937061,
      0.051663462072610855,
      0.04413466528058052,
      -0.08265212923288345,
      -0.08914339542388916,
      -0.05449819564819336,
      0.030904365703463554,
      0.030503686517477036,
      0.0027549630030989647,
      -0.027260005474090576,
      0.00472239637747407,
      -0.0058395699597895145,
      -0.117180734872818,
      0.009420691058039665,
      0.035151947289705276,
      0.10080138593912125,
      -0.047664616256952286,
      -0.058722808957099915,
      0.03023832105100155,
      0.014353742823004723,
      -0.015431852079927921,
      0.042181290686130524,
      -0.07254334539175034,
      0.05116128548979759,
      -0.04569469392299652,
      0.013979489915072918,
      -0.05594896897673607,
      0.036109331995248795,
      0.023834295570850372,
      -0.003574992064386606,
      0.08626329898834229,
      -0.06711863726377487,
      -0.06371218711137772,
      0.023934047669172287,
      -0.03891223669052124,
      -0.028505545109510422,
      0.11260183900594711,
      -0.035879164934158325,
      0.030282536521553993,
      -0.06757037341594696,
      0.017080478370189667,
      0.02236129343509674,
      0.06377991288900375,
      -0.015600614249706268,
      0.020527224987745285,
      -0.06017405539751053,
      -0.033796172589063644,
      0.0038059712387621403,
      -0.03142745792865753,
      0.014458449557423592,
      -0.00655268644914031,
      0.06015516072511673,
      -0.04329601675271988,
      -0.03811393678188324,
      0.08948007971048355,
      -0.09138403832912445,
      -0.03737935796380043,
      0.03696709871292114,
      -0.06470022350549698,
      -0.016168711706995964,
      0.02404085360467434,
      -0.06181615963578224,
      0.05049128085374832,
      -0.09194774925708771,
      0.0064215767197310925,
      0.03388625755906105,
      -0.024320747703313828,
      -0.02697903849184513,
      -0.016864534467458725,
      0.01736997440457344,
      -0.007082193624228239,
      0.0019480970222502947,
      0.03676680848002434,
      -0.04241200536489487,
      0.09021641314029694,
      -0.042866453528404236,
      -0.0358271524310112,
      0.04761005565524101,
      0.0037217994686216116,
      -0.008578889071941376,
      -0.0944909155368805,
      -0.02575957216322422,
      0.028076104819774628,
      0.04603591561317444,
      -0.001677418826147914,
      -0.0017508089076727629,
      -0.008639656007289886,
      -0.015246634371578693,
      0.09235375374555588,
      0.034753166139125824,
      0.03568882495164871,
      -0.03187810629606247,
      -0.0872122123837471,
      -0.08859362453222275,
      -0.012031560763716698,
      0.027522165328264236,
      -0.015744421631097794,
      0.0024292622692883015,
      0.00646528135985136,
      -0.053536493331193924,
      0.03736891970038414,
      -0.006954604294151068,
      -0.014349416829645634,
      -0.0002110345521941781,
      0.049225613474845886,
      0.043655265122652054,
      0.006551902741193771,
      -0.05045558884739876,
      0.034953773021698,
      0.03662382811307907,
      0.0049712173640728,
      -0.012551393359899521,
      -0.0414186455309391,
      -0.015991296619176865,
      0.020418154075741768,
      -0.059007614850997925,
      -0.048312410712242126,
      -0.017078354954719543,
      -0.006099347956478596,
      -0.029098011553287506,
      0.06116189807653427,
      -0.01742239110171795,
      -0.043165385723114014,
      0.03474409878253937,
      0.06250324845314026,
      -0.051055822521448135,
      -0.071627177298069,
      -0.0046046143397688866,
      0.10055778175592422,
      0.022237926721572876,
      0.024454390630126,
      0.028548862785100937,
      0.07197175920009613,
      -0.019078843295574188,
      0.034175701439380646,
      -0.010848873294889927,
      0.020840054377913475,
      0.010872541926801205,
      0.05413902923464775,
      -0.037119586020708084,
      -0.027102194726467133,
      0.013463378883898258,
      0.031768329441547394,
      0.02597561664879322,
      0.07474915683269501,
      0.0576157346367836,
      -0.015056542120873928,
      0.04244406148791313,
      0.031209023669362068,
      0.0441092886030674,
      0.010300002992153168,
      -0.019915755838155746,
      -0.0677192434668541,
      0.027297917753458023,
      0.03429451957345009,
      -0.002730986336246133,
      -0.04674600064754486,
      0.07693636417388916,
      0.028372809290885925,
      -0.0003136628365609795,
      0.025787733495235443,
      -0.012227633036673069,
      0.01659225858747959,
      -0.03760237991809845,
      -0.016568008810281754,
      -0.07562956213951111,
      0.0005043497658334672,
      0.02608284167945385,
      0.0553114153444767,
      -0.024597106501460075,
      0.03746987134218216,
      0.04523041099309921,
      -0.05958515778183937,
      0.0001698008709354326,
      0.025977684184908867,
      -0.006029736716300249,
      0.017839986830949783,
      -0.008409451693296432,
      -0.09244576841592789,
      -0.040577542036771774,
      -0.021639583632349968,
      0.058128584176301956,
      0.03246534615755081,
      0.020484736189246178,
      -0.02296406403183937,
      0.038580551743507385,
      -0.000612929929047823,
      -0.0024912238586694,
      0.01766006462275982,
      0.031770333647727966,
      -0.014804739505052567,
      -0.031685296446084976,
      0.0499887652695179,
      0.0008055158541537821,
      0.00909483339637518,
      0.05967135354876518,
      -0.041415516287088394,
      -0.0003162138455081731,
      -0.0041082389652729034,
      -0.06669491529464722,
      -0.027245135977864265,
      0.00026395986787974834,
      -0.05492817983031273,
      0.03692256286740303,
      -0.06425648182630539,
      0.029103752225637436,
      0.0832231193780899,
      0.009160761721432209,
      -0.004538578912615776,
      -0.031179528683423996,
      -0.03061198629438877,
      -0.1163925975561142,
      -0.06738846004009247,
      -0.048016246408224106,
      -0.00016022974159568548,
      0.03492897003889084,
      0.08540917187929153,
      0.08662541210651398,
      -0.007315917871892452,
      0.07443831115961075,
      0.034883398562669754,
      0.003319709561765194,
      -0.0025925145018845797,
      -0.027498258277773857,
      -0.029378382489085197,
      0.06059112027287483,
      0.030876711010932922,
      -0.010290141217410564,
      -0.012985303066670895,
      -0.027165280655026436,
      -0.04126607999205589,
      -0.018937859684228897,
      0.00912359356880188,
      -0.04739182069897652,
      0.023852916434407234,
      -0.013198555447161198,
      0.07002046704292297,
      0.05972307175397873,
      0.07262423634529114,
      0.001467260648496449,
      0.02332448586821556,
      -0.004451614338904619,
      -0.009318048134446144,
      0.021576473489403725,
      0.012799126096069813,
      0.036329980939626694,
      0.04338419437408447,
      0.07047903537750244,
      0.041982755064964294,
      -0.04681204631924629,
      0.017335103824734688,
      0.002408752217888832,
      0.04184485599398613,
      -0.030080091208219528,
      0.0028117590118199587,
      -0.03485444560647011,
      0.010739844292402267,
      -0.06289051473140717,
      -0.005893645342439413,
      0.06518394500017166,
      0.04344959184527397,
      0.05801127851009369,
      0.01665785163640976,
      -0.04680357500910759,
      0.011104241013526917,
      0.002426479710265994,
      -0.008664744906127453,
      -0.08283474296331406,
      0.031923599541187286,
      0.021496444940567017,
      -0.09874971956014633,
      -0.00034174424945376813,
      -0.06349226087331772,
      0.038790013641119,
      -0.05386464297771454,
      0.0060126157477498055,
      0.08970910310745239,
      -0.11127109080553055,
      0.06599746644496918,
      0.016606204211711884,
      -0.049994152039289474,
      -0.004173303954303265,
      -0.01567002758383751,
      -0.040562719106674194,
      -0.024011453613638878,
      -0.06801171600818634,
      0.0020890887826681137,
      -0.04531000182032585,
      0.02723657712340355,
      -0.12583890557289124,
      -0.018105776980519295,
      0.04973170533776283,
      -0.07622068375349045,
      0.07093952596187592,
      0.04751533269882202,
      0.01243123970925808,
      0.018735723569989204,
      0.021216481924057007,
      0.06118969991803169,
      0.008629831485450268,
      -0.07909657806158066,
      -0.05393529683351517,
      -0.07088904082775116,
      -0.0018804891733452678,
      0.014094912447035313,
      -0.03600820153951645,
      -0.07301382720470428,
      0.04730761796236038,
      0.026435453444719315,
      0.024225588887929916,
      -0.05238772928714752,
      -0.010253583081066608,
      -0.07172974944114685,
      0.017189014703035355,
      0.011581220664083958,
      -0.05968756601214409,
      -0.03942547366023064,
      -0.043870437890291214,
      -0.03753577172756195,
      0.09156418591737747,
      0.09487704187631607,
      0.04065093398094177,
      -0.00996223371475935,
      0.02418854460120201,
      -0.011071977205574512,
      -0.02645438350737095,
      -0.030340947210788727,
      -0.03687436878681183,
      0.010326107032597065,
      0.020676132291555405,
      -0.03329852595925331,
      0.016847999766469002,
      -0.05123794451355934,
      -0.04411367326974869,
      0.05071715638041496,
      -0.06789274513721466,
      0.003992191515862942,
      -0.02397002838551998,
      -0.008537521585822105,
      0.013758774846792221,
      -0.062471676617860794,
      -0.03669558838009834,
      -0.029028326272964478,
      0.023925920948386192,
      -0.041936613619327545,
      -0.02029200829565525
    ],
    [
      0.03853677958250046,
      -0.018792057409882545,
      0.015487832948565483,
      -0.04678148031234741,
      -0.05321191996335983,
      0.015430022962391376,
      0.01822321116924286,
      -0.019095120951533318,
      0.044431861490011215,
      0.05900096893310547,
      -0.009301804006099701,
      -0.019883980974555016,
      0.022206977009773254,
      0.0694039911031723,
      0.03211292624473572,
      -0.01532654743641615,
      0.05185253918170929,
      -0.018809080123901367,
      0.027537811547517776,
      -0.008099834434688091,
      -0.03263078257441521,
      -0.0883660838007927,
      -0.02881171554327011,
      0.011476603336632252,
      -0.0009694101754575968,
      -0.02751767449080944,
      0.048056863248348236,
      -0.03039018251001835,
      -0.05502810329198837,
      -0.008995819836854935,
      -0.029210837557911873,
      -0.03699203580617905,
      -0.027406351640820503,
      0.0592988021671772,
      0.045621607452631,
      0.08983547240495682,
      0.008725191466510296,
      -0.0008539995178580284,
      -0.014952098950743675,
      -0.03795811906456947,
      -0.02348659373819828,
      -0.0781465694308281,
      -0.013341869227588177,
      0.05778956040740013,
      -0.04230251535773277,
      -0.02252892591059208,
      0.08121708035469055,
      -0.07195771485567093,
      -0.09832590818405151,
      -0.10825210064649582,
      -0.03871503099799156,
      -0.027397530153393745,
      0.05039209872484207,
      -0.06191110238432884,
      -0.08148154616355896,
      0.01828409731388092,
      0.06738358736038208,
      -0.023692911490797997,
      -0.008584292605519295,
      -0.03565679118037224,
      0.026318693533539772,
      0.014650802128016949,
      0.08591941744089127,
      0.001698756474070251,
      -0.06476473063230515,
      -0.0020686176139861345,
      0.012889530509710312,
      -0.03660425916314125,
      0.024811767041683197,
      0.030783716589212418,
      0.027948370203375816,
      0.03955066204071045,
      -0.03904411196708679,
      0.06421560049057007,
      0.049881357699632645,
      0.009559516794979572,
      -0.0049889544025063515,
      0.03508562222123146,
      -0.014421111904084682,
      -0.04715743288397789,
      0.04924333468079567,
      -0.04031439498066902,
      -0.01846669241786003,
      -0.04862005636096001,
      -0.033005133271217346,
      0.04898985102772713,
      0.01010849978774786,
      -0.08458133786916733,
      -0.04445734992623329,
      0.0030812928453087807,
      0.02373715303838253,
      0.018776636570692062,
      -0.011954405345022678,
      0.01805466040968895,
      -0.015168522484600544,
      -0.04086761921644211,
      -0.0699845552444458,
      -0.008354974910616875,
      -0.018779808655381203,
      0.09009720385074615,
      -0.05854135751724243,
      0.016055623069405556,
      0.008569497615098953,
      0.0785062313079834,
      0.03509822115302086,
      0.004604700952768326,
      -0.04652200639247894,
      0.013216143473982811,
      -0.013903261162340641,
      0.004689154680818319,
      -0.11938856542110443,
      -0.07678283005952835,
      -0.00538590969517827,
      -0.005967708304524422,
      0.022313138470053673,
      -0.06143001839518547,
      0.015907641500234604,
      0.06334961205720901,
      0.043511763215065,
      0.04508645460009575,
      -0.013678822666406631,
      0.015669602900743484,
      -0.008997593075037003,
      -0.08612748235464096,
      -0.012286807410418987,
      -0.00907982885837555,
      -0.016658281907439232,
      -0.029131071642041206,
      -0.07729721814393997,
      0.00583824934437871,
      -0.0023993325885385275,
      -0.004555136896669865,
      -0.029616927728056908,
      0.027277616783976555,
      0.013147695921361446,
      0.028040524572134018,
      0.036054037511348724,
      0.002064399654045701,
      0.0807400718331337,
      0.09254886209964752,
      -0.0509767159819603,
      0.02111021988093853,
      0.028310900554060936,
      -0.021831778809428215,
      0.03191680088639259,
      0.038584452122449875,
      -0.03153767064213753,
      0.04633832350373268,
      -0.04691879078745842,
      -0.008849340490996838,
      0.014340749010443687,
      0.05675835534930229,
      0.0331965908408165,
      -0.00022519688354805112,
      -0.018489988520741463,
      0.017845185473561287,
      0.1324104219675064,
      -0.018366802483797073,
      -0.03977248817682266,
      0.03268922120332718,
      -0.02031402289867401,
      -0.0334242582321167,
      0.07709295302629471,
      -0.05598007142543793,
      -0.009788163006305695,
      -0.04479426518082619,
      0.022310636937618256,
      -1.203032661578618e-05,
      0.002029580529779196,
      0.01456223614513874,
      0.01716713234782219,
      -0.04935277998447418,
      -0.010399021208286285,
      -0.04532979801297188,
      0.010465663857758045,
      0.07050824910402298,
      0.09378598630428314,
      -0.056110624223947525,
      0.08277815580368042,
      0.0872810035943985,
      0.051719438284635544,
      0.027333833277225494,
      0.05437258258461952,
      -0.040621038526296616,
      -0.008056378923356533,
      -0.05214667320251465,
      -0.04807552322745323,
      -0.012509475462138653,
      0.03665393590927124,
      0.030428124591708183,
      0.019080381840467453,
      -0.030656706541776657,
      -0.02835525944828987,
      0.0017116422532126307,
      -0.020801598206162453,
      -0.10524310171604156,
      0.0178065188229084,
      -0.054903507232666016,
      -0.09030047804117203,
      -0.050836239010095596,
      -0.021270642057061195,
      -0.05898243561387062,
      -0.010777889750897884,
      0.04166204482316971,
      -0.036403343081474304,
      0.03842215612530708,
      0.019595207646489143,
      0.0328480526804924,
      -0.014110071584582329,
      -0.0069215125404298306,
      0.07239635288715363,
      0.01141680870205164,
      0.0005851640598848462,
      -0.023125220090150833,
      -0.015769144520163536,
      -0.09880983084440231,
      0.05490416660904884,
      -0.06780149787664413,
      -0.0060524241998791695,
      -0.031918711960315704,
      0.006310957483947277,
      -0.0152788907289505,
      -0.039599090814590454,
      -0.011991428211331367,
      0.03362064063549042,
      -0.010294914245605469,
      0.03386642038822174,
      -0.009901849552989006,
      -0.030858473852276802,
      -0.0030366890132427216,
      -0.03481138125061989,
      -0.03976160287857056,
      -0.0009338459931313992,
      -0.058538589626550674,
      -0.08752641826868057,
      -0.019186658784747124,
      0.009947971440851688,
      0.01991073414683342,
      -0.012956452555954456,
      0.01805124245584011,
      -0.011089091189205647,
      -0.013663199730217457,
      0.0067291343584656715,
      0.013557648286223412,
      -0.0223324466496706,
      -0.05252482369542122,
      0.06906451284885406,
      0.06189442425966263,
      -0.07089099287986755,
      0.05269954726099968,
      0.024151142686605453,
      -0.01553271058946848,
      -0.028926130384206772,
      -0.04463682696223259,
      0.03910357877612114,
      0.03175019845366478,
      -0.0008494189823977649,
      -0.08097852021455765,
      0.0354432538151741,
      0.036268506199121475,
      0.04760657995939255,
      -0.03345809876918793,
      -0.03746020048856735,
      0.049963682889938354,
      -0.04818565398454666,
      0.03666047379374504,
      -0.042118992656469345,
      0.00019535321916919202,
      0.0708845928311348,
      0.05591583624482155,
      0.020207323133945465,
      0.031060826033353806,
      0.06093593314290047,
      -0.011885096319019794,
      0.0003818150726146996,
      -0.08677802234888077,
      0.025382699444890022,
      0.008275764994323254,
      -0.022080035880208015,
      -0.0067426059395074844,
      -0.02122814767062664,
      -0.019309435039758682,
      0.01760687306523323,
      0.07667568325996399,
      0.013636455871164799,
      0.035273946821689606,
      0.036739300936460495,
      -0.008801603689789772,
      -0.06433790177106857,
      -0.023383870720863342,
      -0.045872244983911514,
      -0.01035800389945507,
      -0.05146560072898865,
      -0.00284180766902864,
      0.008225924335420132,
      -0.01780535653233528,
      -0.03292872756719589,
      0.025169210508465767,
      -0.03945159167051315,
      -0.0033479146659374237,
      -0.013381519354879856,
      0.009443406015634537,
      -0.054617900401353836,
      0.05309305340051651,
      -0.040828607976436615,
      -0.05244450643658638,
      0.007344242185354233,
      0.0679548904299736,
      0.05595848336815834,
      -0.04589684680104256,
      0.022660640999674797,
      0.012222577817738056,
      0.05123044550418854,
      -0.028225038200616837,
      0.0848131775856018,
      -0.020686449483036995,
      0.00403804425150156,
      -0.009021181613206863,
      0.015708936378359795,
      -0.04589885473251343,
      -0.04651279002428055,
      -0.01963113807141781,
      -0.0645160898566246,
      -0.07807989418506622,
      -0.03509476035833359,
      0.04449683427810669,
      -0.021184533834457397,
      0.07073679566383362,
      -0.06048603728413582,
      -0.03159407898783684,
      -0.0034866591449826956,
      -0.03296094015240669,
      -0.011703173629939556,
      0.012677333317697048,
      0.007578456308692694,
      -0.009213016368448734,
      0.035265181213617325,
      -0.01367533765733242,
      0.04959430918097496,
      -0.022581564262509346,
      0.026440158486366272,
      0.03149653226137161,
      -0.06671866774559021,
      0.07230258733034134,
      0.010878159664571285,
      -0.03687022998929024,
      0.02312297746539116,
      -0.060052454471588135,
      0.007593326736241579,
      -0.02111095003783703,
      -0.05726391077041626,
      0.0004371140676084906,
      0.004066751804202795,
      0.02939176931977272,
      0.016634447500109673,
      -0.03856748342514038,
      -0.022888468578457832,
      -0.026867786422371864,
      -0.021526290103793144,
      0.021914856508374214,
      0.0067537697032094,
      -0.03145035356283188,
      0.05766816809773445,
      -0.03446222096681595,
      -0.009837864898145199,
      0.03404207155108452,
      -0.021682919934391975,
      -0.08904638886451721,
      0.04467594996094704,
      -0.011985236778855324,
      0.051275137811899185,
      0.03015764430165291,
      0.01825837418437004,
      0.09161507338285446,
      -0.013459612615406513,
      0.043213214725255966,
      -0.01610550843179226,
      -0.011730169877409935,
      0.047560982406139374,
      0.0349702388048172,
      -0.03479276970028877,
      0.0668317973613739,
      -0.01893252320587635,
      -0.05151009187102318,
      0.05099399387836456,
      0.010183541104197502,
      0.018507152795791626,
      0.0642012357711792,
      0.02261998876929283,
      -0.030962025746703148,
      -0.05027608945965767,
      0.051797930151224136,
      -0.055911917239427567,
      0.0901423916220665,
      0.04501499608159065,
      0.06375002861022949,
      0.01818058080971241,
      0.009022067300975323,
      0.026038559153676033,
      -0.027604788541793823,
      -0.10139110684394836,
      0.05153730511665344,
      -0.004471553955227137,
      0.0371735617518425,
      0.037005577236413956,
      0.007296198979020119,
      -0.05433426424860954,
      0.01767287403345108,
      -0.03328137472271919,
      -0.09258253872394562,
      0.014769135043025017,
      0.07052440196275711,
      -0.02432343177497387,
      0.009841583669185638,
      -0.013711299747228622,
      0.0617765448987484,
      0.004762669093906879,
      0.026885703206062317,
      0.05038405954837799,
      0.01358172856271267,
      -0.03611324355006218,
      0.04616670683026314,
      -0.09389758110046387,
      0.015986913815140724,
      0.028577467426657677,
      0.1066456064581871,
      0.019998960196971893,
      0.024699639528989792,
      0.028865646570920944,
      0.016198379918932915,
      0.03303205594420433,
      0.14251047372817993,
      -0.01824820041656494,
      0.028680507093667984,
      0.011178235523402691,
      -0.005235919263213873,
      0.017561541870236397,
      0.020635653287172318,
      -0.008377124555408955,
      -0.053681232035160065,
      0.026128079742193222,
      0.03773431479930878,
      -0.0015243446687236428,
      0.057585570961236954,
      0.019910413771867752,
      0.030802913010120392,
      -0.020612334832549095,
      -0.039586085826158524,
      -0.004654899705201387,
      0.05717875435948372,
      -0.02804139256477356,
      -0.004243428353220224,
      -0.03164791688323021,
      0.062414057552814484,
      0.008852122351527214,
      -0.025254979729652405,
      -0.0007871827692724764,
      0.01528794877231121,
      -0.016516568139195442,
      0.08567897975444794,
      0.03657689318060875,
      0.06020249053835869,
      0.05827157571911812,
      0.0002642774488776922,
      0.023198280483484268,
      0.018658362329006195,
      0.024431664496660233,
      -0.01734950952231884,
      -0.09188679605722427,
      0.011842671781778336,
      -0.12917745113372803,
      -0.09799469262361526,
      -0.0760112926363945,
      0.02197415940463543,
      -0.04856661334633827,
      0.009858338162302971,
      -0.04383764788508415,
      -0.06739339232444763,
      0.03249827399849892,
      0.10016368329524994,
      0.06691160798072815,
      -0.08360471576452255,
      -0.059201911091804504,
      0.10931664705276489,
      0.03868166729807854,
      -0.11518790572881699,
      0.07587216049432755,
      -0.031848032027482986,
      0.04767417162656784,
      -0.021806037053465843,
      -0.025380000472068787,
      0.024530909955501556,
      0.008012787438929081,
      -0.06878779828548431,
      0.013239550404250622,
      0.026943758130073547,
      0.0012041134759783745,
      -9.091930405702442e-05,
      0.022851381450891495,
      -0.013394753448665142,
      0.09295114874839783,
      0.0463772714138031,
      -0.07058596611022949
    ],
    [
      0.01662036031484604,
      0.05499647185206413,
      0.05103233456611633,
      0.06710465997457504,
      0.013098815456032753,
      -0.0030584270134568214,
      0.044416237622499466,
      -0.014443454332649708,
      -0.020436830818653107,
      0.009805005043745041,
      0.014175266958773136,
      -0.014492932707071304,
      -0.03512797877192497,
      -0.07800798118114471,
      0.011442041024565697,
      -0.10859663039445877,
      -0.006786404177546501,
      -0.05260876566171646,
      0.08236559480428696,
      -0.06500215828418732,
      0.0018246538238599896,
      -0.023925645276904106,
      0.043117932975292206,
      -0.10835520178079605,
      0.018183035776019096,
      -0.07122886180877686,
      -0.00035183862200938165,
      -0.013006521388888359,
      0.008892352692782879,
      0.0730464830994606,
      0.006751664914190769,
      0.014751143753528595,
      0.01489540096372366,
      -0.012695236131548882,
      -0.023027164861559868,
      -0.057608429342508316,
      0.03545486554503441,
      0.023903902620077133,
      0.037161312997341156,
      0.008383571170270443,
      -0.013922909274697304,
      -0.04918644204735756,
      0.006930400617420673,
      -0.06572215259075165,
      -0.03445335477590561,
      -0.02783513441681862,
      0.007991141639649868,
      0.030649276450276375,
      0.09906776249408722,
      0.024469677358865738,
      0.03093268722295761,
      0.05124171823263168,
      0.07407500594854355,
      -0.03106778673827648,
      0.05111909285187721,
      -0.0096126738935709,
      0.05827920511364937,
      0.09682376682758331,
      0.06695738434791565,
      -0.029744692146778107,
      0.02260359562933445,
      -0.05370554327964783,
      0.05962451547384262,
      -0.014288467355072498,
      0.004717931617051363,
      0.08833164721727371,
      0.03700077533721924,
      0.020654676482081413,
      0.004399297293275595,
      -0.015662649646401405,
      0.015552529133856297,
      0.011440472677350044,
      0.014833766035735607,
      0.045541148632764816,
      -0.05973883718252182,
      0.01667981967329979,
      0.02408558689057827,
      0.009513077326118946,
      -0.06813836842775345,
      0.007846590131521225,
      -0.010527157224714756,
      -0.07484236359596252,
      -0.014379628002643585,
      0.003732631914317608,
      0.037172093987464905,
      -0.037064097821712494,
      -0.08259736746549606,
      0.12114615738391876,
      0.0165825467556715,
      0.016121655702590942,
      0.09799566119909286,
      -0.036385517567396164,
      -0.06640087068080902,
      0.025386178866028786,
      -0.04149070009589195,
      -0.0033336859196424484,
      -0.10595182329416275,
      0.092827707529068,
      0.0022129067219793797,
      0.08581886440515518,
      0.022854449227452278,
      0.06595619022846222,
      0.08186113089323044,
      0.00735623762011528,
      -0.0063588572666049,
      0.009868083521723747,
      -0.017905455082654953,
      -0.018572958186268806,
      -0.010256246663630009,
      -0.05850088596343994,
      0.007680740673094988,
      0.061737656593322754,
      -0.05573805421590805,
      -0.07220818847417831,
      -0.011557981371879578,
      0.0074446010403335094,
      0.05024626851081848,
      7.228687172755599e-05,
      -0.052367448806762695,
      -0.0013605225831270218,
      -0.01135933492332697,
      0.05283105745911598,
      -0.031181195750832558,
      0.02886069193482399,
      0.14482848346233368,
      -0.023188313469290733,
      0.02134203165769577,
      -0.04767501726746559,
      0.04927414283156395,
      -0.02373676374554634,
      -0.022225596010684967,
      0.06578980386257172,
      0.004323695786297321,
      0.0343976765871048,
      -0.08194269984960556,
      -0.0860419049859047,
      -0.014611846767365932,
      0.05450903996825218,
      0.014282618649303913,
      -0.014577774330973625,
      0.019716957584023476,
      -0.018184175714850426,
      0.0011611016234382987,
      -0.05136187747120857,
      0.014195038937032223,
      -0.01648227870464325,
      -0.04393944889307022,
      -0.07898404449224472,
      -0.06308996677398682,
      0.05809585750102997,
      -0.06366747617721558,
      0.030621573328971863,
      0.009125744923949242,
      0.029320117086172104,
      0.005502458196133375,
      -0.056257281452417374,
      -0.01068840455263853,
      0.0328499861061573,
      -0.0958418995141983,
      -0.01250501535832882,
      0.039358772337436676,
      -0.05991539731621742,
      -0.015185946598649025,
      0.07471290230751038,
      -0.08064690977334976,
      0.08237311989068985,
      0.01792888343334198,
      -0.04107993096113205,
      0.01621551252901554,
      0.13877421617507935,
      -0.1413741111755371,
      -0.008234260603785515,
      -0.032058555632829666,
      -0.009639470838010311,
      0.05344955623149872,
      -0.006643296219408512,
      -0.04337447136640549,
      -0.010792219080030918,
      -0.036020345985889435,
      -0.04619330167770386,
      -0.10053469985723495,
      0.04231366887688637,
      0.0635601207613945,
      0.006927401293069124,
      -0.006702349986881018,
      0.023352008312940598,
      0.03866676986217499,
      0.050579093396663666,
      0.0007108915015123785,
      0.05466608703136444,
      -0.016589857637882233,
      0.0297651756554842,
      -0.020364927127957344,
      0.05553699657320976,
      0.006542265880852938,
      -0.016974063590168953,
      -0.01568746380507946,
      -0.04657738283276558,
      0.04487505555152893,
      0.06512857228517532,
      0.05573958531022072,
      -0.004156986717134714,
      -0.04611576721072197,
      -0.020028619095683098,
      -0.04521166905760765,
      -0.0673641487956047,
      0.03904831409454346,
      0.019673557952046394,
      0.07350438088178635,
      0.038304489105939865,
      -0.033750034868717194,
      0.08236873894929886,
      -0.018501881510019302,
      -0.06535977870225906,
      0.028242625296115875,
      0.0066931964829564095,
      0.05919450893998146,
      -0.05161153897643089,
      -0.019375761970877647,
      -0.014851977117359638,
      -0.002818420762196183,
      0.05570099502801895,
      -0.0010064333910122514,
      -0.029276562854647636,
      -0.0067780655808746815,
      -0.07813836634159088,
      0.020607443526387215,
      -0.02600969560444355,
      0.013770160265266895,
      0.03123677708208561,
      0.013625524938106537,
      0.03618062287569046,
      0.017941690981388092,
      0.053061190992593765,
      0.02504986710846424,
      0.08409401774406433,
      0.014716630801558495,
      -0.003288144711405039,
      -0.0005679522291757166,
      0.07911090552806854,
      0.013056398369371891,
      0.037504591047763824,
      0.050890929996967316,
      0.019284415990114212,
      -0.003813559887930751,
      0.032223284244537354,
      -0.08056557178497314,
      -0.029685279354453087,
      -0.019862931221723557,
      0.012530368752777576,
      0.07385698705911636,
      0.04317742586135864,
      0.00549411540850997,
      -0.06363781541585922,
      -0.029583562165498734,
      0.010637175291776657,
      0.04094100743532181,
      -0.017504969611763954,
      -0.057755064219236374,
      -0.005151729099452496,
      0.05056042596697807,
      0.02884695492684841,
      -0.03751625865697861,
      0.01164376363158226,
      0.07596549391746521,
      0.03519563749432564,
      -0.02715406008064747,
      0.07165896147489548,
      -0.043159473687410355,
      0.075583815574646,
      -0.01413559541106224,
      -0.048808805644512177,
      -0.033414360135793686,
      0.025223273783922195,
      -0.048352643847465515,
      -0.03904510661959648,
      0.03741420432925224,
      -0.026041759178042412,
      -0.02717519924044609,
      -0.05725155770778656,
      0.021863775327801704,
      0.03545832261443138,
      -0.04295867308974266,
      -0.0009542435873299837,
      0.011780626140534878,
      0.04258796572685242,
      0.046995099633932114,
      0.07994799315929413,
      0.055066417902708054,
      0.025509437546133995,
      0.0341566801071167,
      0.01340549997985363,
      -0.0015583682106807828,
      0.008971101604402065,
      0.027869386598467827,
      0.0020597060211002827,
      0.08666610717773438,
      0.02432134561240673,
      0.07498413324356079,
      -0.03693707659840584,
      -0.01789572462439537,
      0.04766729101538658,
      0.033856358379125595,
      0.02408522367477417,
      -0.030457211658358574,
      0.017669755965471268,
      -0.062495287507772446,
      -0.03193053603172302,
      0.01858980767428875,
      -0.04880458489060402,
      0.06795055419206619,
      -0.00042973662493750453,
      -0.004368402063846588,
      -0.02160646766424179,
      -0.07665856927633286,
      -0.011758998036384583,
      -0.04168073460459709,
      -0.005404352676123381,
      0.016720900312066078,
      -0.03557568043470383,
      0.023811088874936104,
      -0.0007006733794696629,
      0.010140192694962025,
      0.016678493469953537,
      0.04545433819293976,
      0.014543868601322174,
      0.04635106399655342,
      0.022523120045661926,
      -0.04755175858736038,
      0.024279017001390457,
      0.026150431483983994,
      0.04381681978702545,
      -0.045905206352472305,
      0.04195764288306236,
      -0.02098083682358265,
      -0.0052452548407018185,
      -0.06180217117071152,
      -0.019933849573135376,
      -0.012673387303948402,
      -0.01722557097673416,
      0.017383219674229622,
      0.00816427357494831,
      0.05101386457681656,
      0.07746904343366623,
      0.017070667818188667,
      -0.00413188710808754,
      0.0880701094865799,
      -0.004319214262068272,
      0.03471832349896431,
      0.017268609255552292,
      0.01978996954858303,
      -0.020647164434194565,
      0.04156900569796562,
      0.012520236894488335,
      -0.1226750835776329,
      0.010568328201770782,
      0.02362651191651821,
      0.04744550585746765,
      -0.030056321993470192,
      0.06212262809276581,
      -0.0551108717918396,
      -0.018313102424144745,
      -0.054626043885946274,
      0.009458587504923344,
      -0.038204506039619446,
      -0.007200649473816156,
      0.045073702931404114,
      -0.006084942724555731,
      0.021974198520183563,
      -0.0007612527115270495,
      0.01559302769601345,
      -0.018611935898661613,
      0.0075148907490074635,
      0.0653756782412529,
      -0.013722876086831093,
      0.04284027963876724,
      0.09387391805648804,
      0.013937713578343391,
      -0.04368055984377861,
      0.05280201882123947,
      -0.06405041366815567,
      0.09360901266336441,
      0.02783781662583351,
      9.26429929677397e-05,
      0.06431123614311218,
      -0.0038885304238647223,
      -0.026527009904384613,
      0.03626519814133644,
      0.02539936453104019,
      -0.021452132612466812,
      0.03148439899086952,
      0.07326070964336395,
      0.02538532204926014,
      -0.008621648885309696,
      -0.02676849253475666,
      0.0010787310311570764,
      -0.045361265540122986,
      0.014443827793002129,
      -0.0004925567773170769,
      -0.050145331770181656,
      0.012865586206316948,
      -0.028851931914687157,
      0.06688158214092255,
      -0.08213586360216141,
      0.036277808248996735,
      -0.0020757303573191166,
      0.07315250486135483,
      0.04900527745485306,
      -0.05983644723892212,
      -0.02529580518603325,
      0.051585596054792404,
      -0.11653754860162735,
      0.013480047695338726,
      -0.07742779701948166,
      0.0007190245087258518,
      -0.06866376847028732,
      -0.07939214259386063,
      0.026288311928510666,
      0.045464154332876205,
      -0.0865856483578682,
      0.05230793356895447,
      -0.05868947505950928,
      0.03671742230653763,
      -0.03812601789832115,
      0.009607591666281223,
      -0.04359942302107811,
      -0.0015452343504875898,
      0.007415636908262968,
      -0.03294046223163605,
      -0.0039345030672848225,
      -0.028744598850607872,
      0.03571416065096855,
      -0.01939457468688488,
      -0.01368723064661026,
      0.018841760233044624,
      0.01778174564242363,
      -0.03694766387343407,
      0.018981413915753365,
      -0.028421349823474884,
      0.04815473407506943,
      0.06817769259214401,
      -0.018752701580524445,
      0.0024011170025914907,
      0.024842584505677223,
      0.024805709719657898,
      -0.027432169765233994,
      0.02723395824432373,
      -0.008222942240536213,
      0.00029730479582212865,
      0.04058825224637985,
      -0.014557739719748497,
      -0.032036297023296356,
      -0.022214382886886597,
      -0.03742990642786026,
      0.037705257534980774,
      -0.04179348051548004,
      -0.03968751057982445,
      0.04627830535173416,
      -0.0021080190781503916,
      -0.024687297642230988,
      -0.055352453142404556,
      -0.060537684708833694,
      0.019264735281467438,
      0.06881479918956757,
      -0.009107525460422039,
      0.009583498351275921,
      -0.0067789750173687935,
      0.06941746175289154,
      -0.0371621698141098,
      -0.016080010682344437,
      -0.009085813537240028,
      -0.02644900232553482,
      -0.02284952439367771,
      0.033803075551986694,
      0.013743394985795021,
      -0.06629053503274918,
      -0.02857511304318905,
      -0.0016740044811740518,
      -0.01173895038664341,
      0.018994677811861038,
      0.06267848610877991,
      -0.11155581474304199,
      -0.06579316407442093,
      0.039681948721408844,
      -0.02060086280107498,
      0.05435667932033539,
      0.02237963303923607,
      -0.01312653161585331,
      -0.04566822201013565,
      0.037781331688165665,
      -0.04734847694635391,
      0.05503823235630989,
      0.04193456470966339,
      0.020322751253843307,
      -0.040164269506931305,
      -0.09096099436283112,
      0.07682264596223831,
      -0.0394492968916893,
      0.007847600616514683,
      0.10612738877534866,
      0.004959400277584791,
      -0.03302695229649544,
      -0.06016527861356735
    ],
    [
      0.009455309249460697,
      -0.11515908688306808,
      -0.01583518087863922,
      0.014013804495334625,
      0.014275566674768925,
      0.04361103102564812,
      -0.047482915222644806,
      -0.04911929368972778,
      0.006732203532010317,
      0.049348942935466766,
      0.06255445629358292,
      0.047041334211826324,
      -0.013633289374411106,
      -0.044212259352207184,
      -0.00046441933955065906,
      -0.007632490713149309,
      -0.04782393202185631,
      -0.06555794924497604,
      -0.023422399535775185,
      0.09665308147668839,
      -0.020532483235001564,
      -0.06462987512350082,
      -0.04880311340093613,
      -0.018748687580227852,
      0.11028293520212173,
      -0.037200890481472015,
      -0.004559948109090328,
      -0.024504387751221657,
      0.003747348440811038,
      0.08658325672149658,
      -0.027652151882648468,
      0.028794392943382263,
      0.03916552662849426,
      0.013188319280743599,
      0.017060788348317146,
      0.01940462738275528,
      0.046914611011743546,
      -0.012571010738611221,
      0.009431170299649239,
      -0.04917309060692787,
      0.05368903651833534,
      0.042556799948215485,
      -0.03004343807697296,
      0.027677005156874657,
      0.024888213723897934,
      -0.014727923087775707,
      0.07739222794771194,
      -0.06476977467536926,
      -0.026595834642648697,
      0.04351118952035904,
      0.024957183748483658,
      0.06851697713136673,
      0.09558460861444473,
      -0.007433472666889429,
      -0.06415561586618423,
      -0.051876410841941833,
      0.05481823906302452,
      0.012224797159433365,
      0.026701506227254868,
      0.029276229441165924,
      -0.0021420505363494158,
      0.0035811096895486116,
      0.032451778650283813,
      -0.04406984522938728,
      -0.015952738001942635,
      0.02683335728943348,
      -0.0018491618102416396,
      0.06645794212818146,
      -0.07687152177095413,
      0.026012282818555832,
      -0.0604742057621479,
      0.025641532614827156,
      0.04353807494044304,
      -0.04436735436320305,
      -0.06169044226408005,
      0.005725991912186146,
      0.029150735586881638,
      0.03935813531279564,
      -0.04931696131825447,
      -0.0005885850405320525,
      0.049590613692998886,
      -0.09660645574331284,
      -0.03555348142981529,
      -0.03511524200439453,
      -0.016147296875715256,
      -0.015490316785871983,
      0.07212590426206589,
      0.014052001759409904,
      0.03234713897109032,
      0.013297549448907375,
      -0.03847155347466469,
      0.04042014852166176,
      -0.011651251465082169,
      -0.012046515010297298,
      -0.028396986424922943,
      -0.0997631624341011,
      0.09391903132200241,
      -0.025778885930776596,
      0.05415324494242668,
      0.07330737262964249,
      0.00917491503059864,
      -0.051406051963567734,
      0.0469716377556324,
      -0.0430837981402874,
      0.01714969612658024,
      -0.0016959997592493892,
      0.012038846500217915,
      -0.029371637850999832,
      -0.038968030363321304,
      -0.05213424563407898,
      0.08437786251306534,
      0.05594690516591072,
      0.0497821569442749,
      -0.01627509854733944,
      0.06600770354270935,
      -0.11357010155916214,
      0.009887192398309708,
      0.020813319832086563,
      0.028742332011461258,
      0.024582816287875175,
      -0.1281992346048355,
      0.019546648487448692,
      0.0022718380205333233,
      0.03588833659887314,
      0.011463390663266182,
      -0.049675095826387405,
      -0.03382593393325806,
      0.048409342765808105,
      0.0046710725873708725,
      -0.037653785198926926,
      0.07389651983976364,
      0.006505039054900408,
      -0.032943349331617355,
      -0.003383778501302004,
      0.005954984575510025,
      -0.03164438530802727,
      -0.06059190258383751,
      0.005922280251979828,
      0.06086229532957077,
      0.014396785758435726,
      0.021797357127070427,
      0.015005852095782757,
      0.050555113703012466,
      -0.0724017545580864,
      -0.06292986869812012,
      0.008739626966416836,
      0.08534705638885498,
      -0.005393869709223509,
      0.0670342668890953,
      0.06508225202560425,
      0.04886194318532944,
      -0.11933387815952301,
      0.06456735730171204,
      0.0018983478657901287,
      -0.023136334493756294,
      0.010995062068104744,
      0.031183384358882904,
      0.10870449990034103,
      0.06441406160593033,
      0.02242656983435154,
      -0.10344050079584122,
      -0.03559352084994316,
      0.014648884534835815,
      0.08529120683670044,
      0.0008245604112744331,
      -0.003557387972250581,
      0.01869427226483822,
      -0.0022412866819649935,
      -0.003444880712777376,
      -0.013336453586816788,
      -0.030941402539610863,
      -0.008063122630119324,
      -0.030319906771183014,
      0.07937701046466827,
      -0.10261455178260803,
      -0.0016778295394033194,
      0.026860753074288368,
      0.00828518532216549,
      -0.1106698140501976,
      -0.025744901970028877,
      -0.08029469102621078,
      -0.002521985210478306,
      0.03463645651936531,
      0.006448911502957344,
      -0.03003508411347866,
      0.05045977979898453,
      0.021112538874149323,
      0.038535427302122116,
      -0.005357103422284126,
      0.013693242333829403,
      -0.02420211024582386,
      -0.04326140508055687,
      0.015543151646852493,
      0.049211498349905014,
      0.022141164168715477,
      0.06286514550447464,
      -0.12564148008823395,
      0.052515726536512375,
      -0.04013991728425026,
      0.0010240568080917,
      -0.0011101379059255123,
      0.03386887535452843,
      0.048164624720811844,
      -0.03201189637184143,
      0.014063370414078236,
      0.03258346766233444,
      -0.009937629103660583,
      -0.0021121420431882143,
      0.005652980878949165,
      0.00018500867008697242,
      0.01143200509250164,
      0.006209585815668106,
      -0.10746945440769196,
      -0.027107249945402145,
      0.03132947161793709,
      -0.04576604440808296,
      -0.016997002065181732,
      -0.05809786915779114,
      0.024935079738497734,
      -0.03017614781856537,
      -0.03788581117987633,
      0.07375942915678024,
      0.10142863541841507,
      -0.017194412648677826,
      0.006536547560244799,
      -0.019515838474035263,
      -0.0025842369068413973,
      -0.03921016305685043,
      0.05554446950554848,
      0.021739386022090912,
      -0.0163153987377882,
      -0.05851049721240997,
      0.035576559603214264,
      0.043412934988737106,
      0.02345559187233448,
      -0.018666250631213188,
      -0.02246682159602642,
      -0.017376627773046494,
      -0.009234377183020115,
      -0.07847020030021667,
      0.05968543514609337,
      0.09625047445297241,
      0.02961835451424122,
      0.14218132197856903,
      0.006913360673934221,
      -0.03795221075415611,
      0.031024321913719177,
      0.0013553911121562123,
      -0.008709781803190708,
      0.000795640517026186,
      0.10842395573854446,
      -0.08239240199327469,
      0.007207343354821205,
      0.04026505723595619,
      -0.03465050086379051,
      -0.020222986117005348,
      0.021623894572257996,
      0.04130616784095764,
      0.035695962607860565,
      -0.04333333298563957,
      -0.005434797145426273,
      0.02373739704489708,
      0.0035765243228524923,
      0.09181886911392212,
      0.026347875595092773,
      -0.06483926624059677,
      -0.03746747970581055,
      0.047329507768154144,
      -0.0296944547444582,
      0.033124472945928574,
      -0.0033882022835314274,
      0.011234236881136894,
      -0.06616657227277756,
      -0.001938150729984045,
      -0.10338471829891205,
      -0.00486297532916069,
      0.018712732940912247,
      0.05287090316414833,
      0.07741573452949524,
      0.05195120722055435,
      -0.05895299091935158,
      0.060636237263679504,
      -0.013783808797597885,
      -0.046952441334724426,
      0.0377374142408371,
      0.010870878584682941,
      0.058349207043647766,
      0.02740059792995453,
      -0.04712111875414848,
      0.028626874089241028,
      0.02840643934905529,
      -0.060043178498744965,
      0.030522363260388374,
      -0.0039005756843835115,
      0.004896043799817562,
      0.019669154658913612,
      0.040449436753988266,
      -0.00020923398551531136,
      0.017478695139288902,
      -0.018086260184645653,
      -0.004926845896989107,
      0.018914954736828804,
      -8.785631507635117e-06,
      0.003954989369958639,
      0.037987422198057175,
      0.015197353437542915,
      0.0796324610710144,
      -0.007704086136072874,
      0.12246542423963547,
      0.07620151340961456,
      0.027304567396640778,
      0.02583145536482334,
      0.03194442763924599,
      -0.07114861160516739,
      -0.0007373911794275045,
      0.07154732942581177,
      0.05645325779914856,
      0.07131009548902512,
      0.03284792974591255,
      -0.02681885100901127,
      0.006312306970357895,
      -0.0007544852560386062,
      -0.0490269735455513,
      0.015366699546575546,
      0.04259737208485603,
      -0.014233188703656197,
      0.03503002971410751,
      -0.011029226705431938,
      -0.007477778010070324,
      -0.030259795486927032,
      -0.014886141754686832,
      -0.010303420946002007,
      -0.027454596012830734,
      0.055571068078279495,
      -0.027770714834332466,
      0.012744267471134663,
      -0.030207425355911255,
      -0.015084799379110336,
      -0.050583645701408386,
      0.07819681614637375,
      0.006945052184164524,
      -0.026696471497416496,
      -0.07926753163337708,
      -0.13652673363685608,
      -0.04936940595507622,
      0.0721483901143074,
      -0.0026694803964346647,
      0.04138197377324104,
      -0.05418350547552109,
      0.02019559033215046,
      0.014653928577899933,
      0.02490052580833435,
      -0.013241461478173733,
      0.06718570739030838,
      -0.023379618301987648,
      0.03255712240934372,
      -0.03612640127539635,
      0.04956771805882454,
      0.08829474449157715,
      0.0016480573685839772,
      0.037336234003305435,
      0.009428064338862896,
      -0.08082222193479538,
      0.00760044576600194,
      0.0740671306848526,
      0.020618170499801636,
      -0.04204101115465164,
      0.04793873056769371,
      0.09484604746103287,
      0.06775414198637009,
      -0.03643730655312538,
      0.026081187650561333,
      0.016589157283306122,
      0.03756587579846382,
      -0.03137612342834473,
      0.10766634345054626,
      0.00029631744837388396,
      -0.013758874498307705,
      -0.01563124917447567,
      -0.05473827198147774,
      -0.007477918174117804,
      0.012547658756375313,
      0.043398719280958176,
      0.04615757614374161,
      -0.08671347051858902,
      0.12055721879005432,
      0.016951780766248703,
      -0.09642273187637329,
      -0.09615717828273773,
      0.01600542664527893,
      0.03331045061349869,
      -0.03484892472624779,
      0.04766121134161949,
      -0.08396472036838531,
      -0.030173778533935547,
      -0.011608130298554897,
      -0.08080510795116425,
      -0.05718110129237175,
      -0.052724987268447876,
      -0.0025016278959810734,
      0.028603093698620796,
      -0.060336608439683914,
      -0.008836900815367699,
      -0.001613480388186872,
      -0.029654650017619133,
      0.03396928310394287,
      -0.09330806881189346,
      0.06825263053178787,
      -0.02992081083357334,
      0.02022230625152588,
      0.049865201115608215,
      -0.09640613943338394,
      0.031054213643074036,
      -0.008069907315075397,
      -0.03348193317651749,
      0.0136854974552989,
      0.007009481079876423,
      0.06881264597177505,
      -0.009254999458789825,
      0.01745816133916378,
      0.021596159785985947,
      -0.013730146922171116,
      -0.012716474942862988,
      -0.020650101825594902,
      -0.03887702524662018,
      0.0614161416888237,
      -0.017009910196065903,
      -0.017175907269120216,
      -0.03788807988166809,
      -0.019507434219121933,
      0.03585554659366608,
      0.01607544906437397,
      -0.014967781491577625,
      -0.001626139273867011,
      0.007554220035672188,
      0.044042084366083145,
      0.03337419778108597,
      0.027494698762893677,
      0.01132497750222683,
      0.010074997320771217,
      0.0399978831410408,
      0.01390885841101408,
      -0.03291730582714081,
      0.0016851024702191353,
      0.07101435214281082,
      -0.00406884727999568,
      0.004817300010472536,
      -0.000658641685731709,
      0.0706510916352272,
      0.04477490484714508,
      -0.006833977997303009,
      0.013822811655700207,
      -0.01118973083794117,
      -0.022031662985682487,
      0.015736013650894165,
      -0.01945679821074009,
      0.003981275483965874,
      -0.0008053685305640101,
      0.07032213360071182,
      -0.023373866453766823,
      0.038451239466667175,
      0.09139307588338852,
      -0.006449147593230009,
      -0.0020801322534680367,
      -0.022027119994163513,
      -0.018093949183821678,
      -0.011153805069625378,
      -0.07348986715078354,
      -0.03338374197483063,
      -0.023062651976943016,
      -0.03609306365251541,
      -0.08112847059965134,
      -0.016119949519634247,
      0.07672180235385895,
      -0.01860656589269638,
      -0.021029818803071976,
      -0.025625938549637794,
      -0.009890580549836159,
      0.004258547443896532,
      -0.03008309192955494,
      0.04409926012158394,
      0.0013713449006900191,
      -0.07118235528469086,
      0.059930551797151566,
      -0.0010995923075824976,
      0.05465042218565941,
      -0.038884811103343964,
      0.001035658409819007,
      -0.04592254012823105,
      -0.049043890088796616,
      0.11710561066865921,
      0.03746315464377403,
      -0.001975737977772951,
      0.05268027260899544,
      -0.0671185627579689,
      0.018915431573987007,
      -0.038126230239868164,
      0.0031667249277234077,
      0.01817486248910427,
      0.009214561432600021,
      0.04816470295190811,
      -0.04598136246204376,
      -0.04906695336103439
    ],
    [
      0.015417112037539482,
      -0.037326380610466,
      -0.0257294699549675,
      -0.03820204362273216,
      0.058150675147771835,
      0.03326316550374031,
      -0.01925349421799183,
      -0.011853807605803013,
      0.020039111375808716,
      -0.09618658572435379,
      0.061236388981342316,
      -0.05535842105746269,
      0.03000725619494915,
      0.024719974026083946,
      0.02076507732272148,
      -0.023786582052707672,
      -0.0696127787232399,
      -0.0028586299158632755,
      0.008434806950390339,
      -0.13432958722114563,
      0.006988540291786194,
      -0.008423558436334133,
      -0.02112928032875061,
      -0.062461141496896744,
      0.01158229447901249,
      -0.07927841693162918,
      0.04587201401591301,
      0.0181620791554451,
      0.005859969183802605,
      -0.028409499675035477,
      0.023672351613640785,
      -0.05446896702051163,
      -0.01908346451818943,
      0.08961313962936401,
      0.05723189935088158,
      -0.04801921173930168,
      0.02916419319808483,
      0.030239665880799294,
      0.040228940546512604,
      0.03031916543841362,
      0.0016374586848542094,
      -0.024888500571250916,
      -0.0381854884326458,
      -0.04305783286690712,
      -0.007215804886072874,
      0.001233115210197866,
      0.1151142343878746,
      -0.02824883535504341,
      0.08701136708259583,
      -0.07960085570812225,
      0.05905676633119583,
      0.011137662455439568,
      -0.007379100192338228,
      0.09347550570964813,
      -0.02894151397049427,
      0.024580681696534157,
      0.052137888967990875,
      -0.012657714076340199,
      -0.0907173678278923,
      0.029223958030343056,
      0.02297379821538925,
      -0.016195686534047127,
      -0.0801500752568245,
      -0.0264816302806139,
      -0.05509104207158089,
      -0.009759889915585518,
      -0.05053715407848358,
      0.003028778126463294,
      -0.014585994184017181,
      -0.027371583506464958,
      0.02134506218135357,
      0.011416678316891193,
      -0.059353746473789215,
      -0.03946695849299431,
      -0.019045285880565643,
      0.04919090121984482,
      0.03309987112879753,
      -0.0839390978217125,
      0.041881948709487915,
      0.05369473248720169,
      -0.014260421507060528,
      0.016743043437600136,
      0.07218872755765915,
      0.0174490287899971,
      -0.024976836517453194,
      0.07300292700529099,
      -0.1142924427986145,
      0.02020004205405712,
      0.013877209275960922,
      0.018825054168701172,
      -0.054071392863988876,
      -0.029590273275971413,
      0.05416896939277649,
      0.04011852294206619,
      0.006975999101996422,
      0.05158647894859314,
      0.006350533105432987,
      -0.04033637046813965,
      0.04209166765213013,
      -0.04532948508858681,
      0.004697796888649464,
      -0.004484597127884626,
      0.027705831453204155,
      0.0111263832077384,
      -0.019473059102892876,
      0.04081287235021591,
      -0.02250383235514164,
      0.038412176072597504,
      0.0007103379466570914,
      0.07098720222711563,
      -0.046474527567625046,
      0.013602233491837978,
      0.0007494984311051667,
      -0.047905560582876205,
      -0.04404199868440628,
      -0.06212189048528671,
      -0.0547742061316967,
      -0.06244774907827377,
      -0.005438522435724735,
      0.019838828593492508,
      -0.06162210926413536,
      0.010014704428613186,
      -0.06383369117975235,
      -0.03302015736699104,
      0.01517521496862173,
      -0.03463428094983101,
      0.004834308288991451,
      -0.004304265137761831,
      -0.01349582802504301,
      0.0706026703119278,
      0.07051794975996017,
      0.05580449849367142,
      0.021670373156666756,
      -0.003455328056588769,
      0.0722503662109375,
      0.11740764230489731,
      -0.06816448271274567,
      0.04985138401389122,
      0.0156918466091156,
      -0.02065115235745907,
      -0.014345047064125538,
      -0.04031311720609665,
      0.09465938061475754,
      -0.038958605378866196,
      0.10441020876169205,
      -0.03638743981719017,
      0.05435175448656082,
      -0.028847863897681236,
      -0.038064975291490555,
      0.07069099694490433,
      -0.04378456622362137,
      -0.008316084742546082,
      -0.042949747294187546,
      -0.009969135746359825,
      -0.012753639370203018,
      -0.020715992897748947,
      -0.030009441077709198,
      -0.011546588502824306,
      0.05252940207719803,
      0.04136855527758598,
      -0.04662285000085831,
      -0.004468097817152739,
      0.04477737843990326,
      0.09168824553489685,
      0.014228877611458302,
      -0.01021075900644064,
      0.0972810685634613,
      0.028517644852399826,
      -0.03224518522620201,
      -0.011154497042298317,
      -0.016421861946582794,
      -0.05083383992314339,
      0.012049824930727482,
      0.01083478145301342,
      -0.016252221539616585,
      -0.010625804774463177,
      0.0006266867858357728,
      -0.04576234892010689,
      -0.01294130552560091,
      0.006947512272745371,
      -0.046622928231954575,
      -0.07030579447746277,
      0.01445748284459114,
      -0.023356735706329346,
      -0.05490535870194435,
      0.06027248874306679,
      -0.013087103143334389,
      -0.054833438247442245,
      0.09203922748565674,
      0.01841013878583908,
      -0.022651806473731995,
      0.02214713580906391,
      -0.05028634890913963,
      -0.02095344476401806,
      0.02154887653887272,
      -0.019283795729279518,
      -0.036337293684482574,
      -0.028361836448311806,
      -0.009555807337164879,
      -0.03482574597001076,
      -0.04338114708662033,
      0.06621483713388443,
      -0.02295832335948944,
      -0.07590878009796143,
      0.008614841848611832,
      -0.002175917848944664,
      0.020732305943965912,
      -0.0761980190873146,
      -0.026480523869395256,
      -0.01787020079791546,
      0.06630103290081024,
      0.05174439027905464,
      -0.023539142683148384,
      -0.07005108892917633,
      0.03127310797572136,
      0.12351967394351959,
      -0.003290360327810049,
      0.009635576978325844,
      0.029777375981211662,
      -0.052333198487758636,
      0.013265286572277546,
      -0.012536689639091492,
      0.06896918267011642,
      0.057397741824388504,
      0.07003149390220642,
      0.06372082233428955,
      0.05890946462750435,
      0.012983792461454868,
      0.0036115527618676424,
      0.0519513376057148,
      -0.08316683769226074,
      -0.03233899921178818,
      0.007531129289418459,
      0.016782335937023163,
      0.04047233983874321,
      0.0005484988214448094,
      0.0919988676905632,
      0.040782395750284195,
      -0.005519953556358814,
      0.005116583313792944,
      0.04645725339651108,
      0.0856584832072258,
      0.022475745528936386,
      -0.02691909857094288,
      0.028575632721185684,
      -0.003605491016060114,
      0.047046925872564316,
      -0.06511477380990982,
      0.0330752432346344,
      0.05088599771261215,
      0.023908231407403946,
      -0.0038429968990385532,
      -0.0011380345094949007,
      -0.03195871040225029,
      -0.04897649958729744,
      -0.05532834678888321,
      0.09096749871969223,
      -0.008661923930048943,
      -0.03759824112057686,
      0.018930941820144653,
      0.07850494235754013,
      0.0007783050532452762,
      0.04235213249921799,
      -0.06634654104709625,
      -0.05604834854602814,
      -0.013697989284992218,
      -0.05965719744563103,
      0.052569661289453506,
      -0.03275156021118164,
      -0.004899619612842798,
      -0.041907694190740585,
      0.030415020883083344,
      0.054108019918203354,
      0.01282468531280756,
      -0.036705151200294495,
      -0.022090045735239983,
      0.040108174085617065,
      -0.02617800235748291,
      0.002891112584620714,
      0.004261388443410397,
      0.026856964454054832,
      -0.05962416157126427,
      -0.02167529985308647,
      0.03128194808959961,
      0.0025321985594928265,
      -0.0006428289925679564,
      0.0026663292665034533,
      -0.008275244385004044,
      -0.014487428590655327,
      0.08671768009662628,
      -0.048102591186761856,
      0.03948460519313812,
      0.06753305345773697,
      0.0043099382892251015,
      0.04984003305435181,
      -0.019228698685765266,
      0.012853939086198807,
      0.10588224977254868,
      0.004977331031113863,
      0.08142127841711044,
      0.001287810504436493,
      -0.04925597086548805,
      0.01283723209053278,
      0.07010907679796219,
      0.024736838415265083,
      0.0428919680416584,
      0.0050217993557453156,
      -0.005224971566349268,
      -0.01259187888354063,
      0.00931231863796711,
      0.04679034650325775,
      0.01818806491792202,
      0.004422436933964491,
      0.0066679697483778,
      -0.002510086866095662,
      0.0646369531750679,
      0.07993239909410477,
      -0.04200379550457001,
      0.0629357174038887,
      -0.027115153148770332,
      0.08695749193429947,
      -0.0024748975411057472,
      0.013737396337091923,
      0.08299915492534637,
      -0.008299863897264004,
      -0.09847406297922134,
      0.07964155077934265,
      -0.008855637162923813,
      0.03794839605689049,
      0.07091894745826721,
      -0.008947927504777908,
      -0.004132081754505634,
      0.03993786871433258,
      -0.03245359659194946,
      0.0012306642020121217,
      -0.0013024109648540616,
      -0.11625470221042633,
      -0.05949144437909126,
      0.05470265820622444,
      -0.0005697041051462293,
      0.052210282534360886,
      -0.013192183338105679,
      0.00618685083463788,
      -0.07805491983890533,
      0.01567113772034645,
      0.07311233133077621,
      0.07227815687656403,
      0.048872143030166626,
      -0.05725487321615219,
      -0.0534106008708477,
      0.04180924594402313,
      0.0804842934012413,
      0.02957707829773426,
      -0.008673391304910183,
      -0.03327309340238571,
      -0.017770521342754364,
      0.11147304624319077,
      0.024454809725284576,
      -0.009941263124346733,
      0.06394018977880478,
      0.0593523345887661,
      0.05428747460246086,
      0.07339641451835632,
      -0.007830307818949223,
      0.05630739778280258,
      -0.01419133972376585,
      0.06231407821178436,
      0.03963923826813698,
      0.0346820242702961,
      0.01630735583603382,
      -0.07955434173345566,
      0.033078622072935104,
      -0.013042174279689789,
      -0.0265946127474308,
      0.015975693240761757,
      -0.07747607678174973,
      0.05350064858794212,
      0.039433907717466354,
      -0.014053286984562874,
      -0.017044780775904655,
      0.04822440817952156,
      0.04265940934419632,
      0.03613333776593208,
      0.0499495267868042,
      0.015983615070581436,
      -0.11704249680042267,
      0.010846255347132683,
      -0.02293744497001171,
      -0.02171015366911888,
      -0.014885070733726025,
      0.003992692567408085,
      0.0050637307576835155,
      0.014104818925261497,
      0.0068837557919323444,
      -0.06951385736465454,
      0.08244482427835464,
      -0.0830792635679245,
      0.04400551691651344,
      -0.07562350481748581,
      -0.012344293296337128,
      -0.04408874735236168,
      -0.008168739266693592,
      0.04213771969079971,
      0.03028760850429535,
      -0.017289714887738228,
      0.014898421242833138,
      -0.0525176040828228,
      -0.006514923181384802,
      0.07615954428911209,
      -0.026108425110578537,
      0.02672126702964306,
      0.03689221292734146,
      0.04265724495053291,
      0.012512206099927425,
      0.0261971615254879,
      0.025954248383641243,
      0.08496201038360596,
      0.017150653526186943,
      0.012550625950098038,
      -0.11537158489227295,
      0.07413458079099655,
      0.06599298119544983,
      -0.03987612575292587,
      -0.050605595111846924,
      -0.021085640415549278,
      0.043594956398010254,
      -0.0490923635661602,
      0.06822308897972107,
      -0.0069681997410953045,
      -0.00028870656387880445,
      -0.04220932349562645,
      0.07340437918901443,
      -0.036755990236997604,
      -0.0503554493188858,
      -0.030499177053570747,
      -0.04563670977950096,
      -0.04387325420975685,
      -0.03962275758385658,
      0.014393088407814503,
      0.003769641276448965,
      0.00655652629211545,
      0.03907151520252228,
      -0.0098553616553545,
      -0.026479149237275124,
      -0.07766735553741455,
      -0.05057283118367195,
      0.029743032529950142,
      -0.09806036204099655,
      -0.07713821530342102,
      -0.0696047693490982,
      -0.03824080154299736,
      0.09643246978521347,
      0.0025471970438957214,
      0.00602244446054101,
      -0.07911059260368347,
      0.09024284034967422,
      0.04950521141290665,
      0.06117740646004677,
      0.07322704792022705,
      0.011076743714511395,
      0.006406707689166069,
      -0.055773839354515076,
      -0.03705386072397232,
      -0.05492953956127167,
      0.04016490280628204,
      -0.07766323536634445,
      0.0057002524845302105,
      0.030414093285799026,
      -0.03243035450577736,
      -0.05704956874251366,
      0.003010114189237356,
      0.01035246066749096,
      0.04547365754842758,
      0.09111307561397552,
      0.059218768030405045,
      -0.09264754503965378,
      -0.014662529341876507,
      -0.060454610735177994,
      -0.045697834342718124,
      0.05093011260032654,
      0.007590674329549074,
      0.03826940432190895,
      0.018390106037259102,
      0.05154459923505783,
      0.04323059692978859,
      0.013387443497776985,
      -0.04776851832866669,
      0.05733340233564377,
      -0.13272054493427277,
      -0.03384649008512497,
      -0.03213561326265335,
      0.03472292423248291,
      0.0984724760055542,
      0.023672359064221382,
      0.004428884945809841,
      0.027312450110912323,
      0.0673360675573349,
      -0.039002884179353714,
      -0.0035750968381762505,
      -0.08579989522695541,
      -0.021356355398893356,
      0.0018367273733019829,
      -0.011630191467702389
    ],
    [
      -0.012639451771974564,
      -0.003208793932572007,
      0.013862816616892815,
      0.07204744964838028,
      -0.05732150748372078,
      0.04657662659883499,
      0.05989005044102669,
      0.024544332176446915,
      0.0027223441284149885,
      -0.06513197720050812,
      0.02044125646352768,
      0.06947550177574158,
      0.07737258076667786,
      -0.04080776497721672,
      0.014825182035565376,
      0.02478988841176033,
      0.03867949917912483,
      -0.004716155584901571,
      0.06757562607526779,
      -0.06115315854549408,
      0.023944638669490814,
      -0.044230714440345764,
      -0.0720859244465828,
      -0.06168441101908684,
      -2.877743827411905e-05,
      -0.1146511361002922,
      -0.0910215973854065,
      0.004357700701802969,
      0.00026361882919445634,
      -0.005226976703852415,
      0.03756290301680565,
      0.003261828562244773,
      -0.03655204176902771,
      0.02928691916167736,
      0.02279834821820259,
      -0.029268845915794373,
      -0.05922464653849602,
      0.05769230052828789,
      -0.0260623786598444,
      -0.002714339643716812,
      -0.0829693078994751,
      -0.0042835138738155365,
      -0.025779755786061287,
      0.02212049812078476,
      0.01580260880291462,
      0.06336045265197754,
      -0.02236626297235489,
      -0.01761787198483944,
      0.0023848670534789562,
      -0.09062489867210388,
      0.029492594301700592,
      0.0350756011903286,
      0.00493408739566803,
      -0.06242438033223152,
      0.028980882838368416,
      0.011763827875256538,
      0.05461882799863815,
      0.04950183257460594,
      -0.06549959629774094,
      -0.001223572064191103,
      0.01768987812101841,
      0.0382503867149353,
      0.039278626441955566,
      0.019300024956464767,
      -0.038383599370718,
      0.03455031290650368,
      -0.03287625312805176,
      0.017733508720993996,
      -0.05637088045477867,
      0.05599534511566162,
      -0.0074570574797689915,
      0.0008812352898530662,
      -0.013448906131088734,
      -0.0010094314347952604,
      0.045779723674058914,
      -0.026219764724373817,
      0.040659427642822266,
      0.017760932445526123,
      -0.031829170882701874,
      -0.06312989443540573,
      0.023842591792345047,
      -0.08168036490678787,
      -0.07904792577028275,
      0.017069989815354347,
      -0.05088674649596214,
      -0.04216591268777847,
      -0.016710054129362106,
      0.0663265809416771,
      -0.029370825737714767,
      0.016898106783628464,
      0.027562398463487625,
      -0.029251599684357643,
      0.019811784848570824,
      -0.011984989047050476,
      -0.01992993988096714,
      0.025536546483635902,
      -0.1055373027920723,
      0.019840965047478676,
      0.07190737873315811,
      0.0012816765811294317,
      -0.12391870468854904,
      -0.03588502109050751,
      0.006918198894709349,
      -0.070078544318676,
      0.05738437920808792,
      0.0059491116553545,
      0.017283333465456963,
      -0.02789665199816227,
      0.029337938874959946,
      -0.02301672287285328,
      0.10912345349788666,
      -0.026064053177833557,
      0.02220536582171917,
      0.03254854306578636,
      -0.033524271100759506,
      0.06801516562700272,
      -0.02012307196855545,
      -0.005261983722448349,
      -0.013190478086471558,
      0.012368611060082912,
      -0.03963080421090126,
      0.011829068884253502,
      0.015026314184069633,
      -0.01856464520096779,
      0.06562106311321259,
      0.03630838915705681,
      -0.040825869888067245,
      0.03812303766608238,
      -0.08943089842796326,
      0.018478941172361374,
      -0.04503367468714714,
      -0.08984103798866272,
      -0.00513588497415185,
      0.06414347887039185,
      0.04393806308507919,
      0.010045644827187061,
      -0.05891568586230278,
      0.03767203912138939,
      -0.07061030715703964,
      -0.04849473014473915,
      -0.034656740725040436,
      0.0032253568060696125,
      0.03213512897491455,
      -0.08662537485361099,
      -0.019583864137530327,
      -0.10421042144298553,
      -0.03782329335808754,
      0.021320227533578873,
      0.013794626109302044,
      0.029095249250531197,
      -0.00200505624525249,
      0.023351270705461502,
      -0.038481131196022034,
      0.01596841588616371,
      0.0430590845644474,
      0.041269440203905106,
      0.05642480403184891,
      0.0018300252268090844,
      0.06105438247323036,
      0.04441409558057785,
      0.05514213815331459,
      -0.008963300846517086,
      -0.01634434424340725,
      0.10557426512241364,
      0.00935311708599329,
      0.00012014385720249265,
      -0.018622348085045815,
      -0.0296239722520113,
      -0.021474262699484825,
      0.02033633179962635,
      0.004802052862942219,
      -0.006518619600683451,
      0.010760240256786346,
      0.010640638880431652,
      -0.004206600598990917,
      0.03179614245891571,
      0.03708624094724655,
      0.016090428456664085,
      -0.03764699772000313,
      -0.037549227476119995,
      0.13239748775959015,
      -0.08849766105413437,
      -0.009305081330239773,
      -0.014597892761230469,
      0.09288793057203293,
      -0.03944207727909088,
      -0.06279938668012619,
      -0.057708192616701126,
      0.00737567525357008,
      0.018611757084727287,
      -0.06448032706975937,
      -0.035895802080631256,
      0.06875073909759521,
      0.05566871166229248,
      -0.06989797204732895,
      0.07108641415834427,
      -0.027343010529875755,
      0.020988499745726585,
      -0.03604995459318161,
      -0.008847245015203953,
      -0.033542465418577194,
      0.04903753474354744,
      0.03867543116211891,
      0.04463260620832443,
      0.0272528026252985,
      0.00604597944766283,
      -0.04050947353243828,
      -0.013174809515476227,
      0.041125159710645676,
      0.07954441010951996,
      0.06322813034057617,
      0.013998899608850479,
      0.013196771033108234,
      -0.05556989833712578,
      0.007669389713555574,
      -0.0038254058454185724,
      -0.016593599691987038,
      -0.02161208726465702,
      0.04767591878771782,
      0.032563239336013794,
      -0.09039978682994843,
      0.10049691051244736,
      0.022175414487719536,
      0.0019909157417714596,
      -0.03395712375640869,
      -0.011398181319236755,
      0.010144866071641445,
      0.07215739041566849,
      -0.03178900480270386,
      0.0031286729499697685,
      -0.03612853214144707,
      -0.035127200186252594,
      0.026958977803587914,
      0.0693264827132225,
      -0.024787412956357002,
      -0.062226150184869766,
      0.00037635586340911686,
      -0.06893988698720932,
      0.046553630381822586,
      -0.021172575652599335,
      -0.040032729506492615,
      -0.033895716071128845,
      0.04964124783873558,
      0.02465667761862278,
      0.05911116674542427,
      -0.07803806662559509,
      -0.016565127298235893,
      0.006238455884158611,
      0.01923508569598198,
      0.03685912862420082,
      0.0428917296230793,
      -0.009214946068823338,
      0.09968537092208862,
      0.03199564293026924,
      -0.10282471030950546,
      -0.038876473903656006,
      0.010537036694586277,
      0.05064457282423973,
      -0.09505098313093185,
      -0.003226745408028364,
      -0.028819026425480843,
      0.05281057953834534,
      0.0842212662100792,
      -0.010548683814704418,
      0.043419502675533295,
      -0.03409430384635925,
      -0.03975421190261841,
      -0.006607590708881617,
      0.07097362726926804,
      0.007955553941428661,
      0.0796717181801796,
      -0.020909568294882774,
      -0.0075309621170163155,
      -0.0022148650605231524,
      -0.013888710178434849,
      -0.031803108751773834,
      0.03243110328912735,
      -0.01807336136698723,
      -0.045569438487291336,
      0.06565692275762558,
      0.02614056132733822,
      0.05505131557583809,
      0.027644937857985497,
      0.025337066501379013,
      -0.015430728904902935,
      -0.041593972593545914,
      0.10226411372423172,
      0.019079741090536118,
      0.0465703159570694,
      -0.04350995272397995,
      0.06094228848814964,
      -0.002730058738961816,
      -0.046910516917705536,
      -0.02053205668926239,
      0.002992115681990981,
      0.022958936169743538,
      -0.05418011173605919,
      0.03316536918282509,
      0.038568418473005295,
      -0.008159474469721317,
      0.022923488169908524,
      -0.07037460058927536,
      -0.024643750861287117,
      0.05840412899851799,
      0.026920517906546593,
      -0.07229110598564148,
      -0.010505192913115025,
      0.0003060372837353498,
      0.06319816410541534,
      0.04524160921573639,
      0.054266370832920074,
      0.064473956823349,
      0.060933467000722885,
      0.040445178747177124,
      0.01139833778142929,
      -0.01814785599708557,
      0.03184279054403305,
      0.0688786506652832,
      -0.016146933659911156,
      -0.0364064984023571,
      -0.07276023179292679,
      -0.04702605679631233,
      -0.011753683909773827,
      0.07667241990566254,
      0.026536455377936363,
      -0.03946825861930847,
      0.0037011911626905203,
      0.03665085881948471,
      -0.05226963758468628,
      0.041544098407030106,
      -0.029606826603412628,
      -0.019129181280732155,
      0.04734955355525017,
      -0.029180947691202164,
      0.08064696192741394,
      0.05536871403455734,
      0.036135025322437286,
      0.016266735270619392,
      0.007161301095038652,
      -0.06623438745737076,
      0.07512810826301575,
      -0.05339030548930168,
      0.03508279100060463,
      -0.017130430787801743,
      -0.007397186942398548,
      -0.015495911240577698,
      0.05553697049617767,
      0.0006565080257132649,
      -0.07423486560583115,
      -0.0030649900436401367,
      0.09958982467651367,
      -0.10263577848672867,
      -0.06288362294435501,
      0.05605738237500191,
      -0.04203466698527336,
      -0.010686187073588371,
      -0.042210862040519714,
      -0.008644896559417248,
      -0.02206273004412651,
      -0.06421355158090591,
      -0.059454672038555145,
      -0.03997378051280975,
      0.09000212699174881,
      0.05404522269964218,
      -0.011775580234825611,
      0.025685187429189682,
      0.034299515187740326,
      0.011232930235564709,
      0.058855943381786346,
      0.09101280570030212,
      -0.026320666074752808,
      -0.0025920465122908354,
      0.030250249430537224,
      0.015671851113438606,
      -0.026893993839621544,
      0.00892723724246025,
      0.05527728050947189,
      -0.01860537938773632,
      0.04002217948436737,
      0.008957876823842525,
      -0.03446659445762634,
      -0.04442575201392174,
      -0.036162711679935455,
      0.022247573360800743,
      0.023566918447613716,
      -0.06286966800689697,
      -0.07152612507343292,
      0.06742022186517715,
      0.0020588100887835026,
      0.037831876426935196,
      -0.0033790217712521553,
      0.07067161053419113,
      0.020993346348404884,
      -0.09613676369190216,
      -0.0015847813338041306,
      0.03591417521238327,
      -0.007846567779779434,
      -0.017481017857789993,
      0.04607347771525383,
      -0.060486938804388046,
      -0.018140584230422974,
      -0.0018754432676360011,
      0.03174871951341629,
      0.005356841254979372,
      -0.07662488520145416,
      -0.0008024288108572364,
      -0.010854038409888744,
      -0.042343251407146454,
      0.014404661022126675,
      -0.000660311954561621,
      -0.006303911097347736,
      -0.01087268441915512,
      0.05860796570777893,
      0.019803820177912712,
      -0.06913596391677856,
      0.05682723969221115,
      0.07046908885240555,
      0.008568525314331055,
      0.03623471409082413,
      0.09303776174783707,
      -0.03487474471330643,
      0.053694840520620346,
      -0.0009438399574719369,
      0.021194206550717354,
      -0.017393676564097404,
      -0.015003906562924385,
      0.023185310885310173,
      0.13158191740512848,
      0.08256610482931137,
      0.07491618394851685,
      0.06320954114198685,
      -0.05925080552697182,
      -0.08174040913581848,
      -0.012988165020942688,
      0.00026188016636297107,
      -0.0549621619284153,
      0.06074554845690727,
      -0.003030079649761319,
      -0.023105647414922714,
      0.03348354622721672,
      0.013664387166500092,
      0.03433581814169884,
      0.0060953013598918915,
      -0.0025370169896632433,
      0.0358143113553524,
      -0.009709557518362999,
      -0.05075090005993843,
      -0.1120375320315361,
      0.062169596552848816,
      -0.024312011897563934,
      -0.017457013949751854,
      -0.000983544043265283,
      -0.12686176598072052,
      -0.07545285671949387,
      -0.0652812048792839,
      0.004635696765035391,
      -0.03765743225812912,
      -0.022649306803941727,
      -0.1020476445555687,
      -0.017039475962519646,
      -0.0027497841510921717,
      0.035945337265729904,
      0.03307007998228073,
      -0.09520695358514786,
      0.06705496460199356,
      0.04539346694946289,
      0.011041936464607716,
      -0.03941318392753601,
      -0.0061185178346931934,
      -0.0033354342449456453,
      -0.009874634444713593,
      0.0649121031165123,
      -0.004913011100143194,
      -0.03175366297364235,
      -0.02019089087843895,
      -0.011785564012825489,
      0.045133963227272034,
      -0.01729333959519863,
      -0.07024648785591125,
      0.09880855679512024,
      0.050357818603515625,
      0.016255823895335197,
      0.03848009184002876,
      0.0030457237735390663,
      0.04409310594201088,
      -0.05400123447179794,
      0.009789835661649704,
      0.07373566925525665,
      0.04848375916481018,
      0.04832427203655243,
      -0.006942204665392637,
      -0.0543954074382782,
      -0.01986309140920639,
      0.020682772621512413,
      0.021753180772066116,
      0.04148609936237335,
      0.004957901779562235,
      -0.049765974283218384,
      0.02356446534395218,
      0.03783845156431198,
      -0.03768761828541756,
      -0.039139196276664734,
      0.0036227507516741753
    ],
    [
      -0.010858465917408466,
      -0.005622547585517168,
      0.07877255976200104,
      0.00024159232270903885,
      0.08920527249574661,
      0.06648992002010345,
      -0.0011696876026690006,
      0.02024640142917633,
      -0.021811608225107193,
      -0.04925621300935745,
      0.12911677360534668,
      -0.0648559108376503,
      -0.04482415318489075,
      0.1040169969201088,
      0.005651974119246006,
      -0.016189172863960266,
      -0.016894308850169182,
      -0.0955762192606926,
      -0.035647451877593994,
      -0.00020456916536204517,
      -0.08794941008090973,
      -0.041957322508096695,
      -0.07364952564239502,
      -0.035057444125413895,
      -0.049727994948625565,
      0.03553611785173416,
      0.04697960987687111,
      0.02086102031171322,
      -0.026683416217565536,
      0.025884190574288368,
      0.033293381333351135,
      -0.024393906816840172,
      0.03596523031592369,
      0.10138743370771408,
      0.08287730067968369,
      -0.015251644887030125,
      0.00013290726928971708,
      -0.009463765658438206,
      0.052502796053886414,
      -0.05661356449127197,
      -0.09161070734262466,
      0.005993521306663752,
      -0.10668183863162994,
      -0.018776103854179382,
      -0.02176116779446602,
      -0.010928662493824959,
      -0.0034293930511921644,
      0.08316624909639359,
      0.08018356561660767,
      -0.007092195563018322,
      -0.03989608213305473,
      -0.00911409966647625,
      -0.002175817033275962,
      0.009774424135684967,
      0.0528823658823967,
      0.02735929936170578,
      0.05576825886964798,
      -0.012055758386850357,
      0.064230777323246,
      -0.022260356694459915,
      -0.020043572410941124,
      -0.06407299637794495,
      -0.01278720423579216,
      -0.02800232358276844,
      -0.0481339767575264,
      0.021855564787983894,
      0.0036604106426239014,
      -0.013348267413675785,
      0.06559379398822784,
      -0.011547340080142021,
      -0.006886777002364397,
      -0.04819703474640846,
      -0.03421887382864952,
      0.003944494295865297,
      -0.05166085436940193,
      0.07554630935192108,
      -0.04370714724063873,
      -0.05366068705916405,
      -0.023157915100455284,
      -0.06079097464680672,
      -0.005022294819355011,
      -0.02173789031803608,
      -0.0006913504330441356,
      -0.01961471699178219,
      0.0010556865017861128,
      0.018107902258634567,
      0.015747463330626488,
      0.09959722310304642,
      0.006391571834683418,
      -0.03278931602835655,
      0.00826333835721016,
      -0.034091707319021225,
      0.06992048025131226,
      0.009257357567548752,
      -0.0035135920625180006,
      0.050336804240942,
      -0.037316616624593735,
      0.04676021635532379,
      0.0038567553274333477,
      -0.0460401214659214,
      -0.002570078242570162,
      0.022706564515829086,
      -0.022910691797733307,
      -0.03561002388596535,
      0.07315889745950699,
      -0.05427918583154678,
      -0.010534320026636124,
      -0.002293817000463605,
      -0.062433529645204544,
      0.0037003483157604933,
      -0.047475606203079224,
      0.010618249885737896,
      0.004278039559721947,
      0.010437493212521076,
      0.03820202872157097,
      -0.012385023757815361,
      0.012742063030600548,
      0.024867039173841476,
      0.08900990337133408,
      0.10149280726909637,
      0.01681818999350071,
      -0.007076955400407314,
      -0.07812364399433136,
      0.014586187899112701,
      0.07249912619590759,
      -0.07044952362775803,
      -0.012364313937723637,
      -0.0023899779189378023,
      -0.014270496554672718,
      -0.007752015255391598,
      0.06837507337331772,
      0.005879044532775879,
      -0.0022708685137331486,
      0.007062390446662903,
      0.07837953418493271,
      -0.026938626542687416,
      -0.06924086809158325,
      -0.004554367624223232,
      0.015898633748292923,
      -0.03674750030040741,
      -0.011481394991278648,
      0.048343610018491745,
      0.11300932615995407,
      -0.05330074205994606,
      0.020102258771657944,
      -0.013654792681336403,
      -0.002949620597064495,
      0.015496249310672283,
      -0.12532728910446167,
      0.027899619191884995,
      0.023702530190348625,
      -0.008304739370942116,
      0.005229329690337181,
      0.0019346431363373995,
      0.005736683029681444,
      -0.049337971955537796,
      0.052891481667757034,
      0.02831168845295906,
      0.07193265110254288,
      -0.02268904447555542,
      -0.07092349976301193,
      0.02571694925427437,
      -0.007916620932519436,
      0.052656400948762894,
      0.039579179137945175,
      0.053736310452222824,
      -0.057135529816150665,
      -0.018393460661172867,
      -0.02169092558324337,
      0.0002578134590294212,
      -0.03345932066440582,
      -0.05087776854634285,
      0.020534636452794075,
      0.015583707019686699,
      0.04765814542770386,
      -0.027745982632040977,
      -0.04708388075232506,
      -0.017903178930282593,
      0.06409171223640442,
      -0.111455038189888,
      0.03748568147420883,
      0.0467098169028759,
      0.04628964141011238,
      -0.015529700554907322,
      0.02268986962735653,
      0.07810445874929428,
      -0.11607544869184494,
      -0.07686997950077057,
      0.0013325652107596397,
      -0.023865029215812683,
      0.0025080221239477396,
      0.03459014371037483,
      0.013267265632748604,
      -0.03981975093483925,
      -0.013266879133880138,
      0.05257740989327431,
      -0.03654266893863678,
      -0.007863296195864677,
      0.00432355934754014,
      0.03497062250971794,
      -0.011669295839965343,
      0.0013649878092110157,
      0.022162552922964096,
      0.03476167097687721,
      0.06718090921640396,
      0.0273470226675272,
      0.018404364585876465,
      0.014857000671327114,
      -0.0337311252951622,
      -0.03266491740942001,
      -0.012331699952483177,
      0.008894981816411018,
      -0.08999992161989212,
      0.011811042204499245,
      0.02328432910144329,
      -0.04745052382349968,
      0.04576091468334198,
      0.05370118468999863,
      0.05053245276212692,
      -0.054131679236888885,
      0.04990840703248978,
      0.03272801637649536,
      -0.031216677278280258,
      0.0977870300412178,
      -0.013374143280088902,
      0.026185208931565285,
      -0.007458828389644623,
      0.05183010548353195,
      -0.04711788892745972,
      -0.022227134555578232,
      0.027462966740131378,
      0.003703460330143571,
      0.011192893609404564,
      -0.02699345350265503,
      -0.06466355919837952,
      0.013541513122618198,
      0.02851700223982334,
      -0.10680683702230453,
      -0.059336546808481216,
      0.04547453671693802,
      -0.06503657251596451,
      -0.030368216335773468,
      0.05254504457116127,
      -0.04109252989292145,
      -0.022797558456659317,
      -0.04419036954641342,
      -0.008692516945302486,
      0.00907883234322071,
      0.05262663587927818,
      -0.055247675627470016,
      -0.0046885330229997635,
      -0.028096720576286316,
      -0.15185260772705078,
      0.0733666643500328,
      -0.11460398882627487,
      -0.04818689078092575,
      0.011778374202549458,
      0.0754409059882164,
      0.035043563693761826,
      0.007495699916034937,
      0.011494140140712261,
      -0.02998424880206585,
      0.023592352867126465,
      0.011179420165717602,
      0.020164813846349716,
      -0.06792975217103958,
      -0.009190043434500694,
      -0.005022524390369654,
      -0.03617160767316818,
      -0.03961651027202606,
      0.03523965924978256,
      0.03832537680864334,
      0.0875120609998703,
      0.008202102035284042,
      -0.0013650211039930582,
      -0.029186541214585304,
      -0.06742356717586517,
      0.024284956976771355,
      0.019987009465694427,
      0.0025684316642582417,
      -0.028577204793691635,
      -0.049889419227838516,
      -0.004352663177996874,
      0.005109468474984169,
      -0.023752814158797264,
      -0.05378550663590431,
      -0.03324577212333679,
      -0.015758145600557327,
      -0.04977986589074135,
      0.007952128536999226,
      0.06669847667217255,
      -0.07040218263864517,
      0.0013921450590714812,
      -0.012410136871039867,
      -0.03504721820354462,
      0.011141795665025711,
      0.0005257509765215218,
      0.035818785429000854,
      -0.007191421464085579,
      0.0608668215572834,
      -0.03967447206377983,
      0.03579190373420715,
      -0.03414461761713028,
      -0.0013541722437366843,
      0.018237188458442688,
      0.023094555363059044,
      -0.04084377735853195,
      0.12132800370454788,
      0.06052907183766365,
      0.044427964836359024,
      -0.11247989535331726,
      -0.01663236878812313,
      -0.01101677119731903,
      -0.016412725672125816,
      0.0298111941665411,
      0.007054856047034264,
      0.048546891659498215,
      0.05527869611978531,
      0.08569229394197464,
      0.017156191170215607,
      0.004969045985490084,
      -0.08516203612089157,
      -0.011630909517407417,
      0.014965285547077656,
      -0.004615886602550745,
      0.05808288976550102,
      -0.010821256786584854,
      0.05281892046332359,
      -0.06359526515007019,
      -0.11753810197114944,
      0.005127663724124432,
      0.020236730575561523,
      0.014782825484871864,
      -0.01982729509472847,
      0.026872241869568825,
      -0.06021568551659584,
      0.0022639608941972256,
      -0.07817371189594269,
      -0.0013877043966203928,
      0.0008528567850589752,
      -0.07374872267246246,
      -0.06879270821809769,
      -0.00905208382755518,
      0.036217100918293,
      0.013370105065405369,
      0.02043045312166214,
      -0.06096176430583,
      -0.02303728088736534,
      0.041815660893917084,
      -0.013200965709984303,
      0.05501176416873932,
      0.006035567726939917,
      0.02036707103252411,
      0.02826555445790291,
      -0.00750389602035284,
      -0.025218328461050987,
      -0.08698875457048416,
      -0.09293694794178009,
      -0.04492883011698723,
      0.051982298493385315,
      0.028791729360818863,
      -0.11725252121686935,
      -0.02837778814136982,
      -0.020905079320073128,
      0.02887839265167713,
      0.05939210578799248,
      -0.06377029418945312,
      0.06908027082681656,
      0.06452474743127823,
      -0.01824573613703251,
      0.0680871531367302,
      -0.006633973214775324,
      -0.014857999049127102,
      0.01325844693928957,
      0.11806859076023102,
      0.006691585294902325,
      -0.06962962448596954,
      -0.006685469765216112,
      -0.034540630877017975,
      0.11584837734699249,
      -0.03997569903731346,
      0.06420504301786423,
      0.026641150936484337,
      0.007937696762382984,
      -0.02687867358326912,
      0.04552079737186432,
      -0.028978001326322556,
      -0.09048975259065628,
      -0.05003059655427933,
      -0.013785053044557571,
      0.013399304822087288,
      -0.029553314670920372,
      0.003935310523957014,
      -0.04143289849162102,
      -0.008564624935388565,
      0.004213674459606409,
      -0.01876090094447136,
      0.0028999834321439266,
      0.021551355719566345,
      0.08335954695940018,
      0.038622256368398666,
      0.024044521152973175,
      -0.03678327426314354,
      -0.015555947087705135,
      0.00259413942694664,
      -0.04931597784161568,
      0.013342685997486115,
      0.0467897467315197,
      0.0186331644654274,
      -0.02606852911412716,
      -0.035309799015522,
      -0.006059031933546066,
      -0.06255555152893066,
      0.021628979593515396,
      -0.042375482618808746,
      -0.08466475456953049,
      0.018661174923181534,
      0.020323557779192924,
      0.06887310743331909,
      0.1028001457452774,
      -0.008097941987216473,
      -0.04991117864847183,
      0.02556542307138443,
      0.012693173252046108,
      0.004990737419575453,
      -0.01437717117369175,
      -0.03367745503783226,
      -0.03260615095496178,
      0.040466032922267914,
      -0.0879584327340126,
      0.04486469179391861,
      0.07679617404937744,
      -0.051689133048057556,
      -0.010484403930604458,
      -0.017925066873431206,
      -0.034915901720523834,
      0.02323410101234913,
      0.0017536550294607878,
      -0.08495623618364334,
      -0.00942107755690813,
      0.029477836564183235,
      0.055473703891038895,
      -0.00642255088314414,
      0.008831514976918697,
      0.057734690606594086,
      -0.017789211124181747,
      0.03014821745455265,
      -0.006076130550354719,
      0.006335371173918247,
      0.0036838047672063112,
      0.00023520809190813452,
      -0.026577617973089218,
      -0.07047548145055771,
      -0.052506186068058014,
      0.06568699330091476,
      -0.0644996389746666,
      0.013408910483121872,
      -0.025840401649475098,
      0.041172511875629425,
      -0.013060660101473331,
      -0.06490352749824524,
      0.03282507508993149,
      0.0319690965116024,
      -0.04522756114602089,
      0.008272722363471985,
      0.06403040140867233,
      -0.03142340108752251,
      0.01855762116611004,
      -0.01412484422326088,
      -0.01039884053170681,
      -0.08326489478349686,
      -0.01758265681564808,
      -0.09551173448562622,
      0.07720057666301727,
      0.004480372648686171,
      0.00343665131367743,
      0.006348354276269674,
      0.08088015764951706,
      -0.025007056072354317,
      -0.04220684617757797,
      0.052253980189561844,
      0.014949720352888107,
      -0.01998966932296753,
      -0.017570769414305687,
      0.02011280693113804,
      -0.08133377879858017,
      0.0009950163075700402,
      0.0036050782073289156,
      -0.01910487934947014,
      0.03441308066248894,
      0.04530453309416771,
      0.006323469802737236,
      0.10109413415193558,
      0.0006029648357070982,
      0.003244811436161399,
      -0.010237149894237518,
      -0.01748744025826454,
      -0.023304086178541183,
      -0.08045154809951782,
      -0.02711249515414238,
      0.005336489994078875,
      -0.003592699533328414,
      0.05867929384112358
    ],
    [
      0.07121241837739944,
      0.023182138800621033,
      0.00603755796328187,
      -0.01921340823173523,
      0.008814766071736813,
      -0.09430834650993347,
      -0.08760963380336761,
      0.0014893334591761231,
      0.04766374081373215,
      -0.018830450251698494,
      0.04452184960246086,
      -0.0320885106921196,
      0.07406363636255264,
      -0.07417632639408112,
      -0.005143813788890839,
      0.029750550165772438,
      0.0038950310554355383,
      0.010304865427315235,
      -0.05908769369125366,
      0.02888113260269165,
      0.028509490191936493,
      0.07265535742044449,
      -0.013921116478741169,
      -0.03398581221699715,
      0.02891748957335949,
      0.03370165079832077,
      0.009481540881097317,
      -0.08786056190729141,
      0.02021838165819645,
      0.01454299408942461,
      0.045632410794496536,
      -0.03454156219959259,
      0.050063882023096085,
      -0.013212094083428383,
      0.054284289479255676,
      -0.018745452165603638,
      -0.003282986581325531,
      -0.03579549491405487,
      -0.05107036232948303,
      0.0783466249704361,
      -0.026139087975025177,
      0.036785539239645004,
      0.0368722639977932,
      -0.09041672945022583,
      0.046808961778879166,
      0.027756039053201675,
      0.00989660993218422,
      -0.018606986850500107,
      0.04770459979772568,
      -0.05081276223063469,
      -0.00956416130065918,
      0.09843027591705322,
      -0.009356643073260784,
      0.017515845596790314,
      -0.026998957619071007,
      0.01805403269827366,
      0.056108783930540085,
      0.000778146437369287,
      0.05994400009512901,
      0.027769772335886955,
      0.01747927814722061,
      -0.012958592735230923,
      0.07082343101501465,
      0.02233176864683628,
      -0.11483925580978394,
      -0.08329755812883377,
      -0.02899811789393425,
      0.03546632453799248,
      0.0042755878530442715,
      -0.0650915578007698,
      -0.02063111402094364,
      0.03587828204035759,
      -0.038587719202041626,
      -0.024244792759418488,
      -0.005905725061893463,
      0.0025914234574884176,
      -0.014799837954342365,
      -0.027249233797192574,
      -0.0014342181384563446,
      -0.021596521139144897,
      0.046249181032180786,
      -0.04562770202755928,
      -0.0089829470962286,
      0.02527497336268425,
      0.00227867322973907,
      -0.03495292365550995,
      0.04675780609250069,
      -0.07186838239431381,
      -0.011139681562781334,
      -0.036535587161779404,
      -0.052128907293081284,
      -0.050160180777311325,
      0.09151479601860046,
      -0.017659394070506096,
      0.06972840428352356,
      0.020862950012087822,
      0.02620905451476574,
      0.04737132042646408,
      -0.013015568256378174,
      0.0005978302797302604,
      -0.08941510319709778,
      0.05740019679069519,
      0.004510783590376377,
      0.05685074254870415,
      -0.004193139728158712,
      0.024547096341848373,
      -0.018659692257642746,
      0.030840322375297546,
      -0.05708422139286995,
      0.005997857544571161,
      -0.04728836566209793,
      0.03242754191160202,
      0.05551499128341675,
      0.008673942647874355,
      0.11596895009279251,
      0.09653761237859726,
      0.012522702105343342,
      -0.018683061003684998,
      0.001185175497084856,
      0.03049270249903202,
      -0.03638070449233055,
      0.022634198889136314,
      -0.058638796210289,
      -0.018526365980505943,
      -0.025260671973228455,
      -0.012549149803817272,
      -0.009293034672737122,
      0.052139196544885635,
      0.04473935067653656,
      0.04301024600863457,
      -0.000965164857916534,
      -0.059023622423410416,
      0.011342033743858337,
      -0.046052608639001846,
      0.05284092575311661,
      0.0737067312002182,
      -0.03789267688989639,
      0.06119895353913307,
      -0.07143792510032654,
      -0.01573554426431656,
      0.018379544839262962,
      -0.02267417311668396,
      -0.052902549505233765,
      -0.018482478335499763,
      0.07602600753307343,
      0.018881121650338173,
      0.06098330765962601,
      -0.03205641731619835,
      0.024969080463051796,
      -0.026222174987196922,
      0.02021048404276371,
      -0.006128779612481594,
      -0.030914312228560448,
      -0.03523651137948036,
      0.09408442676067352,
      0.01792743243277073,
      -0.042231444269418716,
      -0.01638714410364628,
      -0.018473796546459198,
      0.03759322687983513,
      0.03314811736345291,
      0.021161232143640518,
      -0.017598820850253105,
      -0.04143781587481499,
      0.006679024081677198,
      -0.023897023871541023,
      -0.05490961670875549,
      -0.05889945849776268,
      -0.043523550033569336,
      -0.00686126621440053,
      0.03823918476700783,
      0.01586691103875637,
      0.025689929723739624,
      -0.0002678581222426146,
      -0.017927082255482674,
      0.009642505086958408,
      -0.03265094757080078,
      -0.10665810853242874,
      -0.06019839271903038,
      0.005656692199409008,
      -0.07686398923397064,
      0.04220355302095413,
      0.08949491381645203,
      0.026359545066952705,
      0.009319662116467953,
      0.016629967838525772,
      0.027376439422369003,
      -0.05017704889178276,
      -0.016358334571123123,
      0.026271464303135872,
      -0.0321686826646328,
      -0.02960915118455887,
      -0.023033060133457184,
      0.08552712202072144,
      -0.032978810369968414,
      -0.009947041980922222,
      0.07848192751407623,
      0.04379454627633095,
      0.06791063398122787,
      0.05862470716238022,
      -0.04127894714474678,
      -0.018092835322022438,
      -0.0905059427022934,
      0.03305993601679802,
      -0.07121606916189194,
      0.03330665081739426,
      0.010495039634406567,
      -0.019484933465719223,
      -0.03459639474749565,
      0.07506956905126572,
      -0.04589461907744408,
      0.028558902442455292,
      -0.06791283935308456,
      -0.006605350878089666,
      -0.01474855188280344,
      -0.009613437578082085,
      0.006185001228004694,
      0.004774921108037233,
      0.026304207742214203,
      -0.00630561588332057,
      0.019448045641183853,
      0.05016693100333214,
      -0.0551714263856411,
      0.018453780561685562,
      0.0258400309830904,
      -0.021726299077272415,
      -0.043270375579595566,
      -0.010092894546687603,
      -0.03674694150686264,
      0.09284011274576187,
      -0.003578424919396639,
      0.013073574751615524,
      -0.022355254739522934,
      0.014847862534224987,
      -0.024266645312309265,
      0.014824341051280499,
      -0.017387067899107933,
      0.054174941033124924,
      0.015021393075585365,
      -0.059639688581228256,
      -0.015427966602146626,
      -0.005730383098125458,
      0.026208672672510147,
      -0.04575270041823387,
      -0.017797311767935753,
      -0.008339038118720055,
      0.020388567820191383,
      0.008840454742312431,
      -0.007876439020037651,
      0.06066068261861801,
      0.04130171611905098,
      0.026701854541897774,
      -0.011847623623907566,
      -0.04446178674697876,
      0.042576517909765244,
      -0.005942078307271004,
      0.09124429523944855,
      0.04190457612276077,
      0.007232101634144783,
      -0.034770648926496506,
      0.055478937923908234,
      0.003751573618501425,
      0.006848292890936136,
      0.05494348704814911,
      0.03463253378868103,
      -0.09761027991771698,
      0.043526019901037216,
      0.03101893700659275,
      0.03289419412612915,
      -0.043509479612112045,
      -0.01662510633468628,
      0.09263422340154648,
      0.03586633503437042,
      -0.022709790617227554,
      0.0003885616606567055,
      0.018527686595916748,
      -0.007401021663099527,
      -0.060152195394039154,
      -0.05359123274683952,
      -0.04811130836606026,
      0.014236325398087502,
      -0.022099569439888,
      -0.055478405207395554,
      0.05386463925242424,
      -0.008005958050489426,
      -0.13607057929039001,
      -0.03980550169944763,
      0.011015993542969227,
      0.055467698723077774,
      0.009113864973187447,
      -0.005655449349433184,
      -0.04908788949251175,
      -0.012204604223370552,
      -0.02043955773115158,
      0.0012187183601781726,
      0.08670926094055176,
      0.005151877645403147,
      0.0447944737970829,
      0.019891804084181786,
      0.07396911084651947,
      -0.010779728181660175,
      0.047559548169374466,
      0.0356106273829937,
      -0.04728807881474495,
      0.03547985106706619,
      -0.028238074854016304,
      0.07423289865255356,
      0.05299200117588043,
      0.03959665820002556,
      -0.05476587638258934,
      -0.028049470856785774,
      -0.012964267283678055,
      -0.007249108050018549,
      0.0670953169465065,
      0.019367164000868797,
      -0.03650228679180145,
      -0.08931132405996323,
      -0.016032278537750244,
      0.08688486367464066,
      0.038419127464294434,
      0.05676819011569023,
      -0.04264232516288757,
      0.07013725489377975,
      0.0773770660161972,
      0.003983912989497185,
      -0.0887608453631401,
      -0.04962267726659775,
      0.03298543393611908,
      0.03715609014034271,
      -0.02299073338508606,
      0.042261190712451935,
      0.045313842594623566,
      -0.01264727208763361,
      0.03695136308670044,
      -0.034956637769937515,
      -0.03554602339863777,
      0.06561017036437988,
      -0.029404601082205772,
      0.06232711300253868,
      0.08067924529314041,
      -0.03635937720537186,
      -0.04029719904065132,
      0.041018713265657425,
      0.0026703167241066694,
      -0.0560695044696331,
      0.1002902016043663,
      -0.04957647621631622,
      0.028329530730843544,
      -0.06198890879750252,
      0.014033641666173935,
      0.026477279141545296,
      0.01068806555122137,
      -0.04851727932691574,
      -0.024725299328565598,
      0.08323003351688385,
      0.07513824850320816,
      0.034756798297166824,
      -0.03268835321068764,
      0.02913927659392357,
      -0.012749198824167252,
      -0.01273988839238882,
      -0.028338219970464706,
      0.01732408069074154,
      -0.06020181253552437,
      0.029221391305327415,
      -0.05858464911580086,
      0.023042190819978714,
      0.01050413679331541,
      -0.014829970896244049,
      0.07289709895849228,
      0.02281177043914795,
      0.05981171876192093,
      -0.06189535930752754,
      0.07635553181171417,
      -0.03854846581816673,
      0.01677955687046051,
      -0.06485213339328766,
      -0.016516026109457016,
      -0.008778984658420086,
      -0.021760398522019386,
      -0.055893734097480774,
      -0.05811546370387077,
      0.00966386403888464,
      0.009561981074512005,
      0.024584442377090454,
      -0.08727061003446579,
      0.04466019570827484,
      0.011710546910762787,
      0.006766827777028084,
      0.046123206615448,
      -0.02476496994495392,
      -0.05371095985174179,
      -0.024933354929089546,
      -0.047444336116313934,
      -0.051797810941934586,
      0.0504748597741127,
      -0.01304751168936491,
      -0.040166325867176056,
      -0.023027721792459488,
      0.020105700939893723,
      0.04587409272789955,
      0.03171613812446594,
      -0.04537786915898323,
      0.018628517165780067,
      -0.06185472011566162,
      0.02953019179403782,
      -0.07092857360839844,
      0.013453545980155468,
      0.07754362374544144,
      -0.030176708474755287,
      -0.019870378077030182,
      0.02554355002939701,
      -0.07255586981773376,
      0.042592618614435196,
      -0.04195655882358551,
      -0.051038432866334915,
      -0.12164201587438583,
      0.01311492919921875,
      -0.05194370076060295,
      0.091394804418087,
      0.05082352086901665,
      0.018191348761320114,
      -0.07520119100809097,
      0.020188666880130768,
      0.00020193778618704528,
      -0.04414523020386696,
      0.010448863729834557,
      0.09585373848676682,
      -0.033613041043281555,
      0.03984345123171806,
      0.03770318254828453,
      0.06043509393930435,
      -0.019455112516880035,
      0.028837816789746284,
      0.05091580003499985,
      0.005332623142749071,
      -0.037975531071424484,
      0.028732890263199806,
      -0.09240145236253738,
      0.0525677464902401,
      -0.06827428936958313,
      0.03283555060625076,
      0.01375490054488182,
      0.06470721960067749,
      -0.04318426921963692,
      -0.0360594168305397,
      -0.010586227290332317,
      0.017650408670306206,
      -0.07594218850135803,
      0.06271760165691376,
      -0.0009173433063551784,
      -0.005574382375925779,
      0.05843029543757439,
      0.0265089962631464,
      -0.03783680498600006,
      0.041058022528886795,
      -0.03782733157277107,
      -0.0549420602619648,
      -0.008852832950651646,
      -0.04469330236315727,
      0.04412653669714928,
      0.05257093161344528,
      -0.04045184329152107,
      -0.01595003716647625,
      0.0008284474024549127,
      -0.059374433010816574,
      0.03633025288581848,
      0.04246783256530762,
      -0.0271706935018301,
      0.04354935884475708,
      -0.018904877826571465,
      0.04243665188550949,
      -0.021805454045534134,
      0.013516074977815151,
      0.008980095386505127,
      0.03922458365559578,
      0.12421336024999619,
      0.08273335546255112,
      -0.00598910404369235,
      0.033621110022068024,
      0.012550712563097477,
      -0.016147315502166748,
      -0.029619397595524788,
      0.04388599842786789,
      -0.008818783797323704,
      0.029253672808408737,
      0.08479250222444534,
      -0.007898741401731968,
      0.04550110548734665,
      -0.008367049507796764,
      -0.023781195282936096,
      0.07006540149450302,
      -0.07969662547111511,
      0.04810469225049019,
      0.050946976989507675,
      0.11644043773412704,
      0.06627286970615387,
      -0.040974151343107224,
      -0.023540282621979713,
      0.04206262528896332,
      0.006071805953979492,
      0.04404248669743538,
      -0.05377691611647606
    ],
    [
      -0.04042992740869522,
      -0.01873266138136387,
      -0.02662249654531479,
      -0.008646966889500618,
      0.0004237165558151901,
      -0.004890213254839182,
      -0.003396228887140751,
      0.05325464904308319,
      -0.0581362247467041,
      -0.042508985847234726,
      0.04087390750646591,
      -0.032245077192783356,
      -0.03179828077554703,
      0.011494607664644718,
      -0.033468130975961685,
      0.03830026462674141,
      -0.0022110308054834604,
      -0.05435684323310852,
      -0.04394148290157318,
      0.08004393428564072,
      -0.01349977683275938,
      -0.026973044499754906,
      -0.12917765974998474,
      0.027270231395959854,
      0.01773858070373535,
      0.14214302599430084,
      0.0008229652885347605,
      0.022676896303892136,
      0.041448626667261124,
      0.1091684103012085,
      -0.032994162291288376,
      -0.06394004076719284,
      0.06773190200328827,
      -0.0002927004243247211,
      0.020741652697324753,
      0.04215509444475174,
      -0.0005822323146276176,
      0.015580053441226482,
      0.04820683225989342,
      0.08247643709182739,
      0.005224284250289202,
      -0.02663310244679451,
      0.044764354825019836,
      -0.01700517348945141,
      -0.1510707587003708,
      0.03739070147275925,
      0.030843641608953476,
      -0.01455814577639103,
      0.03107420541346073,
      0.01931821182370186,
      -0.00747255003079772,
      0.002014477038756013,
      -0.051663145422935486,
      0.049579158425331116,
      0.0313456766307354,
      -0.046537380665540695,
      -0.07476520538330078,
      -0.032542116940021515,
      -0.034828346222639084,
      -0.0832001268863678,
      0.04808076098561287,
      -0.0012660417705774307,
      0.03150085359811783,
      0.018549855798482895,
      -0.052418895065784454,
      -0.025799071416258812,
      0.0066342405043542385,
      0.04227966442704201,
      0.06557012349367142,
      -0.08951614797115326,
      -0.010951879434287548,
      0.039553239941596985,
      0.06711356341838837,
      0.09322715550661087,
      0.06575191766023636,
      0.04342323914170265,
      -0.007140719331800938,
      -0.07776481658220291,
      0.02369469217956066,
      -0.011730672791600227,
      0.03735833987593651,
      0.008150354027748108,
      0.016611339524388313,
      -0.004222870338708162,
      0.002070000162348151,
      -0.011514704674482346,
      -0.004530421923846006,
      0.04227816313505173,
      0.015857478603720665,
      0.029381945729255676,
      -0.0459582544863224,
      0.00210147094912827,
      0.03063284046947956,
      -0.04879253730177879,
      0.018272975459694862,
      -0.05934085696935654,
      -0.023377373814582825,
      0.00039442870183847845,
      -0.023507462814450264,
      -0.05725877732038498,
      -0.06154174730181694,
      0.01742330752313137,
      -0.01788429543375969,
      0.02597496472299099,
      -0.045115187764167786,
      -0.013064432889223099,
      -0.031183527782559395,
      0.04379547759890556,
      0.06094437837600708,
      -0.002678033197298646,
      0.012302577495574951,
      0.038959283381700516,
      0.016378717496991158,
      0.04845109209418297,
      -0.14922809600830078,
      0.004577405285090208,
      -0.017270155251026154,
      -0.02197420224547386,
      -0.005896809510886669,
      -0.02746131829917431,
      -0.013631015084683895,
      -0.06715036928653717,
      0.020708007737994194,
      -0.060657717287540436,
      0.08178480714559555,
      0.09095634520053864,
      -0.04377509281039238,
      0.021555237472057343,
      -0.011007350869476795,
      -0.07729678601026535,
      -0.003214154625311494,
      -0.12532183527946472,
      -0.01703532412648201,
      0.018431080505251884,
      0.01259677205234766,
      -0.05724943429231644,
      -0.036921437829732895,
      -0.02145766280591488,
      -0.011757656931877136,
      0.004128194879740477,
      -0.033281873911619186,
      -0.06325774639844894,
      -0.0070765395648777485,
      0.03603380173444748,
      0.0025849230587482452,
      0.024721873924136162,
      0.019596271216869354,
      0.04976597800850868,
      -0.005107831209897995,
      0.014313065446913242,
      0.006993322633206844,
      0.009885909035801888,
      0.01071801595389843,
      -0.006825222168117762,
      0.034540653228759766,
      0.018047671765089035,
      0.055762507021427155,
      0.07013017684221268,
      -0.00955034326761961,
      0.00038453927845694125,
      -0.01691732369363308,
      -0.03569076210260391,
      -0.039185456931591034,
      -0.059304676949977875,
      -0.012717006728053093,
      0.07122166454792023,
      0.04489482566714287,
      -0.057103194296360016,
      0.031316936016082764,
      0.01650436595082283,
      -0.03508412837982178,
      0.013303264044225216,
      0.0192909874022007,
      -0.03396820276975632,
      0.005817095283418894,
      0.045408040285110474,
      0.04963747039437294,
      -0.02483076974749565,
      0.044315844774246216,
      -0.012092911638319492,
      -0.052318137139081955,
      -0.0013101103249937296,
      0.03013594262301922,
      0.020191553980112076,
      -0.027636967599391937,
      0.01978605054318905,
      -0.03943894803524017,
      -0.10133816301822662,
      0.03169025480747223,
      -0.045676592737436295,
      -0.01816801354289055,
      0.00215791049413383,
      -0.05376939848065376,
      0.02516419254243374,
      -0.026847410947084427,
      0.022596532478928566,
      0.06445742398500443,
      0.05144606903195381,
      -0.01142046507447958,
      0.028264347463846207,
      -0.04364157095551491,
      0.008192908018827438,
      0.01260358840227127,
      0.044249165803194046,
      -0.01486008521169424,
      -0.060926008969545364,
      0.058013834059238434,
      -0.04898281395435333,
      -0.03764805197715759,
      0.012137050740420818,
      -0.03258052095770836,
      0.015330230817198753,
      -0.03639809414744377,
      -0.05855293199419975,
      -0.05233161896467209,
      0.07825235277414322,
      -0.07357703894376755,
      0.009879929013550282,
      -0.03477412462234497,
      -0.05551113188266754,
      -0.019245583564043045,
      -0.006518457550555468,
      -0.025064906105399132,
      0.025956187397241592,
      -0.035009581595659256,
      -0.04635554924607277,
      -0.060471661388874054,
      0.02027032896876335,
      0.0025397087447345257,
      -0.009613852947950363,
      -0.014359277673065662,
      -0.0072112660855054855,
      0.03497835993766785,
      -0.01372568216174841,
      -0.04867401719093323,
      -0.056686218827962875,
      0.05003758519887924,
      -0.01607431285083294,
      -0.021793102845549583,
      -0.04519566521048546,
      0.01658094860613346,
      -0.05443977192044258,
      0.021299706771969795,
      0.01724330335855484,
      -0.002892819233238697,
      -0.0314326249063015,
      0.03133926913142204,
      0.020459046587347984,
      -0.135555237531662,
      0.13985979557037354,
      0.02979142591357231,
      0.01621428318321705,
      -0.10775808990001678,
      -0.02275300770998001,
      0.04576544091105461,
      0.0005135844694450498,
      -0.05111616104841232,
      0.02041028067469597,
      0.02075398899614811,
      -0.02458401769399643,
      -0.018671028316020966,
      -0.010934967547655106,
      0.028484970331192017,
      -0.0441153459250927,
      0.04043754190206528,
      0.05438704416155815,
      -0.0015984703786671162,
      0.012264678254723549,
      0.001862927689217031,
      -0.016846789047122,
      0.05017386004328728,
      -0.05503462255001068,
      -0.04041704162955284,
      -0.0052946521900594234,
      0.0032572404015809298,
      0.042506489902734756,
      0.0707627460360527,
      -0.013881410472095013,
      -0.030781863257288933,
      -0.018555190414190292,
      0.05069242790341377,
      -0.005366527009755373,
      -0.07863667607307434,
      0.04616659879684448,
      -0.02474227175116539,
      -0.014773407019674778,
      0.034557044506073,
      -0.010078792460262775,
      -0.04676739126443863,
      -0.010228298604488373,
      0.041773028671741486,
      -0.035062335431575775,
      0.029879529029130936,
      0.004002991132438183,
      -0.02498151920735836,
      0.002996262861415744,
      -0.02818877249956131,
      -0.03667044639587402,
      0.03441919758915901,
      0.050304923206567764,
      -0.064796082675457,
      0.053147051483392715,
      -0.040688835084438324,
      0.012193133123219013,
      -0.03322143480181694,
      -0.000625178508926183,
      -0.004891619086265564,
      0.032081060111522675,
      -0.029153719544410706,
      -0.03725467622280121,
      -0.017748340964317322,
      0.017984095960855484,
      0.01587103120982647,
      -0.04396996647119522,
      0.04760446026921272,
      0.027474144473671913,
      0.024382317438721657,
      -0.02325780875980854,
      -0.0037401332519948483,
      -0.07804164290428162,
      0.006470827851444483,
      -0.05630018562078476,
      0.0016339049907401204,
      -0.021381063386797905,
      0.024073198437690735,
      0.040642451494932175,
      -0.010854322463274002,
      -0.008672392927110195,
      0.04607245326042175,
      0.026850782334804535,
      0.1015547439455986,
      -0.06400220841169357,
      0.09582266211509705,
      0.054068878293037415,
      -0.0069317021407186985,
      0.0487813726067543,
      0.031587954610586166,
      0.008687165565788746,
      0.08667375147342682,
      -0.080457903444767,
      -0.04730043187737465,
      -0.014695968478918076,
      0.001743155880831182,
      0.03514351695775986,
      0.004855671431869268,
      0.07897710055112839,
      -0.01255854032933712,
      0.16317018866539001,
      0.031076744198799133,
      -0.03260417655110359,
      -0.0014282960910350084,
      -0.0602249912917614,
      0.02682751789689064,
      -0.014304055832326412,
      0.0279653612524271,
      -0.0406799390912056,
      0.043090883642435074,
      0.0033857901580631733,
      -0.0159919373691082,
      -0.007839495316147804,
      -0.020108746364712715,
      0.14506126940250397,
      0.06160399317741394,
      0.03221076354384422,
      -0.11648391932249069,
      0.07190480083227158,
      0.03308006003499031,
      0.02444530464708805,
      -0.08541614562273026,
      0.05793670937418938,
      0.009632561355829239,
      0.04321214556694031,
      -0.001040167873725295,
      -0.08206800371408463,
      -0.03189997002482414,
      -0.01992260478436947,
      -0.02105717919766903,
      0.0355038158595562,
      -0.022413508966565132,
      0.004513532388955355,
      -0.05790124461054802,
      0.011901442892849445,
      -0.0012320514069870114,
      -0.0005609713261947036,
      -0.04311053082346916,
      0.03152080625295639,
      0.019603516906499863,
      0.020920634269714355,
      0.09565234184265137,
      -0.08376627415418625,
      0.0022079255431890488,
      -0.0023365484084933996,
      -0.008706443011760712,
      0.0005255337455309927,
      0.07361806929111481,
      0.07327399402856827,
      -0.0428670197725296,
      0.004657040350139141,
      0.009957012720406055,
      0.018264304846525192,
      -0.061073027551174164,
      -0.025207092985510826,
      -0.038589149713516235,
      -0.011520965956151485,
      -0.09266374260187149,
      0.0766315832734108,
      -0.0701272189617157,
      -0.0008136449032463133,
      0.007986091077327728,
      0.007599744480103254,
      -0.02712317556142807,
      -0.0017166334437206388,
      -0.04596070200204849,
      0.0271229799836874,
      -0.021825198084115982,
      0.018880588933825493,
      0.021351056173443794,
      0.017081335186958313,
      -0.037205640226602554,
      0.09485357254743576,
      -0.07890255004167557,
      0.034814830869436264,
      -0.02530030347406864,
      -0.014369931071996689,
      0.009909002110362053,
      0.08609466254711151,
      -0.03247971087694168,
      -0.04324493557214737,
      0.09016437828540802,
      0.03491592779755592,
      0.054582588374614716,
      0.05286458879709244,
      -0.039522238075733185,
      -0.0018517179414629936,
      0.010748458094894886,
      0.0692545622587204,
      0.08232712000608444,
      0.039636917412281036,
      -0.023968931287527084,
      -0.07085274904966354,
      -0.04702544957399368,
      -0.026692846789956093,
      -0.0949954241514206,
      0.004763273522257805,
      0.005815335549414158,
      0.11456120014190674,
      -0.022516660392284393,
      0.02620151825249195,
      -0.0015326584689319134,
      0.04099460691213608,
      0.04584991931915283,
      -0.02143043465912342,
      -0.032463498413562775,
      0.0007360462914220989,
      -0.03176148235797882,
      0.07695509493350983,
      0.061245258897542953,
      -0.08728119730949402,
      -0.0027462479192763567,
      0.05156324803829193,
      0.025355029851198196,
      -0.01664455607533455,
      0.05944383516907692,
      0.05011561140418053,
      -0.002692762529477477,
      0.0015034632524475455,
      -0.006986286956816912,
      0.01737590879201889,
      0.06371179968118668,
      -0.06270232796669006,
      -0.05671335384249687,
      0.04605254530906677,
      -0.03866245225071907,
      -0.042131632566452026,
      0.025038577616214752,
      0.02396579086780548,
      0.08306130021810532,
      -0.007460309658199549,
      0.016614414751529694,
      -0.022357676178216934,
      -0.006264060270041227,
      -0.09873892366886139,
      -0.04953945428133011,
      0.040280744433403015,
      0.01811468042433262,
      0.03916173428297043,
      0.05436210334300995,
      0.05687209218740463,
      0.03439518064260483,
      0.016331112012267113,
      -0.10210315883159637,
      0.013857587240636349,
      0.07245383411645889,
      0.12304341048002243,
      -0.05438770726323128,
      -0.05902278050780296,
      -0.010647272691130638,
      -0.02146851271390915,
      -0.06335404515266418,
      -0.058188825845718384,
      0.01035701297223568,
      0.006955174263566732,
      -0.053483568131923676
    ],
    [
      -0.08228809386491776,
      0.08450295776128769,
      -0.03993712365627289,
      0.0024261949583888054,
      -0.037006817758083344,
      0.019537072628736496,
      0.0004773623659275472,
      0.018485818058252335,
      -0.012961819767951965,
      0.04176388680934906,
      -0.033768024295568466,
      -0.10134630650281906,
      0.051508400589227676,
      0.01946244202554226,
      0.033128853887319565,
      -0.03042808547616005,
      -0.060892004519701004,
      0.009586107917129993,
      0.02050265483558178,
      -0.028120357543230057,
      0.0016441164771094918,
      -0.04934219643473625,
      0.10690294951200485,
      0.0652114674448967,
      -0.021812396124005318,
      -0.04136639088392258,
      -0.014513092115521431,
      0.040752626955509186,
      -0.011653326451778412,
      -0.03547893837094307,
      0.004078166093677282,
      0.012218309566378593,
      -0.005229874048382044,
      -0.04522538557648659,
      0.055652692914009094,
      -0.026851525530219078,
      0.0618574395775795,
      -0.01809193566441536,
      -0.0058666858822107315,
      -0.04527866095304489,
      -0.01386560034006834,
      -0.006288697477430105,
      -0.05534014478325844,
      -0.03337706997990608,
      0.017108222469687462,
      0.049870796501636505,
      0.07653900235891342,
      -0.03934251889586449,
      0.05398919805884361,
      0.05405488610267639,
      -0.0472555048763752,
      0.11222394555807114,
      -0.007373931352049112,
      0.012529010884463787,
      0.03501652926206589,
      0.045892633497714996,
      -0.00057606166228652,
      0.07789129763841629,
      -0.028185509145259857,
      0.06445509940385818,
      0.07245698571205139,
      0.03378284350037575,
      0.03303530067205429,
      -0.08879578113555908,
      0.05365656688809395,
      0.04539797082543373,
      -0.03385032340884209,
      0.030543828383088112,
      0.005634242668747902,
      0.06685978174209595,
      0.07051444053649902,
      -0.058624859899282455,
      -0.006628703325986862,
      0.009905073791742325,
      -0.07548338919878006,
      0.05134223401546478,
      0.011191098019480705,
      -0.029493102803826332,
      0.020705562084913254,
      -0.006468307226896286,
      0.030509306117892265,
      0.032973915338516235,
      0.020807266235351562,
      -0.037452585995197296,
      0.05225880816578865,
      -0.0033324777614325285,
      -0.006219221279025078,
      -0.025397127494215965,
      0.06626949459314346,
      -0.054699141532182693,
      7.434502913383767e-05,
      0.025271626189351082,
      -0.012528989464044571,
      -0.015440508723258972,
      -0.0034829939249902964,
      0.01556169893592596,
      0.02828310802578926,
      -0.024246523156762123,
      -0.04765303432941437,
      0.01908118650317192,
      -0.00639755604788661,
      -0.020715195685625076,
      -0.01819578930735588,
      0.016953781247138977,
      -0.03495442122220993,
      -0.13322961330413818,
      0.0006813259096816182,
      0.032078616321086884,
      0.027843523770570755,
      -0.05813251808285713,
      0.04768279939889908,
      -0.021992316469550133,
      0.0004269387573003769,
      -0.022904345765709877,
      0.06797409802675247,
      -0.024011336266994476,
      -0.028709853067994118,
      -0.05532081052660942,
      -0.004189704079180956,
      -0.02868175134062767,
      0.020304087549448013,
      0.07234413921833038,
      -0.02785305306315422,
      0.03303106874227524,
      0.028296051546931267,
      -0.042671915143728256,
      -0.08492725342512131,
      0.00594365643337369,
      -0.03930483013391495,
      0.03357115760445595,
      -0.03182066231966019,
      0.03267674148082733,
      -0.016093581914901733,
      -0.037905264645814896,
      0.014162402600049973,
      -0.016079595312476158,
      -0.0031126972753554583,
      0.05126936733722687,
      -0.003638742258772254,
      0.05683266744017601,
      0.0305999293923378,
      -0.042898278683423996,
      0.009622829034924507,
      -0.005521545186638832,
      -0.001354522886686027,
      0.019333424046635628,
      0.01649903506040573,
      0.02551109716296196,
      0.036420147866010666,
      0.04958619922399521,
      -0.032729119062423706,
      0.12238528579473495,
      0.03397021070122719,
      0.06352067738771439,
      0.04658207669854164,
      -0.03943249210715294,
      0.04159905016422272,
      -0.04049296677112579,
      -0.0746498703956604,
      0.02568388171494007,
      0.03637582063674927,
      0.08802881091833115,
      0.04101115092635155,
      -0.006747845094650984,
      0.035273950546979904,
      0.004141072276979685,
      -0.06738008558750153,
      -0.03702681139111519,
      -0.05107637122273445,
      -0.06782984733581543,
      0.024311192333698273,
      0.07355402410030365,
      -0.02961224690079689,
      0.010098839178681374,
      -0.04869489744305611,
      0.023037290200591087,
      0.04206293448805809,
      -0.01568315364420414,
      0.03925268352031708,
      0.02981376089155674,
      -0.053741175681352615,
      -0.001741718384437263,
      -0.01876005530357361,
      0.0829390287399292,
      0.03935854882001877,
      -0.0382976271212101,
      0.049177393317222595,
      0.022690745070576668,
      -0.058386024087667465,
      0.006208199076354504,
      -0.02581498585641384,
      0.00674540875479579,
      -0.013229007832705975,
      -0.010834167711436749,
      -0.004976216237992048,
      -0.015049375593662262,
      -0.010409128852188587,
      0.00979636237025261,
      0.03802821412682533,
      0.011770715937018394,
      0.09338238835334778,
      0.09086702018976212,
      -0.031420640647411346,
      -0.03314011171460152,
      0.051977045834064484,
      0.035044677555561066,
      -0.011079349555075169,
      -0.07533913105726242,
      -0.09681804478168488,
      -0.032879941165447235,
      -0.03839454427361488,
      -0.0071698324754834175,
      0.024355439469218254,
      0.05314100161194801,
      -0.05603618547320366,
      0.08025313913822174,
      0.0453554205596447,
      0.0143141383305192,
      0.03678283467888832,
      -0.08320893347263336,
      0.0781991109251976,
      -0.03359818831086159,
      -0.0058950562961399555,
      -0.03450120985507965,
      0.06945202499628067,
      -0.04136880114674568,
      -0.06227704510092735,
      -0.05159236118197441,
      -0.04098429158329964,
      -0.058241356164216995,
      0.06769577413797379,
      0.030721062794327736,
      0.08605720847845078,
      -0.013625011779367924,
      0.007101060822606087,
      0.016204366460442543,
      -0.0120908347889781,
      -0.030325308442115784,
      0.010516173206269741,
      -0.027063483372330666,
      -0.0888654887676239,
      -0.042606573551893234,
      0.024900168180465698,
      0.04734119772911072,
      0.04212809354066849,
      0.021160224452614784,
      -0.042597394436597824,
      0.06853102892637253,
      0.036562640219926834,
      -0.028461070731282234,
      0.0430082343518734,
      0.010832526721060276,
      0.020522473379969597,
      -0.05587401241064072,
      -0.03919852524995804,
      0.025083499029278755,
      0.04014328867197037,
      -0.06810875236988068,
      -0.10266577452421188,
      -0.021366816014051437,
      0.05809753015637398,
      -0.00682064238935709,
      0.045125674456357956,
      0.08045694977045059,
      -0.0457783080637455,
      -0.10944587737321854,
      0.05223540961742401,
      -0.05861371010541916,
      -0.022666219621896744,
      -0.025146180763840675,
      -0.01661689765751362,
      -0.025916287675499916,
      -0.03665274381637573,
      0.029154151678085327,
      0.01372077688574791,
      0.008997039869427681,
      0.0002829127188306302,
      -0.02796795219182968,
      0.031075233593583107,
      -0.016586363315582275,
      0.019523480907082558,
      0.02303505688905716,
      0.10164059698581696,
      0.12134035676717758,
      0.02209005318582058,
      -0.015627354383468628,
      -0.06727499514818192,
      0.06288737803697586,
      0.021694820374250412,
      0.05919989198446274,
      0.015204275958240032,
      -0.03248230740427971,
      -0.09637119621038437,
      0.030797425657510757,
      -0.019387798383831978,
      -0.0430796816945076,
      0.055163100361824036,
      -0.003607200924307108,
      0.03432274982333183,
      -0.009438961744308472,
      -0.027920031920075417,
      6.203782686498016e-05,
      0.026246393099427223,
      -0.01672547496855259,
      0.044140543788671494,
      -0.019054442644119263,
      0.02553042210638523,
      -0.035115186125040054,
      -0.059201430529356,
      0.006185510661453009,
      -0.035213977098464966,
      0.08557794243097305,
      0.02249976247549057,
      -0.07676699757575989,
      -0.05224126949906349,
      -0.008816145360469818,
      -0.029521126300096512,
      -0.056307997554540634,
      0.09122885763645172,
      0.001956477528437972,
      0.008727068081498146,
      -0.017293382436037064,
      -0.012107077986001968,
      -0.0024516419507563114,
      0.08107316493988037,
      0.049792416393756866,
      0.058072760701179504,
      0.013649571686983109,
      -0.017299076542258263,
      -0.046509601175785065,
      0.02633357048034668,
      0.016683507710695267,
      0.03989547863602638,
      -0.03561393916606903,
      -0.04767448082566261,
      0.022920891642570496,
      -0.04570101574063301,
      -0.02790278010070324,
      -0.027217505499720573,
      0.05880481004714966,
      -0.0316648855805397,
      -0.03231669217348099,
      0.01409029308706522,
      0.07293026894330978,
      -0.025556087493896484,
      -0.03738374635577202,
      0.019183708354830742,
      0.05093921720981598,
      0.00788105372339487,
      0.013427117839455605,
      -0.03866444528102875,
      0.06883841753005981,
      0.03987488895654678,
      0.04168101027607918,
      0.0031853083055466413,
      -0.12459652870893478,
      0.027833476662635803,
      0.03273116052150726,
      0.001282347016967833,
      -0.057777661830186844,
      0.02469535730779171,
      -0.04971308261156082,
      -0.008202404715120792,
      0.004588299430906773,
      -0.021121177822351456,
      -0.03493744507431984,
      -0.04287179931998253,
      -0.011716006323695183,
      0.035960324108600616,
      -0.041946303099393845,
      -0.02077564038336277,
      -0.026543838903307915,
      -0.025353949517011642,
      -0.017432067543268204,
      -0.08796723932027817,
      0.007631558924913406,
      -0.04768480733036995,
      -0.00412787776440382,
      0.1005900502204895,
      0.07607466727495193,
      0.028040213510394096,
      0.03183401748538017,
      -0.0495976023375988,
      0.04204043373465538,
      -0.08710034191608429,
      0.05509733781218529,
      0.016797514632344246,
      0.01723443530499935,
      -0.01530732586979866,
      0.052574507892131805,
      -0.03107631765305996,
      -0.061579007655382156,
      0.011106959544122219,
      0.02623317390680313,
      -0.04259536415338516,
      0.05234669893980026,
      0.036303889006376266,
      0.003276198636740446,
      0.05062658339738846,
      0.03991907089948654,
      0.03347579762339592,
      -0.0023972848430275917,
      0.03812524303793907,
      0.041675493121147156,
      0.07673264294862747,
      -0.07170501351356506,
      -0.033458027988672256,
      -0.008873208425939083,
      0.03827040642499924,
      -0.021702241152524948,
      -0.01883997954428196,
      0.03557277098298073,
      0.012646103277802467,
      0.02698083035647869,
      -0.11968054622411728,
      0.057690784335136414,
      -0.018950512632727623,
      -0.05403457209467888,
      0.032856330275535583,
      -0.05265619605779648,
      0.041001707315444946,
      0.06123685464262962,
      0.044726040214300156,
      -0.05292610079050064,
      0.00987046118825674,
      0.03159264102578163,
      0.0547868050634861,
      0.09693846106529236,
      -0.03862159326672554,
      -0.009958557784557343,
      -0.03916085138916969,
      -0.04558868706226349,
      -0.10719653218984604,
      0.031069470569491386,
      -0.01977667398750782,
      0.06371532380580902,
      0.029942629858851433,
      0.10390926152467728,
      0.025892429053783417,
      -0.017119552940130234,
      0.03492249548435211,
      -0.004056921228766441,
      -0.0077635012567043304,
      0.026294149458408356,
      0.0470556914806366,
      0.006687265820801258,
      -0.014289292506873608,
      -0.01462516374886036,
      -0.003905568039044738,
      0.022167714312672615,
      0.038332391530275345,
      -0.04358987510204315,
      -0.11642865091562271,
      -0.029451895505189896,
      0.0645507425069809,
      0.04803859069943428,
      0.015234376303851604,
      -0.05656657740473747,
      -0.001144233625382185,
      -0.014204420149326324,
      0.01910153031349182,
      0.026558533310890198,
      0.022329555824398994,
      0.0035257635172456503,
      0.13504758477210999,
      0.05628783255815506,
      0.05253840237855911,
      0.05003224313259125,
      0.031111057847738266,
      -0.030217090621590614,
      0.015428459271788597,
      -0.0350298173725605,
      0.030070863664150238,
      0.0022394396364688873,
      0.02132883481681347,
      -0.003672930644825101,
      -0.00834876112639904,
      0.09956791251897812,
      0.13762037456035614,
      -0.06461727619171143,
      -0.03226708620786667,
      -0.04221953824162483,
      0.11652854084968567,
      -0.0534658320248127,
      0.012130862101912498,
      -0.04333006218075752,
      -0.09450560063123703,
      -0.0448811799287796,
      0.012432767078280449,
      0.04225494712591171,
      0.0269742701202631,
      -0.01610506698489189,
      -0.08385086804628372,
      0.04120272397994995,
      0.03987111151218414,
      -0.08696325123310089,
      -0.02241724170744419,
      0.07398654520511627,
      0.03731565922498703,
      0.025230372324585915,
      -0.07130874693393707,
      0.07489350438117981,
      0.07054580003023148
    ],
    [
      0.026092592626810074,
      -0.08577779680490494,
      0.020358458161354065,
      0.01596071943640709,
      0.05170667544007301,
      0.015572980046272278,
      -0.04736310616135597,
      0.005710113327950239,
      0.04960176721215248,
      -0.0014438903890550137,
      -0.009239045903086662,
      -0.022223399952054024,
      0.03932195156812668,
      0.006890001241117716,
      -0.06545684486627579,
      -0.09587384015321732,
      -0.008519403636455536,
      -0.03660314157605171,
      -0.032450634986162186,
      -0.01616981253027916,
      0.029445791617035866,
      -0.03402625396847725,
      -0.026746930554509163,
      -0.041525743901729584,
      0.09639254212379456,
      0.011497631669044495,
      -0.014956671744585037,
      -0.05055241659283638,
      -0.005578021984547377,
      0.060735251754522324,
      -0.013737722299993038,
      0.05673108249902725,
      -0.06225290149450302,
      0.04345160350203514,
      -0.10302063822746277,
      -0.0050087422132492065,
      -0.01158701442182064,
      0.006375108379870653,
      -0.017025582492351532,
      -0.010664580389857292,
      -0.008547798730432987,
      0.06428030878305435,
      0.0036997192073613405,
      -0.0006671854644082487,
      0.0425480492413044,
      -0.026191245764493942,
      -0.049457721412181854,
      0.06299708038568497,
      0.0208884309977293,
      -0.0072911879979074,
      -0.04310917481780052,
      0.04914504662156105,
      -0.006194862071424723,
      -0.0009002977167256176,
      -0.02060697041451931,
      0.012508613988757133,
      0.049267154186964035,
      0.028411339968442917,
      0.026416867971420288,
      -0.09527984261512756,
      0.01714252308011055,
      -0.03406110033392906,
      -0.0021645964588969946,
      0.007246298249810934,
      -0.07572817802429199,
      0.023774201050400734,
      0.005264804698526859,
      0.01908871904015541,
      -0.0007656879024580121,
      0.0944683626294136,
      0.0006632264121435583,
      -0.03513814136385918,
      -0.08782283216714859,
      -0.06607798486948013,
      0.0231881495565176,
      -0.03963032737374306,
      0.012621673755347729,
      -0.07600010931491852,
      0.01386226061731577,
      0.013026702217757702,
      0.051130007952451706,
      0.010071168653666973,
      0.020065337419509888,
      0.010934105142951012,
      -0.021632136777043343,
      -0.0963842049241066,
      -0.04296780750155449,
      0.008916730061173439,
      0.06470682471990585,
      -0.04020981118083,
      0.04590054601430893,
      -0.0012309241574257612,
      0.03818419575691223,
      0.08200227469205856,
      -0.026873866096138954,
      -0.014310842379927635,
      -0.01162334717810154,
      -0.05793750286102295,
      0.0454997643828392,
      -0.11309953033924103,
      0.09147959202528,
      -0.03226201981306076,
      -0.04405472055077553,
      0.011582926847040653,
      0.06483067572116852,
      0.06779540330171585,
      -0.005826422944664955,
      -0.09045262634754181,
      0.09044501185417175,
      0.05971265956759453,
      -0.013518774881958961,
      -0.05043218284845352,
      -0.015763798728585243,
      -0.026597145944833755,
      -0.04482274129986763,
      -0.027599195018410683,
      0.02164539322257042,
      -0.003331622341647744,
      0.06004699692130089,
      -0.0441320575773716,
      0.0553797110915184,
      -0.004784826654940844,
      -0.064427949488163,
      0.0185775738209486,
      -0.018108662217855453,
      0.013563230633735657,
      0.06125635281205177,
      0.00033763403189368546,
      -0.03434234857559204,
      -0.028118068352341652,
      -0.10374297201633453,
      0.0013348383363336325,
      -0.009424288757145405,
      -0.06044214963912964,
      -0.026237666606903076,
      0.0222025029361248,
      0.06892306357622147,
      0.0173973236232996,
      0.011241152882575989,
      -0.07146109640598297,
      -0.04200459271669388,
      0.011076845228672028,
      -0.07391039282083511,
      -0.08299829065799713,
      -0.0007828397210687399,
      0.06422066688537598,
      -0.016059834510087967,
      -0.003677596803754568,
      -0.0026272169779986143,
      -0.09970878809690475,
      0.05742025747895241,
      -0.06675222516059875,
      -0.10655798017978668,
      0.06879299879074097,
      -0.009362093172967434,
      0.07586707919836044,
      0.059765126556158066,
      -0.0625651553273201,
      0.05080590397119522,
      -0.08062063902616501,
      0.026411592960357666,
      0.01299615390598774,
      -0.07271705567836761,
      0.05138541758060455,
      -0.03052343986928463,
      0.023805024102330208,
      0.029020875692367554,
      -0.03781389072537422,
      -0.056705500930547714,
      -0.009451151825487614,
      0.018773594871163368,
      0.040816761553287506,
      -0.07617863267660141,
      0.029033062979578972,
      0.08752703666687012,
      -0.043583910912275314,
      0.05637006461620331,
      0.017315082252025604,
      -0.008232099004089832,
      -0.06855431199073792,
      -0.014755012467503548,
      0.0026048757135868073,
      0.05103939771652222,
      0.02806227281689644,
      -0.022401222959160805,
      0.07727553695440292,
      0.035755958408117294,
      -0.03138282150030136,
      -0.04714975133538246,
      0.04843152314424515,
      0.04311098903417587,
      -0.03571194037795067,
      -0.04544255509972572,
      -0.03801564872264862,
      -0.030324457213282585,
      -0.03570957109332085,
      -0.015022511593997478,
      0.031308695673942566,
      0.06177464500069618,
      0.08609418570995331,
      0.04062241315841675,
      -0.005698072724044323,
      -0.0052245007827878,
      0.08785524219274521,
      0.047392334789037704,
      -0.11186031252145767,
      0.08611433207988739,
      0.029329199343919754,
      -0.048566900193691254,
      0.0696420669555664,
      -0.061871904879808426,
      0.021802416071295738,
      0.004414453636854887,
      -0.0032583987340331078,
      -0.0022587229032069445,
      -0.012069339863955975,
      -0.10294285416603088,
      0.05324753373861313,
      0.0008517226669937372,
      0.003933291882276535,
      0.08152998983860016,
      0.02087811566889286,
      -0.10316627472639084,
      -0.031177591532468796,
      0.09194517135620117,
      0.03369498252868652,
      -0.0014917185762897134,
      -0.06758666783571243,
      -0.04514305666089058,
      0.08153928816318512,
      0.06885043531656265,
      0.03355846926569939,
      0.0617830790579319,
      -0.011377952992916107,
      -0.02917700819671154,
      -0.043597668409347534,
      -0.002258396940305829,
      -0.10759422183036804,
      0.03304234892129898,
      0.0022381541784852743,
      0.029079267755150795,
      0.01831889897584915,
      0.03804165497422218,
      -0.05720353126525879,
      -0.020644238218665123,
      0.018324842676520348,
      0.008336864411830902,
      0.01164984330534935,
      -0.044102948158979416,
      -0.034189388155937195,
      -0.0362204946577549,
      0.04974878206849098,
      -0.06268000602722168,
      -0.05359939485788345,
      0.030617646872997284,
      0.0603400282561779,
      0.006291875150054693,
      0.04003264009952545,
      -0.11935306340456009,
      0.008145804516971111,
      0.02737933211028576,
      0.0647110864520073,
      -0.005393456667661667,
      0.03728806599974632,
      -0.0998380035161972,
      0.046329643577337265,
      -0.11533861607313156,
      0.07846155017614365,
      -0.025638168677687645,
      -0.011666123755276203,
      -0.018241237848997116,
      -0.033659543842077255,
      0.029711199924349785,
      -0.015207464806735516,
      0.06800689548254013,
      0.00889753270894289,
      0.010649418458342552,
      -0.006696541793644428,
      0.022488629445433617,
      -0.012307326309382915,
      0.041184116154909134,
      0.10936852544546127,
      -0.00684061273932457,
      -0.0035895227920264006,
      0.015735793858766556,
      -0.013653803616762161,
      -0.0743527039885521,
      0.06592744588851929,
      0.06602694094181061,
      -0.018459834158420563,
      0.03235402703285217,
      -0.055029500275850296,
      -0.009498940780758858,
      0.051899321377277374,
      -0.06930158287286758,
      0.012560393661260605,
      -0.009191581979393959,
      0.05283717066049576,
      -0.07990481704473495,
      0.05972922593355179,
      -0.019505422562360764,
      0.0005313682486303151,
      0.02363022044301033,
      -0.048726052045822144,
      0.018076492473483086,
      -0.03660183027386665,
      0.07186853885650635,
      -0.03411789610981941,
      0.02278270572423935,
      -0.034785836935043335,
      -0.052561089396476746,
      -0.06275937706232071,
      0.07774774730205536,
      -0.005456557963043451,
      0.036141857504844666,
      -0.037242669612169266,
      0.03262409195303917,
      0.055450402200222015,
      0.014598392881453037,
      -0.024075224995613098,
      0.030455490574240685,
      -0.06088879331946373,
      0.05620812997221947,
      -0.060811713337898254,
      -0.014007053337991238,
      -0.06458211690187454,
      0.030905408784747124,
      -0.05462298542261124,
      0.10105510801076889,
      -0.0358155183494091,
      -0.0109744593501091,
      0.07083916664123535,
      0.004662216641008854,
      0.0660429298877716,
      -0.02138454094529152,
      0.049303002655506134,
      0.03426080942153931,
      -0.0577707514166832,
      0.14954181015491486,
      0.07246861606836319,
      0.06032257527112961,
      -0.023982079699635506,
      -0.028163325041532516,
      0.026460858061909676,
      0.028922291472554207,
      -0.07242722809314728,
      -0.07309846580028534,
      0.05012249946594238,
      0.0040640560910105705,
      0.05391540005803108,
      -0.013931354507803917,
      0.00014772481517866254,
      -0.035558268427848816,
      0.041604962199926376,
      -0.001595658715814352,
      -0.06672117114067078,
      -0.056324079632759094,
      0.030647389590740204,
      0.056418709456920624,
      -0.038749273866415024,
      0.035672932863235474,
      0.04867163673043251,
      -0.017490651458501816,
      0.007610163651406765,
      -0.004959404934197664,
      0.006370414048433304,
      -0.018658900633454323,
      0.005459397099912167,
      0.004897675942629576,
      -0.025837641209363937,
      -0.026307152584195137,
      0.005845432169735432,
      -0.07173888385295868,
      0.06778495013713837,
      0.009370612911880016,
      -0.011640614829957485,
      0.03588332235813141,
      -0.024875197559595108,
      0.020105157047510147,
      -0.08374641090631485,
      -0.08937306702136993,
      -0.005578694865107536,
      0.016648583114147186,
      0.02446889318525791,
      0.11376732587814331,
      0.016616178676486015,
      0.011682087555527687,
      -0.031387485563755035,
      0.06598788499832153,
      0.0067069632932543755,
      0.02458401955664158,
      0.045240484178066254,
      0.009715777821838856,
      -0.033156074583530426,
      0.005952190607786179,
      0.03299003094434738,
      -0.030062003061175346,
      0.05859896168112755,
      0.059145279228687286,
      -0.006958305835723877,
      -0.022759757936000824,
      -0.027150841429829597,
      0.008736003190279007,
      0.002298474544659257,
      -0.07418868690729141,
      0.06084289029240608,
      -0.002999732503667474,
      0.0865156501531601,
      0.03325721248984337,
      0.05362821742892265,
      -0.0018686488037928939,
      0.014429343864321709,
      -0.01283473614603281,
      0.012824821285903454,
      -0.027437875047326088,
      -0.06827259808778763,
      0.033928874880075455,
      -0.057460129261016846,
      0.03884097933769226,
      0.053161174058914185,
      -0.05179772898554802,
      -0.018934665247797966,
      0.073554627597332,
      0.01729644276201725,
      0.039332494139671326,
      -0.00965830311179161,
      0.029870403930544853,
      -0.06221487373113632,
      -0.05000312626361847,
      0.07252377271652222,
      -0.01739119365811348,
      0.01100555993616581,
      0.04840647801756859,
      0.08813027292490005,
      -0.020201539620757103,
      -0.004770818166434765,
      -0.004880499094724655,
      0.07756871730089188,
      0.055503539741039276,
      0.04105595126748085,
      0.031183714047074318,
      0.02660038135945797,
      0.029462434351444244,
      -0.0028796489350497723,
      -0.042584579437971115,
      -0.06476052850484848,
      0.06409527361392975,
      -0.008976656943559647,
      0.009871800430119038,
      0.02524019218981266,
      0.08809684962034225,
      -0.003961375914514065,
      0.06270097941160202,
      0.05269477516412735,
      -0.09325607120990753,
      -0.030588407069444656,
      0.017581705003976822,
      -0.03382458910346031,
      -0.02142759971320629,
      -0.0030136408749967813,
      -0.0024259197525680065,
      -0.061897989362478256,
      -0.044427599757909775,
      0.09219162911176682,
      0.09045318514108658,
      -0.0582512803375721,
      0.030010277405381203,
      0.0052595073357224464,
      5.004451850254554e-06,
      -0.01610848307609558,
      -0.10006861388683319,
      -0.015291227027773857,
      -0.0576959103345871,
      0.016370324417948723,
      -0.014794784598052502,
      0.09524205327033997,
      -0.00415366655215621,
      0.0491882748901844,
      0.024579182267189026,
      -0.0028398381546139717,
      -0.0014224599581211805,
      0.01895662397146225,
      -0.05481324717402458,
      0.05735103785991669,
      -0.006958262529224157,
      0.01777171716094017,
      0.02153315395116806,
      -0.039892666041851044,
      -0.07613368332386017,
      0.0033648661337792873,
      0.003688416676595807,
      -0.03869025036692619,
      0.03205159306526184,
      0.007947957143187523,
      0.10128320753574371,
      0.07765691727399826,
      -0.011471062898635864,
      -0.01173999160528183,
      0.017497530207037926,
      0.004308394156396389,
      0.022434545680880547,
      0.04360104352235794,
      0.01724846661090851
    ],
    [
      -0.056692954152822495,
      -0.012768393382430077,
      -0.005093328654766083,
      -0.0019359587458893657,
      0.005126486532390118,
      0.0145805599167943,
      -0.07044186443090439,
      0.0505705326795578,
      0.053002238273620605,
      0.00030452662031166255,
      -0.04444205388426781,
      -0.04274632781744003,
      0.002175639383494854,
      -0.013689877465367317,
      0.038129743188619614,
      0.019429299980401993,
      0.00017554094665683806,
      0.0016676035011187196,
      0.06513498723506927,
      0.018448900431394577,
      -0.03516428917646408,
      0.03695766627788544,
      0.061302851885557175,
      0.12702681124210358,
      -0.06765685975551605,
      0.03720808029174805,
      -0.09099038690328598,
      0.10174737125635147,
      0.005823937244713306,
      -0.09083019942045212,
      -0.002968351822346449,
      -0.01004101149737835,
      -0.03348376974463463,
      -0.01711399294435978,
      0.042033325880765915,
      -0.024850280955433846,
      0.06955859810113907,
      -0.0488673597574234,
      -0.029295438900589943,
      -0.00922864768654108,
      -0.05933532491326332,
      -0.003801792860031128,
      -0.03789658099412918,
      -0.002077131997793913,
      -0.014643169939517975,
      0.006849324330687523,
      0.06073153018951416,
      -0.031771063804626465,
      0.048898015171289444,
      -0.04116065800189972,
      -0.10705099999904633,
      0.11244848370552063,
      0.02780691161751747,
      -0.008435441181063652,
      -0.03751535341143608,
      0.03585235774517059,
      0.042973343282938004,
      0.0076241763308644295,
      -0.059955038130283356,
      0.023387860506772995,
      0.04730379953980446,
      0.013299794867634773,
      0.010584881529211998,
      -0.029667427763342857,
      -0.039574697613716125,
      -0.012322490103542805,
      -0.029924744740128517,
      0.00013583565305452794,
      0.01479589194059372,
      -0.0830557569861412,
      0.041103545576334,
      -0.05036347359418869,
      0.019030949100852013,
      0.07021281123161316,
      -0.004489704966545105,
      0.003744589164853096,
      -0.0426688976585865,
      -0.03400144726037979,
      0.07787946611642838,
      0.0033940174616873264,
      0.016129011288285255,
      -0.048862893134355545,
      -0.009579889476299286,
      -0.03838757798075676,
      -0.05196467787027359,
      0.07514886558055878,
      0.009178323671221733,
      -0.01818424090743065,
      0.03848685324192047,
      0.04169868677854538,
      -0.009558552876114845,
      -0.03911988064646721,
      0.04476024582982063,
      0.02060651034116745,
      0.059321045875549316,
      -0.03685889020562172,
      0.0507778599858284,
      0.014388786628842354,
      -0.06096291169524193,
      0.0782896876335144,
      0.036251965910196304,
      -0.05605171620845795,
      -0.033781539648771286,
      0.013240267522633076,
      -0.009588680230081081,
      0.025789527222514153,
      0.021614069119095802,
      -0.03896644711494446,
      -0.06113655865192413,
      0.0635138750076294,
      0.015009451657533646,
      0.05484167858958244,
      -0.08357853442430496,
      -0.022313907742500305,
      0.024045564234256744,
      0.07759544998407364,
      0.03308646380901337,
      0.0218829195946455,
      0.010823606513440609,
      -0.0027011441998183727,
      0.14227847754955292,
      0.0272735096514225,
      -0.01895766891539097,
      0.007868354208767414,
      0.06388860195875168,
      0.06661541759967804,
      0.06350412219762802,
      0.006704432889819145,
      -0.03490658849477768,
      0.041342925280332565,
      -0.024809274822473526,
      0.09916720539331436,
      0.05195484310388565,
      0.03448358178138733,
      0.004202389623969793,
      -0.05244096368551254,
      -0.03272254019975662,
      0.0017903564730659127,
      0.001508065382950008,
      0.03141868859529495,
      0.054073769599199295,
      0.03209194913506508,
      0.04650731012225151,
      0.011908660642802715,
      -0.04839572310447693,
      -0.01217660866677761,
      -0.017155306413769722,
      -0.015172374434769154,
      -0.04743382707238197,
      -0.04863598570227623,
      0.04313239827752113,
      -0.007581367157399654,
      -0.0063212257809937,
      0.015709146857261658,
      -0.016412004828453064,
      -0.08772367238998413,
      -0.0023332738783210516,
      0.020060552284121513,
      -0.031018923968076706,
      0.02197101339697838,
      -0.015349671244621277,
      -0.0918160080909729,
      -0.010705558583140373,
      -0.0708174854516983,
      -0.015083607286214828,
      0.045875970274209976,
      -0.0037841584999114275,
      -0.05612102895975113,
      -0.07221389561891556,
      -0.023103222250938416,
      -0.0340023934841156,
      0.03193817660212517,
      0.021141694858670235,
      -0.06845661997795105,
      -0.012100453488528728,
      0.0621434822678566,
      -0.10537935048341751,
      0.01770072616636753,
      0.08759580552577972,
      -0.04542876407504082,
      -0.08477211743593216,
      0.01853637397289276,
      -0.00026395771419629455,
      0.010622826404869556,
      0.030536308884620667,
      0.04959382861852646,
      -0.011783340014517307,
      -0.1008165031671524,
      -0.0014124051667749882,
      -0.04848960414528847,
      -0.031724151223897934,
      0.020671330392360687,
      -0.014315703883767128,
      0.007910245098173618,
      -0.010447167791426182,
      -0.030605006963014603,
      0.029185468330979347,
      0.036544956266880035,
      0.00651585403829813,
      0.031312234699726105,
      -0.07163451611995697,
      0.05965903773903847,
      -0.01731300540268421,
      0.09336597472429276,
      -0.007527640555053949,
      0.0012243188684806228,
      0.008059201762080193,
      -0.0026567878667265177,
      -0.021133335307240486,
      -0.09048591554164886,
      0.04143523797392845,
      0.003207988338544965,
      -0.03970504179596901,
      0.044504858553409576,
      0.008931273594498634,
      -0.035494543612003326,
      -0.057622045278549194,
      0.10560867935419083,
      0.05851835384964943,
      0.006682576611638069,
      0.012701991945505142,
      -0.016252633184194565,
      -0.02082403004169464,
      0.02530726231634617,
      0.024377480149269104,
      -0.013351415283977985,
      0.06639483571052551,
      0.026720624417066574,
      -0.06251820176839828,
      -0.04065345227718353,
      0.05989208072423935,
      -0.00720978993922472,
      -0.00231678388081491,
      0.007261112332344055,
      -0.06310046464204788,
      0.056818172335624695,
      0.07405799627304077,
      0.09256761521100998,
      -0.019851792603731155,
      -0.024289144203066826,
      0.006539338268339634,
      -0.02186725288629532,
      0.016844747588038445,
      -0.003577908268198371,
      -0.03227616101503372,
      -0.01598895899951458,
      0.06286604702472687,
      0.0087673868983984,
      -0.03619220480322838,
      -0.014632794074714184,
      -0.052809230983257294,
      -0.07881665229797363,
      0.04815371334552765,
      -0.05023203045129776,
      0.027431830763816833,
      0.07312951982021332,
      -0.03116636537015438,
      0.028388552367687225,
      -0.0034285555593669415,
      0.005293227732181549,
      0.025999242439866066,
      0.05330698564648628,
      0.07984968274831772,
      -0.04967295005917549,
      0.0017959417309612036,
      0.04115450009703636,
      -0.00811037514358759,
      0.046445753425359726,
      0.06527060270309448,
      0.07090815901756287,
      0.052201706916093826,
      0.023968685418367386,
      0.03655001148581505,
      0.038667943328619,
      -0.017191652208566666,
      0.03243253007531166,
      -0.026175709441304207,
      -0.005616819951683283,
      -0.05120699852705002,
      0.016140036284923553,
      0.00374492141418159,
      0.010701652616262436,
      -0.004567819181829691,
      -0.018197983503341675,
      0.08721161633729935,
      -0.044320713728666306,
      -0.021720176562666893,
      0.051342498511075974,
      -0.008040751330554485,
      -0.06028655916452408,
      0.022452764213085175,
      0.08224412053823471,
      -0.020391404628753662,
      0.023191725835204124,
      -0.014955331571400166,
      -0.009433935396373272,
      -0.045560672879219055,
      -0.0005940147675573826,
      0.07387803494930267,
      -0.06014622002840042,
      -0.019206993281841278,
      -0.06133021041750908,
      0.028035668656229973,
      -0.026605524122714996,
      -4.067162808496505e-05,
      -0.04944831505417824,
      0.024485282599925995,
      -0.007327830884605646,
      -0.008464551530778408,
      -0.09652339667081833,
      0.043714094907045364,
      -0.013729242607951164,
      -0.02325299382209778,
      -0.014380850829184055,
      -0.03328109532594681,
      0.029725801199674606,
      -0.03787143528461456,
      -0.0018122297478839755,
      0.031691014766693115,
      -0.032119784504175186,
      -0.04989105463027954,
      0.014546516351401806,
      0.017182961106300354,
      -0.0421573631465435,
      0.05701248720288277,
      0.018808022141456604,
      -0.016656585037708282,
      0.0489661768078804,
      -0.09869103133678436,
      -0.0509718656539917,
      -0.022662974894046783,
      -0.015478248707950115,
      -0.029994679614901543,
      -0.076960489153862,
      0.03298206254839897,
      -0.01055498979985714,
      0.015002954751253128,
      0.032947976142168045,
      -0.023943182080984116,
      -0.022471914067864418,
      0.0037717297673225403,
      -0.006433159578591585,
      -0.008952241390943527,
      -0.03276190161705017,
      0.0068996259942650795,
      0.038376037031412125,
      0.020709030330181122,
      -0.023255478590726852,
      -0.019796250388026237,
      0.0301711093634367,
      0.05166560411453247,
      0.07999738305807114,
      0.018537454307079315,
      0.014015711843967438,
      0.06037924811244011,
      0.0022509063128381968,
      -0.03287629410624504,
      0.026131300255656242,
      0.0012530185049399734,
      0.029466602951288223,
      0.058589477092027664,
      0.0013571921736001968,
      -0.013620562851428986,
      -0.011271466501057148,
      -0.040339190512895584,
      0.012566526420414448,
      0.054306671023368835,
      -0.03372785076498985,
      -0.011944238096475601,
      -0.06611952185630798,
      0.03913652151823044,
      -0.027061238884925842,
      -0.0780189037322998,
      -0.025710484012961388,
      0.059443674981594086,
      0.002516594948247075,
      -0.10683828592300415,
      -0.033358365297317505,
      -0.003665571566671133,
      -0.0243056770414114,
      0.014819561503827572,
      0.012691664509475231,
      0.03926866501569748,
      0.007208698429167271,
      -0.018196556717157364,
      0.038438018411397934,
      -0.044134195894002914,
      -0.007211912889033556,
      0.032967258244752884,
      0.008254214189946651,
      -0.01925395429134369,
      0.019071662798523903,
      -0.0763973668217659,
      -0.070374496281147,
      -0.024810954928398132,
      -0.020459378138184547,
      -0.04683918505907059,
      0.031005674973130226,
      0.06365390121936798,
      0.03972197324037552,
      0.07890789210796356,
      0.047814492136240005,
      0.039334993809461594,
      -7.1847447543405e-05,
      0.005769788753241301,
      -0.03661637008190155,
      0.003318398492410779,
      -0.033655647188425064,
      -0.03644051402807236,
      -0.03918105363845825,
      0.005306728184223175,
      -0.07402369379997253,
      -0.05626218020915985,
      -0.030009688809514046,
      0.008717057295143604,
      -0.028674187138676643,
      -0.07285543531179428,
      0.03184745833277702,
      0.03050597570836544,
      0.00735941156744957,
      0.09150399267673492,
      0.004251502454280853,
      -0.020018111914396286,
      -0.057230524718761444,
      -0.006664651446044445,
      0.004062258638441563,
      0.007962831296026707,
      0.05067668855190277,
      -0.059018492698669434,
      -0.0910678431391716,
      0.01765512302517891,
      0.012103740125894547,
      -0.0067973751574754715,
      -0.07472282648086548,
      0.0013618676457554102,
      0.005664768163114786,
      0.038188762962818146,
      0.006818010937422514,
      -0.07622300833463669,
      0.020281746983528137,
      0.10173843055963516,
      0.013954674825072289,
      0.0899900421500206,
      0.0024017204996198416,
      0.11398068070411682,
      -0.0547221377491951,
      0.01083439402282238,
      0.04875906556844711,
      -0.035605721175670624,
      -0.03653823584318161,
      0.040324289351701736,
      0.02205168455839157,
      0.01271575316786766,
      -0.011524634435772896,
      -0.020531201735138893,
      0.00973058957606554,
      -0.03876286745071411,
      0.07200392335653305,
      0.015881286934018135,
      -0.045234229415655136,
      -0.019810236990451813,
      0.01287137158215046,
      -0.027530940249562263,
      0.07002170383930206,
      -0.04975089803338051,
      0.04201684147119522,
      -0.04039468616247177,
      -0.044188227504491806,
      0.07389185577630997,
      0.07173971086740494,
      -0.08048434555530548,
      -0.021536387503147125,
      -0.009022755548357964,
      0.03598838672041893,
      -0.03565102443099022,
      0.005511880852282047,
      -0.02963901124894619,
      -0.018458664417266846,
      0.0039005964063107967,
      -0.07145232707262039,
      0.04118119180202484,
      -0.020802751183509827,
      -0.0182795412838459,
      0.055290743708610535,
      0.01584968902170658,
      -0.015744369477033615,
      -0.016746819019317627,
      0.021953469142317772,
      0.013181510381400585,
      -0.05207320675253868,
      0.04143421724438667,
      -0.05323387309908867,
      -0.0013675166992470622,
      -0.0032617351971566677,
      -0.0027823052369058132,
      -0.05851779505610466,
      -0.02563300170004368,
      0.06564828008413315,
      -0.048887159675359726,
      -0.06682690232992172,
      -0.05537168309092522,
      0.06401190906763077,
      0.04995758458971977
    ],
    [
      0.03896836191415787,
      0.06424202769994736,
      0.0020332352723926306,
      -0.003715425031259656,
      0.05523260310292244,
      0.025200091302394867,
      -0.045614298433065414,
      0.0025163190439343452,
      0.05652937665581703,
      -0.08659321814775467,
      -0.0021011242642998695,
      -0.06725215911865234,
      0.011920145712792873,
      0.0004036605532746762,
      0.010445951484143734,
      -0.056911759078502655,
      -0.017825104296207428,
      -0.016953863203525543,
      0.008694794028997421,
      -0.10135462135076523,
      -0.0038672233931720257,
      -0.021404772996902466,
      0.0012977190781384706,
      -0.0336596816778183,
      0.0289192833006382,
      0.012343402951955795,
      -0.052374254912137985,
      0.07016719877719879,
      0.013095946051180363,
      0.004276780411601067,
      0.0908631905913353,
      -0.05529927462339401,
      0.04620091989636421,
      -0.04445552080869675,
      0.03697572275996208,
      -0.04674442484974861,
      0.0005967600154690444,
      -0.009872477501630783,
      0.006607521325349808,
      -0.0066726007498800755,
      0.013200776651501656,
      0.06502244621515274,
      -0.05201175436377525,
      0.007987088523805141,
      0.05968714505434036,
      0.01625266671180725,
      0.06918885558843613,
      0.04838003218173981,
      -0.00217477441765368,
      0.026107240468263626,
      -0.02495383843779564,
      0.04266834631562233,
      -0.01758989877998829,
      0.04997224733233452,
      -0.04302607849240303,
      0.034697480499744415,
      -0.024714229628443718,
      0.030875632539391518,
      -0.05780941992998123,
      -0.011778819374740124,
      -0.1048969104886055,
      -0.01524163968861103,
      0.06394675374031067,
      -0.01776994951069355,
      -0.01447147037833929,
      -0.06002281233668327,
      0.02836894430220127,
      -0.012591688893735409,
      0.056681789457798004,
      0.01606537029147148,
      -0.08139778673648834,
      0.035476747900247574,
      -0.036890771239995956,
      -0.0008558299159631133,
      -0.01440341118723154,
      -0.07487483322620392,
      -0.03803231939673424,
      -0.05753257870674133,
      -0.005749360658228397,
      0.040462739765644073,
      -0.023581188172101974,
      -0.05818929895758629,
      0.03364529833197594,
      -0.06657297164201736,
      0.028879402205348015,
      0.024909600615501404,
      0.015433153137564659,
      0.02259109914302826,
      0.021130196750164032,
      -0.0032356830779463053,
      0.009465210139751434,
      -0.11356592923402786,
      0.029060445725917816,
      0.0074658263474702835,
      0.08008197695016861,
      -0.04078985005617142,
      0.0021457523107528687,
      -0.05671640485525131,
      -0.01964241825044155,
      -0.005575059447437525,
      -0.0454518124461174,
      0.0321844257414341,
      -0.05443274602293968,
      0.012788905762135983,
      0.0922691822052002,
      0.03398115932941437,
      -0.039594054222106934,
      0.013452805578708649,
      0.006884818896651268,
      0.06373696774244308,
      0.04416718706488609,
      0.03143712133169174,
      -0.020596947520971298,
      0.00043282832484692335,
      -0.04660964757204056,
      0.025010205805301666,
      -0.08645293116569519,
      0.01671959087252617,
      0.029451923444867134,
      -0.010486030951142311,
      -0.01399135310202837,
      -0.08202912658452988,
      -0.002134917536750436,
      0.03383783623576164,
      0.008231375366449356,
      0.026877881959080696,
      -0.017137892544269562,
      -0.04079689830541611,
      0.022426895797252655,
      -0.00012303169933147728,
      0.029375307261943817,
      0.013075522147119045,
      -0.0239606611430645,
      0.08044049143791199,
      -0.07915966212749481,
      -0.0032826114911586046,
      -0.013938591815531254,
      0.020330160856246948,
      0.026615319773554802,
      -0.011884531006217003,
      -0.012499573640525341,
      -0.027595948427915573,
      -0.005703086964786053,
      0.004126225598156452,
      -0.07095827907323837,
      0.024567002430558205,
      0.00818725861608982,
      -0.020924201235175133,
      0.014156135730445385,
      -0.06286467611789703,
      -0.05214591696858406,
      -0.024504845961928368,
      -0.024734215810894966,
      0.03017059713602066,
      0.0666152611374855,
      0.05861865356564522,
      -0.11298400163650513,
      0.005714289378374815,
      -0.008737565018236637,
      0.019202712923288345,
      0.08022510260343552,
      -0.005456578452140093,
      -0.06422951817512512,
      -0.09278945624828339,
      -0.051139649003744125,
      -0.0899527445435524,
      -0.025557000190019608,
      -0.03304852545261383,
      -0.07098478078842163,
      0.019062625244259834,
      0.02910950779914856,
      0.02499924786388874,
      0.026272574439644814,
      -0.06519179791212082,
      -0.07473006844520569,
      -0.06475941836833954,
      0.008207450620830059,
      -0.04881729930639267,
      0.0014451657189056277,
      -0.003586476668715477,
      0.07041517645120621,
      -0.010554683394730091,
      -0.007942895404994488,
      0.1155024915933609,
      0.028142189607024193,
      -0.004883272107690573,
      -0.020970314741134644,
      -0.018844878301024437,
      0.04594722017645836,
      -0.02588147483766079,
      0.02287493832409382,
      0.024139689281582832,
      -0.040726277977228165,
      -0.034599900245666504,
      0.04303703084588051,
      0.009768867865204811,
      0.01234885212033987,
      0.024978194385766983,
      -0.07182306796312332,
      0.0012017154367640615,
      -0.009206324815750122,
      -0.05414839833974838,
      0.036865994334220886,
      0.05397730693221092,
      0.04360854998230934,
      0.009942174889147282,
      0.04196375235915184,
      0.027958761900663376,
      -0.027620352804660797,
      -0.04093029350042343,
      -0.053381990641355515,
      -0.014002335257828236,
      0.07939308881759644,
      0.01201535388827324,
      0.012674041092395782,
      0.000994260422885418,
      -0.014214284718036652,
      -0.03788510710000992,
      -0.02577156201004982,
      -0.02359173819422722,
      0.08421507477760315,
      0.02309875190258026,
      -0.022605128586292267,
      0.024721935391426086,
      -0.018537849187850952,
      0.05266009643673897,
      0.02473682351410389,
      -0.05298219993710518,
      0.04755347967147827,
      -0.02831951528787613,
      0.06113635376095772,
      0.023911338299512863,
      0.05228876322507858,
      -0.03487364202737808,
      -0.023175910115242004,
      0.014518099837005138,
      0.07043477147817612,
      0.00952073186635971,
      -0.03130630403757095,
      0.045230962336063385,
      -0.020873021334409714,
      -0.012886947952210903,
      0.06015518307685852,
      0.02156108431518078,
      -0.0052676149643957615,
      -0.11755924671888351,
      -0.018215177580714226,
      -0.001075511914677918,
      -0.00032423718948848546,
      0.006189575884491205,
      0.008812869898974895,
      -0.03032919391989708,
      0.051754433661699295,
      0.03468003869056702,
      0.04733770713210106,
      -0.017320435494184494,
      0.01988123543560505,
      0.07318650931119919,
      -0.012488470412790775,
      0.1077522486448288,
      0.0031313933432102203,
      0.03935115411877632,
      -0.03519998490810394,
      0.005540140904486179,
      -0.009208064526319504,
      -0.013880920596420765,
      0.014955095015466213,
      -0.02341676503419876,
      -0.030028272420167923,
      0.0631241723895073,
      0.033501140773296356,
      0.009401975199580193,
      -0.0006231741863302886,
      0.005405691917985678,
      0.0448354110121727,
      0.05633346363902092,
      -0.035136878490448,
      -0.02590659260749817,
      0.007765295449644327,
      0.01787223480641842,
      -0.032765939831733704,
      0.06599067896604538,
      -0.012537665665149689,
      0.006568312644958496,
      0.0056930710561573505,
      0.018025876954197884,
      0.005376290064305067,
      0.057232566177845,
      -0.017182156443595886,
      0.0919274315237999,
      -0.00928926095366478,
      -0.004545579664409161,
      -0.04340802878141403,
      0.006504856050014496,
      0.051378387957811356,
      0.02132839895784855,
      0.09026996046304703,
      0.0041288654319942,
      0.010701283812522888,
      0.014989832416176796,
      0.06691405177116394,
      -0.060662783682346344,
      0.02437259629368782,
      0.0442008301615715,
      -0.007345973514020443,
      0.005240880884230137,
      -0.02034061774611473,
      -0.03429502621293068,
      -0.06020442768931389,
      0.01860855706036091,
      0.09037111699581146,
      -0.06159951910376549,
      -0.007794480305165052,
      0.020240621641278267,
      0.04127662628889084,
      -0.03540593385696411,
      -0.05427573248744011,
      -0.0032783686183393,
      0.03412211686372757,
      0.03439367935061455,
      -0.04123338684439659,
      0.012987312860786915,
      0.015518306754529476,
      -0.019420165568590164,
      0.04680251330137253,
      -0.012050565332174301,
      0.031031258404254913,
      0.07915918529033661,
      0.02437070943415165,
      -0.0785360261797905,
      -0.030227461829781532,
      0.038527511060237885,
      0.008513630367815495,
      0.021389273926615715,
      -0.015147972851991653,
      0.06675281375646591,
      0.08192746341228485,
      -0.048883311450481415,
      -0.11364781856536865,
      -0.019964849576354027,
      0.028763694688677788,
      0.04862591624259949,
      0.009251678362488747,
      -0.0300874225795269,
      -0.003480533603578806,
      -0.009953150525689125,
      0.11132128536701202,
      0.04874216020107269,
      -0.014779455028474331,
      -0.07097942382097244,
      0.067111536860466,
      0.040163539350032806,
      -0.0301992017775774,
      0.028896303847432137,
      -0.013919591903686523,
      0.11901280283927917,
      0.005607204977422953,
      -0.089310422539711,
      -0.025328869000077248,
      0.03743625804781914,
      -0.00376764964312315,
      0.03005349449813366,
      -0.002776399487629533,
      -0.13045816123485565,
      0.028131134808063507,
      0.02938666380941868,
      -0.02549625001847744,
      -0.00017393681628163904,
      -0.01958327926695347,
      -0.09490182995796204,
      0.023792793974280357,
      -0.024980193004012108,
      -0.05892513319849968,
      -0.10825210809707642,
      -0.06042596697807312,
      0.05169236287474632,
      -0.06181991100311279,
      0.023155152797698975,
      -0.018873611465096474,
      -0.05599652975797653,
      -0.03139564394950867,
      -0.07832208275794983,
      -0.0082228509709239,
      0.050455521792173386,
      -0.035083699971437454,
      0.07488586753606796,
      -0.035766635090112686,
      0.03788398578763008,
      0.07377702742815018,
      -0.025949597358703613,
      -0.036514248698949814,
      0.052098266780376434,
      -0.0010215400252491236,
      0.022625068202614784,
      -0.007734186947345734,
      0.02969406545162201,
      0.01533695962280035,
      0.039398420602083206,
      -0.015478060580790043,
      -0.07337092608213425,
      -0.008593493141233921,
      0.09702533483505249,
      -0.045023731887340546,
      0.09709841012954712,
      -0.05066760629415512,
      0.041108742356300354,
      -0.04328395426273346,
      0.029976552352309227,
      0.020791875198483467,
      -0.004890042822808027,
      0.06742335110902786,
      0.02723419852554798,
      0.0601142980158329,
      -0.006265492178499699,
      0.030817564576864243,
      -0.00028874349663965404,
      0.0725577175617218,
      0.06087217107415199,
      -0.05669156089425087,
      0.08205672353506088,
      -0.027061715722084045,
      -0.039393432438373566,
      -0.005661513656377792,
      -0.061015162616968155,
      -0.07729963958263397,
      -0.06302869319915771,
      0.032426826655864716,
      0.02019544504582882,
      -0.007681253831833601,
      -0.008954883553087711,
      -0.043824564665555954,
      -0.056288354098796844,
      -0.016175895929336548,
      0.0047586457803845406,
      -0.03136571869254112,
      0.06303902715444565,
      0.06949998438358307,
      0.020157821476459503,
      -0.015905866399407387,
      -0.01276622898876667,
      -0.03127844259142876,
      -0.027756990864872932,
      -0.037285853177309036,
      -0.0035145042929798365,
      -0.07110663503408432,
      -0.005241103004664183,
      0.000589111412409693,
      0.04901815205812454,
      0.03718269616365433,
      0.012486979365348816,
      -0.029212459921836853,
      0.09579150378704071,
      -0.0145778963342309,
      -0.041400689631700516,
      0.04614344611763954,
      -0.033129118382930756,
      0.014701449312269688,
      0.06311319023370743,
      -0.029130687937140465,
      0.008722517639398575,
      0.04903106018900871,
      -0.08638709038496017,
      -0.003469809191301465,
      0.06920500099658966,
      -0.08523108065128326,
      -0.028690878301858902,
      -0.0036347575951367617,
      0.06269568204879761,
      0.029346689581871033,
      0.0036521784495562315,
      -0.0037195768672972918,
      -0.0019656657241284847,
      0.011285278014838696,
      -0.011680529452860355,
      0.09763886034488678,
      0.05040842294692993,
      -0.008070392534136772,
      0.043174032121896744,
      -0.018624842166900635,
      -0.0030386520083993673,
      0.08076794445514679,
      0.041263800114393234,
      -0.0036868928000330925,
      -0.06904981285333633,
      0.010449714958667755,
      0.010132534429430962,
      0.038432296365499496,
      -0.007692699786275625,
      0.025574129074811935,
      -0.00694082910194993,
      0.045087236911058426,
      -0.021739210933446884,
      -0.004542046692222357,
      0.07513314485549927,
      0.030085144564509392,
      0.0294231865555048,
      0.0037977867759764194,
      0.15662941336631775,
      0.01245107315480709,
      -0.025488516315817833,
      -0.09253068268299103,
      -0.02948024310171604,
      0.005563981365412474
    ],
    [
      0.01575036346912384,
      -0.0033902248833328485,
      -0.0075518409721553326,
      -0.08437693119049072,
      0.03198527544736862,
      0.004307274241000414,
      -0.04489373043179512,
      -0.0038974229246377945,
      -0.010298053734004498,
      0.06543781608343124,
      0.014603481628000736,
      -0.027751196175813675,
      -0.029099762439727783,
      0.03173462674021721,
      -0.012143275700509548,
      -0.005516566801816225,
      0.008004645816981792,
      0.036451246589422226,
      -0.059068333357572556,
      0.041910089552402496,
      0.01677723601460457,
      0.004101429600268602,
      0.023887351155281067,
      -0.06861159950494766,
      -0.044331785291433334,
      0.06716155260801315,
      -0.00030219671316444874,
      0.005029671359807253,
      0.0006054427940398455,
      0.012653937563300133,
      0.07014111429452896,
      -0.038207393139600754,
      -0.02440441958606243,
      -0.022425277158617973,
      0.09898712486028671,
      0.046785175800323486,
      0.012598078697919846,
      -0.10051952302455902,
      0.003692950587719679,
      0.05637397617101669,
      0.10851340740919113,
      0.018921654671430588,
      0.03838794305920601,
      -0.0994076281785965,
      0.004303072113543749,
      0.03658125922083855,
      0.026650920510292053,
      0.06305790692567825,
      0.050749458372592926,
      0.030342431738972664,
      0.007158080115914345,
      -0.06260712444782257,
      -0.028542205691337585,
      -0.02285940945148468,
      -0.008904832415282726,
      0.06236851215362549,
      0.03631219267845154,
      -0.02077358029782772,
      -0.005140372551977634,
      -0.01471980195492506,
      -0.0002630549424793571,
      -0.025015193969011307,
      -0.05021381005644798,
      0.0073393466882407665,
      -0.05511372908949852,
      0.0004199126560706645,
      0.019780173897743225,
      -0.017736198380589485,
      -0.05917636677622795,
      -0.03085215948522091,
      -0.024948911741375923,
      0.008676469326019287,
      -0.05276547372341156,
      0.06579073518514633,
      0.0003445580368861556,
      -0.019452350214123726,
      -0.003304598154500127,
      -0.01962420530617237,
      -0.023127492517232895,
      -0.02851644717156887,
      0.05503864213824272,
      -0.033294226974248886,
      -0.05326792970299721,
      -0.03836547210812569,
      0.13088417053222656,
      -0.010873359628021717,
      -0.008877720683813095,
      0.030818985775113106,
      0.05068409442901611,
      0.03274039924144745,
      0.029238617047667503,
      -0.005989680998027325,
      0.02469417080283165,
      -0.018161199986934662,
      0.0031836230773478746,
      -0.07510249316692352,
      0.031392939388751984,
      0.013350140303373337,
      0.025185775011777878,
      -0.1000964567065239,
      -0.033659715205430984,
      -0.033102329820394516,
      -0.04747391119599342,
      0.04476016014814377,
      -0.09661226719617844,
      0.02853045053780079,
      0.035874780267477036,
      -0.049124576151371,
      0.01542744506150484,
      0.0002421995159238577,
      0.008926448412239552,
      0.018378138542175293,
      0.040631253272295,
      -0.06371752172708511,
      0.018963798880577087,
      0.0657513439655304,
      0.0808260515332222,
      -0.005533847492188215,
      0.023516636341810226,
      -0.07026827335357666,
      0.0672164335846901,
      -0.0004724269383586943,
      -0.021778829395771027,
      -0.03682617098093033,
      0.03062806837260723,
      0.013573109172284603,
      0.003366527147591114,
      -0.03082466684281826,
      0.028293989598751068,
      -0.01766943745315075,
      -0.028779219835996628,
      -0.03915410861372948,
      -0.01275154110044241,
      0.02270650304853916,
      -0.11124592274427414,
      -0.006506915669888258,
      -0.027168605476617813,
      -0.08241205662488937,
      -0.03653101995587349,
      0.01322114747017622,
      0.03910582512617111,
      -0.001270207460038364,
      0.00988487247377634,
      0.0244106724858284,
      -0.024736732244491577,
      -0.03778089955449104,
      -0.046529412269592285,
      -0.013239017687737942,
      -0.016114406287670135,
      0.0019221191760152578,
      0.004222013987600803,
      0.004620847292244434,
      -0.0032616450916975737,
      -0.024622762575745583,
      -0.0005818767822347581,
      -0.007048152387142181,
      0.05685989558696747,
      0.02099509909749031,
      -0.008400719612836838,
      0.026638926938176155,
      0.068219393491745,
      -0.023428987711668015,
      -0.09799063205718994,
      0.01022577565163374,
      -0.06491246074438095,
      -0.0568251758813858,
      0.07684013247489929,
      -0.03763245418667793,
      0.011961917392909527,
      0.039597947150468826,
      -0.04738033562898636,
      0.05352766439318657,
      -0.07289576530456543,
      0.0032008057460188866,
      0.008519207127392292,
      0.021848663687705994,
      -0.015436573885381222,
      0.028725242242217064,
      -0.01904631406068802,
      -0.04221515730023384,
      -0.011355647817254066,
      0.046088848263025284,
      -0.014526013284921646,
      -0.015379750169813633,
      0.025849418714642525,
      0.0689854770898819,
      -0.051673002541065216,
      0.02291984111070633,
      0.02569737657904625,
      -0.023077217862010002,
      -0.01766827143728733,
      0.032085124403238297,
      -0.047025375068187714,
      0.02960285358130932,
      -0.006702865473926067,
      0.017449796199798584,
      0.00503204669803381,
      0.027588149532675743,
      0.0061630383133888245,
      -0.07300308346748352,
      -0.04605328291654587,
      -0.01775934174656868,
      -0.029756935313344002,
      0.034335069358348846,
      0.0014258153969421983,
      -0.04277254268527031,
      0.044074200093746185,
      0.04935933277010918,
      0.002662206534296274,
      -0.009893878363072872,
      0.0053147864528000355,
      -0.02270338498055935,
      -0.06916707754135132,
      -0.03413090482354164,
      0.016135571524500847,
      -0.04061861336231232,
      0.0210921298712492,
      -0.014899504370987415,
      -0.027040252462029457,
      -0.04864237457513809,
      -0.0006235743057914078,
      0.057288024574518204,
      0.016462229192256927,
      -0.06261405348777771,
      -0.013652755878865719,
      -0.06721940636634827,
      0.028055697679519653,
      -0.006712417118251324,
      -0.01958685927093029,
      0.010766277089715004,
      0.046767935156822205,
      0.0015972782857716084,
      -0.051898326724767685,
      0.09281770884990692,
      0.07788297533988953,
      0.020988713949918747,
      0.0037314470391720533,
      0.019243575632572174,
      0.03492758050560951,
      -0.01274251751601696,
      0.026698341593146324,
      0.020316192880272865,
      0.053082142025232315,
      0.03270711749792099,
      -0.014513681642711163,
      -0.030416134744882584,
      -0.025793878361582756,
      0.029924821108579636,
      0.05883987620472908,
      0.040267955511808395,
      -0.05085466429591179,
      -0.019327577203512192,
      0.08737598359584808,
      0.0016614258056506515,
      0.001726417918689549,
      -0.04413709416985512,
      -0.01299187634140253,
      -0.03023931011557579,
      -0.0230923630297184,
      -0.06307004392147064,
      0.06094111129641533,
      0.0899726003408432,
      0.009929776191711426,
      -0.03219473734498024,
      -0.031261373311281204,
      -0.005015561357140541,
      -0.031148336827754974,
      -0.09988649189472198,
      0.027103284373879433,
      -0.0034867955837398767,
      -0.06408215314149857,
      0.013567824847996235,
      -0.010199830867350101,
      0.07033959031105042,
      -0.02980363741517067,
      0.01795925758779049,
      0.04174567013978958,
      -0.15321297943592072,
      0.08795508742332458,
      -0.024548236280679703,
      0.0328633077442646,
      0.11925173550844193,
      0.08149261772632599,
      0.003752603428438306,
      0.0782705545425415,
      -0.01475479081273079,
      0.034157492220401764,
      -0.01552884466946125,
      -0.0049289814196527,
      0.023784412071108818,
      0.054774194955825806,
      -0.03776424005627632,
      -0.04139571264386177,
      0.018513424322009087,
      -0.016883032396435738,
      0.0024540689773857594,
      0.07262752950191498,
      0.05490545183420181,
      -0.0055205258540809155,
      -0.02254083938896656,
      0.018384737893939018,
      0.009603134356439114,
      -0.017370864748954773,
      0.03090018406510353,
      0.012062406167387962,
      0.014887945726513863,
      0.011607570573687553,
      -0.0892847329378128,
      0.06334347277879715,
      -0.01719367504119873,
      -0.046509843319654465,
      0.037505533546209335,
      0.009906678460538387,
      -0.02666969783604145,
      0.04051816463470459,
      0.03516817092895508,
      -0.12789197266101837,
      -0.058640751987695694,
      0.036707159131765366,
      0.0572986863553524,
      -0.018271196633577347,
      -0.013869120739400387,
      0.0020875062327831984,
      0.09679464995861053,
      -0.11469663679599762,
      -0.05789434164762497,
      -0.009139952249825,
      0.08076918870210648,
      -0.001452046213671565,
      -0.02541932463645935,
      0.04333703592419624,
      0.02671356126666069,
      0.009108670987188816,
      -0.037856657058000565,
      -0.06605829298496246,
      0.055748630315065384,
      -0.008310153149068356,
      -0.011508236639201641,
      -0.049157992005348206,
      -0.07117413729429245,
      0.04813748225569725,
      0.07221800088882446,
      0.010608886368572712,
      0.10492788255214691,
      -0.019074443727731705,
      0.0008598798303864896,
      -0.08326517790555954,
      0.019710371270775795,
      0.016502005979418755,
      0.07170127332210541,
      0.0656757727265358,
      0.12232036143541336,
      -0.016747118905186653,
      -0.061029382050037384,
      -0.050889793783426285,
      0.05682794377207756,
      0.03423181176185608,
      -0.00949500035494566,
      0.04643099755048752,
      -0.0348510816693306,
      -0.022515341639518738,
      -0.020711952820420265,
      -0.014260600320994854,
      -0.07740900665521622,
      -0.02990090288221836,
      -0.03980059549212456,
      -0.006496735382825136,
      0.0034889348316937685,
      0.03768210485577583,
      -0.07091071456670761,
      -0.00047942239325493574,
      -0.04470750316977501,
      0.019879041239619255,
      -0.02157476730644703,
      0.013365253806114197,
      -0.01693512126803398,
      -0.05552297458052635,
      0.03829098492860794,
      -0.05043016001582146,
      0.07159601151943207,
      -0.05945024639368057,
      0.06399647146463394,
      0.026511874049901962,
      0.039918746799230576,
      -0.024293722584843636,
      0.052353475242853165,
      0.042228709906339645,
      -0.027016138657927513,
      0.025477610528469086,
      -4.823602466785815e-06,
      0.015039704740047455,
      0.0012421354185789824,
      0.0017333850264549255,
      -0.014477528631687164,
      -0.11115703731775284,
      0.07556338608264923,
      0.03548924997448921,
      0.0822545737028122,
      0.001516686170361936,
      0.02737748809158802,
      0.03624603524804115,
      0.0573202408850193,
      -0.017873702570796013,
      0.046025656163692474,
      -0.004310073330998421,
      0.019328055903315544,
      -0.052633706480264664,
      -0.027477700263261795,
      0.005512675736099482,
      -0.04339802265167236,
      0.028732655569911003,
      -0.016275495290756226,
      0.016975294798612595,
      0.01146838627755642,
      0.014873863197863102,
      -0.038066644221544266,
      0.05158109962940216,
      0.03360135480761528,
      -0.05438107252120972,
      0.026820670813322067,
      -0.08798398822546005,
      0.01670251600444317,
      -0.03313814476132393,
      0.015836378559470177,
      0.019438032060861588,
      -0.011431224644184113,
      -0.02046402357518673,
      -0.044630374759435654,
      0.024021903052926064,
      0.03732480853796005,
      0.0016834306297823787,
      0.0053292871452867985,
      -0.05815834179520607,
      0.04228470101952553,
      -0.05219234898686409,
      0.029511554166674614,
      -0.05524206534028053,
      -0.019270028918981552,
      -0.03478221595287323,
      -0.05183358117938042,
      -0.00453144870698452,
      0.011968914419412613,
      0.028929831460118294,
      0.023828359320759773,
      -0.050614871084690094,
      0.06671664863824844,
      -0.044641152024269104,
      -0.01387983001768589,
      0.011859827674925327,
      0.038295552134513855,
      -0.03508102521300316,
      -0.009177855215966702,
      0.07681311666965485,
      0.014365575276315212,
      -0.006777751259505749,
      0.04582411050796509,
      0.042262520641088486,
      0.04585148021578789,
      0.05178534612059593,
      -0.06075501814484596,
      0.031090404838323593,
      -0.08637359738349915,
      0.02894083596765995,
      0.02430601418018341,
      -0.004257525317370892,
      -0.025879215449094772,
      -0.015675285831093788,
      0.039996933192014694,
      0.0009753623744472861,
      -0.07450985908508301,
      -0.03786009922623634,
      -0.04580321162939072,
      -0.035497747361660004,
      -0.019962014630436897,
      0.02647492289543152,
      -0.05371776968240738,
      0.026076702401041985,
      0.010394816286861897,
      -0.0021492396481335163,
      0.01601375825703144,
      -0.026485692709684372,
      0.07958105951547623,
      0.022517003118991852,
      -0.06960047036409378,
      0.005623436067253351,
      0.00458547193557024,
      -0.05684471130371094,
      0.00978658627718687,
      0.030435681343078613,
      -0.0237605981528759,
      -0.04914112016558647,
      -0.05820842459797859,
      0.07720061391592026,
      0.033888109028339386,
      0.00811852514743805,
      -0.012819462455809116,
      -0.08928775787353516,
      -0.05917154997587204,
      -0.01227591559290886,
      -0.06008480489253998,
      -0.005111212842166424,
      -0.06440102308988571,
      0.07388171553611755
    ],
    [
      -0.02973487228155136,
      -0.031183985993266106,
      -0.021268105134367943,
      0.02832515351474285,
      -0.016885682940483093,
      -0.02164546027779579,
      -0.029716525226831436,
      0.0019513086881488562,
      -0.031783849000930786,
      -0.03123687580227852,
      -0.024316340684890747,
      0.007427128031849861,
      -0.04984914883971214,
      0.025243166834115982,
      0.011344119906425476,
      0.003713543526828289,
      -0.08341000229120255,
      0.0647657960653305,
      -0.10137578845024109,
      -0.06885800510644913,
      -0.017820164561271667,
      0.05289926379919052,
      0.04307740926742554,
      0.030858822166919708,
      0.023377152159810066,
      0.1072377860546112,
      0.003709364915266633,
      0.013250061310827732,
      -0.021433573216199875,
      -0.0773358941078186,
      0.1057768315076828,
      0.004838178399950266,
      0.01069206278771162,
      0.012182864360511303,
      0.005165779963135719,
      -0.04232996329665184,
      0.019035648554563522,
      0.045191116631031036,
      0.007741597481071949,
      -0.05471126735210419,
      0.05091864615678787,
      -0.013162359595298767,
      -0.02286161482334137,
      0.010283054783940315,
      0.12025336921215057,
      0.00775365624576807,
      0.007108354941010475,
      -0.005687408614903688,
      0.05778035521507263,
      -0.002038417849689722,
      -0.018287409096956253,
      -0.022386759519577026,
      -0.06754098832607269,
      0.029831578955054283,
      -0.03389547020196915,
      0.017268821597099304,
      0.02627590112388134,
      -0.01166105642914772,
      -0.04064789041876793,
      0.04715767130255699,
      -0.028324661776423454,
      0.0546850711107254,
      0.0025202154647558928,
      0.05308619514107704,
      0.015366412699222565,
      0.0277851689606905,
      -0.020663857460021973,
      -0.028976723551750183,
      -0.04984983429312706,
      -0.08472277969121933,
      -0.03809439018368721,
      0.010772052221000195,
      0.10689806193113327,
      0.05214456841349602,
      0.037011392414569855,
      0.03177175670862198,
      -0.05646746978163719,
      -0.019378913566470146,
      -0.0353388749063015,
      0.001092904363758862,
      -0.007176428101956844,
      -0.026612818241119385,
      -0.08463432639837265,
      -0.08252687752246857,
      0.04602118209004402,
      0.028834376484155655,
      0.040216054767370224,
      -0.04879245162010193,
      0.029397765174508095,
      0.0222347229719162,
      0.01628778874874115,
      0.05596507713198662,
      -0.01256108470261097,
      -0.0018903972813859582,
      0.06979977339506149,
      0.021720238029956818,
      -0.0866071805357933,
      -0.022666502743959427,
      1.2240299838595092e-05,
      0.0019027083180844784,
      0.014015771448612213,
      -0.0014470176538452506,
      -0.0031460814643651247,
      -0.019236277788877487,
      0.04292818903923035,
      0.1264418065547943,
      -0.07529555261135101,
      -0.06261344254016876,
      -0.025651711970567703,
      0.036767374724149704,
      -0.004184311721473932,
      -0.05283623933792114,
      0.038334738463163376,
      0.009520215913653374,
      -0.0019718334078788757,
      0.08372458070516586,
      0.03461743891239166,
      0.02231113240122795,
      0.028622541576623917,
      0.031791701912879944,
      0.05315736308693886,
      0.018069328740239143,
      0.07098240405321121,
      -0.039309412240982056,
      -0.06817889213562012,
      0.09136821329593658,
      0.060533557087183,
      0.0067882356233894825,
      -0.09367994219064713,
      0.012100991792976856,
      -0.00025491349515505135,
      -0.06907442957162857,
      -0.028532953932881355,
      0.044512443244457245,
      0.004928423557430506,
      -0.0072928559966385365,
      0.005660851951688528,
      0.07402119040489197,
      -0.07573582977056503,
      0.042403969913721085,
      0.016703298315405846,
      0.00044725104817189276,
      -0.04450422525405884,
      0.046596210449934006,
      -0.008595140650868416,
      -0.04341524839401245,
      -0.03362860903143883,
      -0.0009877389529719949,
      0.03592941537499428,
      0.08642391115427017,
      -0.0534510500729084,
      -0.04162847623229027,
      0.015618206933140755,
      0.021698206663131714,
      -0.029532790184020996,
      0.07536448538303375,
      0.01522748451679945,
      -0.02659125253558159,
      -0.005316246300935745,
      0.05515047162771225,
      0.12430908530950546,
      0.038035545498132706,
      -0.01167263649404049,
      -0.11664433032274246,
      0.024653097614645958,
      -0.052248500287532806,
      0.004415531642735004,
      -0.06548506766557693,
      -0.022306347265839577,
      0.005421270616352558,
      0.00014709752576891333,
      -0.030181318521499634,
      0.04480387642979622,
      -0.011249560862779617,
      0.039756208658218384,
      -0.004356364719569683,
      -0.03459169715642929,
      -0.04627171903848648,
      0.004820694215595722,
      0.044998183846473694,
      -0.045945294201374054,
      -0.004672026727348566,
      -0.014597848989069462,
      0.025325730443000793,
      -0.022822003811597824,
      -0.06540592014789581,
      -0.03475915268063545,
      -0.03825782239437103,
      -0.01082103606313467,
      -0.07152360677719116,
      0.015063585713505745,
      -0.03964442014694214,
      -0.054370250552892685,
      -0.02768602967262268,
      0.004481114912778139,
      0.004520866554230452,
      0.05083831027150154,
      0.0015015030512586236,
      0.022366996854543686,
      -0.04448644444346428,
      -0.055176615715026855,
      0.02036239020526409,
      -0.06259791553020477,
      0.014029926620423794,
      0.011368339881300926,
      -0.043141815811395645,
      0.0292681697756052,
      0.0017935318173840642,
      0.005842985585331917,
      -0.006699372548609972,
      -0.05920405685901642,
      -0.017377063632011414,
      0.03924242779612541,
      0.05970890074968338,
      0.03153007850050926,
      0.05413879081606865,
      0.03086400218307972,
      -0.02323862537741661,
      -0.005343166645616293,
      -0.0027057917322963476,
      -0.02708086557686329,
      0.03078455664217472,
      -0.043808091431856155,
      -0.015437339432537556,
      -0.03272926062345505,
      -0.023078326135873795,
      0.006162816192954779,
      -0.05039199814200401,
      0.03900756686925888,
      -0.0690804272890091,
      -0.005102076102048159,
      0.05090774968266487,
      0.058692365884780884,
      -0.013181082904338837,
      0.04897814616560936,
      0.0017890322487801313,
      -0.035636793822050095,
      -0.003958807792514563,
      -0.030081091448664665,
      0.04532996192574501,
      0.05331399291753769,
      -0.05562682822346687,
      0.004245905205607414,
      0.03542424365878105,
      0.008012235164642334,
      0.0053269327618181705,
      0.18038634955883026,
      0.004285973031073809,
      0.040456414222717285,
      -0.01734876073896885,
      -0.0930185467004776,
      0.0035405922681093216,
      0.02060496248304844,
      0.01333521120250225,
      -0.033591531217098236,
      0.019236600026488304,
      -0.057155903428792953,
      -0.02238251082599163,
      -0.11468828469514847,
      -0.019226431846618652,
      0.05598395690321922,
      0.0063447109423577785,
      -0.042192164808511734,
      0.007609077263623476,
      0.08725673705339432,
      0.043478380888700485,
      0.05587294325232506,
      -0.03579144924879074,
      -0.01337131205946207,
      0.044541239738464355,
      -0.03557519242167473,
      0.05894799530506134,
      -0.06430430710315704,
      0.018857039511203766,
      0.004557005129754543,
      -0.10296772420406342,
      0.0529467836022377,
      0.024242114275693893,
      0.13925188779830933,
      -0.033084686845541,
      -0.0063415830954909325,
      0.09049056470394135,
      -0.044482529163360596,
      -0.027676189318299294,
      -0.00875122006982565,
      0.003933724947273731,
      0.03697033226490021,
      0.024801533669233322,
      -0.055949147790670395,
      0.0013570402516052127,
      -0.05404072254896164,
      -0.10294841229915619,
      -0.0015579338651150465,
      -0.07617880403995514,
      0.030632223933935165,
      0.014080893248319626,
      -0.01288251206278801,
      -0.05298670381307602,
      0.060780178755521774,
      0.07777683436870575,
      -0.05700274184346199,
      0.06684494018554688,
      -0.050530023872852325,
      0.03350569307804108,
      0.01278221607208252,
      -0.054626256227493286,
      0.02714780904352665,
      -0.028753668069839478,
      -0.006427582819014788,
      -0.028472287580370903,
      -0.046273451298475266,
      -0.004670772701501846,
      0.010318130254745483,
      -0.03802565857768059,
      -0.008483855053782463,
      -0.022173991426825523,
      -0.01165427453815937,
      -0.09190230071544647,
      0.008423333056271076,
      0.009091884829103947,
      -0.07260587066411972,
      -0.012870675884187222,
      0.05598662048578262,
      -0.0026092727202922106,
      -0.013862060382962227,
      -0.07519885152578354,
      0.029011821374297142,
      0.04485900327563286,
      -0.07060574740171432,
      -0.027136951684951782,
      -0.035032059997320175,
      0.057824768126010895,
      -0.06618615984916687,
      -0.03468290716409683,
      -0.008004413917660713,
      0.09258486330509186,
      3.8647500332444906e-05,
      -0.016929227858781815,
      -0.056693185120821,
      0.02192188985645771,
      0.0015212055295705795,
      0.06508653610944748,
      -0.018383998423814774,
      -0.004948352929204702,
      0.026747144758701324,
      -0.045388203114271164,
      0.006155106239020824,
      0.05461035668849945,
      -0.003636126173660159,
      0.07488773763179779,
      0.017346413806080818,
      0.07355188578367233,
      -0.014781179837882519,
      0.018394827842712402,
      0.004627068527042866,
      0.019971344619989395,
      -0.07269992679357529,
      -0.009746585972607136,
      0.09887679666280746,
      -0.017985263839364052,
      0.042248934507369995,
      0.02964344620704651,
      0.028447335585951805,
      0.014791667461395264,
      0.013102827593684196,
      0.049440402537584305,
      0.07093792408704758,
      0.02066248282790184,
      0.07241059094667435,
      -0.06958335638046265,
      -0.023134691640734673,
      0.00012544066703412682,
      0.029026620090007782,
      0.08191300928592682,
      0.07561052590608597,
      -0.04035554453730583,
      0.050677355378866196,
      -0.03217243775725365,
      0.02934650518000126,
      -0.1039469838142395,
      0.10259382426738739,
      0.021641375496983528,
      -0.04420189559459686,
      -0.04758352413773537,
      0.04837365075945854,
      0.1674043834209442,
      0.048793110996484756,
      0.06425219774246216,
      0.018554562702775,
      -0.055381983518600464,
      0.0038850917480885983,
      -0.05452786386013031,
      -0.05363396182656288,
      0.008004624396562576,
      0.004001048393547535,
      0.07251832634210587,
      0.03380852937698364,
      0.06298000365495682,
      -0.07835717499256134,
      -0.016543684527277946,
      -0.05144088342785835,
      0.00338660622946918,
      -0.08239385485649109,
      -0.029456207528710365,
      0.01836412400007248,
      0.05064591392874718,
      -0.028674475848674774,
      -0.011119389906525612,
      0.04471035301685333,
      0.060888271778821945,
      0.00023895797494333237,
      0.05795932933688164,
      -0.03785303235054016,
      0.04769717529416084,
      -0.0612059086561203,
      0.07906956970691681,
      0.054399870336055756,
      -0.015409144572913647,
      -0.015004110522568226,
      0.07526915520429611,
      -0.05546365678310394,
      0.048203591257333755,
      0.032349783927202225,
      0.06318814307451248,
      0.007118142209947109,
      0.010471462272107601,
      -0.008815537206828594,
      -0.004740397911518812,
      0.0020512123592197895,
      0.02110965922474861,
      0.05792929232120514,
      0.03125312551856041,
      0.07444837689399719,
      0.04714232683181763,
      0.02254640869796276,
      -0.006837263237684965,
      -0.09755454212427139,
      0.13067512214183807,
      0.016420729458332062,
      -0.05832969397306442,
      -0.046318359673023224,
      0.03337237611413002,
      -0.040807899087667465,
      0.0013853390701115131,
      0.011791465803980827,
      0.05530017614364624,
      -0.04089011251926422,
      -0.005279923789203167,
      0.021985631436109543,
      -0.026269299909472466,
      0.025776119902729988,
      0.054067838937044144,
      -0.030302314087748528,
      0.06547223776578903,
      -0.05539897456765175,
      0.04117020592093468,
      0.025663239881396294,
      -0.02465728484094143,
      -0.010422224178910255,
      0.05107388272881508,
      -0.01390025857836008,
      -0.03247688338160515,
      -0.07443428039550781,
      0.059420231729745865,
      -0.03396397456526756,
      -0.029012909159064293,
      -0.01470425445586443,
      -0.058498550206422806,
      -0.014401188120245934,
      -0.01079570222645998,
      0.05463476479053497,
      0.017370086163282394,
      0.044540293514728546,
      0.01936199702322483,
      0.024622077122330666,
      -0.010015781968832016,
      0.015209171921014786,
      -0.025477483868598938,
      0.02857339009642601,
      0.05474034696817398,
      0.037203360348939896,
      0.03672013431787491,
      -0.01090486254543066,
      -0.059745270758867264,
      0.014582045376300812,
      -0.0608023963868618,
      0.043397288769483566,
      -0.028212636709213257,
      -0.010646495036780834,
      -0.042141709476709366,
      0.0021665801759809256,
      0.01643487438559532,
      -0.09764643758535385,
      0.05265003815293312,
      -0.062207259237766266,
      0.08464820683002472,
      0.033302001655101776,
      0.0680244043469429,
      0.028945421800017357,
      0.061939921230077744,
      -0.008850923739373684,
      0.008691845461726189,
      0.09977717697620392
    ],
    [
      -0.059199463576078415,
      0.038791488856077194,
      -0.0529627799987793,
      -0.10198517143726349,
      -0.007818598300218582,
      -0.0023122921120375395,
      -0.07942207157611847,
      0.05581676959991455,
      -0.039531171321868896,
      0.013052133843302727,
      0.005098168272525072,
      -0.05740699917078018,
      -0.04371125251054764,
      -0.0007185658905655146,
      0.0368926115334034,
      -0.05083003267645836,
      -0.023121802136301994,
      -0.005372929852455854,
      -0.10422539710998535,
      -0.06087397411465645,
      -0.08449870347976685,
      0.02232930064201355,
      -0.011691122315824032,
      0.000496320950333029,
      -0.040407944470644,
      0.08679413795471191,
      0.09903771430253983,
      0.07108303904533386,
      -0.046156324446201324,
      -0.049158528447151184,
      0.011287611909210682,
      -0.06368233263492584,
      -0.011994268745183945,
      0.024249838665127754,
      0.01089782826602459,
      0.028774399310350418,
      0.06210200488567352,
      -0.012871856801211834,
      0.08154360204935074,
      -0.03315906226634979,
      0.047826603055000305,
      -0.04581324756145477,
      -0.051220595836639404,
      0.005757187958806753,
      0.02657882496714592,
      0.038666944950819016,
      -0.03002926893532276,
      0.014257552102208138,
      0.07935924828052521,
      0.06502334773540497,
      -0.05501352250576019,
      0.0633080005645752,
      -0.0017359246267005801,
      0.035104900598526,
      0.024848120287060738,
      -0.02817651256918907,
      0.060730013996362686,
      -0.012511953711509705,
      0.004074461758136749,
      0.022301193326711655,
      0.02402016520500183,
      0.027758922427892685,
      -0.006057027727365494,
      -0.009085843339562416,
      0.04662495106458664,
      0.05876294896006584,
      -0.04219631478190422,
      -0.06141642853617668,
      0.02069452404975891,
      -0.04213495925068855,
      0.05831630900502205,
      0.04758719354867935,
      0.029461998492479324,
      -0.02189968153834343,
      -0.03413357213139534,
      0.005580193363130093,
      -0.017513016238808632,
      -0.06466680020093918,
      -0.12188545614480972,
      0.02122548036277294,
      -0.02060389146208763,
      -0.009639889001846313,
      0.038796424865722656,
      -0.009756995365023613,
      0.008913149125874043,
      0.08249221742153168,
      0.04497557133436203,
      0.009192381985485554,
      0.055211346596479416,
      0.052485231310129166,
      0.027401944622397423,
      -0.048442158848047256,
      0.0059591541066765785,
      0.09656696021556854,
      0.014043190516531467,
      0.041783373802900314,
      0.0032317056320607662,
      -0.042647525668144226,
      0.001653080340474844,
      -0.07086128741502762,
      -0.007784666959196329,
      -0.010145380161702633,
      0.014293123036623001,
      0.020893406122922897,
      0.07910436391830444,
      0.06402724981307983,
      0.007337930612266064,
      0.0291733480989933,
      -0.008410418406128883,
      0.00988108292222023,
      0.06558141857385635,
      0.0016349507495760918,
      0.004683800041675568,
      0.10375898331403732,
      0.040058474987745285,
      -0.05098339542746544,
      -0.09944022446870804,
      0.07665251940488815,
      0.005730962380766869,
      -0.04799697920680046,
      0.05935884639620781,
      0.02201627381145954,
      0.006409910507500172,
      0.0036074745003134012,
      0.06864967197179794,
      -0.016666749492287636,
      -0.08380241692066193,
      0.042772553861141205,
      0.05321054905653,
      -0.022064611315727234,
      -0.03344842046499252,
      0.04513821378350258,
      0.004608157090842724,
      -0.06213822960853577,
      -0.07622373104095459,
      -0.06966198235750198,
      0.02338089793920517,
      0.01929659955203533,
      -0.011818320490419865,
      0.031138833612203598,
      0.007173143792897463,
      0.022579891607165337,
      -0.005439308006316423,
      -0.05908823758363724,
      -0.055638473480939865,
      -0.032315805554389954,
      -0.0016762567684054375,
      0.041785191744565964,
      -0.045662157237529755,
      0.03356749564409256,
      -0.04166683182120323,
      0.06271443516016006,
      -0.04419107362627983,
      -0.022660547867417336,
      -0.03558003529906273,
      0.0032586646266281605,
      -0.021098800003528595,
      0.010815701447427273,
      0.034399792551994324,
      -0.03351178020238876,
      -0.0290348082780838,
      -0.01564652845263481,
      -0.019995231181383133,
      0.04093965142965317,
      0.04839178919792175,
      0.039469823241233826,
      -0.03969072550535202,
      -0.0013217870146036148,
      0.02521933615207672,
      -0.01119405496865511,
      -0.042192600667476654,
      -0.008466913364827633,
      0.011836984194815159,
      -0.018491297960281372,
      -0.06499755382537842,
      -0.023857900872826576,
      0.04599214345216751,
      -0.05630842223763466,
      -0.05884826183319092,
      0.05376789718866348,
      0.026493018493056297,
      -0.04374874755740166,
      -0.024389784783124924,
      0.021481899544596672,
      -0.03856607526540756,
      0.03346529230475426,
      0.0803842544555664,
      -0.08479313552379608,
      -0.03843923285603523,
      -0.014503782615065575,
      -0.0767051950097084,
      -0.06435937434434891,
      -0.04185483604669571,
      -0.0017739899922162294,
      -0.005802875850349665,
      -0.01103759091347456,
      -0.033307477831840515,
      -0.056388989090919495,
      0.07437285780906677,
      -0.02647474594414234,
      -0.07476965337991714,
      -0.009950635954737663,
      -0.07662855088710785,
      0.01795605570077896,
      0.013613247312605381,
      -0.037328753620386124,
      -0.021649612113833427,
      -0.05846306309103966,
      0.016698921099305153,
      -0.0028301323764026165,
      -0.02330855280160904,
      0.011905640363693237,
      0.03141389042139053,
      0.06051384285092354,
      -0.09267172962427139,
      -0.020229876041412354,
      -0.1033753976225853,
      0.07190049439668655,
      0.020103001967072487,
      -0.055046238005161285,
      0.012170742265880108,
      -0.05365419387817383,
      0.011822705157101154,
      -0.021798057481646538,
      0.027642758563160896,
      0.04779103770852089,
      -0.02960025519132614,
      -0.054822083562612534,
      -0.03165871277451515,
      -0.038470134139060974,
      0.05618059262633324,
      0.08352532982826233,
      0.008246994577348232,
      0.02630675956606865,
      -0.03881283476948738,
      0.02382863499224186,
      0.04917646944522858,
      -0.004893534816801548,
      0.007444006856530905,
      0.030491799116134644,
      0.1156136617064476,
      0.00040388465276919305,
      -0.01463134866207838,
      -0.019955797120928764,
      0.052505653351545334,
      0.011041764169931412,
      -0.019000612199306488,
      0.06610076874494553,
      0.04928973689675331,
      -0.016687465831637383,
      0.045385655015707016,
      0.03097243793308735,
      -0.05358090251684189,
      0.005799820646643639,
      0.0001293561072088778,
      -0.030412888154387474,
      -0.014520175755023956,
      -0.03622288256883621,
      -0.11184749752283096,
      -0.04412822797894478,
      0.04307475686073303,
      -0.038774531334638596,
      -0.04932611808180809,
      -0.011365905404090881,
      0.08097144961357117,
      -0.06131904944777489,
      0.047028928995132446,
      -0.027004344388842583,
      0.009838459081947803,
      -0.019786851480603218,
      -0.0052300672978162766,
      -0.004145702812820673,
      0.010816135443747044,
      -0.0456484779715538,
      0.05533822253346443,
      -0.056598808616399765,
      -0.0003009162319358438,
      0.040476515889167786,
      0.007665233686566353,
      -0.015152070671319962,
      -0.014548249542713165,
      -0.022607311606407166,
      0.04745068401098251,
      0.0818573608994484,
      -0.015194597654044628,
      -0.024513384327292442,
      0.07945416122674942,
      0.12668508291244507,
      -0.011951006948947906,
      0.03064216859638691,
      0.07478577643632889,
      -0.020316695794463158,
      0.08698627352714539,
      -0.07042821496725082,
      0.020068679004907608,
      0.05806587263941765,
      0.01647789403796196,
      -0.00900562945753336,
      0.0504571758210659,
      -0.028404327109456062,
      0.009249611757695675,
      0.019995100796222687,
      -0.013072672300040722,
      0.0058026667684316635,
      0.045961931347846985,
      0.047108929604291916,
      -0.07719740271568298,
      -0.06164095923304558,
      -0.04509367048740387,
      -0.04322322458028793,
      -0.012382036074995995,
      0.02993994951248169,
      -0.00025470711989328265,
      -0.006536379456520081,
      -0.009879613295197487,
      0.048840299248695374,
      -0.03877243772149086,
      0.0507996492087841,
      -0.0893138200044632,
      -0.03429792448878288,
      -0.02501310035586357,
      0.035463713109493256,
      -0.01292334496974945,
      -0.038102660328149796,
      0.0704478845000267,
      -0.009148452430963516,
      -0.038042131811380386,
      -0.010078566148877144,
      0.006529567297548056,
      -0.04761273041367531,
      0.012190167792141438,
      -0.02185964584350586,
      -0.002869087504222989,
      0.061942897737026215,
      -0.0737021416425705,
      0.004214437212795019,
      0.0012919730506837368,
      -0.013711784034967422,
      0.02531232312321663,
      -0.045751575380563736,
      0.016103271394968033,
      -0.06615307182073593,
      -0.06681171804666519,
      0.033544257283210754,
      -0.04999016597867012,
      -0.09059873968362808,
      0.0476192943751812,
      -0.05193294584751129,
      -0.029497340321540833,
      0.02190391719341278,
      0.05240640416741371,
      -0.06194988265633583,
      0.03500082716345787,
      0.003541407873854041,
      -0.025608085095882416,
      0.05369245260953903,
      -0.02331545390188694,
      0.007029134780168533,
      -0.005188434384763241,
      -0.005086340010166168,
      0.012435559183359146,
      -0.04243491217494011,
      0.007224495057016611,
      -0.022753339260816574,
      0.05291958153247833,
      0.000647757260594517,
      -0.018371034413576126,
      0.04610806703567505,
      -0.06323309242725372,
      0.017529917880892754,
      0.07405110448598862,
      -0.08025648444890976,
      0.06861498206853867,
      -0.024460319429636,
      0.06759363412857056,
      -0.055622149258852005,
      -0.03965883329510689,
      0.053159698843955994,
      0.028763605281710625,
      0.011861247941851616,
      -0.018558744341135025,
      0.04497719928622246,
      -0.01482586469501257,
      0.08085526525974274,
      -0.0053086914122104645,
      -0.029791302978992462,
      -0.10094209015369415,
      0.0057885670103132725,
      0.0037535489536821842,
      0.006263147108256817,
      0.05861787870526314,
      -0.07087092846632004,
      0.04838297888636589,
      -0.05053432658314705,
      -0.06999912858009338,
      0.03277023136615753,
      -0.054286692291498184,
      0.04168785735964775,
      0.09660481661558151,
      -0.01679888926446438,
      0.025865284726023674,
      -0.04448895901441574,
      -0.06187119334936142,
      -0.09550445526838303,
      0.06068253517150879,
      -0.07535887509584427,
      -0.035465870052576065,
      -0.01740291714668274,
      -0.06730882078409195,
      -0.0640212669968605,
      0.023418232798576355,
      -0.014727412723004818,
      -0.001915668835863471,
      -0.05096326768398285,
      -0.09562111645936966,
      -0.03164665028452873,
      0.06935320049524307,
      -0.01277619507163763,
      0.033464036881923676,
      -0.00797232799232006,
      0.09039297699928284,
      -0.07534699141979218,
      0.05092334374785423,
      0.05505157634615898,
      0.002965387422591448,
      -0.03360969200730324,
      -0.02010083757340908,
      0.050312768667936325,
      -0.0057601057924330235,
      -0.13970746099948883,
      0.020700475201010704,
      -0.08589040488004684,
      -0.07580652832984924,
      -0.09919219464063644,
      0.030119767412543297,
      0.019373729825019836,
      0.10131547600030899,
      -0.002880206098780036,
      -0.004796331748366356,
      -0.016156304627656937,
      -0.0004227948375046253,
      -0.03988003730773926,
      0.03557179495692253,
      -0.025258146226406097,
      -0.02411605976521969,
      -0.04252085089683533,
      -0.027476279065012932,
      0.040573276579380035,
      0.005380456335842609,
      0.05851125717163086,
      -0.027931084856390953,
      -0.016514373943209648,
      -0.011213032528758049,
      0.0352894589304924,
      0.08228030055761337,
      -0.05374308302998543,
      -0.04403156787157059,
      0.06784339994192123,
      -0.0069412169978022575,
      -0.008759741671383381,
      0.023374218493700027,
      -0.023272166028618813,
      0.06466515362262726,
      -0.0244735199958086,
      -0.0007692457875236869,
      -0.04480629786849022,
      0.019236354157328606,
      -0.01942153461277485,
      -0.03152673318982124,
      -0.013715963810682297,
      0.025090744718909264,
      -0.03585704788565636,
      -0.01608031429350376,
      0.06584250181913376,
      0.019073758274316788,
      -0.10430221259593964,
      0.03170013800263405,
      -0.018604213371872902,
      -0.02895970828831196,
      -0.002637619385495782,
      -0.00928050372749567,
      0.10505256801843643,
      -0.01474019791930914,
      -0.017556168138980865,
      0.010686354711651802,
      -0.04488407075405121,
      0.0573808029294014,
      -0.0808645635843277,
      -0.007002463098615408,
      -0.004547305405139923,
      -0.07378893345594406,
      -0.033543121069669724,
      0.020029477775096893,
      0.03129249066114426,
      0.05327437072992325,
      -0.05089150369167328,
      -0.07501246780157089,
      0.04113484546542168,
      -0.00236839079298079,
      0.06820813566446304,
      -0.001441459869965911,
      -0.06496402621269226,
      0.04044090211391449
    ],
    [
      0.038137052208185196,
      0.04564296081662178,
      0.007253109943121672,
      0.01700003817677498,
      0.040792450308799744,
      0.058700017631053925,
      -0.02060764655470848,
      0.026345565915107727,
      -0.034035515040159225,
      0.009249274618923664,
      -0.038602545857429504,
      -0.005993502214550972,
      0.005686149932444096,
      0.04566366598010063,
      0.01189725287258625,
      0.036575060337781906,
      -0.04972473531961441,
      0.04268316924571991,
      -0.027488823980093002,
      0.003654591739177704,
      0.00236067664809525,
      0.04833484813570976,
      -0.004818731918931007,
      0.03806697577238083,
      -0.0151639673858881,
      -0.014165840111672878,
      0.016807466745376587,
      0.008831198327243328,
      -0.03462197631597519,
      0.0015813754871487617,
      0.045212335884571075,
      0.009549235925078392,
      0.05119621381163597,
      -0.012425120919942856,
      0.02458648942410946,
      0.11748268455266953,
      0.05352481082081795,
      -0.054914359003305435,
      -0.015594004653394222,
      -0.05967321619391441,
      0.03344146907329559,
      -0.0597902275621891,
      -0.03546822816133499,
      -0.009253693744540215,
      0.021601224318146706,
      0.07710644602775574,
      0.0028738980181515217,
      0.06579643487930298,
      0.05270177870988846,
      -0.011654253117740154,
      -0.014553016051650047,
      -0.011201603338122368,
      -0.045518361032009125,
      -0.04576459527015686,
      0.032115593552589417,
      0.04428764432668686,
      0.03724566474556923,
      -0.037944190204143524,
      0.0023011332377791405,
      -0.020201046019792557,
      0.0174728836864233,
      0.0492110513150692,
      -0.028367334976792336,
      -0.04725469648838043,
      0.038487982004880905,
      0.008019594475626945,
      -0.0204128660261631,
      -0.08996932208538055,
      0.034290190786123276,
      -0.03929935023188591,
      0.00023211358347907662,
      0.003413035534322262,
      0.044359561055898666,
      0.10309561342000961,
      0.018088042736053467,
      0.03272707760334015,
      0.029293131083250046,
      -0.01907106675207615,
      0.03568586707115173,
      -0.02924090251326561,
      -0.014812704175710678,
      0.010717518627643585,
      -0.017482979223132133,
      0.0029078959487378597,
      -0.005501266568899155,
      -0.017807967960834503,
      -0.0012425838503986597,
      -0.10731508582830429,
      0.06302659213542938,
      0.05944744497537613,
      -0.03880397230386734,
      -0.03712770715355873,
      0.05062079057097435,
      -0.003178580431267619,
      0.008103437721729279,
      0.033827025443315506,
      0.06925080716609955,
      -0.02460695058107376,
      -0.023487510159611702,
      -0.011492444202303886,
      -0.008207001723349094,
      0.020285068079829216,
      -0.005323496181517839,
      -0.04259604960680008,
      0.06274395436048508,
      0.15148882567882538,
      0.043486956506967545,
      -0.06783150136470795,
      -0.012275214307010174,
      0.028180820867419243,
      -0.02808188647031784,
      0.010287987999618053,
      0.04535287618637085,
      -0.03702197223901749,
      0.04579949751496315,
      -0.06345145404338837,
      0.016274943947792053,
      -0.017501652240753174,
      -0.034852128475904465,
      -0.0435233935713768,
      0.03909454867243767,
      0.0280267633497715,
      0.04251311719417572,
      -0.06023583188652992,
      -0.03269440680742264,
      -0.04367857798933983,
      0.07956887036561966,
      0.02485872432589531,
      -0.04660331830382347,
      -0.05128484219312668,
      -0.04751373082399368,
      0.03524920716881752,
      0.025114214047789574,
      0.023644598200917244,
      0.03249872103333473,
      -0.018453389406204224,
      0.05931820347905159,
      0.021624838933348656,
      0.034811753779649734,
      0.027806773781776428,
      0.025291820988059044,
      -0.018479175865650177,
      0.025887396186590195,
      -0.0683882087469101,
      -0.046469975262880325,
      -0.005400362424552441,
      -0.012825515121221542,
      -0.015349093824625015,
      -0.049479465931653976,
      0.05575596168637276,
      0.025039495900273323,
      -0.010367040522396564,
      -0.078489288687706,
      -0.003830967703834176,
      0.015043245628476143,
      -0.0604073666036129,
      0.0029541635885834694,
      0.02346276491880417,
      -0.023077571764588356,
      0.08415897190570831,
      0.07156768441200256,
      0.026667872443795204,
      -0.07778418064117432,
      -0.02994745597243309,
      0.045035041868686676,
      0.0017126334132626653,
      0.016896318644285202,
      -0.033234432339668274,
      -0.05420535430312157,
      0.034478943794965744,
      -0.004109450615942478,
      -0.030349863693118095,
      -0.04429459944367409,
      0.0594741590321064,
      -0.05638047307729721,
      -0.07624252140522003,
      0.025662193074822426,
      0.08938877284526825,
      -0.08102165907621384,
      -0.02592877857387066,
      0.0003291140019427985,
      -0.0005287990206852555,
      -0.009442721493542194,
      0.04450945183634758,
      -0.039075031876564026,
      -0.02485942281782627,
      -0.08577907085418701,
      0.0255499966442585,
      -0.07298275828361511,
      0.04112973436713219,
      -0.045351602137088776,
      -0.05153382942080498,
      -0.02579795941710472,
      0.008043384179472923,
      -0.002293667756021023,
      0.03564281761646271,
      0.09149974584579468,
      -0.023936333134770393,
      -0.03260421380400658,
      0.03852983936667442,
      -0.02520393766462803,
      -0.03336156904697418,
      0.011639251373708248,
      0.044620443135499954,
      0.02629859559237957,
      0.01361276675015688,
      0.04889508709311485,
      0.07921124249696732,
      -0.05728829279541969,
      0.05280771479010582,
      0.011901751160621643,
      0.026441313326358795,
      -0.04066246375441551,
      -0.017556343227624893,
      0.03802720457315445,
      -0.030089210718870163,
      -0.00974566861987114,
      -0.04538511484861374,
      -0.017263375222682953,
      0.013295549899339676,
      0.011349905282258987,
      0.049377523362636566,
      -0.013697056099772453,
      0.05931766331195831,
      0.021063754335045815,
      0.01956108771264553,
      -0.026062268763780594,
      -0.032975923269987106,
      -0.05356590077280998,
      0.018940430134534836,
      0.0316174142062664,
      -0.04874766618013382,
      0.07588285952806473,
      -0.04671676456928253,
      -0.046772293746471405,
      0.09014981985092163,
      0.071236252784729,
      -0.05220067873597145,
      -0.036755021661520004,
      0.03341243043541908,
      0.023911863565444946,
      -0.02034398354589939,
      0.05297340080142021,
      -0.021986914798617363,
      -0.0543312206864357,
      0.02607765421271324,
      0.06401009857654572,
      0.004571916535496712,
      0.02400497905910015,
      -0.017552709206938744,
      -0.030630651861429214,
      0.05062946677207947,
      -0.05443045496940613,
      -0.004848372656852007,
      -0.1126207783818245,
      -0.056120287626981735,
      0.041062645614147186,
      -0.048951782286167145,
      -0.05262303724884987,
      0.006442266516387463,
      0.01375661976635456,
      -0.014111684635281563,
      -0.02453918755054474,
      -0.0007237815880216658,
      -0.06344109028577805,
      -0.054605934768915176,
      -0.0033696750178933144,
      0.007908528670668602,
      0.04472712427377701,
      0.025672920048236847,
      -0.055460285395383835,
      -0.05927581712603569,
      -0.0048341332003474236,
      0.07415246218442917,
      -0.05999297648668289,
      0.04945532605051994,
      0.004510057624429464,
      0.011004874482750893,
      0.02505510300397873,
      0.035793736577034,
      -0.040637996047735214,
      -0.058843258768320084,
      0.037675466388463974,
      -0.027177242562174797,
      -0.000463601405499503,
      0.07557204365730286,
      0.008967386558651924,
      0.03325200453400612,
      0.029872827231884003,
      0.05904167518019676,
      0.09102104604244232,
      -0.13554412126541138,
      -0.020796751603484154,
      0.0025314835365861654,
      0.00930276233702898,
      -0.05118841677904129,
      0.05186634138226509,
      0.03678324818611145,
      0.023954622447490692,
      -0.03267744928598404,
      0.0016763771418482065,
      -0.0240419153124094,
      0.03222142904996872,
      -0.03398292139172554,
      -0.01106953900307417,
      -0.07609107345342636,
      0.04600755125284195,
      0.00753783667460084,
      0.046393346041440964,
      -0.02574993297457695,
      0.026010068133473396,
      0.035115066915750504,
      -0.0036855493672192097,
      0.02643556147813797,
      0.10953429341316223,
      -0.04429249465465546,
      0.0005504130385816097,
      -0.006432599388062954,
      -0.009654446505010128,
      -0.018776103854179382,
      -0.023659637197852135,
      -0.0394003726541996,
      -0.029961608350276947,
      -0.01860911399126053,
      -0.045769888907670975,
      -0.04651375114917755,
      0.0218342375010252,
      0.01945139467716217,
      -0.04662695154547691,
      -0.06915096938610077,
      0.00646947743371129,
      -0.07355821877717972,
      0.024720268324017525,
      -0.05397928133606911,
      0.08682813495397568,
      -0.06645777821540833,
      0.025376630946993828,
      -0.06449510902166367,
      0.03161265701055527,
      -0.042091719806194305,
      -0.0007531307637691498,
      0.01976250484585762,
      -0.006388494279235601,
      0.0008392020827159286,
      -0.06650405377149582,
      -0.0015695892507210374,
      -0.001961256843060255,
      0.007666968274861574,
      0.02398724853992462,
      0.010874348692595959,
      0.019255533814430237,
      -0.0035458593629300594,
      -0.053091708570718765,
      -0.022430354729294777,
      -0.024897979572415352,
      -0.00985008105635643,
      0.07797053456306458,
      0.027851609513163567,
      -0.04015234112739563,
      -0.015188072808086872,
      -0.005906959529966116,
      -0.021213851869106293,
      -0.0288590956479311,
      0.00845030602067709,
      -0.0442969873547554,
      0.020777428522706032,
      0.031787529587745667,
      -0.0068177729845047,
      -0.0020214063115417957,
      -0.020041916519403458,
      -0.048675332218408585,
      -0.014654487371444702,
      -0.08325549215078354,
      -0.07234752178192139,
      0.03275034949183464,
      -0.02476675435900688,
      -0.005651236046105623,
      0.03894320875406265,
      0.06190965697169304,
      -0.006845563650131226,
      -0.07785596698522568,
      -0.004877959843724966,
      -0.02633066661655903,
      -0.03722182661294937,
      0.013916568830609322,
      0.06590492278337479,
      -0.07245656102895737,
      0.015612217597663403,
      -0.029443366453051567,
      -0.07429590076208115,
      -0.005350552033632994,
      -0.0013876971788704395,
      0.027384383603930473,
      -0.046627674251794815,
      -0.008226009085774422,
      -0.061823368072509766,
      -0.05592970550060272,
      -0.04057160019874573,
      -0.065256766974926,
      0.054146960377693176,
      -0.003853571368381381,
      0.060261987149715424,
      -0.054579172283411026,
      -0.018536394461989403,
      0.0453561507165432,
      0.04764750599861145,
      -0.0054636187851428986,
      -0.013772891834378242,
      0.004435625392943621,
      -0.05028929188847542,
      0.056433189660310745,
      0.04720831662416458,
      0.013385187834501266,
      0.0612255297601223,
      0.003938339184969664,
      0.006065667606890202,
      0.004473258275538683,
      0.02399180829524994,
      -0.018988091498613358,
      0.10728344321250916,
      0.014943520538508892,
      -0.04131780564785004,
      -0.047720301896333694,
      0.060533128678798676,
      0.03624013066291809,
      -0.02017855830490589,
      0.043555568903684616,
      0.022930463775992393,
      0.02496500127017498,
      -0.004812571220099926,
      -0.03999968245625496,
      -0.03673943504691124,
      -0.058489613234996796,
      -0.03684558346867561,
      -0.03291874751448631,
      -0.04436596482992172,
      0.01606423780322075,
      0.043948423117399216,
      0.026272986084222794,
      -0.01124919205904007,
      0.003971153870224953,
      0.06340876966714859,
      -0.03638260439038277,
      -0.026506513357162476,
      -0.056680526584386826,
      -0.038994792848825455,
      -0.08789575099945068,
      -0.005367442965507507,
      -0.02874506637454033,
      0.06382795423269272,
      0.07084530591964722,
      0.11220253258943558,
      -0.03637595474720001,
      -0.036989156156778336,
      -0.03618719056248665,
      0.030043749138712883,
      0.000910636386834085,
      0.018521912395954132,
      0.002264586742967367,
      0.09536433219909668,
      -0.02971586212515831,
      -0.06285829097032547,
      -0.01925049163401127,
      0.054835885763168335,
      0.026723934337496758,
      0.04091937467455864,
      0.09128354489803314,
      0.07928656786680222,
      0.06319238245487213,
      -0.06545641273260117,
      0.02634628675878048,
      -0.00859881192445755,
      0.06338153034448624,
      -0.10722515732049942,
      0.07003804296255112,
      0.007700290996581316,
      0.01978764496743679,
      0.041193652898073196,
      -0.026408931240439415,
      0.041348446160554886,
      -0.06284013390541077,
      0.07638934254646301,
      -0.019390156492590904,
      -0.05836915597319603,
      0.03636341169476509,
      0.004810723010450602,
      0.031817346811294556,
      0.013099335134029388,
      -0.012803045101463795,
      0.036726854741573334,
      0.05136070027947426,
      -0.0024249497801065445,
      0.002921250183135271,
      0.024383561685681343,
      -0.022312097251415253,
      -0.0052259317599236965,
      -0.06519076973199844,
      0.003990024793893099,
      -0.004066747613251209,
      0.018489524722099304,
      0.03757668286561966,
      -0.04283853992819786,
      -0.039990998804569244
    ],
    [
      0.017068881541490555,
      -0.05491724982857704,
      0.03129122033715248,
      -0.09922745078802109,
      0.014356832019984722,
      -0.008754092268645763,
      -0.02351677231490612,
      0.030931225046515465,
      0.10821405798196793,
      0.03070337325334549,
      -0.039580874145030975,
      0.00678580766543746,
      -0.014635160565376282,
      0.17864827811717987,
      0.04705223813652992,
      0.045893166214227676,
      0.037409424781799316,
      -0.008720454759895802,
      -0.002890197327360511,
      0.008266252465546131,
      -0.05681948363780975,
      -0.08590451627969742,
      -0.025818824768066406,
      0.021883228793740273,
      -0.07167290896177292,
      0.01515837013721466,
      -0.030263926833868027,
      0.08345934003591537,
      0.042704083025455475,
      -0.002010551979765296,
      -0.018385501578450203,
      0.03692776337265968,
      -0.008660097606480122,
      -0.0507274866104126,
      0.029794227331876755,
      -0.06946691870689392,
      -0.05208893120288849,
      -0.0021851893980056047,
      0.07018965482711792,
      0.023358626291155815,
      0.05566829442977905,
      -0.04183372110128403,
      -0.021526742726564407,
      0.028896160423755646,
      -0.005671981256455183,
      0.08153219521045685,
      0.03310594707727432,
      0.039469003677368164,
      -0.000530624995008111,
      0.028453700244426727,
      -0.005334845744073391,
      -0.01848245970904827,
      0.07023981958627701,
      -0.011928158812224865,
      0.07406727969646454,
      0.013511622324585915,
      -0.04174117371439934,
      0.035940125584602356,
      -0.0801701471209526,
      -0.028022920712828636,
      0.04963522404432297,
      0.0022960868664085865,
      -0.027828866615891457,
      -0.016979362815618515,
      0.03432779386639595,
      0.030542239546775818,
      -0.040267862379550934,
      -0.037235286086797714,
      -0.0021776759531348944,
      -0.08814884722232819,
      -0.06625713407993317,
      -0.0032923026010394096,
      0.0042601884342730045,
      0.02409423142671585,
      0.00675000436604023,
      0.0012608305551111698,
      -0.022756632417440414,
      -0.08276849240064621,
      0.011625864543020725,
      0.07299961149692535,
      -0.019604310393333435,
      0.035393521189689636,
      -0.019791899248957634,
      -0.0300042275339365,
      -0.04712551832199097,
      0.028152864426374435,
      0.08969556540250778,
      0.006325313821434975,
      0.01926301419734955,
      0.03866325691342354,
      0.03934824466705322,
      -0.027146289125084877,
      -0.01988556981086731,
      -0.02676394209265709,
      0.01991182006895542,
      0.09397189319133759,
      -0.01222940068691969,
      -0.03349272161722183,
      -0.0031484593637287617,
      -0.08034837245941162,
      0.03855202719569206,
      0.03494981676340103,
      -0.03243719041347504,
      0.030404476448893547,
      0.012773368507623672,
      0.04570116847753525,
      0.08597704768180847,
      -0.0026722168549895287,
      0.08462139964103699,
      0.09877069294452667,
      0.1010117158293724,
      -0.010453776456415653,
      0.08752404153347015,
      0.023738795891404152,
      -0.04568782448768616,
      0.06040625274181366,
      0.04891149327158928,
      0.0850617066025734,
      -0.06547775864601135,
      0.022665293887257576,
      -0.0031692394986748695,
      -0.0641181692481041,
      0.02058052271604538,
      0.0035901351366192102,
      -0.0222809836268425,
      0.018914498388767242,
      -0.017108533531427383,
      0.03896911069750786,
      -0.02664906717836857,
      -0.010575523599982262,
      0.022975366562604904,
      0.05670933052897453,
      -0.05757107213139534,
      0.025311777368187904,
      -0.06624101102352142,
      -0.0004816305881831795,
      0.023778067901730537,
      0.04407631978392601,
      -0.0019226321019232273,
      0.02419196255505085,
      -0.1071169301867485,
      -0.02354930341243744,
      0.08641619980335236,
      -0.05712360516190529,
      -0.04585868492722511,
      0.0059746792539954185,
      0.06620200723409653,
      0.016499899327754974,
      -0.04861807823181152,
      -0.030549244955182076,
      -0.03210536390542984,
      0.04431037977337837,
      0.031227003782987595,
      -0.00045847176807001233,
      -0.11463993787765503,
      -0.03940955922007561,
      -0.05919772386550903,
      -0.04023001343011856,
      0.07489048689603806,
      -0.00857050996273756,
      0.026281416416168213,
      -0.03015068732202053,
      -0.07442942261695862,
      0.06485839933156967,
      -0.008167183957993984,
      -0.040935151278972626,
      0.035709965974092484,
      0.014986284077167511,
      0.017000645399093628,
      0.014131894335150719,
      0.004010792821645737,
      -0.04690660163760185,
      -0.049297694116830826,
      -0.007777003571391106,
      0.07163294404745102,
      -0.08134251832962036,
      0.0036625717766582966,
      -0.037096261978149414,
      0.016178186982870102,
      0.0272102989256382,
      0.02113567665219307,
      -0.01004295889288187,
      0.0067553273402154446,
      0.05013341084122658,
      -0.016363881528377533,
      -0.030454248189926147,
      0.008677933365106583,
      -0.04784773662686348,
      0.047775860875844955,
      -0.02215258590877056,
      -0.06029774621129036,
      0.06961965560913086,
      -0.03635088726878166,
      0.013729147613048553,
      -0.06618394702672958,
      0.05697778984904289,
      0.07446727901697159,
      0.003506640437990427,
      -0.07130668312311172,
      -0.06492874026298523,
      -0.03259320929646492,
      -0.03419002518057823,
      -0.07707090675830841,
      -0.05165814980864525,
      0.05904325842857361,
      0.06730139255523682,
      0.04225224256515503,
      -0.03750910609960556,
      0.05698124319314957,
      -0.018272563815116882,
      -0.02914518490433693,
      -0.023058993741869926,
      -0.03389419615268707,
      0.06788486987352371,
      0.021587137132883072,
      -0.02541423588991165,
      -0.07511121034622192,
      0.03524106368422508,
      -0.005047865677624941,
      0.040547724813222885,
      0.006681771948933601,
      -0.023075086995959282,
      -0.06839289516210556,
      -0.039643287658691406,
      -0.007054340094327927,
      -0.05199510604143143,
      0.00021583707712125033,
      -0.0326705202460289,
      -0.06482705473899841,
      -0.005348155274987221,
      -0.08083096146583557,
      0.035605888813734055,
      0.023924028500914574,
      0.026459960266947746,
      -0.0025786305777728558,
      0.010630696080625057,
      -0.03028380498290062,
      0.026238856837153435,
      -0.0008956704405136406,
      0.06835994869470596,
      -0.030153313651680946,
      -0.020690616220235825,
      0.04065385460853577,
      -0.005615299567580223,
      -0.011083745397627354,
      0.0012219735654070973,
      0.02550152689218521,
      -0.013898306526243687,
      0.036689866334199905,
      0.04033530503511429,
      0.022501638159155846,
      0.027844654396176338,
      -0.0460495762526989,
      -0.0035184421576559544,
      -0.06568043678998947,
      0.03413083776831627,
      0.01295312400907278,
      -0.043608084321022034,
      -0.02541743963956833,
      0.054431747645139694,
      -0.04690449312329292,
      0.03702162206172943,
      -0.025676513090729713,
      0.059732042253017426,
      0.0628797709941864,
      0.14338095486164093,
      -0.028462663292884827,
      0.019176146015524864,
      -0.05684947595000267,
      -0.027623159810900688,
      -0.07381252199411392,
      0.003664326388388872,
      0.09655683487653732,
      0.053538188338279724,
      0.04133172333240509,
      -0.03010844811797142,
      0.03639210760593414,
      0.02282864786684513,
      -0.006728101056069136,
      0.08701913803815842,
      0.037717465311288834,
      -0.008156318217515945,
      0.03014584444463253,
      -0.022474490106105804,
      -0.05668538436293602,
      0.04801760986447334,
      -0.029506158083677292,
      0.09117531776428223,
      0.005550360307097435,
      0.004516431130468845,
      -0.019924309104681015,
      -0.0630357638001442,
      -0.0002130462962668389,
      0.004168270155787468,
      -0.023682905361056328,
      0.06064818054437637,
      0.04614075645804405,
      0.03873554617166519,
      0.006082009989768267,
      -0.027357354760169983,
      -0.02653133124113083,
      -0.009205928072333336,
      0.035377465188503265,
      -0.032400116324424744,
      -0.04588974639773369,
      -0.059323132038116455,
      -0.07509288936853409,
      0.006736715789884329,
      -0.08964142948389053,
      -0.006739096250385046,
      0.018992692232131958,
      0.010654822923243046,
      0.01652577891945839,
      -0.036744434386491776,
      0.04469165578484535,
      0.007521141320466995,
      -0.011397096328437328,
      -0.03394431620836258,
      -0.024981357157230377,
      0.023831795901060104,
      -0.023583726957440376,
      -0.04029149189591408,
      0.08078217506408691,
      0.07781162112951279,
      0.02606089785695076,
      0.021556682884693146,
      -0.08519092202186584,
      0.13130176067352295,
      -0.05819815769791603,
      -0.04944245517253876,
      -0.0411674864590168,
      -0.014025853015482426,
      -0.049991875886917114,
      -0.04282231256365776,
      -0.020894359797239304,
      0.07868586480617523,
      0.024502098560333252,
      -0.06866040080785751,
      -0.0364578515291214,
      -0.03287007287144661,
      0.05929841101169586,
      -0.07683158665895462,
      -0.04179532080888748,
      -0.02252514660358429,
      0.014374949969351292,
      -0.05242598056793213,
      0.0008759727352298796,
      0.032899584621191025,
      0.002054202137514949,
      0.033375754952430725,
      -0.049293335527181625,
      -0.02025921270251274,
      0.08852443099021912,
      0.0020346951205283403,
      0.035987772047519684,
      0.036622997373342514,
      0.05980672314763069,
      0.06131991371512413,
      -0.05356050282716751,
      0.05893316864967346,
      -0.0010681496933102608,
      -0.025274885818362236,
      0.03186316788196564,
      0.006211079191416502,
      -0.06266186386346817,
      0.0282038114964962,
      0.060207549482584,
      0.0027551206294447184,
      -0.019625311717391014,
      0.039677444845438004,
      0.038782209157943726,
      -0.00323214428499341,
      -0.048114582896232605,
      0.07348454743623734,
      -0.04398932680487633,
      0.04302167519927025,
      0.008332252502441406,
      -0.010305291973054409,
      0.006484213750809431,
      0.01586940512061119,
      0.009596227668225765,
      -0.04630553349852562,
      -0.019773660227656364,
      0.015152539126574993,
      -0.011202546767890453,
      0.03493319824337959,
      0.0063033332116901875,
      0.08837740123271942,
      -0.00800585001707077,
      0.06770279258489609,
      0.03969412297010422,
      0.013702886179089546,
      0.05593322589993477,
      -0.0025891598779708147,
      -0.0009196032187901437,
      -0.07508453726768494,
      -0.06036459282040596,
      -0.009060519747436047,
      0.08234516531229019,
      -0.04817560687661171,
      0.009463995695114136,
      -0.0006025292677804828,
      -0.007804742082953453,
      -0.0019276522798463702,
      0.08943875879049301,
      -0.045130953192710876,
      0.030401967465877533,
      0.04760453850030899,
      0.029141783714294434,
      -0.03420402482151985,
      0.027556192129850388,
      0.04839515686035156,
      0.05291924998164177,
      -0.016183428466320038,
      -0.016720818355679512,
      0.06292649358510971,
      -0.07700297981500626,
      0.004690071567893028,
      0.03368327021598816,
      0.05839487537741661,
      -0.026203222572803497,
      -0.053471386432647705,
      0.04691893234848976,
      0.020652752369642258,
      0.05130072683095932,
      -0.06524640321731567,
      0.05380624532699585,
      -0.005096654407680035,
      0.01836669258773327,
      0.10160320997238159,
      -0.03614296764135361,
      0.002671443857252598,
      0.09434901922941208,
      0.03346577286720276,
      -0.0033253985457122326,
      0.05553087964653969,
      -0.017915401607751846,
      0.06931837648153305,
      0.012589623220264912,
      -0.013777658343315125,
      0.035715024918317795,
      -0.02879035286605358,
      -0.04899408295750618,
      -0.030561046674847603,
      -0.008298046886920929,
      0.04581272602081299,
      0.04179886728525162,
      0.037334639579057693,
      0.04357845336198807,
      0.07769469171762466,
      0.02730858512222767,
      -0.05658777803182602,
      -0.0391601137816906,
      0.018328363075852394,
      0.08630873262882233,
      -0.008955508470535278,
      -0.0057235825806856155,
      -0.027089601382613182,
      -0.05272297561168671,
      0.05909374728798866,
      -0.06523949652910233,
      0.007324497681111097,
      -0.033216580748558044,
      0.0540495403110981,
      -0.07402283698320389,
      0.026789549738168716,
      0.020075509324669838,
      0.007422857917845249,
      0.11085481196641922,
      -0.010352938435971737,
      -0.018747257068753242,
      -0.1269759088754654,
      -0.015270106494426727,
      -0.04052096977829933,
      -0.03221858665347099,
      0.015469073317945004,
      0.013611191883683205,
      -0.08227259665727615,
      -0.022257722914218903,
      0.01979256048798561,
      -0.04080481082201004,
      0.04920539632439613,
      0.0327567383646965,
      0.048250023275613785,
      0.024072658270597458,
      -0.026387525722384453,
      0.026156937703490257,
      -0.0024610997643321753,
      -0.003751285606995225,
      0.04859321564435959,
      0.01044575497508049,
      0.05279483273625374,
      -0.08164354413747787,
      -0.03235945478081703,
      -0.00014946353621780872,
      -0.008920852094888687,
      0.0334375724196434,
      0.12381847947835922,
      -0.004491353873163462,
      -0.02932710573077202,
      0.040131088346242905,
      -0.04693479463458061,
      0.023424867540597916
    ],
    [
      0.01915588229894638,
      -0.010778342373669147,
      0.0596940778195858,
      -0.011894885450601578,
      0.016477204859256744,
      -0.024176744744181633,
      0.027892382815480232,
      0.05997886136174202,
      0.02703038789331913,
      0.09829916059970856,
      -0.00274610030464828,
      0.026493335142731667,
      0.033076923340559006,
      0.08343726396560669,
      0.02005653828382492,
      0.008395065553486347,
      0.04986697807908058,
      0.03131048381328583,
      0.002900336869060993,
      0.043908413499593735,
      0.04622834175825119,
      0.13235759735107422,
      -0.011289783753454685,
      0.047704555094242096,
      -0.15796105563640594,
      -0.04000316932797432,
      -0.018930498510599136,
      0.07355620712041855,
      -0.01821833662688732,
      0.05893624946475029,
      0.060877617448568344,
      0.04395294189453125,
      0.002821478061378002,
      -0.08618611842393875,
      -0.05990768224000931,
      -0.037581734359264374,
      -0.03348749503493309,
      -0.004664147738367319,
      -0.007734846323728561,
      0.02132590301334858,
      0.1159079447388649,
      0.031185002997517586,
      0.035380732268095016,
      -0.01729251816868782,
      0.09376312792301178,
      0.04150088131427765,
      -0.010107806883752346,
      0.03486854210495949,
      0.044722624123096466,
      -0.013044615276157856,
      -0.01515984907746315,
      0.030338311567902565,
      0.013127991929650307,
      0.040366824716329575,
      0.0049369921907782555,
      -0.0042350757867097855,
      -0.041587915271520615,
      -0.0029772177804261446,
      0.025922875851392746,
      0.10096388310194016,
      -0.0022516362369060516,
      -0.003856709459796548,
      -0.05240657553076744,
      0.024873949587345123,
      0.03397895023226738,
      -0.04683198779821396,
      -0.03711487352848053,
      -0.007506935391575098,
      0.06879942119121552,
      -0.06857093423604965,
      0.04790019616484642,
      0.05879905819892883,
      0.11705993115901947,
      -0.05722583085298538,
      -0.04549887403845787,
      0.04564866051077843,
      0.051036156713962555,
      -0.033972568809986115,
      0.06565091758966446,
      0.05717538669705391,
      -0.09208288788795471,
      -0.041318584233522415,
      -0.055131569504737854,
      -0.004032214172184467,
      -0.009178281761705875,
      0.008078519254922867,
      0.025147313252091408,
      -0.0146024813875556,
      0.060404643416404724,
      0.017877714708447456,
      -0.02211686782538891,
      -0.0387972816824913,
      -0.02933363988995552,
      -0.00761353550478816,
      -0.037359315901994705,
      0.07093705981969833,
      0.006002024747431278,
      -0.10159146785736084,
      0.07277019321918488,
      -0.04856672137975693,
      0.08338837325572968,
      0.06861419975757599,
      0.027009543031454086,
      -0.04077162593603134,
      0.04405078664422035,
      0.03614535927772522,
      0.03713033348321915,
      -0.014674239791929722,
      -0.04702822118997574,
      0.0747421458363533,
      0.046668801456689835,
      -0.018179073929786682,
      -0.006962087005376816,
      0.03359011188149452,
      0.02768898196518421,
      0.06153460964560509,
      0.02278546243906021,
      -0.012882273644208908,
      0.013093744404613972,
      -0.01559741236269474,
      0.03506598621606827,
      -0.031643353402614594,
      0.009915642440319061,
      0.047302473336458206,
      -0.04141860455274582,
      0.030001314356923103,
      0.014347118325531483,
      0.07201886922121048,
      -0.05154743790626526,
      0.02474721521139145,
      -0.02346177212893963,
      0.05116840824484825,
      0.005559100303798914,
      -0.0049257841892540455,
      -0.0715266540646553,
      0.010659185238182545,
      0.005504779051989317,
      -0.08200031518936157,
      0.01203105691820383,
      -0.028750142082571983,
      0.03568786010146141,
      -0.017329921945929527,
      -0.05074583366513252,
      0.01902005821466446,
      -0.08723556250333786,
      -0.02317768521606922,
      -0.028191449120640755,
      -0.0035919244401156902,
      -0.034256212413311005,
      0.05006717890501022,
      0.03771444410085678,
      -0.04147857055068016,
      0.002900837454944849,
      0.05359257012605667,
      -0.015548245050013065,
      0.012682055123150349,
      0.046645596623420715,
      0.0486636683344841,
      -0.030406702309846878,
      -0.053954921662807465,
      -0.02909889817237854,
      0.045516934245824814,
      -0.10941092669963837,
      -0.0036350497975945473,
      0.031760722398757935,
      0.030529972165822983,
      0.013691063970327377,
      -0.022510528564453125,
      -0.03257707506418228,
      -0.007401429582387209,
      0.07602864503860474,
      -0.032132115215063095,
      -0.06490175426006317,
      -0.048425983637571335,
      -0.043621331453323364,
      -0.039172571152448654,
      0.06254411488771439,
      0.05281316488981247,
      0.07302629947662354,
      -0.007588693872094154,
      0.013547846116125584,
      -0.0037138485349714756,
      -0.0012898828135803342,
      0.01041372399777174,
      0.05854661017656326,
      0.014648807235062122,
      -0.006144244689494371,
      -0.01327013410627842,
      -0.015942808240652084,
      0.044116660952568054,
      -0.08278840780258179,
      -0.006673476658761501,
      0.05444224923849106,
      0.08445186913013458,
      -0.010814695619046688,
      -0.00042032511555589736,
      0.011610127985477448,
      -0.02787487581372261,
      -0.04294925928115845,
      0.0162136759608984,
      -0.034603651612997055,
      0.04551423341035843,
      -0.0322503037750721,
      0.059201255440711975,
      -0.011900006793439388,
      -0.03684791550040245,
      0.05659367889165878,
      -0.050229694694280624,
      -0.0026555985677987337,
      0.013200563378632069,
      0.007505899295210838,
      -0.007818124257028103,
      0.01766236126422882,
      0.08058507740497589,
      -0.01189878024160862,
      -0.08974041789770126,
      0.011309439316391945,
      0.019330671057105064,
      0.06753064692020416,
      -0.07455593347549438,
      -0.024847835302352905,
      0.04646570235490799,
      0.023875875398516655,
      -0.04234931245446205,
      -0.030775776132941246,
      -0.017689445987343788,
      -0.05705896392464638,
      -0.004949119407683611,
      -0.13438096642494202,
      -0.060580480843782425,
      -0.027139930054545403,
      0.01891442947089672,
      -0.042197324335575104,
      0.03902940824627876,
      -0.051675595343112946,
      -0.0021917661651968956,
      -0.0045499117113649845,
      -0.04166470468044281,
      0.0738741010427475,
      0.031911153346300125,
      0.001699071959592402,
      0.05094284191727638,
      -0.008595487102866173,
      0.03940281644463539,
      -0.0636494979262352,
      0.0036587854847311974,
      0.018887605518102646,
      0.09357181191444397,
      0.024134330451488495,
      -0.07628322392702103,
      0.03486960381269455,
      0.03431650623679161,
      0.018638720735907555,
      -0.02871653065085411,
      0.009293143637478352,
      -0.051280975341796875,
      -0.054888468235731125,
      -0.0815126895904541,
      -0.03437548130750656,
      0.03193436563014984,
      0.04667426645755768,
      0.07324249297380447,
      -0.04040595144033432,
      -0.042641304433345795,
      0.026801185682415962,
      -0.012656223960220814,
      0.007083762902766466,
      -0.043770741671323776,
      -0.0553666427731514,
      0.03463933989405632,
      -0.002051021670922637,
      -0.002985710045322776,
      0.018785227090120316,
      0.026093747466802597,
      -0.07907719165086746,
      -0.05087009817361832,
      0.04437438026070595,
      0.024266857653856277,
      0.0627288892865181,
      -0.010851690545678139,
      0.03931726515293121,
      -0.06055891141295433,
      0.006075217388570309,
      0.03825727850198746,
      0.012271911837160587,
      -0.00354373874142766,
      0.02223176322877407,
      0.030626516789197922,
      0.051389019936323166,
      -0.019540870562195778,
      0.0627581924200058,
      -0.11563995480537415,
      0.05215030908584595,
      0.03851480036973953,
      0.10240694135427475,
      0.018877776339650154,
      0.015046518296003342,
      -0.03562261536717415,
      0.010792024433612823,
      0.03850960731506348,
      -0.009923934936523438,
      -0.0887192115187645,
      -0.05816103145480156,
      0.08066247403621674,
      -0.01451572123914957,
      0.008359667845070362,
      -0.02031049132347107,
      -0.005343855824321508,
      0.0158385057002306,
      0.07429381459951401,
      0.02005252242088318,
      -0.024834604933857918,
      -0.031727321445941925,
      0.014472976326942444,
      0.019438402727246284,
      0.055637769401073456,
      -0.061433594673871994,
      -0.002709457417950034,
      -0.03648768365383148,
      8.805422112345695e-05,
      -0.03842682018876076,
      -0.005952675826847553,
      0.06954823434352875,
      -0.028830207884311676,
      -0.019962212070822716,
      -0.04039497300982475,
      0.004662623163312674,
      -0.07926814258098602,
      -0.0013903506333008409,
      0.0402955561876297,
      -0.06139820069074631,
      0.01901787891983986,
      0.0626194104552269,
      -0.12017270177602768,
      -0.052084192633628845,
      0.01794486492872238,
      -0.060837000608444214,
      -0.0634937435388565,
      -0.06258880347013474,
      0.0376950167119503,
      -0.007571265567094088,
      -0.052641741931438446,
      0.012739850208163261,
      -0.014890467748045921,
      -0.07854879647493362,
      0.06258723884820938,
      -0.08799228817224503,
      -0.12111251801252365,
      0.0032883030362427235,
      0.0037936540320515633,
      -0.042940061539411545,
      0.0554375983774662,
      -0.06926896423101425,
      -0.03919025510549545,
      0.01644766330718994,
      0.05183523893356323,
      0.027504192665219307,
      -0.032488349825143814,
      -0.014042984694242477,
      0.02503514662384987,
      0.013949528336524963,
      -0.054162777960300446,
      -0.08101736009120941,
      -0.046905823051929474,
      -0.006299447733908892,
      0.05587002635002136,
      -0.010303409770131111,
      0.03295251354575157,
      0.023168563842773438,
      -0.1023269072175026,
      0.017563384026288986,
      -0.07348094135522842,
      0.08640266954898834,
      0.06950340420007706,
      0.013846035115420818,
      -0.016207203269004822,
      0.0507916659116745,
      0.013900712132453918,
      0.010728246532380581,
      0.03941943123936653,
      0.04348033666610718,
      0.014161068946123123,
      0.00788724236190319,
      -0.0051678684540092945,
      -0.04264171048998833,
      0.044739123433828354,
      0.022745046764612198,
      -0.021937796846032143,
      0.04303186386823654,
      -0.060802895575761795,
      -0.05335542932152748,
      0.07487171143293381,
      -0.05709362030029297,
      -0.04165499284863472,
      0.025136204436421394,
      -0.016945451498031616,
      -0.008161989971995354,
      -0.022107165306806564,
      -0.053119078278541565,
      -0.07261666655540466,
      -0.051005128771066666,
      -0.03274192661046982,
      0.04511871188879013,
      -0.0008028857409954071,
      0.03782514110207558,
      -0.051069874316453934,
      0.04999135434627533,
      -0.020467447116971016,
      0.030908886343240738,
      0.02213778719305992,
      0.06572264432907104,
      0.005640595685690641,
      0.0017560035921633244,
      0.014020105823874474,
      -0.01186433993279934,
      0.009003080427646637,
      0.014973681420087814,
      -0.0330740362405777,
      -0.0031765669118613005,
      0.10633942484855652,
      0.001510788919404149,
      0.051682986319065094,
      0.0285814069211483,
      -0.005878881551325321,
      -0.05943708494305611,
      -0.03725507855415344,
      0.06477008014917374,
      -0.015515251085162163,
      0.06687576323747635,
      -0.02168894372880459,
      0.04642041400074959,
      -0.010088905692100525,
      -0.022021546959877014,
      -0.06132890284061432,
      0.009913044981658459,
      -0.009610876441001892,
      0.028900716453790665,
      0.02838958241045475,
      -0.04634537920355797,
      -0.05015530809760094,
      0.02610877715051174,
      0.006490900181233883,
      -0.026119429618120193,
      0.029470158740878105,
      0.01882760040462017,
      -0.014988896436989307,
      -0.027678001672029495,
      0.05340409278869629,
      0.04743599891662598,
      -0.06745368987321854,
      -0.013163994066417217,
      0.04342764988541603,
      -0.04282331094145775,
      -0.05450926721096039,
      0.015054603107273579,
      -0.0025269491598010063,
      0.07034024596214294,
      0.03344132378697395,
      0.036327064037323,
      0.06518092751502991,
      -0.004961018916219473,
      -0.03052363358438015,
      -0.03583850339055061,
      0.10117724537849426,
      -0.010091472417116165,
      0.0966014415025711,
      -0.008842566050589085,
      -0.06482187658548355,
      -0.04529416188597679,
      0.024757463485002518,
      -0.05313742160797119,
      0.029306208714842796,
      0.06885550171136856,
      0.03296521306037903,
      -0.03966460004448891,
      -0.0436493344604969,
      0.059157051146030426,
      0.005353037733584642,
      0.03662141039967537,
      -0.0043856482952833176,
      -0.10397619009017944,
      0.0060626971535384655,
      0.01180960237979889,
      0.056943416595458984,
      0.0334409661591053,
      -0.07449954003095627,
      0.037396274507045746,
      0.03650425001978874,
      -0.03248928487300873,
      0.06800509244203568,
      0.03793689236044884,
      0.046933215111494064,
      -0.022003455087542534,
      -0.004252940881997347,
      -0.05455070734024048,
      -0.024520812556147575,
      -0.040977317839860916,
      0.04784637689590454,
      -0.0041047013364732265,
      -0.048112042248249054,
      0.08484455943107605,
      0.009357470087707043,
      0.06240619719028473
    ],
    [
      -0.05310006067156792,
      -0.00887928158044815,
      -0.002045170171186328,
      -0.05623137950897217,
      -0.051717452704906464,
      -0.01687975786626339,
      0.05886518582701683,
      0.018738025799393654,
      0.05230427160859108,
      -0.022677304223179817,
      0.059790920466184616,
      -0.011371484026312828,
      0.05095360055565834,
      -0.012236904352903366,
      0.08665112406015396,
      0.01982920430600643,
      -0.06268724799156189,
      -0.010914052836596966,
      -0.026801763102412224,
      0.004055170342326164,
      0.004520004149526358,
      -0.023972179740667343,
      -0.06299055367708206,
      -0.035696543753147125,
      0.008564548566937447,
      0.0064689163118600845,
      0.014863076619803905,
      -0.1168486475944519,
      -0.024488026276230812,
      -0.0005842385580763221,
      0.05994890257716179,
      -0.010696742683649063,
      0.04442036524415016,
      -0.02834019809961319,
      0.08751380443572998,
      -0.010896219871938229,
      -0.02940484695136547,
      2.389756264165044e-05,
      0.03280105069279671,
      -0.03737793117761612,
      0.14729037880897522,
      0.04587114229798317,
      0.014338658191263676,
      0.03227119892835617,
      -0.06202440336346626,
      -0.0010221968404948711,
      -0.019809693098068237,
      -0.02443496696650982,
      -0.009851698763668537,
      0.002867496106773615,
      -0.05543332174420357,
      0.0129198357462883,
      -0.00507088378071785,
      0.045729346573352814,
      -0.02477136254310608,
      0.04443858563899994,
      0.009970929473638535,
      -0.006980500649660826,
      -0.0222338754683733,
      -0.00877001229673624,
      0.015026967972517014,
      -0.02126881293952465,
      0.024532731622457504,
      0.04916732758283615,
      0.10087019205093384,
      0.027947289869189262,
      -0.048478782176971436,
      0.042924828827381134,
      -0.0456283763051033,
      -0.07217205315828323,
      -0.040122319012880325,
      -0.03874105587601662,
      0.0034114765003323555,
      -0.021560627967119217,
      0.03310022130608559,
      -0.06591597199440002,
      -0.053554631769657135,
      -0.03537879139184952,
      0.006898004561662674,
      -0.07007796317338943,
      -0.01132542360574007,
      0.019683849066495895,
      -0.005837088916450739,
      -0.037392325699329376,
      -0.09033125638961792,
      0.056327540427446365,
      -0.005060584284365177,
      -0.022657621651887894,
      0.059889357537031174,
      -0.035687558352947235,
      -0.09107588976621628,
      0.06479331851005554,
      -0.013793383724987507,
      -0.025957806035876274,
      0.02682453580200672,
      -0.07813187688589096,
      -0.03639687970280647,
      0.023827295750379562,
      0.04524078592658043,
      0.007863275706768036,
      0.053940579295158386,
      0.005438094958662987,
      0.0058958823792636395,
      -0.0038861646316945553,
      0.03331128507852554,
      -0.13210949301719666,
      -0.014790660701692104,
      -0.00821783859282732,
      -0.02083856426179409,
      0.09525914490222931,
      -0.009166684001684189,
      -0.024696258828043938,
      -0.004749567713588476,
      0.05596831813454628,
      0.05725134164094925,
      -0.09201142191886902,
      0.008246693760156631,
      0.06261537224054337,
      0.053730182349681854,
      -0.009780786000192165,
      0.07709519565105438,
      0.009127805009484291,
      0.04306774213910103,
      -0.03221780061721802,
      -0.06415993720293045,
      -0.030551565811038017,
      0.02766665816307068,
      0.07137183099985123,
      -0.010683166794478893,
      -0.02367321029305458,
      0.02054695598781109,
      0.04358002543449402,
      -0.059289056807756424,
      0.11639902740716934,
      0.016445482149720192,
      -0.013504484668374062,
      0.02051161602139473,
      0.015840856358408928,
      -0.02881232462823391,
      0.0035677840933203697,
      0.02940393052995205,
      0.06169988214969635,
      0.008914118632674217,
      -0.008463332429528236,
      -0.007128519471734762,
      0.02214352786540985,
      0.004388430155813694,
      -0.015851011499762535,
      0.04072484001517296,
      0.04382581263780594,
      -0.05974232032895088,
      -0.04637381061911583,
      0.09917763620615005,
      -0.039528120309114456,
      0.038527458906173706,
      0.00792229175567627,
      0.061759237200021744,
      0.07510415464639664,
      0.016943130642175674,
      0.008251248858869076,
      -0.007646557874977589,
      -0.001508592045865953,
      -0.08349322527647018,
      -0.024011876434087753,
      0.04980087652802467,
      -0.0617431178689003,
      -0.018304940313100815,
      0.06434918195009232,
      0.0457247756421566,
      -0.06398668885231018,
      -0.004060185048729181,
      -0.06326619535684586,
      0.02749955654144287,
      -0.060894910246133804,
      0.05761348456144333,
      0.005272143054753542,
      -0.020988883450627327,
      0.04324878379702568,
      0.09220106899738312,
      0.028334325179457664,
      0.016090495511889458,
      9.721169044496492e-05,
      -0.07942713797092438,
      -0.0019866719376295805,
      -0.007773802150040865,
      -0.050848621875047684,
      0.08354324847459793,
      -0.005359059199690819,
      -0.030587945133447647,
      -0.03842054307460785,
      -0.007092202082276344,
      0.025389643386006355,
      -0.07018092274665833,
      -0.01864638552069664,
      -0.043541938066482544,
      -0.0711159035563469,
      -0.06359617412090302,
      -0.010099994018673897,
      -0.023149652406573296,
      0.01583685167133808,
      -0.070005401968956,
      0.01355308573693037,
      -0.0013079611817374825,
      0.02595171518623829,
      0.04369879886507988,
      0.06904356926679611,
      -0.001134670921601355,
      -0.035736627876758575,
      0.03894948214292526,
      -0.010585585609078407,
      -0.04154600203037262,
      0.025965237990021706,
      -0.026757922023534775,
      0.03178035467863083,
      0.01620331220328808,
      0.02968733012676239,
      -0.01377206202596426,
      0.038018982857465744,
      -0.024652957916259766,
      0.05559026449918747,
      0.023764844983816147,
      -0.007266214117407799,
      -0.05464724823832512,
      0.05691908299922943,
      0.03580855578184128,
      -0.010465203784406185,
      0.09578287601470947,
      -0.05808670446276665,
      0.00111957429908216,
      -0.11345664411783218,
      -0.024870121851563454,
      -0.01004739385098219,
      0.052975572645664215,
      0.0015133311972022057,
      0.06530118733644485,
      -0.12353390455245972,
      -0.03801790252327919,
      -0.019996164366602898,
      -0.05383017286658287,
      0.01234645675867796,
      -0.009537912905216217,
      -0.021446824073791504,
      0.053715869784355164,
      -0.06547816097736359,
      0.0009517659200355411,
      0.02882632240653038,
      -0.011240513063967228,
      -0.021013759076595306,
      -0.08361136168241501,
      0.044924963265657425,
      -0.040801532566547394,
      -0.02521223947405815,
      0.008276275359094143,
      0.04554826021194458,
      -0.03017342835664749,
      -0.020152734592556953,
      -0.05038168281316757,
      -0.05541723594069481,
      0.018078476190567017,
      0.014695007354021072,
      -0.01752236858010292,
      -0.016355756670236588,
      -0.07852500677108765,
      0.024234043434262276,
      -0.032428547739982605,
      -0.06300889700651169,
      -0.0007091413135640323,
      -0.08894653618335724,
      -0.017624665051698685,
      0.05225730687379837,
      -0.14848624169826508,
      -0.023154836148023605,
      0.01338804792612791,
      -0.07307617366313934,
      0.0142732635140419,
      -0.04126030579209328,
      -0.040633946657180786,
      -0.0018623165087774396,
      0.07649052143096924,
      0.03312041237950325,
      -0.05197104066610336,
      0.02617349475622177,
      -0.0015322886174544692,
      0.0557841956615448,
      -0.04082798212766647,
      -0.0650939792394638,
      0.0036015775986015797,
      -0.02787647396326065,
      -0.03671553358435631,
      0.03745002672076225,
      -0.02919217199087143,
      -0.04756978526711464,
      0.004162111319601536,
      -0.029489126056432724,
      -0.0348261222243309,
      -0.014825870282948017,
      -0.0021746193524450064,
      -0.05203664302825928,
      -0.06205270439386368,
      0.05591137334704399,
      -0.03487745299935341,
      -0.07046382874250412,
      0.07075958698987961,
      0.0026316202711313963,
      0.026315458118915558,
      0.050219058990478516,
      -0.00517483102157712,
      0.04236866533756256,
      -0.06469669938087463,
      -0.009474940598011017,
      0.026351725682616234,
      -0.05883766710758209,
      -0.036217909306287766,
      -0.09279729425907135,
      0.010062666609883308,
      -0.041993364691734314,
      -0.0656505823135376,
      -0.022550495341420174,
      -0.07662427425384521,
      -0.05645384266972542,
      0.011884068138897419,
      -0.03909760341048241,
      -0.06936242431402206,
      0.027633411809802055,
      0.025890540331602097,
      0.03201162442564964,
      -0.059341706335544586,
      0.055744949728250504,
      0.013490390032529831,
      -0.0345708429813385,
      -0.08260490745306015,
      -0.06141822040081024,
      -0.04694068804383278,
      -0.014921238645911217,
      0.06561680883169174,
      -0.036984317004680634,
      0.09455594420433044,
      -0.07582923769950867,
      0.034930337220430374,
      -0.0363980270922184,
      -0.027987629175186157,
      -0.0621386356651783,
      -0.08627539873123169,
      0.0010554565815255046,
      -0.09298552572727203,
      -0.02431391552090645,
      0.020039472728967667,
      0.030686376616358757,
      0.022880220785737038,
      -0.06999003142118454,
      0.003459959290921688,
      -0.008122050203382969,
      0.07841794937849045,
      0.005795584525913,
      0.010935653932392597,
      -0.07171740382909775,
      -0.048147302120923996,
      0.0399172306060791,
      0.04025670140981674,
      0.009967979975044727,
      -0.037036024034023285,
      -0.07244504988193512,
      0.005812276620417833,
      -0.0539432018995285,
      -0.00036542891757562757,
      -0.037577178329229355,
      -0.028854986652731895,
      0.04310159012675285,
      0.011713865213096142,
      -0.00016445500659756362,
      0.02557075209915638,
      0.040841661393642426,
      -0.03207617625594139,
      0.009784228168427944,
      0.0268354844301939,
      0.005404695402830839,
      0.010874942876398563,
      0.035739604383707047,
      -0.018445933237671852,
      -0.032730910927057266,
      0.003997549880295992,
      -0.007862440310418606,
      0.035164497792720795,
      -0.005237290170043707,
      -0.006579861044883728,
      0.008496703580021858,
      -0.06706131994724274,
      -0.058648642152547836,
      0.07943347096443176,
      0.017446348443627357,
      -0.024870596826076508,
      0.035300932824611664,
      -0.04320591315627098,
      0.003153373021632433,
      0.009135410189628601,
      0.07725661993026733,
      -0.010632235556840897,
      -0.06595064699649811,
      -0.025225792080163956,
      -0.04094278812408447,
      -0.06304477900266647,
      -0.019077355042099953,
      -0.020769745111465454,
      0.03320387750864029,
      0.012460379861295223,
      -0.06061322242021561,
      -0.0005750483251176775,
      -0.00671067601069808,
      0.053911417722702026,
      -0.039365652948617935,
      -0.019417086616158485,
      0.021875523030757904,
      0.04038804769515991,
      0.0001557812502142042,
      -0.02617967315018177,
      0.03628349304199219,
      -0.054881561547517776,
      0.0162250567227602,
      -0.011556115001440048,
      0.021764185279607773,
      0.05255497246980667,
      -0.06879878789186478,
      -0.038082223385572433,
      -0.0817326083779335,
      0.07484413683414459,
      -0.039767589420080185,
      0.011758046224713326,
      0.0335821770131588,
      0.05232469365000725,
      -0.013327832333743572,
      0.06609360873699188,
      -0.004223458003252745,
      -0.05315745249390602,
      0.08866115659475327,
      0.04847177863121033,
      0.024008791893720627,
      0.02668853849172592,
      -0.11165539175271988,
      -0.0733131617307663,
      -0.03800013288855553,
      -0.0029403837397694588,
      -0.05930689722299576,
      -0.030913272872567177,
      -0.01423569954931736,
      0.0558290034532547,
      0.03219634294509888,
      0.003684971248731017,
      0.03903811052441597,
      -0.023503858596086502,
      -0.030331270769238472,
      -0.05259856954216957,
      0.02221175655722618,
      -0.00739198038354516,
      -0.023579154163599014,
      -0.029435571283102036,
      0.03700396418571472,
      -0.005117395892739296,
      0.042351510375738144,
      0.057328369468450546,
      0.001235823961906135,
      -0.02140718698501587,
      -0.04709852486848831,
      -0.0224835816770792,
      0.016215341165661812,
      -0.038690757006406784,
      -0.010605725459754467,
      -0.0011904810089617968,
      0.03884901851415634,
      0.00740034319460392,
      0.009201426059007645,
      -0.015016398392617702,
      0.008674675598740578,
      -0.02195763774216175,
      -0.0741608589887619,
      0.06106593459844589,
      0.014175130985677242,
      -0.05097980797290802,
      -0.029762407764792442,
      -0.037843216210603714,
      0.10533401370048523,
      -0.04964662715792656,
      -0.010394911281764507,
      -0.037797924131155014,
      -0.02107281982898712,
      -0.008288951590657234,
      -0.042419981211423874,
      0.07460314780473709,
      0.01522977463901043,
      0.009010274894535542,
      -0.041482966393232346,
      0.014148097485303879,
      -0.015502044931054115,
      0.0018988236552104354,
      -0.04493483155965805,
      -0.06317049264907837,
      0.01153495628386736,
      0.1235598772764206,
      -0.03812674805521965,
      0.05753982439637184,
      0.00794849544763565,
      0.033088210970163345,
      0.029153309762477875,
      -0.06633517891168594
    ],
    [
      0.05233781784772873,
      0.06434526294469833,
      -0.09379313141107559,
      -0.024656254798173904,
      -0.08476091176271439,
      -0.008502911776304245,
      -0.060777440667152405,
      0.021411435678601265,
      0.05233762413263321,
      0.02511332742869854,
      -0.043942827731370926,
      0.01984453573822975,
      -0.004075408913195133,
      0.08668041229248047,
      0.0046028271317481995,
      -0.02256721630692482,
      0.014097915962338448,
      -0.12075512856245041,
      0.020814688876271248,
      0.014684967696666718,
      0.052653975784778595,
      0.0793512612581253,
      -0.0712062269449234,
      0.04055911302566528,
      -0.009578674100339413,
      0.09097860753536224,
      0.037377193570137024,
      0.05108833685517311,
      0.0022105854004621506,
      -0.06337439268827438,
      0.003252104390412569,
      0.007850561290979385,
      -0.046029508113861084,
      -0.058278873562812805,
      -0.008463017642498016,
      -0.002952156588435173,
      0.0025708177126944065,
      0.008766470476984978,
      -0.01838887482881546,
      -0.026217585429549217,
      -0.0204794742166996,
      0.021030830219388008,
      -0.009642370976507664,
      -0.059768155217170715,
      0.047927215695381165,
      0.030182497575879097,
      -0.03930697962641716,
      -0.030712710693478584,
      -0.042558297514915466,
      0.018756071105599403,
      0.0013770172372460365,
      -0.1198434829711914,
      -0.007140508387237787,
      -0.0059129223227500916,
      0.022919807583093643,
      -0.10780779272317886,
      -0.026237621903419495,
      -0.12136559188365936,
      -0.058237213641405106,
      0.028786126524209976,
      0.03445683419704437,
      0.05529429763555527,
      0.010471670888364315,
      0.08064515888690948,
      -0.0471910759806633,
      0.0261201374232769,
      -0.024285778403282166,
      -0.07430802285671234,
      0.022839289158582687,
      0.01783725433051586,
      0.025796152651309967,
      -0.0002922908461187035,
      -0.042697615921497345,
      0.002122199395671487,
      -0.03731792792677879,
      -0.018337154760956764,
      -0.05396042764186859,
      -0.026893408969044685,
      0.00965221505612135,
      -0.04162919893860817,
      -0.023056430742144585,
      0.05488519370555878,
      -0.027405256405472755,
      0.04098795726895332,
      0.06475643813610077,
      -0.08630802482366562,
      -0.12688550353050232,
      0.009554584510624409,
      0.04639598727226257,
      -0.00317120598629117,
      -0.03174087777733803,
      0.011333458125591278,
      0.017760837450623512,
      -0.012112647294998169,
      0.041484035551548004,
      -0.03031746856868267,
      0.013163020834326744,
      0.043580830097198486,
      0.10522408038377762,
      0.01408403180539608,
      0.00946588721126318,
      0.019356267526745796,
      0.03772306814789772,
      0.05034717172384262,
      -0.037446655333042145,
      -0.02165168523788452,
      0.024387437850236893,
      -0.06349083036184311,
      -0.003934886306524277,
      -0.0004910062998533249,
      -0.009684272110462189,
      0.00837213359773159,
      0.00027768753352575004,
      0.03328872099518776,
      -0.043616652488708496,
      0.006064310669898987,
      0.0026946146972477436,
      0.08404503017663956,
      0.07696247100830078,
      -0.021520407870411873,
      0.11854659765958786,
      0.010086306370794773,
      0.015805518254637718,
      -0.03157151862978935,
      0.005691994447261095,
      0.028731819242239,
      0.012322362512350082,
      0.044105708599090576,
      -0.04943886026740074,
      -0.05905479937791824,
      -0.014844546094536781,
      0.02253451757133007,
      -0.002119000069797039,
      0.1331346482038498,
      0.06044071540236473,
      -0.017497852444648743,
      -0.03043508343398571,
      0.003317517228424549,
      0.05283176153898239,
      -0.006172272842377424,
      0.024016210809350014,
      0.026323853060603142,
      -0.06133751943707466,
      0.002617576392367482,
      -0.07234834879636765,
      -0.019242053851485252,
      -0.01569625549018383,
      0.02583214081823826,
      0.013997333124279976,
      0.04404148459434509,
      0.046042751520872116,
      -0.0074681006371974945,
      -0.04401349648833275,
      0.0004235386732034385,
      -0.04554382339119911,
      0.006073876284062862,
      -0.03525213152170181,
      -0.021988067775964737,
      -0.05625090375542641,
      -0.003850495908409357,
      0.009426889941096306,
      -0.007611517328768969,
      -0.02334110252559185,
      -0.09071848541498184,
      -0.048322729766368866,
      -0.011894728057086468,
      0.02814090996980667,
      -0.010399779304862022,
      -0.006628474686294794,
      -0.053307048976421356,
      0.04188248887658119,
      -0.033126525580883026,
      -0.056756772100925446,
      -0.0482943132519722,
      -0.0006407471955753863,
      -0.019130738452076912,
      0.0025383864995092154,
      -0.01830645464360714,
      0.021130401641130447,
      0.07374994456768036,
      -0.06473273038864136,
      0.014500057324767113,
      -0.06678187847137451,
      0.03826121613383293,
      -0.046308521181344986,
      0.022310370579361916,
      0.007955281995236874,
      0.03081028163433075,
      0.042925018817186356,
      -0.01985948719084263,
      -0.020332779735326767,
      0.0056726764887571335,
      -0.05961420014500618,
      0.0032198107801377773,
      -0.006277681328356266,
      -0.011570712551474571,
      0.06723915040493011,
      -0.056295353919267654,
      -0.07783369719982147,
      0.018859265372157097,
      -0.015424616634845734,
      0.127113476395607,
      -0.06430292129516602,
      0.0008438158547505736,
      0.03429682180285454,
      0.012213705107569695,
      -0.0228438638150692,
      0.014419722370803356,
      -0.0519077442586422,
      0.007465689443051815,
      -0.025967178866267204,
      -0.07896332442760468,
      0.06086268648505211,
      0.11017429828643799,
      -0.016242647543549538,
      -0.04586704447865486,
      -0.028376424685120583,
      -0.04951241984963417,
      0.044635672122240067,
      0.0032982253469526768,
      -0.030923141166567802,
      0.020040055736899376,
      -0.06399515271186829,
      -0.02277977578341961,
      0.0012140212347730994,
      -0.023000121116638184,
      -0.014501173980534077,
      -0.04658772796392441,
      0.022715291008353233,
      -0.04903606325387955,
      -0.09523845463991165,
      0.07758717238903046,
      0.049815673381090164,
      -0.005373853724449873,
      0.05441761761903763,
      0.05256050080060959,
      -0.04123874753713608,
      -0.008410693146288395,
      -0.028408264741301537,
      0.13433009386062622,
      0.021452898159623146,
      -0.016353696584701538,
      0.00016957630577962846,
      -0.037366993725299835,
      -0.0013033464783802629,
      -0.04656375199556351,
      -0.03909191116690636,
      0.042650360614061356,
      -0.018273241817951202,
      -0.02003064937889576,
      -0.04135766252875328,
      0.02460932917892933,
      -0.004460950847715139,
      -0.004094237927347422,
      -0.042855605483055115,
      0.06418319046497345,
      0.08349941670894623,
      -0.02046944573521614,
      -0.09320178627967834,
      -0.042214278131723404,
      0.01260294858366251,
      0.016950715333223343,
      -0.00796013418585062,
      0.04126390814781189,
      -0.03181111067533493,
      0.14600791037082672,
      -0.03602234646677971,
      -0.0035197027027606964,
      -0.017087025567889214,
      -0.044226910918951035,
      -0.023386333137750626,
      0.015114731155335903,
      0.08138866722583771,
      0.023380400612950325,
      -0.02852671965956688,
      -0.042738109827041626,
      0.05813246965408325,
      -0.06375222653150558,
      0.09006290882825851,
      -0.09522784501314163,
      0.09916818141937256,
      0.022955069318413734,
      0.034515559673309326,
      0.09435130655765533,
      -0.06110465154051781,
      0.0034508933313190937,
      0.013213470578193665,
      0.04688524082303047,
      0.03778894990682602,
      0.021419914439320564,
      0.00469097588211298,
      -0.002426339779049158,
      -0.004922863561660051,
      0.0027261851355433464,
      -0.0242157019674778,
      -0.05171457305550575,
      -0.08817887306213379,
      0.01678752526640892,
      0.04836456850171089,
      0.011177773587405682,
      0.018335450440645218,
      -0.035069484263658524,
      0.00014927696611266583,
      -0.010217342525720596,
      -0.00039173205732367933,
      0.02581501565873623,
      0.02182677574455738,
      -0.03929082304239273,
      -0.06146230176091194,
      0.05233222618699074,
      0.0018426586175337434,
      -0.015559275634586811,
      0.012918194755911827,
      -0.08178500086069107,
      0.040118347853422165,
      -0.05448085442185402,
      -0.08227542787790298,
      0.05306653305888176,
      0.029123442247509956,
      -0.04527087137103081,
      -0.0179352518171072,
      -0.043712712824344635,
      -0.07121939957141876,
      0.02325613610446453,
      -0.05399404093623161,
      0.0489669069647789,
      0.006152600981295109,
      0.06305467337369919,
      -0.07460277527570724,
      -0.00759157445281744,
      0.02356475405395031,
      -0.00950365886092186,
      -0.025734014809131622,
      0.03925241157412529,
      -0.054001566022634506,
      0.06612984091043472,
      0.003670371137559414,
      0.04821857810020447,
      -0.05238005146384239,
      -0.04280999302864075,
      0.08038318157196045,
      -0.030410941690206528,
      0.04282740131020546,
      -0.014665385708212852,
      0.026577835902571678,
      -0.07167376577854156,
      -0.002588271861895919,
      0.0034470874816179276,
      0.11908839643001556,
      -0.04871901497244835,
      -0.09012970328330994,
      -0.0022765519097447395,
      0.006569283083081245,
      -0.01756640523672104,
      0.00872815027832985,
      -0.04866509884595871,
      -0.040542080998420715,
      0.06321221590042114,
      -0.03285941109061241,
      -0.018075767904520035,
      -0.08195564895868301,
      0.03567763417959213,
      0.009792480617761612,
      -0.06951361149549484,
      -0.08566915988922119,
      0.0459282323718071,
      -0.01943027600646019,
      0.021032866090536118,
      0.04540686309337616,
      -0.07808239012956619,
      -0.028841301798820496,
      0.02269466407597065,
      -0.000636284239590168,
      0.013592509552836418,
      0.030810225754976273,
      -0.0010110357543453574,
      -0.011985582299530506,
      0.004731114022433758,
      0.035698577761650085,
      0.038837213069200516,
      0.044333670288324356,
      -0.05325882509350777,
      -0.10226388275623322,
      0.0032276890706270933,
      0.018745550885796547,
      0.11829464137554169,
      -0.01785987801849842,
      -0.019126174971461296,
      0.026891103014349937,
      -0.02967449277639389,
      0.034747589379549026,
      0.0428939089179039,
      0.02795354649424553,
      -0.05287462845444679,
      0.0489821694791317,
      -0.042617522180080414,
      -9.512394171906635e-05,
      -0.004017441999167204,
      -0.0245842132717371,
      -0.09421994537115097,
      0.02667042426764965,
      0.0512908399105072,
      -0.08498252183198929,
      -0.027708929032087326,
      0.042696356773376465,
      0.01162357721477747,
      0.03537483140826225,
      -0.0008489523315802217,
      0.04720567911863327,
      -0.04424893110990524,
      -0.0058643571101129055,
      -0.021218841895461082,
      0.030707430094480515,
      0.03666896000504494,
      -0.00820509810000658,
      -0.02600732445716858,
      -0.04959436506032944,
      0.030445637181401253,
      0.03227037191390991,
      0.05550253763794899,
      -0.14571413397789001,
      -0.07653766870498657,
      -0.036044567823410034,
      -0.0764637440443039,
      -0.08441507071256638,
      -0.0070759993977844715,
      -0.09904056787490845,
      -0.06461277604103088,
      -0.06849274784326553,
      0.004684394225478172,
      0.027978871017694473,
      0.023298872634768486,
      0.00422156136482954,
      0.029490342363715172,
      0.04028646647930145,
      -0.036289628595113754,
      -0.08115755021572113,
      0.03940580040216446,
      0.023432273417711258,
      -0.052571214735507965,
      0.02856786735355854,
      -0.008727535605430603,
      0.003648152109235525,
      0.06188235804438591,
      0.03301398456096649,
      0.02255135029554367,
      0.025739407166838646,
      0.006304510403424501,
      -0.08900754898786545,
      0.02067117765545845,
      0.06361543387174606,
      0.03738848865032196,
      0.04019121080636978,
      -0.005480529740452766,
      0.08734839409589767,
      -0.01145500224083662,
      -0.012910502962768078,
      -0.04485652595758438,
      0.01766958273947239,
      0.019444730132818222,
      -0.03545074164867401,
      -0.06048852577805519,
      0.058981284499168396,
      -0.04846835508942604,
      -0.02036132477223873,
      -0.04433726146817207,
      -0.016363395377993584,
      -0.03839040920138359,
      -0.10796770453453064,
      -0.022218478843569756,
      0.0020696488209068775,
      0.011655544862151146,
      -0.02161918766796589,
      0.005949441343545914,
      -0.008647450245916843,
      0.044204551726579666,
      0.07487858086824417,
      -0.031072938814759254,
      -0.022625073790550232,
      -0.06070917472243309,
      0.042622826993465424,
      0.04441850632429123,
      -0.028177684172987938,
      -0.04387325420975685,
      -0.03564365580677986,
      -0.012364618480205536,
      0.03679561987519264,
      -0.03863817825913429,
      -0.020825836807489395,
      0.010660408064723015,
      0.0526130236685276,
      -0.01658918894827366,
      0.004370224662125111,
      0.047383252531290054,
      -0.029309187084436417,
      0.03586046025156975,
      0.027990588918328285,
      0.018046902492642403,
      -0.07822243124246597,
      -0.0588684044778347,
      0.05249903351068497,
      -0.000783436989877373,
      -0.03920949622988701
    ],
    [
      0.007329036947339773,
      0.03201206028461456,
      0.0007604934507980943,
      0.02392013557255268,
      0.01184027548879385,
      -0.018221942707896233,
      0.012491055764257908,
      -0.01202794723212719,
      -0.02194104716181755,
      -0.08175286650657654,
      -0.04733382910490036,
      -0.05334900692105293,
      -0.01891939900815487,
      -0.012325071729719639,
      0.012552877888083458,
      -0.06725242733955383,
      -0.023792782798409462,
      -0.04422193765640259,
      0.01247643306851387,
      -0.03651922196149826,
      0.05700593441724777,
      -0.02779635414481163,
      -0.059229735285043716,
      -0.0005120653659105301,
      -0.11467333137989044,
      0.0695810541510582,
      -0.006889741867780685,
      0.08772072941064835,
      0.0007320723962038755,
      0.062022674828767776,
      0.09810386598110199,
      0.013540353626012802,
      0.00866956077516079,
      -0.05417214334011078,
      0.03437137231230736,
      -0.024160170927643776,
      0.1107538715004921,
      0.004143122583627701,
      -0.09882186353206635,
      -0.10013683885335922,
      0.09972206503152847,
      0.06929520517587662,
      -0.0016803197795525193,
      -0.018951525911688805,
      0.04894958436489105,
      -0.014107096940279007,
      0.0004991514142602682,
      0.04179586470127106,
      -0.018168101087212563,
      0.042200084775686264,
      -0.05748427286744118,
      -0.005478272680193186,
      -0.014708325266838074,
      -0.05209662392735481,
      -0.04008552059531212,
      0.031583305448293686,
      -0.019618263468146324,
      -0.03521055728197098,
      0.022507479414343834,
      0.03088860772550106,
      0.027090316638350487,
      -0.017831305041909218,
      -0.04889935627579689,
      0.02507041022181511,
      0.005454341880977154,
      -0.027792997658252716,
      -0.0199690293520689,
      -0.05263451114296913,
      0.05668666586279869,
      0.02658674493432045,
      -0.0018187876557931304,
      -0.10026813298463821,
      0.026916420087218285,
      -0.026694409549236298,
      -0.004937018267810345,
      0.034246232360601425,
      0.0059525687247514725,
      -0.07188358902931213,
      -0.024447159841656685,
      -0.05659156292676926,
      -0.04992650821805,
      0.08461082726716995,
      0.03996269032359123,
      0.04381522536277771,
      -0.010040350258350372,
      -0.06347347050905228,
      -0.03664972260594368,
      -0.06410743296146393,
      0.00864237081259489,
      -0.007323295343667269,
      -0.08035434037446976,
      -0.014093906618654728,
      0.056269653141498566,
      -0.01640925742685795,
      -0.00875295139849186,
      0.009702169336378574,
      -0.0017252309480682015,
      -0.030521513894200325,
      -0.0013995285844430327,
      0.0005904223071411252,
      -0.02916843257844448,
      -0.002951608272269368,
      -0.004374862182885408,
      0.06868439167737961,
      0.01277607399970293,
      -0.019702525809407234,
      -0.0075064352713525295,
      -0.09369828552007675,
      0.013464795425534248,
      0.0032395583111792803,
      -0.014452804811298847,
      -0.01844269223511219,
      -0.010669014416635036,
      -0.018172061070799828,
      -0.009357206523418427,
      0.0613727942109108,
      -0.030422819778323174,
      0.003054264932870865,
      -0.03424018993973732,
      0.035930175334215164,
      -0.054963119328022,
      -0.028360184282064438,
      -0.0120173254981637,
      -0.0367804691195488,
      0.009298291988670826,
      -0.03415536507964134,
      0.028646789491176605,
      0.012838179245591164,
      -0.030646275728940964,
      0.02673891745507717,
      0.06815510988235474,
      0.050748541951179504,
      -0.004919501952826977,
      6.473084795288742e-05,
      0.007255299016833305,
      0.0501340851187706,
      0.0017540758708491921,
      0.02374017797410488,
      -0.04705987125635147,
      0.05906953662633896,
      -0.033792298287153244,
      -0.0884513258934021,
      0.007921339944005013,
      0.06102510541677475,
      0.06777530908584595,
      0.022370774298906326,
      -0.009044773876667023,
      -0.041745591908693314,
      -0.009123653173446655,
      0.018962576985359192,
      0.002926408313214779,
      0.011952322907745838,
      0.030400166288018227,
      -0.007910449989140034,
      -0.005223727319389582,
      -0.049323827028274536,
      0.004275536630302668,
      0.09537600725889206,
      -0.03132128342986107,
      0.039717040956020355,
      -0.036733679473400116,
      0.017899775877594948,
      0.033517058938741684,
      -0.06614711880683899,
      -0.06414590775966644,
      0.011137353256344795,
      0.05622648075222969,
      0.051818519830703735,
      -0.024339836090803146,
      -0.05765382945537567,
      -0.008341989479959011,
      -0.006556313019245863,
      0.05859839916229248,
      0.020527105778455734,
      -0.02897392213344574,
      0.021419381722807884,
      -0.06990053504705429,
      -0.04707260802388191,
      0.04403413087129593,
      0.0439937524497509,
      0.06263474375009537,
      -0.007396473083645105,
      -0.004517748486250639,
      0.054367922246456146,
      0.1642526537179947,
      -0.053237684071063995,
      0.05392109975218773,
      0.026636164635419846,
      0.10185186564922333,
      0.06521403789520264,
      0.003622261807322502,
      0.023823518306016922,
      -0.02419934794306755,
      -0.006213093176484108,
      0.0025846844073385,
      0.018005702644586563,
      -0.03255869820713997,
      -0.008146735839545727,
      -0.0026948440354317427,
      -0.01089473906904459,
      0.04041479155421257,
      0.055240146815776825,
      0.0049351355992257595,
      0.04203963652253151,
      0.06143379583954811,
      0.002375498181208968,
      0.024882938712835312,
      0.009422453120350838,
      0.026828894391655922,
      -0.007362228352576494,
      -0.040902379900217056,
      0.07543958723545074,
      0.027971910312771797,
      0.03372560068964958,
      -0.012745797634124756,
      -0.04651574790477753,
      0.04087461158633232,
      -0.05822984501719475,
      -0.04571903124451637,
      0.014201394282281399,
      0.06365035474300385,
      -0.0355832464993,
      0.023363912478089333,
      0.08802326768636703,
      0.019509252160787582,
      -0.0709073394536972,
      0.05953643098473549,
      -0.045777466148138046,
      0.06566156446933746,
      -0.04777822643518448,
      0.012452534399926662,
      -0.03029724955558777,
      0.01672774739563465,
      -0.042066093534231186,
      0.05960638076066971,
      0.013880948536098003,
      0.0807509571313858,
      -0.010956136509776115,
      0.022087696939706802,
      0.1333809345960617,
      -0.05683913454413414,
      0.01198747381567955,
      -0.00784350372850895,
      0.06548354774713516,
      -0.005534691736102104,
      -0.0087117375805974,
      -0.018696429207921028,
      0.10161523520946503,
      0.03962557390332222,
      0.07785183936357498,
      -0.003684316063299775,
      0.06528142094612122,
      0.010762667283415794,
      -0.04576488584280014,
      -0.07411950081586838,
      0.024410612881183624,
      0.05221398174762726,
      0.034746672958135605,
      -0.0003786566376220435,
      -0.018810180947184563,
      0.03585333377122879,
      0.04467692971229553,
      0.07919704169034958,
      -0.05619148537516594,
      0.035097286105155945,
      -0.07183469831943512,
      0.001080464804545045,
      -0.020567594096064568,
      -7.671792263863608e-05,
      -0.07474091649055481,
      -0.05074663832783699,
      0.018558276817202568,
      -0.011990528553724289,
      -0.019095636904239655,
      -0.11459393054246902,
      0.009129364974796772,
      -0.04218931868672371,
      -0.03524530678987503,
      -0.0008759311749599874,
      -0.005898481234908104,
      -0.03673284128308296,
      0.047076720744371414,
      -0.03524722903966904,
      0.016787411645054817,
      -0.03229338675737381,
      -0.018519768491387367,
      0.04768750071525574,
      0.07848849892616272,
      0.012907054275274277,
      0.1032659262418747,
      0.02934538573026657,
      -0.0851159319281578,
      -0.07242054492235184,
      -0.01019182987511158,
      0.013728627003729343,
      -0.06963460147380829,
      0.019730666652321815,
      -0.015164077281951904,
      0.03892596811056137,
      0.04037237539887428,
      0.0011081260163336992,
      0.012705857865512371,
      -0.02709946408867836,
      0.03267250582575798,
      -0.09040582180023193,
      -0.0935087576508522,
      0.0031200465746223927,
      -0.00927235372364521,
      -0.12577632069587708,
      0.025215858593583107,
      0.032512303441762924,
      -0.032193027436733246,
      0.02218426950275898,
      -0.036226995289325714,
      -0.028734171763062477,
      0.017935549840331078,
      -0.041562341153621674,
      -0.012208121828734875,
      0.051031388342380524,
      0.11573001742362976,
      -0.02806154638528824,
      -0.012602063827216625,
      0.08900201320648193,
      0.03734143450856209,
      0.04859378933906555,
      0.04003078490495682,
      0.020887359976768494,
      -0.0045121158473193645,
      0.015377306379377842,
      -0.05598326027393341,
      -0.05698888748884201,
      0.04255295172333717,
      0.08464325219392776,
      -0.03231934830546379,
      0.0370505228638649,
      0.01780570112168789,
      0.0368301197886467,
      -0.011127243749797344,
      0.006398430094122887,
      0.011808989569544792,
      -0.01596686616539955,
      -0.052307888865470886,
      -0.02803407609462738,
      -0.07404179871082306,
      -0.03526458516716957,
      -0.03211362287402153,
      -0.07128425687551498,
      -0.027484510093927383,
      -0.03605697304010391,
      -0.014341020956635475,
      0.049745287746191025,
      -0.05939381942152977,
      0.049544282257556915,
      -0.05216041952371597,
      0.12615714967250824,
      -0.009393256157636642,
      0.08734790980815887,
      0.06840526312589645,
      0.018658200278878212,
      0.031499896198511124,
      -0.014337149448692799,
      -0.004897007718682289,
      -0.0029680149164050817,
      0.03960233926773071,
      0.0022931487765163183,
      -0.049613650888204575,
      0.08190662413835526,
      0.05511675029993057,
      -0.046838972717523575,
      -0.043638043105602264,
      -0.016524678096175194,
      -0.017812484875321388,
      0.04630816727876663,
      -0.0643114522099495,
      -0.0031653763726353645,
      -0.0697876363992691,
      0.02549811452627182,
      0.01503837388008833,
      0.010492822155356407,
      0.11665686219930649,
      0.0386778898537159,
      -0.04287739843130112,
      -0.048827603459358215,
      -0.009959559887647629,
      -0.05867915228009224,
      0.08869042992591858,
      -0.024027319625020027,
      -0.018651343882083893,
      0.033197980374097824,
      0.046332839876413345,
      -0.06748337298631668,
      0.011969251558184624,
      -0.02155142091214657,
      -0.08404593169689178,
      -0.02161944843828678,
      0.04325432702898979,
      0.023490702733397484,
      0.00169739022385329,
      -0.07644932717084885,
      0.026792893186211586,
      0.0027845348231494427,
      -0.03627888485789299,
      0.09058073163032532,
      0.027606701478362083,
      0.06824758648872375,
      -0.05821596086025238,
      0.11680831760168076,
      -0.007858363911509514,
      0.0700179859995842,
      -0.055977556854486465,
      0.00148514483589679,
      0.07338274270296097,
      0.04590524733066559,
      -0.026543188840150833,
      0.0014820251381024718,
      0.04145395755767822,
      0.02746659703552723,
      0.026065006852149963,
      0.057886529713869095,
      -0.033838216215372086,
      -0.04279739782214165,
      -0.01810639351606369,
      0.04939044266939163,
      -0.05897705629467964,
      -0.00968104600906372,
      0.06649741530418396,
      0.03801538422703743,
      0.01681937836110592,
      -0.10099787265062332,
      -0.018653348088264465,
      -0.0035415650345385075,
      0.03141838684678078,
      0.026480782777071,
      0.05722886696457863,
      -0.06961376965045929,
      0.014667969197034836,
      0.06576304882764816,
      -0.01349171157926321,
      -0.14879100024700165,
      0.02125556394457817,
      0.0039710476994514465,
      0.014265913516283035,
      -0.0255435798317194,
      -0.005348837468773127,
      0.05680746212601662,
      -0.0611703023314476,
      0.03709210455417633,
      -0.06520213186740875,
      -0.03302893787622452,
      0.03000659868121147,
      0.06056727468967438,
      -0.0495271198451519,
      -0.04978596419095993,
      0.017007000744342804,
      -0.018696773797273636,
      -0.05684003233909607,
      0.0005960155394859612,
      0.008432283997535706,
      -0.012120578438043594,
      -0.05815090611577034,
      0.04903171584010124,
      -0.010856814682483673,
      0.03542570024728775,
      0.09293334931135178,
      -0.0260055772960186,
      0.0820620059967041,
      -0.06545110791921616,
      -0.029055189341306686,
      -0.09851977229118347,
      0.028051801025867462,
      -0.030201535671949387,
      -0.003433302277699113,
      -0.04614700376987457,
      0.012056137435138226,
      -0.007581508718430996,
      0.06597112864255905,
      0.0037644284311681986,
      -0.011653255671262741,
      -0.0541464127600193,
      -0.015166860073804855,
      0.014252545312047005,
      0.06025557592511177,
      0.0031457808800041676,
      -0.06069779768586159,
      0.033770639449357986,
      -0.04238066077232361,
      -0.019321708008646965,
      0.0025017298758029938,
      0.003806816413998604,
      0.016139864921569824,
      0.06648609787225723,
      0.007896656170487404,
      0.060121119022369385,
      0.0858568325638771,
      0.031176922842860222,
      -0.10663015395402908,
      -0.09375479817390442,
      0.15636608004570007,
      0.05596938356757164,
      -0.002452041720971465,
      -0.010669747367501259,
      -0.06730235368013382,
      -0.0489347018301487
    ],
    [
      0.08087235689163208,
      -0.023240430280566216,
      0.03503599017858505,
      -0.071653351187706,
      -0.04998141527175903,
      0.1070413589477539,
      0.011617030948400497,
      -0.02335374988615513,
      -0.027813604101538658,
      0.0899307131767273,
      -0.002267258707433939,
      -0.05250629410147667,
      0.0330403670668602,
      0.030513139441609383,
      0.040406156331300735,
      0.0035590461920946836,
      -0.034361790865659714,
      -0.037055496126413345,
      0.01006773579865694,
      -0.08712930232286453,
      0.00940007995814085,
      -0.002834856742992997,
      -0.01625734567642212,
      0.013212868012487888,
      -0.0430743433535099,
      0.04970073327422142,
      -0.01600310578942299,
      0.0376170389354229,
      -0.03891880065202713,
      -0.05352822318673134,
      0.08399255573749542,
      -0.05830494686961174,
      -0.006875690538436174,
      -0.07906137406826019,
      -0.02022339403629303,
      -0.0015575849683955312,
      0.003272164147347212,
      0.010619918815791607,
      0.048058439046144485,
      0.036869265139102936,
      -0.022904692217707634,
      0.01899613067507744,
      -0.06537391990423203,
      -0.0014112575445324183,
      0.007669980637729168,
      -0.03255218639969826,
      -0.039927758276462555,
      0.01807665266096592,
      -0.037922732532024384,
      0.023110944777727127,
      0.0011809481075033545,
      -0.020839542150497437,
      0.09234428405761719,
      -0.04042194411158562,
      -0.08576380461454391,
      0.0313982330262661,
      -0.006693936884403229,
      -0.08370010554790497,
      -0.050902485847473145,
      -0.03300982341170311,
      -0.03373022377490997,
      -0.02530418150126934,
      -0.13790364563465118,
      0.00254083052277565,
      0.08327106386423111,
      -0.058830294758081436,
      0.025777306407690048,
      -0.05018699914216995,
      0.12475106120109558,
      -0.040527671575546265,
      0.04463932290673256,
      -0.006294376216828823,
      0.04284185543656349,
      -0.05726613849401474,
      -0.06140727177262306,
      0.10057336837053299,
      0.04026896134018898,
      0.004463966470211744,
      -0.003154913429170847,
      0.01789107173681259,
      0.013200444169342518,
      0.061754871159791946,
      -0.007933172397315502,
      0.03598738834261894,
      0.07997281104326248,
      -0.010018682107329369,
      -0.08023858070373535,
      0.02983684279024601,
      0.0034903723280876875,
      -0.034251242876052856,
      -0.025912053883075714,
      0.03221092373132706,
      0.025580305606126785,
      0.00908114854246378,
      0.05756731331348419,
      -0.03774828463792801,
      -0.008189830929040909,
      0.004604900721460581,
      -0.06185723468661308,
      -0.011599449440836906,
      -0.004288131836801767,
      -0.056631606072187424,
      -0.061643801629543304,
      -0.011992805637419224,
      0.026100583374500275,
      0.0011059448588639498,
      -0.006903320550918579,
      0.05652344599366188,
      -0.010876436717808247,
      0.07169010490179062,
      0.05563102290034294,
      0.02964431419968605,
      -0.03419245406985283,
      0.03902624174952507,
      0.053206074982881546,
      -0.06227333843708038,
      0.0018111211247742176,
      -0.009793777950108051,
      0.010927321389317513,
      -0.012867754325270653,
      -0.001303571742027998,
      0.059156373143196106,
      -0.01241877768188715,
      -0.11801360547542572,
      -0.04529793933033943,
      -0.002225298434495926,
      0.07837586849927902,
      0.011831924319267273,
      0.011892832815647125,
      -0.015611665323376656,
      0.14419429004192352,
      -0.0013293034862726927,
      -0.04370445758104324,
      0.020565908402204514,
      -0.021657224744558334,
      -0.0486670657992363,
      0.03423943743109703,
      -0.06934802979230881,
      -0.03515101596713066,
      0.008974936790764332,
      -0.05576134845614433,
      0.00368038984015584,
      -0.15251797437667847,
      0.00401727668941021,
      -0.005552306305617094,
      -0.029298894107341766,
      0.029093753546476364,
      -0.008721470832824707,
      0.00859904382377863,
      -0.0019008646486327052,
      -0.02973935753107071,
      -0.03609351068735123,
      -0.06040947139263153,
      -0.03647557646036148,
      -0.02728298306465149,
      0.006306524388492107,
      0.031868092715740204,
      -0.05348128452897072,
      0.0035545912105590105,
      -0.002087930217385292,
      0.005290045868605375,
      -0.025337262079119682,
      0.06483728438615799,
      -0.05849985033273697,
      0.017188630998134613,
      -0.04275696352124214,
      0.02863064780831337,
      -0.009731144644320011,
      0.025320738554000854,
      -0.07193577289581299,
      0.08068633079528809,
      0.018266987055540085,
      -0.04326998069882393,
      -0.07329194247722626,
      0.025890501216053963,
      -0.002823821036145091,
      -0.05241042748093605,
      0.04091213643550873,
      -0.05927016958594322,
      -0.06539471447467804,
      0.06781984120607376,
      -0.04366663098335266,
      0.0010183306876569986,
      -0.003464947221800685,
      0.03456282615661621,
      -0.06375902146100998,
      0.05081479623913765,
      -0.0881248414516449,
      -0.014826199971139431,
      -0.06539083272218704,
      -0.03738173842430115,
      0.007055999245494604,
      -0.02145693637430668,
      0.04886212572455406,
      -0.05538412556052208,
      0.08235327154397964,
      0.00565656553953886,
      0.04764416813850403,
      0.04808127135038376,
      -0.04521336778998375,
      -0.1118914857506752,
      0.03621866926550865,
      0.035422343760728836,
      -0.0546964667737484,
      0.009403901174664497,
      0.030097708106040955,
      0.0615033283829689,
      -0.06855278462171555,
      0.03602837771177292,
      0.046381134539842606,
      -0.018232436850667,
      0.039199765771627426,
      0.051567625254392624,
      0.08787678182125092,
      0.015248981304466724,
      -0.005682341288775206,
      0.015367722138762474,
      -0.026177244260907173,
      0.03479741886258125,
      -0.011087430641055107,
      0.008721056394279003,
      0.006930320058017969,
      -0.008912230841815472,
      0.028219886124134064,
      0.006907002069056034,
      -0.006567463278770447,
      -0.04362626373767853,
      -0.037348128855228424,
      -0.0015261147636920214,
      0.018294505774974823,
      0.0027532903477549553,
      -0.036684613674879074,
      -0.015346819534897804,
      -0.0287422277033329,
      -0.049162544310092926,
      -0.03323042765259743,
      -0.00889046210795641,
      -0.005482228938490152,
      -0.058011382818222046,
      -0.0023762895725667477,
      0.02318437024950981,
      -0.05068615823984146,
      0.01185318361967802,
      0.007423127070069313,
      -0.013502700254321098,
      0.023910846561193466,
      -0.0023235876578837633,
      -0.027552427724003792,
      -0.003440353088080883,
      0.09128504991531372,
      0.05642520263791084,
      0.05060780793428421,
      -0.005653657950460911,
      -0.04955694079399109,
      -0.00566576374694705,
      -0.057855330407619476,
      -0.004955748561769724,
      -0.03027462027966976,
      -0.01984817162156105,
      -0.012620649300515652,
      -0.06972408294677734,
      -0.025788001716136932,
      0.02727213315665722,
      -0.029041649773716927,
      0.04922041296958923,
      0.01705716736614704,
      -0.07428320497274399,
      -0.05680414289236069,
      -0.021461166441440582,
      -0.03935687616467476,
      -0.0906854048371315,
      -0.01957893557846546,
      0.10985614359378815,
      0.043641597032547,
      0.05452543869614601,
      -0.007965343073010445,
      0.007000226993113756,
      0.009240799583494663,
      0.028924819082021713,
      -0.010547607205808163,
      -0.03763381391763687,
      0.014968707226216793,
      -0.07052755355834961,
      0.13964031636714935,
      0.05888700112700462,
      -0.04641106724739075,
      -0.08379819244146347,
      0.08523732423782349,
      -0.007622628007084131,
      0.02306899055838585,
      -0.004197527654469013,
      -0.0853131115436554,
      -0.11923830956220627,
      -0.05145489051938057,
      -0.025928393006324768,
      0.05281830579042435,
      -0.06702166795730591,
      -0.08591753989458084,
      0.049648743122816086,
      0.02167103998363018,
      -0.01217357162386179,
      0.044982098042964935,
      0.007603166624903679,
      0.08857855945825577,
      -0.005574174225330353,
      -0.11539863049983978,
      -0.01753501407802105,
      -0.05754914879798889,
      -0.05250268056988716,
      -0.14126527309417725,
      -0.0054504298605024815,
      -0.0517447292804718,
      -0.05076612904667854,
      0.0016538079362362623,
      0.007607396692037582,
      -0.016076963394880295,
      -0.06625262647867203,
      0.06000388413667679,
      -0.05859813094139099,
      -0.004842992871999741,
      0.12285997718572617,
      -0.02770337648689747,
      0.008808082900941372,
      0.051061052829027176,
      -0.027942420914769173,
      -0.042173873633146286,
      -0.021321361884474754,
      -0.0029656195547431707,
      0.021221008151769638,
      0.027240663766860962,
      -0.008556491695344448,
      -0.013181375339627266,
      0.028496745973825455,
      0.033967405557632446,
      -0.029513515532016754,
      -0.05835946276783943,
      0.013130070641636848,
      -0.03454709053039551,
      -0.003098250599578023,
      0.03508800268173218,
      0.02703116275370121,
      -0.009719057939946651,
      -0.00997342448681593,
      -0.09501402080059052,
      0.044537629932165146,
      0.029635805636644363,
      0.03197503089904785,
      -0.008904271759092808,
      0.07441968470811844,
      0.026198985055088997,
      0.000842078123241663,
      -0.02641555853188038,
      -0.011555061675608158,
      -0.023501673713326454,
      -0.02093544788658619,
      -0.002499144058674574,
      0.05556950345635414,
      0.02850746177136898,
      -0.052865080535411835,
      -0.011193930171430111,
      -0.05443795770406723,
      -0.03736169636249542,
      -0.012143278494477272,
      0.02463562786579132,
      -0.027571411803364754,
      -0.030964629724621773,
      0.07852005958557129,
      0.06590037047863007,
      -0.04896746575832367,
      0.024841897189617157,
      0.0009429267374798656,
      0.04186598211526871,
      0.012489917688071728,
      -0.013345473445951939,
      -0.009616469033062458,
      0.012911312282085419,
      -0.02847377024590969,
      -0.08098464459180832,
      -0.022615712136030197,
      0.05358709394931793,
      -0.0369548499584198,
      0.003446639981120825,
      0.03331499919295311,
      0.025785598903894424,
      0.01118388306349516,
      0.0495879091322422,
      -0.0035602992866188288,
      -0.08544476330280304,
      0.005701967515051365,
      -0.025090061128139496,
      -0.04869234934449196,
      0.051187604665756226,
      -0.07944153994321823,
      0.07736174762248993,
      0.010541490279138088,
      0.008392821066081524,
      -0.031564515084028244,
      0.033296726644039154,
      -0.0005598437855951488,
      -0.02104818820953369,
      0.03564157709479332,
      -0.013383310288190842,
      -0.03775140643119812,
      0.019839780405163765,
      0.07276475429534912,
      0.021995799615979195,
      0.02186962217092514,
      -0.030712919309735298,
      0.06602904200553894,
      0.01867680251598358,
      0.018328934907913208,
      0.037882763892412186,
      -0.0019206139259040356,
      -0.046224288642406464,
      -0.10852403193712234,
      0.03290516138076782,
      0.08448843657970428,
      0.02663283795118332,
      -0.003920434974133968,
      0.056591540575027466,
      -0.020369255915284157,
      -0.04824104532599449,
      -0.047610484063625336,
      -0.031668905168771744,
      -0.029674073681235313,
      -0.048221249133348465,
      0.04659339413046837,
      0.009940175339579582,
      -0.031683795154094696,
      0.04926559701561928,
      -0.03218897432088852,
      -0.06837131828069687,
      -0.025663210079073906,
      0.034416038542985916,
      -0.07402355968952179,
      -0.02657937817275524,
      -0.014522737823426723,
      -0.012853384017944336,
      0.057817619293928146,
      -0.0019143337849527597,
      0.06364445388317108,
      -0.04600703716278076,
      -0.04963478446006775,
      -0.06920763850212097,
      -0.01995956152677536,
      0.07194109261035919,
      -0.07262836396694183,
      -0.03102075308561325,
      0.03690507635474205,
      -0.03242944926023483,
      0.07608013600111008,
      0.021022772416472435,
      0.02039342001080513,
      0.07438881695270538,
      0.007011543959379196,
      0.007022641133517027,
      -0.04523249343037605,
      0.04146143049001694,
      -0.01827005296945572,
      0.013117131777107716,
      0.04127826541662216,
      0.043969105929136276,
      -0.01618465594947338,
      0.0414242260158062,
      0.005111570470035076,
      -0.013762377202510834,
      0.011773158796131611,
      0.013074285350739956,
      -0.016377365216612816,
      0.05165865272283554,
      0.01817324385046959,
      -0.028055693954229355,
      -0.000568254676181823,
      0.022602906450629234,
      -0.03323882818222046,
      -0.011563355103135109,
      0.006967674940824509,
      -0.02671818621456623,
      0.03415822982788086,
      -0.03298884630203247,
      0.014291329309344292,
      -0.046421173959970474,
      0.039241280406713486,
      0.008851978927850723,
      -0.005821948405355215,
      0.03924984484910965,
      0.03862076997756958,
      0.03681629151105881,
      -0.13914160430431366,
      0.010393192991614342,
      -0.012392822653055191,
      -0.0446317233145237,
      -0.023749690502882004,
      -0.013256150297820568,
      -0.027618300169706345,
      -0.011544819921255112,
      0.07261301577091217,
      0.054405100643634796,
      -0.0036507677286863327,
      -0.026304004713892937,
      -0.050272468477487564,
      -0.08336242288351059,
      0.0044346097856760025
    ],
    [
      0.05932540446519852,
      -0.11212356388568878,
      -0.00957847572863102,
      -0.023490648716688156,
      -0.06200286000967026,
      -0.012897233478724957,
      -0.07008914649486542,
      0.024275122210383415,
      -0.11470265686511993,
      -0.025883588939905167,
      -0.014509526081383228,
      -0.031919945031404495,
      -0.036585643887519836,
      0.013115087524056435,
      0.06811732053756714,
      0.01989958994090557,
      0.05313943699002266,
      0.07038498669862747,
      -0.06296861916780472,
      -0.026440685614943504,
      0.05279098451137543,
      0.04852063208818436,
      -0.04994790628552437,
      -0.04908602312207222,
      0.013446868397295475,
      -0.012393787503242493,
      0.0004957229248248041,
      0.11425729840993881,
      -0.041319768875837326,
      0.005900344345718622,
      0.07028032839298248,
      -0.039300337433815,
      -0.007842055521905422,
      0.012528500519692898,
      0.028419677168130875,
      0.04998918995261192,
      -0.030270567163825035,
      0.04469248279929161,
      -0.0684429332613945,
      -0.057451002299785614,
      0.0191282257437706,
      0.022842977195978165,
      -0.03467017039656639,
      0.0338451974093914,
      0.038021691143512726,
      0.03596891462802887,
      -0.04183962196111679,
      -0.008071334101259708,
      0.04426151141524315,
      -0.053988225758075714,
      -0.011197849176824093,
      -0.07878199219703674,
      0.0715952068567276,
      -0.07052835822105408,
      -0.018919887021183968,
      0.029433373361825943,
      -0.05315994843840599,
      -0.07908444851636887,
      -0.04073571041226387,
      0.13462212681770325,
      0.022240616381168365,
      0.05325091630220413,
      -0.004468128085136414,
      -0.0976509377360344,
      0.01360778696835041,
      -0.09880680590867996,
      0.04066755250096321,
      0.04226963594555855,
      -0.017375994473695755,
      -0.0456845760345459,
      0.0028102744836360216,
      0.06105715036392212,
      -0.01949925906956196,
      0.05915665999054909,
      0.01519942656159401,
      -0.04442474991083145,
      0.018363557755947113,
      -0.028468307107686996,
      -0.011762031354010105,
      0.09225856512784958,
      -0.001781656639650464,
      0.019300006330013275,
      0.053808484226465225,
      0.007423162925988436,
      -0.004668934736400843,
      0.002935370896011591,
      -0.035090766847133636,
      -0.012883801013231277,
      0.018203845247626305,
      0.01865236461162567,
      -0.0721168965101242,
      -0.0014591208891943097,
      0.025779522955417633,
      0.005789106246083975,
      0.02651410736143589,
      0.028503477573394775,
      0.04092612862586975,
      0.045271262526512146,
      0.05913110449910164,
      0.0152913648635149,
      0.088700070977211,
      -0.008162164129316807,
      -0.003467008238658309,
      0.01991540566086769,
      0.040799133479595184,
      0.0664190948009491,
      0.04154552146792412,
      -0.06004878878593445,
      0.015426674857735634,
      0.005467703565955162,
      0.008585294708609581,
      0.03906506299972534,
      -0.023124855011701584,
      -0.012243799865245819,
      0.04386758431792259,
      -0.04248547926545143,
      -0.012149323709309101,
      -0.06376941502094269,
      0.08116043359041214,
      -0.01231977716088295,
      -0.015327767468988895,
      0.027775360271334648,
      -0.038933709263801575,
      -0.010182555764913559,
      -0.01997070573270321,
      -0.012016402557492256,
      0.024518346413969994,
      -0.0467950664460659,
      -0.0036453886423259974,
      -0.06642187386751175,
      0.06510472297668457,
      0.04478844255208969,
      -0.013238263316452503,
      0.014079130254685879,
      0.02537880651652813,
      -0.02093897946178913,
      0.07438205182552338,
      0.013145141303539276,
      -0.0805550217628479,
      0.02919510006904602,
      0.003951895050704479,
      -0.08430324494838715,
      0.03359323367476463,
      0.016979852691292763,
      -0.04540875926613808,
      -0.004023037385195494,
      -0.02460363321006298,
      0.021606041118502617,
      0.1196453794836998,
      0.01896686851978302,
      -0.009627049788832664,
      -0.03961697593331337,
      -0.04803496599197388,
      0.058340154588222504,
      -0.02432980015873909,
      -0.04943351075053215,
      -0.03891158849000931,
      0.04457147791981697,
      0.03673867508769035,
      0.0002062587591353804,
      -0.03140652924776077,
      0.023351380601525307,
      0.04153604060411453,
      -0.014456255361437798,
      -0.015670176595449448,
      -0.03155355155467987,
      -0.04505204036831856,
      0.05398160591721535,
      0.030154703184962273,
      0.01340525783598423,
      0.012764018028974533,
      -0.01322182547301054,
      -0.07482525706291199,
      -0.09762687236070633,
      0.026594966650009155,
      0.009481614455580711,
      -0.0013782514724880457,
      -0.06539049744606018,
      0.015579520724713802,
      -0.0019066015956923366,
      0.007190531585365534,
      -0.05747929960489273,
      0.013648881576955318,
      0.008942486718297005,
      0.009660924784839153,
      -0.019062437117099762,
      -0.015116359107196331,
      0.0314340740442276,
      0.008765698410570621,
      0.004312517587095499,
      -0.016984477639198303,
      0.04247874766588211,
      -0.01976192742586136,
      -0.03190484642982483,
      -0.060898661613464355,
      0.046142105013132095,
      -0.02501182071864605,
      -0.00321513251401484,
      -0.042183686047792435,
      0.06894588470458984,
      -0.0348774790763855,
      0.008717812597751617,
      -0.04208797588944435,
      0.064976267516613,
      -0.048978112637996674,
      0.0024963808245956898,
      0.0114749101921916,
      -0.000269753101747483,
      0.009330176748335361,
      -0.011140973307192326,
      0.03768841177225113,
      0.05922802910208702,
      0.01944916695356369,
      0.042640119791030884,
      0.021062951534986496,
      0.03137509524822235,
      -0.0479993037879467,
      -0.022877804934978485,
      -0.05560833215713501,
      -0.09494592994451523,
      0.03698287531733513,
      -0.00617621187120676,
      -0.016149114817380905,
      -0.045809004455804825,
      0.03886326402425766,
      -0.062335580587387085,
      0.04991038516163826,
      0.029196638613939285,
      -0.015355621464550495,
      -0.044603850692510605,
      -0.03963867574930191,
      -0.04503876715898514,
      0.0577499121427536,
      -0.031408581882715225,
      -0.031569067388772964,
      0.0479973629117012,
      -0.013039692305028439,
      0.002669521141797304,
      0.014693141914904118,
      0.0017271938268095255,
      -0.013219253160059452,
      -0.09517776221036911,
      0.05283479392528534,
      0.016004087403416634,
      0.006252175662666559,
      -0.01822539232671261,
      0.00984257459640503,
      0.001529790461063385,
      -0.009098042733967304,
      0.05064172297716141,
      -0.030234215781092644,
      -0.059696704149246216,
      -0.08142887055873871,
      0.052307020872831345,
      -0.06168275326490402,
      -0.02627617120742798,
      -0.03307674825191498,
      0.038530487567186356,
      -0.026328489184379578,
      -0.06329534947872162,
      -0.0010829316452145576,
      -0.010065373964607716,
      0.0033187682274729013,
      -0.11028757691383362,
      0.010052796453237534,
      0.013423887081444263,
      -0.030712613835930824,
      0.0498887337744236,
      -0.06652069091796875,
      -0.033251941204071045,
      -0.05761546269059181,
      -0.052583687007427216,
      -0.03782867267727852,
      -0.005845772568136454,
      0.04584028571844101,
      -0.040655266493558884,
      0.05494958907365799,
      0.00396169675514102,
      0.016491081565618515,
      0.01888529770076275,
      -0.07480188459157944,
      0.08385372906923294,
      -0.03334575146436691,
      -0.030370183289051056,
      0.037271711975336075,
      0.010339460335671902,
      0.018950141966342926,
      0.008642749860882759,
      -0.020785752683877945,
      -0.018141519278287888,
      -0.09382972866296768,
      0.015749255195260048,
      -0.1158473938703537,
      0.036920689046382904,
      -0.0006557381711900234,
      0.012155860662460327,
      0.11177259683609009,
      -0.03812878578901291,
      0.08735918253660202,
      0.032480161637067795,
      0.004178766161203384,
      0.09929143637418747,
      -0.002832520753145218,
      0.00407358119264245,
      0.0667821541428566,
      0.02070399560034275,
      0.028548939153552055,
      0.026204604655504227,
      0.06836166232824326,
      -0.011955234222114086,
      -0.01349673978984356,
      -0.01151703204959631,
      -0.03327746316790581,
      -0.024199368432164192,
      -0.011133117601275444,
      0.004161940421909094,
      -0.08387298136949539,
      0.036557674407958984,
      -8.239533781306818e-05,
      -0.005294738803058863,
      0.018258903175592422,
      -0.04823717474937439,
      0.037007566541433334,
      -0.03881176933646202,
      -0.031569257378578186,
      0.056115925312042236,
      0.04033156856894493,
      -0.007082663010805845,
      -0.028652731329202652,
      0.033797238022089005,
      0.04371025040745735,
      0.017363114282488823,
      0.008963167667388916,
      -0.008524173870682716,
      0.0451836958527565,
      -0.03672891482710838,
      0.01316466648131609,
      -0.015125841833651066,
      -0.09032627940177917,
      -0.03690342977643013,
      0.015087242238223553,
      0.05171222239732742,
      -0.07690099626779556,
      0.05564257875084877,
      -0.028367584571242332,
      0.006703161634504795,
      0.026146765798330307,
      -0.01409539021551609,
      0.02558659017086029,
      -0.023417145013809204,
      -0.0189487524330616,
      0.017464328557252884,
      -0.01924765296280384,
      0.005626330152153969,
      0.025194624438881874,
      -0.06353069841861725,
      -0.026356426998972893,
      -0.007777055725455284,
      0.035716135054826736,
      0.03771600499749184,
      -0.029652906581759453,
      0.024331919848918915,
      -0.013313807547092438,
      0.03699155151844025,
      0.027826989069581032,
      -0.033880751579999924,
      0.03947008028626442,
      0.008334490470588207,
      -0.036949802190065384,
      0.04598920792341232,
      -0.06602977961301804,
      0.016841774806380272,
      0.06393406540155411,
      0.005202982574701309,
      0.028880596160888672,
      -0.009399649687111378,
      0.019163597375154495,
      -0.03225076571106911,
      -0.05675361305475235,
      0.033996839076280594,
      0.06081458181142807,
      -0.03170952945947647,
      0.06573332101106644,
      0.028153469786047935,
      -0.05713389441370964,
      -0.040035977959632874,
      -0.06553143262863159,
      -0.04050951823592186,
      0.025733282789587975,
      0.023019246757030487,
      -0.1438412070274353,
      -0.02311873994767666,
      -0.11742813885211945,
      0.05025974661111832,
      -0.052920714020729065,
      -0.03769048675894737,
      -0.056266143918037415,
      -0.02678249403834343,
      0.0015554573619738221,
      -0.026789382100105286,
      0.03017503209412098,
      0.030290953814983368,
      0.021448953077197075,
      -0.009080939926207066,
      -0.027084430679678917,
      0.08571362495422363,
      0.009293579496443272,
      0.008531720377504826,
      0.030208513140678406,
      0.01230684109032154,
      -0.014301113784313202,
      0.03157858923077583,
      -0.001330872531980276,
      -0.037882328033447266,
      0.012079158797860146,
      0.006700403057038784,
      0.040241606533527374,
      0.016222238540649414,
      -0.03399065509438515,
      -0.04893255978822708,
      -0.03493611514568329,
      -0.038838330656290054,
      -0.056745823472738266,
      0.029416363686323166,
      -0.1260194331407547,
      0.009025788865983486,
      0.03639020025730133,
      -0.058415018022060394,
      -0.06933631002902985,
      0.03673507273197174,
      0.021356863901019096,
      3.710105738718994e-05,
      0.006750642322003841,
      -0.03287811204791069,
      -0.014158828184008598,
      0.07573159039020538,
      0.002560580614954233,
      0.001773806638084352,
      -0.013210270553827286,
      0.06513002514839172,
      -0.032839976251125336,
      -0.04426814615726471,
      0.01174019556492567,
      -0.003918007016181946,
      0.06082705035805702,
      0.01073338370770216,
      0.046263135969638824,
      0.0049312361516058445,
      0.08687911182641983,
      -0.003262570360675454,
      0.042115435004234314,
      0.012374583631753922,
      0.05997234582901001,
      0.007177654188126326,
      -0.06208854168653488,
      -0.034670423716306686,
      0.02883678674697876,
      0.03952968865633011,
      0.04390259087085724,
      -0.05351364240050316,
      0.05667173117399216,
      0.016117054969072342,
      -0.057607684284448624,
      0.04798822104930878,
      0.07984182238578796,
      -0.010338742285966873,
      0.03396831825375557,
      0.01176447980105877,
      -0.07056932896375656,
      0.08990100026130676,
      -0.017068317160010338,
      -0.019343046471476555,
      0.01830843836069107,
      0.0032464570831507444,
      -0.033328525722026825,
      -0.04112842679023743,
      -0.025822347030043602,
      0.0646897554397583,
      0.011269094422459602,
      -0.041490450501441956,
      0.07332763820886612,
      0.10197298228740692,
      0.016399482265114784,
      -0.048343583941459656,
      0.061735380440950394,
      -0.022528603672981262,
      -0.03228997811675072,
      -0.04273896664381027,
      -0.07281627506017685,
      -0.00932310987263918,
      0.0211161058396101,
      0.034634292125701904,
      -0.006130283232778311,
      0.016031261533498764,
      -0.021445099264383316,
      -0.06846092641353607,
      -0.07279305160045624,
      0.027737995609641075,
      0.04697712883353233,
      0.0664563849568367,
      -0.04726104810833931,
      -0.008183482103049755,
      0.022678082808852196
    ],
    [
      0.04303506016731262,
      -0.04969632253050804,
      1.7889004084281623e-05,
      -0.003912679385393858,
      -0.07193303853273392,
      -0.1001838892698288,
      -0.061434246599674225,
      -0.002919963328167796,
      0.005980602465569973,
      -0.03233632072806358,
      0.026087233796715736,
      -0.011511456221342087,
      -0.09339861571788788,
      0.05329151451587677,
      0.040862202644348145,
      0.030420619994401932,
      0.01925712078809738,
      -0.06581626832485199,
      0.013416403904557228,
      -0.04805003106594086,
      0.06732525676488876,
      -0.11134982109069824,
      -0.035917751491069794,
      0.017750248312950134,
      -0.021513905376195908,
      0.0017814297461882234,
      -0.041457608342170715,
      0.12223883718252182,
      0.009161250665783882,
      0.03462117165327072,
      -0.04351015016436577,
      0.0004981799866072834,
      -0.09522347152233124,
      0.02206672541797161,
      0.020032275468111038,
      0.005621261894702911,
      -0.07126541435718536,
      -0.02241424284875393,
      -0.031788215041160583,
      -0.020993027836084366,
      -0.05649183318018913,
      -0.09629122167825699,
      0.001929836580529809,
      -0.0648898258805275,
      0.08206050097942352,
      0.0026757013984024525,
      0.013165631331503391,
      -0.07646866142749786,
      0.05517382174730301,
      -0.0034758285619318485,
      0.07886124402284622,
      -0.04049806669354439,
      0.04591325297951698,
      0.002052335999906063,
      -0.008987878449261189,
      -0.0016617296496406198,
      -0.011042318306863308,
      0.03487331047654152,
      -0.012499974109232426,
      0.018906446173787117,
      0.05109245330095291,
      -0.017699280753731728,
      -0.07349792122840881,
      -0.0479334257543087,
      0.029803795740008354,
      0.03469637408852577,
      0.04687291011214256,
      0.01792188547551632,
      0.03380962833762169,
      -0.10099264979362488,
      0.003930728882551193,
      -0.04654684290289879,
      0.05432627350091934,
      0.052693698555231094,
      0.07440505921840668,
      -0.016845954582095146,
      0.0505918450653553,
      -0.03933548927307129,
      -0.0018513837130740285,
      -0.0019947730470448732,
      0.03305397927761078,
      0.07432788610458374,
      -0.0156764667481184,
      -0.09818927943706512,
      -0.025342654436826706,
      0.026660045608878136,
      0.008267469704151154,
      0.053835075348615646,
      0.0817718654870987,
      0.03991008922457695,
      0.06346705555915833,
      -0.001039594179019332,
      -0.10099707543849945,
      0.030646029859781265,
      -0.00787096843123436,
      0.005846764892339706,
      -0.018685614690184593,
      -0.028844695538282394,
      -0.0054876236245036125,
      -0.054257459938526154,
      -0.01568867266178131,
      0.009557968005537987,
      -0.027173500508069992,
      -0.06666981428861618,
      0.026158403605222702,
      0.0008394020842388272,
      0.057170022279024124,
      0.021198412403464317,
      0.02101702056825161,
      0.04157382622361183,
      0.0024556280113756657,
      0.045509010553359985,
      0.11214088648557663,
      0.004013857804238796,
      -0.05189449340105057,
      -0.002275328617542982,
      0.004188905470073223,
      0.04270811006426811,
      0.0023317348677664995,
      -0.05189766362309456,
      0.012497346848249435,
      0.0608382411301136,
      -0.04907835274934769,
      -0.058711495250463486,
      -0.05614897236227989,
      -0.019927281886339188,
      0.0961235985159874,
      -0.005940633825957775,
      0.00974556989967823,
      -0.0570731945335865,
      0.07134243100881577,
      0.021206172183156013,
      0.0013194684870541096,
      -0.029339222237467766,
      -0.040942996740341187,
      0.09866860508918762,
      0.009765975177288055,
      -0.03152605518698692,
      -0.003957001492381096,
      -0.06043388694524765,
      0.018085449934005737,
      -0.08080757409334183,
      0.0359010323882103,
      0.01039784587919712,
      0.03272363543510437,
      -0.0017525297589600086,
      -0.0171511247754097,
      -0.024011453613638878,
      0.03665316104888916,
      0.044772304594516754,
      -0.05943085998296738,
      0.022390220314264297,
      0.011728832498192787,
      0.04170992970466614,
      0.025798916816711426,
      0.039226945489645004,
      0.013717110268771648,
      0.035491373389959335,
      0.004356297664344311,
      0.011911403387784958,
      -0.017511630430817604,
      -0.031305283308029175,
      -0.01773211546242237,
      -0.056420598179101944,
      -0.03780176863074303,
      0.09225871413946152,
      0.061509400606155396,
      -0.004473590292036533,
      -0.005803465377539396,
      0.03922464698553085,
      -0.016154266893863678,
      -0.024302257224917412,
      0.04825558140873909,
      0.04164959117770195,
      -0.031086036935448647,
      -0.04138123616576195,
      -0.09249202162027359,
      0.06525254249572754,
      -0.06947764754295349,
      0.07429546117782593,
      0.047364383935928345,
      -0.048692140728235245,
      -0.028085140511393547,
      0.0047553591430187225,
      -0.004302110988646746,
      0.013517159037292004,
      -0.030172836035490036,
      -0.027994245290756226,
      0.006982733961194754,
      -0.05450800806283951,
      -0.024251695722341537,
      0.05445053055882454,
      0.06460835784673691,
      0.02508660964667797,
      -0.040949735790491104,
      -0.0820692777633667,
      -0.08640773594379425,
      -0.002758576301857829,
      -0.05907414108514786,
      -0.011554853990674019,
      0.039569173008203506,
      -0.02245846763253212,
      0.12249612808227539,
      -0.028046825900673866,
      0.10942104458808899,
      -0.02441486343741417,
      -0.03669876977801323,
      -0.02843925915658474,
      0.003078174777328968,
      -0.011781502515077591,
      -0.008769301697611809,
      -0.0485810786485672,
      0.004170404747128487,
      -0.017692644149065018,
      -0.055435799062252045,
      -0.035604238510131836,
      0.04750534147024155,
      -0.017035461962223053,
      0.0105586564168334,
      -0.02526761218905449,
      -0.01887858286499977,
      0.020893216133117676,
      0.09556535631418228,
      -0.010194823145866394,
      -0.020866164937615395,
      -0.013689953833818436,
      -0.005103232339024544,
      0.044365204870700836,
      0.02499801106750965,
      0.026221303269267082,
      -0.042243968695402145,
      0.04787347838282585,
      -0.10517733544111252,
      -0.05175745114684105,
      -0.025654630735516548,
      -0.0014449201989918947,
      0.04964344576001167,
      0.01597696915268898,
      0.022966422140598297,
      0.015040389262139797,
      -0.02171340212225914,
      -0.020264968276023865,
      -0.04012209549546242,
      0.07723899930715561,
      -0.045162513852119446,
      -0.008687630295753479,
      -0.05671960115432739,
      -0.04047344624996185,
      -0.002885296242311597,
      0.03713245689868927,
      0.04302437976002693,
      -0.032259199768304825,
      0.031244143843650818,
      -0.031888291239738464,
      0.03293706104159355,
      0.0047176615335047245,
      0.0464855320751667,
      -0.008478531613945961,
      -0.07299748808145523,
      -0.06353200227022171,
      -0.021509915590286255,
      -0.042079534381628036,
      -0.07449480891227722,
      0.002530026016756892,
      0.003502440173178911,
      0.00122403958812356,
      0.044686105102300644,
      -0.01700746826827526,
      0.01940564624965191,
      -0.0004472122818697244,
      -0.03674684464931488,
      0.033830802887678146,
      0.03223161771893501,
      -0.03106115758419037,
      -0.014637937769293785,
      -0.03968213126063347,
      0.0021784852724522352,
      0.0899699479341507,
      0.031432658433914185,
      0.05388067290186882,
      0.05618443712592125,
      0.05537981912493706,
      -0.09139008820056915,
      -0.028376953676342964,
      0.019917212426662445,
      0.04668121039867401,
      -0.016215093433856964,
      0.0003806125314440578,
      0.02349650114774704,
      0.0906938761472702,
      0.016658933833241463,
      0.03941858932375908,
      -0.050732195377349854,
      -0.05665425956249237,
      -0.023887310177087784,
      0.030670996755361557,
      -0.0315299816429615,
      -0.043808404356241226,
      -0.0009601140627637506,
      0.008291387930512428,
      -0.08059819787740707,
      0.027014099061489105,
      0.06418103724718094,
      -0.08444752544164658,
      0.051145534962415695,
      -0.026324722915887833,
      0.007371570449322462,
      -0.008100386708974838,
      -0.08138800412416458,
      0.03670942038297653,
      -0.02955128252506256,
      -0.015109961852431297,
      0.020152850076556206,
      0.003688788041472435,
      -0.07248102873563766,
      0.003055300796404481,
      0.04245856776833534,
      0.06452830880880356,
      0.010595264844596386,
      -0.018648169934749603,
      -0.03902164101600647,
      -0.1166815459728241,
      0.10875552147626877,
      0.07460051029920578,
      -0.024365177378058434,
      -0.012911313213407993,
      0.011953719891607761,
      0.014493589289486408,
      -0.03223761171102524,
      -0.03472014144062996,
      -0.03973812237381935,
      0.013574794866144657,
      -0.023133158683776855,
      -0.11142553389072418,
      -0.006915767677128315,
      0.021672792732715607,
      0.018866168335080147,
      -0.0820869579911232,
      0.04297986254096031,
      -0.019025316461920738,
      0.016114279627799988,
      -0.03298578038811684,
      0.04904114454984665,
      0.006639841943979263,
      0.08728665858507156,
      0.07294874638319016,
      0.02459091693162918,
      -0.014819463714957237,
      0.02687745913863182,
      0.048072315752506256,
      0.03599414974451065,
      -0.0614674910902977,
      -0.05149330198764801,
      0.00706394761800766,
      -0.03436427190899849,
      0.1055404394865036,
      0.010451363399624825,
      0.0030865443404763937,
      0.008794568479061127,
      -0.07269615679979324,
      0.03732925280928612,
      0.0013355795526877046,
      -0.1641446202993393,
      -0.02932792343199253,
      0.10786356031894684,
      -0.000923473562579602,
      0.03580768033862114,
      -0.022345922887325287,
      0.09065234661102295,
      -0.02658851072192192,
      -0.004475325811654329,
      0.028263915330171585,
      0.037398237735033035,
      -0.06370943784713745,
      -0.04253238067030907,
      -0.06994543969631195,
      0.08983130753040314,
      -0.01455372292548418,
      0.019910583272576332,
      0.015487931668758392,
      -0.006192982662469149,
      -0.011338545940816402,
      0.031348854303359985,
      0.11950047314167023,
      -0.018365124240517616,
      -0.0030756015330553055,
      -0.004296779632568359,
      0.004976493772119284,
      0.07990757375955582,
      -0.03346306085586548,
      0.026445424184203148,
      0.015745341777801514,
      -0.07770373672246933,
      0.06033194810152054,
      -0.014703263528645039,
      -0.057884205132722855,
      0.011714325286448002,
      0.034839242696762085,
      -0.034284669905900955,
      0.08043564110994339,
      0.05123114958405495,
      0.00687947403639555,
      -0.030894188210368156,
      0.0011417459463700652,
      -0.0016607249854132533,
      0.014957946725189686,
      0.009237507358193398,
      -0.07794469594955444,
      0.04136084020137787,
      0.018316328525543213,
      0.05627252906560898,
      0.008663544431328773,
      0.05954854190349579,
      -0.055473215878009796,
      -0.009488237090408802,
      -0.034658439457416534,
      0.004613296594470739,
      -0.007667984813451767,
      0.020818615332245827,
      -0.05345076322555542,
      -0.002406693296507001,
      -0.017624976113438606,
      0.04422307386994362,
      -0.04247123748064041,
      0.026007313281297684,
      0.06759952008724213,
      0.02112916298210621,
      -0.004108096472918987,
      0.013229482807219028,
      -0.05061112716794014,
      0.08498843759298325,
      0.05648753046989441,
      -0.012272235937416553,
      -0.020968735218048096,
      0.00800994411110878,
      0.014440217986702919,
      -0.019681056961417198,
      -0.06231747940182686,
      0.004621047992259264,
      -0.010786292143166065,
      -0.01611167937517166,
      -0.0017603266751393676,
      0.051586467772722244,
      0.018622420728206635,
      0.038812991231679916,
      0.012802266515791416,
      0.0727820172905922,
      0.055445216596126556,
      -0.010272816754877567,
      0.023231355473399162,
      0.00740391667932272,
      -0.05611827224493027,
      0.0599927194416523,
      -0.017312541604042053,
      -0.027527837082743645,
      0.031214803457260132,
      -0.014926350675523281,
      -0.043444328010082245,
      -0.04986739158630371,
      0.06849624216556549,
      0.03798339515924454,
      0.08400187641382217,
      -0.04138828068971634,
      0.046869318932294846,
      0.0573836974799633,
      0.02584502100944519,
      -0.02280072495341301,
      0.04397646710276604,
      -0.0644553080201149,
      -0.0385437048971653,
      -0.01133664045482874,
      -0.0605868361890316,
      0.029459930956363678,
      -0.034272968769073486,
      -0.06505970656871796,
      0.0628524199128151,
      -0.009886931627988815,
      -0.04255998879671097,
      -0.06609360128641129,
      -0.07863171398639679,
      -0.08098936825990677,
      0.010850116610527039,
      -0.09251196682453156,
      0.009427088312804699,
      -0.001538366312161088,
      0.053826406598091125,
      0.029794050380587578,
      0.03747237101197243,
      0.00445001944899559,
      -0.059941019862890244,
      0.1112368032336235,
      0.013490174897015095,
      0.02979116328060627,
      -0.09780317544937134,
      0.05064143240451813,
      -0.1635950654745102,
      0.02371661737561226,
      0.016092956066131592,
      0.013202772475779057,
      0.03920416533946991,
      -0.012204724363982677,
      -0.010587562806904316,
      0.11146307736635208
    ],
    [
      0.024520844221115112,
      -0.007961387746036053,
      -0.05220483988523483,
      0.024354303255677223,
      -0.09024447202682495,
      -0.03342309594154358,
      -5.36529187229462e-05,
      -0.02865535579621792,
      0.03547407314181328,
      0.008211757987737656,
      -0.04670119658112526,
      -0.02401796355843544,
      0.015733283013105392,
      0.011654061265289783,
      -0.03556537628173828,
      -0.07498623430728912,
      -0.03899410367012024,
      0.05047573521733284,
      0.010067312978208065,
      -0.03407343849539757,
      -0.008042022585868835,
      0.01598779298365116,
      0.03828081861138344,
      -0.058346282690763474,
      -0.0038262454327195883,
      0.10121472179889679,
      -0.04015160724520683,
      0.0050820112228393555,
      0.0710517168045044,
      0.05421771481633186,
      0.039385728538036346,
      0.015099995769560337,
      -0.016391806304454803,
      0.00721981143578887,
      -0.05637992545962334,
      -0.040495529770851135,
      0.04085059091448784,
      -0.07836899161338806,
      -0.007684602402150631,
      -0.00913004670292139,
      -0.019097542390227318,
      -0.010439906269311905,
      -0.05208580940961838,
      0.003067034762352705,
      -0.012807787396013737,
      -0.08042698353528976,
      -0.03038007579743862,
      -0.058912575244903564,
      0.05283486843109131,
      0.030113108456134796,
      -0.033580880612134933,
      0.09850576519966125,
      -0.05407899245619774,
      0.04263371601700783,
      -0.0518561452627182,
      0.04361087456345558,
      -0.020405614748597145,
      -0.08369807153940201,
      -0.0445939339697361,
      0.06532880663871765,
      0.03132115304470062,
      0.0037935422733426094,
      0.01624637097120285,
      -0.03380587697029114,
      0.057784900069236755,
      -0.060148898512125015,
      -0.039004478603601456,
      -0.004923443775624037,
      -0.045536383986473083,
      -0.004949487745761871,
      0.0014526268932968378,
      0.021623054519295692,
      -0.036595966666936874,
      -0.09217818081378937,
      -0.03385145217180252,
      0.037586335092782974,
      0.06046968698501587,
      -0.02538686990737915,
      -0.08128070086240768,
      -0.046172067523002625,
      0.03043808788061142,
      -0.021893151104450226,
      -0.06013065576553345,
      -0.08991201221942902,
      -0.004185076337307692,
      -0.027678631246089935,
      -0.016865257173776627,
      -0.022890042513608932,
      0.05133773386478424,
      -0.018501054495573044,
      0.047809503972530365,
      0.06382187455892563,
      0.040819305926561356,
      -0.021407175809144974,
      -0.02824787050485611,
      -0.10863398760557175,
      0.01468625757843256,
      -0.02861194685101509,
      0.008523629978299141,
      -0.02694024331867695,
      -0.013275315053761005,
      -0.13442468643188477,
      0.015408002771437168,
      -0.006259821821004152,
      -0.038335129618644714,
      -0.11839784681797028,
      0.03551720455288887,
      -0.07376587390899658,
      0.04252294823527336,
      0.054847922176122665,
      -0.005846349056810141,
      -0.05191287398338318,
      -0.0013011506525799632,
      -0.0922287106513977,
      0.028704548254609108,
      0.023431282490491867,
      0.10434918105602264,
      0.04123157635331154,
      -0.026143863797187805,
      -0.05384286120533943,
      -0.03468725457787514,
      0.01033751294016838,
      0.027419060468673706,
      -0.031705934554338455,
      -0.0032311654649674892,
      0.04406772926449776,
      -0.02811189368367195,
      0.0377664715051651,
      0.061653800308704376,
      -0.00022457093291450292,
      0.0071534751914441586,
      0.010442047379910946,
      0.05944841355085373,
      0.038176149129867554,
      -0.015225864946842194,
      -0.01905793882906437,
      0.0002941393759101629,
      -0.006413931492716074,
      -0.023531785234808922,
      0.06979434192180634,
      0.026654325425624847,
      0.0066147237084805965,
      -0.10782318562269211,
      0.015341217629611492,
      0.03836857154965401,
      -0.017944613471627235,
      -0.0021787199657410383,
      -0.03590201959013939,
      0.0492883138358593,
      0.018334271386265755,
      0.015585001558065414,
      -0.03136195242404938,
      0.018037384375929832,
      -0.01995781622827053,
      0.0023334198631346226,
      -0.04736097902059555,
      -0.1263202577829361,
      0.05178384482860565,
      -0.031692977994680405,
      0.004064259584993124,
      0.00401299400255084,
      0.06568007171154022,
      0.06859468668699265,
      0.019496917724609375,
      -0.06704864650964737,
      0.057876452803611755,
      -0.044018812477588654,
      0.025523988530039787,
      -0.03597647696733475,
      0.03226129338145256,
      0.030213015154004097,
      0.05580339953303337,
      -0.01979507878422737,
      0.048805758357048035,
      0.026465516537427902,
      -0.0146615169942379,
      -0.031555693596601486,
      0.0617075189948082,
      -0.0314757339656353,
      -0.010987859219312668,
      -0.030278677120804787,
      0.026543809100985527,
      0.0718829482793808,
      0.06061301752924919,
      -0.08689211308956146,
      0.09003742784261703,
      0.03697691112756729,
      0.023545991629362106,
      0.010409381240606308,
      -0.022285552695393562,
      -0.04078258201479912,
      -0.011059469543397427,
      0.048562146723270416,
      0.04166431725025177,
      -0.005628043320029974,
      -0.08234032988548279,
      0.0018406086601316929,
      -0.0408237986266613,
      -0.008935114368796349,
      -0.0678468719124794,
      0.006399183999747038,
      0.03622138872742653,
      -0.043398402631282806,
      0.012921889312565327,
      -0.04283713176846504,
      0.017468983307480812,
      0.041298165917396545,
      0.055048540234565735,
      -0.01774388924241066,
      -0.008066181093454361,
      -0.0019277690444141626,
      0.00013876575394533575,
      0.09043855220079422,
      0.06328670680522919,
      -0.08618632704019547,
      0.06099896505475044,
      -0.0075426166877150536,
      -0.07230719923973083,
      0.028421524912118912,
      -0.07859624922275543,
      0.09069547057151794,
      0.01802128180861473,
      0.004064689856022596,
      0.02903001941740513,
      0.04267847537994385,
      -0.014646938070654869,
      -0.00825019646435976,
      0.0023954741191118956,
      0.10543107241392136,
      0.013979586772620678,
      -0.022802788764238358,
      -0.01651664264500141,
      -0.005258610006421804,
      -0.028111176565289497,
      -0.012187924236059189,
      -0.004499077331274748,
      0.05107451230287552,
      0.036615125834941864,
      -0.03161275386810303,
      0.015028662979602814,
      0.07678548991680145,
      0.014909738674759865,
      0.038558002561330795,
      0.09790243208408356,
      0.02485508657991886,
      -0.04217389598488808,
      0.046818237751722336,
      -0.022613657638430595,
      -0.0011540893465280533,
      0.057855553925037384,
      0.017453474923968315,
      0.04510194808244705,
      0.042598020285367966,
      0.027355248108506203,
      -0.11008220165967941,
      -0.010730147361755371,
      0.011197040788829327,
      -0.03281901031732559,
      -0.08946441113948822,
      0.00627359002828598,
      -0.037191327661275864,
      0.04834173247218132,
      0.03572281822562218,
      -0.026900868862867355,
      0.012659219093620777,
      0.009592712856829166,
      0.06437299400568008,
      0.03112659603357315,
      0.019986264407634735,
      0.006770525127649307,
      0.016627568751573563,
      0.0665883719921112,
      0.015095047652721405,
      -0.06323351711034775,
      0.012614796869456768,
      -0.06284929811954498,
      -0.014242093078792095,
      -0.004047659691423178,
      0.015672024339437485,
      0.003938913345336914,
      -9.790465264813974e-05,
      0.10535916686058044,
      0.042853448539972305,
      0.11710751056671143,
      -0.0003606853715609759,
      0.03534191474318504,
      -0.006209517363458872,
      0.0025856695137917995,
      0.08010285347700119,
      0.03136039152741432,
      -0.0129502322524786,
      0.028699662536382675,
      -0.02364029921591282,
      -0.05850856378674507,
      -0.04848823323845863,
      0.060939230024814606,
      -0.03401334211230278,
      -0.06683634966611862,
      -0.052481379359960556,
      -0.04266845062375069,
      0.054809264838695526,
      0.06068255007266998,
      0.05029556527733803,
      0.009457682259380817,
      0.02705850824713707,
      -0.029024213552474976,
      -0.0038231429643929005,
      0.055812157690525055,
      0.013622278347611427,
      -0.019534053280949593,
      0.0030329085420817137,
      -0.05774855241179466,
      -0.044964682310819626,
      0.003473927965387702,
      -0.018131213262677193,
      -0.04963981732726097,
      0.004056492354720831,
      -0.0021698763594031334,
      -0.002338601276278496,
      -0.03780774399638176,
      -0.005216565448790789,
      0.0029477495700120926,
      -0.005705228075385094,
      0.08139660954475403,
      -0.010075012221932411,
      0.07479260116815567,
      -0.08192447572946548,
      -0.030355116352438927,
      0.07486101984977722,
      0.07326646149158478,
      -0.07017900049686432,
      0.014138254337012768,
      -0.00647698575630784,
      -0.041966624557971954,
      0.055577199906110764,
      -0.02143312245607376,
      0.025104474276304245,
      0.04741908237338066,
      0.09661709517240524,
      -0.005721732508391142,
      -0.01943103037774563,
      0.04267945513129234,
      -0.049464404582977295,
      -0.02166973426938057,
      -0.042720574885606766,
      0.0012531758984550834,
      0.04019998013973236,
      -0.04013674333691597,
      -0.09770102053880692,
      0.007389352191239595,
      -0.005452411714941263,
      0.02442660927772522,
      0.030391916632652283,
      -0.023660656064748764,
      0.023737402632832527,
      -0.03569640964269638,
      0.06169532611966133,
      -0.02608129382133484,
      0.006923596374690533,
      -0.055743299424648285,
      -0.09622325748205185,
      0.02532206103205681,
      -0.014204874634742737,
      0.10319695621728897,
      0.003161111380904913,
      0.02949911169707775,
      0.0030552290845662355,
      -0.04553358256816864,
      -0.021708833053708076,
      -0.0199047289788723,
      0.05314403399825096,
      -0.04735259339213371,
      -0.020702309906482697,
      0.0020121969282627106,
      -0.01867057755589485,
      -0.10652323067188263,
      0.04140612483024597,
      -0.002478702226653695,
      0.09446124732494354,
      -0.04061582311987877,
      -0.006391014438122511,
      -0.01571630872786045,
      -0.07420789450407028,
      -0.006162813864648342,
      -0.05506503954529762,
      -0.016723232343792915,
      0.007277901284396648,
      0.034858766943216324,
      -0.07069913297891617,
      -0.03807277977466583,
      -0.008306806907057762,
      0.08869579434394836,
      -0.02881857194006443,
      -0.015778904780745506,
      0.03746182098984718,
      -0.038037847727537155,
      0.003429782809689641,
      0.09889044612646103,
      0.03251715004444122,
      -0.09121953696012497,
      0.00029541298863478005,
      0.016621669754385948,
      -0.027895240113139153,
      0.01835281029343605,
      -0.07065907120704651,
      0.024998681619763374,
      -0.015493417158722878,
      -0.08456139266490936,
      -0.0018354272469878197,
      0.0005950424820184708,
      0.014600150287151337,
      -0.013489698991179466,
      -0.05269084498286247,
      -0.03681095689535141,
      -0.08869469165802002,
      -0.02988004870712757,
      -0.013266926631331444,
      -0.017652049660682678,
      0.040652062743902206,
      0.003139440668746829,
      -0.03239057585597038,
      -0.0778605192899704,
      -0.003628595033660531,
      0.02521499991416931,
      -0.031931716948747635,
      -0.010063830763101578,
      -0.0074072652496397495,
      -0.09879148751497269,
      -0.009352713823318481,
      -0.0026631122455000877,
      -0.06075097993016243,
      0.010997254401445389,
      0.059764314442873,
      0.03269249573349953,
      0.044427383691072464,
      -0.03002098575234413,
      0.05843446031212807,
      -0.09247633069753647,
      -0.08199840039014816,
      -0.08681996911764145,
      -0.021999018266797066,
      0.024594994261860847,
      -0.009324842132627964,
      -0.046489618718624115,
      0.03013366460800171,
      -0.08868391066789627,
      -0.03598439320921898,
      0.01446067076176405,
      0.02658446878194809,
      0.030076775699853897,
      -0.025905167683959007,
      -0.0016781900776550174,
      0.019003916531801224,
      0.07047576457262039,
      0.022407887503504753,
      0.005153709091246128,
      0.027575930580496788,
      0.052689917385578156,
      0.005986879114061594,
      0.013579029589891434,
      0.03306357190012932,
      -0.03978573530912399,
      -0.013686785474419594,
      0.07310839742422104,
      0.035336948931217194,
      -0.002749169012531638,
      0.11506272107362747,
      0.06010044738650322,
      -0.0018784237327054143,
      -0.031129997223615646,
      0.06990595906972885,
      -0.06183706969022751,
      -0.0299480352550745,
      0.030608586966991425,
      0.02902545966207981,
      0.05960119888186455,
      -0.03874557465314865,
      -0.03833242133259773,
      -0.10159122198820114,
      0.019082121551036835,
      -0.09003020077943802,
      -0.04754682257771492,
      -0.08090650290250778,
      0.0884581133723259,
      -0.06037301570177078,
      -0.02293832041323185,
      0.08445226401090622,
      -0.050398796796798706,
      0.07052738964557648,
      0.061441853642463684,
      0.010859995149075985,
      -0.1057366132736206,
      -0.13249702751636505,
      -0.07062707841396332,
      -0.11684995889663696,
      -0.02760048769414425,
      0.03966788947582245,
      0.05955606698989868,
      -0.039019886404275894,
      -0.03249446302652359,
      0.034543588757514954,
      0.0031047046650201082,
      -0.011583434417843819
    ],
    [
      0.04405173659324646,
      -0.007638321258127689,
      0.10396260023117065,
      0.0402994304895401,
      -0.03405863791704178,
      0.013434872031211853,
      -0.05052101984620094,
      0.04333947226405144,
      0.04478119686245918,
      0.018624205142259598,
      0.01976822502911091,
      -0.014274991117417812,
      -0.05530845373868942,
      -0.006699556950479746,
      0.06699171662330627,
      -0.023018578067421913,
      -0.0727665051817894,
      0.004791399464011192,
      0.04154987633228302,
      -0.03365986794233322,
      -0.03737615421414375,
      0.025514595210552216,
      -0.004427001345902681,
      -0.01825868710875511,
      -0.05439067259430885,
      0.01289843488484621,
      0.010012348182499409,
      0.1237165778875351,
      -0.04336210712790489,
      0.010678477585315704,
      0.05678747966885567,
      -0.0110336784273386,
      0.005893787369132042,
      0.05427701026201248,
      0.03958544507622719,
      -0.060664527118206024,
      0.006930881179869175,
      -0.024394018575549126,
      -0.05886770039796829,
      -0.02692660130560398,
      0.06037406623363495,
      -0.006133697926998138,
      -0.023802174255251884,
      -0.010200204327702522,
      0.020999234169721603,
      0.027939323335886,
      -0.041834115982055664,
      -0.026000112295150757,
      -0.012876994907855988,
      0.07737691700458527,
      -0.02292066253721714,
      0.021092098206281662,
      -0.007980202324688435,
      -0.018380658701062202,
      -0.04130573198199272,
      0.008184704929590225,
      -0.02595733292400837,
      0.01967056840658188,
      0.054019343107938766,
      -0.033864762634038925,
      -0.06832516938447952,
      0.013524327427148819,
      -0.10863465815782547,
      0.08054354786872864,
      0.06363081932067871,
      0.06473717838525772,
      -0.048103075474500656,
      0.01499550137668848,
      0.05299479514360428,
      -0.05561245232820511,
      0.013278817757964134,
      0.0317896343767643,
      -0.0011753729777410626,
      0.024401580914855003,
      0.031727127730846405,
      0.016322487965226173,
      -0.034568388015031815,
      -0.08031870424747467,
      0.0641326904296875,
      -0.008143668062984943,
      -0.012945395894348621,
      0.004606396891176701,
      0.02678070217370987,
      0.012290792539715767,
      -0.005257190205156803,
      -0.05905131250619888,
      -0.10827358812093735,
      -0.0019651674665510654,
      -0.022054681554436684,
      0.048675864934921265,
      0.05192394182085991,
      -0.04249624162912369,
      0.03611195832490921,
      0.010232090018689632,
      -0.0231596902012825,
      0.03292621299624443,
      0.03426527604460716,
      0.04962466284632683,
      -0.060417257249355316,
      -0.02925151400268078,
      0.0326915942132473,
      -0.04812944680452347,
      0.0505647249519825,
      0.006757394410669804,
      -0.10913673788309097,
      -0.03161139413714409,
      -0.018156325444579124,
      -0.04178006947040558,
      0.08636578917503357,
      0.022197289392352104,
      -0.01552711520344019,
      -0.01918710395693779,
      0.045732270926237106,
      0.02672395668923855,
      -0.01787625253200531,
      0.0042685940861701965,
      -0.08144643902778625,
      -0.0039454856887459755,
      0.04195142164826393,
      0.04713613912463188,
      -0.022180860862135887,
      0.0012829863699153066,
      0.06886792182922363,
      -0.046081990003585815,
      -0.04223299026489258,
      -0.0069540441036224365,
      0.00019231044279877096,
      -0.04799291491508484,
      0.034784868359565735,
      -0.09908077120780945,
      0.0008316648309119046,
      0.047841936349868774,
      -0.026651378720998764,
      -0.04756713658571243,
      -0.043021511286497116,
      0.009798080660402775,
      -0.038423776626586914,
      0.06769120693206787,
      0.008188643492758274,
      0.04365883022546768,
      -0.07566919177770615,
      -0.02984062395989895,
      0.03081188164651394,
      -0.05439471825957298,
      0.044665757566690445,
      -0.03634105995297432,
      0.037508029490709305,
      -0.04809107258915901,
      0.019815484061837196,
      0.040228553116321564,
      0.019961265847086906,
      0.0025618961080908775,
      -0.08081047981977463,
      0.01642431691288948,
      -0.050810206681489944,
      0.02094443514943123,
      -0.03674443066120148,
      0.03644389286637306,
      0.059776850044727325,
      -0.07888051122426987,
      -0.0025451520923525095,
      -0.09685948491096497,
      0.016486141830682755,
      -0.010206934995949268,
      0.00012120170868001878,
      -0.07517283409833908,
      0.0034870028030127287,
      0.0520809143781662,
      0.025156142190098763,
      0.01078441459685564,
      0.04702172800898552,
      0.03324674442410469,
      -0.00550947105512023,
      0.012436077930033207,
      -0.04651356115937233,
      0.04667476564645767,
      -0.0007477457984350622,
      0.0031008380465209484,
      0.005941558629274368,
      -0.029288621619343758,
      0.011807234957814217,
      -0.05962531268596649,
      0.03381695598363876,
      -0.007664975710213184,
      -0.04030168429017067,
      0.036601148545742035,
      0.05448426678776741,
      -0.024097494781017303,
      -0.023296337574720383,
      0.001565805752761662,
      0.029485436156392097,
      -0.019143402576446533,
      -0.008299106732010841,
      0.00022838047880213708,
      -0.035118814557790756,
      0.07841531187295914,
      0.009135764092206955,
      0.0379130020737648,
      -0.04953134059906006,
      0.019454650580883026,
      0.03580191731452942,
      0.019676895812153816,
      0.021716557443141937,
      0.04169442504644394,
      -0.012299621477723122,
      0.04474487528204918,
      0.018579628318548203,
      -0.012298798188567162,
      0.010395045392215252,
      0.006400387734174728,
      0.0815371721982956,
      0.009003621526062489,
      0.01254368294030428,
      0.026756105944514275,
      -0.06707438826560974,
      0.03977201133966446,
      0.024301273748278618,
      -0.082731693983078,
      0.024351056665182114,
      0.05053424462676048,
      -0.14363951981067657,
      0.018295379355549812,
      0.0188082717359066,
      -0.022218987345695496,
      -0.05107646808028221,
      -0.03433072566986084,
      0.009645824320614338,
      0.04869202896952629,
      0.09142099320888519,
      0.08716843277215958,
      0.049871623516082764,
      -0.0298417117446661,
      -0.055393487215042114,
      -0.057492293417453766,
      -0.0038732709363102913,
      0.07739359885454178,
      0.005861647892743349,
      0.025471458211541176,
      -0.06730665266513824,
      -0.0049391682259738445,
      0.047750502824783325,
      0.01148359477519989,
      -0.02871311828494072,
      0.055933013558387756,
      0.022015489637851715,
      -0.047376930713653564,
      0.03841964900493622,
      -0.0060532912611961365,
      -0.05275014415383339,
      0.00798665452748537,
      -0.029514402151107788,
      -0.05017911270260811,
      0.020829355344176292,
      -0.013344813138246536,
      0.061513498425483704,
      0.04708852618932724,
      -0.03267395496368408,
      0.017537755891680717,
      0.06742622703313828,
      0.05289071425795555,
      0.026597701013088226,
      -0.07919032871723175,
      -0.035604387521743774,
      0.09390469640493393,
      -0.04312267154455185,
      0.007837877608835697,
      0.08098664879798889,
      0.005296512972563505,
      -0.08500521630048752,
      -0.008596919476985931,
      0.02086951769888401,
      -0.10147848725318909,
      0.04027622193098068,
      0.05864636227488518,
      0.00893595814704895,
      0.03219309821724892,
      -0.08223089575767517,
      0.065755695104599,
      -0.06956160068511963,
      0.03250622749328613,
      0.015102984383702278,
      -0.06670953333377838,
      -0.06790456175804138,
      -0.04453722760081291,
      -0.1028486043214798,
      0.044883470982313156,
      -0.053210917860269547,
      -0.0012679228093475103,
      -0.009740120731294155,
      -0.027023205533623695,
      -0.017912350594997406,
      -0.037466537207365036,
      -0.0224198866635561,
      0.027130207046866417,
      -0.039610590785741806,
      0.004473830107599497,
      -0.04702237993478775,
      -0.1356726884841919,
      -0.020626598969101906,
      -0.01783587783575058,
      -0.01372502464801073,
      0.016945064067840576,
      -0.03164777532219887,
      -0.05696593597531319,
      -0.035084232687950134,
      0.06431081146001816,
      -0.015606635250151157,
      -0.06223466247320175,
      0.025857090950012207,
      0.07814329117536545,
      0.05337023362517357,
      0.030881395563483238,
      -0.023996761068701744,
      0.004682773724198341,
      0.039265260100364685,
      -0.013624288141727448,
      0.003825103398412466,
      0.06967562437057495,
      -0.09359041601419449,
      -0.08733730763196945,
      -0.025038892403244972,
      -0.04000604525208473,
      0.0033653804566711187,
      0.02067895606160164,
      0.002531625796109438,
      0.07328596711158752,
      0.03574148938059807,
      0.009489692747592926,
      0.023771587759256363,
      0.030986299738287926,
      0.0035832305438816547,
      -0.023762447759509087,
      -0.04946888983249664,
      0.02229938842356205,
      -0.05260377749800682,
      0.06696566194295883,
      -0.017483681440353394,
      0.037716999650001526,
      0.047869972884655,
      -0.0005591704975813627,
      0.012253718450665474,
      -0.033081766217947006,
      -0.0008480469696223736,
      -0.06559929251670837,
      -0.051217999309301376,
      0.004561999347060919,
      0.01138992328196764,
      -0.01117205061018467,
      -0.03900909796357155,
      -0.028196608647704124,
      0.006379300728440285,
      -0.01868521422147751,
      -0.04969920963048935,
      0.02049061842262745,
      -0.037569690495729446,
      -0.046475108712911606,
      -0.011366521008312702,
      0.014584704302251339,
      0.08666310459375381,
      0.005490552168339491,
      0.02437657304108143,
      0.006192713510245085,
      -0.04951120913028717,
      -0.02558588981628418,
      0.009494551457464695,
      0.01489598024636507,
      0.05762886628508568,
      0.05057583749294281,
      -0.021044569090008736,
      -0.060500625520944595,
      0.01783248595893383,
      0.01373105589300394,
      -0.050189852714538574,
      0.03293626010417938,
      -0.008402962237596512,
      -0.024286052212119102,
      -0.004820707254111767,
      -0.027189606800675392,
      -0.02986789122223854,
      0.023705044761300087,
      0.030494268983602524,
      -0.04155001416802406,
      0.006343870889395475,
      -0.050399523228406906,
      -0.03439396992325783,
      0.08207842707633972,
      0.06738974899053574,
      0.04809354245662689,
      0.041219230741262436,
      0.027345124632120132,
      0.086229607462883,
      -0.00042806321289390326,
      -0.03650457039475441,
      -0.022017721086740494,
      0.014317086897790432,
      -0.00855689775198698,
      -0.007452082354575396,
      0.030611185356974602,
      -0.01201457716524601,
      0.009271960705518723,
      0.0034089554101228714,
      0.07167660444974899,
      -0.09737803786993027,
      0.004788742866367102,
      -0.03734588995575905,
      -0.058611877262592316,
      0.09828705340623856,
      0.04505014792084694,
      -0.005729018710553646,
      0.05902902036905289,
      -0.02655438333749771,
      -0.03213983401656151,
      -0.04856529459357262,
      -0.02030801586806774,
      0.031389519572257996,
      -0.014106704853475094,
      -0.026275690644979477,
      -0.004814597778022289,
      -0.03673982992768288,
      0.005769223906099796,
      -0.09058081358671188,
      0.024734072387218475,
      0.030556321144104004,
      0.11789418756961823,
      0.052593380212783813,
      -0.014618070796132088,
      -0.038597214967012405,
      -0.03550843894481659,
      0.03242691233754158,
      0.036463413387537,
      -0.00402600085362792,
      0.02439756505191326,
      -0.058248188346624374,
      0.06107143685221672,
      -0.013103485107421875,
      -0.0030455898959189653,
      0.06490489095449448,
      -0.017646338790655136,
      -0.036940932273864746,
      0.00303334416821599,
      -0.06192498281598091,
      -0.032091643661260605,
      0.04693526774644852,
      -0.09835066646337509,
      -0.05016538128256798,
      0.03370317816734314,
      0.02975447103381157,
      -0.08077419549226761,
      -0.008151780813932419,
      -0.023120110854506493,
      -0.007127577438950539,
      -0.0029689795337617397,
      -0.03662082180380821,
      -0.0712876096367836,
      -0.06366714090108871,
      -0.007641134317964315,
      -0.0625985711812973,
      0.019885515794157982,
      -0.04594382271170616,
      -0.07381068915128708,
      0.018865147605538368,
      0.10915368795394897,
      -0.023650944232940674,
      0.04126003384590149,
      -0.08449111878871918,
      -0.0845383033156395,
      0.02255883440375328,
      -0.0005168525385670364,
      0.039812784641981125,
      -0.03862371668219566,
      -0.0197407528758049,
      0.02644885517656803,
      -0.035513512790203094,
      0.04104219377040863,
      -0.031603507697582245,
      -0.05127529427409172,
      -0.06018374487757683,
      0.040439706295728683,
      -0.026972156018018723,
      0.025018800050020218,
      0.033317480236291885,
      0.006321360822767019,
      -0.021048512309789658,
      0.07061140984296799,
      -0.0033849868923425674,
      -0.012666125781834126,
      -0.08168617635965347,
      -0.013768830336630344,
      -0.024211684241890907,
      -0.005858391989022493,
      0.03908839449286461,
      -0.09338567405939102,
      0.07638789713382721,
      -0.02367873676121235,
      0.03007015585899353,
      -0.022957516834139824,
      0.004058163147419691,
      0.002886977745220065,
      0.00434208894148469,
      -0.07328756153583527,
      0.027930231764912605,
      -0.006112640257924795,
      0.044812899082899094
    ],
    [
      0.024141468107700348,
      0.11533979326486588,
      0.03279326111078262,
      -0.023890815675258636,
      0.00926462933421135,
      -0.023249322548508644,
      -0.01869959570467472,
      0.04707181081175804,
      -0.03669319301843643,
      0.005673972424119711,
      0.08762116730213165,
      -0.01573210395872593,
      0.0033909662161022425,
      0.023184798657894135,
      -0.02047407068312168,
      -0.001770750037394464,
      0.01849294640123844,
      0.015561764128506184,
      -0.004525780212134123,
      0.07529223710298538,
      0.0356937050819397,
      -0.03239194676280022,
      -0.04513026401400566,
      -0.002130548469722271,
      0.0007322701858356595,
      0.001439938205294311,
      -0.1083948016166687,
      0.02841750904917717,
      -0.05470634996891022,
      0.03749876096844673,
      0.01059467438608408,
      0.042920999228954315,
      -0.010818262584507465,
      -0.10452774912118912,
      -0.014025636948645115,
      0.0439726784825325,
      -0.0062772841192781925,
      -0.07351496070623398,
      -0.027251098304986954,
      -0.028869781643152237,
      -0.03239155933260918,
      -0.04578167945146561,
      0.06624230742454529,
      -0.0006583385984413326,
      -0.03412967920303345,
      -0.017265819013118744,
      -0.08413061499595642,
      0.030982190743088722,
      0.028351647779345512,
      0.06307706981897354,
      0.021419312804937363,
      -0.0020549739710986614,
      -0.01719774678349495,
      -0.021656936034560204,
      -0.0386006124317646,
      0.07398170977830887,
      0.03847286477684975,
      -0.0602894201874733,
      -0.02096971683204174,
      -0.007345143705606461,
      0.06428107619285583,
      -0.0481506772339344,
      0.05194326117634773,
      -0.03595747798681259,
      -0.0030557727441191673,
      -0.01104202214628458,
      -0.022346151992678642,
      -0.0036734684836119413,
      -0.04185005649924278,
      0.03682735562324524,
      -0.04337112978100777,
      -0.06584805995225906,
      0.009774366393685341,
      -0.0041351295076310635,
      -0.008813341148197651,
      -0.01765427738428116,
      0.05002184957265854,
      -0.026339206844568253,
      0.012798220850527287,
      0.030609572306275368,
      0.05054911971092224,
      -0.03575849160552025,
      0.016368558630347252,
      0.001800089725293219,
      0.03516615927219391,
      0.0153873385861516,
      -0.03896389901638031,
      0.006405925378203392,
      -0.07101212441921234,
      -0.0025102952495217323,
      -0.031573642045259476,
      -0.05864332988858223,
      0.019581874832510948,
      0.01885024644434452,
      -0.0405900776386261,
      0.011526455171406269,
      0.01609130948781967,
      -0.08025612682104111,
      -0.04160698130726814,
      -0.03649066016077995,
      -0.05323471501469612,
      0.07495397329330444,
      0.030531734228134155,
      0.004341148305684328,
      0.0006897523417137563,
      0.029440714046359062,
      0.022248167544603348,
      0.030568242073059082,
      -0.0381198488175869,
      0.05864192545413971,
      -0.021155908703804016,
      0.03275980055332184,
      0.034131452441215515,
      -0.0607769750058651,
      0.018359951674938202,
      -0.0020315598230808973,
      0.022500688210129738,
      -0.08701898902654648,
      -0.028092965483665466,
      -0.0179938692599535,
      0.02779202163219452,
      -0.005410118494182825,
      0.01617257110774517,
      -0.010226481594145298,
      -0.017434390261769295,
      -0.02044251561164856,
      -0.043541427701711655,
      0.033413659781217575,
      -0.0451434962451458,
      -0.0017742604250088334,
      -0.01484430581331253,
      -0.015812331810593605,
      0.0014563719742000103,
      -0.007000696379691362,
      -0.041982509195804596,
      -0.013580127619206905,
      0.012327237986028194,
      0.0013721006689593196,
      0.07738686352968216,
      0.007280441932380199,
      -0.06829378008842468,
      0.04695238545536995,
      -0.04162139445543289,
      0.018689697608351707,
      -0.05728451907634735,
      -0.03924624249339104,
      0.01170423161238432,
      0.029013214632868767,
      0.08601480722427368,
      0.010136744007468224,
      -0.04893508553504944,
      0.013586447574198246,
      0.015634190291166306,
      -0.0004887342220172286,
      -0.0016541521763429046,
      -0.03454916179180145,
      -0.011993015184998512,
      -0.06263516843318939,
      -0.04689004644751549,
      -0.010983520187437534,
      0.009384606964886189,
      -0.06121325492858887,
      -0.04657454788684845,
      -0.01837644912302494,
      0.03517163544893265,
      0.021382320672273636,
      -0.008761131204664707,
      -0.03911679983139038,
      0.05949021130800247,
      -0.011634990572929382,
      0.010976538993418217,
      0.0827019140124321,
      -0.0008703822968527675,
      0.04594844952225685,
      -0.014000589028000832,
      0.009303216822445393,
      -0.019979214295744896,
      0.030907122418284416,
      0.009484684094786644,
      -0.04032944515347481,
      0.01359965093433857,
      -0.07166078686714172,
      -0.014580484479665756,
      0.05630569905042648,
      0.020836560055613518,
      0.052638985216617584,
      0.044468168169260025,
      -0.004123114049434662,
      0.05887098237872124,
      -0.0005542503786273301,
      0.009344716556370258,
      0.08998048305511475,
      0.052788328379392624,
      -0.05136173591017723,
      0.0019078265177085996,
      0.012803182937204838,
      0.03848433494567871,
      0.0047683571465313435,
      0.003823057049885392,
      0.026924073696136475,
      0.032644838094711304,
      0.07340433448553085,
      -0.06921995431184769,
      -0.07280855625867844,
      -0.004763114731758833,
      0.008936163038015366,
      0.04172775149345398,
      0.05829324573278427,
      -0.0023269152734428644,
      0.011287934146821499,
      -0.024090871214866638,
      -0.06606373190879822,
      0.008295038715004921,
      -0.043626829981803894,
      -0.04321315139532089,
      0.0057617067359387875,
      0.0003731878532562405,
      -0.03664680942893028,
      0.08356457948684692,
      -0.025840913876891136,
      -0.002347814617678523,
      0.005101113114506006,
      -0.03827422484755516,
      -0.023402506485581398,
      0.015325421467423439,
      0.04103487730026245,
      0.06958992779254913,
      -0.0539194755256176,
      0.03558051958680153,
      -0.00040030921809375286,
      -0.0921059399843216,
      0.008906284347176552,
      -0.05511632561683655,
      -0.08136196434497833,
      -0.011281833052635193,
      0.020757397636771202,
      0.0123134795576334,
      0.00251114834100008,
      0.0376727320253849,
      0.012646473944187164,
      0.018920838832855225,
      0.0014271512627601624,
      -0.0302860327064991,
      0.05576617643237114,
      -0.052124250680208206,
      -0.04990087449550629,
      0.030429190024733543,
      0.0715574100613594,
      -0.028064832091331482,
      0.10222874581813812,
      -0.0518234521150589,
      0.044059835374355316,
      0.10701736062765121,
      -0.011593584902584553,
      -0.09599440544843674,
      -0.02414800599217415,
      -0.024488814175128937,
      -0.07687841355800629,
      0.03505351394414902,
      0.03429507091641426,
      0.01645875908434391,
      -0.06882346421480179,
      0.0227153729647398,
      -0.07788359373807907,
      -0.004924469627439976,
      -0.025605902075767517,
      -0.03767898306250572,
      -0.08241137862205505,
      0.05486490577459335,
      0.032699961215257645,
      -0.0016466518864035606,
      0.003532486967742443,
      -0.0063315448351204395,
      -0.05060969293117523,
      -0.023807460442185402,
      0.0564856231212616,
      0.0431995652616024,
      0.03676733374595642,
      0.04955369606614113,
      -0.0728267952799797,
      -0.012792732566595078,
      -0.02637256681919098,
      0.0023557154927402735,
      -0.008860458619892597,
      0.05769261345267296,
      0.023119855672121048,
      -0.031410131603479385,
      0.05294674634933472,
      -0.08947931975126266,
      0.09601396322250366,
      0.031988244503736496,
      0.06943768262863159,
      0.025971831753849983,
      0.03326316177845001,
      -0.020648377016186714,
      0.04130283743143082,
      -0.06908771395683289,
      0.008360185660421848,
      0.00834373477846384,
      -0.05841274559497833,
      -0.02843395061790943,
      0.012985136359930038,
      0.004966932814568281,
      -0.03336232900619507,
      0.011480165645480156,
      -0.10500841587781906,
      0.05149481073021889,
      0.00024009695334825665,
      -0.04374546930193901,
      0.06834591925144196,
      0.015230130404233932,
      -0.049119751900434494,
      -0.04981384798884392,
      0.05659351870417595,
      0.05711798742413521,
      0.014083623886108398,
      -0.018629880622029305,
      -0.022577587515115738,
      -0.01376357115805149,
      -0.013117551803588867,
      -0.02817707322537899,
      0.025089198723435402,
      0.04138451814651489,
      0.0531109981238842,
      -0.06906993687152863,
      -0.06183222308754921,
      0.07391214370727539,
      -0.055773016065359116,
      0.05265455320477486,
      0.0766407772898674,
      0.025054503232240677,
      -0.02765638940036297,
      -0.018291722983121872,
      -0.027003901079297066,
      -0.03498077392578125,
      -0.016100386157631874,
      0.031496088951826096,
      0.02987789735198021,
      0.023856189101934433,
      -0.0874587818980217,
      -0.02983531355857849,
      -0.03665059432387352,
      -0.024157261475920677,
      -0.056785110384225845,
      -0.04094773903489113,
      -0.05254161357879639,
      0.04788973182439804,
      0.034408003091812134,
      0.05731310695409775,
      0.017752796411514282,
      0.0058455136604607105,
      -0.010138153098523617,
      0.009605049155652523,
      0.046922702342271805,
      0.05124244466423988,
      0.027964580804109573,
      -0.01427017617970705,
      -0.043519776314496994,
      -0.02151295356452465,
      -0.05868634954094887,
      0.08969995379447937,
      -0.02635563351213932,
      -0.039233095943927765,
      -0.03562835231423378,
      0.03837906941771507,
      0.017788004130125046,
      0.059506550431251526,
      -0.02656116895377636,
      0.03792695701122284,
      -0.0518701896071434,
      0.03783324733376503,
      0.023452555760741234,
      -0.006439195014536381,
      0.008975797332823277,
      -0.0376705639064312,
      0.11309602111577988,
      0.03198361396789551,
      -0.0010226024314761162,
      -0.02274993620812893,
      0.06572359800338745,
      0.03294682502746582,
      0.022841980680823326,
      0.008822892792522907,
      -0.04314330965280533,
      0.028755612671375275,
      0.0003813976072706282,
      0.0430523119866848,
      0.028425663709640503,
      -0.013070490211248398,
      0.004226605407893658,
      0.05520014464855194,
      0.0007539588259533048,
      0.022415470331907272,
      -0.0391739197075367,
      -0.052805956453084946,
      0.01662546955049038,
      0.009719659574329853,
      0.061664287000894547,
      0.030217288061976433,
      0.036047566682100296,
      -0.07255604863166809,
      0.010422785766422749,
      0.06729260832071304,
      0.005944814067333937,
      0.04901887848973274,
      0.07976169884204865,
      0.009175200015306473,
      -0.01516091637313366,
      0.05060703679919243,
      -0.03392983600497246,
      0.08387858420610428,
      0.03448573499917984,
      -0.08162136375904083,
      0.06839222460985184,
      -0.030332691967487335,
      -0.04440385475754738,
      0.028713490813970566,
      0.012021214701235294,
      0.022330710664391518,
      -0.06116122379899025,
      0.070217065513134,
      0.09831639379262924,
      -0.028589673340320587,
      0.012813321314752102,
      0.03829572722315788,
      -0.03734952211380005,
      0.021398762241005898,
      -0.015689527615904808,
      -0.023903032764792442,
      -0.00776552502065897,
      0.006593209225684404,
      0.03119889460504055,
      -0.0029662963934242725,
      0.0358571782708168,
      0.0036120626609772444,
      -0.06117475777864456,
      0.07745441049337387,
      -0.11522278189659119,
      -0.056455016136169434,
      0.0518621988594532,
      0.050140056759119034,
      0.07938511669635773,
      0.029828686267137527,
      0.022336728870868683,
      -0.05136041343212128,
      -0.017078768461942673,
      0.0019323194865137339,
      -0.12353461980819702,
      -0.027590492740273476,
      -0.03743154928088188,
      0.02381344512104988,
      0.040745995938777924,
      0.01999621093273163,
      -0.028153399005532265,
      -0.04507339000701904,
      -0.006030093878507614,
      -0.06846020370721817,
      0.06159015744924545,
      -0.00497118104249239,
      -0.04391937702894211,
      0.004533310886472464,
      0.034772247076034546,
      -0.013732459396123886,
      -0.05494082346558571,
      0.0758448913693428,
      -0.0200711227953434,
      0.041785966604948044,
      -0.004064809996634722,
      0.03337492048740387,
      0.019709300249814987,
      0.042253121733665466,
      0.0354330912232399,
      -0.0020737636368721724,
      0.002319907769560814,
      0.0017473174957558513,
      0.0070378342643380165,
      0.025668079033493996,
      0.005594685673713684,
      0.030873171985149384,
      -0.05398289114236832,
      -0.06635308265686035,
      0.07689226418733597,
      0.04294554144144058,
      0.06066538020968437,
      0.062342286109924316,
      0.07524479180574417,
      0.02435869164764881,
      -0.04182766005396843,
      0.10200385749340057,
      0.03115597367286682,
      -0.00913051888346672,
      -0.032506849616765976,
      -0.057015348225831985,
      0.12334567308425903,
      -0.004742104560136795,
      0.051819540560245514,
      -0.02041052281856537,
      0.01331586018204689,
      -0.0019859098829329014,
      0.06074050813913345,
      -0.06405607610940933,
      -0.01688307151198387,
      0.00940124224871397
    ],
    [
      -0.006757541559636593,
      0.007198644801974297,
      -0.05368177220225334,
      -0.02107788436114788,
      0.006170431151986122,
      0.05710482597351074,
      0.0664697140455246,
      -0.006525069009512663,
      -0.022710800170898438,
      -0.034811291843652725,
      -0.024647485464811325,
      -0.004361365456134081,
      -0.02094264142215252,
      0.02581329271197319,
      0.0658797100186348,
      0.01116520818322897,
      -0.015963252633810043,
      -0.07752572000026703,
      0.0075056347995996475,
      -0.06760404258966446,
      -0.044977616518735886,
      -0.021175522357225418,
      0.05320213362574577,
      0.042004525661468506,
      -0.019813455641269684,
      0.0014119033003225923,
      -0.05965564027428627,
      -0.02002228982746601,
      -0.017284715548157692,
      -0.008133134804666042,
      -0.0651824101805687,
      0.042300526052713394,
      -0.046160679310560226,
      0.02139599621295929,
      0.01604597270488739,
      -0.0735490545630455,
      0.09681203216314316,
      -0.040183670818805695,
      0.04977915808558464,
      -0.1526651829481125,
      -0.038044676184654236,
      0.004510608501732349,
      -0.01926434226334095,
      0.05382886528968811,
      0.04356426000595093,
      -0.04154784977436066,
      0.03673943504691124,
      0.05643376335501671,
      0.006081182509660721,
      -0.05450260639190674,
      0.03793931379914284,
      -0.01324567198753357,
      0.01942361518740654,
      0.05756291002035141,
      0.07253102958202362,
      -0.004499162081629038,
      -0.013212475925683975,
      -0.056529778987169266,
      0.048608243465423584,
      -0.05864771828055382,
      0.03649356588721275,
      -0.021373070776462555,
      -0.013929321430623531,
      -0.06315771490335464,
      -0.005681411828845739,
      0.07781216502189636,
      -0.07490914314985275,
      0.026702065020799637,
      -0.004718204028904438,
      0.02865716628730297,
      -0.01807369478046894,
      -0.005010940134525299,
      0.0109255975112319,
      0.030444521456956863,
      0.07586779445409775,
      -0.011033675633370876,
      0.014433481730520725,
      -0.026170408353209496,
      -0.030961303040385246,
      0.046108659356832504,
      0.08221392333507538,
      -0.021498965099453926,
      -0.016381267458200455,
      0.01670839637517929,
      0.02329963818192482,
      0.021087827160954475,
      -0.07943213731050491,
      0.0510687418282032,
      0.00965512078255415,
      0.03171774372458458,
      0.037592485547065735,
      -0.007929076440632343,
      -0.036019887775182724,
      -0.013329995796084404,
      -0.02256869152188301,
      -0.0503779873251915,
      -0.03615906462073326,
      -0.04832309111952782,
      -0.0408596508204937,
      0.01906486786901951,
      0.015090222470462322,
      -0.045510999858379364,
      0.022378964349627495,
      -0.01422933954745531,
      0.033272672444581985,
      0.0668552964925766,
      -0.018283281475305557,
      -0.03354812040925026,
      0.06780321896076202,
      -0.024633560329675674,
      -0.036196019500494,
      0.05939050018787384,
      0.09646923094987869,
      0.03707311302423477,
      0.01764872297644615,
      -0.17337444424629211,
      0.02539517916738987,
      -0.031829074025154114,
      0.07890639454126358,
      0.058280084282159805,
      -0.010640020482242107,
      0.007498724851757288,
      -0.02352878451347351,
      0.043771903961896896,
      -0.020999271422624588,
      -0.0011184836039319634,
      -0.0370478630065918,
      -0.021045418456196785,
      0.04249110072851181,
      0.051920097321271896,
      -0.04743102192878723,
      -0.009325983002781868,
      -0.0019230344332754612,
      -0.05454886704683304,
      -0.006128882989287376,
      -0.01855986937880516,
      -0.021744683384895325,
      -0.03461387753486633,
      -0.020950764417648315,
      -0.033294133841991425,
      0.01594954915344715,
      -0.047779180109500885,
      0.001370511599816382,
      -0.020362699404358864,
      0.10170849412679672,
      0.007993170991539955,
      0.028713993728160858,
      -0.011800140142440796,
      0.028533393517136574,
      -0.03263073414564133,
      0.03432479873299599,
      -0.06365209072828293,
      -0.02559276483952999,
      -0.04716549068689346,
      -0.052616678178310394,
      0.021437661722302437,
      -0.028151152655482292,
      -0.044719234108924866,
      0.055025357753038406,
      0.0016226265579462051,
      0.08607536554336548,
      -0.053394023329019547,
      -0.0472097285091877,
      0.05011914297938347,
      -0.045565858483314514,
      0.04492003098130226,
      -0.03767462819814682,
      0.07634253799915314,
      -0.020488405600190163,
      0.05494453012943268,
      0.10586385428905487,
      0.026371384039521217,
      0.05623387172818184,
      -0.06224573403596878,
      0.03621986508369446,
      0.04729747027158737,
      -0.02505374513566494,
      0.06973279267549515,
      0.017145687714219093,
      0.05193202570080757,
      -0.0812193751335144,
      -0.028629088774323463,
      0.033695876598358154,
      0.007518135942518711,
      -0.04122566804289818,
      0.00438879756256938,
      0.07359795272350311,
      0.02213500253856182,
      0.11091870069503784,
      -0.0002778924535959959,
      -0.03787592053413391,
      -0.10857586562633514,
      0.0072783916257321835,
      -0.006920623127371073,
      0.08282720297574997,
      0.0514569990336895,
      -0.026117781177163124,
      -0.07608842849731445,
      -0.03508533537387848,
      0.04090087115764618,
      0.025660715997219086,
      0.03239761292934418,
      -0.0661129429936409,
      0.015542421489953995,
      -0.011344731785356998,
      -0.007492885459214449,
      -0.02928219921886921,
      0.08007273823022842,
      0.04496832937002182,
      -0.05522549897432327,
      0.011664840392768383,
      -0.03136257454752922,
      -0.08860772848129272,
      0.11433205753564835,
      -0.053162358701229095,
      -0.029270241037011147,
      -0.024508316069841385,
      0.01560789905488491,
      0.015832072123885155,
      0.04403446242213249,
      -0.0005265278741717339,
      0.07876408845186234,
      -0.029978249222040176,
      0.04920915886759758,
      0.010523484088480473,
      0.06124906614422798,
      0.04969172924757004,
      0.04185868799686432,
      -0.020129477605223656,
      -0.07372798025608063,
      -0.036044564098119736,
      0.05666496232151985,
      -0.030432479456067085,
      -0.11831094324588776,
      -0.02105887047946453,
      -0.004448584746569395,
      -0.02482285536825657,
      -0.02744782157242298,
      0.04530777782201767,
      0.11970127373933792,
      0.038538094609975815,
      -0.032249096781015396,
      0.004961794707924128,
      0.04790332913398743,
      0.014079739339649677,
      -0.0008496662485413253,
      -0.022199170663952827,
      -0.012230264954268932,
      0.001948982710018754,
      0.08527088165283203,
      0.047981712967157364,
      0.022245313972234726,
      -0.017457515001296997,
      -0.06721074134111404,
      -0.08329000324010849,
      -0.02763953246176243,
      0.03102646768093109,
      -0.010808955878019333,
      0.08466513454914093,
      -0.09288366883993149,
      0.05231476575136185,
      0.05027935281395912,
      0.0001046981051331386,
      0.005195337813347578,
      0.07047377526760101,
      0.029929352924227715,
      0.051061782985925674,
      -0.044523343443870544,
      0.04349981248378754,
      -0.044285230338573456,
      0.0243067629635334,
      -0.0016529950080439448,
      -0.010753502137959003,
      -0.005096552427858114,
      -0.004532238002866507,
      -0.006803099066019058,
      -0.060846470296382904,
      0.07072608172893524,
      -0.046228572726249695,
      -0.06671825051307678,
      -0.03450983762741089,
      -0.023885278031229973,
      -0.03254455327987671,
      -0.09098010510206223,
      0.023516753688454628,
      0.08615998923778534,
      0.027836091816425323,
      0.01877686195075512,
      0.01509030070155859,
      0.10506054013967514,
      0.05940994992852211,
      0.0245863925665617,
      0.0026776546146720648,
      0.019400764256715775,
      -0.021258659660816193,
      0.01590503193438053,
      0.01062733680009842,
      0.08558527380228043,
      0.043596360832452774,
      -0.08607260882854462,
      0.11183588951826096,
      -0.0382746085524559,
      -0.003352770581841469,
      0.022117529064416885,
      -0.09002497047185898,
      0.02303377166390419,
      -0.01655484363436699,
      -0.008750025182962418,
      0.013396971859037876,
      -0.052575770765542984,
      0.05294732749462128,
      0.029298631474375725,
      -0.05035553500056267,
      0.10585759580135345,
      0.021512294188141823,
      0.09324860572814941,
      -0.03417855128645897,
      0.02271464467048645,
      0.022801164537668228,
      0.07394284009933472,
      0.01657198555767536,
      -0.08024588227272034,
      -0.03323687985539436,
      0.02384188398718834,
      -0.017513368278741837,
      0.05555795133113861,
      -0.047218047082424164,
      0.014098825864493847,
      0.053182754665613174,
      0.002073592972010374,
      0.004888847935944796,
      -0.009942694567143917,
      0.016626890748739243,
      0.11648760735988617,
      0.029159432277083397,
      -0.08650770783424377,
      0.024360796436667442,
      -0.012832198292016983,
      0.07165423780679703,
      0.00833719689399004,
      0.08253917098045349,
      0.012123831547796726,
      0.03130440041422844,
      0.04964244365692139,
      0.04458525776863098,
      0.053788576275110245,
      0.00023080856772139668,
      0.041031692177057266,
      0.041435159742832184,
      0.023718353360891342,
      0.014804337173700333,
      -0.05663330852985382,
      -0.03811806067824364,
      0.011062659323215485,
      -0.006856133695691824,
      -0.035471413284540176,
      0.04452691599726677,
      0.09029227495193481,
      0.03147246688604355,
      -0.07562624663114548,
      0.022332649677991867,
      -0.07896524667739868,
      0.04396706447005272,
      0.0036953568924218416,
      -0.06543055176734924,
      0.006557911168783903,
      -0.009996102191507816,
      -0.04799754545092583,
      0.046407587826251984,
      -0.0037683139089494944,
      -0.0342768058180809,
      0.014872098341584206,
      -0.05083144083619118,
      0.036985110491514206,
      -0.031004367396235466,
      -0.01467650942504406,
      0.03565327450633049,
      -0.0058508990332484245,
      0.021827148273587227,
      0.01659785956144333,
      -0.03238143399357796,
      -0.004562973510473967,
      0.018419520929455757,
      0.029319826513528824,
      0.04254759103059769,
      -0.04796084016561508,
      0.046134814620018005,
      0.03000365011394024,
      -0.005959463305771351,
      -0.002447993727400899,
      -0.039742205291986465,
      -0.009285913780331612,
      -0.0038228973280638456,
      0.051175449043512344,
      -0.10265573859214783,
      -0.02362167462706566,
      -0.006114552728831768,
      -0.04558408632874489,
      0.020654138177633286,
      0.09072361141443253,
      0.03597564995288849,
      -0.040442414581775665,
      -0.033003415912389755,
      0.006911566946655512,
      0.0447726808488369,
      0.003375663422048092,
      0.07222096621990204,
      -0.009121361188590527,
      0.003852752037346363,
      0.029218001291155815,
      0.05307956784963608,
      0.0016874887514859438,
      -0.029543865472078323,
      0.003921955358237028,
      -0.0161722544580698,
      0.09636380523443222,
      -0.021059276536107063,
      0.010614688508212566,
      0.011040636338293552,
      0.0219603031873703,
      -0.03037692978978157,
      0.06979150325059891,
      -0.0331413559615612,
      -0.0001555341004859656,
      0.031198110431432724,
      -0.057092417031526566,
      -0.03237881138920784,
      -0.03865771368145943,
      -0.05882858857512474,
      -0.00023410700669046491,
      -0.050142478197813034,
      0.03250470012426376,
      -0.011151560582220554,
      -0.07442036271095276,
      -0.03838631510734558,
      -0.04828941076993942,
      0.012208083644509315,
      -0.0017632083036005497,
      -0.010125422850251198,
      -0.00481923995539546,
      0.03498112037777901,
      0.011604759842157364,
      -0.03671620041131973,
      0.059198424220085144,
      -0.08313216269016266,
      0.02184826321899891,
      0.030335042625665665,
      -0.03251662850379944,
      -0.04452507197856903,
      -0.051955532282590866,
      0.028693437576293945,
      0.03433253616094589,
      0.02744174189865589,
      0.006057373248040676,
      0.006537314970046282,
      0.09117209166288376,
      -0.018881261348724365,
      -0.009214455261826515,
      0.041447367519140244,
      -0.015863832086324692,
      -0.0032624646555632353,
      -0.033348288387060165,
      0.005931755993515253,
      -0.04927045851945877,
      -0.048308201134204865,
      0.037885382771492004,
      -0.03444399684667587,
      -0.01585228741168976,
      0.04203033074736595,
      0.004143356811255217,
      0.01935775764286518,
      0.06915010511875153,
      0.07726960629224777,
      0.015042778104543686,
      0.001982670044526458,
      0.011916326358914375,
      0.01531093381345272,
      0.0557890348136425,
      -0.014209087938070297,
      0.005386917386204004,
      0.038748666644096375,
      0.004453724715858698,
      -0.019607368856668472,
      -0.08570946007966995,
      -0.012854792177677155,
      -0.019617294892668724,
      0.01760164089500904,
      -0.10491254180669785,
      0.013405007310211658,
      0.023798080161213875,
      -0.05115373805165291,
      -0.07928305864334106,
      0.12092658877372742,
      0.013262461870908737,
      -0.07231605052947998,
      0.008906494826078415,
      0.0007638665265403688,
      -0.03519497066736221,
      -0.015935048460960388,
      -0.027262864634394646,
      -0.06406546384096146,
      -0.038910865783691406,
      0.05174540355801582
    ],
    [
      0.02634710632264614,
      -0.02685268223285675,
      -0.06057704612612724,
      0.011201536282896996,
      -0.049677830189466476,
      0.02155856043100357,
      -0.04823802039027214,
      -0.026467550545930862,
      -0.125234916806221,
      0.10501053929328918,
      -0.02183488756418228,
      -0.08516251295804977,
      0.06488955020904541,
      0.03576013818383217,
      0.0016770067159086466,
      0.007203319575637579,
      0.08935576677322388,
      0.05837101861834526,
      0.0842399075627327,
      -0.03349317982792854,
      -0.04208824783563614,
      0.010219515301287174,
      0.07757466286420822,
      0.008317593485116959,
      0.03179550915956497,
      -0.07209105044603348,
      -0.04697650298476219,
      -0.012604499235749245,
      -0.016756169497966766,
      -0.005671969614923,
      0.04355441406369209,
      0.02526899054646492,
      -0.05200360715389252,
      -0.05578358843922615,
      0.06506111472845078,
      0.04097915440797806,
      0.025885971263051033,
      -0.0060314759612083435,
      0.03952634707093239,
      0.029081426560878754,
      0.034629616886377335,
      -0.04390832409262657,
      -0.010411271825432777,
      -0.016364099457859993,
      -0.03344114124774933,
      -0.0035684979520738125,
      -0.05837737396359444,
      0.05810410901904106,
      0.01608167588710785,
      0.018204480409622192,
      0.025596892461180687,
      0.01747770607471466,
      -0.012243545614182949,
      -0.0053145671263337135,
      0.051507849246263504,
      0.12502796947956085,
      -0.09112381935119629,
      -0.09383883327245712,
      -0.06431280821561813,
      0.10675723105669022,
      -0.06604314595460892,
      -0.018157748505473137,
      0.018559018149971962,
      0.007057984825223684,
      -0.02475229650735855,
      0.01075226254761219,
      -0.016582656651735306,
      0.041560448706150055,
      -0.006456478498876095,
      0.09064746648073196,
      -0.0646183118224144,
      0.01956740766763687,
      -0.08799530565738678,
      -0.0537513829767704,
      0.04363454505801201,
      0.0048045869916677475,
      0.03990884870290756,
      0.030663317069411278,
      -0.03738701716065407,
      0.03462737798690796,
      0.1051531434059143,
      0.028220895677804947,
      0.00809937622398138,
      0.057079777121543884,
      0.0021710782311856747,
      0.04960503801703453,
      -0.04943434149026871,
      -0.015246666967868805,
      -0.03202667087316513,
      -0.021243391558527946,
      0.05162060260772705,
      0.03229239955544472,
      -0.037672605365514755,
      -0.010851863771677017,
      -0.010051394812762737,
      0.01763702929019928,
      -0.10178403556346893,
      -0.08859708905220032,
      0.05631035938858986,
      0.010296434164047241,
      0.04132605344057083,
      0.018873823806643486,
      0.03160449117422104,
      -0.014540592208504677,
      -0.02370450831949711,
      -0.03159988671541214,
      -0.06034226715564728,
      0.01521240919828415,
      0.027159033343195915,
      0.0016176862409338355,
      -0.06805025041103363,
      -0.04406866058707237,
      0.058106135576963425,
      0.06052592024207115,
      -0.01841515116393566,
      0.04292229562997818,
      0.014805406332015991,
      -0.02068185992538929,
      -0.07804320007562637,
      -0.04512564465403557,
      -0.09863342344760895,
      -0.06989578902721405,
      -0.059647683054208755,
      -0.03407701477408409,
      -0.019558200612664223,
      0.00023819507623557,
      0.03891772776842117,
      0.024662252515554428,
      -0.010450704954564571,
      0.0500466451048851,
      -0.04316788911819458,
      0.07455828040838242,
      0.006186488084495068,
      -0.06562623381614685,
      0.005323747172951698,
      0.023573240265250206,
      0.018697289749979973,
      0.023257674649357796,
      -0.037364404648542404,
      0.048725783824920654,
      -0.04847734794020653,
      0.03928280994296074,
      0.027699783444404602,
      -0.03223387151956558,
      -0.006886048708111048,
      -0.05996019020676613,
      0.006085801869630814,
      -0.003374060383066535,
      -0.0012808310566470027,
      -0.003927269019186497,
      -0.004437293857336044,
      -0.049671195447444916,
      -0.0025352854281663895,
      -0.07147084176540375,
      -0.0333072654902935,
      -0.054151855409145355,
      0.03486218303442001,
      0.015368248336017132,
      0.013441446237266064,
      -0.008134155534207821,
      -0.00029555329820141196,
      -0.09144286811351776,
      -0.0740947499871254,
      -0.056876953691244125,
      -0.01381402462720871,
      0.009076499380171299,
      0.06204315647482872,
      0.005055558402091265,
      -0.025744827464222908,
      0.11851083487272263,
      -0.04403292015194893,
      0.06806044280529022,
      0.007071469910442829,
      0.026206305250525475,
      -0.045768726617097855,
      -0.021218866109848022,
      -0.02122447080910206,
      0.027112912386655807,
      -0.060831647366285324,
      -0.022309286519885063,
      -0.0009091739193536341,
      0.021817941218614578,
      -0.017760779708623886,
      -0.07774237543344498,
      -0.0914926677942276,
      0.05250375717878342,
      -0.005234462209045887,
      -0.026167215779423714,
      0.03278830274939537,
      0.06010163202881813,
      0.03147580102086067,
      0.0021355769131332636,
      0.025684185326099396,
      0.037535782903432846,
      0.027751993387937546,
      0.020880620926618576,
      0.0070616514421999454,
      0.1107214093208313,
      -0.0560096800327301,
      0.07752396166324615,
      0.009243114851415157,
      0.01240365207195282,
      -0.08827302604913712,
      -0.04733957722783089,
      -0.08252198994159698,
      0.02037425898015499,
      -0.08394064754247665,
      0.02615426294505596,
      -0.056950684636831284,
      -0.10860001295804977,
      0.11534272134304047,
      -0.027490150183439255,
      0.0062032947316765785,
      -0.008419985882937908,
      -0.08420101553201675,
      -0.005769239738583565,
      -0.008647036738693714,
      0.04516281560063362,
      0.034854184836149216,
      -0.032943952828645706,
      -0.01583033986389637,
      -0.009595056064426899,
      0.04213714227080345,
      -0.02265031263232231,
      -0.015013088472187519,
      0.035028841346502304,
      -0.02395918034017086,
      -0.015058108605444431,
      -0.0054558957926929,
      0.05020743981003761,
      -0.050584711134433746,
      -0.015424654819071293,
      0.004568320233374834,
      0.0018933918327093124,
      -0.032227758318185806,
      0.008212648332118988,
      -0.04219457134604454,
      -0.03384271636605263,
      0.04065493121743202,
      0.002833206905052066,
      -0.02063165418803692,
      -0.00074871996184811,
      -0.0035085000563412905,
      0.06985148042440414,
      -0.029961325228214264,
      -0.04610367491841316,
      -0.07436656206846237,
      -0.02543092891573906,
      0.053216516971588135,
      0.03299031779170036,
      -0.021386615931987762,
      -0.01004504133015871,
      0.05411674454808235,
      0.02309209294617176,
      0.017352160066366196,
      -0.030172469094395638,
      -0.0702451691031456,
      -0.04833856225013733,
      -0.04800865054130554,
      -0.009934954345226288,
      0.0012828109320253134,
      0.003649849910289049,
      -0.05481975898146629,
      0.0367143489420414,
      -0.02812119387090206,
      -0.11448057740926743,
      0.025474902242422104,
      0.042721305042505264,
      -0.02547317184507847,
      0.10359907895326614,
      -0.02856234461069107,
      -0.02145511284470558,
      -0.04485329985618591,
      -0.0483674630522728,
      -0.012545885518193245,
      0.044558487832546234,
      -0.03512177616357803,
      -0.007262344937771559,
      -0.0284186489880085,
      -0.07395389676094055,
      -0.042425140738487244,
      0.010344482026994228,
      0.004851744510233402,
      0.0697811022400856,
      -0.05608075484633446,
      -0.028797069564461708,
      0.025963343679904938,
      0.025532612577080727,
      0.0249523613601923,
      -0.01107097789645195,
      0.010475153103470802,
      -0.007720385678112507,
      0.000949597975704819,
      -0.07359646260738373,
      0.008962460793554783,
      -0.004227835219353437,
      -0.049607835710048676,
      -0.07719440013170242,
      -0.04423697292804718,
      -0.0074454257264733315,
      -0.07170616090297699,
      0.0194990411400795,
      0.003872922621667385,
      0.03990777209401131,
      0.05267111957073212,
      0.05381220951676369,
      0.08244744688272476,
      -0.05084143206477165,
      0.023270482197403908,
      -0.05032968521118164,
      0.06448477506637573,
      -0.025076698511838913,
      0.01339096948504448,
      -0.05302651226520538,
      0.050116948783397675,
      0.04725350812077522,
      -0.08774342387914658,
      0.033150844275951385,
      -0.012987137772142887,
      0.006067877169698477,
      0.0697237029671669,
      -0.011774417012929916,
      -0.05605996400117874,
      0.034568194299936295,
      0.04387976974248886,
      -0.009204454720020294,
      -0.008429235778748989,
      -0.04370516538619995,
      -0.0027527993079274893,
      0.047074150294065475,
      0.10393889993429184,
      0.035294242203235626,
      0.0359891876578331,
      -0.06933202594518661,
      -0.06765376776456833,
      -0.0012900761794298887,
      -0.00896875374019146,
      -0.0333891361951828,
      -0.04919677972793579,
      -0.018366582691669464,
      0.0031309996265918016,
      0.029550747945904732,
      -0.06112297624349594,
      0.06235223263502121,
      -0.021598804742097855,
      -0.07153978198766708,
      0.018634706735610962,
      -0.038584962487220764,
      0.004690837115049362,
      -0.060344308614730835,
      0.007450041361153126,
      0.029798118397593498,
      -0.06826208531856537,
      0.038094695657491684,
      -0.015988171100616455,
      0.08068380504846573,
      -0.05640672147274017,
      -0.006475683301687241,
      -0.025540200993418694,
      0.012072750367224216,
      0.0823964774608612,
      -0.004531996324658394,
      -0.002769746584817767,
      0.0351174995303154,
      -0.04926052317023277,
      -0.024407681077718735,
      -0.06545750051736832,
      -0.06131863594055176,
      -0.0439295656979084,
      0.04675015062093735,
      0.012146404013037682,
      0.04384743794798851,
      0.03727160394191742,
      -0.04985349625349045,
      0.09687487781047821,
      -0.013598073273897171,
      0.004813529085367918,
      -0.024229634553194046,
      -0.001961305271834135,
      -0.05367110297083855,
      -0.055868618190288544,
      -0.07045112550258636,
      -0.011071396991610527,
      0.03421403467655182,
      0.010307442396879196,
      0.015138573944568634,
      -0.0058545744977891445,
      -0.028058677911758423,
      0.00635502440854907,
      -0.01991541124880314,
      0.050566013902425766,
      0.0002629759255796671,
      0.0027565602213144302,
      0.012182986363768578,
      0.02355843409895897,
      0.006475464906543493,
      -0.00713246688246727,
      0.0023850975558161736,
      -0.010642526671290398,
      0.020097380504012108,
      -0.007218998856842518,
      0.010546871460974216,
      0.1258121132850647,
      -0.045398272573947906,
      0.005855853203684092,
      0.04914592206478119,
      0.06771927326917648,
      0.01281774789094925,
      0.07346109300851822,
      0.050047341734170914,
      0.08808453381061554,
      -0.09535851329565048,
      0.013132940046489239,
      0.006838476750999689,
      -0.05660064145922661,
      0.018256688490509987,
      -0.058964937925338745,
      0.018382493406534195,
      -0.043183885514736176,
      -0.09781062602996826,
      -0.05605791509151459,
      -0.11540018767118454,
      0.03748110309243202,
      -0.04086403548717499,
      0.06085401028394699,
      -0.05612736940383911,
      -0.027590900659561157,
      -0.004247027914971113,
      0.07631166279315948,
      -0.02788902260363102,
      -0.10299663245677948,
      0.014526978135108948,
      0.015238993801176548,
      0.02893931232392788,
      -0.07937797904014587,
      -0.083961620926857,
      -0.05606679618358612,
      -0.04559547081589699,
      -0.0187116377055645,
      0.026782242581248283,
      0.017084086313843727,
      0.04500791057944298,
      0.0030883080326020718,
      0.04156254604458809,
      0.07959672808647156,
      -0.027261238545179367,
      0.004422217607498169,
      -0.06766963005065918,
      0.015329165384173393,
      0.010208760388195515,
      -0.00883953645825386,
      0.15798580646514893,
      0.0330725759267807,
      0.02489360235631466,
      -0.10516376048326492,
      0.016315683722496033,
      -0.013332614675164223,
      0.097736656665802,
      0.04637707397341728,
      0.02524815872311592,
      -0.04007737338542938,
      0.01902182213962078,
      -0.023446282371878624,
      0.06753552705049515,
      -0.004749653395265341,
      -0.020132580772042274,
      -0.0336141474545002,
      -0.037614043802022934,
      -0.00931006669998169,
      0.06607083976268768,
      -0.011687242425978184,
      -0.03372576832771301,
      0.014254926703870296,
      0.05496397614479065,
      0.007184382528066635,
      0.00905182957649231,
      0.011192345060408115,
      -0.001174606615677476,
      0.020970048382878304,
      -0.009207699447870255,
      -0.04123567417263985,
      0.023324638605117798,
      -0.04947532340884209,
      -0.023556191474199295,
      0.0031903802882879972,
      -0.022616472095251083,
      -0.0350894033908844,
      0.07193212956190109,
      0.07195750623941422,
      0.05431478098034859,
      -0.010717467404901981,
      0.04014097899198532,
      -0.056019607931375504,
      0.025119271129369736,
      0.01442967914044857,
      0.03141292929649353,
      -0.014792315661907196,
      -0.006104536820203066,
      -0.0653696209192276,
      0.026270728558301926,
      -0.14145870506763458,
      -0.00506338756531477,
      0.002463273936882615
    ],
    [
      0.05225563049316406,
      0.016092414036393166,
      -0.010711396113038063,
      0.033107154071331024,
      -0.004490470513701439,
      0.004122411832213402,
      -0.01858169212937355,
      -0.03308138623833656,
      -0.053958598524332047,
      0.03697923570871353,
      0.0010277123656123877,
      0.06792578101158142,
      0.030633898451924324,
      0.009793847799301147,
      -0.03249756991863251,
      0.027898821979761124,
      -0.02369792014360428,
      -0.03275848552584648,
      -0.008428680710494518,
      -0.008996540680527687,
      0.03373988717794418,
      -0.04203733801841736,
      0.008158870041370392,
      0.008569857105612755,
      -0.013213230296969414,
      -0.010670303367078304,
      0.048963796347379684,
      0.022814378142356873,
      -0.05164061859250069,
      0.0574466846883297,
      -0.07191166281700134,
      -0.011658078990876675,
      -0.06607591360807419,
      0.039600618183612823,
      0.08441685140132904,
      0.013122080825269222,
      -0.04990172013640404,
      -0.08656100928783417,
      -0.04524945467710495,
      0.022889137268066406,
      0.004997430834919214,
      -0.11511801928281784,
      -0.03724562004208565,
      -0.09461300075054169,
      -0.011794781312346458,
      -0.05873361602425575,
      0.07169072329998016,
      0.06564886122941971,
      0.032755881547927856,
      -0.11805751174688339,
      -0.012127439491450787,
      0.05255502089858055,
      0.039654895663261414,
      -0.01856967993080616,
      0.025459127500653267,
      0.04761311039328575,
      -0.026095861569046974,
      0.052640486508607864,
      0.006904501933604479,
      0.04199206829071045,
      0.04824500158429146,
      0.02034086547791958,
      0.048129089176654816,
      0.006492102053016424,
      0.006614223588258028,
      -0.07679647207260132,
      0.013977687805891037,
      -0.0013341667363420129,
      -0.0042541176080703735,
      0.007116615306586027,
      -0.046375349164009094,
      -0.05651445314288139,
      -0.06695835292339325,
      0.06127758324146271,
      0.03858253359794617,
      -0.0024208598770201206,
      -0.044856633991003036,
      -0.006669097580015659,
      -0.0711439847946167,
      -0.014954259619116783,
      0.018494602292776108,
      -0.030708320438861847,
      0.059423867613077164,
      0.014383942820131779,
      -0.010061265900731087,
      0.04444222152233124,
      -0.0990181490778923,
      0.04833439737558365,
      0.0020277888979762793,
      0.04190944880247116,
      -0.03609786182641983,
      -0.07948920130729675,
      0.015300313010811806,
      -0.0060524470172822475,
      -0.05765656754374504,
      0.07688143104314804,
      -0.07864870131015778,
      0.042452238500118256,
      -0.04465188458561897,
      0.013449168764054775,
      0.0587460957467556,
      0.03395801782608032,
      0.022845104336738586,
      -0.014338461682200432,
      -0.027944382280111313,
      -0.028081607073545456,
      0.10795117169618607,
      -0.051675308495759964,
      0.0011445943964645267,
      -0.0210957583039999,
      -0.02381216548383236,
      -0.016819177195429802,
      -0.01345066074281931,
      0.05248766392469406,
      -0.022407250478863716,
      -0.012934133410453796,
      -0.055641867220401764,
      -0.08967393636703491,
      0.031899649649858475,
      -0.010759402066469193,
      -0.05374293774366379,
      -0.01489024143666029,
      -0.10361435264348984,
      0.0013720281422138214,
      0.11695392429828644,
      0.024524154141545296,
      -0.054743651300668716,
      -0.02244614064693451,
      -0.015293358825147152,
      -0.02455240860581398,
      0.12183250486850739,
      -0.021521585062146187,
      0.012152844108641148,
      -0.06265535950660706,
      0.00945051945745945,
      -0.03629047051072121,
      -0.06926711648702621,
      0.025208186358213425,
      0.04403463006019592,
      -0.026241054758429527,
      -0.017397399991750717,
      -0.056602802127599716,
      -0.03345991671085358,
      0.01664705201983452,
      0.09126650542020798,
      -0.02324097603559494,
      -0.07108759880065918,
      0.04024309664964676,
      -0.11164180189371109,
      0.0846218392252922,
      -0.0183467548340559,
      1.2435651115083601e-05,
      0.03592023253440857,
      0.053709544241428375,
      -0.005828538443893194,
      -0.10785694420337677,
      0.09413334727287292,
      -0.02814236655831337,
      -0.0012804485158994794,
      -0.009435134008526802,
      0.01725955121219158,
      -0.024681124836206436,
      0.006691774819046259,
      0.015314146876335144,
      -0.035949964076280594,
      -0.010797797702252865,
      -0.03569137677550316,
      -0.005702277645468712,
      -0.03427094221115112,
      -0.020495913922786713,
      0.03880228102207184,
      0.008926850743591785,
      -0.021367007866501808,
      -0.07448020577430725,
      0.01454426720738411,
      -0.07826155424118042,
      0.10822843015193939,
      0.03396275267004967,
      -0.03611147776246071,
      0.057979702949523926,
      -0.020416302606463432,
      0.015903446823358536,
      0.05781223624944687,
      -0.04293924570083618,
      0.010924141854047775,
      -0.02238437719643116,
      0.034112852066755295,
      0.07473212480545044,
      -0.037059228867292404,
      0.009704150259494781,
      0.02807256020605564,
      0.07749298959970474,
      0.022835591807961464,
      -0.0010054968297481537,
      0.012160555459558964,
      -0.04640248045325279,
      -0.011500909924507141,
      0.04098441079258919,
      0.03813127055764198,
      0.02131916768848896,
      0.011887774802744389,
      -0.038142722100019455,
      0.0528457947075367,
      -0.04562370851635933,
      0.023974532261490822,
      0.013944439589977264,
      0.05617617815732956,
      -0.056549668312072754,
      0.11737146228551865,
      0.002159502124413848,
      0.05801454931497574,
      -0.011931871064007282,
      0.011837090365588665,
      0.07880045473575592,
      -0.06228199228644371,
      -0.038836926221847534,
      -0.02934763766825199,
      0.0002494321088306606,
      -0.010123051702976227,
      -0.009125306271016598,
      -0.009233791381120682,
      0.010091808624565601,
      0.04731709137558937,
      -0.05085691064596176,
      0.02845074236392975,
      0.0167055856436491,
      0.023763084784150124,
      0.09705392271280289,
      0.05770682543516159,
      0.03241625428199768,
      -0.034428730607032776,
      0.021887008100748062,
      -0.05502180755138397,
      0.01070911530405283,
      -0.05623060092329979,
      -0.03554346039891243,
      0.06535334885120392,
      0.03326047584414482,
      -0.033456332981586456,
      -0.02744414284825325,
      -0.04085014387965202,
      0.037245940417051315,
      0.015951506793498993,
      -0.014685866422951221,
      0.01766340434551239,
      -0.05550742149353027,
      -0.06418053805828094,
      -0.0576704740524292,
      0.022633444517850876,
      0.03797561675310135,
      -0.019241005182266235,
      0.05546669661998749,
      -0.0383186861872673,
      0.01842626929283142,
      -0.04547992721199989,
      -0.0025910772383213043,
      0.009081357158720493,
      0.03829937055706978,
      0.06114503741264343,
      -0.07160857319831848,
      -0.042800094932317734,
      0.002364994725212455,
      -0.009977959096431732,
      -0.0020756942685693502,
      -0.013058784417808056,
      0.023612257093191147,
      0.06662850081920624,
      -0.039750341325998306,
      0.016858873888850212,
      -0.06366613507270813,
      -0.035057660192251205,
      0.08378177136182785,
      0.0912638008594513,
      0.04252533242106438,
      -0.04670754075050354,
      -0.005443699192255735,
      -0.0869237408041954,
      -0.03953072801232338,
      -0.014311728067696095,
      -0.04572352021932602,
      -0.038488760590553284,
      0.06438840180635452,
      -0.013614537194371223,
      -0.0006610865239053965,
      -0.03554651141166687,
      0.024343721568584442,
      0.07305164635181427,
      -0.027119982987642288,
      0.04334595799446106,
      0.014581129886209965,
      0.041313499212265015,
      -0.013963303528726101,
      -0.045226071029901505,
      0.02065887115895748,
      -0.11590320616960526,
      -0.02491184137761593,
      -0.07286389917135239,
      0.027882229536771774,
      -0.05672917887568474,
      -0.042975325137376785,
      -0.05322109907865524,
      -0.04214194416999817,
      0.08351698517799377,
      -0.025880252942442894,
      -0.016446972265839577,
      -0.010487953200936317,
      -0.08262921124696732,
      -0.07000698149204254,
      0.00899410992860794,
      -0.014948728494346142,
      0.04390902817249298,
      -0.04374797269701958,
      -0.0435340516269207,
      -0.008221287280321121,
      -0.018001118674874306,
      -0.008120736107230186,
      0.03800564631819725,
      0.03675859048962593,
      0.03199377283453941,
      0.09830131381750107,
      0.007469522766768932,
      0.004841148853302002,
      0.018116166815161705,
      0.022959904745221138,
      -0.08511371910572052,
      0.04989684373140335,
      -0.0036007093731313944,
      -0.06358517706394196,
      -0.028843972831964493,
      -0.08261333405971527,
      0.0482882596552372,
      -0.0715617686510086,
      0.06338497996330261,
      0.015185176394879818,
      0.03359198197722435,
      -0.002754722721874714,
      0.03663024306297302,
      -0.027850156649947166,
      0.017367597669363022,
      0.041976455599069595,
      0.01954960636794567,
      -0.022971849888563156,
      -0.003363787429407239,
      0.028911979869008064,
      -0.017429327592253685,
      0.016030697152018547,
      -0.012300691567361355,
      2.282879904669244e-05,
      0.08558092266321182,
      -0.028973525390028954,
      0.06485258787870407,
      0.013581866398453712,
      0.03378288075327873,
      -0.09499160945415497,
      0.015601559542119503,
      -0.013468196615576744,
      0.062266286462545395,
      -0.05960829183459282,
      -0.08090723305940628,
      -0.00640912028029561,
      0.05022294819355011,
      -0.020158903673291206,
      -0.007810721639543772,
      -0.0011520114494487643,
      0.06599178165197372,
      -0.08228299021720886,
      0.018765069544315338,
      0.023261534050107002,
      -0.028177009895443916,
      0.018818961456418037,
      -0.02970760129392147,
      0.014908406883478165,
      0.043193601071834564,
      0.04122759401798248,
      -0.008915835991501808,
      0.007186661008745432,
      -0.05398087948560715,
      -0.039908282458782196,
      0.03870653361082077,
      0.008344116620719433,
      0.009137350134551525,
      0.0065685599111020565,
      -0.010466370731592178,
      -0.004511328414082527,
      -0.05459301173686981,
      0.06988207995891571,
      -0.004547994118183851,
      -0.017830152064561844,
      -0.04662947356700897,
      0.04622212424874306,
      0.016575681045651436,
      0.021560272201895714,
      0.020642148330807686,
      -0.046410802751779556,
      0.06776145845651627,
      0.02375848777592182,
      -0.016378430649638176,
      -0.01588742807507515,
      -0.009077328257262707,
      -0.028958160430192947,
      0.03970973938703537,
      -0.030913474038243294,
      0.09155254065990448,
      -0.021651437506079674,
      -0.01720399595797062,
      -0.0033716275356709957,
      0.0350288562476635,
      0.0395604744553566,
      -0.0005625637131743133,
      -0.033951494842767715,
      0.07743582129478455,
      -0.046938005834817886,
      -0.009475831873714924,
      0.05451942980289459,
      0.06179410591721535,
      -0.000946461339481175,
      0.03480658307671547,
      -0.12327026575803757,
      -0.04596266523003578,
      0.002107130130752921,
      0.03506181389093399,
      0.0019978967029601336,
      0.008593444712460041,
      0.033766426146030426,
      0.02853453904390335,
      0.016118207946419716,
      -0.024182705208659172,
      -0.010368230752646923,
      0.06392572820186615,
      0.02389438822865486,
      -0.019636450335383415,
      -0.03137245029211044,
      0.08122154325246811,
      -0.012938654981553555,
      -0.007038141135126352,
      -0.009982110001146793,
      -0.005949887912720442,
      -0.012250808998942375,
      0.012352713383734226,
      0.05242519825696945,
      0.06335929036140442,
      -0.017723627388477325,
      -0.015367303974926472,
      0.07277429103851318,
      -0.02368602715432644,
      -0.09781508892774582,
      -0.034200433641672134,
      -0.04075297340750694,
      -0.024422409012913704,
      0.023652097210288048,
      -0.00945882685482502,
      0.03247585520148277,
      0.04265870526432991,
      -0.01504964753985405,
      -0.045086730271577835,
      0.023075824603438377,
      0.06874769181013107,
      -0.004941217135637999,
      0.017455440014600754,
      0.00458395853638649,
      0.017140129581093788,
      -0.0339767262339592,
      -0.005551395937800407,
      -0.08031436055898666,
      0.013689589686691761,
      0.019652580842375755,
      -0.020103730261325836,
      0.003611472435295582,
      -0.05488726869225502,
      0.0070027238689363,
      0.0012568208621814847,
      0.0475776232779026,
      -0.022081732749938965,
      -0.043960295617580414,
      0.03209328278899193,
      0.03564199432730675,
      0.04184027016162872,
      -0.05636835843324661,
      0.051568374037742615,
      0.02258956804871559,
      -0.023347362875938416,
      0.014733397401869297,
      0.0051526399329304695,
      -0.02994905412197113,
      -0.0014476722571998835,
      0.04331221431493759,
      -0.07613836973905563,
      0.019512025639414787,
      0.036028120666742325,
      0.0028293037321418524,
      -0.06005280464887619,
      -0.08127351105213165,
      -0.04130498692393303,
      0.05770834535360336,
      -0.036938417702913284,
      0.06253153085708618,
      0.0653313547372818,
      -0.04335653409361839,
      0.050884880125522614,
      0.03589911386370659,
      -0.015083454549312592,
      0.01012017484754324,
      0.008713727816939354
    ],
    [
      0.03599148988723755,
      -0.009364011697471142,
      -0.01842055283486843,
      0.06370381265878677,
      -0.040842264890670776,
      -0.08544717729091644,
      0.02209610305726528,
      0.006871678400784731,
      -0.00493928138166666,
      -0.013497221283614635,
      -0.003138092579320073,
      -0.010136505588889122,
      -0.05134320631623268,
      0.02345070242881775,
      -0.03735571354627609,
      0.001132644945755601,
      -0.006966914050281048,
      0.07938577979803085,
      0.05438639596104622,
      0.04058587923645973,
      -0.01475008949637413,
      0.02222856879234314,
      0.04074995219707489,
      0.029918702319264412,
      0.011720264330506325,
      -0.016641555353999138,
      -0.015638194978237152,
      0.026714203879237175,
      -0.07064434885978699,
      0.022691495716571808,
      -0.027268139645457268,
      -0.013220111839473248,
      0.028656216338276863,
      0.03522974252700806,
      -0.075011245906353,
      0.04347385838627815,
      0.03022613748908043,
      0.08173815160989761,
      -0.014432855881750584,
      0.01738397777080536,
      -0.024522189050912857,
      -0.021304313093423843,
      -0.042179130017757416,
      0.00973616261035204,
      0.013512549921870232,
      0.1042529046535492,
      -0.0014801722718402743,
      -0.028970150277018547,
      0.06302861869335175,
      -0.007475463207811117,
      -0.016924327239394188,
      -0.02785656601190567,
      0.058548882603645325,
      -0.0193147175014019,
      0.010768383741378784,
      -0.022093618288636208,
      -0.033736586570739746,
      -0.008418397046625614,
      0.009730231948196888,
      -0.0142580671235919,
      -0.020648103207349777,
      -0.037905216217041016,
      -0.02840765006840229,
      0.02519926242530346,
      0.053248051553964615,
      0.06803445518016815,
      0.06984449923038483,
      -0.04550136253237724,
      -0.04965384304523468,
      -0.024653129279613495,
      -0.09354811161756516,
      -0.08970215171575546,
      -0.0013887037057429552,
      0.045427873730659485,
      -0.011395782232284546,
      0.03500228002667427,
      -0.011730666272342205,
      0.02952226996421814,
      0.059844721108675,
      0.031315721571445465,
      0.07570264488458633,
      -0.032195284962654114,
      -0.06499695777893066,
      -0.03889036551117897,
      0.03015010431408882,
      0.025897150859236717,
      0.0712965875864029,
      -0.020788757130503654,
      0.049307819455862045,
      -0.05413828417658806,
      -0.0785585343837738,
      0.05276598036289215,
      -0.07412297278642654,
      0.01792449876666069,
      0.033308401703834534,
      -0.022081904113292694,
      0.04007957503199577,
      0.04897861182689667,
      0.017290949821472168,
      0.012010679580271244,
      -0.032745927572250366,
      0.00504882400855422,
      0.069534070789814,
      0.006297369487583637,
      -0.07917582243680954,
      -0.04412740468978882,
      -0.028361769393086433,
      0.023019829764962196,
      0.0068991826847195625,
      0.021534474566578865,
      -0.06546173989772797,
      -0.01245846226811409,
      0.07830100506544113,
      -0.0014765409287065268,
      -0.05414770543575287,
      0.13260379433631897,
      0.016876714304089546,
      0.007308607921004295,
      0.010926683433353901,
      0.011462117545306683,
      -0.04645347222685814,
      0.006683812942355871,
      0.005893507972359657,
      0.07530835270881653,
      0.06252491474151611,
      -0.03855456784367561,
      0.04371974244713783,
      -0.056794192641973495,
      -0.01590987667441368,
      -0.07655706256628036,
      0.021960381418466568,
      0.09000158309936523,
      -0.05754568800330162,
      -0.02437836118042469,
      0.050146400928497314,
      0.004734017886221409,
      -0.027119044214487076,
      0.04811522364616394,
      -0.03520817682147026,
      -0.013431312516331673,
      -0.10229476541280746,
      -0.02330707386136055,
      0.035774730145931244,
      0.048743367195129395,
      -0.05004304274916649,
      0.021803880110383034,
      0.03435317426919937,
      0.041621264070272446,
      -0.13968195021152496,
      0.030904915183782578,
      0.054745860397815704,
      -0.05058443546295166,
      -0.0300613921135664,
      0.03271446004509926,
      0.023825285956263542,
      0.03249788656830788,
      -0.10543844103813171,
      -0.005260215140879154,
      0.00904042087495327,
      -0.02364121377468109,
      -0.0007569281733594835,
      0.01294186431914568,
      -0.028781170025467873,
      -0.01852378435432911,
      0.027175115421414375,
      0.09134408086538315,
      0.052599407732486725,
      -0.055700164288282394,
      0.004394208081066608,
      0.030599555000662804,
      0.004281486850231886,
      0.013253597542643547,
      0.06460721790790558,
      -0.036778200417757034,
      0.009652417153120041,
      -0.04333975538611412,
      -0.038142915815114975,
      0.030419381335377693,
      0.007581091020256281,
      0.03005181811749935,
      -0.03635472059249878,
      0.024601463228464127,
      0.037180379033088684,
      -0.0713864415884018,
      0.02678554877638817,
      -0.015863493084907532,
      0.12579619884490967,
      0.018312890082597733,
      -0.009679009206593037,
      -0.09986184537410736,
      -0.03249405696988106,
      0.0608796626329422,
      -0.059741489589214325,
      -0.04998620226979256,
      0.030650334432721138,
      0.01785849966108799,
      0.004229023586958647,
      0.026461591944098473,
      0.0022498297039419413,
      0.02878064662218094,
      0.004775271750986576,
      0.00871043000370264,
      -0.017162511125206947,
      -0.07859108597040176,
      0.03540029749274254,
      -0.01622811332345009,
      -0.07913767546415329,
      0.04164339601993561,
      0.08089373260736465,
      -0.0032547968439757824,
      -0.019357871264219284,
      -0.01560148410499096,
      -0.03718271479010582,
      0.06161975488066673,
      -0.013066328130662441,
      0.012491772882640362,
      -0.026983970776200294,
      0.017170147970318794,
      0.03269076347351074,
      0.05094648525118828,
      -0.02863169275224209,
      0.02276206575334072,
      -0.02631690353155136,
      0.0403517410159111,
      0.03753526136279106,
      -0.04136441648006439,
      -0.00502380495890975,
      -0.023052621632814407,
      -0.010320546105504036,
      0.034645888954401016,
      0.012818478979170322,
      0.01176600344479084,
      0.002085090382024646,
      0.008128481917083263,
      -0.05281626805663109,
      0.027353888377547264,
      0.032105185091495514,
      -0.010253694839775562,
      -0.0191218052059412,
      -0.014290322549641132,
      0.009187828749418259,
      0.05896405503153801,
      0.013291415758430958,
      -0.03477749601006508,
      7.43422715459019e-05,
      -0.017310913652181625,
      0.029104355722665787,
      -0.05367255210876465,
      -0.016099369153380394,
      0.01840844750404358,
      0.010656074620783329,
      0.029237426817417145,
      0.05107191950082779,
      -0.012090782634913921,
      0.057746339589357376,
      0.010809509083628654,
      0.0025410871021449566,
      0.03592633083462715,
      -0.04341081902384758,
      -0.06427484005689621,
      0.09659191220998764,
      0.014245512895286083,
      0.021958842873573303,
      -0.024974286556243896,
      0.009859863668680191,
      -0.045347657054662704,
      0.07957728207111359,
      0.04389989376068115,
      0.03533687815070152,
      -0.03194417804479599,
      -0.002949907211586833,
      0.012759561650454998,
      -0.02768128551542759,
      0.0033751772716641426,
      -0.005472669377923012,
      -0.09004006534814835,
      0.03889928385615349,
      -0.017860794439911842,
      -0.00430058129131794,
      -0.023983463644981384,
      0.0672808438539505,
      -0.012234878726303577,
      0.049614012241363525,
      0.0213937945663929,
      0.03336063027381897,
      0.06678477674722672,
      0.005906535778194666,
      0.006195416674017906,
      0.03180043771862984,
      -0.061982300132513046,
      -0.0023313183337450027,
      -0.029599793255329132,
      0.033572718501091,
      -0.02691008523106575,
      -0.0354989655315876,
      -0.022958984598517418,
      -0.024018872529268265,
      -0.02375444583594799,
      0.040641672909259796,
      -0.01384933851659298,
      0.043029215186834335,
      0.029490672051906586,
      0.03451865538954735,
      -0.001958815846592188,
      0.025095578283071518,
      -0.03651663661003113,
      -0.019984368234872818,
      0.04119664430618286,
      0.016618169844150543,
      -0.021883442997932434,
      0.042596280574798584,
      0.032373685389757156,
      -0.01411029975861311,
      0.0910579189658165,
      0.021759403869509697,
      -0.04579956829547882,
      -0.05471499264240265,
      -0.017204785719513893,
      -0.028942391276359558,
      0.005458907689899206,
      0.011594627983868122,
      -0.03167871758341789,
      -0.05627204850316048,
      -0.08162782341241837,
      -0.045545440167188644,
      0.015526846051216125,
      0.04149826988577843,
      -0.014822591096162796,
      -0.003913095220923424,
      0.005565928295254707,
      0.007742505986243486,
      -0.0019299924606457353,
      0.026585470885038376,
      -0.048330146819353104,
      0.005875308532267809,
      -0.05500951036810875,
      -0.0194472074508667,
      0.08781663328409195,
      0.05317970737814903,
      0.03007841482758522,
      0.03292163088917732,
      -0.03441194072365761,
      0.06045540049672127,
      -0.017703520134091377,
      0.0337257906794548,
      0.005505831446498632,
      0.017727714031934738,
      -0.02019680291414261,
      0.06229174882173538,
      -0.0022216178476810455,
      -0.03837492689490318,
      -0.045161016285419464,
      -0.04663170874118805,
      0.06523845344781876,
      0.04025459289550781,
      -0.041880425065755844,
      0.0775328055024147,
      -0.02665773034095764,
      0.0606992281973362,
      0.06328853219747543,
      -0.009526701644062996,
      0.10438460111618042,
      0.01934421993792057,
      -0.016864672303199768,
      0.09114977717399597,
      0.0015572250122204423,
      0.05323704332113266,
      -0.06285843253135681,
      -0.02555275335907936,
      -0.010832661762833595,
      -0.017539948225021362,
      -0.0578928180038929,
      0.0608837828040123,
      -0.05093887820839882,
      0.008972276002168655,
      0.043318141251802444,
      0.03973677009344101,
      -0.0044748433865606785,
      -0.003055674023926258,
      -0.005614527966827154,
      0.026415960863232613,
      -0.08013515174388885,
      0.019833890721201897,
      0.006870448123663664,
      0.02366519719362259,
      -0.04439492151141167,
      -0.05925244837999344,
      -0.044375400990247726,
      0.10630130767822266,
      -0.032002463936805725,
      0.001501605729572475,
      -0.022416675463318825,
      -0.03600155934691429,
      -0.02524404786527157,
      -0.10626710951328278,
      0.05290241166949272,
      -0.07862856239080429,
      0.01240504439920187,
      -0.032832786440849304,
      0.005640056915581226,
      -0.027210796251893044,
      -0.012887670658528805,
      -0.048520807176828384,
      0.0714011862874031,
      -0.0053281160071492195,
      -0.02198641188442707,
      0.007176320068538189,
      0.019570158794522285,
      0.03151389956474304,
      -0.021770816296339035,
      0.0036611156538128853,
      0.0609252005815506,
      0.06357383728027344,
      -0.07534357905387878,
      -0.017286041751503944,
      -0.012537450529634953,
      0.0532967709004879,
      0.023929115384817123,
      0.07835181802511215,
      -0.06382512301206589,
      0.019240887835621834,
      0.023772556334733963,
      0.03382197394967079,
      -0.11044728010892868,
      0.010163801722228527,
      -0.030688898637890816,
      -0.07616094499826431,
      -0.029077574610710144,
      0.03149803727865219,
      0.018599605187773705,
      0.0241677388548851,
      0.15613606572151184,
      -0.0055768354795873165,
      -0.007428208831697702,
      0.0710655078291893,
      0.029655808582901955,
      -0.017122682183980942,
      0.0018100262386724353,
      0.055029381066560745,
      -0.02154201827943325,
      0.049848027527332306,
      -0.08021586388349533,
      0.04306432232260704,
      -0.006659782957285643,
      -0.01652524061501026,
      -0.08764778077602386,
      -0.06333133578300476,
      -0.05473333224654198,
      -0.01816175878047943,
      -0.033854711800813675,
      -0.014201447367668152,
      0.00029879543581046164,
      0.020236164331436157,
      0.07561910152435303,
      0.0009830311173573136,
      -0.015441609546542168,
      0.09369469434022903,
      -0.014541304670274258,
      0.017807314172387123,
      0.0070754350163042545,
      0.01019391231238842,
      -0.023117652162909508,
      -0.03278832137584686,
      0.06695222854614258,
      0.040038712322711945,
      -0.014897109009325504,
      -0.02493947744369507,
      0.03149180859327316,
      -0.03476296365261078,
      -0.07702962309122086,
      -0.028715617954730988,
      -0.04379138723015785,
      0.0020611323416233063,
      0.008628674782812595,
      0.030331410467624664,
      -0.011150681413710117,
      0.005027217790484428,
      -0.026969486847519875,
      0.04182010516524315,
      0.011079688556492329,
      -0.010171185247600079,
      0.06905289739370346,
      0.06652385741472244,
      0.01257787924259901,
      0.004665824584662914,
      -0.014515245333313942,
      -0.023524971678853035,
      -0.020209351554512978,
      -0.08202413469552994,
      -0.01670500449836254,
      0.059895239770412445,
      0.01673971489071846,
      -0.025235803797841072,
      0.06326097249984741,
      -0.04654236137866974,
      0.006383439525961876,
      0.018476620316505432,
      0.028187759220600128,
      0.011661057360470295,
      -0.011224018409848213,
      -0.026921620592474937,
      -0.040619585663080215,
      -0.007571185473352671
    ],
    [
      0.020781343802809715,
      0.004124139901250601,
      -0.00275353598408401,
      0.03812149912118912,
      -0.0006769546889699996,
      -0.04612259566783905,
      0.03127869963645935,
      -0.0003551072150003165,
      -0.054355867207050323,
      0.04034163057804108,
      0.002273350488394499,
      -0.05639331415295601,
      -0.053534653037786484,
      -0.03073536418378353,
      -0.015791872516274452,
      -0.100509412586689,
      0.06678853183984756,
      -0.0012036287225782871,
      -0.03581253066658974,
      -0.012155290693044662,
      -0.039596833288669586,
      -0.017746655270457268,
      0.03982715681195259,
      0.032932136207818985,
      0.01931171864271164,
      0.050333425402641296,
      0.05576993525028229,
      -0.033410560339689255,
      0.0294309351593256,
      0.02001306787133217,
      0.019543886184692383,
      0.013347454369068146,
      -0.05625603720545769,
      -0.026774102821946144,
      0.022577090188860893,
      -0.03305826708674431,
      -0.0063470802269876,
      -0.0016974759055301547,
      -0.03393792733550072,
      -0.007105542812496424,
      0.06438092142343521,
      0.0146166468039155,
      -0.07377976179122925,
      0.02101936750113964,
      -0.06029907241463661,
      0.015001700259745121,
      0.026485666632652283,
      0.04604770615696907,
      0.06423427164554596,
      0.05001271888613701,
      0.06660868227481842,
      0.06220754608511925,
      -0.03917279839515686,
      -0.02787853218615055,
      -0.013370567001402378,
      -0.03286955505609512,
      0.005038233939558268,
      0.06596066802740097,
      -0.050477296113967896,
      0.012601305730640888,
      -0.004309328272938728,
      -0.09167608618736267,
      -0.0014366068644449115,
      -0.028804698958992958,
      -0.06984329968690872,
      -0.00983255635946989,
      0.001105487230233848,
      -0.0318145826458931,
      -0.08629419654607773,
      -0.0499567836523056,
      -0.06770533323287964,
      -0.023239633068442345,
      0.0020566300954669714,
      0.0856432095170021,
      -0.04926282912492752,
      0.028455810621380806,
      -0.014723975211381912,
      -0.0009854795644059777,
      0.014568287879228592,
      0.030432863160967827,
      0.030825171619653702,
      -0.06304813176393509,
      0.04774337261915207,
      0.01375459786504507,
      0.08677197247743607,
      0.023996615782380104,
      -0.024791041389107704,
      -0.0018691647564992309,
      0.05104393512010574,
      0.04424024373292923,
      0.06535052508115768,
      -0.021978510543704033,
      -0.03526603803038597,
      -0.0787348672747612,
      -0.00994466058909893,
      0.048713259398937225,
      -0.04253995791077614,
      0.05832891911268234,
      0.03748086094856262,
      -0.023715246468782425,
      0.0026814870070666075,
      -0.013180337846279144,
      0.006854109466075897,
      -0.07128346711397171,
      -0.019960088655352592,
      -0.08121488988399506,
      -0.015310847200453281,
      -0.00935707800090313,
      0.028752239421010017,
      0.04049820825457573,
      -0.01845967397093773,
      0.028115199878811836,
      0.013464394956827164,
      -0.07542244344949722,
      -0.04092412814497948,
      0.07586608082056046,
      0.03538213670253754,
      -0.08284066617488861,
      0.08284345269203186,
      -0.030096596106886864,
      -0.06216907501220703,
      0.04994269832968712,
      -0.037699390202760696,
      0.021403715014457703,
      -0.058027904480695724,
      -0.030491601675748825,
      -0.03294050320982933,
      -0.061594050377607346,
      -0.046776801347732544,
      0.10047125816345215,
      0.04500069469213486,
      0.07120320945978165,
      -0.06089121848344803,
      -0.06058862432837486,
      0.03068513795733452,
      0.003978940192610025,
      -0.03539559617638588,
      0.030756007879972458,
      0.06706205755472183,
      0.004909873940050602,
      -0.03853810951113701,
      0.030331101268529892,
      -0.13775816559791565,
      -0.07591022551059723,
      -0.056771937757730484,
      0.007100884336978197,
      0.032984260469675064,
      0.006492030806839466,
      -0.018979599699378014,
      0.00796580035239458,
      -0.05602871626615524,
      0.010467929765582085,
      0.006816249806433916,
      0.014782409183681011,
      -0.0274692103266716,
      0.017734002321958542,
      0.017462147399783134,
      0.03221267834305763,
      0.06899254024028778,
      -0.01907419040799141,
      0.10411039739847183,
      -0.061452582478523254,
      0.015363146550953388,
      0.048672229051589966,
      0.0035193299409002066,
      -0.026463020592927933,
      -0.0376681424677372,
      0.019045192748308182,
      -0.04500962048768997,
      0.020947599783539772,
      0.06900421530008316,
      0.06326628476381302,
      0.04115426912903786,
      0.03570881485939026,
      -0.02563796378672123,
      0.015444178134202957,
      0.07660243660211563,
      0.0647234246134758,
      0.03623156622052193,
      -0.0695413276553154,
      0.08191873878240585,
      -0.0313027985394001,
      -0.036111749708652496,
      -0.041013095527887344,
      0.07679783552885056,
      0.04082877188920975,
      0.05412043631076813,
      -0.0013131486484780908,
      -0.02042047493159771,
      -0.060430072247982025,
      -0.002546470146626234,
      -0.018365491181612015,
      0.020098108798265457,
      -0.0728338435292244,
      0.0013256490929052234,
      -0.05370969697833061,
      -0.0027104727923870087,
      -0.046233173459768295,
      -0.011895871721208096,
      -0.013438220135867596,
      -0.03217024356126785,
      -0.022212743759155273,
      -0.01510798092931509,
      -0.004526170436292887,
      0.04574146494269371,
      -0.0015346846776083112,
      0.0051626055501401424,
      -0.007217624690383673,
      -0.003092473838478327,
      0.014041961170732975,
      0.029024243354797363,
      -0.04305846616625786,
      -0.014171808958053589,
      -0.02837872877717018,
      -0.05097924545407295,
      0.003644085256382823,
      -0.022324221208691597,
      -0.0437292605638504,
      0.052742715924978256,
      -0.05796164274215698,
      -0.017527882009744644,
      0.0814824253320694,
      0.018448464572429657,
      0.05512154847383499,
      0.031068872660398483,
      -0.1253449022769928,
      0.001326868194155395,
      0.007839555852115154,
      0.07879866659641266,
      0.048730265349149704,
      -0.08987779915332794,
      -0.0024458738043904305,
      0.035952262580394745,
      0.03562217950820923,
      -0.03179077431559563,
      0.01892661117017269,
      -0.010190927423536777,
      -0.05860220640897751,
      0.021915225312113762,
      -0.06803324818611145,
      -0.0022554469760507345,
      -0.06014849245548248,
      0.023843083530664444,
      0.00598573312163353,
      -0.04139009863138199,
      0.010164041072130203,
      0.04422793164849281,
      -0.049492835998535156,
      -0.08167301118373871,
      -0.003344468306750059,
      -0.05415895953774452,
      -0.061996620148420334,
      0.038283247500658035,
      0.041771791875362396,
      -0.03532414510846138,
      -0.029777606949210167,
      -0.02235633134841919,
      0.007366124540567398,
      0.032166946679353714,
      -0.031584691256284714,
      0.015255472622811794,
      0.06775584071874619,
      -0.07689052820205688,
      0.03903694823384285,
      -0.015174541622400284,
      -0.014364288188517094,
      -0.017089763656258583,
      -0.0022014735732227564,
      -0.058280810713768005,
      -0.04276735335588455,
      0.047955241054296494,
      0.03880668431520462,
      -0.030234839767217636,
      -0.07106994092464447,
      -0.03923148661851883,
      -0.003246672684326768,
      0.008891942910850048,
      -0.07317852228879929,
      0.03990583121776581,
      -0.017728861421346664,
      0.008138217031955719,
      -0.020891651511192322,
      -0.06739302724599838,
      0.11654026061296463,
      -0.007788356859236956,
      0.05079043284058571,
      0.01519505213946104,
      0.04817463830113411,
      0.01837683841586113,
      -0.05991227552294731,
      0.054585665464401245,
      0.01796119101345539,
      0.056615814566612244,
      -0.052724793553352356,
      -0.02854650840163231,
      0.0039992909878492355,
      0.07113248109817505,
      -0.029930589720606804,
      -0.05506698787212372,
      -0.04719735309481621,
      -0.03699702024459839,
      -0.024013152346014977,
      0.04049651324748993,
      0.02556675672531128,
      0.04878963157534599,
      0.003212926909327507,
      0.023339368402957916,
      -0.010028261691331863,
      -0.0757693350315094,
      0.038492415100336075,
      0.05727175995707512,
      -0.025898244231939316,
      0.014627475291490555,
      -0.01992969587445259,
      0.014016172848641872,
      0.020651111379265785,
      -0.02797180972993374,
      -0.10084457695484161,
      0.01878252811729908,
      -0.002754959976300597,
      -0.014113827608525753,
      0.010581396520137787,
      0.03548872843384743,
      0.0001766561035765335,
      -0.05276310443878174,
      -0.026971254497766495,
      0.01322490256279707,
      -0.020299600437283516,
      0.0008474755450151861,
      0.06658581644296646,
      0.05182712897658348,
      0.03187219426035881,
      0.001843813108280301,
      0.021570362150669098,
      0.04014400765299797,
      0.012203691527247429,
      -0.01323790568858385,
      0.06775535643100739,
      -0.026619669049978256,
      -0.008203228004276752,
      -0.049224432557821274,
      0.0063937073573470116,
      -0.012292444705963135,
      -0.012536084279417992,
      -0.012568967416882515,
      -0.040595874190330505,
      -0.04062052443623543,
      0.006913237739354372,
      0.014679177664220333,
      0.005140441469848156,
      -0.03919871151447296,
      0.04121699556708336,
      -0.00937245599925518,
      -0.10264872014522552,
      -0.06891601532697678,
      -0.017123643308877945,
      -0.070600725710392,
      0.0008068719180300832,
      -0.03305123746395111,
      0.06778965145349503,
      0.007070690859109163,
      0.025471553206443787,
      0.024999339133501053,
      0.06866144388914108,
      0.024075210094451904,
      0.023920448496937752,
      0.0566079206764698,
      -0.070186547935009,
      -0.028618276119232178,
      -0.03656425327062607,
      -0.04221521317958832,
      0.08676200360059738,
      -0.0007803788757883012,
      0.03783626854419708,
      0.05292309448122978,
      0.04100603237748146,
      -0.04353269562125206,
      0.0680578351020813,
      -0.0011749950936064124,
      -0.042124077677726746,
      -0.0026032195892184973,
      0.04392336308956146,
      0.004273632075637579,
      -0.022009680047631264,
      -0.017610734328627586,
      0.018323082476854324,
      -0.10515213012695312,
      -0.04319562762975693,
      -0.0463448204100132,
      -0.0544312447309494,
      -0.006734086200594902,
      0.03808951377868652,
      -0.07378832250833511,
      -0.0048404475674033165,
      0.025975599884986877,
      0.1467491239309311,
      -0.015331837348639965,
      -0.011545968241989613,
      -0.017420589923858643,
      -0.02532968297600746,
      0.039610859006643295,
      -0.016270728781819344,
      0.019435154274106026,
      -0.01572577841579914,
      0.0009611705900169909,
      0.028907427564263344,
      -0.01632874645292759,
      0.044550422579050064,
      0.0649997666478157,
      0.08434537053108215,
      0.07704342156648636,
      0.01506890170276165,
      -0.023284895345568657,
      0.07037069648504257,
      -0.051073476672172546,
      -0.048383068293333054,
      -0.05241404101252556,
      -0.03331683576107025,
      0.009045224636793137,
      0.05207569897174835,
      0.01217657420784235,
      0.004649719689041376,
      0.03989000245928764,
      -0.0709630623459816,
      -0.01596817933022976,
      0.04712057113647461,
      -0.005801870487630367,
      -0.07458149641752243,
      -0.09978718310594559,
      -0.0019303306471556425,
      -0.0062721772119402885,
      -0.033200740814208984,
      0.06463322043418884,
      -0.024033162742853165,
      -0.016410496085882187,
      0.027790136635303497,
      -0.02601410262286663,
      -0.0006200023926794529,
      0.06266283988952637,
      -0.014747194945812225,
      0.013687818311154842,
      -0.004657491110265255,
      -0.00955531932413578,
      -0.05291999131441116,
      -0.050404734909534454,
      -0.01596665009856224,
      0.06948264688253403,
      0.00636266078799963,
      0.05924740806221962,
      0.004366715904325247,
      -0.02583898790180683,
      -0.03136730566620827,
      -0.02979586087167263,
      0.03960970416665077,
      -0.015724118798971176,
      -0.0580095611512661,
      0.07710802555084229,
      -0.06573497503995895,
      -0.03406395763158798,
      0.02588074281811714,
      0.06418350338935852,
      0.0014929292956367135,
      -0.002158406190574169,
      0.05703525245189667,
      0.029202409088611603,
      0.04916178807616234,
      0.013735231943428516,
      -0.039173662662506104,
      -0.045188240706920624,
      -0.06621093302965164,
      -0.03300350159406662,
      0.010235102847218513,
      0.007731286343187094,
      -0.09366454184055328,
      -0.01749299466609955,
      -0.00338381202891469,
      0.07761624455451965,
      -0.007617415860295296,
      -0.03993258252739906,
      0.03753993287682533,
      -0.012888406403362751,
      0.010921214707195759,
      0.020830152556300163,
      -0.013600443489849567,
      -0.06223668158054352,
      -0.0015021278522908688,
      0.013140483759343624,
      0.05243656784296036,
      0.03945913165807724,
      -0.05310007184743881,
      0.06753043830394745,
      0.026169192045927048,
      0.07024098187685013,
      0.05994643270969391,
      -0.004867879673838615,
      0.03693317249417305,
      -0.01918679103255272,
      0.01019836962223053,
      0.0020442220848053694,
      0.022769559174776077,
      0.015016977675259113,
      -0.029593586921691895,
      0.038692913949489594
    ],
    [
      0.007021881639957428,
      -0.02581818401813507,
      -0.06810341030359268,
      0.06854858249425888,
      -0.008830748498439789,
      0.017088597640395164,
      -0.012197411619126797,
      -0.002570901531726122,
      0.05960485711693764,
      0.004787986632436514,
      0.006860529538244009,
      -0.07974907755851746,
      -0.017849933356046677,
      0.012876853346824646,
      -0.054497964680194855,
      -0.044011641293764114,
      -0.005074540618807077,
      -0.01589512825012207,
      0.032441310584545135,
      0.00214331760071218,
      -0.029294494539499283,
      0.017086587846279144,
      -0.02437530644237995,
      0.07253342866897583,
      0.03674928471446037,
      -0.059097882360219955,
      -0.005028726067394018,
      -0.00837611872702837,
      0.055285658687353134,
      0.039016272872686386,
      -0.01735183224081993,
      -0.026510758325457573,
      -0.04988928139209747,
      0.07054852694272995,
      -0.001272207242436707,
      0.032274279743433,
      0.04723304137587547,
      -0.012943203561007977,
      -0.0335797481238842,
      0.06541319191455841,
      0.029995260760188103,
      0.01583782769739628,
      -0.10851311683654785,
      0.0630723088979721,
      -0.02009541168808937,
      0.0022038156166672707,
      -0.0481034554541111,
      0.07358316332101822,
      -0.044160809367895126,
      -0.0015588123351335526,
      -0.04578959941864014,
      -0.024007923901081085,
      0.05503668636083603,
      -0.00956758949905634,
      0.022207438945770264,
      -0.07945864647626877,
      0.026981733739376068,
      0.029439356178045273,
      0.001570050255395472,
      0.047877237200737,
      -0.031747810542583466,
      0.06289111822843552,
      -0.04643027111887932,
      0.04275593161582947,
      -0.039945974946022034,
      -0.03218568116426468,
      -0.04877782240509987,
      0.0443783774971962,
      0.07427357882261276,
      -0.013864420354366302,
      -0.06699535250663757,
      -0.0015708442078903317,
      0.030910832807421684,
      -0.05109098553657532,
      -0.09216002374887466,
      0.015893878415226936,
      0.07461938261985779,
      0.02549113519489765,
      0.013406540267169476,
      0.03349386528134346,
      -0.08789122849702835,
      0.098325215280056,
      -0.007629833649843931,
      0.021307140588760376,
      -0.03956056758761406,
      -0.0029918174259364605,
      -0.03719550743699074,
      0.014192517846822739,
      0.013135264627635479,
      -0.025278015062212944,
      0.03353293612599373,
      -0.02633475884795189,
      -0.07670896500349045,
      -0.009492387995123863,
      0.052745070308446884,
      0.04338943585753441,
      -0.08269727230072021,
      0.09316831082105637,
      -0.07339304685592651,
      0.04354653134942055,
      0.029499132186174393,
      0.0268032718449831,
      0.05555793642997742,
      -0.0022085874807089567,
      0.024933813139796257,
      0.021787095814943314,
      0.10388319939374924,
      -0.07331684231758118,
      0.005341694690287113,
      -0.06658299267292023,
      -0.004149802960455418,
      0.018532993271946907,
      0.0601993203163147,
      0.02722049690783024,
      0.015100271441042423,
      0.027192017063498497,
      -0.04005100578069687,
      -0.023651858791708946,
      -0.045660119503736496,
      -0.02360622212290764,
      -0.057458944618701935,
      0.03062651865184307,
      -0.1289292722940445,
      0.0438348613679409,
      0.011534681543707848,
      0.08311348408460617,
      -0.06915327906608582,
      -0.023983102291822433,
      -0.022173747420310974,
      -0.04253242909908295,
      0.03434877097606659,
      -0.025822756811976433,
      -0.04715364798903465,
      0.01585375890135765,
      -0.02451411634683609,
      -0.02029520645737648,
      0.011099087074398994,
      -0.07666311413049698,
      0.04714462533593178,
      -0.005011153873056173,
      0.010255432687699795,
      -0.009663989767432213,
      0.01631716638803482,
      -0.050895579159259796,
      -0.06568274646997452,
      0.04052462428808212,
      0.02798931486904621,
      -0.053263720124959946,
      0.019390208646655083,
      -0.010448404587805271,
      -0.04398161917924881,
      -0.004575500730425119,
      -0.015863442793488503,
      -0.1075965017080307,
      -0.0104381637647748,
      0.057642027735710144,
      -0.0031180954538285732,
      -0.03799661248922348,
      0.02621925249695778,
      0.011349759064614773,
      -0.032495781779289246,
      -0.030999774113297462,
      0.030345609411597252,
      0.015884047374129295,
      0.1191735789179802,
      0.03104424849152565,
      0.042232926934957504,
      0.007986607030034065,
      -0.022803068161010742,
      0.06566930562257767,
      -0.009177073836326599,
      0.09389487653970718,
      0.016993505880236626,
      0.05270887911319733,
      0.04611312970519066,
      0.058897629380226135,
      -0.0874858871102333,
      -0.02107846923172474,
      0.05719519034028053,
      -0.015856655314564705,
      0.026725461706519127,
      0.04668198898434639,
      0.031441621482372284,
      0.0003395050880499184,
      -0.01827993057668209,
      0.06716135889291763,
      0.03205995634198189,
      -0.022140730172395706,
      0.06573991477489471,
      0.07545339316129684,
      -0.00934085063636303,
      -0.04631790146231651,
      0.06990032643079758,
      -0.07435376942157745,
      -0.03832368180155754,
      -0.09650937467813492,
      -0.007869558408856392,
      0.008116208016872406,
      -0.00645120395347476,
      0.025958165526390076,
      0.04685371741652489,
      0.03643006086349487,
      0.03614139184355736,
      0.015704018995165825,
      0.037281379103660583,
      -0.04800312593579292,
      0.04368537664413452,
      -0.043949343264102936,
      -0.06829068064689636,
      -0.00981795322149992,
      0.028213847428560257,
      0.06770835071802139,
      0.018433166667819023,
      -0.017541659995913506,
      0.016121964901685715,
      -0.07576283812522888,
      -0.03486177325248718,
      0.002368429908528924,
      -0.008835350163280964,
      0.03138592839241028,
      -0.07406212389469147,
      -0.008700045756995678,
      -0.02242756076157093,
      -0.007772630080580711,
      -0.025235086679458618,
      0.10093551874160767,
      -0.04129050299525261,
      0.01801023818552494,
      0.010768922045826912,
      0.06308676302433014,
      -0.05215470492839813,
      0.027584604918956757,
      -0.005368855781853199,
      -0.00666388962417841,
      -0.023432442918419838,
      0.008524072356522083,
      0.016088901087641716,
      -0.05242658406496048,
      0.06501144915819168,
      0.00031618610955774784,
      -0.0051040807738900185,
      0.04393947869539261,
      0.0438431054353714,
      -0.019766870886087418,
      0.08845958858728409,
      0.08372694253921509,
      0.019755996763706207,
      0.014628815464675426,
      0.08161255717277527,
      -0.06698073446750641,
      0.020994190126657486,
      0.04645441100001335,
      -0.02147105149924755,
      0.01143400464206934,
      0.029579218477010727,
      0.07882840931415558,
      -0.0065308925695717335,
      -0.026622571051120758,
      -0.015085207298398018,
      -0.06491295248270035,
      -0.02173081412911415,
      -0.006876835133880377,
      -0.019070332869887352,
      -0.016317730769515038,
      0.0012448780471459031,
      0.04691756144165993,
      -0.04364870861172676,
      -0.04198871925473213,
      -0.04559410735964775,
      0.03148311376571655,
      0.0037849850486963987,
      -0.020370233803987503,
      0.00892171822488308,
      0.04453188553452492,
      0.004891746211796999,
      0.04333880916237831,
      0.011562798172235489,
      0.013758276589214802,
      -0.029181519523262978,
      -0.03660571575164795,
      -0.0022509130649268627,
      -0.015608629211783409,
      0.04164127632975578,
      -0.04681064933538437,
      0.04355504736304283,
      0.053750067949295044,
      -0.016542937606573105,
      0.06415698677301407,
      -0.0009059873409569263,
      -0.008558863773941994,
      0.008475077338516712,
      0.09448102116584778,
      0.019984005019068718,
      0.016489779576659203,
      0.031156254932284355,
      -0.012538429349660873,
      -0.011135177686810493,
      0.04071494936943054,
      -0.010509127750992775,
      -0.017792288213968277,
      -0.033370912075042725,
      -0.06513924896717072,
      0.035768117755651474,
      -0.0647631511092186,
      -0.059856168925762177,
      0.0011981992283836007,
      -0.11518127471208572,
      0.020972859114408493,
      0.05287926644086838,
      0.021353166550397873,
      -0.03332613781094551,
      -0.08678194880485535,
      -0.02073562517762184,
      -0.027361201122403145,
      0.05242037773132324,
      0.04906721040606499,
      0.005834327545017004,
      0.09310462325811386,
      -0.017737070098519325,
      -0.03856486827135086,
      -0.05882703885436058,
      0.025361809879541397,
      -0.08045576512813568,
      -0.022342320531606674,
      -0.0158553384244442,
      0.02144533023238182,
      9.018718992592767e-05,
      -0.026062652468681335,
      -0.009834323078393936,
      -0.04025687277317047,
      0.07014456391334534,
      0.061879295855760574,
      -0.015666548162698746,
      -0.049584925174713135,
      -0.0013877091696485877,
      0.03784799948334694,
      0.01968635991215706,
      -0.03914376720786095,
      -0.01422057393938303,
      0.04832301288843155,
      -0.018558528274297714,
      0.04879140108823776,
      -0.03282303363084793,
      0.027553865686058998,
      -0.001815970055758953,
      -0.03302077576518059,
      -0.052529048174619675,
      -0.04082833230495453,
      0.016136394813656807,
      -0.04666200652718544,
      0.0980774462223053,
      -0.03936205804347992,
      0.006972987670451403,
      -0.030050406232476234,
      -0.005349711049348116,
      0.04669342562556267,
      -0.02568962052464485,
      -0.026115944609045982,
      0.014779551886022091,
      0.00787781085819006,
      0.0037080144975334406,
      -0.022724458947777748,
      -0.02255871891975403,
      0.011049865745007992,
      0.019613759592175484,
      -0.02887861058115959,
      0.10318343341350555,
      -0.06701230257749557,
      0.051255498081445694,
      -0.10763000696897507,
      -0.0047552259638905525,
      0.010316905565559864,
      -0.03846755623817444,
      -0.05091162398457527,
      0.028359683230519295,
      -0.015295049175620079,
      0.017575779929757118,
      -0.03474103659391403,
      -0.08841213583946228,
      0.07259049266576767,
      -0.029242565855383873,
      0.03366383910179138,
      -0.026725850999355316,
      0.08295021206140518,
      0.0032453916501253843,
      0.057993657886981964,
      0.0927659198641777,
      -0.029785605147480965,
      0.009635457769036293,
      -0.06314967572689056,
      -0.04464667662978172,
      -0.034003086388111115,
      0.02850918285548687,
      0.012444084510207176,
      0.012074461206793785,
      0.0686073899269104,
      0.07535097002983093,
      0.11525321751832962,
      -0.01601879484951496,
      -0.03200008347630501,
      0.0025612888857722282,
      0.0027067777700722218,
      0.04181312024593353,
      0.08623445779085159,
      0.018671376630663872,
      0.007403973489999771,
      0.04584392160177231,
      -0.011575823649764061,
      0.06354110687971115,
      0.061770614236593246,
      0.048690177500247955,
      -0.053734250366687775,
      0.012501268647611141,
      0.005699429661035538,
      -0.07091370224952698,
      0.03328608721494675,
      0.015454773791134357,
      -0.039196111261844635,
      0.07333095371723175,
      -0.07893945276737213,
      0.02273940108716488,
      -3.1294170184992254e-05,
      0.038137927651405334,
      0.0291572418063879,
      -0.0009494944824837148,
      -0.02932598814368248,
      -0.0431683287024498,
      0.025447554886341095,
      0.04829265549778938,
      -0.019217103719711304,
      -0.026695726439356804,
      -0.036133427172899246,
      0.018774740397930145,
      0.04108636453747749,
      0.015540492720901966,
      -0.03525190055370331,
      0.031364504247903824,
      -0.022772809490561485,
      -0.010499665513634682,
      0.055973440408706665,
      0.07151975482702255,
      -0.007449937053024769,
      0.020765438675880432,
      -0.04929235205054283,
      -0.0639033317565918,
      0.008008149452507496,
      0.05991658195853233,
      -0.07709093391895294,
      -0.028166262432932854,
      0.007990374229848385,
      -0.05648208037018776,
      0.015566477552056313,
      0.03800428286194801,
      -0.006553232669830322,
      -0.03144334629178047,
      -0.003978468477725983,
      0.04346093907952309,
      -0.02218126878142357,
      -0.02599889039993286,
      0.02821372076869011,
      0.041307661682367325,
      0.06050007790327072,
      -0.011082332581281662,
      0.010635381564497948,
      0.08360575884580612,
      -0.03524811565876007,
      -0.031611718237400055,
      -0.04093935713171959,
      0.07981051504611969,
      -0.014352887868881226,
      -0.03609404340386391,
      0.004735833965241909,
      -0.06919518858194351,
      0.024202557280659676,
      0.0050343400798738,
      0.04891888052225113,
      0.007403858006000519,
      0.05759560689330101,
      -0.0535753108561039,
      0.06156981363892555,
      -0.025362815707921982,
      -0.11053384095430374,
      -0.020804274827241898,
      0.04155885428190231,
      -0.01444960292428732,
      -0.03311634436249733,
      -0.02954763174057007,
      0.00394626846536994,
      -0.017262475565075874,
      -0.034954629838466644,
      -0.07108181715011597,
      0.044248223304748535,
      0.019551726058125496,
      -0.044962167739868164,
      0.018898924812674522,
      0.008224403485655785,
      0.007878581061959267,
      -0.032665837556123734,
      0.03847932815551758,
      0.03804356977343559,
      0.08694450557231903,
      0.004062728490680456,
      -0.04752131551504135
    ],
    [
      0.035377323627471924,
      -0.05342589318752289,
      0.07625409215688705,
      0.025935815647244453,
      -0.0044703250750899315,
      -0.0023071623872965574,
      -0.03340795636177063,
      -0.006651693023741245,
      -0.04888917878270149,
      -0.07504179328680038,
      -0.024857686832547188,
      -0.052468400448560715,
      -0.028431516140699387,
      0.00463933777064085,
      0.0011061426484957337,
      0.03711606189608574,
      -0.026357406750321388,
      -0.031127342954277992,
      -0.01857137307524681,
      -0.011919847689568996,
      -0.04004442319273949,
      0.006053912453353405,
      -0.02795104682445526,
      -0.035215336829423904,
      0.027957286685705185,
      -0.07657921314239502,
      -0.025326477363705635,
      0.02176816761493683,
      -0.08833911269903183,
      -0.0039094663225114346,
      0.019043559208512306,
      -0.03212491795420647,
      -0.0159672349691391,
      0.0036256187595427036,
      -0.020247705280780792,
      0.051037415862083435,
      -0.009172454476356506,
      -0.01913130097091198,
      0.047315940260887146,
      -0.029817314818501472,
      0.008467314764857292,
      0.0014498173259198666,
      0.01183716207742691,
      -0.011468542739748955,
      -0.08238590508699417,
      -0.005663743708282709,
      -0.05640992894768715,
      0.07310818880796432,
      0.025551585480570793,
      -0.014250014908611774,
      0.06229173019528389,
      0.03800717368721962,
      -0.01853293552994728,
      0.0607282854616642,
      0.04675939679145813,
      0.03987560048699379,
      0.008313535712659359,
      -0.01463812030851841,
      0.054253220558166504,
      -0.029100172221660614,
      -0.027997879311442375,
      0.044604863971471786,
      0.0036409657914191484,
      0.04124728962779045,
      0.01582874357700348,
      0.036296818405389786,
      0.04282679036259651,
      -0.001411776407621801,
      -0.02099253237247467,
      -0.09460142254829407,
      -0.05916424095630646,
      -0.021624155342578888,
      0.05502304062247276,
      0.01709156110882759,
      -0.0494813397526741,
      -0.09894239157438278,
      0.10349652916193008,
      0.02643945813179016,
      0.017422258853912354,
      0.02426876686513424,
      0.008115740492939949,
      -0.018135903403162956,
      0.07519850134849548,
      -0.044374462217092514,
      -0.05881612375378609,
      -0.04848368838429451,
      0.0776524618268013,
      0.11639238148927689,
      0.04806399717926979,
      0.00640109134837985,
      -0.03857666254043579,
      0.014754393137991428,
      -0.022786200046539307,
      -0.02484614960849285,
      0.005739651620388031,
      0.004673542454838753,
      -0.044930875301361084,
      -0.008918793871998787,
      0.022221332415938377,
      0.0036819984670728445,
      -0.025639431551098824,
      0.03984470292925835,
      -0.017997169867157936,
      -0.03617721050977707,
      -0.002924267901107669,
      0.0019973567686975002,
      -0.06415800005197525,
      -0.057581834495067596,
      0.12479379028081894,
      -0.006860106717795134,
      0.01913285069167614,
      0.021796364337205887,
      -0.06038864329457283,
      0.06472038477659225,
      0.014930118806660175,
      0.04071472957730293,
      0.028978267684578896,
      0.013669416308403015,
      0.005251535680145025,
      -0.014448204077780247,
      0.008759102784097195,
      0.04995354637503624,
      -0.047209206968545914,
      -0.012697916477918625,
      0.016723737120628357,
      -0.025321586057543755,
      -0.00397196551784873,
      -0.054746270179748535,
      -0.04258507490158081,
      -0.0028544387314468622,
      0.03776220604777336,
      0.04396717995405197,
      -0.03265367075800896,
      0.005882021505385637,
      -0.0766131803393364,
      -0.01404181495308876,
      0.045163594186306,
      0.029023006558418274,
      -0.015429018996655941,
      -0.027130987495183945,
      0.04013633728027344,
      0.05273127555847168,
      0.005343467928469181,
      0.008013182319700718,
      -0.015279053710401058,
      -0.08034124970436096,
      0.0650540217757225,
      0.007854465395212173,
      0.0016062578652054071,
      -0.013432164676487446,
      0.05757918581366539,
      0.024536365643143654,
      0.018995007500052452,
      0.03750772029161453,
      0.04753457382321358,
      0.02679075114428997,
      -0.0329887755215168,
      -0.0014851903542876244,
      0.11627121269702911,
      0.02339024282991886,
      -0.021851247176527977,
      0.03682330623269081,
      0.049098700284957886,
      -0.0327761285007,
      -0.01616710051894188,
      -0.04677144065499306,
      0.030446084216237068,
      0.008402983658015728,
      0.03103451244533062,
      -0.012724227271974087,
      -0.0435926616191864,
      0.004268605262041092,
      -0.012084522284567356,
      -0.0704176127910614,
      0.012185878120362759,
      0.110255666077137,
      0.08118831366300583,
      -0.025415079668164253,
      0.011904416605830193,
      -0.03955652564764023,
      -0.0011968231992796063,
      -0.0685817077755928,
      0.009841934777796268,
      -0.04556228965520859,
      -0.032079849392175674,
      -0.06490162760019302,
      0.027875162661075592,
      -0.021330222487449646,
      0.02194884791970253,
      0.04846521094441414,
      0.002048868453130126,
      0.008993388153612614,
      -0.0038426280952990055,
      -0.02972634881734848,
      0.08699110895395279,
      -0.008340276777744293,
      -0.0450824536383152,
      0.0117902597412467,
      0.05266518518328667,
      0.04747168347239494,
      -0.0881415456533432,
      0.08397333323955536,
      0.0465325303375721,
      0.0445144884288311,
      -0.027674011886119843,
      0.0018432815559208393,
      0.03109949640929699,
      -0.00975838303565979,
      -0.03186541795730591,
      0.039172012358903885,
      0.04129844158887863,
      -0.04825074225664139,
      -0.10688535124063492,
      -0.0462317019701004,
      0.03450046107172966,
      0.0427645668387413,
      0.01074147503823042,
      -0.0029559738468378782,
      -0.03579602763056755,
      -0.0033057010732591152,
      -0.02095717377960682,
      0.00039284722879529,
      0.009458483196794987,
      -0.022424910217523575,
      -0.029205521568655968,
      0.03200443461537361,
      0.016248391941189766,
      0.01819467544555664,
      -0.04722665250301361,
      -0.04425733536481857,
      -0.04077167809009552,
      -0.04012046754360199,
      -0.005271542817354202,
      -0.008470656350255013,
      0.05587848648428917,
      0.0005942147690802813,
      0.011562411673367023,
      0.0577034056186676,
      -0.01653313636779785,
      -0.0620020292699337,
      0.10676000267267227,
      -0.040737804025411606,
      0.04527926445007324,
      0.031015394255518913,
      0.035558994859457016,
      -0.021627547219395638,
      0.03670875355601311,
      -0.07894006371498108,
      0.09117019176483154,
      0.08006662130355835,
      -0.022464986890554428,
      0.005917418748140335,
      0.0001283463934669271,
      -0.12213325500488281,
      0.024754764512181282,
      0.04059617221355438,
      -0.005044169258326292,
      0.020490968599915504,
      0.016419002786278725,
      0.019447406753897667,
      -0.009167380630970001,
      0.008970645256340504,
      0.0854077860713005,
      -0.00010380980529589579,
      0.027710678055882454,
      -0.07955808192491531,
      -0.07975759357213974,
      0.024843372404575348,
      0.029737362638115883,
      -0.05540355294942856,
      0.029083050787448883,
      -0.08951089531183243,
      0.015804409980773926,
      -0.003720573615282774,
      -0.035129617899656296,
      0.05154711753129959,
      0.028875252231955528,
      0.0324053019285202,
      -0.031124481931328773,
      -0.009097985923290253,
      -0.01899539865553379,
      -0.054863013327121735,
      0.024285241961479187,
      -0.07009588181972504,
      -0.016103800386190414,
      0.03809376806020737,
      -0.07223592698574066,
      -0.03252324461936951,
      -0.0885336622595787,
      0.009057090617716312,
      -0.018828416243195534,
      0.08777448534965515,
      0.02696944586932659,
      -0.007316451985388994,
      -0.08088380098342896,
      0.05978976935148239,
      -0.05745507776737213,
      -0.040677815675735474,
      -0.0038439768832176924,
      0.043067775666713715,
      0.002056240104138851,
      0.029231393709778786,
      0.046046026051044464,
      0.03529861941933632,
      -0.1315830796957016,
      0.03817101567983627,
      -0.03787707909941673,
      -0.028297625482082367,
      -0.0750429630279541,
      -0.053470488637685776,
      0.08097881823778152,
      0.021472696214914322,
      -0.008608710952103138,
      -0.00821752194315195,
      0.01579226925969124,
      0.034723520278930664,
      -0.0210580974817276,
      0.014764943160116673,
      -0.01842627488076687,
      0.026050880551338196,
      0.01207078155130148,
      -0.0511452741920948,
      -0.04603179171681404,
      0.005763054825365543,
      0.06510352343320847,
      0.09923282265663147,
      0.09594694525003433,
      -0.016172019764780998,
      -0.030890703201293945,
      0.06924237310886383,
      -0.025457333773374557,
      -0.019176894798874855,
      0.0037837850395590067,
      -0.04760307818651199,
      0.0371248759329319,
      0.029305001720786095,
      0.020381560549139977,
      0.006763609126210213,
      -0.051451425999403,
      -0.010901806876063347,
      0.033739857375621796,
      -0.06870663911104202,
      0.0363386906683445,
      0.013828304596245289,
      -0.04038849472999573,
      0.11009977012872696,
      -0.012172210961580276,
      -0.04097020998597145,
      0.06544698029756546,
      -0.026353389024734497,
      0.07657087594270706,
      0.03038267232477665,
      0.04225359484553337,
      -0.021412894129753113,
      -0.01788453944027424,
      0.04499143734574318,
      -0.05509110912680626,
      -0.049550238996744156,
      0.02633795700967312,
      0.05732494965195656,
      -0.060455307364463806,
      0.013741394504904747,
      -0.03296779841184616,
      -0.014597753062844276,
      0.03197817504405975,
      -0.02440868690609932,
      -0.07275836914777756,
      0.021573591977357864,
      -0.042119745165109634,
      0.009021706879138947,
      0.021319754421710968,
      0.0295396838337183,
      0.04491100832819939,
      -0.006768656894564629,
      -0.01874520257115364,
      0.07609504461288452,
      0.04385341703891754,
      0.031731605529785156,
      -0.033896688371896744,
      0.02134763076901436,
      -0.0492381826043129,
      0.057199351489543915,
      0.07611039280891418,
      0.015573866665363312,
      -0.00521704088896513,
      0.0016917805187404156,
      -0.03595079481601715,
      0.010576100088655949,
      0.026273425668478012,
      0.07233478128910065,
      -0.019825635477900505,
      0.06252042204141617,
      0.07502703368663788,
      0.03856142610311508,
      -0.05173437297344208,
      -0.011444550938904285,
      0.02822762168943882,
      -0.05522909015417099,
      0.07936081290245056,
      -0.049560546875,
      0.009527198038995266,
      0.07782934606075287,
      -0.03717091307044029,
      0.049329858273267746,
      -0.09825213253498077,
      -0.010448715649545193,
      0.04713977500796318,
      0.04664123058319092,
      0.02168191596865654,
      0.037892382591962814,
      -0.003892461769282818,
      0.011947782710194588,
      -0.04108278080821037,
      -0.0148383229970932,
      -0.03160707280039787,
      0.04344991594552994,
      -0.04008154571056366,
      -0.08196063339710236,
      0.015282758511602879,
      -0.01394887175410986,
      -0.041265372186899185,
      0.003618401475250721,
      0.06603171676397324,
      -0.07419274747371674,
      -0.06816955655813217,
      -0.054481860250234604,
      -0.009625675156712532,
      0.0012301388196647167,
      -0.06781767308712006,
      -0.006923915818333626,
      0.016870884224772453,
      0.026233095675706863,
      0.003218891331925988,
      0.01663685403764248,
      0.008169959299266338,
      -0.04129307344555855,
      -0.03687842935323715,
      -0.04373762756586075,
      0.05527276173233986,
      0.01306175533682108,
      -0.014885899610817432,
      -0.002154198009520769,
      -0.01534775085747242,
      -0.006918321829289198,
      -0.026479875668883324,
      -0.0003806344757322222,
      0.062438830733299255,
      0.06420091539621353,
      -0.06929941475391388,
      0.0008081528940238059,
      0.003265530802309513,
      -0.01886751502752304,
      0.045655757188797,
      0.052226096391677856,
      -0.13020572066307068,
      -0.003159761894494295,
      0.008448757231235504,
      -0.0009042223682627082,
      0.013797454535961151,
      -0.004436894319951534,
      0.0013870238326489925,
      0.026099111884832382,
      -0.06529498845338821,
      -0.014570263214409351,
      0.05646895244717598,
      0.009578878991305828,
      -0.09346696734428406,
      0.07962597161531448,
      -0.007845474407076836,
      -0.07659043371677399,
      0.07450567930936813,
      0.0038698434364050627,
      -0.06132993847131729,
      -0.010036353021860123,
      0.08680032938718796,
      -0.07516983896493912,
      0.10153824090957642,
      0.0383131206035614,
      -0.06015072762966156,
      0.03155152499675751,
      0.009565905667841434,
      -0.018516549840569496,
      -0.00620796624571085,
      -0.04870825260877609,
      -0.008213336579501629,
      0.011191844008862972,
      0.058441583067178726,
      -0.003587628249078989,
      -0.02238733321428299,
      0.10540945082902908,
      -0.058300793170928955,
      0.02125748060643673,
      -0.10441003739833832,
      0.018488259986042976,
      0.01277027279138565,
      -0.04610126465559006,
      -0.06665249913930893,
      -0.06792520731687546,
      0.06869502365589142,
      0.09495839476585388,
      0.003125746501609683,
      0.018654966726899147,
      0.05212848633527756
    ],
    [
      -0.005323503632098436,
      0.03360975533723831,
      -0.024035785347223282,
      -0.01799052767455578,
      -0.01643264852464199,
      -0.0010379351442679763,
      -0.066315658390522,
      -0.0002774861641228199,
      -0.021821903064846992,
      -0.05871745944023132,
      -0.010688968934118748,
      -0.02460194192826748,
      0.0037986489478498697,
      0.06905544549226761,
      -0.033815719187259674,
      0.06843052059412003,
      0.026471538469195366,
      0.048823412507772446,
      0.01574726402759552,
      -0.042087726294994354,
      0.003543698461726308,
      -0.0989333912730217,
      -0.0518227219581604,
      -0.07806376367807388,
      0.03770871460437775,
      0.0009430331992916763,
      -0.004616012796759605,
      -0.000527200463693589,
      -0.06798309832811356,
      -0.04990881681442261,
      -0.06454295665025711,
      0.06244051456451416,
      -0.02767440304160118,
      -0.028627321124076843,
      0.005246585700660944,
      -0.10670040547847748,
      -0.0037213030736893415,
      0.04122750088572502,
      0.013921024277806282,
      0.037942759692668915,
      -0.02421286702156067,
      0.010855959728360176,
      0.056106775999069214,
      -0.016445092856884003,
      -0.0009584659710526466,
      0.03371917083859444,
      -0.008892820216715336,
      -0.048475511372089386,
      -0.06908655166625977,
      -0.03988102450966835,
      -0.062044255435466766,
      -0.02590183913707733,
      -0.02769245021045208,
      0.09279277920722961,
      0.02463238500058651,
      -0.06185489520430565,
      -0.0009612946305423975,
      0.08222087472677231,
      -0.028943656012415886,
      -0.020298196002840996,
      0.006435060407966375,
      -0.09209982305765152,
      0.03754983842372894,
      0.06464244425296783,
      -0.05673830211162567,
      0.07154262810945511,
      0.04078095033764839,
      0.09358065575361252,
      -0.012090135365724564,
      0.10498382896184921,
      0.017379125580191612,
      -0.031006945297122,
      -0.07373673468828201,
      0.005755616817623377,
      -0.09665343165397644,
      0.025057585909962654,
      0.04549883306026459,
      0.005334821995347738,
      -0.07091087847948074,
      0.03108150139451027,
      -0.07848594337701797,
      -0.030239351093769073,
      0.08565738797187805,
      0.031787604093551636,
      0.025435347110033035,
      -0.02466425858438015,
      0.020122908055782318,
      0.07915419340133667,
      -0.017081405967473984,
      -0.04098518192768097,
      -0.026177162304520607,
      0.014317765831947327,
      -0.047428157180547714,
      -0.04884149879217148,
      -0.024123793467879295,
      -0.1279941350221634,
      0.0005216380232013762,
      -0.04808511212468147,
      0.032237712293863297,
      0.04446111619472504,
      0.01015625149011612,
      0.021919729188084602,
      0.06312813609838486,
      -0.02266373485326767,
      -0.022079408168792725,
      -0.02397492341697216,
      0.11250539124011993,
      -0.020463405176997185,
      -0.014755056239664555,
      -0.02065451815724373,
      0.04552297294139862,
      0.037719011306762695,
      -0.06778585910797119,
      -0.03358766436576843,
      -0.057266611605882645,
      -0.13617990911006927,
      0.01732907071709633,
      -0.06851426512002945,
      -0.08433730900287628,
      -0.04976995661854744,
      -0.020315665751695633,
      -0.09006662666797638,
      -0.011144636198878288,
      0.035575661808252335,
      0.053647320717573166,
      -0.058522433042526245,
      0.0016525404062122107,
      -0.005026662722229958,
      -0.048175256699323654,
      0.011480042710900307,
      0.0010453460272401571,
      -0.005854375194758177,
      -0.024072447791695595,
      0.06667976826429367,
      -0.03627053648233414,
      -0.008704440668225288,
      0.030391177162528038,
      0.0075781322084367275,
      0.04428055137395859,
      0.028405655175447464,
      -0.023564662784337997,
      0.031720757484436035,
      0.024045230820775032,
      -0.004612781573086977,
      0.035705238580703735,
      0.026773273944854736,
      0.021520614624023438,
      0.047657933086156845,
      -0.009259244427084923,
      0.0025879438035190105,
      0.010632691904902458,
      0.0018623912474140525,
      0.018844658508896828,
      -0.024419547989964485,
      -0.00873505137860775,
      -0.033596765249967575,
      -0.029231416061520576,
      0.0031800155993551016,
      -0.007759965490549803,
      0.036804087460041046,
      -0.021947145462036133,
      -0.0018291845917701721,
      -0.08007413893938065,
      0.03125975281000137,
      0.017171980813145638,
      0.01379094086587429,
      -0.002379855141043663,
      0.0051555875688791275,
      0.04398132860660553,
      0.08011272549629211,
      0.031813789159059525,
      -0.010217255912721157,
      0.02316855452954769,
      0.05031454935669899,
      -0.12357628345489502,
      0.03562598675489426,
      -0.054353974759578705,
      0.010411014780402184,
      -0.07167929410934448,
      0.0053714485839009285,
      0.004082811065018177,
      -0.05095070227980614,
      0.018231792375445366,
      -0.003951941151171923,
      -0.09430117905139923,
      0.02353392355144024,
      -0.020887868478894234,
      0.051957570016384125,
      -0.007891100831329823,
      -0.05451778694987297,
      -0.012339468114078045,
      -0.0015360454563051462,
      0.08223732560873032,
      0.06269171088933945,
      -0.00010942168592009693,
      -0.028046468272805214,
      -0.05983814597129822,
      -0.020655270665884018,
      0.01576114259660244,
      0.007001189980655909,
      0.06792502105236053,
      -0.026689670979976654,
      -0.03367982432246208,
      0.14093270897865295,
      -0.03610990196466446,
      -0.013903261162340641,
      0.0442969836294651,
      -0.006110797636210918,
      0.02681177295744419,
      -0.033068593591451645,
      -0.03186514973640442,
      -0.012251360341906548,
      -0.0735393837094307,
      0.06366327404975891,
      -0.004293803591281176,
      0.07106142491102219,
      0.13531804084777832,
      -0.06350801885128021,
      0.03746875748038292,
      0.03166697546839714,
      0.01737155206501484,
      -0.00974725466221571,
      0.009155488573014736,
      0.0013765551848337054,
      0.031448740512132645,
      -0.030329734086990356,
      -0.012567562982439995,
      -0.038064610213041306,
      -0.050759296864271164,
      0.02467862144112587,
      0.014822508208453655,
      -0.02832218073308468,
      0.07729308307170868,
      -0.018939878791570663,
      0.011918021366000175,
      0.041676316410303116,
      0.024497272446751595,
      -0.03188960254192352,
      -0.01343698613345623,
      0.04412764310836792,
      0.0014227597275748849,
      -0.06884266436100006,
      0.017864864319562912,
      -0.03930273279547691,
      0.007237392943352461,
      -0.07117680460214615,
      -0.043499987572431564,
      -0.0022243759594857693,
      0.014658135361969471,
      0.036435820162296295,
      -0.023342840373516083,
      -0.025575945153832436,
      -0.014687241055071354,
      0.05106960982084274,
      0.005749216303229332,
      0.03489620238542557,
      -0.04499794915318489,
      0.016348334029316902,
      -0.017251942306756973,
      -0.10681566596031189,
      -0.006047933828085661,
      -0.045243989676237106,
      0.02050192654132843,
      -0.03895272687077522,
      -0.018948378041386604,
      0.020562227815389633,
      -0.04768087714910507,
      -0.07098077982664108,
      -0.0323416143655777,
      0.007179766893386841,
      -0.009123134426772594,
      -0.022002961486577988,
      0.004239373840391636,
      -0.02701772376894951,
      0.0022259410470724106,
      0.008444714359939098,
      -0.0113224433735013,
      0.05247706174850464,
      -0.05483466759324074,
      0.018756575882434845,
      0.01990632712841034,
      -0.031957708299160004,
      -0.0776822417974472,
      -0.02831004187464714,
      0.06841865181922913,
      0.06351244449615479,
      -0.012849168851971626,
      -0.04635268449783325,
      0.02541978657245636,
      0.02628541924059391,
      0.05692092329263687,
      -0.04767361283302307,
      0.027646588161587715,
      0.03622989356517792,
      -0.05271550267934799,
      -0.019210288301110268,
      -0.05272487923502922,
      0.06115194410085678,
      0.08150840550661087,
      0.014024259522557259,
      0.016819335520267487,
      0.03819902613759041,
      0.0467088520526886,
      0.08143772184848785,
      -0.016575580462813377,
      0.04925346001982689,
      -0.03724288195371628,
      0.02641650103032589,
      0.011071087792515755,
      0.01033110823482275,
      0.0324108861386776,
      0.025545170530676842,
      -0.029473960399627686,
      -0.09607836604118347,
      0.017297912389039993,
      -0.009226905182003975,
      0.00792473554611206,
      -0.07368608564138412,
      -0.03182399272918701,
      0.0459427572786808,
      0.04376748949289322,
      -0.018629934638738632,
      0.015042800456285477,
      -0.04002813994884491,
      0.057465508580207825,
      0.04859858378767967,
      -0.008825055323541164,
      0.08072791248559952,
      -0.06717720627784729,
      -0.034909866750240326,
      -0.017863865941762924,
      0.008749094791710377,
      0.04249586537480354,
      0.029032021760940552,
      -0.02504623681306839,
      0.07198886573314667,
      -0.018254250288009644,
      -0.0049813613295555115,
      -0.02533029578626156,
      -0.01197250746190548,
      0.04192878305912018,
      0.0076997061260044575,
      -0.01089477725327015,
      0.015345390886068344,
      -0.060765303671360016,
      0.002181476913392544,
      0.051835011690855026,
      -0.0346728153526783,
      -0.02735150419175625,
      -0.06728831678628922,
      0.04354541748762131,
      -0.038259781897068024,
      -0.025106074288487434,
      -0.08176086097955704,
      0.019959228113293648,
      -0.00625633355230093,
      -0.05111241713166237,
      -0.06443294137716293,
      0.010409397073090076,
      0.06911536306142807,
      -0.022461146116256714,
      -0.020599255338311195,
      0.03490002825856209,
      0.06861517578363419,
      -0.0012515870621427894,
      -0.057820096611976624,
      0.010877266526222229,
      0.015453924424946308,
      0.01745963841676712,
      -0.07356743514537811,
      0.03462563455104828,
      0.0015062586171552539,
      -0.0032037037890404463,
      0.003176161553710699,
      -0.04118959978222847,
      -0.02951398864388466,
      0.01067774835973978,
      0.0021823809947818518,
      -0.010301755741238594,
      -0.09474635869264603,
      0.03168153017759323,
      0.05650616064667702,
      0.04467424750328064,
      0.06764853000640869,
      0.03280331566929817,
      0.0017334475414827466,
      0.0239573884755373,
      0.03883430361747742,
      0.003254855750128627,
      0.0027993947733193636,
      -0.05762770026922226,
      0.006680699996650219,
      -0.11228432506322861,
      0.022957930341362953,
      -0.05292937904596329,
      0.06683399528265,
      -0.04241347685456276,
      -0.03506771847605705,
      -0.09987136721611023,
      0.014316783286631107,
      -0.0036980416625738144,
      -0.032928504049777985,
      0.030997566878795624,
      -0.011223740875720978,
      0.03497060015797615,
      0.011792395263910294,
      -0.0189528726041317,
      -0.003826273139566183,
      0.039633430540561676,
      0.027766751125454903,
      0.04141324758529663,
      0.01116302888840437,
      0.017387811094522476,
      -0.035925544798374176,
      0.012094024568796158,
      0.06541243940591812,
      -0.03467150032520294,
      0.0019562491215765476,
      -0.011609447188675404,
      0.016432669013738632,
      0.004680352751165628,
      0.036017999053001404,
      0.02711266465485096,
      0.032667264342308044,
      0.03565124794840813,
      0.09774480760097504,
      0.04037683457136154,
      -0.013106913305819035,
      0.06607036292552948,
      0.005785332527011633,
      -0.060255974531173706,
      -0.018857894465327263,
      -0.09590510278940201,
      0.01004533190280199,
      -0.024302182719111443,
      -0.03541677072644234,
      0.04188508912920952,
      -0.02289639227092266,
      0.03576333820819855,
      -0.015427041798830032,
      -0.006204074248671532,
      -0.02970188483595848,
      -0.12795203924179077,
      -0.05061506852507591,
      -0.047557756304740906,
      -0.00343247689306736,
      0.016066376119852066,
      -0.10020653158426285,
      -0.0522887222468853,
      -0.018858712166547775,
      -0.08817422389984131,
      -0.04359256476163864,
      -0.0024920860305428505,
      -0.022019365802407265,
      0.01737319305539131,
      -0.06585856527090073,
      -0.005380346905440092,
      0.04358048737049103,
      -0.03587636351585388,
      0.02953273057937622,
      0.017424266785383224,
      0.062228403985500336,
      -0.010866955853998661,
      -0.016062479466199875,
      0.0019008322851732373,
      -0.04076608642935753,
      0.0754978135228157,
      0.005519547965377569,
      -0.0076176878064870834,
      -0.008377116173505783,
      -0.02063603326678276,
      -0.01686246693134308,
      -0.03770456835627556,
      -0.02127441018819809,
      0.0005198383005335927,
      -0.041749678552150726,
      0.007828354835510254,
      -0.0016581390518695116,
      -0.04270397871732712,
      0.026821574196219444,
      0.037889525294303894,
      -0.012897025793790817,
      -0.019573334604501724,
      0.007945545949041843,
      0.013551078736782074,
      0.05283937230706215,
      0.001871821703389287,
      0.003638782072812319,
      -0.010933304205536842,
      0.019107332453131676,
      0.039486903697252274,
      0.02051657810807228,
      0.016191985458135605,
      0.00922871008515358,
      -0.01935352012515068,
      -0.018795305863022804,
      0.023479633033275604,
      0.02357606031000614,
      -0.0319817028939724,
      -0.029702546074986458,
      -0.024088185280561447,
      -0.0617070235311985,
      -0.025176122784614563
    ],
    [
      -0.009197777137160301,
      -0.02106151357293129,
      -0.004971630405634642,
      -0.02832111530005932,
      0.0087057426571846,
      -0.04075093939900398,
      -0.04990856349468231,
      0.01235363632440567,
      -0.05352887883782387,
      -0.03439886495471001,
      -0.016208630055189133,
      0.045281436294317245,
      -0.04430201277136803,
      0.03564156964421272,
      0.06953397393226624,
      -0.04388401284813881,
      0.07026312500238419,
      0.00464661093428731,
      -0.060613978654146194,
      0.04224250093102455,
      -0.010389385744929314,
      0.003481995314359665,
      0.03630535304546356,
      -0.003292095148935914,
      -0.03636334091424942,
      -0.06949759274721146,
      0.015213065780699253,
      9.631126886233687e-05,
      0.031243592500686646,
      0.026504699140787125,
      -0.0034878936130553484,
      -0.01990969106554985,
      0.02384747937321663,
      -0.09114841371774673,
      0.044133033603429794,
      -0.04770098626613617,
      -0.008701270446181297,
      -0.04895208030939102,
      0.05613136664032936,
      -0.02691463753581047,
      -0.07510123401880264,
      -0.06783133745193481,
      -0.01909642294049263,
      0.06263968348503113,
      -0.0035913113970309496,
      0.01875707134604454,
      0.017437104135751724,
      -0.05577433481812477,
      0.03600320219993591,
      -0.06543242931365967,
      0.0019757479894906282,
      -0.04776255041360855,
      -0.03724553436040878,
      -0.014800803735852242,
      -0.05674968287348747,
      -0.10934080183506012,
      -0.04506761580705643,
      0.038584545254707336,
      0.025468284264206886,
      0.004109960049390793,
      0.017476556822657585,
      -0.03829510882496834,
      0.11551403254270554,
      0.02423548884689808,
      -0.007715067360550165,
      0.044557925313711166,
      -0.030130157247185707,
      0.027863727882504463,
      -0.020370550453662872,
      0.08732544630765915,
      0.0699796974658966,
      -0.033850088715553284,
      0.00022057931346353143,
      0.015873748809099197,
      0.04133087024092674,
      -0.06792724877595901,
      -0.00795820727944374,
      -0.04725009575486183,
      -0.05616847053170204,
      0.07334620505571365,
      0.032114624977111816,
      -0.0005723441718146205,
      -0.029343347996473312,
      -0.043035443872213364,
      0.024942781776189804,
      0.02214106172323227,
      -0.04181436076760292,
      0.014387139119207859,
      -0.08256444334983826,
      0.0051316311582922935,
      0.04841785505414009,
      -0.007105011492967606,
      0.06014157459139824,
      -0.017260311171412468,
      0.08077424764633179,
      -0.0018711683806031942,
      0.05099357292056084,
      -0.03063168376684189,
      -0.048213545233011246,
      -0.0069460938684642315,
      0.003892523469403386,
      0.06263371556997299,
      -0.007011925335973501,
      0.03945242986083031,
      -0.024110296741127968,
      -0.08930519968271255,
      0.002029369818046689,
      -0.03640922158956528,
      -0.047563519328832626,
      -0.059210970997810364,
      -0.004413353279232979,
      0.01685747317969799,
      0.025142524391412735,
      -0.05746794119477272,
      -0.030653540045022964,
      0.028122149407863617,
      0.006782182026654482,
      -0.00983458198606968,
      0.01575566828250885,
      0.04941696673631668,
      -0.03349625691771507,
      -0.057813432067632675,
      0.039914485067129135,
      -0.025239339098334312,
      -0.044944021850824356,
      0.03810926526784897,
      0.01337977685034275,
      -0.01851348765194416,
      0.026932138949632645,
      -0.012881668284535408,
      0.010106544941663742,
      0.007549603004008532,
      -0.01018656138330698,
      0.015194234438240528,
      0.03931348770856857,
      0.03338062763214111,
      -0.009080806747078896,
      -0.0315445214509964,
      0.018391286954283714,
      0.03252645581960678,
      0.01888880506157875,
      -0.03743252158164978,
      -0.009212399832904339,
      6.697148637613282e-05,
      0.06356163322925568,
      -0.014157467521727085,
      -0.003480445360764861,
      -0.05037840083241463,
      0.01932281255722046,
      0.030252642929553986,
      0.007764526177197695,
      -0.07942410558462143,
      0.02411665953695774,
      0.028448231518268585,
      0.01918714866042137,
      0.024890661239624023,
      0.036740727722644806,
      0.05399635061621666,
      -0.0166977159678936,
      0.01434629037976265,
      -0.013228103518486023,
      0.014390362426638603,
      0.04433136060833931,
      -0.07126455754041672,
      0.024975838139653206,
      0.009015698917210102,
      -0.05542047321796417,
      -0.0019777347333729267,
      -0.06712478399276733,
      -0.03378869965672493,
      -0.021385159343481064,
      0.013481935486197472,
      0.061850666999816895,
      -0.06988877803087234,
      -0.06616401672363281,
      -0.025891203433275223,
      -0.039886295795440674,
      0.08826885372400284,
      0.09367861598730087,
      -0.024354571476578712,
      0.02540598064661026,
      -0.013093680143356323,
      0.0628642737865448,
      -0.07504827529191971,
      -0.0388537161052227,
      -0.03224284574389458,
      -0.030503319576382637,
      -0.020354313775897026,
      -0.005273711867630482,
      -0.028915589675307274,
      -0.010518098250031471,
      -0.000980100128799677,
      0.06330447643995285,
      0.018018614500761032,
      -0.035030048340559006,
      -0.04741593450307846,
      -0.05234744772315025,
      -0.04130769520998001,
      -0.09338438510894775,
      0.00130642659496516,
      -0.04630553722381592,
      -0.005448674783110619,
      -0.06078621745109558,
      0.06222447007894516,
      0.020377373322844505,
      -0.04462826997041702,
      0.002774487715214491,
      0.022016482427716255,
      0.0015778278466314077,
      0.02322910726070404,
      0.025027398020029068,
      0.018990864977240562,
      -0.09906782954931259,
      -0.04301466792821884,
      0.01788327284157276,
      0.05438978597521782,
      -0.012665578164160252,
      0.06866953521966934,
      0.027793478220701218,
      -0.023981092497706413,
      -0.07139318436384201,
      -0.06597353518009186,
      -0.036763109266757965,
      -0.09521938860416412,
      0.028561174869537354,
      -0.023406652733683586,
      0.005599756725132465,
      -0.00827868189662695,
      -0.015304269269108772,
      0.07596047967672348,
      0.042476724833250046,
      0.005850905552506447,
      -0.01714191772043705,
      -0.020882148295640945,
      -0.016988787800073624,
      -0.0022308353800326586,
      -0.04399361461400986,
      -0.03906243294477463,
      0.02742782048881054,
      0.03377532213926315,
      -0.008116020821034908,
      0.05805812031030655,
      -0.06645150482654572,
      -0.05965544283390045,
      0.02838253043591976,
      0.02329963445663452,
      -0.013592688366770744,
      -0.029627062380313873,
      0.021153278648853302,
      -0.0019927204120904207,
      0.02819180116057396,
      -0.08812430500984192,
      0.041607752442359924,
      -0.02026054821908474,
      0.11401703208684921,
      0.012486863881349564,
      -0.06304002553224564,
      -0.0018248417181894183,
      -0.053356606513261795,
      -0.06822613626718521,
      -0.02002970688045025,
      0.006412338465452194,
      0.0032082083635032177,
      0.08184526115655899,
      0.0047450284473598,
      0.016187628731131554,
      0.007308386731892824,
      -0.043514758348464966,
      0.04689299315214157,
      -0.00736416457220912,
      0.04864884912967682,
      0.02343076281249523,
      0.03584475442767143,
      0.035461436957120895,
      0.03169095888733864,
      0.044063519686460495,
      -0.03799867257475853,
      0.007520262151956558,
      0.06013432517647743,
      0.007159835658967495,
      -0.05946870520710945,
      0.024305501952767372,
      0.02204282023012638,
      0.0134742371737957,
      -0.01611739583313465,
      0.04545268416404724,
      0.0040359837003052235,
      0.020691800862550735,
      0.037584878504276276,
      0.025237340480089188,
      0.02789323404431343,
      0.007505640387535095,
      0.006440365687012672,
      0.049314115196466446,
      0.013972919434309006,
      -0.04569338634610176,
      0.01809440739452839,
      0.049335870891809464,
      -0.023615330457687378,
      -0.029259543865919113,
      0.04957282543182373,
      0.037257108837366104,
      -0.013821079395711422,
      -0.013004912063479424,
      -0.09345588833093643,
      -0.009859917685389519,
      -0.07932289689779282,
      -0.022349540144205093,
      -0.023949187248945236,
      -0.05362697318196297,
      0.08555907011032104,
      0.016115980222821236,
      0.008741156198084354,
      0.022266682237386703,
      -0.018272800371050835,
      -0.022128932178020477,
      0.05295627564191818,
      0.04561096057295799,
      0.04278186336159706,
      -0.004167599603533745,
      -0.03018113225698471,
      -0.012027441523969173,
      0.01300342008471489,
      -0.07808057963848114,
      0.0765451043844223,
      0.03359530121088028,
      -0.00201031775213778,
      0.020269181579351425,
      -0.035405393689870834,
      0.052268195897340775,
      0.0449608750641346,
      -0.02028915286064148,
      0.09412620216608047,
      -0.09528280794620514,
      -0.032337725162506104,
      -0.05921822413802147,
      -0.030926261097192764,
      -2.6941474970954005e-06,
      0.03025081381201744,
      0.054204780608415604,
      -0.006337844301015139,
      -0.05536938086152077,
      -0.009146961383521557,
      8.410877489950508e-05,
      -0.06520551443099976,
      -0.02388984151184559,
      -0.024595461785793304,
      0.09239103645086288,
      -0.004360559396445751,
      -0.06107277423143387,
      -0.016796067357063293,
      0.009681914001703262,
      0.06968643516302109,
      -0.014521567150950432,
      0.03145992010831833,
      0.04808416590094566,
      0.012443668209016323,
      -0.07703959196805954,
      0.08636804670095444,
      0.07491106539964676,
      0.008076632395386696,
      0.03191987797617912,
      0.06880463659763336,
      -0.009792850352823734,
      0.024316556751728058,
      -0.04790515452623367,
      0.02993878535926342,
      -0.06325067579746246,
      -0.07425810396671295,
      -0.03783872723579407,
      -0.085136778652668,
      0.013557815924286842,
      0.058189623057842255,
      -0.0017734088469296694,
      -0.06661749631166458,
      0.009020308032631874,
      -0.017325395718216896,
      0.023270824924111366,
      -0.02940826304256916,
      0.02917926199734211,
      0.0439700186252594,
      -0.0041355593129992485,
      0.06402873247861862,
      -0.0058992416597902775,
      -0.040817517787218094,
      -0.0609336756169796,
      0.014287151396274567,
      0.0002453337365295738,
      0.04209743067622185,
      -0.07846949994564056,
      0.006869873963296413,
      0.06813347339630127,
      0.04454384371638298,
      -0.02629953622817993,
      0.06021201238036156,
      0.02509467303752899,
      0.019841399043798447,
      -0.09999600052833557,
      0.07411087304353714,
      0.041303813457489014,
      0.038352660834789276,
      -0.03494713827967644,
      -0.03001275658607483,
      0.05627496913075447,
      -0.01534504909068346,
      -0.009634984657168388,
      0.031590960919857025,
      0.01202586479485035,
      0.0933152362704277,
      0.0355561301112175,
      -0.031836096197366714,
      -0.05583189055323601,
      -0.02786891907453537,
      0.028388196602463722,
      -0.027550121769309044,
      0.05153748765587807,
      0.10434277355670929,
      -0.018330993130803108,
      0.02020922675728798,
      -0.022003062069416046,
      0.03288320079445839,
      0.0658339262008667,
      0.10726027190685272,
      -0.0036954847164452076,
      -0.020141171291470528,
      -0.009501929394900799,
      0.022874685004353523,
      0.02664870396256447,
      0.06552670896053314,
      -0.021181056275963783,
      0.010863508097827435,
      -0.09819046407938004,
      0.0028194147162139416,
      0.00971928983926773,
      -0.05041917786002159,
      0.02982371859252453,
      0.03544633463025093,
      0.04736519232392311,
      0.01984458416700363,
      0.013879205100238323,
      0.07970092445611954,
      0.07796187698841095,
      -0.023764997720718384,
      0.04507286846637726,
      0.05112750455737114,
      0.056965019553899765,
      0.047287099063396454,
      0.05098249390721321,
      -0.0354849137365818,
      -0.016896847635507584,
      -0.011334423907101154,
      -0.00819541234523058,
      0.05192602798342705,
      0.006033286452293396,
      -0.014197650365531445,
      0.03878781944513321,
      -0.07380090653896332,
      -0.1167779341340065,
      0.04861117899417877,
      -0.051878347992897034,
      -0.024106137454509735,
      -0.039137400686740875,
      0.0320272296667099,
      -0.04231475293636322,
      -0.0529007650911808,
      0.002256127307191491,
      0.03555848076939583,
      -0.056957751512527466,
      0.013355681672692299,
      0.05738983303308487,
      -0.0070453607477247715,
      -0.0420617014169693,
      0.007763498462736607,
      0.05941386893391609,
      0.02931416593492031,
      0.020835302770137787,
      0.025593170896172523,
      0.018694588914513588,
      0.040373288094997406,
      0.019714506343007088,
      0.03081103414297104,
      -0.04821893572807312,
      -0.09094958007335663,
      -0.049194350838661194,
      -0.052155546844005585,
      -0.017469557002186775,
      -0.056129392236471176,
      -0.09120175242424011,
      -0.004450282081961632,
      0.08918289095163345,
      0.06704849749803543,
      -0.006135161034762859,
      0.032340649515390396,
      -0.03390461206436157,
      -0.003672284772619605,
      0.029391808435320854,
      -0.047894418239593506,
      -0.031713321805000305,
      0.01195093896239996,
      -0.02505691535770893,
      -0.07132856547832489,
      0.01780378259718418,
      0.024364911019802094
    ],
    [
      -0.0041211857460439205,
      -0.01944459043443203,
      0.018018262460827827,
      0.04344559833407402,
      -0.01779506728053093,
      -0.0006680588703602552,
      0.011762131005525589,
      -0.0013034468283876777,
      -0.01601066067814827,
      -0.10320688039064407,
      0.019243571907281876,
      0.040154095739126205,
      0.03040771558880806,
      0.01668652519583702,
      0.06494858115911484,
      0.08513825386762619,
      0.09720715880393982,
      0.013579134829342365,
      0.08308708667755127,
      0.04518735781311989,
      0.0069782002829015255,
      -0.06968923658132553,
      0.0665525272488594,
      -0.022865714505314827,
      0.043039143085479736,
      0.008177782408893108,
      0.007942132651805878,
      -0.09923458099365234,
      -0.05028032138943672,
      0.03264015540480614,
      0.019759362563490868,
      0.0607273206114769,
      0.04590638726949692,
      -0.012586034834384918,
      -0.030313733965158463,
      0.038713518530130386,
      -0.08497729152441025,
      -0.05079636722803116,
      -0.0045458353124558926,
      0.026612538844347,
      -0.053201645612716675,
      -0.0724644884467125,
      0.031928543001413345,
      0.01468286570161581,
      -0.009249036200344563,
      -0.059198468923568726,
      0.024393511936068535,
      0.010310480371117592,
      -0.037604257464408875,
      -0.017977118492126465,
      0.03692200407385826,
      0.09881561249494553,
      -0.01875905692577362,
      -0.07159988582134247,
      -0.0059058284386992455,
      0.07849875092506409,
      0.031069515272974968,
      0.03322799503803253,
      -0.011134529486298561,
      0.0197930708527565,
      0.035362742841243744,
      -0.048079442232847214,
      0.0006205266108736396,
      -0.001522925915196538,
      -0.05373268201947212,
      0.018736759200692177,
      0.012573267333209515,
      0.019934965297579765,
      0.008765216916799545,
      0.06771770864725113,
      0.04201424866914749,
      -0.12339889258146286,
      -0.007188241928815842,
      0.013568609021604061,
      -0.04413045570254326,
      0.051435623317956924,
      -0.10917181521654129,
      2.968664739455562e-05,
      0.0012277679052203894,
      0.08409304171800613,
      -0.024281829595565796,
      0.009821822866797447,
      0.06448932737112045,
      -0.0018935812404379249,
      0.06612346321344376,
      0.016047431156039238,
      0.05874628573656082,
      0.021166345104575157,
      0.00860873144119978,
      -0.11101487278938293,
      -0.022438541054725647,
      -0.0255487821996212,
      0.04739449545741081,
      0.07359210401773453,
      -0.027941087260842323,
      -0.02975388988852501,
      0.08132145553827286,
      -0.01672426611185074,
      -0.006956032942980528,
      0.03247019276022911,
      -0.03554132580757141,
      0.0152751374989748,
      -0.016556667163968086,
      0.06095818802714348,
      -0.0005480066174641252,
      0.06094695255160332,
      0.08402971178293228,
      0.02190384641289711,
      -0.11764011532068253,
      -0.022779323160648346,
      0.06534375995397568,
      -0.0013179618399590254,
      0.014286858960986137,
      -0.053730595856904984,
      0.007247051689773798,
      -0.004964806139469147,
      0.002734746551141143,
      -0.0036555081605911255,
      0.001685665687546134,
      0.04883056506514549,
      0.020780619233846664,
      -0.0443081371486187,
      0.02644786238670349,
      0.014375703409314156,
      0.03781980648636818,
      -0.06732470542192459,
      0.03378821164369583,
      0.09823198616504669,
      0.024513717740774155,
      -0.04448271170258522,
      0.03034847043454647,
      0.020661579445004463,
      0.0023419903591275215,
      -0.04290296509861946,
      0.052167776972055435,
      0.007009338121861219,
      -0.054702065885066986,
      0.005936640314757824,
      0.042725685983896255,
      0.058316417038440704,
      -0.02255273051559925,
      0.006672462914139032,
      0.03163371980190277,
      0.026700491085648537,
      -0.008778348565101624,
      -0.04842308908700943,
      0.0064666857942938805,
      0.00841575488448143,
      0.08104316890239716,
      -0.01930622011423111,
      0.02334248647093773,
      0.01654902845621109,
      -0.03383626043796539,
      -0.022455422207713127,
      0.019370153546333313,
      0.0658821314573288,
      0.025479694828391075,
      -0.007989742793142796,
      0.011176100932061672,
      0.00685993954539299,
      -0.09631308913230896,
      -0.011480867862701416,
      -0.02090313658118248,
      0.019092535600066185,
      0.08730235695838928,
      -0.022339629009366035,
      -0.0474674254655838,
      -0.08454097807407379,
      -0.023749718442559242,
      -0.023547615855932236,
      0.006405317690223455,
      0.03949631005525589,
      -0.04975241422653198,
      0.057785604149103165,
      0.009773490019142628,
      0.023285401985049248,
      -0.007140095811337233,
      0.02217593789100647,
      0.0009702962124720216,
      -0.028857193887233734,
      -0.031213542446494102,
      0.0926351249217987,
      -0.018706414848566055,
      0.0019151904853060842,
      -0.018024567514657974,
      0.009355985559523106,
      0.046914245933294296,
      -0.03725653141736984,
      0.02643021009862423,
      -0.04981788247823715,
      -0.013270080089569092,
      0.02040075697004795,
      -0.017890581861138344,
      0.011173187755048275,
      0.034058697521686554,
      0.03180873394012451,
      -0.035871878266334534,
      0.019329924136400223,
      0.08448399603366852,
      0.03429894894361496,
      -0.011820252053439617,
      -0.003100732108578086,
      0.03337293863296509,
      0.0008477713563479483,
      0.06557494401931763,
      0.005288638174533844,
      0.0479426309466362,
      0.05913102999329567,
      -0.02480139583349228,
      0.032119426876306534,
      -0.024899043142795563,
      0.0033700368367135525,
      -0.06986774504184723,
      -0.056723784655332565,
      -0.0515843965113163,
      0.03756260871887207,
      0.005571476649492979,
      -0.024492841213941574,
      0.03407343477010727,
      0.008422087877988815,
      0.0073266164399683475,
      -0.07603836059570312,
      -0.00817702617496252,
      -0.006289985030889511,
      -0.003936377819627523,
      0.12087151408195496,
      0.008356497623026371,
      -0.050620291382074356,
      0.017284663394093513,
      -0.03346867486834526,
      -0.019802602007985115,
      -0.03351934999227524,
      -0.07468049973249435,
      0.007925810292363167,
      -0.05620183050632477,
      0.004999096039682627,
      0.011898703873157501,
      -0.02353273145854473,
      -0.016309406608343124,
      -0.010050904005765915,
      -0.05205626040697098,
      0.015836583450436592,
      -0.014323687180876732,
      0.006266961805522442,
      -0.0019671842455863953,
      -0.00547418836504221,
      -0.047709424048662186,
      0.039886295795440674,
      0.06339065730571747,
      -0.03657567501068115,
      0.043407801538705826,
      -0.014342774637043476,
      -0.04177756607532501,
      0.05946291983127594,
      0.019418660551309586,
      0.059673432260751724,
      0.030721614137291908,
      0.00022558076307177544,
      -0.04574933648109436,
      0.01605638489127159,
      0.023670407012104988,
      -0.004627089481800795,
      -0.0252121239900589,
      0.02146749570965767,
      -0.004620998632162809,
      0.025359729304909706,
      0.03395366668701172,
      -0.020237280055880547,
      0.028820954263210297,
      0.007726636249572039,
      0.0208395104855299,
      -0.024812811985611916,
      -0.06137193366885185,
      0.02157830446958542,
      0.042435988783836365,
      0.05233234912157059,
      -0.005466418340802193,
      -0.04831812530755997,
      0.03792060539126396,
      0.08447602391242981,
      -0.031171899288892746,
      -0.06414826959371567,
      0.03327131271362305,
      -0.000573672354221344,
      -0.03871038556098938,
      -0.014357028529047966,
      5.5806045565987006e-05,
      -0.05327046662569046,
      -0.07067250460386276,
      -0.06539637595415115,
      0.0553261823952198,
      0.0009507005452178419,
      0.02466598153114319,
      -0.012713645584881306,
      0.0010982201201841235,
      0.07197551429271698,
      -0.020376933738589287,
      0.08658425509929657,
      0.016991103067994118,
      -0.05152767896652222,
      0.03803648799657822,
      0.06094270944595337,
      -0.003288090694695711,
      -0.004006276838481426,
      0.06147490441799164,
      0.048861898481845856,
      0.005247242283076048,
      -0.05793651565909386,
      -0.039987713098526,
      -0.03893616050481796,
      0.0473257414996624,
      -0.02733590081334114,
      0.00317660765722394,
      0.01935090683400631,
      -0.007302098907530308,
      0.02776366099715233,
      -0.01785661093890667,
      -0.02673504129052162,
      -0.05538595840334892,
      -0.01914355345070362,
      0.023087037727236748,
      0.019956694915890694,
      -0.04210826754570007,
      0.06456810235977173,
      0.0005430295714177191,
      -0.040728311985731125,
      -0.08971085399389267,
      0.02383388951420784,
      -0.0730648785829544,
      0.029013337567448616,
      -0.04443496838212013,
      0.0038259292487055063,
      -0.03289405256509781,
      0.0029474569018930197,
      0.045138806104660034,
      -0.07325495779514313,
      -0.024601133540272713,
      -0.043780162930488586,
      -0.045392997562885284,
      -0.001587194623425603,
      -0.04201192408800125,
      -0.010698080062866211,
      -0.01788407750427723,
      -0.01291030365973711,
      0.04050968587398529,
      -0.03312557935714722,
      -0.07901839911937714,
      -0.024667222052812576,
      -0.010533494874835014,
      0.0356069914996624,
      0.013213698752224445,
      0.0002793127787299454,
      0.0051243188790977,
      -0.0028140523936599493,
      -0.05832536146044731,
      -0.033208925276994705,
      -0.005455898120999336,
      -0.025606175884604454,
      0.061150986701250076,
      -0.01261893566697836,
      0.040355753153562546,
      0.02783491089940071,
      -0.01784275472164154,
      -0.016565809026360512,
      -0.014831066131591797,
      -9.389982733409852e-05,
      0.012427215464413166,
      0.03621957451105118,
      0.0011558326659724116,
      0.03895476832985878,
      0.10765401273965836,
      -0.014832129701972008,
      -0.06858902424573898,
      -0.004886258859187365,
      0.019060811027884483,
      -0.058639347553253174,
      -0.1041286513209343,
      -0.015769820660352707,
      -0.009934676811099052,
      0.03202057629823685,
      -0.024830088019371033,
      -0.006165847647935152,
      -0.00489101791754365,
      0.018904196098446846,
      0.058484338223934174,
      0.04190170392394066,
      -0.007221321575343609,
      -0.018953323364257812,
      0.03962322324514389,
      -0.035708531737327576,
      -0.03397030383348465,
      -0.032367903739213943,
      -0.007069165352731943,
      -0.02646857313811779,
      0.02393447235226631,
      -0.05320500209927559,
      0.002197461435571313,
      -0.055227603763341904,
      -0.018057867884635925,
      -0.056012481451034546,
      0.12003582715988159,
      -0.023662690073251724,
      -0.023724481463432312,
      -0.0032257509883493185,
      -0.06434451043605804,
      -0.042699139565229416,
      0.07085485011339188,
      -0.0228795874863863,
      -0.051943641155958176,
      -0.005868401378393173,
      -0.045040473341941833,
      -0.08058541268110275,
      -0.0033288858830928802,
      0.06199956312775612,
      0.04377633333206177,
      0.019613761454820633,
      -0.008031814359128475,
      0.012165822088718414,
      0.07957231253385544,
      0.07188534736633301,
      0.0433509387075901,
      0.007841654121875763,
      -0.12617899477481842,
      -0.016895819455385208,
      -0.0177912600338459,
      -0.009917977266013622,
      -0.028645018115639687,
      -0.023955298587679863,
      -0.032654743641614914,
      0.041579220443964005,
      0.02833995595574379,
      -0.0037371127400547266,
      -0.026272060349583626,
      0.14505596458911896,
      0.01842907816171646,
      0.07130291312932968,
      -0.00015585063374601305,
      -0.021702207624912262,
      -0.009084113873541355,
      -0.10167496651411057,
      -0.06401795893907547,
      0.00043546545202843845,
      -0.015107810497283936,
      -0.03233686462044716,
      0.03962963819503784,
      -0.025968298316001892,
      0.08958633989095688,
      0.10016175359487534,
      -0.025021448731422424,
      0.008634972386062145,
      0.004747864790260792,
      -0.050537027418613434,
      -0.06913066655397415,
      0.06027833744883537,
      -0.03138355165719986,
      -0.004171639680862427,
      0.0046092793345451355,
      -0.06455200165510178,
      -0.026117980480194092,
      0.021631283685564995,
      -0.015748025849461555,
      -0.005713512189686298,
      -0.02251431904733181,
      -0.07494733482599258,
      -0.015057133510708809,
      -0.01282036304473877,
      -0.040703821927309036,
      -0.016595952212810516,
      -0.014734326861798763,
      0.024795489385724068,
      -0.04915201663970947,
      0.028506172820925713,
      -0.073680579662323,
      0.04359867423772812,
      0.08145545423030853,
      -0.027661025524139404,
      0.05372831970453262,
      0.07681792229413986,
      0.03482936695218086,
      -0.03144119307398796,
      0.009194702841341496,
      -0.011292525567114353,
      -0.014567777514457703,
      -0.050516530871391296,
      0.039116404950618744,
      0.01996229775249958,
      -0.0673346295952797,
      -0.06500924378633499,
      0.07148898392915726,
      0.008985047228634357,
      0.013905207626521587,
      -0.006511494982987642,
      -0.0031685568392276764,
      0.0028680493123829365,
      -0.017513122409582138,
      -0.01369393989443779,
      0.036537230014801025,
      0.005273619666695595,
      -0.004448052030056715,
      0.013145838864147663,
      -0.012742706574499607,
      0.021894706413149834,
      0.01987893134355545
    ],
    [
      -0.0031291565392166376,
      -0.09943067282438278,
      0.0012667719274759293,
      0.04052165895700455,
      0.06881631165742874,
      0.01955782063305378,
      -0.04619960859417915,
      0.016639770939946175,
      -0.01715124398469925,
      0.012904602102935314,
      0.0065496573224663734,
      -0.055685121566057205,
      -0.07499121874570847,
      0.027428606525063515,
      -0.035375844687223434,
      -0.005717155523598194,
      0.03925158083438873,
      0.00425880728289485,
      0.008012605831027031,
      -0.0684979259967804,
      -0.024325860664248466,
      0.04890437051653862,
      0.03648000210523605,
      0.0338466502726078,
      0.06475749611854553,
      0.014825347810983658,
      0.0541963055729866,
      -0.13481365144252777,
      0.02676171250641346,
      -0.038901012390851974,
      0.08532481640577316,
      0.042472947388887405,
      0.05840006843209267,
      -0.011313905008137226,
      -0.0125173544511199,
      -0.04912789165973663,
      -0.07558678090572357,
      0.022740334272384644,
      -0.024655763059854507,
      0.012734432704746723,
      -0.017409346997737885,
      0.04409303888678551,
      0.06428266316652298,
      -0.06069556251168251,
      0.0876893699169159,
      -0.013218129053711891,
      0.03794407472014427,
      0.08315111696720123,
      0.07049410045146942,
      0.007207463961094618,
      -0.029510043561458588,
      -0.0026636067777872086,
      0.08992084115743637,
      -0.05413895845413208,
      -0.03192601725459099,
      -0.04331488162279129,
      -0.046590372920036316,
      0.03956427797675133,
      0.06655503064393997,
      -0.036301758140325546,
      -0.05081337317824364,
      0.04316507279872894,
      -0.009210138581693172,
      -0.08468557894229889,
      -0.04271566495299339,
      0.007280225399881601,
      -0.053061239421367645,
      -0.03205959126353264,
      0.028326062485575676,
      -0.024135947227478027,
      0.029314963147044182,
      -0.020887965336441994,
      -0.010026214644312859,
      0.0067794471979141235,
      0.10505172610282898,
      -0.059604864567518234,
      -0.09877010434865952,
      -0.0612511932849884,
      0.019207393750548363,
      -0.014659666456282139,
      -0.059225261211395264,
      -0.02325151115655899,
      0.026489637792110443,
      -0.02561884932219982,
      -0.05996484309434891,
      -0.039104804396629333,
      0.0331755094230175,
      -0.02239413373172283,
      0.01669022999703884,
      -0.07131331413984299,
      -0.029680036008358,
      -0.013342860154807568,
      0.0025105965323746204,
      -0.02730492874979973,
      0.1073634997010231,
      -0.024656040593981743,
      -0.029570240527391434,
      0.03980788588523865,
      -0.0024355631321668625,
      0.06938321888446808,
      0.03840871527791023,
      -0.020519131794571877,
      -0.04499863460659981,
      -0.0033095059916377068,
      -0.020657403394579887,
      0.018378660082817078,
      0.04963144287467003,
      0.08372887969017029,
      -0.0395270511507988,
      0.0802098736166954,
      0.019223064184188843,
      -0.02359219267964363,
      -0.0796734020113945,
      -0.034250594675540924,
      -0.03096528723835945,
      -0.012397672049701214,
      -0.03954685106873512,
      -0.029386235401034355,
      0.12424365431070328,
      0.013747675344347954,
      0.015501429326832294,
      0.05763284116983414,
      -0.022255489602684975,
      -0.00997068453580141,
      0.01584695838391781,
      -0.012884643860161304,
      0.0236220620572567,
      0.016460612416267395,
      0.04015577957034111,
      0.008868972770869732,
      -0.003706729505211115,
      -0.08635623008012772,
      0.009165666997432709,
      -0.018132327124476433,
      0.009878108277916908,
      0.06029205769300461,
      -0.05751600116491318,
      0.05754303187131882,
      0.03749438747763634,
      -0.05837371200323105,
      -0.012002205476164818,
      -0.033328160643577576,
      0.12002267688512802,
      -0.04510953277349472,
      0.039466723799705505,
      -0.03109757788479328,
      0.11071407049894333,
      0.03060792200267315,
      -0.10379016399383545,
      0.010377464815974236,
      0.059469521045684814,
      -0.0767701119184494,
      -0.013661563396453857,
      0.06524360924959183,
      0.010631226934492588,
      0.03942381218075752,
      0.07185159623622894,
      0.025924241170287132,
      -0.02436673454940319,
      0.10038267821073532,
      -0.0023911301977932453,
      -0.038337841629981995,
      -0.010944793932139874,
      -0.03084251657128334,
      -0.05629969388246536,
      0.017081454396247864,
      0.0632709264755249,
      0.011714167892932892,
      -0.017145154997706413,
      0.12465402483940125,
      0.014132502488791943,
      0.00034648284781724215,
      -0.015366688370704651,
      -0.03550320491194725,
      -0.0050266520120203495,
      -0.055091943591833115,
      0.022627128288149834,
      -0.003256953088566661,
      -0.039747778326272964,
      -0.015083522535860538,
      0.04217730462551117,
      -0.03193221241235733,
      -0.020564155653119087,
      0.008485433645546436,
      -0.021378664299845695,
      0.07113523036241531,
      -0.04984646290540695,
      -0.011637948453426361,
      0.05385000631213188,
      -0.029437340795993805,
      0.038854677230119705,
      0.06940481066703796,
      -0.12673042714595795,
      0.017847584560513496,
      -0.03146662935614586,
      -0.04742349684238434,
      -0.021698039025068283,
      0.043544791638851166,
      -0.06201734393835068,
      0.07674208283424377,
      0.01070285402238369,
      0.07756633311510086,
      0.08547870069742203,
      0.030316252261400223,
      -0.08934500068426132,
      0.02875339239835739,
      0.0691053569316864,
      0.05067362263798714,
      -0.015342782251536846,
      0.023777179419994354,
      -0.0021688572596758604,
      0.014199717901647091,
      -0.03653872385621071,
      -0.01750766672194004,
      0.06170902028679848,
      -0.04771866649389267,
      0.047587547451257706,
      -0.056892942637205124,
      -0.018273744732141495,
      -0.012632676400244236,
      0.014629858545958996,
      -0.09747633337974548,
      0.0008347617695108056,
      -0.04941684752702713,
      -0.06495754420757294,
      -0.06943939626216888,
      -0.01903713494539261,
      0.09908720850944519,
      -0.002030918374657631,
      0.015133239328861237,
      -0.005740548484027386,
      0.09203043580055237,
      -0.055107057094573975,
      -0.06027401238679886,
      -0.038079775869846344,
      -0.009010433219373226,
      0.0014052585465833545,
      -0.08391448110342026,
      0.021441655233502388,
      0.03987794741988182,
      -0.01064752135425806,
      -0.031219610944390297,
      -0.0597936287522316,
      0.022988589480519295,
      -0.018004458397626877,
      -0.03687792643904686,
      -0.005992497317492962,
      -0.07022085040807724,
      0.004657185170799494,
      0.006094568874686956,
      0.013883206062018871,
      0.026750748977065086,
      0.03339767083525658,
      0.05451393499970436,
      -0.03018525429069996,
      -0.026718422770500183,
      0.032248444855213165,
      0.02919590286910534,
      0.01519736461341381,
      0.013640844263136387,
      -0.00020578131079673767,
      -0.048480093479156494,
      -0.06667343527078629,
      -0.003892294829711318,
      -0.03824928030371666,
      0.0015497151762247086,
      0.005115563049912453,
      0.021012496203184128,
      -0.00936218909919262,
      -0.023162050172686577,
      0.027978597208857536,
      -0.0754886344075203,
      0.08804003894329071,
      0.024424981325864792,
      -0.02862415835261345,
      -0.032806213945150375,
      0.0186222642660141,
      0.008750734850764275,
      -0.0012852678773924708,
      -0.02525160275399685,
      -0.03459006920456886,
      0.044331859797239304,
      -0.034010179340839386,
      -0.005370134022086859,
      -0.0039761862717568874,
      0.025493202731013298,
      -0.005788938142359257,
      -0.006199422758072615,
      0.007905740290880203,
      0.20779597759246826,
      0.07496722042560577,
      -0.0170406736433506,
      0.03516814112663269,
      -0.09309674799442291,
      -0.020342949777841568,
      0.007188572082668543,
      -0.02696113847196102,
      0.06109808012843132,
      0.04837413504719734,
      -0.1517031490802765,
      0.000872553326189518,
      0.057816386222839355,
      -0.0057576908729970455,
      0.02572399191558361,
      0.003291328903287649,
      -0.010381179861724377,
      -0.025990473106503487,
      0.032152287662029266,
      0.052555277943611145,
      -0.020523851737380028,
      -0.14729733765125275,
      -0.07038430124521255,
      0.016396814957261086,
      -0.0730915293097496,
      0.02536552958190441,
      0.02012261375784874,
      -0.0013759976718574762,
      -0.051841624081134796,
      0.029845569282770157,
      -0.058106258511543274,
      0.021440783515572548,
      0.04198744520545006,
      0.0020666620694100857,
      0.004816400818526745,
      -0.022525420412421227,
      0.04177728667855263,
      0.014774606563150883,
      0.05326205864548683,
      -0.00508651789277792,
      -0.04423269256949425,
      0.023365087807178497,
      -0.04428689181804657,
      -0.027715397998690605,
      -0.004720697179436684,
      0.039523471146821976,
      -0.06429927051067352,
      -0.014411699958145618,
      -0.04967368766665459,
      0.024069789797067642,
      0.029678242281079292,
      0.13145019114017487,
      0.058355022221803665,
      -0.035504285246133804,
      -0.0208952184766531,
      0.021157555282115936,
      -0.06430349498987198,
      -0.04858236387372017,
      0.07175351679325104,
      0.01190218236297369,
      -0.01905873790383339,
      -0.02177705056965351,
      -0.07163409888744354,
      0.02928072400391102,
      -0.029170645400881767,
      0.05829508975148201,
      0.03492480143904686,
      -0.016010841354727745,
      -0.0013959878124296665,
      0.045543134212493896,
      -0.04183173179626465,
      -0.07566659897565842,
      -0.059501707553863525,
      0.06557683646678925,
      -0.024228479713201523,
      0.009982367046177387,
      0.0221253652125597,
      0.05726882070302963,
      0.056138597428798676,
      0.018426217138767242,
      -0.06140315160155296,
      0.0030893394723534584,
      -0.024395572021603584,
      0.005735390353947878,
      0.08765795826911926,
      -0.0020729131065309048,
      0.02803795412182808,
      -0.06289350986480713,
      -0.0019739405252039433,
      0.04662373661994934,
      0.03122941218316555,
      -0.07640597969293594,
      -0.04384753853082657,
      -0.018099753186106682,
      0.028815530240535736,
      0.01111456286162138,
      0.025388048961758614,
      0.006341821514070034,
      -0.01823398284614086,
      0.08145244419574738,
      0.0013588112778961658,
      0.012513931840658188,
      0.021954379975795746,
      0.0556517019867897,
      0.01222930010408163,
      -0.014482535421848297,
      0.023962482810020447,
      -0.06265055388212204,
      0.03423239663243294,
      -0.02132597751915455,
      -0.007436723913997412,
      0.06331142038106918,
      0.03575360029935837,
      0.04191222041845322,
      0.016176754608750343,
      -0.08649799972772598,
      0.07694246619939804,
      -0.10528863221406937,
      -0.06341247260570526,
      -0.044304508715867996,
      -0.017206303775310516,
      -0.11777202039957047,
      0.048857659101486206,
      0.035177867859601974,
      0.019082002341747284,
      0.012642531655728817,
      -0.013521300628781319,
      -0.0075572216883301735,
      -0.08296458423137665,
      0.03900928050279617,
      -0.0013613611226901412,
      -0.006176735740154982,
      0.046612005680799484,
      0.014219837263226509,
      0.060114894062280655,
      -0.049599986523389816,
      0.04072996973991394,
      -0.004188808612525463,
      -0.03175345063209534,
      -0.058077722787857056,
      -0.012720051221549511,
      -0.0158906988799572,
      0.07041634619235992,
      -0.04103045165538788,
      0.07327441871166229,
      -0.10191618651151657,
      0.020653171464800835,
      -0.016413763165473938,
      0.09036369621753693,
      0.00452844426035881,
      -0.05668022856116295,
      0.030295182019472122,
      0.08648192137479782,
      -0.05936716869473457,
      0.00023364275693893433,
      -0.013060112483799458,
      -0.010688822716474533,
      0.005958448629826307,
      -0.0071251532062888145,
      0.043371863663196564,
      0.029690464958548546,
      -0.04998202994465828,
      -0.012470114976167679,
      0.023986496031284332,
      0.08604883402585983,
      -0.06717059761285782,
      0.05693686008453369,
      0.014588125981390476,
      -0.07921180129051208,
      0.0004910918651148677,
      -0.012208358384668827,
      0.06401240080595016,
      0.03927651047706604,
      -0.03396989777684212,
      -0.0337875671684742,
      0.021938035264611244,
      -0.020978400483727455,
      0.026096947491168976,
      0.03125930204987526,
      0.01647047884762287,
      0.0485214963555336,
      -0.08666662871837616,
      0.02426132932305336,
      0.03237885236740112,
      0.017619723454117775,
      -0.010455154813826084,
      -0.011471168138086796,
      -0.01728217862546444,
      -0.021215692162513733,
      0.055548254400491714,
      0.04872259870171547,
      -0.03432329371571541,
      0.027939260005950928,
      -0.1338808834552765,
      -0.0022232781630009413,
      -0.04851546511054039,
      0.011954019777476788,
      -0.03666481003165245,
      -0.02601373754441738,
      0.047161832451820374,
      0.03319229185581207,
      -0.019084451720118523,
      -0.053784195333719254,
      -0.026072856038808823,
      0.013136178255081177,
      0.06754456460475922,
      -0.020790349692106247,
      -0.02649412676692009,
      -0.01606551744043827,
      0.06156798452138901,
      -0.03430802747607231,
      -0.061972834169864655,
      0.044793449342250824,
      -0.04079551249742508
    ],
    [
      -0.016860712319612503,
      -0.03328792378306389,
      0.026061052456498146,
      0.04716440290212631,
      -0.012925486080348492,
      0.0002622389292810112,
      0.08066532760858536,
      0.023734793066978455,
      -0.011483224108815193,
      -0.008435127325356007,
      0.00950297899544239,
      -0.012347144074738026,
      -0.05401703715324402,
      -0.10001089423894882,
      -0.052697356790304184,
      0.030794275924563408,
      -0.01965109072625637,
      -0.012295441702008247,
      -0.06064502149820328,
      -0.0031383426394313574,
      0.06086678057909012,
      0.029624078422784805,
      0.009248376823961735,
      -0.004598511848598719,
      -0.014998708851635456,
      0.10298404097557068,
      -0.07005251199007034,
      -0.07527267932891846,
      0.04628997668623924,
      0.00952945090830326,
      -0.035725440829992294,
      0.028923580422997475,
      -0.05083528533577919,
      0.020457006990909576,
      -0.010011279955506325,
      0.0017350915586575866,
      -0.005388235207647085,
      0.011428432539105415,
      0.03688632324337959,
      -0.13087709248065948,
      0.032108649611473083,
      -0.04263388738036156,
      -0.06151316314935684,
      -0.013337860815227032,
      0.046795524656772614,
      -0.0440010130405426,
      0.08537725359201431,
      0.020391147583723068,
      0.06766979396343231,
      0.004892800003290176,
      -0.03445442393422127,
      0.10504380613565445,
      0.04904382303357124,
      0.0007522219675593078,
      0.04153548181056976,
      -0.0152287557721138,
      -0.06446588784456253,
      0.03510311618447304,
      0.03168046101927757,
      0.09113680571317673,
      0.06821880489587784,
      0.00904657319188118,
      -0.03734583035111427,
      -0.04732215404510498,
      -0.03307293355464935,
      -0.06747560203075409,
      0.024523116648197174,
      -0.10362841933965683,
      0.018153777346014977,
      0.034974779933691025,
      0.005888912361115217,
      -0.05781475082039833,
      0.052602242678403854,
      0.04398499056696892,
      0.010467729531228542,
      -0.008379491046071053,
      -0.00135289307218045,
      -0.007152385078370571,
      -0.13097123801708221,
      0.02846844308078289,
      -0.030224723741412163,
      -0.059682730585336685,
      0.0031342576257884502,
      -0.028435267508029938,
      -0.01818620041012764,
      -0.05111335217952728,
      0.06312528252601624,
      0.055279091000556946,
      0.036897823214530945,
      -0.023945631459355354,
      0.02548537403345108,
      -0.06125194579362869,
      -0.012860060669481754,
      0.018441908061504364,
      -0.004817723762243986,
      0.038363438099622726,
      0.019438361749053,
      0.024752987548708916,
      0.015513310208916664,
      0.04295792430639267,
      0.05378197133541107,
      0.03248792514204979,
      -0.00042246171506121755,
      -0.0329778715968132,
      0.023022420704364777,
      -0.01665051095187664,
      -0.02578880824148655,
      0.0466245599091053,
      -0.01566888391971588,
      0.0028160102665424347,
      -0.03132819011807442,
      -0.013965141028165817,
      -0.006801700685173273,
      -0.04097637161612511,
      0.07848465442657471,
      -0.006597746629267931,
      0.030928803607821465,
      -0.020685343071818352,
      0.0559920072555542,
      -0.0071076033636927605,
      0.003311890410259366,
      -0.02005191519856453,
      0.03243107721209526,
      0.032474976032972336,
      0.017582038417458534,
      -0.03440646827220917,
      0.022790946066379547,
      0.05265806242823601,
      -0.026893476024270058,
      0.06284873187541962,
      0.045241761952638626,
      -0.047924794256687164,
      -0.06758297979831696,
      -0.0024896508548408747,
      -0.09381822496652603,
      -0.027224848046898842,
      0.06915441155433655,
      -0.025305140763521194,
      -0.07388854771852493,
      0.023792605847120285,
      -0.025801710784435272,
      -0.01988968625664711,
      0.02328777313232422,
      -0.04547306150197983,
      -0.08221152424812317,
      -0.010109802708029747,
      0.09929518401622772,
      0.024636708199977875,
      -0.0383342020213604,
      0.03106849454343319,
      -0.059558428823947906,
      -0.08624334633350372,
      -0.004802057519555092,
      -0.010012992657721043,
      -0.08653932809829712,
      -0.018550381064414978,
      -0.03082626312971115,
      -0.05844971910119057,
      0.010048342868685722,
      0.06038758158683777,
      0.0068731470964848995,
      0.0002016578655457124,
      0.02779087796807289,
      0.008394671604037285,
      0.045526765286922455,
      -0.0009379073744639754,
      0.03624548390507698,
      -0.04829874634742737,
      0.05116456374526024,
      0.044428396970033646,
      -0.03513301536440849,
      0.012348259799182415,
      0.06510920077562332,
      0.07478205859661102,
      -0.06129365414381027,
      -0.04058488458395004,
      0.02170948125422001,
      -0.011022202670574188,
      0.08228909224271774,
      -0.018801992759108543,
      -0.0033380412496626377,
      0.003314858302474022,
      -0.01705482043325901,
      0.028730757534503937,
      0.014317487366497517,
      0.03176257759332657,
      -0.05071692168712616,
      0.08852323889732361,
      -0.031210187822580338,
      0.07949016988277435,
      -0.008743120357394218,
      0.06763157993555069,
      -0.06268870830535889,
      0.009392989799380302,
      0.07711609452962875,
      -0.013512546196579933,
      0.010307482443749905,
      -0.07379922270774841,
      0.025357834994792938,
      -0.06575513631105423,
      -0.0052821640856564045,
      -0.06040262430906296,
      -0.02226080745458603,
      0.05034413933753967,
      -0.0039015489164739847,
      0.06757760792970657,
      -0.0658349096775055,
      -0.07753373682498932,
      0.021842841058969498,
      -0.04195629060268402,
      -0.06731165945529938,
      0.016983013600111008,
      -0.0299697145819664,
      -0.01733137108385563,
      0.009984481148421764,
      -5.0369242671877146e-05,
      -0.04171550273895264,
      -0.05045606195926666,
      0.011819761246442795,
      0.00011695439752656966,
      0.02428148314356804,
      0.01047232374548912,
      -0.025413760915398598,
      -0.030148807913064957,
      0.03125826269388199,
      -0.07867176830768585,
      -0.06611842662096024,
      -0.0231106448918581,
      0.05717388913035393,
      -0.011776561848819256,
      0.01944683864712715,
      -0.010456524789333344,
      0.055076077580451965,
      0.005451192148029804,
      -0.02029242366552353,
      0.07013877481222153,
      -0.04143819585442543,
      0.015398988500237465,
      -0.03703569248318672,
      0.091901034116745,
      0.021232370287179947,
      0.026489775627851486,
      0.012382091954350471,
      -0.061225928366184235,
      0.047171931713819504,
      -0.00761215528473258,
      0.018422802910208702,
      0.057473938912153244,
      0.08187971264123917,
      0.059608858078718185,
      -0.0039272853173315525,
      -0.0483335517346859,
      -0.04127150774002075,
      -0.09448152780532837,
      -0.06176793947815895,
      0.07867350429296494,
      0.0070581636391580105,
      0.02151508815586567,
      0.06606584042310715,
      0.06317330151796341,
      -0.020103776827454567,
      0.02746613323688507,
      -0.026825789362192154,
      0.05522570013999939,
      0.009496496990323067,
      -0.07639177143573761,
      -0.010488104075193405,
      -0.0033240076154470444,
      0.0049406071193516254,
      -0.04750855267047882,
      0.05950626730918884,
      -0.009663421660661697,
      0.03320872038602829,
      -8.885982970241457e-05,
      -0.0789109319448471,
      0.0004244383017066866,
      -0.03597061336040497,
      -0.011369526386260986,
      0.030087988823652267,
      0.011334234848618507,
      -0.04200466349720955,
      0.03351283073425293,
      0.010408991947770119,
      0.12997177243232727,
      0.01854011043906212,
      0.08425568044185638,
      -0.005368945654481649,
      0.006682301871478558,
      0.07547076791524887,
      0.08053320646286011,
      -0.05647658929228783,
      -0.003758902195841074,
      -0.049081332981586456,
      0.00903758592903614,
      0.015420569106936455,
      -0.04137831926345825,
      0.016721822321414948,
      -0.04788365215063095,
      -0.040347497910261154,
      0.07076648622751236,
      0.04112985357642174,
      0.01928257942199707,
      0.0035746910143643618,
      -0.009601952508091927,
      0.02427121438086033,
      0.02593751810491085,
      0.014815988950431347,
      0.065056212246418,
      -0.03359188511967659,
      -0.03245190531015396,
      -0.02438761480152607,
      -0.0013423049822449684,
      0.031213464215397835,
      -0.03984977677464485,
      0.005780916661024094,
      -0.05838558077812195,
      -0.02110685221850872,
      0.04520552232861519,
      -0.11667423695325851,
      -0.07122871279716492,
      -0.050799787044525146,
      0.0008461790275759995,
      0.008749163709580898,
      0.07984356582164764,
      0.09811194986104965,
      -0.0186842642724514,
      0.027666816487908363,
      -0.03411293029785156,
      0.010566988959908485,
      0.043466802686452866,
      0.06703785061836243,
      0.040664348751306534,
      0.027881324291229248,
      -0.02938607707619667,
      -0.03137889504432678,
      -0.023027371615171432,
      0.046047914773225784,
      0.05517116189002991,
      0.020261764526367188,
      0.061796631664037704,
      0.019918298348784447,
      0.002529889577999711,
      0.0453597716987133,
      0.024529634043574333,
      -0.01616194285452366,
      -0.019604329019784927,
      -0.047170933336019516,
      0.012686915695667267,
      0.0558953583240509,
      0.0010857293382287025,
      0.03341751918196678,
      2.970159584947396e-06,
      -0.026366733014583588,
      0.07620914280414581,
      0.019820628687739372,
      0.00032233080128207803,
      -0.010800758376717567,
      -0.09676137566566467,
      0.0009116450091823936,
      0.03173496201634407,
      0.00600462919101119,
      0.009839532896876335,
      -0.022732170298695564,
      -0.03916921466588974,
      0.04008903354406357,
      0.08702129125595093,
      0.033284012228250504,
      0.035484377294778824,
      0.018600182607769966,
      0.042278651148080826,
      0.0580880306661129,
      -0.1228049099445343,
      0.024436067789793015,
      0.07601001113653183,
      -0.030394522473216057,
      -0.08699136972427368,
      0.036521684378385544,
      0.015367405489087105,
      -0.026468021795153618,
      -0.04744281619787216,
      -0.03895435482263565,
      -0.06657397001981735,
      -0.02309129759669304,
      0.08280327916145325,
      0.02825320139527321,
      0.004515356384217739,
      -0.07126464694738388,
      -0.044998638331890106,
      0.026980364695191383,
      -0.0390959158539772,
      0.0006634244928136468,
      0.06209448724985123,
      -0.0484185554087162,
      -0.02858085371553898,
      0.0827777236700058,
      0.027603918686509132,
      0.0309415552765131,
      -0.0365348756313324,
      -0.05658746138215065,
      0.03588514029979706,
      -0.027276083827018738,
      0.059029605239629745,
      0.05082767456769943,
      -0.0035768577363342047,
      -0.03822903335094452,
      0.004545147996395826,
      0.01740252785384655,
      0.037892840802669525,
      -0.023235740140080452,
      -0.07128016650676727,
      0.0007865094812586904,
      -0.04597616568207741,
      -0.011390730738639832,
      0.030985623598098755,
      -0.0366063192486763,
      -0.0006418589618988335,
      -0.020145796239376068,
      0.07300087809562683,
      -0.0462687723338604,
      0.04443090781569481,
      -0.013000410050153732,
      0.003128819400444627,
      0.01616073213517666,
      -0.06716123223304749,
      -0.00877272430807352,
      0.021152451634407043,
      -0.043751344084739685,
      0.0024801024701446295,
      0.01808435469865799,
      0.01879972219467163,
      -0.021741725504398346,
      0.003928502555936575,
      -0.00268202624283731,
      -0.0855482667684555,
      0.0005674779531545937,
      0.011084324680268764,
      0.04932257533073425,
      0.016959955915808678,
      0.04600726068019867,
      -0.02461780048906803,
      0.025075381621718407,
      0.03150414675474167,
      -0.01410992443561554,
      0.013607988134026527,
      0.02038276195526123,
      0.043988458812236786,
      -0.03764059394598007,
      -0.017849087715148926,
      -0.006136201787739992,
      -0.02484123408794403,
      -0.012538934126496315,
      0.0086035942658782,
      -0.017855338752269745,
      0.012106141075491905,
      0.055680565536022186,
      0.10904444754123688,
      0.02592734806239605,
      0.05285082012414932,
      0.020798999816179276,
      -0.07212070375680923,
      0.07448585331439972,
      -0.021367156878113747,
      -0.007295381277799606,
      -0.057748593389987946,
      -0.044209133833646774,
      0.006394031923264265,
      0.07021214067935944,
      -0.06266859918832779,
      -0.0150547930970788,
      -0.10257306694984436,
      -0.01184418611228466,
      -0.002314436947926879,
      -0.011369933374226093,
      -0.0367235466837883,
      -0.03566757217049599,
      -0.0014502170961350203,
      0.027926724404096603,
      0.0350705087184906,
      0.019892675802111626,
      0.015188446268439293,
      0.027651650831103325,
      0.05382014811038971,
      0.02239818312227726,
      0.08267557621002197,
      -0.040277112275362015,
      -0.03505980595946312,
      -0.04957032948732376,
      -0.05077660083770752,
      0.04493147134780884,
      0.005828589200973511,
      0.035487592220306396,
      -0.0006071340758353472,
      -0.10261089354753494,
      -0.03720977157354355,
      0.021053727716207504,
      0.004175056703388691,
      0.06423550844192505,
      0.04010697081685066,
      0.002894034842029214,
      0.018254317343235016,
      -0.07621540874242783,
      -0.05050765350461006,
      -0.03559237718582153
    ],
    [
      0.04443544149398804,
      -0.024778444319963455,
      0.03163636103272438,
      0.013186809606850147,
      0.003265233477577567,
      0.029250003397464752,
      0.00839526392519474,
      0.033969536423683167,
      -0.00849384069442749,
      0.01592334546148777,
      -0.018307773396372795,
      -0.01771262288093567,
      -0.0005679453606717288,
      -0.05291752517223358,
      -0.022930780425667763,
      -0.057057760655879974,
      0.024455316364765167,
      -0.00725244777277112,
      0.000670539156999439,
      0.05576704815030098,
      -0.017513884231448174,
      -0.054421424865722656,
      -0.0031560950446873903,
      -0.022425778210163116,
      0.04514741525053978,
      0.04485272616147995,
      -0.012639609165489674,
      0.06387815624475479,
      0.036964382976293564,
      0.03256404772400856,
      0.020687535405158997,
      -0.07790156453847885,
      -0.0015868458431214094,
      -0.0071270582266151905,
      -0.030057236552238464,
      0.0013275542296469212,
      -0.0018767319852486253,
      -0.0018001031130552292,
      -0.0923159196972847,
      0.016219407320022583,
      -0.018707450479269028,
      0.0358494408428669,
      0.030564680695533752,
      0.023352721706032753,
      0.006742632947862148,
      0.01675638183951378,
      0.05354929715394974,
      -0.02616567350924015,
      -0.01796545647084713,
      -0.005633077118545771,
      -0.005271296016871929,
      -0.060732707381248474,
      -0.05368870124220848,
      -0.053416263312101364,
      -0.029872195795178413,
      0.032850589603185654,
      0.01761019416153431,
      0.007051259744912386,
      0.02437356300652027,
      -0.01337664108723402,
      0.005825618281960487,
      0.07566243410110474,
      -0.029244527220726013,
      -0.06933970749378204,
      0.1023063063621521,
      -0.03353828936815262,
      0.12527847290039062,
      0.043203409761190414,
      0.07168332487344742,
      0.009732174687087536,
      0.000815236649941653,
      0.015263800509274006,
      -0.007260482758283615,
      0.047766558825969696,
      0.023120887577533722,
      0.009891519322991371,
      0.010970444418489933,
      -0.01068834587931633,
      -0.02550404518842697,
      0.01629999838769436,
      0.04687396436929703,
      -0.00205849576741457,
      -0.09210767596960068,
      -0.05727646127343178,
      -0.07088024914264679,
      -0.008414970710873604,
      -0.05593419447541237,
      0.00861484743654728,
      0.006714028771966696,
      0.02013252303004265,
      -0.024282529950141907,
      0.05083443596959114,
      -0.029389608651399612,
      -0.057354990392923355,
      -0.005625949706882238,
      -0.0063278707675635815,
      0.08801498264074326,
      0.06643176823854446,
      0.031124701723456383,
      -0.06466179341077805,
      0.0020254317205399275,
      -0.06149188056588173,
      -0.010691599920392036,
      0.04520980268716812,
      0.022541234269738197,
      0.05818775296211243,
      -0.01504733320325613,
      -0.061655089259147644,
      0.01902669668197632,
      -0.055958040058612823,
      -0.023048561066389084,
      0.011820700950920582,
      0.0013666783925145864,
      -0.016152458265423775,
      0.057849157601594925,
      -0.07152846455574036,
      -0.08825622498989105,
      -0.046914950013160706,
      -0.0325365886092186,
      0.04090601205825806,
      0.07015710324048996,
      -0.013268801383674145,
      0.01809416338801384,
      -0.054239921271800995,
      0.01280407328158617,
      -0.017509441822767258,
      -0.036721158772706985,
      -0.008617043495178223,
      0.05839492380619049,
      0.02949153445661068,
      -0.006777405738830566,
      0.0851152166724205,
      0.021992072463035583,
      -0.01968519575893879,
      0.00254970439709723,
      -0.020311273634433746,
      0.012246118858456612,
      0.01446245051920414,
      -0.08831782639026642,
      0.031747233122587204,
      0.027395527809858322,
      -0.012752052396535873,
      -0.05099033564329147,
      0.0734877735376358,
      -0.04677502438426018,
      -0.055626560002565384,
      0.04580610245466232,
      -0.08526770770549774,
      -0.006657985504716635,
      0.015149293467402458,
      -0.03211356699466705,
      -0.015185697935521603,
      -0.010390880517661572,
      -0.02873215824365616,
      0.05807012319564819,
      -0.023675646632909775,
      -0.015484653413295746,
      -0.015371418558061123,
      -0.004051183350384235,
      -0.06560808420181274,
      0.004183223936706781,
      -0.022466905415058136,
      -0.11527565121650696,
      -0.028651857748627663,
      -0.00120131007861346,
      0.010006718337535858,
      0.007078169379383326,
      0.031009983271360397,
      0.02112330123782158,
      0.024395853281021118,
      -0.04056362807750702,
      -0.033265769481658936,
      -0.061066582798957825,
      -0.0008394740871153772,
      0.03362363576889038,
      -0.012273631989955902,
      -0.06967968493700027,
      -0.01017073541879654,
      0.07053180038928986,
      -0.0272079911082983,
      0.048636335879564285,
      -0.03875775262713432,
      0.03799588605761528,
      0.07517796754837036,
      0.00812985934317112,
      -0.013031753711402416,
      -0.09301217645406723,
      0.01921987719833851,
      0.05041332170367241,
      0.02482256293296814,
      0.05659405514597893,
      0.018669676035642624,
      -0.022791093215346336,
      -0.05724891275167465,
      0.0834387019276619,
      -0.07123422622680664,
      0.01640135794878006,
      -0.030345972627401352,
      0.01901138760149479,
      -0.0007369995582848787,
      -0.016284001991152763,
      -0.01656210422515869,
      -0.012179287150502205,
      0.022709844633936882,
      0.03353026509284973,
      -0.0857686698436737,
      -0.007312092464417219,
      0.08830223232507706,
      -0.03579412400722504,
      0.029671775177121162,
      -0.04502657800912857,
      0.0007372662657871842,
      0.03224574774503708,
      0.050028324127197266,
      0.13259533047676086,
      0.018855104222893715,
      -0.04973256587982178,
      -0.04436570405960083,
      -0.007124236319214106,
      0.08557187020778656,
      0.05325193703174591,
      0.06942638754844666,
      -0.0852351114153862,
      0.045041851699352264,
      0.04761994257569313,
      0.002846523653715849,
      0.06713070720434189,
      -0.0887138620018959,
      -0.02256234921514988,
      -0.09198864549398422,
      -0.036450471729040146,
      0.04257775843143463,
      -0.006057857535779476,
      -0.02255246229469776,
      0.026603149250149727,
      -0.04823342338204384,
      -0.026998111978173256,
      -0.049703001976013184,
      0.0006459894357249141,
      -0.017381025478243828,
      -0.005244565196335316,
      0.10451501607894897,
      0.05514776334166527,
      -0.05724235624074936,
      0.058681339025497437,
      -0.07265393435955048,
      -0.059033941477537155,
      -0.02565399371087551,
      -0.019418183714151382,
      0.05565435439348221,
      -0.048822641372680664,
      -0.011143042705953121,
      0.03819127753376961,
      -0.004538219887763262,
      0.05563335493206978,
      0.013038813136518002,
      0.039206113666296005,
      -0.004761365242302418,
      0.049458254128694534,
      0.10118618607521057,
      -0.05760757252573967,
      -0.00269870413467288,
      -0.026719823479652405,
      0.006097785197198391,
      -0.0047916811890900135,
      -0.03031095117330551,
      0.018507791683077812,
      0.068939208984375,
      -0.032678235322237015,
      0.026380334049463272,
      0.048165611922740936,
      0.027641156688332558,
      -0.1150398775935173,
      -0.030352281406521797,
      0.03786329925060272,
      -0.03693690523505211,
      -0.05772595480084419,
      -0.05143309384584427,
      0.00838981382548809,
      -0.0019701854325830936,
      -0.032928530126810074,
      0.07226130366325378,
      0.0657220184803009,
      -0.005274590104818344,
      0.010581215843558311,
      -0.017617302015423775,
      -0.00303446757607162,
      0.026409490033984184,
      0.004092353396117687,
      -0.009113459847867489,
      -0.0021644053049385548,
      -0.0024044588208198547,
      0.0157412551343441,
      -0.02038871869444847,
      -0.019897859543561935,
      -0.07398960739374161,
      -0.017118552699685097,
      -0.0483432300388813,
      0.02190495654940605,
      0.003631944302469492,
      -0.012047093361616135,
      -0.002212401945143938,
      -0.06773659586906433,
      0.012833383865654469,
      0.02822537161409855,
      -0.11090060323476791,
      -0.02635139413177967,
      0.04193108528852463,
      0.0895523726940155,
      0.029922230169177055,
      0.03534775972366333,
      0.04874000325798988,
      -0.008862032555043697,
      -0.022199582308530807,
      0.06003525108098984,
      -0.014100574888288975,
      -0.044633638113737106,
      0.05095537006855011,
      -0.004359621554613113,
      -0.04080972447991371,
      -0.04673033580183983,
      -0.03497902676463127,
      0.002323565538972616,
      -0.02651538886129856,
      -0.06795438379049301,
      -0.11217659711837769,
      -0.03426492214202881,
      -0.020058955997228622,
      0.011569335125386715,
      -0.039539966732263565,
      -0.0644037127494812,
      -0.032358430325984955,
      0.03104572929441929,
      0.011035307310521603,
      0.06324292719364166,
      0.069916270673275,
      -0.1345670372247696,
      -0.066890187561512,
      0.0475657694041729,
      0.0398789681494236,
      0.029288697987794876,
      0.011708538047969341,
      -0.02450132742524147,
      0.022875258699059486,
      -0.02591240219771862,
      0.00867720227688551,
      -0.029501458629965782,
      0.043640460819005966,
      -0.09487568587064743,
      0.07536638528108597,
      0.07025057822465897,
      0.015543299727141857,
      -0.010693752206861973,
      -0.12204822897911072,
      0.047454021871089935,
      -0.04847608134150505,
      0.06768420338630676,
      -0.028182953596115112,
      0.007727377116680145,
      0.07481755316257477,
      0.009173371829092503,
      -0.054482053965330124,
      0.011744637042284012,
      0.03460757061839104,
      0.05526374280452728,
      -0.044568270444869995,
      -0.01627342216670513,
      0.0798616111278534,
      -0.010490833781659603,
      -0.010165981948375702,
      0.036911629140377045,
      -0.08480455726385117,
      0.00808160100132227,
      0.04095781594514847,
      -0.02196991816163063,
      0.006153399124741554,
      0.03966956585645676,
      -0.05010269582271576,
      -0.022209947928786278,
      -0.0035493161994963884,
      0.03125499188899994,
      0.10185565799474716,
      0.0020073270425200462,
      0.019836436957120895,
      -0.03664611279964447,
      -0.054458629339933395,
      -0.04968808963894844,
      -0.04413066431879997,
      0.026159847155213356,
      0.007823480293154716,
      -0.06289388239383698,
      -0.0870293378829956,
      0.010143791325390339,
      0.005852749105542898,
      -0.005544551648199558,
      -0.042567480355501175,
      -0.015059673227369785,
      0.10273686796426773,
      -0.012082349509000778,
      -0.03038814291357994,
      -0.003987503238022327,
      0.09095831215381622,
      -0.06533890962600708,
      -0.00658907787874341,
      -0.0059151602908968925,
      0.01760334149003029,
      -0.05279815196990967,
      0.017332011833786964,
      -0.011691275984048843,
      -0.05761903524398804,
      -0.010965146124362946,
      0.021713361144065857,
      -0.04046136513352394,
      0.03756394609808922,
      -0.024385064840316772,
      -0.004589393734931946,
      -0.05748901143670082,
      -0.04566477611660957,
      -0.0334598645567894,
      0.061500050127506256,
      0.031977079808712006,
      0.043711185455322266,
      0.025026677176356316,
      0.019614696502685547,
      -0.005150823388248682,
      0.039166875183582306,
      -0.005113956984132528,
      0.008396661840379238,
      -0.0352432057261467,
      -0.01108094397932291,
      0.0263751782476902,
      -0.08774098753929138,
      0.04630354791879654,
      -0.036979299038648605,
      -0.06178630515933037,
      -0.03555791825056076,
      0.05081444978713989,
      0.04801389202475548,
      0.031217724084854126,
      -0.10389425605535507,
      0.00238277530297637,
      -0.015390953049063683,
      -0.03176506608724594,
      0.03450790047645569,
      0.03194158151745796,
      -0.05048927292227745,
      -0.0004747229104395956,
      4.561095920507796e-05,
      0.01091388612985611,
      0.035817839205265045,
      0.019465239718556404,
      -0.052390363067388535,
      0.03887060657143593,
      -0.018282508477568626,
      -0.026481186971068382,
      0.11770782619714737,
      0.04887804016470909,
      0.0515558160841465,
      0.019089845940470695,
      -0.033483877778053284,
      0.06411214917898178,
      0.007230373565107584,
      0.10553163290023804,
      -0.0526115708053112,
      0.020295489579439163,
      -0.027800729498267174,
      0.022106172516942024,
      0.03958118334412575,
      0.0274354200810194,
      -0.05076511949300766,
      -0.025579102337360382,
      -0.07015421986579895,
      0.048839353024959564,
      0.00031975205638445914,
      0.07980853319168091,
      -0.0059701064601540565,
      0.04199991747736931,
      -0.009768589399755001,
      0.08497156202793121,
      0.08145686239004135,
      0.04246388003230095,
      0.030518852174282074,
      -0.011413874104619026,
      -0.03738909214735031,
      -0.010430161841213703,
      0.004268359858542681,
      -0.0008787046535871923,
      0.03221166133880615,
      0.034711338579654694,
      0.03354031965136528,
      -0.0938035249710083,
      -0.07579920440912247,
      -0.07130379974842072,
      0.0719602182507515,
      0.02878018468618393,
      0.019215648993849754,
      -0.0520143136382103,
      0.011934488080441952,
      -0.04618103429675102,
      0.029226651415228844,
      -0.11150605231523514,
      -0.05466901510953903,
      -0.08865930140018463
    ],
    [
      -0.07891477644443512,
      0.005327257327735424,
      0.026181086897850037,
      -0.026165232062339783,
      -0.03543352708220482,
      0.028555726632475853,
      -0.0684560015797615,
      0.052206505089998245,
      -0.015262017957866192,
      -0.031492140144109726,
      -0.025107650086283684,
      -0.08974189311265945,
      -0.0043347496539354324,
      -0.03531945124268532,
      0.0035648136399686337,
      -0.026544582098722458,
      0.011270176619291306,
      -0.05074490234255791,
      0.06986059248447418,
      -0.04838842153549194,
      -0.0015399139374494553,
      -0.02735535055398941,
      0.04061555117368698,
      -0.0051522022113204,
      0.04834236949682236,
      -0.037123337388038635,
      -0.04478706791996956,
      -0.017427820712327957,
      0.006282868795096874,
      0.02673804946243763,
      -0.02168978750705719,
      -0.020925836637616158,
      -0.057439617812633514,
      -0.01529709156602621,
      -0.012223218567669392,
      0.03687167540192604,
      -0.018758799880743027,
      -0.05249401554465294,
      0.0155442850664258,
      -0.07135985046625137,
      -0.04727606475353241,
      0.07668647915124893,
      -0.061917174607515335,
      -0.036879438906908035,
      -0.015257160179316998,
      -0.02525065280497074,
      -0.0005633113323710859,
      -0.014095710590481758,
      0.0391538105905056,
      -0.04009618982672691,
      -0.12329136580228806,
      -0.01702679693698883,
      0.061602137982845306,
      0.053026799112558365,
      -0.10326256603002548,
      0.0512571856379509,
      0.06151323765516281,
      0.11980243027210236,
      0.021730372682213783,
      0.01701311394572258,
      -0.10025886446237564,
      -0.007401386741548777,
      0.018367959186434746,
      -0.0777546614408493,
      -0.060009267181158066,
      -0.017414674162864685,
      -0.010568141005933285,
      -0.006871408317238092,
      0.017810281366109848,
      -0.08161662518978119,
      -0.08388683199882507,
      -0.01647726632654667,
      0.0002514585212338716,
      0.021961065009236336,
      -0.03561697527766228,
      -0.03794306516647339,
      0.04020934924483299,
      -0.022267688065767288,
      -0.04243173450231552,
      0.10098975151777267,
      0.0021565055940300226,
      0.0554647296667099,
      -0.005732748191803694,
      0.019633784890174866,
      -0.04289481043815613,
      0.048554107546806335,
      -0.011972842738032341,
      -0.050267454236745834,
      -0.006158337462693453,
      -0.018657194450497627,
      0.049742162227630615,
      0.039287738502025604,
      -0.006877296604216099,
      -0.04403650760650635,
      -0.07027152180671692,
      -0.01574750617146492,
      -0.050326548516750336,
      0.019874880090355873,
      0.06816083937883377,
      -0.026112964376807213,
      0.048074886202812195,
      -0.04383380338549614,
      0.015356140211224556,
      0.000834585924167186,
      -0.06992632150650024,
      0.07571788877248764,
      0.06245506927371025,
      -0.007341585122048855,
      0.050593361258506775,
      0.09134500473737717,
      0.06699737906455994,
      0.02088537998497486,
      -0.05798548460006714,
      -0.0017588003538548946,
      -0.008286680094897747,
      -0.08780147135257721,
      -0.0609765462577343,
      -0.0011608880013227463,
      0.0821266919374466,
      -0.08363248407840729,
      -0.025564780458807945,
      0.045871008187532425,
      0.04174842685461044,
      -0.057612378150224686,
      -0.006712140049785376,
      0.021867645904421806,
      0.04245993494987488,
      -0.05392363294959068,
      -0.00039695060695521533,
      0.10963273793458939,
      0.05912027135491371,
      -0.034844640642404556,
      0.005089689511805773,
      -0.010517879389226437,
      0.04031342640519142,
      0.026788823306560516,
      0.042213283479213715,
      -0.05607239902019501,
      0.0035551395267248154,
      0.06791310012340546,
      0.05472355708479881,
      0.07898962497711182,
      -0.07799788564443588,
      0.025032414123415947,
      -0.02829071879386902,
      -0.06608407199382782,
      0.003377786371856928,
      0.07816655933856964,
      0.04533062502741814,
      0.01845373772084713,
      -0.001247757812961936,
      -0.011045570485293865,
      -0.02750454470515251,
      0.05138234794139862,
      0.06050432100892067,
      -0.0006383569561876357,
      -0.04589969292283058,
      -0.0706004947423935,
      -0.030066605657339096,
      -0.00043844038737006485,
      0.0567249059677124,
      0.009954198263585567,
      -0.01020090188831091,
      -0.031110255047678947,
      0.014386973343789577,
      -0.06974714994430542,
      0.0349772647023201,
      0.05758140608668327,
      0.038745202124118805,
      -0.11123435199260712,
      0.012902156449854374,
      -0.0012228288687765598,
      0.02062581479549408,
      -0.05295061692595482,
      0.02888176031410694,
      -0.024063844233751297,
      0.043779920786619186,
      -0.028837282210588455,
      0.0026239228900521994,
      0.059791840612888336,
      0.04686838388442993,
      -0.01187175139784813,
      -0.011943833902478218,
      0.0074654449708759785,
      0.0554630383849144,
      0.08146612346172333,
      -0.06944642215967178,
      0.016819335520267487,
      -0.06629651039838791,
      0.015573974698781967,
      0.05493747070431709,
      0.09092124551534653,
      0.023278869688510895,
      0.013446507975459099,
      -0.017567068338394165,
      0.025371747091412544,
      -0.01555530447512865,
      -0.02501237951219082,
      -0.012108616530895233,
      -0.06490229815244675,
      -0.03346773982048035,
      0.06707756966352463,
      -0.010575471445918083,
      0.006971478927880526,
      -0.0035252675879746675,
      -0.06226956471800804,
      -0.03382177650928497,
      -0.01921689510345459,
      -0.041885342448949814,
      -9.807026071939617e-05,
      -0.04884439334273338,
      -0.02249027229845524,
      -0.04702631011605263,
      0.06980514526367188,
      0.06494510918855667,
      -0.008327927440404892,
      0.015161622315645218,
      0.0025266879238188267,
      -0.013484200462698936,
      -0.09083769470453262,
      0.02057478204369545,
      -0.028465233743190765,
      -0.04496833682060242,
      -0.045484866946935654,
      0.011900045908987522,
      -0.03887045755982399,
      -0.022363826632499695,
      0.08128029853105545,
      -0.031476881355047226,
      0.09120403230190277,
      -0.014583270996809006,
      0.004599248990416527,
      0.024370096623897552,
      0.01367154624313116,
      0.11016996204853058,
      -0.024942683055996895,
      -0.03071696311235428,
      -0.04707120731472969,
      -0.029720859602093697,
      -0.004589654505252838,
      0.035149235278367996,
      0.0023577625397592783,
      -0.03872295841574669,
      0.10362757742404938,
      0.01737908273935318,
      -0.0705319419503212,
      -0.019856220111250877,
      -0.06589699536561966,
      -0.0033763323444873095,
      0.044405534863471985,
      0.03662826493382454,
      -0.08975454419851303,
      0.06206634268164635,
      -0.0361035130918026,
      -0.03920088708400726,
      -0.028534917160868645,
      0.007768387906253338,
      0.0053287772461771965,
      -0.09847771376371384,
      -0.02190207690000534,
      0.027192723006010056,
      -0.005436196457594633,
      0.02479466423392296,
      -0.026005810126662254,
      -0.02088572271168232,
      -0.05761248990893364,
      0.0051912181079387665,
      -0.01895592175424099,
      -0.044734250754117966,
      0.05302726849913597,
      -0.0316753014922142,
      -0.050711993128061295,
      -0.13774548470973969,
      0.059709008783102036,
      0.013028607703745365,
      0.005028205923736095,
      -0.0318550206720829,
      0.0459594801068306,
      -0.02320699393749237,
      0.004466785117983818,
      0.07899896800518036,
      -0.05416709929704666,
      -0.0005028251907788217,
      -0.12324100732803345,
      0.010375771671533585,
      -0.009427589364349842,
      0.007151745725423098,
      -0.06985189765691757,
      0.0022289245389401913,
      -0.01224936731159687,
      -0.015889424830675125,
      -0.016673190519213676,
      0.012264151126146317,
      0.025165334343910217,
      0.0069645182229578495,
      -0.07809348404407501,
      -0.005545949097722769,
      -0.04225429147481918,
      -0.05732382461428642,
      0.01979675702750683,
      -0.042477961629629135,
      -0.01739639975130558,
      -0.006358709651976824,
      0.05166476219892502,
      0.05298715457320213,
      0.011137982830405235,
      0.015490368008613586,
      0.0799890011548996,
      -0.01239180751144886,
      -0.07089479267597198,
      0.014773597940802574,
      0.06776558607816696,
      0.06923823058605194,
      -0.09222599864006042,
      -0.009940548799932003,
      0.041467636823654175,
      -0.06301486492156982,
      0.023926302790641785,
      -0.010136396624147892,
      0.01447782851755619,
      0.04375142976641655,
      -0.02102654054760933,
      0.029084309935569763,
      -0.062384553253650665,
      0.03155054152011871,
      -0.03175302594900131,
      0.051363199949264526,
      -0.009596869349479675,
      0.01647167280316353,
      -0.054066915065050125,
      0.03683863580226898,
      -0.07442473620176315,
      -0.02565494365990162,
      -0.029254721477627754,
      -0.03595607727766037,
      0.00583408959209919,
      0.05197180062532425,
      0.03799040615558624,
      0.00840417854487896,
      0.029799267649650574,
      0.04471220821142197,
      -2.4644350560265593e-05,
      0.0051120575517416,
      0.0023320410400629044,
      0.08813118189573288,
      -0.014265366829931736,
      -0.06337706744670868,
      -0.022089768201112747,
      0.0007563058170489967,
      0.028370670974254608,
      0.012340295128524303,
      0.03553735092282295,
      -0.015141306445002556,
      0.014103639870882034,
      0.03666146844625473,
      0.08695380389690399,
      0.031502485275268555,
      -0.028302589431405067,
      0.0692249983549118,
      0.007905498147010803,
      -0.029986338689923286,
      -0.07959877699613571,
      -0.032944805920124054,
      0.019518954679369926,
      -0.025722971186041832,
      0.04042477533221245,
      0.010486399754881859,
      0.09715435653924942,
      -0.06875915080308914,
      0.020555390045046806,
      0.030619017779827118,
      -0.018313370645046234,
      0.014215297996997833,
      -0.014797812327742577,
      -0.05731258913874626,
      -0.04831525683403015,
      -0.0296159777790308,
      -0.026656417176127434,
      0.021328134462237358,
      0.018829403445124626,
      -0.03609544038772583,
      -0.016567574813961983,
      -0.025274543091654778,
      -0.01088023278862238,
      -0.011039732955396175,
      -0.0021813414059579372,
      0.02497408166527748,
      0.011914092116057873,
      0.0038138367235660553,
      0.007707643788307905,
      0.01644575409591198,
      0.015565372072160244,
      0.013931065797805786,
      0.02368873357772827,
      -0.0070752897299826145,
      0.01201382651925087,
      0.05193435028195381,
      -0.05773690342903137,
      0.029090525582432747,
      0.11110926419496536,
      0.10453137010335922,
      0.005478593986481428,
      0.0129209253937006,
      0.039142508059740067,
      -0.06523390114307404,
      -0.019393686205148697,
      -0.004151447676122189,
      0.054499197751283646,
      -0.037453990429639816,
      0.028109969571232796,
      -0.03690556064248085,
      -0.017744436860084534,
      0.02991512604057789,
      0.03756587952375412,
      0.04452834650874138,
      -0.046396080404520035,
      0.04128169268369675,
      -0.03257618471980095,
      0.02305460534989834,
      0.08764548599720001,
      0.007262939587235451,
      -0.07744502276182175,
      -0.06343452632427216,
      0.012113922275602818,
      -0.002810250036418438,
      0.07477649301290512,
      0.03518814221024513,
      -0.04013219475746155,
      0.0035391938872635365,
      0.02719169482588768,
      -0.009959839284420013,
      0.031036267057061195,
      -0.017205500975251198,
      -0.024446791037917137,
      -0.013263765722513199,
      0.06025254353880882,
      -0.03322567418217659,
      0.019530372694134712,
      -0.029462039470672607,
      0.02712518349289894,
      -0.01749158278107643,
      0.03664574399590492,
      -0.04187965393066406,
      -0.05187050253152847,
      0.03758978471159935,
      -0.038469281047582626,
      -0.048029299825429916,
      -0.02739943005144596,
      -0.026791781187057495,
      0.04666297882795334,
      -0.019842183217406273,
      -0.02936995029449463,
      0.04712141677737236,
      -0.054766297340393066,
      -0.019247019663453102,
      0.09481063485145569,
      -0.002256800653412938,
      -0.06658034771680832,
      -0.03874082863330841,
      -0.06040147319436073,
      0.0012954548001289368,
      -0.021902302280068398,
      0.04021379351615906,
      -0.0031944119837135077,
      -0.05275163799524307,
      -0.005160740111023188,
      -0.04104550927877426,
      0.07463070005178452,
      0.052546460181474686,
      0.00785357691347599,
      -0.055627379566431046,
      -0.11286282539367676,
      -0.06397499144077301,
      0.016589444130659103,
      0.039378877729177475,
      0.05202929303050041,
      0.0231634434312582,
      -0.04468192905187607,
      0.034382905811071396,
      -0.028192225843667984,
      0.04977622255682945,
      0.015402340330183506,
      -0.045702964067459106,
      -0.07503370195627213,
      -0.024950681254267693,
      -0.03277231380343437,
      1.4831254702585284e-05,
      0.01737200655043125,
      -0.1505807489156723,
      -0.043630439788103104,
      0.009211786091327667,
      -0.014528747648000717,
      0.056648656725883484,
      0.15831813216209412,
      0.04773322492837906,
      0.0034844493493437767,
      -0.05126010626554489,
      0.0033335506450384855,
      -0.0497632697224617,
      0.0339595265686512,
      0.04064497724175453,
      0.0057021197862923145,
      -0.0579349622130394
    ],
    [
      0.0009770143078640103,
      0.029839441180229187,
      -0.016882801428437233,
      0.01919487863779068,
      -0.037518300116062164,
      0.06683167070150375,
      -0.057017602026462555,
      -0.006591832265257835,
      0.06794732064008713,
      0.06387767195701599,
      -0.014936979860067368,
      -0.04283959046006203,
      -0.07753649353981018,
      -0.009179865010082722,
      0.08586335182189941,
      -0.066989466547966,
      -0.12652651965618134,
      -0.011125434190034866,
      0.01726868376135826,
      -0.033629160374403,
      -0.01401353720575571,
      -0.015215585939586163,
      -0.017033500596880913,
      -0.05263170972466469,
      0.009004472754895687,
      0.016433119773864746,
      -0.050702184438705444,
      -0.06560511887073517,
      0.0074957492761313915,
      0.016912221908569336,
      0.04994119703769684,
      0.05208703503012657,
      -0.018701516091823578,
      -0.009512213990092278,
      -0.026037219911813736,
      -0.010961582884192467,
      0.02554151974618435,
      -0.02164233662188053,
      -0.03825446218252182,
      -0.007042011246085167,
      -0.07630962133407593,
      -0.056452877819538116,
      -0.034664154052734375,
      -0.04192112758755684,
      0.012574727647006512,
      -0.05114086717367172,
      -0.069380022585392,
      -0.029439250007271767,
      0.010115552693605423,
      -0.018268169835209846,
      0.02294020541012287,
      0.016325974836945534,
      0.02528645098209381,
      0.025290513411164284,
      0.03953281417489052,
      0.009377674199640751,
      0.12799440324306488,
      0.10563880205154419,
      -0.03698936477303505,
      0.06771546602249146,
      0.03873135522007942,
      0.05764305591583252,
      -0.08448127657175064,
      -0.02544381283223629,
      0.009972172789275646,
      0.009090619161725044,
      -0.01882612518966198,
      0.07715508341789246,
      0.034705549478530884,
      0.08170529454946518,
      -0.017474474385380745,
      -0.00011288616224192083,
      -0.01978575810790062,
      0.018277136608958244,
      -0.06118111312389374,
      0.012507795356214046,
      0.01988096907734871,
      -0.06781899929046631,
      0.08358218520879745,
      0.0011944930301979184,
      -0.03326604142785072,
      -0.07897509634494781,
      0.016922088339924812,
      -0.004978694953024387,
      0.022895894944667816,
      -0.01890472136437893,
      0.04408710077404976,
      0.029648685827851295,
      0.032262369990348816,
      0.009413748048245907,
      0.0043550292029976845,
      -0.026682449504733086,
      0.01734778843820095,
      -7.230617484310642e-06,
      -0.02298627234995365,
      0.012839211151003838,
      0.011150360107421875,
      -0.049386270344257355,
      0.0149560971185565,
      -0.09841997921466827,
      -0.036386165767908096,
      0.021720407530665398,
      0.027234040200710297,
      0.007964080199599266,
      0.025060130283236504,
      -0.05214357003569603,
      0.018160318955779076,
      0.006886034272611141,
      -0.04431290924549103,
      0.03115825168788433,
      -0.016021495684981346,
      -0.07079637050628662,
      -0.002985389670357108,
      -0.03706619143486023,
      -0.08137697726488113,
      -0.034070923924446106,
      0.030416404828429222,
      -0.0009735053754411638,
      0.05922139808535576,
      -0.00012049687938997522,
      -0.00022514822194352746,
      0.017867406830191612,
      -0.11284524947404861,
      0.004021972883492708,
      -0.003617797512561083,
      0.004725669510662556,
      0.05765196681022644,
      0.04328421130776405,
      0.01764090731739998,
      0.026276929304003716,
      -0.0767788290977478,
      -0.03979618474841118,
      -0.05601318180561066,
      -0.014312624000012875,
      -0.0432133674621582,
      -0.06645292043685913,
      -0.06331510096788406,
      -0.02416911907494068,
      -0.0570065975189209,
      0.010895829647779465,
      -0.008828588761389256,
      -0.06669865548610687,
      0.071737140417099,
      0.09277606755495071,
      -0.01960691064596176,
      -0.002554120495915413,
      -0.013521513901650906,
      0.03162067010998726,
      0.022995132952928543,
      0.020022714510560036,
      0.02059825137257576,
      0.0396137498319149,
      -0.08674686402082443,
      0.06560923159122467,
      -0.06273756921291351,
      0.019548211246728897,
      0.027992090210318565,
      0.03072183020412922,
      -0.013509124517440796,
      -0.046059321612119675,
      -0.02019338496029377,
      0.034994937479496,
      2.004439193115104e-05,
      -0.052049942314624786,
      0.021359916776418686,
      0.01631969027221203,
      0.026691514998674393,
      -0.0006914547411724925,
      0.029148314148187637,
      0.03583938255906105,
      0.021312519907951355,
      -0.018345218151807785,
      -0.09269919991493225,
      -0.07011117786169052,
      -0.007763999048620462,
      -0.01968161016702652,
      -0.0033968323841691017,
      -0.03710963577032089,
      -0.01956580951809883,
      0.024304309859871864,
      0.008867518045008183,
      -0.00042710304842330515,
      -0.0521579273045063,
      -0.0042132604867219925,
      -0.04847060889005661,
      -0.08389164507389069,
      -0.038199856877326965,
      -0.01453136932104826,
      -0.007722075562924147,
      -0.0254169013351202,
      0.009098675101995468,
      0.0014378110645338893,
      -0.04533807188272476,
      0.002076122909784317,
      0.028101062402129173,
      0.011981705203652382,
      -0.04189012944698334,
      0.07263489067554474,
      -0.0516262985765934,
      0.09425920248031616,
      -0.015736525878310204,
      0.043330658227205276,
      -0.06800150126218796,
      -0.005888200365006924,
      0.01268018502742052,
      0.032288674265146255,
      0.025381725281476974,
      -0.0018051929073408246,
      -0.08362552523612976,
      0.018801679834723473,
      0.058351028710603714,
      0.01006479561328888,
      -0.07024860382080078,
      0.02318502590060234,
      0.06238788738846779,
      0.06241358071565628,
      0.04426015913486481,
      -0.021890789270401,
      0.03190826252102852,
      -0.011690884828567505,
      0.07923974096775055,
      0.05691329389810562,
      0.0016584276454523206,
      -0.028893565759062767,
      0.0739423930644989,
      -0.03251392021775246,
      -0.0064895725809037685,
      0.01698211207985878,
      0.023790696635842323,
      -0.03158717602491379,
      0.039114780724048615,
      -0.08566121757030487,
      -0.028748059645295143,
      -0.01693802885711193,
      0.044359710067510605,
      -0.051857080310583115,
      0.008052457123994827,
      0.014662045054137707,
      0.03735595941543579,
      0.012997152283787727,
      0.016701089218258858,
      0.03176464885473251,
      0.016489485278725624,
      0.021869346499443054,
      0.012781764380633831,
      -0.071165069937706,
      0.07156934589147568,
      0.03353249654173851,
      0.007906449027359486,
      0.013585695996880531,
      -0.07480278611183167,
      -0.042364899069070816,
      -0.11356136202812195,
      0.04814263433218002,
      -0.02271253801882267,
      0.12252930551767349,
      0.00926976464688778,
      -0.0075387549586594105,
      -0.04754875972867012,
      0.06502662599086761,
      0.023258084431290627,
      0.07245047390460968,
      -0.05118931084871292,
      0.07198569178581238,
      -0.008320706896483898,
      -0.028532711789011955,
      0.0660456046462059,
      -0.0020820193458348513,
      0.052532859146595,
      -0.01174058485776186,
      0.04056892171502113,
      -0.02024875581264496,
      0.014323354698717594,
      -0.031162099912762642,
      -0.08376574516296387,
      0.005941277369856834,
      0.012670747004449368,
      -0.011398768983781338,
      0.07618853449821472,
      -0.061347976326942444,
      0.030160751193761826,
      -0.05599910020828247,
      -0.006205737590789795,
      0.07637497782707214,
      -0.015076031908392906,
      0.011575006879866123,
      0.056576285511255264,
      0.028922852128744125,
      0.028797844424843788,
      -0.03818242624402046,
      -0.03027702309191227,
      0.006369332317262888,
      0.011811405420303345,
      0.04095594212412834,
      0.00035796576412394643,
      -0.004808538593351841,
      -0.06209868565201759,
      0.002777856308966875,
      -0.0013601473765447736,
      -0.04171338677406311,
      0.007265154737979174,
      0.03877871483564377,
      0.009278268553316593,
      0.10735300928354263,
      -0.048745546489953995,
      -0.06792761385440826,
      -0.004203408490866423,
      0.1272314488887787,
      0.024777952581644058,
      -0.05958535894751549,
      -0.024286141619086266,
      0.10963772237300873,
      -0.060493119060993195,
      0.00915981363505125,
      -0.03317500278353691,
      0.021054022014141083,
      -0.060778435319662094,
      0.015163433738052845,
      -0.05646828934550285,
      -0.045066408812999725,
      0.0095461905002594,
      -0.075234554708004,
      -0.00972147099673748,
      0.03222544118762016,
      0.027076201513409615,
      0.07542368024587631,
      -0.018639659509062767,
      0.0165211483836174,
      -0.043735720217227936,
      0.02925257571041584,
      -0.04985763132572174,
      -0.012198015116155148,
      0.04185066744685173,
      -0.03365229070186615,
      0.03642556443810463,
      -0.04449336230754852,
      0.06410841643810272,
      -0.022389255464076996,
      -0.02452021650969982,
      -0.06311723589897156,
      -0.02004026249051094,
      -0.06760994344949722,
      0.017906581982970238,
      -0.0670597106218338,
      0.060672540217638016,
      -0.03609688952565193,
      -0.01776023954153061,
      0.03109290450811386,
      -0.007738477550446987,
      -0.007699442096054554,
      -0.06333112716674805,
      0.05833754688501358,
      -0.04405783861875534,
      0.011860116384923458,
      0.03488161414861679,
      -0.02667851187288761,
      -0.0848657414317131,
      0.018649142235517502,
      0.0544118694961071,
      0.014515843242406845,
      0.015256963670253754,
      -0.011755624786019325,
      -0.004456956405192614,
      -0.07356972247362137,
      0.10013888776302338,
      0.030894536525011063,
      0.06958488374948502,
      0.1274874210357666,
      -0.016329437494277954,
      -0.01907193288207054,
      -0.002971100155264139,
      -0.041247181594371796,
      0.05205908790230751,
      0.04068806394934654,
      0.011750464327633381,
      0.014295518398284912,
      -0.013606755994260311,
      -0.028399283066391945,
      -0.016316941007971764,
      -0.033724382519721985,
      -0.033739279955625534,
      0.02848372794687748,
      -0.008933802135288715,
      -0.045555658638477325,
      0.0011660914169624448,
      0.07468731701374054,
      0.010863840579986572,
      0.03114628978073597,
      -0.07788632810115814,
      -0.05044851079583168,
      0.019226398319005966,
      -0.023186562582850456,
      0.02852724865078926,
      -0.019441740587353706,
      -0.07478126138448715,
      0.04563014954328537,
      -0.00314929592423141,
      0.014234352856874466,
      -0.015875238925218582,
      -0.0010547953424975276,
      -0.0489441454410553,
      0.11270024627447128,
      0.03965616971254349,
      -0.02095957286655903,
      -0.09314391762018204,
      -0.041205037385225296,
      -0.03193730488419533,
      0.06212395429611206,
      -0.009563279338181019,
      -0.07715249061584473,
      -0.04514677822589874,
      -0.013756710104644299,
      0.03420856595039368,
      -6.449030479416251e-05,
      -0.051780615001916885,
      0.06514237821102142,
      -0.006045676302164793,
      0.05615083873271942,
      0.012130319140851498,
      0.06952457875013351,
      0.05085163936018944,
      0.014148659072816372,
      -0.044192563742399216,
      0.02134581282734871,
      -0.04045043885707855,
      0.00029072980396449566,
      -0.017223753035068512,
      0.03550293296575546,
      -0.03166875243186951,
      0.02849089540541172,
      0.020881257951259613,
      0.018330538645386696,
      -0.046433452516794205,
      -0.07354965806007385,
      0.01609152741730213,
      0.04556640237569809,
      0.039461784064769745,
      0.118098683655262,
      -0.011473434045910835,
      0.04760425165295601,
      0.05038091167807579,
      0.02142498269677162,
      0.021386606618762016,
      0.029347727075219154,
      -0.04688715562224388,
      -0.012814329005777836,
      -0.10698163509368896,
      0.025556521490216255,
      -0.022801315411925316,
      -0.015046604909002781,
      0.017564769834280014,
      0.03006378374993801,
      0.010679353028535843,
      -0.022243421524763107,
      0.03507353365421295,
      0.016107851639389992,
      0.018470484763383865,
      -0.07110199332237244,
      0.05168746039271355,
      -0.010801286436617374,
      0.01856299489736557,
      -0.02838754840195179,
      0.083096943795681,
      -0.001553746871650219,
      0.04653043672442436,
      -0.055821385234594345,
      -0.010873130522668362,
      -0.059169020503759384,
      0.0778583437204361,
      -0.031621430069208145,
      -0.009501359425485134,
      0.022350262850522995,
      -0.005212033167481422,
      0.003960931207984686,
      0.01632648892700672,
      0.01672406867146492,
      0.014911776408553123,
      0.12315283715724945,
      0.04216495901346207,
      -0.0848160907626152,
      0.049604058265686035,
      0.07125496119260788,
      -0.019399357959628105,
      -0.05817146226763725,
      0.0047891223803162575,
      0.01803682930767536,
      0.10001426935195923,
      -0.05726347491145134,
      -0.04228333756327629,
      -0.049793556332588196,
      -0.043200720101594925,
      -0.008480284363031387,
      0.00027958888676948845,
      0.0740758404135704,
      0.06382422894239426,
      0.035644255578517914,
      0.07702170312404633,
      0.03014586679637432,
      -0.0679846927523613,
      5.1853236072929576e-05,
      -0.014824071899056435,
      0.05252363532781601,
      -0.05760343745350838
    ],
    [
      -0.06993957608938217,
      -0.04279904440045357,
      -0.04858291894197464,
      0.0382043831050396,
      0.024940235540270805,
      -0.043720681220293045,
      -0.0029022868257015944,
      -0.03152420371770859,
      0.12338928878307343,
      0.07883810251951218,
      0.0008543900330550969,
      0.0012944978661835194,
      0.05634307116270065,
      0.0006060199229978025,
      0.01137633342295885,
      -5.4255779105005786e-05,
      0.027862057089805603,
      -0.029692934826016426,
      0.0007197837112471461,
      -0.0034035718999803066,
      0.05409837141633034,
      -0.05574225261807442,
      0.028872378170490265,
      -0.011956308037042618,
      0.014441960491240025,
      0.043794598430395126,
      0.022531427443027496,
      -0.05303699150681496,
      0.05803828686475754,
      0.01973681151866913,
      0.08885025233030319,
      0.018247563391923904,
      -0.025527674704790115,
      0.006100500002503395,
      -0.09288670867681503,
      0.048600804060697556,
      -0.042111627757549286,
      -0.016354074701666832,
      0.09539011120796204,
      -0.00977255217730999,
      0.03143802285194397,
      -0.032121092081069946,
      0.01994422823190689,
      -0.0359741672873497,
      0.010092745535075665,
      -0.016063878312706947,
      0.04279432073235512,
      -0.04027371481060982,
      0.010162762366235256,
      0.039083901792764664,
      -0.06467417627573013,
      0.04438020661473274,
      0.052548304200172424,
      -0.02157176472246647,
      0.06442032754421234,
      0.06227795407176018,
      -0.0018510576337575912,
      -0.004254984203726053,
      0.03078782930970192,
      0.0678308755159378,
      0.13615407049655914,
      0.02876858413219452,
      -0.050892170518636703,
      0.06166045367717743,
      -0.004433509893715382,
      0.013071921654045582,
      0.004823040217161179,
      -0.0270835030823946,
      -0.027449265122413635,
      -0.09962360560894012,
      -0.022429537028074265,
      0.04747210070490837,
      -0.026619214564561844,
      0.046817075461149216,
      0.016036558896303177,
      -0.05608247593045235,
      0.018302274867892265,
      -0.11158286035060883,
      0.01759856939315796,
      -0.04694665968418121,
      0.029152940958738327,
      -0.033911481499671936,
      -0.018638933077454567,
      -0.007273899391293526,
      0.02313300035893917,
      -0.007829820737242699,
      -0.013338746502995491,
      -0.03063065931200981,
      0.07559134811162949,
      -0.006045245099812746,
      0.06377051025629044,
      0.13263529539108276,
      0.018235931172966957,
      -0.05009281262755394,
      0.08115067332983017,
      0.01640322059392929,
      0.01748347282409668,
      0.008458815515041351,
      -0.04303912818431854,
      -0.05206821858882904,
      0.042032770812511444,
      -0.023082301020622253,
      0.026559140533208847,
      -0.005915005225688219,
      0.024770323187112808,
      -0.05854194983839989,
      0.01346761267632246,
      0.052137795835733414,
      0.003947935067117214,
      -0.032101619988679886,
      0.014993232674896717,
      -0.02069021761417389,
      -0.028810933232307434,
      0.030914299190044403,
      0.029069839045405388,
      -0.02850123681128025,
      -0.012126387096941471,
      0.013083996251225471,
      0.02558496594429016,
      -0.006777781527489424,
      0.00925180222839117,
      0.052494656294584274,
      0.0023707603104412556,
      -0.12716616690158844,
      0.016423704102635384,
      0.048258014023303986,
      -0.04402365908026695,
      -0.08071274310350418,
      0.039073921740055084,
      -0.0026721616741269827,
      0.05284788832068443,
      -0.00033536594128236175,
      -0.06953729689121246,
      -0.02946712076663971,
      -0.06412077695131302,
      0.04164186120033264,
      -0.008879275061190128,
      -0.011623662896454334,
      0.05439940094947815,
      0.048621516674757004,
      0.07868854701519012,
      0.05544726178050041,
      0.01745446026325226,
      0.022329872474074364,
      -0.08633597195148468,
      -0.04312633350491524,
      0.02315453067421913,
      0.015896709635853767,
      -0.02989201247692108,
      0.08189553022384644,
      0.058544766157865524,
      0.039282526820898056,
      -0.030572429299354553,
      -0.003890594933182001,
      -0.014880271628499031,
      -0.010943463072180748,
      0.009220757521688938,
      -0.0566052570939064,
      -0.0173801202327013,
      -0.02548854984343052,
      0.07742425799369812,
      0.025836756452918053,
      -0.03285416215658188,
      -0.041400618851184845,
      0.05387287586927414,
      -0.016964424401521683,
      -0.01389534305781126,
      -0.053092245012521744,
      0.058165181428194046,
      0.02396714687347412,
      -0.0464010015130043,
      -0.01992456242442131,
      -0.0004791738174390048,
      -0.031746041029691696,
      -0.020797882229089737,
      0.04027984291315079,
      -0.04953574016690254,
      -0.0453728549182415,
      0.03142443299293518,
      0.0030370482709258795,
      -0.03317241370677948,
      -0.03581945225596428,
      -0.020892232656478882,
      -0.020918481051921844,
      -0.10995881259441376,
      0.09249572455883026,
      -0.02890252135694027,
      -0.018273817375302315,
      0.0009175873128697276,
      0.04624691605567932,
      0.015500330366194248,
      0.02365555614233017,
      -0.024417804554104805,
      0.0767226368188858,
      -0.089851513504982,
      -0.015118192881345749,
      0.07439228147268295,
      -0.008552431128919125,
      -0.04940059408545494,
      -0.052485473453998566,
      -0.06103822588920593,
      -0.03787602111697197,
      -0.017553675919771194,
      -0.014072893187403679,
      0.03160250186920166,
      0.010818138718605042,
      -0.035455040633678436,
      0.006323200650513172,
      -0.030895154923200607,
      -0.00027163809863850474,
      -0.07169932872056961,
      0.003997985739260912,
      0.026310449466109276,
      0.005630566738545895,
      0.005328670609742403,
      -0.07964492589235306,
      -0.0010702046565711498,
      0.05731258913874626,
      0.0012864480959251523,
      -0.05201272293925285,
      -0.014546364545822144,
      0.054220374673604965,
      -0.05586472153663635,
      -0.018547991290688515,
      0.021931948140263557,
      -0.11591453105211258,
      -0.020893750712275505,
      0.04258715733885765,
      -0.013915589079260826,
      -0.006789584644138813,
      -0.03689267858862877,
      -0.053571127355098724,
      0.06025661528110504,
      0.027600957080721855,
      0.010906532406806946,
      -0.04385263845324516,
      -0.00986032746732235,
      0.0028918934985995293,
      0.02971949242055416,
      -0.03464358299970627,
      0.03842507675290108,
      -0.06303347647190094,
      -0.017928095534443855,
      -0.0928533598780632,
      0.021212423220276833,
      -0.03375232592225075,
      -0.040864065289497375,
      0.09285317361354828,
      -0.035744186490774155,
      0.061664823442697525,
      -0.05879797786474228,
      -0.051923975348472595,
      -0.046051912009716034,
      0.024938395246863365,
      0.06768263876438141,
      -0.002668963512405753,
      0.03747885674238205,
      0.004041306208819151,
      -0.09149706363677979,
      -0.048269838094711304,
      0.0003627319820225239,
      0.014253058470785618,
      0.051468104124069214,
      -0.076107919216156,
      -0.025136379525065422,
      -0.007712238468229771,
      -0.004485704470425844,
      0.041945233941078186,
      -0.04338899999856949,
      0.03496274724602699,
      -0.06425794959068298,
      -0.019514864310622215,
      -0.07820950448513031,
      -0.001142052817158401,
      0.00021108573128003627,
      0.019555114209651947,
      -0.02312321402132511,
      -0.02957838401198387,
      0.04409262537956238,
      -0.01097125094383955,
      -0.047978948801755905,
      -0.07469431310892105,
      0.01891244202852249,
      0.005377823952585459,
      0.0478462353348732,
      -0.00710342125967145,
      0.02250688709318638,
      0.05767308920621872,
      0.04913417994976044,
      -0.09721702337265015,
      -0.005743741057813168,
      -0.055408213287591934,
      0.031328968703746796,
      -0.01812380738556385,
      0.0057033053599298,
      0.026724858209490776,
      0.026408767327666283,
      0.013382594101130962,
      -0.0518401674926281,
      0.03710101917386055,
      0.055562276393175125,
      -0.05477061867713928,
      -0.02989598549902439,
      0.07181038707494736,
      0.03740397468209267,
      -0.020974718034267426,
      -0.018206432461738586,
      0.06798627227544785,
      -0.008304507471621037,
      -0.011481818743050098,
      -0.03245498985052109,
      -0.05621521174907684,
      0.058475300669670105,
      0.011103654280304909,
      -0.058638691902160645,
      0.00029944945708848536,
      -0.12432917952537537,
      -0.042764801532030106,
      0.07520952820777893,
      -0.018790386617183685,
      -0.011830635368824005,
      -0.02363978512585163,
      -0.017127016559243202,
      -0.03548311069607735,
      -0.001750093069858849,
      -0.03952546417713165,
      0.03612738102674484,
      -0.0031616000924259424,
      -0.005830788519233465,
      -0.07756062597036362,
      -0.036860425025224686,
      -0.055185675621032715,
      -0.001968453638255596,
      0.011833125725388527,
      0.027098000049591064,
      0.006244934629648924,
      0.06595084816217422,
      -0.05878281220793724,
      0.12315826117992401,
      0.017310578376054764,
      -0.026798196136951447,
      -0.013241668231785297,
      0.06123342737555504,
      0.06878699362277985,
      0.0706985667347908,
      0.0443589948117733,
      -0.026874596253037453,
      -0.005684227682650089,
      -0.028049875050783157,
      0.05739665403962135,
      0.02449515461921692,
      -0.05763313174247742,
      -0.02976885624229908,
      0.057546064257621765,
      -0.03563913330435753,
      0.05390806123614311,
      0.01291559636592865,
      0.02487190067768097,
      -0.008911153301596642,
      0.02705911174416542,
      0.056108564138412476,
      -0.0007343658362515271,
      -0.009914852678775787,
      -0.07313299924135208,
      -0.0290987491607666,
      0.0031214423943310976,
      -0.03260262310504913,
      0.04225880652666092,
      0.03392341732978821,
      -0.02502789907157421,
      0.08772004395723343,
      0.02803546003997326,
      -0.06788589805364609,
      0.0042968872003257275,
      0.026406148448586464,
      -0.07107695192098618,
      0.01339138112962246,
      0.05390730872750282,
      0.08378853648900986,
      -0.021728767082095146,
      -0.07826078683137894,
      0.03811640664935112,
      -0.020747562870383263,
      -0.03401106968522072,
      -0.07814245671033859,
      0.022646641358733177,
      0.04418361932039261,
      -0.050790149718523026,
      0.05497222766280174,
      0.013266448862850666,
      0.0289869774132967,
      0.02587154507637024,
      -0.06779082119464874,
      0.05641493201255798,
      -0.05010044202208519,
      -0.031376902014017105,
      -0.01645645685493946,
      0.08068152517080307,
      0.03691261261701584,
      -0.037588197737932205,
      -0.015731897205114365,
      -0.02127716690301895,
      -0.009575914591550827,
      -0.020937835797667503,
      -0.07287263870239258,
      0.019665272906422615,
      -0.03938305377960205,
      -0.01697922870516777,
      -0.026253975927829742,
      0.06280376017093658,
      0.005417292471975088,
      0.1040460616350174,
      0.005490046460181475,
      0.0636594146490097,
      0.018521219491958618,
      -0.04750453680753708,
      -0.04576953127980232,
      0.07259419560432434,
      -0.07463663071393967,
      0.06284317374229431,
      0.026743901893496513,
      -0.08876486122608185,
      -0.019197380170226097,
      -0.02316390536725521,
      0.011928142979741096,
      -0.0605994313955307,
      -0.03865863010287285,
      -0.09407150000333786,
      -0.04184175655245781,
      -0.00837542675435543,
      0.04938708245754242,
      -0.05155525729060173,
      0.006954014766961336,
      -0.013114689849317074,
      0.0162757970392704,
      0.0067447773180902,
      -0.003943528514355421,
      -0.014520336873829365,
      -0.040789153426885605,
      -0.07757151871919632,
      0.028991302475333214,
      -0.023837702348828316,
      0.0214815903455019,
      -0.026593541726469994,
      -0.0004976415075361729,
      -0.008214440196752548,
      0.0021555316634476185,
      0.021029487252235413,
      -0.02187749184668064,
      0.07714100927114487,
      0.05928736925125122,
      0.08357077091932297,
      0.009703177958726883,
      -0.00232441327534616,
      -0.03028780035674572,
      0.027328824624419212,
      -0.04793590307235718,
      -0.055990368127822876,
      -0.01825793646275997,
      -0.0509035624563694,
      0.06176375597715378,
      0.0501018688082695,
      -0.023449739441275597,
      0.07267535477876663,
      -0.017161382362246513,
      0.02629869244992733,
      0.02272580750286579,
      0.035757895559072495,
      0.03984508663415909,
      -0.08032853901386261,
      -0.06987108290195465,
      0.020159903913736343,
      0.042151592671871185,
      0.052971772849559784,
      -0.05498458817601204,
      0.053829532116651535,
      0.015087706968188286,
      -0.062054429203271866,
      0.002243081806227565,
      -0.0428667813539505,
      0.03640148416161537,
      0.09254471212625504,
      0.007219428662210703,
      -0.0002603810280561447,
      -0.05536150932312012,
      -0.012402961030602455,
      -0.021730810403823853,
      -0.007747052237391472,
      0.049157362431287766,
      -0.02243591472506523,
      0.03338848426938057,
      0.007644304074347019,
      -0.011193078942596912,
      -0.003819525707513094,
      -0.029739079996943474,
      -0.09666451066732407,
      0.045665085315704346,
      -0.0018078346038237214,
      -0.03949953615665436,
      -0.04358776658773422,
      -0.06241442263126373,
      0.04931328073143959,
      -0.011670801788568497
    ],
    [
      -0.020922547206282616,
      0.06976258754730225,
      0.01959429495036602,
      -0.01256824191659689,
      -0.024696987122297287,
      0.06989959627389908,
      -0.02318841777741909,
      -0.03346831724047661,
      -0.015210989862680435,
      0.006360085215419531,
      0.04632839933037758,
      -0.041969459503889084,
      0.04183470085263252,
      -0.014462543651461601,
      -0.09519576281309128,
      -0.06870304048061371,
      -0.01809770055115223,
      0.009739530272781849,
      -0.07308174669742584,
      0.014148857444524765,
      -0.006663224194198847,
      0.02807505987584591,
      0.07416578382253647,
      0.04580503702163696,
      -0.03266618773341179,
      0.07483948022127151,
      -0.13200914859771729,
      -0.004618848208338022,
      -0.07706467807292938,
      -0.06384122371673584,
      0.04106355831027031,
      -0.028200911357998848,
      0.02833370491862297,
      -0.019773133099079132,
      0.017634041607379913,
      -0.023997219279408455,
      0.045303866267204285,
      -0.021785501390695572,
      0.00046712320181541145,
      -0.03968273103237152,
      0.012655369006097317,
      -0.02732182666659355,
      -0.04093160107731819,
      -0.013118993490934372,
      -0.05627870932221413,
      -0.03314269334077835,
      -0.008024289272725582,
      0.07482324540615082,
      0.01605408266186714,
      -0.043482523411512375,
      -0.03438958898186684,
      -0.04916940629482269,
      -0.061621323227882385,
      -0.011183436028659344,
      -0.036261461675167084,
      -0.06228620931506157,
      -0.09983155876398087,
      0.007546946872025728,
      0.0019854046404361725,
      -0.001452955068089068,
      0.00483937980607152,
      -0.0018501579761505127,
      0.02544918656349182,
      -0.057121310383081436,
      0.052857354283332825,
      -0.01045699417591095,
      0.008640672080218792,
      0.05993176996707916,
      0.022878000512719154,
      -0.004097761120647192,
      0.05501900985836983,
      0.0401398241519928,
      -0.08403702825307846,
      0.047721169888973236,
      -0.04417519271373749,
      -0.023254085332155228,
      0.031034113839268684,
      -0.0021530964877456427,
      -0.07181623578071594,
      0.07192389667034149,
      0.051249198615550995,
      0.02483072318136692,
      0.015321474522352219,
      -0.03678789362311363,
      0.012379704974591732,
      -0.01646990142762661,
      -0.03440132364630699,
      -0.018599683418869972,
      0.002306448295712471,
      -0.018007025122642517,
      0.008543047122657299,
      -0.007549492176622152,
      0.06130140647292137,
      -0.046886131167411804,
      0.02731144241988659,
      -0.07105277478694916,
      0.06171000748872757,
      0.0057454840280115604,
      0.0076459781266748905,
      -0.06665565818548203,
      -0.06017287075519562,
      -0.03184164687991142,
      -0.021991349756717682,
      -0.050113022327423096,
      -0.021474916487932205,
      0.041785430163145065,
      -0.020971834659576416,
      0.07972858101129532,
      0.012975618243217468,
      0.02365221455693245,
      0.05308986455202103,
      -0.04502524062991142,
      0.012843017466366291,
      0.004648326430469751,
      -0.08633340895175934,
      -0.08131105452775955,
      -0.015083168633282185,
      0.045140765607357025,
      -0.04930851235985756,
      0.024794066324830055,
      0.03427169844508171,
      0.027736643329262733,
      0.030323579907417297,
      -0.03377491980791092,
      0.017401695251464844,
      0.10085040330886841,
      0.006660971790552139,
      -0.03084747865796089,
      -0.01325046457350254,
      0.01474000420421362,
      0.009711593389511108,
      -0.0024405887816101313,
      0.07484983652830124,
      0.04165496304631233,
      -0.03503134474158287,
      -0.012476937845349312,
      0.03832538425922394,
      -0.07766328752040863,
      -0.019630463793873787,
      0.03253826126456261,
      -0.04570617526769638,
      0.06907248497009277,
      0.06967826187610626,
      -0.03448858484625816,
      0.02598097361624241,
      0.0027780774980783463,
      0.022901061922311783,
      0.0578920803964138,
      0.05633041262626648,
      -0.12450174242258072,
      0.028186537325382233,
      0.06054002046585083,
      -0.14709658920764923,
      0.01721850223839283,
      -0.029099466279149055,
      -0.008176647126674652,
      -0.02484438568353653,
      0.04470449313521385,
      -0.07738775759935379,
      0.04858949035406113,
      0.017430035397410393,
      0.038922373205423355,
      -0.02347404882311821,
      -0.006813532672822475,
      0.003876060014590621,
      -0.044967181980609894,
      0.03896600380539894,
      -0.017140386626124382,
      0.0029096726793795824,
      0.02172141894698143,
      -0.007454149425029755,
      0.0354694202542305,
      0.07198392599821091,
      0.010596062056720257,
      -0.030927564948797226,
      -0.09154651314020157,
      0.028750836849212646,
      0.09219105541706085,
      0.05179929733276367,
      0.010822474025189877,
      -0.027450209483504295,
      -0.07294254750013351,
      -0.020370740443468094,
      0.05783093720674515,
      -0.057521626353263855,
      -0.10214763879776001,
      -0.022874455899000168,
      -0.09789374470710754,
      0.07585561275482178,
      -0.053380537778139114,
      -0.059180211275815964,
      0.0011285522487014532,
      0.030205953866243362,
      0.005869145970791578,
      0.0556354746222496,
      -0.06563099473714828,
      0.11358517408370972,
      0.04731672257184982,
      -0.02845829352736473,
      -0.06543280184268951,
      -0.08525174856185913,
      0.02970210462808609,
      -0.01314801350235939,
      0.04782155528664589,
      -0.037446267902851105,
      0.006348839029669762,
      -0.02782628871500492,
      0.03935713320970535,
      -0.08570703119039536,
      -0.020202070474624634,
      0.00042351058800704777,
      0.030121980234980583,
      0.0010002495255321264,
      0.0389401949942112,
      -0.024294085800647736,
      0.01904415898025036,
      0.04646165668964386,
      0.10121642798185349,
      -0.021042460575699806,
      -0.014537420123815536,
      0.0016701818676665425,
      0.03892100974917412,
      -0.05992044508457184,
      -0.06583305448293686,
      -0.01766534149646759,
      -0.05171012133359909,
      -0.011087288148701191,
      0.03335806727409363,
      0.08625912666320801,
      -0.08891233056783676,
      0.03192055597901344,
      0.10765767842531204,
      0.11450301110744476,
      0.0031959041953086853,
      -0.008573398925364017,
      -0.07253321260213852,
      -0.0054275295697152615,
      0.050284914672374725,
      0.071566142141819,
      -0.09249706566333771,
      -0.029035063460469246,
      0.03585359826683998,
      -0.03498680144548416,
      -0.09304739534854889,
      -0.05914957448840141,
      -0.016920801252126694,
      0.009432969614863396,
      0.04723076522350311,
      0.012069834396243095,
      0.02751092053949833,
      0.07285431772470474,
      -0.04188524931669235,
      0.060635507106781006,
      0.07817695289850235,
      -0.04696429520845413,
      -0.04981084540486336,
      -0.0007121965172700584,
      -0.025354620069265366,
      0.03786433860659599,
      -0.03145357966423035,
      -0.05209948867559433,
      0.05444846302270889,
      0.06464829295873642,
      -0.023141611367464066,
      -0.010437946766614914,
      -0.031814854592084885,
      -0.004731214605271816,
      0.0006663489039056003,
      -0.005175480153411627,
      -0.01574333943426609,
      0.05654770880937576,
      -0.045548904687166214,
      -0.04572206735610962,
      -0.02690078690648079,
      -0.022851021960377693,
      -0.05439719557762146,
      -0.04940680041909218,
      0.021373454481363297,
      -0.004081616643816233,
      0.03997522220015526,
      -0.005535237491130829,
      0.04820562154054642,
      0.013032414019107819,
      0.015683118253946304,
      -0.09820258617401123,
      -0.013406259939074516,
      0.010390310548245907,
      0.014744225889444351,
      0.1052362471818924,
      0.021522516384720802,
      0.010476159863173962,
      -0.019001856446266174,
      -0.09599168598651886,
      0.061865657567977905,
      0.01845010742545128,
      -0.0721665695309639,
      0.001543925842270255,
      -0.03807314857840538,
      0.011837122961878777,
      0.01995910331606865,
      -0.008973835036158562,
      0.013096350245177746,
      -0.03388485684990883,
      -0.034234389662742615,
      -0.0089490357786417,
      -0.045321885496377945,
      -0.04652441293001175,
      0.036932505667209625,
      0.03314843028783798,
      -0.05271057039499283,
      0.00855061411857605,
      -0.055600617080926895,
      -0.059634026139974594,
      -0.056362587958574295,
      -0.005007738713175058,
      -0.008190594613552094,
      -0.061903029680252075,
      0.01602850668132305,
      -0.04122757166624069,
      -0.0003742660628631711,
      -0.03670620918273926,
      0.04477492347359657,
      -0.006190660875290632,
      -0.005277868360280991,
      0.015213128179311752,
      -0.012227500788867474,
      0.01952008716762066,
      0.09748996794223785,
      0.0481860414147377,
      -0.058761049062013626,
      -0.020185206085443497,
      -0.009040762670338154,
      0.0038292573299258947,
      -0.026247041299939156,
      -0.01486210897564888,
      -0.11442035436630249,
      -0.006983701139688492,
      -0.12933380901813507,
      -0.08255483210086823,
      -0.08632118254899979,
      0.007766683120280504,
      0.06563831120729446,
      -0.018259812146425247,
      0.014284307137131691,
      -0.0638793334364891,
      0.04633288457989693,
      0.008650512434542179,
      0.0031911509577184916,
      -0.042990852147340775,
      0.004926309455186129,
      0.020594215020537376,
      0.021025195717811584,
      0.04523959010839462,
      -0.02639085054397583,
      -0.026823177933692932,
      -0.09429070353507996,
      -0.02247060090303421,
      -0.025281202048063278,
      0.02652251534163952,
      0.023005817085504532,
      0.049656566232442856,
      -0.003816333832219243,
      0.0363965705037117,
      0.030291536822915077,
      0.09170723706483841,
      0.0735819935798645,
      0.0013566042762249708,
      0.006656078156083822,
      0.031593192368745804,
      -0.008893662132322788,
      0.028890913352370262,
      -0.011678467504680157,
      0.07065414637327194,
      0.040955156087875366,
      -0.06501445919275284,
      -0.028298193588852882,
      0.003814508207142353,
      -0.02808539569377899,
      -0.04164911434054375,
      0.028326116502285004,
      0.018984900787472725,
      -0.04762644320726395,
      -0.011305855587124825,
      0.09935243427753448,
      0.05897429212927818,
      0.03234493359923363,
      0.03966232389211655,
      0.0028545681852847338,
      -0.013270797207951546,
      0.04473129287362099,
      0.04295319691300392,
      -0.03222089633345604,
      -0.057685986161231995,
      0.02504422888159752,
      -0.011382280848920345,
      -0.03873752802610397,
      -0.06910192221403122,
      -0.02543875202536583,
      0.03022989258170128,
      -0.08206374198198318,
      -0.028707044199109077,
      0.02806081436574459,
      -0.0595567412674427,
      0.043388571590185165,
      -0.03820086643099785,
      -0.009579199366271496,
      0.039336442947387695,
      0.0699363723397255,
      0.04389490187168121,
      0.10069538652896881,
      -0.0070327394641935825,
      0.01423693634569645,
      -0.09340102225542068,
      0.06154581904411316,
      0.07917337864637375,
      -0.010462542064487934,
      0.05826125293970108,
      0.028574159368872643,
      -0.06412018090486526,
      0.07489049434661865,
      -0.020238619297742844,
      0.0067796227522194386,
      0.054326750338077545,
      -0.0454997792840004,
      -0.03534463420510292,
      0.014131668023765087,
      0.04702937230467796,
      0.0027864710427820683,
      0.06682153046131134,
      0.05002046748995781,
      0.026398397982120514,
      0.005864117294549942,
      -0.012325524352490902,
      0.03937463089823723,
      -0.05997433140873909,
      -0.016071807593107224,
      0.030008869245648384,
      0.04238466918468475,
      0.0035951484460383654,
      0.01730705238878727,
      0.017637701705098152,
      -0.033412374556064606,
      -0.023855803534388542,
      0.05703967437148094,
      -0.03300899267196655,
      0.03859196603298187,
      0.08368878066539764,
      0.01757732778787613,
      -0.027914760634303093,
      -0.06886202841997147,
      -0.02383316680788994,
      -0.003166613867506385,
      0.06574387848377228,
      0.007777929771691561,
      -0.002784781390801072,
      0.020082009956240654,
      0.03912387415766716,
      0.049179840832948685,
      0.09996552020311356,
      0.04795089364051819,
      -0.016798948869109154,
      0.01237640343606472,
      -0.08449926972389221,
      0.06616359949111938,
      0.009813709184527397,
      -0.04254043847322464,
      0.043678730726242065,
      -0.059835709631443024,
      0.05947188287973404,
      0.006080661900341511,
      -0.016674963757395744,
      -0.049995675683021545,
      -0.023595517501235008,
      -0.03091537393629551,
      -0.07114990800619125,
      -0.04736451432108879,
      0.003643903648480773,
      0.0038877837359905243,
      0.0721290335059166,
      0.03529532998800278,
      -0.019287552684545517,
      0.0689825639128685,
      -0.0030249322298914194,
      -0.033218882977962494,
      0.07110758870840073,
      0.0772470235824585,
      -0.028194060549139977,
      -0.03729059547185898,
      0.010617823339998722,
      0.006844882853329182,
      -0.06462106108665466,
      -0.001692674937658012,
      0.06716904044151306,
      -0.009377667680382729,
      -0.06772308051586151,
      -0.02817254327237606,
      0.03398972749710083,
      0.007141896989196539,
      0.07218161225318909,
      0.08817818015813828,
      -0.049008555710315704,
      -0.01509606558829546,
      0.041442111134529114
    ],
    [
      -0.07093282043933868,
      0.035830751061439514,
      0.007622929755598307,
      0.052706290036439896,
      0.01967160589993,
      0.03591815009713173,
      -0.03739190474152565,
      -0.030135536566376686,
      -0.01937970519065857,
      0.0034350138157606125,
      -0.026381947100162506,
      -0.004627011716365814,
      -0.033400166779756546,
      0.03285420686006546,
      0.05043531581759453,
      -0.035843007266521454,
      0.038471922278404236,
      -0.04416453465819359,
      -0.018390601500868797,
      0.04108929634094238,
      0.007551451213657856,
      0.03557758033275604,
      -0.049283772706985474,
      -0.02662908285856247,
      -0.035436712205410004,
      0.011795018799602985,
      0.015848176553845406,
      0.04976637661457062,
      -0.03768656402826309,
      -0.08410464227199554,
      0.008620807901024818,
      -0.00907615665346384,
      0.05228424444794655,
      -0.02176021784543991,
      -0.006756261922419071,
      -0.05125180259346962,
      -0.028733517974615097,
      0.03733295574784279,
      -0.024092888459563255,
      -0.04633824899792671,
      -0.04464064911007881,
      -0.05983990803360939,
      -0.013500889763236046,
      -0.022529801353812218,
      0.012245457619428635,
      0.006455118767917156,
      0.005155212711542845,
      0.03549464792013168,
      -0.0438627265393734,
      0.0953194871544838,
      0.024726850911974907,
      0.023616960272192955,
      -0.009741979651153088,
      -0.009113547392189503,
      -0.03548505902290344,
      0.013371055014431477,
      0.0373307541012764,
      0.11052339524030685,
      -0.07271677255630493,
      -0.005972183309495449,
      -0.04995067045092583,
      0.10266534239053726,
      0.04894388094544411,
      -0.05632593855261803,
      -0.1071741059422493,
      -0.036438245326280594,
      0.0364004485309124,
      0.10643680393695831,
      -0.012913530692458153,
      -0.041240300983190536,
      -0.022081930190324783,
      0.026942435652017593,
      -0.011636758223176003,
      0.005783213302493095,
      -0.02518891729414463,
      -0.021277330815792084,
      -0.005530678201466799,
      0.023733867332339287,
      -0.02731150947511196,
      0.047922682017087936,
      -0.05459361523389816,
      0.041250210255384445,
      -0.015081854537129402,
      -0.008177506737411022,
      -0.06312242150306702,
      -0.029542764648795128,
      0.05329720303416252,
      -0.03330467641353607,
      0.06084679067134857,
      -0.031981971114873886,
      -0.0007214611978270113,
      0.021945059299468994,
      0.0606122724711895,
      -0.03026508167386055,
      0.022930487990379333,
      -0.0812627449631691,
      0.03232595697045326,
      -0.05824727565050125,
      0.003710552817210555,
      -0.046711139380931854,
      0.050212059170007706,
      -0.0012290466111153364,
      -0.05335744097828865,
      0.03320379555225372,
      0.08814211189746857,
      0.0601661279797554,
      -0.0740702822804451,
      0.07455025613307953,
      -0.012654208578169346,
      0.10242652148008347,
      0.04045558348298073,
      -0.024193942546844482,
      0.00893297791481018,
      0.042821258306503296,
      -0.013528364710509777,
      -0.004238131921738386,
      0.057980138808488846,
      -0.022312041372060776,
      0.03635769337415695,
      0.03591446951031685,
      0.028989629819989204,
      -0.04907262697815895,
      0.017965208739042282,
      -0.05492459982633591,
      0.0067969197407364845,
      0.035922396928071976,
      -0.04154421389102936,
      -0.007074268069118261,
      0.031445253640413284,
      -0.08449196815490723,
      0.04773542284965515,
      0.012294267304241657,
      0.005334937945008278,
      0.006300589069724083,
      0.0022834811825305223,
      0.06598137319087982,
      -0.03940027207136154,
      -0.09672530740499496,
      0.06746066361665726,
      0.010842997580766678,
      -0.026516180485486984,
      0.03119521588087082,
      0.017462942749261856,
      -0.06904963403940201,
      -0.0028763406444340944,
      -0.05226059630513191,
      0.05487673729658127,
      -0.05087282881140709,
      0.09635616838932037,
      0.002212026622146368,
      -0.008304556831717491,
      0.07443028688430786,
      -0.07167670130729675,
      0.010307051241397858,
      0.059917937964200974,
      -0.00682045379653573,
      -0.003185819834470749,
      0.01328092347830534,
      -0.021784286946058273,
      -0.01501697301864624,
      -0.017531057819724083,
      -0.06163902208209038,
      -0.08743347972631454,
      -0.014665588736534119,
      -0.00012312285252846777,
      -0.047841351479291916,
      -0.0161956325173378,
      -0.008733255788683891,
      0.057075563818216324,
      -0.006522403098642826,
      -0.06303541362285614,
      0.021312376484274864,
      0.02253742329776287,
      0.004018557257950306,
      0.04553545266389847,
      -0.022650742903351784,
      -0.008955094963312149,
      -0.05406846106052399,
      -0.12715227901935577,
      -0.0567048080265522,
      -0.03762108460068703,
      0.04036584869027138,
      0.03311691805720329,
      0.041765518486499786,
      0.013839684426784515,
      -0.015836568549275398,
      0.0338888056576252,
      -0.0403340682387352,
      0.003493483178317547,
      -0.04897811636328697,
      0.019210729748010635,
      -0.03704669699072838,
      -0.005548278801143169,
      -0.02785860188305378,
      0.019567780196666718,
      -0.005947964731603861,
      -0.0020327584352344275,
      -0.05251661688089371,
      -0.015151550062000751,
      -0.03863111138343811,
      -0.04065701737999916,
      0.024922113865613937,
      -0.002483544871211052,
      0.04418836906552315,
      0.050944965332746506,
      -0.03056316263973713,
      -0.051443032920360565,
      -0.039205342531204224,
      0.002418996300548315,
      0.0491466224193573,
      -0.025666048750281334,
      0.027500616386532784,
      -0.06460682302713394,
      -0.010455230250954628,
      -0.08288008719682693,
      0.0274740569293499,
      -0.01719159074127674,
      0.08328458666801453,
      -0.06614870578050613,
      -0.0049464027397334576,
      -0.03236253932118416,
      0.02089531347155571,
      -0.022350333631038666,
      -0.03652934357523918,
      -0.003728054231032729,
      0.014697939157485962,
      -0.06386493891477585,
      0.03577981889247894,
      -0.07355573028326035,
      0.00308608659543097,
      0.04273135960102081,
      0.0063392166048288345,
      -0.01483667641878128,
      -0.02369297482073307,
      0.016462154686450958,
      -0.049918416887521744,
      -0.04342150688171387,
      0.03202461451292038,
      0.016877278685569763,
      -0.03088122420012951,
      -0.04411190003156662,
      0.02472887746989727,
      0.011163320392370224,
      0.010723751969635487,
      -0.08516520261764526,
      -0.026298921555280685,
      0.01992974802851677,
      0.014147922396659851,
      0.012272501364350319,
      0.0024086234625428915,
      0.05409165471792221,
      -0.02291053906083107,
      0.09205399453639984,
      -0.028754767030477524,
      0.016822025179862976,
      0.06582556664943695,
      -0.07232163101434708,
      -0.01339811086654663,
      -0.005692676641047001,
      -0.0725133940577507,
      -0.024720311164855957,
      0.077543705701828,
      0.011714660562574863,
      -0.008577545173466206,
      0.04201867803931236,
      -0.04603685438632965,
      -0.028007006272673607,
      0.005863678175956011,
      0.010430175811052322,
      -0.04735082760453224,
      -0.057378288358449936,
      0.0369098000228405,
      -0.02344624325633049,
      0.07756491750478745,
      0.008760688826441765,
      -0.035388294607400894,
      -0.0199398435652256,
      -0.008769425563514233,
      -0.027595577761530876,
      -0.04202920198440552,
      -0.08259937912225723,
      -0.033015843480825424,
      0.12641103565692902,
      0.0010502865770831704,
      -0.04190162569284439,
      -0.003111675614491105,
      0.030609672889113426,
      0.04329034313559532,
      -0.05712336301803589,
      -0.029861586168408394,
      -0.02495138719677925,
      -0.09602779895067215,
      0.03154931962490082,
      -0.0014564900193363428,
      0.034308061003685,
      0.012451178394258022,
      0.04212838411331177,
      -0.06621537357568741,
      -0.049650657922029495,
      0.035479214042425156,
      -0.07292807847261429,
      -0.008985704742372036,
      0.013336735777556896,
      0.03340954706072807,
      9.493049219599925e-06,
      -0.02033756673336029,
      -0.00648420350626111,
      -0.08183883875608444,
      -0.010598459281027317,
      -0.03054528310894966,
      -0.008616957813501358,
      -0.08059801161289215,
      -0.00845994520932436,
      0.08146441727876663,
      -0.013650769367814064,
      -0.003620277624577284,
      -0.08134600520133972,
      0.03745647519826889,
      0.022981718182563782,
      -0.026296434924006462,
      -0.05811519920825958,
      0.01831033080816269,
      -0.03767838701605797,
      -0.013984410092234612,
      0.04151061922311783,
      -0.006589948665350676,
      -0.018815340474247932,
      0.03459107130765915,
      0.02351057343184948,
      -0.03491275757551193,
      -0.04575889930129051,
      0.0009832876967266202,
      0.05773434415459633,
      -0.006144708953797817,
      0.06515861302614212,
      0.042602989822626114,
      0.0232884231954813,
      -0.009716027416288853,
      -0.047255780547857285,
      0.053302548825740814,
      0.07208595424890518,
      0.044117867946624756,
      -0.009752961806952953,
      0.038716092705726624,
      -0.01077224500477314,
      -0.0661066323518753,
      -0.04237176477909088,
      0.045695140957832336,
      0.07347997277975082,
      -0.05648397281765938,
      0.08242437243461609,
      0.02523637004196644,
      -0.028500491753220558,
      -0.0030709903221577406,
      -0.009405244141817093,
      0.04842199757695198,
      0.022181205451488495,
      -0.00137526192702353,
      0.021733464673161507,
      0.01813555508852005,
      -0.033810555934906006,
      -0.03255686163902283,
      -0.03073902614414692,
      0.04315612092614174,
      -0.042981669306755066,
      0.021244507282972336,
      -0.04873526841402054,
      -0.03645937144756317,
      0.022013019770383835,
      -0.02931739017367363,
      0.09077166765928268,
      -0.01472827885299921,
      -0.033316172659397125,
      0.02785937860608101,
      -0.025669600814580917,
      -0.02295699529349804,
      0.05049506947398186,
      0.023149263113737106,
      0.024733511731028557,
      -0.014556021429598331,
      -0.038580190390348434,
      0.01581084355711937,
      0.10400289297103882,
      0.010842479765415192,
      0.005906546022742987,
      0.04690931737422943,
      -0.026498904451727867,
      -0.048532962799072266,
      0.034401785582304,
      0.0038106576539576054,
      0.03427297621965408,
      -0.052967965602874756,
      -0.018398534506559372,
      0.1049981638789177,
      0.034963205456733704,
      0.01425012294203043,
      0.031070495024323463,
      0.017430027946829796,
      -0.0317482128739357,
      -0.02786632627248764,
      0.03321722149848938,
      0.09556132555007935,
      -0.0325058251619339,
      -0.0259865652769804,
      -0.06373012810945511,
      0.0031181948725134134,
      -0.007642512675374746,
      0.035452764481306076,
      0.026810981333255768,
      0.06606036424636841,
      -0.07570850104093552,
      0.023858051747083664,
      -0.03239452838897705,
      0.042453549802303314,
      -0.03977254033088684,
      0.025034168735146523,
      -0.0410650297999382,
      0.03395336866378784,
      -0.004363927990198135,
      0.040219053626060486,
      -0.022258078679442406,
      -0.025138063356280327,
      0.006121466401964426,
      0.04160922020673752,
      -0.010454357601702213,
      -0.06198649853467941,
      -0.021015066653490067,
      -0.043586213141679764,
      -0.08607955276966095,
      -0.02708403393626213,
      0.09686693549156189,
      0.037367142736911774,
      0.040198925882577896,
      0.022802961990237236,
      0.022175556048750877,
      -0.030074909329414368,
      -0.005441341549158096,
      0.07028675079345703,
      -0.06817247718572617,
      -0.05001688376069069,
      0.07223179936408997,
      -0.08639069646596909,
      -0.057199861854314804,
      -0.00456829322502017,
      0.0342039130628109,
      -0.03493150323629379,
      0.10041031986474991,
      -0.03541824221611023,
      0.02800731733441353,
      -0.030715305358171463,
      0.03450567275285721,
      -0.00555682647973299,
      -0.034868646413087845,
      -0.015451014041900635,
      -0.06750462204217911,
      -0.022881420329213142,
      -0.06517454236745834,
      0.008188060484826565,
      0.02033134363591671,
      0.053214315325021744,
      0.030427785590291023,
      0.03760441392660141,
      -0.0013830026146024466,
      0.040415871888399124,
      -0.08909264206886292,
      0.02307349257171154,
      -0.04091699421405792,
      -0.03926308825612068,
      0.04542047530412674,
      -0.036777280271053314,
      -0.014019971713423729,
      0.0029572192579507828,
      0.04405159130692482,
      0.07112181931734085,
      -0.017107300460338593,
      -0.020449325442314148,
      0.04571188986301422,
      -0.05643799528479576,
      -0.0334947444498539,
      0.0001251709327334538,
      0.058978840708732605,
      0.032605063170194626,
      0.04195079579949379,
      0.027812078595161438,
      0.062724269926548,
      -0.009528578259050846,
      0.031052477657794952,
      0.003557487390935421,
      -0.0071396720595657825,
      0.034256886690855026,
      0.04807078093290329,
      0.022243859246373177,
      0.0603182390332222,
      0.07558006793260574,
      0.03696971759200096,
      -0.015547855757176876,
      0.039102695882320404,
      0.03807484731078148,
      0.010986783541738987,
      -0.03236597403883934,
      -0.03817182034254074,
      0.031088655814528465,
      0.09620268642902374
    ],
    [
      0.003563550068065524,
      -0.08529940992593765,
      -0.04594551771879196,
      0.04578285291790962,
      0.026897627860307693,
      0.0047002132050693035,
      0.014867902733385563,
      0.11610400676727295,
      0.01795794628560543,
      -0.02058202214539051,
      0.03652876988053322,
      0.009218242950737476,
      0.00858114194124937,
      -0.06787978112697601,
      0.027517706155776978,
      0.03012639470398426,
      0.03139602020382881,
      0.048340391367673874,
      0.0491701103746891,
      0.04298480227589607,
      -0.0338420532643795,
      0.022120051085948944,
      -0.0454566515982151,
      0.0422196127474308,
      -0.025962423533201218,
      0.0298051368445158,
      0.025154419243335724,
      0.07128802686929703,
      -0.016936637461185455,
      -0.0626753568649292,
      0.0751880332827568,
      -0.001061394577845931,
      -0.029305975884199142,
      -0.041816484183073044,
      -0.002182100433856249,
      -0.02160434052348137,
      -0.0255757924169302,
      -0.07347021996974945,
      0.0013061144854873419,
      0.00990216713398695,
      -0.004705049563199282,
      -0.08774569630622864,
      -0.032046422362327576,
      0.05461660400032997,
      0.02863486111164093,
      0.09354688972234726,
      -0.06898879259824753,
      0.012149033136665821,
      -0.043853968381881714,
      0.017019053921103477,
      -0.09652452915906906,
      -0.01659158244729042,
      -0.005085011012852192,
      -0.012628652155399323,
      0.011760715395212173,
      0.008853313513100147,
      0.04815416410565376,
      0.08954115211963654,
      0.05599081516265869,
      0.010339789092540741,
      -0.04986298829317093,
      -0.058128420263528824,
      -0.026732608675956726,
      -0.025867274031043053,
      -0.00874886941164732,
      0.03768893703818321,
      -0.037973735481500626,
      -0.015490694902837276,
      -0.05352076143026352,
      -0.062235038727521896,
      -0.09519033133983612,
      0.025774601846933365,
      0.0539214126765728,
      -0.04526927322149277,
      0.0328955203294754,
      -0.031708523631095886,
      0.07295944541692734,
      -0.08774953335523605,
      0.009365485981106758,
      0.01564178615808487,
      -0.02159195765852928,
      -0.04438534751534462,
      0.05728080868721008,
      -0.050490036606788635,
      0.051939044147729874,
      -0.029683871194720268,
      0.08699693530797958,
      -0.04652068391442299,
      0.12501540780067444,
      0.048064328730106354,
      0.025723649188876152,
      0.04491420090198517,
      -0.04945793002843857,
      0.049534332007169724,
      0.01263614185154438,
      0.020341889932751656,
      -0.04430302232503891,
      0.01728249154984951,
      -0.04888749122619629,
      -0.022113531827926636,
      0.05105564370751381,
      -0.007729548029601574,
      -0.023221014067530632,
      0.030839981511235237,
      0.05812809616327286,
      -0.04007938504219055,
      0.025450630113482475,
      0.03577904775738716,
      0.054350774735212326,
      0.12821747362613678,
      0.030175188556313515,
      0.02906983345746994,
      -0.06478534638881683,
      -0.034261636435985565,
      0.032881926745176315,
      0.03694857656955719,
      -0.02317471243441105,
      0.04340768977999687,
      0.04357026517391205,
      0.02701353095471859,
      -0.020311739295721054,
      -0.08028203994035721,
      0.10133641213178635,
      -0.06290598958730698,
      -0.08381710201501846,
      0.004386307206004858,
      0.07002228498458862,
      0.06079527735710144,
      0.001893877750262618,
      0.03273538127541542,
      0.02374703250825405,
      0.04123480245471001,
      -0.05884416401386261,
      0.01268178690224886,
      -0.05788988992571831,
      -0.04728155955672264,
      -0.030708646401762962,
      -0.05687401443719864,
      0.07503462582826614,
      0.009695244953036308,
      0.05577310174703598,
      0.024366963654756546,
      -0.03203437477350235,
      -0.0012188267428427935,
      0.0007962319650687277,
      0.12093804031610489,
      -0.09365281462669373,
      0.0033177572768181562,
      0.029160918667912483,
      0.0501631535589695,
      0.01815163716673851,
      0.0019196560606360435,
      -0.021347366273403168,
      0.04775012657046318,
      0.015517937950789928,
      0.022281603887677193,
      0.011196105740964413,
      -0.0008849608129821718,
      -0.07978477329015732,
      0.0014615985564887524,
      0.07629715651273727,
      0.07548083364963531,
      0.048917971551418304,
      0.0035369046963751316,
      0.05449245497584343,
      -0.07496211677789688,
      0.022965479642152786,
      0.06304073333740234,
      0.01204626727849245,
      -0.08955778181552887,
      0.03407370299100876,
      -0.03769124299287796,
      0.018864121288061142,
      -0.07083296775817871,
      0.05999113246798515,
      -0.04508568346500397,
      -0.05496205762028694,
      0.026036391034722328,
      -0.06098996847867966,
      0.029941650107502937,
      0.040691688656806946,
      -0.032826948910951614,
      0.07661109417676926,
      -0.06092971935868263,
      -0.009318767115473747,
      -0.05877832695841789,
      0.010639174841344357,
      -0.0004756028938572854,
      -0.013902837410569191,
      0.07527780532836914,
      -0.03449618071317673,
      0.0236044954508543,
      0.03133885934948921,
      -0.07680074125528336,
      -0.005854445043951273,
      -0.03722553700208664,
      -0.02442852593958378,
      0.13090205192565918,
      0.03554518520832062,
      -0.012081429362297058,
      -0.08275075256824493,
      -0.01593058928847313,
      -0.08530781418085098,
      0.025362884625792503,
      0.039808742702007294,
      -0.03649259731173515,
      0.0821174830198288,
      0.03766484558582306,
      -0.09003596752882004,
      0.014215929433703423,
      -0.016958586871623993,
      0.05489809438586235,
      -0.06379427015781403,
      0.059661511331796646,
      -0.033290840685367584,
      -0.02351185865700245,
      0.020315229892730713,
      0.014574412256479263,
      0.014271914958953857,
      -0.08091725409030914,
      0.058845553547143936,
      0.08634008467197418,
      -0.017990659922361374,
      -0.03253479674458504,
      0.002827749587595463,
      0.06696717441082001,
      -0.07508666068315506,
      -0.0725170224905014,
      0.06881169229745865,
      -0.01833982579410076,
      -0.027004176750779152,
      0.03468560427427292,
      0.04653622955083847,
      0.10209813714027405,
      -0.001575118163600564,
      0.012396088801324368,
      0.014821229502558708,
      0.009017200209200382,
      -0.03973041847348213,
      0.03699998930096626,
      -0.0014489785535261035,
      0.03136134520173073,
      -0.03760470077395439,
      0.028477400541305542,
      -0.044675763696432114,
      -0.026730824261903763,
      -0.03650834783911705,
      -0.023941801860928535,
      -0.017503799870610237,
      -0.09234205633401871,
      -0.011324526742100716,
      -0.022331612184643745,
      -0.0390787348151207,
      -0.05899344012141228,
      -0.02852119691669941,
      0.01364697515964508,
      0.021075380966067314,
      -0.008254940621554852,
      -0.02621031366288662,
      0.07774746417999268,
      0.018073974177241325,
      0.009693561121821404,
      0.04831784963607788,
      -0.05561671033501625,
      0.08313398063182831,
      0.053471025079488754,
      -0.041340172290802,
      -0.02018798515200615,
      -0.00944612268358469,
      0.004852554760873318,
      0.028392145410180092,
      0.06745189428329468,
      0.0037866828497499228,
      -0.043926630169153214,
      -0.04378439113497734,
      0.07094620168209076,
      0.04614109918475151,
      0.05943962559103966,
      0.0385965071618557,
      0.05727345496416092,
      -0.0032685818150639534,
      0.01421071495860815,
      0.06774180382490158,
      0.1606973558664322,
      0.04319469630718231,
      -0.07676462084054947,
      -0.0621800422668457,
      0.04403015226125717,
      -0.05754484608769417,
      -0.003697330132126808,
      -0.025618257001042366,
      -0.0746908113360405,
      -0.03558019921183586,
      0.05872500687837601,
      -0.01492346078157425,
      0.0027734164614230394,
      -0.04524003714323044,
      -0.021039700135588646,
      0.0034544363152235746,
      0.029807427898049355,
      0.04236184060573578,
      -0.03543170168995857,
      0.009463725611567497,
      -0.01977933757007122,
      -0.003725930117070675,
      0.0035507450811564922,
      0.04912946745753288,
      0.029922565445303917,
      0.017450600862503052,
      0.03272339701652527,
      -0.0016666592564433813,
      -0.03049858845770359,
      0.0016036059241741896,
      -0.03757510334253311,
      0.056117765605449677,
      -0.05775708705186844,
      -0.04731643944978714,
      0.019658714532852173,
      0.026041988283395767,
      0.06187101826071739,
      0.02264217659831047,
      0.006689650937914848,
      -0.01946425624191761,
      0.03196294233202934,
      -0.015672234818339348,
      -0.05591385066509247,
      -0.02139032632112503,
      0.0482669472694397,
      -0.00013491354184225202,
      0.07229919731616974,
      -0.020374035462737083,
      0.0352606400847435,
      0.056429579854011536,
      0.011570258066058159,
      -0.06687204539775848,
      0.06589798629283905,
      -0.006167343817651272,
      0.019054437056183815,
      0.0025270208716392517,
      -0.02534749172627926,
      0.09059552848339081,
      0.062048595398664474,
      -0.03734903410077095,
      -0.013685761950910091,
      -0.04953685775399208,
      0.008651971817016602,
      -0.022097868844866753,
      -0.001301491865888238,
      -0.04329565167427063,
      0.0019276839448139071,
      0.06054669991135597,
      0.08934459835290909,
      0.09630152583122253,
      -0.013098514638841152,
      -0.005295942537486553,
      0.0313347764313221,
      0.02219267375767231,
      -0.012635248713195324,
      -0.013776984997093678,
      0.07374470680952072,
      -0.013358447700738907,
      -0.03646464645862579,
      -0.036992624402046204,
      -0.053124669939279556,
      -0.05710168182849884,
      -0.105611152946949,
      -0.030558191239833832,
      -0.0652935579419136,
      -0.028834959492087364,
      0.0028367808554321527,
      -0.022281281650066376,
      -0.020801657810807228,
      0.00022197794169187546,
      -0.01929865963757038,
      0.001468677306547761,
      -0.06616130471229553,
      -0.010141746141016483,
      -0.040973834693431854,
      0.007867944426834583,
      -0.022844141349196434,
      -0.11430823802947998,
      -0.016859641298651695,
      -0.06609450280666351,
      0.001599323470145464,
      -0.00890617910772562,
      0.029810436069965363,
      0.0895015150308609,
      -0.01086874958127737,
      -0.00551705714315176,
      0.0676746517419815,
      -0.02982301078736782,
      -0.002589298179373145,
      0.07415691763162613,
      0.0643865168094635,
      -0.007239277474582195,
      -0.027143394574522972,
      0.01944366656243801,
      0.08721542358398438,
      0.08130402863025665,
      -0.017314789816737175,
      0.019234903156757355,
      0.042627204209566116,
      0.01477587129920721,
      0.011191938072443008,
      0.05569915100932121,
      -0.007010874804109335,
      -0.003013267181813717,
      0.03318020701408386,
      0.026287799701094627,
      0.04663419723510742,
      0.03854796290397644,
      -0.05216098204255104,
      0.013555863872170448,
      -0.021485935896635056,
      -0.0119343725964427,
      -0.01512094121426344,
      0.06385456770658493,
      -0.01862986572086811,
      -0.03643700107932091,
      -0.03550290688872337,
      -0.08205502480268478,
      -0.026810139417648315,
      0.016311537474393845,
      0.05804678052663803,
      0.023631669580936432,
      -0.00773723516613245,
      0.020599165931344032,
      0.001378965680487454,
      -0.0006847280892543495,
      -0.04137197136878967,
      0.01518150232732296,
      0.015493552200496197,
      0.015489752404391766,
      0.01741391234099865,
      -0.07892744988203049,
      -0.028158927336335182,
      -0.022597959265112877,
      0.037105828523635864,
      -0.02423609048128128,
      0.0007864563376642764,
      -0.0008635944686830044,
      0.07159779965877533,
      0.009634844027459621,
      -0.0763971135020256,
      -0.008371271193027496,
      -0.07409495860338211,
      -0.06061035394668579,
      0.029094845056533813,
      0.0427519865334034,
      -0.017265357077121735,
      -0.01564125530421734,
      -0.02621181309223175,
      -0.0051212189719080925,
      -0.01896955445408821,
      0.004066190216690302,
      0.021091386675834656,
      0.046622663736343384,
      0.10735374689102173,
      0.02535332180559635,
      0.040701352059841156,
      0.03664672002196312,
      -0.00806698203086853,
      0.06471148133277893,
      0.031633906066417694,
      -0.06153973937034607,
      -0.009556875564157963,
      -0.06095251441001892,
      -0.016399811953306198,
      0.04493771493434906,
      -0.07649531960487366,
      -0.10699354857206345,
      -0.035583604127168655,
      -0.09098740667104721,
      0.05341511219739914,
      -0.014370020478963852,
      -0.037604525685310364,
      -0.031293220818042755,
      0.05129411816596985,
      0.03881801292300224,
      0.004639648832380772,
      0.015686223283410072,
      0.005525520537048578,
      -0.08140566200017929,
      -0.0017353161238133907,
      0.026419494301080704,
      0.10495573282241821,
      0.029056677594780922,
      0.03678472340106964,
      0.037911947816610336,
      -0.023718371987342834,
      0.015054150484502316,
      0.008240063674747944,
      0.024653244763612747,
      -0.054703403264284134,
      0.06523416191339493,
      0.019207272678613663,
      0.019555171951651573,
      0.012393975630402565,
      0.03185074031352997,
      0.02757294848561287,
      0.03138315677642822,
      0.031811609864234924,
      0.020623760297894478
    ],
    [
      -0.044607244431972504,
      -0.02206088788807392,
      -0.010400442406535149,
      -0.06048818305134773,
      0.017520910128951073,
      -0.09860045462846756,
      -0.04614431783556938,
      0.00012991062249056995,
      -0.006204849109053612,
      -0.03504437208175659,
      -0.08271723240613937,
      0.04538307711482048,
      -0.039396319538354874,
      0.015427918173372746,
      0.09514154493808746,
      -0.0011482096742838621,
      -0.014347867108881474,
      -0.025219010189175606,
      0.04410654678940773,
      -0.0047938646748661995,
      0.04668949544429779,
      -0.025599729269742966,
      0.017126459628343582,
      -0.048246871680021286,
      -0.029497751966118813,
      0.02158859372138977,
      -0.027649132534861565,
      -0.06394635140895844,
      0.006036319769918919,
      -0.03600839152932167,
      0.025961430743336678,
      -0.0279192216694355,
      0.028174079954624176,
      0.012143594212830067,
      -0.034334696829319,
      0.014184834435582161,
      0.012960437685251236,
      -0.0736858993768692,
      -0.06145147606730461,
      -0.058283284306526184,
      0.0007439168985001743,
      0.06932161003351212,
      -0.023823508992791176,
      0.03186611458659172,
      0.046730875968933105,
      0.044399868696928024,
      -0.06626109033823013,
      -0.01955089345574379,
      0.02867063507437706,
      0.00962500087916851,
      4.869497934123501e-05,
      0.03413200378417969,
      0.0582655668258667,
      0.0476236417889595,
      0.024985048919916153,
      -0.01697579026222229,
      0.033062975853681564,
      0.046808380633592606,
      -0.016018984839320183,
      0.005487898364663124,
      -0.01462455466389656,
      0.04751027002930641,
      -0.03220853582024574,
      0.04366299510002136,
      0.033432066440582275,
      -0.047682251781225204,
      0.005557691212743521,
      -0.0488642081618309,
      0.057727448642253876,
      -0.021286534145474434,
      -0.10023705661296844,
      0.04537038132548332,
      0.016261707991361618,
      0.007948565296828747,
      0.034538932144641876,
      -0.04946979880332947,
      0.07731325924396515,
      -0.009964680299162865,
      -0.017451109364628792,
      -0.0034656089264899492,
      0.07126908004283905,
      0.03492624685168266,
      -0.076065793633461,
      -0.07177980244159698,
      0.030920661985874176,
      -0.09948208183050156,
      -0.02893405221402645,
      0.027659570798277855,
      0.028106670826673508,
      0.0053315358236432076,
      0.022808130830526352,
      -0.014261480420827866,
      -0.01563972793519497,
      0.01503076497465372,
      0.05896749347448349,
      -0.029135683551430702,
      0.010532274842262268,
      0.007000341545790434,
      0.003011745633557439,
      -0.035510867834091187,
      0.042120058089494705,
      -0.08023124188184738,
      0.07972261309623718,
      0.034056343138217926,
      0.015390333719551563,
      0.006456194445490837,
      0.09210065007209778,
      -0.08840664476156235,
      0.013537978753447533,
      0.09598241001367569,
      0.07339325547218323,
      -0.008928755298256874,
      -0.04358968138694763,
      0.06311710178852081,
      0.00767341535538435,
      0.02535354346036911,
      -0.008161445148289204,
      0.06758212298154831,
      0.023352840915322304,
      -0.006117035634815693,
      0.0897996798157692,
      0.0016831365646794438,
      0.06138371303677559,
      0.05971774458885193,
      0.018749641254544258,
      0.029737655073404312,
      -0.005204668268561363,
      0.05344115570187569,
      0.0935169905424118,
      -0.03291549161076546,
      -0.014239683747291565,
      -0.014267741702497005,
      0.0035100234672427177,
      0.014082225039601326,
      -0.027591818943619728,
      -0.007250624243170023,
      0.026928739622235298,
      0.008159521967172623,
      -0.08731207251548767,
      -0.018534664064645767,
      -0.04289485514163971,
      -0.027724940329790115,
      -0.047782767564058304,
      -0.03612322732806206,
      -0.06780827045440674,
      -0.007849021814763546,
      0.022077765315771103,
      0.05101705715060234,
      -0.008275928907096386,
      0.048130642622709274,
      -0.035485077649354935,
      0.024184057489037514,
      0.13940449059009552,
      -0.03426987677812576,
      -0.019044578075408936,
      0.061706092208623886,
      0.03074110485613346,
      0.0775136724114418,
      0.002155718859285116,
      0.024620063602924347,
      0.0781388059258461,
      -0.030773697420954704,
      -0.10772021114826202,
      -0.03743128105998039,
      -0.05912439525127411,
      -0.04417550936341286,
      0.045377057045698166,
      0.015545192174613476,
      -0.09661276638507843,
      -0.013622722588479519,
      -8.480232645524666e-05,
      -0.012981189414858818,
      0.05599299445748329,
      -0.0402284599840641,
      -0.012272425927221775,
      -0.013190055266022682,
      -0.01000506617128849,
      -0.047653887420892715,
      0.06518145650625229,
      -0.0021201346535235643,
      -0.010236957110464573,
      -0.004203255288302898,
      0.031625896692276,
      0.11408016085624695,
      0.08900705724954605,
      0.0032068623695522547,
      0.0010658709798008204,
      0.048148263245821,
      0.0074388557113707066,
      -0.03046254627406597,
      -0.03958527743816376,
      -0.05835523083806038,
      -0.004221170209348202,
      -0.010974242351949215,
      -0.03836270794272423,
      0.0499236062169075,
      -0.07031971961259842,
      0.013117202557623386,
      -0.006587966810911894,
      0.061366867274045944,
      -0.005810182075947523,
      0.0578395240008831,
      -0.003138529136776924,
      0.03747875615954399,
      -0.01482097152620554,
      -0.03308704495429993,
      0.04418839141726494,
      -0.08261661976575851,
      -0.0329112745821476,
      0.016409704461693764,
      0.0036426647566258907,
      0.011125068180263042,
      -0.06454296410083771,
      -0.0013442984782159328,
      -0.011370198801159859,
      -0.043068766593933105,
      0.04598544165492058,
      0.14868496358394623,
      -0.0278526172041893,
      -0.011906853877007961,
      -0.021979056298732758,
      -0.0031052366830408573,
      0.011503784917294979,
      -0.03893699869513512,
      0.006544857751578093,
      0.022735189646482468,
      0.009835704229772091,
      -0.11448684334754944,
      0.01082050520926714,
      -0.09293068945407867,
      -0.014737465418875217,
      0.05499810725450516,
      0.04192546010017395,
      0.03185664489865303,
      -0.043375056236982346,
      0.06051359698176384,
      0.019051989540457726,
      0.0821133553981781,
      -0.09747189283370972,
      -0.03851725906133652,
      -0.05524638667702675,
      0.04171691834926605,
      0.014850485138595104,
      -0.04935064539313316,
      -0.08313260227441788,
      -0.04321044310927391,
      -0.00013366475468501449,
      -0.03586142510175705,
      0.04792293533682823,
      -0.02629757858812809,
      0.004173784051090479,
      0.015518061816692352,
      -0.0015621399506926537,
      0.022148557007312775,
      -0.029604291543364525,
      0.08475786447525024,
      -0.04164329543709755,
      0.03762466087937355,
      -0.06627869606018066,
      -0.023117128759622574,
      0.05056742578744888,
      0.02039341814815998,
      0.023151660338044167,
      -0.036427974700927734,
      -0.0013561294181272388,
      -0.037328507751226425,
      -0.05864059552550316,
      0.09301819652318954,
      0.02377706579864025,
      0.0020748404785990715,
      -0.06864991039037704,
      -0.008543635718524456,
      0.03657358139753342,
      -0.05623513087630272,
      -0.04509555548429489,
      -0.010327015072107315,
      -0.006471458822488785,
      -0.0044814967550337315,
      0.019490843638777733,
      -0.022323137149214745,
      0.03083217889070511,
      -0.02566191740334034,
      0.0986585021018982,
      -0.03775359317660332,
      0.026883020997047424,
      0.019667157903313637,
      0.005137632600963116,
      0.08948676288127899,
      0.036103859543800354,
      -0.06543931365013123,
      0.01738089695572853,
      -0.01504211314022541,
      0.004661640152335167,
      -0.1644003689289093,
      0.008137601427733898,
      -0.047219377011060715,
      -0.03672979772090912,
      -0.029389766976237297,
      -0.01191408559679985,
      -0.02087036892771721,
      0.00720551609992981,
      0.07934701442718506,
      -0.0005532790091820061,
      0.01787799410521984,
      -0.027381055057048798,
      0.06491832435131073,
      -0.029128845781087875,
      0.04734380170702934,
      -0.003227012464776635,
      0.004948020447045565,
      0.022115059196949005,
      0.029572423547506332,
      0.01734304055571556,
      0.0017162258736789227,
      -0.09453040361404419,
      0.04513770714402199,
      -0.049939800053834915,
      -0.042640190571546555,
      0.08682214468717575,
      0.06794069707393646,
      -0.03216683119535446,
      -0.048897746950387955,
      -0.04063883423805237,
      -0.0591299869120121,
      -0.0016335333930328488,
      0.04329574108123779,
      -0.06480944156646729,
      0.0469028539955616,
      -0.003037169110029936,
      -0.028606632724404335,
      -0.05265301093459129,
      -0.018285861238837242,
      0.052296269685029984,
      0.058382321149110794,
      0.015839051455259323,
      0.03559441491961479,
      -0.05458040162920952,
      -0.11481021344661713,
      0.02428157813847065,
      -0.03605494648218155,
      0.04255329445004463,
      -0.03424431383609772,
      0.02815147489309311,
      0.0404837429523468,
      -0.06920241564512253,
      -0.0008639704319648445,
      -0.02549969218671322,
      0.044288452714681625,
      -0.042642224580049515,
      -0.02300543151795864,
      0.021916311234235764,
      -0.016827603802084923,
      0.04723678156733513,
      -0.04065879434347153,
      -0.02999160997569561,
      0.07561842352151871,
      -0.06582774221897125,
      0.05720052123069763,
      -0.05756203457713127,
      -0.006229389924556017,
      -0.02505912259221077,
      -0.024434661492705345,
      -0.06626691669225693,
      0.06439550966024399,
      0.021900448948144913,
      -0.013438560999929905,
      -0.0036420824471861124,
      0.0256354883313179,
      0.02956717275083065,
      -0.0046289050951600075,
      -0.025334538891911507,
      0.022843822836875916,
      0.04653581604361534,
      0.04080791026353836,
      -0.014571625739336014,
      -0.07800250500440598,
      -0.02359694428741932,
      0.00451374426484108,
      -0.0714261457324028,
      -0.04497469961643219,
      -0.01421545073390007,
      0.032554417848587036,
      0.06694000959396362,
      0.017304779961705208,
      0.033090267330408096,
      0.013867015019059181,
      0.001218064222484827,
      -0.004734882153570652,
      -0.08293645828962326,
      -0.07714251428842545,
      0.003041548188775778,
      -0.006864498369395733,
      0.04609302431344986,
      0.059181008487939835,
      -0.015340418554842472,
      -0.0588332824409008,
      0.07763246446847916,
      0.052053142338991165,
      -0.08517271280288696,
      -0.025867201387882233,
      -0.04011086747050285,
      -0.02666425332427025,
      -0.11098390072584152,
      -0.002453204244375229,
      -0.021210281178355217,
      0.06993426382541656,
      -0.03827706351876259,
      -0.005819127429276705,
      -0.014097434468567371,
      -0.010239331983029842,
      -0.07722541689872742,
      -0.06551001965999603,
      -0.05288315936923027,
      0.05490744858980179,
      -0.005792297888547182,
      -0.0414113849401474,
      0.0047536990605294704,
      0.053264882415533066,
      -0.012169967405498028,
      -0.02790098637342453,
      -0.05952756479382515,
      0.0037481829058378935,
      -0.02319216914474964,
      -0.03740455582737923,
      -0.06570218503475189,
      0.02726030722260475,
      0.05539911612868309,
      -0.013056144118309021,
      -0.027538662776350975,
      -0.05607592314481735,
      -0.08606787770986557,
      -0.05443103238940239,
      -0.023393845185637474,
      0.05010317638516426,
      0.13256196677684784,
      -0.05558951571583748,
      -0.07940234988927841,
      -0.036630142480134964,
      -0.035233207046985626,
      -0.0378708615899086,
      0.01960591971874237,
      -0.03586244955658913,
      -0.013977003283798695,
      -0.020406430587172508,
      0.005657150875777006,
      -0.04689919203519821,
      -0.04890553653240204,
      -0.05578358843922615,
      -0.05672501027584076,
      -0.02905360609292984,
      -0.00459640147164464,
      -0.013486814685165882,
      -0.02202845737338066,
      0.01698247529566288,
      0.12172728031873703,
      0.04196906462311745,
      0.035369623452425,
      -0.030832817777991295,
      -0.021757498383522034,
      -0.009819969534873962,
      -0.012417294085025787,
      -0.06185751408338547,
      0.053054437041282654,
      -0.03457123041152954,
      -0.0035272911190986633,
      -0.030375951901078224,
      -0.06565434485673904,
      0.04246816784143448,
      -0.09626875817775726,
      -0.004546152427792549,
      0.05257405340671539,
      0.0023225906770676374,
      -0.028684144839644432,
      0.0825561136007309,
      -0.07453406602144241,
      0.04014157876372337,
      -0.004467269405722618,
      0.00766999926418066,
      -0.002201809547841549,
      0.053914040327072144,
      -0.07722116261720657,
      -0.00793678779155016,
      0.017533738166093826,
      0.021376492455601692,
      0.08598156273365021,
      0.07477428764104843,
      -0.06748829036951065,
      0.05659674480557442,
      -0.050355494022369385,
      0.03524923324584961,
      0.0705849751830101,
      -0.07179787755012512,
      0.05203818902373314,
      -0.049288343638181686,
      0.023408135399222374,
      0.04870610311627388,
      0.04233632981777191,
      0.0096665620803833,
      -0.04298057034611702,
      -0.018330467864871025,
      -0.07634588330984116,
      -0.055901553481817245,
      0.006057772785425186
    ],
    [
      0.046418171375989914,
      0.09049639850854874,
      -0.0108964703977108,
      0.037580456584692,
      0.002710738917812705,
      -0.04256194084882736,
      0.005278283264487982,
      0.0160247553139925,
      -0.027476780116558075,
      -0.01098881009966135,
      -0.04347982257604599,
      0.11200935393571854,
      -0.05918315798044205,
      0.08691798150539398,
      0.012689327821135521,
      -0.06252853572368622,
      -0.05056743696331978,
      -0.0032000963110476732,
      0.03489707037806511,
      -0.09261762350797653,
      0.02970953658223152,
      -0.06912707537412643,
      0.0818132534623146,
      0.0313219390809536,
      -0.03218073025345802,
      0.03741128370165825,
      0.007977106608450413,
      0.02676740474998951,
      0.02156781777739525,
      -0.12094257026910782,
      0.06125953048467636,
      -0.005839523393660784,
      0.022930964827537537,
      0.07453848421573639,
      -0.01941671408712864,
      -0.07347075641155243,
      0.021031854674220085,
      -0.018329361453652382,
      0.048596013337373734,
      0.012785420753061771,
      0.04665471613407135,
      0.024233656004071236,
      -0.06163780391216278,
      -0.08312354236841202,
      0.0015464290045201778,
      -0.06141893193125725,
      -0.030743582174181938,
      0.00873562227934599,
      -0.03613036498427391,
      -0.046435583382844925,
      -0.02994147501885891,
      -0.016172608360648155,
      0.017195280641317368,
      0.006854125298559666,
      -0.03970108926296234,
      -0.011830214411020279,
      -0.027879273518919945,
      0.02954947203397751,
      0.023665549233555794,
      -0.034725796431303024,
      -0.01725037768483162,
      -0.012280084192752838,
      -0.020468249917030334,
      0.00998685322701931,
      -0.039113543927669525,
      -0.032139554619789124,
      -0.061816416680812836,
      0.010954278521239758,
      -0.0029021725058555603,
      -0.095548614859581,
      -0.03371636942028999,
      -0.016225764527916908,
      -0.0881669670343399,
      0.037389710545539856,
      -0.00338297919370234,
      -0.06610270589590073,
      0.05114711448550224,
      0.008339621126651764,
      0.04026448354125023,
      0.04185512661933899,
      -0.004923746455460787,
      -0.0757286325097084,
      -0.060473620891571045,
      0.0411900095641613,
      0.0788089781999588,
      0.01236333791166544,
      -0.03647822141647339,
      -0.0294477678835392,
      0.06767594814300537,
      -0.01632986217737198,
      0.003790621878579259,
      -0.020739590749144554,
      0.05708320811390877,
      -0.08127400279045105,
      0.039705488830804825,
      -0.005526949651539326,
      -0.009902953170239925,
      0.023692425340414047,
      -0.055515170097351074,
      -0.0259703416377306,
      -0.012807008810341358,
      -0.05799030512571335,
      0.09969011694192886,
      0.046701185405254364,
      -0.01808435469865799,
      -0.005540370009839535,
      0.04395873099565506,
      0.08013001084327698,
      -0.03532193601131439,
      0.038195498287677765,
      0.04489912837743759,
      -0.0024222952779382467,
      -0.07018192857503891,
      -0.01633472554385662,
      -0.02018137462437153,
      -0.005791313946247101,
      0.002981762168928981,
      0.029007023200392723,
      0.09517662227153778,
      -0.04520956426858902,
      0.09515651315450668,
      -0.03068077750504017,
      0.014481483958661556,
      0.004221651703119278,
      -0.022793913260102272,
      0.06899970024824142,
      0.00025556181208230555,
      0.0719248577952385,
      -0.013082706369459629,
      -0.027237186208367348,
      -0.008001450449228287,
      -0.06047169491648674,
      -0.09865505993366241,
      -0.04672757536172867,
      -0.0021693482995033264,
      0.01941283419728279,
      0.011184820905327797,
      -0.021975770592689514,
      -0.008642050437629223,
      -0.02715400420129299,
      0.04620383307337761,
      -0.04154079034924507,
      -0.08012115210294724,
      -0.010207286104559898,
      -0.1592949479818344,
      -0.03869808092713356,
      0.04276591166853905,
      0.01548476330935955,
      -0.0202419962733984,
      -0.01100136898458004,
      -0.025730015709996223,
      0.0011759280459955335,
      0.02864810638129711,
      -0.05361900106072426,
      -0.03543712943792343,
      0.025359533727169037,
      -0.0001996644277824089,
      -0.00582673167809844,
      0.06840591877698898,
      0.019829178228974342,
      0.0516965277493,
      0.04185415431857109,
      0.012883935123682022,
      -0.07779598236083984,
      0.021408837288618088,
      0.016198862344026566,
      -0.003954237326979637,
      0.066902294754982,
      -0.1042461097240448,
      -0.03345148265361786,
      -0.07630246132612228,
      -0.036753199994564056,
      -0.03836944326758385,
      -0.08144037425518036,
      0.03891716152429581,
      -0.009086726233363152,
      0.04059267416596413,
      0.08123281598091125,
      0.0016071057179942727,
      0.043699946254491806,
      -0.02968718856573105,
      -0.03587990999221802,
      -0.0026708177756518126,
      -0.009444273076951504,
      -0.038177989423274994,
      -0.10484128445386887,
      -0.06613975763320923,
      0.019544187933206558,
      -0.03584993630647659,
      0.08531974256038666,
      0.00026897952193394303,
      0.12225719541311264,
      -0.03882104158401489,
      -0.05100435018539429,
      0.020480338484048843,
      -0.00021295674378052354,
      -0.028071042150259018,
      0.02679283730685711,
      0.031854163855314255,
      -0.022961098700761795,
      -0.03352191299200058,
      0.028501536697149277,
      -0.018548594787716866,
      0.032123640179634094,
      -0.08846069127321243,
      -0.02969415858387947,
      0.027574511244893074,
      -0.020508622750639915,
      0.04719868302345276,
      -0.008412067778408527,
      0.026674799621105194,
      -0.013944657519459724,
      0.02355569414794445,
      0.03490163013339043,
      0.006014922168105841,
      0.05984588339924812,
      -0.04699525237083435,
      0.041145578026771545,
      0.009330248460173607,
      0.004771358799189329,
      -0.041297074407339096,
      -0.030406838282942772,
      -0.033118706196546555,
      0.008223635144531727,
      -0.03262585401535034,
      0.02864871919155121,
      -0.04548199847340584,
      0.043806787580251694,
      0.01797809638082981,
      0.022685149684548378,
      0.07497759908437729,
      0.09333474189043045,
      -0.03675613924860954,
      -0.0319354310631752,
      -0.025274133309721947,
      -0.04530121758580208,
      0.05914280191063881,
      -0.001883621676824987,
      0.09543417394161224,
      -0.03254504129290581,
      0.027606772258877754,
      -0.024859633296728134,
      -0.0071475026197731495,
      -0.011447373777627945,
      -0.017156902700662613,
      0.07136237621307373,
      -0.033504918217659,
      0.06778092682361603,
      -0.0036925028543919325,
      -0.023194963112473488,
      -0.02242523804306984,
      -0.04564100131392479,
      0.04256022348999977,
      0.03882598504424095,
      0.02779521234333515,
      -0.027623634785413742,
      0.04008719325065613,
      0.02199036069214344,
      -0.01973630301654339,
      -0.022745244204998016,
      -0.03577890247106552,
      -0.06124468892812729,
      0.013690581545233727,
      0.006022706162184477,
      0.03589622676372528,
      -0.06214850768446922,
      -0.02045552060008049,
      -0.05005223676562309,
      -0.051594801247119904,
      -0.061668142676353455,
      -0.07821222394704819,
      0.06378226727247238,
      -0.055723484605550766,
      -0.08739519119262695,
      -0.08172138780355453,
      0.07194193452596664,
      0.021434688940644264,
      0.04972940310835838,
      -0.029726305976510048,
      -0.027791092172265053,
      0.038836684077978134,
      -0.02728346362709999,
      0.0017904103733599186,
      0.022480083629488945,
      -0.032414183020591736,
      0.010219312272965908,
      -0.024846026673913002,
      0.06701094657182693,
      0.0480002760887146,
      -0.041907258331775665,
      -0.025620799511671066,
      0.012597117573022842,
      0.038839519023895264,
      -0.035472322255373,
      0.0741608515381813,
      -0.09656696021556854,
      0.08162345737218857,
      0.005214189179241657,
      -0.10871318727731705,
      0.023707835003733635,
      0.012146135792136192,
      -0.005170684773474932,
      0.023782985284924507,
      0.005052264779806137,
      0.022398632019758224,
      -0.022110484540462494,
      0.028728531673550606,
      0.023660922423005104,
      0.01974703185260296,
      -0.025036364793777466,
      0.006772185675799847,
      0.01790527254343033,
      0.0952417254447937,
      -0.020452065393328667,
      0.022905349731445312,
      0.05053338035941124,
      -0.029674511402845383,
      -0.006475942209362984,
      -0.03811706602573395,
      -0.033289823681116104,
      -0.008652142249047756,
      -0.018426934257149696,
      0.012168719433248043,
      -0.10590922832489014,
      -0.032351795583963394,
      0.010926066897809505,
      -0.07305976003408432,
      0.03960898518562317,
      0.04399809241294861,
      -0.05862320587038994,
      -0.037453703582286835,
      0.0447831004858017,
      -0.03555547073483467,
      -0.03439898416399956,
      0.0015213682781904936,
      -0.013257822953164577,
      0.048355475068092346,
      0.008539236150681973,
      0.015284824185073376,
      0.003341405652463436,
      -0.02858373522758484,
      0.05585458502173424,
      0.05118795484304428,
      0.05419190227985382,
      -0.00628689443692565,
      -0.020907733589410782,
      0.04144444689154625,
      0.021882910281419754,
      -0.012371394783258438,
      -0.014663821086287498,
      -0.05485524609684944,
      0.1153440997004509,
      -0.04630844667553902,
      -0.028072522953152657,
      0.08006837964057922,
      -0.013396303169429302,
      -0.01876593381166458,
      -0.025226110592484474,
      -0.03265226632356644,
      -0.05432898923754692,
      0.08332589268684387,
      -0.02030646614730358,
      -0.04028527811169624,
      -0.016843555495142937,
      -0.02458447404205799,
      0.06771431118249893,
      0.05778532102704048,
      -0.02805418334901333,
      0.014512323774397373,
      0.0001993655605474487,
      -0.028503574430942535,
      -0.06942372024059296,
      -0.005075445398688316,
      0.05336417630314827,
      -0.02503341995179653,
      -0.027267679572105408,
      -0.05375809222459793,
      -0.03841922804713249,
      -0.01892964169383049,
      -0.04968814179301262,
      -0.04922434687614441,
      -0.05753963068127632,
      0.0483536422252655,
      0.08966770023107529,
      0.010705878026783466,
      0.023326348513364792,
      0.09605680406093597,
      -0.04686184599995613,
      -0.018932843580842018,
      -0.023062078282237053,
      -0.0236066821962595,
      -0.024503203108906746,
      -0.030565476045012474,
      -0.0035041319206357002,
      0.024008996784687042,
      0.01008530706167221,
      -0.04807962477207184,
      0.05517815053462982,
      0.00632101483643055,
      0.018460439518094063,
      -0.009456044994294643,
      -0.0014899735106155276,
      -0.03436687961220741,
      0.018913056701421738,
      -0.013357275165617466,
      -0.027003854513168335,
      -0.012673291377723217,
      -0.04284844174981117,
      -0.025324029847979546,
      -0.021611100062727928,
      -0.05679897218942642,
      0.014830062165856361,
      -0.005380955524742603,
      0.064482681453228,
      -0.059276457875967026,
      -0.07363670319318771,
      -0.039182595908641815,
      0.012094028294086456,
      0.012557245790958405,
      -0.007780708838254213,
      -0.04369668290019035,
      -0.020111316815018654,
      -0.024218302220106125,
      -0.055293139070272446,
      0.015346678905189037,
      -0.004218142945319414,
      0.058717913925647736,
      -0.010736043564975262,
      0.018231714144349098,
      0.027050374075770378,
      0.04222111403942108,
      -0.15855151414871216,
      -0.04541770741343498,
      -0.0919046401977539,
      -0.0051744249649345875,
      0.09107092767953873,
      0.044685881584882736,
      0.03580699488520622,
      0.053375910967588425,
      0.0665237233042717,
      0.08622926473617554,
      0.07008812576532364,
      -0.09797323495149612,
      -0.00823432020843029,
      -0.003112246049568057,
      -0.024593854323029518,
      -0.04254036024212837,
      0.08176057040691376,
      -0.06043121963739395,
      -0.06077702343463898,
      -0.008347798138856888,
      0.018308356404304504,
      0.033377014100551605,
      -0.0690658688545227,
      0.012410127557814121,
      0.06586989015340805,
      -0.07258214056491852,
      0.015043733641505241,
      0.041068676859140396,
      -0.0049491161480546,
      0.03361230343580246,
      -0.05751274153590202,
      -0.05674498528242111,
      -0.003722236957401037,
      -0.0009726856951601803,
      -0.005452073644846678,
      -0.08614841848611832,
      0.009276406839489937,
      -0.024783000349998474,
      -0.02502555586397648,
      -0.01838148571550846,
      -0.008356379345059395,
      0.12664568424224854,
      0.021128974854946136,
      -0.03620769828557968,
      0.03547607362270355,
      0.01732887700200081,
      -0.08524768799543381,
      -0.010536031797528267,
      0.026624029502272606,
      -0.0714111477136612,
      0.09900864958763123,
      0.07101153582334518,
      -0.06770153343677521,
      -0.03469255194067955,
      0.0218619666993618,
      0.03533328324556351,
      -0.04353919252753258,
      -0.030667860060930252,
      0.03319000452756882,
      0.02694881148636341,
      -0.044419046491384506,
      -0.008983125910162926,
      0.11082367599010468,
      -0.013525220565497875,
      0.044191598892211914,
      -0.004531940445303917,
      -0.013464795425534248,
      -0.018229525536298752,
      0.007252079900354147,
      -0.0029250658117234707,
      0.03195440024137497,
      -0.013315591029822826
    ],
    [
      -0.020939549431204796,
      0.007004018407315016,
      0.02587459608912468,
      0.10541322827339172,
      -0.0034370848443359137,
      0.008226193487644196,
      -0.08709414303302765,
      -0.04703826084733009,
      0.01273071113973856,
      0.004612290300428867,
      -0.06873025000095367,
      0.037314947694540024,
      -0.029541170224547386,
      0.012515746988356113,
      0.056659504771232605,
      0.017915133386850357,
      -0.06083347648382187,
      -0.00274623092263937,
      0.037563808262348175,
      -0.06730367988348007,
      -0.0882577896118164,
      0.044914763420820236,
      0.012246085330843925,
      0.021113663911819458,
      -0.10112068057060242,
      -0.017752621322870255,
      -0.01616845093667507,
      0.09237007796764374,
      0.046280767768621445,
      0.062429025769233704,
      0.11809604614973068,
      0.00048786672414280474,
      -0.07530348747968674,
      -0.001885911449790001,
      -0.10280445963144302,
      0.028147822245955467,
      0.02765234000980854,
      0.0026654242537915707,
      0.012922335416078568,
      -0.03777702897787094,
      0.0004103009996470064,
      -0.022313319146633148,
      0.02267633005976677,
      0.063777856528759,
      0.002232311060652137,
      -0.0215382631868124,
      -0.08191509544849396,
      0.0395011231303215,
      0.054000917822122574,
      -0.032478246837854385,
      -0.06016956642270088,
      -0.01044106762856245,
      -0.04018867760896683,
      0.026175176724791527,
      0.0029826064128428698,
      -0.07880061864852905,
      -0.0862664133310318,
      -0.007822725921869278,
      0.009614958427846432,
      0.010358324274420738,
      0.018598295748233795,
      0.09100916236639023,
      0.02914739027619362,
      0.010115370154380798,
      0.0609099306166172,
      0.09054835140705109,
      -0.09248659759759903,
      0.052017129957675934,
      -0.020933691412210464,
      -0.009524453431367874,
      0.07272199541330338,
      0.04393194243311882,
      -0.021755771711468697,
      0.07460188120603561,
      -0.024079056456685066,
      -0.07603476196527481,
      -0.0019135263282805681,
      -0.13175006210803986,
      -0.05716859921813011,
      -0.03925773873925209,
      -0.044039878994226456,
      -0.006219629198312759,
      -0.09816183894872665,
      0.01507933996617794,
      -0.018803710117936134,
      0.0514729768037796,
      0.006896329112350941,
      0.008188056759536266,
      0.009321113117039204,
      0.017950713634490967,
      -0.07401326298713684,
      -0.03187137097120285,
      0.04783742502331734,
      0.04783299192786217,
      0.02264619804918766,
      0.06005556508898735,
      0.05417563021183014,
      0.003495957935228944,
      -0.026407914236187935,
      -0.11435458064079285,
      0.10410835593938828,
      -0.005328059196472168,
      -0.02321888692677021,
      -0.009965226985514164,
      0.02325316146016121,
      0.06229104846715927,
      -0.049781434237957,
      -0.022248469293117523,
      0.007504332810640335,
      0.024850739166140556,
      0.031866997480392456,
      0.047556210309267044,
      -0.0688893273472786,
      -0.06574312597513199,
      -0.04315408691763878,
      -0.0043206228874623775,
      0.02710561268031597,
      0.060061242431402206,
      0.02982742339372635,
      -0.08932103216648102,
      0.06595513969659805,
      0.0694621354341507,
      0.07119638472795486,
      0.03720446303486824,
      0.09264883399009705,
      0.00018399389227852225,
      -0.015929827466607094,
      0.012884628027677536,
      0.008053665980696678,
      -0.10782988369464874,
      0.06686246395111084,
      0.03910864517092705,
      0.011475945822894573,
      -0.05889328569173813,
      -0.0372261181473732,
      0.04017195850610733,
      -0.024388374760746956,
      -0.016492893919348717,
      0.05573231726884842,
      0.035678792744874954,
      0.07520288228988647,
      -0.052807845175266266,
      -0.02369222790002823,
      -0.02213001623749733,
      0.08140545338392258,
      0.003356394823640585,
      -0.013156826607882977,
      0.056455109268426895,
      -0.022029822692275047,
      0.053956538438797,
      -0.041930533945560455,
      0.0007121102535165846,
      -0.021698392927646637,
      -0.0711856260895729,
      -0.004580786917358637,
      -0.07424329221248627,
      -0.034394215792417526,
      -0.029861463233828545,
      0.0885845273733139,
      0.055352553725242615,
      0.09610522538423538,
      0.0032797742169350386,
      0.06818465888500214,
      0.01684265397489071,
      0.013343455269932747,
      0.07607334107160568,
      0.01531219482421875,
      0.012018398381769657,
      -0.038728658109903336,
      -0.08989234268665314,
      0.042014967650175095,
      -0.019418103620409966,
      0.011087999679148197,
      -0.06823904812335968,
      0.016394728794693947,
      0.10138845443725586,
      0.06000768393278122,
      -0.06698445975780487,
      -0.025742841884493828,
      0.05800563469529152,
      0.05242658033967018,
      -0.0011084144935011864,
      -0.047524385154247284,
      -0.06445003300905228,
      0.0024319232907146215,
      0.0832417756319046,
      -0.0290166474878788,
      0.04594128206372261,
      -0.012691039592027664,
      -0.06585221737623215,
      0.07856802642345428,
      -0.06172451749444008,
      -0.032843396067619324,
      0.000710250751581043,
      0.1404470056295395,
      0.05693497881293297,
      -0.025541096925735474,
      -0.006314767524600029,
      -0.030943598598241806,
      -0.015248347073793411,
      -0.10832968354225159,
      0.03966394066810608,
      -0.027831269428133965,
      0.009502014145255089,
      0.08687763661146164,
      -0.0773768201470375,
      -0.045012496411800385,
      0.07366283237934113,
      0.006244526244699955,
      0.07344719767570496,
      -0.021000036969780922,
      -0.046998921781778336,
      0.029989387840032578,
      0.050181299448013306,
      -0.05854794755578041,
      0.0398443304002285,
      -0.06952403485774994,
      -0.050517499446868896,
      -0.027839088812470436,
      0.026980385184288025,
      0.011540384963154793,
      -0.05433455854654312,
      -0.03302145376801491,
      -0.08260918408632278,
      0.03920326009392738,
      0.029946710914373398,
      -0.01919146068394184,
      -0.009924998506903648,
      -0.0022085250820964575,
      0.05446375161409378,
      0.0405769906938076,
      0.11508695036172867,
      0.11817214637994766,
      -0.08413717895746231,
      -0.02702644281089306,
      -0.020006466656923294,
      -0.03538866713643074,
      0.036508604884147644,
      0.01777530647814274,
      0.04233153909444809,
      -0.08579766005277634,
      0.04282442107796669,
      -0.06407447159290314,
      -0.019333571195602417,
      -0.039866164326667786,
      0.09210411459207535,
      0.07313529402017593,
      0.03420576825737953,
      -0.0487922728061676,
      0.003282868303358555,
      -0.07687931507825851,
      -0.0014582729199901223,
      0.06128996983170509,
      0.03804938495159149,
      -0.04085328057408333,
      -0.05182977765798569,
      0.06035570055246353,
      -0.019339844584465027,
      -0.08730192482471466,
      -0.02233870141208172,
      -0.042404308915138245,
      0.01035168394446373,
      -0.01392410509288311,
      0.015984294936060905,
      -0.06437652558088303,
      -0.008658789098262787,
      -0.01838822476565838,
      0.025624899193644524,
      0.0024976334534585476,
      -0.020140597596764565,
      0.008702839724719524,
      0.0016363051254302263,
      0.020297665148973465,
      -0.01805831678211689,
      0.038198672235012054,
      -0.02701106294989586,
      0.016220219433307648,
      0.08391129225492477,
      -0.05188017338514328,
      -0.007962770760059357,
      0.0071107191033661366,
      0.0478079617023468,
      0.025551466271281242,
      -0.0007349476800300181,
      -0.06584750860929489,
      -0.04971500486135483,
      -0.03191090747714043,
      0.0931931659579277,
      -0.0028572487644851208,
      -0.032957058399915695,
      -0.0048283725045621395,
      -0.034122370183467865,
      0.10676149278879166,
      0.034154389053583145,
      0.020455174148082733,
      0.014026952907443047,
      0.08426987379789352,
      -0.01808965764939785,
      0.007911278866231441,
      0.007123404648154974,
      0.014569809660315514,
      0.011892566457390785,
      -0.03240015730261803,
      0.047558557242155075,
      -0.07483446598052979,
      0.03479120507836342,
      -0.0016225718427449465,
      -0.0056722816079854965,
      -0.002604058478027582,
      0.08130695670843124,
      0.005727323703467846,
      -0.03598370403051376,
      -0.0361776240170002,
      -0.024080762639641762,
      0.05130097270011902,
      0.036054618656635284,
      0.008540422655642033,
      -0.023009268566966057,
      0.048725198954343796,
      -0.05279530584812164,
      0.05118487775325775,
      -0.06398428231477737,
      0.04420197382569313,
      -0.09484750777482986,
      0.02714552916586399,
      -0.006868218537420034,
      -0.06269940733909607,
      -0.00825569499284029,
      -0.006226866506040096,
      -0.026265189051628113,
      0.09899081289768219,
      -0.024686330929398537,
      0.0065858019515872,
      0.01859309896826744,
      -0.025377396494150162,
      0.0013780079316347837,
      0.006684392224997282,
      -0.0705966055393219,
      -0.08669083565473557,
      0.08773777633905411,
      -0.03430520370602608,
      0.0019942610524594784,
      -0.01868479698896408,
      0.007607374805957079,
      -0.03086685761809349,
      0.05109194666147232,
      0.04421612247824669,
      0.05066528916358948,
      0.044348713010549545,
      -0.05028165876865387,
      -0.01934552565217018,
      0.020192258059978485,
      -0.04642230272293091,
      0.021245863288640976,
      0.005637884605675936,
      0.024753598496317863,
      0.037845250219106674,
      -0.05848080664873123,
      -0.03907588869333267,
      -0.04289424791932106,
      -0.012264647521078587,
      0.029408974573016167,
      -0.060663506388664246,
      0.015463379211723804,
      -0.030005404725670815,
      0.02160865068435669,
      0.04365313798189163,
      -0.03190034627914429,
      0.041879504919052124,
      0.01903868280351162,
      -0.0057264408096671104,
      -0.06252667307853699,
      -0.024201763793826103,
      0.019214224070310593,
      0.010061878710985184,
      -0.09907004237174988,
      -0.011684900149703026,
      0.023043090477585793,
      -0.016162212938070297,
      0.037949491292238235,
      -0.015834901481866837,
      -0.04746095463633537,
      -0.05411995202302933,
      0.01030076202005148,
      -0.0314662903547287,
      0.056973185390233994,
      -0.014520570635795593,
      0.030704272910952568,
      -0.013908877037465572,
      -0.05522043630480766,
      0.03173958137631416,
      0.013832581229507923,
      -0.013382820412516594,
      -0.036021389067173004,
      -0.010231767781078815,
      -0.04312372952699661,
      0.06922342628240585,
      0.04658476263284683,
      -0.08992267400026321,
      0.08226736634969711,
      -0.031179998070001602,
      -0.04852432385087013,
      0.02286728098988533,
      -0.08528325706720352,
      0.05094778910279274,
      0.053888238966464996,
      0.015428771264851093,
      0.030824530869722366,
      -0.03304626792669296,
      -0.0028817940037697554,
      -0.024551555514335632,
      0.043684788048267365,
      0.01710280403494835,
      0.06153801083564758,
      -0.008387149311602116,
      0.023434391245245934,
      -0.012853987514972687,
      0.01326966192573309,
      -0.10293608158826828,
      -0.037393637001514435,
      -0.0020516174845397472,
      0.061800677329301834,
      -0.04188614338636398,
      -0.056324101984500885,
      -0.007290338631719351,
      0.022919783368706703,
      0.040342435240745544,
      0.011435210704803467,
      0.06314995139837265,
      -0.0279966089874506,
      0.02282581850886345,
      -0.005965573247522116,
      0.07516582310199738,
      -0.020528431981801987,
      -0.010418940335512161,
      -0.03912578895688057,
      -0.034661807119846344,
      0.07225178182125092,
      -0.010963293723762035,
      -0.022079993039369583,
      0.04940848797559738,
      0.05846063420176506,
      0.08154398947954178,
      0.0029706263449043036,
      -0.008298213593661785,
      -0.07262753695249557,
      0.00605048006400466,
      0.08876863121986389,
      -0.08884543180465698,
      0.02000744827091694,
      -0.033912040293216705,
      -0.07079426199197769,
      0.0418313667178154,
      -0.05873408168554306,
      0.004955851472914219,
      -0.024246977642178535,
      -0.04343796148896217,
      0.06769593060016632,
      0.1072825938463211,
      0.08920655399560928,
      -0.03503361716866493,
      -0.030003709718585014,
      0.03711692988872528,
      0.04975545406341553,
      -0.01442658994346857,
      -0.0269626472145319,
      0.06264307349920273,
      -0.021751489490270615,
      -0.02881491929292679,
      -0.04814663156867027,
      -0.002160682575777173,
      0.020204467698931694,
      0.001453483127988875,
      -0.04926422983407974,
      0.07220420241355896,
      -0.03364953398704529,
      0.08241527527570724,
      -0.024723518639802933,
      -0.017203314229846,
      0.08099504560232162,
      -0.024124370887875557,
      0.025482697412371635,
      0.03437618538737297,
      0.07210191339254379,
      -0.012745125219225883,
      -0.010207714512944221,
      0.04530980810523033,
      -0.011198374442756176,
      -0.023294787853956223,
      0.003940050955861807,
      0.04393555223941803,
      0.013925350271165371,
      -0.06330818682909012,
      0.053086940199136734,
      0.10549759119749069,
      -0.08324448019266129,
      -0.03352529555559158,
      0.026530437171459198,
      0.06015973165631294,
      0.0056500486098229885,
      0.029119448736310005,
      0.05506037548184395,
      -0.006069465074688196
    ],
    [
      -0.001381248701363802,
      0.09074603021144867,
      0.04276058077812195,
      -0.0228730458766222,
      0.04702693596482277,
      0.02497122250497341,
      -0.03927047923207283,
      0.07452544569969177,
      -0.004614479374140501,
      0.0728544145822525,
      0.012258581817150116,
      -0.03074813261628151,
      0.05402129143476486,
      0.013635631650686264,
      0.030002467334270477,
      -0.0011076668743044138,
      -0.02606087736785412,
      0.14029787480831146,
      0.05500822141766548,
      0.0017618645215407014,
      -0.013897123746573925,
      0.030905183404684067,
      0.020491721108555794,
      -0.00726626580581069,
      -0.03785204142332077,
      0.035396743565797806,
      0.0694880336523056,
      0.017496952787041664,
      0.0010091442381963134,
      0.0025720444973558187,
      0.051916129887104034,
      -0.020672373473644257,
      -0.0010674844961613417,
      -0.04514347016811371,
      -0.013196692802011967,
      -0.011064021848142147,
      0.05326579883694649,
      -0.01592712104320526,
      -0.07555079460144043,
      0.00042935970122925937,
      0.03458927571773529,
      -0.014119530096650124,
      0.04873064160346985,
      0.09980732202529907,
      0.11019792407751083,
      -0.04498572275042534,
      0.00847422331571579,
      0.019290950149297714,
      -0.015213899314403534,
      -0.04970754310488701,
      -0.04469429329037666,
      0.028014879673719406,
      0.07056597620248795,
      0.009607503190636635,
      0.07170864194631577,
      0.02497938647866249,
      0.06766451150178909,
      0.0003000834840349853,
      0.03183746337890625,
      -0.0007584737031720579,
      0.010997756384313107,
      -0.016152840107679367,
      0.051542721688747406,
      -0.009026357904076576,
      -0.05957632511854172,
      -0.011543159373104572,
      -0.10269322246313095,
      0.06603910028934479,
      -0.07100355625152588,
      -0.06136888265609741,
      0.040883902460336685,
      -0.000799334142357111,
      -0.03653666749596596,
      -0.05698566883802414,
      0.00552588514983654,
      0.010561536997556686,
      0.02436749078333378,
      0.06413429975509644,
      -0.02725735865533352,
      -0.01995018497109413,
      -0.011322269216179848,
      0.06497851759195328,
      -0.053232867270708084,
      0.016982344910502434,
      -0.03485162556171417,
      -0.04500192776322365,
      0.007431661710143089,
      -0.003820942249149084,
      0.029530514031648636,
      0.02755838818848133,
      -0.005562840029597282,
      0.007258809637278318,
      0.11800185590982437,
      0.015823321416974068,
      0.03613631799817085,
      0.024840936064720154,
      0.012180954217910767,
      0.03859013319015503,
      -0.014391041360795498,
      -0.014744707383215427,
      0.012718996033072472,
      -0.059847790747880936,
      -0.005076194182038307,
      -0.023108521476387978,
      0.04325741156935692,
      -0.003801540471613407,
      0.004351526498794556,
      0.005247843451797962,
      -0.12325645983219147,
      0.10609573125839233,
      -0.023096779361367226,
      0.03081158548593521,
      0.014947879128158092,
      0.015466997399926186,
      0.06630758941173553,
      -0.0027534046676009893,
      -0.007169628981500864,
      -0.024050043895840645,
      0.07510428875684738,
      -0.054712116718292236,
      0.09180627018213272,
      0.03957001119852066,
      0.08029800653457642,
      0.012223170138895512,
      -0.013707155361771584,
      0.03668257221579552,
      0.02440459653735161,
      0.025722207501530647,
      -0.053970444947481155,
      -0.054868172854185104,
      0.06283358484506607,
      0.06802897900342941,
      -0.007119608577340841,
      -0.02416018210351467,
      0.06704474985599518,
      -0.049229636788368225,
      -0.01389981433749199,
      0.06089974194765091,
      -0.028817739337682724,
      0.005503179505467415,
      -0.008122426457703114,
      -0.014386302791535854,
      -0.08753608912229538,
      0.007790676783770323,
      -0.012900284491479397,
      -0.008293184451758862,
      0.007992170751094818,
      -0.04426182433962822,
      0.028026603162288666,
      -0.03613060340285301,
      -0.04762668535113335,
      -0.036249369382858276,
      0.0038234961684793234,
      0.0466274619102478,
      -0.01207730919122696,
      0.05345723778009415,
      0.05211479216814041,
      -0.07187288254499435,
      0.07716650515794754,
      -0.008221968077123165,
      -0.10111823678016663,
      -0.0008545753080397844,
      0.038409605622291565,
      -0.018558038398623466,
      -0.018028002232313156,
      -0.04484764486551285,
      -0.043027304112911224,
      -0.01142591331154108,
      -0.019749347120523453,
      -0.0009630083804950118,
      0.021384047344326973,
      0.0053680408746004105,
      0.07189391553401947,
      -0.031216686591506004,
      0.0332498773932457,
      0.0612773597240448,
      -0.026007570326328278,
      -0.04775175824761391,
      0.008137677796185017,
      0.018506811931729317,
      -0.002814109204337001,
      0.025731924921274185,
      -0.017870288342237473,
      0.06707196682691574,
      -0.010629582218825817,
      -0.0327845998108387,
      0.05588219314813614,
      -0.06821765750646591,
      0.079398974776268,
      0.018433917313814163,
      0.030300145968794823,
      -0.014917487278580666,
      0.042578570544719696,
      -0.006203343626111746,
      0.04567314311861992,
      -0.06910018622875214,
      -0.11774647980928421,
      0.015188774093985558,
      -0.01896880567073822,
      -0.006468277424573898,
      -0.06090165674686432,
      0.03491126745939255,
      -0.06656442582607269,
      0.06944748014211655,
      0.012466145679354668,
      -0.032894015312194824,
      0.05058634653687477,
      0.010185815393924713,
      0.01469055749475956,
      0.014200891368091106,
      -0.029114438220858574,
      -0.042277391999959946,
      0.011153352446854115,
      -0.009110338054597378,
      -0.044556885957717896,
      -0.09780119359493256,
      -0.042244039475917816,
      0.057734373956918716,
      -0.043851472437381744,
      -0.03868578374385834,
      0.12681734561920166,
      0.07707879692316055,
      0.006108039524406195,
      0.06590065360069275,
      0.026208555325865746,
      -0.028577540069818497,
      0.05352984741330147,
      -0.0012781689874827862,
      -0.0633690133690834,
      -0.028019608929753304,
      0.09449122101068497,
      0.018698247149586678,
      0.03954823315143585,
      -0.045853421092033386,
      0.013912282884120941,
      -0.04537351056933403,
      0.07766116410493851,
      -0.048939574509859085,
      -0.04070453345775604,
      0.016802143305540085,
      -0.018296483904123306,
      0.013777151703834534,
      -0.024175191298127174,
      0.016334466636180878,
      0.004293982405215502,
      -0.03887740522623062,
      0.08124252408742905,
      0.04245607554912567,
      0.009248940274119377,
      0.04858668893575668,
      -0.028631214052438736,
      0.013648874126374722,
      -0.044431716203689575,
      0.04147544875741005,
      -0.015455985441803932,
      -0.056227561086416245,
      0.06138492748141289,
      -0.03466014564037323,
      -0.07712159305810928,
      -0.029532117769122124,
      -0.03622986748814583,
      -0.025184769183397293,
      0.00909364689141512,
      -0.01116578746587038,
      -0.0020248843356966972,
      -0.01543479971587658,
      0.02912861481308937,
      -0.044240862131118774,
      -0.04853745177388191,
      -0.02283456362783909,
      -0.0569455549120903,
      0.07588985562324524,
      0.03501797094941139,
      -0.022847643122076988,
      -0.14509771764278412,
      -0.03901677951216698,
      0.0015921213198453188,
      -0.08124102652072906,
      0.041719384491443634,
      -0.031979046761989594,
      -0.025784654542803764,
      0.05488245561718941,
      0.03441451117396355,
      -0.08493030816316605,
      0.09661933779716492,
      -0.015765106305480003,
      -0.019367067143321037,
      0.01844729296863079,
      0.04262172803282738,
      0.033497318625450134,
      0.05560794100165367,
      -0.026540035381913185,
      -0.03735693171620369,
      -0.08601469546556473,
      -0.07056380808353424,
      0.052786946296691895,
      -0.0244483333081007,
      -0.016070397570729256,
      0.0932411327958107,
      0.033674366772174835,
      -0.05105927214026451,
      -0.00855170376598835,
      0.01927524246275425,
      -0.03256160020828247,
      -0.0162156093865633,
      -0.008541043847799301,
      0.0046416702680289745,
      -0.015259608626365662,
      0.0223959032446146,
      -0.025399688631296158,
      -0.01747293397784233,
      0.029781322926282883,
      -0.06601564586162567,
      -0.019599536433815956,
      -0.036606330424547195,
      -0.03208508342504501,
      -0.06780625134706497,
      -0.09787071496248245,
      0.05118909850716591,
      0.0010055825114250183,
      -0.011709795333445072,
      0.002468382939696312,
      0.01191825419664383,
      0.015042047947645187,
      -0.002494253683835268,
      0.038897350430488586,
      -0.010913572274148464,
      0.03759767860174179,
      0.03466590866446495,
      -0.052879177033901215,
      -0.08596498519182205,
      -0.008824408985674381,
      0.019959453493356705,
      -0.13286153972148895,
      0.008208321407437325,
      0.07313311845064163,
      -0.011515090242028236,
      0.025435034185647964,
      0.04681682586669922,
      -0.011955763213336468,
      0.04678789898753166,
      -0.06175709515810013,
      0.014929797500371933,
      -0.010480436496436596,
      0.00656700786203146,
      -0.047812771052122116,
      0.0016260455595329404,
      0.058083582669496536,
      0.06383150815963745,
      0.02091710828244686,
      -0.09491202235221863,
      -0.007980539463460445,
      -0.00811365433037281,
      -0.0812794417142868,
      -0.01037619262933731,
      -0.05555520951747894,
      0.015418623574078083,
      0.07909935712814331,
      -0.04470369219779968,
      -0.053144101053476334,
      -0.026588765904307365,
      -0.05032532662153244,
      0.03149740770459175,
      0.017159752547740936,
      -0.07469186931848526,
      -0.013356461189687252,
      -0.010087775997817516,
      0.01718786731362343,
      0.03967015817761421,
      -0.06725721061229706,
      -0.027706097811460495,
      0.014831538312137127,
      0.0010657957755029202,
      -0.014684343710541725,
      0.042788200080394745,
      0.03409716486930847,
      -0.027789516374468803,
      0.021171392872929573,
      -0.044917549937963486,
      -0.11387790739536285,
      -0.028719106689095497,
      -0.05173024535179138,
      0.05280837416648865,
      0.016355233266949654,
      0.042047448456287384,
      0.06081070378422737,
      -0.032227613031864166,
      0.0035399743355810642,
      -0.015993298962712288,
      0.0007441355264745653,
      -0.024058252573013306,
      -0.08822540193796158,
      -0.018840692937374115,
      0.03864983096718788,
      -0.06016790494322777,
      -0.025662651285529137,
      0.004392473958432674,
      0.007375381421297789,
      0.05202478542923927,
      0.02245236746966839,
      -0.007562566548585892,
      0.023172903805971146,
      0.0031613335013389587,
      -0.009042689576745033,
      -0.09841025620698929,
      0.03703562170267105,
      -0.05015207454562187,
      0.05495348572731018,
      -0.10028042644262314,
      -0.002024521818384528,
      0.0069331219419837,
      -0.012298370711505413,
      -0.05886150524020195,
      0.01847037859261036,
      -0.039384085685014725,
      0.09407882392406464,
      -0.028900111094117165,
      0.017051203176379204,
      0.012282783165574074,
      0.016548646613955498,
      0.03742624819278717,
      -0.0030084617901593447,
      0.038494158536195755,
      0.05388003587722778,
      0.004916244186460972,
      -0.009099081158638,
      -0.05628921091556549,
      -0.009546871297061443,
      -0.016426799818873405,
      -0.021770622581243515,
      0.029269633814692497,
      -0.0527566596865654,
      0.00023503434204030782,
      0.03878603130578995,
      -0.04137679189443588,
      0.012544511817395687,
      0.023676659911870956,
      0.021758094429969788,
      0.02356261946260929,
      -0.03766443580389023,
      -0.0562015138566494,
      0.112295962870121,
      -0.01881464384496212,
      0.0030758504290133715,
      0.03228171914815903,
      0.005533372517675161,
      -0.0024793576449155807,
      0.010702387429773808,
      0.03141764551401138,
      0.043509677052497864,
      -0.03385462239384651,
      -0.06382144242525101,
      -0.010678091086447239,
      -0.050692420452833176,
      0.04384719952940941,
      0.015349024906754494,
      0.017043916508555412,
      0.010318460874259472,
      0.016696300357580185,
      0.051383789628744125,
      0.07079976052045822,
      -0.01490353886038065,
      0.012654093094170094,
      -0.03218648210167885,
      -0.03922484442591667,
      -0.04983140155673027,
      0.09904215484857559,
      -0.09347172826528549,
      -0.04137540981173515,
      -0.09495968371629715,
      -0.04443509131669998,
      -0.053155988454818726,
      0.025122717022895813,
      0.055228203535079956,
      -0.05371169000864029,
      -0.02248629927635193,
      -0.04712293669581413,
      0.004821957089006901,
      -0.08336757868528366,
      -0.09326943755149841,
      -0.023821432143449783,
      0.07763709872961044,
      -0.019394923001527786,
      -0.004987939726561308,
      0.003424252150580287,
      -0.008510581217706203,
      0.024556197226047516,
      -0.026801032945513725,
      0.03450816124677658,
      -0.01650274358689785,
      0.009873148053884506,
      -0.0689949169754982,
      0.041556037962436676,
      0.05200260877609253,
      0.01661534793674946,
      -0.02103983797132969,
      0.0016839616000652313,
      0.010820336639881134,
      0.04575375095009804,
      0.1013193279504776,
      0.004450913984328508,
      -0.011793233454227448,
      0.029354579746723175
    ],
    [
      -0.01606087200343609,
      -0.010068791918456554,
      -0.013343051075935364,
      0.05540119484066963,
      -0.005025835242122412,
      -0.00871617067605257,
      0.0172323826700449,
      -0.07412994652986526,
      -0.017470145598053932,
      0.13256381452083588,
      0.008328731171786785,
      -0.05504412576556206,
      -0.011282901279628277,
      0.022244777530431747,
      0.10792072862386703,
      -0.013199618086218834,
      -0.08865422010421753,
      0.011576718650758266,
      0.015327228233218193,
      -0.07705102115869522,
      0.03687310591340065,
      0.00565232103690505,
      -0.011379723437130451,
      -0.01932605542242527,
      -0.06189177185297012,
      0.007611203007400036,
      0.04801630601286888,
      -0.0037060806062072515,
      0.0229979045689106,
      0.024720972403883934,
      0.055230528116226196,
      -0.05318663269281387,
      0.0017718038288876414,
      -0.046552132815122604,
      -0.029562247917056084,
      0.030413754284381866,
      0.07629115134477615,
      -0.0357825942337513,
      0.01909767836332321,
      -0.03738876059651375,
      0.020247766748070717,
      -0.01328304037451744,
      -0.04078519716858864,
      -0.027469979599118233,
      -0.006501712370663881,
      -0.037355199456214905,
      -0.02729932591319084,
      -0.039784546941518784,
      -0.03240085020661354,
      0.018127361312508583,
      -0.1446935087442398,
      -0.061287906020879745,
      0.10472465306520462,
      -0.0660087838768959,
      0.010323029942810535,
      0.07250863313674927,
      -0.052212826907634735,
      -0.002511008409783244,
      -0.06372193992137909,
      -0.01949399709701538,
      0.02681955136358738,
      -0.07399236410856247,
      -0.04237537458539009,
      0.07600878179073334,
      0.05999443307518959,
      0.04756765812635422,
      -0.015866823494434357,
      0.044770170003175735,
      0.02719697542488575,
      -0.003492115531116724,
      -0.02186638116836548,
      0.0013977318303659558,
      -0.0382632277905941,
      -0.050009045749902725,
      0.005645899102091789,
      0.08701367676258087,
      0.07054179161787033,
      0.005322090350091457,
      -0.023205291479825974,
      -0.033757489174604416,
      -0.03939763084053993,
      0.06341031193733215,
      -0.005930289626121521,
      -0.07606762647628784,
      -0.0033802450634539127,
      0.03342541307210922,
      -0.03978247940540314,
      0.017288589850068092,
      0.1357126086950302,
      -0.08255889266729355,
      -0.05353963002562523,
      -0.03454453870654106,
      -0.011568066664040089,
      -0.0512242466211319,
      0.04179510474205017,
      0.03391634300351143,
      0.10981186479330063,
      0.01214527152478695,
      0.027771197259426117,
      0.034604188054800034,
      0.02321990765631199,
      0.005742613226175308,
      -0.00124645687174052,
      0.012197023257613182,
      -0.09618624299764633,
      -0.060493357479572296,
      -0.026065368205308914,
      -0.026588425040245056,
      0.010787817649543285,
      0.023035626858472824,
      0.08518633246421814,
      0.04209965094923973,
      0.050831206142902374,
      0.012418386526405811,
      0.006559893488883972,
      -0.017144808545708656,
      -0.10963018238544464,
      -0.006253102328628302,
      -0.011763329617679119,
      -0.06359227746725082,
      0.03964851796627045,
      0.021878531202673912,
      0.0010561586823314428,
      0.005289709661155939,
      0.004027879796922207,
      0.0167855117470026,
      0.05234648287296295,
      -0.024089714512228966,
      0.002083750441670418,
      -0.00036097137490287423,
      -0.00918512511998415,
      -0.01591053232550621,
      0.03454228490591049,
      0.004567482974380255,
      0.026313049718737602,
      0.013350915163755417,
      0.026843536645174026,
      -0.056941110640764236,
      0.04001735523343086,
      0.04465296119451523,
      0.00613287603482604,
      0.09231424331665039,
      -0.03484854847192764,
      -0.010482296347618103,
      -0.07956075668334961,
      -0.01985039748251438,
      -0.03481220453977585,
      -0.022591877728700638,
      -0.05475864186882973,
      -0.07848065346479416,
      0.022402038797736168,
      0.014175084419548512,
      0.017774922773241997,
      -0.0472838468849659,
      0.011319750919938087,
      -0.020810332149267197,
      0.05901549011468887,
      -0.0312923789024353,
      0.009236163459718227,
      0.025184813886880875,
      0.001893842127174139,
      0.05030478164553642,
      -0.02853452041745186,
      0.0017787461401894689,
      0.009425331838428974,
      0.030831841751933098,
      0.010063792578876019,
      -0.05826256424188614,
      0.013971323147416115,
      -0.08094163239002228,
      -0.006059592589735985,
      -0.0005142666050232947,
      0.04102715477347374,
      -0.1056494489312172,
      -0.011035004630684853,
      0.05856543406844139,
      -0.0484120175242424,
      -0.029696611687541008,
      0.08445301651954651,
      0.06175879389047623,
      0.0017529147444292903,
      -0.022985540330410004,
      0.005608824081718922,
      -0.010204663500189781,
      0.062369175255298615,
      -0.003447572700679302,
      -0.06005266681313515,
      -0.021938174962997437,
      -0.03924579173326492,
      0.005481940694153309,
      -0.033079907298088074,
      0.005907206796109676,
      -0.00506554963067174,
      0.006915707141160965,
      0.0068183233961462975,
      0.03812982141971588,
      0.042741235345602036,
      -0.02214524894952774,
      -0.03836720809340477,
      -0.031264591962099075,
      -0.03199246525764465,
      0.10597489029169083,
      -0.01292352844029665,
      -0.08089591562747955,
      0.09827110171318054,
      0.025223053991794586,
      0.08111380785703659,
      -0.06430424004793167,
      -0.009750612080097198,
      0.05905540660023689,
      -0.10932081192731857,
      0.030748004093766212,
      0.11889034509658813,
      0.02367476187646389,
      -0.007983719930052757,
      0.006596148479729891,
      0.10712932795286179,
      0.05317508801817894,
      0.04778935760259628,
      0.09048628062009811,
      0.025635218247771263,
      -0.04915895685553551,
      -0.09582880139350891,
      0.04572409763932228,
      0.066047303378582,
      -0.029489625245332718,
      -0.028584087267518044,
      -0.06407395750284195,
      0.0769486203789711,
      -0.11240755021572113,
      0.0579022653400898,
      -0.03600125387310982,
      0.028501426801085472,
      -0.051531773060560226,
      0.09868857264518738,
      -0.01733190380036831,
      0.055808212608098984,
      0.0038146174047142267,
      0.08480589091777802,
      0.0023377849720418453,
      0.016893036663532257,
      -0.0016677019884809852,
      -0.12862245738506317,
      -0.03787337243556976,
      -0.06575959920883179,
      0.01438931841403246,
      -0.031557608395814896,
      -0.016240587458014488,
      -0.007821626961231232,
      -0.028596125543117523,
      0.041700251400470734,
      -0.02797459438443184,
      -0.05317845940589905,
      -0.02197951078414917,
      -0.04209248721599579,
      0.0029826718382537365,
      -0.029636230319738388,
      -0.006394665688276291,
      -0.010324036702513695,
      0.06457282602787018,
      0.0310748890042305,
      0.01889326237142086,
      -0.017041752114892006,
      -0.08862313628196716,
      0.008712111972272396,
      0.007240200415253639,
      0.004884600173681974,
      -0.001790615264326334,
      0.04932668060064316,
      -0.02307208999991417,
      -0.06762375682592392,
      0.0015809263568371534,
      -0.003203859319910407,
      -0.013589662499725819,
      0.008633246645331383,
      -0.05136076360940933,
      -0.024868307635188103,
      -0.06156108155846596,
      -0.007487249094992876,
      0.0077199009247124195,
      -0.10760544240474701,
      -0.02605903521180153,
      0.050317686051130295,
      0.04231218993663788,
      -0.04438794404268265,
      0.048064883798360825,
      -0.05442347750067711,
      -0.035621676594018936,
      -0.10255881398916245,
      0.019206341356039047,
      0.012229903601109982,
      0.003754007164388895,
      -0.024298153817653656,
      0.00027477386174723506,
      -0.017724836245179176,
      0.021309126168489456,
      -0.03500812500715256,
      -0.05324064940214157,
      -0.02593349851667881,
      0.002354125725105405,
      0.022277483716607094,
      0.04629167914390564,
      0.025235600769519806,
      -0.03582156077027321,
      -0.07321058213710785,
      0.003040061565116048,
      0.07901374250650406,
      0.03302093967795372,
      -0.03993934392929077,
      -0.07225579768419266,
      -0.08214108645915985,
      -0.09716545790433884,
      0.038709498941898346,
      -0.05207262560725212,
      0.049091704189777374,
      -0.09088873863220215,
      -0.0075108823366463184,
      0.06861861050128937,
      -0.062377121299505234,
      -0.015020743012428284,
      0.009440471418201923,
      0.00047844561049714684,
      -0.019709179177880287,
      -0.04909798130393028,
      -0.034082453697919846,
      -0.009815637022256851,
      0.003475662786513567,
      -0.05721060931682587,
      -0.047283370047807693,
      -0.02235966920852661,
      0.05562562495470047,
      0.04062748700380325,
      -0.08963527530431747,
      0.0045684021897614,
      0.062108397483825684,
      -0.0738673135638237,
      0.009868794120848179,
      0.06335821747779846,
      -0.02608209103345871,
      -0.0067779370583593845,
      -0.019670963287353516,
      -0.015500971116125584,
      -0.045375704765319824,
      -0.023699073120951653,
      -0.03368254005908966,
      0.04838240146636963,
      0.04397624731063843,
      0.05171848461031914,
      0.08559771627187729,
      0.027227772399783134,
      -0.048524122685194016,
      -0.0020943377166986465,
      -0.03377414867281914,
      -0.02189064770936966,
      -0.03713323920965195,
      -0.028804810717701912,
      -0.042349617928266525,
      -0.03721660375595093,
      0.05433771014213562,
      0.017428459599614143,
      0.026714276522397995,
      -0.040445104241371155,
      -0.09055313467979431,
      -0.022275229915976524,
      -0.01719857193529606,
      0.023582011461257935,
      -0.0022276148665696383,
      0.03721264377236366,
      -0.042919259518384933,
      0.029014335945248604,
      -0.01862356998026371,
      0.07699407637119293,
      -0.03274941444396973,
      0.05306629836559296,
      0.07675246149301529,
      -0.014562100172042847,
      0.030626799911260605,
      -0.05839824303984642,
      -0.0015850919298827648,
      -0.011129858903586864,
      -0.011174174025654793,
      -0.013232972472906113,
      0.028026239946484566,
      -0.08147424459457397,
      -0.0315527506172657,
      0.048403989523649216,
      -0.03560890629887581,
      -0.004459465388208628,
      -0.037177179008722305,
      -0.07230726629495621,
      0.004911867436021566,
      -0.02283170074224472,
      0.057799432426691055,
      0.026009388267993927,
      -0.01896461471915245,
      -0.03322000429034233,
      -0.03184438869357109,
      -0.04878055304288864,
      -0.03931516408920288,
      -0.04159928858280182,
      -0.011245694011449814,
      0.07599176466464996,
      0.033679697662591934,
      0.058561183512210846,
      -0.047803930938243866,
      0.07360198348760605,
      0.06312073767185211,
      -0.0018697393825277686,
      -0.05395085737109184,
      -0.01600949466228485,
      -0.011661608703434467,
      -0.0663626566529274,
      -0.09236981719732285,
      0.021673351526260376,
      0.0008415451156906784,
      -0.015446914359927177,
      0.016404569149017334,
      0.09400548040866852,
      -0.0215594545006752,
      -0.011308465152978897,
      0.011314144358038902,
      -0.08163757622241974,
      -0.02370675466954708,
      0.0016876752488315105,
      0.0050200205296278,
      -0.002716329414397478,
      0.00737718353047967,
      -0.007979894056916237,
      0.015448317863047123,
      0.031263355165719986,
      -0.013731168583035469,
      -0.07576866447925568,
      0.05815760791301727,
      0.0022889855317771435,
      -0.0268575306981802,
      0.05623311921954155,
      -0.002104232320562005,
      -0.0017967855092138052,
      0.009230373427271843,
      -0.00023555233201477677,
      0.06794637441635132,
      0.08338148146867752,
      -0.06272785365581512,
      0.004869732540100813,
      0.03635920211672783,
      -0.013242996297776699,
      -0.06845822930335999,
      -0.04854670166969299,
      0.019222427159547806,
      0.0013162025716155767,
      0.05734453722834587,
      0.020632009953260422,
      0.010397403500974178,
      -0.008239793591201305,
      -0.00023016023624222726,
      0.05496055632829666,
      -0.010796214453876019,
      -0.029283665120601654,
      0.03820390999317169,
      0.04026981443166733,
      -0.026788152754306793,
      -0.04144160449504852,
      -0.07332021743059158,
      0.052238304167985916,
      0.037630632519721985,
      -0.05811621993780136,
      0.008100353181362152,
      -0.02008947916328907,
      -0.02831018902361393,
      0.048151541501283646,
      0.0015514232218265533,
      0.05671696737408638,
      0.04044940695166588,
      -0.04262223467230797,
      0.040294066071510315,
      -0.005899603944271803,
      0.07553879916667938,
      -0.013696549460291862,
      -0.01735714264214039,
      -0.04545293003320694,
      0.01687832549214363,
      0.055008117109537125,
      0.020359160378575325,
      0.014673999510705471,
      -0.010344581678509712,
      -0.014592344872653484,
      0.054328881204128265,
      0.02312668226659298,
      0.07036948204040527,
      0.061825428158044815,
      0.02801746502518654,
      0.020055729895830154,
      0.024014895781874657,
      0.06644074618816376,
      -0.0025887414813041687,
      0.009602086618542671,
      -0.10032853484153748,
      0.03202417865395546,
      0.01624513790011406,
      -0.0372110940515995,
      0.027983728796243668,
      -0.0224558487534523,
      -0.04660759121179581
    ],
    [
      0.0344926081597805,
      -0.00010970146104227751,
      -0.016574669629335403,
      -0.0030634566210210323,
      -0.007816573604941368,
      -0.012890844605863094,
      0.004743887111544609,
      -0.04037145525217056,
      0.05305779352784157,
      0.09181763231754303,
      -0.00012797629460692406,
      -0.025930220261216164,
      -0.04247558116912842,
      -0.013846625573933125,
      -0.04187539592385292,
      -0.05090360343456268,
      0.011402091942727566,
      0.02497061714529991,
      0.030332541093230247,
      0.013562376610934734,
      -0.02830488421022892,
      -0.03061526268720627,
      0.028177453204989433,
      0.05841365456581116,
      -0.014476384036242962,
      0.05840302258729935,
      -0.07290396094322205,
      0.04138078913092613,
      -0.03027927502989769,
      0.057875100523233414,
      0.0009546898072585464,
      -0.13989099860191345,
      -0.02851194329559803,
      -0.001124560716561973,
      0.009187988936901093,
      -0.022688593715429306,
      0.05315512418746948,
      0.01888946443796158,
      -0.0075464737601578236,
      -0.12493665516376495,
      -0.002729439875110984,
      -0.04890948161482811,
      0.006120461504906416,
      -0.04457506164908409,
      0.01417101826518774,
      -0.02766047604382038,
      -0.07174978405237198,
      0.024574164301156998,
      -0.031999751925468445,
      0.033763300627470016,
      -0.06429567188024521,
      0.10065733641386032,
      0.010674734599888325,
      0.032679829746484756,
      0.00457586208358407,
      0.0038118967786431313,
      -0.02272840030491352,
      -0.011296787299215794,
      -0.00472623948007822,
      -0.011097289621829987,
      -0.0181789081543684,
      -0.0012103066546842456,
      0.07419326901435852,
      -0.019894545897841454,
      0.029899565503001213,
      -0.05118739977478981,
      -0.018512897193431854,
      0.01669340766966343,
      0.006316622253507376,
      -0.07260920852422714,
      0.019918937236070633,
      -0.01365108136087656,
      0.0159546360373497,
      -0.017059899866580963,
      0.020992787554860115,
      0.03510472923517227,
      0.05202031508088112,
      0.01636066846549511,
      -0.0706530287861824,
      -0.0025818634312599897,
      -0.0019424202619120479,
      0.02147032506763935,
      -0.017185349017381668,
      -0.01184870582073927,
      -0.05127258971333504,
      0.044995758682489395,
      -0.00367159117013216,
      -0.006019669584929943,
      0.04811539873480797,
      0.014261333271861076,
      -0.03677404671907425,
      -0.0015969870146363974,
      -0.0018375074723735452,
      0.01893087662756443,
      0.0004720246943179518,
      -0.008664892986416817,
      -0.06683998554944992,
      -0.055866848677396774,
      0.05316369980573654,
      -0.03783559054136276,
      0.005493986885994673,
      -0.0676218718290329,
      0.07890530675649643,
      0.047699760645627975,
      -0.01473784726113081,
      0.09506411850452423,
      0.03486601263284683,
      0.026446575298905373,
      0.0644780695438385,
      -0.0014998840633779764,
      0.0764654204249382,
      0.021836552768945694,
      -0.05138276144862175,
      -0.0018988490337505937,
      -0.07552952319383621,
      0.03548206388950348,
      0.027034062892198563,
      0.03157222270965576,
      0.00876016914844513,
      0.04199595749378204,
      0.09402777999639511,
      -0.03347958251833916,
      0.08554936945438385,
      0.06516646593809128,
      -0.052953049540519714,
      -0.019207337871193886,
      -0.022219663485884666,
      -0.005836083088070154,
      0.06315009295940399,
      0.05616125836968422,
      -0.0041987095028162,
      0.05674118921160698,
      0.07178134471178055,
      -0.02394874021410942,
      0.009177534841001034,
      -0.008158831857144833,
      0.07065504789352417,
      0.002178866183385253,
      0.026861460879445076,
      0.03237535059452057,
      0.018187565729022026,
      0.015656957402825356,
      -0.06642139703035355,
      -0.039774615317583084,
      0.032400358468294144,
      -0.05584002286195755,
      -0.002910354407504201,
      -0.006236848421394825,
      -0.03621130436658859,
      0.004660211503505707,
      0.021803513169288635,
      0.08081717044115067,
      0.00721733458340168,
      -0.012393701821565628,
      -0.06076992675662041,
      0.014009206555783749,
      -0.02503751590847969,
      -0.024567849934101105,
      -0.08145757019519806,
      -0.007862833328545094,
      -0.022810770198702812,
      0.021323231980204582,
      0.07108677178621292,
      -0.047173645347356796,
      0.012382551096379757,
      0.027553966268897057,
      -0.010801083408296108,
      -0.03437744453549385,
      0.01831487938761711,
      -0.07347189635038376,
      0.014199533499777317,
      0.0009818042162805796,
      -0.005832394119352102,
      0.037901267409324646,
      -0.018589025363326073,
      -0.07324973493814468,
      -0.0014394683530554175,
      -0.02691705897450447,
      0.02898218296468258,
      0.03660118579864502,
      -0.02301206812262535,
      0.006360707804560661,
      0.022867698222398758,
      0.017687955871224403,
      -0.1203935444355011,
      0.08798954635858536,
      0.025011152029037476,
      -0.07330505549907684,
      0.04178909957408905,
      -0.015346392057836056,
      -0.008444470353424549,
      -0.03887239098548889,
      -0.03622788190841675,
      0.016226954758167267,
      0.004832063335925341,
      0.013110212981700897,
      0.02386351488530636,
      0.04297300800681114,
      -0.0675983726978302,
      0.011115938425064087,
      -0.02934216894209385,
      -0.02640160359442234,
      -0.03878270089626312,
      0.03686154633760452,
      -0.012354792095720768,
      -0.022838205099105835,
      0.04540415480732918,
      0.002282633213326335,
      -0.0515817366540432,
      -0.10382699966430664,
      -0.009690151549875736,
      0.020806042477488518,
      0.0658915713429451,
      -0.009319500997662544,
      0.013595680706202984,
      0.10376976430416107,
      -0.02040976844727993,
      -0.009861019439995289,
      0.029160423204302788,
      -0.060572922229766846,
      0.005872928537428379,
      0.0396839864552021,
      -0.030353505164384842,
      -0.030566412955522537,
      0.040457550436258316,
      0.0387486033141613,
      -0.0034974906593561172,
      -0.04080662131309509,
      -0.08773376047611237,
      0.05452167987823486,
      -0.04661719128489494,
      0.06845249235630035,
      0.05432911589741707,
      -0.008610887452960014,
      0.028670771047472954,
      -0.031494420021772385,
      -0.020040756091475487,
      -0.10597878694534302,
      0.017516931518912315,
      0.0030317199416458607,
      -0.08693546801805496,
      -0.07250113040208817,
      0.07446597516536713,
      -0.04499228298664093,
      -0.04443793743848801,
      0.07033617794513702,
      0.04864677041769028,
      0.043167658150196075,
      0.049318354576826096,
      -0.000564670714084059,
      0.009249712340533733,
      -0.013441006653010845,
      -0.010440354235470295,
      -0.06546100974082947,
      -0.023620454594492912,
      -0.008836781606078148,
      0.005599410273134708,
      -0.10799343883991241,
      0.004426306113600731,
      0.02113693580031395,
      0.030602330341935158,
      0.05343340337276459,
      -0.0020499075762927532,
      -0.030733445659279823,
      0.049208395183086395,
      0.01100564282387495,
      0.03427712246775627,
      0.03497624024748802,
      -0.0014398259809240699,
      0.006359393708407879,
      -0.07116395235061646,
      0.03158169612288475,
      0.016502195969223976,
      -0.003336085705086589,
      -0.03189243748784065,
      0.040250882506370544,
      0.04085451737046242,
      -0.009293857961893082,
      0.02262786403298378,
      0.01128165703266859,
      0.004727508872747421,
      -0.012597587890923023,
      0.008951131254434586,
      -0.059754956513643265,
      0.028939953073859215,
      0.047567371279001236,
      -0.020108293741941452,
      -0.019925342872738838,
      0.021037332713603973,
      -0.09323158115148544,
      -0.03477054461836815,
      0.011365270242094994,
      0.02986093796789646,
      -0.023894648998975754,
      0.01645129919052124,
      0.060084644705057144,
      -0.04152466729283333,
      -0.046019863337278366,
      -0.004459230229258537,
      0.054887451231479645,
      -0.022394733503460884,
      -0.016899721696972847,
      -0.05133683606982231,
      0.00461291428655386,
      -0.04431977868080139,
      0.012532220222055912,
      -0.05612535402178764,
      -0.02361430414021015,
      -0.14323700964450836,
      0.07168256491422653,
      0.07478251308202744,
      -0.03589547798037529,
      -0.011431951075792313,
      -0.021083181723952293,
      0.0272436011582613,
      -0.17669840157032013,
      -0.051977600902318954,
      0.027531856670975685,
      0.010046204552054405,
      -0.04802719131112099,
      -0.024503640830516815,
      -0.007360300049185753,
      0.016232283785939217,
      -0.029454195871949196,
      -0.004269121214747429,
      0.05247804895043373,
      -0.06408561021089554,
      0.02581784501671791,
      -0.011662119068205357,
      -0.020031055435538292,
      0.0474679060280323,
      0.05892101675271988,
      -0.03396147862076759,
      0.06478020548820496,
      -0.05678827688097954,
      -0.03799257054924965,
      -0.012911969795823097,
      -0.04155416786670685,
      -0.08886241167783737,
      -0.010949909687042236,
      0.06619447469711304,
      0.001536752562969923,
      -0.00984470546245575,
      0.06174139678478241,
      -0.019949203357100487,
      0.01767110824584961,
      -0.012497493997216225,
      0.0061745732091367245,
      0.007467472460120916,
      -0.044715236872434616,
      0.08630935847759247,
      0.06963837146759033,
      0.02853390946984291,
      -0.020932074636220932,
      0.00476796505972743,
      0.028870267793536186,
      -0.01277114450931549,
      0.02276763506233692,
      0.05667581036686897,
      -0.04986036196351051,
      0.03557617962360382,
      0.02103721722960472,
      -0.023200690746307373,
      0.006236295681446791,
      -0.004908662289381027,
      0.0929470807313919,
      -0.05756528303027153,
      0.0015878560952842236,
      0.03284700587391853,
      0.032574284821748734,
      -0.030147291719913483,
      -0.01067876536399126,
      -0.056462764739990234,
      0.03744197264313698,
      -0.03992360085248947,
      -0.007982537150382996,
      0.011007781140506268,
      -0.006775300949811935,
      0.05688535049557686,
      -0.06560654938220978,
      -0.039332542568445206,
      0.041424382477998734,
      -0.04857461899518967,
      0.01599189266562462,
      0.0475802943110466,
      0.10522366315126419,
      0.0019956694450229406,
      0.02861786261200905,
      -0.0033180748578161,
      -0.019035277888178825,
      0.012970332987606525,
      -0.05137638375163078,
      0.051335837692022324,
      0.06605406105518341,
      -0.01346861757338047,
      0.004851871635764837,
      -0.0025230664759874344,
      0.014421285130083561,
      -0.04266609996557236,
      -0.06852532178163528,
      -0.007224178873002529,
      -0.059663865715265274,
      0.03737207129597664,
      -0.03693464398384094,
      0.0022948256228119135,
      -0.026926541700959206,
      -0.0134242232888937,
      0.00713317608460784,
      0.00721896905452013,
      -0.0007105754339136183,
      0.06101538985967636,
      0.028880244120955467,
      -0.01205088198184967,
      -0.027042390778660774,
      -0.02344805933535099,
      -0.0033046596217900515,
      0.025761336088180542,
      -0.0623416006565094,
      0.022526096552610397,
      0.009988191537559032,
      -0.0050530824810266495,
      -0.01937190629541874,
      -0.024314958602190018,
      -0.0025916434824466705,
      0.010097927413880825,
      -0.008265034295618534,
      0.02829437516629696,
      0.026779847219586372,
      0.06940145045518875,
      -0.022459445521235466,
      0.08725956827402115,
      -0.017608728259801865,
      -0.03703971207141876,
      0.026557598263025284,
      -0.08755254745483398,
      -0.000224917646846734,
      -0.028393426910042763,
      -0.04671308025717735,
      -0.002167345490306616,
      0.03948967903852463,
      0.07926533371210098,
      -0.05216839909553528,
      0.012327130883932114,
      -0.006342506967484951,
      -0.0356929711997509,
      -0.016525592654943466,
      0.002093676710501313,
      -0.06994255632162094,
      0.03454351797699928,
      0.03520745784044266,
      0.050602491945028305,
      -0.01274208351969719,
      -0.01280261017382145,
      0.043214909732341766,
      -0.01821841299533844,
      0.09645865112543106,
      -0.00894967745989561,
      -0.009133568033576012,
      -0.028763994574546814,
      -0.024352414533495903,
      0.018958887085318565,
      -0.039287734776735306,
      -0.028653545305132866,
      0.10945765674114227,
      0.06584403663873672,
      -0.043913502246141434,
      0.02207772806286812,
      0.003911083564162254,
      -0.051235686987638474,
      0.07033282518386841,
      -0.021966179832816124,
      -0.0010507518891245127,
      0.06649374961853027,
      0.010631934739649296,
      0.015548422001302242,
      0.05055268481373787,
      -0.048791080713272095,
      -0.025613125413656235,
      -0.027180157601833344,
      -0.03331809118390083,
      0.033519476652145386,
      0.07500280439853668,
      -0.029991328716278076,
      0.06683024764060974,
      0.0241257157176733,
      0.0346362367272377,
      0.04811250790953636,
      -0.08066534996032715,
      0.06551210582256317,
      0.04249891638755798,
      0.0927196741104126,
      -0.0018359061796218157,
      -0.0008580299909226596,
      0.009531771764159203,
      0.051339101046323776,
      -0.01663435809314251,
      0.020676668733358383,
      0.03743715211749077,
      -0.0447884202003479,
      0.015075914561748505,
      -0.00658311415463686,
      -0.05088922753930092,
      -0.027180273085832596
    ],
    [
      0.06551855802536011,
      0.011313963681459427,
      0.055622488260269165,
      -0.03204832226037979,
      -0.032100796699523926,
      0.023008093237876892,
      0.03850819915533066,
      0.0632554218173027,
      0.034899670630693436,
      0.054820746183395386,
      0.002120266668498516,
      0.018313350155949593,
      0.06852176785469055,
      0.017827708274126053,
      0.10815153270959854,
      0.03420032188296318,
      0.05265708640217781,
      -0.052911512553691864,
      -0.08522804081439972,
      0.025679459795355797,
      0.0038019870407879353,
      -0.006555830594152212,
      0.07715902477502823,
      0.019746409729123116,
      -0.08859463781118393,
      0.07000355422496796,
      0.014836885966360569,
      0.03703857213258743,
      0.051470231264829636,
      -0.05905786529183388,
      -0.03319685533642769,
      -0.002055971883237362,
      0.03462671488523483,
      0.009652990847826004,
      0.020178459584712982,
      -0.045647162944078445,
      0.0018024301389232278,
      0.025659436360001564,
      0.033424247056245804,
      -0.027864325791597366,
      -0.024214401841163635,
      0.028156202286481857,
      -0.038193367421627045,
      0.018339063972234726,
      -0.006702984217554331,
      0.04144187644124031,
      -0.05076386779546738,
      0.032224141061306,
      0.05105767771601677,
      -0.03503282740712166,
      -0.07296149432659149,
      0.07631488144397736,
      0.0789104625582695,
      -0.020951982587575912,
      -0.061082303524017334,
      -0.017877116799354553,
      -0.14394570887088776,
      0.07725556939840317,
      0.05900013819336891,
      -0.006353749427944422,
      -0.018566668033599854,
      0.038789041340351105,
      -0.0018648022087290883,
      0.047699373215436935,
      0.029774313792586327,
      0.012263073585927486,
      0.017404522746801376,
      -0.04125795140862465,
      -0.017494268715381622,
      -0.06888450682163239,
      -0.00718733761459589,
      -0.0328136645257473,
      -0.06697429716587067,
      0.024830535054206848,
      0.05117496848106384,
      -0.02957037277519703,
      -0.07992181181907654,
      -0.058286264538764954,
      -0.0623534619808197,
      0.011753655970096588,
      -0.042715493589639664,
      -0.014424600638449192,
      0.012865202501416206,
      0.03458843752741814,
      -0.02074180729687214,
      0.056325823068618774,
      -0.06920677423477173,
      0.031166044995188713,
      0.04602416604757309,
      -0.010338102467358112,
      -0.0940212607383728,
      -0.06357365846633911,
      -0.040553394705057144,
      0.0029953629709780216,
      0.04300263151526451,
      0.09258349239826202,
      -0.01250546146184206,
      -0.026740994304418564,
      0.054324179887771606,
      -0.017945583909749985,
      0.08860038220882416,
      0.06411345303058624,
      -0.012383281253278255,
      0.03085959516465664,
      -0.11574807018041611,
      -0.0025946632958948612,
      -0.0694531798362732,
      -0.0027458579279482365,
      0.060822177678346634,
      0.09121283888816833,
      0.09023166447877884,
      -0.02682972326874733,
      -0.017102835699915886,
      -0.043614260852336884,
      -0.049750909209251404,
      -0.02441313862800598,
      -0.027921007946133614,
      0.06347230076789856,
      0.04474397003650665,
      -0.033211227506399155,
      0.004275370854884386,
      -0.08912963420152664,
      -0.023250490427017212,
      0.0005937572568655014,
      -0.036535248160362244,
      -0.0013729371130466461,
      0.031407058238983154,
      -0.026254933327436447,
      0.10235797613859177,
      -0.06863459199666977,
      -0.008304600603878498,
      0.043857693672180176,
      0.0061732009053230286,
      0.045965101569890976,
      -0.06731576472520828,
      -0.059013720601797104,
      -0.014585166238248348,
      0.02727552503347397,
      0.026188671588897705,
      0.0018248405540362,
      -0.07093118131160736,
      -0.009114536456763744,
      -0.008671808987855911,
      0.009181655012071133,
      0.04131002724170685,
      -0.05503585562109947,
      0.015066166408360004,
      -0.010475394316017628,
      0.03741241618990898,
      0.002385613741353154,
      0.02653239667415619,
      0.03532705828547478,
      -0.05071356147527695,
      -0.005277917720377445,
      -0.013694086112082005,
      0.003984157461673021,
      0.010196498595178127,
      -0.03492508456110954,
      -0.02314458228647709,
      0.05303393676877022,
      -0.022481244057416916,
      -0.04877353087067604,
      0.0146816810593009,
      0.025162823498249054,
      -0.07591845840215683,
      0.020066238939762115,
      0.004243139177560806,
      -0.008013101294636726,
      0.039120376110076904,
      0.04408586025238037,
      -0.008446890860795975,
      -0.07019896060228348,
      -0.03158126026391983,
      -0.0064074150286614895,
      -0.03936218470335007,
      0.0066332570277154446,
      -0.04730931296944618,
      0.05805389583110809,
      0.02296551875770092,
      0.06098568066954613,
      0.0378464050590992,
      -0.00878334604203701,
      -0.05718696862459183,
      -0.02685893140733242,
      0.0010411065304651856,
      0.03586454316973686,
      0.012453795410692692,
      -0.06053568050265312,
      0.07359550148248672,
      0.03975941613316536,
      -0.036313772201538086,
      -0.023066498339176178,
      -0.0010663771536201239,
      -0.0537070594727993,
      0.020488958805799484,
      0.016572827473282814,
      0.07596663385629654,
      -0.02343813143670559,
      -0.012715157121419907,
      -0.024017715826630592,
      -0.01880398578941822,
      0.0735241249203682,
      0.02454184740781784,
      0.06523574888706207,
      -0.01103229634463787,
      -0.019430773332715034,
      -0.043234240263700485,
      -0.013559258542954922,
      -0.0439489372074604,
      -0.003964475356042385,
      -0.096135713160038,
      0.015774236992001534,
      -0.001482511404901743,
      0.10848429054021835,
      0.00302777998149395,
      0.045765724033117294,
      0.014274933375418186,
      0.01583094149827957,
      -0.03921264782547951,
      -0.042219292372465134,
      0.024255303665995598,
      0.018541019409894943,
      -0.01826549507677555,
      0.09509845077991486,
      0.031069617718458176,
      -0.06992325186729431,
      0.01146116852760315,
      0.005164719186723232,
      0.0044341133907437325,
      0.03852732107043266,
      -0.01246311143040657,
      0.05610807240009308,
      0.010489249601960182,
      -0.03728233650326729,
      4.563752736430615e-05,
      0.013271930627524853,
      -0.04426185041666031,
      0.07022958993911743,
      -0.02731405571103096,
      -0.006427309941500425,
      -0.044547684490680695,
      0.06303850561380386,
      0.10358336567878723,
      -0.04697633907198906,
      0.08400630950927734,
      0.10531360656023026,
      -0.04181015491485596,
      0.012451832182705402,
      -0.027771171182394028,
      -0.05284684896469116,
      -0.045960742980241776,
      -0.009096095338463783,
      -0.04310699552297592,
      -0.05438217148184776,
      0.00860470812767744,
      0.0045051067136228085,
      -0.0341547429561615,
      -0.002936076372861862,
      -0.0699039101600647,
      0.0061597637832164764,
      -0.014126385562121868,
      0.05214376747608185,
      -0.002265088027343154,
      0.060775276273489,
      0.04504011198878288,
      -0.009504401125013828,
      0.0009858852718025446,
      0.03639841824769974,
      -0.04613608866930008,
      -0.05908304080367088,
      -0.04033445939421654,
      0.03177864849567413,
      0.009532642550766468,
      0.009120400063693523,
      0.022968359291553497,
      -0.02623159810900688,
      -0.02142009139060974,
      0.04484126716852188,
      -0.06231627240777016,
      0.014652552083134651,
      0.033620189875364304,
      -0.004811679944396019,
      -0.05254467949271202,
      -0.007489611394703388,
      0.008607898838818073,
      0.005503356922417879,
      0.008721739053726196,
      0.04749484360218048,
      0.052715010941028595,
      -0.0377492718398571,
      0.04573071748018265,
      0.032117437571287155,
      0.0028761294670403004,
      -0.046531230211257935,
      -0.05297257378697395,
      0.0757911205291748,
      0.02406102605164051,
      -0.0506562776863575,
      0.021638022735714912,
      0.05338749289512634,
      0.018737267702817917,
      0.06766050308942795,
      0.0826277956366539,
      -0.023357268422842026,
      0.03610179200768471,
      0.026691893115639687,
      -0.011589808389544487,
      -0.06776107102632523,
      -0.005443869158625603,
      0.006144639104604721,
      0.02589687891304493,
      -0.05521289259195328,
      -0.023825304582715034,
      0.051421165466308594,
      -2.697283707675524e-05,
      -0.014671389013528824,
      -0.03620658069849014,
      0.00785932969301939,
      -0.10353399068117142,
      0.008322468027472496,
      -0.024576138705015182,
      0.011265881359577179,
      -0.03228400647640228,
      0.03574415668845177,
      0.013567531481385231,
      -0.017860345542430878,
      -0.033446889370679855,
      -0.07241789251565933,
      0.019716521725058556,
      0.027075419202446938,
      -0.03258698806166649,
      0.03803081065416336,
      -0.03632846847176552,
      0.040853746235370636,
      -0.02870389074087143,
      -0.034125253558158875,
      0.011178035289049149,
      0.07537458091974258,
      -0.08355842530727386,
      0.0169354360550642,
      0.026679597795009613,
      0.03634023666381836,
      0.04999832808971405,
      0.006417856086045504,
      0.0358424112200737,
      -0.026343120262026787,
      0.0008823898970149457,
      0.06451163440942764,
      -0.03499535098671913,
      -0.01737452670931816,
      -0.027370987460017204,
      -0.07062683999538422,
      0.08196315169334412,
      -0.02718120627105236,
      0.05928938835859299,
      -0.014695870690047741,
      -0.08149334043264389,
      -0.07294845581054688,
      0.019085651263594627,
      0.018267523497343063,
      0.038391970098018646,
      -0.05345157906413078,
      0.03182804211974144,
      -0.011197288520634174,
      -0.04419814422726631,
      0.01731734164059162,
      0.0019054097356274724,
      0.04240763559937477,
      -0.028861524537205696,
      -0.08467917889356613,
      -0.06304790079593658,
      -0.03094739094376564,
      -0.0012310189194977283,
      -0.02455599419772625,
      -0.04473460838198662,
      -0.023973703384399414,
      0.013462104834616184,
      0.025405051186680794,
      0.04003305733203888,
      0.01977275311946869,
      0.07895153760910034,
      -0.00608393270522356,
      -0.014587619341909885,
      -0.00750847440212965,
      -0.017493940889835358,
      0.1004115641117096,
      -0.020561959594488144,
      0.0003692497266456485,
      -0.08405663073062897,
      -0.024957532063126564,
      0.12409020215272903,
      0.00016896157467272133,
      0.0010939963394775987,
      0.028667328879237175,
      -0.010583044029772282,
      -0.02053780108690262,
      -0.01517679076641798,
      0.04928611218929291,
      -0.0016981548396870494,
      0.02779124304652214,
      0.05679376795887947,
      0.0711512640118599,
      -0.05737737566232681,
      6.847919848951278e-06,
      -0.004373010713607073,
      -0.047634754329919815,
      0.0817190483212471,
      0.0535457618534565,
      -0.03366156294941902,
      0.0812302753329277,
      0.05044160038232803,
      -0.041465822607278824,
      -0.028660131618380547,
      0.014157464727759361,
      0.04774677753448486,
      0.09165041148662567,
      0.002457918832078576,
      0.08090484887361526,
      0.006342106498777866,
      -0.06347419321537018,
      -0.022568348795175552,
      -0.04991734400391579,
      0.02471083402633667,
      0.04804401472210884,
      -0.009794607758522034,
      0.02943332865834236,
      -0.0020909865852445364,
      -0.013355984352529049,
      -0.04244689270853996,
      0.056381966918706894,
      0.054662834852933884,
      0.021294744685292244,
      -0.03314327076077461,
      0.008093548007309437,
      0.05938010290265083,
      -0.009231026284396648,
      -0.04009725898504257,
      -0.025258569046854973,
      0.027062304317951202,
      -0.02529122494161129,
      0.04700962081551552,
      -0.05306306853890419,
      0.005200596526265144,
      -0.07724237442016602,
      0.05485032871365547,
      -0.05990554392337799,
      -0.09218034893274307,
      0.01750580407679081,
      0.04975173622369766,
      -0.07877303659915924,
      -0.010462501086294651,
      0.007048914674669504,
      0.03997663035988808,
      0.02580385096371174,
      0.031895313411951065,
      0.01775556243956089,
      0.000648070708848536,
      0.0705018937587738,
      0.006414889357984066,
      0.025844767689704895,
      -0.008705122396349907,
      -0.00855773501098156,
      0.005438546650111675,
      0.013250259682536125,
      -0.10333424061536789,
      -0.04218490794301033,
      0.024449598044157028,
      -0.06488869339227676,
      -0.06776928156614304,
      -0.005349022336304188,
      -0.0514741986989975,
      -0.030008001253008842,
      0.053646497428417206,
      0.008951437659561634,
      0.03582911565899849,
      -0.01635499857366085,
      0.02977682836353779,
      -0.018115591257810593,
      0.05092906206846237,
      0.02144334465265274,
      0.0718747228384018,
      0.002245709765702486,
      -0.006503599230200052,
      -0.06271442770957947,
      0.021241698414087296,
      0.017769373953342438,
      0.0785670354962349,
      -0.02930343523621559,
      0.05416334047913551,
      -0.018081380054354668,
      0.028386937454342842,
      0.02939143404364586,
      -0.04326299950480461,
      0.07804709672927856,
      0.02067725919187069,
      0.005371252074837685,
      -0.14925938844680786,
      0.06020788848400116,
      0.02122916840016842,
      -0.05202362686395645,
      0.0679454505443573,
      0.011880006641149521,
      -0.0064120786264538765
    ],
    [
      -0.02143736742436886,
      -0.01506502740085125,
      -0.05533676594495773,
      -0.007596095558255911,
      0.046945586800575256,
      -0.009822391904890537,
      0.014689270406961441,
      -0.072905033826828,
      0.021213170140981674,
      -0.03534172102808952,
      -0.005825681146234274,
      0.0016850077081471682,
      -0.02200905978679657,
      0.013634922914206982,
      -0.10307126492261887,
      -0.008061208762228489,
      -0.011622389778494835,
      -0.10406039655208588,
      -0.0028743548318743706,
      -0.011529477313160896,
      -0.07179798185825348,
      -0.014246459119021893,
      0.019340209662914276,
      -0.019581172615289688,
      -0.0017164722084999084,
      0.0322590172290802,
      0.018478279933333397,
      -0.016459766775369644,
      0.003626910038292408,
      0.014334035106003284,
      0.05090712755918503,
      -0.011435027234256268,
      -0.021716421470046043,
      -0.0035446982365101576,
      0.03199451416730881,
      0.03142058476805687,
      0.016506511718034744,
      -0.04252201318740845,
      -0.06551195681095123,
      0.008848126046359539,
      0.07588198781013489,
      0.007846180349588394,
      0.01081831194460392,
      0.057068053632974625,
      0.04593699052929878,
      0.011430476792156696,
      -0.08525841683149338,
      0.00220146169885993,
      0.011527610011398792,
      0.02672266960144043,
      -0.011664992198348045,
      -0.03080080822110176,
      0.04767966270446777,
      -0.0015777964144945145,
      -0.04236995801329613,
      0.05367938056588173,
      -0.03490644693374634,
      -0.04406445473432541,
      -0.0414472296833992,
      -0.05385872349143028,
      -0.06675802171230316,
      0.020296046510338783,
      -0.04464919865131378,
      -0.010413113050162792,
      -0.06341428309679031,
      -0.02004636637866497,
      0.05227852985262871,
      -0.0005531565402634442,
      0.028722554445266724,
      0.061172712594270706,
      0.05697775259613991,
      -0.09739209711551666,
      0.025015192106366158,
      0.016012845560908318,
      0.036273226141929626,
      -0.0393846295773983,
      0.055473167449235916,
      0.05686718598008156,
      -0.04216703772544861,
      -0.04548262804746628,
      -0.028949858620762825,
      -0.1273052841424942,
      0.015337346121668816,
      0.007002992555499077,
      0.07560030370950699,
      0.024075591936707497,
      -0.001150317955762148,
      -0.0052534667775034904,
      0.06168828904628754,
      -0.029100356623530388,
      0.005670603830367327,
      -0.025545109063386917,
      -0.039357639849185944,
      -0.0006942151230759919,
      0.10301289707422256,
      0.012133585289120674,
      0.02472654916346073,
      -0.031740058213472366,
      0.05159124359488487,
      -0.045060236006975174,
      0.06695087999105453,
      -0.0007395533611997962,
      0.043675635010004044,
      0.06081618368625641,
      -0.005704034119844437,
      0.00995334517210722,
      0.01171850599348545,
      -0.006950190756469965,
      0.06399454176425934,
      -0.024891002103686333,
      0.07079033553600311,
      0.041388582438230515,
      -0.018082808703184128,
      0.10929834097623825,
      0.037572406232357025,
      -0.04249780625104904,
      -0.038421861827373505,
      0.06497377157211304,
      0.016159379854798317,
      -0.026602167636156082,
      0.025094088166952133,
      0.017411887645721436,
      -0.04945002868771553,
      -0.03441435843706131,
      -0.05715129151940346,
      -0.034497056156396866,
      -0.0396161787211895,
      0.038854967802762985,
      0.044206831604242325,
      -0.062148164957761765,
      0.07231254130601883,
      0.023121949285268784,
      0.006244736723601818,
      0.09872304648160934,
      0.01839319057762623,
      0.0032980218529701233,
      -0.05088276416063309,
      0.021786198019981384,
      -0.019682709127664566,
      -0.02148597128689289,
      -0.09184711426496506,
      -0.02209448628127575,
      -0.03224250674247742,
      -0.03755525127053261,
      0.027694789692759514,
      -0.013172708451747894,
      -0.017708012834191322,
      -0.004783003591001034,
      0.044258054345846176,
      0.0199868343770504,
      0.04914269968867302,
      0.05863445624709129,
      0.048678796738386154,
      0.03367825970053673,
      -0.0036841384135186672,
      -0.031149018555879593,
      0.05115516483783722,
      -0.06821523606777191,
      -0.023695804178714752,
      -0.03996957838535309,
      -0.05916016176342964,
      0.036745186895132065,
      0.06387040764093399,
      -0.03034910000860691,
      -0.0835963636636734,
      0.0364370234310627,
      0.053075410425662994,
      -3.251956150052138e-05,
      0.05018939450383186,
      0.0015289841685444117,
      0.004097654949873686,
      0.10439251363277435,
      -0.009022051468491554,
      0.06512415409088135,
      -0.03720097616314888,
      -0.0029800613410770893,
      -0.021180279552936554,
      0.023227635771036148,
      -0.07147061079740524,
      0.06107865273952484,
      -0.023773396387696266,
      -0.02821202389895916,
      0.005321417935192585,
      -0.011547792702913284,
      0.009001720696687698,
      -0.05426005646586418,
      0.13527347147464752,
      -0.011131643317639828,
      0.06784535199403763,
      0.00729943485930562,
      -0.1096123531460762,
      0.028977662324905396,
      -0.03722262382507324,
      0.03715771064162254,
      -0.04004392772912979,
      -0.011439547874033451,
      0.02668674662709236,
      -0.0847950354218483,
      0.0305694080889225,
      0.03459441289305687,
      0.02966398373246193,
      -0.010107703506946564,
      -0.02656053565442562,
      0.0014488361775875092,
      0.016814175993204117,
      0.010273738764226437,
      -0.01630920171737671,
      -0.04102791100740433,
      0.02430538833141327,
      -0.04397226870059967,
      0.013401937671005726,
      0.015585013665258884,
      0.04302211478352547,
      0.1276211440563202,
      -0.03649795055389404,
      -0.0027504933532327414,
      -0.009426752105355263,
      0.06356626749038696,
      0.010817152447998524,
      -0.03664357587695122,
      0.06695576012134552,
      -0.03136792778968811,
      -0.10075461864471436,
      0.05633512884378433,
      -0.0055124713107943535,
      -0.08744195848703384,
      0.029228776693344116,
      -0.0823800191283226,
      0.05795205757021904,
      0.015333090908825397,
      -0.01650223508477211,
      0.050861600786447525,
      -0.017364146187901497,
      -0.05509815737605095,
      -0.026079639792442322,
      0.003961658570915461,
      -0.08405835926532745,
      0.0022286546882241964,
      0.0847967118024826,
      0.03542982041835785,
      -0.07911878824234009,
      -0.03734682872891426,
      -0.015571908093988895,
      0.05608677864074707,
      0.020568951964378357,
      -0.043716203421354294,
      0.0028190631419420242,
      0.07460194826126099,
      -0.042269378900527954,
      0.051805995404720306,
      -0.037630390375852585,
      -0.005463456269353628,
      0.09344986081123352,
      -0.06676045060157776,
      0.04365530610084534,
      0.08081015199422836,
      0.07328610867261887,
      -0.002059872029349208,
      -0.004237136337906122,
      0.02238929457962513,
      -0.07959702610969543,
      0.06795132905244827,
      -0.07254423201084137,
      0.07860337942838669,
      0.06556987762451172,
      -0.016195055097341537,
      -0.0175526961684227,
      0.024091290310025215,
      0.021790729835629463,
      0.05833076685667038,
      -0.05058557912707329,
      0.05483422055840492,
      0.06018613651394844,
      -0.06185261532664299,
      -0.008330638520419598,
      0.036258649080991745,
      -0.003553695511072874,
      -0.12691184878349304,
      -0.014416912570595741,
      0.007431402336806059,
      -0.013329888693988323,
      0.01872028224170208,
      -0.01171867549419403,
      0.024457301944494247,
      -0.07419240474700928,
      -0.0023962981067597866,
      0.05845554545521736,
      -0.013336950913071632,
      0.003925136290490627,
      0.060018058866262436,
      0.08374558389186859,
      0.02134900540113449,
      0.004839170258492231,
      -0.01035682950168848,
      -0.013986000791192055,
      -0.07348079234361649,
      -0.028424853459000587,
      -0.005274129565805197,
      0.04488230124115944,
      0.008661936037242413,
      0.004292411729693413,
      -0.054402388632297516,
      0.03618438541889191,
      -0.003104440402239561,
      0.022121373564004898,
      0.07057048380374908,
      -0.08359381556510925,
      0.07539787143468857,
      0.00684495409950614,
      -0.04204747453331947,
      0.06342843174934387,
      0.05913357809185982,
      0.05473315343260765,
      -0.04253040626645088,
      0.04081883653998375,
      -0.0719318687915802,
      -0.003887117374688387,
      -0.02084825560450554,
      -0.041782137006521225,
      -0.009751089848577976,
      0.021201632916927338,
      0.05420539900660515,
      -0.09233672171831131,
      0.08741489797830582,
      -0.02337873913347721,
      0.022994903847575188,
      -0.04130867123603821,
      0.06169766187667847,
      0.010436983779072762,
      0.04494062066078186,
      0.014021789655089378,
      -0.007011445239186287,
      -0.036465730518102646,
      -0.028559381142258644,
      -0.007798230275511742,
      0.04625628888607025,
      0.021611031144857407,
      -0.016213804483413696,
      -0.02422289177775383,
      0.005662186071276665,
      0.0838293805718422,
      0.014181841164827347,
      -0.039274975657463074,
      0.01326670777052641,
      0.10327856987714767,
      -0.05640478804707527,
      0.0016840912867337465,
      -0.036002740263938904,
      -0.01443738304078579,
      -0.039251722395420074,
      -0.09011904150247574,
      -0.030145224183797836,
      0.02405090443789959,
      0.07748541235923767,
      -0.05596204847097397,
      0.010111239738762379,
      0.05928181856870651,
      -0.023045668378472328,
      -0.04829879105091095,
      -0.055786699056625366,
      0.05925261974334717,
      -0.06376173347234726,
      0.08612487465143204,
      -0.028105374425649643,
      -0.015854062512516975,
      -0.07582810521125793,
      0.02340030111372471,
      -0.0664033591747284,
      -0.06506966799497604,
      0.02245744690299034,
      -0.01878761500120163,
      0.06011074036359787,
      -0.08365094661712646,
      0.028277166187763214,
      0.01637900061905384,
      0.02277672104537487,
      -0.024268245324492455,
      -0.013574761338531971,
      -0.06480321288108826,
      0.007012516260147095,
      -0.04516626521945,
      -0.043113142251968384,
      0.025244321674108505,
      0.0028911251574754715,
      0.015682542696595192,
      0.004293419886380434,
      -0.07107807695865631,
      0.015696659684181213,
      -0.1337694376707077,
      0.03874073177576065,
      0.03516917675733566,
      0.014755244366824627,
      -0.07659102231264114,
      0.02968876250088215,
      0.0070905014872550964,
      0.026045458391308784,
      -0.0003458320570643991,
      0.1015692949295044,
      0.020686067640781403,
      0.04717080295085907,
      0.04305409640073776,
      0.017923476174473763,
      0.02211456373333931,
      -0.02836744487285614,
      0.005406862590461969,
      0.07114187628030777,
      -0.07059313356876373,
      0.008055032230913639,
      0.06283721327781677,
      0.044546063989400864,
      0.023296305909752846,
      -0.027401214465498924,
      0.05475199222564697,
      -0.025702567771077156,
      0.033686041831970215,
      -0.045291174203157425,
      0.023350104689598083,
      -0.047977689653635025,
      0.0691106989979744,
      0.0433487668633461,
      -0.021085897460579872,
      0.01779116503894329,
      0.002286183414980769,
      -0.028484653681516647,
      -0.0016611990286037326,
      0.06495988368988037,
      0.03293865546584129,
      0.0039031291380524635,
      -0.02449638769030571,
      0.039892878383398056,
      0.011349785141646862,
      -0.011590580455958843,
      -0.0054208217188715935,
      -0.02400825545191765,
      -0.017702773213386536,
      -0.056248415261507034,
      0.045437272638082504,
      0.006049324292689562,
      0.0019925343804061413,
      -0.011315103620290756,
      -0.03250667452812195,
      -0.08138998597860336,
      -0.009781569242477417,
      -0.06723868101835251,
      -0.05035792291164398,
      -0.025340232998132706,
      0.012448379769921303,
      0.006289293523877859,
      0.08643841743469238,
      0.011512909084558487,
      -0.0016677388921380043,
      -0.018133630976080894,
      -0.008200637064874172,
      -0.027812030166387558,
      0.04116741195321083,
      -0.01688668131828308,
      0.0031051109544932842,
      -0.05561339855194092,
      -0.0018419107655063272,
      -0.019017016515135765,
      -0.001961005851626396,
      0.016039066016674042,
      0.01536144595593214,
      0.053908973932266235,
      0.022870412096381187,
      -0.010270491242408752,
      -0.03170819208025932,
      -0.02443278394639492,
      -0.027569158002734184,
      0.009631388820707798,
      -0.09007032960653305,
      0.000615362951066345,
      0.06275050342082977,
      -0.02469254471361637,
      0.038167860358953476,
      -0.05261601135134697,
      0.03673509508371353,
      -0.03050624206662178,
      -0.03171771019697189,
      -0.08784271031618118,
      0.03461040183901787,
      0.06127380207180977,
      0.04487716406583786,
      -0.025826580822467804,
      -0.07394538819789886,
      -0.06434822827577591,
      0.04044470191001892,
      0.0076537420973181725,
      -0.024130092933773994,
      -0.01710493117570877,
      -0.02610568143427372,
      0.026094608008861542,
      -0.04292871803045273,
      -0.032037403434515,
      0.04465964064002037,
      -0.0040390328504145145,
      -0.009553535841405392,
      0.035035330802202225,
      -0.006333336234092712,
      0.008031866513192654,
      0.02472429722547531,
      0.0412578247487545,
      -0.017080586403608322
    ],
    [
      -0.03403904289007187,
      -0.04264170303940773,
      0.044756632298231125,
      0.04040910676121712,
      -0.021649526432156563,
      0.040894556790590286,
      0.0912141278386116,
      0.007396996952593327,
      -0.012902793474495411,
      -0.016537848860025406,
      -0.04442761838436127,
      0.0017810717690736055,
      0.02424630895256996,
      -0.023359237238764763,
      0.05043203383684158,
      -0.017861025407910347,
      0.0007329765358008444,
      -0.008882338181138039,
      0.04754524677991867,
      -0.029582465067505836,
      -0.01859954744577408,
      -0.047065019607543945,
      -0.008864288218319416,
      -0.09018144011497498,
      -0.09168694913387299,
      0.015765678137540817,
      -0.007123930379748344,
      0.05987074226140976,
      -0.057152532041072845,
      0.05753951147198677,
      -0.04691925272345543,
      -0.029568249359726906,
      -0.02459483966231346,
      -0.0012751725735142827,
      -0.04538821056485176,
      -0.0074683804996311665,
      -0.04453253746032715,
      -0.0042558349668979645,
      -0.02984842285513878,
      -0.01761876977980137,
      0.05864475294947624,
      0.020823489874601364,
      -0.006974807474762201,
      0.04853720963001251,
      0.023642847314476967,
      0.001911828643642366,
      -0.05529230833053589,
      -0.00900168064981699,
      0.06587736308574677,
      -0.09492304921150208,
      -0.03751957044005394,
      -0.030989378690719604,
      0.0065882145427167416,
      -0.005673097912222147,
      0.02511722408235073,
      0.12699207663536072,
      -0.04847507178783417,
      -0.08093955367803574,
      -0.04423579201102257,
      -0.00860973633825779,
      0.0072454591281712055,
      0.02157684415578842,
      -0.025928247720003128,
      0.010094097815454006,
      0.059446003288030624,
      0.08621687442064285,
      -0.02585887908935547,
      0.022552430629730225,
      0.03479677438735962,
      -0.007305165287107229,
      -0.07380817830562592,
      0.05142160505056381,
      -0.03985866159200668,
      -0.00376161583699286,
      -0.05600540712475777,
      -0.003872295841574669,
      0.009245887398719788,
      0.02677365206182003,
      -0.017355013638734818,
      -0.0024273402523249388,
      0.02068951539695263,
      -0.03210119530558586,
      -0.04184705391526222,
      0.0017663483740761876,
      0.03857654333114624,
      0.09541080892086029,
      -0.02447814866900444,
      0.006111763883382082,
      -0.020514149218797684,
      -0.037046853452920914,
      -0.009014777839183807,
      0.012852391228079796,
      0.016559476032853127,
      0.06235890090465546,
      0.01265918742865324,
      -0.05906912684440613,
      -0.0215022973716259,
      0.02215251885354519,
      -0.02633741870522499,
      0.00551633071154356,
      0.037771016359329224,
      -0.06238386407494545,
      -0.008841688744723797,
      0.07720891386270523,
      -0.004145471379160881,
      0.0047377017326653,
      -0.09091208130121231,
      0.015857452526688576,
      0.04015619680285454,
      0.03726336359977722,
      -0.0592336505651474,
      -0.027153266593813896,
      -0.023044250905513763,
      -0.05988520756363869,
      -0.05500339716672897,
      -0.027730021625757217,
      0.06275937706232071,
      -0.012477152049541473,
      -0.029804324731230736,
      0.05881970748305321,
      0.059989284723997116,
      0.03663672134280205,
      -0.023990511894226074,
      -0.03596620634198189,
      -0.020753728225827217,
      0.06595353782176971,
      -0.04822949692606926,
      -0.043858081102371216,
      0.06322162598371506,
      0.003198004560545087,
      -0.008482128381729126,
      0.010860029608011246,
      -0.05897972732782364,
      -0.044906388968229294,
      -0.049009501934051514,
      0.021127281710505486,
      -0.0031785534229129553,
      -0.09527595341205597,
      -0.05749265477061272,
      -0.00936411414295435,
      0.003384716808795929,
      -0.0034601918887346983,
      0.055837132036685944,
      -0.03365888074040413,
      0.04937874153256416,
      -0.07326749712228775,
      0.0005263534258119762,
      0.016443070024251938,
      -0.06900399178266525,
      0.046712491661310196,
      0.04958989843726158,
      0.03512376546859741,
      -0.044991880655288696,
      -0.058552298694849014,
      -0.1185913234949112,
      -0.07145091146230698,
      0.11716613173484802,
      -0.06822731345891953,
      -0.057200413197278976,
      -0.008970439434051514,
      -0.025633446872234344,
      0.019954955205321312,
      0.08118508756160736,
      0.029583916068077087,
      -0.07936899363994598,
      -0.03896282985806465,
      -0.014711608178913593,
      -0.030208192765712738,
      0.04563334956765175,
      0.02164836972951889,
      0.05179290100932121,
      -0.002950002672150731,
      0.014306782744824886,
      -0.03190308064222336,
      0.01764104515314102,
      -0.048363156616687775,
      0.026160145178437233,
      0.024738753214478493,
      -0.061914507299661636,
      -0.01649799384176731,
      -0.02423180267214775,
      -0.009139218367636204,
      0.06309769302606583,
      0.04634208604693413,
      -0.05405043065547943,
      -0.07232557982206345,
      -0.005558261182159185,
      0.05882624536752701,
      -0.024849681183695793,
      -0.035415008664131165,
      -0.03333638608455658,
      -0.0156877338886261,
      0.028889546170830727,
      -0.008557263761758804,
      -0.020772648975253105,
      -0.03140303120017052,
      0.09644387662410736,
      0.00976080633699894,
      -0.00040092633571475744,
      -0.05557841807603836,
      0.029523057863116264,
      -0.015919972211122513,
      -0.08107884228229523,
      -0.04930097237229347,
      -0.017598304897546768,
      -0.030144885182380676,
      -0.07443530112504959,
      -4.482594522414729e-05,
      0.010242823511362076,
      0.11908596754074097,
      -0.09600114822387695,
      0.006785436999052763,
      0.0060419803485274315,
      -0.01069446187466383,
      -0.06673315912485123,
      -0.05846374109387398,
      -0.04189060628414154,
      0.03621735796332359,
      0.03305494040250778,
      0.015561849810183048,
      0.03820287436246872,
      0.033356111496686935,
      -0.05871881544589996,
      0.027882389724254608,
      -0.03333883732557297,
      -0.02023962140083313,
      -0.004385286010801792,
      0.07303796708583832,
      -0.04693882539868355,
      -0.09435813128948212,
      -0.02621236816048622,
      -0.0070909601636230946,
      0.004353091586381197,
      -0.07706814259290695,
      0.050672534853219986,
      -0.025440022349357605,
      -0.0749148279428482,
      0.033501315861940384,
      0.06869002431631088,
      -0.03291633352637291,
      0.012302553281188011,
      -0.0025280825793743134,
      0.04287610948085785,
      -0.05664331838488579,
      -0.037502359598875046,
      0.06328009068965912,
      0.013661453500390053,
      0.08324510604143143,
      -0.0009673330932855606,
      0.013076113536953926,
      0.04457026347517967,
      0.06830018013715744,
      0.012541980482637882,
      0.08366270363330841,
      0.016064388677477837,
      -0.009771405719220638,
      0.07636434584856033,
      0.01292483787983656,
      0.03521556407213211,
      0.0068728248588740826,
      -0.04074574261903763,
      -0.024955466389656067,
      0.09460055828094482,
      0.019068770110607147,
      0.03881911560893059,
      0.0030222032219171524,
      -0.027768539264798164,
      -0.004202095326036215,
      -0.004637320525944233,
      0.04217146337032318,
      -0.05515298992395401,
      0.07405620068311691,
      -0.03591429814696312,
      -0.049262672662734985,
      0.05269267410039902,
      -0.029936090111732483,
      -0.028842275962233543,
      -0.04699905589222908,
      0.05954889580607414,
      0.017202846705913544,
      0.025354938581585884,
      0.02201181836426258,
      0.11521690338850021,
      -0.09396336227655411,
      0.003935576882213354,
      0.0502912811934948,
      -0.005015089642256498,
      0.007081480231136084,
      -0.026664476841688156,
      0.0009856984252110124,
      0.03619874641299248,
      0.04923265799880028,
      -0.0555223748087883,
      -0.018225956708192825,
      0.00938046257942915,
      0.05402299389243126,
      -0.0028806128539144993,
      -0.02932608127593994,
      0.02901538647711277,
      0.008695312775671482,
      -0.04693552106618881,
      -0.013689830899238586,
      0.0019908249378204346,
      -0.05527101084589958,
      -0.09340144693851471,
      0.010207238607108593,
      0.04268888011574745,
      -0.012564122676849365,
      -0.09793498367071152,
      0.013409624807536602,
      0.05914286896586418,
      -0.07260829210281372,
      -0.07346748560667038,
      0.014449813403189182,
      0.03863231837749481,
      -0.06310827285051346,
      -0.004417151678353548,
      0.014038852415978909,
      -0.025490151718258858,
      -0.010220887139439583,
      0.07544253021478653,
      -0.02224094606935978,
      0.13844025135040283,
      0.03397843614220619,
      -0.002765087177976966,
      0.09469876438379288,
      0.014544835314154625,
      0.006645177491009235,
      -0.012774995528161526,
      0.007404062431305647,
      0.01688573881983757,
      -0.004301797598600388,
      -0.03447987139225006,
      -0.14200162887573242,
      -0.041977230459451675,
      -0.007037417497485876,
      0.022948624566197395,
      -0.003352571278810501,
      -0.06521335244178772,
      0.083192840218544,
      0.035027630627155304,
      -0.008467749692499638,
      0.10703840851783752,
      -2.7584734198171645e-05,
      -0.12114910781383514,
      -0.009901024401187897,
      0.02216343954205513,
      -0.04045813903212547,
      -0.026628313586115837,
      0.06498076766729355,
      -0.013537758961319923,
      0.005697642453014851,
      0.037916507571935654,
      0.009874136187136173,
      -0.017059829086065292,
      0.05665040761232376,
      0.014759411104023457,
      -0.10555599629878998,
      0.0590730644762516,
      0.03258243575692177,
      -0.009007526561617851,
      -0.010108611546456814,
      0.023745786398649216,
      0.061297956854104996,
      0.04305008053779602,
      -0.018604809418320656,
      0.03944981098175049,
      0.008806886151432991,
      0.020083840936422348,
      -0.07641685754060745,
      -0.04783766716718674,
      0.0019269795157015324,
      -0.04104265198111534,
      0.035366181284189224,
      -0.028174765408039093,
      0.031127560883760452,
      0.039892926812171936,
      -0.057857710868120193,
      -0.005760883912444115,
      -0.10840195417404175,
      -0.04262952134013176,
      -0.026117075234651566,
      -0.07006567716598511,
      -0.015177296474575996,
      0.06383603811264038,
      -0.014099366031587124,
      0.012627922929823399,
      -0.014178391546010971,
      -0.0229132492095232,
      -0.032093096524477005,
      -0.0473606176674366,
      0.01758616417646408,
      0.021120039746165276,
      -0.005254880525171757,
      -0.08624812960624695,
      0.029366839677095413,
      -0.03684226796030998,
      0.04625736549496651,
      0.04415087029337883,
      0.002620105165988207,
      -0.0016062360955402255,
      0.030326945707201958,
      0.01010629441589117,
      -0.022618088871240616,
      0.03583895415067673,
      -0.04194709286093712,
      0.020606735721230507,
      -0.08966971188783646,
      -0.008947821334004402,
      -0.02484745718538761,
      0.05014544725418091,
      0.027939898893237114,
      -0.05351589620113373,
      -0.06716115772724152,
      0.011427709832787514,
      0.06161169707775116,
      0.0019485915545374155,
      -0.035611316561698914,
      0.07446952164173126,
      0.024680184200406075,
      -0.0024328797589987516,
      -0.06684316694736481,
      -0.05309750512242317,
      0.07172482460737228,
      -0.026960402727127075,
      -0.0032226594630628824,
      -0.0032336111180484295,
      -0.07681791484355927,
      0.028972534462809563,
      -0.04753175005316734,
      0.02574039436876774,
      0.035092003643512726,
      0.011877607554197311,
      0.04614847153425217,
      0.057524219155311584,
      0.03556209057569504,
      -0.028267985209822655,
      0.0380154624581337,
      -0.0070526935160160065,
      -0.012031611986458302,
      0.10988311469554901,
      0.010649949312210083,
      0.05363998934626579,
      -0.005303015932440758,
      -0.015833111479878426,
      0.09003157913684845,
      0.03335467353463173,
      0.040977977216243744,
      -0.0519389733672142,
      -0.04626522585749626,
      0.020382819697260857,
      0.033349815756082535,
      -0.01797398366034031,
      0.04740210622549057,
      -0.12456303834915161,
      -0.0432901568710804,
      -0.09579703956842422,
      -0.011750547215342522,
      0.08482689410448074,
      0.08419262617826462,
      -0.016182389110326767,
      0.020294008776545525,
      -0.0028109836857765913,
      0.006622683722525835,
      0.09201595187187195,
      -0.047082144767045975,
      -0.07504314929246902,
      0.0062880306504666805,
      -0.04777506738901138,
      0.0014731030678376555,
      -0.07045435160398483,
      -0.07010725885629654,
      0.0007381141767837107,
      0.08150254189968109,
      -0.11834777891635895,
      0.071779265999794,
      -0.052667487412691116,
      -0.019744815304875374,
      -0.08820265531539917,
      -0.0899883508682251,
      -0.0012717690551653504,
      0.030631130561232567,
      -0.05264776945114136,
      0.09377484768629074,
      -0.0026923140976577997,
      0.06464727222919464,
      -0.009057126007974148,
      -0.06083860620856285,
      0.030322765931487083,
      0.07557477802038193,
      -0.024855300784111023,
      0.0016708486946299672,
      0.12355586886405945,
      -0.11592612415552139,
      0.05929259583353996,
      -0.0330415740609169,
      -0.06547104567289352,
      0.019172994419932365,
      -0.049818530678749084,
      0.03356087952852249,
      -0.05566283315420151,
      0.0012558875605463982,
      0.06132261082530022
    ],
    [
      -0.036647729575634,
      0.014442437328398228,
      0.05347343161702156,
      0.012337267398834229,
      -0.002840891480445862,
      0.048901092261075974,
      0.04808100685477257,
      0.0019403938204050064,
      0.02707875706255436,
      -0.048760026693344116,
      -0.009943735785782337,
      -0.014637172222137451,
      0.053263142704963684,
      0.08814321458339691,
      -0.022637102752923965,
      0.011105078272521496,
      0.056831151247024536,
      0.0010823324555531144,
      -0.02437484636902809,
      -0.03864939883351326,
      0.020503869280219078,
      0.017435483634471893,
      0.015093539841473103,
      0.06175534799695015,
      -0.010036068968474865,
      0.1471726894378662,
      -0.0452684685587883,
      0.07488501816987991,
      -0.12439079582691193,
      -0.04220183566212654,
      -0.0357125923037529,
      0.027318930253386497,
      0.03196216747164726,
      -0.036045562475919724,
      0.07471594959497452,
      -0.024304185062646866,
      0.048530951142311096,
      -0.05472565069794655,
      0.013589223846793175,
      -0.01740252785384655,
      0.029115065932273865,
      0.004338228143751621,
      0.016257628798484802,
      -0.03960727900266647,
      0.0010444865329191089,
      -0.013563252985477448,
      0.07433474063873291,
      -0.03316987678408623,
      0.09618145227432251,
      -0.024523021653294563,
      -0.0598970428109169,
      -0.028922544792294502,
      0.008854172192513943,
      -0.05378514528274536,
      -0.06377004086971283,
      -0.05006764829158783,
      0.0051903086714446545,
      0.0019551662262529135,
      -0.04209360107779503,
      0.029815327376127243,
      0.053137652575969696,
      0.02790044993162155,
      -0.0201901625841856,
      0.0015085737686604261,
      -0.017969802021980286,
      0.04260295256972313,
      0.07155706733465195,
      -0.06932654231786728,
      0.10679877549409866,
      -0.0410165973007679,
      -0.039401423186063766,
      -0.030662236735224724,
      -0.04064270481467247,
      -0.0357387438416481,
      0.0008329970296472311,
      -0.04636511579155922,
      0.05070112273097038,
      0.024367017671465874,
      -0.021469637751579285,
      0.03585720434784889,
      -0.018246261402964592,
      -0.0011444482952356339,
      0.027728617191314697,
      0.0052699134685099125,
      -0.04875970631837845,
      0.022680556401610374,
      -0.07971128821372986,
      0.037253931164741516,
      -0.0026804537046700716,
      -0.005567754153162241,
      -0.06263846158981323,
      -0.06773721426725388,
      -0.038152649998664856,
      -0.0007906219689175487,
      0.02445201948285103,
      -0.011495880782604218,
      -0.020608603954315186,
      -0.02467115968465805,
      -0.0974988341331482,
      -0.006454515736550093,
      -0.01855243556201458,
      0.011109264567494392,
      -0.02920694462954998,
      0.010017036460340023,
      -0.07988916337490082,
      0.013018894009292126,
      0.059490054845809937,
      -0.00026138772955164313,
      0.05334346741437912,
      0.0050962683744728565,
      -0.009616226889193058,
      0.013198131695389748,
      -0.024888476356863976,
      0.024224288761615753,
      -0.09797479212284088,
      0.03677681088447571,
      0.016482997685670853,
      0.003916020505130291,
      -0.07901296764612198,
      0.0167290847748518,
      -0.00023213765234686434,
      0.0871150866150856,
      -0.00025725949672050774,
      -0.05027347430586815,
      -0.04455264285206795,
      0.007132432889193296,
      -0.015437450259923935,
      0.05796332657337189,
      0.07718022167682648,
      -0.05252648517489433,
      -0.030377017334103584,
      0.0423172302544117,
      0.0752449631690979,
      -0.003994184546172619,
      -0.04289750009775162,
      -0.0023410215508192778,
      0.019780920818448067,
      -0.05270419642329216,
      -0.00841877143830061,
      -0.029985586181282997,
      -0.021264947950839996,
      -0.044553570449352264,
      -0.08895889669656754,
      -0.01729370467364788,
      -0.04451659694314003,
      -0.000829506607260555,
      0.016525425016880035,
      0.011341230012476444,
      0.06589527428150177,
      0.046890322118997574,
      0.027426181361079216,
      0.009752906858921051,
      -0.07809983193874359,
      -0.06373395025730133,
      -0.0015659189084544778,
      0.015892397612333298,
      -0.005202290136367083,
      -0.08810572326183319,
      -0.07731056213378906,
      -0.016856130212545395,
      0.009777667000889778,
      -0.027261540293693542,
      -0.014651370234787464,
      0.06843965500593185,
      -0.05329776927828789,
      -0.016084061935544014,
      -0.041582513600587845,
      -0.027224833145737648,
      0.004372772760689259,
      0.10082040727138519,
      0.12989628314971924,
      0.04716017469763756,
      0.04213184490799904,
      -0.04000281170010567,
      0.0243474580347538,
      -0.050995148718357086,
      0.009721409529447556,
      -0.009525230154395103,
      -0.024768568575382233,
      0.027023902162909508,
      -0.042525339871644974,
      0.068870909512043,
      -0.009883643127977848,
      -0.007417953573167324,
      0.03750259801745415,
      -0.04857177659869194,
      -0.01189492829144001,
      -0.06453178077936172,
      -0.015591738745570183,
      0.01277377363294363,
      -0.006290744990110397,
      0.04485572129487991,
      -0.007815491408109665,
      -0.03189067170023918,
      0.03135206550359726,
      -0.00010254377411911264,
      0.00275505892932415,
      0.0013363712932914495,
      -0.042507339268922806,
      0.05244499444961548,
      0.014855686575174332,
      -0.0921836569905281,
      -0.01182656642049551,
      0.002026832662522793,
      0.017206910997629166,
      0.022170087322592735,
      0.025133924558758736,
      -0.012765551917254925,
      -0.04877491667866707,
      0.027679210528731346,
      -0.060159698128700256,
      0.09406290203332901,
      0.11562300473451614,
      0.016499130055308342,
      0.007720856461673975,
      0.03622785210609436,
      -0.005478231236338615,
      -0.08048119395971298,
      0.1144350990653038,
      -0.07486654072999954,
      0.014486545696854591,
      0.03598655015230179,
      0.0022966735996305943,
      0.03299310803413391,
      -0.021290842443704605,
      0.019991954788565636,
      0.03934504836797714,
      0.027611462399363518,
      -0.11396634578704834,
      -0.06556788086891174,
      -0.04169410094618797,
      0.008616890758275986,
      -0.009675569832324982,
      -0.051143620163202286,
      -0.053145527839660645,
      0.051057931035757065,
      0.05602577328681946,
      -0.006903813686221838,
      0.07648811489343643,
      0.039765629917383194,
      0.018205419182777405,
      -0.054752860218286514,
      0.039619117975234985,
      0.04384147375822067,
      0.025234006345272064,
      -0.07170142233371735,
      -0.023875398561358452,
      -0.03188232704997063,
      -0.034998729825019836,
      0.053493913263082504,
      0.020610276609659195,
      0.08594556897878647,
      0.008917921222746372,
      -0.022733327001333237,
      -0.046431925147771835,
      -0.025112979114055634,
      0.028320355340838432,
      -0.017069291323423386,
      0.015710322186350822,
      0.06018533930182457,
      0.02104826644062996,
      -0.05898311734199524,
      0.024097952991724014,
      0.028222713619470596,
      0.03279176354408264,
      0.009909267537295818,
      -0.036292772740125656,
      -0.004352021962404251,
      -0.053848594427108765,
      0.016204573214054108,
      -0.011291978880763054,
      0.018719300627708435,
      -0.0327908918261528,
      -0.02549680322408676,
      0.02908959984779358,
      0.023965291678905487,
      -0.058518704026937485,
      -0.012104779481887817,
      -0.054827045649290085,
      0.018830081447958946,
      -0.03711612895131111,
      0.019898438826203346,
      0.05132731422781944,
      0.09402310848236084,
      0.04795842245221138,
      0.10829495638608932,
      -0.022719601169228554,
      -0.037818148732185364,
      -0.01925966702401638,
      0.010437346994876862,
      -7.570632442366332e-05,
      0.04928898066282272,
      -0.07181946188211441,
      -0.008186398074030876,
      0.006362421438097954,
      0.028421292081475258,
      0.05960003286600113,
      0.011105512268841267,
      -0.006358452141284943,
      -0.02255728654563427,
      0.022749673575162888,
      -0.027934899553656578,
      -0.06603687256574631,
      -0.07479830086231232,
      0.06110696867108345,
      0.023881342262029648,
      0.08050406724214554,
      0.015306449495255947,
      0.041006311774253845,
      -0.006332384888082743,
      -0.022055530920624733,
      0.0024351770989596844,
      0.02519836649298668,
      0.042859211564064026,
      0.017614303156733513,
      0.03290961682796478,
      0.03537347912788391,
      0.10613081604242325,
      -0.06707897037267685,
      -0.02454628236591816,
      -0.04567001387476921,
      -0.059072963893413544,
      0.02730223350226879,
      -0.0268410537391901,
      -0.05302351713180542,
      0.04157959669828415,
      -0.08662007004022598,
      -0.010439000092446804,
      0.020681848749518394,
      -0.023217450827360153,
      0.007280340883880854,
      -0.014808760955929756,
      -0.005234420765191317,
      -0.0025551877915859222,
      0.0334041453897953,
      0.07722444832324982,
      -0.04342496395111084,
      -0.0059089213609695435,
      -0.0035195511300116777,
      -0.0049195438623428345,
      -0.012566547840833664,
      -0.025965170934796333,
      -0.06452417373657227,
      -0.0031343442387878895,
      0.02405158057808876,
      0.00684712827205658,
      0.060218751430511475,
      0.02027084305882454,
      0.0430847704410553,
      -0.08545370399951935,
      -0.06632209569215775,
      0.022609438747167587,
      -0.04246680438518524,
      0.006732958368957043,
      0.06402652710676193,
      -0.047529853880405426,
      -0.11882415413856506,
      0.028270814567804337,
      -0.040391985327005386,
      -0.06368779391050339,
      -0.07373645156621933,
      -0.012937336228787899,
      0.02552986331284046,
      -0.037926532328128815,
      0.08058187365531921,
      -0.04733272269368172,
      0.04425181448459625,
      0.02121018059551716,
      0.07993675023317337,
      -0.057578977197408676,
      -0.017409484833478928,
      0.030402766540646553,
      -0.06506184488534927,
      -0.03115822747349739,
      -0.03803166374564171,
      -0.10207382589578629,
      -0.017873771488666534,
      0.0033349257428199053,
      0.0027748208958655596,
      -0.03311112895607948,
      -0.08499376475811005,
      -0.023799113929271698,
      0.03166653960943222,
      -0.016307320445775986,
      -0.0842478796839714,
      0.0015108449151739478,
      -0.03897658735513687,
      -0.014569212682545185,
      -0.013459927402436733,
      0.06264336407184601,
      -0.054828155785799026,
      0.013238591141998768,
      -0.08115934580564499,
      -0.045155152678489685,
      -0.049013301730155945,
      0.033297907561063766,
      0.031012581661343575,
      -0.0189301036298275,
      0.05439760535955429,
      -0.07254169136285782,
      0.06219577044248581,
      0.0889270231127739,
      -0.007745619863271713,
      0.02160736359655857,
      -0.038529977202415466,
      -0.0027893621008843184,
      0.05304664000868797,
      -0.029557669535279274,
      0.014501155354082584,
      -0.017494846135377884,
      -0.006908902898430824,
      0.010234979912638664,
      0.05949157476425171,
      -0.00696282833814621,
      0.036721859127283096,
      0.020345430821180344,
      -0.014830615371465683,
      -0.05950724333524704,
      0.007709653116762638,
      0.02375705912709236,
      0.05017322301864624,
      -0.011284823529422283,
      -0.04043106362223625,
      -0.04462328925728798,
      0.03979143872857094,
      0.0014672030229121447,
      -0.05870681256055832,
      -0.055161815136671066,
      0.015595059841871262,
      -0.042513616383075714,
      -0.03692711517214775,
      0.018297366797924042,
      0.001262818113900721,
      -0.06669291853904724,
      -0.017476137727499008,
      0.03757128119468689,
      -0.07235142588615417,
      0.013920360244810581,
      -0.02522262930870056,
      -0.01274073775857687,
      0.02470368519425392,
      -0.03265481814742088,
      -0.03473721444606781,
      0.13178811967372894,
      0.013026517815887928,
      0.05304231867194176,
      -0.07000090181827545,
      -0.009675861336290836,
      0.04993715509772301,
      0.0676737055182457,
      0.03245437517762184,
      0.016762180253863335,
      0.04417257755994797,
      0.009713560342788696,
      0.00286675407551229,
      -0.04230133071541786,
      0.02091517113149166,
      -0.0422942079603672,
      -0.03378375247120857,
      0.0867573544383049,
      0.05907519534230232,
      0.03171514347195625,
      -0.034742001444101334,
      0.0022993297316133976,
      -0.00926727894693613,
      0.11007705330848694,
      -0.04490182548761368,
      0.02968296781182289,
      -0.02125750482082367,
      -0.03751957789063454,
      0.11029958724975586,
      0.03028954192996025,
      -0.03887693211436272,
      0.03905251994729042,
      -0.03197573125362396,
      0.01902022212743759,
      -0.04548303037881851,
      0.012328439392149448,
      -0.05547128617763519,
      -0.06062695011496544,
      0.016228370368480682,
      0.027948487550020218,
      -0.008334366604685783,
      0.012546123936772346,
      0.050438687205314636,
      -0.014549008570611477,
      0.010317378677427769,
      0.018187932670116425,
      0.004869900643825531,
      0.0015857702819630504,
      -0.018451852723956108,
      0.03514019772410393,
      -0.018027300015091896,
      -0.056048836559057236,
      -0.01040590088814497,
      -0.005570119712501764,
      -0.0031074974685907364,
      0.011704854667186737,
      0.009014036506414413,
      0.024240214377641678,
      -0.011351281777024269,
      -0.04245445132255554,
      -0.049673985689878464
    ],
    [
      -0.028629621490836143,
      0.023313872516155243,
      -0.008974650874733925,
      -0.06915753334760666,
      0.061688508838415146,
      -0.03451651707291603,
      -0.11420135945081711,
      -0.037159159779548645,
      -0.021103668957948685,
      -0.0064145601354539394,
      -0.05679895728826523,
      -0.008951081894338131,
      0.032303642481565475,
      -0.031753409653902054,
      0.0063874502666294575,
      0.025250714272260666,
      0.07131323963403702,
      -0.017839010804891586,
      -0.028683722019195557,
      0.07241776585578918,
      -0.005383027717471123,
      0.08698979020118713,
      -0.06701644510030746,
      -0.007591303437948227,
      0.0533316545188427,
      0.06677228212356567,
      -0.06744951754808426,
      0.018993182107806206,
      -0.08702864497900009,
      -0.014318287372589111,
      0.021918784826993942,
      -0.03330422565340996,
      0.013533823192119598,
      -0.006066170521080494,
      0.01871453784406185,
      -0.015745796263217926,
      0.025022242218255997,
      -0.00766739109531045,
      -0.01349650789052248,
      0.07813683152198792,
      0.037516891956329346,
      -0.02264273725450039,
      -0.051844630390405655,
      -0.0479910708963871,
      -0.03963914141058922,
      0.04547945037484169,
      0.07539086788892746,
      0.007755338679999113,
      0.02536754496395588,
      -0.09030737727880478,
      -0.009117011912167072,
      0.047206390649080276,
      0.01216029655188322,
      0.007356749381870031,
      -0.04401920363306999,
      0.007369294296950102,
      0.010832798667252064,
      0.020192274823784828,
      -0.048950184136629105,
      -0.10293624550104141,
      -0.010721361264586449,
      0.05402278155088425,
      -0.06045689433813095,
      0.04294617474079132,
      0.04479730501770973,
      0.009699012152850628,
      -0.047384195029735565,
      0.013590171933174133,
      -0.03339836746454239,
      -0.03644860163331032,
      0.042790737003088,
      -0.03763681650161743,
      0.007285832893103361,
      -0.028162876144051552,
      0.001890143845230341,
      0.05638178810477257,
      0.012304152362048626,
      0.025401460006833076,
      -0.06801676005125046,
      0.00016417811275459826,
      -0.020259933546185493,
      0.010553587228059769,
      -0.015598545782268047,
      0.024449648335576057,
      0.04304882511496544,
      -0.05960024148225784,
      -0.033251069486141205,
      -0.06822504103183746,
      -0.031927503645420074,
      -0.004149786662310362,
      -0.10652359575033188,
      0.05057845637202263,
      -0.013616610318422318,
      -0.09465162456035614,
      0.04512359946966171,
      0.025327622890472412,
      0.031201645731925964,
      -0.022275133058428764,
      -0.035320065915584564,
      0.10420958697795868,
      0.02382846362888813,
      -0.06098867952823639,
      0.009127557277679443,
      0.038250263780355453,
      -0.005244632251560688,
      -0.038883909583091736,
      -0.02261810004711151,
      0.06542777270078659,
      0.036551058292388916,
      -0.045488156378269196,
      0.028360649943351746,
      -0.029350047931075096,
      0.0032075385097414255,
      -0.0024337724316865206,
      0.03967956453561783,
      -0.021639229729771614,
      -0.022380497306585312,
      0.03624073788523674,
      -0.007065394427627325,
      -0.017330452799797058,
      0.06039785221219063,
      0.0130604337900877,
      0.002863796427845955,
      -0.008966733701527119,
      -0.04437611252069473,
      -0.03678208589553833,
      -0.04421711713075638,
      0.008515927009284496,
      -0.02104431577026844,
      -0.02176215499639511,
      0.03053954616189003,
      -0.008374763652682304,
      -0.03320346027612686,
      0.04468254745006561,
      0.009565711952745914,
      0.0006709587760269642,
      -0.02729775384068489,
      0.00930668506771326,
      0.028840094804763794,
      0.006881521549075842,
      0.07782022655010223,
      -0.04194062948226929,
      -0.06193862110376358,
      -0.0018268903950229287,
      -0.0030015036463737488,
      -0.04646608978509903,
      0.04138804227113724,
      -0.015292258001863956,
      -0.013947397470474243,
      -0.003916635178029537,
      0.059946075081825256,
      -0.022830354049801826,
      0.029037972912192345,
      0.008360794745385647,
      -0.06725011020898819,
      0.07381924241781235,
      0.02878545969724655,
      -0.024249104782938957,
      -0.06638447195291519,
      0.021801745519042015,
      -0.016135722398757935,
      -0.04478314146399498,
      -0.005950402468442917,
      -0.04401013255119324,
      0.03011275641620159,
      -0.015088695101439953,
      0.0017949086613953114,
      0.027357259765267372,
      0.04262813553214073,
      0.020506002008914948,
      0.02660316787660122,
      0.0449410006403923,
      -0.06383822113275528,
      -0.042914748191833496,
      -0.02317325957119465,
      0.062454286962747574,
      -0.06965272128582001,
      -0.13645347952842712,
      -0.024316253140568733,
      0.0035633002407848835,
      -0.047727592289447784,
      0.045799195766448975,
      0.00549493869766593,
      -0.08556579798460007,
      0.03228722885251045,
      0.03744291141629219,
      -0.03267109394073486,
      0.0780080109834671,
      -0.015044729225337505,
      0.011104288510978222,
      0.018844742327928543,
      -0.012574450112879276,
      0.023010948672890663,
      0.021317200735211372,
      0.003602544544264674,
      -0.005980453919619322,
      0.017362890765070915,
      -0.0403299443423748,
      -0.016330933198332787,
      0.0847606509923935,
      0.09489172697067261,
      -0.007089113350957632,
      0.020073510706424713,
      0.02050485461950302,
      -0.04479505121707916,
      -0.040340978652238846,
      0.03386498987674713,
      0.03404325619339943,
      0.02604261040687561,
      0.023105090484023094,
      0.043093375861644745,
      0.06829322129487991,
      0.05029740557074547,
      0.050184670835733414,
      0.02102499082684517,
      0.029263699427247047,
      -0.022260354831814766,
      -0.08399718999862671,
      -0.010376719757914543,
      -0.03164372965693474,
      0.004612849093973637,
      0.012307488359510899,
      -0.018075766041874886,
      0.042236171662807465,
      0.023974761366844177,
      -0.03872588276863098,
      -0.015416269190609455,
      -0.0008447107975371182,
      0.014969004318118095,
      -0.026994619518518448,
      -0.013313913717865944,
      -0.024565357714891434,
      -0.09998565167188644,
      -0.04533359408378601,
      -0.08124914765357971,
      0.012637296691536903,
      -0.028055859729647636,
      -0.10714346915483475,
      -0.02353588119149208,
      -0.027437353506684303,
      0.04137309640645981,
      0.01633434370160103,
      -0.010083834640681744,
      0.054152440279722214,
      -0.028439844027161598,
      -0.014584246091544628,
      -0.03139667212963104,
      -0.05692976340651512,
      0.03329716995358467,
      -0.04268937185406685,
      0.06300248205661774,
      -0.0015054938849061728,
      0.01649765856564045,
      -0.07977887243032455,
      -0.059477418661117554,
      -0.021916385740041733,
      -0.04674213379621506,
      0.017616596072912216,
      0.08889395743608475,
      0.02931695058941841,
      -0.014872380532324314,
      0.002371259266510606,
      -0.04790978506207466,
      -0.013774264603853226,
      0.014551131054759026,
      0.051464784890413284,
      0.04698740318417549,
      0.03900934383273125,
      0.015341696329414845,
      0.07095932960510254,
      -0.06208213418722153,
      0.016464320942759514,
      -0.02205747365951538,
      -0.007441495545208454,
      0.10071706771850586,
      0.06660881638526917,
      0.027403919026255608,
      0.039137884974479675,
      -0.04932231456041336,
      -0.00225323555059731,
      -0.0013714968226850033,
      -0.04690258949995041,
      0.07357876002788544,
      0.0002494818181730807,
      0.02281690016388893,
      -0.0007824046188034117,
      0.07922931760549545,
      -0.012971227057278156,
      0.035386886447668076,
      -0.06632627546787262,
      -0.022072451189160347,
      0.04017230495810509,
      -0.09378430992364883,
      0.0022570777218788862,
      0.000649880210403353,
      0.021150844171643257,
      -0.03368942812085152,
      0.0062300157733261585,
      0.08426091820001602,
      -0.020917898043990135,
      -0.042611632496118546,
      0.06196238473057747,
      -0.09282180666923523,
      -0.03394097089767456,
      0.024114064872264862,
      -0.0528043732047081,
      0.03576197847723961,
      0.005154709331691265,
      -0.022704944014549255,
      -0.0038349097594618797,
      0.06077156960964203,
      -0.030024439096450806,
      0.02598121203482151,
      0.028362536802887917,
      -0.06450986862182617,
      -0.024479515850543976,
      -0.0817212462425232,
      -0.06309577822685242,
      0.0749126598238945,
      0.0675542876124382,
      -0.030246635898947716,
      -0.005254237912595272,
      0.07772272825241089,
      0.019881436601281166,
      0.056010741740465164,
      0.0505024679005146,
      -0.027591506019234657,
      -0.015766935423016548,
      -0.01714918576180935,
      0.0277709998190403,
      0.07241006940603256,
      0.10274963080883026,
      -0.02451535314321518,
      0.027330759912729263,
      -0.03761085122823715,
      -0.028170114383101463,
      -0.018648400902748108,
      -0.009278720244765282,
      0.05723053589463234,
      0.025948291644454002,
      0.03831470385193825,
      -0.0351833812892437,
      0.030390534549951553,
      -0.07678761333227158,
      -0.032730892300605774,
      -0.02399425022304058,
      0.058099910616874695,
      -0.007219195831567049,
      0.02294413186609745,
      0.058428723365068436,
      -0.0050418442115187645,
      0.022041065618395805,
      0.02023734711110592,
      -0.05752401798963547,
      0.07336613535881042,
      0.07177845388650894,
      0.051794540137052536,
      -0.06570430845022202,
      0.06195763126015663,
      -0.003287670901045203,
      0.06777315586805344,
      -0.05646343156695366,
      -0.005487565882503986,
      -0.054290175437927246,
      0.0010769503423944116,
      0.0007044079247862101,
      0.024692386388778687,
      0.03835027292370796,
      0.0006533791893161833,
      0.04285513609647751,
      0.03755252808332443,
      0.05422893166542053,
      0.03197135403752327,
      0.004431933630257845,
      0.018853534013032913,
      -0.03347768634557724,
      0.03427702561020851,
      -0.031038757413625717,
      0.026270950213074684,
      0.0005605584592558444,
      -0.0011683793272823095,
      0.0339052751660347,
      -0.0430537611246109,
      -0.08749832957983017,
      -0.008964586071670055,
      -0.055090710520744324,
      -0.005651703104376793,
      0.05470409244298935,
      -0.0914255827665329,
      0.001059519243426621,
      0.018823672086000443,
      -0.05070696771144867,
      -0.06481070071458817,
      0.02031569555401802,
      0.015624293126165867,
      -0.07291365414857864,
      -0.10232267528772354,
      -0.0027855844236910343,
      -0.020872920751571655,
      -0.039716534316539764,
      -0.009553850628435612,
      0.02397601492702961,
      -0.07597740739583969,
      0.008590777404606342,
      0.030937572941184044,
      0.009939289651811123,
      0.07225599139928818,
      -0.021725762635469437,
      -0.04155830293893814,
      0.0009495847625657916,
      -0.013366271741688251,
      -0.06846360862255096,
      -0.09602498263120651,
      -0.006007090676575899,
      -0.03522081300616264,
      -0.02253403514623642,
      0.06417399644851685,
      0.019218431785702705,
      -0.07929667830467224,
      0.0028501187916845083,
      0.03800591081380844,
      -0.04804779216647148,
      0.08345440030097961,
      0.02931530959904194,
      -0.0611826628446579,
      -0.06208642199635506,
      -0.017387283965945244,
      -0.09208884835243225,
      -0.03436676040291786,
      -0.02259965054690838,
      0.03192545101046562,
      0.050380755215883255,
      0.04828996583819389,
      0.022243818268179893,
      0.1322997361421585,
      -0.06633199006319046,
      -0.009474544785916805,
      -0.044792644679546356,
      -0.007453923113644123,
      0.0018305298872292042,
      0.012291714549064636,
      -0.059391945600509644,
      0.01968311332166195,
      0.02721048891544342,
      -0.07037367671728134,
      0.011791102588176727,
      -0.05247240513563156,
      0.021989965811371803,
      0.03887627273797989,
      -0.05161602050065994,
      -0.0010550268925726414,
      -0.017969796434044838,
      0.029491769149899483,
      0.058772556483745575,
      0.002637434983626008,
      0.06894643604755402,
      -0.005352206528186798,
      0.027320621535182,
      0.048090264201164246,
      0.0028279342222958803,
      -0.05207603797316551,
      0.008457319810986519,
      -0.003447851864621043,
      -0.039207823574543,
      0.039264015853405,
      0.005340080242604017,
      0.005553488153964281,
      0.016680428758263588,
      -0.03214043006300926,
      0.010582413524389267,
      -0.0248560793697834,
      0.057257574051618576,
      -0.024380138143897057,
      -0.014956953004002571,
      0.029466519132256508,
      -0.046878404915332794,
      0.048444461077451706,
      0.01359471119940281,
      -0.0447068028151989,
      -0.04864593967795372,
      0.045717768371105194,
      0.031050197780132294,
      -0.02801203541457653,
      -0.06380976736545563,
      0.007005660329014063,
      0.011667701415717602,
      0.042710863053798676,
      -0.016169100999832153,
      -0.03647737205028534,
      -0.04275107383728027,
      0.05711660906672478,
      -0.001946001430042088,
      0.030865445733070374,
      0.02937328815460205,
      -0.03838588297367096,
      0.004287536721676588,
      0.041040949523448944,
      -0.004460024181753397,
      -0.007334357593208551,
      0.022253166884183884,
      -0.02753119170665741,
      0.06510167568922043,
      0.037232741713523865
    ],
    [
      -0.11558598279953003,
      -0.010176464915275574,
      0.0034178472124040127,
      -0.006129565183073282,
      -0.07591153681278229,
      0.010713144205510616,
      -0.008975135162472725,
      0.00576966255903244,
      -0.03258480131626129,
      0.03907964378595352,
      -0.004736804869025946,
      -0.01507670059800148,
      -0.042975250631570816,
      -0.0010766260093078017,
      -0.0023274857085198164,
      -0.008114398457109928,
      0.050440285354852676,
      -0.025497999042272568,
      -0.047102052718400955,
      -0.06674196571111679,
      0.10504315793514252,
      -0.023478146642446518,
      0.05522702634334564,
      -0.018007943406701088,
      0.0011763968504965305,
      -0.023885386064648628,
      0.018269553780555725,
      0.03869533911347389,
      0.012673298828303814,
      0.013534440658986568,
      0.015511071309447289,
      -0.04672945663332939,
      0.08752714097499847,
      -0.003082232316955924,
      -0.015230091288685799,
      -0.07063596695661545,
      -0.06138576939702034,
      -0.03895299509167671,
      0.021052854135632515,
      -0.028910405933856964,
      0.0419839583337307,
      -0.017309973016381264,
      -0.08367402106523514,
      0.006882012821733952,
      -0.034929048269987106,
      0.0420549176633358,
      0.07922746986150742,
      0.015566843561828136,
      -0.047678422182798386,
      0.04160401597619057,
      -0.0577629990875721,
      0.03844866901636124,
      -0.012108709663152695,
      0.025410115718841553,
      -0.014605038799345493,
      0.0026887638960033655,
      -0.08860128372907639,
      0.017115125432610512,
      0.03771713376045227,
      0.049767639487981796,
      0.044152580201625824,
      0.08332492411136627,
      0.02497466653585434,
      -0.02065392956137657,
      0.07031691819429398,
      0.03933229297399521,
      0.028415320441126823,
      0.009343517012894154,
      -0.030067287385463715,
      0.012491417117416859,
      -0.0036426535807549953,
      -0.0420374870300293,
      -0.012267787009477615,
      0.012466637417674065,
      -0.01587642915546894,
      -0.020597537979483604,
      -0.021461084485054016,
      0.06248660758137703,
      -0.023432211950421333,
      0.005674308631569147,
      0.05007302388548851,
      0.040575820952653885,
      -0.05338023975491524,
      0.03729655221104622,
      0.045344192534685135,
      0.11749372631311417,
      -0.04667114466428757,
      -0.10204247385263443,
      -0.09791222959756851,
      0.06676588952541351,
      -0.03715882822871208,
      0.0037441495805978775,
      0.02319149859249592,
      -0.08700858056545258,
      -0.09135005623102188,
      -0.06131910905241966,
      0.01270974986255169,
      0.00020208311616443098,
      0.01667356863617897,
      0.03414752706885338,
      0.04134184867143631,
      0.007093292195349932,
      0.03506910800933838,
      -0.021249907091259956,
      0.03064456768333912,
      -0.043669186532497406,
      -0.00028789645875804126,
      0.014683173038065434,
      0.01500669401139021,
      0.007711062673479319,
      -0.03188687190413475,
      -0.06567832082509995,
      0.025232674553990364,
      -0.0038971605245023966,
      -0.021921837702393532,
      -0.05429292470216751,
      -0.059704918414354324,
      -0.004798645619302988,
      0.05203273892402649,
      -0.06700079888105392,
      0.027840683236718178,
      0.04622972384095192,
      -0.0835106372833252,
      0.02419285848736763,
      -0.03217892348766327,
      0.007521423976868391,
      -0.0035131683107465506,
      0.0788498967885971,
      0.0372316911816597,
      -0.0640968382358551,
      -0.06184862554073334,
      0.005772937089204788,
      0.043003737926483154,
      -0.017466779798269272,
      -0.07431170344352722,
      -0.010054819285869598,
      -0.061765868216753006,
      0.07878804206848145,
      0.036681242287158966,
      -0.032569609582424164,
      0.00118339026812464,
      0.019944721832871437,
      0.08565355837345123,
      0.027770867571234703,
      0.046496663242578506,
      -0.034084469079971313,
      -0.05357738211750984,
      -0.008121371269226074,
      0.01524091511964798,
      0.041434213519096375,
      -0.027804581448435783,
      0.0008774222224019468,
      -0.007681355345994234,
      0.00800427608191967,
      -0.028586195781826973,
      -0.055584002286195755,
      -0.02121131867170334,
      0.019848942756652832,
      0.023695262148976326,
      0.07475864142179489,
      0.0361444428563118,
      0.09198152273893356,
      0.03349030017852783,
      0.009098624810576439,
      -0.019681666046380997,
      0.018389854580163956,
      0.021957285702228546,
      -0.0697987824678421,
      -0.07101982086896896,
      0.08928849548101425,
      0.06688518077135086,
      -0.051094815135002136,
      -0.056567996740341187,
      -0.0197064820677042,
      0.03398560360074043,
      -0.03907723352313042,
      -0.02909105084836483,
      0.022227315232157707,
      0.03426685556769371,
      -0.030465759336948395,
      -0.04925433546304703,
      0.031552258878946304,
      -0.03843973949551582,
      0.08656040579080582,
      0.042805422097444534,
      0.02010025829076767,
      -0.015752436593174934,
      0.07093963027000427,
      0.02136468142271042,
      -0.10453375428915024,
      0.03646998852491379,
      0.014173452742397785,
      0.031376440078020096,
      0.04597778618335724,
      0.03841996192932129,
      0.01424660999327898,
      0.009049293585121632,
      0.0038858188781887293,
      0.031818877905607224,
      0.08097216486930847,
      0.0582195408642292,
      -0.03523923084139824,
      0.04645225405693054,
      0.0017367901746183634,
      0.05469836667180061,
      0.0010941465152427554,
      0.014528000727295876,
      -0.032799337059259415,
      0.010024506598711014,
      0.003296160837635398,
      -0.0006054567056708038,
      0.03322312980890274,
      -0.04721696674823761,
      -0.024307459592819214,
      -0.011349848471581936,
      0.050917379558086395,
      -0.022279122844338417,
      -0.025027072057127953,
      0.008583389222621918,
      -0.05443807691335678,
      -0.09546377509832382,
      0.0790024846792221,
      0.06218269467353821,
      0.02359408140182495,
      0.062496863305568695,
      -0.03502252325415611,
      0.01742519438266754,
      0.02175973355770111,
      0.031806524842977524,
      0.08414377272129059,
      0.007646880578249693,
      -0.03150631859898567,
      0.03562397137284279,
      -0.03550543636083603,
      0.02769293822348118,
      -0.05867702513933182,
      -0.053634606301784515,
      0.000529997400008142,
      -0.07350652664899826,
      -0.04933119937777519,
      -0.0412529818713665,
      0.006036324426531792,
      0.07709472626447678,
      0.04115895926952362,
      0.0018318012589588761,
      0.06348877400159836,
      0.015868613496422768,
      -0.023827485740184784,
      0.046830423176288605,
      0.051742710173130035,
      -0.0525323860347271,
      0.05304892733693123,
      -0.012424932792782784,
      -0.018299749121069908,
      -0.026196632534265518,
      0.0032003524247556925,
      0.05190756916999817,
      -0.004404958803206682,
      0.008136524818837643,
      0.0026485982816666365,
      0.0135843800380826,
      -0.006765700411051512,
      -0.00396635290235281,
      0.04322852939367294,
      -0.06471103429794312,
      0.0717826634645462,
      -0.06469331681728363,
      -0.06416558474302292,
      0.02677377499639988,
      -0.019359136000275612,
      0.03451984375715256,
      0.001498175784945488,
      0.057005543261766434,
      -0.0158516988158226,
      -0.031206604093313217,
      -0.013083159923553467,
      -0.033305373042821884,
      -0.04851814731955528,
      0.023299887776374817,
      0.0067613739520311356,
      0.11147357523441315,
      0.04148423299193382,
      -0.07350166887044907,
      0.036682140082120895,
      0.04386013001203537,
      0.05746118351817131,
      0.03580445051193237,
      0.035524267703294754,
      0.00571995647624135,
      -0.014322889968752861,
      0.005551027599722147,
      -0.05307089537382126,
      0.0370294451713562,
      -0.07861305773258209,
      -0.011846746318042278,
      0.054300133138895035,
      -0.03736003115773201,
      -0.06587422639131546,
      0.004209732636809349,
      -0.002151558641344309,
      -0.021389761939644814,
      -0.026672253385186195,
      -0.0629199892282486,
      0.05713371932506561,
      -0.0063057634979486465,
      -0.0029519516974687576,
      -0.08584456890821457,
      -0.05545872449874878,
      -0.011657445691525936,
      -0.016086142510175705,
      0.05471402779221535,
      -0.0769442766904831,
      -0.09496157616376877,
      0.020833101123571396,
      0.041110359132289886,
      -0.005725130904465914,
      -0.06773005425930023,
      0.03327777236700058,
      -0.019577687606215477,
      -0.05901407450437546,
      0.11722234636545181,
      0.05441862717270851,
      -0.09474630653858185,
      -0.03603120148181915,
      -0.11087866127490997,
      0.03429203853011131,
      0.009067044593393803,
      0.019596800208091736,
      -0.003820236772298813,
      0.05105322226881981,
      0.02460416965186596,
      -0.04007631912827492,
      -0.02864878997206688,
      -0.004563033580780029,
      0.006828922312706709,
      -0.004980959929525852,
      -0.09702899307012558,
      0.027231091633439064,
      -0.010797486640512943,
      -0.012138163670897484,
      0.05047687515616417,
      0.0036419525276869535,
      0.02226657047867775,
      -0.08054506778717041,
      -0.021524306386709213,
      0.004042882472276688,
      -0.05252467840909958,
      -0.08368004858493805,
      0.019211219623684883,
      -0.05582414194941521,
      -0.057258833199739456,
      0.040674708783626556,
      0.07005400955677032,
      -0.10979324579238892,
      -0.004941356834024191,
      0.009275040589272976,
      -0.0021499553695321083,
      0.014645302668213844,
      0.026377292349934578,
      -0.010944443754851818,
      -0.04013415053486824,
      0.068186916410923,
      0.06223038583993912,
      0.03605908155441284,
      -0.04362437129020691,
      0.032954052090644836,
      0.019083241000771523,
      -0.07125665992498398,
      -0.021104691550135612,
      -0.04040435701608658,
      -0.023041347041726112,
      0.06836888194084167,
      0.0019423728808760643,
      0.009621767327189445,
      0.030527491122484207,
      -0.006029925309121609,
      0.014007793739438057,
      0.07285059243440628,
      0.01030359510332346,
      -0.019697805866599083,
      0.02491794340312481,
      0.015043351799249649,
      0.056632764637470245,
      0.00798738282173872,
      -0.03616372495889664,
      0.056902192533016205,
      -0.025883449241518974,
      0.023254983127117157,
      -0.08181116729974747,
      0.009672926738858223,
      0.006177769973874092,
      0.052414420992136,
      -0.016328586265444756,
      0.021656006574630737,
      -0.01594051532447338,
      -0.028562035411596298,
      0.024863947182893753,
      0.05481413006782532,
      -0.061688635498285294,
      0.017552906647324562,
      0.018067127093672752,
      0.015284642577171326,
      0.11719904094934464,
      -0.0666518434882164,
      -0.037639327347278595,
      0.0008243012707680464,
      0.052594490349292755,
      0.054907798767089844,
      0.010818694718182087,
      -0.02716483175754547,
      0.03556704521179199,
      -0.041545454412698746,
      0.052978936582803726,
      0.061442069709300995,
      0.02230161800980568,
      0.005418098997324705,
      0.03859391435980797,
      0.04192115738987923,
      0.0015252567827701569,
      -0.011232452467083931,
      0.032883524894714355,
      -0.05915321409702301,
      0.037192534655332565,
      0.02434915117919445,
      -0.038676343858242035,
      -0.05227803438901901,
      -0.03531923517584801,
      -0.06123034656047821,
      0.018026983365416527,
      0.04016656428575516,
      -0.005692341830581427,
      0.025366146117448807,
      0.0533524714410305,
      0.03698037564754486,
      -0.05115242674946785,
      -0.11852604895830154,
      0.019525418058037758,
      0.03473956510424614,
      -0.02531662955880165,
      -0.02720254473388195,
      0.021826589480042458,
      0.010787000879645348,
      -0.02172757498919964,
      -0.08174099773168564,
      -0.07612385600805283,
      0.04776912182569504,
      -0.0653131976723671,
      0.057948220521211624,
      0.03628074750304222,
      0.025537915527820587,
      -0.005500939209014177,
      -0.023996679112315178,
      0.0614967867732048,
      0.011742457747459412,
      -0.047683510929346085,
      -0.07345458120107651,
      0.019681300967931747,
      0.08217300474643707,
      -0.0037968733813613653,
      -0.009817824698984623,
      0.0034675830975174904,
      0.025284621864557266,
      -0.015738705173134804,
      0.016575848683714867,
      0.04015859588980675,
      -0.01512225903570652,
      -0.03287068381905556,
      0.005750012118369341,
      0.01782441884279251,
      0.06888986378908157,
      -0.011663812212646008,
      -0.013503216207027435,
      0.026826266199350357,
      -0.031234418973326683,
      0.0015566445654258132,
      -0.0023136837407946587,
      -0.02946856990456581,
      0.013487188145518303,
      -0.04201893508434296,
      0.02923373132944107,
      0.02355239726603031,
      0.0160786472260952,
      0.022687291726469994,
      0.022766821086406708,
      -0.0036269621923565865,
      -0.0020878806244581938,
      -0.02089562453329563,
      -0.010423071682453156,
      0.02754455804824829,
      0.0461261160671711,
      -0.045942340046167374,
      0.006386382505297661,
      0.01582372933626175,
      0.014124569483101368,
      0.011763335205614567,
      -0.048222865909338,
      -0.04048990458250046,
      0.06872405111789703,
      -0.019000785425305367,
      0.04755675420165062,
      0.037840455770492554,
      0.01256285049021244,
      0.02064782939851284
    ],
    [
      -0.024046562612056732,
      -0.06796601414680481,
      -0.03139282390475273,
      -0.056801483035087585,
      0.03220637887716293,
      0.006673899944871664,
      -0.02647274173796177,
      -0.014633952639997005,
      -0.019714422523975372,
      -0.029314741492271423,
      -0.05960885062813759,
      0.049361225217580795,
      0.05616147071123123,
      0.0059192548505961895,
      0.0339251272380352,
      -0.02075871080160141,
      -0.015229616314172745,
      0.04288868233561516,
      0.01399398222565651,
      -0.03375967592000961,
      0.030352624133229256,
      0.09564974159002304,
      -0.04428938403725624,
      -0.02065388113260269,
      0.02966286800801754,
      0.054861489683389664,
      0.03424520045518875,
      0.0443233996629715,
      -0.03167416900396347,
      -0.04745705425739288,
      -0.07726821303367615,
      0.07418094575405121,
      -0.011428010649979115,
      0.03623491898179054,
      -0.09268953651189804,
      -0.05911913141608238,
      -0.06877437978982925,
      0.07733824849128723,
      -0.03079216741025448,
      -0.007406247779726982,
      0.03287266939878464,
      -0.005393784027546644,
      0.012392828240990639,
      -0.014151174575090408,
      -0.008092683739960194,
      0.09003634005784988,
      -0.02567809820175171,
      0.005588545463979244,
      -0.05998038500547409,
      -0.0663825124502182,
      0.011308819986879826,
      -0.005365217104554176,
      -0.017359189689159393,
      0.004987644962966442,
      0.06642075628042221,
      0.004510217811912298,
      0.02199949510395527,
      0.03255822882056236,
      -0.00245434301905334,
      0.008242112584412098,
      0.05378544330596924,
      0.04862966388463974,
      0.07325512915849686,
      0.0008627250790596008,
      -0.01495424285531044,
      0.01928102597594261,
      -0.02045200578868389,
      -0.04374264180660248,
      -0.03356354683637619,
      -0.12426691502332687,
      -0.03117358684539795,
      -0.0925445482134819,
      -0.053025923669338226,
      0.06180553138256073,
      -0.013743160292506218,
      -0.05940476059913635,
      -0.006066672969609499,
      0.0077807120978832245,
      -0.003225682070478797,
      -0.053365226835012436,
      -0.028209270909428596,
      -0.017606252804398537,
      -0.009822532534599304,
      0.024472007527947426,
      0.006718645803630352,
      -0.0692865327000618,
      -0.032322995364665985,
      -0.025093089789152145,
      0.01882988214492798,
      -0.0009650730062276125,
      -0.018797459080815315,
      -0.02968773804605007,
      -0.025101646780967712,
      0.006700796075165272,
      -0.04322027787566185,
      -0.03673486411571503,
      0.021308308467268944,
      -0.0795970931649208,
      -0.06322477012872696,
      0.07418689131736755,
      -0.02183457836508751,
      0.042390596121549606,
      -0.08868623524904251,
      0.011987575329840183,
      0.05565796047449112,
      -0.02153240330517292,
      0.031121669337153435,
      0.06525812298059464,
      -0.023057909682393074,
      -0.0027680688071995974,
      0.02846965752542019,
      0.050930771976709366,
      -0.025726357474923134,
      -0.07461711019277573,
      -0.025335565209388733,
      -0.06959383934736252,
      -0.013877682387828827,
      -0.024503415450453758,
      -0.07123470306396484,
      0.00922942440956831,
      -0.0657498762011528,
      -0.01101916003972292,
      0.03773622587323189,
      0.034176792949438095,
      0.07115740329027176,
      0.04237626865506172,
      -0.04098914563655853,
      -0.016688648611307144,
      0.07155120372772217,
      0.002676659496501088,
      -0.01799595355987549,
      0.049028173089027405,
      -0.014971580356359482,
      0.09074071049690247,
      0.034882787615060806,
      0.0017077006632462144,
      0.048091910779476166,
      -0.025473162531852722,
      -0.053189944475889206,
      0.0523611344397068,
      -0.05116725713014603,
      -0.030036775395274162,
      0.022319581359624863,
      0.011448817327618599,
      0.011120902374386787,
      0.011600465513765812,
      -0.03285342454910278,
      -0.08408449590206146,
      0.05362849310040474,
      0.02574189566075802,
      -0.014078913256525993,
      0.058556102216243744,
      0.07044285535812378,
      0.03516771271824837,
      0.019571030512452126,
      -0.05952759087085724,
      0.020270057022571564,
      0.06284818798303604,
      -0.046915844082832336,
      0.007579786237329245,
      0.007443620823323727,
      -0.0027499974239617586,
      0.08012199401855469,
      -0.06957701593637466,
      -0.015375914052128792,
      0.0006644274108111858,
      -0.028726352378726006,
      0.11161962896585464,
      -0.003659164998680353,
      0.10118149220943451,
      0.052924737334251404,
      -0.025422099977731705,
      -0.04882020503282547,
      -0.055094216018915176,
      0.028357500210404396,
      -0.027014445513486862,
      -0.012206636369228363,
      -0.07688791304826736,
      -0.05383305251598358,
      0.012637114152312279,
      -0.012381485663354397,
      -0.049871064722537994,
      0.016049711033701897,
      0.03634000942111015,
      -0.037034329026937485,
      0.0647105872631073,
      0.054990824311971664,
      -0.0083911819383502,
      0.014235454611480236,
      0.04204043745994568,
      -0.017972510308027267,
      0.03552467003464699,
      -0.056363701820373535,
      0.002881341613829136,
      -0.059497661888599396,
      -0.006109090521931648,
      0.056804999709129333,
      0.0537070706486702,
      0.01634456217288971,
      0.02349977381527424,
      0.07794036716222763,
      0.03412327915430069,
      0.004172060173004866,
      -0.010592476464807987,
      -0.0686374232172966,
      0.009578571654856205,
      0.0010889957193285227,
      -0.014686993323266506,
      0.002158546354621649,
      0.08268021047115326,
      0.062416668981313705,
      0.02699204534292221,
      -0.00024695025058463216,
      0.08929575234651566,
      -0.08477991819381714,
      0.018066521733999252,
      0.05174686759710312,
      -0.02931571565568447,
      0.06700370460748672,
      -0.010985483415424824,
      0.022671140730381012,
      -0.017835956066846848,
      -0.11668609827756882,
      0.057411160320043564,
      0.038858577609062195,
      0.006352169904857874,
      0.07817339152097702,
      0.027367522940039635,
      0.0638701394200325,
      0.05392323434352875,
      -0.06638272851705551,
      0.03933567553758621,
      -0.08118709176778793,
      -0.0035483131650835276,
      -0.039150550961494446,
      -0.07818672806024551,
      0.05686943233013153,
      -0.025879452005028725,
      -0.0013902033679187298,
      -0.024532929062843323,
      -0.03785291686654091,
      -0.00578338373452425,
      0.012444155290722847,
      0.03390800207853317,
      -0.07089658826589584,
      0.004162929952144623,
      -0.012500716373324394,
      0.015419752337038517,
      0.025997444987297058,
      0.019091932103037834,
      0.009995860047638416,
      0.006914451718330383,
      -0.008750170469284058,
      0.05636880546808243,
      -0.010842321440577507,
      -0.043102554976940155,
      -0.06009833887219429,
      0.07298200577497482,
      -0.033955950289964676,
      -0.005736324470490217,
      0.028979681432247162,
      0.014894182793796062,
      -0.029604878276586533,
      0.05806036293506622,
      0.013568072579801083,
      0.041404273360967636,
      -0.02780274674296379,
      0.003789104288443923,
      0.028369445353746414,
      0.051607146859169006,
      0.0442277230322361,
      -0.007187293376773596,
      0.05204237997531891,
      -0.014330239966511726,
      0.05687837675213814,
      -0.02609221450984478,
      -0.0021073773968964815,
      0.037598300725221634,
      -0.007851289585232735,
      0.014494120143353939,
      0.04343941807746887,
      0.0077011678367853165,
      0.002698564436286688,
      0.029478194192051888,
      0.10258284956216812,
      -0.04434148967266083,
      -0.004013880155980587,
      0.02384871058166027,
      0.006699096877127886,
      -0.011075107380747795,
      0.014557400718331337,
      0.1243288666009903,
      -0.005203644745051861,
      0.016236433759331703,
      -0.010727373883128166,
      0.019278718158602715,
      0.04751462861895561,
      -0.09209713339805603,
      -0.10767648369073868,
      -0.02361869439482689,
      0.03302175924181938,
      -0.12040498852729797,
      -0.08060383796691895,
      0.02848932333290577,
      -0.018475493416190147,
      -0.015472412109375,
      -0.018232304602861404,
      -0.03620452061295509,
      0.007995166815817356,
      0.007933530025184155,
      0.034484706819057465,
      -0.004353559575974941,
      -0.03178256377577782,
      0.0024668551050126553,
      -0.015031316317617893,
      -0.037948593497276306,
      -0.003615232650190592,
      -0.04113486409187317,
      -0.008924365974962711,
      0.010271590203046799,
      -0.03561130166053772,
      -0.03334135562181473,
      -0.05370616167783737,
      0.00020763052452821285,
      0.0033521996811032295,
      7.316279516089708e-05,
      -0.011523746885359287,
      0.014370155520737171,
      -0.03934932127594948,
      0.03403135761618614,
      -0.027551177889108658,
      -0.03143641725182533,
      -0.01187330111861229,
      -0.06793877482414246,
      0.03611777722835541,
      0.07223396003246307,
      0.008933608420193195,
      -0.03487928956747055,
      -0.04408645257353783,
      -0.026527613401412964,
      0.011794005520641804,
      0.06765006482601166,
      -0.013937719166278839,
      0.03240378201007843,
      -0.025287337601184845,
      0.005288585089147091,
      -0.00219808891415596,
      -0.04245549812912941,
      -0.05533066391944885,
      -0.028022458776831627,
      0.025905614718794823,
      -0.0016775610856711864,
      -0.05037814378738403,
      -0.02277499809861183,
      0.03614486753940582,
      0.04021712392568588,
      -0.04350750148296356,
      -0.02424543723464012,
      -0.012506457976996899,
      -0.016715973615646362,
      0.040204524993896484,
      -0.042559605091810226,
      -0.04812166839838028,
      0.012095065787434578,
      0.03346491977572441,
      -0.031211506575345993,
      0.027723591774702072,
      0.0635417029261589,
      -0.000434425484854728,
      0.013228870928287506,
      -0.04686794802546501,
      0.05723032355308533,
      0.02848285809159279,
      0.05327579006552696,
      -0.07229376584291458,
      -0.03200354799628258,
      0.023686174303293228,
      -0.0735904648900032,
      0.14540086686611176,
      -0.06211594492197037,
      0.02643491141498089,
      0.0022271163761615753,
      -0.0334838442504406,
      -0.030738497152924538,
      0.02253063954412937,
      0.009262170642614365,
      -0.021338479593396187,
      -0.04322097823023796,
      -0.05970403552055359,
      0.0270916186273098,
      0.02465924061834812,
      0.03955913335084915,
      0.0405736044049263,
      0.04450124874711037,
      -0.025569044053554535,
      -0.003758368780836463,
      -0.029736267402768135,
      0.09148380905389786,
      -0.019062254577875137,
      0.029140574857592583,
      0.011777284555137157,
      0.009327748790383339,
      0.0799349695444107,
      0.07774333655834198,
      0.02905857004225254,
      0.006663629785180092,
      0.037671856582164764,
      0.01433783583343029,
      0.02213653363287449,
      -0.10392496734857559,
      -0.03658023476600647,
      0.017017265781760216,
      0.08822732418775558,
      0.018273450434207916,
      0.0447365939617157,
      0.09344416111707687,
      0.02206472121179104,
      0.02571946568787098,
      -0.0831441730260849,
      -0.027771057561039925,
      0.04769117012619972,
      -0.060833655297756195,
      -0.04755931347608566,
      -0.022524716332554817,
      0.02307601273059845,
      -0.018382994458079338,
      0.033514220267534256,
      -0.0674876868724823,
      0.007998631335794926,
      0.03518079221248627,
      -0.07604261487722397,
      0.021970368921756744,
      0.062244851142168045,
      -0.0024561509490013123,
      -0.0540611706674099,
      -0.015146677382290363,
      -0.08807704597711563,
      -0.030977876856923103,
      0.010870692320168018,
      -0.030046481639146805,
      0.009614938870072365,
      -0.07605081051588058,
      0.05302995443344116,
      -0.007985163480043411,
      -0.14948856830596924,
      -0.03679205849766731,
      0.002247104188427329,
      -0.10168177634477615,
      0.11396941542625427,
      -0.0011977144749835134,
      -0.017646927386522293,
      0.007392991799861193,
      0.09819237142801285,
      0.0034829184878617525,
      0.054352130740880966,
      -0.0553312674164772,
      0.010379810817539692,
      -0.05217800661921501,
      0.049133315682411194,
      -0.0348944216966629,
      0.06772362440824509,
      -0.05359583720564842,
      -0.014626826159656048,
      -0.04411771148443222,
      -0.018453367054462433,
      -0.03127057105302811,
      -0.03421606868505478,
      -0.03506097570061684,
      -0.04027824103832245,
      0.08004392683506012,
      0.08619499951601028,
      -0.04390499368309975,
      -0.06145676225423813,
      0.014258050359785557,
      -0.04719238355755806,
      -0.05262504145503044,
      -0.013338996097445488,
      -0.07794598489999771,
      -0.09180756658315659,
      0.054636187851428986,
      0.04394804313778877,
      -0.08487612754106522,
      -0.07641863077878952,
      -0.0036062239669263363,
      -0.012289146892726421,
      -0.006188423838466406,
      0.03940988704562187,
      0.01258385181427002,
      -0.09581714123487473,
      -0.08022115379571915,
      -0.028823496773838997,
      -0.0743468776345253,
      -0.01959809474647045,
      -0.12227137386798859,
      -0.03198789805173874,
      0.008934843353927135,
      -0.08196467906236649,
      0.0014495031209662557,
      -0.00737229036167264,
      0.05687877908349037,
      -0.05093115195631981,
      0.007710233796387911,
      0.04306430369615555
    ],
    [
      0.03683488070964813,
      -0.024512115865945816,
      0.032874319702386856,
      -0.01886766590178013,
      0.024437282234430313,
      0.008716666139662266,
      -0.005373904947191477,
      0.041261207312345505,
      -0.034847721457481384,
      0.027155570685863495,
      -0.009268781170248985,
      0.06759059429168701,
      0.004693695344030857,
      0.0028256489895284176,
      -0.01773708686232567,
      -0.17783597111701965,
      0.014756284654140472,
      0.04027639329433441,
      0.015988197177648544,
      0.028674423694610596,
      0.0018328218720853329,
      -0.017199218273162842,
      0.03682393208146095,
      0.006631620228290558,
      -0.038013048470020294,
      -0.059642430394887924,
      -0.04987693950533867,
      -0.036911819130182266,
      -0.03792174905538559,
      -0.0461411215364933,
      -0.03087693266570568,
      -0.02074413187801838,
      0.05708419159054756,
      -0.06387920677661896,
      -0.014188194647431374,
      -0.010336439125239849,
      0.06685056537389755,
      0.07189273834228516,
      -0.028066642582416534,
      0.08299754559993744,
      0.06461489200592041,
      0.05239655822515488,
      -0.059589460492134094,
      -0.08489187061786652,
      -0.024848997592926025,
      -0.031496625393629074,
      -0.021760238334536552,
      -0.010638081468641758,
      0.04429147392511368,
      0.0004732478701043874,
      -0.05125809460878372,
      0.026426667347550392,
      0.011474177241325378,
      -0.033836666494607925,
      0.059122420847415924,
      0.09154054522514343,
      0.027968984097242355,
      0.0010522246593609452,
      -0.09999588131904602,
      0.021691875532269478,
      -0.06364766508340836,
      0.003089261706918478,
      0.01800457201898098,
      0.08557003736495972,
      0.01850423775613308,
      0.024074723944067955,
      -0.023265954107046127,
      0.012888121418654919,
      0.06856081634759903,
      0.0900348350405693,
      -0.06400030851364136,
      0.01786518655717373,
      0.00042106217006221414,
      -0.017460806295275688,
      -0.02419988624751568,
      -0.06395629048347473,
      0.08487891405820847,
      -0.01691100001335144,
      0.01718340814113617,
      -0.040569815784692764,
      0.01114741899073124,
      -0.06228894367814064,
      -0.011582470498979092,
      0.011034462600946426,
      -0.02171742357313633,
      0.09940260648727417,
      -0.011836349964141846,
      -0.03688202425837517,
      0.08776538074016571,
      -0.028326014056801796,
      0.04258449375629425,
      0.01395974401384592,
      -0.09000072628259659,
      -0.12063853442668915,
      0.03840860351920128,
      0.010331133380532265,
      0.0079873725771904,
      -0.07353925704956055,
      0.03725103661417961,
      -0.00466585299000144,
      0.0915982574224472,
      0.04945459961891174,
      0.0594247542321682,
      -0.04839223995804787,
      -0.06635548919439316,
      0.04739552363753319,
      -0.011948232538998127,
      0.0019095769384875894,
      0.035108547657728195,
      0.02006731927394867,
      -0.001905555371195078,
      0.049635302275419235,
      0.015401623211801052,
      0.05922708660364151,
      -0.018383968621492386,
      0.09772403538227081,
      0.04075682908296585,
      -0.07243528962135315,
      0.01129758171737194,
      0.03591548278927803,
      -0.03403722122311592,
      0.03263352811336517,
      -0.018628334626555443,
      -0.027752282097935677,
      0.040366895496845245,
      -0.08568529784679413,
      -0.016076618805527687,
      -0.032957833260297775,
      -0.025777703151106834,
      0.004296909086406231,
      -0.060270145535469055,
      0.05565087869763374,
      0.014070834033191204,
      0.014238905161619186,
      0.048925645649433136,
      0.005604914389550686,
      0.0666785016655922,
      0.006584811955690384,
      -0.02726256102323532,
      -0.029700037091970444,
      0.023283515125513077,
      0.041043683886528015,
      -0.07065713405609131,
      -0.0377158559858799,
      0.022417455911636353,
      -0.030000677332282066,
      0.04228203743696213,
      -0.06589093059301376,
      -0.05454041808843613,
      -0.07686204463243484,
      0.040848661214113235,
      -0.03320100158452988,
      0.04869789630174637,
      -0.026592038571834564,
      -0.030352531000971794,
      0.000727370788808912,
      -0.06488891690969467,
      -0.019227270036935806,
      -0.017262179404497147,
      -0.051474589854478836,
      0.008986622095108032,
      -0.010872205719351768,
      0.012520051561295986,
      0.008548631332814693,
      0.047419946640729904,
      -0.012447843328118324,
      -0.021497443318367004,
      -0.026935014873743057,
      0.00208778353407979,
      0.05670816823840141,
      0.05191024765372276,
      0.012888921424746513,
      -1.413641257386189e-05,
      -0.010925349779427052,
      0.04975961148738861,
      0.0406150221824646,
      -0.009408855810761452,
      -0.003935234621167183,
      0.06279665231704712,
      -0.04285925254225731,
      0.015735477209091187,
      -0.013990306295454502,
      -0.03658495470881462,
      -0.010047726333141327,
      0.007850661873817444,
      0.0823756605386734,
      0.028858855366706848,
      -0.08699598163366318,
      0.015076293610036373,
      -0.006014671176671982,
      0.021716443821787834,
      0.06419298052787781,
      0.05094005540013313,
      -0.06970410794019699,
      0.06933513283729553,
      -0.08479826152324677,
      -0.0743086114525795,
      0.011592423543334007,
      0.020566347986459732,
      -0.10095977038145065,
      0.013920881785452366,
      -0.04999258741736412,
      0.044687386602163315,
      -0.02104930207133293,
      -0.05000247061252594,
      -0.026554064825177193,
      -0.07471693307161331,
      -0.03586398437619209,
      -0.060119036585092545,
      -0.029654765501618385,
      0.025832321494817734,
      0.002382339211180806,
      0.02821403183043003,
      -0.0011785323731601238,
      0.03182432800531387,
      0.053292058408260345,
      -0.03043944388628006,
      -0.08383414894342422,
      0.02718924731016159,
      -0.0075636934489011765,
      -0.011607841588556767,
      0.0269940085709095,
      -0.0328373946249485,
      0.03421453759074211,
      0.028825003653764725,
      -0.042266398668289185,
      -0.03551986813545227,
      0.018636979162693024,
      -0.01035133097320795,
      0.10286130011081696,
      8.11174904811196e-05,
      -0.01364235207438469,
      -0.07984506338834763,
      -0.08041878044605255,
      0.017354300245642662,
      -0.02571137435734272,
      0.023899812251329422,
      0.03530571982264519,
      0.05607825890183449,
      -0.03985033184289932,
      -0.03083134815096855,
      0.02324407920241356,
      -0.0010517657501623034,
      0.061407025903463364,
      -0.024632666260004044,
      0.006179407238960266,
      -0.04823049157857895,
      0.036081332713365555,
      -0.060418687760829926,
      0.019388394430279732,
      0.023167725652456284,
      0.05717312917113304,
      -0.016028117388486862,
      -0.027710242196917534,
      0.048204872757196426,
      -0.03090120665729046,
      0.008652480319142342,
      -0.06650803238153458,
      0.02250950038433075,
      0.020031969994306564,
      -0.028712719678878784,
      -0.029730256646871567,
      -0.04954943805932999,
      -0.08176591992378235,
      -0.03225379064679146,
      -0.10316029191017151,
      0.07018635421991348,
      -0.02009672299027443,
      0.048834215849637985,
      0.06213413178920746,
      0.045385755598545074,
      -0.019540341570973396,
      0.07537707686424255,
      -0.05479484423995018,
      0.028636306524276733,
      0.1085384339094162,
      -0.019263921305537224,
      0.0821649581193924,
      -0.033407412469387054,
      -0.021051127463579178,
      0.022693360224366188,
      0.028305763378739357,
      -0.029478367418050766,
      0.07071784138679504,
      0.03227934613823891,
      0.01521319430321455,
      -0.03758402168750763,
      0.0046297041699290276,
      0.022252840921282768,
      -0.028538765385746956,
      0.05240268632769585,
      0.08406399935483932,
      -0.061382927000522614,
      0.06262470781803131,
      0.00019211010658182204,
      0.0001074060273822397,
      0.0001295691472478211,
      -0.05892577022314072,
      0.001029319129884243,
      -0.006381128448992968,
      0.007290937472134829,
      -0.006451004184782505,
      0.025522403419017792,
      0.04253029078245163,
      -0.006324922665953636,
      -0.01169956848025322,
      0.033187221735715866,
      -0.01454948540776968,
      -0.04240914434194565,
      0.05965491011738777,
      0.005695099476724863,
      -0.0010277845431119204,
      0.012191131711006165,
      -0.043463461101055145,
      0.027858048677444458,
      0.031410101801157,
      0.0026221470907330513,
      -0.062067411839962006,
      -0.05834757164120674,
      -0.006238119211047888,
      -0.005357209127396345,
      -0.022389695048332214,
      0.004001872148364782,
      -0.0854344442486763,
      -0.08595091104507446,
      0.08473128825426102,
      0.03357011452317238,
      -0.08626244962215424,
      -0.017683932557702065,
      0.026165274903178215,
      0.0344543382525444,
      -0.009772774763405323,
      -0.01111163105815649,
      0.018377767875790596,
      0.07966218143701553,
      -0.027576331049203873,
      0.005721624940633774,
      -0.06423188745975494,
      -0.0571247935295105,
      0.030116461217403412,
      0.03495889529585838,
      0.06682741641998291,
      0.08203042298555374,
      0.003913524094969034,
      -0.00670522078871727,
      0.02779477648437023,
      0.027369843795895576,
      -0.021694745868444443,
      -0.00871101301163435,
      -0.04501873254776001,
      -0.0034794993698596954,
      0.048806603997945786,
      -0.05772387236356735,
      -0.04176672548055649,
      0.006507467478513718,
      0.03708800673484802,
      -0.02312328666448593,
      -0.011071421205997467,
      0.0918770506978035,
      -0.08523360639810562,
      0.04158006235957146,
      0.010472885333001614,
      0.03516725078225136,
      0.03245227783918381,
      -0.03225065395236015,
      -0.0027965372428297997,
      0.011806071735918522,
      -0.044951021671295166,
      0.006190058309584856,
      0.0323273129761219,
      0.020781146362423897,
      0.008478610776364803,
      -0.06940432637929916,
      0.030118035152554512,
      0.051726799458265305,
      0.064170703291893,
      0.11093960702419281,
      -0.021840011700987816,
      0.0881311371922493,
      0.007391548715531826,
      -0.03604317829012871,
      0.03238917142152786,
      -0.02643917314708233,
      -0.06233256310224533,
      -0.0714692771434784,
      -0.08475019782781601,
      -0.0021328500006347895,
      0.009095519781112671,
      0.009842921048402786,
      -0.024929899722337723,
      -0.02774856798350811,
      -0.051831964403390884,
      0.00027941513690166175,
      0.0568096786737442,
      -0.0025296034291386604,
      -0.02575896494090557,
      0.005146087147295475,
      -0.0007284648600034416,
      -0.07092466950416565,
      -0.013081022538244724,
      -0.008992352522909641,
      0.05499384179711342,
      -0.009446770884096622,
      0.010349303483963013,
      -0.024351738393306732,
      0.023570692166686058,
      0.05569545924663544,
      0.00976925902068615,
      0.03783801198005676,
      -0.00762319378554821,
      0.019623398780822754,
      -0.02747933752834797,
      -0.017884913831949234,
      -0.03605502471327782,
      0.06093518063426018,
      0.09073151648044586,
      -0.012091190554201603,
      -0.01354120671749115,
      -0.007785525172948837,
      -0.00866815634071827,
      0.03531252220273018,
      -0.014266829937696457,
      0.015694692730903625,
      -0.05665162205696106,
      -0.008948561735451221,
      -0.015129267238080502,
      -0.043155353516340256,
      0.011286883614957333,
      0.008061075583100319,
      0.03822927176952362,
      -0.030317440629005432,
      -0.028687620535492897,
      -0.029895519837737083,
      -0.03077022172510624,
      -0.06024479866027832,
      0.05626881867647171,
      -0.029659001156687737,
      -0.0092545785009861,
      0.02590787224471569,
      0.05296577140688896,
      0.03385830298066139,
      0.009309949353337288,
      -0.04924345389008522,
      -0.016332289204001427,
      0.06207922846078873,
      -0.038262199610471725,
      0.04569954425096512,
      -0.057072076946496964,
      0.0318630076944828,
      0.01185078639537096,
      -0.046239856630563736,
      0.0340227335691452,
      -0.05851712077856064,
      0.006910859607160091,
      -0.002697601681575179,
      -0.014615636318922043,
      0.04905170947313309,
      -0.018726645037531853,
      -0.024978894740343094,
      0.052680861204862595,
      -0.03935219720005989,
      -0.07639411091804504,
      0.011344483122229576,
      -0.054905619472265244,
      0.0485898032784462,
      -0.03094460628926754,
      -0.0441773496568203,
      0.04967226833105087,
      0.06695397943258286,
      0.02205808460712433,
      0.025586513802409172,
      -0.039815809577703476,
      0.005787490867078304,
      0.02723240666091442,
      0.03150453418493271,
      0.014403759501874447,
      -0.009014736860990524,
      0.020189154893159866,
      -0.010543436743319035,
      0.008596033789217472,
      0.011893492192029953,
      0.039826054126024246,
      -0.03247319906949997,
      0.00053629488684237,
      -0.0035217590630054474,
      -0.002786030527204275,
      -0.011073160916566849,
      0.028467632830142975,
      0.0058885663747787476,
      -0.016372907906770706,
      0.021582335233688354,
      0.05456478148698807,
      0.018987393006682396,
      0.05266868695616722,
      -0.048973456025123596,
      0.015797922387719154,
      -0.04365234822034836,
      0.07017520070075989,
      -0.02596358209848404,
      -0.014447812922298908,
      0.017826847732067108,
      0.006775903981178999,
      0.0011913053458556533
    ],
    [
      0.016872717067599297,
      0.003581613302230835,
      0.030952051281929016,
      0.055306702852249146,
      -0.026118015870451927,
      -0.011008933186531067,
      -0.07072832435369492,
      -0.026362191885709763,
      -0.04722074419260025,
      0.06100044399499893,
      -0.019117429852485657,
      0.02204940840601921,
      0.05850346013903618,
      0.0017439672956243157,
      -0.043247733265161514,
      0.011055531911551952,
      0.019203998148441315,
      0.0397973470389843,
      0.07000382244586945,
      -0.05551208555698395,
      0.06821561604738235,
      -0.03164991736412048,
      -0.03103657066822052,
      0.0009582306956872344,
      -0.015516337938606739,
      0.017619604244828224,
      -0.0334901288151741,
      0.00384157826192677,
      -0.07920464128255844,
      -0.03605964779853821,
      -0.0032608038745820522,
      -0.06005280837416649,
      -0.025607870891690254,
      -0.006061985157430172,
      -0.06017608940601349,
      -0.015204660594463348,
      -0.06096768006682396,
      -0.001787530374713242,
      0.018502717837691307,
      0.027918092906475067,
      0.038765955716371536,
      0.027050236240029335,
      -0.0023968364112079144,
      -0.08436144143342972,
      0.019638249650597572,
      -0.02725338377058506,
      0.014622392132878304,
      -0.013394886627793312,
      0.09338830411434174,
      -0.017542535439133644,
      -0.015810823068022728,
      0.004257005173712969,
      0.053078792989254,
      0.022784298285841942,
      -0.044749755412340164,
      -0.062197476625442505,
      0.00321200885809958,
      -0.10563366860151291,
      -0.04301096871495247,
      -0.07780294120311737,
      -0.02462702989578247,
      -0.010882978327572346,
      -0.0077637904323637486,
      -0.02707296796143055,
      0.04701905697584152,
      -0.03970112279057503,
      0.04661796614527702,
      0.020845875144004822,
      -0.044768694788217545,
      -0.0009556945296935737,
      -0.0015563572524115443,
      0.061577994376420975,
      0.015325053595006466,
      -0.06218648701906204,
      0.02123023197054863,
      0.03711484372615814,
      0.01918153092265129,
      0.005096754990518093,
      -0.00887584500014782,
      -0.06179285794496536,
      -0.0023478714283555746,
      0.046426594257354736,
      -0.027697769924998283,
      -0.03742795065045357,
      -0.013717777095735073,
      -0.058414850383996964,
      -0.09691222757101059,
      0.051585178822278976,
      -0.0008738220203667879,
      0.004880662076175213,
      -0.0063094026409089565,
      0.056764159351587296,
      -0.019217969849705696,
      -0.0016529035056009889,
      -0.07264382392168045,
      -0.018561730161309242,
      0.03960626944899559,
      0.07504177838563919,
      -0.09987683594226837,
      0.05250058323144913,
      -0.00653878366574645,
      -0.06062121316790581,
      -0.017427951097488403,
      0.03027377463877201,
      -0.10763988643884659,
      0.0013381927274167538,
      0.052470993250608444,
      -0.07378669083118439,
      0.0004695609677582979,
      0.055335670709609985,
      -0.005780091509222984,
      0.013374035246670246,
      -0.09626632928848267,
      0.01950843818485737,
      -0.042762838304042816,
      -0.0221872441470623,
      -0.0013635113136842847,
      -0.026098866015672684,
      0.02166617661714554,
      0.06384000927209854,
      -0.0017577552935108542,
      -0.023407207801938057,
      0.07069101929664612,
      -0.05705855414271355,
      -0.04007531329989433,
      -0.005456042475998402,
      0.0911848172545433,
      0.0003903710166923702,
      0.06854448467493057,
      -0.020425178110599518,
      -0.0473097488284111,
      0.015177975408732891,
      0.04984695836901665,
      -0.018773475661873817,
      -0.04510331526398659,
      0.01201434526592493,
      0.03305753692984581,
      0.01377894077450037,
      0.025006428360939026,
      0.006096679717302322,
      0.12101373821496964,
      0.03296610340476036,
      0.07164739072322845,
      0.04031043127179146,
      -0.026460183784365654,
      -0.010608258657157421,
      -0.027711333706974983,
      0.027062125504016876,
      0.0801708847284317,
      0.00330465123988688,
      -0.00734372902661562,
      -0.01044650748372078,
      0.06921623647212982,
      0.04622624069452286,
      0.022943943738937378,
      -0.0032438747584819794,
      -0.01435079425573349,
      -0.006300360895693302,
      0.052061837166547775,
      0.016673056408762932,
      -0.05690532177686691,
      -0.010240472853183746,
      0.034908123314380646,
      -0.01790532097220421,
      -0.02051543816924095,
      0.0635107234120369,
      0.022103771567344666,
      -0.03229255601763725,
      0.05902206897735596,
      0.0061036706902086735,
      0.046300534158945084,
      0.02931266650557518,
      -0.0035923717077821493,
      -0.021396644413471222,
      0.0020062464755028486,
      -0.06242327392101288,
      -0.0655202716588974,
      -0.005689103156328201,
      0.051045011729002,
      -0.018858633935451508,
      -0.018720023334026337,
      -0.0390293188393116,
      0.05441970005631447,
      -0.022175025194883347,
      0.00938217993825674,
      0.06145781651139259,
      0.012100894935429096,
      -0.0062097907066345215,
      0.07161031663417816,
      -0.009978926740586758,
      -0.01712489128112793,
      0.07035546749830246,
      0.027234556153416634,
      -0.027674026787281036,
      0.02432139404118061,
      0.08589097112417221,
      0.0379917211830616,
      0.025943713262677193,
      0.03430667892098427,
      -0.005571804009377956,
      0.024015048518776894,
      -0.018719099462032318,
      0.06294722110033035,
      -0.018145844340324402,
      -0.0026414780877530575,
      -0.029501210898160934,
      0.035389743745326996,
      -0.03988897055387497,
      -0.017218489199876785,
      -0.016494903713464737,
      0.038333043456077576,
      0.006381179206073284,
      0.05571678280830383,
      -0.06210484355688095,
      0.07298687845468521,
      0.05743229761719704,
      -0.038774408400058746,
      -0.05143582075834274,
      -0.11739954352378845,
      -0.0256664976477623,
      -0.029724180698394775,
      0.01779063418507576,
      0.08042003214359283,
      -0.0005085591110400856,
      -0.01553999911993742,
      0.008830467239022255,
      -0.04079956188797951,
      -0.025747139006853104,
      0.039928942918777466,
      -0.020513664931058884,
      0.005242444574832916,
      -0.05362003669142723,
      -0.015161041170358658,
      -0.040582075715065,
      -0.025265755131840706,
      0.01663396507501602,
      -0.0291301142424345,
      -0.0122566232457757,
      -0.002232357393950224,
      -0.04112951457500458,
      -0.03564058616757393,
      -0.007725872099399567,
      -0.005452999845147133,
      -0.010720213875174522,
      0.07335229963064194,
      -0.012253290973603725,
      -0.024213282391428947,
      -0.016262587159872055,
      0.05364781990647316,
      -0.003276982344686985,
      -0.08932342380285263,
      0.03285421431064606,
      -0.02199217863380909,
      0.0368669256567955,
      -0.06539670377969742,
      0.03930409997701645,
      -0.023753194138407707,
      0.002627488225698471,
      -0.026965931057929993,
      0.05469921603798866,
      0.0211247056722641,
      -0.01082012802362442,
      0.014900931157171726,
      -0.07348562031984329,
      0.014722581021487713,
      -0.032798901200294495,
      -0.06251415610313416,
      -0.055434636771678925,
      -0.031242206692695618,
      0.017496053129434586,
      -0.013402613811194897,
      0.014155098237097263,
      -0.03100580722093582,
      0.04953868314623833,
      0.013744446448981762,
      0.06825099140405655,
      -0.013449140824377537,
      0.12390714883804321,
      -0.040025100111961365,
      0.032111555337905884,
      -0.01036924310028553,
      -0.02625196985900402,
      -0.1196889877319336,
      -0.08714830130338669,
      0.0019622305408120155,
      0.05835888534784317,
      -0.04871618375182152,
      0.04259137064218521,
      0.06788721680641174,
      -0.058032408356666565,
      0.007349215913563967,
      0.08215697854757309,
      0.029020991176366806,
      0.05963835120201111,
      0.05636373162269592,
      -0.00904406700283289,
      -0.005564255174249411,
      0.018460962921380997,
      -0.071726955473423,
      0.02797679975628853,
      -0.009893410839140415,
      0.010117814876139164,
      0.05410262197256088,
      -0.020165137946605682,
      0.06005328148603439,
      0.04903841018676758,
      -0.04418451339006424,
      0.07627347856760025,
      0.0761917233467102,
      0.006191388238221407,
      -0.08007606118917465,
      -0.028371471911668777,
      0.0019189973827451468,
      0.08298644423484802,
      -0.009769998490810394,
      0.013143126852810383,
      -0.025347188115119934,
      -0.0679064393043518,
      0.0354640930891037,
      -0.044301390647888184,
      0.0821123868227005,
      -0.010138314217329025,
      -0.08725670725107193,
      -0.0009850644273683429,
      -0.013006702996790409,
      0.055412255227565765,
      0.03623441234230995,
      0.014085114002227783,
      -0.010039538145065308,
      0.029004279524087906,
      -0.001297038746997714,
      0.09620924293994904,
      0.013396507129073143,
      0.01938331313431263,
      0.03151111304759979,
      0.01395762711763382,
      -0.019005538895726204,
      -0.036232996731996536,
      -0.0595409981906414,
      -0.03566201776266098,
      0.07130702584981918,
      -0.007738509681075811,
      0.02945854142308235,
      0.04346037656068802,
      0.10670216381549835,
      0.01111461129039526,
      -0.025855284184217453,
      0.07909360527992249,
      -0.032564468681812286,
      -0.02322266437113285,
      -0.010609635151922703,
      -0.027346771210432053,
      -0.004054522607475519,
      0.016618462279438972,
      -0.015720179304480553,
      -0.08327662199735641,
      0.03424757719039917,
      0.008397073484957218,
      -0.03297167643904686,
      0.062177013605833054,
      0.05504707619547844,
      -0.04170353710651398,
      -0.07724553346633911,
      0.026596497744321823,
      -0.020487548783421516,
      0.02128559723496437,
      -0.04266269505023956,
      -0.05702861398458481,
      -0.02416018769145012,
      0.027606258168816566,
      -0.1111937090754509,
      -0.05461544170975685,
      0.1128673255443573,
      0.014520445838570595,
      0.019346315413713455,
      -0.08869342505931854,
      0.05454626679420471,
      -0.006639807019382715,
      0.0034552630968391895,
      0.0413406677544117,
      0.03827143833041191,
      -0.0120244100689888,
      -0.026773681864142418,
      0.054314978420734406,
      0.11416440457105637,
      -0.010037770494818687,
      0.04182816296815872,
      0.0692816972732544,
      -0.03628122806549072,
      0.06484353542327881,
      0.0290739256888628,
      -0.022584805265069008,
      -0.02085447683930397,
      0.07826225459575653,
      0.0153321148827672,
      0.0013287081383168697,
      0.016803188249468803,
      0.027192039415240288,
      0.023633504286408424,
      -0.008940311148762703,
      -0.00044944812543690205,
      0.0001436869497410953,
      -0.0417536124587059,
      0.031714245676994324,
      0.015533329918980598,
      0.03193608298897743,
      0.05762574076652527,
      0.039839137345552444,
      0.00025605567498132586,
      -0.0011649675434455276,
      0.11208958178758621,
      -0.06789229065179825,
      0.02634929120540619,
      -0.09067875146865845,
      0.004046597983688116,
      -0.07643938064575195,
      -0.004164308309555054,
      -0.023937750607728958,
      -0.013920858502388,
      -0.0006050323136150837,
      -0.05039236694574356,
      -0.006668510846793652,
      0.004676950164139271,
      0.06840375065803528,
      0.030608702450990677,
      -0.024984581395983696,
      -0.0013175259809941053,
      -0.02585655450820923,
      0.02631206437945366,
      -0.00044136217911727726,
      0.061908330768346786,
      -0.011432294733822346,
      -0.012442338280379772,
      0.03841276094317436,
      -0.02353687211871147,
      0.07247913628816605,
      0.12183982878923416,
      -0.03397439420223236,
      -0.039483364671468735,
      0.006313811521977186,
      -0.03165817633271217,
      -0.03994600847363472,
      0.01525044720619917,
      0.10143917798995972,
      -0.05738294497132301,
      -0.06275496631860733,
      -0.010026919655501842,
      0.0092717744410038,
      -0.03509477525949478,
      0.019101934507489204,
      -0.05549126863479614,
      0.01884797215461731,
      0.04448850452899933,
      -0.01335499994456768,
      -0.08361001312732697,
      0.039719901978969574,
      0.006608851719647646,
      0.07318674027919769,
      0.020746096968650818,
      0.013934356160461903,
      0.052334532141685486,
      -0.03523184359073639,
      -0.03294339030981064,
      -0.04911276325583458,
      0.01676751673221588,
      0.013976001180708408,
      -0.006732164416462183,
      0.0076019312255084515,
      0.09595375508069992,
      0.02085806429386139,
      -0.042234648019075394,
      -0.029506845399737358,
      -0.002918581711128354,
      -0.021684205159544945,
      0.015226563438773155,
      0.03228531405329704,
      -0.0019500370835885406,
      -0.018134480342268944,
      0.011741476133465767,
      -0.035104431211948395,
      -0.027956968173384666,
      0.03047744743525982,
      0.014204391278326511,
      0.005739252548664808,
      0.0012159000616520643,
      -0.03633790835738182,
      -0.05986308678984642,
      -0.04910660535097122,
      -0.0441264882683754,
      -0.07615645974874496,
      -0.02413680963218212,
      -0.022125406190752983,
      -0.014638279564678669,
      0.11323829740285873,
      -0.03577255457639694,
      0.04282624274492264,
      0.07002506405115128,
      0.024682365357875824,
      0.009793808683753014,
      0.022653093561530113,
      -0.023810572922229767,
      0.0045805079862475395,
      0.08130048215389252
    ],
    [
      -0.058730918914079666,
      -0.008067854680120945,
      -0.004444686230272055,
      -0.050649337470531464,
      0.034806229174137115,
      0.020514613017439842,
      0.03781569004058838,
      0.07021954655647278,
      0.004611752927303314,
      -0.17342916131019592,
      0.06191273778676987,
      0.02128315158188343,
      -0.023031674325466156,
      0.04846883565187454,
      0.00543519901111722,
      -0.01879018358886242,
      -0.026592468842864037,
      0.0007957778288982809,
      -0.04429404437541962,
      -0.03895621374249458,
      0.015491648577153683,
      0.015130585059523582,
      0.013670917600393295,
      0.030015749856829643,
      0.03353160247206688,
      -0.02540508285164833,
      -0.03619041666388512,
      0.08971699327230453,
      -0.05107467249035835,
      -0.0303864236921072,
      -0.05531976372003555,
      0.011235532350838184,
      0.07107079774141312,
      0.03762804716825485,
      0.013758481480181217,
      -0.054492659866809845,
      0.13670901954174042,
      0.08432211726903915,
      0.03308117389678955,
      -0.0034712357446551323,
      -0.006233047693967819,
      0.0686744898557663,
      0.0135025130584836,
      0.02355038747191429,
      0.019481269642710686,
      -0.012243296951055527,
      -0.004729937762022018,
      0.04863535240292549,
      -0.03688470646739006,
      -0.01019237656146288,
      -0.040987055748701096,
      0.031971629709005356,
      0.038508228957653046,
      0.006405032239854336,
      -0.01774296537041664,
      0.009453332051634789,
      -0.07953710854053497,
      -0.031004032120108604,
      -0.02860402688384056,
      0.01955491118133068,
      0.02187272161245346,
      -0.07124348729848862,
      -0.014221147634088993,
      0.03640841320157051,
      0.057680271565914154,
      -0.0808117687702179,
      -0.01541301142424345,
      -0.013958003371953964,
      0.048691920936107635,
      -0.0054282997734844685,
      0.04543128237128258,
      -0.020063769072294235,
      0.06411600112915039,
      0.07479993999004364,
      -0.018751678988337517,
      0.0013437345623970032,
      0.04765132814645767,
      -0.030420830473303795,
      -0.08273432403802872,
      -0.002261121990159154,
      0.005468628369271755,
      0.046159274876117706,
      0.026176251471042633,
      -0.02911684289574623,
      0.004168013110756874,
      -0.012264908291399479,
      -0.011718207038939,
      0.031214294955134392,
      -0.051918864250183105,
      -0.046787079423666,
      0.007387429475784302,
      0.0801759660243988,
      -0.004410385154187679,
      0.042011868208646774,
      0.026008572429418564,
      0.10006117820739746,
      0.04680550843477249,
      -0.000667709275148809,
      -0.05278511717915535,
      0.02104203589260578,
      -0.08071909099817276,
      -0.009554211981594563,
      0.06754101067781448,
      -0.03487762063741684,
      -0.0668657049536705,
      -0.07572104036808014,
      -0.019226210191845894,
      -0.021608866751194,
      0.0303186047822237,
      -0.026016172021627426,
      0.009092012420296669,
      0.05199963599443436,
      0.09198645502328873,
      -0.039576370269060135,
      -0.03282247111201286,
      -0.040781889110803604,
      0.014897451736032963,
      -0.015624861232936382,
      -0.0073417420499026775,
      -0.012836255133152008,
      -0.05700905621051788,
      0.08732899278402328,
      -0.07039918005466461,
      -0.015878820791840553,
      0.02843899093568325,
      -0.019000040367245674,
      0.10000228136777878,
      0.026229724287986755,
      -0.01687956601381302,
      -0.07074806839227676,
      0.007804145570844412,
      -0.040539078414440155,
      0.07282137870788574,
      0.06018231809139252,
      -0.03100850060582161,
      0.02376546896994114,
      0.04956844821572304,
      -0.03432922065258026,
      0.10576001554727554,
      0.055766511708498,
      0.0014886831631883979,
      -0.054531171917915344,
      -0.02191218174993992,
      -0.05261416360735893,
      0.024757297709584236,
      -0.03651144728064537,
      0.05796825885772705,
      0.013893965631723404,
      0.08903598040342331,
      -0.052116408944129944,
      0.03192557394504547,
      0.044978465884923935,
      -0.03397789224982262,
      0.05280882865190506,
      0.03749143332242966,
      0.06357284635305405,
      -0.022348180413246155,
      0.05124485120177269,
      -0.022276857867836952,
      -0.02629397064447403,
      0.07144653052091599,
      0.03215789422392845,
      -0.005689624231308699,
      0.11038436740636826,
      -0.05503977835178375,
      0.0020042031537741423,
      -0.04214097559452057,
      -0.011072250083088875,
      -0.023284897208213806,
      -0.012947472743690014,
      -0.05294169485569,
      -0.031889352947473526,
      0.001638630055822432,
      -0.007184609770774841,
      0.009142003022134304,
      0.011255155317485332,
      -0.11038848757743835,
      0.0057444460690021515,
      0.010014371015131474,
      0.045712754130363464,
      0.05425289273262024,
      0.007492100354284048,
      0.0370708666741848,
      -0.10144256800413132,
      -0.04498780146241188,
      0.04094951972365379,
      0.010453904047608376,
      -0.008551076054573059,
      -0.004555175080895424,
      -0.02131413109600544,
      0.047682613134384155,
      -0.013440297916531563,
      -0.014058717526495457,
      -0.0562766008079052,
      -0.006883714348077774,
      -0.03773747757077217,
      -0.03275930508971214,
      -0.04031271114945412,
      0.014972633682191372,
      -0.06718426942825317,
      -0.026636332273483276,
      -0.03709094598889351,
      0.052548833191394806,
      -0.10747282952070236,
      -0.008052299730479717,
      -0.04132065549492836,
      -0.014651237986981869,
      0.039895493537187576,
      0.016697896644473076,
      -0.024396562948822975,
      0.05947190523147583,
      0.007430433761328459,
      -0.008561022579669952,
      -0.00821656920015812,
      -0.0140931885689497,
      -0.033952631056308746,
      -0.016245445236563683,
      0.042303428053855896,
      -0.026971103623509407,
      -0.06344346702098846,
      0.07384797930717468,
      0.03297076374292374,
      0.0038715340197086334,
      -0.005385161377489567,
      -0.03424229845404625,
      0.05033502355217934,
      -0.03391338139772415,
      0.042134299874305725,
      -0.012876753695309162,
      0.026029009371995926,
      -0.07653340697288513,
      0.029063930734992027,
      -0.020702913403511047,
      0.023538552224636078,
      0.009245183318853378,
      0.040696658194065094,
      0.015949418768286705,
      -0.03137141838669777,
      -0.04556255415081978,
      -0.038845453411340714,
      -0.025899888947606087,
      0.04644076153635979,
      -0.026694754138588905,
      -0.02003038488328457,
      -0.057005710899829865,
      -0.034342311322689056,
      0.004179636016488075,
      -0.03392261639237404,
      -0.024487663060426712,
      0.011553117074072361,
      -0.02274167723953724,
      -0.027253717184066772,
      -0.0051504760049283504,
      0.0013553056633099914,
      0.04598530754446983,
      0.044893134385347366,
      0.03361598029732704,
      0.09152403473854065,
      -0.07389869540929794,
      -0.05093000456690788,
      0.02574109472334385,
      -0.002906023059040308,
      0.10444764792919159,
      -0.02867255173623562,
      -0.041924457997083664,
      -0.03808090090751648,
      -0.0125437555834651,
      -0.09946994483470917,
      0.06032417714595795,
      -0.03017142042517662,
      -0.09085478633642197,
      -0.018318159505724907,
      -0.0010556047782301903,
      -0.027501452714204788,
      -0.0340423546731472,
      -0.03652152046561241,
      0.004938032012432814,
      0.07826609909534454,
      -0.09750418365001678,
      0.01691470853984356,
      -0.005546016152948141,
      -0.03183787688612938,
      0.013868064619600773,
      -0.006331352051347494,
      -0.014877188950777054,
      -0.04750599339604378,
      0.046983931213617325,
      -0.043995026499032974,
      -0.00489877350628376,
      -0.015254214406013489,
      -0.019460178911685944,
      0.028982356190681458,
      3.84171653422527e-06,
      -0.05977553874254227,
      0.043094418942928314,
      0.03521040081977844,
      0.012212269008159637,
      0.023685865104198456,
      -0.03456820920109749,
      -0.07085397839546204,
      0.029052408412098885,
      -0.0040797097608447075,
      -0.08003440499305725,
      -0.016547398641705513,
      0.03409361094236374,
      0.0018206746317446232,
      -0.0546342097222805,
      0.0105770668014884,
      -0.04325735569000244,
      -0.06169894337654114,
      -0.019929910078644753,
      0.030033932998776436,
      -0.11555544286966324,
      -0.010775886476039886,
      -0.080145925283432,
      -0.03125007078051567,
      0.05310134217143059,
      0.05029451102018356,
      0.005536696873605251,
      -0.03688908368349075,
      -7.675716915400699e-05,
      -0.025353584438562393,
      0.0029140417464077473,
      -0.03391198813915253,
      -0.011899590492248535,
      0.03474199399352074,
      -0.013799170032143593,
      0.010649798437952995,
      0.035510119050741196,
      0.019834311679005623,
      0.0234370194375515,
      -0.04486219212412834,
      -0.024156948551535606,
      0.06138119846582413,
      0.08196624368429184,
      -0.0027513783425092697,
      0.007662854623049498,
      0.053957339376211166,
      -0.06992661207914352,
      -0.025715665891766548,
      0.014131991192698479,
      0.011772782541811466,
      -0.06386484950780869,
      -0.02711164392530918,
      -0.02971777319908142,
      0.02187761850655079,
      0.1119203120470047,
      -0.05365210771560669,
      0.024743815883994102,
      0.0449499636888504,
      -0.12492809444665909,
      0.03668567165732384,
      0.10361927002668381,
      0.08263751864433289,
      -0.025096388533711433,
      -0.010794390924274921,
      0.021101467311382294,
      -0.019400330260396004,
      0.016500797122716904,
      -0.030236469581723213,
      0.03464367985725403,
      -0.030775772407650948,
      -0.05208729952573776,
      0.013137823902070522,
      -0.08976331353187561,
      -0.05126243457198143,
      0.02515425905585289,
      0.05744538456201553,
      -0.01226470060646534,
      -0.06315598636865616,
      0.050026487559080124,
      0.07845019549131393,
      -0.06685927510261536,
      0.021312672644853592,
      -0.0848894715309143,
      -0.004884100519120693,
      0.0721399262547493,
      0.007215029094368219,
      0.0574716292321682,
      0.08320911228656769,
      0.018228335306048393,
      -0.011277587153017521,
      0.020049914717674255,
      -0.00023031258024275303,
      0.08253801614046097,
      0.09547163546085358,
      0.01377061102539301,
      -0.0027062424924224615,
      -0.12440918385982513,
      -0.009517556987702847,
      0.0005982198636047542,
      -0.019355224445462227,
      -0.08658640086650848,
      0.007507631089538336,
      0.0328066386282444,
      -0.02527330070734024,
      0.028938941657543182,
      -0.06298379600048065,
      -0.005889073014259338,
      -0.0607781745493412,
      0.08847187459468842,
      -0.011311933398246765,
      -0.07845191657543182,
      -0.027813956141471863,
      -0.03889622911810875,
      -0.07719890028238297,
      0.058077242225408554,
      0.06995857506990433,
      -0.07005546241998672,
      -0.07010412961244583,
      0.06350582093000412,
      0.008427608758211136,
      0.03093685768544674,
      0.03884270787239075,
      -0.020998729392886162,
      -0.00681808777153492,
      -0.05724337324500084,
      0.015004164539277554,
      -0.021037034690380096,
      -0.01305364165455103,
      0.04218263551592827,
      0.007231018505990505,
      -0.0256208386272192,
      -0.00766856549307704,
      -0.0032591186463832855,
      -0.04886982589960098,
      -0.034890104085206985,
      0.05135531350970268,
      0.05379435792565346,
      0.029981685802340508,
      0.032930564135313034,
      -0.005296140443533659,
      0.05596206337213516,
      0.005899286363273859,
      0.0242098867893219,
      0.04135047271847725,
      0.007599544711410999,
      -0.026026999577879906,
      -0.09418832510709763,
      0.017464101314544678,
      -0.02520933374762535,
      -0.027040310204029083,
      -0.06367073208093643,
      0.06002432852983475,
      0.03365607187151909,
      -0.026768367737531662,
      0.03717995434999466,
      -0.01850145310163498,
      -0.008274424821138382,
      -0.04540009796619415,
      0.004371827468276024,
      0.013609236106276512,
      -0.034158140420913696,
      -0.05646006390452385,
      -0.01766449771821499,
      -0.02820320799946785,
      -0.07898513227701187,
      0.009528887458145618,
      -0.02483750879764557,
      0.12242013216018677,
      0.04047061502933502,
      0.009144023060798645,
      -0.01836659573018551,
      -0.04878096282482147,
      -0.08633200824260712,
      -0.009151404723525047,
      -0.0693153515458107,
      -0.037212010473012924,
      -0.056608907878398895,
      0.07306108623743057,
      0.0063389940187335014,
      -0.016440723091363907,
      0.03361052647233009,
      0.051415253430604935,
      -0.034981619566679,
      -0.007867757230997086,
      0.010216264985501766,
      0.011670962907373905,
      0.003836620133370161,
      0.02170693129301071,
      -0.016697391867637634,
      -0.0269776564091444,
      -0.008257213979959488,
      -0.004742405842989683,
      -0.019870471209287643,
      0.07704262435436249,
      -0.007935560308396816,
      -0.04177064448595047,
      0.012913739308714867,
      -0.030619552358984947,
      0.015131019987165928,
      -0.007888898253440857,
      0.011617925018072128,
      0.05625142902135849,
      -0.01819254830479622,
      7.583596016047522e-05,
      0.011711932718753815,
      -0.016978539526462555,
      -0.035746898502111435,
      0.00923103652894497,
      0.07011482864618301,
      -0.035641562193632126,
      0.040414270013570786
    ],
    [
      0.03844228386878967,
      -0.07037052512168884,
      -0.03985743969678879,
      0.03969286009669304,
      0.0035271088127046824,
      0.01762743480503559,
      -0.048160240054130554,
      0.02611624449491501,
      0.023625249043107033,
      0.007080703042447567,
      -0.022559288889169693,
      0.0425022728741169,
      0.09731805324554443,
      -0.011477593332529068,
      0.02342998795211315,
      -0.004262731410562992,
      -0.01348123699426651,
      0.006693230476230383,
      -0.03241457790136337,
      0.024817747995257378,
      0.016551773995161057,
      0.06290892511606216,
      0.036936771124601364,
      0.052235618233680725,
      0.05779777467250824,
      0.0532422699034214,
      -0.06080464646220207,
      -0.014501882717013359,
      0.013891350477933884,
      -0.0029801547061651945,
      0.007643304765224457,
      -0.027570581063628197,
      0.0599946454167366,
      0.06538376212120056,
      0.02520635537803173,
      0.06509966403245926,
      -0.008333025500178337,
      0.004346727859228849,
      0.03619204834103584,
      -0.06126728653907776,
      0.03976456820964813,
      0.04203307628631592,
      -0.00589139387011528,
      -0.03485516831278801,
      -0.021072307601571083,
      0.04882321134209633,
      0.1048882007598877,
      0.0294637531042099,
      -0.08088712394237518,
      -0.011943047866225243,
      0.02110123261809349,
      -0.04355136677622795,
      0.039097774773836136,
      -0.041147731244564056,
      0.04223650321364403,
      -0.03382134437561035,
      0.043774839490652084,
      0.0698203295469284,
      -0.09191253781318665,
      -0.04106846824288368,
      -0.03361145779490471,
      0.06595543026924133,
      -0.03626900911331177,
      -0.04437483474612236,
      0.03341390937566757,
      -0.012048919685184956,
      0.01818571239709854,
      0.010633677244186401,
      0.03729897737503052,
      0.023886222392320633,
      -0.028621887788176537,
      -0.03652140498161316,
      -0.020286759361624718,
      -0.04003530740737915,
      0.017495952546596527,
      0.05035022646188736,
      0.06168592721223831,
      -0.007425742223858833,
      0.020702918991446495,
      -0.017514390870928764,
      -0.020483968779444695,
      -0.02548137493431568,
      -0.03140736743807793,
      -0.00885662343353033,
      0.0440911240875721,
      0.09176163375377655,
      -0.004910020157694817,
      0.06666579842567444,
      -0.011965783312916756,
      0.021499434486031532,
      -0.07901958376169205,
      -0.0057428739964962006,
      -0.05475236847996712,
      0.018027478829026222,
      0.03238985314965248,
      -1.6877045709406957e-05,
      0.015577705577015877,
      0.05916834995150566,
      -0.05909910425543785,
      -0.03555962070822716,
      -0.05194726958870888,
      -0.0032984816934913397,
      0.009113747626543045,
      0.0947887971997261,
      -0.0761232003569603,
      0.028975902125239372,
      -0.004729094915091991,
      0.012903817929327488,
      -0.03717447444796562,
      -0.07786614447832108,
      0.018511010333895683,
      -0.0561261810362339,
      -0.006125647109001875,
      -0.035779159516096115,
      0.026951251551508904,
      0.01891396939754486,
      -0.01651470549404621,
      0.02298496477305889,
      -0.00031260031391866505,
      -0.05071008950471878,
      -0.05300012603402138,
      -0.0026239955332130194,
      -0.02455742098391056,
      -0.007728283293545246,
      -0.040675751864910126,
      -0.05026831477880478,
      -0.037036553025245667,
      0.03336290270090103,
      0.04703899100422859,
      0.09087048470973969,
      0.007633879315108061,
      0.0010377297876402736,
      0.004696825984865427,
      -0.03332890197634697,
      0.051056843250989914,
      -0.031785160303115845,
      -0.09517029672861099,
      -0.027259156107902527,
      -0.009079048410058022,
      0.08283986151218414,
      0.09534388035535812,
      0.02651595138013363,
      -0.014906751923263073,
      -0.003615317167714238,
      -0.06273218989372253,
      -0.02254750020802021,
      -0.0027298429049551487,
      -0.024159355089068413,
      0.017550088465213776,
      -0.02079925499856472,
      -0.007008957210928202,
      -0.07056918740272522,
      -0.01188103947788477,
      0.01678343117237091,
      0.04043161869049072,
      -0.0037599157076328993,
      -0.03831908479332924,
      -0.017357826232910156,
      -0.018929118290543556,
      0.027510514482855797,
      0.04078942909836769,
      0.08062300831079483,
      0.033227093517780304,
      -0.07562491297721863,
      0.03323064744472504,
      -0.03319135308265686,
      0.02375825121998787,
      0.0025231274776160717,
      -0.05641481652855873,
      0.019337188452482224,
      0.05628056451678276,
      -0.01985098607838154,
      -0.06958542764186859,
      -0.0027130611706525087,
      -0.002829616656526923,
      -0.03347673639655113,
      -0.0020209599751979113,
      0.029336970299482346,
      0.04057817533612251,
      0.05510132387280464,
      -0.04951029643416405,
      -0.05444778874516487,
      0.02238919958472252,
      0.011149643920361996,
      0.006038378924131393,
      0.006900002248585224,
      -0.001259101671166718,
      0.018588941544294357,
      -0.09393813461065292,
      -0.03036685287952423,
      -0.02501842752099037,
      0.018803318962454796,
      -0.03347175940871239,
      -0.042694415897130966,
      0.020148446783423424,
      -0.017286891117691994,
      0.0642608255147934,
      0.014352671802043915,
      0.04178803041577339,
      -0.005470681004226208,
      0.032843150198459625,
      0.03965739160776138,
      -0.03002195619046688,
      0.022319048643112183,
      0.024917982518672943,
      -0.029233284294605255,
      0.09773008525371552,
      0.0025275289081037045,
      -0.04635787010192871,
      -0.010146183893084526,
      -0.02282979153096676,
      0.09357767552137375,
      -0.08945939689874649,
      -0.08499602973461151,
      0.07111065089702606,
      0.03498341888189316,
      0.017482135444879532,
      -0.04881327599287033,
      -0.016678309068083763,
      0.04548168554902077,
      -0.02134542725980282,
      0.01083575189113617,
      0.00010930089774774387,
      0.033937953412532806,
      0.05828506872057915,
      -0.057785920798778534,
      0.02866514027118683,
      0.005390701349824667,
      -0.03145354613661766,
      0.012340848334133625,
      -0.05849985033273697,
      -0.015578866004943848,
      -0.00919661670923233,
      -0.014582046307623386,
      -0.000740010931622237,
      -0.01085284911096096,
      -0.009629829786717892,
      -0.0167903583496809,
      -0.026200011372566223,
      -0.0636586844921112,
      0.01204216293990612,
      -0.04160863906145096,
      -0.017358215525746346,
      0.0045660813339054585,
      0.02694285660982132,
      -0.028358452022075653,
      -0.013183703646063805,
      -0.05780872330069542,
      -0.06593779474496841,
      0.03982783108949661,
      0.09221453964710236,
      0.016410917043685913,
      0.05045808106660843,
      0.018516765907406807,
      0.03645500913262367,
      -0.0007828049128875136,
      -0.04864911362528801,
      0.010382480919361115,
      -0.07419680804014206,
      -0.009381287731230259,
      -0.04721520096063614,
      0.014034070074558258,
      -0.009764691814780235,
      0.025940878316760063,
      -0.005461712367832661,
      -0.0063515943475067616,
      0.03815865144133568,
      -0.006385375279933214,
      -0.0015496425330638885,
      -0.008939790539443493,
      -0.024649934843182564,
      -0.02457093447446823,
      -0.07322878390550613,
      -0.035814400762319565,
      0.0176383126527071,
      -0.03729558736085892,
      -0.016993368044495583,
      0.056936077773571014,
      -0.002131449757143855,
      0.029364386573433876,
      -0.012690571136772633,
      0.027924858033657074,
      -0.07674959301948547,
      -0.06444107741117477,
      -0.04542263597249985,
      -0.017079776152968407,
      -0.039271995425224304,
      -0.009649115614593029,
      0.02320217713713646,
      0.0002951120841316879,
      -0.039311833679676056,
      -0.03467608988285065,
      -0.05674026533961296,
      0.02375211752951145,
      -0.05204296484589577,
      -0.029957503080368042,
      0.027512121945619583,
      -0.037061434239149094,
      0.016581030562520027,
      0.071310855448246,
      -0.11753182113170624,
      -0.06531725078821182,
      -0.07931390404701233,
      -0.0063758911564946175,
      0.027323387563228607,
      0.0035189755726605654,
      -0.05863505229353905,
      -0.08785607665777206,
      -0.06320580840110779,
      -0.05681047588586807,
      -0.05770571902394295,
      -0.05390116199851036,
      0.026385784149169922,
      0.09040027111768723,
      0.0432722307741642,
      0.008747472427785397,
      0.0653083398938179,
      -0.04383121430873871,
      -0.027175970375537872,
      -0.0033224972430616617,
      -0.06097576394677162,
      -0.09065158665180206,
      -0.021711427718400955,
      0.06519041955471039,
      -0.035704322159290314,
      0.10621482133865356,
      0.003201460698619485,
      -0.00886793527752161,
      0.03631143644452095,
      0.0008295158040709794,
      -0.004235482309013605,
      0.017749985679984093,
      -0.07435469329357147,
      -0.02696918696165085,
      -0.016052518039941788,
      0.010206062346696854,
      -0.09083093702793121,
      0.04676469787955284,
      -0.08810946345329285,
      -0.0037335033994168043,
      -0.05717947706580162,
      0.06046249344944954,
      0.08563944697380066,
      -0.019815783947706223,
      -0.014915354549884796,
      0.017617054283618927,
      -0.011753532104194164,
      0.09525248408317566,
      -0.02739391289651394,
      -0.015857724472880363,
      0.037270355969667435,
      -0.0561952106654644,
      0.03126147761940956,
      0.05275730788707733,
      -0.059449147433042526,
      0.004311396274715662,
      0.031019266694784164,
      -0.012543690390884876,
      -0.008427130989730358,
      -0.008788800798356533,
      0.017752546817064285,
      0.06640565395355225,
      0.09753371775150299,
      0.07129085808992386,
      0.09588832408189774,
      -0.03786877170205116,
      -0.08447923511266708,
      -0.02304328791797161,
      0.05284220725297928,
      -0.0702366977930069,
      -0.12444723397493362,
      0.023218320682644844,
      -0.02819221280515194,
      0.029493996873497963,
      0.07259473204612732,
      -0.018280191347002983,
      0.010953233577311039,
      0.01736598275601864,
      0.04690847918391228,
      -0.03139585256576538,
      -0.00438695028424263,
      -0.0562143437564373,
      -0.04463070631027222,
      -0.02279122918844223,
      0.0052560316398739815,
      -0.07240530848503113,
      -0.008977155201137066,
      -0.010716739110648632,
      -0.04285518452525139,
      0.10304685682058334,
      -0.09663441777229309,
      -0.003754184115678072,
      -0.0021282488014549017,
      0.049525562673807144,
      -0.04688771069049835,
      -0.07071742415428162,
      -0.01491191703826189,
      -0.048557695001363754,
      -0.06917156279087067,
      0.06945949792861938,
      -0.07899821549654007,
      -0.02430574595928192,
      0.058862995356321335,
      -0.020617427304387093,
      0.020660478621721268,
      0.011736903339624405,
      -0.037899553775787354,
      0.01736951619386673,
      0.0434725247323513,
      -0.04915769770741463,
      -0.055376846343278885,
      0.010357088409364223,
      0.04107707366347313,
      0.012323394417762756,
      -0.008081285282969475,
      0.015588956885039806,
      -0.01678391732275486,
      -0.08623941242694855,
      -0.03699474409222603,
      -0.011336473748087883,
      0.06494037061929703,
      -0.05445356294512749,
      0.06829249113798141,
      -0.11089581251144409,
      -0.013989880681037903,
      0.04798027500510216,
      -0.06201976537704468,
      -0.08130668848752975,
      -0.008304857648909092,
      -0.08909701555967331,
      -0.04306090250611305,
      0.04515553638339043,
      0.059135232120752335,
      0.03139645978808403,
      0.11329930275678635,
      0.04969726875424385,
      0.05216798931360245,
      0.02837497927248478,
      -0.06443613767623901,
      0.045980941504240036,
      -0.0004586839640978724,
      0.048838529735803604,
      0.008272727951407433,
      0.041038304567337036,
      0.07187306880950928,
      0.027900120243430138,
      0.015872085466980934,
      -0.02527078054845333,
      -0.01867762953042984,
      -0.008480959571897984,
      -0.12167175859212875,
      0.058470312505960464,
      0.04075443372130394,
      -0.024344883859157562,
      0.0019412020919844508,
      0.015239126048982143,
      -0.028454815968871117,
      -0.007172905839979649,
      -0.0052329073660075665,
      -0.053322844207286835,
      0.0321015827357769,
      -0.021105289459228516,
      -0.010271602310240269,
      -0.02962508425116539,
      -0.06674069166183472,
      0.030946990475058556,
      0.04883447661995888,
      -0.044832151383161545,
      -0.004717673175036907,
      0.023539599031209946,
      0.06764421612024307,
      0.01520812138915062,
      -0.007163473404943943,
      -0.047944072633981705,
      -0.016045251861214638,
      0.048249080777168274,
      -0.004192622844129801,
      -0.0021661934442818165,
      0.030439751222729683,
      -0.01444305945187807,
      -0.061333753168582916,
      -0.015806350857019424,
      -0.048033133149147034,
      0.08664480596780777,
      -0.024161232635378838,
      0.004150085151195526,
      0.03748130425810814,
      -0.024883631616830826,
      0.019714130088686943,
      0.04205992445349693,
      0.012431795708835125,
      -0.00031298253452405334,
      0.04853071644902229,
      0.03276775777339935,
      0.03704183176159859,
      0.008110887371003628,
      0.08244369924068451,
      0.009253072552382946,
      -0.08289166539907455,
      -0.00251635885797441,
      -0.031694184988737106,
      -0.07999072223901749,
      -0.027947483584284782
    ],
    [
      0.020630810409784317,
      -0.0096461595967412,
      0.04089105874300003,
      0.03560490906238556,
      0.05436302348971367,
      0.01966160349547863,
      0.034696418792009354,
      0.08003126829862595,
      0.02329699508845806,
      0.02487676404416561,
      0.0344097875058651,
      -0.029212504625320435,
      -0.0016588174039497972,
      -0.00630108593031764,
      0.007186451926827431,
      0.0038261504378169775,
      -3.13448763336055e-05,
      0.06598653644323349,
      -0.0339001789689064,
      0.051093194633722305,
      -0.01971365138888359,
      0.008337331004440784,
      0.0021072588860988617,
      0.0432979054749012,
      -0.011724960058927536,
      -0.032116543501615524,
      -0.051226839423179626,
      -0.019872810691595078,
      0.03387238830327988,
      -0.06333998590707779,
      -0.01983034424483776,
      -0.02119291014969349,
      0.0018613687716424465,
      0.028033025562763214,
      0.029825737699866295,
      -0.07911499589681625,
      -0.07633548974990845,
      -0.0031067600939422846,
      0.06211808696389198,
      0.07747655361890793,
      0.016705142334103584,
      -0.017831990495324135,
      0.08652714639902115,
      -0.031019577756524086,
      -0.024527275934815407,
      0.08019601553678513,
      0.03143110126256943,
      0.03239357843995094,
      -0.019214313477277756,
      -0.0209733285009861,
      0.019170980900526047,
      -0.02548857592046261,
      0.02290225587785244,
      0.018321016803383827,
      0.04765479266643524,
      -0.02374568209052086,
      -0.030005261301994324,
      0.047972142696380615,
      -0.028096923604607582,
      0.029565569013357162,
      0.0033498562406748533,
      -0.004616643767803907,
      -0.05365358665585518,
      0.036715470254421234,
      -0.13100630044937134,
      -0.02819736674427986,
      0.07139631360769272,
      -0.028759190812706947,
      -0.011263368651270866,
      0.011266090907156467,
      0.01031057070940733,
      0.012297872453927994,
      -0.11021393537521362,
      0.026904786005616188,
      0.0014436334604397416,
      0.017196329310536385,
      0.004330319818109274,
      0.0034203557297587395,
      0.0072823017835617065,
      0.030185967683792114,
      -0.063265360891819,
      -0.0738769918680191,
      0.0585424080491066,
      -0.010265997610986233,
      -0.05441328138113022,
      -0.01993587426841259,
      -0.06654573231935501,
      0.021610213443636894,
      0.04037012532353401,
      0.023957114666700363,
      -0.0324072539806366,
      -0.005097890738397837,
      -0.07045944780111313,
      -0.06139123812317848,
      0.057112082839012146,
      0.05327747017145157,
      0.024457789957523346,
      0.030854789540171623,
      -0.00513425562530756,
      0.055070169270038605,
      0.06539221853017807,
      -0.033191975206136703,
      -0.03016478568315506,
      0.09973983466625214,
      0.017410799860954285,
      0.015705881640315056,
      -0.0003458760620560497,
      -0.009072808548808098,
      -0.0215576384216547,
      -0.04967759549617767,
      -0.054502133280038834,
      0.06502586603164673,
      -0.02063501626253128,
      0.025413526222109795,
      -0.021205194294452667,
      -0.03074532188475132,
      -0.031522925943136215,
      -0.017861803993582726,
      0.019906844943761826,
      0.09794792532920837,
      -0.08833184838294983,
      0.06346846371889114,
      -0.01693570800125599,
      -0.005764927715063095,
      -0.009232951328158379,
      0.027654312551021576,
      -0.0367475301027298,
      0.05965220928192139,
      -0.033278726041316986,
      0.06961014121770859,
      0.0787765309214592,
      0.025781884789466858,
      0.0071340459398925304,
      0.0758737325668335,
      -0.018477777019143105,
      -0.08438712358474731,
      -0.0040512592531740665,
      -0.0006079112645238638,
      -0.0038315237034112215,
      -0.057085681706666946,
      -0.03098355419933796,
      0.09139951318502426,
      0.004014101345092058,
      -0.05563429743051529,
      0.004633461125195026,
      -0.01483938843011856,
      -0.013686989434063435,
      -0.015066132880747318,
      0.04710080474615097,
      0.010508270002901554,
      0.03581488877534866,
      -0.023292286321520805,
      0.019267451018095016,
      0.08533420413732529,
      0.03711065649986267,
      -0.02581237442791462,
      0.01508190855383873,
      0.015046597458422184,
      0.018688084557652473,
      -0.06364618241786957,
      -0.011109057813882828,
      -0.019865678623318672,
      -0.019055185839533806,
      0.0587022565305233,
      -0.09672294557094574,
      -0.01988276280462742,
      -0.024580927565693855,
      0.005224762950092554,
      0.02168356068432331,
      -0.06342676281929016,
      -0.008920117281377316,
      -0.009645986370742321,
      0.011513084173202515,
      -0.10049038380384445,
      0.07034614682197571,
      0.006480752490460873,
      0.007537920959293842,
      -0.05351550877094269,
      0.035667866468429565,
      -0.01996505819261074,
      0.02453760802745819,
      0.02171969600021839,
      -0.04746856167912483,
      -0.03585083410143852,
      0.048714425414800644,
      0.04325639456510544,
      0.012638876214623451,
      0.022108882665634155,
      0.005420266184955835,
      0.05456918850541115,
      -0.028099846094846725,
      0.020747821778059006,
      0.10975237935781479,
      -0.004245639778673649,
      -0.008031313307583332,
      0.009634592570364475,
      0.032462235540151596,
      0.055150751024484634,
      0.002597449580207467,
      0.014131683856248856,
      0.03710996359586716,
      0.0016612702747806907,
      -0.03878216817975044,
      -0.04389084875583649,
      0.043412357568740845,
      0.006379191763699055,
      -0.04173669219017029,
      0.0966423824429512,
      -0.01248819287866354,
      -0.01058963593095541,
      0.020868897438049316,
      0.015073183923959732,
      0.030908068642020226,
      -0.005403453949838877,
      -0.09310397505760193,
      0.10252025723457336,
      0.053033970296382904,
      -0.027632124722003937,
      0.03680410236120224,
      0.03857845067977905,
      0.005612139590084553,
      0.04635785147547722,
      -0.07706048339605331,
      -0.007721549831330776,
      -0.004949328489601612,
      0.09227094054222107,
      -0.0006239369977265596,
      0.04647643119096756,
      -0.02206403575837612,
      -0.052903223782777786,
      0.03697297349572182,
      0.04543791711330414,
      0.05647134780883789,
      0.09077885746955872,
      0.03526634722948074,
      -0.029073035344481468,
      -0.04461437091231346,
      0.017902841791510582,
      0.02306162565946579,
      -0.03808742016553879,
      -0.000976891373284161,
      0.014199978671967983,
      -0.02805572748184204,
      -0.05196278914809227,
      0.020915944129228592,
      0.11471126228570938,
      -0.05311867222189903,
      -0.011875186115503311,
      0.014261343516409397,
      0.044857561588287354,
      0.022863708436489105,
      -0.01701538823544979,
      0.01143659371882677,
      0.012314604595303535,
      0.0119173564016819,
      0.04626338928937912,
      0.0807887613773346,
      -0.01808270998299122,
      -0.037686243653297424,
      0.03291744366288185,
      -0.009801949374377728,
      -0.027982985600829124,
      0.018134014680981636,
      0.026738906279206276,
      0.01570132002234459,
      0.04867389798164368,
      0.05330193415284157,
      -0.014187279157340527,
      -0.03531690314412117,
      0.0023865029215812683,
      0.060001615434885025,
      -0.03766049072146416,
      0.0022771176882088184,
      0.06215529888868332,
      0.0010907638352364302,
      -0.016774695366621017,
      0.11010492593050003,
      0.08359231054782867,
      0.001970385666936636,
      -0.07714314013719559,
      -0.0012379827676340938,
      0.027275528758764267,
      0.005436259787529707,
      0.012927178293466568,
      -0.06185232475399971,
      0.042779590934515,
      -0.03475435823202133,
      0.02382349967956543,
      0.10308241844177246,
      -0.00032282655593007803,
      0.04609637334942818,
      -0.10012755542993546,
      0.06534184515476227,
      0.04304090887308121,
      -0.01630098558962345,
      -0.0695103257894516,
      0.0071436213329434395,
      0.06727633625268936,
      -0.013769544661045074,
      -0.0244034044444561,
      0.07660803943872452,
      -0.0009810198098421097,
      0.011937172152101994,
      -0.004607354756444693,
      0.04862051457166672,
      0.04792965203523636,
      0.08111482113599777,
      -0.027770470827817917,
      0.06578449159860611,
      -0.006214459892362356,
      -0.021964842453598976,
      -0.053151119500398636,
      0.03076326847076416,
      0.026199165731668472,
      -0.01718129962682724,
      -0.0010829156963154674,
      -0.06823870539665222,
      0.0003435599501244724,
      -0.03091142699122429,
      -0.04457388445734978,
      0.007033867295831442,
      0.03330245241522789,
      -0.010159261524677277,
      -0.026759881526231766,
      -0.03258930891752243,
      0.056473057717084885,
      -0.06666230410337448,
      0.05657777935266495,
      0.03231193497776985,
      0.012431877665221691,
      0.037574268877506256,
      -0.05584701523184776,
      0.032623227685689926,
      0.01019211020320654,
      0.06212424859404564,
      0.06166817247867584,
      0.02024252898991108,
      0.07524228096008301,
      0.0054946294985711575,
      0.02726168744266033,
      -0.06056533753871918,
      0.007066883146762848,
      0.017287883907556534,
      0.033634867519140244,
      -0.037839338183403015,
      -0.02172294817864895,
      -0.07851467281579971,
      -0.005059447139501572,
      -0.03339405357837677,
      0.05732593685388565,
      -0.007536177523434162,
      0.025537163019180298,
      -0.048110052943229675,
      0.05027054250240326,
      -0.01834954135119915,
      0.03678916394710541,
      -0.05151957646012306,
      0.03053993172943592,
      -0.027781585231423378,
      -0.06771736592054367,
      0.06177525222301483,
      0.008361670188605785,
      -0.03596729785203934,
      -2.107426007569302e-05,
      -0.007715268991887569,
      -0.030724551528692245,
      -0.015351756475865841,
      0.08326683938503265,
      -0.06429069489240646,
      -0.06565462797880173,
      0.03847406059503555,
      0.018672456964850426,
      -0.00924453604966402,
      0.04654987156391144,
      0.043662816286087036,
      0.04909704998135567,
      -0.04310593754053116,
      0.039132650941610336,
      -0.024661289528012276,
      -0.025918275117874146,
      -0.010561413131654263,
      -0.02815951406955719,
      -0.023237185552716255,
      0.012774587608873844,
      0.010213285684585571,
      0.05680432170629501,
      -0.027695298194885254,
      -0.013567144051194191,
      0.0531466118991375,
      0.03098306804895401,
      0.03296365961432457,
      0.021474165841937065,
      0.01463797502219677,
      0.09138801693916321,
      0.04471254721283913,
      0.04114806279540062,
      0.021925482898950577,
      0.01989091746509075,
      -0.04542295262217522,
      -0.014764027670025826,
      0.02973112091422081,
      -0.011879026889801025,
      0.05505428463220596,
      -0.005365404300391674,
      0.010263273492455482,
      0.08792303502559662,
      -0.02920997329056263,
      0.0782461017370224,
      0.06090781092643738,
      -0.061202216893434525,
      0.045976705849170685,
      0.01740644872188568,
      0.025952095165848732,
      0.07939668744802475,
      -0.002859954722225666,
      0.02943115122616291,
      0.033610641956329346,
      -0.0043179793283343315,
      -0.055600013583898544,
      0.04992355406284332,
      -0.019955091178417206,
      0.017367003485560417,
      -0.10157500207424164,
      -0.0029280937742441893,
      -0.03041669726371765,
      -0.09592592716217041,
      -0.03465389832854271,
      -0.037234529852867126,
      -0.014929213561117649,
      -0.07148721069097519,
      -0.04240964725613594,
      0.04125484451651573,
      0.048380136489868164,
      0.07016496360301971,
      -0.004382581450045109,
      0.020214196294546127,
      -0.05630774796009064,
      -0.004794234409928322,
      -0.03184792026877403,
      0.0039046502206474543,
      -0.009118941612541676,
      -0.008350929245352745,
      0.012092002667486668,
      0.003166058799251914,
      -0.027264129370450974,
      -0.009820324368774891,
      0.014676231890916824,
      0.04531680792570114,
      -0.0006163791404105723,
      -0.05469672381877899,
      -0.006387761794030666,
      0.041848693042993546,
      -0.00918527226895094,
      0.01484296377748251,
      -0.07911672443151474,
      0.07545193284749985,
      0.04529469832777977,
      -0.07847493141889572,
      0.03364228829741478,
      -0.002380463993176818,
      0.07189847528934479,
      -0.07741421461105347,
      0.059758562594652176,
      0.014807105995714664,
      -0.08006088435649872,
      0.019041849300265312,
      0.005611732602119446,
      0.033251795917749405,
      -0.04436969384551048,
      -0.023676596581935883,
      -0.0003289452870376408,
      -0.05242663621902466,
      0.07013290375471115,
      -0.023092912510037422,
      -0.013021188788115978,
      -0.006915896199643612,
      -0.05156425014138222,
      -0.058267991989851,
      0.02390238083899021,
      0.055364981293678284,
      0.009163012728095055,
      -0.09125211834907532,
      -0.005746846552938223,
      0.04756303131580353,
      -0.04080728441476822,
      -0.02760145254433155,
      -0.044507142156362534,
      -0.06999325752258301,
      -0.07959289848804474,
      -0.03229405730962753,
      -0.01013086922466755,
      0.025839604437351227,
      0.02985750325024128,
      -0.004556902684271336,
      0.002454314148053527,
      -0.006809919606894255,
      0.0004475969180930406,
      -0.002039619954302907,
      0.07625573873519897,
      0.034851644188165665,
      -0.07591952383518219,
      0.049643583595752716,
      0.05289408564567566
    ],
    [
      0.014730935916304588,
      0.013777990825474262,
      -0.0863896831870079,
      0.021595526486635208,
      0.023590218275785446,
      0.046649202704429626,
      -0.020435133948922157,
      -0.005850416142493486,
      0.08418282121419907,
      -0.047613475471735,
      -0.05649244785308838,
      -0.014639776200056076,
      0.006024436093866825,
      0.05014264956116676,
      -0.016192635521292686,
      0.0025356982368975878,
      -0.001469427952542901,
      -0.02138306200504303,
      -0.07900699973106384,
      -0.025336135178804398,
      -0.0012672294396907091,
      0.05168581381440163,
      -0.05674440413713455,
      -0.004906919784843922,
      0.01842614635825157,
      -0.060970816761255264,
      -0.1227794960141182,
      -0.02857602946460247,
      -0.05769948661327362,
      -0.07498189806938171,
      0.02572542428970337,
      0.014575144276022911,
      0.024910079315304756,
      -0.008017665706574917,
      0.019970862194895744,
      0.019639473408460617,
      -0.06043090298771858,
      -0.11750669032335281,
      -0.039480049163103104,
      0.0332329124212265,
      -0.016516199335455894,
      -0.053499504923820496,
      -0.02374841831624508,
      -0.05458442494273186,
      -0.022436615079641342,
      0.06548251211643219,
      0.0034733167849481106,
      0.021259846165776253,
      -0.02866360917687416,
      0.026043370366096497,
      0.013084441423416138,
      -0.03929666802287102,
      -0.00556821096688509,
      -0.04853569343686104,
      0.042897891253232956,
      0.04504019767045975,
      0.04137131944298744,
      -0.02067655697464943,
      0.03816704824566841,
      0.0847451388835907,
      -0.015344127081334591,
      -0.0807303637266159,
      0.002251479309052229,
      0.07933875918388367,
      -0.006159545853734016,
      -0.023153379559516907,
      -0.05452577397227287,
      -0.13153579831123352,
      -0.032375503331422806,
      -0.018604764714837074,
      0.0009979024762287736,
      -0.014117696322500706,
      -0.06429004669189453,
      0.08470424264669418,
      0.0034040273167192936,
      -0.009614654816687107,
      0.009393291547894478,
      0.01561336126178503,
      -0.01112093310803175,
      0.0034158448688685894,
      0.04432820901274681,
      0.04510647803544998,
      -0.02008707821369171,
      -0.020296704024076462,
      -0.005832707043737173,
      -0.10382341593503952,
      -0.01736183650791645,
      0.012667596340179443,
      0.013550455681979656,
      -0.022822681814432144,
      0.018424106761813164,
      -0.06287962198257446,
      0.049752939492464066,
      0.04734434187412262,
      -0.005548960063606501,
      -0.04185545817017555,
      0.029859403148293495,
      -0.04250814765691757,
      -0.023722263053059578,
      0.00045989188947714865,
      0.05652351304888725,
      -0.0023368671536445618,
      -0.048872336745262146,
      -0.008650178089737892,
      0.04198930412530899,
      -0.040860939770936966,
      0.03845689818263054,
      0.0160191860049963,
      0.029919464141130447,
      0.021850580349564552,
      0.017663633450865746,
      -0.00015140879258979112,
      0.015793565660715103,
      0.023368990048766136,
      -0.05546689033508301,
      0.08989282697439194,
      0.028612559661269188,
      -0.0014796628383919597,
      -0.04248710721731186,
      -0.014713183976709843,
      -0.08475928753614426,
      -0.03688014671206474,
      -0.020510433241724968,
      0.03742110729217529,
      -0.04853687807917595,
      0.029649673029780388,
      -0.0015471826773136854,
      -0.022087352350354195,
      -0.008670320734381676,
      0.021806960925459862,
      -0.034940168261528015,
      -0.09870335459709167,
      0.0009366028243675828,
      0.09000154584646225,
      -0.068682961165905,
      0.020590778440237045,
      0.016686463728547096,
      0.01653202250599861,
      0.03834911063313484,
      -0.022187471389770508,
      0.030693750828504562,
      -0.029730698093771935,
      0.05034623667597771,
      0.06154223531484604,
      0.034102343022823334,
      -0.016280848532915115,
      -0.020466778427362442,
      -0.027771245688199997,
      0.008229104802012444,
      0.0394873321056366,
      0.057220928370952606,
      0.043635595589876175,
      0.05550353229045868,
      0.04960509389638901,
      0.04556930065155029,
      -0.04078338295221329,
      0.020155562087893486,
      0.019029401242733,
      0.06633640080690384,
      -0.05448874458670616,
      0.0031439366284757853,
      -0.0745914950966835,
      -0.06352172046899796,
      -0.04906729608774185,
      0.027261240407824516,
      -0.0055663869716227055,
      -0.008857973851263523,
      0.07654917985200882,
      -0.02946513332426548,
      0.06897980719804764,
      -0.044304270297288895,
      0.050060760229825974,
      0.09020575135946274,
      0.01941256783902645,
      0.0613073967397213,
      -0.0036803886760026217,
      -0.022468455135822296,
      -0.0318085215985775,
      -0.008838615380227566,
      -0.028175920248031616,
      -0.004373159725219011,
      -0.017253005877137184,
      -0.021862056106328964,
      -0.04347585141658783,
      0.05601958557963371,
      -0.08756457269191742,
      -0.0020861339289695024,
      -0.03995083272457123,
      0.013278980739414692,
      -0.018620412796735764,
      -0.06957896798849106,
      0.0034031879622489214,
      -0.026277465745806694,
      0.05633372440934181,
      0.00043177162297070026,
      0.059559788554906845,
      -0.017248352989554405,
      0.06345348805189133,
      -0.010528654791414738,
      0.009391948580741882,
      0.045299679040908813,
      0.03671756014227867,
      -0.07476309686899185,
      0.035040926188230515,
      -0.009469578042626381,
      -0.07406137883663177,
      -0.0026674361433833838,
      -0.05372649431228638,
      0.019934790208935738,
      -0.07905055582523346,
      0.010913301259279251,
      0.02119419351220131,
      -0.0702836737036705,
      -0.05479733273386955,
      -0.010891901329159737,
      -0.01715172827243805,
      0.07427854835987091,
      0.020896902307868004,
      0.05679592862725258,
      0.016672935336828232,
      0.014364945702254772,
      0.0645827054977417,
      0.02178836613893509,
      -0.06681206077337265,
      0.033785123378038406,
      0.06344351172447205,
      0.041190873831510544,
      0.0442013256251812,
      -0.06108807027339935,
      0.06275622546672821,
      -0.04125566408038139,
      0.11459234356880188,
      0.062322016805410385,
      0.004430023021996021,
      0.08194655179977417,
      -0.08952251821756363,
      0.05107886344194412,
      0.029292495921254158,
      -0.058923713862895966,
      0.001902319840155542,
      -0.005000677891075611,
      0.05136696994304657,
      0.05267864465713501,
      0.03808724135160446,
      0.021199261769652367,
      0.06363339722156525,
      -0.00506777036935091,
      -0.0669347494840622,
      -0.06468523293733597,
      0.03566604107618332,
      -0.06834302097558975,
      -0.030889535322785378,
      0.02129732072353363,
      -0.050023920834064484,
      0.008257022127509117,
      0.10481785237789154,
      0.06497490406036377,
      0.05325673148036003,
      0.048808299005031586,
      0.029399331659078598,
      0.042803168296813965,
      0.01998857595026493,
      -0.03526969626545906,
      -0.004164522048085928,
      -0.022307025268673897,
      -0.005948028527200222,
      0.00456650136038661,
      -0.05413984879851341,
      0.008972277864813805,
      -0.011460922658443451,
      -0.043723929673433304,
      -0.013333120383322239,
      0.05067664757370949,
      -0.02549676224589348,
      0.034159693866968155,
      -0.03593362867832184,
      -0.004469297360628843,
      0.0038707777857780457,
      -0.04567943140864372,
      -0.0031552740838378668,
      -0.017346734181046486,
      -0.03622830659151077,
      -0.04514658823609352,
      -0.08383359014987946,
      -0.016025036573410034,
      0.14900025725364685,
      0.029094498604536057,
      -0.06065554916858673,
      0.044348567724227905,
      -0.0738835483789444,
      0.11888961493968964,
      0.015911532565951347,
      0.013279842212796211,
      -0.03127150610089302,
      -0.04595763236284256,
      0.021146755665540695,
      0.007558447774499655,
      0.006304716691374779,
      0.04193470999598503,
      -0.03193620592355728,
      0.06747615337371826,
      -0.01815141551196575,
      0.02209554985165596,
      0.04227370023727417,
      0.03260922431945801,
      0.033182356506586075,
      -0.03737892210483551,
      -0.003082397161051631,
      -0.05884214863181114,
      0.054412394762039185,
      -0.006549084093421698,
      0.041197389364242554,
      0.03197210654616356,
      0.04094778373837471,
      0.02823241986334324,
      0.034170858561992645,
      0.02319880574941635,
      0.038555171340703964,
      0.036330725997686386,
      0.03126491233706474,
      0.005434114020317793,
      0.009527034126222134,
      -0.02664138749241829,
      0.005654900800436735,
      0.0024033719673752785,
      0.032005298882722855,
      0.007472475524991751,
      -0.07252563536167145,
      -0.0704333484172821,
      0.03135400265455246,
      -0.01421270053833723,
      0.02109508588910103,
      0.07549115270376205,
      -0.049013059586286545,
      0.019324814900755882,
      -0.13816434144973755,
      0.0227828212082386,
      -0.041721854358911514,
      0.015792325139045715,
      -0.03569905832409859,
      0.018084635958075523,
      0.0027333800680935383,
      -0.034480124711990356,
      0.007331318221986294,
      0.0076431212946772575,
      -0.05114907771348953,
      0.09450828284025192,
      0.02296426147222519,
      0.03459868207573891,
      -0.04110283777117729,
      0.03470379486680031,
      0.027331585064530373,
      -0.027647903189063072,
      0.05940191075205803,
      -0.025583919137716293,
      0.03725812956690788,
      -0.022958198562264442,
      -0.018898364156484604,
      -0.02800188586115837,
      0.020350107923150063,
      -0.1227237731218338,
      -0.07340223342180252,
      0.00044562824768945575,
      -0.03540925681591034,
      0.13317765295505524,
      -3.097869921475649e-05,
      0.0383719727396965,
      -0.006599279586225748,
      0.017057031393051147,
      -0.07799452543258667,
      -0.025201018899679184,
      0.041518229991197586,
      0.09557776153087616,
      -0.009010694921016693,
      0.011761640198528767,
      -0.05003433674573898,
      -0.02302628941833973,
      0.06642000377178192,
      -0.02342551201581955,
      -0.045493435114622116,
      0.01513073593378067,
      0.02453981526196003,
      0.03184095025062561,
      -0.0344625785946846,
      -0.03576092794537544,
      0.0024047712795436382,
      -0.01257050409913063,
      0.05693506821990013,
      -0.017675284296274185,
      -0.06299770623445511,
      -0.03487520292401314,
      -0.07138767838478088,
      0.01739959977567196,
      -0.019468743354082108,
      -0.0004364177002571523,
      -0.017918594181537628,
      -0.04315270856022835,
      -0.009479939937591553,
      -0.019620193168520927,
      0.07066694647073746,
      0.03341631218791008,
      -0.02714904583990574,
      -0.017496023327112198,
      0.08300013840198517,
      0.05140283703804016,
      0.07322636246681213,
      0.00783702451735735,
      0.049513183534145355,
      -0.023371946066617966,
      0.017507143318653107,
      0.003572881454601884,
      -0.043297313153743744,
      -0.08119357377290726,
      -0.07737737149000168,
      0.0398092158138752,
      0.051517993211746216,
      0.006476092152297497,
      -0.06753214448690414,
      0.04854947328567505,
      -0.03092312626540661,
      -0.01338112261146307,
      -0.014565282501280308,
      0.07289105653762817,
      0.03921911120414734,
      -0.05940617248415947,
      -0.03353244438767433,
      0.021132227033376694,
      0.03184099122881889,
      0.008092307485640049,
      0.04407821595668793,
      0.0025974197778850794,
      -0.013976850546896458,
      -0.04396692290902138,
      0.007472200784832239,
      -0.046209465712308884,
      0.02315164916217327,
      0.001781511353328824,
      -0.06567878276109695,
      -0.02060389332473278,
      0.03592795878648758,
      -0.020809002220630646,
      -0.024056585505604744,
      0.031063009053468704,
      -0.037331320345401764,
      -0.013499993830919266,
      -0.019777270033955574,
      -0.04879122972488403,
      -0.009216620586812496,
      -0.008298820815980434,
      -0.0024122970644384623,
      0.04012833535671234,
      0.042465940117836,
      0.05647789314389229,
      -0.03839648887515068,
      -0.055099908262491226,
      0.009513264521956444,
      0.06182926893234253,
      0.02696152590215206,
      -0.051170557737350464,
      0.003458386519923806,
      0.028064031153917313,
      0.03429211676120758,
      0.04140191152691841,
      0.04874745011329651,
      0.05349944159388542,
      0.04582162946462631,
      -0.010100961662828922,
      -0.03771926462650299,
      0.02880125865340233,
      0.055165767669677734,
      -0.03764853626489639,
      -0.042988501489162445,
      0.0017380863428115845,
      -0.06688229739665985,
      0.06544400006532669,
      -0.019658461213111877,
      -0.014472371898591518,
      -0.0374196395277977,
      0.026256872341036797,
      0.017908858135342598,
      -0.01611413061618805,
      0.03082452528178692,
      0.02334211952984333,
      0.06704559177160263,
      -0.0077390349470078945,
      0.10219702869653702,
      0.044560663402080536,
      0.05769776552915573,
      0.07241895794868469,
      0.05807819589972496,
      -0.116111621260643,
      0.00519939698278904,
      -0.0032710412051528692,
      0.056259408593177795,
      -0.017789917066693306,
      0.01547962799668312,
      -0.05415454879403114,
      -0.011567211709916592,
      -0.012972495518624783,
      0.0350804403424263,
      -0.014006518758833408,
      0.06119276210665703,
      0.006253655534237623
    ],
    [
      0.03032403625547886,
      -0.08368101716041565,
      -0.0894014984369278,
      -0.018310870975255966,
      0.010428640991449356,
      0.08928243815898895,
      -0.04649514704942703,
      -0.021533669903874397,
      -0.02873239852488041,
      0.03855705261230469,
      -0.005019075702875853,
      -0.004341689869761467,
      0.04125554859638214,
      0.0015676215989515185,
      0.029905807226896286,
      -0.01428584661334753,
      0.020112428814172745,
      -0.0014860534574836493,
      0.0845196545124054,
      0.004573766142129898,
      -0.03702494874596596,
      0.02195618487894535,
      0.02452094666659832,
      0.028300968930125237,
      0.012206019833683968,
      -0.08937586843967438,
      0.1220117136836052,
      -0.011288382112979889,
      -0.06138285994529724,
      -0.03880661725997925,
      -0.030214974656701088,
      -0.00529826432466507,
      0.005298399832099676,
      -0.06488589942455292,
      -0.03750442713499069,
      0.0022719905246049166,
      0.05926448106765747,
      0.014446131885051727,
      -0.043005507439374924,
      0.1340714693069458,
      0.024504803121089935,
      0.06736515462398529,
      0.035677529871463776,
      -0.06467502564191818,
      0.047001808881759644,
      0.01651996746659279,
      0.029005806893110275,
      -0.02552765980362892,
      -0.11723765730857849,
      -0.009572598151862621,
      -0.1337510198354721,
      -0.021018926054239273,
      -0.026379559189081192,
      0.07969815284013748,
      0.09534254670143127,
      -0.012252629734575748,
      0.021575190126895905,
      0.047425221651792526,
      -0.024642635136842728,
      -0.10971859842538834,
      -0.04171152040362358,
      -0.05011871084570885,
      -0.00805877149105072,
      -0.02008471079170704,
      0.03036116622388363,
      -0.010506808757781982,
      0.08325620740652084,
      0.13314884901046753,
      -0.09001398086547852,
      -0.0021214759908616543,
      0.031070834025740623,
      0.05494387447834015,
      -0.036422453820705414,
      0.00518700759857893,
      0.023749196901917458,
      0.06333116441965103,
      0.05994952842593193,
      0.02341623604297638,
      -0.0125013068318367,
      -0.05364222079515457,
      0.008101576939225197,
      0.013993984088301659,
      -0.01944505050778389,
      0.018097762018442154,
      -0.01922025717794895,
      -0.02997436374425888,
      -0.00044728376087732613,
      -0.0337541289627552,
      -0.0684320330619812,
      -0.055354535579681396,
      -0.008986876346170902,
      -0.04227632284164429,
      0.07413981854915619,
      -0.022372158244252205,
      0.040026724338531494,
      -0.10292056202888489,
      -0.013255001977086067,
      0.043751310557127,
      -0.07545360177755356,
      -0.008642473258078098,
      0.03519389033317566,
      -0.05857149511575699,
      0.04496530815958977,
      -0.03555725887417793,
      -0.011889494024217129,
      0.03581800311803818,
      -0.04038691893219948,
      0.00998238380998373,
      0.09359811991453171,
      -0.02382105402648449,
      -0.011348447762429714,
      -0.06601202487945557,
      0.02567949891090393,
      -0.1360890418291092,
      0.012387656606733799,
      0.005690546706318855,
      0.022481516003608704,
      -0.028442928567528725,
      -0.013413816690444946,
      0.03311764448881149,
      0.03419801965355873,
      -0.023690106347203255,
      -0.07169099897146225,
      -0.02814711630344391,
      0.05733233317732811,
      -0.0073081073351204395,
      -0.05342919006943703,
      0.006343076471239328,
      0.06168290600180626,
      0.021350786089897156,
      0.033611197024583817,
      -0.03343775123357773,
      0.06215730682015419,
      0.039240624755620956,
      -0.01145082339644432,
      -0.09629008919000626,
      0.006095157004892826,
      0.04705777391791344,
      0.04781516268849373,
      -0.018002377822995186,
      0.06445201486349106,
      -0.01826741360127926,
      0.0688721090555191,
      -0.02387346513569355,
      0.04183971509337425,
      0.007213164120912552,
      0.026071472093462944,
      -0.013181351125240326,
      0.0021670260466635227,
      -0.011271419003605843,
      -0.003946644254028797,
      -0.07060570269823074,
      0.004726875107735395,
      -0.005549865774810314,
      0.04154727980494499,
      0.06458884477615356,
      0.0020044948905706406,
      0.0029926577117294073,
      -0.029485292732715607,
      -0.03332679718732834,
      0.08534388244152069,
      0.049761250615119934,
      -0.047592442482709885,
      -0.01684393174946308,
      -0.009119809605181217,
      -0.07974790036678314,
      0.0195612832903862,
      0.007648809812963009,
      0.09264419227838516,
      0.05949079990386963,
      -0.017951330170035362,
      -0.01851484552025795,
      -0.0014726590598002076,
      0.01017529983073473,
      -0.05835127457976341,
      -0.02948334440588951,
      -0.0916561409831047,
      -0.0168230552226305,
      0.022297615185379982,
      0.038795892149209976,
      -0.012008053250610828,
      -0.04285547137260437,
      -0.002240727422758937,
      -0.08061464130878448,
      -0.03284536302089691,
      -0.02099793404340744,
      -0.025706471875309944,
      -0.04395679011940956,
      0.07025992125272751,
      -0.04642287641763687,
      0.0034760734997689724,
      0.044228848069906235,
      -0.028853772208094597,
      -0.028182609006762505,
      0.061145540326833725,
      -0.05437657609581947,
      0.04916130751371384,
      -0.003574157366529107,
      -0.06688113510608673,
      -0.02347695082426071,
      -0.037574343383312225,
      0.016850987449288368,
      -0.0581294447183609,
      -0.008602648042142391,
      0.07186415046453476,
      -0.03134952858090401,
      0.0366290807723999,
      -0.017824018374085426,
      -0.05820503830909729,
      0.0428289957344532,
      0.05845453590154648,
      -0.0809793695807457,
      -0.045116618275642395,
      -0.03979240357875824,
      0.029309282079339027,
      -0.041578032076358795,
      0.04973015561699867,
      0.018792519345879555,
      0.040277864784002304,
      -0.019525479525327682,
      0.01573377288877964,
      0.005296667106449604,
      -0.03997597470879555,
      0.03186320513486862,
      0.0024903053417801857,
      -0.028603823855519295,
      -0.009336267597973347,
      0.02463489957153797,
      0.028364792466163635,
      -0.02339850552380085,
      -0.03255492076277733,
      -0.12024900317192078,
      0.07429169863462448,
      0.04699628800153732,
      -0.050327591598033905,
      0.07443967461585999,
      -0.05085187405347824,
      -0.04085647314786911,
      0.015370511449873447,
      0.0233034398406744,
      0.06866506487131119,
      -0.10931795090436935,
      0.03931037709116936,
      0.0016186261782422662,
      0.059653643518686295,
      -0.01974708028137684,
      -0.04019183665513992,
      0.005191889125853777,
      -0.020742639899253845,
      0.016957001760601997,
      0.0042708455584943295,
      -0.04307068884372711,
      -0.03823891282081604,
      0.008953156881034374,
      0.036452893167734146,
      -0.03028896637260914,
      0.11491962522268295,
      0.0022895943839102983,
      -0.04057542234659195,
      0.028062229976058006,
      -0.014079445973038673,
      0.008116055279970169,
      0.03297938033938408,
      0.03405304625630379,
      0.0026179261039942503,
      0.053967662155628204,
      -0.054429735988378525,
      -0.04651495814323425,
      0.041763268411159515,
      -0.06418226659297943,
      -0.014277924783527851,
      0.05763774365186691,
      -0.05129924416542053,
      -0.010491901077330112,
      -0.056794051080942154,
      0.051867589354515076,
      0.06451310217380524,
      -0.0095472177490592,
      0.03399457409977913,
      -0.0077193137258291245,
      0.02373095229268074,
      0.044889356940984726,
      -0.04349669814109802,
      -0.007180177606642246,
      -0.062205422669649124,
      -0.07052115350961685,
      -0.04921684414148331,
      -0.02465665154159069,
      0.0024994518607854843,
      0.010120982304215431,
      -0.04590662941336632,
      0.10250610113143921,
      0.02464786171913147,
      -0.01545167900621891,
      0.04439856857061386,
      0.037105295807123184,
      -0.03337370976805687,
      0.024321207776665688,
      -0.10716115683317184,
      0.017267616465687752,
      0.0400874987244606,
      0.04169440641999245,
      0.07670684158802032,
      0.060511279851198196,
      0.07389800995588303,
      0.07968606799840927,
      -0.02527768351137638,
      -0.03492307290434837,
      0.03342414274811745,
      -0.11668341606855392,
      -0.05188877135515213,
      0.006925478111952543,
      0.049191128462553024,
      -0.08096299320459366,
      0.04779469594359398,
      0.09259020537137985,
      -0.024557098746299744,
      0.08380228281021118,
      0.05023925006389618,
      0.043349847197532654,
      0.023851100355386734,
      0.01203360315412283,
      -0.14126428961753845,
      -0.006686602719128132,
      0.06517063826322556,
      0.043345995247364044,
      -0.012790130451321602,
      -0.003957175649702549,
      -0.0415617935359478,
      -0.007138514891266823,
      0.0397578626871109,
      0.029461974278092384,
      -0.0742315948009491,
      -0.016556400805711746,
      -0.1094159483909607,
      -0.039144013077020645,
      0.004764340817928314,
      0.04330085217952728,
      -0.006870269309729338,
      0.07215294986963272,
      0.0004807701916433871,
      -0.006604955065995455,
      0.08336897194385529,
      0.0980871245265007,
      -0.045249562710523605,
      0.05798758938908577,
      -0.036176107823848724,
      0.0036451267078518867,
      -0.05432913079857826,
      0.059003591537475586,
      0.04866085201501846,
      -0.030860543251037598,
      0.020665517076849937,
      -0.02247227355837822,
      -0.02749023400247097,
      0.05270569398999214,
      -0.012996629811823368,
      0.008727657608687878,
      0.010280617512762547,
      -0.03064470924437046,
      0.055963411927223206,
      0.04853301867842674,
      -0.05111737921833992,
      0.045976508408784866,
      0.05821998417377472,
      -0.025258902460336685,
      0.05834003537893295,
      -0.05106794834136963,
      -0.019616732373833656,
      -0.03746497258543968,
      -0.023576945066452026,
      -0.015154420398175716,
      0.04656652733683586,
      -0.03175375983119011,
      0.008655804209411144,
      0.09020856022834778,
      0.006845525465905666,
      0.049660902470350266,
      0.06690983474254608,
      0.03768761083483696,
      0.03846687451004982,
      0.018284767866134644,
      -0.004729455802589655,
      0.00864795595407486,
      0.02302708849310875,
      -0.032802704721689224,
      -0.019333548843860626,
      -0.005734703969210386,
      -0.04639052227139473,
      0.061406854540109634,
      0.006672319490462542,
      -0.04188033938407898,
      -0.03514294698834419,
      0.04096928983926773,
      0.06889506429433823,
      0.002793734660372138,
      0.005571278277784586,
      -0.04334435239434242,
      -0.02528228610754013,
      -0.04023106023669243,
      0.040601328015327454,
      0.06607750803232193,
      0.06733619421720505,
      -0.09893081337213516,
      -0.03359237685799599,
      0.03160607069730759,
      0.033917710185050964,
      0.015952756628394127,
      0.008391288109123707,
      -0.008335775695741177,
      0.08144896477460861,
      -0.05470442399382591,
      0.02811521850526333,
      -0.04270230233669281,
      -0.05290750786662102,
      0.012686415575444698,
      0.018908346071839333,
      0.023330606520175934,
      -0.0010512975277379155,
      0.02207927778363228,
      0.09138378500938416,
      0.04236001893877983,
      0.006484730634838343,
      -0.05172707885503769,
      -0.00894395262002945,
      -0.0063855997286736965,
      -0.035306837409734726,
      -0.014503205195069313,
      0.03726443275809288,
      -0.013711021281778812,
      -0.0826234221458435,
      0.030132390558719635,
      0.05225826054811478,
      0.08160532265901566,
      0.009105502627789974,
      -0.06154937669634819,
      0.03783387690782547,
      0.028526747599244118,
      -0.033307310193777084,
      -0.025677131488919258,
      -0.06463436782360077,
      0.041408952325582504,
      0.017551276832818985,
      0.0676841214299202,
      -0.003452934557572007,
      -0.010186252184212208,
      0.014126107096672058,
      -0.05330023914575577,
      -0.03293537348508835,
      -0.006682965438812971,
      0.027272909879684448,
      0.022420229390263557,
      0.04268984869122505,
      0.015054233372211456,
      0.10566937178373337,
      0.04617709293961525,
      -0.01581937074661255,
      0.010797707363963127,
      -0.035646725445985794,
      0.024810124188661575,
      -0.041342537850141525,
      0.021690066903829575,
      -0.0826975628733635,
      -0.03811389580368996,
      0.017468266189098358,
      0.046393536031246185,
      0.010568734258413315,
      0.03087470680475235,
      -0.06095420569181442,
      -0.06150379404425621,
      0.007769956719130278,
      0.020904630422592163,
      -0.05336584523320198,
      0.06876029819250107,
      -0.05105239152908325,
      -0.05746946483850479,
      -0.030100608244538307,
      -0.06421096622943878,
      -0.004596386104822159,
      0.03862947225570679,
      -0.004459599032998085,
      -0.08357488363981247,
      0.017728526145219803,
      -0.09271817654371262,
      0.023275883868336678,
      -0.00830062385648489,
      0.06971823424100876,
      -0.05939849093556404,
      0.06398336589336395,
      0.030851541087031364,
      0.04998908191919327,
      0.02388712763786316,
      0.04304996877908707,
      0.06933378428220749,
      -0.011403821408748627,
      0.018296729773283005,
      -0.0602356381714344,
      -0.08114426583051682,
      -0.021172109991312027,
      0.06337547302246094,
      0.027487657964229584,
      -0.06478691101074219,
      0.0505998358130455
    ],
    [
      -0.049855686724185944,
      0.025565460324287415,
      0.0052998424507677555,
      0.05355244502425194,
      0.024581098929047585,
      0.012896300293505192,
      -0.0340682677924633,
      -0.016138965263962746,
      -0.01645137555897236,
      -0.06401470303535461,
      0.08732129633426666,
      -0.051249682903289795,
      0.04177633672952652,
      0.00919343251734972,
      0.015634020790457726,
      -0.08107177913188934,
      0.11018752306699753,
      -0.04788368567824364,
      -0.025021668523550034,
      -0.031521908938884735,
      0.04494398087263107,
      0.03845392167568207,
      -0.014270725660026073,
      -0.07397095859050751,
      0.03103341907262802,
      -0.050819654017686844,
      0.038407158106565475,
      -0.11167995631694794,
      0.05761845409870148,
      -0.009196712635457516,
      -0.00591943459585309,
      0.019123192876577377,
      0.00990410428494215,
      0.0044036805629730225,
      0.027570687234401703,
      -0.026092829182744026,
      0.019210372120141983,
      0.039131488651037216,
      -0.05459405481815338,
      0.020587923005223274,
      0.04011576622724533,
      0.04720788449048996,
      -0.03472672402858734,
      -0.032043278217315674,
      -0.09553570300340652,
      -0.002554318867623806,
      -0.018480103462934494,
      0.04809786379337311,
      -0.044270459562540054,
      -0.06621444970369339,
      0.008209536783397198,
      0.03354673460125923,
      -0.02608002908527851,
      0.007845026440918446,
      -0.012870612554252148,
      -0.030434226617217064,
      0.015053469687700272,
      0.016682054847478867,
      -0.05249415710568428,
      -0.008699453435838223,
      0.06611811369657516,
      0.0642990693449974,
      -0.060885511338710785,
      0.01455194316804409,
      0.035093434154987335,
      0.03598230332136154,
      0.03268207237124443,
      0.005880965851247311,
      0.04567277804017067,
      0.016522545367479324,
      -0.08358181267976761,
      0.061964355409145355,
      -0.0037471645046025515,
      0.001982580404728651,
      -0.0477897971868515,
      -0.02420147880911827,
      -0.06569822877645493,
      0.02096167393028736,
      -0.048107750713825226,
      -0.03866106644272804,
      -0.10620828717947006,
      -0.038361310958862305,
      0.07102611660957336,
      0.018202966079115868,
      -0.005510537885129452,
      0.03258736431598663,
      0.018934952095150948,
      0.0069649433717131615,
      -0.08693230152130127,
      0.058018870651721954,
      -0.036310791969299316,
      -0.013111710548400879,
      0.05129383131861687,
      -0.08615830540657043,
      -0.013711249455809593,
      0.031144902110099792,
      -0.0295852217823267,
      -0.04854185879230499,
      0.019484061747789383,
      -0.09179418534040451,
      -0.10778834670782089,
      -0.010647239163517952,
      -0.01249094121158123,
      -0.040789805352687836,
      -0.07415346056222916,
      -0.06423275172710419,
      0.008554896339774132,
      -0.0029368936084210873,
      -0.022849641740322113,
      -0.024884967133402824,
      0.06483162939548492,
      0.0635288655757904,
      -0.017811615020036697,
      -0.009322017431259155,
      -0.034430380910634995,
      0.07927126437425613,
      0.029621055349707603,
      0.03326848894357681,
      -0.06088152900338173,
      0.015183033421635628,
      -0.036502521485090256,
      0.042937107384204865,
      0.004728903993964195,
      0.034981172531843185,
      -0.01619078777730465,
      0.08063020557165146,
      0.05990792065858841,
      0.014216076582670212,
      0.041197262704372406,
      -0.005753288045525551,
      -0.02440205216407776,
      -0.011244229972362518,
      0.01127552054822445,
      -0.005746939219534397,
      0.0749501958489418,
      0.03275417909026146,
      0.1281081885099411,
      -0.0002938928082585335,
      0.03796813264489174,
      -0.023258643224835396,
      0.07909775525331497,
      0.031160762533545494,
      -0.0793527364730835,
      -0.029257621616125107,
      -0.07480600476264954,
      0.05175710469484329,
      0.07022348046302795,
      -0.025868982076644897,
      0.04545584321022034,
      0.10036394745111465,
      0.017271069809794426,
      -0.07981666177511215,
      -0.03118465468287468,
      0.03908315300941467,
      0.03111918270587921,
      -0.02526351809501648,
      0.025095470249652863,
      -0.02398647740483284,
      0.0028473809361457825,
      -0.002390812383964658,
      -0.003280328121036291,
      0.04520102217793465,
      -0.017928360030055046,
      -0.019820060580968857,
      -0.00490624038502574,
      0.06806868314743042,
      -0.04015013948082924,
      -0.03300471231341362,
      0.11919232457876205,
      0.018405886366963387,
      -0.027377987280488014,
      -0.05301424488425255,
      0.0011982398573309183,
      0.047842081636190414,
      -0.11855129152536392,
      0.009711177088320255,
      0.02163011021912098,
      0.07843397557735443,
      0.09797896444797516,
      -0.09097018092870712,
      -0.007993913255631924,
      0.019216444343328476,
      0.040429309010505676,
      0.020136279985308647,
      -0.0005562810692936182,
      0.03100448288023472,
      0.054445017129182816,
      -0.006337127648293972,
      -0.012597697786986828,
      0.0004369837115518749,
      0.0417928509414196,
      0.007696015760302544,
      0.04190589115023613,
      -0.04460388422012329,
      -0.022298414260149002,
      0.03973139822483063,
      0.00976184755563736,
      -0.029218828305602074,
      -0.02964201383292675,
      0.04723208025097847,
      -0.041455432772636414,
      -0.020055575296282768,
      -0.14237529039382935,
      0.002871778327971697,
      -0.015858683735132217,
      0.025048084557056427,
      0.020818209275603294,
      0.02305927500128746,
      0.07895726710557938,
      0.024048209190368652,
      -0.05854762718081474,
      0.013778313994407654,
      -0.06624295562505722,
      0.016343794763088226,
      0.03837982192635536,
      0.011317798867821693,
      0.012024128809571266,
      0.006612248718738556,
      -0.01607266068458557,
      0.06962798535823822,
      -0.03445826843380928,
      0.048544224351644516,
      0.019826017320156097,
      -0.0790277048945427,
      -0.04306873679161072,
      0.07683902233839035,
      -0.0709964781999588,
      -0.08699778467416763,
      0.0028157737106084824,
      0.08220548182725906,
      -0.046160999685525894,
      -0.04931063950061798,
      0.0043464964255690575,
      -0.06361091881990433,
      -0.006039843428879976,
      0.01983216591179371,
      0.009397461079061031,
      0.06575926393270493,
      -0.05322036147117615,
      -0.01715143956243992,
      -0.02415359765291214,
      3.538155942806043e-05,
      -0.02195044234395027,
      0.025095701217651367,
      -0.006997488904744387,
      -0.016642019152641296,
      0.07280267030000687,
      -0.04932061582803726,
      -0.023409917950630188,
      -0.014043650589883327,
      -0.02813904918730259,
      0.06373578310012817,
      -0.0012074063997715712,
      0.052900005131959915,
      0.002411431632936001,
      0.013789104297757149,
      0.0019214991480112076,
      0.04250438138842583,
      0.021026285365223885,
      0.016504472121596336,
      -0.07064078003168106,
      -0.00788511335849762,
      -0.0789916068315506,
      0.06940030306577682,
      0.049731746315956116,
      0.02394150197505951,
      0.06765636801719666,
      -0.027979224920272827,
      0.005749598145484924,
      0.03679298609495163,
      -0.08714661747217178,
      -0.05511735752224922,
      -0.05436434969305992,
      -0.0238520335406065,
      0.04968675598502159,
      -0.029280923306941986,
      0.034217189997434616,
      0.0036166836507618427,
      -0.05994201451539993,
      0.05609910562634468,
      -0.05027356371283531,
      0.01922370307147503,
      -0.03738107159733772,
      -0.018661027774214745,
      -0.00297006918117404,
      0.03765055909752846,
      0.010807875543832779,
      0.054950810968875885,
      0.033714309334754944,
      0.08183427155017853,
      -0.03306574374437332,
      0.019706090912222862,
      -0.037096187472343445,
      0.008170797489583492,
      -0.0014121971325948834,
      -0.02835293672978878,
      -0.03799602761864662,
      0.022274179384112358,
      -0.011597767472267151,
      -0.03065444342792034,
      -0.0018656104803085327,
      0.07141345739364624,
      -0.0020775198936462402,
      -0.0023979905527085066,
      -0.0011496871011331677,
      -0.05081183463335037,
      -0.09188253432512283,
      0.022774508222937584,
      0.0527866892516613,
      0.02145446464419365,
      0.02219947800040245,
      -0.01598559133708477,
      -0.015755876898765564,
      -0.009019534103572369,
      0.09156997501850128,
      -0.05201173573732376,
      -0.06544126570224762,
      0.014531326480209827,
      0.011272030882537365,
      0.004177698399871588,
      -0.04085615277290344,
      -0.02993379719555378,
      -0.01183156855404377,
      0.030606281012296677,
      0.002707315143197775,
      0.07129579037427902,
      0.04211888834834099,
      -0.008392701856791973,
      0.006038218270987272,
      -0.011177346110343933,
      0.06509871780872345,
      -0.053290437906980515,
      -0.012884749099612236,
      -0.025523068383336067,
      -0.09645006060600281,
      0.03917573764920235,
      0.09482000768184662,
      0.047399114817380905,
      -0.10814066976308823,
      0.014336819760501385,
      0.011407140642404556,
      -0.05914471298456192,
      0.08383249491453171,
      0.017544405534863472,
      0.025868551805615425,
      -0.02996029704809189,
      -0.04270472750067711,
      -0.059790462255477905,
      -0.03959780931472778,
      -0.03513621911406517,
      0.03856680914759636,
      -0.015027986839413643,
      0.001361655886285007,
      0.020878029987215996,
      0.04869699850678444,
      -0.015643518418073654,
      -0.03252282366156578,
      0.020247971639037132,
      0.04254069924354553,
      0.046209581196308136,
      0.04608678072690964,
      -0.06175897642970085,
      -0.029616890475153923,
      0.020491866394877434,
      0.01880592480301857,
      -0.03201138600707054,
      0.03986053168773651,
      0.0576450452208519,
      0.05035260692238808,
      0.01186535693705082,
      0.024437954649329185,
      -0.008271158672869205,
      0.023086663335561752,
      -0.0036775192711502314,
      0.024206383153796196,
      -0.002657798118889332,
      0.015294834971427917,
      0.020165590569376945,
      -0.024310624226927757,
      -0.09237543493509293,
      -0.05855601280927658,
      0.028641605749726295,
      -0.07404528558254242,
      -0.0035160731058567762,
      -0.005964398384094238,
      -0.009867669083178043,
      -0.015912719070911407,
      0.015219754539430141,
      0.007601561490446329,
      0.017093978822231293,
      0.049169111996889114,
      0.07558684051036835,
      0.0858936458826065,
      -0.040730517357587814,
      -0.01193903386592865,
      -0.056050751358270645,
      -0.027151454240083694,
      -0.0007228992180898786,
      -0.059240903705358505,
      -0.03167284280061722,
      -0.031176544725894928,
      0.015572267584502697,
      0.029775913804769516,
      -0.021796604618430138,
      0.025727836415171623,
      0.0510048046708107,
      -0.07988336682319641,
      0.02719470113515854,
      0.09155569970607758,
      0.059830691665410995,
      -0.024991631507873535,
      -0.07507548481225967,
      0.010632729157805443,
      -0.014925071969628334,
      -0.03380132466554642,
      0.014815029688179493,
      0.07046201825141907,
      -0.019600357860326767,
      -0.005105485673993826,
      -0.012400345876812935,
      0.09058026969432831,
      0.04128790274262428,
      -0.003946545999497175,
      0.021708527579903603,
      0.040608275681734085,
      -0.11848358064889908,
      0.05112123116850853,
      -0.07706460356712341,
      -0.026761947199702263,
      -0.005660727620124817,
      0.003857274306938052,
      0.061330169439315796,
      0.002544773044064641,
      0.005724078509956598,
      0.029381388798356056,
      0.054897241294384,
      0.005751937627792358,
      0.06387124210596085,
      0.05506771802902222,
      0.01822062022984028,
      0.04142532870173454,
      0.00515368627384305,
      -0.02836339734494686,
      -0.010002289898693562,
      -0.012352204881608486,
      -0.03314186632633209,
      0.010850637219846249,
      0.040761496871709824,
      0.035730328410863876,
      -0.00675699720159173,
      -0.01742568053305149,
      0.005434423219412565,
      0.03474591672420502,
      -0.06138860434293747,
      -0.0054998877458274364,
      0.07675454020500183,
      -0.05770495906472206,
      -0.07496422529220581,
      -0.023921390995383263,
      0.0060545275919139385,
      0.026793474331498146,
      0.04076509550213814,
      -0.09364274144172668,
      -0.04759843274950981,
      0.07699591666460037,
      -0.022295687347650528,
      -0.08152180910110474,
      -0.00635308213531971,
      -0.02486848458647728,
      0.049175865948200226,
      -0.03517158329486847,
      0.005989789962768555,
      -0.06521665304899216,
      -0.057451214641332626,
      -0.10911839455366135,
      -0.0647866427898407,
      0.07143654674291611,
      -0.0122284647077322,
      -0.12110696732997894,
      -0.01492801308631897,
      -0.05105775222182274,
      0.062013138085603714,
      0.006472619250416756,
      0.01975306309759617,
      -0.10218560695648193,
      0.008601280860602856,
      0.08629006892442703,
      -0.0374564565718174,
      0.08108066767454147,
      0.07578269392251968,
      -0.0005656888824887574,
      0.06386203318834305,
      -0.05350249260663986,
      0.03046845644712448,
      0.03101358562707901,
      -0.045843351632356644,
      0.05096544697880745,
      -0.026325389742851257,
      -0.010580901987850666,
      0.01540827564895153,
      -0.049900736659765244,
      -0.029755786061286926,
      0.048368196934461594
    ],
    [
      0.0502534843981266,
      -0.07030968368053436,
      -0.07334142178297043,
      0.018549321219325066,
      -0.03923821076750755,
      0.042525045573711395,
      0.060115691274404526,
      0.11725026369094849,
      -0.14997242391109467,
      -0.006068884395062923,
      0.08534450829029083,
      0.04351462423801422,
      7.395417924271896e-05,
      0.042096834629774094,
      -0.021801507100462914,
      0.023272234946489334,
      0.029428070411086082,
      -0.012737068347632885,
      -0.030050840228796005,
      0.06710586696863174,
      0.034952737390995026,
      0.050931163132190704,
      0.047130875289440155,
      -0.00038043115637265146,
      0.0828549787402153,
      -0.03715977072715759,
      0.015375814400613308,
      0.020396586507558823,
      -0.0702863410115242,
      0.012721355073153973,
      0.07085736095905304,
      -0.03590421378612518,
      0.027362799271941185,
      -0.02602524124085903,
      -0.013179064728319645,
      0.11554940789937973,
      -0.03180171921849251,
      0.03005341999232769,
      -0.018044158816337585,
      0.09882219135761261,
      0.004494690801948309,
      0.004767715930938721,
      0.0823572427034378,
      -0.0036207796074450016,
      0.01792040839791298,
      0.057091258466243744,
      -0.05052429810166359,
      0.08481311053037643,
      0.007679961621761322,
      -0.10169015824794769,
      -0.02487177401781082,
      -0.07443724572658539,
      -0.009400498121976852,
      0.004704307299107313,
      -0.022519981488585472,
      -0.0835055410861969,
      0.054956696927547455,
      0.06279437243938446,
      0.010151585564017296,
      -0.01658882200717926,
      -0.046916332095861435,
      0.010232830420136452,
      0.032504841685295105,
      0.06685616075992584,
      0.007399555295705795,
      -0.008782255463302135,
      -0.06013766676187515,
      0.0006260107620619237,
      0.026882542297244072,
      -0.07143709808588028,
      -0.01083638146519661,
      0.010110972449183464,
      0.03960172086954117,
      -0.042358703911304474,
      -0.06491242349147797,
      -0.05165540799498558,
      -0.05026158690452576,
      0.0167024414986372,
      -0.0118394885212183,
      0.030823061242699623,
      0.04157455265522003,
      -0.014856616035103798,
      0.02436188980937004,
      -0.10123130679130554,
      -0.0070116519927978516,
      -0.003478405997157097,
      0.013950088992714882,
      -0.007753061596304178,
      -0.01941819302737713,
      0.0031525276135653257,
      -0.005718260072171688,
      -0.04151086136698723,
      0.01986553519964218,
      -0.023113904520869255,
      -0.00696149468421936,
      0.022344494238495827,
      0.008914077654480934,
      -0.057756207883358,
      -0.04904766008257866,
      0.04122409597039223,
      -0.040338754653930664,
      -0.07199431955814362,
      -0.012721392326056957,
      0.03428180515766144,
      0.0032558224629610777,
      -0.03286561742424965,
      -0.03520870581269264,
      0.042865071445703506,
      0.027873631566762924,
      -0.008632803335785866,
      0.08776362240314484,
      0.0057016643695533276,
      -0.06691145151853561,
      -0.04065098240971565,
      -0.03504354506731033,
      0.01386614702641964,
      0.023868732154369354,
      -0.012767642736434937,
      0.03032347373664379,
      -0.00969665590673685,
      -0.051013048738241196,
      0.026763033121824265,
      0.015690680593252182,
      0.031501635909080505,
      0.026299459859728813,
      0.012129569426178932,
      0.03288792073726654,
      -0.12817886471748352,
      -0.04121679812669754,
      0.040901944041252136,
      -0.027970612049102783,
      -0.055835191160440445,
      0.01641157828271389,
      -0.06453975290060043,
      -0.08575672656297684,
      -0.07040488719940186,
      -0.04068295285105705,
      0.0098101906478405,
      -0.05606124922633171,
      -0.006260767113417387,
      0.017565520480275154,
      -0.06690677255392075,
      0.07078557461500168,
      0.002311652060598135,
      0.031452517956495285,
      0.009176436811685562,
      -0.034152716398239136,
      0.06597988307476044,
      -0.05521254613995552,
      0.036864232271909714,
      -0.0009602783829905093,
      0.004127634223550558,
      0.03006424382328987,
      0.03835034370422363,
      -0.04657374694943428,
      0.02410109154880047,
      0.03365727886557579,
      0.00878522265702486,
      0.007613531779497862,
      -0.03759413957595825,
      0.04887135699391365,
      -0.021102312952280045,
      0.04272310435771942,
      -0.06189035251736641,
      0.007950998842716217,
      0.002666488057002425,
      0.05337102711200714,
      -0.01583748124539852,
      0.0033434524666517973,
      -0.07995627820491791,
      -0.039934005588293076,
      0.0302492156624794,
      0.04805469885468483,
      0.022991430014371872,
      -0.05988926440477371,
      0.03957009315490723,
      -0.0012098688166588545,
      -0.014143659733235836,
      0.0628981813788414,
      0.029582055285573006,
      0.03479791432619095,
      -0.07469014823436737,
      0.041218940168619156,
      0.00968914944678545,
      -0.012687264010310173,
      0.06838688254356384,
      -0.08099055290222168,
      0.039181631058454514,
      -0.054709576070308685,
      -0.005987710785120726,
      -0.00979275070130825,
      -0.07088779658079147,
      -0.04562535136938095,
      -0.016223784536123276,
      -0.01927248202264309,
      0.07146117091178894,
      0.04147723317146301,
      -0.016713477671146393,
      0.04405192658305168,
      0.11040889471769333,
      -0.014171745628118515,
      0.004785807337611914,
      0.04644405469298363,
      -0.0082295136526227,
      0.05846865847706795,
      -0.026536349207162857,
      0.047208521515131,
      -0.05775636434555054,
      -0.015205449424684048,
      0.010286803357303143,
      -0.028090866282582283,
      -0.02339981310069561,
      -0.007970687933266163,
      -0.0037216050550341606,
      0.06126165762543678,
      0.01784868724644184,
      -0.01785907708108425,
      -0.016353018581867218,
      0.04428090527653694,
      0.043528296053409576,
      -0.08348797261714935,
      -0.03314540907740593,
      -0.03393172100186348,
      0.012100799940526485,
      0.009748278185725212,
      -0.04322890564799309,
      0.03472520038485527,
      -0.020277919247746468,
      -0.06096792221069336,
      0.039864495396614075,
      -0.040946874767541885,
      -0.022791609168052673,
      0.04731804132461548,
      0.04413732513785362,
      0.018510647118091583,
      0.030358755961060524,
      0.06862489134073257,
      -0.008142702281475067,
      0.04022766649723053,
      -0.03661695495247841,
      0.04291130602359772,
      -0.038285668939352036,
      -0.015885567292571068,
      -0.010979101061820984,
      0.024485770612955093,
      -0.053156498819589615,
      0.0055521088652312756,
      -0.025545867159962654,
      0.012233029119670391,
      -0.055644530802965164,
      0.015404101461172104,
      -0.022252440452575684,
      -0.045058440417051315,
      0.011482520028948784,
      0.029394803568720818,
      0.016419995576143265,
      -0.04534778743982315,
      -0.013412702828645706,
      0.03137699142098427,
      -0.028730805963277817,
      0.07086321711540222,
      -0.06965269893407822,
      0.04287131503224373,
      -0.0008093073265627027,
      -0.04214179888367653,
      -0.014743883162736893,
      -0.0793161690235138,
      -0.021937306970357895,
      0.028791232034564018,
      0.042294975370168686,
      -0.10070791840553284,
      0.0437127985060215,
      0.005656026303768158,
      0.022727426141500473,
      0.030086755752563477,
      -0.019368670880794525,
      -0.029521401971578598,
      -0.03283984586596489,
      -0.09378194063901901,
      0.007466202601790428,
      -0.061166588217020035,
      -0.08538589626550674,
      0.008337237872183323,
      0.0076285372488200665,
      -0.004384439904242754,
      0.06579447537660599,
      0.0007772670360282063,
      -0.04213966429233551,
      0.019513310864567757,
      0.01637049950659275,
      -0.05798519775271416,
      -0.058489084243774414,
      0.0111240204423666,
      -0.004769338294863701,
      -0.052391037344932556,
      -0.11308840662240982,
      -0.024399107322096825,
      -0.0888618752360344,
      0.03459000587463379,
      0.026278387755155563,
      0.06728921830654144,
      0.06643708050251007,
      -0.029502583667635918,
      -0.008113154210150242,
      -0.03793659433722496,
      0.08857880532741547,
      0.07845564186573029,
      0.04215685650706291,
      0.027779024094343185,
      -0.024089183658361435,
      0.06179937347769737,
      0.01760934852063656,
      0.013651172630488873,
      0.00531009491533041,
      0.03426974266767502,
      -0.03128378093242645,
      0.008085304871201515,
      -0.05780768766999245,
      0.13875456154346466,
      -0.08465072512626648,
      -0.08328662067651749,
      -0.005519113503396511,
      -0.018030690029263496,
      0.005194853059947491,
      -0.039961520582437515,
      0.02433488890528679,
      0.041096799075603485,
      -0.012517841532826424,
      0.022576162591576576,
      -0.001715484308078885,
      0.03593117371201515,
      -0.07599454373121262,
      -0.00317873852327466,
      -0.10163430869579315,
      -0.006022781133651733,
      -0.022639796137809753,
      -0.019447045400738716,
      0.009593193419277668,
      0.002282538451254368,
      0.03360550105571747,
      -0.05957585200667381,
      -0.000500973139423877,
      0.034258730709552765,
      -0.015601817518472672,
      -0.02039780281484127,
      0.047313809394836426,
      -0.06175842136144638,
      0.013739033602178097,
      -0.017118016257882118,
      -0.0476294606924057,
      0.056410305202007294,
      0.011450284160673618,
      -0.039206888526678085,
      -0.0031896259170025587,
      -0.04046471044421196,
      0.047531042248010635,
      -0.03221070393919945,
      0.03257570415735245,
      -0.028333941474556923,
      0.06881778687238693,
      0.06693128496408463,
      -0.020896682515740395,
      0.02218283899128437,
      0.01739920862019062,
      0.003949551843106747,
      -0.043219052255153656,
      0.035929564386606216,
      0.002133587608113885,
      0.023344218730926514,
      0.05070861056447029,
      0.10776979476213455,
      0.05148601159453392,
      0.05772208422422409,
      -0.02649945765733719,
      0.061978720128536224,
      -0.09283631294965744,
      -0.0017272785771638155,
      -0.05006086453795433,
      0.015250446274876595,
      0.00196013692766428,
      -0.07604631781578064,
      0.01783328503370285,
      0.0379723496735096,
      0.07434926182031631,
      -0.01610918715596199,
      0.013037459924817085,
      -0.050401121377944946,
      0.020687740296125412,
      0.044540829956531525,
      -0.007786932867020369,
      0.09587999433279037,
      0.0002666926884558052,
      0.00863668229430914,
      -0.06995370984077454,
      -0.06639863550662994,
      0.03126168251037598,
      -0.038794469088315964,
      0.05953795462846756,
      -0.05928900092840195,
      -0.010952872224152088,
      -0.011569174937903881,
      -0.011479219421744347,
      -0.03755174204707146,
      -0.013443946838378906,
      -0.024607861414551735,
      0.003759557381272316,
      0.009100335650146008,
      -0.012176573276519775,
      0.010779068805277348,
      0.0293747428804636,
      -0.044625964015722275,
      -0.004102614708244801,
      -0.023407110944390297,
      0.011274500750005245,
      0.036582741886377335,
      0.021840499714016914,
      0.046187497675418854,
      0.10282420367002487,
      -0.021578209474682808,
      -0.049066655337810516,
      -0.02765386737883091,
      0.043815139681100845,
      0.01917888969182968,
      0.018014991655945778,
      0.003954910207539797,
      0.002973521826788783,
      0.04369349405169487,
      0.11686025559902191,
      0.049817539751529694,
      -0.05103130638599396,
      -0.07198642939329147,
      0.005123776849359274,
      -0.0701276957988739,
      0.024399109184741974,
      0.026760617271065712,
      0.005222538951784372,
      0.007992135360836983,
      -0.013096588663756847,
      -0.0418689027428627,
      -0.00493414094671607,
      -0.01913677528500557,
      0.0069572050124406815,
      0.032497573643922806,
      -0.02373526617884636,
      0.009582309052348137,
      -0.026177367195487022,
      -0.05772550404071808,
      -0.07027530670166016,
      0.0395352765917778,
      0.009370037354528904,
      -0.0719628781080246,
      -0.03494737297296524,
      0.07521279156208038,
      0.06950367242097855,
      -0.0361873023211956,
      0.011354368180036545,
      0.01586652174592018,
      0.009946011006832123,
      -0.003890484804287553,
      -0.09927840530872345,
      0.01858796551823616,
      -0.03589818254113197,
      -0.03926106169819832,
      -0.07335550338029861,
      -0.01980552263557911,
      -0.038413528352975845,
      0.014165728352963924,
      0.004896712023764849,
      0.01883130520582199,
      -0.014661653898656368,
      5.0119062507292256e-05,
      -0.09889425337314606,
      -0.02550608105957508,
      -0.08518916368484497,
      0.01226248312741518,
      0.009472401812672615,
      0.004498614929616451,
      -0.0791511982679367,
      0.038144972175359726,
      -0.03963799029588699,
      -0.08565104752779007,
      -0.025306086987257004,
      0.045131951570510864,
      0.04018569365143776,
      -0.10261532664299011,
      0.10490117967128754,
      0.023990023881196976,
      -0.030086377635598183,
      -0.03945762291550636,
      0.0020017579663544893,
      -0.007132130209356546,
      0.02365117147564888,
      0.01125203538686037,
      -0.04736193269491196,
      -0.017382435500621796,
      -0.0656619444489479,
      -0.03459421172738075,
      -0.025955267250537872,
      -0.006124365143477917,
      -0.014197073876857758,
      -0.045839253813028336,
      -0.10577837377786636,
      0.02107844687998295
    ],
    [
      0.041232429444789886,
      0.0791856124997139,
      -0.04581400379538536,
      -0.019471008330583572,
      -0.048997119069099426,
      0.03825778514146805,
      -0.0007094595348462462,
      0.02996881492435932,
      -0.013509873300790787,
      0.029994290322065353,
      0.03358286991715431,
      -0.021193362772464752,
      -0.004195458721369505,
      -0.032779112458229065,
      -0.014487617649137974,
      0.042520374059677124,
      0.04400649666786194,
      0.05387872830033302,
      0.06640440970659256,
      -0.001873756991699338,
      -0.015747342258691788,
      -0.08780981600284576,
      0.054617371410131454,
      -0.04024890810251236,
      -0.006125232204794884,
      -0.03995333984494209,
      0.01907454803586006,
      0.061196327209472656,
      -0.07103801518678665,
      0.019462749361991882,
      -0.059879910200834274,
      -0.02591267041862011,
      0.026656387373805046,
      0.011063787154853344,
      -0.004941480699926615,
      0.02660502679646015,
      -0.01529801543802023,
      -0.020495381206274033,
      0.044305406510829926,
      -0.031848687678575516,
      0.020730530843138695,
      -0.022713270038366318,
      -0.014429222792387009,
      0.014103231951594353,
      0.04566001519560814,
      0.003322203876450658,
      0.016785839572548866,
      0.048424605280160904,
      -0.010271423496305943,
      -0.09489578008651733,
      -0.039916813373565674,
      0.0342642106115818,
      0.04759531468153,
      0.06881161034107208,
      0.011785086244344711,
      -0.08573924005031586,
      -0.01767544262111187,
      0.06449821591377258,
      -0.04094090685248375,
      -0.02302776277065277,
      -0.05120095983147621,
      0.04286174848675728,
      -0.006891854107379913,
      0.005615828558802605,
      0.005425740033388138,
      -0.009569632820785046,
      0.005617881193757057,
      -0.04893528297543526,
      0.0631621927022934,
      -0.043786656111478806,
      -0.009062901139259338,
      0.023221544921398163,
      -0.027060428634285927,
      -0.01453304197639227,
      -0.05396343022584915,
      -0.030283555388450623,
      0.006918749772012234,
      0.055259913206100464,
      -0.020348219200968742,
      0.05569501593708992,
      -0.020687628537416458,
      -0.04047416150569916,
      0.08855268359184265,
      0.026713509112596512,
      0.02449006587266922,
      0.06466647982597351,
      -0.021368272602558136,
      -0.013326981104910374,
      -0.06578680127859116,
      0.014070181176066399,
      0.09899713844060898,
      0.02453850954771042,
      0.02549874223768711,
      0.04423642158508301,
      0.017571454867720604,
      0.050133172422647476,
      -0.013744521886110306,
      0.042760226875543594,
      0.029736250638961792,
      0.02340914122760296,
      -0.07303893566131592,
      0.0482296347618103,
      0.0718299150466919,
      0.05657138302922249,
      -0.0077508785761892796,
      0.020374175161123276,
      0.02528851479291916,
      0.04425342381000519,
      0.03102770820260048,
      0.06273239105939865,
      0.05743365362286568,
      -0.026585783809423447,
      -0.053103938698768616,
      -0.016262294724583626,
      -0.013978132046759129,
      -0.03146791458129883,
      0.06213225796818733,
      0.03042101301252842,
      0.027028502896428108,
      -0.08036962896585464,
      0.0638093501329422,
      -0.02031632885336876,
      0.01414225623011589,
      0.03551327809691429,
      0.0010082811350002885,
      -0.02311793528497219,
      0.045841317623853683,
      -0.014792182482779026,
      -0.058679111301898956,
      0.040621303021907806,
      0.08939645439386368,
      -0.04684152081608772,
      -0.01533800270408392,
      -0.02491392381489277,
      0.028380868956446648,
      0.024667013436555862,
      0.024826200678944588,
      0.05833975970745087,
      -0.10164482891559601,
      -0.0012521479511633515,
      0.056127652525901794,
      -0.033631063997745514,
      -0.048631247133016586,
      -0.001135298516601324,
      0.05812980234622955,
      0.011763633228838444,
      0.05085311084985733,
      -0.015476740896701813,
      -0.09689375758171082,
      0.029113616794347763,
      0.03945145010948181,
      -0.0623895637691021,
      -0.01033368892967701,
      0.02951345592737198,
      -0.03537141904234886,
      0.015395360998809338,
      0.08631954342126846,
      0.01905270852148533,
      0.016379594802856445,
      0.05694521591067314,
      -0.018702175468206406,
      -0.06878022849559784,
      -0.017730847001075745,
      0.025159422308206558,
      0.00943338219076395,
      -0.06469693779945374,
      0.0011774558806791902,
      -0.01657390408217907,
      -0.025568179786205292,
      -0.05738864839076996,
      0.0700816661119461,
      0.0019126262050122023,
      0.04387080296874046,
      0.0735660120844841,
      -0.002465244848281145,
      0.016934987157583237,
      -0.028569336980581284,
      0.03859017416834831,
      0.07392419874668121,
      0.03553980588912964,
      -0.0059433383867144585,
      -0.039612505584955215,
      -0.04603785648941994,
      -0.005340239033102989,
      -0.08722741901874542,
      -0.02152351476252079,
      -0.015949923545122147,
      0.007498501800000668,
      -0.01908264309167862,
      -0.013836405239999294,
      0.04813357815146446,
      0.0415709912776947,
      -0.020694702863693237,
      -0.053861502557992935,
      -0.03444726765155792,
      -0.009142023511230946,
      -0.020510081201791763,
      0.012165402993559837,
      0.06080860272049904,
      0.016242332756519318,
      -0.05419076606631279,
      -0.06606311351060867,
      -0.03623483330011368,
      -0.02203151397407055,
      0.036054205149412155,
      -0.05151235684752464,
      0.0553993321955204,
      0.00516858184710145,
      -0.0370313823223114,
      -0.006653979420661926,
      0.008444292470812798,
      0.007479413878172636,
      -0.033814627677202225,
      0.05091479793190956,
      0.040535423904657364,
      0.019710736349225044,
      0.010095115751028061,
      0.058382030576467514,
      0.07904382050037384,
      -0.0024127596989274025,
      -0.004145719576627016,
      0.03151445463299751,
      -0.06164248660206795,
      0.019072500988841057,
      0.03317909687757492,
      -0.018522925674915314,
      -0.006645109038800001,
      0.048800189048051834,
      -0.01695220172405243,
      0.05299275368452072,
      0.002239017281681299,
      -0.03782697394490242,
      0.04639972746372223,
      -0.03305581957101822,
      0.011969518847763538,
      0.0844360888004303,
      -0.07095112651586533,
      -0.033100422471761703,
      -0.031405434012413025,
      -0.0022407693322747946,
      -0.03227045759558678,
      -0.07220833748579025,
      -0.032050274312496185,
      0.02975710853934288,
      0.05593585595488548,
      -0.036462295800447464,
      -0.014939578250050545,
      -0.026119211688637733,
      -0.058926910161972046,
      -0.038350969552993774,
      0.08148601651191711,
      0.03734209015965462,
      -0.014041163958609104,
      0.011807631701231003,
      0.06375112384557724,
      -0.031668756157159805,
      -0.028740447014570236,
      -0.01165830623358488,
      0.034440480172634125,
      -0.04140302538871765,
      -0.016662348061800003,
      0.049478743225336075,
      -0.04628061503171921,
      -0.0827537328004837,
      -0.028200890868902206,
      0.013027774170041084,
      -4.260247078491375e-05,
      -0.039955370128154755,
      -0.006197560578584671,
      0.016412140801548958,
      -0.034787196666002274,
      -0.038440458476543427,
      0.054845985025167465,
      0.03011324070394039,
      -0.06761850416660309,
      0.029008526355028152,
      -0.05099792778491974,
      -0.02527475170791149,
      -0.027663348242640495,
      -0.02822808362543583,
      0.09780722856521606,
      -0.008977261371910572,
      -0.003177282400429249,
      0.0031718129757791758,
      0.06498725712299347,
      0.016896938905119896,
      -0.03259236738085747,
      -0.05196904018521309,
      -0.012267076410353184,
      0.01673908345401287,
      -0.04232364147901535,
      0.046113207936286926,
      -0.020683780312538147,
      0.0012502585304901004,
      -0.06009501963853836,
      -0.010587284341454506,
      0.019757522270083427,
      0.045694075524806976,
      -0.03382278233766556,
      0.03843162953853607,
      -0.0024955100379884243,
      -0.047587182372808456,
      0.031745463609695435,
      0.14252831041812897,
      0.10814088582992554,
      -0.03910838067531586,
      0.004933961201459169,
      -0.07991626858711243,
      0.037216365337371826,
      9.127737575909123e-05,
      0.005898930598050356,
      -0.07012446969747543,
      0.04975130036473274,
      -0.017493275925517082,
      -0.018015438690781593,
      -0.038117144256830215,
      -0.0813964307308197,
      -0.0605088472366333,
      0.024332622066140175,
      0.029441727325320244,
      0.025525035336613655,
      0.004204072989523411,
      0.054163768887519836,
      -0.006887875497341156,
      0.044009022414684296,
      -0.012238601222634315,
      -0.0787467211484909,
      -0.08467163145542145,
      -0.027080416679382324,
      0.0639425665140152,
      -0.004195513669401407,
      -0.03485093638300896,
      0.1268632411956787,
      -0.045358240604400635,
      0.030135925859212875,
      -0.017304981127381325,
      0.004589513875544071,
      -0.03413617983460426,
      0.013294810429215431,
      0.08735726773738861,
      -0.06940072029829025,
      -0.09174566715955734,
      0.018868401646614075,
      -0.02183084562420845,
      -0.02500389702618122,
      0.03292127326130867,
      -0.013803130947053432,
      0.05985790118575096,
      -0.045105040073394775,
      0.05381232872605324,
      -0.020205505192279816,
      0.07757595926523209,
      0.016405032947659492,
      -0.0808316022157669,
      0.07442523539066315,
      -0.006814571563154459,
      0.055829647928476334,
      -0.024658232927322388,
      -0.044634848833084106,
      0.058173179626464844,
      0.05602516606450081,
      -0.0787988007068634,
      0.03718241676688194,
      -0.02112741582095623,
      -0.043047863990068436,
      0.029346702620387077,
      -0.07618775218725204,
      0.032802876085042953,
      -0.043062932789325714,
      -0.004850285593420267,
      -0.015593591146171093,
      -0.019304467365145683,
      0.07040734589099884,
      -0.03241988644003868,
      0.020575400441884995,
      0.019819630309939384,
      -0.026811575517058372,
      -0.007569651585072279,
      0.009736563079059124,
      -0.01169463898986578,
      0.03587851673364639,
      0.012230435386300087,
      -0.04556713625788689,
      0.016860563308000565,
      -0.013299728743731976,
      -0.035457104444503784,
      0.10280518978834152,
      0.040213692933321,
      0.008758767507970333,
      0.004323740489780903,
      0.09291236102581024,
      -0.08542986959218979,
      -0.0053621199913322926,
      -0.0156166460365057,
      -0.021799517795443535,
      0.024402253329753876,
      -0.11550983041524887,
      -0.027526214718818665,
      0.05101310461759567,
      -0.033802807331085205,
      -0.04637443274259567,
      0.06825461983680725,
      0.037334468215703964,
      -0.0023406785912811756,
      -0.03267504647374153,
      -0.023701390251517296,
      -0.01646355725824833,
      0.005699922796338797,
      -0.001551728812046349,
      0.07693632692098618,
      -0.05897221714258194,
      -0.06402219831943512,
      -0.02477211505174637,
      0.02195461094379425,
      0.024294348433613777,
      -0.015037926845252514,
      -0.014163680374622345,
      0.050416771322488785,
      0.036940209567546844,
      0.07051549106836319,
      -0.0030087123159319162,
      0.0602535717189312,
      -0.005974774714559317,
      0.029434403404593468,
      -0.055698372423648834,
      0.013839129358530045,
      0.03843672573566437,
      0.010791776701807976,
      -0.025993073359131813,
      0.005394198000431061,
      0.0026227517519146204,
      0.0704304426908493,
      -0.0482742078602314,
      0.08800874650478363,
      -0.04149225726723671,
      0.002131811575964093,
      -0.01890081912279129,
      0.06236197426915169,
      0.00235055573284626,
      0.05205117538571358,
      -0.00769037427380681,
      0.04660680517554283,
      -0.018545089289546013,
      -0.006304129492491484,
      0.030779682099819183,
      0.06483578681945801,
      -0.06788976490497589,
      0.03175146505236626,
      0.023183545097708702,
      -0.0008758283802308142,
      0.06236349046230316,
      -0.056384019553661346,
      -0.011792192235589027,
      -0.014360357075929642,
      -0.0006392832729034126,
      0.049752794206142426,
      -0.012714733369648457,
      0.0034621418453752995,
      -0.050821851938962936,
      -0.07870662957429886,
      -0.044035956263542175,
      -0.05233399197459221,
      -0.006389115937054157,
      -0.008496373891830444,
      -0.005222107283771038,
      -0.021925395354628563,
      0.012516812421381474,
      0.0159141942858696,
      -0.0625639483332634,
      -0.03402113541960716,
      0.06737001985311508,
      0.0663844645023346,
      -0.09240370988845825,
      -0.09299203753471375,
      0.020080575719475746,
      -0.007148633245378733,
      0.0031748104374855757,
      0.059408336877822876,
      -0.0211594607681036,
      0.033350326120853424,
      0.0035861164797097445,
      -0.04833833500742912,
      0.00020045312703587115,
      0.08449326455593109,
      -0.02354343980550766,
      0.035319358110427856,
      0.004743856843560934,
      0.0782453641295433,
      -0.009993107989430428,
      -0.05678381398320198,
      -0.0027285658288747072,
      0.004411495290696621,
      0.03143497183918953,
      0.08321351557970047,
      -0.019473057240247726,
      0.061309851706027985,
      0.09013179689645767,
      -0.012769521214067936,
      0.05384945124387741,
      -0.09265095740556717,
      0.042776744812726974,
      0.03262264281511307,
      0.009076074697077274
    ],
    [
      -0.04806011915206909,
      -0.04010686278343201,
      0.07359569519758224,
      0.10832896083593369,
      -0.04013188183307648,
      -0.003469227347522974,
      0.02575099468231201,
      -0.018624322488904,
      0.06693529337644577,
      -0.033987682312726974,
      -0.006144821643829346,
      -0.042789146304130554,
      -0.02575983665883541,
      -0.017551401630043983,
      -0.0576976053416729,
      -0.0212590079754591,
      0.0594777874648571,
      -0.04355435073375702,
      0.04517853632569313,
      -0.029401902109384537,
      0.0125490827485919,
      0.01862299256026745,
      -0.028665637597441673,
      -0.05597944185137749,
      -0.0038077726494520903,
      0.06635896861553192,
      0.007757602725178003,
      0.04736137017607689,
      0.0014373550657182932,
      0.030136805027723312,
      0.018614334985613823,
      -0.0027065263129770756,
      0.001649843412451446,
      -0.0343402698636055,
      -0.015404149889945984,
      0.10003841668367386,
      0.009112208150327206,
      0.026192687451839447,
      -0.045372214168310165,
      0.010666873306035995,
      -0.055332083255052567,
      -0.03000441938638687,
      -0.040436405688524246,
      0.0484936498105526,
      0.023388778790831566,
      0.022260334342718124,
      -0.06404236704111099,
      -0.011179798282682896,
      -0.006026252172887325,
      -0.05980515480041504,
      -0.006231063976883888,
      0.024669166654348373,
      0.010233658365905285,
      -0.04110848531126976,
      0.019538436084985733,
      0.01408813800662756,
      0.033836480230093,
      0.03359129652380943,
      0.024523066356778145,
      0.0177440382540226,
      0.027066325768828392,
      -0.014033491723239422,
      0.06398263573646545,
      -0.0268054511398077,
      -0.1035226434469223,
      0.01740265265107155,
      0.06159554794430733,
      -0.06849319487810135,
      0.024936562404036522,
      -0.0027302068192511797,
      -0.018204418942332268,
      -0.03455508127808571,
      -0.03916269168257713,
      0.0016095383325591683,
      0.03308117017149925,
      0.00015555716527160257,
      0.030262134969234467,
      -0.005473716650158167,
      0.008984273299574852,
      0.020126882940530777,
      -0.020517539232969284,
      0.07398030906915665,
      0.04973486810922623,
      -0.07702688872814178,
      -0.029305147007107735,
      0.006333349738270044,
      -0.057674556970596313,
      0.05973011627793312,
      0.043851591646671295,
      -0.018729737028479576,
      0.0031240342650562525,
      -0.040511537343263626,
      0.034314967691898346,
      0.004084174986928701,
      -0.03934524208307266,
      0.051663197576999664,
      -0.044273197650909424,
      -0.05512552708387375,
      0.05008960887789726,
      -0.050733231008052826,
      0.00968279130756855,
      -0.06449572741985321,
      -0.022840507328510284,
      -0.033071763813495636,
      -0.09542707353830338,
      0.03212668374180794,
      -0.0420217327773571,
      -0.024787966161966324,
      0.03389763459563255,
      -0.053404781967401505,
      -0.008234652690589428,
      -0.008370545692741871,
      -0.06941208243370056,
      0.00037402339512482285,
      -0.02493157982826233,
      0.022914670407772064,
      -0.032999515533447266,
      -0.03227444365620613,
      -0.04250489920377731,
      0.06813987344503403,
      0.034542180597782135,
      -0.034399885684251785,
      0.021070487797260284,
      0.021351603791117668,
      0.0390474759042263,
      -0.07198189198970795,
      0.028358763083815575,
      -0.057017989456653595,
      0.07855802029371262,
      -0.01637229695916176,
      -0.05967581272125244,
      0.02091270312666893,
      -0.038686130195856094,
      0.012435643002390862,
      0.01705637387931347,
      0.06594573706388474,
      0.03970719501376152,
      0.0026260896120220423,
      -0.017426718026399612,
      0.03802758827805519,
      -0.04015928506851196,
      -0.021665461361408234,
      0.010084107518196106,
      0.007815445773303509,
      -0.0450017936527729,
      -0.01749316416680813,
      -0.022038249298930168,
      0.058533985167741776,
      -0.02532138302922249,
      -0.07051127403974533,
      0.025530396029353142,
      -0.0577574223279953,
      -0.022636856883764267,
      0.028901685029268265,
      0.005755668971687555,
      0.023162413388490677,
      0.05546373128890991,
      -0.022449331358075142,
      -0.02516068145632744,
      -0.04637857899069786,
      -0.06035885214805603,
      0.0013777932617813349,
      -0.03490684926509857,
      0.034386638551950455,
      0.044451162219047546,
      0.05269574001431465,
      0.03720393031835556,
      0.03293909132480621,
      -0.02976345643401146,
      -0.03915579617023468,
      -0.025990702211856842,
      0.04548417031764984,
      0.0017811392899602652,
      0.021557187661528587,
      -0.06928806006908417,
      0.061415933072566986,
      0.030304472893476486,
      0.028039129450917244,
      0.02776491641998291,
      0.008977013640105724,
      -0.05341227725148201,
      -0.0526423342525959,
      -0.04359203204512596,
      -0.01605183258652687,
      -0.006777474191039801,
      -0.009388943202793598,
      0.03892963379621506,
      0.03274109959602356,
      0.07584283500909805,
      0.031867899000644684,
      0.051406379789114,
      0.006103608291596174,
      -0.0019882016349583864,
      -0.059713322669267654,
      0.011165500618517399,
      -0.015233159996569157,
      -0.012212864123284817,
      -0.057189736515283585,
      -0.01773333176970482,
      -0.004802329931408167,
      -0.11121164262294769,
      0.0031411077361553907,
      -0.016952667385339737,
      0.058532536029815674,
      0.11418159306049347,
      0.053344521671533585,
      0.021650642156600952,
      0.04360850527882576,
      -0.029680071398615837,
      -0.023486502468585968,
      0.016447750851511955,
      0.05798787251114845,
      0.02925790473818779,
      -0.07181565463542938,
      -0.03780828416347504,
      -0.04417791590094566,
      0.01504378393292427,
      -0.012401070445775986,
      0.009003400802612305,
      0.04134900122880936,
      0.08822833746671677,
      -0.010161061771214008,
      0.00905915629118681,
      0.056415144354104996,
      -0.014614572748541832,
      0.017565447837114334,
      0.008446881547570229,
      0.02904731221497059,
      0.08129396289587021,
      0.026624886319041252,
      -0.10277319699525833,
      0.018978172913193703,
      0.04743528738617897,
      -0.021078243851661682,
      0.005778027698397636,
      -0.020155200734734535,
      0.04259740933775902,
      -0.043479129672050476,
      -0.0480821318924427,
      -0.06485114246606827,
      0.0010140209924429655,
      -0.09186343848705292,
      -0.01581042818725109,
      -0.018221596255898476,
      0.039055801928043365,
      0.035935934633016586,
      -0.015907268971204758,
      0.029855811968445778,
      0.02385731041431427,
      0.04934967681765556,
      0.03534378856420517,
      -0.09652753174304962,
      0.011528486385941505,
      -0.00715112779289484,
      0.03212953358888626,
      0.04011676087975502,
      0.03417954593896866,
      -0.01066160574555397,
      0.014451208524405956,
      0.027153590694069862,
      -0.02688424289226532,
      0.0032378174364566803,
      0.06756047904491425,
      0.00750030716881156,
      -0.008082323707640171,
      -0.05652229115366936,
      -0.01971607655286789,
      -0.01854410395026207,
      -0.001277522067539394,
      -0.045698363333940506,
      0.017926877364516258,
      -0.023555872961878777,
      0.04695315659046173,
      -0.014955231919884682,
      -0.003278176300227642,
      -0.03216461092233658,
      0.024188997223973274,
      -0.062370479106903076,
      0.05928294360637665,
      0.017038065940141678,
      0.04929855465888977,
      0.07972163707017899,
      0.015132824890315533,
      -0.02392493188381195,
      -0.11273335665464401,
      -0.014768000692129135,
      0.0255839005112648,
      -0.03407971188426018,
      -0.027918333187699318,
      0.06319613009691238,
      0.004249386955052614,
      0.025739025324583054,
      -0.01814713142812252,
      -0.0024156973231583834,
      0.04419081285595894,
      0.0018707423005253077,
      0.048899341374635696,
      0.04550107195973396,
      0.044492460787296295,
      -0.010100383311510086,
      -0.055976491421461105,
      -0.00761042395606637,
      0.01914912462234497,
      0.02662259340286255,
      0.08039114624261856,
      0.05119607597589493,
      -0.040499377995729446,
      -0.0748528391122818,
      -0.09915430098772049,
      0.004753185901790857,
      0.03868504986166954,
      0.02852002903819084,
      0.06316707283258438,
      -0.0013402624754235148,
      -0.04147335886955261,
      -0.04607720300555229,
      -0.027805473655462265,
      0.02915789745748043,
      -0.04737582057714462,
      -0.08138275891542435,
      0.0013771625235676765,
      -0.02163722924888134,
      0.06700421869754791,
      -0.035226769745349884,
      -0.00023392132425215095,
      -0.08251795917749405,
      -0.06982991099357605,
      0.025642549619078636,
      0.011841434054076672,
      -0.03271537274122238,
      -0.05873062089085579,
      -0.01941450871527195,
      0.06172353774309158,
      -0.014012902975082397,
      -0.04952891543507576,
      0.06922660022974014,
      0.011595654301345348,
      -0.023049840703606606,
      -0.00677556311711669,
      0.010948061011731625,
      0.01272167544811964,
      -0.006394957657903433,
      0.004450704902410507,
      -0.026440775021910667,
      0.010248253121972084,
      -0.01605406403541565,
      0.00561260711401701,
      -0.03899811953306198,
      -0.053045086562633514,
      -0.08062735199928284,
      -0.09389777481555939,
      -0.05558578297495842,
      0.015299470163881779,
      0.015927104279398918,
      -0.05378652736544609,
      -0.018479537218809128,
      0.05738655477762222,
      -0.05721830204129219,
      -0.0042967121116817,
      0.036021888256073,
      -0.034989528357982635,
      0.05813804641366005,
      0.0311284177005291,
      -0.06772281229496002,
      -0.029454417526721954,
      0.035330310463905334,
      0.04692341014742851,
      0.008704902604222298,
      -0.010943464934825897,
      0.0010559491347521544,
      -0.02407200075685978,
      0.05323764309287071,
      -0.040894582867622375,
      0.033359233289957047,
      -0.03985600918531418,
      -0.05751744285225868,
      0.017313450574874878,
      0.09824853390455246,
      0.014134366065263748,
      0.029543377459049225,
      -0.020756486803293228,
      -0.04795568436384201,
      -0.004370587412267923,
      0.0025462666526436806,
      -0.04800252243876457,
      -0.08089302480220795,
      -0.032755378633737564,
      0.011753218248486519,
      -0.015371641144156456,
      -0.021635862067341805,
      -0.0013881407212466002,
      0.0247352235019207,
      0.011163361370563507,
      0.010650832206010818,
      0.03469274938106537,
      0.04906361550092697,
      0.06755848228931427,
      0.007274060510098934,
      0.024913843721151352,
      0.03233296424150467,
      0.028439996764063835,
      -0.0040891412645578384,
      0.0032151397317647934,
      0.022862479090690613,
      0.06515519320964813,
      -0.10552234202623367,
      -0.06733224540948868,
      0.07621026784181595,
      -0.04720039293169975,
      -0.005419536959379911,
      -0.026305215433239937,
      -0.011368812993168831,
      0.006056393031030893,
      -0.02572532743215561,
      0.054205022752285004,
      0.0061300829984247684,
      0.025462964549660683,
      -0.11091608554124832,
      0.03770722076296806,
      -0.04184805229306221,
      -0.09905954450368881,
      -0.001432037097401917,
      0.0029786042869091034,
      0.007249713875353336,
      0.027087343856692314,
      0.02622949704527855,
      -0.04800118878483772,
      0.06799807399511337,
      -0.023453719913959503,
      -0.03126365691423416,
      -0.02945035882294178,
      -0.014632935635745525,
      -0.044895537197589874,
      -0.044006939977407455,
      0.08362806588411331,
      0.021840212866663933,
      0.006985661573708057,
      0.007495560217648745,
      0.02595132403075695,
      -0.025426220148801804,
      -0.05289904400706291,
      0.01752481237053871,
      -0.06540223211050034,
      0.05691332742571831,
      0.04164455085992813,
      0.0015145050128921866,
      -0.020970532670617104,
      -0.005452712532132864,
      -0.008433973416686058,
      -0.014022058807313442,
      0.03304344415664673,
      0.07804545760154724,
      -0.0031035684514790773,
      -0.029891859740018845,
      0.01796930469572544,
      0.04787341132760048,
      -0.0007768400246277452,
      0.040801409631967545,
      -0.0030724063981324434,
      -0.1010822057723999,
      -0.04173123463988304,
      0.06700432300567627,
      -0.03155013173818588,
      -0.0024752772878855467,
      0.07902307063341141,
      0.025624869391322136,
      0.064186692237854,
      0.01210695318877697,
      0.009884572587907314,
      -0.0658603087067604,
      -0.01898273453116417,
      0.024598250165581703,
      -0.03799813985824585,
      -0.07991684973239899,
      0.03383483365178108,
      0.10366420447826385,
      -0.010237247683107853,
      0.08213988691568375,
      0.04137812554836273,
      -0.023695800453424454,
      -0.10628510266542435,
      -0.05999201163649559,
      -0.0751504972577095,
      -0.003403875045478344,
      -0.012644653208553791,
      0.022040098905563354,
      -0.03251140937209129,
      0.017496058717370033,
      0.0681188553571701,
      0.04196885973215103,
      -0.019007347524166107,
      0.0502796396613121,
      0.01058153435587883,
      0.050326451659202576,
      0.035222750157117844,
      0.0025509195402264595,
      -0.04542529210448265,
      -0.029112687334418297,
      -0.01097069401293993,
      -0.05291617289185524,
      -0.022815154865384102,
      0.04322981461882591,
      0.059507764875888824
    ],
    [
      -0.021348727867007256,
      -0.09155783802270889,
      0.03518790006637573,
      0.03655615448951721,
      -0.006740947719663382,
      -0.015963494777679443,
      0.012009106576442719,
      0.07104120403528214,
      -0.0018996563740074635,
      0.010808825492858887,
      0.02194218337535858,
      -0.0016109519638121128,
      -0.010128169320523739,
      -0.050138771533966064,
      -0.017069345340132713,
      -0.015448459424078465,
      -0.008997229859232903,
      0.044657252728939056,
      -0.006555765867233276,
      -0.03724512457847595,
      -0.029460664838552475,
      -0.02018856629729271,
      -0.010720892809331417,
      0.007151087746024132,
      0.021556606516242027,
      0.03453761711716652,
      0.013361227698624134,
      0.0003158116014674306,
      0.04406962916254997,
      -0.023780878633260727,
      -0.02233722433447838,
      0.045884620398283005,
      0.06714721769094467,
      -0.031223740428686142,
      0.02164822816848755,
      -0.01923251524567604,
      -0.0343659333884716,
      0.033450596034526825,
      -0.005700367968529463,
      0.047752656042575836,
      -0.038047317415475845,
      0.0653873160481453,
      0.025716273114085197,
      -0.004573055077344179,
      0.006056111305952072,
      0.011604555882513523,
      -0.02494695968925953,
      -0.005739108193665743,
      -0.10552842170000076,
      0.04500150680541992,
      -0.0725574940443039,
      0.03660369664430618,
      -0.002146154874935746,
      0.0065450360998511314,
      0.05444318801164627,
      -0.035343874245882034,
      0.0028175751212984324,
      -0.0021841509733349085,
      0.026067128404974937,
      -0.048534877598285675,
      -0.01728217303752899,
      0.05210410803556442,
      0.03521540015935898,
      0.015821274369955063,
      0.03269767016172409,
      -0.02804071083664894,
      -0.006052947603166103,
      0.04329746961593628,
      0.026887204498052597,
      0.036993104964494705,
      -0.01893889717757702,
      -0.02955763228237629,
      0.014059974811971188,
      0.11851786077022552,
      -0.023510292172431946,
      -0.011554837226867676,
      -0.02760724164545536,
      0.0015708161517977715,
      -0.026143347844481468,
      0.0404757596552372,
      0.042437899857759476,
      -0.06414015591144562,
      0.07060274481773376,
      -0.02093476802110672,
      0.11106076091527939,
      0.03938479721546173,
      0.04841306805610657,
      0.016806140542030334,
      0.001834044000133872,
      0.11922691017389297,
      -0.0456809401512146,
      0.019474849104881287,
      0.046344731003046036,
      0.01643805019557476,
      0.03977004438638687,
      -0.06899693608283997,
      -0.00922368559986353,
      0.015083888545632362,
      0.017417389899492264,
      0.0169476680457592,
      -0.017145195975899696,
      0.018854869529604912,
      -0.009803465567529202,
      -0.036660343408584595,
      0.0014924006536602974,
      0.04572519287467003,
      -0.04888062924146652,
      -0.04283202439546585,
      -0.020435960963368416,
      0.0042991372756659985,
      0.06270530819892883,
      0.007830792106688023,
      0.0050851344130933285,
      -0.063164122402668,
      -0.018807103857398033,
      -0.10962426662445068,
      -0.04564695060253143,
      -0.012289942242205143,
      -0.0023650294169783592,
      -0.022182026877999306,
      0.007129749748855829,
      -0.01832212135195732,
      -0.05073383077979088,
      -0.03466647490859032,
      0.02148314192891121,
      0.023273441940546036,
      0.06944907456636429,
      0.06309880316257477,
      0.008247517980635166,
      0.026252344250679016,
      0.03477118909358978,
      0.04272623360157013,
      -0.020142361521720886,
      -0.037018805742263794,
      0.04900289699435234,
      -0.03504154086112976,
      0.03867946192622185,
      -0.00787543598562479,
      -0.032291095703840256,
      -0.01931428723037243,
      -0.029609521850943565,
      -0.1091027781367302,
      0.021617237478494644,
      0.031496886163949966,
      0.017845233902335167,
      -0.01428674440830946,
      0.054902467876672745,
      -0.07067330181598663,
      0.03909735381603241,
      -0.046349961310625076,
      0.042840197682380676,
      -0.12086038291454315,
      -0.0879024937748909,
      -0.05266629159450531,
      0.03713450953364372,
      -0.0006770979380235076,
      -0.011410750448703766,
      -0.0734795406460762,
      0.012530624866485596,
      -0.006731995847076178,
      -0.010981164872646332,
      0.031125307083129883,
      -0.07986389100551605,
      0.008166313171386719,
      0.009224344044923782,
      0.05514376610517502,
      0.028490209951996803,
      -0.011153117753565311,
      0.041422776877880096,
      -0.011241993866860867,
      0.0033361788373440504,
      0.08407807350158691,
      0.005867579486221075,
      -0.0024957654532045126,
      -0.02157430537045002,
      0.0411817729473114,
      -0.07926080375909805,
      -0.09810395538806915,
      0.06032043695449829,
      -0.009858510456979275,
      0.013195731677114964,
      -0.11594916135072708,
      -0.0003726865688804537,
      -0.04214198887348175,
      0.10311701148748398,
      0.016612840816378593,
      -0.04969125986099243,
      -0.012422357685863972,
      0.0021389273460954428,
      0.01834382861852646,
      0.06395427137613297,
      -0.059229232370853424,
      -0.04753178730607033,
      -0.015008383430540562,
      0.0016690327320247889,
      0.011040270328521729,
      0.030669761821627617,
      0.042625367641448975,
      0.06057010218501091,
      0.05093885958194733,
      0.0649726465344429,
      -0.037811871618032455,
      0.06299039721488953,
      0.025142798200249672,
      0.0035029402934014797,
      -0.04679684713482857,
      0.050260260701179504,
      0.01827848143875599,
      -0.1283218413591385,
      0.000589471310377121,
      0.03868218883872032,
      0.0014987288741394877,
      -0.06122157722711563,
      -0.04844369366765022,
      -0.026091212406754494,
      0.09409577399492264,
      -0.0003163697256240994,
      0.02303341217339039,
      -0.03066428005695343,
      -0.018182123079895973,
      0.06376528739929199,
      -0.049538180232048035,
      -0.009388912469148636,
      -0.0571124367415905,
      0.06537620723247528,
      0.010028578341007233,
      -0.029748201370239258,
      -0.04507845640182495,
      -0.026151061058044434,
      0.009318661876022816,
      0.02074294723570347,
      -0.0200346689671278,
      0.01568901352584362,
      0.03652609512209892,
      0.018035246059298515,
      -0.003975974395871162,
      -0.12987367808818817,
      -0.006563916336745024,
      0.015644481405615807,
      0.0612783208489418,
      0.011691702529788017,
      -0.0048035732470452785,
      0.05829913169145584,
      0.02045309916138649,
      0.024116598069667816,
      0.012243242003023624,
      0.048227448016405106,
      0.05763467401266098,
      0.018615292385220528,
      0.012924380600452423,
      -0.009623577818274498,
      0.02488298900425434,
      -0.03974059596657753,
      0.008476300165057182,
      0.08321429789066315,
      0.01566082052886486,
      -0.005903936456888914,
      0.024625204503536224,
      0.0204432625323534,
      0.0336713008582592,
      -0.02088790200650692,
      0.015361065044999123,
      -0.052372872829437256,
      0.008006437681615353,
      0.009474244900047779,
      -0.029852380976080894,
      0.018521936610341072,
      0.014406283386051655,
      0.01885804906487465,
      -0.021677326411008835,
      0.0031503282953053713,
      0.0237676203250885,
      0.02899952046573162,
      0.05501854419708252,
      -0.0029866171535104513,
      -0.01111441571265459,
      -0.0014985748566687107,
      -0.036250580102205276,
      -0.022218286991119385,
      0.08627142012119293,
      0.014828660525381565,
      -0.008878850378096104,
      -0.024994073435664177,
      -0.052743714302778244,
      0.11166544258594513,
      -0.017618022859096527,
      -0.0395880788564682,
      0.017716610804200172,
      0.04208080470561981,
      0.0024915868416428566,
      0.053437069058418274,
      0.01588987372815609,
      -0.030686620622873306,
      -0.0018816330702975392,
      -0.07381583750247955,
      -0.10627152025699615,
      -0.010748512111604214,
      -0.02429056726396084,
      -0.043381400406360626,
      -0.027164146304130554,
      -0.10271792858839035,
      0.021519746631383896,
      0.00411126296967268,
      0.02000119350850582,
      0.019590485841035843,
      0.07136721909046173,
      -0.00338260387070477,
      -0.0032894995529204607,
      -0.014407433569431305,
      0.0002413991023786366,
      0.01712123304605484,
      0.024107007309794426,
      0.010058402083814144,
      0.0002713181893341243,
      0.09148479998111725,
      -0.001588646788150072,
      -0.004858886823058128,
      -0.014004755765199661,
      -0.06071959808468819,
      -0.0032037084456533194,
      0.01810607686638832,
      0.008431554771959782,
      0.0655880719423294,
      0.0008851427119225264,
      0.047635696828365326,
      -0.014262978918850422,
      -0.017558662220835686,
      0.015118532814085484,
      0.03264579921960831,
      -0.03858260065317154,
      0.03822329640388489,
      0.001467069610953331,
      -0.025292782112956047,
      0.018094763159751892,
      0.10246835649013519,
      0.01153862290084362,
      0.034949880093336105,
      -0.0030601173639297485,
      -0.005377073306590319,
      0.1031440943479538,
      -0.0007338085561059415,
      0.008965437300503254,
      0.00843682512640953,
      -0.07551418989896774,
      -0.01027864869683981,
      0.07242438197135925,
      -0.07457137852907181,
      0.006335014011710882,
      -0.04422203451395035,
      -0.04064279422163963,
      -0.056806039065122604,
      -0.06662334501743317,
      0.001961741829290986,
      0.039463505148887634,
      -0.013832269236445427,
      -0.006778349634259939,
      0.00830884650349617,
      -0.064951092004776,
      -0.06432925909757614,
      0.10782273858785629,
      -0.08286868780851364,
      0.008903692476451397,
      0.030384548008441925,
      -0.007075194735080004,
      -0.013864241540431976,
      0.01571262814104557,
      -0.044222392141819,
      -0.028823453933000565,
      -0.0006938365986570716,
      0.03446781635284424,
      0.015147900208830833,
      0.0030104718171060085,
      0.008463079109787941,
      -0.06277987360954285,
      0.010138080455362797,
      0.009554309770464897,
      0.08689302206039429,
      0.018771696835756302,
      -0.018191669136285782,
      0.09131191670894623,
      -0.04203841835260391,
      -0.0077476538717746735,
      -0.026325538754463196,
      -0.017299547791481018,
      0.01008001808077097,
      0.02347993478178978,
      0.011468165554106236,
      -0.07910536974668503,
      0.0058072637766599655,
      -0.02438107132911682,
      -0.005754975136369467,
      0.012554708868265152,
      -0.003429956268519163,
      0.07465328276157379,
      -0.0516788586974144,
      0.028350522741675377,
      0.03439757972955704,
      0.026392262428998947,
      -0.03667145222425461,
      0.04483291879296303,
      -0.026801185682415962,
      -0.013889745809137821,
      0.007920317351818085,
      -0.13343320786952972,
      -0.062000785022974014,
      0.004852793645113707,
      -0.05321061238646507,
      0.03651951625943184,
      0.0022193514741957188,
      -0.03271380811929703,
      0.006993716582655907,
      -0.007803742308169603,
      0.09494315832853317,
      -0.011936669237911701,
      0.022401662543416023,
      -0.022575614973902702,
      0.04405200853943825,
      0.058858610689640045,
      -0.015603221952915192,
      0.05746754631400108,
      0.026178715750575066,
      0.03856315463781357,
      -0.0065300739370286465,
      -0.010690764524042606,
      0.022961270064115524,
      -0.06791811436414719,
      0.06561479717493057,
      0.006409667432308197,
      0.03350264951586723,
      -0.04326639696955681,
      -0.0031458898447453976,
      -0.010286202654242516,
      -0.030663881450891495,
      -0.01939799077808857,
      0.030170438811182976,
      -0.02856338396668434,
      0.009449709206819534,
      0.01782327890396118,
      -0.026320982724428177,
      0.026668468490242958,
      0.012694388628005981,
      -0.07888106256723404,
      -0.049863554537296295,
      0.03778130188584328,
      0.07883292436599731,
      -0.0419340580701828,
      0.06709548830986023,
      -0.023851102218031883,
      0.05517752096056938,
      0.03372664004564285,
      -0.02333758771419525,
      0.04226330295205116,
      -0.02077646553516388,
      0.020434817299246788,
      -0.07018009573221207,
      0.0489254929125309,
      -0.0032974507194012403,
      -0.04155944660305977,
      0.11428746581077576,
      0.019385598599910736,
      0.014214187860488892,
      -0.02348995767533779,
      -0.0429932102560997,
      -0.013660762459039688,
      -0.05717290937900543,
      0.04421227425336838,
      -0.01308324746787548,
      0.01982300914824009,
      0.04499641805887222,
      -0.0034706168808043003,
      -0.028252294287085533,
      0.07949776202440262,
      -0.004451737739145756,
      0.013934750109910965,
      -0.014031830243766308,
      -0.06388059258460999,
      -0.016792045906186104,
      0.0058394246734678745,
      -0.006065507419407368,
      -0.04012612998485565,
      -0.001644451287575066,
      0.010840526781976223,
      -0.01659521460533142,
      0.05339236557483673,
      -0.028121016919612885,
      -0.033373624086380005,
      -0.0798117145895958,
      0.01107040699571371,
      -0.020202361047267914,
      -0.015983162447810173,
      -0.1088528260588646,
      -0.051301080733537674,
      0.007320436183363199,
      -0.0202118381857872,
      0.04011768475174904,
      0.059226471930742264,
      -0.01102476567029953,
      0.01000940054655075,
      -0.036104533821344376,
      0.04979275166988373,
      -0.12138194590806961,
      0.01956460438668728,
      -0.07683882862329483
    ],
    [
      0.04516777768731117,
      -0.06979057937860489,
      0.0559135265648365,
      0.029792077839374542,
      -0.04735406115651131,
      0.01982806622982025,
      -0.06056789681315422,
      -0.030744852498173714,
      -0.011844385415315628,
      -0.0027276952750980854,
      -0.030809715390205383,
      -0.05753839761018753,
      -0.0840512067079544,
      -0.007271304726600647,
      0.002393921837210655,
      0.023601235821843147,
      0.07068857550621033,
      0.03737547621130943,
      -0.04377401992678642,
      0.05271978676319122,
      -0.0243610180914402,
      -0.0799456462264061,
      0.042944617569446564,
      -0.05828283354640007,
      0.01974136382341385,
      -0.03864133358001709,
      -0.008476540446281433,
      0.01329639833420515,
      -0.0022349718492478132,
      -0.02525816299021244,
      0.032656073570251465,
      -0.06198956072330475,
      -0.0010607894510030746,
      -0.02696789801120758,
      -0.027798699215054512,
      0.012901697307825089,
      -0.012577184475958347,
      0.04879195988178253,
      -0.08183048665523529,
      -0.0017767540412023664,
      0.06836654245853424,
      -0.04214440658688545,
      -0.003769081551581621,
      0.06904743611812592,
      0.08364376425743103,
      -0.033118121325969696,
      -0.05980384349822998,
      -0.03309234604239464,
      0.09409934282302856,
      0.007462667766958475,
      0.050039175897836685,
      0.03124830685555935,
      -0.026666415855288506,
      -0.05638199299573898,
      0.05153385549783707,
      0.003953918348997831,
      0.06242448464035988,
      0.038060639053583145,
      -0.018179483711719513,
      -0.042074695229530334,
      -0.028728442266583443,
      0.05176665261387825,
      0.024309810250997543,
      0.008061032742261887,
      -0.05382193624973297,
      -0.0037320731207728386,
      0.07831118255853653,
      0.009660797193646431,
      0.02296828292310238,
      -0.07050579786300659,
      -0.010821068659424782,
      0.016918456181883812,
      -0.10312619060277939,
      -0.10544339567422867,
      0.017146065831184387,
      0.037165749818086624,
      -0.042295895516872406,
      0.018155578523874283,
      0.012028236873447895,
      -0.04564422369003296,
      0.00916787888854742,
      -0.08004582673311234,
      0.03602554649114609,
      -0.004620201885700226,
      0.0013587940484285355,
      0.049715809524059296,
      0.009534589014947414,
      -0.0416322760283947,
      0.035575658082962036,
      -0.050961144268512726,
      0.0055914646945893764,
      0.000467350851977244,
      -0.0037572351284325123,
      -0.025768740102648735,
      0.060261111706495285,
      0.05327056348323822,
      0.011133918538689613,
      -0.03572291135787964,
      0.03615583851933479,
      -0.0026769693940877914,
      -0.043628718703985214,
      -0.027617072686553,
      0.007537897676229477,
      -0.04733167588710785,
      -0.006131605710834265,
      0.029417693614959717,
      -0.011938964016735554,
      -0.050043776631355286,
      -0.03379238769412041,
      -0.07868709415197372,
      0.051301080733537674,
      -0.029024038463830948,
      0.050301212817430496,
      -0.050171010196208954,
      -0.07191152125597,
      -0.02742578275501728,
      0.06371146440505981,
      -0.010449565015733242,
      -0.07873509079217911,
      -0.059859491884708405,
      0.017001064494252205,
      -0.03263365104794502,
      0.019529124721884727,
      0.08844723552465439,
      0.05442659929394722,
      -0.008340554311871529,
      0.12996238470077515,
      -0.047739095985889435,
      -0.01882961019873619,
      0.03177111595869064,
      -0.032170217484235764,
      0.05552680045366287,
      -0.020435264334082603,
      -0.016849743202328682,
      -0.02134544961154461,
      0.003572128713130951,
      -0.029863784089684486,
      -0.005170483607798815,
      -0.003435361199080944,
      0.052049800753593445,
      -0.06988655775785446,
      -0.017739281058311462,
      0.10563857108354568,
      -0.016622712835669518,
      0.015123298391699791,
      0.009531202726066113,
      0.019660064950585365,
      -0.04774206504225731,
      -0.01256741676479578,
      0.05915181338787079,
      0.06208841875195503,
      -0.07325369864702225,
      0.0005618597497232258,
      0.05708497017621994,
      0.05909568443894386,
      -0.008200127631425858,
      -0.02442706562578678,
      -0.025500668212771416,
      0.08351064473390579,
      -0.033207967877388,
      -0.0558021180331707,
      0.01268159132450819,
      0.0277243722230196,
      0.06787388026714325,
      -0.05642477795481682,
      -0.02226960100233555,
      0.025515839457511902,
      -0.04268490895628929,
      -0.05184097960591316,
      -0.0741858184337616,
      -0.09571179747581482,
      0.02902931533753872,
      0.03763527423143387,
      0.023437833413481712,
      -0.005259880796074867,
      -0.10452455282211304,
      -0.022957265377044678,
      -0.06222405657172203,
      0.038262106478214264,
      -0.04049427807331085,
      -0.0140684200450778,
      -0.04463018476963043,
      -0.05519993603229523,
      -0.008407467976212502,
      -0.06556998193264008,
      -0.09340812265872955,
      0.01064720656722784,
      -0.04138278216123581,
      0.04142167419195175,
      -0.012339715845882893,
      0.00029171083588153124,
      -2.5148154236376286e-05,
      -0.08117569237947464,
      0.028036003932356834,
      -0.01005872804671526,
      -0.042142730206251144,
      0.017940595746040344,
      -0.04355548322200775,
      -0.03505254536867142,
      -0.011622434481978416,
      0.012249842286109924,
      0.05069660395383835,
      -0.04464603587985039,
      0.04230385646224022,
      -0.05405925586819649,
      0.05149975046515465,
      -0.037818506360054016,
      -0.08734027296304703,
      -0.09341409057378769,
      0.03931482508778572,
      -0.008274026215076447,
      0.06645418703556061,
      -0.0012520363088697195,
      0.0033352160826325417,
      0.005123198498040438,
      0.040060658007860184,
      -0.03822974115610123,
      -0.049197226762771606,
      0.03951359540224075,
      0.028716884553432465,
      0.04388975724577904,
      -0.02848675288259983,
      -0.0020029335282742977,
      0.02339964546263218,
      -0.0024026159662753344,
      -0.04337610676884651,
      -0.03940613195300102,
      -0.06183381751179695,
      0.0278011504560709,
      -0.014257561415433884,
      0.07775221019983292,
      0.022972166538238525,
      0.0345228835940361,
      -0.0013887814711779356,
      0.03716851398348808,
      0.07452087104320526,
      0.06125544384121895,
      -0.024340927600860596,
      0.028960224241018295,
      -0.021930724382400513,
      0.006749480497092009,
      -0.11553315073251724,
      -0.016489297151565552,
      0.0918671116232872,
      -0.07316131144762039,
      0.004391933791339397,
      -0.023090297356247902,
      0.0713818147778511,
      0.027569709345698357,
      0.004722003825008869,
      0.038439273834228516,
      0.03381893038749695,
      -0.07018329203128815,
      -0.008565347641706467,
      0.056116439402103424,
      0.018947262316942215,
      -0.03503165766596794,
      -0.04327956587076187,
      0.06294137239456177,
      0.0129063306376338,
      -0.05564213544130325,
      -0.04313165321946144,
      -0.06041925027966499,
      0.06348889321088791,
      -0.05125712230801582,
      0.10486803948879242,
      -0.014161997474730015,
      -0.03333626687526703,
      0.03871056064963341,
      0.0636691153049469,
      0.021280821412801743,
      -0.01833161525428295,
      -0.03212284296751022,
      0.018031803891062737,
      0.03673253580927849,
      0.016224192455410957,
      0.03336706385016441,
      -0.09249235689640045,
      -0.015007000416517258,
      0.03555459529161453,
      0.078977070748806,
      0.017816051840782166,
      0.06249445676803589,
      0.01930088736116886,
      -0.017230315133929253,
      0.10074343532323837,
      0.039744529873132706,
      -0.03185416758060455,
      0.004618843551725149,
      -0.01571700908243656,
      -0.0008410500013269484,
      0.03605431318283081,
      -0.028498714789748192,
      0.012001357041299343,
      0.04498974233865738,
      -0.05481009930372238,
      -0.0051598274149000645,
      0.06843256950378418,
      -0.04757794365286827,
      0.008143564686179161,
      0.0763954222202301,
      0.011083369143307209,
      -0.07864786684513092,
      0.017836490646004677,
      0.013957538641989231,
      0.009698891080915928,
      -0.06167399883270264,
      -0.014608193188905716,
      -0.0011062521953135729,
      -0.05262162908911705,
      -0.07783854752779007,
      -0.01341791357845068,
      0.006236623972654343,
      -0.04234718903899193,
      -0.0638643428683281,
      0.02772848680615425,
      -0.03587246686220169,
      -0.024852203205227852,
      0.025341156870126724,
      -0.058675024658441544,
      0.13664282858371735,
      -0.07124112546443939,
      0.003195781959220767,
      -0.023999778553843498,
      -0.05235099792480469,
      -0.01915610022842884,
      -0.009952185675501823,
      0.010256309062242508,
      0.016414541751146317,
      0.0003725200949702412,
      -0.042475566267967224,
      -0.010931281372904778,
      -0.03030279651284218,
      0.014314401894807816,
      0.06101834774017334,
      -0.03240790218114853,
      0.028612880036234856,
      -0.07304397970438004,
      0.06045805662870407,
      0.051922496408224106,
      0.00191404833458364,
      -0.0024041691794991493,
      0.04408537968993187,
      -0.03434855118393898,
      0.08443505316972733,
      -0.03019876778125763,
      0.030522892251610756,
      -0.06136859953403473,
      0.04342719539999962,
      -0.08053901046514511,
      -0.011760188266634941,
      -0.01263627503067255,
      0.009368698112666607,
      -0.020449072122573853,
      -0.02395501360297203,
      -0.007530750706791878,
      0.03146923705935478,
      0.04626775160431862,
      0.10032197088003159,
      -0.03339493274688721,
      0.11922179162502289,
      -0.023594500496983528,
      0.014265715144574642,
      0.032511238008737564,
      -0.0031075698789209127,
      0.02571716718375683,
      -0.024905294179916382,
      0.028696391731500626,
      0.046664901077747345,
      0.0038567304145544767,
      0.001222767517901957,
      -0.07237990200519562,
      -0.024752648547291756,
      0.006497731897979975,
      -0.0004986047861166298,
      -0.06021314859390259,
      -0.1111413836479187,
      0.008269709534943104,
      -0.032629359513521194,
      0.06966575980186462,
      0.09364327788352966,
      -0.020572541281580925,
      0.026107436046004295,
      0.014704734086990356,
      -0.011769142001867294,
      -0.0003254007315263152,
      0.06733275949954987,
      -0.024737833067774773,
      -0.07219666987657547,
      -0.07759787142276764,
      -0.09410283714532852,
      -0.054472725838422775,
      0.023840406909585,
      0.016522793099284172,
      0.06011287122964859,
      0.024757135659456253,
      -0.0573311448097229,
      0.0053807953372597694,
      -0.06383009254932404,
      -0.004828926175832748,
      -0.025175269693136215,
      0.05128490924835205,
      0.07263509929180145,
      -0.09820374101400375,
      -0.018121037632226944,
      0.04623085632920265,
      0.01835746504366398,
      -0.04775995761156082,
      0.010028526186943054,
      -0.009813105687499046,
      -0.01211050245910883,
      0.029192565008997917,
      -0.07516150176525116,
      -0.012210450135171413,
      -0.01235418301075697,
      0.025809699669480324,
      -0.05793521925806999,
      -0.02218160405755043,
      -0.10506755113601685,
      0.009851331822574139,
      -0.02264348603785038,
      0.009976567700505257,
      -0.020982027053833008,
      0.048249173909425735,
      -0.08672938495874405,
      -0.04325883835554123,
      0.0372999869287014,
      0.04061657935380936,
      -0.04404256492853165,
      -0.08155319094657898,
      -0.03344166651368141,
      -0.07206271588802338,
      -0.08797702938318253,
      0.0006768160383217037,
      0.03268543258309364,
      0.04159403592348099,
      0.010380919091403484,
      -0.04577701911330223,
      -0.014550752937793732,
      -0.032238736748695374,
      0.02158741094172001,
      0.011787818744778633,
      0.0670875683426857,
      0.0537722148001194,
      -0.060628216713666916,
      0.0447692833840847,
      0.028185786679387093,
      0.020030248910188675,
      -0.01927681639790535,
      -0.01660093292593956,
      -0.03166165575385094,
      0.085054911673069,
      -0.05776110291481018,
      -0.004156533163040876,
      -0.008929282426834106,
      0.08955845981836319,
      0.017188962548971176,
      -0.01770995371043682,
      0.005659972783178091,
      0.019613059237599373,
      0.03314201906323433,
      0.08333887904882431,
      0.024531211704015732,
      -0.031194092705845833,
      -0.00239224499091506,
      0.07483405619859695,
      0.09785248339176178,
      0.0033818534575402737,
      -0.07055173069238663,
      -0.0074507142417132854,
      8.864381379680708e-05,
      0.01955125294625759,
      -0.08052852004766464,
      -0.07275721430778503,
      0.0024427708704024553,
      -0.003327426500618458,
      -0.01724938675761223,
      -0.005976734682917595,
      0.03722566366195679,
      0.042240288108587265,
      -0.0666099563241005,
      0.0028839290607720613,
      0.007438907399773598,
      0.021799417212605476,
      -0.01951690949499607,
      -0.06151062250137329,
      -0.0043160514906048775,
      -0.08151252567768097,
      0.04205021262168884,
      0.0029021240770816803,
      -0.07392837107181549,
      0.022311104461550713,
      -0.01554102636873722,
      0.021013399586081505,
      -0.00936800055205822,
      0.01243993267416954,
      0.07316409051418304,
      -0.05179053172469139,
      0.07639551907777786,
      0.03980912268161774,
      0.04538311809301376,
      -0.0694030299782753,
      0.03401555120944977
    ],
    [
      0.03889739140868187,
      0.015622545965015888,
      -0.009442804381251335,
      0.026290083304047585,
      0.011646843515336514,
      0.024346698075532913,
      -0.005119916517287493,
      -0.010579737834632397,
      -0.01863275282084942,
      0.003963811788707972,
      0.008267915807664394,
      0.004738288465887308,
      -0.06446297466754913,
      -0.05234687402844429,
      -0.007462369278073311,
      -0.012708768248558044,
      0.02961522713303566,
      0.050722818821668625,
      0.0012905971379950643,
      -0.019988177344202995,
      -0.022970015183091164,
      -0.011639765463769436,
      -0.08123261481523514,
      0.01504776906222105,
      0.01847841963171959,
      0.06492377817630768,
      -0.022922195494174957,
      0.01662890799343586,
      -0.017363710328936577,
      -0.011286832392215729,
      0.029598914086818695,
      0.06855583190917969,
      -0.020622149109840393,
      -0.03796771168708801,
      0.009409203194081783,
      -0.031263843178749084,
      -0.014265775680541992,
      0.031578950583934784,
      0.05460568517446518,
      0.03950269892811775,
      -0.034616682678461075,
      0.02205454744398594,
      -0.014583694748580456,
      0.011016947217285633,
      -0.055152274668216705,
      -0.01899654045701027,
      -0.0746823102235794,
      -0.018015168607234955,
      0.039087388664484024,
      -0.07578524947166443,
      -0.008108088746666908,
      0.05409806966781616,
      0.009702920913696289,
      0.004189487546682358,
      -0.06290195137262344,
      0.0030280370265245438,
      -0.028516165912151337,
      0.050630442798137665,
      0.004405422601848841,
      0.01060095801949501,
      -0.02120179310441017,
      -0.032168786972761154,
      -0.037247661501169205,
      0.027689512819051743,
      0.0022116382606327534,
      0.05129685997962952,
      0.0936802551150322,
      0.04065072163939476,
      -0.06412055343389511,
      -0.08181478083133698,
      -0.01256764866411686,
      -0.04065631330013275,
      -0.024031326174736023,
      -0.030094580724835396,
      -0.01174830924719572,
      -0.0704272910952568,
      0.06139402464032173,
      -0.04638868570327759,
      -0.04343857988715172,
      0.07960446923971176,
      0.001922821975313127,
      0.031697846949100494,
      0.03943910822272301,
      -0.07289355248212814,
      -0.02263842709362507,
      -0.007573739159852266,
      0.05072030797600746,
      0.06879354268312454,
      0.059020303189754486,
      0.07659189403057098,
      -0.02376331202685833,
      0.010188453830778599,
      -0.02078539878129959,
      0.023480741307139397,
      0.06070784851908684,
      -0.00862177461385727,
      0.02726113796234131,
      -0.04854379966855049,
      0.051255300641059875,
      -0.025317465886473656,
      0.008352870121598244,
      -0.020881954580545425,
      0.010417644865810871,
      0.012940258719027042,
      -0.008653522469103336,
      -0.0086559122428298,
      0.01889101415872574,
      -0.015451037324965,
      -0.09805767983198166,
      -0.016791028901934624,
      -0.027921654284000397,
      0.015101523138582706,
      0.024134870618581772,
      0.04942607879638672,
      -0.0119339469820261,
      0.029137134552001953,
      0.02734467387199402,
      -0.043919846415519714,
      0.05514157563447952,
      0.0710056871175766,
      0.047125719487667084,
      -0.08261570334434509,
      0.04985036700963974,
      0.026525219902396202,
      0.04527578502893448,
      0.0012788496678695083,
      0.04283703863620758,
      -0.009150550700724125,
      -0.008954410441219807,
      -0.0421842485666275,
      0.08206667006015778,
      -0.028390858322381973,
      0.05722622945904732,
      0.01859375834465027,
      -0.0018244798993691802,
      0.033700987696647644,
      0.0821908712387085,
      -0.07542390376329422,
      0.006086940411478281,
      0.046929098665714264,
      0.017955780029296875,
      0.03658356890082359,
      0.07577813416719437,
      0.06562422215938568,
      -0.04553169384598732,
      -0.010319480672478676,
      -0.09155663847923279,
      0.0040397788397967815,
      -0.0031715109944343567,
      0.06000354140996933,
      -0.12260328978300095,
      -0.02086479403078556,
      -0.03579862415790558,
      -0.022333212196826935,
      -0.017535315826535225,
      0.062300439924001694,
      -0.009121478535234928,
      -0.010075370781123638,
      -0.0015576723963022232,
      -0.018708666786551476,
      -0.024619735777378082,
      -0.003458052407950163,
      -0.041292235255241394,
      -0.05496950447559357,
      -0.04268550127744675,
      -0.04513586685061455,
      -0.018072204664349556,
      0.09396238625049591,
      -0.05307883024215698,
      -0.012702279724180698,
      0.027385346591472626,
      0.0067114802077412605,
      -0.02987579256296158,
      0.04107150435447693,
      0.08560789376497269,
      0.040677204728126526,
      -0.09825369715690613,
      -0.038178808987140656,
      0.049936722964048386,
      -0.005084646865725517,
      -0.009939590469002724,
      0.06234351173043251,
      -0.010708951391279697,
      0.0768478512763977,
      -0.004154175519943237,
      0.04190973564982414,
      -0.03481088578701019,
      0.0009144873474724591,
      -0.07001299411058426,
      -0.012625819072127342,
      -0.0012205846142023802,
      -0.01561247929930687,
      -0.026994401589035988,
      -0.0448637455701828,
      -0.01358353067189455,
      0.0978190079331398,
      -0.0599430613219738,
      -0.08011520653963089,
      -0.004339522682130337,
      -0.035817574709653854,
      0.053855814039707184,
      0.000868720468133688,
      0.07555410265922546,
      0.036089688539505005,
      0.057819366455078125,
      0.07524139434099197,
      0.015158755704760551,
      -0.11678413301706314,
      -0.0021218375768512487,
      0.015452992171049118,
      0.0307901781052351,
      0.0013556737685576081,
      -0.11453617364168167,
      -0.04289652779698372,
      -0.024352414533495903,
      -0.021549561992287636,
      0.022076085209846497,
      0.047405265271663666,
      0.0018981057219207287,
      0.007556533440947533,
      0.038077462464571,
      -0.015589903108775616,
      -0.05410032719373703,
      -0.011189576238393784,
      0.03447926789522171,
      -0.007593273650854826,
      -0.051634978502988815,
      0.015787389129400253,
      -0.03172251954674721,
      0.049923669546842575,
      -0.05806473270058632,
      0.019947823137044907,
      -0.04404990002512932,
      0.04054110124707222,
      0.011819024570286274,
      -0.053057316690683365,
      0.05546511709690094,
      -0.019505467265844345,
      0.11721058934926987,
      -0.0015399460680782795,
      0.04079744964838028,
      -0.001654451247304678,
      0.02526669390499592,
      0.015335293486714363,
      -0.028323663398623466,
      0.05324305593967438,
      0.023308197036385536,
      -0.017105046659708023,
      -0.035696230828762054,
      -0.06863827258348465,
      0.02649623155593872,
      0.043204352259635925,
      -0.012328137643635273,
      -0.0014938551466912031,
      0.07090596854686737,
      -0.02341540716588497,
      -0.017829233780503273,
      0.01821216568350792,
      -0.013891410082578659,
      -0.04316326975822449,
      0.04609055817127228,
      0.12215722352266312,
      0.030583469197154045,
      -0.04516350105404854,
      -0.04619913920760155,
      -0.022217687219381332,
      -0.010972270742058754,
      -0.09995011240243912,
      -0.022510742768645287,
      0.05065193772315979,
      0.003377571702003479,
      -0.024074256420135498,
      -0.0728917270898819,
      0.01944832131266594,
      0.0877237468957901,
      0.00025060883490368724,
      -0.006807957775890827,
      -0.028349313884973526,
      0.029585300013422966,
      0.013047240674495697,
      0.008481726050376892,
      0.006126618478447199,
      0.0030258027836680412,
      0.02567877061665058,
      -0.04329865425825119,
      0.04823802784085274,
      -0.010583830997347832,
      0.05472267419099808,
      -0.04377605393528938,
      0.06497400999069214,
      0.004922760650515556,
      -0.019575651735067368,
      -0.0014747579116374254,
      0.02800634503364563,
      -0.013288725167512894,
      -0.06183760240674019,
      -0.08657927066087723,
      0.00025488287792541087,
      0.055718760937452316,
      0.017519768327474594,
      -0.024781526997685432,
      -0.07936253398656845,
      -0.05974062904715538,
      -0.0046082474291324615,
      0.036133602261543274,
      0.00882338173687458,
      0.0025101464707404375,
      0.02724386192858219,
      -0.0335787869989872,
      -0.08360818773508072,
      -0.05603404343128204,
      -0.10635235160589218,
      0.005373284686356783,
      -0.06059004366397858,
      -0.019468633458018303,
      0.07102468609809875,
      0.03152092918753624,
      -0.0047345468774437904,
      -0.02812645211815834,
      -0.017350738868117332,
      0.11217401176691055,
      0.04752974212169647,
      -0.041726548224687576,
      -0.027552025392651558,
      -0.017263704910874367,
      -0.012502237223088741,
      -0.08211671561002731,
      0.030412698164582253,
      0.0018785673892125487,
      0.05552905052900314,
      0.12227105349302292,
      -0.001974761951714754,
      0.007338223047554493,
      -0.11318212002515793,
      -0.006871976424008608,
      0.04017054662108421,
      0.010898641310632229,
      -0.029383782297372818,
      -0.05540576949715614,
      -0.05109817907214165,
      -0.01108503993600607,
      0.034588925540447235,
      -0.042580921202898026,
      0.0024489073548465967,
      -0.010988744907081127,
      -0.05990671366453171,
      0.054675113409757614,
      0.06637744605541229,
      -0.020496847108006477,
      0.0225540641695261,
      0.014744923450052738,
      -0.037644561380147934,
      -0.013775726780295372,
      -0.06079374998807907,
      -0.010605179704725742,
      -0.07939911633729935,
      0.07345067709684372,
      -0.05761902034282684,
      -0.0285964235663414,
      0.008420044556260109,
      -0.022600317373871803,
      -0.004335785284638405,
      -0.08399206399917603,
      -0.08845138549804688,
      0.06453023850917816,
      0.030079839751124382,
      0.05891925096511841,
      0.005648509133607149,
      -0.025236813351511955,
      0.06097062677145004,
      0.04414844512939453,
      -0.08809680491685867,
      -0.017822183668613434,
      0.027337506413459778,
      0.028415141627192497,
      -0.05274773761630058,
      -0.029378384351730347,
      0.020628465339541435,
      -0.047487374395132065,
      0.012664882466197014,
      0.011175115592777729,
      0.037228237837553024,
      -0.03500928357243538,
      0.031031044200062752,
      -0.05018465965986252,
      0.02446831203997135,
      -0.028237545862793922,
      0.05196746811270714,
      -0.04449881985783577,
      -0.0765480175614357,
      -0.015050043351948261,
      0.030887939035892487,
      0.022272830829024315,
      -0.02820872701704502,
      -0.03147117793560028,
      -0.05923972278833389,
      0.04056360945105553,
      0.02794630452990532,
      0.024048378691077232,
      -0.029222412034869194,
      -0.038936808705329895,
      0.041288599371910095,
      -0.04152454063296318,
      -0.09746566414833069,
      -0.029645489528775215,
      -0.0230596624314785,
      0.04153084754943848,
      0.03137620538473129,
      0.01562882587313652,
      0.03082893416285515,
      0.05572032555937767,
      0.06021413952112198,
      0.004256045911461115,
      0.024683216586709023,
      -0.017422106117010117,
      0.005322614684700966,
      -0.021832607686519623,
      -0.010016076266765594,
      0.020021164789795876,
      0.04487772658467293,
      -0.005581344477832317,
      -0.004763864912092686,
      -0.013770339079201221,
      -0.02689715288579464,
      0.0028095566667616367,
      0.02544575184583664,
      0.014123992063105106,
      0.022912180051207542,
      -0.01835542358458042,
      -0.08461382985115051,
      -0.090059295296669,
      0.0013888019602745771,
      0.010520689189434052,
      0.04515833035111427,
      0.05888016149401665,
      -0.016775675117969513,
      0.007545104715973139,
      0.12765495479106903,
      -0.08051812648773193,
      -0.03152160719037056,
      -0.041929010301828384,
      -0.034123897552490234,
      0.01466278824955225,
      0.02715378627181053,
      0.03656143695116043,
      0.02133740484714508,
      0.0001863602374214679,
      -0.042235516011714935,
      0.029520085081458092,
      0.06169915571808815,
      0.021402664482593536,
      -0.017046213150024414,
      0.033365391194820404,
      -0.0039506955072283745,
      0.10584557801485062,
      0.07298991084098816,
      0.06331975013017654,
      0.007495492231100798,
      0.06280485540628433,
      -0.03513019531965256,
      -0.09600669145584106,
      0.02361639402806759,
      -0.026778411120176315,
      0.09073235094547272,
      -0.016404278576374054,
      -0.010412003844976425,
      -0.07792286574840546,
      0.040406450629234314,
      0.041802629828453064,
      0.024698354303836823,
      0.015000448562204838,
      0.01376679539680481,
      -0.014746211469173431,
      0.0015218732878565788,
      -0.07513835281133652,
      -0.0644170492887497,
      0.002936654258519411,
      0.04642071574926376,
      -0.015919256955385208,
      -0.02365129068493843,
      0.05573615804314613,
      0.061085108667612076,
      -0.04533907771110535,
      -0.04293590039014816,
      0.05595797300338745,
      -0.011710668914020061,
      0.09232153743505478,
      0.051565151661634445,
      -0.07897964119911194,
      -0.024335207417607307,
      -0.010362000204622746,
      -0.004659152589738369,
      -0.0019665497820824385,
      0.0349380262196064,
      0.01189839094877243,
      0.014616681262850761,
      0.0116741843521595,
      -0.0033172655384987593,
      -0.0044756741262972355,
      0.006978380493819714,
      -0.006611115299165249,
      -0.04071832448244095,
      -0.06924566626548767
    ],
    [
      -0.061682023108005524,
      -0.024477601051330566,
      -0.03263147547841072,
      0.012979884631931782,
      0.08356666564941406,
      -0.042961858212947845,
      -0.0799945816397667,
      0.05882332846522331,
      -0.0006630558636970818,
      -0.054791420698165894,
      0.07469290494918823,
      0.030806176364421844,
      -0.04160311818122864,
      -0.04214692488312721,
      -0.04536798968911171,
      -0.015037867240607738,
      -0.03492537885904312,
      0.006268058903515339,
      0.02069873735308647,
      0.023033300414681435,
      -0.05745720863342285,
      0.03243229165673256,
      0.01026634406298399,
      -0.06859838217496872,
      0.07589446008205414,
      0.047026753425598145,
      0.06478513032197952,
      -0.04716099426150322,
      -0.056875135749578476,
      -0.002604428678750992,
      0.04536383971571922,
      -0.01207710150629282,
      0.0374637134373188,
      0.004435956012457609,
      -0.03204420581459999,
      0.03546750172972679,
      -0.029555216431617737,
      -0.03708009049296379,
      -0.04306086152791977,
      -0.025621095672249794,
      0.061961811035871506,
      0.023309269919991493,
      -0.04642433300614357,
      0.014965391717851162,
      0.06750035285949707,
      -0.026575151830911636,
      0.02780226618051529,
      -0.010900051333010197,
      0.04372290149331093,
      0.013847678899765015,
      -0.0553952120244503,
      -0.026359807699918747,
      0.04183531925082207,
      0.048094574362039566,
      -0.05591154843568802,
      -0.054621316492557526,
      -0.036966219544410706,
      0.010624113492667675,
      0.013082414865493774,
      0.01480819471180439,
      0.003012527246028185,
      0.016585445031523705,
      -0.015879588201642036,
      -0.04282074049115181,
      0.06724130362272263,
      0.02901003323495388,
      0.04713575541973114,
      -0.05550805851817131,
      0.04132746532559395,
      -0.10580487549304962,
      0.056328218430280685,
      0.037147000432014465,
      0.006376211065798998,
      0.0164908766746521,
      -0.021561114117503166,
      0.016516678035259247,
      -0.009517552331089973,
      -0.07141673564910889,
      -0.09902239590883255,
      0.059897467494010925,
      0.015744201838970184,
      -0.046320851892232895,
      -0.03455141559243202,
      -0.05717957392334938,
      -0.07299768924713135,
      0.026512375101447105,
      0.03790070116519928,
      -0.020624510943889618,
      0.03785267099738121,
      0.0014696505386382341,
      0.03231487050652504,
      0.07256927341222763,
      -0.009002207778394222,
      -0.024270419031381607,
      -0.009417545050382614,
      0.013819013722240925,
      -0.029923977330327034,
      0.05237603560090065,
      0.009813923388719559,
      -0.07701309025287628,
      -0.028315309435129166,
      0.014098460786044598,
      -0.020919062197208405,
      0.015035358257591724,
      -0.06547721475362778,
      0.04033351317048073,
      0.018862798810005188,
      -0.051944341510534286,
      0.07116179913282394,
      -0.0207526832818985,
      0.032154981046915054,
      0.0014844551915302873,
      -0.04428298771381378,
      0.04684440419077873,
      0.061834368854761124,
      -0.010605537332594395,
      0.01570645533502102,
      -0.07315176725387573,
      -0.022816572338342667,
      0.008760955184698105,
      0.05965118855237961,
      -0.025508878752589226,
      0.007594696711748838,
      0.021907726302742958,
      0.004316102247685194,
      0.12838517129421234,
      0.060393400490283966,
      -0.020359549671411514,
      -0.046400684863328934,
      0.0031897572334855795,
      -0.02040443755686283,
      -0.031199589371681213,
      0.06280753761529922,
      -0.021541858091950417,
      -0.020531730726361275,
      0.04236745089292526,
      -0.04531450942158699,
      -0.043942246586084366,
      -0.10676532983779907,
      0.008885935880243778,
      -0.02799266204237938,
      0.029962098225951195,
      -0.018629830330610275,
      0.07209087908267975,
      -0.057110004127025604,
      -0.13843539357185364,
      -0.0549345500767231,
      0.005189001094549894,
      0.0477217398583889,
      -0.036479849368333817,
      -0.017764100804924965,
      -0.02473655343055725,
      0.030450237914919853,
      0.049639005213975906,
      0.06307979673147202,
      -0.021643558517098427,
      0.011944453231990337,
      0.019526172429323196,
      0.01666203700006008,
      0.039415668696165085,
      0.05973288416862488,
      -0.05601057410240173,
      -0.012258249334990978,
      -0.05910926312208176,
      -0.0013003525091335177,
      0.017570314928889275,
      -0.040988195687532425,
      -0.016438951715826988,
      0.05746849626302719,
      0.08989860862493515,
      0.01175048016011715,
      0.05639147013425827,
      0.006613702978938818,
      -0.08374129235744476,
      -0.029435019940137863,
      0.011791235767304897,
      -0.025687577202916145,
      0.029692815616726875,
      -0.029582377523183823,
      -0.0754939466714859,
      -0.0020052604377269745,
      -0.017517561092972755,
      0.007001037243753672,
      0.052325084805488586,
      0.08851294219493866,
      -0.005653981119394302,
      -0.031586166471242905,
      0.0541728138923645,
      -0.05302657559514046,
      -0.11429537087678909,
      -0.022004319354891777,
      -0.008957117795944214,
      0.015998518094420433,
      -0.006935431156307459,
      -0.04598880559206009,
      0.021740537136793137,
      -0.03171853348612785,
      -0.046034205704927444,
      0.07822796702384949,
      -0.022856097668409348,
      -0.04220432788133621,
      -0.016737882047891617,
      -0.10154175013303757,
      -0.028042512014508247,
      0.01464245468378067,
      0.02091613970696926,
      -0.00025890086544677615,
      0.048178210854530334,
      -0.06842448562383652,
      -0.021806342527270317,
      0.03399065509438515,
      0.026339733973145485,
      -0.08852997422218323,
      0.008231016807258129,
      0.029431328177452087,
      -0.00016708286420907825,
      -0.03341458737850189,
      -0.034910932183265686,
      -0.016918553039431572,
      0.01946154609322548,
      -0.05255194008350372,
      -0.008568475022912025,
      -0.030568374320864677,
      0.031760670244693756,
      -0.012127600610256195,
      -0.011087046936154366,
      0.008355391211807728,
      0.031338732689619064,
      -0.04941154643893242,
      -0.017763452604413033,
      -0.042754605412483215,
      0.03582668676972389,
      -0.02996378391981125,
      -0.07312783598899841,
      -0.005529053974896669,
      0.06556185334920883,
      0.02915537729859352,
      0.005482078064233065,
      0.027668355032801628,
      0.0465720035135746,
      -0.029401184991002083,
      -0.0008038106607273221,
      -0.059832096099853516,
      0.03467238321900368,
      -0.06217625364661217,
      0.059775929898023605,
      0.025518283247947693,
      -0.0013637000229209661,
      -0.0655844584107399,
      0.016224587336182594,
      -0.07261832058429718,
      -0.04984765127301216,
      0.022483929991722107,
      0.00916303601115942,
      0.011651996523141861,
      -0.0004071552539244294,
      0.002519317204132676,
      0.006918060593307018,
      -0.012203126214444637,
      -0.06436759233474731,
      0.03849159926176071,
      0.05031904950737953,
      -0.025718482211232185,
      0.03355422616004944,
      0.03595530986785889,
      -0.07244883477687836,
      -0.023282485082745552,
      0.03169913589954376,
      -0.015898847952485085,
      0.07286684215068817,
      0.13364602625370026,
      -0.07870139926671982,
      0.10318318009376526,
      -0.05700254812836647,
      0.029056623578071594,
      -0.018559584394097328,
      0.07545360922813416,
      -0.03536837548017502,
      0.006923919543623924,
      -0.0005535404197871685,
      0.014623240567743778,
      -0.06667938083410263,
      -0.06450206786394119,
      0.0015927600907161832,
      -0.02280217967927456,
      -0.004972793627530336,
      -0.02113247476518154,
      0.010091863572597504,
      0.01776028610765934,
      -0.011980511248111725,
      0.03020874224603176,
      -0.00579415075480938,
      -0.0004416120355017483,
      0.025424771010875702,
      -0.01790136843919754,
      -0.003914074506610632,
      -0.016245104372501373,
      0.05870065838098526,
      0.05349745601415634,
      -0.0630582794547081,
      -0.006552096456289291,
      -0.059374112635850906,
      0.013026413507759571,
      -0.03719337284564972,
      0.000852513883728534,
      -0.023233961313962936,
      0.01748897135257721,
      0.06355738639831543,
      -0.012121150270104408,
      -0.06997653841972351,
      0.01676342636346817,
      -0.03505990281701088,
      -0.03507170081138611,
      0.008057797327637672,
      -0.007456887513399124,
      -0.13757984340190887,
      -0.05815025791525841,
      -0.017122020944952965,
      0.025208227336406708,
      -0.04341473802924156,
      -0.03399493917822838,
      0.029649807140231133,
      -0.006598536390811205,
      -0.08238159120082855,
      -0.037007950246334076,
      0.019421733915805817,
      -0.00900008250027895,
      0.03654276207089424,
      0.010382523760199547,
      -0.03418157622218132,
      -0.0031531720887869596,
      -0.011844012886285782,
      -0.016049230471253395,
      0.02080133929848671,
      -0.11038118600845337,
      0.024612143635749817,
      0.03650689870119095,
      -0.0778435543179512,
      0.039717692881822586,
      0.031285885721445084,
      0.03315829485654831,
      -0.03360011801123619,
      0.020944422110915184,
      0.003657215042039752,
      -0.0322030708193779,
      -0.07801688462495804,
      -0.0013033655704930425,
      -0.04028854891657829,
      0.05890614911913872,
      0.0031321761198341846,
      0.0384155809879303,
      -0.03171805664896965,
      0.0016377810388803482,
      0.01855495758354664,
      -0.014498726464807987,
      -0.07191450893878937,
      -0.031536318361759186,
      0.01459847018122673,
      0.038219328969717026,
      -0.08716343343257904,
      0.040572140365839005,
      -0.012875615619122982,
      0.04611372947692871,
      0.05119392275810242,
      0.00327064236626029,
      0.029086247086524963,
      0.06716080754995346,
      -0.04192541539669037,
      0.003844773629680276,
      -0.00966532714664936,
      -0.01448833104223013,
      0.0411846749484539,
      -0.025466300547122955,
      0.0059717693366110325,
      -0.0971907526254654,
      -0.0298137404024601,
      0.03481040149927139,
      0.04097763076424599,
      -0.015162977389991283,
      -0.06096852198243141,
      0.012949524447321892,
      0.0585792176425457,
      0.004217986483126879,
      -0.014574693515896797,
      -0.11218976229429245,
      -0.027488697320222855,
      -0.09327898174524307,
      0.030165502801537514,
      -0.06302816420793533,
      -0.047609686851501465,
      0.06099115312099457,
      0.019034016877412796,
      0.007391011342406273,
      -0.10347156971693039,
      -0.027298593893647194,
      -0.029445992782711983,
      -0.09881389886140823,
      0.054812319576740265,
      -0.007218832150101662,
      0.0006911017117090523,
      -0.014359168708324432,
      -0.017486974596977234,
      0.06840165704488754,
      -0.018654845654964447,
      -0.03828110545873642,
      0.040286533534526825,
      -0.04486576467752457,
      -0.010864945128560066,
      0.016544703394174576,
      0.053014107048511505,
      -0.024288535118103027,
      0.028589386492967606,
      0.057748667895793915,
      -0.06221241503953934,
      0.0320383682847023,
      -0.032106418162584305,
      -0.009331739507615566,
      -0.03346757963299751,
      0.1323579102754593,
      0.004036423284560442,
      -0.05871642753481865,
      -0.04684203863143921,
      -0.08401104062795639,
      0.04421628639101982,
      0.007514080498367548,
      -0.03586926683783531,
      -0.06193544343113899,
      -0.054044727236032486,
      0.004297322127968073,
      0.007745975162833929,
      0.013611342757940292,
      -0.07838864624500275,
      0.020205054432153702,
      0.07358068227767944,
      0.007594575639814138,
      -0.08343544602394104,
      -0.00038855080492794514,
      0.12479886412620544,
      -0.048036690801382065,
      -0.05952421575784683,
      -0.017340566962957382,
      -0.05867636203765869,
      -0.0013377444120123982,
      0.0128407534211874,
      0.0009723477996885777,
      -0.002437193412333727,
      0.012189975008368492,
      0.1014469563961029,
      -0.07104810327291489,
      0.031282421201467514,
      -0.0006130770198069513,
      -0.05319841951131821,
      0.03680282458662987,
      -0.07783804088830948,
      0.038585565984249115,
      -0.010712547227740288,
      0.03591262921690941,
      -0.03744618594646454,
      -0.015063351951539516,
      -0.0071134851314127445,
      -0.0632060095667839,
      -0.05845566466450691,
      0.004267881624400616,
      -0.03668214753270149,
      0.049554113298654556,
      0.030458666384220123,
      -0.05516238138079643,
      -0.05536455288529396,
      0.06290464103221893,
      0.04120831936597824,
      -0.04572422057390213,
      -0.07283315807580948,
      -0.009586677886545658,
      0.0036586637143045664,
      -0.01742960326373577,
      -0.007121284957975149,
      0.10421837866306305,
      0.01721830666065216,
      0.017250454053282738,
      -0.06519109010696411,
      -0.01793878898024559,
      0.025363584980368614,
      -0.04934702068567276,
      -0.0682065486907959,
      -0.010963287204504013,
      -0.01879584789276123,
      0.013908850029110909,
      0.11126424372196198,
      0.08098383992910385,
      -0.018261071294546127,
      -0.033974744379520416,
      -0.018813911825418472,
      -0.03797049820423126,
      0.037785861641168594,
      -0.018763268366456032,
      -0.04300083592534065,
      -0.11820078641176224,
      -0.03747953101992607,
      0.06327953189611435,
      -0.027337847277522087,
      -0.0662769004702568,
      0.06299673020839691,
      0.039557185024023056
    ],
    [
      -0.0009740843088366091,
      -0.05389838665723801,
      0.027580594643950462,
      -0.07743816077709198,
      -0.0012196946190670133,
      0.024524997919797897,
      -0.1038309782743454,
      -0.0209391750395298,
      -0.03450418636202812,
      -0.028173282742500305,
      0.03234691545367241,
      -0.058243244886398315,
      0.07029525190591812,
      0.053834136575460434,
      -0.06923078000545502,
      0.030862469226121902,
      0.034549616277217865,
      0.0008226708159781992,
      -0.028838958591222763,
      0.03371674194931984,
      0.002517911372706294,
      0.007108946796506643,
      -0.08206850290298462,
      -0.003970152698457241,
      0.012664781883358955,
      0.05677888169884682,
      -0.022181635722517967,
      0.02989273890852928,
      -0.039746884256601334,
      -0.051702167838811874,
      -0.08005630970001221,
      0.009907648898661137,
      0.03022443875670433,
      0.0030881441198289394,
      0.0021890914067626,
      -0.12239976972341537,
      0.01301281712949276,
      -0.09011327475309372,
      0.02068270370364189,
      0.020784104242920876,
      -0.007716490887105465,
      -0.12494392693042755,
      -0.06997402012348175,
      0.024421725422143936,
      0.06149568036198616,
      0.04346560314297676,
      0.0154662961140275,
      0.022955724969506264,
      -0.04742925614118576,
      0.01145112980157137,
      -0.040390338748693466,
      0.051933445036411285,
      -0.02069023996591568,
      -0.00012713830801658332,
      0.025428028777241707,
      -0.015067816711962223,
      -0.026890801265835762,
      0.007648843806236982,
      0.011414708569645882,
      -0.017710156738758087,
      0.04428175091743469,
      0.05262096971273422,
      -0.10083232820034027,
      0.06067316234111786,
      -0.08123141527175903,
      -0.01766432449221611,
      0.014802362769842148,
      0.050295595079660416,
      -0.01218667533248663,
      0.05429989844560623,
      -0.009976272471249104,
      -0.007893510162830353,
      0.038106463849544525,
      -0.008148661814630032,
      -0.03462080657482147,
      0.0029741222970187664,
      0.08808852732181549,
      0.004883152898401022,
      -0.026502195745706558,
      0.028367334976792336,
      0.08573376387357712,
      0.007343484554439783,
      -0.00946803018450737,
      -0.02457088604569435,
      0.09866795688867569,
      0.03164736554026604,
      -0.03702676296234131,
      0.03922555595636368,
      0.0988415777683258,
      0.06692836433649063,
      0.0652623400092125,
      -0.007449681404978037,
      0.0006858970737084746,
      -0.09676313400268555,
      -0.012113652192056179,
      0.06184942647814751,
      -0.022275103256106377,
      0.030691860243678093,
      -0.028167562559247017,
      -0.04256417974829674,
      0.024335350841283798,
      0.06162184849381447,
      -0.011068075895309448,
      -0.020430145785212517,
      0.09158610552549362,
      0.047539640218019485,
      -0.033022813498973846,
      0.01747220940887928,
      -0.021937644109129906,
      0.06164690479636192,
      -0.04558423161506653,
      -0.007844856008887291,
      0.06236528232693672,
      0.013595877215266228,
      -0.019141970202326775,
      0.01656477339565754,
      0.0669403001666069,
      -0.0012558423914015293,
      -0.06567303836345673,
      -0.02124597690999508,
      0.019067201763391495,
      0.009507285431027412,
      -0.015286761336028576,
      -0.044213831424713135,
      -0.015535132959485054,
      -0.027493415400385857,
      -0.037121791392564774,
      -0.14282289147377014,
      0.005443190690129995,
      -0.013768423348665237,
      -0.03093193657696247,
      -0.004260815680027008,
      0.016644977033138275,
      -0.015812313184142113,
      -0.030321210622787476,
      0.05112592875957489,
      -0.05877147614955902,
      -0.047129083424806595,
      -0.004458277951925993,
      0.001623700954951346,
      -0.052968624979257584,
      0.029365651309490204,
      0.04801058769226074,
      0.028574425727128983,
      0.012144634500145912,
      -0.06446629017591476,
      -0.028856424614787102,
      0.013804122805595398,
      -0.006342477630823851,
      -0.012334492057561874,
      0.09153023362159729,
      0.021567780524492264,
      0.01888204924762249,
      0.059719301760196686,
      -0.07012486457824707,
      -0.024732505902647972,
      0.06458402425050735,
      0.015573186799883842,
      0.0001284136960748583,
      -0.02305837720632553,
      0.04779205843806267,
      0.06041510030627251,
      0.011143633164465427,
      -0.0064556654542684555,
      -0.00014854036271572113,
      0.005506964400410652,
      -0.011272100731730461,
      0.020282618701457977,
      0.04600055515766144,
      0.03111022524535656,
      0.004210188053548336,
      -0.03832446411252022,
      0.02860981784760952,
      -0.003269761335104704,
      -0.05707412213087082,
      0.0011943966383114457,
      0.02597052790224552,
      -0.10229090601205826,
      0.01773645542562008,
      0.025350421667099,
      -0.07362426817417145,
      0.05295076593756676,
      0.016620734706521034,
      0.002955155447125435,
      0.05480218306183815,
      -0.06285003572702408,
      0.0283694788813591,
      0.0923333540558815,
      -0.030546018853783607,
      -0.09627895057201385,
      -0.09087982028722763,
      0.006566440220922232,
      -0.027612242847681046,
      0.020989414304494858,
      -0.023203428834676743,
      0.0234519150108099,
      0.008471058681607246,
      -0.0642809048295021,
      0.010788238607347012,
      -0.05831928178668022,
      -0.08980628103017807,
      0.0567813366651535,
      -0.05211828649044037,
      0.03189689293503761,
      0.05436578392982483,
      0.029881538823246956,
      0.03498346731066704,
      -0.047028668224811554,
      0.0023129114415496588,
      -0.02965651825070381,
      -0.030230185016989708,
      0.04671751707792282,
      0.05478096753358841,
      0.01499931514263153,
      -0.04419170692563057,
      -0.01783694699406624,
      0.010586489923298359,
      0.028564056381583214,
      0.003872678615152836,
      -0.0357532724738121,
      0.0693514496088028,
      0.01944538578391075,
      -0.061689238995313644,
      0.012232255190610886,
      -0.02737470157444477,
      -0.008324692957103252,
      0.013326317071914673,
      -0.045072492212057114,
      0.0017167875776067376,
      0.0497322604060173,
      0.05116390436887741,
      0.06846293061971664,
      0.059795133769512177,
      -0.019732384011149406,
      -0.04224276542663574,
      0.02493681199848652,
      0.0164813045412302,
      0.07532551139593124,
      -0.012479321099817753,
      0.06892897188663483,
      -0.0010543415555730462,
      -0.06334953755140305,
      -0.07286716252565384,
      0.010462414473295212,
      0.07581806927919388,
      0.05411967635154724,
      -0.04238041862845421,
      -0.009832998737692833,
      -0.07971593737602234,
      -0.003228986170142889,
      0.014723391272127628,
      0.012249919585883617,
      0.035363730043172836,
      -0.09120970964431763,
      0.030925463885068893,
      0.07803446054458618,
      -0.001226640772074461,
      0.0510205440223217,
      0.030191348865628242,
      0.08268773555755615,
      0.053085144609212875,
      0.011948540806770325,
      0.00911292340606451,
      0.030179232358932495,
      -0.018018240109086037,
      0.03520680218935013,
      -0.06727562099695206,
      0.021537618711590767,
      0.07437609881162643,
      0.04687875136733055,
      0.03388223424553871,
      0.008617337793111801,
      -0.06992845237255096,
      -0.008485921658575535,
      0.07426529377698898,
      0.021114792674779892,
      0.03693584352731705,
      -0.013689926825463772,
      0.040539566427469254,
      0.0056082396768033504,
      0.03149634599685669,
      0.010436488315463066,
      0.05387524142861366,
      0.024592187255620956,
      -0.00374910538084805,
      -0.04942530021071434,
      -0.052061401307582855,
      -0.024336518719792366,
      -0.02884736843407154,
      -0.008182928897440434,
      -0.0008356122998520732,
      0.044193085283041,
      0.05872765928506851,
      -0.03197009488940239,
      0.019834253937005997,
      -0.08690331876277924,
      0.0006735504139214754,
      0.08295247703790665,
      0.07068555057048798,
      -0.01329710427671671,
      -0.0054300688207149506,
      -0.0267923716455698,
      0.0012873047962784767,
      -0.04784403741359711,
      -0.005283110775053501,
      0.04114168882369995,
      -0.0018433345248922706,
      -0.057897698134183884,
      0.00818821880966425,
      0.09865657240152359,
      -0.006016801577061415,
      0.0062127914279699326,
      -0.05926986783742905,
      -0.028984511271119118,
      0.03517545387148857,
      0.06019270792603493,
      -0.0463375486433506,
      0.04506135731935501,
      -0.07856547832489014,
      0.0462338924407959,
      -0.017652764916419983,
      -0.010655906982719898,
      -0.004956443794071674,
      -0.047154154628515244,
      0.025049516931176186,
      -0.021540947258472443,
      0.07910722494125366,
      0.02437964454293251,
      -0.02882338874042034,
      -0.005468552466481924,
      0.008733853697776794,
      -0.016066335141658783,
      0.02737041376531124,
      0.08254215866327286,
      -0.01767561212182045,
      -0.014615122228860855,
      0.01255722064524889,
      -0.029161423444747925,
      0.010513699613511562,
      -0.019387902691960335,
      0.057017240673303604,
      -0.01514896284788847,
      0.019276605919003487,
      0.05128089338541031,
      0.056191932410001755,
      -0.016966694965958595,
      0.007141054142266512,
      -0.0472012422978878,
      0.028769366443157196,
      -0.010303651914000511,
      0.018648920580744743,
      0.06360161304473877,
      0.024273015558719635,
      -0.053104739636182785,
      -0.007084354292601347,
      -0.03204694017767906,
      -0.046324245631694794,
      -0.03246772289276123,
      -0.06478588283061981,
      0.008168651722371578,
      0.06020312011241913,
      -0.09683539718389511,
      -0.039123427122831345,
      -0.00513166468590498,
      0.04712167754769325,
      -0.015815142542123795,
      -0.053143687546253204,
      -0.08270619809627533,
      0.062655508518219,
      -0.033749911934137344,
      0.010766563005745411,
      -0.04038897156715393,
      -0.02920176275074482,
      0.05908047407865524,
      0.0012740237871184945,
      -0.046793606132268906,
      -0.007828285917639732,
      -0.04008737951517105,
      0.017292531207203865,
      -0.061637066304683685,
      0.04352376610040665,
      0.026614680886268616,
      0.040290772914886475,
      -0.002419582800939679,
      -0.03328894451260567,
      0.017130622640252113,
      -0.044852886348962784,
      -0.02409624308347702,
      -0.06174439936876297,
      0.06567905843257904,
      0.04226125776767731,
      0.058579642325639725,
      0.020008528605103493,
      0.01929383911192417,
      -0.014576403424143791,
      -0.040033407509326935,
      -0.03558414801955223,
      0.057466309517621994,
      0.028596099466085434,
      0.07611110806465149,
      -0.07411535084247589,
      -0.026238303631544113,
      0.04935299977660179,
      0.07098312675952911,
      -0.06140274927020073,
      -0.05009321868419647,
      -0.017602842301130295,
      -0.07308486104011536,
      -0.022632768377661705,
      -0.07467716187238693,
      0.006166599225252867,
      0.10121043771505356,
      0.08700891584157944,
      0.07056304812431335,
      0.058091554790735245,
      0.002716441871598363,
      -0.026134401559829712,
      0.014607422985136509,
      -0.008140377700328827,
      0.01993406191468239,
      0.025162072852253914,
      -0.0359942652285099,
      -0.0015411714557558298,
      0.04174612835049629,
      0.09104540199041367,
      0.013110194355249405,
      -0.09156663715839386,
      0.06568022817373276,
      0.041221316903829575,
      -0.03445357829332352,
      0.017552021890878677,
      -0.10756367444992065,
      0.02993512526154518,
      0.00034391440567560494,
      0.03017597086727619,
      0.035584043711423874,
      0.01242218166589737,
      0.00893362145870924,
      -0.0708562359213829,
      0.009325554594397545,
      -0.08517514169216156,
      -0.021693343296647072,
      0.01588154397904873,
      0.013570255599915981,
      0.0843922570347786,
      0.06292709708213806,
      0.020627010613679886,
      -0.052308037877082825,
      -0.06401176750659943,
      0.1259361356496811,
      0.020464187487959862,
      -0.059357043355703354,
      -0.058433037251234055,
      0.039931051433086395,
      -0.02824294939637184,
      -0.047920968383550644,
      0.004833831451833248,
      -0.007637265603989363,
      0.08115333318710327,
      0.03769204393029213,
      0.03271547704935074,
      0.04788346216082573,
      -0.030072856694459915,
      -0.028370702639222145,
      0.021680939942598343,
      -0.058844756335020065,
      0.033476486802101135,
      -0.08545830100774765,
      0.0473473034799099,
      0.0501360185444355,
      0.030581464990973473,
      -0.02513260766863823,
      0.07789140939712524,
      0.009579629637300968,
      0.05929221585392952,
      -0.0690414234995842,
      -0.04478967934846878,
      0.0010213973000645638,
      0.0017016991041600704,
      0.024728838354349136,
      -0.10579296946525574,
      0.02864583395421505,
      -0.05290224403142929,
      -0.06885730475187302,
      0.0032711608801037073,
      -0.038013819605112076,
      -0.04576968401670456,
      0.028766145929694176,
      -0.0347309336066246,
      0.09421545267105103,
      -0.03250538185238838,
      0.04441213607788086,
      0.005669786129146814,
      -0.03500049188733101,
      -0.027216743677854538,
      0.05610862001776695,
      0.08218143880367279,
      0.015972182154655457,
      0.0870092511177063,
      -0.019825559109449387,
      0.04949389025568962,
      0.03221386671066284,
      -0.03365454822778702
    ],
    [
      -0.06680061668157578,
      0.004528914578258991,
      0.039239250123500824,
      0.008681053295731544,
      -0.05364266410470009,
      0.0656767338514328,
      -0.057710688561201096,
      0.07069481164216995,
      -0.00259411777369678,
      -0.03701375797390938,
      0.0327100045979023,
      0.033944450318813324,
      -0.018684981390833855,
      -0.038772691041231155,
      0.00777836749330163,
      -0.07934054732322693,
      -0.012585540302097797,
      -0.024217376485466957,
      0.057830359786748886,
      -0.029942382127046585,
      -0.04480331018567085,
      0.10474361479282379,
      0.05286109820008278,
      0.031573351472616196,
      0.08959384262561798,
      -0.02033214457333088,
      -0.014732460491359234,
      -0.0860782042145729,
      -0.0038019162602722645,
      -0.09533008933067322,
      0.09370628744363785,
      0.02915123477578163,
      0.03932148963212967,
      0.029813716188073158,
      -0.013605830259621143,
      0.033550143241882324,
      -0.0030555478297173977,
      0.011511444114148617,
      -0.012459429912269115,
      0.06440132111310959,
      0.026618996635079384,
      -0.01965106837451458,
      -0.05925555154681206,
      -0.017269713804125786,
      0.008802400901913643,
      -0.007807313464581966,
      0.03593719005584717,
      0.009136523120105267,
      0.06603874266147614,
      -0.027941375970840454,
      -0.08586463332176208,
      0.038780663162469864,
      -0.008434356190264225,
      0.028584621846675873,
      0.04357147961854935,
      0.006695393472909927,
      0.061579011380672455,
      -0.013887555338442326,
      0.04057448357343674,
      0.005923801101744175,
      -0.0033722843509167433,
      0.027349231764674187,
      -0.004234757274389267,
      -0.027363363653421402,
      -0.0040635643526911736,
      0.04412269592285156,
      -0.09131378680467606,
      0.0139114735648036,
      -0.038124360144138336,
      -0.04671443998813629,
      -0.07809669524431229,
      -0.02815760299563408,
      -0.04378104954957962,
      -0.03771164268255234,
      0.03379962593317032,
      0.014676210470497608,
      0.009158723056316376,
      -0.07378823310136795,
      -0.08514684438705444,
      0.02077321708202362,
      0.02194472774863243,
      0.0778065100312233,
      0.0009340587421320379,
      0.02504030056297779,
      -0.045423008501529694,
      0.0667785033583641,
      -0.04858862981200218,
      -0.0754435732960701,
      -0.04640153422951698,
      -0.05462677776813507,
      -0.007416503969579935,
      -0.004182971082627773,
      0.01846420206129551,
      0.050017062574625015,
      -0.02132023312151432,
      0.07687628269195557,
      0.030362695455551147,
      -0.00852530263364315,
      -0.08046465367078781,
      -0.07482752203941345,
      0.07830467820167542,
      -0.06392313539981842,
      0.0033099465072155,
      0.006812856066972017,
      0.03559493273496628,
      -0.04285021126270294,
      0.03122200258076191,
      -0.017995089292526245,
      -0.06381876021623611,
      -0.033805012702941895,
      0.08136177808046341,
      0.03945336118340492,
      -0.010932727716863155,
      -0.019727496430277824,
      0.03458935022354126,
      0.01111643947660923,
      0.002373969880864024,
      0.1437128782272339,
      -0.006401948165148497,
      0.05685282498598099,
      0.007750546094030142,
      -0.06793922930955887,
      0.08913270384073257,
      -0.040383368730545044,
      0.027733318507671356,
      -0.02410924807190895,
      0.02429773285984993,
      -0.0718412771821022,
      -0.024413980543613434,
      -0.0037454338744282722,
      -0.0735095664858818,
      0.11581499129533768,
      0.01360833179205656,
      -0.0432034432888031,
      -0.015792926773428917,
      -0.052158668637275696,
      -0.023962784558534622,
      0.08036908507347107,
      -0.0015072423266246915,
      -0.06746454536914825,
      0.03165462613105774,
      -0.05788026005029678,
      0.0705130398273468,
      -0.018791615962982178,
      -0.05379249155521393,
      -0.04486524686217308,
      -0.03280634805560112,
      -0.006298337131738663,
      0.001955752493813634,
      0.03833504393696785,
      -0.00729352468624711,
      -0.03444964811205864,
      -0.10544534027576447,
      0.04978764429688454,
      -0.0038743617478758097,
      0.06993211805820465,
      0.008165336214005947,
      -0.0576142892241478,
      -0.006628312170505524,
      0.03528069332242012,
      0.021950528025627136,
      0.027789602056145668,
      -0.047259699553251266,
      -0.015998762100934982,
      -0.08186736702919006,
      -0.016275353729724884,
      0.05453858897089958,
      0.022641975432634354,
      -0.052020177245140076,
      -0.022557174786925316,
      0.012962691485881805,
      -0.02188034914433956,
      -0.07043410837650299,
      0.09069417417049408,
      0.005607531871646643,
      -0.08189015835523605,
      0.014392446726560593,
      -0.07826164364814758,
      -0.04188641160726547,
      0.031942084431648254,
      -0.09868752956390381,
      -0.0017519620014354587,
      -0.06334236264228821,
      0.0816679447889328,
      0.049331750720739365,
      -0.04523136839270592,
      -0.043910082429647446,
      -0.005656943190842867,
      0.0052823093719780445,
      -0.018200373277068138,
      0.04620261490345001,
      -0.04651864990592003,
      -0.01819085329771042,
      -0.09055629372596741,
      -0.021104460582137108,
      -0.07546133548021317,
      -0.07920094579458237,
      0.07795906811952591,
      0.03742821887135506,
      -0.06197700276970863,
      0.06137215718626976,
      -0.010391151532530785,
      -0.03340466320514679,
      0.003481908468529582,
      -0.041740015149116516,
      0.01919817551970482,
      -0.0361204519867897,
      0.012548618018627167,
      -0.09385993331670761,
      0.03028205968439579,
      0.002806880511343479,
      0.012547996826469898,
      0.014249748550355434,
      -0.00567564507946372,
      -0.0221693217754364,
      0.011153306812047958,
      0.03162834793329239,
      -0.006479911506175995,
      -0.025717975571751595,
      -0.0142586138099432,
      0.022596308961510658,
      0.02146567590534687,
      -0.03879034146666527,
      -0.02748103439807892,
      0.039290376007556915,
      -0.02177336812019348,
      -0.000930225767660886,
      -0.04340974614024162,
      -0.04903482645750046,
      -0.0755830854177475,
      0.03438446298241615,
      -0.04563008248806,
      0.003378561232239008,
      -0.0035841374192386866,
      0.09600961953401566,
      0.014148845337331295,
      0.0065686749294400215,
      -0.03549804165959358,
      -0.04557626694440842,
      0.024730131030082703,
      -0.07176563888788223,
      -0.030109737068414688,
      0.06469942629337311,
      0.05299590528011322,
      0.037341583520174026,
      0.03648080676794052,
      0.04184047505259514,
      -0.05331121385097504,
      0.0899970754981041,
      -0.012214711867272854,
      0.08773095905780792,
      -0.0005259565077722073,
      -0.020866690203547478,
      -0.03959519788622856,
      -0.051053594797849655,
      -0.08879851549863815,
      0.05225314572453499,
      -0.05484987795352936,
      -0.031661257147789,
      0.031655944883823395,
      -0.006880769971758127,
      -0.03742310777306557,
      0.028409644961357117,
      -0.019029665738344193,
      0.0013331061927601695,
      -0.041441887617111206,
      0.0018338430672883987,
      -0.050790973007678986,
      -0.0029509044252336025,
      0.07537767291069031,
      0.0018938608700409532,
      0.03790802136063576,
      -0.0553341843187809,
      0.014484027400612831,
      -0.15763741731643677,
      -0.031538091599941254,
      0.06856215000152588,
      -0.03144725412130356,
      -0.006611283402889967,
      0.07746390998363495,
      0.04419473931193352,
      0.04423748329281807,
      0.012855832464993,
      0.031903766095638275,
      -0.06650939583778381,
      -0.007809920236468315,
      0.024990463629364967,
      0.07967320084571838,
      -0.030386734753847122,
      -0.017246222123503685,
      -0.0111890509724617,
      -0.01576901413500309,
      0.020469000563025475,
      0.0234353207051754,
      -0.007006149273365736,
      -0.08834009617567062,
      0.046097345650196075,
      -0.022464759647846222,
      0.014606449753046036,
      -0.07126447558403015,
      -0.0003995574079453945,
      -0.006535060703754425,
      0.1060333251953125,
      -0.04924827814102173,
      0.010069077834486961,
      -0.02410861849784851,
      0.006291733589023352,
      0.009036953561007977,
      -0.059132013469934464,
      0.043511442840099335,
      -0.03053594008088112,
      -0.029442308470606804,
      -0.0006256873020902276,
      0.0062318965792655945,
      0.032871026545763016,
      -0.022535331547260284,
      0.03275283798575401,
      -0.02689502388238907,
      -0.07302858680486679,
      -0.10932181030511856,
      0.03294975683093071,
      -0.01705138571560383,
      0.0795602947473526,
      -0.08007630705833435,
      -0.03503852337598801,
      -0.0301503948867321,
      -0.05702275037765503,
      -0.021016564220190048,
      -0.017277970910072327,
      -0.08133811503648758,
      -0.011770152486860752,
      0.020284786820411682,
      -0.029545685276389122,
      0.012690022587776184,
      0.008632254786789417,
      -0.07338736951351166,
      0.03415633365511894,
      -0.05671766772866249,
      -0.03434237465262413,
      -0.02825305238366127,
      -0.0038426264654845,
      -0.011767638847231865,
      -0.0034401672892272472,
      -0.027744881808757782,
      0.025231335312128067,
      -0.04866888374090195,
      0.04093870148062706,
      0.024920344352722168,
      -0.009295951575040817,
      0.05696704611182213,
      0.08418410271406174,
      0.058892153203487396,
      0.006356117781251669,
      -0.02613142877817154,
      0.050597358494997025,
      -0.04192095622420311,
      -0.03473573178052902,
      -0.03875034302473068,
      0.032281339168548584,
      0.02359876036643982,
      0.0053630126640200615,
      0.00849230494350195,
      -0.07366178184747696,
      0.0019002857152372599,
      -0.020650217309594154,
      0.06328396499156952,
      0.000456235691672191,
      -0.0008264319621957839,
      0.0032584501896053553,
      0.060847025364637375,
      -0.032353851944208145,
      0.00818011723458767,
      -0.03788984566926956,
      -0.0012089970987290144,
      -0.012815588153898716,
      -0.048725441098213196,
      -0.004227173049002886,
      0.004205111879855394,
      0.028214730322360992,
      0.05342129245400429,
      0.019864631816744804,
      -0.0027624794747680426,
      0.00874054990708828,
      0.02737097628414631,
      0.011456029489636421,
      -0.024367162957787514,
      -0.028320245444774628,
      -0.017970310524106026,
      -0.01041437778621912,
      -0.002749026520177722,
      0.0446285754442215,
      0.08067221194505692,
      0.07448060810565948,
      0.05531406030058861,
      0.009511693380773067,
      -0.034783780574798584,
      -0.04733111709356308,
      -0.003933473024517298,
      0.03343537449836731,
      0.03451813757419586,
      -0.03347573056817055,
      -0.0844794511795044,
      -0.02770250290632248,
      0.00883842445909977,
      -0.03915714845061302,
      -0.05270741879940033,
      -0.06134451925754547,
      -0.0447942391037941,
      -0.08192786574363708,
      0.037156619131565094,
      -0.0003999915497843176,
      -0.016942203044891357,
      -0.021036552265286446,
      0.08395246416330338,
      0.002241356298327446,
      0.0272270068526268,
      -0.0456722117960453,
      -0.03769455850124359,
      0.025258053094148636,
      0.023910026997327805,
      0.0029848385602235794,
      -0.011813285760581493,
      0.0659274309873581,
      0.03051109053194523,
      0.060117706656455994,
      -0.0042681205086410046,
      -0.02864861860871315,
      -0.01112112682312727,
      -0.012176505289971828,
      -0.0928502231836319,
      -0.035316213965415955,
      -0.028498131781816483,
      -0.019038597121834755,
      -0.08377207815647125,
      -0.010365406982600689,
      -0.041870296001434326,
      0.045208778232336044,
      0.10251111537218094,
      -0.015222967602312565,
      -0.00752324378117919,
      -0.02149076573550701,
      -0.02940410189330578,
      -0.05678148567676544,
      0.02813602238893509,
      0.017404083162546158,
      -0.02943561039865017,
      0.122676320374012,
      -0.0031880117021501064,
      0.015069485642015934,
      -0.03430552035570145,
      0.042635396122932434,
      0.05650341883301735,
      0.051810819655656815,
      0.02372041344642639,
      -0.06100277230143547,
      0.03276019170880318,
      -0.01766006089746952,
      -0.039637885987758636,
      -0.006184733938425779,
      0.043709512799978256,
      0.017288697883486748,
      -0.0018955613486468792,
      0.046828411519527435,
      -0.018922602757811546,
      0.04726526141166687,
      -0.013516099192202091,
      0.10558423399925232,
      -0.12049134820699692,
      0.0023598377592861652,
      0.029598604887723923,
      -0.04729236289858818,
      -0.06857598572969437,
      -0.0028161953669041395,
      -0.011892915703356266,
      -0.0006257279310375452,
      0.04191342741250992,
      -0.0594758465886116,
      0.027112450450658798,
      -0.009906413964927197,
      0.015838036313652992,
      0.013639246113598347,
      0.008607091382145882,
      0.04508863389492035,
      0.029253698885440826,
      0.05208536237478256,
      0.051455605775117874,
      0.0037660873495042324,
      0.02778458595275879,
      -0.04538353905081749,
      -0.007584562059491873,
      -0.04589201137423515,
      0.03392624855041504,
      -0.029565827921032906,
      -0.052530962973833084,
      0.007247256115078926,
      -0.07419037818908691,
      -0.005093615967780352,
      0.004697129596024752,
      -0.036561183631420135,
      0.08174287527799606,
      0.00456134881824255,
      -0.048571858555078506,
      -0.027023695409297943
    ],
    [
      -0.001776413177140057,
      0.060170385986566544,
      -0.06264971941709518,
      0.05090773478150368,
      0.06547895073890686,
      0.003534487681463361,
      -0.0279565267264843,
      0.03459383174777031,
      -0.03751524165272713,
      -0.07307428121566772,
      0.004293291829526424,
      0.03448973596096039,
      0.008261538110673428,
      -0.01410240400582552,
      -0.005040337797254324,
      -0.0017942020203918219,
      0.04361078515648842,
      -0.05735209211707115,
      -0.0659075528383255,
      0.01236023474484682,
      -0.0753415897488594,
      0.031126325950026512,
      -0.015675637871026993,
      -0.04546215757727623,
      0.0547959990799427,
      0.09635564684867859,
      0.029504669830203056,
      -0.0008427542052231729,
      -0.05186808854341507,
      0.03421218693256378,
      0.03745832294225693,
      -0.06762875616550446,
      -0.05398689955472946,
      0.06964731216430664,
      -0.0723116472363472,
      0.018500275909900665,
      -0.04834827780723572,
      -0.04176418110728264,
      -0.00946490466594696,
      -0.01956227421760559,
      -0.037453748285770416,
      -0.028566330671310425,
      0.012279854156076908,
      0.030431892722845078,
      0.032739248126745224,
      -0.01035225298255682,
      -0.006996865849941969,
      0.035991061478853226,
      0.01747727580368519,
      -0.034198325127363205,
      -0.031454697251319885,
      0.03302757814526558,
      0.014044856652617455,
      0.025601381435990334,
      -0.08996640145778656,
      0.07792108505964279,
      0.0282488614320755,
      0.03072694130241871,
      -0.04994213953614235,
      0.08328410238027573,
      0.09030842781066895,
      -0.02147320657968521,
      -0.008081457577645779,
      -0.019342884421348572,
      -0.07002010196447372,
      0.0530974455177784,
      -0.005981680937111378,
      0.01025166641920805,
      0.010191436856985092,
      -0.010931573808193207,
      -0.03339847922325134,
      0.07086697965860367,
      0.04802197217941284,
      0.049011312425136566,
      -0.012049579992890358,
      -0.0010578966466709971,
      -0.04433634132146835,
      -0.010943365283310413,
      0.0007867281674407423,
      0.024640634655952454,
      0.1008799597620964,
      0.13991640508174896,
      0.0686129480600357,
      0.07580870389938354,
      0.009853584691882133,
      -0.05629919096827507,
      0.04380188137292862,
      -0.03613138943910599,
      0.024544607847929,
      6.48629356874153e-05,
      0.011235302314162254,
      0.03212137520313263,
      0.0741252675652504,
      -0.06075960770249367,
      0.05068197473883629,
      -0.019476236775517464,
      -0.049306999891996384,
      0.03559941053390503,
      0.025610562413930893,
      -0.04805581644177437,
      0.017755387350916862,
      -0.004430608358234167,
      -0.013609596528112888,
      -0.005923465825617313,
      0.07199618965387344,
      -0.02040240913629532,
      0.041123248636722565,
      0.01139941718429327,
      -0.023530058562755585,
      0.0031195443589240313,
      -0.045957908034324646,
      0.0716986432671547,
      -0.08489774912595749,
      -0.027870703488588333,
      -0.07813028991222382,
      0.0369223952293396,
      -0.044753748923540115,
      -0.018243402242660522,
      -0.05653255060315132,
      -0.008497568778693676,
      0.0171576626598835,
      -0.05014465004205704,
      0.04062109813094139,
      0.09794431924819946,
      0.08734466880559921,
      0.04230167716741562,
      0.05731205269694328,
      0.0005387278506532311,
      -0.050912342965602875,
      0.03373999893665314,
      -0.04906270280480385,
      -0.02715967781841755,
      -0.016241466626524925,
      0.04511607810854912,
      -0.03182316571474075,
      -0.005534857511520386,
      0.03638782724738121,
      -0.04607958719134331,
      -0.07629507780075073,
      -0.006806630641222,
      0.05191599205136299,
      -0.05127352848649025,
      0.041915878653526306,
      -0.04411150887608528,
      -0.045171719044446945,
      0.0916711613535881,
      0.07036900520324707,
      0.04780017212033272,
      0.0855078473687172,
      0.024018723517656326,
      -0.007349975872784853,
      0.027786951512098312,
      -0.02097867988049984,
      0.06896571815013885,
      -0.030091769993305206,
      -0.07168065011501312,
      0.06591756641864777,
      -0.0004348658549133688,
      0.05375680327415466,
      0.011673655360937119,
      -0.015243577770888805,
      0.013705136254429817,
      0.020907703787088394,
      -0.07237571477890015,
      -0.060745593160390854,
      -0.036942511796951294,
      -0.017084481194615364,
      -0.04509671404957771,
      -0.034545350819826126,
      -0.09445777535438538,
      0.02816580981016159,
      -0.0580243356525898,
      -0.012828866019845009,
      -0.053080808371305466,
      0.03739510104060173,
      -0.06156127527356148,
      -0.03559694066643715,
      0.005108912009745836,
      0.02488558180630207,
      -0.0784258022904396,
      0.05706816911697388,
      0.003515773918479681,
      0.0020174935925751925,
      -0.025093980133533478,
      -0.008992897346615791,
      -0.04920145496726036,
      0.014290334656834602,
      0.029076026752591133,
      -0.012073393911123276,
      0.050940003246068954,
      -0.02485397644340992,
      0.03460121154785156,
      0.030948366969823837,
      -0.08359716832637787,
      0.037160687148571014,
      0.003826515981927514,
      0.034678466618061066,
      0.04412347450852394,
      -0.0042040604166686535,
      -0.06995085626840591,
      -0.053837209939956665,
      0.011807341128587723,
      0.03753634914755821,
      -0.009921511635184288,
      -0.007323417812585831,
      0.01627037674188614,
      0.025732750073075294,
      0.07114040106534958,
      -0.015927433967590332,
      0.005781989544630051,
      -0.02575857564806938,
      0.03265035152435303,
      0.003898726310580969,
      0.03183173015713692,
      0.03269649296998978,
      0.0660630613565445,
      0.013342068530619144,
      -0.038900792598724365,
      -0.041964124888181686,
      -0.04129445180296898,
      -0.0069275349378585815,
      0.056825071573257446,
      -0.054613299667835236,
      -0.007261160295456648,
      -0.06387399137020111,
      -0.03638885170221329,
      0.05546043440699577,
      -0.059541672468185425,
      0.004244780167937279,
      -0.06469354033470154,
      0.023757215589284897,
      0.04021717980504036,
      0.06281784921884537,
      -0.06644967198371887,
      -0.04689739644527435,
      -0.014834650792181492,
      -0.013569270260632038,
      -0.029903676360845566,
      -0.020044248551130295,
      -0.003277258016169071,
      0.04198424145579338,
      0.004326699301600456,
      -0.0373222790658474,
      -0.06702693551778793,
      -0.033903010189533234,
      -0.03334767743945122,
      -0.05016803368926048,
      -0.007757496554404497,
      -0.036659251898527145,
      -0.007997381500899792,
      -0.018866898491978645,
      0.00027488332125358284,
      -0.022292254492640495,
      0.011890987865626812,
      -0.0012802323326468468,
      -0.0007515043253079057,
      0.010141483508050442,
      -0.0819239541888237,
      -0.043437711894512177,
      -0.03959603235125542,
      -0.047506075352430344,
      -0.005975302308797836,
      0.03883295878767967,
      -0.023881057277321815,
      -0.11179859936237335,
      -0.00028312724316492677,
      -0.09425357729196548,
      -0.03313731402158737,
      -0.008746061474084854,
      -0.007274121977388859,
      -0.047652777284383774,
      0.013084418140351772,
      0.05166368559002876,
      0.007049884647130966,
      -0.0034668254666030407,
      -0.01780284009873867,
      0.0775691419839859,
      0.06795518100261688,
      0.003214564872905612,
      0.06444191187620163,
      0.03773006796836853,
      0.0499906912446022,
      0.053951140493154526,
      0.0651230439543724,
      -0.0031671819742769003,
      -0.04570654779672623,
      0.01698737032711506,
      0.07263937592506409,
      -0.014208818785846233,
      0.03270532935857773,
      -0.03526326268911362,
      -0.004045872483402491,
      0.015449387021362782,
      -0.020770151168107986,
      0.04887954145669937,
      -0.1022823303937912,
      -0.03226881101727486,
      -0.007917381823062897,
      -0.06960812956094742,
      -0.07079865038394928,
      -0.07143077999353409,
      -0.019867034628987312,
      -0.04305360093712807,
      -0.0023360312916338444,
      0.06101411208510399,
      0.0040542506612837315,
      0.041983120143413544,
      0.014082079753279686,
      0.025499755516648293,
      -0.013114161789417267,
      -0.020868070423603058,
      0.06636716425418854,
      0.06894967705011368,
      0.05062123015522957,
      -0.0509941503405571,
      -0.06762340664863586,
      -0.1269526183605194,
      0.025108851492404938,
      0.023618988692760468,
      0.05656127631664276,
      0.03281047195196152,
      -0.0007157146465033293,
      -0.02991006337106228,
      0.03744910657405853,
      -0.036660682410001755,
      0.029373686760663986,
      -0.027923990041017532,
      0.07469204813241959,
      0.03579935431480408,
      0.02273920364677906,
      -0.023048968985676765,
      0.026943739503622055,
      -0.017873017117381096,
      -0.021129237487912178,
      -0.08332501351833344,
      0.03929493948817253,
      0.025923127308487892,
      -0.07711580395698547,
      0.04801614210009575,
      -0.016707338392734528,
      -0.02934790775179863,
      0.026284856721758842,
      0.02016712911427021,
      0.027004435658454895,
      0.012730829417705536,
      0.0387151762843132,
      0.0065436880104243755,
      0.036499664187431335,
      -0.009275771677494049,
      0.018165241926908493,
      -0.0297170951962471,
      0.03717964142560959,
      -0.004994816146790981,
      0.0931219756603241,
      -0.0005037271766923368,
      -0.011605176143348217,
      -0.01903994008898735,
      -0.049440596252679825,
      -0.005267595872282982,
      -0.025761671364307404,
      -0.061353255063295364,
      -0.057534851133823395,
      -0.0006323847337625921,
      -0.005007263273000717,
      0.08202040195465088,
      -0.02746756002306938,
      0.023427769541740417,
      0.05025062710046768,
      -0.004071040078997612,
      -0.02914460562169552,
      -0.045995503664016724,
      -0.011364798061549664,
      -0.045769210904836655,
      0.03004569746553898,
      -0.022279025986790657,
      -0.009009065106511116,
      -0.02024521678686142,
      -0.03039231337606907,
      -0.030980702489614487,
      0.023424042388796806,
      0.0625513345003128,
      0.0389239601790905,
      -0.009899482131004333,
      0.05300074443221092,
      0.00958254188299179,
      0.07322347164154053,
      0.04956541582942009,
      -0.1077408641576767,
      0.009697376750409603,
      0.031362202018499374,
      -0.020396161824464798,
      -0.018027938902378082,
      0.0010714703239500523,
      0.044585589319467545,
      -0.07356129586696625,
      -0.01880751922726631,
      -0.01038306299597025,
      0.0335390605032444,
      0.016465723514556885,
      0.03314489871263504,
      -0.06294243782758713,
      0.030484557151794434,
      -0.041494812816381454,
      -0.021762415766716003,
      -0.016000131145119667,
      -0.025653863325715065,
      -0.04662102088332176,
      -0.04012692719697952,
      -0.01220440212637186,
      -0.025796841830015182,
      0.028090234845876694,
      0.02288220450282097,
      0.012938468717038631,
      0.027520842850208282,
      0.07100165635347366,
      0.012375719845294952,
      0.010822816751897335,
      -0.04454515129327774,
      -0.06856698542833328,
      0.001788553548976779,
      -0.05639961361885071,
      -0.05480008199810982,
      0.01107889972627163,
      -0.07234632223844528,
      -0.03875210881233215,
      -0.0009176530293188989,
      0.06819576770067215,
      0.014701489359140396,
      0.02407030574977398,
      -0.012786468490958214,
      0.07201492041349411,
      -0.11181320995092392,
      -0.03358807787299156,
      -0.01906428299844265,
      -0.022863954305648804,
      0.05161846801638603,
      -0.015507549047470093,
      -0.02772180363535881,
      -0.01602840982377529,
      -0.10128498822450638,
      0.038108423352241516,
      -0.09183767437934875,
      0.059166718274354935,
      -0.02064051479101181,
      -0.0843430683016777,
      -0.06324882060289383,
      -0.0012573240092024207,
      0.05842110514640808,
      -0.037851929664611816,
      -0.04320235177874565,
      -0.02212345227599144,
      0.07095742225646973,
      0.03899291157722473,
      -0.026922836899757385,
      0.050313550978899,
      -0.0667104721069336,
      -0.04250898212194443,
      -0.14407597482204437,
      -0.014904358424246311,
      0.026312632486224174,
      0.05089215934276581,
      0.02168303355574608,
      -0.020413672551512718,
      0.05431738495826721,
      0.011512014083564281,
      -0.026163266971707344,
      0.026854997500777245,
      0.02530992031097412,
      -0.07808582484722137,
      -0.007429826073348522,
      0.030276134610176086,
      0.05384054034948349,
      0.037458229809999466,
      -0.012547416612505913,
      0.04864279180765152,
      -0.0024482908193022013,
      -0.07496461272239685,
      0.006738330703228712,
      0.039487697184085846,
      -0.033139146864414215,
      0.025163985788822174,
      -0.06682713329792023,
      -0.005806219298392534,
      0.021115049719810486,
      0.10976891219615936,
      0.06601957976818085,
      0.07151089608669281,
      0.003920691553503275,
      -0.10075495392084122,
      -0.01249387115240097,
      0.10772742331027985,
      0.025524353608489037,
      -0.07497456669807434,
      0.0035914306063205004,
      -0.029375966638326645,
      -0.005061456002295017,
      0.11039100587368011,
      -0.07274211198091507,
      -0.0053251562640070915,
      0.0054841614328324795,
      -0.024945469573140144,
      -0.05321509391069412,
      0.052110787481069565
    ],
    [
      0.0471491664648056,
      0.02397250570356846,
      0.038371969014406204,
      -0.05646355450153351,
      0.0006801467970944941,
      0.07821059972047806,
      -0.1148209348320961,
      -0.11669987440109253,
      0.009821907617151737,
      -0.017255494371056557,
      0.012977064587175846,
      0.08954238891601562,
      0.05525568127632141,
      0.03408749774098396,
      0.10585658997297287,
      0.009114132262766361,
      -0.08045531809329987,
      0.04567178338766098,
      -0.012799111194908619,
      -0.09093019366264343,
      0.032680582255125046,
      0.011708839796483517,
      -0.053395941853523254,
      -0.05299917608499527,
      -0.004626876674592495,
      -0.026156695559620857,
      -0.002099008997902274,
      0.050587862730026245,
      0.00774962967261672,
      0.005937960464507341,
      0.05153176560997963,
      -0.014404821209609509,
      0.09815844893455505,
      -0.004754595924168825,
      -0.041563067585229874,
      -0.02777513675391674,
      -0.07249686121940613,
      -0.003886185819283128,
      -0.11361382156610489,
      -0.01385995838791132,
      -0.053139686584472656,
      0.05796048790216446,
      -0.0034484555944800377,
      -0.011399727314710617,
      0.1029505729675293,
      -0.015888484194874763,
      -0.004317543935030699,
      -0.014332723803818226,
      0.005149738863110542,
      0.06521879136562347,
      -0.029122039675712585,
      0.04454330727458,
      0.0036000502295792103,
      -0.008385905995965004,
      0.02469194307923317,
      -0.030249953269958496,
      0.05386601388454437,
      -0.0036425567232072353,
      -0.00925079733133316,
      0.06526942551136017,
      -0.0348329171538353,
      0.04731815680861473,
      0.11866957694292068,
      -0.042974285781383514,
      -0.04518599435687065,
      -0.03936084359884262,
      -0.0029474524781107903,
      -0.023979099467396736,
      -0.02245640940964222,
      -0.00783622171729803,
      -0.03542039543390274,
      -0.021160118281841278,
      -0.03508859500288963,
      0.10214473307132721,
      0.0015927841886878014,
      -0.013221780769526958,
      0.000984079553745687,
      -0.05546580255031586,
      0.03181322664022446,
      0.0016192556358873844,
      0.04102759808301926,
      -0.03434816747903824,
      0.007957871071994305,
      -0.14409708976745605,
      -0.048081174492836,
      0.013633484952151775,
      0.029940105974674225,
      0.0610661581158638,
      0.024874424561858177,
      0.0176241435110569,
      -0.04624496027827263,
      0.023301267996430397,
      0.0071218423545360565,
      -0.018252290785312653,
      -0.0025539693888276815,
      0.03309081122279167,
      -0.02908463589847088,
      0.056233398616313934,
      0.011704489588737488,
      -0.06076309084892273,
      0.0791749507188797,
      -0.016339492052793503,
      0.019089708104729652,
      -0.07146681100130081,
      0.06227230280637741,
      0.06974377483129501,
      -0.004988664295524359,
      0.02986736223101616,
      0.06926171481609344,
      0.018410757184028625,
      -0.03994821384549141,
      -0.03384146839380264,
      -0.028767265379428864,
      -0.09799613058567047,
      0.03508036583662033,
      -0.02163502760231495,
      0.0012750555761158466,
      0.05445942282676697,
      -0.04544151946902275,
      -0.010401508770883083,
      0.0647917166352272,
      0.004107604268938303,
      0.006497721653431654,
      0.06245839223265648,
      0.03483888879418373,
      0.06876017153263092,
      0.11918112635612488,
      0.04268564283847809,
      -0.06534447520971298,
      -0.02666395530104637,
      0.034251537173986435,
      0.06373197585344315,
      0.03417456895112991,
      -0.022600725293159485,
      0.08546695858240128,
      0.05671576410531998,
      -0.07089437544345856,
      -0.06534139811992645,
      0.04588480293750763,
      0.011897165328264236,
      0.05255104601383209,
      -0.009978395886719227,
      0.035943858325481415,
      -0.0039012087509036064,
      -0.008132456801831722,
      0.03585565462708473,
      -0.04380708560347557,
      -0.025361184030771255,
      -0.05294143036007881,
      0.0024020907003432512,
      -0.025021487846970558,
      0.022434286773204803,
      0.030228234827518463,
      0.02325686626136303,
      -0.012026947923004627,
      -0.03510060906410217,
      0.04421468451619148,
      -0.03488849475979805,
      0.02778218872845173,
      0.024612165987491608,
      -0.04080411419272423,
      -0.03568088635802269,
      -0.08265671133995056,
      0.03980085626244545,
      0.04442964121699333,
      0.07105547934770584,
      0.024111077189445496,
      0.0257728211581707,
      0.03974886238574982,
      0.02549232915043831,
      -0.038820862770080566,
      0.0348181277513504,
      0.009518510662019253,
      0.018694521859288216,
      0.005042404867708683,
      0.045553360134363174,
      0.0003805924498010427,
      -0.03821754455566406,
      -0.10985635221004486,
      0.03780149668455124,
      -0.02077345736324787,
      -0.05500590801239014,
      -0.09948937594890594,
      0.011300199665129185,
      0.023380277678370476,
      -0.03314480185508728,
      -0.013140401802957058,
      0.04734157770872116,
      0.017872625961899757,
      0.0006735759670846164,
      0.026497947052121162,
      -0.06781499087810516,
      -0.10045964270830154,
      -0.019243499264121056,
      0.027715187519788742,
      -0.03473590314388275,
      -0.040966250002384186,
      -0.01831469126045704,
      -0.002970194211229682,
      -0.0017860830994322896,
      0.026532214134931564,
      0.011536545120179653,
      -0.020083649083971977,
      -0.005551446694880724,
      -0.028408795595169067,
      0.04293311387300491,
      0.0514148510992527,
      -0.012596679851412773,
      -0.12960664927959442,
      -0.006792266387492418,
      -0.01105602364987135,
      -0.06696807593107224,
      -0.06116323173046112,
      0.07094360142946243,
      0.006318950094282627,
      0.07151129096746445,
      0.005094379652291536,
      0.0012713646283373237,
      0.02729150652885437,
      -0.010460304096341133,
      0.019069796428084373,
      -0.006539196707308292,
      -0.030121996998786926,
      -0.08108125627040863,
      -0.05299537256360054,
      0.020153284072875977,
      -0.05281312018632889,
      0.04838842526078224,
      -0.10063731670379639,
      0.05058562010526657,
      -0.001239640754647553,
      0.028581717982888222,
      0.02084663324058056,
      0.0291049312800169,
      0.019023630768060684,
      -0.0023538549430668354,
      -0.05777508765459061,
      0.03591877222061157,
      -0.08298381417989731,
      0.07232950627803802,
      -0.04468521475791931,
      0.006738889962434769,
      0.0402003712952137,
      -0.023249860852956772,
      -0.058092620223760605,
      -0.05885334312915802,
      0.042558517307043076,
      0.04437856003642082,
      -0.0981750413775444,
      0.05869795382022858,
      -0.04834685102105141,
      -0.034099236130714417,
      0.004143472295254469,
      0.03488259017467499,
      0.02482055313885212,
      0.0008975231321528554,
      0.0267785657197237,
      -0.057705704122781754,
      -0.10520947724580765,
      0.08802621811628342,
      0.03582911565899849,
      0.0534391924738884,
      0.04291651025414467,
      -0.010157274082303047,
      0.001967006828635931,
      -0.012972699478268623,
      -0.02678469382226467,
      0.019608113914728165,
      0.04897709935903549,
      0.048542484641075134,
      0.008054235018789768,
      -0.007785367779433727,
      -0.0001841636112658307,
      0.07143101841211319,
      -0.014791213907301426,
      -0.01597623899579048,
      -0.012383917346596718,
      0.022068889811635017,
      -0.019209744408726692,
      0.04578689485788345,
      -8.921594599087257e-06,
      0.114504873752594,
      0.05394379794597626,
      -0.02397141419351101,
      0.011487598530948162,
      -0.026791337877511978,
      0.02845124900341034,
      0.03256906196475029,
      -0.0266740582883358,
      0.03317377343773842,
      0.06085730716586113,
      -0.03983746096491814,
      -0.016927791759371758,
      0.022717496380209923,
      0.059733789414167404,
      -0.051807571202516556,
      -0.012955336831510067,
      0.08283977955579758,
      -0.02066943049430847,
      0.07525306195020676,
      -0.027814732864499092,
      0.006450955290347338,
      -0.030939176678657532,
      0.004438404925167561,
      -0.07250084728002548,
      -0.015254412777721882,
      -0.007034819573163986,
      0.024261068552732468,
      -0.027764156460762024,
      0.08342580497264862,
      -0.02818261831998825,
      -0.10314533114433289,
      0.01599419116973877,
      -0.0060074543580412865,
      -0.036344014108181,
      0.03785033896565437,
      -0.05401800572872162,
      -0.02233271859586239,
      0.025684349238872528,
      -0.0055874246172606945,
      -0.031495600938797,
      0.0081977853551507,
      -0.025662861764431,
      -0.033798325806856155,
      -0.016783155500888824,
      0.06108788028359413,
      0.009931717999279499,
      -0.01772421970963478,
      -0.04163949936628342,
      -0.11949601769447327,
      -0.01342925988137722,
      -0.003060967894271016,
      -0.036505769938230515,
      0.08243027329444885,
      0.012528947554528713,
      -0.02379627525806427,
      0.0012107758084312081,
      -0.0011624727630987763,
      0.0626087412238121,
      0.053955353796482086,
      -0.022277656942605972,
      -0.04229561612010002,
      0.011155539192259312,
      0.016559742391109467,
      0.050743475556373596,
      0.027049142867326736,
      0.04084969684481621,
      0.004771656356751919,
      -0.023210961371660233,
      -0.006153949070721865,
      -0.029230492189526558,
      -0.03296677768230438,
      0.064411461353302,
      -0.018787125125527382,
      -0.06684888899326324,
      -0.03491818159818649,
      0.013017511926591396,
      0.037354033440351486,
      0.033177509903907776,
      0.041795674711465836,
      0.06780736148357391,
      -0.011834385804831982,
      -0.0780940130352974,
      -0.049379173666238785,
      -0.0018524955958127975,
      -0.026509348303079605,
      -0.03211349993944168,
      -0.07213786244392395,
      -0.032173898071050644,
      0.06192438676953316,
      -0.009084613062441349,
      -0.010591836646199226,
      -0.00417629349976778,
      0.08457570523023605,
      0.0026233368553221226,
      -0.021576598286628723,
      0.024403082206845284,
      0.008669012226164341,
      0.03138834238052368,
      -0.008521249517798424,
      -0.02236143685877323,
      -0.038819219917058945,
      -0.042517226189374924,
      0.0003795943921431899,
      0.03538709133863449,
      -0.010007493197917938,
      -0.15827327966690063,
      -0.07247087359428406,
      0.0031331453938037157,
      -0.004289887845516205,
      0.020544031634926796,
      -0.021622691303491592,
      0.01866539567708969,
      0.017678771167993546,
      0.009541754610836506,
      -0.012313134036958218,
      0.003989839926362038,
      0.0036531533114612103,
      -0.015179373323917389,
      -0.06347715109586716,
      0.005751427263021469,
      0.051234565675258636,
      -0.03702486306428909,
      -0.08058539777994156,
      0.07424581050872803,
      -0.060171715915203094,
      -0.032651808112859726,
      0.0244455523788929,
      -0.021107247099280357,
      0.0075197299011051655,
      0.026951419189572334,
      0.03948955982923508,
      0.06138613447546959,
      0.0427553653717041,
      -0.01070280559360981,
      -0.03659554943442345,
      0.093958280980587,
      0.02833220176398754,
      -0.007216789294034243,
      0.012865766882896423,
      -0.034723345190286636,
      0.07622919231653214,
      -0.09904487431049347,
      -0.06876830011606216,
      -0.07666914910078049,
      0.0029947718139737844,
      -0.04114261269569397,
      0.031204964965581894,
      -0.02053859829902649,
      -0.022999227046966553,
      -0.0414600744843483,
      -0.01716533862054348,
      -0.006371942814439535,
      -0.01656387932598591,
      -0.026846300810575485,
      0.06944429874420166,
      0.017599696293473244,
      -0.012349077500402927,
      0.004260634537786245,
      -0.05921357497572899,
      -0.019085552543401718,
      -0.07790926843881607,
      -0.05727876350283623,
      0.05920904502272606,
      -0.016668137162923813,
      0.04362306371331215,
      0.009591974318027496,
      -0.016519861295819283,
      -0.031226560473442078,
      -0.05361912027001381,
      0.010778271593153477,
      0.033101510256528854,
      -0.02853778563439846,
      0.0660736933350563,
      0.0412905290722847,
      0.04500251263380051,
      0.027599498629570007,
      0.06966862082481384,
      0.06086453050374985,
      0.012616266496479511,
      0.06967614591121674,
      -0.025683347135782242,
      -0.011096642352640629,
      0.07874745875597,
      0.012013367377221584,
      -0.004359702114015818,
      0.014334512874484062,
      0.09052273631095886,
      0.10776631534099579,
      0.0035654446110129356,
      0.004213062580674887,
      -0.12192860245704651,
      -0.03298937901854515,
      0.06882311403751373,
      -0.06407064944505692,
      0.05696887895464897,
      0.05343891680240631,
      0.03563600033521652,
      -0.0632268413901329,
      0.027668830007314682,
      0.02308785170316696,
      -0.05387333780527115,
      0.06112354248762131,
      0.041641637682914734,
      0.023258119821548462,
      -0.018240442499518394,
      0.0011775890598073602,
      0.07672219723463058,
      0.049496959894895554,
      0.029891008511185646,
      0.05424869433045387,
      -0.02693694457411766,
      0.00046819986891932786,
      0.048635587096214294,
      -0.04438591003417969,
      -0.08153654634952545,
      0.050752513110637665,
      0.07047802954912186,
      -0.06599679589271545,
      0.021748585626482964,
      -0.07098278403282166,
      0.05564442649483681,
      0.012314215302467346
    ],
    [
      0.03603047505021095,
      -0.009121300652623177,
      -0.03291204571723938,
      0.01259957067668438,
      0.12443758547306061,
      -0.060249049216508865,
      0.08686761558055878,
      0.014407187700271606,
      0.0014761477941647172,
      0.015688352286815643,
      -0.013006400316953659,
      0.05340941622853279,
      -0.02039189636707306,
      -0.035067636519670486,
      0.03406636044383049,
      0.004907320253551006,
      -0.005916752386838198,
      0.001110427314415574,
      0.038927555084228516,
      -0.004164808429777622,
      0.1041964441537857,
      0.023681074380874634,
      0.02887958288192749,
      0.08815992623567581,
      0.020768631249666214,
      0.02384175732731819,
      -0.007975807413458824,
      0.009069932624697685,
      -0.059752512723207474,
      -0.08478627353906631,
      0.060312364250421524,
      -0.0512249693274498,
      0.01888882741332054,
      -0.06170284375548363,
      -0.0026139093097299337,
      -0.06775113940238953,
      -0.08857458829879761,
      -0.03429795429110527,
      -0.030756201595067978,
      -0.019805876538157463,
      -0.017889587208628654,
      -0.023524226620793343,
      -0.013742893002927303,
      -0.0603528767824173,
      0.01960107311606407,
      -0.006903029978275299,
      0.014249442145228386,
      0.0519644096493721,
      0.027768662199378014,
      -0.03427199646830559,
      -0.06324923783540726,
      -0.006800401024520397,
      -0.06658055633306503,
      -0.022629201412200928,
      0.05906819924712181,
      -0.03248706832528114,
      0.12513692677021027,
      -0.03961015120148659,
      0.012454605661332607,
      -0.043447352945804596,
      0.05504636839032173,
      0.03457804396748543,
      0.013244847767055035,
      -0.008481517434120178,
      -0.024992169812321663,
      0.03952373191714287,
      -0.05164457485079765,
      -0.0025186974089592695,
      -0.010775989852845669,
      -0.019530311226844788,
      0.05633580684661865,
      0.03267763555049896,
      0.07759342342615128,
      0.028607981279492378,
      -0.03453892096877098,
      0.045181114226579666,
      -0.03225100412964821,
      0.07885892689228058,
      0.024351591244339943,
      -0.0024203998036682606,
      -0.044085580855607986,
      0.05071061849594116,
      -0.034323714673519135,
      -0.129402294754982,
      -0.015331750735640526,
      -0.03476083278656006,
      0.011752920225262642,
      0.0246486384421587,
      0.035068780183792114,
      -0.05873230844736099,
      0.045997291803359985,
      -0.014949992299079895,
      0.027881383895874023,
      -0.01224078144878149,
      0.003355586202815175,
      0.05999709293246269,
      0.03245037794113159,
      0.026829469949007034,
      -0.003218397730961442,
      -0.05797552689909935,
      -0.015886224806308746,
      -0.02082500420510769,
      -0.04915555566549301,
      0.04101432487368584,
      0.00607672892510891,
      0.07789336144924164,
      -0.005743285641074181,
      0.09919675439596176,
      -0.0013654646463692188,
      0.028980761766433716,
      0.07835766673088074,
      0.01919589564204216,
      -0.003928566351532936,
      0.010312546044588089,
      -0.008462715893983841,
      0.030485715717077255,
      -0.03450929746031761,
      -0.013344157487154007,
      0.048590611666440964,
      -0.027491576969623566,
      0.10870718210935593,
      0.030642755329608917,
      0.07684221863746643,
      0.02805253118276596,
      0.030616074800491333,
      0.0326852910220623,
      0.02542266994714737,
      -0.0790436789393425,
      0.05966710299253464,
      0.007302715443074703,
      -0.08841964602470398,
      -0.026514116674661636,
      0.09113147109746933,
      0.06705954670906067,
      -0.12656034529209137,
      -0.026827430352568626,
      0.0025531724095344543,
      0.03581109270453453,
      0.003925988916307688,
      0.14336898922920227,
      0.029822958633303642,
      -0.02081643044948578,
      0.015421957708895206,
      0.0469568632543087,
      -0.013611922971904278,
      -0.027385151013731956,
      -0.030164968222379684,
      0.07548599690198898,
      0.014680163003504276,
      -0.10536591708660126,
      -0.021969392895698547,
      -0.019681565463542938,
      -0.028986496850848198,
      0.047329891473054886,
      -0.035459812730550766,
      0.0003880395961459726,
      -0.07161078602075577,
      -0.0094929663464427,
      -0.011874891817569733,
      -0.07798180729150772,
      0.04084951430559158,
      0.0070878309197723866,
      -0.015572452917695045,
      0.07949524372816086,
      -0.04811913147568703,
      0.008864082396030426,
      0.015033436007797718,
      -0.055772289633750916,
      0.06297732889652252,
      0.008532693609595299,
      0.0081403236836195,
      0.003460745792835951,
      -0.05283176526427269,
      -0.029968949034810066,
      0.03345519304275513,
      0.011790907941758633,
      0.05465327203273773,
      -0.02374204248189926,
      0.0015795368235558271,
      0.029207393527030945,
      -0.08179941028356552,
      -0.11977557837963104,
      -0.0777038186788559,
      0.027383843436837196,
      0.03643534705042839,
      0.004339315462857485,
      0.012648528441786766,
      0.013594156131148338,
      -0.01982114650309086,
      -0.08134737610816956,
      -0.013168818317353725,
      -0.054796598851680756,
      -0.03647129610180855,
      -0.03281264007091522,
      -0.03720201551914215,
      0.014861619099974632,
      0.002751100342720747,
      -0.03150278702378273,
      0.02346557192504406,
      -0.060419466346502304,
      0.012394756078720093,
      0.027002878487110138,
      -0.00919731892645359,
      0.08851693570613861,
      0.1301085352897644,
      -0.027927767485380173,
      -0.01433141715824604,
      -0.027544841170310974,
      -0.029506050050258636,
      0.06741084158420563,
      -0.00870432611554861,
      0.04084230214357376,
      -0.01736513525247574,
      0.020496636629104614,
      -0.06491350382566452,
      -0.0018693901365622878,
      -0.005399597343057394,
      0.0077303992584347725,
      -0.03176548331975937,
      0.0059147486463189125,
      -0.060619644820690155,
      0.009567812085151672,
      0.02083207666873932,
      0.016998810693621635,
      -0.019373703747987747,
      -0.003999752923846245,
      0.03952547907829285,
      0.07651747763156891,
      -0.024425385519862175,
      -0.019964495673775673,
      0.03199348598718643,
      -0.014449442736804485,
      0.045605435967445374,
      -0.03512078523635864,
      -0.0025416319258511066,
      -0.043012723326683044,
      -0.027455385774374008,
      0.021170303225517273,
      0.0009418739355169237,
      0.06447046250104904,
      0.03837636113166809,
      -0.0005092363571748137,
      0.011421412229537964,
      -0.01931728981435299,
      0.014787345193326473,
      0.03994929417967796,
      -0.017199821770191193,
      0.03469813987612724,
      0.0015187409007921815,
      0.03750766068696976,
      -0.05665803700685501,
      -0.030369574204087257,
      -0.047532618045806885,
      -0.014240856282413006,
      -0.013675201684236526,
      -0.03877974674105644,
      0.0015319185331463814,
      0.011741997674107552,
      -0.027976471930742264,
      -0.06382758915424347,
      -0.033425603061914444,
      0.03540830314159393,
      0.0025415653362870216,
      -0.0033884504809975624,
      -0.030823644250631332,
      -0.005940758157521486,
      -0.017065852880477905,
      -0.09130611270666122,
      0.021652523428201675,
      0.0019250024342909455,
      0.02107519842684269,
      0.08595605194568634,
      0.02000333182513714,
      0.04383028671145439,
      -0.02770659141242504,
      -0.018040597438812256,
      -0.002833254635334015,
      -0.047920722514390945,
      -0.05821941792964935,
      0.07985090464353561,
      -0.00040105124935507774,
      0.04898224025964737,
      0.06360157579183578,
      -0.030426733195781708,
      0.010547949001193047,
      -0.05718247592449188,
      0.036939837038517,
      0.03844268620014191,
      0.042839888483285904,
      0.03440047800540924,
      0.04193706810474396,
      0.0005947722820565104,
      0.10902530699968338,
      -0.01712687313556671,
      -0.08895311504602432,
      -0.029316097497940063,
      0.047245800495147705,
      -0.006705570500344038,
      -0.019610395655035973,
      0.003466337453573942,
      -0.03210574761033058,
      0.034486476331949234,
      0.0492706298828125,
      0.001681684167124331,
      -0.0054826256819069386,
      -0.020262520760297775,
      0.03095843270421028,
      0.006256548222154379,
      -0.08894825726747513,
      0.01886730082333088,
      0.049575138837099075,
      -0.014953224919736385,
      0.042649153620004654,
      0.07275034487247467,
      0.013150965794920921,
      0.03390765190124512,
      -0.031095195561647415,
      0.0243806391954422,
      -0.04954475536942482,
      0.06857689470052719,
      -0.022147195413708687,
      0.01895182579755783,
      -0.005797010846436024,
      -0.01120044756680727,
      -0.04541127383708954,
      -0.011634479276835918,
      -0.04617984592914581,
      -0.012265023775398731,
      0.013885579071938992,
      -0.0007993324543349445,
      0.028283223509788513,
      0.004725966602563858,
      -0.03948809206485748,
      0.03995963931083679,
      0.07804667204618454,
      0.03940388187766075,
      0.08647070825099945,
      0.0143752945587039,
      -0.02106931433081627,
      0.025459352880716324,
      0.0021478538401424885,
      -0.00725472392514348,
      -0.045810338109731674,
      -0.028622878715395927,
      0.038051266223192215,
      -0.09791097044944763,
      -0.0504211001098156,
      -0.0060227420181035995,
      0.03849489614367485,
      -0.0026611112989485264,
      0.081981360912323,
      0.016917074099183083,
      -0.0025125136598944664,
      0.03931710124015808,
      -0.01624728925526142,
      -0.04424228146672249,
      0.05562959611415863,
      -0.0034641495440155268,
      0.03439367935061455,
      0.05782117694616318,
      0.03271854668855667,
      -0.02512376569211483,
      0.03931444138288498,
      -0.015910206362605095,
      0.0005441672401502728,
      0.01200005691498518,
      0.05634179711341858,
      -0.01277789194136858,
      0.01776989735662937,
      -0.025934506207704544,
      -0.009718062356114388,
      -0.05715078115463257,
      0.01939084194600582,
      0.021108686923980713,
      -0.04380316287279129,
      -0.009248564019799232,
      -0.08213647454977036,
      -0.010697324760258198,
      -0.08810578286647797,
      0.03249102458357811,
      -0.058570537716150284,
      0.03513094410300255,
      0.017482217401266098,
      -0.01547009777277708,
      -0.02975008264183998,
      -0.02388165332376957,
      0.028980044648051262,
      0.06093110516667366,
      0.018762357532978058,
      0.0022752454970031977,
      0.025561491027474403,
      -0.0669630765914917,
      0.04837622493505478,
      -0.029278801754117012,
      0.006616540718823671,
      0.043800052255392075,
      0.02096511609852314,
      0.014090492390096188,
      -0.060546837747097015,
      -0.001849320251494646,
      0.03733695670962334,
      0.005435149185359478,
      0.02686895988881588,
      0.0038809371180832386,
      0.032887037843465805,
      -0.03723039850592613,
      -0.04323473572731018,
      0.11546558141708374,
      0.08937225490808487,
      0.047140300273895264,
      0.03377426043152809,
      0.09783275425434113,
      -0.0331624336540699,
      0.05542847141623497,
      0.00841073039919138,
      0.05428602918982506,
      -0.09629331529140472,
      -0.0068468679673969746,
      -0.08484575897455215,
      0.026523282751441002,
      -0.029219062998890877,
      -0.07652194052934647,
      -0.016857409849762917,
      0.054579585790634155,
      -0.06583815813064575,
      0.05477910488843918,
      0.04299772158265114,
      -0.03872291371226311,
      -0.04219039902091026,
      -0.005217331927269697,
      0.046924009919166565,
      0.0639176145195961,
      0.025622202083468437,
      -0.018139280378818512,
      0.017807502299547195,
      -0.04039570689201355,
      -0.04913289472460747,
      -0.050220780074596405,
      0.035969313234090805,
      0.08418042957782745,
      0.06763087958097458,
      -0.04804116487503052,
      0.040101855993270874,
      0.033055875450372696,
      0.00797914806753397,
      -0.04089380428195,
      0.011928104795515537,
      -0.06434991210699081,
      0.05163896456360817,
      -0.03569357097148895,
      0.0675763487815857,
      -0.02353636920452118,
      -0.06712313741445541,
      0.016520977020263672,
      0.023936331272125244,
      -0.02607845515012741,
      -0.05512666329741478,
      -0.011308211833238602,
      0.08420798182487488,
      -0.0011831007432192564,
      -0.0008727741078473628,
      -0.0320199579000473,
      0.02535303123295307,
      -0.023859413340687752,
      -0.012029306031763554,
      0.04194876551628113,
      0.009674758650362492,
      -0.06160883978009224,
      0.02923687733709812,
      0.0461571142077446,
      -0.06989417225122452,
      0.09253957122564316,
      -0.02638489566743374,
      0.05024382099509239,
      0.08432240784168243,
      0.06979669630527496,
      -0.06557493656873703,
      0.1262083649635315,
      0.11749298870563507,
      -0.07107973098754883,
      -0.019539281725883484,
      0.07646911591291428,
      0.04212211072444916,
      0.016920562833547592,
      -0.046537838876247406,
      -0.005659427028149366,
      -0.02478571981191635,
      -0.0944993644952774,
      -0.04946558177471161,
      -0.025017142295837402,
      0.011230161413550377,
      -0.012094726786017418,
      0.0023434641771018505,
      0.16163192689418793,
      -0.046693045645952225,
      -0.029022669419646263,
      -0.050785403698682785,
      0.07341127097606659,
      0.0016883069183677435,
      -0.023591449484229088,
      -0.02903600037097931,
      0.06752059608697891,
      -0.03397464379668236
    ],
    [
      -0.049694329500198364,
      0.06554681807756424,
      -0.011753731407225132,
      -0.006554142106324434,
      -0.015444699674844742,
      0.01664702408015728,
      -0.035435739904642105,
      -0.0073022074066102505,
      0.026906194165349007,
      -0.11804354190826416,
      0.03421951085329056,
      0.006497920025140047,
      0.04525735229253769,
      0.040721308439970016,
      0.05996747687458992,
      0.040133699774742126,
      0.0031921749468892813,
      -0.0029296218417584896,
      0.01071212999522686,
      0.006159627344459295,
      0.03122386522591114,
      0.0160321407020092,
      0.0071017323061823845,
      -0.03005049377679825,
      -0.03626931086182594,
      0.01319473423063755,
      0.01418781653046608,
      -0.037586938589811325,
      -0.07829554378986359,
      -0.013289780355989933,
      0.03042641095817089,
      -0.00608849385753274,
      -0.042627640068531036,
      -0.009468530304729939,
      0.05501817539334297,
      0.01365412212908268,
      0.029989421367645264,
      -0.031024852767586708,
      -0.0011089876061305404,
      0.0009273244068026543,
      0.011553402058780193,
      -0.05071888491511345,
      0.052909743040800095,
      -0.04214449226856232,
      -0.014258304610848427,
      0.07813771814107895,
      0.07147710770368576,
      0.007784602232277393,
      0.086475670337677,
      -0.036924634128808975,
      -0.04872998967766762,
      0.0037557827308773994,
      0.0009975386783480644,
      0.014209129847586155,
      0.037186238914728165,
      -0.025808461010456085,
      0.0138655174523592,
      -0.010892161168158054,
      -0.06059488281607628,
      0.005338858347386122,
      -0.030802641063928604,
      0.05128898844122887,
      0.04316806048154831,
      0.005420232191681862,
      0.06216440349817276,
      -0.07540107518434525,
      0.0018477209378033876,
      0.059468623250722885,
      -0.059763289988040924,
      -0.030017374083399773,
      -0.09765761345624924,
      0.04414742439985275,
      0.027788493782281876,
      0.02062617801129818,
      0.12947209179401398,
      -0.043757323175668716,
      0.019219154492020607,
      -0.05371466279029846,
      -0.01186155341565609,
      0.02356729283928871,
      -0.057887397706508636,
      -0.0024113806430250406,
      0.030488891527056694,
      -0.028157779946923256,
      0.05958518385887146,
      -0.012182094156742096,
      0.014960943721234798,
      0.04070892930030823,
      0.15668529272079468,
      0.009369145147502422,
      -0.02118748426437378,
      0.03333340957760811,
      0.05690224841237068,
      -0.008159419521689415,
      0.034285854548215866,
      0.029039869084954262,
      -0.03588113188743591,
      -0.050687484443187714,
      -0.008108388632535934,
      -0.06133889779448509,
      0.056105807423591614,
      0.023890506476163864,
      0.006298358086496592,
      0.019482653588056564,
      0.05737312510609627,
      0.011350113898515701,
      -0.006180793046951294,
      -0.04617108032107353,
      0.04884282127022743,
      0.09108057618141174,
      0.03902825713157654,
      0.07518123090267181,
      -0.11240193247795105,
      0.03442438691854477,
      -0.0334019735455513,
      -0.035210780799388885,
      0.04245671629905701,
      0.03518061339855194,
      0.032839175313711166,
      0.005372229963541031,
      0.04304225742816925,
      0.043080154806375504,
      -0.009920225478708744,
      -0.049099259078502655,
      0.021119777113199234,
      0.08836698532104492,
      0.09744534641504288,
      0.10911718755960464,
      -0.0651392713189125,
      -0.04246731474995613,
      -0.04686113819479942,
      0.014410953968763351,
      -0.023627564311027527,
      0.08369721472263336,
      -0.032193366438150406,
      0.009178975597023964,
      0.043349578976631165,
      -0.015362568199634552,
      -0.04267234727740288,
      0.0074229976162314415,
      0.08921446651220322,
      0.005022966302931309,
      0.019894618541002274,
      0.05026340112090111,
      -0.0365084670484066,
      0.019221778959035873,
      -0.04237350448966026,
      -0.04415378347039223,
      0.0409172922372818,
      0.07339595258235931,
      0.011982640251517296,
      -0.003322766162455082,
      -0.01696380041539669,
      -0.0004956015036441386,
      -0.03508377447724342,
      0.04357912391424179,
      0.017551526427268982,
      0.00759336119517684,
      -0.03690904751420021,
      0.0128944031894207,
      0.02512774057686329,
      -0.04724019393324852,
      -0.06041533127427101,
      -0.13032928109169006,
      -0.020007122308015823,
      0.020549237728118896,
      0.028132226318120956,
      -0.03308067470788956,
      0.04121987521648407,
      -0.04152197390794754,
      0.014826216734945774,
      0.06886166334152222,
      0.05771718546748161,
      0.13204431533813477,
      -0.01439704280346632,
      -0.02664749138057232,
      0.07423023134469986,
      -0.033515363931655884,
      0.0011140381684526801,
      0.010995287448167801,
      -0.05331715941429138,
      -0.05027800798416138,
      0.0431058369576931,
      -0.10061851888895035,
      -0.0021545584313571453,
      0.016809973865747452,
      -0.005609413143247366,
      0.06542997807264328,
      -0.06965318322181702,
      -0.059121619910001755,
      0.045804526656866074,
      -0.029942821711301804,
      0.0015490967780351639,
      0.05771153047680855,
      0.01827751100063324,
      0.03710733354091644,
      0.028283419087529182,
      0.0282771997153759,
      0.003784547559916973,
      -0.0351388081908226,
      -0.01413610391318798,
      0.04522611200809479,
      0.04049783945083618,
      0.09432555735111237,
      0.03221564739942551,
      -0.010152770206332207,
      -0.0059247747994959354,
      -0.05393177643418312,
      0.0453825406730175,
      0.07916538417339325,
      -0.01992643252015114,
      0.029963448643684387,
      -1.3883758583688177e-05,
      -0.03676814213395119,
      0.018733356148004532,
      -0.01765957474708557,
      -0.012461649253964424,
      -0.02798089012503624,
      0.011680856347084045,
      -0.04045606032013893,
      0.009232566691935062,
      0.0013945504324510694,
      -0.025175930932164192,
      0.022161757573485374,
      0.010437767021358013,
      -0.0024616767186671495,
      -0.035446908324956894,
      -0.029680287465453148,
      0.019020086154341698,
      -0.009203433990478516,
      -0.009411097504198551,
      0.033377617597579956,
      -0.06913220882415771,
      0.08620329201221466,
      -0.09041904658079147,
      -0.08123504370450974,
      0.03072940744459629,
      -0.026547396555542946,
      -0.03809436038136482,
      0.003342490177601576,
      0.00548551743850112,
      -0.06971457600593567,
      0.029618483036756516,
      0.0702119693160057,
      -0.03241411969065666,
      0.05406549572944641,
      -0.09268461167812347,
      0.02680315263569355,
      0.052527960389852524,
      -0.06122725456953049,
      0.007017521653324366,
      0.002612895332276821,
      0.0022133532911539078,
      -0.021231312304735184,
      0.0695851743221283,
      -0.011763160116970539,
      0.012807512655854225,
      -0.00730404956266284,
      -0.03871041163802147,
      0.011020182631909847,
      0.0012203343212604523,
      0.03221362456679344,
      -0.050932642072439194,
      0.024881746619939804,
      -0.043356139212846756,
      -0.02772916667163372,
      0.02811955101788044,
      -0.042562663555145264,
      0.012801540084183216,
      -0.10984668135643005,
      0.005957264453172684,
      0.056413717567920685,
      0.02587863989174366,
      0.04697510600090027,
      -0.023013511672616005,
      -0.020750617608428,
      -0.07521740347146988,
      -0.0004195916117168963,
      -0.00967499241232872,
      -0.06322091072797775,
      0.09685686230659485,
      -0.006088865455240011,
      0.009048964828252792,
      -8.657050057081506e-05,
      0.03488507121801376,
      0.019562609493732452,
      0.08104381710290909,
      0.08542096614837646,
      -0.020874787122011185,
      0.024594761431217194,
      -0.019132394343614578,
      -0.030386030673980713,
      0.04137525334954262,
      -0.06631161272525787,
      0.02846621535718441,
      -0.050334881991147995,
      -0.07297027856111526,
      -0.10868015885353088,
      -0.010550439357757568,
      0.06766141206026077,
      -0.09003333002328873,
      0.03711912781000137,
      -0.039390262216329575,
      -0.0072170160710811615,
      0.004433713387697935,
      -0.01017473079264164,
      -0.020024975761771202,
      0.011174282059073448,
      0.08422790467739105,
      0.002997027710080147,
      0.05755982920527458,
      -0.04782676696777344,
      -0.005330830346792936,
      -0.03271561115980148,
      -0.0034876989666372538,
      0.01167794968932867,
      -0.09325859695672989,
      0.0435783714056015,
      -0.047650329768657684,
      -0.04267718642950058,
      -0.05472638085484505,
      0.05064740777015686,
      -0.054569076746702194,
      -0.042093440890312195,
      -0.07417377084493637,
      0.018120773136615753,
      0.08933518081903458,
      0.07046814262866974,
      -0.07484469562768936,
      -0.021554911509156227,
      -0.04276164621114731,
      0.018412508070468903,
      -0.026185039430856705,
      -0.05345390737056732,
      -0.006266670301556587,
      -0.04182244837284088,
      -0.0012476228876039386,
      -0.021544689312577248,
      -0.004148698411881924,
      0.015482079237699509,
      -0.06074349209666252,
      -0.004707529675215483,
      -0.05637155473232269,
      -0.032497141510248184,
      0.029003551229834557,
      0.05928296595811844,
      -0.05336921289563179,
      -0.01380795706063509,
      0.012334163300693035,
      -0.04308032989501953,
      -0.028916679322719574,
      0.06667020171880722,
      0.021307768300175667,
      0.004521041177213192,
      -0.03513767570257187,
      0.02922385185956955,
      -0.017074111849069595,
      0.012612092308700085,
      -0.06313531845808029,
      -0.03729290887713432,
      -0.02411882020533085,
      -0.0034550358541309834,
      0.03655163571238518,
      0.05455651134252548,
      0.0018471198854967952,
      -0.015106669627130032,
      -0.035627588629722595,
      -0.00993275921791792,
      0.036843858659267426,
      0.03285663202404976,
      0.002463156823068857,
      0.02425178699195385,
      -0.05220697447657585,
      -0.05634167417883873,
      0.003310771193355322,
      -0.002148081548511982,
      0.07578836381435394,
      -0.005812698509544134,
      0.036006487905979156,
      -0.06881947815418243,
      0.02528340183198452,
      0.031112344935536385,
      -0.016746388748288155,
      0.05717684328556061,
      0.008482353761792183,
      0.04483461752533913,
      0.04675397276878357,
      0.027763817459344864,
      -0.006590597331523895,
      -0.06684597581624985,
      0.006290310062468052,
      -0.03910272940993309,
      -0.0047540608793497086,
      0.06289932131767273,
      -0.007397927343845367,
      0.0380777008831501,
      0.10622796416282654,
      -0.027757160365581512,
      0.017442721873521805,
      -0.015383701771497726,
      -0.029767660424113274,
      -0.0178830549120903,
      0.053573787212371826,
      0.06609541177749634,
      -0.022247077897191048,
      -0.007275031413882971,
      -0.00011176765838172287,
      0.017674563452601433,
      -0.02258554846048355,
      -0.07758128643035889,
      0.013940048404037952,
      0.03919139876961708,
      -0.1002604067325592,
      -0.008853494189679623,
      -0.03542165830731392,
      0.07293396443128586,
      0.006463530007749796,
      0.037908587604761124,
      -0.008820203132927418,
      0.06780122220516205,
      0.02238062210381031,
      -0.006911600008606911,
      0.02778707817196846,
      -0.030837032943964005,
      -0.07126729190349579,
      -0.020374327898025513,
      -0.02161978743970394,
      0.0026204355526715517,
      0.023357277736067772,
      0.008924219757318497,
      0.058187324553728104,
      -0.037121910601854324,
      -0.05037437006831169,
      0.010235382243990898,
      0.017727913334965706,
      0.07892312854528427,
      -0.0018626782111823559,
      0.06115728244185448,
      0.060754165053367615,
      0.0355491079390049,
      0.056348029524087906,
      -0.022498421370983124,
      0.009506803937256336,
      0.004387461114674807,
      0.022665414959192276,
      -0.07249853760004044,
      0.03121206723153591,
      -0.04620260372757912,
      -0.015309298411011696,
      0.032678574323654175,
      0.03564733639359474,
      -0.017511660233139992,
      -0.07211150974035263,
      0.022656520828604698,
      -0.014315108768641949,
      0.012801255099475384,
      -0.007605331018567085,
      -0.006469006650149822,
      0.05943259224295616,
      0.02223459631204605,
      -0.02168641798198223,
      -0.0985555499792099,
      -0.009305351413786411,
      0.0038812451530247927,
      0.06097278743982315,
      -0.0015059267170727253,
      0.07454948872327805,
      0.02015840634703636,
      -0.010114775970578194,
      -0.025224093347787857,
      -0.13622935116291046,
      0.03203536570072174,
      -0.06956081092357635,
      0.025010164827108383,
      -0.058956217020750046,
      -0.016859853640198708,
      0.04791492596268654,
      -0.04904363676905632,
      0.007570998277515173,
      -0.09823218733072281,
      -0.01875694841146469,
      0.033957209438085556,
      -0.06588918715715408,
      -0.04010383039712906,
      -0.047859031707048416,
      -0.0017999117262661457,
      -0.01314029935747385,
      0.004724403843283653,
      -0.021800626069307327,
      0.024114474654197693,
      -0.009351427666842937,
      -0.07003968209028244,
      0.025016287341713905,
      0.06088845804333687,
      0.028716979548335075,
      0.05254241079092026,
      0.02130722627043724,
      0.036250535398721695,
      -0.006937076803296804,
      -0.02802041359245777,
      -0.05114643648266792,
      0.033983368426561356,
      -0.021503310650587082
    ],
    [
      -0.016462307423353195,
      -0.023487083613872528,
      -0.014257259666919708,
      -0.061868906021118164,
      0.04672364890575409,
      -0.004161123652011156,
      0.015015755780041218,
      0.06417771428823471,
      0.04728436470031738,
      0.04076673462986946,
      0.03150743991136551,
      0.024194782599806786,
      -0.03170532360672951,
      0.000588981609325856,
      0.10335780680179596,
      -0.03132285922765732,
      0.06697814166545868,
      0.018195265904068947,
      0.0033705353271216154,
      -0.027846327051520348,
      -0.012659198604524136,
      0.040882643312215805,
      0.02998856082558632,
      0.07125283032655716,
      -0.0006596745224669576,
      0.07378412783145905,
      0.03178349509835243,
      0.04751606658101082,
      -0.02574329264461994,
      0.01942751742899418,
      0.1379520297050476,
      0.017699390649795532,
      -0.03179076313972473,
      -0.036783576011657715,
      -0.02382022514939308,
      0.03206413984298706,
      -0.0798567607998848,
      0.0460456945002079,
      -0.02376282773911953,
      0.08417627960443497,
      0.02829809859395027,
      0.04713903367519379,
      -0.07220833748579025,
      -0.03235191851854324,
      -0.002674780786037445,
      0.02189232036471367,
      0.022054795175790787,
      -0.057441528886556625,
      0.02535739727318287,
      0.038208942860364914,
      -0.08675561845302582,
      0.04653870314359665,
      0.04919270798563957,
      -0.030479509383440018,
      0.06356452405452728,
      -0.006975923199206591,
      -0.006731369532644749,
      -0.0006395704695023596,
      0.04719208925962448,
      -0.013458987697958946,
      -0.04985461384057999,
      0.02596510387957096,
      0.03154292702674866,
      -0.03261137008666992,
      -0.02977116033434868,
      0.03788015618920326,
      -0.05913309380412102,
      -0.012695773504674435,
      0.04767211899161339,
      0.0018118128646165133,
      0.010114247910678387,
      -0.05602572485804558,
      -0.07728748023509979,
      0.0532434843480587,
      -0.010549032129347324,
      0.015187781304121017,
      0.037553299218416214,
      -0.009542091749608517,
      -0.01858191378414631,
      -0.053267888724803925,
      0.023789940401911736,
      -0.08723145723342896,
      -0.08947985619306564,
      -0.015038340352475643,
      -0.03558690473437309,
      0.10855836421251297,
      0.056405507028102875,
      -0.02836427092552185,
      0.051324110478162766,
      0.0017004681285470724,
      -0.029333969578146935,
      -0.006309241056442261,
      -0.004458578769117594,
      -0.04981539398431778,
      0.021178076043725014,
      -0.031658921390771866,
      0.040976542979478836,
      -0.03492734208703041,
      -0.006171235349029303,
      0.01933775283396244,
      0.051796842366456985,
      -0.004256755579262972,
      -0.029616117477416992,
      -0.016918789595365524,
      0.005247598048299551,
      -0.03975385054945946,
      0.026325853541493416,
      0.014468817040324211,
      -0.05443023145198822,
      0.033533088862895966,
      -0.015354943461716175,
      0.005923723801970482,
      0.012206396088004112,
      0.03440498188138008,
      -0.04150933027267456,
      0.028368091210722923,
      0.019828686490654945,
      0.03609101474285126,
      0.030326535925269127,
      -0.0296586062759161,
      -0.017295124009251595,
      0.046421222388744354,
      -0.0240803025662899,
      -0.008306792937219143,
      0.08797711879014969,
      -0.017649734392762184,
      -0.022977007552981377,
      0.050237178802490234,
      0.046323858201503754,
      0.03406551852822304,
      -0.029543820768594742,
      -0.08919885754585266,
      0.019764920696616173,
      0.08404030650854111,
      -0.002481424016878009,
      -0.05805462226271629,
      0.0026095183566212654,
      -0.012400258332490921,
      0.016073960810899734,
      0.02107367478311062,
      -0.024226689711213112,
      -0.0388665609061718,
      0.030950507149100304,
      -0.07263316214084625,
      -0.0345572791993618,
      0.06658481806516647,
      -0.023894159123301506,
      -0.04232221469283104,
      0.02919951267540455,
      0.03996888920664787,
      0.03875165060162544,
      0.008750757202506065,
      0.02683841623365879,
      -0.041881512850522995,
      -0.03525766730308533,
      0.032418448477983475,
      -0.012428018264472485,
      0.019150199368596077,
      -0.05371176078915596,
      -0.07657362520694733,
      0.006105384789407253,
      0.012744136154651642,
      -0.021200241521000862,
      0.0654672309756279,
      -0.07379543036222458,
      -0.06210406869649887,
      -0.038848068565130234,
      -0.0459306575357914,
      -0.03017040528357029,
      -0.011990023776888847,
      0.025477750226855278,
      0.04602855071425438,
      -0.0292076226323843,
      0.04216954857110977,
      -0.0021065804176032543,
      0.07950888574123383,
      0.044850192964076996,
      0.011948234401643276,
      0.08573245257139206,
      0.01782446727156639,
      0.006955447606742382,
      -0.05418500676751137,
      0.0500638522207737,
      0.025729922577738762,
      -0.03559815511107445,
      0.006837198510766029,
      -0.006676631048321724,
      0.05128421634435654,
      0.03213813900947571,
      -0.05860384181141853,
      -0.011245916597545147,
      0.053441472351551056,
      0.06760082393884659,
      0.03269743546843529,
      0.09085066616535187,
      -0.01906994916498661,
      0.007838121615350246,
      -0.008505091071128845,
      0.013809671625494957,
      -0.06304433941841125,
      0.0382254533469677,
      -0.020649228245019913,
      -0.01163527276366949,
      0.04709678143262863,
      0.04311741888523102,
      -0.013504521921277046,
      0.017478924244642258,
      0.0023715123534202576,
      -0.00758904917165637,
      0.01306203007698059,
      -0.0012483312748372555,
      0.00399815896525979,
      0.0508151538670063,
      0.07267694920301437,
      -0.07452641427516937,
      -0.029298651963472366,
      0.030930399894714355,
      -0.014838900417089462,
      0.01011758390814066,
      -0.06820773333311081,
      -0.027404602617025375,
      0.08262680470943451,
      -0.06256509572267532,
      -0.017179030925035477,
      0.0058604334481060505,
      -0.011953573673963547,
      0.026977473869919777,
      -0.03721773251891136,
      -0.014730498194694519,
      0.02120017446577549,
      0.10983549803495407,
      0.10661733895540237,
      -0.017690787091851234,
      0.025323903188109398,
      -0.03909245878458023,
      -0.005635308101773262,
      -0.01555759459733963,
      -0.018272019922733307,
      -0.04540207237005234,
      -0.026975668966770172,
      -0.041138097643852234,
      -0.03557860478758812,
      -0.0046516950242221355,
      0.006138930097222328,
      0.03582454472780228,
      -0.007819920778274536,
      -0.00351603701710701,
      -0.016476433724164963,
      -0.01786837726831436,
      -0.00114086561370641,
      0.026071788743138313,
      -0.013104831799864769,
      -0.0005170676740817726,
      -0.021466834470629692,
      -0.04855101555585861,
      -0.027359139174222946,
      -0.11444197595119476,
      0.032142531126737595,
      -0.052228040993213654,
      -0.03545471653342247,
      0.004125333856791258,
      0.051317308098077774,
      0.01205320656299591,
      -0.08620691299438477,
      0.004143071826547384,
      -0.006292457226663828,
      -0.15644630789756775,
      0.04051627963781357,
      -0.05949082225561142,
      -0.07297904044389725,
      -0.10455632954835892,
      0.03931455314159393,
      0.01674245484173298,
      0.008405212312936783,
      0.05826743692159653,
      -0.05988870561122894,
      -0.09981482475996017,
      0.06893875449895859,
      0.019279100000858307,
      -0.018149206414818764,
      0.043416764587163925,
      0.030919069424271584,
      -0.006595095153898001,
      -0.12465129047632217,
      -0.061597153544425964,
      0.050529733300209045,
      -0.08674003928899765,
      0.07195710390806198,
      0.08099234104156494,
      0.003241637721657753,
      -0.07298370450735092,
      -0.06746458262205124,
      -0.0070798867382109165,
      0.05976206809282303,
      0.02326284535229206,
      0.04804164171218872,
      0.004109857138246298,
      0.014077600091695786,
      -0.13061289489269257,
      -0.030642759054899216,
      0.029676685109734535,
      0.07557225227355957,
      -0.026609353721141815,
      0.03498860076069832,
      0.0006725619314238429,
      0.02246955968439579,
      0.04624223709106445,
      0.06985663622617722,
      -0.0624508373439312,
      0.062390781939029694,
      0.01973075419664383,
      0.03090539015829563,
      0.02684974856674671,
      0.03364164009690285,
      0.0810019001364708,
      0.005672407802194357,
      0.014642106369137764,
      0.0365540087223053,
      0.025681011378765106,
      0.060207393020391464,
      -0.041128117591142654,
      0.04465455189347267,
      -0.04682478681206703,
      -0.11978569626808167,
      -0.007174768019467592,
      -0.060698721557855606,
      -0.04792206734418869,
      -0.029998406767845154,
      0.022337840870022774,
      0.048926617950201035,
      -0.03859439119696617,
      0.018253544345498085,
      -0.01866896264255047,
      -0.02934037521481514,
      -0.030117396265268326,
      0.004197837319225073,
      -0.007140953093767166,
      -0.010003597475588322,
      0.016816481947898865,
      -0.08688955008983612,
      0.017310598865151405,
      0.035132311284542084,
      -0.039046481251716614,
      0.010593266226351261,
      0.029489681124687195,
      0.11114667356014252,
      -0.02081369236111641,
      0.07779669016599655,
      -0.03978976607322693,
      0.007055336143821478,
      0.037780940532684326,
      -0.027676375582814217,
      0.061531808227300644,
      0.015944460406899452,
      -0.014250059612095356,
      -0.004183578304946423,
      -0.04431235417723656,
      0.013979506678879261,
      -0.0867389515042305,
      -0.008631161414086819,
      -0.044226668775081635,
      0.034872353076934814,
      0.02126208506524563,
      0.016117868945002556,
      0.01138696726411581,
      0.010573117062449455,
      -0.059482015669345856,
      0.05837557092308998,
      0.04231196269392967,
      0.07358679175376892,
      0.0135814119130373,
      0.0678114965558052,
      0.016368934884667397,
      0.0464075431227684,
      -0.010645314119756222,
      0.04530596733093262,
      -0.1063477098941803,
      -0.01926996372640133,
      -0.0077330791391432285,
      0.004016784019768238,
      -0.044012755155563354,
      0.04403359070420265,
      0.07391633093357086,
      0.0644303411245346,
      0.002531456295400858,
      -0.006940808147192001,
      -0.0020601695869117975,
      0.017215166240930557,
      -0.02824380435049534,
      -0.05945836007595062,
      0.05838003754615784,
      -0.08518550544977188,
      0.029222680255770683,
      0.018824603408575058,
      -0.03985821083188057,
      0.006773012690246105,
      0.021168779581785202,
      0.10990476608276367,
      -0.027640027925372124,
      -0.048107534646987915,
      0.00017295956786256284,
      0.10502904653549194,
      0.1248011589050293,
      -0.023930007591843605,
      -0.038964271545410156,
      0.04304712638258934,
      0.021976586431264877,
      -0.0048628016375005245,
      -0.033336542546749115,
      -0.024696320295333862,
      0.024666503071784973,
      -0.019861819222569466,
      -0.08739504218101501,
      0.033641282469034195,
      0.023150842636823654,
      -0.029169514775276184,
      -0.00438028946518898,
      -0.020316559821367264,
      -0.04074198007583618,
      0.0042446027509868145,
      0.04988974705338478,
      0.06428086012601852,
      -0.05770277976989746,
      -0.057898856699466705,
      -0.04429182782769203,
      0.02263568341732025,
      -0.004782076925039291,
      -0.03806687146425247,
      -0.0262445118278265,
      -0.05012878403067589,
      -0.048366937786340714,
      -0.03504817932844162,
      -0.01108736451715231,
      0.004760790150612593,
      -0.0027354394551366568,
      -0.03622528910636902,
      -0.044278986752033234,
      -0.007081258576363325,
      0.06610698997974396,
      -0.08777926117181778,
      -0.07397597283124924,
      -0.05332662910223007,
      -0.03454143926501274,
      -0.053282689303159714,
      -0.016843391582369804,
      -0.005010322201997042,
      0.04500270262360573,
      -0.012717251665890217,
      0.02604781836271286,
      0.031871598213911057,
      -0.04643195495009422,
      0.006252857856452465,
      -0.031215418130159378,
      -0.03530563414096832,
      0.024210555478930473,
      -0.013301093131303787,
      0.04790510982275009,
      0.019210882484912872,
      0.018748728558421135,
      -0.06925159692764282,
      -0.1186719611287117,
      -0.039544980973005295,
      -0.0020668937359005213,
      -0.04496488720178604,
      -0.028315242379903793,
      -0.07672114670276642,
      0.004781181924045086,
      0.030143938958644867,
      0.020654508844017982,
      0.04695744812488556,
      0.0866188034415245,
      -0.08332374691963196,
      0.0680978074669838,
      0.04252398759126663,
      -0.03843699023127556,
      0.04998333379626274,
      -0.04735289514064789,
      0.02670234814286232,
      0.010418757796287537,
      0.04477193206548691,
      0.0017863198881968856,
      -0.009462283924221992,
      0.09440112859010696,
      -0.03451152890920639,
      0.044246263802051544,
      -0.056951384991407394,
      0.024511972442269325,
      0.06579461693763733,
      -0.010912169702351093,
      0.11852304637432098,
      0.011421694420278072,
      0.020721621811389923,
      0.04316098988056183,
      -0.03009197860956192,
      0.004100827965885401,
      -0.09448555111885071,
      -0.02020774409174919,
      -0.04598015919327736,
      -0.045209020376205444,
      -0.11494569480419159,
      0.04403942823410034,
      -0.09172415733337402,
      0.06629455834627151
    ],
    [
      -0.00130261795129627,
      0.003719049971550703,
      -0.008412896655499935,
      -0.007697817403823137,
      0.11173834651708603,
      0.08199980109930038,
      -0.029568061232566833,
      0.05968230590224266,
      0.05807849019765854,
      0.0036229100078344345,
      -0.0046990253031253815,
      0.025379423052072525,
      0.002636580727994442,
      0.004500571172684431,
      0.05927077308297157,
      0.04062559828162193,
      0.11099007725715637,
      -0.01893087662756443,
      -0.031217249110341072,
      0.005682449322193861,
      -0.06086942180991173,
      -0.03563414514064789,
      -0.055029500275850296,
      -0.029523195698857307,
      -0.12203217297792435,
      0.06767792254686356,
      -0.122524194419384,
      0.036660049110651016,
      -0.02752278372645378,
      0.0044042435474693775,
      0.013745585456490517,
      0.023229369893670082,
      0.049216438084840775,
      0.046040259301662445,
      -0.026564089581370354,
      0.00036334566539153457,
      0.037367984652519226,
      -0.011700423434376717,
      -0.049545206129550934,
      -0.015118218027055264,
      0.03519384190440178,
      -0.013301476836204529,
      0.06788189709186554,
      0.07189606875181198,
      -0.023264722898602486,
      -0.0772685706615448,
      -0.049347732216119766,
      -0.014107604511082172,
      -0.014991126023232937,
      0.04910117760300636,
      0.014475743286311626,
      0.030583929270505905,
      -0.07336386293172836,
      0.01021886058151722,
      -0.027671506628394127,
      0.004995046183466911,
      -0.001988822128623724,
      -0.019318154081702232,
      0.021247850731015205,
      0.005173390731215477,
      0.055896732956171036,
      0.012176260352134705,
      0.039351191371679306,
      -0.046046238392591476,
      0.09497690200805664,
      0.007163212168961763,
      0.04062897711992264,
      -0.022084102034568787,
      -0.03299247846007347,
      -0.05037389695644379,
      -0.028765510767698288,
      0.041771166026592255,
      0.05203339830040932,
      -0.07651430368423462,
      0.03138386458158493,
      0.018130311742424965,
      -0.01353888027369976,
      0.030575109645724297,
      0.011544883251190186,
      -0.048259567469358444,
      0.010492970235645771,
      0.030423525720834732,
      -0.013960548676550388,
      -0.05534428730607033,
      0.04673182591795921,
      0.019819285720586777,
      -0.02707463689148426,
      -0.056833770126104355,
      0.045807257294654846,
      -0.02733662724494934,
      -0.0370691642165184,
      0.04903746023774147,
      -0.03079098090529442,
      0.012072620913386345,
      0.02562529779970646,
      -0.06368079781532288,
      0.013537746854126453,
      -0.005347921047359705,
      0.034714989364147186,
      -0.11053586006164551,
      -0.02139635756611824,
      -0.005141149275004864,
      0.038645707070827484,
      -0.023046493530273438,
      -0.09484236687421799,
      0.04786020889878273,
      -0.07135707885026932,
      0.0002475679211784154,
      -0.03984539583325386,
      0.07031772285699844,
      -0.003952579572796822,
      -0.07386817038059235,
      -0.05157371610403061,
      -0.04373202845454216,
      0.011979798786342144,
      -0.05040239170193672,
      0.009118819609284401,
      -0.0504467599093914,
      0.05462009087204933,
      0.0196539256721735,
      0.0687350258231163,
      0.053822994232177734,
      0.01884145475924015,
      -0.019392671063542366,
      -0.0594150610268116,
      0.07354535907506943,
      0.008754302747547626,
      0.04532482475042343,
      -0.06271662563085556,
      -0.020342867821455002,
      0.001464213477447629,
      -0.045498356223106384,
      0.0023467110004276037,
      0.03815911337733269,
      -0.0034266586881130934,
      0.027460185810923576,
      0.061356667429208755,
      0.02012823522090912,
      -0.025749657303094864,
      0.06299332529306412,
      -0.005929663777351379,
      -0.03528022766113281,
      0.11837038397789001,
      0.013564866036176682,
      -0.0014636871637776494,
      0.014950810931622982,
      0.0026632968802005053,
      0.01993652619421482,
      -0.039773520082235336,
      -0.027246881276369095,
      -0.017551366239786148,
      0.0015349939931184053,
      -0.008752654306590557,
      -0.04168212041258812,
      0.015027298592031002,
      0.023947017267346382,
      0.004944491665810347,
      0.011343995109200478,
      -0.05443927273154259,
      0.05876678600907326,
      -0.00946402084082365,
      -0.09934264421463013,
      -0.01075433287769556,
      -2.9841299692634493e-05,
      -0.03138652816414833,
      -0.04894663020968437,
      -0.04579402133822441,
      -0.03872302174568176,
      -0.030242886394262314,
      0.06937219947576523,
      0.04305890202522278,
      0.03150109574198723,
      -0.056378163397312164,
      0.08714431524276733,
      -0.005173847544938326,
      -0.030336283147335052,
      -0.037862349301576614,
      -0.03134581074118614,
      0.025685278698801994,
      -0.015412848442792892,
      -0.039846811443567276,
      -0.036651551723480225,
      -0.070252425968647,
      0.0208046343177557,
      -0.004505079239606857,
      0.07968282699584961,
      -0.06265416741371155,
      -0.06613607704639435,
      -0.04374873265624046,
      -0.06666415184736252,
      0.01847153902053833,
      0.11558858305215836,
      0.00964444875717163,
      0.003951315302401781,
      -0.002739791991189122,
      -0.03725733608007431,
      -0.11276538670063019,
      0.004064009990543127,
      -0.04095113277435303,
      -0.038464926183223724,
      -0.05818238854408264,
      0.05760367959737778,
      -0.03076731227338314,
      0.06130126863718033,
      -0.0073887137696146965,
      -0.023439638316631317,
      -0.07518431544303894,
      -0.07566910982131958,
      0.04104379937052727,
      0.025691673159599304,
      -0.05442018806934357,
      0.05023767426609993,
      0.05608931556344032,
      0.038945507258176804,
      -0.043055854737758636,
      0.026304803788661957,
      0.035299718379974365,
      -0.01524494867771864,
      0.004877787083387375,
      0.010908052325248718,
      -0.10601489245891571,
      0.010206147097051144,
      -0.05470123142004013,
      -0.08668762445449829,
      -0.02176385000348091,
      -0.11535967886447906,
      0.04108307138085365,
      -0.06916189193725586,
      0.034888796508312225,
      -0.0343814417719841,
      -0.01234338991343975,
      0.016662660986185074,
      0.007086470257490873,
      -0.008591324090957642,
      -0.027118725702166557,
      0.004506663419306278,
      -0.024903984740376472,
      -0.020349502563476562,
      0.025867633521556854,
      0.0340539813041687,
      -0.040045056492090225,
      0.02257690392434597,
      0.011938869021832943,
      -0.04602964594960213,
      -0.007023814134299755,
      0.16236726939678192,
      0.03337330371141434,
      0.10068262368440628,
      0.022545240819454193,
      -0.0037552169524133205,
      -0.04977993294596672,
      -0.032660797238349915,
      0.09075666964054108,
      0.010015024803578854,
      -0.0279302429407835,
      0.00857464037835598,
      0.03484303876757622,
      -0.05736411735415459,
      -0.011924353428184986,
      -0.03305831551551819,
      0.04613446444272995,
      0.062247924506664276,
      0.02195870876312256,
      0.04458751901984215,
      -0.038845911622047424,
      0.018769996240735054,
      0.13884517550468445,
      0.0023854412138462067,
      -0.005759267136454582,
      -0.04397867992520332,
      0.048234205693006516,
      0.020076120272278786,
      0.0017520677065476775,
      0.026069365441799164,
      0.02655188925564289,
      -0.011398172937333584,
      -0.03163523226976395,
      -0.066081203520298,
      -0.010901983827352524,
      -0.06740361452102661,
      0.03160359337925911,
      0.01770823262631893,
      0.010672231204807758,
      0.007261405698955059,
      -0.0911131426692009,
      0.017948225140571594,
      0.01663985848426819,
      0.07464882731437683,
      -0.02620522677898407,
      -0.014792273752391338,
      -0.022485651075839996,
      -0.11326609551906586,
      0.002383057028055191,
      -0.01511531975120306,
      -0.02319808304309845,
      0.026564033702015877,
      0.06715811789035797,
      -0.0013161853421479464,
      -0.04611549153923988,
      -0.043031904846429825,
      0.02413635328412056,
      -0.03306596726179123,
      0.0825698971748352,
      -0.018931932747364044,
      0.04918805509805679,
      -0.044797517359256744,
      0.05455869063735008,
      0.08562242984771729,
      -0.11695874482393265,
      0.002884347690269351,
      0.05831603705883026,
      -0.008018954657018185,
      0.030006591230630875,
      -0.050130050629377365,
      0.025782214477658272,
      0.02570585161447525,
      -0.012777675874531269,
      0.06376591324806213,
      0.0193464495241642,
      -0.03265799209475517,
      0.002565923612564802,
      -0.1617175042629242,
      -0.002213322091847658,
      0.0454457625746727,
      0.04059929400682449,
      -0.004934428259730339,
      0.05183670297265053,
      0.030301926657557487,
      -0.0433354526758194,
      0.030579878017306328,
      -0.06391815841197968,
      -0.011553829535841942,
      -0.015956973657011986,
      0.05932630971074104,
      -0.021552376449108124,
      -0.05414475128054619,
      0.04903735592961311,
      0.035268384963274,
      0.005601550918072462,
      0.009343566372990608,
      -0.0076635186560451984,
      0.00413166219368577,
      -0.03412541374564171,
      -0.033858973532915115,
      -0.003131489036604762,
      -0.0031687601003795862,
      0.038423649966716766,
      0.02252943441271782,
      0.00219009374268353,
      -0.020625585690140724,
      -0.0012116060825064778,
      0.023502368479967117,
      0.037780970335006714,
      0.013112390413880348,
      -0.0357014499604702,
      -0.013234789483249187,
      -0.04543094336986542,
      -0.07910032570362091,
      -0.03229961171746254,
      0.020074471831321716,
      0.009413809515535831,
      -0.02202029712498188,
      -0.05441415682435036,
      0.007885871455073357,
      -0.014307323843240738,
      -0.041124261915683746,
      0.06046081334352493,
      0.049453504383563995,
      0.0065699550323188305,
      0.029506640508770943,
      0.01649017632007599,
      -0.0035849891137331724,
      0.0021128212101757526,
      0.013972747139632702,
      -0.05159632861614227,
      0.008181451819837093,
      0.06511682271957397,
      -0.022731494158506393,
      0.030885646119713783,
      0.049904126673936844,
      0.018843702971935272,
      0.03742413967847824,
      -0.025103552266955376,
      -0.04199915751814842,
      -0.005694910883903503,
      0.04316164180636406,
      -0.04442109167575836,
      0.05114654451608658,
      -0.07782474905252457,
      0.032025065273046494,
      -0.01419673953205347,
      -0.05659181997179985,
      -0.060235366225242615,
      0.05477437376976013,
      0.03315059468150139,
      -0.036439523100852966,
      -0.05511996150016785,
      0.03423803672194481,
      0.07427041977643967,
      -0.01205793209373951,
      -0.026875533163547516,
      0.031766127794981,
      0.03762537240982056,
      -0.07228856533765793,
      -0.013521796092391014,
      0.016425594687461853,
      0.02549905888736248,
      -0.006892991717904806,
      -0.08172667026519775,
      -0.055091798305511475,
      0.028844745829701424,
      0.030464624986052513,
      0.011255116201937199,
      0.061776675283908844,
      -0.012544029392302036,
      0.021649789065122604,
      0.051570482552051544,
      -0.12038830667734146,
      0.09872325509786606,
      0.034875620156526566,
      0.03539089113473892,
      0.04114879295229912,
      0.04204047471284866,
      0.0036144303157925606,
      0.06948387622833252,
      0.025354795157909393,
      0.11269503831863403,
      0.049458689987659454,
      0.05152151733636856,
      0.02507122978568077,
      -0.07147987186908722,
      -0.041308969259262085,
      -0.03725985437631607,
      0.020275846123695374,
      0.022425491362810135,
      0.061730075627565384,
      -0.04672319069504738,
      0.01222182810306549,
      -0.09577365964651108,
      -0.015617365948855877,
      0.009202047251164913,
      -0.02632666565477848,
      0.025153012946248055,
      -0.00024504659813828766,
      -0.024587256833910942,
      0.07034643739461899,
      0.03077678009867668,
      0.08616065233945847,
      0.0352616161108017,
      0.02485721744596958,
      -0.0678367167711258,
      -0.004965915344655514,
      -0.009147069416940212,
      -0.004113369155675173,
      -0.005264820531010628,
      -0.026415975764393806,
      -0.060220032930374146,
      -0.04268836975097656,
      0.08389552682638168,
      -0.046033844351768494,
      0.015760792419314384,
      -0.011970283463597298,
      2.922756721090991e-05,
      -0.008342458866536617,
      0.048643726855516434,
      -0.03815801441669464,
      0.028317496180534363,
      -0.08950667083263397,
      -0.07509870827198029,
      -0.014392895624041557,
      -0.004044453613460064,
      0.03554517403244972,
      -0.053902775049209595,
      0.02529429830610752,
      -0.017376035451889038,
      0.01347206812351942,
      -0.04640630632638931,
      0.09947352111339569,
      0.025513865053653717,
      0.03182585909962654,
      -0.04202600196003914,
      0.06436599791049957,
      0.08034426718950272,
      -0.01157374121248722,
      -0.029417332261800766,
      -0.06557914614677429,
      -0.01955106481909752,
      -0.006288451608270407,
      -0.05726620554924011,
      -0.03238604962825775,
      -0.008754930458962917,
      0.04937134310603142,
      -0.013752959668636322,
      0.034783538430929184,
      -0.0020923044066876173,
      -0.06433907896280289,
      -0.06405634433031082,
      -0.006005795672535896,
      0.02445918694138527,
      0.010603995062410831,
      0.027508271858096123,
      -0.036711033433675766,
      0.044666849076747894
    ],
    [
      0.0555405430495739,
      -0.0031065084040164948,
      -0.027502302080392838,
      -0.07299350947141647,
      -0.021667355671525,
      -0.04230301082134247,
      0.002648974070325494,
      -0.024480892345309258,
      0.02627757377922535,
      -0.047747716307640076,
      -0.03418339416384697,
      -0.06850259751081467,
      0.04606086388230324,
      -0.017491698265075684,
      0.0004947523702867329,
      -0.023261617869138718,
      0.004283187445253134,
      -0.014785338193178177,
      0.00036268774420022964,
      0.0074877142906188965,
      0.0595194511115551,
      -0.006076442077755928,
      -0.012985894456505775,
      0.015381990000605583,
      -0.04385548457503319,
      0.04382955655455589,
      -0.0003480278537608683,
      0.08134463429450989,
      -0.003973915707319975,
      -0.08168698102235794,
      0.026368938386440277,
      0.0023276100400835276,
      0.024356963112950325,
      0.0350557304918766,
      0.003648637095466256,
      0.01302427053451538,
      0.07328477501869202,
      -0.05075158551335335,
      -0.015381059609353542,
      -0.04326155409216881,
      0.0383962020277977,
      0.05534244328737259,
      0.03171919658780098,
      -0.053334325551986694,
      0.07832521945238113,
      0.05541851371526718,
      0.0237349234521389,
      0.06150668114423752,
      -0.05141260102391243,
      -0.028758304193615913,
      -0.05405953526496887,
      0.07078801095485687,
      0.05821322277188301,
      -0.034633491188287735,
      0.07072479277849197,
      0.011176388710737228,
      0.01448971126228571,
      -0.02343408204615116,
      -0.10278140753507614,
      -0.0338984876871109,
      0.055666033178567886,
      0.037040043622255325,
      -0.0166766420006752,
      0.009465671144425869,
      -0.004463477060198784,
      -0.07491356879472733,
      -0.04950946941971779,
      0.03035788983106613,
      -0.02481835149228573,
      -0.02577051892876625,
      0.0007422059425152838,
      -0.016482915729284286,
      0.014905443415045738,
      -0.0021016725804656744,
      -0.03033808059990406,
      0.01665515825152397,
      -0.011052064597606659,
      0.006890313234180212,
      -0.020302647724747658,
      -0.04138128086924553,
      -0.07357168942689896,
      -0.026082763448357582,
      -0.04946231096982956,
      -0.04914617910981178,
      0.027757413685321808,
      0.0009380052797496319,
      0.06455263495445251,
      0.03330172598361969,
      0.033883772790431976,
      -0.05889434739947319,
      -0.012460967525839806,
      -0.04295450076460838,
      -0.050042904913425446,
      0.0596749484539032,
      0.1100311130285263,
      -0.0056644887663424015,
      0.05367560312151909,
      0.03900851681828499,
      0.010544092394411564,
      -0.11668659001588821,
      0.09807907044887543,
      0.045937176793813705,
      -0.06255792081356049,
      -0.012898401357233524,
      -0.028383756056427956,
      0.002596999052911997,
      -0.04351913928985596,
      0.0022162236273288727,
      0.004634182900190353,
      0.013962892815470695,
      -0.04520555958151817,
      0.07310862094163895,
      0.05455809086561203,
      0.030555449426174164,
      0.007394807878881693,
      0.05939577892422676,
      0.04103938862681389,
      -0.013735589571297169,
      -0.010184201411902905,
      -0.04080837592482567,
      0.028863217681646347,
      -0.04034595191478729,
      -0.012706163339316845,
      -0.048744626343250275,
      -0.016235461458563805,
      -0.1340557336807251,
      0.0010727870976552367,
      0.017440231516957283,
      -0.04967493936419487,
      0.046913016587495804,
      -0.0727141872048378,
      -0.1218055859208107,
      -0.003450364340096712,
      -0.06007631868124008,
      -0.08541436493396759,
      0.010678043588995934,
      0.0026406366378068924,
      0.07573851943016052,
      -0.023438120260834694,
      -0.021729957312345505,
      0.023142460733652115,
      -0.014992491342127323,
      -0.007193801924586296,
      0.06674183160066605,
      -0.07173210382461548,
      -0.08198586851358414,
      0.04401816427707672,
      0.028552697971463203,
      0.008532727137207985,
      -0.03176655247807503,
      -0.010773050598800182,
      -0.09509752690792084,
      0.0994901955127716,
      0.014931191690266132,
      -0.021671516820788383,
      0.01786852441728115,
      0.004787950776517391,
      0.04568878933787346,
      0.08611097186803818,
      0.012500840239226818,
      -0.04119497165083885,
      0.06754092872142792,
      0.0051907324232161045,
      0.006104792933911085,
      -0.003174738958477974,
      -0.07021722197532654,
      -0.07356206327676773,
      -0.04241572692990303,
      0.040419235825538635,
      -0.05312103405594826,
      0.038606029003858566,
      -0.02396315522491932,
      0.034675758332014084,
      0.04654383659362793,
      0.05947055295109749,
      -0.003037613583728671,
      -0.05037255585193634,
      -0.010245111770927906,
      -0.02652418427169323,
      0.028639886528253555,
      -0.012677257880568504,
      -0.0024272757582366467,
      0.030188225209712982,
      0.10641937702894211,
      -0.02997460961341858,
      -0.008800789713859558,
      0.013289344497025013,
      -0.03290494903922081,
      -0.015684455633163452,
      -0.0566449798643589,
      0.002470550360158086,
      0.11732785403728485,
      0.009831534698605537,
      -0.026423275470733643,
      0.0009704817784950137,
      0.014430721290409565,
      -0.07028575241565704,
      -0.029363445937633514,
      0.02331690676510334,
      -0.025013688951730728,
      -0.007948687300086021,
      -0.00015826993330847472,
      0.01624901033937931,
      0.007679931353777647,
      -0.03973192721605301,
      -0.006281751673668623,
      -0.10064365714788437,
      0.03669261187314987,
      -0.009601404890418053,
      0.05149984359741211,
      -0.0378999263048172,
      0.07001835107803345,
      -0.010927981697022915,
      0.08537720143795013,
      -0.02368970960378647,
      -0.002501853508874774,
      -0.02218136563897133,
      -0.046504151076078415,
      0.053648240864276886,
      0.03916018083691597,
      0.0015920501900836825,
      0.052143823355436325,
      0.05859837681055069,
      -0.04271002486348152,
      -0.04740442708134651,
      0.01469038613140583,
      -0.08754465728998184,
      0.003695667488500476,
      -0.08579431474208832,
      -0.02464112639427185,
      -0.011287102475762367,
      0.006250332109630108,
      -0.08168632537126541,
      -0.057706356048583984,
      -0.0270681194961071,
      -0.04694700613617897,
      0.008381323888897896,
      -0.0017256319988518953,
      -0.12550044059753418,
      -0.035278547555208206,
      -0.01840554177761078,
      -0.03252428025007248,
      0.036390408873558044,
      0.02916930802166462,
      -0.042735304683446884,
      0.08525948226451874,
      0.07310318201780319,
      0.017717896029353142,
      -0.03411845117807388,
      0.028578557074069977,
      -0.030810818076133728,
      0.026308538392186165,
      -0.027769211679697037,
      0.07284854352474213,
      0.01129805389791727,
      -0.026051804423332214,
      0.033747002482414246,
      -0.05343632400035858,
      -0.013483415357768536,
      -0.08035514503717422,
      -0.031486332416534424,
      0.019394949078559875,
      0.05515426769852638,
      -0.03456176444888115,
      -0.06215088441967964,
      -0.0077935922890901566,
      0.08252441138029099,
      -0.027641648426651955,
      0.022170262411236763,
      -0.000943504914175719,
      -0.03719889372587204,
      -0.008475241251289845,
      -0.007089504972100258,
      -0.03060326911509037,
      -0.013598385266959667,
      0.011706632561981678,
      0.007647426333278418,
      -0.07891220599412918,
      0.06339205801486969,
      0.04980506747961044,
      -0.027802905067801476,
      0.03061549738049507,
      0.06469417363405228,
      0.058052193373441696,
      -0.005060732830315828,
      -0.0032482119277119637,
      0.018510814756155014,
      0.03890828788280487,
      0.03700338304042816,
      0.09603002667427063,
      0.019315442070364952,
      -0.06092512235045433,
      0.10466060787439346,
      0.08164636045694351,
      -0.007857616059482098,
      -0.000728011887986213,
      0.024749131873250008,
      -0.11778251081705093,
      -0.04022208973765373,
      -0.07096273452043533,
      -0.0857946053147316,
      -0.005428899545222521,
      0.0969499796628952,
      -0.0832076370716095,
      0.05743405595421791,
      -0.045861877501010895,
      0.04045127332210541,
      -0.08584950119256973,
      -0.05656740069389343,
      -0.012550987303256989,
      -0.021242452785372734,
      -0.014536743052303791,
      -0.031976278871297836,
      -0.02802681364119053,
      -0.01970333606004715,
      -0.0279744490981102,
      0.0012865879107266665,
      -0.052501216530799866,
      -0.02014586701989174,
      0.04672820866107941,
      -0.044240161776542664,
      0.02798118069767952,
      -0.028774209320545197,
      -0.009431564249098301,
      0.027289513498544693,
      -0.043163854628801346,
      0.006368291564285755,
      0.00874464213848114,
      -0.02644856460392475,
      -0.10191439092159271,
      -0.025353100150823593,
      0.056037094444036484,
      -0.013228485360741615,
      0.022963345050811768,
      -0.016240378841757774,
      0.05289411544799805,
      0.0018081518355756998,
      0.0958159863948822,
      -0.015239414758980274,
      0.044450338929891586,
      -0.002907239133492112,
      0.0051594567485153675,
      -0.07895918190479279,
      -0.040741678327322006,
      0.025959225371479988,
      -0.025949742645025253,
      -0.04343288391828537,
      -0.06453024595975876,
      -0.03151284158229828,
      0.022037429735064507,
      -0.04166695475578308,
      0.006016118917614222,
      0.014231960289180279,
      0.07645563781261444,
      -0.018064528703689575,
      -0.02220231667160988,
      -0.08441180735826492,
      0.01027902401983738,
      0.018270261585712433,
      0.041368283331394196,
      0.03757506236433983,
      -0.00014646194176748395,
      0.060255266726017,
      0.03552163764834404,
      0.03468656167387962,
      0.059988364577293396,
      -0.01794315315783024,
      0.03258184343576431,
      0.06371626257896423,
      -0.040125321596860886,
      -0.09812591224908829,
      0.01811186783015728,
      0.020757999271154404,
      0.07405620068311691,
      -0.015122798271477222,
      0.0024010788183659315,
      0.0063245100900530815,
      -0.025643980130553246,
      -0.003990539815276861,
      0.02486610598862171,
      0.06831622123718262,
      -0.08299590647220612,
      -0.022919123992323875,
      0.016584500670433044,
      0.031409651041030884,
      -0.018443820998072624,
      0.039981912821531296,
      -0.048567406833171844,
      -0.0446966290473938,
      -0.07247216999530792,
      -0.0656457394361496,
      -0.025899341329932213,
      -0.053795356303453445,
      0.03728184476494789,
      0.030612049624323845,
      -0.006562637630850077,
      0.016919078305363655,
      0.024743933230638504,
      0.033049460500478745,
      -0.024866122752428055,
      -0.038525477051734924,
      -0.018954074010252953,
      0.014847242273390293,
      -0.0292961485683918,
      -0.04806503653526306,
      0.02670699544250965,
      -0.023994868621230125,
      -0.039126235991716385,
      -0.05772886052727699,
      -0.07585453987121582,
      -0.002009923104196787,
      0.020389718934893608,
      -0.04903635010123253,
      0.003034327644854784,
      0.048421312123537064,
      -0.013817468658089638,
      -0.019062340259552002,
      -0.021075887605547905,
      -0.02637306973338127,
      0.06677055358886719,
      -0.041642725467681885,
      -0.03209278732538223,
      0.05144428461790085,
      -0.01076242420822382,
      0.025354070588946342,
      -0.021637050434947014,
      0.10803495347499847,
      0.021242894232273102,
      0.014871735125780106,
      -0.06287448108196259,
      0.00819348730146885,
      0.00541579769924283,
      0.03899957612156868,
      0.02499920129776001,
      0.06782814115285873,
      -0.05194171890616417,
      0.03285020962357521,
      -0.01935999095439911,
      -0.07771316170692444,
      -0.04508528113365173,
      0.005462799686938524,
      -0.008895773440599442,
      -0.03716590255498886,
      -0.02851368859410286,
      0.0050997487269341946,
      0.005582487676292658,
      0.008008674718439579,
      0.06925417482852936,
      0.03434896096587181,
      -0.05380956083536148,
      -0.01832982711493969,
      -0.010741311125457287,
      -0.06034047529101372,
      0.12098643183708191,
      -0.0686301589012146,
      -0.01625506766140461,
      0.03379810228943825,
      0.015208940953016281,
      -0.03512309119105339,
      -0.011514917947351933,
      0.015210387296974659,
      0.04550168663263321,
      0.049405913800001144,
      0.004004036542028189,
      0.0028395173139870167,
      0.0106131536886096,
      0.009232576005160809,
      0.06185837462544441,
      -0.04038117825984955,
      -0.050789982080459595,
      -0.014283537864685059,
      -0.06199629232287407,
      0.06774123013019562,
      0.08393245935440063,
      0.03226683661341667,
      0.03638856112957001,
      0.053109489381313324,
      -0.052458755671978,
      0.006263836286962032,
      -0.02303423546254635,
      -0.05311346426606178,
      0.06766261905431747,
      -0.05924597755074501,
      0.07330065220594406,
      -0.030630089342594147,
      0.026764674112200737,
      -0.041711997240781784,
      -0.04239699989557266,
      -0.07003580033779144,
      -0.05767635256052017,
      -0.06626463681459427,
      -0.03139866515994072,
      0.07695181667804718,
      -0.014651636593043804,
      -0.059111665934324265,
      0.051129478961229324,
      0.014969519339501858,
      -0.047779154032468796,
      0.06271625310182571,
      0.03764376416802406,
      -0.030327074229717255,
      -0.04471238702535629,
      -0.04301368445158005
    ],
    [
      -0.008428639732301235,
      0.014976109378039837,
      0.01367107778787613,
      0.059303682297468185,
      -0.02125166542828083,
      0.04020621255040169,
      0.029756881296634674,
      -0.04360121488571167,
      -0.03812555596232414,
      0.030903514474630356,
      -0.007685489021241665,
      -0.054883334785699844,
      0.07318344712257385,
      0.07470496743917465,
      -0.010854192078113556,
      -0.0052274065092206,
      0.043108634650707245,
      0.055674467235803604,
      -0.04682367295026779,
      -0.036086779087781906,
      -0.01586054638028145,
      -0.020647082477808,
      -0.018356243148446083,
      -0.02143261581659317,
      -0.040549080818891525,
      0.028778133913874626,
      -0.05768311023712158,
      0.042961373925209045,
      -0.02646913379430771,
      -0.05411757528781891,
      -0.02594759687781334,
      -0.0761755108833313,
      -0.030991271138191223,
      -0.0628596767783165,
      0.00283349072560668,
      -0.04933324083685875,
      0.049464039504528046,
      -0.01793929934501648,
      0.010383624583482742,
      0.004677224438637495,
      0.0878034383058548,
      0.05228855460882187,
      0.011460932902991772,
      0.04881129786372185,
      -0.019898775964975357,
      0.04339649900794029,
      0.03759516403079033,
      -0.06860708445310593,
      -0.054277531802654266,
      0.04468226432800293,
      0.00315099791623652,
      -0.03412739932537079,
      0.03135882690548897,
      -0.00015662249643355608,
      -0.08036922663450241,
      0.016994046047329903,
      -0.01364889182150364,
      0.05169118195772171,
      -0.061383068561553955,
      -0.012439633719623089,
      -0.12023605406284332,
      0.007325963582843542,
      -0.13880221545696259,
      -0.05278020352125168,
      0.007285041734576225,
      -0.012524343095719814,
      0.024373754858970642,
      -0.026044970378279686,
      -0.04415339231491089,
      -0.02804495580494404,
      0.004800061695277691,
      -0.052039217203855515,
      -0.02481374889612198,
      0.03800236061215401,
      0.011895923875272274,
      0.016032712534070015,
      -0.012528710067272186,
      -0.05225332826375961,
      -0.09699956327676773,
      -0.029236551374197006,
      -0.07466006278991699,
      -0.01630081795156002,
      -0.015479141846299171,
      -0.07545452564954758,
      -0.003553160931915045,
      -0.034402038902044296,
      0.05302949622273445,
      -0.01969621144235134,
      0.0014177022967487574,
      0.009894267655909061,
      -0.022551504895091057,
      0.00316924462094903,
      0.029661385342478752,
      0.04514089971780777,
      0.0776059702038765,
      -0.02927195280790329,
      -0.04027681052684784,
      -0.0661688968539238,
      0.11147382855415344,
      -0.007829071022570133,
      0.057266514748334885,
      0.0716317743062973,
      -0.016671158373355865,
      0.002727637067437172,
      -0.008400949649512768,
      0.04041553661227226,
      0.028420886024832726,
      0.011818488128483295,
      0.0047548203729093075,
      0.03865394368767738,
      0.02032119780778885,
      0.024091806262731552,
      0.015857160091400146,
      -0.027463173493742943,
      0.025866059586405754,
      -0.013010184280574322,
      -0.07838732749223709,
      0.02084655500948429,
      0.02122020721435547,
      0.00044653922668658197,
      0.06522218883037567,
      -0.02714763768017292,
      0.022692343220114708,
      -0.03892207145690918,
      0.06537143886089325,
      0.041225872933864594,
      0.0015922170132398605,
      -0.025285983458161354,
      0.037286147475242615,
      0.03781953826546669,
      -0.002958789002150297,
      0.03946442902088165,
      0.03520767763257027,
      0.017793875187635422,
      -0.028228892013430595,
      -0.019410638138651848,
      -0.03219978138804436,
      -0.018802158534526825,
      0.022356774657964706,
      -0.03900859132409096,
      0.014124971814453602,
      0.055232636630535126,
      -0.008582458831369877,
      0.003818221390247345,
      -0.052853044122457504,
      0.054497648030519485,
      -0.012494252063333988,
      0.007906805723905563,
      0.08292969316244125,
      0.07297173142433167,
      -0.03233051672577858,
      -0.028665093705058098,
      -0.013725042343139648,
      -0.03775518760085106,
      -0.08565938472747803,
      -0.11093870550394058,
      0.024304216727614403,
      0.039973124861717224,
      0.018193190917372704,
      0.03159715235233307,
      0.028191475197672844,
      -0.00840407982468605,
      -0.05876650661230087,
      0.00040506903314962983,
      0.0865342766046524,
      -0.09764422476291656,
      0.07257889956235886,
      -0.004676260519772768,
      0.0033536350820213556,
      -0.04018359258770943,
      0.03380829095840454,
      -0.04231872037053108,
      0.06352606415748596,
      -0.045682523399591446,
      -0.09353369474411011,
      0.0479237399995327,
      0.0262612272053957,
      0.004108992405235767,
      0.04232246056199074,
      -0.01471752393990755,
      0.04314402490854263,
      -0.09169071912765503,
      0.005027240142226219,
      0.032219383865594864,
      -0.07627370953559875,
      0.02488795295357704,
      -0.023641712963581085,
      -0.0033388480078428984,
      -0.033210840076208115,
      -0.05332757532596588,
      0.06650453060865402,
      -0.0018149878596886992,
      -0.03654056042432785,
      -0.002128549152985215,
      0.0031054920982569456,
      0.08556436002254486,
      0.09032775461673737,
      0.003210604889318347,
      -0.07861807942390442,
      -0.10151100903749466,
      0.0646791160106659,
      0.03793850168585777,
      0.007788478396832943,
      0.001800239784643054,
      -0.02454748935997486,
      -0.008752039633691311,
      0.012512747198343277,
      -0.0016288913320749998,
      -0.009025282226502895,
      0.038049254566431046,
      0.03501325473189354,
      0.02229795604944229,
      0.027046365663409233,
      0.04717742279171944,
      -0.03520352020859718,
      0.07720354944467545,
      -0.0025173984467983246,
      -0.010900868102908134,
      0.07397055625915527,
      0.12033900618553162,
      -0.04728977009654045,
      -0.07307422161102295,
      0.005005992949008942,
      0.013981976546347141,
      0.0039028238970786333,
      -0.016668057069182396,
      0.07911841571331024,
      -0.02561880648136139,
      -0.01869557797908783,
      0.08198598027229309,
      -0.023313995450735092,
      0.04868614301085472,
      -0.004579950589686632,
      0.021458961069583893,
      -0.09209005534648895,
      -0.023225171491503716,
      0.08520116657018661,
      -0.09187409281730652,
      -0.017625391483306885,
      0.04120832681655884,
      0.059100549668073654,
      0.040522899478673935,
      0.06461907178163528,
      -0.00405138311907649,
      -0.04406153783202171,
      0.08500306308269501,
      0.05553678050637245,
      -0.0958932489156723,
      -0.040998488664627075,
      0.027780544012784958,
      -0.049608927220106125,
      -0.013719256035983562,
      0.018838124349713326,
      -0.028586333617568016,
      -0.016594812273979187,
      0.0168195478618145,
      0.051330968737602234,
      -0.000653157418128103,
      0.04117853567004204,
      0.01860087551176548,
      -0.053198620676994324,
      0.025282787159085274,
      0.008235747925937176,
      -0.04293232783675194,
      -0.02273763157427311,
      0.007487527560442686,
      0.005800788290798664,
      -0.04311693459749222,
      -0.014575340785086155,
      0.0351882204413414,
      -0.020861171185970306,
      0.06377310305833817,
      -0.027505135163664818,
      0.0029629755299538374,
      -0.06476011872291565,
      -0.09809031337499619,
      -0.05236085504293442,
      -0.04272495582699776,
      -0.025571594014763832,
      0.017611205577850342,
      0.0861920714378357,
      0.019839851185679436,
      -0.0007054701563902199,
      0.08122188597917557,
      -0.03544541820883751,
      0.036564815789461136,
      0.018569093197584152,
      -0.0038417044561356306,
      0.010418364778161049,
      -0.0695355087518692,
      -0.019716888666152954,
      0.08506300300359726,
      -0.04670425131917,
      -0.06645461916923523,
      -0.008961615152657032,
      0.01430978998541832,
      0.038751453161239624,
      0.0401153564453125,
      0.03935321420431137,
      -0.035128332674503326,
      0.013705825433135033,
      -0.02917834371328354,
      -0.018351348116993904,
      -0.05355498567223549,
      -0.04524242877960205,
      -0.021785525605082512,
      0.03380173444747925,
      0.018060173839330673,
      -0.016074087470769882,
      0.01358406338840723,
      -0.021950120106339455,
      0.0014716064324602485,
      -0.02994372323155403,
      -0.08852879703044891,
      0.05925272777676582,
      0.03227213770151138,
      -0.02493193931877613,
      0.023849882185459137,
      -0.08108965307474136,
      -0.005030571483075619,
      -0.07264106720685959,
      -0.015250037424266338,
      0.0035766696091741323,
      0.035778243094682693,
      -0.08680601418018341,
      -0.023962538689374924,
      -0.011118720285594463,
      0.02837080880999565,
      -0.07181396335363388,
      -0.029119689017534256,
      0.010591941885650158,
      -0.05719016492366791,
      -0.0726790800690651,
      0.0205412395298481,
      0.03709539771080017,
      -0.020653631538152695,
      0.03287060186266899,
      0.010086003690958023,
      -0.01837967149913311,
      0.03236108645796776,
      0.05044054612517357,
      0.047173790633678436,
      0.015679001808166504,
      0.025392379611730576,
      0.02024456299841404,
      0.0038174984510987997,
      0.03740544244647026,
      -0.018293673172593117,
      -0.08396976441144943,
      -0.05800655484199524,
      -0.04671226441860199,
      0.04170658439397812,
      -0.03378598392009735,
      -0.007443124894052744,
      0.05238345265388489,
      0.0007181804394349456,
      -0.002465874422341585,
      0.012116105295717716,
      -0.05873400717973709,
      0.02948506362736225,
      -0.0695526972413063,
      -0.0419498085975647,
      -0.021536847576498985,
      0.013511121273040771,
      -0.026998139917850494,
      0.02147757261991501,
      -0.0018255519680678844,
      -0.05839897319674492,
      -0.005554688163101673,
      -0.024896834045648575,
      0.011576212011277676,
      -0.006978270132094622,
      0.0017761498456820846,
      -0.01915189065039158,
      0.011291180737316608,
      0.01844804547727108,
      0.027068568393588066,
      0.037528909742832184,
      -0.12426304072141647,
      0.08830869197845459,
      0.025833355262875557,
      -0.014375820755958557,
      0.04864172264933586,
      -0.018274353817105293,
      0.02433997392654419,
      0.06819988042116165,
      0.02613816224038601,
      -0.012316600419580936,
      -0.010809985920786858,
      0.0019340738654136658,
      0.04246245697140694,
      -0.013231794349849224,
      0.03502007573843002,
      0.02420930564403534,
      0.060208991169929504,
      0.05543728545308113,
      -0.017847364768385887,
      -0.006917017512023449,
      0.10490252822637558,
      -0.06797292083501816,
      0.012115693651139736,
      0.032137565314769745,
      -0.007474290207028389,
      -0.0332813486456871,
      0.05630078911781311,
      0.053846847265958786,
      0.05923674628138542,
      0.03472306579351425,
      0.02199953980743885,
      -0.04681248962879181,
      -0.05135494843125343,
      0.002135639078915119,
      0.009542393498122692,
      0.06352580338716507,
      0.03395228460431099,
      -0.023801155388355255,
      -0.02955452911555767,
      0.07834545522928238,
      -0.027800625190138817,
      -0.027957160025835037,
      -0.022065766155719757,
      0.02527390792965889,
      0.06590040773153305,
      -0.07966454327106476,
      -0.028898578137159348,
      -0.034838855266571045,
      0.0812280997633934,
      0.03773478791117668,
      0.06322944164276123,
      -0.0646185427904129,
      -0.04807611182332039,
      0.0234886035323143,
      -0.05911045894026756,
      7.644434663234279e-05,
      -0.02759295329451561,
      -0.03699323162436485,
      -0.05324501916766167,
      0.03140266612172127,
      0.018445787951350212,
      -0.0381292849779129,
      -0.0955163836479187,
      -0.07302489876747131,
      -0.18609370291233063,
      0.019067199900746346,
      0.02986309677362442,
      -0.07889722287654877,
      0.02845536544919014,
      -0.01989763416349888,
      -0.0563613660633564,
      -0.035623423755168915,
      0.003264553612098098,
      0.0021170068066567183,
      -0.12910671532154083,
      0.03146720305085182,
      -0.08198627829551697,
      -0.011005808599293232,
      -0.04652886837720871,
      0.026732023805379868,
      0.02460588701069355,
      -0.06656638532876968,
      -0.004020294174551964,
      -0.1129809021949768,
      -0.03533095121383667,
      0.10883016884326935,
      -0.057175710797309875,
      0.005715477280318737,
      -0.010379863902926445,
      -0.001973810838535428,
      -0.004316766280680895,
      -0.009080398827791214,
      0.0005498060490936041,
      -0.011144137009978294,
      -0.03011322021484375,
      -0.050892382860183716,
      -0.05665334686636925,
      -0.023205220699310303,
      0.03308293968439102,
      -0.05519269034266472,
      0.0660271868109703,
      0.03184061497449875,
      0.10586793720722198,
      -0.047169279307127,
      -0.07125687599182129,
      -0.0007522106170654297,
      -0.028046680614352226,
      -0.0160248763859272,
      -0.01723797433078289,
      0.06820262223482132,
      0.050388287752866745,
      -0.03049600124359131,
      -0.09552647173404694,
      0.028841495513916016,
      0.017765281721949577,
      0.012717358767986298,
      0.017110245302319527,
      -0.005669636186212301,
      0.02975582145154476,
      -0.03723606839776039,
      -0.017762599512934685,
      0.056806161999702454,
      -0.022530317306518555,
      -0.008752038702368736,
      0.0857790857553482
    ],
    [
      -0.0013060295023024082,
      0.005275850184261799,
      0.05350945517420769,
      0.007462707348167896,
      0.009056109003722668,
      0.07235045731067657,
      -0.03901983052492142,
      0.04112619906663895,
      0.05162062868475914,
      -0.017721205949783325,
      -0.08720601350069046,
      -0.02616213448345661,
      0.08075162768363953,
      -0.01238469872623682,
      0.034684523940086365,
      -0.07399865239858627,
      0.051291562616825104,
      0.08978220075368881,
      -0.00136086018756032,
      0.041512370109558105,
      0.011154362931847572,
      -0.0014776536263525486,
      -0.0007534480537287891,
      -0.027823608368635178,
      -0.0393800362944603,
      0.07405294477939606,
      -0.007778202183544636,
      0.04716389253735542,
      0.026106376200914383,
      0.06118009611964226,
      -0.03865968436002731,
      0.003461085958406329,
      -0.005968846846371889,
      0.009327052161097527,
      0.020708588883280754,
      0.012666573747992516,
      -0.022634578868746758,
      -0.026597479358315468,
      0.0005732790450565517,
      -0.016920864582061768,
      0.08030332624912262,
      0.03142921254038811,
      -0.007076292764395475,
      -0.05035063624382019,
      0.0230269692838192,
      -0.0073254965245723724,
      0.05144720897078514,
      0.007763345260173082,
      -0.007350470405071974,
      0.028516240417957306,
      -0.008488853462040424,
      0.012010584585368633,
      -0.06045211851596832,
      0.0015251528238877654,
      -0.0536462664604187,
      0.013271374627947807,
      0.011663338169455528,
      -0.03579999506473541,
      0.04121486097574234,
      0.024374419823288918,
      -0.048745278269052505,
      0.04705668240785599,
      0.05236629396677017,
      0.08063457161188126,
      0.037600744515657425,
      0.06171780079603195,
      -0.0027595325373113155,
      -0.05178600549697876,
      0.06142524257302284,
      0.004305137787014246,
      -0.02949533611536026,
      0.00812566839158535,
      -0.010230123065412045,
      0.00415231566876173,
      0.0654030367732048,
      -0.02570396661758423,
      -0.01867114193737507,
      0.024149704724550247,
      -0.010536895133554935,
      0.0041825734078884125,
      -0.03496018797159195,
      0.02379613183438778,
      -0.019268730655312538,
      -0.0572064034640789,
      -0.014540879987180233,
      -0.017425542697310448,
      0.040609125047922134,
      -0.003006318584084511,
      0.03438038378953934,
      0.036095988005399704,
      0.023678453639149666,
      -0.04117279127240181,
      0.0016600517556071281,
      0.019995106384158134,
      -0.007433285471051931,
      0.03960779309272766,
      -0.006364847999066114,
      -0.012702732346951962,
      0.007390968967229128,
      0.05762073025107384,
      -0.0689227506518364,
      0.012355688028037548,
      0.07195711135864258,
      -0.05883697420358658,
      -0.08967370539903641,
      -0.02201964519917965,
      0.02345488965511322,
      -0.06518369168043137,
      -0.024646203964948654,
      0.04810062795877457,
      -0.008718756027519703,
      -0.035540685057640076,
      0.003413623431697488,
      -0.012394004501402378,
      -0.06006213277578354,
      -0.00698977941647172,
      -0.01898297481238842,
      -0.002262052847072482,
      0.013029762543737888,
      0.028207501396536827,
      0.08933760970830917,
      -0.0027254971209913492,
      -0.023000027984380722,
      -0.03362150490283966,
      -0.034552525728940964,
      0.06664621829986572,
      -0.009625520557165146,
      0.09277451038360596,
      0.0411565825343132,
      -0.01051882840692997,
      0.05803950875997543,
      -0.009664535522460938,
      0.045281555503606796,
      -0.030888289213180542,
      0.028435705229640007,
      -0.032569702714681625,
      -0.03616830334067345,
      -0.013968480750918388,
      -0.012183638289570808,
      -0.06222805008292198,
      -0.0386597104370594,
      0.023417489603161812,
      -0.006647458765655756,
      0.008723399601876736,
      0.02492392063140869,
      -0.06082817539572716,
      0.06498802453279495,
      0.0823969915509224,
      -0.042759209871292114,
      -0.00964992307126522,
      -0.01303673442453146,
      0.014793874695897102,
      0.004353374242782593,
      0.06116579473018646,
      -0.0746578499674797,
      0.06602581590414047,
      -0.032769039273262024,
      0.055998578667640686,
      0.053631607443094254,
      -0.05577521771192551,
      0.0752280205488205,
      -0.009235627949237823,
      0.0856277346611023,
      0.06015336886048317,
      -0.04292575269937515,
      -0.0025521230418235064,
      0.04880181699991226,
      -0.027073178440332413,
      -0.051232896745204926,
      0.055322978645563126,
      -0.018945496529340744,
      0.03474070131778717,
      0.009837034158408642,
      0.02708163671195507,
      0.05141435191035271,
      0.04591111093759537,
      0.0065068574622273445,
      -0.015247232280671597,
      0.10979104787111282,
      0.018458480015397072,
      0.02631266601383686,
      0.01836150884628296,
      0.026773851364850998,
      -0.014505998231470585,
      0.020387429744005203,
      0.05101647973060608,
      0.034771811217069626,
      0.0027581946924328804,
      0.02486148476600647,
      0.01693633757531643,
      -0.0977715328335762,
      -0.022073213011026382,
      -0.031406763941049576,
      0.02344193495810032,
      0.006108210887759924,
      -0.022561775520443916,
      -0.07102925330400467,
      0.021014181897044182,
      0.07781869173049927,
      -0.03593882918357849,
      -0.08737585693597794,
      -0.019715560600161552,
      -0.05908115953207016,
      0.0011124054435640574,
      -0.003234052797779441,
      0.02744011953473091,
      -0.06575267016887665,
      -0.023332692682743073,
      0.0027651465497910976,
      -0.0016332364175468683,
      0.051492977887392044,
      -0.07231214642524719,
      0.017676644027233124,
      0.008084139786660671,
      -0.04080839455127716,
      -0.07592794299125671,
      -0.054793983697891235,
      -0.006611822172999382,
      -0.029247211292386055,
      -0.04688994213938713,
      0.03318318724632263,
      0.03849538415670395,
      -0.08731503039598465,
      -0.01334046758711338,
      0.015729648992419243,
      0.10321437567472458,
      -0.020762046799063683,
      -0.026519982144236565,
      -0.024049770087003708,
      0.10164642333984375,
      -0.033748187124729156,
      0.04724079370498657,
      -0.07201974838972092,
      -0.022107649594545364,
      -0.04362865909934044,
      -0.0013481577625498176,
      -0.026147615164518356,
      0.02527245134115219,
      0.010583164170384407,
      0.027427643537521362,
      -0.05974312499165535,
      -0.053260233253240585,
      -0.006749975495040417,
      0.018363166600465775,
      0.016883566975593567,
      -0.01425416674464941,
      -0.023770276457071304,
      -0.05034739896655083,
      -0.0477396696805954,
      0.04239935800433159,
      0.05191950127482414,
      -0.06793895363807678,
      0.011469735763967037,
      -0.005288469605147839,
      -0.00678049074485898,
      -0.011852584779262543,
      0.00998089648783207,
      -0.0063164131715893745,
      -0.010030422359704971,
      0.001581015414558351,
      0.10725153982639313,
      -0.02808554098010063,
      -0.05822237953543663,
      0.010517864488065243,
      0.017907381057739258,
      0.03674524649977684,
      0.031021228060126305,
      0.09768921881914139,
      0.01965162716805935,
      0.01411875244230032,
      -0.0048219794407486916,
      -0.015741456300020218,
      0.005096300970762968,
      0.05788005143404007,
      -0.010383359156548977,
      0.0008624405600130558,
      0.00636313995346427,
      0.035718582570552826,
      0.01699173077940941,
      -0.010421433486044407,
      0.014839956536889076,
      0.05388351157307625,
      0.002052635420113802,
      -0.00872371532022953,
      0.03327106684446335,
      0.012955514714121819,
      -0.04358847439289093,
      0.03421672433614731,
      -0.02900446206331253,
      -0.0330929309129715,
      0.02231341414153576,
      -0.03892706334590912,
      0.06885883957147598,
      0.0090180654078722,
      0.036676760762929916,
      0.03792637586593628,
      0.06817968934774399,
      0.01558059174567461,
      4.295190592529252e-05,
      0.002341648330911994,
      0.016756420955061913,
      -0.0018664683448150754,
      0.025981958955526352,
      -0.07664473354816437,
      0.020365964621305466,
      0.05941934138536453,
      -0.022574711591005325,
      -0.06840670853853226,
      -0.0371147096157074,
      -0.0027288252022117376,
      0.031837910413742065,
      0.07108753174543381,
      0.03653934597969055,
      0.018666815012693405,
      -0.1194547787308693,
      0.09365930408239365,
      0.05686134845018387,
      -0.029248220846056938,
      -0.026626907289028168,
      -0.0068735722452402115,
      -0.0485658124089241,
      -0.02102375030517578,
      0.010705158114433289,
      -0.04359138384461403,
      -0.020843883976340294,
      0.015909649431705475,
      0.01318475790321827,
      0.045065686106681824,
      -0.027099132537841797,
      -0.021834516897797585,
      0.07683692127466202,
      -0.02842627465724945,
      -0.01851845718920231,
      -0.024576710537075996,
      0.011722348630428314,
      -0.009285841137170792,
      -0.019355112686753273,
      -0.004455252084881067,
      -0.11325687915086746,
      -0.0012014907551929355,
      0.0647835060954094,
      0.09489280730485916,
      0.0154221560806036,
      0.0444759763777256,
      -0.09815024584531784,
      -0.007128885947167873,
      0.07873652130365372,
      -0.03697481006383896,
      -0.01245375070720911,
      0.024477170780301094,
      0.016214901581406593,
      -0.04167557507753372,
      0.01777920499444008,
      0.03565046191215515,
      0.024877991527318954,
      -0.045833226293325424,
      -0.0009781189728528261,
      -0.10581494122743607,
      0.016448669135570526,
      -0.06250762939453125,
      0.030698144808411598,
      0.046555981040000916,
      0.03477936610579491,
      0.023751534521579742,
      -0.01715684123337269,
      -0.013647282496094704,
      0.017498042434453964,
      -0.0557379275560379,
      0.015109676867723465,
      0.05722230300307274,
      -0.052485521882772446,
      -0.008818639442324638,
      -0.06451598554849625,
      -0.0002763147640507668,
      0.04330854490399361,
      -0.02334202639758587,
      0.037401821464300156,
      -0.09896345436573029,
      0.03095848113298416,
      0.040810417383909225,
      -0.02849569171667099,
      -0.020736901089549065,
      -0.06617722660303116,
      0.03716807812452316,
      0.05875161662697792,
      -0.014820053242146969,
      0.022619938477873802,
      0.02437785267829895,
      0.008276444859802723,
      -0.032340362668037415,
      -0.00983585137873888,
      -0.0498323068022728,
      0.04545162618160248,
      0.07129891216754913,
      0.04834121838212013,
      -0.06052715331315994,
      -0.017352832481265068,
      -0.03475657477974892,
      0.029133262112736702,
      0.03994148597121239,
      0.007903690449893475,
      0.025967514142394066,
      0.03017626702785492,
      0.0037263415288180113,
      0.045961298048496246,
      0.01162741519510746,
      -0.024806123226881027,
      0.0038209338672459126,
      0.029558518901467323,
      -0.018236340954899788,
      0.017310967668890953,
      0.023573536425828934,
      0.015168847516179085,
      0.05204393342137337,
      -0.07903981953859329,
      -0.048833634704351425,
      -0.04026203602552414,
      -0.0497741624712944,
      -0.06139739975333214,
      -0.03690577670931816,
      -0.04012971371412277,
      -0.005315185058861971,
      -0.030872438102960587,
      0.006409694906324148,
      0.02433037757873535,
      -0.03238296881318092,
      -0.007021661847829819,
      -0.06110238656401634,
      0.041691530495882034,
      -0.005495517514646053,
      -0.048578765243291855,
      -0.02426759898662567,
      0.008798069320619106,
      -0.016594592481851578,
      -0.0466834120452404,
      0.04811198264360428,
      0.037103500217199326,
      0.01448376290500164,
      0.010672667995095253,
      0.05413394793868065,
      0.0273537989705801,
      0.02845223993062973,
      0.06291286647319794,
      -0.009057201445102692,
      -0.1139766126871109,
      -0.05452795326709747,
      0.005570242181420326,
      0.002710494678467512,
      -0.09139296412467957,
      -0.027849232777953148,
      -0.013005529530346394,
      0.01033095084130764,
      0.07259976863861084,
      -0.06450209766626358,
      -0.0541532076895237,
      0.038990095257759094,
      -0.015932297334074974,
      -0.008667740039527416,
      0.033714599907398224,
      0.012611805461347103,
      -0.06301717460155487,
      -0.08838954567909241,
      -0.008713265880942345,
      -0.003989651799201965,
      0.052689068019390106,
      -0.0067427619360387325,
      0.014186491258442402,
      -0.10570063441991806,
      0.019153956323862076,
      -0.08492272347211838,
      0.04353675991296768,
      -0.01645689271390438,
      0.03604647144675255,
      -0.06087232008576393,
      -0.09224961698055267,
      -0.09676220268011093,
      -0.045850180089473724,
      -0.05837210640311241,
      0.00463229650631547,
      0.027764227241277695,
      0.03332848846912384,
      0.07207618653774261,
      -0.006386700551956892,
      -0.0713580772280693,
      -0.042683497071266174,
      -0.010022173635661602,
      -0.008733031339943409,
      0.03324369713664055,
      0.005712429992854595,
      0.0508456826210022,
      -0.030724331736564636,
      -0.04661456122994423,
      0.08331219106912613,
      0.019559606909751892,
      0.039548613131046295,
      0.009100646711885929,
      -0.06491932272911072,
      0.025165632367134094,
      -0.07840517163276672,
      0.08293089270591736,
      0.001931837061420083,
      0.015649011358618736,
      0.0342303141951561
    ],
    [
      -0.07556150108575821,
      0.006917278282344341,
      0.024402793496847153,
      -0.12238634377717972,
      -0.03214457631111145,
      -0.08152704685926437,
      0.009231950156390667,
      0.030763864517211914,
      0.03671898692846298,
      -0.03647754713892937,
      -0.03842267766594887,
      0.016916977241635323,
      0.02114001475274563,
      -0.038198698312044144,
      0.05558406561613083,
      -0.03021777793765068,
      0.004442209377884865,
      -0.04747673124074936,
      -0.04540600627660751,
      -0.01409583818167448,
      -0.06039963662624359,
      0.03421459719538689,
      -0.09277626127004623,
      -0.00738569488748908,
      -0.029551003128290176,
      -0.0007730069919489324,
      -0.033582791686058044,
      -0.013282357715070248,
      -0.061012182384729385,
      -0.04977577552199364,
      -0.028689874336123466,
      -0.11106064170598984,
      0.07232434302568436,
      -0.00465558422729373,
      0.005551275331526995,
      -0.05606325715780258,
      0.04982890188694,
      -0.031061243265867233,
      0.025533465668559074,
      -0.09106453508138657,
      0.09337293356657028,
      -0.01073810737580061,
      -0.050769876688718796,
      0.03453544154763222,
      0.066495381295681,
      -0.0055633787997066975,
      -0.019900735467672348,
      0.034338418394327164,
      0.010867008939385414,
      -0.021778814494609833,
      -0.009169397875666618,
      -0.04429271072149277,
      0.03424355760216713,
      -0.039279963821172714,
      0.06487343460321426,
      -0.0005209946539252996,
      0.009724654257297516,
      0.035728566348552704,
      0.0420067235827446,
      0.0570576973259449,
      0.017431676387786865,
      0.015712562948465347,
      0.01988399587571621,
      -0.09146498143672943,
      0.004971270449459553,
      0.0054416777566075325,
      0.058703213930130005,
      0.03180745616555214,
      -0.005402677226811647,
      -0.010544218122959137,
      -0.025309648364782333,
      -0.0961277112364769,
      -0.029207436367869377,
      -0.022459769621491432,
      -0.07416761666536331,
      -0.06516661494970322,
      0.024856453761458397,
      0.005954559426754713,
      -0.01750502735376358,
      0.04710353910923004,
      -0.030403872951865196,
      0.04746314510703087,
      0.03190680220723152,
      1.0768076208478305e-05,
      -0.011938212439417839,
      -0.020489204674959183,
      -0.04078926518559456,
      -0.0878206416964531,
      0.05557723715901375,
      -0.06460747122764587,
      0.02876788191497326,
      -0.01559190358966589,
      -0.032231029123067856,
      -0.05517647787928581,
      0.01757848635315895,
      0.04226155951619148,
      -0.013548244722187519,
      -0.06737913191318512,
      0.012761916033923626,
      -0.06007131561636925,
      0.09631545841693878,
      0.00362380500882864,
      -0.017307091504335403,
      0.060698892921209335,
      -0.0783248320221901,
      -0.010623801499605179,
      0.037125490605831146,
      0.012515473179519176,
      0.031057849526405334,
      0.086332768201828,
      -0.027425585314631462,
      0.06345809251070023,
      0.03762286901473999,
      -0.04870535433292389,
      -0.028861189261078835,
      -0.025210987776517868,
      0.0011140895076096058,
      0.09368068724870682,
      -0.042960766702890396,
      -0.014776188880205154,
      -0.014125761575996876,
      0.0005005723796784878,
      -0.10712605714797974,
      0.07343916594982147,
      0.029201146215200424,
      0.02234484627842903,
      0.045584939420223236,
      -0.020266279578208923,
      -0.03244191035628319,
      -0.00939196441322565,
      0.017033101990818977,
      0.059661928564310074,
      0.09591219574213028,
      -0.0531407967209816,
      0.02219926379621029,
      0.008464278653264046,
      -0.015663575381040573,
      -0.043558355420827866,
      0.10206200927495956,
      -0.05421442165970802,
      0.02167391963303089,
      -0.03676358982920647,
      0.04592394828796387,
      0.01089368388056755,
      -0.0016405635979026556,
      0.0018158028833568096,
      -0.017712093889713287,
      0.04050467535853386,
      0.11180271208286285,
      -0.010141273029148579,
      0.013040527701377869,
      -0.02590225078165531,
      -0.07490561902523041,
      -0.03038223832845688,
      -0.04353542998433113,
      0.020705239847302437,
      -0.07213430851697922,
      0.026391398161649704,
      -0.1223258227109909,
      0.013253367505967617,
      -0.04216429591178894,
      -0.029192717745900154,
      0.04625586420297623,
      0.027160611003637314,
      -0.015076538547873497,
      -0.03798366338014603,
      0.020917264744639397,
      0.06260862946510315,
      -0.03699786588549614,
      -0.02058468386530876,
      0.012081319466233253,
      -0.08645858615636826,
      0.030311301350593567,
      0.031681403517723083,
      0.005942025687545538,
      0.0023517415393143892,
      -0.07532686740159988,
      0.00618821382522583,
      -0.004777954425662756,
      0.06349211186170578,
      0.039695870131254196,
      0.01653941161930561,
      0.043972600251436234,
      -0.023449070751667023,
      -0.00979062169790268,
      -0.047932278364896774,
      0.11849558353424072,
      -0.003953988663852215,
      0.05078078806400299,
      -0.04430455341935158,
      0.004949949216097593,
      0.04567110911011696,
      -0.004268093965947628,
      0.037537574768066406,
      0.013838134706020355,
      0.027507923543453217,
      -0.024732256308197975,
      -0.01787814125418663,
      0.03907140716910362,
      0.04159773513674736,
      -0.07837384939193726,
      -0.03955036401748657,
      0.04581673443317413,
      0.007918784394860268,
      0.10277929902076721,
      -0.07568719983100891,
      -0.016774248331785202,
      -0.023469598963856697,
      -0.047744669020175934,
      -0.03501780703663826,
      -0.00988718681037426,
      0.02508603222668171,
      0.029118899255990982,
      0.030679307878017426,
      0.018492441624403,
      -0.013100815936923027,
      -0.06714711338281631,
      -0.029158886522054672,
      0.0352352000772953,
      -0.04769226908683777,
      -0.09352599084377289,
      0.026409098878502846,
      0.012346136383712292,
      0.03826229274272919,
      -0.02114667370915413,
      -0.07122775912284851,
      0.03145771473646164,
      -0.06342723965644836,
      -0.02509160339832306,
      0.045649249106645584,
      0.07751128077507019,
      -0.031198900192975998,
      0.02309894561767578,
      0.02645338699221611,
      0.05571821331977844,
      -0.057556331157684326,
      0.026819312945008278,
      0.017202867195010185,
      0.03289584070444107,
      0.07438351213932037,
      -0.04828958585858345,
      0.08014916628599167,
      0.009071175009012222,
      0.04288924112915993,
      0.07752525061368942,
      0.031539060175418854,
      0.06701221317052841,
      -0.003801333485171199,
      -0.03868066892027855,
      0.053392134606838226,
      0.13202553987503052,
      -0.06362130492925644,
      -0.04210377484560013,
      0.051414839923381805,
      -0.027705159038305283,
      0.020953640341758728,
      -0.003017504932358861,
      0.056978534907102585,
      0.09113021194934845,
      0.02157837338745594,
      0.01445331797003746,
      -0.06862367689609528,
      -0.006379701662808657,
      0.05418688803911209,
      0.018586350604891777,
      0.09024439752101898,
      -0.015805698931217194,
      0.013188106007874012,
      -0.007577332202345133,
      0.04288090392947197,
      -0.008197467774152756,
      0.014015881344676018,
      0.03080170601606369,
      0.07985484600067139,
      0.007173361722379923,
      0.07669855654239655,
      0.056340668350458145,
      0.05735882744193077,
      0.020781666040420532,
      0.008844738826155663,
      0.06308043748140335,
      -0.007716813590377569,
      0.06532586365938187,
      -0.01109887845814228,
      0.05138281732797623,
      0.07233290374279022,
      0.08144748210906982,
      0.007387564983218908,
      -0.04964368790388107,
      -0.025689957663416862,
      -0.010020243003964424,
      0.009383244439959526,
      0.07202669233083725,
      0.1252298206090927,
      -0.0074097090400755405,
      -0.002828066935762763,
      0.09669521450996399,
      -0.0360366590321064,
      -0.00086414092220366,
      0.03330405429005623,
      0.034604188054800034,
      -0.004686721600592136,
      -0.030331887304782867,
      0.020135335624217987,
      -0.0195144135504961,
      0.0014012361643835902,
      0.0875614807009697,
      -0.03238561004400253,
      0.03850202634930611,
      0.058294136077165604,
      0.009656161069869995,
      0.053649384528398514,
      -0.041814155876636505,
      -0.001679343287833035,
      -0.0005060631665401161,
      -0.040172260254621506,
      0.10050388425588608,
      -0.07573992758989334,
      0.04201459139585495,
      -0.04133700579404831,
      0.05718528851866722,
      -0.01296252477914095,
      0.03232073038816452,
      -0.01627766340970993,
      -0.009872648864984512,
      -0.01160687580704689,
      -0.0059103285893797874,
      -0.0735120102763176,
      0.04288287088274956,
      -0.036554012447595596,
      0.02643643505871296,
      -0.006900886073708534,
      -0.009729567915201187,
      0.036942362785339355,
      0.004153020679950714,
      0.0176326222717762,
      -0.05591545253992081,
      -0.012555841356515884,
      0.09334029257297516,
      0.02625633217394352,
      0.002447886625304818,
      -0.018135692924261093,
      -0.029131179675459862,
      0.008947191759943962,
      0.03992152586579323,
      0.06878849118947983,
      -0.006878561805933714,
      -0.01321138720959425,
      -0.057831041514873505,
      -0.03638872131705284,
      -0.03795306384563446,
      -0.008607102558016777,
      0.042125653475522995,
      0.016689512878656387,
      -0.02655790187418461,
      0.007343981880694628,
      0.003738881554454565,
      -0.003897956106811762,
      0.11770249158143997,
      -0.013841447420418262,
      -0.02005491964519024,
      0.01477719098329544,
      -0.005004163831472397,
      -0.04764172434806824,
      -0.07996863126754761,
      0.027641892433166504,
      -0.007789799943566322,
      0.06110619008541107,
      -0.022394578903913498,
      0.0043467325158417225,
      -0.03373588249087334,
      0.0628868043422699,
      0.024748913943767548,
      -0.05383637174963951,
      -0.016812391579151154,
      -0.044855549931526184,
      -0.0016398418229073286,
      0.02117614634335041,
      0.004293103702366352,
      -0.06208983436226845,
      0.0009739647503010929,
      0.01427289005368948,
      0.053245365619659424,
      0.024866407737135887,
      0.05963556841015816,
      0.01787669211626053,
      -0.006656291428953409,
      -0.01028370764106512,
      0.048734214156866074,
      0.023819027468562126,
      0.04005809128284454,
      0.010488356463611126,
      -0.016189685091376305,
      -0.03948526084423065,
      -0.02294212020933628,
      -0.008029516786336899,
      -0.04777730628848076,
      0.07189635187387466,
      -0.033290088176727295,
      0.007989536970853806,
      0.05705123022198677,
      -0.03827700391411781,
      0.048867713660001755,
      -0.020020099356770515,
      0.001699599321000278,
      0.057703468948602676,
      -0.000329534406773746,
      -0.10587797313928604,
      -0.010255399160087109,
      -0.06890338659286499,
      -0.001027228543534875,
      -0.06282209604978561,
      -0.02931554615497589,
      -0.00018200596969109029,
      -0.031886402517557144,
      -0.027550959959626198,
      0.013785749673843384,
      -0.0414859876036644,
      0.02382260374724865,
      -0.11297563463449478,
      -0.06522952020168304,
      0.02559313364326954,
      0.057416077703237534,
      -0.008248182013630867,
      -0.03939073532819748,
      -0.0016843382036313415,
      -0.07916999608278275,
      -0.016153398901224136,
      0.014738542027771473,
      -0.08567457646131516,
      -0.00508754700422287,
      0.026179416105151176,
      -0.02394525706768036,
      -0.04265964776277542,
      0.027353385463356972,
      0.030078010633587837,
      -0.022740179672837257,
      -0.00754572032019496,
      0.023819871246814728,
      0.022289156913757324,
      -0.09364168345928192,
      0.013773533515632153,
      -0.014545334503054619,
      0.08493401855230331,
      -0.01879657804965973,
      -0.02820647694170475,
      -0.014706853777170181,
      -0.013899565674364567,
      0.13558098673820496,
      -0.04862167686223984,
      0.0020775238517671824,
      0.02945478819310665,
      0.05366950482130051,
      -0.030784884467720985,
      -0.07653691619634628,
      -0.023396121338009834,
      -0.04708576574921608,
      -0.002297321567311883,
      -0.03497427701950073,
      0.016907526180148125,
      -0.00814979150891304,
      0.05173724889755249,
      -0.0033983674366027117,
      -0.0037075805012136698,
      -0.06260740756988525,
      0.0022546520922333,
      0.07240082323551178,
      -0.05613058805465698,
      -0.054769884794950485,
      0.04254145175218582,
      -0.015721434727311134,
      0.04559575393795967,
      0.00344631215557456,
      -0.0319405198097229,
      0.03111187554895878,
      -0.02359439805150032,
      0.05967152863740921,
      -0.05480092391371727,
      0.06789805740118027,
      -0.0642731785774231,
      -0.01388007402420044,
      0.016793284565210342,
      0.12269684672355652,
      -0.01916714757680893,
      0.031078478321433067,
      -0.008833506144583225,
      0.06687764078378677,
      0.011513050645589828,
      -0.031281664967536926,
      -0.032543305307626724,
      -0.020311543717980385,
      0.00062729069031775,
      0.06716206669807434,
      0.08445757627487183,
      -0.0033545864280313253,
      -0.05201838165521622,
      -0.044186774641275406,
      0.006812340579926968,
      -0.01940319687128067,
      0.042750898748636246,
      0.006747404113411903,
      0.007628974970430136,
      -0.006015544757246971
    ],
    [
      -0.07792030274868011,
      0.004685007501393557,
      -0.002117031253874302,
      -0.026785407215356827,
      -0.05733745917677879,
      0.04985427483916283,
      0.05500200390815735,
      -0.028934797272086143,
      -0.03671185299754143,
      -0.003858866635710001,
      0.0030593308620154858,
      -0.04457932710647583,
      -0.07632247358560562,
      0.08667692542076111,
      0.0036866681184619665,
      -0.00851485040038824,
      0.025553537532687187,
      -0.004068886861205101,
      0.0014174276730045676,
      0.019508903846144676,
      0.06470219045877457,
      0.0161262396723032,
      0.0025610558222979307,
      0.006599531974643469,
      -0.13274787366390228,
      0.00022934925800655037,
      -0.03431697189807892,
      -0.033659860491752625,
      -0.015838369727134705,
      -0.0466991662979126,
      -0.002038267906755209,
      -0.003730366937816143,
      -0.013012859039008617,
      -0.023699799552559853,
      -0.06106908991932869,
      0.036463722586631775,
      -0.06058957427740097,
      -0.02312547340989113,
      -0.032889749854803085,
      -0.0036441413685679436,
      0.027481136843562126,
      0.0028969813138246536,
      0.031036749482154846,
      -0.0648331269621849,
      0.0018315076595172286,
      -0.04225956276059151,
      -0.08222690224647522,
      0.058296915143728256,
      0.01575382612645626,
      0.032107122242450714,
      0.010890519246459007,
      0.07124430686235428,
      0.08610817044973373,
      0.024260718375444412,
      0.0105142118409276,
      0.030798599123954773,
      -0.010736376978456974,
      -0.0013163366820663214,
      -0.03338601440191269,
      -0.009374278597533703,
      -0.07793541252613068,
      -0.020641745999455452,
      -0.017641568556427956,
      -0.001110702520236373,
      0.048201121389865875,
      0.0008200570591725409,
      -0.04124979302287102,
      -0.012403404340147972,
      0.010068698786199093,
      0.048057954758405685,
      0.004169646184891462,
      0.06338963657617569,
      -0.042151693254709244,
      -0.00667972769588232,
      -0.025139013305306435,
      -0.05123215913772583,
      -0.0029423616360872984,
      0.004139081574976444,
      -0.03649432957172394,
      0.015215771272778511,
      -0.1001892164349556,
      0.020733186975121498,
      -0.05879317224025726,
      -0.01757330633699894,
      -0.0675005316734314,
      -0.06654772162437439,
      -0.07732368260622025,
      -0.04974684864282608,
      0.04414910450577736,
      0.06300564110279083,
      -0.026748884469270706,
      0.025935761630535126,
      -0.014317097142338753,
      0.08800462633371353,
      0.018045594915747643,
      -0.05233712121844292,
      0.0001551195018691942,
      0.052237965166568756,
      -0.049008559435606,
      0.042447302490472794,
      0.009437520988285542,
      0.03176527097821236,
      0.05430221185088158,
      0.046627581119537354,
      0.0023695901036262512,
      0.050306640565395355,
      -0.044428419321775436,
      -0.04209320247173309,
      0.04171888157725334,
      0.028389209881424904,
      0.048336561769247055,
      -0.01597410999238491,
      -0.009847406297922134,
      0.050338439643383026,
      -0.05174022912979126,
      0.0074959397315979,
      0.0645284429192543,
      0.06712697446346283,
      0.014252732507884502,
      -0.10706102848052979,
      -0.0034589495044201612,
      -0.10253223776817322,
      0.01871410571038723,
      -0.09209922701120377,
      0.015852658078074455,
      -0.04005023092031479,
      0.06922106444835663,
      -0.08273844420909882,
      -0.02243412472307682,
      -0.019588613882660866,
      -0.0705353170633316,
      -0.014339929446578026,
      0.07974482327699661,
      0.01684715412557125,
      -0.02952021174132824,
      -0.03540939837694168,
      0.03743819519877434,
      -0.008548503741621971,
      0.002254685154184699,
      0.04647357761859894,
      -0.009567261673510075,
      0.08758994191884995,
      0.044513359665870667,
      -0.03212812915444374,
      -0.046915460377931595,
      -0.14838041365146637,
      0.10465338826179504,
      -0.03547205403447151,
      -0.04727383702993393,
      -0.03624245524406433,
      0.024232294410467148,
      -0.06158654764294624,
      0.01343128178268671,
      0.029720786958932877,
      0.07752694189548492,
      -0.05428602546453476,
      -0.03016808070242405,
      -0.048394422978162766,
      -0.019239122048020363,
      -0.03810646012425423,
      0.03466023504734039,
      -0.029916001483798027,
      0.00171865604352206,
      -0.021717868745326996,
      -0.01131145004183054,
      0.008597441017627716,
      -0.012965149246156216,
      -0.03784610703587532,
      0.01789873093366623,
      0.02510124444961548,
      0.11416979879140854,
      -0.02477426826953888,
      -0.05696189031004906,
      0.022097120061516762,
      0.06990591436624527,
      0.039860304445028305,
      -0.0172213613986969,
      -0.03419080749154091,
      0.0026527168229222298,
      -0.019055234268307686,
      -0.020670637488365173,
      0.04049995169043541,
      0.07060439139604568,
      -0.06787797808647156,
      -0.04003525897860527,
      -0.06434348225593567,
      0.02619781345129013,
      -0.00625830190256238,
      0.002489450853317976,
      0.011058341711759567,
      -0.01544206216931343,
      -0.012120380997657776,
      0.08065943419933319,
      0.05295455455780029,
      -0.03495388478040695,
      -0.06864440441131592,
      0.022156795486807823,
      0.029714344069361687,
      -0.02206609770655632,
      -0.029714813455939293,
      0.007302486337721348,
      0.02290233038365841,
      0.0252264104783535,
      -0.021736929193139076,
      -0.08407209813594818,
      -0.025206737220287323,
      0.006440047640353441,
      0.02357827126979828,
      0.030320975929498672,
      0.08052925765514374,
      0.05220591276884079,
      0.09916841983795166,
      0.04136747121810913,
      0.04399406537413597,
      -0.02975638210773468,
      0.012121656909584999,
      -0.041182298213243484,
      -0.0711360052227974,
      -0.0473453663289547,
      -0.0001155233767349273,
      0.01636180654168129,
      0.0011297145392745733,
      -0.08306925743818283,
      -0.10820429772138596,
      -0.03390328213572502,
      0.03392498940229416,
      0.06955064833164215,
      0.09830630570650101,
      -0.06892864406108856,
      0.0505133755505085,
      0.0066419728100299835,
      -0.011260219849646091,
      -0.03647675737738609,
      -0.013523008674383163,
      -0.08069710433483124,
      -0.06102605536580086,
      0.009529290720820427,
      -0.023016514256596565,
      -0.003847727784886956,
      0.02807009592652321,
      -0.06788477301597595,
      0.009089170955121517,
      0.059171341359615326,
      0.022011689841747284,
      0.07974528521299362,
      0.01699749566614628,
      0.008962604217231274,
      0.008918145671486855,
      -0.04556797817349434,
      0.010368132032454014,
      -0.0862918496131897,
      -0.022388195618987083,
      0.03853257745504379,
      0.05262112617492676,
      -0.036901820451021194,
      0.04128829762339592,
      0.025847014039754868,
      -0.033930521458387375,
      -0.0755060464143753,
      0.012647459283471107,
      0.0393972285091877,
      0.057293716818094254,
      0.005787273868918419,
      0.06190088763833046,
      0.06391225755214691,
      -0.06089334934949875,
      -0.005851623602211475,
      -0.03484020754694939,
      -0.0035270981024950743,
      0.022285038605332375,
      -0.004323938861489296,
      -0.025466078892350197,
      -0.04519534111022949,
      0.02401638589799404,
      -0.023432964459061623,
      -0.013893389143049717,
      0.03412023186683655,
      0.0007004672079347074,
      0.025295184925198555,
      -0.03925463557243347,
      0.010345537215471268,
      0.0671878531575203,
      0.020673910155892372,
      0.17124995589256287,
      -0.08008769154548645,
      0.013425617478787899,
      0.05554870888590813,
      -0.07617300748825073,
      0.037130121141672134,
      -0.0331592783331871,
      0.0926029235124588,
      -0.045834027230739594,
      0.03538104519248009,
      -0.010408246889710426,
      0.002364770043641329,
      0.08024625480175018,
      -0.043645404279232025,
      -0.03748435899615288,
      -0.016439063474535942,
      0.01024075411260128,
      -0.02127373218536377,
      -0.0066240751184523106,
      -0.016572346910834312,
      -0.0894821286201477,
      -0.0552644357085228,
      0.04942920804023743,
      -0.015113522298634052,
      0.009157500229775906,
      0.024584077298641205,
      0.07132568210363388,
      -0.01251168828457594,
      -0.05850378796458244,
      0.06736398488283157,
      0.04665606468915939,
      0.009254240430891514,
      0.05648622661828995,
      -0.04469272866845131,
      -0.02481808327138424,
      -0.06216200441122055,
      -0.0723758265376091,
      0.012168925255537033,
      0.03719337284564972,
      -0.053432777523994446,
      0.06271741539239883,
      -0.05083870515227318,
      0.07568792253732681,
      -0.01994292438030243,
      -0.034024015069007874,
      -0.01761649362742901,
      0.05166507884860039,
      0.013009704649448395,
      0.05901745334267616,
      0.029655028134584427,
      -0.05527481064200401,
      -0.01006989274173975,
      0.026845695450901985,
      0.02332795225083828,
      0.02614281326532364,
      -0.09224943071603775,
      -0.017620544880628586,
      -0.059180691838264465,
      0.07504139840602875,
      0.026683205738663673,
      0.016303887590765953,
      -0.04632185399532318,
      -0.11050117760896683,
      -0.11100013554096222,
      -0.026232238858938217,
      -0.0716053694486618,
      -0.001146498485468328,
      0.08456243574619293,
      0.04945867136120796,
      0.004896949511021376,
      -0.008062594570219517,
      0.060694389045238495,
      -0.03971701115369797,
      0.015501393936574459,
      0.06381023675203323,
      0.01268291100859642,
      -0.014686057344079018,
      0.05373644456267357,
      -0.04785386845469475,
      0.015962257981300354,
      0.05083412677049637,
      0.0021649368572980165,
      -0.016983481124043465,
      -0.0541071742773056,
      -0.059910841286182404,
      0.05368949472904205,
      0.0861397460103035,
      0.004636985249817371,
      0.0695255920290947,
      -0.05965736508369446,
      -0.0357016921043396,
      -0.0010831203544512391,
      -0.10673101991415024,
      0.04104464501142502,
      -0.09654715657234192,
      0.04945433512330055,
      -0.08421500027179718,
      -0.04011036828160286,
      -0.01644439995288849,
      0.0029119180981069803,
      -0.015733778476715088,
      -0.07653822004795074,
      0.029149910435080528,
      0.05270235612988472,
      0.03267969191074371,
      -0.05225348472595215,
      -0.04067370668053627,
      0.03371250629425049,
      0.019781727343797684,
      -0.014078953303396702,
      0.03385159373283386,
      0.03634246066212654,
      -0.04850490763783455,
      -0.006735461764037609,
      0.059896741062402725,
      0.01527258288115263,
      0.007202122360467911,
      0.007316499017179012,
      -0.021652501076459885,
      0.01215499546378851,
      0.06985989212989807,
      -0.12692777812480927,
      0.0007119057700037956,
      -0.0360923632979393,
      -0.10762444883584976,
      0.011666879989206791,
      -0.02201174572110176,
      0.07052004337310791,
      0.010446369647979736,
      -0.019648058339953423,
      -0.004591716919094324,
      -0.07618192583322525,
      -0.02771591581404209,
      -0.02863096259534359,
      -0.01827327534556389,
      0.06784943491220474,
      -0.011738115921616554,
      -0.04136541858315468,
      0.0008318711770698428,
      -0.0449766181409359,
      -0.04542448744177818,
      -0.04271956905722618,
      0.005464935675263405,
      -0.061678703874349594,
      -0.11143259704113007,
      0.01667131297290325,
      -0.05133607238531113,
      0.004468065686523914,
      -0.06781788915395737,
      -0.04148087650537491,
      -0.033735472708940506,
      0.01952461153268814,
      -0.00017689072410576046,
      0.10889197885990143,
      0.018428925424814224,
      0.045112237334251404,
      -0.06613881886005402,
      -0.0387120321393013,
      0.03677673637866974,
      -0.05055033415555954,
      -0.013641344383358955,
      0.07115457206964493,
      -0.1089339628815651,
      0.017615722492337227,
      -0.01838104985654354,
      -0.03187291696667671,
      0.0050542838871479034,
      0.007727713789790869,
      -0.034643616527318954,
      -0.049922067672014236,
      0.027642041444778442,
      0.004311496391892433,
      0.040354061871767044,
      0.015972957015037537,
      0.03493368998169899,
      -0.04169926419854164,
      -0.05877527594566345,
      0.03999316692352295,
      0.02907082624733448,
      -0.013560385443270206,
      0.04289918765425682,
      0.01669338345527649,
      -0.04051334038376808,
      0.07584922015666962,
      -0.049710795283317566,
      -0.07053569704294205,
      -0.0315118171274662,
      0.07747738063335419,
      -0.005074458196759224,
      -0.04055146500468254,
      0.05812859162688255,
      -0.007063349708914757,
      0.006846193224191666,
      -0.07983013987541199,
      -0.06776800006628036,
      -0.06047354266047478,
      -0.1210058405995369,
      0.045589666813611984,
      0.003650815924629569,
      -0.028378374874591827,
      -0.017282644286751747,
      0.02876952290534973,
      -0.01301183458417654,
      -0.04391232505440712,
      -0.05817786976695061,
      -0.015483733266592026,
      -0.012023602612316608,
      -0.02572660706937313,
      0.037796296179294586,
      0.017316699028015137,
      -0.04017528519034386,
      0.08259287476539612,
      0.014347191900014877,
      0.027062801644206047,
      0.051097970455884933,
      -0.022380882874131203,
      -0.004522252827882767,
      0.018706129863858223,
      0.0709923654794693,
      0.026359304785728455
    ],
    [
      0.011831356212496758,
      0.06205734238028526,
      0.004320668522268534,
      -0.10285244137048721,
      0.04899773746728897,
      -0.03509855270385742,
      -0.0686967670917511,
      0.03238610550761223,
      0.005939322058111429,
      -0.046989891678094864,
      -0.01918460987508297,
      -0.0005239411257207394,
      -0.040190014988183975,
      -0.05297549441456795,
      0.05364103987812996,
      0.01043867040425539,
      -0.003640361363068223,
      0.030949588865041733,
      -0.07048244029283524,
      -0.02271060273051262,
      0.01623232662677765,
      -0.03903866931796074,
      -0.028964368626475334,
      0.051953356713056564,
      -0.06871844083070755,
      0.00950468797236681,
      0.027715329080820084,
      0.1414162963628769,
      0.04522940143942833,
      -0.003314810572192073,
      0.0010992902098223567,
      -0.06678440421819687,
      0.07611478865146637,
      0.00295987818390131,
      -0.015297475270926952,
      0.035283587872982025,
      0.0973672941327095,
      -0.023499377071857452,
      -0.07930489629507065,
      -0.035746023058891296,
      0.01707310602068901,
      -0.01670677587389946,
      0.035135362297296524,
      -0.03277835249900818,
      -0.04205156862735748,
      -0.035309143364429474,
      -0.0456123873591423,
      0.0216200053691864,
      0.0051874020136892796,
      0.05131189525127411,
      0.029004566371440887,
      0.05464419722557068,
      0.0027663814835250378,
      -0.034136705100536346,
      0.05263649672269821,
      0.025536496192216873,
      -0.02498786151409149,
      -0.059047065675258636,
      -0.024928800761699677,
      -0.004471801687031984,
      0.0039154342375695705,
      0.02652791142463684,
      0.010882296599447727,
      -0.0002030349860433489,
      0.08289289474487305,
      -0.03838152065873146,
      -0.029256047680974007,
      0.03720715641975403,
      -0.01628260128200054,
      0.001781036495231092,
      0.036473825573921204,
      -0.022678375244140625,
      -0.01574544608592987,
      -0.027352480217814445,
      -0.005085636395961046,
      -0.018703648820519447,
      0.017378924414515495,
      -0.05768391862511635,
      0.0008589341305196285,
      -0.029357269406318665,
      -0.06133962795138359,
      -0.030940061435103416,
      0.008236870169639587,
      0.06011875346302986,
      -0.03710828721523285,
      -0.033370908349752426,
      0.021534180268645287,
      0.021244773641228676,
      0.0004738140560220927,
      -0.0629890188574791,
      -0.04126762971282005,
      0.005246037617325783,
      -0.03161170333623886,
      -0.047819361090660095,
      0.07918095588684082,
      -0.031099438667297363,
      0.006279845722019672,
      -0.11065275967121124,
      0.0910520926117897,
      0.05545423552393913,
      0.021597132086753845,
      -0.02085019461810589,
      -0.009222019463777542,
      0.021622169762849808,
      0.008867481723427773,
      -0.01150739286094904,
      0.13096983730793,
      -0.05514998361468315,
      -0.05491388961672783,
      -0.025983914732933044,
      -0.06698821485042572,
      0.04706905037164688,
      -0.017675884068012238,
      0.015846729278564453,
      -0.022628571838140488,
      -0.013826461508870125,
      0.019235635176301003,
      -0.020264483988285065,
      0.047212813049554825,
      0.07081552594900131,
      0.006013206671923399,
      0.022051120176911354,
      0.00640105502679944,
      -0.0060868156142532825,
      0.05415768921375275,
      0.043656494468450546,
      0.02904977649450302,
      0.012552516534924507,
      -0.006338818930089474,
      0.003249294590204954,
      -0.0623890645802021,
      0.09483873099088669,
      0.08094575256109238,
      0.0034333420917391777,
      -0.03677154332399368,
      0.04803993180394173,
      0.0043711187317967415,
      0.019328845664858818,
      -0.050994548946619034,
      -0.023412048816680908,
      0.023163996636867523,
      0.10912910848855972,
      -0.035883404314517975,
      -0.013667304068803787,
      0.06648662686347961,
      -0.021015582606196404,
      -0.044789139181375504,
      -0.03642263635993004,
      0.03227612003684044,
      -0.00751475477591157,
      0.014760304242372513,
      0.0005930073675699532,
      -0.030185623094439507,
      -0.006573791615664959,
      -0.01933399774134159,
      -0.05295288935303688,
      -0.0331985279917717,
      -0.06479746103286743,
      0.006712369620800018,
      -0.07074163854122162,
      0.045422814786434174,
      -0.021909913048148155,
      0.02541225031018257,
      0.03989508002996445,
      -0.12372985482215881,
      -0.024699458852410316,
      0.013281566090881824,
      -0.051703836768865585,
      0.03658242151141167,
      0.040809936821460724,
      -0.013499910943210125,
      -0.02982853539288044,
      -0.057670947164297104,
      -0.09648937731981277,
      -0.040785886347293854,
      0.02175752818584442,
      -0.0694112628698349,
      0.023430384695529938,
      -0.03060474991798401,
      0.06533467024564743,
      0.03456040844321251,
      -0.04332722723484039,
      -0.008927751332521439,
      -0.05210768058896065,
      0.005029856227338314,
      0.031528398394584656,
      -0.02262819930911064,
      -0.014980051666498184,
      0.005170547869056463,
      0.07586117088794708,
      -0.04118352383375168,
      0.08218000084161758,
      -0.019178295508027077,
      -0.1495702564716339,
      0.024946723133325577,
      0.04311665892601013,
      -0.006390763446688652,
      -0.09550788998603821,
      -0.036850158125162125,
      0.12799103558063507,
      -0.02120598778128624,
      -0.1040148064494133,
      0.02250456064939499,
      -0.029902726411819458,
      0.07513110339641571,
      -0.0060853660106658936,
      0.030440881848335266,
      0.052950020879507065,
      -0.05401039868593216,
      -0.04280399903655052,
      0.014908574521541595,
      -0.029885021969676018,
      0.07912227511405945,
      0.0554746650159359,
      0.05626799911260605,
      -0.005154057871550322,
      0.019603122025728226,
      0.008431135676801205,
      -0.0012887215707451105,
      -0.06686761230230331,
      -0.027885867282748222,
      0.13249002397060394,
      0.023196816444396973,
      -0.010304680094122887,
      -0.0596783347427845,
      -0.06955172121524811,
      -0.019665097817778587,
      0.03815201297402382,
      -0.04718499630689621,
      0.04374506324529648,
      -0.005386994685977697,
      -0.012802805751562119,
      0.06430598348379135,
      -0.06957582384347916,
      0.04337579011917114,
      -0.09235133230686188,
      -0.0078633027151227,
      0.0042055705562233925,
      -0.012042251415550709,
      0.058186810463666916,
      -2.0286433937144466e-06,
      0.054781876504421234,
      0.013882037252187729,
      0.01778424344956875,
      0.043072909116744995,
      0.0011239575687795877,
      -0.030982395634055138,
      0.026859117671847343,
      -0.10518811643123627,
      -0.08150029927492142,
      -0.005335131660103798,
      -0.10213318467140198,
      0.037227556109428406,
      0.02925444208085537,
      0.0294039323925972,
      -0.05397234112024307,
      -0.020617343485355377,
      0.03792328014969826,
      0.05146792531013489,
      0.03708944469690323,
      0.08881470561027527,
      -0.010464671067893505,
      -0.04046887159347534,
      -0.014176588505506516,
      -0.041132133454084396,
      0.07157086580991745,
      0.04291988164186478,
      -0.001131984405219555,
      -0.020321281626820564,
      0.03856256604194641,
      -0.033313166350126266,
      -0.01856219209730625,
      -0.005162124987691641,
      0.016995500773191452,
      0.011628069914877415,
      0.012077548541128635,
      0.01567446067929268,
      0.0005228394875302911,
      0.061118949204683304,
      -0.005130315665155649,
      0.0041542649269104,
      -0.009259977377951145,
      0.0076780784875154495,
      -0.006124114152044058,
      0.036955155432224274,
      0.024976884946227074,
      0.050726212561130524,
      0.06066332757472992,
      0.03129196539521217,
      -0.03379117697477341,
      0.001660495880059898,
      0.05530812218785286,
      0.013387072831392288,
      -0.07516675442457199,
      -0.0086108623072505,
      -0.029120419174432755,
      -0.00407511368393898,
      -0.0054830643348395824,
      0.0673595443367958,
      0.014484242536127567,
      -0.05121433362364769,
      -0.00240575079806149,
      0.06915008276700974,
      0.015634510666131973,
      -0.09854787588119507,
      0.00042755549657158554,
      -0.04778384044766426,
      0.014539026655256748,
      -0.04383384436368942,
      -0.024001793935894966,
      -0.07640320062637329,
      -0.10009657591581345,
      -0.013927595689892769,
      0.07381780445575714,
      0.011457408778369427,
      -0.023669058457016945,
      0.049817994236946106,
      -0.0028337265830487013,
      0.02649568021297455,
      0.019994141533970833,
      0.011814361438155174,
      0.035836637020111084,
      0.07535748183727264,
      -0.010158839635550976,
      -0.0038391458801925182,
      -0.05622119829058647,
      -0.04448020085692406,
      -0.01909746788442135,
      -0.042572375386953354,
      0.09315967559814453,
      -0.06495024263858795,
      -0.017777662724256516,
      0.016535332426428795,
      -0.0034154567401856184,
      0.022648729383945465,
      0.049202810972929,
      0.060282472521066666,
      -0.03965793177485466,
      0.0525849387049675,
      -0.021067334339022636,
      0.012806878425180912,
      0.0012688792776316404,
      0.006523567251861095,
      -0.023190230131149292,
      0.005840399768203497,
      0.0517280176281929,
      -0.0257695484906435,
      -0.050563205033540726,
      0.0021436091046780348,
      -0.011232564225792885,
      -0.03836309164762497,
      -0.004631819669157267,
      -0.029383022338151932,
      0.0005600758013315499,
      -0.07679563760757446,
      -0.06140965223312378,
      0.08596931397914886,
      0.016829362139105797,
      0.023140549659729004,
      -0.004392124712467194,
      0.013090373948216438,
      0.022895855829119682,
      0.0004354042175691575,
      -0.02686072513461113,
      -0.01685505174100399,
      0.02140658348798752,
      0.012104588560760021,
      0.016232410445809364,
      -0.030440237373113632,
      -0.04590638726949692,
      -0.012993831187486649,
      0.06363806873559952,
      -0.008784903213381767,
      0.029980115592479706,
      -0.028473129495978355,
      0.019818687811493874,
      0.05703655257821083,
      -0.0787113681435585,
      -0.07360684871673584,
      -0.045953162014484406,
      -0.03354949131608009,
      0.030668670311570168,
      0.07700798660516739,
      -0.010883085429668427,
      -0.022892778739333153,
      -0.011968957260251045,
      0.049865078181028366,
      0.02185412310063839,
      -0.051024243235588074,
      0.01299254409968853,
      -0.028568867594003677,
      0.030287696048617363,
      -0.006314470898360014,
      -0.1008087620139122,
      0.0020847790874540806,
      -0.03345106169581413,
      -0.031834714114665985,
      0.004793274216353893,
      -0.00040467464714311063,
      -0.07728181779384613,
      0.030472561717033386,
      -0.020012356340885162,
      -0.014243215322494507,
      -0.0762852355837822,
      0.039055969566106796,
      -0.02684750221669674,
      -0.018522389233112335,
      0.04310091957449913,
      -0.020004328340291977,
      -0.03363078460097313,
      -0.040777795016765594,
      -0.06187829747796059,
      -0.05917736142873764,
      -0.05989880859851837,
      0.012053309939801693,
      -0.0166812464594841,
      -0.03417263925075531,
      0.09136436879634857,
      -0.02705169841647148,
      -0.013977862894535065,
      0.017747987061738968,
      -0.038098324090242386,
      -0.07943793386220932,
      0.06354536861181259,
      -0.08370447903871536,
      0.027168942615389824,
      0.0020903986878693104,
      -0.08835740387439728,
      0.05571477487683296,
      -0.07361981272697449,
      -0.0442194864153862,
      0.030358409509062767,
      0.005657555069774389,
      0.038975585252046585,
      0.04737076163291931,
      0.04699170961976051,
      -0.01669827103614807,
      0.04310467839241028,
      0.043086420744657516,
      0.06182650849223137,
      -0.03506740555167198,
      -0.028284866362810135,
      0.019427577033638954,
      -0.0002580929431132972,
      -0.03769540786743164,
      0.01589781790971756,
      -0.04438665136694908,
      -0.032477907836437225,
      0.007865384221076965,
      0.0603085458278656,
      0.030686507001519203,
      -0.0259048193693161,
      0.006218635011464357,
      -0.05067128688097,
      0.043220579624176025,
      0.11192631721496582,
      -0.006407255306839943,
      -0.016753636300563812,
      -0.039713483303785324,
      -0.06360017508268356,
      0.0034888191148638725,
      -0.04291953146457672,
      -0.1426699161529541,
      -0.09744790196418762,
      -0.012422643601894379,
      0.040687646716833115,
      0.041383784264326096,
      0.05233389511704445,
      -0.027222076430916786,
      -0.021112436428666115,
      -0.006578538566827774,
      -0.04859068989753723,
      0.03143664449453354,
      -0.05669659003615379,
      -0.11592023074626923,
      -0.022442033514380455,
      0.03144486993551254,
      -0.003116919891908765,
      0.0440513975918293,
      0.0027457172982394695,
      0.029900316148996353,
      -0.07450839877128601,
      0.03667154535651207,
      -0.012294391170144081,
      -0.0346236489713192,
      0.055641014128923416,
      -0.008859667927026749,
      -0.004824785050004721,
      0.027501435950398445,
      0.0024422311689704657,
      0.0038864226080477238,
      0.03073911927640438,
      0.010360858403146267,
      0.10993710905313492,
      0.13263140618801117,
      0.05658844858407974,
      -0.04937933385372162,
      0.027664534747600555,
      -0.05972498655319214,
      0.0007563332328572869,
      -0.09971031546592712,
      -0.02218388393521309,
      0.011427758261561394
    ],
    [
      0.014304152689874172,
      -0.009369284845888615,
      0.06366553902626038,
      -0.02053225412964821,
      -0.026927517727017403,
      -0.013483294285833836,
      0.0020685927011072636,
      0.017640268430113792,
      -0.055341482162475586,
      0.10351583361625671,
      0.024676701053977013,
      -0.029750114306807518,
      -0.032638683915138245,
      -0.08363887667655945,
      -0.04546621814370155,
      -0.008690537884831429,
      0.013767940923571587,
      -0.014600031077861786,
      0.04034304618835449,
      -0.00011103083670604974,
      -0.06591252237558365,
      -0.046495284885168076,
      0.02433486469089985,
      0.006042455788701773,
      -0.029961030930280685,
      -0.006973605137318373,
      0.01723421737551689,
      0.059628281742334366,
      -0.04832201078534126,
      -0.01880303956568241,
      0.05522969365119934,
      0.07398524880409241,
      -0.003190099960193038,
      -0.03928646072745323,
      0.028423622250556946,
      0.0438041016459465,
      0.03180844336748123,
      0.003162047825753689,
      -0.07614701986312866,
      0.014921479858458042,
      0.005662941839545965,
      0.060161978006362915,
      0.0009261637460440397,
      -0.019239366054534912,
      0.010907771997153759,
      0.03779400512576103,
      0.018191544339060783,
      0.017432095482945442,
      -0.0045582507736980915,
      0.03660137578845024,
      0.037117768079042435,
      -0.07046602666378021,
      -0.0441482774913311,
      0.023349907249212265,
      -0.007545174565166235,
      0.010599463246762753,
      0.08298582583665848,
      0.004497430752962828,
      -0.07778656482696533,
      -0.017802052199840546,
      -0.06844334304332733,
      -0.009291615337133408,
      -0.01925371028482914,
      0.05276431888341904,
      0.047710951417684555,
      0.019486021250486374,
      0.10909318178892136,
      0.031834036111831665,
      -0.011465382762253284,
      -0.07840515673160553,
      0.05717439576983452,
      0.05425387620925903,
      -0.02576998621225357,
      -0.02857721969485283,
      -0.0022869177628308535,
      -0.024702314287424088,
      0.11316244304180145,
      -0.07140085846185684,
      -0.12472265958786011,
      -0.02351374737918377,
      -0.023732444271445274,
      0.014273155480623245,
      0.027730019763112068,
      -0.0354769192636013,
      -0.09566012024879456,
      -0.0068694851361215115,
      0.01373495627194643,
      -0.07696805894374847,
      -0.014151612296700478,
      -0.09022664278745651,
      0.005919011775404215,
      -0.061132948845624924,
      0.050534460693597794,
      -0.06938182562589645,
      0.017231494188308716,
      0.05309811979532242,
      0.0718846544623375,
      -0.0014670172240585089,
      -0.06752970814704895,
      0.004185931757092476,
      -0.028410084545612335,
      0.06691086292266846,
      0.06674174219369888,
      0.024639051407575607,
      -0.05637025833129883,
      0.020338591188192368,
      -0.049507830291986465,
      -0.022870680317282677,
      -0.025324681773781776,
      -0.034801896661520004,
      0.0018353015184402466,
      0.01972441002726555,
      -0.004399849567562342,
      -0.09766984730958939,
      0.054163698107004166,
      0.01096718292683363,
      0.009823538362979889,
      0.040529217571020126,
      0.024105126038193703,
      0.041939496994018555,
      0.07186947762966156,
      0.003652210347354412,
      -0.04872121289372444,
      -0.01918664015829563,
      0.004012783989310265,
      0.038204118609428406,
      0.0338263064622879,
      0.06978041678667068,
      0.04840661585330963,
      -0.04167395457625389,
      -0.031645044684410095,
      0.0005989836063235998,
      0.05710253864526749,
      0.005474992096424103,
      0.013189836405217648,
      0.02356904186308384,
      0.027110010385513306,
      0.007419676054269075,
      0.056788183748722076,
      0.00540322856977582,
      0.05384816601872444,
      0.022673046216368675,
      0.005754226818680763,
      -0.03383048251271248,
      0.002566408598795533,
      0.051943339407444,
      -0.05352794751524925,
      0.03924805298447609,
      0.04834981635212898,
      0.0526994913816452,
      -0.008002561517059803,
      0.009549388661980629,
      -0.03170960396528244,
      0.08667061477899551,
      -0.0038498006761074066,
      0.0545855388045311,
      -0.016915058717131615,
      0.014438525773584843,
      0.01650633104145527,
      0.039785631000995636,
      -0.0392192117869854,
      0.05088108405470848,
      -0.04295727238059044,
      0.010869459249079227,
      0.06386683136224747,
      -0.06029457971453667,
      0.04490913823246956,
      0.08255290240049362,
      -0.004785484168678522,
      -0.04551023617386818,
      -0.04852987453341484,
      0.024604957550764084,
      -0.022011928260326385,
      0.016021577641367912,
      0.01405790913850069,
      -0.022075742483139038,
      -0.035551413893699646,
      -0.014473828487098217,
      -0.11019878834486008,
      0.01004714798182249,
      0.00586581788957119,
      0.03437778353691101,
      0.024874532595276833,
      -0.04506012424826622,
      0.02558150142431259,
      -0.04453732818365097,
      0.018350502476096153,
      -0.02983172796666622,
      0.02755587361752987,
      0.006848770193755627,
      -0.08751868456602097,
      0.045821137726306915,
      0.010547942481935024,
      -0.04723304510116577,
      0.053010422736406326,
      -0.0027141948230564594,
      0.00344314007088542,
      -0.05543911084532738,
      0.0537504144012928,
      -0.03354191035032272,
      -0.026971539482474327,
      -0.004583857487887144,
      -0.03565073758363724,
      0.0334758497774601,
      0.015432373620569706,
      -0.0025004164781421423,
      -0.08301742374897003,
      0.009328522719442844,
      -0.03231463581323624,
      -0.07016956061124802,
      0.024185145273804665,
      -0.003284187987446785,
      0.054387450218200684,
      0.09871402382850647,
      0.010937513783574104,
      -0.024667030200362206,
      0.04576161131262779,
      0.004844329319894314,
      -0.030655432492494583,
      0.109705850481987,
      0.0039001014083623886,
      -0.0089629041031003,
      -0.04934917017817497,
      0.07885587960481644,
      -0.013829303905367851,
      0.050241418182849884,
      0.015509414486587048,
      0.0336979515850544,
      -0.033854302018880844,
      -0.038802217692136765,
      0.013541112653911114,
      0.0688093826174736,
      -0.0709489956498146,
      -0.026120705530047417,
      0.0007314754766412079,
      0.02504643425345421,
      -0.017649056389927864,
      0.01539350114762783,
      0.03009706735610962,
      0.01938539929687977,
      -0.02406856045126915,
      0.03314277529716492,
      -0.013500827364623547,
      -0.07738197594881058,
      0.013144046999514103,
      0.0580470897257328,
      -0.018369030207395554,
      0.02661590464413166,
      -0.041853953152894974,
      0.01072377897799015,
      -0.02257603406906128,
      0.05189773067831993,
      -0.04135214164853096,
      -0.030177095904946327,
      -0.0597759373486042,
      -0.026339324191212654,
      0.03728213533759117,
      0.012402047403156757,
      -0.06364107131958008,
      -0.02340380847454071,
      0.030724190175533295,
      -0.07509712129831314,
      0.0032686812337487936,
      0.06178766489028931,
      0.015280915424227715,
      -0.033657852560281754,
      -0.021280715242028236,
      0.007590466644614935,
      0.02033337391912937,
      0.005540285259485245,
      0.004184506833553314,
      0.061318397521972656,
      0.000901551335118711,
      -0.03025248274207115,
      -0.0005780935171060264,
      -0.057611383497714996,
      -0.007549549452960491,
      -0.093805231153965,
      0.034350086003541946,
      0.013545711524784565,
      -0.005507918074727058,
      0.01906501315534115,
      0.037348195910453796,
      -0.03966975212097168,
      0.01244920864701271,
      0.06519258767366409,
      0.090544193983078,
      0.1003832072019577,
      -0.06580150127410889,
      -0.05483650416135788,
      0.026033183559775352,
      0.08883058279752731,
      -0.009611593559384346,
      -0.02873908169567585,
      -0.0178521815687418,
      -0.03774542361497879,
      -0.06868655234575272,
      -0.04039277136325836,
      -0.03247413784265518,
      0.01151188649237156,
      -0.01929594576358795,
      0.0382453128695488,
      -0.043366510421037674,
      -0.029667643830180168,
      0.004557386040687561,
      -0.005920536816120148,
      0.005873424466699362,
      -0.008328985422849655,
      -0.016078229993581772,
      0.02686578594148159,
      -0.003014483954757452,
      -0.03668811917304993,
      -0.08615036308765411,
      -0.016016121953725815,
      -0.10756496340036392,
      -0.039053186774253845,
      0.038949958980083466,
      0.04479388892650604,
      -0.05369989573955536,
      -0.1152966320514679,
      0.0031018087174743414,
      0.06849655508995056,
      -0.00946439616382122,
      -0.010251239873468876,
      0.05600421130657196,
      -0.08315474539995193,
      0.04130754619836807,
      -0.0467885322868824,
      -0.017452124506235123,
      -0.0004265744355507195,
      0.0051689231768250465,
      -0.010193646885454655,
      0.018860040232539177,
      0.025718817487359047,
      -0.02110511064529419,
      0.02900521643459797,
      0.02614106424152851,
      -0.05292574688792229,
      0.03464224189519882,
      0.06664590537548065,
      0.029065420851111412,
      -0.044893182814121246,
      -0.04161076992750168,
      0.02204742655158043,
      0.06991131603717804,
      0.015689637511968613,
      -0.02683415450155735,
      -0.0366259440779686,
      0.05555437505245209,
      0.029902596026659012,
      -0.0021499241702258587,
      0.05286521092057228,
      0.020083867013454437,
      -0.11554087698459625,
      -0.025120999664068222,
      0.038598209619522095,
      -0.09024244546890259,
      -0.030607376247644424,
      0.03826465830206871,
      -0.04850545898079872,
      0.019019920378923416,
      0.046087853610515594,
      -0.03383999690413475,
      0.011060698889195919,
      0.007261944003403187,
      0.050108861178159714,
      -0.08817867934703827,
      -0.1217246800661087,
      0.035047050565481186,
      -0.023093556985259056,
      0.019561083987355232,
      0.10260162502527237,
      -0.08279751241207123,
      0.016194051131606102,
      -0.01633617654442787,
      -0.02562946081161499,
      0.056771572679281235,
      0.06295079737901688,
      0.01968347653746605,
      -0.05361505597829819,
      0.047920361161231995,
      -0.08895987272262573,
      -0.010571603663265705,
      0.05890941619873047,
      0.021937962621450424,
      -0.021521329879760742,
      0.08167040348052979,
      -0.03400673344731331,
      0.023186275735497475,
      -0.03781313821673393,
      -0.03682592511177063,
      0.02887764386832714,
      0.026544606313109398,
      -0.020350083708763123,
      -0.028269823640584946,
      -0.020428311079740524,
      0.010712369345128536,
      -0.1054050624370575,
      -0.007642643991857767,
      -0.005887114442884922,
      -0.0638541504740715,
      0.00791702140122652,
      -0.0018897625850513577,
      0.01334173884242773,
      0.023604528978466988,
      0.06713323295116425,
      0.021221086382865906,
      0.016176356002688408,
      -0.08313601464033127,
      0.009247838519513607,
      0.04743466153740883,
      0.015538085252046585,
      0.018084252253174782,
      -0.004627385176718235,
      0.047938160598278046,
      -0.020213957875967026,
      0.0527854822576046,
      -0.044217102229595184,
      0.07599589973688126,
      0.00286388723179698,
      -0.01400897465646267,
      -0.09277106821537018,
      0.006802233401685953,
      0.03386232256889343,
      0.0073695797473192215,
      -0.039181187748909,
      0.01598217710852623,
      0.030751997604966164,
      0.042172621935606,
      -0.03222917765378952,
      -0.05521351099014282,
      -0.027737732976675034,
      0.02436995320022106,
      -0.021981842815876007,
      0.07859857380390167,
      -0.013469483703374863,
      -0.014399627223610878,
      0.058846209198236465,
      0.056129492819309235,
      -0.03979731723666191,
      -0.011502505280077457,
      -0.029731137678027153,
      -0.004534993786364794,
      0.025403831154108047,
      -0.023726264014840126,
      -0.08742881566286087,
      -0.09675376862287521,
      -0.019308073446154594,
      -0.015435018576681614,
      -0.014667567797005177,
      -0.07249228656291962,
      0.029829194769263268,
      0.06187691539525986,
      0.04677177965641022,
      -0.029892347753047943,
      0.05170958489179611,
      -0.023445041850209236,
      -0.028177103027701378,
      0.022021794691681862,
      -0.018593354150652885,
      0.028903096914291382,
      0.1473212093114853,
      -0.004394412040710449,
      -0.021980589255690575,
      0.005571470595896244,
      0.0033819039817899466,
      -0.05873372405767441,
      0.0003665386757347733,
      -0.01519241277128458,
      -0.03326110169291496,
      0.03608463332056999,
      0.052749693393707275,
      -0.014808285981416702,
      -0.045370232313871384,
      -0.03873716667294502,
      -0.12379352003335953,
      0.04051034525036812,
      -0.013216089457273483,
      -0.018509915098547935,
      0.00046763871796429157,
      -0.035519156605005264,
      -0.06920096278190613,
      -0.06690608710050583,
      -0.015110503882169724,
      -0.06103065237402916,
      0.03385017067193985,
      0.03149102255702019,
      0.02938874624669552,
      0.061609651893377304,
      -0.022683823481202126,
      0.023560406640172005,
      -0.0009323302656412125,
      0.05550901219248772,
      -0.031103715300559998,
      -0.014951102435588837,
      0.05274827033281326,
      0.024397986009716988,
      0.06321997195482254,
      0.052284128963947296,
      0.07571747153997421,
      -0.017897607758641243,
      -0.062002360820770264,
      -0.05682501569390297
    ],
    [
      0.04716905951499939,
      0.004841585177928209,
      0.019476979970932007,
      0.01916433498263359,
      -0.03941705822944641,
      -0.011901182122528553,
      -0.05554375424981117,
      -0.014442251063883305,
      0.00308413477614522,
      0.03735607862472534,
      -0.003964697476476431,
      -0.010041854344308376,
      0.0013937816256657243,
      0.04473115876317024,
      -0.004536529537290335,
      -0.025984114035964012,
      0.022281594574451447,
      -0.061916712671518326,
      0.03928828239440918,
      -0.021544765681028366,
      0.03351813182234764,
      0.01198077667504549,
      -0.02113417163491249,
      0.037831567227840424,
      -0.05073484033346176,
      0.007463901769369841,
      0.004704602062702179,
      -0.006825927644968033,
      0.014977149665355682,
      0.015579557046294212,
      -0.03951739892363548,
      0.06027601659297943,
      -0.02669988013803959,
      0.0326433964073658,
      0.029644640162587166,
      -0.02203303389251232,
      -0.05571538954973221,
      0.00960755255073309,
      0.03629986569285393,
      -0.05228102579712868,
      0.09565535187721252,
      0.04128354787826538,
      0.1199769526720047,
      0.03369799256324768,
      0.009320532903075218,
      -0.06215871125459671,
      -0.062055084854364395,
      -0.004304027650505304,
      0.05301245301961899,
      -0.005996118299663067,
      -0.10277210175991058,
      0.016902416944503784,
      0.06309687346220016,
      0.04365292191505432,
      -0.03674525022506714,
      -0.003448285162448883,
      -0.01831768825650215,
      -0.02696414664387703,
      0.045380182564258575,
      -0.0643681064248085,
      0.011864732950925827,
      -0.013661911711096764,
      0.07656025886535645,
      -0.0029555540531873703,
      0.025185544043779373,
      -0.0013397583970800042,
      0.03923366591334343,
      -0.04280253127217293,
      0.025545407086610794,
      -0.06295227259397507,
      0.03129405528306961,
      -0.0364844910800457,
      0.027264893054962158,
      -0.07010051608085632,
      0.051804885268211365,
      0.03366326168179512,
      -0.016455234959721565,
      -0.01565570943057537,
      0.06424449384212494,
      -0.03677906468510628,
      0.006391183938831091,
      0.024992823600769043,
      -0.03768778219819069,
      0.036916155368089676,
      -0.05180932953953743,
      0.01747371256351471,
      -0.05513111874461174,
      -0.03210754320025444,
      -0.00017934100469574332,
      0.025757990777492523,
      0.03627901151776314,
      0.04715915396809578,
      0.08623810112476349,
      -0.05993501469492912,
      0.020287826657295227,
      0.004096421878784895,
      0.03174171596765518,
      -0.06629877537488937,
      0.07114996761083603,
      -0.025495532900094986,
      0.00880836695432663,
      -0.03126930072903633,
      -0.06080250442028046,
      0.00395187409594655,
      -0.05574367940425873,
      0.04914280027151108,
      -0.03791099414229393,
      -0.04215773195028305,
      -0.02320067211985588,
      -0.05181484296917915,
      0.023189714178442955,
      -0.02042686566710472,
      -0.10137008130550385,
      -0.07090699672698975,
      0.017460355535149574,
      0.021595608443021774,
      -0.06525049358606339,
      -0.042365219444036484,
      0.004515422508120537,
      0.034216444939374924,
      0.00405412120744586,
      0.03615475818514824,
      -0.019914351403713226,
      0.01762561872601509,
      0.05009022355079651,
      -0.021526889875531197,
      0.020540131255984306,
      0.006031033117324114,
      0.02156965062022209,
      -0.05694836378097534,
      -0.06741931289434433,
      -0.003299674019217491,
      -0.11173876374959946,
      -0.023884661495685577,
      0.0011528265895321965,
      -0.06533276289701462,
      -0.016939222812652588,
      0.03921883553266525,
      0.06499495357275009,
      0.07065605372190475,
      -0.10315194725990295,
      -0.07953041046857834,
      0.05015648156404495,
      -0.06171408295631409,
      -0.008327028714120388,
      -0.11242695152759552,
      0.0031193390022963285,
      0.016662277281284332,
      0.04062483459711075,
      -0.07085759192705154,
      0.0340411476790905,
      0.06781185418367386,
      0.03069509193301201,
      0.035886988043785095,
      0.029850754886865616,
      0.023645801469683647,
      0.013534381985664368,
      -0.009125154465436935,
      -0.06492741405963898,
      -0.0618353895843029,
      0.007581925019621849,
      0.01815778948366642,
      0.039885979145765305,
      -0.1141929030418396,
      -0.07076645642518997,
      -0.0178633164614439,
      -0.018968237563967705,
      0.03376628831028938,
      -0.021693961694836617,
      -0.004751596134155989,
      -0.0018943011527881026,
      -0.04187828302383423,
      0.048140477389097214,
      0.025372229516506195,
      -0.08311853557825089,
      -0.02924354374408722,
      -0.040527671575546265,
      0.004682553466409445,
      0.07785065472126007,
      0.025432204827666283,
      -0.028797950595617294,
      -0.05937301367521286,
      0.017463622614741325,
      0.009427857585251331,
      -0.01729409769177437,
      0.0036934497766196728,
      0.06162169203162193,
      -0.06032398343086243,
      -0.04046850651502609,
      0.0025332183577120304,
      -0.05590926855802536,
      -0.011421599425375462,
      -0.038941532373428345,
      0.02164517715573311,
      -0.05468064174056053,
      0.007874657399952412,
      -0.000288426352199167,
      0.06110219284892082,
      0.056037381291389465,
      0.027209201827645302,
      0.03590040281414986,
      0.02040228620171547,
      -0.04341818019747734,
      0.056718166917562485,
      -0.03712074086070061,
      0.026602689176797867,
      0.05578961595892906,
      -0.006834177300333977,
      -0.0031709130853414536,
      -0.012701925821602345,
      0.059523805975914,
      -0.09464655071496964,
      -0.003750945907086134,
      0.0913081020116806,
      0.04273352026939392,
      0.11302085220813751,
      -0.02237905003130436,
      -0.05812476575374603,
      0.014652686193585396,
      -0.05255449190735817,
      0.03044477291405201,
      -0.03148028254508972,
      -0.017727164551615715,
      -0.04780885949730873,
      0.022104354575276375,
      -0.022146543487906456,
      -0.03662420064210892,
      -0.01923603191971779,
      0.07718800753355026,
      -0.034322742372751236,
      -0.018459076061844826,
      -0.015442175790667534,
      -0.11031737923622131,
      -0.014031507074832916,
      0.02419402077794075,
      0.010704092681407928,
      0.020878203213214874,
      0.010380219668149948,
      0.0031553825829178095,
      0.03392153978347778,
      0.05460677668452263,
      -0.0462072491645813,
      -0.06587736308574677,
      0.05517046898603439,
      -0.03954319655895233,
      -0.08188338577747345,
      0.015654442831873894,
      0.059001240879297256,
      -0.05651334300637245,
      -0.054456427693367004,
      -0.07625476270914078,
      -0.019327623769640923,
      -0.07368925958871841,
      -0.05265963822603226,
      0.03079133667051792,
      0.03874151036143303,
      -0.028088899329304695,
      0.06930706650018692,
      -0.02525654435157776,
      0.07249506562948227,
      -0.041277188807725906,
      0.012043207883834839,
      -0.0020856864284723997,
      -0.06751731038093567,
      -0.00021676810865756124,
      0.061316944658756256,
      -0.028133757412433624,
      0.034460533410310745,
      -0.004871011711657047,
      -0.01667962782084942,
      0.06045770272612572,
      -0.08282805979251862,
      -0.03760116919875145,
      -0.06852677464485168,
      0.1030128002166748,
      -0.04198528081178665,
      0.05476323142647743,
      0.023506982252001762,
      0.05098855867981911,
      0.029978422448039055,
      -0.04012914374470711,
      -0.08485790342092514,
      -0.04805590584874153,
      0.01726810820400715,
      0.01590748131275177,
      -0.07200644910335541,
      -0.05154350772500038,
      -0.027545996010303497,
      -0.034229569137096405,
      -0.05992712825536728,
      0.03867083042860031,
      -0.03425506129860878,
      -0.0963064655661583,
      -0.09943190962076187,
      -0.029020089656114578,
      -0.029511628672480583,
      0.03903668373823166,
      -0.041144661605358124,
      -0.06944479048252106,
      -0.0067403679713606834,
      -0.08261577039957047,
      0.01111679058521986,
      -0.003380127949640155,
      -0.07434820383787155,
      -0.002850472927093506,
      -0.023924287408590317,
      5.8798552345251665e-05,
      -0.0664324015378952,
      -0.05619300156831741,
      0.1512659341096878,
      0.09764702618122101,
      -0.0388040654361248,
      -0.09194280207157135,
      -0.02434958703815937,
      0.08590676635503769,
      -0.015004976652562618,
      -0.034088384360075,
      -0.09896684437990189,
      -0.01445718016475439,
      -0.11254509538412094,
      0.02533825673162937,
      0.040482062846422195,
      0.01874006725847721,
      -0.06765326857566833,
      -0.00874016247689724,
      -0.03395117446780205,
      -0.027538249269127846,
      0.015463169664144516,
      0.010454648174345493,
      0.03009084053337574,
      0.0019008776871487498,
      0.02395704761147499,
      0.020403074100613594,
      -0.010699748992919922,
      -0.01078000944107771,
      -0.01248854212462902,
      -0.017756402492523193,
      0.04893207922577858,
      -0.06827107071876526,
      -0.034907277673482895,
      0.03545914962887764,
      0.012873707339167595,
      -0.006284954492002726,
      0.02379509061574936,
      0.08677328377962112,
      -0.05702114850282669,
      -0.04794524982571602,
      0.023369181901216507,
      0.04679097607731819,
      -0.06759221851825714,
      0.024198830127716064,
      -0.06714311242103577,
      -0.06044787913560867,
      0.00010388850205345079,
      -0.017291299998760223,
      -0.08327837288379669,
      0.033244743943214417,
      0.01772482879459858,
      -0.06857932358980179,
      -0.03902605175971985,
      0.0074724070727825165,
      0.00526722427457571,
      -0.07584374397993088,
      -0.006206638179719448,
      0.04824941232800484,
      0.042976997792720795,
      0.020785212516784668,
      -0.025379104539752007,
      -0.081364706158638,
      -0.09061042964458466,
      -0.010554111562669277,
      -0.04126348719000816,
      0.10256102681159973,
      0.010091695003211498,
      0.05015580356121063,
      -0.06031816452741623,
      0.05472983419895172,
      -0.005621351767331362,
      -0.030393265187740326,
      -0.002378832083195448,
      0.03243855759501457,
      -0.010724798776209354,
      0.04690016061067581,
      -0.007739621214568615,
      -0.05986562743782997,
      0.05442428216338158,
      0.0030802569817751646,
      0.028416302055120468,
      -0.06046589836478233,
      -0.005355803295969963,
      0.027033602818846703,
      -0.03347603976726532,
      -0.10489822179079056,
      -0.006802583113312721,
      0.0328727588057518,
      0.0035924003459513187,
      0.0031250454485416412,
      -0.015632547438144684,
      0.025328435003757477,
      -0.002775511471554637,
      -0.06519953161478043,
      0.0035075589548796415,
      -0.04233700782060623,
      -0.04849917069077492,
      0.07497264444828033,
      0.06291448324918747,
      -0.013362344354391098,
      0.020252516493201256,
      0.023141097277402878,
      -0.010349513031542301,
      -0.051709290593862534,
      0.06109831854701042,
      0.0015415407251566648,
      0.08731645345687866,
      -0.017011508345603943,
      -0.008144421502947807,
      0.03172287717461586,
      -0.001816525706090033,
      -0.054778803139925,
      0.02724825218319893,
      -0.013040207326412201,
      -0.034120529890060425,
      0.005561819300055504,
      -0.07244081795215607,
      -0.009044235572218895,
      0.0016573200700804591,
      -0.029119767248630524,
      -0.027198638767004013,
      0.023913050070405006,
      0.0007737725391052663,
      -0.01380923856049776,
      0.11726831644773483,
      -0.05937008187174797,
      0.018887991085648537,
      -0.039206475019454956,
      -0.006699751131236553,
      0.03689700737595558,
      0.008393334224820137,
      -0.06805426627397537,
      -0.002644534222781658,
      -0.04878509044647217,
      -0.020452307537198067,
      0.02556811086833477,
      0.054499510675668716,
      -0.042099662125110626,
      -0.014236915856599808,
      -0.04905396327376366,
      -0.05418311804533005,
      -0.05660973861813545,
      -0.0767216831445694,
      0.0023860353976488113,
      -0.02823067083954811,
      -0.056202858686447144,
      -0.018789026886224747,
      -0.029710538685321808,
      -0.019521523267030716,
      0.04658191651105881,
      -0.04812892898917198,
      0.036159414798021317,
      -0.05129420384764671,
      0.047786373645067215,
      0.1203954890370369,
      0.06557148694992065,
      0.004386222921311855,
      -0.00048248926759697497,
      0.009453062899410725,
      -0.020951230078935623,
      0.0575082041323185,
      0.049893684685230255,
      -0.025575395673513412,
      -0.005047236569225788,
      -0.0105559341609478,
      -0.001705758972093463,
      0.05183032900094986,
      0.0021417015232145786,
      0.023890534415841103,
      0.009513885714113712,
      0.02239396795630455,
      -0.028265684843063354,
      -0.09958131611347198,
      0.09054149687290192,
      -0.0555218942463398,
      0.017584465444087982,
      -0.0021767637226730585,
      -0.07365302741527557,
      -0.03651462122797966,
      0.06928568333387375,
      0.04474998638033867,
      -0.007873431779444218,
      -0.01822894997894764,
      0.09884852916002274,
      -0.014177699573338032,
      0.01825649105012417,
      0.0799766555428505,
      -0.05167194455862045,
      -0.015447971411049366,
      0.04625064879655838,
      -0.009865005500614643,
      0.10769566148519516,
      -0.09503384679555893,
      -0.09813894331455231,
      -0.009396014735102654,
      0.08659464865922928
    ],
    [
      -0.021915286779403687,
      0.05246196314692497,
      0.044742364436388016,
      -0.0138245215639472,
      0.003496684832498431,
      0.0245552696287632,
      0.011719228699803352,
      -0.029664654284715652,
      -0.04505486041307449,
      -0.047638505697250366,
      -0.026994263753294945,
      -0.0374155156314373,
      -0.01626608893275261,
      0.08083776384592056,
      -0.1307794749736786,
      -0.006524393800646067,
      0.01259704865515232,
      0.06176857650279999,
      -0.0020947842858731747,
      -0.0013673758367076516,
      -0.0009684436954557896,
      -0.059759773313999176,
      -0.04399263858795166,
      -0.015883304178714752,
      -0.08033715933561325,
      0.05682428553700447,
      -0.015702156350016594,
      -0.05086212605237961,
      0.028506383299827576,
      -0.05947575345635414,
      -0.0011085554724559188,
      -0.0012560805771499872,
      0.005205626133829355,
      0.06099868565797806,
      0.0406888909637928,
      -0.009936140850186348,
      -0.03059699945151806,
      0.0006648003472946584,
      -0.019915534183382988,
      0.01344673614948988,
      0.025568656623363495,
      0.03215254843235016,
      0.0013062156504020095,
      -0.021102100610733032,
      0.04124012216925621,
      0.04897807538509369,
      0.012132544070482254,
      -0.023016491904854774,
      0.0535341277718544,
      -0.019979525357484818,
      0.020412513986229897,
      0.0038294349797070026,
      -0.0331466980278492,
      0.04768001288175583,
      -0.016943087801337242,
      0.02482209913432598,
      0.016531798988580704,
      0.08124368637800217,
      0.009158430621027946,
      0.004431614652276039,
      -0.013052664697170258,
      -0.01479311753064394,
      -0.07013437896966934,
      -0.030450202524662018,
      -0.02545842155814171,
      0.03409426286816597,
      -0.01489101629704237,
      -0.012827099300920963,
      -0.01980317384004593,
      0.006704303435981274,
      0.011828314512968063,
      0.0381290428340435,
      0.045796241611242294,
      0.047614965587854385,
      0.017032800242304802,
      0.023043204098939896,
      -0.061996132135391235,
      0.023488111793994904,
      0.002870903816074133,
      0.07740028947591782,
      0.0046926578506827354,
      0.005112465936690569,
      0.031873416155576706,
      -0.0381951704621315,
      -0.023929351940751076,
      0.014232020825147629,
      -0.038783758878707886,
      -0.006151834037154913,
      -0.07522395253181458,
      -0.019864611327648163,
      0.02099105529487133,
      -0.02235780842602253,
      -0.039736878126859665,
      -0.04614282771945,
      -0.013755230233073235,
      0.06867481023073196,
      -0.024426870048046112,
      -0.017243243753910065,
      -0.0016091405414044857,
      0.002275119535624981,
      -0.03760348632931709,
      -0.017666971310973167,
      0.005269533023238182,
      -0.08143692463636398,
      0.05222366005182266,
      -0.07539981603622437,
      -0.015463289804756641,
      -0.055207375437021255,
      0.028985412791371346,
      0.05507736653089523,
      -0.08177969604730606,
      -0.04882750287652016,
      0.09185923635959625,
      -0.006151904817670584,
      0.10951656848192215,
      -0.01588033325970173,
      -0.025680862367153168,
      -0.05603223666548729,
      0.06452549993991852,
      0.022164996713399887,
      -0.034784261137247086,
      -0.05869277939200401,
      0.06591153144836426,
      0.0005669614183716476,
      -0.04254038259387016,
      0.02153947576880455,
      0.06508678942918777,
      0.07367095351219177,
      0.01613861322402954,
      0.05742266774177551,
      -0.028618723154067993,
      0.08627190440893173,
      -0.0979953482747078,
      0.07453729957342148,
      -0.11262155324220657,
      0.03599727153778076,
      0.01744077168405056,
      -0.004535558633506298,
      -0.03638409078121185,
      0.024940727278590202,
      0.03451394662261009,
      -0.0065343622118234634,
      -0.09557357430458069,
      0.06795887649059296,
      0.042080432176589966,
      0.03772425651550293,
      0.05867891386151314,
      0.029792333021759987,
      -0.0061657060869038105,
      -0.03702444210648537,
      -0.04121125489473343,
      -0.06821032613515854,
      -0.00036781630478799343,
      -0.03524036705493927,
      -0.03667358681559563,
      0.06928974390029907,
      0.007530731149017811,
      -0.05550555884838104,
      0.030230708420276642,
      0.06695730239152908,
      0.03771326318383217,
      0.004829040728509426,
      -0.018879693001508713,
      0.016267720609903336,
      0.054311756044626236,
      0.04129619523882866,
      0.07362683117389679,
      0.0339491069316864,
      0.011897898279130459,
      0.01295393705368042,
      0.013059143908321857,
      -0.0268845371901989,
      0.02466535195708275,
      -0.013682812452316284,
      -0.006312686018645763,
      0.010671032592654228,
      -0.030844135209918022,
      0.0472872331738472,
      -0.04560420662164688,
      -0.049205660820007324,
      -0.0033355040941387415,
      0.005750139243900776,
      -0.05021863803267479,
      -0.0809812918305397,
      0.04146449640393257,
      -0.04797884821891785,
      -0.071780726313591,
      -0.0235404334962368,
      0.018329083919525146,
      -0.012918666005134583,
      0.0008298798347823322,
      -0.012476764619350433,
      0.1014852449297905,
      -0.05535014346241951,
      -0.008264333009719849,
      0.0690346360206604,
      0.010399564169347286,
      -0.015188904479146004,
      -0.08221513032913208,
      0.018540048971772194,
      -0.0032897284254431725,
      -0.0358441099524498,
      0.10568249970674515,
      -0.0037747465539723635,
      0.035273145884275436,
      0.09075753390789032,
      0.033225998282432556,
      -0.04836909845471382,
      0.06268114596605301,
      0.015019207261502743,
      0.02081386186182499,
      -0.04555589705705643,
      0.05230329930782318,
      -0.05753234028816223,
      -0.06272999197244644,
      0.030171221122145653,
      -0.09747043251991272,
      -0.029097668826580048,
      -0.011722655035555363,
      0.03966447710990906,
      0.009779867716133595,
      -0.06713305413722992,
      -0.0784132331609726,
      0.031095687299966812,
      -0.08302439004182816,
      -0.024591129273176193,
      0.01287776231765747,
      -0.049828171730041504,
      -0.013388863764703274,
      0.02667972259223461,
      -0.03789922222495079,
      0.006782340817153454,
      -0.05023162066936493,
      0.022589586675167084,
      0.06370498985052109,
      0.030276596546173096,
      0.03925922140479088,
      0.0080350236967206,
      0.12946996092796326,
      0.02369391731917858,
      -0.010149396024644375,
      0.049880605190992355,
      -0.05217958986759186,
      0.03604478761553764,
      0.06746137142181396,
      0.02218058332800865,
      -0.0884411409497261,
      -0.006154234521090984,
      -0.02669682726264,
      0.10404954850673676,
      -0.03384340554475784,
      -0.002172219567000866,
      0.008278095163404942,
      0.0780235081911087,
      -0.07801434397697449,
      -0.014621387235820293,
      -0.0008814238826744258,
      0.00695877056568861,
      0.04348162189126015,
      0.08115500956773758,
      0.0035421825014054775,
      0.009430866688489914,
      0.04061780124902725,
      -0.02793705090880394,
      -0.055163394659757614,
      -0.004043084103614092,
      -0.0036498357076197863,
      -0.007948572747409344,
      0.02487562596797943,
      -0.02272108569741249,
      -0.012509303167462349,
      0.10056056827306747,
      0.009445510804653168,
      0.010571442544460297,
      0.010541081428527832,
      -0.006478056777268648,
      0.04432029649615288,
      0.11866998672485352,
      -0.03589187189936638,
      -0.017565807327628136,
      -0.028779545798897743,
      0.08114628493785858,
      -0.04556560143828392,
      -0.017046300694346428,
      -0.021939879283308983,
      0.03626498579978943,
      -0.04449000954627991,
      -0.04887006804347038,
      0.03945765644311905,
      0.009642491117119789,
      0.0772833377122879,
      0.029142135754227638,
      0.008201636373996735,
      -0.0018616227898746729,
      -0.018015816807746887,
      -0.0038134711794555187,
      0.05795072019100189,
      -0.01153334230184555,
      -0.0444766990840435,
      -0.05629170313477516,
      0.05125655606389046,
      -0.10698743909597397,
      -0.023066027089953423,
      -0.011890311725437641,
      0.014565737918019295,
      0.024144193157553673,
      0.06719603389501572,
      -0.009013618342578411,
      -0.08009253442287445,
      0.057689908891916275,
      0.0023298265878111124,
      0.008297585882246494,
      -0.01898193173110485,
      -0.00036827093572355807,
      0.06844191253185272,
      0.05769757181406021,
      0.0659077912569046,
      0.03303578123450279,
      -0.02594250813126564,
      -0.063530333340168,
      -0.021839255467057228,
      -0.009688940830528736,
      0.006422738544642925,
      0.01495898887515068,
      -0.01361401192843914,
      0.06791739910840988,
      -0.022672632709145546,
      -0.016492996364831924,
      0.07065117359161377,
      0.029321569949388504,
      0.01142289862036705,
      -0.08095083385705948,
      0.01998572051525116,
      -0.02483489364385605,
      0.05364164710044861,
      -0.07474564015865326,
      -0.03689676895737648,
      -0.021921735256910324,
      -0.009702407754957676,
      -0.03580436110496521,
      -0.023847855627536774,
      -0.03601766377687454,
      -0.016014503315091133,
      0.04686889797449112,
      -0.05917124077677727,
      -0.0638217106461525,
      -0.05814987048506737,
      -0.07478100061416626,
      0.026302704587578773,
      -0.018214570358395576,
      0.0590023510158062,
      0.026805108413100243,
      0.012732969596982002,
      0.004222323186695576,
      -0.052347179502248764,
      -0.0001969670847756788,
      0.056917477399110794,
      -0.03206787630915642,
      -0.04507819935679436,
      -0.03527098521590233,
      -0.0020891716703772545,
      0.014234294183552265,
      -0.00986856035888195,
      -0.04576269909739494,
      -0.030789455398917198,
      0.0711643397808075,
      0.0207553431391716,
      0.018927833065390587,
      0.01278803963214159,
      -0.06239912286400795,
      0.046573370695114136,
      0.053241606801748276,
      -0.04039236530661583,
      0.022814171388745308,
      0.0790063738822937,
      0.040822625160217285,
      -0.01788611337542534,
      -0.030704552307724953,
      -0.062465086579322815,
      0.04694090783596039,
      0.050360582768917084,
      -0.05568460747599602,
      0.004543254151940346,
      0.051848869770765305,
      0.021033231168985367,
      -0.027721136808395386,
      0.029970884323120117,
      0.059287168085575104,
      -0.07732044905424118,
      0.0008334429003298283,
      0.09141096472740173,
      -0.04819389805197716,
      0.0433666817843914,
      -0.037695277482271194,
      -0.05051681026816368,
      0.08242366462945938,
      -0.009533765725791454,
      -0.024228308349847794,
      0.024665571749210358,
      0.004718421027064323,
      0.09999970346689224,
      0.028650829568505287,
      0.057882100343704224,
      0.11412440985441208,
      0.017206428572535515,
      0.03239893540740013,
      0.029788913205266,
      0.03357939049601555,
      -0.03795724734663963,
      -0.04241499304771423,
      -0.02173304371535778,
      -0.024627938866615295,
      -0.0869394838809967,
      -0.002053201198577881,
      -0.07852809876203537,
      -0.07350213825702667,
      0.034076713025569916,
      -0.05039520561695099,
      -0.014541656710207462,
      0.03559171408414841,
      -0.04813408479094505,
      -0.06132940948009491,
      -0.08761372417211533,
      -0.03114778734743595,
      0.009348402731120586,
      0.020188644528388977,
      0.007512459997087717,
      0.016298722475767136,
      0.09783279895782471,
      0.020040886476635933,
      -0.08511795848608017,
      0.05303284525871277,
      -0.04556424170732498,
      0.05734620988368988,
      0.008403966203331947,
      -0.03768354654312134,
      -0.036649588495492935,
      -0.018057065084576607,
      -0.03805938735604286,
      0.002710349392145872,
      0.04208647459745407,
      0.06412999331951141,
      0.0014996593818068504,
      0.014187432825565338,
      0.002341469982638955,
      -0.027424173429608345,
      -0.03018460050225258,
      -0.061031512916088104,
      0.07642865926027298,
      -0.0772116556763649,
      -0.05251136049628258,
      0.01707719825208187,
      0.02627977356314659,
      0.008842547424137592,
      -0.032504767179489136,
      0.03087465837597847,
      0.045781541615724564,
      -0.011074956506490707,
      -0.018362512812018394,
      0.044713348150253296,
      0.037154827266931534,
      0.034207411110401154,
      -0.02085641212761402,
      0.0066300141625106335,
      0.016501501202583313,
      -0.05335957556962967,
      -0.03319744020700455,
      -0.04198146611452103,
      0.018796240910887718,
      0.10944994539022446,
      0.036241479218006134,
      0.030334990471601486,
      0.008909178897738457,
      0.01963029056787491,
      0.06935804337263107,
      -0.06597893685102463,
      0.010127614252269268,
      -0.0517687052488327,
      0.006350267678499222,
      0.07369755208492279,
      0.04735078290104866,
      -0.051871512085199356,
      -0.010326729156076908,
      0.027962975203990936,
      -0.028586631640791893,
      0.0363374687731266,
      -0.08470895886421204,
      0.013256470672786236,
      0.027859263122081757,
      0.0037204138934612274,
      -0.02921656146645546,
      -0.023578697815537453,
      0.08683140575885773,
      0.028321927413344383,
      0.004296882543712854,
      -0.03785565122961998,
      -0.037896838039159775,
      -0.018550407141447067,
      -0.061522532254457474,
      -0.004632341675460339,
      -0.08610469102859497,
      -0.03848871961236,
      0.005486670881509781
    ],
    [
      -0.006780100055038929,
      -0.006393258459866047,
      0.00568801537156105,
      0.006952994037419558,
      0.01562597043812275,
      0.029657430946826935,
      -0.06375038623809814,
      0.008034265600144863,
      0.06461428850889206,
      -0.011321065947413445,
      -0.006961924023926258,
      -0.09610830247402191,
      -0.052108172327280045,
      0.04446563869714737,
      0.038761090487241745,
      0.0030193771235644817,
      -0.006843305192887783,
      0.02254091203212738,
      -0.0005110905622132123,
      0.01194161269813776,
      -0.05314546450972557,
      0.0046003456227481365,
      -0.012598956003785133,
      0.001694759470410645,
      -0.03517138585448265,
      0.01419220119714737,
      0.030713191255927086,
      0.025814510881900787,
      0.029762936756014824,
      -0.0691351443529129,
      0.026582475751638412,
      0.0050791725516319275,
      0.010354978032410145,
      0.03392261266708374,
      -0.029992152005434036,
      0.008572588674724102,
      -0.005073203705251217,
      -0.00475662387907505,
      -0.0674527958035469,
      0.037622950971126556,
      -0.004850881174206734,
      0.00970902107656002,
      -0.03316577896475792,
      -0.05031518265604973,
      -0.019593825563788414,
      -0.020299110561609268,
      -0.005850635003298521,
      -0.05160435289144516,
      0.007389049977064133,
      -0.023852961137890816,
      -0.030677499249577522,
      0.02944818139076233,
      -0.04463169723749161,
      -0.0446968600153923,
      0.034281887114048004,
      -0.025473633781075478,
      -0.017479900270700455,
      0.030735226348042488,
      -0.040989432483911514,
      0.005807820707559586,
      -0.038193218410015106,
      0.006669738795608282,
      -0.036574818193912506,
      -0.03819761797785759,
      0.016145804896950722,
      0.06519870460033417,
      0.02837320789694786,
      -0.06346462666988373,
      0.02221020869910717,
      0.017660794779658318,
      0.03352617472410202,
      -0.02591725066304207,
      0.03980008512735367,
      0.02085571363568306,
      -0.05085111781954765,
      0.04656006395816803,
      -0.038077402859926224,
      -0.013452613726258278,
      0.04492368549108505,
      0.01807328686118126,
      -0.0546233244240284,
      -0.02155570685863495,
      -0.04263969510793686,
      0.03491890802979469,
      0.061456046998500824,
      0.006305153947323561,
      -0.05393999069929123,
      0.01615861803293228,
      -0.02268386259675026,
      -0.01631009951233864,
      -0.05929382145404816,
      0.005830099806189537,
      0.08052068203687668,
      -0.033947620540857315,
      -0.029199104756116867,
      -0.06772814691066742,
      0.04609048366546631,
      0.012560714967548847,
      0.00646963668987155,
      0.023508595302700996,
      -0.05791742354631424,
      -0.047679368406534195,
      0.09830950945615768,
      -0.019522206857800484,
      -0.01688333787024021,
      0.012405071407556534,
      0.012385849840939045,
      0.03727354109287262,
      0.1296461820602417,
      0.018887197598814964,
      -0.05250566452741623,
      0.010132024064660072,
      0.004859918262809515,
      0.009419524110853672,
      0.09629876166582108,
      0.013181446120142937,
      -0.013516785576939583,
      0.03046265058219433,
      -0.03222567215561867,
      -0.02255898155272007,
      0.0023121817503124475,
      -0.01669110171496868,
      -0.04463578388094902,
      -0.048256631940603256,
      0.004541249480098486,
      0.0047734021209180355,
      -0.15784691274166107,
      -0.024962114170193672,
      0.0032601712737232447,
      -0.03809473291039467,
      -0.07515347003936768,
      -0.03944985195994377,
      0.018528582528233528,
      -0.007110550068318844,
      0.012882116250693798,
      0.07833997160196304,
      0.010830981656908989,
      0.06175628677010536,
      -0.05869051069021225,
      -0.010854353196918964,
      0.015504089184105396,
      -0.0026513717602938414,
      -0.00431347731500864,
      -0.05047924071550369,
      0.06553357094526291,
      0.04426904022693634,
      -0.022708246484398842,
      0.020803363993763924,
      0.06264016777276993,
      -0.03998452425003052,
      -0.037185292690992355,
      -7.31149484636262e-05,
      -0.014797819778323174,
      0.01305686216801405,
      0.06409528851509094,
      0.035469602793455124,
      -0.035070937126874924,
      -0.029477665200829506,
      0.0811193436384201,
      -0.011378458701074123,
      0.027836071327328682,
      -0.04770399630069733,
      -0.020466726273298264,
      0.006885227747261524,
      0.010742461308836937,
      0.08269000053405762,
      -0.05599312111735344,
      -0.04618016630411148,
      -0.047860659658908844,
      0.024124223738908768,
      0.07487115263938904,
      0.02306150458753109,
      0.023752428591251373,
      -0.07721178978681564,
      0.036619748920202255,
      0.053402647376060486,
      -0.01578834094107151,
      -0.09205484390258789,
      0.018511896952986717,
      0.017434032633900642,
      0.011759000830352306,
      -0.04126188904047012,
      -0.03858635202050209,
      0.061598245054483414,
      -0.02974117547273636,
      -0.008118218742311,
      0.018524102866649628,
      0.031591929495334625,
      -0.03564237430691719,
      0.03426327183842659,
      0.086448073387146,
      0.029546134173870087,
      0.0004389355599414557,
      -0.017043879255652428,
      -0.015802927315235138,
      0.09581506252288818,
      0.010404824279248714,
      -0.07440856099128723,
      0.08482441306114197,
      0.055302944034338,
      0.09023895859718323,
      0.003283894620835781,
      -0.0017700562020763755,
      -0.03599419817328453,
      -0.005895593203604221,
      0.018175246194005013,
      0.015190856531262398,
      0.019166989251971245,
      0.07128041982650757,
      0.03266381099820137,
      0.010786390863358974,
      0.016758469864726067,
      0.05457188934087753,
      0.001338775036856532,
      -0.020818356424570084,
      0.02052873559296131,
      -0.04822179302573204,
      -0.004276793450117111,
      0.0580986924469471,
      -0.08995602279901505,
      -0.014501006342470646,
      0.016103379428386688,
      0.008172020316123962,
      -0.04407891258597374,
      0.031173447147011757,
      -0.022719893604516983,
      -0.047858260571956635,
      -0.0005749227129854262,
      0.00508674094453454,
      0.036794546991586685,
      0.015632372349500656,
      -0.053112540394067764,
      -0.008527240715920925,
      -0.035726092755794525,
      0.05917195603251457,
      -0.08665159344673157,
      -0.03451372683048248,
      -0.0025618686340749264,
      0.022359533235430717,
      -0.043460577726364136,
      -0.010318363085389137,
      0.08979947865009308,
      0.00789357628673315,
      -0.03069375827908516,
      -0.025911225005984306,
      -0.055553462356328964,
      -0.05000787600874901,
      -0.0014482724945992231,
      -0.05045419558882713,
      -0.03170529007911682,
      -0.07033222913742065,
      0.06805292516946793,
      -0.006974733434617519,
      0.010753392241895199,
      -0.0002470926265232265,
      -0.1058882549405098,
      -0.08389590680599213,
      -0.07054385542869568,
      -0.025231726467609406,
      -0.054662853479385376,
      -0.013590751215815544,
      -0.043043892830610275,
      -0.06485238671302795,
      -0.06907831132411957,
      0.03937670215964317,
      -0.04040428251028061,
      0.008412948809564114,
      -0.01981806568801403,
      -0.09895452111959457,
      0.06769189983606339,
      0.05142242833971977,
      -0.014526044018566608,
      0.04524386674165726,
      -0.0179308895021677,
      -0.0514490008354187,
      -0.0665765106678009,
      0.031123975291848183,
      -0.007856803014874458,
      -0.021301057189702988,
      0.04228983074426651,
      0.038751568645238876,
      0.09909383207559586,
      -0.049587998539209366,
      -0.030089182779192924,
      -0.022987009957432747,
      0.006870837416499853,
      -0.03504199534654617,
      -0.05629439651966095,
      0.09471463412046432,
      0.045350540429353714,
      0.007499045226722956,
      0.027120865881443024,
      -0.056152816861867905,
      0.03338383138179779,
      -0.027493782341480255,
      -0.04530355706810951,
      -0.007735599298030138,
      -0.006035774014890194,
      0.09772276878356934,
      -0.03198011964559555,
      -0.057820916175842285,
      -0.012140248902142048,
      0.029407350346446037,
      -0.07796066999435425,
      0.007875526323914528,
      -0.048561833798885345,
      0.01632341742515564,
      0.01720677688717842,
      0.02318115159869194,
      -0.03285554051399231,
      0.02320987358689308,
      0.03669363632798195,
      -0.07326730340719223,
      -0.05398031324148178,
      0.06545409560203552,
      -0.011781775392591953,
      -0.015510267578065395,
      0.041271332651376724,
      -0.0018895246321335435,
      -0.04423772171139717,
      0.08704182505607605,
      -0.02256023697555065,
      0.03754573315382004,
      -0.025536172091960907,
      0.018289577215909958,
      0.04208612069487572,
      -0.008795910514891148,
      -0.09355030208826065,
      0.0018720859661698341,
      -0.0070678675547242165,
      0.03937625139951706,
      0.056875720620155334,
      -0.019625086337327957,
      -0.04894933104515076,
      -0.015059837140142918,
      0.041853588074445724,
      -0.042445238679647446,
      0.06254401057958603,
      -0.028720324859023094,
      0.05896899476647377,
      0.002311530290171504,
      -0.02290261723101139,
      -0.04654143750667572,
      0.02082080952823162,
      -0.0050635733641684055,
      0.03246105834841728,
      -0.016632013022899628,
      -0.06908702850341797,
      -0.056906796991825104,
      -0.04746000096201897,
      0.039098870009183884,
      0.05526066944003105,
      0.016285879537463188,
      0.005386859644204378,
      -0.007519882172346115,
      -0.04977688193321228,
      -0.02861633151769638,
      0.052885498851537704,
      0.08096163719892502,
      0.10427491366863251,
      0.0020010804291814566,
      0.026895267888903618,
      -0.0660816952586174,
      -0.00010768373613245785,
      0.03551092371344566,
      0.009983763098716736,
      0.020241474732756615,
      0.013345026411116123,
      -0.028976280242204666,
      0.07563088834285736,
      -0.055606625974178314,
      0.048931073397397995,
      0.058827634900808334,
      -0.12102312594652176,
      0.02480541169643402,
      -0.08510120958089828,
      0.09748569130897522,
      -0.05097733065485954,
      0.0923033133149147,
      -0.0398058257997036,
      -0.052069202065467834,
      0.053741052746772766,
      0.0552176795899868,
      -0.0381939597427845,
      0.013080854900181293,
      -0.026420360431075096,
      -0.002918876940384507,
      0.057140056043863297,
      0.06709877401590347,
      -0.01162894070148468,
      0.029650289565324783,
      0.014401747845113277,
      -0.04329784959554672,
      0.0070309508591890335,
      0.012681195512413979,
      -0.0521070770919323,
      -0.031957365572452545,
      -0.020596468821167946,
      0.00868182722479105,
      -0.004004059359431267,
      0.09287543594837189,
      0.07962184399366379,
      0.02612290531396866,
      -0.065010666847229,
      0.0325317345559597,
      -0.009416330605745316,
      -0.07112174481153488,
      0.058992210775613785,
      0.002229311503469944,
      0.10997332632541656,
      -0.017799653112888336,
      -0.09007691591978073,
      0.05066191777586937,
      -0.0026527869049459696,
      0.04219246283173561,
      0.08312948793172836,
      -0.06400823593139648,
      -0.0203372985124588,
      0.12021613866090775,
      -0.008919870480895042,
      -0.016194697469472885,
      -0.009593302384018898,
      0.020610608160495758,
      0.030885236337780952,
      0.01813906989991665,
      -0.016422603279352188,
      -0.0693400651216507,
      0.06229329854249954,
      0.01241334155201912,
      0.03249673917889595,
      0.13955605030059814,
      0.005470313131809235,
      0.021950844675302505,
      0.017776090651750565,
      -0.02399097941815853,
      -0.032133374363183975,
      0.0503460057079792,
      -0.001287754625082016,
      0.04934244230389595,
      0.00045515093370340765,
      0.04022906348109245,
      0.04136715084314346,
      -0.020020771771669388,
      0.013070367276668549,
      0.01214057020843029,
      -0.01028013788163662,
      -0.0201285257935524,
      -0.09235476702451706,
      -0.023454032838344574,
      -0.05743691697716713,
      -0.07164933532476425,
      -0.043460771441459656,
      -0.06923853605985641,
      -0.05609298497438431,
      -0.007398785091936588,
      0.04340573027729988,
      -0.0032855127938091755,
      -0.037822045385837555,
      -0.002467005280777812,
      -0.010918590240180492,
      0.0006642459775321186,
      0.00575870368629694,
      0.0038472849410027266,
      0.03820817545056343,
      -0.011427191086113453,
      -0.055287059396505356,
      0.0748564824461937,
      -0.009242381900548935,
      0.0018589220708236098,
      0.02021663635969162,
      -0.07365325093269348,
      0.010975130833685398,
      -0.10562650859355927,
      0.036787886172533035,
      0.04584609717130661,
      -0.017992015928030014,
      0.02240856923162937,
      0.041287072002887726,
      0.001307639991864562,
      -0.0549982450902462,
      -0.011614242568612099,
      -0.02838309109210968,
      0.03403652459383011,
      0.07452622056007385,
      0.026057207956910133,
      -0.038098257035017014,
      0.015847718343138695,
      -0.06137000769376755,
      0.028079429641366005,
      0.033930666744709015,
      -0.009681275114417076,
      0.0046912673860788345,
      0.030784321948885918,
      0.07011690735816956,
      0.05084767937660217,
      -0.007194844074547291,
      0.028151988983154297,
      0.011651666834950447,
      0.01850544847548008,
      -0.05388409644365311,
      -0.019717007875442505,
      0.045766431838274,
      0.09368366748094559
    ],
    [
      0.01763434335589409,
      0.04067995026707649,
      -0.07220923900604248,
      -0.01342847477644682,
      0.01590857096016407,
      0.04448392987251282,
      0.013847936876118183,
      -0.01597871631383896,
      0.03138944134116173,
      -0.018464764580130577,
      -0.018870199099183083,
      -0.01933029666543007,
      0.05512537807226181,
      -0.06754100322723389,
      -0.046221934258937836,
      -0.013234555721282959,
      -0.09816612303256989,
      -0.018796421587467194,
      -0.012173342518508434,
      0.03132971376180649,
      0.0052974591962993145,
      -0.040356654673814774,
      0.047007326036691666,
      -0.039036959409713745,
      -0.031498320400714874,
      -0.00619738083332777,
      0.01907549984753132,
      -0.062248025089502335,
      0.0041003040969371796,
      0.04258798062801361,
      -0.09956669062376022,
      -0.025372756645083427,
      0.07466627657413483,
      0.07915320992469788,
      -0.04213162884116173,
      0.027667660266160965,
      0.038330093026161194,
      0.0008246144861914217,
      -0.06505855917930603,
      -0.02678716368973255,
      -0.0033995036501437426,
      0.04051826521754265,
      0.009597687982022762,
      -0.00031309586483985186,
      -0.052156079560518265,
      0.032016463577747345,
      0.02868511527776718,
      -0.05725284665822983,
      0.016546351835131645,
      -0.07754574716091156,
      0.07081328332424164,
      -0.032968875020742416,
      0.024567540735006332,
      -0.13841679692268372,
      -0.021841922774910927,
      -0.02485675737261772,
      0.04189380258321762,
      -0.01887894980609417,
      -0.029237497597932816,
      -0.052789948880672455,
      -0.029492296278476715,
      0.029931552708148956,
      0.014032373204827309,
      -0.05000140145421028,
      0.055454134941101074,
      -0.01937098056077957,
      0.015480590984225273,
      -0.031765662133693695,
      0.04157211259007454,
      0.027424024417996407,
      -0.034515220671892166,
      0.0013300206046551466,
      0.04248721897602081,
      -0.02239525318145752,
      -0.031364887952804565,
      -0.049559976905584335,
      0.008357871323823929,
      -0.00311312940903008,
      -0.0859558954834938,
      -0.041532695293426514,
      0.04126439616084099,
      0.07227228581905365,
      -0.038687966763973236,
      0.009485031478106976,
      -0.025678187608718872,
      0.06959136575460434,
      -0.0023790353443473577,
      -0.03750518709421158,
      -0.0686492770910263,
      -0.011751134879887104,
      0.003118214663118124,
      -0.06467452645301819,
      -0.0452909916639328,
      -0.01052330993115902,
      -0.010496018454432487,
      -0.07682468742132187,
      0.03922371566295624,
      0.024733727797865868,
      -0.021188443526625633,
      -0.00651921983808279,
      -0.07173475623130798,
      -0.031697653234004974,
      -0.0056434208527207375,
      0.11328781396150589,
      0.0130744194611907,
      -0.030197281390428543,
      -0.03412198647856712,
      -0.046405140310525894,
      -0.05729924514889717,
      0.015507089905440807,
      0.020202796906232834,
      -0.060562100261449814,
      -0.021466244012117386,
      0.07096221297979355,
      0.042717061936855316,
      -0.012027313001453876,
      -0.01980690099298954,
      -0.026890460401773453,
      -0.027140451595187187,
      0.02159571461379528,
      -0.061820439994335175,
      0.014459730125963688,
      -0.022735169157385826,
      -0.03274441510438919,
      0.06179952248930931,
      0.010654850862920284,
      0.03999282792210579,
      -0.005493493750691414,
      -0.034831296652555466,
      0.10661854594945908,
      0.0070532942190766335,
      0.09941824525594711,
      -0.0379784069955349,
      -0.12431307882070541,
      0.060081224888563156,
      -0.0022428350057452917,
      0.011650017462670803,
      0.005113838706165552,
      0.05175664648413658,
      0.03457115590572357,
      -0.09132992476224899,
      -0.02980370633304119,
      -0.0022694244980812073,
      -0.006545096170157194,
      0.010669155046343803,
      -0.04222780466079712,
      0.050147444009780884,
      0.011119228787720203,
      -0.052853044122457504,
      0.016331005841493607,
      0.023871954530477524,
      -0.042401742190122604,
      0.00877755880355835,
      0.008459714241325855,
      0.017858542501926422,
      0.025167634710669518,
      -0.014606536366045475,
      -0.007057608105242252,
      -0.015387868508696556,
      0.0483306385576725,
      -0.01913793385028839,
      0.01237216591835022,
      -0.04215378686785698,
      -0.08031008392572403,
      0.022999614477157593,
      -0.03951252996921539,
      -0.024702711030840874,
      -0.01795329339802265,
      0.028099961578845978,
      0.04346688464283943,
      0.015207038260996342,
      -0.024194352328777313,
      -0.008852040395140648,
      -0.008480058051645756,
      0.004329669289290905,
      -0.012739392928779125,
      -0.04763517901301384,
      -0.05385155975818634,
      0.10291432589292526,
      0.037465065717697144,
      0.0002533552178647369,
      -0.035641301423311234,
      0.04528380185365677,
      -0.019251199439167976,
      0.0833313837647438,
      0.03372417017817497,
      0.011123454198241234,
      -0.020477233454585075,
      0.05151412636041641,
      -0.0018642296781763434,
      -0.08830916881561279,
      -0.02301681973040104,
      -0.025908393785357475,
      -0.045900244265794754,
      0.03335234895348549,
      -0.00936481449753046,
      0.0325794592499733,
      0.00653801579028368,
      -0.017052654176950455,
      0.04957028850913048,
      0.030699273571372032,
      -0.01934167556464672,
      -0.0564049556851387,
      -0.06034030020236969,
      0.06256961822509766,
      -0.0034342966973781586,
      0.009160665795207024,
      0.037865959107875824,
      -0.004819892346858978,
      -0.06962364166975021,
      0.0248735174536705,
      -0.027378983795642853,
      -0.025457365438342094,
      0.028709162026643753,
      0.051180921494960785,
      0.036002062261104584,
      0.0743194967508316,
      -0.10970591008663177,
      -0.05281440541148186,
      -0.024370646104216576,
      -0.024030115455389023,
      0.05667194724082947,
      0.019067032262682915,
      -0.01772928051650524,
      -0.0395178347826004,
      -0.010925160720944405,
      -0.0007815567078068852,
      0.036182451993227005,
      0.012373787350952625,
      -0.002431133994832635,
      -0.007259236183017492,
      0.04930225759744644,
      0.1254187375307083,
      0.026326380670070648,
      -0.0033309138379991055,
      0.011096187867224216,
      -0.04535637050867081,
      -0.03113853931427002,
      0.006934680975973606,
      0.02398812584578991,
      -0.08238484710454941,
      0.061969127506017685,
      -0.023410627618432045,
      -0.00711405836045742,
      -0.018406160175800323,
      -0.05843420699238777,
      -0.0218206699937582,
      0.07101709395647049,
      0.05254405736923218,
      -0.03927042335271835,
      -0.04015921801328659,
      0.005845941137522459,
      0.03398877754807472,
      -0.02319154515862465,
      -0.07783812284469604,
      0.023700561374425888,
      -0.04940081760287285,
      0.009276149794459343,
      0.027158157899975777,
      0.042473938316106796,
      0.011277373880147934,
      0.05028606578707695,
      0.033767085522413254,
      -0.020775102078914642,
      0.01690879464149475,
      -0.007685563527047634,
      0.03745304420590401,
      0.017702994868159294,
      -0.048381123691797256,
      -0.04529162868857384,
      -0.041364625096321106,
      0.034848231822252274,
      -0.004395973868668079,
      0.01784917525947094,
      0.02699044533073902,
      -0.008868060074746609,
      0.02098230831325054,
      0.02151997573673725,
      0.03348451107740402,
      -0.09436365216970444,
      -0.02276669070124626,
      0.011110330000519753,
      0.027387015521526337,
      -0.04936075210571289,
      -0.02135597914457321,
      0.031513020396232605,
      -0.08008933067321777,
      -0.005437508691102266,
      -0.013842460699379444,
      -0.005351130850613117,
      0.011252439580857754,
      0.0362195186316967,
      0.013767129741609097,
      -0.03675259277224541,
      0.03043636679649353,
      0.021332034841179848,
      -0.01888948865234852,
      0.0015816621016710997,
      -0.03878367319703102,
      0.04697413370013237,
      0.053552910685539246,
      -0.047830674797296524,
      0.027650995180010796,
      0.07763081789016724,
      0.01951645128428936,
      0.0679236352443695,
      0.12471739947795868,
      -0.00422818586230278,
      -0.04178091511130333,
      0.03186766058206558,
      -0.028067151084542274,
      -0.03581713140010834,
      -0.0027327134739607573,
      0.0732482373714447,
      0.052569564431905746,
      -0.04872436821460724,
      0.02098505012691021,
      -0.0581468902528286,
      0.052227284759283066,
      -0.03177720308303833,
      0.058687955141067505,
      -0.035036224871873856,
      -0.009766779839992523,
      -0.05245628580451012,
      0.08958058059215546,
      0.061145126819610596,
      0.03111826814711094,
      -0.05409186705946922,
      -0.007723462767899036,
      0.0179603174328804,
      -0.005741412751376629,
      0.04467632621526718,
      -0.024585260078310966,
      0.04694724082946777,
      0.019916081801056862,
      0.03230143338441849,
      -0.012609339319169521,
      0.08234803378582001,
      0.022976059466600418,
      -0.04505520313978195,
      -0.0026932444889098406,
      -0.007451933342963457,
      0.008292610757052898,
      -1.3781072993879206e-05,
      -0.0005531586357392371,
      -0.04663774371147156,
      0.01339926291257143,
      0.004797041416168213,
      0.04353830963373184,
      -0.019743209704756737,
      -0.0568946972489357,
      -0.04053862765431404,
      0.032742127776145935,
      0.053949397057294846,
      0.03002939373254776,
      -0.07170502096414566,
      -0.01888464018702507,
      0.07245904952287674,
      -0.03227248787879944,
      -0.03771869093179703,
      0.002211876679211855,
      -0.021274970844388008,
      0.010043922811746597,
      0.025467894971370697,
      0.055842865258455276,
      -0.0008908486925065517,
      -0.06190022453665733,
      -0.00647400040179491,
      -0.013813498429954052,
      -0.024064386263489723,
      -0.04656863957643509,
      -0.02799060568213463,
      -0.12619318068027496,
      0.052194878458976746,
      0.03144454210996628,
      -0.0286099836230278,
      0.08688323944807053,
      -0.004345036577433348,
      -0.011717496439814568,
      0.1051374077796936,
      -0.001400644308887422,
      0.04743241146206856,
      0.08486967533826828,
      -0.03669710457324982,
      0.02346140518784523,
      0.046429507434368134,
      0.05086850747466087,
      0.03210040181875229,
      -0.004696753807365894,
      -0.012095581740140915,
      0.04044562205672264,
      -0.005327160004526377,
      -0.017941158264875412,
      0.048284146934747696,
      -0.05847805738449097,
      0.040594492107629776,
      -0.012006868608295918,
      0.030629131942987442,
      0.0713493749499321,
      -0.016930142417550087,
      0.015312712639570236,
      0.023440634831786156,
      -0.01623517833650112,
      0.008666444569826126,
      0.014014627784490585,
      0.04314226657152176,
      0.0031267558224499226,
      0.062008924782276154,
      -0.006014531012624502,
      0.0776723176240921,
      0.014789178967475891,
      -0.060467761009931564,
      0.038197942078113556,
      0.0033796802163124084,
      -0.020001348108053207,
      -0.01483386754989624,
      0.017075136303901672,
      -0.0705498605966568,
      0.004104886204004288,
      -0.01314428448677063,
      0.04231187701225281,
      -0.002248776378110051,
      -0.003439321182668209,
      0.045543525367975235,
      -0.11834829300642014,
      -0.024562682956457138,
      -0.08564747124910355,
      0.013686028309166431,
      -0.07090173661708832,
      0.011125908233225346,
      0.014222080819308758,
      -0.018705733120441437,
      -0.04683836176991463,
      0.008348268456757069,
      -0.01484706625342369,
      -0.0804675817489624,
      0.06171225756406784,
      -0.002591326367110014,
      0.018306102603673935,
      0.060513172298669815,
      0.12753373384475708,
      -0.03943623974919319,
      0.01727069355547428,
      0.062207914888858795,
      -0.02982414700090885,
      0.012232128530740738,
      0.030344489961862564,
      0.06890086084604263,
      0.03558087721467018,
      0.04028479382395744,
      -0.02421995811164379,
      -0.026961779221892357,
      -0.013634649105370045,
      0.04272967576980591,
      -0.01826510764658451,
      0.0702136904001236,
      -0.055686578154563904,
      0.041084129363298416,
      -0.019697805866599083,
      -0.04837304726243019,
      0.04507848620414734,
      0.10153425484895706,
      0.023732051253318787,
      0.07533782720565796,
      3.878017741953954e-05,
      0.0030712124425917864,
      0.018174724653363228,
      -0.06873039156198502,
      -0.09108154475688934,
      0.052182432264089584,
      0.04045165702700615,
      0.0771070197224617,
      -0.09540006518363953,
      -0.024237172678112984,
      0.020788075402379036,
      -0.02816329337656498,
      0.037821609526872635,
      0.017271384596824646,
      -0.005424102768301964,
      0.04689039662480354,
      0.020867910236120224,
      -0.029836105182766914,
      -0.008760805241763592,
      -0.017916884273290634,
      -0.017637519165873528,
      0.05345180630683899,
      0.056356281042099,
      -0.10064015537500381,
      -0.044787079095840454,
      0.053867191076278687,
      -0.010346166789531708,
      -0.05269722267985344,
      -0.047086481004953384,
      -0.016013039276003838,
      -0.019258078187704086,
      -0.01914973370730877,
      0.018072867766022682,
      0.05914238095283508,
      -0.027745764702558517,
      -0.04360921308398247,
      0.01888084225356579,
      0.05978541821241379,
      0.034544188529253006
    ],
    [
      0.0007955760229378939,
      -0.058271147310733795,
      -0.042961250990629196,
      0.07243288308382034,
      -0.028900325298309326,
      0.02971040830016136,
      0.0656067505478859,
      -0.12637312710285187,
      0.002955182921141386,
      0.01145783718675375,
      0.02090883068740368,
      0.07406239211559296,
      -0.006707781460136175,
      0.01695890910923481,
      -0.07384312897920609,
      0.017133688554167747,
      0.03737952560186386,
      0.034751713275909424,
      -0.00794697180390358,
      0.026747582480311394,
      0.05791662260890007,
      -0.05060478672385216,
      -0.014531789347529411,
      0.04079664498567581,
      0.06540016829967499,
      0.048726946115493774,
      -0.05765445530414581,
      -0.06383021175861359,
      -0.0237130094319582,
      -0.010120080783963203,
      -0.051137447357177734,
      0.07103770971298218,
      0.07612931728363037,
      0.04510730132460594,
      -0.057816166430711746,
      0.002100510522723198,
      0.024920763447880745,
      -0.025095727294683456,
      -0.038377415388822556,
      -0.061479806900024414,
      0.05777652561664581,
      -0.05528727173805237,
      0.01060185395181179,
      0.044835563749074936,
      0.012876326218247414,
      0.025056736543774605,
      -0.03990889713168144,
      -0.11613427102565765,
      -0.03195326030254364,
      0.018667610362172127,
      -0.0399496927857399,
      0.005276153329759836,
      -0.0564274862408638,
      -0.050071462988853455,
      0.009100804105401039,
      0.025029674172401428,
      -0.04212198778986931,
      -0.021289348602294922,
      -0.02272653952240944,
      -0.06300676614046097,
      0.045322123914957047,
      0.007735377177596092,
      -0.02553660236299038,
      -0.005738657433539629,
      0.013494416140019894,
      -0.07327765971422195,
      0.02860279008746147,
      -0.014531336724758148,
      -0.003626276273280382,
      -0.001398668740876019,
      0.040978286415338516,
      -0.008648999966681004,
      0.017554644495248795,
      -0.011037708260118961,
      0.06554855406284332,
      0.06218429282307625,
      0.017080139368772507,
      -0.040695883333683014,
      0.039678774774074554,
      -0.01674564927816391,
      0.05859140306711197,
      -0.010324216447770596,
      -0.0411960706114769,
      -0.007466249633580446,
      0.07382290810346603,
      0.006361781619489193,
      -0.03660304471850395,
      0.09847571700811386,
      -0.052548572421073914,
      -0.02862350270152092,
      -0.06286364793777466,
      0.03243928402662277,
      0.003003226825967431,
      0.0032315838616341352,
      0.002715486567467451,
      0.04184630140662193,
      -2.2725866983819287e-06,
      -0.026385225355625153,
      -0.061777908354997635,
      -0.007164633367210627,
      -0.08175738155841827,
      0.02942490391433239,
      0.07025930285453796,
      0.02326063998043537,
      0.011231967248022556,
      -0.06286945939064026,
      -0.0006316840881481767,
      -0.0009689840371720493,
      0.02192123979330063,
      -0.08296365290880203,
      0.03814209625124931,
      0.02958798222243786,
      0.02282359078526497,
      -0.03001365251839161,
      -0.012190326116979122,
      -0.03152713179588318,
      0.07351741939783096,
      0.04676944389939308,
      0.039560023695230484,
      0.11855533719062805,
      -0.005187872797250748,
      -0.056492142379283905,
      0.032815806567668915,
      -0.0009619946940802038,
      0.027376944199204445,
      -0.06685221940279007,
      0.0456690639257431,
      0.044632863253355026,
      -0.013075689785182476,
      0.007459528744220734,
      -0.008317772299051285,
      0.07592124491930008,
      0.024654200300574303,
      -0.0051756929606199265,
      0.018986715003848076,
      0.01748139038681984,
      0.01188381016254425,
      -0.003604800906032324,
      -0.03340662270784378,
      -0.009172212332487106,
      -0.01306570041924715,
      0.026454923674464226,
      0.018953876569867134,
      -0.09522324800491333,
      -0.03933163732290268,
      -0.03680257126688957,
      -0.007890227250754833,
      -0.061508167535066605,
      -0.01652461104094982,
      0.07579275220632553,
      -0.03341270610690117,
      0.020009681582450867,
      0.06024657562375069,
      0.07086855918169022,
      0.06882195174694061,
      -0.009558877907693386,
      -0.02166006527841091,
      0.02718779444694519,
      0.011171293444931507,
      0.016597144305706024,
      0.05764355883002281,
      -0.04223893582820892,
      0.009416192770004272,
      0.005218576639890671,
      0.009999956004321575,
      0.004081393126398325,
      0.023541932925581932,
      0.09900659322738647,
      -0.03963414207100868,
      0.025276165455579758,
      -0.012937341816723347,
      0.0415995754301548,
      -0.009773502126336098,
      0.03279636427760124,
      -0.0032703387551009655,
      0.03080473095178604,
      -0.004059295170009136,
      -0.0066712708212435246,
      -0.03463387116789818,
      0.024434693157672882,
      0.04827113822102547,
      -0.12197957932949066,
      0.054466187953948975,
      -0.10323947668075562,
      -0.020054837688803673,
      0.06968189030885696,
      -0.009728309698402882,
      0.018626032397150993,
      -0.09102395176887512,
      -0.026978831738233566,
      0.06561335921287537,
      0.06669256836175919,
      0.01088721863925457,
      0.02529148757457733,
      -0.02511003613471985,
      0.055283136665821075,
      0.01754102297127247,
      -0.03974980488419533,
      -0.03874694183468819,
      0.0189738180488348,
      0.027178214862942696,
      -0.07618660479784012,
      -0.01841355860233307,
      -0.07299216091632843,
      0.05227070674300194,
      -0.04739266261458397,
      0.050985921174287796,
      -0.058072544634342194,
      -0.005845444742590189,
      -0.02267397567629814,
      0.04002409428358078,
      0.0025474913418293,
      -0.02293500304222107,
      0.02427206002175808,
      0.019627662375569344,
      -0.05408407747745514,
      0.029274068772792816,
      0.034472059458494186,
      0.16652324795722961,
      0.00509291747584939,
      0.039139289408922195,
      -0.06689616292715073,
      0.022812407463788986,
      -0.01626448519527912,
      -0.0323374941945076,
      -0.04070783779025078,
      0.032359104603528976,
      0.055226944386959076,
      -0.030816657468676567,
      -0.08343030512332916,
      0.03845115005970001,
      0.017437027767300606,
      -0.007080822251737118,
      -0.07735104858875275,
      -0.012538131326436996,
      0.055574916303157806,
      -0.021761002019047737,
      -0.07160559296607971,
      0.031073134392499924,
      0.04178273677825928,
      -0.0597260519862175,
      -0.0031347270123660564,
      0.017455119639635086,
      0.05535255745053291,
      -0.012984653003513813,
      0.1311899870634079,
      -0.02666940726339817,
      -0.012492713518440723,
      0.02843703143298626,
      0.09700722992420197,
      -0.04791770502924919,
      0.006603722460567951,
      0.08917614072561264,
      0.0784037858247757,
      0.0005634870612993836,
      -0.01624911278486252,
      0.002310319570824504,
      -0.019198931753635406,
      -0.05583799630403519,
      -0.0651673972606659,
      0.023519860580563545,
      -0.031248053535819054,
      -0.01517424639314413,
      -0.003135072998702526,
      0.04095345363020897,
      0.03660864010453224,
      -0.02344423532485962,
      0.009339503943920135,
      0.03672153875231743,
      -0.0196797214448452,
      0.03430400788784027,
      0.045736972242593765,
      -0.09119224548339844,
      -0.04449613019824028,
      0.02965167909860611,
      -0.019221151247620583,
      -0.08310652524232864,
      0.03594851866364479,
      -0.05870741233229637,
      -0.04468056187033653,
      0.0030527859926223755,
      -0.022032689303159714,
      -0.034499641507864,
      -0.0118283461779356,
      -0.027059877291321754,
      0.05159299448132515,
      -0.039589397609233856,
      -0.09352809190750122,
      0.00956491194665432,
      -0.0017043978441506624,
      0.029039986431598663,
      -0.0008517571841366589,
      -0.046924322843551636,
      0.00801182258874178,
      0.025459622964262962,
      -0.07320533692836761,
      -0.07008131593465805,
      -0.026710467413067818,
      0.08193422108888626,
      -0.02773020975291729,
      -0.011066824197769165,
      -0.021726537495851517,
      -0.010358422994613647,
      0.012820745818316936,
      -0.06664415448904037,
      -0.032126251608133316,
      -0.02690035291016102,
      -0.055371273308992386,
      -0.01255644578486681,
      -0.002727063838392496,
      -0.01769845373928547,
      0.008577837608754635,
      -0.0670240968465805,
      -0.004232299979776144,
      -0.03312816470861435,
      0.08061446994543076,
      0.04783910885453224,
      0.027583101764321327,
      -0.03836037963628769,
      0.0026970948092639446,
      -0.035532329231500626,
      0.07153347134590149,
      -0.010299084708094597,
      -0.007682724390178919,
      0.024381428956985474,
      0.02111120894551277,
      -0.03450443223118782,
      0.04746157303452492,
      0.01516025047749281,
      0.007637386210262775,
      -0.03172265738248825,
      -0.056367333978414536,
      0.018442166969180107,
      -0.008701297454535961,
      0.06293057650327682,
      -0.027045290917158127,
      -0.06788074225187302,
      0.007103866431862116,
      -0.026139501482248306,
      0.03726629540324211,
      0.03311949223279953,
      -0.0446302592754364,
      -0.004742441698908806,
      -0.022535577416419983,
      -0.03381837531924248,
      0.04535733163356781,
      -0.009973236359655857,
      0.012819298543035984,
      0.03514644503593445,
      0.028172452002763748,
      -0.018873443827033043,
      -0.056837376207113266,
      -0.017707400023937225,
      -0.094725601375103,
      -0.04274814575910568,
      -0.006183785852044821,
      0.07112490385770798,
      -0.03573710843920708,
      0.046044837683439255,
      0.022895602509379387,
      -0.10174728184938431,
      0.03487016633152962,
      -0.05047107860445976,
      0.013864419423043728,
      -0.00694600073620677,
      0.018696874380111694,
      0.024724820628762245,
      0.0037050279788672924,
      0.007829149253666401,
      -0.06303433328866959,
      0.04203155264258385,
      -0.07323472201824188,
      -0.03063802607357502,
      -0.013722137548029423,
      -0.05885667726397514,
      -0.01702597178518772,
      0.07641935348510742,
      -0.04880053177475929,
      -0.04433899000287056,
      -0.14813870191574097,
      -0.06872686743736267,
      0.07369796186685562,
      -0.03945467993617058,
      -0.02680053561925888,
      -0.009621791541576385,
      -0.013337734155356884,
      -0.01708902418613434,
      0.11275198310613632,
      0.014253917150199413,
      -0.01685534417629242,
      -0.07746487855911255,
      0.021187208592891693,
      0.05225630849599838,
      0.036032237112522125,
      0.04266252741217613,
      -0.01325688324868679,
      -0.02158161997795105,
      -0.022736400365829468,
      0.016705501824617386,
      -0.035241853445768356,
      -0.010359218344092369,
      0.05650537088513374,
      0.011545458808541298,
      0.03907889872789383,
      0.026035219430923462,
      -0.020420756191015244,
      0.07715726643800735,
      0.042046621441841125,
      -0.029531056061387062,
      -0.06180090829730034,
      -0.007405939046293497,
      -0.0169991385191679,
      0.039514120668172836,
      0.08104398846626282,
      0.04981742054224014,
      -0.08334872871637344,
      -0.011059271171689034,
      -0.02773534692823887,
      0.045465435832738876,
      0.037366412580013275,
      0.10106566548347473,
      -0.054923441261053085,
      0.05585499107837677,
      0.020345231518149376,
      0.03581593185663223,
      0.03967003524303436,
      0.01893712766468525,
      0.05032869800925255,
      -0.07258014380931854,
      -0.010235103778541088,
      -0.022643882781267166,
      -0.021703675389289856,
      0.011859550140798092,
      -0.10864458978176117,
      -0.1155720204114914,
      -0.026288947090506554,
      0.015581452287733555,
      -0.07151053845882416,
      0.07203112542629242,
      -0.06894572079181671,
      0.03665124624967575,
      0.002022398170083761,
      0.0887179747223854,
      -0.04797229543328285,
      -0.014523575082421303,
      -0.020065734162926674,
      0.0029430061113089323,
      0.031068559736013412,
      0.09377732872962952,
      -0.05668789520859718,
      0.08625749498605728,
      0.022673849016427994,
      -0.018165089190006256,
      -0.008268115110695362,
      0.03318347409367561,
      0.030393550172448158,
      0.009620163589715958,
      0.03462968394160271,
      0.0015575294382870197,
      0.007266753353178501,
      -0.0045392317697405815,
      0.006557499058544636,
      -0.021463535726070404,
      -0.08578880876302719,
      0.03475292772054672,
      0.011507293209433556,
      0.15019641816616058,
      -0.011716261506080627,
      0.017836185172200203,
      0.0390414297580719,
      0.061331044882535934,
      -0.05303332954645157,
      -0.011091620661318302,
      -0.05444725602865219,
      0.034674663096666336,
      0.051719699054956436,
      0.030035074800252914,
      -0.056846220046281815,
      0.04434342309832573,
      0.01600852608680725,
      0.022562801837921143,
      -0.07557463645935059,
      0.02216518484055996,
      0.0010956256883218884,
      -0.00989946536719799,
      0.0073335361666977406,
      -0.005532002542167902,
      -0.028246983885765076,
      0.12033438682556152,
      0.018794793635606766,
      -0.049135565757751465,
      -0.07513459771871567,
      0.032210562378168106,
      0.046647414565086365,
      -0.031102819368243217,
      0.061607860028743744,
      0.024489665403962135,
      -0.02979476936161518,
      0.004812754224985838,
      0.005944206845015287,
      -0.00479779951274395,
      -0.010916424915194511,
      -0.010552752763032913
    ],
    [
      -0.021392730996012688,
      0.03041999042034149,
      -0.021161893382668495,
      -0.006426629144698381,
      0.09683800488710403,
      -0.04812886565923691,
      0.03048902377486229,
      0.014625811018049717,
      -0.01068093627691269,
      -0.025265859439969063,
      -0.004260497633367777,
      0.0067708129063248634,
      0.023395124822854996,
      0.09422601014375687,
      0.014311794191598892,
      -0.00824317242950201,
      0.01549502369016409,
      0.006166471634060144,
      0.05479761213064194,
      0.04476172849535942,
      -0.04438258334994316,
      0.06663071364164352,
      -0.01324950810521841,
      0.008089791052043438,
      -0.060125257819890976,
      0.06263576447963715,
      -0.0407172366976738,
      0.018236536532640457,
      0.00787791796028614,
      0.032087188214063644,
      -0.035875655710697174,
      0.08984526991844177,
      0.03247220069169998,
      -0.06794799119234085,
      -0.052713003009557724,
      0.030302613973617554,
      0.06578198820352554,
      0.025417577475309372,
      0.035365961492061615,
      -0.03294556215405464,
      -0.02691730298101902,
      -0.06509127467870712,
      -0.05217869579792023,
      0.044768091291189194,
      -0.04048684239387512,
      0.022458547726273537,
      -0.042970217764377594,
      -0.06745591014623642,
      0.042245376855134964,
      -0.05895514786243439,
      -0.04402133822441101,
      0.01496682595461607,
      0.037284042686223984,
      0.01138437818735838,
      -0.010436142794787884,
      -0.0935620367527008,
      -0.07763992995023727,
      0.014003999531269073,
      0.03258981928229332,
      0.039781805127859116,
      -0.05718687176704407,
      0.019945649430155754,
      0.037090081721544266,
      -0.057260043919086456,
      0.00637110834941268,
      -0.013435972854495049,
      0.03094552271068096,
      0.017775479704141617,
      0.02255195379257202,
      0.01544250175356865,
      -0.02313895709812641,
      -0.01942344941198826,
      0.009780013002455235,
      -0.0673375129699707,
      -0.0356784351170063,
      0.01965797133743763,
      -0.050753120332956314,
      0.03422919660806656,
      -0.006257332395762205,
      -0.018216094002127647,
      0.005694762337952852,
      0.011277223937213421,
      0.02815019153058529,
      0.03461069613695145,
      -0.044202130287885666,
      0.03259088099002838,
      -0.010408652015030384,
      0.026038287207484245,
      0.018206387758255005,
      0.0025439695455133915,
      0.031514815986156464,
      0.07714425772428513,
      -0.07832449674606323,
      -0.016133783385157585,
      -0.00390049209818244,
      0.0214696042239666,
      0.015836119651794434,
      -0.10401809215545654,
      0.06627556681632996,
      -0.01032928191125393,
      0.03750177100300789,
      -0.025388209149241447,
      -0.04322678595781326,
      0.004343295935541391,
      0.01817445084452629,
      0.03235302120447159,
      -0.010028211399912834,
      0.01857512816786766,
      0.05593124404549599,
      0.08184528350830078,
      -0.11323000490665436,
      0.018136993050575256,
      0.063310906291008,
      0.028071139007806778,
      0.09816453605890274,
      0.049474067986011505,
      -0.020838206633925438,
      -0.009619662538170815,
      -0.13031011819839478,
      -0.03843650966882706,
      -0.05317693203687668,
      -0.0023565555457025766,
      0.03246276453137398,
      0.0019293007208034396,
      0.06799629330635071,
      0.05857301503419876,
      -0.0020938932429999113,
      -0.0031604282557964325,
      -0.06031327694654465,
      0.025998538359999657,
      -0.04250483587384224,
      0.03825514391064644,
      0.03538700193166733,
      0.04679146781563759,
      0.03670581802725792,
      -0.02566308155655861,
      -0.020837144926190376,
      -0.022403376176953316,
      0.0014765041414648294,
      0.0972868949174881,
      -0.014758240431547165,
      0.011234422214329243,
      -0.011942295357584953,
      -0.06195978447794914,
      0.0058769602328538895,
      -0.08782847970724106,
      -0.017666161060333252,
      0.0517464242875576,
      0.02402302995324135,
      0.045014116913080215,
      -0.028543319553136826,
      -0.06269665062427521,
      0.09406136721372604,
      -0.021469179540872574,
      -0.029485732316970825,
      -0.006105100270360708,
      -0.04803178086876869,
      0.044027991592884064,
      0.06969599425792694,
      0.035302191972732544,
      -0.0014122212305665016,
      0.003794217947870493,
      -0.0027417801320552826,
      -0.044533200562000275,
      -0.10470210760831833,
      -0.013745670206844807,
      -0.07192378491163254,
      0.015525461174547672,
      0.020431293174624443,
      -0.005318743642419577,
      0.037329141050577164,
      -0.01177365705370903,
      -0.04592731595039368,
      0.027761662378907204,
      -0.04595852643251419,
      0.011653621681034565,
      0.04156380891799927,
      0.043264586478471756,
      0.07465963810682297,
      -0.0036351149901747704,
      0.03928828611969948,
      -0.02652088925242424,
      -0.006734962109476328,
      -0.0206755418330431,
      -0.03177589178085327,
      -0.029168765991926193,
      0.04461629316210747,
      0.07679599523544312,
      -0.005292065907269716,
      -0.010558249428868294,
      0.025515519082546234,
      0.033786073327064514,
      -0.035492852330207825,
      -0.07282551378011703,
      -0.025805318728089333,
      0.045951563864946365,
      -0.01655266247689724,
      0.008613528683781624,
      -0.04775971546769142,
      0.0638386532664299,
      -0.040428563952445984,
      -0.03206682577729225,
      -0.09837201982736588,
      0.019404012709856033,
      -0.06570038199424744,
      -0.0028023559134453535,
      0.002838839776813984,
      0.033242739737033844,
      -0.006868029944598675,
      -0.024088822305202484,
      0.02898729220032692,
      0.08450387418270111,
      -0.09034227579832077,
      -0.03822717443108559,
      0.005167925264686346,
      0.000567427952773869,
      0.012192239053547382,
      -0.04049176722764969,
      -0.02336246706545353,
      -0.002938988618552685,
      -0.036869585514068604,
      0.029987167567014694,
      -0.08542966842651367,
      0.038137272000312805,
      -0.03255479410290718,
      0.031755827367305756,
      -0.013027068227529526,
      0.03207797184586525,
      -0.0018175814766436815,
      -0.0875188484787941,
      -0.06709693372249603,
      0.09569352120161057,
      0.024268943816423416,
      0.0903368890285492,
      -0.01698324643075466,
      0.02563387155532837,
      -0.05407789349555969,
      0.05160311982035637,
      0.004839939530938864,
      0.02379007078707218,
      -0.0031036289874464273,
      -0.004733110778033733,
      0.06782371550798416,
      -0.04811939224600792,
      -0.0005793629679828882,
      0.04725942388176918,
      0.007515260949730873,
      -0.027418307960033417,
      -0.007628690451383591,
      0.05068798363208771,
      0.005576373543590307,
      0.04267708957195282,
      0.018782401457428932,
      -0.06169991195201874,
      0.004201462958008051,
      -0.015080100856721401,
      -0.010477549396455288,
      0.05973431468009949,
      0.010949894785881042,
      -0.018188195303082466,
      -0.05721868947148323,
      -0.010717702098190784,
      0.023146359249949455,
      0.03454951196908951,
      -0.005839158780872822,
      -0.01739044487476349,
      -0.009536407887935638,
      -0.023628676310181618,
      -0.06835641711950302,
      -0.007099762558937073,
      -0.005448333453387022,
      -0.06799596548080444,
      -0.02965470403432846,
      -0.007823838852345943,
      0.05502461642026901,
      0.030243132263422012,
      -0.047703780233860016,
      0.06793785095214844,
      -0.019867464900016785,
      -0.024568989872932434,
      0.04617152735590935,
      -0.04009139910340309,
      0.017687475308775902,
      0.01585247367620468,
      0.027815496549010277,
      0.006454559974372387,
      -0.027443060651421547,
      -0.02226709946990013,
      0.005053377244621515,
      0.01517175231128931,
      0.05262869596481323,
      0.03353307396173477,
      -0.014649678952991962,
      0.06765586882829666,
      -0.0024953351821750402,
      -0.009401634335517883,
      -0.037664130330085754,
      0.025884581729769707,
      -0.032241955399513245,
      -0.08056443929672241,
      0.04200132191181183,
      0.060690537095069885,
      0.059197381138801575,
      0.01662052981555462,
      -0.004335562698543072,
      0.01033934485167265,
      -0.101838618516922,
      -0.008942551910877228,
      0.0078269736841321,
      0.09126991033554077,
      -0.05774398520588875,
      0.050927501171827316,
      -0.01969020441174507,
      0.02355770580470562,
      -0.06712829321622849,
      0.04058540239930153,
      -0.009949910454452038,
      -0.035294193774461746,
      0.019528565928339958,
      -0.026939483359456062,
      0.02719436027109623,
      -0.010308950208127499,
      0.04288816824555397,
      0.0019348766654729843,
      0.05832723528146744,
      0.01508993562310934,
      -0.045651260763406754,
      0.06712227314710617,
      -0.0005586055340245366,
      0.08160374313592911,
      -0.02746838517487049,
      -0.057405389845371246,
      -0.02016562409698963,
      -0.04525407776236534,
      0.016774937510490417,
      0.014120472595095634,
      -0.023871734738349915,
      0.014003719203174114,
      -0.058825910091400146,
      0.022027205675840378,
      -0.05134260281920433,
      -0.009279481135308743,
      -0.04299639165401459,
      0.00558820366859436,
      -0.025528499856591225,
      0.03623981773853302,
      -0.030612928792834282,
      -0.02027757093310356,
      0.06680884212255478,
      -0.013235512189567089,
      0.058701641857624054,
      -0.035526640713214874,
      -0.016292918473482132,
      -0.04552527517080307,
      0.024665232747793198,
      -0.06424442678689957,
      -0.02541358955204487,
      0.039130017161369324,
      -0.05619563162326813,
      0.07838239520788193,
      -0.01395297609269619,
      -0.04965838044881821,
      -0.04682932049036026,
      0.01286373846232891,
      0.044825367629528046,
      0.022852038964629173,
      -0.007156231440603733,
      0.008347620256245136,
      -0.017001580446958542,
      -0.009743026457726955,
      -0.08975028246641159,
      0.08538889139890671,
      -0.006222832482308149,
      0.00030184752540662885,
      -0.002829041564837098,
      -0.008558440953493118,
      -0.04802650585770607,
      0.0300739798694849,
      -0.01480892300605774,
      -0.03043200448155403,
      -0.0684240460395813,
      0.021482516080141068,
      0.002539257984608412,
      0.005648949649184942,
      -0.05254469811916351,
      0.025823812931776047,
      -0.11161638051271439,
      -0.017410771921277046,
      0.018357591703534126,
      0.006068181246519089,
      -0.04706716537475586,
      -0.021969638764858246,
      -0.006189486011862755,
      -0.05058320611715317,
      0.009439397603273392,
      0.11312327533960342,
      0.025062380358576775,
      0.0089994752779603,
      0.02692289836704731,
      -0.03341715410351753,
      0.009217518381774426,
      -0.014663581736385822,
      0.013154002837836742,
      0.03238384798169136,
      0.05661961808800697,
      -0.004998935852199793,
      0.045077476650476456,
      0.055641964077949524,
      0.014467515982687473,
      0.02350352704524994,
      0.06049259379506111,
      -0.010201803408563137,
      -0.01575888693332672,
      -0.07404076308012009,
      0.049642547965049744,
      0.014168467372655869,
      -0.09694243967533112,
      0.04213952645659447,
      0.001340127782896161,
      0.012560766190290451,
      -0.010075381956994534,
      -0.06779572367668152,
      -0.004280622582882643,
      0.025077778846025467,
      -0.08885355293750763,
      0.055119115859270096,
      -0.022468091920018196,
      0.07280243188142776,
      0.0029563482385128736,
      -0.03127291426062584,
      -0.0034724215511232615,
      -0.03636062145233154,
      -0.053292419761419296,
      0.05439068004488945,
      -0.026237303391098976,
      0.0314883328974247,
      -0.010586094111204147,
      -0.07836967706680298,
      -0.05563616007566452,
      0.0781957358121872,
      0.0032741818577051163,
      -0.007103713694959879,
      0.02625381387770176,
      -0.003644344862550497,
      -0.018847016617655754,
      0.023015068843960762,
      0.018631834536790848,
      -0.00904578436166048,
      -0.10250239074230194,
      -0.04614800214767456,
      -0.06039002910256386,
      0.05263228714466095,
      -0.034308161586523056,
      0.011674204841256142,
      -0.10078071802854538,
      -0.03263938054442406,
      -0.0032252788078039885,
      -0.01779295690357685,
      -5.193271499592811e-05,
      -0.028066495433449745,
      0.03075539879500866,
      0.02030489593744278,
      0.062155723571777344,
      -0.06008473038673401,
      0.030709678307175636,
      -0.11135374754667282,
      0.04792824387550354,
      -0.021714400500059128,
      -0.025853238999843597,
      -0.01259086187928915,
      -0.035508718341588974,
      0.023438874632120132,
      -0.04169195890426636,
      -0.005391504615545273,
      -0.029851695522665977,
      -0.038008034229278564,
      0.0016165943816304207,
      0.014139889739453793,
      -0.002232547616586089,
      0.05874984338879585,
      0.06956234574317932,
      -0.0592309832572937,
      0.025540610775351524,
      0.048750609159469604,
      0.00013501624925993383,
      0.07048503309488297,
      0.014141145162284374,
      -0.017513351514935493,
      -0.04480162635445595,
      0.0234431941062212,
      -0.10700056701898575,
      0.10289911180734634,
      -0.001727683236822486,
      0.04484137147665024,
      0.032179106026887894,
      -0.02123003825545311,
      0.032357245683670044,
      -0.018313949927687645,
      -0.02562607266008854,
      -0.026681477203965187,
      -0.023317156359553337,
      0.0599110871553421,
      0.02671240083873272
    ],
    [
      -0.029626669362187386,
      -0.003192898351699114,
      0.0022091991268098354,
      -0.05852902680635452,
      -0.07877074182033539,
      0.05256350338459015,
      -0.030445728451013565,
      0.0815868005156517,
      -0.013111011125147343,
      -0.04187395051121712,
      -0.05123443529009819,
      0.0843164250254631,
      -0.0033946025650948286,
      0.02667032927274704,
      -0.029466677457094193,
      -0.015325435437262058,
      -0.0018746976274996996,
      -0.0981278046965599,
      -0.016266390681266785,
      -0.06732584536075592,
      -0.024932656437158585,
      0.03128466010093689,
      -0.0028769252821803093,
      0.036657094955444336,
      -0.01954697258770466,
      0.013100473210215569,
      -0.06339316070079803,
      -0.07337620109319687,
      -0.04605482518672943,
      -0.0007214294746518135,
      0.016568297520279884,
      0.030383968725800514,
      0.02049148827791214,
      0.002367547480389476,
      0.0250862967222929,
      0.04846921190619469,
      0.026085348799824715,
      -0.07972130924463272,
      -0.017269646748900414,
      -0.04168302193284035,
      0.07545260339975357,
      -0.021052246913313866,
      -0.027139486744999886,
      -0.018543152138590813,
      -0.010757441632449627,
      0.013841110281646252,
      0.0011225526686757803,
      -0.01884460262954235,
      -0.014490634202957153,
      -0.01170799694955349,
      0.051467180252075195,
      0.015037905424833298,
      0.07888961583375931,
      -0.053695157170295715,
      -0.02588920295238495,
      0.08302345871925354,
      -0.012569159269332886,
      0.018985139206051826,
      -0.06614319980144501,
      -0.02741769514977932,
      0.0200571957975626,
      -0.03654688596725464,
      -0.0351719856262207,
      -0.07021339237689972,
      -0.004226160701364279,
      -0.04394897073507309,
      0.06845781952142715,
      -0.012174314819276333,
      -0.013148423284292221,
      -0.023350341245532036,
      -0.02531885914504528,
      -0.01612548902630806,
      0.029120637103915215,
      0.06363192200660706,
      0.009289258159697056,
      0.03762507438659668,
      -0.07452739030122757,
      -0.009972421452403069,
      -0.06259091943502426,
      0.027701135724782944,
      0.03656955808401108,
      0.04942258447408676,
      -0.03283194452524185,
      0.04430057480931282,
      -0.019606811925768852,
      -0.0004961001104675233,
      0.01258070021867752,
      -0.005557067226618528,
      -0.04037567600607872,
      0.026917951181530952,
      0.01808691956102848,
      -0.01918988861143589,
      0.023294176906347275,
      0.03787003457546234,
      -0.0749896764755249,
      -0.03398578613996506,
      -0.002633526222780347,
      0.0063307867385447025,
      0.006054673343896866,
      0.009949319064617157,
      -0.006378468591719866,
      0.014776475727558136,
      0.017855176702141762,
      0.06234375387430191,
      -0.06236044690012932,
      0.04121526703238487,
      -0.050467319786548615,
      -0.0011235976126044989,
      -0.03045080043375492,
      -0.0024852182250469923,
      0.04802753031253815,
      -0.002950348425656557,
      0.12856906652450562,
      -0.0593704991042614,
      -0.05239179730415344,
      0.01207904051989317,
      -0.07051786035299301,
      0.022464390844106674,
      0.024057233706116676,
      0.014006942510604858,
      0.048785846680402756,
      0.07823006808757782,
      0.01399345975369215,
      -0.0201929472386837,
      -0.017500676214694977,
      -0.027791105210781097,
      -0.0036445113364607096,
      0.048486556857824326,
      -0.042773663997650146,
      0.017805567011237144,
      0.06320177018642426,
      0.00196827738545835,
      -0.012637516483664513,
      0.057327743619680405,
      0.01959063671529293,
      -0.00312386779114604,
      -0.06449486315250397,
      -0.03844374045729637,
      -0.008799424394965172,
      0.03234824165701866,
      -0.07921604812145233,
      -0.01326820719987154,
      0.013174968771636486,
      -0.048943497240543365,
      -0.008972887881100178,
      -0.010453803464770317,
      0.058092955499887466,
      0.013603879138827324,
      -0.0054835663177073,
      -0.04167129844427109,
      0.003068136051297188,
      -0.04236713796854019,
      0.024962203577160835,
      -0.007465047296136618,
      0.03466982766985893,
      -0.0051094465889036655,
      -0.03717517852783203,
      -0.04545825347304344,
      0.03710431605577469,
      0.01567377895116806,
      -0.018353382125496864,
      0.005792232230305672,
      0.019783250987529755,
      0.03700186684727669,
      -0.02111910842359066,
      0.08135895431041718,
      -0.09183264523744583,
      -0.053813427686691284,
      0.005091717001050711,
      0.033554110676050186,
      0.02093908190727234,
      0.032801639288663864,
      -0.04395376145839691,
      0.017598122358322144,
      0.05901101604104042,
      0.00900572631508112,
      -0.0033386731520295143,
      0.018279409036040306,
      -0.03939482197165489,
      -0.051064249128103256,
      -0.02708098106086254,
      -0.025522524490952492,
      -0.0381072498857975,
      0.030182763934135437,
      -0.05549203231930733,
      -0.1287248134613037,
      -0.010817022994160652,
      0.006174067966639996,
      -0.034495025873184204,
      -0.021954352036118507,
      0.04192543774843216,
      -0.048249222338199615,
      -0.0188811756670475,
      -0.036792486906051636,
      -0.03298747166991234,
      0.002052741590887308,
      0.004765328019857407,
      0.012515729293227196,
      -0.05032047629356384,
      0.049168165773153305,
      0.035554420202970505,
      0.012288381345570087,
      0.04597662016749382,
      0.01534989569336176,
      0.01589846983551979,
      0.00647917203605175,
      -0.0076415347866714,
      0.045474063605070114,
      0.04391060769557953,
      0.017504030838608742,
      0.04100074619054794,
      0.014109519310295582,
      -0.01723167859017849,
      0.005008493084460497,
      0.012941366992890835,
      -0.0662018433213234,
      0.08067801594734192,
      0.03449120372533798,
      -0.019967468455433846,
      -0.0025227325968444347,
      -0.029782338067889214,
      0.023818574845790863,
      -0.007871261797845364,
      0.046516481786966324,
      0.043212320655584335,
      -0.014185885898768902,
      0.012188013643026352,
      0.04142681509256363,
      -0.0018760156817734241,
      -0.038798119872808456,
      0.014922194182872772,
      0.027057059109210968,
      0.07093227654695511,
      -0.04405088722705841,
      0.014464223757386208,
      -0.08019966632127762,
      0.04323183000087738,
      -0.03694310784339905,
      0.06596530973911285,
      0.007453339174389839,
      0.05618315935134888,
      -0.03329068794846535,
      0.025935541838407516,
      0.09556655585765839,
      0.08427190780639648,
      0.11669666320085526,
      -0.004495406523346901,
      0.020009217783808708,
      0.0009905995102599263,
      0.0625271424651146,
      -0.06210685521364212,
      0.019094018265604973,
      -0.008569145575165749,
      0.016359277069568634,
      -0.09681738913059235,
      -0.017740100622177124,
      -0.007714502979069948,
      0.0584491603076458,
      -0.047857675701379776,
      -0.01125521119683981,
      0.017710214480757713,
      -0.02383214794099331,
      0.023114081472158432,
      0.05361977964639664,
      -0.007776960730552673,
      -0.0007690488127991557,
      0.005032238084822893,
      -0.00044129526941105723,
      -0.06103540584445,
      -0.05318921059370041,
      -0.01958087645471096,
      0.08964291214942932,
      -0.000915671931579709,
      0.02581639587879181,
      -0.013336586765944958,
      0.025938142091035843,
      0.004007789772003889,
      -0.07754959911108017,
      -0.05335871875286102,
      -0.030587179586291313,
      0.0028397785499691963,
      -0.017384925857186317,
      -0.0605459064245224,
      0.05274892598390579,
      -0.007122721057385206,
      0.011528762057423592,
      0.04724712297320366,
      0.02796037122607231,
      0.046224094927310944,
      0.00883091613650322,
      -0.06427312642335892,
      0.03227170929312706,
      0.04303748905658722,
      -0.03922633081674576,
      -0.013619869016110897,
      -0.06846074014902115,
      0.040303707122802734,
      0.010732114315032959,
      0.03933025151491165,
      -0.009981738403439522,
      0.01805168390274048,
      -0.10497279465198517,
      0.0089270593598485,
      0.053257931023836136,
      0.04194967448711395,
      -0.0017116720555350184,
      0.0581616647541523,
      0.04646057263016701,
      -0.07138442993164062,
      0.05020080879330635,
      -0.032936807721853256,
      0.012239051051437855,
      -0.07632816582918167,
      -0.027676109224557877,
      0.05620350316166878,
      -0.04649615287780762,
      0.054544847458601,
      -0.013769123703241348,
      0.027580982074141502,
      0.008545810356736183,
      -0.006528054364025593,
      -0.0019624056294560432,
      0.014422141015529633,
      0.012678169645369053,
      -0.07076303660869598,
      -0.0955846831202507,
      -0.07210154831409454,
      -0.040680721402168274,
      -0.0004218527174089104,
      0.025242343544960022,
      0.03603837639093399,
      -0.020812682807445526,
      -0.033832233399152756,
      -0.0369466133415699,
      0.042976561933755875,
      0.002409118227660656,
      0.033867329359054565,
      -0.00028740946436300874,
      0.01494632102549076,
      0.05792905017733574,
      -0.03228628262877464,
      -0.030497979372739792,
      0.054401591420173645,
      -0.02681892365217209,
      0.015902740880846977,
      -0.027473049238324165,
      -0.011494314298033714,
      -0.043798454105854034,
      -0.0746731087565422,
      -0.03168202564120293,
      -0.0077533177100121975,
      0.05155976489186287,
      -0.039713479578495026,
      0.07538449019193649,
      0.005264082457870245,
      -0.013046370819211006,
      0.012104025110602379,
      -0.0830874815583229,
      0.020653221756219864,
      -0.05450916662812233,
      -0.08305839449167252,
      -0.017427433282136917,
      0.07115885615348816,
      0.011787021532654762,
      0.01524247508496046,
      0.04621194303035736,
      -0.04288332909345627,
      -0.0011666533537209034,
      0.0018057046690955758,
      0.026029406115412712,
      -0.09201414883136749,
      -0.029845543205738068,
      -0.02180168591439724,
      0.0020551588386297226,
      0.023360077291727066,
      0.05467282235622406,
      0.09573996812105179,
      0.005260287784039974,
      0.004810578655451536,
      -0.03620845079421997,
      0.020768137648701668,
      -0.02145962044596672,
      0.04353468492627144,
      -0.016106033697724342,
      0.0353504940867424,
      -0.03673480078577995,
      0.08217689394950867,
      -0.0013760357396677136,
      -0.008191847242414951,
      -0.020483601838350296,
      0.01703597605228424,
      0.012508570216596127,
      -0.008379991166293621,
      0.0009723984985612333,
      0.012371551245450974,
      0.029619775712490082,
      -0.014550366438925266,
      -0.0046459101140499115,
      0.014597457833588123,
      0.021874140948057175,
      0.013086465187370777,
      -0.040742237120866776,
      0.04670822620391846,
      -0.009244922548532486,
      0.03715507686138153,
      -0.003465645480901003,
      -0.013964224606752396,
      0.12042371183633804,
      -0.08362504094839096,
      0.02576567977666855,
      0.00128218624740839,
      -0.05356980860233307,
      -0.00848640501499176,
      0.004206913057714701,
      0.0451122410595417,
      -0.09580618143081665,
      0.04399683326482773,
      -0.02236373908817768,
      -0.07585827261209488,
      0.021463241428136826,
      0.08032181859016418,
      0.0020505350548774004,
      -0.04060708358883858,
      0.014826631173491478,
      -0.013041936792433262,
      0.14204862713813782,
      -0.02364298142492771,
      -0.03569341078400612,
      0.032332345843315125,
      0.0032164393924176693,
      -0.00016384280752390623,
      -0.005566958338022232,
      -0.002573134144768119,
      -0.04399966821074486,
      0.006209840066730976,
      0.06328166276216507,
      0.02986057661473751,
      -0.010687997564673424,
      0.04002924636006355,
      -0.012858009897172451,
      0.08528489619493484,
      0.049698736518621445,
      0.006445403676480055,
      -0.05701917037367821,
      -0.054230839014053345,
      0.01810426451265812,
      0.004140649456530809,
      -0.03076745569705963,
      0.01894547790288925,
      -0.0016317402478307486,
      -0.020069103688001633,
      -0.03629620745778084,
      0.05022173002362251,
      -0.00737991277128458,
      -0.07508763670921326,
      0.04835880920290947,
      0.035790760070085526,
      0.03749443590641022,
      -0.06328744441270828,
      -0.05610591173171997,
      0.03516272455453873,
      0.05698525533080101,
      0.10793107002973557,
      -0.10276935249567032,
      -0.028590040281414986,
      -0.04603369161486626,
      0.06147920712828636,
      0.0029143623542040586,
      0.03839506581425667,
      -0.08679342269897461,
      0.005166234448552132,
      0.06027206778526306,
      -0.10106467455625534,
      0.004658958874642849,
      0.010314692743122578,
      0.05516458675265312,
      0.04727891832590103,
      0.007389378268271685,
      0.009213084354996681,
      -0.044507935643196106,
      0.048630665987730026,
      0.007151090539991856,
      0.06453749537467957,
      0.04202653467655182,
      -0.007670032326132059,
      0.09081055223941803,
      0.08045073598623276,
      0.03978879004716873,
      0.009462645277380943,
      -0.008936912752687931,
      -0.05781807750463486,
      0.09137261658906937,
      0.05593172833323479,
      0.01129938755184412,
      -0.05585833266377449,
      -0.0058806287124753,
      -0.09492465853691101,
      -0.028684817254543304,
      0.04808250814676285,
      0.02775166928768158,
      -0.02382897026836872,
      -0.006413987372070551,
      0.07559885084629059
    ],
    [
      -0.04468689113855362,
      0.0026510453317314386,
      -0.05793764069676399,
      0.015900446102023125,
      0.036626726388931274,
      -0.006404664367437363,
      0.013447858393192291,
      -0.041489314287900925,
      -0.07731316238641739,
      0.030258245766162872,
      0.02108965627849102,
      0.011118820868432522,
      0.054160237312316895,
      0.06213622912764549,
      -0.059581249952316284,
      0.0074095092713832855,
      0.014524546451866627,
      0.039033472537994385,
      -0.006750212982296944,
      -0.028205621987581253,
      -0.029632700607180595,
      0.021230941638350487,
      -0.03504820540547371,
      -0.01248040609061718,
      0.04319671541452408,
      0.020149461925029755,
      0.057601168751716614,
      -0.028721468523144722,
      -0.07322625815868378,
      -0.012696434743702412,
      0.031720004975795746,
      -0.03791143372654915,
      0.031408101320266724,
      -0.051913730800151825,
      0.02985520102083683,
      0.05422128364443779,
      0.008148894645273685,
      0.011255751363933086,
      -0.022791804745793343,
      0.00021453187218867242,
      -0.007955310866236687,
      -0.011860530823469162,
      -0.0003010072687175125,
      0.06450697034597397,
      0.06741578876972198,
      -0.010292375460267067,
      -0.030342712998390198,
      0.048216644674539566,
      0.03761418163776398,
      -0.011341454461216927,
      0.04701869562268257,
      0.07515832781791687,
      0.06883499771356583,
      -0.03315234184265137,
      0.06911026686429977,
      -0.029827114194631577,
      0.008781361393630505,
      -0.08550005406141281,
      -0.0033984272740781307,
      -0.01153539214283228,
      -0.06580003350973129,
      -0.06600713729858398,
      0.06423617154359818,
      -0.01604490913450718,
      0.04775480553507805,
      0.06504441052675247,
      0.013906832784414291,
      0.029424086213111877,
      0.024173663929104805,
      -0.02588268183171749,
      -0.01695573516190052,
      0.04707647114992142,
      0.049702033400535583,
      0.010925985872745514,
      0.07126759737730026,
      0.0503414161503315,
      -0.04046005755662918,
      -0.0376201756298542,
      -0.0975136011838913,
      -0.03008878417313099,
      -0.02276558242738247,
      -0.0015010540373623371,
      0.035012394189834595,
      -0.005708193406462669,
      0.016192208975553513,
      0.026403147727251053,
      -0.023646768182516098,
      -0.027819287031888962,
      -0.08653706312179565,
      -3.698425643960945e-05,
      0.004533636849373579,
      0.0816771388053894,
      0.026226826012134552,
      0.06794194132089615,
      0.04316285252571106,
      -0.028429780155420303,
      -0.006939597427845001,
      -0.025241335853934288,
      -0.06437043100595474,
      0.015312083065509796,
      -0.024744722992181778,
      0.03306509926915169,
      0.008918429724872112,
      0.018915869295597076,
      -0.03617297112941742,
      -0.05433521047234535,
      0.022822700440883636,
      -0.052313871681690216,
      -0.01948297955095768,
      0.013508184812963009,
      -0.014341454021632671,
      -0.016517458483576775,
      0.03620219603180885,
      -0.03699673339724541,
      -0.009494464844465256,
      -0.01116104144603014,
      0.00877990759909153,
      0.006285539828240871,
      0.010141695849597454,
      -0.05193908512592316,
      -0.14372186362743378,
      0.03443235531449318,
      -0.007162943482398987,
      0.00501051964238286,
      -0.04388655722141266,
      0.02298906445503235,
      -0.01767083816230297,
      0.0016331687802448869,
      -0.01998702622950077,
      0.06238952651619911,
      0.06696289032697678,
      0.0046006860211491585,
      -0.015393541194498539,
      -0.00019618171791080385,
      -0.003117593703791499,
      0.015791764482855797,
      -0.049303166568279266,
      -0.03248142451047897,
      -0.025198550894856453,
      -0.06917692720890045,
      -0.020419079810380936,
      -0.0003393090737517923,
      0.08374630659818649,
      0.01600167341530323,
      0.04820146784186363,
      -0.05162777751684189,
      -0.03916729614138603,
      -0.05881417170166969,
      0.00822510477155447,
      -0.00457514775916934,
      0.0038041768129915,
      -0.038133662194013596,
      -0.003895680420100689,
      0.001184186083264649,
      -0.004568555857986212,
      -0.056295402348041534,
      -0.016820624470710754,
      0.009650642052292824,
      -0.009503868408501148,
      -0.041964586824178696,
      0.09706777334213257,
      -0.006256430875509977,
      0.015193186700344086,
      0.016826534643769264,
      -0.03246127441525459,
      0.06159401312470436,
      -0.04182535409927368,
      -0.005421529058367014,
      0.021449211984872818,
      0.018991369754076004,
      0.027231793850660324,
      0.10380630195140839,
      -0.03798983246088028,
      0.04112018644809723,
      0.011389577761292458,
      -0.02960544265806675,
      -0.015914008021354675,
      0.06309407949447632,
      0.08702515810728073,
      0.04542844742536545,
      0.006062082480639219,
      -0.03333648666739464,
      -0.03546428680419922,
      -0.08744519203901291,
      -0.006268704310059547,
      -0.014377066865563393,
      0.0017962538404390216,
      0.04744181036949158,
      0.03552575409412384,
      0.00021217223547864705,
      0.03382162004709244,
      0.02993585169315338,
      0.03896345570683479,
      0.05421527475118637,
      -0.030109306797385216,
      0.08668592572212219,
      0.05095181241631508,
      -0.01706339791417122,
      0.035907670855522156,
      0.10152837634086609,
      -0.10167695581912994,
      0.0036876515951007605,
      0.0030553031247109175,
      -0.0813775509595871,
      0.007777838036417961,
      -0.016657181084156036,
      0.03887532651424408,
      -0.014226768165826797,
      -0.02475082501769066,
      -0.1252133548259735,
      0.09666115790605545,
      0.032276224344968796,
      -0.021209625527262688,
      -0.02241372875869274,
      -0.08092547953128815,
      -0.0055018216371536255,
      -0.05267048254609108,
      -0.08157935738563538,
      0.013361506164073944,
      0.013946215622127056,
      -0.06728710979223251,
      0.02530505880713463,
      -0.04006355628371239,
      0.07890863716602325,
      0.04565713182091713,
      -0.062113381922245026,
      0.02996242605149746,
      0.005688120145350695,
      -0.0038244170136749744,
      0.11340757459402084,
      0.06183379143476486,
      -0.04582420736551285,
      -0.019661961123347282,
      0.03878812864422798,
      0.02139786258339882,
      0.050457119941711426,
      0.026038283482193947,
      0.05542551726102829,
      -0.06099671870470047,
      -0.010587054304778576,
      -0.022825591266155243,
      0.01882046088576317,
      -0.021163763478398323,
      -0.024593645706772804,
      0.013155774213373661,
      -0.008849266916513443,
      0.02924361266195774,
      -0.06876246631145477,
      0.07648299634456635,
      0.020087970420718193,
      -0.02032560296356678,
      -0.016552800312638283,
      -0.05311683937907219,
      0.02746300958096981,
      0.05253032594919205,
      0.01876039430499077,
      -0.028888247907161713,
      -0.048409104347229004,
      0.011500947177410126,
      -0.04154509678483009,
      0.06068560481071472,
      -0.025930285453796387,
      0.015074305236339569,
      0.025173746049404144,
      0.03310752287507057,
      0.032006215304136276,
      -0.008319876156747341,
      0.028575027361512184,
      -0.0031864922493696213,
      0.04037315398454666,
      -0.009041821584105492,
      0.03659224510192871,
      0.008347860537469387,
      -0.014200868085026741,
      0.00667427945882082,
      0.007917024195194244,
      -0.0032202342990785837,
      -0.0227830708026886,
      -0.13275349140167236,
      0.06317175924777985,
      0.048473507165908813,
      -0.0381326749920845,
      -0.010519754141569138,
      0.0011008313158527017,
      0.02948169782757759,
      -0.021065834909677505,
      0.05166037753224373,
      0.07512526959180832,
      -0.06346674263477325,
      0.020290683954954147,
      0.04522985219955444,
      0.07600797712802887,
      -0.001628631609492004,
      -0.05656617879867554,
      -0.08247674256563187,
      0.06689514964818954,
      -0.04643981531262398,
      0.08591882884502411,
      0.01957259140908718,
      -0.016196807846426964,
      -0.06644344329833984,
      0.042815014719963074,
      0.03816165402531624,
      -0.015184800140559673,
      -0.002564595779404044,
      0.04694979265332222,
      -0.01585611142218113,
      -0.01073532272130251,
      0.03519705682992935,
      -0.013950366526842117,
      0.029958847910165787,
      0.0009100496536120772,
      -0.08805984258651733,
      -0.021660398691892624,
      0.04235002025961876,
      -0.020989511162042618,
      -0.07846584916114807,
      0.012125509791076183,
      -0.005772343371063471,
      -0.040918223559856415,
      -0.014194131828844547,
      0.03937317058444023,
      0.0035796461161226034,
      -0.08086928725242615,
      0.06904138624668121,
      -0.05215153843164444,
      0.035876017063856125,
      -0.025122428312897682,
      0.011422792449593544,
      0.03913690894842148,
      0.002944094827398658,
      -0.08356738090515137,
      -0.08823347091674805,
      -0.011458024382591248,
      -0.02820458821952343,
      0.009274393320083618,
      0.020628631114959717,
      0.005893993657082319,
      0.025120895355939865,
      -0.090416818857193,
      -0.027084557339549065,
      -0.018528569489717484,
      -0.00901805516332388,
      0.05974216014146805,
      0.06295637786388397,
      0.0028962092474102974,
      -0.030883612111210823,
      -0.06986575573682785,
      -0.009182370267808437,
      0.001698348787613213,
      0.040451109409332275,
      0.006687949411571026,
      -0.0448632575571537,
      0.05195561423897743,
      0.018801821395754814,
      0.0478527806699276,
      -0.09120842069387436,
      -0.06263639777898788,
      0.02311222441494465,
      -0.04961938410997391,
      0.009181847795844078,
      0.030087731778621674,
      0.023224830627441406,
      -0.004727690946310759,
      -0.08174940198659897,
      0.005303138867020607,
      0.017184246331453323,
      0.03540630638599396,
      -0.023940809071063995,
      0.023990968242287636,
      0.004191869869828224,
      0.011582762002944946,
      -0.06151299551129341,
      0.01287058461457491,
      -0.04613035172224045,
      0.07729130238294601,
      0.013711179606616497,
      0.02043621614575386,
      -0.02146645076572895,
      -0.0261427853256464,
      -0.01582976244390011,
      0.026614289730787277,
      -0.013518641702830791,
      0.05956638604402542,
      -0.02654586359858513,
      -0.06775984168052673,
      -0.01552428025752306,
      -0.008834724314510822,
      -0.007048850413411856,
      -0.004074314143508673,
      -0.03954017907381058,
      0.015332918614149094,
      -0.03565434366464615,
      0.06254135072231293,
      0.030035465955734253,
      0.002266655443236232,
      -0.07600586861371994,
      -0.021765563637018204,
      -0.07614227384328842,
      0.044788289815187454,
      -0.018597548827528954,
      0.013794454745948315,
      0.04317730665206909,
      0.03259924799203873,
      0.03475341945886612,
      0.02170582115650177,
      -0.07285449653863907,
      0.0400126650929451,
      0.01059720292687416,
      0.05415339395403862,
      0.06879144161939621,
      0.027174655348062515,
      -0.04840417206287384,
      0.002273161895573139,
      -0.027486996725201607,
      0.010776202194392681,
      -0.007860703393816948,
      0.06101642921566963,
      -0.031260907649993896,
      0.01029161922633648,
      0.008200323209166527,
      -0.0587349534034729,
      -0.06056562066078186,
      0.011873745359480381,
      -0.03344159200787544,
      -0.01177159696817398,
      0.012062580324709415,
      -0.10597582161426544,
      0.07013318687677383,
      -0.027636069804430008,
      -0.00010130210284842178,
      -0.07736887037754059,
      0.04200858250260353,
      -0.0766855850815773,
      -0.0029313534032553434,
      0.0004446880193427205,
      0.023807600140571594,
      0.035435374826192856,
      0.06970471888780594,
      -0.047583043575286865,
      0.031452104449272156,
      -0.025580571964383125,
      0.003769692499190569,
      0.059986334294080734,
      -2.4631266569485888e-05,
      -0.050572991371154785,
      0.004563234746456146,
      0.03162337839603424,
      0.03431469574570656,
      -0.008936782367527485,
      0.021017789840698242,
      0.019846444949507713,
      0.007999393157660961,
      0.0397498719394207,
      -0.06855384260416031,
      0.03342531621456146,
      0.03172103688120842,
      0.053313083946704865,
      -0.0030486409086734056,
      -0.005020458716899157,
      0.09065649658441544,
      0.005207698792219162,
      0.008982819505035877,
      -0.015204237774014473,
      0.03761502727866173,
      -0.018683958798646927,
      -0.027658499777317047,
      -0.0008015984785743058,
      -0.009505274705588818,
      0.05381618067622185,
      -0.027653835713863373,
      -0.0309741273522377,
      0.020226525142788887,
      0.06462378799915314,
      0.02050500176846981,
      -0.0679422914981842,
      0.036760345101356506,
      0.05355602875351906,
      -0.05827021971344948,
      -0.06286431849002838,
      0.060987748205661774,
      -0.013422785326838493,
      -0.03270886093378067,
      0.06545528024435043,
      0.013930721208453178,
      0.06075432151556015,
      0.05161063000559807,
      0.056145135313272476,
      0.0183451846241951,
      -0.05540419742465019,
      -0.016961267217993736,
      0.09537115693092346,
      -0.04464421421289444,
      -0.02832118235528469,
      0.048744454979896545,
      0.0819382295012474,
      -0.0032341063488274813,
      0.01726854033768177,
      0.07209654897451401,
      0.0069871945306658745,
      0.012455900199711323,
      0.0018024495802819729,
      0.011963093653321266
    ],
    [
      -0.0698220431804657,
      0.013047904707491398,
      -0.05311325564980507,
      0.018234528601169586,
      0.0014029779704287648,
      0.0013972606975585222,
      0.01789160445332527,
      -0.07332990318536758,
      -0.0011076650116592646,
      -0.08618321269750595,
      0.04049813002347946,
      -0.055321868509054184,
      0.09721755236387253,
      0.010116107761859894,
      0.008117505349218845,
      0.06416342407464981,
      0.004760062787681818,
      0.07026407122612,
      0.040999121963977814,
      -0.06637637317180634,
      -0.03264538571238518,
      -0.03611825406551361,
      -0.041186314076185226,
      -0.03233177214860916,
      0.08972664922475815,
      -0.004959459882229567,
      0.03720264136791229,
      -0.06204880028963089,
      -0.13618764281272888,
      0.06696967780590057,
      -0.021959897130727768,
      -0.012873852625489235,
      0.00914465170353651,
      -0.04035574197769165,
      0.011861646547913551,
      0.07476340234279633,
      -0.03979761525988579,
      0.07139608263969421,
      -0.06468158960342407,
      0.03944177180528641,
      0.004925046116113663,
      0.0611482672393322,
      0.06072521209716797,
      0.04449433460831642,
      -0.07712447643280029,
      0.052386585623025894,
      0.01985817402601242,
      -0.047206033021211624,
      0.02620888315141201,
      0.049980588257312775,
      0.10861228406429291,
      0.01683664508163929,
      0.026893042027950287,
      -0.04333915561437607,
      -0.04950277507305145,
      -0.02445831336081028,
      0.05243747681379318,
      -0.04342595860362053,
      0.06292860954999924,
      -0.07738801091909409,
      0.05828626826405525,
      -0.04235637187957764,
      -0.06513246893882751,
      0.02232108823955059,
      0.06741500645875931,
      0.02408403344452381,
      -0.06186535581946373,
      0.008344653993844986,
      -0.0018569686217233539,
      0.019176041707396507,
      -0.04458068311214447,
      -0.05527586489915848,
      -0.0417342483997345,
      -0.001047205412760377,
      -0.01829177513718605,
      -0.02369728684425354,
      0.011894826777279377,
      -0.023191262036561966,
      0.014112060889601707,
      -0.08993519097566605,
      0.015039724297821522,
      -0.00956137664616108,
      -0.014049713499844074,
      -0.04083462059497833,
      0.061115920543670654,
      -0.0069045620039105415,
      0.02479872852563858,
      0.12234877794981003,
      -0.061506349593400955,
      0.048483047634363174,
      0.06739919632673264,
      -0.03807731345295906,
      0.01311732642352581,
      0.026294512674212456,
      0.027385124936699867,
      0.03564884513616562,
      -0.002340705832466483,
      -0.008648380637168884,
      -0.06529351323843002,
      0.0012402038555592299,
      0.03895918279886246,
      0.025546710938215256,
      0.03466726094484329,
      0.005451477598398924,
      -0.010084832087159157,
      -0.08092642575502396,
      0.015567053109407425,
      0.07603587955236435,
      0.07478252798318863,
      0.0071998173370957375,
      0.008383706212043762,
      0.013390772975981236,
      -0.09875888377428055,
      0.03280813246965408,
      0.02301928773522377,
      0.027336787432432175,
      0.0034231548197567463,
      0.0036012267228215933,
      -0.01889132522046566,
      -0.03599020466208458,
      -0.05678742006421089,
      -0.06458276510238647,
      0.03305372595787048,
      -0.06779101490974426,
      -0.019509565085172653,
      0.030040226876735687,
      0.009507900103926659,
      -0.02148422785103321,
      0.024040600284934044,
      -0.008297066204249859,
      0.007769083604216576,
      0.04993559420108795,
      0.04078768566250801,
      0.032113123685121536,
      0.04933281987905502,
      0.014252879656851292,
      0.04424436017870903,
      -0.06747480481863022,
      0.02884775400161743,
      -0.024433599784970284,
      0.04587014764547348,
      -0.024099307134747505,
      0.01911761797964573,
      0.009157553315162659,
      0.01726406253874302,
      -0.004809122532606125,
      -0.044687896966934204,
      0.03326207771897316,
      -0.10439333319664001,
      -0.09762038290500641,
      -0.041457463055849075,
      0.04050443321466446,
      -0.009902149438858032,
      0.1104469895362854,
      -0.021849000826478004,
      0.043801628053188324,
      0.030412891879677773,
      -0.0048116459511220455,
      0.011330236680805683,
      0.05111272633075714,
      -0.0014109249459579587,
      -0.09476706385612488,
      -0.054389987140893936,
      0.03538927435874939,
      -0.04689458757638931,
      -0.07425687462091446,
      0.014610747806727886,
      -0.02454262599349022,
      -0.016459016129374504,
      0.030353151261806488,
      -0.03174770250916481,
      -0.007180095184594393,
      0.022693447768688202,
      -0.02652919478714466,
      0.006341542582958937,
      0.08225932717323303,
      -0.04872506484389305,
      -0.04842350259423256,
      -0.012713457457721233,
      -0.016753120347857475,
      0.028516460210084915,
      -0.022273560985922813,
      0.007837464101612568,
      -0.03633422031998634,
      0.02305060438811779,
      -0.017187243327498436,
      -0.034222450107336044,
      -0.033632196485996246,
      0.10047928243875504,
      -0.033548928797245026,
      0.02174992673099041,
      -0.11444662511348724,
      0.029007483273744583,
      -0.02152860350906849,
      -0.053996264934539795,
      -0.016453949734568596,
      -0.011685533449053764,
      -0.009979148395359516,
      -0.012699064798653126,
      0.021985648199915886,
      0.0017231308156624436,
      0.021914061158895493,
      0.021762140095233917,
      0.01395959872752428,
      -0.07101737707853317,
      0.018338562920689583,
      -0.017990952357649803,
      0.01794806867837906,
      0.08989685028791428,
      -0.010617438703775406,
      -0.00029275609995238483,
      0.0580984503030777,
      -0.04154449701309204,
      0.04540473595261574,
      0.006549516227096319,
      -0.05823802575469017,
      0.0171711016446352,
      0.05166938155889511,
      -0.019200319424271584,
      -0.01533238124102354,
      -0.009328287094831467,
      -0.02856048196554184,
      -0.00508526898920536,
      -0.0159769169986248,
      -0.023586682975292206,
      -0.023567745462059975,
      -0.13175925612449646,
      0.07498783618211746,
      0.049721747636795044,
      -0.051870349794626236,
      -0.07163577526807785,
      0.03423824906349182,
      0.015373197384178638,
      -0.03362046927213669,
      0.004147022496908903,
      -0.00166985928080976,
      0.04629601910710335,
      0.023680849000811577,
      0.04830493405461311,
      0.04074873402714729,
      -0.03859679028391838,
      0.05450751259922981,
      -0.02842545136809349,
      -0.015581592917442322,
      0.04900199919939041,
      0.009098486043512821,
      0.08963724225759506,
      0.007925936952233315,
      0.04955190792679787,
      0.008361044339835644,
      0.010793707333505154,
      -0.010047209449112415,
      -0.024289928376674652,
      -0.09153582155704498,
      0.05966707319021225,
      0.043036479502916336,
      0.07711104303598404,
      -0.04978906735777855,
      0.11104834824800491,
      -0.0287516750395298,
      0.0523197315633297,
      -0.036051034927368164,
      -0.007861927151679993,
      -0.02399909868836403,
      0.07251113653182983,
      0.09190019965171814,
      -0.06172669306397438,
      -0.07080592215061188,
      0.026897871866822243,
      0.0043306294828653336,
      -0.013412770815193653,
      -0.02691475860774517,
      0.03085608407855034,
      -0.050945017486810684,
      0.04815152660012245,
      0.03641154617071152,
      0.001948356512002647,
      -0.013402570970356464,
      0.0015912031522020698,
      0.02069992944598198,
      -0.06773842871189117,
      0.05863552168011665,
      -0.03949956223368645,
      0.004270059522241354,
      0.00761187169700861,
      -0.047707363963127136,
      0.012208107858896255,
      0.016213299706578255,
      0.002392807975411415,
      0.02336406148970127,
      0.02201555296778679,
      0.04124705493450165,
      0.030316876247525215,
      -0.02225205861032009,
      -0.0635981410741806,
      0.0097377710044384,
      0.010752369649708271,
      -0.02127850614488125,
      0.01964954100549221,
      0.07204318791627884,
      0.012900729663670063,
      0.04118525609374046,
      0.04507319629192352,
      -0.07975829392671585,
      -0.020955462008714676,
      0.015900520607829094,
      0.010869573801755905,
      0.02409733459353447,
      -0.004572906531393528,
      0.05722235515713692,
      -0.033742550760507584,
      0.05627715215086937,
      0.003853950183838606,
      -0.07377001643180847,
      -0.05001864582300186,
      0.06405184417963028,
      -0.05752990022301674,
      0.004577558487653732,
      -0.009254397824406624,
      0.0056191072799265385,
      -0.038320694118738174,
      -0.0012086064089089632,
      0.03255365043878555,
      0.012988359667360783,
      0.044993191957473755,
      0.047330886125564575,
      -0.05076500400900841,
      -0.022222531959414482,
      0.06472829729318619,
      -0.011721894145011902,
      0.040712449699640274,
      -0.055140282958745956,
      -0.05535713583230972,
      -0.061900053173303604,
      -0.07670091092586517,
      -0.01833391562104225,
      -0.004648121073842049,
      -0.010553523898124695,
      -0.015378369949758053,
      0.03685259073972702,
      0.02069411799311638,
      0.09512544423341751,
      -0.11325634270906448,
      -0.013397172093391418,
      -0.02740618772804737,
      -0.0025291580241173506,
      -0.025255970656871796,
      -0.026723286136984825,
      0.003194964025169611,
      -0.005770765244960785,
      -0.004399414174258709,
      -0.0162469781935215,
      -0.06712860614061356,
      -0.015419651754200459,
      -0.06891805678606033,
      0.05180886387825012,
      0.05012226849794388,
      -0.0006186669925227761,
      -0.09240607172250748,
      -0.031962182372808456,
      -0.05135268718004227,
      -0.013594265095889568,
      0.008054153062403202,
      0.0819414034485817,
      0.04671773687005043,
      -0.009315291419625282,
      -0.006507648155093193,
      -0.07822803407907486,
      0.0453043207526207,
      0.10692186653614044,
      0.03812357783317566,
      0.0708041861653328,
      0.06876737624406815,
      0.030352694913744926,
      0.02626524679362774,
      0.10560749471187592,
      -0.025050951167941093,
      0.048618704080581665,
      0.021022453904151917,
      0.04345592111349106,
      -0.00029861231450922787,
      0.017277542501688004,
      0.024857589974999428,
      -0.01727127470076084,
      0.05062558501958847,
      0.010539370588958263,
      0.03284795582294464,
      0.02873258851468563,
      -0.047705162316560745,
      -0.019198328256607056,
      -0.07289160788059235,
      -0.022561922669410706,
      -0.02165205590426922,
      0.009672489948570728,
      0.004068757873028517,
      0.04057861492037773,
      0.015564956702291965,
      0.007035480346530676,
      0.04830647632479668,
      0.032040439546108246,
      -0.06346456706523895,
      -0.05727599933743477,
      0.020098382607102394,
      0.03342785686254501,
      0.02869691513478756,
      0.059713467955589294,
      0.04642626643180847,
      -0.0531463697552681,
      0.004194638691842556,
      -0.004974909592419863,
      0.04932694509625435,
      0.02179929055273533,
      -0.057346198707818985,
      0.11016327142715454,
      0.09504643827676773,
      0.054722003638744354,
      -0.022405045107007027,
      -0.017465025186538696,
      -0.03882763162255287,
      -0.10167857259511948,
      -0.04506826773285866,
      0.014084119349718094,
      -0.01751285418868065,
      0.03423135727643967,
      0.018935464322566986,
      0.032265935093164444,
      -0.022851843386888504,
      -0.03664431720972061,
      -0.043623149394989014,
      -0.01656229794025421,
      0.02001293934881687,
      0.036045320332050323,
      0.023444993421435356,
      0.020764758810400963,
      0.10944095999002457,
      0.05470765009522438,
      -0.003129272721707821,
      -0.047628387808799744,
      -0.03308853134512901,
      0.07452715933322906,
      0.019994188100099564,
      -0.07604628801345825,
      -0.042146023362874985,
      0.009413447231054306,
      -0.04233735054731369,
      0.012368672527372837,
      -0.020011374726891518,
      0.05563943833112717,
      0.020613661035895348,
      0.07028834521770477,
      -0.003134477883577347,
      0.033854540437459946,
      0.07963711768388748,
      0.045883964747190475,
      -0.011989240534603596,
      -0.015187976881861687,
      -0.04284251481294632,
      -0.008648927323520184,
      -0.026094434782862663,
      0.014118258841335773,
      -0.032940737903118134,
      -0.01252415869385004,
      0.0021125939674675465,
      -0.03945903480052948,
      -0.03687538579106331,
      -0.055911362171173096,
      0.0741819515824318,
      -0.013568803668022156,
      -0.012659989297389984,
      0.007343157660216093,
      0.02514726109802723,
      0.0197344608604908,
      -0.03328210487961769,
      -0.02606288716197014,
      0.04107986018061638,
      0.011042138561606407,
      -0.03156783804297447,
      -0.061324797570705414,
      -0.07238582521677017,
      0.008802066557109356,
      -0.014188925735652447,
      0.07157361507415771,
      0.02732890471816063,
      -0.02044730819761753,
      0.015366433188319206,
      -0.022918200120329857,
      -0.07406742870807648,
      0.04450101777911186,
      -0.010284177027642727,
      0.002670318353921175,
      0.017518362030386925,
      -0.07366394996643066,
      0.01582062430679798,
      -0.05831301584839821,
      -0.05000127851963043,
      0.05842537805438042,
      0.005501392763108015,
      0.022465649992227554,
      -0.019306058064103127,
      0.043922703713178635,
      -0.0062577370554208755,
      0.0017924034036695957,
      -0.03985266387462616
    ],
    [
      0.0604688785970211,
      -0.03781367093324661,
      -0.026925018057227135,
      0.04100343585014343,
      0.029948396608233452,
      0.03971041738986969,
      -0.02893010340631008,
      -0.04933644086122513,
      0.09925027936697006,
      -0.0179562009871006,
      -0.03176606446504593,
      -0.017026392742991447,
      0.019551903009414673,
      -0.004307035356760025,
      0.0449986606836319,
      0.0009142558556050062,
      0.0362699069082737,
      0.04918351024389267,
      -0.02888692542910576,
      0.07106811553239822,
      -0.022024773061275482,
      -0.013203718699514866,
      0.011311337351799011,
      0.061646781861782074,
      0.01593487337231636,
      0.06159229204058647,
      -0.08461695909500122,
      0.01614687778055668,
      -0.025057565420866013,
      0.03442417457699776,
      -0.02112032286822796,
      -0.030344119295477867,
      -0.0028495145961642265,
      -0.053254105150699615,
      -0.061648424714803696,
      0.006750022992491722,
      0.051703110337257385,
      0.042323898524045944,
      -0.02019011601805687,
      0.09303823858499527,
      0.030232466757297516,
      -0.0426628403365612,
      -0.010851027444005013,
      0.042743898928165436,
      -0.0325448140501976,
      0.02919580042362213,
      -0.10358653962612152,
      -0.0547969788312912,
      0.023271501064300537,
      -0.04745084419846535,
      -0.06899343430995941,
      -0.07046244293451309,
      0.08135923743247986,
      -0.035905443131923676,
      0.04051722213625908,
      0.007275513838976622,
      0.009745374321937561,
      -0.11078330129384995,
      -0.021763339638710022,
      0.01037672907114029,
      -0.035063181072473526,
      -0.057191286236047745,
      -0.029467973858118057,
      0.002444443991407752,
      0.03798577934503555,
      0.02683480642735958,
      0.022433176636695862,
      -0.026653200387954712,
      -0.034679118543863297,
      -0.03658226877450943,
      -0.002757118782028556,
      -0.0452408641576767,
      0.03280717879533768,
      -0.10495936125516891,
      0.03892133757472038,
      0.016292594373226166,
      -0.07408652454614639,
      0.014711367897689342,
      0.026270337402820587,
      0.09978891164064407,
      0.01759687438607216,
      0.001011436339467764,
      0.029166335240006447,
      0.013659129850566387,
      -0.0024871460627764463,
      0.05455981567502022,
      0.056174445897340775,
      -0.06648761034011841,
      0.062071263790130615,
      -0.07621346414089203,
      -0.05026434361934662,
      -0.04882286116480827,
      0.04987010732293129,
      0.0002292448771186173,
      0.07209429144859314,
      0.0892329216003418,
      -0.022670047357678413,
      -0.04000149294734001,
      -0.009228173643350601,
      -0.0773807018995285,
      0.019808372482657433,
      0.04686077684164047,
      -0.001493033254519105,
      0.06930442899465561,
      0.02935906872153282,
      0.032625459134578705,
      -0.025509940460324287,
      0.0368349514901638,
      0.09206456691026688,
      -0.05674783140420914,
      0.046018436551094055,
      -0.00340694235637784,
      0.0032499167136847973,
      -0.006962158717215061,
      0.0688462108373642,
      0.0037828669883310795,
      -0.005074576009064913,
      0.06399935483932495,
      -0.08300622552633286,
      0.04252639785408974,
      0.004376017488539219,
      -0.03317053243517876,
      -0.016314132139086723,
      -0.05233322083950043,
      0.09906785935163498,
      -0.03167369216680527,
      0.04741101339459419,
      -0.061156608164310455,
      -0.05220774933695793,
      0.026321904733777046,
      -0.014567052945494652,
      0.03994789347052574,
      0.02545546554028988,
      -0.042025480419397354,
      0.029514329507946968,
      -0.010504432953894138,
      -0.03400386869907379,
      -0.014988810755312443,
      -0.018207071349024773,
      -0.002942465478554368,
      -0.058882128447294235,
      0.0016579709481447935,
      0.0022835005074739456,
      -0.0687447264790535,
      -0.03522055596113205,
      -0.015019159764051437,
      0.027797916904091835,
      0.03595471754670143,
      0.023000195622444153,
      0.03849506378173828,
      0.04248027503490448,
      -0.013046430423855782,
      0.01557652186602354,
      -0.03201080113649368,
      -0.023003971204161644,
      -0.013062439858913422,
      -0.01812872104346752,
      0.03367490693926811,
      0.014704631641507149,
      0.041860200464725494,
      -0.008155656047165394,
      0.045287951827049255,
      0.02394798956811428,
      0.0357307568192482,
      -0.03017975017428398,
      0.016259854659438133,
      -0.020653463900089264,
      -0.046468336135149,
      0.009417926892638206,
      -0.034142788499593735,
      -0.03820416331291199,
      0.11642616987228394,
      -0.015475834719836712,
      0.037461332976818085,
      0.015376175753772259,
      0.11642726510763168,
      0.011301361955702305,
      -0.07192501425743103,
      0.07276102900505066,
      -0.032729096710681915,
      0.01820763200521469,
      -0.06965062022209167,
      -0.02693052589893341,
      0.11865489929914474,
      -0.12058494240045547,
      -0.02148839831352234,
      -0.018014606088399887,
      -0.057018108665943146,
      0.02931855618953705,
      0.036064811050891876,
      0.03244452923536301,
      0.03657689318060875,
      0.04071479290723801,
      0.007518799975514412,
      -0.0170707106590271,
      -0.024591442197561264,
      -0.04082809016108513,
      0.023433370515704155,
      0.03586540371179581,
      0.05085112527012825,
      -0.042467545717954636,
      -0.07140223681926727,
      0.00649043545126915,
      -0.022862764075398445,
      0.028109412640333176,
      -0.04674955829977989,
      0.0804688036441803,
      0.057202134281396866,
      -0.06565333157777786,
      0.05752735584974289,
      -0.003155006328597665,
      -0.005043176002800465,
      -0.00807696208357811,
      -0.08403545618057251,
      -0.05426540970802307,
      0.007222223095595837,
      0.012637383304536343,
      -0.0512247160077095,
      -0.037198975682258606,
      0.06601987034082413,
      0.006195671856403351,
      0.014190019108355045,
      -0.04643445461988449,
      0.009077238850295544,
      -0.02211252599954605,
      -0.05012699216604233,
      -0.0011368179693818092,
      0.07892388105392456,
      0.0530683808028698,
      0.1170613244175911,
      0.034493401646614075,
      -0.02237587422132492,
      0.018946373835206032,
      0.07622937858104706,
      -0.025374552235007286,
      0.039440859109163284,
      0.005281356628984213,
      0.041195616126060486,
      0.05918332189321518,
      -0.04278763011097908,
      0.028149034827947617,
      -0.0029073436744511127,
      0.01560581848025322,
      0.036897338926792145,
      0.06526260077953339,
      0.050581447780132294,
      -0.021457813680171967,
      -0.0063873291946947575,
      0.0917958915233612,
      -0.049718067049980164,
      -0.03067552112042904,
      0.043809324502944946,
      0.06251717358827591,
      -0.06118185073137283,
      -0.017609626054763794,
      0.04385092109441757,
      -0.03321129456162453,
      -0.019414709880948067,
      0.037131644785404205,
      0.01617787964642048,
      -0.012190143577754498,
      0.020797578617930412,
      -0.019910700619220734,
      -0.000408405700000003,
      0.06581418961286545,
      -0.009001455269753933,
      0.04789787903428078,
      -0.02093140408396721,
      0.018433379009366035,
      0.03614262491464615,
      -0.01632656902074814,
      -0.03580277040600777,
      0.05216960981488228,
      -0.04196334257721901,
      0.08099723607301712,
      0.05594060570001602,
      0.007284792605787516,
      0.0034248880110681057,
      -0.011651046574115753,
      0.038122016936540604,
      -0.09413802623748779,
      -0.025061484426259995,
      -0.0179374311119318,
      0.08072961866855621,
      0.03372649848461151,
      0.03892585635185242,
      -0.009294722229242325,
      0.019337715581059456,
      0.04121711105108261,
      -0.045685332268476486,
      0.09539175033569336,
      0.061975713819265366,
      0.09169283509254456,
      0.07676323503255844,
      -0.010148748755455017,
      -0.028865206986665726,
      -0.031662970781326294,
      -0.03816472738981247,
      -0.04549366980791092,
      0.046726834028959274,
      -0.007537389639765024,
      -0.05308838561177254,
      -0.06564951688051224,
      -0.07433265447616577,
      0.04158215969800949,
      0.010607500560581684,
      0.03007100336253643,
      0.022163880988955498,
      -0.05851544439792633,
      0.06905262917280197,
      0.025459786877036095,
      -0.033643726259469986,
      -0.03858194500207901,
      0.0017548631876707077,
      0.013878703117370605,
      0.07319687306880951,
      0.032517749816179276,
      0.030555013567209244,
      -0.05186876282095909,
      -0.06698668003082275,
      -0.09046263247728348,
      -0.08994443714618683,
      0.004691135138273239,
      -0.01991117186844349,
      -0.026804018765687943,
      -0.008360098116099834,
      -0.047368407249450684,
      -0.03318173065781593,
      0.049338676035404205,
      -0.059762511402368546,
      0.006126337219029665,
      0.016803834587335587,
      -0.03948579356074333,
      -0.037287287414073944,
      -0.018634267151355743,
      0.002267729490995407,
      0.02003377117216587,
      -0.010101528838276863,
      0.08219636231660843,
      -0.02766396664083004,
      -0.010545688681304455,
      -0.017480013892054558,
      0.03546733781695366,
      0.1130312904715538,
      -0.06972659379243851,
      0.07330059260129929,
      -0.058622926473617554,
      0.022774405777454376,
      -0.009690949693322182,
      0.05902247130870819,
      -0.015527945011854172,
      -0.022498799487948418,
      0.04324449971318245,
      -0.06337235867977142,
      0.05965375155210495,
      -0.025377783924341202,
      0.05772767588496208,
      -0.03866511955857277,
      0.02882084622979164,
      0.001949833007529378,
      -0.04540279880166054,
      -0.03724115341901779,
      -0.01802597939968109,
      0.015542488545179367,
      -0.020386431366205215,
      -0.06802237778902054,
      -0.0022156955674290657,
      -0.08627949655056,
      0.024869278073310852,
      0.027384664863348007,
      -0.03931976854801178,
      0.03765479847788811,
      -0.060352619737386703,
      0.005675472319126129,
      0.0517917163670063,
      0.023174602538347244,
      0.045105040073394775,
      0.054271236062049866,
      0.07289984822273254,
      -0.03379082679748535,
      -0.013889444060623646,
      -0.01729341596364975,
      0.09643430262804031,
      0.038922980427742004,
      0.02496347948908806,
      -0.003655947744846344,
      0.07476983964443207,
      -0.014462489634752274,
      -0.002957903314381838,
      0.09988295286893845,
      0.0003729247546289116,
      -0.014640910550951958,
      0.0702260360121727,
      -0.060793839395046234,
      0.05234301835298538,
      0.0128181716427207,
      0.014346643351018429,
      0.03538551554083824,
      0.01754409819841385,
      -0.058058787137269974,
      -0.07278268039226532,
      -0.048210252076387405,
      -0.037210863083601,
      0.04679308086633682,
      0.0783567950129509,
      0.06093239039182663,
      0.06938444823026657,
      0.007754751481115818,
      -0.005250333808362484,
      -0.016010472550988197,
      -0.024495568126440048,
      -0.09061204642057419,
      0.007408806122839451,
      0.008602161891758442,
      0.01557257492095232,
      -0.09852833300828934,
      -0.02068030834197998,
      0.07089406996965408,
      -0.0534541942179203,
      -0.015629349276423454,
      0.018176255747675896,
      0.07000766694545746,
      0.06347186863422394,
      0.002786928089335561,
      0.03883810713887215,
      -0.00645351130515337,
      -0.05039410665631294,
      0.06726056337356567,
      0.04217630624771118,
      0.07368908822536469,
      0.0022687017917633057,
      -0.03717496991157532,
      -0.023657530546188354,
      0.03871772810816765,
      0.008944660425186157,
      -0.029497085139155388,
      -0.010873869061470032,
      0.014045335352420807,
      0.03535185754299164,
      -0.031293466687202454,
      -0.03629959374666214,
      0.006676382850855589,
      -0.013022773899137974,
      0.009634675458073616,
      -0.02907387726008892,
      -0.0004710792563855648,
      -0.021498091518878937,
      -0.0443684384226799,
      0.030682237818837166,
      0.031396668404340744,
      0.0492730475962162,
      -0.014021997340023518,
      -0.06087757647037506,
      -0.0011561742285266519,
      -0.03658537194132805,
      -0.03303452581167221,
      -0.07164406776428223,
      0.028551531955599785,
      0.020993029698729515,
      -0.03574313595890999,
      0.025457024574279785,
      -0.027085185050964355,
      0.003409853670746088,
      -0.025949914008378983,
      0.04851868748664856,
      0.0030647385865449905,
      0.02999560534954071,
      0.017191320657730103,
      0.030458632856607437,
      0.0049087428487837315,
      -0.061794914305210114,
      -0.010959266684949398,
      -0.0488295778632164,
      -0.04076497629284859,
      -0.03936075046658516,
      0.04245632886886597,
      0.00434578163549304,
      -0.07016176730394363,
      -0.05000085383653641,
      -0.01403950247913599,
      0.00478278286755085,
      0.0299660824239254,
      0.038362447172403336,
      0.04709026962518692,
      0.010441925376653671,
      -0.016089918091893196,
      0.08285658061504364,
      0.022235512733459473,
      -0.000743064156267792,
      0.03152848407626152,
      0.02153359353542328,
      -0.03234825283288956,
      -0.007219893392175436,
      0.008292235434055328,
      -0.030422167852520943,
      -0.03193777799606323,
      -0.07799168676137924,
      -0.010847371071577072,
      -0.05355622246861458,
      0.07399912178516388,
      0.05095137283205986,
      -0.019663233309984207,
      0.010981050319969654
    ],
    [
      0.03866003453731537,
      0.0254670400172472,
      0.06655457615852356,
      -0.03682786971330643,
      0.033964816480875015,
      -0.04171484708786011,
      0.021935883909463882,
      -0.009699998423457146,
      -0.026036623865365982,
      0.006516628433018923,
      0.04295450448989868,
      -0.020926116034388542,
      -0.011694563552737236,
      0.02083124779164791,
      0.047260675579309464,
      -0.051310546696186066,
      -0.007135914638638496,
      -0.026056397706270218,
      -0.07940534502267838,
      -0.027189098298549652,
      0.01570090465247631,
      -0.02915036492049694,
      -0.009131024591624737,
      0.05089707672595978,
      0.028799453750252724,
      -0.0020179131533950567,
      -0.04587320238351822,
      -0.020757444202899933,
      0.011379022151231766,
      0.05287885665893555,
      -0.03160688653588295,
      0.06683722883462906,
      0.13043317198753357,
      -0.0016454994911327958,
      -0.00016433268319815397,
      0.018033236265182495,
      -0.03987676277756691,
      0.009744877927005291,
      0.023625781759619713,
      0.06103140860795975,
      0.0007728794007562101,
      -0.037265896797180176,
      0.044114112854003906,
      -0.03706091269850731,
      -0.042994629591703415,
      0.05718623101711273,
      -0.0012921738671138883,
      -0.013799634762108326,
      0.011879971250891685,
      0.1508510559797287,
      0.004470722749829292,
      0.028905829414725304,
      0.05579569190740585,
      -0.002413955982774496,
      0.10078541189432144,
      -0.054837800562381744,
      -0.016713643446564674,
      0.01369351614266634,
      0.0016923346556723118,
      -0.08304166048765182,
      -0.00943778082728386,
      -0.02750835195183754,
      0.047229498624801636,
      -0.03812778741121292,
      -0.040301427245140076,
      -0.02608146332204342,
      0.08871162682771683,
      0.030009666457772255,
      0.008165520615875721,
      -0.05598028004169464,
      0.02287408895790577,
      0.02690577134490013,
      0.016846992075443268,
      0.02338484302163124,
      0.02162317931652069,
      -0.014613096602261066,
      -0.03520401939749718,
      -0.03909935802221298,
      -0.1219511553645134,
      0.03304873779416084,
      -0.013782542198896408,
      -0.04946648329496384,
      -0.05859445780515671,
      -0.09802743792533875,
      -0.027607085183262825,
      -0.030984558165073395,
      -0.02828105166554451,
      0.008407608605921268,
      -0.01132603082805872,
      0.07234171777963638,
      0.08777479082345963,
      0.0040427702479064465,
      -0.053840991109609604,
      -0.012223080731928349,
      0.04650247469544411,
      -0.027116626501083374,
      -0.026203662157058716,
      0.04021187499165535,
      -0.012080585584044456,
      0.07182144373655319,
      -0.040099311619997025,
      -0.08363406360149384,
      -0.03660557046532631,
      0.022067781537771225,
      -0.038760047405958176,
      -0.05036498233675957,
      0.0797610655426979,
      -0.0836988016963005,
      -0.014355587773025036,
      -0.07050124555826187,
      0.06969448179006577,
      -0.050924476236104965,
      -0.029673248529434204,
      -0.004570482298731804,
      0.006945868954062462,
      0.009963832795619965,
      -0.008992000482976437,
      -0.009387030266225338,
      0.05607467517256737,
      0.00814658310264349,
      -0.07035791873931885,
      -0.006857965607196093,
      0.039572183042764664,
      0.04750296100974083,
      -0.002946687862277031,
      0.010614447295665741,
      -0.06815088540315628,
      -0.07711295783519745,
      0.0166313536465168,
      0.037316299974918365,
      0.042956333607435226,
      -0.07019433379173279,
      -0.018697427585721016,
      -0.016340209171175957,
      0.005743313580751419,
      0.03412958234548569,
      0.0902777686715126,
      0.02606392279267311,
      0.036401256918907166,
      0.015356793068349361,
      -0.001436605234630406,
      0.03759574145078659,
      0.03913957253098488,
      -0.005887812003493309,
      -0.054567497223615646,
      5.1942988648079336e-05,
      0.0038238612469285727,
      0.045052241533994675,
      -0.038694676011800766,
      0.051141705363988876,
      0.041491277515888214,
      0.006838441360741854,
      -0.013228110037744045,
      -0.0172701608389616,
      -0.07108399271965027,
      0.02556566707789898,
      -0.002499370602890849,
      -0.026326719671487808,
      0.02155344933271408,
      0.025709209963679314,
      0.018393369391560555,
      -0.012660377658903599,
      -0.026562213897705078,
      -0.036037296056747437,
      -0.0009242868982255459,
      0.0027051963843405247,
      -0.008300644345581532,
      0.000919961603358388,
      -0.03399346023797989,
      -0.013438502326607704,
      -0.024520600214600563,
      -0.06536723673343658,
      0.07641991972923279,
      0.026425739750266075,
      0.049980852752923965,
      -0.026042912155389786,
      0.029284624382853508,
      0.03866629675030708,
      -0.00941722746938467,
      -0.0480380542576313,
      0.05040692538022995,
      0.016408860683441162,
      0.0007520571816712618,
      0.05892148241400719,
      0.0008518251124769449,
      0.04831958934664726,
      -0.035137876868247986,
      -0.019331295043230057,
      -0.004408872686326504,
      0.04086771234869957,
      0.01948733627796173,
      0.04267314448952675,
      0.03601076453924179,
      0.03652329370379448,
      -0.004336114041507244,
      0.03159265220165253,
      -0.012418083846569061,
      0.014685570262372494,
      0.0113205062225461,
      0.021133407950401306,
      -0.009946506470441818,
      0.06702861189842224,
      0.009759755805134773,
      0.02279159426689148,
      0.05601557716727257,
      -0.0072488076984882355,
      -0.011937950737774372,
      0.004354012198746204,
      0.00951427686959505,
      -0.08613917231559753,
      -0.008234265260398388,
      0.0099813062697649,
      -0.03245842829346657,
      -0.024552682414650917,
      -0.017932726070284843,
      -0.02360489033162594,
      0.02052963525056839,
      -0.02592558227479458,
      -0.04346029460430145,
      -0.007976209744811058,
      0.04452873021364212,
      0.02137443609535694,
      0.111156165599823,
      -0.028320306912064552,
      0.05889827013015747,
      -0.005257538985460997,
      0.018050162121653557,
      -0.015110083855688572,
      -0.01018198486417532,
      -0.012002786621451378,
      0.015932597219944,
      0.06491472572088242,
      -0.05013376101851463,
      -0.0136079927906394,
      -0.0647435113787651,
      -0.02963801845908165,
      0.04810616001486778,
      0.013570482842624187,
      -0.036135945469141006,
      -0.041764941066503525,
      0.04216590151190758,
      -0.08214643597602844,
      -0.038382723927497864,
      0.022651100531220436,
      0.021293094381690025,
      0.017534596845507622,
      0.05194174870848656,
      -0.01066565327346325,
      -0.008437486365437508,
      0.022416595369577408,
      -0.018964823335409164,
      0.0006769484025426209,
      0.025955839082598686,
      0.003751537296921015,
      0.0637093186378479,
      -0.03325331211090088,
      0.03509673476219177,
      -0.0024160221219062805,
      0.0667029619216919,
      0.022412635385990143,
      -0.0215010903775692,
      -0.017211871221661568,
      -0.04413576424121857,
      -0.007892034947872162,
      -0.032061684876680374,
      -0.030075738206505775,
      0.03230563551187515,
      0.018783003091812134,
      0.01498213317245245,
      0.07369019836187363,
      -0.019790390506386757,
      0.043533213436603546,
      -0.06265631318092346,
      -0.061396609991788864,
      -0.06739144772291183,
      0.05583604797720909,
      -0.030917348340153694,
      -0.004720826167613268,
      0.01846165768802166,
      -0.0763469934463501,
      -0.02181333489716053,
      0.04496648162603378,
      -0.02405918575823307,
      -0.08493805676698685,
      0.021109875291585922,
      0.06828365474939346,
      -0.018575189635157585,
      -0.09860581904649734,
      0.011057399213314056,
      0.0049980320036411285,
      -0.02552218735218048,
      0.10402783006429672,
      0.07240147143602371,
      0.003985006362199783,
      0.04493248835206032,
      0.04654540866613388,
      0.007126939948648214,
      -0.00971959251910448,
      0.012893863022327423,
      -0.0420256108045578,
      0.00716820266097784,
      0.006768501363694668,
      0.042949289083480835,
      0.03771098330616951,
      0.06415154039859772,
      0.028483279049396515,
      -0.0006640157662332058,
      -0.00898420624434948,
      -0.03324902057647705,
      -0.030534178018569946,
      -0.06266649067401886,
      0.07112180441617966,
      0.0017280542524531484,
      0.0020834559109061956,
      -0.04412950575351715,
      0.07713818550109863,
      0.05096733570098877,
      -0.08423958718776703,
      0.006459095049649477,
      0.11667165160179138,
      0.03602485731244087,
      0.04934368655085564,
      -0.07050865143537521,
      -0.02797158993780613,
      0.06690137088298798,
      0.014886520802974701,
      -0.06437571346759796,
      -0.037604041397571564,
      -0.09030118584632874,
      0.02741815149784088,
      0.0006009373464621603,
      0.024891428649425507,
      -0.044968876987695694,
      -0.03768613561987877,
      0.034987084567546844,
      -0.07305219769477844,
      0.01733199693262577,
      0.0008054411737248302,
      -0.03189966827630997,
      0.01214244682341814,
      -0.12006984651088715,
      -0.025407206267118454,
      0.0015666381223127246,
      -0.03839905932545662,
      0.03910422697663307,
      -0.016893625259399414,
      0.06890629976987839,
      -0.020004330202937126,
      0.016719773411750793,
      0.05251495912671089,
      0.031654249876737595,
      0.09675541520118713,
      -0.02256988175213337,
      0.07392655313014984,
      -0.008643369190394878,
      -0.009614936076104641,
      0.11296268552541733,
      -0.018708903342485428,
      0.018294865265488625,
      -0.01857575587928295,
      0.030394500121474266,
      0.01793387532234192,
      -0.08588220179080963,
      -0.0035852547734975815,
      -0.0221041738986969,
      0.09176256507635117,
      -0.041148893535137177,
      -0.011552073061466217,
      0.01975645311176777,
      -0.0037594011519104242,
      0.0207674503326416,
      -0.003034173510968685,
      -0.019651934504508972,
      0.0328776091337204,
      -0.04367709532380104,
      0.03339817002415657,
      -0.07373394072055817,
      -0.024482641369104385,
      -0.03705842047929764,
      -0.025894327089190483,
      0.005896601360291243,
      0.014232179149985313,
      0.06014896184206009,
      -0.022256383672356606,
      -0.0016495794989168644,
      -0.0677698478102684,
      -0.012053146958351135,
      0.0010474157752469182,
      0.052217770367860794,
      0.008444037288427353,
      -0.07638058066368103,
      -0.025262905284762383,
      -0.009705168195068836,
      -0.009833099320530891,
      -0.021320415660738945,
      -0.07149483263492584,
      0.024014359340071678,
      0.029307713732123375,
      0.10126366466283798,
      -0.05203654617071152,
      -0.05845656618475914,
      -0.03976399078965187,
      0.08951766043901443,
      -0.025286464020609856,
      -0.007450420875102282,
      0.0660446509718895,
      0.00778259988874197,
      0.0409647673368454,
      -0.023373622447252274,
      0.006806467194110155,
      0.1115802600979805,
      -0.08241783082485199,
      -0.055087488144636154,
      -0.050275467336177826,
      0.016703585162758827,
      -0.04305939003825188,
      0.00378558156080544,
      0.005633020307868719,
      -0.06427934765815735,
      0.11523684859275818,
      -0.04626394435763359,
      0.02183324471116066,
      -0.053752560168504715,
      0.012513195164501667,
      0.029060151427984238,
      -0.012800081633031368,
      0.0732223317027092,
      -0.05912778154015541,
      0.05987045541405678,
      0.030790546908974648,
      0.004814125597476959,
      0.0033004572615027428,
      -0.02299659699201584,
      -0.005810185335576534,
      -0.04156941547989845,
      -0.002306607784703374,
      0.06510699540376663,
      0.043299149721860886,
      0.020680474117398262,
      0.04033886641263962,
      0.01277602557092905,
      -0.048942163586616516,
      0.038626428693532944,
      0.05493061617016792,
      0.04710005968809128,
      0.03658023104071617,
      0.022265052422881126,
      -0.04512842372059822,
      0.03374340012669563,
      0.04971328750252724,
      -0.06685619801282883,
      0.01450229249894619,
      -0.06057848781347275,
      0.07521335780620575,
      0.023037776350975037,
      -0.009503225795924664,
      -0.009952742606401443,
      0.019695524126291275,
      -0.02545582316815853,
      -0.02565266564488411,
      0.09267950057983398,
      0.012296024709939957,
      -0.025143511593341827,
      0.007931775413453579,
      0.00029657623963430524,
      0.035493142902851105,
      -0.1323915272951126,
      -0.003935998305678368,
      -0.024219101294875145,
      0.0737079456448555,
      -0.01426581759005785,
      0.01631048321723938,
      -0.00805332139134407,
      -0.02463839203119278,
      0.12167531996965408,
      0.01385368313640356,
      0.06752639263868332,
      -0.011496421881020069,
      -0.027962636202573776,
      0.023349741473793983,
      -0.05837662145495415,
      0.013379976153373718,
      -0.0034986704122275114,
      0.004663233179599047,
      0.0033772168681025505,
      0.03247753903269768,
      0.033324044197797775,
      -0.03486664965748787,
      0.010010402649641037,
      -0.02405906468629837,
      0.002032230608165264,
      -0.029322857037186623,
      -0.0093025341629982,
      0.034322015941143036,
      0.08022689074277878,
      -0.0058949594385921955,
      0.06188524514436722,
      -0.024198558181524277,
      -0.039920270442962646,
      -0.007082832045853138,
      0.03846096247434616,
      -0.0001970282755792141,
      0.006548072211444378
    ],
    [
      -0.01701122708618641,
      -0.04613382741808891,
      -0.04676006734371185,
      0.011732164770364761,
      -0.024046413600444794,
      0.09182269126176834,
      -0.01943068765103817,
      -0.005667570978403091,
      0.006390487775206566,
      -0.04101753979921341,
      -0.041713226586580276,
      -0.026629028841853142,
      0.002222105860710144,
      -0.015179569832980633,
      -0.04600986838340759,
      -0.030984971672296524,
      0.044305551797151566,
      -0.0032237365376204252,
      -0.014940991066396236,
      0.04210381209850311,
      -0.046244703233242035,
      -0.014253220520913601,
      -0.06490442156791687,
      -0.08836963027715683,
      -0.07180066406726837,
      0.019732452929019928,
      -0.02917066588997841,
      0.002857640152797103,
      -0.0028582296799868345,
      0.010484394617378712,
      -0.016072051599621773,
      0.025139842182397842,
      0.017853131517767906,
      -0.03136153146624565,
      0.019426235929131508,
      -0.029689570888876915,
      0.05571502819657326,
      -0.02697836048901081,
      0.001753980410285294,
      0.03838740661740303,
      -0.06813882291316986,
      0.009405475109815598,
      0.008439408615231514,
      0.028668634593486786,
      0.06137017533183098,
      0.08422744274139404,
      -0.0015002487925812602,
      0.03655170276761055,
      0.00820147804915905,
      -0.04426603764295578,
      -0.0521186962723732,
      0.10686366260051727,
      -0.030358681455254555,
      0.02689342200756073,
      0.021060971543192863,
      0.0031253488268703222,
      0.0598670095205307,
      0.0061623998917639256,
      0.016133392229676247,
      0.020291894674301147,
      0.07518545538187027,
      0.040954384952783585,
      -0.004864548798650503,
      -0.004183828830718994,
      0.07899855077266693,
      -0.036025457084178925,
      0.02634759619832039,
      0.03008081391453743,
      0.05724261701107025,
      -0.011349746957421303,
      0.00012182709906483069,
      -0.01697833463549614,
      -0.03234054520726204,
      -0.09119982272386551,
      -0.05615080147981644,
      -0.023081570863723755,
      -0.012283972464501858,
      -0.053636495023965836,
      -0.03924211859703064,
      0.0020187199115753174,
      0.01321630273014307,
      0.038007356226444244,
      -0.012866528704762459,
      0.024659190326929092,
      0.019017783924937248,
      0.0216352678835392,
      -0.002584767760708928,
      0.03930210322141647,
      -0.03664513677358627,
      0.06438504904508591,
      0.035578493028879166,
      -0.0030910668428987265,
      0.04822585731744766,
      0.02618054486811161,
      0.023563187569379807,
      -0.012388099916279316,
      0.023027800023555756,
      0.017092444002628326,
      -0.022425208240747452,
      0.029362380504608154,
      -0.04503479227423668,
      0.037613507360219955,
      0.018706653267145157,
      -0.0404062420129776,
      -0.06960424035787582,
      -0.0469483807682991,
      0.00821218453347683,
      0.04863809049129486,
      -0.038045480847358704,
      -0.03256174921989441,
      0.03639429435133934,
      -0.06593810021877289,
      -0.0326918326318264,
      0.03275299817323685,
      0.059366799890995026,
      0.005467506591230631,
      0.014101441949605942,
      0.035282257944345474,
      -0.05658852681517601,
      0.03440852835774422,
      -0.03599622845649719,
      0.04143388941884041,
      0.01653849333524704,
      0.07740247249603271,
      0.024155739694833755,
      -0.07486268877983093,
      -0.0029150238260626793,
      0.04427046701312065,
      -0.01532017346471548,
      -0.07285023480653763,
      0.013264610432088375,
      -0.006272684317082167,
      -0.024784568697214127,
      0.014345140196383,
      0.052213042974472046,
      -0.0329078771173954,
      0.05470622703433037,
      0.05022546648979187,
      0.029077986255288124,
      -0.012430691160261631,
      -0.03127552941441536,
      -0.026154475286602974,
      -0.05119111388921738,
      0.0021821800619363785,
      -0.037746649235486984,
      -0.03666972368955612,
      -0.03581368923187256,
      -0.02430044487118721,
      -0.035577740520238876,
      -0.049070149660110474,
      -0.08337868750095367,
      -0.06830894201993942,
      0.05071181431412697,
      0.06091838330030441,
      0.01286627072840929,
      0.13716694712638855,
      -0.012052061036229134,
      -0.06853310763835907,
      -0.026479722931981087,
      0.03051115944981575,
      0.08082861453294754,
      -0.05449170246720314,
      -0.033802155405282974,
      0.031076136976480484,
      0.05562316253781319,
      0.03094945289194584,
      0.025071052834391594,
      0.034532271325588226,
      -0.05314462259411812,
      -0.016350597143173218,
      -0.01615840569138527,
      0.040132034569978714,
      0.05113938823342323,
      0.056686390191316605,
      0.008352397941052914,
      0.07467952370643616,
      -0.0104375584051013,
      -0.007492152042686939,
      0.040128231048583984,
      -0.03433141112327576,
      -0.006426239386200905,
      -0.03261785954236984,
      -0.030231786891818047,
      -0.021221499890089035,
      0.007260905113071203,
      -0.09731655567884445,
      -0.07959642261266708,
      -0.02593465894460678,
      -0.09307835251092911,
      0.005632540211081505,
      -0.07462236285209656,
      -0.00033345058909617364,
      -0.06782524287700653,
      -0.026810703799128532,
      -0.0017471314640715718,
      0.052771128714084625,
      -0.012851777486503124,
      -0.10764909535646439,
      -0.01010683923959732,
      0.028481975197792053,
      -0.055412616580724716,
      -0.02663741074502468,
      -0.02198718674480915,
      -0.054801810532808304,
      -0.025702599436044693,
      0.05213107541203499,
      0.025144100189208984,
      0.012481671757996082,
      0.013735434040427208,
      -0.02669692412018776,
      0.03505856916308403,
      -0.030409712344408035,
      -0.017552951350808144,
      -0.08385882526636124,
      0.10975784063339233,
      0.06665866822004318,
      0.024627022445201874,
      0.03443281352519989,
      0.020416300743818283,
      -0.05777973309159279,
      0.06706265360116959,
      -0.09068185091018677,
      -0.04455328732728958,
      -0.05071387439966202,
      0.08910580724477768,
      0.005256634671241045,
      0.07679006457328796,
      -0.08849228918552399,
      0.007199431303888559,
      0.04662559926509857,
      0.05118495970964432,
      -0.02822713926434517,
      -0.011300892569124699,
      0.07485774904489517,
      -0.03650650754570961,
      -0.04476721212267876,
      0.055291686207056046,
      0.01913793571293354,
      -0.0022281832061707973,
      0.01128351129591465,
      -0.010451079346239567,
      -0.03548869863152504,
      -0.024026410654187202,
      -0.04192686453461647,
      -0.028886083513498306,
      -0.03042236715555191,
      -0.04650885984301567,
      -0.027949225157499313,
      -0.053180400282144547,
      0.062354277819395065,
      0.002210769336670637,
      -0.03305751085281372,
      -0.014499267563223839,
      0.012474637478590012,
      0.06676792353391647,
      0.044895146042108536,
      -0.011305168271064758,
      0.003963239025324583,
      -0.059149209409952164,
      -0.00871677789837122,
      -0.004643191117793322,
      -0.03679198399186134,
      0.06967593729496002,
      0.05953323841094971,
      0.027207886800169945,
      -0.07302406430244446,
      0.05944151431322098,
      -0.03161250799894333,
      0.011994920670986176,
      0.06342931091785431,
      0.009575163945555687,
      0.0225974153727293,
      0.024497201666235924,
      -0.04492823779582977,
      -0.03256377950310707,
      0.026551425457000732,
      0.09074797481298447,
      -0.04547092318534851,
      -0.015691887587308884,
      -0.017925983294844627,
      -0.09418285638093948,
      -0.07307861000299454,
      -0.03429605811834335,
      0.025337280705571175,
      -0.016108429059386253,
      0.0011070769978687167,
      -0.015825001522898674,
      -0.03259361907839775,
      0.07300356030464172,
      -0.01837727427482605,
      -0.027454130351543427,
      -0.017565516754984856,
      0.03698161616921425,
      -0.009229764342308044,
      -0.013524987734854221,
      -0.03374481946229935,
      -0.04948978126049042,
      0.03828398883342743,
      -0.006686629727482796,
      -0.03729599341750145,
      0.09215236455202103,
      0.042312052100896835,
      0.06702584028244019,
      0.07154298573732376,
      -0.0030035481322556734,
      0.022938529029488564,
      -0.05498263239860535,
      -0.02857924811542034,
      0.013039209879934788,
      -0.014011956751346588,
      0.022775923833251,
      0.014465882442891598,
      0.037101536989212036,
      0.06305595487356186,
      -0.09843470901250839,
      -0.020092185586690903,
      0.08459081500768661,
      0.023621609434485435,
      0.048013027757406235,
      -0.040037814527750015,
      -0.005221620202064514,
      -0.024820759892463684,
      0.03818335756659508,
      0.04900602996349335,
      0.02881881222128868,
      -0.013929091393947601,
      0.02194260060787201,
      0.036536503583192825,
      -0.09022405743598938,
      0.011014135554432869,
      -0.02728605456650257,
      -0.0643848329782486,
      0.007565175648778677,
      0.0670454204082489,
      -0.04384332150220871,
      0.018208714202046394,
      -0.01334291696548462,
      -0.029946105554699898,
      0.027506815269589424,
      -0.020343037322163582,
      -0.049837350845336914,
      0.005781908519566059,
      0.03262434899806976,
      0.05458701029419899,
      0.018849432468414307,
      0.01858573593199253,
      0.04236612841486931,
      0.05392909795045853,
      -0.04028616473078728,
      -0.06261682510375977,
      0.027852322906255722,
      0.029686447232961655,
      0.042541198432445526,
      0.021376529708504677,
      0.020059309899806976,
      -0.006190954707562923,
      0.002688961336389184,
      -0.0026031285524368286,
      0.00010063156514661387,
      -0.03249279037117958,
      -0.030179087072610855,
      -0.026130706071853638,
      -0.04860151186585426,
      -0.06435880810022354,
      -0.06072403863072395,
      -0.041032783687114716,
      0.012038208544254303,
      -0.06634615361690521,
      0.016797814518213272,
      0.008186698891222477,
      -0.004200610797852278,
      0.025844478979706764,
      -0.002646055771037936,
      -0.008907252922654152,
      -0.07923094183206558,
      0.05963931605219841,
      0.038510795682668686,
      0.004685550928115845,
      -0.0148717500269413,
      -0.031047683209180832,
      -0.009440727531909943,
      0.06376954168081284,
      0.08829203993082047,
      -0.04356564208865166,
      0.04550222307443619,
      0.09138389676809311,
      -0.04599125683307648,
      0.012722422368824482,
      0.024764759466052055,
      0.013597678393125534,
      0.022699326276779175,
      0.01622627303004265,
      0.012991930358111858,
      -0.0658063217997551,
      0.0452185794711113,
      0.024515656754374504,
      0.004211442545056343,
      -0.059909578412771225,
      0.06334663182497025,
      0.06899544596672058,
      0.03375036269426346,
      0.014913562685251236,
      0.021990260109305382,
      -0.011718678288161755,
      -0.05510496348142624,
      0.03308669105172157,
      -0.026303239166736603,
      -0.001913628657348454,
      0.08676698058843613,
      -0.027979271486401558,
      -0.021033886820077896,
      -0.00686822272837162,
      0.06880693882703781,
      0.028645141050219536,
      0.032173510640859604,
      0.01262378878891468,
      0.10859180986881256,
      -0.014776202850043774,
      -0.0017257168656215072,
      0.006192595697939396,
      0.09306714683771133,
      0.04847259074449539,
      -0.009353875182569027,
      0.05476557835936546,
      0.037731487303972244,
      0.05059026926755905,
      -0.05321841686964035,
      -0.026019707322120667,
      0.04884465038776398,
      0.023601293563842773,
      -0.03986259549856186,
      0.012161068618297577,
      -0.04931081086397171,
      -0.051919855177402496,
      0.012651177123188972,
      -0.05657516047358513,
      -0.015028082765638828,
      0.004700383637100458,
      -0.003028591861948371,
      0.0446770042181015,
      -0.03334393724799156,
      0.005717492662370205,
      -0.003719395026564598,
      0.0822475329041481,
      -0.023104319348931313,
      -0.0694010853767395,
      -0.0805564597249031,
      0.021967893466353416,
      -0.004528299439698458,
      -0.03918635472655296,
      -0.011775398626923561,
      -0.06989627331495285,
      -0.06884569674730301,
      -0.02909035049378872,
      -0.014058221131563187,
      -0.019222861155867577,
      -0.022531839087605476,
      -0.025705453008413315,
      -0.034968260675668716,
      -0.002902915934100747,
      -0.05253968387842178,
      0.052079398185014725,
      0.021821551024913788,
      -0.024906190112233162,
      -0.006029405165463686,
      -0.026923788711428642,
      0.020463090389966965,
      -0.10756616294384003,
      0.015928342938423157,
      -0.06814666837453842,
      0.09482791274785995,
      0.06186810880899429,
      -0.019130636006593704,
      -0.015596384182572365,
      0.06038824841380119,
      0.004949948750436306,
      0.08416882157325745,
      0.053357139229774475,
      -0.02902514487504959,
      0.02713172137737274,
      -0.04741908237338066,
      0.024040136486291885,
      -0.004279032349586487,
      -0.01814839616417885,
      -0.05734562501311302,
      -0.07800794392824173,
      0.008802363649010658,
      0.02657337486743927,
      -0.05647820606827736,
      -0.017379652708768845,
      0.012968440540134907,
      0.027061935514211655,
      0.03756682202219963,
      0.013510623946785927,
      -3.5153872886439785e-05,
      -0.07587230950593948,
      -0.019630538299679756,
      0.018526218831539154,
      -0.021750133484601974,
      -0.08117514103651047,
      -0.020468950271606445,
      0.028934551402926445,
      -0.0050382474437355995
    ],
    [
      0.022408317774534225,
      0.03500567749142647,
      -0.05456019565463066,
      -0.018264366313815117,
      0.004085318185389042,
      -0.022110890597105026,
      -0.059343840926885605,
      -0.01222859788686037,
      0.021441759541630745,
      -0.089285708963871,
      0.0012250222498551011,
      0.02799343504011631,
      -0.04579378291964531,
      -0.04667919501662254,
      -0.04350657761096954,
      0.0012038757558912039,
      -0.023343976587057114,
      0.0007939336355775595,
      -0.020879914984107018,
      -0.08509774506092072,
      -0.013891516253352165,
      -0.025270799174904823,
      -0.06152169778943062,
      -0.0885227769613266,
      0.0665394589304924,
      -0.06282325088977814,
      -0.003438616869971156,
      -0.026935981586575508,
      -0.014893593266606331,
      0.07969768345355988,
      0.0002930880291387439,
      0.02014441043138504,
      0.007490609772503376,
      -0.034066032618284225,
      -0.0278159212321043,
      0.0640193521976471,
      -0.07015162706375122,
      -0.025189252570271492,
      -0.00732679245993495,
      0.015770139172673225,
      -0.0240850280970335,
      0.029658181592822075,
      -0.017747770994901657,
      -0.02885834500193596,
      0.04649261012673378,
      0.03703130781650543,
      0.0038023057859390974,
      -0.01763191446661949,
      -0.01796514354646206,
      0.07315468788146973,
      -0.045804642140865326,
      0.050085458904504776,
      -0.016022110357880592,
      -0.06625965237617493,
      0.0018546052742749453,
      -0.00676828483119607,
      0.026474198326468468,
      0.008158712647855282,
      -0.08292209357023239,
      0.013751358725130558,
      -0.03618038073182106,
      -0.0013664911966770887,
      0.04203515872359276,
      -0.029403964057564735,
      0.07453706115484238,
      -0.009527440182864666,
      0.0007970370352268219,
      -0.05994458124041557,
      -0.005956745240837336,
      -0.07547835260629654,
      -0.04005082696676254,
      -0.0406460277736187,
      0.039326008409261703,
      -0.07051519304513931,
      -0.0004511635343078524,
      -0.031132897362113,
      0.016990473493933678,
      -0.0326128713786602,
      -0.002868609270080924,
      0.01654145121574402,
      0.022533327341079712,
      0.02785622514784336,
      0.04917782545089722,
      -0.028570370748639107,
      -0.04189486801624298,
      -0.03494080901145935,
      0.06450483947992325,
      -0.012192636728286743,
      0.03211580216884613,
      -0.004203529562801123,
      0.03928413987159729,
      -0.012423968873918056,
      0.06660141050815582,
      0.010038521140813828,
      0.0646330937743187,
      0.03363014757633209,
      0.07254280894994736,
      0.01152733899652958,
      -0.0386267714202404,
      0.056914497166872025,
      -0.023355757817626,
      0.0916932076215744,
      -0.0022798378486186266,
      0.037742968648672104,
      0.02002180740237236,
      -0.06264908611774445,
      -0.0045488011091947556,
      0.02723969891667366,
      -0.07321444153785706,
      0.02310960367321968,
      0.09952639788389206,
      -0.07048662006855011,
      0.05366537719964981,
      -0.05742490291595459,
      -0.06897864490747452,
      -0.09271842241287231,
      0.058567117899656296,
      0.023714492097496986,
      0.014760023914277554,
      -0.06329607963562012,
      -0.03706701099872589,
      -0.02044745720922947,
      0.014102485962212086,
      -0.020661108195781708,
      0.009343261830508709,
      -0.01180137787014246,
      -0.016081031411886215,
      -0.0037769735790789127,
      0.017375867813825607,
      0.01384754665195942,
      -0.07610201090574265,
      0.01537195686250925,
      -0.010455840267241001,
      0.06711716949939728,
      0.10081295669078827,
      -0.011170833371579647,
      -0.031118685379624367,
      0.009955449029803276,
      -0.02456885389983654,
      0.012640994973480701,
      -0.06989161670207977,
      0.05780550464987755,
      -0.01875605247914791,
      -0.05751097574830055,
      -0.04104536399245262,
      0.0427979938685894,
      -0.024317387491464615,
      -0.029653940349817276,
      0.022896382957696915,
      -0.06664754450321198,
      -0.0034297259990125895,
      0.019065530970692635,
      -0.003983885981142521,
      0.043697670102119446,
      0.027697233483195305,
      -0.031747668981552124,
      0.05549292638897896,
      -0.050575096160173416,
      0.0031987223774194717,
      0.032332275062799454,
      -0.0527290441095829,
      0.017470717430114746,
      -0.05020034685730934,
      -0.003289466956630349,
      0.004506600555032492,
      -0.007564431056380272,
      -0.024044053629040718,
      0.03002418950200081,
      -0.0360364131629467,
      0.016649071127176285,
      0.01989198848605156,
      0.025606678798794746,
      -0.010505896992981434,
      0.03523258492350578,
      0.06116068735718727,
      0.03108624741435051,
      -0.041397031396627426,
      -0.03891456499695778,
      0.03543132543563843,
      -0.06934230774641037,
      0.038300201296806335,
      -0.0008096398669295013,
      0.017610030248761177,
      0.022278916090726852,
      -0.1002991646528244,
      -0.04348524287343025,
      -0.010819009505212307,
      0.05490527302026749,
      0.016642408445477486,
      0.04962315037846565,
      0.037495557218790054,
      0.030825084075331688,
      0.10982845723628998,
      -0.008792711421847343,
      -0.008751419372856617,
      -0.024993324652314186,
      0.06900758296251297,
      -0.037024352699518204,
      0.03345761448144913,
      0.06118767336010933,
      -0.05429011210799217,
      0.007725703530013561,
      0.03249895200133324,
      0.07230623066425323,
      -0.048234015703201294,
      0.026630084961652756,
      -0.05148928612470627,
      0.012637406587600708,
      -0.054368019104003906,
      0.006433676928281784,
      -0.00903253722935915,
      0.03453322872519493,
      -0.008672860451042652,
      0.04483168572187424,
      0.030678320676088333,
      -0.05311092734336853,
      0.06642457842826843,
      0.0046309540048241615,
      -0.03405157849192619,
      0.04733968898653984,
      -0.05173119530081749,
      0.005273884162306786,
      0.008352725766599178,
      0.03536997735500336,
      0.03821220621466637,
      -0.02591465599834919,
      0.02013935148715973,
      -0.024070557206869125,
      -0.0470535047352314,
      0.025351617485284805,
      -0.03388753905892372,
      0.048999104648828506,
      -0.017622333019971848,
      0.03412051498889923,
      0.03383908420801163,
      0.007410862483084202,
      -0.009265609085559845,
      -0.04641595482826233,
      0.018265116959810257,
      0.0157249104231596,
      0.06263123452663422,
      0.013358254916965961,
      -0.003239421173930168,
      -0.017674848437309265,
      -0.02121003158390522,
      -0.001386137562803924,
      0.02752060815691948,
      -0.035304002463817596,
      -0.05724167823791504,
      -0.07417012751102448,
      -0.01681436598300934,
      0.0026954265777021646,
      0.01789928413927555,
      0.02630598284304142,
      0.035880155861377716,
      0.03304180130362511,
      0.0007952474406920373,
      0.013086589984595776,
      -0.015225155279040337,
      -0.010677891783416271,
      0.0064733559265732765,
      -0.003914557863026857,
      -0.052704840898513794,
      0.015353966504335403,
      -0.044092293828725815,
      -0.027437089011073112,
      -0.037359386682510376,
      -0.08987541496753693,
      -0.02720755524933338,
      0.019122110679745674,
      0.0070897312834858894,
      -0.00707052368670702,
      0.006739484611898661,
      0.0065392679534852505,
      0.015265469439327717,
      -0.07233019173145294,
      -0.01681538112461567,
      -0.029491707682609558,
      0.010306174866855145,
      0.037765875458717346,
      -0.016077198088169098,
      -0.03981906548142433,
      0.004313265439122915,
      -0.009716503322124481,
      -0.008159897290170193,
      0.04221910983324051,
      -0.012891250662505627,
      -0.07496751844882965,
      0.031703438609838486,
      0.0064418865367770195,
      0.031033413484692574,
      -0.06903503835201263,
      0.0077744293957948685,
      -0.06254823505878448,
      -0.011276830919086933,
      -0.04352394491434097,
      -0.04408423975110054,
      0.048756055533885956,
      0.002273031510412693,
      -0.025136806070804596,
      -0.02852093055844307,
      -0.016699600964784622,
      -0.07377085089683533,
      0.020248424261808395,
      0.015366659499704838,
      0.025173155590891838,
      0.010544877499341965,
      -0.04219672456383705,
      0.031499624252319336,
      -0.030798176303505898,
      0.03456813842058182,
      -0.07495368272066116,
      0.00808880664408207,
      -0.040028076618909836,
      -0.012401249259710312,
      0.09375996142625809,
      0.03516334667801857,
      -0.008858309127390385,
      0.011487511917948723,
      0.000891522504389286,
      0.10139064490795135,
      0.05053490400314331,
      0.0020626026671379805,
      0.010694299824535847,
      0.05492517352104187,
      -0.03759654611349106,
      0.003860470373183489,
      0.03384048491716385,
      0.005809720139950514,
      0.02682790718972683,
      0.018488600850105286,
      0.04397786781191826,
      0.006134674418717623,
      -0.03829123452305794,
      -0.041976846754550934,
      0.015290606766939163,
      -0.05810493975877762,
      -0.057034511119127274,
      -0.036511100828647614,
      -0.04797212779521942,
      -0.04086236283183098,
      -3.478764119790867e-05,
      -0.043279439210891724,
      0.007114732172340155,
      0.03754938021302223,
      0.03316035121679306,
      -0.009511149488389492,
      -0.043902263045310974,
      -0.01765136420726776,
      -0.10108336061239243,
      0.03850216045975685,
      -0.05682530254125595,
      -0.028626088052988052,
      0.05446666106581688,
      0.01584733836352825,
      0.01830422692000866,
      0.029372092336416245,
      0.014419223181903362,
      -0.017301121726632118,
      -0.01920301653444767,
      0.026542114093899727,
      -0.007320601958781481,
      0.012453209608793259,
      -0.01143738441169262,
      -0.03390812873840332,
      0.035722605884075165,
      0.04445020481944084,
      0.07544657588005066,
      0.023258637636899948,
      0.007400063797831535,
      -0.048257555812597275,
      0.01730266958475113,
      0.0735836997628212,
      0.021245595067739487,
      0.055555276572704315,
      0.024487541988492012,
      0.0011008121073246002,
      0.01638168655335903,
      0.024827605113387108,
      0.13508901000022888,
      0.00947860162705183,
      0.0003880232397932559,
      -0.023350603878498077,
      0.009942082688212395,
      0.057609546929597855,
      0.02357606217265129,
      0.04260454699397087,
      -0.028939716517925262,
      -0.04128769040107727,
      0.00013684859732165933,
      0.05455032363533974,
      0.032356563955545425,
      -0.04585198312997818,
      -0.0052154334262013435,
      -0.02463148906826973,
      0.05154094472527504,
      -0.0024846168234944344,
      -0.026315173134207726,
      0.027915725484490395,
      0.020120220258831978,
      -0.018669631332159042,
      0.05495454743504524,
      -0.0246536023914814,
      -0.047297604382038116,
      -0.030658923089504242,
      -0.04246336966753006,
      0.04652099683880806,
      -0.02105819061398506,
      -0.03063272312283516,
      -0.0987541601061821,
      -0.08577195554971695,
      -0.05351489782333374,
      0.004528523422777653,
      0.06286097317934036,
      -0.01870347373187542,
      0.021259140223264694,
      -0.08576925098896027,
      0.034567128866910934,
      -0.03013678267598152,
      -0.01087990403175354,
      -0.004361296072602272,
      0.002624429762363434,
      -0.038557663559913635,
      -0.06280980259180069,
      0.04247480258345604,
      -0.015930697321891785,
      -0.09526750445365906,
      -0.018778756260871887,
      -0.03852931782603264,
      -0.008633164688944817,
      0.01830574870109558,
      0.03024672530591488,
      -0.026671655476093292,
      0.06893137097358704,
      -0.010942943394184113,
      0.029491787776350975,
      0.10619338601827621,
      0.046008966863155365,
      -0.00495414063334465,
      0.0041352235712111,
      -0.0066051967442035675,
      0.012538019567728043,
      0.016316086053848267,
      0.028446828946471214,
      -0.03575265407562256,
      -0.008266611956059933,
      -0.012407844886183739,
      0.03321933001279831,
      0.01734059862792492,
      -0.0025220783427357674,
      0.0483287088572979,
      -0.01749418117105961,
      0.018407758325338364,
      -0.0038695470429956913,
      -0.004882496781647205,
      -0.04966016486287117,
      -0.1118762344121933,
      -0.0031001614406704903,
      -0.0021658907644450665,
      -0.0028926159720867872,
      0.010381487198174,
      -0.053814277052879333,
      -0.006941942498087883,
      -0.0018359089735895395,
      -0.0032330600079149008,
      0.06833622604608536,
      0.04883669316768646,
      -0.04325486719608307,
      -0.004512996878474951,
      -0.0048571364022791386,
      -0.020249083638191223,
      -0.12353675812482834,
      -0.0296965092420578,
      0.01813216507434845,
      0.05151929333806038,
      0.08806446939706802,
      -0.019411608576774597,
      -0.02197583205997944,
      0.04324507340788841,
      -0.06392530351877213,
      0.015458737500011921,
      -0.04218834266066551,
      0.05045265331864357,
      0.0483049601316452,
      -0.0006410777568817139,
      -0.059846822172403336,
      0.02595146745443344,
      0.01220663357526064,
      -0.009403570555150509,
      -0.014465355314314365,
      -0.02009490691125393,
      0.048640139400959015,
      -0.006479165051132441,
      0.08395540714263916,
      0.02084394171833992,
      -0.06570911407470703,
      -0.04778711125254631,
      -0.03329121321439743,
      -0.057915013283491135,
      0.01950165256857872,
      0.060464046895504,
      0.015927821397781372,
      0.0061639295890927315
    ],
    [
      0.02553907409310341,
      0.014859958551824093,
      0.07192099094390869,
      0.10265873372554779,
      0.006800297647714615,
      -0.001832190784625709,
      -0.029085012152791023,
      0.0016555549809709191,
      -0.0019970776047557592,
      -0.09734415262937546,
      -0.004960755351930857,
      0.026843547821044922,
      0.014089514501392841,
      0.015732308849692345,
      -0.01512373611330986,
      0.003459123196080327,
      0.09129337966442108,
      0.014271349646151066,
      -0.06059948354959488,
      -0.012597408145666122,
      0.011559396050870419,
      0.08629109710454941,
      -0.0548245906829834,
      -0.06977847218513489,
      0.16044989228248596,
      0.038344599306583405,
      -0.008625580929219723,
      -0.0392179973423481,
      -0.005569902714341879,
      -0.0031544449739158154,
      0.020107896998524666,
      0.07845639437437057,
      -0.05676267668604851,
      -0.04955220967531204,
      0.009203697554767132,
      -0.014079034328460693,
      -0.016046985983848572,
      -0.009802870452404022,
      -0.06244964152574539,
      -0.08338773995637894,
      0.017879631370306015,
      0.0037486874498426914,
      -0.02142311818897724,
      0.03242840990424156,
      0.008036831393837929,
      -0.02759144827723503,
      -0.03329147398471832,
      0.024824872612953186,
      0.03750680759549141,
      0.025420906022191048,
      5.411459642346017e-05,
      0.009885605424642563,
      -0.06181677058339119,
      -0.03388844430446625,
      0.012843156233429909,
      8.983669977169484e-05,
      -0.008339380845427513,
      -0.012872567400336266,
      -0.0034337572287768126,
      -0.027537640184164047,
      0.09252393990755081,
      -0.0433635488152504,
      0.10478752106428146,
      -0.007333681918680668,
      -0.016991635784506798,
      -0.0589313767850399,
      0.014024871401488781,
      -0.05786944925785065,
      -0.01640697941184044,
      0.054978035390377045,
      0.008380680344998837,
      -0.03677617013454437,
      0.0022941138595342636,
      -0.021255413070321083,
      -0.00036987429484725,
      -0.020899267867207527,
      -0.04060283303260803,
      -0.030282482504844666,
      0.007831435650587082,
      -0.028407994657754898,
      0.003502315143123269,
      -0.029827415943145752,
      -0.03670430928468704,
      0.005201163236051798,
      0.023269059136509895,
      -0.05415574833750725,
      0.01548534445464611,
      -0.012086248956620693,
      0.0019046412780880928,
      -0.03285617008805275,
      -0.021806474775075912,
      0.0032821097411215305,
      0.06701046973466873,
      0.011408988386392593,
      0.03441374748945236,
      0.09130845963954926,
      -0.009625213220715523,
      0.021333390846848488,
      0.030540501698851585,
      0.07828261703252792,
      -0.05380634963512421,
      -0.08972006291151047,
      -0.011728312820196152,
      0.01275727804750204,
      -0.005034815054386854,
      -0.019095316529273987,
      -0.01134289987385273,
      -0.04462164640426636,
      -0.10010644793510437,
      -0.039392732083797455,
      0.011657841503620148,
      0.009363273158669472,
      0.06495597213506699,
      0.045126672834157944,
      0.014835184440016747,
      -0.02729203924536705,
      -0.04393064230680466,
      0.008971708826720715,
      0.03362838551402092,
      -0.07279151678085327,
      0.01681739278137684,
      -0.025456558912992477,
      0.07100018858909607,
      -0.016836609691381454,
      -0.0001382125192321837,
      0.03769904747605324,
      0.05399998649954796,
      0.023268988355994225,
      0.042309846729040146,
      -0.07670557498931885,
      0.043616555631160736,
      -0.04400084912776947,
      -0.08959140628576279,
      -0.0806933343410492,
      0.045240696519613266,
      0.05620059743523598,
      -0.05305124819278717,
      -0.02836049161851406,
      -0.08169674873352051,
      0.02408314310014248,
      0.031928930431604385,
      -0.002867637202143669,
      -0.07723665237426758,
      -0.0022553326562047005,
      -0.08762449026107788,
      0.01852189563214779,
      0.05325291305780411,
      -0.0031846414785832167,
      -0.060179486870765686,
      -0.043875738978385925,
      -0.051398567855358124,
      0.01304051373153925,
      -0.019621627405285835,
      -0.026967229321599007,
      0.023168368265032768,
      -0.005357074551284313,
      0.01854335144162178,
      0.008715283125638962,
      -0.03596310317516327,
      0.0012557514710351825,
      -0.033538684248924255,
      -0.04202859103679657,
      -0.04441322386264801,
      0.06068730354309082,
      0.033888716250658035,
      0.05896572023630142,
      0.035139478743076324,
      0.01952134259045124,
      -0.003496514167636633,
      -0.11135934293270111,
      0.009719801135361195,
      0.011424900032579899,
      -0.008600156754255295,
      0.07587312161922455,
      0.02247941493988037,
      -0.03426305204629898,
      -0.07256650179624557,
      -0.02524804323911667,
      0.01289492566138506,
      0.04586472734808922,
      0.005670623853802681,
      0.04280940070748329,
      0.08246873319149017,
      -0.004619988612830639,
      0.014054535888135433,
      0.05058152601122856,
      -0.06007634475827217,
      -0.019446276128292084,
      -0.020475106313824654,
      -0.08840639889240265,
      -0.10082348436117172,
      0.018390627577900887,
      -0.004353300668299198,
      -0.1161055639386177,
      0.0012314178748056293,
      -0.031945161521434784,
      0.02350470796227455,
      -0.052371587604284286,
      0.016696415841579437,
      0.03510172665119171,
      0.02663250081241131,
      -0.06010717153549194,
      -0.027052057906985283,
      -0.019378408789634705,
      0.09064885973930359,
      -0.06136429309844971,
      -0.017468661069869995,
      -0.04349923133850098,
      -0.029933417215943336,
      0.04038185998797417,
      0.014345000497996807,
      -0.012099904008209705,
      -0.01199391670525074,
      -0.009550277143716812,
      0.006547458469867706,
      -0.03697395324707031,
      0.00763864116743207,
      0.042993273586034775,
      -0.05276873707771301,
      0.03086855635046959,
      -0.039963893592357635,
      0.0023695598356425762,
      -0.04624247923493385,
      0.06011771038174629,
      0.007886074483394623,
      -0.03259936347603798,
      -0.054177138954401016,
      -0.02563868835568428,
      0.02612951584160328,
      0.0039535281248390675,
      0.07720521837472916,
      0.07195887714624405,
      -0.007069571875035763,
      0.03939692676067352,
      0.07015080749988556,
      0.038221146911382675,
      0.028726382181048393,
      0.03324784338474274,
      0.03734062612056732,
      -0.015614197589457035,
      0.049331050366163254,
      -0.06822512298822403,
      0.029886722564697266,
      0.035836413502693176,
      0.026753844693303108,
      -0.07581496983766556,
      -0.05289154127240181,
      -0.08777644485235214,
      -0.013361956924200058,
      0.01688997633755207,
      0.015696808695793152,
      0.00988494511693716,
      -0.08183667063713074,
      -0.07943494617938995,
      0.028712553903460503,
      -0.039033155888319016,
      -0.01443532481789589,
      0.012965193018317223,
      -0.012136113829910755,
      0.0751665011048317,
      0.03886362537741661,
      0.07469359040260315,
      0.011608796194195747,
      0.003225175430998206,
      -0.041133299469947815,
      -0.01937130093574524,
      0.00821267906576395,
      0.04907504841685295,
      -0.028389131650328636,
      0.027739262208342552,
      0.02618352696299553,
      0.11378319561481476,
      0.07202914357185364,
      -0.04972539842128754,
      -0.009892093949019909,
      -0.013238477520644665,
      0.024792682379484177,
      0.09062276780605316,
      -0.06559889018535614,
      0.053482916206121445,
      -0.04576407000422478,
      -0.017215769737958908,
      0.10497857630252838,
      0.02590726688504219,
      0.0002448173181619495,
      0.06353499740362167,
      -0.03112991899251938,
      -0.06442642956972122,
      0.006833742838352919,
      0.00977576244622469,
      -0.028937624767422676,
      0.02194463089108467,
      -0.05907724052667618,
      -0.053637001663446426,
      -0.09779344499111176,
      -0.029992543160915375,
      -0.04495733603835106,
      0.027052806690335274,
      -0.028847454115748405,
      -0.07682996988296509,
      0.034992069005966187,
      0.012617957778275013,
      -0.030772317200899124,
      0.046620242297649384,
      -0.027317823842167854,
      -0.001446235692128539,
      0.009954065084457397,
      0.021178018301725388,
      -0.041256844997406006,
      0.0262758806347847,
      -0.0338943749666214,
      0.03750879690051079,
      -0.05228069797158241,
      0.07403988391160965,
      -0.04616282507777214,
      -0.0005779553321190178,
      -0.012016664259135723,
      0.04328546300530434,
      0.01124553196132183,
      0.012811638414859772,
      0.006578265223652124,
      0.03370111435651779,
      -0.027152253314852715,
      -0.04336405545473099,
      0.01579170860350132,
      -0.0738939568400383,
      0.019933143630623817,
      0.015124558471143246,
      0.05427871644496918,
      -0.0011890263995155692,
      -0.023936208337545395,
      0.006547240074723959,
      0.01443138625472784,
      0.05751107260584831,
      0.0017746215453371406,
      0.0037932901177555323,
      0.003238511038944125,
      -0.0033542909659445286,
      -0.06508636474609375,
      -0.08193634450435638,
      0.0013135805493220687,
      0.008569031953811646,
      0.04074019193649292,
      0.038126733154058456,
      0.018433252349495888,
      -0.02316451631486416,
      0.0076870545744895935,
      0.038123276084661484,
      0.048092544078826904,
      0.014631924219429493,
      0.07707760483026505,
      0.03764328360557556,
      0.031828392297029495,
      -0.046566132456064224,
      -0.01580907218158245,
      -0.0047842408530414104,
      -0.06908213347196579,
      0.030998913571238518,
      -0.017354896292090416,
      -0.09331496059894562,
      0.044737402349710464,
      0.05553261190652847,
      -0.02635982073843479,
      -0.05938195437192917,
      -0.16253632307052612,
      -0.003713803831487894,
      0.05370262265205383,
      -0.03393200412392616,
      0.053841281682252884,
      -0.00848403200507164,
      -0.03333751857280731,
      -0.013523595407605171,
      -0.10282476991415024,
      0.022157276049256325,
      -0.05705936253070831,
      0.02909063920378685,
      0.011204426176846027,
      -0.0762777104973793,
      -0.05099613964557648,
      -0.009886598214507103,
      -0.07415503263473511,
      0.025070931762456894,
      0.0555175356566906,
      -0.040341541171073914,
      -0.0598096065223217,
      0.002582213841378689,
      0.009110620245337486,
      -0.0010269304038956761,
      0.02874002233147621,
      0.006873802747577429,
      0.008920491673052311,
      -0.013029091991484165,
      -0.06168822571635246,
      -0.020921729505062103,
      0.09095712006092072,
      -0.04244096204638481,
      -0.07067031413316727,
      0.047390636056661606,
      -0.03804370388388634,
      0.05874617397785187,
      0.07809923589229584,
      -0.043722692877054214,
      -0.01783357374370098,
      0.029922183603048325,
      -0.0005945279845036566,
      -0.0037529715336859226,
      -0.013015720061957836,
      -0.009593511000275612,
      0.022589504718780518,
      0.01054774597287178,
      0.04708702862262726,
      0.034256406128406525,
      0.07212274521589279,
      -0.010228595696389675,
      0.0010834770509973168,
      0.05340617895126343,
      -0.07295995205640793,
      -0.021844223141670227,
      -0.04821908101439476,
      0.003768470836803317,
      0.007983425632119179,
      0.008609852753579617,
      -0.04878873750567436,
      -0.025715447962284088,
      -0.029676638543605804,
      -0.04954712837934494,
      0.011287997476756573,
      -0.02692646160721779,
      -0.07368218898773193,
      -0.032691098749637604,
      0.09121250361204147,
      -0.0813547894358635,
      -0.02275945618748665,
      0.010141300037503242,
      -0.09834691882133484,
      0.07238522171974182,
      -0.04835911840200424,
      -0.007781752850860357,
      -0.030125528573989868,
      0.0824691653251648,
      -0.0018144292989745736,
      -0.058845821768045425,
      -0.0015577791491523385,
      0.04275199770927429,
      -0.006352095399051905,
      -0.08761201798915863,
      -0.05141624063253403,
      -0.0363130122423172,
      -0.01421964168548584,
      0.02432279661297798,
      0.013216046616435051,
      0.06877332180738449,
      0.06533689051866531,
      0.01134546473622322,
      0.03450169041752815,
      0.018564363941550255,
      -0.04106844961643219,
      0.03735332936048508,
      -0.01619546301662922,
      -0.0176121573895216,
      -0.02439603954553604,
      -0.02849341370165348,
      -0.05578816682100296,
      -0.022312527522444725,
      0.02717909775674343,
      -0.037191249430179596,
      -0.035768888890743256,
      -0.03243350237607956,
      -0.0023069775197654963,
      -0.028779679909348488,
      0.005561709403991699,
      0.0042009116150438786,
      0.00401100842282176,
      -0.03627641126513481,
      0.03502343222498894,
      0.005776058416813612,
      0.013673879206180573,
      -0.08794385939836502,
      -0.014183918945491314,
      0.06617849320173264,
      0.004253616090863943,
      -0.05326743423938751,
      0.02074298821389675,
      -0.005656230729073286,
      0.05334724858403206,
      0.048038266599178314,
      0.007059084251523018,
      0.06107668951153755,
      -0.017634369432926178,
      0.05587216094136238,
      0.021795375272631645,
      0.0037267960142344236,
      -0.006339276675134897,
      0.023755086585879326,
      -0.05151151493191719,
      -0.02096373774111271,
      0.01239749975502491,
      0.04867943003773689,
      -0.032052814960479736,
      0.05403260886669159,
      0.02199883759021759,
      0.02613685466349125,
      0.0022007159423083067
    ],
    [
      -0.0025610760785639286,
      0.02020498923957348,
      0.026177523657679558,
      -0.029557764530181885,
      0.01723344810307026,
      -0.047834739089012146,
      0.010474828071892262,
      0.00677754171192646,
      0.013882223516702652,
      0.0011523880530148745,
      -0.009354759007692337,
      -0.007047895807772875,
      0.021913912147283554,
      0.019928786903619766,
      -0.011360402218997478,
      -0.033271972090005875,
      0.11465874314308167,
      -0.04686626046895981,
      -0.013558238744735718,
      0.016199249774217606,
      -0.06651122123003006,
      0.009370693005621433,
      -0.034942589700222015,
      -0.057837966829538345,
      -0.014763792045414448,
      0.08132296800613403,
      -0.0176767036318779,
      -0.06395699083805084,
      -0.071709044277668,
      0.0294207576662302,
      0.09804195910692215,
      0.0006475065601989627,
      -0.007834507152438164,
      0.01756313629448414,
      -0.007965415716171265,
      -0.07328038662672043,
      -0.012284555472433567,
      -0.018752511590719223,
      -0.03898046165704727,
      0.02438702993094921,
      -0.026994815096259117,
      -0.03384530544281006,
      -0.028602080419659615,
      -0.012750813737511635,
      -0.03290554881095886,
      0.02575918845832348,
      -0.04009447991847992,
      0.0027846398297697306,
      0.02658863551914692,
      -0.029220735654234886,
      0.006725701969116926,
      -0.07383879274129868,
      -0.01554206945002079,
      -0.0816190093755722,
      0.03691919893026352,
      0.03377588093280792,
      0.0796033963561058,
      -0.02822772040963173,
      -0.054385628551244736,
      -0.0015915634576231241,
      0.07616586983203888,
      -0.008752994239330292,
      0.0077870432287454605,
      -0.003133192425593734,
      -0.04697086662054062,
      0.03308584913611412,
      -0.002591068157926202,
      0.027002276852726936,
      0.03848237171769142,
      0.0007342774188145995,
      -0.029501570388674736,
      -0.041332706809043884,
      0.07726544141769409,
      -0.0009064992773346603,
      -0.06527529656887054,
      -0.05372222885489464,
      0.027351917698979378,
      0.0607508160173893,
      0.030482066795229912,
      -0.007527491543442011,
      0.06885602325201035,
      0.06151370704174042,
      -0.04891707003116608,
      0.04117274284362793,
      0.06991689652204514,
      0.004470671992748976,
      0.048591841012239456,
      0.008395800366997719,
      -0.01710999384522438,
      0.021069340407848358,
      0.06868863850831985,
      -0.02035609818994999,
      0.030165057629346848,
      -0.03814663365483284,
      0.07759879529476166,
      0.0031175673939287663,
      0.006269197911024094,
      -0.05918493866920471,
      0.04811084270477295,
      -0.06483639031648636,
      -0.0015376271912828088,
      0.01959691196680069,
      -0.03162187337875366,
      0.03081943653523922,
      -0.010532823391258717,
      -0.003724894020706415,
      0.005775912199169397,
      -0.08082621544599533,
      0.0385095588862896,
      -0.03364128991961479,
      -0.008902356028556824,
      -0.01604364439845085,
      0.008113867603242397,
      -0.042497530579566956,
      -0.056652966886758804,
      0.09050402045249939,
      0.03627372160553932,
      0.0076955389231443405,
      -0.08024739474058151,
      0.015669461339712143,
      0.05545111373066902,
      -0.035101503133773804,
      0.016088631004095078,
      0.02198858931660652,
      0.02501467801630497,
      0.05825146287679672,
      0.07589789479970932,
      0.07959321141242981,
      -0.010428879410028458,
      0.025264039635658264,
      0.06537063419818878,
      -0.014055104926228523,
      -0.017469249665737152,
      0.04838920384645462,
      -0.04840652644634247,
      -0.02367691695690155,
      0.02725261077284813,
      0.017011044546961784,
      -0.051400672644376755,
      0.03955244645476341,
      0.010420192964375019,
      0.07410262525081635,
      0.16787497699260712,
      -0.05070158839225769,
      0.01079012081027031,
      0.03808997571468353,
      -0.04111697897315025,
      -0.06803108751773834,
      0.0346636101603508,
      -0.036173008382320404,
      0.03493199869990349,
      -0.014975138939917088,
      0.009889338165521622,
      0.029066307470202446,
      0.023092957213521004,
      -0.028200898319482803,
      -0.004213955253362656,
      -0.0003115045838057995,
      0.02347336895763874,
      0.02150542102754116,
      0.011746671050786972,
      -0.05461622774600983,
      -0.029897836968302727,
      -0.02208683453500271,
      -0.038366686552762985,
      -0.00020017770293634385,
      -0.038035277277231216,
      -0.045333828777074814,
      -0.05218421295285225,
      0.055963847786188126,
      0.0057387338019907475,
      -0.04189140349626541,
      0.012258041650056839,
      -0.014579271897673607,
      -0.020552434027194977,
      0.08120455592870712,
      -0.00760880159214139,
      0.01489598024636507,
      0.09692393988370895,
      0.04473793879151344,
      -0.02070470154285431,
      -0.01739594154059887,
      -0.013973932713270187,
      -0.0046118441969156265,
      -0.013429133221507072,
      0.04605865851044655,
      -0.02311403863132,
      -0.03301560506224632,
      0.013506379909813404,
      0.030656933784484863,
      -0.019649934023618698,
      -0.09461510181427002,
      0.003654944011941552,
      -0.013091626577079296,
      0.030118180438876152,
      -0.04357881844043732,
      0.03731042146682739,
      -0.06848769634962082,
      0.014164579100906849,
      0.03194790333509445,
      -0.0013343703467398882,
      -0.02278466522693634,
      -0.021737156435847282,
      -0.016638796776533127,
      0.02135234698653221,
      -0.023192349821329117,
      -0.01126259658485651,
      0.011609921231865883,
      -0.027316836640238762,
      -0.017895327880978584,
      -0.029849711805582047,
      -0.02756374515593052,
      0.010742707177996635,
      0.022690171375870705,
      0.05173233896493912,
      0.0427275188267231,
      -0.03871864080429077,
      0.04551156237721443,
      0.06733899563550949,
      0.03180968016386032,
      -0.030751779675483704,
      0.01323660183697939,
      0.04586922004818916,
      0.04052122309803963,
      0.052520282566547394,
      0.02465749718248844,
      0.03967312350869179,
      0.050948869436979294,
      -0.07800742983818054,
      0.013556676916778088,
      0.08199580758810043,
      0.04084734618663788,
      0.012976473197340965,
      0.02935030683875084,
      -0.05951579660177231,
      -0.030979707837104797,
      0.030004657804965973,
      0.0052243126556277275,
      0.0463540218770504,
      0.04893423616886139,
      -0.03209351375699043,
      -0.000461625779280439,
      0.01690850779414177,
      -0.04526572301983833,
      0.05422351881861687,
      0.06370344012975693,
      0.11083556711673737,
      0.00979677028954029,
      0.05902884155511856,
      0.06526613980531693,
      -0.022846778854727745,
      -0.01955626718699932,
      -0.03124881722033024,
      0.049213703721761703,
      -0.004057781305164099,
      0.04158096760511398,
      -0.005572841502726078,
      0.028001321479678154,
      0.00034316693199798465,
      0.029223402962088585,
      0.06091747060418129,
      -0.09832156449556351,
      -0.04619184508919716,
      0.03265880420804024,
      0.04427358880639076,
      0.022170625627040863,
      0.012405047193169594,
      -0.1094648540019989,
      0.012612815015017986,
      0.04544641822576523,
      0.05150269344449043,
      -0.009706028737127781,
      0.07708034664392471,
      0.050694555044174194,
      -0.03231010213494301,
      -0.033559974282979965,
      0.03808481618762016,
      -0.0179094597697258,
      -0.007473798468708992,
      0.0023742394987493753,
      0.017354970797896385,
      0.049637213349342346,
      0.010084819048643112,
      0.012175554409623146,
      0.029265431687235832,
      -0.036841023713350296,
      0.03286385163664818,
      0.07755410671234131,
      0.04744913801550865,
      0.005822083912789822,
      -0.113967664539814,
      -0.06072423234581947,
      -0.02161346934735775,
      -0.013169473968446255,
      -0.039243441075086594,
      0.06635259091854095,
      0.01805897243320942,
      0.03181174397468567,
      0.07997570186853409,
      0.023756779730319977,
      -0.10086748749017715,
      0.03056642785668373,
      0.021329928189516068,
      0.028636017814278603,
      0.05160936340689659,
      0.009372991509735584,
      -0.02826674096286297,
      -0.022595418617129326,
      0.007565669249743223,
      0.014698376879096031,
      -0.04388978332281113,
      -0.0048254672437906265,
      0.03642016276717186,
      0.008375195786356926,
      0.057041704654693604,
      -0.005977232474833727,
      0.0021272592712193727,
      0.0011077671078965068,
      -0.026595277711749077,
      -0.014663576148450375,
      -0.0637243464589119,
      0.023471634835004807,
      0.042921118438243866,
      -0.05315297469496727,
      -0.015000071376562119,
      -0.06966795027256012,
      -0.03382103517651558,
      -0.014341231435537338,
      -0.048155706375837326,
      -0.015418557450175285,
      0.05148845538496971,
      -0.013538572005927563,
      0.04212207347154617,
      -0.005200415849685669,
      -0.05856610834598541,
      -0.02408255822956562,
      0.060029756277799606,
      -0.040169551968574524,
      0.05554738640785217,
      -0.03934113308787346,
      -0.06965155154466629,
      -0.023972369730472565,
      -0.023601675406098366,
      -0.04538201168179512,
      0.025200461968779564,
      -0.0010086072143167257,
      -0.03340614214539528,
      -0.009277819655835629,
      -0.04289095848798752,
      0.036298349499702454,
      0.04478893801569939,
      -0.04073004424571991,
      -0.10808166861534119,
      -0.035050179809331894,
      0.03201909363269806,
      0.04274773225188255,
      0.09681371599435806,
      -0.027602583169937134,
      -0.0007322203600779176,
      -0.05256936699151993,
      -0.054945796728134155,
      0.12728209793567657,
      0.03198910877108574,
      -0.031822532415390015,
      -0.003982596565037966,
      0.014681387692689896,
      -0.05224381014704704,
      -0.010466279461979866,
      -0.013414268381893635,
      0.02196362055838108,
      0.03820719197392464,
      0.022750109434127808,
      0.03060045652091503,
      -0.04753270372748375,
      -0.00246083689853549,
      0.02607424557209015,
      0.011281786486506462,
      -0.02792125567793846,
      0.0502970889210701,
      0.017729785293340683,
      0.005199497565627098,
      0.09906303882598877,
      -0.032674603164196014,
      -0.025709932669997215,
      -0.0749734416604042,
      0.025641435757279396,
      -0.023593856021761894,
      0.024870501831173897,
      0.07382484525442123,
      0.045587051659822464,
      0.005230657756328583,
      0.07440333068370819,
      0.030787399038672447,
      0.05099983513355255,
      -0.009752560406923294,
      -0.008341779001057148,
      0.0438079908490181,
      -0.0016622854163870215,
      -0.038873590528964996,
      -0.0347466878592968,
      0.05077765882015228,
      -0.084489606320858,
      0.03682735189795494,
      -0.03137587383389473,
      0.0023054543416947126,
      -0.008461869321763515,
      -0.03262481838464737,
      0.06706513464450836,
      -0.03240148350596428,
      0.05568452551960945,
      -0.004345736466348171,
      0.0679229274392128,
      0.015969915315508842,
      0.06384293735027313,
      -0.11381484568119049,
      0.016619138419628143,
      0.05726349353790283,
      -0.02906429022550583,
      0.022446228191256523,
      0.05139125883579254,
      0.026803012937307358,
      -0.020244626328349113,
      -0.06561320275068283,
      -0.006483474280685186,
      0.09266874939203262,
      -0.038633011281490326,
      -0.020118484273552895,
      0.08426326513290405,
      0.025944946333765984,
      0.0052871727384626865,
      0.04651681333780289,
      0.0351407527923584,
      0.0008270932594314218,
      0.057343803346157074,
      -0.011189823038876057,
      0.06925813853740692,
      0.02977544441819191,
      0.008816941641271114,
      0.052795086055994034,
      -0.02407093159854412,
      -0.05629201978445053,
      -0.018819283694028854,
      -0.020523661747574806,
      -0.058093421161174774,
      0.010268745943903923,
      -0.02981153316795826,
      0.004791048355400562,
      -0.058168113231658936,
      -0.015913300216197968,
      0.00501132570207119,
      -0.01133026834577322,
      -0.00854164082556963,
      0.042020034044981,
      -0.04646821692585945,
      -0.0027594375424087048,
      -0.002383619314059615,
      0.007679051719605923,
      -0.06238753721117973,
      0.028352566063404083,
      0.059928808361291885,
      0.015014606527984142,
      0.14378558099269867,
      -0.07493887096643448,
      -0.013914470560848713,
      0.031687118113040924,
      0.028821276500821114,
      0.049818191677331924,
      0.02293635718524456,
      -0.0218373890966177,
      0.021479694172739983,
      0.1569705456495285,
      -0.03583214432001114,
      0.014771105721592903,
      0.006747592240571976,
      0.07719922810792923,
      -0.060373350977897644,
      0.016831597313284874,
      -0.039543233811855316,
      0.027877449989318848,
      0.015046421438455582,
      0.009184369817376137,
      -0.04465661942958832,
      0.09503287076950073,
      -0.04366035386919975,
      0.03502960130572319,
      -0.029384003952145576,
      -0.016429446637630463,
      0.07155389338731766,
      -0.05881053954362869,
      0.07213187962770462,
      -0.048400331288576126,
      0.023715605959296227,
      -0.05997544899582863,
      -0.0073890164494514465,
      -0.014827817678451538,
      0.03255444020032883,
      -0.0035593253560364246,
      -0.008013095706701279,
      0.006939023267477751,
      0.041902076452970505,
      -0.04630250856280327,
      -0.07180394977331161,
      -0.04169874265789986
    ],
    [
      0.06054854765534401,
      0.04220496490597725,
      0.10081254690885544,
      0.060500308871269226,
      0.028044352307915688,
      -0.09703706949949265,
      0.007144925184547901,
      -0.010120285674929619,
      -0.034884024411439896,
      -0.03381998464465141,
      -0.02067141979932785,
      0.05582701414823532,
      -0.020922470837831497,
      -0.020366016775369644,
      -0.0384628027677536,
      0.05466020479798317,
      0.03426231071352959,
      -0.005207970272749662,
      -0.10203433036804199,
      0.03479001671075821,
      0.03915026783943176,
      -0.04240782931447029,
      -0.039453357458114624,
      0.011206730268895626,
      -0.03138043358922005,
      0.011581397615373135,
      -0.08910264074802399,
      -0.019275609403848648,
      0.021049391478300095,
      -0.025702066719532013,
      -0.01895732246339321,
      -0.0917624682188034,
      0.03590647503733635,
      -0.01249940786510706,
      0.035005614161491394,
      0.020334918051958084,
      -0.022540267556905746,
      0.034671083092689514,
      -0.029993683099746704,
      -0.01872490905225277,
      -0.021671641618013382,
      -0.056455716490745544,
      0.09182404726743698,
      -0.004321857821196318,
      -0.003811466507613659,
      -0.010208377614617348,
      -0.016359323635697365,
      -0.04631945490837097,
      -0.018742749467492104,
      0.06145590916275978,
      -0.02832554467022419,
      0.06727682054042816,
      -0.008454186841845512,
      -0.022913789376616478,
      -0.011791200377047062,
      0.04129984974861145,
      -0.029255617409944534,
      0.011406108736991882,
      0.04555245488882065,
      0.04653972014784813,
      0.003904056968167424,
      -0.03496742248535156,
      0.02417156845331192,
      0.01413600705564022,
      0.013664737343788147,
      -0.030582565814256668,
      0.0013885201187804341,
      0.013292571529746056,
      -0.03834303095936775,
      0.014519592747092247,
      -0.05094796419143677,
      0.0021977436263114214,
      0.08295322954654694,
      0.020823614671826363,
      0.03855159878730774,
      -0.04156901687383652,
      -0.004145787563174963,
      -0.06995245814323425,
      0.017182819545269012,
      -0.10267092287540436,
      0.007582031190395355,
      0.01699712686240673,
      -0.006540193688124418,
      0.06405329704284668,
      -0.06323965638875961,
      -0.056641776114702225,
      -0.06559159606695175,
      0.057203151285648346,
      -0.026857489719986916,
      -0.06743960082530975,
      -0.03646864742040634,
      -0.03188112750649452,
      0.048711951822042465,
      -0.04996788874268532,
      -0.011228346265852451,
      -0.029475098475813866,
      0.04307803139090538,
      -0.021730788052082062,
      0.007521493826061487,
      -0.024984857067465782,
      -0.06987085193395615,
      0.015138047747313976,
      0.016583070158958435,
      0.06077302247285843,
      0.012639266438782215,
      -0.0702948048710823,
      0.009465758688747883,
      0.03365439921617508,
      0.005712167359888554,
      0.07134133577346802,
      0.01929292269051075,
      0.08450789004564285,
      -0.05392163246870041,
      -0.017701413482427597,
      -0.0374433659017086,
      -0.03022949956357479,
      0.019857710227370262,
      -0.06155546382069588,
      0.010137544013559818,
      0.018540162593126297,
      -0.04218847677111626,
      0.01265694759786129,
      0.058218665421009064,
      0.11779063194990158,
      0.07627225667238235,
      -0.07339617609977722,
      -0.02239861898124218,
      -0.06066783145070076,
      0.002699753036722541,
      0.014120014384388924,
      0.09015480428934097,
      0.006258853245526552,
      -0.013283949345350266,
      -0.01567559689283371,
      0.004214448854327202,
      0.05474286898970604,
      0.0021611731499433517,
      0.043213993310928345,
      0.018931664526462555,
      0.0205302182585001,
      -0.057457707822322845,
      0.021870259195566177,
      -0.10314375162124634,
      -0.033314306288957596,
      0.06189236417412758,
      -0.06666649132966995,
      0.0022030784748494625,
      0.06424562633037567,
      -0.03219620883464813,
      -0.05380517616868019,
      -0.020552029833197594,
      0.0417805016040802,
      -0.015551364049315453,
      0.01426288578659296,
      -0.0014552549691870809,
      0.0528138168156147,
      0.09078728407621384,
      0.01575976051390171,
      0.013165048323571682,
      -0.05811219662427902,
      -0.06213780865073204,
      0.004940913990139961,
      -0.048564277589321136,
      0.04300962761044502,
      -0.03296225890517235,
      -0.04396819695830345,
      0.0519079826772213,
      -0.006941602099686861,
      -0.04680730774998665,
      -0.022542791441082954,
      -0.003913640510290861,
      0.031145168468356133,
      -0.015616974793374538,
      -0.0016713495133444667,
      -0.05908863618969917,
      0.049672290682792664,
      0.003543723374605179,
      -0.06214668229222298,
      -0.005324927624315023,
      0.0709482729434967,
      0.04094662517309189,
      -0.036456987261772156,
      -0.008663025684654713,
      0.05940796062350273,
      0.01848282851278782,
      -0.054483186453580856,
      -0.058672524988651276,
      0.029554910957813263,
      -0.0510093979537487,
      0.016348237171769142,
      -0.018021492287516594,
      0.06878961622714996,
      0.017055809497833252,
      0.024795159697532654,
      0.04576008394360542,
      -0.022758975625038147,
      -0.05183262750506401,
      0.07805024832487106,
      -0.029055899009108543,
      -0.007473069708794355,
      0.022756248712539673,
      -0.04750951752066612,
      -0.04434490576386452,
      0.02733670361340046,
      -0.01013961061835289,
      -0.07944073528051376,
      -0.07370047271251678,
      0.049471415579319,
      -0.024401895701885223,
      0.009549165144562721,
      -0.06911351531744003,
      -0.1005823016166687,
      -0.013911931775510311,
      -0.06923510134220123,
      -0.0386161282658577,
      0.0012704430846497416,
      -0.006058927159756422,
      -0.0077111488208174706,
      -0.04034004732966423,
      0.016631530597805977,
      -0.013865871354937553,
      0.0004838532186113298,
      -0.01875932700932026,
      0.03501677140593529,
      0.005561319179832935,
      0.1025586649775505,
      0.00823327898979187,
      0.06755717843770981,
      -0.0007489421404898167,
      0.020213240757584572,
      0.04491856321692467,
      0.055994123220443726,
      -0.05394381284713745,
      0.048173341900110245,
      -0.032196685671806335,
      0.09069327265024185,
      -0.008824335411190987,
      0.008822311647236347,
      0.07503896206617355,
      0.016462966799736023,
      -0.10497865080833435,
      -0.08768480271100998,
      -0.022894222289323807,
      -0.029556911438703537,
      0.013980763033032417,
      -0.00980980321764946,
      -0.05169612541794777,
      -0.007429047487676144,
      -0.030255911871790886,
      -0.03842201456427574,
      -0.05594310909509659,
      -0.06165400147438049,
      0.03455056622624397,
      -0.048550307750701904,
      0.045136459171772,
      -0.050348177552223206,
      -0.014199323952198029,
      0.05013171583414078,
      -0.002947665983811021,
      -0.07437672466039658,
      0.07540904730558395,
      0.025812191888689995,
      -0.06130694970488548,
      0.009704683907330036,
      0.04832901060581207,
      -0.07465946674346924,
      -0.04657883569598198,
      -0.04107210040092468,
      -0.06441371887922287,
      -0.0926104262471199,
      -0.01174316555261612,
      0.000841711473185569,
      -0.02469257079064846,
      -0.051029715687036514,
      -0.01535727083683014,
      0.019044309854507446,
      -0.01946609653532505,
      -0.060458287596702576,
      -0.030490636825561523,
      -0.06681820005178452,
      -0.03207583352923393,
      0.0016230222536250949,
      0.10208213329315186,
      0.03051995113492012,
      -0.016710983589291573,
      -0.04249119758605957,
      -0.04891052469611168,
      -0.02866259030997753,
      0.030682483687996864,
      0.0029517286457121372,
      0.005182378459721804,
      -0.034373845905065536,
      -0.059503477066755295,
      -0.04931177198886871,
      0.030108191072940826,
      0.0047854590229690075,
      0.033858221024274826,
      0.060266945511102676,
      -0.0849398821592331,
      -0.07217805087566376,
      -0.08739743381738663,
      -0.026290807873010635,
      -0.06234869733452797,
      -0.04847473278641701,
      0.04933920502662659,
      -0.05129176378250122,
      -0.07046597450971603,
      -0.01584426313638687,
      0.04906196519732475,
      0.002825150266289711,
      -0.0007668311591260135,
      -0.03474327176809311,
      -0.06328883022069931,
      -0.04798087850213051,
      0.04155169799923897,
      0.07124688476324081,
      -0.03293074667453766,
      -3.864329482894391e-05,
      0.013715715147554874,
      -0.07260977476835251,
      0.07346886396408081,
      -0.0016095658065751195,
      0.0023603264708071947,
      -0.06338020414113998,
      0.005422566086053848,
      -0.03113463521003723,
      -0.005422318819910288,
      0.05440439656376839,
      -0.008299358189105988,
      0.009068846702575684,
      -0.016541942954063416,
      0.08180724829435349,
      0.02901388891041279,
      -0.017510419711470604,
      0.038893792778253555,
      -0.07338657230138779,
      -0.039944153279066086,
      -0.052384525537490845,
      0.01791815645992756,
      0.025315668433904648,
      0.07252516597509384,
      -0.007908455096185207,
      0.022516246885061264,
      0.04614029452204704,
      -0.03172454610466957,
      0.00817183032631874,
      -0.04024416208267212,
      0.06171037629246712,
      -0.012113488279283047,
      0.03176655247807503,
      -0.0264086052775383,
      -0.0052391719073057175,
      -0.008478115312755108,
      0.05020899698138237,
      -0.018346527591347694,
      0.051244158297777176,
      -0.04928383231163025,
      -0.012167034670710564,
      0.0015705814585089684,
      -0.049376338720321655,
      0.012969654984772205,
      -0.0026195670943707228,
      0.009349898435175419,
      -0.05236835777759552,
      -0.0616522952914238,
      -0.13496211171150208,
      0.03765401989221573,
      0.025450387969613075,
      -0.020890166983008385,
      -0.0691257044672966,
      0.020013412460684776,
      0.028210069984197617,
      0.010974228382110596,
      0.045162517577409744,
      -0.027811864390969276,
      0.07735741883516312,
      -0.033188045024871826,
      -0.053224533796310425,
      0.022777875885367393,
      0.006966125685721636,
      -0.02304205112159252,
      0.07380258291959763,
      -0.006176769267767668,
      -0.07261164486408234,
      -0.014124843291938305,
      -0.0013668257743120193,
      -0.009130112826824188,
      -0.025797739624977112,
      0.04500164836645126,
      0.008929723873734474,
      -0.02420555241405964,
      -0.07224339991807938,
      -0.007539810612797737,
      0.007725759409368038,
      -0.04365922138094902,
      -0.016645170748233795,
      -0.0439186729490757,
      0.01032218337059021,
      0.0002619940205477178,
      0.03230756148695946,
      -0.05927860364317894,
      0.07080455869436264,
      -0.0006080602761358023,
      -0.03328229859471321,
      -0.0737396776676178,
      0.004719960503280163,
      0.056688081473112106,
      -0.03877653554081917,
      -0.03161882609128952,
      0.030271274968981743,
      -0.03433125838637352,
      -0.09222254157066345,
      -0.008716602809727192,
      0.016018755733966827,
      -0.04519957676529884,
      -0.035340432077646255,
      -0.05363323912024498,
      0.010396444238722324,
      0.00440713856369257,
      0.02080640196800232,
      0.04207291454076767,
      0.0028038276359438896,
      -0.042932283133268356,
      -0.054844148457050323,
      -0.061976365745067596,
      0.012547849677503109,
      0.028366703540086746,
      -0.004839278757572174,
      -0.1192796379327774,
      -0.14218080043792725,
      0.05928506702184677,
      -0.0031491706613451242,
      0.013190408237278461,
      -0.06150983273983002,
      -0.03433804214000702,
      0.00562142301350832,
      0.05499940738081932,
      0.03890172392129898,
      -0.03262287750840187,
      0.05869673565030098,
      -0.024797068908810616,
      -0.03422902524471283,
      0.06232805922627449,
      -0.048655737191438675,
      0.009267700836062431,
      -0.06389425694942474,
      0.0029081907123327255,
      -0.08735468983650208,
      0.013630268163979053,
      -0.02827169932425022,
      0.020257575437426567,
      0.016683604568243027,
      0.05712072178721428,
      -0.022203903645277023,
      -0.0009904542239382863,
      0.010375641286373138,
      0.02423582784831524,
      0.017806047573685646,
      0.024463588371872902,
      -0.031215719878673553,
      0.08125807344913483,
      0.04174055531620979,
      -0.018522851169109344,
      0.010629321448504925,
      -0.003402185393497348,
      0.03871823847293854,
      0.03793273866176605,
      -0.009909885935485363,
      -0.005141211673617363,
      -0.00672904634848237,
      -0.0009378971299156547,
      0.052130572497844696,
      -0.1019488126039505,
      -0.07837328314781189,
      0.025541743263602257,
      0.06502130627632141,
      0.04721086099743843,
      0.01409325934946537,
      -0.004882789216935635,
      0.03935908153653145,
      0.02801024168729782,
      0.05686467885971069,
      -0.03025188110768795,
      -0.07237550616264343,
      0.04370487108826637,
      -0.02496017888188362,
      0.03906648978590965,
      0.016216257587075233,
      0.004580991342663765,
      -0.02319168858230114,
      -0.01486077532172203,
      0.03683796897530556,
      0.018923675641417503,
      0.0823032557964325,
      -0.047734711319208145,
      -0.05418708547949791,
      0.001956898020580411,
      -0.006771501153707504,
      -0.059144578874111176,
      -0.06005236506462097,
      -0.01573030836880207,
      0.0736239030957222,
      0.03354461491107941
    ],
    [
      -0.018296200782060623,
      0.02665388397872448,
      -0.017930248752236366,
      0.04576372727751732,
      0.06752726435661316,
      -0.00888208206743002,
      -0.04598730430006981,
      -0.05436141416430473,
      -0.03888397663831711,
      0.020317979156970978,
      0.009070140309631824,
      -0.0057191732339560986,
      -0.01809421367943287,
      0.0331169068813324,
      0.011299381963908672,
      0.012760210782289505,
      0.02642066590487957,
      0.00930466316640377,
      0.03921976685523987,
      -0.010223009623587132,
      0.054938118904829025,
      -0.019184518605470657,
      -0.0020221539307385683,
      -0.017591901123523712,
      -0.0038474106695502996,
      0.028395937755703926,
      -0.010253437794744968,
      0.000735587440431118,
      -0.00824037380516529,
      0.017509547993540764,
      -0.10874442756175995,
      0.0289290864020586,
      -0.10642196983098984,
      0.030093934386968613,
      0.020798103883862495,
      -0.03728277236223221,
      -0.045032668858766556,
      -0.038331225514411926,
      -0.056543029844760895,
      -0.027227507904171944,
      0.07211048901081085,
      -0.0085651446133852,
      0.030619561672210693,
      -0.07165081053972244,
      0.010111074894666672,
      0.1033487468957901,
      -0.030187388882040977,
      0.010334041900932789,
      0.021201228722929955,
      -0.08689442276954651,
      -0.042380452156066895,
      0.009731432422995567,
      0.05583840608596802,
      0.007158880587667227,
      -0.02027791738510132,
      0.05425901710987091,
      -0.0369151309132576,
      0.06088551506400108,
      -0.033183202147483826,
      0.04759254679083824,
      0.011947792954742908,
      0.0831848606467247,
      -0.03721600025892258,
      -0.007627733983099461,
      -0.016661247238516808,
      0.0479564368724823,
      -0.031032385304570198,
      -0.06162026897072792,
      -0.0954870656132698,
      0.015458351001143456,
      0.064596027135849,
      -0.051197487860918045,
      -0.025026848539710045,
      0.006036271341145039,
      0.048520032316446304,
      0.03350937366485596,
      0.0778956413269043,
      -0.12387368828058243,
      -0.05004091560840607,
      0.07169251143932343,
      -0.03467468544840813,
      -0.044733233749866486,
      0.029872849583625793,
      -0.02146957255899906,
      -0.03274434804916382,
      -0.06944306194782257,
      0.039264976978302,
      0.013540267013013363,
      -0.007673347368836403,
      -0.007571799214929342,
      0.10371188819408417,
      0.05687117576599121,
      0.019714655354619026,
      -0.026068495586514473,
      -0.017069078981876373,
      0.052620019763708115,
      -0.012720842845737934,
      0.03207606449723244,
      -0.0016283653676509857,
      0.012297834269702435,
      0.06128133460879326,
      0.017676010727882385,
      0.02167126163840294,
      -0.041448090225458145,
      0.07552378624677658,
      -0.05489666014909744,
      0.02607833594083786,
      -0.0690390020608902,
      -0.04749252647161484,
      0.06246580556035042,
      0.09693244099617004,
      -0.042667217552661896,
      0.01790178380906582,
      0.005219985730946064,
      0.036657944321632385,
      0.078600212931633,
      -0.013144785538315773,
      -0.10252237319946289,
      0.03382088243961334,
      0.01007595844566822,
      -0.039742905646562576,
      -0.01709914021193981,
      0.02385934814810753,
      -0.031105900183320045,
      0.045983754098415375,
      -0.02410830557346344,
      0.07373980432748795,
      0.018491262570023537,
      0.04024944454431534,
      -0.007595112081617117,
      -0.006008705589920282,
      0.026260076090693474,
      0.014676879160106182,
      -0.008934504352509975,
      0.017816981300711632,
      -0.006737728603184223,
      0.0025920644402503967,
      0.016010837629437447,
      -0.05155503377318382,
      0.022084496915340424,
      -0.03185974061489105,
      0.02232414484024048,
      -0.01600244641304016,
      -0.06766253709793091,
      -0.06591486930847168,
      0.048735249787569046,
      0.06279706209897995,
      0.01841394230723381,
      -0.07509167492389679,
      -0.005063223652541637,
      0.06764820218086243,
      0.006257848348468542,
      0.03186088427901268,
      0.03503141179680824,
      0.024819910526275635,
      0.046883124858140945,
      0.011132589541375637,
      -0.0661502406001091,
      0.014701898209750652,
      -0.08100318163633347,
      -0.017514539882540703,
      0.05021592602133751,
      -0.016446398571133614,
      0.018547097221016884,
      0.006349582690745592,
      0.04945838823914528,
      0.04862909018993378,
      -0.04110945388674736,
      0.010649193078279495,
      -0.037846215069293976,
      -0.05224611237645149,
      -0.0032464421819895506,
      -0.08384663611650467,
      0.028786195442080498,
      0.026071755215525627,
      -0.02696891874074936,
      -0.09757395833730698,
      -0.02086923085153103,
      -0.031105097383260727,
      -0.025765366852283478,
      0.0034566367976367474,
      -0.02629038132727146,
      -0.10426251590251923,
      0.04154845327138901,
      -0.034391678869724274,
      -0.02579903043806553,
      0.016731660813093185,
      -0.01759772002696991,
      -0.031487759202718735,
      0.06804069131612778,
      -0.0031428299844264984,
      0.04428147152066231,
      -0.019649431109428406,
      0.06315016746520996,
      -0.00618937099352479,
      0.002949546556919813,
      0.02890637330710888,
      0.027430543676018715,
      -0.031387124210596085,
      0.04490503668785095,
      0.0994364395737648,
      0.05464797094464302,
      0.005122796166688204,
      0.016890740022063255,
      -0.0050886753015220165,
      -0.001193178235553205,
      -0.002094136318191886,
      0.014547618106007576,
      -0.03356426581740379,
      0.05247391760349274,
      0.011271459050476551,
      -0.010502620600163937,
      -0.09381633251905441,
      0.01695140264928341,
      0.07059504836797714,
      0.005767737980931997,
      0.023707296699285507,
      0.07418449223041534,
      0.0004194920475129038,
      -0.058902010321617126,
      0.05855872109532356,
      -0.01320231519639492,
      -0.029134543612599373,
      0.05744604021310806,
      -0.032315079122781754,
      -0.0009042276651598513,
      0.013492558151483536,
      0.007689590100198984,
      -0.007102297618985176,
      -0.044894989579916,
      0.012063459493219852,
      0.05267607793211937,
      0.06884801387786865,
      -0.03032033145427704,
      -0.01888365112245083,
      -0.0232238806784153,
      0.004851510748267174,
      0.040898632258176804,
      0.020253952592611313,
      0.028883589431643486,
      -0.0026392440777271986,
      -0.01440214179456234,
      0.04887819290161133,
      0.005007531028240919,
      0.03983493149280548,
      -0.043229665607213974,
      -0.001609354978427291,
      -0.027568083256483078,
      0.006194375455379486,
      -0.005619584582746029,
      -0.015664253383874893,
      0.03222474083304405,
      -0.04057709127664566,
      -0.005510655231773853,
      -0.03684188798069954,
      -0.01508917473256588,
      -0.0242595374584198,
      -0.014465083368122578,
      0.037046756595373154,
      0.012535219080746174,
      0.0032274117693305016,
      0.019858933985233307,
      -0.08188728988170624,
      0.013098418712615967,
      0.04763960465788841,
      -0.03488115221261978,
      0.021000990644097328,
      -0.01184176653623581,
      -0.006435234099626541,
      -0.042565882205963135,
      0.04133468121290207,
      -0.08255218714475632,
      -0.043219298124313354,
      0.04029473289847374,
      -0.03408243879675865,
      0.038006264716386795,
      0.011234241537749767,
      -0.05530531331896782,
      0.011475466191768646,
      -0.04028639197349548,
      -0.028231535106897354,
      -0.019745077937841415,
      0.03349297121167183,
      -0.07151564955711365,
      0.015007087029516697,
      -0.1132284626364708,
      -0.0007431442500092089,
      -0.0186560470610857,
      -0.028781624510884285,
      -0.005807720124721527,
      -0.0031479140743613243,
      0.012575299479067326,
      -0.047171495854854584,
      -0.030763745307922363,
      -0.038338180631399155,
      -0.04926358535885811,
      0.019644910469651222,
      -0.02543475478887558,
      -0.006100960075855255,
      0.034230489283800125,
      -0.005809488240629435,
      -0.014821787364780903,
      -0.03037596493959427,
      -0.015305032953619957,
      -0.031131813302636147,
      0.00703738909214735,
      -0.11264106631278992,
      0.0651126354932785,
      0.057049110531806946,
      -0.0522942952811718,
      0.039250448346138,
      -0.021999025717377663,
      0.0576094351708889,
      0.023417236283421516,
      -0.04027509689331055,
      -0.034316536039114,
      -0.04302387684583664,
      -0.0020882445387542248,
      0.019645463675260544,
      0.06127789244055748,
      0.015915274620056152,
      -0.0036922316066920757,
      0.05497937649488449,
      -0.08015402406454086,
      -0.0038978299126029015,
      -0.034635212272405624,
      0.053541574627161026,
      -0.05879772827029228,
      0.01717190444469452,
      -0.02126782014966011,
      -0.019687307998538017,
      -0.005938413552939892,
      -0.005692190956324339,
      0.059100858867168427,
      -0.01716671511530876,
      -0.056811634451150894,
      -0.010486243292689323,
      -0.1196824461221695,
      0.03519578278064728,
      -0.025291210040450096,
      -0.10750120133161545,
      -0.008049089461565018,
      0.012734740041196346,
      -0.04517875611782074,
      0.02176561765372753,
      -0.04538676142692566,
      -0.07305791229009628,
      0.04548301175236702,
      -0.04863864555954933,
      -0.053922731429338455,
      0.06771896034479141,
      0.0035616462118923664,
      0.041755884885787964,
      -0.06836818903684616,
      0.028482628986239433,
      -0.052774276584386826,
      -0.03357880190014839,
      -0.07703850418329239,
      0.012544647790491581,
      -0.11733496189117432,
      -0.04901083558797836,
      -0.010944708250463009,
      -0.0019772844389081,
      -0.06031826138496399,
      0.003960147965699434,
      -0.04154852777719498,
      -0.023935159668326378,
      -0.026352224871516228,
      0.022300096228718758,
      0.03466051444411278,
      0.03953506052494049,
      -0.005806515924632549,
      -0.11244679987430573,
      -0.009513676166534424,
      -0.03665103018283844,
      -0.04000639542937279,
      -0.00440625473856926,
      -0.004576532635837793,
      -0.022113582119345665,
      0.03517337143421173,
      -0.008862902410328388,
      -0.011061151511967182,
      -0.03626343980431557,
      0.015615146607160568,
      0.022256838157773018,
      0.03797721862792969,
      0.03506031259894371,
      -0.1081472635269165,
      -0.10298991203308105,
      0.0014399259816855192,
      -0.014001473784446716,
      -0.015815312042832375,
      0.019612710922956467,
      0.010514969006180763,
      -0.037845805287361145,
      0.014084719121456146,
      0.021357085555791855,
      0.003578833770006895,
      0.003892862470820546,
      0.004657751880586147,
      0.028330890461802483,
      0.05439150333404541,
      0.018344612792134285,
      -0.0018202252686023712,
      -0.014886131510138512,
      0.0893319770693779,
      -0.007063469849526882,
      0.06376025825738907,
      0.04592425003647804,
      0.0048548756167292595,
      0.03766872361302376,
      -0.037464603781700134,
      -0.03406922146677971,
      0.13185548782348633,
      -0.004075066186487675,
      -0.0285552516579628,
      -0.05798100307583809,
      0.008297660388052464,
      0.003075965214520693,
      -0.03064458630979061,
      -0.024177270010113716,
      0.00895855575799942,
      -0.1253610998392105,
      0.00011767633259296417,
      0.07696068286895752,
      0.04904308170080185,
      0.03976793214678764,
      -0.00012650089047383517,
      -0.013675357215106487,
      0.026743892580270767,
      0.08072084933519363,
      -0.06588710844516754,
      0.018663952127099037,
      0.0016847224906086922,
      -0.0183967687189579,
      -0.03661218658089638,
      0.007597822695970535,
      -0.022487321868538857,
      0.0437048114836216,
      0.0763043537735939,
      0.027288295328617096,
      -0.02640225552022457,
      -0.05393148958683014,
      0.06830750405788422,
      0.013536475598812103,
      -0.10529332607984543,
      -0.061384592205286026,
      -0.0037680957466363907,
      0.02007955312728882,
      0.02588040940463543,
      -0.03237367048859596,
      0.04403740540146828,
      -0.012548438273370266,
      -0.023087499663233757,
      -0.001045289565809071,
      0.12001604586839676,
      0.05616497993469238,
      0.012780279852449894,
      0.021059712395071983,
      0.042302247136831284,
      -0.01584995537996292,
      -0.05747188627719879,
      0.021349135786294937,
      -0.03690459951758385,
      0.009197625331580639,
      0.05796826630830765,
      0.03831585869193077,
      -0.04671059176325798,
      0.02050946094095707,
      0.0070367902517318726,
      -0.03430279344320297,
      0.008510668762028217,
      -0.024956753477454185,
      0.0680447444319725,
      -0.008983558043837547,
      0.07859370857477188,
      0.032728224992752075,
      0.0017259871819987893,
      -0.03770219534635544,
      0.050963833928108215,
      0.001415149075910449,
      -0.056580860167741776,
      -0.03384978696703911,
      0.04588184133172035,
      -0.02627047337591648,
      -0.0437970831990242,
      -0.008917899802327156,
      -0.017331916838884354,
      -0.012929118238389492,
      -0.01614571548998356,
      -0.02241263911128044,
      -0.00900384783744812,
      0.041497327387332916,
      0.07990098744630814,
      -0.014228709042072296,
      0.025435572490096092,
      0.06466426700353622,
      0.021861854940652847,
      -0.015393203124403954,
      0.054548487067222595,
      0.028617871925234795,
      -0.02016141451895237,
      0.029229464009404182
    ],
    [
      0.10869921743869781,
      0.021354174241423607,
      -0.013295897282660007,
      -0.045807577669620514,
      0.04213492199778557,
      -0.022049129009246826,
      -0.11239650845527649,
      -0.02453201822936535,
      0.00013939365453552455,
      -0.07009575515985489,
      -0.0007195630460046232,
      0.06420203298330307,
      -0.005362438037991524,
      0.06581960618495941,
      0.05684000626206398,
      -0.035369012504816055,
      0.07251006364822388,
      -0.033378809690475464,
      0.06414108723402023,
      0.008029786869883537,
      -0.011523139663040638,
      0.0078952731564641,
      0.05997677519917488,
      -0.021428516134619713,
      0.07408038526773453,
      0.014966004528105259,
      0.07970886677503586,
      -0.0037274514324963093,
      0.054638344794511795,
      -0.05010995641350746,
      0.08196989446878433,
      -0.022350609302520752,
      0.009003489278256893,
      0.09766077250242233,
      0.04816068708896637,
      0.031205926090478897,
      -0.023392271250486374,
      0.02757919952273369,
      -0.020845873281359673,
      0.026289932429790497,
      -0.08605903387069702,
      0.038827504962682724,
      -0.03412654250860214,
      0.026614004746079445,
      0.007001797202974558,
      0.0914183259010315,
      0.052755340933799744,
      0.01083432324230671,
      0.04896250739693642,
      -0.060222554951906204,
      -0.034751810133457184,
      0.014382245019078255,
      0.018013855442404747,
      -0.04289979487657547,
      -0.00713696051388979,
      0.07097858190536499,
      0.0052255806513130665,
      0.030116641893982887,
      -0.031609322875738144,
      0.00022242750856094062,
      -0.0272597037255764,
      -0.004849122371524572,
      0.0027893763035535812,
      0.051638808101415634,
      0.03289156034588814,
      0.002056458033621311,
      -0.04155462607741356,
      0.011905699968338013,
      0.00014558585826307535,
      -0.025316057726740837,
      0.056524962186813354,
      0.07980293035507202,
      0.0011461066314950585,
      0.032175395637750626,
      0.03939531370997429,
      0.10947013646364212,
      0.020911987870931625,
      -0.017053823918104172,
      -0.02179453894495964,
      0.08863816410303116,
      -0.07650547474622726,
      -0.02730722911655903,
      0.02714281901717186,
      0.02305014431476593,
      -0.004633415024727583,
      -0.04195840284228325,
      0.04711786285042763,
      -0.011472309939563274,
      0.009276434779167175,
      0.002415789058431983,
      0.0859687551856041,
      -0.001998418476432562,
      -0.0504910945892334,
      0.12557660043239594,
      0.04648778587579727,
      0.00926669966429472,
      0.018146298825740814,
      -0.03726963698863983,
      0.0542663037776947,
      -0.05971069261431694,
      0.0032113429624587297,
      0.05498194321990013,
      -0.014333437196910381,
      0.011471942067146301,
      0.02443157136440277,
      0.03880566731095314,
      0.10596660524606705,
      -0.013810797594487667,
      -0.007712255697697401,
      0.03863045573234558,
      0.0493072085082531,
      -0.04700425639748573,
      0.03568873181939125,
      -0.013080399483442307,
      0.05082030966877937,
      0.029627371579408646,
      -0.03486717492341995,
      -0.08261065930128098,
      -0.022814223542809486,
      0.004847322590649128,
      -0.009011056274175644,
      0.006698960904031992,
      0.04769114777445793,
      -0.15316975116729736,
      -0.021976348012685776,
      0.0466107577085495,
      0.10331778228282928,
      -0.0032846960239112377,
      0.014908027835190296,
      0.047550491988658905,
      -0.0021722731180489063,
      -0.022635476663708687,
      0.058115214109420776,
      0.00859370268881321,
      0.04246002808213234,
      -0.0991101786494255,
      0.035699110478162766,
      -0.0966423824429512,
      0.00043878090218640864,
      -0.009700561873614788,
      0.05458657816052437,
      0.01689380221068859,
      -0.0259519275277853,
      -5.130284989718348e-05,
      0.026798749342560768,
      0.02448456920683384,
      0.06297114491462708,
      -0.02034965343773365,
      0.10422806441783905,
      -0.06118648126721382,
      0.0035889828577637672,
      -0.024502573534846306,
      0.030504437163472176,
      0.017100824043154716,
      0.005246264394372702,
      -0.027090271934866905,
      0.04217046871781349,
      -0.04561326280236244,
      0.07162518054246902,
      0.007360151503235102,
      0.01149945892393589,
      0.029715990647673607,
      -0.005637451075017452,
      0.017357105389237404,
      -0.01136189978569746,
      0.03454308211803436,
      0.09500778466463089,
      0.02295004576444626,
      0.02815079875290394,
      -0.011563981883227825,
      0.03454034775495529,
      0.0243002250790596,
      -0.10460209846496582,
      -0.03924741595983505,
      0.07183361053466797,
      -0.01838001050055027,
      0.014729050919413567,
      -0.04835476726293564,
      0.060449060052633286,
      0.05311676114797592,
      0.02140246517956257,
      -0.03044360876083374,
      -0.028229566290974617,
      0.06876529008150101,
      0.017221057787537575,
      0.0052149235270917416,
      0.02419445477426052,
      -0.022040164098143578,
      0.021659741178154945,
      0.01778823509812355,
      -0.016980914399027824,
      0.06778927147388458,
      0.06815215945243835,
      -0.03416993468999863,
      0.022164253517985344,
      -0.03678862378001213,
      0.03571214899420738,
      0.04800708219408989,
      -0.012272421270608902,
      -0.03613871708512306,
      -0.08464743942022324,
      -0.016531523317098618,
      -0.14291492104530334,
      0.02570411004126072,
      0.04501211270689964,
      -0.09231287240982056,
      -0.014414378441870213,
      -0.036717671900987625,
      -0.034094277769327164,
      0.025277668610215187,
      -0.07697086036205292,
      -0.02946789190173149,
      -0.02054307982325554,
      -0.012514251284301281,
      -0.04948271065950394,
      -0.0785089060664177,
      -0.006616696249693632,
      0.04389554262161255,
      -0.05924130976200104,
      0.058757003396749496,
      0.009238043799996376,
      -0.003857898758724332,
      -0.00622979924082756,
      -0.0418255440890789,
      -0.02374742366373539,
      0.00954163633286953,
      0.018301546573638916,
      0.037707459181547165,
      -0.03242385387420654,
      0.00018552548135630786,
      0.013426892459392548,
      0.014584429562091827,
      -0.013341293670237064,
      0.0757855772972107,
      -0.02396370656788349,
      -0.014617000706493855,
      0.023372270166873932,
      -0.014114100486040115,
      -0.07238510996103287,
      0.031033296138048172,
      -0.0273958258330822,
      0.020861145108938217,
      -0.017404254525899887,
      -0.004094183444976807,
      -0.01939232647418976,
      -0.023228097707033157,
      0.006057418882846832,
      -0.04905159771442413,
      0.00029362706118263304,
      0.005576393101364374,
      -0.00022219825768843293,
      0.00889302883297205,
      0.09689991921186447,
      -0.02790991961956024,
      0.054397642612457275,
      0.08196254074573517,
      0.017055418342351913,
      0.1138266921043396,
      -0.014765581116080284,
      -0.003629376646131277,
      0.14199213683605194,
      0.04427197203040123,
      0.0037520253099501133,
      -0.044942114502191544,
      0.020808657631278038,
      -0.0001352716062683612,
      -0.015284594148397446,
      0.021466735750436783,
      0.01858004741370678,
      0.016386238858103752,
      0.06284581124782562,
      0.08150077611207962,
      0.018923746421933174,
      -0.00733795715495944,
      -0.022566962987184525,
      0.004360018763691187,
      0.034132733941078186,
      -0.020610380917787552,
      0.037625834345817566,
      0.014317190274596214,
      -0.011850306764245033,
      -0.07413759082555771,
      -0.011124852113425732,
      -0.05531642958521843,
      0.01459471881389618,
      -0.008842402137815952,
      -0.05811890587210655,
      0.008390695787966251,
      -0.0033112631645053625,
      0.006181253120303154,
      -0.01193480659276247,
      -0.06934672594070435,
      -0.013992445543408394,
      -0.06381402164697647,
      0.05230164900422096,
      -0.008407577872276306,
      0.013173368759453297,
      -0.039684902876615524,
      0.03646484389901161,
      -0.07297465950250626,
      -0.06994414329528809,
      0.014111253432929516,
      -0.046266961842775345,
      -0.05973277986049652,
      0.04615771770477295,
      -0.011566750705242157,
      0.005848750472068787,
      -0.03389944136142731,
      0.021947890520095825,
      -0.03811302036046982,
      -0.06483026593923569,
      -0.008802182041108608,
      0.02823401801288128,
      -0.09087783098220825,
      0.018043573945760727,
      0.013545658439397812,
      0.026616854593157768,
      -0.011696262285113335,
      -0.07477206736803055,
      -0.0879494845867157,
      0.012713543139398098,
      0.017049003392457962,
      0.06661037355661392,
      0.05323892459273338,
      0.03629455715417862,
      0.019351551309227943,
      -0.05327270179986954,
      0.038984041661024094,
      0.01974312961101532,
      0.023059332743287086,
      0.0214895810931921,
      -0.05481082946062088,
      -0.005100124515593052,
      -0.038774508982896805,
      -0.040340423583984375,
      -0.043102849274873734,
      -0.04688556492328644,
      -0.008903253823518753,
      0.03068067878484726,
      -0.06756097823381424,
      0.026096314191818237,
      0.05247505381703377,
      -0.006353502627462149,
      -0.1290164738893509,
      0.12366107106208801,
      -0.06546507030725479,
      -0.17007286846637726,
      0.02337065525352955,
      0.058042604476213455,
      0.00021844852017238736,
      -0.012142381630837917,
      0.0011725734220817685,
      -0.040753323584795,
      0.08799540996551514,
      0.04790649190545082,
      -0.12742604315280914,
      0.012626750394701958,
      0.04758927598595619,
      0.12697358429431915,
      -0.02574901469051838,
      -0.011718218214809895,
      0.058897849172353745,
      0.0293242409825325,
      0.04997457191348076,
      0.06458595395088196,
      -0.03189597278833389,
      0.03862691670656204,
      0.09200294315814972,
      0.016415487974882126,
      -0.0703790932893753,
      0.03989718109369278,
      -0.12383976578712463,
      0.007620169781148434,
      -0.028300633653998375,
      -0.033604271709918976,
      -0.007093355990946293,
      0.002516603795811534,
      -0.007938943803310394,
      -0.06058501824736595,
      -0.03491505607962608,
      -0.013955740258097649,
      0.0605505146086216,
      -0.11312977224588394,
      -0.04880579188466072,
      0.03955927863717079,
      0.012557880952954292,
      0.022316202521324158,
      0.0025453190319240093,
      0.011445488780736923,
      -0.017152300104498863,
      -0.02053305320441723,
      -0.031358502805233,
      -0.033221784979104996,
      0.029784291982650757,
      -0.046754658222198486,
      0.05984758958220482,
      0.0421711690723896,
      -0.10622470080852509,
      -0.019360873848199844,
      0.0688193216919899,
      0.07723518460988998,
      -0.03319263458251953,
      0.012416916899383068,
      0.005791970528662205,
      -0.018037855625152588,
      0.018424401059746742,
      -0.018511947244405746,
      -0.027600610628724098,
      0.026788102462887764,
      0.0690905824303627,
      0.034872423857450485,
      0.02917546033859253,
      -0.06665731966495514,
      -0.04289267957210541,
      -0.03021090291440487,
      -0.01901066303253174,
      -0.05194314569234848,
      -0.038479406386613846,
      -0.07080983370542526,
      0.026653975248336792,
      0.07595428079366684,
      -0.0317029170691967,
      0.030869288370013237,
      -0.03366466611623764,
      0.02453785575926304,
      0.01107042096555233,
      0.03706224635243416,
      0.006025286391377449,
      -0.05590969696640968,
      -0.028374172747135162,
      -0.01810571178793907,
      -0.07780640572309494,
      0.07401379197835922,
      0.03583654388785362,
      0.004376302007585764,
      0.03410831838846207,
      0.04631172865629196,
      0.022351955994963646,
      -0.044321730732917786,
      -0.07073909789323807,
      -0.06958223134279251,
      -0.038317758589982986,
      0.013897576369345188,
      0.05587249621748924,
      0.040641140192747116,
      -0.02209724299609661,
      0.06134902685880661,
      -0.0518106184899807,
      0.010289938189089298,
      0.028439326211810112,
      -0.00044070466537959874,
      0.009022216312587261,
      0.06032188981771469,
      -0.10188217461109161,
      -0.004277909640222788,
      -0.03151513636112213,
      -0.00322422431781888,
      -0.050998505204916,
      0.0651775524020195,
      -0.012615676037967205,
      -0.04893060028553009,
      -0.07846131920814514,
      -0.045044586062431335,
      0.04746859893202782,
      0.026893530040979385,
      -0.04490654170513153,
      0.013806328177452087,
      0.003289013635367155,
      0.1057237833738327,
      -0.07894837111234665,
      -0.024115774780511856,
      -0.04017185792326927,
      -0.06897639483213425,
      0.003790169721469283,
      0.0900161936879158,
      0.0022724000737071037,
      -0.09893317520618439,
      0.02120528370141983,
      0.021368833258748055,
      0.015793602913618088,
      0.03289895877242088,
      0.030955621972680092,
      -0.000858992978464812,
      -0.05288774147629738,
      0.08181656152009964,
      -0.051607001572847366,
      0.01683637499809265,
      0.017412763088941574,
      -0.02070043794810772,
      0.12013467401266098,
      0.01558383833616972,
      -0.03793776035308838,
      0.016693701967597008,
      -0.06872893124818802,
      0.0164938997477293,
      -0.014100151136517525,
      0.07160725444555283,
      -0.008777800016105175,
      0.012353175319731236,
      -0.07282504439353943,
      -0.06944540143013,
      -0.0213963370770216,
      0.010238858871161938
    ],
    [
      0.025106029585003853,
      -0.012950929813086987,
      0.01487661525607109,
      -0.024834901094436646,
      -0.018760617822408676,
      -0.002552372170612216,
      -0.05700889974832535,
      0.03853762522339821,
      -0.009383711963891983,
      0.013944140635430813,
      -0.01854528672993183,
      -0.0441349633038044,
      -0.01287506241351366,
      0.022583212703466415,
      0.03710566461086273,
      -0.03648657724261284,
      0.05397237837314606,
      0.0020211082883179188,
      -0.042772598564624786,
      -0.05365985631942749,
      -0.11800742149353027,
      0.06672728806734085,
      0.001149387564510107,
      0.0511680543422699,
      -0.02083818055689335,
      0.002973740454763174,
      0.09604250639677048,
      0.0119979502633214,
      0.014330793172121048,
      -0.004767604637891054,
      0.0689789205789566,
      0.026076124981045723,
      -0.02268318086862564,
      -0.06303578615188599,
      -0.0659138485789299,
      0.021350054070353508,
      -0.04349112883210182,
      0.009295380674302578,
      -0.03829747065901756,
      0.06144436448812485,
      -0.009897508658468723,
      -0.0004610528994817287,
      -0.06099601462483406,
      0.01933434046804905,
      0.02716630883514881,
      -0.0524820014834404,
      0.016153821721673012,
      -0.0015576210571452975,
      0.03076816163957119,
      0.041035592555999756,
      -0.0935894027352333,
      0.11271065473556519,
      0.0046032387763261795,
      0.043383341282606125,
      0.07487330585718155,
      -0.031030526384711266,
      0.03144072741270065,
      0.040320031344890594,
      -0.10198169201612473,
      -0.0625438243150711,
      0.033969756215810776,
      -0.04632299020886421,
      0.023945948109030724,
      -0.08532946556806564,
      -0.019399311393499374,
      0.04265468195080757,
      0.03745434433221817,
      0.038573380559682846,
      0.011994961649179459,
      0.003510488197207451,
      0.033279187977313995,
      0.010964108631014824,
      -0.03406864032149315,
      0.03259215131402016,
      0.0011122269788756967,
      0.03106962889432907,
      0.01913943700492382,
      -0.042934708297252655,
      -0.027933696284890175,
      0.035809267312288284,
      0.007591975387185812,
      0.00319451536051929,
      -0.030055854469537735,
      0.05675816163420677,
      0.05081603676080704,
      -0.10242194682359695,
      -0.031372494995594025,
      -0.012785777449607849,
      0.05708659067749977,
      -0.04014405980706215,
      -0.045914385467767715,
      -0.032068029046058655,
      0.009750801138579845,
      0.013139376416802406,
      0.015882955864071846,
      0.025178782641887665,
      0.03695076331496239,
      -0.04137206822633743,
      -0.017560632899403572,
      0.008644110523164272,
      5.601075099548325e-05,
      -0.0657312199473381,
      0.04138582944869995,
      0.010647237300872803,
      -0.019378917291760445,
      -0.048513103276491165,
      0.03170246630907059,
      0.05168691277503967,
      0.04036179184913635,
      0.052738986909389496,
      -0.0002395805495325476,
      -0.03632677346467972,
      0.02685277722775936,
      0.0777193084359169,
      -0.04120641574263573,
      -0.054747939109802246,
      -0.021904578432440758,
      0.06022121384739876,
      -0.07274307310581207,
      0.06204374134540558,
      0.08333109319210052,
      0.04936676099896431,
      0.00442474102601409,
      0.04572072997689247,
      -0.01346222311258316,
      -0.0031413440592586994,
      0.017017191275954247,
      0.02909420244395733,
      -0.051258914172649384,
      -0.03807583078742027,
      -0.07752722501754761,
      -0.022731972858309746,
      0.0940915048122406,
      0.021192379295825958,
      0.03831601142883301,
      0.06359593570232391,
      0.020355714485049248,
      -0.020937975496053696,
      0.024616798385977745,
      -0.029416523873806,
      0.04288526251912117,
      0.02625245414674282,
      -0.01932515949010849,
      -0.06748758256435394,
      0.009248589165508747,
      0.002386455424129963,
      -0.039361778646707535,
      -0.020763590931892395,
      -0.08823420107364655,
      0.0002575773687567562,
      0.016173535957932472,
      0.033878762274980545,
      -0.09344609081745148,
      -0.021581090986728668,
      0.045528437942266464,
      0.04317542538046837,
      -0.0008556282264180481,
      -0.0026539999525994062,
      -0.025899332016706467,
      0.06576525419950485,
      -0.08043564110994339,
      -0.03189590573310852,
      -0.014497886411845684,
      -0.041958581656217575,
      -0.060355208814144135,
      -0.048010628670454025,
      -0.02588306926190853,
      -0.024568302556872368,
      0.06319363415241241,
      -0.002825318370014429,
      0.033772435039281845,
      0.04219360277056694,
      0.023446310311555862,
      -0.011342314071953297,
      -0.045886751264333725,
      0.06519050896167755,
      0.04388577118515968,
      0.03411703556776047,
      -0.01329295989125967,
      0.00889697764068842,
      -0.06898021697998047,
      0.009301924146711826,
      -0.053013622760772705,
      -0.037471648305654526,
      0.09899303317070007,
      -0.04779631271958351,
      -0.01608194224536419,
      -0.04456009715795517,
      -0.04152296110987663,
      -0.04612560570240021,
      -0.07281215488910675,
      0.0893457755446434,
      -0.1110742911696434,
      -0.041954752057790756,
      -0.0016824305057525635,
      0.005399956367909908,
      -0.03554627671837807,
      -0.025859735906124115,
      0.0365256667137146,
      -0.028813468292355537,
      0.004348774440586567,
      -0.025386692956089973,
      -0.03388188034296036,
      0.04358872398734093,
      0.022378645837306976,
      0.05901281535625458,
      0.05682098865509033,
      -0.05287977308034897,
      0.018046734854578972,
      0.09694268554449081,
      -0.030506694689393044,
      -0.13092240691184998,
      0.03828724846243858,
      -0.04238564893603325,
      -0.014394608326256275,
      0.03610866516828537,
      -0.02527538686990738,
      -0.04571803659200668,
      -0.02261422760784626,
      -0.009864181280136108,
      0.06834810227155685,
      -0.007186266593635082,
      -0.02149047702550888,
      0.020860275253653526,
      0.060999490320682526,
      -0.032829418778419495,
      0.030591202899813652,
      0.013644102960824966,
      -0.06456193327903748,
      0.0174021627753973,
      -0.011334297247231007,
      -0.0012880940921604633,
      0.08048610389232635,
      0.020691990852355957,
      0.03541393578052521,
      -0.012440032325685024,
      0.0016689348267391324,
      0.04806368052959442,
      -0.04056248813867569,
      0.09116451442241669,
      -0.02683096006512642,
      -0.07613226771354675,
      0.03358316048979759,
      0.037575237452983856,
      -0.02667893096804619,
      -0.03144076466560364,
      -0.011983518488705158,
      0.04384669288992882,
      0.0645071417093277,
      0.009272843599319458,
      -0.04699946194887161,
      0.01519013661891222,
      -0.047947958111763,
      -0.026584260165691376,
      0.012395855039358139,
      0.0911218672990799,
      -0.04423947632312775,
      0.06600634008646011,
      -0.028559422120451927,
      -0.04568745940923691,
      0.11232659220695496,
      0.024330416694283485,
      -0.0038843629881739616,
      0.04441196843981743,
      -0.040585532784461975,
      -0.014597221277654171,
      0.06818824261426926,
      0.029141349717974663,
      -0.0011438674991950393,
      0.05518306419253349,
      -0.04316583648324013,
      0.025131482630968094,
      0.06909918785095215,
      -0.040912922471761703,
      0.066599041223526,
      -0.03143593296408653,
      0.02093692123889923,
      0.0447067990899086,
      0.05342615023255348,
      -0.058044131845235825,
      0.09460248053073883,
      -0.02022439055144787,
      -0.015003002248704433,
      0.028741877526044846,
      -0.021809522062540054,
      -0.0375099703669548,
      -0.0597965307533741,
      0.025232860818505287,
      0.02339055761694908,
      0.01884576305747032,
      -0.0746334046125412,
      -0.00979612022638321,
      -0.010327390395104885,
      0.009764261543750763,
      -0.008262593299150467,
      -0.02902274765074253,
      0.03644446283578873,
      -0.02850102074444294,
      0.047478172928094864,
      -0.030159229412674904,
      0.020911497995257378,
      0.05085034668445587,
      -0.05111612379550934,
      0.042313896119594574,
      -0.04233980178833008,
      -0.005964187439531088,
      -0.006418696604669094,
      -0.018511565402150154,
      0.048976849764585495,
      -0.03983185440301895,
      0.017062822356820107,
      0.07142001390457153,
      0.038449905812740326,
      -0.03152190521359444,
      -0.014216583222150803,
      0.05904918164014816,
      -0.03422887995839119,
      -0.009521331638097763,
      0.002622218569740653,
      0.024512875825166702,
      -0.007280832156538963,
      0.0028001382015645504,
      0.05087541788816452,
      0.0032680833246558905,
      -0.10431747883558273,
      0.00023572033387608826,
      -0.031129464507102966,
      -0.03597956523299217,
      -0.026937711983919144,
      -0.008213158696889877,
      0.04009123519062996,
      -0.08091943711042404,
      0.04957924783229828,
      0.07315931469202042,
      0.026593251153826714,
      0.014501146040856838,
      0.02453351952135563,
      0.039349380880594254,
      0.02317005954682827,
      -0.017094574868679047,
      0.026434941217303276,
      -0.058388058096170425,
      0.0006613489240407944,
      0.011724337935447693,
      0.06679749488830566,
      0.022161584347486496,
      -0.014276663772761822,
      0.051521386951208115,
      0.059653591364622116,
      -0.0409451387822628,
      0.03801419585943222,
      0.03461227938532829,
      -0.09236548095941544,
      0.003014418762177229,
      0.060451190918684006,
      -0.044369619339704514,
      -0.04092588275671005,
      -0.1139933243393898,
      0.05309074744582176,
      -0.05640195310115814,
      -0.03350163251161575,
      0.0433938167989254,
      -0.021890250965952873,
      -0.07200182974338531,
      0.043507687747478485,
      0.01890903152525425,
      0.024681951850652695,
      0.03808769956231117,
      0.02942384034395218,
      -0.02811749279499054,
      0.0002839826629497111,
      -0.03807729482650757,
      -0.018263855949044228,
      0.0067818439565598965,
      -0.04699607565999031,
      -0.06900174915790558,
      -0.01828044094145298,
      -0.07425319403409958,
      0.04283302277326584,
      -0.036797720938920975,
      0.048529524356126785,
      -0.04140153154730797,
      0.05139396712183952,
      0.024568088352680206,
      -0.03772890940308571,
      0.011918066069483757,
      0.043854352086782455,
      -0.024519670754671097,
      0.02098541334271431,
      -0.11959322541952133,
      0.07173263281583786,
      0.11279033124446869,
      0.010075741447508335,
      -0.06634322553873062,
      -0.006322664208710194,
      0.06933995336294174,
      -0.005500973202288151,
      0.0703541710972786,
      -0.04228607937693596,
      0.04888284578919411,
      0.050546158105134964,
      0.05355049669742584,
      -0.027056127786636353,
      0.009141131304204464,
      -0.0023624091409146786,
      0.07876412570476532,
      -0.10712692141532898,
      0.04497996345162392,
      -0.04599815234541893,
      0.08165598660707474,
      0.013680744916200638,
      -0.14613454043865204,
      -0.00401986064389348,
      0.047624845057725906,
      0.02453666180372238,
      -0.02251887507736683,
      0.022631913423538208,
      -0.02289545349776745,
      0.01349691767245531,
      0.07233824580907822,
      0.05322436988353729,
      -0.03450837731361389,
      0.08475671708583832,
      -0.02698339708149433,
      0.052087508141994476,
      0.028681427240371704,
      0.007185454946011305,
      -0.03423260152339935,
      -0.030484942719340324,
      -0.01660740002989769,
      -0.010017317719757557,
      0.017434822395443916,
      -0.06682202965021133,
      -0.0060964482836425304,
      0.040906019508838654,
      0.06521923840045929,
      0.011391382664442062,
      -0.06116992607712746,
      -0.02495207078754902,
      -0.02930464968085289,
      0.024270320311188698,
      -0.02428463101387024,
      -0.01169169507920742,
      -0.006621392909437418,
      -0.025294724851846695,
      -0.022497650235891342,
      -0.034478992223739624,
      0.014674647711217403,
      -0.0398360900580883,
      0.041676539927721024,
      -0.015683121979236603,
      -0.00400115828961134,
      -0.02037353254854679,
      -0.008055333979427814,
      0.013146985322237015,
      0.04290475323796272,
      0.053903721272945404,
      0.031768448650836945,
      -0.01959201693534851,
      -0.01570027880370617,
      0.016393130645155907,
      -0.01831500232219696,
      0.023312320932745934,
      -0.015882058069109917,
      0.02958604134619236,
      0.0007423574570566416,
      -0.009887480176985264,
      -0.03751913830637932,
      -0.05311309173703194,
      0.04084359481930733,
      -0.05318571999669075,
      -0.04509591683745384,
      0.01251709833741188,
      0.0025651983451098204,
      -0.019484810531139374,
      -0.038689061999320984,
      -0.014278790913522243,
      0.04214462265372276,
      0.06770939379930496,
      -0.04648861289024353,
      -0.028767595067620277,
      0.009659558534622192,
      0.034215252846479416,
      -0.027563681825995445,
      -0.005371671635657549,
      -0.08950552344322205,
      -0.002530390163883567,
      0.0453895628452301,
      -0.044712334871292114,
      0.012898487038910389,
      0.017078032717108727,
      0.006786458194255829,
      -0.0013035702286288142,
      0.027314530685544014,
      0.06154473498463631,
      -0.08478278666734695,
      0.02209368161857128,
      0.02523709088563919,
      0.035937994718551636,
      -0.04295850545167923,
      0.04835309088230133,
      -0.017770517617464066,
      0.09673034399747849
    ],
    [
      0.038896866142749786,
      -0.03478005528450012,
      0.032705750316381454,
      0.013802984729409218,
      -0.030340922996401787,
      -0.0768095850944519,
      -0.0776839330792427,
      -0.005445585120469332,
      -0.07606334984302521,
      -0.014065276831388474,
      -0.010145583190023899,
      0.008731180801987648,
      -0.07919418811798096,
      -0.04435819759964943,
      0.0414004847407341,
      0.02951943688094616,
      -0.03438903018832207,
      0.030910365283489227,
      -0.0855495035648346,
      0.0018683032831177115,
      -0.03848981484770775,
      0.04629341512918472,
      -0.10055724531412125,
      0.02691097930073738,
      -0.10508698970079422,
      -0.0012164718937128782,
      0.008381571620702744,
      -0.004388852044939995,
      -0.0427534319460392,
      -0.035155441612005234,
      0.04104083403944969,
      0.011845549568533897,
      0.08152515441179276,
      0.033063121140003204,
      -0.016530996188521385,
      -0.0021492396481335163,
      0.06280938535928726,
      -0.058677852153778076,
      -0.0686815083026886,
      -0.0029275116976350546,
      0.03486661612987518,
      -0.04568704590201378,
      0.036506034433841705,
      0.0038047332782298326,
      -0.05253065004944801,
      0.06627987325191498,
      0.04831186681985855,
      0.009810379706323147,
      0.09774179756641388,
      0.028141053393483162,
      -0.08401374518871307,
      -0.017950082197785378,
      -0.03012029081583023,
      -0.04643677920103073,
      0.05335526540875435,
      -0.014287235215306282,
      -0.01737349107861519,
      -0.041019972413778305,
      0.05196146294474602,
      -0.017019586637616158,
      -0.004528345074504614,
      0.04362386465072632,
      0.06282275170087814,
      -0.04297541826963425,
      0.015578554943203926,
      -0.041538916528224945,
      0.009908952750265598,
      -0.013921769335865974,
      -0.011828362941741943,
      -0.03921543061733246,
      0.015220217406749725,
      0.009294631890952587,
      -0.009710656479001045,
      0.02993234433233738,
      -0.05263889953494072,
      -0.0036976090632379055,
      -0.06044298782944679,
      -0.07592150568962097,
      0.006127095781266689,
      0.011986086145043373,
      -0.1591338962316513,
      0.00042567041236907244,
      0.06608915328979492,
      0.007246559485793114,
      -0.022404910996556282,
      0.030854683369398117,
      0.0068402402102947235,
      -0.01697688177227974,
      0.021253671497106552,
      -0.004260525107383728,
      0.037474375218153,
      -0.007966814562678337,
      -0.018946919590234756,
      0.026573430746793747,
      0.039176784455776215,
      0.10741380602121353,
      -0.007405374199151993,
      0.023894701153039932,
      0.009363599121570587,
      -0.05574154481291771,
      0.06714911013841629,
      -0.03891352191567421,
      -0.029792005196213722,
      0.020434517413377762,
      0.04821920022368431,
      0.03508416563272476,
      -0.012427075766026974,
      -0.04920220747590065,
      0.003944638650864363,
      0.05849410220980644,
      -0.014324023388326168,
      0.0439622662961483,
      0.012501220218837261,
      0.070350781083107,
      -0.018627844750881195,
      0.031224291771650314,
      -0.03875564783811569,
      0.01831229217350483,
      0.004130971617996693,
      -0.004000648856163025,
      0.09882864356040955,
      -0.08694487065076828,
      0.02861126884818077,
      -0.00610375264659524,
      -0.002291049575433135,
      0.030699053779244423,
      -0.006990151945501566,
      -0.016764363273978233,
      0.0464441291987896,
      -0.06939823925495148,
      0.06136321648955345,
      -0.06823541224002838,
      -0.04175052046775818,
      -0.034556977450847626,
      0.06742163002490997,
      -0.03826228156685829,
      0.012953180819749832,
      0.1215551346540451,
      -0.02464872971177101,
      0.055285871028900146,
      0.024844568222761154,
      -0.014234276488423347,
      -0.002478180220350623,
      0.03025560826063156,
      -0.03524107113480568,
      -0.029261058196425438,
      -0.021677779033780098,
      0.0007479719934053719,
      -0.06477475166320801,
      0.06411595642566681,
      -0.004604276269674301,
      0.009917589835822582,
      -0.05424052104353905,
      0.028406651690602303,
      -0.007479141931980848,
      -0.04731091484427452,
      -0.025101004168391228,
      0.10780125111341476,
      0.05852269381284714,
      -0.05453043058514595,
      0.04905406013131142,
      0.050646260380744934,
      -0.09840942919254303,
      -0.03448767587542534,
      0.004876667633652687,
      0.03597526252269745,
      -0.011772830970585346,
      0.01672709733247757,
      0.026954904198646545,
      -0.008361107669770718,
      0.019031008705496788,
      0.008040378801524639,
      0.010060219094157219,
      0.004442857578396797,
      -0.027052750810980797,
      0.016689065843820572,
      -0.05476793274283409,
      -0.07290587574243546,
      -0.02966243028640747,
      0.03444612771272659,
      0.07341387122869492,
      -0.03042455203831196,
      -0.01067348662763834,
      -0.04111335054039955,
      -0.021124958992004395,
      -0.02573607675731182,
      -0.038071222603321075,
      -0.07500453293323517,
      -0.1094420850276947,
      0.0801195576786995,
      -0.025211544707417488,
      0.03482351824641228,
      0.022630931809544563,
      0.02756710723042488,
      0.08857462555170059,
      -0.030418219044804573,
      0.05975334346294403,
      0.02648302912712097,
      0.007480412255972624,
      0.005652368534356356,
      -0.053111329674720764,
      -0.03545762225985527,
      -0.044201742857694626,
      -0.04959240183234215,
      0.036842990666627884,
      0.06775742769241333,
      0.002335638040676713,
      0.005450950004160404,
      -0.015140782110393047,
      0.03330991417169571,
      0.04512746259570122,
      -0.027770284563302994,
      -0.06696991622447968,
      0.029527412727475166,
      0.05314386263489723,
      -0.10184640437364578,
      -0.03809114545583725,
      -0.0165779497474432,
      0.0013488034019246697,
      0.027206795290112495,
      -0.046757809817790985,
      -0.0024779168888926506,
      0.07080311328172684,
      -0.06838437169790268,
      0.031741123646497726,
      -0.007057118229568005,
      -0.04045621305704117,
      -0.01829245127737522,
      -0.02413235604763031,
      -0.045808739960193634,
      0.01036750990897417,
      0.054585158824920654,
      0.04148487746715546,
      0.0339236743748188,
      -0.0322931632399559,
      -0.02347007766366005,
      -0.04384791851043701,
      0.08519728481769562,
      0.11824078857898712,
      0.0015173028223216534,
      0.0002842736430466175,
      -0.05172828957438469,
      0.011037418618798256,
      -0.006727026775479317,
      0.03254147991538048,
      0.09036944806575775,
      0.08766402304172516,
      -0.011174362152814865,
      -0.0306013822555542,
      -0.018223660066723824,
      0.01461017969995737,
      0.04674237221479416,
      -0.03716520220041275,
      0.036545123904943466,
      0.02482474036514759,
      -0.11623307317495346,
      -0.0278832595795393,
      -0.03654623031616211,
      -0.0193653367459774,
      -0.0064231352880597115,
      0.06956702470779419,
      0.04577846825122833,
      -0.03591201454401016,
      0.005522459279745817,
      -0.0307566337287426,
      -0.023148752748966217,
      -0.038172170519828796,
      0.01879524253308773,
      -0.06119494140148163,
      0.00926134828478098,
      0.005794080439954996,
      0.01572287641465664,
      0.018417594954371452,
      0.00011484489368740469,
      0.003861137665808201,
      0.0595250129699707,
      -0.0488298237323761,
      0.07371431589126587,
      0.006232273764908314,
      0.03477016091346741,
      0.01594436541199684,
      0.07633305341005325,
      -0.04861703887581825,
      -0.004769630264490843,
      0.08535323292016983,
      -0.12540145218372345,
      0.01421510148793459,
      0.00474075973033905,
      0.0861625224351883,
      -0.01661001332104206,
      0.03893062472343445,
      0.021322716027498245,
      0.055024370551109314,
      0.0006335251964628696,
      -0.03720404580235481,
      -0.0850127562880516,
      0.028239771723747253,
      -0.07703741639852524,
      0.007060070522129536,
      0.0447910875082016,
      -0.0584804005920887,
      0.007710141595453024,
      -0.018168088048696518,
      -0.004299395717680454,
      -0.013593808747828007,
      0.007647282909601927,
      -0.002904498018324375,
      -0.02776964381337166,
      -0.05146946385502815,
      -0.0879579409956932,
      -0.05280007794499397,
      0.03507097437977791,
      -0.016947127878665924,
      -0.015331108123064041,
      0.02426268719136715,
      -0.026272406801581383,
      -0.08055484294891357,
      0.014370926655828953,
      0.1003551185131073,
      -0.019134966656565666,
      0.03004295378923416,
      -0.0627247616648674,
      0.01595282554626465,
      -0.03557690605521202,
      -0.006905929185450077,
      0.01254566852003336,
      0.008546614088118076,
      -0.08242858946323395,
      -0.011488823220133781,
      0.010443317703902721,
      0.017124272882938385,
      0.06681438535451889,
      -0.0020939430687576532,
      -0.011806156486272812,
      -0.01567114144563675,
      -0.017659300938248634,
      0.025317871943116188,
      -0.0002857268264051527,
      0.0592588447034359,
      0.07412686944007874,
      0.06774532049894333,
      -0.026551054790616035,
      0.0011432180181145668,
      -0.002238733461126685,
      0.002695514587685466,
      -0.03726731240749359,
      0.030550066381692886,
      0.08492991328239441,
      0.011939244344830513,
      -0.022663675248622894,
      -0.007817992009222507,
      0.029668651521205902,
      -0.09566348791122437,
      -0.010365971364080906,
      0.07057860493659973,
      -0.08381157368421555,
      -0.002436855109408498,
      -0.0327487476170063,
      0.00961232092231512,
      0.006504169199615717,
      0.02161955088376999,
      -0.033756449818611145,
      -0.019816884770989418,
      -0.06949792802333832,
      -0.05443612486124039,
      -0.04589230194687843,
      0.006922645028680563,
      -0.053510718047618866,
      0.08364053815603256,
      -0.04784158989787102,
      0.03140941262245178,
      -0.06870957463979721,
      0.009608780033886433,
      -0.0052298554219305515,
      -0.005987946409732103,
      -0.03552291914820671,
      0.002150793094187975,
      -0.014606677927076817,
      -0.00409957580268383,
      -0.035823944956064224,
      0.03586503863334656,
      0.051243022084236145,
      0.07485654205083847,
      -0.0001863039069576189,
      0.08701875805854797,
      0.06319671869277954,
      -0.0009187269606627524,
      0.11404839903116226,
      -0.03321603685617447,
      -0.011768889613449574,
      -0.051209356635808945,
      -0.018667403608560562,
      0.011014902032911777,
      0.0515579879283905,
      -0.03574376553297043,
      -0.02244609221816063,
      -0.005105571821331978,
      0.017447715625166893,
      -0.011194932274520397,
      -0.06887279450893402,
      0.004253973253071308,
      0.07384204119443893,
      -0.005376446060836315,
      -0.0005655891727656126,
      0.0041371360421180725,
      0.0029260911978781223,
      -0.060936447232961655,
      -0.021661782637238503,
      -0.01218504086136818,
      -0.00902647152543068,
      -0.02980020083487034,
      -0.004415140487253666,
      0.04029283672571182,
      0.016804944723844528,
      -0.0767609253525734,
      0.09993384778499603,
      0.008189691230654716,
      -0.06049279123544693,
      0.023373346775770187,
      0.12015610933303833,
      0.03202897682785988,
      0.05288032814860344,
      -0.03820142149925232,
      0.0368785485625267,
      -0.05227717384696007,
      -0.03409222140908241,
      0.015971975401043892,
      0.008274423889815807,
      0.05332060158252716,
      -0.05686566233634949,
      -0.020728500559926033,
      -0.017689455300569534,
      0.012673747725784779,
      0.01766652800142765,
      0.011068814434111118,
      0.01809239387512207,
      0.03598296642303467,
      0.017593534663319588,
      -0.04016107693314552,
      -0.038319893181324005,
      0.04022863134741783,
      -0.02730463445186615,
      0.02699468471109867,
      0.08365972340106964,
      0.033216994255781174,
      -0.024999672546982765,
      0.022281084209680557,
      -0.009090549312531948,
      0.033848002552986145,
      -0.06282725930213928,
      -0.0028126519173383713,
      -0.048819128423929214,
      0.010166911408305168,
      -0.07243481278419495,
      0.05388922616839409,
      0.0016423487104475498,
      0.003671011421829462,
      0.029137415811419487,
      0.011049878783524036,
      0.0019625385757535696,
      0.0036121965385973454,
      -0.00309735257178545,
      0.012274002656340599,
      0.030929766595363617,
      0.06488955765962601,
      -0.051910772919654846,
      0.021366847679018974,
      0.058652523905038834,
      0.08248516917228699,
      0.09227456897497177,
      -0.017964424565434456,
      -0.04599643871188164,
      0.01687927544116974,
      0.10248706489801407,
      0.02019374631345272,
      -0.016548698768019676,
      -0.0048978314734995365,
      -0.005856148432940245,
      0.0037127325776964426,
      0.07003188133239746,
      -0.07581181079149246,
      0.008077121339738369,
      -0.02366475947201252,
      -0.10915923863649368,
      -0.002837511245161295,
      0.006596435327082872,
      0.01409956905990839,
      -0.03155503049492836,
      0.09784700721502304,
      0.0023301374167203903,
      0.02163430117070675,
      0.04174552112817764,
      0.05669727921485901,
      0.0707700103521347,
      -0.04041340947151184,
      0.1058746799826622,
      -0.08964227885007858,
      0.045271191745996475,
      0.0071149468421936035,
      -0.03200339898467064,
      0.09713223576545715,
      0.026391809806227684,
      0.026163041591644287
    ],
    [
      -0.018586304038763046,
      -0.001229266170412302,
      -0.022891679778695107,
      0.015581011772155762,
      -0.04846355319023132,
      -0.0546564981341362,
      -0.08614922314882278,
      0.003409280441701412,
      -0.004505957011133432,
      -0.016841033473610878,
      0.012862173840403557,
      -0.06289711594581604,
      -0.06950949132442474,
      0.015383964404463768,
      -0.08684605360031128,
      -0.018670568242669106,
      0.06332455575466156,
      -0.03969932347536087,
      -0.1292925626039505,
      -0.0035880033392459154,
      0.03270341455936432,
      0.009948397055268288,
      -0.0008283808710984886,
      -0.03852815181016922,
      -0.016720252111554146,
      -0.10495538264513016,
      -0.015516826882958412,
      0.06436323374509811,
      -0.033559489995241165,
      -0.08593213558197021,
      0.014608385041356087,
      -0.0630793496966362,
      0.04253625124692917,
      -0.00522900465875864,
      -0.016272449865937233,
      -0.002019535982981324,
      -0.06390663981437683,
      -0.021579638123512268,
      0.03077957220375538,
      0.00345110846683383,
      0.044306136667728424,
      0.005769774317741394,
      0.0276582520455122,
      -0.040683917701244354,
      0.046652499586343765,
      0.009056764654815197,
      0.054185014218091965,
      0.026903614401817322,
      0.07423926144838333,
      -0.09889480471611023,
      -0.10026997327804565,
      0.043930646032094955,
      0.024200784042477608,
      -0.01802341639995575,
      -0.04383942857384682,
      0.035568710416555405,
      0.02574031427502632,
      0.06409874558448792,
      0.018494896590709686,
      -0.004557898733764887,
      -0.05809497833251953,
      0.04673675075173378,
      0.03950922191143036,
      0.04714076966047287,
      0.02010231837630272,
      0.09471581876277924,
      -0.03848149999976158,
      -0.06056438013911247,
      0.042580828070640564,
      -0.02864346280694008,
      0.0034503357019275427,
      0.02795293554663658,
      -0.025352027267217636,
      -0.04221948981285095,
      0.03548978641629219,
      0.09725573658943176,
      -0.05833618715405464,
      0.013957778923213482,
      0.036023471504449844,
      0.03657054156064987,
      -0.026069706305861473,
      -0.048521772027015686,
      -0.036631785333156586,
      -0.022572245448827744,
      -0.039466772228479385,
      0.06431622058153152,
      0.007878012023866177,
      -0.06453286111354828,
      0.06134391948580742,
      -0.01665925607085228,
      -0.024101797491312027,
      -0.04377734288573265,
      0.06735780835151672,
      0.025140322744846344,
      0.05857199802994728,
      -0.01281384564936161,
      0.05753735080361366,
      -0.07905656844377518,
      -0.0764416828751564,
      -0.10749780386686325,
      0.03314768895506859,
      0.010983427986502647,
      -0.06282880157232285,
      -0.004925173241645098,
      0.10189926624298096,
      0.09408515691757202,
      -0.028617607429623604,
      -0.006426363717764616,
      -0.05641266331076622,
      0.05285850167274475,
      0.01842454820871353,
      -0.006667052861303091,
      -0.0354611836373806,
      0.014893299899995327,
      0.023657191544771194,
      0.0668460950255394,
      0.005207768641412258,
      0.04291442409157753,
      -0.02072712406516075,
      0.0658840760588646,
      -0.021359829232096672,
      0.0019781971350312233,
      0.04807469993829727,
      0.044880472123622894,
      0.0294630229473114,
      0.03475170582532883,
      0.028109973296523094,
      0.047681670635938644,
      -0.0499248243868351,
      -0.01986677013337612,
      -0.048901159316301346,
      -0.015655750408768654,
      -0.011472944170236588,
      -0.05401311069726944,
      -0.03350202366709709,
      -0.0003526372311171144,
      -0.01189927477389574,
      0.015433032065629959,
      0.03355451673269272,
      0.043088674545288086,
      -0.0239401888102293,
      0.029987579211592674,
      -0.02110304683446884,
      -0.038980428129434586,
      -0.12285830825567245,
      0.02442101016640663,
      -0.07314887642860413,
      -0.035369884222745895,
      0.023903395980596542,
      0.016549108549952507,
      0.021206023171544075,
      0.008955922909080982,
      0.004572911188006401,
      0.003656556596979499,
      -0.062164679169654846,
      0.0527707003057003,
      -0.013971869833767414,
      -0.015865705907344818,
      0.004617231898009777,
      0.02657904475927353,
      -0.03815784305334091,
      0.037168990820646286,
      -0.02759726531803608,
      -0.002785627031698823,
      -0.058492399752140045,
      0.050618965178728104,
      0.01086245197802782,
      -0.02280520275235176,
      -0.12614773213863373,
      0.0602988563477993,
      0.036111872643232346,
      0.09113658964633942,
      -0.021754378452897072,
      0.04828156903386116,
      -0.028874246403574944,
      0.06127427518367767,
      0.022656766697764397,
      0.04294002801179886,
      -0.012258731760084629,
      -0.003690039273351431,
      0.00608571944758296,
      -0.013875013217329979,
      -0.04402376338839531,
      0.04618644714355469,
      -0.0073921820148825645,
      0.004501089453697205,
      0.04075488820672035,
      -0.0016535138711333275,
      -0.04585466906428337,
      -0.013120695948600769,
      -0.028406227007508278,
      -0.041864410042762756,
      0.04790075495839119,
      0.001998221967369318,
      0.047222789376974106,
      -0.0682174488902092,
      0.041146088391542435,
      -0.004064543172717094,
      0.05116533115506172,
      -0.012666474096477032,
      -0.03154342994093895,
      0.06251857429742813,
      -0.013859939761459827,
      0.008017664775252342,
      -0.0012265820987522602,
      0.019389143213629723,
      -0.08032382279634476,
      -0.007905122824013233,
      0.011174801737070084,
      0.04082968831062317,
      0.029551642015576363,
      0.002116503193974495,
      0.013924913480877876,
      -0.04291804879903793,
      -0.03830360993742943,
      -0.013551706448197365,
      -0.01829162798821926,
      -0.0043691666796803474,
      0.03398708999156952,
      0.031737979501485825,
      -0.030391590669751167,
      0.02784370817244053,
      0.034802909940481186,
      -0.011056526564061642,
      -0.07608215510845184,
      0.006156496703624725,
      -0.06941139698028564,
      -0.05036872997879982,
      -0.06761330366134644,
      -0.043320756405591965,
      0.012041427195072174,
      0.07314667850732803,
      -0.03396846726536751,
      0.02629009447991848,
      -0.00409227330237627,
      -0.03041106089949608,
      -0.022391285747289658,
      0.0022901203483343124,
      0.09263941645622253,
      0.036107756197452545,
      -0.030314894393086433,
      0.00487525574862957,
      0.04974307492375374,
      -0.019630929455161095,
      -0.0778602585196495,
      -0.011788945645093918,
      6.321161345113069e-05,
      -0.051047183573246,
      0.04108670726418495,
      -0.06727637350559235,
      0.05224364623427391,
      0.09049607068300247,
      -0.020545389503240585,
      0.07983864098787308,
      0.03821052983403206,
      0.007547968532890081,
      -0.0010554109467193484,
      0.05825916677713394,
      -0.054869476705789566,
      -0.042287055402994156,
      0.01939525455236435,
      -0.022002965211868286,
      0.04841616749763489,
      -0.015488465316593647,
      -0.039488233625888824,
      0.005388743709772825,
      0.059699904173612595,
      0.0413840226829052,
      0.015624058432877064,
      -0.06592310965061188,
      0.0058571333065629005,
      -0.007192892488092184,
      -0.047449689358472824,
      0.026157621294260025,
      -0.1331501305103302,
      -0.09243427217006683,
      0.0807282105088234,
      -0.00626779580488801,
      -0.0002645487547852099,
      -0.01680152490735054,
      0.07884285598993301,
      0.02353730797767639,
      0.03559667244553566,
      0.03316895663738251,
      -0.028272420167922974,
      -0.0498664416372776,
      0.019274523481726646,
      -0.05747782066464424,
      0.059325993061065674,
      -0.0532122366130352,
      -0.04217156022787094,
      -0.0605350136756897,
      -0.009606264531612396,
      0.07011629641056061,
      0.016108041629195213,
      0.038475021719932556,
      -0.026875318959355354,
      0.0340900756418705,
      0.008516741916537285,
      -0.06834878027439117,
      -0.07684764266014099,
      0.032123398035764694,
      0.013067415915429592,
      0.008444361388683319,
      0.027765540406107903,
      -0.009145868942141533,
      0.06569548696279526,
      -0.11558814346790314,
      -0.06321709603071213,
      0.01180049404501915,
      -0.08159448206424713,
      0.04277034476399422,
      0.033178042620420456,
      -0.02674238011240959,
      -0.006565339397639036,
      0.017151333391666412,
      -0.05526326969265938,
      0.017045283690094948,
      0.013320794329047203,
      0.026852576062083244,
      -0.03191552311182022,
      -0.05349522456526756,
      0.04856705665588379,
      0.0011450008023530245,
      0.0029399259947240353,
      0.04015103355050087,
      -0.00026644315221346915,
      -0.01615574210882187,
      -0.004402826074510813,
      -0.018291613087058067,
      -0.027929767966270447,
      0.07177852094173431,
      -0.02496248111128807,
      -0.05747542157769203,
      -0.015105707570910454,
      -0.020171688869595528,
      0.02003503404557705,
      0.034749649465084076,
      -0.009676563553512096,
      -0.013654792681336403,
      0.10207714885473251,
      -0.03256906941533089,
      -0.07565111666917801,
      0.004438877571374178,
      -0.03623531758785248,
      -0.010526510886847973,
      -0.0050536938942968845,
      -0.015741832554340363,
      0.04224071651697159,
      -0.017963578924536705,
      0.031071903184056282,
      -0.01893736608326435,
      0.05230060964822769,
      -0.012918501161038876,
      0.06534042954444885,
      -0.0433197095990181,
      -0.010477688163518906,
      -0.02963300235569477,
      0.00966933835297823,
      0.033205047249794006,
      -0.0466843880712986,
      -0.03426540642976761,
      -0.07730120420455933,
      -0.025699347257614136,
      0.0053110988810658455,
      0.03317216411232948,
      -0.02147204615175724,
      -0.06380772590637207,
      -0.07784387469291687,
      -0.003344048745930195,
      0.007134821265935898,
      -0.002486361423507333,
      -0.0056512076407670975,
      0.057009968906641006,
      -0.0301353819668293,
      -0.09966318309307098,
      -0.04612857475876808,
      -0.05270351842045784,
      0.01732313074171543,
      0.007047285325825214,
      -0.05212465673685074,
      -0.03794117644429207,
      -0.02085823006927967,
      0.042173635214567184,
      -0.0006071091629564762,
      0.004818628542125225,
      0.044622208923101425,
      -0.028366411104798317,
      -0.02954590506851673,
      -0.04306033253669739,
      -0.01594056561589241,
      -0.007228810805827379,
      -0.017365029081702232,
      0.02953866869211197,
      0.05297424644231796,
      0.005250834859907627,
      -0.012247692793607712,
      0.020645318552851677,
      0.01497766561806202,
      -0.03331911563873291,
      0.08400929719209671,
      0.046851951628923416,
      -0.04577706381678581,
      -0.010473042726516724,
      0.048574477434158325,
      -0.07994227111339569,
      -0.07276317477226257,
      -0.04776308313012123,
      0.07096365094184875,
      -0.06195724755525589,
      -0.0015661357901990414,
      0.008539990521967411,
      -0.051315661519765854,
      0.09847106784582138,
      -0.011932303197681904,
      -0.05934128537774086,
      0.03590885177254677,
      -0.045387860387563705,
      -0.12077232450246811,
      0.069273442029953,
      -0.015195684507489204,
      -0.010706966742873192,
      -0.018624914810061455,
      -0.014687045477330685,
      -0.014303474687039852,
      0.0595611035823822,
      -0.04056553915143013,
      -0.02326800860464573,
      -0.012006299570202827,
      -0.09435142576694489,
      0.02515275403857231,
      0.055566344410181046,
      0.022783730179071426,
      0.002782596042379737,
      0.012443315237760544,
      0.03130967170000076,
      0.04883905500173569,
      0.028206219896674156,
      -0.05406763777136803,
      0.09263843297958374,
      0.006829199381172657,
      -0.010109537281095982,
      0.062118496745824814,
      0.04041939228773117,
      -0.0375245176255703,
      -0.05180364474654198,
      0.026389148086309433,
      -0.05468684434890747,
      -0.026635145768523216,
      0.008763968013226986,
      0.0060094245709478855,
      0.00293031451292336,
      0.06773190200328827,
      0.07337815314531326,
      0.00998678058385849,
      0.011263146065175533,
      -0.04282207787036896,
      -0.03320571780204773,
      -0.07867378741502762,
      -0.07522482424974442,
      -0.00019061684724874794,
      -0.008415848948061466,
      -0.0076952711679041386,
      0.04499541223049164,
      0.0036635396536439657,
      -0.02196086198091507,
      0.08808634430170059,
      0.047966547310352325,
      -0.010467508807778358,
      -0.02628069929778576,
      -0.06302069872617722,
      -0.003369088750332594,
      0.006026670802384615,
      0.03037313185632229,
      -0.04846061393618584,
      0.030747760087251663,
      -0.03165839612483978,
      -0.018766390159726143,
      -0.0016636818181723356,
      0.07386189699172974,
      -0.009730921126902103,
      0.024628503248095512,
      0.025528229773044586,
      -0.03913561627268791,
      0.026687689125537872,
      0.028571924194693565,
      0.0542367622256279,
      0.0054319677874445915,
      0.01497462298721075,
      0.03123553842306137,
      -0.02555912546813488,
      -0.010373054072260857,
      0.005784071050584316,
      -0.027370858937501907,
      0.04319518804550171,
      -0.020748116075992584,
      -0.03632530942559242,
      0.03656941279768944,
      0.014066888019442558,
      0.06812559068202972,
      0.015409386716783047,
      -0.10542220622301102,
      0.05528949573636055
    ],
    [
      0.036529865115880966,
      0.02248155139386654,
      -0.033274661749601364,
      -0.056098826229572296,
      0.03174677863717079,
      -0.08831906318664551,
      -0.001628924161195755,
      -0.04460548236966133,
      -0.02077055722475052,
      0.00030699942726641893,
      0.040508151054382324,
      -0.03398500382900238,
      -0.08594667166471481,
      0.02706178091466427,
      0.12443529069423676,
      0.03539259359240532,
      0.04294659197330475,
      0.019035745412111282,
      -0.05238160118460655,
      0.022592533379793167,
      0.009454679675400257,
      -0.024595197290182114,
      0.05672943964600563,
      0.032867275178432465,
      -0.073064424097538,
      0.0014258951414376497,
      0.11427229642868042,
      0.0010750520741567016,
      -0.05532927066087723,
      -0.007318489253520966,
      -0.008540594950318336,
      0.05123123526573181,
      -0.0353454165160656,
      0.0008311645942740142,
      -0.022716181352734566,
      0.03671099990606308,
      -0.009754166007041931,
      0.04894036799669266,
      0.04197456315159798,
      0.019475342705845833,
      -0.04507431015372276,
      -0.007879681885242462,
      -0.003713192418217659,
      -0.02367125079035759,
      -0.019340641796588898,
      0.0471685528755188,
      0.0018516600830480456,
      -0.011419492773711681,
      -0.0034411163069307804,
      0.036473922431468964,
      -0.06724940240383148,
      -0.031071674078702927,
      -0.04438190907239914,
      0.028795691207051277,
      -0.0023847101256251335,
      0.026836678385734558,
      0.017533427104353905,
      0.033625680953264236,
      0.020355047658085823,
      -0.054661255329847336,
      0.07250189781188965,
      0.002379543846473098,
      0.015557568520307541,
      -0.003336674300953746,
      -0.038989096879959106,
      -0.07841815799474716,
      -0.0009371213964186609,
      -0.05379513278603554,
      0.010443395003676414,
      -0.028063075616955757,
      -0.008143938146531582,
      0.07149156183004379,
      0.04709116742014885,
      0.01778288744390011,
      3.9350685256067663e-05,
      -0.062130123376846313,
      0.028275396674871445,
      -0.04879863187670708,
      0.05481807887554169,
      0.0395372249186039,
      0.016998663544654846,
      -0.0350709967315197,
      0.03026769682765007,
      0.018168099224567413,
      -0.029713943600654602,
      -0.0464635007083416,
      -0.05917557701468468,
      -0.009858482517302036,
      0.028621166944503784,
      -0.01978047378361225,
      -0.009869632311165333,
      0.03747464343905449,
      0.01701156049966812,
      0.023213114589452744,
      0.003589587053284049,
      0.00343782058916986,
      0.06949924677610397,
      0.04040016606450081,
      -0.030312729999423027,
      -0.020026003941893578,
      -0.026316506788134575,
      -0.06311541795730591,
      -0.07530321925878525,
      -0.10168087482452393,
      0.027855198830366135,
      -0.05162562429904938,
      -0.11976522207260132,
      0.08936331421136856,
      0.01852159947156906,
      0.039097629487514496,
      0.0711793452501297,
      -0.024637149646878242,
      0.056863974779844284,
      0.05351176857948303,
      -0.07225260883569717,
      0.02435123361647129,
      0.016748111695051193,
      0.05148084834218025,
      -0.07292671501636505,
      -0.0611589215695858,
      0.15263478457927704,
      -0.04368911311030388,
      0.01110769808292389,
      -0.02997766062617302,
      0.07456421852111816,
      -0.04904577136039734,
      -0.01008909847587347,
      -0.007529241498559713,
      0.0155331427231431,
      -0.09271891415119171,
      0.09344030916690826,
      0.05509740114212036,
      0.0740862786769867,
      -0.10123178362846375,
      0.03974997624754906,
      -0.04775876924395561,
      -0.012066259980201721,
      0.009561380371451378,
      0.02632914111018181,
      0.05039706453680992,
      -0.014061514288187027,
      0.04701749235391617,
      0.010530614294111729,
      0.02181396819651127,
      -0.06554806232452393,
      -0.03956359997391701,
      -0.02390751801431179,
      -0.009148095734417439,
      0.03399265557527542,
      -0.007338176015764475,
      -0.03657124936580658,
      0.03420611843466759,
      0.0480119064450264,
      0.017659936100244522,
      -0.05680437386035919,
      -0.05446658283472061,
      0.04734599217772484,
      -0.012744873762130737,
      -0.005432089790701866,
      -0.03257865086197853,
      0.033292364329099655,
      -0.06346891820430756,
      -0.08750160783529282,
      0.007571718655526638,
      -0.0005053576896898448,
      -0.00793924368917942,
      0.0373266264796257,
      -0.03472935035824776,
      -0.04942458122968674,
      0.013513505458831787,
      -0.004422956611961126,
      -0.015066726133227348,
      -0.029471538960933685,
      -0.015193982049822807,
      0.02554064244031906,
      0.04061097651720047,
      0.028257887810468674,
      -0.002428502542898059,
      -0.027473952621221542,
      0.05531380698084831,
      0.03407054394483566,
      0.03612327575683594,
      0.002361634746193886,
      0.07334671169519424,
      0.02161683514714241,
      0.022438976913690567,
      0.07063502073287964,
      -0.03089410811662674,
      0.047449592500925064,
      0.008657926693558693,
      -0.06564049422740936,
      0.04698023200035095,
      0.02939778007566929,
      0.04223431646823883,
      -0.1369933784008026,
      -0.024114668369293213,
      -0.000840102497022599,
      0.0406724251806736,
      0.05865424498915672,
      -0.02232387103140354,
      0.0693429484963417,
      0.024069426581263542,
      -0.10261774063110352,
      0.010997980833053589,
      0.017333384603261948,
      0.014064065180718899,
      -0.026269705966114998,
      0.02509303390979767,
      0.021325763314962387,
      0.09132466465234756,
      0.05199788510799408,
      -0.016972742974758148,
      -0.009348483756184578,
      -0.013413364998996258,
      0.030161527916789055,
      -0.048679593950510025,
      -0.04254060611128807,
      -0.02978615276515484,
      -0.11511532217264175,
      0.019977785646915436,
      0.042149320244789124,
      0.022221194580197334,
      -0.02605213224887848,
      0.0555177666246891,
      0.018027398735284805,
      0.050276871770620346,
      -0.06995315849781036,
      -0.02248096652328968,
      0.0446307472884655,
      -0.0814443901181221,
      0.012033876031637192,
      -0.03905376419425011,
      -0.010982967913150787,
      0.0020833665039390326,
      -0.03799604997038841,
      -0.031211726367473602,
      -0.029894081875681877,
      -0.00819186121225357,
      -0.015528041869401932,
      0.022765008732676506,
      0.022075727581977844,
      -0.04471838101744652,
      0.060970764607191086,
      -0.02682582102715969,
      0.005189011804759502,
      0.05012001097202301,
      -0.012977257370948792,
      0.03446102514863014,
      0.007923540659248829,
      -0.033248234540224075,
      0.05258679762482643,
      -0.013129498809576035,
      -0.07864823937416077,
      0.030487073585391045,
      -0.0032811625860631466,
      -0.013925816863775253,
      -0.07065135985612869,
      0.06604941934347153,
      -0.012688692659139633,
      0.04400234669446945,
      -0.05080770328640938,
      -0.015890730544924736,
      0.022909626364707947,
      -0.023565717041492462,
      -0.001169020077213645,
      0.058174628764390945,
      -0.014596273191273212,
      -0.03575786203145981,
      0.03313899785280228,
      -0.024070758372545242,
      -0.025962913408875465,
      -0.012377937324345112,
      0.01130193006247282,
      -0.031020641326904297,
      -0.07247217744588852,
      0.023015670478343964,
      0.04267629608511925,
      -0.037042926996946335,
      -0.05346284806728363,
      -0.04724928364157677,
      0.03712112829089165,
      -0.02989776059985161,
      0.028757207095623016,
      0.08395623415708542,
      -0.012959958985447884,
      -0.05312412604689598,
      0.008544011041522026,
      0.12606041133403778,
      0.054571378976106644,
      0.006960732396692038,
      0.015457329340279102,
      -0.09336579591035843,
      0.04978414252400398,
      0.01998339779675007,
      0.02307952381670475,
      -0.05114367604255676,
      0.04139895737171173,
      0.08154478669166565,
      -0.03560570627450943,
      0.05489277467131615,
      -0.06762924045324326,
      0.03794354200363159,
      -0.00424440111964941,
      -0.07283905893564224,
      0.02397683635354042,
      -0.028152408078312874,
      0.006273353472352028,
      0.04057028517127037,
      0.009474926628172398,
      0.011288413777947426,
      0.04674462601542473,
      -0.05869392305612564,
      0.02927827462553978,
      0.056450024247169495,
      -0.10387786477804184,
      0.029633324593305588,
      -0.14404001832008362,
      -0.019065797328948975,
      0.01596783846616745,
      0.032329801470041275,
      -0.025397980585694313,
      0.0012082390021532774,
      0.01681193709373474,
      0.02291254885494709,
      -0.03611946851015091,
      -0.02731463313102722,
      -0.08847050368785858,
      0.0031638809014111757,
      0.08473101258277893,
      -0.055569667369127274,
      0.08905903995037079,
      0.011278660036623478,
      -0.10644650459289551,
      -0.03843573480844498,
      -0.0018939942819997668,
      -0.03249901533126831,
      0.05990103632211685,
      0.03269773721694946,
      -0.04438245669007301,
      0.025378866121172905,
      0.01798815093934536,
      0.007583890110254288,
      0.00839382316917181,
      0.03658685088157654,
      -0.011882374994456768,
      0.01995488815009594,
      -0.025778023526072502,
      0.11644291132688522,
      -0.023626571521162987,
      0.08533993363380432,
      -0.04956928268074989,
      0.044761307537555695,
      0.01699381321668625,
      0.07262837886810303,
      0.04117133468389511,
      0.0077575999312102795,
      0.007689599879086018,
      0.0048106093890964985,
      -0.07881995290517807,
      -0.0793037861585617,
      0.010058746673166752,
      0.015628483146429062,
      0.04920525476336479,
      -0.0065603675320744514,
      -0.0673510730266571,
      0.032500240951776505,
      0.022837858647108078,
      -0.01294469553977251,
      0.015894681215286255,
      -0.06078470125794411,
      0.1412544548511505,
      0.01302975881844759,
      0.0044485521502792835,
      0.04355680197477341,
      0.11359249800443649,
      -0.06384972482919693,
      -0.03817744180560112,
      -0.011860943399369717,
      -0.013713248074054718,
      -0.02547485940158367,
      -0.09369629621505737,
      -0.09035486727952957,
      0.010000973008573055,
      0.0027490861248224974,
      0.015787839889526367,
      -0.007179503329098225,
      -0.01307141873985529,
      -0.014672908000648022,
      -0.008969794027507305,
      -0.0051927887834608555,
      -0.031601469963788986,
      0.02729673869907856,
      0.06241364777088165,
      -0.023932790383696556,
      -0.04630831256508827,
      0.01600233092904091,
      -0.06311424821615219,
      0.03164580091834068,
      -0.011996671557426453,
      -0.005543165374547243,
      -0.05212967470288277,
      0.07234446704387665,
      0.026252634823322296,
      -0.0025007794611155987,
      0.018611479550600052,
      0.041747868061065674,
      -0.004495745059102774,
      0.04418803006410599,
      -0.06891220062971115,
      -0.08857119083404541,
      -0.021641656756401062,
      0.009619254618883133,
      0.006780319381505251,
      0.07640693336725235,
      0.047918032854795456,
      -0.10280870646238327,
      -0.024304158985614777,
      -0.061847787350416183,
      -0.029421698302030563,
      0.058527566492557526,
      0.008506069891154766,
      -0.04889152944087982,
      0.046874724328517914,
      0.07250270247459412,
      -0.0686575323343277,
      0.02916802279651165,
      0.012383390218019485,
      -0.07395394146442413,
      -0.04591367393732071,
      -0.05834632366895676,
      -0.03116643615067005,
      0.021626301109790802,
      -0.04283272102475166,
      -0.02653711661696434,
      0.023269042372703552,
      -0.042037155479192734,
      0.0005986635806038976,
      0.09836702793836594,
      0.009789595380425453,
      -0.06353475898504257,
      -0.11413717269897461,
      -0.021383831277489662,
      -0.01887277513742447,
      -0.0864972323179245,
      0.03151030093431473,
      -0.04213850945234299,
      -0.046898726373910904,
      -0.05313751474022865,
      -0.05551533401012421,
      -0.005981999449431896,
      -0.04389988258481026,
      -0.017436455935239792,
      -0.03177078813314438,
      -0.01290742214769125,
      0.08095511794090271,
      0.08612209558486938,
      -0.01543217059224844,
      -0.031202126294374466,
      -0.011036013253033161,
      0.05400228127837181,
      -0.050247784703969955,
      0.03788278251886368,
      -0.03839246556162834,
      0.013240810483694077,
      -0.013498177751898766,
      0.017309479415416718,
      0.05694907158613205,
      -0.01939665898680687,
      -0.023258116096258163,
      0.021049313247203827,
      -0.041389573365449905,
      0.014684782363474369,
      0.022662121802568436,
      0.004060704726725817,
      0.013419034890830517,
      -0.014828471466898918,
      -0.035173866897821426,
      0.01894625648856163,
      0.031329452991485596,
      -0.019302161410450935,
      0.017174456268548965,
      0.044008105993270874,
      0.08726458251476288,
      -0.04991626366972923,
      0.051031969487667084,
      -0.08503342419862747,
      0.012713869102299213,
      -0.010993813164532185,
      -0.025874653831124306,
      -0.035400934517383575,
      0.02059704251587391,
      -0.10887540131807327,
      -0.028916651383042336,
      0.0029467162676155567,
      0.0026376505848020315,
      -0.007032107561826706,
      -0.017672641202807426,
      -0.0012263937387615442,
      0.006515671499073505,
      0.06311320513486862,
      0.05441593378782272,
      0.06977365165948868,
      -0.02382965385913849
    ],
    [
      -0.056354787200689316,
      -0.059999022632837296,
      -0.04470264911651611,
      -0.0710325539112091,
      0.0017006986308842897,
      0.052098941057920456,
      -0.04086333140730858,
      -0.04134000837802887,
      0.05282537266612053,
      -0.011669449508190155,
      0.01415487565100193,
      -0.012300096452236176,
      -0.03815845027565956,
      0.03756002336740494,
      -0.044546518474817276,
      -0.011662513948976994,
      -0.013379158452153206,
      0.053807731717824936,
      0.013058128766715527,
      0.00761139253154397,
      -0.012401480227708817,
      0.0005084732547402382,
      -0.04341357201337814,
      -0.004560238681733608,
      -0.04088619723916054,
      -0.016401518136262894,
      -0.005616399925202131,
      0.07353459298610687,
      -0.07247355580329895,
      -0.006492213811725378,
      0.058126434683799744,
      -0.0004602435801643878,
      -0.054395101964473724,
      -0.019626028835773468,
      0.06884710490703583,
      -6.391601345967501e-05,
      -0.039868008345365524,
      -0.007392928935587406,
      -0.06871088594198227,
      0.009234185330569744,
      -0.06554678827524185,
      0.030151663348078728,
      -0.021945910528302193,
      -0.04070642590522766,
      0.06100861728191376,
      -0.023373635485768318,
      -0.003921221476048231,
      -0.056758999824523926,
      0.02806534618139267,
      0.021060828119516373,
      -0.002564706839621067,
      0.04508394002914429,
      0.004298368003219366,
      0.009791397489607334,
      -0.01679205894470215,
      0.06659319996833801,
      0.07937800884246826,
      0.08304683119058609,
      0.08285897970199585,
      0.009867104701697826,
      0.001454986515454948,
      0.06887928396463394,
      -0.05065862089395523,
      -0.01790778711438179,
      0.03096706047654152,
      0.019185436889529228,
      -0.06075994670391083,
      -0.0013120053336024284,
      0.009174431674182415,
      0.005693812854588032,
      -0.039953578263521194,
      0.1361428052186966,
      0.008014452643692493,
      -0.043164461851119995,
      -0.03296556696295738,
      0.0037229307927191257,
      0.013643321581184864,
      -0.011747263371944427,
      0.0013995161280035973,
      0.12497077137231827,
      -0.030758975073695183,
      -0.016493072733283043,
      0.019440867006778717,
      -0.06513844430446625,
      -0.0199549850076437,
      0.035426732152700424,
      0.02149404026567936,
      -0.04475439339876175,
      0.05208863317966461,
      -0.03660135343670845,
      -0.052584197372198105,
      -0.03560300171375275,
      0.07142405956983566,
      -0.034831784665584564,
      0.12074203789234161,
      0.002878411440178752,
      -0.07800830900669098,
      0.0011773427249863744,
      -0.04644075781106949,
      -0.053079988807439804,
      0.08176619559526443,
      -0.09931933134794235,
      0.0912378802895546,
      0.018307698890566826,
      0.019748954102396965,
      0.04843675345182419,
      0.04268714040517807,
      0.03576236218214035,
      -0.10658974200487137,
      0.03280359506607056,
      0.06380417197942734,
      0.03738970309495926,
      0.0019231382757425308,
      -0.04457932710647583,
      -0.0007188488380052149,
      -0.13206657767295837,
      -0.00926522072404623,
      0.028003094717860222,
      -0.016767717897892,
      -0.06824375689029694,
      0.0571446567773819,
      0.06558512896299362,
      0.07289952039718628,
      -0.15055924654006958,
      -0.019300110638141632,
      -0.003955755848437548,
      -0.01867736503481865,
      -0.05962899699807167,
      -0.05449707433581352,
      -0.025356514379382133,
      -0.015971867367625237,
      -0.035255901515483856,
      -0.028183607384562492,
      -0.008268206380307674,
      0.09044133871793747,
      -0.05397225171327591,
      0.020445114001631737,
      -0.018992582336068153,
      0.013323506340384483,
      0.0540153905749321,
      -0.02276907116174698,
      -0.004981406033039093,
      -0.022712890058755875,
      -0.09302375465631485,
      -0.025343963876366615,
      -0.06263816356658936,
      -0.006177995353937149,
      0.0041123307310044765,
      0.019837133586406708,
      -0.11061540246009827,
      -0.010210901498794556,
      -0.07853531092405319,
      0.0686759203672409,
      -0.04288652911782265,
      -0.06952176988124847,
      0.06282572448253632,
      -0.047968391329050064,
      -0.06903793662786484,
      0.029431356117129326,
      0.07810208201408386,
      -0.04815112426877022,
      -0.03134797886013985,
      -0.01675616018474102,
      0.053871799260377884,
      0.03956921771168709,
      -0.027229158207774162,
      -0.039565615355968475,
      0.04518958926200867,
      -0.049842823296785355,
      -0.039635833352804184,
      0.04301343485713005,
      0.016781140118837357,
      0.03209240734577179,
      -0.011495037004351616,
      -0.020199716091156006,
      0.019686294719576836,
      -0.04964618384838104,
      0.005156459752470255,
      -0.0004293856618460268,
      0.052253540605306625,
      0.06646007299423218,
      0.011802282184362411,
      0.06887353211641312,
      -0.09048519283533096,
      0.08077283203601837,
      -0.0470179058611393,
      0.006185745354741812,
      0.012812948785722256,
      0.042625654488801956,
      0.02237832546234131,
      0.0696624368429184,
      0.05031852051615715,
      -0.028358928859233856,
      0.034800104796886444,
      -0.005830227863043547,
      -0.03455767408013344,
      -0.0016750030918046832,
      -0.008650180883705616,
      0.05495002865791321,
      -0.005129632540047169,
      0.008476746268570423,
      0.04553007334470749,
      -0.0023171515204012394,
      0.10354216396808624,
      0.007931665517389774,
      -0.0071315583772957325,
      0.02815074287354946,
      -0.011661717668175697,
      0.023627156391739845,
      0.06282331794500351,
      0.017792081460356712,
      0.041601572185754776,
      0.0069579160772264,
      0.044994670897722244,
      -0.1745065599679947,
      0.0006332093034870923,
      -0.037114519625902176,
      0.0391741618514061,
      -0.02348538674414158,
      -0.0020107210148125887,
      0.01664978638291359,
      -0.043258875608444214,
      -0.12281254678964615,
      -0.016812780871987343,
      0.009518041275441647,
      -0.019998133182525635,
      -0.05296671763062477,
      -0.046340037137269974,
      -0.04139883816242218,
      -0.01664622686803341,
      0.038105495274066925,
      -0.04238024353981018,
      -0.012429353781044483,
      0.05351291969418526,
      0.061237674206495285,
      -0.016885114833712578,
      0.006480369716882706,
      -0.028103578835725784,
      -0.011269181966781616,
      -0.03969477862119675,
      0.041585031896829605,
      -0.005539471283555031,
      0.008654676377773285,
      -0.04083794727921486,
      -0.005855733063071966,
      -0.0059979381039738655,
      0.06991495937108994,
      0.002025441499426961,
      0.007817345671355724,
      0.013784084469079971,
      0.02801753766834736,
      -0.06396999955177307,
      -0.0255727581679821,
      -0.02753228321671486,
      -0.032402846962213516,
      0.04048849269747734,
      0.016309531405568123,
      -0.015251917764544487,
      -0.0029276234563440084,
      0.06409715116024017,
      0.041083015501499176,
      -0.025769582018256187,
      0.020667409524321556,
      -0.013040067628026009,
      0.02367638610303402,
      -0.04876749590039253,
      -0.010473961010575294,
      -0.06347783654928207,
      0.06237242370843887,
      0.07261079549789429,
      -0.01663815788924694,
      -0.0009830453200265765,
      0.023971080780029297,
      0.014815948903560638,
      -0.02723202109336853,
      0.027708105742931366,
      0.008876289241015911,
      0.03909262269735336,
      0.009846215136349201,
      0.017795538529753685,
      0.012247874401509762,
      0.10369963198900223,
      -0.001413575722835958,
      -0.03750627487897873,
      0.010984652675688267,
      -0.008552259765565395,
      0.022662056609988213,
      0.11807353794574738,
      -0.03845291957259178,
      -0.000707102008163929,
      0.11829746514558792,
      -0.03199417144060135,
      0.04043183475732803,
      -0.013476667925715446,
      -0.052567996084690094,
      -0.018581055104732513,
      0.04725361987948418,
      0.025594908744096756,
      0.014444984495639801,
      0.033334869891405106,
      -0.10954077541828156,
      0.029753904789686203,
      0.0021993587724864483,
      -0.05542762950062752,
      -0.007330953609198332,
      0.04937528446316719,
      0.007284335792064667,
      0.041026078164577484,
      0.010979657992720604,
      -0.011167090386152267,
      -0.008494239300489426,
      -0.012112028896808624,
      0.09815654903650284,
      0.07518985867500305,
      0.0436757393181324,
      0.00616066250950098,
      -0.07331285625696182,
      0.04471781477332115,
      -0.04064822942018509,
      0.002387427259236574,
      -0.0340411476790905,
      0.0882420539855957,
      -0.04251597076654434,
      0.032487042248249054,
      -0.000598537502810359,
      0.05001675337553024,
      -0.008316956460475922,
      -0.0348484180867672,
      -0.014510096982121468,
      -0.010344218462705612,
      -0.06345837563276291,
      0.04913214221596718,
      -0.03737421706318855,
      0.09894532710313797,
      -0.045975372195243835,
      -0.01723439060151577,
      -0.017344433814287186,
      0.004227516241371632,
      -0.06728596985340118,
      0.06905706226825714,
      0.03980760648846626,
      -0.016401467844843864,
      -0.004478295799344778,
      0.061799608170986176,
      0.029314078390598297,
      0.030878223478794098,
      -0.013478594832122326,
      0.1011037677526474,
      0.06021474301815033,
      0.056191541254520416,
      -0.048702821135520935,
      0.043177422136068344,
      -0.0667695477604866,
      0.04785894975066185,
      0.09829769283533096,
      -0.02844512276351452,
      0.09754468500614166,
      -0.038397472351789474,
      -0.0011265493230894208,
      0.023339461535215378,
      -0.10279834270477295,
      0.023359429091215134,
      -0.0014752885326743126,
      -0.026355227455496788,
      -0.059908028692007065,
      0.08059452474117279,
      0.003166783833876252,
      0.003969518002122641,
      -0.05867047607898712,
      0.025380300357937813,
      0.0577092245221138,
      0.04354608431458473,
      0.09094052016735077,
      0.056272588670253754,
      0.012279911898076534,
      0.023622367531061172,
      0.0468883216381073,
      -0.0282290056347847,
      -0.01558305136859417,
      -0.042014576494693756,
      -0.04583163186907768,
      0.07533694803714752,
      0.006603128742426634,
      0.04781799018383026,
      -0.0378265418112278,
      0.015398422256112099,
      0.04536353424191475,
      -0.07949591428041458,
      -0.01848430745303631,
      -0.05393850430846214,
      0.0012347075389698148,
      -0.05876101925969124,
      -0.012476801872253418,
      -0.0479886494576931,
      0.025562260299921036,
      -0.04281730204820633,
      -0.005618047434836626,
      0.012334250845015049,
      0.0590955875813961,
      0.018645256757736206,
      -0.08321280032396317,
      -0.008058331906795502,
      -0.05966518074274063,
      -0.03799977898597717,
      0.04332787171006203,
      0.02348662167787552,
      -0.035417117178440094,
      0.04432293027639389,
      0.10121667385101318,
      -0.05548805370926857,
      0.08357100933790207,
      0.09552732855081558,
      -0.06540778279304504,
      0.042581260204315186,
      -0.03826181963086128,
      -0.05283563211560249,
      -0.03440079838037491,
      0.007153778802603483,
      -0.041682127863168716,
      -0.012367530725896358,
      0.10677149146795273,
      0.0006897239363752306,
      -0.007363585289567709,
      0.00038446797407232225,
      0.02883385680615902,
      -0.015674414113163948,
      0.04832090064883232,
      0.0066506448201835155,
      0.034637175500392914,
      0.035052403807640076,
      0.009689650498330593,
      -0.02720778062939644,
      -0.05325843393802643,
      -0.04886375740170479,
      -0.07618580013513565,
      -0.022669780999422073,
      -0.017869766801595688,
      0.08605072647333145,
      -0.0016543525271117687,
      -0.007852641865611076,
      0.009374585933983326,
      0.01668686792254448,
      0.05021676793694496,
      -0.010363640263676643,
      0.07030199468135834,
      -0.04994945600628853,
      -0.05108347162604332,
      0.020644161850214005,
      -0.059806402772665024,
      -0.011370883323252201,
      0.044728826731443405,
      -0.042813852429389954,
      0.0751522108912468,
      -0.02169974334537983,
      -0.005916317459195852,
      -0.023930834606289864,
      0.06364908814430237,
      -0.03782738372683525,
      0.07645998150110245,
      0.048690441995859146,
      0.028748730197548866,
      -0.02507249265909195,
      -0.025402456521987915,
      -0.03054218739271164,
      -0.05955903232097626,
      -0.016166765242815018,
      0.038533590734004974,
      0.07654933631420135,
      0.0345105305314064,
      -0.062944196164608,
      0.0027341744862496853,
      0.015472150407731533,
      -0.03869567811489105,
      -0.0400872565805912,
      -0.01036752387881279,
      0.05627533793449402,
      -0.013451146893203259,
      -0.025776123628020287,
      -0.06160207837820053,
      0.017694763839244843,
      -0.08438277244567871,
      0.04809112846851349,
      -0.0023852456361055374,
      -0.014041840098798275,
      -0.002133056754246354,
      0.09209015965461731,
      0.056838322430849075,
      -0.06074786186218262,
      -0.017646851018071175,
      -0.056476060301065445,
      -0.031536396592855453,
      -0.1135791763663292,
      -0.029821190983057022,
      -0.04153362661600113,
      -0.04018745943903923,
      -0.012564039789140224,
      0.10182321816682816,
      0.020399948582053185,
      -0.06378696858882904,
      -0.0771423876285553,
      0.07673529535531998,
      0.04829801246523857,
      0.005197842605412006
    ],
    [
      -0.07353660464286804,
      0.0005571074434556067,
      0.05606052652001381,
      -0.029215345159173012,
      -0.06809224933385849,
      -0.008495612069964409,
      0.022875867784023285,
      -0.00544012850150466,
      0.0060865068808197975,
      0.03376045450568199,
      0.01618366129696369,
      0.07728683203458786,
      0.015353125520050526,
      -0.006173871457576752,
      0.040895987302064896,
      -0.014964709989726543,
      -0.014416827820241451,
      -0.10530512779951096,
      0.05642634630203247,
      0.027651021257042885,
      0.003295995993539691,
      0.09258800745010376,
      0.00827370397746563,
      -0.049273740500211716,
      -0.010258213616907597,
      -0.014991484582424164,
      -0.031149346381425858,
      -0.06988411396741867,
      0.055176299065351486,
      0.02061048150062561,
      0.0453774631023407,
      -0.04078507795929909,
      0.023688985034823418,
      -0.06824033707380295,
      -0.1262519210577011,
      -0.012038259766995907,
      -0.0013298009289428592,
      -0.010661150328814983,
      0.00818299688398838,
      -0.039737340062856674,
      0.06440819799900055,
      -0.04293181002140045,
      -0.04450950771570206,
      -0.034749917685985565,
      -0.06222255527973175,
      0.0004481227369979024,
      -0.0027796216309070587,
      -0.07073064893484116,
      -0.06800572574138641,
      -0.06253335624933243,
      -0.012276108376681805,
      0.011679534800350666,
      0.001292236614972353,
      0.011091905646026134,
      0.004015686921775341,
      -0.060379546135663986,
      0.06682613492012024,
      -0.06262398511171341,
      -0.09309420734643936,
      0.0018849989864975214,
      0.037762969732284546,
      0.026200151070952415,
      0.08082115650177002,
      0.010088547132909298,
      0.014327218756079674,
      0.07535174489021301,
      -0.0544251874089241,
      -5.284309736452997e-05,
      -0.05053054168820381,
      0.0001016702881315723,
      -0.05561639368534088,
      -0.0072732591070234776,
      -0.015923427417874336,
      -0.018937382847070694,
      -0.059795279055833817,
      -0.02433299832046032,
      -0.007186479400843382,
      0.03623807057738304,
      -0.045895710587501526,
      -0.03116525709629059,
      0.013270502910017967,
      0.08277514576911926,
      0.05703973397612572,
      -0.05354549363255501,
      -0.016021577641367912,
      -0.015425664372742176,
      0.05411883443593979,
      -0.04037918895483017,
      0.004999062977731228,
      -0.0017279499443247914,
      -0.040985532104969025,
      0.018884314224123955,
      0.028874827548861504,
      0.01733030192553997,
      0.08289667218923569,
      -0.02431161142885685,
      -0.017728731036186218,
      -0.034746307879686356,
      -0.004520436283200979,
      -0.03874639421701431,
      0.11203554272651672,
      -0.011985388584434986,
      0.017061257734894753,
      0.03028431534767151,
      -0.10354868322610855,
      0.12307000905275345,
      0.01790153980255127,
      0.02143876813352108,
      0.029071375727653503,
      0.029928511008620262,
      -0.003198317950591445,
      0.008668297901749611,
      0.014165240339934826,
      0.06276164948940277,
      -0.011407095938920975,
      -0.0009626632090657949,
      -0.06181086227297783,
      -0.010685880668461323,
      0.08955159783363342,
      0.020003212615847588,
      0.03911437839269638,
      -0.03431297466158867,
      0.026185326278209686,
      0.017407935112714767,
      -0.054280176758766174,
      -0.012125404551625252,
      0.03484981507062912,
      0.015728946775197983,
      -0.013341797515749931,
      -0.08573612570762634,
      -0.005241131875663996,
      -0.021935997530817986,
      0.02518671378493309,
      0.0021335622295737267,
      -0.0714489296078682,
      0.03747240826487541,
      0.023421229794621468,
      -0.025848891586065292,
      0.011588272638618946,
      -0.011848054826259613,
      -0.01647423952817917,
      -0.058053359389305115,
      -0.0173348356038332,
      0.03700913488864899,
      -0.03538026660680771,
      0.022460220381617546,
      -0.08415411412715912,
      0.08825570344924927,
      0.036994922906160355,
      -0.03239959105849266,
      0.006556484382599592,
      0.0126489894464612,
      -0.021039709448814392,
      -0.07322990149259567,
      -0.05613873526453972,
      -0.033759184181690216,
      -0.0068029239773750305,
      0.015269189141690731,
      0.024566389620304108,
      0.06858006119728088,
      0.06152928248047829,
      -0.06005728617310524,
      -0.004393989685922861,
      0.05591391399502754,
      -0.04243888705968857,
      0.02022765576839447,
      -0.05968618392944336,
      0.03467387706041336,
      -0.02031172253191471,
      -0.014158003032207489,
      -0.03965643048286438,
      0.051949769258499146,
      -0.019988931715488434,
      -0.02492356300354004,
      -0.03238895535469055,
      -0.04459301009774208,
      0.07067244499921799,
      -0.020215634256601334,
      -0.03777017816901207,
      -0.008480498567223549,
      -0.06492350250482559,
      0.004893528763204813,
      -0.06400755047798157,
      0.04929676279425621,
      0.03593922406435013,
      0.06323430687189102,
      -0.06391353905200958,
      0.01625257357954979,
      0.0847189649939537,
      -0.035944513976573944,
      0.022592423483729362,
      0.06950020045042038,
      0.0017895136261358857,
      -0.12864650785923004,
      0.038448311388492584,
      0.025052674114704132,
      -0.023284364491701126,
      0.005570428911596537,
      0.06383682787418365,
      0.06755931675434113,
      -0.012903924100100994,
      -0.022611787542700768,
      0.03291744366288185,
      -0.021497484296560287,
      5.030583270126954e-05,
      -0.0061310091987252235,
      -0.011967792175710201,
      0.02523939311504364,
      -0.055736031383275986,
      0.008502665907144547,
      -0.051098261028528214,
      0.06169430911540985,
      0.0484623908996582,
      0.011641141958534718,
      0.02569522336125374,
      -0.022056231275200844,
      0.00268567749299109,
      0.00918613187968731,
      0.046148739755153656,
      -0.02847655862569809,
      -0.0069189188070595264,
      -0.005215463228523731,
      -0.10638945549726486,
      0.046800270676612854,
      -0.040320806205272675,
      -0.05021251365542412,
      0.09591607004404068,
      -0.0815427154302597,
      -0.04653587564826012,
      -0.013953524641692638,
      -0.03877556324005127,
      -0.008469633758068085,
      -0.03244582191109657,
      0.03264728561043739,
      0.049414411187171936,
      0.025429779663681984,
      0.015411600470542908,
      0.030082212761044502,
      -0.025477172806859016,
      -0.017359400168061256,
      0.06154606118798256,
      -0.0851350948214531,
      0.03145546466112137,
      -0.015827253460884094,
      -0.030818063765764236,
      -0.013259056024253368,
      0.0409737229347229,
      -0.037409186363220215,
      -0.025146616622805595,
      -0.026215815916657448,
      -0.04147882014513016,
      0.08682932704687119,
      0.03415490686893463,
      0.007136800792068243,
      0.029206138104200363,
      -0.027818411588668823,
      0.04528305307030678,
      0.03412711247801781,
      -0.08404633402824402,
      -0.05066567659378052,
      -0.03258071467280388,
      0.049248017370700836,
      -0.011232927441596985,
      0.023376889526844025,
      -0.005350795108824968,
      0.05075658857822418,
      0.01915636658668518,
      -0.033505506813526154,
      0.016723057255148888,
      -0.011629239656031132,
      0.0036861789412796497,
      0.005645114462822676,
      0.07029109448194504,
      0.009411228820681572,
      0.010533750988543034,
      0.01817484386265278,
      -0.03929099813103676,
      -0.0171001348644495,
      0.035344935953617096,
      0.003445107489824295,
      0.040920764207839966,
      0.07306970655918121,
      0.023428527638316154,
      -0.02350439690053463,
      -0.08680134266614914,
      0.04867490381002426,
      0.008863952942192554,
      0.02733422815799713,
      -0.02719227224588394,
      -0.07363203912973404,
      0.09256397932767868,
      -0.013117465190589428,
      0.06381988525390625,
      -0.1215544193983078,
      -0.02130577340722084,
      -0.009870483539998531,
      -0.00575360469520092,
      -0.01935604028403759,
      0.014414030127227306,
      0.023853376507759094,
      -0.043556805700063705,
      -0.02362232282757759,
      -0.04436906799674034,
      0.007706378120929003,
      0.0283646360039711,
      0.024882927536964417,
      -0.06946567445993423,
      -0.030852213501930237,
      -0.06402690708637238,
      -0.022193696349859238,
      -0.030726579949259758,
      -0.025323137640953064,
      0.0205228328704834,
      0.03174547106027603,
      0.1281241476535797,
      -0.028467025607824326,
      -0.00109354208689183,
      -0.018482280895113945,
      0.02975168451666832,
      -0.04804571717977524,
      -0.03580102697014809,
      -0.008728665299713612,
      -0.08985433727502823,
      0.010822487995028496,
      0.035603176802396774,
      -0.00617942214012146,
      0.0027607146184891462,
      0.08326537907123566,
      0.030858516693115234,
      -0.0017153737135231495,
      0.01292363740503788,
      -0.04663610830903053,
      -0.041219454258680344,
      0.04815908893942833,
      0.009645549580454826,
      -0.08900908380746841,
      0.07027442008256912,
      -0.01344265230000019,
      0.07255972176790237,
      -0.00376837607473135,
      -0.018681606277823448,
      -0.026240523904561996,
      0.04869581013917923,
      0.01615174487233162,
      -0.02879774570465088,
      -0.057554472237825394,
      -0.05793844163417816,
      -0.009679590351879597,
      0.03869706019759178,
      -0.004980778321623802,
      0.09147417545318604,
      -0.007031562272459269,
      -0.018017921596765518,
      0.010058403946459293,
      0.04325839877128601,
      -0.0011510960757732391,
      0.0756092518568039,
      0.058938439935445786,
      0.08575863391160965,
      -0.050747256726026535,
      -0.06611742079257965,
      -0.037136342376470566,
      -0.039941344410181046,
      0.09812775254249573,
      0.028211576864123344,
      -0.045538853853940964,
      -0.021748116239905357,
      -0.021182116121053696,
      0.019876575097441673,
      0.05671512335538864,
      0.03333710879087448,
      0.0004287893825676292,
      0.058842115104198456,
      0.013782769441604614,
      0.009565724059939384,
      -0.0191159937530756,
      0.011966999620199203,
      0.018421605229377747,
      0.044358644634485245,
      -0.025877339765429497,
      -0.04189707711338997,
      0.04282045736908913,
      -0.0292205847799778,
      0.07552429288625717,
      0.010626218281686306,
      0.008163751102983952,
      -0.034994278103113174,
      -0.04465344920754433,
      0.01017974317073822,
      0.046155232936143875,
      -0.027188627049326897,
      0.028603674843907356,
      0.0071319472044706345,
      -0.06620802730321884,
      0.051305633038282394,
      0.11133190989494324,
      0.03333747014403343,
      0.03444235399365425,
      0.008126456290483475,
      0.00048074329970404506,
      -0.0071487328968942165,
      0.12677699327468872,
      0.028403306379914284,
      0.052022162824869156,
      0.07190249115228653,
      0.013428899459540844,
      -0.0030762450769543648,
      -0.005492403171956539,
      -0.022366445511579514,
      0.0053716194815933704,
      -0.017811980098485947,
      -0.03559773042798042,
      0.10445533692836761,
      0.013983001001179218,
      -0.033358629792928696,
      0.004682458937168121,
      0.045719921588897705,
      -0.09285406023263931,
      -0.05388946086168289,
      0.02598152868449688,
      0.0011567736510187387,
      -0.060144826769828796,
      0.034385159611701965,
      0.08891943842172623,
      -0.029782364144921303,
      -0.04501757770776749,
      0.09330455213785172,
      -0.029079217463731766,
      0.07436946034431458,
      0.040377452969551086,
      -0.07720824331045151,
      0.009223525412380695,
      0.05108729004859924,
      0.002540066372603178,
      0.053374841809272766,
      0.04047483578324318,
      0.005081321112811565,
      0.027840398252010345,
      0.0018052584491670132,
      -0.008840473368763924,
      -0.000868400267791003,
      -0.004137263633310795,
      -0.03483947739005089,
      -0.07741206139326096,
      0.0005178683204576373,
      -0.00424458272755146,
      -0.013348795473575592,
      0.04918001964688301,
      -0.12864655256271362,
      0.0035426223184913397,
      0.021552300080657005,
      -0.03574888035655022,
      -0.015307214111089706,
      -0.020302586257457733,
      0.058743834495544434,
      -0.004049604292958975,
      -0.05498168617486954,
      0.05032319575548172,
      0.040248941630125046,
      0.0002365838154219091,
      0.11817751079797745,
      -0.03500650078058243,
      0.04728010296821594,
      -0.03350191190838814,
      -0.049459535628557205,
      -0.03529409319162369,
      -0.029675845056772232,
      -0.052617501467466354,
      -0.047994133085012436,
      -0.04653066396713257,
      -0.05288149416446686,
      0.07251644134521484,
      0.08147311955690384,
      -0.0017239578301087022,
      0.035621777176856995,
      -0.0417514331638813,
      -0.01451935712248087,
      -0.01944546028971672,
      -0.019289884716272354,
      -0.04505254700779915,
      -0.005533228628337383,
      0.06841404736042023,
      -0.05261833965778351,
      0.03302633389830589,
      0.13285984098911285,
      -0.009602833539247513,
      -0.049880776554346085,
      0.058140382170677185,
      0.026111463084816933,
      0.07280639559030533,
      0.04972977563738823,
      -0.007631518412381411,
      0.010451225563883781,
      0.04073075205087662,
      0.029248826205730438,
      -0.006663012318313122,
      -0.04025271162390709,
      -0.0530572235584259,
      -0.06610157340765,
      -0.027261819690465927,
      0.08415841311216354,
      0.0021690779831260443,
      0.09515257179737091
    ],
    [
      -0.007423861883580685,
      0.0019076023017987609,
      -0.007994655519723892,
      -0.06953181326389313,
      -0.015972496941685677,
      0.06212268024682999,
      -0.11636973172426224,
      0.018110159784555435,
      -0.06667790561914444,
      -0.017162641510367393,
      -0.036454636603593826,
      -0.06827548891305923,
      0.021363265812397003,
      0.11135940998792648,
      0.025532174855470657,
      -0.0665728747844696,
      0.06927173584699631,
      -0.008323499001562595,
      -0.007205406203866005,
      -0.04035830870270729,
      0.013365894556045532,
      0.08517211675643921,
      -0.021714812144637108,
      -0.05299852043390274,
      0.060149792581796646,
      0.040233101695775986,
      0.042643699795007706,
      0.030092336237430573,
      0.07647747546434402,
      -0.08524736016988754,
      -0.034155312925577164,
      -0.027289828285574913,
      0.014172055758535862,
      -0.030782081186771393,
      -0.006587462965399027,
      -0.0512082502245903,
      0.04360480234026909,
      -0.16496963798999786,
      -0.0040563540533185005,
      0.022528165951371193,
      0.034358855336904526,
      0.03241837024688721,
      0.04463178664445877,
      0.06648233532905579,
      -0.025182319805026054,
      -0.06447705626487732,
      -0.02084055356681347,
      -0.038607124239206314,
      -0.024343784898519516,
      0.01124513428658247,
      0.02081417106091976,
      -0.0014512436464428902,
      -0.03896233066916466,
      -0.05726199969649315,
      0.07581685483455658,
      -0.02985289879143238,
      -0.04447813332080841,
      0.03564055263996124,
      0.11475612968206406,
      0.027729300782084465,
      0.12427984178066254,
      -0.05913138389587402,
      -0.04915228858590126,
      -0.0813380554318428,
      0.008986277505755424,
      -0.05321240797638893,
      0.03793950751423836,
      0.027306152507662773,
      0.010199771262705326,
      -0.05099177360534668,
      -0.03016391210258007,
      0.027790384367108345,
      -0.0228415634483099,
      0.012460335157811642,
      0.08072929829359055,
      -0.016015740111470222,
      -0.01793641969561577,
      -0.002396905329078436,
      0.03488333150744438,
      -0.04977274313569069,
      -0.10207027196884155,
      0.004183255601674318,
      0.04179675877094269,
      -0.029512204229831696,
      -0.03911050036549568,
      0.021253682672977448,
      0.025741616263985634,
      -0.02348502352833748,
      0.09443073719739914,
      0.035754695534706116,
      -0.03948800638318062,
      -0.07697687298059464,
      0.08267442882061005,
      0.06421897560358047,
      0.04288085177540779,
      0.05585787445306778,
      -0.04632618650794029,
      -0.040274932980537415,
      0.052085116505622864,
      0.005899817682802677,
      0.012852657586336136,
      -0.032965704798698425,
      0.003830751171335578,
      0.04876100271940231,
      -0.022455723956227303,
      0.043513353914022446,
      0.03390024974942207,
      0.02009439468383789,
      -0.007975933142006397,
      0.009597749449312687,
      0.016887672245502472,
      0.006963247898966074,
      -0.0526408888399601,
      -0.02747812122106552,
      0.05091202259063721,
      -0.06538521498441696,
      -0.03864830359816551,
      -0.00798048172146082,
      0.039482202380895615,
      0.06629512459039688,
      0.09629861265420914,
      -0.11458835750818253,
      -0.014762316830456257,
      -0.003686977084726095,
      0.02012675441801548,
      -0.10647374391555786,
      -0.0293613038957119,
      0.03482523560523987,
      0.0029037101194262505,
      -0.0679083988070488,
      -0.009631662629544735,
      -0.03900054469704628,
      0.023703288286924362,
      0.017859937623143196,
      -0.031568944454193115,
      -0.020370110869407654,
      0.02961043454706669,
      -0.000878793653100729,
      -0.021818194538354874,
      0.02071879245340824,
      -0.037842344492673874,
      0.08089561015367508,
      -0.02911127358675003,
      0.01330734696239233,
      -0.07537001371383667,
      0.016910169273614883,
      -0.02154288999736309,
      -0.0026896207127720118,
      -0.06362615525722504,
      0.015525709837675095,
      0.054199978709220886,
      -0.11313527822494507,
      -0.015264680609107018,
      0.029449310153722763,
      0.029430227354168892,
      0.038306236267089844,
      0.06877198815345764,
      0.04583657160401344,
      -0.05347871407866478,
      0.0008006334537640214,
      -0.007420973852276802,
      0.05720840394496918,
      -0.0615796260535717,
      -0.08871505409479141,
      -0.016835685819387436,
      -0.11386357992887497,
      0.01849123276770115,
      -0.09038744866847992,
      0.0003318560484331101,
      -0.03221672400832176,
      0.07818616181612015,
      -0.04757475107908249,
      0.02931722067296505,
      -0.005176117643713951,
      0.05914456769824028,
      0.024772414937615395,
      0.0566343255341053,
      0.015928424894809723,
      0.02011667750775814,
      0.04989013075828552,
      0.040005434304475784,
      -0.0028464721981436014,
      0.03896346688270569,
      0.0019521478097885847,
      -0.10054818540811539,
      0.012107506394386292,
      0.005282141733914614,
      0.08199206739664078,
      0.04807185009121895,
      -0.009921888820827007,
      -0.06640298664569855,
      -0.01264659222215414,
      -0.04635946825146675,
      0.07713610678911209,
      -0.022424926981329918,
      0.017307445406913757,
      -0.06510548293590546,
      -0.02709532529115677,
      0.011304490268230438,
      -0.08822910487651825,
      -0.04882065951824188,
      0.01123015582561493,
      -0.035718996077775955,
      0.04349806159734726,
      -0.028505655005574226,
      -0.03532107546925545,
      -0.015180110931396484,
      0.033107511699199677,
      -0.03639568015933037,
      0.02150634117424488,
      -0.04802708327770233,
      0.08475157618522644,
      0.013655363582074642,
      0.02589958906173706,
      -0.014366674236953259,
      0.003435051767155528,
      0.08551618456840515,
      -0.005810726433992386,
      0.015221101231873035,
      -0.05324796214699745,
      -0.006916462909430265,
      0.04578198492527008,
      -0.011779069900512695,
      -0.04873187839984894,
      -0.00949458871036768,
      -0.03259506821632385,
      0.09691783785820007,
      -0.05526656284928322,
      0.06527463346719742,
      0.0051354230381548405,
      -0.006061563268303871,
      -0.06181301921606064,
      0.03205568715929985,
      -0.005165738984942436,
      0.025949575006961823,
      0.005369111429899931,
      -0.022399475798010826,
      -0.030004698783159256,
      -0.017313681542873383,
      -0.007559840101748705,
      -0.0468938909471035,
      -0.012779686599969864,
      0.024125931784510612,
      -0.01821126416325569,
      0.03296007961034775,
      0.0563543401658535,
      0.02752543054521084,
      0.02748296782374382,
      -0.03004678525030613,
      0.028681660071015358,
      0.02308981493115425,
      0.10417450964450836,
      0.00671310955658555,
      0.09942565113306046,
      0.018073951825499535,
      -0.010300916619598866,
      -0.02129535563290119,
      -0.010199195705354214,
      0.06270314007997513,
      0.032509807497262955,
      0.060960739850997925,
      0.021813364699482918,
      0.0921633169054985,
      -0.06451766937971115,
      0.03104974329471588,
      -0.05201235041022301,
      -0.03803867846727371,
      0.04316691309213638,
      0.10635925829410553,
      -0.0038277048151940107,
      -0.0065673538483679295,
      -0.01172813680022955,
      0.0017450520535930991,
      -0.03410213813185692,
      0.01222269143909216,
      -0.01967274211347103,
      0.12481535226106644,
      -0.05393533781170845,
      -0.04563266783952713,
      0.02406829223036766,
      0.021725764498114586,
      -0.06578397750854492,
      -0.03462592512369156,
      0.01241026446223259,
      0.03362805396318436,
      0.07338429242372513,
      -0.006867151241749525,
      0.09577220678329468,
      -0.01644514501094818,
      0.05108471214771271,
      0.06551333516836166,
      -0.04703988507390022,
      0.011301488615572453,
      -0.04244391247630119,
      -0.05315108969807625,
      -0.0005550857749767601,
      0.044360361993312836,
      -0.0004247122851666063,
      -0.04909343272447586,
      0.04698118194937706,
      -0.03667030856013298,
      0.04897366464138031,
      0.025153791531920433,
      -0.03750896826386452,
      0.03787316381931305,
      0.0013729205820709467,
      0.053301893174648285,
      0.020318644121289253,
      -0.02110537327826023,
      0.0003870443906635046,
      0.00664494140073657,
      0.031170539557933807,
      -0.03683022782206535,
      -0.012890384532511234,
      0.08835119009017944,
      -0.009879074990749359,
      -0.11732742190361023,
      -0.020075174048542976,
      -0.045420724898576736,
      0.029973847791552544,
      -0.11746466904878616,
      0.05214152857661247,
      -0.0012809570180252194,
      -0.001287164748646319,
      0.0028169334400445223,
      0.07671613991260529,
      -0.0392615906894207,
      0.019640415906906128,
      -0.023231832310557365,
      -0.026025494560599327,
      -0.033547237515449524,
      -0.035122815519571304,
      0.055744316428899765,
      -0.002708786865696311,
      -0.05443960428237915,
      0.013133808970451355,
      -0.011082909069955349,
      -0.019235072657465935,
      -0.04278437793254852,
      0.025447577238082886,
      0.013352269306778908,
      -0.0377705842256546,
      0.01674162968993187,
      8.477327355649322e-05,
      -0.11175079643726349,
      -0.030523432418704033,
      0.008662363514304161,
      0.0007363170734606683,
      0.05493129417300224,
      -0.02168240025639534,
      0.00015761912800371647,
      -0.05296025797724724,
      0.016047021374106407,
      0.07680647075176239,
      -0.031086957082152367,
      -0.03300949186086655,
      -0.04752916097640991,
      -0.07511305063962936,
      -0.012240694835782051,
      0.046926677227020264,
      0.002531401813030243,
      -0.015429189428687096,
      0.04368587210774422,
      0.017361285164952278,
      0.08878372609615326,
      -0.0007867970853112638,
      -0.04635315015912056,
      -0.10882812738418579,
      0.015684224665164948,
      0.09224977344274521,
      0.030965840443968773,
      0.10157709568738937,
      -0.01763363927602768,
      0.015431552194058895,
      0.008088944479823112,
      -0.06501331925392151,
      -0.04173498600721359,
      0.04346366226673126,
      -0.014544217847287655,
      0.02719927579164505,
      0.04327256232500076,
      -0.026474181562662125,
      -0.06063108518719673,
      -0.03818654641509056,
      -0.00047172410995699465,
      -0.01319432444870472,
      -0.006879465654492378,
      0.022632749751210213,
      0.05110342428088188,
      0.0007873355643823743,
      0.029948556795716286,
      0.03209690749645233,
      0.05660460144281387,
      0.046590112149715424,
      -0.10081556439399719,
      0.04833155497908592,
      -0.04699184000492096,
      -0.01194823905825615,
      0.04134340211749077,
      -0.07158425450325012,
      0.005682579707354307,
      -0.01939321681857109,
      -0.034528698772192,
      -0.07257360965013504,
      0.06143062934279442,
      0.03835664317011833,
      0.07132195681333542,
      -0.029402587562799454,
      -0.05085070803761482,
      -0.021359585225582123,
      -0.009929461404681206,
      0.049220237880945206,
      -0.002848783042281866,
      0.0006553942221216857,
      -0.013431788422167301,
      0.033589959144592285,
      -0.04271325469017029,
      -0.04319818690419197,
      -0.047755107283592224,
      0.09175950288772583,
      0.030595388263463974,
      0.03867211565375328,
      0.06863754242658615,
      -0.03020624630153179,
      -0.016121333464980125,
      -0.01249721646308899,
      0.02646978572010994,
      -0.02561071887612343,
      -0.07994052022695541,
      0.03813006356358528,
      -0.01539464108645916,
      -0.0013259879779070616,
      0.04958668351173401,
      0.022630028426647186,
      0.004961517173796892,
      0.040262382477521896,
      0.08133932203054428,
      -0.03692946210503578,
      0.04084388166666031,
      -0.029157333076000214,
      -0.05830151215195656,
      0.09827249497175217,
      -0.06819024682044983,
      -0.02223239652812481,
      -0.01131400279700756,
      -0.029873156920075417,
      -0.028961844742298126,
      -0.023661233484745026,
      0.0530335009098053,
      0.015742428600788116,
      0.0486612506210804,
      0.04946647211909294,
      -0.04062017798423767,
      0.0554119348526001,
      0.007407858967781067,
      -0.09269309788942337,
      -0.04599834606051445,
      0.05291184037923813,
      -0.028450535610318184,
      0.02532757632434368,
      -0.005022148601710796,
      -0.03148705139756203,
      -0.0765470415353775,
      0.007701416965574026,
      -0.02465055324137211,
      0.00744424294680357,
      -0.09473782777786255,
      -0.059337589889764786,
      -0.05372891575098038,
      0.02283589541912079,
      -0.056497398763895035,
      -0.02690996415913105,
      -0.030832737684249878,
      0.009270877577364445,
      0.023215332999825478,
      -0.007525191642343998,
      0.00846370030194521,
      0.05475888028740883,
      0.007775170262902975,
      -0.007405151147395372,
      -0.03419122472405434,
      0.06661222875118256,
      -0.025661176070570946,
      -0.03887691721320152,
      0.026066569611430168,
      -0.026150988414883614,
      0.03577195852994919,
      0.01952018216252327,
      0.007329649291932583,
      -0.0070313988253474236,
      0.024711936712265015,
      -0.0027091510128229856,
      0.03958439454436302,
      0.022211993113160133,
      0.017581049352884293,
      -0.05270468816161156,
      0.008647818118333817,
      0.028222234919667244,
      -0.024305740371346474,
      -0.05960499867796898,
      -0.00908270850777626,
      0.03728010505437851,
      0.04203950613737106
    ],
    [
      -0.07901427894830704,
      0.02265547774732113,
      0.0067406101152300835,
      0.02221999317407608,
      0.004775633569806814,
      0.03561430424451828,
      0.044874437153339386,
      -0.02583768032491207,
      -0.02745881862938404,
      0.01005026139318943,
      0.05180661007761955,
      0.023493103682994843,
      0.022499769926071167,
      0.03110164776444435,
      -0.07221043109893799,
      0.025958111509680748,
      0.004811090882867575,
      -0.025015441700816154,
      -0.07508860528469086,
      0.019185004755854607,
      -0.048165008425712585,
      0.016190828755497932,
      0.018097789958119392,
      -0.0248139388859272,
      -0.11848165094852448,
      0.005554775707423687,
      -0.027666714042425156,
      0.08749847859144211,
      0.09956406056880951,
      -0.03216876462101936,
      0.06959594786167145,
      -0.006501291412860155,
      0.024367570877075195,
      -0.042880017310380936,
      -0.032370224595069885,
      -0.08558333665132523,
      -0.008400042541325092,
      -0.020658332854509354,
      0.06716322153806686,
      0.014850580133497715,
      0.13694342970848083,
      0.028717292472720146,
      -0.008197752758860588,
      -0.02666209638118744,
      -0.037172041833400726,
      -0.024924645200371742,
      -0.03685013949871063,
      -0.013089394196867943,
      -0.02551918290555477,
      0.05424214527010918,
      -0.042960025370121,
      0.003680658293887973,
      0.021091090515255928,
      -0.020809689536690712,
      -0.07932834327220917,
      0.044646911323070526,
      -0.025645175948739052,
      0.03577037528157234,
      -0.12100020796060562,
      0.04011481627821922,
      -0.016569437459111214,
      0.0960586667060852,
      0.013339663855731487,
      -0.006241491064429283,
      -0.030681975185871124,
      -0.07163535058498383,
      -0.0021529318764805794,
      0.0014142486033961177,
      -0.03331243619322777,
      0.05544622242450714,
      -0.011959034949541092,
      -0.06257608532905579,
      0.07262855023145676,
      0.006782740820199251,
      0.03543426841497421,
      -0.020725015550851822,
      0.022580578923225403,
      0.006375057622790337,
      -0.08971864730119705,
      0.059595752507448196,
      -0.025285564363002777,
      -0.008129178546369076,
      0.06179129332304001,
      0.05025424063205719,
      -0.044358499348163605,
      0.04700411856174469,
      0.019743701443076134,
      0.08113373070955276,
      0.055839404463768005,
      -0.004320353735238314,
      -0.011520196683704853,
      0.06462400406599045,
      -0.013254150748252869,
      -0.00953095592558384,
      0.06939681619405746,
      0.05446504428982735,
      0.047030456364154816,
      0.09271351993083954,
      -0.014069651253521442,
      0.019927965477108955,
      0.01964164339005947,
      0.06617552042007446,
      -0.05672447010874748,
      -0.03320419415831566,
      -0.010174248367547989,
      0.04121588543057442,
      -0.062463149428367615,
      -0.08692736178636551,
      0.030207384377717972,
      -0.023678019642829895,
      0.030493885278701782,
      0.01379285380244255,
      0.05103648453950882,
      0.00022327325132209808,
      -0.01056692749261856,
      -0.02781314216554165,
      -0.07153420150279999,
      -0.05373743548989296,
      0.01904570870101452,
      -0.01876591332256794,
      0.04953719303011894,
      -0.027252133935689926,
      0.0357668437063694,
      -0.03790142387151718,
      -0.05406591668725014,
      -0.02257407270371914,
      0.036625467240810394,
      0.02445325441658497,
      -0.019716883078217506,
      0.06816275417804718,
      0.09913437813520432,
      0.01554953120648861,
      0.06034880131483078,
      -0.016606204211711884,
      0.042649511247873306,
      -0.004497682210057974,
      -0.04586708918213844,
      0.08566528558731079,
      0.019249435514211655,
      -0.04256566986441612,
      -0.03270406648516655,
      -0.017545105889439583,
      0.023068638518452644,
      0.006707543507218361,
      -0.03635777160525322,
      -0.056354623287916183,
      0.013730419799685478,
      -0.01724075898528099,
      0.015466072596609592,
      0.019876863807439804,
      0.04672824963927269,
      -0.012264614924788475,
      0.003178505226969719,
      -0.05868697911500931,
      0.01925734430551529,
      -0.01822648197412491,
      -0.04687958583235741,
      0.03184179961681366,
      -0.04000183567404747,
      -0.014499157667160034,
      -0.07091668993234634,
      -0.03860676288604736,
      -0.05403575673699379,
      -0.047201454639434814,
      -0.011272517964243889,
      0.021021924912929535,
      -0.03240559622645378,
      0.040131762623786926,
      0.0024911724030971527,
      -0.05971066653728485,
      0.042363278567790985,
      0.008901499211788177,
      0.06492258608341217,
      0.09296873956918716,
      -0.024259435012936592,
      0.07354524731636047,
      -0.01821010746061802,
      0.03887809440493584,
      0.09309623390436172,
      0.09944488853216171,
      0.028612712398171425,
      0.03184353560209274,
      -0.03595762699842453,
      -0.04543121159076691,
      0.04322703555226326,
      0.01949964463710785,
      -0.011980285868048668,
      0.011521831154823303,
      0.021322645246982574,
      -0.10387096554040909,
      0.04425029084086418,
      0.07094668596982956,
      0.030351758003234863,
      -0.029389042407274246,
      -0.07091070711612701,
      -0.03780039772391319,
      -0.016909632831811905,
      -0.006992566864937544,
      -0.03263196349143982,
      0.03052695281803608,
      -0.0016838134033605456,
      -0.007938564755022526,
      -0.14082811772823334,
      0.02166271023452282,
      0.040997207164764404,
      -0.028834261000156403,
      -0.028224946931004524,
      -0.013003635220229626,
      0.04348905012011528,
      -0.017514722421765327,
      0.008804419077932835,
      0.0045827762223780155,
      0.07753241807222366,
      0.043270666152238846,
      -0.015362696722149849,
      -0.0220941212028265,
      0.110293447971344,
      0.04281635582447052,
      -0.0795280709862709,
      0.035561297088861465,
      -0.006510604172945023,
      0.00931824091821909,
      -0.04997771233320236,
      0.005687162280082703,
      -0.011581763625144958,
      -0.005981330294162035,
      0.07063870877027512,
      -0.025279786437749863,
      -0.016336383298039436,
      0.07036690413951874,
      0.10265686362981796,
      0.023329773917794228,
      -0.006984846666455269,
      -0.010136471129953861,
      -0.04530436918139458,
      0.04171052575111389,
      0.04212666675448418,
      -0.071467824280262,
      0.02076389640569687,
      -0.043337542563676834,
      0.041769642382860184,
      0.014821239747107029,
      -0.04345851391553879,
      -0.06164752319455147,
      0.007210984826087952,
      0.03279329463839531,
      -0.02256454899907112,
      -0.019909698516130447,
      -0.04117202013731003,
      -0.0005524646840058267,
      -0.05180126056075096,
      -0.005966041702777147,
      0.005005459301173687,
      -0.07462194561958313,
      -0.04930240288376808,
      0.00236136675812304,
      -0.002451616106554866,
      -0.036622438579797745,
      0.009972621686756611,
      0.02005082555115223,
      0.04130779206752777,
      -0.03631437197327614,
      -0.0023256130516529083,
      0.015784170478582382,
      -0.03634704276919365,
      0.07906930893659592,
      -0.026555892080068588,
      -0.05193140357732773,
      -0.005328687839210033,
      0.009602322243154049,
      -0.011535907164216042,
      -0.10585503280162811,
      0.039137594401836395,
      0.0037609077990055084,
      -0.011779706925153732,
      0.002284312853589654,
      0.05671355873346329,
      -0.00401703966781497,
      0.1280733346939087,
      0.05608189478516579,
      0.08278342336416245,
      -0.028953595086932182,
      0.0625808835029602,
      0.03941040113568306,
      0.03352506458759308,
      0.018193494528532028,
      -0.02517935261130333,
      0.05149325355887413,
      -0.03021118976175785,
      -0.014682652428746223,
      0.017987875267863274,
      0.0012286988785490394,
      -0.03911091759800911,
      0.004287034273147583,
      0.05674394965171814,
      -0.0006474031833931804,
      0.05625925958156586,
      0.02963527850806713,
      -0.05379991978406906,
      -0.01638704724609852,
      0.05162259563803673,
      -0.00965763721615076,
      -0.07129321247339249,
      0.011630128137767315,
      -0.026511143893003464,
      -0.04413583502173424,
      -0.08565842360258102,
      0.05951807647943497,
      -0.004329484421759844,
      0.09857133030891418,
      -0.0306980162858963,
      0.025432219728827477,
      -0.013257930986583233,
      -0.06751998513936996,
      -0.06058845296502113,
      -0.03449801728129387,
      0.056542012840509415,
      0.009688147343695164,
      -0.00982050783932209,
      -0.07514283806085587,
      -0.025917628780007362,
      0.0019571781158447266,
      0.05330641567707062,
      0.001266100094653666,
      0.035808075219392776,
      -0.1005920022726059,
      0.013513084501028061,
      0.038324907422065735,
      -0.09329491853713989,
      -0.001201240811496973,
      0.03849373385310173,
      -0.07257021218538284,
      -0.022023292258381844,
      -0.0315251462161541,
      -0.002479228423908353,
      0.011284731328487396,
      0.08846510946750641,
      0.09122095257043839,
      0.0026622877921909094,
      0.03253389894962311,
      -0.0398048534989357,
      0.019498391076922417,
      0.08580238372087479,
      -0.11697719246149063,
      -0.03979160264134407,
      0.035330675542354584,
      -0.008299089968204498,
      0.0267694890499115,
      -0.04825432598590851,
      -0.1235613152384758,
      -0.11342472583055496,
      -0.03304858133196831,
      -0.07628671824932098,
      0.030931230634450912,
      0.03297700360417366,
      0.017164621502161026,
      -0.003201361745595932,
      -0.01335838157683611,
      -0.012932158075273037,
      -0.06678090244531631,
      0.07507520169019699,
      -0.0036857817322015762,
      -0.031949374824762344,
      -0.04128343239426613,
      -0.022000130265951157,
      -0.004049330484122038,
      -0.04433763772249222,
      0.02602478489279747,
      -0.005796442739665508,
      -0.049115803092718124,
      0.051158249378204346,
      -0.03109791688621044,
      -0.04523231461644173,
      -0.07787401974201202,
      -0.0007925009704194963,
      -0.024507086724042892,
      0.03888896480202675,
      -0.04264392331242561,
      -0.06735214591026306,
      0.05112810432910919,
      -0.02269583009183407,
      -0.003817931981757283,
      -0.02918330393731594,
      0.05576000735163689,
      0.033077310770750046,
      0.044099826365709305,
      -0.017346784472465515,
      -0.092105932533741,
      0.06878899782896042,
      0.0030595106072723866,
      -0.0713215097784996,
      -0.03968844935297966,
      0.05700279772281647,
      -0.07363209128379822,
      0.01975499652326107,
      -0.047851625829935074,
      0.008200893178582191,
      0.030265118926763535,
      0.08938596397638321,
      -0.07236160337924957,
      -0.008247356861829758,
      -0.028708113357424736,
      0.0005791015573777258,
      -0.004253716208040714,
      0.01630530133843422,
      0.04945665970444679,
      0.03869810327887535,
      -0.015035182237625122,
      -0.017494510859251022,
      0.005697630811482668,
      -0.01943350024521351,
      0.0016630039317533374,
      -0.017704924568533897,
      -0.03894130885601044,
      -0.011587546207010746,
      -0.027673305943608284,
      -0.03642807528376579,
      0.08405547589063644,
      -0.048387572169303894,
      0.016475893557071686,
      0.009160702116787434,
      -0.03411801904439926,
      0.07174848765134811,
      -0.08120527863502502,
      -0.028547115623950958,
      0.0292676854878664,
      0.0794445052742958,
      0.03917368873953819,
      0.08669830113649368,
      -0.000491535582114011,
      0.05304935574531555,
      0.0950464978814125,
      -0.012896045111119747,
      -0.08042347431182861,
      -0.027035193517804146,
      -0.05525973439216614,
      0.00380507530644536,
      0.0012995889410376549,
      -0.019557639956474304,
      -0.013467324897646904,
      0.048270899802446365,
      0.012061393819749355,
      0.08831825852394104,
      0.06549680233001709,
      0.08371195942163467,
      -0.0579879991710186,
      0.05279000103473663,
      -0.03825990855693817,
      -0.04736350476741791,
      -0.03262410685420036,
      0.05535262078046799,
      -0.01000616978853941,
      -0.02199331670999527,
      0.05000399425625801,
      0.007780705578625202,
      -0.049943357706069946,
      0.04652921110391617,
      -0.004965120926499367,
      0.03635694459080696,
      -0.0002658919838722795,
      0.08491627871990204,
      0.02208424173295498,
      0.00995117798447609,
      -0.0009627180406823754,
      0.002462698146700859,
      0.01668662205338478,
      0.02213747799396515,
      -0.058448489755392075,
      -0.00025753543013706803,
      0.02827172912657261,
      -0.04749087244272232,
      -0.02127317152917385,
      0.004217421170324087,
      0.026604842394590378,
      0.042792994529008865,
      0.0006103882333263755,
      -0.030119018629193306,
      0.07665963470935822,
      -0.03885233774781227,
      0.02769041806459427,
      0.05339490994811058,
      0.09142795205116272,
      -0.015112854540348053,
      0.009855154901742935,
      0.025500917807221413,
      0.014855796471238136,
      -0.01415158435702324,
      0.002997039817273617,
      -0.0017626670887693763,
      -0.013628586195409298,
      0.010776502080261707,
      -0.024010464549064636,
      0.013534174300730228,
      -0.04671729728579521,
      0.07827381789684296,
      -0.009683090262115002,
      0.027526963502168655,
      -0.00025720716803334653,
      -0.04770616069436073,
      -0.005471609067171812,
      -0.0013632356422021985,
      0.12824614346027374,
      -0.03692387044429779
    ],
    [
      0.012933572754263878,
      0.008052265271544456,
      -0.035806868225336075,
      0.0737442821264267,
      0.053666118532419205,
      -0.012117259204387665,
      0.020857112482190132,
      0.012920456007122993,
      -0.07174834609031677,
      0.04465046525001526,
      0.03538510203361511,
      -0.059015095233917236,
      -0.025390418246388435,
      0.05658125504851341,
      -0.0794106125831604,
      0.06476400047540665,
      0.057657428085803986,
      -0.05133728310465813,
      -0.024931078776717186,
      0.013835348188877106,
      0.041987281292676926,
      -0.03601434826850891,
      -0.05817209556698799,
      0.021953588351607323,
      -0.013231063261628151,
      0.10067574679851532,
      0.023227903991937637,
      -0.006526739802211523,
      -0.08224211633205414,
      0.04824059456586838,
      0.10332950204610825,
      -0.029184957966208458,
      0.11220525950193405,
      0.038230542093515396,
      -0.05268127843737602,
      -0.007476136554032564,
      -0.03245947137475014,
      -0.03533881530165672,
      0.019884143024683,
      0.0664481446146965,
      -0.013484588824212551,
      -0.020357666537165642,
      0.04292365908622742,
      0.003703537629917264,
      -0.08175898343324661,
      -0.012367257848381996,
      0.052896276116371155,
      -0.03499578312039375,
      -0.0844300165772438,
      -0.009664124809205532,
      -0.03874892741441727,
      -0.021260451525449753,
      -0.04998411610722542,
      0.038468439131975174,
      0.013989590108394623,
      -0.05867215618491173,
      -0.01929793506860733,
      -0.008102833293378353,
      0.05388380587100983,
      0.008375529199838638,
      -0.06811225414276123,
      0.016675302758812904,
      0.03866323456168175,
      0.025481080636382103,
      0.05791075900197029,
      0.009907872416079044,
      0.021967703476548195,
      -0.005949582904577255,
      -0.0016123572131618857,
      0.008474350906908512,
      -0.07005543261766434,
      0.020373590290546417,
      0.031537752598524094,
      -0.006566123571246862,
      -0.00719469552859664,
      0.02038910612463951,
      -0.021677080541849136,
      0.035337045788764954,
      0.029276007786393166,
      -0.04339417442679405,
      -0.04614546522498131,
      -0.014008781872689724,
      -0.0049774255603551865,
      -0.07139814645051956,
      -0.05895903706550598,
      -0.011972500942647457,
      -0.03195066377520561,
      -0.08342237770557404,
      -0.0066061378456652164,
      -0.026878707110881805,
      -0.02624708227813244,
      0.008600245229899883,
      0.07670290023088455,
      -0.0518903024494648,
      0.03733459860086441,
      -0.09995945543050766,
      0.06279084831476212,
      0.0003801468410529196,
      0.012522424571216106,
      0.02320977859199047,
      -0.010760442353785038,
      -0.006192757748067379,
      -0.050797656178474426,
      0.055511388927698135,
      -0.030396288260817528,
      0.004028907045722008,
      -0.07562366127967834,
      -0.03220011666417122,
      -0.012981582432985306,
      0.07140766084194183,
      0.0750776082277298,
      -0.03416116163134575,
      -0.05034550651907921,
      -0.005170101299881935,
      -0.02933073230087757,
      -0.06268438696861267,
      -0.01051250845193863,
      0.002707179868593812,
      0.005450740456581116,
      -0.07059898227453232,
      0.12695202231407166,
      0.016101069748401642,
      0.03581124171614647,
      -0.07446015626192093,
      0.007996553555130959,
      -0.05470680072903633,
      -0.01540604792535305,
      0.009684707969427109,
      -0.028193501755595207,
      0.008589422330260277,
      0.04060155153274536,
      -0.029099376872181892,
      -0.01561402902007103,
      0.00018180972256232053,
      -0.047025613486766815,
      -0.027096271514892578,
      -0.08700045198202133,
      -0.05746641755104065,
      -0.0013326440239325166,
      -0.0021437560208141804,
      -0.025065762922167778,
      -0.025167765095829964,
      -0.01669228821992874,
      -0.0907265841960907,
      0.044353097677230835,
      -0.05340726673603058,
      -0.048579536378383636,
      0.018875380977988243,
      0.004326467402279377,
      0.018633779138326645,
      0.0011711904080584645,
      -0.02561192587018013,
      0.0313396193087101,
      -0.07631265372037888,
      -0.02692926861345768,
      -0.09930901974439621,
      -0.03706791251897812,
      0.01131615974009037,
      0.033140894025564194,
      0.0494161881506443,
      0.014034489169716835,
      -0.008534973487257957,
      -0.03240807354450226,
      0.052736490964889526,
      0.05465157330036163,
      -0.049127109348773956,
      -0.023507731035351753,
      0.014248684048652649,
      0.007954966276884079,
      0.02328055538237095,
      -0.018485398963093758,
      0.028730886057019234,
      -0.06442644447088242,
      -0.030102763324975967,
      -0.0689796432852745,
      0.05668299272656441,
      0.017855437472462654,
      0.06761608272790909,
      -0.002445645397529006,
      -0.037501782178878784,
      0.014362230896949768,
      0.06301558017730713,
      -0.05941938981413841,
      0.07387160509824753,
      -0.0709814801812172,
      0.00448186369612813,
      -0.03476639464497566,
      -0.02401498332619667,
      0.048569969832897186,
      0.00807358417659998,
      -0.02133636176586151,
      0.009160512126982212,
      0.06338075548410416,
      -0.032150741666555405,
      0.019761597737669945,
      0.03261880576610565,
      0.04679374396800995,
      -0.011425420641899109,
      0.05834969878196716,
      0.0620240792632103,
      -0.03862113133072853,
      0.03339463472366333,
      -0.01384714525192976,
      0.0650879517197609,
      -0.05791088193655014,
      0.011185725219547749,
      -0.01038955058902502,
      -0.020225562155246735,
      0.01638847216963768,
      -0.058488357812166214,
      0.005945544224232435,
      0.08715663850307465,
      0.030131952837109566,
      0.10008654743432999,
      0.019868947565555573,
      -0.02430318482220173,
      0.0011657830327749252,
      -0.042829521000385284,
      0.1164216697216034,
      0.001824602484703064,
      0.012659418396651745,
      -0.09167424589395523,
      -0.001468222588300705,
      0.004536298103630543,
      -0.08244222402572632,
      0.016332823783159256,
      0.000613623415119946,
      -0.06443548202514648,
      0.018946630880236626,
      -0.014568986371159554,
      -0.09893172979354858,
      0.04050465300679207,
      0.0821080431342125,
      0.06924395263195038,
      0.03619060665369034,
      0.04377749189734459,
      -0.018564099445939064,
      0.04303333908319473,
      0.03673776611685753,
      0.005134413484483957,
      0.11221112310886383,
      -0.08673632889986038,
      0.04341009259223938,
      0.021141139790415764,
      0.004231058061122894,
      0.007736580446362495,
      -0.004547533579170704,
      -0.0767054557800293,
      0.033048249781131744,
      0.03488381206989288,
      -0.007456371560692787,
      0.02810719422996044,
      -0.05150371417403221,
      -0.052655234932899475,
      -0.0030854528304189444,
      -0.042303964495658875,
      -0.034635983407497406,
      -0.055611126124858856,
      -0.05193949118256569,
      -0.005747613962739706,
      0.04524517059326172,
      -0.04084545746445656,
      0.012064273469150066,
      0.04840259253978729,
      -0.026248395442962646,
      -0.01090329047292471,
      0.013527601025998592,
      -0.03262860327959061,
      -0.020111219957470894,
      0.07235154509544373,
      -0.03530598431825638,
      0.06645791977643967,
      0.05511278286576271,
      -0.01686890795826912,
      0.009703670628368855,
      0.03243083134293556,
      0.03149668872356415,
      0.03076915256679058,
      -0.03037305362522602,
      0.009455719962716103,
      -0.006395646370947361,
      0.07902922481298447,
      -0.0691116601228714,
      0.005213232710957527,
      -0.013427688740193844,
      -0.012179181911051273,
      0.02238558419048786,
      -0.013758468441665173,
      0.04844928905367851,
      0.03965351730585098,
      0.026150405406951904,
      0.004086269531399012,
      -0.04504887014627457,
      -0.004659539554268122,
      0.005186946131289005,
      -0.008627708069980145,
      -0.03247345983982086,
      0.003964346833527088,
      -0.0712251290678978,
      0.03166494145989418,
      -0.08451971411705017,
      0.047812677919864655,
      0.039307478815317154,
      -0.08354433625936508,
      -0.045833770185709,
      0.03794297203421593,
      -0.0714263841509819,
      0.004734516609460115,
      -0.053992852568626404,
      -0.0006654666503891349,
      0.06241858750581741,
      0.03400040417909622,
      -0.03427066653966904,
      -0.09967707097530365,
      0.06356034427881241,
      0.060051094740629196,
      -0.03504674881696701,
      -0.03926508501172066,
      -0.0037903611082583666,
      -0.030349653214216232,
      -0.008086495101451874,
      -0.03963400050997734,
      0.0254469346255064,
      0.06673119962215424,
      0.007298036012798548,
      -0.031186869367957115,
      0.0775449275970459,
      0.04909288138151169,
      0.009206579066812992,
      0.03139296546578407,
      -0.12125935405492783,
      0.05618530884385109,
      0.07187144458293915,
      0.0030403919517993927,
      0.023782748728990555,
      -0.07957936078310013,
      0.019937967881560326,
      0.02746037021279335,
      0.012206376530230045,
      -0.026323817670345306,
      0.03202732652425766,
      0.006571004167199135,
      0.11114975064992905,
      0.030052777379751205,
      -0.04469890892505646,
      -0.027435390278697014,
      0.026645755395293236,
      0.055359408259391785,
      0.08243034034967422,
      0.04522399231791496,
      0.09451702237129211,
      0.004867588169872761,
      -0.03149992600083351,
      -0.026008103042840958,
      0.026080740615725517,
      0.0005015208153054118,
      0.040201205760240555,
      -0.06394927203655243,
      0.07482530176639557,
      -0.07849867641925812,
      0.011551064439117908,
      0.10349473357200623,
      -0.0341644287109375,
      0.01196871418505907,
      -0.0803101658821106,
      0.04514908045530319,
      0.06519430130720139,
      0.04572262987494469,
      0.014445213600993156,
      0.05615323781967163,
      0.05679357796907425,
      0.10072298347949982,
      -0.0061691817827522755,
      -0.04827171936631203,
      -0.026618190109729767,
      0.04213253781199455,
      0.06421305239200592,
      -0.0620708130300045,
      0.014019354246556759,
      0.06367751955986023,
      -0.010122828185558319,
      -0.0236956849694252,
      0.002541054505854845,
      0.020056452602148056,
      0.012258225120604038,
      0.03180009871721268,
      -0.06931348890066147,
      0.12383880466222763,
      -0.03569851070642471,
      -0.030709320679306984,
      0.1193060651421547,
      -0.13372232019901276,
      0.05116645246744156,
      0.07374611496925354,
      0.006846396252512932,
      -0.049526721239089966,
      0.030132422223687172,
      0.06555680930614471,
      -0.014627319760620594,
      -0.061207350343465805,
      -0.04350131377577782,
      -0.0476946197450161,
      -0.03836706653237343,
      0.0018076837295666337,
      0.005905109457671642,
      0.03289688751101494,
      0.020814547315239906,
      -0.0033976442646235228,
      -0.02299128659069538,
      0.06757979094982147,
      0.014976553618907928,
      -0.04510343819856644,
      -0.06628751754760742,
      0.0374661460518837,
      0.011837327852845192,
      0.05314511060714722,
      -0.022815288975834846,
      0.0966968908905983,
      0.0022609701845794916,
      -0.0094923609867692,
      0.013106015510857105,
      0.028399137780070305,
      0.06795208156108856,
      0.030596300959587097,
      -0.00021733732137363404,
      -0.04407713562250137,
      0.08241575956344604,
      0.0026014228351414204,
      0.002591846277937293,
      0.04600904881954193,
      0.0426827147603035,
      0.09472063183784485,
      0.02168697491288185,
      0.03954958915710449,
      -0.07594123482704163,
      0.006473739631474018,
      0.0274842269718647,
      0.13804559409618378,
      -0.023670103400945663,
      -0.09370791167020798,
      -0.012719825841486454,
      0.0006520160241052508,
      0.04666486382484436,
      0.04095304384827614,
      -0.048616379499435425,
      -0.0607571043074131,
      -0.0593867264688015,
      0.04005460813641548,
      0.027000701054930687,
      -0.02727336250245571,
      -0.026165861636400223,
      0.028474964201450348,
      -0.010227344930171967,
      -0.02390657179057598,
      0.05245343595743179,
      -0.05315566435456276,
      -0.05958737060427666,
      0.0015848197508603334,
      0.013863160274922848,
      -0.0035521462559700012,
      -0.02816937305033207,
      -0.11961565166711807,
      -0.07385572046041489,
      0.0734609067440033,
      0.001692332560196519,
      0.05001714825630188,
      0.012049602344632149,
      0.07370617985725403,
      0.0043366109021008015,
      0.010054152458906174,
      0.02639385312795639,
      0.049974411725997925,
      -0.020124491304159164,
      -0.015621769241988659,
      -0.04184027016162872,
      -0.05773385986685753,
      -0.02694578655064106,
      0.005547358654439449,
      -0.0271752942353487,
      -0.06284845620393753,
      0.058300405740737915,
      0.1032271608710289,
      0.008429843932390213,
      -0.0658581405878067,
      -0.024219118058681488,
      -0.037837374955415726,
      -0.011854881420731544,
      0.013725233264267445,
      0.00010107965499628335,
      -0.08813297748565674,
      -0.02741842530667782,
      -0.02798050455749035,
      0.03202219679951668,
      -0.020606258884072304,
      9.385781595483422e-05,
      0.025962939485907555,
      -0.07108309119939804,
      -0.04288254678249359,
      0.008406453765928745,
      0.000874473771546036,
      0.02828577160835266,
      -0.013372756540775299,
      0.05866924673318863
    ],
    [
      0.04897988587617874,
      -0.042017869651317596,
      -0.07510150223970413,
      -0.07896949350833893,
      -0.04639896750450134,
      -0.0051567647606134415,
      0.04097753390669823,
      -0.03945835307240486,
      0.03995256498456001,
      0.03463048115372658,
      0.019513100385665894,
      0.03588791564106941,
      0.024190831929445267,
      0.006886282470077276,
      -0.05690953508019447,
      -0.0390033945441246,
      0.034478846937417984,
      0.007394109386950731,
      0.05005573108792305,
      0.009207267314195633,
      -8.936080121202394e-05,
      0.04105590283870697,
      -0.04804808273911476,
      -0.10724315792322159,
      -0.003120786277577281,
      0.10235685855150223,
      -0.021336279809474945,
      -0.031032858416438103,
      -0.014490457251667976,
      0.03538936376571655,
      -0.06513069570064545,
      0.025681357830762863,
      -0.038885537534952164,
      0.03392435982823372,
      -0.04077934846282005,
      -0.060948483645915985,
      0.02414276823401451,
      -0.02981855906546116,
      0.04519805684685707,
      -0.03470843285322189,
      0.06441014260053635,
      -0.03139818459749222,
      0.03179134801030159,
      -0.040654804557561874,
      -0.008302994072437286,
      0.017182299867272377,
      -0.04059280827641487,
      -0.012897001579403877,
      -0.021058214828372,
      0.012882198207080364,
      -0.03585529699921608,
      0.062355268746614456,
      -0.02887958660721779,
      0.03164289519190788,
      -0.07313764840364456,
      0.07472740113735199,
      -0.08936116099357605,
      -0.004777207970619202,
      0.01768903061747551,
      -0.005020976532250643,
      -0.08299990743398666,
      -0.038683947175741196,
      0.019138626754283905,
      -0.03648360073566437,
      -0.07852362841367722,
      -0.06608575582504272,
      0.005112113431096077,
      0.01999044232070446,
      -0.02270813100039959,
      0.002103574341163039,
      -0.025839300826191902,
      -0.009602396748960018,
      0.08040420711040497,
      -0.026195378974080086,
      -0.018174054101109505,
      -0.06297435611486435,
      0.0727582573890686,
      0.058551326394081116,
      -0.008887194097042084,
      -0.07148221880197525,
      -0.03731728717684746,
      -0.04174603894352913,
      -0.015096471644937992,
      -0.01237516850233078,
      -0.03263543173670769,
      -0.09048686921596527,
      -0.014733250252902508,
      -0.021280350163578987,
      -0.03966094180941582,
      0.028847746551036835,
      -0.05713370442390442,
      0.009642021730542183,
      0.08680997788906097,
      -0.0792023241519928,
      0.016976291313767433,
      -0.02728901244699955,
      -0.030562475323677063,
      0.011101968586444855,
      0.044179607182741165,
      0.03760942071676254,
      -0.08117549866437912,
      0.0274420864880085,
      0.03501615300774574,
      -0.009725364856421947,
      -0.025224953889846802,
      -0.024891352280974388,
      -0.04057612642645836,
      -0.04145517200231552,
      0.03794392943382263,
      -0.007043364457786083,
      -0.027422742918133736,
      -0.10152051597833633,
      -0.0009730090387165546,
      -0.04488663375377655,
      -0.060496680438518524,
      -0.08348553627729416,
      0.03073835000395775,
      0.0663469061255455,
      -0.006416726391762495,
      0.003929642960429192,
      0.0027749051805585623,
      -0.04721939563751221,
      0.10901377350091934,
      -0.06688808649778366,
      -0.0498315691947937,
      -0.03237779811024666,
      0.03615100309252739,
      0.026536626741290092,
      -0.013788269832730293,
      0.05225565284490585,
      0.05969515070319176,
      0.02120581641793251,
      0.00395188620314002,
      0.0289398692548275,
      -0.020582515746355057,
      0.024263087660074234,
      -0.021350828930735588,
      0.06804695725440979,
      0.023763718083500862,
      -0.04088788852095604,
      -0.06164783984422684,
      -0.026093322783708572,
      -0.0274545568972826,
      -0.03927056863903999,
      -0.043461691588163376,
      0.01869198866188526,
      -0.019613007083535194,
      0.048553500324487686,
      -0.03758375346660614,
      -0.0021948767825961113,
      -0.009639998897910118,
      0.06903845816850662,
      -0.05190256983041763,
      -0.013641899451613426,
      0.013725701719522476,
      0.047767095267772675,
      -0.05779300630092621,
      -0.008139729499816895,
      0.02009800262749195,
      -0.08062975108623505,
      -0.05688023567199707,
      0.007897550240159035,
      -0.0322488509118557,
      -0.0626477524638176,
      -0.07020768523216248,
      0.04441763460636139,
      0.0365467369556427,
      -0.028998559340834618,
      0.07986792176961899,
      0.007532660383731127,
      0.012372051365673542,
      -0.024062398821115494,
      -0.04551265761256218,
      -0.12309621274471283,
      -0.07040278613567352,
      0.013208053074777126,
      -0.018801679834723473,
      0.046302586793899536,
      0.05608847737312317,
      0.03795626014471054,
      -0.005213294178247452,
      -0.030781637877225876,
      0.02386544458568096,
      0.005865992046892643,
      0.02968795783817768,
      -0.04546918347477913,
      0.03646419569849968,
      0.04291120916604996,
      0.018109621480107307,
      -0.060287751257419586,
      -0.0668521299958229,
      0.01614248938858509,
      -0.0167213324457407,
      0.09938134253025055,
      -0.04425239935517311,
      0.056214191019535065,
      0.044796038419008255,
      -0.034326717257499695,
      0.05702991783618927,
      -0.13530808687210083,
      -0.06766822189092636,
      0.010820887051522732,
      -0.07743053138256073,
      0.07255902886390686,
      0.07247541844844818,
      -0.0327826589345932,
      0.006947267800569534,
      0.014354865998029709,
      0.05326615646481514,
      0.007132294122129679,
      -0.033398400992155075,
      -0.018389100208878517,
      0.11094938218593597,
      0.00045490640331991017,
      0.03783051297068596,
      -0.09204412996768951,
      -0.07502129673957825,
      -0.06272433698177338,
      0.007264343556016684,
      0.021842271089553833,
      0.009689563885331154,
      -0.02286851406097412,
      -0.004798026289790869,
      -0.0008067292510531843,
      -0.012973128817975521,
      0.02434086799621582,
      0.03603318706154823,
      -0.028668537735939026,
      -0.06850375980138779,
      0.07219134271144867,
      -0.11752533912658691,
      0.04078742489218712,
      0.05937199667096138,
      -0.05245156213641167,
      -0.06364928185939789,
      -0.003246370470151305,
      -0.02623276226222515,
      -0.04664965346455574,
      0.03495652601122856,
      0.07149572670459747,
      -0.027444584295153618,
      0.021243099123239517,
      0.03139681741595268,
      -0.0014253694098442793,
      -0.029894856736063957,
      -0.05009127035737038,
      0.0044412254355847836,
      -0.001948043005540967,
      -0.05913633480668068,
      0.0005477849626913667,
      0.10612453520298004,
      0.03682646155357361,
      0.06358004361391068,
      0.026210341602563858,
      -0.10068795084953308,
      -0.06400579959154129,
      -0.009087185375392437,
      -0.010464510880410671,
      0.025569505989551544,
      0.030754301697015762,
      0.032372042536735535,
      -0.012874577194452286,
      0.0030275671742856503,
      0.015160693787038326,
      0.02551032230257988,
      -0.09101426601409912,
      -0.04778982698917389,
      -0.02091742306947708,
      -0.028492359444499016,
      -0.02120308205485344,
      -0.022474119439721107,
      -0.03321295976638794,
      0.023206550627946854,
      0.009056259877979755,
      -0.02212182618677616,
      -0.016228236258029938,
      -0.013984158635139465,
      0.0158910620957613,
      -0.031282585114240646,
      0.01371417474001646,
      0.024171913042664528,
      0.026414448395371437,
      0.04667801409959793,
      0.05229237303137779,
      0.015543164685368538,
      0.018917439505457878,
      0.09777118265628815,
      0.029497593641281128,
      -0.029594672843813896,
      -0.04710821807384491,
      -0.055274493992328644,
      7.458993786713108e-05,
      0.011117448098957539,
      -0.02116161212325096,
      -0.01114308089017868,
      0.010217633098363876,
      0.009834032505750656,
      0.016755009070038795,
      -0.041635725647211075,
      -0.025528447702527046,
      -0.029275190085172653,
      -0.0055909790098667145,
      0.018799657002091408,
      0.040391888469457626,
      -0.02650453895330429,
      0.10408008843660355,
      -0.034701913595199585,
      -0.039483893662691116,
      -0.012027637101709843,
      0.06269031763076782,
      -0.01394956186413765,
      -0.05761067569255829,
      0.029655080288648605,
      -0.05211595818400383,
      -0.01775587536394596,
      0.011812003329396248,
      0.0013391273096203804,
      0.02314085327088833,
      -0.04882143810391426,
      -0.03224955126643181,
      -0.015249901451170444,
      0.04812272638082504,
      0.051276836544275284,
      0.02455390989780426,
      -0.019309276714920998,
      0.0016858454328030348,
      0.04170852154493332,
      -0.02871144562959671,
      -0.0746723935008049,
      0.011442541144788265,
      -0.04115696996450424,
      -0.012145401909947395,
      -0.07964026182889938,
      -0.022595280781388283,
      0.030033335089683533,
      -0.014278293587267399,
      0.04908912256360054,
      0.04395557567477226,
      0.037720322608947754,
      -0.04689022898674011,
      0.02576233074069023,
      -0.005744230467826128,
      -0.024364233016967773,
      0.013480336405336857,
      -0.005552629474550486,
      0.007135678082704544,
      -0.009256212040781975,
      -0.004489462357014418,
      0.06640280783176422,
      0.056943658739328384,
      -0.027287520468235016,
      -0.07076923549175262,
      -0.018343038856983185,
      -0.03438407555222511,
      0.0033716331236064434,
      -0.10390105098485947,
      0.04229709133505821,
      0.02414424903690815,
      0.047050561755895615,
      0.023310938850045204,
      0.016764719039201736,
      -0.041658915579319,
      0.03679058328270912,
      -0.06984828412532806,
      -0.08497334271669388,
      0.029793281108140945,
      -0.01862415112555027,
      -0.0055423276498913765,
      -0.028693845495581627,
      0.012145916000008583,
      -0.0002795651089400053,
      -4.657034878619015e-05,
      0.013289024122059345,
      0.025792168453335762,
      -0.007101529743522406,
      0.02798159420490265,
      0.03544030711054802,
      0.04919906333088875,
      0.005457296036183834,
      -0.0214671790599823,
      0.015674805268645287,
      -0.03781978785991669,
      -0.0037269997410476208,
      0.0915222316980362,
      0.0557275116443634,
      0.0023121461272239685,
      -0.07541687786579132,
      -0.005147965624928474,
      -0.004748089239001274,
      -0.013146213255822659,
      -0.05185471475124359,
      -0.05714264139533043,
      0.015810247510671616,
      -0.010102644562721252,
      -0.008407475426793098,
      -0.008671402931213379,
      -0.03064168617129326,
      -0.056089792400598526,
      0.029369384050369263,
      -0.00633613346144557,
      0.042245037853717804,
      0.05991515517234802,
      0.09895285964012146,
      -0.011059397831559181,
      0.04678444564342499,
      0.10703553259372711,
      0.04268941655755043,
      -0.047772299498319626,
      0.046169720590114594,
      -0.041208479553461075,
      0.12381315976381302,
      0.059705644845962524,
      0.0896429792046547,
      -0.0024087040219455957,
      -0.08046476542949677,
      -0.004323480650782585,
      0.041517287492752075,
      -0.09854119271039963,
      0.1010536253452301,
      -0.02115723490715027,
      -0.03291464224457741,
      -0.03391619026660919,
      0.06500110030174255,
      -0.01838436722755432,
      -0.016307275742292404,
      0.0017126748571172357,
      -0.05117655172944069,
      0.059258509427309036,
      -0.010297905653715134,
      -0.016673509031534195,
      0.056351691484451294,
      0.006217797286808491,
      -0.0155423479154706,
      -0.010475232265889645,
      -0.018959131091833115,
      -0.0319291427731514,
      -0.00010372182441642508,
      0.06009497866034508,
      0.027107536792755127,
      0.020175699144601822,
      0.02801710180938244,
      0.007696536835283041,
      -0.05414846912026405,
      0.02464829944074154,
      -0.0027080755680799484,
      -0.06936328858137131,
      -0.009691910818219185,
      0.03669014200568199,
      0.002665457548573613,
      -0.01784214749932289,
      -0.08209228515625,
      -0.00029243301833048463,
      -0.07016267627477646,
      0.03286876529455185,
      0.04721781611442566,
      -0.07163308560848236,
      0.07820414006710052,
      -0.07862383872270584,
      0.0013939491473138332,
      0.036483313888311386,
      -0.006928807124495506,
      0.022415459156036377,
      0.10080021619796753,
      0.050928156822919846,
      -0.03976989537477493,
      0.011467142961919308,
      0.0029576043598353863,
      -0.014099246822297573,
      0.02190272882580757,
      -0.014036334119737148,
      -0.048314742743968964,
      -0.043230827897787094,
      0.017010338604450226,
      0.04851065203547478,
      -0.024688584730029106,
      -0.0456153079867363,
      0.01124359481036663,
      -0.06366018950939178,
      0.027880381792783737,
      0.006261155474931002,
      0.0420725978910923,
      -0.07207031548023224,
      0.01804882474243641,
      -0.010424353182315826,
      -0.013733084313571453,
      -0.04289857670664787,
      -0.020154107362031937,
      -0.017361609265208244,
      0.012185229919850826,
      -0.06901903450489044,
      0.016591539606451988,
      0.033484380692243576,
      -0.0043730526231229305,
      -0.018939930945634842,
      0.008528773672878742,
      0.028868408873677254,
      0.06700684130191803,
      0.01842476986348629,
      0.020537106320261955,
      -0.031171029433608055,
      0.058897629380226135,
      -0.07473784685134888,
      0.019384421408176422
    ],
    [
      -0.0013561585219576955,
      -0.0434936098754406,
      0.03300571069121361,
      -0.07773842662572861,
      0.051419030874967575,
      -0.03173885494470596,
      -0.10165589302778244,
      -0.011497249826788902,
      0.020689167082309723,
      -0.02967189811170101,
      -0.017488887533545494,
      0.09687194973230362,
      -0.04269154369831085,
      -0.022754361853003502,
      -0.01340306457132101,
      -0.012395929545164108,
      -0.057260170578956604,
      -0.014078980311751366,
      0.07103271037340164,
      -0.003962873015552759,
      -0.07661444693803787,
      -0.02308199182152748,
      0.06491854786872864,
      0.06548672914505005,
      -0.026244889944791794,
      0.0565650649368763,
      0.03547115996479988,
      0.15718162059783936,
      0.06050574779510498,
      -0.039423659443855286,
      0.010136019438505173,
      -0.007933181710541248,
      0.05919132009148598,
      -0.056089531630277634,
      0.040677301585674286,
      -0.05759930610656738,
      0.022951500490307808,
      -0.042185228317976,
      0.0887620821595192,
      -0.07860485464334488,
      0.05013766512274742,
      0.0034693258348852396,
      0.025002891197800636,
      -0.012739475816488266,
      -0.052418675273656845,
      -0.005863340105861425,
      0.045427899807691574,
      -0.10929116606712341,
      -0.06095761060714722,
      -0.08395029604434967,
      -0.005876830313354731,
      -0.011066990904510021,
      -0.04283160716295242,
      0.009657036513090134,
      -0.10206017643213272,
      0.0021693743765354156,
      0.012855907902121544,
      -0.047595538198947906,
      -0.03312838822603226,
      -0.04276452958583832,
      0.0495673269033432,
      -0.06951173394918442,
      0.10417685657739639,
      0.04031890630722046,
      0.07706113159656525,
      0.006315029691904783,
      -0.04166431725025177,
      -0.03841525688767433,
      0.01860560104250908,
      -0.05873832106590271,
      0.0783454030752182,
      -0.040459416806697845,
      0.02211468480527401,
      -0.033475007861852646,
      0.022481881082057953,
      -0.02235039882361889,
      -0.03989998996257782,
      0.06726405024528503,
      -0.009364452213048935,
      -0.04927106574177742,
      0.040168024599552155,
      -0.07557512074708939,
      0.02162402682006359,
      0.008680441416800022,
      0.01422354206442833,
      -0.03024263307452202,
      0.020643942058086395,
      -0.09605662524700165,
      0.08004593849182129,
      -0.03252473101019859,
      -0.0629011020064354,
      -0.04668593779206276,
      -0.0016877640737220645,
      0.07365099340677261,
      0.07270332425832748,
      -0.008748681284487247,
      0.014364199712872505,
      0.017859112471342087,
      -0.0294635146856308,
      0.044658225029706955,
      -0.006520798895508051,
      -0.006599866319447756,
      0.07734674960374832,
      -0.045431990176439285,
      0.034960292279720306,
      -0.041921503841876984,
      0.022803248837590218,
      -0.04639093950390816,
      0.07458730787038803,
      0.0010641519911587238,
      0.08595242351293564,
      -0.024375546723604202,
      0.009309197776019573,
      -0.005926704499870539,
      0.06729713827371597,
      -0.013113309629261494,
      0.006560100708156824,
      0.01860545389354229,
      0.0507403165102005,
      0.042375221848487854,
      -0.03362814337015152,
      -0.028160203248262405,
      0.03220855072140694,
      0.03890002891421318,
      0.10804754495620728,
      0.041118770837783813,
      -0.01969999447464943,
      -0.02668752893805504,
      0.00956045277416706,
      -0.04415961727499962,
      0.06745555251836777,
      -0.009780277498066425,
      -0.0390968918800354,
      -0.012523166835308075,
      -0.019787123426795006,
      0.05097999423742294,
      -0.04856320470571518,
      -0.03439648076891899,
      -0.003018610179424286,
      -0.00899498164653778,
      -0.07247138023376465,
      0.016920097172260284,
      0.020533116534352303,
      -0.057751260697841644,
      0.020513197407126427,
      0.022071558982133865,
      -0.02663000300526619,
      0.032693568617105484,
      0.08278188854455948,
      -0.04528917372226715,
      -0.038723185658454895,
      0.04365352541208267,
      -0.03280925378203392,
      -0.048638444393873215,
      -0.12141647934913635,
      0.020719541236758232,
      -0.05425437539815903,
      0.03274685889482498,
      -0.04863033816218376,
      0.02847481518983841,
      -0.008677628822624683,
      -0.03305310755968094,
      0.10703666508197784,
      0.03509042412042618,
      -0.01318048033863306,
      -0.00934274960309267,
      -0.025428377091884613,
      -0.011619179509580135,
      -0.014064030721783638,
      -0.04910574480891228,
      0.07042156159877777,
      -0.05083169788122177,
      -0.07902780175209045,
      -0.07345671206712723,
      0.06218492612242699,
      -0.04634907841682434,
      0.024013446643948555,
      0.005642999429255724,
      0.01009591855108738,
      0.0274802315980196,
      0.07706381380558014,
      -0.03446266055107117,
      -0.00437390711158514,
      -0.024231670424342155,
      0.019086038693785667,
      -0.07886282354593277,
      0.003918231464922428,
      0.059259604662656784,
      0.008806776255369186,
      0.0026962407864630222,
      -0.02694760262966156,
      -0.03353545069694519,
      0.08654249459505081,
      0.03589225932955742,
      0.030789416283369064,
      -0.018368754535913467,
      -0.049721721559762955,
      -0.02576005645096302,
      -0.036902572959661484,
      0.024657005444169044,
      0.025561414659023285,
      0.013117694295942783,
      -0.036122553050518036,
      -0.014124822802841663,
      -0.06330026686191559,
      -0.0394478514790535,
      -0.0007566548883914948,
      0.018790578469634056,
      0.023678261786699295,
      0.038547832518815994,
      0.008040082640945911,
      0.001993476180359721,
      0.02777429297566414,
      0.003429956501349807,
      -0.07251282036304474,
      -0.002721922704949975,
      0.0012791557237505913,
      -0.038447629660367966,
      -0.018044346943497658,
      -0.00027073948876932263,
      -0.037200674414634705,
      0.07947655767202377,
      0.05928117409348488,
      0.04138701781630516,
      0.006680269725620747,
      0.053638700395822525,
      0.03321316838264465,
      0.015112957917153835,
      -0.04970259591937065,
      0.018490327522158623,
      -0.009516039863228798,
      0.03709479048848152,
      -0.036111850291490555,
      -0.07123345136642456,
      -0.002183128148317337,
      -0.01951286755502224,
      -0.006595994345843792,
      0.04471994936466217,
      0.03176837041974068,
      0.010376020334661007,
      -0.06757887452840805,
      -0.02059369534254074,
      0.0694749504327774,
      0.00015848020848352462,
      0.02719203010201454,
      0.04929709434509277,
      0.0594644732773304,
      -0.01606663502752781,
      -0.009621220640838146,
      0.05413685739040375,
      -0.018565602600574493,
      -0.027941595762968063,
      -0.007782021537423134,
      0.030540162697434425,
      0.002673761686310172,
      -0.07730459421873093,
      -0.03144747391343117,
      0.018334155902266502,
      0.010014459490776062,
      0.02623104862868786,
      0.026036294177174568,
      0.008939862251281738,
      -1.7716887668939307e-05,
      -0.008629377000033855,
      0.0468301884829998,
      -0.04118824750185013,
      0.05216924846172333,
      0.04825926944613457,
      0.01547169778496027,
      -0.01975707709789276,
      -0.1286391168832779,
      -0.0037754825316369534,
      0.0049301739782094955,
      0.001851600711233914,
      -0.012740636244416237,
      0.0045953053049743176,
      -0.05678544566035271,
      -0.0303718950599432,
      0.001034796005114913,
      0.030242277309298515,
      0.03496181219816208,
      0.05492173880338669,
      0.01932467520236969,
      0.014225780963897705,
      0.07262647151947021,
      0.018495768308639526,
      -0.02770291641354561,
      0.047385189682245255,
      -0.015427966602146626,
      -0.02208789996802807,
      0.005377887282520533,
      -0.018804410472512245,
      -0.02362482063472271,
      -0.02935156039893627,
      0.027397239580750465,
      0.039561908692121506,
      0.0032723217736929655,
      -0.10747791081666946,
      -0.05331650748848915,
      -0.008719629608094692,
      -0.05481714382767677,
      -0.020166629925370216,
      -0.054252754896879196,
      0.03410741314291954,
      0.03726312145590782,
      -0.017642784863710403,
      0.01870366930961609,
      -0.028342410922050476,
      -0.061686474829912186,
      0.030558334663510323,
      -0.026237379759550095,
      -0.002062662737444043,
      -0.006064998917281628,
      -0.013929546810686588,
      0.01751164346933365,
      0.03691614046692848,
      -0.007065269630402327,
      0.028979962691664696,
      -0.04792430251836777,
      0.03770757466554642,
      -0.0022053380962461233,
      0.06534288823604584,
      0.029451431706547737,
      -0.06355541944503784,
      -0.01876167021691799,
      0.016114888712763786,
      -0.015473795123398304,
      0.01756243035197258,
      0.017690757289528847,
      0.009863008745014668,
      -0.020160330459475517,
      -0.0035774889402091503,
      -0.006122954655438662,
      -0.06510523706674576,
      0.0035151562187820673,
      0.007455038372427225,
      0.08634030818939209,
      -0.05087258294224739,
      0.06470891833305359,
      0.04260294884443283,
      0.04604855924844742,
      -0.02255871519446373,
      -0.012400238774716854,
      -0.017862195149064064,
      0.048609405755996704,
      -0.10227401554584503,
      -0.036327917128801346,
      0.012043459340929985,
      0.014267324469983578,
      -0.02882077544927597,
      -0.061796486377716064,
      -0.09593963623046875,
      0.030714062973856926,
      -0.06084112450480461,
      -0.01766534149646759,
      -0.08461477607488632,
      -0.056768305599689484,
      -0.09704001992940903,
      0.006859863642603159,
      0.00965871475636959,
      0.005160266533493996,
      0.026937367394566536,
      0.02739865705370903,
      0.0495656318962574,
      -0.04067414253950119,
      0.003351677907630801,
      -0.0706050768494606,
      -0.07806946337223053,
      -0.11480432003736496,
      0.08525753021240234,
      -0.033782485872507095,
      -0.07721220701932907,
      0.029274076223373413,
      -0.046973105520009995,
      0.026996348053216934,
      0.017656773328781128,
      -0.030943499878048897,
      0.04774000495672226,
      0.02378801815211773,
      -0.04979618638753891,
      -0.05680129677057266,
      -0.0018578552408143878,
      0.057203374803066254,
      0.10771980881690979,
      0.06030495464801788,
      -0.07188790291547775,
      -0.019453980028629303,
      -0.041453637182712555,
      -0.034336186945438385,
      0.00684133218601346,
      0.02088117226958275,
      -0.020172562450170517,
      -0.053227636963129044,
      -0.011404858902096748,
      0.03772272169589996,
      -0.026925232261419296,
      0.021422725170850754,
      0.05508370324969292,
      -0.030703401193022728,
      -0.09006782621145248,
      0.025108391419053078,
      0.007289113011211157,
      -0.019437916576862335,
      0.014419576153159142,
      0.006127489730715752,
      0.01844905875623226,
      -0.10923336446285248,
      0.011272335425019264,
      -0.01516622956842184,
      -0.011972412467002869,
      0.09797727316617966,
      0.056478388607501984,
      0.044980041682720184,
      -0.031237421557307243,
      0.06688009202480316,
      -0.05342577025294304,
      -0.06509547680616379,
      0.010080236941576004,
      0.11324906349182129,
      0.04227956011891365,
      -0.001043642871081829,
      0.14043255150318146,
      0.032520584762096405,
      0.0412132702767849,
      0.02543795108795166,
      0.026985250413417816,
      -0.047023896127939224,
      -0.0735466405749321,
      -0.02549656480550766,
      0.05265951529145241,
      0.04367092624306679,
      0.0035649477504193783,
      0.030971037223935127,
      -0.012741081416606903,
      0.0014503079000860453,
      -0.0361160971224308,
      0.018089210614562035,
      -0.0940677672624588,
      -0.05139295756816864,
      -0.004497883841395378,
      -0.030677542090415955,
      0.03758399561047554,
      -0.05686801299452782,
      0.09757821261882782,
      0.04061996564269066,
      -0.0007788040093146265,
      -0.015959730371832848,
      -0.017690781503915787,
      -0.013376477174460888,
      0.020139245316386223,
      -0.08176970481872559,
      0.028683479875326157,
      0.051645636558532715,
      0.013555608689785004,
      0.005807842127978802,
      -0.03984565660357475,
      0.023039348423480988,
      0.04883979633450508,
      0.05792265757918358,
      -0.04466738551855087,
      0.032800108194351196,
      0.0027911891229450703,
      0.0826134905219078,
      -0.08040335029363632,
      -0.018027380108833313,
      -0.012717127799987793,
      0.0214094091206789,
      0.008715981617569923,
      -0.0578964501619339,
      0.048636455088853836,
      0.023127486929297447,
      0.05017436295747757,
      -0.07546377182006836,
      -0.007519543636590242,
      0.04146099090576172,
      -0.005770652089267969,
      0.027123695239424706,
      -0.015550590120255947,
      -0.054673004895448685,
      -0.01969296671450138,
      -0.0017834462923929095,
      0.02790275402367115,
      -0.04562772065401077,
      0.052664246410131454,
      0.09090922027826309,
      -0.04896683990955353,
      -0.06675811856985092,
      0.05754825845360756,
      0.07365819066762924,
      -0.011813299730420113,
      0.028740169480443,
      -0.017510661855340004,
      -0.054823655635118484,
      -0.01006778422743082,
      0.037037257105112076,
      -0.03914424031972885,
      0.027874477207660675,
      0.003181669395416975,
      -0.03823057562112808,
      -0.012498924508690834,
      0.053786106407642365,
      -0.050398267805576324,
      -0.022180568426847458
    ],
    [
      -0.020203346386551857,
      -0.02296094037592411,
      -0.05983670428395271,
      -0.04948269948363304,
      -0.0788467600941658,
      -4.313900717534125e-05,
      -0.03536715358495712,
      0.072655089199543,
      0.034913793206214905,
      -0.04575682803988457,
      -0.03878241032361984,
      0.026677748188376427,
      -0.04082602635025978,
      0.07296459376811981,
      -0.011225210502743721,
      -0.027134686708450317,
      0.022574571892619133,
      -0.015075746923685074,
      0.0273304283618927,
      -0.049219805747270584,
      -0.05063682422041893,
      -0.00877853948622942,
      -0.02321573533117771,
      0.017960326746106148,
      0.018591050058603287,
      0.04987252503633499,
      -0.03523198142647743,
      -0.010235397145152092,
      -0.057489439845085144,
      -0.05569064989686012,
      0.03482759743928909,
      -0.02393312379717827,
      -0.09443740546703339,
      0.007913786917924881,
      -0.005298403557389975,
      0.0782545655965805,
      -0.024516483768820763,
      0.04840322956442833,
      -0.02594151347875595,
      0.009870348498225212,
      0.07620713114738464,
      -0.016280092298984528,
      -0.0760834738612175,
      -0.03411168232560158,
      -0.00979653187096119,
      -0.00460294634103775,
      -0.030977871268987656,
      -0.008455525152385235,
      -0.015486255288124084,
      -0.05601545795798302,
      -0.0579904280602932,
      -0.004732589237391949,
      0.10339877754449844,
      0.051847439259290695,
      -0.008009864948689938,
      0.0009853494120761752,
      -0.0033924628514796495,
      0.017809348180890083,
      -0.0989222526550293,
      -0.05098015442490578,
      -0.04146448150277138,
      -0.00946839153766632,
      0.09808355569839478,
      -0.04402076080441475,
      -0.023369690403342247,
      -0.023180080577731133,
      -0.03481902554631233,
      0.04809759184718132,
      -0.09633124619722366,
      0.02240900509059429,
      -0.0820140391588211,
      0.019212324172258377,
      -0.005771669093519449,
      -0.009209786541759968,
      -0.012349157594144344,
      0.061582181602716446,
      -0.02387131191790104,
      -0.010037004947662354,
      -0.034077346324920654,
      0.049959346652030945,
      0.015365773811936378,
      -0.017961718142032623,
      -0.03183061257004738,
      -0.024021515622735023,
      0.0241481252014637,
      -0.06172604858875275,
      0.006243391428142786,
      0.07988438010215759,
      -0.0214681439101696,
      0.04593955725431442,
      0.013355697505176067,
      0.0066935098730027676,
      -0.058671291917562485,
      0.033502545207738876,
      0.06628237664699554,
      0.03482197970151901,
      -0.047621019184589386,
      -0.012942754663527012,
      0.1508464366197586,
      0.06259628385305405,
      0.07606714963912964,
      0.008673949167132378,
      0.04296314716339111,
      -0.04951055347919464,
      -0.008420651778578758,
      0.04699243605136871,
      -0.020756511017680168,
      0.02138700895011425,
      0.04447982460260391,
      0.0021234878804534674,
      -0.002735176822170615,
      0.017520397901535034,
      0.013232731260359287,
      -0.01413720939308405,
      0.01620970480144024,
      -0.04010231792926788,
      -0.005658875685185194,
      -0.001445288653485477,
      -0.025162652134895325,
      -0.0843636691570282,
      -0.03188909962773323,
      0.001705997041426599,
      0.0452997200191021,
      -0.010142910294234753,
      0.028005920350551605,
      0.021362921223044395,
      0.038583751767873764,
      0.03811120614409447,
      0.02847111038863659,
      0.011827287264168262,
      0.018019767478108406,
      0.0006503455806523561,
      0.024251170456409454,
      -0.0686148852109909,
      -0.08392438292503357,
      -0.0014287905069068074,
      -0.0014714397257193923,
      0.07561343908309937,
      0.1063491553068161,
      0.03404397889971733,
      -0.031285516917705536,
      -0.04497171565890312,
      -0.035588186234235764,
      -0.07513128221035004,
      -0.04672042280435562,
      0.030615806579589844,
      0.028158865869045258,
      -0.0473334975540638,
      0.07205229252576828,
      -0.0008756513125263155,
      -0.05109604075551033,
      -0.018058309331536293,
      0.0566202811896801,
      -0.008583711460232735,
      -0.01139531098306179,
      -0.0661587044596672,
      -0.028176121413707733,
      0.015529542230069637,
      0.0010978726204484701,
      0.0081257913261652,
      -0.02504693530499935,
      -0.05258191004395485,
      0.08074383437633514,
      -0.002343015279620886,
      -0.04930885508656502,
      0.008725856430828571,
      -0.04846523702144623,
      -0.014942249283194542,
      -0.013478830456733704,
      0.08175849169492722,
      0.05131535977125168,
      -0.013046409003436565,
      -0.04650156572461128,
      -0.04331587255001068,
      0.029198937118053436,
      0.037502918392419815,
      -0.004327757749706507,
      -0.028297152370214462,
      -0.0504617914557457,
      0.08900009095668793,
      -0.00764455646276474,
      0.026766058057546616,
      0.0037492255214601755,
      0.01873021014034748,
      -0.019797999411821365,
      -0.044855885207653046,
      0.1144731342792511,
      0.0022788832429796457,
      0.019281696528196335,
      -0.04493268206715584,
      0.019788622856140137,
      -0.03040081448853016,
      0.004460076801478863,
      0.01742507889866829,
      0.012496912851929665,
      -0.012842425145208836,
      0.035933930426836014,
      0.042032960802316666,
      -0.01708007976412773,
      0.0028848692309111357,
      -0.027436193078756332,
      0.09569373726844788,
      0.010067872703075409,
      0.05480064079165459,
      -0.1251441389322281,
      -0.04602877050638199,
      -0.015274698846042156,
      -0.013156326487660408,
      -0.04627446457743645,
      -0.011751467362046242,
      -0.025146404281258583,
      -0.003027193248271942,
      -0.0324256457388401,
      0.005065469071269035,
      0.030255412682890892,
      -0.023326758295297623,
      -0.039639826864004135,
      0.0519200898706913,
      0.026813611388206482,
      -0.1111595407128334,
      0.032016631215810776,
      0.004157133866101503,
      0.0010959157953038812,
      -0.014702931977808475,
      0.022062167525291443,
      0.0357009656727314,
      -0.03340601176023483,
      -0.0092608742415905,
      0.018955305218696594,
      0.07899767905473709,
      -0.08856402337551117,
      -0.005848593078553677,
      -0.0017542543355375528,
      -0.03887719660997391,
      -0.0048404051922261715,
      0.0007444571238011122,
      -0.05860157310962677,
      -0.07781811058521271,
      -0.022151358425617218,
      -0.028493421152234077,
      -0.04611160233616829,
      0.06412579119205475,
      0.06233583018183708,
      0.03122860938310623,
      -0.031700313091278076,
      0.012606894597411156,
      0.060470614582300186,
      -0.016919652000069618,
      0.08338863402605057,
      -0.01245790533721447,
      0.02518528886139393,
      0.0047155460342764854,
      0.049549173563718796,
      0.03474247828125954,
      -0.047096773982048035,
      0.05283733829855919,
      0.026138726621866226,
      -0.015680739656090736,
      0.010383795946836472,
      -0.007170487195253372,
      -0.0459606759250164,
      0.03186361491680145,
      -0.007858511060476303,
      0.050359584391117096,
      0.02174489200115204,
      0.0089010214433074,
      0.011440594680607319,
      0.02629752643406391,
      -0.043462079018354416,
      -0.09257764369249344,
      -0.027385808527469635,
      0.011980372481048107,
      0.09235244244337082,
      0.029491344466805458,
      -0.012521770782768726,
      -0.03372279182076454,
      0.024388188496232033,
      -0.06051241233944893,
      0.028301630169153214,
      0.02369868755340576,
      0.029487844556570053,
      -0.06820222735404968,
      -0.029043814167380333,
      0.03486244007945061,
      0.020418750122189522,
      -0.04575993865728378,
      -0.028620131313800812,
      0.016731631010770798,
      0.004855120554566383,
      0.011814625933766365,
      -0.008846962824463844,
      -0.06040726229548454,
      0.033570852130651474,
      0.0009460183209739625,
      -0.011514074169099331,
      -0.042687103152275085,
      0.05889901518821716,
      0.007268444634974003,
      -0.03702196851372719,
      -0.07843398302793503,
      0.024841543287038803,
      -0.01704612374305725,
      0.023332156240940094,
      -0.041783686727285385,
      -0.0735403373837471,
      0.01230279915034771,
      0.0019212884362787008,
      -0.04348784312605858,
      0.02275954931974411,
      -0.08744391053915024,
      0.08298872411251068,
      0.008044064044952393,
      0.0341666042804718,
      -0.03221271559596062,
      0.03811114281415939,
      -0.01573931612074375,
      0.006302456371486187,
      -0.06328930705785751,
      0.008743410930037498,
      0.032367341220378876,
      -0.10037019103765488,
      -0.0020281404722481966,
      -0.06241413950920105,
      0.039088863879442215,
      -0.05224102735519409,
      0.053341127932071686,
      -0.09503556787967682,
      -0.02166876383125782,
      0.03609379753470421,
      -0.020946525037288666,
      -0.02195780910551548,
      -0.01880820468068123,
      -0.012778384611010551,
      -0.03333636745810509,
      -0.031003156676888466,
      -0.00018234032904729247,
      0.0003912959073204547,
      0.12308267503976822,
      0.054258327931165695,
      0.04214166849851608,
      0.10061951726675034,
      -0.026381010189652443,
      -0.0002591851516626775,
      0.07597190886735916,
      -0.011942727491259575,
      0.028795290738344193,
      -0.07449471950531006,
      0.02175995334982872,
      0.049050625413656235,
      0.020202025771141052,
      0.0030079390853643417,
      -0.005627817939966917,
      0.06821008771657944,
      0.05861424282193184,
      -0.03737439587712288,
      -0.03840199485421181,
      0.0780225470662117,
      -0.075881727039814,
      -0.021247781813144684,
      -0.013127987273037434,
      -0.0025683511048555374,
      -0.06635307520627975,
      -0.010276860557496548,
      0.00618969090282917,
      0.06094728037714958,
      -0.017036128789186478,
      -0.004489113576710224,
      -0.00989775825291872,
      0.029734862968325615,
      0.0034700040705502033,
      -0.004959676414728165,
      0.02589801885187626,
      -0.0180156622081995,
      -0.04061495140194893,
      -0.05768873542547226,
      0.05011478438973427,
      0.01445556990802288,
      0.001724187983199954,
      0.04206051304936409,
      -0.0769733339548111,
      0.06131713464856148,
      -0.029787419363856316,
      0.0043575032614171505,
      0.007724740542471409,
      -0.006636689882725477,
      0.012780027464032173,
      -0.005565123166888952,
      0.03842158243060112,
      -0.020789556205272675,
      0.0038761014584451914,
      0.04436204209923744,
      0.0831291601061821,
      0.0012726382119581103,
      -0.04984085261821747,
      -0.07966853678226471,
      -0.07001419365406036,
      -0.04487026110291481,
      0.02220720425248146,
      0.014506682753562927,
      0.03987516835331917,
      0.015194667503237724,
      -0.018189499154686928,
      -0.042872354388237,
      -0.015769027173519135,
      0.07930915802717209,
      0.044696688652038574,
      -0.06096957251429558,
      0.04943536967039108,
      0.021257512271404266,
      0.0407264269888401,
      -0.00039071409264579415,
      -0.03590921312570572,
      0.054344531148672104,
      0.03501089662313461,
      -0.015699315816164017,
      -0.0022441763430833817,
      -0.023811835795640945,
      -0.0676160529255867,
      0.02588595263659954,
      -0.0881132036447525,
      0.018824134021997452,
      -0.007538054138422012,
      -0.026497552171349525,
      0.03137320652604103,
      -0.04190213978290558,
      -0.011032919399440289,
      -0.007964453659951687,
      -0.016149140894412994,
      -0.07189733535051346,
      0.012633553706109524,
      -0.021860698238015175,
      -0.04518967494368553,
      -0.021085204556584358,
      0.03749442845582962,
      -0.031098652631044388,
      -0.09220711886882782,
      0.08422935009002686,
      -0.10168309509754181,
      0.022348500788211823,
      0.04992466792464256,
      0.06479646265506744,
      -0.12406419962644577,
      0.03526981920003891,
      -0.02826637402176857,
      -0.047536857426166534,
      0.03603553771972656,
      0.014269293285906315,
      -0.03484645485877991,
      -0.0064955297857522964,
      0.02329815924167633,
      -0.0023555480875074863,
      0.016835100948810577,
      -0.0008637196733616292,
      -0.010591825470328331,
      0.016488049179315567,
      0.023699933663010597,
      -0.006159169599413872,
      0.033862050622701645,
      0.03645920380949974,
      -0.028839778155088425,
      0.07771583646535873,
      0.054952334612607956,
      0.028537200763821602,
      0.0682956799864769,
      -0.046790286898612976,
      -0.03338654711842537,
      0.061451222747564316,
      -0.025617389008402824,
      -0.007589244749397039,
      0.0479087308049202,
      -0.019617212936282158,
      0.0014173237141221762,
      -0.0029964877758175135,
      0.02203621156513691,
      -0.05561080947518349,
      -0.003019236493855715,
      -0.0785026028752327,
      -0.014323937706649303,
      -0.02848484180867672,
      0.020014991983771324,
      -0.04887927696108818,
      -0.03615448623895645,
      0.030386166647076607,
      -0.06087367609143257,
      -0.060947250574827194,
      -0.036721616983413696,
      0.04836561903357506,
      -0.003197509329766035,
      0.03250056877732277,
      -0.03111770749092102,
      0.011698800139129162,
      -0.07443861663341522,
      -0.004741077311336994,
      -0.0794815644621849,
      0.010528572835028172,
      0.017886485904455185,
      -0.02492298185825348,
      0.04864111170172691,
      -0.09914939850568771,
      -0.033973921090364456,
      -0.031101176515221596,
      0.00837699044495821,
      0.0040237801149487495
    ],
    [
      -0.00777585431933403,
      -0.11077497899532318,
      0.036099400371313095,
      0.022927386686205864,
      0.008021279238164425,
      -0.08713103830814362,
      -0.034316737204790115,
      0.044711824506521225,
      0.0202233474701643,
      0.03487805277109146,
      -0.012236316688358784,
      -0.03624102100729942,
      -0.011300292797386646,
      -0.023526236414909363,
      0.011924929916858673,
      -0.04125059396028519,
      0.053928423672914505,
      0.05043512582778931,
      0.04620097950100899,
      -0.006618090905249119,
      -0.04925923049449921,
      0.0924030989408493,
      -0.060387592762708664,
      -0.018080679699778557,
      -0.002144749742001295,
      -0.027172567322850227,
      -0.07087800651788712,
      0.09343152493238449,
      0.0230783149600029,
      -0.03520243614912033,
      0.02944815158843994,
      -0.062124788761138916,
      -0.015924446284770966,
      0.07358093559741974,
      -0.007488549221307039,
      0.01489165797829628,
      0.0040060849860310555,
      -0.01734425127506256,
      0.03765106573700905,
      -0.038260847330093384,
      0.03239233419299126,
      0.026570212095975876,
      -0.024589115753769875,
      0.015311566181480885,
      -0.005000978708267212,
      -0.026124771684408188,
      -0.07790371775627136,
      0.011060669086873531,
      0.005891044624149799,
      -0.021388625726103783,
      -0.11791516840457916,
      -0.03093048743903637,
      -0.005606957245618105,
      -0.0943978875875473,
      -0.01727990247309208,
      -0.0383056104183197,
      -0.024151992052793503,
      0.04585801437497139,
      -0.12499754130840302,
      -0.01461544819176197,
      0.006681750062853098,
      0.013306482695043087,
      -0.05622946098446846,
      0.03398599475622177,
      -0.04879416525363922,
      0.02158583141863346,
      -0.020508872345089912,
      -0.017554206773638725,
      0.09033133089542389,
      0.002180313691496849,
      0.03294592723250389,
      0.014486532658338547,
      0.05939244106411934,
      0.0397479422390461,
      -0.016390828415751457,
      -0.003829674096778035,
      0.10431338846683502,
      0.05763760954141617,
      0.003303870325908065,
      0.04197022691369057,
      0.019399169832468033,
      -0.00030600596801377833,
      -0.05938569828867912,
      -0.00512210326269269,
      0.05032908543944359,
      0.04361255466938019,
      -0.037349820137023926,
      -0.052445732057094574,
      0.058563124388456345,
      -0.03438873589038849,
      0.01001278217881918,
      -0.016049783676862717,
      -0.13942407071590424,
      0.018615685403347015,
      0.05469898506999016,
      0.03766600042581558,
      -0.04719914495944977,
      0.04429855942726135,
      0.048437800258398056,
      0.08569607883691788,
      0.010349886491894722,
      -0.0360088087618351,
      -0.012130231596529484,
      -0.04136967286467552,
      0.04287196695804596,
      0.027879223227500916,
      0.025711815804243088,
      -0.021300293505191803,
      0.037666235119104385,
      0.012373155914247036,
      0.008948399685323238,
      -0.0023234474938362837,
      0.02334100566804409,
      -0.016674404963850975,
      0.07277243584394455,
      0.02301652915775776,
      -0.06907675415277481,
      0.006884726230055094,
      0.08414222300052643,
      -0.013868460431694984,
      -0.03612937405705452,
      0.006846912205219269,
      0.062063273042440414,
      0.0014869804726913571,
      0.0176982618868351,
      -0.0669635608792305,
      0.016745999455451965,
      -0.04083709046244621,
      0.00874523725360632,
      -0.028750170022249222,
      -0.008682171814143658,
      0.022809434682130814,
      -0.014468181878328323,
      -0.01916675455868244,
      0.0325021892786026,
      -0.005995170678943396,
      -0.014747370965778828,
      -0.044837068766355515,
      -0.0053312163800001144,
      0.04519670084118843,
      -0.08928040415048599,
      -0.05177982524037361,
      -0.0016879276372492313,
      0.05683182179927826,
      -0.004386531189084053,
      -0.008760352618992329,
      0.018443962559103966,
      -0.0748739019036293,
      0.0836748331785202,
      0.06539874523878098,
      0.027480948716402054,
      0.002287905430421233,
      -0.050776198506355286,
      0.01425462681800127,
      -0.08132176846265793,
      -0.007788582239300013,
      -0.09700877219438553,
      0.11774805933237076,
      0.044914811849594116,
      -0.016723016276955605,
      0.01920851320028305,
      0.10477827489376068,
      -0.10201793909072876,
      0.037694524973630905,
      0.015962079167366028,
      0.011608093045651913,
      0.09017376601696014,
      -7.651322084711865e-05,
      0.018655262887477875,
      0.008673340082168579,
      0.12256240844726562,
      0.15422064065933228,
      -0.09523507207632065,
      0.042676687240600586,
      0.019416699185967445,
      -0.06475967913866043,
      0.009310510940849781,
      0.018626496195793152,
      -0.05307150259613991,
      0.08451537042856216,
      -0.0038651381619274616,
      -0.018001100048422813,
      0.019719017669558525,
      -0.04945844039320946,
      0.05945317819714546,
      -0.0827464684844017,
      0.053445037454366684,
      0.03346114605665207,
      0.018177706748247147,
      0.007973000407218933,
      0.029978586360812187,
      0.0032908415887504816,
      -0.0034238393418490887,
      0.05284103751182556,
      0.054303135722875595,
      0.007391408085823059,
      0.04093044623732567,
      -0.060371775180101395,
      0.026868082582950592,
      -0.029994452372193336,
      -0.0032528480514883995,
      -0.059180717915296555,
      -0.030834974721074104,
      0.027521036565303802,
      -0.025138990953564644,
      0.034076135605573654,
      0.08568320423364639,
      -0.015069845132529736,
      -0.06660323590040207,
      0.05326616019010544,
      -0.0021833491045981646,
      -0.05114033818244934,
      -0.014709632843732834,
      0.0828762799501419,
      -0.016408687457442284,
      0.02851719968020916,
      -0.07117724418640137,
      0.06324878334999084,
      0.06389229744672775,
      0.02115744911134243,
      -0.009608425199985504,
      0.08485881984233856,
      0.001648069592192769,
      -0.05841996148228645,
      -0.006311960984021425,
      -0.05028514191508293,
      0.005390300881117582,
      0.027201322838664055,
      0.03320689499378204,
      0.016285400837659836,
      -0.08963466435670853,
      0.04317301884293556,
      -0.01118314079940319,
      -0.016623612493276596,
      -0.03347530961036682,
      -0.020995061844587326,
      -0.01386710349470377,
      0.08106950670480728,
      -0.0043724640272557735,
      0.0011560677085071802,
      -0.032885052263736725,
      -0.01862378604710102,
      -0.00802546739578247,
      0.034059904515743256,
      0.0018763672560453415,
      0.01880873367190361,
      -0.003627276048064232,
      0.018048027530312538,
      -0.01038472168147564,
      0.17811384797096252,
      -0.036667317152023315,
      0.03939276188611984,
      -0.06882103532552719,
      0.02206527441740036,
      -0.020068608224391937,
      0.035156816244125366,
      0.010497456416487694,
      0.018069010227918625,
      -0.018002474680542946,
      0.07368544489145279,
      0.005645007826387882,
      -0.02455933950841427,
      0.01405054796487093,
      0.010492904111742973,
      -0.0015904160682111979,
      0.03791686147451401,
      0.07269872725009918,
      0.004301593638956547,
      0.0752631276845932,
      0.039700113236904144,
      0.038438305258750916,
      -0.06320789456367493,
      -0.030761711299419403,
      -0.014354410581290722,
      0.0132242226973176,
      -0.08989155292510986,
      -0.0015208468539640307,
      0.04360221326351166,
      0.02445366233587265,
      -0.08151384443044662,
      0.09021763503551483,
      0.03902927041053772,
      -0.03701316937804222,
      0.04357336461544037,
      0.04759082943201065,
      -0.00472499243915081,
      -0.06466560065746307,
      -0.019523272290825844,
      -0.04620184376835823,
      -0.09224259108304977,
      -0.02268303744494915,
      0.08954750746488571,
      -0.05429840832948685,
      0.008107763715088367,
      0.001055524917319417,
      -0.073745958507061,
      -0.03176350146532059,
      0.030529294162988663,
      -0.014830267056822777,
      -0.016538195312023163,
      -0.0020306636579334736,
      -0.08898293972015381,
      -0.07200901955366135,
      -0.036601219326257706,
      0.050220850855112076,
      -0.0009328704909421504,
      -0.09257691353559494,
      0.017614969983696938,
      0.023087019100785255,
      -0.022058922797441483,
      0.0680939108133316,
      0.002325888955965638,
      0.022286266088485718,
      -0.038905464112758636,
      -0.0504361130297184,
      -0.021419420838356018,
      0.01272200234234333,
      -0.055566783994436264,
      0.0207049697637558,
      0.037677861750125885,
      0.009234867990016937,
      -0.021940449252724648,
      -0.015712542459368706,
      0.09921132028102875,
      -0.007606560830026865,
      0.01313062198460102,
      0.017963217571377754,
      -0.02412855438888073,
      0.03171326592564583,
      0.04955402389168739,
      -0.029262030497193336,
      -0.002873332006856799,
      -0.024314673617482185,
      0.002069339621812105,
      0.010003192350268364,
      0.053188174962997437,
      -0.043919164687395096,
      -0.0012092393590137362,
      0.03079863078892231,
      -0.04288264364004135,
      0.038155291229486465,
      -0.07172270864248276,
      0.016291165724396706,
      0.038349345326423645,
      0.03859567269682884,
      -0.04873460531234741,
      -0.053967710584402084,
      -0.06976330280303955,
      0.04685734957456589,
      -0.06577617675065994,
      0.07312960177659988,
      0.02135126106441021,
      -0.0025813099928200245,
      -0.07763264328241348,
      0.0275945533066988,
      -0.020621879026293755,
      -0.05172722414135933,
      -0.01430047769099474,
      0.021114813163876534,
      0.06631404161453247,
      -0.07717812806367874,
      0.019936397671699524,
      -0.04678694158792496,
      0.06838708370923996,
      -0.0005823984392918646,
      0.03500949963927269,
      0.07373088598251343,
      0.06793168187141418,
      -0.06819233298301697,
      0.06253433227539062,
      0.014466004446148872,
      -0.017746148630976677,
      0.008334069512784481,
      -0.0556289367377758,
      -0.06912872195243835,
      0.059010669589042664,
      -0.03128894418478012,
      0.007744217291474342,
      -0.011953848414123058,
      -0.03588781878352165,
      -0.015642108395695686,
      0.02109920047223568,
      -0.04292001202702522,
      0.07416992634534836,
      0.00921605248004198,
      0.025022229179739952,
      0.0006902606110088527,
      -0.02564607374370098,
      -0.06384362280368805,
      -0.00774022750556469,
      0.040483515709638596,
      -0.02656332403421402,
      0.012383414432406425,
      -0.0527287982404232,
      -0.07599446922540665,
      -0.029905609786510468,
      0.01370093785226345,
      -0.022468987852334976,
      -0.06472919881343842,
      -0.0030350228771567345,
      0.09240910410881042,
      0.04257733374834061,
      0.11004742234945297,
      -0.036503568291664124,
      0.04842647910118103,
      0.024666529148817062,
      -0.011171169579029083,
      -0.059809133410453796,
      -0.011754531413316727,
      0.048794716596603394,
      0.07943376153707504,
      0.022244129329919815,
      -0.04535512998700142,
      -0.001430849777534604,
      0.011257338337600231,
      0.027175096794962883,
      0.04886211082339287,
      0.06149083003401756,
      -0.06309203058481216,
      0.031738340854644775,
      0.023435508832335472,
      -0.006022430490702391,
      0.0009127494413405657,
      -0.05524968355894089,
      -0.0060277776792645454,
      0.00935592781752348,
      -0.005128116346895695,
      0.001057520741596818,
      0.03032602183520794,
      0.008965461514890194,
      0.09105745702981949,
      -0.05387809872627258,
      -0.000334807438775897,
      -0.016067394986748695,
      0.01041998527944088,
      -0.04503640532493591,
      0.06134013831615448,
      -0.061751239001750946,
      -0.047013524919748306,
      -0.017557524144649506,
      -0.05378115549683571,
      -0.049415696412324905,
      0.030543968081474304,
      -0.033003635704517365,
      0.023225773125886917,
      0.01889428310096264,
      -0.03860888257622719,
      0.051974859088659286,
      0.049367066472768784,
      -0.10273485630750656,
      -0.00916226301342249,
      -0.015386299230158329,
      0.02677907980978489,
      -0.05634915456175804,
      -0.04925478249788284,
      0.023578913882374763,
      0.04330025613307953,
      0.025687487795948982,
      -0.000232440754189156,
      0.03795959800481796,
      0.005854169838130474,
      0.049333374947309494,
      0.026503954082727432,
      -0.04694925248622894,
      -0.005811289884150028,
      -0.0006873595993965864,
      0.03674347326159477,
      -0.006997186224907637,
      -0.10167483985424042,
      -0.025774281471967697,
      0.00679721450433135,
      0.006851923651993275,
      -0.043173253536224365,
      -0.07766234129667282,
      0.0196361280977726,
      -0.04848950356245041,
      -0.0026273014955222607,
      0.015859520062804222,
      0.08796833455562592,
      0.047687094658613205,
      0.004913405980914831,
      -0.03157781809568405,
      -0.006916058249771595,
      0.021058201789855957,
      -0.016406867653131485,
      -0.08821593970060349,
      -0.06141402944922447,
      -0.008275050669908524,
      -0.010126063600182533,
      -0.006703492719680071,
      0.019298410043120384,
      0.0558333545923233,
      0.0680520236492157,
      0.04984479770064354,
      0.006548048462718725,
      -0.013551154173910618,
      -0.012046701274812222,
      -0.018083008006215096,
      0.06597240269184113,
      0.037656210362911224,
      0.01927439123392105,
      -0.05209006741642952,
      0.044868163764476776
    ],
    [
      -0.02945970930159092,
      0.04117199406027794,
      0.041169311851263046,
      -0.0014056506333872676,
      -0.060005102306604385,
      -0.018475351855158806,
      0.0684775561094284,
      -0.009573598392307758,
      -0.030111584812402725,
      -0.039577893912792206,
      0.028332829475402832,
      -0.008950207382440567,
      -0.07781591266393661,
      0.019833184778690338,
      0.06440886855125427,
      -0.006006215699017048,
      0.05411742255091667,
      0.02257179282605648,
      -0.046374138444662094,
      0.044351331889629364,
      -0.0019269947661086917,
      -0.04759564250707626,
      -0.02470400743186474,
      -0.03972162306308746,
      -0.01955157332122326,
      0.005888725630939007,
      -0.08287593722343445,
      -0.04229680448770523,
      0.0058008055202662945,
      -0.03956083208322525,
      0.004203755408525467,
      0.011409941129386425,
      -0.003074751701205969,
      0.03927800431847572,
      0.018066171556711197,
      -0.024549277499318123,
      -0.015587419271469116,
      0.0299547016620636,
      0.022771986201405525,
      -0.03552732616662979,
      0.07139082252979279,
      0.00019116656039841473,
      0.05653467774391174,
      -0.07285510748624802,
      -0.015940947458148003,
      0.011165663599967957,
      0.029760858044028282,
      -0.018592799082398415,
      -0.05137760192155838,
      -0.08770222216844559,
      -0.036909282207489014,
      -0.005383961834013462,
      0.08503024280071259,
      0.014570772647857666,
      -0.00018398907559458166,
      -0.04131587594747543,
      -0.07212324440479279,
      0.048075295984745026,
      -0.01637231931090355,
      -0.018617982044816017,
      -0.00947481207549572,
      0.007568507920950651,
      -0.02004389837384224,
      0.008900965563952923,
      0.04812418669462204,
      -0.004518297966569662,
      -0.030639341101050377,
      -0.034208741039037704,
      -0.05540613830089569,
      0.05164077505469322,
      0.043033137917518616,
      -0.02427000366151333,
      -0.015501564368605614,
      -0.04213866963982582,
      0.0031703568529337645,
      -0.06457802653312683,
      -0.08762101083993912,
      0.13035708665847778,
      -0.0681147426366806,
      -0.06159146502614021,
      0.0470149889588356,
      -0.04443717375397682,
      0.0984717458486557,
      -0.020294008776545525,
      -0.03132175654172897,
      -0.05234849080443382,
      -0.06184732913970947,
      -0.04082057997584343,
      0.11657839268445969,
      -0.044351235032081604,
      -0.014740779995918274,
      0.0025147085543721914,
      -0.05076257511973381,
      -0.015234318561851978,
      0.006938725244253874,
      -0.0028779685962945223,
      0.08853805065155029,
      -0.11292769014835358,
      0.06287750601768494,
      0.04382502660155296,
      0.004312536679208279,
      -0.00014090680633671582,
      0.017444677650928497,
      -0.02019612118601799,
      0.01792977750301361,
      -0.027960866689682007,
      -0.009398465976119041,
      -0.07376186549663544,
      -0.016605529934167862,
      0.0018762898398563266,
      0.011547147296369076,
      -0.003805607557296753,
      0.02377471886575222,
      -0.05553698539733887,
      -0.03691675886511803,
      -0.015760548412799835,
      -0.025682663545012474,
      -0.0333186537027359,
      -0.004665557295084,
      0.04955034703016281,
      -0.066905178129673,
      -0.05090292543172836,
      -0.015621965751051903,
      -0.043035320937633514,
      0.051541149616241455,
      -0.12022524327039719,
      0.051475636661052704,
      0.04302384704351425,
      0.020312698557972908,
      -0.07671798020601273,
      -0.06270275264978409,
      -0.03494497388601303,
      0.00810917280614376,
      -0.010618569329380989,
      0.08095509558916092,
      0.061416298151016235,
      0.036053337156772614,
      0.0920177549123764,
      -0.007832915522158146,
      -0.003906597848981619,
      -0.10580158978700638,
      0.041156213730573654,
      0.03383481502532959,
      0.04589539393782616,
      0.11362661421298981,
      -0.0023212565574795008,
      -0.024094313383102417,
      -0.049407366663217545,
      0.059088800102472305,
      -0.04884280636906624,
      -0.027144597843289375,
      0.07898067682981491,
      -0.020466070622205734,
      -0.06466429680585861,
      0.014368923380970955,
      -0.058699578046798706,
      -0.04931432381272316,
      0.012602660804986954,
      -0.11746406555175781,
      -0.014883971773087978,
      -0.012606220319867134,
      0.03457418829202652,
      -0.06610526889562607,
      0.11260665208101273,
      0.0005274632130749524,
      0.011546267196536064,
      -0.013013359159231186,
      -0.07713937014341354,
      -0.05407517030835152,
      0.013909568078815937,
      0.0740988627076149,
      -0.01991073600947857,
      -0.014051277190446854,
      -0.043241385370492935,
      -0.006376434583216906,
      0.046865519136190414,
      0.03645908832550049,
      -0.014287365600466728,
      0.010679271072149277,
      0.08656439185142517,
      -0.009070772677659988,
      -0.03307216614484787,
      0.06259078532457352,
      0.004255230538547039,
      -0.021162427961826324,
      0.006241030991077423,
      0.02208428457379341,
      -0.030202994123101234,
      0.04070252180099487,
      0.033138830214738846,
      -0.045947931706905365,
      0.032798588275909424,
      0.01614833064377308,
      0.08162108808755875,
      -0.01817975752055645,
      0.03973083198070526,
      0.023271366953849792,
      0.00848467368632555,
      0.024562295526266098,
      0.039895497262477875,
      0.062475696206092834,
      -0.014336347579956055,
      -0.037481632083654404,
      -0.03877021372318268,
      -0.02287749946117401,
      -0.038917314261198044,
      -0.04164321720600128,
      -0.01834407076239586,
      0.016090551391243935,
      0.03471500426530838,
      -0.00734998332336545,
      0.03203219547867775,
      0.0026639222633093596,
      -0.0014695570571348071,
      0.028175218030810356,
      -0.07898210734128952,
      0.03798748925328255,
      -0.048786986619234085,
      -0.013047544285655022,
      -0.017172856256365776,
      0.06422426551580429,
      0.019463621079921722,
      -0.07764167338609695,
      -0.04358062148094177,
      0.07191169261932373,
      -0.026670880615711212,
      0.029162829741835594,
      0.019201630726456642,
      -0.03330279886722565,
      -0.07042545080184937,
      -0.024488912895321846,
      -0.07790708541870117,
      -0.005819466896355152,
      -0.04280804842710495,
      0.062452755868434906,
      -0.059321437031030655,
      0.007316122762858868,
      -0.0073722549714148045,
      -0.0012780299875885248,
      0.03898293897509575,
      0.037217408418655396,
      -0.04109610244631767,
      0.02742653712630272,
      -0.012062355875968933,
      0.09516682475805283,
      0.05374766141176224,
      -0.017848223447799683,
      0.05408094823360443,
      -0.00037482925108633935,
      0.06528829783201218,
      0.003595242276787758,
      0.017480894923210144,
      -0.01740095764398575,
      -0.07140462845563889,
      0.023834068328142166,
      0.007644190918654203,
      0.04844330623745918,
      0.02988840639591217,
      -0.010614659637212753,
      -0.054277729243040085,
      0.004548361990600824,
      0.020296968519687653,
      -0.008125361986458302,
      0.013649110682308674,
      -0.06114891171455383,
      -0.07076825201511383,
      0.08116456866264343,
      0.04043194651603699,
      -0.04351603984832764,
      0.05459755286574364,
      0.07698309421539307,
      0.031041642650961876,
      -0.036085985600948334,
      0.032411012798547745,
      0.02844918519258499,
      -0.07098407298326492,
      0.043830692768096924,
      -0.005900049582123756,
      0.083037830889225,
      0.02900204062461853,
      -0.03690362349152565,
      -0.011564232409000397,
      -0.01841878518462181,
      -0.04564409703016281,
      0.037161290645599365,
      0.00229162210598588,
      0.034214720129966736,
      -0.0068677314557135105,
      -0.022529831156134605,
      0.042770855128765106,
      0.01490370836108923,
      0.07829355448484421,
      -0.008079285733401775,
      0.021427014842629433,
      0.017331810668110847,
      0.0043995254673063755,
      -0.039751410484313965,
      -0.013103969395160675,
      0.021543683484196663,
      -0.004704432096332312,
      -0.06120458245277405,
      0.01321488805115223,
      0.0013415616704151034,
      0.030942443758249283,
      0.05738101527094841,
      -0.030781375244259834,
      -0.03991939127445221,
      -0.011369653977453709,
      -0.06934347748756409,
      -0.00425854604691267,
      0.04232466220855713,
      0.004951184149831533,
      -0.02658817172050476,
      0.05347665771842003,
      0.052999239414930344,
      0.06996127963066101,
      0.06307980418205261,
      -0.05090849846601486,
      -0.04786210507154465,
      -0.06551331281661987,
      0.01827840879559517,
      -0.10105437785387039,
      -0.0592426098883152,
      0.016170967370271683,
      0.0039022406563162804,
      0.05781906470656395,
      -0.05851360037922859,
      0.004795459099113941,
      0.023862246423959732,
      -0.04159500449895859,
      -0.031182682141661644,
      0.04351550713181496,
      0.01732039265334606,
      -0.011169396340847015,
      -0.044443048536777496,
      0.020992979407310486,
      -0.0075570372864604,
      0.04645107686519623,
      -0.045287635177373886,
      0.0032246150076389313,
      0.029074128717184067,
      -0.04387597739696503,
      -0.00498999422416091,
      -0.050962988287210464,
      0.05185496062040329,
      0.01882968842983246,
      -0.008349648676812649,
      -0.02333533577620983,
      -0.05078981816768646,
      0.014591396786272526,
      -0.005281815771013498,
      0.04305795952677727,
      0.07302934676408768,
      0.08794832229614258,
      0.011893341317772865,
      -0.07627648115158081,
      -0.02373981848359108,
      -0.019419880583882332,
      0.003757186932489276,
      -0.05429975688457489,
      -0.04832099378108978,
      -0.014249254949390888,
      -0.02259659767150879,
      -0.029308374971151352,
      0.0776471197605133,
      -0.011569705791771412,
      0.011837991885840893,
      0.017076024785637856,
      -0.0069288392551243305,
      -0.03163769096136093,
      -0.0052536725997924805,
      0.056351207196712494,
      0.0014698189916089177,
      -0.00938495434820652,
      -0.011652333661913872,
      0.009311155416071415,
      -0.029956655576825142,
      -0.004993980750441551,
      0.06856782734394073,
      0.014320498332381248,
      -0.1064281240105629,
      0.04371790960431099,
      0.0020082101691514254,
      0.013862121850252151,
      0.007406921125948429,
      0.022141477093100548,
      0.006612848024815321,
      -0.002241836627945304,
      0.018826795741915703,
      0.023300236091017723,
      -0.0305868461728096,
      -0.04259198158979416,
      -0.0580553337931633,
      0.00499374233186245,
      0.0552251897752285,
      -0.04249211400747299,
      0.022612424567341805,
      0.012247773818671703,
      -0.05590327829122543,
      -0.05053264647722244,
      0.04307695850729942,
      -0.006860664580017328,
      0.03874333202838898,
      0.05933941528201103,
      -0.02831638976931572,
      0.01765238121151924,
      -0.0322037898004055,
      -0.08231493830680847,
      0.027733106166124344,
      -0.06963755190372467,
      -0.02986801229417324,
      -0.005534140393137932,
      -0.049764905124902725,
      0.0063111307099461555,
      -0.002269193297252059,
      -0.08077915012836456,
      0.01987640932202339,
      0.04446408525109291,
      -0.02772146463394165,
      -0.02496299520134926,
      -0.10829602181911469,
      -0.05963673070073128,
      -0.05300416424870491,
      -0.008896677754819393,
      -0.062154799699783325,
      -0.0030693886801600456,
      0.07991094887256622,
      0.03300933167338371,
      -0.02183580957353115,
      0.016698073595762253,
      -0.028207292780280113,
      -0.007300523109734058,
      -0.08192966878414154,
      -0.0015652397414669394,
      -0.024949248880147934,
      0.07911457866430283,
      0.03234470263123512,
      -0.08970841020345688,
      0.026693075895309448,
      0.004135448951274157,
      -0.021839240565896034,
      0.048891108483076096,
      0.009780200198292732,
      0.028234709054231644,
      0.03178653120994568,
      -0.04697464033961296,
      0.008248817175626755,
      -0.05189353600144386,
      0.08218658715486526,
      -0.041099950671195984,
      -0.02643621526658535,
      -0.01713002286851406,
      0.039812128990888596,
      -0.05721630901098251,
      0.04523925483226776,
      0.11003561317920685,
      0.037265844643116,
      0.02943500690162182,
      0.05091423913836479,
      0.030409758910536766,
      0.002686089836061001,
      -0.0005194282275624573,
      -0.041058510541915894,
      0.018679143860936165,
      0.029677093029022217,
      -0.05665624886751175,
      0.03847605362534523,
      -0.04637178033590317,
      -0.016249168664216995,
      0.039678238332271576,
      0.06718065589666367,
      0.027513861656188965,
      -0.039329562336206436,
      0.03129167854785919,
      0.08991585671901703,
      -0.022097166627645493,
      0.05522714927792549,
      0.10073521733283997,
      -0.0366482175886631,
      0.02842140942811966,
      0.032730165868997574,
      -0.04420775920152664,
      0.029912864789366722,
      0.01764991134405136,
      0.01747937500476837,
      -0.051980942487716675,
      -0.008762477897107601,
      -0.0007239522528834641,
      -0.07210969924926758,
      0.014769907109439373,
      0.04989580810070038,
      0.03294438123703003,
      0.030554544180631638,
      -0.028614528477191925,
      -0.05894271656870842,
      0.029834384098649025,
      0.004696686286479235,
      0.05712708458304405,
      0.03076181560754776,
      -0.01911105401813984,
      0.030831273645162582,
      0.06214786693453789,
      0.02417050302028656
    ],
    [
      -0.0195064265280962,
      0.07359550148248672,
      -0.009662173688411713,
      0.005691294092684984,
      0.02310710772871971,
      0.05135349556803703,
      0.029984887689352036,
      0.058678656816482544,
      -0.054629433900117874,
      -0.024509485810995102,
      -0.04975789412856102,
      0.05267168581485748,
      0.03604459762573242,
      0.053551916033029556,
      -0.03479338809847832,
      -0.10105320066213608,
      -0.037389934062957764,
      -0.012927605770528316,
      -0.02158515714108944,
      -0.024398846551775932,
      -0.04289361834526062,
      -0.012953796423971653,
      0.010957890190184116,
      -0.017096050083637238,
      0.0022613760083913803,
      0.013935166411101818,
      0.03729667142033577,
      0.05207141116261482,
      -0.06491222232580185,
      0.04576900973916054,
      0.030855612829327583,
      0.028734590858221054,
      0.02034948766231537,
      -0.034989334642887115,
      -0.012859877198934555,
      -0.024351561442017555,
      0.05385804548859596,
      0.05410836264491081,
      -0.024825135245919228,
      0.02815813384950161,
      0.035364314913749695,
      -0.029832853004336357,
      -0.02790151908993721,
      -0.03455318883061409,
      -0.08538518846035004,
      0.16143813729286194,
      -0.01073689665645361,
      -0.0015568712260574102,
      -0.01077162567526102,
      -0.031819168478250504,
      -0.014204795472323895,
      -0.03321075066924095,
      -0.030961627140641212,
      -0.013493155129253864,
      -0.0040458799339830875,
      -0.007088575512170792,
      -0.06165743246674538,
      -0.06481725722551346,
      -0.027791311964392662,
      0.009292730130255222,
      0.07983121275901794,
      -0.01177310012280941,
      0.0950726717710495,
      -0.01763342134654522,
      0.07972216606140137,
      -0.028510311618447304,
      0.01215428113937378,
      0.08459467440843582,
      -0.022095242515206337,
      -0.010748270899057388,
      0.03740672767162323,
      -0.03957744315266609,
      -0.0015046217013150454,
      -0.011925732716917992,
      -0.029763229191303253,
      0.016282012686133385,
      -0.03065817803144455,
      0.012816298753023148,
      0.04278184473514557,
      0.032721322029829025,
      -0.056940220296382904,
      0.0028657668735831976,
      0.009791620075702667,
      0.0122569240629673,
      -0.010740549303591251,
      -0.06300169974565506,
      -0.0014812792651355267,
      0.035053275525569916,
      -0.07194007188081741,
      0.0028475397266447544,
      -0.008376602083444595,
      0.00046182231744751334,
      -0.06450312584638596,
      -0.040878649801015854,
      -0.020580511540174484,
      0.04870499670505524,
      0.05948944017291069,
      0.00913733709603548,
      -0.057488813996315,
      -0.07281497120857239,
      0.03358617424964905,
      0.07954471558332443,
      0.053969357162714005,
      -0.07741846889257431,
      -0.04577506706118584,
      -0.0557803176343441,
      -0.07016314566135406,
      -0.029841847717761993,
      0.06847215443849564,
      -0.02507884055376053,
      -0.1027534008026123,
      0.015168294310569763,
      0.056567203253507614,
      -0.041893381625413895,
      -0.0029697129502892494,
      -0.02414054609835148,
      -0.04788170009851456,
      0.02551155351102352,
      -0.08910006284713745,
      0.037639070302248,
      0.020686667412519455,
      0.016867591068148613,
      -0.055529192090034485,
      -0.021314281970262527,
      0.03642113879323006,
      0.05871063470840454,
      0.04063192754983902,
      -0.0155092878267169,
      -0.02851194702088833,
      0.0006681742379441857,
      0.02400192804634571,
      -0.019546344876289368,
      0.004911390598863363,
      -0.06973310559988022,
      -0.08317375183105469,
      0.013053260743618011,
      -0.03606097027659416,
      -0.030480606481432915,
      -0.014514587819576263,
      0.021995404735207558,
      0.010529844090342522,
      0.03659403324127197,
      -0.04050389677286148,
      0.015491455793380737,
      0.0251636765897274,
      0.02860473096370697,
      -0.05804853141307831,
      -0.03786718100309372,
      0.024625133723020554,
      0.008570612408220768,
      -0.005439104977995157,
      0.04201434925198555,
      -0.017047541216015816,
      0.019619135186076164,
      0.019871456548571587,
      -0.008276372216641903,
      -0.0038876906037330627,
      -0.004461424890905619,
      0.04934699088335037,
      -0.021458683535456657,
      0.06746640056371689,
      -0.03152734041213989,
      0.033510323613882065,
      0.0055101835168898106,
      -0.009356295689940453,
      0.06743386387825012,
      -0.06724601984024048,
      0.02549320086836815,
      -0.03558996692299843,
      0.048958659172058105,
      0.09956107288599014,
      0.05646373704075813,
      0.045542944222688675,
      -0.008707843720912933,
      0.08329986035823822,
      0.1185927614569664,
      0.045198991894721985,
      0.0004756650887429714,
      0.05900086835026741,
      0.011885189451277256,
      -0.056952282786369324,
      -0.012870485894382,
      -0.016476353630423546,
      -0.03715403378009796,
      0.012899371795356274,
      0.021779699251055717,
      0.003648188430815935,
      -0.02170621231198311,
      -0.01567912846803665,
      0.026506775990128517,
      0.029171139001846313,
      -0.09252560883760452,
      0.03661491721868515,
      0.04797552525997162,
      0.02528342790901661,
      0.015838077291846275,
      -0.0013487446121871471,
      -0.04254908487200737,
      0.01348686683923006,
      -0.03337861970067024,
      -0.058745164424180984,
      0.05631536617875099,
      0.005031665321439505,
      -0.058158356696367264,
      0.03730783239006996,
      0.021852554753422737,
      0.018653249368071556,
      -0.03571230545639992,
      0.018083877861499786,
      -0.021043285727500916,
      0.049084704369306564,
      0.008488278836011887,
      0.05169415473937988,
      -0.022419312968850136,
      0.017716392874717712,
      -0.03966853767633438,
      -0.026905903592705727,
      -0.04039928689599037,
      0.00889413058757782,
      -0.026088662445545197,
      -0.010617760941386223,
      -0.002038022968918085,
      0.007062791846692562,
      -0.019043825566768646,
      -0.0611596554517746,
      0.05941101536154747,
      0.07852843403816223,
      0.09655600786209106,
      -0.04703061282634735,
      0.0050510223954916,
      -0.024241182953119278,
      -0.060763049870729446,
      0.0017689958913251758,
      -0.020560698583722115,
      0.04240847006440163,
      0.028604544699192047,
      -0.010740634053945541,
      0.039480797946453094,
      0.032315876334905624,
      0.008227967657148838,
      0.013540517538785934,
      0.031125998124480247,
      0.021013719961047173,
      0.0032004036474972963,
      0.03811883181333542,
      0.009579498320817947,
      -0.047523412853479385,
      0.02669304795563221,
      -0.06488905847072601,
      -0.025653120130300522,
      -0.07573667168617249,
      -0.08547656238079071,
      0.00419950857758522,
      0.015282943844795227,
      -0.03488340973854065,
      -0.047318700700998306,
      0.054500628262758255,
      0.049953117966651917,
      -0.004791742190718651,
      -0.07558517903089523,
      -0.005616191308945417,
      0.04206589236855507,
      -0.00880209356546402,
      -0.035056404769420624,
      0.009104009717702866,
      -0.0001761767198331654,
      0.03918301314115524,
      0.07460658252239227,
      0.03629178926348686,
      0.04101957008242607,
      0.02392716333270073,
      -0.0838404968380928,
      0.010033910162746906,
      0.036778904497623444,
      -0.0600048191845417,
      0.0656692311167717,
      -0.08237157762050629,
      -0.06310342997312546,
      0.01242352370172739,
      0.02048279345035553,
      0.006758762523531914,
      -0.04192741960287094,
      0.022054970264434814,
      0.012118158861994743,
      0.05535325035452843,
      0.05385970696806908,
      -0.04503301531076431,
      0.0772937685251236,
      0.07364526391029358,
      0.00016760143626015633,
      0.01913013495504856,
      0.027988478541374207,
      0.1045035719871521,
      -0.028265882283449173,
      0.02328813262283802,
      0.02891448140144348,
      0.022693749517202377,
      0.010058680549263954,
      -0.03171207010746002,
      -0.018033064901828766,
      0.03840470314025879,
      0.003365503391250968,
      -0.04451177641749382,
      -0.011392275802791119,
      0.03102586604654789,
      0.011563806794583797,
      -0.08013714849948883,
      0.013866868801414967,
      -0.07531104981899261,
      -0.00883418321609497,
      -0.03422657772898674,
      0.08096256852149963,
      -0.05012642219662666,
      -0.06229125335812569,
      0.012922319583594799,
      -0.019615771248936653,
      0.029600145295262337,
      0.0343291275203228,
      0.02953011356294155,
      -0.03510044515132904,
      -0.023667793720960617,
      -0.01677553541958332,
      0.0027947400230914354,
      -0.059770651161670685,
      -0.022846749052405357,
      0.020691920071840286,
      -0.05699567869305611,
      0.04928944259881973,
      0.10220829397439957,
      0.079571433365345,
      -0.020533455535769463,
      0.0034212861210107803,
      -0.06975302845239639,
      -0.010626030154526234,
      0.011565078049898148,
      0.03080623224377632,
      -0.013299370184540749,
      0.007308628875762224,
      0.01848500408232212,
      -0.012913785874843597,
      -0.009459161199629307,
      0.05086107552051544,
      0.01618291810154915,
      0.073605015873909,
      -0.02043115720152855,
      -0.025233685970306396,
      0.08471684902906418,
      0.0614003986120224,
      0.06383765488862991,
      0.05898541957139969,
      -0.07293814420700073,
      0.007618403062224388,
      0.015084865503013134,
      0.06647834926843643,
      -0.04116937518119812,
      0.025911971926689148,
      0.00441847275942564,
      0.0442078597843647,
      0.0186326801776886,
      -0.01454577874392271,
      -0.056580882519483566,
      -0.04247835651040077,
      0.01436114963144064,
      0.07129573076963425,
      0.11104502528905869,
      -0.049354687333106995,
      -0.034498099237680435,
      -0.016758255660533905,
      0.017708702012896538,
      -0.03329140692949295,
      0.02449512667953968,
      -0.002570257056504488,
      -0.037926219403743744,
      -0.058332864195108414,
      0.022474436089396477,
      0.0009512279648333788,
      -0.043818987905979156,
      0.06124556064605713,
      0.03496474400162697,
      -0.11050574481487274,
      0.08773964643478394,
      0.032885804772377014,
      -0.061192020773887634,
      0.054680828005075455,
      0.0006897806888446212,
      0.051278360188007355,
      0.04947851970791817,
      -0.010677211917936802,
      -0.01819939911365509,
      -0.025330346077680588,
      -0.039700523018836975,
      0.011276699602603912,
      -0.05515649542212486,
      0.0006511450628750026,
      -0.02342444472014904,
      -0.04465753585100174,
      0.0032154035288840532,
      -0.030746448785066605,
      0.006101595237851143,
      0.030589155852794647,
      -0.016190728172659874,
      -0.019798465073108673,
      -0.05391054227948189,
      -0.0017470880411565304,
      -0.01015977468341589,
      -0.02686982974410057,
      0.024871358647942543,
      0.04692072048783302,
      0.011869308538734913,
      0.09979718923568726,
      0.0005615116097033024,
      0.038478150963783264,
      0.03407222405076027,
      0.03317441791296005,
      -0.017647260800004005,
      -0.018656576052308083,
      0.046039171516895294,
      0.007548145949840546,
      -0.028272369876503944,
      0.013904975727200508,
      -0.012403867207467556,
      -0.05560269206762314,
      0.03392307087779045,
      -0.02911297045648098,
      0.05163088068366051,
      0.019735267385840416,
      -0.046895354986190796,
      -0.03380223363637924,
      0.016835685819387436,
      -0.028531553223729134,
      -0.04605286195874214,
      -0.07164262235164642,
      0.022886265069246292,
      0.07137267291545868,
      0.02969213016331196,
      -0.043188851326704025,
      -0.021951032802462578,
      -0.03516840934753418,
      0.07479173690080643,
      -0.005730874370783567,
      0.07569416612386703,
      0.04010678827762604,
      0.011994815431535244,
      0.07308539748191833,
      -0.01218063198029995,
      -0.033901698887348175,
      -0.009760058484971523,
      0.05151664838194847,
      -0.004849036689847708,
      0.04886288940906525,
      -0.0808001458644867,
      -0.018928946927189827,
      -0.05501975119113922,
      -0.01798221282660961,
      -0.010926805436611176,
      0.0690927505493164,
      0.0028494601137936115,
      0.05361775681376457,
      -0.014365354552865028,
      -0.027745651081204414,
      0.060659173876047134,
      0.07619625329971313,
      0.028646545484662056,
      0.018963636830449104,
      0.053670767694711685,
      0.12486770004034042,
      0.07117100059986115,
      0.008592872880399227,
      0.02301914244890213,
      0.00831608660519123,
      -0.07845266163349152,
      0.017725272104144096,
      -0.05824257805943489,
      -0.018374433740973473,
      -0.043306831270456314,
      -0.0008069362957030535,
      0.055813588201999664,
      -0.019879033789038658,
      -0.031065277755260468,
      0.0354665108025074,
      0.07681896537542343,
      -0.01783251017332077,
      0.0018612578278407454,
      -0.03645370155572891,
      0.04245600476861,
      0.01008143275976181,
      -0.00877945963293314,
      -0.01031863410025835,
      0.014598356559872627,
      0.0660981759428978,
      -0.008840994909405708,
      -0.011613292619585991,
      -0.02838001400232315,
      -0.005985010880976915,
      0.052470024675130844,
      -0.01387071143835783,
      -0.0032054593320935965,
      0.06908474862575531,
      0.028557047247886658,
      0.021893106400966644,
      -0.06228301301598549,
      0.06219257414340973,
      0.05457776412367821
    ],
    [
      0.015167588368058205,
      -0.01815497688949108,
      -0.03851717337965965,
      0.10569511353969574,
      -0.07492969930171967,
      -0.03130362182855606,
      -0.00859700608998537,
      0.07359591126441956,
      -0.02126050926744938,
      0.006703929044306278,
      -0.030670711770653725,
      -0.0099323196336627,
      0.09466870129108429,
      0.12744610011577606,
      -0.032645173370838165,
      -0.03571893647313118,
      0.1113714948296547,
      -0.02359817735850811,
      0.026883773505687714,
      -0.026726029813289642,
      0.007887634448707104,
      -0.0869443416595459,
      -0.0023998161777853966,
      -0.030062956735491753,
      0.00783006101846695,
      -0.06690346449613571,
      0.018065551295876503,
      -0.04167155548930168,
      -0.07815707474946976,
      0.0626617893576622,
      -0.008772100321948528,
      -0.0026490797754377127,
      -0.011401758529245853,
      0.04091722518205643,
      -0.04038666561245918,
      0.019149472936987877,
      -0.037503574043512344,
      -0.03352101147174835,
      -0.03922565281391144,
      0.05773903429508209,
      0.037617143243551254,
      0.044562701135873795,
      0.05156814306974411,
      0.007517874240875244,
      0.038319483399391174,
      0.03888401761651039,
      0.04015687108039856,
      -0.041312944144010544,
      -0.037417951971292496,
      0.0563679002225399,
      0.04593435674905777,
      0.035612814128398895,
      -0.05454280227422714,
      0.0586775578558445,
      0.031190985813736916,
      -0.017192700877785683,
      0.014774870127439499,
      0.008426802232861519,
      -0.04259336739778519,
      -0.01490567997097969,
      0.07743276655673981,
      -0.03632243350148201,
      -0.01627887785434723,
      0.014948869124054909,
      -0.02385891042649746,
      -0.07655708491802216,
      -0.0701230987906456,
      -0.06895624846220016,
      -0.03038613125681877,
      0.05295751616358757,
      -0.05276331678032875,
      0.09199021756649017,
      0.03116573765873909,
      -0.07429754734039307,
      0.048209190368652344,
      -0.03604196384549141,
      -0.06531652808189392,
      -0.04035038873553276,
      -0.01415396947413683,
      0.11794444173574448,
      -0.015236491337418556,
      -0.0012643237132579088,
      0.01942150853574276,
      -0.041191957890987396,
      -0.032486118376255035,
      -0.012246877886354923,
      0.013375804759562016,
      -0.12464815378189087,
      -0.012812797911465168,
      -0.01355357002466917,
      0.047476738691329956,
      0.021302228793501854,
      -0.03075077384710312,
      -0.017243046313524246,
      0.06414568424224854,
      0.00010478708281880245,
      -0.030413081869482994,
      -0.03393394500017166,
      -0.0020040979143232107,
      0.004260620102286339,
      0.08841419965028763,
      -0.02375485561788082,
      0.09677522629499435,
      0.04328503832221031,
      0.033612366765737534,
      0.004156079608947039,
      0.00064528250368312,
      -0.001615501707419753,
      -0.1071564182639122,
      -0.006035396363586187,
      0.009615256451070309,
      -0.0018766451394185424,
      -0.037676047533750534,
      -0.05949068069458008,
      -0.03936625272035599,
      -0.02169124409556389,
      0.0030977032147347927,
      0.07453605532646179,
      0.10936053842306137,
      0.02058326080441475,
      0.005065349396318197,
      0.010162927210330963,
      -0.03684462606906891,
      -0.014893509447574615,
      0.00029111694311723113,
      -0.01771889254450798,
      0.0162760391831398,
      0.005252042785286903,
      -0.0026338454335927963,
      -0.021145673468708992,
      -0.004287242889404297,
      -0.017854677513241768,
      0.12109797447919846,
      -0.11882096529006958,
      -0.01233013067394495,
      -0.04255908727645874,
      -0.00936394464224577,
      -0.015755342319607735,
      0.010915877297520638,
      0.030409937724471092,
      -0.06800074875354767,
      0.017561327666044235,
      0.017398877069354057,
      -0.009226794354617596,
      0.04740084335207939,
      -0.020168881863355637,
      -0.07510627061128616,
      0.03639181703329086,
      -0.02248067781329155,
      0.007191795855760574,
      -0.05216355621814728,
      -0.045129068195819855,
      -0.023906324058771133,
      -0.03784836083650589,
      -0.08556698262691498,
      -0.028560666367411613,
      -0.09011940658092499,
      0.08810847252607346,
      -0.015973953530192375,
      0.04218636080622673,
      0.07205888628959656,
      -0.0044531384482979774,
      -0.012069949880242348,
      0.03466207906603813,
      -0.05891965702176094,
      0.05827239528298378,
      -0.020698847249150276,
      0.005705530289560556,
      0.09356404840946198,
      -0.02444806881248951,
      0.03168831393122673,
      0.0299990214407444,
      -0.0012087340001016855,
      0.018754765391349792,
      -0.012950957752764225,
      -0.032511673867702484,
      0.021987514570355415,
      -0.00960641447454691,
      0.02549052983522415,
      0.01305857952684164,
      -0.005526390857994556,
      0.005512654781341553,
      0.027876421809196472,
      -0.056634243577718735,
      0.009130372665822506,
      0.00519071239978075,
      0.060590267181396484,
      -0.050536151975393295,
      -0.016099950298666954,
      -0.03080807253718376,
      0.008618599735200405,
      -0.0300952959805727,
      0.07052590698003769,
      0.02682734653353691,
      -0.03563624620437622,
      -0.029050933197140694,
      -0.007515539415180683,
      0.005222405772656202,
      -0.1143142357468605,
      0.03482687100768089,
      0.0008667964721098542,
      -0.038399837911129,
      -0.051478706300258636,
      0.04751313477754593,
      -0.017722073942422867,
      -0.009260107763111591,
      -0.08011875301599503,
      0.017460964620113373,
      -0.05920279771089554,
      0.06577038019895554,
      0.026607494801282883,
      0.013670270331203938,
      0.03151385486125946,
      0.05010849982500076,
      0.016984542831778526,
      0.028972016647458076,
      0.004571464378386736,
      -0.0518430694937706,
      0.04879341274499893,
      -0.08749083429574966,
      -0.02738831751048565,
      -0.0022208993323147297,
      -0.03930673748254776,
      -0.07844159007072449,
      -0.00728627247735858,
      -0.03206303343176842,
      -0.06711074709892273,
      0.13795700669288635,
      -0.027898725122213364,
      -0.02443593181669712,
      0.00623627845197916,
      0.030643001198768616,
      0.011114002205431461,
      -0.019067931920289993,
      -0.007296186871826649,
      -0.011234630830585957,
      0.02784034237265587,
      -0.030291851609945297,
      -0.009547743014991283,
      0.02095138467848301,
      -0.026052867993712425,
      0.002595496829599142,
      0.06108683720231056,
      -0.10168508440256119,
      -0.02369634620845318,
      0.037399549037218094,
      0.005355232395231724,
      -0.08981777727603912,
      -0.03474933281540871,
      0.05408235266804695,
      -0.05852051451802254,
      -0.008812310174107552,
      0.02651008777320385,
      0.05961223319172859,
      0.04275795444846153,
      -0.03575267642736435,
      0.07570040225982666,
      0.06189833953976631,
      -0.02742757648229599,
      -0.01657196320593357,
      -0.03358747437596321,
      -0.024734515696763992,
      0.01092132180929184,
      0.020762315019965172,
      -0.037920497357845306,
      -0.033990345895290375,
      -0.06486281007528305,
      -0.009489210322499275,
      -0.08697280287742615,
      0.05535893142223358,
      0.029349414631724358,
      0.03734701871871948,
      -0.011994493193924427,
      0.011698480695486069,
      0.023542366921901703,
      -0.008579881861805916,
      -0.030665382742881775,
      0.042078498750925064,
      -0.028861505910754204,
      0.007034969981759787,
      -0.06543713063001633,
      0.04950203374028206,
      0.0039674947038292885,
      -0.002224298659712076,
      0.03257446736097336,
      -0.00923448707908392,
      -0.008891457691788673,
      -0.0180716123431921,
      -0.03585326671600342,
      -0.0530770942568779,
      -0.034329332411289215,
      0.02631695754826069,
      -0.0001871287968242541,
      -0.016633518040180206,
      -0.019465917721390724,
      -0.03803443908691406,
      -0.03682885691523552,
      -0.06534072011709213,
      -0.005588955245912075,
      -0.0018340147798880935,
      -0.04261872172355652,
      0.006786079611629248,
      -0.02254543825984001,
      0.05866518244147301,
      -0.033313799649477005,
      -0.011266615241765976,
      0.01937984861433506,
      -0.04168897867202759,
      0.050543297082185745,
      0.004781033843755722,
      0.062455520033836365,
      0.08346424251794815,
      0.08153314143419266,
      -0.04289178550243378,
      0.022248033434152603,
      0.005487736780196428,
      0.008380809798836708,
      0.05845498666167259,
      0.016414158046245575,
      -0.020982984453439713,
      0.06946499645709991,
      0.04581116884946823,
      0.009852446615695953,
      0.04331560060381889,
      0.034860264509916306,
      -0.05755850300192833,
      0.007092922925949097,
      -0.07140903919935226,
      0.07575066387653351,
      -0.01515925768762827,
      0.04468681663274765,
      0.06332390755414963,
      0.005161370616406202,
      0.026523182168602943,
      -0.10386130213737488,
      -0.02855171263217926,
      0.019291255623102188,
      0.007013283669948578,
      0.03241078183054924,
      -0.09104283899068832,
      -0.04582897946238518,
      -0.025946706533432007,
      0.022649459540843964,
      -0.08415000885725021,
      0.005598788149654865,
      -0.003082934534177184,
      -0.021772516891360283,
      -0.033695511519908905,
      -0.05019872635602951,
      0.05653216317296028,
      0.008802874945104122,
      0.03502478823065758,
      0.05348942428827286,
      -0.007037485484033823,
      -0.01725592650473118,
      0.023899372667074203,
      -0.009847390465438366,
      -0.0006322900881059468,
      0.0357903316617012,
      0.017405644059181213,
      -0.09067356586456299,
      0.055594779551029205,
      0.05144450068473816,
      -0.08144053816795349,
      0.07933278381824493,
      0.0022737414110451937,
      -0.09817913919687271,
      -0.07052739709615707,
      0.0486927255988121,
      0.10832449793815613,
      0.034670837223529816,
      0.05070493370294571,
      0.034748975187540054,
      -0.0236045029014349,
      0.008148894645273685,
      0.02683916687965393,
      0.007824070751667023,
      0.08467482775449753,
      0.015795333310961723,
      0.03201967477798462,
      -0.004891620017588139,
      0.0018896069377660751,
      -0.03544199839234352,
      0.040288541465997696,
      -0.09253448992967606,
      0.01819782517850399,
      -0.08387208729982376,
      -0.05350736901164055,
      0.035036422312259674,
      0.04087671637535095,
      0.024517659097909927,
      -0.0159912072122097,
      0.044776801019907,
      -0.01177650410681963,
      -0.002031379844993353,
      -0.07633679360151291,
      -0.024985700845718384,
      0.011612830683588982,
      -0.04080843925476074,
      0.018074898049235344,
      0.0451490692794323,
      0.029897009953856468,
      -0.015521278604865074,
      0.00041171451448462903,
      -0.0411129891872406,
      0.06247716024518013,
      0.04330558329820633,
      -0.019268561154603958,
      -0.009785986505448818,
      0.07583530247211456,
      0.02864835225045681,
      -0.04770853370428085,
      -0.020803865045309067,
      -0.031630948185920715,
      -0.06652127206325531,
      0.05336243659257889,
      -0.00885264202952385,
      -0.008825445547699928,
      0.017365165054798126,
      -0.11915118992328644,
      0.0068664876744151115,
      -0.00656770309433341,
      0.02021300047636032,
      -0.04319559037685394,
      0.05432749167084694,
      0.015817862004041672,
      -0.047746941447257996,
      -0.01581314578652382,
      0.07100121676921844,
      -0.02669350802898407,
      -0.002131425077095628,
      0.013296747580170631,
      -0.03120618686079979,
      -0.06335683166980743,
      0.013390461914241314,
      0.05107055976986885,
      0.08194965124130249,
      0.0025501451455056667,
      -2.200173184974119e-05,
      -0.01529355812817812,
      0.032021332532167435,
      0.004416510462760925,
      0.039904236793518066,
      -0.02960304357111454,
      -0.1007118821144104,
      -0.013479555957019329,
      -0.07314939796924591,
      0.008726011961698532,
      0.032075464725494385,
      -0.024119500070810318,
      -0.0021051866933703423,
      -0.00890705268830061,
      0.04235215112566948,
      -0.004519133362919092,
      -0.06883818656206131,
      -0.019303269684314728,
      -0.021167896687984467,
      -0.03880519047379494,
      -0.05596860870718956,
      -0.0319618321955204,
      0.0778578445315361,
      0.04872721806168556,
      0.011300615034997463,
      0.020964626222848892,
      -0.05068901926279068,
      -0.026658331975340843,
      -0.004215514753013849,
      -0.035035550594329834,
      -0.0019437760347500443,
      0.005170111544430256,
      0.011070357635617256,
      -0.029167065396904945,
      0.08305385708808899,
      -0.02190081961452961,
      0.0765591710805893,
      -0.02974250167608261,
      0.005258799530565739,
      0.010262864641845226,
      -0.048198189586400986,
      -0.03704289719462395,
      0.021093744784593582,
      -0.05175057426095009,
      0.02487846463918686,
      -0.0641305223107338,
      -0.056676220148801804,
      0.02017403393983841,
      0.013326109386980534,
      -0.00407193461433053,
      0.06899704039096832,
      0.03509252518415451,
      -0.0692233219742775,
      0.03482142835855484,
      0.03571109473705292,
      0.028195399791002274,
      0.0065300604328513145,
      0.03473929688334465,
      -0.051341135054826736,
      -0.01907402276992798,
      -0.022897420451045036,
      0.040504030883312225,
      0.06135975942015648,
      -0.04816244915127754,
      -0.029546339064836502
    ],
    [
      0.009646940045058727,
      -0.02385382540524006,
      -0.025756264105439186,
      0.0017529414035379887,
      -0.0045831650495529175,
      -0.021594207733869553,
      -0.03385843336582184,
      -0.054677851498126984,
      -0.10180515795946121,
      -0.01013241708278656,
      -0.04223905876278877,
      -0.005809377878904343,
      -0.021851958706974983,
      0.0656207874417305,
      -0.04601019620895386,
      0.031836915761232376,
      0.019270282238721848,
      0.00345604307949543,
      0.0020785436499863863,
      -0.09099999815225601,
      -0.017126383259892464,
      0.047476623207330704,
      -0.019581064581871033,
      0.015102618373930454,
      0.04358099400997162,
      -0.06895627081394196,
      0.016134053468704224,
      -0.06080005690455437,
      0.024290522560477257,
      0.05245431140065193,
      0.021644771099090576,
      0.0444943942129612,
      0.0224996916949749,
      0.017834240570664406,
      -0.10416768491268158,
      -0.004278204869478941,
      -0.055306706577539444,
      0.0033321892842650414,
      -0.023399442434310913,
      -0.020856758579611778,
      0.05853358283638954,
      0.052584607154130936,
      -0.1356368064880371,
      -0.010580680333077908,
      -0.03169096261262894,
      0.04032840207219124,
      -0.006259709596633911,
      -0.021665923297405243,
      -0.04642467200756073,
      0.00019112630980089307,
      -0.007508308161050081,
      0.06744855642318726,
      0.07819721102714539,
      -0.05056168884038925,
      -0.03232646360993385,
      -0.006342282518744469,
      -0.007461400702595711,
      -0.08115696161985397,
      -0.06309744715690613,
      0.03153188154101372,
      -0.015769992023706436,
      0.00594380684196949,
      -0.04774767905473709,
      0.0018845641752704978,
      0.04961802065372467,
      0.05832379683852196,
      0.02517501823604107,
      -0.07257692515850067,
      -0.052710797637701035,
      0.05893497169017792,
      -0.03608236089348793,
      0.013376321643590927,
      -0.02562888152897358,
      0.0018517231801524758,
      -0.00459146685898304,
      -0.0022228965535759926,
      -0.08949499577283859,
      0.03004162572324276,
      -0.01604231260716915,
      -0.011144192889332771,
      -0.03440355882048607,
      0.032202038913965225,
      0.001530052861198783,
      -0.00401691347360611,
      -0.03331715986132622,
      0.030851168558001518,
      0.05957615748047829,
      0.005193271208554506,
      -0.013197526335716248,
      0.03610885888338089,
      0.09458200633525848,
      -0.054266173392534256,
      -0.05739618465304375,
      0.003187093185260892,
      -0.01899690181016922,
      0.03602045774459839,
      -0.04618976265192032,
      0.04423244670033455,
      -0.05758322775363922,
      -0.006849674042314291,
      0.07648881524801254,
      0.0071137635968625546,
      0.03566763550043106,
      -0.003406836884096265,
      -0.051306385546922684,
      -0.03586873412132263,
      -0.05476480722427368,
      -0.10192325711250305,
      -0.06193918734788895,
      -0.0473208874464035,
      0.0546489879488945,
      0.0028732893988490105,
      0.03778178617358208,
      0.03770739212632179,
      -0.08966649323701859,
      -0.010286083444952965,
      0.005484511144459248,
      0.04432019218802452,
      -0.13393524289131165,
      0.050716664642095566,
      -0.04002378135919571,
      -0.05430416017770767,
      -0.0012159326579421759,
      0.017087090760469437,
      -0.03882260620594025,
      0.008489510975778103,
      0.007299817632883787,
      0.0642600953578949,
      -0.024727802723646164,
      -0.039022672921419144,
      0.014445360749959946,
      -0.03793542459607124,
      -0.013796459883451462,
      -0.047266602516174316,
      -0.01996377855539322,
      0.038886215537786484,
      0.0885041132569313,
      0.023826278746128082,
      0.01406133733689785,
      -0.03971685841679573,
      0.010077003389596939,
      0.007456939201802015,
      0.012529272586107254,
      0.023606648668646812,
      -0.023331450298428535,
      -0.04301852732896805,
      0.00169197132345289,
      -0.03024895116686821,
      0.07131239771842957,
      -0.04474521055817604,
      0.0354490764439106,
      0.016936980187892914,
      0.013706049881875515,
      0.002160030882805586,
      -0.0007284950115717947,
      0.015310494229197502,
      0.022469855844974518,
      0.04503409191966057,
      -0.035652149468660355,
      -0.06163780018687248,
      0.07224701344966888,
      0.031178563833236694,
      0.021719373762607574,
      -0.040723394602537155,
      -0.02015387825667858,
      0.03503943234682083,
      0.005299058742821217,
      0.02399064041674137,
      -0.02279708907008171,
      -0.013205595314502716,
      -0.029021065682172775,
      0.06115110591053963,
      0.00948026031255722,
      -0.03828328475356102,
      -0.027846675366163254,
      -0.016967471688985825,
      -0.07218244671821594,
      0.06144702062010765,
      -0.025384895503520966,
      0.009852277114987373,
      0.07475464046001434,
      0.06353946775197983,
      0.06349584460258484,
      -0.02778719924390316,
      0.02718978561460972,
      0.08928859233856201,
      -0.035726871341466904,
      -0.055406007915735245,
      0.12709203362464905,
      0.05175621807575226,
      0.03564365208148956,
      0.00510187353938818,
      -0.043953876942396164,
      -0.03181333467364311,
      0.03749522566795349,
      -0.009651454165577888,
      0.13980592787265778,
      0.013905330561101437,
      0.0016767376800999045,
      0.011983104981482029,
      -0.02066192217171192,
      0.03810427710413933,
      -0.010526631958782673,
      -0.026039058342576027,
      -0.04472137615084648,
      -0.002774648368358612,
      0.03250296041369438,
      -0.022783774882555008,
      0.004151138011366129,
      -0.023563314229249954,
      -0.0500674732029438,
      -0.02907358482480049,
      0.045290328562259674,
      -0.0014262445038184524,
      -0.020752936601638794,
      -0.023832662031054497,
      0.006150394212454557,
      0.001961589092388749,
      0.06103372201323509,
      -0.02030102163553238,
      -0.010406624525785446,
      0.02172606624662876,
      -0.013823255896568298,
      0.0391731783747673,
      0.036854181438684464,
      -0.033974990248680115,
      0.06777584552764893,
      0.1462293267250061,
      0.08027400821447372,
      0.012428437359631062,
      -0.0026207841001451015,
      0.03250737115740776,
      -0.027232768014073372,
      0.09302446246147156,
      -0.04937118664383888,
      -0.053931958973407745,
      -0.004102569539099932,
      0.06036736071109772,
      -0.000728030689060688,
      -0.031260062009096146,
      -0.0015834731748327613,
      -0.024764539673924446,
      0.013378719799220562,
      -0.007621032185852528,
      -0.03970332443714142,
      0.025130880996584892,
      -0.036853790283203125,
      -0.06539136171340942,
      -0.006269002798944712,
      0.0004941512015648186,
      0.07339644432067871,
      0.01937519758939743,
      0.0031557767651975155,
      -0.0035548910964280367,
      -0.016357852146029472,
      -0.05345650762319565,
      0.006018191110342741,
      0.026261121034622192,
      -0.03667522221803665,
      -0.017857059836387634,
      -0.02912238799035549,
      -0.015283380635082722,
      -0.03544728457927704,
      -0.008532175794243813,
      0.035470131784677505,
      -0.009450843557715416,
      -0.0069580585695803165,
      -0.02162112109363079,
      0.04184592887759209,
      0.06420313566923141,
      0.09402354806661606,
      -0.004674491938203573,
      -0.03760083019733429,
      -0.06427093595266342,
      -0.06553521752357483,
      -0.0094919977709651,
      -0.012098750099539757,
      -0.04500085860490799,
      -0.07133267819881439,
      0.008920212276279926,
      0.061982717365026474,
      0.0019310042262077332,
      -0.061128340661525726,
      0.08493489772081375,
      -0.0038842735812067986,
      -0.04048746079206467,
      -0.05675419420003891,
      -0.005152790807187557,
      0.030786141753196716,
      -0.02057160809636116,
      0.05795930325984955,
      0.026634125038981438,
      0.08786817640066147,
      0.047965340316295624,
      0.05183553323149681,
      -0.059096962213516235,
      0.0760912373661995,
      -0.002940706443041563,
      -0.006069357041269541,
      0.050822850316762924,
      0.05541557818651199,
      -0.03509300947189331,
      -0.024428725242614746,
      -0.03415755555033684,
      -0.01804065704345703,
      -0.039303503930568695,
      0.04399042949080467,
      -0.035680558532476425,
      -0.00024762647808529437,
      0.02489033155143261,
      -0.01066342368721962,
      -0.008532329462468624,
      0.015048516914248466,
      -0.0526069700717926,
      -0.04934243485331535,
      0.0036545780021697283,
      0.016352495178580284,
      -0.0070622144266963005,
      -0.03348523750901222,
      -0.03861850127577782,
      0.004435105249285698,
      0.01882323995232582,
      -0.037426311522722244,
      0.005927834659814835,
      0.00890276301652193,
      -0.03619970753788948,
      -0.012279664166271687,
      -0.03386891633272171,
      0.04515521228313446,
      -0.0038248267956078053,
      -0.04614456743001938,
      0.016990259289741516,
      0.01889350265264511,
      0.01401335559785366,
      -0.0031750702764838934,
      -0.028901340439915657,
      -0.048401735723018646,
      -0.037337567657232285,
      -0.04123133420944214,
      0.007370439823716879,
      0.03840341791510582,
      -0.02235228382050991,
      -0.05900835618376732,
      -0.04182025045156479,
      -0.06146007031202316,
      0.056556981056928635,
      -0.10436375439167023,
      -0.04422515630722046,
      0.08346102386713028,
      -0.02243446186184883,
      -0.022715261206030846,
      -0.04509415104985237,
      -0.013118571601808071,
      -0.07063104957342148,
      -0.04741286486387253,
      0.0727476179599762,
      0.005286798812448978,
      -0.04597988724708557,
      0.053325213491916656,
      0.03016330860555172,
      0.042627207934856415,
      0.015471884980797768,
      -0.05572953820228577,
      0.02344110980629921,
      0.008657606318593025,
      -0.00807995069772005,
      0.026844607666134834,
      -0.048829544335603714,
      -0.04371627792716026,
      -0.02608383260667324,
      0.01036890409886837,
      -0.0038807333912700415,
      0.062235139310359955,
      0.027519868686795235,
      0.06990295648574829,
      0.014813009649515152,
      0.02233617939054966,
      -0.01930895633995533,
      0.04942712560296059,
      -0.005369094666093588,
      0.02355186641216278,
      0.02273683249950409,
      0.04049457609653473,
      -0.081670381128788,
      0.02636556699872017,
      0.027568435296416283,
      -0.08585935086011887,
      -0.0717087984085083,
      -0.03042312152683735,
      0.007709322031587362,
      0.0877956971526146,
      -0.04436139389872551,
      -0.04185931384563446,
      -0.06513230502605438,
      -0.04608001559972763,
      0.05413731932640076,
      -0.0452975295484066,
      -0.02395663596689701,
      0.0036501523572951555,
      0.016010766848921776,
      -0.01460170652717352,
      0.0015633992152288556,
      0.011965123005211353,
      0.03225741907954216,
      -0.03324749320745468,
      0.05775698274374008,
      -0.021366946399211884,
      0.005058669485151768,
      -0.040874820202589035,
      -0.05775526538491249,
      0.02726726420223713,
      0.01496192254126072,
      0.05543231964111328,
      0.020330796018242836,
      -0.02373555488884449,
      -0.03241267800331116,
      -0.01196831651031971,
      0.015103292651474476,
      0.05099799484014511,
      -0.019564423710107803,
      0.01113147009164095,
      -0.0008180058212019503,
      0.006405997090041637,
      -0.0083662373945117,
      0.05531175434589386,
      -0.07755216211080551,
      0.056419577449560165,
      -0.024348361417651176,
      0.010828636586666107,
      -0.06888008117675781,
      0.014075400307774544,
      -0.06905072927474976,
      0.0814695730805397,
      -0.05691034346818924,
      0.10045595467090607,
      -0.008077784441411495,
      -0.036444734781980515,
      -0.05196348577737808,
      -0.0596037283539772,
      0.004467600956559181,
      0.03581484034657478,
      0.05179668590426445,
      0.027733055874705315,
      -0.008018620312213898,
      0.05143372341990471,
      0.0939587727189064,
      0.06707911938428879,
      -0.030110256746411324,
      -0.01834738627076149,
      0.09823764115571976,
      -0.048531852662563324,
      -0.06895110011100769,
      0.030947428196668625,
      -0.09610573947429657,
      -0.06830345094203949,
      0.06570180505514145,
      -0.01629510708153248,
      -0.008671049028635025,
      -0.016529899090528488,
      -0.03914005309343338,
      0.012376520782709122,
      0.054727669805288315,
      -0.014785133302211761,
      -0.028487375006079674,
      -0.04177699610590935,
      -0.01901235803961754,
      0.030773423612117767,
      -0.06614305824041367,
      -0.03068225458264351,
      -0.05752015858888626,
      0.07518362998962402,
      -0.011751290410757065,
      0.08946363627910614,
      -0.027006564661860466,
      0.07756175845861435,
      0.07760344445705414,
      0.01721942238509655,
      -0.05391848459839821,
      0.055782269686460495,
      0.022186093032360077,
      -0.03615653142333031,
      -0.01652677357196808,
      -0.015496027655899525,
      -0.03250537067651749,
      -0.02457931824028492,
      -0.05603635311126709,
      0.000743775861337781,
      0.04676711931824684,
      -0.06909433007240295,
      -0.051278699189424515,
      0.027561506256461143,
      0.00966523215174675,
      0.010172869078814983,
      0.007505964022129774,
      -0.04670654982328415,
      0.057839181274175644,
      0.013928755186498165,
      0.0048610372468829155,
      0.02821931429207325,
      -0.0560762882232666,
      -0.008601701818406582,
      -0.000957927608396858,
      0.040796782821416855
    ],
    [
      -0.016650892794132233,
      0.002709923777729273,
      0.04349600896239281,
      0.0090242400765419,
      -0.03799775242805481,
      0.020009031519293785,
      -0.04820079728960991,
      -0.0024882685393095016,
      0.0028116425964981318,
      0.057646047323942184,
      0.0005602228338830173,
      0.010825800709426403,
      0.010289421305060387,
      0.03896181285381317,
      -0.029491592198610306,
      0.009573069401085377,
      0.0019426775397732854,
      0.00043195756734348834,
      0.007064393721520901,
      0.0030465105082839727,
      -0.017168838530778885,
      -0.042889539152383804,
      0.04182866960763931,
      -0.012512974441051483,
      0.014780697412788868,
      0.01514597237110138,
      0.022843599319458008,
      0.01192013081163168,
      -0.010658296756446362,
      -0.034822624176740646,
      0.010276700370013714,
      0.03496064990758896,
      -0.05841926112771034,
      0.03305431082844734,
      0.028303973376750946,
      -0.08424282819032669,
      0.037440069019794464,
      0.01712190918624401,
      0.019585372880101204,
      -0.020612003281712532,
      -0.07446439564228058,
      0.09730815142393112,
      -0.04727952927350998,
      0.012735939584672451,
      -0.030270135030150414,
      0.01616165228188038,
      -0.03861735761165619,
      -0.03513842821121216,
      0.04046349599957466,
      0.07923708856105804,
      -0.04372395575046539,
      0.06975238025188446,
      -0.003692834172397852,
      0.1382853388786316,
      0.05648257955908775,
      0.007786340545862913,
      0.002091027796268463,
      -0.04652583226561546,
      -0.02819274552166462,
      0.0189890768378973,
      -0.03264516964554787,
      0.08016451448202133,
      -0.02450026571750641,
      -0.03782958164811134,
      -0.0008589904755353928,
      0.015598295256495476,
      0.02797667123377323,
      0.012556854635477066,
      -0.06318055838346481,
      -0.04837104305624962,
      0.026210060343146324,
      0.06569498032331467,
      -0.018723005428910255,
      0.04172658547759056,
      0.017423518002033234,
      -0.004513396415859461,
      -0.009964942000806332,
      -0.07866787165403366,
      0.057843372225761414,
      0.033762846142053604,
      0.041934236884117126,
      0.006582168862223625,
      0.0017794161103665829,
      0.0027027048636227846,
      -0.019666340202093124,
      -0.004912554286420345,
      -0.03456965833902359,
      -0.03268393129110336,
      -0.10803955048322678,
      -0.06103796139359474,
      0.012605397962033749,
      -0.04030440375208855,
      0.015941055491566658,
      -0.08745178580284119,
      -0.0006218774942681193,
      -0.06253661960363388,
      0.08310484886169434,
      -0.0013883165083825588,
      -0.028397560119628906,
      0.01682104356586933,
      0.06209592521190643,
      0.028322365134954453,
      -0.03496728837490082,
      -0.060108885169029236,
      -0.009883240796625614,
      0.0035443103406578302,
      0.013184333220124245,
      -0.004974365700036287,
      -0.03578471764922142,
      0.03867299482226372,
      -0.019140614196658134,
      0.03201417997479439,
      -0.007861632853746414,
      0.045315250754356384,
      -0.05717514827847481,
      -0.02805323339998722,
      0.07935795933008194,
      0.07696978002786636,
      -0.03191715478897095,
      0.013888237997889519,
      -0.05654590576887131,
      -0.04830615594983101,
      -0.02437465265393257,
      -0.019354432821273804,
      -0.0018994477577507496,
      0.010118252597749233,
      -0.08155030757188797,
      0.021273288875818253,
      0.04603109136223793,
      -0.013095063157379627,
      0.07203555107116699,
      -0.021283797919750214,
      -0.0782075822353363,
      0.05781443789601326,
      0.05366276949644089,
      -0.020417818799614906,
      -0.009391551837325096,
      0.00653665279969573,
      0.04315148666501045,
      -0.022190431132912636,
      0.0311810914427042,
      0.011579147540032864,
      -0.004761547781527042,
      0.027694089338183403,
      0.0171346552670002,
      0.016635112464427948,
      0.06519519537687302,
      -0.07089082896709442,
      0.047007959336042404,
      -0.009486839175224304,
      -0.09495682269334793,
      -0.00430723512545228,
      -0.06128980591893196,
      -0.0036387108266353607,
      -0.08916688710451126,
      0.05406405031681061,
      -0.12865768373012543,
      -0.03197380155324936,
      0.0069638327695429325,
      0.006073420867323875,
      0.02045517787337303,
      -0.021633170545101166,
      0.046761613339185715,
      0.030967701226472855,
      0.056313421577215195,
      0.02276322804391384,
      -0.02987665683031082,
      0.04751306772232056,
      0.025149226188659668,
      -0.011177991516888142,
      0.010641790926456451,
      -0.040410686284303665,
      -0.034638457000255585,
      0.050991855561733246,
      0.09215409308671951,
      -0.05564413592219353,
      0.03073696792125702,
      -0.06847620755434036,
      -0.00951249711215496,
      0.06068156287074089,
      0.0347653292119503,
      -0.04485223442316055,
      -0.06006826087832451,
      -0.028310401365160942,
      -0.025515733286738396,
      -0.033748283982276917,
      0.006738082971423864,
      0.029538044705986977,
      -0.07870472967624664,
      0.014535498805344105,
      -0.0016201251419261098,
      0.03693624213337898,
      -0.0415872223675251,
      0.0011075760703533888,
      0.0346301905810833,
      0.047762949019670486,
      0.0044871605932712555,
      0.027720129117369652,
      0.11903993040323257,
      0.05717034637928009,
      -0.04620581492781639,
      -0.01278571505099535,
      0.003246370004490018,
      -0.054553475230932236,
      -0.014933325350284576,
      -0.034587956964969635,
      -0.0017975326627492905,
      -0.0035819935146719217,
      0.004943229258060455,
      0.00621928321197629,
      0.012231576256453991,
      0.053634267300367355,
      0.041632458567619324,
      -0.014353577978909016,
      0.010940022766590118,
      0.018152575939893723,
      0.011657288298010826,
      0.027616336941719055,
      -0.05974549055099487,
      -0.052206944674253464,
      -0.02769443579018116,
      0.009551403112709522,
      -0.07161612808704376,
      0.08283820003271103,
      -0.011569260619580746,
      0.03053918667137623,
      -0.016973985359072685,
      0.1144348680973053,
      0.03430473804473877,
      -0.020843656733632088,
      -0.025457127019762993,
      0.004802096635103226,
      -0.10079149156808853,
      -0.04482473433017731,
      0.010794330388307571,
      0.04446399584412575,
      0.0006574394064955413,
      -0.062327876687049866,
      -0.006906577851623297,
      -0.11495993286371231,
      -0.07151422649621964,
      0.012975000776350498,
      -0.0025691164191812277,
      -0.001245263498276472,
      -0.05463962256908417,
      -0.08453238755464554,
      0.10788410902023315,
      0.13387958705425262,
      0.002700264798477292,
      -0.02578294277191162,
      -0.015122920274734497,
      0.021584108471870422,
      0.03293122351169586,
      0.012521438300609589,
      -0.04349272698163986,
      0.07461199164390564,
      0.00015965389320626855,
      0.0671720877289772,
      0.04696698114275932,
      -0.02389594353735447,
      0.029056133702397346,
      -0.01846921443939209,
      0.021346941590309143,
      0.05887540057301521,
      -0.020171552896499634,
      0.05561814084649086,
      -0.02250957116484642,
      -0.015733081847429276,
      0.07142649590969086,
      0.0474952757358551,
      0.10024965554475784,
      0.06300030648708344,
      0.057569414377212524,
      -0.05951333045959473,
      -0.014453111216425896,
      0.014692997559905052,
      0.026926403865218163,
      0.034773584455251694,
      0.02505166456103325,
      -0.023115821182727814,
      -0.04336519166827202,
      0.007334182038903236,
      0.00474926782771945,
      0.031005823984742165,
      -0.013000803999602795,
      0.005526443012058735,
      0.04915224015712738,
      -0.006521281320601702,
      0.0034721328411251307,
      0.04409606382250786,
      0.008869116194546223,
      0.03956364467740059,
      0.060616083443164825,
      -0.03593573719263077,
      -0.010444455780088902,
      -0.030690837651491165,
      0.03173867613077164,
      -0.04256724938750267,
      -0.04319298639893532,
      -0.008381519466638565,
      0.006471697241067886,
      0.01176063809543848,
      -0.028527332469820976,
      -0.013636397197842598,
      -0.014099089428782463,
      -0.021403800696134567,
      0.020446304231882095,
      -0.06418435275554657,
      0.11609098315238953,
      -0.04114431142807007,
      -0.05169828608632088,
      0.008251560851931572,
      -0.08522885292768478,
      0.05310945212841034,
      -0.06939604878425598,
      -0.05196306109428406,
      -0.0584854893386364,
      -0.002193605061620474,
      -0.04986155778169632,
      -0.07499051839113235,
      0.05001191049814224,
      0.04911535978317261,
      0.0014720081817358732,
      -0.03926791250705719,
      -0.006975337862968445,
      0.021171661093831062,
      0.059373702853918076,
      -0.023117177188396454,
      0.029434507712721825,
      0.023579776287078857,
      0.018915710970759392,
      -0.020014597102999687,
      -0.02119957096874714,
      0.01165809202939272,
      0.026807915419340134,
      0.020512912422418594,
      0.0012459527933970094,
      0.05470476299524307,
      0.028637727722525597,
      -0.06756916642189026,
      -0.11188539862632751,
      0.014490670524537563,
      -0.008296798914670944,
      0.025009680539369583,
      0.04575160890817642,
      0.05980459600687027,
      -0.11188848316669464,
      0.014061269350349903,
      0.018468119204044342,
      -0.1190294399857521,
      0.010272353887557983,
      -0.005566091742366552,
      0.06564299017190933,
      0.01025522779673338,
      0.041829973459243774,
      -0.008393237367272377,
      -0.024775736033916473,
      0.018072444945573807,
      -0.04319939389824867,
      -0.053536251187324524,
      -0.008739345706999302,
      0.021600117906928062,
      0.01003025658428669,
      0.041287973523139954,
      0.021088982000947,
      -0.03360404819250107,
      0.10500247776508331,
      0.04572343826293945,
      -0.01016939990222454,
      0.022758975625038147,
      0.007394793909043074,
      -0.022540980949997902,
      -0.10564910620450974,
      -0.030457835644483566,
      0.06229047104716301,
      0.018072813749313354,
      -0.08516471087932587,
      0.027202585712075233,
      -0.020530151203274727,
      0.06398039311170578,
      -0.013679287396371365,
      -0.0012993310810998082,
      -0.004099604208022356,
      0.03647397831082344,
      -0.13678604364395142,
      0.04114809259772301,
      -0.03065418265759945,
      0.010898963548243046,
      0.000493014813400805,
      0.02029428258538246,
      -0.013269214890897274,
      0.03407720848917961,
      0.0035023773089051247,
      0.023384058848023415,
      -0.013219211250543594,
      -0.038305919617414474,
      0.021567771211266518,
      0.07147885113954544,
      0.0469476692378521,
      0.022945841774344444,
      0.058433156460523605,
      0.015515346080064774,
      0.044769205152988434,
      0.00977166835218668,
      -0.025868481025099754,
      -0.04997286945581436,
      0.038309626281261444,
      0.003976436797529459,
      0.030801832675933838,
      0.03596998751163483,
      0.013676212169229984,
      -0.10388010740280151,
      0.03545209765434265,
      0.029197582975029945,
      -0.005286330822855234,
      0.042184241116046906,
      0.017026664689183235,
      0.01354661863297224,
      0.002787444507703185,
      0.062378790229558945,
      0.06153086945414543,
      0.02800391986966133,
      0.04696868732571602,
      -0.05878395587205887,
      0.044432662427425385,
      -0.043269574642181396,
      -0.06982826441526413,
      -0.05972754955291748,
      0.024723263457417488,
      0.05420518293976784,
      -0.07692143321037292,
      -0.02787911146879196,
      -0.0057156141847372055,
      -0.04833141714334488,
      0.02984158881008625,
      0.0196018535643816,
      -0.008038963191211224,
      0.06931068748235703,
      0.03252429887652397,
      -0.029391124844551086,
      -0.05095948651432991,
      0.019190924242138863,
      -0.0023390166461467743,
      0.046434130519628525,
      -0.02690325677394867,
      -0.01656605862081051,
      -0.002573483157902956,
      -0.010743936523795128,
      -0.043478019535541534,
      0.020067712292075157,
      0.02697775885462761,
      0.06130765751004219,
      -0.010764902457594872,
      0.06426074355840683,
      0.019109975546598434,
      -0.03528032824397087,
      -0.033817827701568604,
      -0.010230151936411858,
      0.0139542231336236,
      -0.03792286291718483,
      0.013404307886958122,
      -0.02923591621220112,
      0.03165128082036972,
      -0.007301938720047474,
      -0.02336188219487667,
      -0.005654060747474432,
      -0.10291697829961777,
      -0.02996070496737957,
      0.015737680718302727,
      0.005018359515815973,
      -0.03945934772491455,
      0.048034217208623886,
      0.04183425009250641,
      -0.007957382127642632,
      -0.08913812041282654,
      -0.07028122991323471,
      -0.02450760267674923,
      -0.043201275169849396,
      0.08950359374284744,
      0.00816652923822403,
      0.03461172804236412,
      -0.08549397438764572,
      0.07490365207195282,
      0.04779075086116791,
      0.05486680194735527,
      0.04770013689994812,
      0.048948757350444794,
      0.018679222092032433,
      -0.06029873713850975,
      -0.0007461789646185935,
      -0.006734117865562439,
      0.07638699561357498,
      -0.045489776879549026,
      0.06793990731239319,
      0.06616505235433578,
      0.059462808072566986,
      0.04334371164441109,
      0.07544341683387756,
      0.03731144219636917,
      0.021241826936602592,
      0.04237045720219612,
      0.025023287162184715,
      0.02197215147316456
    ],
    [
      0.014682463370263577,
      -0.03381785377860069,
      -0.028568200767040253,
      -0.055729206651449203,
      -0.011999477632343769,
      -0.027402400970458984,
      -0.011942295357584953,
      0.045570045709609985,
      0.018922263756394386,
      0.012403230182826519,
      -0.05085493251681328,
      -0.024318290874361992,
      0.011622264981269836,
      0.028268465772271156,
      0.052615392953157425,
      -0.02690226398408413,
      0.03672884404659271,
      0.0496692918241024,
      0.05896812304854393,
      -0.11991646140813828,
      -0.03346889466047287,
      0.08357973396778107,
      0.0315471850335598,
      0.029881224036216736,
      0.04826465994119644,
      0.005786022637039423,
      0.011758645065128803,
      -0.07447744905948639,
      -0.029547136276960373,
      -0.011040537618100643,
      0.00835825689136982,
      0.045156896114349365,
      0.018975678831338882,
      -0.03161028027534485,
      -0.03414198383688927,
      -0.07788412272930145,
      -0.06644921749830246,
      0.03829062357544899,
      0.024026423692703247,
      0.07649372518062592,
      0.004607464652508497,
      0.011387406848371029,
      0.02113303169608116,
      -0.05577133223414421,
      0.024744341149926186,
      0.06238574534654617,
      -0.060415349900722504,
      0.013331371359527111,
      -0.007448198739439249,
      0.046055033802986145,
      -0.059924252331256866,
      0.013669457286596298,
      -0.006976647302508354,
      -0.05780816450715065,
      -0.01599690318107605,
      -0.006300796288996935,
      0.11156051605939865,
      -0.07959295064210892,
      -0.05106819048523903,
      -0.053004808723926544,
      -0.027838343754410744,
      0.06258225440979004,
      0.0295447688549757,
      -0.016966959461569786,
      0.02102242223918438,
      0.049618251621723175,
      0.13931512832641602,
      0.0580991692841053,
      0.011509932577610016,
      -0.06378669291734695,
      0.01746402680873871,
      -0.045704226940870285,
      -0.008810249157249928,
      -0.0511041022837162,
      0.008820045739412308,
      0.046849507838487625,
      -0.05616132542490959,
      0.06946288049221039,
      0.019424360245466232,
      -0.0225713811814785,
      0.029778363183140755,
      0.01163381990045309,
      -0.05803240090608597,
      -0.07821550965309143,
      0.0783572718501091,
      -0.03662590682506561,
      0.040380511432886124,
      0.03903893381357193,
      -0.05709551274776459,
      -0.030104681849479675,
      0.04752649366855621,
      -0.03157925605773926,
      -0.07758928090333939,
      0.07337060570716858,
      -0.02218819409608841,
      -0.021410277113318443,
      -0.03289247676730156,
      0.037330012768507004,
      -0.02648407407104969,
      -0.08783529698848724,
      0.04962621629238129,
      0.01626802235841751,
      -0.012725169770419598,
      -0.02733018808066845,
      -0.013618657365441322,
      -0.031508028507232666,
      0.027810310944914818,
      -0.021645862609148026,
      -0.0034591685980558395,
      -0.008500343188643456,
      0.033959973603487015,
      -0.025414973497390747,
      -0.026779931038618088,
      0.05725220590829849,
      -0.05255425348877907,
      -0.005778634920716286,
      0.005436639301478863,
      0.010962409898638725,
      0.03383217751979828,
      0.011678898707032204,
      -0.11424519121646881,
      -0.005470560863614082,
      0.017806358635425568,
      0.0342162549495697,
      0.0380680225789547,
      -0.028212113305926323,
      0.073680579662323,
      0.020800158381462097,
      0.06041086092591286,
      -0.06793668121099472,
      0.0152230029925704,
      0.024995693936944008,
      0.009777179919183254,
      0.09498969465494156,
      -0.0182187557220459,
      -0.045945268124341965,
      -0.07573268562555313,
      -0.016577234491705894,
      0.05412765219807625,
      0.03523680195212364,
      0.012743755243718624,
      0.005416586995124817,
      0.0008739704499021173,
      -0.019750135019421577,
      0.09896299988031387,
      0.0035193052608519793,
      0.09883624315261841,
      -0.05489019304513931,
      -0.0007773929391987622,
      -0.0031960627529770136,
      0.05766458436846733,
      -0.015311974100768566,
      -0.029391076415777206,
      -0.11459270864725113,
      0.0048115430399775505,
      -0.01100868545472622,
      0.006130064371973276,
      0.04658520221710205,
      0.05119199678301811,
      0.10433349013328552,
      0.0501127615571022,
      0.06473797559738159,
      -0.062026772648096085,
      -0.0076555535197257996,
      -0.018237745389342308,
      -0.038759615272283554,
      -0.04664952680468559,
      0.001985143171623349,
      -0.06342171132564545,
      0.05104836821556091,
      0.028772983700037003,
      -0.034044377505779266,
      0.04742277413606644,
      -0.04389702156186104,
      0.03252987563610077,
      0.057736217975616455,
      -0.05762166529893875,
      -0.029726842418313026,
      0.006901654414832592,
      -0.004787852056324482,
      0.07885300368070602,
      0.019387472420930862,
      -0.07647556066513062,
      -0.0075859748758375645,
      0.019152456894516945,
      -0.014929493889212608,
      0.012713826261460781,
      0.03009548969566822,
      0.013199103064835072,
      -0.008571278303861618,
      -0.0444982536137104,
      0.02996588498353958,
      -0.034593723714351654,
      -0.07515305280685425,
      0.0681566670536995,
      0.06422597914934158,
      0.02293279953300953,
      -0.04215879738330841,
      0.0012570269173011184,
      0.03153460845351219,
      -0.048025164753198624,
      0.076469786465168,
      0.0746266171336174,
      0.006169383879750967,
      0.04579072818160057,
      -0.045064713805913925,
      -0.002723526209592819,
      -0.010199653916060925,
      -0.030171601101756096,
      0.06322933733463287,
      0.02609626203775406,
      0.06867679208517075,
      0.08737984299659729,
      0.03062357008457184,
      0.08827285468578339,
      -0.00464885588735342,
      -0.04073816165328026,
      0.04354765638709068,
      0.04456624761223793,
      -0.08293861895799637,
      -0.06883297115564346,
      0.02999536320567131,
      0.059966735541820526,
      -0.01167210191488266,
      0.0468597449362278,
      -0.044726017862558365,
      -0.040361229330301285,
      0.01958371512591839,
      0.006509355269372463,
      -0.08156197518110275,
      0.030766256153583527,
      -0.1303384155035019,
      -0.0032561086118221283,
      -0.033801499754190445,
      0.019221194088459015,
      -0.01980375312268734,
      -0.05538255721330643,
      -0.009376361966133118,
      0.037477053701877594,
      0.05185220018029213,
      -0.08023526519536972,
      0.01830201968550682,
      0.04255584999918938,
      -0.010934564284980297,
      0.06934375315904617,
      -0.03653930872678757,
      -0.0312277190387249,
      0.0650080144405365,
      -0.00667784595862031,
      -0.02161741442978382,
      0.07466066628694534,
      0.0372600331902504,
      -0.07657288014888763,
      0.03997628018260002,
      0.058364637196063995,
      0.08653213828802109,
      -0.027330586686730385,
      0.003731420962139964,
      -0.05821330472826958,
      -0.040412891656160355,
      0.02175472490489483,
      -0.10976601392030716,
      -0.038567978888750076,
      -0.02448914386332035,
      0.02822149731218815,
      0.03127183020114899,
      0.05999214947223663,
      0.00290597602725029,
      -0.010728783905506134,
      -0.016397224739193916,
      0.02120511420071125,
      0.026811156421899796,
      -0.05112403631210327,
      -0.03399547189474106,
      0.03818138688802719,
      -0.04688899591565132,
      0.03966266289353371,
      0.05478023737668991,
      0.005152034107595682,
      -0.09353289753198624,
      -0.01741771027445793,
      0.009394029155373573,
      0.0031694932840764523,
      -0.07846075296401978,
      0.050697796046733856,
      -0.0242520272731781,
      -0.01599598303437233,
      0.05379027500748634,
      0.056043531745672226,
      -0.007328299339860678,
      0.039609652012586594,
      -0.04554414376616478,
      -0.029161294922232628,
      -0.02040400356054306,
      -0.06145751476287842,
      0.05251232534646988,
      -0.0704289898276329,
      -0.056874215602874756,
      0.05447091907262802,
      -0.10295484960079193,
      0.05375705286860466,
      -0.04986216872930527,
      -0.06302075833082199,
      0.021052200347185135,
      0.001771531067788601,
      -0.08265860378742218,
      0.0034027323126792908,
      0.04845676198601723,
      0.05212276801466942,
      0.054221637547016144,
      0.07985907047986984,
      0.06200813502073288,
      0.029829125851392746,
      -0.0023770607076585293,
      -0.05907431244850159,
      0.004632447846233845,
      0.003951858729124069,
      -0.09874944388866425,
      -0.011953909881412983,
      0.02319496124982834,
      0.019406598061323166,
      0.04165242612361908,
      0.060702625662088394,
      -0.014220385812222958,
      -0.02919408120214939,
      0.07365459948778152,
      -0.03467534855008125,
      -0.017475100234150887,
      0.02325398474931717,
      -0.14657510817050934,
      -0.041794102638959885,
      0.05980615317821503,
      -0.036017078906297684,
      0.08968580514192581,
      0.07995442301034927,
      -0.015364432707428932,
      -0.0046978965401649475,
      -0.0539918951690197,
      0.01862129382789135,
      -0.02425134740769863,
      -0.05759865418076515,
      -0.02982478216290474,
      0.027242282405495644,
      -0.07358314096927643,
      0.0059837764129042625,
      0.025744955986738205,
      0.10302094370126724,
      -0.057910095900297165,
      -0.007546239998191595,
      0.052919697016477585,
      -0.024396833032369614,
      0.03738519921898842,
      -0.010963808745145798,
      0.036307718604803085,
      0.06521755456924438,
      0.024673910811543465,
      0.012622063979506493,
      0.03747210651636124,
      0.019889846444129944,
      0.03627888113260269,
      0.01703745312988758,
      0.02308352291584015,
      0.03358012065291405,
      0.03835861384868622,
      0.05633231997489929,
      0.006808712147176266,
      -0.03237684443593025,
      -0.04878054931759834,
      0.07802104204893112,
      0.002213374013081193,
      -0.024436408653855324,
      -0.00387157523073256,
      0.05867808312177658,
      -0.03738049045205116,
      -0.02114942856132984,
      0.03463377803564072,
      0.01634233072400093,
      -0.021614743396639824,
      -0.016746768727898598,
      0.005588242784142494,
      0.045535940676927567,
      -0.030678115785121918,
      -0.04593286290764809,
      -0.047440484166145325,
      0.019422786310315132,
      0.05576324835419655,
      0.037126023322343826,
      -0.09789617359638214,
      0.04778421297669411,
      -0.043235745280981064,
      0.05874115973711014,
      -0.03434982895851135,
      0.02465732954442501,
      -0.020595194771885872,
      0.04745177924633026,
      0.07946529984474182,
      0.06046698987483978,
      -0.049253325909376144,
      -0.04581986740231514,
      0.06245185062289238,
      0.032540153712034225,
      -0.02530091069638729,
      -0.06053447350859642,
      -0.05750855430960655,
      -0.07164143770933151,
      0.026246696710586548,
      -0.03837990760803223,
      -0.017280608415603638,
      -0.06080232933163643,
      -0.005898912902921438,
      0.05105991289019585,
      0.0298270583152771,
      0.03042943961918354,
      -0.03022041730582714,
      0.02725910022854805,
      0.034310318529605865,
      0.07180138677358627,
      0.040257710963487625,
      0.036352355033159256,
      -0.0058391583152115345,
      -0.01096042525023222,
      -0.008739765733480453,
      -0.04255153611302376,
      0.034212496131658554,
      0.09437403827905655,
      -0.00040453270776197314,
      0.026239020749926567,
      -0.030324624851346016,
      -0.05515590310096741,
      0.010937139391899109,
      0.013449556194245815,
      0.0853772759437561,
      -0.03105875290930271,
      0.015768174082040787,
      0.03969192132353783,
      -0.0014407248236238956,
      0.0664973184466362,
      -0.07333537936210632,
      -0.006124497391283512,
      -0.07639294862747192,
      -0.07161229103803635,
      0.02213173173367977,
      -0.0323866568505764,
      -0.06252163648605347,
      0.0015453154919669032,
      -0.0928046777844429,
      0.06088947132229805,
      -0.010041115805506706,
      0.037863776087760925,
      -0.04506901651620865,
      0.06679175794124603,
      0.048696912825107574,
      -0.07448979467153549,
      0.1064707562327385,
      -0.032448410987854004,
      0.06061730161309242,
      -0.008634756319224834,
      0.0441669300198555,
      0.009442138485610485,
      -0.003412837628275156,
      -0.030357666313648224,
      -0.008758492767810822,
      0.07037100195884705,
      0.04864954203367233,
      -0.016118768602609634,
      0.04336295276880264,
      0.017355993390083313,
      -0.027509165927767754,
      0.029793154448270798,
      -0.05549195781350136,
      -0.050692155957221985,
      0.025989362969994545,
      -0.009806309826672077,
      -0.0707780197262764,
      0.019474007189273834,
      -0.08752064406871796,
      0.06123180314898491,
      0.00014995197125244886,
      -0.016380587592720985,
      -0.003923279698938131,
      -0.020251700654625893,
      -0.119020015001297,
      -0.0012326029827818274,
      -0.03160661458969116,
      0.052970610558986664,
      -0.030556129291653633,
      0.05455965921282768,
      -0.07123137265443802,
      0.03606820106506348,
      0.06331916898488998,
      -0.0029312148690223694,
      0.009787132032215595,
      -0.03582826629281044,
      -0.0185700636357069,
      0.011401825584471226,
      0.008154291659593582,
      0.02207176759839058,
      0.024685101583600044,
      -0.02932949922978878,
      -0.03195277228951454,
      -0.04884122312068939,
      0.04099825397133827,
      -0.0036876958329230547
    ],
    [
      -0.00417492026463151,
      0.02220366895198822,
      0.028583403676748276,
      -0.01933017186820507,
      -0.03757234290242195,
      -0.0503448024392128,
      -0.0035993806086480618,
      0.029360448941588402,
      -0.006814998108893633,
      0.01792265847325325,
      0.062025364488363266,
      -0.007952232845127583,
      -0.027136139571666718,
      0.022617999464273453,
      -0.039227914065122604,
      0.028695257380604744,
      -0.04106806218624115,
      0.11229267716407776,
      -0.018738651648163795,
      -0.016987502574920654,
      0.04150160402059555,
      0.09142643213272095,
      0.0469689667224884,
      -0.048371415585279465,
      0.001717086648568511,
      -0.00472504086792469,
      -0.005737628322094679,
      -0.004795536398887634,
      -0.0009428513003513217,
      -0.029810477048158646,
      -0.019980933517217636,
      -0.04673287272453308,
      0.02835042215883732,
      -0.004863586276769638,
      -0.011814874596893787,
      -0.026232365518808365,
      -0.04931781068444252,
      -0.040599189698696136,
      0.05553711950778961,
      0.02026606909930706,
      0.10018106549978256,
      0.019393088296055794,
      0.01739470846951008,
      -0.03365553170442581,
      0.011544608511030674,
      -0.030936356633901596,
      -0.08548854291439056,
      0.02979467436671257,
      0.013761583715677261,
      0.030328402295708656,
      -0.048226386308670044,
      -0.043292153626680374,
      0.0032729278318583965,
      0.031104492023587227,
      -0.08144399523735046,
      0.01185616385191679,
      0.03814593702554703,
      -0.06676333397626877,
      -0.015223560854792595,
      -0.023497357964515686,
      -0.02021283283829689,
      0.03562377020716667,
      0.009505894966423512,
      -0.051691919565200806,
      0.027455050498247147,
      0.03014601208269596,
      0.011798709630966187,
      -0.03824000805616379,
      -0.011378435418009758,
      0.11199931055307388,
      -0.0404990091919899,
      0.029062191024422646,
      0.02008584514260292,
      -0.041533634066581726,
      0.05389755591750145,
      0.030461471527814865,
      -0.03533871844410896,
      0.008449366316199303,
      -0.05312149226665497,
      0.029976310208439827,
      -0.005652701947838068,
      0.0024113086983561516,
      0.06052885204553604,
      -0.04446912556886673,
      -0.057360682636499405,
      0.10471055656671524,
      0.014174224808812141,
      -0.0050834170542657375,
      -0.08012378960847855,
      -0.019789723679423332,
      -0.014354116283357143,
      -0.02415994182229042,
      0.03326664865016937,
      -0.010287226177752018,
      0.01720810867846012,
      0.017330173403024673,
      -0.039064597338438034,
      -0.03929900377988815,
      -0.015912145376205444,
      -0.06627530604600906,
      -0.05547599494457245,
      0.036129046231508255,
      0.0030394336208701134,
      -0.08115097135305405,
      0.02652708627283573,
      -0.058455392718315125,
      0.10007070749998093,
      -0.05929834768176079,
      -0.01784455217421055,
      0.006927483715116978,
      -0.02796153724193573,
      0.05458289012312889,
      -0.03929433599114418,
      -0.04571210592985153,
      0.01275443285703659,
      -0.03541160374879837,
      -0.002778874710202217,
      0.05855577439069748,
      -0.02423214539885521,
      -0.07562823593616486,
      -0.045276056975126266,
      -0.03451143577694893,
      -0.001037508132867515,
      0.02515716664493084,
      0.04209486395120621,
      0.07635479420423508,
      0.02584758587181568,
      -0.02637491002678871,
      0.03398009017109871,
      -0.0005586984334513545,
      0.08024457097053528,
      0.06486357003450394,
      0.04297400638461113,
      0.038053665310144424,
      0.0339059941470623,
      -0.016645023599267006,
      0.08238861709833145,
      -0.0024992984253913164,
      0.011421018280088902,
      -0.012097395025193691,
      0.008550798520445824,
      0.06931791454553604,
      -0.0014896311331540346,
      -0.07575341314077377,
      -0.059169333428144455,
      -0.0471419021487236,
      -0.04523937404155731,
      -0.022862451151013374,
      -0.018212707713246346,
      0.027102285996079445,
      0.029021600261330605,
      0.033286046236753464,
      0.05026642605662346,
      0.030832510441541672,
      0.05502217635512352,
      -0.04721711575984955,
      0.06006117910146713,
      -0.01590675115585327,
      0.02463436685502529,
      0.05221409350633621,
      0.01155430730432272,
      0.05604487285017967,
      -0.033751100301742554,
      0.0015520862070843577,
      0.007735374383628368,
      -0.047347601503133774,
      -0.023674705997109413,
      0.03773673623800278,
      0.03555990010499954,
      0.05568688362836838,
      -0.038334958255290985,
      -0.02349071390926838,
      -0.031673677265644073,
      -0.1152992770075798,
      -0.03789107874035835,
      0.06566865742206573,
      0.019825704395771027,
      0.023309238255023956,
      0.08822760730981827,
      0.015174253843724728,
      -0.09469151496887207,
      -0.0007938777562230825,
      -0.05664938688278198,
      -0.00944712944328785,
      0.006116852629929781,
      -0.029475513845682144,
      -0.00020640909497160465,
      0.02280612103641033,
      -0.04886329174041748,
      0.0254890825599432,
      0.005726877134293318,
      0.0020030736923217773,
      0.03228268027305603,
      -0.01135170552879572,
      -0.030971601605415344,
      0.026963839307427406,
      -0.012912035919725895,
      0.06744834780693054,
      0.009648716077208519,
      0.04284605383872986,
      0.02878686785697937,
      -0.02931416593492031,
      0.011575859971344471,
      -0.058916013687849045,
      0.0009981339098885655,
      -0.034910328686237335,
      0.09239380806684494,
      -0.028830712661147118,
      0.049146201461553574,
      0.015693185850977898,
      -0.057497721165418625,
      0.043039146810770035,
      -0.04573315009474754,
      -0.07386470586061478,
      -0.024616869166493416,
      -0.01965576969087124,
      0.001430978300049901,
      -0.044620536267757416,
      0.01784287951886654,
      -0.0489283986389637,
      -0.07856085151433945,
      0.04419358819723129,
      0.0008529162150807679,
      -0.012943409383296967,
      -0.08497952669858932,
      0.0046080900356173515,
      -0.09625471383333206,
      0.0400199331343174,
      -0.022391140460968018,
      -0.04592498764395714,
      -0.043828077614307404,
      0.014812825247645378,
      0.02740846388041973,
      -0.031150393187999725,
      -0.0278606154024601,
      0.09309554100036621,
      -0.025867702439427376,
      0.07734441012144089,
      0.047905370593070984,
      0.023110685870051384,
      0.021213721483945847,
      0.06654568761587143,
      -0.004946877248585224,
      -0.03728984296321869,
      0.011242507956922054,
      0.011316712014377117,
      0.055164601653814316,
      0.028195049613714218,
      -0.013779675588011742,
      0.0032207549083977938,
      0.006433160975575447,
      0.03931424766778946,
      0.007432811893522739,
      -0.02406589686870575,
      0.045529354363679886,
      0.001700954744592309,
      -0.004292889963835478,
      -0.03552497178316116,
      0.004612027667462826,
      0.01310400664806366,
      0.0067192623391747475,
      -0.001537046511657536,
      0.025812458246946335,
      0.04088665917515755,
      -0.033783916383981705,
      0.006719858385622501,
      0.02760314755141735,
      0.012215940281748772,
      0.00823830533772707,
      -0.05174882709980011,
      -0.03365761414170265,
      0.11356395483016968,
      0.02858332172036171,
      0.03879662975668907,
      0.009374507702887058,
      0.02467435412108898,
      -0.08254054188728333,
      0.03208756446838379,
      -0.07943353056907654,
      0.034995656460523605,
      -0.030448654666543007,
      -0.012492943555116653,
      -0.04152084141969681,
      0.029479848220944405,
      0.03341921046376228,
      0.016577202826738358,
      0.005769426003098488,
      0.006585470400750637,
      0.0238967128098011,
      0.032410942018032074,
      0.05909767001867294,
      -0.009363089688122272,
      -0.03850952163338661,
      -0.059129804372787476,
      -0.03458435460925102,
      0.034673694521188736,
      0.04153472185134888,
      -0.03508814796805382,
      -0.012011390179395676,
      -0.018884707242250443,
      0.02511005662381649,
      -0.04782944545149803,
      0.07477711141109467,
      -0.0094691077247262,
      -0.02605319395661354,
      -0.04518325999379158,
      -0.03744816035032272,
      0.044276997447013855,
      -0.055294740945100784,
      -0.0077421944588422775,
      -0.03525140509009361,
      -0.018986308947205544,
      0.05053863674402237,
      -0.07286658138036728,
      -0.04131808876991272,
      -0.030691083520650864,
      0.08982154726982117,
      -0.00960285309702158,
      -0.06704352051019669,
      -0.01954021118581295,
      0.011503255926072598,
      0.006865745410323143,
      0.003489668248221278,
      -0.03028016723692417,
      -0.02412055991590023,
      -0.0399002730846405,
      -0.014735657721757889,
      0.09744307398796082,
      -0.00413034250959754,
      0.07036173343658447,
      0.0262132678180933,
      -0.02570674754679203,
      -0.08007638156414032,
      0.0026601948775351048,
      0.08393624424934387,
      0.06710700690746307,
      0.038257427513599396,
      -0.03226293995976448,
      -0.004448026418685913,
      0.09105832129716873,
      0.011728595942258835,
      0.06808489561080933,
      0.015527155250310898,
      0.020888909697532654,
      -0.0026204660534858704,
      -0.025030843913555145,
      -0.02232646383345127,
      -0.006802307907491922,
      0.038289811462163925,
      0.0821465477347374,
      0.013884437270462513,
      -0.027777379378676414,
      0.0360865518450737,
      0.04683778062462807,
      0.022784454748034477,
      0.003383500501513481,
      -0.019101999700069427,
      0.022972671315073967,
      0.020664343610405922,
      0.059703338891267776,
      -0.06455133110284805,
      0.029342791065573692,
      0.03315320983529091,
      0.08881434053182602,
      0.06523282080888748,
      0.0008235072600655258,
      0.03281337767839432,
      0.0428721159696579,
      0.014230790548026562,
      -0.03514142706990242,
      -0.038965530693531036,
      0.01092186663299799,
      0.013449665158987045,
      -0.024686800315976143,
      0.06986533850431442,
      0.008680956438183784,
      0.06056215241551399,
      0.043745849281549454,
      -0.020696863532066345,
      0.013220541179180145,
      0.04107913747429848,
      -0.05195481330156326,
      -0.0657157227396965,
      0.006788856349885464,
      -0.059425484389066696,
      -0.06513373553752899,
      -0.09697683155536652,
      0.047340523451566696,
      -0.02770410291850567,
      0.019097890704870224,
      -0.049719151109457016,
      0.0013732503866776824,
      0.046606648713350296,
      0.01504547894001007,
      -0.0016662654234096408,
      -0.00848366878926754,
      0.0033782492391765118,
      0.019523190334439278,
      0.13854992389678955,
      -0.044638194143772125,
      0.04066033661365509,
      0.03622797504067421,
      0.040555644780397415,
      0.06105877831578255,
      0.02308727614581585,
      -0.0638687014579773,
      0.03235652670264244,
      0.054819732904434204,
      -0.044341541826725006,
      0.021926766261458397,
      -0.038660336285829544,
      -0.09260303527116776,
      -0.03477839007973671,
      -0.04278043657541275,
      0.009693626314401627,
      -0.1258193552494049,
      -0.05036228150129318,
      0.05682273209095001,
      -0.0017440648516640067,
      -0.03824271634221077,
      -0.04513871669769287,
      0.0002104466111632064,
      -0.07383877038955688,
      0.010639631189405918,
      -0.040559642016887665,
      -0.01129065826535225,
      -0.03969184309244156,
      -0.07984326779842377,
      -0.03856414183974266,
      0.02635229006409645,
      -0.021506285294890404,
      -0.026999792084097862,
      0.06479272991418839,
      0.003154309466481209,
      0.008880513720214367,
      -0.016183383762836456,
      0.04961984604597092,
      -0.050189629197120667,
      -0.0900067389011383,
      -0.04574330896139145,
      -0.028867559507489204,
      0.032348647713661194,
      0.019915027543902397,
      -0.012687409296631813,
      -0.04113065078854561,
      -0.03291601687669754,
      0.048190031200647354,
      0.0409407913684845,
      -0.021466180682182312,
      0.030243566259741783,
      -0.02833135426044464,
      -0.08407066017389297,
      0.04712823033332825,
      0.0010273472871631384,
      0.031878843903541565,
      0.016339341178536415,
      0.009198295883834362,
      0.07083410024642944,
      -0.0346643291413784,
      -0.03577742725610733,
      0.04005454480648041,
      -0.045291706919670105,
      -0.012399193830788136,
      -0.027172818779945374,
      0.0158049575984478,
      0.006750764325261116,
      0.00378991081379354,
      0.0807926207780838,
      -0.0034249708987772465,
      0.008836057037115097,
      -0.003261938691139221,
      0.05041668564081192,
      -3.399555498617701e-05,
      0.013277280144393444,
      0.06750956177711487,
      -0.005327451508492231,
      0.012689793482422829,
      -0.06957097351551056,
      0.0024202149361371994,
      0.05210988596081734,
      0.054173510521650314,
      -0.08545941114425659,
      0.06943843513727188,
      0.010706077329814434,
      0.030667949467897415,
      0.009192959405481815,
      -0.03347957134246826,
      -0.00673725875094533,
      -0.02553582563996315,
      0.11250357329845428,
      -0.07516160607337952,
      0.011574248783290386,
      -0.007728693075478077,
      0.017856255173683167,
      0.015196425840258598,
      0.030127810314297676,
      -0.06620572507381439,
      -0.03650679066777229,
      -0.02160327509045601,
      0.070945143699646,
      -0.011852758936583996,
      -0.07735954970121384,
      0.03413430228829384
    ],
    [
      -0.0013312508817762136,
      0.02327229082584381,
      0.034582480788230896,
      0.03104047290980816,
      -0.02143687754869461,
      -0.0168109443038702,
      0.11168212443590164,
      -0.03450429439544678,
      0.042921796441078186,
      -0.030256040394306183,
      -0.0039225309155881405,
      -0.05642644688487053,
      -0.023321403190493584,
      -0.017942823469638824,
      -0.024767614901065826,
      -0.0510721281170845,
      -0.012177474796772003,
      0.02911708503961563,
      0.019337689504027367,
      -0.01786036230623722,
      -0.020083818584680557,
      -0.029763445258140564,
      -0.0371147096157074,
      0.038514915853738785,
      0.03228847309947014,
      0.06082938611507416,
      0.0400497131049633,
      -0.03424159809947014,
      0.041665151715278625,
      0.040032483637332916,
      -0.046673502773046494,
      -0.012727832421660423,
      -0.02579677663743496,
      0.0005288597312755883,
      0.03750349208712578,
      0.042048219591379166,
      -0.01274209376424551,
      -0.029643820598721504,
      -0.022363508120179176,
      0.058776963502168655,
      0.03869413211941719,
      -0.014600747264921665,
      -0.011515921913087368,
      -0.01435569953173399,
      -0.03464122861623764,
      0.04447665810585022,
      -0.06838724762201309,
      -0.03711491450667381,
      -0.0424654446542263,
      -0.03112994320690632,
      0.06089324876666069,
      -0.014793467707931995,
      -0.018078630790114403,
      0.04822671413421631,
      -0.010252699255943298,
      -0.08455555140972137,
      0.0723925456404686,
      0.04771772027015686,
      0.02920432575047016,
      -0.008745611645281315,
      0.04193510115146637,
      -0.02727873995900154,
      -0.02506309561431408,
      -0.024413609877228737,
      -0.020188957452774048,
      -0.0001547731226310134,
      0.025462597608566284,
      0.07386656105518341,
      0.028811853379011154,
      0.006510831415653229,
      -0.07642719894647598,
      -0.07257717847824097,
      -0.016505656763911247,
      -0.052550867199897766,
      -0.03147905692458153,
      -0.02677568607032299,
      -0.05958457663655281,
      -0.05041458457708359,
      -0.020373709499835968,
      0.024588394910097122,
      0.022138750180602074,
      -0.0091065913438797,
      0.037262607365846634,
      0.015550512820482254,
      -0.029108310118317604,
      -0.009808432310819626,
      0.00941642839461565,
      -0.040985118597745895,
      -0.02469407021999359,
      -0.06293850392103195,
      -0.0126962186768651,
      -0.011369244195520878,
      0.0069083599373698235,
      0.01615513488650322,
      -0.08290184289216995,
      -0.043902426958084106,
      -0.048012875020504,
      -0.019661957398056984,
      -0.010017863474786282,
      0.06179637834429741,
      0.05091904476284981,
      -0.013644625432789326,
      0.0012724067782983184,
      -0.05881944298744202,
      0.05460279807448387,
      0.004443455953150988,
      0.011539487168192863,
      -0.0016284589655697346,
      -0.013402624987065792,
      0.047196801751852036,
      0.024840010330080986,
      0.028669193387031555,
      -0.0056782737374305725,
      -0.08865460008382797,
      -0.038552362471818924,
      -0.006799653172492981,
      -0.015918966382741928,
      0.03506428748369217,
      -0.01667354255914688,
      0.006854962091892958,
      -0.057281821966171265,
      -0.0735105350613594,
      -0.01838368922472,
      0.04603493958711624,
      -0.009456135332584381,
      -0.010927868075668812,
      0.006364946253597736,
      -0.017977068200707436,
      -0.006968426052480936,
      -0.006732366047799587,
      0.04137217253446579,
      -0.04410545900464058,
      0.05412027984857559,
      0.04061262682080269,
      0.018849682062864304,
      0.005700422916561365,
      -0.04670080915093422,
      0.013429781422019005,
      0.09818977117538452,
      0.0027659854386001825,
      0.06900402158498764,
      0.029474522918462753,
      0.04031502828001976,
      -0.026932021602988243,
      0.02657933719456196,
      0.046965472400188446,
      -0.031658757477998734,
      -0.058519914746284485,
      -0.05212586000561714,
      0.05241377651691437,
      0.043049100786447525,
      -0.01954439841210842,
      -0.0528671070933342,
      0.003084520809352398,
      -0.005772837903350592,
      0.04831969738006592,
      -0.044342998415231705,
      0.02294536493718624,
      -0.014303917065262794,
      0.048013292253017426,
      0.07244822382926941,
      -0.005430338438600302,
      0.027117975056171417,
      -0.00468083843588829,
      0.022096756845712662,
      -0.04624848812818527,
      -0.07893110811710358,
      0.029285978525877,
      0.013646645471453667,
      0.008661946281790733,
      -0.06899403035640717,
      -0.04198133200407028,
      0.0485452339053154,
      -0.05265114828944206,
      0.004046736750751734,
      0.028257420286536217,
      0.024263892322778702,
      -0.03799889236688614,
      -0.012870818376541138,
      -0.008410844951868057,
      -0.06028367578983307,
      0.008638915605843067,
      0.027380526065826416,
      0.01882321946322918,
      0.02448662929236889,
      0.0650542676448822,
      -0.010418538935482502,
      0.046337127685546875,
      0.041599053889513016,
      -0.06457668542861938,
      -0.10547458380460739,
      -0.012300828471779823,
      0.10792185366153717,
      -0.04256277158856392,
      -0.047144968062639236,
      0.04527728259563446,
      0.05515296757221222,
      -0.015127068385481834,
      0.014224011451005936,
      0.08665841072797775,
      0.009626365266740322,
      -0.02930510602891445,
      0.016232293099164963,
      0.009709314443171024,
      0.012513780035078526,
      0.019341230392456055,
      -0.04223589226603508,
      -0.04911254346370697,
      -0.040362101048231125,
      -0.016856059432029724,
      0.06301353126764297,
      0.03891289234161377,
      0.0011899862438440323,
      0.03375603258609772,
      -0.013069992884993553,
      0.017662929370999336,
      -0.05906634405255318,
      -0.047197774052619934,
      -0.11194644123315811,
      -0.046922776848077774,
      0.005847323220223188,
      -0.0007932325825095177,
      -0.040682561695575714,
      0.0671415850520134,
      -0.031987350434064865,
      0.024291133508086205,
      -0.016107195988297462,
      -0.03927617520093918,
      -0.0074266367591917515,
      -0.01173375640064478,
      0.0208031814545393,
      0.04807507246732712,
      0.03252199664711952,
      -0.019583621993660927,
      -0.012204485014081001,
      -0.002380268182605505,
      -0.05625652149319649,
      -0.043319132179021835,
      -0.03820612654089928,
      0.028079858049750328,
      -0.04251489043235779,
      0.05106114223599434,
      -0.09087348729372025,
      -0.036662761121988297,
      0.039933159947395325,
      -0.032892320305109024,
      -0.04460271820425987,
      -0.025436853989958763,
      0.006151147186756134,
      -0.12225444614887238,
      0.03959956020116806,
      -0.004957669880241156,
      0.014330113306641579,
      -0.024548809975385666,
      0.12133247405290604,
      -0.02497738040983677,
      0.04843020811676979,
      0.002832958009094,
      -0.08698837459087372,
      0.003957381471991539,
      0.04904324933886528,
      -0.04263624548912048,
      0.010482433252036572,
      0.059147655963897705,
      0.034383323043584824,
      0.017962904646992683,
      -0.0411539226770401,
      -0.024912696331739426,
      0.011871272698044777,
      0.016720695421099663,
      0.026412734761834145,
      0.008944088593125343,
      0.01409628801047802,
      -0.06813541054725647,
      0.004995802417397499,
      -0.02114967443048954,
      -0.06360521167516708,
      0.04917876422405243,
      -0.0363580547273159,
      0.00888328067958355,
      -0.04439229518175125,
      0.0021872902289032936,
      0.07006515562534332,
      0.0186800304800272,
      0.0016628168523311615,
      -0.020548490807414055,
      -0.06372623145580292,
      -0.0332057923078537,
      -0.050663061439991,
      0.016724921762943268,
      0.047798383980989456,
      0.056759320199489594,
      -0.1266712248325348,
      0.013748041354119778,
      0.011886334046721458,
      -0.025728126987814903,
      -0.005254214629530907,
      -0.04378708079457283,
      -0.026010693982243538,
      0.045167457312345505,
      -0.017889613285660744,
      -0.019276240840554237,
      0.0542999766767025,
      0.07461954653263092,
      0.01644240692257881,
      0.043566957116127014,
      -0.016080781817436218,
      0.005214165896177292,
      -0.02200070396065712,
      0.019111739471554756,
      0.0010175042552873492,
      0.00418205838650465,
      -0.0010282383300364017,
      -0.021435564383864403,
      -0.06718854606151581,
      0.031703636050224304,
      -0.02008787915110588,
      0.027252543717622757,
      -0.03537219390273094,
      -0.014959737658500671,
      0.05195913091301918,
      0.057113371789455414,
      0.0886184498667717,
      -0.02531624212861061,
      0.012892680242657661,
      0.03350598365068436,
      0.04568534344434738,
      -0.0009426039177924395,
      -0.034078262746334076,
      -0.0037908575031906366,
      0.09300963580608368,
      0.08838549256324768,
      0.0005767904222011566,
      -0.04166923090815544,
      -0.011989235877990723,
      0.03990006074309349,
      0.021595414727926254,
      -0.040209971368312836,
      0.020504765212535858,
      -0.09229671210050583,
      0.009908298961818218,
      0.03805948421359062,
      -0.022709907963871956,
      -0.02036507986485958,
      -0.02832299843430519,
      0.010681159794330597,
      0.022376403212547302,
      0.06602024286985397,
      0.062381308525800705,
      -0.04157153144478798,
      0.017835402861237526,
      -0.017843473702669144,
      0.03749996796250343,
      0.04116925224661827,
      -0.013597462326288223,
      0.04164387658238411,
      -0.0283120758831501,
      0.036036066710948944,
      0.034184206277132034,
      -0.05622322857379913,
      -0.002860066946595907,
      -0.048921938985586166,
      0.034690629690885544,
      0.0784246027469635,
      -0.050855159759521484,
      0.04359453171491623,
      0.025631392374634743,
      -0.016351288184523582,
      0.029010554775595665,
      0.0544196180999279,
      0.00689965533092618,
      0.07663210481405258,
      0.029634840786457062,
      -0.0666649341583252,
      0.017739243805408478,
      -0.045870669186115265,
      -0.032784271985292435,
      -0.03358124941587448,
      -0.037647794932127,
      -0.019094346091151237,
      -0.07145551592111588,
      0.006037415005266666,
      0.026571230962872505,
      -0.005220317747443914,
      -0.045549001544713974,
      -0.0027043239679187536,
      -0.03238534554839134,
      0.01491503044962883,
      -0.06706085056066513,
      0.03698547184467316,
      0.016116933897137642,
      -0.04731646180152893,
      0.036010969430208206,
      0.013450545258820057,
      -0.06282822787761688,
      0.05519179254770279,
      0.05284847319126129,
      0.04438639432191849,
      -0.026819998398423195,
      -0.0014744348591193557,
      -0.04674327000975609,
      0.042658377438783646,
      0.010718876495957375,
      -0.01982133276760578,
      0.025380004197359085,
      -0.020041469484567642,
      -0.00037382927257567644,
      -0.052813634276390076,
      0.0019337763078510761,
      0.03097725287079811,
      0.03130665794014931,
      -0.06538357585668564,
      -0.11117012798786163,
      0.005597589537501335,
      -0.010096998885273933,
      -0.01936556026339531,
      0.005459046922624111,
      -0.07632294297218323,
      0.012420233339071274,
      0.03396124765276909,
      -0.025726422667503357,
      -0.050146643072366714,
      -0.006158103700727224,
      -0.08028203994035721,
      -0.035232219845056534,
      -0.039614275097846985,
      0.054726794362068176,
      -0.054803527891635895,
      -0.01866661198437214,
      0.04384998977184296,
      -0.018603792414069176,
      -0.026103438809514046,
      -0.030344082042574883,
      -0.04047045111656189,
      0.04595391824841499,
      -0.012066693045198917,
      -0.024574482813477516,
      -0.03497123345732689,
      0.09506392478942871,
      0.016789661720395088,
      0.034874700009822845,
      -0.0209179800003767,
      -0.0034620047081261873,
      0.02640003152191639,
      -0.03587964549660683,
      0.027819080278277397,
      -0.08707252889871597,
      -0.11887143552303314,
      -0.07668060064315796,
      -0.01960967853665352,
      -0.016964249312877655,
      -0.002873760648071766,
      0.014399150386452675,
      -0.00029945580172352493,
      0.03766448423266411,
      0.020314432680606842,
      0.03879610449075699,
      -0.020627303048968315,
      -0.03506731241941452,
      0.018678437918424606,
      -0.05972228944301605,
      -0.02763054147362709,
      -0.024308737367391586,
      0.012319589965045452,
      -0.04551739618182182,
      -0.06292904913425446,
      0.04666552692651749,
      0.010091140866279602,
      0.0670894905924797,
      -0.07201974093914032,
      -0.018997233361005783,
      -0.04123528301715851,
      0.08398190885782242,
      -0.013516192324459553,
      0.006451130378991365,
      -0.1235262081027031,
      0.02299356274306774,
      0.053881824016571045,
      -0.007617858704179525,
      0.027723325416445732,
      -0.014908715151250362,
      0.026102108880877495,
      -0.09874500334262848,
      0.02711263857781887,
      0.006328873336315155,
      0.027353571727871895,
      0.026279447600245476,
      0.039543841034173965,
      0.04717807471752167,
      0.06511930376291275,
      -0.008633756078779697,
      -0.0584382638335228,
      -0.0033068801276385784,
      -0.004931777715682983,
      -0.049721233546733856,
      0.0350116603076458,
      -0.013815265148878098,
      -0.03410812467336655,
      -0.06953944265842438,
      -0.011314839124679565,
      0.060810718685388565,
      -0.02745766192674637,
      -0.027838580310344696
    ],
    [
      -0.017233436927199364,
      -0.0009213538141921163,
      0.0548379048705101,
      0.0471624918282032,
      0.06618855893611908,
      -0.04068709537386894,
      0.0553729273378849,
      -0.051997553557157516,
      -0.008511744439601898,
      0.0460972934961319,
      0.01186328288167715,
      0.014604071155190468,
      -0.08802002668380737,
      0.023833027109503746,
      -0.08325574547052383,
      0.0048644752241671085,
      -0.009028900414705276,
      0.03246281296014786,
      -0.04975838214159012,
      -0.020028403028845787,
      -0.07181750982999802,
      0.01531624048948288,
      -0.015489536337554455,
      0.005956314969807863,
      0.07351190596818924,
      -0.007320153992623091,
      -0.042826440185308456,
      -0.09709328413009644,
      0.018496455624699593,
      0.04485495388507843,
      -0.004875346552580595,
      0.025896908715367317,
      -0.03759562596678734,
      -0.027138972654938698,
      0.08866941183805466,
      -0.014379401691257954,
      -0.0473296120762825,
      0.0007444896618835628,
      -0.016156187281012535,
      0.007246264722198248,
      0.023597775027155876,
      0.05271543189883232,
      -0.02160581946372986,
      -0.058993443846702576,
      0.07834034413099289,
      -0.009890821762382984,
      -0.0298477653414011,
      0.037183795124292374,
      -0.028385872021317482,
      0.0026099318638443947,
      0.0003696597123052925,
      0.0185821745544672,
      0.026590799912810326,
      0.04268297553062439,
      0.0001416617160430178,
      -0.056215591728687286,
      0.043902020901441574,
      0.0037461582105606794,
      -0.0355265736579895,
      0.013065190985798836,
      -0.026722200214862823,
      -0.03787536919116974,
      -0.024178961291909218,
      0.03474511578679085,
      0.014514632523059845,
      0.035436633974313736,
      0.009403225034475327,
      0.002979967277497053,
      0.020881278440356255,
      0.003407865995541215,
      0.017812900245189667,
      0.01076694019138813,
      -0.03394603356719017,
      -0.012112259864807129,
      -0.030992651358246803,
      0.04279029369354248,
      -0.04570966958999634,
      0.022959280759096146,
      -0.0023865646217018366,
      -0.004219467286020517,
      0.07058031111955643,
      0.030688364058732986,
      -0.08761594444513321,
      -0.038792796432971954,
      -0.058679889887571335,
      0.020209982991218567,
      0.023770002648234367,
      -0.023685265332460403,
      0.01653880625963211,
      0.005762508139014244,
      -0.008099005557596684,
      0.04198119416832924,
      0.026331061497330666,
      0.04941681772470474,
      0.03950556740164757,
      -0.003980562556535006,
      -0.005099624861031771,
      0.011715882457792759,
      0.012069663032889366,
      0.05977895110845566,
      -0.06683067232370377,
      0.06515668332576752,
      -0.0031871588435024023,
      -0.10033518075942993,
      -0.10492231696844101,
      0.07334364205598831,
      -0.06346049904823303,
      0.002104620449244976,
      0.030044827610254288,
      -0.08166249096393585,
      0.028857145458459854,
      -0.07933279871940613,
      0.009514120407402515,
      -0.029046352952718735,
      -0.07897143065929413,
      -0.04454813897609711,
      -0.013666722923517227,
      -0.05917324125766754,
      0.046530794352293015,
      -0.0121630122885108,
      -0.02554289624094963,
      0.07871660590171814,
      -0.029486387968063354,
      -0.045995526015758514,
      -0.029430503025650978,
      -0.006883321795612574,
      0.07224256545305252,
      0.06271456182003021,
      -0.029549647122621536,
      0.02746742032468319,
      -0.009301126934587955,
      0.06261885911226273,
      0.0038702492602169514,
      0.02924218401312828,
      -0.03910226747393608,
      0.04574383422732353,
      -0.01770692877471447,
      0.04261590912938118,
      -0.09511511772871017,
      -0.10169282555580139,
      -0.01835700124502182,
      0.01170087419450283,
      -0.016588814556598663,
      0.005682054441422224,
      -0.0055427951738238335,
      -0.04217599332332611,
      -0.03784268721938133,
      0.011405996046960354,
      -0.022758357226848602,
      -0.014122067019343376,
      0.06081421300768852,
      -0.04143013060092926,
      -0.025407113134860992,
      -0.08830444514751434,
      0.02113191969692707,
      -0.07119954377412796,
      0.009680354036390781,
      -0.03168710321187973,
      -0.007734484039247036,
      -0.03230852633714676,
      -0.040065545588731766,
      -0.010973306372761726,
      -0.04411783814430237,
      -0.08452727645635605,
      0.06258436292409897,
      0.011908085085451603,
      -0.03933296725153923,
      0.024365901947021484,
      -0.0008451160974800587,
      -0.012718666344881058,
      0.02980063296854496,
      -0.03332715854048729,
      0.010489106178283691,
      0.05757491663098335,
      0.03785032406449318,
      -0.049117449671030045,
      -0.04269033297896385,
      -0.006251932121813297,
      0.027212869375944138,
      0.026767589151859283,
      -0.03662988916039467,
      0.02052466943860054,
      -0.004639592487365007,
      -0.014586827717721462,
      0.07368806004524231,
      -0.02436106838285923,
      -0.04143775254487991,
      0.041117578744888306,
      0.07164712995290756,
      0.0331367664039135,
      0.07444451004266739,
      -0.0028040718752890825,
      0.04300808161497116,
      0.00776865566149354,
      -0.11796872317790985,
      -0.004498811438679695,
      0.01673428900539875,
      0.010581430979073048,
      0.04595765098929405,
      0.07387397438287735,
      0.021654915064573288,
      0.04680304974317551,
      0.0359707772731781,
      -0.0033424613066017628,
      -0.023635808378458023,
      -0.007931032218039036,
      0.02929834835231304,
      0.0429636612534523,
      0.02335103042423725,
      -0.01536326203495264,
      0.019862812012434006,
      0.058253902941942215,
      -0.011938856914639473,
      -0.02338123321533203,
      -0.06210417300462723,
      -0.04646952450275421,
      -0.014262186363339424,
      -0.04012973979115486,
      -0.00328370020724833,
      0.041800543665885925,
      -0.007544538006186485,
      0.006021716166287661,
      -0.002944859443232417,
      0.002013135701417923,
      0.0432894304394722,
      0.03581519424915314,
      -0.025687916204333305,
      -0.024996820837259293,
      0.0029155765660107136,
      0.05806378275156021,
      0.07069780677556992,
      0.07720523327589035,
      0.05645965039730072,
      0.04470834508538246,
      -0.020629437640309334,
      0.03066152147948742,
      -0.06734898686408997,
      -0.043390028178691864,
      0.007986994460225105,
      0.06238509342074394,
      -0.08158564567565918,
      -0.015881644561886787,
      0.026694046333432198,
      -0.0007912587025202811,
      -0.02495000511407852,
      0.006125145126134157,
      -0.05775098875164986,
      -0.01270216703414917,
      -0.0006335151265375316,
      0.07161610573530197,
      -0.030512863770127296,
      0.039768416434526443,
      0.03118814155459404,
      0.07065636664628983,
      -0.04355106130242348,
      0.07404156774282455,
      0.03579004853963852,
      0.019087832421064377,
      0.015097104012966156,
      0.05186730623245239,
      -0.05323587357997894,
      -0.006507405079901218,
      0.06877931952476501,
      0.03970105201005936,
      -0.01361781544983387,
      -0.072426438331604,
      -0.014449287205934525,
      -0.04580101743340492,
      -0.028077367693185806,
      -0.0485830157995224,
      -0.016654765233397484,
      0.06685317307710648,
      0.06371769309043884,
      0.05817291885614395,
      -0.0638602152466774,
      -0.0416698157787323,
      0.08921460807323456,
      -0.007511573377996683,
      -0.007617556024342775,
      0.04948204383254051,
      -0.0070045702159404755,
      -0.05480668321251869,
      -0.0094329584389925,
      -0.011599565856158733,
      -0.10717904567718506,
      0.025567438453435898,
      -0.005281911231577396,
      0.036117780953645706,
      0.05552671477198601,
      -0.06147066131234169,
      0.059556975960731506,
      0.002374001545831561,
      -0.005005165934562683,
      -0.005457020364701748,
      0.02636740542948246,
      0.08356714993715286,
      0.02127445489168167,
      0.00037153580342419446,
      -0.028557049110531807,
      -0.09079219400882721,
      -0.0189058817923069,
      0.03876480832695961,
      -0.012900537811219692,
      -0.0037906235083937645,
      0.0580175444483757,
      0.0021166098304092884,
      0.03769216686487198,
      0.06916733086109161,
      0.07707320898771286,
      -0.005306220147758722,
      -0.08397633582353592,
      0.024256493896245956,
      0.06989160180091858,
      -0.015049610286951065,
      0.01764906570315361,
      0.022495832294225693,
      -0.005571828689426184,
      0.08918531984090805,
      -0.04967018589377403,
      -0.009591609239578247,
      -0.007971026934683323,
      0.01767670176923275,
      0.025725234299898148,
      -0.06958108395338058,
      -0.08182460814714432,
      -0.06973259150981903,
      0.053513187915086746,
      0.05126766860485077,
      -0.01169271394610405,
      0.03056790679693222,
      0.00519500020891428,
      -0.023372147232294083,
      0.08904162794351578,
      0.0109945023432374,
      -0.006951895542442799,
      0.038026660680770874,
      0.03422847017645836,
      -0.007500785868614912,
      -0.0044352528639137745,
      0.03424457088112831,
      0.05479179695248604,
      -0.05609339475631714,
      0.06541526317596436,
      -0.007125732023268938,
      -0.050506170839071274,
      0.009435723535716534,
      -0.012320028617978096,
      -0.028868868947029114,
      -0.020078672096133232,
      -0.013818607665598392,
      -0.06792711466550827,
      0.10402984172105789,
      0.08087686449289322,
      0.04036678001284599,
      -0.004936653655022383,
      -0.029226211830973625,
      -0.053608693182468414,
      0.026581503450870514,
      -0.0029171365313231945,
      0.07856433838605881,
      -0.040445663034915924,
      -0.08790822327136993,
      -0.045477766543626785,
      -0.008982561528682709,
      0.0865810215473175,
      0.055470895022153854,
      0.03492442145943642,
      -0.02223087102174759,
      -0.04741347208619118,
      0.029089195653796196,
      0.03609243407845497,
      -0.012344513088464737,
      -0.005235650576651096,
      -0.0018598745809867978,
      -0.07884569466114044,
      -0.010888704098761082,
      -0.0478009469807148,
      -0.027278823778033257,
      0.017094764858484268,
      0.0012377608800306916,
      -0.0455806627869606,
      0.025351695716381073,
      -0.05655450001358986,
      -0.02094072662293911,
      0.10583926737308502,
      0.011005660519003868,
      -0.019711457192897797,
      0.006184534169733524,
      -0.03210577741265297,
      0.03487696871161461,
      -0.05660610646009445,
      -0.0594552643597126,
      -0.03752955049276352,
      -0.03766888380050659,
      0.06746087223291397,
      0.01630636490881443,
      0.014117209240794182,
      0.055874601006507874,
      0.07232911139726639,
      -0.05839622765779495,
      -0.04654836654663086,
      -0.017068490386009216,
      0.003938363399356604,
      0.0059091863222420216,
      0.02180345542728901,
      0.009892941452562809,
      -0.023653147742152214,
      0.04076565429568291,
      -0.030427876859903336,
      0.02126367948949337,
      0.0565672367811203,
      0.0006925706402398646,
      -0.0015354464994743466,
      -0.06192662566900253,
      -0.007519214414060116,
      0.011296725831925869,
      0.05398055538535118,
      -0.05395912379026413,
      -0.07899275422096252,
      0.03970710560679436,
      0.00031944725196808577,
      0.0052328333258628845,
      0.00046732634655199945,
      0.06117827817797661,
      0.040452200919389725,
      -0.0030957746785134077,
      0.017543276771903038,
      0.06028483062982559,
      -0.03006896749138832,
      -0.09222326427698135,
      0.09708017855882645,
      0.08149262517690659,
      -0.002439450938254595,
      0.022705109789967537,
      -0.07324443757534027,
      0.04298210144042969,
      -0.008445832878351212,
      -0.01593686081469059,
      0.035129301249980927,
      0.025834806263446808,
      0.03554278239607811,
      0.015283672139048576,
      0.01118484977632761,
      -0.02617233619093895,
      -0.03615225479006767,
      0.08106650412082672,
      0.024967046454548836,
      -0.0007562799146398902,
      0.02404264733195305,
      -0.009050752967596054,
      -0.028269363567233086,
      -0.07072439044713974,
      -0.016078878194093704,
      0.051213499158620834,
      -0.026175618171691895,
      -0.016891811043024063,
      -0.05965792015194893,
      -0.05940810218453407,
      0.03925338387489319,
      -0.03501619026064873,
      -0.00014422120875678957,
      -0.017939994111657143,
      0.0421295166015625,
      -0.003865616861730814,
      -0.03044692985713482,
      0.08640410006046295,
      0.06943276524543762,
      -0.009201847948133945,
      0.02131078578531742,
      0.06536044925451279,
      0.070554718375206,
      -0.013903165236115456,
      0.05475383996963501,
      -0.018791943788528442,
      -0.006974696647375822,
      0.05555327609181404,
      0.00776307936757803,
      -0.04250703752040863,
      0.04116190969944,
      0.08218589425086975,
      0.0698305144906044,
      0.0037733553908765316,
      0.02476346306502819,
      0.009903284721076488,
      0.0020319039467722178,
      0.032296303659677505,
      0.016098905354738235,
      0.005760325584560633,
      0.04042106866836548,
      0.06547129899263382,
      -0.05629216879606247,
      0.0018620057962834835,
      -0.02071085199713707,
      0.028708677738904953,
      -0.053244929760694504,
      -0.06956682354211807,
      -0.006546474527567625,
      -0.009848962537944317,
      -0.07121189683675766,
      0.003858032636344433,
      0.00017146121535915881,
      0.011058533564209938,
      -0.00718529149889946
    ],
    [
      0.020160891115665436,
      0.009951379150152206,
      0.11194472759962082,
      -0.03489147499203682,
      0.007737955078482628,
      -0.046586472541093826,
      0.05950211361050606,
      0.06183425709605217,
      0.02706320770084858,
      0.07322441786527634,
      -0.05160607025027275,
      -0.03320098668336868,
      -0.041215285658836365,
      0.0019877352751791477,
      -0.05358807370066643,
      -0.01236516609787941,
      -0.01810831017792225,
      0.007312708534300327,
      -0.010389739647507668,
      -0.0507655031979084,
      -0.05813436210155487,
      0.01639813371002674,
      -0.039314694702625275,
      -0.046089138835668564,
      -0.001506238360889256,
      -0.022420987486839294,
      -0.02209128811955452,
      -0.04378455877304077,
      0.02094963565468788,
      -0.032353006303310394,
      -0.06585142761468887,
      -0.0008225484052672982,
      -0.004208327736705542,
      0.07386526465415955,
      0.07603012770414352,
      0.012455292046070099,
      -0.0027627740055322647,
      -0.014336584135890007,
      0.06643707305192947,
      0.11339104920625687,
      -0.032016508281230927,
      -0.05123787745833397,
      -0.0023079176899045706,
      -0.05330464988946915,
      -0.03525882959365845,
      0.004839123692363501,
      0.08148542791604996,
      0.009600542485713959,
      -0.04892301559448242,
      -0.0024530140217393637,
      -0.01902688853442669,
      -0.039635561406612396,
      0.025069693103432655,
      -0.08559927344322205,
      0.028459006920456886,
      0.00773345772176981,
      -0.029545322060585022,
      0.009253081865608692,
      0.05805610865354538,
      0.0027754027396440506,
      0.007674494292587042,
      -0.007407831493765116,
      0.041428547352552414,
      0.016405519098043442,
      -0.04278849810361862,
      -0.03706984594464302,
      0.003652053652331233,
      -0.02651011198759079,
      0.0365569069981575,
      -0.004342544823884964,
      -0.00331662455573678,
      -0.012364420108497143,
      0.01267935335636139,
      -0.06593821942806244,
      -0.04276840388774872,
      -0.035453446209430695,
      -0.0020303125493228436,
      0.018913542851805687,
      -0.02057606913149357,
      -0.04081474989652634,
      0.042775917798280716,
      0.04573371261358261,
      0.040477294474840164,
      0.0338742695748806,
      -0.05046816170215607,
      -0.10926637798547745,
      -0.012299544177949429,
      -0.055424660444259644,
      -0.06213858723640442,
      -0.06612195819616318,
      0.0730738490819931,
      -0.046754445880651474,
      -0.013082685880362988,
      -0.11616263538599014,
      -0.03195161372423172,
      -0.03999099135398865,
      -0.02828972600400448,
      0.014924571849405766,
      -0.025921085849404335,
      0.0028043612837791443,
      -0.0041132522746920586,
      -0.0036461337003856897,
      -0.040435805916786194,
      -0.00024695202591829,
      -0.041505537927150726,
      0.03364839032292366,
      -0.019320057705044746,
      0.015218120999634266,
      -0.03035116195678711,
      -0.01225948240607977,
      0.03390027582645416,
      0.035418640822172165,
      0.08762612193822861,
      -0.03610772639513016,
      0.012358663603663445,
      0.0732513889670372,
      0.06446131318807602,
      0.02358175814151764,
      0.015219578519463539,
      0.04973689839243889,
      0.040809642523527145,
      0.03990443795919418,
      0.03442763164639473,
      -0.02366703748703003,
      -0.04187289997935295,
      -0.03350016847252846,
      -0.021130837500095367,
      -0.00946745928376913,
      0.004501055460423231,
      0.0705760046839714,
      -0.012594136409461498,
      0.12733447551727295,
      -0.07050681114196777,
      -0.046749867498874664,
      -0.0014107704628258944,
      -0.06202635169029236,
      -0.00820604246109724,
      -0.03803121671080589,
      -0.06248422712087631,
      -0.00831568893045187,
      0.009470200166106224,
      0.005020208191126585,
      -0.018710337579250336,
      0.028790447860956192,
      -0.026935860514640808,
      -0.007297108881175518,
      0.027542434632778168,
      -0.029538430273532867,
      -0.014078104868531227,
      -0.031749069690704346,
      -0.030844196677207947,
      -0.05874583125114441,
      -0.01845928654074669,
      -0.030413370579481125,
      -0.007234248798340559,
      0.007223617751151323,
      -0.03115527145564556,
      -0.031132904812693596,
      -0.04245234280824661,
      0.043446075171232224,
      0.021958433091640472,
      -0.04383271187543869,
      -0.01654566265642643,
      -0.009262300096452236,
      0.0010540429502725601,
      0.0019405190832912922,
      -0.04949819669127464,
      0.044816434383392334,
      -0.030978726223111153,
      0.005848797503858805,
      0.10542257875204086,
      -0.006665999069809914,
      -0.06965567171573639,
      0.07521405071020126,
      0.013652428984642029,
      0.0003981372865382582,
      -0.06808839738368988,
      -0.033031538128852844,
      -0.0034366894979029894,
      -0.023957539349794388,
      -0.0037156238686293364,
      0.04222894832491875,
      0.03333818539977074,
      -0.05785650387406349,
      0.05917346104979515,
      -0.011066378094255924,
      -0.016616450622677803,
      0.0519193671643734,
      0.04080400615930557,
      0.011699941009283066,
      0.045461516827344894,
      -0.020540112629532814,
      -0.03277898207306862,
      0.06137744337320328,
      -0.021890724077820778,
      0.03143475204706192,
      0.04082478582859039,
      0.041672807186841965,
      0.009202529676258564,
      -0.04276018217206001,
      0.03690513223409653,
      0.008013187907636166,
      -0.034832775592803955,
      0.0253754835575819,
      0.040980014950037,
      0.029558053240180016,
      -0.046009838581085205,
      -0.0316159687936306,
      -0.0378987081348896,
      -0.012451629154384136,
      0.0052277217619121075,
      0.029630927368998528,
      -0.017493441700935364,
      0.01737176813185215,
      -0.010132606141269207,
      0.06619929522275925,
      0.03542495146393776,
      0.013976243324577808,
      0.04615933820605278,
      0.029715489596128464,
      -0.0469353124499321,
      0.020125582814216614,
      -0.0573502741754055,
      -0.004261263180524111,
      0.02663920260965824,
      -0.04648181423544884,
      0.00013773702085018158,
      0.029921583831310272,
      0.018812276422977448,
      0.025872042402625084,
      -0.014668215997517109,
      -0.05573346093297005,
      0.034867674112319946,
      0.06747893989086151,
      -0.043642789125442505,
      0.03291532024741173,
      -0.05159306526184082,
      -0.06002068892121315,
      0.03910105302929878,
      0.03145163878798485,
      0.015111487358808517,
      -0.013666694983839989,
      -0.02499808557331562,
      -0.0458931140601635,
      -0.10089510679244995,
      -0.03774336725473404,
      0.04792432487010956,
      0.08883834630250931,
      0.025491533800959587,
      -0.027095070108771324,
      0.029115376994013786,
      -0.02544999308884144,
      0.045438528060913086,
      0.05479811504483223,
      0.03714432567358017,
      0.016310593113303185,
      0.014736976474523544,
      -0.04904085025191307,
      -0.03736310079693794,
      -0.056426431983709335,
      -0.015570401214063168,
      0.02500673197209835,
      0.026126423850655556,
      -0.0033418559469282627,
      -0.01072964072227478,
      -0.0742444097995758,
      0.064651720225811,
      0.02284875139594078,
      0.027486134320497513,
      -0.031772177666425705,
      -0.02276899665594101,
      0.006687209941446781,
      -0.03878209739923477,
      -0.01980821043252945,
      0.039881590753793716,
      -0.05054241046309471,
      -0.014935115352272987,
      0.018977945670485497,
      0.04731165990233421,
      0.019026845693588257,
      -0.04402700439095497,
      0.07140662521123886,
      0.013479374349117279,
      -0.10682152211666107,
      -0.058116182684898376,
      0.020104344934225082,
      -0.03069528378546238,
      -0.03690967336297035,
      0.004820513539016247,
      -0.03785395622253418,
      0.021304113790392876,
      0.0036006139125674963,
      -0.0030480874702334404,
      -0.016173379495739937,
      -0.0053216079249978065,
      0.09178751707077026,
      0.022657005116343498,
      0.034865450114011765,
      -0.03331676498055458,
      -0.03003523126244545,
      -0.051110368221998215,
      0.04618988186120987,
      0.008887514472007751,
      0.008054094389081001,
      0.0525418221950531,
      0.016791317611932755,
      -0.0418582484126091,
      -0.02111966349184513,
      -0.046613648533821106,
      -0.02005133591592312,
      0.03306004777550697,
      0.036759305745363235,
      -0.0035780644975602627,
      -0.1008249968290329,
      -0.013736763969063759,
      -0.06608113646507263,
      0.013877429068088531,
      -0.09001382440328598,
      -0.01594623178243637,
      0.09498842805624008,
      0.012373095378279686,
      0.014973663724958897,
      -0.003114892402663827,
      0.034429099410772324,
      0.029433155432343483,
      0.03950681909918785,
      -0.0032427427358925343,
      0.023300012573599815,
      0.010216020047664642,
      -0.05029438063502312,
      0.0158737450838089,
      0.008189196698367596,
      -0.03413179889321327,
      0.017772933468222618,
      -0.007443445734679699,
      -0.08875314891338348,
      -0.11225451529026031,
      0.03683502972126007,
      -0.04004679247736931,
      0.049044135957956314,
      -0.017607923597097397,
      -0.008599195629358292,
      -0.03580760583281517,
      -0.03662572801113129,
      -0.01767188310623169,
      -0.139658123254776,
      -0.017405718564987183,
      -0.007452900521457195,
      0.024235021322965622,
      -0.0035578846000134945,
      -0.04292731732130051,
      -0.005522211082279682,
      -0.015675274655222893,
      -0.043823663145303726,
      0.0022012817207723856,
      0.06368523836135864,
      -0.10113811492919922,
      0.029875537380576134,
      0.11202532798051834,
      -0.040828097611665726,
      0.09540911763906479,
      -0.021604984998703003,
      -0.011891952715814114,
      0.036865752190351486,
      0.05983761325478554,
      -0.07638874650001526,
      0.035199861973524094,
      -0.03426506370306015,
      -0.01706264540553093,
      0.029386043548583984,
      -0.022548727691173553,
      -0.055113837122917175,
      -0.059140246361494064,
      -0.04151540249586105,
      -0.027950679883360863,
      0.06921680271625519,
      0.0031525481026619673,
      0.05001142993569374,
      -0.005475201178342104,
      0.02313653565943241,
      0.05348048731684685,
      -0.03673217445611954,
      0.016076942905783653,
      0.08449319750070572,
      -0.02525457739830017,
      0.012765581719577312,
      -0.034471262246370316,
      -0.04172584414482117,
      0.05381234735250473,
      -0.012548590078949928,
      -0.046207595616579056,
      0.039569441229104996,
      0.02514883130788803,
      -0.012006079778075218,
      0.00026206477195955813,
      -0.06755612790584564,
      0.06713444739580154,
      -0.033201541751623154,
      0.03368017077445984,
      0.02187834493815899,
      -0.0008529900223948061,
      0.003193735145032406,
      -0.004086687229573727,
      0.09252206981182098,
      0.051547516137361526,
      0.05936933681368828,
      0.003069171216338873,
      -0.045020394027233124,
      0.07497858256101608,
      0.0018202330684289336,
      0.07265833765268326,
      -0.04211888462305069,
      -0.022584715858101845,
      0.0245998315513134,
      0.05872218683362007,
      -0.01722385734319687,
      0.025271696969866753,
      -0.003832713235169649,
      -0.04441281035542488,
      0.03529997915029526,
      0.08918233960866928,
      0.031918544322252274,
      0.06623128056526184,
      0.08220621198415756,
      -0.08761859685182571,
      0.012540915049612522,
      -0.03540264815092087,
      0.021095983684062958,
      -0.04149884358048439,
      0.005893727298825979,
      -0.05348838493227959,
      -0.03666429594159126,
      -0.003633745713159442,
      0.04670336842536926,
      0.010314976796507835,
      0.012663970701396465,
      -0.011141047812998295,
      0.024156106635928154,
      -0.06836892664432526,
      -0.030328959226608276,
      -0.03749530762434006,
      0.01023807842284441,
      -0.11509303003549576,
      0.018077002838253975,
      0.0006030485965311527,
      0.002667113905772567,
      0.06179916486144066,
      -0.018487170338630676,
      0.046050842851400375,
      0.015938811004161835,
      0.03777559474110603,
      0.05198495462536812,
      0.061621420085430145,
      0.0013211691984906793,
      0.02251019887626171,
      0.07071911543607712,
      0.0018934821709990501,
      -0.05675271153450012,
      0.033166419714689255,
      0.03053649701178074,
      -0.02768680453300476,
      0.0005613976973108947,
      0.02355160191655159,
      -0.0818164199590683,
      -0.004130881279706955,
      0.07869566231966019,
      -0.06158805266022682,
      -0.07877948135137558,
      -0.025060664862394333,
      0.01853560283780098,
      -0.08415684103965759,
      -0.12222664803266525,
      0.07355601340532303,
      0.023687513545155525,
      0.017993969842791557,
      -0.018754739314317703,
      -0.035945773124694824,
      -0.0434969961643219,
      0.007672952022403479,
      0.01421293057501316,
      0.010929266922175884,
      -0.04267686605453491,
      0.010032505728304386,
      0.02663244493305683,
      0.03593002259731293,
      0.06866161525249481,
      0.08130244165658951,
      -0.021744323894381523,
      -0.003221732098609209,
      -0.00791862141340971,
      0.00419599236920476,
      -0.04260272905230522,
      -0.017115721479058266,
      0.010096495971083641,
      0.018208183348178864,
      0.0026401826180517673,
      0.02391855977475643,
      -0.005870969966053963,
      0.02914097160100937,
      0.051216866821050644,
      0.019973130896687508,
      -0.05567130446434021,
      -0.054170746356248856
    ],
    [
      0.02973971515893936,
      0.0007596872746944427,
      0.030423153191804886,
      0.11211349815130234,
      -0.0044816783629357815,
      0.05795319378376007,
      -0.0488622784614563,
      0.05854222923517227,
      -0.007632566150277853,
      0.020451445132493973,
      0.023006616160273552,
      -0.02558898739516735,
      0.02404404617846012,
      0.025927836075425148,
      0.008071864023804665,
      0.02258748933672905,
      -0.02589099481701851,
      0.030523167923092842,
      0.048694148659706116,
      -0.02695240080356598,
      -0.021659620106220245,
      -0.054354116320610046,
      -0.07851915061473846,
      -0.12373978644609451,
      -0.05734431371092796,
      -0.02438804693520069,
      0.009142344817519188,
      -0.052783653140068054,
      -0.025730116292834282,
      -0.05183987691998482,
      0.013835790567100048,
      -0.014351051300764084,
      -0.004516219720244408,
      -0.0031851399689912796,
      0.003734054509550333,
      -0.0045888349413871765,
      -0.02291696146130562,
      -0.06365447491407394,
      -0.04236084222793579,
      0.01416210737079382,
      -0.003197432728484273,
      -0.05273919552564621,
      0.04019702225923538,
      -0.029049750417470932,
      0.02436910755932331,
      0.03133954852819443,
      0.025453202426433563,
      -0.004759784787893295,
      -0.025865742936730385,
      -0.04368354752659798,
      -0.060469143092632294,
      0.0457703061401844,
      0.0162833072245121,
      0.009284814819693565,
      0.0033750387374311686,
      -0.13341575860977173,
      0.0622141994535923,
      -0.07959950715303421,
      0.018210262060165405,
      0.04930371791124344,
      -0.031263548880815506,
      -0.005200310610234737,
      -0.02155807800590992,
      0.008486252278089523,
      0.06824899464845657,
      -0.011736755259335041,
      0.02169635519385338,
      -0.027656013146042824,
      -0.04360666871070862,
      0.043664585798978806,
      -0.05633477121591568,
      0.002357000485062599,
      -0.011024711653590202,
      -0.01880532130599022,
      -0.0347028411924839,
      -0.019196076318621635,
      -0.035235434770584106,
      -0.015748169273138046,
      0.04725424200296402,
      -0.01692884974181652,
      0.017226986587047577,
      0.0013472024584189057,
      0.02667894773185253,
      -0.02324414625763893,
      -0.02768813632428646,
      0.012019450776278973,
      -0.029813485220074654,
      0.011968130245804787,
      0.03290312737226486,
      -0.12326202541589737,
      0.014904400333762169,
      0.05543442815542221,
      -0.02330862544476986,
      -0.004228019155561924,
      0.11950237303972244,
      -0.030166996642947197,
      -0.0018526873318478465,
      -0.03179379180073738,
      0.012050919234752655,
      -0.029047224670648575,
      0.03473854437470436,
      -0.01858140528202057,
      -0.043797895312309265,
      0.004513094667345285,
      -0.005690289661288261,
      0.11664634943008423,
      0.047363556921482086,
      -0.07950238138437271,
      0.0004785594646818936,
      0.020869672298431396,
      0.013279003091156483,
      -0.052995990961790085,
      -0.031125860288739204,
      -0.05736169591546059,
      -0.0071268402971327305,
      -0.012912485748529434,
      0.029672563076019287,
      0.006533386651426554,
      -0.015034159645438194,
      0.014934966340661049,
      -0.07124757021665573,
      -0.029670070856809616,
      0.02663656510412693,
      0.001847169827669859,
      -0.0205790214240551,
      0.013462706468999386,
      0.02336317114531994,
      -0.03216684237122536,
      -0.09694716334342957,
      0.06713186204433441,
      0.12279526889324188,
      0.022423895075917244,
      0.009196527302265167,
      0.0258149653673172,
      0.030475778505206108,
      -0.06991679221391678,
      0.05355500057339668,
      0.07403983920812607,
      -0.03462126851081848,
      0.06950751692056656,
      -0.04041792079806328,
      0.004538146313279867,
      0.03392406553030014,
      -0.02244136668741703,
      -0.044243667274713516,
      -0.04631010815501213,
      0.00679078558459878,
      0.027629198506474495,
      0.1268972009420395,
      0.03495708107948303,
      -0.0028548319824039936,
      -0.0871104970574379,
      0.08017604798078537,
      0.025278983637690544,
      0.0398099310696125,
      -0.03323722258210182,
      -0.02595878206193447,
      0.02986546792089939,
      -0.05766575038433075,
      0.014988420531153679,
      0.015493824146687984,
      -0.02040942944586277,
      -0.007403825409710407,
      -0.0017205679323524237,
      -0.009717205539345741,
      0.008478712290525436,
      0.010103684850037098,
      -0.020003901794552803,
      -0.03663516417145729,
      -0.13440196216106415,
      0.006767154671251774,
      -0.011222747154533863,
      0.01898045651614666,
      -0.001382098882459104,
      -0.024604106321930885,
      0.10185553878545761,
      0.01889178715646267,
      -0.06201838329434395,
      0.038371533155441284,
      0.07282615453004837,
      0.035212304443120956,
      0.07467754185199738,
      -0.05125647783279419,
      -0.018725289031863213,
      -0.03956790640950203,
      0.01824144460260868,
      0.002398838521912694,
      -0.06712742894887924,
      -0.02539960853755474,
      0.056597985327243805,
      0.020433921366930008,
      0.008988289162516594,
      0.01990264281630516,
      -0.01821548491716385,
      -0.05405423417687416,
      -0.06155281513929367,
      -0.03409590199589729,
      0.013203859329223633,
      -0.0005167489289306104,
      0.09359858930110931,
      0.052064232528209686,
      -0.03077208250761032,
      -0.0017621422884985805,
      0.11377143114805222,
      0.02257121168076992,
      -0.0627702996134758,
      0.009448212571442127,
      -0.003918676637113094,
      0.03075803443789482,
      0.013574779964983463,
      -0.005410709884017706,
      -0.05289217457175255,
      -0.072283074259758,
      0.028924062848091125,
      -0.018368521705269814,
      0.052657682448625565,
      0.012911544181406498,
      0.02785404771566391,
      -0.025943920016288757,
      0.045570727437734604,
      0.002472412306815386,
      0.013467496261000633,
      -0.05991613492369652,
      0.037736162543296814,
      0.012745573185384274,
      -0.013152986764907837,
      0.07384049892425537,
      -0.03730286285281181,
      -0.012548618018627167,
      0.012899491935968399,
      0.03887913376092911,
      0.11104569584131241,
      -0.06728669255971909,
      0.05398966744542122,
      -0.035529281944036484,
      0.06949442625045776,
      -0.01666003279387951,
      -0.00041729494114406407,
      -0.028593821451067924,
      -0.01734326221048832,
      0.020657073706388474,
      0.03300204500555992,
      0.08561906218528748,
      -0.005305279511958361,
      -0.01029522716999054,
      -0.007555576041340828,
      -0.04433273896574974,
      0.013269304297864437,
      0.050983719527721405,
      0.06864027678966522,
      -0.06949982047080994,
      -0.0445193313062191,
      0.04475456848740578,
      -0.06433773040771484,
      -0.021188577637076378,
      -0.03738878667354584,
      -0.03861993923783302,
      0.03431229293346405,
      -0.01117620524019003,
      -0.06817491352558136,
      0.022056542336940765,
      -0.02469848468899727,
      -0.06277363002300262,
      0.010301277972757816,
      -0.004748576786369085,
      0.027749070897698402,
      0.03294235095381737,
      0.02470744587481022,
      -0.05298324301838875,
      0.07481987029314041,
      0.024795958772301674,
      -0.03638242557644844,
      0.018001968041062355,
      0.01704608090221882,
      -0.006077755242586136,
      -0.10238512605428696,
      0.01708601601421833,
      0.005932314321398735,
      0.02860870026051998,
      -0.04196985438466072,
      0.02383382059633732,
      0.06739958375692368,
      -0.009878305718302727,
      -0.03973926976323128,
      0.12902168929576874,
      0.037627529352903366,
      0.026693254709243774,
      -0.05067925155162811,
      0.0054331133142113686,
      -0.08389441668987274,
      -0.04934743419289589,
      0.09092696756124496,
      -0.023484718054533005,
      -0.04465242475271225,
      0.046733465045690536,
      -0.10653924196958542,
      0.003054338973015547,
      -0.005266855470836163,
      0.084754079580307,
      -0.10227207839488983,
      -0.0624435693025589,
      -0.015397412702441216,
      0.014671494252979755,
      -0.03262834623456001,
      0.04601544141769409,
      -0.056898150593042374,
      -0.05752309411764145,
      0.0657924935221672,
      0.07718013972043991,
      0.0036536273546516895,
      -0.07627712935209274,
      -0.025910891592502594,
      0.015592364594340324,
      0.013685999438166618,
      0.005363085772842169,
      0.04324872046709061,
      0.019077595323324203,
      0.0821208730340004,
      0.000869609706569463,
      0.033456701785326004,
      0.010079596191644669,
      -0.009475266560912132,
      -0.0020024459809064865,
      -0.024988941848278046,
      0.027757786214351654,
      0.005904455669224262,
      0.06476614624261856,
      0.019990738481283188,
      -0.014037135988473892,
      -0.06981047987937927,
      -0.09078831225633621,
      -0.0032760335598140955,
      -0.02022494189441204,
      -0.0405164510011673,
      -0.009177939966320992,
      -0.0007827244116924703,
      0.04264124855399132,
      0.07096262276172638,
      -0.040994253009557724,
      0.07863586395978928,
      -0.010984956286847591,
      -0.028025342151522636,
      -0.031137559562921524,
      -0.06783740967512131,
      0.017390761524438858,
      -0.07601997256278992,
      0.020713839679956436,
      -0.039884649217128754,
      0.03885504603385925,
      -0.0016356625128537416,
      0.11430240422487259,
      0.014757605269551277,
      0.04386178031563759,
      -0.010825521312654018,
      -0.023326359689235687,
      -0.03051324374973774,
      -0.04122817516326904,
      0.036201637238264084,
      0.041659582406282425,
      0.01068500243127346,
      -0.0053020077757537365,
      0.03749609738588333,
      -0.004423391539603472,
      -0.02917167730629444,
      -0.059380222111940384,
      -0.015099206939339638,
      0.06684043258428574,
      -0.02662976272404194,
      -0.015541702508926392,
      0.06871654838323593,
      0.005283920094370842,
      0.05576573684811592,
      -0.03824448585510254,
      -0.043858498334884644,
      0.04412029683589935,
      -0.044758666306734085,
      -0.025110511109232903,
      0.008905842900276184,
      -0.024564476683735847,
      -0.0688653215765953,
      -0.025614337995648384,
      0.02002125047147274,
      0.07556901127099991,
      -0.011855115182697773,
      0.023235689848661423,
      -0.02166496403515339,
      0.0637652575969696,
      -0.03638598322868347,
      0.017314035445451736,
      0.10324101895093918,
      -0.06689351797103882,
      0.02316770702600479,
      -0.04002652317285538,
      0.07191988825798035,
      0.03883155435323715,
      -0.04387679696083069,
      -0.021845538169145584,
      -0.000806895550340414,
      -0.02658575214445591,
      0.009903108701109886,
      0.04147973284125328,
      -0.023607267066836357,
      0.07395865768194199,
      -0.03636295348405838,
      -0.013284613378345966,
      -0.045459698885679245,
      -0.00868101418018341,
      -0.009333967231214046,
      -0.05964589864015579,
      -0.013106944039463997,
      -0.0354689285159111,
      0.03864696994423866,
      -0.04463466629385948,
      0.002486573997884989,
      -0.07118523120880127,
      0.05100347101688385,
      -0.02724262699484825,
      0.03421180695295334,
      -0.023994365707039833,
      0.005781263113021851,
      -0.015040929429233074,
      -0.033169765025377274,
      0.02763024903833866,
      -0.026126757264137268,
      -0.012619774788618088,
      -0.02775934711098671,
      -0.074190154671669,
      -0.036511633545160294,
      -0.017957670614123344,
      -0.07769204676151276,
      -0.03682738542556763,
      0.013367576524615288,
      -0.03576543182134628,
      -0.01942615769803524,
      0.042079292237758636,
      -0.03197730332612991,
      0.047920890152454376,
      0.01433225441724062,
      -0.0037418100982904434,
      0.002361819613724947,
      -0.0657210499048233,
      -0.001737781334668398,
      -0.01623375527560711,
      -0.075132817029953,
      -0.03217250108718872,
      0.030068306252360344,
      0.03352709487080574,
      -0.048121556639671326,
      -0.0563693530857563,
      -0.016681475564837456,
      -0.035294342786073685,
      -0.0018341048853471875,
      0.026229865849018097,
      0.05319257453083992,
      -0.036714714020490646,
      -0.011783079244196415,
      -0.03320865333080292,
      -0.029534336179494858,
      -0.004029618110507727,
      0.06468866020441055,
      -0.027035795152187347,
      0.041757382452487946,
      0.053632982075214386,
      -0.011933577246963978,
      -0.027066223323345184,
      -0.056196290999650955,
      0.03764009848237038,
      -0.004681632854044437,
      -0.002918212441727519,
      0.007898285053670406,
      0.03490488603711128,
      -0.005813155323266983,
      -0.04342813789844513,
      -0.07085327804088593,
      0.0708022266626358,
      -0.030008193105459213,
      -0.011255025863647461,
      -0.037009965628385544,
      -0.0009973185369744897,
      -0.040904562920331955,
      0.031136270612478256,
      0.05617844685912132,
      0.005710153840482235,
      0.010956107638776302,
      0.0205652117729187,
      -0.015429383143782616,
      0.06674189120531082,
      -0.04501662030816078,
      -0.0347914844751358,
      0.00416215043514967,
      0.009704465046525002,
      -0.05051993578672409,
      -0.014837391674518585,
      -0.0560612715780735,
      -0.018627479672431946,
      0.011638900265097618,
      0.05172770470380783,
      0.015008472837507725,
      -0.03977581113576889,
      -0.09837566316127777,
      -0.022283026948571205,
      -0.005362757481634617,
      -0.008380640298128128
    ],
    [
      -0.049545254558324814,
      -0.06349185109138489,
      0.0028551968280225992,
      0.1383921504020691,
      -0.02543502487242222,
      -0.08224958926439285,
      0.03820570930838585,
      0.020313333719968796,
      0.03249415382742882,
      -0.039824534207582474,
      -0.06548246741294861,
      -0.015800418332219124,
      0.008134308271110058,
      0.025825172662734985,
      0.0024676171597093344,
      -0.00482130004093051,
      0.0015174231957644224,
      -0.058598991483449936,
      0.04910244792699814,
      0.01738896407186985,
      0.04353998228907585,
      -0.002917095087468624,
      -0.057056207209825516,
      0.0049282582476735115,
      0.0024880466517060995,
      -0.019044535234570503,
      -0.02480136975646019,
      0.04607595130801201,
      0.06174211949110031,
      0.008952719159424305,
      0.053480248898267746,
      -0.04683868959546089,
      -0.05418497323989868,
      0.04271814227104187,
      -0.008714151568710804,
      0.029743462800979614,
      0.014552926644682884,
      -0.03075161576271057,
      0.06738962233066559,
      -0.02276070974767208,
      0.01364594604820013,
      0.017122937366366386,
      -0.027165405452251434,
      -0.07573681324720383,
      -0.017461217939853668,
      -0.014627613127231598,
      0.0025818420108407736,
      -0.09787895530462265,
      0.04022233560681343,
      0.02925536036491394,
      -0.122243233025074,
      0.06629869341850281,
      -0.00434485450387001,
      -0.052376024425029755,
      0.004434266127645969,
      -0.04871160164475441,
      -0.03501236438751221,
      0.021433591842651367,
      0.018404560163617134,
      -0.05665198341012001,
      -0.04202709347009659,
      0.03551528975367546,
      -0.023762809112668037,
      0.04810278117656708,
      0.03709165006875992,
      0.047355081886053085,
      0.005146313924342394,
      0.030061595141887665,
      -0.001035869587212801,
      -0.009469132870435715,
      0.058071479201316833,
      0.02760879509150982,
      -0.06021991744637489,
      -0.039691537618637085,
      -0.016227100044488907,
      -0.028492923825979233,
      0.02207942306995392,
      -0.044405024498701096,
      -0.09171765297651291,
      -0.015344361774623394,
      -0.05739738419651985,
      -0.05235987901687622,
      -0.08404511213302612,
      -0.041609764099121094,
      -0.08209044486284256,
      0.03580236807465553,
      0.011029491201043129,
      0.013714587315917015,
      0.05231519043445587,
      -0.0315268337726593,
      0.13104672729969025,
      0.02545490860939026,
      0.014455377124249935,
      -0.055165115743875504,
      -0.0474432036280632,
      -0.026771722361445427,
      -0.0785580426454544,
      0.058372337371110916,
      -0.03489532321691513,
      -0.005509643815457821,
      0.041490379720926285,
      0.01722053810954094,
      0.06513969600200653,
      0.06265036761760712,
      0.020016247406601906,
      0.029695041477680206,
      -0.015797609463334084,
      -0.04559478163719177,
      -0.015572345815598965,
      -0.03661058098077774,
      0.01858489401638508,
      -0.03622869774699211,
      -0.03511866554617882,
      0.07490437477827072,
      0.053112324327230453,
      0.05695117637515068,
      0.021724361926317215,
      -0.01623370684683323,
      0.05934470519423485,
      0.019453590735793114,
      0.0762973427772522,
      0.018467977643013,
      -0.012965960428118706,
      -0.0455496609210968,
      0.053743548691272736,
      -0.014616286382079124,
      0.1158144623041153,
      0.022185280919075012,
      0.04228489100933075,
      -0.01678764633834362,
      0.08279938250780106,
      -0.009116513654589653,
      0.03962256386876106,
      -0.0962829738855362,
      -0.016409339383244514,
      0.03179970756173134,
      0.028695957735180855,
      0.04328417778015137,
      0.013964795507490635,
      -0.09710406512022018,
      0.0076308343559503555,
      -0.020264487713575363,
      0.03709310665726662,
      0.009581372141838074,
      0.02252817340195179,
      -0.027015168219804764,
      -0.05087999254465103,
      -0.003829613793641329,
      -0.0970417931675911,
      -0.0209470484405756,
      -0.045202091336250305,
      -0.0685836598277092,
      0.03354958817362785,
      -0.008062301203608513,
      -0.045419689267873764,
      -0.03227231651544571,
      0.025883974507451057,
      0.03235844150185585,
      0.11725187301635742,
      -0.01905338652431965,
      0.012966939248144627,
      0.020503822714090347,
      -0.07116012275218964,
      0.015529404394328594,
      0.03747818246483803,
      -0.004461411852389574,
      0.037307459861040115,
      -0.030429352074861526,
      0.026661137118935585,
      -0.011949078179895878,
      0.06725418567657471,
      0.008954491466283798,
      -0.0490243025124073,
      0.021890975534915924,
      0.021625297144055367,
      0.10282716900110245,
      -0.013833743520081043,
      0.05844629555940628,
      0.021169578656554222,
      -0.03559209406375885,
      -0.048029668629169464,
      0.01628004014492035,
      -0.09783909469842911,
      0.03219245746731758,
      0.019823655486106873,
      0.017312319949269295,
      0.0075307851657271385,
      -0.02908352017402649,
      0.03661274537444115,
      0.010601010173559189,
      -0.03631441667675972,
      -0.038908496499061584,
      -0.023298081010580063,
      -0.0065454659052193165,
      0.005649711471050978,
      0.0013346308842301369,
      -0.015173093415796757,
      0.01716618239879608,
      0.062099993228912354,
      0.028755150735378265,
      -0.05981598421931267,
      -0.0021645508240908384,
      -0.09298987686634064,
      -0.0093849403783679,
      -0.0002987436600960791,
      0.006383161060512066,
      -0.08301235735416412,
      -0.009895190596580505,
      -0.01849282532930374,
      0.1286979615688324,
      0.03730572760105133,
      -0.048875946551561356,
      0.0438302718102932,
      -0.018915604799985886,
      0.04449273645877838,
      -0.03436907380819321,
      -0.007575697731226683,
      0.01387086696922779,
      0.01171849761158228,
      -0.01920928619801998,
      -0.02994425967335701,
      -0.017761167138814926,
      0.026893405243754387,
      0.0017726946389302611,
      0.013138821348547935,
      -0.06791462749242783,
      0.015602296218276024,
      0.04370653256773949,
      -0.05187779292464256,
      -0.026337964460253716,
      0.03308388218283653,
      -0.0688580870628357,
      0.013412770815193653,
      0.08941604942083359,
      -0.040152452886104584,
      -0.044676728546619415,
      0.010286814533174038,
      -0.03988694027066231,
      -0.06366219371557236,
      0.017123015597462654,
      -0.0019851436372846365,
      -0.05647880956530571,
      -0.07138394564390182,
      0.03204955533146858,
      0.11464741826057434,
      -0.060689423233270645,
      -0.016685614362359047,
      0.02676265500485897,
      0.04516085237264633,
      0.005550025030970573,
      0.005268414039164782,
      0.010806368663907051,
      -0.02616591937839985,
      0.045447178184986115,
      0.05645294860005379,
      0.006708294153213501,
      0.05140279605984688,
      0.014571653679013252,
      -0.03222997486591339,
      0.09380032867193222,
      0.060261040925979614,
      0.030641600489616394,
      0.03635506331920624,
      0.011262165382504463,
      -0.07445944845676422,
      0.002333945594727993,
      -0.03556340932846069,
      0.13054338097572327,
      0.021992037072777748,
      0.05315510556101799,
      0.01095325592905283,
      -0.06672028452157974,
      0.0009271238814108074,
      -0.014292069710791111,
      0.03730263561010361,
      0.06147410348057747,
      -0.06227680668234825,
      -0.0017157697584480047,
      0.00754398200660944,
      0.03140890598297119,
      0.010887062177062035,
      -0.04558935388922691,
      -0.014538802206516266,
      0.027494950219988823,
      -0.08972522616386414,
      -0.02131296508014202,
      0.028381608426570892,
      0.03030889853835106,
      -0.010245256125926971,
      0.001592810614965856,
      0.07497715950012207,
      0.03576725721359253,
      -0.00785418413579464,
      -0.03799951449036598,
      0.030342621728777885,
      -0.06829389184713364,
      -0.05961702764034271,
      0.0015513889957219362,
      0.10032383352518082,
      -0.00822566170245409,
      0.07388869673013687,
      -0.05151214823126793,
      0.008147803135216236,
      0.026284227147698402,
      -0.08909773081541061,
      0.027922870591282845,
      -0.061483610421419144,
      -0.07264161854982376,
      -0.030557403340935707,
      0.02974984236061573,
      0.09550012648105621,
      -0.011470294557511806,
      -0.024196520447731018,
      -0.05835334211587906,
      -0.021143823862075806,
      -0.005699231754988432,
      0.010835192166268826,
      0.0025875477585941553,
      0.0026676361449062824,
      0.010555337183177471,
      -0.06887342780828476,
      -0.06027476489543915,
      -0.026831191033124924,
      -0.04669877141714096,
      0.00477316789329052,
      -0.03410341590642929,
      0.03460044786334038,
      0.026445705443620682,
      -0.043993156403303146,
      -0.09756211936473846,
      -0.00769495265558362,
      0.04972471296787262,
      -0.01939012110233307,
      0.023359911516308784,
      0.026705190539360046,
      0.0023444481194019318,
      -0.04431258514523506,
      0.04651914909482002,
      0.03195975720882416,
      0.01505560614168644,
      0.0395343191921711,
      0.03977544978260994,
      0.0039123231545090675,
      0.028742007911205292,
      0.049184203147888184,
      -0.002304518362507224,
      -0.017039010301232338,
      0.023624053224921227,
      -0.044321756809949875,
      0.016232796013355255,
      -0.058827947825193405,
      -0.01014141459017992,
      -0.03390408679842949,
      0.10104455798864365,
      0.040399424731731415,
      -0.029324600473046303,
      -0.017102409154176712,
      0.005830495618283749,
      -0.04469979554414749,
      0.00343084754422307,
      -0.000773516483604908,
      -0.005210192408412695,
      0.0020078702364116907,
      -0.015194656327366829,
      -0.02100333757698536,
      -0.05922561138868332,
      -0.0566246435046196,
      -0.0166767667979002,
      -0.028404168784618378,
      -0.060650534927845,
      -0.016711410135030746,
      -0.004742531105875969,
      0.06532386690378189,
      -0.05104093253612518,
      -0.028286319226026535,
      0.0049767643213272095,
      0.0244195144623518,
      -0.03497116267681122,
      0.04477044567465782,
      -0.03483908995985985,
      0.13749876618385315,
      0.07304150611162186,
      -0.00647374615073204,
      0.09435594826936722,
      -0.01166398823261261,
      0.09004693478345871,
      -0.044640932232141495,
      0.039516154676675797,
      0.04093651473522186,
      -0.011485693044960499,
      -0.02687538042664528,
      -0.0013640050310641527,
      -0.016523463651537895,
      0.04609156772494316,
      0.04702179878950119,
      0.008498531766235828,
      0.036586079746484756,
      0.04881450533866882,
      0.05249826982617378,
      -0.060685962438583374,
      -0.0015826482558622956,
      0.0047159758396446705,
      0.04974467307329178,
      0.014521914534270763,
      0.0696788802742958,
      -0.05191640928387642,
      0.0419139638543129,
      -0.041567932814359665,
      0.0647452175617218,
      -0.06203203648328781,
      -0.08250149339437485,
      0.03261968865990639,
      0.03627113997936249,
      0.03385716304183006,
      0.013161232694983482,
      -0.1604909896850586,
      0.04386276751756668,
      -0.0024066113401204348,
      -0.08561187237501144,
      0.028775423765182495,
      0.005701493937522173,
      0.0014345458475872874,
      0.014455528929829597,
      -0.02065095491707325,
      -0.047149669378995895,
      0.05414832383394241,
      -0.05271877720952034,
      -0.0012530360836535692,
      0.03261573612689972,
      -0.06143372133374214,
      0.0013238112442195415,
      -0.06845669448375702,
      0.02414475940167904,
      -0.05019001290202141,
      -0.04008499160408974,
      -0.035373199731111526,
      -0.03523100167512894,
      -0.0030959260184317827,
      0.03779515624046326,
      0.021253909915685654,
      0.08603393286466599,
      0.006522689945995808,
      -0.005610163789242506,
      0.04384951293468475,
      0.026730865240097046,
      0.056143518537282944,
      -0.05677488073706627,
      -0.036464665085077286,
      -0.03701792284846306,
      0.026206299662590027,
      -0.01769302226603031,
      -0.036173127591609955,
      -0.0610852874815464,
      0.006945035420358181,
      -0.031466469168663025,
      -0.041958827525377274,
      0.020969688892364502,
      -0.026144908741116524,
      -0.04025784507393837,
      0.002351630013436079,
      0.053757160902023315,
      0.003806239925324917,
      -0.018878744915127754,
      0.015035510063171387,
      0.0981583297252655,
      0.00450889253988862,
      0.016604270786046982,
      -0.03926519304513931,
      0.0366416834294796,
      0.064267598092556,
      0.016056744381785393,
      0.005716294981539249,
      -0.07195896655321121,
      -0.06740576028823853,
      0.06495289504528046,
      0.011864297091960907,
      0.07242018729448318,
      0.06189407780766487,
      -0.0731932744383812,
      -0.004069993272423744,
      0.01576077751815319,
      0.00931927002966404,
      -0.014966916292905807,
      0.07085723429918289,
      -0.021185774356126785,
      -0.03964228928089142,
      0.014633550308644772,
      0.06187615171074867,
      0.025483449921011925,
      -0.05348611995577812,
      0.013295715674757957,
      -0.0424477644264698,
      -0.028982441872358322,
      -0.022354312241077423,
      -0.019055962562561035,
      -0.04309679567813873,
      -0.011919091455638409,
      0.004924285225570202,
      -0.0467037558555603,
      -0.048349760472774506,
      -0.0057167671620845795,
      -0.013784478418529034,
      -0.020408399403095245
    ],
    [
      -0.041257593780756,
      -0.019989272579550743,
      -0.07779822498559952,
      0.0010776591952890158,
      0.024598833173513412,
      -0.08106473088264465,
      -0.07057958841323853,
      0.05931967869400978,
      -0.041340991854667664,
      -0.04274718090891838,
      0.0057303160429000854,
      -0.016920192167162895,
      -0.04251568391919136,
      -0.011633678339421749,
      -0.08066646009683609,
      0.0035483455285429955,
      0.019136808812618256,
      0.061826035380363464,
      0.03691085800528526,
      0.006319786887615919,
      0.04382495954632759,
      -0.020698530599474907,
      0.05689112842082977,
      -0.03860948607325554,
      0.0259073656052351,
      -0.024211250245571136,
      -0.003836417570710182,
      0.06280094385147095,
      -0.016454147174954414,
      -0.059724316000938416,
      -0.019095201045274734,
      0.01857578195631504,
      -0.012680694460868835,
      -0.07787171006202698,
      -0.03913829103112221,
      -0.035721808671951294,
      0.03293877840042114,
      -0.03036436438560486,
      -0.07673001289367676,
      -0.06310747563838959,
      -0.014866349287331104,
      -0.03939623758196831,
      0.031948719173669815,
      -0.07939562201499939,
      0.06116658076643944,
      -0.019306691363453865,
      0.08681074529886246,
      -0.018744714558124542,
      -0.05579949542880058,
      -0.015848862007260323,
      0.018295826390385628,
      0.013100652024149895,
      -0.05804124101996422,
      0.017859037965536118,
      0.035168956965208054,
      0.03836436942219734,
      0.009671409614384174,
      0.0016317171975970268,
      0.046789877116680145,
      -0.02448461204767227,
      0.0015625192318111658,
      -0.00850038044154644,
      0.027938568964600563,
      0.04182516783475876,
      0.06877579540014267,
      0.0057095433585345745,
      0.06745331734418869,
      -0.11119073629379272,
      0.011945648118853569,
      0.00016264086298178881,
      0.08237256854772568,
      -0.06590474396944046,
      -0.011060412973165512,
      -0.017565201967954636,
      0.020848829299211502,
      -0.02798520401120186,
      0.016602449119091034,
      -0.03589516505599022,
      0.10553911328315735,
      0.07544945925474167,
      -0.04033266007900238,
      0.03283293545246124,
      0.0276449304074049,
      -0.0363745242357254,
      0.0015118056908249855,
      -0.0031342313159257174,
      -0.021384982392191887,
      -0.0457233265042305,
      -0.03999745845794678,
      0.02466720901429653,
      -0.034511443227529526,
      -0.010491992346942425,
      0.02142905443906784,
      -0.008357440121471882,
      0.04669221118092537,
      -0.028684496879577637,
      0.024555929005146027,
      0.009590798057615757,
      0.013382558710873127,
      -0.01421179249882698,
      0.054861925542354584,
      -0.03750331699848175,
      0.03870333731174469,
      0.10889893025159836,
      0.010334638878703117,
      -0.011954681016504765,
      -0.0095975985750556,
      0.0014198666904121637,
      0.024272017180919647,
      -0.059923458844423294,
      -0.011929520405828953,
      0.05290338769555092,
      -0.04239964857697487,
      -0.0633760392665863,
      -0.03396611660718918,
      -0.038201142102479935,
      0.02153603918850422,
      -0.040842074900865555,
      -0.10364357382059097,
      0.08117777854204178,
      0.037357937544584274,
      0.0008385084802284837,
      0.056978628039360046,
      -0.005162332206964493,
      -0.0745101124048233,
      0.0072783660143613815,
      -0.04500224068760872,
      0.011295098811388016,
      -0.08891914039850235,
      0.06296766549348831,
      -0.015611930750310421,
      -0.06552030891180038,
      -0.03929273411631584,
      -0.00820426270365715,
      0.05042169243097305,
      0.04759424179792404,
      0.0205390527844429,
      -0.05476943030953407,
      -0.008156709372997284,
      0.04882904142141342,
      -0.029691100120544434,
      -0.03040972910821438,
      0.025114575400948524,
      0.057203687727451324,
      0.023618921637535095,
      -0.005327984690666199,
      0.008956115692853928,
      -0.014148812741041183,
      -0.05752580612897873,
      -0.004318342078477144,
      -0.04834480956196785,
      0.0024670579005032778,
      0.015085778199136257,
      0.011250021867454052,
      0.02378534898161888,
      0.06230790913105011,
      0.019423119723796844,
      -0.0805978924036026,
      -0.01609915681183338,
      -0.03784969449043274,
      0.01974909007549286,
      -0.04714120924472809,
      0.0018621650524437428,
      0.044079892337322235,
      -0.012514211237430573,
      -0.06598415970802307,
      -0.02340468019247055,
      -0.03795000910758972,
      -0.015986105427145958,
      -0.04123940318822861,
      -0.04926992952823639,
      0.021659154444932938,
      -0.04136456176638603,
      -0.027417518198490143,
      0.09557942301034927,
      0.06440934538841248,
      0.09197449684143066,
      -0.0025813716929405928,
      0.00952787697315216,
      -0.003017870942130685,
      -0.022101422771811485,
      0.012062820605933666,
      -0.0090898172929883,
      -0.036145225167274475,
      0.01746952533721924,
      -0.01113197673112154,
      -0.009933150373399258,
      -0.02450418844819069,
      -0.033691827207803726,
      0.003831849666312337,
      0.0009982987539842725,
      0.007183727342635393,
      -0.021852437406778336,
      0.034823548048734665,
      0.03728989139199257,
      -0.012069293297827244,
      -0.020809099078178406,
      0.048739854246377945,
      -0.03336484357714653,
      0.027132881805300713,
      -0.03709806874394417,
      -0.0565246120095253,
      0.028861606493592262,
      -0.006426313426345587,
      -0.04589596018195152,
      0.015199942514300346,
      -0.0529920756816864,
      -0.023588769137859344,
      -0.04079647734761238,
      0.018909726291894913,
      0.02647263929247856,
      0.01815023273229599,
      -0.08141423761844635,
      -0.06112954020500183,
      -0.08269904553890228,
      -0.03005806729197502,
      0.05590764433145523,
      -0.09057942032814026,
      -0.023646535351872444,
      0.015954799950122833,
      0.012655348517000675,
      -0.009490610100328922,
      0.0050888569094240665,
      0.03012780472636223,
      0.005338416434824467,
      0.004012429155409336,
      0.03940248116850853,
      0.0773276835680008,
      -0.037399839609861374,
      -0.022954627871513367,
      -0.03255130723118782,
      -0.0380818173289299,
      0.0343429259955883,
      0.023506367579102516,
      0.014583745039999485,
      -0.04708939418196678,
      -0.00436140364035964,
      -0.019172899425029755,
      -0.053113412111997604,
      -0.0016987097915261984,
      0.012560056522488594,
      0.06838058680295944,
      -0.09058712422847748,
      -0.05076470598578453,
      -0.02612534537911415,
      0.05661053955554962,
      -0.035775285214185715,
      -0.003472598036751151,
      -0.03091685101389885,
      0.09583479911088943,
      0.030341804027557373,
      -0.0076768905855715275,
      0.029052283614873886,
      -0.05304241180419922,
      0.02826877124607563,
      0.022894512861967087,
      0.028956741094589233,
      -0.03172861784696579,
      0.007227999623864889,
      0.06210727244615555,
      -0.022041812539100647,
      0.030827118083834648,
      0.10917232930660248,
      0.0045946999453008175,
      0.0450376458466053,
      0.01116375345736742,
      0.027726389467716217,
      -0.0032973908819258213,
      -0.055660445243120193,
      0.013248317874968052,
      -0.01926751434803009,
      0.00210676365531981,
      0.1501450538635254,
      0.023624766618013382,
      -0.058830201625823975,
      0.03505963459610939,
      -0.023599805310368538,
      0.009108505211770535,
      -0.08062423020601273,
      0.04010890796780586,
      0.011109781451523304,
      0.04932226240634918,
      0.05045737326145172,
      -0.07880627363920212,
      0.03169398009777069,
      -0.016270313411951065,
      -0.04422908276319504,
      -0.010934584774076939,
      0.057880859822034836,
      0.0646054595708847,
      -0.09251198172569275,
      0.057329706847667694,
      0.004599485546350479,
      -0.06961143016815186,
      -0.025045856833457947,
      -0.022511694580316544,
      -0.03346734866499901,
      0.04247589036822319,
      0.04538443312048912,
      0.07123440504074097,
      0.07527577131986618,
      0.08603040128946304,
      0.0448269285261631,
      0.05048249661922455,
      -0.10063859075307846,
      0.07063712179660797,
      0.007037500850856304,
      0.05706295371055603,
      0.052907343953847885,
      0.042901184409856796,
      -0.015191032551229,
      0.08668912947177887,
      -0.0027437282260507345,
      0.002481969306245446,
      0.02147822640836239,
      0.0689808577299118,
      0.013918370939791203,
      0.04490220546722412,
      0.052066534757614136,
      0.004637233447283506,
      -0.02584451250731945,
      -0.05370086058974266,
      0.015954257920384407,
      -0.03824266791343689,
      0.023417644202709198,
      0.03424565866589546,
      0.07610496133565903,
      0.010411547496914864,
      -0.05920669808983803,
      0.007725359871983528,
      -0.047679685056209564,
      0.004695693962275982,
      0.09979300200939178,
      0.09814392775297165,
      0.05061138793826103,
      -0.03596086800098419,
      -0.06717634201049805,
      0.08939016610383987,
      0.057108037173748016,
      0.04457337036728859,
      0.006996494252234697,
      0.04926895350217819,
      0.06688780337572098,
      -0.07181189954280853,
      0.11798033118247986,
      0.027384497225284576,
      -0.020487800240516663,
      0.09851484000682831,
      -0.06597955524921417,
      -0.09363164007663727,
      -0.0009811773197725415,
      -0.05804155021905899,
      0.01753353327512741,
      -0.03404070809483528,
      0.03345786780118942,
      0.03872276470065117,
      0.04614972695708275,
      -0.03562552481889725,
      0.01825590431690216,
      -0.008420065976679325,
      0.07357674092054367,
      0.02762865275144577,
      -0.04093412309885025,
      -0.04469072446227074,
      0.012008586898446083,
      0.028923464938998222,
      0.017207268625497818,
      -0.008016507141292095,
      -0.01273050531744957,
      0.022381357848644257,
      -0.029287993907928467,
      0.006907614879310131,
      -0.013027109205722809,
      0.02606610767543316,
      0.002748208586126566,
      -0.06605622917413712,
      -0.06597191095352173,
      -0.03194228187203407,
      0.056210774928331375,
      -0.006704950239509344,
      -0.07876735180616379,
      0.023138344287872314,
      -0.07347849756479263,
      -0.035535793751478195,
      -0.016771389171481133,
      -0.04783907160162926,
      -0.058511484414339066,
      0.03338104113936424,
      -0.07702019065618515,
      0.08302368223667145,
      0.008535516448318958,
      -0.039042748510837555,
      0.011332247406244278,
      -0.0003057759895455092,
      -0.08233704417943954,
      0.06317223608493805,
      -0.002494903514161706,
      -0.029452791437506676,
      0.04949606582522392,
      -0.021203160285949707,
      0.007741317618638277,
      0.03481699898838997,
      0.0194572601467371,
      -0.041242536157369614,
      0.04195358604192734,
      0.037719015032052994,
      0.06673085689544678,
      0.09062021970748901,
      -0.07475434243679047,
      0.011208636686205864,
      0.008765270002186298,
      -0.09919886291027069,
      -0.031624265015125275,
      -0.009556152857840061,
      -0.0757969468832016,
      0.04525860399007797,
      0.07903998345136642,
      -0.045842647552490234,
      -0.04416260868310928,
      0.05821159854531288,
      0.04317791759967804,
      0.027157513424754143,
      -0.002214524196460843,
      -0.041040919721126556,
      -0.0019765240140259266,
      0.05375147983431816,
      -0.006293528247624636,
      0.07488946616649628,
      -0.00010655652295099571,
      -0.01466735266149044,
      -0.08740971982479095,
      -0.02370319701731205,
      0.07643423229455948,
      0.09247922897338867,
      0.03188936039805412,
      -0.028585484251379967,
      0.044045791029930115,
      0.054019249975681305,
      -0.011197417043149471,
      -0.008995331823825836,
      -0.08627412468194962,
      -0.044273313134908676,
      0.03040219470858574,
      0.0650828555226326,
      0.031455155462026596,
      0.01949666067957878,
      -0.0020502093248069286,
      0.051787883043289185,
      0.011153055354952812,
      0.07926656305789948,
      -0.011458725668489933,
      -0.012929840013384819,
      0.003875509137287736,
      -0.07162550836801529,
      -0.04655972868204117,
      -0.009474067017436028,
      0.013333274982869625,
      0.02752341702580452,
      -0.015140378847718239,
      -0.0495598129928112,
      0.014157748781144619,
      0.0445130281150341,
      0.008543630130589008,
      0.07244361937046051,
      0.015957653522491455,
      0.10187865048646927,
      0.019801003858447075,
      -0.05228176340460777,
      -0.04667537286877632,
      0.032421816140413284,
      0.0006264118710532784,
      0.03613731637597084,
      -0.0206385999917984,
      0.04365267977118492,
      -0.006907281000167131,
      0.017295459285378456,
      0.05011582747101784,
      0.0802082046866417,
      -0.0025725143495947123,
      0.01713031530380249,
      -0.01347736082971096,
      0.022319922223687172,
      -0.045945484191179276,
      0.004111950285732746,
      -0.09579180181026459,
      -0.0065962886437773705,
      0.0719592422246933,
      -0.0001956688065547496,
      0.05010470747947693,
      0.007736744824796915,
      0.017007717862725258,
      -0.05575280264019966,
      -0.06057325378060341,
      -0.018295209854841232,
      0.012181541882455349,
      0.03999415412545204,
      0.10244593769311905,
      -0.06456214934587479,
      -0.07666973024606705,
      0.03953346610069275,
      -0.02001073770225048,
      0.03750310093164444,
      -0.11140110343694687
    ],
    [
      0.08299974352121353,
      0.05882403627038002,
      -0.013462926261126995,
      0.0714465081691742,
      0.023701045662164688,
      -0.049460042268037796,
      -0.07207060605287552,
      -0.009262647479772568,
      0.02781238779425621,
      0.05159962549805641,
      0.06970610469579697,
      -0.03384749963879585,
      0.02832622267305851,
      0.006426425185054541,
      0.024239378049969673,
      0.017800278961658478,
      0.06484819203615189,
      -0.047190044075250626,
      -0.008783506229519844,
      -0.04793357849121094,
      0.028832975775003433,
      -0.022326717153191566,
      0.058471038937568665,
      0.05278554931282997,
      0.012504342012107372,
      0.048625290393829346,
      0.09589391201734543,
      -0.0175755824893713,
      -0.06466226279735565,
      0.040900129824876785,
      -0.061347030103206635,
      0.012164720334112644,
      0.07122665643692017,
      0.018618861213326454,
      -0.04827266186475754,
      -0.016301557421684265,
      -0.03149319067597389,
      0.020696276798844337,
      0.061125777661800385,
      -0.06418583542108536,
      0.009179859422147274,
      -0.028887461870908737,
      -0.003278413088992238,
      0.018605487421154976,
      0.04666250944137573,
      0.03195338696241379,
      0.011944130063056946,
      -0.04594308137893677,
      0.09960450232028961,
      0.0009539234451949596,
      -0.05745183676481247,
      0.09864988923072815,
      -0.048326365649700165,
      0.057604167610406876,
      0.039282314479351044,
      -0.0960901752114296,
      0.026042219251394272,
      0.04239468649029732,
      0.0290838573127985,
      -0.006075762677937746,
      0.0002300901833223179,
      0.06663583219051361,
      0.004941022954881191,
      -0.02769857458770275,
      0.07428525388240814,
      -0.017890146002173424,
      -0.011886144056916237,
      0.0013099421048536897,
      -0.02013516239821911,
      0.030515436083078384,
      -0.08180361986160278,
      -0.07283669710159302,
      -0.041351430118083954,
      0.05357779935002327,
      -0.019928747788071632,
      0.013459064066410065,
      -0.03343604877591133,
      0.03324513882398605,
      0.08150292187929153,
      0.09761060029268265,
      0.005217900965362787,
      -0.009291993454098701,
      0.03661602735519409,
      0.026055440306663513,
      -0.05290653184056282,
      -0.0218524057418108,
      -0.001968738157302141,
      -0.0014108135364949703,
      -0.008864451199769974,
      0.044264305382966995,
      -0.07930247485637665,
      -0.0691409483551979,
      0.009622687473893166,
      -0.08956088125705719,
      0.018566787242889404,
      -0.0996263399720192,
      -0.028183529153466225,
      0.02769320085644722,
      0.03224505856633186,
      -0.020066089928150177,
      -0.019150765612721443,
      0.05044698342680931,
      -0.000935256714001298,
      0.0656728595495224,
      -0.04443494603037834,
      -0.024139441549777985,
      -0.0272147785872221,
      0.010671344585716724,
      -0.004041009116917849,
      0.07951822876930237,
      -0.08186142146587372,
      0.08180654048919678,
      -0.01838679239153862,
      0.049331869930028915,
      -0.03377493843436241,
      0.007677664048969746,
      -0.10025336593389511,
      0.10931994765996933,
      0.012823538854718208,
      -0.08745281398296356,
      0.04335338622331619,
      -0.011394456028938293,
      0.001971673220396042,
      0.002576047321781516,
      0.008240029215812683,
      -0.019202610477805138,
      0.08135606348514557,
      0.0002852656180039048,
      0.03443117067217827,
      -0.03763144463300705,
      -0.02699880301952362,
      -0.030801206827163696,
      -0.011289865709841251,
      0.0050693717785179615,
      -0.0532141849398613,
      -0.04215520992875099,
      -0.12991783022880554,
      0.06162254512310028,
      -0.027226528152823448,
      0.019729701802134514,
      -0.003446081653237343,
      -0.02870752103626728,
      -0.04929923266172409,
      0.04982789605855942,
      -0.03722807392477989,
      -0.01481980923563242,
      0.02377757616341114,
      0.04845370724797249,
      -0.012828954495489597,
      -0.08153042197227478,
      -0.00015279751096386462,
      0.054491765797138214,
      0.0049451482482254505,
      -0.06933866441249847,
      -0.05104958266019821,
      -0.07700768113136292,
      -0.04074768349528313,
      0.01986449770629406,
      0.02462553046643734,
      0.010090396739542484,
      0.016850847750902176,
      -0.11407673358917236,
      -0.019169138744473457,
      0.024325935170054436,
      -0.041190166026353836,
      -0.012162867933511734,
      -0.004600927699357271,
      -0.043389711529016495,
      -0.09393970668315887,
      -0.005331218242645264,
      -0.03383094072341919,
      0.0416712760925293,
      -0.004511079750955105,
      0.045457977801561356,
      0.011480401270091534,
      -0.038710493594408035,
      0.012832642532885075,
      -0.04887661337852478,
      -0.08080769330263138,
      -0.003633527783676982,
      -0.10319335013628006,
      -0.012771183624863625,
      -0.08721111714839935,
      -0.0893716812133789,
      -0.00022505676315631717,
      -0.02368094213306904,
      -0.023562366142868996,
      6.542689516209066e-05,
      0.037774644792079926,
      -0.034302785992622375,
      -0.0001314980909228325,
      -0.03195615112781525,
      0.05643238499760628,
      0.014677491039037704,
      -0.04966616630554199,
      -0.007610816042870283,
      0.02663068100810051,
      0.012335741892457008,
      0.03078475221991539,
      0.011684228666126728,
      -0.008433708921074867,
      -0.009328682906925678,
      -0.037117455154657364,
      0.08485741168260574,
      0.019653581082820892,
      0.03303098678588867,
      0.012706506997346878,
      0.005071807652711868,
      0.018992921337485313,
      0.011602594517171383,
      -0.06907002627849579,
      -0.03568243607878685,
      0.04758957400918007,
      0.06156144663691521,
      0.04367111995816231,
      0.02037869393825531,
      -0.042472660541534424,
      0.010274878703057766,
      -0.025310339406132698,
      0.015318505465984344,
      -0.07343064248561859,
      -0.021696578711271286,
      0.10361173003911972,
      0.05047246813774109,
      0.07674576342105865,
      -0.0036532145459204912,
      -0.0063905781134963036,
      0.10018051415681839,
      -0.006663624197244644,
      0.005946088116616011,
      0.014652641490101814,
      0.027220383286476135,
      -0.04194459691643715,
      0.055144231766462326,
      -0.06012467294931412,
      -0.018546024337410927,
      0.014530378393828869,
      -0.05621064081788063,
      -0.006249552592635155,
      -0.08003932982683182,
      -0.03653155639767647,
      0.011251677758991718,
      0.010221797041594982,
      0.0009604711667634547,
      -0.0408640094101429,
      0.008517231792211533,
      -0.02638271450996399,
      -0.017207177355885506,
      0.008789116516709328,
      -0.0031128793489187956,
      0.04486066848039627,
      0.019911954179406166,
      0.022180983796715736,
      -0.0665203407406807,
      -0.021089477464556694,
      -0.055344194173812866,
      0.014843180775642395,
      0.04379260912537575,
      -0.041383907198905945,
      -0.023912381380796432,
      -0.011158760637044907,
      -0.03837139159440994,
      0.013892053626477718,
      0.09394808113574982,
      -0.007795020472258329,
      -0.06267226487398148,
      -0.10605084151029587,
      -0.034069523215293884,
      0.044513314962387085,
      0.052245572209358215,
      0.00731731578707695,
      -0.02711036615073681,
      0.03277193754911423,
      -0.01761227287352085,
      0.1003756895661354,
      -0.07619114220142365,
      -0.11980753391981125,
      -0.09819511324167252,
      -0.006531730759888887,
      -0.001560236793011427,
      0.007109218742698431,
      0.07155093550682068,
      0.02063542604446411,
      0.017145439982414246,
      0.045067790895700455,
      -0.004078341647982597,
      0.02544933371245861,
      -0.03985418379306793,
      0.006315594073385,
      0.066047802567482,
      0.03351046144962311,
      0.0665464848279953,
      -0.05620170012116432,
      0.012595255859196186,
      -0.016266614198684692,
      -0.02916876971721649,
      -0.004209993407130241,
      -0.030428513884544373,
      0.033784542232751846,
      -0.019062038511037827,
      -0.03843499720096588,
      -0.07168217748403549,
      0.04487019404768944,
      0.04043564945459366,
      -0.030255703255534172,
      -0.00939435325562954,
      -0.02699153497815132,
      -0.0719335526227951,
      0.024778535589575768,
      -0.02149180695414543,
      0.015634695068001747,
      0.00662394380196929,
      0.03163202479481697,
      0.04992655664682388,
      -0.020733937621116638,
      -0.1157747358083725,
      0.033249132335186005,
      0.013874216005206108,
      -0.06278210133314133,
      0.02077449858188629,
      -0.018624629825353622,
      0.03676329925656319,
      0.017063794657588005,
      -0.07277830690145493,
      0.09881288558244705,
      0.015089122578501701,
      -0.0028376446571201086,
      0.048079714179039,
      0.037515658885240555,
      -0.037606678903102875,
      0.00695287249982357,
      -0.022511513903737068,
      -0.04252476617693901,
      0.0010745016625151038,
      -0.0738494023680687,
      -0.007192542776465416,
      -0.006074792239814997,
      0.01073006447404623,
      0.022732138633728027,
      0.0923997163772583,
      -0.007655151654034853,
      -0.034015532582998276,
      0.000903487263713032,
      0.015079188160598278,
      -0.0013141597155481577,
      -0.05813739448785782,
      0.02719246782362461,
      -0.03444110229611397,
      -0.04684222489595413,
      -0.0612865574657917,
      -0.024016987532377243,
      0.028808774426579475,
      -0.032381247729063034,
      0.056492626667022705,
      0.02378631941974163,
      0.003931357990950346,
      0.023777058348059654,
      -0.0519791878759861,
      0.03753313794732094,
      0.003241803264245391,
      -0.0029535372741520405,
      -0.038580361753702164,
      0.03814646974205971,
      0.04673640802502632,
      0.05176829174160957,
      0.012334614060819149,
      0.04316262528300285,
      0.022465428337454796,
      0.007291924208402634,
      -0.007250738330185413,
      0.030927401036024094,
      0.03857004642486572,
      -0.03790421783924103,
      0.019233891740441322,
      0.09082777798175812,
      0.04613322392106056,
      -0.06142527237534523,
      -0.11330398917198181,
      0.05137499049305916,
      -0.010273282416164875,
      -0.019663872197270393,
      0.07508911192417145,
      0.04776175320148468,
      -0.04435906931757927,
      -0.032305821776390076,
      0.006514095701277256,
      -0.05448809266090393,
      -0.06660441309213638,
      0.01779073476791382,
      0.009359541349112988,
      0.051142025738954544,
      -0.014979204162955284,
      0.06449712812900543,
      0.059616073966026306,
      0.02638297900557518,
      0.04094403237104416,
      0.02327297441661358,
      -0.0193264689296484,
      -0.07079179584980011,
      -0.06846286356449127,
      0.019839107990264893,
      -0.050302904099226,
      -0.002433634828776121,
      0.040362648665905,
      -0.03961814194917679,
      0.010543527081608772,
      0.0017895469209179282,
      -0.03130253031849861,
      -0.01029619388282299,
      0.005726105533540249,
      -0.01963772252202034,
      0.07649269700050354,
      -0.10611504316329956,
      -0.06076525151729584,
      -0.046800144016742706,
      -0.03014698624610901,
      -0.030258432030677795,
      -0.01828501559793949,
      0.021608006209135056,
      0.03765872120857239,
      0.036299388855695724,
      0.03159176930785179,
      -0.07645049691200256,
      -0.03744645416736603,
      -0.0009636169415898621,
      -0.03257281333208084,
      0.03889079764485359,
      -0.01698676496744156,
      -0.050298139452934265,
      0.06976267695426941,
      -0.09223052114248276,
      -0.020748762413859367,
      -0.08016972988843918,
      -0.003740809392184019,
      0.008191369473934174,
      0.009670618921518326,
      0.005308095365762711,
      0.04571050405502319,
      0.017030788585543633,
      -0.06473202258348465,
      0.0004271683865226805,
      0.04238172620534897,
      0.02455783635377884,
      -0.05805907025933266,
      0.030352860689163208,
      0.04264901205897331,
      0.030647851526737213,
      -0.03095076233148575,
      0.038287073373794556,
      -0.04530470445752144,
      -0.003451316384598613,
      -0.035532645881175995,
      -0.05321730673313141,
      0.05896981433033943,
      0.01706182397902012,
      -0.05359518900513649,
      -0.049523793160915375,
      0.001701732282526791,
      -0.0065500251948833466,
      -0.03274259343743324,
      -0.015513544902205467,
      -0.004012086894363165,
      0.01549083273857832,
      0.03134876862168312,
      0.05123760551214218,
      0.02329825796186924,
      -0.012254898436367512,
      -0.05679641291499138,
      -0.08544064313173294,
      0.012985605746507645,
      -0.011310002766549587,
      0.011705768294632435,
      0.019745372235774994,
      0.051703426986932755,
      -0.06919506192207336,
      0.05190245434641838,
      2.254721403005533e-05,
      -0.07845009863376617,
      -0.05243384465575218,
      -0.010165764018893242,
      0.062546007335186,
      -0.005766109563410282,
      -0.0017016746569424868,
      -0.02745073102414608,
      0.11336362361907959,
      -0.0671333447098732,
      -0.015949968248605728,
      -0.01117254514247179,
      -0.0024518966674804688,
      0.04219970479607582,
      0.010075420141220093,
      -0.06615599989891052,
      0.10143868625164032,
      -0.014289623126387596,
      0.05793103203177452,
      0.034498315304517746,
      -0.04547189548611641,
      0.02468101494014263,
      0.013404395431280136,
      0.003588926512748003,
      -0.02149011939764023,
      -0.0686873346567154,
      -0.011417204514145851
    ],
    [
      -0.00691763823851943,
      -0.025235088542103767,
      0.02474885620176792,
      -0.046755723655223846,
      0.026523850858211517,
      -0.05530741438269615,
      -0.0035488950088620186,
      0.028970086947083473,
      -0.05395101383328438,
      -0.049329906702041626,
      0.05576729774475098,
      -0.038136694580316544,
      -0.035458315163850784,
      -0.03631485626101494,
      0.04376319795846939,
      -0.03488624468445778,
      0.03423134237527847,
      -0.08316419273614883,
      0.008004412986338139,
      -0.002427967032417655,
      -0.038285911083221436,
      -0.030477935448288918,
      -0.03573855385184288,
      -0.02496068924665451,
      -0.051937662065029144,
      -0.06481603533029556,
      -0.0470556914806366,
      0.045089758932590485,
      -0.0325651615858078,
      0.018646053969860077,
      -0.05688203498721123,
      -0.01825222373008728,
      -0.12204422056674957,
      0.09674157202243805,
      0.04920119047164917,
      -0.052903443574905396,
      -0.10455936193466187,
      0.03924257680773735,
      0.005499111022800207,
      -0.054420724511146545,
      0.06317401677370071,
      -0.00727796507999301,
      -0.025632116943597794,
      -0.10141362249851227,
      0.0025489171966910362,
      -0.02466246299445629,
      -0.057556238025426865,
      0.09196233749389648,
      -0.04538046196103096,
      0.006766959093511105,
      -0.01756219193339348,
      0.06988793611526489,
      -0.02297411672770977,
      0.05082881450653076,
      -0.05020210146903992,
      0.08477786183357239,
      0.1457565575838089,
      0.10147957503795624,
      0.00822534505277872,
      0.015805911272764206,
      0.055086467415094376,
      -0.010572624392807484,
      -0.03573329374194145,
      -0.016626892611384392,
      -0.01891820691525936,
      0.05552362650632858,
      0.08859452605247498,
      0.01720241643488407,
      -0.061787866055965424,
      -0.0012885004980489612,
      -0.019639592617750168,
      0.02485515922307968,
      0.043843891471624374,
      0.02650371938943863,
      0.059787120670080185,
      -0.03825076296925545,
      -0.010391533374786377,
      -0.05939745157957077,
      -0.04544023424386978,
      -0.000962903955951333,
      0.003543591359630227,
      0.006489419378340244,
      0.09278765320777893,
      0.044449806213378906,
      0.021570252254605293,
      0.03486572578549385,
      0.006821557879447937,
      -0.03579426556825638,
      0.01489310897886753,
      -0.0003804246080107987,
      0.013506446965038776,
      0.0029804501682519913,
      -0.03947971761226654,
      0.051217157393693924,
      -0.03328409418463707,
      -0.05834364891052246,
      0.04819444566965103,
      0.05440957099199295,
      0.03420398756861687,
      -0.02305670455098152,
      -0.00888139009475708,
      -0.030597832053899765,
      0.040280211716890335,
      -0.05802573636174202,
      0.030284682288765907,
      -0.017428843304514885,
      -0.024600381031632423,
      0.0015385205624625087,
      0.025704937055706978,
      -0.06357765942811966,
      0.012830531224608421,
      0.02372780069708824,
      0.01242666132748127,
      -0.0009078708244487643,
      0.06574945896863937,
      -0.0005914546782150865,
      0.02907712571322918,
      -0.050617486238479614,
      -0.033618662506341934,
      -0.03719919174909592,
      0.07125453650951385,
      0.022555183619260788,
      0.027075311169028282,
      -0.0003435480175539851,
      0.019626880064606667,
      -0.0047353156842291355,
      0.05316019803285599,
      0.01088383886963129,
      0.007723786868155003,
      0.09135464578866959,
      -0.04251033440232277,
      0.001279195654205978,
      0.05058739334344864,
      -0.004807579796761274,
      0.04014395549893379,
      -0.018030377104878426,
      0.025623375549912453,
      0.062052082270383835,
      -0.02618478797376156,
      0.024996845051646233,
      0.0020891495514661074,
      0.062172453850507736,
      -0.03731691837310791,
      -0.009864861145615578,
      -0.04402773454785347,
      -0.07153573632240295,
      0.05043415725231171,
      -0.035817112773656845,
      -0.013635504990816116,
      0.0236250888556242,
      0.023484855890274048,
      0.05918833985924721,
      -0.011271364986896515,
      0.05926301330327988,
      0.04229903593659401,
      0.039092641323804855,
      -0.018628587946295738,
      -0.034992463886737823,
      -0.02187042310833931,
      -0.08728189021348953,
      0.005961334798485041,
      -0.04470574110746384,
      0.04017333686351776,
      0.056397974491119385,
      -0.00748392753303051,
      0.04333655536174774,
      0.04110005125403404,
      0.07943591475486755,
      -0.04974788799881935,
      0.00692098680883646,
      -0.005077721551060677,
      0.0839771255850792,
      0.03614036738872528,
      -0.05881888419389725,
      0.022113656625151634,
      0.01458108052611351,
      -0.002963298000395298,
      0.0030353397596627474,
      0.025638049468398094,
      0.036830879747867584,
      -0.02024761773645878,
      0.015498916618525982,
      0.0070365727879107,
      -0.006270078010857105,
      0.032388463616371155,
      -0.02752719447016716,
      0.05283452197909355,
      -0.026257792487740517,
      0.026672160252928734,
      -0.03663547337055206,
      0.054467856884002686,
      -0.025157347321510315,
      0.02538817934691906,
      -0.05702383071184158,
      0.031205523759126663,
      0.02796825021505356,
      0.031443797051906586,
      0.000478368136100471,
      -0.011599232442677021,
      -0.020415209233760834,
      0.08433795720338821,
      -0.012561893090605736,
      -0.03688601404428482,
      0.002148526953533292,
      -0.015634270384907722,
      0.018124563619494438,
      0.0023563941940665245,
      -0.057671476155519485,
      -0.0771145150065422,
      0.023683303967118263,
      -0.00455560302361846,
      0.005500754341483116,
      -0.042343076318502426,
      0.03880786895751953,
      -0.009334711357951164,
      0.01719687134027481,
      -0.0029061611276119947,
      0.04763678461313248,
      0.017697036266326904,
      0.008377380669116974,
      0.0540156252682209,
      0.0449535958468914,
      -0.013845078647136688,
      0.007338694296777248,
      -0.011938911862671375,
      0.0825091227889061,
      0.008335592225193977,
      0.05571473017334938,
      -0.07317034900188446,
      0.031510140746831894,
      -0.020079268142580986,
      -0.014482066966593266,
      0.03541257232427597,
      0.07659413665533066,
      0.03495783731341362,
      -0.07440437376499176,
      -0.014527526684105396,
      0.03950492665171623,
      -0.023210855200886726,
      -0.06786543130874634,
      -0.06492146849632263,
      0.042052753269672394,
      -0.060909949243068695,
      -0.0006639422499574721,
      0.001386157819069922,
      0.03351293131709099,
      -0.05721313878893852,
      -0.027845105156302452,
      0.021490497514605522,
      0.03306107595562935,
      0.050953298807144165,
      -0.005519300699234009,
      0.0820973739027977,
      0.0483861044049263,
      -0.04428503289818764,
      0.10262247920036316,
      0.10251066833734512,
      -0.06180413439869881,
      0.008014190010726452,
      0.00586832407861948,
      0.13506227731704712,
      0.03595677390694618,
      -0.1368071436882019,
      0.06902868300676346,
      0.04703941568732262,
      0.059236861765384674,
      0.059487681835889816,
      0.016369279474020004,
      0.021487096324563026,
      0.029209019616246223,
      0.06550750136375427,
      -0.009047589264810085,
      0.04782048612833023,
      -0.03783304989337921,
      0.025776656344532967,
      0.05193869024515152,
      -0.044188179075717926,
      0.005974713247269392,
      -0.028057051822543144,
      -0.028162194415926933,
      0.06526587903499603,
      0.018730930984020233,
      0.06994380056858063,
      0.07449792325496674,
      0.0021796361543238163,
      -0.000593588687479496,
      0.02060515061020851,
      -0.0102202408015728,
      0.06444767862558365,
      -0.019226744771003723,
      0.013689201325178146,
      -0.07861672341823578,
      0.017577530816197395,
      0.1042189747095108,
      -0.04542939364910126,
      -0.05406586825847626,
      -0.009001142345368862,
      -0.012216433882713318,
      0.08404611051082611,
      -0.006840025540441275,
      0.03297035023570061,
      -0.07731197774410248,
      -0.025273634120821953,
      0.06138165667653084,
      -0.06727555394172668,
      -0.004038841929286718,
      0.050249774008989334,
      0.00597483990713954,
      0.02808455005288124,
      -0.014799128286540508,
      -0.06833833456039429,
      0.009279986843466759,
      -0.02222367562353611,
      -0.04669717326760292,
      0.0283365361392498,
      0.015519031323492527,
      0.032268963754177094,
      0.024381883442401886,
      -0.043776363134384155,
      0.017318187281489372,
      0.030030671507120132,
      0.019743800163269043,
      0.014727609232068062,
      0.08018354326486588,
      0.11019740998744965,
      -0.0923730880022049,
      0.06016241014003754,
      -0.04373958334326744,
      -0.014383628964424133,
      -0.03961755335330963,
      0.06763888895511627,
      -0.004971887916326523,
      0.0363977886736393,
      -0.01958772912621498,
      -0.048155590891838074,
      -0.025200238451361656,
      -0.0384482778608799,
      0.019561655819416046,
      -0.004416023846715689,
      -0.04875500872731209,
      0.025932127609848976,
      -0.07316393405199051,
      0.02441251464188099,
      0.010841202922165394,
      0.08247455954551697,
      -0.04828381910920143,
      0.005687472876161337,
      -0.020264629274606705,
      0.07991285622119904,
      -0.015473339706659317,
      0.09373556077480316,
      0.021413201466202736,
      -0.0006532492116093636,
      -0.012286225333809853,
      0.06830306351184845,
      0.0380018912255764,
      0.05525077134370804,
      0.02029377967119217,
      0.05396522954106331,
      -0.014457016251981258,
      0.0660691112279892,
      0.021763181313872337,
      0.04768246039748192,
      0.03371233120560646,
      -0.01759178750216961,
      -0.06727965921163559,
      0.021646279841661453,
      0.07862219214439392,
      0.06455698609352112,
      -0.041075486689805984,
      0.018852388486266136,
      -0.0013323898892849684,
      -0.03576868027448654,
      0.013808009214699268,
      0.021109800785779953,
      0.08118163794279099,
      0.059856291860342026,
      -0.023610325530171394,
      -0.031126882880926132,
      0.05601434037089348,
      0.031081832945346832,
      -0.0006334518548101187,
      -0.003880924079567194,
      0.07036954164505005,
      -0.006311103235930204,
      -0.027244139462709427,
      0.005006082355976105,
      -0.05649368092417717,
      0.02265932224690914,
      0.031317468732595444,
      0.01915610209107399,
      -0.07919317483901978,
      0.027141481637954712,
      -0.032857105135917664,
      0.008676274679601192,
      0.005578779615461826,
      0.014352398924529552,
      0.05868825688958168,
      0.05675119906663895,
      0.02113003470003605,
      0.02083859033882618,
      0.06368780881166458,
      -0.024973507970571518,
      -0.034462761133909225,
      0.006722362246364355,
      0.03487243130803108,
      0.01140694972127676,
      0.0592438168823719,
      -0.08324398100376129,
      0.029879475012421608,
      0.026232853531837463,
      -0.02016405761241913,
      -0.016107741743326187,
      -0.046017639338970184,
      -0.048929113894701004,
      -0.027943968772888184,
      -0.05028248950839043,
      0.05548934265971184,
      0.036040470004081726,
      0.05500121787190437,
      0.09520040452480316,
      -0.03555470332503319,
      -0.04310194402933121,
      -0.005488469265401363,
      0.05581606179475784,
      -0.07347175478935242,
      0.03077530674636364,
      0.04222467169165611,
      0.03369462490081787,
      -0.07771696895360947,
      -0.028638457879424095,
      -0.030398355796933174,
      -0.011806827038526535,
      -0.03287145867943764,
      -0.02392246387898922,
      0.09764348715543747,
      0.12717264890670776,
      0.008466613478958607,
      0.04256577417254448,
      0.05676169693470001,
      0.01255881879478693,
      -0.02056371420621872,
      -0.007466921117156744,
      -0.05204734206199646,
      0.01223097462207079,
      -0.012809987179934978,
      -0.011432371102273464,
      0.06724675744771957,
      0.01152793224900961,
      0.021406613290309906,
      0.007918928749859333,
      -0.06961168348789215,
      -0.0985875204205513,
      -0.000937487930059433,
      -0.007818999700248241,
      0.031848467886447906,
      -0.12094014137983322,
      0.014778560027480125,
      -0.004576385952532291,
      0.03194675222039223,
      0.04153849929571152,
      0.06001778319478035,
      0.021166076883673668,
      0.015450461767613888,
      0.0030820860993117094,
      -0.06999767571687698,
      0.044328924268484116,
      -0.03325019031763077,
      -0.019705956801772118,
      0.018147312104701996,
      0.05670720338821411,
      -0.06656989455223083,
      0.05842447653412819,
      0.0354200154542923,
      0.03365497663617134,
      0.04445212706923485,
      0.032178495079278946,
      0.001482814666815102,
      -0.012034730985760689,
      0.04407719522714615,
      0.050535790622234344,
      0.05252113193273544,
      -0.06598348915576935,
      0.004600510001182556,
      -0.04722526669502258,
      0.036557748913764954,
      0.008897006511688232,
      0.05393308028578758,
      -0.08788279443979263,
      -0.013647321611642838,
      0.005746892653405666,
      -0.0019001049222424626,
      0.04968828335404396,
      -0.07589805126190186,
      0.022581420838832855,
      0.04525689780712128,
      0.050937846302986145,
      -0.012289819307625294,
      -0.03407571464776993,
      0.00513956043869257,
      0.01364838145673275,
      -0.013198243454098701,
      0.049053654074668884
    ],
    [
      0.095401830971241,
      0.009578046388924122,
      0.020497940480709076,
      -0.0062353056855499744,
      0.06337456405162811,
      0.06781122833490372,
      -0.006055421661585569,
      0.017712479457259178,
      -0.039675530046224594,
      -0.015446905978024006,
      -0.07058081030845642,
      -0.07408550381660461,
      -0.024583905935287476,
      0.03550577163696289,
      -0.010696371085941792,
      -0.0975731760263443,
      -0.026107605546712875,
      -0.005441047251224518,
      -0.05935336649417877,
      -0.007874956354498863,
      0.060870517045259476,
      -0.01849658414721489,
      -0.0029711381066590548,
      -0.10731299966573715,
      0.0840439721941948,
      -0.04389836639165878,
      -0.01547754742205143,
      0.05958151817321777,
      -0.08784922957420349,
      0.09524497389793396,
      0.032634586095809937,
      0.015505548566579819,
      0.03378544747829437,
      0.034794438630342484,
      -0.026962077245116234,
      0.05158018320798874,
      0.0011450785677880049,
      0.013751477003097534,
      0.02545139752328396,
      -0.07533485442399979,
      -0.06781015545129776,
      0.0316304974257946,
      0.058551643043756485,
      -0.016535157337784767,
      0.030604174360632896,
      0.007883066311478615,
      0.04224427789449692,
      0.030176755040884018,
      0.006083301734179258,
      0.022867510095238686,
      -0.02530558407306671,
      0.07435488700866699,
      -0.05448434129357338,
      0.08147276192903519,
      0.002909939270466566,
      -0.01275809295475483,
      -0.025868097320199013,
      0.003878313582390547,
      0.017839467152953148,
      0.0015856296522542834,
      -0.030290331691503525,
      0.0737949088215828,
      0.10180994868278503,
      0.024371931329369545,
      0.014645001851022243,
      0.00135711464099586,
      0.031060419976711273,
      0.020324083045125008,
      0.010911889374256134,
      0.023510145023465157,
      0.006097264122217894,
      0.039747755974531174,
      -0.048979926854372025,
      0.04604070633649826,
      0.001973364967852831,
      -0.0787569060921669,
      0.05305185168981552,
      -0.021617241203784943,
      0.02417571470141411,
      -0.008060967549681664,
      0.07329201698303223,
      -0.02813129313290119,
      0.026249287649989128,
      0.08061625808477402,
      -0.05577387660741806,
      -0.0030450818594545126,
      -0.015616743825376034,
      -0.03428197652101517,
      0.06308311969041824,
      -0.04491805657744408,
      0.04198862612247467,
      -0.08449345827102661,
      0.013084156438708305,
      -0.02389722689986229,
      0.030325712636113167,
      -0.08541533350944519,
      -0.05713288113474846,
      0.026720838621258736,
      -0.026236316189169884,
      -0.040047578513622284,
      -0.016711371019482613,
      0.034853529185056686,
      0.042032577097415924,
      0.03799831494688988,
      0.024923134595155716,
      -0.026861483231186867,
      0.0198696106672287,
      -0.02397887594997883,
      -0.005130518693476915,
      0.010188293643295765,
      0.14492709934711456,
      -0.03410685062408447,
      -0.025037676095962524,
      -0.03479748219251633,
      0.01956171728670597,
      0.04401129111647606,
      -0.004607032984495163,
      0.04800869897007942,
      -0.022787269204854965,
      -0.01082000695168972,
      -0.01168938260525465,
      0.07865355163812637,
      0.0718870460987091,
      -0.032222017645835876,
      0.01992877572774887,
      0.07165943831205368,
      -0.04629434272646904,
      -0.09945079684257507,
      0.014215373434126377,
      0.06260693073272705,
      -0.01590071991086006,
      0.019785407930612564,
      -0.006365885958075523,
      0.007075038738548756,
      -0.04259834066033363,
      0.02461448311805725,
      -0.049028631299734116,
      -0.02008683606982231,
      -0.076560378074646,
      -0.00494425417855382,
      -0.0035418097395449877,
      0.02210264652967453,
      0.034223396331071854,
      -0.04682401195168495,
      0.06576436758041382,
      -0.019250784069299698,
      -0.05549684166908264,
      -0.13923141360282898,
      0.05008569732308388,
      -0.05160200968384743,
      -0.11515295505523682,
      0.008837566711008549,
      -0.007070345338433981,
      0.01477924082428217,
      0.0682392343878746,
      0.06255152076482773,
      -0.03098425641655922,
      0.004893368110060692,
      -0.0923394188284874,
      -0.002175889676436782,
      -0.006464804522693157,
      -0.015062312595546246,
      -0.062292978167533875,
      0.03209178149700165,
      0.015466406010091305,
      -0.012063904665410519,
      -0.003505780128762126,
      0.02828146703541279,
      0.030263790860772133,
      0.015240049920976162,
      -0.01874786987900734,
      -0.012071235105395317,
      -0.03620848432183266,
      0.037282440811395645,
      0.02510691247880459,
      -0.05737854912877083,
      -0.007917935028672218,
      -0.04704055190086365,
      -0.022576050832867622,
      -0.02438942715525627,
      0.0364280566573143,
      -0.07153785228729248,
      -0.09743112325668335,
      0.04560138285160065,
      -0.03479680418968201,
      0.003989969380199909,
      0.008965149521827698,
      0.019657079130411148,
      -0.024541471153497696,
      0.008651673793792725,
      -0.036445971578359604,
      -0.024872591719031334,
      -0.028318746015429497,
      -0.007226571440696716,
      -0.11006192117929459,
      -0.024789413437247276,
      -0.0008740256307646632,
      -0.023991825059056282,
      -0.027420802041888237,
      0.0351252555847168,
      -0.002003661822527647,
      0.003945756703615189,
      -0.00581358140334487,
      0.026190733537077904,
      -0.030269324779510498,
      0.02923888899385929,
      -0.0626596063375473,
      0.08665958046913147,
      -0.037552911788225174,
      -0.0847061276435852,
      -0.0015842413995414972,
      -0.015549375675618649,
      -0.049670346081256866,
      0.028840353712439537,
      -0.007990908809006214,
      0.04652734473347664,
      0.009325587190687656,
      0.020429231226444244,
      -0.01048354897648096,
      0.02639337256550789,
      0.04909467324614525,
      -0.0598100982606411,
      -0.06323156505823135,
      0.009603184647858143,
      0.045732010155916214,
      0.023139476776123047,
      -0.04465460404753685,
      -0.08855307847261429,
      -0.06554808467626572,
      0.01253893505781889,
      0.05312391743063927,
      0.048768144100904465,
      0.005163158290088177,
      -0.03591223061084747,
      -0.04125029221177101,
      -0.03129259869456291,
      0.030814992263913155,
      -0.08095158636569977,
      -0.01019574049860239,
      -0.05237049236893654,
      0.005345160141587257,
      -0.013061679899692535,
      0.061327483505010605,
      -0.02565080113708973,
      0.04175618290901184,
      -0.03272178769111633,
      -0.0790925920009613,
      -0.04150263965129852,
      -0.02670331858098507,
      0.03126632794737816,
      -0.03214600309729576,
      -0.011750278063118458,
      -0.06149795278906822,
      -0.0016399630112573504,
      -0.029856780543923378,
      0.06170537695288658,
      -0.045601628720760345,
      -0.06510116159915924,
      -0.06895274668931961,
      -0.002514083869755268,
      0.002051893388852477,
      -0.0026153477374464273,
      -0.03204340487718582,
      0.03473786264657974,
      -0.010420316830277443,
      0.0196597371250391,
      -0.04917315021157265,
      -0.035873446613550186,
      -0.10636632889509201,
      0.05558516085147858,
      -0.014731121249496937,
      -0.08549400418996811,
      0.034912705421447754,
      -0.0530899241566658,
      0.05239991098642349,
      -0.00016862964548636228,
      0.012608823366463184,
      -0.07473782449960709,
      -0.02559712715446949,
      -0.05071794614195824,
      0.03471970930695534,
      -0.018620654940605164,
      0.07765863835811615,
      0.0395684689283371,
      -0.02811397612094879,
      0.011140761896967888,
      0.005368578247725964,
      -0.09313323348760605,
      0.012390092015266418,
      -0.03687484934926033,
      0.037577059119939804,
      0.0033658365719020367,
      0.019681841135025024,
      0.064060740172863,
      -0.040186405181884766,
      0.04807596653699875,
      0.02929629571735859,
      0.01845737360417843,
      0.010900610126554966,
      -0.018782882019877434,
      0.06584078073501587,
      -0.031807661056518555,
      0.03161177039146423,
      0.04406726360321045,
      -0.08886324614286423,
      0.0003741446416825056,
      0.04634147882461548,
      -0.07551152259111404,
      0.007808262016624212,
      -0.06795144826173782,
      0.03271043300628662,
      0.08384930342435837,
      0.0029184736777096987,
      0.06471400707960129,
      0.040197741240262985,
      0.026903146877884865,
      -0.01107766292989254,
      0.0459713377058506,
      -0.032076396048069,
      0.014301258139312267,
      0.043034523725509644,
      -0.004948568996042013,
      -0.030523639172315598,
      -0.007691399194300175,
      -0.015492187812924385,
      0.0226596686989069,
      -0.0003596865863073617,
      -0.0778636783361435,
      -0.12600354850292206,
      0.11463726311922073,
      0.01890183612704277,
      0.018976518884301186,
      0.02517859824001789,
      -0.01776815392076969,
      0.054918739944696426,
      0.024010322988033295,
      0.01682646945118904,
      -0.03592558577656746,
      -0.011766522191464901,
      0.019580760970711708,
      -0.020488569512963295,
      0.021076157689094543,
      0.03952370956540108,
      -0.0662633553147316,
      0.013785062357783318,
      -0.028046561405062675,
      -0.021961253136396408,
      -0.048014719039201736,
      0.030554423108696938,
      -0.05390038341283798,
      -0.028799379244446754,
      0.08656598627567291,
      0.040139421820640564,
      -0.020840510725975037,
      0.020093372091650963,
      0.01384134590625763,
      0.05273318663239479,
      0.014159193262457848,
      0.08377952128648758,
      0.02201446332037449,
      0.10757178068161011,
      0.049524057656526566,
      0.044282976537942886,
      0.05504336580634117,
      0.0323292501270771,
      -0.011941664852201939,
      -0.0934424176812172,
      -0.029707610607147217,
      -0.01405838131904602,
      0.014337418600916862,
      -0.0602739080786705,
      0.022777525708079338,
      -0.07702013105154037,
      -0.0012591775739565492,
      -0.003981671761721373,
      0.04015449061989784,
      -0.0532100647687912,
      0.0010006511583924294,
      -0.018675344064831734,
      -0.016255028545856476,
      -0.01126126665621996,
      -0.012978143990039825,
      -0.04326869919896126,
      -0.05895423889160156,
      -0.07684816420078278,
      -0.05488834157586098,
      -0.011712784878909588,
      0.04729551449418068,
      -0.03617122024297714,
      0.00019456562586128712,
      -0.021503088995814323,
      -0.04743038862943649,
      0.0015552295371890068,
      0.003829129971563816,
      0.04863395914435387,
      -0.01117882039397955,
      0.056092701852321625,
      -0.0029527589213103056,
      -0.0336102657020092,
      -0.010561429895460606,
      0.008767788298428059,
      -0.050296325236558914,
      -0.04767211154103279,
      0.03510631248354912,
      0.03520980104804039,
      -0.013333248905837536,
      -0.02397468127310276,
      0.10803350061178207,
      -0.016859663650393486,
      -0.04824588820338249,
      0.02564782276749611,
      -0.036364514380693436,
      -0.025523394346237183,
      0.046649836003780365,
      0.054340895265340805,
      0.022342074662446976,
      -0.029043812304735184,
      -0.01570516638457775,
      -0.05196243152022362,
      0.05594296380877495,
      0.009351011365652084,
      0.020909838378429413,
      0.01291732583194971,
      0.044836871325969696,
      -0.05870506539940834,
      0.0774296224117279,
      0.041849300265312195,
      0.024504557251930237,
      0.052569855004549026,
      -0.034215137362480164,
      -0.03532040864229202,
      0.05072454363107681,
      -0.026261353865265846,
      0.0051850806921720505,
      -0.025604568421840668,
      0.04175572842359543,
      0.01407046988606453,
      -0.02270728349685669,
      0.009556719101965427,
      0.052310001105070114,
      0.0647064596414566,
      -0.034161895513534546,
      0.10092591494321823,
      0.05752827599644661,
      0.0018767626024782658,
      -0.0706571713089943,
      0.044615909457206726,
      -0.02168840728700161,
      -0.015539192594587803,
      -0.011174009181559086,
      0.025338515639305115,
      0.04134466126561165,
      0.0008859654190018773,
      0.02336045168340206,
      0.04282482713460922,
      -0.033914435654878616,
      0.03715090453624725,
      0.006599760614335537,
      0.05282645672559738,
      -0.01918918266892433,
      -0.0013096323236823082,
      0.06855905055999756,
      -0.11980582028627396,
      -0.028479091823101044,
      0.06623528152704239,
      0.052363909780979156,
      0.00330761494114995,
      0.027052786201238632,
      -0.04538752883672714,
      0.04276587441563606,
      0.019361279904842377,
      -0.02333233691751957,
      0.005617546383291483,
      -0.0615672804415226,
      -0.06684193015098572,
      0.0044503407552838326,
      0.05623016878962517,
      0.001486171968281269,
      0.07550426572561264,
      0.07355520129203796,
      0.015116577036678791,
      0.008361686021089554,
      0.048413608223199844,
      -0.10875377804040909,
      0.02902190387248993,
      0.05432286486029625,
      0.05080639198422432,
      -0.04922264814376831,
      -0.045238837599754333,
      -0.03463112190365791,
      -0.030678685754537582,
      -0.0421517975628376,
      0.02196010947227478,
      -0.01618712954223156,
      -0.026168061420321465,
      -0.009080013260245323,
      -0.00303661753423512,
      0.020031800493597984,
      0.07172991335391998,
      0.006479948293417692,
      0.014215072616934776,
      -0.00035882904194295406,
      0.0009284191764891148
    ],
    [
      -0.022497285157442093,
      -0.07002533227205276,
      -0.07665764540433884,
      0.04552339389920235,
      -0.027361907064914703,
      -0.004818148445338011,
      -0.012984239496290684,
      -0.05203657224774361,
      0.04208676889538765,
      -0.026806091889739037,
      0.014135811477899551,
      0.07196559011936188,
      -0.028879154473543167,
      -0.038366105407476425,
      0.08176387846469879,
      -0.09873085469007492,
      -0.010684422217309475,
      -0.00392466364428401,
      -0.009735632687807083,
      -0.0009901057928800583,
      -0.02142929472029209,
      0.0015277368947863579,
      -0.028835978358983994,
      -0.03423413634300232,
      -0.045382000505924225,
      0.016064833849668503,
      0.005001925863325596,
      -0.07898137718439102,
      0.007868906483054161,
      0.011843335814774036,
      -0.02916184440255165,
      -0.022125227376818657,
      0.009039284661412239,
      0.043206796050071716,
      0.026530569419264793,
      0.03950290009379387,
      -0.044989414513111115,
      -0.03319329023361206,
      -0.0429227352142334,
      -0.03476462885737419,
      0.0015247445553541183,
      -0.024763531982898712,
      0.13064289093017578,
      0.08235622942447662,
      0.04734433814883232,
      0.03210529685020447,
      -0.007338176481425762,
      -0.018076380714774132,
      0.05141032114624977,
      0.029840316623449326,
      0.025134457275271416,
      0.07954678684473038,
      0.007126444950699806,
      -0.018348705023527145,
      -0.03799346461892128,
      -0.027898499742150307,
      0.009882085025310516,
      -0.006757872644811869,
      0.0399165004491806,
      -0.0001752137759467587,
      0.003496988210827112,
      0.06216856464743614,
      -0.08380615711212158,
      -0.06474719196557999,
      0.017035577446222305,
      0.005015939008444548,
      -2.2323454686556943e-05,
      -0.04614618048071861,
      0.06473114341497421,
      -0.013990511186420918,
      -0.0268266461789608,
      -0.005159448366612196,
      0.007688198238611221,
      0.086870938539505,
      0.019727762788534164,
      0.04298584908246994,
      0.037686869502067566,
      -0.08596464991569519,
      -0.023153234273195267,
      -0.009057587012648582,
      -0.009470800869166851,
      -0.07244671881198883,
      -0.03639017045497894,
      0.015451370738446712,
      0.0006657808553427458,
      -0.03421858325600624,
      -0.01248391903936863,
      0.03404368460178375,
      -0.023130493238568306,
      -0.015457229688763618,
      0.0678553432226181,
      -0.022551095113158226,
      0.052766352891922,
      -0.049644045531749725,
      0.028009844943881035,
      -0.03838646411895752,
      -0.009284080006182194,
      -0.038291435688734055,
      -0.05185270681977272,
      -0.023953836411237717,
      0.026119718328118324,
      -0.03367441147565842,
      0.003251846646890044,
      0.0013823353219777346,
      0.01041567511856556,
      0.05030543729662895,
      -0.06598711013793945,
      -0.04456574097275734,
      0.06457699835300446,
      0.01322825625538826,
      -0.035254597663879395,
      -0.06463254988193512,
      -0.033523473888635635,
      -0.01825694926083088,
      0.0894702672958374,
      0.03584202006459236,
      -0.052235059440135956,
      -0.02424897998571396,
      -0.02883181720972061,
      0.025779273360967636,
      -0.00010761419252958149,
      0.051081374287605286,
      0.03845156729221344,
      -0.04757685214281082,
      -0.06527294963598251,
      0.015696903690695763,
      0.03697328642010689,
      -0.04052114486694336,
      0.029599005356431007,
      0.01540604792535305,
      -0.044466640800237656,
      0.020889250561594963,
      0.05173761397600174,
      -0.027163676917552948,
      0.014336341992020607,
      -0.11160408705472946,
      -0.07706931233406067,
      0.023545455187559128,
      -0.0548027940094471,
      0.012192037887871265,
      0.004366796929389238,
      -0.020194603130221367,
      0.054273221641778946,
      0.011385791003704071,
      -0.025968700647354126,
      -0.04739740118384361,
      0.039687223732471466,
      0.004257545340806246,
      -0.016302580013871193,
      -0.014923455193638802,
      0.023884907364845276,
      -0.023778604343533516,
      0.020389961078763008,
      -0.0035867944825440645,
      -0.01135333999991417,
      -0.021685825660824776,
      0.02375653199851513,
      -0.040484100580215454,
      0.07157900929450989,
      0.05802829563617706,
      0.052626971155405045,
      0.023452062159776688,
      -0.04353963956236839,
      -0.0962567999958992,
      -0.019499503076076508,
      0.013743355870246887,
      -0.03581630811095238,
      -0.0221738051623106,
      0.04418789967894554,
      -0.027263423427939415,
      -0.013856997713446617,
      -0.021231113001704216,
      -0.016483016312122345,
      -0.03427876904606819,
      -0.05202335864305496,
      -0.054324183613061905,
      0.02034691348671913,
      -0.0167961698025465,
      -0.015167261473834515,
      0.005322952289134264,
      0.02425040863454342,
      0.013106176629662514,
      -0.062143005430698395,
      0.08347633481025696,
      -0.09063027799129486,
      0.056049637496471405,
      -0.01728026568889618,
      -0.027749329805374146,
      0.006293199025094509,
      0.051272228360176086,
      -0.046634383499622345,
      -0.00021372952323872596,
      0.02136576548218727,
      0.0059086233377456665,
      -0.06479334086179733,
      0.05840965360403061,
      0.008192605338990688,
      0.033374760299921036,
      -0.0340602733194828,
      0.05761629715561867,
      -0.08114378154277802,
      0.03675355389714241,
      0.08050349354743958,
      -0.008384736254811287,
      0.0162736177444458,
      -0.09486475586891174,
      -0.005668396595865488,
      0.053073398768901825,
      -0.01971193216741085,
      -0.04754696786403656,
      -0.0055613392032682896,
      -0.00040173603338189423,
      0.0031911772675812244,
      0.014303569681942463,
      0.014087616465985775,
      0.03851552680134773,
      0.004035651218146086,
      0.004309509880840778,
      -0.03331020101904869,
      0.00496013043448329,
      0.027731211856007576,
      0.010995280928909779,
      0.03747164458036423,
      0.05119733884930611,
      0.006662416737526655,
      0.00638717832043767,
      -0.029617328196763992,
      0.07402792572975159,
      0.036393556743860245,
      0.019397342577576637,
      -0.015545496717095375,
      -0.07424400746822357,
      -0.0121466014534235,
      0.028323164209723473,
      -0.03812704235315323,
      0.022901184856891632,
      -0.018420791253447533,
      -0.06826219707727432,
      0.0035017055924981833,
      0.03788086771965027,
      -0.056848760694265366,
      -0.016407977789640427,
      -0.047868214547634125,
      -0.01899552531540394,
      0.02582995966076851,
      -0.00524197006598115,
      0.010497263632714748,
      0.06570025533437729,
      -0.04510824382305145,
      -0.006705194711685181,
      -0.004896028432995081,
      -0.09807513654232025,
      -0.027856353670358658,
      0.06618576496839523,
      0.04529876261949539,
      0.021725909784436226,
      0.017356645315885544,
      0.059691302478313446,
      0.007513528689742088,
      -0.0373237170279026,
      0.03896268829703331,
      0.016677377745509148,
      0.040773577988147736,
      -0.00594828138127923,
      -0.05312425643205643,
      -0.025574566796422005,
      0.005154543090611696,
      0.04307951405644417,
      0.013611355796456337,
      -0.021033093333244324,
      -0.018276723101735115,
      -0.01674683578312397,
      -0.008396574296057224,
      0.06739961355924606,
      0.009471112862229347,
      -0.07962385565042496,
      0.03617812320590019,
      0.060710228979587555,
      0.03357233479619026,
      0.02559129148721695,
      -0.030474502593278885,
      0.025913028046488762,
      0.03390950709581375,
      0.013123909011483192,
      -0.04235467687249184,
      -0.04059494286775589,
      -0.005824954714626074,
      -0.07257818430662155,
      0.006097533740103245,
      0.04033495858311653,
      -0.0067017269320786,
      0.020429672673344612,
      -0.06174229830503464,
      0.03200512006878853,
      -0.12458590418100357,
      0.03693493455648422,
      0.023665230721235275,
      0.02678108960390091,
      -0.05376876890659332,
      0.04582202062010765,
      -0.044607892632484436,
      0.015602494589984417,
      0.05237370729446411,
      0.02135426737368107,
      -0.024574274197220802,
      0.04285008832812309,
      0.024365324527025223,
      -0.037484124302864075,
      -0.01502151321619749,
      -0.08323465287685394,
      0.004580233711749315,
      -0.043604906648397446,
      0.01170262973755598,
      0.11503805220127106,
      0.033028699457645416,
      -0.05151493102312088,
      -0.09536745399236679,
      0.059351880103349686,
      0.0062761674635112286,
      0.02978302165865898,
      0.02934580110013485,
      0.11352528631687164,
      0.04817916825413704,
      -0.002003285102546215,
      -0.01596423052251339,
      -0.04455920308828354,
      -0.02877029776573181,
      0.11400214582681656,
      0.029337750747799873,
      0.008214226923882961,
      0.055515047162771225,
      -0.008514367043972015,
      0.03660799562931061,
      0.00021562915935646743,
      -0.0029583366122096777,
      0.016126170754432678,
      0.07656393200159073,
      -0.030666926875710487,
      0.030334850773215294,
      0.0042617348954081535,
      -0.04890671744942665,
      -0.017357952892780304,
      0.04212753474712372,
      -0.03940437734127045,
      -0.029930248856544495,
      -0.018228616565465927,
      0.05322038754820824,
      0.04554785415530205,
      0.04952820762991905,
      0.031726062297821045,
      -0.005663513205945492,
      -0.04423408955335617,
      0.013832919299602509,
      -0.011089853942394257,
      -0.028839515522122383,
      0.010644543915987015,
      -0.0294596366584301,
      -0.0456482358276844,
      0.08191462606191635,
      -0.044556185603141785,
      -0.012331178411841393,
      -0.031755320727825165,
      -0.009878505021333694,
      -0.02917461097240448,
      -0.00623321020975709,
      -0.033122532069683075,
      -0.016013817861676216,
      0.005391909275203943,
      0.02482094056904316,
      -0.02885124273598194,
      -0.04867066070437431,
      -0.09409394860267639,
      -0.021042129024863243,
      -0.0036808724980801344,
      -0.0027591034304350615,
      -0.04649732634425163,
      -0.04078954458236694,
      0.12492621690034866,
      -0.012857831083238125,
      0.06619250029325485,
      -0.022505223751068115,
      -0.05594512075185776,
      -0.014818034134805202,
      0.04959987848997116,
      -0.009378926828503609,
      -0.07248988747596741,
      -0.03451421856880188,
      0.004803405608981848,
      -0.05316942557692528,
      -0.030756961554288864,
      0.12778159976005554,
      -0.07143180817365646,
      0.0933845266699791,
      -0.0031846538186073303,
      -0.004485101439058781,
      0.09680839627981186,
      0.04706450551748276,
      -0.03930504992604256,
      0.07353003323078156,
      -0.051037222146987915,
      0.012619789689779282,
      0.02460569702088833,
      -0.002913564443588257,
      -0.03317929431796074,
      -0.005584638100117445,
      0.007187664974480867,
      0.04544829577207565,
      0.019880345091223717,
      0.03335956856608391,
      -0.040340036153793335,
      0.03523467481136322,
      0.058525729924440384,
      -0.06264204531908035,
      -0.019904199987649918,
      0.08154716342687607,
      0.019232837483286858,
      0.0470556914806366,
      -0.0589187853038311,
      -0.021920276805758476,
      0.06412864476442337,
      0.09217149019241333,
      -0.027989409863948822,
      0.048005588352680206,
      0.027396125718951225,
      0.05926902964711189,
      -0.06083211675286293,
      -0.011654454283416271,
      -0.03601547330617905,
      0.014578942209482193,
      0.010089199058711529,
      0.07187896966934204,
      -0.019866742193698883,
      0.018039386719465256,
      0.0036167134530842304,
      -0.07270998507738113,
      -0.008795054629445076,
      0.002200525254011154,
      0.008239101618528366,
      -0.07675455510616302,
      -0.04307761415839195,
      0.023134877905249596,
      -0.05750460922718048,
      -0.038019511848688126,
      -0.020279141142964363,
      0.0737345963716507,
      -0.06296413391828537,
      0.036400407552719116,
      0.049237724393606186,
      0.024708928540349007,
      0.008922708220779896,
      -0.024738911539316177,
      0.008033202961087227,
      0.0491953119635582,
      -0.026708541437983513,
      0.014761269092559814,
      0.05321734771132469,
      0.059590015560388565,
      0.03978945314884186,
      0.0080993277952075,
      0.01668579690158367,
      -0.009285666979849339,
      0.08201666176319122,
      0.052886962890625,
      0.029171885922551155,
      0.023931074887514114,
      0.00859751645475626,
      0.08912543207406998,
      0.01612372323870659,
      -0.04688286408782005,
      -0.03529556095600128,
      -0.06652083247900009,
      -0.02398499846458435,
      0.004606605041772127,
      -0.018351398408412933,
      -0.09031210839748383,
      0.005674212239682674,
      0.06245504692196846,
      0.00860932469367981,
      -0.01231473870575428,
      0.018271250650286674,
      0.03902607411146164,
      -0.02960848994553089,
      0.030569447204470634,
      0.05773570388555527,
      0.0359480194747448,
      -0.037939172238111496,
      0.11797136068344116,
      -0.04405059665441513,
      -0.058144889771938324,
      0.04695280268788338,
      -0.0022796231787651777,
      0.03325574845075607,
      -0.002973187016323209,
      -0.0007622112170793116,
      -0.01033233106136322,
      0.006629750598222017,
      0.01982792094349861,
      -0.013858753256499767,
      -0.025725558400154114,
      0.08656693994998932,
      0.03787066787481308,
      -0.06081032752990723,
      0.04123515263199806
    ],
    [
      -0.03266948089003563,
      -0.007534909062087536,
      -0.0060531944036483765,
      0.04628760367631912,
      0.02059248648583889,
      0.05371033400297165,
      -0.1313619315624237,
      0.0633205771446228,
      -0.059367287904024124,
      -0.016349690034985542,
      0.0071829454973340034,
      -0.039049457758665085,
      0.05172564089298248,
      -0.0169560257345438,
      -0.0023972103372216225,
      0.042743682861328125,
      -0.006423194892704487,
      0.06752007454633713,
      0.01605777069926262,
      0.05275062471628189,
      -0.05774039402604103,
      0.013604489155113697,
      0.04473022371530533,
      -0.008247289806604385,
      -0.03074357472360134,
      0.0021472149528563023,
      -0.07615374028682709,
      -0.0006971102557145059,
      0.05367116630077362,
      0.02433653734624386,
      -0.04346203804016113,
      -0.060483962297439575,
      0.00965598039329052,
      0.032399732619524,
      0.003896240843459964,
      0.011758843436837196,
      -0.07352335751056671,
      -0.014629512093961239,
      -0.06856610625982285,
      0.06210508942604065,
      0.03544696792960167,
      -0.029187588021159172,
      -0.005557101219892502,
      0.051163069903850555,
      0.03656090050935745,
      -0.04581017419695854,
      -0.055550843477249146,
      0.07313302904367447,
      0.014729412272572517,
      -0.053545933216810226,
      0.009162900038063526,
      0.013396029360592365,
      0.023191392421722412,
      0.06124427542090416,
      -0.033891014754772186,
      -0.025246549397706985,
      0.0305254515260458,
      -0.024492274969816208,
      -0.0700339749455452,
      0.010706809349358082,
      0.024076160043478012,
      -0.013035084120929241,
      0.055201806128025055,
      -0.041877396404743195,
      0.029644399881362915,
      -0.01436554454267025,
      -0.02609306201338768,
      -0.0301508866250515,
      0.010943806730210781,
      -0.01907343789935112,
      0.00690160458907485,
      -0.028741097077727318,
      0.02647845447063446,
      -0.0016112570883706212,
      -0.005545661319047213,
      0.03195277974009514,
      0.0032512154430150986,
      -0.05776434391736984,
      0.0400516577064991,
      0.019910329952836037,
      0.04744501784443855,
      -0.011361629702150822,
      -0.0214278232306242,
      0.004000798333436251,
      0.04662920534610748,
      0.03710952401161194,
      0.0807323157787323,
      0.014810035936534405,
      -0.025632983073592186,
      -0.02791144885122776,
      0.03386750444769859,
      0.0185992494225502,
      0.04168001934885979,
      -0.08931224048137665,
      -0.06646395474672318,
      -0.050136592239141464,
      0.06360895186662674,
      -0.023433037102222443,
      -0.048805348575115204,
      -0.0008197883144021034,
      0.010002226568758488,
      0.014779694378376007,
      0.07758631557226181,
      0.01647345721721649,
      -0.057813484221696854,
      -0.01870480738580227,
      0.011018508113920689,
      0.01625862717628479,
      0.025318939238786697,
      0.009636875241994858,
      0.05520051345229149,
      -0.0013628662563860416,
      0.03482576087117195,
      -0.03304874524474144,
      -0.045138679444789886,
      0.008916598744690418,
      0.04026595503091812,
      -0.030915729701519012,
      -0.03440362587571144,
      -0.0034632468596100807,
      0.019169187173247337,
      -0.09461501240730286,
      0.11215852200984955,
      0.018772734329104424,
      -0.0011831826996058226,
      0.052869539707899094,
      0.04157557338476181,
      0.034240029752254486,
      0.04648684710264206,
      0.0006823479197919369,
      0.008155528455972672,
      0.06383464485406876,
      0.010733448900282383,
      0.011253738775849342,
      0.09082193672657013,
      0.009201695211231709,
      -0.025289267301559448,
      0.050548654049634933,
      0.04255009815096855,
      -0.00141986517701298,
      -0.07231713831424713,
      -0.008280281908810139,
      -0.010712997987866402,
      -0.021680697798728943,
      -0.01556200347840786,
      0.03915758058428764,
      0.028764644637703896,
      0.061334822326898575,
      -0.023860711604356766,
      -0.039407990872859955,
      -0.017741922289133072,
      -0.043275170028209686,
      0.01585095189511776,
      0.0354340597987175,
      -0.02194363996386528,
      -0.005811519920825958,
      0.11323688179254532,
      0.008415549993515015,
      0.07252027839422226,
      -0.006427030544728041,
      0.030663907527923584,
      0.021110549569129944,
      -0.03225616365671158,
      0.04332046955823898,
      -0.051166582852602005,
      -0.0030998648144304752,
      0.05605493485927582,
      -0.0075623770244419575,
      -0.02735992893576622,
      0.044472482055425644,
      -0.014115504920482635,
      -0.032964009791612625,
      0.04362738877534866,
      0.03578558191657066,
      -0.008852006867527962,
      -0.05161766707897186,
      0.08966019749641418,
      0.01702021434903145,
      0.06488583236932755,
      -0.026050429791212082,
      0.1097390204668045,
      0.06735371798276901,
      0.012347848154604435,
      -0.001872205059044063,
      0.009426332078874111,
      0.06098894402384758,
      -0.046434346586465836,
      0.031954094767570496,
      -0.019154714420437813,
      0.054913830012083054,
      -0.08929549157619476,
      0.028948549181222916,
      -0.061775267124176025,
      -0.002152631990611553,
      0.06920298933982849,
      0.07053499668836594,
      -0.0040563247166574,
      -0.03827745094895363,
      -0.06670545786619186,
      0.09034281224012375,
      -0.0011429148726165295,
      -0.0389166995882988,
      0.0018464878667145967,
      0.06705770641565323,
      0.045546550303697586,
      -0.02952415496110916,
      0.08868034929037094,
      -0.011746895499527454,
      -0.04266098514199257,
      0.03544354438781738,
      -0.027814166620373726,
      -0.08780350536108017,
      -0.020372502505779266,
      0.04741932079195976,
      -0.02078585885465145,
      -0.10148921608924866,
      -0.022049834951758385,
      0.02491028606891632,
      -0.012721956707537174,
      0.056180182844400406,
      0.0514446385204792,
      0.051050372421741486,
      -0.005833496805280447,
      0.05579372122883797,
      0.008851525373756886,
      0.07200390845537186,
      0.06491314619779587,
      -0.010885574854910374,
      -0.01318979263305664,
      0.019235750660300255,
      0.03784596174955368,
      -0.038523122668266296,
      0.001116488827392459,
      0.017150728031992912,
      -0.0389784500002861,
      0.00024153738922905177,
      -0.039023011922836304,
      -0.042458176612854004,
      0.01281418465077877,
      -0.011263667605817318,
      -0.05902502313256264,
      -0.007682195398956537,
      -0.0408032089471817,
      -0.039260655641555786,
      0.05268134921789169,
      -0.049420855939388275,
      0.013683689758181572,
      0.048543211072683334,
      0.08152025192975998,
      -0.01766258478164673,
      0.019897330552339554,
      -0.035354647785425186,
      -0.10021115839481354,
      -0.0060807932168245316,
      0.027439333498477936,
      0.05023092404007912,
      -0.10348973423242569,
      0.03151684254407883,
      -0.05925765633583069,
      0.03351833298802376,
      -0.14326785504817963,
      -0.050960201770067215,
      0.0006252474267967045,
      -0.045468974858522415,
      0.027550430968403816,
      -0.07332241535186768,
      -0.045421965420246124,
      0.06519100815057755,
      0.03499257564544678,
      -0.003954552114009857,
      0.024482645094394684,
      0.01132594421505928,
      0.01409312803298235,
      0.006040564738214016,
      -0.0058760265819728374,
      -0.049841638654470444,
      -0.00724392756819725,
      -0.04255921393632889,
      0.05898130312561989,
      -0.05542058125138283,
      0.036415714770555496,
      -0.01815115660429001,
      0.05533488467335701,
      0.022247863933444023,
      0.024690475314855576,
      0.03796134516596794,
      -0.015422852709889412,
      -9.140656766248867e-05,
      -0.05891616642475128,
      -0.01852264627814293,
      0.0014762445352971554,
      0.040934156626462936,
      -0.04913657158613205,
      -0.004911134950816631,
      -0.007267598994076252,
      0.011490710079669952,
      -0.0011551396455615759,
      -0.06475884467363358,
      0.04624776914715767,
      0.018923379480838776,
      0.014804329723119736,
      -0.029017340391874313,
      -0.05826015770435333,
      -0.002323237480595708,
      -0.04690221697092056,
      -0.0445694699883461,
      -0.11165297776460648,
      -0.07731640338897705,
      0.0070873708464205265,
      -0.0947394073009491,
      -0.05138268321752548,
      0.014567366801202297,
      0.020182915031909943,
      -0.012116020545363426,
      0.010701218619942665,
      -0.06394197791814804,
      -0.026885434985160828,
      0.04350324720144272,
      0.09183783084154129,
      -0.08623896539211273,
      0.0068937973119318485,
      -0.07196008414030075,
      -0.05512041971087456,
      -0.05838413164019585,
      0.04412378370761871,
      -0.09123794734477997,
      -0.021341966465115547,
      -0.08039884269237518,
      0.015501603484153748,
      -0.0621849000453949,
      0.014975153841078281,
      0.05243353545665741,
      0.015277032740414143,
      -0.012403649277985096,
      -0.03167187049984932,
      0.002979440148919821,
      -0.04898877814412117,
      -0.060368746519088745,
      0.0005546516040340066,
      0.03700585663318634,
      -0.024139519780874252,
      -0.05048311501741409,
      0.07067843526601791,
      -0.002591107739135623,
      0.03014376200735569,
      -0.07338586449623108,
      -0.0016110838623717427,
      0.010898035950958729,
      -0.0645604282617569,
      -0.023272797465324402,
      0.08516256511211395,
      -0.004825336392968893,
      0.002776443026959896,
      0.06201575696468353,
      0.03901075944304466,
      -0.02890288084745407,
      0.020575562492012978,
      0.0397205650806427,
      0.023303426802158356,
      0.031058842316269875,
      0.0707462802529335,
      0.0009235643083229661,
      0.02368208020925522,
      -0.08347980678081512,
      -0.020299237221479416,
      -0.02657909318804741,
      0.05389068275690079,
      -0.013530797325074673,
      -0.07492300122976303,
      -0.01774907112121582,
      0.047407954931259155,
      -0.061347562819719315,
      -0.012185630388557911,
      0.025682000443339348,
      -0.016559427604079247,
      -0.05661684647202492,
      -0.03474891930818558,
      -0.06416235864162445,
      -0.025991424918174744,
      -0.08463050425052643,
      -0.024912619963288307,
      0.04708631709218025,
      0.02562718838453293,
      0.01546475850045681,
      -0.08225370943546295,
      -0.012507788836956024,
      -0.054562490433454514,
      0.012115619145333767,
      -0.021319827064871788,
      -0.01456441730260849,
      0.009561927057802677,
      -0.1017296314239502,
      -0.060026898980140686,
      0.014984329231083393,
      0.014743166975677013,
      -0.06389545649290085,
      0.025420760735869408,
      0.0019548658747226,
      0.028582489117980003,
      0.007818762212991714,
      0.05642571300268173,
      0.004423962440341711,
      -0.08061268925666809,
      -0.00022758332488592714,
      -0.04053345322608948,
      -0.0042207143269479275,
      0.05759398266673088,
      -0.11370054632425308,
      0.01172358077019453,
      0.01672689989209175,
      -0.049002256244421005,
      0.03304087743163109,
      -0.0124506875872612,
      0.045995693653821945,
      -0.017051050439476967,
      -0.07104957103729248,
      -0.1402510702610016,
      -0.06067813187837601,
      -0.037752069532871246,
      -0.004569763317704201,
      -0.05298566445708275,
      -0.0622415654361248,
      0.025403175503015518,
      -0.010224783793091774,
      -0.015308576636016369,
      -0.017534369602799416,
      0.01337225828319788,
      0.01722203753888607,
      0.008799584582448006,
      -0.018867455422878265,
      -0.04218137264251709,
      0.023546157404780388,
      0.07716239988803864,
      -0.014534477144479752,
      0.07901386171579361,
      0.015529233030974865,
      0.03702409937977791,
      0.05091478303074837,
      -0.03171496093273163,
      0.014392252080142498,
      0.06912724673748016,
      0.036734145134687424,
      -0.018948467448353767,
      0.05885826051235199,
      0.08715857565402985,
      0.019743427634239197,
      -0.05724659934639931,
      0.04788776487112045,
      0.044354669749736786,
      -0.01984338089823723,
      0.04060922935605049,
      -0.0017296860460191965,
      -0.0001209976471727714,
      0.012126206420361996,
      -0.04372583329677582,
      -0.06677151471376419,
      -0.025782398879528046,
      -0.038465358316898346,
      -0.011644372716546059,
      -0.08493614941835403,
      -0.0279863141477108,
      0.06435612589120865,
      0.02713584341108799,
      -0.07615417242050171,
      -0.10054179280996323,
      -0.01124802976846695,
      -0.006694222334772348,
      0.025782456621527672,
      0.00980752520263195,
      0.008104968816041946,
      -0.05029444769024849,
      0.029364554211497307,
      -0.060283441096544266,
      -0.016086459159851074,
      0.07119911909103394,
      -0.0327414907515049,
      -0.01885463111102581,
      0.01933326944708824,
      -0.015629855915904045,
      0.024676019325852394,
      -0.011216297745704651,
      0.028384292498230934,
      -0.07283708453178406,
      -0.03978782519698143,
      0.03226080164313316,
      0.02389306202530861,
      0.06750036031007767,
      -0.06929807364940643,
      0.049976665526628494,
      0.06225978583097458,
      0.001117669278755784,
      -0.008841551840305328,
      0.009034818969666958,
      0.028651932254433632,
      -0.06869775056838989,
      0.008248969912528992,
      0.000433514011092484,
      -0.0006940087769180536,
      0.07565635442733765,
      0.019974811002612114,
      0.03547516465187073,
      -0.08309134095907211,
      -0.03209587186574936
    ],
    [
      -0.05742799863219261,
      0.025737011805176735,
      0.019296707585453987,
      0.04216112196445465,
      0.05634302273392677,
      -0.006256996653974056,
      -0.04218865931034088,
      -0.06600476801395416,
      -0.04444055259227753,
      -0.03617807477712631,
      -0.0040207491256296635,
      0.07584061473608017,
      -0.06274379044771194,
      0.05354703962802887,
      -0.03438961133360863,
      -0.017448730766773224,
      0.018246034160256386,
      0.050895899534225464,
      -0.0821831077337265,
      0.013355374336242676,
      0.10063851624727249,
      0.1165517121553421,
      -0.003897301619872451,
      0.01400802657008171,
      -0.006829667836427689,
      -0.00017325556837022305,
      0.046261321753263474,
      0.07555870711803436,
      0.024295909330248833,
      0.0044795433059334755,
      0.061529744416475296,
      -0.02721465192735195,
      0.03008420020341873,
      0.10335373878479004,
      0.012278632260859013,
      0.013547508977353573,
      0.01683131977915764,
      -0.007972422055900097,
      0.032444920390844345,
      0.025119442492723465,
      -0.036441899836063385,
      0.009118889458477497,
      -0.03232952579855919,
      0.0447872020304203,
      0.033431436866521835,
      0.021940788254141808,
      0.07058662921190262,
      -0.01186166051775217,
      0.02681056037545204,
      -0.04703497141599655,
      -0.010175754316151142,
      -0.016265053302049637,
      0.031530044972896576,
      0.017307603731751442,
      -0.101829394698143,
      0.03117816336452961,
      0.0095199104398489,
      -0.04073534533381462,
      -0.06712104380130768,
      -0.052955836057662964,
      -0.012987032532691956,
      -0.07378637790679932,
      0.0020411552395671606,
      0.12156832218170166,
      -0.01328323595225811,
      -0.016880227252840996,
      0.0756809338927269,
      0.06016760319471359,
      -0.06715726852416992,
      -0.018835924565792084,
      -0.01704895682632923,
      -0.050542596727609634,
      -0.01598758064210415,
      -0.018112823367118835,
      -0.051920242607593536,
      -0.0018893835367634892,
      0.06595712900161743,
      -0.016988251358270645,
      0.11499788612127304,
      -0.0017535019433125854,
      -0.02563127689063549,
      -0.0029002754017710686,
      -0.01022693607956171,
      0.05697347968816757,
      -0.04213714227080345,
      0.04674120619893074,
      0.018045170232653618,
      -0.02532186731696129,
      0.03586926683783531,
      0.0064772432669997215,
      -0.0851883739233017,
      -0.05808977782726288,
      0.004312841687351465,
      -0.034959178417921066,
      -0.06187043339014053,
      -0.04384820908308029,
      -0.04533318057656288,
      0.10658568888902664,
      -0.05909648537635803,
      -0.05281661078333855,
      0.0203838013112545,
      0.008648429997265339,
      0.003259948454797268,
      0.07831284403800964,
      0.04701414331793785,
      -0.019724488258361816,
      0.00690749567002058,
      0.08079059422016144,
      0.026851562783122063,
      0.03656128793954849,
      0.05863175168633461,
      0.03744553029537201,
      -0.03555792197585106,
      -0.07428872585296631,
      0.0687352865934372,
      0.037376221269369125,
      -0.012249604798853397,
      0.04292270541191101,
      0.06652294099330902,
      -0.10069791972637177,
      -0.027269812300801277,
      -0.032375268638134,
      -0.04960938170552254,
      -0.04285376891493797,
      0.06642242521047592,
      0.0051217093132436275,
      0.0780128762125969,
      -0.07474040240049362,
      -0.00010995975753758103,
      0.06845343858003616,
      -0.0041947141289711,
      -0.0008619279251433909,
      -0.017882224172353745,
      0.08475872129201889,
      0.03518031910061836,
      0.053385067731142044,
      0.06802622228860855,
      -0.05243143066763878,
      -0.052890557795763016,
      0.02856462635099888,
      0.0647132620215416,
      0.07167384773492813,
      0.010526430793106556,
      -0.039806246757507324,
      -0.0011557750403881073,
      0.010970103554427624,
      -0.002947976114228368,
      0.020094187930226326,
      0.034925658255815506,
      -0.054595090448856354,
      0.018705399706959724,
      -0.032723259180784225,
      0.04379735141992569,
      -0.010492379777133465,
      0.014949142932891846,
      0.058016370981931686,
      0.006806537043303251,
      0.00782576110213995,
      -0.08192864805459976,
      -0.012275605462491512,
      0.025470731779932976,
      -0.06261691451072693,
      -0.04566285014152527,
      0.04550118371844292,
      -0.08557166904211044,
      -0.020128190517425537,
      0.023449452593922615,
      0.02609124407172203,
      -0.07391299307346344,
      -0.07975584268569946,
      0.07744496315717697,
      -0.05685698240995407,
      0.03480926528573036,
      -0.0379641018807888,
      -0.023571990430355072,
      0.019010264426469803,
      -0.007645486854016781,
      -0.0031707878224551678,
      0.02347448468208313,
      -0.006922783330082893,
      -0.08613555878400803,
      0.05668589845299721,
      -0.00922121200710535,
      -0.03688183054327965,
      0.0624191053211689,
      -0.043686505407094955,
      0.03465379402041435,
      0.014361645095050335,
      -0.00944366306066513,
      -0.03088359534740448,
      0.051273100078105927,
      0.017461031675338745,
      -0.09198562800884247,
      -0.03741045296192169,
      0.0033957555424422026,
      0.02464371919631958,
      -0.001042331918142736,
      -0.03804554417729378,
      -0.04849698394536972,
      0.06217145174741745,
      -0.06362653523683548,
      -0.05390935391187668,
      0.009365187026560307,
      0.06304018199443817,
      0.045569851994514465,
      -0.042684994637966156,
      -0.007339306641370058,
      -0.04962284117937088,
      -0.04691430553793907,
      -0.07411403208971024,
      -0.013447788543999195,
      -0.05822784826159477,
      -0.043251894414424896,
      -0.043253343552351,
      0.0024649144615978003,
      -0.017800139263272285,
      -0.06117844581604004,
      -0.017703847959637642,
      -0.02772841788828373,
      0.02505083754658699,
      0.08491295576095581,
      0.026457469910383224,
      0.03803597018122673,
      -0.033501025289297104,
      0.028447670862078667,
      -0.0418618842959404,
      0.05645208805799484,
      -0.04474851116538048,
      -0.09602830559015274,
      0.04907035455107689,
      -0.08924305438995361,
      0.007186647970229387,
      0.03233351558446884,
      -0.008166426792740822,
      0.013754726387560368,
      0.018077639862895012,
      0.08418717235326767,
      -0.023288317024707794,
      0.0205545611679554,
      0.05241972208023071,
      -0.017945993691682816,
      0.018148325383663177,
      -0.04737785458564758,
      -0.0006278196233324707,
      0.009704800322651863,
      -0.0015731325838714838,
      0.02231440506875515,
      0.03634797781705856,
      -0.03461860492825508,
      -0.04044448211789131,
      0.05860724672675133,
      -0.01499475259333849,
      -0.011454456485807896,
      -0.04776359722018242,
      0.0034102145582437515,
      0.08655472844839096,
      -0.06839514523744583,
      0.0006425126339308918,
      -0.09694942086935043,
      -0.012804578989744186,
      -0.0348103791475296,
      0.05709332227706909,
      0.050600674003362656,
      -0.033744629472494125,
      0.01788429543375969,
      0.01058580819517374,
      -0.008194920606911182,
      -0.03499014303088188,
      0.024343522265553474,
      0.005819652695208788,
      -0.01158421766012907,
      -0.03089847043156624,
      0.004269804339855909,
      0.09580571949481964,
      -0.046088140457868576,
      0.010480488650500774,
      0.0686146542429924,
      -0.026096917688846588,
      -0.017294928431510925,
      -0.014658081345260143,
      0.01719413883984089,
      0.03826751187443733,
      -0.024581357836723328,
      -0.05963537096977234,
      0.03442690894007683,
      0.007131506223231554,
      -0.09888521581888199,
      0.013851023279130459,
      0.03750677406787872,
      0.030778901651501656,
      -0.0077591766603291035,
      0.021292440593242645,
      -0.053471293300390244,
      0.05281038582324982,
      -0.04753677174448967,
      -0.07468767464160919,
      -0.0147980572655797,
      0.0037243033293634653,
      0.05776935815811157,
      -0.03185952454805374,
      -0.008990839123725891,
      0.0018851693021133542,
      -0.09402482956647873,
      -0.010332047007977962,
      0.09974116086959839,
      0.012335064820945263,
      -0.027665678411722183,
      0.05823503062129021,
      -0.014689742587506771,
      -0.06815958023071289,
      -0.003399800043553114,
      0.03127658739686012,
      -0.050292257219552994,
      -0.05488506332039833,
      -0.0035540256649255753,
      0.010636897757649422,
      0.00806970801204443,
      -0.016058286651968956,
      -0.0009574663708917797,
      0.030550319701433182,
      0.04817570000886917,
      0.04516502842307091,
      0.022566115483641624,
      -0.146892711520195,
      0.008437464013695717,
      -0.0658566802740097,
      -0.005028003826737404,
      0.021858489140868187,
      -0.03485606610774994,
      -0.011096669360995293,
      -0.035850025713443756,
      -0.09049953520298004,
      0.00848858617246151,
      0.05685865134000778,
      -0.15215924382209778,
      0.060252945870161057,
      0.0017441775416955352,
      -0.07048503309488297,
      0.016331281512975693,
      -0.014085320755839348,
      0.12081103771924973,
      -0.003403670620173216,
      -0.061629533767700195,
      -0.11652545630931854,
      0.040461376309394836,
      0.04074268788099289,
      -0.025475382804870605,
      0.015390241518616676,
      -0.04587129130959511,
      0.010220900177955627,
      -0.023973142728209496,
      -0.0049028173089027405,
      0.06751648336648941,
      -0.058530986309051514,
      0.010128454305231571,
      -0.03085416927933693,
      -0.047742050141096115,
      0.0012174062430858612,
      -0.007551971357315779,
      0.013104605488479137,
      -0.044171664863824844,
      0.033793434500694275,
      0.011497543193399906,
      -0.06381722539663315,
      -0.00025490031111985445,
      0.08002988994121552,
      -0.009770543314516544,
      -0.05795300751924515,
      -0.0441189780831337,
      0.12522485852241516,
      0.008846873417496681,
      0.039498306810855865,
      0.05238870158791542,
      0.07433772087097168,
      0.04863595962524414,
      -0.024629835039377213,
      -0.04275653511285782,
      -0.03563356399536133,
      0.05840505287051201,
      0.011091756634414196,
      0.0025808135978877544,
      0.05253848806023598,
      -0.09020553529262543,
      0.013189947232604027,
      0.06057334318757057,
      0.07388179004192352,
      -0.10517637431621552,
      0.052951566874980927,
      -0.004520186223089695,
      -0.009873762726783752,
      0.11030099540948868,
      0.025066863745450974,
      0.004299315623939037,
      0.06990117579698563,
      -0.01483268290758133,
      -0.04860324412584305,
      0.03332328796386719,
      0.10115578770637512,
      -0.056918397545814514,
      0.01911071315407753,
      -0.05824444442987442,
      -0.025378620252013206,
      -0.08159910142421722,
      -0.007889405824244022,
      0.009060084819793701,
      0.02313024178147316,
      0.06556396186351776,
      -0.009042398072779179,
      0.05397186428308487,
      0.016573814675211906,
      0.009159573353827,
      -0.14654970169067383,
      0.0584847517311573,
      0.0902058556675911,
      -0.015660127624869347,
      0.008944455534219742,
      -0.020119579508900642,
      0.024132033810019493,
      0.007240579463541508,
      0.07148915529251099,
      -0.044860903173685074,
      -0.020853348076343536,
      -0.04224970191717148,
      0.006015796214342117,
      -0.018889615312218666,
      -0.047459788620471954,
      0.004640619736164808,
      0.007535520009696484,
      -0.021083729341626167,
      -0.05399507284164429,
      -0.02401326410472393,
      0.03869318217039108,
      0.04780508950352669,
      0.03084009699523449,
      0.06329794228076935,
      -0.0018226458923891187,
      0.042182806879282,
      0.04271584749221802,
      -0.03787197917699814,
      0.04103664308786392,
      0.06353545188903809,
      -0.009801505133509636,
      0.006530113983899355,
      -0.06132704019546509,
      0.028605811297893524,
      -0.014189490117132664,
      0.07413610070943832,
      0.03345818817615509,
      0.023621654137969017,
      0.0365399532020092,
      -0.06265836954116821,
      0.03627655282616615,
      0.02728612720966339,
      0.02199316769838333,
      -0.0027230887208133936,
      0.08433325588703156,
      -0.01835131086409092,
      0.018814481794834137,
      0.048682983964681625,
      -0.05754240229725838,
      0.0010684786830097437,
      0.014591294340789318,
      -0.029099181294441223,
      0.08951357007026672,
      -0.08044231683015823,
      0.020801138132810593,
      -0.017237259075045586,
      0.08900013566017151,
      -0.0003148787363898009,
      -0.036984462291002274,
      -0.017862355336546898,
      0.010615738108754158,
      -0.05612634867429733,
      0.06435365974903107,
      0.019969407469034195,
      0.020141329616308212,
      0.006442653480917215,
      -0.07286474108695984,
      0.015386058017611504,
      -0.02535274624824524,
      0.03850384056568146,
      -0.008645305410027504,
      -0.048212677240371704,
      -0.111508309841156,
      0.04429531469941139,
      -0.06548923999071121,
      -0.05949743092060089,
      -0.00493781641125679,
      -0.018934547901153564,
      0.08308332413434982,
      -0.002971920883283019,
      -0.052516717463731766,
      0.029960328713059425,
      -0.00982872024178505,
      0.0022582050878554583,
      -0.03142140433192253,
      0.03215716779232025,
      -0.043766677379608154,
      0.017809709534049034,
      0.016862859949469566,
      -0.015705531463027,
      -0.04595279321074486,
      -0.011324606835842133
    ],
    [
      -0.030037304386496544,
      0.031502529978752136,
      0.029800675809383392,
      -0.06398798525333405,
      -0.0355878584086895,
      -0.017018049955368042,
      -0.031192252412438393,
      -0.017695371061563492,
      -0.09022895991802216,
      -0.03206140920519829,
      -0.01897979900240898,
      -0.00556775601580739,
      0.02856018953025341,
      -0.010821066796779633,
      0.07807040214538574,
      -0.0015614712610840797,
      -0.03929087892174721,
      -0.02902553416788578,
      0.04399816319346428,
      -0.026504145935177803,
      0.0042379917576909065,
      -0.02870679460465908,
      -0.027924440801143646,
      0.06202718988060951,
      -0.012843099422752857,
      -0.02470271661877632,
      0.021678568795323372,
      -0.03339928016066551,
      0.015354651026427746,
      -0.0015408983454108238,
      0.0775502622127533,
      0.0058105820789933205,
      0.006692432798445225,
      0.04003830999135971,
      -0.042842574417591095,
      0.025758448988199234,
      0.04563046246767044,
      -0.010520434007048607,
      -0.04726684093475342,
      0.04078979417681694,
      0.002654723357409239,
      -0.005343281663954258,
      -0.06585891544818878,
      -0.123380646109581,
      0.006438306532800198,
      0.020268086344003677,
      -0.12544003129005432,
      -0.013064430095255375,
      0.03223482519388199,
      0.05303100869059563,
      0.0916164219379425,
      0.02573753148317337,
      0.0023754851426929235,
      0.028822148218750954,
      0.0322202630341053,
      -0.006271034013479948,
      0.06746671348810196,
      0.054686497896909714,
      0.03455039858818054,
      -0.057436827570199966,
      0.12323764711618423,
      0.07212904840707779,
      0.024074101820588112,
      0.046913135796785355,
      -0.01570040173828602,
      -0.06457776576280594,
      0.02719133161008358,
      -0.08075522631406784,
      -0.021621793508529663,
      -0.00493671465665102,
      0.05495044216513634,
      0.015541544184088707,
      -0.008710725232958794,
      0.09949630498886108,
      -0.01659919135272503,
      0.04791382700204849,
      -0.05135100334882736,
      0.002098269760608673,
      0.017249181866645813,
      0.10197678953409195,
      0.016517233103513718,
      -0.010901612229645252,
      0.017568519338965416,
      -0.052314575761556625,
      0.03405676782131195,
      0.023144325241446495,
      0.049701057374477386,
      0.048074282705783844,
      0.0034475470893085003,
      -0.041412826627492905,
      -0.05713857337832451,
      -0.022675635293126106,
      -0.07112050801515579,
      -0.01755649223923683,
      -0.04873055964708328,
      -0.022675268352031708,
      0.011811615899205208,
      0.04908217862248421,
      -0.002915620105341077,
      -0.013242878019809723,
      0.04874327778816223,
      -0.07906991243362427,
      -0.04803290590643883,
      -0.0018725282279774547,
      0.05452447012066841,
      0.07308933138847351,
      -0.005205370020121336,
      0.014861742034554482,
      -0.039511580020189285,
      0.03218873217701912,
      -0.013426353223621845,
      0.02055199258029461,
      -0.003994994331151247,
      -0.0235823355615139,
      -0.008417416363954544,
      -0.007105397060513496,
      0.01962452568113804,
      0.06446406990289688,
      0.022652164101600647,
      -0.012529521249234676,
      -0.0336642861366272,
      0.0010367449140176177,
      0.04202798381447792,
      0.04558241367340088,
      0.05483810231089592,
      0.06498299539089203,
      0.009760393761098385,
      -0.05803826451301575,
      0.024359537288546562,
      0.011267296969890594,
      0.046310730278491974,
      0.007199466694146395,
      0.01565323770046234,
      -0.014841337688267231,
      0.023670462891459465,
      0.004430349450558424,
      -0.04741496220231056,
      0.022065171971917152,
      0.008692216128110886,
      -0.047922465950250626,
      -0.003280822653323412,
      -0.06485115736722946,
      0.04206335172057152,
      0.03894471377134323,
      -0.0009755883365869522,
      -0.04056723415851593,
      -0.004678480792790651,
      0.03149126097559929,
      0.05629303678870201,
      0.09795217216014862,
      -0.031264644116163254,
      0.02488352358341217,
      0.012575438246130943,
      -0.003784437896683812,
      0.06577369570732117,
      0.062037378549575806,
      -0.005740552209317684,
      0.021566329523921013,
      -0.019100463017821312,
      0.020475037395954132,
      0.03023851104080677,
      0.06572218984365463,
      -0.02272273413836956,
      0.0016422381158918142,
      0.08139254152774811,
      0.017072733491659164,
      -0.0797651931643486,
      -0.00197069114074111,
      -0.04668484628200531,
      -0.030379625037312508,
      -0.02853887528181076,
      -0.04625744745135307,
      -0.06277783215045929,
      0.05445145443081856,
      0.04192785546183586,
      0.032432619482278824,
      -0.059028081595897675,
      -0.031103212386369705,
      -0.018249543383717537,
      -0.07658547908067703,
      -0.1543174684047699,
      -0.10150399059057236,
      0.003920519724488258,
      -0.02212686464190483,
      -0.02122356928884983,
      -0.0033078354317694902,
      -0.0290643572807312,
      0.014363152906298637,
      -0.003084697760641575,
      0.03874589502811432,
      -0.006856250111013651,
      -0.05398435518145561,
      -0.0864417776465416,
      0.0015484223840758204,
      0.02126859873533249,
      0.08646844327449799,
      -0.07513950765132904,
      -0.04617644473910332,
      0.016773322597146034,
      -0.0661860927939415,
      0.042545415461063385,
      0.00202065403573215,
      -0.0750228613615036,
      0.013584255240857601,
      0.06909295171499252,
      0.09384112805128098,
      -0.07547097653150558,
      0.003600796451792121,
      -0.06167718768119812,
      -0.10346719622612,
      0.041583213955163956,
      0.015399214811623096,
      -0.0015746670542284846,
      0.0045310030691325665,
      0.02484196238219738,
      -0.015363681130111217,
      -0.03134315460920334,
      0.01122446171939373,
      -0.07451082766056061,
      0.0202922485768795,
      -0.002924985019490123,
      -0.04707527160644531,
      0.049054358154535294,
      -0.009551663883030415,
      -0.04236545041203499,
      -0.003284091129899025,
      -0.036418087780475616,
      -0.03197135403752327,
      0.008401109836995602,
      -0.04628042131662369,
      0.013974900357425213,
      -0.018321329727768898,
      0.06912727653980255,
      0.00249859387986362,
      -0.005726981908082962,
      -0.07474792748689651,
      0.023116691038012505,
      -0.037715621292591095,
      -0.024833589792251587,
      -0.05270159989595413,
      -0.043277375400066376,
      0.05089804530143738,
      0.03320051729679108,
      -0.0524868443608284,
      -0.038570601493120193,
      -0.05112798139452934,
      -0.09560633450746536,
      0.04408722370862961,
      0.04414934292435646,
      -0.02295580692589283,
      0.01431565172970295,
      -0.02359575405716896,
      -0.01961411163210869,
      -0.0038648194167762995,
      0.054628968238830566,
      -0.0760289877653122,
      0.07611009478569031,
      -0.03887711092829704,
      -0.12032162398099899,
      0.06172960251569748,
      -0.026745665818452835,
      0.01133927796036005,
      0.010904539376497269,
      0.0076238662004470825,
      -0.05452251061797142,
      0.04111684113740921,
      0.022374611347913742,
      -0.03739430382847786,
      -0.036590542644262314,
      0.007395613938570023,
      0.005882986821234226,
      0.008396351709961891,
      -0.011055334471166134,
      0.007225038483738899,
      -0.052976615726947784,
      -0.0058926306664943695,
      0.016444247215986252,
      0.06694737076759338,
      -0.023669501766562462,
      0.044884003698825836,
      0.014834891073405743,
      0.011506992392241955,
      0.042958639562129974,
      0.07086453586816788,
      -0.004686716943979263,
      0.028427131474018097,
      0.02356768399477005,
      -0.044806744903326035,
      -0.02021627314388752,
      -0.03981785103678703,
      0.015635350719094276,
      -0.0399358831346035,
      -0.040145304054021835,
      0.02781270071864128,
      0.004040981642901897,
      -0.015320844016969204,
      -0.006509862374514341,
      -0.0258670374751091,
      -0.023695223033428192,
      -0.015945514664053917,
      -0.05449869856238365,
      -0.020448364317417145,
      -0.05325623229146004,
      -0.04955882951617241,
      -0.011197148822247982,
      -0.012904682196676731,
      0.008741066791117191,
      -0.08947614580392838,
      -0.01046880055218935,
      0.029532769694924355,
      0.016081539914011955,
      -0.04612254351377487,
      -0.0052608163096010685,
      -0.037334077060222626,
      0.001446239766664803,
      0.07788324356079102,
      -0.015055040828883648,
      -0.02402685396373272,
      0.06760749220848083,
      0.0010130390292033553,
      0.04149102419614792,
      0.007442341651767492,
      0.043815821409225464,
      -0.03426223248243332,
      0.03607144579291344,
      -0.01566443033516407,
      -0.038655079901218414,
      0.06324052810668945,
      0.030175376683473587,
      0.0027581050526350737,
      0.03756912797689438,
      -0.014693452045321465,
      -0.04994083195924759,
      -0.06205745413899422,
      0.018357552587985992,
      -0.025433724746108055,
      0.019218163564801216,
      -0.05252447724342346,
      -0.012899989262223244,
      -0.08600754290819168,
      0.04769723117351532,
      0.026344889774918556,
      0.004160542041063309,
      0.10727769136428833,
      -0.07771041989326477,
      -0.004938731901347637,
      0.041269686073064804,
      -0.08453608304262161,
      -0.046425241976976395,
      0.036504629999399185,
      0.09400950372219086,
      -0.013322877697646618,
      0.014816191047430038,
      0.03313881903886795,
      -0.01458594761788845,
      0.04316605627536774,
      -0.0026189121417701244,
      0.010173525661230087,
      0.04205414280295372,
      -0.0604565367102623,
      0.0446195974946022,
      -0.03026607260107994,
      -0.013092524372041225,
      -0.030060846358537674,
      -0.006058785133063793,
      -0.07242801785469055,
      0.05365873500704765,
      0.04661431908607483,
      0.05313564091920853,
      -0.03625800833106041,
      0.05879170447587967,
      -0.013084203004837036,
      -0.1060926616191864,
      -0.00776301184669137,
      0.06950875371694565,
      0.04356541112065315,
      0.06218143180012703,
      -0.017397643998265266,
      -0.01441707368940115,
      -0.026801280677318573,
      -0.029945416375994682,
      0.07358399778604507,
      -0.09800297021865845,
      0.08014555275440216,
      0.014663462527096272,
      -0.005889424588531256,
      -0.026597129181027412,
      0.003626616671681404,
      0.027884591370821,
      0.021017000079154968,
      0.0635918602347374,
      0.043930862098932266,
      0.0018971995450556278,
      0.0485130175948143,
      -0.04579274356365204,
      0.04070946201682091,
      0.0374065563082695,
      7.51729603507556e-05,
      0.04389896243810654,
      0.024201728403568268,
      0.010651309043169022,
      0.0817648321390152,
      0.01691979356110096,
      -0.09458846598863602,
      0.0042043449357151985,
      0.004792321473360062,
      -0.04026862978935242,
      0.036111991852521896,
      -0.023614613339304924,
      0.018763363361358643,
      -0.029265351593494415,
      -0.021701527759432793,
      0.02703164517879486,
      0.04977382719516754,
      -0.03506254032254219,
      -0.030269073322415352,
      -0.012692445889115334,
      -0.03465044125914574,
      0.03654683753848076,
      0.0698818787932396,
      -0.05836333706974983,
      0.026547877117991447,
      -0.01454383134841919,
      0.021298186853528023,
      -0.03452238440513611,
      0.04240311682224274,
      0.07141277194023132,
      0.0018837543902918696,
      0.005054372828453779,
      -0.07774845510721207,
      -0.015297544188797474,
      0.04549044743180275,
      -0.008242395706474781,
      -0.020745916292071342,
      0.0189517829567194,
      -0.06962698698043823,
      0.022753173485398293,
      -0.009271388873457909,
      -0.11030399054288864,
      0.038324348628520966,
      0.0021623317152261734,
      0.03588688373565674,
      0.024895252659916878,
      0.013803900219500065,
      0.07080654799938202,
      -0.01729811541736126,
      0.031124960631132126,
      -0.022421307861804962,
      0.07076544314622879,
      0.05833113566040993,
      0.0151368398219347,
      0.04451936483383179,
      -0.03383789584040642,
      0.01622171700000763,
      -0.020581314340233803,
      0.014565705321729183,
      -0.05416969954967499,
      -0.028710288926959038,
      -0.022613346576690674,
      0.014650152996182442,
      -0.040463920682668686,
      0.06233930587768555,
      -0.051695115864276886,
      0.023790784180164337,
      -0.008848859928548336,
      0.009094377979636192,
      -0.015770578756928444,
      -0.060304369777441025,
      0.04734393209218979,
      -0.033566560596227646,
      -0.0417901948094368,
      -0.033657606691122055,
      -0.030451059341430664,
      -0.0006336187361739576,
      0.019405728206038475,
      0.04205071181058884,
      0.03430243954062462,
      -0.017257407307624817,
      -0.0053720069117844105,
      0.012311475351452827,
      0.005231716204434633,
      0.06125998497009277,
      -0.05338042974472046,
      0.0061903358437120914,
      0.006713759154081345,
      0.02449743263423443,
      -0.044851481914520264,
      -0.03174104914069176,
      0.05544840916991234,
      -0.009391356259584427,
      0.06269891560077667,
      0.016205428168177605,
      0.0181480310857296,
      -0.028898142278194427,
      -0.001539267017506063,
      0.08048486709594727,
      -0.0036114684771746397,
      -0.006392915267497301,
      -0.004100076388567686,
      0.025726359337568283,
      -0.021705981343984604,
      -0.012444970197975636,
      0.05421439930796623
    ],
    [
      -0.024933287873864174,
      -0.04049136862158775,
      0.06288033723831177,
      0.07318773865699768,
      -0.011498305015265942,
      0.10728252679109573,
      -0.04811510816216469,
      -0.08531889319419861,
      0.027455445379018784,
      -0.05679914355278015,
      0.061740707606077194,
      0.05759240686893463,
      0.011125021614134312,
      0.06700778752565384,
      -0.04181860759854317,
      0.01177248265594244,
      0.11435169726610184,
      0.04066689684987068,
      0.11089672893285751,
      -0.047957997769117355,
      -0.059290312230587006,
      -0.023697197437286377,
      0.024991285055875778,
      -0.030206283554434776,
      0.05298992991447449,
      0.04582320526242256,
      0.03526229038834572,
      0.02140372432768345,
      0.059636108577251434,
      0.02247975766658783,
      -0.01996103674173355,
      0.005149099975824356,
      0.03051130659878254,
      0.00569770485162735,
      0.10931438207626343,
      0.010643786750733852,
      -0.003457697806879878,
      0.04799213260412216,
      -0.05815286561846733,
      0.022512286901474,
      -0.01390599925071001,
      0.03487356752157211,
      -0.036277078092098236,
      0.02032441273331642,
      0.08223837614059448,
      0.028141867369413376,
      -0.01374597568064928,
      0.0397321842610836,
      -0.0050538573414087296,
      -0.14723248779773712,
      -0.10943299531936646,
      0.033177316188812256,
      0.05151458457112312,
      -0.024126896634697914,
      -0.061860330402851105,
      -0.05734758451581001,
      0.016376033425331116,
      0.08789585530757904,
      0.02714873105287552,
      -0.04143916815519333,
      0.07101025432348251,
      0.012944729998707771,
      0.0008335199090652168,
      0.004166232887655497,
      0.030715594068169594,
      -0.041512541472911835,
      -0.032676756381988525,
      0.0028962665237486362,
      -0.05435005575418472,
      0.02838466316461563,
      -0.009831925854086876,
      0.0009976772125810385,
      0.02726820670068264,
      -0.05502675101161003,
      0.0005623881588689983,
      0.06530099362134933,
      -0.018290676176548004,
      0.041402991861104965,
      -0.03980550169944763,
      0.007709961384534836,
      0.01489110104739666,
      0.009572041220963001,
      -0.04545324295759201,
      0.07667110860347748,
      0.03297126293182373,
      0.047467101365327835,
      0.039090488106012344,
      -0.09983553737401962,
      0.06522393971681595,
      0.07319899648427963,
      -0.023932624608278275,
      0.049171168357133865,
      0.09988059848546982,
      -0.01024764683097601,
      -0.008804126642644405,
      -0.057507630437612534,
      0.037344180047512054,
      0.017237864434719086,
      0.017480064183473587,
      -0.06374870240688324,
      0.019546763971447945,
      0.02406720630824566,
      -0.06350494921207428,
      -0.036903880536556244,
      -0.11100953072309494,
      -0.026719888672232628,
      -0.03506317362189293,
      -0.07792488485574722,
      0.00788690336048603,
      -0.010602101683616638,
      0.026756014674901962,
      0.07345732301473618,
      -0.048139553517103195,
      -0.060836952179670334,
      0.0038514318875968456,
      0.006186558399349451,
      -0.0006620385101996362,
      -0.010743433609604836,
      0.09383133798837662,
      0.038090046495199203,
      0.03591948375105858,
      0.012806100770831108,
      0.043212518095970154,
      -0.016499027609825134,
      -0.0146322101354599,
      0.09165901690721512,
      0.003722436958923936,
      0.003760840743780136,
      -0.06040926277637482,
      -0.03954044356942177,
      -0.02447952888906002,
      0.04533979296684265,
      -0.00872806366533041,
      0.0390353761613369,
      -0.07417380064725876,
      -0.06580712646245956,
      0.022374499589204788,
      -0.008365933783352375,
      -0.07918129116296768,
      0.0686110407114029,
      -0.12676513195037842,
      0.1025577262043953,
      0.037208590656518936,
      -0.029851481318473816,
      -0.017530124634504318,
      -0.008165834471583366,
      0.006430713925510645,
      0.050940509885549545,
      -0.007037985138595104,
      -0.060018401592969894,
      0.015541808679699898,
      -0.03451979160308838,
      0.0766223818063736,
      -0.03559800237417221,
      0.063898466527462,
      -0.0052541824989020824,
      -0.029364028945565224,
      -0.020586581900715828,
      0.049710601568222046,
      -0.03380921483039856,
      0.030096985399723053,
      0.09881255030632019,
      -0.005524923093616962,
      0.02795208990573883,
      0.07225115597248077,
      -0.04667655751109123,
      0.019300872460007668,
      0.09023125469684601,
      -0.054752569645643234,
      0.011338825337588787,
      0.07995997369289398,
      -0.03871956095099449,
      0.03400639072060585,
      -0.028288761153817177,
      -0.10876826196908951,
      -0.014309713616967201,
      0.01553864125162363,
      -0.05348782613873482,
      0.07537318021059036,
      0.057583488523960114,
      -0.03530352562665939,
      -0.016139324754476547,
      0.035906143486499786,
      0.07877431809902191,
      -0.012925990857183933,
      -0.004298376385122538,
      -0.037682581692934036,
      0.03785843402147293,
      -0.13101160526275635,
      -0.04042396321892738,
      0.03857172653079033,
      -0.07201720029115677,
      0.09597326070070267,
      -0.007228470873087645,
      0.02476714551448822,
      0.10197917371988297,
      0.013046602718532085,
      0.1006101742386818,
      -0.0679100751876831,
      -0.10676154494285583,
      -0.0801849439740181,
      -0.011590189300477505,
      0.026720454916357994,
      -0.005779371131211519,
      -0.0687762126326561,
      0.05031846463680267,
      -0.08393172174692154,
      0.0029814683366566896,
      -0.056972846388816833,
      0.021217502653598785,
      -0.007613929454237223,
      0.012950742617249489,
      -0.005338573362678289,
      -0.022371625527739525,
      -0.006775861140340567,
      0.025896431878209114,
      0.051906511187553406,
      0.017357561737298965,
      0.06855501979589462,
      0.034645926207304,
      -0.020214704796671867,
      0.03982141613960266,
      0.00013721150753553957,
      -0.009724467061460018,
      0.056726835668087006,
      -0.08096811175346375,
      0.02989250235259533,
      0.01210174523293972,
      -0.0023229317739605904,
      -0.04414520785212517,
      -0.018568463623523712,
      -0.012271471321582794,
      0.04936159774661064,
      0.096102274954319,
      -0.03123396635055542,
      0.02603311464190483,
      0.0799744576215744,
      -0.07409298419952393,
      -0.02742013707756996,
      0.021451905369758606,
      -0.04489120841026306,
      -0.10294244438409805,
      0.07146938145160675,
      -0.003666039789095521,
      -0.018261590972542763,
      -0.02330923266708851,
      0.0677802786231041,
      -0.047152817249298096,
      -0.023562153801321983,
      -0.025839179754257202,
      0.04079236090183258,
      -0.05499625578522682,
      -0.027383245527744293,
      0.03233613073825836,
      0.021584387868642807,
      -0.06433245539665222,
      -0.06143752112984657,
      -0.026059942319989204,
      0.011514168232679367,
      -0.036040548235177994,
      0.017474908381700516,
      -0.011313516646623611,
      -0.02044483833014965,
      -0.05916653200984001,
      -0.04130319878458977,
      0.04912148043513298,
      0.012868411839008331,
      0.021867670118808746,
      0.07126622647047043,
      0.044347260147333145,
      0.028182493522763252,
      -0.03351149708032608,
      -0.03016374446451664,
      0.014817135408520699,
      0.008151236921548843,
      -0.00805147085338831,
      0.053191713988780975,
      0.022619687020778656,
      -0.017710363492369652,
      0.03026673011481762,
      -0.05758355185389519,
      0.06746853142976761,
      -0.019872022792696953,
      -0.016615891829133034,
      -0.02855253778398037,
      0.05787844583392143,
      0.014321492984890938,
      -0.01447209157049656,
      0.02055390737950802,
      -0.12966656684875488,
      0.0003804998705163598,
      0.03000720776617527,
      -0.04796168953180313,
      -0.06021495535969734,
      -0.023806771263480186,
      0.00613354193046689,
      -0.01714826561510563,
      0.003922733478248119,
      -0.08127208799123764,
      -0.008506456390023232,
      -0.015232974663376808,
      -0.04054780304431915,
      -0.07422107458114624,
      -0.121463343501091,
      0.07286801189184189,
      0.0026216148398816586,
      -0.12016695737838745,
      -0.003542499616742134,
      -0.010510831139981747,
      -0.09761980175971985,
      0.005820568185299635,
      0.008687549270689487,
      0.0010365479392930865,
      -0.009462451562285423,
      -0.020889775827527046,
      0.006669474299997091,
      -0.05195698142051697,
      0.08951591700315475,
      0.008258684538304806,
      0.03868449106812477,
      -0.04166559502482414,
      0.04605775699019432,
      0.03884749114513397,
      -0.14344316720962524,
      -0.00900489091873169,
      -0.11714071035385132,
      0.06741705536842346,
      0.08146900683641434,
      -0.018192321062088013,
      -0.10936926305294037,
      -0.05683678388595581,
      -0.0109343770891428,
      -0.02128698118031025,
      0.02945120818912983,
      -0.04933120310306549,
      -0.01660834066569805,
      0.005155638325959444,
      -0.02578149177134037,
      -0.0642474964261055,
      0.030153287574648857,
      0.004173973575234413,
      -0.01042085699737072,
      -0.009408850222826004,
      0.037025440484285355,
      -0.09549660235643387,
      -0.006560596637427807,
      -0.03187912702560425,
      0.12683738768100739,
      -0.051100119948387146,
      0.022458994761109352,
      -0.0013169485609978437,
      -0.013111492618918419,
      0.014983872883021832,
      -0.009199457243084908,
      0.04342496767640114,
      0.014948165975511074,
      0.013830505311489105,
      0.0024343302939087152,
      0.02482817880809307,
      -0.030223818495869637,
      0.05256710201501846,
      0.04722466319799423,
      -0.0322350338101387,
      0.027282774448394775,
      0.00536711048334837,
      0.07651753723621368,
      -0.04982073977589607,
      0.001080065150745213,
      0.023086780682206154,
      0.018581869080662727,
      -0.0076279775239527225,
      -0.06920139491558075,
      0.0037477060686796904,
      -0.0008010517340153456,
      -0.05135062709450722,
      -0.050478335469961166,
      -0.02870749495923519,
      -0.03342002257704735,
      0.039927367120981216,
      -0.050187136977910995,
      -0.14454755187034607,
      -0.006041321437805891,
      0.053993456065654755,
      0.022349638864398003,
      -0.09255597740411758,
      0.12987293303012848,
      -0.019005058333277702,
      -0.0010535899782553315,
      -0.08370427042245865,
      0.07393094897270203,
      0.05702265724539757,
      -0.023146115243434906,
      0.0058025880716741085,
      -0.04324375092983246,
      -0.004053407348692417,
      0.088129922747612,
      -0.006199678871780634,
      0.11164406687021255,
      -0.036225900053977966,
      -0.06189538538455963,
      -0.03427921608090401,
      0.03546619787812233,
      -0.06606034189462662,
      -0.017014531418681145,
      0.022474894300103188,
      0.030872318893671036,
      -0.07248025387525558,
      -0.034577805548906326,
      -0.07843782007694244,
      0.021874915808439255,
      -0.01552756130695343,
      -0.040101058781147,
      0.05872148647904396,
      0.052602607756853104,
      0.03190002962946892,
      0.01182151772081852,
      -0.046049296855926514,
      -0.030921414494514465,
      -0.03173547610640526,
      -0.03605712950229645,
      -0.05100388079881668,
      -0.0401175357401371,
      -0.012931603007018566,
      -0.014311804436147213,
      -0.015225823037326336,
      0.00917103886604309,
      0.003146509872749448,
      0.08257806301116943,
      -0.08234609663486481,
      0.012286832556128502,
      0.0001086952761397697,
      0.061534639447927475,
      -0.10780942440032959,
      -0.07271258533000946,
      -0.03664790466427803,
      0.028433775529265404,
      -0.04539130628108978,
      0.07785903662443161,
      0.02504231035709381,
      0.013855550438165665,
      -0.0720721110701561,
      -0.03280121833086014,
      -0.002035594079643488,
      -0.03286382928490639,
      -0.0023393838200718164,
      -0.010583702474832535,
      0.000855288642924279,
      -0.006472064647823572,
      -0.010693137533962727,
      0.012043996714055538,
      -0.05264004319906235,
      0.0004979030345566571,
      0.012608283199369907,
      -0.026841355487704277,
      0.007685293909162283,
      -0.009130459278821945,
      -0.013254973106086254,
      0.03553455322980881,
      -0.022644735872745514,
      -0.02438361570239067,
      -0.010961158201098442,
      0.10137316584587097,
      0.07097094506025314,
      0.06210906431078911,
      0.013969365507364273,
      -0.04134024307131767,
      0.040562331676483154,
      0.07442528009414673,
      0.033664315938949585,
      -0.02411123365163803,
      -0.06857401877641678,
      -0.09317217767238617,
      -0.08066938817501068,
      0.01690514199435711,
      0.03688277676701546,
      0.01921474002301693,
      0.04011523351073265,
      -0.050554800778627396,
      0.017485368996858597,
      0.028080670163035393,
      -0.028245842084288597,
      0.034564949572086334,
      0.056651584804058075,
      -0.0015001530991867185,
      -0.040194910019636154,
      0.06076618656516075,
      0.02186385542154312,
      -0.059143971651792526,
      -0.010703125037252903,
      0.01914142072200775,
      0.05140814185142517,
      -0.07204866409301758,
      -0.029388772323727608,
      0.06693849712610245,
      0.00177131243981421,
      0.041250236332416534,
      0.026121269911527634,
      -0.0061570098623633385,
      0.04173888638615608,
      -0.023245809599757195,
      0.029899047687649727,
      -0.032289762049913406,
      0.04751778766512871
    ],
    [
      -0.045819349586963654,
      -0.05633529648184776,
      -0.0012407781323418021,
      -0.052314210683107376,
      0.002586444141343236,
      -0.02453080751001835,
      -0.060289815068244934,
      0.006252834107726812,
      -0.08090893179178238,
      0.08713116496801376,
      0.0019643947016447783,
      0.08274748921394348,
      0.003275251714512706,
      -0.04595267027616501,
      0.054456792771816254,
      0.011171694844961166,
      -0.020888300612568855,
      0.012856264598667622,
      0.02450481615960598,
      0.010587369091808796,
      0.02128288894891739,
      -0.02281072363257408,
      -0.041912976652383804,
      -0.018553441390395164,
      0.02812926657497883,
      0.016507603228092194,
      0.022779904305934906,
      0.03613969683647156,
      0.017455460503697395,
      -0.0064088632352650166,
      0.06268681585788727,
      -0.0750451385974884,
      0.013642416335642338,
      0.11198309808969498,
      -0.02695559896528721,
      -0.01581110991537571,
      -0.06250548362731934,
      0.013881601393222809,
      -0.011003001593053341,
      0.009142857044935226,
      0.08107548207044601,
      0.034014537930488586,
      0.023621156811714172,
      0.031817901879549026,
      0.03721132129430771,
      -0.0050216782838106155,
      -0.04911987483501434,
      0.002736287424340844,
      0.03926830366253853,
      -0.07256626337766647,
      -0.0030741754453629255,
      0.05544230714440346,
      0.022423937916755676,
      -0.045270003378391266,
      0.04615379497408867,
      0.0034362783189862967,
      0.0602191723883152,
      -0.022502943873405457,
      0.06717946380376816,
      0.03425826132297516,
      0.011856641620397568,
      0.0536922924220562,
      -0.045529965311288834,
      -0.07279971987009048,
      -0.011080937460064888,
      -0.038389693945646286,
      -0.03508751466870308,
      -0.011621193960309029,
      0.027359433472156525,
      -0.02192557603120804,
      0.00019364713807590306,
      -0.10371655970811844,
      0.06450709700584412,
      0.09252318739891052,
      -0.058591410517692566,
      0.010928243398666382,
      -0.05729350075125694,
      0.10795693099498749,
      -0.07849110662937164,
      0.03140305355191231,
      0.06783037632703781,
      -0.0763414055109024,
      0.03839615359902382,
      0.0025517723988741636,
      0.0349847674369812,
      -0.002632370451465249,
      0.03540115803480148,
      -0.02697112038731575,
      0.0032645119354128838,
      -0.06428997963666916,
      -0.04044342413544655,
      -0.004489980172365904,
      0.10077682882547379,
      0.04116743057966232,
      -0.023707635700702667,
      0.023790448904037476,
      -0.04136189445853233,
      -0.020295483991503716,
      -0.04717870056629181,
      -0.07086589187383652,
      -0.051844995468854904,
      0.028166387230157852,
      0.09319431334733963,
      0.027127671986818314,
      -0.1067473292350769,
      0.04999958723783493,
      0.0480094850063324,
      0.043777335435152054,
      0.00635741138830781,
      0.018625732511281967,
      0.058197859674692154,
      0.0372205413877964,
      0.05805698782205582,
      -0.002938763238489628,
      0.048864349722862244,
      -0.007116419728845358,
      -0.05160146206617355,
      0.025228351354599,
      0.06624345481395721,
      0.01585470512509346,
      0.03082652948796749,
      -0.031994495540857315,
      0.007892756722867489,
      -0.02477308362722397,
      0.03484942391514778,
      0.005397134460508823,
      0.03430088981986046,
      0.024377994239330292,
      -0.01950189843773842,
      0.01571875624358654,
      0.011713439598679543,
      -0.006858412176370621,
      -0.014929738827049732,
      0.03102719970047474,
      -0.035770636051893234,
      0.002437702612951398,
      0.0876239687204361,
      -0.018233798444271088,
      -0.005831353832036257,
      0.002121789613738656,
      0.013252563774585724,
      0.1334148645401001,
      0.04073471575975418,
      0.005983693990856409,
      -0.054405126720666885,
      -0.04070039838552475,
      0.04843185842037201,
      -0.038260895758867264,
      0.012566926889121532,
      -0.062292277812957764,
      -0.017175300046801567,
      -0.025470945984125137,
      0.011546510271728039,
      -0.06215926259756088,
      -0.038068678230047226,
      -0.064358651638031,
      -0.010967861860990524,
      -0.0012732648756355047,
      -0.008318845182657242,
      -0.01988539844751358,
      0.04102204740047455,
      -0.009588630869984627,
      -0.03788945823907852,
      -0.018576785922050476,
      0.02051767148077488,
      0.046051591634750366,
      -0.03369072452187538,
      0.04978437349200249,
      -0.0575203113257885,
      0.04502434283494949,
      -0.02764127589762211,
      -0.039524536579847336,
      0.07864154130220413,
      -0.0006359224207699299,
      -0.03710290417075157,
      -0.032298412173986435,
      -0.09926401078701019,
      -0.005699047353118658,
      -0.054640013724565506,
      0.03956453874707222,
      0.01761409267783165,
      -0.05937955901026726,
      -0.03129870817065239,
      0.057567086070775986,
      -0.05034709721803665,
      0.006087701767683029,
      0.04712233692407608,
      0.06734815984964371,
      -0.06363345682621002,
      -0.03524765744805336,
      -0.052577510476112366,
      -0.04043189063668251,
      0.01673436351120472,
      0.017337685450911522,
      -0.032441645860672,
      -0.027101824060082436,
      0.04392145574092865,
      0.07575929164886475,
      -0.03208751231431961,
      -0.038864411413669586,
      -0.06934953480958939,
      -0.024668827652931213,
      -0.007374898064881563,
      0.005055198445916176,
      0.04032866284251213,
      0.00457090325653553,
      -0.10364696383476257,
      0.007401550188660622,
      -0.06531255692243576,
      -0.0630982294678688,
      0.06726528704166412,
      -0.004719350952655077,
      0.0032611002679914236,
      0.05182249844074249,
      0.012266929261386395,
      0.03727687522768974,
      -0.06532104313373566,
      -0.020376652479171753,
      -0.08432549238204956,
      -0.0024984164629131556,
      -0.007180838845670223,
      -0.0637548416852951,
      -0.017067350447177887,
      -0.024071654304862022,
      -0.09660673886537552,
      -0.06101468577980995,
      -0.07992223650217056,
      0.036497533321380615,
      -0.04158521071076393,
      -0.0745435506105423,
      0.004560515750199556,
      0.043258387595415115,
      0.028506357222795486,
      -0.06537583470344543,
      0.060439806431531906,
      -0.01831025630235672,
      0.0233689583837986,
      -0.057296544313430786,
      -0.04016213119029999,
      -0.018697291612625122,
      -0.012980272993445396,
      -0.006188687868416309,
      -0.010333810932934284,
      0.05622728914022446,
      -0.06239514425396919,
      0.03903757408261299,
      -0.05533426254987717,
      0.04888242855668068,
      0.006010981742292643,
      -0.03219548240303993,
      0.024210186675190926,
      0.0707235038280487,
      -0.003781706327572465,
      0.026189379394054413,
      -0.0383947379887104,
      0.019992155954241753,
      0.03309912979602814,
      -0.04013305529952049,
      0.025677429512143135,
      -0.017767461016774178,
      0.0008465731516480446,
      -0.032386939972639084,
      0.037713855504989624,
      0.028495892882347107,
      0.020607274025678635,
      0.042095065116882324,
      0.050930824130773544,
      -0.07993993908166885,
      0.019587771967053413,
      0.05876590684056282,
      0.05439375713467598,
      -0.08885610103607178,
      -0.02097536437213421,
      -0.047518279403448105,
      -0.0067326659336686134,
      -0.07309699058532715,
      0.025914838537573814,
      0.026245353743433952,
      0.06536255031824112,
      -0.04021384194493294,
      0.03887302055954933,
      0.053025372326374054,
      0.058159321546554565,
      -0.029446803033351898,
      -0.05605466291308403,
      -0.106144979596138,
      -0.004960837308317423,
      0.08551893383264542,
      0.04376765340566635,
      -0.03458680957555771,
      -0.021561777219176292,
      -0.02990509197115898,
      0.06289727985858917,
      0.03415076434612274,
      -0.020113306120038033,
      0.03593985363841057,
      -0.015571905300021172,
      0.008234270848333836,
      -0.015012474730610847,
      -0.052773114293813705,
      0.015710996463894844,
      0.03846472129225731,
      0.03822401911020279,
      -0.010121684521436691,
      0.05977989733219147,
      0.07153692841529846,
      0.08420713990926743,
      0.0324459969997406,
      0.012392015196383,
      -0.016455909237265587,
      0.030131256207823753,
      0.06828443706035614,
      0.028517158702015877,
      0.05084293708205223,
      -0.019360467791557312,
      0.0056335353292524815,
      -0.06148851290345192,
      0.035046085715293884,
      0.014373757876455784,
      0.015543943271040916,
      -0.07475093007087708,
      0.020406849682331085,
      0.05804513767361641,
      0.010391837917268276,
      0.023409107699990273,
      -0.010022665373980999,
      0.06101471930742264,
      0.030567120760679245,
      0.017740176990628242,
      -0.03330119699239731,
      -0.06393000483512878,
      -0.05339803546667099,
      0.059752486646175385,
      -0.0005857541691511869,
      -0.04312356933951378,
      -0.02267967164516449,
      -0.029387371614575386,
      0.02581939287483692,
      -0.02423456870019436,
      0.031806908547878265,
      0.014256954193115234,
      0.028051016852259636,
      0.03141813725233078,
      -0.0815301239490509,
      -0.04623398184776306,
      -0.00678151985630393,
      -0.053131308406591415,
      0.0185620728880167,
      0.03145595267415047,
      -0.07745122909545898,
      0.03663850575685501,
      0.06889985501766205,
      -0.10627095401287079,
      0.05544000491499901,
      0.004618748091161251,
      -0.017920441925525665,
      -0.007535695564001799,
      -0.00046572674182243645,
      0.03577157109975815,
      0.0013627761509269476,
      -0.05286505073308945,
      0.0889032632112503,
      -0.019492216408252716,
      0.02595355734229088,
      -0.07963322848081589,
      0.0864366665482521,
      0.10007181763648987,
      -0.0071073067374527454,
      0.019574668258428574,
      0.020045984536409378,
      -0.028133008629083633,
      -0.05366123840212822,
      -0.050338272005319595,
      0.011287378147244453,
      -0.04109584167599678,
      -0.04615264758467674,
      -0.05263027921319008,
      -0.059128645807504654,
      -0.009398679248988628,
      -0.03890975937247276,
      0.06517279148101807,
      -0.0690382793545723,
      -0.014682325534522533,
      -0.005067832302302122,
      -0.026339873671531677,
      -0.08077996224164963,
      0.04264593869447708,
      -0.029519377276301384,
      -0.006662020459771156,
      0.07878179103136063,
      0.055801644921302795,
      -0.053972192108631134,
      0.0024301190860569477,
      -0.055111803114414215,
      -0.00574816158041358,
      0.016778623685240746,
      -0.04443790763616562,
      -0.022527053952217102,
      -0.061819303780794144,
      0.051802169531583786,
      -0.03142374008893967,
      -0.03294394165277481,
      -0.03258074074983597,
      -0.03941092640161514,
      0.024931982159614563,
      0.028632506728172302,
      -0.035112541168928146,
      0.025169892236590385,
      0.04306155815720558,
      -0.027811238542199135,
      -0.05223487690091133,
      -0.10468627512454987,
      -0.00555481156334281,
      0.029959093779325485,
      -0.04560131952166557,
      0.020771371200680733,
      0.018915269523859024,
      0.019954603165388107,
      -0.046449750661849976,
      -0.003354101674631238,
      0.0033804732374846935,
      -0.007284685503691435,
      -0.02181689254939556,
      -0.003223876003175974,
      -0.009578172117471695,
      -0.01990491710603237,
      0.011047609150409698,
      -0.08064265549182892,
      -0.03699956461787224,
      -0.03641352429986,
      0.039633601903915405,
      -0.0008215995039790869,
      0.05576203018426895,
      -0.0867614671587944,
      0.028551949188113213,
      -0.020443465560674667,
      -0.009284988045692444,
      -0.007785087917000055,
      -0.04839800298213959,
      -0.05109695717692375,
      -0.01911717653274536,
      0.03369995951652527,
      0.04592669755220413,
      -0.09209843724966049,
      0.018779663369059563,
      0.004924806300550699,
      0.0216937605291605,
      -9.189772390527651e-05,
      0.034000858664512634,
      0.0018209601985290647,
      -0.006197565235197544,
      -0.01294882595539093,
      -0.07007112354040146,
      0.030370375141501427,
      -0.03076556697487831,
      0.010638072155416012,
      0.03757535666227341,
      -0.027461715042591095,
      0.0034014529082924128,
      -0.004830845631659031,
      0.0013084730599075556,
      0.05654378980398178,
      -0.017286404967308044,
      0.05540073662996292,
      -0.034814078360795975,
      0.023552237078547478,
      0.016093699261546135,
      0.013615171425044537,
      -0.06153104454278946,
      0.055268656462430954,
      -0.03296986222267151,
      -0.049709223210811615,
      -0.04678160324692726,
      -0.003355991095304489,
      0.032324157655239105,
      0.03234061226248741,
      0.08154419809579849,
      -0.058398932218551636,
      0.02916133403778076,
      0.048494044691324234,
      -0.011252718977630138,
      -0.01379777118563652,
      -0.0347420908510685,
      0.02648293599486351,
      0.02999899350106716,
      0.014146897941827774,
      0.10668548196554184,
      -0.03985048830509186,
      0.03890912979841232,
      -0.038641974329948425,
      0.0020564019214361906,
      0.018129775300621986,
      0.04339054599404335,
      0.021433012560009956,
      -0.012974114157259464,
      0.0006212953012436628,
      -0.0009551150142215192,
      0.00702713243663311,
      0.03132972866296768,
      0.02223803848028183,
      -0.07537610083818436,
      -0.013031399808824062,
      -0.024025216698646545
    ],
    [
      0.02384979836642742,
      0.021660786122083664,
      -0.005205992143601179,
      0.05328512564301491,
      -0.04019029438495636,
      -0.07518875598907471,
      -0.023995740339159966,
      0.0689978301525116,
      0.07702257484197617,
      0.0066749705001711845,
      0.05127141252160072,
      0.06461499631404877,
      -0.009511696174740791,
      0.009131456725299358,
      -0.001108977827243507,
      0.08690474927425385,
      -0.0990244448184967,
      -0.0015913575189188123,
      0.0969887226819992,
      -0.03073284588754177,
      -0.03845475614070892,
      -0.01818970777094364,
      -0.03228549659252167,
      -0.013926330022513866,
      0.04618274047970772,
      -0.027376780286431313,
      0.028657197952270508,
      -0.03451558202505112,
      0.06657437235116959,
      -0.11298563331365585,
      0.06161818653345108,
      0.020864292979240417,
      0.03366542235016823,
      0.045285437256097794,
      0.0009112702100537717,
      -0.027088506147265434,
      0.09305670857429504,
      0.0037738229148089886,
      -0.09601589292287827,
      0.02228819951415062,
      0.10858900845050812,
      0.028126416727900505,
      -0.0197606198489666,
      -0.0780797228217125,
      0.06704655289649963,
      -0.0061200689524412155,
      0.01240870263427496,
      -0.004082227125763893,
      0.06204020977020264,
      -0.019677648320794106,
      -0.05753453075885773,
      -0.02983732335269451,
      0.055281076580286026,
      -0.04739823937416077,
      0.03501790761947632,
      0.037717849016189575,
      0.008326194249093533,
      0.012270866893231869,
      0.0926215797662735,
      -0.056129079312086105,
      -0.024641744792461395,
      -0.0674067810177803,
      0.0467340350151062,
      0.021947160363197327,
      -0.14190639555454254,
      -0.019048454239964485,
      -0.04269496724009514,
      0.017885996028780937,
      -0.05249251425266266,
      0.0308307446539402,
      -0.04960767552256584,
      0.013913874514400959,
      -0.01045008935034275,
      0.03728166222572327,
      0.011159487068653107,
      -0.04526889696717262,
      0.037530843168497086,
      0.0029434359166771173,
      -0.020180614665150642,
      -0.05071457847952843,
      0.07530006021261215,
      -0.04151664674282074,
      0.01197497546672821,
      -0.051681533455848694,
      -0.01626150868833065,
      -0.06836756318807602,
      0.003964514471590519,
      -0.005539301782846451,
      0.11690473556518555,
      -0.041117265820503235,
      0.003553974675014615,
      0.0022547829430550337,
      0.010386655107140541,
      -0.07841862738132477,
      -0.011709487996995449,
      -0.042620521038770676,
      -0.04675649479031563,
      -0.06261707842350006,
      0.005673261824995279,
      -0.06736856698989868,
      0.07499287277460098,
      -0.0070210471749305725,
      0.02041255123913288,
      0.040260933339595795,
      -0.017121000215411186,
      0.1067693680524826,
      -0.06156785041093826,
      0.00017639175348449498,
      -0.08783940225839615,
      -0.01469888724386692,
      0.04240519925951958,
      0.01641412451863289,
      -0.01183043047785759,
      -0.024459505453705788,
      0.026501178741455078,
      0.02853681705892086,
      -0.11134425550699234,
      -0.0010344079928472638,
      -0.03605097159743309,
      0.000911647395696491,
      0.01268068514764309,
      -0.000461367832031101,
      0.007460040505975485,
      0.10704179853200912,
      -0.012962582521140575,
      0.045446161180734634,
      -0.06293842941522598,
      0.0032395552843809128,
      0.014809266664087772,
      0.027569282799959183,
      -0.052245695143938065,
      0.061863500624895096,
      -0.00578018743544817,
      -0.08062844723463058,
      -0.04822823032736778,
      -0.11221034824848175,
      0.006617431528866291,
      -0.10735975205898285,
      0.012992126867175102,
      0.09947995841503143,
      -0.0334165021777153,
      0.054377973079681396,
      -0.05492428317666054,
      0.009899523109197617,
      0.03369978442788124,
      -0.03365457057952881,
      0.049089089035987854,
      0.054655011743307114,
      0.003264979226514697,
      -0.00560801150277257,
      0.029803462326526642,
      0.0014478153316304088,
      -0.05207596346735954,
      -0.009798137471079826,
      -0.007607121951878071,
      0.02179172821342945,
      0.006639082916080952,
      -0.07944847643375397,
      -0.003962822258472443,
      -0.02180461212992668,
      0.027693146839737892,
      0.07312563061714172,
      -0.05955146253108978,
      -0.08584994077682495,
      0.06981617957353592,
      0.022853737697005272,
      0.017380019649863243,
      0.025374289602041245,
      -0.004489758051931858,
      0.07366139441728592,
      0.016801655292510986,
      -0.0004887377726845443,
      0.004633018746972084,
      -0.02455732226371765,
      0.034527186304330826,
      0.05626743286848068,
      -0.07686415314674377,
      0.03253921493887901,
      0.03164402395486832,
      0.00800593663007021,
      -0.12230511754751205,
      -0.024455763399600983,
      -0.045029036700725555,
      -0.004223248455673456,
      -0.040751997381448746,
      -0.036381788551807404,
      -0.05129107087850571,
      -0.061435312032699585,
      0.029038894921541214,
      0.05158507823944092,
      0.05846680328249931,
      -0.09930948168039322,
      0.06288106739521027,
      0.012031357735395432,
      -0.009670783765614033,
      -0.0031910636462271214,
      0.008021489717066288,
      -0.0022321725264191628,
      -0.020166320726275444,
      -0.05273139476776123,
      0.011007768101990223,
      0.02093818597495556,
      -0.011897285468876362,
      0.05483858659863472,
      0.043428514152765274,
      0.010492091998457909,
      -0.08687791973352432,
      0.007182469125837088,
      0.07622460275888443,
      0.019496843218803406,
      -0.05447221174836159,
      0.03156859055161476,
      -0.0477798655629158,
      -0.027418838813900948,
      -0.01568976417183876,
      0.0014498669188469648,
      -0.0038670082576572895,
      -0.020776528865098953,
      0.01643337495625019,
      -0.013194635510444641,
      -0.05753164738416672,
      0.02361925318837166,
      -0.03582353889942169,
      -0.053796447813510895,
      0.01988597959280014,
      -0.0018594255670905113,
      0.0022798737045377493,
      -0.0787210464477539,
      -0.04083705693483353,
      0.011122677475214005,
      0.03457937017083168,
      0.018004486337304115,
      0.04102125018835068,
      0.003224582178518176,
      -0.011784600093960762,
      -0.034322917461395264,
      0.011823032051324844,
      -0.0013511114520952106,
      -0.006419159937649965,
      0.01587807573378086,
      2.2953576262807474e-05,
      -0.032918404787778854,
      0.01753571443259716,
      -0.021137746050953865,
      0.004205726552754641,
      -0.0677972137928009,
      0.011818489991128445,
      -0.039886973798274994,
      0.06678943336009979,
      0.04254317283630371,
      -0.032072313129901886,
      -0.04466499760746956,
      -0.044877465814352036,
      0.029838714748620987,
      0.0691557303071022,
      0.08569196611642838,
      -0.006196248810738325,
      0.03527882695198059,
      -0.026427894830703735,
      -0.03474456071853638,
      0.01650296151638031,
      -0.04701779782772064,
      -0.028549257665872574,
      0.013382570818066597,
      -0.06292576342821121,
      0.023867830634117126,
      -0.13250604271888733,
      -0.05502120405435562,
      0.007781316060572863,
      0.013949308544397354,
      -0.029519280418753624,
      0.019438790157437325,
      0.008833508007228374,
      -0.00807186309248209,
      -0.06358809024095535,
      -0.06253625452518463,
      -0.024402622133493423,
      -0.03989839553833008,
      -0.04997943714261055,
      -0.06297135353088379,
      0.0673234835267067,
      0.020033840090036392,
      -0.02853303775191307,
      0.002581978216767311,
      -0.009389368817210197,
      0.07668767124414444,
      0.039618123322725296,
      0.056640177965164185,
      0.005049732513725758,
      -0.1036621555685997,
      -0.04814649373292923,
      0.012777861207723618,
      0.03682662919163704,
      -0.07044916599988937,
      -0.04696965962648392,
      0.01770724169909954,
      -0.06612329185009003,
      -0.01967535726726055,
      -0.07685885578393936,
      0.06817520409822464,
      -0.025659902021288872,
      0.02783174254000187,
      0.015750747174024582,
      0.043041881173849106,
      0.02000744640827179,
      -0.08159582316875458,
      0.008421962149441242,
      0.04920795187354088,
      -0.02929609827697277,
      0.01692224107682705,
      0.010595561936497688,
      -0.003769676201045513,
      0.0693567544221878,
      -0.03481908515095711,
      -0.015401464886963367,
      0.0027181031182408333,
      -0.00027444856823422015,
      0.01774599961936474,
      0.0027547753416001797,
      -0.028361046686768532,
      -0.06490863114595413,
      0.031182745471596718,
      -0.015444755554199219,
      -0.047620516270399094,
      0.002666537184268236,
      -0.027316998690366745,
      -0.07359634339809418,
      0.03029918298125267,
      0.02165832743048668,
      -0.05599706247448921,
      -0.09031090885400772,
      0.09440682828426361,
      -0.030733244493603706,
      -0.050749145448207855,
      -0.052235618233680725,
      -0.05897516384720802,
      0.01605481654405594,
      -0.03449542820453644,
      -0.01950339786708355,
      -0.014194422401487827,
      0.03629055246710777,
      0.08047009259462357,
      0.0005864155245944858,
      0.04064369946718216,
      -0.008694730699062347,
      0.03245903551578522,
      0.0020860624499619007,
      0.03230166435241699,
      0.06394697725772858,
      0.031976405531167984,
      -0.010883820243179798,
      -0.0513228140771389,
      -0.045371439307928085,
      -0.03841762989759445,
      -0.003285480896010995,
      -0.08287660777568817,
      0.025031298398971558,
      -0.05157177522778511,
      0.013717852532863617,
      -0.005569623317569494,
      -0.016666267067193985,
      0.02770209312438965,
      -0.030872097238898277,
      -0.04733956232666969,
      -0.040607303380966187,
      0.043404679745435715,
      -0.09779434651136398,
      -0.05327123403549194,
      -0.06826894730329514,
      -0.006192282307893038,
      -0.05336519330739975,
      -0.0051199342124164104,
      -0.03247752785682678,
      0.011647897772490978,
      0.037440259009599686,
      0.04572834074497223,
      -0.02488037198781967,
      0.019838914275169373,
      0.017990194261074066,
      -0.01676059700548649,
      0.086882583796978,
      -0.03665969520807266,
      -0.0485864095389843,
      0.009736914187669754,
      -0.014828751794993877,
      0.021606873720884323,
      -0.049393340945243835,
      -0.06184278428554535,
      -0.03381703421473503,
      0.00030887138564139605,
      0.019443536177277565,
      -0.033612385392189026,
      0.05167573690414429,
      -0.005134870298206806,
      0.04360349476337433,
      -0.021506477147340775,
      -0.044485922902822495,
      0.04708682373166084,
      0.034660179167985916,
      -0.015149926766753197,
      0.019731445237994194,
      0.05425555631518364,
      0.048185043036937714,
      0.03932517394423485,
      -0.021938160061836243,
      -0.03155210614204407,
      0.020428428426384926,
      -0.020201222971081734,
      0.012475559487938881,
      -0.02015671692788601,
      0.0072592333890497684,
      -0.02140108123421669,
      -0.0008911994518712163,
      -0.0458148717880249,
      0.061343468725681305,
      0.05061516538262367,
      -0.032170843333005905,
      0.014747722074389458,
      0.0240816418081522,
      -0.0512634739279747,
      -0.07132922857999802,
      0.04435938596725464,
      0.007730874232947826,
      -0.009324091486632824,
      0.014405909925699234,
      0.0027859790716320276,
      -0.041341882199048996,
      0.08327959477901459,
      0.03307520970702171,
      -0.04055694490671158,
      -0.05643264576792717,
      -0.04282240942120552,
      -0.024394890293478966,
      -0.06086248904466629,
      -0.07920641452074051,
      0.011268588714301586,
      0.09127926081418991,
      0.03566155210137367,
      0.0008022640831768513,
      0.08001737296581268,
      -0.0343276783823967,
      -0.0041654533706605434,
      0.06271494925022125,
      0.01502228993922472,
      0.03672284260392189,
      -0.048693180084228516,
      -0.03778454661369324,
      -0.0008899150416254997,
      -0.0247868113219738,
      -0.02664361521601677,
      0.005248702131211758,
      0.0518682561814785,
      -0.03801261633634567,
      0.0028445322532206774,
      -0.04034431651234627,
      -0.04803582280874252,
      -0.06785465031862259,
      -0.026882430538535118,
      0.039007529616355896,
      -0.07340769469738007,
      -0.08890750259160995,
      -0.03142903000116348,
      -0.017333513125777245,
      -0.013974323868751526,
      0.01796741783618927,
      -0.032425474375486374,
      0.0034006843343377113,
      0.04199370741844177,
      -0.024392787367105484,
      0.010052988305687904,
      0.026740042492747307,
      -0.016175661236047745,
      -0.12055262178182602,
      -0.042458560317754745,
      0.028786925598978996,
      -0.024623852223157883,
      -0.02677897736430168,
      -0.08390844613313675,
      0.06031467020511627,
      0.025160085409879684,
      -0.030104992911219597,
      0.00260291644372046,
      0.03931932896375656,
      0.0001508344430476427,
      -0.02969471365213394,
      -0.014553824439644814,
      -0.0719221755862236,
      0.04422881826758385,
      -0.07270902395248413,
      0.06284525990486145,
      -0.026254594326019287,
      0.03655938431620598,
      -0.012036599218845367,
      0.06504078209400177,
      0.04226138815283775,
      -0.006548505276441574,
      -0.1065998524427414,
      -0.0045169685035943985,
      -0.052484020590782166,
      0.05550765618681908,
      0.005719267297536135,
      0.0227806456387043,
      0.022788155823946
    ],
    [
      0.08353610336780548,
      0.07823212444782257,
      -0.047116074711084366,
      -0.014323902316391468,
      0.08748044073581696,
      0.03969800844788551,
      -0.01626715622842312,
      -0.01029647421091795,
      0.034429825842380524,
      -0.003303361823782325,
      -0.05174658074975014,
      0.016093140468001366,
      -0.02271023392677307,
      0.061782337725162506,
      -0.03134014829993248,
      0.032080747187137604,
      -0.024000292643904686,
      0.05222317576408386,
      -0.021615823730826378,
      -0.03416121378540993,
      -0.045843105763196945,
      -0.057962242513895035,
      0.02656572312116623,
      0.014838442206382751,
      -0.06934043765068054,
      0.05890432372689247,
      0.049399323761463165,
      0.028247946873307228,
      0.08333150297403336,
      -0.006373061798512936,
      0.0030205429065972567,
      -0.0197603702545166,
      -0.029738351702690125,
      0.0918373167514801,
      -0.018347427248954773,
      -0.004167764913290739,
      0.04418490082025528,
      0.05966856703162193,
      0.014125610701739788,
      0.02535250596702099,
      0.03301389142870903,
      -0.018397249281406403,
      0.033661022782325745,
      0.07361650466918945,
      0.04737986624240875,
      -0.033451374620199203,
      0.07633846998214722,
      -0.0033853682689368725,
      0.07085558772087097,
      0.044285327196121216,
      0.025612452998757362,
      0.06187044084072113,
      -0.01792067661881447,
      0.025348961353302002,
      0.0014092028141021729,
      0.006934379693120718,
      0.040892284363508224,
      0.021180151030421257,
      -0.028923969715833664,
      -0.01720867119729519,
      0.014987952075898647,
      0.04833859205245972,
      0.022623613476753235,
      -0.03419990465044975,
      0.04742446914315224,
      0.017939874902367592,
      -0.0644056424498558,
      0.0014449665322899818,
      -0.05789460241794586,
      -0.07047230750322342,
      -0.036969173699617386,
      -0.05889330431818962,
      -0.02691587805747986,
      -0.09862527251243591,
      -0.020478084683418274,
      -0.04261958971619606,
      0.015322997234761715,
      0.04160531610250473,
      0.11145573854446411,
      0.02584039606153965,
      0.01992877572774887,
      -0.11281932890415192,
      0.03296933323144913,
      0.03491266816854477,
      0.06233995035290718,
      0.0020402753725647926,
      -0.038185399025678635,
      -0.1237986832857132,
      0.05595581233501434,
      0.07670367509126663,
      -0.056626833975315094,
      0.02741033397614956,
      -0.09498535096645355,
      -0.015859350562095642,
      -0.00020728626986965537,
      -0.019449230283498764,
      0.034215740859508514,
      -0.08421187847852707,
      0.015205184929072857,
      -0.058051858097314835,
      0.022347401827573776,
      -0.028953466564416885,
      0.05097013711929321,
      -0.03321878984570503,
      -0.035094182938337326,
      -0.04055727645754814,
      0.030504124239087105,
      -0.0067558689042925835,
      -0.04116049408912659,
      0.045863956212997437,
      0.040740303695201874,
      0.0595657043159008,
      -0.047220077365636826,
      0.032793380320072174,
      0.010982477106153965,
      -0.04751040041446686,
      -0.0607885867357254,
      0.03915983811020851,
      0.09940554946660995,
      0.0719715878367424,
      0.020003505051136017,
      0.03565872088074684,
      0.06389458477497101,
      -0.059643298387527466,
      0.035734180361032486,
      0.013037472032010555,
      0.011382520198822021,
      -0.037730488926172256,
      -0.03475509211421013,
      0.02093350514769554,
      0.028681199997663498,
      -0.02632555179297924,
      -0.08148083835840225,
      0.09685134142637253,
      0.01998927816748619,
      0.055335238575935364,
      0.05271611735224724,
      0.0008152730297297239,
      0.09925641119480133,
      0.014674250036478043,
      -0.0015556521248072386,
      0.026547525078058243,
      -0.018043063580989838,
      -0.10405933111906052,
      0.013196449726819992,
      0.0009727566502988338,
      0.11300768703222275,
      0.03451795130968094,
      0.0336822010576725,
      -0.04980003461241722,
      0.02359146624803543,
      0.01803959347307682,
      0.05744064971804619,
      -0.01299232617020607,
      -0.1202646866440773,
      -0.029782332479953766,
      -0.0027929157949984074,
      0.021953534334897995,
      -0.058478355407714844,
      0.009032398462295532,
      -0.01853751391172409,
      -0.05344075337052345,
      -0.08408572524785995,
      0.049700502306222916,
      -0.01147184893488884,
      0.0011804546229541302,
      -0.030516164377331734,
      0.0494229793548584,
      0.003033154411241412,
      -0.051861897110939026,
      -0.023274600505828857,
      -0.034411877393722534,
      -0.05487782508134842,
      0.042209796607494354,
      0.08968290686607361,
      0.012436989694833755,
      -0.06266111880540848,
      0.059043046087026596,
      -0.02713775262236595,
      -0.016483837738633156,
      0.022371675819158554,
      -0.00610017403960228,
      0.035226404666900635,
      -0.006390358787029982,
      -0.07515355199575424,
      0.06661058962345123,
      0.03595425561070442,
      -0.024330811575055122,
      -0.013853310607373714,
      -0.0070810262113809586,
      -0.10967618972063065,
      -0.06085645407438278,
      0.008580327033996582,
      -0.01694115251302719,
      -0.03765008598566055,
      -0.03621645271778107,
      0.030596159398555756,
      0.008167601190507412,
      -0.024633998051285744,
      0.07776764780282974,
      -0.06220725551247597,
      -0.013995042070746422,
      0.04095277935266495,
      0.03180092200636864,
      0.06671085953712463,
      -0.0029546660371124744,
      -0.03046170063316822,
      -0.005759327672421932,
      -0.0633353665471077,
      0.003966363612562418,
      0.027492234483361244,
      -0.008053158409893513,
      0.06882116198539734,
      -0.029790259897708893,
      0.009559893049299717,
      0.003829865250736475,
      0.011290576308965683,
      0.05530731752514839,
      -0.02730201929807663,
      0.013880046084523201,
      -0.008910659700632095,
      0.012879684567451477,
      0.03738990053534508,
      0.03522100672125816,
      -0.018040766939520836,
      -0.011214149184525013,
      0.008188307285308838,
      -0.0280663650482893,
      -0.02096744254231453,
      0.007000724319368601,
      -0.023164646700024605,
      0.05112446844577789,
      -0.0070908693596720695,
      0.03840092942118645,
      -0.031167317181825638,
      0.06604211032390594,
      -0.012764173559844494,
      0.0008476608782075346,
      -0.01598518341779709,
      -0.060260821133852005,
      -0.026491140946745872,
      -0.006549540441483259,
      0.04048099368810654,
      0.022581681609153748,
      0.09633628278970718,
      0.022490767762064934,
      0.06134085729718208,
      0.0027915097307413816,
      0.01989930495619774,
      -0.0025834140833467245,
      -0.022583868354558945,
      -0.03821052983403206,
      -0.0035125629510730505,
      0.05793892964720726,
      -0.012708760797977448,
      0.012173036113381386,
      -0.005546733271330595,
      -0.049949828535318375,
      -0.0061821830458939075,
      0.044859468936920166,
      0.06271985173225403,
      0.05762616544961929,
      0.06173558905720711,
      0.015763726085424423,
      -0.031235190108418465,
      -0.015884429216384888,
      0.01616992987692356,
      0.015102948993444443,
      -0.08916261792182922,
      -0.01671457290649414,
      0.006743047386407852,
      0.047270409762859344,
      0.03752594441175461,
      0.016325736418366432,
      0.010748418979346752,
      0.03833821415901184,
      0.043392330408096313,
      -0.009377127513289452,
      0.027692146599292755,
      -0.04564712569117546,
      -0.03959452733397484,
      -0.009256411343812943,
      0.0659419372677803,
      -0.04741143062710762,
      0.044553570449352264,
      0.07193061709403992,
      -0.001034381566569209,
      -0.02595188282430172,
      -0.002354839351028204,
      -0.018950041383504868,
      0.03564327210187912,
      -0.06646712869405746,
      0.060349155217409134,
      -0.04207964614033699,
      -0.0351220928132534,
      0.10414490848779678,
      -0.06385107338428497,
      -0.005372089333832264,
      0.0004895577440038323,
      0.020974263548851013,
      -0.025342388078570366,
      -0.025888025760650635,
      -0.0021006674505770206,
      -0.10551988333463669,
      0.09542743116617203,
      -0.017761914059519768,
      -0.050599876791238785,
      0.0988059937953949,
      0.0667085200548172,
      -0.031895775347948074,
      0.018922248855233192,
      0.0363125205039978,
      -0.060316164046525955,
      0.055494390428066254,
      0.101107656955719,
      0.033069003373384476,
      -0.044157661497592926,
      -0.03544044867157936,
      0.030916735529899597,
      -0.0011860543163493276,
      -0.09946741163730621,
      -0.047552864998579025,
      -0.022436611354351044,
      -0.020173722878098488,
      -0.02760034054517746,
      -0.061926841735839844,
      -0.05965476110577583,
      0.025750188156962395,
      -0.11857981234788895,
      0.004572047386318445,
      -0.05814458429813385,
      -0.031645774841308594,
      -0.05847778171300888,
      -0.030309690162539482,
      0.03847811371088028,
      -0.06404636800289154,
      0.020907031372189522,
      -0.08123746514320374,
      0.047967519611120224,
      0.042591556906700134,
      0.047216206789016724,
      -0.11341699212789536,
      0.04027590900659561,
      0.08563464134931564,
      -0.01207105815410614,
      0.001329279039055109,
      0.0006520604947581887,
      0.040408942848443985,
      -0.01445410493761301,
      -0.05964810773730278,
      0.046247124671936035,
      -0.03309542313218117,
      -0.03412020578980446,
      0.02545034885406494,
      -0.0258457213640213,
      0.017440004274249077,
      -0.07005462795495987,
      -0.09155797213315964,
      -0.008001217618584633,
      -0.032255224883556366,
      0.02415832318365574,
      0.0037443796172738075,
      -0.09158965200185776,
      -0.094899982213974,
      0.06636400520801544,
      0.016160648316144943,
      -0.10540391504764557,
      -0.03989424556493759,
      0.026471849530935287,
      -0.010668356902897358,
      0.05466782674193382,
      0.06191389635205269,
      -0.10048014670610428,
      -0.03802347928285599,
      0.03282974287867546,
      -0.05221345275640488,
      0.01578211411833763,
      0.0029753600247204304,
      0.11538860946893692,
      -0.020481348037719727,
      -0.013587863184511662,
      0.032763876020908356,
      0.03276369348168373,
      -0.025141824036836624,
      0.001518574426881969,
      0.03622479736804962,
      0.07571044564247131,
      0.025996282696723938,
      0.07471755892038345,
      0.035505227744579315,
      0.030034121125936508,
      -0.04412846267223358,
      -0.04593781754374504,
      -0.015984833240509033,
      -0.005988248158246279,
      0.004029575269669294,
      -0.013756759464740753,
      0.05305197462439537,
      -0.04357755184173584,
      0.04057693108916283,
      -0.022368239238858223,
      -0.04118474945425987,
      0.02855663187801838,
      -0.04007052630186081,
      -0.006459254305809736,
      -0.0519532710313797,
      0.026536203920841217,
      -0.06241059675812721,
      0.0007045487291179597,
      0.06378484517335892,
      0.02898670919239521,
      0.08200148493051529,
      -0.07341578602790833,
      0.04251879081130028,
      -0.024973658844828606,
      -0.08415352553129196,
      -0.07907478511333466,
      0.007017452735453844,
      0.031779419630765915,
      0.05697433650493622,
      -0.027559692040085793,
      -0.02751917578279972,
      -0.016922395676374435,
      -0.0033340295776724815,
      -0.014007668010890484,
      -0.0679110586643219,
      -0.006164989434182644,
      -0.027524473145604134,
      0.024461498484015465,
      -0.00017092529742512852,
      -0.005549705121666193,
      -0.057030245661735535,
      0.08201755583286285,
      -0.05565723776817322,
      0.024429209530353546,
      -0.04729226976633072,
      0.00924215093255043,
      -0.009147760458290577,
      0.02637597732245922,
      0.0035083473194390535,
      -0.0416824035346508,
      -0.004285073839128017,
      -0.058832794427871704,
      -0.08001351356506348,
      0.010709521360695362,
      -0.025898603722453117,
      0.015989283099770546,
      -0.04851948469877243,
      -0.04704012721776962,
      0.024889955297112465,
      0.08996032178401947,
      0.0032744714990258217,
      -0.05904272943735123,
      -0.005282596684992313,
      0.08324820548295975,
      0.05544080212712288,
      -0.01193323079496622,
      -0.05029608681797981,
      0.056661393493413925,
      0.10353479534387589,
      -0.017441285774111748,
      0.002272239653393626,
      0.04447955638170242,
      -0.009744619950652122,
      0.05909945070743561,
      -0.009733106009662151,
      0.01208591554313898,
      -0.004997838754206896,
      -0.031602051109075546,
      -0.005332242231816053,
      0.009953244589269161,
      -0.06776725500822067,
      0.008163757622241974,
      -0.03544768691062927,
      -0.0006349189789034426,
      0.011251414194703102,
      -0.026226721704006195,
      0.0948130339384079,
      0.02108698897063732,
      0.05451519787311554,
      -0.06453151255846024,
      -0.02145942486822605,
      -0.03626783564686775,
      -0.058690499514341354,
      -0.06038644164800644,
      -0.08503635972738266,
      0.05568382889032364,
      -0.011983933858573437,
      -0.0010318778222426772,
      -0.06345666199922562,
      -0.054774537682533264,
      -0.02920476906001568,
      -0.04069654643535614,
      0.03858646750450134,
      0.0025541577488183975,
      0.09778093546628952,
      -0.05911703407764435,
      0.03446466103196144,
      0.032980289310216904,
      -0.048258230090141296,
      -0.05683512985706329,
      0.054691702127456665,
      0.03575020283460617
    ],
    [
      -0.03804905340075493,
      -0.08013381063938141,
      0.041131600737571716,
      -0.0028357321862131357,
      -0.07995527982711792,
      0.001702610868960619,
      -0.0005640286253765225,
      0.03559480607509613,
      -0.012028768658638,
      0.0249940138310194,
      0.004458886105567217,
      0.022990616038441658,
      0.07226812839508057,
      0.01811475306749344,
      0.0887957215309143,
      -0.032866157591342926,
      -0.004238618537783623,
      -0.0354507714509964,
      -0.05605205520987511,
      -0.06161576136946678,
      0.006791580002754927,
      0.048996277153491974,
      -0.02841321937739849,
      0.00800422951579094,
      -0.0342094786465168,
      0.03699203580617905,
      0.05340017378330231,
      0.056606411933898926,
      0.02964874356985092,
      0.02891472727060318,
      -0.003331036539748311,
      0.037920814007520676,
      0.06431720405817032,
      -0.0756567195057869,
      -0.02529836632311344,
      0.03454268351197243,
      -0.04031854495406151,
      -0.007969824597239494,
      -0.01790628395974636,
      0.04736733436584473,
      0.09561346471309662,
      -0.038144949823617935,
      0.046706248074769974,
      -0.0057690683752298355,
      0.09981638938188553,
      -0.009540431201457977,
      0.03530269116163254,
      -0.03424781933426857,
      -0.05371953919529915,
      -0.021357662975788116,
      0.02951323799788952,
      0.038888122886419296,
      0.07737787067890167,
      -0.06868398189544678,
      -0.06080532819032669,
      -0.0415225550532341,
      -0.011451962403953075,
      -0.018505370244383812,
      0.058497101068496704,
      0.04730934277176857,
      0.0216640867292881,
      0.04172157496213913,
      0.03710515424609184,
      0.03827257081866264,
      0.05868068337440491,
      0.036728955805301666,
      0.024103224277496338,
      0.032740287482738495,
      -0.015069941058754921,
      -0.005340244621038437,
      0.0008591943187639117,
      -0.023107221350073814,
      0.015996074303984642,
      0.04214908182621002,
      0.026827123016119003,
      0.04920947179198265,
      0.014073112979531288,
      0.026535360142588615,
      0.04698573052883148,
      -0.033747199922800064,
      0.03414766490459442,
      0.013628369197249413,
      0.005825910251587629,
      -0.049034424126148224,
      0.04596448317170143,
      -0.049441806972026825,
      0.02793925814330578,
      0.007711198180913925,
      0.02640428952872753,
      0.08255720883607864,
      -0.03108818270266056,
      -0.012366993352770805,
      0.041791390627622604,
      0.055593933910131454,
      0.026271218433976173,
      0.020185405388474464,
      -0.0006161415367387235,
      0.0037309550680220127,
      -0.00766717130318284,
      -0.04885883256793022,
      -0.047801170498132706,
      0.013711386360228062,
      0.03852849826216698,
      0.010957710444927216,
      0.0494462326169014,
      -0.030837541446089745,
      0.003309080842882395,
      -0.06521432101726532,
      0.01117605809122324,
      0.0352870412170887,
      0.05294444039463997,
      -0.05044284835457802,
      -0.00438323337584734,
      -0.018314683809876442,
      -0.006971416529268026,
      0.10267345607280731,
      -0.001547608058899641,
      0.014305288903415203,
      -0.0672915130853653,
      -0.012972834520041943,
      0.07230350375175476,
      -0.0012186617823317647,
      0.16922292113304138,
      0.02417280338704586,
      0.011108838953077793,
      -0.035525787621736526,
      -0.06319928914308548,
      0.07711455971002579,
      -0.06707772612571716,
      -0.10739512741565704,
      -0.03269239887595177,
      -0.02598070725798607,
      -0.0014335154555737972,
      0.013076136820018291,
      -0.0799318477511406,
      -0.0864960327744484,
      0.02309475652873516,
      0.07758031040430069,
      -0.06545936316251755,
      0.019299130886793137,
      -0.04892489314079285,
      0.00497900415211916,
      0.047877974808216095,
      0.03315763548016548,
      -0.0012840594863519073,
      0.06126213073730469,
      -0.049288004636764526,
      0.003128030803054571,
      0.12465231865644455,
      -0.002400984289124608,
      -0.031328655779361725,
      -0.06600936502218246,
      0.00841554719954729,
      0.01094855647534132,
      -0.023981302976608276,
      -0.005318326875567436,
      0.013525635004043579,
      0.1034252867102623,
      -0.028154654428362846,
      0.021617067977786064,
      0.010859978385269642,
      0.08513107150793076,
      -0.024412570521235466,
      0.06162470579147339,
      0.08302135765552521,
      0.005329868756234646,
      0.014942708425223827,
      0.06381126493215561,
      -0.024133481085300446,
      0.005700407549738884,
      -0.06799519062042236,
      -0.004481854848563671,
      -0.02010735310614109,
      0.056135646998882294,
      0.04166288301348686,
      -0.013247683644294739,
      0.04278971254825592,
      0.039841875433921814,
      -0.008260698057711124,
      -0.03528568893671036,
      0.008144961670041084,
      0.0753442570567131,
      -0.01687033288180828,
      -0.03254753351211548,
      0.0694604367017746,
      0.035624679177999496,
      -0.01399699691683054,
      0.01654013805091381,
      -0.046045951545238495,
      -0.001205923967063427,
      -0.029499981552362442,
      -0.014260552823543549,
      -0.02953083999454975,
      -0.030955258756875992,
      -0.04842117056250572,
      -0.053746674209833145,
      -0.0679028108716011,
      -0.055414218455553055,
      0.03781631588935852,
      -0.042575202882289886,
      -0.0013381631579250097,
      0.04963229224085808,
      -0.10139483958482742,
      0.03215115889906883,
      0.040037255734205246,
      0.01578495465219021,
      -0.04942227154970169,
      -0.01723819598555565,
      -0.009227730333805084,
      0.003454603021964431,
      -0.09061495214700699,
      0.024173986166715622,
      0.08366416394710541,
      -0.00467967614531517,
      -0.028728604316711426,
      -0.040880728513002396,
      0.04728696867823601,
      0.006918817758560181,
      0.001014342880807817,
      0.026980219408869743,
      -0.0009888953063637018,
      -0.03350316733121872,
      -0.014382347464561462,
      -0.023963719606399536,
      -0.05757841840386391,
      -0.02499591000378132,
      -0.062469638884067535,
      -0.0661553144454956,
      -0.013189450837671757,
      0.049626294523477554,
      -0.08098521828651428,
      0.054683007299900055,
      -0.04983915761113167,
      -0.06389310956001282,
      0.05884221941232681,
      0.01138164009898901,
      0.07183612883090973,
      0.01479188259691,
      -0.025783967226743698,
      -0.02698158286511898,
      0.09200486540794373,
      0.03830365464091301,
      0.047163333743810654,
      -0.024310600012540817,
      -0.007641360629349947,
      -0.05500795319676399,
      0.005974367260932922,
      0.034530945122241974,
      0.03834795579314232,
      -0.0052689011208713055,
      -0.04218810796737671,
      -0.05503196269273758,
      -0.024516846984624863,
      0.01958654448390007,
      -0.030615072697401047,
      0.015081079676747322,
      -0.004915107972919941,
      0.055553194135427475,
      -0.019885532557964325,
      0.05839400738477707,
      -0.007228387054055929,
      0.00107152434065938,
      -0.07391192764043808,
      -0.03925138711929321,
      0.06165604293346405,
      -0.07206343114376068,
      -0.01568290777504444,
      -0.012904680334031582,
      0.08199073374271393,
      0.012874750420451164,
      0.01277437899261713,
      0.012000939808785915,
      0.011200081557035446,
      -0.013390974141657352,
      0.0282941535115242,
      -0.04220439866185188,
      0.029164988547563553,
      0.012510872446000576,
      0.07786707580089569,
      0.08136864751577377,
      0.01493848580867052,
      0.0248162429779768,
      0.03281816095113754,
      0.014588271267712116,
      0.04047712683677673,
      0.06838124990463257,
      -0.008264840580523014,
      -0.01416597981005907,
      0.016891350969672203,
      -0.005698205437511206,
      0.06871358305215836,
      -0.08783459663391113,
      0.0014117888640612364,
      -0.030488234013319016,
      -0.06308139115571976,
      -0.014416317455470562,
      0.019935037940740585,
      -0.025896942242980003,
      0.05720825120806694,
      -0.002705647610127926,
      -0.0011851771268993616,
      -0.001297329319640994,
      0.009897259995341301,
      -0.11228351294994354,
      0.03063092939555645,
      0.024029629305005074,
      -0.04009368643164635,
      0.025099294260144234,
      -0.01339894998818636,
      -0.014959320425987244,
      -0.01431658398360014,
      0.021772831678390503,
      -0.07019848376512527,
      -0.030627259984612465,
      -0.0010663983412086964,
      0.02675938606262207,
      -0.06794998794794083,
      0.08411167562007904,
      0.036228954792022705,
      0.011587421409785748,
      -0.08479050546884537,
      -0.011508294381201267,
      0.08873429149389267,
      -0.016333239153027534,
      0.028457650914788246,
      -0.01615576632320881,
      0.06874853372573853,
      0.04307493567466736,
      -0.0357026532292366,
      -0.06037001311779022,
      -0.05328507348895073,
      0.08057914674282074,
      0.0008856836357153952,
      0.038197629153728485,
      -0.03572985529899597,
      0.03582288697361946,
      -0.060233812779188156,
      0.0063993921503424644,
      -0.010352705605328083,
      -0.03691449016332626,
      -0.07930624485015869,
      0.049278758466243744,
      0.014645786955952644,
      -0.038636162877082825,
      -0.018769606947898865,
      -0.009222968481481075,
      -0.005855099763721228,
      0.025645041838288307,
      -0.03305970877408981,
      -0.03446344658732414,
      -0.03249906376004219,
      0.0932631567120552,
      -0.058017902076244354,
      0.04597577825188637,
      -0.04893886670470238,
      -0.04185868799686432,
      -0.007125484757125378,
      0.02994789369404316,
      -0.04987775534391403,
      0.04015401750802994,
      -0.031122634187340736,
      -0.01211603730916977,
      -0.019932623952627182,
      -0.10513978451490402,
      -0.006990170106291771,
      0.061272647231817245,
      -0.012905201874673367,
      -0.021253367885947227,
      -0.0303281731903553,
      0.04488961771130562,
      0.07353487610816956,
      -0.02662905678153038,
      0.04059898480772972,
      -0.014787804335355759,
      -0.0009502700995653868,
      0.07737284898757935,
      0.03638382628560066,
      -0.03321663290262222,
      0.02273019216954708,
      -0.051916174590587616,
      0.020790189504623413,
      -0.004175900481641293,
      0.04126156121492386,
      0.08191544562578201,
      -0.02135341428220272,
      0.05995466932654381,
      0.048171255737543106,
      -0.010477033443748951,
      -0.08268781751394272,
      -0.05588877946138382,
      0.0241622906178236,
      0.008144612424075603,
      -0.004182044882327318,
      0.07464730739593506,
      -0.03286135569214821,
      -0.06611794233322144,
      -0.0041121384128928185,
      -0.025373972952365875,
      -0.0471237413585186,
      -0.11544878035783768,
      0.007564333733171225,
      -0.021411722525954247,
      -0.0016450721304863691,
      0.02851850539445877,
      -0.012780970893800259,
      -0.09268535673618317,
      -0.025235943496227264,
      0.03296811506152153,
      -0.03474240005016327,
      -0.03399566188454628,
      -0.006229320541024208,
      -0.03128611296415329,
      -0.04977535083889961,
      0.02229737490415573,
      -0.028519099578261375,
      -0.010338636115193367,
      0.0894058421254158,
      0.006087639834731817,
      -0.0656161978840828,
      0.01783214882016182,
      -0.12978166341781616,
      -0.011598458513617516,
      0.053105007857084274,
      -0.057031210511922836,
      0.057309381663799286,
      -0.02014485001564026,
      -0.033681441098451614,
      -0.0165143683552742,
      -0.020350346341729164,
      -0.09962726384401321,
      0.0034657353535294533,
      -0.05242962762713432,
      0.03283555433154106,
      0.045891474932432175,
      0.002673102542757988,
      0.1006188839673996,
      -0.04060228168964386,
      -0.05128749832510948,
      -0.05701940134167671,
      0.0241785179823637,
      -0.033230748027563095,
      0.08120553195476532,
      -0.00022616358182858676,
      -0.08895296603441238,
      -0.06394138932228088,
      0.02155866101384163,
      -0.017022697255015373,
      0.024746892973780632,
      -0.007220288272947073,
      -0.10150521248579025,
      0.044234614819288254,
      -0.04497915506362915,
      -0.012600645422935486,
      0.06230796501040459,
      0.01700308732688427,
      -0.006080341525375843,
      -0.00511748855933547,
      -0.03797793760895729,
      0.10041549056768417,
      -0.029641088098287582,
      -0.05551113188266754,
      0.046711090952157974,
      -0.037424806505441666,
      0.054577700793743134,
      -0.05727287009358406,
      -0.01937110722064972,
      -0.018651943653821945,
      0.05721345543861389,
      0.027300024405121803,
      0.05055607110261917,
      0.023380175232887268,
      -0.02356109395623207,
      0.004134867340326309,
      -0.005551123060286045,
      -0.05808354169130325,
      0.10626905411481857,
      0.06442839652299881,
      0.04945908114314079,
      -0.026465963572263718,
      0.00995347835123539,
      0.043124545365571976,
      -0.007378791458904743,
      0.05888759717345238,
      -0.011897364631295204,
      -0.016697684302926064,
      -0.029632531106472015,
      -0.05385000631213188,
      -0.004185085650533438,
      0.00573390256613493,
      0.001983119873329997,
      -0.029694946482777596,
      -0.023333556950092316,
      0.08825130760669708,
      0.012933741323649883,
      0.05430222675204277,
      -0.01886053942143917,
      0.058090001344680786,
      -0.02111118659377098,
      0.06585501879453659,
      -0.052156127989292145,
      0.07169358432292938,
      0.0012322755064815283,
      -0.014961427077651024
    ],
    [
      0.05162849649786949,
      0.050905175507068634,
      0.02706201933324337,
      -0.008794166147708893,
      0.01669575460255146,
      0.06370698660612106,
      0.03023443929851055,
      0.0317782387137413,
      0.01710626855492592,
      -0.005085311364382505,
      -0.04038212448358536,
      -0.04596053808927536,
      -0.031526751816272736,
      -0.029295118525624275,
      0.013935328461229801,
      0.011899919249117374,
      -0.07887974381446838,
      0.028626831248402596,
      -0.02212626487016678,
      -0.07263500243425369,
      -0.034050580114126205,
      0.05917128175497055,
      0.007865538820624352,
      0.03142194822430611,
      -0.009930822066962719,
      0.07089561969041824,
      0.02673479914665222,
      -0.0647355243563652,
      -0.06382174789905548,
      -0.06463708728551865,
      0.011257213540375233,
      0.024426190182566643,
      -0.03018978424370289,
      -0.04811932146549225,
      -0.03846604749560356,
      -0.08186990022659302,
      0.05900011956691742,
      0.0007824855856597424,
      0.014187379740178585,
      -0.020058531314134598,
      0.08247150480747223,
      0.021214408800005913,
      0.047179047018289566,
      0.015021221712231636,
      -0.003438027109950781,
      0.0031213604379445314,
      0.011417195200920105,
      0.0001608731981832534,
      0.019813796505331993,
      -0.051586199551820755,
      -0.054322145879268646,
      0.06391043215990067,
      0.014551984146237373,
      0.009123817086219788,
      0.04044248163700104,
      -0.05167287215590477,
      0.013146571815013885,
      0.03992806747555733,
      0.05440680310130119,
      -0.017164405435323715,
      -0.03042236901819706,
      0.01789161190390587,
      0.03814764693379402,
      -0.036311689764261246,
      0.0411808080971241,
      0.015432500280439854,
      0.009768852032721043,
      0.011585886590182781,
      -0.02310001663863659,
      0.03026566281914711,
      -0.037596773356199265,
      0.0678313821554184,
      0.07177385687828064,
      0.014639699831604958,
      -0.0895547866821289,
      -0.08618254959583282,
      0.02188533917069435,
      -0.03231434524059296,
      -0.04075156897306442,
      -0.022795584052801132,
      0.02816057950258255,
      -0.028697054833173752,
      -0.022797659039497375,
      -0.06437132507562637,
      -0.0034320675767958164,
      0.04204406216740608,
      0.0819568783044815,
      0.0024670481216162443,
      -0.018731029704213142,
      -0.03339223191142082,
      0.020643973723053932,
      -0.011036708950996399,
      -0.017422672361135483,
      0.004561197012662888,
      0.024046285077929497,
      -0.05581358075141907,
      -0.0298022348433733,
      0.009092966094613075,
      -0.026325343176722527,
      -0.03658479079604149,
      -0.11399152874946594,
      -0.007837378419935703,
      0.044842276722192764,
      0.07953902333974838,
      0.034598615020513535,
      -0.002415482187643647,
      -0.011010968126356602,
      -0.07476814091205597,
      -0.007275542244315147,
      0.024447571486234665,
      0.006042579188942909,
      -0.005845464300364256,
      0.0004917425103485584,
      -0.08384066075086594,
      0.05745534226298332,
      -0.0295257568359375,
      -0.0013637584634125233,
      -0.04980679228901863,
      -0.08119739592075348,
      -0.038638368248939514,
      0.03748400881886482,
      -0.021425951272249222,
      0.0041504306718707085,
      -0.013843506574630737,
      0.01649542525410652,
      0.04711518809199333,
      0.05602880194783211,
      0.05197446793317795,
      -0.04491546377539635,
      -0.015761928632855415,
      -0.020355412736535072,
      -0.042693834751844406,
      0.0061182137578725815,
      0.019130486994981766,
      -0.0751955434679985,
      0.03398652747273445,
      0.0019830428063869476,
      0.004765004385262728,
      0.010670541785657406,
      -0.03922048211097717,
      0.08573483675718307,
      -0.009111055172979832,
      -0.027609730139374733,
      -0.030436348170042038,
      -0.06049291789531708,
      0.05052971467375755,
      -0.04069354012608528,
      -0.04481218010187149,
      -0.020054928958415985,
      0.019239336252212524,
      -0.044147226959466934,
      0.03486425802111626,
      0.04739489033818245,
      -0.009602428413927555,
      -0.011807592585682869,
      -0.03488984331488609,
      -0.04661048203706741,
      0.025412341579794884,
      -0.036439962685108185,
      0.043157514184713364,
      -0.009258899837732315,
      0.05728764086961746,
      -0.07903394848108292,
      -0.09971518069505692,
      0.047737009823322296,
      -0.04307049885392189,
      -0.014729232527315617,
      -0.03237079828977585,
      0.025233261287212372,
      -0.038000430911779404,
      -0.03432013839483261,
      0.06697343289852142,
      0.013865689747035503,
      0.01514632161706686,
      -0.044067803770303726,
      0.0017947204178199172,
      -0.011248123832046986,
      0.0072915940545499325,
      0.00042026207665912807,
      0.1239788830280304,
      -0.04675789549946785,
      -0.041693512350320816,
      0.024288177490234375,
      -0.03417245298624039,
      -0.040144383907318115,
      -0.08272930979728699,
      0.004576226696372032,
      -1.8046347349809366e-07,
      0.0902845710515976,
      0.08318684995174408,
      0.03416857495903969,
      -0.06914079934358597,
      0.005399915389716625,
      -0.038202978670597076,
      0.009219835512340069,
      -0.06420327723026276,
      -0.019563112407922745,
      0.07302045822143555,
      -0.013505165465176105,
      0.05944826826453209,
      -0.0834750160574913,
      0.030371790751814842,
      0.033592820167541504,
      0.07987640053033829,
      0.007391889113932848,
      -0.08934319019317627,
      0.05058688670396805,
      0.004557219799607992,
      0.059042200446128845,
      0.04343035817146301,
      -0.026414183899760246,
      -0.002287286566570401,
      -0.006362587679177523,
      -0.03310241922736168,
      0.02724629081785679,
      0.037599045783281326,
      -0.09164991229772568,
      0.03225639835000038,
      0.05610105022788048,
      0.06910417228937149,
      0.009696497581899166,
      0.04716752469539642,
      -0.032140910625457764,
      0.010183381848037243,
      -0.06290706247091293,
      -0.07210433483123779,
      0.04216904938220978,
      -0.11638906598091125,
      -0.0368138924241066,
      -0.02443983405828476,
      0.002815926680341363,
      0.04723334684967995,
      -0.09185492992401123,
      0.0027213499415665865,
      -0.012637825682759285,
      -0.008907598443329334,
      -0.10028424113988876,
      0.061950091272592545,
      0.062464747577905655,
      -0.02016652375459671,
      0.07195127755403519,
      -0.03517986088991165,
      0.04889027401804924,
      -0.0009385188459418714,
      -0.0582699216902256,
      -0.03726132959127426,
      0.016591329127550125,
      -0.017189426347613335,
      0.01298106461763382,
      0.0494353324174881,
      0.03483867645263672,
      -0.010241558775305748,
      -0.03768044710159302,
      0.04411623626947403,
      -0.0160325039178133,
      0.03843282163143158,
      -0.07008149474859238,
      -0.031788188964128494,
      0.03287729620933533,
      -0.01963898539543152,
      -0.015359020791947842,
      -0.10844104737043381,
      -0.018678385764360428,
      0.03146463260054588,
      0.02491060644388199,
      0.029942266643047333,
      -0.009770700708031654,
      -0.021763671189546585,
      0.014456774108111858,
      -0.022585636004805565,
      -0.10547728836536407,
      -0.06017555296421051,
      -0.06704148650169373,
      0.06949612498283386,
      -0.0033823491539806128,
      -0.003416705410927534,
      -0.040593333542346954,
      0.08160221576690674,
      0.004862888716161251,
      0.07604563236236572,
      0.09608086198568344,
      0.07427612692117691,
      -0.04683076590299606,
      0.008931437507271767,
      0.036352235823869705,
      -0.01985696703195572,
      0.022556018084287643,
      -0.026319408789277077,
      0.01648840494453907,
      0.03912461921572685,
      0.04897818714380264,
      0.007571704685688019,
      0.0654735416173935,
      0.01674925722181797,
      0.03380705043673515,
      0.001880702213384211,
      0.05431821569800377,
      -0.002634793985635042,
      0.03452058136463165,
      0.01754075475037098,
      0.056538522243499756,
      -0.05251186713576317,
      0.0190411563962698,
      -0.05489809066057205,
      0.0654052346944809,
      0.0314539335668087,
      -0.007181155029684305,
      -0.04663267731666565,
      0.053255245089530945,
      -0.011351965367794037,
      0.044251684099435806,
      0.07857616245746613,
      -0.03941887244582176,
      -0.0007301506120711565,
      -0.0037317804526537657,
      0.027777384966611862,
      -0.13761956989765167,
      0.027570292353630066,
      -0.06700843572616577,
      -0.11384221911430359,
      0.019771594554185867,
      0.0470835380256176,
      -0.00320647144690156,
      -0.011013888753950596,
      0.012208003550767899,
      0.03293280676007271,
      -0.06791990995407104,
      0.06341472268104553,
      0.0056535303592681885,
      0.009846197441220284,
      -0.01783149689435959,
      -0.02327054925262928,
      -0.02175794541835785,
      -0.02904127538204193,
      -0.021458502858877182,
      -0.0348225012421608,
      -0.0279981829226017,
      -0.006442856043577194,
      -0.0675215795636177,
      -0.017826111987233162,
      -0.020116494968533516,
      -0.08525736629962921,
      -0.04044797644019127,
      0.0016797916032373905,
      0.05049299821257591,
      -0.022157100960612297,
      0.018933337181806564,
      -0.011619606986641884,
      0.019902657717466354,
      -0.057168807834386826,
      -0.0008905025897547603,
      -0.03055705688893795,
      0.041904300451278687,
      0.04293665289878845,
      -0.02614813670516014,
      0.011642631143331528,
      -0.0889124870300293,
      0.028665900230407715,
      -0.10075928270816803,
      0.0024554228875786066,
      -0.0011800451902672648,
      -0.023983648046851158,
      -0.003246559528633952,
      -0.04748999699950218,
      0.01160945650190115,
      0.05743350833654404,
      -0.029155530035495758,
      -0.05093627795577049,
      -0.059847988188266754,
      0.03803802654147148,
      -0.03302844986319542,
      0.04528820514678955,
      0.037737347185611725,
      0.043054528534412384,
      0.017881950363516808,
      0.0017043218249455094,
      0.06298156827688217,
      0.007259837351739407,
      0.03557313606142998,
      0.041467685252428055,
      0.0013250941410660744,
      -0.05092993006110191,
      -0.018552955240011215,
      -0.015645498409867287,
      0.032565079629421234,
      -0.031322065740823746,
      -0.031345732510089874,
      -0.02169455960392952,
      0.06031529977917671,
      -0.029915323480963707,
      -0.045980360358953476,
      -0.04629767686128616,
      -0.003068653866648674,
      -0.09691724926233292,
      0.05168019235134125,
      -0.02797580696642399,
      -0.006680504884570837,
      0.02435527928173542,
      -0.03204596787691116,
      0.02684343419969082,
      -0.03062562644481659,
      0.02732640504837036,
      0.0007119350484572351,
      0.0006751553155481815,
      0.06260606646537781,
      -0.008152065798640251,
      0.00034331579809077084,
      -0.03427087888121605,
      0.01579313911497593,
      -0.029464611783623695,
      -0.029989706352353096,
      0.022213006392121315,
      0.015199139714241028,
      0.03848124295473099,
      0.002202016767114401,
      -0.12007907778024673,
      -0.0010772645473480225,
      0.031600501388311386,
      -0.05308301001787186,
      -0.11541145294904709,
      -0.011978182010352612,
      -0.01398717425763607,
      -0.006498927250504494,
      0.051594022661447525,
      -0.021409256383776665,
      -0.06484130769968033,
      -0.011570276692509651,
      0.055816300213336945,
      0.04087246581912041,
      -0.02471899800002575,
      0.10320056229829788,
      -0.033022891730070114,
      -0.001484379405155778,
      0.03609885275363922,
      -0.0012566721998155117,
      -0.019832724705338478,
      -0.009593612514436245,
      0.0352797694504261,
      0.059539176523685455,
      -0.05945424735546112,
      -0.1027122363448143,
      -0.09535177052021027,
      0.044351235032081604,
      -0.06403570622205734,
      -0.022975416854023933,
      -0.011439913883805275,
      0.07290749996900558,
      -0.12749186158180237,
      -0.005730920936912298,
      -0.04159924387931824,
      -0.0061649056151509285,
      0.06582209467887878,
      -0.06971251964569092,
      -0.003224469954147935,
      -0.06831109523773193,
      -0.0061912210658192635,
      0.03141261264681816,
      -0.042046111077070236,
      -0.03684630244970322,
      0.059080660343170166,
      0.045500777661800385,
      -0.023694565519690514,
      -0.027521416544914246,
      0.015183675102889538,
      -0.02845252864062786,
      -0.060724593698978424,
      -0.012475300580263138,
      -0.0005938172689639032,
      0.06699124723672867,
      -0.03545396775007248,
      0.044349197298288345,
      0.06425771862268448,
      -0.004939149599522352,
      0.04240736365318298,
      0.017231689766049385,
      0.005433523096144199,
      0.00804823450744152,
      -0.0457020103931427,
      0.04439395293593407,
      0.11526073515415192,
      -0.0072469026781618595,
      0.02340681292116642,
      -0.07467543333768845,
      0.028958233073353767,
      0.04320468753576279,
      0.009013112634420395,
      0.06196488440036774,
      0.011442841030657291,
      -0.03442339971661568,
      0.014835033565759659,
      0.04115372151136398,
      -0.0009455155814066529,
      -0.008891668170690536,
      -0.06649883091449738,
      0.05810261517763138,
      -0.01354355737566948,
      0.04093218967318535,
      -0.03705977648496628,
      0.06637796014547348,
      0.03199191763997078,
      -0.07775823026895523,
      0.004426530562341213,
      0.05232442170381546
    ],
    [
      -0.020601682364940643,
      0.016009341925382614,
      -0.0013437796151265502,
      -0.04401763528585434,
      0.03990013152360916,
      0.013424999080598354,
      0.03659464046359062,
      0.0319051593542099,
      -0.015769125893712044,
      -0.01750773936510086,
      0.07228350639343262,
      -0.029695143923163414,
      -0.03935246542096138,
      0.00032094994094222784,
      0.05861848220229149,
      -0.04331929236650467,
      0.006882655434310436,
      0.008831661194562912,
      0.04375893250107765,
      0.11768041551113129,
      0.03153601288795471,
      0.07922837883234024,
      0.005441718269139528,
      0.03452101722359657,
      0.010389914736151695,
      -0.06315253674983978,
      0.037090037018060684,
      1.788383087841794e-05,
      0.028070909902453423,
      0.02256910689175129,
      0.06779638677835464,
      0.015450474806129932,
      0.051877979189157486,
      -0.0912005603313446,
      -0.022471508011221886,
      0.044486742466688156,
      0.13345853984355927,
      -0.01506250724196434,
      0.020483192056417465,
      0.0004951754817739129,
      0.014968001283705235,
      -0.042206060141325,
      -0.02617601864039898,
      -0.003728119656443596,
      0.0696309357881546,
      -0.0682171955704689,
      0.020534617826342583,
      -0.08609241247177124,
      -0.06901377439498901,
      -0.07072743773460388,
      0.0502445362508297,
      0.0887245312333107,
      0.04497630149126053,
      0.04645325616002083,
      0.0012129845563322306,
      0.026736734434962273,
      0.00898720696568489,
      -0.07816173881292343,
      0.03278622776269913,
      -0.014331900514662266,
      0.08334557712078094,
      -0.0014180492144078016,
      0.018103541806340218,
      0.00768690463155508,
      0.06244172900915146,
      0.04266655072569847,
      -0.0362979993224144,
      0.0020319614559412003,
      0.02186606079339981,
      0.05946683883666992,
      0.005783570930361748,
      -0.0468386709690094,
      -0.04756077378988266,
      -0.03955128416419029,
      -0.0033959713764488697,
      0.0235044676810503,
      0.037406716495752335,
      0.008949811570346355,
      0.04865169897675514,
      -0.01045719999819994,
      -0.0595722422003746,
      0.015087406150996685,
      0.06069183349609375,
      -0.05474162474274635,
      -0.005045956000685692,
      -0.034811943769454956,
      -0.000832869263831526,
      -0.008963719941675663,
      0.020930202677845955,
      -0.035136040300130844,
      -0.08254930377006531,
      -0.006371068302541971,
      0.029028529301285744,
      -0.003619961440563202,
      0.016120366752147675,
      -0.052933868020772934,
      -0.029872357845306396,
      0.007931668311357498,
      0.017833657562732697,
      0.043975379317998886,
      -0.0310704093426466,
      -0.032665517181158066,
      0.05268866568803787,
      0.012569699436426163,
      -0.1080867126584053,
      0.11782306432723999,
      -0.06593391299247742,
      -0.08457586914300919,
      -0.03949586674571037,
      0.0849384069442749,
      0.004169580992311239,
      -0.040007833391427994,
      0.07010267674922943,
      0.08671476691961288,
      -0.02276468649506569,
      -0.015176869928836823,
      -0.0883440375328064,
      -0.00819167960435152,
      -0.013196690939366817,
      0.022509077563881874,
      0.049263905733823776,
      0.01676093228161335,
      0.05130987986922264,
      -0.043759867548942566,
      -0.007854076102375984,
      -0.02223561890423298,
      -0.017942391335964203,
      -0.026839207857847214,
      0.05066065862774849,
      -0.011455603875219822,
      0.03668894246220589,
      0.09909751266241074,
      -0.013850221410393715,
      0.09827054291963577,
      0.023210447281599045,
      -0.05175762623548508,
      0.017891259863972664,
      -0.05812811106443405,
      0.016499200835824013,
      -0.024358835071325302,
      0.03355931118130684,
      -0.04007161036133766,
      0.0015588199021294713,
      0.03760426864027977,
      -0.023319346830248833,
      -0.03116588667035103,
      -0.06632304191589355,
      -0.09515921026468277,
      -0.054889190942049026,
      -0.004002082627266645,
      0.05046994984149933,
      0.0207158625125885,
      0.021558372303843498,
      -0.10421407222747803,
      -0.07384441792964935,
      -0.0017375145107507706,
      -0.047001443803310394,
      0.030181102454662323,
      0.021808462217450142,
      0.026562606915831566,
      -0.04601151496171951,
      0.04743662849068642,
      -0.08757287263870239,
      0.06523732841014862,
      -0.025313200429081917,
      -0.03982209786772728,
      -0.054868970066308975,
      0.020387789234519005,
      -0.007068527862429619,
      0.06033908948302269,
      -0.08038420975208282,
      0.07122255116701126,
      0.06000461429357529,
      -0.023483509197831154,
      0.08865252137184143,
      -0.015155852772295475,
      -0.01618809439241886,
      -0.017596004530787468,
      -0.019415562972426414,
      0.026685331016778946,
      -0.022742876783013344,
      -0.03927280381321907,
      -0.06403308361768723,
      -0.06520615518093109,
      -0.02233033813536167,
      -0.07823893427848816,
      0.024606477469205856,
      -0.021703585982322693,
      -0.005295074079185724,
      -0.04454605653882027,
      -0.032737214118242264,
      0.032335396856069565,
      0.06303034722805023,
      -0.023450057953596115,
      0.01219889149069786,
      -0.0700174868106842,
      0.028545590117573738,
      -0.034751713275909424,
      0.028623076155781746,
      0.07501254230737686,
      -0.025570323690772057,
      0.036148108541965485,
      0.013160724192857742,
      0.052554305642843246,
      0.0030170846730470657,
      -0.08000969886779785,
      0.005677882116287947,
      -0.02642190083861351,
      0.032944001257419586,
      0.0344698503613472,
      -0.041596196591854095,
      0.1467745006084442,
      -0.019572921097278595,
      0.01007785927504301,
      0.023677630349993706,
      -0.02435116656124592,
      -0.041991304606199265,
      -0.02555943839251995,
      0.012556526809930801,
      0.05558782443404198,
      0.01945716328918934,
      0.05932427570223808,
      -0.058619674295186996,
      0.017214836552739143,
      0.004355580545961857,
      -0.037883173674345016,
      0.008027838543057442,
      -0.028384776785969734,
      -0.05512147769331932,
      0.004091640003025532,
      0.04428037256002426,
      -0.05173856392502785,
      0.02065005898475647,
      0.0005953062209300697,
      -0.06277460604906082,
      0.028340168297290802,
      -0.01665167510509491,
      0.004069870337843895,
      0.044622041285037994,
      -0.017136290669441223,
      -0.05322267487645149,
      -0.038952723145484924,
      0.061449311673641205,
      -0.1022752970457077,
      0.008695576339960098,
      0.036152713000774384,
      0.005035714246332645,
      0.021865572780370712,
      0.0005054259672760963,
      -0.04754139110445976,
      0.03045487031340599,
      -0.012138799764215946,
      0.0030008673202246428,
      0.020546013489365578,
      0.04109573736786842,
      -0.058731526136398315,
      0.01572209782898426,
      -0.08638793975114822,
      0.014024634845554829,
      -0.09172747284173965,
      -0.013606072403490543,
      -0.008976667188107967,
      -0.031304650008678436,
      -0.010881712660193443,
      -0.050685781985521317,
      -0.06425674259662628,
      0.0258882287889719,
      0.009886831045150757,
      0.04985414817929268,
      0.07432682812213898,
      -0.058114226907491684,
      -0.009052317589521408,
      0.00960808526724577,
      -0.014263035729527473,
      -0.055348098278045654,
      0.006917318794876337,
      -0.03680732473731041,
      -0.024799643084406853,
      0.06163914501667023,
      -0.0033176587894558907,
      -0.004583913832902908,
      -0.1053885892033577,
      0.046460025012493134,
      0.011614454910159111,
      -0.04570373520255089,
      0.11799457669258118,
      0.0577751025557518,
      0.029220398515462875,
      -0.05917826667428017,
      0.08247552067041397,
      -0.09990575164556503,
      -0.011741851456463337,
      -0.02334231324493885,
      -0.014183332212269306,
      0.027712007984519005,
      0.030026845633983612,
      0.1303064376115799,
      -0.06577616930007935,
      -0.004287011455744505,
      -0.030498668551445007,
      -0.022039594128727913,
      -0.0014297263696789742,
      0.02480621263384819,
      -0.008627518080174923,
      -0.01989670842885971,
      0.02909303456544876,
      0.019294580444693565,
      -0.007021533325314522,
      -0.01649557799100876,
      -0.04733267426490784,
      0.014604871161282063,
      -0.042246900498867035,
      0.022021591663360596,
      0.04043067246675491,
      0.04582591727375984,
      0.09562856703996658,
      -0.07776748389005661,
      0.07870685309171677,
      -0.060823388397693634,
      -0.09088314324617386,
      0.04935449734330177,
      -0.03855358436703682,
      0.0044077117927372456,
      0.035976894199848175,
      0.05020984634757042,
      0.05039474368095398,
      -0.043137725442647934,
      -0.010108253918588161,
      -0.012463023886084557,
      -0.008428527042269707,
      -0.08654598891735077,
      0.00911122839897871,
      0.031305961310863495,
      -0.058001939207315445,
      -0.006410716101527214,
      0.0852959081530571,
      -0.010820797644555569,
      -0.022429445758461952,
      0.036070480942726135,
      0.0702633336186409,
      0.05944463610649109,
      -0.06870797276496887,
      0.09625738859176636,
      0.044217705726623535,
      -0.027087314054369926,
      -0.04627004638314247,
      0.04217702895402908,
      -0.07796593755483627,
      -0.011685235425829887,
      0.04003981500864029,
      0.027051497250795364,
      0.004009837284684181,
      -0.013138281181454659,
      0.02884502336382866,
      -0.018389692530035973,
      0.021017469465732574,
      0.03538794815540314,
      -0.0499880313873291,
      -0.07337227463722229,
      0.03778967261314392,
      -0.03676712512969971,
      0.02155604027211666,
      -0.022693704813718796,
      -0.021998491138219833,
      -0.11032707244157791,
      0.008327571675181389,
      0.04893646389245987,
      -0.03528682887554169,
      -0.1281983107328415,
      -0.024168701842427254,
      0.10265308618545532,
      0.06849174946546555,
      -0.060777027159929276,
      -0.002174606081098318,
      0.027949417009949684,
      0.01687818020582199,
      0.06340266764163971,
      0.00809039268642664,
      0.02257678657770157,
      -0.07579655200242996,
      0.024445516988635063,
      -0.019287561997771263,
      0.06965059041976929,
      0.0660250261425972,
      0.021219603717327118,
      0.03031572885811329,
      -0.020421002060174942,
      -0.06360984593629837,
      -5.503083229996264e-05,
      0.012447269633412361,
      -0.004020329564809799,
      -0.016229011118412018,
      0.029545199126005173,
      -0.03262391313910484,
      -0.00968184880912304,
      0.0012225346872583032,
      -0.02059657871723175,
      0.03803442046046257,
      -0.09321663528680801,
      -0.06279746443033218,
      -0.053138457238674164,
      0.047784533351659775,
      -0.019340796396136284,
      0.008825734257698059,
      -0.026321250945329666,
      -0.0010717636905610561,
      -0.03623659908771515,
      0.0073019652627408504,
      -0.00030705047538504004,
      -0.08258915692567825,
      0.04469418153166771,
      -0.0501573272049427,
      -0.07486413419246674,
      0.07030836492776871,
      -0.03189041465520859,
      -0.08131776005029678,
      0.035109348595142365,
      -0.009448310360312462,
      0.05017146095633507,
      -0.04154028743505478,
      0.014611770398914814,
      -0.0069504487328231335,
      0.05656507983803749,
      -0.055098213255405426,
      -0.014673341996967793,
      -0.011052777990698814,
      0.06639733165502548,
      -0.010027519427239895,
      0.08796431869268417,
      0.004578437656164169,
      0.01769593358039856,
      0.009141587652266026,
      -0.007980594411492348,
      0.04051564261317253,
      0.018279382959008217,
      0.02164803072810173,
      -0.053820546716451645,
      0.011677469126880169,
      0.009603375568985939,
      0.058407723903656006,
      -0.03523501381278038,
      -0.008260087110102177,
      -0.013893318362534046,
      -0.03433721512556076,
      -0.03180718421936035,
      -0.010268021374940872,
      0.004035275429487228,
      0.012307479977607727,
      0.0012692176969721913,
      0.06405898928642273,
      0.04687637835741043,
      0.04965700954198837,
      -0.0014842025702819228,
      0.045612286776304245,
      0.057072050869464874,
      0.0015733812469989061,
      -0.0674326941370964,
      0.018889812752604485,
      0.05583898350596428,
      -0.055209189653396606,
      -0.019572921097278595,
      -0.058010000735521317,
      -0.004949049558490515,
      0.016078434884548187,
      0.03235494717955589,
      0.0018849815241992474,
      -0.020004939287900925,
      0.11483895033597946,
      -0.05365985631942749,
      -0.00627733301371336,
      -0.05130814388394356,
      0.005944699514657259,
      -0.04013603925704956,
      0.03207400068640709,
      -0.027804533019661903,
      0.04195871949195862,
      -0.049096573144197464,
      0.013792568817734718,
      0.02793317846953869,
      0.024223526939749718,
      -0.051076438277959824,
      0.013894918374717236,
      -0.04685626178979874,
      0.035063959658145905,
      -0.013574131764471531,
      0.0344821959733963,
      0.03230331838130951,
      -0.05192406103014946,
      -0.03704236075282097,
      0.08683750778436661,
      0.006346259266138077,
      0.0728839784860611,
      -0.007312813773751259,
      0.07320626825094223,
      0.03457037732005119,
      -0.020165467634797096,
      0.00016727026377338916,
      0.01673448644578457,
      -0.03245248645544052,
      0.030079372227191925,
      -0.018755227327346802,
      0.0011436683125793934,
      -0.04670478031039238
    ],
    [
      0.013927233405411243,
      0.0016212079208344221,
      0.05196741968393326,
      -0.049087345600128174,
      -0.01109050028026104,
      -0.03648556023836136,
      0.02595188468694687,
      -0.006990176625549793,
      0.023376062512397766,
      0.018103618174791336,
      0.003242937382310629,
      0.008577518165111542,
      -0.04042448103427887,
      0.09347841143608093,
      -0.03249254450201988,
      -0.021585503593087196,
      0.04627569019794464,
      -0.02754158154129982,
      9.628872794564813e-05,
      -0.042445942759513855,
      0.08181947469711304,
      0.043662529438734055,
      -0.019976306706666946,
      0.013837472535669804,
      -0.04079591482877731,
      0.0030105295591056347,
      -0.01092433836311102,
      0.08033031225204468,
      0.006969819776713848,
      0.04975876957178116,
      -0.013832652941346169,
      -0.006957989651709795,
      0.009392543695867062,
      0.07100063562393188,
      -0.0016333520179614425,
      -0.060384757816791534,
      0.0577254518866539,
      0.034224215894937515,
      -0.0023746092338114977,
      0.04704565554857254,
      0.11081007122993469,
      -0.010229792445898056,
      -0.023228561505675316,
      0.004858673084527254,
      0.008846159093081951,
      0.0841434895992279,
      -0.014316141605377197,
      -0.09023235738277435,
      -0.026933107525110245,
      -0.007764109876006842,
      -0.001503748819231987,
      0.05198594927787781,
      0.03881172090768814,
      0.03300516679883003,
      0.007618870120495558,
      0.006900410633534193,
      -0.0017692726105451584,
      -0.007578625809401274,
      0.012756460346281528,
      0.050010260194540024,
      -0.029640330001711845,
      -0.0341133214533329,
      -0.04523036628961563,
      0.06477920711040497,
      0.04313551262021065,
      0.009659827686846256,
      -0.04837866872549057,
      0.09788142144680023,
      -0.06429041177034378,
      -0.08518584817647934,
      0.036290381103754044,
      0.022288162261247635,
      0.09766396880149841,
      0.05572746321558952,
      -0.07847924530506134,
      -0.01621927320957184,
      -0.004136381670832634,
      0.00398801127448678,
      0.013720237649977207,
      -0.07151255011558533,
      0.004876408725976944,
      -0.01984231173992157,
      -0.030322253704071045,
      0.003919981420040131,
      0.03818411007523537,
      -0.02045070193707943,
      -0.049542807042598724,
      -0.018941152840852737,
      0.004233797080814838,
      0.008082865737378597,
      -0.04401446133852005,
      -0.043142110109329224,
      0.037146858870983124,
      -0.03389276936650276,
      0.04994046688079834,
      0.01978159509599209,
      0.004571893718093634,
      -0.023787308484315872,
      0.03256742283701897,
      -0.008403810672461987,
      0.027341872453689575,
      -0.06095379963517189,
      0.12549807131290436,
      -0.0052133649587631226,
      -0.049180347472429276,
      0.04332640767097473,
      0.04240034148097038,
      0.030243776738643646,
      -0.0005827510613016784,
      -0.015211467631161213,
      0.003879640018567443,
      0.024488694965839386,
      -0.0006023551686666906,
      -0.0372595451772213,
      0.030997974798083305,
      -0.04968446493148804,
      -0.01139045786112547,
      0.06160846725106239,
      -0.03284598886966705,
      0.03962478041648865,
      -0.032942015677690506,
      -0.021662339568138123,
      0.04793541505932808,
      -0.026122791692614555,
      0.04240069165825844,
      -0.004628363531082869,
      0.036389488726854324,
      0.06230694055557251,
      0.015960240736603737,
      0.005799010396003723,
      -0.06596963852643967,
      0.047698188573122025,
      -0.0747724249958992,
      0.009419986978173256,
      -0.0657598078250885,
      -0.006095981691032648,
      -0.0443766750395298,
      -0.0004914386081509292,
      0.04483944922685623,
      0.022497639060020447,
      -0.004242870025336742,
      -0.03376515582203865,
      -0.04220740497112274,
      -0.00763315986841917,
      0.003879754338413477,
      -0.024188531562685966,
      -0.019596431404352188,
      0.0008854371262714267,
      -0.028025977313518524,
      0.010030027478933334,
      0.02918948605656624,
      0.06970857083797455,
      -0.0635390430688858,
      -0.03369945287704468,
      0.04414265230298042,
      -0.032593391835689545,
      0.02301562950015068,
      -0.023339198902249336,
      -0.022912200540304184,
      0.004263791721314192,
      0.05463789775967598,
      -0.0020726840011775494,
      0.04695933684706688,
      0.023834144696593285,
      -0.0183328315615654,
      -0.026112016290426254,
      0.07859698683023453,
      0.03422098979353905,
      0.023004014045000076,
      -0.011101889424026012,
      0.025707734748721123,
      0.035721730440855026,
      -0.0049944487400352955,
      0.028535597026348114,
      0.053971897810697556,
      -0.07024379819631577,
      0.08909620344638824,
      -0.010580566711723804,
      -0.14296498894691467,
      0.010641374625265598,
      0.05411280691623688,
      -0.04031100869178772,
      0.057130321860313416,
      0.012330264784395695,
      0.03933718800544739,
      0.03678610175848007,
      -0.03118470311164856,
      0.03686030954122543,
      -0.018914801999926567,
      -0.0029452680610120296,
      -0.046347618103027344,
      0.0021932402160018682,
      0.0463871955871582,
      -0.053567010909318924,
      -0.07145025581121445,
      -0.041720278561115265,
      -0.06147081032395363,
      0.07165446132421494,
      -0.03302709013223648,
      0.05944518744945526,
      0.02741936594247818,
      0.06772653758525848,
      -0.06707841902971268,
      0.005123769864439964,
      -0.06853794306516647,
      0.0035499760415405035,
      0.06225144490599632,
      0.01832248829305172,
      -0.05732947960495949,
      -0.015859806910157204,
      -0.022543804720044136,
      0.05374540388584137,
      0.008970391005277634,
      0.0551409050822258,
      0.05189863592386246,
      -0.0528515987098217,
      0.05884448438882828,
      -0.03123515471816063,
      0.06045322120189667,
      0.04627653583884239,
      0.010962482541799545,
      -0.06729399412870407,
      -0.04499933123588562,
      -0.046637073159217834,
      -0.02337466925382614,
      0.05668632313609123,
      0.01881612464785576,
      -0.03696844354271889,
      -0.001413442543707788,
      0.02177320420742035,
      -0.042794790118932724,
      0.07416029274463654,
      -0.04282310605049133,
      -0.010495215654373169,
      0.028714464977383614,
      0.027863657101988792,
      -0.04192008823156357,
      0.016872510313987732,
      0.04042420908808708,
      0.007985645905137062,
      -0.02037774585187435,
      0.04662762209773064,
      0.09446704387664795,
      -0.014389123767614365,
      -0.01624901220202446,
      0.06880272924900055,
      -0.08035524189472198,
      -0.01633669249713421,
      0.13958150148391724,
      0.004178810399025679,
      -0.018282441422343254,
      0.0006024724571034312,
      -0.02173229306936264,
      0.0016670816112309694,
      0.020822614431381226,
      -0.06635235249996185,
      -0.0158710777759552,
      -0.024195922538638115,
      0.041018903255462646,
      -0.0728418231010437,
      0.022978177294135094,
      0.030581088736653328,
      -0.02202017232775688,
      0.04673171788454056,
      0.013847053982317448,
      0.08286096155643463,
      -0.05799552798271179,
      0.0010724504245445132,
      -0.025412248447537422,
      -0.006049787625670433,
      0.0009182572830468416,
      0.06795262545347214,
      -0.09185850620269775,
      0.07576082646846771,
      -0.036610398441553116,
      -0.0012718952493742108,
      -0.021145688369870186,
      0.10107862949371338,
      0.005509740673005581,
      -0.010228733532130718,
      0.025397391989827156,
      -0.017901849001646042,
      -0.09996151924133301,
      -0.07516316324472427,
      0.03507886826992035,
      -0.0381622314453125,
      -0.04492669925093651,
      0.005642331205308437,
      -0.06122483313083649,
      -0.07585525512695312,
      0.03681492432951927,
      -0.03624487295746803,
      -0.10989398509263992,
      0.022765731438994408,
      -0.004794552456587553,
      0.04753438010811806,
      0.03503328934311867,
      -0.014192907139658928,
      0.022156039252877235,
      -0.020604178309440613,
      0.030930135399103165,
      0.02870730683207512,
      -0.053715240210294724,
      -0.05120595917105675,
      0.055545441806316376,
      0.0071089123375713825,
      0.004861381370574236,
      -0.027811400592327118,
      -0.022186266258358955,
      -0.006722860038280487,
      0.05843772366642952,
      0.00019724376033991575,
      -0.01336898934096098,
      -0.025981158018112183,
      0.0017913971096277237,
      0.03539402782917023,
      -0.019494295120239258,
      0.03153152018785477,
      0.020211366936564445,
      -0.021219048649072647,
      -0.11318357288837433,
      -0.0004698196717072278,
      -0.04966377839446068,
      -0.03950667753815651,
      0.02613476850092411,
      0.04398319497704506,
      -0.010652267374098301,
      0.06401674449443817,
      -0.0913587361574173,
      0.002653846750035882,
      -0.07030122727155685,
      -0.041140735149383545,
      -0.00996118038892746,
      -0.0019178313668817282,
      -0.03837781772017479,
      -0.05136418715119362,
      -0.0026990703772753477,
      0.020871693268418312,
      0.053653426468372345,
      -0.04500148072838783,
      -0.031266529113054276,
      -0.013493882492184639,
      -0.05684627592563629,
      -0.0355231799185276,
      -0.02181871421635151,
      0.009835487231612206,
      -0.01570497453212738,
      -0.0038018624763935804,
      0.037723541259765625,
      -0.05313054472208023,
      -0.11438395828008652,
      -0.03878903016448021,
      -0.045875921845436096,
      0.012876727618277073,
      -0.017055397853255272,
      0.010599412024021149,
      -0.11223006248474121,
      0.05451934412121773,
      -0.05944715440273285,
      -0.03272387385368347,
      0.07805979996919632,
      -0.03309999406337738,
      -0.08887635171413422,
      -0.006523970514535904,
      -0.0007954624015837908,
      0.023369306698441505,
      -0.009575814008712769,
      -0.04114712029695511,
      0.04336369037628174,
      -0.023659484460949898,
      0.009220349602401257,
      0.054448626935482025,
      -0.04758167639374733,
      0.035642512142658234,
      -0.03658578544855118,
      -0.012181426398456097,
      -0.0006349304458126426,
      -0.04135730490088463,
      0.044380128383636475,
      0.02823861688375473,
      0.00015027879271656275,
      -0.039738722145557404,
      -0.05892464891076088,
      0.05825416371226311,
      -0.07967648655176163,
      0.033308058977127075,
      0.04597190394997597,
      0.024983204901218414,
      -0.014356487430632114,
      -0.030639788135886192,
      0.022372225299477577,
      0.015564595349133015,
      0.05072500929236412,
      0.06311704963445663,
      -0.028587335720658302,
      0.01970437355339527,
      0.019789520651102066,
      0.022146204486489296,
      0.09348563104867935,
      0.006663926411420107,
      -0.07061095535755157,
      -0.03391075134277344,
      -0.006397894583642483,
      0.02358950674533844,
      -0.03027195855975151,
      0.03881445527076721,
      0.00896819680929184,
      -0.0017463108524680138,
      -0.0005141519359312952,
      -0.043863944709300995,
      0.044876616448163986,
      0.004983954131603241,
      0.08838258683681488,
      -0.00451017078012228,
      0.015432003885507584,
      0.05700572952628136,
      0.03106001950800419,
      0.023988278582692146,
      -0.02346907928586006,
      -0.006896957289427519,
      0.015874942764639854,
      0.027043653652071953,
      -0.06821800023317337,
      0.022231217473745346,
      -0.05135231465101242,
      0.02062753401696682,
      -0.019897516816854477,
      0.030711403116583824,
      0.03008488193154335,
      -0.0020284904167056084,
      -0.07815725356340408,
      -0.02147558704018593,
      -0.06615343689918518,
      0.03202119842171669,
      0.08454149961471558,
      -0.0027649649418890476,
      0.0642363503575325,
      -0.030046068131923676,
      0.06500938534736633,
      -0.06099271774291992,
      0.04811970517039299,
      -0.08463139832019806,
      0.04386778175830841,
      0.0032263556495308876,
      -0.06516752392053604,
      -0.024180587381124496,
      -0.032042909413576126,
      -0.04377574473619461,
      -0.02028917707502842,
      -0.06256487965583801,
      -0.006950998213142157,
      -0.016599809750914574,
      -0.0141428979113698,
      -0.05623900517821312,
      -0.0437219999730587,
      -0.05153024196624756,
      0.11329282820224762,
      -0.029320700094103813,
      -0.03576589748263359,
      -0.007791006006300449,
      -0.026998715475201607,
      -0.0014982577413320541,
      -0.017656881362199783,
      -0.06845583021640778,
      0.03551929071545601,
      0.01829185150563717,
      -0.015835562720894814,
      0.06085316091775894,
      -0.05386744812130928,
      -0.018878500908613205,
      0.05071185529232025,
      -0.09996366500854492,
      0.011364645324647427,
      0.02336423099040985,
      -0.02682970091700554,
      -0.01591068133711815,
      0.02547251246869564,
      0.019086413085460663,
      0.006203075405210257,
      0.016880113631486893,
      -0.06951636075973511,
      0.06982843577861786,
      -0.04491737484931946,
      -0.04988708347082138,
      -0.01885874755680561,
      -0.034432604908943176,
      -0.034639280289411545,
      0.016040237620472908,
      0.09333284944295883,
      0.01958431489765644,
      0.0077429646626114845,
      0.007434041704982519,
      0.012865506112575531,
      0.052857205271720886,
      0.0950765460729599,
      0.07614528387784958,
      0.0013582773972302675,
      -0.06641614437103271,
      0.05710478127002716,
      -0.017838535830378532,
      -0.04626878350973129,
      -0.07776610553264618,
      -0.05048566311597824
    ],
    [
      0.013711580075323582,
      0.01805213838815689,
      -0.039128534495830536,
      0.030770421028137207,
      0.017767872661352158,
      0.08653324842453003,
      0.04062976688146591,
      0.04859241843223572,
      -0.030268941074609756,
      -0.01631566695868969,
      -0.1592206060886383,
      -0.009333708323538303,
      -0.051957644522190094,
      -0.0024930390063673258,
      0.051541220396757126,
      0.011479598470032215,
      -0.028684264048933983,
      0.0030151016544550657,
      -0.07761912792921066,
      0.03219910338521004,
      0.03534896299242973,
      0.03073868528008461,
      0.03821290656924248,
      0.0028680944815278053,
      -0.021081045269966125,
      -0.04829392209649086,
      0.012019727379083633,
      0.07833851128816605,
      -0.10213794559240341,
      0.03744421899318695,
      0.011102283373475075,
      0.011187120340764523,
      0.07650531083345413,
      0.028134070336818695,
      0.04441556707024574,
      0.02184024639427662,
      -0.004259028006345034,
      0.005775416735559702,
      -0.015904342755675316,
      -0.006532648112624884,
      0.013041302561759949,
      0.004756590351462364,
      0.03550473228096962,
      -0.010986628010869026,
      -0.03657030314207077,
      0.03282918408513069,
      0.016010306775569916,
      0.02822556160390377,
      0.07784412801265717,
      -0.10341376811265945,
      0.01476410124450922,
      0.04951583594083786,
      0.027318060398101807,
      -0.0661102682352066,
      -0.0020152200013399124,
      0.011429093778133392,
      0.0783429816365242,
      0.12914717197418213,
      -0.07498445361852646,
      0.026923784986138344,
      -0.003384229727089405,
      0.06162579730153084,
      -0.025813564658164978,
      0.06720997393131256,
      0.11059724539518356,
      0.051804687827825546,
      0.01742488332092762,
      0.025176817551255226,
      -0.1276211440563202,
      -0.04903780296444893,
      0.024922382086515427,
      -0.03110097162425518,
      -0.03235490620136261,
      -0.02667427994310856,
      0.009335123002529144,
      0.03745070472359657,
      -0.023393752053380013,
      0.03391009941697121,
      -0.011353282257914543,
      0.004719062242656946,
      -0.09028743207454681,
      -0.013034064322710037,
      -0.03717420995235443,
      -0.09433994442224503,
      0.02555081620812416,
      -0.042564745992422104,
      0.04764208942651749,
      0.015374080277979374,
      -0.10711812227964401,
      0.0614912249147892,
      0.01463362853974104,
      0.028262846171855927,
      -0.06474515795707703,
      -0.01816394552588463,
      0.01127257663756609,
      -0.026881985366344452,
      -0.06080332770943642,
      -0.06989589333534241,
      -0.024722078815102577,
      -0.026670433580875397,
      -0.016901293769478798,
      0.022110741585493088,
      0.029828300699591637,
      0.11813128739595413,
      -0.011894532479345798,
      0.022195495665073395,
      0.06059285253286362,
      0.05079380422830582,
      -0.032105930149555206,
      0.10242784768342972,
      0.010402165353298187,
      -0.020423684269189835,
      0.0004496460605878383,
      -0.02109825238585472,
      0.00968058593571186,
      0.04290112480521202,
      -0.05728847533464432,
      -0.004793803207576275,
      0.08511101454496384,
      -0.012190651148557663,
      0.07838329672813416,
      -0.011221927590668201,
      -0.08793327212333679,
      0.06389185786247253,
      -0.025699147954583168,
      0.051822926849126816,
      -0.03276976943016052,
      0.02187654748558998,
      0.01835610903799534,
      -0.03950478881597519,
      0.060147322714328766,
      -0.028284108266234398,
      0.041260648518800735,
      0.01851346157491207,
      -0.04786231741309166,
      -0.00488624582067132,
      -0.05986148118972778,
      0.028308235108852386,
      -0.03048482909798622,
      0.06376080960035324,
      -0.04604718089103699,
      0.03230064734816551,
      0.015377450734376907,
      -0.03673090040683746,
      -0.0783141627907753,
      0.040194250643253326,
      0.032851848751306534,
      -0.015567661263048649,
      -0.02062593586742878,
      -0.018443375825881958,
      -0.01715739630162716,
      -0.0009367566090077162,
      0.008103830739855766,
      -0.046109504997730255,
      -0.05215175449848175,
      -0.022203076630830765,
      0.030411899089813232,
      -0.0007802706677466631,
      -0.012914636172354221,
      -0.0022625024430453777,
      0.032948095351457596,
      -0.05453822761774063,
      0.045467011630535126,
      -0.0036715995520353317,
      0.04770936444401741,
      0.033107805997133255,
      -0.1108020693063736,
      0.0084606958553195,
      0.09680760651826859,
      -0.005911367945373058,
      0.06588342785835266,
      -0.004183354787528515,
      0.011791838333010674,
      0.07861702889204025,
      -0.01062900573015213,
      -0.029315106570720673,
      0.028974948450922966,
      0.024282850325107574,
      0.031078247353434563,
      0.05773459002375603,
      -0.046324558556079865,
      0.014496555551886559,
      0.040366217494010925,
      -0.03737514093518257,
      -0.011436503380537033,
      0.03082987479865551,
      0.023718202486634254,
      0.07894556224346161,
      -0.034081168472766876,
      0.07409950345754623,
      -0.03670849651098251,
      -0.04417404904961586,
      0.023526396602392197,
      -0.07033639401197433,
      -0.005461717024445534,
      -0.06617343425750732,
      0.04498149827122688,
      -0.029147226363420486,
      0.08656908571720123,
      0.023864205926656723,
      -0.006451268680393696,
      0.05589236691594124,
      -0.08274218440055847,
      0.052381932735443115,
      -0.0669109895825386,
      0.008599408902227879,
      0.030116667971014977,
      0.06613708287477493,
      -0.04260089993476868,
      0.05347798764705658,
      -0.04584614560008049,
      0.009603586047887802,
      0.0033762736711651087,
      0.002049078466370702,
      0.003358869580551982,
      -0.006492610555142164,
      -0.02057185024023056,
      -0.09758090227842331,
      0.027572235092520714,
      0.025446798652410507,
      0.02590683102607727,
      0.04520328342914581,
      0.04268106073141098,
      0.12249011546373367,
      -0.005412611179053783,
      0.0067164101637899876,
      -0.00937629397958517,
      -0.04038375988602638,
      0.03248324990272522,
      -0.02356831729412079,
      -0.05189907178282738,
      -0.01064055785536766,
      -0.03609209880232811,
      -0.04611834138631821,
      0.08759961277246475,
      -0.020226387307047844,
      0.07018700987100601,
      0.04440491273999214,
      0.01792910322546959,
      0.011631364934146404,
      -0.02441086247563362,
      0.034423843026161194,
      0.08363870531320572,
      -0.05467688664793968,
      -0.014999599196016788,
      -0.004399329889565706,
      0.01903437450528145,
      -0.018947143107652664,
      0.059718530625104904,
      0.03148083761334419,
      0.01740119792521,
      0.005259346216917038,
      -0.022754989564418793,
      0.08081047981977463,
      -0.018708691000938416,
      -0.053426943719387054,
      0.06269026547670364,
      -0.052409827709198,
      0.02679279074072838,
      0.017916880548000336,
      -0.01581287942826748,
      -0.046813737601041794,
      0.09382359683513641,
      0.054671771824359894,
      -0.022178227081894875,
      0.03398863598704338,
      -0.008532841689884663,
      -0.06656505912542343,
      0.0001988054282264784,
      -0.004728854633867741,
      -0.053297024220228195,
      -0.005878000520169735,
      0.05468880385160446,
      0.015287443064153194,
      -0.002846336690708995,
      0.0919475257396698,
      0.09027816355228424,
      0.10862662643194199,
      -0.004450318869203329,
      -0.06318126618862152,
      0.024303315207362175,
      -0.03528367355465889,
      0.06593885272741318,
      -0.032005131244659424,
      -0.016744710505008698,
      0.017172440886497498,
      -0.022941485047340393,
      0.021338127553462982,
      -0.08161007612943649,
      0.08530698716640472,
      0.04131714627146721,
      -0.01687718741595745,
      0.10983528941869736,
      -0.0045476951636374,
      0.08713915944099426,
      0.06348025053739548,
      -0.015193079598248005,
      -0.0957934781908989,
      0.061083462089300156,
      0.022208254784345627,
      0.05410905182361603,
      -0.07992611825466156,
      -0.06543396413326263,
      0.014799151569604874,
      0.012463406659662724,
      0.033032145351171494,
      0.028288964182138443,
      -0.02244681306183338,
      0.0646708533167839,
      -0.06307370960712433,
      0.023043187335133553,
      0.07390516251325607,
      -0.03909837827086449,
      -0.0030166837386786938,
      0.025823574513196945,
      -0.0044127400033175945,
      -0.02130591683089733,
      0.0052336701191961765,
      0.0026815596502274275,
      0.015226179733872414,
      0.010758272372186184,
      -0.08682236820459366,
      0.027257470414042473,
      0.031212588772177696,
      0.028073672205209732,
      -0.0442691408097744,
      -0.015404182486236095,
      0.06736819446086884,
      -0.009848418645560741,
      0.054206229746341705,
      -0.010468298569321632,
      -0.017983926460146904,
      -0.026790348812937737,
      0.06886693835258484,
      -0.02550879679620266,
      -0.08045535534620285,
      -0.09371571987867355,
      0.03635617718100548,
      -0.10752113908529282,
      -0.01592540554702282,
      0.04743044450879097,
      -0.025854719802737236,
      -0.01717202737927437,
      0.059747397899627686,
      0.1047503799200058,
      0.06518987566232681,
      -0.0673045665025711,
      -0.013031751848757267,
      0.00038879181374795735,
      0.022017668932676315,
      -0.00046103226486593485,
      -0.0035021649673581123,
      0.05653902888298035,
      0.07676806300878525,
      -0.04831284284591675,
      -0.056886814534664154,
      0.019460834562778473,
      0.0015598441241309047,
      0.04238887131214142,
      -0.07918976247310638,
      0.03018449805676937,
      -0.06957568228244781,
      -0.09807702898979187,
      0.010299774818122387,
      0.0012763425474986434,
      0.0008744518854655325,
      0.006137493532150984,
      -0.021567746996879578,
      0.005069522652775049,
      0.06653239578008652,
      0.01878223940730095,
      -0.02416979894042015,
      -0.02515811286866665,
      -0.028765114024281502,
      -0.09535501152276993,
      0.04237490892410278,
      -0.020312044769525528,
      -0.056534476578235626,
      -0.035219669342041016,
      -0.03099868819117546,
      -0.03570246696472168,
      0.030639057978987694,
      -0.033553652465343475,
      -0.03703785315155983,
      -0.050731122493743896,
      0.0048665315844118595,
      0.0634055882692337,
      -0.01301597524434328,
      -0.04476350173354149,
      -0.0162653848528862,
      0.0007399171590805054,
      -0.10547195374965668,
      0.04967932403087616,
      -0.023950273171067238,
      -0.011469995602965355,
      -0.013132882304489613,
      0.08623839914798737,
      0.021880101412534714,
      -0.009520596824586391,
      -0.07393648475408554,
      -0.045449018478393555,
      -0.06778367608785629,
      0.04720311611890793,
      0.013482160866260529,
      -0.017446063458919525,
      0.001435844344086945,
      -0.02004045993089676,
      0.04890262335538864,
      0.006840262096375227,
      -0.045986197888851166,
      0.023823672905564308,
      -0.05386938154697418,
      -0.00749298557639122,
      -0.025890232995152473,
      -0.032228969037532806,
      0.040140070021152496,
      -0.0756087601184845,
      -0.008872601203620434,
      0.0782477855682373,
      0.007720183581113815,
      0.003266057465225458,
      0.010268178768455982,
      -0.06226857751607895,
      0.020813824608922005,
      -0.04569007456302643,
      -0.021055808290839195,
      -0.0910278707742691,
      -0.014010447077453136,
      0.057300180196762085,
      -0.004675413016229868,
      0.0057632410898804665,
      -0.006618811748921871,
      -0.001807437278330326,
      -0.030779799446463585,
      0.03445979580283165,
      -0.01281309686601162,
      -0.014716455712914467,
      -0.022090408951044083,
      -0.016879698261618614,
      0.007541852071881294,
      0.06083707511425018,
      0.037933025509119034,
      0.037638962268829346,
      -0.013498260639607906,
      -0.05104349926114082,
      0.10384675115346909,
      0.05030600726604462,
      0.014675899408757687,
      -0.0462501123547554,
      -0.027982525527477264,
      0.06445588171482086,
      -0.055735621601343155,
      -0.06468331068754196,
      0.04876479133963585,
      0.011150617152452469,
      0.05423075705766678,
      -0.01842782460153103,
      0.019235603511333466,
      -0.04752250388264656,
      -0.01827494241297245,
      -0.02679530903697014,
      -0.056238219141960144,
      0.01885838247835636,
      -0.08548087626695633,
      0.036690160632133484,
      -0.03222807124257088,
      -0.04404690861701965,
      0.010839981026947498,
      -0.031146088615059853,
      -0.03829063102602959,
      -0.027278713881969452,
      -0.05744694918394089,
      -0.03975705802440643,
      -0.03341039642691612,
      -0.023602129891514778,
      -0.029742058366537094,
      -0.018289677798748016,
      -0.03428139537572861,
      -0.04821397364139557,
      0.006745670922100544,
      0.003543855156749487,
      -0.05906788632273674,
      -0.012941673398017883,
      -0.013270020484924316,
      -0.01899617351591587,
      -0.05863058567047119,
      -0.057080190628767014,
      -0.03516051173210144,
      0.020310161635279655,
      -0.008364262990653515,
      -0.05909641087055206,
      -0.04421431943774223,
      0.03280842304229736,
      -0.024006938561797142,
      -0.07754813879728317,
      -0.07566145062446594,
      0.013723718002438545,
      0.01946602761745453,
      -0.02037907764315605,
      -0.006845668889582157,
      -0.0029520264361053705,
      0.007813598029315472,
      0.04774734005331993
    ],
    [
      -0.038389623165130615,
      0.005823816172778606,
      0.04018331691622734,
      0.07344508171081543,
      -0.01783921755850315,
      -0.030127346515655518,
      0.021214768290519714,
      0.042740348726511,
      -0.10031355917453766,
      -0.08043570816516876,
      0.0021404761355370283,
      0.00979580543935299,
      -0.058779165148735046,
      -0.004285515286028385,
      0.03153598681092262,
      -0.0022632512263953686,
      -0.007073394488543272,
      -0.026812538504600525,
      0.03037075884640217,
      -0.04029971361160278,
      -0.04406309127807617,
      0.08318402618169785,
      0.05768071115016937,
      -0.029935920611023903,
      0.017075952142477036,
      0.03230373188853264,
      -0.03077743574976921,
      0.01529805175960064,
      -0.03772401064634323,
      0.026243750005960464,
      -0.028990888968110085,
      0.08460601419210434,
      0.02371714822947979,
      0.08220224827528,
      0.011226372793316841,
      0.06172235310077667,
      0.051891159266233444,
      -0.029761018231511116,
      0.009006550535559654,
      0.07847169041633606,
      0.06456296890974045,
      0.02240726165473461,
      0.02467750385403633,
      0.04382189363241196,
      -0.046089909970760345,
      0.06584322452545166,
      -0.015013779513537884,
      0.05609821528196335,
      0.011135087348520756,
      0.03111644834280014,
      0.03515669330954552,
      -0.04278852790594101,
      0.01568634994328022,
      -0.041237615048885345,
      -0.009302787482738495,
      0.02189321257174015,
      0.07719693332910538,
      0.016013436019420624,
      0.05955260992050171,
      -0.04650872200727463,
      0.04519781842827797,
      -0.0009159932960756123,
      -0.05231914669275284,
      -0.030137017369270325,
      0.09008605033159256,
      -0.02077135629951954,
      0.021332835778594017,
      0.0440790131688118,
      -0.00032709166407585144,
      -0.09863996505737305,
      -0.014727486297488213,
      -0.031805332750082016,
      -0.010127650573849678,
      -0.07065362483263016,
      -0.006750976201146841,
      0.03213072568178177,
      0.035124268382787704,
      -0.0634000152349472,
      0.09348606318235397,
      -0.03638676926493645,
      -0.03966975212097168,
      0.052632834762334824,
      -0.05695737153291702,
      -0.025789622217416763,
      0.09124737232923508,
      -0.030788129195570946,
      -0.05113532021641731,
      -0.006624205969274044,
      0.026740359142422676,
      -0.07502512633800507,
      0.022771792486310005,
      -0.011929378844797611,
      0.004409477114677429,
      0.010644111782312393,
      0.05749974027276039,
      -0.0024391384795308113,
      -0.013656748458743095,
      -0.02955208159983158,
      0.03232787176966667,
      -0.01584409549832344,
      0.04065658897161484,
      -0.00969152431935072,
      0.08880935609340668,
      0.03973611444234848,
      -0.0451689139008522,
      0.044829726219177246,
      -0.00357615202665329,
      -0.05568950995802879,
      -0.060681071132421494,
      -0.001329056452959776,
      -0.10480327904224396,
      0.003816543146967888,
      -0.008130994625389576,
      0.0001857360330177471,
      -0.06126802787184715,
      0.05808420851826668,
      -0.034460555762052536,
      -0.05730167403817177,
      -0.07259838283061981,
      -0.013447271659970284,
      0.051977816969156265,
      -0.02225799486041069,
      -0.022996976971626282,
      0.00901720765978098,
      0.1053134948015213,
      0.041333187371492386,
      0.05110331252217293,
      -0.08239113539457321,
      0.06300575286149979,
      -0.08137501031160355,
      0.04166973754763603,
      0.040316998958587646,
      -0.0061025917530059814,
      -0.0030267704278230667,
      -0.01635393314063549,
      -0.040459852665662766,
      -0.056024786084890366,
      -0.021243754774332047,
      -0.06602393835783005,
      -0.0032641219440847635,
      0.014697021804749966,
      0.024651721119880676,
      -0.03849565610289574,
      0.07955850660800934,
      -0.013697008602321148,
      -0.008146766573190689,
      -0.026026548817753792,
      -0.03689189255237579,
      0.051960572600364685,
      0.03966619446873665,
      -0.041918329894542694,
      0.025066642090678215,
      0.015002108179032803,
      0.07896601408720016,
      0.02264387346804142,
      -0.015454437583684921,
      -0.010872620157897472,
      0.0464271642267704,
      0.04077534377574921,
      0.07966097444295883,
      -0.07340894639492035,
      -0.028187869116663933,
      -0.04370023310184479,
      0.017640572041273117,
      0.03791653364896774,
      0.0459834560751915,
      0.016864610835909843,
      0.011620812118053436,
      0.03218042850494385,
      -0.008620570413768291,
      0.07512123882770538,
      0.013893276453018188,
      0.057562489062547684,
      -0.06550152599811554,
      0.026919253170490265,
      0.05906793475151062,
      0.08153273165225983,
      -0.003109205048531294,
      0.01788625493645668,
      0.000564069370739162,
      -0.019627366214990616,
      -0.04298783093690872,
      -0.04428772255778313,
      0.0847780853509903,
      0.018600191920995712,
      -0.020827244967222214,
      0.014481466263532639,
      -0.0654839351773262,
      0.02250676043331623,
      0.011026228778064251,
      -0.025687746703624725,
      -0.0031396124977618456,
      0.016879331320524216,
      -0.09425856173038483,
      0.0184396393597126,
      -0.01882762834429741,
      0.011884687468409538,
      0.020358949899673462,
      0.024440007284283638,
      0.045560091733932495,
      0.03955056145787239,
      -0.018133755773305893,
      -0.046731457114219666,
      -0.04123177379369736,
      0.055070292204618454,
      0.060944490134716034,
      -0.02179543860256672,
      -0.007977224886417389,
      -0.027236763387918472,
      0.014437939040362835,
      -0.004304602276533842,
      0.0763126015663147,
      -0.06573479622602463,
      0.0697624459862709,
      0.01142684556543827,
      -0.058254312723875046,
      0.04111068695783615,
      -0.023386292159557343,
      0.01934695988893509,
      0.024399716407060623,
      0.09045907855033875,
      0.08206666260957718,
      0.011557756923139095,
      -0.006596684455871582,
      -0.04159986600279808,
      -0.10851694643497467,
      0.021147621795535088,
      -0.10321157425642014,
      -0.026282288134098053,
      -0.07255491614341736,
      -0.056264035403728485,
      -0.026996567845344543,
      -0.037614304572343826,
      -0.043358977884054184,
      -0.019996022805571556,
      0.043825142085552216,
      -0.021853506565093994,
      0.056022655218839645,
      0.029715467244386673,
      0.01553251501172781,
      -0.021136417984962463,
      0.0028635214548557997,
      -0.022622454911470413,
      -0.031978994607925415,
      -0.028204262256622314,
      -0.11883781105279922,
      0.027797607704997063,
      0.008430316112935543,
      0.04462488740682602,
      -0.018646012991666794,
      -0.02792232669889927,
      -0.019463712349534035,
      -0.04016954079270363,
      0.09208177775144577,
      0.05301109328866005,
      0.0013386292848736048,
      0.00017532591300550848,
      -0.050220686942338943,
      0.05059044063091278,
      -0.012821340002119541,
      -0.07116346061229706,
      0.04601870849728584,
      0.035955145955085754,
      -0.027051076292991638,
      0.01168796606361866,
      -0.008148998022079468,
      -0.016336703673005104,
      -0.04118786379694939,
      0.08349897712469101,
      0.03425489738583565,
      -0.0037352298386394978,
      0.029770493507385254,
      0.03611578047275543,
      -0.0451534241437912,
      0.03602727875113487,
      -0.05291737988591194,
      -0.03570886701345444,
      -0.007455224636942148,
      0.04018886759877205,
      0.022825248539447784,
      0.014148342423141003,
      0.0014940476976335049,
      -0.053105428814888,
      -0.006369434297084808,
      0.017583945766091347,
      -0.038814034312963486,
      0.08813516050577164,
      0.07404637336730957,
      0.053035445511341095,
      0.05244845524430275,
      -0.022960025817155838,
      -0.06916062533855438,
      0.0001355421991320327,
      0.02040058933198452,
      -0.03112943284213543,
      -0.04154648259282112,
      0.03177712857723236,
      -0.035581283271312714,
      0.024450400844216347,
      0.043126001954078674,
      -0.03132249414920807,
      -0.11298175901174545,
      -0.07266703248023987,
      0.01015891507267952,
      -0.009646875783801079,
      -0.0033473889343440533,
      -0.04904278740286827,
      -0.010789400897920132,
      -0.002120270626619458,
      0.00941153708845377,
      0.01927533745765686,
      0.023269597440958023,
      -0.05670231208205223,
      0.06934715062379837,
      0.0018829256296157837,
      -0.040037378668785095,
      0.10502424091100693,
      -0.1255139708518982,
      -0.008536912500858307,
      -0.009852761402726173,
      -0.09440603852272034,
      0.04477623105049133,
      0.07260559499263763,
      0.0012639109045267105,
      -0.02011043205857277,
      0.007877152413129807,
      -0.04837137088179588,
      -0.060256268829107285,
      -0.0011201122542843223,
      0.12472925335168839,
      0.07350677251815796,
      -0.008384937420487404,
      -0.018366552889347076,
      -0.07345700263977051,
      0.041640132665634155,
      0.0009198006591759622,
      0.02799718640744686,
      -0.005204025190323591,
      -0.07585397362709045,
      0.020845694467425346,
      -0.03258472681045532,
      -0.04925311729311943,
      -0.04041934385895729,
      -0.004588876385241747,
      -0.0784291923046112,
      -0.04382234811782837,
      0.0129150515422225,
      -0.02890787459909916,
      -0.009680046699941158,
      0.013152237050235271,
      0.03000214882194996,
      -0.0445832833647728,
      -0.01875918172299862,
      0.01659141480922699,
      -0.017335092648863792,
      0.013640332035720348,
      0.021600650623440742,
      0.033293601125478745,
      0.004138292279094458,
      0.006381717510521412,
      0.00937461294233799,
      -0.03871393948793411,
      -0.050332773476839066,
      -0.026763739064335823,
      0.02269972302019596,
      0.01469326950609684,
      -0.005664288531988859,
      -0.008786879479885101,
      0.012677050195634365,
      -0.026526357978582382,
      -0.02239655703306198,
      -0.03186842426657677,
      0.014571912586688995,
      -0.059355635195970535,
      -0.014409885741770267,
      -0.027609366923570633,
      0.05073828622698784,
      -0.029646649956703186,
      -0.010623013600707054,
      0.11443425714969635,
      -0.03974776342511177,
      -0.05561067536473274,
      -0.038921624422073364,
      -0.028450364246964455,
      0.031686410307884216,
      0.025222914293408394,
      -0.10429403185844421,
      -0.03401067107915878,
      0.044002775102853775,
      -0.0054083820432424545,
      -0.05384979397058487,
      -0.13830938935279846,
      -0.07249212265014648,
      0.029498744755983353,
      -0.033063456416130066,
      -0.0226268470287323,
      0.004254162777215242,
      0.0078042661771178246,
      -0.026905063539743423,
      0.031873270869255066,
      -0.001289949519559741,
      0.003632126608863473,
      -0.03983796387910843,
      0.0026359346229583025,
      0.11576829850673676,
      0.03368746116757393,
      -0.01468968391418457,
      -0.018606219440698624,
      0.00617955019697547,
      0.003948950674384832,
      -0.007516925223171711,
      -0.012891674414277077,
      0.03586655855178833,
      0.07253266870975494,
      0.04569756239652634,
      -0.03625180944800377,
      0.0016871800180524588,
      -0.09425719082355499,
      -0.0074926274828612804,
      0.062256570905447006,
      -0.03705112636089325,
      -0.02394983544945717,
      0.03418313339352608,
      -0.08919424563646317,
      0.01202080026268959,
      -0.03058014065027237,
      -0.047538597136735916,
      0.021586023271083832,
      -0.019863763824105263,
      0.020184729248285294,
      -0.0025950002018362284,
      0.013502877205610275,
      0.05481904745101929,
      0.041719406843185425,
      0.008881154470145702,
      0.03732658922672272,
      -0.0013193609192967415,
      2.6991214326699264e-05,
      0.04048067703843117,
      -0.08655129373073578,
      0.027827294543385506,
      0.07246573269367218,
      0.006907458882778883,
      -0.08760283887386322,
      0.0723489299416542,
      0.0318281464278698,
      0.12317110598087311,
      -3.8548128941329196e-05,
      -0.08406732976436615,
      -0.0068247658200562,
      -0.09772728383541107,
      0.037759844213724136,
      0.016123207286000252,
      -0.07254869490861893,
      0.025017166510224342,
      0.03869695961475372,
      -0.0446559377014637,
      0.06726836413145065,
      -0.03790029138326645,
      0.0728994607925415,
      -0.015516912564635277,
      0.04129870980978012,
      -0.03237884119153023,
      -0.09289602935314178,
      0.06502101570367813,
      0.003860048484057188,
      -0.032616354525089264,
      0.0029748796951025724,
      0.007816008292138577,
      -0.08141203224658966,
      0.017345678061246872,
      -0.000524249451700598,
      -0.00474486593157053,
      -0.03639933466911316,
      -0.01741958037018776,
      -0.02073144167661667,
      -0.04570014402270317,
      -0.0042275795713067055,
      -0.0005640220479108393,
      -0.031851641833782196,
      -0.007521543651819229,
      0.05110393092036247,
      0.012979448772966862,
      -0.053214095532894135,
      -0.06823191791772842,
      0.032001469284296036,
      -0.010586883872747421,
      0.06187589466571808,
      0.04489297419786453,
      -0.03135766088962555,
      0.0525057278573513,
      0.009224936366081238,
      0.04566293954849243,
      -0.006537311244755983,
      0.0030013895593583584,
      -0.07569124549627304,
      0.03736257180571556,
      0.028502481058239937,
      -0.009154473431408405,
      -0.05995473265647888,
      -0.012906176969408989,
      0.06162147596478462,
      0.02270171046257019
    ],
    [
      -0.005749637261033058,
      -0.03430742025375366,
      -0.1049986332654953,
      -0.0336156003177166,
      -0.030285188928246498,
      0.02282768487930298,
      0.10591679811477661,
      -0.06663313508033752,
      0.03922794759273529,
      -0.034389678388834,
      0.01266592089086771,
      -0.0063748168759047985,
      -0.015168351121246815,
      -0.007805755361914635,
      -0.0015949521912261844,
      -0.07833923399448395,
      -0.0352356843650341,
      -0.03139998018741608,
      -0.021450232714414597,
      -0.0038727684877812862,
      -0.05529039725661278,
      0.03147305175662041,
      -0.0675358772277832,
      -0.03341586887836456,
      0.008184068836271763,
      0.041471216827631,
      -0.06688155233860016,
      -0.019723257049918175,
      0.014370684511959553,
      -0.06809258460998535,
      -0.06082237884402275,
      -0.06320678442716599,
      -0.026147902011871338,
      0.010590236634016037,
      -0.0014628084609284997,
      0.043215859681367874,
      -0.058333948254585266,
      -0.08348031342029572,
      0.08531442284584045,
      -0.03355192765593529,
      0.03794020414352417,
      -0.03239694610238075,
      0.05498477816581726,
      0.026307225227355957,
      0.027837149798870087,
      -0.065801702439785,
      0.028440279886126518,
      0.02949107065796852,
      0.0028172186575829983,
      0.02207995392382145,
      -0.013414126820862293,
      -0.05858246982097626,
      -0.011861716397106647,
      -0.028450168669223785,
      -0.007840286009013653,
      0.02295519970357418,
      0.0026605320163071156,
      -0.006999344099313021,
      -0.024380452930927277,
      0.012573819607496262,
      -0.005371080711483955,
      -0.011633498594164848,
      0.018940571695566177,
      -0.04451647028326988,
      -0.005827768240123987,
      -0.003012007335200906,
      0.02385711669921875,
      0.03428465500473976,
      0.02859320677816868,
      -0.023201506584882736,
      0.0022651697508990765,
      -0.09791456162929535,
      -0.04044000059366226,
      0.037958934903144836,
      -0.002609699498862028,
      -0.05552462115883827,
      -0.008048863150179386,
      0.07157919555902481,
      -0.048119235783815384,
      -0.03635778650641441,
      0.06553724408149719,
      -0.003805014071986079,
      -0.006592831574380398,
      -0.00917806662619114,
      0.022605089470744133,
      -0.0773567482829094,
      0.06694882363080978,
      -0.06169028952717781,
      0.05132327601313591,
      -0.06676928699016571,
      0.0367136113345623,
      0.06180057302117348,
      -0.035116832703351974,
      0.018624141812324524,
      0.06493837386369705,
      0.010710123926401138,
      0.12252261489629745,
      0.024562591686844826,
      -0.04234639182686806,
      0.001647119875997305,
      -0.01577908545732498,
      0.08462323993444443,
      0.08231779932975769,
      -0.06968361884355545,
      -0.052486397325992584,
      0.04059556499123573,
      -0.060087189078330994,
      0.030749619007110596,
      0.012055440805852413,
      0.018861452117562294,
      -0.052857574075460434,
      0.0018861198332160711,
      -0.004166448023170233,
      -0.0607021227478981,
      -0.05471236631274223,
      0.012546340934932232,
      0.04833660274744034,
      0.04997977986931801,
      -0.010996704921126366,
      0.014464037492871284,
      0.028207561001181602,
      -0.00652310298755765,
      0.010091245174407959,
      -0.034001316875219345,
      -0.023701287806034088,
      -0.030885953456163406,
      -0.05073102191090584,
      -0.03526687994599342,
      -0.007257381919771433,
      -0.010707128793001175,
      -0.0035146703012287617,
      -0.008665800094604492,
      -0.03325417637825012,
      -0.0612073615193367,
      -0.06609060615301132,
      0.06417305022478104,
      -0.009457176551222801,
      0.0182862039655447,
      0.15507687628269196,
      0.016836067661643028,
      -0.022010892629623413,
      0.06191651523113251,
      0.028142288327217102,
      0.00801005307585001,
      0.01995588280260563,
      -0.009390320628881454,
      0.030220098793506622,
      -0.03889022022485733,
      0.02666504681110382,
      -0.02844194881618023,
      0.03247375413775444,
      -0.055581171065568924,
      -0.03556276112794876,
      -0.013479022309184074,
      0.08687566220760345,
      0.04451371729373932,
      -0.021708186715841293,
      0.09780780971050262,
      0.024820061400532722,
      -0.03584444895386696,
      -0.03862789645791054,
      0.03774100914597511,
      -0.03406621143221855,
      0.026297349482774734,
      0.0152686582878232,
      -0.05907300114631653,
      0.00020920411043334752,
      0.07419390976428986,
      0.07828746736049652,
      0.010474774986505508,
      0.057554081082344055,
      -0.07845916599035263,
      0.01941053941845894,
      -0.042869701981544495,
      -0.008009999990463257,
      0.08276247978210449,
      0.023232465609908104,
      0.03870547190308571,
      -0.05831734091043472,
      0.016627581790089607,
      -0.014844824559986591,
      -0.022662397474050522,
      -0.05508119985461235,
      -0.007262539584189653,
      -0.06598523259162903,
      0.09518776834011078,
      -0.0658995509147644,
      0.0225491002202034,
      0.01007359754294157,
      0.0004926225519739091,
      0.022311951965093613,
      0.008600072003901005,
      0.024472521618008614,
      0.06746217608451843,
      0.02932083047926426,
      0.09159521758556366,
      0.011610535904765129,
      0.009214484132826328,
      -0.0029634686652570963,
      -0.051740095019340515,
      0.04439052194356918,
      0.006650662049651146,
      -0.12075495719909668,
      0.00894876103848219,
      -0.01881503313779831,
      -0.013764845207333565,
      -0.06225256994366646,
      0.0330703966319561,
      -0.09998857975006104,
      -0.05926011875271797,
      -0.059304699301719666,
      0.07677724957466125,
      -0.08404722809791565,
      0.037420544773340225,
      -0.009583879262208939,
      -0.045898646116256714,
      -0.02609846368432045,
      0.014453732408583164,
      -0.015825139358639717,
      -0.07668596506118774,
      0.026245709508657455,
      0.0118648000061512,
      0.025090813636779785,
      0.04731477051973343,
      -0.01717245765030384,
      0.002754442859441042,
      0.029592271894216537,
      0.017487606033682823,
      0.04751717671751976,
      0.015837155282497406,
      -0.03824489563703537,
      0.01254305336624384,
      -0.0034749917685985565,
      0.0018501878948882222,
      -0.02755289152264595,
      -0.00796064455062151,
      0.04132503643631935,
      -0.11325258761644363,
      0.04420212656259537,
      0.020971378311514854,
      -0.06227323040366173,
      -0.0564616359770298,
      0.05442255362868309,
      -0.05249112471938133,
      -0.06802666187286377,
      -0.03304068744182587,
      -0.020605510100722313,
      -0.021495696157217026,
      0.026712024584412575,
      -0.036193620413541794,
      -0.009600088931620121,
      0.07526149600744247,
      -0.011187812313437462,
      -0.014405302703380585,
      -0.036501694470644,
      -0.05900657922029495,
      0.06663098186254501,
      -0.016424888744950294,
      0.0668482556939125,
      0.08533160388469696,
      0.041412122547626495,
      0.02747311443090439,
      -0.05245867371559143,
      -0.04069356247782707,
      0.0003993503632955253,
      0.03654945269227028,
      -0.016049858182668686,
      0.030586805194616318,
      -0.021885771304368973,
      0.04139416664838791,
      -0.08416266739368439,
      0.011562489904463291,
      0.023570826277136803,
      0.011899488046765327,
      0.05495236814022064,
      -0.05128762498497963,
      -0.022493764758110046,
      -0.009822212159633636,
      0.0869811475276947,
      -0.005420290865004063,
      -0.019050560891628265,
      0.05623900890350342,
      -0.003607292892411351,
      0.04693899676203728,
      0.002370978007093072,
      0.03199277073144913,
      0.009497761726379395,
      0.008341805078089237,
      -0.05803372338414192,
      -0.07051068544387817,
      -0.053748179227113724,
      0.06105733662843704,
      0.03873908519744873,
      0.004081957507878542,
      0.018218722194433212,
      -0.02745094895362854,
      -0.03730866312980652,
      -0.018017731606960297,
      0.034864988178014755,
      0.01324435044080019,
      -0.005270249675959349,
      0.047915928065776825,
      -0.047089338302612305,
      0.05025501921772957,
      -0.040481630712747574,
      -0.02925804629921913,
      -0.018454618752002716,
      0.0014942565467208624,
      -0.023600373417139053,
      -0.004573887214064598,
      0.03476535901427269,
      -0.03278220072388649,
      -0.04049672186374664,
      -0.003527160733938217,
      -0.06875280290842056,
      -0.029378481209278107,
      -0.09440062940120697,
      -0.11966480314731598,
      0.003757916623726487,
      -0.03129344433546066,
      -0.12393075227737427,
      0.01610729843378067,
      0.08303044736385345,
      0.005299815442413092,
      -0.036300934851169586,
      0.008121179416775703,
      -0.015760311856865883,
      -0.005206347443163395,
      0.046952713280916214,
      -0.03486582264304161,
      0.013418545015156269,
      0.04312865808606148,
      0.0329415462911129,
      -0.04889513552188873,
      0.029221395030617714,
      -0.025682557374238968,
      0.03666803985834122,
      -0.01905844360589981,
      -0.005751186516135931,
      0.029090935364365578,
      0.09682052582502365,
      -0.045020535588264465,
      0.032507333904504776,
      -0.007953408174216747,
      -0.017301013693213463,
      0.013302628882229328,
      0.003069141414016485,
      -0.08984793722629547,
      -0.005043241195380688,
      0.013082874938845634,
      -0.034830059856176376,
      0.04431060329079628,
      -0.013005789369344711,
      0.07408316433429718,
      0.053976934403181076,
      0.002252100268378854,
      -0.03485044091939926,
      -0.013300440274178982,
      0.00732186296954751,
      -0.03310439735651016,
      -0.07510160654783249,
      0.03290524706244469,
      -0.05051075294613838,
      0.06092242896556854,
      0.07569832354784012,
      -0.04661151394248009,
      0.0159177053719759,
      0.0649041160941124,
      0.08633141964673996,
      -0.009484010748565197,
      -0.05076758190989494,
      0.022744465619325638,
      0.032869432121515274,
      -0.06945767998695374,
      0.04523226618766785,
      0.037234190851449966,
      0.0036043087020516396,
      -0.05904289707541466,
      -0.008106900379061699,
      0.016628263518214226,
      0.0453299954533577,
      -0.010551534593105316,
      0.016280068084597588,
      0.0011879815720021725,
      -0.029936326667666435,
      0.13106262683868408,
      0.014380822889506817,
      0.008632232435047626,
      0.008555821143090725,
      -0.02764807641506195,
      0.006752226501703262,
      0.047929808497428894,
      -0.011149565689265728,
      0.004650318529456854,
      0.0011719014728441834,
      -0.04562864825129509,
      0.021291131153702736,
      0.014737825840711594,
      -0.04585285857319832,
      -0.05180734023451805,
      0.10973259806632996,
      -0.022935235872864723,
      0.03540433943271637,
      0.01193070039153099,
      -0.010266926139593124,
      -0.04483244940638542,
      -0.012708178721368313,
      0.005080056376755238,
      -0.09270668029785156,
      -0.062083929777145386,
      -0.01313734333962202,
      0.0038819005712866783,
      0.0805266872048378,
      -0.09418482333421707,
      -0.011446882039308548,
      -0.034313805401325226,
      -0.054739829152822495,
      -0.02141963504254818,
      -0.0767061859369278,
      -0.008075823076069355,
      0.012968972325325012,
      0.00532370014116168,
      0.07669749110937119,
      0.11420760303735733,
      0.0426204614341259,
      0.0936233326792717,
      0.049167439341545105,
      0.042624086141586304,
      0.000713441870175302,
      -0.038049254566431046,
      -0.10120513290166855,
      0.004829155746847391,
      0.017974693328142166,
      -0.04807332530617714,
      0.05318301543593407,
      -0.00819889735430479,
      0.07350623607635498,
      -0.049392011016607285,
      0.029354918748140335,
      0.05558180436491966,
      -0.059962403029203415,
      0.012293445877730846,
      -0.06007426232099533,
      0.005624611862003803,
      0.014383120462298393,
      0.002136706141754985,
      -0.004495089873671532,
      -0.00577210308983922,
      -0.016009757295250893,
      0.005814956501126289,
      -0.061902858316898346,
      0.044806212186813354,
      0.07322858273983002,
      -0.07781615108251572,
      0.005015823058784008,
      -0.06624290347099304,
      -0.0539565272629261,
      -0.037025004625320435,
      0.01643330045044422,
      -0.01280834712088108,
      0.051146116107702255,
      0.06943978369235992,
      -0.07622030377388,
      0.024923283606767654,
      -0.05677986145019531,
      -0.06353224813938141,
      -0.05326882749795914,
      0.019941596314311028,
      -0.039684489369392395,
      0.016161879524588585,
      -0.025143293663859367,
      0.045568447560071945,
      -0.010320835746824741,
      0.004473081789910793,
      0.051417604088783264,
      -0.06776630878448486,
      -0.01853519305586815,
      -0.04209417477250099,
      0.03816935792565346,
      0.023593340069055557,
      0.01787612773478031,
      -0.01847904361784458,
      -0.07490676641464233,
      -0.01103286538273096,
      -0.05190028250217438,
      0.06533323228359222,
      -0.020409394055604935,
      0.006512296386063099,
      -0.04239014908671379,
      -0.06790342926979065,
      -0.03509936481714249,
      -0.0014373739250004292,
      0.11756172776222229,
      -0.00264892540872097,
      -0.008853078819811344,
      0.04639282077550888,
      0.022692985832691193,
      -0.01609056442975998,
      -0.07784657180309296,
      0.06424108892679214,
      -0.018023796379566193,
      0.04313221573829651,
      0.014607274904847145
    ],
    [
      -0.011836523190140724,
      -0.056698087602853775,
      -0.0505249947309494,
      0.06420475989580154,
      -0.044778112322092056,
      0.0581076554954052,
      -0.052720390260219574,
      -0.0975937470793724,
      -0.0149361751973629,
      0.04310643672943115,
      0.03028305247426033,
      0.10028529167175293,
      -0.03979672119021416,
      -0.03701138123869896,
      -0.005303078331053257,
      0.028519406914711,
      0.012873456813395023,
      -0.016064103692770004,
      -0.06053584814071655,
      0.02727651223540306,
      0.03288944810628891,
      0.08071650564670563,
      0.12135002762079239,
      0.04637540876865387,
      0.01813610829412937,
      0.06812481582164764,
      -0.003124628681689501,
      -0.022858768701553345,
      0.004907133523374796,
      0.0696231871843338,
      0.012634243816137314,
      0.09378459304571152,
      0.023681432008743286,
      0.006332824472337961,
      0.0817192867398262,
      -0.0103224478662014,
      0.047495145350694656,
      0.017134815454483032,
      0.011594719253480434,
      -0.06681497395038605,
      0.05044858530163765,
      -0.06246182322502136,
      -0.025817200541496277,
      0.07236039638519287,
      0.053945332765579224,
      -0.027285626158118248,
      -0.09405097365379333,
      0.004890609532594681,
      0.025400575250387192,
      -0.04080759361386299,
      0.032672006636857986,
      0.08085329830646515,
      -0.01587289571762085,
      0.00038184874574653804,
      0.016561023890972137,
      -0.002000743057578802,
      0.05359280854463577,
      -0.03365236148238182,
      -0.0411035418510437,
      -0.011626466177403927,
      0.04270240291953087,
      0.021409494802355766,
      0.08007027953863144,
      -0.010461592115461826,
      0.024722734466195107,
      -0.0018016481772065163,
      0.12268223613500595,
      -0.01707708090543747,
      -0.036880604922771454,
      -0.007371595129370689,
      0.01899005100131035,
      -0.02291548065841198,
      0.005528769455850124,
      0.02892250008881092,
      -0.01781393587589264,
      0.05238552764058113,
      0.04055078327655792,
      0.00994496513158083,
      2.920477527368348e-06,
      -0.033425215631723404,
      -0.027739310637116432,
      0.006233092397451401,
      0.030095770955085754,
      -0.0018645371310412884,
      0.037817247211933136,
      0.04481371492147446,
      -0.037022460252046585,
      0.07831035554409027,
      -0.007429562509059906,
      -0.04434482753276825,
      -0.008813826367259026,
      0.03563617542386055,
      -0.10144715011119843,
      0.039936453104019165,
      0.009638650342822075,
      0.02940530702471733,
      0.035087745636701584,
      -0.04015336185693741,
      0.003418124746531248,
      -0.0071617611683905125,
      -0.00935203954577446,
      0.01462799496948719,
      -0.017994144931435585,
      0.017434151843190193,
      0.025560645386576653,
      -0.00018731709860730916,
      -0.03173355013132095,
      0.0033120226580649614,
      0.055809494107961655,
      0.02429770864546299,
      0.028558406978845596,
      -0.048609767109155655,
      -0.00806912686675787,
      0.021285373717546463,
      -0.012767418287694454,
      -0.0044371518306434155,
      -0.003555011935532093,
      0.09823629260063171,
      0.0447845496237278,
      -0.033804625272750854,
      0.0018843916477635503,
      0.058683499693870544,
      -0.029522746801376343,
      0.01967535726726055,
      0.009234498254954815,
      -0.04754684120416641,
      0.04457354173064232,
      -0.017565278336405754,
      0.0686597228050232,
      -0.0540066622197628,
      0.08689002692699432,
      0.021952113136649132,
      0.048873189836740494,
      -0.023944741114974022,
      -0.005838748067617416,
      -0.03680497035384178,
      -0.044613294303417206,
      -0.017951693385839462,
      -7.332352106459439e-05,
      0.06384317576885223,
      0.03156091645359993,
      -0.056792136281728745,
      0.023159470409154892,
      -0.01070172805339098,
      -0.03587436303496361,
      0.00021116471907589585,
      -0.049275778234004974,
      -0.02746233157813549,
      0.0018790541216731071,
      0.0628957524895668,
      0.018110768869519234,
      -0.03674677386879921,
      0.08318842202425003,
      0.01279719639569521,
      0.004606164060533047,
      0.0036984900943934917,
      -0.04730062186717987,
      0.0025585296098142862,
      -0.05907054618000984,
      0.03110465221107006,
      -0.01781613938510418,
      0.07407385110855103,
      -0.021607303991913795,
      -0.045301590114831924,
      0.05986902862787247,
      0.02426137402653694,
      0.053399987518787384,
      -0.031861089169979095,
      0.03853730112314224,
      0.06646883487701416,
      0.06687916815280914,
      0.04821205511689186,
      -0.052265509963035583,
      0.12809661030769348,
      0.000824680901132524,
      -0.0086588179692626,
      0.013465499505400658,
      0.007020852062851191,
      0.032636191695928574,
      0.08418465405702591,
      0.030697796493768692,
      -0.03645021840929985,
      0.010148908942937851,
      -0.04447270929813385,
      -0.0483376607298851,
      0.0418267548084259,
      -0.12033533304929733,
      0.007543993182480335,
      -0.02466306835412979,
      -0.040160659700632095,
      -0.04098089411854744,
      -0.020307669416069984,
      0.004323887638747692,
      -0.04007486253976822,
      -0.017372092232108116,
      0.04624828323721886,
      0.03750185668468475,
      -0.02223663218319416,
      -0.011618598364293575,
      0.010508882813155651,
      0.049469705671072006,
      0.05113358795642853,
      0.06534717977046967,
      -0.03225180134177208,
      -0.024733899161219597,
      0.026883332058787346,
      0.02320341020822525,
      -0.041001513600349426,
      -0.06403050571680069,
      0.007674806751310825,
      0.03829788416624069,
      0.05975126847624779,
      -0.03058217652142048,
      0.0910443589091301,
      0.030690299347043037,
      -0.05249057337641716,
      0.017665669322013855,
      0.0765991285443306,
      0.014309070073068142,
      -0.11129304766654968,
      -0.014887416735291481,
      -0.020903246477246284,
      -0.03727714717388153,
      -0.019459741190075874,
      0.01197859924286604,
      0.012356257066130638,
      -0.0361810103058815,
      0.0856298878788948,
      0.027629639953374863,
      0.021542992442846298,
      0.0663708746433258,
      0.02944364957511425,
      -0.07872539758682251,
      -0.04823846369981766,
      0.05852072313427925,
      -0.015502027235925198,
      -0.01839374005794525,
      -0.029772253707051277,
      0.03835402429103851,
      -0.0857815369963646,
      -0.00030923433951102197,
      0.028172751888632774,
      0.02821042202413082,
      -0.01804959587752819,
      0.0049524568021297455,
      0.03277382627129555,
      0.07032418996095657,
      0.039057787507772446,
      -0.03424333035945892,
      0.004533988889306784,
      0.07295864075422287,
      0.06362766772508621,
      -0.030724335461854935,
      -0.03369155526161194,
      0.010269875638186932,
      -0.08473006635904312,
      -0.057883188128471375,
      0.0018201869679614902,
      0.052142418920993805,
      -0.08428187668323517,
      0.04979049041867256,
      -0.03639427199959755,
      0.034536976367235184,
      -0.08748482167720795,
      0.04790807515382767,
      -0.06630279123783112,
      0.01731470786035061,
      -0.05842328444123268,
      -0.01743803173303604,
      0.036481115967035294,
      -0.030696092173457146,
      0.027885638177394867,
      0.013016438111662865,
      0.021710235625505447,
      0.04482566565275192,
      -0.03151549771428108,
      -0.03922012075781822,
      0.010691706091165543,
      0.026536619290709496,
      -0.08887502551078796,
      -0.03479738533496857,
      -0.03523410111665726,
      -0.11933509260416031,
      -0.002803158015012741,
      0.001249420689418912,
      0.0010271592764183879,
      0.013249210081994534,
      -0.08320010453462601,
      -0.00991941150277853,
      0.005866806022822857,
      0.010470438748598099,
      -0.0420791395008564,
      -0.003009943524375558,
      0.05367498844861984,
      -0.0029617510735988617,
      0.04919279366731644,
      -0.04403633251786232,
      -0.014437534846365452,
      -0.04718217998743057,
      -0.009341420605778694,
      -0.0506550557911396,
      0.032551318407058716,
      0.04514097794890404,
      -0.04609840735793114,
      0.05280767381191254,
      -0.056282609701156616,
      0.0038788081146776676,
      0.016360027715563774,
      0.04629305750131607,
      0.0879906415939331,
      0.10073880106210709,
      0.052008602768182755,
      0.03490633890032768,
      -0.11509060114622116,
      0.095281682908535,
      -0.058223433792591095,
      0.014816414564847946,
      -0.004736699629575014,
      -0.015303373336791992,
      0.020255159586668015,
      -0.006818936672061682,
      -0.03135225549340248,
      0.02358262427151203,
      0.01469349768012762,
      -0.035685475915670395,
      0.014919817447662354,
      0.01083694864064455,
      -0.02005121111869812,
      0.06686156988143921,
      -0.022488854825496674,
      -0.09545310586690903,
      0.03486572206020355,
      0.01712711714208126,
      0.03226606175303459,
      0.032445184886455536,
      -0.015381347388029099,
      0.0763583853840828,
      -0.0453609861433506,
      -0.021722938865423203,
      0.03331916034221649,
      0.05792733281850815,
      0.1084383875131607,
      0.06920042634010315,
      -0.019622495397925377,
      0.04873814433813095,
      -0.014626999385654926,
      -0.021780868992209435,
      -0.026748834177851677,
      0.04277415946125984,
      -0.10851531475782394,
      -0.027553847059607506,
      0.012076117098331451,
      -0.07738881558179855,
      -0.04205953702330589,
      -0.039628349244594574,
      -0.018773315474390984,
      0.017809875309467316,
      -0.054090846329927444,
      -0.007846116088330746,
      -0.03013012930750847,
      -0.05075838789343834,
      0.011301027610898018,
      -0.049231499433517456,
      0.00937593076378107,
      0.009871573187410831,
      -0.08555424213409424,
      -0.058093324303627014,
      0.06976783275604248,
      -0.02111094631254673,
      -0.023856446146965027,
      -0.012381992302834988,
      0.01081431470811367,
      0.031809333711862564,
      0.04858361557126045,
      -0.004595459904521704,
      -0.003883230034261942,
      -0.02035294845700264,
      -0.03160201013088226,
      0.05085940286517143,
      0.0222927276045084,
      -0.005156921222805977,
      0.0471763014793396,
      0.04393739253282547,
      0.08122939616441727,
      0.04575612023472786,
      -0.025312865152955055,
      0.009334181435406208,
      -0.005251229275017977,
      -0.020534958690404892,
      0.03418540582060814,
      -0.07372727245092392,
      -0.08359580487012863,
      0.0040570893324911594,
      0.016389675438404083,
      0.012477759271860123,
      -0.046927712857723236,
      -0.023260565474629402,
      0.053010523319244385,
      -0.03432312235236168,
      0.09922374039888382,
      -0.009129279293119907,
      0.03246384486556053,
      0.03418907895684242,
      -0.036258190870285034,
      0.01561757456511259,
      0.00673928065225482,
      -0.014064807444810867,
      0.011759823188185692,
      -0.0731520801782608,
      -0.03120275028049946,
      -0.025957586243748665,
      0.02123548835515976,
      0.01042467262595892,
      0.09231866151094437,
      -0.07788138091564178,
      -0.010496743954718113,
      0.021042868494987488,
      0.05362872779369354,
      0.04581274464726448,
      0.045304812490940094,
      0.007132974918931723,
      -0.01771012507379055,
      0.049309391528367996,
      0.020675253123044968,
      -0.004412911832332611,
      -6.662698433501646e-05,
      0.039082031697034836,
      -0.058393049985170364,
      -0.004644062835723162,
      0.017391260713338852,
      -0.04825696349143982,
      -0.011139290407299995,
      -0.003023102879524231,
      -0.0344371423125267,
      -0.05351746827363968,
      0.0033623753115534782,
      0.05078783631324768,
      -0.06173517182469368,
      0.007163279689848423,
      0.054103441536426544,
      -0.00219740835018456,
      0.03173292428255081,
      -0.07532323151826859,
      0.00571570685133338,
      0.02518942952156067,
      0.1005249172449112,
      -0.07164236903190613,
      -0.019692223519086838,
      0.05525166541337967,
      0.008785721845924854,
      -0.03785957396030426,
      -0.01638929732143879,
      0.05428100749850273,
      0.08603790402412415,
      -0.02812318503856659,
      0.042690109461545944,
      -0.0662710964679718,
      0.02339525707066059,
      -0.03843572735786438,
      -0.043701235204935074,
      0.004735567606985569,
      0.08180532604455948,
      -0.003491968847811222,
      -0.022442933171987534,
      0.056733351200819016,
      -0.02436550334095955,
      -0.020834796130657196,
      -0.04564312845468521,
      -0.059059299528598785,
      0.04382538050413132,
      -0.009132727049291134,
      -0.045466337352991104,
      -0.01954040117561817,
      0.005730997771024704,
      -0.005611662287265062,
      -0.019319871440529823,
      -0.006650123279541731,
      0.028198176994919777,
      -0.03005998209118843,
      0.0013505024835467339,
      0.010469109751284122,
      0.008922617882490158,
      0.06873058527708054,
      -0.05898531526327133,
      0.06130305677652359,
      0.029488196596503258,
      -0.0818752869963646,
      0.00447939895093441,
      -0.06659261137247086,
      0.016337675973773003,
      -0.03261071443557739,
      -0.028571028262376785,
      0.0664866715669632,
      0.03839688003063202,
      -0.001914213178679347,
      -0.02340550906956196,
      0.022726448252797127,
      0.03424157574772835,
      -0.03510067239403725,
      0.046075038611888885,
      -0.06639903038740158,
      -0.021126428619027138,
      -0.027578512206673622
    ],
    [
      -0.03980155661702156,
      0.007760724518448114,
      0.13337886333465576,
      0.06859123706817627,
      -0.015181969851255417,
      -0.028018612414598465,
      0.0188990980386734,
      0.09147021919488907,
      0.0427914634346962,
      0.03414331376552582,
      -0.04561306908726692,
      -0.01924535632133484,
      -0.03608512133359909,
      0.026500755921006203,
      -0.05407751724123955,
      0.011392301879823208,
      -0.006654609926044941,
      -0.09320352226495743,
      -0.014196699485182762,
      0.0017385880928486586,
      -0.04160701483488083,
      -0.03810673952102661,
      0.11484311521053314,
      -0.03878992795944214,
      0.02125830575823784,
      -0.04878964275121689,
      0.026232803240418434,
      0.005517700221389532,
      0.011182032525539398,
      -0.04292268306016922,
      -0.01834743842482567,
      0.02280176617205143,
      0.024813510477542877,
      -0.012287440709769726,
      -0.02035888284444809,
      0.030948614701628685,
      -0.06761881709098816,
      -0.04307970032095909,
      0.038289133459329605,
      0.026074077934026718,
      0.09601900726556778,
      -0.025988494977355003,
      -0.059197746217250824,
      0.009597922675311565,
      -0.06325427442789078,
      -0.022001249715685844,
      0.03127321973443031,
      -0.07282385975122452,
      0.03458733484148979,
      -0.042918313294649124,
      0.0020409254357218742,
      0.0036540718283504248,
      -0.0002459915995132178,
      0.05741601809859276,
      0.009752070531249046,
      0.03867316246032715,
      0.0259371530264616,
      -0.030481014400720596,
      0.0007755052065476775,
      -0.031766925007104874,
      0.06850403547286987,
      -0.0011870322050526738,
      0.008305048570036888,
      0.09932238608598709,
      0.04231947660446167,
      -0.03685350343585014,
      0.07884871959686279,
      -0.028785882517695427,
      0.00956065021455288,
      -0.04118845611810684,
      0.01645621843636036,
      0.004332258366048336,
      -0.006240296643227339,
      0.07968009263277054,
      -0.002506585093215108,
      -0.0068304953165352345,
      0.006471197586506605,
      0.004379854071885347,
      -0.03820633143186569,
      -0.018121585249900818,
      0.11925601214170456,
      0.013981497846543789,
      -0.01309109851717949,
      0.02471221797168255,
      -0.062083788216114044,
      -0.05097377300262451,
      -0.0160409826785326,
      0.0186997689306736,
      0.03221689909696579,
      -0.0422075055539608,
      0.048891860991716385,
      -0.0191498976200819,
      -0.0932515561580658,
      0.04909857362508774,
      0.02647934854030609,
      0.0419522188603878,
      -0.09725049883127213,
      0.04257133975625038,
      -0.025644442066550255,
      0.06472090631723404,
      -0.06695236265659332,
      0.030426889657974243,
      -0.036888256669044495,
      -0.0556650348007679,
      -0.019519805908203125,
      0.02923496626317501,
      -0.12213359773159027,
      -0.09137347340583801,
      -0.015409000217914581,
      0.059608589857816696,
      -0.0017611348303034902,
      -0.0032235360704362392,
      0.055519942194223404,
      -0.06034131720662117,
      0.07393114268779755,
      0.08618395775556564,
      -0.07767915725708008,
      0.01869361847639084,
      0.023090990260243416,
      0.024534491822123528,
      -0.00021499177091754973,
      -0.0665072649717331,
      0.05398968607187271,
      0.007558884099125862,
      0.012843919917941093,
      0.043637555092573166,
      0.06558404117822647,
      -0.0174520593136549,
      0.03928022459149361,
      -0.00220115901902318,
      -0.014047627337276936,
      0.029110347852110863,
      -0.09140165895223618,
      -0.06262321025133133,
      -0.033837106078863144,
      0.01570677012205124,
      0.011179878376424313,
      -0.009186842478811741,
      0.022151494398713112,
      0.06850980222225189,
      0.017509382218122482,
      0.01800311729311943,
      0.03674698993563652,
      0.04734742268919945,
      0.015789465978741646,
      0.0071681165136396885,
      0.018746785819530487,
      -0.015615199692547321,
      0.09068343788385391,
      -0.028759239241480827,
      -0.019061623141169548,
      0.009164582006633282,
      -0.02021854557096958,
      -0.02924000471830368,
      0.05805222690105438,
      -0.05635956674814224,
      -0.08158722519874573,
      -0.07208126783370972,
      -0.030004439875483513,
      0.02398780733346939,
      -0.003699214430525899,
      -0.014714051969349384,
      0.02820361591875553,
      0.0036894557997584343,
      0.07510580122470856,
      0.012093923054635525,
      -0.012373583391308784,
      0.03461989015340805,
      0.006194205489009619,
      -0.04424814134836197,
      0.04387315735220909,
      -0.003924870863556862,
      0.006424273364245892,
      -0.044561661779880524,
      -0.08010530471801758,
      -0.06084346026182175,
      0.02339066006243229,
      0.030059603974223137,
      -0.03321389853954315,
      -0.013148454949259758,
      0.052683863788843155,
      0.0024218596518039703,
      0.04043973237276077,
      -0.06471594423055649,
      0.014819735661149025,
      -0.029852205887436867,
      0.030401065945625305,
      -0.017788413912057877,
      -0.06766820698976517,
      -0.03181837499141693,
      -0.00861989613622427,
      0.020832981914281845,
      -0.0018801760161295533,
      -0.034465331584215164,
      -0.03726201504468918,
      -0.030825620517134666,
      -9.444559509574901e-06,
      0.08941148221492767,
      0.11687106639146805,
      -0.01312397699803114,
      -0.038297440856695175,
      -0.022243427112698555,
      0.01922498270869255,
      -0.04548473283648491,
      -0.07641435414552689,
      -0.03246954083442688,
      -0.001772901974618435,
      0.05882553383708,
      -0.061919331550598145,
      0.06330931186676025,
      -7.055421156110242e-05,
      -0.07462722808122635,
      0.03444351255893707,
      0.0009636393515393138,
      0.06226792931556702,
      0.05093779414892197,
      -0.01237302366644144,
      -0.04783172905445099,
      0.03323032334446907,
      0.009249420836567879,
      0.0851229727268219,
      0.011245436035096645,
      -0.08919617533683777,
      0.03166735917329788,
      0.004318309016525745,
      0.06299715489149094,
      0.061238743364810944,
      0.03698169067502022,
      -0.01948552206158638,
      -0.06447964161634445,
      -0.09751728177070618,
      -0.020759085193276405,
      -0.01889690011739731,
      -0.02211899682879448,
      0.11679456382989883,
      -0.023931633681058884,
      -0.015890948474407196,
      -0.0009522076579742134,
      0.06035187467932701,
      0.04662838578224182,
      0.02064230851829052,
      -0.13395357131958008,
      -0.021268827840685844,
      0.06500522047281265,
      0.023978615179657936,
      -0.008603340946137905,
      0.0174965001642704,
      0.03626647964119911,
      0.01633927971124649,
      -0.024650797247886658,
      0.031157149001955986,
      -0.07850237190723419,
      0.03633169084787369,
      -0.045007411390542984,
      -0.03464604169130325,
      0.024240538477897644,
      -0.027148127555847168,
      -0.04113173484802246,
      -0.055285997688770294,
      -0.0906875804066658,
      -0.02800682559609413,
      -0.015082082711160183,
      -0.0971669927239418,
      -0.022939350455999374,
      0.013148367404937744,
      0.00010915150051005185,
      0.06999468058347702,
      0.021281002089381218,
      -0.009510179981589317,
      -0.0450117252767086,
      0.09054987877607346,
      -0.006344879046082497,
      -0.05761999636888504,
      0.06816805899143219,
      0.050976626574993134,
      -0.1044531986117363,
      0.06001224368810654,
      -0.04029306769371033,
      0.008701156824827194,
      0.037755340337753296,
      -0.02132294699549675,
      0.04723250865936279,
      -0.01618148572742939,
      0.06317899376153946,
      0.07324663549661636,
      0.0549018420279026,
      0.008013284765183926,
      0.04966166242957115,
      -0.12591323256492615,
      0.03908199071884155,
      -0.013061882928013802,
      0.007906758226454258,
      -0.0029148883186280727,
      -0.015710899606347084,
      -0.03949255496263504,
      -0.05646209046244621,
      0.010549566708505154,
      -0.010492604225873947,
      0.07807023823261261,
      0.01403262373059988,
      0.051848944276571274,
      0.027355821803212166,
      -0.044873908162117004,
      -0.08305329829454422,
      0.053251538425683975,
      -0.017970651388168335,
      0.021221693605184555,
      0.024160543456673622,
      -0.018122417852282524,
      -0.04296068474650383,
      -0.03632147237658501,
      0.04550788551568985,
      0.002033398486673832,
      -0.0006828898331150413,
      0.029080213978886604,
      -0.027423163875937462,
      -0.02963918447494507,
      0.046859290450811386,
      -0.04086001217365265,
      0.10222555696964264,
      0.031403400003910065,
      0.02101888321340084,
      0.017924921587109566,
      -0.012476421892642975,
      0.048302020877599716,
      0.008727836422622204,
      0.03998751938343048,
      0.07129070907831192,
      -0.0015707483980804682,
      0.08224837481975555,
      0.09841582179069519,
      -0.013787416741251945,
      0.004699405748397112,
      -0.005298659671097994,
      0.02628062479197979,
      -0.01926063746213913,
      0.005985585041344166,
      -0.02242008037865162,
      -0.018113238736987114,
      -0.037713777273893356,
      -0.008999777026474476,
      0.04622538760304451,
      0.06069745868444443,
      0.04270273447036743,
      0.006659196224063635,
      -0.0576385073363781,
      -0.07027268409729004,
      -0.002725462429225445,
      -0.0450073778629303,
      0.07150282710790634,
      0.01496448926627636,
      -0.0007593554328195751,
      -0.0068492884747684,
      -0.014875364489853382,
      -0.026076937094330788,
      -0.020496197044849396,
      -0.024383878335356712,
      -0.023568283766508102,
      -0.06833373010158539,
      0.014703625813126564,
      0.04504504054784775,
      -0.14290031790733337,
      -0.04512092471122742,
      0.06666111201047897,
      0.013894469477236271,
      -0.09100048243999481,
      0.03803499788045883,
      0.01477927528321743,
      -0.004502934403717518,
      0.019136514514684677,
      0.009244750253856182,
      -0.03667392581701279,
      -0.045385610312223434,
      0.050921473652124405,
      -0.039005279541015625,
      -0.06673590838909149,
      0.0021161483600735664,
      0.034381184726953506,
      -0.014420481398701668,
      0.007588346488773823,
      -0.13150262832641602,
      0.0350957065820694,
      0.029116367921233177,
      0.04188622534275055,
      -0.07878696918487549,
      -0.011158530600368977,
      -0.02540360391139984,
      0.02596292272210121,
      -0.04436469078063965,
      -0.007051246706396341,
      -0.06301142275333405,
      -0.02889505960047245,
      -0.07182351499795914,
      0.08295206725597382,
      -0.014819120056927204,
      0.014802124351263046,
      -0.06880311667919159,
      0.01496928185224533,
      -0.03248585760593414,
      -0.04558688402175903,
      -0.052201222628355026,
      0.03109586052596569,
      -0.056221697479486465,
      0.022951116785407066,
      0.055335067212581635,
      -0.0020712767727673054,
      -0.002040924271568656,
      -0.004887279588729143,
      0.04246228188276291,
      -0.019709045067429543,
      0.006880343891680241,
      -0.008183677680790424,
      0.05189875513315201,
      -0.002494384767487645,
      -0.011969619430601597,
      0.013243092224001884,
      -0.06090308725833893,
      0.032857563346624374,
      -0.04046452045440674,
      -0.031121259555220604,
      -0.004382150713354349,
      -0.055696774274110794,
      0.03687590733170509,
      -0.022383086383342743,
      -0.051654376089572906,
      -0.07662029564380646,
      -0.03295795992016792,
      0.01573977619409561,
      0.06967998296022415,
      -0.07043876498937607,
      0.05425884202122688,
      0.0025102607905864716,
      -0.014089020900428295,
      -0.04399484023451805,
      -0.0786580890417099,
      -0.03776108846068382,
      0.03150596842169762,
      0.010786804370582104,
      -0.06933633983135223,
      -0.08038534224033356,
      0.027966894209384918,
      0.0748048648238182,
      -0.01911774091422558,
      -0.018748875707387924,
      0.011795507743954659,
      0.025157826021313667,
      0.07399721443653107,
      0.03511347249150276,
      -0.06634753942489624,
      0.014865056611597538,
      0.047392986714839935,
      -0.041118964552879333,
      0.01544980425387621,
      -0.019435347989201546,
      -0.04293902590870857,
      -0.08029337227344513,
      -0.016896162182092667,
      -0.05594108626246452,
      -0.06635264307260513,
      0.0006815961678512394,
      0.094129778444767,
      -0.05127502605319023,
      -0.07360311597585678,
      0.007945416495203972,
      -0.029869841411709785,
      0.04316133260726929,
      -0.05970972776412964,
      -0.01495197881013155,
      -0.024813426658511162,
      -0.025115594267845154,
      0.019173769280314445,
      -0.07015066593885422,
      0.003808906301856041,
      0.027793314307928085,
      0.017644882202148438,
      -0.05640767514705658,
      -0.018941758200526237,
      -0.05856148898601532,
      -0.015183242037892342,
      0.019867194816470146,
      -0.03648361936211586,
      -0.029266351833939552,
      0.020919853821396828,
      0.01750197447836399,
      0.018909571692347527,
      -0.003522643120959401,
      -0.04682426154613495,
      -0.037459079176187515,
      0.004009904805570841,
      0.09575628489255905,
      0.03112734667956829,
      0.007584278006106615,
      0.06271843612194061,
      -0.01907491870224476,
      0.05333959683775902,
      -0.00034559861524030566,
      0.05609728768467903,
      0.019436925649642944,
      0.024732191115617752,
      0.03238532692193985,
      -0.006094890180975199,
      -0.036310434341430664,
      -0.004846461582928896
    ],
    [
      -0.03694876655936241,
      -0.022293632850050926,
      0.039191026240587234,
      0.054421041160821915,
      -0.02509145624935627,
      -0.008011676371097565,
      -0.0304521843791008,
      0.0013634570641443133,
      -0.02046123705804348,
      0.02527325227856636,
      0.0021781004033982754,
      -0.03612065315246582,
      -0.05233389884233475,
      -0.008860177360475063,
      0.026279397308826447,
      -0.023734435439109802,
      0.06707688421010971,
      -0.038501907140016556,
      0.03391839191317558,
      -0.021284108981490135,
      -0.009586991742253304,
      0.004605355206876993,
      -0.02138444595038891,
      -0.03746962174773216,
      0.018414895981550217,
      -0.015328951179981232,
      0.04871948063373566,
      0.01116515789180994,
      -0.021710796281695366,
      -0.10876678675413132,
      0.012866721488535404,
      -0.048427555710077286,
      -0.005225294269621372,
      -0.04035179689526558,
      0.014274757355451584,
      0.006586755625903606,
      -0.029934143647551537,
      -0.04415116459131241,
      0.05443846061825752,
      0.06361886858940125,
      -0.012794059701263905,
      -0.009929253719747066,
      -0.020557500422000885,
      -0.002820839174091816,
      0.0831785574555397,
      0.07956597954034805,
      -0.06665412336587906,
      -0.062472134828567505,
      -0.1162986233830452,
      -0.006897368002682924,
      0.06523341685533524,
      0.0006766762235201895,
      -0.013055470772087574,
      -0.0804586410522461,
      -0.08283504098653793,
      0.04194405674934387,
      -0.04461640119552612,
      0.06439092010259628,
      0.00979190319776535,
      0.023649226874113083,
      -0.005747201386839151,
      -0.039832644164562225,
      -0.010516836307942867,
      0.06223110109567642,
      0.015602405183017254,
      -0.011280892416834831,
      0.03479635342955589,
      0.046877678483724594,
      -0.047485627233982086,
      0.044934432953596115,
      -0.009288785047829151,
      0.03020934946835041,
      -0.06847674399614334,
      -0.03911936283111572,
      0.07248066365718842,
      0.06118042767047882,
      0.04014935716986656,
      0.010966536588966846,
      0.05303278937935829,
      -0.02999715320765972,
      0.02753024362027645,
      0.00012875162065029144,
      0.05623944103717804,
      0.005875850096344948,
      -0.09117123484611511,
      -0.015097209252417088,
      -0.03787097707390785,
      -0.030918072909116745,
      0.06138451397418976,
      0.017844298854470253,
      -0.01353108137845993,
      0.011007877998054028,
      -0.024882620200514793,
      -0.027654897421598434,
      0.04276735335588455,
      -0.046384718269109726,
      -0.06860475987195969,
      -0.07387857139110565,
      -0.021503902971744537,
      -0.05833657830953598,
      0.05348263308405876,
      -0.029011482372879982,
      0.1027650237083435,
      -0.035937704145908356,
      -0.09665241837501526,
      -0.04959439858794212,
      -0.012314863502979279,
      0.08939538151025772,
      -0.09108258038759232,
      0.024928918108344078,
      -0.011988683603703976,
      -0.025470973923802376,
      -0.08067109435796738,
      0.00855168979614973,
      -0.0510944165289402,
      0.051999133080244064,
      0.030389631167054176,
      0.08287735283374786,
      0.09899690002202988,
      0.012151381932199001,
      -0.01632852852344513,
      0.00030549478833563626,
      -0.06889986991882324,
      -0.03734374791383743,
      -0.00809451099485159,
      0.050919536501169205,
      0.029376178979873657,
      -0.027277160435914993,
      0.02474565990269184,
      -0.04325668513774872,
      -0.016051553189754486,
      0.03463579714298248,
      -0.036442629992961884,
      0.058762721717357635,
      0.06873530894517899,
      -0.07047246396541595,
      -0.011558069847524166,
      0.04441219940781593,
      -0.05589472874999046,
      0.11425860226154327,
      -0.054461244493722916,
      0.02612757682800293,
      0.034098781645298004,
      -0.07457958906888962,
      0.007161548361182213,
      0.0014606924960389733,
      0.044180091470479965,
      -0.023493047803640366,
      0.11490422487258911,
      -0.03403210639953613,
      -0.06529617309570312,
      0.08652620017528534,
      0.04261796176433563,
      0.014542547054588795,
      0.01570148766040802,
      0.03445713222026825,
      -0.10849086195230484,
      -0.02981536276638508,
      0.034259360283613205,
      -0.03063848242163658,
      0.007154964841902256,
      -0.016816845163702965,
      0.064869225025177,
      -0.0008750936249271035,
      -0.028587454929947853,
      0.033813171088695526,
      -0.002436994342133403,
      -0.07757733762264252,
      0.04272732138633728,
      0.006626450456678867,
      -0.00157652678899467,
      0.07389627397060394,
      -0.005351558327674866,
      -0.09306690096855164,
      0.06244351342320442,
      -0.06453709304332733,
      -0.03165227547287941,
      -0.06319880485534668,
      -0.03214646875858307,
      0.0073872776702046394,
      0.04899522662162781,
      -0.003138594562187791,
      0.08390046656131744,
      0.051864366978406906,
      0.03075856901705265,
      0.022751864045858383,
      0.05370556190609932,
      -0.016586175188422203,
      -0.07974603772163391,
      -0.03888391703367233,
      0.006500888150185347,
      0.04817081615328789,
      9.666194091551006e-05,
      0.09089448302984238,
      -0.021058471873402596,
      -0.1104222983121872,
      0.03375914320349693,
      0.08653469383716583,
      0.004297660198062658,
      0.024620449170470238,
      -0.03205813094973564,
      -0.050304338335990906,
      -0.01717495732009411,
      -0.04374093934893608,
      -0.012927268631756306,
      0.0076941256411373615,
      -0.04883630946278572,
      0.0468222014605999,
      -0.04410639405250549,
      -0.06947732716798782,
      -0.0015532574616372585,
      0.021849777549505234,
      0.061420563608407974,
      0.030799711123108864,
      0.05955597758293152,
      -0.047613706439733505,
      -0.007518164813518524,
      -0.06032205745577812,
      -0.01661161705851555,
      -0.09298089891672134,
      -0.024516751989722252,
      0.04217420518398285,
      0.023234494030475616,
      -0.005718317348510027,
      0.004339568316936493,
      -0.035555098205804825,
      0.02121494524180889,
      -0.015287954360246658,
      -0.001456028432585299,
      0.013317359611392021,
      0.04232640936970711,
      -0.01834157109260559,
      -0.05410954728722572,
      -0.0015964102931320667,
      -0.0888780802488327,
      -0.005445229355245829,
      -0.017778949812054634,
      0.044083643704652786,
      -0.004571841098368168,
      0.02089248038828373,
      0.0026152213104069233,
      0.023236054927110672,
      -0.006601368542760611,
      0.03148546442389488,
      0.002582400571554899,
      -0.047714486718177795,
      0.07097051292657852,
      -0.020921681076288223,
      0.0706583559513092,
      0.04815785214304924,
      0.06812985241413116,
      0.0421195812523365,
      -0.003653018968179822,
      -0.0035906594712287188,
      -0.08693394809961319,
      0.01252075843513012,
      -0.028820477426052094,
      -0.03500983119010925,
      -0.01039367076009512,
      -0.06378816813230515,
      0.03977596014738083,
      0.040042728185653687,
      -0.014112435281276703,
      -0.05398239940404892,
      -0.05512036755681038,
      -0.017233574762940407,
      0.012242638505995274,
      -0.01669536717236042,
      -0.019698508083820343,
      0.031075144186615944,
      0.053874820470809937,
      0.005878101103007793,
      0.07816710323095322,
      -0.07317986339330673,
      -0.0006830861675553024,
      -0.020573468878865242,
      -0.022003665566444397,
      0.006696414202451706,
      0.06832801550626755,
      -0.0740116760134697,
      0.016649534925818443,
      0.03496686369180679,
      -0.03757922351360321,
      0.08291102200746536,
      -0.025895332917571068,
      0.017906924709677696,
      -0.022221099585294724,
      0.0036320725921541452,
      0.0363440066576004,
      -0.0002995372633449733,
      0.09507095813751221,
      0.015001125633716583,
      -0.017585327848792076,
      0.035363320261240005,
      0.07527840882539749,
      -0.014776056632399559,
      -0.012036171741783619,
      -0.015455765649676323,
      0.019066061824560165,
      -0.006027120165526867,
      -0.0027904906310141087,
      0.05913190543651581,
      -0.12741905450820923,
      -0.03760618343949318,
      -0.01409925613552332,
      0.0009717483189888299,
      -0.011390842497348785,
      0.0045182835310697556,
      0.03273952379822731,
      0.031070079654455185,
      0.10107705742120743,
      0.037424806505441666,
      -0.03324071317911148,
      -0.02450263872742653,
      -0.04683154076337814,
      0.030427366495132446,
      0.008585461415350437,
      0.015943562611937523,
      0.018779847770929337,
      -0.04391413554549217,
      0.004506006371229887,
      0.006129901390522718,
      0.014281129464507103,
      -0.02420140616595745,
      0.03234627842903137,
      -0.05932589992880821,
      -0.03363495320081711,
      -0.046802520751953125,
      0.10232174396514893,
      -0.03313416987657547,
      -0.03559057042002678,
      0.024623269215226173,
      0.05186016485095024,
      0.04630569741129875,
      0.02594340220093727,
      0.004266571253538132,
      -0.06653089821338654,
      -0.02816055528819561,
      -0.026633791625499725,
      0.013309038244187832,
      -0.0259751807898283,
      0.015570458956062794,
      -0.005835964344441891,
      0.06388793885707855,
      0.013258103281259537,
      0.0845337063074112,
      0.0024823297280818224,
      -0.0012036209227517247,
      -0.029107803478837013,
      0.08045656234025955,
      0.022344941273331642,
      -0.023309476673603058,
      0.00567923067137599,
      -0.016269274055957794,
      -0.012773017399013042,
      -0.05000872537493706,
      0.08215115964412689,
      -0.054255757480859756,
      0.06621214002370834,
      -0.044368721544742584,
      -0.01385770458728075,
      0.02659730054438114,
      0.02471497654914856,
      -0.016047436743974686,
      -0.029907245188951492,
      0.00867395754903555,
      0.05899864062666893,
      0.017728568986058235,
      0.04931256175041199,
      0.008032342419028282,
      -0.038318682461977005,
      0.028544973582029343,
      0.0031042201444506645,
      0.025777289643883705,
      0.02330676093697548,
      -0.06891634315252304,
      0.03625497594475746,
      -0.0014782267389819026,
      0.032827697694301605,
      -0.031527042388916016,
      -0.0020144106820225716,
      0.03078778274357319,
      -0.043647266924381256,
      0.06226566061377525,
      -0.018150193616747856,
      0.05115611106157303,
      0.03237047418951988,
      0.04502182826399803,
      0.0107766417786479,
      0.03920836001634598,
      -0.0465228371322155,
      -0.008778726682066917,
      -0.0027318201027810574,
      0.01765396073460579,
      0.030734237283468246,
      -0.05048365890979767,
      0.010813457891345024,
      0.004157441668212414,
      0.07274183630943298,
      -0.036776117980480194,
      0.010275915265083313,
      0.016620319336652756,
      0.02933722734451294,
      -0.09214142709970474,
      0.05179540812969208,
      -0.026374980807304382,
      0.040955446660518646,
      0.05506519228219986,
      0.00910418014973402,
      -0.0063146851025521755,
      -0.018085870891809464,
      -0.02188160829246044,
      -0.01036254595965147,
      -0.0626484677195549,
      -0.031982824206352234,
      0.011281143873929977,
      -0.08522851765155792,
      0.03791472315788269,
      0.01708384044468403,
      0.05116599425673485,
      0.01914006844162941,
      0.01812080107629299,
      -0.0020332320127636194,
      0.024188371375203133,
      -0.042578913271427155,
      -0.0247165709733963,
      0.02339252643287182,
      0.016639461740851402,
      0.01938301883637905,
      0.00021632231073454022,
      -0.07197057455778122,
      0.025071723386645317,
      -0.045811727643013,
      0.00795856025069952,
      -0.08298967778682709,
      0.02885863371193409,
      -0.029332013800740242,
      -0.0025090151466429234,
      -0.027015957981348038,
      0.011187247931957245,
      -0.06401792168617249,
      0.009109840728342533,
      0.054858241230249405,
      -0.013450787402689457,
      -0.054812707006931305,
      0.03632885590195656,
      0.01716013066470623,
      -0.0721675306558609,
      -0.022543609142303467,
      -0.0009564810316078365,
      0.02599291317164898,
      0.04647880047559738,
      -0.0015660629142075777,
      -0.07891242206096649,
      0.06468819081783295,
      0.05532805621623993,
      -0.026836467906832695,
      0.030812237411737442,
      0.04778863862156868,
      0.045398782938718796,
      0.06223728135228157,
      -0.0802818238735199,
      -0.029108231887221336,
      0.004018835257738829,
      -0.024168381467461586,
      0.03315291181206703,
      0.06012924015522003,
      0.0828588604927063,
      0.00405052350834012,
      -0.032832417637109756,
      0.015592778101563454,
      -0.006446090526878834,
      0.04748797044157982,
      0.023966940119862556,
      0.009098317474126816,
      0.015483755618333817,
      0.026950731873512268,
      -0.05195445194840431,
      -0.08288823813199997,
      0.008287757635116577,
      0.03339720144867897,
      0.003032337874174118,
      0.0102146090939641,
      0.06295493990182877,
      -0.020458515733480453,
      -0.056345872581005096,
      0.019444238394498825,
      -0.014476977288722992,
      0.0018990315729752183,
      -0.035897642374038696,
      -0.011449240148067474,
      -0.018496975302696228,
      0.04016917943954468,
      0.05760253593325615,
      -0.04950578883290291,
      -0.00677148625254631,
      -0.006012581288814545,
      0.00146014173515141,
      0.0029989080503582954,
      0.04329809173941612,
      0.028074707835912704,
      0.02765190415084362,
      -0.0011589223286136985
    ],
    [
      0.020454617217183113,
      0.005709804594516754,
      0.01768682152032852,
      0.01775282621383667,
      0.0712522640824318,
      -0.05437232181429863,
      -0.0078048608265817165,
      0.01925010420382023,
      -0.04849260672926903,
      -0.0038441047072410583,
      0.03549423813819885,
      0.011575070209801197,
      0.039397552609443665,
      0.12091726809740067,
      -0.023408224806189537,
      0.024966109544038773,
      0.07164792716503143,
      -0.07044550031423569,
      -0.04762476682662964,
      0.009194061160087585,
      0.0262990090996027,
      0.02651005983352661,
      0.07021436840295792,
      -0.03800734132528305,
      -0.0809236466884613,
      -0.0027148518711328506,
      -0.029131904244422913,
      0.03403959423303604,
      0.0020719587337225676,
      0.01758948713541031,
      -0.05512916296720505,
      -0.062314167618751526,
      0.002702114637941122,
      0.12138815224170685,
      -0.04010327160358429,
      0.02436974085867405,
      0.020707450807094574,
      0.052386943250894547,
      0.060316260904073715,
      0.06326170265674591,
      0.10686469823122025,
      0.05490412190556526,
      -0.05491538345813751,
      0.025510801002383232,
      -0.0015285999979823828,
      -0.0043959105387330055,
      -0.056029822677373886,
      0.03296526148915291,
      -0.05531776323914528,
      0.036581650376319885,
      0.01183548104017973,
      0.010221026837825775,
      0.07307296991348267,
      -0.03708194941282272,
      -0.06349307298660278,
      0.0009272596798837185,
      -0.019406631588935852,
      -0.005986197851598263,
      -0.02389976754784584,
      -0.07007191330194473,
      0.0439058355987072,
      0.027173373848199844,
      0.019787847995758057,
      0.00790773332118988,
      0.046026650816202164,
      -0.04635154455900192,
      -0.04825177043676376,
      0.0583207830786705,
      0.0710979774594307,
      -0.008435225114226341,
      -0.07301726192235947,
      -0.01677556335926056,
      0.02645081654191017,
      0.04645656421780586,
      0.03418104723095894,
      0.024753574281930923,
      -0.03671230375766754,
      -0.04652968421578407,
      0.009829917922616005,
      -0.02121041528880596,
      -0.031118737533688545,
      -0.017048409208655357,
      -0.08124584704637527,
      0.02569737285375595,
      0.02806863933801651,
      -0.009194483049213886,
      -0.10353296995162964,
      -0.017227452248334885,
      -0.04861611872911453,
      0.039546821266412735,
      -0.0013687725877389312,
      0.01984424702823162,
      0.05907268077135086,
      -0.0743999257683754,
      0.05462942644953728,
      0.05299752950668335,
      -0.04117929935455322,
      0.1168578639626503,
      -0.021242329850792885,
      -0.05799539387226105,
      0.0014514648355543613,
      0.008730323985219002,
      0.028768591582775116,
      -0.019772984087467194,
      -0.006230594590306282,
      -0.0036162848118692636,
      -0.0005144162569195032,
      -0.035121601074934006,
      -0.023175876587629318,
      0.11061854660511017,
      -0.03441290557384491,
      0.043027520179748535,
      0.049902401864528656,
      0.03526207059621811,
      0.021339051425457,
      -0.04518963024020195,
      0.018799887970089912,
      -0.01653147116303444,
      -0.09767530858516693,
      -0.009161079302430153,
      -0.003999439068138599,
      -0.04820459708571434,
      0.03275371342897415,
      0.083111971616745,
      -0.015814712271094322,
      -0.00478154793381691,
      -0.0027865045703947544,
      -0.0035326771903783083,
      0.00536223454400897,
      0.03419012203812599,
      -0.023680290207266808,
      0.04674836993217468,
      -0.04703633487224579,
      -0.0021054595708847046,
      0.04927577078342438,
      -0.009122151881456375,
      0.011306915432214737,
      0.0032478817738592625,
      0.08346875011920929,
      0.06761843711137772,
      0.010346189141273499,
      0.05375594645738602,
      -0.006569764576852322,
      0.011027367785573006,
      -0.023455509915947914,
      -0.025940844789147377,
      -0.002983215730637312,
      0.06746260076761246,
      0.0496656633913517,
      -0.05612695962190628,
      0.009121488779783249,
      0.01340531837195158,
      0.07736591249704361,
      -0.04321279376745224,
      0.0018281120574101806,
      0.011627180501818657,
      -0.07253498584032059,
      0.027908451855182648,
      0.0781833678483963,
      0.12448973953723907,
      0.0021850294433534145,
      0.056918442249298096,
      0.04195024445652962,
      -0.05896691977977753,
      -0.011747414246201515,
      -0.028655987232923508,
      -0.01975303143262863,
      -0.0031119028571993113,
      0.006289124023169279,
      -0.09016361087560654,
      0.017028480768203735,
      0.01476380042731762,
      -0.07270248234272003,
      0.0028158901259303093,
      0.042235441505908966,
      -0.049178700894117355,
      0.03659440204501152,
      -0.03583566099405289,
      -0.012411863543093204,
      0.0156320221722126,
      0.045567065477371216,
      -0.05986790359020233,
      0.0038464709650725126,
      -0.009955806657671928,
      -0.07785608619451523,
      0.0161123126745224,
      -0.027627289295196533,
      0.025446878746151924,
      -0.041692450642585754,
      0.020853860303759575,
      -0.018410811200737953,
      -0.04619615525007248,
      0.05466030538082123,
      -0.004751941189169884,
      0.031428005546331406,
      0.021502820774912834,
      0.12132483720779419,
      0.06616737693548203,
      -0.003499508136883378,
      0.06827060133218765,
      0.011403420940041542,
      0.054667841643095016,
      -0.05011773109436035,
      0.06806221604347229,
      0.06517141312360764,
      -0.0034979749470949173,
      0.013251573778688908,
      0.03882918879389763,
      -0.018637346103787422,
      0.04879000782966614,
      0.02121509611606598,
      0.08951915055513382,
      0.042575377970933914,
      -0.015400832518935204,
      0.03457994386553764,
      -0.043658722192049026,
      -0.014271562919020653,
      -0.029221639037132263,
      -0.004520308692008257,
      -0.014146089553833008,
      -0.020530980080366135,
      0.010286176577210426,
      -0.02562333643436432,
      0.05937575921416283,
      0.0333322249352932,
      0.033394135534763336,
      -0.0224101971834898,
      -0.04509131982922554,
      0.008272364735603333,
      -0.004198109731078148,
      -0.044357962906360626,
      0.002261463087052107,
      0.04442363977432251,
      -0.016028964892029762,
      -0.03930705413222313,
      0.04934580251574516,
      0.019084934145212173,
      0.04103907197713852,
      -0.011888010427355766,
      -0.008351501077413559,
      0.04305653274059296,
      0.022699818015098572,
      -0.021442027762532234,
      0.024457624182105064,
      0.028333788737654686,
      0.14011648297309875,
      0.03694090619683266,
      -0.03933529555797577,
      0.09575266391038895,
      0.042312249541282654,
      -0.05583691969513893,
      0.018001064658164978,
      -0.007077684625983238,
      -0.021352019160985947,
      -0.03645047917962074,
      -0.016300274059176445,
      0.00967252254486084,
      -0.041663117706775665,
      -0.01613132655620575,
      -0.044262710958719254,
      0.06722885370254517,
      0.03495173156261444,
      0.0023824323434382677,
      0.008831457234919071,
      0.05117334425449371,
      -0.019804533571004868,
      0.046498000621795654,
      -0.0619557723402977,
      -0.010306238196790218,
      0.025154907256364822,
      0.04261481389403343,
      -0.035918138921260834,
      0.07160673290491104,
      -0.002677174285054207,
      -0.022030815482139587,
      -0.0638943687081337,
      -0.059514500200748444,
      0.03160533681511879,
      -0.03066934458911419,
      -0.0502329058945179,
      -0.06217688322067261,
      0.03378469869494438,
      -0.07109351456165314,
      -0.005871457513421774,
      0.022128036245703697,
      0.019180847331881523,
      0.05422476306557655,
      0.07506979256868362,
      -0.048457399010658264,
      -0.007512561045587063,
      0.039172422140836716,
      0.012165145017206669,
      0.020892322063446045,
      0.07439251989126205,
      0.019484804943203926,
      -0.09581955522298813,
      0.016553891822695732,
      -0.006289876066148281,
      -0.04676021635532379,
      0.0102158198133111,
      -0.132056325674057,
      -0.06799722462892532,
      0.011491267010569572,
      -0.0329553447663784,
      -0.08337543159723282,
      0.000570723379496485,
      -0.03755207359790802,
      -0.01614786498248577,
      0.017589222639799118,
      -0.022187910974025726,
      0.029953455552458763,
      0.022524060681462288,
      -0.020279327407479286,
      0.013995158486068249,
      0.0585455521941185,
      0.039797451347112656,
      -0.019028527662158012,
      0.010774582624435425,
      -0.01852782629430294,
      -0.059443894773721695,
      -0.014370881952345371,
      0.010546620935201645,
      -0.030000491067767143,
      -0.03601568937301636,
      -0.0036231831181794405,
      -0.013226676732301712,
      0.015220655128359795,
      -0.003726463532075286,
      -0.03904227912425995,
      0.06069832295179367,
      -0.09302452206611633,
      0.08641120791435242,
      -0.03297625854611397,
      -0.05416543781757355,
      0.031050631776452065,
      0.02750733494758606,
      -0.027757015079259872,
      0.03676440566778183,
      -0.041611045598983765,
      -0.12228304892778397,
      0.052810147404670715,
      0.0658198744058609,
      0.03388705104589462,
      -0.06855524331331253,
      0.021076513454318047,
      -0.03218572214245796,
      0.0029834392480552197,
      -0.05347556248307228,
      0.05630002170801163,
      0.04807369038462639,
      -0.05347626656293869,
      0.028600947931408882,
      -0.016269804909825325,
      0.07829346507787704,
      0.07115747779607773,
      0.022723481059074402,
      0.028423471376299858,
      -0.007743144873529673,
      0.08493122458457947,
      0.008042828179895878,
      0.016838738694787025,
      0.052376847714185715,
      -0.018253767862915993,
      0.036065492779016495,
      -0.0492192842066288,
      0.016854410991072655,
      -0.019029270857572556,
      0.03678184002637863,
      -0.058334678411483765,
      0.012983892112970352,
      -0.037101082503795624,
      0.019726796075701714,
      0.027884285897016525,
      -0.023521237075328827,
      0.04349740594625473,
      -0.023649316281080246,
      0.056155286729335785,
      0.05418228730559349,
      0.0293270256370306,
      0.06335947662591934,
      -0.05901678651571274,
      0.06697271019220352,
      -0.060819003731012344,
      0.06216852366924286,
      0.03764977306127548,
      -0.025437498465180397,
      0.035963866859674454,
      0.02730792947113514,
      -0.013992455787956715,
      -0.067600779235363,
      0.049041397869586945,
      0.042431529611349106,
      -0.004217053763568401,
      -0.09767397493124008,
      0.012178558856248856,
      0.01936720684170723,
      -0.0729646235704422,
      0.056612882763147354,
      0.016821598634123802,
      0.08161113411188126,
      0.02804853580892086,
      0.0808849185705185,
      0.019796187058091164,
      -0.06204275041818619,
      -0.03589307516813278,
      -0.038796085864305496,
      -0.06295652687549591,
      -0.02371126227080822,
      -0.004252840764820576,
      0.03362611308693886,
      0.01169511303305626,
      -0.049739085137844086,
      0.0003419107524678111,
      0.038535986095666885,
      -0.03174559026956558,
      -0.12619423866271973,
      0.011720754206180573,
      -0.034334857016801834,
      -0.03960283100605011,
      -0.0391743928194046,
      -0.03400086611509323,
      -0.040977586060762405,
      -0.06892867386341095,
      -0.06151200085878372,
      -0.02719910815358162,
      0.04137684032320976,
      -0.04511655494570732,
      -0.020911777392029762,
      -0.0035752763506025076,
      0.0024344269186258316,
      -0.06158467009663582,
      -0.03969450294971466,
      0.036863088607788086,
      -0.006323669571429491,
      0.002501482842490077,
      0.03905222937464714,
      -0.0011830312432721257,
      -0.010305782780051231,
      -0.04033084586262703,
      -0.0314471498131752,
      0.021410660818219185,
      -0.08036922663450241,
      -0.027511565014719963,
      0.09943347424268723,
      -0.02259872481226921,
      0.029807742685079575,
      0.009693408384919167,
      0.004198169801384211,
      0.0312894843518734,
      0.003023662371560931,
      -0.07975298911333084,
      -0.02407723292708397,
      0.0672965869307518,
      -0.044778890907764435,
      0.018671195954084396,
      0.021685218438506126,
      0.026585280895233154,
      -0.049725715070962906,
      0.026247704401612282,
      -0.07858198136091232,
      -0.003985302522778511,
      0.003868881845846772,
      -0.10344216972589493,
      -0.010819125920534134,
      -0.045947957783937454,
      -0.018229184672236443,
      0.021608639508485794,
      0.04668431356549263,
      -0.015883512794971466,
      0.017005477100610733,
      -0.04915767163038254,
      0.022706232964992523,
      0.0267193540930748,
      -0.0006415703683160245,
      0.019441114738583565,
      -0.013010913506150246,
      0.037626963108778,
      -0.06524119526147842,
      0.00822364166378975,
      -0.03703824803233147,
      0.03264331445097923,
      0.034858353435993195,
      0.026613570749759674,
      0.07874896377325058,
      0.07154955714941025,
      0.03198982775211334,
      0.037461671978235245,
      0.05494746193289757,
      -0.03961269184947014,
      -0.041475810110569,
      0.02020660787820816,
      -0.08300391584634781,
      0.037143927067518234,
      -0.0870514065027237,
      0.03208436816930771,
      0.06813487410545349,
      -0.008830311708152294,
      0.10084051638841629,
      -0.0006413533701561391,
      -0.013003779575228691,
      0.08318708091974258,
      0.01805851235985756,
      0.06091010570526123
    ],
    [
      0.025249337777495384,
      -0.019664421677589417,
      0.004334066063165665,
      0.025094514712691307,
      -0.06885126978158951,
      0.031162450090050697,
      -0.05457006022334099,
      0.005558918695896864,
      -0.0672357827425003,
      -0.026289144530892372,
      -0.053101297467947006,
      0.02588476613163948,
      -0.07632215321063995,
      0.030609270557761192,
      0.03109635040163994,
      0.03816120699048042,
      0.058750975877046585,
      0.020016245543956757,
      0.024247942492365837,
      0.009227234870195389,
      0.05884639546275139,
      -0.047867581248283386,
      -0.007787003181874752,
      0.09498947858810425,
      -0.018632791936397552,
      -0.024680865928530693,
      -0.07506439834833145,
      -0.10107149183750153,
      0.039852842688560486,
      -0.03696706146001816,
      -0.0816568061709404,
      -0.023533735424280167,
      -0.052749764174222946,
      0.015537729486823082,
      -0.015379191376268864,
      0.004147338215261698,
      -0.0345793254673481,
      -0.06538345664739609,
      -0.03636722266674042,
      -0.023951523005962372,
      0.036355309188365936,
      -0.005368650890886784,
      -0.07103688269853592,
      -0.05210607871413231,
      -0.06395028531551361,
      -0.05041512846946716,
      -0.057341862469911575,
      0.008835336193442345,
      -0.056397248059511185,
      0.01730191893875599,
      -0.03230900317430496,
      0.035372041165828705,
      -0.055322036147117615,
      0.047646719962358475,
      -0.0027159324381500483,
      -0.01239618007093668,
      -0.011021570302546024,
      -0.01103963702917099,
      -0.029161179438233376,
      0.10249193012714386,
      0.01673511601984501,
      -0.016949838027358055,
      -0.0593842975795269,
      0.053232379257678986,
      -0.040638864040374756,
      0.008315540850162506,
      -0.05999220535159111,
      0.03830888494849205,
      0.05431700125336647,
      0.02968711033463478,
      0.010773721151053905,
      -0.04114172235131264,
      0.005604778882116079,
      -0.062337540090084076,
      -0.009015937335789204,
      0.06149423494935036,
      -0.06494811922311783,
      0.02749899961054325,
      0.02505171112716198,
      -0.0510815754532814,
      -0.05173718184232712,
      -0.008733371272683144,
      -0.0058671897277235985,
      0.0023155908565968275,
      0.007824988104403019,
      0.010354452766478062,
      0.015149686485528946,
      -0.05788203328847885,
      -0.009877484291791916,
      0.026844317093491554,
      -0.003271321300417185,
      0.0399261973798275,
      -0.01901504583656788,
      -0.041585106402635574,
      0.03863867372274399,
      -0.07126858830451965,
      -0.026748323813080788,
      -0.04962974041700363,
      0.03108803741633892,
      -0.027835601940751076,
      0.12512433528900146,
      -0.03593263402581215,
      0.05855153501033783,
      0.06631835550069809,
      0.03174964338541031,
      -0.010067860595881939,
      0.04298161715269089,
      -0.020636050030589104,
      -0.022462956607341766,
      0.011186743155121803,
      0.016816765069961548,
      -0.0005204954650253057,
      0.056089889258146286,
      -0.06426015496253967,
      0.0764036625623703,
      0.00895449984818697,
      0.04850869998335838,
      0.0021385354921221733,
      -0.04296531900763512,
      -0.0615667998790741,
      -0.01975184492766857,
      0.0006462584133259952,
      0.005953154060989618,
      -0.029160302132368088,
      0.04198765754699707,
      0.030153237283229828,
      0.05621356517076492,
      0.014898939058184624,
      0.04466374218463898,
      -0.01970929279923439,
      -0.0011678823502734303,
      -0.006678393576294184,
      -0.006760271731764078,
      -0.023299913853406906,
      0.019147146493196487,
      -0.0007471191347576678,
      0.0073376805521547794,
      0.01906699873507023,
      0.012215444818139076,
      -0.011615622788667679,
      -0.014998787082731724,
      0.014143605716526508,
      0.03247056156396866,
      -0.09398899972438812,
      -0.05042090639472008,
      -0.04861297458410263,
      -0.08899081498384476,
      0.01979007013142109,
      0.06196853891015053,
      0.045617878437042236,
      0.007297317497432232,
      0.01118285208940506,
      0.053940754383802414,
      0.01565299555659294,
      0.07211611419916153,
      -0.01616610400378704,
      -0.028139352798461914,
      -0.09121988713741302,
      0.031691908836364746,
      -0.012219280935823917,
      -0.046729955822229385,
      0.015926145017147064,
      0.06865856796503067,
      -0.04528695344924927,
      -0.0315355509519577,
      -0.02635996602475643,
      -0.03969663754105568,
      -0.05002738907933235,
      0.025811130180954933,
      0.011891832575201988,
      0.06211121380329132,
      0.0025265575386583805,
      0.08501850068569183,
      -0.011932293884456158,
      0.009551052935421467,
      -0.07058919966220856,
      -0.040275514125823975,
      0.008297795429825783,
      -0.02127976156771183,
      -0.04182320460677147,
      -0.05826040357351303,
      -0.02280128002166748,
      0.07307888567447662,
      -0.03249753639101982,
      -0.10404106229543686,
      -0.05090400576591492,
      0.0008109032642096281,
      0.020231781527400017,
      0.020022962242364883,
      0.002317471895366907,
      0.05156997963786125,
      -0.062327392399311066,
      -0.02237769030034542,
      -0.040307383984327316,
      -0.003124438924714923,
      -0.011694664135575294,
      -0.06209637597203255,
      -0.008153170347213745,
      -0.0527760349214077,
      -0.017377370968461037,
      -0.005950025748461485,
      -0.020147204399108887,
      -0.02150063030421734,
      -0.003173435339704156,
      0.06965561956167221,
      0.060349058359861374,
      0.041863732039928436,
      0.016930600628256798,
      0.029516953974962234,
      -0.015578996390104294,
      0.05433417111635208,
      0.021452318876981735,
      0.061059143394231796,
      -0.04947538673877716,
      -0.02365412563085556,
      -0.03635050356388092,
      0.0383714959025383,
      -0.025480078533291817,
      -0.026573972776532173,
      0.036990582942962646,
      -0.004714920185506344,
      -0.0029830001294612885,
      -0.09338448196649551,
      -0.021352175623178482,
      -0.027400804683566093,
      -0.06702985614538193,
      0.057323530316352844,
      0.0750170424580574,
      -0.045237068086862564,
      0.023115886375308037,
      0.03770871087908745,
      -0.0756886824965477,
      -0.06466509401798248,
      -0.031231798231601715,
      0.03087467886507511,
      0.0019232329213991761,
      -0.013973887078464031,
      0.07708333432674408,
      -0.04825534671545029,
      0.047815706580877304,
      -0.0118354931473732,
      -0.002668212167918682,
      0.008654521778225899,
      -0.033022914081811905,
      0.038207150995731354,
      0.06097535789012909,
      -0.023568108677864075,
      -0.029596740379929543,
      0.028009947389364243,
      0.023102503269910812,
      -0.006847637239843607,
      0.052685026079416275,
      0.0024148959200829268,
      -0.0452222004532814,
      0.018816566094756126,
      -0.0627933144569397,
      0.039507556706666946,
      -0.020171312615275383,
      -0.02370281144976616,
      0.004679759033024311,
      0.013235442340373993,
      -0.005724546033889055,
      -0.13575856387615204,
      0.08529072254896164,
      0.008460497483611107,
      -0.01043214462697506,
      -0.06392952799797058,
      0.057953428477048874,
      -0.04795229807496071,
      -0.024451643228530884,
      -0.037743959575891495,
      -0.05469246581196785,
      -0.010230583138763905,
      0.020854445174336433,
      -0.04200711101293564,
      -0.025936080142855644,
      0.0037346684839576483,
      0.03107132762670517,
      -0.06259661167860031,
      0.006232243962585926,
      0.0431649312376976,
      0.023268014192581177,
      -0.06715120375156403,
      0.0008342609507963061,
      0.02004319801926613,
      -0.03646139055490494,
      -0.008830699138343334,
      0.03058689832687378,
      -0.0179416686296463,
      -0.0018269633874297142,
      0.017170844599604607,
      0.014523845165967941,
      0.03294934332370758,
      0.0012674530735239387,
      0.05778023600578308,
      0.03120897151529789,
      -0.039467547088861465,
      -0.036815330386161804,
      -0.03434485197067261,
      -0.0014729375252500176,
      0.0308004729449749,
      -0.005716088693588972,
      -0.0008631098899058998,
      0.001890584477223456,
      -0.0005577320116572082,
      -0.061297327280044556,
      0.0609017051756382,
      -0.011413407512009144,
      -0.025017239153385162,
      -0.049180809408426285,
      0.021627744659781456,
      0.04102795943617821,
      0.011142241768538952,
      -0.008286278694868088,
      0.08918435126543045,
      -0.027703886851668358,
      -0.06572441756725311,
      0.006456866394728422,
      -0.049129512161016464,
      -0.019127706065773964,
      0.008671765215694904,
      -0.030879389494657516,
      0.027694979682564735,
      -0.013358063995838165,
      0.011435228399932384,
      0.05582541599869728,
      -0.026345035061240196,
      0.00798940472304821,
      0.060135502368211746,
      -0.08918952196836472,
      -0.015967676416039467,
      0.050594788044691086,
      0.005881048273295164,
      0.021901560947299004,
      0.029598960652947426,
      0.011217119172215462,
      -0.04778909683227539,
      0.04159700125455856,
      0.015662267804145813,
      -0.00294428039342165,
      0.018951768055558205,
      -0.008725978434085846,
      -0.035435523837804794,
      0.012455902993679047,
      -0.08329890668392181,
      -0.11916550993919373,
      -0.05158993974328041,
      -0.03865766152739525,
      -0.027539487928152084,
      -0.007551670540124178,
      0.02055765502154827,
      -0.038558345288038254,
      0.03935828059911728,
      0.026247916743159294,
      -0.02551344595849514,
      0.03937489166855812,
      0.005138845648616552,
      0.06303970515727997,
      0.08317749202251434,
      -0.06755124032497406,
      -0.0010591309983283281,
      -0.03792957216501236,
      0.03268876671791077,
      0.00600880291312933,
      0.0276991818100214,
      -0.02464015781879425,
      0.02655388042330742,
      0.001845833845436573,
      -0.023332858458161354,
      0.007542591542005539,
      0.0019954971503466368,
      -0.05231567844748497,
      -0.027622507885098457,
      0.07168272137641907,
      0.023816829547286034,
      -0.03445092961192131,
      -0.009438147768378258,
      0.058766745030879974,
      0.02582019753754139,
      0.03990993648767471,
      0.0021155704744160175,
      0.06480086594820023,
      0.09632539004087448,
      0.01769978553056717,
      0.03331486135721207,
      0.028331296518445015,
      -0.060048844665288925,
      -0.005190798547118902,
      -0.06674172729253769,
      0.00561545230448246,
      -0.0016957835759967566,
      0.0454879067838192,
      -0.03694158047437668,
      0.01770290546119213,
      0.046194229274988174,
      -0.04881898686289787,
      -0.08935841917991638,
      0.015729473903775215,
      0.02468961849808693,
      -0.002789772115647793,
      0.10400494933128357,
      0.03337504342198372,
      -0.007794366683810949,
      0.05542609095573425,
      -0.0001832907146308571,
      -0.012228158302605152,
      -0.049740638583898544,
      -0.0012399805709719658,
      0.03281770274043083,
      0.009311680682003498,
      0.1043214499950409,
      0.04228043556213379,
      -0.041000716388225555,
      -0.0025373452808707952,
      0.0030530428048223257,
      0.03603244200348854,
      -0.09643515944480896,
      -0.023552604019641876,
      -0.0467904694378376,
      0.004309915471822023,
      -0.07291305810213089,
      0.04044836759567261,
      0.0023880917578935623,
      -0.037329915910959244,
      0.019003720954060555,
      -0.0077610923908650875,
      -0.005232267547398806,
      -0.018113497644662857,
      0.020066751167178154,
      -0.01694686897099018,
      0.01598075032234192,
      -0.0752742663025856,
      0.012699855491518974,
      0.014843651093542576,
      -0.03642391040921211,
      0.008983492851257324,
      -0.005091868340969086,
      0.01318418513983488,
      0.001647068653255701,
      0.00836624950170517,
      -0.0282006673514843,
      0.042987097054719925,
      0.04395383223891258,
      0.01725737936794758,
      0.00030705396784469485,
      -0.04312074929475784,
      0.035237666219472885,
      0.05173371359705925,
      -0.041055869311094284,
      -0.02338702231645584,
      0.06214049831032753,
      0.010240388102829456,
      -0.04027832671999931,
      0.03090076521039009,
      -0.03753302991390228,
      0.10010907053947449,
      -0.005928737111389637,
      -0.013309985399246216,
      -0.07343868166208267,
      -0.06851773709058762,
      0.07079121470451355,
      -0.03663152828812599,
      0.004444218706339598,
      -0.06750801205635071,
      0.010791041888296604,
      0.0030898633413016796,
      -0.011957337148487568,
      0.04741361737251282,
      -0.027156906202435493,
      0.0009767557494342327,
      -0.03963309898972511,
      0.051645051687955856,
      0.021672219038009644,
      0.03150872141122818,
      -0.004873843863606453,
      -0.02019307389855385,
      0.03181421756744385,
      -0.042899005115032196,
      -0.040831781923770905,
      0.052363842725753784,
      0.005875508300960064,
      -0.003336373483762145,
      0.05957229062914848,
      0.07326190918684006,
      0.016429917886853218,
      -0.035333339124917984,
      0.07892103493213654,
      0.004616976249963045,
      0.06722726672887802,
      0.01785222627222538,
      0.030245810747146606,
      -0.019868575036525726,
      -0.011777685955166817,
      -0.044948168098926544,
      0.014786961488425732,
      -0.09343154728412628,
      0.062010690569877625,
      0.06580327451229095,
      -0.0648198202252388,
      0.05384979397058487,
      -0.05465412884950638,
      -0.024408800527453423,
      0.008075898513197899
    ],
    [
      0.04570901021361351,
      0.008039792999625206,
      0.045834653079509735,
      -0.0006188869592733681,
      0.00398034043610096,
      0.040707189589738846,
      -0.0062478152103722095,
      -0.037396132946014404,
      0.0862007811665535,
      0.011524457484483719,
      -0.00944034568965435,
      0.08183874934911728,
      0.015594052150845528,
      0.0019633304327726364,
      0.025497710332274437,
      -0.04567703232169151,
      0.04956283047795296,
      -0.006034898571670055,
      0.04224349558353424,
      0.021831925958395004,
      -0.026707347482442856,
      -0.016084035858511925,
      -0.09123627096414566,
      0.04592223837971687,
      0.011444075033068657,
      0.07049249112606049,
      0.04347964748740196,
      -0.017315395176410675,
      -0.008766243234276772,
      -0.04011804237961769,
      -0.029232382774353027,
      -0.048660412430763245,
      0.012962383218109608,
      -0.014340436086058617,
      -0.027143480256199837,
      0.005824886728078127,
      -0.09694627672433853,
      -0.04725049063563347,
      0.0580894835293293,
      0.04726850241422653,
      -0.007741027045994997,
      -0.021738102659583092,
      -0.05402258783578873,
      0.04562819004058838,
      -0.01323998998850584,
      -0.0230578426271677,
      -0.08554410934448242,
      0.021495645865797997,
      -0.02203224040567875,
      -0.043185923248529434,
      0.028733156621456146,
      -0.0645277351140976,
      0.010037554427981377,
      -0.008228379301726818,
      -0.016531210392713547,
      0.018577419221401215,
      0.033529918640851974,
      -0.05277276039123535,
      -0.0544201135635376,
      -0.07252401113510132,
      -0.06005381420254707,
      -0.01998908258974552,
      -0.07972292602062225,
      -0.03915184363722801,
      -0.03046405129134655,
      0.03464875370264053,
      0.033802662044763565,
      0.03533029928803444,
      -0.01489473320543766,
      -0.012816879898309708,
      0.06099819019436836,
      -0.01864716410636902,
      -0.02278558351099491,
      -0.09345042705535889,
      -0.03607478737831116,
      0.042159464210271835,
      -0.027623692527413368,
      0.03619757667183876,
      0.0803399607539177,
      0.004572623409330845,
      -0.08251571655273438,
      0.030522268265485764,
      0.003732838435098529,
      0.02405063807964325,
      0.03169994801282883,
      -0.13298344612121582,
      0.025826435536146164,
      0.015073755756020546,
      0.012637091800570488,
      0.039375804364681244,
      0.03759538382291794,
      -0.03818339854478836,
      0.014544691890478134,
      0.023860638961195946,
      -0.048006102442741394,
      -0.0180105809122324,
      0.01196998916566372,
      -0.02311818301677704,
      -0.003761804196983576,
      -0.030739175155758858,
      -0.002203881274908781,
      -0.011474039405584335,
      -0.006334294565021992,
      0.014677825383841991,
      -0.01015901006758213,
      -0.011461067944765091,
      -0.01981162466108799,
      0.029062192887067795,
      -0.020428165793418884,
      -0.05259017273783684,
      -0.06892085075378418,
      0.013479018583893776,
      0.04172486066818237,
      -0.006038612220436335,
      -0.018186425790190697,
      0.018449267372488976,
      0.0361773818731308,
      0.009168949909508228,
      0.030896028503775597,
      0.043000735342502594,
      -0.08390296250581741,
      0.041636671870946884,
      0.11029785871505737,
      0.0255535040050745,
      0.015157116577029228,
      -0.022704167291522026,
      0.006831943057477474,
      -0.03898698464035988,
      0.0900314450263977,
      -0.08388470113277435,
      0.00711006810888648,
      -0.06766548752784729,
      -0.005655220709741116,
      0.015277266502380371,
      -0.034933846443891525,
      0.041970230638980865,
      0.08557513356208801,
      0.05530674755573273,
      -0.047471415251493454,
      -0.03862069547176361,
      0.008427917025983334,
      -0.03650611639022827,
      -0.09396357089281082,
      -0.1041959822177887,
      -0.08425533026456833,
      -0.08105047792196274,
      0.019044235348701477,
      -0.030721787363290787,
      0.05288301408290863,
      -0.02245120331645012,
      0.010667125694453716,
      -0.02808220311999321,
      -0.02835155464708805,
      -0.00665366230532527,
      -0.005868071224540472,
      0.02486034482717514,
      0.05874631181359291,
      -0.004109588451683521,
      -0.0194693673402071,
      0.05030437186360359,
      -0.0547451488673687,
      0.032152291387319565,
      0.006740216631442308,
      -0.04836076498031616,
      0.03765695169568062,
      -0.0005205619963817298,
      0.0008558509289287031,
      -0.00286747794598341,
      -0.038053710013628006,
      0.05196865275502205,
      0.007025304716080427,
      0.030610904097557068,
      0.04238991439342499,
      -0.012576094828546047,
      -0.0042716749012470245,
      -0.003646444994956255,
      0.03426727280020714,
      0.042822133749723434,
      -0.0421154610812664,
      -0.008898015134036541,
      -0.013020634651184082,
      -0.07335954159498215,
      0.04379211738705635,
      0.043944913893938065,
      -0.0470910407602787,
      0.027421696111559868,
      -0.07461860030889511,
      0.0368383564054966,
      -0.04805624112486839,
      -0.028501320630311966,
      -0.038823675364255905,
      -0.03308733180165291,
      -0.007396467495709658,
      0.02694588713347912,
      -0.02903343178331852,
      0.002347887260839343,
      0.017827637493610382,
      0.02514590509235859,
      -0.014991243369877338,
      0.0682719275355339,
      -0.0001847467792686075,
      0.00015212206926662475,
      0.046610984951257706,
      -0.020919427275657654,
      -0.028731968253850937,
      0.03385956212878227,
      -0.04266487434506416,
      -0.06326601654291153,
      -0.08434820920228958,
      -0.052568111568689346,
      0.08329210430383682,
      0.0477791465818882,
      0.06714658439159393,
      0.04562349244952202,
      -0.09722066670656204,
      0.05683512985706329,
      0.023093638941645622,
      -0.051455289125442505,
      -0.0724560022354126,
      0.046577341854572296,
      -0.05284321680665016,
      -0.046487368643283844,
      -0.019314322620630264,
      -0.01932116225361824,
      -0.05492198094725609,
      -0.00846251193434,
      -0.011785666458308697,
      -0.05244758725166321,
      0.04209553450345993,
      -0.0022689481265842915,
      0.07134872674942017,
      -0.003902239492163062,
      0.03527524694800377,
      0.07132211327552795,
      -0.06080683320760727,
      0.024021869525313377,
      0.017584558576345444,
      0.01314858254045248,
      0.0072420863434672356,
      0.022358521819114685,
      -0.09144267439842224,
      -0.047567371279001236,
      0.07583888620138168,
      0.03353532776236534,
      -0.021278055384755135,
      0.024708440527319908,
      0.04504295065999031,
      0.05812303349375725,
      0.010838396847248077,
      -0.03503589704632759,
      0.0030090520158410072,
      -0.018325312063097954,
      -0.024937473237514496,
      -0.004427306819707155,
      -0.03723851218819618,
      -0.003776115132495761,
      -0.026494653895497322,
      0.032425105571746826,
      0.01322120986878872,
      -0.0415620394051075,
      -0.012490671128034592,
      -0.04556478187441826,
      0.029397686943411827,
      -0.13529720902442932,
      -0.04210079833865166,
      0.053517356514930725,
      0.1244066134095192,
      -0.039878539741039276,
      0.028857167810201645,
      0.029145626351237297,
      -0.012098980136215687,
      -0.012793704867362976,
      -0.011113094165921211,
      -0.040450822561979294,
      -0.0695757046341896,
      0.010520335286855698,
      -0.008946968242526054,
      0.006249119061976671,
      0.0027575918938964605,
      -0.06640815734863281,
      0.06858058273792267,
      0.09196135401725769,
      -0.027495594695210457,
      -0.006532528903335333,
      -0.07017841935157776,
      -0.04582897201180458,
      -0.05613023415207863,
      0.028085557743906975,
      0.001500267069786787,
      -0.04758014157414436,
      -0.013062382116913795,
      0.1064862459897995,
      0.00017306601512245834,
      0.04953909292817116,
      -0.023824401199817657,
      -0.02644617296755314,
      -0.0529533326625824,
      -0.01605439931154251,
      0.019894123077392578,
      -0.05380130931735039,
      0.053361400961875916,
      -0.006303871050477028,
      0.054780784994363785,
      0.003822988597676158,
      0.058482151478528976,
      -0.02721952460706234,
      -0.051428984850645065,
      -0.045676376670598984,
      0.005051612388342619,
      -0.05772298946976662,
      -0.030001431703567505,
      0.060657232999801636,
      -0.03390661999583244,
      0.00017113733338192105,
      -0.03130136430263519,
      -0.009945842437446117,
      0.046328429132699966,
      -0.03316902741789818,
      0.09914018958806992,
      -0.05160253867506981,
      0.006936283782124519,
      0.002413940615952015,
      -0.014868869446218014,
      -0.0009857633849605918,
      -0.07210292667150497,
      0.06364825367927551,
      -0.035083819180727005,
      -0.032993610948324203,
      0.02048708125948906,
      -0.06745307147502899,
      -0.00823956448584795,
      -0.009259643964469433,
      0.01198701560497284,
      0.07037610560655594,
      -0.008457476273179054,
      0.021087922155857086,
      -0.041347336024045944,
      -0.07004547864198685,
      0.04513281211256981,
      -0.03078308328986168,
      0.06915377825498581,
      0.09041907638311386,
      0.014092649333178997,
      -0.011386572383344173,
      0.02235862798988819,
      0.04197002947330475,
      0.02632286213338375,
      -0.0385335311293602,
      -0.04106856882572174,
      -0.04649122804403305,
      -0.04847382754087448,
      -0.008394346572458744,
      -0.009342354722321033,
      -0.01630830205976963,
      0.012668362818658352,
      -0.008163142949342728,
      0.08720394223928452,
      0.07038503140211105,
      -0.013082011602818966,
      -0.09097804129123688,
      -0.008567710407078266,
      -0.03590032830834389,
      0.03353796899318695,
      0.014405784197151661,
      0.07836274802684784,
      0.02094109356403351,
      0.04689950495958328,
      0.007623034529387951,
      -0.020801877602934837,
      0.03393958881497383,
      -0.05156189203262329,
      0.03967418894171715,
      0.0027692546136677265,
      0.07801643013954163,
      -0.01286187581717968,
      -0.01835392788052559,
      0.03488213196396828,
      -0.0965290516614914,
      -0.061443354934453964,
      -0.007798053789883852,
      0.028833508491516113,
      -0.024532325565814972,
      -0.03706186264753342,
      0.053227078169584274,
      0.03748248144984245,
      -0.03254297375679016,
      0.08561386168003082,
      0.08018381148576736,
      -0.018339496105909348,
      0.01988382264971733,
      -0.03108205832540989,
      0.06348887830972672,
      -0.009463180787861347,
      0.027777839452028275,
      0.03943221643567085,
      -0.06574448943138123,
      0.04929029569029808,
      0.010862099006772041,
      -0.07657235860824585,
      0.04637739062309265,
      0.059078678488731384,
      -0.005440593231469393,
      -0.006411635782569647,
      0.019676389172673225,
      0.0034033460542559624,
      -0.004439136944711208,
      0.06939423829317093,
      0.017575040459632874,
      0.024663666263222694,
      0.03408268839120865,
      0.056515831500291824,
      -0.005528304260224104,
      0.006152989808470011,
      0.02221664786338806,
      -0.02953547053039074,
      0.006475306116044521,
      0.01697535440325737,
      0.006454543210566044,
      -0.00950183067470789,
      0.06796105951070786,
      -0.06503698974847794,
      -0.03605654463171959,
      0.0019522042712196708,
      -0.020160600543022156,
      -0.012641736306250095,
      0.002249892568215728,
      0.034681469202041626,
      -0.04880831018090248,
      -0.03820691630244255,
      -0.00570016959682107,
      -0.02889959327876568,
      -0.10317016392946243,
      -0.03232034668326378,
      0.017038054764270782,
      -0.0018943927716463804,
      0.038287453353405,
      0.09178739786148071,
      -0.03547154366970062,
      -0.012014513835310936,
      0.0693897157907486,
      0.03481375053524971,
      0.023546580225229263,
      -0.06159338727593422,
      0.01260343100875616,
      -0.0015361713012680411,
      -0.0459916777908802,
      -0.043087929487228394,
      -0.016241280362010002,
      0.012195601128041744,
      -0.0015670995926484466,
      -0.07413076609373093,
      0.016038669273257256,
      0.009695762768387794,
      -0.010160368867218494,
      -0.03901383653283119,
      0.04209653660655022,
      -0.04362878203392029,
      -0.06387336552143097,
      -0.030226508155465126,
      -0.005745545960962772,
      0.07536162436008453,
      0.055805884301662445,
      -0.044020310044288635,
      0.026161707937717438,
      -0.0291176475584507,
      -0.058496735990047455,
      -0.014281241223216057,
      -0.025362037122249603,
      0.007489747833460569,
      -0.00922924093902111,
      -0.039697449654340744,
      0.0061319563537836075,
      0.05821537971496582,
      0.045420385897159576,
      -0.025433599948883057,
      0.08323989063501358,
      -0.008622856810688972,
      -0.01965285651385784,
      0.0860876515507698,
      0.03986659273505211,
      -0.006174164824187756,
      0.003788705449551344,
      0.0014616426778957248,
      0.004183306358754635,
      -0.006625410635024309,
      -0.0009077208815142512,
      -0.06929802894592285,
      -0.012288774363696575,
      0.023967521265149117,
      0.008902394212782383,
      0.0850740447640419,
      0.047886185348033905,
      -0.02143668197095394,
      0.05613987520337105,
      -0.01023570355027914,
      0.0400947630405426,
      0.006581967230886221,
      -0.10634060949087143,
      0.011788003146648407,
      -0.034641724079847336,
      -0.04475586116313934,
      0.03405790030956268,
      0.04879988729953766
    ],
    [
      0.04182085767388344,
      -0.010041077621281147,
      0.0330614298582077,
      0.015883905813097954,
      -0.021250413730740547,
      -0.02854732982814312,
      -0.02861412614583969,
      -0.0004927729023620486,
      0.07191312313079834,
      0.024435915052890778,
      -0.040685780346393585,
      -0.022645901888608932,
      0.0012410704512149096,
      0.004806327633559704,
      -0.058525629341602325,
      -0.07269780337810516,
      0.009179726243019104,
      -0.11420340836048126,
      0.06369774788618088,
      -0.04523560404777527,
      -0.06866605579853058,
      0.07005786895751953,
      0.002822413807734847,
      0.01693427748978138,
      0.008978917263448238,
      -0.008143192157149315,
      -0.06344106793403625,
      0.006399083882570267,
      0.044693395495414734,
      -0.07149578630924225,
      0.0013847679365426302,
      -0.019532697275280952,
      0.03528124839067459,
      0.01738157868385315,
      0.023518959060311317,
      -0.01215446274727583,
      -0.07891031354665756,
      -0.11592040956020355,
      0.05193343758583069,
      -0.008278065361082554,
      0.011844664812088013,
      0.02995431050658226,
      -0.1309957504272461,
      -0.0016897741006687284,
      -0.036635227501392365,
      0.012651761062443256,
      -0.010008220560848713,
      0.009981301613152027,
      -0.01276835985481739,
      -0.035772088915109634,
      -0.03887314721941948,
      -0.00994501356035471,
      0.0006484476034529507,
      -0.0669480562210083,
      -0.0216085035353899,
      -0.046112142503261566,
      0.028970198705792427,
      0.07452497631311417,
      -0.07381389290094376,
      0.06086912378668785,
      0.001219554920680821,
      -0.04294249042868614,
      0.009757551364600658,
      -0.03428614139556885,
      0.012153956107795238,
      -0.0011585139436647296,
      -0.046858515590429306,
      -0.04476846382021904,
      -0.044146791100502014,
      -0.06850773841142654,
      -0.00967082567512989,
      -0.04312945529818535,
      -0.0318005308508873,
      -0.04817242920398712,
      0.01784452795982361,
      -0.04564940556883812,
      0.0790184885263443,
      0.06935417652130127,
      0.004821102600544691,
      0.013158515095710754,
      -0.0006751829641871154,
      0.01845460571348667,
      -0.02363843098282814,
      0.013449945487082005,
      0.0028075240552425385,
      -0.03373219072818756,
      0.017210515215992928,
      -0.053684476763010025,
      -0.027177779003977776,
      0.0010811514221131802,
      0.005239750258624554,
      0.031501904129981995,
      -0.015037092380225658,
      0.07951980829238892,
      0.006101537961512804,
      0.002008127747103572,
      -0.015250490978360176,
      -0.004453015513718128,
      -0.0487687848508358,
      0.014930018223822117,
      -0.04368511214852333,
      0.009465229697525501,
      0.02250033989548683,
      -0.004163972567766905,
      0.006287736352533102,
      -0.021213294938206673,
      -0.039889346808195114,
      -0.01929786056280136,
      0.06757378578186035,
      -0.045880261808633804,
      0.02114742249250412,
      -0.043542683124542236,
      0.021307876333594322,
      -0.003382293740287423,
      0.007394721265882254,
      0.00920825731009245,
      -0.02439931035041809,
      -0.03740282729268074,
      -0.03037952072918415,
      -0.00016014056745916605,
      -0.018031558021903038,
      -0.09592705965042114,
      0.026720164343714714,
      0.01839698664844036,
      -0.017387881875038147,
      -0.022155972197651863,
      -0.042110078036785126,
      -0.02796165645122528,
      0.0820937231183052,
      -0.061785195022821426,
      -0.05971744284033775,
      -0.02470400370657444,
      0.04282979294657707,
      0.03331415727734566,
      0.010555733926594257,
      0.01172464620321989,
      -0.045353200286626816,
      -0.012915069237351418,
      0.025152141228318214,
      0.021782025694847107,
      0.05898416414856911,
      0.028626354411244392,
      0.00032615038799121976,
      -0.02951228804886341,
      -0.02416374906897545,
      -0.012801794335246086,
      -0.05240713432431221,
      -0.008698154240846634,
      -0.005174119491130114,
      -0.030622290447354317,
      -0.020375357940793037,
      -0.09206980466842651,
      0.10463690757751465,
      -0.013629304245114326,
      -0.0419783815741539,
      -0.04295920580625534,
      -0.0011586974142119288,
      -0.0167124904692173,
      0.0301249697804451,
      -0.01025321613997221,
      -0.0028747948817908764,
      0.007555922027677298,
      -0.012257211841642857,
      0.05085675045847893,
      0.019463006407022476,
      0.0632239580154419,
      0.0389874167740345,
      0.07709360867738724,
      0.02666313201189041,
      0.0038182339631021023,
      0.014140385203063488,
      -5.169329233467579e-05,
      -0.03124048188328743,
      0.0033137272112071514,
      -0.02154023014008999,
      -0.025642797350883484,
      -0.058883167803287506,
      0.035250499844551086,
      -0.07699801027774811,
      0.0441000759601593,
      -0.051486458629369736,
      -0.036238495260477066,
      -0.03872308507561684,
      0.015105404891073704,
      0.13756497204303741,
      -0.03951907157897949,
      -0.04867200180888176,
      0.0031377729028463364,
      0.02793749049305916,
      0.040152180939912796,
      -0.0674622505903244,
      0.06860002130270004,
      0.025126487016677856,
      -0.009260541759431362,
      -0.036375194787979126,
      0.016725771129131317,
      0.02908494882285595,
      0.028373735025525093,
      0.060833889991045,
      0.01999076083302498,
      0.010282929055392742,
      -0.014727387577295303,
      -0.030137227848172188,
      0.004669140093028545,
      0.06542441993951797,
      0.023101260885596275,
      0.0679628849029541,
      0.016763001680374146,
      -0.055215828120708466,
      -0.0013377695577219129,
      -0.037101536989212036,
      0.016516171395778656,
      0.018627792596817017,
      0.02147793024778366,
      -0.034996602684259415,
      0.024439474567770958,
      0.0008847760618664324,
      -0.014998851343989372,
      -0.06237729638814926,
      -0.01949520967900753,
      -0.05493859201669693,
      0.058429136872291565,
      -0.024975961074233055,
      0.020852496847510338,
      -0.017471931874752045,
      0.00292933639138937,
      0.08080088347196579,
      -0.0036623040214180946,
      -0.03756743669509888,
      -0.02930026315152645,
      -0.03966550529003143,
      -0.05454089120030403,
      -0.0731874629855156,
      -0.04272661730647087,
      -0.07998847216367722,
      0.052145496010780334,
      -0.06711868941783905,
      -0.08373665809631348,
      -0.1413121223449707,
      0.04713248834013939,
      -0.01864279992878437,
      0.0021373683121055365,
      0.013618063181638718,
      -0.002356453100219369,
      0.04569030925631523,
      -0.015217998996376991,
      0.02597571164369583,
      -0.03434635326266289,
      0.0007004071958363056,
      -0.00941398087888956,
      -0.0522516667842865,
      -0.01388653926551342,
      -0.024856291711330414,
      -0.03996626287698746,
      -0.0581841841340065,
      0.026688534766435623,
      -0.042950116097927094,
      -0.001126099843531847,
      -0.058960169553756714,
      -0.04115098714828491,
      0.04285114258527756,
      -0.0015354737406596541,
      -0.04596337303519249,
      -0.07596680521965027,
      0.016522672027349472,
      0.0012875627726316452,
      0.04966304823756218,
      -0.006869166158139706,
      0.025765541940927505,
      -0.004921631421893835,
      -0.0004550670273602009,
      -0.046359263360500336,
      0.000507907650899142,
      -0.0019709032494574785,
      -0.03878604248166084,
      -0.016281910240650177,
      -0.02482800930738449,
      0.07291918992996216,
      -0.0063700382597744465,
      -0.007997753098607063,
      -0.010409335605800152,
      0.02105797827243805,
      0.03220835328102112,
      0.05101308971643448,
      0.0035626653116196394,
      -0.026475513353943825,
      0.030256304889917374,
      0.03203386068344116,
      -0.06755217164754868,
      -0.03679496794939041,
      0.01995665766298771,
      0.08589178323745728,
      -0.05657753720879555,
      -0.010263585485517979,
      -0.032707858830690384,
      -0.03957799822092056,
      0.054006412625312805,
      -0.04642803594470024,
      -0.0022541668731719255,
      -0.04806079715490341,
      0.06099320948123932,
      0.06909777969121933,
      0.04552457109093666,
      -0.03171655908226967,
      0.013856674544513226,
      0.07609894871711731,
      0.09988389909267426,
      -0.010656431317329407,
      0.05018443614244461,
      0.04874740168452263,
      0.009766354225575924,
      0.09094361215829849,
      0.03306160122156143,
      0.05282074213027954,
      -0.11073287576436996,
      -0.044585227966308594,
      0.048071328550577164,
      -0.009911568835377693,
      -0.010376124642789364,
      0.035807739943265915,
      0.009772159159183502,
      -0.000838554697111249,
      0.00023829788551665843,
      0.03394227847456932,
      0.09085236489772797,
      0.07816850394010544,
      -0.0008559483685530722,
      -0.07857367396354675,
      0.09669794887304306,
      -0.011628535576164722,
      -0.02611958421766758,
      0.04554805904626846,
      -0.0074312444776296616,
      -0.0408986434340477,
      0.003466707421466708,
      -0.01833900436758995,
      0.046905238181352615,
      0.031846851110458374,
      -0.0033503833692520857,
      0.016266148537397385,
      0.0263475701212883,
      -0.05168141797184944,
      -0.010629270225763321,
      -0.051277514547109604,
      0.0038493936881422997,
      -0.0013169912854209542,
      -0.08191420882940292,
      0.048521097749471664,
      -0.048555079847574234,
      0.004250392317771912,
      -0.007627382874488831,
      0.037381455302238464,
      0.04405941814184189,
      0.05848407745361328,
      -0.00806456245481968,
      0.007466793991625309,
      0.0496193990111351,
      -0.017800599336624146,
      0.013549847528338432,
      0.02964320220053196,
      -0.004383370280265808,
      -0.005656968802213669,
      0.03012119233608246,
      -0.005115087144076824,
      0.0326375886797905,
      -0.028580710291862488,
      -0.01478282269090414,
      0.022484762594103813,
      0.009175657294690609,
      0.04171418771147728,
      0.04474073275923729,
      0.04198853299021721,
      -0.03450770303606987,
      -0.027081944048404694,
      -0.022036483511328697,
      -0.014449440874159336,
      0.011712088249623775,
      -0.09313840419054031,
      -0.024909572675824165,
      0.04386267438530922,
      -0.05205605551600456,
      0.04005032777786255,
      0.015779325738549232,
      0.028280386701226234,
      0.0073195262812078,
      -0.03179363161325455,
      0.0012141409097239375,
      -0.020345665514469147,
      -0.00304348929785192,
      0.06810735911130905,
      -0.015412327833473682,
      0.03676105663180351,
      -0.030442073941230774,
      0.004700819496065378,
      -0.023816140368580818,
      0.028514890000224113,
      0.004851180128753185,
      0.007666610646992922,
      -0.015620632097125053,
      0.0153679633513093,
      0.05358780920505524,
      -0.04438697546720505,
      -0.01980484463274479,
      -0.06196726858615875,
      0.025034604594111443,
      -0.035755522549152374,
      -0.010648535564541817,
      -0.007386781740933657,
      -0.04245643690228462,
      0.035961687564849854,
      0.048973411321640015,
      -0.03468990698456764,
      -0.002245632465928793,
      0.014361840672791004,
      -0.015343117527663708,
      -0.009205575101077557,
      -0.013160948641598225,
      0.0562642402946949,
      -0.000271507102297619,
      0.02523823082447052,
      -0.07112637162208557,
      -0.044753946363925934,
      -0.03852001205086708,
      0.0021305400878190994,
      0.06067932769656181,
      -0.03695869445800781,
      -0.014897644519805908,
      -0.04885895922780037,
      -0.030049817636609077,
      -0.07412663102149963,
      0.03174927830696106,
      0.023595867678523064,
      0.007232906762510538,
      0.06668180972337723,
      -0.0839257538318634,
      -0.029031652957201004,
      0.027720477432012558,
      0.01673515886068344,
      -0.0681755393743515,
      0.027306508272886276,
      0.013743333518505096,
      0.0522359162569046,
      0.09410654753446579,
      0.011195496656000614,
      0.0425146147608757,
      -0.029985301196575165,
      0.041106630116701126,
      -0.03003779985010624,
      -0.025302793830633163,
      0.024953974410891533,
      0.051556870341300964,
      -0.034099072217941284,
      0.03860248997807503,
      -0.04417136684060097,
      0.06171565130352974,
      0.018221106380224228,
      0.004628332797437906,
      0.03457751125097275,
      0.053979165852069855,
      -0.10364329069852829,
      -0.0488639697432518,
      -0.08775290101766586,
      0.10584017634391785,
      -0.00209823134355247,
      -0.0391235277056694,
      -0.0019614940974861383,
      0.016320737078785896,
      -0.1089305505156517,
      0.05151282995939255,
      0.055918850004673004,
      0.016844945028424263,
      -0.016254683956503868,
      0.02105320803821087,
      0.0123415132984519,
      0.050234563648700714,
      0.035690657794475555,
      0.08272960036993027,
      0.05696834996342659,
      -0.0075859082862734795,
      -0.010914918035268784,
      -0.0862002968788147,
      -0.04734845086932182,
      -0.011251071467995644,
      -0.05739610269665718,
      0.0029825957026332617,
      0.024269772693514824,
      -0.1314459592103958,
      -0.014838915318250656,
      -0.05417560413479805,
      0.029441900551319122,
      0.025958489626646042,
      0.013591356575489044,
      -0.009721015579998493,
      0.08947930485010147,
      -0.02505401149392128,
      0.0436699204146862,
      -0.033017564564943314,
      0.009439516812562943,
      -0.03864740952849388,
      0.08227617293596268,
      -0.007248144131153822,
      0.014493201859295368,
      -0.0285700261592865
    ],
    [
      0.01900547184050083,
      -0.07087348401546478,
      0.04614662751555443,
      0.12463323026895523,
      -0.020658953115344048,
      -0.006500325631350279,
      -0.06602679938077927,
      -0.09335355460643768,
      0.03057192824780941,
      0.009444261901080608,
      0.03528301790356636,
      -0.043189194053411484,
      -0.05079860985279083,
      -0.06321392208337784,
      0.006937034428119659,
      -0.021159576252102852,
      -0.022364966571331024,
      -0.024411523714661598,
      0.00816583912819624,
      0.001383432885631919,
      -0.00036130830994807184,
      -0.03633193299174309,
      -0.019628101959824562,
      -0.04260445386171341,
      0.03562900424003601,
      -0.03737350553274155,
      -0.0027747706044465303,
      -0.04135044664144516,
      -0.03149169683456421,
      0.0029969189781695604,
      -0.0016064441297203302,
      -0.05108366906642914,
      -0.022379659116268158,
      0.018117861822247505,
      -0.028649616986513138,
      0.08292699605226517,
      -0.05941040813922882,
      -0.1263531893491745,
      0.13404640555381775,
      0.02767663449048996,
      -0.016529133543372154,
      0.02464621141552925,
      -0.036043550819158554,
      0.014878997579216957,
      -0.051499996334314346,
      -0.0247753094881773,
      -0.06812574714422226,
      -0.047660693526268005,
      0.03635543957352638,
      -0.09018436074256897,
      -0.13906358182430267,
      0.14177775382995605,
      -0.05731930211186409,
      -0.0014849478611722589,
      -0.04183690994977951,
      -0.011855483055114746,
      -0.015486336313188076,
      0.007390253711491823,
      0.011385001242160797,
      0.01619224064052105,
      -0.007610473316162825,
      0.03646858036518097,
      0.005546300206333399,
      -0.04999255761504173,
      0.06561886519193649,
      -0.01879841275513172,
      -0.0022437917068600655,
      -0.07211846113204956,
      -0.10054764151573181,
      0.002773917280137539,
      -0.012095066718757153,
      -0.07571092993021011,
      0.04281777888536453,
      -0.003982104826718569,
      -0.03833375871181488,
      -0.03410649672150612,
      -0.011213807389140129,
      -0.005501285195350647,
      0.0430736280977726,
      -0.017031153663992882,
      -0.026421038433909416,
      0.031353142112493515,
      -0.05384483560919762,
      0.013000879436731339,
      -0.01508618239313364,
      0.0802135244011879,
      -0.06619525700807571,
      -0.08730114251375198,
      -0.06899666041135788,
      0.021744633093476295,
      0.0019136193441227078,
      0.016354555264115334,
      -0.048386815935373306,
      -0.058329988270998,
      0.008327345363795757,
      0.02520013228058815,
      0.024932395666837692,
      -0.05473622307181358,
      0.013768565841019154,
      0.07098698616027832,
      -0.012137959711253643,
      -0.0354207381606102,
      0.01825188845396042,
      0.008844597265124321,
      -0.06672679632902145,
      -0.03176984190940857,
      -0.007710495963692665,
      -0.033445898443460464,
      0.045786432921886444,
      -0.07548067718744278,
      -0.05911776423454285,
      -0.010241061449050903,
      -0.05293409526348114,
      0.06549055129289627,
      -0.06584425270557404,
      -0.01855466514825821,
      0.05130079761147499,
      -0.008819862268865108,
      0.007130511570721865,
      -0.03181958198547363,
      -0.01688029244542122,
      -0.039622556418180466,
      -0.09527407586574554,
      0.03737255558371544,
      -0.034966155886650085,
      0.014783746562898159,
      0.02449367567896843,
      -0.057738155126571655,
      -0.05954967439174652,
      -0.020278388634324074,
      -0.011873763054609299,
      0.08932927250862122,
      -0.051072943955659866,
      -0.024654442444443703,
      0.0342094823718071,
      0.047167133539915085,
      -0.042642880231142044,
      -0.01858900673687458,
      -0.04828015714883804,
      -0.04754010587930679,
      -0.040272247046232224,
      0.04322042688727379,
      -0.005494439974427223,
      0.038531672209501266,
      -0.03997774422168732,
      0.015765443444252014,
      -0.03164573386311531,
      0.04763905331492424,
      -0.023735148832201958,
      -0.018670890480279922,
      -0.027518073096871376,
      -0.038773324340581894,
      -0.03386929631233215,
      -0.05797096714377403,
      0.018425339832901955,
      -0.08078762888908386,
      -0.015066998079419136,
      0.04129016026854515,
      0.03984721004962921,
      -0.08930598199367523,
      -0.08767016977071762,
      -0.004657165613025427,
      0.017423881217837334,
      0.011657101102173328,
      0.002951746340841055,
      0.006284636911004782,
      -0.012775714509189129,
      -0.02432781085371971,
      -0.0001482070074416697,
      -0.020118646323680878,
      0.03571340814232826,
      0.03994511067867279,
      0.008299699984490871,
      0.04888244345784187,
      0.021101268008351326,
      0.021208664402365685,
      0.00023640356084797531,
      0.037909768521785736,
      0.07744582742452621,
      0.05254095420241356,
      0.004334871657192707,
      0.0690232440829277,
      -0.025210443884134293,
      0.045564454048871994,
      -0.07381913810968399,
      0.015023591928184032,
      0.014715285040438175,
      -0.07215627282857895,
      -0.03436272218823433,
      -0.06345289945602417,
      0.012703233398497105,
      0.004283173941075802,
      -0.03655341640114784,
      0.043320462107658386,
      0.026723502203822136,
      -0.07181809097528458,
      -0.0326254665851593,
      0.05045245215296745,
      0.040496114641427994,
      0.09906177222728729,
      -0.044791676104068756,
      -0.09568873047828674,
      -0.030309349298477173,
      0.011943241581320763,
      -0.046247731894254684,
      -0.030777309089899063,
      0.039536140859127045,
      -0.05783064290881157,
      -0.07716485857963562,
      -0.050831858068704605,
      0.04003961756825447,
      0.015479959547519684,
      -0.026074308902025223,
      -0.05440817400813103,
      -0.052430860698223114,
      -0.03219868615269661,
      -0.031829625368118286,
      -0.01615813933312893,
      -0.056506019085645676,
      0.012279084883630276,
      -0.01709282211959362,
      0.026715410873293877,
      0.00960873905569315,
      0.049600277096033096,
      -0.04509715363383293,
      0.09016410261392593,
      0.04568329080939293,
      -0.029601415619254112,
      -0.008809136226773262,
      0.0761069729924202,
      0.037247613072395325,
      -4.254599480191246e-05,
      -0.0024218116886913776,
      0.017524173483252525,
      -0.0027730355504900217,
      -0.027000635862350464,
      0.04009218513965607,
      -0.014142630621790886,
      -0.00430042902007699,
      -0.0064987074583768845,
      0.04201122373342514,
      0.065486378967762,
      -0.02371971309185028,
      0.021375978365540504,
      -0.08914253860712051,
      0.009982391260564327,
      -0.03202507644891739,
      -0.023132072761654854,
      0.0033961585722863674,
      -0.052018120884895325,
      -0.0005003349506296217,
      0.04539613798260689,
      -0.10508449375629425,
      0.04153982549905777,
      0.08568298816680908,
      0.0327821709215641,
      0.048655830323696136,
      0.03027147799730301,
      -0.09212210774421692,
      -0.032926108688116074,
      0.0059590027667582035,
      -0.010392197407782078,
      -0.03579322248697281,
      -0.052992597222328186,
      0.02656835876405239,
      -0.011098300106823444,
      -0.0304302666336298,
      0.0015919591533020139,
      0.004195925313979387,
      -0.01626415178179741,
      -0.03910757228732109,
      0.02949932962656021,
      -0.05688708275556564,
      -0.053855810314416885,
      -0.009427458979189396,
      0.041668154299259186,
      0.012688562273979187,
      -0.06259948760271072,
      0.08802270889282227,
      0.046905793249607086,
      0.00496983528137207,
      0.02745615504682064,
      -0.06801877915859222,
      0.04502290114760399,
      0.024913297966122627,
      -0.08066075295209885,
      0.028626175597310066,
      -0.08218459784984589,
      0.007776459213346243,
      -0.034383729100227356,
      -0.027743283659219742,
      -0.007599340286105871,
      0.012785498984158039,
      -0.1011701226234436,
      0.04862875118851662,
      0.02643674612045288,
      0.03628692403435707,
      0.04378883168101311,
      -0.08722896128892899,
      -0.03968677669763565,
      0.031832482665777206,
      0.029978273436427116,
      0.05312490090727806,
      0.011177953332662582,
      0.036833569407463074,
      -0.005778591148555279,
      0.09159839153289795,
      -0.058231983333826065,
      0.001997676445171237,
      0.024089820683002472,
      -0.009786353446543217,
      -0.003721792483702302,
      0.008471360430121422,
      0.07613714039325714,
      -0.028955241665244102,
      0.038700371980667114,
      -0.040697984397411346,
      -0.12903380393981934,
      0.006578352302312851,
      -0.011249109171330929,
      0.031080404296517372,
      -0.06096019595861435,
      0.04603775590658188,
      -0.09615831822156906,
      -0.07091876864433289,
      0.007528563030064106,
      0.06395157426595688,
      -0.02757912501692772,
      -0.022807681933045387,
      0.028188874945044518,
      0.05326773226261139,
      -0.022493939846754074,
      0.0020040315575897694,
      0.09508015215396881,
      0.01187082938849926,
      -0.0297173410654068,
      -0.02347515895962715,
      -0.0170124564319849,
      -0.10684509575366974,
      0.07747373729944229,
      0.0809597298502922,
      -0.003069999162107706,
      -0.002957369899377227,
      -0.0771995335817337,
      0.09504856914281845,
      -0.0012735545169562101,
      -0.013514776714146137,
      -0.11044829338788986,
      0.04274945706129074,
      0.06130179390311241,
      -0.01361723244190216,
      -0.012346453964710236,
      0.025550739839673042,
      0.0245091300457716,
      -0.025980718433856964,
      -0.01299047190696001,
      0.03461386263370514,
      0.04857834056019783,
      0.07652388513088226,
      0.001940113608725369,
      0.0023391274735331535,
      0.061450403183698654,
      -0.0023737226147204638,
      0.05207781493663788,
      0.06864789873361588,
      0.022241873666644096,
      -0.0016035257140174508,
      0.027581095695495605,
      -0.03979376703500748,
      0.04245080426335335,
      0.03774738684296608,
      -0.00311666214838624,
      0.009069186635315418,
      -0.03953677788376808,
      -0.0021438454277813435,
      0.07358692586421967,
      0.01726205088198185,
      0.015240014530718327,
      -0.022556055337190628,
      -0.0082582151517272,
      -0.02314414642751217,
      -0.029926981776952744,
      0.02252596989274025,
      0.09460507333278656,
      -0.010336775332689285,
      0.03667762130498886,
      -0.025763584300875664,
      0.015495933592319489,
      -0.02917340397834778,
      -0.026213815435767174,
      -0.0165974460542202,
      -0.026763204485177994,
      -0.024302888661623,
      0.012227676808834076,
      -0.06164717674255371,
      -0.0006664101383648813,
      -0.08783411979675293,
      -0.04789908230304718,
      0.03937506303191185,
      -0.10056701302528381,
      -0.02237389236688614,
      0.027001583948731422,
      0.04916715249419212,
      -0.09992102533578873,
      -0.09973505139350891,
      0.055985841900110245,
      0.029057446867227554,
      0.01583719626069069,
      -0.004112853668630123,
      -0.03757929429411888,
      0.053792450577020645,
      0.04247986897826195,
      0.03314308077096939,
      -0.02116255834698677,
      -0.06575701385736465,
      -0.03195280581712723,
      0.06166918948292732,
      0.0034927213564515114,
      -0.01795421727001667,
      0.009428583085536957,
      0.07524343580007553,
      -0.08841691166162491,
      0.025345979258418083,
      -0.07113063335418701,
      -0.014459927566349506,
      -0.017658108845353127,
      -0.0902521014213562,
      0.018043650314211845,
      -0.007323889061808586,
      0.0371728241443634,
      0.06385539472103119,
      0.02480502612888813,
      -0.06959780305624008,
      -0.0016118596540763974,
      -0.10200099647045135,
      0.010422215797007084,
      0.029168305918574333,
      -0.01869344338774681,
      0.008640567772090435,
      -0.030904512852430344,
      -0.042942360043525696,
      0.056209735572338104,
      0.08909361809492111,
      0.0032380300108343363,
      0.09984337538480759,
      -0.024673838168382645,
      0.02588506042957306,
      -0.04381493106484413,
      0.08896861225366592,
      0.07933791726827621,
      0.15005777776241302,
      -0.029359500855207443,
      -0.008981569670140743,
      -0.006554328370839357,
      0.016172096133232117,
      -0.07383006066083908,
      -0.05082200467586517,
      -0.09328430891036987,
      -0.01873205602169037,
      -0.0654250979423523,
      -0.05984440818428993,
      -0.059991974383592606,
      -0.03041106089949608,
      0.04157496243715286,
      0.040962181985378265,
      0.04911122843623161,
      0.030794233083724976,
      0.0013659397372975945,
      0.0659366324543953,
      -0.037982646375894547,
      -0.010443643666803837,
      -0.0094230268150568,
      -0.02658090367913246,
      -0.049282997846603394,
      -0.03733890503644943,
      -0.032900620251894,
      0.02503601461648941,
      -0.013321763835847378,
      0.01658753491938114,
      0.03925132006406784,
      0.08534587174654007,
      -0.04680771753191948,
      -0.06042880564928055,
      -0.04645680636167526,
      -0.0010781938908621669,
      -0.007877389900386333,
      -0.056652240455150604,
      0.021094519644975662,
      0.015590092167258263,
      -0.10012425482273102,
      0.056645601987838745,
      0.04236721992492676,
      -0.02830798365175724,
      0.05757763236761093,
      -0.020941441878676414,
      0.08738880604505539,
      -0.013846357353031635,
      -0.05737687274813652,
      0.0006399238482117653,
      -0.005872176494449377,
      0.03418993204832077,
      0.05426990985870361,
      -0.09207384288311005
    ],
    [
      -0.020197751000523567,
      -0.06776191294193268,
      -0.033372752368450165,
      0.01795221120119095,
      0.06673486530780792,
      -0.068107970058918,
      0.011711680330336094,
      0.0517757385969162,
      -0.009951597079634666,
      -0.004825280513614416,
      -0.06517563760280609,
      -0.0022895601578056812,
      0.03946966305375099,
      -0.03975730761885643,
      -0.01682051457464695,
      0.007320628967136145,
      0.04129653424024582,
      0.03191441297531128,
      -0.0490262433886528,
      0.023924080654978752,
      -0.09086717665195465,
      -0.11109654605388641,
      0.08340435475111008,
      -0.0903194472193718,
      -0.06437496840953827,
      0.011065209284424782,
      -0.03430677950382233,
      -0.05519253760576248,
      0.021187283098697662,
      -0.00562120508402586,
      0.02602975443005562,
      0.04889155551791191,
      0.03829589858651161,
      0.03571653366088867,
      0.03538583964109421,
      0.06316551566123962,
      0.024563992395997047,
      0.041586291044950485,
      0.01924380473792553,
      0.06321078538894653,
      0.07503825426101685,
      -0.018508484587073326,
      0.022097375243902206,
      0.0858914852142334,
      -0.0322396382689476,
      0.05563987419009209,
      0.02241845428943634,
      -0.002118002623319626,
      0.03734637424349785,
      -0.021316949278116226,
      0.0031966085080057383,
      -0.04149197041988373,
      0.00023028443683870137,
      -0.01392115093767643,
      0.025710303336381912,
      -0.026949506253004074,
      0.03269899636507034,
      0.0022904754150658846,
      0.05723477900028229,
      0.02630041353404522,
      -0.043558649718761444,
      -0.03361636772751808,
      0.014301260933279991,
      0.03728272393345833,
      -0.026777494698762894,
      0.00813177227973938,
      0.032639507204294205,
      -0.013437582179903984,
      0.05164158716797829,
      -0.017550019547343254,
      0.011914405040442944,
      -0.0039772349409759045,
      -0.02109386771917343,
      -0.1091468408703804,
      -0.012491206638514996,
      -0.04376107454299927,
      0.004391064401715994,
      -0.023556824773550034,
      0.03918096050620079,
      0.010671268217265606,
      -0.022817978635430336,
      -0.010599893517792225,
      0.00456719845533371,
      -0.02052881196141243,
      -0.012344632297754288,
      -0.010568877682089806,
      -0.051548559218645096,
      0.007781242486089468,
      0.013030147179961205,
      -0.027385715395212173,
      0.03817412257194519,
      0.033260587602853775,
      0.01654081419110298,
      0.05923227220773697,
      -0.06068228557705879,
      -0.03577815368771553,
      0.08117317408323288,
      -0.01423508208245039,
      0.0703229084610939,
      0.04110714793205261,
      0.0033836541697382927,
      -0.0170567836612463,
      -0.001359964138828218,
      -0.02078988403081894,
      -0.04525111988186836,
      -0.033749837428331375,
      0.038415972143411636,
      -0.06923973560333252,
      0.02689700946211815,
      0.03258295729756355,
      0.005783420521765947,
      -0.00496326107531786,
      0.02379653789103031,
      -0.02250715345144272,
      -0.06386269629001617,
      0.016164936125278473,
      -0.058638133108615875,
      0.013441992923617363,
      0.009080587886273861,
      0.013280298560857773,
      0.045993316918611526,
      -0.11876312643289566,
      0.061960916966199875,
      0.03846590593457222,
      -0.07002513110637665,
      -0.07509515434503555,
      -0.08044280111789703,
      0.03860010951757431,
      -0.034329354763031006,
      -0.05637633427977562,
      0.02295880764722824,
      0.0783054456114769,
      0.018495682626962662,
      -0.05878773331642151,
      -0.028043681755661964,
      0.0108010433614254,
      0.0015606539091095328,
      0.03987134248018265,
      -0.06022471934556961,
      0.04451882839202881,
      -0.012654928490519524,
      0.10884523391723633,
      -0.04498603194952011,
      -0.054792363196611404,
      -0.016061965376138687,
      0.041677046567201614,
      0.006049043964594603,
      -0.04194038733839989,
      0.027751611545681953,
      0.002618219004943967,
      -0.01572706736624241,
      -0.045157838612794876,
      0.031131654977798462,
      0.05435454100370407,
      0.01142595149576664,
      0.028856545686721802,
      -0.07105282694101334,
      0.01237688958644867,
      0.03714859485626221,
      -0.024043673649430275,
      -0.02444702386856079,
      0.013697521761059761,
      0.03494957461953163,
      -0.005903040990233421,
      0.062391046434640884,
      0.009438566863536835,
      -0.027150191366672516,
      0.01061237882822752,
      -0.05902513489127159,
      -0.01842450350522995,
      0.08143278956413269,
      -0.006082360167056322,
      0.012902813963592052,
      0.023681996390223503,
      0.015220807865262032,
      -0.023745600134134293,
      -0.08819158375263214,
      -0.05862314626574516,
      -0.014604514464735985,
      -0.03791499137878418,
      0.008307723328471184,
      -0.07371488213539124,
      -0.008885825984179974,
      -0.02346161939203739,
      0.013064433820545673,
      -0.021959587931632996,
      0.007086941972374916,
      -0.030726050958037376,
      -0.031716395169496536,
      -0.00573729770258069,
      -0.025403505191206932,
      0.023709846660494804,
      -0.03901688754558563,
      0.024149661883711815,
      -0.026387743651866913,
      0.07495772838592529,
      -0.018511230126023293,
      -0.027958756312727928,
      -0.047205790877342224,
      -0.021186746656894684,
      5.9887646784773096e-05,
      0.005421726498752832,
      -0.02288738824427128,
      -0.022476812824606895,
      0.047338224947452545,
      0.011149343103170395,
      -0.008688243106007576,
      0.05470817908644676,
      -0.015776103362441063,
      0.010255000554025173,
      0.08050049096345901,
      -0.005949107930064201,
      0.03139163553714752,
      -0.13324013352394104,
      0.04619816690683365,
      0.014504393562674522,
      0.037883054465055466,
      -0.06448634713888168,
      -0.03345301002264023,
      -0.052548766136169434,
      -0.059392597526311874,
      -0.028354927897453308,
      0.004859895445406437,
      -0.03458184748888016,
      0.017655890434980392,
      -0.013092119246721268,
      -0.0727563127875328,
      0.02217072993516922,
      -0.054262176156044006,
      0.07180867344141006,
      0.058629512786865234,
      -0.01388467662036419,
      0.03948783129453659,
      0.0632101371884346,
      -0.03066970407962799,
      -0.020775653421878815,
      0.06541500240564346,
      -0.013070535846054554,
      0.004727146588265896,
      -0.030141258612275124,
      -0.03292141482234001,
      0.05920100584626198,
      0.047306083142757416,
      0.029856985434889793,
      0.04945890232920647,
      0.014349658042192459,
      0.024009641259908676,
      -0.01670857146382332,
      0.007242991589009762,
      0.05170314759016037,
      -0.020111193880438805,
      0.007826373912394047,
      -0.0026099770329892635,
      0.07734560966491699,
      0.0731804370880127,
      0.04122770205140114,
      0.003954426851123571,
      -0.02065710909664631,
      -0.07064402103424072,
      0.032802436500787735,
      0.025756262242794037,
      0.027963850647211075,
      -0.06725411862134933,
      0.05010787397623062,
      0.014243359677493572,
      -0.03917807340621948,
      -0.031244734302163124,
      0.02547622285783291,
      -0.010472684167325497,
      0.015541314147412777,
      0.11526484787464142,
      -0.03486118093132973,
      0.050983864814043045,
      -0.03171280771493912,
      0.054456040263175964,
      -0.042987387627363205,
      -0.042376209050416946,
      0.060812342911958694,
      -0.01705700159072876,
      -0.06424230337142944,
      0.03294273465871811,
      0.023527951911091805,
      -0.007731625344604254,
      -0.05634889006614685,
      0.0392206646502018,
      0.024075636640191078,
      -0.04345008730888367,
      0.034314416348934174,
      0.0684691071510315,
      -0.04745988920331001,
      0.059352193027734756,
      -0.04341508820652962,
      -0.05729993060231209,
      -0.08654189109802246,
      -0.0329856313765049,
      0.06283211708068848,
      -0.011465806514024734,
      0.06679331511259079,
      0.009988580830395222,
      0.005389765370637178,
      -0.06953609734773636,
      0.018291529268026352,
      0.04419312998652458,
      -0.023679038509726524,
      0.03375738859176636,
      -0.05213140323758125,
      -0.037077415734529495,
      -0.019432028755545616,
      0.022473933175206184,
      0.02386736497282982,
      -0.036366526037454605,
      0.07148628681898117,
      -0.03475414589047432,
      0.05625374987721443,
      0.05742962285876274,
      0.05498821660876274,
      -0.002928965026512742,
      -0.000742277130484581,
      0.04352577403187752,
      -0.020216379314661026,
      -0.0451759435236454,
      0.029852190986275673,
      -0.002749290317296982,
      0.06826358288526535,
      -0.029636064544320107,
      0.009261978790163994,
      0.03757547587156296,
      0.032386645674705505,
      0.024549325928092003,
      0.047825396060943604,
      0.012224498204886913,
      -0.05608319863677025,
      -0.03323725238442421,
      -0.012007145211100578,
      0.045428622514009476,
      -0.01870381459593773,
      0.018004877492785454,
      0.04859542101621628,
      -0.029392147436738014,
      -0.004394478164613247,
      -0.032534994184970856,
      -0.05525153875350952,
      0.0037011154927313328,
      0.02976812981069088,
      -0.006246930919587612,
      -0.010892702266573906,
      0.005312680266797543,
      -0.058114126324653625,
      0.02159254066646099,
      -0.08106820285320282,
      -0.06517290323972702,
      0.043163079768419266,
      -0.02766750380396843,
      -0.025339262560009956,
      -0.0172321368008852,
      0.013016635552048683,
      -0.03383758291602135,
      0.01927984319627285,
      -0.00479405652731657,
      -0.06633885949850082,
      0.013491513207554817,
      0.01629495620727539,
      -0.06656738370656967,
      0.11111277341842651,
      0.09109726548194885,
      0.004522796720266342,
      0.03117515705525875,
      -0.06412209570407867,
      0.012921958230435848,
      0.04542183503508568,
      0.020969118922948837,
      0.028565043583512306,
      -0.0314323715865612,
      0.05524243414402008,
      -0.05301332101225853,
      0.05878612771630287,
      -0.07077270746231079,
      0.04171256348490715,
      -0.055229928344488144,
      -0.07052992284297943,
      -0.012881126254796982,
      0.03815414011478424,
      -0.018635544925928116,
      0.06503096967935562,
      -0.05107232183218002,
      0.020505625754594803,
      -0.0741817057132721,
      -0.0244490634649992,
      -0.030735254287719727,
      -0.03524763509631157,
      0.0024882981088012457,
      -0.01333542913198471,
      -0.06464841961860657,
      -0.00894588977098465,
      0.024787230417132378,
      -0.007847282104194164,
      -0.01802833005785942,
      -0.013383205980062485,
      -0.04331805184483528,
      -0.021879514679312706,
      -0.00891965813934803,
      -0.08196397125720978,
      0.07500249147415161,
      0.05193039029836655,
      -0.00033507312764413655,
      -0.04038376361131668,
      -0.043548647314310074,
      -0.018726347014307976,
      0.04063864424824715,
      0.08479360491037369,
      0.09650218486785889,
      -0.010716753080487251,
      -0.04439355060458183,
      0.02060100995004177,
      0.0008983670850284398,
      0.0017402229132130742,
      -0.027652472257614136,
      0.016970301046967506,
      -0.005677768494933844,
      0.04450078308582306,
      8.926221198635176e-05,
      -0.023823438212275505,
      0.0735074132680893,
      -0.02655038610100746,
      -0.06309102475643158,
      0.00778648816049099,
      -0.04932064563035965,
      0.021119361743330956,
      -0.05120907351374626,
      0.04540449008345604,
      0.009233019314706326,
      0.0440753698348999,
      0.00869026966392994,
      -0.04786700755357742,
      0.02191748656332493,
      0.04154982790350914,
      -0.06828898936510086,
      0.046300631016492844,
      0.033800818026065826,
      0.06573942303657532,
      0.015269427560269833,
      0.02955200895667076,
      0.02255217544734478,
      -0.01241935696452856,
      -0.022453511133790016,
      0.05914754047989845,
      -0.08344998210668564,
      0.011153414845466614,
      -0.04045584797859192,
      0.05109460651874542,
      0.017296522855758667,
      -0.018324650824069977,
      -0.03170882910490036,
      0.06099874526262283,
      -0.09536891430616379,
      0.0027538659051060677,
      -0.0555247999727726,
      -0.07361999154090881,
      -0.004794830456376076,
      -0.031780779361724854,
      0.003685570554807782,
      0.03339507430791855,
      0.04427310824394226,
      0.019478527829051018,
      0.10838162899017334,
      0.005659077782183886,
      -0.017319530248641968,
      0.027776863425970078,
      -0.06490493565797806,
      0.015354477800428867,
      0.02261829562485218,
      -0.03037303127348423,
      0.0011923041893169284,
      0.05249848589301109,
      -0.02201899141073227,
      0.03729936107993126,
      0.06359777599573135,
      -0.005826061591506004,
      -0.05322469398379326,
      -0.007480607833713293,
      -0.0068151867017149925,
      0.027843838557600975,
      0.03553073853254318,
      0.020258886739611626,
      0.008625132031738758,
      0.020272554829716682,
      -0.01154210977256298,
      0.03924308344721794,
      -0.046411290764808655,
      -0.012667177245020866,
      0.01121720764786005,
      -0.07186353951692581,
      -0.11636824160814285,
      0.004117378033697605,
      0.011664548888802528,
      -0.014357563108205795,
      -0.023258185014128685,
      0.029508057981729507,
      0.02032891847193241,
      0.0024707417469471693,
      -0.03904189169406891,
      -0.028571609407663345,
      0.0007931317086331546
    ],
    [
      -0.02578815072774887,
      -0.0007967918645590544,
      -0.05441898852586746,
      0.01976805366575718,
      -0.019117791205644608,
      0.004511404316872358,
      0.0756717100739479,
      -0.05654696375131607,
      -0.06606138497591019,
      0.04983863979578018,
      0.013682893477380276,
      -0.055626027286052704,
      -0.04074394330382347,
      0.02865150384604931,
      -0.04516683518886566,
      -0.015251869335770607,
      0.0692630484700203,
      -0.07316958904266357,
      0.04985987767577171,
      -0.02774282731115818,
      0.06556925922632217,
      0.007178605999797583,
      0.0031646161805838346,
      -0.07348901778459549,
      0.041017189621925354,
      -0.00652299216017127,
      0.019287632778286934,
      0.014773654751479626,
      0.03687132149934769,
      -0.0028812556993216276,
      0.04732676222920418,
      0.05847950279712677,
      0.016813073307275772,
      0.16877494752407074,
      -0.02441399358212948,
      -0.07924715429544449,
      -0.006365542765706778,
      0.015101907774806023,
      -0.028497863560914993,
      -0.03340897336602211,
      0.017338968813419342,
      -0.09640643000602722,
      -0.03309142217040062,
      -0.038355398923158646,
      -0.013451027683913708,
      -0.024689069017767906,
      -0.03504940867424011,
      0.040809791535139084,
      -0.06559325009584427,
      -0.030032573267817497,
      0.05484979599714279,
      0.04354057461023331,
      -0.03642113879323006,
      -0.009191260673105717,
      -0.08047404885292053,
      -0.028072398155927658,
      -0.02769169956445694,
      0.061606548726558685,
      -0.035040371119976044,
      -0.014890776947140694,
      -0.005536680109798908,
      -0.028488626703619957,
      0.004216418135911226,
      -0.003400141606107354,
      0.04706728458404541,
      -0.03320601209998131,
      0.05687655508518219,
      -0.00014870068116579205,
      -0.01845238357782364,
      0.12993691861629486,
      -0.004066405352205038,
      -0.06336434930562973,
      0.0045255022123456,
      -0.03711565583944321,
      -0.008209134452044964,
      -0.0070561738684773445,
      -0.002654197160154581,
      -0.027576936408877373,
      0.0026758459862321615,
      0.04802720621228218,
      0.1126682236790657,
      0.02085249312222004,
      -0.01749451830983162,
      -4.901679858448915e-05,
      0.012670557014644146,
      0.034840017557144165,
      0.08097618073225021,
      0.03349721431732178,
      -0.03310931846499443,
      0.021158771589398384,
      -0.00425840076059103,
      -0.057216957211494446,
      0.06223295256495476,
      0.03355187550187111,
      -0.0480898842215538,
      -0.08450933545827866,
      -0.030339734628796577,
      0.016506431624293327,
      0.0366746224462986,
      0.024794314056634903,
      -0.07137571275234222,
      0.059242215007543564,
      -0.0006534726126119494,
      0.029991598799824715,
      -0.04413501173257828,
      0.04263777658343315,
      -0.020548216998577118,
      0.05704227089881897,
      0.024789707735180855,
      -0.03589425981044769,
      0.006422998383641243,
      0.06468265503644943,
      0.052594833076000214,
      -0.0541655458509922,
      0.057524919509887695,
      0.03114769607782364,
      0.03766077011823654,
      0.016234010457992554,
      0.09565794467926025,
      0.003538416465744376,
      -0.028146227821707726,
      -0.029641056433320045,
      -0.008409300819039345,
      0.010832181200385094,
      -0.0020097647793591022,
      -0.05619415268301964,
      -0.026572713628411293,
      -0.12839995324611664,
      0.017688903957605362,
      0.049987390637397766,
      0.04407395049929619,
      -0.015298512764275074,
      0.00657664192840457,
      -0.0044145588763058186,
      0.026268955320119858,
      0.04653553664684296,
      -0.014369779266417027,
      -0.014115686528384686,
      0.027252987027168274,
      0.010956278070807457,
      -0.035447344183921814,
      -0.010125097818672657,
      -0.08533220738172531,
      -0.0943114385008812,
      -0.010730764828622341,
      0.06815595924854279,
      0.015860024839639664,
      -0.043223973363637924,
      0.0657360702753067,
      0.005560870282351971,
      0.0012267758138477802,
      -0.0458693765103817,
      0.019946997985243797,
      -0.04563393443822861,
      0.010130533948540688,
      -0.04952859506011009,
      0.022244246676564217,
      -0.03089766763150692,
      -0.051655370742082596,
      -0.04989718645811081,
      -0.02746688388288021,
      -0.07334864884614944,
      0.03244433179497719,
      -0.0331091545522213,
      0.039453137665987015,
      -0.01465399656444788,
      0.07900244742631912,
      -0.03354007005691528,
      0.019822929054498672,
      0.035738226026296616,
      -0.04820604994893074,
      -0.03675118088722229,
      -0.07554957270622253,
      0.005028860177844763,
      0.03795988857746124,
      0.054106324911117554,
      0.022467726841568947,
      -0.0027782830875366926,
      0.06461729854345322,
      -0.019521459937095642,
      -0.00034620348014868796,
      -0.051399946212768555,
      -0.024384265765547752,
      -0.02891978621482849,
      -0.008790201507508755,
      -0.047417573630809784,
      0.03417673334479332,
      -0.012787971645593643,
      -0.016237935051321983,
      0.007600562181323767,
      0.03141544759273529,
      -0.04412735253572464,
      0.053054939955472946,
      -0.07395925372838974,
      -0.030463002622127533,
      0.0027986159548163414,
      -0.06201523542404175,
      -0.0050179967656731606,
      0.0047185686416924,
      0.06539039313793182,
      0.009421075694262981,
      0.015683358535170555,
      -0.0798678770661354,
      0.03526423126459122,
      0.02283412404358387,
      -0.0659712553024292,
      -0.020744794979691505,
      0.03754594549536705,
      -0.011270842514932156,
      0.010391623713076115,
      0.05868851765990257,
      -0.06270670890808105,
      -0.042202427983284,
      -0.032896947115659714,
      -0.08214711397886276,
      0.09586507827043533,
      0.054457709193229675,
      -0.09588135778903961,
      -0.04861622303724289,
      0.0032229204662144184,
      -0.03406897932291031,
      -0.06279685348272324,
      -0.04676230624318123,
      0.030431104823946953,
      -0.004523478448390961,
      -0.044479288160800934,
      -0.05893208086490631,
      -0.018511289730668068,
      -0.07687073945999146,
      -0.08808629959821701,
      0.020172573626041412,
      -7.971945888129994e-05,
      0.06060103327035904,
      -0.04662500321865082,
      0.02173723466694355,
      -0.006847677286714315,
      0.04527043178677559,
      -0.011964167468249798,
      0.014398941770195961,
      0.051515549421310425,
      -0.07888195663690567,
      -0.07935366034507751,
      0.012504817917943,
      0.0287465937435627,
      -0.0641242191195488,
      0.03755825385451317,
      -0.03812442719936371,
      -0.05487103760242462,
      0.013412948697805405,
      0.00101941148750484,
      0.0005108017357997596,
      0.010811339132487774,
      0.05530274659395218,
      -0.004028880503028631,
      -0.009468486532568932,
      0.09985968470573425,
      0.03685421124100685,
      0.01570117473602295,
      -0.006231983657926321,
      -0.06973985582590103,
      -0.04363632947206497,
      0.038421161472797394,
      0.03934326395392418,
      0.032912179827690125,
      0.011826022528111935,
      0.05342423915863037,
      -0.04370454326272011,
      -0.03828129172325134,
      0.003425812581554055,
      0.09730752557516098,
      0.018633168190717697,
      -0.0020433864556252956,
      -0.10981068760156631,
      0.022750020027160645,
      0.0043235099874436855,
      0.019028447568416595,
      -0.056706685572862625,
      0.011808681301772594,
      -0.034271858632564545,
      -0.020953934639692307,
      0.06093871593475342,
      0.07714186608791351,
      -0.11907847225666046,
      0.03338896483182907,
      0.03421074524521828,
      0.015823567286133766,
      0.028583738952875137,
      0.03170830383896828,
      0.012187170796096325,
      0.01407457422465086,
      0.00027555215638130903,
      0.1418466866016388,
      -0.030053414404392242,
      -0.032685406506061554,
      0.04139574617147446,
      -0.061858005821704865,
      0.07432044297456741,
      -0.002558234380558133,
      0.07217513769865036,
      0.0027087698690593243,
      0.043332673609256744,
      0.03059828095138073,
      0.03704121336340904,
      -0.023694027215242386,
      0.0032007440458983183,
      0.03341824188828468,
      -0.0001069544450729154,
      -0.04683223366737366,
      0.039040859788656235,
      -0.019384682178497314,
      0.05877973884344101,
      0.03661145642399788,
      0.03658062592148781,
      0.10221223533153534,
      -0.05459986627101898,
      -0.10363774001598358,
      -0.008686743676662445,
      -0.029358835890889168,
      -0.026747502386569977,
      0.07096719741821289,
      -0.02526957169175148,
      -0.013796106912195683,
      -0.04272579029202461,
      0.06674189120531082,
      0.03964970260858536,
      -0.031045889481902122,
      0.02795359678566456,
      0.004710156936198473,
      9.7127296612598e-05,
      0.03620804473757744,
      0.002020348561927676,
      0.01362569723278284,
      -0.036343079060316086,
      -0.0761193111538887,
      -0.0691663920879364,
      0.01550733856856823,
      -0.0016622345428913832,
      -0.11007402092218399,
      -0.045003749430179596,
      -0.01553217601031065,
      -0.0537218414247036,
      0.06045660004019737,
      -0.01873237080872059,
      -0.043845366686582565,
      0.09770912677049637,
      0.04626524820923805,
      -0.09388065338134766,
      -0.01870071329176426,
      -0.040796827524900436,
      -0.026463311165571213,
      -0.02510388009250164,
      -0.06468062102794647,
      -0.023120617493987083,
      0.03401828929781914,
      -0.008322174660861492,
      0.07957414537668228,
      0.03767071291804314,
      -0.0019188253208994865,
      0.06552504003047943,
      0.015640052035450935,
      -0.042336028069257736,
      -0.061790209263563156,
      0.03831800818443298,
      0.003884138772264123,
      0.04372008517384529,
      0.0012713682372123003,
      0.006760202348232269,
      -0.06409604847431183,
      -0.011746134608983994,
      0.041501108556985855,
      0.015441229566931725,
      0.01599157601594925,
      0.000544924580026418,
      0.006620858330279589,
      -0.04520240053534508,
      0.022870976477861404,
      -0.04420324042439461,
      0.04523586109280586,
      -0.02086147852241993,
      -0.11694139242172241,
      -0.040638361126184464,
      -0.06773076206445694,
      -0.03172152116894722,
      0.022902188822627068,
      -0.0492422841489315,
      0.018656529486179352,
      0.008968895301222801,
      -0.022218041121959686,
      0.040031105279922485,
      0.08942648768424988,
      0.030487682670354843,
      -0.04241622984409332,
      -0.014925277791917324,
      0.016546783968806267,
      -0.035357240587472916,
      -0.04824720695614815,
      -0.012787532061338425,
      -0.003503209911286831,
      0.0374746136367321,
      0.012952730990946293,
      0.0027626939117908478,
      -0.017149614170193672,
      0.022486155852675438,
      -0.02940821647644043,
      0.03967396914958954,
      -0.00598714267835021,
      0.09380678087472916,
      0.008596106432378292,
      -0.003237092401832342,
      0.023688243702054024,
      -0.027290022000670433,
      -0.03545762971043587,
      -0.03645547851920128,
      0.04227333888411522,
      0.03270440548658371,
      0.023645438253879547,
      -0.012048798613250256,
      -0.049892839044332504,
      -0.042538225650787354,
      -0.023559007793664932,
      0.06926724314689636,
      0.041119229048490524,
      0.0180596224963665,
      -0.013494595885276794,
      -0.026830656453967094,
      -0.027982385829091072,
      0.015199382789433002,
      -0.03039761818945408,
      -0.005933329463005066,
      0.029786884784698486,
      -0.026036828756332397,
      0.07806677371263504,
      -0.02870510146021843,
      -0.03019057959318161,
      0.04454081133008003,
      -0.06206135079264641,
      0.025130275636911392,
      -0.00556890619918704,
      -0.026895780116319656,
      -0.008350441232323647,
      -0.006875903811305761,
      -0.0004021282366011292,
      -0.03300418332219124,
      0.012075528502464294,
      0.038541171699762344,
      -0.05023631453514099,
      -0.025767376646399498,
      0.049574751406908035,
      0.025974011048674583,
      -0.024791769683361053,
      -0.05208506062626839,
      -0.014213617891073227,
      -0.09939323365688324,
      -0.007399185560643673,
      -0.005106351338326931,
      -0.02208201214671135,
      -0.04596380516886711,
      -0.07095441222190857,
      -0.0066911024041473866,
      0.013475649990141392,
      0.010978223755955696,
      -0.06717628985643387,
      -0.010819329880177975,
      0.035792674869298935,
      -0.028218571096658707,
      -0.007668845355510712,
      -0.0065183937549591064,
      -0.0764310359954834,
      -0.003784910310059786,
      0.06355215609073639,
      0.016646478325128555,
      -0.09078717976808548,
      0.029373154044151306,
      0.018857283517718315,
      -0.012598872184753418,
      -0.05238709598779678,
      -0.013106118887662888,
      -0.03127187862992287,
      -0.01613771915435791,
      -0.013852246105670929,
      -0.0036723183002322912,
      0.06373240053653717,
      -0.040168825536966324,
      0.029499035328626633,
      0.030837008729577065,
      0.048258304595947266,
      -0.03841642290353775,
      0.02134951576590538,
      -0.023221522569656372,
      -0.022455483675003052,
      0.015298420563340187,
      -0.037758342921733856,
      0.03994743525981903,
      0.016978777945041656,
      -0.09898562729358673,
      0.041176218539476395,
      0.0018148997332900763,
      -0.0749589055776596,
      -0.02612987533211708,
      -0.019985664635896683,
      -0.03157530352473259,
      -0.020056409761309624,
      0.09581912308931351
    ],
    [
      -0.06531154364347458,
      0.005025118589401245,
      -0.0386091023683548,
      -0.014454140327870846,
      0.024592462927103043,
      0.023921312764286995,
      0.04038609564304352,
      -0.021945370361208916,
      0.02042962610721588,
      0.020718535408377647,
      -0.07658030092716217,
      -0.035018786787986755,
      -0.043346796184778214,
      -0.005377781577408314,
      -0.05694478750228882,
      -0.06516287475824356,
      -0.008793400600552559,
      -0.007554484996944666,
      -0.16358940303325653,
      0.02342846617102623,
      -0.008902142755687237,
      0.033606234937906265,
      0.11895782500505447,
      -0.03873088210821152,
      0.0450093038380146,
      -0.020987026393413544,
      0.0072830538265407085,
      0.0222008116543293,
      -0.016910696402192116,
      0.01233880128711462,
      0.018707621842622757,
      -0.035219818353652954,
      0.018145235255360603,
      -0.016568312421441078,
      0.014239680953323841,
      -0.03742561116814613,
      -0.017964240163564682,
      -0.07353822886943817,
      0.025699812918901443,
      -0.014973847195506096,
      -0.0346219465136528,
      0.05712953582406044,
      -0.018484577536582947,
      -0.014383884146809578,
      -0.09567322582006454,
      0.1125091165304184,
      0.0569387823343277,
      0.019383985549211502,
      0.005937754642218351,
      -0.018784288316965103,
      -0.0074099404737353325,
      0.0817163735628128,
      -0.04508349299430847,
      -0.0421188548207283,
      0.009822641499340534,
      0.020203182473778725,
      -0.0009609113913029432,
      0.048149559646844864,
      0.025270022451877594,
      -0.020355813205242157,
      0.047438640147447586,
      0.06844567507505417,
      -0.00971242506057024,
      -0.011184490285813808,
      -0.0024840596597641706,
      0.04071229323744774,
      -0.09045962244272232,
      0.025736138224601746,
      -0.00011062558769481257,
      0.0027148781809955835,
      -0.05864861235022545,
      -0.019081369042396545,
      0.06082513928413391,
      -0.024544663727283478,
      0.03682262822985649,
      -0.0019711197819560766,
      -0.049072764813899994,
      -0.009101364761590958,
      -0.02532297745347023,
      0.08811286091804504,
      0.008607570081949234,
      -0.04892418533563614,
      0.005127408541738987,
      0.0001526090200059116,
      -0.0181711558252573,
      0.021527763456106186,
      0.08894339203834534,
      -0.07167882472276688,
      -0.016710929572582245,
      -0.07888726145029068,
      0.00921495258808136,
      0.0972730815410614,
      -0.009427983313798904,
      0.018199969083070755,
      0.0826248899102211,
      -0.012962927110493183,
      -0.014403386041522026,
      -0.006176517345011234,
      -0.004204787313938141,
      -0.0611315593123436,
      -0.04546612873673439,
      0.09637550264596939,
      0.06851474940776825,
      -0.02332780882716179,
      -0.07298624515533447,
      0.018137892708182335,
      -0.07114274799823761,
      0.042745016515254974,
      0.03641152381896973,
      -0.023958509787917137,
      0.06599479913711548,
      -0.038641106337308884,
      -0.06762958317995071,
      -0.0827089250087738,
      0.05849155783653259,
      -0.03615584596991539,
      -0.009661706164479256,
      -0.028140109032392502,
      0.00056988256983459,
      -0.03540728613734245,
      0.007895486429333687,
      -0.04592645540833473,
      -0.03814494237303734,
      -0.03389264643192291,
      0.06930208206176758,
      0.047225650399923325,
      0.016979007050395012,
      0.019781075417995453,
      0.01126821618527174,
      0.06445830315351486,
      -0.014014939777553082,
      0.023754719644784927,
      0.07319045811891556,
      -0.033041637390851974,
      0.0677117332816124,
      0.06049645319581032,
      0.009810000658035278,
      0.02631346322596073,
      -0.017362305894494057,
      -0.03700902312994003,
      -0.021879564970731735,
      0.05554254725575447,
      -0.01749216951429844,
      -0.054224010556936264,
      -0.03842748701572418,
      -0.014471610076725483,
      0.06280836462974548,
      -0.034641820937395096,
      -0.06052911654114723,
      0.052711371332407,
      -0.014572384767234325,
      0.00033819227246567607,
      0.044569678604602814,
      0.02402847446501255,
      0.023435309529304504,
      -0.06060369312763214,
      -0.05667762830853462,
      -0.008239760994911194,
      0.060586798936128616,
      -0.08318327367305756,
      0.006483657285571098,
      0.0526127889752388,
      -0.06412488967180252,
      0.045823629945516586,
      -0.010434567928314209,
      0.01483737863600254,
      -0.006179098505526781,
      0.013508821837604046,
      0.012468567118048668,
      0.03511969372630119,
      0.056631289422512054,
      -0.03449166938662529,
      0.02575761452317238,
      -0.011132647283375263,
      0.024371348321437836,
      0.034128766506910324,
      0.05477122589945793,
      0.0038101051468402147,
      -0.07750216126441956,
      0.004412639886140823,
      -0.008955443277955055,
      -0.03291604295372963,
      0.017317617312073708,
      0.02574361115694046,
      0.03152906522154808,
      -0.027691148221492767,
      0.04921574518084526,
      0.005240884609520435,
      -0.023383906111121178,
      0.01783183217048645,
      -0.03412800654768944,
      -0.02868390455842018,
      -0.011068763211369514,
      0.031632501631975174,
      -0.010571001097559929,
      0.05087921768426895,
      -0.06963394582271576,
      -0.006713249254971743,
      -0.00463872542604804,
      -0.026064861565828323,
      0.04732312634587288,
      0.02395337074995041,
      0.014381801709532738,
      -0.03656966611742973,
      -0.03579036146402359,
      -0.06890331208705902,
      0.07488547265529633,
      -0.010873887687921524,
      0.031347401440143585,
      -0.02361985109746456,
      0.00844613742083311,
      -0.01009160466492176,
      0.06628189980983734,
      -0.03445815667510033,
      0.019918575882911682,
      -0.0674964115023613,
      -0.010697228834033012,
      -0.02587675303220749,
      -0.08086439222097397,
      -0.032420914620161057,
      -0.006558927241712809,
      0.01384088583290577,
      -0.006479430012404919,
      -0.05359998717904091,
      0.06488965451717377,
      -0.06364979594945908,
      0.0677625834941864,
      -0.12892548739910126,
      -0.05840093642473221,
      0.03444002941250801,
      -0.08895563334226608,
      -0.02405184879899025,
      -0.0234586913138628,
      0.06206592172384262,
      -0.02217458374798298,
      -0.05920035392045975,
      0.0013223476707935333,
      0.017412928864359856,
      0.05045580491423607,
      -0.01805157959461212,
      0.0512869767844677,
      0.022829564288258553,
      0.09898258745670319,
      -0.00851441640406847,
      0.027329999953508377,
      0.14778131246566772,
      0.05333501473069191,
      0.0036114153917878866,
      0.02069433219730854,
      0.03869428485631943,
      -0.00641254335641861,
      -0.030125705525279045,
      0.025622637942433357,
      0.03907879441976547,
      -0.059842176735401154,
      -0.01523092295974493,
      0.02170104905962944,
      -0.05386819317936897,
      -0.04337100684642792,
      -0.004173675086349249,
      0.05688125267624855,
      -0.057981938123703,
      0.04217950627207756,
      0.011402578093111515,
      -0.020413799211382866,
      -0.0548027940094471,
      -0.016593346372246742,
      0.04057605564594269,
      -0.05584269389510155,
      0.014428525231778622,
      -0.014984948560595512,
      0.03614712134003639,
      0.004405519459396601,
      0.00039897867827676237,
      -0.004258377011865377,
      0.07839131355285645,
      0.03582010790705681,
      -0.03223854675889015,
      0.06296750903129578,
      -0.025422804057598114,
      0.006988876033574343,
      -0.00634250370785594,
      0.04487071931362152,
      -0.009183219633996487,
      0.009666768833994865,
      -0.07545235008001328,
      -0.00124978250823915,
      -0.06763695180416107,
      -0.01091742143034935,
      -0.02049160748720169,
      0.002478203736245632,
      0.09213048964738846,
      0.022932086139917374,
      0.03694656491279602,
      0.060506854206323624,
      0.010706896893680096,
      -0.07331563532352448,
      0.0034045411739498377,
      -0.03760819137096405,
      -0.01191722322255373,
      -0.013392725959420204,
      -0.05653613805770874,
      0.022662537172436714,
      -0.044764865189790726,
      0.02893962897360325,
      -0.03066417947411537,
      -0.01906016282737255,
      0.015549393370747566,
      0.07330634444952011,
      0.06684692203998566,
      0.06295302510261536,
      -0.0031867658253759146,
      -0.040160492062568665,
      -0.016356853768229485,
      -0.13936951756477356,
      0.0365392304956913,
      0.0736088901758194,
      0.09014826267957687,
      0.06964775174856186,
      0.04397093877196312,
      -0.04466642811894417,
      0.0347326435148716,
      -0.028446756303310394,
      0.002844259375706315,
      -0.043247856199741364,
      -0.04207541421055794,
      0.004929773509502411,
      -0.0854642316699028,
      -0.026657795533537865,
      -0.049194954335689545,
      -0.033761005848646164,
      0.03667004033923149,
      -0.037496697157621384,
      -0.060963064432144165,
      -0.05140550807118416,
      -0.04389307647943497,
      -0.04409997910261154,
      0.06466935575008392,
      -0.03731682896614075,
      -0.04311424493789673,
      0.051749538630247116,
      0.03656113147735596,
      0.024057812988758087,
      0.08725398778915405,
      -0.020409811288118362,
      0.05630938336253166,
      0.009750839322805405,
      0.05362226068973541,
      -0.029584281146526337,
      0.0019342999439686537,
      0.005678937770426273,
      -0.0003826471511274576,
      -0.01721040904521942,
      -0.021488482132554054,
      -0.05815312638878822,
      -0.010148060508072376,
      0.06648007035255432,
      -0.051485173404216766,
      0.016583530232310295,
      -0.07014813274145126,
      0.057347025722265244,
      0.0014814831083640456,
      0.009387488476932049,
      -0.07026979327201843,
      -0.0037954337894916534,
      -0.040179021656513214,
      0.022444112226366997,
      -0.014106860384345055,
      -0.02029227651655674,
      0.004907302092760801,
      0.01708049327135086,
      -0.05808610841631889,
      -0.09605788439512253,
      0.023165499791502953,
      0.03320246562361717,
      0.0753062292933464,
      -0.013775049708783627,
      -0.053200069814920425,
      0.0025504459626972675,
      0.05225682258605957,
      -0.01395153533667326,
      0.0367656908929348,
      0.00729571096599102,
      0.0205898005515337,
      0.006118649151176214,
      0.06740831583738327,
      -0.056586749851703644,
      -0.02131764590740204,
      0.017887849360704422,
      0.008227478712797165,
      -0.013583186082541943,
      0.01716551184654236,
      -0.00848808791488409,
      -0.013162048533558846,
      0.006572567392140627,
      -0.06252249330282211,
      0.015485112555325031,
      0.006598294246941805,
      -0.019164789468050003,
      0.0017094871727749705,
      0.021751482039690018,
      -0.03780212253332138,
      0.036619435995817184,
      -0.02754565328359604,
      0.038520678877830505,
      0.05340880528092384,
      0.03932107239961624,
      -0.0059522055089473724,
      -0.011344214901328087,
      -0.09469052404165268,
      0.06305691599845886,
      -0.07177432626485825,
      -0.0014563489239662886,
      0.03005380928516388,
      -0.024669542908668518,
      0.03429022058844566,
      0.04838581010699272,
      0.07364138960838318,
      -0.024294374510645866,
      -0.04086555913090706,
      0.03661748021841049,
      -0.07372847944498062,
      0.017217246815562248,
      0.04065487161278725,
      0.09945227205753326,
      0.020292866975069046,
      0.007740233093500137,
      -0.1108717992901802,
      -0.0010720923310145736,
      -0.0054133194498717785,
      0.0588226318359375,
      0.02782660722732544,
      0.08175454288721085,
      -0.13077105581760406,
      -0.007881519384682178,
      0.03320550173521042,
      -0.09642086178064346,
      -0.03302299603819847,
      -0.014027983881533146,
      -0.018575327470898628,
      -0.011586821638047695,
      0.04658634588122368,
      0.0002685304207261652,
      -0.004046300891786814,
      -0.01459679938852787,
      0.03876669332385063,
      0.006931097246706486,
      -0.044699933379888535,
      -0.028319763019680977,
      0.005017186980694532,
      0.047757089138031006,
      0.07564949244260788,
      0.030283505097031593,
      0.04121087118983269,
      -0.022528210654854774,
      0.052307214587926865,
      -0.050355203449726105,
      -0.07459131628274918,
      -0.04770060256123543,
      -0.07089871168136597,
      0.041079211980104446,
      0.07427192479372025,
      -0.013682049699127674,
      0.05940250679850578,
      -0.011322641745209694,
      0.001182605978101492,
      0.06068557873368263,
      -0.022013960406184196,
      -0.09510636329650879,
      -0.05197976157069206,
      -0.005603213794529438,
      0.025485295802354813,
      0.02377926930785179,
      -0.002906204666942358,
      -0.018578292801976204,
      -0.040920618921518326,
      -0.005655440967530012,
      -0.011562416329979897,
      -0.010838893242180347,
      0.009930085390806198,
      -0.03311684727668762,
      0.011238319799304008,
      0.06256984174251556,
      -0.10386668145656586,
      -0.01405659131705761,
      0.025487152859568596,
      -0.010032612830400467,
      0.0283682718873024,
      -0.018497128039598465,
      0.04659733921289444,
      -0.02885464020073414,
      -0.0027454420924186707,
      -0.037298738956451416,
      -0.06280994415283203,
      -0.013023489154875278,
      -0.0011725651565939188,
      -0.033422790467739105,
      -0.013144275173544884,
      0.0389849990606308,
      -0.009574903175234795,
      0.006479176227003336,
      -0.01310726534575224,
      0.05079343914985657
    ],
    [
      0.006039094645529985,
      0.04875321686267853,
      0.09608639031648636,
      0.06411223113536835,
      -0.042668260633945465,
      0.03548790141940117,
      0.052403662353754044,
      -0.033354949206113815,
      -0.014829267747700214,
      -0.022209856659173965,
      0.01428318303078413,
      0.00804162211716175,
      -0.005249646957963705,
      0.03619271516799927,
      -0.038752228021621704,
      0.06022655963897705,
      -0.1233200803399086,
      -0.06262679398059845,
      -0.0022558660712093115,
      -0.015852736309170723,
      -0.06615138798952103,
      0.020107056945562363,
      -0.060746774077415466,
      -0.04314487800002098,
      -0.005555975250899792,
      -0.03257167339324951,
      -0.11534470319747925,
      -0.10435958206653595,
      -0.05381399765610695,
      -0.03925266116857529,
      0.03952214866876602,
      0.020932512357831,
      0.008861593902111053,
      -0.014726840890944004,
      0.017487624660134315,
      0.06618577986955643,
      0.0008106300956569612,
      -0.04170336574316025,
      0.018123192712664604,
      0.04402934014797211,
      0.027693787589669228,
      -0.019662275910377502,
      0.011169538833200932,
      0.0027358108200132847,
      -0.045292794704437256,
      -0.01716366410255432,
      0.025979597121477127,
      -0.006769157014787197,
      -0.08823046833276749,
      -0.11259432137012482,
      -0.02079547941684723,
      -0.0014810165157541633,
      -0.04923827946186066,
      -0.03655126318335533,
      -0.009673390537500381,
      -0.030593134462833405,
      -0.00025518317124806345,
      -0.0646597370505333,
      0.022536249831318855,
      -0.0219868253916502,
      -0.03920075669884682,
      0.038244135677814484,
      0.012696781195700169,
      0.07643197476863861,
      -0.007037490606307983,
      0.00666072266176343,
      -0.00788218341767788,
      -0.011840018443763256,
      -0.06181590259075165,
      -0.04136677831411362,
      0.02051367796957493,
      -0.017020106315612793,
      -0.015126582235097885,
      0.015904096886515617,
      0.022704076021909714,
      0.01148028951138258,
      0.060209713876247406,
      -0.01038418710231781,
      -0.016542255878448486,
      0.0013114030007272959,
      -0.06532112509012222,
      -0.011073975823819637,
      -0.018934449180960655,
      -0.034041766077280045,
      0.012404486536979675,
      -0.027527913451194763,
      -0.04094163700938225,
      0.04639410227537155,
      -0.014351758174598217,
      0.030396096408367157,
      -0.036531101912260056,
      0.05987019091844559,
      -0.029244689270853996,
      -0.05879731476306915,
      -0.040529679507017136,
      0.03351563960313797,
      -0.010310058481991291,
      0.017698662355542183,
      0.017659790813922882,
      -0.06317531317472458,
      -0.03807103633880615,
      -0.006621232721954584,
      0.008140251971781254,
      0.02419419400393963,
      -0.03235561028122902,
      -0.02352924272418022,
      0.026365747675299644,
      0.001440375461243093,
      0.012547804974019527,
      -0.02916000783443451,
      -0.06463070958852768,
      -0.035293545573949814,
      0.005964469164609909,
      -0.046913307160139084,
      -0.05135113000869751,
      -0.023211149498820305,
      -0.02447705902159214,
      -0.07450500130653381,
      0.02684304490685463,
      -0.012038894928991795,
      -0.03849382698535919,
      0.013785800896584988,
      -0.03229409456253052,
      -0.06040557473897934,
      0.025444474071264267,
      0.007120306603610516,
      0.0009732397738844156,
      -0.09857133775949478,
      0.029471509158611298,
      0.1193733736872673,
      0.07239783555269241,
      -0.03853881359100342,
      0.12869153916835785,
      0.03890617564320564,
      0.02133749984204769,
      0.03225450590252876,
      -0.04035874828696251,
      -0.01370773371309042,
      0.01621807925403118,
      -0.03867044672369957,
      0.016407815739512444,
      -0.024078382179141045,
      0.020343603566288948,
      -0.054370034486055374,
      0.0496644601225853,
      -0.08828015625476837,
      0.011773375794291496,
      -0.04504379257559776,
      0.011209988035261631,
      0.00935609731823206,
      -0.04983559250831604,
      0.00874706357717514,
      -0.0006734566995874047,
      0.04284625127911568,
      0.0027823802083730698,
      0.07382117956876755,
      0.09436874836683273,
      -0.03019593469798565,
      0.04891425371170044,
      -0.06499738991260529,
      0.046589445322752,
      -0.0025641440879553556,
      0.0286917332559824,
      -0.004934306256473064,
      -0.04071672633290291,
      -0.009768619202077389,
      -0.038974829018116,
      -0.03709512948989868,
      -0.0071485936641693115,
      -0.03097010776400566,
      0.06603851914405823,
      0.017060868442058563,
      0.02927461639046669,
      0.019399860873818398,
      -0.10414383560419083,
      0.024576952680945396,
      -0.06478814780712128,
      -0.010028061456978321,
      0.12408219277858734,
      0.04955406114459038,
      -0.0884757786989212,
      -0.040614187717437744,
      -0.07951761037111282,
      -0.03391948714852333,
      -0.014351209625601768,
      0.070765919983387,
      -0.053314585238695145,
      0.06360645592212677,
      0.01783548668026924,
      -0.015843048691749573,
      -0.009010556153953075,
      -0.042241450399160385,
      -0.0018935787957161665,
      0.05296076834201813,
      -0.029059331864118576,
      -0.05774012207984924,
      0.047417052090168,
      0.013195786625146866,
      0.0005215186974965036,
      -0.06675182282924652,
      0.020066028460860252,
      0.0023797096218913794,
      -0.011387201957404613,
      0.002784059150144458,
      -0.0344076007604599,
      -0.02189120277762413,
      -0.04947282373905182,
      0.05805530026555061,
      0.0366315059363842,
      -0.056994710117578506,
      -0.016689857468008995,
      -0.010779036208987236,
      0.07365043461322784,
      -0.08721184730529785,
      0.026379643008112907,
      0.007448316551744938,
      -0.055758100003004074,
      -0.013605820946395397,
      0.004374214913696051,
      -0.00253655225969851,
      -0.022236987948417664,
      0.07498322427272797,
      -0.01852734386920929,
      0.06699343770742416,
      0.00044715136755257845,
      -0.0009212077711708844,
      0.010060655884444714,
      0.05720001459121704,
      0.000675626564770937,
      -0.053948480635881424,
      -0.04672776535153389,
      0.009636053815484047,
      0.03291620314121246,
      0.08841972053050995,
      0.02092706598341465,
      0.00842153187841177,
      -0.003966465592384338,
      0.011874421499669552,
      -0.03343379124999046,
      0.03997982293367386,
      0.007701490074396133,
      -0.008145885542035103,
      0.12010366469621658,
      -0.010002203285694122,
      0.012390836142003536,
      0.050900548696517944,
      0.004168761894106865,
      -0.03749975934624672,
      0.05892454832792282,
      0.05064352974295616,
      0.030199754983186722,
      0.0007317485869862139,
      0.004341751337051392,
      0.040305040776729584,
      0.08713153749704361,
      0.0010480702621862292,
      -0.025648208335042,
      -0.02372417226433754,
      -0.004111278336495161,
      -0.02674289233982563,
      0.05925039201974869,
      -0.013789957389235497,
      0.04080216586589813,
      -0.01630358025431633,
      -0.017738953232765198,
      -0.03984038904309273,
      -0.0595056377351284,
      -0.01829654723405838,
      0.005620932672172785,
      -0.05376063287258148,
      -0.044534631073474884,
      -0.12217815965414047,
      0.1287202686071396,
      0.0018915586406365037,
      0.012408528476953506,
      -0.011789161711931229,
      0.0008754670852795243,
      0.04299034923315048,
      -0.1141209602355957,
      -0.019997112452983856,
      -0.018960587680339813,
      -0.03226412460207939,
      -0.00535410875454545,
      -0.028345834463834763,
      -0.026208234950900078,
      0.010388986207544804,
      -0.0453801155090332,
      0.019141534343361855,
      0.055789731442928314,
      0.0046142227947711945,
      -0.01298668421804905,
      0.04685499519109726,
      0.034927066415548325,
      -0.004210354760289192,
      0.008710171096026897,
      0.02218780480325222,
      0.01889997161924839,
      -0.001744189765304327,
      0.013143175281584263,
      0.01883951760828495,
      0.0280318520963192,
      -0.025191113352775574,
      0.060181282460689545,
      -0.06682688742876053,
      0.10583845525979996,
      0.029953209683299065,
      0.02114422246813774,
      0.014871819876134396,
      0.021375827491283417,
      0.015648702159523964,
      0.06593195348978043,
      0.030569253489375114,
      0.03578059375286102,
      0.029083706438541412,
      0.03467433527112007,
      -0.003445689333602786,
      -0.034029293805360794,
      -0.02204768732190132,
      -0.014958328567445278,
      -0.020530331879854202,
      -0.06913748383522034,
      -0.01926673948764801,
      -0.022099191322922707,
      0.021597441285848618,
      -0.03442254662513733,
      0.06537720561027527,
      -0.03704526647925377,
      -0.012733310461044312,
      -0.037160538136959076,
      0.04464997723698616,
      -0.04266994073987007,
      0.01356380246579647,
      -0.08401350677013397,
      -0.032442640513181686,
      -0.05365053564310074,
      -0.005973250605165958,
      -0.05002722516655922,
      -0.023997578769922256,
      0.06991554796695709,
      -0.01316109485924244,
      -0.05228990688920021,
      0.024788880720734596,
      0.05431899055838585,
      -0.02920539490878582,
      -0.018886780366301537,
      -0.019537240266799927,
      -0.0077907326631248,
      -0.0009726998978294432,
      0.0020933060441166162,
      0.11774902045726776,
      -0.000520365487318486,
      -0.009254496544599533,
      -0.0537872314453125,
      0.019620584324002266,
      0.08759616315364838,
      -0.06426563858985901,
      0.011224282905459404,
      0.04952794313430786,
      -0.018864918500185013,
      -0.029400885105133057,
      -0.07752061635255814,
      -0.026369402185082436,
      0.023579075932502747,
      -0.032861631363630295,
      -0.028460385277867317,
      -0.03543311357498169,
      0.019969813525676727,
      -0.02860444411635399,
      -0.026604562997817993,
      -0.06357666850090027,
      -0.02400522492825985,
      -0.004764850251376629,
      0.01997106708586216,
      -0.04801720008254051,
      -0.08620671182870865,
      0.06044991314411163,
      -0.044747576117515564,
      -0.05700436607003212,
      -0.038020167499780655,
      0.04214818775653839,
      -0.077982097864151,
      0.0068390825763344765,
      0.02181895263493061,
      0.02316691167652607,
      -0.025117279961705208,
      -0.07165645062923431,
      -0.050027891993522644,
      0.01781921461224556,
      0.11847322434186935,
      0.02036936581134796,
      -0.03858606889843941,
      -0.03966647759079933,
      -0.02879139594733715,
      0.026150742545723915,
      -0.024649109691381454,
      -0.004801769740879536,
      -0.011547897942364216,
      0.03311910852789879,
      -0.0719192698597908,
      -0.0099566038697958,
      0.030923018231987953,
      -0.029363838955760002,
      -0.04628714546561241,
      0.049548182636499405,
      -0.047333844006061554,
      0.11158300936222076,
      -0.04523690044879913,
      -0.012211505323648453,
      -0.0256104227155447,
      0.06415353715419769,
      0.037650156766176224,
      0.04167919605970383,
      -0.07639724016189575,
      -0.030290592461824417,
      0.035044897347688675,
      -0.0848865732550621,
      0.0026656887494027615,
      -0.033835332840681076,
      -0.031041841953992844,
      0.05723391845822334,
      0.04431788995862007,
      0.04332401603460312,
      -0.019358759745955467,
      0.08119219541549683,
      0.03492790088057518,
      -0.036941271275281906,
      -0.021279996261000633,
      -0.03209689259529114,
      0.0185202956199646,
      -0.04225018247961998,
      0.08361931890249252,
      -0.04685744270682335,
      0.00820886343717575,
      0.009771741926670074,
      -0.0669344812631607,
      0.042103756219148636,
      0.033349327743053436,
      0.0331520140171051,
      0.020655391737818718,
      -0.004815965425223112,
      0.028211159631609917,
      -0.0622703954577446,
      0.022406352683901787,
      -0.02181381545960903,
      -0.0017532018246129155,
      0.018431082367897034,
      -0.014291857369244099,
      0.03404911980032921,
      0.03361346200108528,
      0.03693506866693497,
      -0.07539534568786621,
      0.016335612162947655,
      0.016953561455011368,
      0.022761208936572075,
      -0.021086474880576134,
      0.06886395066976547,
      -0.056243229657411575,
      -0.08891449868679047,
      -0.05067216977477074,
      0.0025185439735651016,
      0.02005190961062908,
      -0.0012676626211032271,
      0.04430422931909561,
      0.029131582006812096,
      -0.06963560730218887,
      -0.03591925650835037,
      0.04210330918431282,
      0.050584081560373306,
      -0.021655740216374397,
      -0.07814036309719086,
      0.08564586192369461,
      -0.026917075738310814,
      0.024745389819145203,
      0.07390350103378296,
      -0.030803825706243515,
      0.0014771816786378622,
      0.04982713982462883,
      -0.004795829765498638,
      0.012167290784418583,
      0.036233969032764435,
      -0.0400843620300293,
      0.015978118404746056,
      0.03334862366318703,
      -0.06002549082040787,
      0.002498524496331811,
      -0.018983077257871628,
      0.055972516536712646,
      0.08654483407735825,
      -0.08051642775535583,
      0.021431775763630867,
      0.005422194488346577,
      0.09035079926252365,
      0.05512578785419464,
      -0.04877765476703644,
      0.013359992764890194,
      0.027460679411888123,
      -0.0006640100036747754,
      0.00971158966422081,
      -0.06810537725687027,
      0.038584161549806595,
      -0.04619107022881508,
      4.629137765732594e-05,
      -0.029467687010765076
    ],
    [
      -0.05411461740732193,
      -0.0012812354834750295,
      0.002032846212387085,
      -0.04293883591890335,
      -0.007260825484991074,
      -0.05854077637195587,
      0.004224792122840881,
      -0.017511088401079178,
      0.006015128921717405,
      -0.046845145523548126,
      -0.01436725351959467,
      -0.02662576362490654,
      0.08948373049497604,
      0.02130289562046528,
      0.0367063470184803,
      0.018249114975333214,
      0.0408126637339592,
      -0.07809705287218094,
      0.015183113515377045,
      0.006847364362329245,
      -0.01699407957494259,
      -0.04142262041568756,
      -0.029886871576309204,
      -0.10859861224889755,
      0.004378444515168667,
      0.012837681919336319,
      0.07298871874809265,
      -0.004667223431169987,
      0.017082232981920242,
      0.050673551857471466,
      0.020892534404993057,
      0.0009442211012355983,
      0.0035485236439853907,
      0.09127591550350189,
      -0.02776739001274109,
      -0.05622987449169159,
      0.03090657666325569,
      0.1000077947974205,
      0.09061973541975021,
      -0.004616023972630501,
      0.05151037126779556,
      0.00715288519859314,
      -0.005896526388823986,
      -0.04940268397331238,
      -0.005545380990952253,
      0.015754075720906258,
      -0.03820992633700371,
      -0.0005229345988482237,
      -0.042924873530864716,
      -0.04092121124267578,
      0.059924107044935226,
      -0.04257642850279808,
      0.012813491746783257,
      -0.0076531535014510155,
      0.04053638130426407,
      0.005223018582910299,
      -0.04439293593168259,
      0.043518222868442535,
      -0.0020070720929652452,
      -0.014406021684408188,
      -0.022625282406806946,
      -0.05698603391647339,
      0.017375126481056213,
      -0.05167895928025246,
      0.06504363566637039,
      -0.010883204638957977,
      -0.0215250663459301,
      0.022144993767142296,
      0.058023516088724136,
      -0.06085686758160591,
      -0.06876274198293686,
      0.0709356814622879,
      -0.10510137677192688,
      0.04210273548960686,
      0.021905403584241867,
      0.012808128260076046,
      0.028903188183903694,
      -0.024652129039168358,
      -0.06974224001169205,
      -0.0018589256796985865,
      0.0027954571414738894,
      0.002141061704605818,
      0.07127685099840164,
      -0.0713036060333252,
      0.010085019282996655,
      -0.04557522386312485,
      0.03348930552601814,
      0.01618574932217598,
      -0.008685728535056114,
      -0.034175146371126175,
      -0.02091831900179386,
      -0.02621285803616047,
      -0.00013542704982683063,
      0.006572082173079252,
      -0.03200743719935417,
      -0.005876762792468071,
      0.02937089465558529,
      0.002705734223127365,
      -0.011179467663168907,
      0.014750426635146141,
      0.021986976265907288,
      -0.05702869966626167,
      -0.001953176222741604,
      -0.04396771267056465,
      -0.004151037894189358,
      0.022180262953042984,
      -0.004005303140729666,
      0.031608257442712784,
      0.027857786044478416,
      0.06884199380874634,
      -0.03750566765666008,
      -0.07896381616592407,
      0.0390741266310215,
      -0.05016385018825531,
      -0.08354350179433823,
      -0.025195416063070297,
      -0.09064347296953201,
      -0.06516412645578384,
      -0.0001274015085073188,
      -0.033850181847810745,
      0.09988640993833542,
      0.018387187272310257,
      -0.031113119795918465,
      -0.054633937776088715,
      0.007732265628874302,
      -0.07815941423177719,
      0.008545654825866222,
      -0.0018296325579285622,
      0.029792381450533867,
      0.00026430553407408297,
      -0.009862624108791351,
      0.018524182960391045,
      0.010992562398314476,
      -0.009895016439259052,
      0.024227244779467583,
      0.014645843766629696,
      -0.04823260381817818,
      0.036882758140563965,
      -0.055090855807065964,
      -0.03530406206846237,
      -0.017789632081985474,
      -0.05366869270801544,
      0.03948211669921875,
      -0.08028050512075424,
      0.020980004221200943,
      0.09216035902500153,
      0.07641924172639847,
      -0.0014179269783198833,
      -0.07188715785741806,
      -0.03218408301472664,
      0.03561297804117203,
      -0.08883906155824661,
      0.09126131236553192,
      -0.01817474700510502,
      0.07982166856527328,
      -0.057532813400030136,
      -0.018850237131118774,
      0.008052736520767212,
      0.03287959471344948,
      0.0333993099629879,
      0.0196632482111454,
      -0.01834028773009777,
      -0.0062353177927434444,
      -0.038316525518894196,
      0.03606976568698883,
      0.007944390177726746,
      -0.013469778001308441,
      -0.05196269229054451,
      0.06629180908203125,
      -0.012176175601780415,
      -0.06576313823461533,
      0.03419956564903259,
      -0.00244649569503963,
      0.058855075389146805,
      0.018783997744321823,
      0.04728556424379349,
      0.033421702682971954,
      -0.03546120226383209,
      0.10742282122373581,
      -0.06926292926073074,
      -0.031221728771924973,
      -0.027751639485359192,
      0.03466618433594704,
      -0.0008018716471269727,
      0.12865282595157623,
      0.09005032479763031,
      -0.006598549894988537,
      -0.14785905182361603,
      -0.005259825382381678,
      0.09563842415809631,
      0.03798715025186539,
      -0.03604374825954437,
      0.051964640617370605,
      -0.05798821523785591,
      -0.08198434859514236,
      0.038790781050920486,
      0.03213929012417793,
      -0.006792591884732246,
      0.03826327994465828,
      0.0271588247269392,
      -0.020840128883719444,
      0.07420738786458969,
      0.006585316266864538,
      0.011212721467018127,
      0.062195055186748505,
      -0.07213976979255676,
      0.053197190165519714,
      -0.001886647311039269,
      -0.006844011601060629,
      0.006553787738084793,
      -0.0832834392786026,
      -0.03293411806225777,
      -0.04720042645931244,
      -0.0500413179397583,
      -0.0022075443994253874,
      0.0029961136169731617,
      -0.08179054409265518,
      -0.012736978940665722,
      -0.009164909832179546,
      0.01659283973276615,
      -0.03067639283835888,
      0.044537708163261414,
      -0.025945328176021576,
      -0.028850719332695007,
      0.03423801437020302,
      0.02080322429537773,
      0.012413178570568562,
      0.06427580118179321,
      0.057123418897390366,
      -0.0057687908411026,
      0.04240049421787262,
      0.0016440233448520303,
      -0.08499019593000412,
      0.022119436413049698,
      0.0864277184009552,
      -0.02054787054657936,
      0.02976285107433796,
      -0.03693476319313049,
      0.030958885326981544,
      -0.04034074395895004,
      0.007485723588615656,
      0.01210850477218628,
      0.022377362474799156,
      0.026721984148025513,
      0.03025888092815876,
      -0.0531516931951046,
      0.007883992977440357,
      0.042021527886390686,
      0.014543728902935982,
      -0.04358627274632454,
      0.01867682673037052,
      -0.08803672343492508,
      -0.06843006610870361,
      0.0009996183216571808,
      0.041320908814668655,
      0.04977453127503395,
      -0.02697589434683323,
      -0.014922226779162884,
      0.037716466933488846,
      0.047056764364242554,
      0.08571654558181763,
      0.060338228940963745,
      0.094759501516819,
      -0.0048203663900494576,
      -0.059552229940891266,
      -0.00425519235432148,
      0.04706694558262825,
      -0.10259552299976349,
      -0.008905013091862202,
      -0.022494720295071602,
      -0.006889081560075283,
      0.0004601614491548389,
      -0.07646956294775009,
      -0.08432987332344055,
      0.07174579054117203,
      -0.06035887449979782,
      -0.03432371839880943,
      -0.028532428666949272,
      0.0011295115109533072,
      0.08543121814727783,
      0.03094586543738842,
      0.025580137968063354,
      -0.018462946638464928,
      -0.02166668139398098,
      -0.017569664865732193,
      -0.061412401497364044,
      -0.0426335446536541,
      -0.03545538708567619,
      0.03903070464730263,
      0.04785364866256714,
      -0.01687239482998848,
      -0.013561870902776718,
      -0.07531114667654037,
      0.02611304074525833,
      0.10613702237606049,
      0.020378077402710915,
      0.08834926784038544,
      0.04320479929447174,
      -0.019895799458026886,
      0.056701041758060455,
      0.03914012387394905,
      -0.026283878833055496,
      -0.02192060649394989,
      -0.03389695659279823,
      -0.06827245652675629,
      -0.029362043365836143,
      -0.008241706527769566,
      -0.04987843334674835,
      0.026355305686593056,
      0.006112920120358467,
      0.015489159151911736,
      0.006096416153013706,
      0.02047889120876789,
      0.09897623211145401,
      0.008783145807683468,
      -0.049256954342126846,
      0.017109984531998634,
      0.05956864356994629,
      -0.040797531604766846,
      0.03842304274439812,
      -0.00861408468335867,
      -0.029477166011929512,
      -0.06582136452198029,
      -0.036124441772699356,
      -0.036405403167009354,
      -0.08046027272939682,
      0.05661961808800697,
      -0.006529857404530048,
      -0.06181788817048073,
      0.04060547798871994,
      0.07482124865055084,
      -0.030994541943073273,
      -0.0673837810754776,
      0.008365877903997898,
      0.012178990058600903,
      0.05976419895887375,
      -0.07519318163394928,
      -0.01680714637041092,
      -0.01820654794573784,
      0.019197218120098114,
      -0.0018602642230689526,
      0.012384969741106033,
      0.010529152117669582,
      -0.08623919636011124,
      -0.036727748811244965,
      0.08929497003555298,
      0.07742919772863388,
      0.003648109268397093,
      0.02153071016073227,
      0.009226091206073761,
      -0.01735346019268036,
      0.06474216282367706,
      0.01353426929563284,
      0.04273804649710655,
      0.0012571378611028194,
      -0.00724282069131732,
      0.020782602950930595,
      0.07416015863418579,
      0.05523527041077614,
      0.0385691374540329,
      0.11219174414873123,
      0.027399230748414993,
      0.05803615227341652,
      0.10150594264268875,
      0.036448266357183456,
      -0.06558897346258163,
      -0.02976362593472004,
      0.036339934915304184,
      0.046232547610998154,
      0.013469655998051167,
      -0.039607807993888855,
      -0.031297169625759125,
      0.02891802042722702,
      0.0011660393793135881,
      0.00839222501963377,
      0.07817613333463669,
      0.08067148923873901,
      0.10714883357286453,
      0.09101974964141846,
      0.0040513514541089535,
      -0.036538779735565186,
      0.061759572476148605,
      -0.0852830782532692,
      0.056182827800512314,
      0.021157609298825264,
      0.010898442938923836,
      -0.03590358421206474,
      -0.039605237543582916,
      0.04433245584368706,
      -0.0814092829823494,
      0.020128320902585983,
      -0.06829472631216049,
      0.029008233919739723,
      -0.013787292875349522,
      -0.023171711713075638,
      -0.009204507805407047,
      0.021798811852931976,
      -0.012744676321744919,
      0.07233568280935287,
      0.02336905337870121,
      -0.08415339142084122,
      0.0018144773785024881,
      0.0014902271796017885,
      -0.0024413815699517727,
      0.038315437734127045,
      -0.023416494950652122,
      -0.03438311070203781,
      -0.02382151037454605,
      -0.0950724333524704,
      -0.048259422183036804,
      -0.0646202340722084,
      -0.01761317066848278,
      -0.0641198679804802,
      -0.005328979808837175,
      -0.03770243749022484,
      0.017358390614390373,
      0.028926685452461243,
      -0.025027738884091377,
      0.012437387369573116,
      -0.041803259402513504,
      0.016467155888676643,
      -0.03131143003702164,
      -0.024051526561379433,
      0.04228418692946434,
      0.04577624797821045,
      0.0015081032179296017,
      0.07148091495037079,
      0.05414843186736107,
      0.028927968814969063,
      0.033860187977552414,
      0.055767785757780075,
      0.007142581511288881,
      -0.008225144818425179,
      0.04550620913505554,
      0.03890642151236534,
      -0.04019995406270027,
      0.04845184460282326,
      0.002450651256367564,
      0.01361535582691431,
      0.048919927328825,
      0.04810314625501633,
      0.0260472409427166,
      -0.015050811693072319,
      -0.040305428206920624,
      0.004506715573370457,
      -0.009647651575505733,
      -0.04431529343128204,
      0.00029111033654771745,
      0.08672132343053818,
      -0.006732871290296316,
      -0.08645346015691757,
      -0.03800006955862045,
      -0.0335608534514904,
      -0.007671997416764498,
      -0.026656759902834892,
      -0.011244753375649452,
      -0.006868073716759682,
      0.0004661520943045616,
      0.06612586230039597,
      -0.010180761106312275,
      -0.05950901657342911,
      0.04268696531653404,
      -0.061979543417692184,
      -0.062411557883024216,
      0.03995008021593094,
      -0.07322246581315994,
      -0.02748839184641838,
      -0.04559057578444481,
      -0.013643823564052582,
      0.016733216121792793,
      -0.05634501948952675,
      0.011990309692919254,
      0.03862687200307846,
      0.033159978687763214,
      0.07821658998727798,
      0.027774261310696602,
      0.016706382855772972,
      -0.05239193141460419,
      -0.03769152611494064,
      0.06795018911361694,
      -0.009035558439791203,
      0.01575055718421936,
      0.010344731621444225,
      -0.010904979892075062,
      0.0717054232954979,
      -0.06772571802139282,
      0.07885639369487762,
      0.011640163138508797,
      -0.05957864224910736,
      0.06624352931976318,
      0.01919431984424591,
      -0.039830438792705536,
      0.017788825556635857,
      -0.030103910714387894,
      -0.040489375591278076,
      -0.07407552003860474,
      -0.014086379669606686,
      0.0016870902618393302,
      -0.025050250813364983,
      0.016795456409454346,
      -0.005359640344977379,
      -0.08208087086677551,
      0.026219066232442856
    ],
    [
      0.0005180035368539393,
      0.014322544448077679,
      -0.04288458079099655,
      0.04169487580657005,
      0.02695208601653576,
      0.013172452338039875,
      -0.06560840457677841,
      0.046651486307382584,
      0.061970122158527374,
      -0.039512548595666885,
      0.006922038272023201,
      -0.026278873905539513,
      0.10176633298397064,
      -0.042606841772794724,
      -0.020861869677901268,
      -0.02642967738211155,
      0.030062751844525337,
      -0.06034558638930321,
      0.005051260348409414,
      -0.004652728792279959,
      -0.0411251075565815,
      -0.00723428837954998,
      0.012479658238589764,
      0.021022964268922806,
      0.008889306336641312,
      0.021363113075494766,
      0.0044340030290186405,
      -0.0494680218398571,
      0.010631960816681385,
      0.030715104192495346,
      0.024405566975474358,
      0.05647522956132889,
      0.07030080258846283,
      0.008701139129698277,
      -0.02409525215625763,
      0.08830912411212921,
      -0.021442968398332596,
      -0.026841983199119568,
      0.01243160106241703,
      0.01790272258222103,
      -0.013093697838485241,
      0.054264627397060394,
      -0.06191975995898247,
      0.06753549724817276,
      -0.0230459775775671,
      -0.041386544704437256,
      -0.09158341586589813,
      0.04837290570139885,
      -0.01374876219779253,
      0.04694034531712532,
      0.009211908094584942,
      0.056608881801366806,
      0.04328415170311928,
      -0.07923275977373123,
      -0.010933860205113888,
      -0.004567317198961973,
      0.05724542215466499,
      0.02943449653685093,
      -0.03956673666834831,
      0.10295014828443527,
      -0.02854708395898342,
      0.05598444119095802,
      0.1058647632598877,
      0.09505421668291092,
      -0.004859934560954571,
      -0.0013480880297720432,
      0.027685146778821945,
      -0.00731300376355648,
      0.047128986567258835,
      0.03289271518588066,
      -0.05498793348670006,
      -0.02130662091076374,
      -0.09402888268232346,
      0.04726594313979149,
      -0.04281393066048622,
      -0.06645925343036652,
      0.03829219937324524,
      0.021645283326506615,
      0.015666987746953964,
      0.055599141865968704,
      0.0651257336139679,
      0.009640229865908623,
      0.007867942564189434,
      0.02363458089530468,
      -0.008972624316811562,
      0.0056760720908641815,
      0.07030162215232849,
      0.026217302307486534,
      -0.04783741384744644,
      -0.0073904069140553474,
      0.0007679998525418341,
      0.020141182467341423,
      0.023010535165667534,
      0.06911411881446838,
      -0.004896649159491062,
      0.018956048414111137,
      -0.04758816957473755,
      -0.01933746039867401,
      -0.001161006628535688,
      -0.03219353035092354,
      0.054621487855911255,
      -0.04199093580245972,
      -0.032828427851200104,
      -0.014937013387680054,
      -0.019913550466299057,
      0.014597413130104542,
      0.06733235716819763,
      -0.03346242383122444,
      -0.0994766354560852,
      -0.03822090104222298,
      0.07900702953338623,
      0.03275370970368385,
      -0.005991843994706869,
      -3.4212757782370318e-06,
      0.055572621524333954,
      -0.00639716349542141,
      -0.0247760321944952,
      0.0024420120753347874,
      0.007076607551425695,
      -0.020960655063390732,
      -0.03192751482129097,
      0.011092872358858585,
      -0.06870140880346298,
      0.07910141348838806,
      0.022749988362193108,
      0.0391385592520237,
      0.021857215091586113,
      0.038687814027071,
      0.027932194992899895,
      0.052968960255384445,
      -0.029554618522524834,
      -0.043961092829704285,
      0.014786619693040848,
      0.05131564289331436,
      -0.004713557660579681,
      0.02595554105937481,
      -0.02471776306629181,
      -0.03549942001700401,
      -0.023412305861711502,
      0.07950741797685623,
      -0.0629534050822258,
      0.03269549459218979,
      -0.014331309124827385,
      0.0010465161176398396,
      0.025060852989554405,
      0.009967010468244553,
      -0.04448499158024788,
      -0.09246786683797836,
      0.03488970175385475,
      -0.013995370827615261,
      0.0023906102869659662,
      -0.037496160715818405,
      0.05345969647169113,
      0.04818607121706009,
      -0.02839701622724533,
      3.0275530207291013e-06,
      0.08211224526166916,
      -0.012433845549821854,
      0.005240183789283037,
      0.060406386852264404,
      -0.05216899886727333,
      0.06428075581789017,
      0.017391573637723923,
      0.022437049075961113,
      0.07259714603424072,
      -0.020655546337366104,
      0.00434617605060339,
      0.008416838012635708,
      0.07206934690475464,
      0.056740302592515945,
      -0.013760530389845371,
      -0.024436496198177338,
      0.10200534015893936,
      0.048804353922605515,
      -0.0786958783864975,
      0.0030922568403184414,
      0.011374312452971935,
      0.017615169286727905,
      -0.041194453835487366,
      -0.0835360512137413,
      0.06152807176113129,
      0.023782314732670784,
      0.035225026309490204,
      -0.007968024350702763,
      -0.00731508806347847,
      0.025332916527986526,
      -0.020611930638551712,
      0.05099755898118019,
      0.014621684327721596,
      -0.04006973281502724,
      0.03535489737987518,
      -0.09239748120307922,
      0.023786915466189384,
      -0.09034854173660278,
      0.01929675042629242,
      -0.11377544701099396,
      -0.001503082923591137,
      0.05626356601715088,
      0.00672207260504365,
      0.018260950222611427,
      0.025813423097133636,
      -0.06332197785377502,
      0.04188236594200134,
      -0.01516004092991352,
      0.028398144990205765,
      0.04274464771151543,
      0.014744971878826618,
      -0.02243325300514698,
      0.00929508451372385,
      -0.06037105992436409,
      -0.05372566357254982,
      0.00283308164216578,
      -0.09062045812606812,
      -0.03488709032535553,
      -0.014843631535768509,
      -0.044526610523462296,
      0.015241016633808613,
      0.07855106145143509,
      -0.03816850110888481,
      -0.04869135096669197,
      -0.012471330352127552,
      -0.020006736740469933,
      -0.015754668042063713,
      -0.025797268375754356,
      -0.01054009236395359,
      0.06142222881317139,
      0.07693018764257431,
      0.03957999125123024,
      0.0006326112197712064,
      -0.05020047724246979,
      -0.05650388076901436,
      -0.005063808057457209,
      0.07147053629159927,
      0.0816221684217453,
      -0.04328048974275589,
      0.008034334518015385,
      -0.027204321697354317,
      0.03251665458083153,
      -0.03015427477657795,
      -0.06706517189741135,
      0.006029139272868633,
      -0.027768895030021667,
      -0.018662545830011368,
      -0.0020701675675809383,
      -0.023997928947210312,
      0.016714025288820267,
      0.0248333178460598,
      -0.07380610704421997,
      0.015375648625195026,
      -0.03814878314733505,
      0.019469883292913437,
      -0.04009401053190231,
      0.009487058036029339,
      0.00945670809596777,
      0.07584547996520996,
      -0.026725221425294876,
      0.017705250531435013,
      0.0783645287156105,
      0.04255787655711174,
      0.046441562473773956,
      0.04960646107792854,
      0.060781631618738174,
      -0.03334083780646324,
      0.007149691227823496,
      0.06871583312749863,
      -0.0007716564578004181,
      -0.01794210448861122,
      0.07391854375600815,
      -0.1430198699235916,
      -0.07204465568065643,
      0.0057359361089766026,
      -0.057717449963092804,
      0.034329477697610855,
      -0.08276747167110443,
      -0.07922305911779404,
      0.0220244862139225,
      0.0004153175977990031,
      0.028580090031027794,
      0.033765759319067,
      0.004531200975179672,
      -0.09882953017950058,
      -0.05187079310417175,
      0.007565859705209732,
      -0.09587632119655609,
      -0.02277291752398014,
      0.04123831167817116,
      0.007180888205766678,
      -0.041139356791973114,
      0.0011587436310946941,
      -0.05621388554573059,
      -0.0219022948294878,
      0.016847865656018257,
      -0.014675199054181576,
      0.02876204624772072,
      -0.04647420346736908,
      -0.011602733284235,
      -0.05874376371502876,
      0.045197613537311554,
      0.011068864725530148,
      -0.042073965072631836,
      0.05120521038770676,
      0.01365907397121191,
      0.04562407732009888,
      -0.0668366476893425,
      0.0016848568338900805,
      0.038457345217466354,
      0.022744501009583473,
      0.006451100576668978,
      0.0030032615177333355,
      0.04420693591237068,
      -0.069301076233387,
      0.006365856621414423,
      0.026965737342834473,
      -0.09663853794336319,
      -0.00047867954708635807,
      -0.0468364916741848,
      -0.008451067842543125,
      -0.05314614251255989,
      0.03681785240769386,
      0.049835216253995895,
      0.029444731771945953,
      -0.03990150988101959,
      -0.048650939017534256,
      -0.08030746877193451,
      0.05017618462443352,
      0.006553416606038809,
      0.06366594135761261,
      0.03362084552645683,
      -0.0018177052261307836,
      0.061134710907936096,
      0.08579649031162262,
      -0.0023136711679399014,
      0.017451856285333633,
      0.05032239481806755,
      0.11301283538341522,
      -0.06518425047397614,
      0.012949490919709206,
      0.0027608401142060757,
      0.04101265221834183,
      0.053793203085660934,
      -0.03464692458510399,
      0.04230450466275215,
      -0.04566340520977974,
      -0.07246457040309906,
      0.02578457072377205,
      0.013598988763988018,
      0.023950185626745224,
      -0.029378224164247513,
      -0.05529572814702988,
      -0.02539696730673313,
      -0.05620922893285751,
      0.01145460456609726,
      0.084065742790699,
      0.002815165091305971,
      0.03290191665291786,
      0.018666887655854225,
      0.07019522041082382,
      0.021433698013424873,
      0.014743447303771973,
      0.037882234901189804,
      -0.04634475335478783,
      -0.11328712105751038,
      0.0034494386054575443,
      0.05866609886288643,
      -0.010780436918139458,
      -0.035764291882514954,
      0.08047660440206528,
      -0.0019334818935021758,
      -0.012377679347991943,
      0.018188953399658203,
      -0.011008987203240395,
      -0.013549653813242912,
      0.08067989349365234,
      0.018655888736248016,
      0.03854505345225334,
      -0.036652375012636185,
      -0.05660374462604523,
      0.04778358340263367,
      -0.006846928503364325,
      -0.008179917931556702,
      -0.027991842478513718,
      0.020853307098150253,
      0.03137580677866936,
      0.044696442782878876,
      0.05117790028452873,
      -0.023827245458960533,
      0.02713131345808506,
      0.025372762233018875,
      0.02664516121149063,
      -0.048592459410429,
      -0.01139871682971716,
      0.016399959102272987,
      -0.07318191230297089,
      -0.02180100977420807,
      0.03473774343729019,
      0.014110483229160309,
      0.01821710914373398,
      0.03564208745956421,
      0.04872538894414902,
      -0.040014687925577164,
      0.10080978274345398,
      -0.028739921748638153,
      -0.0073624104261398315,
      0.0019613096956163645,
      -0.0021563456393778324,
      0.00571066839620471,
      0.02352144755423069,
      0.03236793726682663,
      0.0536339171230793,
      0.04051056504249573,
      0.06203121691942215,
      0.04206624999642372,
      0.034411538392305374,
      0.0985225960612297,
      -0.008540439419448376,
      0.004055028781294823,
      -0.037245020270347595,
      -0.05384302884340286,
      0.0740414559841156,
      0.03625590726733208,
      0.007758369669318199,
      -0.03826013579964638,
      0.10284789651632309,
      -0.05416647344827652,
      -0.05700879171490669,
      -0.016032395884394646,
      0.01211724802851677,
      0.007504450622946024,
      0.03139326721429825,
      -0.060307104140520096,
      0.007957694120705128,
      0.027865620329976082,
      -0.032658208161592484,
      0.0707598403096199,
      -0.01920042373239994,
      0.08451829105615616,
      0.07640041410923004,
      0.008211852051317692,
      0.018197359517216682,
      0.015760548412799835,
      -0.040655482560396194,
      -0.07478275150060654,
      0.031244970858097076,
      0.006200455594807863,
      -0.024573100730776787,
      0.005902524571865797,
      0.043238282203674316,
      0.011101262643933296,
      0.021454956382513046,
      -0.033101245760917664,
      -0.030757276341319084,
      -0.015585845336318016,
      -0.00097762665245682,
      -0.08314339071512222,
      -0.006715878844261169,
      -0.1228102520108223,
      -0.03151792287826538,
      -0.034433938562870026,
      -0.014096761122345924,
      0.04911773279309273,
      -0.028369298204779625,
      0.058500491082668304,
      0.022002585232257843,
      -0.01902853138744831,
      -0.01257296558469534,
      0.040564753115177155,
      0.06450062990188599,
      0.0779256746172905,
      -0.016591766849160194,
      0.11372324824333191,
      -0.010299074463546276,
      -0.011151417158544064,
      0.019147850573062897,
      -0.022020690143108368,
      -0.02071903459727764,
      0.039043959230184555,
      0.07478031516075134,
      0.02858497016131878,
      -0.02349504828453064,
      0.001905243145301938,
      0.07905159890651703,
      0.02888120897114277,
      -0.022895721718668938,
      -0.046528156846761703,
      -0.014978671446442604,
      -0.004068216774612665,
      0.12890885770320892,
      -0.03623887524008751,
      0.02649340219795704,
      0.03962608799338341,
      0.025548450648784637,
      0.04503083974123001,
      0.002364777959883213,
      -0.08467350155115128,
      -0.03157183900475502,
      -0.01649302802979946,
      0.02220446802675724,
      0.06600470840930939,
      -0.03859240934252739,
      -0.0020384355448186398,
      0.0020394385792315006,
      -0.01768895797431469,
      -0.05693994089961052
    ],
    [
      0.016550926491618156,
      0.059178534895181656,
      0.006030335556715727,
      0.02665218897163868,
      -0.05097592622041702,
      -0.06624125689268112,
      0.03676658496260643,
      0.0494929663836956,
      0.003134828293696046,
      -0.055627863854169846,
      -0.019314372912049294,
      -0.10389696061611176,
      0.04106097295880318,
      -0.0026495836209505796,
      -0.0600472018122673,
      0.013388263992965221,
      -0.023368673399090767,
      0.027560394257307053,
      0.0412735790014267,
      0.011399764567613602,
      0.05999794602394104,
      0.014288082718849182,
      -0.04166433960199356,
      -0.04034203663468361,
      -0.01820308342576027,
      0.05519823729991913,
      0.009307660162448883,
      0.0031002499163150787,
      0.028912661597132683,
      0.031055103987455368,
      0.01654161512851715,
      0.007313838694244623,
      0.032082147896289825,
      0.019017713144421577,
      -0.03282136842608452,
      0.03851819410920143,
      -0.11915700882673264,
      -0.026550646871328354,
      0.004514089785516262,
      0.056604377925395966,
      -0.00467020133510232,
      -0.023807350546121597,
      0.025154121220111847,
      -0.002533021615818143,
      0.023301541805267334,
      0.0055254194885492325,
      -0.053910497575998306,
      -0.005870378576219082,
      -0.0041785589419305325,
      -0.05328448489308357,
      -0.0025392991956323385,
      0.00564737431704998,
      -0.04820777475833893,
      0.04366380348801613,
      -0.048574257642030716,
      0.11495397984981537,
      0.02893947623670101,
      0.019298158586025238,
      0.002975239884108305,
      -0.07666302472352982,
      -0.035887736827135086,
      0.0958922728896141,
      -0.02559983916580677,
      0.04858019948005676,
      0.008044944144785404,
      0.015675604343414307,
      0.03233438730239868,
      -0.04682557284832001,
      0.047391198575496674,
      -0.005099417641758919,
      -0.02652452513575554,
      -0.003451411845162511,
      -0.022305211052298546,
      0.02045011706650257,
      -0.02081376500427723,
      0.033327601850032806,
      0.04166363179683685,
      0.04124327003955841,
      0.011508206836879253,
      0.02317449450492859,
      -0.0030029925983399153,
      -0.01837186887860298,
      0.002617801306769252,
      0.003931796178221703,
      0.09107250720262527,
      -0.03523455187678337,
      0.012939893640577793,
      0.038716234266757965,
      0.010678346268832684,
      0.04175123572349548,
      -0.010645474307239056,
      -0.013877233490347862,
      -0.07589255273342133,
      -0.04770778864622116,
      0.004130367189645767,
      -0.0738409087061882,
      0.03575287014245987,
      -0.06969845294952393,
      0.07609175890684128,
      0.038277894258499146,
      -0.03848135098814964,
      -0.05539211258292198,
      0.06803665310144424,
      0.026664750650525093,
      -0.002949834568426013,
      -0.0700438842177391,
      0.0037412545643746853,
      0.02617519535124302,
      -0.04367627575993538,
      0.07004668563604355,
      -0.02572781965136528,
      -0.008895021863281727,
      -0.06405020505189896,
      -0.07257474213838577,
      -0.011150786653161049,
      0.008735792711377144,
      -0.03142192214727402,
      -0.05175986886024475,
      0.07247653603553772,
      -0.0368981659412384,
      -0.003702027490362525,
      -0.0534452423453331,
      -0.003557761199772358,
      0.007249093614518642,
      -0.01531011052429676,
      -0.021429019048810005,
      -0.038993723690509796,
      0.06618734449148178,
      0.010952415876090527,
      -0.035838257521390915,
      -0.07794591039419174,
      0.03315719962120056,
      -0.02048475295305252,
      0.05674602836370468,
      0.037181306630373,
      0.029927933588624,
      -0.07243455201387405,
      0.08021185547113419,
      0.016284411773085594,
      -0.008329634554684162,
      0.0027607691008597612,
      0.00013052973372396082,
      -0.004467900842428207,
      -0.1005767434835434,
      -0.008275497704744339,
      -0.055368900299072266,
      -0.10541366785764694,
      0.04614328220486641,
      -0.0037545606028288603,
      -0.0019342456944286823,
      0.03558482229709625,
      -0.023281365633010864,
      0.015330999158322811,
      -0.029177816584706306,
      0.025508277118206024,
      0.016434146091341972,
      0.04111012816429138,
      -0.034184180200099945,
      0.006918676663190126,
      -0.030195089057087898,
      0.09988638758659363,
      -0.04273822158575058,
      -0.054528769105672836,
      0.010038649663329124,
      0.037946030497550964,
      -0.015516683459281921,
      -0.0009042549063451588,
      -0.016873473301529884,
      0.03644239529967308,
      0.0546128936111927,
      0.022899342700839043,
      -0.02579314634203911,
      0.027331022545695305,
      -0.03971240669488907,
      0.040348369628190994,
      0.027476919814944267,
      -0.03632992133498192,
      -0.04047142341732979,
      -0.0606975294649601,
      -0.038314513862133026,
      -0.007878703996539116,
      -0.018674951046705246,
      0.020535647869110107,
      0.0033926060423254967,
      -0.028080586344003677,
      0.01570938341319561,
      -0.04806651175022125,
      0.05343809723854065,
      -0.030924633145332336,
      0.017619699239730835,
      -0.015095125883817673,
      -0.05018942058086395,
      0.03707506135106087,
      0.030435292050242424,
      0.04217596352100372,
      0.00169136724434793,
      0.1430273950099945,
      -0.012882517650723457,
      0.00465652858838439,
      0.029896704480051994,
      -0.011982742697000504,
      -0.009508626535534859,
      0.08512702584266663,
      -0.054629210382699966,
      -0.0040560257621109486,
      0.048816390335559845,
      -0.0014663564506918192,
      -0.010655908845365047,
      -0.029921313747763634,
      -0.03236070275306702,
      -0.045322783291339874,
      0.015554632991552353,
      -0.0006256329943425953,
      0.015332077629864216,
      -0.05857280269265175,
      -0.01926027238368988,
      -0.07159632444381714,
      0.08586902171373367,
      0.0024735850747674704,
      -0.022910544648766518,
      0.023680390790104866,
      -0.003196348436176777,
      -0.09443625062704086,
      -0.047819480299949646,
      -0.052331119775772095,
      0.026173332706093788,
      -0.009821951389312744,
      -0.016526130959391594,
      -0.015332717448472977,
      -0.01850477047264576,
      0.016408396884799004,
      0.025231394916772842,
      0.009885212406516075,
      0.006971490103751421,
      -0.05106772109866142,
      -0.0009254650212824345,
      0.011894329451024532,
      -0.044402334839105606,
      -0.05574408173561096,
      0.017702793702483177,
      -0.02923506125807762,
      -0.031713198870420456,
      -0.034008678048849106,
      -0.050993114709854126,
      0.03788422420620918,
      -0.014557918533682823,
      -0.06466643512248993,
      0.00926246028393507,
      -0.03226582333445549,
      -0.02391505241394043,
      -0.008076119236648083,
      0.009159913286566734,
      -0.07775678485631943,
      -0.022966137155890465,
      0.0351920947432518,
      -0.03551992028951645,
      0.08673889189958572,
      -0.056524377316236496,
      -0.0306497011333704,
      -0.003085481934249401,
      0.021266169846057892,
      -0.054007142782211304,
      0.0741964727640152,
      0.0627625584602356,
      -0.02075587958097458,
      -0.016593031585216522,
      0.01148965023458004,
      -0.041728805750608444,
      -0.03749801591038704,
      0.017301859334111214,
      0.10472758859395981,
      -0.016256365925073624,
      0.037856023758649826,
      0.02895629033446312,
      -0.021609148010611534,
      -0.0030960675794631243,
      -0.09203380346298218,
      -0.020839931443333626,
      0.1092190146446228,
      -0.04830440133810043,
      -0.018974443897604942,
      0.0570668987929821,
      -0.033271580934524536,
      -0.03033272922039032,
      0.03372010588645935,
      0.04042601212859154,
      -0.014874108135700226,
      -0.05622923746705055,
      0.0306021049618721,
      0.009188276715576649,
      0.04303764924407005,
      0.022791745141148567,
      -0.09415464103221893,
      -0.04602302238345146,
      0.05543963611125946,
      0.018451621755957603,
      -0.025962337851524353,
      -0.005532225593924522,
      -0.007420472335070372,
      0.05017870292067528,
      -0.025380944833159447,
      -0.006287472788244486,
      0.0037800087593495846,
      -0.024475282058119774,
      0.06850166618824005,
      0.08035911619663239,
      -0.004379097372293472,
      -0.03481655940413475,
      0.007978097535669804,
      -0.025628112256526947,
      0.06112685427069664,
      -0.06475447118282318,
      0.025248758494853973,
      0.033730071038007736,
      -0.005750579293817282,
      -0.011670971289277077,
      0.05463843047618866,
      0.023237721994519234,
      -0.01127541996538639,
      -0.0433940514922142,
      0.03169707953929901,
      -0.024723511189222336,
      -0.01568530686199665,
      0.03863026574254036,
      0.027907095849514008,
      -0.020427539944648743,
      -0.035419490188360214,
      0.011857500299811363,
      -0.10187377035617828,
      -0.04488246142864227,
      -0.0062651303596794605,
      -0.0015874773962423205,
      -0.0051449318416416645,
      0.0013010174734517932,
      0.04189428314566612,
      0.01536101009696722,
      -0.015561729669570923,
      -0.13340885937213898,
      0.09414409101009369,
      0.11856167763471603,
      0.04828936979174614,
      -0.06748506426811218,
      0.030724961310625076,
      -0.03323548287153244,
      -0.022710315883159637,
      0.016578434035182,
      -0.0001352170656900853,
      -0.06155414506793022,
      0.031042909249663353,
      -0.008509170264005661,
      0.033248171210289,
      0.03168119490146637,
      -0.0024045458994805813,
      -0.001105757080949843,
      -0.0017747838282957673,
      0.03015771321952343,
      -0.019295817241072655,
      0.04691855236887932,
      0.053870055824518204,
      -0.027233315631747246,
      -0.003802740480750799,
      0.013762116432189941,
      -0.009181230328977108,
      -0.033311568200588226,
      -0.07870712131261826,
      0.06069822236895561,
      0.024806536734104156,
      -0.08976076543331146,
      -0.018694743514060974,
      0.0511547289788723,
      0.06205524876713753,
      -0.02075096406042576,
      -0.035108938813209534,
      0.06884922087192535,
      -0.02239708974957466,
      0.08655917644500732,
      0.028862275183200836,
      0.018358811736106873,
      -0.07465662062168121,
      0.0132150212302804,
      0.04170505329966545,
      0.038315340876579285,
      0.0496954545378685,
      0.08143389225006104,
      -0.0008139919373206794,
      -0.016297420486807823,
      0.0029685257468372583,
      0.01813027448952198,
      0.00863632932305336,
      0.0717601329088211,
      0.0291043259203434,
      0.04438750818371773,
      -0.033668890595436096,
      0.05020641162991524,
      0.05008699744939804,
      0.0174512080848217,
      -0.020735520869493484,
      -0.012881943956017494,
      0.03338500112295151,
      -0.07887502759695053,
      -0.044688500463962555,
      0.02497551217675209,
      0.010998097248375416,
      0.009735158644616604,
      0.01142911147326231,
      -0.006419509649276733,
      0.04048565402626991,
      0.0038984608836472034,
      -0.009193209931254387,
      -0.019100049510598183,
      -0.012018397450447083,
      0.006448565516620874,
      -0.03225545212626457,
      0.017392598092556,
      0.0348401814699173,
      -0.031180644407868385,
      -0.01072497759014368,
      0.026805661618709564,
      0.06091763451695442,
      -0.026148127391934395,
      0.14699508249759674,
      0.10017721354961395,
      -0.07542391121387482,
      0.01033034548163414,
      -0.0394483357667923,
      0.043583329766988754,
      0.03257773444056511,
      -0.013898337259888649,
      -0.04084330424666405,
      0.005053411703556776,
      0.01629827730357647,
      -0.06312122941017151,
      0.007074803579598665,
      0.021054761484265327,
      0.02308502234518528,
      0.03024536930024624,
      0.08581646531820297,
      0.07565328478813171,
      0.0032479623332619667,
      0.058329902589321136,
      -0.0028360693249851465,
      0.017055286094546318,
      0.04987107589840889,
      0.016170674934983253,
      0.009126252494752407,
      -0.023842643946409225,
      -0.025818772614002228,
      0.0020528172608464956,
      0.0009832208743318915,
      -0.028616324067115784,
      -0.09818831831216812,
      0.058952782303094864,
      -0.04888664186000824,
      0.027288490906357765,
      0.00023057733778841794,
      0.055406372994184494,
      -0.011194661259651184,
      -0.03181344270706177,
      0.06849280744791031,
      -0.04169410094618797,
      -0.0021367627196013927,
      -0.0237303264439106,
      -0.006001379806548357,
      -0.038284216076135635,
      -0.009204204194247723,
      -0.07352376729249954,
      0.05191401019692421,
      0.02051975019276142,
      0.006033351644873619,
      -0.008806987665593624,
      0.03568464517593384,
      -0.013905328698456287,
      -0.06326348334550858,
      0.018358523026108742,
      0.056592270731925964,
      0.04774566367268562,
      -0.010207120329141617,
      -0.04528383910655975,
      -0.026972200721502304,
      0.01949397660791874,
      0.010573930107057095,
      0.06072283536195755,
      -0.010439577512443066,
      0.0034938380122184753,
      -0.02491585724055767,
      0.020596740767359734,
      -0.10650128126144409,
      0.07142722606658936,
      -0.0337614007294178,
      0.014869383536279202,
      -0.019764240831136703,
      0.015269058756530285,
      0.016986165195703506,
      -0.026552466675639153,
      -0.08047734200954437,
      0.03789296746253967,
      -0.03145081177353859,
      -0.03144439682364464,
      -0.018516048789024353,
      0.015150382183492184,
      0.026202600449323654,
      0.06422243267297745,
      0.04060029238462448
    ],
    [
      -0.029389850795269012,
      0.02676197700202465,
      0.07543712854385376,
      0.010989271104335785,
      -0.05969928950071335,
      -0.03953462839126587,
      -0.06628375500440598,
      0.05518350005149841,
      0.002715611830353737,
      -0.011206812225282192,
      0.033674631267786026,
      0.06557679176330566,
      -0.00013634706556331366,
      0.11661144345998764,
      -0.061571601778268814,
      -0.01090796198695898,
      0.005420164670795202,
      0.019883904606103897,
      -0.01747916266322136,
      -0.008936486206948757,
      0.023111028596758842,
      0.028239721432328224,
      -0.04070807993412018,
      -0.04017503559589386,
      0.001141315558925271,
      0.06469323486089706,
      -0.0402647964656353,
      -0.03524905815720558,
      0.01904318667948246,
      0.0032363019417971373,
      0.008164658211171627,
      0.02236776426434517,
      -0.01283479854464531,
      -0.047280557453632355,
      -0.048564616590738297,
      -0.016851484775543213,
      0.008058290928602219,
      -0.018457813188433647,
      0.018216123804450035,
      0.049335431307554245,
      -0.04606224223971367,
      0.053370773792266846,
      0.07116742432117462,
      0.007966171018779278,
      0.08143412321805954,
      0.022364284843206406,
      -0.06616585701704025,
      -0.04033367708325386,
      -0.052579622715711594,
      -0.07027941942214966,
      0.06696001440286636,
      0.03191956877708435,
      -0.0040068053640425205,
      -0.05755271017551422,
      0.03873537853360176,
      0.03076700121164322,
      0.04828759655356407,
      0.01688523218035698,
      -0.020495161414146423,
      0.055048611015081406,
      -0.00463235005736351,
      0.0477726049721241,
      0.027783947065472603,
      -0.03941301628947258,
      0.02541697397828102,
      -0.04535325989127159,
      0.01261342316865921,
      -0.01557720173150301,
      -0.01894989423453808,
      0.031169096007943153,
      0.023149989545345306,
      -0.05681705102324486,
      -0.02171953208744526,
      0.03861953690648079,
      0.03974345326423645,
      0.042211562395095825,
      -0.03722035884857178,
      -0.0027519511058926582,
      -0.008544061332941055,
      0.0931362435221672,
      0.10308990627527237,
      0.03804786503314972,
      -0.01193166058510542,
      0.026012061163783073,
      0.010370347648859024,
      0.013034453615546227,
      0.04157602787017822,
      0.08882086724042892,
      0.011145982891321182,
      -0.022348932921886444,
      -0.022430170327425003,
      -0.0671876072883606,
      -0.045792970806360245,
      -0.05732204392552376,
      -0.028074020519852638,
      -0.06125627085566521,
      -0.028607528656721115,
      -0.04637616127729416,
      0.018822258338332176,
      -0.08309785276651382,
      -0.011788995005190372,
      -0.03544464707374573,
      -0.04076208546757698,
      -0.03337455540895462,
      -0.06434719264507294,
      0.06187869608402252,
      0.010319535620510578,
      0.026671575382351875,
      0.027646508067846298,
      0.015600433573126793,
      0.08504916727542877,
      0.0041273850947618484,
      -0.004036479629576206,
      -0.010185983963310719,
      -0.11431267112493515,
      0.005648554768413305,
      -0.050352148711681366,
      -0.0126388780772686,
      -0.05428008362650871,
      -8.433143375441432e-05,
      0.01028840895742178,
      -0.03159862756729126,
      0.03596680611371994,
      -0.055386021733284,
      0.06494343280792236,
      0.002367305103689432,
      -0.004088871646672487,
      0.05418140068650246,
      0.09358137100934982,
      0.08852947503328323,
      -0.04290563985705376,
      0.011529475450515747,
      0.011332150548696518,
      0.022779401391744614,
      -0.029881877824664116,
      0.006348865572363138,
      -0.030248820781707764,
      0.00503701763227582,
      0.03308180347084999,
      -0.0026022465899586678,
      -0.02056901343166828,
      -0.084164559841156,
      -0.02968115173280239,
      0.041064854711294174,
      0.07075457274913788,
      0.033441655337810516,
      -0.05982733517885208,
      -0.0714687779545784,
      -0.04080493375658989,
      -0.035715579986572266,
      0.009412850253283978,
      0.043184731155633926,
      0.026382166892290115,
      -0.03916172310709953,
      0.040119148790836334,
      0.0071556600742042065,
      0.028801893815398216,
      -0.06418751925230026,
      -0.04248619079589844,
      -0.033185284584760666,
      -0.02976907044649124,
      0.04713035374879837,
      0.02935454621911049,
      -0.002726293634623289,
      -0.015203434973955154,
      -0.0008409001748077571,
      -0.012737107463181019,
      0.12155640125274658,
      0.03442826122045517,
      0.006928838323801756,
      -0.048872750252485275,
      -0.04957699775695801,
      -0.041563134640455246,
      0.0498780831694603,
      0.008085189387202263,
      0.003992276731878519,
      0.0036214457359164953,
      -0.08156213909387589,
      0.016193166375160217,
      0.021953288465738297,
      -0.0746387243270874,
      -0.012821477837860584,
      0.056239593774080276,
      0.05504290759563446,
      0.022177858278155327,
      -0.012640273198485374,
      0.03437734395265579,
      -0.011393922381103039,
      -0.05862029641866684,
      0.012580975890159607,
      0.01987859234213829,
      0.01975593902170658,
      0.03643336147069931,
      -0.013685667887330055,
      -0.10072653740644455,
      0.028752155601978302,
      0.05076724290847778,
      -0.0394744835793972,
      0.007688721176236868,
      0.054002340883016586,
      0.009602599777281284,
      0.023564942181110382,
      0.0788063332438469,
      0.032406676560640335,
      -0.033312730491161346,
      -0.0026614724192768335,
      -0.041597798466682434,
      0.055020496249198914,
      -0.08398108184337616,
      0.02650575526058674,
      -0.039800770580768585,
      -0.004634569399058819,
      0.010522267781198025,
      0.007372439373284578,
      -0.002315330319106579,
      -0.031113261356949806,
      0.04299936816096306,
      0.049077603965997696,
      0.10136500746011734,
      0.10508007556200027,
      0.06587284058332443,
      0.014615487307310104,
      0.026272252202033997,
      -0.016345001757144928,
      -0.0001392421399941668,
      -0.11838486045598984,
      -0.04072685167193413,
      -0.08724945038557053,
      0.025953633710741997,
      -0.01227523759007454,
      -0.016372423619031906,
      -0.03451550006866455,
      0.010999809019267559,
      -0.010750304907560349,
      -0.02743944339454174,
      0.11734291166067123,
      0.08428939431905746,
      -0.010650956071913242,
      0.050287965685129166,
      -0.02268100157380104,
      0.0031029325909912586,
      0.0737118124961853,
      -0.007868954911828041,
      -0.03698953986167908,
      0.024471089243888855,
      0.02054324373602867,
      0.027144886553287506,
      0.03963514044880867,
      0.02736145444214344,
      0.005529521033167839,
      0.003187396563589573,
      -0.05489827319979668,
      0.013819407671689987,
      -0.06067373603582382,
      0.11579594761133194,
      -0.0294997189193964,
      -0.014168120920658112,
      -0.047474443912506104,
      -0.008957487531006336,
      0.035146720707416534,
      -0.02353842929005623,
      -0.05379291623830795,
      -0.019365904852747917,
      0.05067531019449234,
      -0.041556961834430695,
      -0.02605096623301506,
      0.012839787639677525,
      0.01850280910730362,
      0.015008981339633465,
      0.07070556282997131,
      -0.05061911419034004,
      -0.0964035764336586,
      -0.0006638117483817041,
      0.025491414591670036,
      0.07509371638298035,
      -0.004963864106684923,
      -0.024982111528515816,
      0.03611935302615166,
      -0.0018494740361347795,
      -0.008004819974303246,
      0.04158485680818558,
      0.04120070859789848,
      -0.005160735920071602,
      0.007101956754922867,
      -0.022836731746792793,
      -0.0152597576379776,
      -0.03553086519241333,
      -0.017369193956255913,
      0.03582290560007095,
      -0.13254837691783905,
      0.04056066274642944,
      -0.06677833199501038,
      0.04450836777687073,
      0.022116778418421745,
      0.02267898991703987,
      -0.0011178749846294522,
      0.03178948536515236,
      0.00011344393715262413,
      0.0065638297237455845,
      -0.01618018187582493,
      -0.033414941281080246,
      -0.023785095661878586,
      -0.03942881152033806,
      -0.023395979776978493,
      -0.014817019924521446,
      -0.03620992973446846,
      0.06743606925010681,
      -0.039063528180122375,
      0.041904009878635406,
      0.014623654074966908,
      0.035270340740680695,
      -0.011441344395279884,
      -0.06868921965360641,
      -0.04225087910890579,
      -0.014683730900287628,
      0.0012081526219844818,
      -0.07089579850435257,
      -0.026612117886543274,
      -0.016147835180163383,
      0.006656858138740063,
      0.04792667552828789,
      0.018162043765187263,
      -0.044615406543016434,
      0.026756929233670235,
      -0.07353556901216507,
      0.07257979363203049,
      -0.10199981182813644,
      -0.011281774379312992,
      -0.04123532027006149,
      -0.02096393145620823,
      -0.061136674135923386,
      -0.08440285176038742,
      -0.03835231438279152,
      -0.0377020500600338,
      -0.03847608342766762,
      -0.017153223976492882,
      -0.038762353360652924,
      -0.034727033227682114,
      -0.0019603257533162832,
      0.016748161986470222,
      0.017189135774970055,
      0.0016232674242928624,
      0.04450957104563713,
      0.0022803694009780884,
      0.06625068187713623,
      -0.09613476693630219,
      -0.07174456864595413,
      -0.01301999855786562,
      0.040627770125865936,
      0.048070624470710754,
      0.059473130851984024,
      -0.008208219893276691,
      -0.033426325768232346,
      -0.008784227073192596,
      -0.03802775964140892,
      0.03326408565044403,
      -0.03506912663578987,
      0.034127071499824524,
      0.08080615848302841,
      0.01680261641740799,
      0.012503528967499733,
      0.03821335732936859,
      0.046042539179325104,
      0.004436973482370377,
      -0.013273440301418304,
      -0.010522758588194847,
      0.050565205514431,
      -0.07306055724620819,
      -0.0016980767250061035,
      -0.03800499811768532,
      -0.020243246108293533,
      -0.05470665544271469,
      -0.012920751236379147,
      0.042564984411001205,
      0.020139966160058975,
      0.03540731221437454,
      -0.0248027965426445,
      -0.04139986261725426,
      0.03271377459168434,
      0.0583936832845211,
      0.0538177415728569,
      -0.019508574157953262,
      -0.026260364800691605,
      0.04676245152950287,
      0.0418437235057354,
      -0.03503161668777466,
      0.04087245464324951,
      -0.010019645094871521,
      0.005952897481620312,
      0.053492847830057144,
      -0.006665474269539118,
      0.06302991509437561,
      0.04048608988523483,
      -0.06369537115097046,
      0.045293767005205154,
      -0.04586474969983101,
      0.0518195666372776,
      0.022854264825582504,
      0.008014557883143425,
      0.06996744871139526,
      0.017184598371386528,
      -0.068644680082798,
      0.026488030329346657,
      -0.03850257769227028,
      0.03507862985134125,
      -0.06609530001878738,
      0.0526718907058239,
      -0.0415133461356163,
      0.03485548496246338,
      -0.010796787217259407,
      -0.01728241890668869,
      0.014278575778007507,
      0.05132382735610008,
      -0.003688614582642913,
      0.008703512139618397,
      0.025142481550574303,
      0.02587440051138401,
      -0.006677174475044012,
      -0.014140225015580654,
      0.012349818833172321,
      -0.028796566650271416,
      0.046523164957761765,
      -0.04587773233652115,
      0.013937179930508137,
      0.08183405548334122,
      0.026771239936351776,
      -0.015820467844605446,
      0.037370603531599045,
      0.0025618483778089285,
      -0.05789706110954285,
      -0.0009156599990092218,
      0.06029844284057617,
      0.02140084095299244,
      -0.12338028848171234,
      0.029525503516197205,
      0.044314343482255936,
      -0.014038371853530407,
      -0.034202657639980316,
      0.07573297619819641,
      0.07300223410129547,
      -0.025144247338175774,
      0.014661082997918129,
      0.04877278581261635,
      0.007703979033976793,
      0.024454740807414055,
      -0.027226168662309647,
      0.009341754019260406,
      0.042848989367485046,
      0.024179626256227493,
      0.022521212697029114,
      -0.03941568359732628,
      -0.0686626210808754,
      -0.044103510677814484,
      0.026824625208973885,
      -0.04429708793759346,
      -0.08753930777311325,
      0.03415348008275032,
      0.05114041268825531,
      -0.08056249469518661,
      -0.03491838276386261,
      -0.027877453714609146,
      0.0084524005651474,
      0.10370221734046936,
      0.036116473376750946,
      -0.029214300215244293,
      0.02143273502588272,
      0.05220457911491394,
      0.0670456513762474,
      0.004884273745119572,
      0.024153325706720352,
      -0.027718430384993553,
      -0.024273140355944633,
      -0.01774044707417488,
      0.02616802789270878,
      -0.023381756618618965,
      0.0447281189262867,
      -0.011474762111902237,
      0.03129010647535324,
      0.04910725727677345,
      -0.01778329908847809,
      -0.02487514726817608,
      -0.10983429849147797,
      -0.039574604481458664,
      0.0049447291530668736,
      -0.008835838176310062,
      -0.0010198178933933377,
      0.05499238148331642,
      0.006083054468035698,
      -0.017571309581398964,
      0.041738491505384445,
      0.05665270611643791,
      -0.005576991941779852,
      0.02953648753464222,
      0.05095663666725159,
      0.08814934641122818,
      -0.0061767008155584335,
      -0.006929097697138786,
      0.03925129771232605,
      0.0023147815372794867,
      -0.015206353738904,
      0.01881152018904686,
      0.012437095865607262,
      -0.005616793408989906
    ],
    [
      0.037997033447027206,
      0.11607527732849121,
      0.0249113067984581,
      -0.040084291249513626,
      0.010597292333841324,
      0.009631541557610035,
      -0.06268671900033951,
      0.011559300124645233,
      -0.09078121930360794,
      -0.00021777946676593274,
      -0.061476580798625946,
      -0.031173009425401688,
      0.028949234634637833,
      -0.0005943116848357022,
      -0.05477133020758629,
      -0.040186140686273575,
      0.0019841743633151054,
      -0.036207783967256546,
      0.07709348946809769,
      0.06084202602505684,
      0.10128603130578995,
      0.0002554195816628635,
      0.049664344638586044,
      -0.050063472241163254,
      0.016815273091197014,
      0.025761643424630165,
      -0.054322220385074615,
      -0.05505643039941788,
      0.01071181520819664,
      -0.0035108320880681276,
      0.04629552736878395,
      -0.05182874947786331,
      -0.0068230703473091125,
      0.059420812875032425,
      -0.01962915249168873,
      0.027662277221679688,
      -0.005323523655533791,
      0.008256875909864902,
      -0.03683602809906006,
      0.003875892609357834,
      0.001001343596726656,
      0.04202651605010033,
      -0.010792404413223267,
      0.016755346208810806,
      -0.017235442996025085,
      0.04364699125289917,
      -0.010982672683894634,
      0.0260322205722332,
      -0.005230708979070187,
      -0.030990421772003174,
      0.008570653386414051,
      0.008087548427283764,
      -0.058585431426763535,
      0.03013734519481659,
      0.07351574301719666,
      -0.012829410843551159,
      -0.051111578941345215,
      -0.04605931043624878,
      -0.02035052701830864,
      0.020749976858496666,
      0.01893290877342224,
      0.018558360636234283,
      -0.0033995306584984064,
      0.025552986189723015,
      -0.024479489773511887,
      0.027685431763529778,
      -0.045069340616464615,
      -0.04850180819630623,
      -0.02561042830348015,
      0.05608116835355759,
      -0.0020272296387702227,
      -0.04221130535006523,
      0.012568333186209202,
      0.037569042295217514,
      -0.019730813801288605,
      -0.01794028840959072,
      -0.01967533491551876,
      -0.015083085745573044,
      0.021556388586759567,
      0.02976894937455654,
      0.08922213315963745,
      0.06475651264190674,
      0.08142248541116714,
      0.001331123523414135,
      0.06391493231058121,
      0.02522294409573078,
      0.03781767934560776,
      -0.015572949312627316,
      -0.0017235910054296255,
      0.027147721499204636,
      0.020154019817709923,
      0.08251602202653885,
      0.02065691165626049,
      0.009567142464220524,
      0.010179003700613976,
      0.009469127282500267,
      -0.10228665173053741,
      -0.014471901580691338,
      0.01846255548298359,
      0.0668531209230423,
      -0.12052079290151596,
      -0.03002961352467537,
      0.019801823422312737,
      0.03570197895169258,
      0.04779527336359024,
      0.019793516024947166,
      0.03632659465074539,
      -0.08632069826126099,
      0.03096008114516735,
      0.020163703709840775,
      -0.04226717725396156,
      0.035416699945926666,
      -0.04405451565980911,
      -0.013579758815467358,
      -0.025189843028783798,
      0.0023254563566297293,
      -0.024139519780874252,
      -0.04648265987634659,
      0.024016771465539932,
      -0.004834598861634731,
      -0.035365957766771317,
      -0.06384118646383286,
      -0.021576711907982826,
      0.032851193100214005,
      0.07331602275371552,
      -0.010274501517415047,
      -0.03752589225769043,
      0.02838645502924919,
      0.019621290266513824,
      -0.04783138632774353,
      0.02239137701690197,
      -0.014862003736197948,
      0.03746313974261284,
      0.03259671479463577,
      -0.0052321841940283775,
      -0.011065221391618252,
      -0.024512125179171562,
      -0.04670947417616844,
      -0.014898968860507011,
      -0.08774896711111069,
      -0.011222155764698982,
      0.04057855159044266,
      0.0496433824300766,
      -0.017758028581738472,
      -0.005941440816968679,
      0.027768924832344055,
      0.0039030525367707014,
      -0.005442870780825615,
      0.03935763239860535,
      -0.009319898672401905,
      0.00830869935452938,
      -0.049047280102968216,
      0.029770782217383385,
      -0.006090831011533737,
      0.13437432050704956,
      0.08058508485555649,
      0.022674886509776115,
      0.020313717424869537,
      -0.040476635098457336,
      0.0022512006107717752,
      -0.04454189911484718,
      0.03636739030480385,
      -0.028299011290073395,
      -0.014848943799734116,
      0.014702970162034035,
      0.025575803592801094,
      -0.04082712531089783,
      -0.04789701849222183,
      -0.05061863735318184,
      -0.057079292833805084,
      -0.03571300953626633,
      0.05057559534907341,
      0.005473329219967127,
      0.0812164694070816,
      0.018634388223290443,
      -0.023990988731384277,
      -0.006668423302471638,
      0.022191246971488,
      0.03314032033085823,
      0.059567246586084366,
      -0.03736867383122444,
      0.029607312753796577,
      -0.03506376966834068,
      -0.0009795320220291615,
      0.05879678577184677,
      0.039015669375658035,
      -0.021210161969065666,
      0.005319731775671244,
      0.024745699018239975,
      -0.04034064710140228,
      0.07199879735708237,
      -0.0624212883412838,
      0.0021676942706108093,
      0.030963703989982605,
      -0.020910458639264107,
      0.005697370506823063,
      0.037549786269664764,
      0.0186415184289217,
      -0.06018003448843956,
      0.011787808500230312,
      0.020993340760469437,
      -0.0393386147916317,
      -0.05449488386511803,
      -0.06833522766828537,
      0.09188061952590942,
      -0.010970092378556728,
      0.000999774201773107,
      -0.021715007722377777,
      -0.010349063202738762,
      0.02877672016620636,
      0.012286077253520489,
      -0.09418044239282608,
      -0.06011774018406868,
      -0.014197541400790215,
      0.002519979141652584,
      0.07378913462162018,
      0.020114317536354065,
      0.01141192577779293,
      0.021229641512036324,
      0.00035917674540542066,
      0.0637088268995285,
      -0.007990765385329723,
      0.05854438245296478,
      -0.005496349185705185,
      -0.07526011019945145,
      0.0885748565196991,
      0.04391591250896454,
      0.010130789130926132,
      -0.05733080953359604,
      0.033467747271060944,
      -0.051594629883766174,
      -0.00898426491767168,
      -0.03468901291489601,
      0.013173305429518223,
      0.021840820088982582,
      0.022152794525027275,
      -0.000863537541590631,
      -0.10726044327020645,
      -0.02070288360118866,
      -0.010046135634183884,
      -0.012296664528548717,
      0.047511689364910126,
      -0.042811039835214615,
      -0.02228488028049469,
      0.00013033497089054435,
      0.004990522284060717,
      -0.061416689306497574,
      0.019697794690728188,
      -0.0019610226154327393,
      -0.04012500122189522,
      -0.04870548099279404,
      0.01642308384180069,
      0.040344104170799255,
      -0.03192422538995743,
      0.018545055761933327,
      0.07448282837867737,
      0.06921599060297012,
      -0.039241477847099304,
      -0.02781178243458271,
      -0.02369384840130806,
      -0.0024371182080358267,
      -0.02309357188642025,
      0.028214018791913986,
      0.021979568526148796,
      0.01010988000780344,
      0.015654806047677994,
      0.027842365205287933,
      -0.01477666012942791,
      0.01896529644727707,
      0.04212113842368126,
      0.003495527431368828,
      0.007933584973216057,
      0.036060236394405365,
      -0.018118537962436676,
      0.027752988040447235,
      0.01832146756350994,
      -0.006176120135933161,
      -0.038429003208875656,
      0.01999642513692379,
      -0.007727246265858412,
      -0.020138241350650787,
      0.02736349031329155,
      -0.05354216322302818,
      0.06448021531105042,
      -0.06963986158370972,
      -0.0006649007555097342,
      -0.001852907007560134,
      -0.03304893150925636,
      0.03858620300889015,
      -0.027415255084633827,
      -0.005911475047469139,
      -0.037416182458400726,
      0.02013923041522503,
      0.0008536714594811201,
      0.030124032869935036,
      -0.045350681990385056,
      0.026124771684408188,
      0.06030925363302231,
      -0.04414869472384453,
      -0.039714712649583817,
      0.0452892929315567,
      0.056931305676698685,
      -0.04205209016799927,
      -0.01778215356171131,
      0.005492744036018848,
      0.029374167323112488,
      -0.041761111468076706,
      0.022709915414452553,
      -0.0734696239233017,
      0.022799603641033173,
      -0.032380878925323486,
      -0.02182047814130783,
      0.015577697195112705,
      -0.040598150342702866,
      0.02387208864092827,
      -0.010875705629587173,
      -0.014355248771607876,
      -0.05608896538615227,
      0.016901377588510513,
      -0.0500471368432045,
      -0.06610076874494553,
      -0.009797010570764542,
      -0.03530777990818024,
      0.015367954969406128,
      0.006603527814149857,
      -0.07153555005788803,
      0.08824866265058517,
      0.09036697447299957,
      0.12938006222248077,
      0.036815792322158813,
      -0.001239592325873673,
      -0.03453953564167023,
      -0.011536014266312122,
      0.03899811580777168,
      -0.05841422453522682,
      0.033471714705228806,
      0.05603829771280289,
      0.11244715750217438,
      0.013435636647045612,
      0.007828328758478165,
      0.0445009209215641,
      0.03526383265852928,
      0.029616136103868484,
      -0.006675559561699629,
      0.019552873447537422,
      -0.039887066930532455,
      -0.04992373287677765,
      0.00334808137267828,
      0.003239493351429701,
      0.02596467174589634,
      0.05978524684906006,
      0.01134970411658287,
      0.023251889273524284,
      0.04142211005091667,
      0.05261307954788208,
      -0.028807658702135086,
      -0.025288185104727745,
      -0.043199192732572556,
      -0.0033649071119725704,
      0.02301579900085926,
      0.026314368471503258,
      0.015309931710362434,
      -0.04800165444612503,
      -0.0434553399682045,
      -0.060657091438770294,
      -0.009470924735069275,
      -0.040847036987543106,
      0.0308464877307415,
      0.00193600554484874,
      -0.03296823799610138,
      -0.05995020270347595,
      0.0192746389657259,
      -0.043209973722696304,
      0.003004874102771282,
      0.07591930776834488,
      0.09162558615207672,
      -0.054093584418296814,
      -0.04188430681824684,
      -0.04693817347288132,
      4.370205715531483e-05,
      0.057483844459056854,
      -0.013339849188923836,
      0.046477824449539185,
      0.03508559614419937,
      -0.045166704803705215,
      0.04785359278321266,
      0.05948356166481972,
      -0.04118937999010086,
      -0.010429118759930134,
      0.08919903635978699,
      0.03668244183063507,
      -0.08762052655220032,
      -0.07110985368490219,
      0.03964461013674736,
      0.04891758784651756,
      0.011731106787919998,
      0.008877722546458244,
      0.07644441723823547,
      -0.03966257721185684,
      0.019321957603096962,
      -0.011489546857774258,
      -0.06060332432389259,
      0.08586782217025757,
      -0.06355661898851395,
      0.09912741929292679,
      -0.05071665719151497,
      -0.006954502779990435,
      0.028269443660974503,
      -0.03125503659248352,
      0.036891236901283264,
      -0.013134893029928207,
      0.04977956786751747,
      -0.038839537650346756,
      0.014634622260928154,
      0.07852761447429657,
      0.07980821281671524,
      -0.04724389687180519,
      0.02207094430923462,
      0.011043179780244827,
      0.014660133048892021,
      -0.0412481315433979,
      0.032819416373968124,
      0.08553481847047806,
      -0.006809794809669256,
      0.08112283796072006,
      0.06215579807758331,
      -0.09206697344779968,
      -0.02419820800423622,
      0.04362268000841141,
      -0.003969898913055658,
      0.027488360181450844,
      -0.018339812755584717,
      -0.013738246634602547,
      -0.1472925841808319,
      -0.002799508860334754,
      0.004061522893607616,
      0.08544497191905975,
      0.0257845651358366,
      0.032078005373477936,
      0.0035230410285294056,
      0.09055484086275101,
      0.026859281584620476,
      0.03166370838880539,
      0.04284273833036423,
      -0.023978330194950104,
      0.028776902705430984,
      0.0754106268286705,
      -0.0005406721029430628,
      0.009767466224730015,
      -0.017695289105176926,
      -0.028448164463043213,
      0.07472367584705353,
      -0.07723144441843033,
      0.016214216127991676,
      -0.01578378491103649,
      -0.011877722106873989,
      -0.013738324865698814,
      0.02489161118865013,
      0.0280606746673584,
      0.0616290345788002,
      0.010241652838885784,
      -0.0036445583682507277,
      0.06143636256456375,
      -0.012355322018265724,
      -0.04960617050528526,
      0.05896412953734398,
      0.056678999215364456,
      -0.05515718087553978,
      0.06151352822780609,
      -0.0024441545829176903,
      0.027484210208058357,
      0.12829066812992096,
      -0.05635565519332886,
      -0.0037405232433229685,
      -0.005118851084262133,
      -0.013007685542106628,
      0.0366070494055748,
      0.0127593157812953,
      0.013785818591713905,
      -0.03423013538122177,
      -0.045996274799108505,
      -0.05942395329475403,
      -0.029461165890097618,
      0.00013264454901218414,
      -0.09820389747619629,
      -0.020832499489188194,
      0.04348042607307434,
      0.006201756652444601,
      0.04907918721437454,
      -0.028913846239447594,
      0.018257329240441322,
      0.01552156824618578,
      -0.005311423446983099,
      -0.018679600208997726,
      -0.021573863923549652,
      -0.01995868980884552,
      -0.0010585724376142025,
      -0.0048179058358073235,
      -0.0429939366877079,
      -0.0223737433552742,
      -0.0008954743389040232,
      -0.019525106996297836,
      -0.0010416596196591854
    ],
    [
      -0.06805843114852905,
      -0.001989866839721799,
      0.006396837532520294,
      -0.030052850022912025,
      -0.03919835016131401,
      -0.04164198040962219,
      -0.026709893718361855,
      0.01719043403863907,
      0.0009294031769968569,
      -0.011933046393096447,
      -0.04391246289014816,
      -0.002154851332306862,
      0.021467935293912888,
      -0.006733189336955547,
      0.048270389437675476,
      0.06929151713848114,
      0.013525418005883694,
      0.00942495372146368,
      -0.052243586629629135,
      0.006534619256854057,
      0.02245447039604187,
      0.033584266901016235,
      0.03488092124462128,
      -0.0322938896715641,
      -0.002825746312737465,
      0.03314146026968956,
      0.047249533236026764,
      -0.018714843317866325,
      -0.0026743074413388968,
      0.05463673546910286,
      -0.015028358437120914,
      0.007756154518574476,
      -0.00895723421126604,
      0.06645194441080093,
      -0.001451908960007131,
      0.02874448150396347,
      0.01788720116019249,
      -0.016700029373168945,
      0.06446798145771027,
      0.008609043434262276,
      -0.022627446800470352,
      -0.10263160616159439,
      -0.03841005638241768,
      -0.042438261210918427,
      -0.04733791574835777,
      -0.0017503161216154695,
      -0.027708930894732475,
      0.012415081262588501,
      -0.0038373414427042007,
      -0.029707737267017365,
      0.061096370220184326,
      0.06463441252708435,
      -0.010443990118801594,
      0.06731115281581879,
      0.04460782930254936,
      -0.11941765993833542,
      0.027331188321113586,
      0.03275785595178604,
      0.050398651510477066,
      0.029935460537672043,
      0.057173583656549454,
      0.057464849203825,
      -0.03797822445631027,
      0.07464580982923508,
      0.08047671616077423,
      0.012529090978205204,
      0.03520762920379639,
      0.025935377925634384,
      0.017757726833224297,
      0.01578196883201599,
      -0.019060084596276283,
      -0.011511248536407948,
      0.054330646991729736,
      0.029300739988684654,
      -0.01628786325454712,
      -0.039094310253858566,
      0.016423773020505905,
      -0.0039757234044373035,
      0.06973950564861298,
      -0.006012673024088144,
      0.1008445993065834,
      0.04346480220556259,
      -0.01804812252521515,
      -0.027611451223492622,
      -0.019615910947322845,
      -0.0018855829257518053,
      0.06640937924385071,
      -0.04098119959235191,
      0.015303931199014187,
      -0.019520845264196396,
      0.003312839660793543,
      0.023124543949961662,
      -0.026497377082705498,
      0.009505445137619972,
      -0.006520008202642202,
      -0.07411624491214752,
      0.0528426468372345,
      -0.00653555104508996,
      0.038455232977867126,
      -0.04874643683433533,
      0.015776367858052254,
      -0.0719856396317482,
      0.05681035667657852,
      0.0011141598224639893,
      0.016615206375718117,
      0.04836652800440788,
      0.07279319316148758,
      -0.017879879102110863,
      0.012941247783601284,
      0.09405580908060074,
      -0.0385984443128109,
      -0.045692846179008484,
      -0.024022240191698074,
      0.043098580092191696,
      -0.04408023878931999,
      -0.019090525805950165,
      0.024540821090340614,
      0.026826322078704834,
      -0.049554433673620224,
      0.050733551383018494,
      -0.07729245722293854,
      0.02786240354180336,
      -0.0007380903698503971,
      0.04972958564758301,
      0.06385558098554611,
      0.08947586268186569,
      0.02080199308693409,
      0.02498129941523075,
      0.038154613226652145,
      -0.05325814709067345,
      -0.040582913905382156,
      -0.07796262204647064,
      0.03832952305674553,
      -0.038049038499593735,
      0.059826623648405075,
      0.039857279509305954,
      0.08950737863779068,
      0.06799498945474625,
      0.030147133395075798,
      0.01065114326775074,
      -0.021216124296188354,
      0.03513364866375923,
      0.003867274848744273,
      0.035597700625658035,
      -0.03833575174212456,
      0.015529497526586056,
      -0.011806236580014229,
      -0.052369002252817154,
      0.0030932812951505184,
      -0.0584939569234848,
      -0.09729238599538803,
      0.059338513761758804,
      0.009272790513932705,
      -0.07951384037733078,
      -0.01051193568855524,
      -0.011270259507000446,
      0.06909135729074478,
      -0.007562459446489811,
      -0.08844729512929916,
      0.017105460166931152,
      0.06583430618047714,
      -0.03061830624938011,
      -0.052214328199625015,
      0.021917110309004784,
      -0.06043689697980881,
      0.042724985629320145,
      -0.027095817029476166,
      0.009666362777352333,
      -0.0023967877496033907,
      0.056699857115745544,
      0.07169882208108902,
      -0.0070908027701079845,
      0.06774228811264038,
      0.049649834632873535,
      -0.06275873631238937,
      -0.03161186724901199,
      0.0009706990094855428,
      -0.05465586856007576,
      -0.05793355032801628,
      -0.015524493530392647,
      0.0052681416273117065,
      -0.023176686838269234,
      0.07889886945486069,
      0.009540555998682976,
      -0.02693362534046173,
      0.06131579354405403,
      -0.00799915287643671,
      0.008853139355778694,
      0.0010833507403731346,
      -0.007360012736171484,
      0.003422591369599104,
      0.0399615615606308,
      -0.09641183912754059,
      0.07702536880970001,
      -0.03251050040125847,
      0.06073983386158943,
      -0.012772588059306145,
      -0.030677849426865578,
      -0.06121707707643509,
      0.015850966796278954,
      -0.023669173941016197,
      0.0010643241694197059,
      0.05328066274523735,
      0.09514504671096802,
      0.006128567736595869,
      0.007681506220251322,
      -0.05516811087727547,
      -0.016975680366158485,
      -0.019542047753930092,
      -0.1261259913444519,
      0.035280726850032806,
      -0.02467210218310356,
      -0.050184354186058044,
      -0.09243646264076233,
      0.022407928481698036,
      0.03442263603210449,
      -0.00604050001129508,
      -0.01613638922572136,
      0.06416860967874527,
      -0.05177764967083931,
      -0.038235023617744446,
      -0.025476135313510895,
      -0.05440457910299301,
      0.0037112808786332607,
      0.04603970795869827,
      0.030830733478069305,
      -0.0362803153693676,
      -0.006140751764178276,
      0.006158364936709404,
      -0.00740862125530839,
      0.055235810577869415,
      -0.06949574500322342,
      -0.022294091060757637,
      0.021743401885032654,
      0.09450848400592804,
      0.024063603952527046,
      -0.00893548596650362,
      -0.019903281703591347,
      -0.07583167403936386,
      0.07407382130622864,
      -0.023167673498392105,
      0.030251260846853256,
      0.03286776691675186,
      -0.026630043983459473,
      0.048047177493572235,
      0.01265574712306261,
      -0.08450812846422195,
      0.00406505074352026,
      0.05291898176074028,
      -0.060827117413282394,
      0.003987027797847986,
      -0.037951987236738205,
      -0.058487582951784134,
      -0.02123800851404667,
      0.139461487531662,
      -0.014411814510822296,
      -0.04620717838406563,
      0.04520156979560852,
      -0.03839440643787384,
      0.07942235469818115,
      -0.059548426419496536,
      0.02335529960691929,
      -0.025915641337633133,
      -0.07854487746953964,
      -0.06341855973005295,
      0.027841567993164062,
      -0.04566710814833641,
      -0.03420725464820862,
      0.06906008720397949,
      0.00960167683660984,
      0.04005556181073189,
      -0.061671268194913864,
      -0.029433026909828186,
      -0.0033655574079602957,
      0.026642823591828346,
      -0.014492507092654705,
      -0.014689925126731396,
      -0.029878733679652214,
      -0.02200118638575077,
      0.0021729108411818743,
      -0.018631990998983383,
      0.030173825100064278,
      -0.005817817989736795,
      -0.04614631086587906,
      -0.06455810368061066,
      -0.03880086541175842,
      -0.03421821817755699,
      0.03351229801774025,
      0.004601477645337582,
      -0.04185408726334572,
      -0.055414218455553055,
      0.00901684258133173,
      -0.029310215264558792,
      0.01970631256699562,
      -0.05456390976905823,
      -0.035342708230018616,
      -0.005086252000182867,
      -0.012848522514104843,
      0.014805690385401249,
      0.03210281580686569,
      -0.00665623415261507,
      -0.04604998603463173,
      0.035488490015268326,
      -0.08695260435342789,
      -0.026429053395986557,
      -0.09890314936637878,
      -0.009257636032998562,
      -0.06560975313186646,
      0.01991262659430504,
      0.014408201910555363,
      -0.026388663798570633,
      0.04061517491936684,
      -0.032242678105831146,
      -0.022043492645025253,
      0.018921447917819023,
      0.011790699325501919,
      0.017990369349718094,
      -0.11892800033092499,
      0.034730345010757446,
      -0.006671146024018526,
      -0.015109877102077007,
      -0.04192853346467018,
      -0.00826336070895195,
      -0.09761998802423477,
      -0.017650000751018524,
      0.0471394881606102,
      0.06008114293217659,
      -0.010603489354252815,
      0.0944976806640625,
      0.11242648214101791,
      -0.0081900954246521,
      0.0058713327161967754,
      -0.1022338792681694,
      -0.05960230529308319,
      -0.017936699092388153,
      -0.0775052085518837,
      -0.02235201746225357,
      -0.0029220583382993937,
      -0.02703242003917694,
      -0.023274896666407585,
      0.010471855290234089,
      0.05713118985295296,
      -0.04205935448408127,
      -0.050601184368133545,
      -0.04246470332145691,
      -0.07796555012464523,
      0.025278938934206963,
      0.04439340531826019,
      -0.014381559565663338,
      -0.006582240574061871,
      0.021991970017552376,
      -0.07874572277069092,
      0.027404233813285828,
      -0.007219637278467417,
      0.05357061326503754,
      0.012166164815425873,
      -0.06537361443042755,
      -0.0009958415757864714,
      0.09588086605072021,
      -0.0008323209476657212,
      -0.04967167228460312,
      0.052686888724565506,
      0.04604870453476906,
      0.0023908151779323816,
      -0.005546272732317448,
      -0.043926529586315155,
      -0.02051754854619503,
      -0.027256356552243233,
      -0.04610376060009003,
      -0.004611642565578222,
      -0.014358113519847393,
      0.025750329717993736,
      0.036405179649591446,
      0.017157431691884995,
      0.05902467295527458,
      -0.06261494755744934,
      0.040458645671606064,
      -0.048509664833545685,
      0.0639830082654953,
      0.045491836965084076,
      -0.0669180378317833,
      0.04014058783650398,
      0.07089504599571228,
      0.007900156080722809,
      -0.031259212642908096,
      -0.053813934326171875,
      0.02904338762164116,
      0.024937162175774574,
      -0.00500602088868618,
      -0.006935160607099533,
      -0.006758352275937796,
      -0.03469007834792137,
      0.009681728668510914,
      -0.014245514757931232,
      0.10350456833839417,
      0.0012003681622445583,
      -0.021369410678744316,
      -0.022086238488554955,
      0.03242073208093643,
      0.042233292013406754,
      -0.02067072130739689,
      -0.0033367176074534655,
      -0.023303773254156113,
      0.03719762712717056,
      0.02952284924685955,
      -0.04184560850262642,
      -0.03269467130303383,
      0.03526003286242485,
      0.03854326531291008,
      -0.059267204254865646,
      -0.06882478296756744,
      0.00714506721124053,
      -0.023063644766807556,
      0.05116500332951546,
      0.04424130544066429,
      -0.0644066333770752,
      0.040974147617816925,
      0.018596716225147247,
      -0.019398203119635582,
      0.024089550599455833,
      -0.056178055703639984,
      0.02866140380501747,
      -0.05636661499738693,
      -0.01066438015550375,
      -0.008510329760611057,
      0.035186391323804855,
      -0.04756785184144974,
      -0.09640495479106903,
      -0.049319952726364136,
      0.0018370745237916708,
      0.026884177699685097,
      0.10346126556396484,
      0.02736710198223591,
      -0.027863824740052223,
      0.08184560388326645,
      0.08559121936559677,
      0.06433849781751633,
      0.05704483017325401,
      0.009865048341453075,
      0.05787714943289757,
      0.013569656759500504,
      0.0450887568295002,
      -0.06141240522265434,
      0.019222628325223923,
      0.023166853934526443,
      0.04713762551546097,
      -0.07178004086017609,
      0.0443824902176857,
      0.005337350536137819,
      0.018648291006684303,
      0.02136513777077198,
      -0.038090310990810394,
      0.00177808350417763,
      0.06010366231203079,
      0.008540447801351547,
      -0.0466122180223465,
      -0.044579461216926575,
      -0.06496571004390717,
      -0.07796227931976318,
      0.03208479285240173,
      0.0051798587664961815,
      -0.08457088470458984,
      -0.019955869764089584,
      0.03432666137814522,
      0.0022999451030045748,
      0.05707854405045509,
      0.052944574505090714,
      -0.0019882929045706987,
      -0.026219187304377556,
      -0.04556797072291374,
      0.041192878037691116,
      -0.11727815866470337,
      -0.02183692716062069,
      0.07893767952919006,
      -0.01923631876707077,
      0.10300320386886597,
      0.04282648488879204,
      0.0553993284702301,
      -0.02065812610089779,
      0.03989647701382637,
      0.006747085601091385,
      0.02918836660683155,
      -0.04532670974731445,
      0.03286081179976463,
      0.015807276591658592,
      -0.05037596449255943,
      0.022151418030261993,
      0.0036688970867544413,
      -0.07550239562988281,
      0.01643374189734459,
      -0.029353249818086624,
      -0.03869249299168587,
      -0.05355009809136391,
      0.0425330214202404,
      0.026999332010746002,
      -0.03879127651453018,
      0.005311260931193829,
      0.010060355998575687,
      0.0308111272752285,
      -0.06650034338235855,
      0.01135662104934454,
      -0.0083028394728899,
      0.02449760213494301
    ],
    [
      -0.017885202541947365,
      0.0752330869436264,
      -0.07543624937534332,
      -0.0007974631735123694,
      -0.050978463143110275,
      -0.007739611901342869,
      -0.03374725207686424,
      0.03665030375123024,
      0.0844554603099823,
      -0.030216848477721214,
      0.01354973390698433,
      -0.010414979420602322,
      -0.008240139111876488,
      -0.02388099953532219,
      0.011068994179368019,
      0.05298644304275513,
      0.0484987311065197,
      -0.012403088621795177,
      0.0888628363609314,
      -0.028063202276825905,
      0.005758695304393768,
      -0.07692793011665344,
      -0.06932232528924942,
      0.007364710792899132,
      0.010619612410664558,
      -0.010231070220470428,
      -0.027304990217089653,
      -0.07353876531124115,
      0.07304973155260086,
      -0.0577353872358799,
      -0.0004777488356921822,
      0.02286626026034355,
      -0.03781065717339516,
      -0.05531224608421326,
      0.027073338627815247,
      0.026103969663381577,
      0.041845791041851044,
      -0.016563471406698227,
      -0.0472845733165741,
      -0.015061704441905022,
      -0.000559302163310349,
      0.11320994049310684,
      -0.005726889241486788,
      -0.06202273815870285,
      -0.03286956623196602,
      -0.011258020997047424,
      -0.03896425664424896,
      0.007290319073945284,
      0.026512157171964645,
      -0.06315390765666962,
      -0.07045591622591019,
      -0.021892106160521507,
      -0.03949554264545441,
      -0.006842342671006918,
      0.0056122178211808205,
      -0.07658834755420685,
      0.011106870137155056,
      0.02920815534889698,
      0.05102546140551567,
      -0.051746465265750885,
      -0.012258795090019703,
      0.055371057242155075,
      0.005856905598193407,
      0.037166424095630646,
      0.013902153819799423,
      -0.024941975250840187,
      0.018019501119852066,
      -0.026671916246414185,
      -0.015905452892184258,
      -0.01420602947473526,
      -0.05050967261195183,
      -0.006470530293881893,
      -0.02456245757639408,
      0.039480920881032944,
      -0.006857340689748526,
      0.03196806460618973,
      0.02917833812534809,
      -0.08563287556171417,
      -0.04125995934009552,
      0.050282206386327744,
      0.01628904975950718,
      0.011412487365305424,
      -0.04409802332520485,
      0.06788300722837448,
      -0.04166649654507637,
      -0.04536110907793045,
      -0.0204620398581028,
      -0.04714147746562958,
      0.04251547530293465,
      0.056421805173158646,
      0.0532565675675869,
      -0.07322488725185394,
      0.02177623100578785,
      -0.017276667058467865,
      0.08709775656461716,
      0.042607955634593964,
      -0.012164359912276268,
      -0.05008470267057419,
      0.03232213854789734,
      -0.08083859086036682,
      -0.1048831045627594,
      -0.08177617192268372,
      0.07593097537755966,
      0.015315861441195011,
      0.07821013033390045,
      0.02516801282763481,
      -0.04406912997364998,
      -0.011238141916692257,
      0.0835636705160141,
      -0.07221719622612,
      0.06909258663654327,
      -0.021121811121702194,
      0.09413664788007736,
      0.037406500428915024,
      -0.034337520599365234,
      0.02109816111624241,
      -0.007608009967952967,
      -0.06462650001049042,
      0.07158728688955307,
      0.016823161393404007,
      0.04050946608185768,
      -0.009794769808650017,
      -0.025113102048635483,
      0.05019279196858406,
      -0.055305518209934235,
      0.005309336353093386,
      -0.009225316345691681,
      0.030335748568177223,
      0.03685569018125534,
      -0.09967084974050522,
      -0.07655604183673859,
      -0.011922045610845089,
      0.04906635731458664,
      0.02614525333046913,
      0.029557719826698303,
      -0.011487219482660294,
      0.009466721676290035,
      0.09292623400688171,
      -0.006365562789142132,
      0.02541441097855568,
      0.03390015289187431,
      0.04184148833155632,
      -0.09309958666563034,
      -0.013658019714057446,
      -0.004909754730761051,
      0.002603264292702079,
      -0.029367715120315552,
      -0.0363321416079998,
      0.03957933932542801,
      -0.02703642100095749,
      0.07471863925457001,
      -0.06460975855588913,
      -0.024010060355067253,
      0.05920129269361496,
      -0.007152252830564976,
      0.09647112339735031,
      0.11028913408517838,
      -0.025133617222309113,
      0.023308128118515015,
      0.029529333114624023,
      0.06836255639791489,
      -0.015029363334178925,
      0.05388006567955017,
      0.0527031384408474,
      -0.024037038907408714,
      0.026246175169944763,
      0.023559387773275375,
      -0.0748160108923912,
      -0.02389194443821907,
      0.031307850033044815,
      -0.018040690571069717,
      0.024362796917557716,
      -0.08546832948923111,
      0.03171297535300255,
      -0.019822152331471443,
      -0.014705226756632328,
      0.025504840537905693,
      -0.010341208428144455,
      -0.11329666525125504,
      -0.04047922417521477,
      0.04104173183441162,
      0.0008390969596803188,
      -0.05060338228940964,
      0.10458702594041824,
      -0.05210799723863602,
      0.05714154615998268,
      0.001121140201576054,
      -0.029973827302455902,
      -0.045186493545770645,
      -0.00812311191111803,
      -0.03857698291540146,
      -0.0011756836902350187,
      -0.033057838678359985,
      -0.025090375915169716,
      0.01344531960785389,
      0.03882109001278877,
      -0.06841661781072617,
      -0.06167345494031906,
      -0.007081954274326563,
      0.035963740199804306,
      0.023949244990944862,
      -0.0011484177084639668,
      0.007350197993218899,
      -0.05560966208577156,
      0.018410276621580124,
      -0.0185115747153759,
      0.06201527640223503,
      0.01826980896294117,
      -0.09412608295679092,
      -0.017030401155352592,
      -0.05131453275680542,
      0.0698421373963356,
      -0.03225930407643318,
      -0.033630918711423874,
      -0.12294084578752518,
      -0.031511079519987106,
      -0.027031324803829193,
      -0.06848441809415817,
      0.06111188232898712,
      -0.07646478712558746,
      -0.04598209261894226,
      0.028130818158388138,
      -0.03829225152730942,
      0.005337469279766083,
      -0.0031440872699022293,
      0.11635827273130417,
      0.008821592666208744,
      -0.0038071596063673496,
      -0.05218486860394478,
      -0.06278607249259949,
      0.025717856362462044,
      0.04736315459012985,
      -0.01486287359148264,
      0.0815761461853981,
      -0.09139864891767502,
      0.009632891044020653,
      0.02330256812274456,
      -0.05707966908812523,
      -0.026158738881349564,
      -0.004446773789823055,
      0.07005515694618225,
      0.039574865251779556,
      -0.06837522238492966,
      -0.06680632382631302,
      -0.044981542974710464,
      -0.0954352393746376,
      -0.11660248041152954,
      0.010354085825383663,
      0.0442626029253006,
      -0.031940534710884094,
      -0.026029163971543312,
      -0.09520804136991501,
      -0.05603441223502159,
      0.022430894896388054,
      -0.025098323822021484,
      0.02114391140639782,
      0.02821733057498932,
      -0.06803116947412491,
      -0.016593797132372856,
      -0.011418690904974937,
      -0.011425579898059368,
      -0.03605012223124504,
      -0.048650238662958145,
      -0.09792812168598175,
      0.014811545610427856,
      0.03127254918217659,
      -0.01473035104572773,
      -0.0024991859681904316,
      -0.028817469254136086,
      -0.07468224316835403,
      0.02271653153002262,
      -0.004042987246066332,
      -0.007323327008634806,
      -0.014159727841615677,
      0.006171097978949547,
      0.007042283192276955,
      0.033014412969350815,
      -0.012024040333926678,
      -0.03892044723033905,
      -0.005650639999657869,
      -0.08050515502691269,
      0.0401659719645977,
      -0.03171111270785332,
      -0.020049817860126495,
      -0.015318197198212147,
      -0.026340583339333534,
      0.059347495436668396,
      -0.061671216040849686,
      0.04115841165184975,
      0.003181221429258585,
      0.0315709188580513,
      -0.01403094083070755,
      0.06276928633451462,
      0.03487636521458626,
      -0.06246708706021309,
      -0.09567221999168396,
      -0.05349653959274292,
      -0.09411392360925674,
      -0.06346052885055542,
      0.03971180319786072,
      0.05929047614336014,
      -0.027953026816248894,
      0.010440361686050892,
      0.011989842168986797,
      -0.003077188739553094,
      -0.05780486389994621,
      0.04280713573098183,
      0.017228219658136368,
      0.026083221659064293,
      -0.043219923973083496,
      -0.03471120446920395,
      -0.011889590881764889,
      -0.026061510667204857,
      0.06193509325385094,
      -0.025532612577080727,
      0.028843142092227936,
      0.00283240620046854,
      -0.07371154427528381,
      -0.03276834636926651,
      0.010902323760092258,
      0.029800379648804665,
      0.04663018509745598,
      -0.027293285354971886,
      -0.011953212320804596,
      0.05090493708848953,
      -0.04640558362007141,
      0.0580105297267437,
      0.05007382854819298,
      0.0035219769924879074,
      -0.010368562303483486,
      0.02304762601852417,
      0.014293544925749302,
      0.051310986280441284,
      -0.07324575632810593,
      0.06563925743103027,
      -0.016666602343320847,
      0.023311268538236618,
      -0.02264135330915451,
      0.01768437586724758,
      -0.07589779794216156,
      -0.013738025911152363,
      -0.0038641714490950108,
      -0.07788146287202835,
      0.04392382130026817,
      0.10559844970703125,
      -0.002716578310355544,
      0.024625327438116074,
      0.01915411278605461,
      0.0026114715728908777,
      -0.01871790736913681,
      0.0176934115588665,
      0.015196751803159714,
      -0.040013376623392105,
      0.00906563363969326,
      -0.020113365724682808,
      -0.02354460209608078,
      0.016808414831757545,
      -0.04184650257229805,
      0.07710298150777817,
      -0.03487589210271835,
      -0.06418909877538681,
      -0.08801227062940598,
      -0.04776960238814354,
      -0.004376228898763657,
      -0.023889997974038124,
      -0.028250131756067276,
      0.060045283287763596,
      -0.013636299408972263,
      0.09521565586328506,
      0.03617722913622856,
      -0.05996456369757652,
      -0.06767164915800095,
      -0.004448574036359787,
      0.04846983030438423,
      -0.08132853358983994,
      0.014240116812288761,
      -0.06255875527858734,
      0.0635499581694603,
      0.06727283447980881,
      0.07560452818870544,
      0.0007209043833427131,
      -0.014806218445301056,
      -0.008302041329443455,
      -0.04136423394083977,
      0.07484113425016403,
      -0.0323081873357296,
      0.029821228235960007,
      0.002385589526966214,
      -0.0482528880238533,
      -0.0644015520811081,
      -0.012018062174320221,
      -0.017529411241412163,
      -0.06261379271745682,
      0.00043102112249471247,
      0.07316691428422928,
      0.07708851248025894,
      0.011306514032185078,
      -0.007140820380300283,
      0.010578770190477371,
      0.10818523913621902,
      -0.04799554497003555,
      -0.03354512155056,
      -0.05631944164633751,
      0.007425238844007254,
      0.01692384108901024,
      -0.011424422264099121,
      0.025615084916353226,
      -0.0016574625624343753,
      0.05932149663567543,
      0.034804027527570724,
      0.05196912959218025,
      0.03766947612166405,
      -0.0025247104931622744,
      0.09099458158016205,
      0.04493636265397072,
      0.00047656052629463375,
      0.022145932540297508,
      0.037718579173088074,
      0.043205276131629944,
      0.047411661595106125,
      0.02740900032222271,
      -0.04034850746393204,
      -0.0168248750269413,
      -0.04468810558319092,
      -0.13546140491962433,
      0.004217930603772402,
      -0.02598969265818596,
      0.004154101479798555,
      -0.014745818451046944,
      -0.0017138725379481912,
      0.0662703886628151,
      0.037169210612773895,
      0.04155951738357544,
      0.02490532211959362,
      -0.08171641081571579,
      0.03560846298933029,
      0.04024335741996765,
      -0.023658862337470055,
      -0.0472123883664608,
      0.012286406941711903,
      -0.03991152346134186,
      -0.03217167407274246,
      0.012286734767258167,
      -0.061356183141469955,
      0.026614967733621597,
      0.025039533153176308,
      0.0036347131244838238,
      0.07098628580570221,
      0.027737503871321678,
      -0.024710694327950478,
      -0.005903474986553192,
      -0.026973189786076546,
      -0.04979712888598442,
      0.00094791961601004,
      -0.021032793447375298,
      0.043307702988386154,
      -0.05002542585134506,
      -0.008350629359483719,
      -0.028763318434357643,
      -0.13767553865909576,
      -0.013398231938481331,
      0.08205843716859818,
      0.04293777793645859,
      0.055694181472063065,
      -0.011032181791961193,
      0.044519249349832535,
      0.017163271084427834,
      0.011112100444734097,
      0.01782619208097458,
      -0.11971339583396912,
      0.012762817554175854,
      0.01137225329875946,
      -0.12309452891349792,
      0.013143138960003853,
      -0.017661329358816147,
      0.007879788987338543,
      -0.050128187984228134,
      -0.07739364355802536,
      0.025438250973820686,
      -0.03531932458281517,
      -0.040789566934108734,
      -0.01136733964085579,
      -0.025875480845570564,
      -0.003697673324495554,
      -0.09048813581466675,
      0.03209106624126434,
      0.03146997466683388,
      0.004738294519484043,
      0.10357573628425598,
      -0.04843111336231232,
      -0.07081696391105652,
      0.06876206398010254,
      -0.09853219240903854,
      0.015591484494507313,
      -0.020346997305750847,
      0.0035426204558461905,
      0.0069618262350559235,
      0.0399966761469841,
      0.013933786191046238,
      0.028174936771392822,
      -0.04586675763130188,
      0.06238669157028198,
      -0.019875269383192062
    ],
    [
      0.02568240463733673,
      -0.029584240168333054,
      -0.028589541092514992,
      -0.05798199772834778,
      -0.08061300963163376,
      -0.02458609826862812,
      0.10605816543102264,
      -0.0083257295191288,
      -0.08374092727899551,
      0.029293997213244438,
      -0.012723010033369064,
      0.0477905310690403,
      0.002307254122570157,
      0.07041104137897491,
      -0.03921838849782944,
      0.07209416478872299,
      -3.3880620321724564e-05,
      0.11057178676128387,
      -0.033215995877981186,
      -0.016505703330039978,
      0.012163931503891945,
      -0.04220014065504074,
      0.1369425654411316,
      0.04578743502497673,
      -0.016365205869078636,
      0.04384956881403923,
      -0.001358318142592907,
      0.007173094432801008,
      -0.0865808054804802,
      0.03191230818629265,
      0.041555892676115036,
      0.07609698176383972,
      -0.07225397229194641,
      0.03723062202334404,
      0.0040152729488909245,
      0.029215481132268906,
      -0.02515529841184616,
      0.0007724817260168493,
      0.019856851547956467,
      -0.014929259195923805,
      0.0002853989426512271,
      -0.07365049421787262,
      0.011230247095227242,
      -0.07653427124023438,
      -0.015280800871551037,
      -0.0075202262960374355,
      0.0030667809769511223,
      0.0560612715780735,
      0.037224020808935165,
      -0.06675630062818527,
      0.03218384459614754,
      0.05619341880083084,
      0.015064284205436707,
      -0.04829135164618492,
      0.014103221707046032,
      -0.056904181838035583,
      0.03308597579598427,
      -0.05222073569893837,
      -0.02004465088248253,
      -0.02786611020565033,
      -0.009503348730504513,
      0.08461444824934006,
      0.06212707608938217,
      0.030762655660510063,
      -0.0352880135178566,
      0.019724566489458084,
      0.053919706493616104,
      0.016962070018053055,
      0.02549830637872219,
      -0.08548658341169357,
      0.005989921744912863,
      -0.05854835361242294,
      0.02701394632458687,
      0.1269398033618927,
      -0.025010667741298676,
      0.01022068876773119,
      -0.0031646352726966143,
      0.06183922290802002,
      0.004984572995454073,
      0.0166581142693758,
      0.004139360506087542,
      0.029349831864237785,
      0.02555771917104721,
      0.04451243579387665,
      0.056388478726148605,
      0.06705459207296371,
      0.0655108243227005,
      -0.028292937204241753,
      -0.012069190852344036,
      -0.02269718050956726,
      -0.03530878573656082,
      -0.01122825127094984,
      0.035027679055929184,
      -0.08530168235301971,
      -0.05257289484143257,
      -0.017681799829006195,
      0.06926853209733963,
      0.03163863718509674,
      0.01376811321824789,
      0.03271711990237236,
      -0.05770868808031082,
      0.010070478543639183,
      0.01670648716390133,
      -0.05820080265402794,
      -0.0018901494331657887,
      -0.026273733004927635,
      -0.0638563334941864,
      -0.0389583557844162,
      -0.03257891908288002,
      -0.011343749240040779,
      -0.005206733476370573,
      -0.014614898711442947,
      -0.007354557979851961,
      0.01800219528377056,
      -0.0029527766164392233,
      0.03265656158328056,
      -0.046508289873600006,
      0.02287311665713787,
      -0.01632719486951828,
      -0.05089984089136124,
      -0.036028556525707245,
      0.023224269971251488,
      -0.018762366846203804,
      0.00768359936773777,
      0.05613572150468826,
      0.0014921745751053095,
      0.07729609310626984,
      -0.013853290118277073,
      0.05405275523662567,
      0.007888415828347206,
      -0.01292648259550333,
      0.03698994964361191,
      -0.019946159794926643,
      0.016665123403072357,
      -0.00215548207052052,
      0.0016215480864048004,
      -0.013876019045710564,
      0.07187172770500183,
      0.041132114827632904,
      -0.025107495486736298,
      -0.03673359006643295,
      -0.019214622676372528,
      0.020548000931739807,
      0.04644506052136421,
      -0.057913728058338165,
      -0.027575131505727768,
      -0.009283146820962429,
      0.030302051454782486,
      0.05502771586179733,
      -0.025292852893471718,
      -0.024326911196112633,
      -0.05236088111996651,
      0.0009462350280955434,
      -0.047383055090904236,
      -0.02302931807935238,
      -0.01819351315498352,
      0.1066916435956955,
      -0.03188148885965347,
      0.03252546861767769,
      -0.03981555998325348,
      0.07975496351718903,
      0.0005945618031546474,
      -0.015820607542991638,
      -0.03254406526684761,
      -0.06347909569740295,
      0.007323034107685089,
      -0.022798320278525352,
      -0.034473784267902374,
      0.030709756538271904,
      -0.04531783238053322,
      -0.010677875019609928,
      0.010346252471208572,
      -0.0686836913228035,
      0.02697427198290825,
      0.005694746971130371,
      -0.09128071367740631,
      -0.046039383858442307,
      0.03677399456501007,
      -0.01975014992058277,
      0.02219843678176403,
      -0.0878359004855156,
      0.009586334228515625,
      0.01992866024374962,
      0.0405997633934021,
      0.017583366483449936,
      -0.007434077560901642,
      0.017011895775794983,
      -0.07605515420436859,
      0.11236352473497391,
      0.0857786163687706,
      0.04202279821038246,
      0.02512659877538681,
      -0.01040161307901144,
      0.002414311980828643,
      0.06758648157119751,
      -0.002512175589799881,
      0.018728697672486305,
      0.028728662058711052,
      -0.02303912118077278,
      -0.026165325194597244,
      -0.05636975169181824,
      0.002953888149932027,
      0.0188849288970232,
      0.013574730604887009,
      -0.01573871448636055,
      -0.05461512506008148,
      -0.04510620981454849,
      0.004273691214621067,
      -0.051843658089637756,
      0.01763884164392948,
      -0.03563658148050308,
      -0.006984080653637648,
      -0.07149001210927963,
      -0.032708846032619476,
      -0.0566532276570797,
      -0.013630411587655544,
      0.007158502470701933,
      0.056433115154504776,
      -0.013578600250184536,
      -0.02043229155242443,
      -0.012221901677548885,
      0.059649620205163956,
      -0.06875554472208023,
      -0.06498043239116669,
      0.013289723545312881,
      0.06966955214738846,
      -0.028113072738051414,
      -0.054786454886198044,
      -0.028168631717562675,
      0.02087874338030815,
      0.03126868978142738,
      -0.0007670276099815965,
      -0.010914085432887077,
      0.0747373104095459,
      0.018981799483299255,
      0.05327644571661949,
      -0.04890371486544609,
      0.028109576553106308,
      0.05414794757962227,
      0.10742399841547012,
      -0.024521972984075546,
      -0.03333405405282974,
      0.019309742376208305,
      -0.07451824098825455,
      0.008652220480144024,
      -0.042791593819856644,
      0.00583058362826705,
      -0.05658771097660065,
      -0.02186441607773304,
      -0.08568673580884933,
      0.0349305234849453,
      -0.011434247717261314,
      -0.0899580866098404,
      -0.005921154282987118,
      0.0973152220249176,
      0.07970071583986282,
      0.0389566533267498,
      0.06884248554706573,
      -0.07385875284671783,
      0.002029016613960266,
      -0.0797201469540596,
      0.051452770829200745,
      0.0143821369856596,
      -0.016097916290163994,
      -0.03176523745059967,
      -0.02452143095433712,
      0.023856405168771744,
      -0.05406671762466431,
      0.0023662294261157513,
      -0.0073447260074317455,
      -0.007660300936549902,
      0.08273224532604218,
      0.043336499482393265,
      -0.008911496959626675,
      -0.026348143815994263,
      -0.02391287125647068,
      0.022449690848588943,
      0.039005015045404434,
      0.016845131292939186,
      -0.025413773953914642,
      -0.024960514158010483,
      -0.007258758880198002,
      -0.014190131798386574,
      -0.015442388132214546,
      0.00494620855897665,
      0.019070442765951157,
      -0.06954100728034973,
      0.022047432139515877,
      0.004855350591242313,
      0.04434646666049957,
      -0.08082844316959381,
      0.0020488989539444447,
      -0.08077336102724075,
      -0.03315182775259018,
      0.0013372740941122174,
      -0.02002767287194729,
      0.01395756471902132,
      0.003509251167997718,
      0.061185091733932495,
      -0.04023824632167816,
      0.018887439742684364,
      0.008421018719673157,
      0.03274333477020264,
      0.009309075772762299,
      -0.04393131658434868,
      -0.06720835715532303,
      -0.09168748557567596,
      -0.05141354724764824,
      0.0077394749969244,
      -0.053524717688560486,
      -0.023678740486502647,
      0.06656216830015182,
      -0.03546151518821716,
      0.017347529530525208,
      -0.021950630471110344,
      -0.030766770243644714,
      -0.009706078097224236,
      -0.012316050007939339,
      0.07399041950702667,
      -0.01317382138222456,
      0.003535172902047634,
      -0.010635470040142536,
      -0.04611096531152725,
      0.0064981719478964806,
      0.006016014609485865,
      0.03926875814795494,
      0.03449846804141998,
      0.038043759763240814,
      0.03832005336880684,
      -0.0207391194999218,
      -0.03625704348087311,
      -0.013449901714920998,
      -0.05829145386815071,
      0.025518516078591347,
      0.0863078385591507,
      0.01163547020405531,
      0.010781685821712017,
      -0.09125955402851105,
      0.043063435703516006,
      -0.10328628867864609,
      0.01438775286078453,
      0.002271974692121148,
      0.07519962638616562,
      -0.013871212489902973,
      0.023333895951509476,
      0.020481307059526443,
      0.020044732838869095,
      -0.033720340579748154,
      -0.07855252176523209,
      -0.12809713184833527,
      -0.011851629242300987,
      0.08939634263515472,
      0.0012638946063816547,
      0.0070426128804683685,
      0.07657473534345627,
      -0.022724855691194534,
      -0.01309801172465086,
      -0.06035324186086655,
      0.024704741314053535,
      0.08990451693534851,
      -0.008140426129102707,
      0.025858284905552864,
      -0.017687270417809486,
      0.011307447217404842,
      -0.06050524488091469,
      0.0491131991147995,
      0.022375429049134254,
      0.02745484933257103,
      0.0036087455227971077,
      0.04464755952358246,
      0.016034353524446487,
      -0.09344802051782608,
      -0.03070756606757641,
      -0.11411520838737488,
      -0.00526183657348156,
      0.025571364909410477,
      -0.017276383936405182,
      -0.0005102226859889925,
      0.08380996435880661,
      -0.031335726380348206,
      -0.05203213170170784,
      0.04254930093884468,
      -0.04248921945691109,
      -0.04348823055624962,
      -0.03299345076084137,
      -0.03188735246658325,
      -0.09665874391794205,
      0.038699012249708176,
      0.040480516850948334,
      0.0303038377314806,
      0.08056607097387314,
      0.014569741673767567,
      0.022389333695173264,
      0.004144185688346624,
      0.017640581354498863,
      -0.009541353210806847,
      0.038981545716524124,
      -0.003921639174222946,
      -0.0006186135578900576,
      0.004499237984418869,
      0.011510386131703854,
      -0.056358952075242996,
      0.042760975658893585,
      0.021412169560790062,
      0.00871453620493412,
      -0.06518422067165375,
      -0.02805103175342083,
      -0.011823314242064953,
      -0.08207709342241287,
      0.03612523153424263,
      -0.03785610571503639,
      0.0020339898765087128,
      -0.05663273483514786,
      0.019315000623464584,
      -0.0041747912764549255,
      0.0577872060239315,
      -0.047780200839042664,
      -0.10452097654342651,
      0.0021500284783542156,
      0.058904461562633514,
      -0.002320224419236183,
      0.031442802399396896,
      0.033938098698854446,
      -0.020668501034379005,
      -0.04057047888636589,
      -0.010264541953802109,
      -0.05621957778930664,
      -0.03530034050345421,
      0.05121805518865585,
      -0.03633130341768265,
      0.030774692073464394,
      0.04443934187293053,
      0.006940696854144335,
      0.022513430565595627,
      0.032113201916217804,
      -0.03163563460111618,
      0.03259734809398651,
      0.09752895683050156,
      0.020968306809663773,
      -0.023239053785800934,
      -0.006248523946851492,
      0.037291381508111954,
      -0.04633394256234169,
      0.03169110789895058,
      0.08437628298997879,
      -0.09711088985204697,
      0.003978592809289694,
      0.029153410345315933,
      0.02091568149626255,
      0.06261732429265976,
      -0.013770254328846931,
      -0.09809844195842743,
      -0.11813204735517502,
      0.025355054065585136,
      0.008559036999940872,
      0.056639332324266434,
      -0.05961048603057861,
      -0.1044120118021965,
      0.054730124771595,
      0.02943115495145321,
      -0.05965752154588699,
      0.10160958766937256,
      -0.026489494368433952,
      -0.05712197721004486,
      -0.019996507093310356,
      -0.032238904386758804,
      -0.082158163189888,
      0.012067949399352074,
      -0.022468693554401398,
      -0.061926085501909256,
      -0.06408762186765671,
      0.07174434512853622,
      -0.012921650893986225,
      -0.007184451445937157,
      -0.05033106729388237,
      0.04903298616409302,
      -0.02876616269350052,
      -0.028842248022556305,
      0.034664325416088104,
      0.09691856056451797,
      0.04254904016852379,
      -0.041214410215616226,
      0.018295062705874443,
      -0.0083980867639184,
      -0.07354560494422913,
      -0.03215179592370987,
      0.03701005503535271,
      -0.081318199634552,
      0.06652504950761795,
      0.004734659567475319,
      -0.03604705259203911,
      0.04448341950774193,
      -0.0720566138625145,
      -0.011579818092286587,
      -0.038401540368795395,
      0.01691468432545662,
      0.01936155930161476,
      0.06414593756198883,
      -0.02329600602388382,
      0.06655722111463547,
      -0.07132072746753693,
      0.006222378928214312,
      0.03588785231113434
    ],
    [
      0.014168756082654,
      -0.042397741228342056,
      -0.026098869740962982,
      -0.014702183194458485,
      0.012165660969913006,
      -0.09016193449497223,
      -0.06011032313108444,
      0.015085047110915184,
      0.03918738663196564,
      0.022967753931879997,
      -0.04754844680428505,
      -0.02138076350092888,
      -0.004145639482885599,
      0.019488785415887833,
      -0.005916007328778505,
      -0.004336920101195574,
      0.09685239940881729,
      0.011742956005036831,
      0.04180854558944702,
      0.06232842803001404,
      -0.061605729162693024,
      -0.008072844706475735,
      -0.08058401197195053,
      0.002483563032001257,
      -0.013620385900139809,
      0.036477696150541306,
      0.007762048859149218,
      -0.06978736817836761,
      0.003126781666651368,
      -0.06426417082548141,
      0.004631025716662407,
      -0.0688280314207077,
      -0.07478176057338715,
      0.017005234956741333,
      0.002462356351315975,
      0.01911037787795067,
      -0.08136388659477234,
      -0.02790231630206108,
      0.06036181002855301,
      0.04364742711186409,
      -0.007846950553357601,
      0.00022659270325675607,
      0.04815969988703728,
      0.020610816776752472,
      0.05078347772359848,
      0.028188904747366905,
      0.030704345554113388,
      0.014352979138493538,
      0.013275640085339546,
      0.01901412568986416,
      -0.0017658041324466467,
      0.07188424468040466,
      -0.07228309661149979,
      0.10852966457605362,
      -0.03781332075595856,
      -0.05197376012802124,
      0.019210312515497208,
      -0.005393265746533871,
      0.03573862835764885,
      0.02277054451406002,
      0.01792455092072487,
      -0.11817502230405807,
      -0.0010604115668684244,
      -0.05118728429079056,
      -0.05062438175082207,
      -0.02408718131482601,
      0.02478763461112976,
      -0.0030661849305033684,
      -0.015123406425118446,
      -0.027394860982894897,
      0.04105225205421448,
      0.022716371342539787,
      0.0556698702275753,
      -0.0355302095413208,
      -0.013392389751970768,
      0.04842288792133331,
      0.007768794894218445,
      0.04522968828678131,
      0.08567851781845093,
      0.14710842072963715,
      -0.03240763768553734,
      -0.03397557884454727,
      -0.07214822620153427,
      -0.06821116805076599,
      0.029143426567316055,
      0.007365657016634941,
      0.07563844323158264,
      0.03604765236377716,
      -0.01887493208050728,
      0.0025561985094100237,
      0.024534650146961212,
      0.04392601549625397,
      -0.07772555202245712,
      -0.10073471069335938,
      -0.013154608197510242,
      0.04748125374317169,
      0.04916180297732353,
      -0.0585954487323761,
      0.0592389777302742,
      -0.026220152154564857,
      0.004474767949432135,
      0.001298948423936963,
      0.05326860770583153,
      -0.006425768602639437,
      0.0462757907807827,
      0.03456992283463478,
      0.01785740815103054,
      -0.008332125842571259,
      -0.053952187299728394,
      0.008109299466013908,
      0.010107321664690971,
      0.010814975015819073,
      -0.03380826488137245,
      0.03810107335448265,
      0.07049787044525146,
      0.020126724615693092,
      -0.007427641190588474,
      0.09418162703514099,
      0.017727531492710114,
      0.017548590898513794,
      0.09288208931684494,
      -0.02741347998380661,
      0.0025458987802267075,
      0.02136356756091118,
      -0.02710130251944065,
      -0.059661220759153366,
      0.044170379638671875,
      -0.04118594527244568,
      0.008673086762428284,
      -0.0816836878657341,
      0.0019289491465315223,
      0.0015567027730867267,
      -0.03242465481162071,
      -0.02131548337638378,
      0.028028732165694237,
      -0.059835124760866165,
      -0.045546144247055054,
      -0.035698581486940384,
      0.013262990862131119,
      0.04373767226934433,
      -0.08470608294010162,
      -0.06993646174669266,
      0.05785573646426201,
      -0.019646143540740013,
      -0.02949993498623371,
      0.0259331576526165,
      -0.01885998621582985,
      -0.0421190969645977,
      0.031980641186237335,
      0.039248041808605194,
      -0.05419737845659256,
      0.005258207209408283,
      -0.00988971907645464,
      0.034902337938547134,
      0.02812488004565239,
      -0.02470129355788231,
      0.0402609258890152,
      0.03063933365046978,
      0.020580442622303963,
      -0.060560692101716995,
      0.10629028081893921,
      0.005211943294852972,
      -0.03450591862201691,
      -4.059438288095407e-05,
      0.024450309574604034,
      -0.05130104720592499,
      0.0836917906999588,
      -0.0012290574377402663,
      0.0019196064677089453,
      -0.09012819826602936,
      -0.06882352381944656,
      0.037816811352968216,
      -0.005777184385806322,
      0.03552871569991112,
      -0.08116408437490463,
      -0.09084512293338776,
      0.0052534774877130985,
      -0.04305317997932434,
      -0.01981331966817379,
      -0.032834652811288834,
      0.02731955423951149,
      -0.04979323968291283,
      0.023866986855864525,
      0.10849007219076157,
      -0.11620667576789856,
      -0.016613692045211792,
      0.016587192192673683,
      0.006071214564144611,
      -0.0009209390264004469,
      0.03825782611966133,
      0.04487721621990204,
      -0.0008616851409897208,
      -0.04606882855296135,
      0.01104937307536602,
      -0.03723112493753433,
      0.031036648899316788,
      0.060720693320035934,
      -0.014099535532295704,
      0.015873203054070473,
      0.043565139174461365,
      -0.04749492183327675,
      -0.014929250814020634,
      0.06152794510126114,
      -0.04334145784378052,
      0.04297488182783127,
      0.021671338006854057,
      -0.022593459114432335,
      0.021549569442868233,
      0.020216338336467743,
      -0.03680291399359703,
      -0.038067981600761414,
      0.013969384133815765,
      0.01770493946969509,
      -0.014745720662176609,
      0.08584530651569366,
      0.019180160015821457,
      -0.010532128624618053,
      0.06238175556063652,
      -0.04933779314160347,
      0.01663726195693016,
      -0.04232826828956604,
      0.003532411763444543,
      -0.003941440023481846,
      0.019419843330979347,
      0.06309583783149719,
      -0.013060524128377438,
      0.015639467164874077,
      0.019752206280827522,
      0.0898832157254219,
      0.04361946880817413,
      0.09968895465135574,
      -0.043190475553274155,
      0.08198358863592148,
      0.04359567537903786,
      0.054821278899908066,
      -0.006472531706094742,
      -0.043324749916791916,
      0.04322715103626251,
      0.037267331033945084,
      -0.021938970312476158,
      -0.008759726770222187,
      -0.02094454877078533,
      0.0015995996072888374,
      -0.009021788835525513,
      -0.0021379936952143908,
      -0.056076712906360626,
      0.013325436972081661,
      0.023441966623067856,
      -0.007260866928845644,
      0.03431543707847595,
      0.04287049174308777,
      -0.029830601066350937,
      0.008689562790095806,
      0.026735547930002213,
      0.016584279015660286,
      0.02810516022145748,
      -0.04737798869609833,
      -0.039954472333192825,
      0.0029524308629333973,
      -0.015734082087874413,
      0.03777683526277542,
      -0.014344940893352032,
      -0.012942099012434483,
      0.08718354254961014,
      -0.023955579847097397,
      -0.06074364483356476,
      0.021195484325289726,
      0.004506490658968687,
      0.07422449439764023,
      0.01593264378607273,
      0.030747056007385254,
      -0.06996727734804153,
      -0.006100003607571125,
      0.002056226832792163,
      0.02945701964199543,
      -0.03432313725352287,
      -0.026245329529047012,
      -0.056057121604681015,
      0.027678264304995537,
      0.09511986374855042,
      -0.004392489790916443,
      0.05190854147076607,
      0.03272058814764023,
      0.02404659241437912,
      -0.01076680701225996,
      -0.03956259787082672,
      0.015173079445958138,
      -0.03629413992166519,
      0.027925515547394753,
      0.020633114501833916,
      0.041648980230093,
      -0.027995282784104347,
      0.0326642282307148,
      0.002004939829930663,
      0.07133223116397858,
      -0.011541461572051048,
      -0.014411112293601036,
      0.09706670045852661,
      0.033119045197963715,
      0.03872353211045265,
      0.016565313562750816,
      -0.013095906004309654,
      0.013278080150485039,
      -0.022896166890859604,
      0.00019480730406939983,
      -0.028899434953927994,
      -0.04715105518698692,
      -0.026948319748044014,
      -0.013542800210416317,
      -0.004488390404731035,
      -0.08622170239686966,
      0.022756770253181458,
      -0.06573609262704849,
      -0.07937764376401901,
      -0.08197468519210815,
      0.011069295927882195,
      0.06559022516012192,
      -0.13455413281917572,
      -0.028990833088755608,
      -0.01544335950165987,
      0.05432714894413948,
      -0.012167503125965595,
      -0.026821987703442574,
      0.0659104436635971,
      0.02803497016429901,
      -0.028332572430372238,
      -0.0021629054099321365,
      0.0007636499358341098,
      0.046264126896858215,
      -0.09263712912797928,
      -0.012641162611544132,
      0.020584970712661743,
      -0.03404625132679939,
      -0.04149334877729416,
      -0.043688565492630005,
      -0.005984276533126831,
      0.09149693697690964,
      0.054452426731586456,
      0.020542677491903305,
      -0.027394093573093414,
      -0.006251374259591103,
      -0.03991793096065521,
      -0.019588211551308632,
      0.019877992570400238,
      0.07406236231327057,
      0.003966156858950853,
      -0.003775777295231819,
      -0.006199039984494448,
      -0.0009678662172518671,
      -0.008290854282677174,
      -0.10607417672872543,
      0.06087141111493111,
      0.037809692323207855,
      -0.08148280531167984,
      0.010355011560022831,
      -0.020034756511449814,
      -0.07090552151203156,
      0.03264094144105911,
      0.09103885293006897,
      -0.05013613775372505,
      0.03995034098625183,
      -0.026675470173358917,
      -0.02495422586798668,
      -0.0037534304428845644,
      0.016863394528627396,
      0.013168505392968655,
      -0.04350012540817261,
      0.0148641811683774,
      0.00017710993415676057,
      0.059248439967632294,
      -0.03021608106791973,
      0.003970571793615818,
      0.025702456012368202,
      0.02404586225748062,
      0.029479824006557465,
      -0.03385717421770096,
      -0.05378736928105354,
      -0.0429597832262516,
      -0.01840377412736416,
      -0.012999100610613823,
      -0.0297471322119236,
      0.05005032569169998,
      0.07295557856559753,
      -0.034991584718227386,
      0.040218695998191833,
      0.0012939464068040252,
      -0.02661469578742981,
      0.051280856132507324,
      0.013143314979970455,
      0.00898422859609127,
      0.006241064053028822,
      0.012805280275642872,
      -0.0043893600814044476,
      -0.09725406765937805,
      0.08163495361804962,
      -0.010624510236084461,
      -0.007228588219732046,
      0.026071103289723396,
      -0.06682198494672775,
      -0.04075852409005165,
      -0.024607378989458084,
      -0.03604177385568619,
      0.025683892890810966,
      0.05920358747243881,
      0.019849926233291626,
      0.04209623113274574,
      -0.007435080595314503,
      0.01790326088666916,
      -0.03593842685222626,
      0.04960495978593826,
      -0.04515829682350159,
      0.01294819824397564,
      -0.011806250549852848,
      0.050014104694128036,
      -0.0055905915796756744,
      0.03386939316987991,
      -0.04326004162430763,
      0.02410275675356388,
      -0.0023782113566994667,
      0.07447858899831772,
      -0.03145409747958183,
      0.04064765200018883,
      0.027906280010938644,
      -0.01583617366850376,
      0.025644099339842796,
      -0.06195751577615738,
      -0.01516941748559475,
      0.0038063800893723965,
      -0.024677425622940063,
      -0.07738181203603745,
      0.060053423047065735,
      -0.0016599098453298211,
      0.04186031594872475,
      -0.04866575077176094,
      -0.0004408526874613017,
      -0.012145979329943657,
      -0.006904561538249254,
      -0.03384149447083473,
      -0.03281557932496071,
      0.009248732589185238,
      -0.03071712702512741,
      0.013241195119917393,
      0.02156565897166729,
      0.03413031995296478,
      0.014712980017066002,
      -0.09483898431062698,
      0.06007809191942215,
      -0.07364043593406677,
      0.021582702174782753,
      -0.01665186882019043,
      0.08094926923513412,
      -0.022128744050860405,
      0.04337543994188309,
      -0.0028692048508673906,
      -0.030943023040890694,
      0.04098667576909065,
      0.004160140175372362,
      0.09856138378381729,
      0.035238947719335556,
      0.024296898394823074,
      -0.0006247080746106803,
      0.014853865839540958,
      -0.03910223767161369,
      -0.06873982399702072,
      -0.09952138364315033,
      -0.05613584816455841,
      0.0236516036093235,
      -0.02933402732014656,
      0.020542649552226067,
      -0.07946040481328964,
      -0.009420371614396572,
      -0.04292645677924156,
      -0.027136696502566338,
      0.028077490627765656,
      0.07144682854413986,
      0.016310160979628563,
      0.016024738550186157,
      -0.08644189685583115,
      -0.016981394961476326,
      0.029013415798544884,
      -0.03933853656053543,
      -0.03306072577834129,
      -0.049401137977838516,
      -0.025250308215618134,
      0.019331123679876328,
      0.010357589460909367,
      0.04388389363884926,
      -0.09453362226486206,
      -0.02041664905846119,
      0.017069416120648384,
      0.06984098255634308,
      -0.02837766334414482,
      -0.0018586373189464211,
      -0.0036383545957505703,
      0.060773011296987534,
      -0.04941839352250099,
      0.052779421210289,
      -0.01919594593346119,
      0.022005489096045494,
      0.026147644966840744,
      -0.006160682998597622,
      0.026377256959676743,
      0.02324441261589527
    ],
    [
      -0.03468646854162216,
      -0.04296582192182541,
      0.027502648532390594,
      -0.01633254997432232,
      0.04669040068984032,
      0.014880687929689884,
      -0.062442585825920105,
      0.06886220723390579,
      0.11309774219989777,
      -0.033026326447725296,
      -0.00839330069720745,
      -0.06301236152648926,
      -0.029734507203102112,
      -0.061791058629751205,
      -0.006131818983703852,
      -0.003553265007212758,
      -0.08990669250488281,
      0.03883308172225952,
      0.001217666082084179,
      0.002364021958783269,
      0.0022353515960276127,
      0.11113385111093521,
      0.04560631886124611,
      0.019234037026762962,
      -0.0732482373714447,
      0.06022191047668457,
      0.04120327904820442,
      -0.010193231515586376,
      0.06779623031616211,
      -0.041168514639139175,
      0.012887301854789257,
      -0.008342674002051353,
      -0.008992853574454784,
      0.029890993610024452,
      0.04255438596010208,
      -0.03305980563163757,
      -0.05158871039748192,
      -0.020092453807592392,
      0.014904778450727463,
      0.03128437697887421,
      -0.01070551760494709,
      0.058349695056676865,
      -0.012303528375923634,
      -0.029426971450448036,
      -0.028854094445705414,
      -0.04382089897990227,
      -0.08193265646696091,
      0.003099994035437703,
      0.07565662264823914,
      -0.04170731455087662,
      -0.009441831149160862,
      0.030571062117815018,
      -0.04166167974472046,
      -0.017446527257561684,
      0.042195484042167664,
      -0.02202870137989521,
      0.08370209485292435,
      0.01997990347445011,
      0.008362614549696445,
      -0.00323181482963264,
      0.010381226427853107,
      0.017003122717142105,
      -0.009196174331009388,
      -0.040248118340969086,
      0.05411559343338013,
      0.021100763231515884,
      -0.0598309300839901,
      -0.07758454978466034,
      0.027339085936546326,
      0.010151625610888004,
      -0.015867600217461586,
      -0.027900585904717445,
      0.013619952835142612,
      -0.06357038021087646,
      -0.0024907472543418407,
      -0.013535486534237862,
      -0.02518421970307827,
      -0.05820435658097267,
      0.1266375035047531,
      0.04903878644108772,
      0.002999967662617564,
      0.054141778498888016,
      -0.03313327580690384,
      0.12726610898971558,
      -0.03517468273639679,
      0.05967852100729942,
      0.0389399416744709,
      0.011632600799202919,
      0.024799559265375137,
      -0.08175554126501083,
      0.03909784182906151,
      -0.014234300702810287,
      0.026586828753352165,
      -0.060043543577194214,
      -0.0009330799803137779,
      0.023624958470463753,
      -0.0027340541128069162,
      -0.025516008958220482,
      -0.06809761375188828,
      -0.09968449920415878,
      0.02590857818722725,
      -0.030162494629621506,
      -0.026430867612361908,
      -0.017586860805749893,
      -0.04239941015839577,
      -0.027213139459490776,
      -0.024272071197628975,
      0.04355023428797722,
      -0.04380648210644722,
      0.007249453105032444,
      0.015357442200183868,
      0.0033416529186069965,
      0.04325847700238228,
      0.006733404006808996,
      -0.03105207532644272,
      -0.04286111891269684,
      -0.013156226836144924,
      0.019871635362505913,
      -0.013589894399046898,
      -0.043560244143009186,
      -0.04124341532588005,
      -0.0037832290399819613,
      -0.02576102688908577,
      0.030942434445023537,
      -0.019971420988440514,
      0.06812457740306854,
      0.005248541012406349,
      -0.049238018691539764,
      0.0900888741016388,
      -0.0836019292473793,
      -0.0873856246471405,
      0.017498167231678963,
      -0.07023802399635315,
      0.025323951616883278,
      -0.04908836632966995,
      -0.009506097994744778,
      -0.02909768931567669,
      -0.06484281271696091,
      0.016757648438215256,
      -0.0382428914308548,
      -0.026858484372496605,
      0.03663481026887894,
      0.03657376021146774,
      0.009731393307447433,
      -0.025748558342456818,
      -0.030380135402083397,
      0.02853931114077568,
      -0.027504660189151764,
      0.048229314386844635,
      0.028703410178422928,
      -0.10903488099575043,
      -0.007426494266837835,
      0.03327403962612152,
      0.0010538409696891904,
      0.018796728923916817,
      -0.014766513369977474,
      -0.06284084171056747,
      -0.011929532513022423,
      0.03141738846898079,
      0.07630150020122528,
      -0.02678207866847515,
      -0.07775624096393585,
      -0.01783887855708599,
      0.019603408873081207,
      -0.03311125561594963,
      -0.07569737732410431,
      -0.0032270383089780807,
      -0.033962998539209366,
      -0.02423553727567196,
      0.02850697748363018,
      -0.04836065694689751,
      0.07726356387138367,
      0.012078850530087948,
      -0.007132810540497303,
      0.036762624979019165,
      -0.019328827038407326,
      -0.010460828430950642,
      0.07924515753984451,
      0.015461727976799011,
      -0.05334336683154106,
      0.048608582466840744,
      -0.043682705610990524,
      0.005499952472746372,
      -0.05607526749372482,
      -0.09493041783571243,
      0.018282098695635796,
      0.0006631836877204478,
      -0.04041961953043938,
      -0.02052893117070198,
      -0.03506571799516678,
      -0.004301723558455706,
      -0.032208722084760666,
      0.06585099548101425,
      0.10561202466487885,
      -0.06151215732097626,
      -0.01629714109003544,
      -0.049905095249414444,
      0.03710274398326874,
      0.08927685022354126,
      -0.09939523041248322,
      0.008718977682292461,
      -0.031076641753315926,
      -0.04582662135362625,
      -0.008405815809965134,
      -0.01061572227627039,
      -0.04130564257502556,
      0.02096703089773655,
      -0.05475065857172012,
      -0.06325118988752365,
      0.012428250163793564,
      0.013154757209122181,
      -0.07350367307662964,
      0.013888710178434849,
      0.11485081166028976,
      0.02019563689827919,
      0.11297053843736649,
      -0.02554324083030224,
      -0.09432511031627655,
      -0.06911659985780716,
      -0.08621658384799957,
      -0.010930153541266918,
      0.02111458219587803,
      -0.000423015677370131,
      0.03454132750630379,
      -0.013981838710606098,
      0.002566486829891801,
      -0.05109488591551781,
      0.0237717404961586,
      0.01128731295466423,
      -0.020645497366786003,
      0.042969562113285065,
      -0.0648634061217308,
      0.0026286235079169273,
      0.05628083273768425,
      -0.010627212934195995,
      -0.06603419780731201,
      -0.012428374961018562,
      0.02355274185538292,
      0.014054683037102222,
      0.015360133722424507,
      0.04482666030526161,
      0.007803034503012896,
      0.026549916714429855,
      -0.04590816795825958,
      0.10282304137945175,
      -0.030866598710417747,
      -0.022832496091723442,
      -0.05567348375916481,
      0.002367004519328475,
      -0.05901196226477623,
      -0.05331454426050186,
      0.023261306807398796,
      -0.02853175438940525,
      -0.04058654233813286,
      0.08094708621501923,
      0.03243013471364975,
      -0.03985588252544403,
      0.017258208245038986,
      -0.00561497500166297,
      0.002526108408346772,
      -0.006722252815961838,
      0.07436968386173248,
      -0.004699309356510639,
      -0.024441583082079887,
      -0.0072448160499334335,
      0.023168860003352165,
      -0.022668493911623955,
      -0.0035356548614799976,
      -0.05129474774003029,
      0.028734425082802773,
      3.502576146274805e-05,
      -0.014982189051806927,
      -0.051971279084682465,
      -0.04698716476559639,
      -0.020646139979362488,
      0.0698825791478157,
      -0.05024505406618118,
      -0.003482746658846736,
      0.025307010859251022,
      0.013800160959362984,
      -0.08217007666826248,
      0.024821631610393524,
      0.03190639615058899,
      0.0232712272554636,
      -0.020187120884656906,
      0.0438162162899971,
      -0.011873828247189522,
      0.0920104905962944,
      0.07801977545022964,
      -0.035477809607982635,
      0.05407550185918808,
      -0.07974391430616379,
      0.02486511506140232,
      -0.0351291261613369,
      -0.0531860776245594,
      0.10687776654958725,
      0.06331105530261993,
      0.0468241423368454,
      -0.03422713279724121,
      -0.041836321353912354,
      0.020230725407600403,
      -0.07174760848283768,
      -0.08523334562778473,
      0.008817856200039387,
      0.03681638836860657,
      -0.0558558888733387,
      0.039192184805870056,
      -0.016408203169703484,
      0.0008561343420296907,
      0.003967735450714827,
      -0.04200063645839691,
      -0.052105218172073364,
      -0.05280737206339836,
      0.03841550275683403,
      0.002679712139070034,
      0.005283654667437077,
      -0.023699672892689705,
      0.023826632648706436,
      -0.010477432049810886,
      0.028013236820697784,
      0.008247210644185543,
      -0.026989825069904327,
      0.05308670178055763,
      0.0018453176598995924,
      -0.0017668385989964008,
      0.018296988680958748,
      0.013665641658008099,
      -0.0036982090678066015,
      0.020915266126394272,
      -0.009985644370317459,
      -0.02602068893611431,
      0.00332143297418952,
      -0.040959201753139496,
      0.05049337446689606,
      -0.05812843143939972,
      0.023176709190011024,
      -0.01845620758831501,
      -0.02948620356619358,
      -0.0033262446522712708,
      -0.0655493438243866,
      0.03283059224486351,
      0.038241639733314514,
      0.010925574228167534,
      0.006307259202003479,
      -0.02776942029595375,
      -0.023455031216144562,
      0.03752749413251877,
      -0.05298249423503876,
      0.03169574961066246,
      -0.0026567985769361258,
      0.015533857978880405,
      0.00966920331120491,
      0.009247101843357086,
      0.046096447855234146,
      -0.049994442611932755,
      0.031239571049809456,
      -0.003255516989156604,
      0.07159919291734695,
      0.05916031077504158,
      -0.06537708640098572,
      -0.008375567384064198,
      0.030596893280744553,
      0.06594687700271606,
      0.07222335785627365,
      0.010545392520725727,
      -0.007266913540661335,
      -0.05420411750674248,
      0.05585620179772377,
      0.061619747430086136,
      -0.05249486863613129,
      0.01567091792821884,
      -0.0722678005695343,
      -0.04988279566168785,
      0.01142487209290266,
      -0.06712691485881805,
      0.040781937539577484,
      -0.014673419296741486,
      0.003918106202036142,
      0.0213333610445261,
      -0.084355928003788,
      0.026702748611569405,
      -0.001449605799280107,
      -0.011022191494703293,
      -0.007959050126373768,
      -0.04709359630942345,
      -0.02100643701851368,
      0.07090707868337631,
      0.002555461600422859,
      0.03641975671052933,
      -0.07982934266328812,
      -0.005124119576066732,
      0.0144541896879673,
      -0.07007376104593277,
      0.09357860684394836,
      0.0015803343849256635,
      0.04690675437450409,
      -0.10750410705804825,
      0.022128699347376823,
      -0.04888930916786194,
      -0.03678477928042412,
      0.0016890824772417545,
      0.08274523913860321,
      0.048809923231601715,
      -0.01215094793587923,
      0.06654776632785797,
      0.030329646542668343,
      0.08427947014570236,
      0.09563343226909637,
      -0.017574632540345192,
      -0.04016141593456268,
      0.047315098345279694,
      0.05682859942317009,
      -0.011128129437565804,
      -0.0054304408840835094,
      0.030834194272756577,
      -0.04918331280350685,
      -0.007515988778322935,
      0.044412922114133835,
      -0.0341377854347229,
      0.00313582899980247,
      -0.05842093005776405,
      0.060191694647073746,
      -0.07658115774393082,
      0.007243892177939415,
      0.0553002692759037,
      0.012738721445202827,
      0.05998077243566513,
      0.004552789032459259,
      -0.029891924932599068,
      -0.12300512194633484,
      0.0007655311492271721,
      0.06234166398644447,
      0.011232576332986355,
      0.10340519994497299,
      -0.03216998279094696,
      -0.01180977188050747,
      0.030739562585949898,
      0.032228998839855194,
      -0.07126333564519882,
      -0.005313349422067404,
      0.09245274215936661,
      0.020895982161164284,
      -0.06247050687670708,
      -0.032105498015880585,
      0.008231713436543941,
      -0.013812604360282421,
      -0.1080557182431221,
      -0.0824882760643959,
      -0.009605486877262592,
      0.0158649031072855,
      0.01921861618757248,
      0.0392051599919796,
      -0.0499618835747242,
      -0.002116001909598708,
      -0.026744093745946884,
      0.014171932823956013,
      9.929380030371249e-05,
      0.06995681673288345,
      0.0788741260766983,
      0.09600900113582611,
      -0.010565992444753647,
      0.10461011528968811,
      -0.04955229163169861,
      -0.06645868718624115,
      -0.052694521844387054,
      -0.018380768597126007,
      0.03174203261733055,
      -0.01569472812116146,
      -0.045302990823984146,
      -0.006552231963723898,
      0.03902608901262283,
      0.0030117491260170937,
      -0.007030185777693987,
      -0.054053205996751785,
      0.00707052880898118,
      -0.022025594487786293,
      0.004425497725605965,
      -0.004663380328565836,
      0.041275765746831894,
      -0.0023891122546046972,
      -0.022610971704125404,
      -0.019595427438616753,
      0.0493038073182106,
      0.010800235904753208,
      0.13815559446811676,
      0.009785022586584091,
      0.001372592058032751,
      0.040032822638750076,
      0.08740918338298798,
      0.03130112960934639,
      -0.06228840723633766,
      0.006110358517616987,
      0.03717958927154541,
      0.040400322526693344,
      0.06143517047166824,
      -0.02405223436653614,
      0.02316979691386223,
      -0.10996968299150467,
      -0.023029053583741188,
      0.07605337351560593,
      0.017507163807749748,
      0.01538078859448433,
      0.014987754635512829
    ],
    [
      0.07958366721868515,
      -0.041532982140779495,
      0.055779457092285156,
      -0.03840033337473869,
      -0.024750297889113426,
      -0.027862345799803734,
      -0.004230515565723181,
      0.017631683498620987,
      0.07505020499229431,
      -0.08288038522005081,
      -0.05416101962327957,
      -0.04623129218816757,
      0.0011116692330688238,
      0.02278776839375496,
      0.025196654722094536,
      0.01701202616095543,
      0.08967507630586624,
      -0.029318619519472122,
      -0.004192684777081013,
      -0.01638358272612095,
      -0.013148517347872257,
      0.0663883239030838,
      0.046776801347732544,
      0.04571392014622688,
      0.04233269765973091,
      0.04710669443011284,
      -0.023451220244169235,
      0.015474853105843067,
      0.020594313740730286,
      -0.01269991509616375,
      0.0039889393374323845,
      -0.0026562970597296953,
      0.012273574247956276,
      0.08015275001525879,
      -0.0063072494231164455,
      -0.03963911160826683,
      0.015618794597685337,
      0.09527285397052765,
      0.026809358969330788,
      -0.08108734339475632,
      0.05678599327802658,
      0.050664737820625305,
      0.08029942214488983,
      -0.040426936000585556,
      0.044757597148418427,
      -0.004845066461712122,
      -0.020548827946186066,
      0.017778852954506874,
      0.03670652583241463,
      -0.01531238667666912,
      0.02756168320775032,
      -0.07344172894954681,
      0.01282321847975254,
      0.0686672180891037,
      0.00894223339855671,
      0.01937195286154747,
      0.0932522788643837,
      0.024951793253421783,
      0.07346814125776291,
      0.020863240584731102,
      0.027765629813075066,
      0.01563986949622631,
      0.033379316329956055,
      -0.0889144018292427,
      -0.08754841238260269,
      -0.08632359653711319,
      0.01286655105650425,
      0.032059624791145325,
      -0.029502201825380325,
      0.06941469013690948,
      0.012362824752926826,
      -0.004955942276865244,
      0.02759675309062004,
      -0.058767225593328476,
      -0.013259857892990112,
      0.057241007685661316,
      0.006434915587306023,
      -0.00040581205394119024,
      0.007811662275344133,
      0.014610655605793,
      0.0011335713788866997,
      -0.014208708889782429,
      -0.014842835254967213,
      0.1427140235900879,
      -0.006150643806904554,
      0.05183887481689453,
      -0.019764410331845284,
      -0.025679996237158775,
      0.07363404333591461,
      0.009733878076076508,
      -0.008084280416369438,
      0.019793815910816193,
      -0.07369044423103333,
      -0.004331210162490606,
      0.026551373302936554,
      -0.06367213279008865,
      0.040807049721479416,
      -0.03854893147945404,
      -0.01411378476768732,
      -0.019340015947818756,
      -0.0017848165007308125,
      -0.006143780425190926,
      0.04703675955533981,
      -0.018873758614063263,
      -0.03549044206738472,
      -0.010979413986206055,
      -0.01741313748061657,
      0.042618777602910995,
      -0.01564883440732956,
      0.020177356898784637,
      0.0499616302549839,
      0.019000118598341942,
      0.0019776036497205496,
      -0.0036956563126295805,
      -0.028457654640078545,
      0.019222266972064972,
      -0.06832614541053772,
      0.006964764557778835,
      0.005322683602571487,
      -0.06483016163110733,
      0.06810170412063599,
      0.03957805782556534,
      0.03145751357078552,
      0.028719469904899597,
      0.0879819318652153,
      -0.027661733329296112,
      0.08821146190166473,
      -0.12122541666030884,
      0.015624070540070534,
      -0.022472470998764038,
      0.10501362383365631,
      0.04423510655760765,
      -0.004923074971884489,
      -0.019837507978081703,
      -0.008596827276051044,
      -0.0647432878613472,
      0.03497037664055824,
      0.010501554235816002,
      -0.001308221137151122,
      0.046196796000003815,
      0.04442807286977768,
      -0.018138792365789413,
      0.058122165501117706,
      -0.08965420722961426,
      0.025379393249750137,
      -0.02498651295900345,
      -0.036128491163253784,
      -0.02465702034533024,
      -0.04056975245475769,
      -0.0006104653584770858,
      0.029931649565696716,
      0.028905173763632774,
      -0.021217992529273033,
      0.018010452389717102,
      0.004735173657536507,
      -0.011362026445567608,
      -0.00035459661739878356,
      -0.05933035910129547,
      0.00022509583504870534,
      -0.09089688211679459,
      -0.003615196794271469,
      0.03532444313168526,
      0.021565277129411697,
      0.004258802626281977,
      -0.10280775278806686,
      -0.09206745773553848,
      -0.006788245867937803,
      0.05288161709904671,
      -0.05500270053744316,
      -0.04239092022180557,
      -0.09984097629785538,
      0.05412712320685387,
      0.02575090527534485,
      -0.0227948185056448,
      -0.015110969543457031,
      0.016361702233552933,
      0.053624287247657776,
      -0.049814365804195404,
      0.06119859963655472,
      -0.06735038012266159,
      -0.0037844590842723846,
      0.04833821579813957,
      -0.054104726761579514,
      0.05178535729646683,
      -0.09129347652196884,
      0.01667756773531437,
      -0.009928366169333458,
      -0.11069779098033905,
      -0.020543476566672325,
      -0.056334488093853,
      -0.01046178862452507,
      -0.0035097356885671616,
      0.016340196132659912,
      0.01788736879825592,
      -0.01266260165721178,
      0.0350412093102932,
      0.005657249595969915,
      -0.010078315623104572,
      -0.012057023122906685,
      0.003638978349044919,
      -0.002993005095049739,
      -0.03713429719209671,
      -0.02859637513756752,
      -0.008331897668540478,
      -0.0577516071498394,
      -0.008869577199220657,
      0.0260944664478302,
      -0.019722770899534225,
      -0.08988187462091446,
      -0.04507733881473541,
      -0.007689305115491152,
      0.018046358600258827,
      0.018736418336629868,
      0.009382744319736958,
      -0.0430898480117321,
      -0.04043976217508316,
      -0.017250550910830498,
      -0.0506296269595623,
      -0.03519672900438309,
      0.06198063865303993,
      -0.0716291218996048,
      -0.006352093070745468,
      -0.05543211102485657,
      -0.06604944169521332,
      -0.00515349768102169,
      0.007259896956384182,
      -0.040133170783519745,
      0.058774758130311966,
      -0.03635996952652931,
      -0.047822292894124985,
      -0.026875874027609825,
      -0.009427416138350964,
      -0.03701987862586975,
      0.08170753717422485,
      -0.05014703422784805,
      0.020647864788770676,
      0.0009740845998749137,
      -0.024669351056218147,
      -0.006870568729937077,
      0.046761929988861084,
      -0.021826909855008125,
      -0.0006397959659807384,
      -0.041997991502285004,
      -0.06700874119997025,
      -0.01837930455803871,
      -0.002193002961575985,
      0.03799945488572121,
      0.06258834898471832,
      0.024828126654028893,
      -2.5910483600455336e-05,
      -0.04591674357652664,
      -0.003563042962923646,
      -0.09804052114486694,
      -0.055169522762298584,
      -0.006901927758008242,
      -0.06428783386945724,
      0.0034831915982067585,
      0.03275671973824501,
      -0.0683937668800354,
      -0.006605761591345072,
      -0.08056599646806717,
      -0.07695665955543518,
      0.02059093303978443,
      0.07487113773822784,
      -0.018113307654857635,
      -0.0002563171146903187,
      0.04398543760180473,
      0.053852323442697525,
      -0.04897233098745346,
      -0.03990061208605766,
      -0.03408615663647652,
      -0.052319757640361786,
      -0.021418973803520203,
      -0.07052202522754669,
      0.06567169725894928,
      0.008740426041185856,
      -0.025816917419433594,
      -0.04031658545136452,
      0.08825898170471191,
      -0.0033202681224793196,
      0.015084546990692616,
      0.048386674374341965,
      0.008645446971058846,
      0.09302938729524612,
      0.008065521717071533,
      -0.026099979877471924,
      -0.004099107813090086,
      0.017660532146692276,
      0.02580099180340767,
      0.004844127222895622,
      -0.028020594269037247,
      -0.09037983417510986,
      -0.05265956372022629,
      -0.005762266460806131,
      -0.08055093884468079,
      0.0007591660832986236,
      0.0006371813360601664,
      -0.055974844843149185,
      -0.0035954946652054787,
      0.03795984014868736,
      -0.022189365699887276,
      0.04489327222108841,
      0.025352146476507187,
      -0.04231322929263115,
      -0.022824449464678764,
      0.006249555852264166,
      0.022447142750024796,
      -0.02941695600748062,
      0.06230328977108002,
      0.05671650916337967,
      0.04155702143907547,
      -0.010739395394921303,
      0.03283650800585747,
      -0.09545399993658066,
      0.0013839853927493095,
      0.013537202030420303,
      -0.01562925986945629,
      0.007743014022707939,
      -0.009530027396976948,
      0.004195448476821184,
      0.06104372814297676,
      -0.014455539174377918,
      -0.02022523432970047,
      -0.016124794259667397,
      -0.025481263175606728,
      0.02436644956469536,
      -0.058670517057180405,
      0.030927136540412903,
      0.006170754786580801,
      -0.020403064787387848,
      0.003024432808160782,
      0.07578153908252716,
      -0.026768634095788002,
      -0.02916833944618702,
      0.07115070521831512,
      0.03205224871635437,
      -0.02988668717443943,
      -0.015029016882181168,
      -0.048509158194065094,
      0.003171724732965231,
      -0.0008449213928543031,
      0.002890136791393161,
      -0.1066189780831337,
      0.0282958522439003,
      -0.009437348693609238,
      -0.030083175748586655,
      -0.013543078675866127,
      0.08622201532125473,
      -0.006519372574985027,
      -0.04543961212038994,
      -0.005474315490573645,
      0.00830380991101265,
      0.006141352467238903,
      0.012736072763800621,
      -0.024695226922631264,
      0.048036687076091766,
      0.010936340317130089,
      0.03951391205191612,
      0.005070094484835863,
      -0.07989486306905746,
      0.047539010643959045,
      0.07229207456111908,
      0.07907586544752121,
      -0.02310062013566494,
      -0.027725934982299805,
      0.06667839735746384,
      0.001044972101226449,
      0.03134888410568237,
      0.014489181339740753,
      -0.0060996985994279385,
      0.02882617525756359,
      -0.04396921396255493,
      -0.07250519096851349,
      -0.05165204778313637,
      0.03334226831793785,
      -0.03197440877556801,
      0.006734633352607489,
      0.039897482842206955,
      -0.0064158993773162365,
      -0.020204121246933937,
      -0.04811592027544975,
      0.07616961747407913,
      0.03289755433797836,
      0.09072995185852051,
      0.06414643675088882,
      0.05292237550020218,
      -0.0005685409414581954,
      -0.00982391182333231,
      -0.0027459010016173124,
      -0.01966577023267746,
      -0.049677371978759766,
      0.0017103811260312796,
      -0.01034862082451582,
      0.043035488575696945,
      0.03404081612825394,
      0.0026998280081897974,
      0.04903487488627434,
      0.019588492810726166,
      -0.056439924985170364,
      -0.02674884907901287,
      -0.0335233099758625,
      0.0783102810382843,
      -0.010129389353096485,
      -0.049046315252780914,
      0.025459811091423035,
      0.03890760615468025,
      -0.03715898096561432,
      0.01314494013786316,
      0.003066723933443427,
      0.010641619563102722,
      0.004544512368738651,
      -0.05277859419584274,
      -0.001391993835568428,
      0.02293333224952221,
      -0.03035806678235531,
      0.04560098424553871,
      0.009861772879958153,
      0.02823670022189617,
      0.020148346200585365,
      0.007630504202097654,
      -0.03604025021195412,
      -0.056312207132577896,
      0.0765276700258255,
      0.0034644072875380516,
      0.030255701392889023,
      -0.02122911997139454,
      0.05151389539241791,
      -0.002736532362177968,
      -0.036937423050403595,
      -0.02971760369837284,
      -0.06109369173645973,
      -0.11303124576807022,
      0.0429590605199337,
      -0.04887019842863083,
      -0.011081598699092865,
      0.0013496034080162644,
      0.004121534992009401,
      -0.026661045849323273,
      -0.009133150801062584,
      0.09772664308547974,
      0.02564777433872223,
      -0.0008256738656200469,
      0.010257137939333916,
      0.005424800328910351,
      -0.02108396217226982,
      -0.0024306036066263914,
      0.05050274357199669,
      0.0027948759961873293,
      0.07841815799474716,
      -0.009308559820055962,
      -0.010012742131948471,
      0.038641203194856644,
      -0.021077672019600868,
      -0.043928492814302444,
      0.10014264285564423,
      -0.05120817944407463,
      -0.05110032856464386,
      -0.0008563653100281954,
      -0.01931721158325672,
      -0.07693801075220108,
      -0.006077717524021864,
      0.05594800412654877,
      -0.01638096198439598,
      0.019893363118171692,
      0.05010790750384331,
      0.06395741552114487,
      0.024120943620800972,
      -0.0043558236211538315,
      -0.01839848794043064,
      0.03612527623772621,
      0.01064610667526722,
      -0.0889841690659523,
      -0.03174389526247978,
      -0.02167607843875885,
      0.06764519214630127,
      -0.015321256592869759,
      0.029536938294768333,
      0.08323077857494354,
      -0.015605660155415535,
      0.004533523228019476,
      0.04945316165685654,
      0.02877200022339821,
      -0.028863506391644478,
      -0.01010056771337986,
      0.03736583888530731,
      0.03261083737015724,
      0.014565235003829002,
      0.01019719336181879,
      0.040518004447221756,
      -0.018434859812259674,
      -0.03734704107046127,
      -0.02828853577375412,
      -0.005576964933425188,
      0.030516259372234344,
      -0.053635504096746445,
      0.042911432683467865,
      0.038517605513334274,
      0.028304779902100563,
      -0.0215892493724823,
      -0.03745602071285248,
      -0.020741617307066917,
      0.09676992893218994,
      -0.009720616042613983
    ],
    [
      -0.01324031874537468,
      -0.004838272929191589,
      -0.08284194767475128,
      -0.08352839946746826,
      0.01778222620487213,
      -0.01199391670525074,
      0.0045897033996880054,
      0.06343743205070496,
      -0.017604462802410126,
      0.11046647280454636,
      0.026135213673114777,
      -0.03043694607913494,
      0.0034896612633019686,
      -0.010355192236602306,
      -0.021318627521395683,
      0.03385297954082489,
      0.022480759769678116,
      0.00021223875228315592,
      -0.08458075672388077,
      -0.02797977812588215,
      -0.07802845537662506,
      -0.046331651508808136,
      -0.12545636296272278,
      0.05755981430411339,
      0.040557071566581726,
      0.08934081345796585,
      0.008797954767942429,
      -0.09038738161325455,
      0.006457943934947252,
      -0.037504781037569046,
      0.03118858113884926,
      0.03486085683107376,
      0.017498614266514778,
      0.008284580893814564,
      0.07428259402513504,
      0.04709869623184204,
      0.012682005763053894,
      0.06855472922325134,
      0.06580956280231476,
      -0.02411923184990883,
      0.04365713521838188,
      -0.036599185317754745,
      0.018277129158377647,
      -0.026954738423228264,
      0.06720808148384094,
      0.053872883319854736,
      -0.0178532712161541,
      -0.01869262382388115,
      0.011534242890775204,
      0.044181279838085175,
      -0.03701051324605942,
      0.026935191825032234,
      -0.019264379516243935,
      -0.05234494060277939,
      -0.02634362317621708,
      -0.014429053291678429,
      0.019262172281742096,
      0.10427382588386536,
      0.06461349129676819,
      0.02681717276573181,
      -0.013707315549254417,
      0.05057654529809952,
      0.007006196770817041,
      0.017991986125707626,
      -0.03551315888762474,
      0.012119192630052567,
      -0.01884794980287552,
      0.02236712910234928,
      0.03620598465204239,
      0.03298681601881981,
      0.0911414623260498,
      -0.036570873111486435,
      0.05540968105196953,
      -0.01914796605706215,
      -0.013635207898914814,
      -0.11530502885580063,
      -0.0503336600959301,
      -0.1017083153128624,
      -0.0037845836486667395,
      -0.042992085218429565,
      -0.10707909613847733,
      0.07621978968381882,
      -0.001297350157983601,
      0.029459385201334953,
      -0.07270302623510361,
      -0.01171262376010418,
      0.013714856468141079,
      0.027885127812623978,
      0.03274894505739212,
      -0.022264892235398293,
      0.08890283107757568,
      -0.02427978254854679,
      -0.006433377508074045,
      0.005135837476700544,
      0.00973311997950077,
      0.016055313870310783,
      -0.059162359684705734,
      0.008628077805042267,
      0.08896933495998383,
      0.07304299622774124,
      -0.06512199342250824,
      -0.001542005455121398,
      -0.06539071351289749,
      -0.08436498045921326,
      -0.014312843792140484,
      0.00572447432205081,
      0.04098105430603027,
      0.03711841627955437,
      -0.06569638848304749,
      0.041768744587898254,
      -0.004149354062974453,
      0.06933038681745529,
      0.05666559189558029,
      0.08582475036382675,
      -0.020007019862532616,
      0.03743049502372742,
      0.0333259217441082,
      0.07228738069534302,
      0.03918173909187317,
      0.006867448333650827,
      0.03299561142921448,
      -0.030564865097403526,
      0.035272762179374695,
      0.006563245318830013,
      -0.027373412624001503,
      0.0013125240802764893,
      0.03889361768960953,
      0.059753820300102234,
      -0.04893650487065315,
      -0.05871604382991791,
      -0.03139087185263634,
      -0.003247747663408518,
      0.01639210246503353,
      -0.06815076619386673,
      0.024071399122476578,
      0.03980730101466179,
      -0.006938834208995104,
      0.09194063395261765,
      0.037405431270599365,
      0.09413198381662369,
      -0.08423308283090591,
      -0.008119741454720497,
      0.008229322731494904,
      -0.009305967949330807,
      -0.035726115107536316,
      -0.015323985368013382,
      -0.08313790708780289,
      -0.038147035986185074,
      -0.026227915659546852,
      -0.02897200919687748,
      -0.034656450152397156,
      0.003545723157003522,
      -0.05365309864282608,
      -0.005582424346357584,
      0.02295365370810032,
      0.051435455679893494,
      0.0380987673997879,
      -0.00782723631709814,
      0.0023092199116945267,
      -0.07192257791757584,
      -0.02256315015256405,
      -0.03917064517736435,
      0.02523256652057171,
      -0.005822859704494476,
      0.011754768900573254,
      -0.038550809025764465,
      -0.0008295107400044799,
      -0.06229778751730919,
      0.08257737755775452,
      -0.00148434704169631,
      0.030167032033205032,
      -0.011879135854542255,
      0.011981482617557049,
      -0.013364472426474094,
      0.02662266604602337,
      -0.021623527631163597,
      0.06228019669651985,
      0.00802150834351778,
      0.02821291796863079,
      0.030030205845832825,
      0.05534845590591431,
      0.06578254699707031,
      0.05134773626923561,
      -0.026965487748384476,
      -0.01989889144897461,
      0.08833097666501999,
      -0.05048251524567604,
      -0.015263290144503117,
      0.013411409221589565,
      -0.112484872341156,
      -0.044315699487924576,
      -0.008827876299619675,
      -0.002452252199873328,
      0.02782900258898735,
      0.09468232840299606,
      0.006537434179335833,
      0.0682176724076271,
      -0.07037155330181122,
      0.008329245261847973,
      -0.05110381916165352,
      0.06387700885534286,
      0.002927683060988784,
      -0.07113807648420334,
      0.030510632321238518,
      -0.04218699783086777,
      -0.0029002614319324493,
      0.03778945282101631,
      0.04958808422088623,
      0.0020861926022917032,
      0.015946252271533012,
      0.007443241775035858,
      -0.00653588492423296,
      -0.0744190439581871,
      -0.02121923863887787,
      -0.03799032047390938,
      -0.0030188625678420067,
      -0.05363211780786514,
      -0.012623626738786697,
      -0.049347445368766785,
      -0.07424472272396088,
      0.0575207881629467,
      -0.04600740224123001,
      0.016627561300992966,
      -0.052593257278203964,
      -0.01997665874660015,
      0.02948042005300522,
      0.03209494426846504,
      -0.05224638804793358,
      0.06102977693080902,
      0.07167655229568481,
      0.06158410757780075,
      0.07570710778236389,
      0.02131931483745575,
      0.009657559916377068,
      0.007628839462995529,
      0.03760361298918724,
      -0.053018614649772644,
      -0.018547121435403824,
      0.06898245215415955,
      -0.015097210183739662,
      0.08297868818044662,
      -0.06656070798635483,
      -0.011766293086111546,
      0.008818196132779121,
      -0.036341678351163864,
      0.04345488175749779,
      0.08752357959747314,
      -0.020072665065526962,
      0.0650448277592659,
      -0.049468595534563065,
      0.03511742502450943,
      0.07659805566072464,
      -0.07440606504678726,
      0.04693283885717392,
      0.021936601027846336,
      0.03064209781587124,
      0.06929203122854233,
      0.05165104940533638,
      -0.1495048999786377,
      0.07461684197187424,
      0.054245542734861374,
      -0.014348350465297699,
      0.02385719306766987,
      0.003941473551094532,
      -0.012951935641467571,
      -0.055916912853717804,
      0.049980372190475464,
      -0.054257895797491074,
      0.00697355717420578,
      -0.003468299750238657,
      -0.039545588195323944,
      -0.040638044476509094,
      0.048861853778362274,
      -0.052022047340869904,
      -0.06177262216806412,
      -0.14025133848190308,
      0.05116768553853035,
      -0.04387408122420311,
      0.005900657270103693,
      -0.0438837856054306,
      0.032487668097019196,
      0.019796930253505707,
      0.01164100132882595,
      0.06989669054746628,
      -0.001202331273816526,
      0.011262251995503902,
      -0.009111255407333374,
      -0.08075209707021713,
      -0.020792096853256226,
      -0.0362837053835392,
      -0.0020939880050718784,
      -0.04453910142183304,
      -0.06750646233558655,
      0.035607997328042984,
      -0.029293932020664215,
      0.00919780321419239,
      -0.0029155183583498,
      -0.040213506668806076,
      -0.017650354653596878,
      0.002771558938547969,
      -0.08580288290977478,
      0.017666269093751907,
      -0.040109287947416306,
      0.015165840275585651,
      -0.08782337605953217,
      0.0403415709733963,
      -0.00999060645699501,
      -0.02488507702946663,
      0.1215139627456665,
      0.007494170684367418,
      -0.03371197730302811,
      0.04796779528260231,
      0.028909025713801384,
      -0.047836460173130035,
      0.04641000181436539,
      -0.007074662018567324,
      -0.018544111400842667,
      0.08952964097261429,
      0.09711785614490509,
      0.03783538192510605,
      0.030370887368917465,
      -0.007268478162586689,
      0.025010446086525917,
      0.0013627605512738228,
      -0.016482245177030563,
      0.012456449680030346,
      0.030881889164447784,
      -0.017644017934799194,
      -0.03725428506731987,
      -0.06164298579096794,
      0.05720311403274536,
      0.07480517774820328,
      0.010688967071473598,
      -0.03626095876097679,
      0.027300996705889702,
      -0.06666535884141922,
      0.024261943995952606,
      -0.056130874902009964,
      0.02950563095510006,
      0.0034964713267982006,
      0.0694756954908371,
      -0.04282815009355545,
      0.022222155705094337,
      0.04211165010929108,
      -0.05600714311003685,
      0.06924242526292801,
      -0.0637606680393219,
      -0.02383545972406864,
      -0.05235709249973297,
      0.0001780936581781134,
      0.01927241124212742,
      0.03604096174240112,
      0.04314229264855385,
      0.005347692407667637,
      -0.027307355776429176,
      0.0775909274816513,
      -0.0019222487462684512,
      0.04707908257842064,
      -0.06422365456819534,
      0.03646035119891167,
      0.015967851504683495,
      0.029176589101552963,
      -0.003874028567224741,
      0.04784076288342476,
      0.06319436430931091,
      0.04162537306547165,
      0.03562324121594429,
      -0.0062999119982123375,
      -0.03841271251440048,
      0.008599319495260715,
      -0.0688307136297226,
      -0.05960265174508095,
      -0.020858023315668106,
      -0.003617665031924844,
      -0.004529465921223164,
      0.05882137641310692,
      -0.0010125169064849615,
      -0.020628156140446663,
      -0.006394613068550825,
      -0.08262016624212265,
      -0.04209541901946068,
      0.08370129019021988,
      0.01834006793797016,
      -0.05555274337530136,
      -0.029985927045345306,
      0.05036568269133568,
      -0.029258864000439644,
      -0.08534560352563858,
      -0.0983818769454956,
      0.006906860042363405,
      0.03719962015748024,
      -0.04628385975956917,
      -0.0005883984267711639,
      -0.001727240509353578,
      -0.04125373438000679,
      0.015919582918286324,
      0.014920626766979694,
      -0.005783969070762396,
      0.025463365018367767,
      -0.014329415746033192,
      0.034737735986709595,
      -0.05675129592418671,
      0.00866026896983385,
      0.04408418759703636,
      -0.009140972048044205,
      0.000761898176278919,
      0.0035280268639326096,
      -0.04944765567779541,
      -0.016357725486159325,
      -0.006943196523934603,
      0.09383885562419891,
      -0.05501040443778038,
      -0.06159201264381409,
      0.02287844568490982,
      0.005457006860524416,
      -0.03912412002682686,
      0.008419019170105457,
      -0.05494876950979233,
      -0.0016054564621299505,
      -0.008834854699671268,
      0.08468473702669144,
      0.09073562920093536,
      -0.003816098440438509,
      0.06286478042602539,
      0.004765101708471775,
      0.01301961112767458,
      0.021604591980576515,
      0.039534587413072586,
      -0.0036888790782541037,
      -0.03198801353573799,
      0.029192499816417694,
      0.016977721825242043,
      0.06257500499486923,
      -0.041757866740226746,
      0.0362238772213459,
      0.023763351142406464,
      0.02485034614801407,
      0.005688144825398922,
      0.03473346680402756,
      0.04122653976082802,
      -0.03208720311522484,
      0.05771266669034958,
      -0.014284027740359306,
      -0.03184123709797859,
      -0.03205600008368492,
      -0.027986010536551476,
      0.042476486414670944,
      -0.01023067720234394,
      -0.02467896230518818,
      0.01397301722317934,
      -0.00843012984842062,
      -0.0383380725979805,
      0.01873893477022648,
      0.013827799819409847,
      0.017570700496435165,
      -0.030121522024273872,
      -0.06006782874464989,
      0.018985049799084663,
      0.01937103644013405,
      -0.04517669975757599,
      0.001505068619735539,
      0.007608668878674507,
      -0.0460796095430851,
      -0.038999978452920914,
      0.06223864108324051,
      0.017581261694431305,
      -0.064760722219944,
      -0.010355553589761257,
      0.0004132251488044858,
      -0.03620746731758118,
      -0.06073346361517906,
      -0.05501929298043251,
      -0.008530933409929276,
      -0.03200367093086243,
      -0.04727628082036972,
      0.027525929734110832,
      -0.0727846696972847,
      -0.026309974491596222,
      0.08582274615764618,
      -0.01978011056780815,
      0.012126294896006584,
      -0.02443452551960945,
      0.0004741153970826417,
      0.032515183091163635,
      0.040024299174547195,
      0.018040142953395844,
      -0.0067504472099244595,
      0.034316983073949814,
      -0.04408856853842735,
      0.09897791594266891,
      0.027207011356949806,
      0.029759889468550682,
      -0.01678728312253952,
      -0.03650711476802826,
      -0.05190781131386757,
      0.048863861709833145,
      0.12160512059926987,
      -0.03440675884485245,
      -0.04426867142319679,
      0.08935794979333878,
      0.11377335339784622,
      -0.0548216849565506,
      0.06565013527870178
    ],
    [
      0.019729318097233772,
      0.006254732143133879,
      0.0423685759305954,
      0.072074294090271,
      -0.019673679023981094,
      -0.06529130786657333,
      0.024653367698192596,
      0.023374048992991447,
      -0.03873102739453316,
      -0.03826042264699936,
      -0.0011915388749912381,
      0.028129199519753456,
      -0.017840972170233727,
      -0.02209828794002533,
      0.05503596365451813,
      -0.004613969009369612,
      -0.003514121752232313,
      0.050558894872665405,
      -0.021985989063978195,
      -0.006158044096082449,
      -0.07954832911491394,
      0.0388762429356575,
      -0.0205261018127203,
      -0.003411519108340144,
      0.01124446652829647,
      0.008787717670202255,
      -0.0018106682691723108,
      -0.01742124743759632,
      0.04239296168088913,
      0.00572191784158349,
      0.018297147005796432,
      -0.04818189889192581,
      0.019163142889738083,
      -0.03010595589876175,
      -0.059719912707805634,
      0.004923604428768158,
      0.043305426836013794,
      0.020631982013583183,
      0.007608960848301649,
      0.04908563569188118,
      -0.0748770609498024,
      -0.02678244188427925,
      0.04252089560031891,
      -0.03646086901426315,
      0.013802465051412582,
      -0.0048929317854344845,
      0.06071969121694565,
      0.004731727764010429,
      0.03416293114423752,
      -0.016888849437236786,
      -0.08166758716106415,
      0.012012542225420475,
      0.001340563059784472,
      -0.023098018020391464,
      -0.0016693391371518373,
      -0.004169372841715813,
      0.023204391822218895,
      -0.054229266941547394,
      -0.011102945543825626,
      0.02983050048351288,
      -0.031685832887887955,
      -0.04101702198386192,
      -0.008264467120170593,
      -0.0015167745295912027,
      0.003999612759798765,
      -0.007020094431936741,
      -0.031789250671863556,
      -0.012047046795487404,
      -0.03656885400414467,
      -0.02381661906838417,
      0.04800285026431084,
      0.028528716415166855,
      0.06954395025968552,
      0.01994025893509388,
      -0.0063980924896895885,
      -0.011246144771575928,
      -0.008075937628746033,
      -0.004127304069697857,
      0.004354258067905903,
      -0.018746329471468925,
      -0.048575419932603836,
      0.04085046425461769,
      -0.015648160129785538,
      0.019380541518330574,
      -0.030089393258094788,
      0.001168289571069181,
      -0.006249566096812487,
      -0.05077062174677849,
      -0.006099895108491182,
      0.01384830567985773,
      0.009225276298820972,
      0.021445587277412415,
      -0.02259318344295025,
      0.050627611577510834,
      -0.009720356203615665,
      -0.02136058360338211,
      -0.04179709032177925,
      0.05579720437526703,
      0.00210382416844368,
      -0.06759779155254364,
      -0.000979639240540564,
      0.0032542196568101645,
      -0.0028051547706127167,
      0.06045815348625183,
      -0.02021690644323826,
      0.044968996196985245,
      0.03205852210521698,
      0.05194483697414398,
      0.05765532702207565,
      -0.07665462791919708,
      -0.10277066379785538,
      0.024264317005872726,
      -0.017918115481734276,
      0.044323332607746124,
      0.019347501918673515,
      0.027031272649765015,
      -0.06448518484830856,
      0.02906247414648533,
      -0.0857493206858635,
      0.032682642340660095,
      -0.06540901958942413,
      0.05548504367470741,
      -0.025315195322036743,
      0.0037631397135555744,
      0.019250601530075073,
      -0.00020992234931327403,
      0.11035680025815964,
      -0.06947789341211319,
      -0.010588347911834717,
      0.04384118691086769,
      0.03150036185979843,
      -0.059450168162584305,
      -0.04414766654372215,
      -0.02181471697986126,
      -0.030155688524246216,
      0.06003844738006592,
      0.051399268209934235,
      -0.025996971875429153,
      0.005764914210885763,
      0.04900112375617027,
      -0.03546493500471115,
      -0.0937563106417656,
      -0.0672169029712677,
      -0.027043651789426804,
      0.03322409465909004,
      0.010938173159956932,
      0.013894298113882542,
      -0.0702744722366333,
      -0.024871518835425377,
      -0.01831226982176304,
      0.059637218713760376,
      0.04913701117038727,
      0.06144530326128006,
      -0.0008407416171394289,
      0.0229934249073267,
      -0.01668730564415455,
      -0.05111275613307953,
      0.022042762488126755,
      0.03124687820672989,
      -0.009508321061730385,
      -0.03511518985033035,
      0.009836237877607346,
      -0.04783819243311882,
      0.048945102840662,
      -0.038645509630441666,
      -0.035113319754600525,
      -0.019782887771725655,
      -0.05029323324561119,
      -0.01619504764676094,
      0.0225314199924469,
      -0.10410328954458237,
      -0.02794095128774643,
      -0.056174661964178085,
      -0.006629078648984432,
      0.09445512294769287,
      0.016244836151599884,
      -3.429541538935155e-05,
      -0.04869161546230316,
      0.056720469146966934,
      -0.03676595166325569,
      -0.030406421050429344,
      -0.08064740151166916,
      -0.0696413591504097,
      -0.05823807790875435,
      0.019682472571730614,
      0.09799496084451675,
      0.07872781157493591,
      0.0032956914510577917,
      -0.005175529979169369,
      0.012200056575238705,
      -0.0340847373008728,
      0.003306328086182475,
      -0.0019661246333271265,
      0.04789260774850845,
      0.02282857336103916,
      -0.004672432783991098,
      0.0626605749130249,
      0.008345278911292553,
      -0.039513181895017624,
      0.005818524863570929,
      -0.013124590739607811,
      0.010648591443896294,
      -0.006178975570946932,
      -0.030537059530615807,
      0.006561788730323315,
      0.02077614516019821,
      -0.06030919402837753,
      -0.008527173660695553,
      0.009078840725123882,
      0.012398812919855118,
      -0.010670671239495277,
      0.08814669400453568,
      0.03453213721513748,
      -0.04001372680068016,
      0.08339106291532516,
      -0.0010044234804809093,
      0.004039747174829245,
      -0.08860020339488983,
      -0.0725850984454155,
      -0.008549915626645088,
      -0.009822015650570393,
      0.020003363490104675,
      0.04163481667637825,
      0.013290508650243282,
      0.03772038593888283,
      -0.09452151507139206,
      -0.0004966864362359047,
      0.03842419385910034,
      0.00681166211143136,
      -0.006207318045198917,
      -0.01342365238815546,
      0.007052021566778421,
      0.019910378381609917,
      0.02410973608493805,
      -0.011047560721635818,
      -0.03677375614643097,
      0.09435831755399704,
      0.08063612878322601,
      -0.06564675271511078,
      -0.04813995957374573,
      0.017533984035253525,
      -0.007946955040097237,
      -0.0012825984740629792,
      -0.016569610685110092,
      -0.024196043610572815,
      0.02154640480875969,
      -0.054688818752765656,
      -0.0500202476978302,
      -0.07392755150794983,
      -0.05169568955898285,
      -0.12596243619918823,
      0.036402590572834015,
      -0.00241669500246644,
      0.023273730650544167,
      0.032090459018945694,
      0.015579013153910637,
      0.06580568850040436,
      0.07286635786294937,
      -0.029476292431354523,
      -0.013165254145860672,
      -0.010923807509243488,
      -0.03768375515937805,
      -0.025746487081050873,
      0.021570798009634018,
      0.0887199342250824,
      0.02315945364534855,
      -0.051315780729055405,
      -0.026803545653820038,
      0.02861134149134159,
      0.057645656168460846,
      -0.05048709735274315,
      -0.05792996659874916,
      -0.03730370104312897,
      0.00760811660438776,
      0.06874191761016846,
      0.03829726204276085,
      0.007104864344000816,
      0.03548862785100937,
      -0.08969267457723618,
      0.007459326181560755,
      0.04996529221534729,
      -0.01696791686117649,
      -0.06850621104240417,
      0.046209827065467834,
      0.06899166107177734,
      0.029053855687379837,
      0.02588614448904991,
      -0.011141782626509666,
      -0.0014480354730039835,
      -0.037593916058540344,
      0.03647005558013916,
      0.001530591631308198,
      0.022601405158638954,
      -0.004730985965579748,
      0.0022282663267105818,
      0.027946094051003456,
      -0.03641970083117485,
      -0.09291338175535202,
      -0.012802846729755402,
      -0.07943610101938248,
      -0.0862579420208931,
      0.07561054080724716,
      0.037531424313783646,
      -0.021981332451105118,
      0.01771531067788601,
      0.01954697258770466,
      0.0017826237017288804,
      -0.010078554973006248,
      -0.03808491677045822,
      0.03023841232061386,
      -0.09119664877653122,
      -0.0005397700588218868,
      0.04738201946020126,
      -0.07081799954175949,
      0.06984134018421173,
      -0.028911376371979713,
      -0.05047621205449104,
      0.03647921234369278,
      0.0530637688934803,
      -0.03720390051603317,
      -0.1321137547492981,
      0.09042251855134964,
      -0.03265366330742836,
      -0.047270480543375015,
      -0.024633465334773064,
      0.0039012364577502012,
      0.017910156399011612,
      0.040982261300086975,
      0.03246711939573288,
      -0.025168318301439285,
      -0.03970841318368912,
      -0.021886711940169334,
      -0.07361777126789093,
      0.02827971801161766,
      -0.07085346430540085,
      -0.1443270444869995,
      -0.011359925381839275,
      -0.006561681162565947,
      -0.05024661868810654,
      -0.030815884470939636,
      0.008071748539805412,
      0.022457292303442955,
      0.041370462626218796,
      0.04265933856368065,
      -0.037891048938035965,
      -0.005118556320667267,
      0.07358551025390625,
      -0.0474335178732872,
      0.09269389510154724,
      0.009739984758198261,
      -0.014590754173696041,
      0.025805065408349037,
      -0.021840589120984077,
      -0.028653757646679878,
      -0.0849900171160698,
      -0.046494148671627045,
      0.03647148236632347,
      0.042445264756679535,
      0.04784916341304779,
      0.05023863911628723,
      0.03144611045718193,
      -0.01879625953733921,
      -0.020642919465899467,
      -0.04523807764053345,
      -0.025297924876213074,
      -0.00813452247530222,
      -0.024662824347615242,
      -0.03258329629898071,
      0.002212909748777747,
      -0.08716262131929398,
      -0.056082043796777725,
      -0.031073875725269318,
      0.004001621622592211,
      0.04110976308584213,
      -0.0575028695166111,
      -0.04827289283275604,
      -0.055053867399692535,
      -0.0182634349912405,
      0.00851919874548912,
      -0.06630809605121613,
      0.0038320482708513737,
      0.02090342901647091,
      0.055981479585170746,
      -0.09471873193979263,
      -0.0066199395805597305,
      0.05492153391242027,
      0.011943142861127853,
      -0.026953382417559624,
      0.0293847918510437,
      0.07883226126432419,
      -0.011736403219401836,
      -0.01122624147683382,
      0.036296095699071884,
      0.06240735948085785,
      -0.021063009276986122,
      0.04736735299229622,
      0.046956323087215424,
      -0.008343799971044064,
      -0.08370678871870041,
      0.07245614379644394,
      -0.011378947645425797,
      -0.02044803649187088,
      0.017762111499905586,
      -0.07469284534454346,
      -0.00047867075772956014,
      -0.03635743632912636,
      -0.0499730110168457,
      -0.019112860783934593,
      -0.003442291170358658,
      -0.06081905961036682,
      0.027102971449494362,
      0.07060307264328003,
      -0.0537199005484581,
      0.03591250255703926,
      -0.014748685993254185,
      0.012556171044707298,
      -0.0007989992736838758,
      0.10190194100141525,
      -0.03121894970536232,
      0.003603958757594228,
      0.07562774419784546,
      -0.03177011013031006,
      -0.04515989124774933,
      0.004375861957669258,
      -0.0529492013156414,
      0.01038598082959652,
      0.0033492364455014467,
      -0.028389524668455124,
      -0.0036597182042896748,
      0.02215922810137272,
      -0.002370458096265793,
      -0.017166541889309883,
      0.024964679032564163,
      -0.01268649473786354,
      -0.020875539630651474,
      0.06877981126308441,
      0.018816638737916946,
      0.026298915967345238,
      -0.05030618607997894,
      -0.005579475779086351,
      -0.06123696640133858,
      0.01605769246816635,
      -0.0600777193903923,
      0.01927022449672222,
      0.03608042746782303,
      -0.009012606926262379,
      -0.017128894105553627,
      -0.11058435589075089,
      -0.010611340403556824,
      -0.046838175505399704,
      0.022719401866197586,
      -0.03988425061106682,
      -0.006127176806330681,
      0.03238017484545708,
      0.01793241687119007,
      -0.0005792297306470573,
      -0.034080568701028824,
      -0.010689266957342625,
      0.03939243406057358,
      -0.11295672506093979,
      -0.03591494634747505,
      -0.005334734916687012,
      0.0055088079534471035,
      0.003978264983743429,
      -0.00992574542760849,
      0.03274672478437424,
      0.03173001483082771,
      -0.06967060267925262,
      -0.024809405207633972,
      -0.006492606829851866,
      -0.002294532721862197,
      -0.0312380101531744,
      0.017744936048984528,
      -0.03439246490597725,
      0.06818870455026627,
      -0.03216591104865074,
      0.03342975303530693,
      -0.013226238079369068,
      0.0050673834048211575,
      0.02288554236292839,
      0.006973417941480875,
      0.05084976553916931,
      -0.037430841475725174,
      0.01649484597146511,
      0.12347020208835602,
      -0.06234955042600632,
      -0.0034284398425370455,
      -0.0586000494658947,
      0.015594404190778732,
      0.010412654839456081,
      -0.0008553988882340491,
      -0.053691085427999496,
      0.00488813454285264,
      0.06766822934150696,
      0.014915309846401215,
      -0.12755046784877777,
      0.05314457044005394,
      0.013919582590460777,
      0.03654555231332779,
      0.09039375185966492,
      0.008704270236194134,
      -0.04442741349339485,
      0.03425566852092743
    ],
    [
      0.03281478211283684,
      0.0049780430272221565,
      -0.04303378984332085,
      -0.025691885501146317,
      0.0018595914589241147,
      -0.0005279241013340652,
      -0.07273228466510773,
      0.09162808954715729,
      -0.07157917320728302,
      0.0077018821612000465,
      0.01625189743936062,
      0.07644033432006836,
      0.052052952349185944,
      0.015675202012062073,
      -0.022021006792783737,
      0.014666842296719551,
      0.019629396498203278,
      0.060647327452898026,
      0.007789456285536289,
      -0.016287924721837044,
      0.026845810934901237,
      -0.020295340567827225,
      -0.06311433017253876,
      -0.017212778329849243,
      0.0783735066652298,
      0.020629169419407845,
      0.02519620768725872,
      0.07087372988462448,
      -0.0626213327050209,
      -0.006133516319096088,
      0.06569056212902069,
      0.002912126714363694,
      0.012402224354445934,
      0.06801743060350418,
      0.05913719907402992,
      -0.08503754436969757,
      -0.0019861955661326647,
      0.11676450818777084,
      -0.0014813591260463,
      0.03467531502246857,
      0.07141993939876556,
      0.034888461232185364,
      -0.016654955223202705,
      0.008253072388470173,
      -0.004221569746732712,
      -0.041063979268074036,
      -0.0036787581630051136,
      -0.0336126983165741,
      -0.007928320206701756,
      0.018897805362939835,
      0.03870915248990059,
      0.06953400373458862,
      0.03420605883002281,
      -0.034126702696084976,
      -0.008527137339115143,
      -0.010422120802104473,
      -0.03694861754775047,
      0.043463483452796936,
      -0.016497252508997917,
      0.003539634170010686,
      0.04636653512716293,
      -0.014297736808657646,
      -0.008434086106717587,
      -0.05296986922621727,
      0.04721520468592644,
      -0.02179311215877533,
      0.04460212215781212,
      0.015286466106772423,
      0.04783952236175537,
      -0.037901703268289566,
      -0.0073342397809028625,
      -0.037956252694129944,
      0.02026788890361786,
      0.015209803357720375,
      0.03560611978173256,
      0.01296257134526968,
      -0.018020428717136383,
      -0.027784904465079308,
      -0.03922262042760849,
      -0.027102196589112282,
      0.001409419346600771,
      -0.010457915253937244,
      -0.021419469267129898,
      0.008375898003578186,
      0.04032573476433754,
      -0.004519837908446789,
      -0.03866484761238098,
      0.026275917887687683,
      -0.034516122192144394,
      0.10447254031896591,
      0.009012265130877495,
      -0.003488910384476185,
      0.06947008520364761,
      0.0031574044842272997,
      0.0593840554356575,
      0.036714017391204834,
      0.08325379341840744,
      0.013802007772028446,
      -0.056841377168893814,
      0.0021361925173550844,
      0.01421599742025137,
      0.001859475625678897,
      0.010193617083132267,
      0.05800006166100502,
      0.03928029537200928,
      0.044271547347307205,
      -0.059756215661764145,
      -0.050085440278053284,
      0.023072319105267525,
      0.03147457540035248,
      0.07896170020103455,
      -0.02505573444068432,
      -0.03899858519434929,
      -0.005663860123604536,
      -0.07868970930576324,
      -0.03817965090274811,
      -0.02862793765962124,
      0.03138772025704384,
      0.05008373409509659,
      -0.06108934432268143,
      -0.007905702106654644,
      -0.016911296173930168,
      -0.059922222048044205,
      -0.04190925136208534,
      0.011036578565835953,
      0.08301946520805359,
      -0.040847375988960266,
      0.0270648505538702,
      -0.033352080732584,
      -0.04883071407675743,
      -0.06345687806606293,
      -0.012550922110676765,
      -0.0508585087954998,
      0.043636150658130646,
      -0.0461689755320549,
      -0.025263413786888123,
      -0.03820713609457016,
      -0.036894429475069046,
      -0.04259883239865303,
      0.04541156068444252,
      0.0366501621901989,
      0.032373566180467606,
      0.09391730278730392,
      -0.03705226257443428,
      -0.032964881509542465,
      -0.08751071244478226,
      0.026375869289040565,
      -0.04911433160305023,
      0.02817498706281185,
      0.014933397993445396,
      -0.0902300477027893,
      0.012184444814920425,
      -0.007575483527034521,
      -0.04133328050374985,
      0.06359266489744186,
      -0.009445544332265854,
      -0.09623055160045624,
      -0.02582838386297226,
      -0.038265131413936615,
      0.0444621704518795,
      0.04835551604628563,
      0.023864932358264923,
      0.0423809215426445,
      0.044390324503183365,
      0.05734669417142868,
      0.024987416341900826,
      0.03860429301857948,
      0.030505357310175896,
      0.01438388042151928,
      -0.05268152058124542,
      0.07075885683298111,
      0.030795644968748093,
      0.0708862692117691,
      0.01195097342133522,
      0.09519803524017334,
      0.08748718351125717,
      -0.04679745435714722,
      -0.04454978182911873,
      -0.03344445675611496,
      -0.009514562785625458,
      0.0025067278183996677,
      -0.0799940824508667,
      0.018552592024207115,
      -0.05277280509471893,
      -0.06464695185422897,
      0.03560005500912666,
      0.01901290751993656,
      0.07013251632452011,
      0.01328596193343401,
      0.027660861611366272,
      0.092715322971344,
      0.04003410413861275,
      -0.023356841877102852,
      0.025118621066212654,
      -0.0004908877890557051,
      -0.03878939151763916,
      -0.04632109776139259,
      0.0074474080465734005,
      0.02359088696539402,
      -0.003115197876468301,
      -0.05452881008386612,
      0.026663577184081078,
      -0.026816250756382942,
      0.05555986613035202,
      0.13774248957633972,
      0.026779955253005028,
      0.004983265418559313,
      0.08006355911493301,
      -0.0928855836391449,
      0.000555891718249768,
      0.05606585741043091,
      0.006287327967584133,
      0.042165059596300125,
      -0.09829965233802795,
      0.031057648360729218,
      0.02395262010395527,
      -0.019404785707592964,
      -0.0031913991551846266,
      0.1155412346124649,
      0.02604903280735016,
      0.0070521947927773,
      0.04314911738038063,
      -0.11637900769710541,
      0.0068483659997582436,
      -0.02029835246503353,
      0.030090350657701492,
      0.000865800422616303,
      0.019331462681293488,
      0.0070119695737957954,
      -0.020913030952215195,
      0.08235511928796768,
      -0.0008675114368088543,
      -0.028910238295793533,
      -0.03200342133641243,
      0.02515038475394249,
      -0.01653175801038742,
      0.020305292680859566,
      0.02366837114095688,
      -0.0013949137646704912,
      0.03965790569782257,
      -0.03360748291015625,
      0.024823753163218498,
      0.052563976496458054,
      -0.02944330684840679,
      -0.05135846138000488,
      -0.02640683948993683,
      -0.021843651309609413,
      -0.04487668350338936,
      0.03051258623600006,
      -0.00046476867282763124,
      0.039205584675073624,
      -0.043629564344882965,
      -0.0145349046215415,
      0.024335864931344986,
      0.03502112254500389,
      0.018521180376410484,
      0.06162627786397934,
      -0.04300882667303085,
      0.005293718539178371,
      0.009127856232225895,
      0.02865000255405903,
      0.04823480546474457,
      -0.042668431997299194,
      0.02615201659500599,
      0.020170563831925392,
      -0.01987183652818203,
      -0.04167057201266289,
      0.020523488521575928,
      0.015872206538915634,
      -0.0037837408017367125,
      -0.056236300617456436,
      0.007630651816725731,
      -0.03119669109582901,
      0.04828938841819763,
      0.02297554537653923,
      0.07667282223701477,
      -0.024567442014813423,
      0.05779629945755005,
      -0.029270417988300323,
      -0.028630878776311874,
      -0.016885604709386826,
      -0.03768577799201012,
      -0.0008888055454008281,
      0.007323350291699171,
      -0.012668924406170845,
      -0.0667555034160614,
      -0.04448510706424713,
      0.044351495802402496,
      -0.0416150726377964,
      0.01699228584766388,
      0.05119749531149864,
      -0.045779015868902206,
      0.04790444299578667,
      -0.03142653405666351,
      0.057952992618083954,
      0.07037395238876343,
      0.023702522739768028,
      0.03209419175982475,
      -0.017225712537765503,
      -0.16198940575122833,
      -0.056468285620212555,
      0.03642956539988518,
      -0.030472377315163612,
      0.00332052749581635,
      -0.0514925941824913,
      0.01666557788848877,
      0.008083897642791271,
      -0.0715564414858818,
      0.038512974977493286,
      -0.07035888731479645,
      0.018438538536429405,
      0.08873551338911057,
      -0.05521232262253761,
      -0.003145664930343628,
      -0.041986748576164246,
      -0.07285496592521667,
      -0.016189446672797203,
      0.020634053274989128,
      -0.004873803351074457,
      -0.007980949245393276,
      -0.10511048138141632,
      0.017473185434937477,
      -0.08080955594778061,
      -0.0427376963198185,
      0.016419002786278725,
      0.011284364387392998,
      -0.04111554101109505,
      0.036224279552698135,
      0.0234976839274168,
      0.025207458063960075,
      -0.05125955864787102,
      0.026394693180918694,
      -0.07396770268678665,
      -0.030904773622751236,
      -0.01790846697986126,
      -0.03951338306069374,
      0.029183190315961838,
      -0.005785077344626188,
      -0.06422876566648483,
      -0.00759527925401926,
      0.03219301253557205,
      -0.005289535038173199,
      -0.027360141277313232,
      0.008126243017613888,
      0.0035666045732796192,
      -0.046759482473134995,
      -0.04361143708229065,
      0.08003822714090347,
      0.031152205541729927,
      -0.0320378802716732,
      -0.10984322428703308,
      0.018049590289592743,
      -0.005489126779139042,
      0.0290879737585783,
      0.013212775811553001,
      -0.015558717772364616,
      0.043823160231113434,
      0.011843116953969002,
      -0.08415728062391281,
      -0.06497514992952347,
      -0.07079289853572845,
      -0.011784423142671585,
      -0.00807109009474516,
      -0.02147853933274746,
      -0.029148878529667854,
      0.020990699529647827,
      0.017558569088578224,
      -0.035115908831357956,
      0.0651121437549591,
      0.014268187806010246,
      0.004788288380950689,
      -0.052316322922706604,
      -0.0035943726543337107,
      -0.02568546123802662,
      0.006745294202119112,
      0.037238966673612595,
      0.004414998460561037,
      -0.04234280809760094,
      0.015993142500519753,
      -0.06055453047156334,
      0.0683765783905983,
      0.012231660075485706,
      -0.0912741869688034,
      0.03204144537448883,
      -0.012399828061461449,
      0.07647243142127991,
      -0.07041744887828827,
      0.0629192441701889,
      -0.05471191927790642,
      -0.0010481951758265495,
      0.0030652640853077173,
      0.020488042384386063,
      0.11859958618879318,
      -0.017735978588461876,
      0.008908790536224842,
      0.02368953637778759,
      0.04532185196876526,
      -0.05870416387915611,
      -0.05902019515633583,
      -0.026063667610287666,
      -0.06539753079414368,
      0.08635839074850082,
      0.01623094268143177,
      0.035553183406591415,
      -0.07485803216695786,
      -0.10961591452360153,
      -0.02513088285923004,
      -0.09338140487670898,
      -0.0017761014169082046,
      -0.028934050351381302,
      0.09361360967159271,
      0.02277562767267227,
      -0.04725603386759758,
      -0.044125303626060486,
      0.025977514684200287,
      -0.0536433644592762,
      -0.010070880874991417,
      0.08044072985649109,
      0.01775388978421688,
      0.0120863551273942,
      0.008572150021791458,
      -0.045961279422044754,
      0.021886438131332397,
      0.09600656479597092,
      -0.0798715129494667,
      0.024540336802601814,
      0.06344377994537354,
      -0.030538970604538918,
      -0.04162893071770668,
      0.08909867703914642,
      -0.04963451251387596,
      0.004459832329303026,
      0.0014843106037005782,
      -0.03767356276512146,
      -0.023153280839323997,
      0.01154628861695528,
      -0.03804105147719383,
      0.030423203483223915,
      -0.007927698083221912,
      -0.010576085187494755,
      0.016452593728899956,
      -0.01997579075396061,
      -0.016500703990459442,
      -0.005387325305491686,
      -0.026539096608757973,
      -0.007859673351049423,
      -0.033775243908166885,
      -0.003295575501397252,
      -0.00341042154468596,
      0.061491966247558594,
      0.07387128472328186,
      -0.07482744753360748,
      0.028880976140499115,
      -0.039618637412786484,
      -0.05919655039906502,
      0.017043376341462135,
      -0.007682714145630598,
      0.041082803159952164,
      0.036517295986413956,
      0.0327584482729435,
      0.050433870404958725,
      -0.017385100945830345,
      0.09046290069818497,
      0.04703414440155029,
      0.02282988838851452,
      0.0007456386229023337,
      0.08116690814495087,
      -0.021861694753170013,
      0.03570754453539848,
      -0.017232844606041908,
      0.04807734116911888,
      0.011081965640187263,
      -0.04120418056845665,
      0.03271406516432762,
      -0.03849726542830467,
      0.008455595932900906,
      0.021036462858319283,
      -0.02793962135910988,
      -0.002919465769082308,
      -0.05813244357705116,
      0.02120453678071499,
      0.01310306042432785,
      -0.014520869590342045,
      -0.008234230801463127,
      -0.003666427917778492,
      -0.03654372692108154,
      0.07471122592687607,
      0.028070859611034393,
      -0.005891133099794388,
      -0.02654862590134144,
      0.06870047748088837,
      0.03465496376156807,
      -0.024942107498645782,
      0.05057442560791969,
      0.060680635273456573,
      -0.07071925699710846,
      0.08325382322072983,
      -0.0054822079837322235,
      0.0025462755002081394,
      0.03051670826971531,
      -0.02889975905418396,
      -0.05075043812394142,
      -0.04373761638998985
    ],
    [
      0.0180670078843832,
      -0.05165209248661995,
      0.04147958382964134,
      0.041992221027612686,
      -0.002640951657667756,
      0.034717150032520294,
      -0.033046890050172806,
      0.007092324085533619,
      -0.027072198688983917,
      0.025823924690485,
      0.009685409255325794,
      0.04126748815178871,
      -0.042995747178792953,
      -0.11572813242673874,
      0.019980264827609062,
      -0.06545005738735199,
      0.011519689112901688,
      0.024844486266374588,
      -0.009675809182226658,
      -0.017200112342834473,
      0.07160979509353638,
      0.05833781138062477,
      -0.06309253722429276,
      -0.09458139538764954,
      -0.024711014702916145,
      0.011526942253112793,
      -0.0859852135181427,
      0.004678505007177591,
      -0.032945603132247925,
      0.00948978029191494,
      0.06252540647983551,
      0.016559654846787453,
      0.07200030237436295,
      -0.07564586400985718,
      -0.010564770549535751,
      -0.034252189099788666,
      -0.010982908308506012,
      0.07280725985765457,
      -0.018991386517882347,
      0.06614284962415695,
      0.0504600927233696,
      -0.020581789314746857,
      -0.005238652694970369,
      -0.03811292350292206,
      -0.024228351190686226,
      0.0550919845700264,
      -0.045405399054288864,
      -0.027052145451307297,
      0.018504157662391663,
      -0.06345711648464203,
      -0.0067558567970991135,
      0.04498719796538353,
      0.07431165128946304,
      0.03983110561966896,
      -0.00269108172506094,
      -0.034853242337703705,
      0.06039148196578026,
      -0.01623803935945034,
      -0.01447063684463501,
      0.06484731286764145,
      0.06292906403541565,
      0.008260956034064293,
      -0.002182008232921362,
      -0.03048727475106716,
      0.1154930517077446,
      -0.0014718775637447834,
      0.0348883718252182,
      0.001972548197954893,
      0.05787750333547592,
      0.018632633611559868,
      -0.009124389849603176,
      0.01754404604434967,
      0.07035195827484131,
      -0.020147306844592094,
      0.004274993669241667,
      0.01357653085142374,
      -0.037447039037942886,
      0.0032575393561273813,
      -0.019732190296053886,
      0.09907253831624985,
      -0.04123967885971069,
      -0.020916355773806572,
      -0.011998498812317848,
      -0.019084572792053223,
      0.01953585259616375,
      -0.061971016228199005,
      0.01736847683787346,
      0.002239529974758625,
      -0.020674249157309532,
      0.04813734441995621,
      0.024674031883478165,
      -0.018769731745123863,
      0.0721467137336731,
      0.06353332102298737,
      -0.02076687663793564,
      0.03016825020313263,
      0.01220610085874796,
      -0.026705121621489525,
      0.010805577971041203,
      0.03998285159468651,
      -0.019650211557745934,
      0.07333317399024963,
      0.06458377093076706,
      0.08817300945520401,
      -0.04286280274391174,
      -0.02117796614766121,
      0.04848545417189598,
      0.003950159531086683,
      0.0029772550333291292,
      -0.11976752430200577,
      0.028353281319141388,
      0.04829584062099457,
      -0.029626071453094482,
      0.06565973162651062,
      0.06003405526280403,
      0.009401245042681694,
      0.012463254854083061,
      -0.04099080339074135,
      0.002028778661042452,
      0.0864797979593277,
      0.06424649804830551,
      -0.0885159894824028,
      -0.06627202779054642,
      -0.037023209035396576,
      0.01892429031431675,
      0.061696410179138184,
      -0.04027184471487999,
      -0.024081921204924583,
      -0.019986813887953758,
      -0.0481436513364315,
      0.02347499504685402,
      0.0437372662127018,
      -0.04174434021115303,
      -0.07713571190834045,
      -0.024754121899604797,
      -0.035038597881793976,
      0.02081441320478916,
      -0.003868409665301442,
      -0.04896276444196701,
      0.0463617779314518,
      -0.05140939727425575,
      -3.18610982503742e-05,
      0.1010814979672432,
      -0.0872248113155365,
      -0.05802980810403824,
      -0.038416605442762375,
      0.019470391795039177,
      0.09691930562257767,
      0.04490163177251816,
      0.03995852544903755,
      -0.034160494804382324,
      -0.005260958801954985,
      -0.07679329812526703,
      0.004311544820666313,
      -0.04372676834464073,
      -0.07099834829568863,
      0.01584923267364502,
      -0.018206648528575897,
      -0.009693349711596966,
      0.05751008167862892,
      -0.06879901885986328,
      0.035473596304655075,
      0.007707606069743633,
      -0.03303241729736328,
      -0.049203481525182724,
      0.0002119426935678348,
      -0.12089017778635025,
      -0.04411908984184265,
      0.0333947017788887,
      0.0007774177356623113,
      -0.0021869493648409843,
      0.0009859714191406965,
      0.013591106981039047,
      -0.040052276104688644,
      -0.003213039366528392,
      -0.002365073887631297,
      0.0013288843911141157,
      -0.02716122195124626,
      -0.013941265642642975,
      -0.01003124937415123,
      0.02738608606159687,
      -0.046114545315504074,
      0.09773604571819305,
      -0.02418610453605652,
      0.018140194937586784,
      0.034468624740839005,
      0.026250779628753662,
      -0.031003937125205994,
      0.05200277268886566,
      -0.0032512270845472813,
      0.04679890349507332,
      -0.009324584156274796,
      -0.04104877635836601,
      0.015274964272975922,
      0.015947360545396805,
      -0.019081436097621918,
      0.022039221599698067,
      0.04518982768058777,
      0.02341865934431553,
      0.015383763238787651,
      -0.014324262738227844,
      0.05613755062222481,
      -0.09612270444631577,
      -0.017377322539687157,
      0.010353307239711285,
      -0.004483012482523918,
      0.03881339356303215,
      0.03713313117623329,
      -0.004860575310885906,
      0.018424635753035545,
      0.018082093447446823,
      0.005748051684349775,
      0.01245842594653368,
      0.028582295402884483,
      0.0437711626291275,
      -0.02317715995013714,
      -0.011430776678025723,
      -0.05760990455746651,
      -0.006789026781916618,
      0.014612380415201187,
      0.018781594932079315,
      -0.008133945986628532,
      0.0033290074206888676,
      0.11114857345819473,
      -0.03959263488650322,
      0.011054079048335552,
      -0.0031053407583385706,
      -0.05666778236627579,
      0.029003018513321877,
      -0.08530517667531967,
      -0.001769722206518054,
      -0.04436259716749191,
      0.07932993769645691,
      0.004189828876405954,
      0.06304219365119934,
      0.010467732325196266,
      0.01787821389734745,
      0.0013999944785609841,
      -0.03515894338488579,
      -0.03024190291762352,
      0.076784648001194,
      0.03526349365711212,
      0.01427862886339426,
      -0.03668768331408501,
      -0.051418960094451904,
      0.05237434804439545,
      0.05394700914621353,
      -0.06843674182891846,
      -0.05825275927782059,
      0.016509979963302612,
      0.00868472270667553,
      0.01046020444482565,
      0.03615761548280716,
      -0.0388471893966198,
      -0.012624531984329224,
      0.017948098480701447,
      0.06407538801431656,
      -0.03524945303797722,
      0.023193825036287308,
      0.09787570685148239,
      -0.0454268753528595,
      0.0003199625061824918,
      0.026096286252141,
      0.05454082414507866,
      -0.03563474118709564,
      0.025968806818127632,
      -0.10112787783145905,
      -0.009626555256545544,
      0.013664274476468563,
      0.009051523171365261,
      -0.05148537456989288,
      -0.05973637104034424,
      0.10409840196371078,
      0.06228087469935417,
      0.016560561954975128,
      -0.010687768459320068,
      0.012515580281615257,
      -0.05143763870000839,
      0.005020090378820896,
      0.02429432049393654,
      0.017525944858789444,
      0.06065306439995766,
      -0.04380352050065994,
      -0.006501294672489166,
      -0.06275171786546707,
      0.07166912406682968,
      0.013492101803421974,
      0.05686374008655548,
      -0.02939116396009922,
      -0.021477971225976944,
      -0.08272570371627808,
      -0.02225511148571968,
      -0.04893103241920471,
      0.05150522664189339,
      0.0040901764295995235,
      -0.031869471073150635,
      -0.01767372526228428,
      -0.02569105103611946,
      -0.05690450966358185,
      -0.016728108748793602,
      0.0006082681356929243,
      -0.007716773077845573,
      0.021580683067440987,
      0.02229783684015274,
      0.00559818372130394,
      -0.02505301684141159,
      -0.027606766670942307,
      0.019721107557415962,
      0.019507233053445816,
      0.026560332626104355,
      -0.022699564695358276,
      0.07709973305463791,
      -0.010638448409736156,
      0.07016350328922272,
      0.014949078671634197,
      0.07853405922651291,
      -0.028299028053879738,
      -0.0584293007850647,
      -0.0020117820240557194,
      -0.01780284382402897,
      -0.029643826186656952,
      0.030338963493704796,
      0.040723349899053574,
      0.015678802505135536,
      0.04064958915114403,
      -0.05461059510707855,
      -0.003316219663247466,
      0.04023618996143341,
      0.025881709530949593,
      -0.09452302753925323,
      0.09816335141658783,
      -0.01641951873898506,
      -0.022863997146487236,
      0.04477648064494133,
      -0.04209126904606819,
      -0.02104032412171364,
      -0.014368540607392788,
      0.05459614470601082,
      0.020296623930335045,
      0.021348150447010994,
      -0.08242039382457733,
      0.021892188116908073,
      0.028154730796813965,
      -0.034033484756946564,
      -0.0527542307972908,
      0.0534377358853817,
      0.008490138687193394,
      0.018534479662775993,
      0.02153688110411167,
      0.01848132163286209,
      -0.02700432389974594,
      0.02183612622320652,
      -0.07273878902196884,
      -0.025634318590164185,
      0.059362929314374924,
      0.006060776300728321,
      -0.0663936585187912,
      0.051232293248176575,
      -0.019223719835281372,
      -0.04660571739077568,
      0.058000605553388596,
      0.030453097075223923,
      -0.00681413384154439,
      0.03288901224732399,
      0.020107263699173927,
      0.005992002785205841,
      0.01817888766527176,
      -0.11507654190063477,
      -0.020219098776578903,
      -0.019771000370383263,
      0.08416973054409027,
      -0.13701537251472473,
      -0.018237493932247162,
      0.04428636655211449,
      0.004719638731330633,
      0.04807646945118904,
      -0.055008240044116974,
      -0.06047813966870308,
      -0.04302675649523735,
      -0.008018099702894688,
      0.04289510101079941,
      0.04341573640704155,
      -0.012189054861664772,
      7.964439646457322e-06,
      0.0017007493879646063,
      0.06395833939313889,
      -0.0056803482584655285,
      -0.05992938578128815,
      0.02677285484969616,
      0.030776286497712135,
      0.005013779271394014,
      0.10251452773809433,
      -0.030050750821828842,
      -0.021100912243127823,
      -0.01490111742168665,
      -0.01606585457921028,
      0.009833959862589836,
      -0.018895544111728668,
      0.033066052943468094,
      0.014787944965064526,
      -0.01982700452208519,
      -0.022216401994228363,
      -0.0288697462528944,
      0.03553649038076401,
      -0.03317689523100853,
      -0.023012366145849228,
      -0.021761681884527206,
      -0.06325199455022812,
      0.02244151197373867,
      0.028713539242744446,
      -0.07130564004182816,
      0.0770677849650383,
      0.0028313647489994764,
      -0.024351999163627625,
      0.01984223537147045,
      0.06607339531183243,
      -0.03679743409156799,
      0.03431796282529831,
      -0.002491263672709465,
      0.03657883033156395,
      -0.07398197799921036,
      0.044529277831315994,
      0.09503829479217529,
      0.10655384510755539,
      0.006258154287934303,
      0.018602842465043068,
      0.03969031199812889,
      0.006470735650509596,
      0.05312100425362587,
      0.025732267647981644,
      -0.026633910834789276,
      -0.014585273340344429,
      -0.027081865817308426,
      0.07325127720832825,
      0.008346876129508018,
      -0.01673259399831295,
      0.010206556878983974,
      -0.059488601982593536,
      -0.026095006614923477,
      0.03637652471661568,
      -0.024458324536681175,
      -0.014226607047021389,
      -0.16066542267799377,
      0.040638089179992676,
      -0.04657043516635895,
      0.04769293591380119,
      -0.045424386858940125,
      -0.059607092291116714,
      0.006512148305773735,
      -0.028194600716233253,
      -0.018530182540416718,
      0.07409433275461197,
      -0.0229983888566494,
      -0.0047959559597074986,
      -0.025330625474452972,
      0.005493937525898218,
      -0.019437773153185844,
      -0.002629723632708192,
      0.009399516507983208,
      0.04558882117271423,
      0.032672151923179626,
      0.003693537088111043,
      0.08476348221302032,
      0.084690622985363,
      -0.05660885199904442,
      0.01696621999144554,
      -0.010696188546717167,
      0.02966170758008957,
      -0.03431127592921257,
      0.025995377451181412,
      0.007678319700062275,
      0.06554491072893143,
      -0.014513853937387466,
      -0.015503711998462677,
      -0.028013747185468674,
      -0.020225288346409798,
      0.0393020436167717,
      -0.08833693712949753,
      0.02489404007792473,
      0.03923062980175018,
      0.009538482874631882,
      0.06079200282692909,
      0.0058210003189742565,
      0.0012992427218705416,
      -0.000516081228852272,
      0.03452569991350174,
      -0.015528187155723572,
      0.0019635639619082212,
      -0.018327610567212105,
      0.0678010880947113,
      -0.03450692817568779,
      0.010873373597860336,
      -0.007425650022923946,
      0.0011101772543042898,
      0.017956607043743134,
      0.03931223228573799,
      -0.03635166212916374,
      -0.07735647261142731,
      0.06603822112083435,
      -0.027939677238464355,
      -0.05062524601817131,
      0.05928735062479973,
      0.01993740350008011
    ],
    [
      0.05250857025384903,
      -0.03398764133453369,
      -0.029504625126719475,
      0.0922367125749588,
      0.02256840467453003,
      0.017478222027420998,
      -0.05683726817369461,
      0.09401556849479675,
      0.035674650222063065,
      -0.022137874737381935,
      -0.04207305237650871,
      0.014659902080893517,
      -0.019566038623452187,
      -0.007056486792862415,
      0.07956583797931671,
      -0.025019140914082527,
      -0.010905143804848194,
      0.01152067817747593,
      -0.09014271944761276,
      -0.027709057554602623,
      0.06364511698484421,
      -0.08982407301664352,
      0.038106661289930344,
      -0.06491568684577942,
      0.01525399275124073,
      -0.02743496373295784,
      0.026873290538787842,
      0.07112212479114532,
      -0.11616343259811401,
      -0.03282240778207779,
      -0.005893473047763109,
      0.00685517955571413,
      -0.016965003684163094,
      -0.029892586171627045,
      -0.018124714493751526,
      -0.009877637960016727,
      0.07285211980342865,
      -0.052679724991321564,
      0.053131815046072006,
      0.025563566014170647,
      0.0013113903114572167,
      -0.020255550742149353,
      -0.04345270246267319,
      0.0550803542137146,
      -0.020131660625338554,
      0.033083390444517136,
      0.02902674488723278,
      0.0316680409014225,
      0.021205240860581398,
      -0.04191966727375984,
      -0.005254589952528477,
      -0.009414798580110073,
      0.0797576904296875,
      -0.05639714002609253,
      0.06374126672744751,
      -0.04602036997675896,
      -0.024099113419651985,
      -0.024356264621019363,
      -0.09698349237442017,
      0.05948478728532791,
      0.024285199120640755,
      0.05727304518222809,
      -0.01914811320602894,
      -0.030459299683570862,
      -0.014234309084713459,
      0.020415600389242172,
      -0.05879005417227745,
      -0.016575632616877556,
      -0.012159070000052452,
      0.037319183349609375,
      0.006629844196140766,
      -0.0014982918510213494,
      -0.04199691116809845,
      -0.05379828065633774,
      -0.044784706085920334,
      0.004411840811371803,
      -0.016019273549318314,
      0.025988638401031494,
      -0.01884838193655014,
      -0.0774863213300705,
      -0.09733647853136063,
      0.01099839061498642,
      -0.015382051467895508,
      -0.019999299198389053,
      0.008784179575741291,
      -0.0061393193900585175,
      0.04349259287118912,
      0.1201539933681488,
      -0.03680679574608803,
      0.027216561138629913,
      -0.05521358922123909,
      -0.003751023206859827,
      0.004365862347185612,
      0.011518164537847042,
      0.04285646229982376,
      -0.09341315925121307,
      -0.10341653972864151,
      -0.023834040388464928,
      -0.004510838072746992,
      -0.04724014922976494,
      -0.0033895219676196575,
      0.0652330070734024,
      0.06940313428640366,
      -0.018872380256652832,
      0.05352741852402687,
      -0.014271359890699387,
      0.03388436883687973,
      -0.001104795839637518,
      0.09937797486782074,
      0.050991542637348175,
      -0.02779192104935646,
      0.064352847635746,
      0.054533202201128006,
      -0.022464381530880928,
      -0.004562523681670427,
      -0.05481123924255371,
      0.06635037809610367,
      -0.008217806927859783,
      -0.003794899443164468,
      0.01600784994661808,
      -0.0074691250920295715,
      -0.05152953788638115,
      -0.07233080267906189,
      -0.04040531441569328,
      -0.07150434702634811,
      -0.019383279606699944,
      -0.053195521235466,
      0.0683441013097763,
      0.03139245882630348,
      0.022153418511152267,
      -0.020675677806138992,
      -0.04965696483850479,
      0.07791454344987869,
      -0.0736590102314949,
      0.0011643955949693918,
      -0.025286830961704254,
      -0.07808665931224823,
      -0.0856369286775589,
      -0.14536389708518982,
      0.0068935551680624485,
      0.0399833619594574,
      0.004726892802864313,
      0.04140978679060936,
      -0.0249177273362875,
      0.037678930908441544,
      -0.07592935860157013,
      0.06261096149682999,
      -0.00801469013094902,
      -0.03173038363456726,
      -0.050406962633132935,
      -0.09375880658626556,
      0.048141609877347946,
      0.04188771918416023,
      -0.060351304709911346,
      -0.0866728201508522,
      0.046763576567173004,
      0.027303123846650124,
      -0.02684362232685089,
      0.00649419566616416,
      0.025018637999892235,
      0.036125168204307556,
      0.021406978368759155,
      0.06354396045207977,
      -0.0009199200430884957,
      -0.0074784597381949425,
      0.02034778520464897,
      -0.0006589031545445323,
      0.03502264618873596,
      0.0971190333366394,
      -0.008853428065776825,
      -0.06987311691045761,
      -0.004032090771943331,
      -0.022459672763943672,
      0.08781468123197556,
      -0.028864620253443718,
      0.024240344762802124,
      -0.050459135323762894,
      -0.003780546598136425,
      0.0029693013057112694,
      0.05890756472945213,
      0.04203449562191963,
      -0.06509337574243546,
      0.050043050199747086,
      0.05887392908334732,
      0.07582548260688782,
      0.07314528524875641,
      -0.0635281503200531,
      0.03822844475507736,
      0.07768892496824265,
      -0.018353275954723358,
      -0.020664667710661888,
      0.022529236972332,
      0.0364893339574337,
      -0.003218661295250058,
      0.009886480867862701,
      -0.05233316868543625,
      0.04732806608080864,
      -0.06258517503738403,
      -0.01768946461379528,
      -0.013130360282957554,
      0.01863890327513218,
      0.0016986705595627427,
      -0.09135764837265015,
      0.0059199160896241665,
      0.04619407653808594,
      -0.0028648003935813904,
      0.0030451081693172455,
      0.0034408748615533113,
      -0.04551243409514427,
      -0.06749669462442398,
      0.03209810331463814,
      0.12677209079265594,
      -0.044191084802150726,
      -0.010358528234064579,
      -0.030586758628487587,
      0.041431110352277756,
      0.07971405237913132,
      -0.0337476022541523,
      0.053666625171899796,
      0.015127564780414104,
      0.021412597969174385,
      0.05111251398921013,
      0.012943425215780735,
      -0.008453468792140484,
      -0.04190177097916603,
      -0.058700259774923325,
      0.02430441603064537,
      0.10779940336942673,
      -0.029468119144439697,
      -0.11749798059463501,
      -0.003659034613519907,
      -0.043274831026792526,
      -0.055787380784749985,
      -0.024446455761790276,
      0.017297975718975067,
      0.08215651661157608,
      -0.01506704930216074,
      0.0021196415182203054,
      0.045465368777513504,
      -0.06079018861055374,
      -0.024786027148365974,
      0.008892922662198544,
      0.01943948119878769,
      0.10224748402833939,
      -0.015352489426732063,
      0.00175338180270046,
      0.02130332589149475,
      -0.02631533332169056,
      -0.14446726441383362,
      -0.05240217596292496,
      0.047306377440690994,
      -0.0718914195895195,
      -0.005304844584316015,
      0.004375160206109285,
      0.018461277708411217,
      -0.027674470096826553,
      0.040914762765169144,
      0.03454770892858505,
      -0.016577810049057007,
      -0.06714972853660583,
      0.01449003629386425,
      0.0563465878367424,
      0.020999295637011528,
      0.005288861691951752,
      -0.029729289934039116,
      0.025668710470199585,
      -0.04000035300850868,
      -0.028869418427348137,
      0.026510531082749367,
      -0.004940085578709841,
      -0.022300394251942635,
      0.00833508837968111,
      -0.037872035056352615,
      0.02142336778342724,
      -0.08393652737140656,
      -0.0010301995789632201,
      0.0024008871987462044,
      -0.017722364515066147,
      0.004273665137588978,
      0.05988151952624321,
      0.012419191189110279,
      -0.03531886637210846,
      -0.04480689391493797,
      0.022488201037049294,
      -0.0007205740548670292,
      0.0018563172779977322,
      -0.047772448509931564,
      0.08383899182081223,
      -0.08457096666097641,
      -0.0020609605126082897,
      -7.075335452100262e-05,
      -0.021151944994926453,
      0.020517632365226746,
      -0.03193748742341995,
      0.12378294765949249,
      0.014296934008598328,
      0.11681834608316422,
      -0.03657028451561928,
      0.005993895698338747,
      0.007100066635757685,
      -0.007781141437590122,
      0.027257388457655907,
      -0.10519182682037354,
      0.001987480092793703,
      -0.04003150388598442,
      0.04551650956273079,
      0.048742350190877914,
      -0.032754987478256226,
      0.014747480861842632,
      -0.0027695661410689354,
      0.02144237980246544,
      -0.08907289057970047,
      -0.009309647604823112,
      0.032770052552223206,
      -0.023684730753302574,
      -0.05645054578781128,
      0.09997843205928802,
      0.08794079720973969,
      -0.017775919288396835,
      0.012932288460433483,
      0.08238919079303741,
      -0.01432922575622797,
      -0.01758885383605957,
      0.017017293721437454,
      -0.09155657142400742,
      -0.019238634034991264,
      0.0605308935046196,
      -0.0077248262241482735,
      -0.027821440249681473,
      0.004004693124443293,
      0.0757133886218071,
      0.06287096440792084,
      -0.058556027710437775,
      -0.010838004760444164,
      -0.060851555317640305,
      -0.07670411467552185,
      0.032432254403829575,
      0.046517264097929,
      -0.047065094113349915,
      0.1326799839735031,
      -0.01228297222405672,
      -0.0466824509203434,
      0.006041510030627251,
      0.08353429287672043,
      0.02826589345932007,
      -0.01524821575731039,
      -0.0078274542465806,
      -0.024359267204999924,
      0.015186337754130363,
      -0.014590584672987461,
      -0.05047319456934929,
      -0.06386566162109375,
      0.05137106403708458,
      -0.049836866557598114,
      0.04770766943693161,
      0.0651584267616272,
      -0.04271414130926132,
      0.04064177721738815,
      -0.0023083288688212633,
      0.0568249337375164,
      0.04161231592297554,
      0.059693481773138046,
      0.003049491671845317,
      0.0071088713593780994,
      0.03784681484103203,
      0.011020701378583908,
      -0.05588101968169212,
      -0.07683081924915314,
      -0.04972735419869423,
      -0.005798923317342997,
      0.016208896413445473,
      -0.03152218088507652,
      -0.05785131826996803,
      -0.0004316875711083412,
      0.02240467071533203,
      -0.009751029312610626,
      0.031067103147506714,
      -0.056821685284376144,
      -0.02876579947769642,
      0.04467591643333435,
      0.012501236982643604,
      -0.031929291784763336,
      0.0491085909307003,
      0.03277776017785072,
      -0.024561485275626183,
      -0.04015234112739563,
      0.004159393720328808,
      0.03442234918475151,
      -0.03892245516180992,
      -0.07041596621274948,
      -0.055515822023153305,
      -0.07872742414474487,
      0.11320541799068451,
      0.025689322501420975,
      -0.055022068321704865,
      0.04321114718914032,
      0.01768271066248417,
      0.041402049362659454,
      0.04937322437763214,
      0.0064773536287248135,
      0.09937196224927902,
      0.06339004635810852,
      0.07352858781814575,
      -0.04879608377814293,
      0.008316870778799057,
      -0.058207809925079346,
      -0.0454084537923336,
      -0.0061733303591609,
      -0.08461202681064606,
      -0.01869949698448181,
      -0.03521118685603142,
      -0.0046868035569787025,
      -0.02020135335624218,
      0.0201275497674942,
      -0.001742370892316103,
      0.054734859615564346,
      -0.01499700266867876,
      -0.029751788824796677,
      -0.075694739818573,
      0.0739997923374176,
      -0.003474427852779627,
      0.08612114191055298,
      -0.0028100255876779556,
      0.030984967947006226,
      -0.007944043725728989,
      0.07303788512945175,
      0.0343843437731266,
      0.08931051194667816,
      0.014032692648470402,
      0.06945004314184189,
      -0.0031230791937559843,
      0.03769645467400551,
      -0.02964066155254841,
      -0.034194376319646835,
      -0.007287271786481142,
      -0.004332510754466057,
      0.009244993329048157,
      -0.006398266647011042,
      0.017627224326133728,
      0.014740416780114174,
      -0.014824849553406239,
      -0.01461726799607277,
      0.010537706315517426,
      0.021273765712976456,
      -0.019707776606082916,
      -0.0067702303640544415,
      -0.023513255640864372,
      -0.07040763646364212,
      -0.025130487978458405,
      0.01933705061674118,
      0.07099888473749161,
      0.02228967472910881,
      -0.027889909222722054,
      0.03763294592499733,
      0.050933901220560074,
      -0.018855925649404526,
      -0.0610523521900177,
      -0.0057671950198709965,
      0.07632935047149658,
      -0.007723350077867508,
      -0.020486662164330482,
      -0.0484987273812294,
      0.0368381142616272,
      0.011630523018538952,
      -0.110749252140522,
      0.0021562669426202774,
      0.0166837889701128,
      0.03392162546515465,
      -0.011356891132891178,
      -0.009876936674118042,
      -0.024740707129240036,
      -0.038520485162734985,
      0.010116590186953545,
      0.012693986296653748,
      0.023245999589562416,
      0.005289991851896048,
      0.0419984869658947,
      0.011531639844179153,
      -0.007212148047983646,
      0.057308755815029144,
      0.05719462409615517,
      0.10053383558988571,
      0.02909894846379757,
      0.004863453563302755,
      -0.025514019653201103,
      -0.04978296160697937,
      -0.04691191390156746,
      -0.059366341680288315,
      -0.04515446722507477,
      0.036592401564121246,
      0.012838221155107021,
      -0.055553317070007324,
      0.08461029082536697,
      -0.05114182084798813,
      9.396197128808126e-05,
      -0.05500335618853569,
      -0.042981211096048355,
      -0.013419191353023052,
      -0.03158803656697273,
      -0.01163716521114111,
      0.05072292685508728,
      0.017740286886692047,
      0.04249567538499832
    ],
    [
      0.021400466561317444,
      0.04337221756577492,
      0.047835756093263626,
      0.04005086049437523,
      0.04993109032511711,
      -0.03466011956334114,
      0.09317280352115631,
      -0.05932505801320076,
      -0.03096153400838375,
      0.011869939975440502,
      0.06432344019412994,
      -0.003950449172407389,
      -0.024356892332434654,
      -0.030720088630914688,
      0.057867683470249176,
      0.0011580783175304532,
      -0.05492638796567917,
      0.03893338516354561,
      0.055246151983737946,
      0.01704981178045273,
      0.016385143622756004,
      -0.05861780792474747,
      -0.02260228805243969,
      0.014942995272576809,
      -0.07434788346290588,
      -0.03585825115442276,
      -0.0682528018951416,
      0.044445112347602844,
      -0.053819965571165085,
      0.012965141795575619,
      0.0384027361869812,
      0.05085182934999466,
      -0.044907696545124054,
      0.04410306364297867,
      0.03163696080446243,
      0.006861980073153973,
      0.04737602174282074,
      0.04454844817519188,
      -0.0024904366582632065,
      -0.0010602740803733468,
      0.026146380230784416,
      -0.0016872852575033903,
      0.04732194170355797,
      -0.01421765424311161,
      -0.0237115528434515,
      -0.017721673473715782,
      0.06590399891138077,
      -0.003726511262357235,
      0.07048234343528748,
      -0.025916805490851402,
      0.036337725818157196,
      0.0011423269752413034,
      -0.015315110795199871,
      0.07035394012928009,
      -0.0659269168972969,
      0.01730537973344326,
      0.05390726774930954,
      -0.042937010526657104,
      -0.011232679709792137,
      0.008306731469929218,
      -0.05395291745662689,
      0.014663905836641788,
      -0.00485894363373518,
      -0.059469129890203476,
      0.05049965903162956,
      0.024968411773443222,
      -0.03526946157217026,
      0.014074968174099922,
      -0.017193831503391266,
      -0.04824359342455864,
      0.02887728624045849,
      -0.0877460166811943,
      0.013614322058856487,
      0.01592349447309971,
      0.04231410101056099,
      -0.026401614770293236,
      -0.02659732475876808,
      -0.032244447618722916,
      0.01620711199939251,
      -0.05311019346117973,
      0.08290104568004608,
      0.00951121561229229,
      0.007260655518621206,
      -0.04490267112851143,
      0.03266105800867081,
      0.05752614885568619,
      -0.03325861692428589,
      0.04488496482372284,
      0.006596419028937817,
      -0.05217199772596359,
      -0.05698217824101448,
      0.021525034680962563,
      0.04909873753786087,
      0.01942404918372631,
      0.019876768812537193,
      0.013283872976899147,
      -0.04603946581482887,
      0.02201685681939125,
      -0.013383537530899048,
      0.03440285846590996,
      0.06228551268577576,
      -0.025694865733385086,
      0.040875840932130814,
      -0.004361119586974382,
      -0.02761739306151867,
      -0.009813480079174042,
      -0.07002998888492584,
      -0.045561619102954865,
      -0.04811980202794075,
      0.027636539191007614,
      0.0196184441447258,
      -0.0007542567327618599,
      0.016391605138778687,
      0.0283969696611166,
      0.05259361490607262,
      0.04139333218336105,
      0.05960933119058609,
      0.049644216895103455,
      0.04457618668675423,
      0.016500402241945267,
      -0.014518954791128635,
      -0.0491580069065094,
      0.0323973223567009,
      -0.022176964208483696,
      -0.008537046611309052,
      0.0494779609143734,
      -0.04482189565896988,
      0.062459882348775864,
      0.013422572985291481,
      -0.03131002560257912,
      0.08596859872341156,
      -0.02962321974337101,
      0.0010323511669412255,
      0.0805484727025032,
      -0.07150379568338394,
      0.012473025359213352,
      -0.02559901587665081,
      0.02315460704267025,
      0.02246665023267269,
      -0.017727890983223915,
      -0.07568936049938202,
      0.044707730412483215,
      0.10331020504236221,
      -0.0019638894591480494,
      -0.10321316123008728,
      -0.01222719345241785,
      -0.05212191119790077,
      -0.01729566790163517,
      0.0040305424481630325,
      -0.019377421587705612,
      0.09635227173566818,
      -0.09964760392904282,
      -0.004812940023839474,
      0.06980980932712555,
      -0.012817488051950932,
      0.06064174696803093,
      0.00913736131042242,
      0.025022925809025764,
      -0.038529299199581146,
      -0.03081085905432701,
      0.022456347942352295,
      -0.01509195938706398,
      0.0008819503709673882,
      0.07175719738006592,
      -0.032591819763183594,
      -0.04791689291596413,
      0.04544420540332794,
      -0.013913048431277275,
      0.049526650458574295,
      -0.027919158339500427,
      -0.008454662747681141,
      0.05707862600684166,
      0.02079182118177414,
      0.11872828006744385,
      -0.041165951639413834,
      -0.01070482935756445,
      0.02014375664293766,
      0.0015111402608454227,
      -0.027018407359719276,
      0.03751842677593231,
      0.1270775943994522,
      0.049618810415267944,
      -0.0011047289008274674,
      0.015528777614235878,
      0.06004897877573967,
      0.029494350776076317,
      -0.008979393169283867,
      -0.047000158578157425,
      0.026180623099207878,
      -0.0055296714417636395,
      0.01839517056941986,
      0.005583870690315962,
      0.03239315003156662,
      0.025701023638248444,
      0.07389634847640991,
      -0.034101225435733795,
      -0.0437006950378418,
      -0.10398846119642258,
      -0.08391951024532318,
      0.0034608470741659403,
      0.00012005503958789632,
      0.030446534976363182,
      -0.006623207591474056,
      -0.05145621299743652,
      -0.028574297204613686,
      -0.013781850226223469,
      0.043466947972774506,
      -0.014732562005519867,
      0.008715675212442875,
      0.03196320682764053,
      0.022114988416433334,
      0.07369125634431839,
      -0.06329222768545151,
      0.015760790556669235,
      0.03801251947879791,
      -0.03619890287518501,
      0.020251916721463203,
      0.012590626254677773,
      0.09104541689157486,
      -0.06691047549247742,
      0.014391711913049221,
      0.048309843987226486,
      -0.0032418370246887207,
      -0.018109038472175598,
      0.06304866820573807,
      0.022544005885720253,
      -0.011083480902016163,
      -0.021960893645882607,
      0.034448347985744476,
      -0.07357670366764069,
      0.02450665645301342,
      0.011948851868510246,
      -0.0491444431245327,
      -0.04102097451686859,
      -0.025109682232141495,
      0.040894463658332825,
      0.03370595723390579,
      -0.04625142738223076,
      0.011026043444871902,
      -0.004055194556713104,
      -0.02679096721112728,
      -0.015363817103207111,
      -0.02310785837471485,
      -0.09054683893918991,
      0.07994073629379272,
      0.011141982860863209,
      -0.06280142068862915,
      -0.019879315048456192,
      0.016472401097416878,
      -0.020049361512064934,
      0.005993754602968693,
      0.02471586875617504,
      -0.057551585137844086,
      0.08315910398960114,
      0.07022089511156082,
      0.010210465639829636,
      0.010567077435553074,
      0.1035379096865654,
      -0.006342731881886721,
      -0.03278866782784462,
      0.019357720389962196,
      -0.023509914055466652,
      0.041668374091386795,
      0.057486552745103836,
      0.012160209938883781,
      0.016871297731995583,
      0.011686775833368301,
      -0.02895180508494377,
      -0.05814168602228165,
      0.005194281227886677,
      -0.004292346071451902,
      0.034792494028806686,
      -0.0488152801990509,
      0.09271673113107681,
      -0.06735099107027054,
      -0.020674029365181923,
      0.010867534205317497,
      0.046337902545928955,
      -0.06745250523090363,
      0.05490539222955704,
      -0.04780237376689911,
      -0.02966533601284027,
      0.02113853208720684,
      -0.01063035149127245,
      0.026851076632738113,
      0.03005109541118145,
      0.037769969552755356,
      -0.048413559794425964,
      0.033606961369514465,
      -0.056677233427762985,
      0.01580772176384926,
      0.0795707255601883,
      -0.07597890496253967,
      0.08449847251176834,
      -0.052894532680511475,
      -0.0035076451022177935,
      0.10452758520841599,
      0.045131564140319824,
      -0.01660773530602455,
      -0.009630752727389336,
      -0.06014915928244591,
      -0.03850273787975311,
      0.0044997395016252995,
      -0.11753279715776443,
      0.043467484414577484,
      -0.00920579768717289,
      -0.007718060165643692,
      0.0040767984464764595,
      -0.013744724914431572,
      -0.020364413037896156,
      0.01950136385858059,
      -0.042838048189878464,
      -0.029345909133553505,
      0.041368190199136734,
      0.005367039702832699,
      -0.0038669344503432512,
      0.05568060651421547,
      0.012717010453343391,
      -0.024015963077545166,
      0.0360289141535759,
      -0.032260410487651825,
      -0.07257486134767532,
      0.016197431832551956,
      -0.02512233331799507,
      -0.03389623388648033,
      -0.017784349620342255,
      -0.10195187479257584,
      -0.009450423531234264,
      -0.0026552292983978987,
      0.021247370168566704,
      0.024954071268439293,
      -0.007841767743229866,
      0.09247492253780365,
      0.07207547128200531,
      0.027736840769648552,
      -0.08396080136299133,
      -0.022594310343265533,
      -0.11255163699388504,
      -0.014697173610329628,
      0.0689569041132927,
      0.013877819292247295,
      0.07102054357528687,
      -0.02669416181743145,
      -0.04234623536467552,
      -0.02629624493420124,
      -0.002058465965092182,
      0.01159259956330061,
      0.05170891806483269,
      -0.03338351473212242,
      -0.0298309326171875,
      0.012128758244216442,
      0.11304764449596405,
      0.023164255544543266,
      0.04005148261785507,
      0.04243083670735359,
      0.06385963410139084,
      -0.029499614611268044,
      -0.002765537006780505,
      0.025447648018598557,
      -0.08856453001499176,
      -0.014453192241489887,
      -0.044027674943208694,
      -0.09811177849769592,
      0.029240699484944344,
      0.045130494982004166,
      -0.09156611561775208,
      0.02674044668674469,
      0.022093549370765686,
      0.0475580170750618,
      0.056411437690258026,
      0.04237205162644386,
      0.053159866482019424,
      -0.07191576808691025,
      -0.01688193716108799,
      -0.08069990575313568,
      -0.02717498503625393,
      -0.009594190865755081,
      -0.04687536135315895,
      0.10660771280527115,
      -0.0019254025537520647,
      0.06601700186729431,
      -0.020413145422935486,
      0.026325952261686325,
      0.029423685744404793,
      -0.06658542156219482,
      -0.0364365391433239,
      -0.03561321273446083,
      0.07906686514616013,
      0.04322826489806175,
      -0.051703616976737976,
      0.03466203063726425,
      -0.08292243629693985,
      -0.03575083240866661,
      0.06803169846534729,
      -0.05714511126279831,
      -0.0045303539372980595,
      0.043786466121673584,
      0.0740557312965393,
      -0.03974578529596329,
      -0.0792156308889389,
      -0.01900646463036537,
      -0.08501964062452316,
      -0.04794307053089142,
      0.0722607746720314,
      0.02773844078183174,
      -0.016039274632930756,
      -0.11012610048055649,
      -0.06467187404632568,
      0.0010852351551875472,
      0.024871021509170532,
      0.08233629912137985,
      0.0391673818230629,
      0.03373366594314575,
      -0.02606014907360077,
      -0.07424318790435791,
      0.005407556425780058,
      0.03207508474588394,
      -0.09784886240959167,
      0.02963285893201828,
      -0.026015961542725563,
      -0.0055758352391421795,
      0.050349097698926926,
      -0.004647395573556423,
      0.02237558923661709,
      -0.002246693940833211,
      -0.005150620359927416,
      -0.024391325190663338,
      -0.02890014834702015,
      0.0733586847782135,
      0.017066849395632744,
      0.0026926088612526655,
      0.07359357923269272,
      0.0023350478149950504,
      0.051124196499586105,
      0.053963687270879745,
      0.012626567855477333,
      0.0297919400036335,
      0.13916026055812836,
      0.06317777186632156,
      0.02255280688405037,
      -0.037181172519922256,
      0.07521026581525803,
      -0.08249484747648239,
      -0.05278562754392624,
      0.03145163133740425,
      0.0019117487827315927,
      0.02564138174057007,
      0.005948203150182962,
      -0.017907097935676575,
      0.06935834139585495,
      0.00042528074118308723,
      -0.07715193182229996,
      -0.013428703881800175,
      0.00865921564400196,
      0.010020283050835133,
      -0.012995006516575813,
      -0.03738370165228844,
      -0.023599600419402122,
      -0.011073391884565353,
      -0.023558033630251884,
      -0.04471080005168915,
      0.04052729159593582,
      0.10417540371417999,
      -0.09528184682130814,
      0.009596913121640682,
      0.054783593863248825,
      0.07142901420593262,
      -0.05519481003284454,
      -0.050570063292980194,
      -0.00456174835562706,
      -0.019716596230864525,
      0.03337065130472183,
      -0.05711172893643379,
      -0.01337066013365984,
      0.01555254403501749,
      0.033272381871938705,
      0.015318315476179123,
      -0.08272238820791245,
      -0.043438661843538284,
      0.0535673163831234,
      -0.029086846858263016,
      -0.020485790446400642,
      -0.05884181708097458,
      0.009143293835222721,
      0.06932557374238968,
      -0.058641817420721054,
      -0.08195808529853821,
      0.026700349524617195,
      0.015207518823444843,
      -0.018599998205900192,
      -0.00236823339946568,
      -0.047080349177122116,
      -0.017449643462896347,
      -0.07074209302663803,
      -0.0039142114110291,
      -0.02428184263408184,
      -0.07526779174804688,
      -0.05559171736240387,
      -0.03819480165839195,
      -0.018704798072576523,
      -0.009435893967747688,
      0.041508693248033524,
      -0.049578625708818436
    ],
    [
      0.04504148289561272,
      -0.023748474195599556,
      0.02267066203057766,
      0.035630349069833755,
      -0.02357986569404602,
      0.07418263703584671,
      0.061966367065906525,
      -0.021982762962579727,
      0.0007538803038187325,
      -0.04348180070519447,
      -0.009046362712979317,
      -0.010719307698309422,
      0.036802712827920914,
      0.03503631055355072,
      0.027330690994858742,
      -0.04649071395397186,
      0.001774043426848948,
      -0.03635338321328163,
      -0.009033526293933392,
      -0.004431450739502907,
      0.02020806074142456,
      0.05242592841386795,
      -0.006850565318018198,
      0.011908300220966339,
      0.01447372604161501,
      -0.014896158128976822,
      -0.021876389160752296,
      0.03181207925081253,
      -0.04090382903814316,
      6.208559352671728e-05,
      0.010312980972230434,
      -0.008611506782472134,
      -0.1248040720820427,
      0.03041675314307213,
      0.021134482696652412,
      0.01107500959187746,
      0.014634658582508564,
      0.013483143411576748,
      0.015705186873674393,
      0.022291356697678566,
      0.05433778464794159,
      -0.025813017040491104,
      -0.01591065526008606,
      0.06942575424909592,
      -0.056347254663705826,
      -0.03432739898562431,
      -0.01191367581486702,
      0.025953035801649094,
      0.02553701028227806,
      0.009332874789834023,
      0.009860142134130001,
      0.07144728302955627,
      0.034650418907403946,
      0.008129782974720001,
      -0.03154556453227997,
      0.005208919290453196,
      0.021557990461587906,
      0.047343093901872635,
      0.05706595629453659,
      -0.02184215933084488,
      -0.008249430917203426,
      0.06711265444755554,
      -0.030560368672013283,
      -0.052334461361169815,
      -0.06700576841831207,
      0.029917091131210327,
      -0.038403674960136414,
      -0.06390593945980072,
      0.04051044583320618,
      0.02184259332716465,
      0.07419554889202118,
      0.03984850272536278,
      0.0030289916321635246,
      -0.004534482955932617,
      0.016526447609066963,
      -0.021842414513230324,
      0.04441266879439354,
      0.025462916120886803,
      0.05257733538746834,
      -0.0005029222811572254,
      -0.01954798959195614,
      0.02127382531762123,
      0.05205671861767769,
      -0.02838541939854622,
      0.06838977336883545,
      -0.018165219575166702,
      0.0553906112909317,
      0.021224698051810265,
      0.0011233396362513304,
      -0.01333813089877367,
      -0.01904876157641411,
      -0.029961535707116127,
      0.08637697249650955,
      -0.11100023984909058,
      0.025487501174211502,
      0.007247747387737036,
      0.019112253561615944,
      0.008531538769602776,
      -0.01593141444027424,
      -0.04979037120938301,
      0.0008421633974649012,
      0.030540024861693382,
      -0.04598000645637512,
      -0.05083752050995827,
      -0.02247699722647667,
      0.04095207154750824,
      0.014790051616728306,
      0.03179679065942764,
      -0.003992846701294184,
      0.03523959964513779,
      0.03075064904987812,
      -0.09948105365037918,
      -0.05480325594544411,
      0.0030675691086798906,
      -0.0022048274986445904,
      -0.01773694157600403,
      0.05506996437907219,
      -0.03410159423947334,
      0.027761848643422127,
      0.024372993037104607,
      -0.020266244187951088,
      0.014759489335119724,
      0.037482015788555145,
      -0.004758510272949934,
      0.0450277216732502,
      0.011452285572886467,
      -0.05351991578936577,
      -0.004176728893071413,
      0.006878011394292116,
      -0.05687377601861954,
      0.00019437989976722747,
      -0.03531000390648842,
      0.0928988978266716,
      0.022198865190148354,
      -0.10395444929599762,
      -0.01387315895408392,
      -0.06623101234436035,
      0.06030885875225067,
      -0.08394589275121689,
      -0.008710811845958233,
      -0.06114925816655159,
      -0.002162868157029152,
      -0.028669022023677826,
      0.06813083589076996,
      -0.04821421951055527,
      -0.003918352071195841,
      -0.046032752841711044,
      0.0076120817102491856,
      0.0150308171287179,
      -0.01235197763890028,
      -0.008564691059291363,
      0.07753603160381317,
      0.024755358695983887,
      0.0035512950271368027,
      -0.06359673291444778,
      0.016513358801603317,
      -0.12357216328382492,
      -0.03696577623486519,
      -0.0060806963592767715,
      -0.028791148215532303,
      -0.07624910771846771,
      -0.043486714363098145,
      -0.04767754301428795,
      0.07078193128108978,
      0.07089783251285553,
      -0.10713262856006622,
      0.061280522495508194,
      -0.040800515562295914,
      0.05754219740629196,
      -0.004178097005933523,
      -0.03799627721309662,
      -0.013225054368376732,
      -0.018267707899212837,
      -0.03196793049573898,
      -0.014032806269824505,
      0.08987641334533691,
      -0.05856630206108093,
      -0.015250119380652905,
      0.04013891518115997,
      0.04400040954351425,
      0.015013213269412518,
      0.009241378866136074,
      -0.0047878301702439785,
      0.03616313263773918,
      -0.03325806185603142,
      0.05161106958985329,
      0.07119946926832199,
      0.032071858644485474,
      -0.08102359622716904,
      0.04929410666227341,
      0.02657802402973175,
      0.04208725690841675,
      -0.048257503658533096,
      -0.006843701004981995,
      0.04301416128873825,
      0.0626538023352623,
      0.027534054592251778,
      -0.029004111886024475,
      0.020658863708376884,
      -0.06065014377236366,
      -0.01645662821829319,
      0.06424414366483688,
      -0.06692790985107422,
      -0.0031940178014338017,
      0.11284604668617249,
      0.005375084932893515,
      0.005344843957573175,
      -0.021669957786798477,
      0.014237245544791222,
      0.030237039551138878,
      0.05922189727425575,
      -0.03952282667160034,
      0.09601084887981415,
      -0.011045923456549644,
      -0.03907201439142227,
      0.012851147912442684,
      0.0684366524219513,
      0.06025415658950806,
      0.0023079102393239737,
      0.025907857343554497,
      -0.07258578389883041,
      -0.02369089052081108,
      0.04010775685310364,
      0.03737414628267288,
      0.028171516954898834,
      0.02355615608394146,
      -0.01965649239718914,
      -0.03893111273646355,
      -0.04897993057966232,
      0.012178566306829453,
      0.02255532145500183,
      0.07382777333259583,
      0.01584024168550968,
      0.03454016521573067,
      -0.00296682002954185,
      0.0013268765760585666,
      0.02756139449775219,
      0.031761035323143005,
      -0.04736986756324768,
      0.011757967993617058,
      -0.009487510658800602,
      0.021630629897117615,
      0.042664770036935806,
      -0.008858255110681057,
      -0.03652675449848175,
      0.029597772285342216,
      0.024588897824287415,
      0.011319304816424847,
      0.05628427863121033,
      0.018589241430163383,
      0.031155046075582504,
      0.0849803164601326,
      0.011507740244269371,
      -0.061157193034887314,
      0.0312278401106596,
      0.07441461086273193,
      -0.019576337188482285,
      -0.0011265407083556056,
      -0.024323973804712296,
      0.030172910541296005,
      -0.011326776817440987,
      -0.005683020688593388,
      0.00044752986286766827,
      0.07047287374734879,
      0.04225046932697296,
      0.028200339525938034,
      0.05755884572863579,
      -0.04735642299056053,
      0.028643539175391197,
      0.022430282086133957,
      -0.11314878612756729,
      0.004226583521813154,
      -0.06011490151286125,
      -0.018733341246843338,
      0.05822966992855072,
      0.057213619351387024,
      0.0407308004796505,
      -0.024869399145245552,
      -0.030310697853565216,
      -0.004544002935290337,
      -0.031034749001264572,
      0.021615328267216682,
      -0.02364302985370159,
      -0.022685039788484573,
      0.04217897355556488,
      0.04529433324933052,
      -0.004476516041904688,
      0.020025888457894325,
      0.0029300639871507883,
      0.033039771020412445,
      0.0657883957028389,
      -0.05529029294848442,
      0.021441668272018433,
      0.026272868737578392,
      -0.01738869398832321,
      0.07516921311616898,
      0.11455440521240234,
      -0.03414535894989967,
      0.01376640610396862,
      0.024590522050857544,
      -0.023494981229305267,
      0.006868251599371433,
      -0.08091610670089722,
      0.04780816659331322,
      0.03833521902561188,
      -0.020912345498800278,
      -0.02249949425458908,
      -0.046448659151792526,
      -0.03423783928155899,
      -0.10347744077444077,
      0.01290719024837017,
      0.026164263486862183,
      0.02355390414595604,
      0.04721274599432945,
      -0.034330446273088455,
      -0.012093527242541313,
      -0.00125517335254699,
      0.04440087825059891,
      -0.020407918840646744,
      0.019707707688212395,
      0.010912266559898853,
      -0.0507330447435379,
      0.02104327455163002,
      -0.06068532541394234,
      0.06183154135942459,
      0.056077878922224045,
      -0.0508895069360733,
      0.017350634559988976,
      0.001970347948372364,
      -0.029313987120985985,
      0.05387023463845253,
      0.0279524065554142,
      0.0673040896654129,
      0.0331745445728302,
      -0.01396776270121336,
      -0.03028828464448452,
      -0.06939874589443207,
      0.0060296137817204,
      -0.08078502863645554,
      0.06249953433871269,
      0.03655088692903519,
      0.014512723311781883,
      -0.005379301030188799,
      -0.07827761024236679,
      -0.03588111698627472,
      0.04316946491599083,
      -0.02501898631453514,
      0.03630107268691063,
      0.007849317975342274,
      0.06750250607728958,
      0.011735043488442898,
      -0.017938153818249702,
      -0.06827738881111145,
      0.054901886731386185,
      -0.1165723204612732,
      0.08361736685037613,
      -0.035240981727838516,
      0.09132851660251617,
      0.01912515237927437,
      0.040734030306339264,
      -0.05672704800963402,
      0.01706945337355137,
      -0.019607488065958023,
      -0.04218650236725807,
      -0.06400502473115921,
      -0.014737395569682121,
      0.031091969460248947,
      -0.07139468938112259,
      0.01616869680583477,
      -0.08790641278028488,
      0.1277567595243454,
      -0.004101317375898361,
      0.04837920889258385,
      0.05418575182557106,
      -0.025448473170399666,
      0.021511217579245567,
      0.06903649866580963,
      0.049224432557821274,
      0.026411062106490135,
      0.06854894757270813,
      0.037764910608530045,
      7.916508911876008e-05,
      -0.007334307301789522,
      0.017255840823054314,
      -0.0575282946228981,
      -0.00776289077475667,
      0.06087103113532066,
      -0.03260457515716553,
      -0.0007204711437225342,
      0.030878368765115738,
      0.018349025398492813,
      0.010065756738185883,
      0.03343014791607857,
      0.0333133190870285,
      -0.05192413553595543,
      -0.042019542306661606,
      -0.0582609586417675,
      0.03052905946969986,
      -0.03326842188835144,
      -0.058249618858098984,
      -0.05485588684678078,
      -0.02331523597240448,
      -0.003123885253444314,
      0.044011060148477554,
      0.06466089934110641,
      0.05602545663714409,
      0.008032211102545261,
      0.04281320795416832,
      0.00975393783301115,
      -0.021685833111405373,
      0.05262046307325363,
      0.001485581393353641,
      -0.008305829018354416,
      0.06619039177894592,
      -0.0380343496799469,
      -0.01255140732973814,
      -0.02271636575460434,
      0.027253007516264915,
      -0.0170655008405447,
      -0.031899381428956985,
      0.022839104756712914,
      0.020493393763899803,
      0.04614609107375145,
      -0.08148012310266495,
      -0.028344672173261642,
      -0.019033242017030716,
      -0.013196482323110104,
      0.10800641030073166,
      0.059111807495355606,
      0.05562673136591911,
      -0.02579129859805107,
      0.01530899666249752,
      0.013234724290668964,
      -0.009932242333889008,
      -0.00310267205350101,
      -0.011673538014292717,
      -0.013660477474331856,
      -0.06318347901105881,
      0.03897590935230255,
      -0.031176172196865082,
      -0.03518545255064964,
      -0.03398456051945686,
      -0.017837734892964363,
      -0.037409473210573196,
      -0.012140999548137188,
      -0.024544892832636833,
      0.12989093363285065,
      -0.040438927710056305,
      -0.03847845643758774,
      -0.023625748232007027,
      -0.04538291320204735,
      -0.0281179491430521,
      -0.01511996891349554,
      -0.011791319586336613,
      -0.041791882365942,
      -0.04670529067516327,
      0.050930023193359375,
      0.06668317317962646,
      0.023548506200313568,
      -0.047227147966623306,
      0.019539957866072655,
      0.018982913345098495,
      0.012466794811189175,
      -0.07086332887411118,
      0.02102554216980934,
      -0.02701551280915737,
      0.06642481684684753,
      0.04295547306537628,
      -0.04570090398192406,
      -0.0013975059846416116,
      0.058389101177453995,
      -0.043898679316043854,
      -0.007338440977036953,
      -0.06287401169538498,
      0.05564994364976883,
      -0.0026055574417114258,
      0.02438659407198429,
      0.006357104983180761,
      0.04585191234946251,
      0.017712512984871864,
      -0.017797624692320824,
      0.01796465925872326,
      -0.05822725594043732,
      0.06567080318927765,
      -0.02753123641014099,
      0.020456377416849136,
      -0.02795274183154106,
      0.03848094493150711,
      -0.03928196802735329,
      -0.003329660277813673,
      -0.009817858226597309,
      0.04421535134315491,
      -0.020154934376478195,
      -0.006877048872411251,
      -0.003247457556426525,
      -0.06086592376232147,
      0.01809481903910637,
      0.011988883838057518,
      -0.06782814115285873,
      0.0016906742239370942,
      0.02891634777188301,
      -0.07876303046941757,
      -0.014629497192800045
    ],
    [
      0.06962358951568604,
      0.06882800161838531,
      -0.02742009051144123,
      -0.055960070341825485,
      -0.026219382882118225,
      0.002896409947425127,
      0.05150393396615982,
      0.01907104067504406,
      0.044626396149396896,
      -0.012054718099534512,
      -0.00026418123161420226,
      0.034805476665496826,
      0.08810123056173325,
      0.03673327714204788,
      -0.008885231800377369,
      -0.022088494151830673,
      -0.002791252452880144,
      -0.08370056003332138,
      0.04482676088809967,
      -0.006790648680180311,
      -0.03683095425367355,
      0.0006215053144842386,
      -0.05120880529284477,
      0.009171206504106522,
      -0.013782061636447906,
      -0.0320182628929615,
      0.02138165757060051,
      -0.003428328549489379,
      0.04893828555941582,
      -0.00558252539485693,
      0.022069428116083145,
      0.04481639340519905,
      0.05553218349814415,
      0.06100437417626381,
      0.007099496200680733,
      -0.015529409050941467,
      0.013964145444333553,
      -0.013034303672611713,
      -0.01576571725308895,
      0.01500728353857994,
      0.0838843360543251,
      0.0517866276204586,
      -0.10621580481529236,
      0.009213859215378761,
      0.005545902531594038,
      -0.04986457899212837,
      0.03343379497528076,
      -0.05629246309399605,
      0.010971314273774624,
      -0.0415622778236866,
      -0.01733408309519291,
      -0.04013938829302788,
      -0.0555914007127285,
      -0.11980414390563965,
      0.0024716737680137157,
      0.012271896004676819,
      0.027924232184886932,
      0.008910760283470154,
      0.02944212220609188,
      0.028843993321061134,
      0.07856065779924393,
      0.03930799663066864,
      -0.031854450702667236,
      0.0027510758955031633,
      0.07819174975156784,
      -0.03409262374043465,
      0.005541830323636532,
      0.01847965270280838,
      -0.014222339726984501,
      0.024646982550621033,
      0.008093491196632385,
      -0.10346713662147522,
      -0.02970505878329277,
      -0.023482874035835266,
      0.03893166780471802,
      -0.06842834502458572,
      0.029645077884197235,
      0.02432416006922722,
      -0.04976552352309227,
      0.06350424140691757,
      0.007829582318663597,
      0.155548095703125,
      -0.03528274968266487,
      0.05160747095942497,
      -1.8747010471997783e-05,
      -0.029711712151765823,
      0.019653448835015297,
      -0.017270192503929138,
      0.009869242087006569,
      -0.043174080550670624,
      0.005135569721460342,
      0.04449529945850372,
      0.020494643598794937,
      0.008026820607483387,
      0.011312759481370449,
      0.012799552641808987,
      -0.0383433923125267,
      0.01589859090745449,
      0.013724973425269127,
      0.06760422140359879,
      0.06706110388040543,
      -0.005366032477468252,
      0.05185661464929581,
      -0.02125503681600094,
      0.0020208267960697412,
      0.028709763661026955,
      -0.08384712040424347,
      -0.007612229324877262,
      0.03137172758579254,
      -0.00360939372330904,
      -0.030787277966737747,
      -0.016431350260972977,
      0.021453794091939926,
      0.011282948777079582,
      0.037364739924669266,
      -0.03634442389011383,
      0.0679103285074234,
      0.010639339685440063,
      0.018141448497772217,
      -0.03576577454805374,
      0.00011331334826536477,
      0.021093394607305527,
      0.06978807598352432,
      -0.021944930776953697,
      -0.029466256499290466,
      0.02681857720017433,
      0.03679749369621277,
      -0.010590425692498684,
      -0.04530443251132965,
      0.052348118275403976,
      0.08354899287223816,
      -0.009787826798856258,
      -0.062086284160614014,
      -0.020670628175139427,
      0.06112920865416527,
      -0.022661946713924408,
      0.015070893801748753,
      0.06860119104385376,
      0.005078565329313278,
      0.06969646364450455,
      -0.028931690379977226,
      0.08505234867334366,
      0.05306285619735718,
      0.006975929252803326,
      0.03658761456608772,
      0.014263411052525043,
      -0.029015423730015755,
      0.07967967540025711,
      0.0043064625933766365,
      -0.004478286020457745,
      -0.03831400349736214,
      0.05017629265785217,
      -0.05315496399998665,
      -0.026759561151266098,
      -0.019043944776058197,
      -0.1249946653842926,
      -0.052538737654685974,
      0.0377710685133934,
      -0.06659768521785736,
      -0.04101094603538513,
      -0.0274371225386858,
      -0.016944771632552147,
      -0.028290709480643272,
      0.05270278453826904,
      0.008934351615607738,
      0.06532150506973267,
      -0.02097863331437111,
      -0.027813978493213654,
      0.050634387880563736,
      0.07244274020195007,
      0.028272423893213272,
      -0.00016038492321968079,
      0.012418931350111961,
      -0.03566879406571388,
      -0.00589198712259531,
      -0.022472359240055084,
      -0.04007697477936745,
      0.07576079666614532,
      -0.03313711658120155,
      0.003627302823588252,
      -0.02737993374466896,
      -0.015909811481833458,
      0.025161880999803543,
      -0.04907857999205589,
      -0.08043590933084488,
      -0.016677647829055786,
      0.05146147310733795,
      -0.028718577697873116,
      0.04654032737016678,
      0.04917695000767708,
      0.09313935786485672,
      0.006322883069515228,
      0.037701696157455444,
      -0.03707908093929291,
      -0.05608348920941353,
      0.04062001407146454,
      0.02674369513988495,
      0.016676483675837517,
      -0.009264804422855377,
      0.030916918069124222,
      0.03306710720062256,
      -0.03128518909215927,
      -0.05888077989220619,
      0.018339892849326134,
      0.054700106382369995,
      -0.004092449322342873,
      0.02246834523975849,
      0.02773415669798851,
      0.03714180365204811,
      -0.008582137525081635,
      -0.0359916016459465,
      0.006572092417627573,
      0.022487083449959755,
      -0.019409263506531715,
      -0.009561805054545403,
      0.03540043532848358,
      0.0010432203998789191,
      -0.022331293672323227,
      0.02081936225295067,
      0.004545674193650484,
      -0.010005800984799862,
      0.031018616631627083,
      0.055732984095811844,
      0.02721431478857994,
      -0.026444852352142334,
      -0.058676838874816895,
      0.04878732189536095,
      -0.06075417995452881,
      0.07759378105401993,
      -0.07311148196458817,
      -0.0802452564239502,
      -0.0775388851761818,
      -0.036983225494623184,
      -0.010192610323429108,
      -0.02372106909751892,
      -0.00894906185567379,
      0.029383081942796707,
      0.009737275540828705,
      -0.05013430491089821,
      -0.019743017852306366,
      0.049449674785137177,
      -0.028723685070872307,
      -0.05120186507701874,
      -0.054687704890966415,
      -0.026240291073918343,
      0.0033961054868996143,
      -0.004816549364477396,
      -0.019348161295056343,
      -0.01908743940293789,
      0.00655188225209713,
      0.0725194588303566,
      0.01801888830959797,
      -0.04601609706878662,
      0.004770218860358,
      0.03305291384458542,
      0.005732077639549971,
      0.005992132704705,
      0.020771322771906853,
      -0.015921344980597496,
      -0.0038268337957561016,
      0.04640566557645798,
      0.021773528307676315,
      0.039667293429374695,
      0.06107717752456665,
      -0.005646789912134409,
      -0.02874194271862507,
      -0.04332992061972618,
      -0.015999184921383858,
      -0.04015987738966942,
      -0.03565022349357605,
      -0.04480950906872749,
      -0.07170968502759933,
      0.013281595893204212,
      -0.03419869393110275,
      -0.07747111469507217,
      -0.03162756562232971,
      -0.0005056974478065968,
      0.09835495054721832,
      -0.0951516181230545,
      0.04127577692270279,
      0.012162137776613235,
      -0.004174009431153536,
      -0.03687402978539467,
      0.009260301478207111,
      -0.01732555218040943,
      0.02394266612827778,
      -0.03248346969485283,
      0.0413011871278286,
      -0.029664741829037666,
      0.018808281049132347,
      0.01423194445669651,
      0.05587344989180565,
      -0.008561956696212292,
      -0.07606346905231476,
      -0.017263801768422127,
      -0.032191962003707886,
      0.05022994801402092,
      -0.05166952684521675,
      -0.03925187513232231,
      -0.017699487507343292,
      -0.04713484272360802,
      -0.028163475915789604,
      0.038534317165613174,
      -0.050147075206041336,
      0.039257124066352844,
      -0.03475835919380188,
      -0.010266234166920185,
      -0.012155487202107906,
      -0.06443732231855392,
      0.06112521514296532,
      -0.001217253739014268,
      0.05625694990158081,
      -0.03117789328098297,
      0.06244298815727234,
      -0.05105239152908325,
      0.04287458583712578,
      -0.09212671220302582,
      -0.0035074965562671423,
      -0.018170543015003204,
      0.006508170627057552,
      0.02169838175177574,
      0.12396581470966339,
      -0.005844736471772194,
      -0.0649624839425087,
      0.0015196037711575627,
      0.08762548118829727,
      -0.00947566982358694,
      0.042083628475666046,
      0.02133091539144516,
      -0.0038906338158994913,
      0.016537195071578026,
      0.007004087790846825,
      -0.057315975427627563,
      0.017078543081879616,
      0.009550395421683788,
      -0.04649876058101654,
      0.041129253804683685,
      -0.0604868121445179,
      0.017348479479551315,
      0.0661945790052414,
      0.04376574233174324,
      -0.00907245371490717,
      0.0583832822740078,
      0.00302887917496264,
      -0.08042126148939133,
      0.012461340986192226,
      -0.030441800132393837,
      -0.005131743848323822,
      -0.05630427598953247,
      0.030562909319996834,
      0.05188664793968201,
      -0.039292123168706894,
      0.030454672873020172,
      0.04084496200084686,
      0.01429416798055172,
      0.01747400499880314,
      -0.030586324632167816,
      -0.08016933500766754,
      -0.04691596329212189,
      -0.05182218179106712,
      0.01105404645204544,
      -0.015169898979365826,
      -0.048339005559682846,
      0.02812490053474903,
      -0.01081265602260828,
      0.04519634321331978,
      0.03733529895544052,
      0.03281550481915474,
      0.025243820622563362,
      -0.031058141961693764,
      -0.013707688078284264,
      0.004226610530167818,
      0.010885162279009819,
      -0.008919958025217056,
      0.050207123160362244,
      -0.07791981101036072,
      -0.0034245760180056095,
      0.07395351678133011,
      0.025669755414128304,
      0.10949359834194183,
      0.057505328208208084,
      0.008908511139452457,
      -0.04991403967142105,
      0.013680940493941307,
      0.02927303873002529,
      -0.0024880196433514357,
      -0.008406694047152996,
      0.1004229187965393,
      0.04983964189887047,
      -0.045667994767427444,
      0.045225661247968674,
      -0.03627540171146393,
      0.03240090236067772,
      0.03559240326285362,
      -0.009314225986599922,
      -0.029386870563030243,
      0.07167401164770126,
      0.018322071060538292,
      0.017380008473992348,
      0.013431558385491371,
      -0.04959328472614288,
      0.02017868496477604,
      -0.0004084142274223268,
      -0.053683992475271225,
      0.019206764176487923,
      0.02668362855911255,
      -0.009946445934474468,
      0.006714364513754845,
      -0.028305407613515854,
      0.13428810238838196,
      -0.03702859953045845,
      0.0533391498029232,
      0.018641233444213867,
      0.018599294126033783,
      -0.06779482960700989,
      -0.11905701458454132,
      -0.0579645112156868,
      -0.04331061616539955,
      -0.045086801052093506,
      0.0284283347427845,
      0.0019376921700313687,
      0.03482970967888832,
      -0.02545767091214657,
      -0.0746936947107315,
      0.05726243183016777,
      0.021247757598757744,
      -0.014307857491075993,
      0.028293203562498093,
      0.07787355780601501,
      0.016919739544391632,
      -0.02896971069276333,
      -0.015119705349206924,
      0.07048547267913818,
      0.010093972086906433,
      -0.03325333446264267,
      0.005455483216792345,
      0.05943387374281883,
      0.0233150701969862,
      -0.05086314678192139,
      -0.09855616837739944,
      0.011079065501689911,
      -0.01913958229124546,
      -0.030749516561627388,
      -0.03077641688287258,
      -0.02136942557990551,
      0.0651438981294632,
      0.023524845018982887,
      -0.026410987600684166,
      0.03682645410299301,
      0.09687819331884384,
      0.0565812811255455,
      -0.054795585572719574,
      -0.0637323334813118,
      -0.04416303709149361,
      0.028432421386241913,
      -0.017650486901402473,
      -0.035134103149175644,
      -0.10542868077754974,
      -0.010996343567967415,
      -0.023476993665099144,
      0.08207102864980698,
      -0.032404087483882904,
      0.017919976264238358,
      -0.02241569198668003,
      -0.11584185063838959,
      0.0045302859507501125,
      -0.07709244638681412,
      0.018632519990205765,
      -0.08432766050100327,
      0.07166193425655365,
      -0.031471699476242065,
      -0.012906723655760288,
      0.016093065962195396,
      0.0014353047590702772,
      0.011245072819292545,
      0.07140269130468369,
      0.005278186406940222,
      -0.10728324204683304,
      0.0665576308965683,
      0.06331764161586761,
      -0.024285484105348587,
      0.1167156919836998,
      -0.04067816212773323,
      -0.03459002822637558,
      -0.06467386335134506,
      -0.006544824223965406,
      0.07721316069364548,
      0.04765354469418526,
      0.0251412782818079,
      -0.014581911265850067,
      0.024223752319812775,
      -0.005737486761063337,
      0.023342879489064217,
      0.0168980211019516,
      0.08167872577905655,
      0.03733058273792267,
      -0.14029115438461304,
      0.008251864463090897,
      -0.027786796912550926,
      0.03552989661693573,
      -0.0075635891407728195,
      0.02320689521729946,
      0.011628805659711361
    ],
    [
      -0.019836653023958206,
      0.0021145085338503122,
      0.040743619203567505,
      0.036619946360588074,
      0.0697949230670929,
      0.04692791774868965,
      -0.04142283275723457,
      0.06998463720083237,
      -0.08894932270050049,
      -0.0717671811580658,
      -0.03467068448662758,
      0.02467610128223896,
      0.012853973545134068,
      0.049469586461782455,
      -0.037257011979818344,
      -0.040625911206007004,
      -0.044624485075473785,
      0.0017660396406427026,
      0.015561308711767197,
      -0.013370255008339882,
      -0.019874250516295433,
      -0.08069760352373123,
      0.030634265393018723,
      -0.018178481608629227,
      -0.03082813322544098,
      -0.01982927694916725,
      -0.0617431104183197,
      0.07426759600639343,
      0.008881893940269947,
      0.07797355204820633,
      -0.06232476979494095,
      0.05563296005129814,
      0.05931566283106804,
      -0.002472918713465333,
      -0.02595089003443718,
      0.02047630026936531,
      0.01332275290042162,
      0.03437184914946556,
      -0.03126960247755051,
      0.051252443343400955,
      0.08492248505353928,
      -0.005101571790874004,
      0.04264218360185623,
      -0.054680321365594864,
      0.043576642870903015,
      0.07637731730937958,
      -0.006110126152634621,
      0.013869323767721653,
      0.00022002728655934334,
      0.015170871280133724,
      0.11625658720731735,
      0.034785784780979156,
      0.0038861529901623726,
      -0.03166796267032623,
      -0.11422162503004074,
      -0.04403857886791229,
      -0.01010211929678917,
      -0.05348232761025429,
      -0.06009996309876442,
      -0.04245397821068764,
      -0.09131515026092529,
      -0.054240308701992035,
      -0.010409246198832989,
      0.007928154431283474,
      0.02033652924001217,
      0.0033996624406427145,
      0.010896837338805199,
      -0.07583343982696533,
      -0.043686818331480026,
      -0.07034637033939362,
      0.04865735396742821,
      0.02902919054031372,
      -0.05495365336537361,
      0.002856286708265543,
      0.05729133263230324,
      0.053234390914440155,
      0.004573913756757975,
      0.05172990262508392,
      -0.0033799277152866125,
      -0.02708647958934307,
      -0.004019835032522678,
      0.006966174580156803,
      -0.011618558317422867,
      -0.004764157347381115,
      0.06409640610218048,
      0.033137839287519455,
      -0.02138368971645832,
      -0.022439446300268173,
      0.0561365969479084,
      0.047599442303180695,
      0.02021409571170807,
      0.005655194632709026,
      -0.03737251088023186,
      -0.07277059555053711,
      0.025870997458696365,
      0.08489631116390228,
      -0.04320001229643822,
      0.02401145175099373,
      -0.004375671502202749,
      -0.01733914203941822,
      -0.027316994965076447,
      0.04001108929514885,
      -0.028450924903154373,
      -0.03647571802139282,
      0.008826280944049358,
      -0.06024731695652008,
      -0.026130307465791702,
      0.03872014209628105,
      -0.047911856323480606,
      0.054825231432914734,
      -0.07974223047494888,
      -0.0270733293145895,
      0.018110834062099457,
      -0.03487178683280945,
      -0.04667927697300911,
      -0.014355362392961979,
      -0.0646660253405571,
      -0.01942317560315132,
      -0.0460992157459259,
      -0.008807923644781113,
      0.019042767584323883,
      -0.07858429849147797,
      -0.049848541617393494,
      0.10849045217037201,
      0.061389513313770294,
      -0.022745352238416672,
      0.0249833595007658,
      -0.04021083191037178,
      0.005806785076856613,
      -0.0694059357047081,
      -0.02174205332994461,
      0.011170797049999237,
      0.05041969567537308,
      -0.040882740169763565,
      0.04165960103273392,
      0.054301466792821884,
      0.01133671123534441,
      0.051535993814468384,
      0.050893042236566544,
      0.0031875125132501125,
      -0.03749977424740791,
      -0.0079721137881279,
      0.022419491782784462,
      -0.046757906675338745,
      -0.10154420137405396,
      -0.01029560063034296,
      0.020186087116599083,
      0.0058655692264437675,
      0.02925981394946575,
      -0.02546565793454647,
      -0.05860709398984909,
      -0.07006816565990448,
      -0.020158670842647552,
      -0.046563684940338135,
      -0.08823550492525101,
      -0.11209317296743393,
      -0.034506864845752716,
      0.003239379031583667,
      -0.06924379616975784,
      0.0032591691706329584,
      -0.002199087990447879,
      0.016055380925536156,
      -0.01557293813675642,
      -0.01017202902585268,
      0.010806280188262463,
      0.0032290122471749783,
      0.043875712901353836,
      0.0063433474861085415,
      -0.004498876631259918,
      0.03309231996536255,
      0.008642035536468029,
      -0.03413090109825134,
      -0.03212304413318634,
      0.021095436066389084,
      -0.021497784182429314,
      0.030965076759457588,
      0.05200253427028656,
      -0.07766025513410568,
      -0.0030611767433583736,
      -0.027099424973130226,
      -0.022103361785411835,
      0.046100012958049774,
      0.07939837127923965,
      -0.013750731013715267,
      -0.0856812596321106,
      -0.05106433108448982,
      -0.04630500078201294,
      0.018917439505457878,
      0.024403775110840797,
      0.08564715087413788,
      0.07057570666074753,
      -0.049561042338609695,
      -0.040186796337366104,
      0.0026152250356972218,
      -0.005476962774991989,
      0.005398548673838377,
      -0.023789087310433388,
      0.05684543028473854,
      -0.007550688926130533,
      -0.08140981942415237,
      0.07543640583753586,
      0.018758926540613174,
      -0.12360543012619019,
      -0.009220470674335957,
      -0.02680153399705887,
      -0.004932161420583725,
      0.04398760199546814,
      0.028157547116279602,
      -0.04080968350172043,
      0.053793370723724365,
      0.03734368085861206,
      0.029546191915869713,
      -0.01808190904557705,
      0.05733594670891762,
      0.006519381422549486,
      0.003982845228165388,
      -0.023130429908633232,
      0.01801171898841858,
      0.0015045451000332832,
      0.04989952966570854,
      0.003140463726595044,
      -0.005271133501082659,
      -0.10283265262842178,
      -0.01961025595664978,
      -0.09832429140806198,
      -0.01102110929787159,
      0.05233529955148697,
      0.017497366294264793,
      -0.055346112698316574,
      -0.029612286016345024,
      0.0391579270362854,
      -0.03238296881318092,
      0.020452231168746948,
      -0.05008750408887863,
      0.04719505086541176,
      0.03231870010495186,
      0.030283330008387566,
      -0.00819956324994564,
      -0.019011864438652992,
      0.012853359803557396,
      0.0008524524164386094,
      0.047250282019376755,
      0.003652402898296714,
      0.027622941881418228,
      0.015499782748520374,
      0.033035844564437866,
      0.029218090698122978,
      0.01585308648645878,
      -0.006831582169979811,
      -0.025293877348303795,
      -0.060521449893713,
      0.041954245418310165,
      -0.010903604328632355,
      -0.039302926510572433,
      0.018520116806030273,
      -0.07676306366920471,
      -0.030111491680145264,
      0.04132499545812607,
      0.048980288207530975,
      -0.011745189316570759,
      0.01802024431526661,
      -0.07926398515701294,
      0.07593835145235062,
      0.1127699688076973,
      -0.03708676993846893,
      0.08626890182495117,
      -0.011368518695235252,
      -0.01791355013847351,
      -0.009129689075052738,
      0.011613622307777405,
      0.003697956446558237,
      0.08299776911735535,
      -0.011207951232790947,
      0.05842970684170723,
      -0.00506651122123003,
      0.07454513758420944,
      -0.03205748647451401,
      0.06984861195087433,
      0.008207176811993122,
      -0.059338927268981934,
      -0.012984415516257286,
      0.03228011354804039,
      -0.07475420832633972,
      0.0032879470381885767,
      0.08705934137105942,
      0.010932598263025284,
      -0.016911884769797325,
      0.03200654685497284,
      -0.05124473571777344,
      0.024470683187246323,
      -0.02209886536002159,
      0.0529855452477932,
      -0.016980187967419624,
      0.03319203481078148,
      0.058341361582279205,
      -0.04411240667104721,
      0.021364763379096985,
      -0.07041221857070923,
      -0.005150426644831896,
      -0.02966129034757614,
      0.008586524985730648,
      0.057741232216358185,
      -0.04242139682173729,
      0.018737295642495155,
      -0.02358275093138218,
      0.00400881003588438,
      0.006606888957321644,
      -0.015715228393673897,
      0.06334034353494644,
      0.013347852975130081,
      0.017836537212133408,
      -0.004846533760428429,
      0.015355993993580341,
      0.09643539041280746,
      -0.03477366268634796,
      0.09428912401199341,
      0.018599236384034157,
      -0.029973311349749565,
      -0.023419752717018127,
      0.01568192057311535,
      -0.040985189378261566,
      -0.056655269116163254,
      0.02701598033308983,
      0.00017655205738265067,
      -0.01027996838092804,
      -0.01705176755785942,
      0.047987200319767,
      0.02128467708826065,
      0.055317990481853485,
      -0.008442152291536331,
      0.012613811530172825,
      0.05094106122851372,
      0.00501285819336772,
      0.028580257669091225,
      -0.0021996505092829466,
      -0.022706612944602966,
      -0.009797749109566212,
      -0.023579368367791176,
      -0.0024246100801974535,
      -0.0224456824362278,
      0.03313058242201805,
      0.02763700857758522,
      0.10573331266641617,
      -0.1091277003288269,
      -0.03605508431792259,
      -0.05360224470496178,
      -0.005424506962299347,
      -0.12333353608846664,
      0.03221164271235466,
      0.04718038812279701,
      -0.060513436794281006,
      0.07437305152416229,
      0.036343805491924286,
      0.054087020456790924,
      0.013604167848825455,
      -0.04405953735113144,
      0.0413542315363884,
      -0.00532164890319109,
      -0.0036927834153175354,
      0.0022517116740345955,
      0.009817065671086311,
      -0.013196825981140137,
      0.01244207564741373,
      -0.004436461254954338,
      -0.017931275069713593,
      -0.02896897867321968,
      0.013671815395355225,
      0.07112357020378113,
      0.007387211080640554,
      0.0034267553128302097,
      0.03379981219768524,
      0.054391030222177505,
      -0.004771458450704813,
      0.06767097860574722,
      -0.05493110418319702,
      -0.05001058802008629,
      0.0044446829706430435,
      0.03068728558719158,
      0.007366909645497799,
      -0.028550757095217705,
      -0.0007366124191321433,
      -0.0004993575857952237,
      -0.03560440242290497,
      0.010958858765661716,
      -0.025705937296152115,
      -0.0065389578230679035,
      -0.04541032388806343,
      -0.026941170915961266,
      0.001079014502465725,
      0.01009626779705286,
      0.058284174650907516,
      0.083546943962574,
      0.010306653566658497,
      0.06945798546075821,
      -0.04531890153884888,
      0.047103140503168106,
      -0.003034895984455943,
      0.028859497979283333,
      0.04207639768719673,
      -0.05781601741909981,
      0.10481557995080948,
      0.031590044498443604,
      0.021086372435092926,
      0.019042333588004112,
      -0.07841495424509048,
      0.031298499554395676,
      0.046369411051273346,
      0.012999722734093666,
      -0.020110713317990303,
      0.026131728664040565,
      0.05809388309717178,
      -0.011433467268943787,
      -0.0022278933320194483,
      -0.013722618110477924,
      -0.0844331756234169,
      0.04757031425833702,
      0.10104012489318848,
      0.023157866671681404,
      0.0424460768699646,
      0.05045851692557335,
      -0.07128078490495682,
      -0.051282115280628204,
      0.014829499647021294,
      0.040486227720975876,
      -0.006500823423266411,
      -0.007265585940331221,
      0.06847106665372849,
      0.08053774386644363,
      -0.011392082087695599,
      0.07863711565732956,
      -0.02949880249798298,
      -0.03126831725239754,
      0.014498012140393257,
      -0.06843304634094238,
      -0.05918457359075546,
      0.059896424412727356,
      -0.023171788081526756,
      -0.03619065880775452,
      -0.04167032241821289,
      -0.05088433250784874,
      0.027315888553857803,
      0.09451442211866379,
      -0.05631861463189125,
      -0.018339041620492935,
      -0.07362470030784607,
      0.00701089296489954,
      -0.003998619504272938,
      -0.06140854209661484,
      -0.015941552817821503,
      0.06387193500995636,
      0.05083179846405983,
      0.04241084307432175,
      -0.0178383719176054,
      -0.01230238750576973,
      -0.01507631316781044,
      0.014773214235901833,
      0.06213664263486862,
      0.021680418401956558,
      -0.05924902483820915,
      -0.0019292959477752447,
      -0.017963549122214317,
      0.04927941411733627,
      0.11179646104574203,
      -0.1170496866106987,
      -0.010700995102524757,
      0.020517796277999878,
      0.00376554555259645,
      0.022088242694735527,
      -0.06806385517120361,
      0.023857533931732178,
      -0.06003071740269661,
      0.028288766741752625,
      -0.05066841468214989,
      -0.017296642065048218,
      -0.026843558996915817,
      -0.009941834025084972,
      0.04249119758605957,
      0.049018871039152145,
      -0.05384679511189461,
      -0.08929596096277237,
      -0.001063104486092925,
      -0.08113119006156921,
      -0.015488604083657265,
      -0.047739285975694656,
      -0.0456998273730278,
      -0.004604472313076258,
      -0.0297323577105999,
      0.011286541819572449,
      -0.053251128643751144,
      0.07000468671321869,
      0.039486318826675415,
      0.027801601216197014,
      0.04360402002930641,
      0.04074463993310928,
      0.031342025846242905,
      0.06460364907979965,
      0.07141004502773285,
      -0.016150105744600296,
      0.049143772572278976,
      0.08533269912004471,
      -0.004994685761630535,
      -0.036430250853300095,
      -0.007853827439248562
    ],
    [
      -0.012193131260573864,
      0.015223074704408646,
      -0.0177475418895483,
      0.02259262651205063,
      0.02389604039490223,
      -0.03588889539241791,
      0.01736469753086567,
      -0.00677081011235714,
      -0.00580632034689188,
      -0.029879162088036537,
      0.06257458031177521,
      -0.03401529788970947,
      0.03793802484869957,
      -0.0035481404047459364,
      0.04217436909675598,
      -0.027640560641884804,
      0.018072588369250298,
      0.02217836305499077,
      0.013975649140775204,
      0.0825391411781311,
      0.045175738632678986,
      -0.013306166045367718,
      -0.03829062730073929,
      -0.052142348140478134,
      0.042653635144233704,
      -0.003236600896343589,
      -0.017039453610777855,
      0.0025408565998077393,
      -0.02130952663719654,
      -0.04250849410891533,
      0.05380244925618172,
      0.03134015575051308,
      -0.010111800394952297,
      -0.04922737181186676,
      0.000411573942983523,
      0.01165855210274458,
      0.03753945231437683,
      0.004156095907092094,
      -0.01220410130918026,
      0.04481494799256325,
      0.1930610090494156,
      -0.046212200075387955,
      -0.014788758009672165,
      -0.050908412784338,
      0.10178174078464508,
      0.004711076617240906,
      -0.03741662949323654,
      0.024491310119628906,
      -0.029710903763771057,
      -0.058655206114053726,
      0.04447922110557556,
      0.00361958472058177,
      -0.0071518924087285995,
      0.00767503073439002,
      0.07796689867973328,
      0.0496893934905529,
      -0.0690191239118576,
      -0.003754180856049061,
      -0.06008116900920868,
      0.03411354497075081,
      0.02839045785367489,
      -0.08392862230539322,
      -0.022138196974992752,
      0.014810509979724884,
      -0.09300732612609863,
      0.04253624379634857,
      -0.007583983242511749,
      0.029248761013150215,
      0.02285216934978962,
      0.03173103556036949,
      0.15943095088005066,
      -0.01951386034488678,
      0.006917545106261969,
      0.03177262097597122,
      -0.027065459638834,
      -0.03798936679959297,
      0.015020040795207024,
      0.04830462485551834,
      0.019434727728366852,
      0.03271324187517166,
      -0.08844531327486038,
      -0.0021044928580522537,
      0.021359283477067947,
      -0.018335215747356415,
      0.023266606032848358,
      -0.10791169106960297,
      -0.005412055645138025,
      0.026621650904417038,
      -0.026880085468292236,
      -0.06607086211442947,
      -0.008923093788325787,
      -0.04507514834403992,
      -0.006845450960099697,
      0.04619584232568741,
      0.040753111243247986,
      -0.03752896189689636,
      0.021242277696728706,
      -0.009460008703172207,
      0.01823035068809986,
      -0.0038337160367518663,
      0.07361879199743271,
      -0.022958971560001373,
      0.003758807899430394,
      -0.04250098019838333,
      -0.00045723828952759504,
      -0.006502334028482437,
      -0.07162119448184967,
      0.06774238497018814,
      -0.018274936825037003,
      0.02639368362724781,
      0.010373801924288273,
      -0.002162032527849078,
      -0.028741544112563133,
      0.01594599150121212,
      -0.10421635955572128,
      0.022733399644494057,
      0.02173948846757412,
      -0.044278476387262344,
      -0.02256428450345993,
      0.041580479592084885,
      0.0647066980600357,
      0.04224775731563568,
      -0.06938818842172623,
      -0.02263936772942543,
      0.047238539904356,
      -0.040652744472026825,
      -0.03485380858182907,
      -0.017118021845817566,
      0.06617478281259537,
      -0.005559961777180433,
      -0.031936295330524445,
      0.03750614449381828,
      -0.10778781026601791,
      -0.0024783124681562185,
      0.0683526024222374,
      0.007824927568435669,
      -0.01583028770983219,
      0.012837684713304043,
      -0.014788955450057983,
      0.035530850291252136,
      -0.0018246290273964405,
      -0.05965425446629524,
      -0.03296379745006561,
      -0.04181545972824097,
      0.015217832289636135,
      -0.06366841495037079,
      0.008391227573156357,
      -0.028348879888653755,
      0.053530655801296234,
      0.07509513944387436,
      -0.002171940403059125,
      -0.042164191603660583,
      0.06788506358861923,
      -0.02896994538605213,
      -0.039990946650505066,
      0.0302286297082901,
      0.01683376356959343,
      -0.01252571027725935,
      -0.07979597896337509,
      -0.03202858194708824,
      0.037770822644233704,
      0.04544784501194954,
      -0.03395198658108711,
      -0.0510590560734272,
      -0.02474760077893734,
      0.022779162973165512,
      0.04389414191246033,
      0.06312119960784912,
      0.03199588879942894,
      0.017603803426027298,
      0.05995596945285797,
      0.01521485298871994,
      -0.014747254550457,
      -0.03926596790552139,
      0.030596114695072174,
      0.046091530472040176,
      0.010633893311023712,
      -0.0863809660077095,
      0.043341245502233505,
      -0.03951948136091232,
      -0.03455037623643875,
      -0.025446198880672455,
      0.04417899623513222,
      -0.0330120287835598,
      -0.028248105198144913,
      -0.05344059690833092,
      0.037729617208242416,
      -0.0004697463009506464,
      0.044931910932064056,
      0.04817720875144005,
      -0.018153151497244835,
      -0.005878333933651447,
      0.03495118394494057,
      -0.019919855520129204,
      -0.017053203657269478,
      0.03887995332479477,
      0.037816207855939865,
      -0.028647948056459427,
      -0.05607585981488228,
      0.06335481256246567,
      -0.0009785423753783107,
      0.01457385253161192,
      -0.07166822999715805,
      -0.007503639906644821,
      -0.041239891201257706,
      0.007633961737155914,
      0.02402062900364399,
      -0.033082760870456696,
      0.004985736683011055,
      -0.0421394445002079,
      0.01231777761131525,
      0.004358002450317144,
      -0.024625208228826523,
      0.01732700690627098,
      -0.002453245921060443,
      -0.013699418865144253,
      -0.024204814806580544,
      0.0029880451038479805,
      -0.019875667989253998,
      -0.10371658205986023,
      0.020989902317523956,
      0.07320050895214081,
      -0.0352579690515995,
      -0.03962260112166405,
      -0.04241508990526199,
      -0.02103232592344284,
      0.0069639976136386395,
      0.06169365346431732,
      0.0009216114995069802,
      -0.005774907302111387,
      -0.025224773213267326,
      0.02669348008930683,
      -0.1376357525587082,
      -0.04903323948383331,
      -0.007773628458380699,
      -0.04941160976886749,
      0.04569576680660248,
      0.07188418507575989,
      -0.01836671307682991,
      0.020169083029031754,
      -0.043095432221889496,
      0.021103326231241226,
      0.06431114673614502,
      -0.019183991476893425,
      -0.03781585767865181,
      -0.005489480681717396,
      0.0658026859164238,
      -0.025216547772288322,
      -0.03860795497894287,
      0.0020891192834824324,
      0.026438308879733086,
      0.016632340848445892,
      -0.09252194315195084,
      0.010507261380553246,
      -0.03293546661734581,
      0.0016434829449281096,
      0.03807082027196884,
      0.04079451784491539,
      0.0031531292479485273,
      -0.037961285561323166,
      0.07556595653295517,
      0.014541015960276127,
      0.03393497318029404,
      -0.006785530596971512,
      0.004796234425157309,
      0.009590250439941883,
      0.023511195555329323,
      -0.0562104806303978,
      0.008411753922700882,
      0.07221655547618866,
      0.015967514365911484,
      -0.060369789600372314,
      0.029574645683169365,
      0.020190894603729248,
      -0.035830412060022354,
      0.020712338387966156,
      -0.09332422912120819,
      0.05667572841048241,
      -0.03136260062456131,
      0.042717061936855316,
      -0.0016837434377521276,
      0.080829918384552,
      -0.004341186489909887,
      -0.0018520168960094452,
      0.024139942601323128,
      -0.019440967589616776,
      -0.001310504972934723,
      -0.06631669402122498,
      -0.08469223231077194,
      -0.018609974533319473,
      -0.039770595729351044,
      -0.0023725407663732767,
      -0.020432237535715103,
      0.008451593108475208,
      -0.03974466770887375,
      0.047124672681093216,
      0.020457429811358452,
      -0.055312320590019226,
      0.0012892528902739286,
      0.04425189644098282,
      0.004494810476899147,
      -0.031066711992025375,
      0.049583420157432556,
      0.0008514748187735677,
      -0.02064673788845539,
      0.07027407735586166,
      -0.018786028027534485,
      0.013881892897188663,
      0.014606424607336521,
      0.05372878536581993,
      0.02514200285077095,
      -0.01104521844536066,
      0.06211122125387192,
      0.03605816885828972,
      0.07730957865715027,
      0.011378253810107708,
      -0.033281754702329636,
      -0.004664826672524214,
      -0.012759852223098278,
      -0.004633430857211351,
      -0.042808301746845245,
      0.014761091209948063,
      -0.00757821137085557,
      0.009205220267176628,
      0.08118133991956711,
      0.004598896484822035,
      -0.03722256422042847,
      0.010177780874073505,
      -0.007243868429213762,
      -0.019209934398531914,
      -0.014469137415289879,
      -0.017049605026841164,
      0.004311701748520136,
      0.0031155049800872803,
      0.007890376262366772,
      -0.05489850416779518,
      -0.01717485301196575,
      -0.017683792859315872,
      0.0430707223713398,
      -0.059178829193115234,
      -0.05364790931344032,
      -0.011717717163264751,
      -0.09867542237043381,
      0.02921953797340393,
      0.09854383766651154,
      0.07617000490427017,
      0.0035910748410969973,
      -0.06206295266747475,
      -0.0035002618096768856,
      -0.076313316822052,
      0.02364068292081356,
      -0.013075209222733974,
      0.05568147823214531,
      -0.027163401246070862,
      -0.053183335810899734,
      -0.02315085381269455,
      -0.0058359489776194096,
      -0.031677864491939545,
      -0.06281868368387222,
      0.015873204916715622,
      0.01072600670158863,
      -0.0304720476269722,
      0.029508251696825027,
      -0.012407477013766766,
      -0.030756302177906036,
      0.05008871108293533,
      0.040253039449453354,
      0.03033108077943325,
      0.051602642983198166,
      0.015016109682619572,
      -0.00010548115824349225,
      -0.07425766438245773,
      0.008611459285020828,
      0.020396823063492775,
      -0.037923842668533325,
      0.009932114742696285,
      -0.008843261748552322,
      0.037679728120565414,
      -0.08284294605255127,
      0.00781696755439043,
      0.0006664862739853561,
      0.0032115995418280363,
      -0.0598970465362072,
      0.04807998239994049,
      -0.036280062049627304,
      0.03821064159274101,
      0.06109194830060005,
      -0.03955966606736183,
      -0.0989149659872055,
      0.11364568024873734,
      0.019599946215748787,
      0.018567536026239395,
      0.002668240340426564,
      0.021420108154416084,
      -0.03631697967648506,
      0.028806328773498535,
      -0.11742221564054489,
      -0.0007891561836004257,
      -0.00029274963890202343,
      -0.0776992067694664,
      0.05355335399508476,
      0.03479866310954094,
      -0.02685960941016674,
      -0.029214175418019295,
      0.00628631329163909,
      0.01560299750417471,
      0.049865007400512695,
      0.039401546120643616,
      -0.01502225548028946,
      -0.020374197512865067,
      0.015232968144118786,
      0.02611381933093071,
      -0.03504638001322746,
      0.011455925181508064,
      -0.024083834141492844,
      -0.07307309657335281,
      0.02582499384880066,
      -0.010561090894043446,
      -0.0072295512072741985,
      0.0009510756353847682,
      0.016677021980285645,
      0.010172901675105095,
      -0.019527794793248177,
      0.029054177924990654,
      -0.01883593201637268,
      -0.02026115544140339,
      -0.04135008156299591,
      -0.02903609350323677,
      -0.04524272680282593,
      0.0070677706971764565,
      0.03569536283612251,
      -0.06153590604662895,
      -0.07433079928159714,
      -0.058868926018476486,
      -0.031218351796269417,
      0.009584836661815643,
      0.02925254963338375,
      -0.026998665183782578,
      0.04964503273367882,
      0.04300342872738838,
      0.09600066393613815,
      0.0074837468564510345,
      -0.04227320849895477,
      -0.0008238654700107872,
      -0.04036201536655426,
      0.09499544650316238,
      0.0234511885792017,
      0.03798979893326759,
      0.020126178860664368,
      -0.05132292956113815,
      0.030056050047278404,
      -0.03985964506864548,
      0.06366558372974396,
      0.041952185332775116,
      0.04723124951124191,
      -0.04478216543793678,
      -0.007941130548715591,
      0.03659769892692566,
      -0.04039506986737251,
      -0.004267928656190634,
      -0.010843925178050995,
      0.02366834133863449,
      -0.020596366375684738,
      0.006642439402639866,
      0.02641758695244789,
      0.08531377464532852,
      -0.015816587954759598,
      0.010297317057847977,
      -0.03962268680334091,
      -0.05901937931776047,
      0.00907350517809391,
      0.05150093510746956,
      0.006145749241113663,
      0.03253555670380592,
      -0.011671439744532108,
      0.042332448065280914,
      0.07325379550457001,
      0.032000795006752014,
      -0.01590479351580143,
      0.02677159383893013,
      -0.01924917846918106,
      0.10423426330089569,
      -0.06522949784994125,
      -0.09369796514511108,
      0.04234711080789566,
      -0.04102962091565132,
      0.02036621980369091,
      -0.03226969391107559,
      -0.010133341886103153,
      -0.055282752960920334,
      0.07532026618719101,
      -0.038551606237888336,
      0.03699227795004845,
      0.048494793474674225,
      -0.016655568033456802,
      0.04497779533267021,
      -0.01974589191377163,
      0.03156372159719467,
      0.035550620406866074,
      -0.005136116873472929,
      -0.10681711137294769,
      0.04822293296456337,
      -0.010287481360137463
    ],
    [
      -0.0032985599245876074,
      -0.10661478340625763,
      0.03294696286320686,
      0.057332877069711685,
      0.004021105356514454,
      0.025548787787556648,
      0.007326752878725529,
      0.07682237029075623,
      -0.019766753539443016,
      0.01856369897723198,
      -0.08812135457992554,
      0.0026457402855157852,
      -0.001579104457050562,
      -0.017367513850331306,
      0.047092899680137634,
      0.04219956696033478,
      0.11177054047584534,
      -0.08975651860237122,
      -0.012351978570222855,
      -0.09174027293920517,
      0.03148183599114418,
      0.04292381554841995,
      -0.02501045912504196,
      0.0084621487185359,
      0.056390054523944855,
      0.007213243283331394,
      -0.07509531080722809,
      -0.02352255769073963,
      0.003955141641199589,
      -0.030849328264594078,
      -0.024893933907151222,
      -0.03843187540769577,
      -0.03427452594041824,
      0.044042203575372696,
      0.02908969111740589,
      0.028259146958589554,
      0.004642716143280268,
      0.005521492101252079,
      -0.011896991170942783,
      0.025512926280498505,
      -0.02975841611623764,
      0.050905950367450714,
      -0.026687348261475563,
      -0.0481463223695755,
      0.033329080790281296,
      -0.03850407153367996,
      -0.05405624583363533,
      0.07292339205741882,
      0.036247335374355316,
      0.020484739914536476,
      -0.019905151799321175,
      -0.0078489501029253,
      0.012476897798478603,
      0.043056096881628036,
      0.040275938808918,
      0.021830134093761444,
      0.11435293406248093,
      -0.004361044615507126,
      -0.04526238143444061,
      0.026453673839569092,
      0.04903033748269081,
      -0.025961894541978836,
      0.03541763499379158,
      -0.02788180112838745,
      0.03571769967675209,
      0.005587082821875811,
      0.00959331076592207,
      -0.04123460501432419,
      -0.018228214234113693,
      -0.06148999556899071,
      0.005469393916428089,
      0.06059221923351288,
      0.059375911951065063,
      0.011506524868309498,
      -0.062138427048921585,
      0.013788716867566109,
      0.017095956951379776,
      0.028052549809217453,
      1.754775075824e-05,
      0.06994582712650299,
      -0.0895601212978363,
      0.011092490516602993,
      0.024914048612117767,
      0.04350821673870087,
      0.03843531012535095,
      -0.015132441185414791,
      0.0005005839630030096,
      -0.030747683718800545,
      -0.011635425500571728,
      -0.03328454867005348,
      0.015090913511812687,
      0.009423917159438133,
      -0.0020462358370423317,
      -0.0974067896604538,
      0.06447914242744446,
      -0.006035532336682081,
      0.03802935406565666,
      1.993332443817053e-05,
      -0.02281143330037594,
      -0.07078367471694946,
      0.007390741258859634,
      0.045203179121017456,
      0.10565926134586334,
      -0.0015403517754748464,
      -0.011336996220052242,
      -0.028757603839039803,
      -0.025756724178791046,
      -0.0008770797285251319,
      -0.0033820769749581814,
      0.03593374043703079,
      0.03238103166222572,
      0.049805935472249985,
      0.048700716346502304,
      -0.02835189923644066,
      0.033797893673181534,
      0.01643948256969452,
      0.04009099677205086,
      -0.030629834160208702,
      -0.05287956818938255,
      -0.022751204669475555,
      -0.0306149423122406,
      -0.03258560970425606,
      0.039366465061903,
      -0.012823153287172318,
      0.0422617569565773,
      -0.010518648661673069,
      0.016072580590844154,
      0.1042746901512146,
      0.04377713426947594,
      -0.053213875740766525,
      0.0052826786413788795,
      0.052621737122535706,
      0.036856405436992645,
      -0.001194309676066041,
      0.028161654248833656,
      -0.06079346314072609,
      -0.05842609703540802,
      0.009343072772026062,
      -0.03724043071269989,
      -0.019083207473158836,
      -0.029527131468057632,
      0.029444865882396698,
      -0.0006521266768686473,
      -0.06817112118005753,
      -0.009108362719416618,
      -0.04594260826706886,
      -0.014150910079479218,
      0.019201746210455894,
      -0.035903509706258774,
      0.05474509671330452,
      0.00797923095524311,
      0.004243261646479368,
      0.009128384292125702,
      0.022781528532505035,
      -0.0012959769228473306,
      -0.07258977741003036,
      -0.023012446239590645,
      0.04816028103232384,
      0.04892897978425026,
      0.03170880675315857,
      0.006630353629589081,
      0.05152175948023796,
      -0.05443207174539566,
      -0.006378384307026863,
      0.020545998588204384,
      -0.12491896748542786,
      -0.011693896725773811,
      0.08251406252384186,
      0.05956387147307396,
      -0.014780890196561813,
      0.006760872900485992,
      0.027016475796699524,
      -0.005488929804414511,
      -0.04252828285098076,
      -0.0004051517753396183,
      0.05992919206619263,
      -0.042580652981996536,
      -0.04435243830084801,
      0.027374785393476486,
      0.013353590853512287,
      -0.015474861487746239,
      0.031681835651397705,
      -0.0011024035047739744,
      -0.019872404634952545,
      -0.02986672893166542,
      -0.020616991445422173,
      0.03741214796900749,
      -0.015810783952474594,
      -0.013970956206321716,
      -0.10288119316101074,
      -0.034351006150245667,
      0.011291456408798695,
      -0.014080455526709557,
      0.008557676337659359,
      -0.04340196028351784,
      0.070501409471035,
      -0.02770972065627575,
      -0.022715825587511063,
      0.018549473956227303,
      0.044570256024599075,
      -0.0005760082276538014,
      -0.0723482221364975,
      0.04054694622755051,
      0.10086130350828171,
      -0.017612550407648087,
      -0.0032329626847058535,
      0.0017284000059589744,
      0.05176657438278198,
      -0.00540788471698761,
      -0.08061088621616364,
      0.06153460592031479,
      -0.0446295402944088,
      0.0750294029712677,
      0.004989113658666611,
      -0.018210723996162415,
      -0.08585888147354126,
      -0.032344840466976166,
      -0.04441304877400398,
      0.0588981918990612,
      0.033676110208034515,
      0.010563874617218971,
      0.017350971698760986,
      -0.04460883513092995,
      -0.0721503347158432,
      0.021653108298778534,
      -0.04628321900963783,
      -0.005683043505996466,
      0.04641398414969444,
      -0.03134878724813461,
      0.04771189019083977,
      -0.007551505230367184,
      0.02723911963403225,
      0.01310046948492527,
      -0.05145379155874252,
      -0.03770023584365845,
      0.047968629747629166,
      -0.0567183643579483,
      0.008963197469711304,
      -0.0854978933930397,
      0.07263626158237457,
      -0.006975230760872364,
      -0.05036378279328346,
      0.037177059799432755,
      0.009706910699605942,
      0.10703770071268082,
      -0.012048216536641121,
      0.05863111838698387,
      -0.006173441186547279,
      -0.008633703924715519,
      0.038264743983745575,
      0.017987936735153198,
      0.03431910648941994,
      0.054007746279239655,
      0.017887134104967117,
      -0.014110986143350601,
      0.015425624325871468,
      0.021630944684147835,
      0.025265155360102654,
      -0.02900286763906479,
      0.009050465188920498,
      -0.023805156350135803,
      0.057296354323625565,
      0.05899960175156593,
      -0.013488995842635632,
      0.009055410511791706,
      -0.017289556562900543,
      -0.008487042970955372,
      0.006445467937737703,
      0.005889652296900749,
      0.05181865021586418,
      0.046749215573072433,
      0.02902131713926792,
      -0.06541728228330612,
      0.0330924391746521,
      0.010801335796713829,
      -0.009164744056761265,
      0.013602032326161861,
      0.016583913937211037,
      0.0353175550699234,
      0.01178800780326128,
      -0.005796304903924465,
      0.046546660363674164,
      -0.059990912675857544,
      0.01082091499119997,
      0.01414953451603651,
      0.02413628250360489,
      -0.05779626592993736,
      0.0001329843798885122,
      0.01458276528865099,
      -0.05257763713598251,
      0.05114070698618889,
      0.14481061697006226,
      -0.0046525513753294945,
      0.03474027290940285,
      0.04390539601445198,
      0.0042548333294689655,
      -0.019690016284585,
      -0.07381755858659744,
      0.03640017285943031,
      0.03764664754271507,
      0.02455952577292919,
      0.03680271655321121,
      -0.04826769605278969,
      -0.028121113777160645,
      0.019106365740299225,
      0.012010998092591763,
      -0.031968556344509125,
      -0.035949189215898514,
      -0.020284313708543777,
      0.0029142654966562986,
      -0.0698307529091835,
      -0.045232921838760376,
      -0.055473580956459045,
      0.028370441868901253,
      -0.007466497365385294,
      0.03551825135946274,
      -0.01386992447078228,
      -0.007929669693112373,
      -0.047447968274354935,
      0.01125708594918251,
      0.004556476604193449,
      -0.060591258108615875,
      0.06916845589876175,
      -0.013114150613546371,
      -0.033727142959833145,
      -0.01872066967189312,
      -0.0793348178267479,
      -0.05922970175743103,
      0.02191523090004921,
      -0.006965349894016981,
      -0.048330653458833694,
      -0.04202978312969208,
      -0.019000058993697166,
      0.066716767847538,
      0.014739762991666794,
      0.04183824360370636,
      -0.010478449985384941,
      0.027333395555615425,
      -0.023996291682124138,
      -0.024213256314396858,
      0.024573886767029762,
      -0.02850302681326866,
      -0.08836417645215988,
      0.012219139374792576,
      0.034342341125011444,
      0.009003443643450737,
      -0.02390311099588871,
      0.008007616735994816,
      0.011459389701485634,
      -0.0008444665581919253,
      0.020099947229027748,
      0.019508643075823784,
      0.01032585185021162,
      -0.007497851736843586,
      0.022278444841504097,
      -0.03326893597841263,
      -0.02470037341117859,
      -0.013670889660716057,
      -0.013222934678196907,
      0.007372560445219278,
      -0.029324309900403023,
      0.08255279809236526,
      -0.018832199275493622,
      -0.07357650250196457,
      0.10382885485887527,
      0.04103263467550278,
      -0.08130165189504623,
      -0.04508427903056145,
      0.03180183842778206,
      0.06466298550367355,
      0.04811682179570198,
      0.01527690701186657,
      -0.009737232699990273,
      -0.03616580739617348,
      -0.019002599641680717,
      -0.02131039835512638,
      0.008281498216092587,
      0.07795023173093796,
      0.037926990538835526,
      -0.07348734140396118,
      -0.07305997610092163,
      0.02523769624531269,
      -0.007426481693983078,
      0.030184738337993622,
      -0.014854161068797112,
      0.050826843827962875,
      0.02781243994832039,
      -0.0007863461505621672,
      0.008220717310905457,
      -0.02390088140964508,
      -0.043116889894008636,
      -0.02281087078154087,
      -0.031593840569257736,
      -0.07060848921537399,
      -0.09740229696035385,
      0.08321654051542282,
      0.020661918446421623,
      -0.02335999719798565,
      0.018614264205098152,
      0.011099749244749546,
      -0.015972908586263657,
      -0.022920001298189163,
      -0.10283768177032471,
      0.05692018195986748,
      -0.05576690658926964,
      0.037885382771492004,
      0.04886912181973457,
      -0.047629259526729584,
      0.00323464535176754,
      0.00993858277797699,
      -0.08658476173877716,
      0.016006074845790863,
      -0.0007292755180969834,
      0.10145943611860275,
      -0.026736561208963394,
      0.040801454335451126,
      -0.0032662118319422007,
      -0.02646264061331749,
      0.05471082776784897,
      0.01766325533390045,
      -0.11124851554632187,
      0.007449881639331579,
      -0.024932395666837692,
      -0.05414725840091705,
      0.019102195277810097,
      -0.05791608616709709,
      0.027476944029331207,
      0.0008347409311681986,
      -0.0020389119163155556,
      0.03623691201210022,
      0.0027041553985327482,
      0.07325222343206406,
      -0.04299893230199814,
      0.0009520548628643155,
      -0.055574528872966766,
      0.02900592051446438,
      0.06978509575128555,
      -0.0056344494223594666,
      0.02425229549407959,
      -0.0224736500531435,
      -0.03516053408384323,
      -0.034328997135162354,
      -0.09430099278688431,
      0.007264849729835987,
      -0.011241503059864044,
      0.05681643635034561,
      0.033452171832323074,
      -0.016511479392647743,
      0.0003698384971357882,
      0.0976012572646141,
      0.013122735545039177,
      0.04772550240159035,
      0.01836681365966797,
      -0.005275247152894735,
      -0.014553409069776535,
      0.017676491290330887,
      -0.07644873112440109,
      -0.019295718520879745,
      0.0021313405595719814,
      0.00605600792914629,
      -0.012038066051900387,
      0.026190396398305893,
      -0.06694700568914413,
      -0.02523825690150261,
      0.014108256436884403,
      0.027415543794631958,
      0.012026210315525532,
      0.017645644024014473,
      -0.03843202069401741,
      0.04533660039305687,
      0.028271451592445374,
      0.04644458368420601,
      0.07576730102300644,
      0.0005501018604263663,
      0.010397412814199924,
      -0.07461588084697723,
      0.018567541614174843,
      -0.05611826479434967,
      -0.019228795543313026,
      0.03264600783586502,
      0.01391715370118618,
      0.01766817271709442,
      -0.03612372279167175,
      -0.056232012808322906,
      -0.06157621368765831,
      0.033785380423069,
      -0.04683113098144531,
      -0.05763687565922737,
      -0.027334541082382202,
      0.00990802887827158,
      -0.005972710438072681,
      0.01796073280274868,
      -0.02127496339380741,
      0.04517383128404617,
      -0.047523096203804016,
      0.04139503464102745,
      -0.008924503810703754,
      0.03663146495819092,
      -0.029762275516986847,
      -0.06824387609958649,
      0.0007817543810233474,
      0.03822283819317818,
      0.029332652688026428
    ],
    [
      0.04641464725136757,
      -0.09332170337438583,
      0.019623415544629097,
      -0.010798055678606033,
      0.012391513213515282,
      0.056912440806627274,
      0.015661999583244324,
      -0.026853956282138824,
      -0.10084641724824905,
      0.02373019978404045,
      -0.04455023258924484,
      0.05431021749973297,
      0.014979266561567783,
      -0.006299927365034819,
      0.056129373610019684,
      -0.0653461143374443,
      0.04547500237822533,
      -0.017258988693356514,
      0.01854737289249897,
      -0.09534960240125656,
      0.07131555676460266,
      0.059814486652612686,
      -0.08229846507310867,
      -0.0026032987516373396,
      0.039481110870838165,
      -0.06765781342983246,
      -0.025351371616125107,
      0.004166303668171167,
      0.044450048357248306,
      0.04805765673518181,
      -0.06997083872556686,
      -0.018753156065940857,
      -0.06447502225637436,
      -0.015661032870411873,
      0.02012450248003006,
      -0.0542144738137722,
      0.007508997805416584,
      0.04437572881579399,
      0.06684839725494385,
      -0.033647481352090836,
      -0.0637659952044487,
      -0.0774168074131012,
      -0.01139059942215681,
      0.08517526835203171,
      0.049070388078689575,
      0.024942994117736816,
      -0.03472127020359039,
      -0.03187941014766693,
      -0.010352499783039093,
      0.0028910785913467407,
      -0.03524976596236229,
      0.067070871591568,
      0.09131979197263718,
      -0.020314568653702736,
      -0.0728580430150032,
      -0.05526519939303398,
      0.0342438630759716,
      -0.0029024265240877867,
      0.03208469599485397,
      -0.056530117988586426,
      -0.0523727610707283,
      -0.021081101149320602,
      0.0059129102155566216,
      -0.02649047039449215,
      0.021143393591046333,
      -0.004342926666140556,
      -0.003429881762713194,
      -0.030426060780882835,
      0.03433316946029663,
      -0.03969956561923027,
      0.004874613136053085,
      -0.03308027610182762,
      -0.0016769617795944214,
      -0.0609402060508728,
      -0.0516769103705883,
      -0.053946126252412796,
      -0.0018113659461960196,
      0.00021363748237490654,
      -0.025899676606059074,
      0.033844348043203354,
      -0.017923610284924507,
      0.055635444819927216,
      -0.042664822190999985,
      0.053157832473516464,
      -0.03424587845802307,
      -0.02048783376812935,
      -0.019415386021137238,
      0.007744581438601017,
      -0.05217382684350014,
      0.04492949694395065,
      -0.09265337884426117,
      -0.022218620404601097,
      -0.020633108913898468,
      -0.06695856153964996,
      0.03892771527171135,
      0.13690993189811707,
      -0.07468323409557343,
      -0.05195731297135353,
      -0.006098857615143061,
      0.06692282855510712,
      -0.03425511345267296,
      -0.055891841650009155,
      -0.024333585053682327,
      -0.007829723879694939,
      0.08128412067890167,
      -0.053590625524520874,
      -0.04682932049036026,
      0.07723016291856766,
      0.06435110419988632,
      -0.04602251946926117,
      -0.02503341995179653,
      -0.0312875397503376,
      0.04537549614906311,
      -0.018256554380059242,
      -0.030469287186861038,
      -0.10483113676309586,
      0.026116136461496353,
      -0.06776894629001617,
      0.045423924922943115,
      0.019424425438046455,
      0.022800756618380547,
      0.051576968282461166,
      -0.04382266476750374,
      -0.03637775033712387,
      0.07858443260192871,
      0.029992619529366493,
      -0.06682442873716354,
      0.05195121839642525,
      -0.032261524349451065,
      -0.0072425371035933495,
      0.05318077653646469,
      0.08084887266159058,
      0.025366537272930145,
      -0.080414317548275,
      -0.02467758022248745,
      0.09534168243408203,
      -0.0036042435094714165,
      -0.04707052931189537,
      0.026193344965577126,
      -0.027932917699217796,
      -0.03183120861649513,
      -0.011420175433158875,
      -0.013916565105319023,
      0.059136517345905304,
      -0.027641089633107185,
      -0.050904739648103714,
      -0.0015723711112514138,
      0.04370136931538582,
      0.05172732099890709,
      0.07113716751337051,
      -0.01215006411075592,
      0.055911194533109665,
      0.05731256678700447,
      -0.0012804764555767179,
      -0.01351850014179945,
      -0.022024840116500854,
      -0.04849560931324959,
      0.04292045906186104,
      -0.015676874667406082,
      0.024500500410795212,
      -0.02842109650373459,
      -0.04166699945926666,
      -0.039477892220020294,
      0.0015355778159573674,
      0.029394574463367462,
      -0.04677259176969528,
      0.02690730430185795,
      -0.05903257057070732,
      -0.018018893897533417,
      0.05128156766295433,
      0.0917082205414772,
      0.04029419273138046,
      -0.13337868452072144,
      0.02934684418141842,
      0.0995432659983635,
      0.04594581946730614,
      0.04031839966773987,
      -0.06131984665989876,
      -0.024070771411061287,
      0.01001586951315403,
      0.027948319911956787,
      -0.02588936686515808,
      -0.002839263528585434,
      0.0241053756326437,
      -0.004861421417444944,
      -0.07953379303216934,
      0.019790204241871834,
      -0.04387757554650307,
      -0.053028807044029236,
      -0.02689409628510475,
      -0.0525350496172905,
      -0.0007498650229535997,
      0.04386431351304054,
      -0.0871879830956459,
      -0.03163923695683479,
      0.07041484862565994,
      0.017553837969899178,
      0.015090981498360634,
      0.07853666692972183,
      -0.009058148600161076,
      -0.023983033373951912,
      -0.010745557956397533,
      0.014189108274877071,
      0.07766543328762054,
      0.02672448195517063,
      0.04459560289978981,
      -0.0632825642824173,
      0.09627918154001236,
      -0.005385351367294788,
      -0.02168610692024231,
      -0.04450813680887222,
      -0.021857338026165962,
      0.02144833840429783,
      0.03344617411494255,
      0.013255877420306206,
      -0.08807658404111862,
      -0.03688252717256546,
      -0.02200137823820114,
      -0.053921155631542206,
      -0.019428521394729614,
      -0.0461360327899456,
      0.057813722640275955,
      0.0015460583381354809,
      -0.055498600006103516,
      -0.08731266111135483,
      0.009363551624119282,
      0.054524168372154236,
      0.06244850158691406,
      -0.017496494576334953,
      -0.00518282363191247,
      -0.016980627551674843,
      0.03205185383558273,
      -0.01270578894764185,
      -0.03175574913620949,
      -0.024330636486411095,
      0.020730921998620033,
      -0.049120545387268066,
      -0.0027435177471488714,
      -0.04321673884987831,
      0.0703045204281807,
      0.009725059382617474,
      -0.003106526331976056,
      -0.001572502893395722,
      0.00979685876518488,
      0.03301393613219261,
      -0.013653685338795185,
      -0.004080608487129211,
      0.03829711675643921,
      -0.08577315509319305,
      -0.02671450562775135,
      0.0267289187759161,
      -0.03379029035568237,
      0.014351926743984222,
      -0.00945241842418909,
      0.06547895818948746,
      -0.034128881990909576,
      0.004078837111592293,
      0.028434719890356064,
      -0.049037184566259384,
      0.003132184734568,
      0.00484383013099432,
      -0.028642162680625916,
      -0.04732942581176758,
      -0.08897313475608826,
      0.04810846224427223,
      -0.01465707365423441,
      0.051181256771087646,
      0.004366887267678976,
      -0.062105391174554825,
      0.01421427447348833,
      0.00885156448930502,
      0.03313760831952095,
      0.007483446039259434,
      -0.021178219467401505,
      -0.06768395751714706,
      0.013050810433924198,
      0.12786102294921875,
      -0.007032135035842657,
      -0.09114635735750198,
      -0.13426841795444489,
      -0.06644562631845474,
      0.03841736912727356,
      -0.07518462836742401,
      -0.001955545274540782,
      -0.008368412032723427,
      0.03649778664112091,
      0.004724185448139906,
      0.059945497661828995,
      0.058546282351017,
      0.11569269001483917,
      0.06247849389910698,
      0.04129859060049057,
      -0.014890240505337715,
      -0.03336096182465553,
      0.06368916481733322,
      -0.012027887627482414,
      -0.04553436487913132,
      -0.09398209303617477,
      0.03672271966934204,
      0.04096058011054993,
      -0.03027438186109066,
      -0.01906260848045349,
      0.040862925350666046,
      0.003084672149270773,
      0.05142487958073616,
      -0.02129739336669445,
      -0.06258226931095123,
      -0.044081442058086395,
      -0.011849467642605305,
      0.06564968824386597,
      0.05277916043996811,
      -0.0175524540245533,
      0.022442461922764778,
      -0.0018910174258053303,
      -0.02936309203505516,
      0.08508048951625824,
      0.0531204491853714,
      -0.07463965564966202,
      0.03433967009186745,
      -0.02278921939432621,
      0.03316856920719147,
      -0.04734000563621521,
      -0.007437382359057665,
      -0.057923853397369385,
      -0.010429533198475838,
      0.04025137424468994,
      -0.016153503209352493,
      0.016491269692778587,
      0.03398626297712326,
      0.012891090475022793,
      0.06313060969114304,
      0.05952989682555199,
      -0.007364904507994652,
      -0.011383933015167713,
      0.01608843356370926,
      0.040902864187955856,
      -0.0016862343763932586,
      -0.05301753431558609,
      -0.041321445256471634,
      0.014368746429681778,
      0.0773065835237503,
      -0.035827670246362686,
      -0.06336429715156555,
      0.01769418455660343,
      0.026227569207549095,
      0.08824379742145538,
      -0.05482500046491623,
      0.022476615384221077,
      -0.040023405104875565,
      -0.04682943969964981,
      0.007894442416727543,
      0.044171787798404694,
      -0.014143543317914009,
      -0.044279035180807114,
      0.04293128848075867,
      -0.011906392872333527,
      -0.03211401030421257,
      0.011453516781330109,
      -0.014199540950357914,
      -0.10339879244565964,
      0.0008921737899072468,
      0.01227480173110962,
      -0.04605158418416977,
      0.0776318609714508,
      0.05828898400068283,
      0.03930717334151268,
      0.07392290234565735,
      -0.029417868703603745,
      -0.043232470750808716,
      -0.09679196029901505,
      0.055167630314826965,
      -0.07119999080896378,
      -0.08315273374319077,
      0.0027377153746783733,
      -0.03564486652612686,
      -0.036590393632650375,
      -0.10932139307260513,
      0.03108474798500538,
      -0.019550248980522156,
      -0.05049923434853554,
      -0.0006489646038971841,
      0.020221352577209473,
      0.02345985546708107,
      -0.042568814009428024,
      -0.010461813770234585,
      0.02458292245864868,
      0.00856266263872385,
      -0.06035824865102768,
      0.02423964999616146,
      0.041715171188116074,
      0.0008135703974403441,
      0.01970144920051098,
      -0.008956803008913994,
      -0.09866462647914886,
      -0.007834258489310741,
      0.05522897467017174,
      0.053133197128772736,
      0.013196977786719799,
      -0.042728010565042496,
      -0.009582582861185074,
      0.010913091711699963,
      0.011644375510513783,
      0.07929375022649765,
      0.024821938946843147,
      -0.026568643748760223,
      0.0236833393573761,
      0.04452897980809212,
      -0.06534790992736816,
      -0.05481623858213425,
      -0.0029579612892121077,
      0.037344738841056824,
      -0.04368511587381363,
      -0.01970078982412815,
      -0.032189056277275085,
      -0.02060597389936447,
      0.008201094344258308,
      0.037581849843263626,
      -0.007085494697093964,
      0.0018751926254481077,
      -0.08043251931667328,
      0.0074910968542099,
      -0.0741397961974144,
      -0.0316057987511158,
      0.04118390753865242,
      0.007450090255588293,
      -0.00499937916174531,
      -0.030746759846806526,
      0.042671915143728256,
      0.005386097356677055,
      0.005583824589848518,
      -0.0381331630051136,
      0.002538145985454321,
      -0.05335398018360138,
      0.03465671092271805,
      -0.04123130068182945,
      -0.029036156833171844,
      0.046442002058029175,
      -0.003081041621044278,
      -0.014078618958592415,
      -0.05668465793132782,
      -0.009542310610413551,
      0.0001977772481041029,
      0.022590702399611473,
      0.05460657551884651,
      0.010563415475189686,
      0.044037703424692154,
      0.021414659917354584,
      0.05231635645031929,
      -0.07705709338188171,
      0.045358531177043915,
      0.004087632056325674,
      -0.09236844629049301,
      -0.03500618413090706,
      0.06121078133583069,
      -0.04540935903787613,
      0.03276687115430832,
      0.036973416805267334,
      -0.013229073956608772,
      -0.13698671758174896,
      0.0484686940908432,
      -0.04464089870452881,
      0.03141315281391144,
      0.1119508370757103,
      0.04590720683336258,
      -0.05168105289340019,
      0.06554087996482849,
      0.027256811037659645,
      0.02144273743033409,
      0.04965348541736603,
      -0.011409100145101547,
      -0.043979842215776443,
      0.0776144340634346,
      0.057413797825574875,
      -0.042478807270526886,
      0.0655985102057457,
      -0.014534003101289272,
      0.0002412376634310931,
      0.04244451969861984,
      0.013125392608344555,
      0.013567226938903332,
      -0.0149654196575284,
      0.04621528461575508,
      -0.02396494522690773,
      -0.02106446400284767,
      -0.0360693484544754,
      0.013180401176214218,
      -0.0253202635794878,
      -0.05392196774482727,
      0.051306355744600296,
      0.009673860855400562,
      -0.009521826170384884,
      -0.014446215704083443,
      -0.019982561469078064,
      0.01239250972867012,
      -0.0448538139462471,
      -0.0035524701233953238,
      0.029444890096783638,
      0.030045807361602783,
      0.05953387916088104,
      0.14864736795425415,
      -0.056301701813936234,
      0.019551126286387444,
      0.010776963084936142
    ],
    [
      -0.033582478761672974,
      -0.0058248513378202915,
      -0.01977427676320076,
      0.016997946426272392,
      -0.042011044919490814,
      0.04279620945453644,
      -0.04008886218070984,
      -0.04574992507696152,
      -0.04217173159122467,
      -0.001359558547846973,
      -0.03872723877429962,
      -0.031162936240434647,
      0.005132494028657675,
      0.07491441816091537,
      -0.07204829156398773,
      0.015599831007421017,
      0.03680581599473953,
      -0.046086352318525314,
      -0.06009026616811752,
      0.01086309365928173,
      0.04864000156521797,
      -0.011030154302716255,
      -0.031127171590924263,
      -0.023267365992069244,
      0.03663435950875282,
      0.016551494598388672,
      -0.0031188821885734797,
      0.0162365660071373,
      -0.0018341911491006613,
      0.016341082751750946,
      0.01739814318716526,
      -0.023225242272019386,
      0.05349867045879364,
      0.049742285162210464,
      -0.08090100437402725,
      -0.019351426512002945,
      -0.023814331740140915,
      -0.05960478261113167,
      0.07270573824644089,
      0.027739521116018295,
      -0.011498088017106056,
      0.03628973290324211,
      -0.093614362180233,
      -0.09540224075317383,
      -0.019128343090415,
      0.010719956830143929,
      -0.07949833571910858,
      0.06623990088701248,
      -0.05759095773100853,
      -0.11563187837600708,
      -0.027254749089479446,
      -0.006865696515887976,
      -0.016263633966445923,
      0.05492309108376503,
      -0.06547457724809647,
      -0.039499681442976,
      -0.026863398030400276,
      -0.01488265860825777,
      -0.021382790058851242,
      -0.010712815448641777,
      0.007452535908669233,
      -0.040374454110860825,
      -0.017443155869841576,
      0.05334121733903885,
      0.004355872981250286,
      0.09345085918903351,
      -0.018146662041544914,
      -0.011214209720492363,
      -0.014969279058277607,
      0.006619416177272797,
      -0.03784257918596268,
      -0.021176133304834366,
      -0.0035350248217582703,
      0.02210412733256817,
      0.08251765370368958,
      0.07699304819107056,
      0.014434169046580791,
      0.06459391117095947,
      0.014542337507009506,
      0.05336582660675049,
      0.05079864710569382,
      -0.04798048362135887,
      -0.053800567984580994,
      0.027800552546977997,
      0.05500844120979309,
      -0.04990829527378082,
      -0.056268248707056046,
      0.07538924366235733,
      0.016960307955741882,
      -0.022116953507065773,
      -0.06197477877140045,
      -0.014986597932875156,
      0.018692994490265846,
      0.0036998207215219736,
      0.011220301501452923,
      0.04278272017836571,
      0.003152077551931143,
      0.03141075000166893,
      0.04377732425928116,
      0.053288184106349945,
      -0.007332562003284693,
      -0.03866122290492058,
      -0.09151844680309296,
      0.02702125534415245,
      0.03344240412116051,
      -0.027089226990938187,
      0.02852270007133484,
      0.03600567579269409,
      -0.03343596309423447,
      0.05622193589806557,
      -0.04158151522278786,
      -0.0942215695977211,
      -0.033789072185754776,
      0.045870620757341385,
      -0.03913729265332222,
      -0.029937386512756348,
      -0.038047440350055695,
      0.03200288861989975,
      -0.0063440026715397835,
      0.028182461857795715,
      -0.02673938311636448,
      -0.027183962985873222,
      0.012439785525202751,
      0.00011287446977803484,
      -0.05888882651925087,
      0.058749981224536896,
      0.016338640823960304,
      -0.06823769956827164,
      -0.005647777114063501,
      0.018538998439908028,
      0.013752802275121212,
      0.12756115198135376,
      0.01756744273006916,
      -0.03342496231198311,
      -0.0576009526848793,
      0.06573954224586487,
      0.01849525421857834,
      -0.015716271474957466,
      -0.10621865093708038,
      0.011731293052434921,
      -0.03415549546480179,
      0.023406686261296272,
      0.05447055399417877,
      -0.1095806360244751,
      0.0010046251118183136,
      0.006901598535478115,
      -0.0012360922992229462,
      0.11026839166879654,
      -0.0022229275200515985,
      -0.00503705395385623,
      -0.006429945584386587,
      0.03944346308708191,
      0.03131631389260292,
      -0.031238878145813942,
      0.023675251752138138,
      0.005550293251872063,
      -0.07964890450239182,
      -0.05144575238227844,
      0.005990114528685808,
      -0.002689527114853263,
      -0.02442874200642109,
      0.03442186489701271,
      0.04806332290172577,
      -0.04233415424823761,
      -0.0020815986208617687,
      -0.03431546688079834,
      0.020275495946407318,
      0.030592724680900574,
      -0.03678823634982109,
      -0.0023590156342834234,
      -0.010683516040444374,
      -0.06523200124502182,
      0.06902314722537994,
      0.03621000051498413,
      0.010891967453062534,
      0.047946397215127945,
      0.010998022742569447,
      -0.05455049127340317,
      -0.01585903763771057,
      -0.0626353919506073,
      0.019617602229118347,
      -0.05607651546597481,
      0.04015861824154854,
      0.044159840792417526,
      0.05836987495422363,
      0.05543144419789314,
      0.0023207301273941994,
      0.01085167471319437,
      -0.05634947866201401,
      -0.02219734527170658,
      0.010808387771248817,
      0.044073499739170074,
      0.040180105715990067,
      -0.058595649898052216,
      0.061612967401742935,
      -0.02366362139582634,
      -0.007891014218330383,
      -0.005197326187044382,
      0.0010981905506923795,
      -0.02497885189950466,
      -0.050991225987672806,
      -0.09063244611024857,
      0.01686493679881096,
      -0.031021058559417725,
      -0.0023441347293555737,
      0.06572189182043076,
      -0.0694655179977417,
      0.02884913608431816,
      0.030262909829616547,
      -0.03227738291025162,
      -0.00738627091050148,
      -0.033572375774383545,
      0.1261250227689743,
      -0.06430066376924515,
      -0.04133719205856323,
      0.01665979065001011,
      -0.059473756700754166,
      -0.011909867636859417,
      0.0008941791602410376,
      -0.0779109075665474,
      0.006291769910603762,
      0.02722979336977005,
      -0.035971444100141525,
      0.0035705568734556437,
      0.010827092453837395,
      -0.005016491282731295,
      0.011136789806187153,
      -0.03749300166964531,
      -0.033912092447280884,
      0.012202813290059566,
      0.04959286376833916,
      0.07457207888364792,
      -0.04595918580889702,
      0.0862235575914383,
      0.032274141907691956,
      0.07230082899332047,
      -0.017542170360684395,
      0.01872965134680271,
      -0.024669216945767403,
      -0.05101022496819496,
      -0.08037013560533524,
      -0.027064688503742218,
      -0.035692792385816574,
      -0.03246644139289856,
      0.015309455804526806,
      0.006127079017460346,
      -0.06980278342962265,
      0.015610718168318272,
      -0.010445673950016499,
      -0.0028105450328439474,
      -0.022592615336179733,
      0.025610120967030525,
      0.014520639553666115,
      -0.006224213168025017,
      -0.0800180584192276,
      0.0038342848420143127,
      0.05270751938223839,
      0.03977476805448532,
      0.038396287709474564,
      -0.01587921380996704,
      -0.01453318726271391,
      0.00013889309775549918,
      -0.014446371234953403,
      0.016472093760967255,
      -0.03703993558883667,
      0.010983480140566826,
      -0.03716399148106575,
      0.011965708807110786,
      -0.044333357363939285,
      0.170350581407547,
      -0.08092138916254044,
      0.018871033564209938,
      -0.03717389702796936,
      0.0012768665328621864,
      -0.0490509532392025,
      0.028633050620555878,
      -0.10650632530450821,
      0.010773124173283577,
      -0.029455292969942093,
      0.010785408318042755,
      -0.04406260699033737,
      -0.02770753763616085,
      -0.08292921632528305,
      0.046515461057424545,
      0.04626535624265671,
      0.03694649040699005,
      0.009223095141351223,
      0.07691805809736252,
      0.038375213742256165,
      -0.025174839422106743,
      0.034878358244895935,
      -0.011522004380822182,
      0.03363732621073723,
      0.07176410406827927,
      0.01649102009832859,
      -0.0035126626025885344,
      0.0015624896623194218,
      -0.06958815455436707,
      0.014246976003050804,
      -0.006899315398186445,
      -0.0358244813978672,
      -0.04045199602842331,
      0.042707979679107666,
      0.021953783929347992,
      0.05878603830933571,
      -0.025724539533257484,
      -0.041133858263492584,
      0.07572384178638458,
      0.0557374507188797,
      -0.04125511273741722,
      0.11046673357486725,
      -0.009050587192177773,
      -0.08745123445987701,
      0.06204192340373993,
      -0.03213914483785629,
      0.05918841436505318,
      0.030400391668081284,
      0.040992096066474915,
      -0.05840533971786499,
      -0.02809075638651848,
      -0.022516410797834396,
      -0.06954442709684372,
      0.02785748429596424,
      0.09773871302604675,
      -0.03639569133520126,
      0.0020845343824476004,
      0.014133360236883163,
      -0.0605071485042572,
      -0.01621757633984089,
      0.005951049271970987,
      0.08939678966999054,
      0.005138539243489504,
      -0.004210873041301966,
      0.014587242156267166,
      -0.021183393895626068,
      -0.01351716835051775,
      -0.0022967613767832518,
      -0.0021196941379457712,
      -0.044861700385808945,
      0.061648327857255936,
      -0.026367755606770515,
      0.051565468311309814,
      -0.031927138566970825,
      0.07814232259988785,
      0.03301861509680748,
      -0.05016576498746872,
      -0.1216530054807663,
      -0.012498411349952221,
      0.06065470352768898,
      0.01196017675101757,
      0.028744759038090706,
      -0.007438357453793287,
      0.01055733673274517,
      0.008769015781581402,
      -0.018676109611988068,
      0.03374166041612625,
      0.024316050112247467,
      0.05222353711724281,
      0.02320176176726818,
      0.05374174192547798,
      -0.03231328725814819,
      -0.09277376532554626,
      -0.008304886519908905,
      -0.07281021028757095,
      0.06669773906469345,
      -0.028014397248625755,
      -0.04888373985886574,
      0.012804332189261913,
      0.08180271834135056,
      -0.040729817003011703,
      -0.04912403225898743,
      0.030208561569452286,
      -0.01634172536432743,
      0.017883554100990295,
      -0.0084003284573555,
      -0.01885150372982025,
      -0.03927261009812355,
      -0.05341155454516411,
      -0.0374315083026886,
      0.02970561757683754,
      -0.021740825846791267,
      0.0952601432800293,
      -0.09918076545000076,
      -0.05738743022084236,
      0.03278714045882225,
      -0.021358152851462364,
      -0.08721453696489334,
      0.0681847333908081,
      0.03826945275068283,
      0.0252398494631052,
      -0.04566030204296112,
      -0.04421216994524002,
      0.01947116106748581,
      0.03334274888038635,
      -0.007973368279635906,
      -0.009773648343980312,
      -0.05499551445245743,
      -0.015138208866119385,
      0.01976863481104374,
      -0.0197726022452116,
      0.05267839878797531,
      0.006246120203286409,
      0.02878003567457199,
      -0.014455473981797695,
      0.07372631877660751,
      -0.07315333932638168,
      0.06446105986833572,
      0.06705834716558456,
      0.1032649427652359,
      0.002945561893284321,
      0.011996638029813766,
      -0.03144827112555504,
      0.07050751894712448,
      0.015543880872428417,
      0.04120803251862526,
      0.01865541562438011,
      0.008581030182540417,
      -0.0007646603626199067,
      0.026734009385108948,
      -0.007808287627995014,
      -0.028205445036292076,
      0.0145560996606946,
      -0.007690224330872297,
      -0.03710980340838432,
      0.055551230907440186,
      -0.029782429337501526,
      0.14239874482154846,
      -0.05026762932538986,
      -0.02133326046168804,
      -0.014372198842465878,
      -0.005211047362536192,
      0.011313924565911293,
      0.018452903255820274,
      -0.08861410617828369,
      0.015411202795803547,
      0.0057929158210754395,
      0.031814660876989365,
      -0.042912181466817856,
      -0.0250163022428751,
      0.040744848549366,
      -0.00945957936346531,
      0.03088095225393772,
      0.016564471647143364,
      0.006833971012383699,
      -0.0030876188538968563,
      -0.02280414290726185,
      0.03416839614510536,
      0.004647102672606707,
      -0.03154071047902107,
      0.06265010684728622,
      0.09704253822565079,
      -0.09644347429275513,
      0.0045724124647676945,
      0.016352863982319832,
      0.031313564628362656,
      0.013720452785491943,
      0.06364376842975616,
      -0.13084401190280914,
      -0.02964051067829132,
      0.002750787418335676,
      0.012211422435939312,
      0.019936343654990196,
      0.07723167538642883,
      0.020228086039423943,
      -0.0008165871258825064,
      0.07315005362033844,
      0.0043982029892504215,
      0.02174931764602661,
      -0.04246072471141815,
      0.000874871329870075,
      0.04194723069667816,
      0.10762687772512436,
      0.04826602339744568,
      0.0032810296397656202,
      -0.05768106132745743,
      -0.08069657534360886,
      -0.03445955365896225,
      -0.058087870478630066,
      -0.027344683185219765,
      -0.04263428598642349,
      0.002341542160138488,
      0.005911401938647032,
      0.02785659208893776,
      -0.002608686685562134,
      0.0835019201040268,
      0.013727053999900818,
      -0.016017701476812363,
      -0.13508488237857819,
      0.030768802389502525,
      -0.0447661355137825,
      0.005018929019570351,
      -0.060709308832883835,
      0.050319306552410126,
      -0.05076277628540993,
      -0.013433031737804413,
      0.030194435268640518,
      -0.012762903235852718,
      0.03193732351064682,
      0.056494928896427155,
      0.06243174150586128,
      0.06991211324930191,
      -0.03865300491452217,
      0.022697502747178078
    ],
    [
      0.005101506598293781,
      0.02775394357740879,
      0.016267763450741768,
      -0.03651103377342224,
      0.012821224518120289,
      7.553241630375851e-06,
      0.06365795433521271,
      0.049232110381126404,
      0.13436323404312134,
      0.014619112946093082,
      -0.03721359744668007,
      -0.011576875112950802,
      0.0033943001180887222,
      -0.010402457788586617,
      0.051860351115465164,
      -0.03908030688762665,
      0.00361855560913682,
      -0.035384856164455414,
      -0.05137858912348747,
      0.0629654973745346,
      0.0533750057220459,
      0.06163165345788002,
      -0.05792514234781265,
      -0.0360962338745594,
      0.015683669596910477,
      0.06542098522186279,
      -0.020021984353661537,
      -0.0600307323038578,
      -0.06229880452156067,
      -0.05508771166205406,
      0.029729437083005905,
      0.0035227746702730656,
      -0.031882379204034805,
      0.03587299585342407,
      0.05431467667222023,
      0.04538297280669212,
      -0.08572550863027573,
      -0.07422319054603577,
      0.07817178964614868,
      0.08934956043958664,
      -0.012884126044809818,
      0.004302718676626682,
      0.019259952008724213,
      -0.02241329476237297,
      0.021923627704381943,
      -0.06422033905982971,
      0.03133499622344971,
      0.02421623282134533,
      -0.027561405673623085,
      0.02676294557750225,
      -0.02588733844459057,
      0.017323171719908714,
      0.06961911916732788,
      0.027356939390301704,
      0.027543583884835243,
      -0.010594398714601994,
      0.06988038867712021,
      0.04770360514521599,
      -0.08528989553451538,
      -0.029866373166441917,
      -0.0070412857457995415,
      0.04289029911160469,
      -0.02572818659245968,
      -0.008463396690785885,
      0.037798311561346054,
      0.016623083502054214,
      0.05015899986028671,
      0.01469863299280405,
      -0.053742729127407074,
      -0.045548874884843826,
      -0.09264831990003586,
      0.02197381667792797,
      -0.004097784869372845,
      0.07015974074602127,
      0.013954149559140205,
      0.06205113232135773,
      -0.04551214352250099,
      -0.01356453262269497,
      -0.025645460933446884,
      -0.08905481547117233,
      -0.027286449447274208,
      0.012149634771049023,
      0.05965319275856018,
      -0.08373601734638214,
      -0.05570328235626221,
      0.0006621914217248559,
      -0.021184468641877174,
      0.05158128961920738,
      0.010731928050518036,
      -0.05612200126051903,
      0.013083004392683506,
      -0.014879346825182438,
      -0.018571386113762856,
      0.012322599068284035,
      -0.04585457965731621,
      -0.006884889677166939,
      -0.002521455055102706,
      0.009410836733877659,
      0.016266072168946266,
      -0.03905029594898224,
      -0.04916556924581528,
      -0.009482892230153084,
      0.08905768394470215,
      -0.05064224824309349,
      -0.029283490031957626,
      0.018908362835645676,
      -0.020427629351615906,
      -0.043122172355651855,
      -0.01694645918905735,
      -0.04134482890367508,
      -0.037233926355838776,
      0.05937811732292175,
      -0.024911269545555115,
      0.025006406009197235,
      -0.00652978103607893,
      -0.0788826048374176,
      -0.034851111471652985,
      -0.049144964665174484,
      -0.025351542979478836,
      -0.046309977769851685,
      0.03468402102589607,
      0.07612080872058868,
      -0.005004383623600006,
      -0.04583904892206192,
      -0.020196713507175446,
      -0.006961802486330271,
      -0.04090866819024086,
      -0.004264242481440306,
      -0.043353453278541565,
      0.0011839050566777587,
      -0.01066476758569479,
      -0.02818197011947632,
      -0.08153124898672104,
      0.010182764381170273,
      0.056473858654499054,
      -0.0442255474627018,
      -0.12686486542224884,
      -0.008938424289226532,
      -0.031800296157598495,
      0.01413249596953392,
      0.021368173882365227,
      0.006410095375031233,
      0.0030531040392816067,
      -0.06651900708675385,
      0.06003711372613907,
      -0.048888541758060455,
      0.010549443773925304,
      -0.001408999552950263,
      0.12325362116098404,
      0.07933320850133896,
      -0.03964487090706825,
      0.024846119806170464,
      0.009667317382991314,
      -0.019556894898414612,
      -0.026158014312386513,
      -0.03620487451553345,
      0.01244442816823721,
      0.054708272218704224,
      -0.04256408289074898,
      0.022255973890423775,
      -0.032088544219732285,
      0.03130901977419853,
      0.026309674605727196,
      -0.05875895172357559,
      -0.01512062270194292,
      4.209809048916213e-05,
      -0.022467413917183876,
      0.007752205245196819,
      -0.035971492528915405,
      -0.008860048837959766,
      0.12483711540699005,
      0.02804988995194435,
      -0.01365213468670845,
      -0.020278723910450935,
      0.050386734306812286,
      0.060297250747680664,
      0.03330865502357483,
      -0.02241847664117813,
      0.01085944939404726,
      0.023793745785951614,
      0.05215249955654144,
      -0.01590322144329548,
      -0.010375753976404667,
      -0.02424178272485733,
      -0.0284639410674572,
      -0.002505979733541608,
      -0.012227212078869343,
      -0.017698323354125023,
      -0.01777212880551815,
      -0.0210491344332695,
      0.05022554472088814,
      0.02955261431634426,
      -0.02437007427215576,
      0.02435211092233658,
      0.034092940390110016,
      0.04095080867409706,
      -0.003106700023636222,
      0.016449106857180595,
      -0.0023231697268784046,
      -0.01534255687147379,
      -0.06582844257354736,
      0.009075285866856575,
      0.008796948939561844,
      -0.06143934279680252,
      0.0841926708817482,
      0.021968675777316093,
      -0.053676921874284744,
      0.07574339210987091,
      0.03925641253590584,
      -0.004722451791167259,
      -0.06573217362165451,
      0.02312570810317993,
      0.0007581092650070786,
      0.01705125905573368,
      -0.007247834466397762,
      -0.11504246294498444,
      -0.010846377350389957,
      0.05110631138086319,
      0.016530539840459824,
      -0.12846054136753082,
      -0.018552834168076515,
      -0.0052110557444393635,
      -0.09670555591583252,
      0.007207347545772791,
      -0.033683985471725464,
      0.009653303772211075,
      0.06360068917274475,
      0.01756417006254196,
      0.004267762415111065,
      0.05415042117238045,
      0.021314917132258415,
      0.046613987535238266,
      0.08353275060653687,
      -0.03536637872457504,
      -0.034008778631687164,
      0.010895867832005024,
      0.06710166484117508,
      -0.052834779024124146,
      0.019856546074151993,
      -0.04347601532936096,
      0.022149279713630676,
      -0.10019832849502563,
      0.07314549386501312,
      0.03434613347053528,
      0.023709414526820183,
      -0.019680697470903397,
      0.07644593715667725,
      -0.06590235233306885,
      -0.025067757815122604,
      0.018483223393559456,
      -0.019985845312476158,
      0.09109438210725784,
      -0.007513763383030891,
      0.008900157175958157,
      -0.02059795893728733,
      -0.06364315003156662,
      -0.02299916371703148,
      -0.004650532733649015,
      -0.042216550558805466,
      -0.008476839400827885,
      0.028441250324249268,
      0.027914267033338547,
      -0.06523922830820084,
      -0.01766854152083397,
      0.02478334680199623,
      0.023187391459941864,
      -0.03318600729107857,
      -0.06066638231277466,
      0.012785559520125389,
      0.0004821238690055907,
      -0.03311508893966675,
      0.012023848481476307,
      0.015433947555720806,
      -0.048257362097501755,
      0.007568933069705963,
      0.03261973336338997,
      0.0010210570180788636,
      0.021106895059347153,
      -0.04027613624930382,
      0.011480404064059258,
      0.12927135825157166,
      -0.06326593458652496,
      -0.01052796933799982,
      0.006993080954998732,
      -0.04628638178110123,
      0.04972052946686745,
      -0.06653474271297455,
      0.04320180043578148,
      0.049990277737379074,
      0.06614864617586136,
      0.03286830708384514,
      0.023056525737047195,
      0.0448182187974453,
      -0.03395555168390274,
      0.0808897614479065,
      -0.02604496106505394,
      0.057577356696128845,
      0.025707731023430824,
      0.00455524493008852,
      0.02094845660030842,
      -0.022875234484672546,
      -0.03387653827667236,
      -0.04165811464190483,
      0.0005055638612248003,
      -0.03788628801703453,
      -0.007853362709283829,
      0.06574272364377975,
      -0.03635377064347267,
      -0.026139484718441963,
      0.042947135865688324,
      0.038693759590387344,
      0.030004015192389488,
      -0.08496170490980148,
      0.04297184571623802,
      -0.045642439275979996,
      0.034851931035518646,
      -0.01905629225075245,
      0.03625970333814621,
      -0.005731034558266401,
      0.11464177817106247,
      -0.0026499785017222166,
      0.025192830711603165,
      -0.004386915825307369,
      -0.08029574155807495,
      -0.06076614558696747,
      0.010203695856034756,
      0.018391301855444908,
      0.008233100175857544,
      -0.006002491340041161,
      0.0661783218383789,
      -0.02005179412662983,
      0.030356865376234055,
      -0.02851753868162632,
      0.0154520682990551,
      -0.005583517253398895,
      -0.07479201257228851,
      0.07633443921804428,
      0.0007073549786582589,
      0.06434600055217743,
      -0.006943404674530029,
      -0.028106525540351868,
      0.012142169289290905,
      -0.026151418685913086,
      0.07853735983371735,
      0.04710863530635834,
      -0.0018512539099901915,
      -0.010672050528228283,
      -0.046123676002025604,
      0.011119290255010128,
      -0.04473314806818962,
      -0.021127577871084213,
      -0.06317117065191269,
      -0.01754058711230755,
      0.050491005182266235,
      0.012365465052425861,
      -0.000571194221265614,
      0.0021080540027469397,
      0.045774906873703,
      -0.041756968945264816,
      0.06386107951402664,
      -0.043882206082344055,
      0.025668006390333176,
      -0.029664624482393265,
      -0.02219253219664097,
      0.04218152537941933,
      0.023056328296661377,
      0.021289680153131485,
      -0.03655374422669411,
      0.05026713013648987,
      -0.04746236279606819,
      -0.06890266388654709,
      -0.0029771483968943357,
      -0.015028191730380058,
      -0.02907942794263363,
      0.07616046816110611,
      -0.04635672643780708,
      -0.04056493192911148,
      -0.04792747274041176,
      -0.10143592953681946,
      -0.08510910719633102,
      0.09700460731983185,
      -0.02822924219071865,
      -0.04586398974061012,
      0.028272442519664764,
      0.07819397747516632,
      0.06455986201763153,
      -0.02545199915766716,
      0.004325778689235449,
      0.06093059480190277,
      0.021325888112187386,
      0.011607288382947445,
      0.03184683620929718,
      0.028452454134821892,
      -0.048198215663433075,
      -0.04841690510511398,
      0.004975995514541864,
      -0.06350430846214294,
      -0.03969203680753708,
      0.0984773188829422,
      0.017228227108716965,
      -0.013571169227361679,
      -0.025275081396102905,
      0.029218170791864395,
      -0.00588150043040514,
      0.04964477941393852,
      0.05430043488740921,
      -0.034828513860702515,
      0.03209276124835014,
      -0.08145154267549515,
      -0.006598855368793011,
      0.016221269965171814,
      -0.06436798721551895,
      -0.020272579044103622,
      -0.03994925320148468,
      0.012599373236298561,
      -0.012869413942098618,
      0.02502194419503212,
      0.00652782479301095,
      -0.020911255851387978,
      -0.010180926881730556,
      -0.007150194142013788,
      -0.039925988763570786,
      -0.034491464495658875,
      -0.025865763425827026,
      -0.008061563596129417,
      0.022085973992943764,
      -0.005786525551229715,
      0.0058240508660674095,
      -0.06698925793170929,
      -0.041053470224142075,
      0.07937075942754745,
      -0.1169646680355072,
      0.04777790233492851,
      -0.09011468291282654,
      0.05271952226758003,
      -0.028858359903097153,
      -0.0792975202202797,
      -0.008100231178104877,
      -0.009057276882231236,
      0.08353399485349655,
      0.005204883869737387,
      0.00039744441164657474,
      0.0363801047205925,
      0.02130708657205105,
      -0.052748147398233414,
      -3.521491089486517e-05,
      0.036003727465867996,
      -0.062302447855472565,
      -0.04834318906068802,
      0.018035028129816055,
      0.019409852102398872,
      -0.007420728914439678,
      0.011800075881183147,
      -0.022484419867396355,
      -0.047572698444128036,
      -0.054527442902326584,
      -0.05052023008465767,
      -0.050399117171764374,
      -0.03245348110795021,
      -0.02916310541331768,
      0.09971269965171814,
      -0.023203592747449875,
      -0.0003635083558037877,
      -0.13552409410476685,
      -0.017952602356672287,
      0.010409996844828129,
      -0.03430939465761185,
      -0.030848072841763496,
      -0.016349390149116516,
      0.11510653048753738,
      0.01957043819129467,
      -0.02305927686393261,
      0.05199548602104187,
      -0.04508427530527115,
      0.03515096753835678,
      0.02167956344783306,
      0.01071853656321764,
      -0.008942719548940659,
      0.01802646741271019,
      0.00744786998257041,
      -0.09511150419712067,
      0.06243553012609482,
      -0.01274321973323822,
      0.04005279764533043,
      0.006290471646934748,
      -0.10561478137969971,
      -0.007767298724502325,
      0.005547733977437019,
      -0.07259897887706757,
      0.03684558346867561,
      0.015596372075378895,
      0.04934043437242508,
      0.0037517405580729246,
      -0.012601830065250397,
      -0.0018419981934130192,
      0.026164047420024872,
      0.08777613192796707,
      -0.023052286356687546,
      -0.01456962525844574,
      0.036298755556344986,
      -0.0019018346210941672,
      -0.058864858001470566,
      -0.018633278086781502
    ],
    [
      -0.0037327930331230164,
      0.009377754293382168,
      -0.11944260448217392,
      -0.04702702537178993,
      0.04009773209691048,
      0.017261933535337448,
      -0.11175616085529327,
      -0.011189479380846024,
      -0.04859105497598648,
      -0.04177413880825043,
      -0.04772891849279404,
      -0.11696209758520126,
      -0.05332818627357483,
      -0.08042021095752716,
      -0.0019888451788574457,
      -0.06739277392625809,
      0.027085911482572556,
      -0.013560744933784008,
      -0.029455451294779778,
      -0.04488895833492279,
      0.03935066610574722,
      -0.031152574345469475,
      -0.06662648171186447,
      -0.013151058927178383,
      0.020658308640122414,
      -0.001412627985700965,
      0.01593507081270218,
      0.041153620928525925,
      -0.011880343779921532,
      -0.03903502598404884,
      -0.07375019043684006,
      -0.01443611178547144,
      -0.051775313913822174,
      -0.027982579544186592,
      -0.06279618293046951,
      -0.013259838335216045,
      -0.015221699140965939,
      0.03920425847172737,
      -0.02983620949089527,
      0.05728660523891449,
      0.033561866730451584,
      0.0009386749006807804,
      0.01523009967058897,
      0.010952620767056942,
      0.07910627871751785,
      -0.03437018394470215,
      0.0020254591945558786,
      -0.033761195838451385,
      -0.04042857512831688,
      -0.04644477739930153,
      -0.1106872409582138,
      -0.026667024940252304,
      0.07097233086824417,
      -0.02233223244547844,
      0.020359884947538376,
      0.008407697081565857,
      0.0037099970504641533,
      0.011406720615923405,
      -0.14529308676719666,
      0.009809030219912529,
      0.046992309391498566,
      -0.05123763531446457,
      0.01687554456293583,
      -0.016827553510665894,
      0.08906026184558868,
      -0.02955617569386959,
      0.012099753133952618,
      -0.06341058760881424,
      0.08116162568330765,
      -0.007161106914281845,
      -0.04384941607713699,
      0.12510868906974792,
      -0.054953888058662415,
      0.008767422288656235,
      -0.02921389602124691,
      -0.006703512277454138,
      -0.07295267283916473,
      0.05875239148736,
      -0.012565415352582932,
      -0.009610725566744804,
      -0.004231753759086132,
      -0.008977632969617844,
      -0.06070093810558319,
      -0.0013909339904785156,
      -0.03594965487718582,
      -0.04087943583726883,
      0.013391010463237762,
      0.05382652208209038,
      -0.018516739830374718,
      -0.01747012697160244,
      -0.01887109875679016,
      -0.030779698863625526,
      0.04464579373598099,
      -0.015489079989492893,
      0.003250463865697384,
      0.006337761878967285,
      0.013727545738220215,
      0.004319291561841965,
      0.029095785692334175,
      -0.030709801241755486,
      -0.02445754036307335,
      0.042120397090911865,
      0.0034556349273771048,
      -0.043078649789094925,
      -0.009408144280314445,
      0.04581458121538162,
      -0.06363116949796677,
      3.275815470260568e-05,
      0.013441255316138268,
      0.05903368815779686,
      -0.039357740432024,
      -0.05135897547006607,
      -0.07783351093530655,
      -0.006739260628819466,
      0.07372818142175674,
      0.00573198264464736,
      0.03831648454070091,
      0.03729211166501045,
      -0.012725736945867538,
      0.011598612181842327,
      -0.003529610112309456,
      -0.02373962290585041,
      0.008263220079243183,
      0.011922752484679222,
      0.020387394353747368,
      -0.015848590061068535,
      0.007755562663078308,
      -0.04055638611316681,
      0.08088105916976929,
      0.026992317289114,
      0.005866192746907473,
      0.041954588145017624,
      -0.017679965123534203,
      0.04361649602651596,
      -0.011963912285864353,
      -0.03343968465924263,
      0.025803105905652046,
      -0.0561414435505867,
      0.10006680339574814,
      0.09108371287584305,
      -0.003175811143592,
      -0.03993474692106247,
      0.037504710257053375,
      -0.049971822649240494,
      -0.0018118304433301091,
      0.016420351341366768,
      -0.053891025483608246,
      0.014796089380979538,
      0.028006121516227722,
      -0.028929632157087326,
      -0.031200731173157692,
      -0.05338867008686066,
      -0.029029199853539467,
      -0.05930129066109657,
      -0.0221771951764822,
      0.02676912397146225,
      0.03244591876864433,
      -0.02847413346171379,
      0.03297145292162895,
      -0.03293284773826599,
      0.05185288190841675,
      0.03526872768998146,
      0.029642874374985695,
      -0.003366447053849697,
      0.0187566876411438,
      0.04483223333954811,
      0.03303499519824982,
      0.0054562827572226524,
      0.026187550276517868,
      -0.10284687578678131,
      0.04198189452290535,
      0.06249247491359711,
      -0.015479174442589283,
      -0.00597787369042635,
      0.016663959249854088,
      0.0009404491866007447,
      0.09050770848989487,
      -0.04511357471346855,
      -0.007275009527802467,
      -0.020166989415884018,
      0.024759020656347275,
      0.028538143262267113,
      -0.0640282854437828,
      -0.021682798862457275,
      0.029440682381391525,
      0.0583769865334034,
      -0.00806979089975357,
      -0.04962644353508949,
      0.07123289257287979,
      -0.022909119725227356,
      -0.05528181791305542,
      0.020624905824661255,
      0.012030386365950108,
      -0.023796353489160538,
      0.02002900280058384,
      0.05078469216823578,
      0.07169690728187561,
      0.018873702734708786,
      -0.004384479019790888,
      0.1264759749174118,
      -0.017724022269248962,
      0.011585568077862263,
      0.04319065436720848,
      -0.03911154717206955,
      0.014683912508189678,
      0.09660004079341888,
      0.021484270691871643,
      0.025522932410240173,
      -0.12173650413751602,
      0.056889839470386505,
      0.05586419254541397,
      -0.0063932305201888084,
      0.04880279302597046,
      0.025806313380599022,
      -0.07388535141944885,
      0.023117411881685257,
      0.03248755261301994,
      0.008050528354942799,
      -0.09307755529880524,
      -0.031692951917648315,
      0.028072481974959373,
      0.06213946267962456,
      0.024488205090165138,
      0.034867655485868454,
      -0.07733762264251709,
      -0.0026347802486270666,
      0.0003076772263739258,
      -0.03284471854567528,
      0.04518672078847885,
      0.026410115882754326,
      0.08047638833522797,
      0.011217148043215275,
      0.011436722241342068,
      -0.004743421450257301,
      0.06514696031808853,
      0.06815609335899353,
      -0.0023485079873353243,
      -0.018773680552840233,
      -0.0196287352591753,
      -0.02307693101465702,
      -0.0010604653507471085,
      0.09180531650781631,
      0.05619094520807266,
      -0.04332011565566063,
      -0.03462705388665199,
      0.03502855449914932,
      -0.01062278263270855,
      0.008455351926386356,
      -0.03651745989918709,
      0.042315054684877396,
      -0.024327846243977547,
      0.0484866201877594,
      -0.04790341854095459,
      0.040352534502744675,
      -0.02554347738623619,
      0.014007262885570526,
      -0.0010310470825061202,
      0.014200372621417046,
      -0.07261204719543457,
      0.0049987672828137875,
      0.05285277217626572,
      -0.017408929765224457,
      0.0339038260281086,
      0.07761196047067642,
      -0.023016108199954033,
      0.04202621430158615,
      -0.04332415387034416,
      -0.03294818103313446,
      0.022935496643185616,
      -0.026056524366140366,
      0.04974503815174103,
      -0.022125687450170517,
      -0.03253476321697235,
      0.049713876098394394,
      -0.012111715972423553,
      0.057110823690891266,
      -0.02571796253323555,
      -0.008123623207211494,
      -0.021339602768421173,
      0.011896799318492413,
      0.03658279776573181,
      0.06451044976711273,
      -0.050443075597286224,
      0.002412240020930767,
      -0.009101931937038898,
      0.07072809338569641,
      -0.019774530082941055,
      0.0001139105879701674,
      -0.11341091990470886,
      0.02609529346227646,
      0.05115301162004471,
      0.10541119426488876,
      -0.061164677143096924,
      0.06527380645275116,
      0.009420217014849186,
      -0.022505665197968483,
      -0.051271919161081314,
      0.008807026781141758,
      -0.06591694056987762,
      0.035390086472034454,
      0.001873873989097774,
      0.022707488387823105,
      -0.05079205334186554,
      -0.02594595029950142,
      0.06302798539400101,
      0.07023961842060089,
      0.021553829312324524,
      -0.024102631956338882,
      0.0028042704798281193,
      0.029146019369363785,
      -0.055430661886930466,
      0.04975496605038643,
      -0.037491120398044586,
      -0.024710332974791527,
      -0.0006038001738488674,
      -0.027062470093369484,
      0.005949460435658693,
      -0.022720498964190483,
      0.07695668935775757,
      -0.12340078502893448,
      -0.013760954141616821,
      -0.0746038481593132,
      -0.04367116466164589,
      -0.004446167033165693,
      0.0036266513634473085,
      -0.001309395069256425,
      -0.021146994084119797,
      0.056938234716653824,
      0.013986766338348389,
      -0.016948672011494637,
      0.01377993356436491,
      0.04797620698809624,
      0.007321640849113464,
      -0.004831779282540083,
      0.07974842190742493,
      0.03247973695397377,
      0.05743246525526047,
      0.06587374210357666,
      -0.061959728598594666,
      -0.04890868440270424,
      0.051863495260477066,
      0.012732038274407387,
      0.0044962577521800995,
      -0.003211444243788719,
      -0.029153913259506226,
      -0.006458239164203405,
      0.0370449461042881,
      -0.017779653891921043,
      0.022907456383109093,
      0.05059134587645531,
      -0.08672108501195908,
      -0.02939075417816639,
      -0.07335440069437027,
      0.07274875789880753,
      -0.037026744335889816,
      0.0030200041364878416,
      0.006140643730759621,
      -0.0221401359885931,
      -0.0006853445083834231,
      0.02447291649878025,
      0.02681342139840126,
      0.006369446404278278,
      0.009209956973791122,
      0.06974966824054718,
      0.032746322453022,
      0.03216666728258133,
      0.02836824581027031,
      0.01686006970703602,
      -0.029713593423366547,
      0.06579815596342087,
      -0.03864634409546852,
      0.014811762608587742,
      -0.04801744967699051,
      -0.01444302313029766,
      -0.006126644555479288,
      0.054161231964826584,
      -0.0265195332467556,
      -0.030613036826252937,
      -0.06603630632162094,
      -0.06069553270936012,
      0.03473598510026932,
      -0.02851663902401924,
      -0.019314344972372055,
      -0.024480445310473442,
      -0.014727173373103142,
      -0.02154320850968361,
      0.009132910519838333,
      -0.05343679338693619,
      0.09255488216876984,
      0.054718345403671265,
      -0.03162207826972008,
      -0.006220713257789612,
      0.07339785248041153,
      -0.04252246022224426,
      0.026306213811039925,
      0.06323827803134918,
      0.038013625890016556,
      0.04516313225030899,
      -0.06523247063159943,
      -0.032264914363622665,
      0.03232220187783241,
      0.041565876454114914,
      0.00805435050278902,
      0.0689781904220581,
      0.043702173978090286,
      0.009794987738132477,
      -0.020693661645054817,
      0.03659069910645485,
      -0.0013325752224773169,
      -0.012621323578059673,
      0.1302923709154129,
      -0.031890787184238434,
      0.05766205117106438,
      -0.054465118795633316,
      0.06295430660247803,
      0.044827889651060104,
      0.04054970666766167,
      -0.04848453402519226,
      -0.024749593809247017,
      0.019322875887155533,
      0.026289792731404305,
      0.10358481109142303,
      0.005204180721193552,
      0.07311070710420609,
      0.0035007172264158726,
      0.008588218130171299,
      -0.07069335132837296,
      -0.0040367525070905685,
      0.0038856854662299156,
      0.014656983315944672,
      0.08276588469743729,
      -0.02045382373034954,
      0.0297018401324749,
      -0.0051514324732124805,
      0.012122835032641888,
      -0.08130347728729248,
      -0.05370727926492691,
      -0.09508641809225082,
      -0.0676536038517952,
      -0.043505046516656876,
      -0.015947088599205017,
      -0.041033968329429626,
      0.03414055332541466,
      -0.008079011924564838,
      -0.008926916867494583,
      -0.09301745146512985,
      -0.013833395205438137,
      -0.002687726868316531,
      -0.03114558570086956,
      0.07020627707242966,
      -0.017429884523153305,
      -0.036328088492155075,
      0.011668535880744457,
      0.051351308822631836,
      0.02702021598815918,
      -0.05288762226700783,
      0.024062037467956543,
      0.047064706683158875,
      0.05327789485454559,
      -0.04375826567411423,
      0.0384298637509346,
      0.04805571213364601,
      0.0143438084051013,
      -0.026359811425209045,
      -0.026461027562618256,
      0.05964949354529381,
      -0.025328481569886208,
      -0.05252418294548988,
      -0.036836620420217514,
      0.00602406170219183,
      -0.09323565661907196,
      0.04192327335476875,
      0.015404382720589638,
      -0.05636514350771904,
      -0.08937624096870422,
      0.0041452995501458645,
      0.01858813315629959,
      0.06922777742147446,
      -0.013068713247776031,
      -0.02510877139866352,
      -0.007187546696513891,
      -0.023002857342362404,
      -0.025468818843364716,
      0.03501206636428833,
      -0.0490923635661602,
      0.0433683916926384,
      0.05087714642286301,
      -0.07661671936511993,
      0.03253324329853058,
      -0.005154877435415983,
      -0.02671205811202526,
      -0.000570585485547781,
      0.04503917321562767,
      0.03701505810022354,
      0.012914765626192093,
      0.04330349341034889,
      -0.023128550499677658,
      0.09035602957010269,
      -0.012092863209545612,
      -0.017555877566337585,
      -0.06640307605266571,
      -0.010427432134747505,
      -0.004610045347362757
    ],
    [
      -0.009213409386575222,
      -0.03050142526626587,
      -0.09325602650642395,
      0.012640113942325115,
      -0.03353163227438927,
      -0.02389800362288952,
      0.04550950601696968,
      0.03840777650475502,
      0.02451552450656891,
      -0.043545499444007874,
      0.049434494227170944,
      -0.0839051678776741,
      -0.011889641173183918,
      -0.10919667035341263,
      0.011469623073935509,
      0.004367656074464321,
      0.024722663685679436,
      -0.05187850818037987,
      0.004129244014620781,
      0.021278535947203636,
      -0.07304990291595459,
      0.0031469615641981363,
      -0.03584441915154457,
      0.024650560691952705,
      0.04967700317502022,
      -0.08105437457561493,
      -0.05952714756131172,
      0.03630244731903076,
      -0.08106335997581482,
      0.06254570931196213,
      0.03260374069213867,
      -0.08899939060211182,
      -0.030900858342647552,
      -0.07771255820989609,
      -0.06527014076709747,
      0.004402398131787777,
      -0.02962912991642952,
      0.04074810445308685,
      -0.07708458602428436,
      0.05313749983906746,
      0.012222690507769585,
      -0.04558905214071274,
      0.03602505847811699,
      -0.13281181454658508,
      0.060888584703207016,
      -0.014711297117173672,
      -0.040918536484241486,
      0.0711335763335228,
      0.01162695698440075,
      0.0011555213714018464,
      0.01758391037583351,
      -0.09553270787000656,
      -0.016736293211579323,
      -0.008445220068097115,
      0.05043281987309456,
      0.028605537489056587,
      0.004625736735761166,
      -0.06791625916957855,
      -0.09222306311130524,
      -0.052161719650030136,
      -0.02104807272553444,
      0.054126616567373276,
      0.03895098343491554,
      0.05323369801044464,
      -0.027511484920978546,
      0.05022723600268364,
      0.13451574742794037,
      -0.010870088823139668,
      0.07320155948400497,
      0.06806109100580215,
      -0.03561332821846008,
      0.023779528215527534,
      0.0072629088535904884,
      0.013710970059037209,
      0.043865449726581573,
      0.030400091782212257,
      0.027848294004797935,
      0.09052736312150955,
      -0.0016103279776871204,
      0.06862802058458328,
      0.0004105409316252917,
      0.07390043139457703,
      0.04612145945429802,
      -0.01131611131131649,
      -0.05476398766040802,
      0.00918661244213581,
      -0.036512140184640884,
      -0.0019563392270356417,
      0.04232575371861458,
      0.03016589768230915,
      0.034930258989334106,
      0.0683421865105629,
      0.020173363387584686,
      -0.017259230837225914,
      0.023313632234930992,
      0.0013388734078034759,
      -0.013635941781103611,
      -0.046840254217386246,
      0.062026236206293106,
      -0.005401294212788343,
      -0.00888629350811243,
      -0.07721815258264542,
      0.01517527550458908,
      0.03458011895418167,
      -0.009060755372047424,
      -0.06374802440404892,
      0.03815019130706787,
      -0.0339910127222538,
      -0.00796497706323862,
      0.05197416990995407,
      0.023965386673808098,
      0.03765951469540596,
      0.021123958751559258,
      -0.04257815331220627,
      -0.05466843768954277,
      -0.07574759423732758,
      0.026508618146181107,
      -0.013997945934534073,
      0.002748338971287012,
      0.014363473281264305,
      -0.043741848319768906,
      0.04141106829047203,
      -0.07964644581079483,
      -0.02963700145483017,
      0.07208790630102158,
      0.0037031471729278564,
      -0.040088754147291183,
      0.017684152349829674,
      -0.015619651414453983,
      0.026107365265488625,
      0.05403907224535942,
      0.007166045717895031,
      0.005981826223433018,
      0.00548552256077528,
      -0.024245861917734146,
      0.0009424053132534027,
      0.023917576298117638,
      -0.03620662912726402,
      0.04856560379266739,
      -0.010565260425209999,
      -0.013449989259243011,
      0.03127162531018257,
      0.03481583669781685,
      0.019120698794722557,
      0.0635543018579483,
      0.01642921194434166,
      0.01964433863759041,
      -0.043071240186691284,
      0.03422338142991066,
      -0.010190946981310844,
      0.05044970661401749,
      -0.022929277271032333,
      0.04380333796143532,
      0.046768296509981155,
      -0.07071717828512192,
      -0.037275828421115875,
      0.01599477417767048,
      -0.0097958380356431,
      0.04001199081540108,
      -0.01946483924984932,
      -0.06986600160598755,
      -0.11265409737825394,
      -0.038831159472465515,
      0.0801774263381958,
      0.04723256081342697,
      0.012273209169507027,
      -0.0710795447230339,
      -0.08075312525033951,
      0.017265096306800842,
      0.08747249096632004,
      -0.08348879218101501,
      0.06060946732759476,
      -0.02682248130440712,
      -0.03328707441687584,
      -0.09840451925992966,
      -0.03606458380818367,
      0.05529792606830597,
      0.06284885853528976,
      -0.052860088646411896,
      -0.027432063594460487,
      0.0506770946085453,
      -0.08021026104688644,
      -0.029405497014522552,
      0.06779395043849945,
      -0.004526657517999411,
      -0.017309680581092834,
      0.04010370746254921,
      -0.035014569759368896,
      0.025772245600819588,
      -0.009066851809620857,
      0.015772726386785507,
      -0.03132997080683708,
      -0.06153830140829086,
      -0.019932905212044716,
      -0.06430798023939133,
      0.04194467514753342,
      -0.053446561098098755,
      0.03767918795347214,
      0.0519595667719841,
      0.03405109420418739,
      0.005517723970115185,
      -0.027857951819896698,
      -0.02361455000936985,
      -0.08146712183952332,
      0.048619359731674194,
      0.012613809667527676,
      -0.07614796608686447,
      0.03671428933739662,
      0.0048392112366855145,
      0.03889135271310806,
      0.0016906971577554941,
      0.028097201138734818,
      -0.01111611071974039,
      0.004354157485067844,
      -0.02017136849462986,
      -0.00488986074924469,
      0.02220223657786846,
      0.06404989212751389,
      0.03560544177889824,
      0.026502421125769615,
      -0.04480152577161789,
      0.1239667758345604,
      -0.022848961874842644,
      -0.07032592594623566,
      -0.015359006822109222,
      0.01406831480562687,
      -0.015643510967493057,
      0.017054293304681778,
      -0.057093266397714615,
      -0.008151124231517315,
      0.09099916368722916,
      0.05824104696512222,
      -0.004513503983616829,
      0.007643566932529211,
      -0.04676678031682968,
      0.008092216216027737,
      0.023515651002526283,
      -0.050300128757953644,
      -0.020632797852158546,
      -0.08646891266107559,
      0.02373283915221691,
      -0.01940385438501835,
      0.02033991552889347,
      -0.07343638688325882,
      -0.02092071995139122,
      0.009305479936301708,
      -0.041551779955625534,
      -0.07438801974058151,
      0.026772180572152138,
      -0.048135045915842056,
      0.06298380345106125,
      0.041197407990694046,
      0.021815063431859016,
      0.005260089412331581,
      -0.01765400730073452,
      0.03770530968904495,
      -0.06269271671772003,
      0.05301881954073906,
      0.023906895890831947,
      0.01193264964967966,
      -0.003290989901870489,
      0.044095348566770554,
      -0.023399867117404938,
      0.07936643064022064,
      0.08498023450374603,
      -0.01227894052863121,
      -0.011970097199082375,
      -0.003274239134043455,
      0.02683204598724842,
      -0.009164905175566673,
      0.08769652247428894,
      0.022722138091921806,
      0.0024434728547930717,
      -0.010224859230220318,
      -0.040793340653181076,
      -0.031591709703207016,
      -0.027904996648430824,
      -0.008034974336624146,
      0.06954724341630936,
      0.018723594024777412,
      -0.009182271547615528,
      -0.06757647544145584,
      -0.08491171896457672,
      0.06970749795436859,
      -0.03200433403253555,
      -0.070209801197052,
      -0.05254945158958435,
      -0.04153171181678772,
      -0.05313941836357117,
      -0.0632062628865242,
      0.012105271220207214,
      0.11733117699623108,
      0.022611869499087334,
      0.004492541309446096,
      -0.011466401629149914,
      0.003704229835420847,
      0.024609746411442757,
      0.012714900076389313,
      0.030419254675507545,
      -0.009405845776200294,
      0.0002080249396385625,
      -0.03226044774055481,
      -0.03223525360226631,
      -0.012801757082343102,
      0.04057358205318451,
      -0.027110815048217773,
      -0.013518109917640686,
      -0.033887214958667755,
      -0.07158080488443375,
      0.0632629245519638,
      -0.006993237882852554,
      0.0750642716884613,
      -0.03771786019206047,
      0.016893170773983,
      -0.002617358462885022,
      0.09878019988536835,
      -0.025886619463562965,
      0.01954696513712406,
      0.06313667446374893,
      0.008352489210665226,
      -0.01378434058278799,
      0.03188606724143028,
      0.04086067155003548,
      0.04481714963912964,
      0.07099904865026474,
      0.008480258285999298,
      0.013693478889763355,
      -0.03891332447528839,
      0.008570421487092972,
      -0.006006288807839155,
      -0.014040166512131691,
      -0.07939613610506058,
      -0.03588326275348663,
      -0.004852612037211657,
      -0.0015035247197374701,
      0.12765341997146606,
      -0.01901761256158352,
      -0.0026018170174211264,
      -0.03702973574399948,
      0.002576575381681323,
      0.01984396204352379,
      -0.04033416137099266,
      -0.046005938202142715,
      -0.10899215191602707,
      0.007614185567945242,
      0.009410112164914608,
      0.0009561812621541321,
      0.044140737503767014,
      -0.009331785142421722,
      0.0016597517533227801,
      0.0034783659502863884,
      0.054035734385252,
      -0.04336170479655266,
      0.053248170763254166,
      -0.008330770768225193,
      0.082879438996315,
      -0.0321197547018528,
      0.0508112758398056,
      0.013450282625854015,
      0.007635393645614386,
      0.012414141558110714,
      -0.03268273174762726,
      -0.04281281679868698,
      0.049177683889865875,
      -0.026312755420804024,
      -0.014764360152184963,
      -0.019283493980765343,
      0.07632254809141159,
      -0.0594562403857708,
      -0.040523968636989594,
      0.04880547150969505,
      -0.008331216871738434,
      0.029179135337471962,
      -0.0035811136476695538,
      -0.03872417286038399,
      -0.021129421889781952,
      0.0003607716935221106,
      0.0023292480036616325,
      0.02129405178129673,
      0.07067915797233582,
      0.0016748502384871244,
      0.04817506670951843,
      -0.03668241202831268,
      -0.02997775189578533,
      0.053073275834321976,
      0.030391782522201538,
      -0.04684143140912056,
      -0.0837760791182518,
      0.001931381644681096,
      -0.00404002983123064,
      -0.00891537219285965,
      -0.04051660746335983,
      0.022453831508755684,
      -0.05046075955033302,
      -0.07913296669721603,
      0.014221442863345146,
      -0.13608282804489136,
      0.008954423479735851,
      0.06144547834992409,
      0.037468329071998596,
      0.030474940314888954,
      0.019944433122873306,
      0.015518756583333015,
      -0.02880089543759823,
      0.034453198313713074,
      -0.025936845690011978,
      0.07085177302360535,
      0.01688789576292038,
      -0.04674607515335083,
      0.055872004479169846,
      0.07159150391817093,
      0.0007820539176464081,
      -0.008105330169200897,
      -0.004432260524481535,
      -0.031831007450819016,
      -0.05736584588885307,
      0.036715924739837646,
      -0.0386391319334507,
      0.03179366514086723,
      -0.02068418823182583,
      0.013869059272110462,
      -0.08083455264568329,
      0.012754829600453377,
      0.005459177307784557,
      0.03876176103949547,
      -0.0038002810906618834,
      0.037855129688978195,
      0.06589259207248688,
      0.0988440066576004,
      -0.010880709625780582,
      -0.06363777816295624,
      -0.07360873371362686,
      -0.009372231550514698,
      0.0006784536526538432,
      0.020486438646912575,
      0.03249690681695938,
      0.04808077588677406,
      -0.04995831102132797,
      -0.00896081980317831,
      0.057969920337200165,
      -0.040896281599998474,
      0.03707650303840637,
      -0.06965889036655426,
      0.020258702337741852,
      -0.078638456761837,
      -0.04508964717388153,
      0.03555065765976906,
      -0.04457118362188339,
      -0.025180906057357788,
      -0.05336742848157883,
      0.04877025634050369,
      -0.06986522674560547,
      0.03434289991855621,
      -0.07540259510278702,
      -0.09134714305400848,
      0.002276579150930047,
      0.04757342487573624,
      -0.00010428281530039385,
      -0.004119102843105793,
      0.06289704889059067,
      0.09858091175556183,
      -0.028529003262519836,
      0.018755827099084854,
      -0.1268511265516281,
      -0.004561969079077244,
      0.011733727529644966,
      -0.0005505147273652256,
      -0.03651021420955658,
      0.05320632457733154,
      0.001073943916708231,
      0.04141945391893387,
      -0.07555758953094482,
      0.009397199377417564,
      0.016435403376817703,
      -0.016325166448950768,
      0.038715410977602005,
      -0.01494803186506033,
      0.013658253476023674,
      -0.06203042343258858,
      0.034769609570503235,
      -0.042655523866415024,
      0.04522695392370224,
      -0.024881843477487564,
      -0.004261417780071497,
      -0.13311488926410675,
      0.0675583928823471,
      0.044387590140104294,
      0.08194608986377716,
      0.01073465682566166,
      -0.04089652746915817,
      0.030004192143678665,
      0.01634717732667923,
      0.01799154467880726,
      -0.0483318492770195,
      -0.022387973964214325,
      -0.0458984375,
      0.04300087317824364,
      -0.06734444200992584,
      -0.023083992302417755,
      -0.014348296448588371,
      -0.04491357505321503,
      0.05812746658921242,
      0.02259797416627407
    ],
    [
      -0.03437471389770508,
      -0.026068974286317825,
      0.006299905478954315,
      -0.003874920541420579,
      -0.04936924949288368,
      -0.03178323060274124,
      -0.013513798825442791,
      -0.12875501811504364,
      -0.05940784513950348,
      -0.02535424567759037,
      -0.03004288859665394,
      0.025521665811538696,
      0.03765827417373657,
      0.02458725869655609,
      -0.07357343286275864,
      -0.04720207303762436,
      -0.04073965176939964,
      -0.04951372742652893,
      0.01742962934076786,
      0.07603662461042404,
      0.0690952017903328,
      0.02987406961619854,
      -0.042222779244184494,
      0.02124711126089096,
      -0.019678685814142227,
      0.05608809366822243,
      -0.05401123687624931,
      -0.10102880746126175,
      0.037849005311727524,
      -0.06936249881982803,
      -0.09248075634241104,
      -0.11544857919216156,
      -0.02449025586247444,
      -0.002138647949323058,
      -0.035494446754455566,
      0.046283233910799026,
      0.029377220198512077,
      0.0041261292062699795,
      -0.017391080036759377,
      0.08008091896772385,
      -0.07427725195884705,
      -0.04313569888472557,
      0.04630379006266594,
      -0.030581098049879074,
      -0.029802532866597176,
      -0.01566215418279171,
      -0.023481721058487892,
      -0.01686697080731392,
      0.00802522897720337,
      -0.03973624110221863,
      0.07636628299951553,
      -0.01392109040170908,
      -0.06460067629814148,
      -0.08500568568706512,
      0.004338263999670744,
      0.005045763682574034,
      0.007622160017490387,
      -0.0544859915971756,
      0.048022620379924774,
      -0.03003166802227497,
      -0.027956940233707428,
      0.06067745015025139,
      0.07267224788665771,
      -0.07917855679988861,
      0.06360480189323425,
      -0.02119547687470913,
      0.013165347278118134,
      -0.01990288682281971,
      -0.0008733216673135757,
      -0.011586972512304783,
      -0.0043183877132833,
      0.07394067943096161,
      0.031745802611112595,
      0.016387537121772766,
      -0.07787618041038513,
      0.056473154574632645,
      -0.07013023644685745,
      -0.035459212958812714,
      -0.07476148009300232,
      0.005374042317271233,
      -0.04519641771912575,
      0.020065905526280403,
      -0.07901425659656525,
      0.05836134031414986,
      0.03117830865085125,
      -0.08576086908578873,
      0.016542578116059303,
      -0.07267753779888153,
      -0.04800151661038399,
      0.03722091764211655,
      -0.0490289069712162,
      0.007862248457968235,
      0.07215908914804459,
      -0.03397178649902344,
      0.015983151271939278,
      -0.006435638293623924,
      -0.02694915235042572,
      -0.001899423310533166,
      0.03674015402793884,
      -0.0059963311068713665,
      0.01064542680978775,
      -0.0710272341966629,
      0.03649772331118584,
      -0.1112583801150322,
      0.01768743060529232,
      -0.016048163175582886,
      -0.033045463263988495,
      -0.07132328301668167,
      -0.018568269908428192,
      0.030612371861934662,
      -0.0290522538125515,
      0.06286615878343582,
      0.021661462262272835,
      0.048344697803258896,
      -0.001204489846713841,
      -0.0008157003903761506,
      0.0383092425763607,
      0.16456931829452515,
      0.006461442448198795,
      -0.004550577141344547,
      -0.04767337068915367,
      -0.0033244783990085125,
      0.01606137678027153,
      0.022084269672632217,
      -0.02411985956132412,
      -0.03185656666755676,
      -0.01169531885534525,
      -0.00053694675443694,
      -0.06476740539073944,
      -0.09330500662326813,
      0.07933545112609863,
      -0.007446588482707739,
      -0.03178548440337181,
      0.003586332779377699,
      -0.011185518465936184,
      -0.04646914452314377,
      -0.0046598115004599094,
      0.025789476931095123,
      -0.038185182958841324,
      0.007308381609618664,
      0.061004236340522766,
      0.010024291463196278,
      -0.02075202204287052,
      -0.05437026917934418,
      0.057155270129442215,
      -0.05524798482656479,
      -0.1144748404622078,
      0.012816076166927814,
      0.0400812141597271,
      0.0012985474895685911,
      0.04212991148233414,
      -0.03445500135421753,
      -0.0010610278695821762,
      -0.004936177749186754,
      -0.034512363374233246,
      0.011812456883490086,
      -0.031001422554254532,
      0.06091545894742012,
      -0.0522681288421154,
      0.004251898266375065,
      -0.06416662037372589,
      0.04209340736269951,
      0.008827703073620796,
      0.016358451917767525,
      -0.008530535735189915,
      -0.032920416444540024,
      -0.013595513999462128,
      0.010162824764847755,
      -0.04474632814526558,
      -0.01633460819721222,
      0.0480392649769783,
      0.053563084453344345,
      -0.00086808210471645,
      0.06541396677494049,
      -0.00569819426164031,
      -8.147372136591002e-05,
      0.015410738997161388,
      0.014253951609134674,
      -0.00800284557044506,
      0.03821604698896408,
      0.01741831563413143,
      0.02572683058679104,
      -0.02774328738451004,
      -0.04959558695554733,
      0.005465053021907806,
      0.03818806633353233,
      0.0025005144998431206,
      -0.012699105776846409,
      -0.012591064907610416,
      -0.027939461171627045,
      0.0033104438334703445,
      -0.029308196157217026,
      -0.013186090625822544,
      0.014713682234287262,
      0.0022680957335978746,
      -0.03631151467561722,
      0.010841540060937405,
      -0.0079275444149971,
      0.041275981813669205,
      0.058085620403289795,
      -0.07364942878484726,
      0.006120741367340088,
      -0.07373154908418655,
      0.0605083703994751,
      -0.05397390201687813,
      -0.003307502018287778,
      -0.024528607726097107,
      -0.017908500507473946,
      -0.026309942826628685,
      0.044716451317071915,
      -0.053538333624601364,
      -0.03739086166024208,
      -0.04243951290845871,
      -0.010395542718470097,
      0.057305511087179184,
      -0.059266384690999985,
      -0.1163080632686615,
      -0.00928246695548296,
      -0.08662466704845428,
      -0.02272423729300499,
      -0.0004796492285095155,
      0.034794386476278305,
      -0.043422162532806396,
      0.030074894428253174,
      -0.007274906616657972,
      -0.04462858662009239,
      -0.011384528130292892,
      0.017145846039056778,
      -0.013979206793010235,
      0.013972459360957146,
      -0.0029663846362382174,
      0.023666534572839737,
      0.02107181027531624,
      0.022139407694339752,
      -0.062054410576820374,
      -0.030140073969960213,
      0.08212656527757645,
      0.017068935558199883,
      0.04555957764387131,
      -0.004029989708214998,
      -0.03745900094509125,
      0.0137310940772295,
      0.047153670340776443,
      -9.864810999715701e-05,
      -0.04981641098856926,
      0.007931146770715714,
      -0.048094943165779114,
      0.07527339458465576,
      -0.014938645996153355,
      0.06107602268457413,
      0.1152268722653389,
      -0.034372661262750626,
      -0.012557601556181908,
      -0.036111973226070404,
      0.0029473293107002974,
      -0.03263724967837334,
      0.06493277102708817,
      0.04288695752620697,
      0.02745482325553894,
      -0.07975714653730392,
      -0.06530171632766724,
      0.06711232662200928,
      0.08971115946769714,
      -0.01228158175945282,
      0.044030409306287766,
      -0.053504832088947296,
      -0.03955360874533653,
      0.02394615299999714,
      -0.021082909777760506,
      0.021396111696958542,
      0.08629344403743744,
      0.06671365350484848,
      0.06694279611110687,
      0.0010153824696317315,
      -0.03848773613572121,
      0.00670285988599062,
      0.045704323798418045,
      -0.01554371602833271,
      -0.003141329623758793,
      0.04318733885884285,
      -0.08192708343267441,
      -0.013186602853238583,
      -0.03548397868871689,
      -0.07379146665334702,
      0.009681017138063908,
      0.06310588121414185,
      0.04700120911002159,
      0.07238604128360748,
      0.010532286018133163,
      0.014104210771620274,
      0.03167298808693886,
      -0.026535514742136,
      0.008547325618565083,
      0.0014425611589103937,
      -0.059013936668634415,
      0.03518543392419815,
      -0.0009164370130747557,
      -0.00801254902034998,
      -0.014527727849781513,
      -0.013356714509427547,
      -0.036692872643470764,
      -0.004739073570817709,
      -0.03849853202700615,
      0.02461395598948002,
      -0.012605573982000351,
      0.06997528672218323,
      0.10258139669895172,
      0.06561398506164551,
      0.06472761183977127,
      -0.028209727257490158,
      0.03555404394865036,
      0.0710219293832779,
      -0.03456922993063927,
      0.006882297806441784,
      -0.03928310051560402,
      -0.10645939409732819,
      0.049113642424345016,
      -0.014168959110975266,
      0.042421840131282806,
      -0.10913369059562683,
      -0.047483671456575394,
      0.05697914958000183,
      -0.05809515342116356,
      0.03487143665552139,
      -0.0068469881080091,
      -0.07007869333028793,
      -0.006215034984052181,
      0.03480818495154381,
      -0.014647825621068478,
      0.04427885264158249,
      0.02657255344092846,
      0.03222796693444252,
      0.039032820612192154,
      0.005287229549139738,
      0.04315545782446861,
      0.04593757912516594,
      0.0031226009596139193,
      0.006238388363271952,
      0.03546799719333649,
      -0.043583326041698456,
      0.01885448768734932,
      0.0019397232681512833,
      -0.030447086319327354,
      -0.016115624457597733,
      0.08700551092624664,
      0.041713982820510864,
      -0.07734984904527664,
      -0.03073551133275032,
      0.049505166709423065,
      -0.004398023243993521,
      -0.021088875830173492,
      -0.05249882489442825,
      0.010920020751655102,
      0.08220020681619644,
      -0.05938871204853058,
      -0.012193066999316216,
      0.05609576031565666,
      0.03975605219602585,
      0.019882265478372574,
      -0.010780660435557365,
      -0.0027997950091958046,
      -0.0602727010846138,
      0.02353855036199093,
      0.06019240990281105,
      0.08505470305681229,
      0.03390637785196304,
      0.0018741823732852936,
      0.037447404116392136,
      0.04583529010415077,
      0.14693494141101837,
      0.0362064503133297,
      -0.009000650607049465,
      0.036265160888433456,
      0.014865349046885967,
      -0.0108208442106843,
      0.07847725600004196,
      0.031087536364793777,
      0.002271475037559867,
      0.12333174794912338,
      -0.03175760433077812,
      -0.0613124780356884,
      0.025640862062573433,
      -0.11665961891412735,
      0.016567325219511986,
      0.05073632672429085,
      0.020249636843800545,
      -0.022948328405618668,
      -0.016926687210798264,
      0.019702835008502007,
      0.05052955448627472,
      -0.06038236245512962,
      -0.064857617020607,
      0.025410333648324013,
      0.03493288904428482,
      0.09041153639554977,
      -0.006738643627613783,
      -0.027563689276576042,
      0.03963122144341469,
      0.07559927552938461,
      0.03447730094194412,
      0.026707848533988,
      -0.007401620037853718,
      0.07637699693441391,
      0.06300903856754303,
      -0.03258639574050903,
      0.014289136976003647,
      0.05238845571875572,
      -0.05898868292570114,
      -0.03434204310178757,
      0.0036557589191943407,
      0.05010579526424408,
      0.037317514419555664,
      -0.09025640785694122,
      0.10809894651174545,
      -0.06131405755877495,
      -0.03226691484451294,
      0.0117670688778162,
      -0.004699154756963253,
      -0.018836889415979385,
      0.057707637548446655,
      -0.019332483410835266,
      -0.01993698440492153,
      -0.005063833203166723,
      -0.0109451524913311,
      0.056064315140247345,
      0.008454728871583939,
      0.041564393788576126,
      0.03091886080801487,
      0.10860414803028107,
      -0.027820585295557976,
      -0.010179789736866951,
      -0.10036278516054153,
      -0.11090699583292007,
      0.061477337032556534,
      -0.041187018156051636,
      0.06945351511240005,
      0.06344583630561829,
      -0.031500302255153656,
      -0.022674668580293655,
      -0.01824093610048294,
      0.02894856035709381,
      -0.01918499544262886,
      0.030544063076376915,
      -0.05356422811746597,
      -0.009695716202259064,
      0.04800907149910927,
      0.0965830534696579,
      0.010665591806173325,
      -0.10680016130208969,
      -0.014106331393122673,
      -0.10360579937696457,
      0.01978083699941635,
      0.049224305897951126,
      -0.011663008481264114,
      0.13484890758991241,
      0.0487096793949604,
      -0.02650170587003231,
      -0.07001391798257828,
      0.04917764663696289,
      0.0475371889770031,
      -0.07958871126174927,
      0.014198589138686657,
      0.037401217967271805,
      -0.045547131448984146,
      0.0380188450217247,
      0.014040912501513958,
      0.05072614178061485,
      -0.020200105383992195,
      0.02010711096227169,
      0.030985038727521896,
      0.05699047073721886,
      0.03881846368312836,
      0.016678517684340477,
      0.035808973014354706,
      0.008445842191576958,
      -0.034812819212675095,
      -0.07496527582406998,
      -0.04827002063393593,
      0.10481970757246017,
      -0.030570274218916893,
      0.08061066269874573,
      0.007232070900499821,
      0.02992515079677105,
      -0.06709178537130356,
      0.018467286601662636,
      0.00614650035277009,
      0.09776178747415543,
      -0.04444502294063568,
      -0.011701498180627823,
      -0.014758001081645489,
      -0.07828310877084732,
      0.00026224504108540714,
      -0.05841613933444023,
      -0.04816950485110283,
      0.020561208948493004,
      0.0796753466129303,
      -0.015401025302708149,
      0.04626136273145676,
      -0.023289727047085762,
      -0.03876865282654762,
      0.019526006653904915,
      -0.0150791360065341,
      -0.043289896100759506
    ],
    [
      0.006961730774492025,
      0.04536372423171997,
      -0.02988331764936447,
      0.013479909859597683,
      -0.0008751472923904657,
      0.012307604774832726,
      0.03733961284160614,
      -0.04456334933638573,
      -0.04095035791397095,
      -0.061523355543613434,
      -0.021073149517178535,
      0.018495099619030952,
      0.001484947046265006,
      -0.051738668233156204,
      0.04556669667363167,
      -0.013428357429802418,
      -0.09466319531202316,
      -0.023067926988005638,
      0.06390702724456787,
      0.006027024704962969,
      -0.015972726047039032,
      0.050969481468200684,
      0.07207926362752914,
      -0.03529517725110054,
      0.022770704701542854,
      0.031680088490247726,
      0.014936289750039577,
      0.007237494457513094,
      -0.04311065748333931,
      -0.04847230389714241,
      0.001006934093311429,
      0.024742435663938522,
      0.012489411979913712,
      0.016626907512545586,
      -0.02336748130619526,
      0.03074202686548233,
      -0.02375572733581066,
      -0.01504362840205431,
      0.04513053968548775,
      0.07631446421146393,
      -0.014502773061394691,
      0.021191079169511795,
      -0.05511742830276489,
      -0.02080760896205902,
      0.032488077878952026,
      0.0741281732916832,
      -0.02551000565290451,
      -0.013751691207289696,
      -0.0031073298305273056,
      -0.019257763400673866,
      0.013217488303780556,
      0.07963701337575912,
      -0.03720909357070923,
      0.002323721768334508,
      -0.002254508901387453,
      -0.04162268340587616,
      0.04992116615176201,
      0.03267368674278259,
      -0.0014627246418967843,
      -0.07996991276741028,
      0.09058471769094467,
      -0.047780148684978485,
      0.025658288970589638,
      -0.04009179398417473,
      0.01171395555138588,
      0.03568777069449425,
      0.048935212194919586,
      0.036339711397886276,
      -0.05887436866760254,
      0.07844755053520203,
      0.014635917730629444,
      0.004477448761463165,
      -0.017212050035595894,
      -0.05692170187830925,
      0.05315444618463516,
      -0.04350970685482025,
      -0.07639024406671524,
      -0.010381846688687801,
      -0.0068511962890625,
      -0.08937831223011017,
      -0.03693482652306557,
      0.03516082838177681,
      -0.050081174820661545,
      0.029631998389959335,
      0.03201911598443985,
      -0.005763323977589607,
      0.06633763760328293,
      0.030376333743333817,
      0.029241390526294708,
      -0.008649202063679695,
      -0.03738708794116974,
      -0.007517651189118624,
      0.06409583240747452,
      0.06553430110216141,
      -0.025401480495929718,
      -0.009070874191820621,
      -0.07980611175298691,
      0.04951019585132599,
      -0.08967529982328415,
      -0.026864878833293915,
      0.0782732218503952,
      -0.03035023994743824,
      0.04865816608071327,
      -0.04861407354474068,
      -0.008878227323293686,
      -0.02323395200073719,
      0.09367699921131134,
      -0.03172408789396286,
      -0.04018058255314827,
      0.08058857917785645,
      0.06744197756052017,
      -0.08943703025579453,
      0.07315243035554886,
      -0.02526945248246193,
      0.05124473571777344,
      0.04692932218313217,
      0.0008977341349236667,
      0.01821272261440754,
      0.008628454990684986,
      0.09110703319311142,
      0.032473914325237274,
      -0.024254795163869858,
      -0.015938561409711838,
      0.09954220801591873,
      0.0008891059551388025,
      0.021493762731552124,
      0.020784422755241394,
      -0.047503761947155,
      0.03350568935275078,
      -0.00867551937699318,
      0.004411837086081505,
      -0.07586827874183655,
      0.0024688709527254105,
      0.006897760089486837,
      -0.004765232093632221,
      0.07971909642219543,
      0.030363216996192932,
      0.040793586522340775,
      0.00921863503754139,
      -0.06656511127948761,
      0.04429885372519493,
      -0.10546859353780746,
      0.012568184174597263,
      0.02036142721772194,
      0.019875291734933853,
      -0.0344802662730217,
      0.011549380607903004,
      0.0013755582040175796,
      0.02349299006164074,
      0.013217977248132229,
      -0.01757832057774067,
      -0.0011747099924832582,
      0.016207847744226456,
      -0.022761864587664604,
      -0.030508849769830704,
      -0.0882946103811264,
      -0.040933091193437576,
      0.05784120038151741,
      -0.05076313391327858,
      0.039569709450006485,
      -0.06857280433177948,
      -0.001346222823485732,
      0.0010715133976191282,
      -0.04445584490895271,
      0.05770541727542877,
      -0.023746343329548836,
      0.003055145964026451,
      -0.02602897398173809,
      -0.012292010709643364,
      -0.07601554691791534,
      0.056767623871564865,
      0.0033708950504660606,
      -0.004853907041251659,
      -0.06422323733568192,
      0.03616848587989807,
      0.031835101544857025,
      0.05525386705994606,
      0.03397328034043312,
      0.013629400171339512,
      0.006538845598697662,
      0.01736774668097496,
      0.05168241634964943,
      0.028780989348888397,
      -0.08282852917909622,
      0.01748131588101387,
      0.06435870379209518,
      -0.006699416786432266,
      0.04874632880091667,
      -0.008150631561875343,
      -0.008111780509352684,
      -0.0653693899512291,
      -0.02338201180100441,
      0.009487170726060867,
      -0.01089128851890564,
      0.011222806759178638,
      0.054869458079338074,
      -0.005176393780857325,
      0.002328639617189765,
      -0.05177534371614456,
      -0.025066493079066277,
      0.041320595890283585,
      0.028780050575733185,
      0.05221010372042656,
      0.07815920561552048,
      -0.0017150120111182332,
      -0.0054284255020320415,
      -0.014594473876059055,
      -0.06457488983869553,
      0.020657921209931374,
      0.058398809283971786,
      0.04924899339675903,
      0.04190730303525925,
      0.00318832416087389,
      -0.03028920851647854,
      -0.03416180610656738,
      0.005185678135603666,
      0.047666314989328384,
      -0.025507329031825066,
      0.03176870197057724,
      0.030214637517929077,
      -0.04284285381436348,
      -0.005914048291742802,
      -0.01962410844862461,
      0.039084162563085556,
      -0.07635010033845901,
      0.06860796362161636,
      -0.04207732528448105,
      -0.05387968197464943,
      -0.029678596183657646,
      -0.040601737797260284,
      0.018894216045737267,
      0.09106804430484772,
      0.03587331995368004,
      -0.004799824208021164,
      0.013086287304759026,
      0.04773838073015213,
      0.027459347620606422,
      0.0008918902021832764,
      0.031070534139871597,
      0.05086331441998482,
      0.05247829481959343,
      -0.02442837879061699,
      0.013130241073668003,
      -0.0697799101471901,
      0.009238721802830696,
      0.04865764081478119,
      0.047559551894664764,
      0.005864906590431929,
      -0.07059217989444733,
      0.01744741201400757,
      0.05637567117810249,
      -0.01827559806406498,
      -0.0053676338866353035,
      0.02717701345682144,
      -0.035531193017959595,
      0.06814753264188766,
      0.08126062899827957,
      -0.03150908276438713,
      -0.016849840059876442,
      -0.01320512592792511,
      0.0705762729048729,
      0.01764213666319847,
      -0.021014973521232605,
      -0.005442617926746607,
      0.015045150183141232,
      0.04096287861466408,
      -0.011756974272429943,
      0.013441424816846848,
      -0.02215542644262314,
      0.03210849314928055,
      0.0007857044693082571,
      0.036760974675416946,
      -0.02212851122021675,
      2.0800562197109684e-05,
      0.08518585562705994,
      0.07479149848222733,
      0.006142984610050917,
      0.0012386597227305174,
      0.016917534172534943,
      0.02340790256857872,
      0.02937311679124832,
      0.030422460287809372,
      -0.020198576152324677,
      -0.009309111163020134,
      0.0635179802775383,
      0.05056208744645119,
      0.003192682983353734,
      -0.031263720244169235,
      -0.04156846925616264,
      0.06556738168001175,
      0.09449579566717148,
      0.03556368127465248,
      -0.03911014273762703,
      0.016378328204154968,
      -0.03131800889968872,
      0.03280143812298775,
      0.040342774242162704,
      0.02253156341612339,
      -0.06704629212617874,
      -0.06911823898553848,
      -0.02408384159207344,
      0.009417524561285973,
      0.001071749022230506,
      -0.005458026193082333,
      0.07232623547315598,
      -0.033715762197971344,
      -0.026582207530736923,
      0.06374211609363556,
      0.018246741965413094,
      0.07260998338460922,
      0.05229233577847481,
      0.016318509355187416,
      0.019132548943161964,
      -0.031623225659132004,
      -0.003133087418973446,
      0.03492036461830139,
      -0.01982087269425392,
      -0.009022827260196209,
      0.059645768254995346,
      -0.01963852159678936,
      -0.0016458423342555761,
      -0.0049852593801915646,
      -0.003456811187788844,
      -0.0075796227902174,
      0.026657888665795326,
      0.012760044075548649,
      -0.06779475510120392,
      -0.04732145741581917,
      0.0017125871963799,
      -0.10705595463514328,
      -0.08153291791677475,
      -0.057996537536382675,
      -0.024459416046738625,
      0.05689169839024544,
      -0.03749890625476837,
      0.018817316740751266,
      -0.030560700222849846,
      -0.0307721309363842,
      0.022392334416508675,
      0.04592251405119896,
      0.0956219881772995,
      0.03147324174642563,
      -0.0684160590171814,
      -0.033706922084093094,
      0.006123069208115339,
      -0.027855847030878067,
      0.029108213260769844,
      0.002957896562293172,
      -0.031276632100343704,
      -0.023154977709054947,
      -0.02908959612250328,
      -0.016527941450476646,
      -0.02791651152074337,
      -0.028036199510097504,
      -0.025126738473773003,
      -0.04869648069143295,
      -0.008607600815594196,
      -0.026841536164283752,
      -0.023541389033198357,
      0.004197483416646719,
      0.008391516283154488,
      -0.03793676570057869,
      0.013379069045186043,
      0.038943808525800705,
      0.046273574233055115,
      -0.04698137193918228,
      -0.036329321563243866,
      0.009059391915798187,
      0.0013398390728980303,
      -0.02496369183063507,
      -0.012955274432897568,
      -0.08701546490192413,
      -0.01373485941439867,
      -0.05926293507218361,
      -0.06348077952861786,
      0.06870301812887192,
      -0.04681519418954849,
      -0.04623294249176979,
      -0.020889507606625557,
      -0.0796142965555191,
      0.008000415749847889,
      0.009922260418534279,
      0.15336352586746216,
      0.05336686968803406,
      0.027701370418071747,
      -0.048254210501909256,
      -0.03255965933203697,
      -0.0531848706305027,
      0.10968952625989914,
      0.09204558283090591,
      0.07868048548698425,
      -0.011190610006451607,
      -0.0688406378030777,
      0.05559241771697998,
      -0.027882663533091545,
      0.029794570058584213,
      -0.011399931274354458,
      -0.06786419451236725,
      0.0298995953053236,
      0.0007095505716279149,
      0.008015528321266174,
      0.012162826955318451,
      -0.03543372079730034,
      -0.0647168830037117,
      -0.03500605747103691,
      0.05652117729187012,
      0.0246896930038929,
      -0.044693198055028915,
      0.020157698541879654,
      0.04307742789387703,
      -0.014156538993120193,
      0.04139965400099754,
      -0.01038970798254013,
      -0.06103960797190666,
      -0.02264685183763504,
      -0.0586232990026474,
      0.05135689303278923,
      0.033183932304382324,
      0.03209780529141426,
      -0.03775016963481903,
      0.023356249555945396,
      0.026553163304924965,
      0.04861628636717796,
      -0.007919666357338428,
      -0.0055651976726949215,
      -0.0152265764772892,
      -0.04143085703253746,
      -0.06588176637887955,
      -0.0618935152888298,
      -0.018889768049120903,
      0.007402647752314806,
      -0.05960218980908394,
      -0.01850571483373642,
      -0.03989535570144653,
      0.023183781653642654,
      -0.03111647255718708,
      0.06639470905065536,
      0.047663696110248566,
      -0.0721728652715683,
      -0.02893548086285591,
      0.01726086437702179,
      -0.052039213478565216,
      -0.011456083506345749,
      -0.04300340637564659,
      0.037472184747457504,
      0.02971475012600422,
      0.014294682070612907,
      0.07622864097356796,
      -0.056660547852516174,
      -0.01792287454009056,
      -0.03656471148133278,
      0.06152946874499321,
      0.08925610035657883,
      -0.01830388605594635,
      0.07105248421430588,
      -0.04207272455096245,
      0.005885744467377663,
      0.043640028685331345,
      0.006389561574906111,
      -0.04034113138914108,
      -0.010326030664145947,
      -0.038189876824617386,
      0.006132966373115778,
      0.03797692060470581,
      -0.02435474470257759,
      -0.10240938514471054,
      -0.07088402658700943,
      -0.07939311116933823,
      0.07719951868057251,
      0.021158630028367043,
      -0.026274049654603004,
      0.005792612209916115,
      -0.03531466796994209,
      0.019127875566482544,
      0.046373818069696426,
      0.030880063772201538,
      -0.027348719537258148,
      0.07291256636381149,
      0.004309841897338629,
      0.01599227450788021,
      0.025674572214484215,
      0.01192727405577898,
      -0.0496179461479187,
      0.012548927217721939,
      0.012359757907688618,
      -0.04182847589254379,
      0.004596075974404812,
      -0.01370275765657425,
      0.02770805172622204,
      -0.02121352031826973,
      -0.01063096895813942,
      0.0006461794837377965,
      -0.03376604989171028,
      -0.004748224746435881,
      -0.01242514792829752,
      0.08290940523147583,
      -0.08631929755210876,
      0.0030060168355703354,
      0.11023062467575073,
      -0.010599580593407154,
      -0.05270873382687569,
      -0.07580757886171341,
      0.01468605361878872
    ],
    [
      0.04095084220170975,
      0.016605885699391365,
      0.08158572763204575,
      0.09095074981451035,
      0.0030168823432177305,
      -0.04880645498633385,
      -0.01765025034546852,
      0.0011496058432385325,
      0.031950660049915314,
      -0.028892509639263153,
      -0.027321351692080498,
      0.04368702694773674,
      0.006413378287106752,
      -0.11584969609975815,
      -0.02176859602332115,
      0.02154502645134926,
      -0.037916626781225204,
      -0.0007812390103936195,
      -0.007182910107076168,
      0.057564616203308105,
      -0.03167509660124779,
      0.006539338734000921,
      0.050503719598054886,
      0.09082797914743423,
      -0.004020916763693094,
      -0.018591919913887978,
      -0.033397067338228226,
      -0.02082301862537861,
      0.10155682265758514,
      -0.01817937195301056,
      0.03925924003124237,
      -0.02126539684832096,
      -0.050924137234687805,
      0.046104758977890015,
      0.03716462850570679,
      0.008298361673951149,
      0.10879606753587723,
      0.023268699645996094,
      0.013543488457798958,
      -0.008001534268260002,
      -0.011988339014351368,
      0.013706373050808907,
      0.07475543767213821,
      -0.06686679273843765,
      -0.0002622445463202894,
      0.028467627242207527,
      -0.0007070709834806621,
      -0.013459477573633194,
      0.014253280125558376,
      0.024470046162605286,
      0.02908080443739891,
      -0.019883010536432266,
      -0.020001618191599846,
      0.05358346924185753,
      0.027875304222106934,
      -0.04904629662632942,
      -0.018623240292072296,
      0.01074960920959711,
      0.062294259667396545,
      0.06017341837286949,
      0.052579138427972794,
      -0.019771207123994827,
      -0.020876049995422363,
      -0.07697224617004395,
      0.1035401001572609,
      -0.011562197469174862,
      -0.009377696551382542,
      -0.004685092251747847,
      0.01227894239127636,
      0.008784477598965168,
      -0.02073441632091999,
      0.061853133141994476,
      0.049461036920547485,
      -0.020533835515379906,
      -0.009290874004364014,
      0.02419273555278778,
      -0.021087970584630966,
      0.06768964231014252,
      -0.02153906039893627,
      0.022215144708752632,
      0.029295317828655243,
      0.05969315767288208,
      0.062317878007888794,
      -0.05383525788784027,
      0.06723369657993317,
      0.03192959725856781,
      -0.013849969021975994,
      0.035634297877550125,
      0.05248582735657692,
      -0.03839106485247612,
      0.04237860068678856,
      0.008237413130700588,
      0.021495552733540535,
      0.01832483895123005,
      -0.045288361608982086,
      0.08188878744840622,
      -0.020850894972682,
      0.03986227139830589,
      -0.08669699728488922,
      -0.11345738917589188,
      -0.05327170342206955,
      -0.03000079095363617,
      0.06854754686355591,
      -0.07306647300720215,
      -0.06606956571340561,
      0.018581969663500786,
      -0.08511123806238174,
      0.06717120110988617,
      -0.015106460079550743,
      0.03224306181073189,
      -0.01797359809279442,
      -0.029588473960757256,
      -0.04375078156590462,
      -0.022797446697950363,
      -0.05877657234668732,
      0.0037831319496035576,
      -0.013245895504951477,
      -0.024057764559984207,
      -0.0386992022395134,
      -0.03094046749174595,
      -0.0490167997777462,
      0.0019650303293019533,
      0.028837144374847412,
      0.0043875714763998985,
      0.02711888961493969,
      -0.0009468919015489519,
      -0.03518056124448776,
      0.020826108753681183,
      -0.04109521210193634,
      -0.00516554107889533,
      0.039518292993307114,
      -0.04532811790704727,
      -0.07174194604158401,
      0.015599813312292099,
      -0.03332888334989548,
      0.011517500504851341,
      -0.09387480467557907,
      0.05520843714475632,
      0.014968081377446651,
      -0.05048135295510292,
      -0.1001785472035408,
      -0.00400299858301878,
      -0.0013720347778871655,
      -0.018174417316913605,
      -0.018296224996447563,
      -0.007721513044089079,
      -0.003669227007776499,
      0.023849880322813988,
      -0.024019755423069,
      0.14092308282852173,
      -0.06803736090660095,
      -0.05345911905169487,
      0.0557880625128746,
      -0.09507725387811661,
      -0.008881260640919209,
      0.08043553680181503,
      -0.03221670910716057,
      0.0031734341755509377,
      -0.011470260098576546,
      -0.02040826715528965,
      -0.005223104730248451,
      0.013899240642786026,
      -0.01857997104525566,
      -0.048749931156635284,
      0.023483354598283768,
      0.010444087907671928,
      -0.016507087275385857,
      0.03009066730737686,
      -0.04781392961740494,
      -0.03573814406991005,
      -0.0023959027603268623,
      -0.035851024091243744,
      0.13148626685142517,
      -0.022308271378278732,
      0.0025629575829952955,
      0.007442945148795843,
      0.0252545028924942,
      0.01824558712542057,
      0.10777029395103455,
      -0.06680070608854294,
      -0.009750887751579285,
      -0.010895919986069202,
      -0.05770086497068405,
      -0.025539575144648552,
      -0.011528833769261837,
      0.06284032016992569,
      0.03355943411588669,
      -0.008923071436583996,
      -0.03245949745178223,
      0.04192794859409332,
      0.03158778324723244,
      -0.050779324024915695,
      -7.928792183520272e-05,
      -0.03788623958826065,
      -0.05943046137690544,
      -0.0326409786939621,
      -0.004376541823148727,
      0.00918907392770052,
      -0.013204331509768963,
      0.0468490906059742,
      0.05621593818068504,
      -0.07105180621147156,
      -0.011142847128212452,
      0.004044358152896166,
      -0.05971160903573036,
      0.07554451376199722,
      -0.03767385333776474,
      -0.0016112420707941055,
      -0.00364218489266932,
      -0.03487823158502579,
      0.07591772079467773,
      -0.009303422644734383,
      -0.003087868681177497,
      0.049042798578739166,
      0.062169428914785385,
      -0.04508410021662712,
      0.05815013498067856,
      0.0031326566822826862,
      -0.08228588849306107,
      -0.03316360339522362,
      -0.03673674166202545,
      -0.03868662938475609,
      -0.048093754798173904,
      0.058171406388282776,
      0.026117879897356033,
      0.08310576528310776,
      0.013701134361326694,
      0.04828956350684166,
      -0.006263959687203169,
      0.05463910102844238,
      -0.01412974949926138,
      -0.00508147431537509,
      0.02919715642929077,
      0.011796996928751469,
      -0.0018984676571562886,
      0.011575155891478062,
      -0.0834687128663063,
      -0.034450776875019073,
      -0.011970685794949532,
      -0.10839012265205383,
      -0.06715339422225952,
      -0.004411361180245876,
      -0.020748578011989594,
      0.007867462933063507,
      -0.022038238123059273,
      0.007959519512951374,
      -0.11100823432207108,
      0.01795234903693199,
      -0.002081609098240733,
      -0.03574978932738304,
      0.0017375447787344456,
      0.01680370792746544,
      -0.0009567034430801868,
      -0.07484349608421326,
      -0.006863841786980629,
      0.08628237247467041,
      -0.02663113735616207,
      0.013312829658389091,
      -0.06738097220659256,
      -0.04721389710903168,
      -0.05439087375998497,
      0.0323982909321785,
      0.02487533539533615,
      -0.03778953105211258,
      0.12459348887205124,
      -0.09319047629833221,
      -0.019499583169817924,
      0.003177737584337592,
      -0.10498546808958054,
      0.01225037220865488,
      0.10285771638154984,
      0.07388562709093094,
      0.015812864527106285,
      -0.029126154258847237,
      -0.02359168790280819,
      0.023528581485152245,
      0.07033351808786392,
      -0.021095477044582367,
      -0.010328073985874653,
      0.0049611604772508144,
      -0.0014591310173273087,
      -0.02448711171746254,
      -0.012850080616772175,
      0.016889095306396484,
      0.008886978961527348,
      -0.016020184382796288,
      -0.028991226106882095,
      0.007023249287158251,
      0.052245792001485825,
      0.029327861964702606,
      0.017000019550323486,
      0.07699581980705261,
      -0.027266237884759903,
      0.006417065393179655,
      0.005602864548563957,
      -0.02831071801483631,
      -0.017840998247265816,
      -0.042091820389032364,
      0.01856123097240925,
      -0.0759771466255188,
      0.04552273452281952,
      0.016348345205187798,
      -0.032382067292928696,
      -0.013947686180472374,
      0.0005049302126280963,
      0.016586605459451675,
      -0.05219466611742973,
      0.0017420238582417369,
      -0.01406123861670494,
      0.0676191970705986,
      -0.03726242855191231,
      -0.004670707508921623,
      0.03959909453988075,
      0.039221663028001785,
      0.05615146830677986,
      0.06443679332733154,
      0.045402709394693375,
      -0.039803486317396164,
      -0.008960646577179432,
      0.021453751251101494,
      0.03469004109501839,
      -0.017735380679368973,
      0.042381517589092255,
      -0.009138539433479309,
      -0.02354537695646286,
      0.0026264050975441933,
      0.0036321990191936493,
      -0.009767619892954826,
      0.0026058179792016745,
      -0.03298609331250191,
      -0.013721039518713951,
      -0.03799274563789368,
      -0.01657763309776783,
      0.032478123903274536,
      -0.05697863921523094,
      -0.05020137131214142,
      -0.05038212239742279,
      0.07731286436319351,
      -0.0480763278901577,
      0.02381802536547184,
      0.014249934814870358,
      -0.007518590893596411,
      0.02710188739001751,
      -0.06686075031757355,
      -0.025536105036735535,
      -0.004198986105620861,
      0.00359293888323009,
      -0.0293907281011343,
      -0.03351998329162598,
      -0.011909129098057747,
      0.04646817222237587,
      0.006813838612288237,
      -0.019231338053941727,
      0.06269580125808716,
      -0.03917986899614334,
      -0.038835618644952774,
      0.011849833652377129,
      0.08196800202131271,
      0.0010997761273756623,
      -0.0499541237950325,
      -0.048113275319337845,
      -0.05211816355586052,
      -0.0665142759680748,
      0.029904020950198174,
      0.025804417207837105,
      -0.0076656220480799675,
      -0.021747836843132973,
      -0.005065111443400383,
      -0.0007602726691402495,
      0.028511470183730125,
      -0.021690549328923225,
      0.027165167033672333,
      0.011421088129281998,
      -0.033701375126838684,
      -0.027451517060399055,
      0.01853835955262184,
      -0.05493147671222687,
      0.05556104704737663,
      0.05270220711827278,
      -0.030563147738575935,
      0.053142547607421875,
      -0.04600150138139725,
      -0.06092340499162674,
      0.03710315749049187,
      -0.007348435930907726,
      0.013875954784452915,
      0.006899205036461353,
      -0.004497883375734091,
      0.011152385734021664,
      -0.06949974596500397,
      0.03893117606639862,
      -0.027581367641687393,
      0.018461963161826134,
      0.021797671914100647,
      0.016599243506789207,
      -0.01969924382865429,
      -0.036266472190618515,
      0.15320606529712677,
      -0.023002244532108307,
      -0.028390338644385338,
      -0.014729210175573826,
      -0.10669384151697159,
      0.012986420653760433,
      0.02246188372373581,
      0.09300465881824493,
      0.01986798830330372,
      0.07056234776973724,
      0.0017796835163608193,
      -0.01122494786977768,
      -0.0176098570227623,
      0.054225023835897446,
      -0.02497069723904133,
      0.08421584963798523,
      -0.06721417605876923,
      0.002811780897900462,
      -0.013249216601252556,
      -0.001454342040233314,
      -0.060641348361968994,
      0.09317722916603088,
      -0.01861070841550827,
      -0.058929119259119034,
      -0.04769417271018028,
      -0.04606553167104721,
      -0.004758447874337435,
      0.018291160464286804,
      -0.08843336999416351,
      0.03286735713481903,
      0.051690924912691116,
      -0.08489008992910385,
      -0.036521799862384796,
      -0.014584872871637344,
      -0.012025993317365646,
      -0.008376352488994598,
      -0.01657865196466446,
      -0.050973836332559586,
      -0.0011759715853258967,
      -0.04850601777434349,
      0.037797026336193085,
      -0.009816867299377918,
      0.11053916811943054,
      -0.0016242126002907753,
      -0.032962024211883545,
      0.014049896970391273,
      0.020008686929941177,
      0.04496392607688904,
      -0.05226860195398331,
      -0.020700037479400635,
      0.03789813071489334,
      -0.0012620388297364116,
      0.0984773337841034,
      -0.033815719187259674,
      -0.01664683222770691,
      -0.0646066889166832,
      0.017129847779870033,
      0.015776770189404488,
      0.04808834567666054,
      -0.02929794415831566,
      0.007820555940270424,
      -0.06751774251461029,
      -0.016307422891259193,
      0.059476763010025024,
      0.016527526080608368,
      -0.03039088100194931,
      -0.03015153668820858,
      -0.0813480094075203,
      0.07461073249578476,
      0.044486548751592636,
      0.08047256618738174,
      -0.02417917177081108,
      0.0074821943417191505,
      -0.032907333225011826,
      0.051516465842723846,
      0.0022416901774704456,
      -0.0054965247400105,
      -0.02328069508075714,
      -0.07818024605512619,
      0.0014386661350727081,
      -0.058403078466653824,
      0.025986190885305405,
      -0.0878545269370079,
      -0.05304611101746559,
      -0.034994278103113174,
      -0.0395195372402668,
      0.03798501938581467,
      -0.005333034787327051,
      0.007764718029648066,
      0.003223184496164322,
      -0.019188864156603813,
      -0.03616710007190704,
      -0.008018500171601772,
      0.07674846053123474,
      0.05855941027402878,
      0.07106529176235199,
      -0.05550329014658928,
      0.0026183200534433126,
      0.023192306980490685,
      0.007736145984381437,
      -0.038141049444675446,
      -0.0685189738869667,
      -0.008906889706850052,
      -0.05102822557091713,
      0.05397951975464821,
      -0.026822896674275398
    ],
    [
      -0.0031402776949107647,
      0.041209135204553604,
      -0.08029340952634811,
      -0.031616032123565674,
      0.11875736713409424,
      -0.009399437345564365,
      0.016614548861980438,
      0.01032180991023779,
      -0.007592224050313234,
      -0.030775683000683784,
      -0.10947860777378082,
      -0.022390669211745262,
      -0.00415155291557312,
      0.08504358679056168,
      0.049321722239255905,
      0.0020133780781179667,
      0.028049007058143616,
      0.00042742170626297593,
      -0.026846004649996758,
      0.13262464106082916,
      -0.030767938122153282,
      0.006472052540630102,
      -0.03741183504462242,
      -0.04708942025899887,
      -0.043126318603754044,
      -0.02105538547039032,
      -0.0055775814689695835,
      0.01926976442337036,
      0.02689477801322937,
      0.029413199052214622,
      -0.0028523006476461887,
      0.01982606202363968,
      -0.04873788356781006,
      0.03704194352030754,
      -0.04832923784852028,
      -0.06150534749031067,
      -0.01945265755057335,
      0.021747170016169548,
      -0.04066462442278862,
      0.021692654117941856,
      -0.06673090159893036,
      -0.0837504118680954,
      -0.02263667620718479,
      -0.10417838394641876,
      0.04566953703761101,
      -0.017306171357631683,
      0.09039686620235443,
      -0.015843449160456657,
      -0.02861037105321884,
      -0.027118949219584465,
      0.023124221712350845,
      -0.03415951505303383,
      0.058656685054302216,
      -0.07456298917531967,
      -0.016493741422891617,
      -0.06849327683448792,
      0.04060432314872742,
      0.03918591886758804,
      -0.03696899861097336,
      -0.014600151218473911,
      -0.02846705913543701,
      -0.03098348155617714,
      -5.2247294661356136e-05,
      -0.021251996979117393,
      -0.011949840001761913,
      0.09247595071792603,
      0.1219061017036438,
      -0.0010551318991929293,
      0.02899271808564663,
      -0.04126136004924774,
      -0.044755518436431885,
      -0.03141146898269653,
      -0.0008739202166907489,
      0.012174258939921856,
      -0.034762561321258545,
      -0.033072009682655334,
      -0.04144406318664551,
      0.055006612092256546,
      -0.02105378918349743,
      0.04258866608142853,
      0.08876841515302658,
      -0.05072992667555809,
      -0.04009954631328583,
      -0.02118171751499176,
      -0.026013903319835663,
      -0.02337600104510784,
      0.0032547602895647287,
      -0.01726504974067211,
      -0.031018370762467384,
      0.0032671077642589808,
      0.010381200350821018,
      -0.025594981387257576,
      0.09775024652481079,
      -0.021984152495861053,
      0.021531332284212112,
      -0.004740831907838583,
      0.034159377217292786,
      0.023296009749174118,
      -0.015121215023100376,
      -0.021648120135068893,
      -0.04254797473549843,
      0.04066045582294464,
      0.030619075521826744,
      -0.01995875872671604,
      0.028457840904593468,
      -0.033745117485523224,
      0.0015829905169084668,
      0.008771945722401142,
      -0.03302402421832085,
      -0.04364635795354843,
      -0.02538486011326313,
      -0.06958669424057007,
      0.040440451353788376,
      0.02444305457174778,
      0.07720140367746353,
      -0.054550494998693466,
      0.06202445179224014,
      -0.0855879858136177,
      -0.03661922365427017,
      0.03739820420742035,
      0.09356004744768143,
      -0.016944926232099533,
      0.06885743141174316,
      0.02067163959145546,
      0.008006316609680653,
      -0.05429183319211006,
      -0.010477262549102306,
      0.07355975359678268,
      0.04892362654209137,
      -0.0021742996759712696,
      0.03241434693336487,
      -0.0039220708422362804,
      0.03097521699965,
      -0.04751727730035782,
      0.029623381793498993,
      0.004563318565487862,
      0.050730057060718536,
      0.017924493178725243,
      0.045934613794088364,
      -0.06212355196475983,
      0.03279951959848404,
      -0.04453584924340248,
      0.0007376860594376922,
      -0.11574333906173706,
      -0.09602298587560654,
      0.05800392106175423,
      -0.035187963396310806,
      0.036466699093580246,
      0.050052709877491,
      -0.003352625062689185,
      -0.007151954807341099,
      0.027043187990784645,
      0.08942145854234695,
      0.0381050780415535,
      0.015093589201569557,
      0.07043362408876419,
      0.06664039194583893,
      -0.005270879250019789,
      -0.007393940351903439,
      -0.038647495210170746,
      0.019128447398543358,
      0.09145468473434448,
      0.05559121444821358,
      -0.07091721892356873,
      -0.033815525472164154,
      -0.010046707466244698,
      -0.005770012270659208,
      -0.01759280078113079,
      -0.030733251944184303,
      0.03804429993033409,
      0.010715959593653679,
      -0.047387219965457916,
      0.011093877255916595,
      -0.006920242216438055,
      0.030740568414330482,
      -0.035696566104888916,
      0.023421136662364006,
      -0.046093668788671494,
      0.013850564137101173,
      0.017384828999638557,
      -0.01814613677561283,
      -0.08053632825613022,
      -0.07465585321187973,
      0.020674144849181175,
      -0.07251807302236557,
      -0.023577973246574402,
      0.04565679281949997,
      -0.06805767863988876,
      -0.027957521378993988,
      0.0593256801366806,
      0.057506728917360306,
      -0.006775981746613979,
      -0.04300662502646446,
      -0.005742416251450777,
      0.10399063676595688,
      -0.04342621564865112,
      0.033973753452301025,
      -0.034466810524463654,
      0.022561823949217796,
      0.08933126926422119,
      -0.05040202662348747,
      0.012004490941762924,
      0.019028281792998314,
      -0.0073964642360806465,
      0.056420713663101196,
      0.028142550960183144,
      -0.10058367997407913,
      0.013144545257091522,
      0.020490024238824844,
      0.0009854945819824934,
      0.07287819683551788,
      0.021447980776429176,
      0.07442186772823334,
      0.023005817085504532,
      -0.004613648168742657,
      -0.02207687497138977,
      -0.023445717990398407,
      0.07302837818861008,
      0.09077286720275879,
      -0.016200650483369827,
      0.030802028253674507,
      0.044911809265613556,
      -0.03164079785346985,
      0.06488490104675293,
      -0.043105583637952805,
      -0.08096937835216522,
      0.07185478508472443,
      0.044419750571250916,
      0.005955062806606293,
      -0.02603856287896633,
      -0.0617394857108593,
      0.08826985210180283,
      -0.005888351239264011,
      -0.06367708742618561,
      0.027423884719610214,
      0.050010260194540024,
      0.04255562648177147,
      0.008115370757877827,
      0.07312232255935669,
      -0.014767407439649105,
      -0.08656413853168488,
      -0.03800190985202789,
      0.010500055737793446,
      0.05640944093465805,
      -0.008342369459569454,
      0.028493259102106094,
      0.030167624354362488,
      -0.0523047149181366,
      -0.02905074506998062,
      -0.05581476166844368,
      0.03780866414308548,
      0.05758136883378029,
      0.01347548607736826,
      -0.054880909621715546,
      0.08373749256134033,
      0.041962940245866776,
      0.03458487242460251,
      0.009964260272681713,
      -0.05593609809875488,
      -0.00924255233258009,
      -0.024421393871307373,
      -0.004201246425509453,
      0.03937625139951706,
      -0.06571953743696213,
      -0.06282336264848709,
      -0.024754943326115608,
      0.02943030185997486,
      -0.03639540076255798,
      0.02357492782175541,
      0.084727443754673,
      -0.014263511635363102,
      -0.020924702286720276,
      -0.04452749714255333,
      -0.02279370278120041,
      0.04552215710282326,
      0.016586782410740852,
      -0.06570804864168167,
      -0.038258761167526245,
      0.04668182134628296,
      0.032852448523044586,
      -0.011111756786704063,
      -0.020360082387924194,
      -0.02750217542052269,
      -0.0070113008841872215,
      -0.03673310950398445,
      0.12534983456134796,
      -0.08697307109832764,
      -0.003444272093474865,
      0.02251998521387577,
      -0.043662622570991516,
      0.010464942082762718,
      0.04273202642798424,
      -0.027171343564987183,
      0.03676691651344299,
      0.10054608434438705,
      0.03960758075118065,
      0.019158463925123215,
      -0.09055356681346893,
      0.0023170451167970896,
      0.09725792706012726,
      0.015161440707743168,
      0.023436948657035828,
      0.08947604894638062,
      0.010125311091542244,
      -0.03871477022767067,
      0.06476182490587234,
      -0.05335714668035507,
      0.017802299931645393,
      0.10178782790899277,
      -0.02019069716334343,
      -0.07853914052248001,
      -0.0012606956297531724,
      0.025451641529798508,
      0.007218559738248587,
      0.10225306451320648,
      0.07774264365434647,
      0.029164547100663185,
      0.017562560737133026,
      0.023397091776132584,
      0.006541713606566191,
      -0.005707514937967062,
      -0.04042878746986389,
      -0.056387197226285934,
      -0.02679428458213806,
      0.08933817595243454,
      0.03872630000114441,
      0.04955080896615982,
      0.08274348825216293,
      -0.05195214971899986,
      -0.0164765827357769,
      -0.007331685163080692,
      -0.055266737937927246,
      0.06848975270986557,
      -0.050802648067474365,
      0.010345053859055042,
      0.0005796287441626191,
      -0.00015550701937172562,
      0.04347115755081177,
      -0.06557045876979828,
      0.012056396342813969,
      -0.0432828851044178,
      -0.034104619175195694,
      -0.05165044963359833,
      -0.04999084025621414,
      -0.0962194949388504,
      -0.06065002456307411,
      -0.024156790226697922,
      0.03791763633489609,
      -0.02570611983537674,
      0.011510263197124004,
      0.0032730945385992527,
      0.005013332236558199,
      -0.012802788987755775,
      0.02889072149991989,
      -0.025626815855503082,
      -0.027396531775593758,
      -0.0652378499507904,
      0.009206469170749187,
      0.057264067232608795,
      -0.01924239471554756,
      -0.08397534489631653,
      -0.009445392526686192,
      0.0014790737768635154,
      -0.04424852505326271,
      0.020277198404073715,
      -0.007632393389940262,
      0.11580724269151688,
      0.061773743480443954,
      -0.0236654132604599,
      0.008896715939044952,
      -0.0016139392973855138,
      -0.03129107505083084,
      0.06353966146707535,
      -0.05573812872171402,
      0.025629421696066856,
      -0.041008006781339645,
      -0.018368015065789223,
      0.07148147374391556,
      -0.07230856269598007,
      0.0714571550488472,
      -0.07004565745592117,
      0.01844935677945614,
      -0.01919933408498764,
      0.02568812482059002,
      -0.0405561737716198,
      -0.03809317946434021,
      -0.022967344149947166,
      -0.008228486403822899,
      0.034859053790569305,
      0.019451498985290527,
      -0.01067817397415638,
      -0.047430697828531265,
      -0.003779651829972863,
      0.05934135243296623,
      -0.013297708705067635,
      -0.031494591385126114,
      -0.029215550050139427,
      -0.013532008975744247,
      0.02478954941034317,
      0.013238037936389446,
      -0.049900684505701065,
      -0.08328614383935928,
      -0.036345165222883224,
      0.10295602679252625,
      0.02550898678600788,
      -0.03663316369056702,
      -0.018419483676552773,
      -0.06658854335546494,
      -0.01588601991534233,
      -0.05045454949140549,
      0.056243136525154114,
      -0.044669754803180695,
      0.013218961656093597,
      0.012550311163067818,
      -0.03149525821208954,
      -0.03110777959227562,
      0.07495559006929398,
      -0.013807868584990501,
      0.022978438064455986,
      0.031191233545541763,
      -0.03292687609791756,
      0.00517561798915267,
      0.06981100142002106,
      -0.05034760385751724,
      -0.047520723193883896,
      0.0075126634910702705,
      0.02780904620885849,
      -0.08483166992664337,
      -0.03337699547410011,
      -0.00495130056515336,
      -0.034589529037475586,
      0.09174735844135284,
      -0.010708912275731564,
      0.03974597901105881,
      -0.007369528524577618,
      -0.05219258368015289,
      0.02598855271935463,
      -0.019385818392038345,
      -0.0029770357068628073,
      -0.014670202508568764,
      0.04136115685105324,
      0.017436804249882698,
      0.006264253053814173,
      0.009433251805603504,
      -0.01503882184624672,
      0.05005805194377899,
      0.028290707617998123,
      0.03524332493543625,
      0.008734663017094135,
      0.008261526003479958,
      0.024019569158554077,
      0.005191195756196976,
      0.027943948283791542,
      -0.013009016402065754,
      -0.08065371960401535,
      -0.01212717592716217,
      -0.01743783801794052,
      -0.017398105934262276,
      -0.058982476592063904,
      -0.077394038438797,
      -0.013977840542793274,
      0.031249430030584335,
      0.04488517716526985,
      -0.0715528130531311,
      -0.05175202712416649,
      0.05098811909556389,
      0.06578688323497772,
      0.001662774826399982,
      0.03053239732980728,
      -0.07544127851724625,
      0.10540945827960968,
      -0.13546772301197052,
      0.016681760549545288,
      -0.012975250370800495,
      -0.031293392181396484,
      0.0018398571992293,
      -0.029872676357626915,
      -0.03527427092194557,
      -0.031026750802993774,
      -0.04400140792131424,
      -0.04193539917469025,
      0.03299476206302643,
      0.026110228151082993,
      0.025048885494470596,
      0.06615518778562546,
      0.05585677921772003,
      -0.01232081651687622,
      -0.00553167937323451,
      -0.08396568149328232,
      0.029988372698426247,
      0.07138478755950928,
      -0.040257953107357025,
      -0.12118978053331375,
      0.06783322244882584,
      0.013108400627970695,
      0.08539693057537079,
      -0.022394996136426926,
      -0.09045037627220154,
      -0.0807868242263794,
      0.06637278199195862,
      -0.06321322172880173,
      0.023098941892385483,
      0.034777142107486725
    ],
    [
      0.04219726473093033,
      -0.05053165927529335,
      0.03185289725661278,
      0.11561070382595062,
      -0.011798053048551083,
      -0.030680036172270775,
      -0.0018166950903832912,
      -0.08208279311656952,
      -0.1250857710838318,
      -0.0660984069108963,
      -0.06344307959079742,
      0.007568109780550003,
      0.04090363532304764,
      0.030193639919161797,
      0.01694181002676487,
      0.08089789003133774,
      -0.0010457426542416215,
      0.02174452506005764,
      0.02950805425643921,
      0.035650961101055145,
      -0.03994814306497574,
      -0.054464977234601974,
      0.005233549512922764,
      -0.02254556678235531,
      0.017683524638414383,
      -0.046090494841337204,
      0.023644598200917244,
      0.003504748921841383,
      0.06067916005849838,
      0.04364655539393425,
      0.03765908628702164,
      0.017899464815855026,
      0.039823517203330994,
      0.038941722363233566,
      0.05529721826314926,
      -0.09506051987409592,
      0.01457448210567236,
      -0.022510763257741928,
      0.0031432330142706633,
      0.05531730875372887,
      0.07379178702831268,
      0.0017446789424866438,
      -0.03286345675587654,
      -0.012447421438992023,
      0.005847509950399399,
      -0.007130149751901627,
      -0.025108059868216515,
      0.026676692068576813,
      -0.014484194107353687,
      0.06171020120382309,
      0.07399915158748627,
      -0.0006234902539290488,
      0.06441910564899445,
      -0.04592832177877426,
      0.014262324199080467,
      -0.09422024339437485,
      -0.1012953370809555,
      0.08576580137014389,
      0.033262550830841064,
      -0.07376080751419067,
      0.10034128278493881,
      0.04400641471147537,
      -0.033824797719717026,
      0.02991667576134205,
      0.04869594797492027,
      -0.05342211574316025,
      0.015850478783249855,
      -0.07191456109285355,
      0.007559777703136206,
      0.07750130444765091,
      -0.016184048727154732,
      -0.038946837186813354,
      -0.10629067569971085,
      0.06288603693246841,
      -0.07159923017024994,
      0.015604780055582523,
      -0.032736923545598984,
      -0.05537373200058937,
      -0.06733476370573044,
      0.04842420667409897,
      0.013709877617657185,
      0.016093865036964417,
      0.08863763511180878,
      -0.07170955091714859,
      0.006935229524970055,
      -0.01870877295732498,
      0.005602758843451738,
      0.07523433864116669,
      -0.028788521885871887,
      -0.00015490376972593367,
      -0.0017422680975869298,
      0.04050786420702934,
      -0.056678406894207,
      0.10347994416952133,
      -0.013147952035069466,
      -0.03160420060157776,
      0.006129775196313858,
      0.027817420661449432,
      0.051574476063251495,
      -0.022545810788869858,
      0.05962630361318588,
      -0.04558846354484558,
      0.0040959385223686695,
      -0.014904255047440529,
      -0.02586863376200199,
      0.0717240422964096,
      0.06159519404172897,
      -0.03239505738019943,
      0.02424643002450466,
      0.015285300090909004,
      -0.004443723242729902,
      -0.06873125582933426,
      0.05679703876376152,
      -0.07389068603515625,
      -0.012970958836376667,
      -0.05929124355316162,
      0.08600716292858124,
      0.00643653841689229,
      0.02240738831460476,
      -0.012512771412730217,
      0.08662401139736176,
      -0.010133395902812481,
      -0.01824037916958332,
      -0.0037740597035735846,
      0.029418228194117546,
      0.044614799320697784,
      -0.03979111835360527,
      0.02598961628973484,
      -0.04379434511065483,
      -0.026722971349954605,
      0.027118319645524025,
      0.061673976480960846,
      -0.036894772201776505,
      0.02721410058438778,
      -0.018589338287711143,
      -0.008873564191162586,
      0.0006075141136534512,
      0.0040778107941150665,
      -0.0073449453338980675,
      -0.011392644606530666,
      -0.03602830320596695,
      0.0006177218165248632,
      -0.09474466741085052,
      0.037741564214229584,
      0.04053792357444763,
      -0.017198465764522552,
      -0.02524978294968605,
      0.0398646779358387,
      0.04537824168801308,
      -0.003915292676538229,
      -0.0448751300573349,
      0.03180436044931412,
      0.03097955882549286,
      -0.012727410532534122,
      -0.04576734080910683,
      -0.023040292784571648,
      0.005738647188991308,
      -0.06504254043102264,
      -0.0200211014598608,
      -0.006203209515661001,
      0.015114215202629566,
      -0.07577867060899734,
      -0.04934731870889664,
      0.023702481761574745,
      -0.014134983532130718,
      -0.038991376757621765,
      0.059655316174030304,
      -0.02974427305161953,
      -0.05689137056469917,
      -0.05133293941617012,
      -0.04864365607500076,
      0.0160424392670393,
      -0.10687237232923508,
      -0.030478112399578094,
      0.04260265454649925,
      -0.008658750914037228,
      0.030774643644690514,
      -0.08327218145132065,
      0.048449888825416565,
      -0.06559543311595917,
      0.023771241307258606,
      0.01334216259419918,
      0.043322134763002396,
      -0.0334174707531929,
      -0.035307321697473526,
      -0.033751342445611954,
      0.03072989173233509,
      -0.019115358591079712,
      0.041612185537815094,
      0.026977328583598137,
      -0.0030914079397916794,
      -0.05671853572130203,
      0.018694303929805756,
      0.013291261158883572,
      -0.068656787276268,
      -0.021170059219002724,
      -0.0040656886994838715,
      -0.04542955011129379,
      0.07225296646356583,
      -0.00885030534118414,
      0.027631662786006927,
      0.013650473207235336,
      -0.004857923835515976,
      0.028293587267398834,
      0.04370837286114693,
      -0.012653367593884468,
      0.02712884545326233,
      0.06617210805416107,
      0.048681676387786865,
      0.04306371137499809,
      0.05100816488265991,
      0.0374729298055172,
      0.06529246270656586,
      0.04322848096489906,
      -0.06087861955165863,
      -0.09581971913576126,
      0.06747766584157944,
      -0.043339136987924576,
      0.04140596091747284,
      0.027592072263360023,
      -0.05087243765592575,
      0.0916222557425499,
      -0.010341773740947247,
      0.008063512854278088,
      -0.005982653703540564,
      0.004945134744048119,
      0.0014547494938597083,
      0.034146904945373535,
      -0.0024662951473146677,
      -0.07362783700227737,
      -0.07104327529668808,
      0.05561317875981331,
      -0.003293016692623496,
      0.08859723061323166,
      -0.0371273010969162,
      0.07169810682535172,
      -0.04875301569700241,
      -0.05058954656124115,
      -0.04110286384820938,
      -0.005426057148724794,
      0.03526654094457626,
      0.020422134548425674,
      0.013855867087841034,
      -0.10397695004940033,
      -0.05306210368871689,
      -0.06516791135072708,
      -0.04600846394896507,
      -0.06044398248195648,
      0.04196816310286522,
      0.05311901122331619,
      -0.05135130137205124,
      -0.04886915534734726,
      -0.027784716337919235,
      0.032459989190101624,
      0.03754251077771187,
      0.06926434487104416,
      0.0006917762220837176,
      -0.04219426214694977,
      0.006882719695568085,
      0.014541423879563808,
      0.00012755375064443797,
      0.0029759560711681843,
      0.03399199992418289,
      -0.012646570801734924,
      0.024991782382130623,
      0.008559467270970345,
      0.11425749957561493,
      0.08046405017375946,
      0.08997134864330292,
      0.04310107231140137,
      -0.03568949177861214,
      -0.025167914107441902,
      0.021021287888288498,
      -0.037167444825172424,
      -0.04913172498345375,
      -0.04198193922638893,
      0.04915827512741089,
      -0.0661650151014328,
      0.08764400333166122,
      4.084529064130038e-05,
      0.013177972286939621,
      0.06867785006761551,
      0.0339207760989666,
      -0.014192617498338223,
      0.0010983316460624337,
      0.020494775846600533,
      0.007127867080271244,
      -0.07549234479665756,
      -0.054412391036748886,
      0.027154667302966118,
      0.0035280087031424046,
      -0.02122846059501171,
      -0.008211460895836353,
      0.004074624739587307,
      0.04418056458234787,
      0.04633272439241409,
      -0.031071309000253677,
      0.056952644139528275,
      -0.024637669324874878,
      -0.013140540570020676,
      0.024118708446621895,
      -0.018343262374401093,
      0.0028316949028521776,
      -0.013610782101750374,
      -0.008670737966895103,
      0.010865171439945698,
      -0.07286539673805237,
      0.013436997309327126,
      0.053746480494737625,
      0.04108409211039543,
      0.01893887296319008,
      -0.03619302064180374,
      -0.09680148959159851,
      -0.05168100818991661,
      -0.04780370742082596,
      0.023941833525896072,
      -0.00028271536575630307,
      0.023883989080786705,
      0.04966440051794052,
      0.06830297410488129,
      0.02266981080174446,
      -0.060456451028585434,
      -0.03904058039188385,
      0.03605812415480614,
      0.05855093151330948,
      0.013467545621097088,
      -0.0046089827083051205,
      -0.057000767439603806,
      -0.00915166363120079,
      -0.05602181702852249,
      0.025461573153734207,
      0.01220510434359312,
      0.028447920456528664,
      -0.07186295092105865,
      -0.01275953371077776,
      0.019560420885682106,
      0.041810981929302216,
      0.0363055020570755,
      -0.011110089719295502,
      -0.020106248557567596,
      0.07119918614625931,
      0.0291058998554945,
      0.031593259423971176,
      -0.003728332929313183,
      -0.004488838836550713,
      -0.05604725331068039,
      -0.021033095195889473,
      -0.008640863001346588,
      0.009055928327143192,
      -0.0408397912979126,
      0.006072769407182932,
      -0.02130165696144104,
      0.015428557060658932,
      0.0006684299441985786,
      -0.0014567940961569548,
      0.015729377046227455,
      -0.02194536104798317,
      0.01223931647837162,
      0.04506916180253029,
      -0.009883911348879337,
      -0.019192084670066833,
      0.00689156586304307,
      -0.053887683898210526,
      0.00016354404215235263,
      0.09195293486118317,
      0.05459011718630791,
      -0.044527817517519,
      0.04133029282093048,
      -0.05013706162571907,
      0.0160426776856184,
      0.032886531203985214,
      -0.061255984008312225,
      -0.018986834213137627,
      -0.030103901401162148,
      -0.030827941372990608,
      -0.03764338418841362,
      -0.05000992864370346,
      0.0149381123483181,
      0.028168002143502235,
      0.04018881171941757,
      0.02223745360970497,
      -0.018861446529626846,
      0.006082659121602774,
      0.03350721299648285,
      0.002000438515096903,
      0.02214588224887848,
      0.006939143408089876,
      0.01811201311647892,
      0.007539567071944475,
      0.08796925842761993,
      0.09962956607341766,
      -0.05108091980218887,
      -0.0537191666662693,
      -0.062029879540205,
      -0.047855060547590256,
      -0.06797586381435394,
      -0.0343346893787384,
      0.027524305507540703,
      -0.02213478460907936,
      -0.007676435634493828,
      -0.029964368790388107,
      0.04785618558526039,
      -0.03334119915962219,
      -0.04040777310729027,
      -0.0121301943436265,
      -0.01874714344739914,
      -0.0680319294333458,
      0.007981293834745884,
      0.04517782852053642,
      0.046969130635261536,
      0.017761709168553352,
      0.046944137662649155,
      0.04563191533088684,
      0.00894684623926878,
      -0.033039528876543045,
      -0.08026912808418274,
      -0.02295602858066559,
      0.02714536152780056,
      -0.022791825234889984,
      0.046651750802993774,
      -0.03759601339697838,
      -0.037994083017110825,
      -0.03230741247534752,
      -0.08126172423362732,
      -0.012611143290996552,
      0.04353344812989235,
      -0.037574347108602524,
      0.030932152643799782,
      0.00980378407984972,
      -0.07124196738004684,
      -0.027293119579553604,
      -0.04355061799287796,
      -0.08849470317363739,
      -0.04095928743481636,
      -0.03883478790521622,
      -0.050202589482069016,
      0.0397041030228138,
      -0.006847639102488756,
      0.04737493023276329,
      0.06386034935712814,
      0.06816396117210388,
      -0.00887747947126627,
      0.006835996173322201,
      0.04144807532429695,
      -0.032072462141513824,
      0.009043151512742043,
      0.022079233080148697,
      -0.015155276283621788,
      -0.008397244848310947,
      -0.07157322764396667,
      -0.09151202440261841,
      0.004066301975399256,
      -0.0024056953843683004,
      0.06562096625566483,
      0.004763063043355942,
      -0.04407813772559166,
      0.005137994419783354,
      -0.04120515659451485,
      -0.060696862637996674,
      0.031479351222515106,
      -0.03731830418109894,
      0.017205962911248207,
      -0.055079907178878784,
      0.02345876768231392,
      0.04198367893695831,
      -0.008024442940950394,
      -0.02134920470416546,
      0.012007856741547585,
      0.07689512521028519,
      -0.008777888491749763,
      0.003296389477327466,
      -0.010432562790811062,
      -0.016539733856916428,
      -0.042511340230703354,
      0.06561196595430374,
      -0.050244610756635666,
      0.02454669401049614,
      -0.01655709184706211,
      0.1053323969244957,
      0.06322771310806274,
      0.04056539013981819,
      0.013529411517083645,
      0.003268607659265399,
      0.003883321536704898,
      0.03536655381321907,
      0.04736137017607689,
      0.06606635451316833,
      0.047969527542591095,
      -0.015571155585348606,
      -0.0014049955643713474,
      0.043234433978796005,
      0.022805681452155113,
      -0.04872073978185654,
      -0.12503297626972198,
      -0.030872737988829613,
      -0.0002295098965987563,
      0.004988612607121468,
      0.0653151422739029,
      -0.01140119880437851,
      0.033440619707107544,
      -0.030293570831418037,
      -0.037840038537979126,
      -0.03059486113488674
    ],
    [
      0.02187337912619114,
      0.0017304099164903164,
      -0.038029491901397705,
      0.0045102378353476524,
      0.019454365596175194,
      -0.064792200922966,
      0.027558419853448868,
      -0.08988413959741592,
      0.004520380403846502,
      -0.020500291138887405,
      0.10025090724229813,
      -0.009444343857467175,
      0.05423414707183838,
      0.009170406498014927,
      0.03264874964952469,
      -0.03439396619796753,
      0.02211344987154007,
      0.05357559770345688,
      -0.022149624302983284,
      -0.06548767536878586,
      0.049656763672828674,
      -0.00503185810521245,
      -0.03352711349725723,
      -0.03075478784739971,
      -0.01869218423962593,
      -0.005346981808543205,
      0.1115664541721344,
      -0.011620793491601944,
      -0.0018534624250605702,
      0.01003104168921709,
      0.005361441057175398,
      0.010845904238522053,
      0.00461979117244482,
      0.024478839710354805,
      -0.02997705712914467,
      -0.03920622915029526,
      0.041932664811611176,
      0.06990686058998108,
      0.01982894539833069,
      -0.003163563320413232,
      0.020999304950237274,
      -0.02215959131717682,
      -0.01530933752655983,
      -0.019933845847845078,
      0.03998361527919769,
      -0.05641067773103714,
      0.06614654511213303,
      0.01110673788934946,
      -0.053880102932453156,
      0.012351248413324356,
      -0.010089857503771782,
      0.06740538775920868,
      -0.02950243465602398,
      -0.007819018326699734,
      -0.01374661736190319,
      0.01666492596268654,
      0.008815014734864235,
      -0.005726824980229139,
      -0.0028282678686082363,
      -0.04895835369825363,
      0.02442908100783825,
      -0.03342604264616966,
      -0.006029580719769001,
      -0.001347244717180729,
      0.04461288824677467,
      -0.04135214909911156,
      -0.023808490484952927,
      -0.05707082152366638,
      -0.05971340462565422,
      0.024489589035511017,
      -0.014840059913694859,
      0.12387332320213318,
      -0.01788761280477047,
      -0.00905118603259325,
      0.0491580069065094,
      -0.11341232061386108,
      -0.0865207388997078,
      0.029363585636019707,
      0.021530309692025185,
      0.09585203975439072,
      -0.020212089642882347,
      0.04246694967150688,
      0.08231481909751892,
      -0.008730599656701088,
      -0.021322496235370636,
      0.015588048845529556,
      0.009750663302838802,
      -0.03612196072936058,
      -0.06370536983013153,
      0.04787750542163849,
      0.012859473004937172,
      -0.07063600420951843,
      -0.054591722786426544,
      0.07692314684391022,
      0.03354556858539581,
      -0.029557697474956512,
      -0.06693617254495621,
      -0.05298617482185364,
      -0.004810983780771494,
      0.028568198904395103,
      -0.010893969796597958,
      -0.011289404705166817,
      0.00684966379776597,
      -0.01859765127301216,
      -0.04901176318526268,
      -0.04838910326361656,
      0.035200078040361404,
      0.07963776588439941,
      0.02362329140305519,
      0.01107714232057333,
      -0.06038987264037132,
      0.009188980795443058,
      -0.023499352857470512,
      0.03490541875362396,
      -0.07853182405233383,
      0.07956215739250183,
      -0.009249739348888397,
      0.041199009865522385,
      0.09224742650985718,
      -0.04847570136189461,
      0.0017434086184948683,
      -0.0821618065237999,
      0.0633220225572586,
      -0.05105409398674965,
      0.0208121407777071,
      -0.0072705503553152084,
      0.07001031190156937,
      0.04897927865386009,
      0.01158381998538971,
      0.04222613945603371,
      0.07578139752149582,
      -0.0890025720000267,
      0.08076836913824081,
      -0.033056702464818954,
      0.060715965926647186,
      0.007457504514604807,
      0.009160135872662067,
      0.04194897785782814,
      0.04679446667432785,
      0.016184020787477493,
      -0.004734680987894535,
      -0.011318347416818142,
      -0.012273203581571579,
      0.07655467838048935,
      -0.015423855744302273,
      0.04592422395944595,
      0.009858056902885437,
      0.022497709840536118,
      -0.038925785571336746,
      -0.020192060619592667,
      0.03268032521009445,
      -0.04047796502709389,
      -0.008207707665860653,
      0.025195185095071793,
      0.0037463330663740635,
      0.04887911304831505,
      0.037918463349342346,
      -0.052902743220329285,
      0.007788442075252533,
      -0.0303540900349617,
      0.03528949245810509,
      0.030286120250821114,
      -0.02597026526927948,
      0.09335047006607056,
      0.048067279160022736,
      -0.03625524416565895,
      0.023994194343686104,
      0.0706573873758316,
      -0.020826665684580803,
      -0.02232392318546772,
      0.1047411784529686,
      -0.011866333894431591,
      -0.044776976108551025,
      -0.02748587541282177,
      0.05481855571269989,
      0.05946936830878258,
      0.03831304982304573,
      -0.020576786249876022,
      0.038499340415000916,
      0.04455661028623581,
      0.054196201264858246,
      0.010675373487174511,
      -0.0375506728887558,
      -0.02181980386376381,
      -0.02311275340616703,
      0.004094427451491356,
      -0.011403582990169525,
      0.030438026413321495,
      0.045159436762332916,
      -0.007604934740811586,
      -0.042258378118276596,
      -0.058422502130270004,
      -0.02720591612160206,
      -0.015452621504664421,
      0.020326441153883934,
      0.0641028881072998,
      -0.01707809418439865,
      -0.07532641291618347,
      -0.06872104108333588,
      -0.056390777230262756,
      -0.052759215235710144,
      -0.020565370097756386,
      -0.08448360115289688,
      -0.0013946660328656435,
      0.009689516387879848,
      -0.00920784194022417,
      -0.013427967205643654,
      -0.009066603146493435,
      -0.01735059544444084,
      -0.011259262450039387,
      -0.061378322541713715,
      0.029155630618333817,
      -0.009770272299647331,
      0.05806703865528107,
      0.02830778993666172,
      -0.05165029317140579,
      -0.13211245834827423,
      -0.028329968452453613,
      -0.02536490559577942,
      -0.037380337715148926,
      0.04266867786645889,
      -0.05951029434800148,
      -0.07817315310239792,
      -0.00366382347419858,
      -0.01144154742360115,
      0.014861596748232841,
      0.039476342499256134,
      -0.03433394059538841,
      0.039438340812921524,
      -0.04797976836562157,
      0.07924889773130417,
      -0.015291481278836727,
      -0.006469116546213627,
      0.004996169358491898,
      -0.0027750625740736723,
      -0.007273492403328419,
      -0.020309748128056526,
      4.45472433057148e-05,
      0.042509134858846664,
      -0.01380064431577921,
      -0.007257755845785141,
      -0.01752343401312828,
      -0.08326209336519241,
      0.056346893310546875,
      0.017242301255464554,
      0.03155849501490593,
      0.016927966848015785,
      0.004124367143958807,
      0.029707780107855797,
      -0.0026935178320854902,
      0.022823374718427658,
      -0.00702035753056407,
      -0.018756091594696045,
      -0.0029303221963346004,
      0.04983048886060715,
      -9.788676834432408e-06,
      -0.027643350884318352,
      -0.05863408371806145,
      0.009351160377264023,
      -0.004033026285469532,
      0.07335149496793747,
      -0.08001327514648438,
      0.046641863882541656,
      -0.0010579127119854093,
      -0.07275549322366714,
      0.037049565464258194,
      0.012638158164918423,
      -0.06264518946409225,
      0.027052761986851692,
      0.01668490283191204,
      0.047072261571884155,
      0.04771356284618378,
      -0.05022299662232399,
      -0.04299672320485115,
      -0.007157419808208942,
      -0.03540338948369026,
      -0.051098767668008804,
      -0.005544038023799658,
      0.010657341219484806,
      -0.03926011919975281,
      0.018676014617085457,
      -0.06978345662355423,
      0.022413549944758415,
      0.0034815131220966578,
      0.10111943632364273,
      -0.059213846921920776,
      -0.03649687394499779,
      0.07144835591316223,
      0.023908939212560654,
      0.049000877887010574,
      0.00889379158616066,
      -0.04441868141293526,
      -0.02209566719830036,
      -0.039681825786828995,
      -0.0649583712220192,
      -0.02378636784851551,
      -0.07726947218179703,
      0.05363326519727707,
      -0.011182555928826332,
      -0.06782572716474533,
      0.012144660577178001,
      -0.06046422943472862,
      0.04366176202893257,
      -0.06026613339781761,
      0.06938804686069489,
      -0.03307253122329712,
      0.010302907787263393,
      -0.02914164960384369,
      0.08723090589046478,
      0.056348927319049835,
      -0.026450885459780693,
      0.036316175013780594,
      -0.018413372337818146,
      0.021632373332977295,
      -0.04965505376458168,
      -0.01971348375082016,
      -0.0010365236084908247,
      -0.012753140181303024,
      0.04962807893753052,
      0.008371904492378235,
      0.003466277848929167,
      -0.07660273462533951,
      -0.02603394351899624,
      -0.011433073319494724,
      0.0141969108954072,
      -0.011445676907896996,
      -0.00473737483844161,
      0.03525499626994133,
      0.04160656780004501,
      0.03465748205780983,
      -0.0349910706281662,
      -0.018083028495311737,
      -0.0034957770258188248,
      -0.02538927085697651,
      0.04095374047756195,
      0.019467489793896675,
      -0.009133589453995228,
      0.0673830658197403,
      0.006106974557042122,
      0.04191796854138374,
      0.07268869131803513,
      -0.05044936761260033,
      -0.09830503910779953,
      -0.009881744161248207,
      0.001765511929988861,
      -0.06683194637298584,
      0.02199745550751686,
      0.021423934027552605,
      0.019380958750844002,
      -0.008202108554542065,
      0.04673636704683304,
      0.03981804475188255,
      0.01130636502057314,
      0.0024935391265898943,
      0.007044379133731127,
      -0.01043660007417202,
      0.04993569850921631,
      -0.09783415496349335,
      0.02174697257578373,
      0.0077582839876413345,
      -0.06931349635124207,
      -0.018145300447940826,
      0.011678052134811878,
      -0.04901805520057678,
      0.011305715888738632,
      0.00867150817066431,
      -0.009342188946902752,
      0.07312881201505661,
      0.045130446553230286,
      -0.0293666273355484,
      -0.00956922397017479,
      0.0004521771625149995,
      0.027578087523579597,
      0.04909589886665344,
      -0.00511676911264658,
      -0.006386509630829096,
      0.04324926435947418,
      -0.04269372671842575,
      0.010494996793568134,
      0.019041096791625023,
      0.059031907469034195,
      -0.0009380276896990836,
      0.012184379622340202,
      -0.0038473857566714287,
      0.03522522747516632,
      -0.01381809450685978,
      0.0559273399412632,
      0.02922665886580944,
      -0.01967228762805462,
      0.06551545858383179,
      -0.04119860380887985,
      0.03900845721364021,
      0.07094106078147888,
      -0.04060503840446472,
      0.0046104551292955875,
      0.06281397491693497,
      0.01741444692015648,
      -0.0034159361384809017,
      -0.06439124792814255,
      0.006384106818586588,
      -0.09639588743448257,
      0.029149364680051804,
      0.011348513886332512,
      0.031068751588463783,
      0.02118818089365959,
      0.0008394049946218729,
      0.03989633172750473,
      -0.02809075266122818,
      -0.04980938509106636,
      -0.013189018703997135,
      -0.060115013271570206,
      0.07012982666492462,
      -0.0350029282271862,
      -0.08367042243480682,
      0.06667787581682205,
      -0.003073274390771985,
      -0.027600819244980812,
      0.04509996250271797,
      -0.01519283838570118,
      0.007287987042218447,
      0.02910624071955681,
      -0.042441509664058685,
      0.0331314280629158,
      -0.054666053503751755,
      -0.023791981860995293,
      -0.045639414340257645,
      -0.010357600636780262,
      -0.003277343697845936,
      0.004806140903383493,
      0.04527352750301361,
      0.0053031728602945805,
      -0.05183521658182144,
      0.028858352452516556,
      0.0227070115506649,
      -0.04339788854122162,
      0.026965320110321045,
      0.01871028169989586,
      -0.034039150923490524,
      0.028996091336011887,
      -0.017997028306126595,
      0.016310349106788635,
      0.018533354625105858,
      -0.08835667371749878,
      0.12493076175451279,
      -0.014159702695906162,
      -0.004542849026620388,
      -0.02145032025873661,
      -0.03510788455605507,
      0.028370408341288567,
      -0.022154396399855614,
      0.020577872171998024,
      0.017676284536719322,
      -0.006548759993165731,
      0.03460850939154625,
      -0.0233821589499712,
      0.012988043949007988,
      0.01657937653362751,
      -0.012236074544489384,
      0.02765539474785328,
      -0.020416881889104843,
      -0.0900280699133873,
      0.021489759907126427,
      0.004620436113327742,
      -0.020670726895332336,
      0.03225382789969444,
      -0.04104333370923996,
      0.060970187187194824,
      0.007834134623408318,
      -0.01610623672604561,
      -0.03978361561894417,
      0.052632637321949005,
      -0.032063473016023636,
      -0.02322312816977501,
      0.08684168756008148,
      0.07229401916265488,
      -0.012297297827899456,
      0.017252901569008827,
      -0.013471449725329876,
      0.0195815060287714,
      0.0404767282307148,
      0.04933096840977669,
      -0.07510900497436523,
      0.012513674795627594,
      0.05052649602293968,
      0.04002675414085388,
      0.014509584754705429,
      0.02920413948595524,
      0.02825886756181717,
      0.016891155391931534,
      -0.06279179453849792,
      -0.016532570123672485,
      0.026387732475996017,
      -0.016275668516755104,
      0.051020532846450806,
      -0.005504249129444361,
      -0.013894314877688885,
      0.03669670224189758,
      -0.03086930327117443,
      -0.008333716541528702,
      -0.003973439801484346,
      0.011149073950946331,
      -0.006456878501921892,
      0.01963207870721817
    ],
    [
      0.05077248439192772,
      -0.017303334549069405,
      -0.07679636031389236,
      0.06416001170873642,
      0.0018934598192572594,
      0.021227877587080002,
      -0.05566208437085152,
      0.058721352368593216,
      0.03190819174051285,
      -0.0331866480410099,
      -0.07609254121780396,
      -0.01086499635130167,
      -0.07182548195123672,
      -0.019801225513219833,
      -0.024212639778852463,
      0.012840788811445236,
      -0.013112627901136875,
      0.08151601999998093,
      0.029884491115808487,
      -0.04016333445906639,
      0.032984260469675064,
      0.024476373568177223,
      0.020053032785654068,
      -0.033913418650627136,
      0.07735235244035721,
      -0.018693136051297188,
      0.054354388266801834,
      -0.0284736230969429,
      -0.036545369774103165,
      0.04609842225909233,
      0.08192695677280426,
      0.001888530096039176,
      -0.008115844801068306,
      -0.021582938730716705,
      -0.03823099285364151,
      0.06195353716611862,
      0.006185588426887989,
      -0.04170648008584976,
      0.04844846948981285,
      -0.017947334796190262,
      0.005405329633504152,
      0.005091194994747639,
      -0.0029328768141567707,
      0.021508602425456047,
      -0.04734475538134575,
      0.0038791520055383444,
      -0.02504696696996689,
      0.03004796802997589,
      -0.0035953293554484844,
      0.04704107716679573,
      0.006340931169688702,
      0.01194376964122057,
      0.036896780133247375,
      -0.007464498281478882,
      -0.04664859548211098,
      -0.017216965556144714,
      -0.017435966059565544,
      0.023621656000614166,
      -0.04526789113879204,
      0.01370357908308506,
      0.08492295444011688,
      -0.0029213540256023407,
      0.05472497269511223,
      -0.01820940151810646,
      -0.03828621655702591,
      0.009162677451968193,
      0.06453892588615417,
      -0.031074604019522667,
      -0.02321367897093296,
      -0.03950220346450806,
      0.015193252824246883,
      0.031764041632413864,
      -0.007668129168450832,
      0.0006903103203512728,
      0.05955497920513153,
      -0.025193560868501663,
      -0.016190867871046066,
      -0.005945983342826366,
      0.04407742619514465,
      0.011368971318006516,
      0.023798897862434387,
      -0.011798422783613205,
      0.08177050948143005,
      -0.019178813323378563,
      0.008571628481149673,
      -0.01576901040971279,
      0.03940729796886444,
      0.015469478443264961,
      0.040439423173666,
      -0.007999210618436337,
      -0.038431257009506226,
      -0.002908612135797739,
      -0.018231889232993126,
      0.06293311715126038,
      0.04665660113096237,
      -0.025675347074866295,
      0.005868161097168922,
      -0.01718892529606819,
      -0.029274607077240944,
      0.038886185735464096,
      0.03300832584500313,
      0.014012525789439678,
      -0.03905581310391426,
      -0.08889827132225037,
      0.018559981137514114,
      0.03018808737397194,
      -0.06760180741548538,
      0.045728713274002075,
      -0.04618318751454353,
      -0.03265121579170227,
      -0.0048971413634717464,
      -0.04606792330741882,
      0.011364776641130447,
      -0.03817940503358841,
      -0.051653407514095306,
      -0.07374680787324905,
      -0.018273362889885902,
      0.009596562013030052,
      0.016127435490489006,
      -0.03737424314022064,
      0.043568599969148636,
      0.0007544850232079625,
      0.004957506433129311,
      0.015832694247364998,
      0.05047896131873131,
      0.0007868713582865894,
      -0.0073631079867482185,
      0.03011457808315754,
      0.008040974847972393,
      0.05605724826455116,
      0.0466490313410759,
      -0.013693984597921371,
      0.05332065001130104,
      0.047555118799209595,
      -0.04872102290391922,
      0.05239977315068245,
      0.060886602848768234,
      0.007869001477956772,
      0.12567035853862762,
      -0.05943983793258667,
      -0.007607156410813332,
      -0.03933878242969513,
      0.0062511637806892395,
      0.08871167153120041,
      -0.02042361907660961,
      0.028488509356975555,
      0.004595762584358454,
      0.03350191190838814,
      0.0026033201720565557,
      -0.008432486094534397,
      -0.09247388690710068,
      -0.017665833234786987,
      0.009230499155819416,
      -0.04711337760090828,
      0.009363277815282345,
      -0.003517317585647106,
      0.027355002239346504,
      -0.05987299606204033,
      -0.039927445352077484,
      -0.0946899801492691,
      -0.05573884770274162,
      -0.09450682997703552,
      0.08371258527040482,
      0.04275914281606674,
      0.05296071246266365,
      -0.07518618553876877,
      0.03467120602726936,
      -0.07819261401891708,
      -0.0547158382833004,
      -0.04006652161478996,
      -0.0751509815454483,
      0.012333039194345474,
      -0.051123954355716705,
      0.03396614268422127,
      0.0016940266359597445,
      0.005415575113147497,
      0.008901527151465416,
      0.05193952098488808,
      -0.005109085701406002,
      -0.09272059053182602,
      -0.0016139020444825292,
      -0.007402956951409578,
      0.03349139541387558,
      0.027287673205137253,
      0.0278650913387537,
      -0.08208026736974716,
      -0.051385365426540375,
      0.037001170217990875,
      -0.08227918297052383,
      -0.014585798606276512,
      -0.044563956558704376,
      -0.03625423088669777,
      -0.01751728542149067,
      0.05221240594983101,
      -0.06552514433860779,
      -0.07287079840898514,
      -0.02180379256606102,
      -0.04159029200673103,
      -0.04232742637395859,
      0.001995847560465336,
      0.050528086721897125,
      0.01592356152832508,
      0.0014436907367780805,
      0.028764287009835243,
      -0.009118741378188133,
      -0.04178433120250702,
      -0.014108062721788883,
      0.03531429544091225,
      0.012181642465293407,
      0.08142893016338348,
      0.06413861364126205,
      0.024988440796732903,
      0.06826268881559372,
      0.004507433157414198,
      0.0068954648450016975,
      -0.04023030027747154,
      -0.018709061667323112,
      -0.008908235467970371,
      -0.013858476653695107,
      0.021259741857647896,
      0.054535843431949615,
      -0.019259294494986534,
      -0.07036183774471283,
      -0.046746876090765,
      -0.06735927611589432,
      -0.1013946384191513,
      0.005653551314026117,
      -1.777638135536108e-05,
      0.0028016313444823027,
      0.019083648920059204,
      -0.0022398200817406178,
      -0.034018415957689285,
      -0.0268833190202713,
      -0.00154976942576468,
      0.029903385788202286,
      -0.015671510249376297,
      -0.027821552008390427,
      -0.017822491005063057,
      -0.002168733160942793,
      -0.059095993638038635,
      -0.03016972728073597,
      0.037814393639564514,
      -0.07159080356359482,
      -0.0075284698978066444,
      0.06481760740280151,
      -0.024872133508324623,
      0.05578654631972313,
      0.023581618443131447,
      -0.08108802884817123,
      -0.03733829781413078,
      -0.03814796358346939,
      0.034230396151542664,
      0.027579335495829582,
      -0.07340311259031296,
      0.060480404645204544,
      -0.058569569140672684,
      -0.07001514732837677,
      -0.05484895408153534,
      -0.05097474157810211,
      -0.011897026561200619,
      -0.025585738942027092,
      -0.0019327887566760182,
      -0.08428631722927094,
      -0.03425700590014458,
      -0.05081545189023018,
      0.05565108358860016,
      -0.026320744305849075,
      -0.019615087658166885,
      -0.013253909535706043,
      -0.0529218465089798,
      0.15156768262386322,
      -0.07536817342042923,
      0.021368371322751045,
      -0.05162721872329712,
      -0.02436756156384945,
      -0.05333973839879036,
      0.0065755462273955345,
      -0.058596421033144,
      0.055682986974716187,
      0.03384330868721008,
      0.04837947338819504,
      0.007048642262816429,
      0.03330303356051445,
      -0.006563615053892136,
      0.03795207664370537,
      -0.09318570047616959,
      -0.031781744211912155,
      -0.021888013929128647,
      -0.030113868415355682,
      0.008330162614583969,
      -0.054792679846286774,
      -0.0257002804428339,
      -0.07407549023628235,
      0.031585875898599625,
      -0.006197154987603426,
      -0.02991626039147377,
      0.02523116208612919,
      0.03429216891527176,
      0.04826701432466507,
      0.011175021529197693,
      0.026424981653690338,
      -0.05250592902302742,
      8.311016426887363e-05,
      -0.010314199142158031,
      0.008435297757387161,
      0.005206650588661432,
      0.003929336089640856,
      -0.06515760719776154,
      -0.018254177644848824,
      -0.042394038289785385,
      0.0188510250300169,
      -0.04956429824233055,
      0.05691682919859886,
      0.029102282598614693,
      0.017984164878726006,
      -0.06351814419031143,
      -0.08963490277528763,
      0.03059949353337288,
      0.04641246050596237,
      -0.028848737478256226,
      -0.052289459854364395,
      -0.03264404460787773,
      -0.00044187670573592186,
      -0.029392940923571587,
      0.018727580085396767,
      -0.0007846337975934148,
      -0.03808718919754028,
      0.0020998320542275906,
      -0.004486301448196173,
      -0.02568773180246353,
      -0.05147498473525047,
      0.06036237254738808,
      -0.05152744799852371,
      -0.019976913928985596,
      -3.264632323407568e-05,
      -0.03699079528450966,
      0.038314685225486755,
      0.0359782911837101,
      -0.07903219014406204,
      0.020815104246139526,
      -0.10884357988834381,
      0.054635241627693176,
      0.04531551152467728,
      0.031675394624471664,
      -0.005450033582746983,
      0.03819530829787254,
      0.028753120452165604,
      -0.08270745724439621,
      -0.04720678552985191,
      0.04675723984837532,
      -0.051479849964380264,
      0.0037146899849176407,
      0.004854047670960426,
      -0.005411788355559111,
      0.039369627833366394,
      -0.08293669670820236,
      -0.02899502031505108,
      -0.014768410474061966,
      0.05252513661980629,
      -0.011119137518107891,
      0.0010394842829555273,
      0.015152798034250736,
      0.06559501588344574,
      0.04187823086977005,
      0.06596101820468903,
      -0.004642368294298649,
      0.02975904382765293,
      -0.06964746117591858,
      0.051802005618810654,
      0.03764266520738602,
      0.028040137141942978,
      0.008243333548307419,
      -0.08770978450775146,
      0.11314336955547333,
      0.02780076116323471,
      0.01146333385258913,
      0.03583976998925209,
      0.028254518285393715,
      -0.017394516617059708,
      -0.03549898415803909,
      -0.06941618025302887,
      -0.06524085253477097,
      0.003882576245814562,
      -0.14662371575832367,
      0.026278480887413025,
      -0.030785921961069107,
      -0.0057866498827934265,
      -0.011012889444828033,
      -0.06236029043793678,
      0.00014452623145189136,
      0.12277624011039734,
      0.01740809716284275,
      0.04225083440542221,
      0.0029354803264141083,
      -0.028755078092217445,
      0.049475908279418945,
      -0.00791796576231718,
      0.0197171401232481,
      0.07619626820087433,
      -0.04587036743760109,
      -0.05316159501671791,
      -0.05514497309923172,
      0.000951953639741987,
      -0.044910866767168045,
      0.023805784061551094,
      0.022506942972540855,
      0.029102137312293053,
      0.046635400503873825,
      0.042705971747636795,
      -0.005370872560888529,
      -0.045753948390483856,
      0.1117294505238533,
      0.01835015043616295,
      -0.01641993597149849,
      -0.08550616353750229,
      -0.11146353930234909,
      0.03646861016750336,
      -0.11895406246185303,
      0.002829446690157056,
      -0.10123942792415619,
      -0.01387584675103426,
      0.0870501920580864,
      -0.029491804540157318,
      0.04363463446497917,
      -0.010704496875405312,
      -0.01065186969935894,
      0.015406295657157898,
      0.0131245581433177,
      0.0024244177620857954,
      -0.09736490249633789,
      0.031953927129507065,
      -0.06712738424539566,
      -0.054993946105241776,
      0.009022765792906284,
      -0.029959768056869507,
      0.05200241506099701,
      -0.019228415563702583,
      -0.01628655381500721,
      0.05087999626994133,
      -0.0415668860077858,
      0.057072874158620834,
      -0.05688956379890442,
      -0.10323411971330643,
      -0.07323320209980011,
      0.03229370713233948,
      0.008019433356821537,
      -0.004854524973779917,
      0.0008431169553659856,
      0.05006261169910431,
      -0.08901144564151764,
      0.020855730399489403,
      0.008285976015031338,
      -0.02867310680449009,
      -0.14790447056293488,
      0.007650161627680063,
      -0.08636337518692017,
      0.011526990681886673,
      -0.0015917529817670584,
      -0.022435158491134644,
      -0.008757743053138256,
      0.040845707058906555,
      0.03411189094185829,
      0.11007092148065567,
      -0.04983390495181084,
      0.08490976691246033,
      -0.03587350621819496,
      -0.05170423537492752,
      -0.01838746666908264,
      0.01444964949041605,
      -0.05984019860625267,
      0.0739285945892334,
      -0.02033047191798687,
      -0.009575893171131611,
      0.02183167077600956,
      -0.040001656860113144,
      -0.02597963809967041,
      -0.028068147599697113,
      0.0004467376274988055,
      0.05898995324969292,
      -0.06376755982637405,
      -0.0189998559653759,
      -0.04434901475906372,
      -0.04994749650359154,
      -0.0021983552724123,
      0.012218594551086426,
      -0.05002136155962944,
      0.0038637598045170307,
      0.05782455950975418,
      -0.08754671365022659,
      -0.053648822009563446,
      -0.03290916606783867,
      0.00018324994016438723,
      -0.02832890674471855,
      0.007618859875947237,
      0.07647412270307541,
      -0.018024710938334465,
      -0.03049550950527191,
      -0.015767579898238182,
      -0.03495461493730545,
      0.021097589284181595,
      0.02469601295888424,
      -0.03308858722448349,
      0.023393219336867332
    ],
    [
      0.11889011412858963,
      0.029716359451413155,
      0.017131276428699493,
      0.0239738617092371,
      0.0003233065945096314,
      -0.06766217947006226,
      -0.04116719588637352,
      -0.015991948544979095,
      -0.06665156036615372,
      -0.058661408722400665,
      -0.05155201628804207,
      0.03761950135231018,
      0.004524595569819212,
      0.05899912863969803,
      -0.0748126432299614,
      -0.04076924920082092,
      0.04232310876250267,
      -0.006229113321751356,
      0.0034232125617563725,
      -0.017978232353925705,
      -0.08345930278301239,
      -0.03939137980341911,
      0.04163895547389984,
      0.02502153441309929,
      0.060451265424489975,
      0.02967837080359459,
      0.017105350270867348,
      -0.046042099595069885,
      -0.012056284584105015,
      0.020538700744509697,
      -0.02461160533130169,
      0.006567175034433603,
      0.032337434589862823,
      0.022661514580249786,
      0.02738790586590767,
      0.016990656033158302,
      -0.01563921570777893,
      -0.03391820937395096,
      -0.025218814611434937,
      -0.10480578243732452,
      -0.007242369931191206,
      -0.035504430532455444,
      0.043081238865852356,
      -0.004017466679215431,
      0.04176335781812668,
      -0.0278790183365345,
      -0.09113792330026627,
      -0.020335044711828232,
      0.037346407771110535,
      0.05769755318760872,
      0.03717505931854248,
      0.02866525575518608,
      -0.03213013336062431,
      0.053513169288635254,
      -0.0024799040984362364,
      0.05656127259135246,
      0.03342308849096298,
      0.0314100906252861,
      0.012322042137384415,
      -0.012117039412260056,
      0.10129334777593613,
      -0.023119350895285606,
      -0.05103159323334694,
      -0.039074111729860306,
      -0.02346327342092991,
      -0.054903820157051086,
      -0.04037027433514595,
      -0.010505685582756996,
      0.0007538290228694677,
      0.06173810735344887,
      0.05505254119634628,
      -0.04998238757252693,
      -0.03751802816987038,
      -0.04274982959032059,
      0.03532250225543976,
      0.004257142543792725,
      -0.07896222919225693,
      -0.050094667822122574,
      0.0023531783372163773,
      0.03564505651593208,
      -0.05454546585679054,
      -0.006632188335061073,
      0.009602130390703678,
      -0.030821330845355988,
      0.02401047572493553,
      -0.005367790814489126,
      0.05288362503051758,
      -0.02868674136698246,
      0.011215327307581902,
      0.06960789859294891,
      0.048322536051273346,
      0.03357212617993355,
      0.02310972660779953,
      -0.012894374318420887,
      0.02845032885670662,
      0.06965415924787521,
      0.019624408334493637,
      -0.012803585268557072,
      0.006437187548726797,
      0.01840880885720253,
      0.0005765218520537019,
      -0.001480046776123345,
      -0.07257097214460373,
      -0.014450084418058395,
      -0.05328112468123436,
      0.014888166449964046,
      0.0020773124415427446,
      0.0912509486079216,
      0.013080107979476452,
      -0.05449816957116127,
      -0.013104826211929321,
      0.006581938825547695,
      0.011225671507418156,
      -0.10050048679113388,
      -0.052721962332725525,
      -0.02988775447010994,
      0.08594322949647903,
      -0.005180946085602045,
      -0.022868435829877853,
      -0.002934942953288555,
      -0.0029349152464419603,
      -0.054620929062366486,
      0.06941340863704681,
      0.012209678068757057,
      -0.008728775195777416,
      0.08805713802576065,
      -0.018618281930685043,
      -0.06737570464611053,
      -0.01218331791460514,
      0.05508247762918472,
      -0.003460956271737814,
      0.09360069036483765,
      0.005626207683235407,
      -0.040538351982831955,
      -0.046896111220121384,
      0.02227543666958809,
      -0.029543932527303696,
      0.02888672612607479,
      0.026466354727745056,
      -0.07624948024749756,
      -0.06993591040372849,
      0.04890265688300133,
      0.04448537901043892,
      -0.009674008004367352,
      -0.02168414555490017,
      0.049694862216711044,
      -0.05891525000333786,
      0.04076988622546196,
      0.05239313095808029,
      0.008037230931222439,
      0.026823334395885468,
      0.0025042265187948942,
      0.019283754751086235,
      -0.005440923385322094,
      -0.025479178875684738,
      0.01728764735162258,
      0.04569754749536514,
      0.029629070311784744,
      -0.024239709600806236,
      0.03237595036625862,
      0.10294491797685623,
      0.05679532140493393,
      0.03314526751637459,
      -0.0042130001820623875,
      0.038714129477739334,
      0.0579402931034565,
      0.05701643228530884,
      -0.044610679149627686,
      0.04644440859556198,
      -0.024217788130044937,
      -0.045264553278684616,
      -0.08274906873703003,
      -0.094938263297081,
      0.010897758416831493,
      -0.03785669058561325,
      0.04594092443585396,
      0.011473942548036575,
      0.019966349005699158,
      0.15953496098518372,
      -0.034736812114715576,
      0.03631969168782234,
      0.05318998545408249,
      -0.03847973421216011,
      -0.03435986861586571,
      -0.047254178673028946,
      -0.02684442512691021,
      -0.003276893636211753,
      0.039324354380369186,
      0.016043860465288162,
      -0.0961366668343544,
      0.07184146344661713,
      -0.07308714836835861,
      -0.015128959901630878,
      -0.04944238439202309,
      0.03507399559020996,
      0.07408490777015686,
      -0.07319608330726624,
      -0.03212282434105873,
      0.06676386296749115,
      0.023707741871476173,
      -0.014089961536228657,
      0.11866453289985657,
      -0.054705336689949036,
      0.01318004447966814,
      0.013068762607872486,
      0.0290242787450552,
      -0.005182374268770218,
      -0.05616753548383713,
      0.013399294577538967,
      -0.030405938625335693,
      0.05849968269467354,
      0.0027256773319095373,
      -0.11878170818090439,
      0.012327750213444233,
      0.06257162243127823,
      -0.006434742361307144,
      -0.04741104692220688,
      -0.0012382672866806388,
      -0.008390075527131557,
      -0.06907466799020767,
      0.057088468223810196,
      -0.11289332062005997,
      0.006405407562851906,
      -0.10241828113794327,
      -0.011946916580200195,
      0.012664703652262688,
      -0.03771832957863808,
      -0.02424168772995472,
      -0.018594032153487206,
      0.0692506805062294,
      -0.028323527425527573,
      -0.004006803035736084,
      -0.023538805544376373,
      -0.006605165079236031,
      -0.07202447205781937,
      -0.001508738612756133,
      0.0004897048347629607,
      0.010153056122362614,
      -0.06333225220441818,
      -0.006735198199748993,
      -0.036944590508937836,
      -0.11066343635320663,
      0.05768020078539848,
      0.016968147829174995,
      -0.11140089482069016,
      -0.018360398709774017,
      -0.09440188854932785,
      0.04725951328873634,
      0.01496899500489235,
      -0.04607182741165161,
      -0.05665774643421173,
      -0.003287658328190446,
      -0.00024241398205049336,
      -0.05011971294879913,
      0.029368553310632706,
      -0.006432883907109499,
      -0.08469262719154358,
      0.023900561034679413,
      0.08593055605888367,
      -0.004107549320906401,
      0.028467239812016487,
      -0.023026665672659874,
      0.0728287473320961,
      0.012296334840357304,
      0.07927822321653366,
      0.02979406714439392,
      -0.03252606838941574,
      -0.058951735496520996,
      0.003582263831049204,
      -0.039587005972862244,
      -0.0015363420825451612,
      0.01076850388199091,
      -0.024828055873513222,
      -0.04966452345252037,
      -0.03685089200735092,
      -0.02737298049032688,
      0.00831224862486124,
      0.12583774328231812,
      -0.010554435662925243,
      0.09620139002799988,
      0.12704215943813324,
      0.015823466703295708,
      0.0765361413359642,
      -0.03759916126728058,
      0.019102858379483223,
      -0.010372498072683811,
      -0.009872055612504482,
      0.055264562368392944,
      -0.0069925012066960335,
      -0.02880283072590828,
      -0.013243040069937706,
      -0.05491100996732712,
      0.015897903591394424,
      -0.01281675323843956,
      -0.018842458724975586,
      0.0573623850941658,
      -0.06762544810771942,
      -0.01721206307411194,
      -0.017749493941664696,
      0.028664693236351013,
      0.011934163980185986,
      0.0036906658206135035,
      0.03081955760717392,
      0.07729439437389374,
      0.02300841733813286,
      -0.048327118158340454,
      0.018067849799990654,
      0.0686870589852333,
      0.022040117532014847,
      0.0934741273522377,
      0.002863038331270218,
      -0.020940056070685387,
      0.0013946339022368193,
      -0.01754045858979225,
      -0.025240600109100342,
      0.016682403162121773,
      -0.020344434306025505,
      -0.008408980444073677,
      0.033783234655857086,
      -0.0618538036942482,
      0.08317852020263672,
      -0.024353189393877983,
      -0.07600319385528564,
      0.011590840294957161,
      0.027365315705537796,
      0.07664427906274796,
      0.030260860919952393,
      0.11268890649080276,
      -0.05044031888246536,
      -0.0008032252080738544,
      -0.01975458487868309,
      -0.002302729059010744,
      0.09605530649423599,
      0.014113676734268665,
      0.001887329388409853,
      -0.0035425513051450253,
      -0.06341011822223663,
      -0.02976958267390728,
      0.0399051308631897,
      0.02177642285823822,
      -0.029845062643289566,
      -0.009945839643478394,
      -0.031753379851579666,
      0.018040388822555542,
      0.03763492405414581,
      0.031646426767110825,
      -0.04345112293958664,
      0.0077974023297429085,
      0.0980125144124031,
      0.02215815894305706,
      0.08570956438779831,
      -0.0006011123186908662,
      0.07194912433624268,
      -0.02676442638039589,
      -0.0302992295473814,
      -0.06604819744825363,
      -0.05292218551039696,
      0.0042500426061451435,
      -0.04352422431111336,
      0.08222257345914841,
      0.035632532089948654,
      0.054880253970623016,
      0.015735594555735588,
      -0.0016883633797988296,
      -0.0029068654403090477,
      0.028346259146928787,
      0.013856989331543446,
      0.015586438588798046,
      -0.024272911250591278,
      -0.037334248423576355,
      0.06009891629219055,
      -0.04082714393734932,
      0.08727073669433594,
      -0.010796084068715572,
      0.016411641612648964,
      0.03177400305867195,
      -0.0006814068183302879,
      -0.012181577272713184,
      -0.029335172846913338,
      0.031724948436021805,
      0.04606986045837402,
      -0.0922221690416336,
      -0.02130969427525997,
      -0.034736517816782,
      -0.0012809456093236804,
      0.05354948714375496,
      0.03567596897482872,
      0.014605372212827206,
      0.06178057938814163,
      -0.012870010919868946,
      0.003963771741837263,
      0.027193572372198105,
      0.08720909059047699,
      -0.029216591268777847,
      -0.01253978069871664,
      0.05878125876188278,
      0.0752822607755661,
      0.00671880878508091,
      0.007063848897814751,
      0.03937623277306557,
      -0.022231174632906914,
      0.0045217289589345455,
      0.04479203000664711,
      0.009759179316461086,
      -0.10091228783130646,
      -0.004424297716468573,
      0.020462045446038246,
      0.0036594700068235397,
      0.1355314701795578,
      -0.03266924247145653,
      -0.021620294079184532,
      0.05893990769982338,
      0.06356698274612427,
      -0.03522424399852753,
      -0.027624066919088364,
      -0.06078943982720375,
      -0.018830901011824608,
      0.04341922327876091,
      -0.04685850068926811,
      0.09522635489702225,
      0.014942274428904057,
      -0.045327264815568924,
      -0.06332944333553314,
      -0.014799143187701702,
      0.014850722625851631,
      0.014896423555910587,
      0.08339536190032959,
      -0.030990341678261757,
      0.0073331925086677074,
      -0.0008820518269203603,
      -0.04233059659600258,
      0.0949702188372612,
      0.07542694360017776,
      -0.06857550144195557,
      0.008076557889580727,
      0.037186965346336365,
      0.050971854478120804,
      -0.20937471091747284,
      0.015204069204628468,
      -0.05518998205661774,
      0.022423740476369858,
      0.04056443274021149,
      -0.01597040705382824,
      -0.04085410386323929,
      0.09188588708639145,
      0.04793895035982132,
      -0.042270563542842865,
      -0.00226880656555295,
      0.0337713249027729,
      -0.04558092728257179,
      0.059754498302936554,
      0.01977028325200081,
      0.038287900388240814,
      0.021093452349305153,
      0.019373970106244087,
      0.05117709934711456,
      -0.024135177955031395,
      -0.02893543243408203,
      0.012281467206776142,
      0.06818173825740814,
      0.06051836535334587,
      -0.0361483171582222,
      -0.001920634531415999,
      -0.019453687593340874,
      -0.007420247886329889,
      0.03510665148496628,
      -0.019025886431336403,
      0.03647850826382637,
      -0.0025008111260831356,
      0.017664311453700066,
      0.029797736555337906,
      -0.13043656945228577,
      -0.015084846876561642,
      0.029797213152050972,
      -0.08194152265787125,
      -0.00015294941840693355,
      0.0010651923948898911,
      0.03438906744122505,
      0.023162392899394035,
      0.01832878403365612,
      0.02365995943546295,
      0.027135634794831276,
      0.10328798741102219,
      0.00424739858135581,
      0.0029080226086080074,
      0.01732833869755268,
      -0.052099622786045074,
      0.013818439096212387,
      0.0871211588382721,
      0.10697919130325317,
      0.0029964731074869633,
      -0.045278169214725494,
      0.031483255326747894,
      -0.020302068442106247,
      -0.009375544264912605,
      0.046341340988874435,
      0.008239245973527431,
      0.04846811667084694,
      0.02051062323153019,
      0.037242088466882706,
      0.017818965017795563,
      -0.019003288820385933,
      0.023655397817492485
    ],
    [
      -0.006575063802301884,
      -0.021965671330690384,
      0.03159117326140404,
      0.07257798314094543,
      0.037800971418619156,
      -0.010681725107133389,
      0.01737038977444172,
      -0.008578992448747158,
      0.0380702018737793,
      -0.030833212658762932,
      0.01381533034145832,
      -0.030233968049287796,
      0.05153118446469307,
      -0.017430109903216362,
      -0.02308296412229538,
      0.03172621130943298,
      0.021276280283927917,
      -0.11638293415307999,
      0.015386590734124184,
      0.018044710159301758,
      0.024541359394788742,
      -0.013177435845136642,
      0.00166771758813411,
      -0.12942175567150116,
      0.016793835908174515,
      0.0010741205187514424,
      -0.05496758595108986,
      -0.06209954991936684,
      -0.050816137343645096,
      -0.05728256329894066,
      0.04565848410129547,
      -0.0002938387624453753,
      0.005555449984967709,
      0.01864408142864704,
      -0.04535641893744469,
      -0.03183164820075035,
      -0.0180463045835495,
      0.003972233273088932,
      -0.11364078521728516,
      0.036395806819200516,
      -0.01974915713071823,
      0.06448458135128021,
      -0.012556195259094238,
      -0.006941215135157108,
      -0.006785888224840164,
      -0.049818005412817,
      0.08830563724040985,
      0.031500451266765594,
      -0.05897723510861397,
      -0.011527872644364834,
      0.019012518227100372,
      -0.023852188140153885,
      -0.07045627385377884,
      -0.0004261350550223142,
      -0.043468013405799866,
      -0.001372378901578486,
      0.014036823995411396,
      -0.04625318944454193,
      0.02811843901872635,
      0.025519762188196182,
      0.06956357508897781,
      0.07530040293931961,
      -0.00670913141220808,
      -0.07054411619901657,
      0.08456456661224365,
      0.0167030431330204,
      -0.0221902783960104,
      0.0014239365700632334,
      0.007312313187867403,
      -0.013820774853229523,
      0.0026801659259945154,
      0.024313461035490036,
      -0.07169728726148605,
      0.0034881809260696173,
      -0.032874833792448044,
      0.11422090977430344,
      0.03878362476825714,
      0.008120069280266762,
      0.044026073068380356,
      -0.038145504891872406,
      -0.03439456224441528,
      -0.03087186999619007,
      -0.03287201002240181,
      0.06053026020526886,
      -0.0515911802649498,
      -0.08005324751138687,
      -0.03565513715147972,
      0.01483997330069542,
      0.05118967965245247,
      0.045914385467767715,
      -0.04729589447379112,
      -0.07981319725513458,
      -0.008893280290067196,
      0.0025292723439633846,
      -0.03971906751394272,
      -0.04461019113659859,
      -0.03952891379594803,
      -0.03839760273694992,
      0.0022966316901147366,
      0.003743092529475689,
      -0.11522193998098373,
      0.042712148278951645,
      -0.017375672236084938,
      -0.016548028215765953,
      -0.05927000194787979,
      -0.010782863013446331,
      0.0029446480330079794,
      -0.02212464064359665,
      0.03208442032337189,
      -0.0036998188588768244,
      0.0788331925868988,
      0.07787428796291351,
      0.10664734989404678,
      0.050258733332157135,
      -0.048780471086502075,
      -0.017302241176366806,
      0.08494039624929428,
      -0.010386115871369839,
      0.010515192523598671,
      -0.05668984726071358,
      0.048970259726047516,
      -0.06555749475955963,
      0.05093032494187355,
      -0.0021106295753270388,
      -0.05478338152170181,
      -0.018656518310308456,
      -0.027399471029639244,
      0.04903310909867287,
      0.08808921277523041,
      0.053456250578165054,
      0.013708225451409817,
      0.04058436304330826,
      0.06941808760166168,
      0.018060943111777306,
      0.06360951066017151,
      -0.05161129683256149,
      -0.003359638387337327,
      0.0865367129445076,
      -0.008239787071943283,
      -0.004194522276520729,
      -0.008571126498281956,
      -0.044929955154657364,
      0.01866518147289753,
      0.04231327399611473,
      -0.029407506808638573,
      -0.018804721534252167,
      -0.00711999973282218,
      0.004848011769354343,
      0.05087396502494812,
      -0.04124709218740463,
      0.02316112630069256,
      0.07983773946762085,
      -0.015538400039076805,
      -0.04553768411278725,
      -0.0077071357518434525,
      0.021869895979762077,
      0.0860610231757164,
      -0.05867216736078262,
      -0.03815742954611778,
      -0.13039065897464752,
      0.0335874930024147,
      -0.01657562330365181,
      0.03110683150589466,
      -0.015380202792584896,
      -0.008046447299420834,
      0.0553276352584362,
      0.0037265997380018234,
      0.01741371862590313,
      -0.09812925010919571,
      0.050320565700531006,
      0.05289582163095474,
      -0.06457188725471497,
      -0.05154493451118469,
      -0.008705544285476208,
      0.08669895678758621,
      -0.013532835058867931,
      0.004561874084174633,
      0.078402079641819,
      -0.06627438962459564,
      -0.007287228014320135,
      0.009315219707787037,
      -0.034889012575149536,
      -0.02171449549496174,
      0.04951236769556999,
      -0.017656417563557625,
      0.020417679101228714,
      -0.02183743380010128,
      -0.021388595923781395,
      -0.013606654480099678,
      0.005522952880710363,
      0.004925829824060202,
      0.02730141393840313,
      0.08167135715484619,
      0.06360898911952972,
      -0.07730568200349808,
      0.020588360726833344,
      0.033623408526182175,
      -0.07817801088094711,
      -0.026856230571866035,
      -0.045769985765218735,
      0.035209108144044876,
      0.04193037375807762,
      -0.005934105720371008,
      -0.02079901657998562,
      0.10965317487716675,
      -0.01857910491526127,
      0.03244867920875549,
      -0.008854405954480171,
      -0.04137324169278145,
      -0.05089111998677254,
      -0.06064440310001373,
      0.008913947269320488,
      0.030410345643758774,
      -0.03877772390842438,
      -0.07783369719982147,
      -0.0365663580596447,
      0.015747465193271637,
      0.03975912556052208,
      -0.022720644250512123,
      0.04631233587861061,
      -0.0030903765000402927,
      0.028581814840435982,
      -0.07004614919424057,
      0.011727306060492992,
      0.006700440309941769,
      0.039464183151721954,
      0.07566460967063904,
      -0.02541409619152546,
      -0.015978483483195305,
      -0.0195369403809309,
      0.05063806474208832,
      -0.01601252891123295,
      -0.06178539991378784,
      0.05810794606804848,
      0.01171658094972372,
      0.020281806588172913,
      0.061121616512537,
      0.042945511639118195,
      0.0017797332257032394,
      -0.035376179963350296,
      0.02865765616297722,
      -0.01899988204240799,
      0.011195936240255833,
      0.05423864722251892,
      0.0033105858601629734,
      0.017691977322101593,
      -0.03334258869290352,
      -0.09415329992771149,
      -0.023361803963780403,
      0.07643872499465942,
      0.0542277991771698,
      -0.009614740498363972,
      0.05667237192392349,
      -0.07174589484930038,
      -0.03679885342717171,
      0.0232965387403965,
      0.0520818792283535,
      -0.04327568784356117,
      0.032462701201438904,
      0.07676945626735687,
      0.010968251153826714,
      0.009113872423768044,
      0.03628683462738991,
      0.04402807354927063,
      0.018935412168502808,
      0.011550129391252995,
      0.03686203435063362,
      0.012184989638626575,
      -0.04141006991267204,
      0.06859831511974335,
      0.02667568251490593,
      -0.07006916403770447,
      0.0308009572327137,
      -0.0208295751363039,
      -0.006241428665816784,
      -0.015625063329935074,
      0.03408835083246231,
      -0.03742441534996033,
      0.03549927473068237,
      0.0004838154709432274,
      -0.04379120469093323,
      -0.0006225535180419683,
      0.0278253685683012,
      0.028922121971845627,
      -0.0018231949070468545,
      0.055700551718473434,
      -0.05787422135472298,
      -0.01569906622171402,
      0.006998725235462189,
      -0.041991736739873886,
      0.002301847795024514,
      0.021982314065098763,
      0.03665610030293465,
      -0.02286466397345066,
      -0.011137709952890873,
      -0.03293253853917122,
      0.08779703080654144,
      0.01804324798285961,
      -0.05676794797182083,
      0.016552239656448364,
      0.0914628654718399,
      -0.012491803616285324,
      -0.0009006353211589158,
      0.07121126353740692,
      -0.016854887828230858,
      0.03472689911723137,
      0.01612103171646595,
      -0.037416137754917145,
      0.0023889157455414534,
      -0.05242922529578209,
      -0.02555326744914055,
      0.046445708721876144,
      0.01766357198357582,
      -0.03700059652328491,
      0.03562064841389656,
      -0.046159129589796066,
      0.005566275212913752,
      0.01952393539249897,
      -0.05493611469864845,
      -0.006294085644185543,
      -0.01617218367755413,
      0.0051982770673930645,
      0.06881700456142426,
      0.013247008435428143,
      0.06770781427621841,
      0.03168199583888054,
      -0.002358728786930442,
      -0.050920553505420685,
      -0.04249473661184311,
      -0.017333973199129105,
      0.10604283213615417,
      0.006608239375054836,
      0.03053039312362671,
      0.04233783110976219,
      0.02182973176240921,
      0.02641761302947998,
      0.0016217519296333194,
      0.014661495573818684,
      -0.028471967205405235,
      0.1084514930844307,
      0.07317663729190826,
      -0.02125803381204605,
      0.004619605839252472,
      -0.03779120743274689,
      -0.0005004690028727055,
      0.05040787160396576,
      0.00442112609744072,
      0.010868587531149387,
      0.040442585945129395,
      0.01862056367099285,
      -0.006447650957852602,
      0.05386705324053764,
      0.029263261705636978,
      0.08295829594135284,
      0.0687120109796524,
      0.056108906865119934,
      0.012005951255559921,
      0.022042667493224144,
      0.017133140936493874,
      0.01185776386409998,
      0.06131003424525261,
      -0.021366769447922707,
      0.008657053112983704,
      0.04919074848294258,
      -0.02257298305630684,
      0.02597532793879509,
      -0.01752868853509426,
      -0.0655578225851059,
      -0.07550722360610962,
      -0.046970922499895096,
      0.08750665187835693,
      -0.09095052629709244,
      0.021967999637126923,
      -0.044799208641052246,
      -0.021407131105661392,
      0.012951105833053589,
      0.0224797073751688,
      0.06047259271144867,
      0.049417078495025635,
      0.012541167438030243,
      0.029706649482250214,
      0.05303958058357239,
      -0.026382530108094215,
      0.09834747761487961,
      0.08178930729627609,
      -0.012820810079574585,
      -0.01726764440536499,
      0.03466469421982765,
      0.011858073994517326,
      0.006970162503421307,
      0.029786312952637672,
      -0.03340783715248108,
      -0.03956380486488342,
      -0.04711048677563667,
      -0.09825360029935837,
      -0.023773493245244026,
      0.05441075935959816,
      0.024807048961520195,
      0.012895986437797546,
      -0.0155564583837986,
      0.037209589034318924,
      0.06486044824123383,
      -0.02574588544666767,
      0.1180608943104744,
      0.027367716655135155,
      0.018685059621930122,
      0.0008080057450570166,
      -0.061186399310827255,
      0.02805285155773163,
      -0.05131460726261139,
      0.05217874050140381,
      -0.031075594946742058,
      -0.041285477578639984,
      -0.028323180973529816,
      0.005989120341837406,
      0.0680832490324974,
      0.02479497715830803,
      0.02396019920706749,
      0.06931300461292267,
      0.06949403882026672,
      0.019169120118021965,
      0.021157143637537956,
      0.07323506474494934,
      -0.0732228010892868,
      0.013297827914357185,
      -0.058112092316150665,
      0.005041393451392651,
      0.005803743842989206,
      -0.04216150566935539,
      0.02262279950082302,
      0.02238287217915058,
      -0.04952045530080795,
      -0.07375205308198929,
      0.03429264575242996,
      0.03282066434621811,
      -0.022740595042705536,
      0.032793719321489334,
      0.07537891715765,
      0.03606095910072327,
      -0.07997813075780869,
      -0.0305020809173584,
      0.03237957879900932,
      -0.0024223511572927237,
      0.03401106595993042,
      0.04012439399957657,
      0.026727620512247086,
      -0.05580078810453415,
      -0.0163571834564209,
      -0.06257188320159912,
      -0.020463302731513977,
      0.0027169843669980764,
      -0.01703159511089325,
      0.032435011118650436,
      0.010509545914828777,
      0.06149214506149292,
      -0.03468045964837074,
      -0.022941824048757553,
      -0.07632526755332947,
      -0.09070394188165665,
      0.0313979834318161,
      -0.0103296535089612,
      -0.03472571074962616,
      0.043476562947034836,
      -0.03583427146077156,
      -0.11491855978965759,
      -0.07067941129207611,
      -0.03797425702214241,
      -0.018701722845435143,
      -0.026609264314174652,
      0.07046691328287125,
      0.06583356857299805,
      -0.018023012205958366,
      -0.11210756748914719,
      0.03867888078093529,
      -0.057291433215141296,
      0.042923763394355774,
      0.005880226846784353,
      -0.057150375097990036,
      0.03786541894078255,
      -0.0437910333275795,
      0.028089655563235283,
      0.012524916790425777,
      -0.0542539618909359,
      0.04982190206646919,
      0.10969775170087814,
      -0.02657613717019558,
      0.0337829627096653,
      0.016506141051650047,
      -0.07484466582536697,
      0.006090921349823475,
      -0.012952381744980812,
      0.012075693346560001,
      0.022437728941440582,
      0.001311064581386745,
      0.020409470424056053,
      0.014381627552211285,
      0.0011784144444391131,
      0.05821901559829712,
      0.0276291873306036,
      0.048792313784360886,
      -0.04908888787031174,
      -0.033261291682720184,
      -0.049388587474823
    ],
    [
      0.0017131087370216846,
      -0.051072265952825546,
      0.04922669380903244,
      0.006530920043587685,
      -0.07450683414936066,
      0.0617629811167717,
      0.0548117533326149,
      0.037230852991342545,
      -0.028175855055451393,
      -0.05577618256211281,
      -0.02671334147453308,
      0.012985841371119022,
      0.07326153665781021,
      -0.06575377285480499,
      0.01756926439702511,
      0.05195337533950806,
      -0.08588463813066483,
      -0.03288733586668968,
      -0.03418804705142975,
      -0.02106832154095173,
      0.018137482926249504,
      0.0555865541100502,
      -0.07851611077785492,
      -0.08299922198057175,
      -0.02203342877328396,
      0.049803562462329865,
      0.04867558926343918,
      -0.042785752564668655,
      -0.019205132499337196,
      0.007609770633280277,
      0.045278917998075485,
      0.042394377291202545,
      0.07233649492263794,
      0.040046073496341705,
      0.019508754834532738,
      0.045622456818819046,
      -0.0027715926989912987,
      -0.0313948355615139,
      0.026530256494879723,
      -0.008755876682698727,
      -0.0019301377469673753,
      0.048277053982019424,
      0.05224527046084404,
      -0.05771308019757271,
      -0.016867022961378098,
      0.06071823835372925,
      0.008447018451988697,
      0.026670854538679123,
      -0.03389411047101021,
      -0.02405289001762867,
      -0.031360313296318054,
      -0.005000049248337746,
      0.07052317261695862,
      0.01061490923166275,
      -0.00902414321899414,
      -0.0005949764745309949,
      0.005308494903147221,
      0.004357875324785709,
      -0.06718840450048447,
      0.016037551686167717,
      -0.004176663234829903,
      0.04748774692416191,
      0.005889476276934147,
      0.02378741465508938,
      0.05736204981803894,
      -0.01657194457948208,
      0.02263074740767479,
      0.031572744250297546,
      0.015313063748180866,
      -0.07750066369771957,
      0.06709208339452744,
      0.03793879225850105,
      -0.03109331801533699,
      0.00010862753697438166,
      0.0390864759683609,
      -0.015732798725366592,
      0.032849665731191635,
      -0.04713325947523117,
      -0.04409933090209961,
      -0.0721118152141571,
      0.03133165091276169,
      -0.030555645003914833,
      0.010188028216362,
      -0.02124846912920475,
      -0.05432944744825363,
      0.009132352657616138,
      0.015570373274385929,
      -0.009766779839992523,
      -0.016570281237363815,
      0.0012183673679828644,
      -0.024106638506054878,
      -0.006498879753053188,
      0.015506064519286156,
      -0.0048719653859734535,
      0.026792805641889572,
      0.029579252004623413,
      0.055010151118040085,
      -0.10760892927646637,
      -0.040138017386198044,
      -0.02612663246691227,
      0.0321711003780365,
      -0.009886929765343666,
      0.020600618794560432,
      -0.03481529280543327,
      0.0045598591677844524,
      -0.08316884189844131,
      -0.016823388636112213,
      0.011974019929766655,
      -0.00886528566479683,
      -0.04591165855526924,
      0.07696541398763657,
      -0.012689291499555111,
      -0.03807331249117851,
      0.12414319813251495,
      0.029485570266842842,
      -0.06896886229515076,
      -0.014480730518698692,
      -0.014574642293155193,
      0.041144296526908875,
      0.014478501863777637,
      -0.006801417097449303,
      0.06651271879673004,
      0.0024772933684289455,
      0.029048258438706398,
      0.024506835266947746,
      -0.06490889191627502,
      -0.0017734175780788064,
      0.01580466888844967,
      0.015369546599686146,
      0.0438300184905529,
      0.004311605356633663,
      -0.0020563239231705666,
      -0.039726272225379944,
      0.043409563601017,
      -0.008766558952629566,
      -0.0174461267888546,
      0.06189948692917824,
      -0.0197891965508461,
      -0.01959376595914364,
      -0.04803729057312012,
      0.005685377400368452,
      0.0614863783121109,
      0.09435875713825226,
      -0.007117757108062506,
      0.0213561300188303,
      0.06035268306732178,
      -0.04335039108991623,
      -0.02198776975274086,
      -0.0544595904648304,
      -0.01851837709546089,
      -0.10233715921640396,
      -0.0011008360888808966,
      0.06395283341407776,
      0.022246498614549637,
      0.0021763162221759558,
      0.015588878653943539,
      0.07043851912021637,
      0.005689708516001701,
      0.023259444162249565,
      0.0827709287405014,
      0.08884743601083755,
      -0.015328367240726948,
      0.0031263940036296844,
      0.08277662843465805,
      0.020930157974362373,
      0.08772820234298706,
      0.005636910442262888,
      -0.006313295103609562,
      -0.04609035700559616,
      -0.08306127041578293,
      0.056732699275016785,
      0.01397223025560379,
      -0.05332256853580475,
      -0.01905868574976921,
      0.05806412547826767,
      -0.04300326853990555,
      -0.06259282678365707,
      -0.09454528242349625,
      -0.0443124882876873,
      -0.06007075309753418,
      -0.015293104574084282,
      0.015809834003448486,
      0.007286272011697292,
      0.0338406004011631,
      -0.08262012898921967,
      0.015029815956950188,
      -0.05216485261917114,
      0.022701134905219078,
      -0.06395955383777618,
      -0.04228869453072548,
      -0.013409731909632683,
      -0.025738760828971863,
      0.07067032903432846,
      0.04897244647145271,
      0.020760659128427505,
      -0.05587773025035858,
      -0.015453471802175045,
      0.03443565219640732,
      0.06810683012008667,
      0.02573881670832634,
      0.021834811195731163,
      -0.02167990244925022,
      0.019463036209344864,
      -0.010176150128245354,
      0.06358366459608078,
      -0.041856952011585236,
      0.01218173373490572,
      -0.07733146101236343,
      0.056946706026792526,
      0.008678150363266468,
      0.018929846584796906,
      -0.022616591304540634,
      -0.008465484715998173,
      -0.03443584963679314,
      0.0370817705988884,
      -0.003833227790892124,
      -0.031479403376579285,
      0.050288282334804535,
      0.06695910543203354,
      -0.00185285578481853,
      0.012453444302082062,
      0.057698339223861694,
      0.021250499412417412,
      0.021852293983101845,
      -0.02116861194372177,
      -0.017711743712425232,
      0.0014777552569285035,
      -0.06166660040616989,
      0.05560164526104927,
      0.015949849039316177,
      0.05640144646167755,
      0.05703452602028847,
      -0.12078364193439484,
      -0.014513581991195679,
      0.1218959391117096,
      -0.021769588813185692,
      0.03130706027150154,
      0.02713511511683464,
      0.002262382535263896,
      0.007466747425496578,
      0.08381601423025131,
      -0.056089188903570175,
      0.00463477149605751,
      0.05253075435757637,
      -0.010587324388325214,
      -0.001172090065665543,
      -0.04837123677134514,
      0.022765420377254486,
      -0.09328974038362503,
      0.03814449906349182,
      0.04763350263237953,
      -0.02266085147857666,
      0.041593167930841446,
      0.02866915985941887,
      0.036019399762153625,
      0.06294120848178864,
      -0.06279407441616058,
      0.03427984192967415,
      -0.059890761971473694,
      -0.08207854628562927,
      -0.018631501123309135,
      -0.022561494261026382,
      -0.020294683054089546,
      -0.005849497392773628,
      -0.07306446135044098,
      0.004094619769603014,
      -0.07793822139501572,
      -0.004862961824983358,
      -0.0288015715777874,
      0.06488059461116791,
      0.038896434009075165,
      0.06970082968473434,
      -0.03501754626631737,
      0.06445565074682236,
      0.0031003961339592934,
      -0.010981852188706398,
      0.028544779866933823,
      -0.08451427519321442,
      -0.01953042298555374,
      0.01710779406130314,
      -0.019782697781920433,
      0.06062650680541992,
      0.07052058726549149,
      0.07624634355306625,
      0.010924376547336578,
      0.04605995491147041,
      0.012270396575331688,
      -0.010616323910653591,
      0.011861809529364109,
      -0.06276176869869232,
      0.004263385199010372,
      0.012905593030154705,
      -0.06069844588637352,
      0.015412732027471066,
      0.07662289589643478,
      0.013724259100854397,
      0.019840124994516373,
      0.0014096610248088837,
      0.03228076547384262,
      -0.07874436676502228,
      0.0004979585064575076,
      -0.058912426233291626,
      -0.024512698873877525,
      0.00016074649465736002,
      -0.005177979823201895,
      -0.0692363828420639,
      0.07585856318473816,
      0.029434241354465485,
      0.03538103401660919,
      -0.011874300427734852,
      0.022179413586854935,
      0.0010009300895035267,
      0.03299693018198013,
      -0.01000365149229765,
      0.06352861225605011,
      -0.015127243474125862,
      -0.07267864793539047,
      -0.06309829652309418,
      0.020371319726109505,
      0.03872266411781311,
      0.045216578990221024,
      -0.022833185270428658,
      0.027652578428387642,
      -0.024906260892748833,
      0.0720759704709053,
      0.03637700900435448,
      -0.061305779963731766,
      0.04045683145523071,
      -0.04313924163579941,
      -0.07433928549289703,
      -0.0013927753316238523,
      -0.0949854627251625,
      -0.014179782010614872,
      -0.043956246227025986,
      -0.015435216948390007,
      -0.0023786132223904133,
      0.007706856820732355,
      -0.06921346485614777,
      -0.015109755098819733,
      0.041211530566215515,
      -0.018070634454488754,
      0.0511481799185276,
      -0.002178875496610999,
      -0.02655918151140213,
      0.054843731224536896,
      -0.017915425822138786,
      0.04030732065439224,
      -0.014168581925332546,
      -0.0011495845392346382,
      0.04580512270331383,
      0.0854145884513855,
      -0.01575089618563652,
      -0.01039896346628666,
      -0.02864692732691765,
      0.11740266531705856,
      0.048716951161623,
      -0.008793536573648453,
      -0.05616621673107147,
      0.020130755379796028,
      0.0007861879421398044,
      0.03860664367675781,
      -0.009789454750716686,
      -0.046449799090623856,
      -0.002086094580590725,
      0.08882638812065125,
      -0.01896115206182003,
      0.028001956641674042,
      -0.019697021692991257,
      -0.03385138511657715,
      -0.05890845134854317,
      0.1516190618276596,
      0.007876316085457802,
      -0.03552445024251938,
      -0.0050095501355826855,
      0.11189480870962143,
      -0.03197585791349411,
      -0.01565462537109852,
      0.039530280977487564,
      -0.020731037482619286,
      -0.0005297109019011259,
      -0.022471914067864418,
      -0.007326821330934763,
      -0.027372365817427635,
      -0.07801797986030579,
      0.016305871307849884,
      -0.04024240002036095,
      0.06512738019227982,
      0.007225723937153816,
      0.040728550404310226,
      0.0018282226519659162,
      -0.020336130633950233,
      0.06725944578647614,
      -0.0243658609688282,
      0.02913784421980381,
      0.03019670397043228,
      0.0020395643077790737,
      0.1027485728263855,
      -0.03778070956468582,
      -0.05398138239979744,
      0.05274777486920357,
      0.0543435700237751,
      -0.026095636188983917,
      0.0289449580013752,
      -0.006122911814600229,
      0.038422610610723495,
      -0.04231777414679527,
      0.010774758644402027,
      0.05670592561364174,
      0.06226833164691925,
      -0.03161504492163658,
      -0.0346362330019474,
      -0.015955353155732155,
      -0.03935644030570984,
      0.04224078729748726,
      0.01975499652326107,
      0.01054482813924551,
      0.08393362164497375,
      -0.04967612773180008,
      0.015759944915771484,
      -0.013159633614122868,
      -0.0007502310909330845,
      0.04804649204015732,
      0.05593695864081383,
      0.01758197322487831,
      0.03690439462661743,
      0.009170150384306908,
      0.04749037325382233,
      0.04368986189365387,
      -0.0006739518139511347,
      -0.01766068860888481,
      -0.050079040229320526,
      -0.0763736292719841,
      0.013361481949687004,
      -0.03558550775051117,
      -0.048010263592004776,
      -0.007612959481775761,
      -0.07664438337087631,
      -0.02170468308031559,
      -0.040005408227443695,
      -0.02233036793768406,
      -0.03174309805035591,
      -0.03602750226855278,
      0.0238350797444582,
      0.01078563742339611,
      -0.0068321931175887585,
      0.010840777307748795,
      -0.01062664482742548,
      0.03540994971990585,
      -0.10459310561418533,
      0.03484662249684334,
      0.05440293997526169,
      0.0697348341345787,
      -0.054759182035923004,
      -0.01082789059728384,
      -0.0088130421936512,
      0.041849300265312195,
      0.0379648320376873,
      -0.06647367030382156,
      0.11145660281181335,
      0.039712872356176376,
      -0.037483371794223785,
      0.027492878958582878,
      -0.023890916258096695,
      -0.039292242377996445,
      -0.025393934920430183,
      -0.0010550732258707285,
      -0.01867827959358692,
      0.06620072573423386,
      -0.00803142599761486,
      -0.012393875978887081,
      0.024403253570199013,
      -0.05341073125600815,
      0.041720349341630936,
      -0.027578771114349365,
      -0.008100856095552444,
      -0.023297501727938652,
      0.07773284614086151,
      0.07033132016658783,
      0.00955090019851923,
      -0.024415530264377594,
      0.024054376408457756,
      0.07066837698221207,
      -0.07776805013418198,
      -0.03065815567970276,
      0.025448249652981758,
      0.027180824428796768,
      -0.02413598634302616,
      -0.030980881303548813,
      -0.014777963049709797,
      -0.09515304863452911,
      -0.022085057571530342,
      0.04958966374397278,
      0.000477581808809191,
      -0.0447465181350708,
      0.03287652134895325,
      -0.008751997724175453,
      -0.021565085276961327,
      0.030357802286744118,
      0.04701509326696396,
      -0.0554499551653862,
      0.023829394951462746,
      -0.05078301951289177,
      -0.0634336993098259
    ],
    [
      0.05132008716464043,
      0.02188011072576046,
      0.0839272066950798,
      -0.10413031280040741,
      0.038228876888751984,
      -0.030871806666254997,
      -0.07818371057510376,
      0.02809746004641056,
      3.5839011616189964e-06,
      -0.05377684906125069,
      -0.008418421261012554,
      0.035462647676467896,
      -0.0033208029344677925,
      0.04379104822874069,
      -0.032178573310375214,
      -0.08001748472452164,
      0.08599371463060379,
      0.010261397808790207,
      -0.03684041276574135,
      0.08557665348052979,
      -0.024466710165143013,
      0.01539785135537386,
      0.0011136948596686125,
      -0.018315942957997322,
      0.05258601903915405,
      -0.019832633435726166,
      -0.03674527257680893,
      -0.06457239389419556,
      -0.02584741823375225,
      -0.0033267491962760687,
      0.10491819679737091,
      0.04413927346467972,
      0.011163252405822277,
      0.04589778929948807,
      0.014178629033267498,
      0.021115364506840706,
      -0.04434990882873535,
      0.029478831216692924,
      0.011857962235808372,
      -0.013360578566789627,
      0.005081852898001671,
      -0.026165494695305824,
      -0.009748467244207859,
      0.04257957264780998,
      0.003909656777977943,
      -0.01646680384874344,
      0.008349570445716381,
      0.02355002611875534,
      -0.03966480493545532,
      0.027145221829414368,
      0.05808575078845024,
      0.03129028156399727,
      0.04830675944685936,
      -0.006576834712177515,
      -0.030008988454937935,
      0.022192619740962982,
      0.05239688232541084,
      0.036000434309244156,
      0.020309899002313614,
      -0.043566565960645676,
      0.03638925775885582,
      -0.03879968449473381,
      -0.09680642187595367,
      0.058380864560604095,
      -0.03786624222993851,
      -0.025448741391301155,
      0.03980299085378647,
      0.03598763421177864,
      0.14927056431770325,
      0.019240504130721092,
      0.05024763196706772,
      0.1044996902346611,
      0.04179934412240982,
      0.045624203979969025,
      -0.027864206582307816,
      -0.011621610261499882,
      0.04264241084456444,
      0.005396504420787096,
      0.14141616225242615,
      0.02127811871469021,
      0.09334943443536758,
      -0.01913284696638584,
      0.05959189310669899,
      0.013533638790249825,
      0.05319853127002716,
      0.03368248790502548,
      0.040717147290706635,
      -0.017843298614025116,
      -0.06201547384262085,
      -0.05211099609732628,
      -0.035475850105285645,
      0.003155139274895191,
      0.06587868928909302,
      -0.057557977735996246,
      -0.03107963688671589,
      -0.05118013173341751,
      -0.032742246985435486,
      0.02324652671813965,
      0.009478852152824402,
      -0.014231503941118717,
      -0.0634658932685852,
      0.005293708760291338,
      -0.040510110557079315,
      -0.007276445627212524,
      -0.024972865357995033,
      0.015717631205916405,
      -0.02116045542061329,
      -0.026638705283403397,
      0.01072971522808075,
      0.010838387534022331,
      0.11411162465810776,
      -0.03864141181111336,
      0.029419763013720512,
      -0.010984096676111221,
      -0.0500522218644619,
      -0.06991780549287796,
      -0.02631850354373455,
      -0.035009805113077164,
      -0.010890298523008823,
      0.01240551844239235,
      -0.029233137145638466,
      -0.04418472200632095,
      0.08189494162797928,
      0.008010848425328732,
      0.0323115810751915,
      0.02626456879079342,
      -0.0018504805630072951,
      0.03196895867586136,
      -0.0015302883693948388,
      0.04057608172297478,
      0.11094510555267334,
      -0.010272054001688957,
      0.024196263402700424,
      -0.05289797484874725,
      -0.007605144288390875,
      0.03277145326137543,
      -0.021006856113672256,
      -0.030032090842723846,
      0.016133276745676994,
      0.022045210003852844,
      0.07273376733064651,
      -0.05172685533761978,
      -0.030551472678780556,
      -0.028450626879930496,
      0.04059875011444092,
      -0.0364355742931366,
      0.02383587136864662,
      0.0980326309800148,
      0.006473364774137735,
      -0.0061207362450659275,
      -0.005456830374896526,
      0.05845525860786438,
      -0.02256590873003006,
      -0.02894797921180725,
      0.01519531849771738,
      0.009171901270747185,
      0.05494270101189613,
      -0.09002336114645004,
      -0.03793809562921524,
      0.08142036944627762,
      -0.02171511948108673,
      0.005582993384450674,
      -0.06057816371321678,
      -0.039231158792972565,
      -0.10534055531024933,
      -0.018459992483258247,
      0.09866704046726227,
      -0.017223654314875603,
      -0.11695030331611633,
      -0.1093498021364212,
      -0.03853599727153778,
      0.0038115752395242453,
      0.021732313558459282,
      0.0730074867606163,
      -0.009282545186579227,
      -0.05062981694936752,
      0.07221316546201706,
      0.02344139665365219,
      -0.022265486419200897,
      0.01128766406327486,
      0.09797323495149612,
      0.026563923805952072,
      -0.07980835437774658,
      -0.05384426191449165,
      -0.006921178195625544,
      -0.011348891071975231,
      -0.10434900969266891,
      -0.02377491071820259,
      0.07503160834312439,
      -0.0409412607550621,
      -0.04761578515172005,
      -0.029933065176010132,
      0.016591006889939308,
      0.04476610943675041,
      -0.0011580943828448653,
      0.055039893835783005,
      -0.02834966965019703,
      -0.02018653228878975,
      -0.05970989540219307,
      0.07494599372148514,
      0.023134879767894745,
      -0.03171803057193756,
      -0.016925200819969177,
      0.04398692771792412,
      0.050657037645578384,
      0.015595854260027409,
      0.045761555433273315,
      -0.021039295941591263,
      0.06376644968986511,
      0.06076359376311302,
      -0.0840577706694603,
      -0.0029789351392537355,
      0.003509646048769355,
      0.003924468997865915,
      0.02232714369893074,
      -0.023595307022333145,
      4.5286233216756955e-05,
      -0.009453837759792805,
      -0.0341944694519043,
      0.019690588116645813,
      -0.010266922414302826,
      0.009249994531273842,
      -0.00024655056768096983,
      0.007021598983556032,
      -0.04881051927804947,
      0.03515952080488205,
      -0.04325560852885246,
      0.05326422303915024,
      -0.04380513355135918,
      -0.04380302131175995,
      -0.018748505041003227,
      0.00927661545574665,
      0.025029469281435013,
      0.03038397803902626,
      -0.028825845569372177,
      0.0011867890134453773,
      -0.0088247861713171,
      -0.056127674877643585,
      -0.0629744678735733,
      -0.06045074760913849,
      0.06109781935811043,
      -0.0023116336669772863,
      0.05732062831521034,
      0.027737589552998543,
      0.04863452911376953,
      -0.003959961235523224,
      0.0469660721719265,
      -0.0192742720246315,
      0.014646127820014954,
      -0.03965676575899124,
      -0.03837621584534645,
      0.011846328154206276,
      -0.029063289985060692,
      0.027756748721003532,
      0.015363479033112526,
      0.06236088275909424,
      0.04367978498339653,
      0.10500572621822357,
      0.010755389928817749,
      0.07804226875305176,
      -0.013370034284889698,
      0.034845899790525436,
      0.0054101417772471905,
      0.06387532502412796,
      0.08858898282051086,
      -0.045200496912002563,
      -0.04115280881524086,
      0.05981995537877083,
      0.05527637153863907,
      -0.02155989594757557,
      -0.012763449922204018,
      0.007848819717764854,
      0.058831654489040375,
      0.052075568586587906,
      0.02216705121099949,
      -0.0541018582880497,
      -0.0046917288564145565,
      -0.0014115329831838608,
      0.0023783231154084206,
      0.016896791756153107,
      0.020925339311361313,
      0.016592778265476227,
      0.0379534512758255,
      0.0016640924150124192,
      0.05904348939657211,
      -0.036268047988414764,
      0.0075487433932721615,
      0.005780056118965149,
      -0.030142061412334442,
      -0.02256319299340248,
      0.02839764393866062,
      0.02299521304666996,
      -0.052649982273578644,
      0.0005536717944778502,
      0.017169389873743057,
      0.024814797565340996,
      -0.07202821224927902,
      0.03153347596526146,
      0.009101132862269878,
      0.019851116463541985,
      0.04506352171301842,
      0.039488840848207474,
      -0.027099594473838806,
      0.1552116721868515,
      0.10179179161787033,
      -0.008750740438699722,
      -0.052699849009513855,
      -0.012517358176410198,
      0.003525989828631282,
      -0.00185025401879102,
      -0.02425364963710308,
      0.011201372370123863,
      -0.0010691953357309103,
      -0.09875379502773285,
      0.07600639760494232,
      0.020888295024633408,
      -0.04652944579720497,
      -0.05537595599889755,
      0.09275400638580322,
      -0.006702865473926067,
      -0.09681674838066101,
      0.05174553766846657,
      -0.023315098136663437,
      0.007540413644164801,
      0.011368684470653534,
      0.007787088863551617,
      -0.050163526087999344,
      -0.02848515473306179,
      -0.016186323016881943,
      -0.004423515871167183,
      -0.003122251247987151,
      0.03141824156045914,
      -0.044274553656578064,
      0.008271167986094952,
      -0.011991366744041443,
      -0.04929656162858009,
      -0.07447356730699539,
      0.014805750921368599,
      -0.05336925387382507,
      -0.032991811633110046,
      -0.04666035994887352,
      -0.01161931175738573,
      0.002952991286292672,
      -0.0683239996433258,
      0.005783392582088709,
      -0.03766411915421486,
      0.044791072607040405,
      -0.0014354599406942725,
      0.02346637286245823,
      0.09357629716396332,
      0.01655437983572483,
      -0.01762090064585209,
      -0.0183962881565094,
      -0.005687905941158533,
      -0.042372241616249084,
      -0.0579136498272419,
      0.03486030921339989,
      -0.01791362091898918,
      0.013607736676931381,
      0.034763578325510025,
      -0.06106387451291084,
      0.007448451593518257,
      -0.062270570546388626,
      -0.10491163283586502,
      0.016823800280690193,
      0.021261896938085556,
      0.02044006437063217,
      -0.09759728610515594,
      -0.016194209456443787,
      -0.059106260538101196,
      -0.004060169216245413,
      0.031123455613851547,
      0.009960480965673923,
      -0.0024133273400366306,
      0.021438416093587875,
      0.05469600111246109,
      -0.0014399596257135272,
      -0.06395946443080902,
      -0.07653773576021194,
      -0.058621060103178024,
      -0.03689641132950783,
      -0.02367635816335678,
      0.030038299039006233,
      -0.05339797958731651,
      -0.012081056833267212,
      -0.015254712663590908,
      -0.05445941165089607,
      -0.14822760224342346,
      0.028735026717185974,
      -0.049156226217746735,
      0.0017465451965108514,
      -0.028182441368699074,
      -0.0269787535071373,
      0.02950696460902691,
      0.02821376919746399,
      -0.0026649716310203075,
      0.019413726404309273,
      -0.05322554334998131,
      -0.04960213229060173,
      0.014610500074923038,
      -0.0018370982725173235,
      0.05439465120434761,
      -0.04194149747490883,
      -0.03613487631082535,
      0.047036346048116684,
      -0.03577793389558792,
      0.015886304900050163,
      0.03351650759577751,
      0.002353906398639083,
      -0.0772906020283699,
      -0.0010524302488192916,
      -0.029926661401987076,
      0.03276274353265762,
      0.04269171506166458,
      0.0470125712454319,
      -0.017048904672265053,
      0.06186800077557564,
      -0.012512385845184326,
      -0.04438319802284241,
      0.02927706204354763,
      -0.07721854746341705,
      0.034275807440280914,
      0.09914111346006393,
      -0.007696657907217741,
      0.02500874176621437,
      -0.00586311798542738,
      0.008140440098941326,
      0.015115861780941486,
      -0.008650422096252441,
      0.029371578246355057,
      -0.024337386712431908,
      0.03276335820555687,
      0.02191273309290409,
      -0.0018146166112273932,
      -0.014716080389916897,
      -0.02954315021634102,
      0.019295448437333107,
      0.02995375543832779,
      -0.07160481810569763,
      -0.07169187068939209,
      0.009787795133888721,
      -0.0216190367937088,
      0.002328546717762947,
      -0.008578917942941189,
      0.026952799409627914,
      0.01631103828549385,
      -0.02245175465941429,
      -0.014543363824486732,
      -0.015784885734319687,
      -0.08867207914590836,
      0.03265281021595001,
      -0.059311237186193466,
      0.047499265521764755,
      -0.05428864806890488,
      -0.049097515642642975,
      -0.03110954351723194,
      -0.038096778094768524,
      0.015834201127290726,
      0.01110943965613842,
      0.016281383112072945,
      -0.028222667053341866,
      0.1426902413368225,
      0.02517227828502655,
      0.01819831132888794,
      0.05816751718521118,
      -0.020803935825824738,
      0.05285311117768288,
      0.06434476375579834,
      0.04077742621302605,
      -0.035737913101911545,
      0.039749667048454285,
      -0.07536448538303375,
      -0.008902853354811668,
      -0.0552089549601078,
      0.027595803141593933,
      -0.042641717940568924,
      0.04227873682975769,
      0.0165997501462698,
      0.059249069541692734,
      -0.03909685090184212,
      -0.026079652830958366,
      -0.004872254561632872,
      -0.043975118547677994,
      -0.08503099530935287,
      0.05551429092884064,
      -0.000558326777536422,
      -0.004127997439354658,
      -0.09797303378582001,
      0.014397869817912579,
      -0.023917680606245995,
      -0.018839269876480103,
      -0.04362049698829651,
      -0.031069116666913033,
      -0.04129141569137573,
      0.007513099815696478,
      0.03305421024560928,
      0.0019077364122495055,
      -0.002804921939969063,
      -0.010586854070425034,
      0.06489785015583038,
      0.052539438009262085,
      0.01799510233104229,
      0.010839750990271568
    ],
    [
      0.022721655666828156,
      0.010094922967255116,
      -0.04066500440239906,
      0.047873251140117645,
      -0.03766005113720894,
      0.005697648506611586,
      0.026886960491538048,
      -0.06964364647865295,
      0.024844782426953316,
      0.076497383415699,
      0.011850747279822826,
      -0.013790475204586983,
      -0.0241391584277153,
      0.014246221631765366,
      0.0023337174206972122,
      -0.029892217367887497,
      0.026995765045285225,
      0.008330969139933586,
      0.027071300894021988,
      -0.03985786437988281,
      0.06779185682535172,
      0.020873088389635086,
      -0.03462688997387886,
      -0.015319397673010826,
      0.004091774113476276,
      -0.008616521954536438,
      -0.018743613734841347,
      -0.045468077063560486,
      0.05450628325343132,
      0.014670775271952152,
      0.03597481921315193,
      0.051431428641080856,
      -0.08110319823026657,
      -0.025017978623509407,
      -0.05755002424120903,
      -0.036580417305231094,
      -0.031576886773109436,
      0.021385975182056427,
      0.014609389938414097,
      0.007821587845683098,
      -0.0563671849668026,
      -0.016066057607531548,
      0.0032300979364663363,
      0.013400093652307987,
      -0.009891337715089321,
      -0.0319785512983799,
      -0.029723361134529114,
      -0.04158364608883858,
      0.04170369729399681,
      -0.005863056518137455,
      0.0051501160487532616,
      0.0064504100009799,
      -0.05735192075371742,
      0.021096138283610344,
      -0.07856087386608124,
      -0.001143168774433434,
      0.10978627949953079,
      -0.006982714403420687,
      -0.003735132282599807,
      -0.040788520127534866,
      0.021480713039636612,
      0.056241828948259354,
      0.003111115889623761,
      -0.020678380504250526,
      -0.022918302565813065,
      0.028414761647582054,
      0.03301120176911354,
      -0.03320855274796486,
      -0.06440035253763199,
      0.048005249351263046,
      -0.0034920531325042248,
      0.05531933158636093,
      -0.007380007300525904,
      -0.027268197387456894,
      -0.022614426910877228,
      -0.0046088541857898235,
      -0.03416024520993233,
      -0.04015342518687248,
      0.017354533076286316,
      0.010148863308131695,
      0.027984216809272766,
      0.03534216061234474,
      0.040528684854507446,
      -0.030511826276779175,
      -0.043893877416849136,
      0.014811314642429352,
      0.007556647062301636,
      -0.032932206988334656,
      0.012509094551205635,
      -0.05189177766442299,
      0.14860862493515015,
      -0.06013813242316246,
      0.014175963588058949,
      -0.086270771920681,
      0.0024838661774992943,
      -0.01058297697454691,
      0.021715309470891953,
      0.08164539933204651,
      0.06637640297412872,
      0.00912944134324789,
      -0.023624232038855553,
      0.025900276377797127,
      -0.02899765409529209,
      0.08327537029981613,
      0.08897987008094788,
      -0.01777719333767891,
      -0.013149794191122055,
      -0.08927077054977417,
      0.06282856315374374,
      0.014002995565533638,
      0.027754751965403557,
      0.0459040142595768,
      -0.003899192437529564,
      0.008049157448112965,
      -0.05636413395404816,
      -0.029332516714930534,
      0.030681408941745758,
      -0.04559504985809326,
      0.03807348757982254,
      0.016807295382022858,
      0.007239941973239183,
      -0.04928234964609146,
      -0.004917639307677746,
      -0.039519716054201126,
      -0.05753198638558388,
      0.06142059713602066,
      0.03289924934506416,
      0.019752267748117447,
      0.05622563138604164,
      0.006214888766407967,
      -0.029748912900686264,
      -0.00011801648361142725,
      -0.04315868020057678,
      -0.014401819556951523,
      0.024478454142808914,
      -0.025592131540179253,
      -0.005338829476386309,
      0.03804272040724754,
      -0.014772284775972366,
      0.03837955743074417,
      0.021814757958054543,
      -0.017104893922805786,
      0.06290756911039352,
      -0.04882058501243591,
      0.003987066447734833,
      -0.10213017463684082,
      0.017755957320332527,
      0.03971963748335838,
      0.06028910353779793,
      -0.012127217836678028,
      0.01172975730150938,
      0.013180103152990341,
      0.1015273854136467,
      -0.03287845849990845,
      0.008981095626950264,
      0.04834321513772011,
      0.04234663024544716,
      0.014540272764861584,
      -0.05007726326584816,
      -0.039393287152051926,
      -0.017118113115429878,
      -0.010822688229382038,
      -0.02199491672217846,
      0.047542884945869446,
      -0.020367583259940147,
      -0.06560147553682327,
      -0.0049673086032271385,
      0.01809605583548546,
      -0.04946526885032654,
      -0.05682867392897606,
      -0.05077967792749405,
      0.020370356738567352,
      0.011759890243411064,
      0.022580040618777275,
      0.004370727110654116,
      -0.09006081521511078,
      -0.03936264291405678,
      -0.03147510439157486,
      0.031309593468904495,
      -0.03170912712812424,
      -0.027338484302163124,
      -0.0128133250400424,
      0.03489407151937485,
      0.010675428435206413,
      -0.006531235761940479,
      0.026326706632971764,
      0.021143727004528046,
      -0.08274661004543304,
      0.08655799925327301,
      -0.014429186470806599,
      0.05777254328131676,
      -0.03281775116920471,
      -0.04435368999838829,
      0.034781403839588165,
      -0.09418490529060364,
      -0.03450873866677284,
      -0.0062946742400527,
      0.04124357923865318,
      0.01416065450757742,
      0.05756800249218941,
      -0.034835733473300934,
      -0.051886118948459625,
      -0.019962437450885773,
      0.01484343595802784,
      -0.018641972914338112,
      -0.022935442626476288,
      -0.04149985685944557,
      0.035937365144491196,
      -0.009756247512996197,
      0.03532920405268669,
      -0.11130928993225098,
      -0.021869411692023277,
      -0.018018314614892006,
      -0.06400009989738464,
      -0.06087571755051613,
      0.01572253741323948,
      0.0009509769733995199,
      0.029889538884162903,
      -0.11202270537614822,
      -0.047049518674612045,
      0.0727669969201088,
      0.004529217258095741,
      0.0729081854224205,
      0.04324895143508911,
      0.0803598016500473,
      0.026143595576286316,
      0.004162391647696495,
      -0.04228225350379944,
      0.04437988996505737,
      0.014833280816674232,
      -0.015614940784871578,
      0.03537203371524811,
      -0.03248807415366173,
      -0.007222623564302921,
      0.007841844111680984,
      0.011041286401450634,
      0.058441247791051865,
      0.03434659168124199,
      -0.002353569259867072,
      -0.05697443336248398,
      -0.09678277373313904,
      0.07282759994268417,
      0.020369689911603928,
      -0.0681130662560463,
      0.03010977804660797,
      0.026891782879829407,
      -0.057808052748441696,
      0.05089990049600601,
      0.026530524715781212,
      -0.012934798374772072,
      -0.009634081274271011,
      -0.05741294473409653,
      -0.061603885143995285,
      -0.07314517349004745,
      0.1204334944486618,
      -0.02437867783010006,
      0.09797224402427673,
      -0.043292172253131866,
      0.032040443271398544,
      -0.012462872080504894,
      0.009551262483000755,
      0.07445048540830612,
      -0.01610121876001358,
      0.02290290594100952,
      0.0013558780774474144,
      0.06960774958133698,
      -0.038058310747146606,
      0.04558104649186134,
      0.016432173550128937,
      0.03567742928862572,
      0.022610828280448914,
      -0.06723131239414215,
      0.012723226100206375,
      0.01563125289976597,
      -0.047141190618276596,
      -0.04397166892886162,
      -0.020496118813753128,
      -0.03251753747463226,
      0.09363105148077011,
      -0.003628880251199007,
      -0.012486342340707779,
      0.09335252642631531,
      0.000834205187857151,
      0.013256460428237915,
      -0.0629846379160881,
      -0.030464371666312218,
      -0.03954887390136719,
      -0.013741944916546345,
      -0.1333586424589157,
      0.03674878925085068,
      0.0013183641713112593,
      -0.018790675327181816,
      0.054592445492744446,
      0.0325038768351078,
      -7.8057091741357e-05,
      -0.026171814650297165,
      -0.044750094413757324,
      0.09109112620353699,
      -0.041727758944034576,
      0.009631434455513954,
      -0.014020579867064953,
      -0.07007324695587158,
      0.12899252772331238,
      0.032957542687654495,
      0.06443855911493301,
      -0.01763509400188923,
      0.03689810633659363,
      -0.04639172554016113,
      -0.027549993246793747,
      -0.041663967072963715,
      0.03547265753149986,
      0.08772293478250504,
      -0.013382027857005596,
      0.01607399806380272,
      -0.026133697479963303,
      -0.01589525304734707,
      0.02949937805533409,
      0.028797827661037445,
      -0.006243120413273573,
      0.026421774178743362,
      -0.012995973229408264,
      -0.03810852766036987,
      0.07230723649263382,
      -0.03558165952563286,
      -0.005571110639721155,
      0.024698814377188683,
      -0.02996363677084446,
      -0.0766025260090828,
      0.017924536019563675,
      0.10033305734395981,
      0.021701214835047722,
      0.04609135910868645,
      -0.04223161190748215,
      -0.054203711450099945,
      -0.005664967931807041,
      -0.03286745026707649,
      0.04005342349410057,
      0.0183254387229681,
      0.015175074338912964,
      0.05970695614814758,
      -0.038706447929143906,
      0.0695565789937973,
      0.05894314870238304,
      0.036078065633773804,
      -0.04546010121703148,
      0.07177310436964035,
      0.02991391345858574,
      -0.0039978427812457085,
      0.032863717526197433,
      0.023012077435851097,
      0.016010336577892303,
      0.04776253178715706,
      -0.013928994536399841,
      -0.04360188543796539,
      0.030615389347076416,
      0.03876108676195145,
      0.04592689871788025,
      -0.0434245727956295,
      0.05553298443555832,
      -0.02772972360253334,
      -0.022340938448905945,
      -0.035330578684806824,
      0.0075720748864114285,
      -0.008558901958167553,
      -0.024252992123365402,
      -0.032456763088703156,
      0.002977170515805483,
      0.01802891679108143,
      0.0135244932025671,
      -0.051422860473394394,
      -0.021202024072408676,
      0.03624185547232628,
      -0.05523797869682312,
      0.027747906744480133,
      -0.016370946541428566,
      0.09712746739387512,
      0.037847261875867844,
      -0.05342455953359604,
      0.009920105338096619,
      -0.03766043111681938,
      -0.007745725102722645,
      0.008163548074662685,
      9.453225356992334e-05,
      -0.07459303736686707,
      -0.03537626564502716,
      -0.002960929414257407,
      -0.029467131942510605,
      -0.06576322764158249,
      0.0027084778994321823,
      0.048672933131456375,
      -0.006758397910743952,
      -0.006976364646106958,
      0.03991588205099106,
      -0.01842685602605343,
      0.06657521426677704,
      -0.11400411278009415,
      0.06806427985429764,
      0.04854654148221016,
      0.00043133849976584315,
      0.03013860061764717,
      0.0618576779961586,
      -0.05922738462686539,
      -0.053833212703466415,
      -0.05010085552930832,
      0.008661984466016293,
      0.06541628390550613,
      0.0040750084444880486,
      0.08762647956609726,
      -0.037845153361558914,
      0.030305130407214165,
      -0.015541402623057365,
      0.005788047332316637,
      -0.025918416678905487,
      0.0014430781593546271,
      -0.028082342818379402,
      -0.05380579084157944,
      -0.07719384133815765,
      -0.06468254327774048,
      -0.04357059299945831,
      0.04694448783993721,
      0.016975410282611847,
      0.013528145849704742,
      0.013780146837234497,
      0.024816783145070076,
      -0.07958400994539261,
      -0.037706706672906876,
      -0.05866653472185135,
      0.04376109689474106,
      -0.026893747970461845,
      -0.009968549013137817,
      -0.011517686769366264,
      0.0040007103234529495,
      -0.06649599224328995,
      0.03281835466623306,
      0.02319471165537834,
      0.09253004938364029,
      0.028416190296411514,
      -0.008867654949426651,
      -0.06476849317550659,
      0.0036886432208120823,
      -0.0131574347615242,
      0.011189722456037998,
      0.02032678760588169,
      -0.026178088039159775,
      -0.08303018659353256,
      0.09046526253223419,
      -0.007029264234006405,
      0.03826972469687462,
      -0.0199056975543499,
      -0.00415797159075737,
      -0.01891351118683815,
      -0.008601059205830097,
      -0.0062272315844893456,
      -0.019203869625926018,
      -0.1274644434452057,
      0.04472057521343231,
      -0.030042976140975952,
      0.015543038956820965,
      0.018465977162122726,
      -0.035855796188116074,
      -0.015738699585199356,
      0.10739319771528244,
      -0.04697153717279434,
      -0.05776062607765198,
      -0.03860195353627205,
      -0.049902722239494324,
      -0.024637922644615173,
      -0.005383070092648268,
      0.12494947016239166,
      -0.029602250084280968,
      0.007972395047545433,
      -0.07336147129535675,
      0.03741840273141861,
      -0.0545668751001358,
      0.10892338305711746,
      0.003832760266959667,
      0.09267816692590714,
      -0.08571944385766983,
      0.02373555675148964,
      0.027553874999284744,
      0.06074796989560127,
      -0.04146159440279007,
      0.06590189039707184,
      0.04627353325486183,
      0.02105279639363289,
      0.04530651122331619,
      0.040633413940668106,
      0.04416774958372116,
      -0.062055300921201706,
      -0.013007710687816143,
      -0.0012388153700158,
      -0.004089836031198502,
      0.02630137838423252,
      0.047335829585790634,
      -0.028275063261389732,
      0.007471551187336445,
      -0.048426683992147446,
      -0.054170336574316025,
      0.06420163810253143,
      0.04668741300702095,
      -0.00500600365921855,
      0.008156304247677326,
      -0.01654527522623539
    ],
    [
      -0.02413042075932026,
      0.00524881249293685,
      -0.00863292720168829,
      0.0052382745780050755,
      -0.11096184700727463,
      0.019975025206804276,
      -0.03635905683040619,
      0.012009146623313427,
      -0.014145558699965477,
      -0.008100260980427265,
      -0.00924589205533266,
      0.026095334440469742,
      0.05321492254734039,
      0.013739243149757385,
      0.01755291037261486,
      -0.02742805704474449,
      -0.06907474994659424,
      -0.0013484652154147625,
      -0.07187919318675995,
      -0.0121609540656209,
      -0.06379243731498718,
      0.10730013251304626,
      -0.013275384902954102,
      -0.050687287002801895,
      -0.07894846796989441,
      0.025443419814109802,
      -0.07596399635076523,
      0.030419491231441498,
      -0.004422508180141449,
      -0.005552717950195074,
      -0.009861056692898273,
      -0.06504806876182556,
      0.015151253901422024,
      -0.0038368161767721176,
      -0.07069901376962662,
      -0.048954226076602936,
      -0.00823510717600584,
      0.05590614676475525,
      -0.07869399338960648,
      -0.021182797849178314,
      -0.050513431429862976,
      -0.033974334597587585,
      -0.013672996312379837,
      0.05596741661429405,
      0.06869345158338547,
      0.04203767701983452,
      -0.014416436664760113,
      0.04761892557144165,
      0.0511428639292717,
      -0.02506112866103649,
      0.06541867554187775,
      0.06448279321193695,
      0.05102017894387245,
      0.02209450490772724,
      0.0014914480270817876,
      0.0438888780772686,
      0.05488305166363716,
      0.025180205702781677,
      0.0428822860121727,
      0.034368984401226044,
      -0.010864189825952053,
      0.030467018485069275,
      -0.055533312261104584,
      0.01597939059138298,
      0.0019003639463335276,
      0.015962831676006317,
      -0.05010560527443886,
      0.022629741579294205,
      -0.038117147982120514,
      0.036714956164360046,
      0.037742387503385544,
      -0.024299290031194687,
      -0.03247889503836632,
      -0.005517727229744196,
      -0.006599551532417536,
      -0.05598387122154236,
      -0.03277212008833885,
      -0.05806800723075867,
      0.039773162454366684,
      0.04095970839262009,
      0.04469316825270653,
      0.050737958401441574,
      0.0031872903928160667,
      0.00981160718947649,
      -0.024395544081926346,
      -0.013362591154873371,
      -0.054852526634931564,
      -0.0022346863988786936,
      -0.04469509422779083,
      -0.028615428134799004,
      0.005320143420249224,
      0.026440273970365524,
      0.05611276999115944,
      -0.005281432066112757,
      -0.03490966558456421,
      -0.03718657046556473,
      0.005656342022120953,
      -0.004692778456956148,
      -0.0026316845323890448,
      0.023750754073262215,
      0.03439786285161972,
      -0.00946040078997612,
      0.05468578636646271,
      -0.0428159199655056,
      0.06333359330892563,
      0.07074251025915146,
      -0.01986020803451538,
      -0.045151740312576294,
      0.005948363803327084,
      0.00826052576303482,
      0.014008226804435253,
      -0.012156408280134201,
      0.12276995182037354,
      0.011229967698454857,
      0.007290617562830448,
      -0.00996022392064333,
      -0.03959687426686287,
      0.021453537046909332,
      -0.003945622593164444,
      0.004177169408649206,
      -0.032331109046936035,
      0.07051156461238861,
      0.05244513228535652,
      -0.0014431269373744726,
      0.06455004960298538,
      0.06159625202417374,
      0.09079830348491669,
      -0.056259091943502426,
      -0.01734158582985401,
      -0.05147003009915352,
      0.007738495245575905,
      0.04092062637209892,
      0.00947907567024231,
      -0.02646770142018795,
      0.047582823783159256,
      0.011873637326061726,
      -0.011131888255476952,
      0.017973918467760086,
      -0.006098686717450619,
      0.04045775532722473,
      0.01688719168305397,
      0.06817647814750671,
      -0.0024607768282294273,
      -0.040649574249982834,
      0.003970860969275236,
      -0.03398166969418526,
      -0.01853165775537491,
      0.025910422205924988,
      0.06305017322301865,
      -0.014335410669445992,
      -0.015671560540795326,
      -0.018488768488168716,
      -0.010494994930922985,
      -0.07346919178962708,
      0.08052918314933777,
      0.0591043084859848,
      0.01075249444693327,
      0.026906626299023628,
      0.04492967575788498,
      0.003547252621501684,
      0.04567351192235947,
      0.05153345689177513,
      -0.03477038815617561,
      0.023013552650809288,
      0.03741820156574249,
      0.010726994834840298,
      -0.017356041818857193,
      0.047258418053388596,
      0.004007627721875906,
      -0.05915490537881851,
      -0.03341609612107277,
      -0.011516164056956768,
      0.05569868162274361,
      0.07775817066431046,
      0.02715526893734932,
      -0.07460227608680725,
      0.01625984162092209,
      -0.030434781685471535,
      -0.020336249843239784,
      0.0457211509346962,
      0.005047429818660021,
      0.014652048237621784,
      -0.02064991369843483,
      -0.007055231835693121,
      0.019864749163389206,
      0.02819974720478058,
      -0.006718202959746122,
      -0.012741588987410069,
      -0.05095779895782471,
      -0.020478403195738792,
      -0.005690396297723055,
      -0.028283145278692245,
      -0.08161189407110214,
      -0.10161320865154266,
      -0.06509534269571304,
      0.015871267765760422,
      0.09279164671897888,
      -0.015051810070872307,
      -0.002273324877023697,
      0.00500513706356287,
      0.034236397594213486,
      -0.02898499369621277,
      0.027266662567853928,
      -0.04641706869006157,
      0.026268601417541504,
      -0.09930887818336487,
      -0.055130165070295334,
      0.032071713358163834,
      -0.018830152228474617,
      0.033224839717149734,
      -0.05170160159468651,
      -0.0846901684999466,
      -0.06321689486503601,
      -0.07862621545791626,
      0.0015375984366983175,
      -0.004963954444974661,
      -0.039615925401449203,
      -0.02547714300453663,
      -0.02565593458712101,
      0.006134314928203821,
      -0.011967913247644901,
      -0.024716520681977272,
      0.04630579799413681,
      -0.004893976729363203,
      0.0650239884853363,
      -0.010793461464345455,
      0.006113105453550816,
      0.03617493063211441,
      -0.04357975721359253,
      -0.00243421015329659,
      0.03229306638240814,
      -0.014828109182417393,
      0.04625250771641731,
      -0.058358028531074524,
      0.05674757808446884,
      -0.07655255496501923,
      -0.05800306797027588,
      0.06029927730560303,
      -0.10773849487304688,
      -0.016565950587391853,
      0.04299357160925865,
      0.013499991968274117,
      -0.037869103252887726,
      0.049893029034137726,
      0.029636522755026817,
      -0.008702763356268406,
      -0.03602977097034454,
      0.06429119408130646,
      0.060201019048690796,
      0.00962612684816122,
      0.11547936499118805,
      -0.02054552547633648,
      -0.059004537761211395,
      -0.020015470683574677,
      0.04554389417171478,
      -0.012400409206748009,
      -0.03738350048661232,
      0.0006861450965516269,
      -0.041857361793518066,
      0.0934884324669838,
      0.05567944049835205,
      -0.07542632520198822,
      0.00862547941505909,
      -0.032436616718769073,
      0.009434725157916546,
      0.01164984330534935,
      0.0046544671058654785,
      0.045994002372026443,
      -0.0688713937997818,
      0.017721639946103096,
      -0.027294181287288666,
      -0.03852595016360283,
      -0.030360475182533264,
      0.06362336874008179,
      -0.012999460101127625,
      0.07473743706941605,
      -0.02806280180811882,
      0.03439771756529808,
      -0.010228516533970833,
      0.00034991541178897023,
      -0.03014463186264038,
      -0.055971208959817886,
      -0.028434650972485542,
      0.051049962639808655,
      0.05240967497229576,
      -0.11066512763500214,
      0.014774343930184841,
      0.05306018143892288,
      0.002158630872145295,
      0.0024218414910137653,
      0.07578067481517792,
      0.043485939502716064,
      0.018961872905492783,
      0.02249118499457836,
      0.04708733409643173,
      -0.07396907359361649,
      0.006665786728262901,
      -0.023552918806672096,
      -0.04128122329711914,
      0.01577739231288433,
      -0.021167047321796417,
      0.00663942564278841,
      -0.027564462274312973,
      0.045969199389219284,
      -0.05523588880896568,
      0.002203191164880991,
      0.008133059367537498,
      -0.052838049829006195,
      0.04151230677962303,
      0.026609286665916443,
      -0.0585600808262825,
      0.11693041771650314,
      -0.025572482496500015,
      -0.018997622653841972,
      0.054644875228405,
      -0.05843955650925636,
      -0.0623885840177536,
      0.04503420367836952,
      0.030581524595618248,
      0.05458046868443489,
      0.08249411731958389,
      0.06348661333322525,
      0.010423125699162483,
      -0.055159661918878555,
      0.011044871993362904,
      -0.11432535201311111,
      0.004824643488973379,
      -0.022592728957533836,
      -5.9638194215949625e-05,
      0.023508811369538307,
      0.04000416398048401,
      -0.07526221126317978,
      -0.050715893507003784,
      -0.00642890902236104,
      0.016192583367228508,
      -0.021013541147112846,
      -0.03772133216261864,
      -0.0010166038991883397,
      -0.06342951953411102,
      0.030686089769005775,
      0.01886572502553463,
      -0.011883806437253952,
      0.018990108743309975,
      0.02269534207880497,
      -0.07273071259260178,
      0.021822981536388397,
      -0.04835003614425659,
      -0.01620791293680668,
      0.037694577127695084,
      -0.054707515984773636,
      -0.005685146432369947,
      -0.01828855089843273,
      0.08867020159959793,
      0.026298636570572853,
      0.03697601705789566,
      -0.0006546697113662958,
      -0.04090659320354462,
      -0.04156692326068878,
      -0.12184973806142807,
      0.015092411078512669,
      -0.017810294404625893,
      0.013569978065788746,
      0.030792945995926857,
      0.026553558185696602,
      -0.06696636974811554,
      -0.060193248093128204,
      -0.10260014235973358,
      -0.03600586950778961,
      -0.007121887523680925,
      0.05815973877906799,
      -0.04176517575979233,
      -0.04744132608175278,
      -0.01364333089441061,
      0.03556418791413307,
      0.10070434212684631,
      -0.0027702907100319862,
      -0.03281217813491821,
      -0.016250357031822205,
      0.012053010053932667,
      -0.019062694162130356,
      -0.09109743684530258,
      0.03275609761476517,
      -0.047683995217084885,
      -0.042555347084999084,
      -0.06134894862771034,
      0.0523756667971611,
      -0.009316668845713139,
      0.0035136614460498095,
      0.010578999295830727,
      0.060683004558086395,
      -0.023623792454600334,
      -0.05633901432156563,
      0.03081820346415043,
      -0.02660614624619484,
      -0.059821512550115585,
      -0.03898933529853821,
      -0.018056876957416534,
      0.026844821870326996,
      -0.02030985988676548,
      0.026775842532515526,
      0.06785151362419128,
      0.0559861846268177,
      0.027386484667658806,
      -0.04484492912888527,
      -0.03613864257931709,
      0.016994498670101166,
      0.012341223657131195,
      -0.00344744767062366,
      -0.12185851484537125,
      0.03843368589878082,
      -0.004951778333634138,
      0.0201407503336668,
      -0.08861681818962097,
      -0.049859464168548584,
      0.006952398456633091,
      -0.0350804403424263,
      -0.026578737422823906,
      0.006000352092087269,
      -0.0677526444196701,
      0.026506643742322922,
      0.004883531481027603,
      -0.040885940194129944,
      -0.010704537853598595,
      0.027806350961327553,
      -0.010370815172791481,
      0.011926300823688507,
      -0.00678980303928256,
      -0.016163989901542664,
      0.03386812284588814,
      -0.0035030150320380926,
      -0.001940920832566917,
      -0.014509591273963451,
      -0.049527689814567566,
      -0.04101312905550003,
      -0.044118866324424744,
      -0.02493048831820488,
      0.038003381341695786,
      0.008958949707448483,
      0.036635082215070724,
      0.030979346483945847,
      -0.00030474760569632053,
      0.03042769804596901,
      0.004482187330722809,
      0.027971137315034866,
      -0.07168325781822205,
      -0.02211839146912098,
      -0.009109120815992355,
      -0.05492706224322319,
      0.0047423881478607655,
      -0.03424408659338951,
      -0.005620738957077265,
      -0.05074658617377281,
      -0.004450847394764423,
      -0.06458044797182083,
      0.01696143113076687,
      -0.01208885945379734,
      -0.010702724568545818,
      -0.035957831889390945,
      0.02535284124314785,
      0.06431939452886581,
      0.007438281551003456,
      -0.005618077702820301,
      0.023002374917268753,
      -0.01596987061202526,
      -0.022964278236031532,
      0.010949806310236454,
      -0.028223032131791115,
      0.018869422376155853,
      0.018881313502788544,
      0.04877225682139397,
      -0.02923344448208809,
      0.017285151407122612,
      0.022825343534350395,
      -0.09142521768808365,
      -0.03528192639350891,
      0.0239817313849926,
      -0.0662580206990242,
      -0.04802624508738518,
      0.049774959683418274,
      0.04059576988220215,
      0.02118818648159504,
      -0.02875354327261448,
      0.017409108579158783,
      -0.021497817710042,
      0.006437100004404783,
      -0.03835902363061905,
      -0.04352875053882599,
      0.00520788598805666,
      -0.1301884800195694,
      0.024305162951350212,
      -0.041480135172605515,
      0.01181602943688631,
      -0.02495446987450123,
      0.013448438607156277,
      -0.0031836326234042645,
      -0.01639900729060173,
      -0.009799657389521599,
      -0.05027424916625023,
      0.048888757824897766,
      0.0002878902305383235,
      0.05239100009202957,
      0.013891741633415222,
      0.03594053164124489
    ],
    [
      -0.029064420610666275,
      -0.016452327370643616,
      0.013241592794656754,
      -0.03228795900940895,
      -0.006928951479494572,
      -0.01859777607023716,
      -0.047899387776851654,
      0.00652884878218174,
      0.01990063115954399,
      0.016483256593346596,
      -0.004630999639630318,
      -0.041197970509529114,
      0.04980562999844551,
      0.018007680773735046,
      -0.00595338037237525,
      -0.04714097082614899,
      -0.06394632905721664,
      0.03811110183596611,
      0.08591948449611664,
      -0.0303299892693758,
      0.007360426709055901,
      -0.04219156503677368,
      0.01774314045906067,
      0.026871761307120323,
      -0.033558499068021774,
      0.06063779816031456,
      -0.039183881133794785,
      0.04938867315649986,
      0.009642228484153748,
      -0.009772872552275658,
      0.009120499715209007,
      0.026535632088780403,
      -0.010461336001753807,
      0.01785416528582573,
      -0.0002494507934898138,
      0.08436522632837296,
      -0.03873925283551216,
      -0.018369430676102638,
      0.001926113967783749,
      -0.029883190989494324,
      -0.010110460221767426,
      -0.0003161152417305857,
      -0.002805139170959592,
      -0.009613186120986938,
      0.024418411776423454,
      -0.039176128804683685,
      -0.028650328516960144,
      -0.004575285129249096,
      -0.024674681946635246,
      0.02462267503142357,
      0.06316877156496048,
      0.04413692653179169,
      -0.0460677370429039,
      -0.08587378263473511,
      0.08355633169412613,
      -0.013686958700418472,
      0.03458452597260475,
      0.022914227098226547,
      -0.025101231411099434,
      0.06452467292547226,
      -0.00540251424536109,
      -0.006939894054085016,
      -0.025715593248605728,
      0.027667274698615074,
      -0.11688988655805588,
      -0.0891866460442543,
      -0.017915505915880203,
      0.04524580389261246,
      0.007070274092257023,
      -0.0019424660131335258,
      0.04574816673994064,
      0.02958141639828682,
      -0.011306877247989178,
      -0.008402165956795216,
      -0.0705537348985672,
      0.08938398212194443,
      0.04076860845088959,
      -0.08161593973636627,
      0.060578152537345886,
      0.020174767822027206,
      0.08569806814193726,
      0.08363501727581024,
      -0.039305415004491806,
      0.021310947835445404,
      0.05293715000152588,
      -0.033380746841430664,
      -0.008672193624079227,
      0.04648285359144211,
      -0.005747261922806501,
      0.005581917241215706,
      -0.1042264923453331,
      0.05421268939971924,
      -0.035410478711128235,
      -0.016818787902593613,
      -0.05118577554821968,
      -0.013781211338937283,
      -0.03529280424118042,
      0.01996651664376259,
      -0.0057520135305821896,
      -0.06495820730924606,
      0.04516666382551193,
      -0.04450947791337967,
      0.03736991807818413,
      0.010822302661836147,
      0.021528078243136406,
      0.019566381350159645,
      0.01993386633694172,
      0.02066064439713955,
      0.017630139365792274,
      -0.028301196172833443,
      -0.05815206095576286,
      -0.03128553181886673,
      0.035087812691926956,
      -0.10268272459506989,
      -0.006262368522584438,
      0.04797813668847084,
      -0.07175399363040924,
      -0.06362493336200714,
      -0.0557490810751915,
      0.056459441781044006,
      -0.013042803853750229,
      0.03435411676764488,
      -0.0036904464941471815,
      0.01385563239455223,
      -0.007684546988457441,
      -0.03179078921675682,
      0.08508231490850449,
      -0.05655096471309662,
      -0.027645418420433998,
      -0.03431689739227295,
      0.05916304141283035,
      -0.030345015227794647,
      -0.013284136541187763,
      -0.04618272930383682,
      0.016002528369426727,
      0.04666728526353836,
      0.13006311655044556,
      -0.03574131056666374,
      -0.016249215230345726,
      0.004892467986792326,
      -0.028076186776161194,
      -0.04608144983649254,
      0.029481684789061546,
      -0.06857513636350632,
      0.0032353291753679514,
      -0.028882449492812157,
      0.04596918448805809,
      -0.03470506891608238,
      -0.020200710743665695,
      -0.0098800640553236,
      0.01529295090585947,
      -0.0013622038532048464,
      -0.08845680952072144,
      -0.0006758397212252021,
      0.07791450619697571,
      -0.002307133749127388,
      0.047241829335689545,
      -0.009725776500999928,
      -0.007449151948094368,
      -0.028998788446187973,
      0.024543045088648796,
      0.02006915770471096,
      -0.08577638119459152,
      -0.0008117383113130927,
      -0.025066865608096123,
      0.009178003296256065,
      0.028390947729349136,
      -0.024809297174215317,
      -0.018740391358733177,
      0.0068528070114552975,
      -0.014135867357254028,
      -0.016027934849262238,
      -0.003294322406873107,
      0.09800151735544205,
      0.05894852429628372,
      -0.009200857020914555,
      0.032798249274492264,
      -0.022738123312592506,
      -0.0012387913884595037,
      0.03967227041721344,
      0.00863413605839014,
      -0.006802285555750132,
      -0.01840205118060112,
      0.03336377814412117,
      0.03970394283533096,
      -0.02569739706814289,
      -0.1155942976474762,
      -0.04103589430451393,
      -0.06364545971155167,
      0.013011190108954906,
      -0.031151479110121727,
      0.07535423338413239,
      -0.023286690935492516,
      -0.008153915405273438,
      -0.0023594836238771677,
      -0.011129021644592285,
      -0.030767489224672318,
      0.0226150956004858,
      0.03828059881925583,
      -0.012213096022605896,
      -0.0030606072396039963,
      -0.034565940499305725,
      0.017867794260382652,
      0.017631346359848976,
      -0.021507754921913147,
      0.010333179496228695,
      0.004873785190284252,
      -0.005188350100070238,
      0.04198839142918587,
      0.009333956055343151,
      0.03997303545475006,
      0.03881574049592018,
      -0.04251168295741081,
      -0.031602535396814346,
      -0.03440198674798012,
      -0.016466131433844566,
      0.011773518286645412,
      0.05945546180009842,
      0.006234497297555208,
      -0.0032211225479841232,
      -0.0117225656285882,
      0.055231232196092606,
      0.07222658395767212,
      0.027442818507552147,
      0.05037873610854149,
      0.06900433450937271,
      -0.025770755484700203,
      0.007015954237431288,
      -0.06435388326644897,
      -0.040513549000024796,
      -0.013164021074771881,
      0.035658761858940125,
      -0.03510963171720505,
      0.06085881590843201,
      -0.01213574968278408,
      0.00512605020776391,
      0.033897049725055695,
      0.004654926247894764,
      -0.0027609101962298155,
      -0.04471717029809952,
      -0.038648929446935654,
      0.009861718863248825,
      -0.09344127774238586,
      0.033776555210351944,
      0.053002141416072845,
      0.04025520756840706,
      0.015142274089157581,
      0.05058242753148079,
      -0.035581693053245544,
      -0.024663612246513367,
      0.049730218946933746,
      -0.04564584046602249,
      -0.0061289300210773945,
      -0.01341470517218113,
      0.02326495200395584,
      0.09629221260547638,
      0.020354917272925377,
      -0.008513610810041428,
      -0.008832124061882496,
      0.03542724624276161,
      -0.03832690045237541,
      -0.044998228549957275,
      -0.0367608442902565,
      -0.0005269753164611757,
      0.01867641881108284,
      -0.03688610717654228,
      0.05682346969842911,
      -0.07082902640104294,
      -0.1307707577943802,
      0.004558607470244169,
      0.07940353453159332,
      -0.03899485990405083,
      -0.013575614430010319,
      -0.0026751661207526922,
      0.04733629524707794,
      -0.06799449026584625,
      0.027594922110438347,
      0.07821047306060791,
      -0.051965903490781784,
      -0.051648594439029694,
      0.056486256420612335,
      0.028525950387120247,
      0.059508081525564194,
      0.04691285640001297,
      -0.008468437008559704,
      0.03167464956641197,
      -0.0491272434592247,
      0.01390146929770708,
      0.09542663395404816,
      0.03569263219833374,
      0.011995770037174225,
      0.00038735344423912466,
      0.019324282184243202,
      0.13401007652282715,
      -0.006108811590820551,
      0.04388865455985069,
      -0.05522383376955986,
      -0.024629665538668633,
      0.06663018465042114,
      -0.0026214364916086197,
      -0.040615472942590714,
      -0.048961907625198364,
      0.030569428578019142,
      0.052544962614774704,
      0.006361428648233414,
      0.059101227670907974,
      -0.03755609691143036,
      0.006492570973932743,
      0.05273950845003128,
      0.026333529502153397,
      -0.009625817649066448,
      -0.02391745150089264,
      -0.07215867191553116,
      0.03703261539340019,
      -0.060140036046504974,
      0.014007138088345528,
      -0.13133980333805084,
      0.04543587937951088,
      -0.04537045210599899,
      -0.0028295109514147043,
      0.0030407821759581566,
      0.0053757186979055405,
      0.004749472253024578,
      0.031015682965517044,
      -0.032884933054447174,
      0.006380386184900999,
      -0.05793984234333038,
      0.06996852159500122,
      0.030739860609173775,
      -0.07432325929403305,
      -0.004506545141339302,
      0.0036550741642713547,
      -0.07455785572528839,
      -0.04908588528633118,
      0.033191122114658356,
      0.007257144898176193,
      0.025044625625014305,
      0.004753726068884134,
      0.015128136612474918,
      -0.10404329001903534,
      0.06466464698314667,
      0.02529034949839115,
      -0.005003828555345535,
      0.014911724254488945,
      0.046771612018346786,
      -0.005590403452515602,
      -0.06157822534441948,
      -0.02442784048616886,
      0.016796868294477463,
      -0.0202933382242918,
      0.06707392632961273,
      0.0352138951420784,
      -0.03890305012464523,
      0.008209973573684692,
      0.007770709227770567,
      -0.012220548465847969,
      -0.10453814268112183,
      -0.07484782487154007,
      -0.013800414279103279,
      -0.04441985860466957,
      -0.033516716212034225,
      -0.04391294717788696,
      0.007747419644147158,
      0.04283110424876213,
      -0.012835211120545864,
      0.049734603613615036,
      -0.023701613768935204,
      0.06571344286203384,
      0.04295432195067406,
      0.07029705494642258,
      -0.0036426163278520107,
      -0.014211595058441162,
      -0.0579615980386734,
      -0.041779324412345886,
      -0.02549503557384014,
      0.046867020428180695,
      0.004216444678604603,
      -0.04296130686998367,
      0.0312028918415308,
      0.031792618334293365,
      0.03628271073102951,
      -0.03709404170513153,
      -0.01292894035577774,
      0.0608820840716362,
      0.025936389341950417,
      0.09127861261367798,
      0.0346548892557621,
      0.03719722479581833,
      0.009363731369376183,
      0.0021879710257053375,
      0.06053829938173294,
      -0.04354220628738403,
      -0.03234293311834335,
      0.04218704625964165,
      -0.04213310033082962,
      0.026422657072544098,
      0.036385588347911835,
      0.07262943685054779,
      -0.055699750781059265,
      0.05480272322893143,
      0.02566213347017765,
      0.008615192957222462,
      -0.037298545241355896,
      -0.025654669851064682,
      -0.04173274710774422,
      -0.08008487522602081,
      -0.04041104391217232,
      0.026743119582533836,
      0.008357889018952847,
      -0.017326828092336655,
      0.01855398342013359,
      0.041467610746622086,
      -0.020527753978967667,
      -0.004427250474691391,
      -0.027776123955845833,
      -0.051217611879110336,
      0.022436225786805153,
      -0.017994705587625504,
      0.011305214837193489,
      -0.0008517560781911016,
      0.0578385554254055,
      0.01651032827794552,
      0.06814713031053543,
      -0.0005513663054443896,
      -0.047416914254426956,
      -0.0010759936412796378,
      -0.051282212138175964,
      0.0163007490336895,
      -0.006875509396195412,
      -0.03199620172381401,
      0.14454461634159088,
      -0.04774937778711319,
      0.029790055006742477,
      -0.0381397046148777,
      -0.015048705972731113,
      0.04786059632897377,
      -0.028852073475718498,
      0.039675384759902954,
      -0.08123286068439484,
      0.03258625417947769,
      -0.020937955006957054,
      -0.04760916531085968,
      0.038146715611219406,
      -0.012915252707898617,
      -0.07338763028383255,
      0.02889089100062847,
      0.04988017678260803,
      0.042114339768886566,
      0.03469941392540932,
      -0.01108564157038927,
      0.008546577766537666,
      0.040837034583091736,
      0.015289305709302425,
      -0.13136477768421173,
      -0.020253822207450867,
      0.02492036111652851,
      0.05850120633840561,
      -0.04863857477903366,
      -0.09188488125801086,
      -0.011661105789244175,
      -0.047132715582847595,
      -0.006963333114981651,
      0.026895590126514435,
      0.05228796601295471,
      0.029995985329151154,
      0.03983072191476822,
      -0.014400561340153217,
      -0.05068628862500191,
      0.04252425208687782,
      0.07572414726018906,
      0.0004846520605497062,
      -0.04735817387700081,
      0.05850783735513687,
      0.013437171466648579,
      0.09635517746210098,
      0.0034947849344462156,
      0.07127692550420761,
      -0.013155873864889145,
      0.044332779943943024,
      -0.0019440901232883334,
      -0.09152353554964066,
      -0.06003544479608536,
      0.058552611619234085,
      -0.020182007923722267,
      -0.09695512801408768,
      0.006088705267757177,
      0.019696863368153572,
      -0.06757411360740662,
      0.022393548861145973,
      -0.00737014040350914,
      -0.007445883005857468,
      -0.01928972266614437,
      -0.03285900130867958,
      0.033210381865501404,
      -0.05825895071029663,
      -0.04594455659389496,
      -0.0007387538207694888,
      0.04489010199904442,
      0.024220537394285202,
      -0.01682356558740139,
      0.07984433323144913,
      -0.0301424078643322,
      -0.03296155855059624
    ],
    [
      -0.06810811161994934,
      -0.007219367194920778,
      -0.0004573150654323399,
      -0.011964181438088417,
      0.042880356311798096,
      -0.014964458532631397,
      -0.025926435366272926,
      -0.08097581565380096,
      0.014615032821893692,
      0.0015342902624979615,
      -0.018397921696305275,
      -0.0728122815489769,
      0.027779487892985344,
      -0.0077520133927464485,
      0.06902090460062027,
      -0.07623737305402756,
      -0.02577134408056736,
      0.0041352808475494385,
      0.011653642170131207,
      0.040261443704366684,
      0.054629333317279816,
      0.03883487358689308,
      -0.04861797019839287,
      -0.1426028162240982,
      0.026734396815299988,
      -0.014382502995431423,
      -0.005739194806665182,
      0.016579920426011086,
      0.007378546055406332,
      -0.0348258875310421,
      0.05153970420360565,
      -0.01632324792444706,
      -0.0033277925103902817,
      -0.0029188767075538635,
      0.0030228698160499334,
      -0.01629328727722168,
      -0.012051638215780258,
      0.03645918518304825,
      0.0989559218287468,
      -0.05822031572461128,
      -0.06272321939468384,
      -0.010140142403542995,
      -0.035930853337049484,
      0.027184540405869484,
      0.007260811515152454,
      -0.015820136293768883,
      -0.0697641372680664,
      0.010088998824357986,
      0.0007281048456206918,
      -0.06672848016023636,
      0.02376811020076275,
      -0.03500579670071602,
      -0.0009211296564899385,
      0.032479386776685715,
      -0.040462952107191086,
      -0.009878112003207207,
      0.1468648761510849,
      0.09556775540113449,
      0.008813533000648022,
      -0.017024556174874306,
      0.00516031589359045,
      0.0819316878914833,
      0.022333307191729546,
      0.03131628409028053,
      -0.0698527917265892,
      0.05031831935048103,
      0.027103794738650322,
      0.02340073511004448,
      -0.030166681855916977,
      0.021320290863513947,
      -0.014608509838581085,
      0.01689048483967781,
      0.00154405168723315,
      0.004495757631957531,
      -0.04001031443476677,
      -0.027650613337755203,
      0.0561625100672245,
      -0.0783991739153862,
      0.00871734507381916,
      -0.0007643730496056378,
      -0.035680387169122696,
      0.08345826715230942,
      -0.041601117700338364,
      0.04561410844326019,
      -0.03532666712999344,
      -0.0455956868827343,
      -0.05010291934013367,
      -0.058434680104255676,
      -0.06563675403594971,
      -0.008864243514835835,
      -0.007237585261464119,
      -0.038492098450660706,
      -0.07368335127830505,
      -0.022263528779149055,
      0.030103525146842003,
      -0.0042579821310937405,
      -0.0015215362654998899,
      0.0258713960647583,
      0.028606032952666283,
      0.022355545312166214,
      -0.0727153941988945,
      -0.03649899363517761,
      0.0015230680583044887,
      0.06495679169893265,
      -0.0026572919450700283,
      0.07492870092391968,
      0.02809455431997776,
      0.07523056864738464,
      0.019157925620675087,
      0.09018422663211823,
      0.0343175083398819,
      0.050374481827020645,
      0.04216421768069267,
      -0.06080600246787071,
      0.016630392521619797,
      0.06102581322193146,
      0.006852671504020691,
      0.017464302480220795,
      -0.011484097689390182,
      -0.005708413198590279,
      0.054413750767707825,
      -0.048780657351017,
      -0.0060208565555512905,
      0.008205398917198181,
      0.0865999385714531,
      0.04418179392814636,
      0.05165519192814827,
      -0.03156047314405441,
      0.05324952304363251,
      -0.0005298740579746664,
      0.024596357718110085,
      0.04616757109761238,
      0.014536668546497822,
      -0.06128303334116936,
      -0.030336853116750717,
      0.04547224938869476,
      -0.0131792351603508,
      0.052984945476055145,
      -0.007456547114998102,
      0.027834463864564896,
      0.02244364097714424,
      0.04949116334319115,
      -0.012158184312283993,
      0.05397195369005203,
      -0.019628217443823814,
      -0.03811673820018768,
      0.0985230952501297,
      -0.00999580416828394,
      -0.007049776613712311,
      -0.05032294616103172,
      0.05684231221675873,
      -0.08900891989469528,
      -0.011076673865318298,
      -0.07185199111700058,
      -0.004606999456882477,
      0.0012614814331755042,
      0.006693722680211067,
      0.07886189222335815,
      0.0417095348238945,
      -0.01921776309609413,
      0.042846135795116425,
      -0.0259139072149992,
      0.014732963405549526,
      -0.060337379574775696,
      0.0028854464180767536,
      -0.04027552157640457,
      0.021685833111405373,
      0.03677724301815033,
      0.009329400956630707,
      -0.057460539042949677,
      0.02924931049346924,
      0.0053510889410972595,
      -0.04870730638504028,
      0.001751550706103444,
      0.04846769943833351,
      -0.09323085844516754,
      -0.0023272226098924875,
      -0.06213900446891785,
      0.03796128183603287,
      -0.0880202203989029,
      0.005030596628785133,
      -0.005912596359848976,
      0.00955126155167818,
      0.05634388327598572,
      0.03522413223981857,
      0.020785365253686905,
      0.009372266009449959,
      -0.06183861196041107,
      0.03455429896712303,
      0.09494823962450027,
      -0.03610029071569443,
      -0.11014719307422638,
      0.0057799676433205605,
      0.07114416360855103,
      0.026224706321954727,
      0.03739568591117859,
      0.04645071178674698,
      -0.03329163044691086,
      0.02088863216340542,
      0.0036356430500745773,
      -0.0398084856569767,
      0.05690496414899826,
      -0.03264030069112778,
      0.016572076827287674,
      0.018936939537525177,
      -0.049867115914821625,
      -0.056649308651685715,
      -0.029934121295809746,
      -0.02626185491681099,
      -0.020491624251008034,
      0.023564284667372704,
      0.0014535030350089073,
      0.015298736281692982,
      -0.02141132764518261,
      0.014738669618964195,
      0.024129578843712807,
      -0.018613891676068306,
      -0.020797520875930786,
      -0.017908960580825806,
      -0.016768209636211395,
      0.010487961582839489,
      -0.013156261295080185,
      -0.017322277650237083,
      0.05269543081521988,
      0.009069954045116901,
      -0.023079579696059227,
      0.033452607691287994,
      0.00038658222183585167,
      -0.006897921673953533,
      0.005671046208590269,
      -0.020417192950844765,
      -0.04396964982151985,
      0.09114360064268112,
      0.031380072236061096,
      -0.005454507656395435,
      -0.019055960699915886,
      0.043878283351659775,
      0.021930400282144547,
      -0.009641556069254875,
      0.006185551639646292,
      -0.027145901694893837,
      0.022007962688803673,
      -0.04804795980453491,
      0.017608392983675003,
      -0.019682627171278,
      0.0589677095413208,
      -0.012720922008156776,
      -0.05301626771688461,
      -0.002718465868383646,
      0.05700434744358063,
      -0.008326276205480099,
      -0.0742172971367836,
      0.02505599521100521,
      0.025016121566295624,
      0.06462685763835907,
      0.012868164107203484,
      -0.017679238691926003,
      -0.06642413139343262,
      -0.049048155546188354,
      -0.05418494716286659,
      0.0848313421010971,
      -0.03902463614940643,
      -0.019007939845323563,
      0.04868685454130173,
      -0.036934591829776764,
      -0.0350947305560112,
      0.06022867187857628,
      0.0035895495675504208,
      -0.07437226921319962,
      0.020212721079587936,
      0.019416630268096924,
      -0.027223093435168266,
      0.0278912466019392,
      0.0005323636578395963,
      0.07069307565689087,
      -0.01637944206595421,
      0.050368521362543106,
      0.004303389228880405,
      0.012480263598263264,
      -0.03541436046361923,
      0.012811701744794846,
      0.032020699232816696,
      -0.05281076580286026,
      0.03396357223391533,
      -0.03255331516265869,
      -0.005046159960329533,
      -0.052141621708869934,
      0.03849254921078682,
      0.11056634783744812,
      0.00887355487793684,
      0.058577608317136765,
      0.012936064042150974,
      0.041026901453733444,
      0.04876166954636574,
      0.03819185867905617,
      0.009238126687705517,
      -0.04508638381958008,
      0.04304242879152298,
      -0.026082316413521767,
      0.051767498254776,
      -0.001468347734771669,
      -0.05718924105167389,
      -0.022258836776018143,
      -0.057522863149642944,
      -0.054837752133607864,
      0.042920541018247604,
      0.04686367139220238,
      -0.05037500709295273,
      0.040730901062488556,
      0.029937010258436203,
      -0.03810521587729454,
      0.026755349710583687,
      0.026952434331178665,
      0.03850080445408821,
      -0.032167114317417145,
      0.038913652300834656,
      -0.0017691736575216055,
      -0.060887958854436874,
      0.008865714073181152,
      0.03154027462005615,
      -0.07938750833272934,
      0.04253032058477402,
      -0.04229219630360603,
      -0.10590670257806778,
      0.07826603949069977,
      0.061312485486269,
      -0.04442356154322624,
      -0.01450667530298233,
      -0.037861648947000504,
      -0.034774407744407654,
      -0.028113728389143944,
      0.016439756378531456,
      0.010746875777840614,
      0.08193542063236237,
      0.0740378126502037,
      -0.038300007581710815,
      0.06598953902721405,
      -0.015746861696243286,
      -0.007907493971288204,
      0.008710195310413837,
      0.010881978087127209,
      -0.006714573595672846,
      0.02237236499786377,
      0.0031689906027168036,
      0.017989175394177437,
      0.02731980010867119,
      -0.0022643457632511854,
      -0.007958649657666683,
      -0.05974121764302254,
      -0.0055555799044668674,
      0.03020469844341278,
      -0.03129984810948372,
      -0.04500190168619156,
      0.005123717710375786,
      -0.024825990200042725,
      -0.10189051181077957,
      -0.013899128884077072,
      0.020707670599222183,
      -0.022304140031337738,
      -0.10041680186986923,
      -0.026279354467988014,
      -0.0407223142683506,
      -0.04147111251950264,
      0.049762994050979614,
      0.024862628430128098,
      0.018390627577900887,
      -0.015250325202941895,
      0.05171862617135048,
      0.05537371709942818,
      0.0233182180672884,
      -0.021312519907951355,
      0.07151241600513458,
      -0.08466549217700958,
      0.09370817244052887,
      -0.023831775411963463,
      -0.01153947226703167,
      0.004180788062512875,
      0.012299884110689163,
      0.01769176311790943,
      0.026983967050909996,
      0.056945085525512695,
      0.058739349246025085,
      -0.05294444411993027,
      -0.026182975620031357,
      0.07165689021348953,
      -0.052384719252586365,
      -0.024776222184300423,
      -0.008835259824991226,
      -0.13841283321380615,
      -0.06954195350408554,
      -0.036371808499097824,
      0.02170267514884472,
      -0.03966037556529045,
      -0.0039202552288770676,
      -0.06932318210601807,
      0.016943518072366714,
      -0.016560664400458336,
      -0.010156013071537018,
      0.025604650378227234,
      0.03647101670503616,
      -4.267424810677767e-05,
      0.03403458371758461,
      0.013112643733620644,
      0.02737845480442047,
      -0.07285875082015991,
      0.05256425961852074,
      0.05230989679694176,
      -0.06096701696515083,
      -0.036457594484090805,
      -0.08410867303609848,
      0.009687761776149273,
      -0.02223261259496212,
      -0.06159549579024315,
      0.03655720874667168,
      -0.016041837632656097,
      0.035275496542453766,
      0.019354652613401413,
      -0.01050806138664484,
      -0.02394549734890461,
      0.08817434310913086,
      0.0031336962711066008,
      -0.012172670103609562,
      -0.031725239008665085,
      0.034776825457811356,
      -0.08383489400148392,
      0.026412278413772583,
      -0.05444784834980965,
      -0.03901095315814018,
      -0.0661853477358818,
      -0.0034735773224383593,
      -0.029950983822345734,
      0.006821045186370611,
      -0.09203977137804031,
      -0.008177545852959156,
      0.06277640163898468,
      -0.07729437947273254,
      0.06646458059549332,
      0.013177016749978065,
      -0.07288418710231781,
      -0.003224471816793084,
      -0.06338468939065933,
      -0.022465968504548073,
      -0.03486238792538643,
      -0.06364133208990097,
      0.011136907152831554,
      0.018168672919273376,
      0.029277358204126358,
      0.03014623187482357,
      -0.016998490318655968,
      -0.037104532122612,
      0.011242608539760113,
      0.027299553155899048,
      -0.044826582074165344,
      0.004606189206242561,
      0.11493334919214249,
      -0.016227854415774345,
      0.04393153265118599,
      0.021887140348553658,
      0.013265009969472885,
      -0.05491328611969948,
      -0.01903764344751835,
      -0.012766506522893906,
      0.0429113544523716,
      -0.029384855180978775,
      -0.06805304437875748,
      0.026062797755002975,
      0.021154217422008514,
      0.0060654194094240665,
      0.033731404691934586,
      0.0033595284912735224,
      -0.0003354937070980668,
      -0.021497227251529694,
      -0.08211153745651245,
      -0.050128962844610214,
      -0.009800627827644348,
      -0.0983620136976242,
      0.008228208869695663,
      0.024776728823781013,
      0.01448825467377901,
      -0.025236960500478745,
      -0.049784522503614426,
      -0.041939444839954376,
      0.060466162860393524,
      -0.006866074167191982,
      -0.049538616091012955,
      -0.02586950734257698,
      0.018323291093111038,
      0.010398593731224537,
      -0.007302702870219946,
      0.016223397105932236,
      -0.0667591318488121,
      0.11121625453233719,
      0.034238606691360474,
      0.0008093793876469135,
      -0.037207137793302536,
      -0.023944109678268433,
      0.024950368329882622,
      0.05264280363917351,
      0.05214014649391174,
      -0.08062694221735,
      -0.016779309138655663,
      -0.033225610852241516,
      0.0066614230163395405
    ],
    [
      -0.05354250967502594,
      0.06214158982038498,
      -0.02125612273812294,
      0.007688368204981089,
      -0.0645698755979538,
      9.781507105799392e-05,
      -0.034845639020204544,
      -0.05500497668981552,
      0.05357881262898445,
      0.07867677509784698,
      0.04279356077313423,
      -0.07014133036136627,
      -0.0868435800075531,
      0.010017279535531998,
      0.0010647407034412026,
      0.06016550958156586,
      0.04579663276672363,
      0.019091440364718437,
      0.005412116181105375,
      -0.1000715121626854,
      0.03531266748905182,
      0.0027344461996108294,
      -0.040969058871269226,
      0.02762931026518345,
      -0.00681783352047205,
      0.0711800828576088,
      -0.03735095262527466,
      -0.03873663395643234,
      -0.06921859830617905,
      -0.002687776228412986,
      0.030285213142633438,
      -0.036400821059942245,
      0.020572621375322342,
      0.002943325787782669,
      0.03581849858164787,
      0.006662194617092609,
      0.09103961288928986,
      0.0069837411865592,
      0.03996716067194939,
      0.05081591755151749,
      0.030907094478607178,
      -0.06056870520114899,
      -0.048582591116428375,
      0.01607413776218891,
      0.08087684959173203,
      -0.10061279684305191,
      0.07480493187904358,
      -0.03790948539972305,
      -0.008902271278202534,
      -0.02179969660937786,
      0.08818121999502182,
      0.04405387490987778,
      -0.06652823090553284,
      -0.04293544590473175,
      0.007488084491342306,
      -0.015563617460429668,
      0.01610444486141205,
      -0.04160643368959427,
      -0.02679530903697014,
      -0.0440707728266716,
      -0.008738420903682709,
      0.014138669706881046,
      0.04099300876259804,
      -0.03496561571955681,
      -0.04486141726374626,
      -0.03384922072291374,
      -0.09672892838716507,
      -0.00593396183103323,
      -0.017572659999132156,
      -0.015262868255376816,
      -0.04013293236494064,
      0.03599658980965614,
      0.01089116744697094,
      -0.04178404435515404,
      -0.0331234335899353,
      -0.006098510697484016,
      -0.022008495405316353,
      0.02199251763522625,
      -0.025642309337854385,
      0.03234071657061577,
      0.04935942590236664,
      0.06688827276229858,
      0.04711303114891052,
      0.03300601616501808,
      0.11473526060581207,
      0.029039470478892326,
      0.042972635477781296,
      0.012561771087348461,
      0.0625012069940567,
      -0.03580756485462189,
      0.05407467111945152,
      0.005540177691727877,
      -0.02894667163491249,
      -0.00996347051113844,
      0.11162471771240234,
      0.004829028621315956,
      0.0203335452824831,
      -0.05535756051540375,
      0.0024319144431501627,
      -0.020024241879582405,
      -0.07704988867044449,
      -0.03163202479481697,
      0.04016008973121643,
      -0.07154899090528488,
      -0.031272534281015396,
      -0.0274432934820652,
      -0.0019278323743492365,
      -0.021412530913949013,
      -0.0770563930273056,
      -0.0039907898753881454,
      -0.014610365964472294,
      0.0021626227535307407,
      0.02401510439813137,
      -0.043345481157302856,
      0.07649694383144379,
      -0.09660007804632187,
      0.025642046704888344,
      -0.01912633888423443,
      0.03000071458518505,
      0.043457817286252975,
      0.020461728796362877,
      -0.05308665707707405,
      0.012096687220036983,
      0.0395091213285923,
      0.0862269476056099,
      0.06877655535936356,
      0.013330471701920033,
      0.05809451639652252,
      0.007480488158762455,
      0.028696008026599884,
      0.04130254313349724,
      -0.013973305001854897,
      -0.011111510917544365,
      -0.05925788730382919,
      0.012389803305268288,
      -0.04051128029823303,
      -0.032572612166404724,
      0.008674350567162037,
      0.022159751504659653,
      0.059277456253767014,
      -0.03527029976248741,
      0.00918872281908989,
      -0.007078261114656925,
      -0.08444949239492416,
      0.09109042584896088,
      0.014935632236301899,
      -0.024715019389986992,
      -0.006788740865886211,
      -0.04191118851304054,
      0.026822715997695923,
      -0.01900302618741989,
      0.01786675490438938,
      -0.04070935770869255,
      -0.03346576914191246,
      0.09962312877178192,
      0.020936932414770126,
      0.07189342379570007,
      -0.01246894896030426,
      -0.04048485308885574,
      -0.011241421103477478,
      0.03934666886925697,
      0.05609205365180969,
      -0.042825277894735336,
      0.0043562836945056915,
      0.00426081707701087,
      0.035173460841178894,
      -0.06856891512870789,
      -0.05792428180575371,
      -0.0027052622754126787,
      0.036153655499219894,
      0.06124117597937584,
      0.00829743780195713,
      -0.0009909492218866944,
      -0.001785336877219379,
      0.0005946439341641963,
      -0.04399368166923523,
      0.03356768563389778,
      -0.07823681086301804,
      -0.0961940586566925,
      -0.014392521232366562,
      0.008580626919865608,
      -0.006642911117523909,
      0.03787558153271675,
      0.057954177260398865,
      -0.07395996898412704,
      -0.030780121684074402,
      0.00723313307389617,
      0.01919371448457241,
      -0.06982425600290298,
      -0.007445001509040594,
      -0.10218534618616104,
      0.06226104497909546,
      -0.0002654185227584094,
      -0.04673054814338684,
      -0.03945370391011238,
      -0.012384353205561638,
      -0.0020716157741844654,
      -0.02149541862308979,
      -0.034113578498363495,
      0.011818289756774902,
      -0.048599518835544586,
      -0.02679973654448986,
      0.06455440074205399,
      0.01290151383727789,
      0.06490574777126312,
      0.13966558873653412,
      -0.00268847169354558,
      0.028817513957619667,
      0.0089857904240489,
      -0.027410902082920074,
      0.0006725226994603872,
      -0.0022228516172617674,
      -0.12743809819221497,
      -0.06563889235258102,
      -0.0013239135732874274,
      -0.005931275896728039,
      0.0684826672077179,
      0.03111468069255352,
      -0.05047249794006348,
      0.07411389797925949,
      -0.003021554322913289,
      0.014828124083578587,
      0.017188984900712967,
      0.025791842490434647,
      0.02009260281920433,
      0.003901452524587512,
      -0.10703637450933456,
      0.06236898526549339,
      0.006532276049256325,
      0.0626758486032486,
      -0.00723815243691206,
      0.04361217841506004,
      0.04411542788147926,
      -0.01573857106268406,
      0.06170240417122841,
      -0.06309504806995392,
      -0.053733762353658676,
      0.027566011995077133,
      0.021853569895029068,
      -0.021327093243598938,
      -0.037341561168432236,
      0.016958946362137794,
      -0.0159798264503479,
      0.016108855605125427,
      0.012281565926969051,
      -0.061821095645427704,
      0.0069918157532811165,
      0.06575939059257507,
      -0.027283266186714172,
      0.013513786718249321,
      -0.12772832810878754,
      -0.11703459918498993,
      -0.026069143787026405,
      0.06219267472624779,
      -0.0184180848300457,
      0.061809394508600235,
      0.07324996590614319,
      0.017412064597010612,
      -0.03677463158965111,
      -0.06729574501514435,
      0.01167962234467268,
      0.07313287258148193,
      0.050489526242017746,
      0.021964848041534424,
      0.05268537625670433,
      -0.04010555148124695,
      -0.07374095916748047,
      -0.015178671106696129,
      0.0020980732515454292,
      0.10850528627634048,
      -0.03638262301683426,
      -0.022891560569405556,
      -0.058680459856987,
      0.003481704741716385,
      -0.05415232107043266,
      0.008600831963121891,
      0.0450529083609581,
      0.023478185757994652,
      -0.0442187525331974,
      -0.01345912367105484,
      -0.0077401394955813885,
      0.028024965897202492,
      -0.026845581829547882,
      -0.06811890006065369,
      -0.022838734090328217,
      -0.08860958367586136,
      0.047613684087991714,
      0.06695236265659332,
      -0.03925338387489319,
      -0.027743956074118614,
      -0.02069939486682415,
      0.020397871732711792,
      0.08364622294902802,
      0.006243189796805382,
      0.0020192982628941536,
      -0.005885612219572067,
      -0.05402693152427673,
      0.009937877766788006,
      0.06036188825964928,
      0.028467996045947075,
      -0.006358101963996887,
      -0.0048161037266254425,
      0.0374453105032444,
      -0.03635673597455025,
      -0.004774956498295069,
      0.014743668958544731,
      0.0055006095208227634,
      -0.022535407915711403,
      0.0713379755616188,
      0.02284955233335495,
      0.019503295421600342,
      0.10118745267391205,
      0.029915934428572655,
      0.00977587141096592,
      0.009728088043630123,
      -0.03186770901083946,
      0.05339936167001724,
      -0.014485228806734085,
      0.04535644128918648,
      -0.13532139360904694,
      0.044804446399211884,
      0.022861463949084282,
      -0.07970712333917618,
      -0.057968154549598694,
      -0.06497092545032501,
      -0.09044696390628815,
      -0.003445851383730769,
      0.09352758526802063,
      0.04830639809370041,
      0.04464450106024742,
      0.07035036385059357,
      -0.024899525567889214,
      -0.00930834375321865,
      0.05750308930873871,
      -0.05040600523352623,
      0.05101422220468521,
      -0.04688449576497078,
      -0.031040523201227188,
      -0.009205885231494904,
      0.001331637380644679,
      -0.05120928958058357,
      -0.05449808016419411,
      0.09740997105836868,
      -0.1089756041765213,
      -0.04954254627227783,
      0.053374189883470535,
      0.031239638105034828,
      -0.002102065598592162,
      -0.06352143734693527,
      0.1686355620622635,
      -0.0018756366334855556,
      -0.0518801175057888,
      0.013967483304440975,
      0.04716714471578598,
      -0.04966007545590401,
      0.02837626077234745,
      0.014230241999030113,
      -0.014637512154877186,
      0.03293218836188316,
      -0.05051010847091675,
      0.03118329867720604,
      0.05786667391657829,
      0.06341296434402466,
      -0.07197738438844681,
      0.041547905653715134,
      -0.018427738919854164,
      0.011647826991975307,
      -0.019465066492557526,
      0.09120678901672363,
      0.058925773948431015,
      -8.384350803680718e-05,
      0.011201865039765835,
      0.031272999942302704,
      -0.05907565727829933,
      -0.0769333764910698,
      0.022176414728164673,
      0.04853792488574982,
      -0.051255546510219574,
      0.010552901774644852,
      0.0007570795714855194,
      0.0928567573428154,
      0.016808560118079185,
      0.019509892910718918,
      -0.0053827520459890366,
      -0.006602075416594744,
      0.0035224438179284334,
      -0.03399352356791496,
      -0.10571742057800293,
      0.002418309450149536,
      0.023743940517306328,
      0.014641933143138885,
      -0.006850206293165684,
      -0.06674196571111679,
      -0.00848036352545023,
      0.01868697628378868,
      0.008760349825024605,
      -0.03973083198070526,
      -0.04398466274142265,
      -0.1057310402393341,
      -0.018599167466163635,
      0.2015339434146881,
      0.05310703441500664,
      -0.020484209060668945,
      -0.013546238653361797,
      0.025446103885769844,
      0.004193209111690521,
      0.0057190945371985435,
      0.07893814146518707,
      0.006820480339229107,
      0.05138932913541794,
      0.061726946383714676,
      -0.013343081809580326,
      -0.08962037414312363,
      0.022870689630508423,
      0.04377727583050728,
      0.026972007006406784,
      -0.005946104880422354,
      -0.026471436023712158,
      -0.03748439624905586,
      0.07284921407699585,
      0.07992028445005417,
      -0.06120700761675835,
      -0.13079945743083954,
      -0.0468369796872139,
      0.009854165837168694,
      -0.05432884395122528,
      0.09578792005777359,
      0.047580987215042114,
      0.011974706314504147,
      -0.03916136920452118,
      -0.05554395169019699,
      -0.036098405718803406,
      0.06575123965740204,
      -0.03648943081498146,
      0.025252895429730415,
      -0.0028603458777070045,
      0.07064644992351532,
      -0.0003076620923820883,
      -0.0026374023873358965,
      0.012710701674222946,
      0.002166290068998933,
      0.007640180643647909,
      0.006407593842595816,
      0.03720720484852791,
      -0.026024989783763885,
      -0.05669382959604263,
      -0.07231973111629486,
      -0.057332150638103485,
      0.051466185599565506,
      -0.023024525493383408,
      0.012168308719992638,
      -0.022397026419639587,
      -0.004252701997756958,
      -0.005518246907740831,
      0.0031534843146800995,
      -0.021901553496718407,
      0.020886855199933052,
      0.03732964023947716,
      -0.005157293286174536,
      0.00788835808634758,
      -0.010559391230344772,
      0.005665086209774017,
      -0.06298023462295532,
      -0.041223812848329544,
      0.0031968425028026104,
      0.0449867807328701,
      -0.014115137979388237,
      0.060910750180482864,
      -0.003505454631522298,
      0.056223683059215546,
      0.07506643235683441,
      -0.025563787668943405,
      -0.012011353857815266,
      0.061941616237163544,
      -0.03291439265012741,
      -0.06977441161870956,
      -0.07107589393854141,
      -0.0003484886547084898,
      -0.04328034073114395,
      0.10651814937591553,
      0.03674208000302315,
      -0.06586361676454544,
      0.057746484875679016,
      -0.020747097209095955,
      0.04235208407044411,
      0.023665329441428185,
      -0.001981260720640421,
      0.04807346314191818,
      -0.008013906888663769,
      0.03031616099178791,
      -0.011755582876503468,
      0.0006165554514154792,
      -0.052510280162096024,
      0.06493016332387924,
      0.01999727077782154,
      -0.02184401825070381,
      0.024613555520772934,
      0.0006356229423545301,
      0.00047771912068128586,
      0.00044157126103527844,
      0.04263966530561447,
      0.006086166016757488,
      0.04080307483673096
    ],
    [
      -0.006269379518926144,
      -0.006983514875173569,
      0.10008217394351959,
      -0.05446542426943779,
      0.03866613656282425,
      -0.010911187157034874,
      0.007317736744880676,
      0.03644498437643051,
      0.040695954114198685,
      0.030249714851379395,
      -0.04788678139448166,
      0.016535285860300064,
      0.05756383761763573,
      -0.05856115743517876,
      0.03440689295530319,
      -0.025192098692059517,
      -0.06624552607536316,
      0.026154180988669395,
      -0.05802181363105774,
      0.029050953686237335,
      -0.07016556710004807,
      -0.09572257101535797,
      -0.09864367544651031,
      0.03412165120244026,
      0.001501960912719369,
      -0.005616983864456415,
      0.03709535300731659,
      -0.0785704031586647,
      -0.05732417479157448,
      -0.026059841737151146,
      -0.01310413982719183,
      -0.04508870840072632,
      -0.05419721081852913,
      0.0025024330243468285,
      -0.03291810303926468,
      -0.055467233061790466,
      0.09771561622619629,
      0.05359913408756256,
      0.029519423842430115,
      -0.014705457724630833,
      -0.016732748597860336,
      0.0037772366777062416,
      -0.05421923100948334,
      -0.05118760094046593,
      0.023856718093156815,
      -0.053662024438381195,
      -0.019168497994542122,
      0.024776117876172066,
      -0.03343125805258751,
      0.08527558296918869,
      0.033556219190359116,
      0.0037483314517885447,
      -0.08041152358055115,
      0.06945373862981796,
      -0.014847727492451668,
      -0.015343247912824154,
      0.07241015136241913,
      0.010099882259964943,
      0.00025553442537784576,
      -0.031061401590704918,
      0.008391824550926685,
      0.02036232501268387,
      0.031295422464609146,
      0.06882325559854507,
      -0.009045419283211231,
      -0.056484077125787735,
      0.08737459778785706,
      0.03647303208708763,
      0.10345151275396347,
      -0.05645674467086792,
      -0.02592703141272068,
      0.06581459194421768,
      -0.07086300849914551,
      -0.021966269239783287,
      -0.03239614889025688,
      -0.031025422737002373,
      0.011747203767299652,
      -0.008897467516362667,
      -0.04503394290804863,
      -0.06713900715112686,
      -0.034111279994249344,
      -0.01617894135415554,
      0.057333625853061676,
      0.09896469116210938,
      0.040068887174129486,
      0.16249896585941315,
      0.08956745266914368,
      0.0004060502687934786,
      -0.033444371074438095,
      -0.032983578741550446,
      -0.0090261772274971,
      0.07666297256946564,
      -0.026182126253843307,
      -0.014484907500445843,
      0.030971843749284744,
      -0.051172759383916855,
      -0.0004994890186935663,
      -0.018275205045938492,
      -0.013680041767656803,
      0.002455849666148424,
      0.03314727917313576,
      0.10505460947751999,
      0.07848355174064636,
      -0.011120439507067204,
      0.0318424217402935,
      0.06947746872901917,
      -0.04228876158595085,
      0.08995053917169571,
      -0.014710375107824802,
      0.014043718576431274,
      0.02086489275097847,
      -0.06934956461191177,
      0.015596062876284122,
      0.048494771122932434,
      0.03315020352602005,
      0.045499030500650406,
      0.00429428881034255,
      -0.01224811002612114,
      0.021006474271416664,
      0.04741859436035156,
      -0.06031833216547966,
      -0.05479085072875023,
      0.015217689797282219,
      0.011073384433984756,
      0.025983436033129692,
      0.07419182360172272,
      0.04670267924666405,
      -0.03858838602900505,
      0.04211967810988426,
      -0.11181598901748657,
      0.00112624978646636,
      0.06312485784292221,
      -0.03454979509115219,
      0.06390414386987686,
      0.027725864201784134,
      -0.057433877140283585,
      0.014300896786153316,
      -0.019264230504631996,
      0.024527810513973236,
      0.0628424733877182,
      -0.09413732588291168,
      -0.030892593786120415,
      0.07926995307207108,
      -0.023145994171500206,
      -0.006379139143973589,
      0.0680205300450325,
      0.019004298374056816,
      0.02473314292728901,
      0.08208829164505005,
      -0.026197172701358795,
      -0.014057002030313015,
      0.04217027500271797,
      0.03989005088806152,
      0.024677909910678864,
      0.026154212653636932,
      -0.04736095294356346,
      -0.05333103612065315,
      -0.09692428261041641,
      -0.04038768261671066,
      -0.0634985864162445,
      0.09088986366987228,
      -0.004561939276754856,
      -0.03282974660396576,
      0.018306374549865723,
      0.0618627667427063,
      -0.02157275751233101,
      0.0556274950504303,
      0.04131624475121498,
      0.10012196004390717,
      0.016244174912571907,
      0.04369931295514107,
      0.010054957121610641,
      -0.07054741680622101,
      -0.05041646584868431,
      -0.044442564249038696,
      0.0667591467499733,
      -0.05520215258002281,
      -0.049732107669115067,
      0.01216359157115221,
      -0.004308915231376886,
      -0.023306801915168762,
      -0.008022220805287361,
      -0.0443546436727047,
      0.07758614420890808,
      -0.037116408348083496,
      -0.0074945478700101376,
      0.15363812446594238,
      0.05589762702584267,
      0.05851081758737564,
      -0.061301201581954956,
      -0.10149317234754562,
      0.03893076628446579,
      0.08603756874799728,
      -0.0014246945502236485,
      -0.015297218225896358,
      -0.03859880194067955,
      -0.020822230726480484,
      -0.0005600667209364474,
      0.009217184968292713,
      -0.0011156884720548987,
      -0.08398952335119247,
      0.06806306540966034,
      -0.06163110211491585,
      0.008824840188026428,
      -0.04763384908437729,
      0.008753502741456032,
      -0.07893067598342896,
      0.10535481572151184,
      -0.05175211280584335,
      -0.032331980764865875,
      0.005590174347162247,
      0.013628015294671059,
      0.030296197161078453,
      -0.002093874616548419,
      0.0453462190926075,
      0.05059242993593216,
      0.02764872834086418,
      -0.08632776141166687,
      -0.03280084580183029,
      -0.00716760428622365,
      -0.021111024543642998,
      -0.023163625970482826,
      -0.013470363803207874,
      0.0382702611386776,
      0.03683197498321533,
      -0.02968759275972843,
      -0.04960734397172928,
      -0.021275658160448074,
      0.020385244861245155,
      0.033256612718105316,
      0.031838491559028625,
      0.004487224388867617,
      0.03581204265356064,
      0.013615081086754799,
      -0.0669020339846611,
      0.08210373669862747,
      -0.0450708232820034,
      -0.002876315265893936,
      0.0007295858231373131,
      0.01552903838455677,
      -0.011943981051445007,
      -0.06024567410349846,
      -0.13325975835323334,
      -0.03700690343976021,
      -0.022779244929552078,
      0.025567930191755295,
      0.011401516385376453,
      -0.014714475721120834,
      0.007288272492587566,
      0.02819240652024746,
      0.0840195044875145,
      0.010908331722021103,
      0.020281601697206497,
      -0.05573771893978119,
      0.06839300692081451,
      0.01539097260683775,
      -0.05856266990303993,
      0.00454524252563715,
      -0.02536444552242756,
      0.07398257404565811,
      -0.01704055815935135,
      -0.05261443555355072,
      0.024165572598576546,
      0.06974675506353378,
      -0.016008377075195312,
      0.021342234686017036,
      0.03723729029297829,
      0.01195967011153698,
      -0.03595791012048721,
      0.040271490812301636,
      0.03092615306377411,
      -0.05354522168636322,
      -0.08462344110012054,
      -0.04755042493343353,
      -0.055653952062129974,
      -0.06021881476044655,
      -0.025318266823887825,
      0.04450000077486038,
      -0.070586659014225,
      0.040641747415065765,
      0.008719711564481258,
      0.030011923983693123,
      -0.03756750002503395,
      -0.00013957894407212734,
      -0.0015638645272701979,
      0.043214280158281326,
      -0.039670344442129135,
      0.047620419412851334,
      0.0837753415107727,
      0.014264104887843132,
      0.03175775706768036,
      -0.004170593339949846,
      -0.0037125712260603905,
      0.0457596629858017,
      -0.06761656701564789,
      0.05040889233350754,
      -0.009384628385305405,
      -0.01821119897067547,
      0.06451106816530228,
      0.08393415808677673,
      0.02242080308496952,
      -0.029002521187067032,
      -0.009104898199439049,
      0.034296054393053055,
      -0.07866799086332321,
      0.032640889286994934,
      0.10548269748687744,
      0.08460402488708496,
      0.03807099908590317,
      -0.014423211105167866,
      -0.013038468547165394,
      -0.04046470671892166,
      -0.004921963904052973,
      -0.08479924499988556,
      0.016398951411247253,
      0.008633730001747608,
      -0.05749880522489548,
      0.02672012709081173,
      -0.12957771122455597,
      -0.009165899828076363,
      0.03619085252285004,
      0.025426656007766724,
      0.023231374099850655,
      -0.10457081347703934,
      -0.06463246047496796,
      0.0547611303627491,
      0.015445566736161709,
      -0.0051445504650473595,
      0.022306783124804497,
      -0.016260670498013496,
      -0.004443086218088865,
      -0.06356184929609299,
      -0.014545722864568233,
      -0.04838567599654198,
      -0.005102848168462515,
      0.022071396932005882,
      -0.04583524167537689,
      0.014908665791153908,
      0.02248324081301689,
      -0.062331341207027435,
      -0.010653780773282051,
      -0.022286733612418175,
      0.006042060907930136,
      -7.704502058913931e-05,
      0.013672788627445698,
      -0.045956410467624664,
      -0.004651724826544523,
      0.05761709809303284,
      -0.04346583038568497,
      0.06175725907087326,
      0.009734093211591244,
      -0.045826204121112823,
      -0.018397735431790352,
      -0.08255244046449661,
      0.0015405848389491439,
      -0.046195708215236664,
      0.05486518144607544,
      -0.02489488013088703,
      0.024647489190101624,
      -0.028972942382097244,
      -0.053859710693359375,
      -0.03162381425499916,
      0.05957100912928581,
      0.014237889088690281,
      0.006035972386598587,
      -0.0481950119137764,
      0.04560362175107002,
      -0.12993638217449188,
      -0.07218810170888901,
      -0.003188137896358967,
      0.0073039657436311245,
      0.044372912496328354,
      0.05644436180591583,
      0.016511455178260803,
      0.040765535086393356,
      -0.05270612984895706,
      -0.01835603639483452,
      0.0032379983458667994,
      -0.0654110461473465,
      -0.015257926657795906,
      0.048892270773649216,
      -0.03596765920519829,
      -0.006750139873474836,
      0.031413014978170395,
      -0.007298652548342943,
      0.022572899237275124,
      -0.02550266869366169,
      -0.05926207825541496,
      0.026615066453814507,
      -0.03964469954371452,
      -0.005723745096474886,
      0.06869158148765564,
      0.08099902421236038,
      -0.050461336970329285,
      0.03239430487155914,
      0.044899359345436096,
      0.06961990892887115,
      0.019149785861372948,
      0.023698462173342705,
      0.021042287349700928,
      0.0657874271273613,
      0.042892567813396454,
      -0.054135460406541824,
      -0.0052976105362176895,
      -0.003511014161631465,
      0.041959892958402634,
      0.021789006888866425,
      -0.01619110070168972,
      -0.004912103526294231,
      -0.025147786363959312,
      0.04921901971101761,
      -0.027928899973630905,
      -0.0083772586658597,
      -0.01857033744454384,
      -0.0928209200501442,
      -0.01341842208057642,
      0.008930055424571037,
      -0.08238380402326584,
      -0.06687659025192261,
      0.02471879869699478,
      -0.023070240393280983,
      -0.014863843098282814,
      0.02460695616900921,
      0.10801985114812851,
      -0.011025026440620422,
      -0.08252975344657898,
      -0.002320254920050502,
      -0.05964193120598793,
      -0.00680906418710947,
      0.011950351297855377,
      0.017885850742459297,
      -0.043005358427762985,
      -0.0628010556101799,
      0.046411123126745224,
      0.010407124646008015,
      0.07844675332307816,
      0.0008965962333604693,
      -0.013118742033839226,
      -0.0017911350587382913,
      0.020939119160175323,
      -0.005691175349056721,
      -0.0002584264148026705,
      -0.0140879200771451,
      -0.020078111439943314,
      0.009513599798083305,
      -0.007123829331248999,
      -0.07170818001031876,
      0.09286583214998245,
      -0.02216249518096447,
      -0.04840846732258797,
      -0.059328485280275345,
      0.07153801620006561,
      -0.033595092594623566,
      -0.05850909650325775,
      -0.007773957215249538,
      -0.03757234662771225,
      0.06593336164951324,
      0.00485642347484827,
      0.0008162061567418277,
      -0.09229109436273575,
      -0.08711788058280945,
      0.014114209450781345,
      0.019163982942700386,
      -0.008692728355526924,
      0.0068838102743029594,
      0.012041494250297546,
      0.010800810530781746,
      -0.04828428104519844,
      0.001923388335853815,
      -0.0004608446906786412,
      -0.053581055253744125,
      -0.001301340525969863,
      -0.007446827366948128,
      0.05654526501893997,
      0.05231244117021561,
      0.02309049852192402,
      0.07547956705093384,
      0.024454358965158463,
      -0.0856173187494278,
      0.07952271401882172,
      -0.03487260267138481,
      0.010636705905199051,
      0.016179213300347328,
      0.052007462829351425,
      0.05824769660830498,
      -0.06748563796281815,
      -0.04226909950375557,
      0.03876158967614174,
      0.00011040946992579848,
      -0.014686440117657185,
      -0.04016818478703499,
      -0.048691291362047195,
      -0.017788540571928024,
      0.1237204372882843,
      0.02889777533710003,
      0.0445084348320961,
      0.021594105288386345,
      0.005444000009447336,
      -0.041331470012664795,
      -0.03837341442704201,
      0.053631674498319626,
      -0.01842045783996582,
      0.053225625306367874
    ],
    [
      0.0539836622774601,
      -0.039101626724004745,
      -0.02457074448466301,
      0.019844163209199905,
      -0.014657187275588512,
      0.07227859646081924,
      -0.04012966528534889,
      -0.03376821428537369,
      0.006700911559164524,
      0.0005802154773846269,
      0.0213752593845129,
      0.051071636378765106,
      -0.005575967486947775,
      0.052924804389476776,
      0.09548468142747879,
      0.1214408427476883,
      0.02488534525036812,
      -0.07607422769069672,
      -0.0599394291639328,
      0.05551068112254143,
      0.010412463918328285,
      -0.022340426221489906,
      -0.05853932723402977,
      -0.009004119783639908,
      0.039352599531412125,
      0.009660174138844013,
      -0.004321053624153137,
      -0.053713977336883545,
      0.03004373051226139,
      0.026744870468974113,
      0.012106800451874733,
      -0.0631432980298996,
      0.03999527171254158,
      -0.005177624057978392,
      0.04055669158697128,
      0.00501349987462163,
      -0.06852789968252182,
      -0.02845020405948162,
      0.01317482627928257,
      -0.012953542172908783,
      0.006486098747700453,
      0.036109309643507004,
      0.029807042330503464,
      -0.04076410457491875,
      -0.012746586464345455,
      0.008685123175382614,
      0.001911462633870542,
      -0.05020831152796745,
      -0.0033038214314728975,
      -0.02348428964614868,
      -0.0222688689827919,
      -0.012854117900133133,
      0.012374794110655785,
      -0.01168783288449049,
      -0.040262237191200256,
      0.07096856087446213,
      0.06323014199733734,
      0.0014148784102872014,
      0.01788484677672386,
      -0.03348267823457718,
      -0.022517602890729904,
      0.07044866681098938,
      -0.06630460917949677,
      -0.042407672852277756,
      -0.0568334124982357,
      -0.06289910525083542,
      -0.0036349690053611994,
      0.05372398719191551,
      -0.03826146945357323,
      -0.013788268901407719,
      -0.09763233363628387,
      0.025428131222724915,
      0.047237277030944824,
      -0.05569954589009285,
      0.01428331807255745,
      -0.019435741007328033,
      0.037159547209739685,
      0.028283972293138504,
      -0.011298323050141335,
      0.0656471848487854,
      -0.01931661181151867,
      -0.002748851664364338,
      0.04368980601429939,
      -0.0509827546775341,
      -0.02689959853887558,
      -0.09574686735868454,
      -0.028575565665960312,
      0.025457030162215233,
      -0.056108683347702026,
      -0.03277669847011566,
      0.05560294911265373,
      -0.00011868277215398848,
      -0.050713762640953064,
      0.03465289995074272,
      -0.007168311160057783,
      0.029135586693882942,
      -0.030609603971242905,
      0.012257817201316357,
      0.05276433005928993,
      -0.0006345327128656209,
      -0.005466202273964882,
      0.07043696939945221,
      0.09180048853158951,
      -0.008293094113469124,
      -0.06567025184631348,
      -0.064837746322155,
      -0.04291246831417084,
      -0.027287229895591736,
      0.059854403138160706,
      0.038886912167072296,
      -0.04640144109725952,
      0.004062531515955925,
      0.008737289346754551,
      -0.0048069823533296585,
      0.004528235178440809,
      0.0036412423942238092,
      -0.00036992941750213504,
      0.03586762398481369,
      0.006631799973547459,
      0.03419795259833336,
      -0.027368970215320587,
      -0.028501147404313087,
      -0.07452079653739929,
      0.051309142261743546,
      -0.03937946632504463,
      0.032172009348869324,
      0.05030051991343498,
      -0.007270336151123047,
      -0.015193748287856579,
      0.02662508189678192,
      0.006479592528194189,
      0.015677915886044502,
      0.10234441608190536,
      -0.03259384259581566,
      -0.06257856637239456,
      0.01246640831232071,
      0.022686075419187546,
      0.08137329667806625,
      0.08130909502506256,
      -0.004176169168204069,
      -0.03265019878745079,
      -0.017200207337737083,
      0.02609456516802311,
      -0.024299612268805504,
      -0.02311244048178196,
      0.016647139564156532,
      0.010272357612848282,
      0.07194235175848007,
      0.012485283426940441,
      0.09045525640249252,
      0.001753416028805077,
      -0.006818004883825779,
      -0.034692417830228806,
      -0.07954245060682297,
      0.020692946389317513,
      -0.0841284990310669,
      0.002927787834778428,
      0.003837406635284424,
      -0.05471226945519447,
      -0.018492132425308228,
      -0.030989043414592743,
      0.0003627378027886152,
      -0.07516399770975113,
      -0.01045141089707613,
      0.013674002140760422,
      0.01480218768119812,
      0.051529526710510254,
      -0.02719784341752529,
      0.0009556933073326945,
      0.08405419439077377,
      0.024154847487807274,
      -0.02802382782101631,
      -0.06985010951757431,
      -0.002107556676492095,
      0.02522592432796955,
      0.021765127778053284,
      -0.0411294624209404,
      -0.052940040826797485,
      -0.0801929458975792,
      -0.09931681305170059,
      -0.015300455503165722,
      -0.014640219509601593,
      0.005642361007630825,
      -0.10452265292406082,
      -0.04477550461888313,
      9.639404015615582e-05,
      0.029607413336634636,
      0.033559650182724,
      0.009508047252893448,
      -0.04348425567150116,
      -0.004195221234112978,
      0.05155276134610176,
      -0.03549586609005928,
      -0.002802473260089755,
      0.0034495482686907053,
      0.08000210672616959,
      0.00012446731852833182,
      -0.0002918062382377684,
      0.03396856039762497,
      -0.026174956932663918,
      0.009824787266552448,
      -0.03892749920487404,
      -0.025395682081580162,
      -0.002750961808487773,
      -0.06257826834917068,
      -0.0111826928332448,
      -0.015007426962256432,
      0.0517876110970974,
      0.013768583536148071,
      0.0023926696740090847,
      0.013433514162898064,
      -0.011138083413243294,
      -0.009889069013297558,
      -0.0015122902113944292,
      -0.0035607509780675173,
      -0.06374982744455338,
      0.05851222202181816,
      -0.04882221296429634,
      0.06444533914327621,
      -0.005898948293179274,
      -0.023263772949576378,
      -0.019182028248906136,
      -0.002786970464512706,
      -0.019502008333802223,
      -0.013032061979174614,
      -0.0012555503053590655,
      -0.002893649274483323,
      -0.03652331605553627,
      0.04560212045907974,
      0.008162684738636017,
      -0.0159661453217268,
      -0.07780490815639496,
      -0.08436552435159683,
      0.04050551354885101,
      -0.08583766967058182,
      0.016342125833034515,
      0.03204254433512688,
      -0.07789197564125061,
      0.043799299746751785,
      -0.04728721082210541,
      -0.05978506803512573,
      0.05653057247400284,
      0.0592428594827652,
      0.0027690979186445475,
      0.006421445868909359,
      0.021262262016534805,
      -0.023478996008634567,
      -0.04838705062866211,
      -0.02299577370285988,
      0.09203214198350906,
      0.026111481711268425,
      -0.05374932661652565,
      -0.06134723126888275,
      -0.08069506287574768,
      0.007178426720201969,
      0.014400516636669636,
      -0.022513853386044502,
      -0.008211709558963776,
      -0.08331812173128128,
      -0.028347846120595932,
      0.17396047711372375,
      -0.03242669999599457,
      0.023993222042918205,
      -0.03511073812842369,
      0.007381738629192114,
      0.024070806801319122,
      0.003207071218639612,
      0.03017229400575161,
      -0.016949187964200974,
      0.08670099824666977,
      -0.04997346177697182,
      0.030258983373641968,
      -0.03352613002061844,
      -0.14946404099464417,
      0.02550879307091236,
      0.1204029843211174,
      -0.07350853085517883,
      0.02732519991695881,
      -0.0013357172720134258,
      0.050404518842697144,
      0.05122356116771698,
      0.008633029647171497,
      0.024256091564893723,
      -0.003664024407044053,
      0.028519732877612114,
      0.07892067730426788,
      -0.08541801571846008,
      0.0031364772003144026,
      -0.03041146695613861,
      -0.0017888901056721807,
      -0.050031792372465134,
      -0.02510310895740986,
      -0.020675765350461006,
      0.055128064006567,
      -0.03876309096813202,
      -0.06595779955387115,
      0.04854555055499077,
      -0.037775274366140366,
      -0.005397645290941,
      0.021499667316675186,
      -0.02703651413321495,
      0.031046172603964806,
      -0.05961108207702637,
      -0.0011130966013297439,
      0.05333244055509567,
      -0.06261409819126129,
      -0.055435676127672195,
      0.011185682378709316,
      0.01885361410677433,
      -0.023535175248980522,
      0.0026540232356637716,
      -0.08309438079595566,
      0.020392514765262604,
      -0.01707947440445423,
      0.003540236037224531,
      0.056777164340019226,
      0.0040178364142775536,
      -0.04281366989016533,
      -0.05737646296620369,
      0.027806201949715614,
      -0.026169104501605034,
      -0.04753889888525009,
      0.057160645723342896,
      0.01846553571522236,
      -0.0012560358736664057,
      -0.08877170085906982,
      -0.01325985323637724,
      -0.0027689561247825623,
      0.013108152896165848,
      0.036033786833286285,
      0.055937279015779495,
      -0.01496028807014227,
      -0.045225758105516434,
      -0.044573839753866196,
      -0.06940506398677826,
      -0.02130848728120327,
      -0.0498279444873333,
      -0.019609322771430016,
      -0.031936150044202805,
      0.011379179544746876,
      0.07128315418958664,
      0.05892070382833481,
      0.013323617167770863,
      0.019227920100092888,
      0.007645181380212307,
      0.014433665201067924,
      0.0007399726891890168,
      -0.005631731823086739,
      0.08210284262895584,
      -0.06207793951034546,
      -0.0021064875181764364,
      -0.025762615725398064,
      0.08590207248926163,
      -0.0014919923851266503,
      -0.04039396718144417,
      0.014054614119231701,
      0.020830247551202774,
      -0.08678135275840759,
      -0.0073021347634494305,
      0.02539561130106449,
      -0.052910953760147095,
      0.03566422685980797,
      0.004563334863632917,
      -0.035276684910058975,
      -0.01329004019498825,
      -0.020101431757211685,
      0.04546229913830757,
      -0.06528447568416595,
      -0.03114522248506546,
      -0.03256982937455177,
      -0.00285536702722311,
      -0.032441217452287674,
      0.023167649284005165,
      -0.022146688774228096,
      0.037897739559412,
      -0.03129560127854347,
      -0.14102452993392944,
      -0.020044168457388878,
      -0.042410776019096375,
      0.027217382565140724,
      -0.035546813160181046,
      0.1171506717801094,
      0.04987858235836029,
      -0.012035757303237915,
      -0.07380981743335724,
      0.06097406893968582,
      -0.012932983227074146,
      -0.0013138513313606381,
      0.0016913785366341472,
      0.015955302864313126,
      -0.10663272440433502,
      -0.027784809470176697,
      -0.06181389465928078,
      -0.06471036374568939,
      0.11568273603916168,
      0.04868403449654579,
      0.002210446400567889,
      0.06454243510961533,
      -0.0413665808737278,
      -0.03630930557847023,
      0.001126798801124096,
      -0.011174292303621769,
      -0.046559661626815796,
      -0.06689482182264328,
      -0.047796059399843216,
      0.024154337123036385,
      -0.026100993156433105,
      -0.0658378079533577,
      0.028088297694921494,
      -0.016870707273483276,
      -0.10237346589565277,
      -0.10320087522268295,
      0.012418814934790134,
      -0.007668559905141592,
      0.006075282581150532,
      0.0009041082230396569,
      0.020265303552150726,
      0.02450282871723175,
      0.019387632608413696,
      0.024733178317546844,
      -0.04843439906835556,
      -0.04828428849577904,
      -0.006593259051442146,
      0.027588291093707085,
      0.020370764657855034,
      -0.006761685945093632,
      -0.03907538577914238,
      0.03182254359126091,
      -0.07334832847118378,
      -0.0016548926942050457,
      -0.05350501090288162,
      0.012846813537180424,
      -0.07014159858226776,
      0.030363697558641434,
      -0.06804490834474564,
      -0.010129597969353199,
      -0.06667100638151169,
      -0.018149947747588158,
      -0.057558026164770126,
      -0.00908107403665781,
      -0.008618389256298542,
      0.02979227714240551,
      -0.02735116332769394,
      -0.029929744079709053,
      -0.03143582120537758,
      0.01607976108789444,
      -0.044882550835609436,
      0.10920633375644684,
      -0.009149309247732162,
      -0.02948823943734169,
      0.03748759999871254,
      0.024199003353714943,
      -0.07199709862470627,
      -0.020567428320646286,
      0.007753302808851004,
      -0.029991982504725456,
      -0.0002938575635198504,
      -0.03299717605113983,
      -0.025833310559391975,
      0.045248497277498245,
      0.010260515846312046,
      0.04704360291361809,
      -0.07561707496643066,
      -0.021816760301589966,
      0.03795306757092476,
      -0.051246706396341324,
      -0.0585588738322258,
      -0.04601527377963066,
      -0.026583077386021614,
      -0.011209522373974323,
      -0.018139518797397614,
      0.01642649807035923,
      0.07361410558223724,
      0.022358344867825508,
      0.04998398944735527,
      0.07187569886445999,
      -0.01490643247961998,
      0.09616699069738388,
      0.06054729223251343,
      -0.017511069774627686,
      -0.01194552332162857,
      0.0011999544221907854,
      -0.09255058318376541,
      0.02512410841882229,
      0.023331379517912865,
      -0.02868996188044548,
      0.0017606205074116588,
      -0.013741116039454937,
      0.013495001010596752,
      -0.11031094193458557,
      0.004920583683997393,
      -0.08993379771709442,
      -0.005360097158700228,
      0.0010897392639890313,
      0.035756826400756836,
      -0.0783504918217659,
      -0.03946711868047714,
      -0.0229459498077631,
      0.02068241871893406,
      0.01569957658648491,
      0.06560536473989487,
      0.03722007945179939,
      -0.023381346836686134
    ],
    [
      -0.04176338016986847,
      0.07088562101125717,
      -0.008239763788878918,
      0.010072390548884869,
      -0.011669952422380447,
      -0.07713781297206879,
      -0.020397290587425232,
      0.060129567980766296,
      -0.016501009464263916,
      0.02857469581067562,
      0.03003845177590847,
      -0.016787780448794365,
      -0.011618905700743198,
      -0.03668146952986717,
      0.04021166265010834,
      0.0419490709900856,
      0.04438226670026779,
      -0.0005098450346849859,
      0.014598065055906773,
      -0.05098869279026985,
      0.026102600619196892,
      0.03458661213517189,
      0.04847197234630585,
      -0.047410886734724045,
      -0.0008985825697891414,
      -0.015514637343585491,
      0.09661414474248886,
      -0.06781165301799774,
      -0.034513115882873535,
      -0.004206880461424589,
      0.016557253897190094,
      -0.05861174687743187,
      0.04131684452295303,
      -0.018765809014439583,
      -0.07269898056983948,
      -0.0198631901293993,
      0.07670016586780548,
      0.059440769255161285,
      0.03564225509762764,
      0.02585088089108467,
      -0.012540941126644611,
      -0.012779033742845058,
      -0.032335665076971054,
      0.06653003394603729,
      0.023415951058268547,
      -0.01918535679578781,
      -0.023252595216035843,
      -0.02842186763882637,
      0.04190990701317787,
      0.016236502677202225,
      0.034938737750053406,
      0.08487791568040848,
      0.0017827469855546951,
      -0.037306204438209534,
      -0.07759116590023041,
      0.03538026660680771,
      -0.0208557341247797,
      -0.0705275908112526,
      -0.05592760071158409,
      -0.041413433849811554,
      -0.002885770983994007,
      0.014183813706040382,
      -0.08321152627468109,
      -0.017516175284981728,
      0.041613634675741196,
      -0.034956514835357666,
      -0.08788243681192398,
      0.059505026787519455,
      0.029359381645917892,
      0.03612353652715683,
      -0.027935268357396126,
      0.04054537042975426,
      0.001870076055638492,
      0.04482179507613182,
      0.07906319200992584,
      -0.05785686522722244,
      -0.02170535735785961,
      0.07494295388460159,
      0.049141306430101395,
      0.005398505832999945,
      -0.04921586811542511,
      -0.024277321994304657,
      -0.03550514578819275,
      -0.029844997450709343,
      0.009704571217298508,
      -0.01878601685166359,
      -0.10670556128025055,
      0.051432833075523376,
      -0.08441716432571411,
      0.02319340780377388,
      0.024414774030447006,
      0.03207675367593765,
      0.07821314036846161,
      0.10659646987915039,
      0.0315595343708992,
      0.013146811164915562,
      -0.02792053483426571,
      -0.014008643105626106,
      -0.05076959729194641,
      -0.021757392212748528,
      -0.045709818601608276,
      0.014375112019479275,
      0.10990359634160995,
      -0.06866803765296936,
      -0.0783051997423172,
      0.057672154158353806,
      0.03505384176969528,
      0.037198495119810104,
      0.059795208275318146,
      0.06420091539621353,
      -0.02288893796503544,
      -0.018814651295542717,
      0.009940042160451412,
      0.08729144185781479,
      -0.02452700026333332,
      0.05149788036942482,
      0.03638250008225441,
      0.0884280726313591,
      -0.039027027785778046,
      -0.03468020260334015,
      0.02802160196006298,
      -0.01838548481464386,
      -0.03743651881814003,
      -0.03407172113656998,
      0.009832924231886864,
      0.020546576008200645,
      -0.031002260744571686,
      -0.006337015423923731,
      0.09074188023805618,
      -0.07736346870660782,
      -0.04172107204794884,
      0.009255166165530682,
      0.002645678585395217,
      -0.025678835809230804,
      0.010317684151232243,
      -0.03642876446247101,
      -0.0031462127808481455,
      0.03941117972135544,
      0.021538475528359413,
      -0.029858971014618874,
      0.06393445283174515,
      -0.05509702488780022,
      0.010735904797911644,
      0.026961741968989372,
      0.0086786774918437,
      -0.07530048489570618,
      0.03920209780335426,
      0.046053577214479446,
      -0.05616366118192673,
      0.020727703347802162,
      -0.021352069452404976,
      -0.022501718252897263,
      0.01564110442996025,
      0.0662977322936058,
      -0.0417107492685318,
      0.003687316318973899,
      0.0019360501319169998,
      0.03034784086048603,
      -0.029588844627141953,
      0.006936472840607166,
      -0.043974053114652634,
      -0.04322599619626999,
      -0.029010724276304245,
      -0.050376877188682556,
      -0.06733004748821259,
      -0.030283598229289055,
      -0.003742485772818327,
      0.047304876148700714,
      -0.05003344640135765,
      0.053468186408281326,
      0.028207547962665558,
      0.03715655952692032,
      0.05750769376754761,
      -0.002640936989337206,
      -0.02011539787054062,
      0.05240827426314354,
      0.031788118183612823,
      0.06220638379454613,
      0.013524748384952545,
      0.03949389606714249,
      0.04535423964262009,
      0.027983160689473152,
      -0.022346317768096924,
      -0.015860851854085922,
      -0.019508838653564453,
      -0.026150552555918694,
      0.07368873059749603,
      0.05982320383191109,
      0.01958606205880642,
      0.009592369198799133,
      -0.02849218249320984,
      0.02825291082262993,
      -0.012944701127707958,
      0.023150678724050522,
      0.02107962779700756,
      -0.09046924114227295,
      -0.029107170179486275,
      0.013385516591370106,
      0.04813524708151817,
      0.015759048983454704,
      -0.05407216027379036,
      -0.002430713502690196,
      0.040537916123867035,
      0.013094170019030571,
      -0.012726617977023125,
      0.025983009487390518,
      0.002366800094023347,
      0.03490396589040756,
      -0.061125803738832474,
      -0.04358475282788277,
      0.05864809453487396,
      -0.021700987592339516,
      -0.017192544415593147,
      -0.02151697315275669,
      -0.007454860955476761,
      -0.0030828872695565224,
      0.02356676384806633,
      -0.006169108208268881,
      0.020205877721309662,
      -0.011877262964844704,
      0.017131347209215164,
      -0.033081501722335815,
      -0.0738474577665329,
      -0.03640817850828171,
      0.0591740608215332,
      0.011844556778669357,
      -0.10765828937292099,
      0.03933126851916313,
      -0.06990092247724533,
      -0.05188783258199692,
      0.02984030731022358,
      0.006562076974660158,
      0.04836253076791763,
      -0.020312078297138214,
      -0.013852039352059364,
      0.04885730519890785,
      0.08356761932373047,
      0.054621465504169464,
      0.025080731138586998,
      -0.06392142921686172,
      0.01240172702819109,
      -0.025481441989541054,
      0.012350227683782578,
      -0.02820151299238205,
      -0.044442806392908096,
      -0.003423529677093029,
      -0.060388024896383286,
      -0.0025068288668990135,
      -0.03884807601571083,
      0.044357623904943466,
      0.014961600303649902,
      -0.03653436154127121,
      0.003625380340963602,
      0.03629808500409126,
      0.016888119280338287,
      0.043793581426143646,
      0.02247956581413746,
      0.045154593884944916,
      -0.02039901353418827,
      0.019618500024080276,
      -0.02820313721895218,
      -0.09169046580791473,
      -0.022264357656240463,
      -0.028736399486660957,
      -0.047682952135801315,
      0.04711095616221428,
      -0.03078574687242508,
      0.05222073569893837,
      -0.011646410450339317,
      -0.04008598253130913,
      -0.003700732486322522,
      -0.01845412142574787,
      0.021770484745502472,
      -0.004200950730592012,
      -0.017098700627684593,
      -0.0020522973500192165,
      0.04875044897198677,
      0.013275939971208572,
      0.10669712722301483,
      0.029341651126742363,
      0.005393226630985737,
      0.009488945826888084,
      -0.003261585021391511,
      -0.01203484833240509,
      -0.08720038831233978,
      -0.022507769986987114,
      -0.0474606491625309,
      -0.025652995333075523,
      0.04010156914591789,
      -0.04779284819960594,
      0.06385832279920578,
      0.0061470018699765205,
      0.0021942462772130966,
      0.02034015581011772,
      -0.037693630903959274,
      0.05439191684126854,
      -0.016333134844899178,
      -0.01166342943906784,
      -0.017753103747963905,
      -0.016360068693757057,
      -0.02991192787885666,
      0.0028936529997736216,
      -0.03526097536087036,
      0.04936489090323448,
      -0.015291093848645687,
      0.04182108864188194,
      0.003971749916672707,
      -0.014125733636319637,
      -0.020109612494707108,
      0.08030443638563156,
      0.020678143948316574,
      0.0801430344581604,
      -0.018174810335040092,
      0.03252378851175308,
      -0.019701218232512474,
      -0.05632281303405762,
      -0.06464670598506927,
      -0.03870116546750069,
      -0.033044490963220596,
      -0.03973887488245964,
      -0.036135561764240265,
      0.014036756940186024,
      -0.05353201553225517,
      -0.030633753165602684,
      -0.028661098331212997,
      0.01393955573439598,
      -0.03591062128543854,
      0.020209943875670433,
      -0.012830650433897972,
      0.028847716748714447,
      0.07283727824687958,
      0.055981364101171494,
      -0.03597710654139519,
      0.055904533714056015,
      0.03187616914510727,
      0.009641731157898903,
      0.028309766203165054,
      0.0285164974629879,
      -0.005517288111150265,
      -0.020029358565807343,
      0.08750752359628677,
      0.022154461592435837,
      0.017660081386566162,
      -0.002831964520737529,
      0.026321079581975937,
      -0.03955546021461487,
      0.03769618272781372,
      0.04358562454581261,
      0.05126895755529404,
      0.08836044371128082,
      0.05135665461421013,
      0.05415981262922287,
      0.09024380892515182,
      -0.003494515549391508,
      -0.0333118736743927,
      -0.021989574655890465,
      0.09611674398183823,
      0.018742118030786514,
      0.045185308903455734,
      -0.02487615868449211,
      -0.0108031015843153,
      0.013012007810175419,
      -0.002203592099249363,
      -0.04608951508998871,
      0.005198368802666664,
      -0.004466004204005003,
      -0.056234363466501236,
      0.0009261344675906003,
      0.08975803852081299,
      0.09501969069242477,
      0.05057738348841667,
      0.04788491874933243,
      0.0383831188082695,
      0.08240395784378052,
      0.0418037585914135,
      -0.004295271821320057,
      0.0226825512945652,
      -0.03940385952591896,
      -0.10870129615068436,
      0.032902758568525314,
      0.06170925870537758,
      -0.030524523928761482,
      -0.016297053545713425,
      0.09550102055072784,
      0.008394474163651466,
      -0.033402685075998306,
      0.1328425258398056,
      0.019141728058457375,
      0.013265252113342285,
      0.015029141679406166,
      -0.07624566555023193,
      -0.09319330751895905,
      0.03243864327669144,
      0.09217283874750137,
      -0.018462883308529854,
      0.032020892947912216,
      0.009484522975981236,
      0.1221279427409172,
      0.006002374459058046,
      0.007832840085029602,
      -0.010081173852086067,
      -0.04731562361121178,
      -0.023487593978643417,
      -0.07703929394483566,
      -0.009151984937489033,
      0.06175106763839722,
      0.06463255733251572,
      0.033621326088905334,
      -0.04854618385434151,
      0.053018804639577866,
      0.04249852895736694,
      0.08427104353904724,
      -0.029249368235468864,
      0.042925652116537094,
      -0.008464116603136063,
      -0.07680340111255646,
      0.017546800896525383,
      -0.039406824856996536,
      -0.04738404601812363,
      -0.026419298723340034,
      -0.06998101621866226,
      -0.007585549261420965,
      -0.005846088286489248,
      -0.0019701754208654165,
      -0.04620784893631935,
      -0.08756651729345322,
      0.0023544051218777895,
      0.04336715489625931,
      0.03673255443572998,
      -0.04896746948361397,
      0.05914292484521866,
      0.025573868304491043,
      -0.013157429173588753,
      0.0815262570977211,
      0.04680684581398964,
      -0.03271998092532158,
      0.050089042633771896,
      -0.016994819045066833,
      0.04419239982962608,
      -0.014430578798055649,
      0.03204585239291191,
      0.11943821609020233,
      -0.061750173568725586,
      0.023559769615530968,
      -0.015968572348356247,
      -0.006145642139017582,
      -0.03854231908917427,
      0.0581916905939579,
      0.06730392575263977,
      0.0377153642475605,
      0.06481438130140305,
      0.048244696110486984,
      0.009827656671404839,
      -0.022782744839787483,
      -0.022589202970266342,
      -0.05167030170559883,
      -0.05377040058374405,
      -0.05830460786819458,
      0.01711132749915123,
      -0.07911477982997894,
      -0.02794271893799305,
      0.0050439490005373955,
      -0.018359770998358727,
      -0.08122649788856506,
      -0.0007116389460861683,
      0.14170630276203156,
      0.05499621108174324,
      -0.019694490358233452,
      -0.06278862804174423,
      -0.06539930403232574,
      -0.015640102326869965,
      -0.051248133182525635,
      0.04935473948717117,
      -0.01638440228998661,
      0.009204834699630737,
      -0.03345385938882828,
      -0.024804633110761642,
      -0.0005838331417180598,
      -0.0500924251973629,
      0.023227332159876823,
      -0.05422140285372734,
      -0.011952118948101997,
      0.023829001933336258,
      0.03721246495842934,
      -0.013858824037015438,
      0.059237558394670486,
      -0.03029416687786579,
      0.05548931658267975,
      -0.07173466682434082,
      -0.030063588172197342,
      -0.029760273173451424,
      0.002674407558515668,
      0.0471312515437603,
      -0.003906254190951586,
      0.048321276903152466,
      0.013648733496665955,
      0.03300401195883751,
      -0.015988195315003395,
      -0.06490003317594528,
      0.018655771389603615,
      -0.011190548539161682,
      -0.005154273472726345,
      -0.06793223321437836
    ],
    [
      0.027282342314720154,
      0.025692583993077278,
      0.08121632039546967,
      0.035616323351860046,
      0.03715357929468155,
      -0.0578288808465004,
      0.049010757356882095,
      -0.01707187108695507,
      0.04512135684490204,
      0.028801463544368744,
      -0.016282079741358757,
      -0.005531189031898975,
      -0.08091886341571808,
      0.025406422093510628,
      0.02731192298233509,
      0.006915048696100712,
      0.05944477394223213,
      0.030886556953191757,
      -0.051349934190511703,
      0.04351630061864853,
      -0.07635177671909332,
      -0.09589789807796478,
      0.06166302040219307,
      0.0026357974857091904,
      -0.028371017426252365,
      -0.06808454543352127,
      0.011807803064584732,
      0.02951343171298504,
      -0.10238701105117798,
      0.014970667660236359,
      0.02127927727997303,
      0.05119817703962326,
      -0.04939848557114601,
      -0.022935662418603897,
      -0.003922915551811457,
      -0.017617637291550636,
      -0.042204663157463074,
      0.030217038467526436,
      0.0671825036406517,
      0.0018604466458782554,
      -0.031542301177978516,
      0.0405629463493824,
      -0.07443569600582123,
      -0.028202326968312263,
      0.008467945270240307,
      -0.0555061511695385,
      0.036937400698661804,
      -0.013930733315646648,
      -0.06914258003234863,
      0.020193975418806076,
      0.020098797976970673,
      -0.03729419782757759,
      0.011929426342248917,
      0.008571434766054153,
      0.020852265879511833,
      -0.07926315814256668,
      0.04878328740596771,
      -0.013266002759337425,
      -0.058120086789131165,
      0.014441538602113724,
      -0.022077292203903198,
      0.016730763018131256,
      -0.003923612181097269,
      0.0552804172039032,
      0.018204031512141228,
      -0.02351556159555912,
      0.013537081889808178,
      0.06695196777582169,
      0.052627161145210266,
      -0.017620718106627464,
      -0.012247464619576931,
      0.0030331797897815704,
      -0.10146420449018478,
      0.019245658069849014,
      -0.026894258335232735,
      -0.04483906924724579,
      0.029001716524362564,
      0.052711121737957,
      -0.06373024731874466,
      -0.07329826802015305,
      0.04713711142539978,
      0.05506191402673721,
      0.03272506222128868,
      -0.014103768393397331,
      0.06472176313400269,
      0.05599384009838104,
      -0.05167193338274956,
      -0.013569997623562813,
      -0.04078042879700661,
      0.01669432781636715,
      -0.05251316726207733,
      0.02667134441435337,
      0.04417843371629715,
      -0.027337145060300827,
      -0.004799201153218746,
      0.03317524492740631,
      -0.0011368378764018416,
      0.03884698078036308,
      -0.010530535131692886,
      0.023604365065693855,
      -0.04655010625720024,
      0.05440019071102142,
      0.03945281356573105,
      0.03874344006180763,
      -0.1037214994430542,
      -0.054031550884246826,
      -0.01093689538538456,
      -0.050326231867074966,
      0.044603291898965836,
      -0.027696726843714714,
      0.00684630312025547,
      0.003586473874747753,
      0.017723726108670235,
      0.0010604365961626172,
      -0.016667472198605537,
      0.003285862971097231,
      0.03750741109251976,
      0.048630181699991226,
      -0.060758888721466064,
      -0.05295040085911751,
      0.07269217818975449,
      0.020456692203879356,
      -0.010669839568436146,
      -0.024891579523682594,
      0.03154083713889122,
      0.04171140864491463,
      -0.011350085027515888,
      -0.05033228173851967,
      0.0788351520895958,
      -0.0036988842766731977,
      0.049009770154953,
      0.0712505578994751,
      -0.019187888130545616,
      -0.0017641063313931227,
      0.0212920680642128,
      -0.01920144073665142,
      -0.04007145017385483,
      0.03170198202133179,
      0.04678840935230255,
      0.0033018402755260468,
      -0.014776451513171196,
      -0.02567584067583084,
      -0.06017801910638809,
      -0.05909065902233124,
      -0.01003934070467949,
      -0.03714940324425697,
      -0.007225202862173319,
      0.0438552163541317,
      -0.06053620204329491,
      0.026102935895323753,
      0.005622935947030783,
      0.07183092832565308,
      -0.01690998114645481,
      -0.017383169382810593,
      -0.04089987277984619,
      -0.001872714958153665,
      -0.03083876706659794,
      -0.036316875368356705,
      0.04141621291637421,
      -0.006634869612753391,
      0.013191786594688892,
      0.024661138653755188,
      0.03441772609949112,
      0.04192686825990677,
      0.025643037632107735,
      0.028791138902306557,
      0.012363644316792488,
      0.020778287202119827,
      -0.020047906786203384,
      0.050637394189834595,
      -0.03550237789750099,
      0.12143674492835999,
      -0.03943534195423126,
      0.026289433240890503,
      0.01156211830675602,
      -0.05733439326286316,
      0.03222279250621796,
      0.10042044520378113,
      -0.03855862095952034,
      -0.03522651270031929,
      -0.05282888561487198,
      -0.004323084373027086,
      0.016137244179844856,
      0.0579041987657547,
      0.06571825593709946,
      -0.06585928797721863,
      -0.04975574091076851,
      -0.04094332829117775,
      0.015313110314309597,
      -0.06810648739337921,
      0.010458067990839481,
      -0.006218382623046637,
      0.002844251459464431,
      -0.019727181643247604,
      0.04112352803349495,
      0.015405622310936451,
      -0.01873553730547428,
      -0.05231834575533867,
      0.024413125589489937,
      0.07055835425853729,
      -0.016233788803219795,
      -0.002009301446378231,
      0.009515506215393543,
      0.020624421536922455,
      -0.011608641594648361,
      0.024984484538435936,
      0.01069099735468626,
      0.005392185878008604,
      -0.10071060806512833,
      0.04142124205827713,
      0.00895437877625227,
      0.05277474224567413,
      0.03183954954147339,
      -0.008055188693106174,
      -0.016025055199861526,
      -0.0015741758979856968,
      0.04550614580512047,
      -0.06630966067314148,
      -0.006788753904402256,
      0.03712894767522812,
      -0.02239767648279667,
      0.07674916088581085,
      0.030212940648198128,
      -0.09067832678556442,
      0.07360758632421494,
      0.041314609348773956,
      -0.0015297106001526117,
      0.015126734972000122,
      0.03840741142630577,
      -0.004992370959371328,
      -0.012389624491333961,
      -0.02315937727689743,
      0.018711593002080917,
      -0.08063237369060516,
      -0.03202994167804718,
      0.021633533760905266,
      0.02528550662100315,
      0.009440071880817413,
      -0.10483337193727493,
      -0.007184900343418121,
      0.05823773890733719,
      -0.07381152361631393,
      -0.0025164952967315912,
      -0.014734232798218727,
      0.013440030626952648,
      -0.02384023740887642,
      -0.023977728560566902,
      0.015041030943393707,
      0.06901885569095612,
      0.06707392632961273,
      0.09847833961248398,
      -0.06892712414264679,
      0.04317160323262215,
      0.04257862642407417,
      0.03713512793183327,
      -0.01264889631420374,
      0.06704486161470413,
      -0.005639074835926294,
      -0.01954033598303795,
      0.016606125980615616,
      0.023344462737441063,
      0.01800718903541565,
      -0.09918198734521866,
      -0.04620533809065819,
      -0.10088842362165451,
      -0.021704768761992455,
      0.022423027083277702,
      -0.0821455717086792,
      -0.009455613791942596,
      0.04929475113749504,
      -0.0028691920451819897,
      -0.05420805513858795,
      0.025338130071759224,
      -0.002231342950835824,
      -0.02521558105945587,
      -0.002364129526540637,
      -0.004692449700087309,
      0.05680446699261665,
      -0.05414826050400734,
      0.0065739038400352,
      0.01085577066987753,
      -0.023175634443759918,
      0.06287656724452972,
      0.02171434462070465,
      -0.030439866706728935,
      -0.025334326550364494,
      0.04112209007143974,
      0.05666982755064964,
      0.04608690366148949,
      0.06302624940872192,
      -0.036610864102840424,
      0.011038112454116344,
      0.05663662403821945,
      -0.03759535029530525,
      0.05356784537434578,
      0.05825704708695412,
      0.04171436280012131,
      0.06151486933231354,
      -0.021404320374131203,
      0.12077222019433975,
      -0.021640228107571602,
      -0.012318557128310204,
      0.031071286648511887,
      -0.05337226763367653,
      -0.032163385301828384,
      -0.034151315689086914,
      0.007643601391464472,
      -0.035453129559755325,
      -0.03780267387628555,
      0.024170048534870148,
      0.04114721342921257,
      0.001437922241166234,
      -0.07573647797107697,
      0.022925695404410362,
      -0.006245880853384733,
      -0.0405927374958992,
      -0.06505683064460754,
      0.00495422026142478,
      0.007773560006171465,
      0.0612129382789135,
      -0.01720726490020752,
      -0.07252354919910431,
      0.027880430221557617,
      -0.04105908423662186,
      0.02625925838947296,
      0.017987482249736786,
      -0.07137744873762131,
      0.04280571639537811,
      0.0037196651101112366,
      0.04362289234995842,
      0.008651084266602993,
      -0.02540644071996212,
      -0.03630273416638374,
      0.009565448388457298,
      -0.0561269149184227,
      -0.0409742146730423,
      -0.0654764324426651,
      -0.0982409343123436,
      0.014328429475426674,
      0.0077985730022192,
      -0.02918286994099617,
      -0.06371551752090454,
      0.07387492060661316,
      0.03074982948601246,
      -0.02091035433113575,
      0.03980781510472298,
      -0.053159844130277634,
      0.022394834086298943,
      0.012167692184448242,
      0.03939705714583397,
      0.04436540976166725,
      0.009790039621293545,
      -0.019632132723927498,
      -0.0011872636387124658,
      -0.023179717361927032,
      -0.0406375490128994,
      -0.038309745490550995,
      -0.001613857108168304,
      -0.00730462372303009,
      0.019061310216784477,
      0.0062699150294065475,
      -0.0011121622519567609,
      -0.03995174542069435,
      -0.06315986812114716,
      0.020002001896500587,
      -0.01164416503161192,
      0.06716291606426239,
      -0.0042654844000935555,
      0.011575261130928993,
      -0.05937306210398674,
      -0.059718504548072815,
      0.0889982208609581,
      0.03093370608985424,
      0.00728858495131135,
      -0.02333991974592209,
      0.06140469014644623,
      -0.045062389224767685,
      0.04631424322724342,
      0.053270965814590454,
      -0.013071966357529163,
      -0.01755802147090435,
      0.03877711296081543,
      0.06045980751514435,
      0.0928187146782875,
      0.056999798864126205,
      0.05649808794260025,
      0.04408949986100197,
      0.011379393748939037,
      0.0078086405992507935,
      -0.03519010916352272,
      -0.003457383718341589,
      0.042103786021471024,
      -0.05348305031657219,
      0.06422965228557587,
      0.0773933157324791,
      0.07072428613901138,
      0.0799194723367691,
      0.03267525136470795,
      -0.047245826572179794,
      0.01019536517560482,
      -0.03677871450781822,
      0.0024879304692149162,
      0.08155935257673264,
      0.0615525096654892,
      0.026705186814069748,
      0.0334663949906826,
      -0.0095846988260746,
      0.0026599590200930834,
      -0.002211423823609948,
      -0.006011527497321367,
      -0.01554193627089262,
      -0.07797522842884064,
      0.005961192771792412,
      0.05892568081617355,
      0.048127125948667526,
      0.07764267921447754,
      -0.006047654431313276,
      -0.041760172694921494,
      0.01987074315547943,
      0.013332688249647617,
      0.06591933220624924,
      0.015860846266150475,
      0.005337969399988651,
      -0.012346523813903332,
      0.0863315612077713,
      -0.031855594366788864,
      0.07957056164741516,
      0.002941077807918191,
      0.040355511009693146,
      -0.011899280361831188,
      0.051921598613262177,
      0.07163317501544952,
      0.0821707621216774,
      -0.05312969535589218,
      0.007855010218918324,
      -0.00023268896620720625,
      0.05587316304445267,
      0.028431328013539314,
      0.0369245670735836,
      -0.04255949705839157,
      -0.027091246098279953,
      -0.06947586685419083,
      -0.0032242964953184128,
      -0.004250905942171812,
      0.017877062782645226,
      -0.07535901665687561,
      -0.035096168518066406,
      -0.05092340707778931,
      -0.021143091842532158,
      0.011120868846774101,
      0.033261943608522415,
      0.031589753925800323,
      -0.029305391013622284,
      0.05981513112783432,
      0.0704265832901001,
      -0.021139202639460564,
      -0.06988190114498138,
      0.04699983820319176,
      0.00230170926079154,
      -0.015206956304609776,
      0.01765592023730278,
      0.11046228557825089,
      0.057141490280628204,
      -0.058523841202259064,
      0.039901092648506165,
      -0.017901839688420296,
      -0.028852835297584534,
      0.0004939301288686693,
      -0.017017588019371033,
      -0.016993096098303795,
      -0.021822666749358177,
      0.0359034538269043,
      -0.06343942135572433,
      0.03742731362581253,
      0.05661526694893837,
      -0.018717603757977486,
      -0.027974816039204597,
      -0.030073778703808784,
      -0.040827419608831406,
      -0.030968284234404564,
      0.016382085159420967,
      0.02455311082303524,
      0.017488420009613037,
      0.014221342280507088,
      0.0486052967607975,
      0.051792632788419724,
      -0.05904847010970116,
      0.026048047468066216,
      -0.08671382069587708,
      0.022861288860440254,
      0.02694799192249775,
      0.09505949914455414,
      -0.03674646466970444,
      0.0015043781604617834,
      0.017009899020195007,
      -0.08539596199989319,
      -0.013461539521813393,
      -0.03529774397611618,
      0.00752766290679574,
      0.013861541636288166,
      0.0362863764166832,
      0.06651240587234497,
      0.02588065154850483
    ],
    [
      0.024341721087694168,
      0.002719127805903554,
      0.062449533492326736,
      0.02473658323287964,
      0.037828654050827026,
      -0.07642319798469543,
      -0.015136297792196274,
      0.06179731711745262,
      -0.05731985345482826,
      0.0018820008262991905,
      -0.06731416285037994,
      0.03239910304546356,
      -0.07976160198450089,
      -0.013225330971181393,
      0.04957164451479912,
      -0.026726678013801575,
      0.05910055711865425,
      0.0630924180150032,
      0.04760556295514107,
      0.03909667581319809,
      0.14055678248405457,
      -0.014129475690424442,
      0.0702841654419899,
      0.023153046146035194,
      -0.07449743151664734,
      0.09136399626731873,
      0.0003983670612797141,
      -0.15583206713199615,
      0.00815307255834341,
      -0.1135668233036995,
      0.07793562859296799,
      -0.0012132258852943778,
      0.06971827894449234,
      0.029862208291888237,
      -0.046325843781232834,
      0.019318921491503716,
      -0.03224154934287071,
      0.019331516698002815,
      0.047518979758024216,
      -0.005546396132558584,
      0.04633251205086708,
      0.0440472774207592,
      -0.010761766694486141,
      -0.04845206066966057,
      0.006975112017244101,
      0.06384097784757614,
      0.030979061499238014,
      0.010243120603263378,
      0.05467935651540756,
      -0.02450435608625412,
      -0.012524164281785488,
      0.03079989179968834,
      -0.03354092687368393,
      0.007443159818649292,
      0.03208816796541214,
      0.04650108516216278,
      0.01007635984569788,
      0.019786780700087547,
      0.10172290354967117,
      -0.025860359892249107,
      -0.045770980417728424,
      0.04562990739941597,
      -0.01636556349694729,
      -0.06390684098005295,
      0.057107262313365936,
      0.0015317356446757913,
      -0.026703674346208572,
      0.07578222453594208,
      0.007610707078129053,
      -0.018311692401766777,
      -0.09048650413751602,
      0.0042204419150948524,
      0.014932583086192608,
      0.04556744173169136,
      -0.02744746021926403,
      0.04595401510596275,
      0.008505417965352535,
      0.07578503340482712,
      0.009454612620174885,
      0.005266890395432711,
      -0.057199567556381226,
      -0.02615615725517273,
      0.036382030695676804,
      -0.042541563510894775,
      0.05700699985027313,
      0.06045110151171684,
      -0.0499490424990654,
      -0.02732822671532631,
      -0.05589291453361511,
      0.008333400823175907,
      -0.0596308596432209,
      -0.037370018661022186,
      0.11580143868923187,
      -0.003319814568385482,
      -0.016676723957061768,
      -0.006202970165759325,
      -0.03436486795544624,
      -0.027574220672249794,
      -0.025015458464622498,
      0.04860944300889969,
      -0.03788482025265694,
      -0.02864581160247326,
      0.11695808917284012,
      0.032877128571271896,
      -0.01636151783168316,
      -0.06557319313287735,
      -0.06769582629203796,
      -0.008600159548223019,
      0.09525707364082336,
      0.016460897400975227,
      0.1183503270149231,
      0.013801510445773602,
      -0.01906575821340084,
      0.06623556464910507,
      0.02802528254687786,
      0.04630426689982414,
      -0.09323940426111221,
      -0.05439973622560501,
      -0.01668483391404152,
      0.005629868246614933,
      0.03866635635495186,
      0.008213639259338379,
      0.048340748995542526,
      -0.06699073314666748,
      0.008805779740214348,
      0.02855636179447174,
      -0.05555395781993866,
      -0.015392404980957508,
      0.020263973623514175,
      0.08375827968120575,
      0.04934462532401085,
      0.0009316012728959322,
      -0.02200384996831417,
      -0.006045604590326548,
      0.027535729110240936,
      -0.03733998164534569,
      -0.08524488657712936,
      -0.06834390759468079,
      0.019723745062947273,
      -0.0007523488020524383,
      0.03226766735315323,
      -0.12084146589040756,
      -0.0030319797806441784,
      0.023544609546661377,
      0.05978691205382347,
      0.013789728283882141,
      0.043497681617736816,
      0.052475977689027786,
      0.012945477850735188,
      0.025410959497094154,
      -0.03990602120757103,
      -0.020498692989349365,
      -0.007896555587649345,
      0.10071869939565659,
      0.009711455553770065,
      -0.007423336151987314,
      -0.08271228522062302,
      0.015024476684629917,
      0.029176747426390648,
      0.042839378118515015,
      0.024990903213620186,
      -0.0447012335062027,
      -0.05409229174256325,
      -0.04769499972462654,
      -0.04318097606301308,
      -0.004465344827622175,
      -0.03542537987232208,
      0.05336169898509979,
      0.02863204851746559,
      0.05164298787713051,
      0.02732653170824051,
      -0.08250202983617783,
      0.009634210728108883,
      0.01379322912544012,
      -0.08620352298021317,
      0.03412488102912903,
      -0.07146929949522018,
      0.01214071735739708,
      0.03946869075298309,
      -0.018080731853842735,
      -0.07338147610425949,
      0.0332220084965229,
      0.02845127321779728,
      0.04073479399085045,
      -0.02207658439874649,
      -0.005965915974229574,
      0.027843227609992027,
      -0.021604252979159355,
      0.009886655956506729,
      0.02995886839926243,
      -0.00905936025083065,
      -0.01919889822602272,
      0.023949159309267998,
      0.01925155334174633,
      0.0018246261170133948,
      -0.014320287853479385,
      0.011730858124792576,
      -0.03676491975784302,
      -0.05507754907011986,
      0.06439699977636337,
      -0.019472556188702583,
      0.05761512741446495,
      -0.12610316276550293,
      -0.07582041621208191,
      -0.03958255052566528,
      -0.06654127687215805,
      -0.09235236793756485,
      -0.01008070632815361,
      -0.029015349224209785,
      0.1015593633055687,
      0.036961689591407776,
      0.055127404630184174,
      -0.0395132452249527,
      -0.017717262730002403,
      0.06868521124124527,
      -0.0022027178201824427,
      0.06334342807531357,
      -0.09639792889356613,
      0.020546309649944305,
      0.07451748102903366,
      -0.07761230319738388,
      -0.013801392167806625,
      -0.012007862329483032,
      -0.07705623656511307,
      0.02714681811630726,
      -0.034296248108148575,
      0.04353175684809685,
      -0.039766453206539154,
      -0.009950844570994377,
      -0.0394437350332737,
      0.0229724682867527,
      -0.10333553701639175,
      -0.03653247281908989,
      0.029325565323233604,
      -0.012910044752061367,
      -0.09772959351539612,
      0.07290549576282501,
      -0.021595850586891174,
      -0.02931033819913864,
      0.03408816456794739,
      -0.08341034501791,
      -0.013533352874219418,
      0.027666868641972542,
      -0.10429616272449493,
      0.028378961607813835,
      0.08140981942415237,
      0.028927963227033615,
      0.035272788256406784,
      -0.026720836758613586,
      0.08787520974874496,
      0.03370920196175575,
      -0.029513735324144363,
      0.010532316751778126,
      -0.004700580611824989,
      -0.007129673380404711,
      0.009883900173008442,
      -0.01864374242722988,
      -0.016086746007204056,
      -0.0037185014225542545,
      0.019955851137638092,
      -0.05828184261918068,
      -0.0014147646725177765,
      0.04871830344200134,
      -0.03678222745656967,
      0.025773614645004272,
      -0.049566689878702164,
      -0.039751555770635605,
      0.037054821848869324,
      -0.0034478968009352684,
      -0.04789010062813759,
      -0.0027140250895172358,
      0.038101427257061005,
      -0.04472025856375694,
      0.062323134392499924,
      -0.023413825780153275,
      0.006871729623526335,
      -0.04687795042991638,
      0.029093464836478233,
      0.0003524886560626328,
      0.07181321084499359,
      -0.03423503786325455,
      0.04849490895867348,
      -0.020615745335817337,
      -0.013604636304080486,
      -0.034371618181467056,
      0.018628938123583794,
      0.01772499643266201,
      -0.04669883847236633,
      -0.0006002543377690017,
      -0.04138358309864998,
      -0.016875388100743294,
      0.06392224878072739,
      0.030201053246855736,
      0.0026211217045783997,
      -0.010219085961580276,
      0.01371434610337019,
      -0.06339049339294434,
      0.03636005148291588,
      0.052856650203466415,
      0.03482382372021675,
      0.019610179588198662,
      0.0791730284690857,
      0.08833625167608261,
      -0.021067924797534943,
      0.007576776202768087,
      -0.032878272235393524,
      0.02786851115524769,
      -0.060614462941884995,
      -0.01622851751744747,
      -0.07966967672109604,
      0.02878914400935173,
      0.015216439962387085,
      0.03514381870627403,
      -0.06222694367170334,
      -0.04898644611239433,
      0.008751079440116882,
      0.016513517126441002,
      0.027035336941480637,
      0.0342559739947319,
      0.02034831792116165,
      -0.02632441744208336,
      0.01447050366550684,
      -0.00393900740891695,
      0.06542560458183289,
      0.0358259491622448,
      0.06470492482185364,
      -0.09477146714925766,
      0.005868813488632441,
      0.025032294914126396,
      -0.034427400678396225,
      0.018962010741233826,
      0.0324297770857811,
      0.013849716633558273,
      0.05152672529220581,
      0.06405587494373322,
      0.027414686977863312,
      -0.05891543626785278,
      0.04346974194049835,
      0.013558370061218739,
      -0.085809625685215,
      0.03285710886120796,
      0.05330316349864006,
      -0.05537961795926094,
      0.019198112189769745,
      0.02660316601395607,
      0.02342437580227852,
      0.005383363459259272,
      0.05450914055109024,
      -0.04567008838057518,
      -0.10224451869726181,
      -0.013325131498277187,
      -0.003259552177041769,
      -0.036777183413505554,
      0.007296858821064234,
      -0.05009312927722931,
      -0.005038836970925331,
      -0.023639798164367676,
      0.025694096460938454,
      -0.009667500853538513,
      0.009591979905962944,
      -0.048315513879060745,
      0.012740924954414368,
      -0.0306205153465271,
      -0.09530633687973022,
      0.015896759927272797,
      -0.028016263619065285,
      -0.054701067507267,
      -0.03674928843975067,
      0.027660926803946495,
      0.042127400636672974,
      0.019911933690309525,
      0.05872742086648941,
      -0.0028964211232960224,
      0.0403522290289402,
      0.0838082805275917,
      0.05215591937303543,
      0.04501355066895485,
      -0.03007800132036209,
      0.031138140708208084,
      0.10264334827661514,
      -0.008099112659692764,
      0.054493460804224014,
      0.04831354692578316,
      -0.0050846729427576065,
      -0.08413124084472656,
      0.019589506089687347,
      -0.012140030972659588,
      0.026730874553322792,
      -0.03907741978764534,
      -0.009596279822289944,
      0.04773719608783722,
      -0.04501084238290787,
      0.08079221099615097,
      0.04694982245564461,
      -0.09669993072748184,
      -0.09710953384637833,
      0.06879980862140656,
      0.05975528806447983,
      -0.05875258147716522,
      0.06188168749213219,
      -0.02057817205786705,
      -0.034463368356227875,
      0.02681048959493637,
      0.0030519927386194468,
      0.06218152493238449,
      0.014056487940251827,
      -0.06972068548202515,
      -0.005066769663244486,
      0.011227835901081562,
      -0.008577483706176281,
      0.0527736134827137,
      0.07947324216365814,
      0.04481646418571472,
      -0.02088887430727482,
      0.03712978586554527,
      -0.03051258809864521,
      -0.023899396881461143,
      -0.05665444955229759,
      -0.06728726625442505,
      -0.031123772263526917,
      0.017609167844057083,
      -0.009863296523690224,
      -0.01596505381166935,
      0.010734711773693562,
      -0.04947270080447197,
      -0.0013848117087036371,
      -0.03294454514980316,
      -0.038935910910367966,
      0.04928740859031677,
      0.06039877608418465,
      0.015494694001972675,
      0.006429418455809355,
      -0.024400314316153526,
      -0.025448942556977272,
      -0.05847993120551109,
      -0.013623209670186043,
      0.031972382217645645,
      -0.02500603348016739,
      -0.0517757385969162,
      0.09514013677835464,
      -0.05628528073430061,
      0.007357677444815636,
      0.02635289914906025,
      0.027594642713665962,
      -0.011828837916254997,
      -0.04876824468374252,
      -0.07722597569227219,
      0.02989799715578556,
      -0.05180738866329193,
      0.06068190559744835,
      -0.050326183438301086,
      0.009567579254508018,
      -0.021523049101233482,
      -0.031123172491788864,
      0.0005535719683393836,
      -0.04936185106635094,
      -0.04315907135605812,
      -0.04028838872909546,
      -0.005916092079132795,
      -0.01442553848028183,
      0.07758587598800659,
      0.014917693100869656,
      -0.12276303023099899,
      -0.04814476892352104,
      0.019995616748929024,
      0.0011692754924297333,
      -0.0582447424530983,
      0.05337371304631233,
      -0.15400861203670502,
      -0.024415332823991776,
      -0.016075344756245613,
      -0.0072236936539411545,
      -0.0205802284181118,
      -0.012922557070851326,
      0.05959368869662285,
      -0.012365440838038921,
      0.030691657215356827,
      0.03985677659511566,
      0.004865249618887901,
      -0.009394919499754906,
      0.04707182198762894,
      0.02116995118558407,
      0.12626054883003235,
      -0.029470469802618027,
      -0.020683694630861282,
      0.03163110837340355,
      -0.08476481586694717,
      -0.05894738435745239,
      0.03483384847640991,
      0.013659684918820858,
      -0.06130795180797577,
      0.04072093218564987,
      -0.013771463185548782,
      0.015776952728629112,
      -0.023814504966139793,
      0.058316685259342194,
      -0.015543417073786259,
      -0.019443683326244354,
      -0.020424477756023407,
      0.06639645248651505,
      0.04085247591137886,
      0.003587067127227783,
      0.0875120460987091
    ],
    [
      -0.028894474729895592,
      -0.04471517726778984,
      0.014978930354118347,
      0.020935267210006714,
      -0.037249185144901276,
      0.014202642254531384,
      -0.01640753634274006,
      0.05639132484793663,
      -0.03487086668610573,
      0.0813203901052475,
      -0.023205259814858437,
      -0.017212677747011185,
      0.040703434497117996,
      0.011531488038599491,
      0.07238050550222397,
      0.0038627858739346266,
      0.026127615943551064,
      -0.03837727755308151,
      -0.05954985320568085,
      0.03183148801326752,
      0.0571223720908165,
      -0.00632031774148345,
      0.08074849098920822,
      -0.009123384952545166,
      -0.0179060660302639,
      -0.01988004706799984,
      -0.08007501065731049,
      0.06950639188289642,
      0.0462687686085701,
      -0.0523490235209465,
      -0.12442465126514435,
      0.044824231415987015,
      0.013537759892642498,
      0.09022162854671478,
      -0.0367312952876091,
      0.012095022946596146,
      -0.004598050378262997,
      0.038112010806798935,
      -0.09466803818941116,
      -0.009338985197246075,
      -0.010890009813010693,
      -0.03850885480642319,
      -0.056379593908786774,
      -0.035636741667985916,
      -0.01689319871366024,
      0.037278130650520325,
      -0.08273283392190933,
      -0.06534779071807861,
      -0.007891381159424782,
      -0.04367866739630699,
      0.00605799863114953,
      0.0055424063466489315,
      -0.03379777818918228,
      -0.030705729499459267,
      -0.003818495897576213,
      -0.0016768130008131266,
      0.08264704793691635,
      -0.12317048013210297,
      0.08110687881708145,
      0.040447961539030075,
      0.05180339142680168,
      -0.0412224642932415,
      0.03420337662100792,
      0.020388063043355942,
      -0.016202811151742935,
      -0.08372201770544052,
      -0.04246513172984123,
      -0.044224657118320465,
      -0.0022977618500590324,
      -0.026723086833953857,
      0.008705470710992813,
      -0.012498637661337852,
      0.009271835908293724,
      -0.05509715899825096,
      -0.032470524311065674,
      -0.022895004600286484,
      0.11123516410589218,
      0.009944665245711803,
      0.001319941016845405,
      -0.02985680289566517,
      0.00421425374224782,
      -0.029115332290530205,
      -0.005080465227365494,
      -0.02582901157438755,
      -0.004211983643472195,
      0.04888244718313217,
      -0.05620460957288742,
      0.006320222280919552,
      -0.017713773995637894,
      0.059107787907123566,
      0.022894946858286858,
      -0.008239638060331345,
      0.06517905741930008,
      0.09517139941453934,
      0.09019236266613007,
      0.04030321538448334,
      -0.001254191854968667,
      0.06466520577669144,
      -0.030288472771644592,
      0.06833984702825546,
      -0.001478967722505331,
      0.031112823635339737,
      0.07692379504442215,
      0.05389689654111862,
      -0.019588878378272057,
      0.011804917827248573,
      0.05217636004090309,
      0.01558731496334076,
      -0.027089521288871765,
      -0.01710497960448265,
      0.01671205833554268,
      0.016943691298365593,
      0.025754069909453392,
      -0.06463365256786346,
      -0.05130697041749954,
      0.048411253839731216,
      -0.009295004419982433,
      0.07467833161354065,
      -0.01935567706823349,
      0.03178989887237549,
      -0.04399007558822632,
      0.07077867537736893,
      -0.03359530121088028,
      -0.05749677121639252,
      -0.03178330510854721,
      0.0029056905768811703,
      -0.010927773080766201,
      0.033804163336753845,
      -0.037541765719652176,
      -0.027426453307271004,
      0.037920840084552765,
      0.059360988438129425,
      0.042967066168785095,
      0.02412756159901619,
      -0.08152486383914948,
      0.008603304624557495,
      -0.0022265075240284204,
      -0.05932033807039261,
      -0.024348149076104164,
      0.025610364973545074,
      0.014349364675581455,
      -0.044901348650455475,
      0.010524147190153599,
      0.011830918490886688,
      0.03611636161804199,
      -0.01348815206438303,
      0.06311161816120148,
      0.0169837549328804,
      -0.047675978392362595,
      -0.021615033969283104,
      0.02150426246225834,
      -0.016388477757573128,
      -0.00029290339443832636,
      0.01831056736409664,
      0.001549786189571023,
      0.008819641545414925,
      -0.022903643548488617,
      -0.02911752089858055,
      0.020513217896223068,
      -0.019309120252728462,
      0.03676941245794296,
      -0.07303601503372192,
      -0.048699140548706055,
      -0.0664280578494072,
      0.010109164752066135,
      -0.033825792372226715,
      -0.004635616671293974,
      0.007719831075519323,
      0.02469572238624096,
      0.011302459053695202,
      0.025933949276804924,
      0.06306996941566467,
      0.04541274532675743,
      0.04280218109488487,
      0.022493533790111542,
      -0.02051153965294361,
      -0.036769989877939224,
      -0.08809366822242737,
      0.0238686203956604,
      -0.04525149613618851,
      -0.0664689689874649,
      0.01703295111656189,
      -0.030656330287456512,
      0.02210386097431183,
      0.0212286077439785,
      -0.05843854695558548,
      0.027070751413702965,
      0.04066842794418335,
      0.015078261494636536,
      0.014177915640175343,
      -0.026546228677034378,
      0.027161264792084694,
      0.02796083129942417,
      0.018782388418912888,
      -0.022515397518873215,
      -0.03633151575922966,
      -0.022729406133294106,
      -0.0006793051143176854,
      0.010445213876664639,
      0.04701920971274376,
      -0.020628316327929497,
      0.020602069795131683,
      -0.07079746574163437,
      -0.03029451332986355,
      0.0007199725368991494,
      -0.02982938289642334,
      0.054370272904634476,
      -0.03874070569872856,
      -0.05310478061437607,
      0.02569214068353176,
      -0.05249730125069618,
      -0.010381524451076984,
      0.020786141976714134,
      -0.039672620594501495,
      0.0049095298163592815,
      0.03284316509962082,
      -0.019833439961075783,
      -0.01510126143693924,
      0.03826054558157921,
      -0.056821972131729126,
      0.03887311741709709,
      0.02706223540008068,
      -0.015090138651430607,
      -0.006053585559129715,
      -0.009969944134354591,
      -0.026983508840203285,
      -0.06371048837900162,
      0.020916739478707314,
      -0.06463298946619034,
      -0.02818130888044834,
      0.09227611869573593,
      0.08078484237194061,
      -0.07327412068843842,
      -0.04670458659529686,
      -0.0799717977643013,
      0.03152291104197502,
      0.027131997048854828,
      -0.017256900668144226,
      0.0017985886661335826,
      0.09632749110460281,
      -0.05552573874592781,
      -0.03604200482368469,
      0.08592011779546738,
      -0.04517950117588043,
      -0.03084963746368885,
      0.08631572127342224,
      0.07390440255403519,
      0.030696703121066093,
      0.08786312490701675,
      0.039216797798871994,
      0.08793283253908157,
      -0.08627794682979584,
      0.010871605016291142,
      0.02068360149860382,
      -0.012603608891367912,
      -0.045711468905210495,
      -0.0782964900135994,
      -0.02872469834983349,
      -0.06279788166284561,
      0.06657442450523376,
      0.06119911000132561,
      -0.07073934376239777,
      0.03420017659664154,
      0.030248578637838364,
      -0.008854200132191181,
      0.023039909079670906,
      -0.05069998279213905,
      -0.0668146088719368,
      -0.04098576307296753,
      -0.041252635419368744,
      -0.009759382344782352,
      0.034120071679353714,
      -0.02024313621222973,
      0.0027672185096889734,
      0.006426421459764242,
      -0.013026789762079716,
      0.05316132307052612,
      0.02972640097141266,
      0.007017392199486494,
      0.051435548812150955,
      0.016796670854091644,
      -0.10874185711145401,
      0.006990907713770866,
      -0.08533179759979248,
      0.002573866629973054,
      -0.05856655538082123,
      0.03080962784588337,
      -0.012484753504395485,
      -0.043136339634656906,
      0.0237398874014616,
      -0.006904966663569212,
      0.006191026419401169,
      -0.07197284698486328,
      0.03307221829891205,
      0.0062710982747375965,
      -0.07392371445894241,
      0.0030726988334208727,
      -0.014142435044050217,
      0.11460380256175995,
      0.01910298690199852,
      0.03224467113614082,
      -0.03546081855893135,
      -0.035912491381168365,
      0.06558611243963242,
      -0.04740212857723236,
      0.041931115090847015,
      -0.02577328123152256,
      0.03990941494703293,
      -0.03222658485174179,
      -0.029761571437120438,
      0.02855476178228855,
      0.010846519842743874,
      -0.05095943063497543,
      0.028975799679756165,
      0.05393163487315178,
      0.03131403401494026,
      0.059975992888212204,
      -0.0034957409370690584,
      0.11473618447780609,
      -0.04999394714832306,
      0.0582500584423542,
      0.04821803793311119,
      -0.03037535771727562,
      -0.06686749309301376,
      0.041861165314912796,
      0.032456859946250916,
      0.05022770166397095,
      0.0041984859853982925,
      0.0006708037108182907,
      -0.038751184940338135,
      0.04089031741023064,
      -0.06303704530000687,
      -0.008151023648679256,
      0.014409136958420277,
      0.001684744143858552,
      -0.029371455311775208,
      0.029746543616056442,
      0.04856305569410324,
      -0.008324705064296722,
      0.021417398005723953,
      -0.054034020751714706,
      -0.007786553353071213,
      -0.08792006969451904,
      0.08085126429796219,
      -0.024499092251062393,
      0.021455250680446625,
      -0.02069113589823246,
      -0.0031123757362365723,
      -0.0009920172160491347,
      0.04357452318072319,
      -0.040639325976371765,
      -0.05096698924899101,
      -0.0055158380419015884,
      0.029611313715577126,
      0.0031418967992067337,
      0.019625145941972733,
      0.026029635220766068,
      -0.003111912403255701,
      -0.008679940365254879,
      0.030832484364509583,
      -0.020365828648209572,
      0.03194897249341011,
      -0.0428108274936676,
      -0.02485109120607376,
      0.053867120295763016,
      -0.02539495937526226,
      -0.0234195776283741,
      -0.006912802811712027,
      -0.11021236330270767,
      -0.033351268619298935,
      0.05156230553984642,
      -0.016640234738588333,
      0.0458240881562233,
      -0.0174093134701252,
      -0.045769695192575455,
      0.013087986968457699,
      -0.016585461795330048,
      -0.05800614878535271,
      0.0673692524433136,
      -0.008489171043038368,
      0.05459455028176308,
      -0.040191806852817535,
      -0.10838009417057037,
      0.0855904296040535,
      -0.07456859946250916,
      0.03682370483875275,
      0.027592021971940994,
      0.009906032122671604,
      0.021809762343764305,
      -0.02320757880806923,
      0.029879339039325714,
      -0.02527257613837719,
      0.06114024668931961,
      0.10695227980613708,
      0.03072967380285263,
      -0.06055435910820961,
      -0.01589568331837654,
      -0.010334437713027,
      0.01413439679890871,
      0.07593031227588654,
      0.012303597293794155,
      0.06251268833875656,
      -0.04046105220913887,
      -0.005577601492404938,
      0.09277037531137466,
      0.05600116774439812,
      0.01159643940627575,
      -0.02065098285675049,
      0.030461737886071205,
      -0.039322786033153534,
      0.04250946268439293,
      0.03522595018148422,
      0.03279789164662361,
      -7.818394078640267e-05,
      -0.0416070856153965,
      -0.021880902349948883,
      0.00878552719950676,
      0.11784177273511887,
      0.04257495328783989,
      -0.025308622047305107,
      0.015739932656288147,
      -0.01846671663224697,
      -0.03765339404344559,
      -0.025572190061211586,
      -0.02260218746960163,
      0.028769908472895622,
      0.046770382672548294,
      0.02043061889708042,
      0.0010753468377515674,
      -0.05138294771313667,
      0.026956956833600998,
      -0.0229396540671587,
      0.022616242989897728,
      0.0162961445748806,
      -0.035446736961603165,
      0.004699698183685541,
      -0.02621339075267315,
      0.0932457223534584,
      -0.07552269101142883,
      -0.0516011044383049,
      0.0062034050934016705,
      -0.06689421087503433,
      -0.034584857523441315,
      0.016078533604741096,
      0.09137503057718277,
      0.0023221170995384455,
      -0.019493287429213524,
      -0.005971461068838835,
      -0.024909669533371925,
      0.015912475064396858,
      -0.03960900008678436,
      0.009422600269317627,
      -0.01528526283800602,
      0.08022167533636093,
      -0.012436870485544205,
      0.03852865844964981,
      -0.10953040421009064,
      0.015611283481121063,
      0.009512745775282383,
      -0.013746824115514755,
      0.026497621089220047,
      0.025190725922584534,
      0.028867965564131737,
      0.03223709389567375,
      0.09376133233308792,
      -0.04156336933374405,
      0.006268238183110952,
      -3.411096258787438e-05,
      -0.04285520315170288,
      -0.03262018412351608,
      -0.025795234367251396,
      -0.04069102182984352,
      0.035458896309137344,
      -0.011645225808024406,
      0.016470564529299736,
      0.006760133896023035,
      -0.019786523655056953,
      0.04928017035126686,
      0.009284739382565022,
      0.022208934649825096,
      -0.04202117770910263,
      -0.006247104611247778,
      -0.03587713837623596,
      0.10335251688957214,
      -0.0007228354224935174,
      -0.04990260675549507,
      0.0638653114438057,
      0.013238830491900444,
      0.024945177137851715,
      -0.0010868896497413516,
      -0.03320153057575226,
      0.08034805208444595,
      0.05948502942919731,
      -0.048130959272384644,
      0.04873853176832199,
      0.019392836838960648,
      -0.0033883037976920605,
      -0.037796955555677414,
      -0.03560327738523483,
      -0.05158456787467003,
      0.045002952218055725,
      -0.03233865648508072,
      -0.045774493366479874
    ],
    [
      0.014612247236073017,
      -0.0686163529753685,
      -0.03686114773154259,
      0.05748314782977104,
      -0.03905123099684715,
      0.06768424063920975,
      -0.0967092365026474,
      -0.0367632657289505,
      -0.01491702813655138,
      0.041173044592142105,
      0.02453567087650299,
      -0.020536912605166435,
      0.04209122434258461,
      -0.02714972198009491,
      0.09802720695734024,
      0.006988706532865763,
      0.005282588768750429,
      -0.021141385659575462,
      0.023681707680225372,
      -0.07840339094400406,
      0.020877795293927193,
      0.007683010771870613,
      0.07867823541164398,
      0.024373093619942665,
      -0.01625598408281803,
      0.008022593334317207,
      0.042122453451156616,
      0.015252931043505669,
      -0.03971870616078377,
      -0.0491991862654686,
      0.004282545298337936,
      0.008933003060519695,
      -0.01110332366079092,
      -0.014941785484552383,
      0.029938701540231705,
      -0.003537307260558009,
      -0.00463392399251461,
      -0.023035261780023575,
      0.0048289974220097065,
      -0.06012309715151787,
      0.0157068632543087,
      0.027914827689528465,
      0.03709726780653,
      0.05069916695356369,
      -0.018111223354935646,
      0.05194590985774994,
      -0.05466321110725403,
      -0.011601153761148453,
      0.014092044904828072,
      0.05256558954715729,
      -0.022246073931455612,
      -0.014457985758781433,
      0.046982795000076294,
      0.028391852974891663,
      0.06019865348935127,
      0.053806692361831665,
      0.008871792815625668,
      0.06444933265447617,
      -0.010066167451441288,
      0.013391625136137009,
      -0.033790960907936096,
      0.02945515885949135,
      -0.027527352795004845,
      0.07834836840629578,
      -0.053665414452552795,
      0.02802293375134468,
      -0.034552428871393204,
      0.036839090287685394,
      -0.011770804412662983,
      0.07422806322574615,
      -0.042424771934747696,
      0.008943258784711361,
      -0.04878136143088341,
      0.007413813378661871,
      0.029136497527360916,
      -0.034739259630441666,
      0.011480562388896942,
      0.008312956430017948,
      0.07112996280193329,
      -0.006045954301953316,
      0.007634194567799568,
      -0.017254114151000977,
      -0.04873469099402428,
      -0.0026901683304458857,
      -0.05319496989250183,
      0.11841633170843124,
      0.006521299947053194,
      0.0019679393153637648,
      -0.05046526715159416,
      0.026597287505865097,
      0.059002749621868134,
      0.04763199761509895,
      -0.007168660871684551,
      -0.09930968284606934,
      0.011431941762566566,
      0.10523618012666702,
      0.0056749568320810795,
      0.0007769869407638907,
      -0.0005419672816060483,
      0.01603507250547409,
      0.11941669881343842,
      -0.032016512006521225,
      0.10099465399980545,
      0.018657084554433823,
      -0.07852144539356232,
      0.01968139410018921,
      -0.03235577046871185,
      0.042926013469696045,
      -0.015279412269592285,
      -0.00807101372629404,
      0.0034478078596293926,
      -0.02408008649945259,
      -0.055769093334674835,
      -0.0016574027249589562,
      0.030726077035069466,
      -0.03144192323088646,
      -0.09140212833881378,
      0.007291524671018124,
      -0.009653535671532154,
      0.012762950733304024,
      -0.06532581895589828,
      -0.013889572583138943,
      -0.04511978477239609,
      -0.0011679871240630746,
      -0.04698582738637924,
      -0.005762267857789993,
      -0.019835347309708595,
      -0.011931783519685268,
      -0.06466615945100784,
      -0.014605630189180374,
      0.06086776405572891,
      -0.014499939046800137,
      0.03217505291104317,
      -0.0005647963262163103,
      -0.03682243451476097,
      -0.047273412346839905,
      0.05213736742734909,
      -0.030559303238987923,
      0.008399618789553642,
      -0.015627307817339897,
      -0.054681167006492615,
      -0.0095930565148592,
      0.003507193410769105,
      0.019681395962834358,
      -0.015426898375153542,
      -0.0708373636007309,
      -0.0911087766289711,
      -0.053595781326293945,
      -0.035289280116558075,
      -0.0250401608645916,
      -0.01571647636592388,
      0.03995007649064064,
      0.029980480670928955,
      0.015320979990065098,
      -0.04048152640461922,
      0.024155814200639725,
      -0.061957910656929016,
      -0.0921480804681778,
      0.0010609101736918092,
      0.008880909532308578,
      -0.04959334060549736,
      0.01584422029554844,
      -0.0335567407310009,
      -0.004748673643916845,
      0.05820910632610321,
      -0.041455384343862534,
      0.051935143768787384,
      -0.06531617790460587,
      0.093004509806633,
      0.05642681568861008,
      0.05895886942744255,
      0.01852806843817234,
      -0.003435691585764289,
      -0.0036421893164515495,
      0.010402952320873737,
      0.039837274700403214,
      -0.05604587867856026,
      -0.003958170767873526,
      -0.030134957283735275,
      0.055226705968379974,
      -0.025270137935876846,
      -0.019258061423897743,
      0.004419124219566584,
      0.06316676735877991,
      0.022128518670797348,
      -0.025135282427072525,
      -0.023649077862501144,
      -0.02381138503551483,
      0.09979762136936188,
      0.03996525704860687,
      0.01695973053574562,
      -0.02284836769104004,
      0.010345639660954475,
      -0.024352245032787323,
      -0.04218635708093643,
      -0.009333735331892967,
      0.044154826551675797,
      0.044224102050065994,
      -0.05320848897099495,
      -0.002668748376891017,
      -0.02758699841797352,
      -0.01686309278011322,
      -0.00852868054062128,
      0.006890022195875645,
      0.015567443333566189,
      0.03229476138949394,
      -0.018286453559994698,
      -0.0541219562292099,
      0.06066939979791641,
      -0.04238046705722809,
      0.009759428910911083,
      -0.010375124402344227,
      -0.05265726149082184,
      -0.04596787318587303,
      -0.02876383066177368,
      0.04149992763996124,
      0.06412739306688309,
      -0.09743980318307877,
      0.036805249750614166,
      -0.031082578003406525,
      -0.09493426233530045,
      0.05260656774044037,
      -0.055207885801792145,
      0.04426167532801628,
      0.022829456254839897,
      -0.06293251365423203,
      -0.12473855912685394,
      -0.08722338080406189,
      0.002741024363785982,
      0.013948739506304264,
      -0.0020119622349739075,
      -0.09291833639144897,
      -0.0275659691542387,
      0.047971758991479874,
      -0.003240752499550581,
      0.01653372496366501,
      -0.025925742462277412,
      0.012190570123493671,
      -0.1137755960226059,
      -0.005534059833735228,
      -0.010123278014361858,
      0.03499017655849457,
      -0.023313699290156364,
      0.014611992985010147,
      0.03236224129796028,
      0.01048912201076746,
      -0.005348121747374535,
      0.051292795687913895,
      -0.01231371983885765,
      0.011313291266560555,
      0.06087060272693634,
      -0.035324838012456894,
      -0.05009237304329872,
      -0.07686101645231247,
      0.005214492790400982,
      -0.03755414858460426,
      -0.008277579210698605,
      -0.05695394426584244,
      0.04910241812467575,
      0.010856839828193188,
      0.03740720450878143,
      0.026275834068655968,
      -0.031237147748470306,
      -0.011556834913790226,
      -0.025733482092618942,
      0.011161615140736103,
      -0.009322614409029484,
      -0.017494723200798035,
      -0.002553909318521619,
      -0.014622044749557972,
      -0.04010453075170517,
      -0.039920929819345474,
      0.0444335862994194,
      0.007267987355589867,
      0.03098347783088684,
      0.0735619068145752,
      -0.011957849375903606,
      0.009280052967369556,
      -0.034069836139678955,
      0.0036554429680109024,
      0.031844716519117355,
      -0.028311297297477722,
      0.023321721702814102,
      -0.027893012389540672,
      0.0550355464220047,
      0.10047396272420883,
      0.018794769421219826,
      0.05813733488321304,
      0.045902740210294724,
      -0.049119241535663605,
      0.11276066303253174,
      0.002749268664047122,
      0.08444666862487793,
      -0.10813531279563904,
      0.04478932172060013,
      0.04534156993031502,
      -0.06658335030078888,
      0.08123445510864258,
      0.04550831392407417,
      -0.05308423936367035,
      -0.09382103383541107,
      0.022337354719638824,
      0.011075489223003387,
      -0.028039414435625076,
      0.04529290273785591,
      0.02460365928709507,
      -0.014402808621525764,
      0.02878013625741005,
      0.042123083025217056,
      0.0826680138707161,
      -0.08060596138238907,
      -0.03516102954745293,
      0.042066145688295364,
      -0.049726251512765884,
      0.036393482238054276,
      -0.020521322265267372,
      -0.013397696428000927,
      0.02884037420153618,
      0.07289113104343414,
      -0.010159522294998169,
      -0.05041975900530815,
      0.0025949953123927116,
      0.016855625435709953,
      0.008364199660718441,
      0.13322925567626953,
      0.014650204218924046,
      0.01594400592148304,
      0.08408018201589584,
      0.04592134431004524,
      0.002039700048044324,
      -0.013956552371382713,
      0.07248860597610474,
      0.011804740875959396,
      0.040829796344041824,
      -0.056583505123853683,
      -0.021463263779878616,
      -0.012621809728443623,
      0.008593223989009857,
      -0.042680639773607254,
      0.07806305587291718,
      0.023962005972862244,
      -0.07090974599123001,
      0.033131882548332214,
      0.06731068342924118,
      -0.033673953264951706,
      -0.006734931375831366,
      -0.11198040843009949,
      -0.0106725562363863,
      -0.038364969193935394,
      0.07205206155776978,
      0.012625712901353836,
      -0.022068195044994354,
      -0.01154316682368517,
      -0.0035924892872571945,
      -0.011840551160275936,
      0.0016086153918877244,
      -0.012147564440965652,
      0.035356707870960236,
      0.008186702616512775,
      0.0052940878085792065,
      0.008861369453370571,
      -0.010113077238202095,
      -0.056933846324682236,
      0.0063754417933523655,
      -0.036184161901474,
      0.046346914023160934,
      0.011350960470736027,
      -0.0874372348189354,
      -0.024606352671980858,
      -0.0641309842467308,
      0.01626499742269516,
      0.06900084763765335,
      0.06646733731031418,
      -0.05435825139284134,
      0.08103282749652863,
      0.09543359279632568,
      -0.05557836592197418,
      -0.10968276113271713,
      0.06643971800804138,
      0.05272424593567848,
      0.007876980118453503,
      0.06007683277130127,
      -0.06931225955486298,
      -0.061875876039266586,
      0.02639796957373619,
      0.03964320197701454,
      0.08323477953672409,
      -0.015348637476563454,
      0.011614752002060413,
      -0.017805960029363632,
      -0.039770130068063736,
      -0.0029241954907774925,
      0.04649464413523674,
      0.0443250872194767,
      0.025739319622516632,
      -0.10737992078065872,
      -0.02557002566754818,
      -0.028449203819036484,
      -0.005798664875328541,
      0.03173736482858658,
      -0.018792858347296715,
      -0.015060198493301868,
      -0.042904626578092575,
      -0.024762336164712906,
      0.03403748944401741,
      0.07291540503501892,
      0.05619321018457413,
      0.054560307413339615,
      -0.011523930355906487,
      -0.061978671699762344,
      -0.0005754406447522342,
      0.0031542133074253798,
      -0.06870612502098083,
      0.06695248931646347,
      0.04980377480387688,
      -0.02060544304549694,
      0.08828435093164444,
      0.01236538216471672,
      0.03076082281768322,
      0.009335195645689964,
      0.045693766325712204,
      -0.03725145384669304,
      0.008287929929792881,
      -0.04622399061918259,
      -0.06203444302082062,
      0.008926940150558949,
      0.044420380145311356,
      -0.012329342775046825,
      -0.016287362203001976,
      0.009767337702214718,
      -0.0212209802120924,
      -0.030497273430228233,
      0.03071019984781742,
      -0.04143387824296951,
      -0.005682636983692646,
      0.019351206719875336,
      0.03462843969464302,
      0.04403350502252579,
      -0.00520161259919405,
      -0.09945230931043625,
      0.0017074914649128914,
      0.004459165968000889,
      0.019574685022234917,
      -0.010878367349505424,
      0.03795532137155533,
      -0.004165528807789087,
      -0.036436695605516434,
      -0.024564487859606743,
      -0.015763843432068825,
      0.04575992375612259,
      -0.03067396581172943,
      -0.028558114543557167,
      0.024798380210995674,
      0.007980111986398697,
      -0.07111158967018127,
      0.05340848118066788,
      -0.06946542114019394,
      0.08663861453533173,
      0.04391247779130936,
      -0.09215439110994339,
      0.07169769704341888,
      -0.0409439355134964,
      -0.023849112913012505,
      0.05464667081832886,
      -0.09574341028928757,
      0.023643173277378082,
      -0.054625023156404495,
      0.027247995138168335,
      0.014825703576207161,
      -0.03219913691282272,
      -0.0234035886824131,
      -0.02362999878823757,
      -0.0014339140616357327,
      -0.06012239679694176,
      0.11532039195299149,
      -0.023095328360795975,
      -0.03492170199751854,
      -0.00961864460259676,
      -0.01298939436674118,
      0.052118975669145584,
      0.025575431063771248,
      0.04577925428748131,
      0.01317922305315733,
      0.014566435478627682,
      0.009410296566784382,
      0.047759559005498886,
      0.03560224920511246,
      0.04132683575153351,
      0.029455527663230896,
      -0.036737896502017975,
      -0.008599337190389633,
      0.010587356053292751,
      -0.03677771985530853,
      0.06002182886004448,
      -0.004608504474163055,
      -0.05355730280280113,
      0.0015355999348685145,
      -1.2347340998530854e-05,
      -0.010751926340162754,
      -0.0336160771548748,
      0.013369586318731308,
      -0.012966187670826912,
      -0.0026848060078918934
    ],
    [
      -0.1144484356045723,
      -0.03078555129468441,
      -0.007956539280712605,
      -0.016491441056132317,
      0.09772594273090363,
      0.007216318976134062,
      -0.04118648171424866,
      -0.004417971707880497,
      -0.029238594695925713,
      0.05068193003535271,
      0.04504818841814995,
      -0.036171190440654755,
      0.0906674861907959,
      0.015005657449364662,
      0.005817083641886711,
      0.07243220508098602,
      -0.0012245012912899256,
      0.07024113833904266,
      -0.11105306446552277,
      0.07331880927085876,
      -0.04995180293917656,
      0.012099584564566612,
      -0.03924131393432617,
      0.06777799874544144,
      -0.060516126453876495,
      0.03034655936062336,
      0.0156557559967041,
      -0.04122935235500336,
      0.018746746703982353,
      -0.015258610248565674,
      0.01838604174554348,
      -0.061207424849271774,
      -0.024002868682146072,
      0.08844636380672455,
      0.049953997135162354,
      0.09588071703910828,
      -0.06754788011312485,
      -0.02427707612514496,
      0.04110884293913841,
      -0.014002308249473572,
      0.03730003908276558,
      -0.025751015171408653,
      0.0408966988325119,
      -0.04501495510339737,
      0.004050371237099171,
      -0.0025193283800035715,
      0.06261146813631058,
      0.037043508142232895,
      -0.018770379945635796,
      -0.03710643947124481,
      0.09219217300415039,
      0.08366565406322479,
      0.007468985393643379,
      0.06418195366859436,
      -0.02316572330892086,
      -0.015246412716805935,
      0.03016548976302147,
      0.033719781786203384,
      -0.005161053501069546,
      0.006344106514006853,
      0.030997902154922485,
      0.08223989605903625,
      0.07298949360847473,
      0.1050453633069992,
      -0.007443714886903763,
      0.03131527453660965,
      0.04220135882496834,
      -0.04321719706058502,
      -0.019565103575587273,
      0.005385169759392738,
      -0.003983334172517061,
      -0.013268460519611835,
      0.06069447845220566,
      0.022994864732027054,
      -0.021930048242211342,
      0.01742609217762947,
      -0.020956777036190033,
      -0.011002490296959877,
      0.011725347489118576,
      -0.04442984610795975,
      -0.06738536804914474,
      0.048134468495845795,
      0.05617056414484978,
      -0.020364981144666672,
      0.04348764568567276,
      0.01731099933385849,
      0.009091626852750778,
      -0.05091223493218422,
      -0.035745568573474884,
      -0.01843133382499218,
      -0.018782172352075577,
      0.03500455990433693,
      0.04411633685231209,
      0.015454445034265518,
      0.039568182080984116,
      0.02482374757528305,
      0.01718847081065178,
      -0.024716336280107498,
      -0.05247756093740463,
      -0.01903153583407402,
      -0.05009894073009491,
      0.05048321932554245,
      -0.004552021622657776,
      -0.013363530859351158,
      -0.04058837890625,
      0.0018359737005084753,
      0.0008013678016141057,
      -0.07847697287797928,
      0.013148648664355278,
      0.03613744676113129,
      -0.03784479200839996,
      0.0361185148358345,
      -0.04012732207775116,
      0.07159708440303802,
      0.018409524112939835,
      0.0031505913939327,
      -0.017234934493899345,
      0.020362496376037598,
      -0.012008966878056526,
      -0.016064418479800224,
      -0.04380262643098831,
      -0.04042438417673111,
      -0.021403908729553223,
      0.035587090998888016,
      -0.035725537687540054,
      0.057523954659700394,
      0.06721600890159607,
      -0.035057999193668365,
      -0.04070792347192764,
      -0.02049551159143448,
      0.12451018393039703,
      0.015313668176531792,
      -0.031442124396562576,
      -0.08099682629108429,
      -0.06128615885972977,
      0.024538278579711914,
      -0.031882986426353455,
      0.016933556646108627,
      0.022707173600792885,
      0.007462744601070881,
      -0.006344903726130724,
      -0.04813089594244957,
      -0.00719804922118783,
      0.09075002372264862,
      -0.013629219494760036,
      -0.015987008810043335,
      -0.026796655729413033,
      -0.0008182217716239393,
      -0.022237233817577362,
      0.019482892006635666,
      0.017550045624375343,
      -0.03144444152712822,
      0.05957549437880516,
      -0.029974300414323807,
      -0.021653560921549797,
      -0.07422856986522675,
      0.0353691391646862,
      -0.020799992606043816,
      0.0021839849650859833,
      0.04203025996685028,
      -0.0027560810558497906,
      0.016194920986890793,
      0.013409974053502083,
      0.005777124781161547,
      0.0704905167222023,
      -0.04709188640117645,
      0.052730899304151535,
      -0.030817797407507896,
      -0.08592721074819565,
      -0.016506580635905266,
      0.02986034005880356,
      0.010387476533651352,
      0.016833553090691566,
      -0.007830515503883362,
      0.01675809919834137,
      -0.01362478919327259,
      0.07510026544332504,
      0.038476746529340744,
      0.05932488292455673,
      -0.020078059285879135,
      -0.07840253412723541,
      -0.003936154767870903,
      0.03669828921556473,
      -0.0669858381152153,
      -0.04056752845644951,
      -0.016828982159495354,
      -0.05222070589661598,
      -0.027101611718535423,
      0.01925075054168701,
      -0.01548445038497448,
      0.020935505628585815,
      -0.0005163463647477329,
      -0.016727078706026077,
      -0.09424223005771637,
      0.06452089548110962,
      -0.02323869988322258,
      -0.10526508092880249,
      0.011060304939746857,
      0.029708508402109146,
      -0.028242051601409912,
      -0.048822496086359024,
      0.07293745130300522,
      -0.05149129405617714,
      0.019395051524043083,
      -0.04889478161931038,
      0.013440031558275223,
      -0.03730564936995506,
      0.012408801354467869,
      0.04238084331154823,
      0.023600898683071136,
      -0.04612931236624718,
      -0.08919435739517212,
      0.08207905292510986,
      -0.08008622378110886,
      -0.03222649171948433,
      0.022399652749300003,
      0.04320994392037392,
      -0.043905992060899734,
      -0.10743370652198792,
      -0.05317795276641846,
      0.022940555587410927,
      0.007811544928699732,
      -0.007469115778803825,
      0.05448976531624794,
      -0.004663312342017889,
      0.04857238009572029,
      -0.04033895581960678,
      -0.10318238288164139,
      0.00680694030597806,
      -0.042733144015073776,
      -0.04087769612669945,
      -0.036484166979789734,
      -0.08515170216560364,
      -0.017171258106827736,
      -0.029769668355584145,
      0.009063491597771645,
      0.001967908814549446,
      -0.018291868269443512,
      0.013693069107830524,
      0.027282021939754486,
      0.004708124790340662,
      0.02205357514321804,
      -0.0014722328633069992,
      0.018475575372576714,
      -0.0029525747522711754,
      -0.003324369201436639,
      0.004168621730059385,
      -0.09846984595060349,
      -0.0002044395514531061,
      0.002965907333418727,
      -0.016932005062699318,
      -0.04268629476428032,
      -0.03433922305703163,
      0.09228301048278809,
      0.004994937684386969,
      -0.0040587857365608215,
      0.05251999944448471,
      0.028775176033377647,
      -0.029766777530312538,
      0.03696240857243538,
      0.013721686787903309,
      -0.01039657462388277,
      -0.020300859585404396,
      -0.0592181570827961,
      0.0018566910875961185,
      0.057515617460012436,
      -0.02028035745024681,
      0.06878071278333664,
      -0.08171939104795456,
      -0.06220129504799843,
      -0.02885429747402668,
      -0.03214072808623314,
      -0.04888034239411354,
      -0.0398540198802948,
      -0.005089364480227232,
      -0.023010291159152985,
      -0.03751302883028984,
      0.05765854939818382,
      -0.04066217318177223,
      0.03362153470516205,
      0.0024919973220676184,
      -0.015668893232941628,
      -0.01715666428208351,
      -0.09169168025255203,
      -0.028254570439457893,
      0.060008395463228226,
      0.003026516642421484,
      0.019321933388710022,
      -0.02042665146291256,
      -0.03205365687608719,
      0.04580418020486832,
      0.022820472717285156,
      -0.02692902833223343,
      0.022965246811509132,
      -0.0065748137421905994,
      0.08859261125326157,
      0.030947016552090645,
      0.021523023024201393,
      -0.06344609707593918,
      -0.0003793750947806984,
      0.0644054189324379,
      -0.02303127758204937,
      0.023451022803783417,
      -0.06929612159729004,
      0.10460848361253738,
      0.04841487854719162,
      0.038253311067819595,
      0.08113499730825424,
      0.01612066850066185,
      0.013430598191916943,
      0.044421758502721786,
      -0.031055007129907608,
      0.08931998163461685,
      -0.01746276021003723,
      -0.06682079285383224,
      0.012991162948310375,
      -0.029941806569695473,
      -0.045924991369247437,
      0.046065595000982285,
      -0.05089826509356499,
      -0.03216615319252014,
      0.00229781586676836,
      -0.001440789201296866,
      0.005573313683271408,
      -0.022235248237848282,
      -0.0022108512930572033,
      -0.008308004587888718,
      0.028710059821605682,
      0.004876559600234032,
      -0.007604795508086681,
      0.07762330025434494,
      0.02633686177432537,
      0.03093520551919937,
      0.0265507772564888,
      0.06486826390028,
      0.010476681403815746,
      0.005923932883888483,
      0.020160462707281113,
      0.092143714427948,
      0.007686613127589226,
      -0.01931021921336651,
      0.011763707734644413,
      -0.06439118832349777,
      -0.037521399557590485,
      0.023300327360630035,
      0.017838096246123314,
      -0.06420367956161499,
      -0.022728072479367256,
      0.013903462328016758,
      -0.04692628234624863,
      0.05845656991004944,
      0.045882467180490494,
      0.01134439930319786,
      0.02789432741701603,
      -0.046681880950927734,
      -0.04906469210982323,
      -0.0007471516146324575,
      0.06887222081422806,
      -0.0589691624045372,
      -0.031439345329999924,
      -0.03441803902387619,
      0.0361265204846859,
      0.012822132557630539,
      -0.07369169592857361,
      -0.021644065156579018,
      0.010032033547759056,
      0.08147195726633072,
      -0.08397611975669861,
      0.0023977297823876143,
      0.032107602804899216,
      -0.06102307140827179,
      -0.02781710959970951,
      0.04729967936873436,
      0.003680568188428879,
      0.0025830387603491545,
      0.03713351860642433,
      0.007249542511999607,
      -0.04964067041873932,
      -0.0075611756183207035,
      -0.0008778383489698172,
      -0.0028402346652001143,
      0.054682835936546326,
      0.040261201560497284,
      0.09366656839847565,
      0.007882572710514069,
      0.04784387722611427,
      0.006524117197841406,
      0.005458950065076351,
      -0.04634327441453934,
      0.06705974787473679,
      0.06368760019540787,
      -0.02891666255891323,
      -0.07060693204402924,
      -0.05240439623594284,
      0.025397809222340584,
      0.042049165815114975,
      -0.06473454087972641,
      0.022805703803896904,
      -0.022410470992326736,
      -0.013096955604851246,
      -0.026880182325839996,
      -0.025632662698626518,
      -0.0935840979218483,
      0.004079020582139492,
      -0.02700906991958618,
      0.0007482246146537364,
      -0.014141487888991833,
      -0.02602037414908409,
      -0.003121541813015938,
      -0.014017187058925629,
      -0.060188502073287964,
      -0.08187136799097061,
      -0.017176078632473946,
      0.07271676510572433,
      0.003916243556886911,
      -0.030443279072642326,
      -0.05586028844118118,
      -0.030834175646305084,
      -0.10557815432548523,
      -0.01984098181128502,
      0.0032884555403143167,
      -0.009638749994337559,
      0.05356089398264885,
      -0.029826616868376732,
      -0.08715593814849854,
      0.01946260780096054,
      0.003839862532913685,
      -0.04183957725763321,
      0.033781539648771286,
      -0.03984060510993004,
      0.00808375608175993,
      -0.05897703021764755,
      -0.02645893767476082,
      0.026080071926116943,
      0.003886838210746646,
      -0.0016929014818742871,
      -0.01057197991758585,
      0.02968013286590576,
      0.02006705291569233,
      -0.1271044909954071,
      -0.059634413570165634,
      0.014195123687386513,
      0.07792650908231735,
      -0.026090888306498528,
      0.09095098078250885,
      -0.011281621642410755,
      -0.05983273312449455,
      0.017428886145353317,
      -0.027549168094992638,
      -0.03549313545227051,
      0.00628278823569417,
      0.07030526548624039,
      -0.004666584078222513,
      0.01280798576772213,
      0.0788167342543602,
      0.03351961076259613,
      -0.028096996247768402,
      -0.11884062737226486,
      -0.004717021249234676,
      -0.011357415467500687,
      -0.03333928436040878,
      -0.013036129996180534,
      -0.018415702506899834,
      -0.02629970759153366,
      -0.08239392191171646,
      -0.1237017884850502,
      -0.0058495174162089825,
      -0.07700252532958984,
      -0.08816186338663101,
      -0.03910660743713379,
      -0.02889014407992363,
      -0.04304327443242073,
      -0.006652423180639744,
      0.05533086881041527,
      -0.01635277457535267,
      -0.0055493637919425964,
      0.0178502406924963,
      0.06702489405870438,
      -0.017866721376776695,
      -0.10466098040342331,
      0.018667522817850113,
      -0.04538341984152794,
      0.0064711919985711575,
      -0.020481832325458527,
      0.09178431332111359,
      0.02181222103536129,
      0.014339546672999859,
      0.07585238665342331,
      0.005995107814669609,
      -0.010957371443510056,
      0.032030027359724045,
      -0.02919798344373703,
      0.021598219871520996,
      0.08892741054296494,
      0.04587119445204735,
      -0.038000740110874176,
      -0.016337497159838676,
      -0.04240898787975311,
      -0.03914567455649376,
      0.0736146792769432,
      0.08739092200994492,
      0.050807125866413116,
      0.02778538502752781
    ],
    [
      0.0666123628616333,
      -0.00390781182795763,
      -0.02122749201953411,
      -0.020936157554388046,
      -0.040258120745420456,
      -0.010122879408299923,
      0.03778880462050438,
      0.011636974290013313,
      0.06814002990722656,
      -0.0381176583468914,
      -0.03382962942123413,
      0.017049796879291534,
      0.03389652073383331,
      -0.038897354155778885,
      -0.015631623566150665,
      -0.033529821783304214,
      0.0008775144815444946,
      0.023314960300922394,
      -0.07724465429782867,
      0.045182257890701294,
      0.003858649404719472,
      -0.041619833558797836,
      0.0025387336499989033,
      0.030593514442443848,
      -0.03511722385883331,
      -0.08351972699165344,
      0.05612439662218094,
      0.03518277034163475,
      0.0074427369982004166,
      0.007887545973062515,
      -0.00644354522228241,
      -0.02539638616144657,
      0.014448576606810093,
      0.03027457557618618,
      -0.07346974313259125,
      -0.007658624090254307,
      -0.02021465264260769,
      -0.004305713810026646,
      -0.026188945397734642,
      -0.0297057144343853,
      0.0408257395029068,
      -0.08081453293561935,
      0.05393354222178459,
      0.00958304014056921,
      0.04523666575551033,
      0.028477227315306664,
      -0.07921169698238373,
      -0.009596589021384716,
      -0.06918053328990936,
      -0.023132536560297012,
      0.04505884274840355,
      0.00332413031719625,
      0.006103680934756994,
      -0.026634637266397476,
      -0.015953203663229942,
      0.05303604155778885,
      0.027828622609376907,
      -0.009599224664270878,
      0.005049955099821091,
      0.005880378652364016,
      0.07511290162801743,
      -0.059513941407203674,
      0.06857003271579742,
      -0.036116957664489746,
      0.010805071331560612,
      0.016644468531012535,
      -0.03810502588748932,
      -0.014521273784339428,
      0.03284415975213051,
      0.012795614078640938,
      -0.01022915355861187,
      0.023314984515309334,
      -0.0019918668549507856,
      0.048457495868206024,
      0.025907982140779495,
      -0.02181505784392357,
      0.003862734418362379,
      -0.014990695752203465,
      0.09199106693267822,
      -0.032513540238142014,
      -0.033755525946617126,
      0.07253620773553848,
      0.06985916197299957,
      -0.004148795735090971,
      -0.013153273612260818,
      0.09857545793056488,
      0.04616047814488411,
      0.056940168142318726,
      0.03264709934592247,
      -0.03301561623811722,
      0.020101886242628098,
      -0.02558707445859909,
      0.032273828983306885,
      -0.08178525418043137,
      -0.018130989745259285,
      0.012769103050231934,
      0.010428832843899727,
      0.026743395254015923,
      -0.023250076919794083,
      -0.08002397418022156,
      -0.023946773260831833,
      0.014950341545045376,
      -0.04095890372991562,
      -0.012845260091125965,
      -0.07714339345693588,
      0.004337830003350973,
      0.016226598992943764,
      0.04335825890302658,
      -0.014616336673498154,
      0.06521670520305634,
      0.06561674922704697,
      -0.051494210958480835,
      -0.04210423305630684,
      0.0007841974147595465,
      -0.022934505715966225,
      -0.035345010459423065,
      -0.026041535660624504,
      0.011470293626189232,
      -0.0035569933243095875,
      -0.006429845932871103,
      -0.07274258881807327,
      0.04961996152997017,
      0.07182198017835617,
      0.005912620108574629,
      -0.017257189378142357,
      0.030494367703795433,
      0.011231956072151661,
      0.044312648475170135,
      -0.02783965691924095,
      -0.0048782434314489365,
      -0.008036862127482891,
      -0.009235573001205921,
      0.04707333818078041,
      0.0537712462246418,
      0.019136764109134674,
      -0.016644088551402092,
      0.030074091628193855,
      -0.0036096132826060057,
      -0.049993351101875305,
      0.036932576447725296,
      -0.05699232593178749,
      -0.08594157546758652,
      0.08822838962078094,
      -0.04449201747775078,
      -0.008014197461307049,
      0.014994468539953232,
      -0.007924691773951054,
      0.05179830268025398,
      0.04049568250775337,
      0.042980145663022995,
      -0.029854603111743927,
      -0.037819113582372665,
      0.0015420778654515743,
      0.015171512961387634,
      -0.03875455632805824,
      -0.04465515539050102,
      -0.02904488518834114,
      0.04873749241232872,
      0.013421561568975449,
      -0.04812692850828171,
      -0.03709878772497177,
      0.04171542450785637,
      -0.02434600703418255,
      0.00996175967156887,
      0.009356825612485409,
      -0.039837323129177094,
      -0.01363525539636612,
      -0.014108395203948021,
      0.017510831356048584,
      0.025652149692177773,
      0.027754852548241615,
      0.10289166867733002,
      -0.05196371302008629,
      -0.10555608570575714,
      -0.009686848148703575,
      -0.024930782616138458,
      0.04391273856163025,
      -0.08634454011917114,
      0.055754777044057846,
      0.05529431253671646,
      -0.07399817556142807,
      -0.05546293407678604,
      -0.09673438966274261,
      -0.01577792502939701,
      0.041330792009830475,
      0.01977572962641716,
      -0.013896413147449493,
      -0.03113224357366562,
      0.10249390453100204,
      0.005312651861459017,
      0.002073503565043211,
      0.0408984012901783,
      -0.03642755374312401,
      -0.10273897647857666,
      0.003508237889036536,
      -0.06651487946510315,
      -0.02552555687725544,
      0.0045394981279969215,
      0.00039376874337904155,
      -0.037279412150382996,
      -0.03208383172750473,
      0.08506837487220764,
      -0.02468566782772541,
      0.03404562547802925,
      -0.0902104452252388,
      -0.031939003616571426,
      -0.06283736974000931,
      0.023714514449238777,
      -0.02354983612895012,
      -0.05224047973752022,
      0.06810532510280609,
      -0.007769577205181122,
      0.08596882969141006,
      -0.036924757063388824,
      -0.02671927772462368,
      -0.026473354548215866,
      -0.030488887801766396,
      0.06950806081295013,
      -0.04358851909637451,
      0.006860311608761549,
      -0.08556679636240005,
      0.0005477904342114925,
      -0.05928606539964676,
      0.011460556648671627,
      -0.09853698313236237,
      0.031256094574928284,
      -0.009586955420672894,
      -0.05499345809221268,
      0.0079888217151165,
      -0.011840277351439,
      0.03123406693339348,
      0.01921536587178707,
      0.01396216917783022,
      -0.04799756035208702,
      -0.038982246071100235,
      -0.004694215953350067,
      0.0007550104637630284,
      -0.042958859354257584,
      -0.0004693894006777555,
      -0.0051109869964420795,
      0.103000208735466,
      -0.044040314853191376,
      0.037485044449567795,
      -0.03255287930369377,
      0.025422897189855576,
      -0.01801649108529091,
      -0.02846583165228367,
      0.03716670721769333,
      0.10352016985416412,
      0.08862067013978958,
      -0.029266003519296646,
      0.06459847837686539,
      -0.05135544762015343,
      -0.02527034655213356,
      -0.01406717300415039,
      0.051332633942365646,
      -0.0347195602953434,
      -0.06094957888126373,
      -0.04908877983689308,
      0.03962017968297005,
      -0.024125024676322937,
      -0.0368230938911438,
      0.017295921221375465,
      -0.02768893912434578,
      -0.051221814006567,
      0.010662411339581013,
      -0.03584694862365723,
      -0.003077617147937417,
      -0.008834908716380596,
      0.04162738099694252,
      0.09591151773929596,
      -0.01275898888707161,
      -0.0645122230052948,
      0.00010444800864206627,
      -0.00856840144842863,
      0.03885732963681221,
      0.05006839707493782,
      0.05208102986216545,
      -0.08850984275341034,
      -0.026923106983304024,
      0.014254691079258919,
      -0.012810849584639072,
      -0.003897970775142312,
      0.027580903843045235,
      -0.0660397857427597,
      0.030832236632704735,
      -0.034601546823978424,
      0.0886923149228096,
      -0.030774127691984177,
      0.021437734365463257,
      0.0631737932562828,
      -0.047719214111566544,
      0.00017083076818380505,
      0.012148352339863777,
      -0.0351439043879509,
      0.030796561390161514,
      0.10256140679121017,
      0.018314048647880554,
      0.03347501903772354,
      -0.04768642038106918,
      0.026049740612506866,
      0.05392644926905632,
      -0.005247102119028568,
      -0.06789695471525192,
      -0.008674276992678642,
      -0.05184932425618172,
      0.011327443644404411,
      -0.03852861374616623,
      -0.05306103080511093,
      0.007611880544573069,
      0.0028287002351135015,
      -0.004170918371528387,
      -0.01841740496456623,
      -0.05260908231139183,
      0.013725707307457924,
      -0.059389155358076096,
      -0.03352230414748192,
      -0.0067570265382528305,
      0.020010629668831825,
      0.03223380818963051,
      0.14235790073871613,
      0.052549995481967926,
      0.026697132736444473,
      -0.05322738736867905,
      0.07290835678577423,
      0.020650589838624,
      0.0018347285222262144,
      0.059243232011795044,
      0.03286547213792801,
      -0.03126994147896767,
      -0.008524278178811073,
      -0.013624923303723335,
      0.013060915283858776,
      0.01584862545132637,
      -0.14135205745697021,
      -0.03360318765044212,
      -0.025405146181583405,
      0.03814561292529106,
      0.05529482290148735,
      0.000963721249718219,
      -0.0012602562783285975,
      -0.0478273406624794,
      -0.07104823738336563,
      0.009273801930248737,
      -0.07698261737823486,
      -0.017494920641183853,
      -0.06186605244874954,
      -0.020579447969794273,
      -0.04476984217762947,
      0.018543541431427002,
      -0.011159852147102356,
      0.029805907979607582,
      -0.05092426761984825,
      -0.04272489249706268,
      -0.03779517859220505,
      0.12321818619966507,
      0.0058419606648385525,
      -0.006988496985286474,
      -0.0031262801494449377,
      -0.03167598694562912,
      -0.04276704788208008,
      -0.0427933894097805,
      -0.03201909363269806,
      0.03106309473514557,
      4.567244104691781e-05,
      0.0007916241302154958,
      0.060316480696201324,
      0.01687798649072647,
      0.013642138801515102,
      0.04541703313589096,
      0.01582314632833004,
      0.05541328713297844,
      -0.01880856789648533,
      -0.03213482350111008,
      -0.0878802090883255,
      0.010449963621795177,
      0.011068596504628658,
      -0.008782493881881237,
      -0.008869720622897148,
      -0.019990479573607445,
      -0.008248033933341503,
      0.047245338559150696,
      -0.007919922471046448,
      0.03813440725207329,
      -0.07180973887443542,
      -0.02080659754574299,
      0.0758494883775711,
      -0.00401495024561882,
      -0.041583720594644547,
      -0.036312490701675415,
      0.013753460720181465,
      -0.012896052561700344,
      -0.03836239129304886,
      0.03666190803050995,
      -0.05587778985500336,
      -0.0021830580662935972,
      -0.08495713770389557,
      -0.004488213453441858,
      0.029131131246685982,
      -0.03540787100791931,
      -0.03437046706676483,
      -0.06621602177619934,
      -0.03358932211995125,
      -0.008479342795908451,
      0.08211851119995117,
      0.012739661149680614,
      0.013259257189929485,
      -0.06808505952358246,
      0.0162945669144392,
      -0.03265530243515968,
      -0.03636261448264122,
      -0.06020413339138031,
      0.06054914742708206,
      0.11666721105575562,
      0.07552136480808258,
      -0.0032298259902745485,
      -0.030341031029820442,
      -0.0534057579934597,
      0.039882123470306396,
      0.01340498961508274,
      -0.04535629600286484,
      0.05419272556900978,
      0.0278022401034832,
      -0.06194932758808136,
      -0.03905898705124855,
      -0.03536483272910118,
      0.09114260971546173,
      0.003483396489173174,
      -0.02486041933298111,
      0.01884651370346546,
      -0.020392579957842827,
      0.07334408164024353,
      -0.025198230519890785,
      -0.017327148467302322,
      -0.02228996716439724,
      0.023920560255646706,
      0.024653421714901924,
      0.022124281153082848,
      0.0037719865795224905,
      0.014693866483867168,
      0.025164397433400154,
      0.1266133189201355,
      0.02724178321659565,
      0.052351828664541245,
      -0.010763765312731266,
      0.05489446967840195,
      0.0004211559135001153,
      0.024615436792373657,
      -0.0017947398591786623,
      -0.0060484036803245544,
      -0.014358889311552048,
      0.055246785283088684,
      -0.08237412571907043,
      0.06909353286027908,
      0.03898672014474869,
      -0.04016491025686264,
      0.015289261937141418,
      0.042418695986270905,
      0.050806377083063126,
      0.035281211137771606,
      0.06944839656352997,
      0.02188357152044773,
      0.011274517513811588,
      -0.09655240923166275,
      -0.13345064222812653,
      0.00896286778151989,
      0.041078075766563416,
      0.021624203771352768,
      -0.020733505487442017,
      0.09948518872261047,
      -0.001698847976513207,
      0.032738298177719116,
      0.027424871921539307,
      -0.033994629979133606,
      -0.0014408904826268554,
      0.020447945222258568,
      0.016725368797779083,
      0.07752958685159683,
      -0.03753913938999176,
      -0.0036600949242711067,
      -0.06314406543970108,
      -0.031025949865579605,
      -0.05697033181786537,
      -0.05433637648820877,
      0.04255460575222969,
      -0.07836662977933884,
      0.009438620880246162,
      -0.015399609692394733,
      -0.00958196446299553,
      -0.00780929671600461,
      -0.0689927414059639,
      0.011250562034547329,
      -0.0026223401073366404,
      0.023929540067911148,
      -0.015533098950982094,
      0.08784916251897812,
      0.05407771095633507,
      0.012212461791932583,
      -0.04422983154654503,
      0.040954627096652985,
      -0.002701078075915575,
      0.03673897311091423,
      0.147568479180336
    ],
    [
      0.008352143689990044,
      0.0336633026599884,
      0.012115443125367165,
      -0.044197775423526764,
      0.020818568766117096,
      -0.002832941245287657,
      0.011013325303792953,
      -0.026200104504823685,
      -0.05199762061238289,
      0.019620271399617195,
      0.005997808650135994,
      -0.06444692611694336,
      0.04804385453462601,
      0.06107942387461662,
      0.0370323620736599,
      -0.02488948032259941,
      0.009282716549932957,
      0.036719128489494324,
      0.02441011369228363,
      -0.012002295814454556,
      -0.05430257320404053,
      0.030167702585458755,
      0.023858333006501198,
      0.08735579997301102,
      0.002214436884969473,
      0.026007885113358498,
      -0.0030847478192299604,
      0.060036759823560715,
      -0.04866040125489235,
      -0.050557591021060944,
      0.051507096737623215,
      -0.07251240313053131,
      0.008438953198492527,
      -0.015024004504084587,
      -0.051030829548835754,
      0.07413423806428909,
      0.04913150519132614,
      0.016241712495684624,
      -0.03425600752234459,
      -0.010435339994728565,
      0.004671252332627773,
      -0.013329125009477139,
      -0.02815893106162548,
      -0.002434964058920741,
      -0.032139815390110016,
      -0.018115678802132607,
      0.005943169817328453,
      -0.01980479247868061,
      -0.0611460916697979,
      0.052389755845069885,
      0.04814176633954048,
      -0.07087104022502899,
      -0.052570853382349014,
      -0.0013811782700940967,
      -0.05718236044049263,
      0.0291605144739151,
      -0.06746822595596313,
      -0.04366067796945572,
      -0.020003540441393852,
      -0.09512049704790115,
      -0.02242194674909115,
      -0.06394822895526886,
      0.03678693622350693,
      -0.01854782924056053,
      -0.05466587096452713,
      0.00729797501116991,
      -0.030350053682923317,
      0.03374212235212326,
      -0.01810414157807827,
      -0.048317600041627884,
      -0.04395884647965431,
      0.019911374896764755,
      -0.005413188133388758,
      0.03500992804765701,
      0.013979802839457989,
      -0.03955983743071556,
      0.053254157304763794,
      -0.027614735066890717,
      0.011884546838700771,
      -0.08227777481079102,
      0.04007656127214432,
      -0.038625091314315796,
      0.01220792718231678,
      -0.01525063719600439,
      -0.017567602917551994,
      -0.11984820663928986,
      0.03523924574255943,
      0.02902090921998024,
      -0.02930946834385395,
      -0.024235548451542854,
      0.023490512743592262,
      0.030025595799088478,
      0.034954044967889786,
      -0.030804984271526337,
      0.06132404878735542,
      0.0037677360232919455,
      -0.009213673882186413,
      0.010225537233054638,
      0.008488592691719532,
      -0.03533828631043434,
      0.019795455038547516,
      0.05175138637423515,
      0.016400231048464775,
      0.019618606194853783,
      -0.02612246386706829,
      0.014586436562240124,
      -0.04415842890739441,
      -0.11362362653017044,
      -0.07041049748659134,
      -0.00839205551892519,
      0.05087276175618172,
      0.04218088835477829,
      0.0007079674396663904,
      0.03601633757352829,
      0.024459831416606903,
      0.08877729624509811,
      0.02417898178100586,
      0.042618848383426666,
      -0.08352665603160858,
      0.017298409715294838,
      0.058560941368341446,
      -0.0015177014283835888,
      0.05886257812380791,
      -0.007109599653631449,
      -0.0023086722940206528,
      0.018096135929226875,
      -0.029769636690616608,
      -0.019155649468302727,
      -0.024319911375641823,
      -0.05151178315281868,
      0.01463855430483818,
      0.01578694023191929,
      0.02112102322280407,
      -0.013122618198394775,
      0.008717894554138184,
      0.0049314056523144245,
      -0.055169954895973206,
      0.022873571142554283,
      0.023661265149712563,
      -0.024961011484265327,
      -0.016805272549390793,
      0.036430053412914276,
      0.03974352777004242,
      -0.04580570384860039,
      -0.010789547115564346,
      0.0682956650853157,
      0.0071087973192334175,
      -0.013062039390206337,
      -0.05622270703315735,
      0.041354384273290634,
      -0.004849465563893318,
      -0.020256148651242256,
      0.059750307351350784,
      0.02680663764476776,
      0.03837442025542259,
      0.03231880068778992,
      -0.03030937910079956,
      -0.0004684665473178029,
      -0.07169751077890396,
      -0.00868973508477211,
      0.10758225619792938,
      -0.044165220111608505,
      0.008881309069693089,
      -0.038570795208215714,
      -0.08265244215726852,
      -0.007852933369576931,
      -0.008503093384206295,
      -0.11373632401227951,
      0.0764189213514328,
      0.04163193330168724,
      0.03690403327345848,
      0.013558498583734035,
      -0.04801354557275772,
      0.003506849054247141,
      0.034077756106853485,
      -0.022212812677025795,
      -0.01737164705991745,
      -0.05046717822551727,
      0.028054185211658478,
      0.038462840020656586,
      0.06818851828575134,
      -0.038686227053403854,
      -0.046130213886499405,
      0.034025780856609344,
      -0.03745366260409355,
      -0.02239527739584446,
      0.09618744999170303,
      -0.0567803755402565,
      0.06491599977016449,
      -0.01390181016176939,
      -0.0010575567139312625,
      0.02233356237411499,
      0.09521587193012238,
      -0.03290678188204765,
      -0.0979628935456276,
      -0.03605787828564644,
      -0.07101337611675262,
      0.12331736832857132,
      0.01897985301911831,
      0.00028879937599413097,
      -0.06124763935804367,
      0.05689132213592529,
      0.0016619096277281642,
      -0.013153532519936562,
      0.030776457861065865,
      -0.0029554078355431557,
      0.03404039144515991,
      0.0031563316006213427,
      0.030369706451892853,
      0.007777879945933819,
      -0.015927115455269814,
      0.029314057901501656,
      -0.03323293477296829,
      -0.06443986296653748,
      -0.03346570208668709,
      0.024183394387364388,
      0.040399231016635895,
      0.021436335518956184,
      0.010316972620785236,
      -0.0375119149684906,
      0.0014035813510417938,
      0.05113182216882706,
      -0.10731358826160431,
      -0.008231820538640022,
      -0.023523585870862007,
      -0.012214895337820053,
      -0.026488183066248894,
      -0.03703264147043228,
      -0.04100711643695831,
      0.05432434007525444,
      -0.012335176579654217,
      -0.014492166228592396,
      -0.042631298303604126,
      0.01771959476172924,
      -0.05008853226900101,
      0.009433926083147526,
      0.005580957047641277,
      0.03948909789323807,
      0.03305206820368767,
      0.004202770069241524,
      -0.004235091619193554,
      0.07440531998872757,
      -0.1096787303686142,
      0.034479815512895584,
      -0.010789470747113228,
      0.028284935280680656,
      -0.012265637516975403,
      0.028165938332676888,
      -0.017709065228700638,
      -0.003318981733173132,
      -0.04322325438261032,
      0.07392637431621552,
      0.06483572721481323,
      -0.04021831229329109,
      0.03911008685827255,
      -0.036299996078014374,
      0.08022269606590271,
      -0.020856842398643494,
      0.03606369346380234,
      -0.027722006663680077,
      0.03129025176167488,
      -0.03689140826463699,
      0.09034156054258347,
      -0.05936923250555992,
      0.039515674114227295,
      -0.02792208455502987,
      0.04200376570224762,
      0.07061497122049332,
      -0.05007673054933548,
      -0.03523760661482811,
      0.060046304017305374,
      -0.012679094448685646,
      0.0811881497502327,
      0.037059247493743896,
      0.07333061844110489,
      0.005826431326568127,
      -0.051865801215171814,
      -0.06759827584028244,
      -0.0397983156144619,
      -0.055010635405778885,
      -0.013979884795844555,
      0.020300626754760742,
      -0.03837323188781738,
      -0.031012995168566704,
      -0.03484688326716423,
      0.04326779767870903,
      -0.07426737248897552,
      -0.017034905031323433,
      -0.03904561325907707,
      -0.024158703163266182,
      0.035271067172288895,
      0.04046491160988808,
      0.044434599578380585,
      -0.03293803706765175,
      -0.02210880257189274,
      0.007588300853967667,
      0.050157614052295685,
      -0.0017194817773997784,
      -0.09868886321783066,
      -0.030132921412587166,
      -0.014298547990620136,
      -0.02336188778281212,
      0.028595563024282455,
      -0.013481643982231617,
      0.015458373352885246,
      -0.007893864996731281,
      -0.010070840828120708,
      -0.115041583776474,
      0.07735554128885269,
      -0.09178022295236588,
      0.07571020722389221,
      0.08393203467130661,
      0.015719814226031303,
      -0.0367165170609951,
      0.022512324154376984,
      -0.01197317149490118,
      -0.02802722156047821,
      -0.01421197410672903,
      0.004742744844406843,
      0.030923960730433464,
      0.08246803283691406,
      -0.01720443181693554,
      0.02182658761739731,
      -0.07573451101779938,
      0.07687339931726456,
      0.031105082482099533,
      0.039464857429265976,
      -0.05862012133002281,
      0.03381137177348137,
      -0.027074774727225304,
      0.027235519140958786,
      -0.035360198467969894,
      0.013534432277083397,
      -0.05378812551498413,
      -0.0296469833701849,
      -0.03930342569947243,
      0.007470165845006704,
      0.01315342541784048,
      -0.032493237406015396,
      0.056904859840869904,
      0.03388774022459984,
      0.0013186739524826407,
      0.07862244546413422,
      0.060355450958013535,
      -0.004713104106485844,
      -0.05525995045900345,
      0.04892099276185036,
      0.03883480653166771,
      0.023821735754609108,
      0.03485173359513283,
      0.04985788092017174,
      0.03871620073914528,
      -0.01912010833621025,
      -0.05228615552186966,
      -0.006926931906491518,
      -0.012143063358962536,
      0.06545492261648178,
      -0.09202581644058228,
      -0.10075768083333969,
      -0.055247582495212555,
      0.08575405925512314,
      -0.06705156713724136,
      0.029103776440024376,
      -0.006445669569075108,
      0.014224445447325706,
      0.09447991102933884,
      -0.026892272755503654,
      -0.002913176314905286,
      -0.0012487750500440598,
      -0.01613481529057026,
      0.011585047468543053,
      -0.07508207857608795,
      -0.05000505968928337,
      -0.020692238584160805,
      -0.08440936356782913,
      0.0282200388610363,
      0.02828076481819153,
      -0.05179016292095184,
      0.008194100111722946,
      -0.02540416084229946,
      -0.04330788180232048,
      0.04939308390021324,
      -0.012130135670304298,
      -0.023992450907826424,
      -0.057966385036706924,
      0.01201094500720501,
      -0.03470904007554054,
      0.06854962557554245,
      -0.025178449228405952,
      -0.00041349022649228573,
      -0.0352846197783947,
      -0.0022085625678300858,
      -0.05156641826033592,
      -0.027303440496325493,
      -0.005769089329987764,
      -0.028577089309692383,
      0.04104602336883545,
      0.013441239483654499,
      -0.014631589874625206,
      -0.013721846975386143,
      0.03358527645468712,
      0.039042577147483826,
      -0.03195097669959068,
      -0.019022831693291664,
      0.04825831577181816,
      -0.001646131044253707,
      0.05021405965089798,
      0.018848169595003128,
      -0.07634231448173523,
      -0.06246297061443329,
      -0.06256875395774841,
      -0.022525418549776077,
      -0.02103949338197708,
      -0.06470883637666702,
      -0.005130054894834757,
      -0.008742737583816051,
      -0.013723921030759811,
      0.04486442729830742,
      0.010637185536324978,
      -0.011321119964122772,
      -0.050223514437675476,
      0.023770831525325775,
      -0.042958877980709076,
      -0.020200345665216446,
      -0.025436291471123695,
      -0.03979751095175743,
      0.05191002041101456,
      0.04987969994544983,
      -0.012883641757071018,
      -0.003816739423200488,
      -0.0773022249341011,
      0.048354942351579666,
      0.023306360468268394,
      0.006606863811612129,
      -0.027752159163355827,
      0.019074873998761177,
      -0.03275720402598381,
      -0.010426378808915615,
      -0.07772450149059296,
      -0.004728199914097786,
      -0.0615592822432518,
      -0.03678930550813675,
      -0.0016428028466179967,
      -0.012823749333620071,
      0.027348417788743973,
      0.01797962561249733,
      -0.035897430032491684,
      0.007790766190737486,
      -0.041844334453344345,
      0.04331482946872711,
      -0.0900336503982544,
      0.05188120901584625,
      0.07434065639972687,
      0.10222039371728897,
      -0.15118427574634552,
      0.14620532095432281,
      0.015506161376833916,
      0.0280174370855093,
      -0.04161642864346504,
      -0.024426637217402458,
      0.03617731109261513,
      0.07782546430826187,
      0.009454293176531792,
      -0.04321227967739105,
      0.0015021730214357376,
      -0.03339885175228119,
      0.051663853228092194,
      0.021728236228227615,
      -0.03043387457728386,
      -0.041338253766298294,
      -0.0017270586686208844,
      0.062124259769916534,
      -0.04226617142558098,
      -0.007069287821650505,
      -0.13877518475055695,
      0.035516466945409775,
      -0.006328877527266741,
      -0.03300976753234863,
      -0.08937912434339523,
      0.007492908276617527,
      -0.08375225961208344,
      0.08382207900285721,
      -0.007634514942765236,
      -0.012036679312586784,
      -0.0028781061992049217,
      -0.004416880663484335,
      0.04449453204870224,
      -0.05450933799147606,
      0.09434594213962555,
      0.0008959353435784578,
      0.005713580176234245,
      -0.05268590897321701,
      -0.013985712081193924,
      -0.007380818948149681,
      -0.07043014466762543,
      -0.020765285938978195,
      0.05351516604423523,
      0.040825020521879196,
      0.08471335470676422,
      0.09911038726568222,
      -0.00719453627243638,
      -0.04993930459022522,
      -0.03776656463742256
    ],
    [
      0.024660924449563026,
      -0.006177594419568777,
      -0.030940132215619087,
      -0.002456790069118142,
      0.0389820858836174,
      -0.02301720902323723,
      -0.04334167018532753,
      -0.011588048189878464,
      -0.008270008489489555,
      -0.032211579382419586,
      0.025238903239369392,
      -0.013231652788817883,
      -0.008885263465344906,
      -0.055927492678165436,
      -0.009203650988638401,
      0.02597130462527275,
      -0.027670815587043762,
      0.0431639738380909,
      -0.05892704799771309,
      -0.032143544405698776,
      -0.005073698237538338,
      0.062168240547180176,
      -0.04956687614321709,
      -0.06464708596467972,
      0.050609439611434937,
      0.008086062967777252,
      0.034644126892089844,
      -0.011893260292708874,
      -0.0020265746861696243,
      0.046040669083595276,
      0.06779689341783524,
      -0.015441360883414745,
      -0.021449217572808266,
      0.010074765421450138,
      0.061138782650232315,
      0.004459212999790907,
      0.035700030624866486,
      0.030824055895209312,
      -0.018485229462385178,
      0.01727612130343914,
      -0.05526383966207504,
      -0.05784113332629204,
      0.021446695551276207,
      -0.07146323472261429,
      -0.003424879629164934,
      -0.058326222002506256,
      -0.045499835163354874,
      -0.10018008947372437,
      -0.06767033785581589,
      0.08883599936962128,
      0.02827504090964794,
      0.01249142549932003,
      0.043976400047540665,
      -0.0014163597952574492,
      0.018818004056811333,
      -0.0034125710371881723,
      -0.05689569562673569,
      -0.029104705899953842,
      0.007639036048203707,
      -0.04689038544893265,
      -0.015821803361177444,
      0.014436581172049046,
      0.005757228005677462,
      -0.012465454638004303,
      0.05786139890551567,
      0.12179585546255112,
      0.0540572814643383,
      0.07802384346723557,
      -0.05258426442742348,
      -0.08583774417638779,
      0.025370124727487564,
      -0.031669314950704575,
      0.08446154743432999,
      0.03629448637366295,
      -0.0028196354396641254,
      -0.0276617594063282,
      0.01703142188489437,
      0.0007376199937425554,
      0.009807821363210678,
      -0.11739657074213028,
      -0.08352723717689514,
      0.026064136996865273,
      0.0016453060088679194,
      0.028910085558891296,
      -0.0449264794588089,
      -0.0649268627166748,
      -0.08567354083061218,
      -0.02034212090075016,
      -0.03875260800123215,
      -0.1326638162136078,
      0.1080658882856369,
      -0.010757550597190857,
      -0.006023597903549671,
      0.04862844944000244,
      0.0391908623278141,
      -0.04277335852384567,
      0.0743500366806984,
      -0.05333070456981659,
      -0.04427267983555794,
      0.008178786374628544,
      -0.04273023456335068,
      -0.027234481647610664,
      -0.008636010810732841,
      -0.03464274853467941,
      -0.007473052013665438,
      -0.013209186494350433,
      -0.01596449874341488,
      0.060012951493263245,
      -0.002496798522770405,
      0.0656518042087555,
      0.03244568780064583,
      -0.021008728072047234,
      0.009381433948874474,
      0.04698235169053078,
      0.005873553920537233,
      0.005599763710051775,
      0.0693611428141594,
      -0.03287484869360924,
      -0.06970582902431488,
      0.06354155391454697,
      -0.008730356581509113,
      0.03225340321660042,
      0.05308697372674942,
      0.015815027058124542,
      -0.061473600566387177,
      0.024038342759013176,
      -0.005642356816679239,
      -0.05686882510781288,
      -0.0002284329675603658,
      0.07671105861663818,
      -0.05313895642757416,
      0.1001838892698288,
      0.001994369551539421,
      -0.017791250720620155,
      0.01207458134740591,
      0.036290962249040604,
      -0.06607208400964737,
      0.03163471445441246,
      -0.011937796138226986,
      0.08442945033311844,
      -0.05399521067738533,
      -0.06218840181827545,
      -0.04830167070031166,
      -0.08841957151889801,
      0.10993005335330963,
      -0.002996389288455248,
      0.020614037290215492,
      0.04221620783209801,
      -0.06370727717876434,
      0.03514672815799713,
      0.0110619543120265,
      0.013998973183333874,
      -0.06117852032184601,
      0.028745003044605255,
      -0.09311819076538086,
      0.0013759997673332691,
      0.014292007312178612,
      0.06268595159053802,
      0.01099331397563219,
      -0.05140610784292221,
      0.0007457266910932958,
      0.016642367467284203,
      -0.0400344543159008,
      0.0071799615398049355,
      0.05295076593756676,
      -0.006377271842211485,
      -0.00558093236759305,
      0.0174786988645792,
      -0.0203031525015831,
      0.07158499211072922,
      0.013220744207501411,
      0.005307134706526995,
      -0.009059303440153599,
      -0.06622309237718582,
      -0.034880585968494415,
      -0.09508214890956879,
      0.040829215198755264,
      -0.002653180155903101,
      0.026639455929398537,
      0.01429811306297779,
      0.04243336245417595,
      -0.002919505350291729,
      -0.04279906675219536,
      0.001480212202295661,
      -0.0006284518749453127,
      0.028711823746562004,
      0.058481279760599136,
      0.043353527784347534,
      0.043122392147779465,
      -0.03136199712753296,
      0.02800404094159603,
      0.06735879182815552,
      0.012510723434388638,
      -0.01730443350970745,
      0.036816343665122986,
      -0.00846429169178009,
      0.02062508836388588,
      -0.009253590367734432,
      0.06154323369264603,
      0.01187846902757883,
      0.020522167906165123,
      0.019320931285619736,
      -0.04410036280751228,
      -0.03272854909300804,
      0.045174989849328995,
      -0.023047421127557755,
      -0.056198783218860626,
      -0.05003863945603371,
      -0.011974341236054897,
      -0.040913328528404236,
      0.026530882343649864,
      -0.03930250555276871,
      -0.10833647847175598,
      0.006266195792704821,
      -0.03900688886642456,
      0.043028343468904495,
      -0.05783296003937721,
      0.00789963360875845,
      -0.02686954289674759,
      -0.05220548436045647,
      -0.03555641323328018,
      0.0015718246577307582,
      -0.06844359636306763,
      0.028745051473379135,
      0.013702730648219585,
      -0.06330064684152603,
      -0.03317029029130936,
      0.039523493498563766,
      -0.0717109739780426,
      0.0584232322871685,
      0.057148467749357224,
      0.06224966049194336,
      0.02406838908791542,
      -0.04127956181764603,
      -0.06779857724905014,
      -0.0024175220169126987,
      -0.005857008974999189,
      0.0483553484082222,
      -0.07070097327232361,
      -0.016059137880802155,
      -0.049646489322185516,
      -0.02680061198771,
      -0.03446951135993004,
      0.0377536304295063,
      0.07923441380262375,
      0.03551792725920677,
      -0.0178446676582098,
      0.007184101268649101,
      -0.030309520661830902,
      -0.07517032325267792,
      0.0010512772714719176,
      0.03764675185084343,
      -0.015067907981574535,
      0.022524243220686913,
      -0.011440525762736797,
      -0.03395846486091614,
      -0.019294314086437225,
      0.034701887518167496,
      -0.02037905715405941,
      -0.0347289964556694,
      -0.017524240538477898,
      0.061951518058776855,
      0.03618748113512993,
      -0.029833972454071045,
      0.06206665560603142,
      0.02205668017268181,
      -0.006237670313566923,
      -0.015259448438882828,
      -0.059354569762945175,
      0.09038145840167999,
      -0.003911545965820551,
      -0.030841175466775894,
      -0.050304312258958817,
      0.020620550960302353,
      -0.006996301002800465,
      0.041891131550073624,
      -0.02968590520322323,
      -0.004666970577090979,
      -0.001582194585353136,
      -0.005710184574127197,
      0.00969358067959547,
      0.008567997254431248,
      0.025997409597039223,
      0.033516425639390945,
      0.004418724682182074,
      0.07798664271831512,
      0.012927756644785404,
      0.06305575370788574,
      -0.02845955826342106,
      -0.06981438398361206,
      0.006703244987875223,
      -0.043705619871616364,
      0.08800502121448517,
      0.05562567338347435,
      -0.013587475754320621,
      -0.08789906650781631,
      -0.0676160380244255,
      0.04120093584060669,
      -0.0021021212451159954,
      -0.016029613092541695,
      0.03982653096318245,
      0.004394392482936382,
      -0.09214182943105698,
      -0.010223577730357647,
      0.024146083742380142,
      -0.023761678487062454,
      0.07980754971504211,
      0.07157357037067413,
      0.030870193615555763,
      -0.014551626518368721,
      -0.019481707364320755,
      -0.029594101011753082,
      0.03533250465989113,
      -0.06556318700313568,
      0.06187322735786438,
      0.11619606614112854,
      0.03938968479633331,
      0.04218028113245964,
      0.07711416482925415,
      -0.043554309755563736,
      -0.027148665860295296,
      0.06418457627296448,
      -0.04502829536795616,
      -0.037797894328832626,
      -0.03738384693861008,
      0.05511411279439926,
      -0.05588783323764801,
      -0.0009818506659939885,
      -0.08878589421510696,
      -0.033441804349422455,
      0.014185678213834763,
      0.043965891003608704,
      0.0084909126162529,
      0.01859837956726551,
      0.04656510427594185,
      0.021756557747721672,
      -0.03263263404369354,
      -0.05850250646471977,
      -0.015344100072979927,
      0.07070831954479218,
      0.07402307540178299,
      0.029960639774799347,
      -0.004087567795068026,
      0.026594119146466255,
      0.038043905049562454,
      -0.05915392190217972,
      0.03962155804038048,
      0.057896122336387634,
      0.03137492015957832,
      0.007538182195276022,
      -0.042317409068346024,
      0.014420285820960999,
      0.10888523608446121,
      0.04427069425582886,
      0.11318998783826828,
      -0.10700657218694687,
      -0.08338619023561478,
      -0.05315432697534561,
      0.03738551586866379,
      0.002197711728513241,
      0.011542136780917645,
      -0.09234551340341568,
      0.04028566926717758,
      0.07039334625005722,
      0.0015793750062584877,
      0.039365578442811966,
      0.01390449795871973,
      -0.017009081318974495,
      -0.01596052572131157,
      -0.06730980426073074,
      -0.09229932725429535,
      0.037146393209695816,
      0.027306506410241127,
      -0.03665509447455406,
      0.01770806312561035,
      -0.036360446363687515,
      -0.04023180156946182,
      -0.024705879390239716,
      -0.03550875931978226,
      -0.038210343569517136,
      -0.08802265673875809,
      0.050746481865644455,
      -0.08698223531246185,
      0.047944068908691406,
      -0.1096562072634697,
      -0.044350557029247284,
      0.02776739001274109,
      -0.028409570455551147,
      -0.05130241438746452,
      0.005598299205303192,
      0.03085799142718315,
      -0.033475711941719055,
      -0.007671043276786804,
      -0.03951171785593033,
      0.012900978326797485,
      0.03917913883924484,
      -0.031647782772779465,
      0.060864079743623734,
      0.025696326047182083,
      -0.02020026370882988,
      0.041163865476846695,
      0.049471165984869,
      -0.0468079037964344,
      -0.01856442540884018,
      0.057121824473142624,
      0.02566097304224968,
      -0.02121221274137497,
      -0.03255096822977066,
      0.04905681684613228,
      0.012231764383614063,
      0.015819760039448738,
      0.04564135521650314,
      -0.027367111295461655,
      -0.0048965150490403175,
      0.0063466825522482395,
      0.012153134681284428,
      0.07135994732379913,
      -0.08567590266466141,
      0.0751015916466713,
      0.05012586712837219,
      -0.06522694230079651,
      0.020877700299024582,
      -0.015494284220039845,
      -0.03408064693212509,
      -0.08155881613492966,
      -0.010380897670984268,
      -0.0427175909280777,
      0.00513499928638339,
      -0.017966752871870995,
      -0.07578907161951065,
      -0.017120180651545525,
      0.01589580439031124,
      -0.027818674221634865,
      0.08717717230319977,
      -0.04155995696783066,
      0.04816430062055588,
      -0.05987955629825592,
      -0.007357163354754448,
      -0.003992733079940081,
      0.0352787971496582,
      -0.03754565119743347,
      0.017315933480858803,
      0.04339487478137016,
      0.00525915389880538,
      -0.014403579756617546,
      0.02692832238972187,
      0.01719851605594158,
      -0.032022010535001755,
      -0.06937556713819504,
      -0.04060591384768486,
      -0.10149140655994415,
      -0.03906582295894623,
      -0.06323538720607758,
      0.02125653810799122,
      -0.04635244235396385,
      0.021316247060894966,
      -0.040945492684841156,
      0.051720164716243744,
      0.043615471571683884,
      0.013560496270656586,
      -0.0661718025803566,
      0.029098903760313988,
      -0.03625224530696869,
      -0.02463334985077381,
      0.01066136546432972,
      -0.01178085058927536,
      0.009526383131742477,
      0.05548057705163956,
      -0.07520238310098648,
      -0.03147238865494728,
      0.008952167816460133,
      0.03620440885424614,
      -0.09223917871713638,
      -0.01648109033703804,
      -0.024725696071982384,
      0.0018435806268826127,
      0.10470671206712723,
      0.015285303816199303,
      -0.037132710218429565,
      -0.031184980645775795,
      0.07343071699142456,
      -0.008854766376316547,
      0.033345822244882584,
      -0.05841352790594101,
      0.02774614468216896,
      -0.0448954701423645,
      0.00011068544699810445,
      0.02483528107404709,
      0.03918831795454025,
      0.015022588893771172,
      -0.05858670547604561,
      0.017859328538179398,
      -0.04379346966743469,
      0.016709614545106888,
      -0.019605474546551704,
      -0.007599612697958946,
      -0.005270309280604124,
      -0.011593133211135864,
      -0.053989674896001816,
      -0.0199249517172575,
      -0.008321404457092285,
      0.011550476774573326,
      0.03951558843255043
    ],
    [
      -0.031276293098926544,
      0.030332015827298164,
      0.03905972093343735,
      -0.0010709703201428056,
      -0.06644074618816376,
      -0.0753123015165329,
      -0.03125311806797981,
      -0.033188290894031525,
      -0.025865107774734497,
      0.02500859647989273,
      0.005699610337615013,
      -0.009801662527024746,
      -0.10246111452579498,
      -0.03200880438089371,
      0.009088287129998207,
      0.0583992563188076,
      0.03831461817026138,
      -0.03686283156275749,
      0.0289893951267004,
      0.07582206279039383,
      -0.03670278564095497,
      -0.060806307941675186,
      -0.12710635364055634,
      -0.02510162815451622,
      0.08690667897462845,
      0.014720217324793339,
      -0.02432354725897312,
      0.021008655428886414,
      -0.039191242307424545,
      -0.08932189643383026,
      -0.031124157831072807,
      -0.015127896331250668,
      0.050435420125722885,
      -0.0023098750971257687,
      -0.004793789703398943,
      0.04248639568686485,
      -0.09003885090351105,
      0.055096760392189026,
      0.02585464157164097,
      0.016856703907251358,
      0.07081420719623566,
      -0.021911095827817917,
      -0.004597921390086412,
      0.009887642227113247,
      0.010582626797258854,
      0.06536202877759933,
      -0.017039312049746513,
      0.009765778668224812,
      -0.022245991975069046,
      0.025980742648243904,
      -0.013098115101456642,
      0.009683781303465366,
      0.018180033192038536,
      0.026448151096701622,
      0.05536787211894989,
      -0.007799380924552679,
      0.019446684047579765,
      0.009287532418966293,
      -0.11232232302427292,
      0.04902864247560501,
      0.04863555356860161,
      0.002901750151067972,
      -0.01844157837331295,
      -0.05589289963245392,
      -0.040308915078639984,
      -0.03588937595486641,
      0.01972397416830063,
      0.0012195247691124678,
      -0.0010156490607187152,
      -0.06086913123726845,
      -0.03961459547281265,
      -0.011560054495930672,
      -0.021742545068264008,
      -0.05206330120563507,
      0.031941283494234085,
      -0.03471215441823006,
      -0.03855695202946663,
      0.035504937171936035,
      0.024779461324214935,
      -0.043901845812797546,
      -0.06258945912122726,
      0.027943676337599754,
      -0.03186853229999542,
      0.07207269221544266,
      0.0803828090429306,
      0.023299628868699074,
      0.009837201796472073,
      0.03511073440313339,
      0.05455837771296501,
      0.0326802060008049,
      -0.022000752389431,
      0.03487195447087288,
      0.015187567099928856,
      -0.060688167810440063,
      0.07841984927654266,
      -0.03788653388619423,
      0.017339715734124184,
      -0.007431988604366779,
      0.05751723051071167,
      -0.015545454807579517,
      0.05714236944913864,
      -0.024296190589666367,
      0.0065160528756678104,
      -0.03800015524029732,
      -0.013448303565382957,
      -0.013986646197736263,
      0.015221210196614265,
      -0.08022720366716385,
      0.011355890892446041,
      -0.024935415014624596,
      -0.005252673290669918,
      0.0029029659926891327,
      0.0010220626136288047,
      -0.06625740230083466,
      -0.05222395807504654,
      0.0847325250506401,
      -0.01830158941447735,
      -0.03262370452284813,
      -0.025359176099300385,
      -0.03375925496220589,
      0.024197014048695564,
      -0.04357156530022621,
      -0.019285086542367935,
      0.010203966870903969,
      -0.04848504066467285,
      -0.06271403282880783,
      -0.043362095952034,
      0.04019717872142792,
      0.0375104658305645,
      -0.046451322734355927,
      -0.05255598947405815,
      0.054996244609355927,
      -0.049607325345277786,
      0.04095049202442169,
      0.020554061979055405,
      -0.031092675402760506,
      -0.010389710776507854,
      -0.011356920935213566,
      0.10612910985946655,
      -0.013148087076842785,
      0.01454588770866394,
      0.041073672473430634,
      0.04034637659788132,
      -0.08627523481845856,
      0.01863950677216053,
      -0.07706765085458755,
      -0.12917517125606537,
      0.012298908084630966,
      0.036004167050123215,
      0.03201456740498543,
      0.039954330772161484,
      0.03372608870267868,
      -0.04014727100729942,
      -0.015716060996055603,
      -0.06566280126571655,
      0.06998269259929657,
      0.021068071946501732,
      -0.06862204521894455,
      -0.012990627437829971,
      -0.01623937301337719,
      -0.03450215607881546,
      0.022270567715168,
      0.04836481809616089,
      -0.08154512196779251,
      -0.11092893034219742,
      0.00949922576546669,
      -0.023689880967140198,
      -0.07784392684698105,
      -0.046390190720558167,
      0.028325285762548447,
      -0.026526451110839844,
      -0.05429200455546379,
      0.008268061093986034,
      -0.06960029900074005,
      -0.03367128223180771,
      -0.018136516213417053,
      0.002716278890147805,
      -0.031077489256858826,
      -0.055168598890304565,
      -0.047971319407224655,
      -0.007856106385588646,
      0.06521909683942795,
      -0.018494751304388046,
      -0.010876081883907318,
      -0.02979188971221447,
      -0.029603688046336174,
      0.013166229240596294,
      0.049637965857982635,
      0.08719559758901596,
      0.041899554431438446,
      -0.04794180393218994,
      -0.019955404102802277,
      -0.04961506277322769,
      -0.03843724727630615,
      0.05759897083044052,
      -0.06585432589054108,
      -0.015397025272250175,
      0.04726513847708702,
      -0.0061248368583619595,
      0.024199655279517174,
      0.004116888158023357,
      -0.012953406199812889,
      0.00952162779867649,
      0.010830814950168133,
      0.009465599432587624,
      -0.03705102950334549,
      0.027211390435695648,
      0.041090965270996094,
      -0.029541803523898125,
      -0.01205821055918932,
      0.02456618845462799,
      -0.00905966479331255,
      -0.016424350440502167,
      0.07736146450042725,
      -0.006619917694479227,
      0.00940378662198782,
      -0.033938709646463394,
      0.004910319112241268,
      -0.01435551606118679,
      -0.08883772045373917,
      -0.08839098364114761,
      0.016263330355286598,
      -0.013280858285725117,
      0.017880385741591454,
      0.01601385325193405,
      -0.06728826463222504,
      0.0764981284737587,
      0.03557493910193443,
      -0.03156774863600731,
      0.022129347547888756,
      -0.09035288542509079,
      -0.02382850833237171,
      -0.018887747079133987,
      -0.005045396741479635,
      0.04132162779569626,
      0.002350791357457638,
      -0.0103162731975317,
      -0.06011250615119934,
      0.01099133025854826,
      0.05789856240153313,
      0.004769370425492525,
      -0.031225841492414474,
      0.042280927300453186,
      -0.061678897589445114,
      -0.07093018293380737,
      0.03679703548550606,
      -0.05122189223766327,
      -0.045132093131542206,
      0.0880734771490097,
      -0.023563412949442863,
      0.036695584654808044,
      -0.07258325070142746,
      -0.02698514796793461,
      0.015765273943543434,
      0.0034647206775844097,
      -0.11170860379934311,
      -0.044556763023138046,
      -0.002255725674331188,
      -0.05855558440089226,
      0.14841939508914948,
      0.035179730504751205,
      0.0314018689095974,
      0.08502276986837387,
      -0.04294893145561218,
      -0.019191699102520943,
      0.012752856127917767,
      0.029807139188051224,
      0.0016999568324536085,
      -0.01700081303715706,
      -0.00038713501999154687,
      0.0409175269305706,
      5.0086538976756856e-05,
      0.019770251587033272,
      0.011701618321239948,
      0.021702609956264496,
      -0.008782696910202503,
      0.03264949098229408,
      0.016764292493462563,
      -0.008467243984341621,
      -0.10555310547351837,
      -0.015073440037667751,
      0.08155138790607452,
      -0.05341441184282303,
      0.008835124783217907,
      0.0018021684372797608,
      0.06581137329339981,
      0.0025355624966323376,
      0.04815280809998512,
      0.03335323929786682,
      -0.023857904598116875,
      0.0605243444442749,
      0.05074756219983101,
      0.02176741696894169,
      -0.053556401282548904,
      0.03324182704091072,
      -0.013010649010539055,
      0.0007946702535264194,
      -0.03370272368192673,
      -0.03595443442463875,
      -0.008111382834613323,
      0.0057468959130346775,
      0.007593439891934395,
      0.026310903951525688,
      0.00038588285678997636,
      -0.006512992549687624,
      -0.01696210354566574,
      -0.025150662288069725,
      -0.019326837733387947,
      -0.06171213090419769,
      -0.000514189072418958,
      0.03525899723172188,
      0.03190086781978607,
      0.059322331100702286,
      -0.01560325175523758,
      0.016201673075556755,
      -0.03561917319893837,
      -0.08885103464126587,
      0.021234430372714996,
      0.06668911874294281,
      0.048650264739990234,
      -0.0019540961366146803,
      -0.005630891304463148,
      0.04386743903160095,
      -0.032186947762966156,
      -0.09681634604930878,
      0.001195988617837429,
      -0.015209105797111988,
      -0.02366977371275425,
      -0.02774258516728878,
      -0.043586354702711105,
      0.06049778684973717,
      -0.06146173179149628,
      -0.002807400655001402,
      0.0369747132062912,
      0.0343935489654541,
      -0.005492556840181351,
      0.06389906257390976,
      -0.05977018550038338,
      -0.0204938892275095,
      -0.01145995408296585,
      0.048192497342824936,
      -0.0036775441840291023,
      0.016216237097978592,
      -0.08776137232780457,
      -0.013482674024999142,
      0.005549201276153326,
      0.011114352382719517,
      -0.055899422615766525,
      0.0602022148668766,
      -0.02866429090499878,
      0.022266387939453125,
      -0.01777288317680359,
      -0.05308762565255165,
      0.07942713052034378,
      0.02468097023665905,
      -0.035232461988925934,
      -0.02453695610165596,
      -0.00307849352248013,
      0.08702392131090164,
      0.012199264951050282,
      -0.024654652923345566,
      0.09702715277671814,
      0.010752453468739986,
      -0.027130642905831337,
      -0.0015632541617378592,
      -0.0017579484265297651,
      0.02316264808177948,
      0.09233573824167252,
      -0.11426094174385071,
      -0.02629311941564083,
      -0.06171702593564987,
      0.019197331741452217,
      -0.028853775933384895,
      -0.04149748384952545,
      0.0008665909408591688,
      -0.005460681393742561,
      -0.01412633154541254,
      0.039875417947769165,
      -0.04299451410770416,
      0.0074624186381697655,
      0.08937017619609833,
      -0.08348651230335236,
      -0.015072435140609741,
      0.031757619231939316,
      0.008894413709640503,
      -0.08321256190538406,
      -0.06744176894426346,
      -0.08470294624567032,
      -0.0463235042989254,
      -0.026470595970749855,
      -0.05199369788169861,
      -0.023070042952895164,
      -0.0650196298956871,
      -0.0233213659375906,
      -0.06556650251150131,
      0.07539110630750656,
      -0.011573669500648975,
      -0.02853211760520935,
      0.03521859645843506,
      0.03239281475543976,
      -0.001929008518345654,
      0.03944970294833183,
      -0.045001160353422165,
      -0.00041268495260737836,
      0.03889266029000282,
      -0.02968907542526722,
      0.08416008949279785,
      -0.11124421656131744,
      -0.02488836646080017,
      0.005923647433519363,
      -0.03592224046587944,
      -0.025615224614739418,
      0.054413869976997375,
      0.06739311665296555,
      0.03589930757880211,
      0.0007386252982541919,
      -0.0439932681620121,
      -0.03715338557958603,
      0.0044781905598938465,
      0.04161601513624191,
      0.03363355994224548,
      -0.05474425479769707,
      0.0634102001786232,
      0.006375012919306755,
      0.09442510455846786,
      0.021136341616511345,
      -6.276088970480487e-05,
      0.06742271780967712,
      0.067523293197155,
      -0.06884119659662247,
      -0.04695869982242584,
      0.023261018097400665,
      0.028954820707440376,
      0.022824786603450775,
      -0.053730182349681854,
      0.008806871250271797,
      -0.00630123820155859,
      -0.07740983366966248,
      -0.016580937430262566,
      0.08451152592897415,
      -0.026363546028733253,
      0.09694191813468933,
      -0.0239536315202713,
      -0.006759119685739279,
      -0.052551764994859695,
      0.020874815061688423,
      0.0032930132001638412,
      -0.0002822935930453241,
      0.025990741327404976,
      0.013514652848243713,
      -0.0402386337518692,
      -0.04307803884148598,
      0.011836019344627857,
      0.04549434781074524,
      0.05251259729266167,
      0.016627714037895203,
      0.012829984538257122,
      -0.04707701504230499,
      0.00031378911808133125,
      0.06372323632240295,
      0.029472297057509422,
      0.014246528036892414,
      0.052499920129776,
      0.007235380820930004,
      0.05442747846245766,
      0.028653452172875404,
      0.044158972799777985,
      -0.012774543836712837,
      -0.05811845138669014,
      0.02331487461924553,
      -0.032438043504953384,
      -0.052309222519397736,
      -0.026179727166891098,
      0.05002318322658539,
      -0.003722571535035968,
      0.02318543754518032,
      0.0622040256857872,
      -0.022207431495189667,
      -0.04884931445121765,
      0.03849854692816734,
      0.03992115706205368,
      -0.041864048689603806,
      -0.00666387053206563,
      -0.049804266542196274,
      -0.028296178206801414,
      -0.022620977833867073,
      0.043340444564819336,
      0.07813850790262222,
      -0.0161368940025568,
      -0.008988050743937492,
      -0.07566944509744644,
      0.01895652525126934,
      -0.02861974947154522,
      -0.004875579848885536,
      0.004809327889233828,
      -0.04456499218940735,
      -0.023639602586627007,
      0.048503220081329346,
      0.07485242933034897,
      0.06827273964881897,
      -0.006566069554537535,
      -0.03029218688607216,
      -0.048673029989004135
    ],
    [
      0.053628016263246536,
      0.003438481129705906,
      0.07511264830827713,
      0.011494050733745098,
      -0.023238714784383774,
      0.023698503151535988,
      0.0701327994465828,
      0.023509567603468895,
      0.03892144560813904,
      -0.07150465250015259,
      0.011214075610041618,
      -0.07404913753271103,
      -0.03950190544128418,
      -0.10755069553852081,
      -0.04273924604058266,
      -0.06833184510469437,
      -0.08337027579545975,
      -0.03157692030072212,
      -0.006980019621551037,
      0.013980125077068806,
      0.06516633182764053,
      -0.009268274530768394,
      0.022098219022154808,
      -0.014028512872755527,
      -0.008287128992378712,
      0.022856706753373146,
      -0.029291430488228798,
      -0.028955917805433273,
      -0.03204880654811859,
      0.05200071260333061,
      0.011112022213637829,
      0.07885567098855972,
      -0.012453028932213783,
      -0.04968900978565216,
      0.05991460755467415,
      -0.03309938311576843,
      -0.028303198516368866,
      -0.029761839658021927,
      0.010660579428076744,
      0.050007414072752,
      0.04607825353741646,
      -0.08147838711738586,
      0.02736523747444153,
      -0.0030276156030595303,
      -0.014880983158946037,
      0.021039113402366638,
      -0.05995035171508789,
      -0.056109603494405746,
      0.009426725097000599,
      -0.06655580550432205,
      -0.055629629641771317,
      0.033058732748031616,
      -0.010520916432142258,
      -0.004684519488364458,
      0.03770344331860542,
      0.023789050057530403,
      0.01966352015733719,
      -0.06824926286935806,
      0.003779764287173748,
      0.031040647998452187,
      0.024419689550995827,
      -0.06232789531350136,
      0.022022312507033348,
      0.0039593009278178215,
      -0.09203214198350906,
      0.03724197298288345,
      -0.006966315675526857,
      -0.024859445169568062,
      0.0785326138138771,
      -0.06049152463674545,
      -0.012792067602276802,
      -0.05147985741496086,
      -0.03521271049976349,
      -0.07487688958644867,
      0.0007811657851561904,
      -0.05020894482731819,
      0.05550495162606239,
      0.005126192234456539,
      -0.06712537258863449,
      0.09254086762666702,
      0.005099385045468807,
      0.11251749843358994,
      0.006414379924535751,
      0.021929748356342316,
      -0.0465519055724144,
      0.07096637785434723,
      -0.0269489623606205,
      0.006845730356872082,
      -0.0059495726600289345,
      -0.006749898660928011,
      -0.0273257028311491,
      -0.023031530901789665,
      0.011780851520597935,
      0.08668734133243561,
      -0.004502435214817524,
      -0.034865088760852814,
      0.06443767994642258,
      -0.022092534229159355,
      -0.007981687784194946,
      0.031026527285575867,
      0.01374303363263607,
      -0.01944894716143608,
      0.0014981681015342474,
      -0.054312814027071,
      0.05326928198337555,
      0.007179642096161842,
      -0.023196041584014893,
      -0.03781551122665405,
      -0.07844219356775284,
      -0.03812761977314949,
      0.05470076948404312,
      -0.1256633698940277,
      0.044256288558244705,
      -0.020416388288140297,
      -0.04118400812149048,
      -0.08616743236780167,
      0.0045463042333722115,
      0.02197425439953804,
      -0.0085099833086133,
      -0.003615794237703085,
      -0.03104005753993988,
      0.07385042309761047,
      -0.0037719407118856907,
      -0.003576904069632292,
      0.012650812976062298,
      -0.046510230749845505,
      -0.03022867813706398,
      0.02562522515654564,
      0.005277024582028389,
      -0.046124499291181564,
      0.013551297597587109,
      0.04473269358277321,
      0.048762209713459015,
      -0.052492570132017136,
      0.029021287336945534,
      -0.07242607325315475,
      0.015779171139001846,
      -0.05637667700648308,
      0.05777791142463684,
      0.017356600612401962,
      -0.005853250157088041,
      0.015230348333716393,
      -0.030829131603240967,
      0.06661459803581238,
      -0.07198797166347504,
      0.10608243942260742,
      -0.04770446941256523,
      0.05009368434548378,
      0.007165998220443726,
      0.03449249267578125,
      -0.009816629812121391,
      -0.016468025743961334,
      -0.03985917568206787,
      -0.0030929858330637217,
      0.020547227934002876,
      0.018207134678959846,
      0.03484802693128586,
      0.02692824974656105,
      0.059639282524585724,
      -0.030341623350977898,
      0.012008395977318287,
      -0.03073161654174328,
      0.13386240601539612,
      0.00627503264695406,
      0.01626238040626049,
      -0.0037249261513352394,
      0.04429079219698906,
      0.03099062107503414,
      0.13630007207393646,
      -0.012805859558284283,
      -0.016935894265770912,
      -0.0029987075831741095,
      -0.02193203754723072,
      0.0026936002541333437,
      -0.006017626728862524,
      -0.05343284085392952,
      0.026167210191488266,
      0.08551550656557083,
      0.015444009564816952,
      -0.047294363379478455,
      -0.0268978513777256,
      -0.04603372514247894,
      0.023910462856292725,
      -0.04536677896976471,
      0.001735216355882585,
      -0.045198891311883926,
      0.02587476745247841,
      -0.07883039861917496,
      0.03262266889214516,
      0.019305270165205002,
      -0.05702493339776993,
      0.06364130973815918,
      0.06427471339702606,
      -0.010584081523120403,
      -0.02057996205985546,
      -0.09850522130727768,
      -0.009745284914970398,
      0.03995590656995773,
      0.00022179305960889906,
      0.026727939024567604,
      0.03995012119412422,
      0.031365860253572464,
      0.03079325146973133,
      -0.009649260900914669,
      0.05064759403467178,
      -0.032707907259464264,
      -0.0294494666159153,
      0.0011167695047333837,
      -0.05039844289422035,
      -0.03572118654847145,
      -0.011554719880223274,
      0.06561142951250076,
      0.006240488030016422,
      -0.06783132255077362,
      -0.012074495665729046,
      -0.05654708668589592,
      -0.035639047622680664,
      -0.034481022506952286,
      -0.0500311553478241,
      0.012415966019034386,
      -0.05320419371128082,
      0.024050980806350708,
      0.007868267595767975,
      0.016775110736489296,
      0.0317687913775444,
      -0.022382020950317383,
      0.039900537580251694,
      0.0008444316335953772,
      -0.008023382164537907,
      0.03406424820423126,
      0.0004973195027559996,
      -0.0014612410450354218,
      -0.03067021444439888,
      -0.05104478448629379,
      0.045752882957458496,
      -0.01964499056339264,
      -0.06370983272790909,
      -0.004119071643799543,
      -0.03582031652331352,
      -0.010026248171925545,
      -0.02714054100215435,
      -0.031969618052244186,
      0.07808579504489899,
      -0.02720346674323082,
      0.0020997647661715746,
      0.00020075321663171053,
      0.00453138817101717,
      -0.00024423800641670823,
      0.03602927550673485,
      0.0022950368002057076,
      -0.06688404828310013,
      -0.06665470451116562,
      0.06439503282308578,
      0.025330590084195137,
      0.0998406931757927,
      0.00843022856861353,
      -0.01924445480108261,
      0.011634639464318752,
      -0.02753765508532524,
      -0.01816185750067234,
      0.013328775763511658,
      0.08948399126529694,
      -0.03360960632562637,
      0.05744454264640808,
      -0.13117539882659912,
      0.0592738576233387,
      -0.06726180762052536,
      -0.06869180500507355,
      0.031424447894096375,
      0.08191148191690445,
      -0.06798864156007767,
      0.10512567311525345,
      -0.059569209814071655,
      0.05112006887793541,
      0.0495338998734951,
      0.0986274853348732,
      0.058056168258190155,
      0.0008808101993054152,
      -0.05606521666049957,
      -0.0809457004070282,
      -0.036796726286411285,
      0.025037182494997978,
      -0.042690470814704895,
      0.012239255011081696,
      0.03230656310915947,
      0.0028189767617732286,
      -0.005732116289436817,
      -0.09755054116249084,
      -0.000732103711925447,
      0.033615365624427795,
      0.022866781800985336,
      -0.03415660932660103,
      -0.007909788750112057,
      0.01794883795082569,
      -0.09549243003129959,
      -0.012563535012304783,
      -0.020277583971619606,
      0.02356073632836342,
      0.008677246049046516,
      0.0970860943198204,
      -0.04838975518941879,
      0.07426048070192337,
      0.053452204912900925,
      0.015451888553798199,
      0.013138366863131523,
      -0.012761912308633327,
      -0.12471014261245728,
      0.01984037458896637,
      0.021465837955474854,
      0.03439894691109657,
      0.005702497903257608,
      0.02445077709853649,
      -0.04103437811136246,
      -0.052003804594278336,
      0.04441329836845398,
      0.0009619829361326993,
      -0.017655175179243088,
      -0.004023510962724686,
      0.08674125373363495,
      -0.013228216208517551,
      -0.044196851551532745,
      -0.01747376099228859,
      -0.005583541002124548,
      -0.055210426449775696,
      0.03878284618258476,
      0.058251481503248215,
      0.005779313389211893,
      0.053020719438791275,
      0.024280870333313942,
      -0.0964680016040802,
      0.058894310146570206,
      -0.034405969083309174,
      0.06496475636959076,
      -0.04343916103243828,
      -0.07810336351394653,
      -0.05029210448265076,
      0.005007346160709858,
      0.07452032715082169,
      0.010788694955408573,
      -0.029110850766301155,
      0.011463808827102184,
      -0.016752317547798157,
      0.022417442873120308,
      0.036809179931879044,
      0.055728036910295486,
      0.07757189869880676,
      -0.021049411967396736,
      -0.0134170763194561,
      -0.04902992025017738,
      -0.005317256785929203,
      -0.011497581377625465,
      0.009072702378034592,
      -0.00431545777246356,
      0.0295161884278059,
      -0.04056122899055481,
      0.06208586320281029,
      0.0052250889129936695,
      -0.02067537233233452,
      -0.05121299624443054,
      0.01158049050718546,
      0.022327927872538567,
      -0.019906682893633842,
      0.0028276171069592237,
      0.0295402929186821,
      0.08539328724145889,
      -0.011243562214076519,
      -0.02206018567085266,
      0.00919908843934536,
      0.021361371502280235,
      -0.05811907723546028,
      0.01619558036327362,
      0.04890771582722664,
      0.004697130527347326,
      -0.10867604613304138,
      -0.03034227341413498,
      -0.019536763429641724,
      0.024657590314745903,
      -0.019762791693210602,
      -0.023157821968197823,
      -0.023212647065520287,
      0.08827928453683853,
      0.08032801002264023,
      0.024407068267464638,
      0.011128328740596771,
      -0.00229975045658648,
      0.06782466918230057,
      -0.009542729705572128,
      -0.00699699018150568,
      0.038047391921281815,
      0.031384412199258804,
      -0.014192975126206875,
      -0.03626479580998421,
      -0.01522861234843731,
      -0.09203024953603745,
      -0.014480741694569588,
      0.019947152584791183,
      0.039661895483732224,
      0.03419987112283707,
      -0.012806677259504795,
      -0.02530534379184246,
      0.08909405022859573,
      0.031362384557724,
      0.04407987371087074,
      0.01303732581436634,
      0.027975914999842644,
      -0.006364387925714254,
      0.12310852110385895,
      0.02062896639108658,
      -0.07799811661243439,
      -0.0070867897011339664,
      0.021768324077129364,
      0.0027706781402230263,
      0.007075251080095768,
      -0.07953812927007675,
      0.02574184350669384,
      -0.053127359598875046,
      -0.03235020115971565,
      -0.034540385007858276,
      -0.11267804354429245,
      0.10300881415605545,
      0.02944205515086651,
      0.015690116211771965,
      -0.02374175749719143,
      -0.030178233981132507,
      0.03215869143605232,
      0.0823456197977066,
      -0.005343336146324873,
      0.02348320372402668,
      0.003203311236575246,
      0.07341933250427246,
      0.007611813489347696,
      -0.06115114688873291,
      -0.01826285570859909,
      -0.0712394043803215,
      -0.003810370806604624,
      -0.008184819482266903,
      0.06497439742088318,
      -0.07776369899511337,
      0.037139326333999634,
      0.0017079818062484264,
      -0.048440299928188324,
      -0.008524938486516476,
      -0.05522298067808151,
      0.005631743930280209,
      -0.03569304943084717,
      0.019478868693113327,
      -0.020091617479920387,
      0.03035234659910202,
      0.11978530138731003,
      0.01303758192807436,
      0.017487458884716034,
      0.047228146344423294,
      -0.004618470557034016,
      -0.022972233593463898,
      0.026495063677430153,
      -0.019129954278469086,
      -0.03805398568511009,
      -0.040880244225263596,
      -0.009809096343815327,
      0.042828306555747986,
      -0.011039192788302898,
      0.03608205169439316,
      -0.0029255703557282686,
      -0.006028129253536463,
      -0.016692979261279106,
      -0.023963240906596184,
      0.017845124006271362,
      0.002798709087073803,
      0.019350692629814148,
      0.013362889178097248,
      -0.01485099270939827,
      0.05882852524518967,
      -0.06460721790790558,
      0.06022975593805313,
      -0.02898469939827919,
      -0.03721029311418533,
      0.03658237308263779,
      0.04253493621945381,
      -0.030300799757242203,
      -0.03367113694548607,
      -0.055722981691360474,
      0.021470123901963234,
      -0.02412577159702778,
      0.001179493498057127,
      -0.0036421490367501974,
      0.06854265928268433,
      0.06440318375825882,
      0.001753208227455616,
      -0.010281072929501534,
      0.03453504666686058,
      -0.01703384518623352,
      0.02079145424067974,
      0.03832852467894554,
      -0.03463529422879219,
      0.016147837042808533,
      -0.01621035300195217,
      -0.036169636994600296,
      0.027462415397167206,
      0.0046815876848995686,
      0.01895676739513874,
      -0.04643283784389496,
      0.045456185936927795,
      0.05528581514954567
    ],
    [
      0.02854098193347454,
      -0.020143089815974236,
      0.0017289977986365557,
      -0.04486801102757454,
      0.12369905412197113,
      -0.10003073513507843,
      0.009006299078464508,
      -0.03811401501297951,
      -0.09207721799612045,
      -0.04981125891208649,
      0.01698898710310459,
      0.09554670006036758,
      0.0069134049117565155,
      -0.010376975871622562,
      -0.002310100942850113,
      -0.013107345439493656,
      0.019908767193555832,
      -0.008338702842593193,
      0.06635960936546326,
      0.10331571847200394,
      0.03340250998735428,
      0.08907786756753922,
      -0.0700698122382164,
      -0.02642783522605896,
      -0.004036488477140665,
      0.06722084432840347,
      -0.060663264244794846,
      -0.0407278798520565,
      -0.028982948511838913,
      -0.04533038288354874,
      -0.01812640391290188,
      -0.0065538519993424416,
      -0.00851068552583456,
      -0.00216512568295002,
      -0.08587593585252762,
      -0.0035739752929657698,
      0.02196529507637024,
      -0.04612887278199196,
      -0.00825332012027502,
      -0.04603876918554306,
      0.02473103068768978,
      0.018745996057987213,
      -0.006367172580212355,
      -0.08300220221281052,
      0.037411049008369446,
      0.04207925498485565,
      -0.03328893706202507,
      0.041657403111457825,
      -0.038113709539175034,
      -0.04006103798747063,
      -0.06927362829446793,
      0.030040757730603218,
      0.01439887098968029,
      -0.036053162068128586,
      0.01481728907674551,
      0.05496471747756004,
      -0.08882845193147659,
      -0.001427165698260069,
      0.08682607859373093,
      -0.03608778119087219,
      0.007938642054796219,
      0.015779130160808563,
      -0.0004843779024668038,
      0.005275162868201733,
      -0.024019936099648476,
      -0.0034587758127599955,
      0.0613764151930809,
      -0.07278013229370117,
      0.08832216262817383,
      0.004196701571345329,
      -0.03408050537109375,
      0.0053907050751149654,
      -0.018791591748595238,
      -0.08084362000226974,
      0.00851893238723278,
      0.03821129351854324,
      0.0014310326660051942,
      0.031187422573566437,
      -0.04621008038520813,
      0.020959557965397835,
      -0.04061109945178032,
      0.020536333322525024,
      0.038413550704717636,
      0.02638004906475544,
      0.024739209562540054,
      0.017680702731013298,
      0.0030678752809762955,
      -0.03820912539958954,
      -0.057024482637643814,
      -0.029020939022302628,
      0.035857897251844406,
      -0.04344324767589569,
      -0.05361931771039963,
      -0.001746607362292707,
      0.05562681704759598,
      0.006524935830384493,
      0.052598197013139725,
      -0.06606333702802658,
      -0.0051715187728405,
      0.07807790488004684,
      0.0657094344496727,
      0.04024175554513931,
      -0.014938265085220337,
      -0.0357239805161953,
      -0.018089108169078827,
      -0.017746949568390846,
      -0.049744606018066406,
      0.023917799815535545,
      -0.006381826475262642,
      0.03714364394545555,
      -0.03173395246267319,
      -0.017720306292176247,
      -0.061552368104457855,
      0.014137983322143555,
      -0.006097585428506136,
      0.02727627381682396,
      -0.11862017214298248,
      0.026828207075595856,
      0.029850035905838013,
      0.04575682058930397,
      -0.00338690634816885,
      -0.007558035664260387,
      -0.028416555374860764,
      0.03880010545253754,
      0.031502727419137955,
      -0.03851699084043503,
      0.032866209745407104,
      -0.046880874782800674,
      -0.01134861446917057,
      0.05013535916805267,
      0.036072202026844025,
      0.023152360692620277,
      -0.0317956805229187,
      -0.04248391464352608,
      -0.04063650965690613,
      0.009347783401608467,
      0.03563389182090759,
      -0.04929105192422867,
      0.07045058161020279,
      0.045671649277210236,
      -0.00812799483537674,
      0.0533028170466423,
      0.022013487294316292,
      0.0033918144181370735,
      0.02566068060696125,
      0.0006680494989268482,
      -0.07231711596250534,
      -0.02075320854783058,
      0.05703293904662132,
      -0.05949915945529938,
      0.06572850048542023,
      -0.09283410757780075,
      0.0228938776999712,
      0.011999256908893585,
      0.012252912856638432,
      0.0573250912129879,
      -0.03177119418978691,
      -0.033516425639390945,
      0.007720676250755787,
      0.08647856116294861,
      0.0319172739982605,
      0.0352904386818409,
      -0.025529948994517326,
      0.034025467932224274,
      -0.03632934391498566,
      0.07014420628547668,
      -0.033891141414642334,
      -0.060353659093379974,
      0.11666804552078247,
      0.008971541188657284,
      -0.007039439398795366,
      0.06284313648939133,
      -0.0751609355211258,
      -0.03384343534708023,
      -0.028653467074036598,
      -0.048635028302669525,
      0.06695910543203354,
      -0.0043484061025083065,
      0.003831482958048582,
      -0.018222006037831306,
      0.01685764640569687,
      -0.02515583671629429,
      -0.00032062974059954286,
      0.02188999578356743,
      0.0289910901337862,
      0.07417265325784683,
      -0.08985216170549393,
      -0.08538539707660675,
      -0.1556803435087204,
      -0.014836776070296764,
      -0.06681028008460999,
      0.057424262166023254,
      0.023868553340435028,
      -0.0006091347895562649,
      0.0002195667620981112,
      -0.021516846492886543,
      0.06501330435276031,
      -0.08393488824367523,
      -0.059747353196144104,
      0.048839177936315536,
      -0.05182452127337456,
      0.003215401666238904,
      0.11310604214668274,
      0.030727729201316833,
      0.04047532379627228,
      -0.08437282592058182,
      -0.07985460013151169,
      -0.029604407027363777,
      0.04175301641225815,
      -0.04876801744103432,
      0.048963479697704315,
      -0.03118501789867878,
      0.014627332799136639,
      -0.04025472328066826,
      0.04218905046582222,
      0.05353403463959694,
      0.06591370701789856,
      0.07106973230838776,
      -0.006479375995695591,
      0.0540127232670784,
      0.03054933249950409,
      0.05152744799852371,
      -0.02364129200577736,
      0.013848990201950073,
      -0.002180184470489621,
      -0.018857484683394432,
      -0.02878652513027191,
      0.01810680888593197,
      -0.131325364112854,
      0.04322434589266777,
      0.035605184733867645,
      -0.046487655490636826,
      -0.10299918800592422,
      0.02225072681903839,
      0.07896488159894943,
      -0.04322328045964241,
      -0.0312001034617424,
      0.009737605229020119,
      -0.006516965571790934,
      0.010574580170214176,
      0.0032239120919257402,
      0.0585750974714756,
      0.06613755226135254,
      0.0150015689432621,
      -0.03300546482205391,
      0.04730714112520218,
      -0.015022045001387596,
      0.016333555802702904,
      -0.0491359606385231,
      -0.03299002721905708,
      0.02718283049762249,
      0.03474649414420128,
      -0.04399258643388748,
      0.05643460527062416,
      -0.035852815955877304,
      -0.02377007156610489,
      -0.06153098866343498,
      0.08025927096605301,
      -0.10825001448392868,
      -0.00018604991782922298,
      0.02198241837322712,
      0.03233952820301056,
      0.00562724843621254,
      -0.046319954097270966,
      -0.07274431735277176,
      0.03495660424232483,
      0.01594320498406887,
      0.08718090504407883,
      -0.056533876806497574,
      0.05581006407737732,
      -0.005038577597588301,
      -0.005546687636524439,
      0.021036429330706596,
      0.05133220553398132,
      0.022615669295191765,
      0.015734005719423294,
      -0.028447920456528664,
      0.012765197083353996,
      -0.08555994927883148,
      0.008246488869190216,
      0.053623493760824203,
      0.05033242329955101,
      0.056268110871315,
      0.09800762683153152,
      0.030149538069963455,
      0.060986850410699844,
      -0.03404390066862106,
      0.11704060435295105,
      0.019972847774624825,
      0.03287427872419357,
      0.07196494936943054,
      -0.016281256452202797,
      0.04362141340970993,
      0.061680376529693604,
      -0.06288798898458481,
      0.04244478419423103,
      -0.026820681989192963,
      0.024532431736588478,
      0.035909056663513184,
      -0.0023949143942445517,
      -0.06482093036174774,
      -0.03241569548845291,
      0.03753636032342911,
      -0.03648944944143295,
      -0.12387669831514359,
      0.04080182686448097,
      -0.016345642507076263,
      -0.039158064872026443,
      0.01621854491531849,
      0.017712250351905823,
      0.03643568977713585,
      -0.0455760583281517,
      -0.0005617021815851331,
      0.021304424852132797,
      0.03593015298247337,
      0.01267174631357193,
      0.008634970523416996,
      -0.04283817484974861,
      0.04718698188662529,
      -0.06654901057481766,
      0.020863458514213562,
      -0.007831638678908348,
      0.018695371225476265,
      -0.010564991272985935,
      -0.03889324143528938,
      0.005701290909200907,
      -0.049342796206474304,
      0.030422572046518326,
      -0.020469170063734055,
      -0.033496979624032974,
      0.05171971023082733,
      -0.09125322848558426,
      0.07581131905317307,
      -0.029471013695001602,
      -0.06853968650102615,
      -0.08301880955696106,
      0.025777505710721016,
      -0.03538764268159866,
      -0.07842180877923965,
      -0.04885268583893776,
      -0.05867735296487808,
      0.0037758343387395144,
      -0.02045365236699581,
      0.04481428861618042,
      0.030884485691785812,
      0.043925438076257706,
      -0.04054882749915123,
      0.0026870029978454113,
      0.003836513264104724,
      -0.0012783193960785866,
      0.0791085883975029,
      0.0361754484474659,
      0.068748340010643,
      0.034575168043375015,
      -0.007060908246785402,
      0.014783762395381927,
      -0.03644672781229019,
      -0.07263548672199249,
      -0.008834254927933216,
      -0.007784129120409489,
      -0.019642872735857964,
      0.037285059690475464,
      0.011461764574050903,
      0.0844215527176857,
      0.02423625998198986,
      -0.01741720922291279,
      0.001493980293162167,
      0.013444830663502216,
      0.044684115797281265,
      0.0038936638738960028,
      -0.037087731063365936,
      -0.025459932163357735,
      0.06088751554489136,
      -0.04139532148838043,
      0.03345997631549835,
      -0.05811699107289314,
      -0.07743658125400543,
      -0.04628637433052063,
      0.0631752461194992,
      0.025284357368946075,
      -0.025455305352807045,
      -0.010819245129823685,
      -0.06450627744197845,
      -0.0427589975297451,
      0.042802829295396805,
      -0.018765809014439583,
      -0.0336780808866024,
      0.02448420599102974,
      -0.08686153590679169,
      -0.11210891604423523,
      0.013370771892368793,
      -0.008739497512578964,
      0.01772373355925083,
      0.03767622634768486,
      0.017867956310510635,
      -0.062140755355358124,
      0.024741077795624733,
      0.035538170486688614,
      -0.02222401835024357,
      0.004568758420646191,
      -0.012845039367675781,
      -0.013147206045687199,
      0.03205083683133125,
      -0.08157813549041748,
      0.013368153013288975,
      0.003928958438336849,
      -0.03673510625958443,
      0.0024471969809383154,
      0.01925528421998024,
      0.002066266257315874,
      0.03404795005917549,
      -0.12103475630283356,
      -0.03899114951491356,
      -0.029225613921880722,
      0.042116258293390274,
      0.0722464844584465,
      -0.045391153544187546,
      -0.01154292467981577,
      0.016270814463496208,
      0.04710553213953972,
      -0.033331166952848434,
      -0.02237473800778389,
      0.05646507814526558,
      -0.033639952540397644,
      -0.0152614526450634,
      0.029050126671791077,
      0.07305313646793365,
      0.03353601321578026,
      0.014206831343472004,
      -0.03232002258300781,
      -0.05280110985040665,
      0.03599581867456436,
      -0.0073235780000686646,
      -0.03906393423676491,
      0.04892406612634659,
      -0.006984249223023653,
      0.05300994589924812,
      0.0002899356768466532,
      -0.04941048100590706,
      0.01758979633450508,
      0.0719890370965004,
      -0.0017672127578407526,
      -0.08887117356061935,
      0.04748978465795517,
      -0.035757262259721756,
      -0.022915346547961235,
      0.04987207055091858,
      0.055958978831768036,
      0.010386954061686993,
      0.04549599811434746,
      -0.03858693689107895,
      0.029742924496531487,
      0.08392889052629471,
      -0.062170375138521194,
      -0.006999694276601076,
      -0.0956621915102005,
      -0.03128514811396599,
      0.04865042120218277,
      0.008547314442694187,
      -0.08110035210847855,
      -0.021901272237300873,
      0.022120388224720955,
      0.006140625104308128,
      -0.03152216970920563,
      -0.08029869198799133,
      0.002638055244460702,
      0.07681503891944885,
      0.008847305551171303,
      -0.041037119925022125,
      0.01229451410472393,
      -0.06858819723129272,
      0.05408690124750137,
      -0.03125326335430145,
      -0.0579104982316494,
      0.10716614127159119,
      -0.06170393526554108,
      -0.036248717457056046,
      0.0698036327958107,
      0.010388301685452461,
      -0.013960001058876514,
      0.01291211973875761,
      -0.0021602846682071686,
      -0.04420946165919304,
      -0.012853199616074562,
      0.030382204800844193,
      0.09625831991434097,
      0.04742366820573807,
      -0.020226452499628067,
      -0.01901904121041298,
      -0.015420177951455116,
      -0.026659095659852028,
      0.03860696777701378,
      -0.07475437223911285,
      0.10906753689050674,
      0.05063886567950249,
      -0.019308876246213913,
      -0.018722278997302055,
      0.014087163843214512,
      0.02763885073363781,
      0.01921870745718479,
      0.032396718859672546,
      0.014140834100544453,
      0.007924342527985573
    ],
    [
      -0.013510838150978088,
      0.01844039373099804,
      -0.0024345170240849257,
      -0.05513817444443703,
      0.00740407919511199,
      -0.05220241844654083,
      0.06441549956798553,
      0.07877957075834274,
      0.009991628117859364,
      0.059522416442632675,
      -0.020757034420967102,
      0.03784370794892311,
      -0.002292829332873225,
      -0.039992108941078186,
      0.04939108341932297,
      -0.06650876253843307,
      -0.006493275053799152,
      0.020767701789736748,
      0.02123253047466278,
      0.0882784053683281,
      -0.006825016345828772,
      0.045491643249988556,
      0.06478626281023026,
      -0.013504811562597752,
      0.021634390577673912,
      0.07135503739118576,
      -0.025832973420619965,
      0.006038414314389229,
      -0.02841946855187416,
      -0.003029833547770977,
      -0.0017928844317793846,
      -0.02514166757464409,
      -0.02264266461133957,
      0.03493316099047661,
      0.03202660009264946,
      -0.02919333055615425,
      -0.08129727095365524,
      0.03076419048011303,
      -0.02787623554468155,
      0.041715607047080994,
      0.08523476868867874,
      0.01909426786005497,
      -0.027608437463641167,
      0.01221108715981245,
      -0.028334250673651695,
      0.002909979084506631,
      -0.05235825479030609,
      -0.06108003482222557,
      -0.06577073037624359,
      0.020504070445895195,
      0.08303806185722351,
      -0.02011187933385372,
      0.0782417580485344,
      -0.10277891159057617,
      -0.020098919048905373,
      -0.0513746552169323,
      -0.02715480513870716,
      0.06919415295124054,
      -0.05774810165166855,
      -0.006342223845422268,
      -0.020951641723513603,
      -0.04274454340338707,
      -0.014198880642652512,
      -0.028665630146861076,
      0.045395854860544205,
      -0.02094564214348793,
      0.011755568906664848,
      -0.006545473821461201,
      -0.04934179037809372,
      0.06779187172651291,
      -0.001837924006395042,
      0.05291029065847397,
      0.028426384553313255,
      0.00766359455883503,
      -0.07027777284383774,
      0.017363116145133972,
      3.6837460356764495e-05,
      0.028408246114850044,
      -0.014264600351452827,
      0.032163865864276886,
      0.03014630638062954,
      -0.12162698805332184,
      0.00016054935986176133,
      -0.032794516533613205,
      -0.01940096542239189,
      -0.0006339034298434854,
      -0.044220540672540665,
      0.10400232672691345,
      -0.015915384516119957,
      0.051332827657461166,
      0.06561693549156189,
      0.02334701456129551,
      -0.018374459818005562,
      0.004027873743325472,
      -0.001868107938207686,
      -0.04444971680641174,
      0.03948217257857323,
      -0.001073221443220973,
      0.02838120236992836,
      0.03672134876251221,
      0.09134303033351898,
      -0.0025280534755438566,
      0.029491981491446495,
      -0.017954427748918533,
      0.06000515818595886,
      -0.06300895661115646,
      -0.09311511367559433,
      -0.06520450860261917,
      0.008342248387634754,
      -0.022508542984724045,
      0.011704512871801853,
      0.04265598580241203,
      0.041537512093782425,
      0.011326620355248451,
      0.06106456741690636,
      -0.12858794629573822,
      -0.03247659280896187,
      -0.007474713958799839,
      0.001600567135028541,
      -0.040869854390621185,
      0.03166843578219414,
      0.0069217137061059475,
      0.002709815977141261,
      0.033749014139175415,
      0.012061379849910736,
      0.0973048284649849,
      0.08101160079240799,
      0.05701053515076637,
      -0.05926667898893356,
      0.07035434246063232,
      -0.08136657625436783,
      0.05617855116724968,
      -0.017351729795336723,
      -0.011782520450651646,
      0.014123772270977497,
      0.013180803507566452,
      -0.06137733906507492,
      0.007022282108664513,
      0.012258300557732582,
      0.00480139022693038,
      0.008431770838797092,
      0.11950076371431351,
      -0.0017169008497148752,
      -0.07663975656032562,
      -0.021927781403064728,
      0.0332016758620739,
      0.015811964869499207,
      -0.039540208876132965,
      0.018468396738171577,
      0.03254752606153488,
      -0.04047248512506485,
      0.045881789177656174,
      -0.009575635194778442,
      -0.04323790967464447,
      -0.027054574340581894,
      0.04682484269142151,
      -0.004601725842803717,
      -0.06534760445356369,
      -0.04120480269193649,
      -0.05444109067320824,
      0.03321666643023491,
      -0.06467770040035248,
      0.024019185453653336,
      -0.08992493152618408,
      -0.010616458021104336,
      0.0816962942481041,
      -0.03846132010221481,
      0.022085921838879585,
      -0.01341970358043909,
      -0.009594766423106194,
      0.06256794184446335,
      -0.0438687801361084,
      -0.029380692169070244,
      0.054969388991594315,
      0.0592912957072258,
      0.04692234843969345,
      0.03677176684141159,
      -0.03692660480737686,
      0.007822619751095772,
      0.0341118760406971,
      0.03908401355147362,
      -0.027245664969086647,
      0.029778407886624336,
      -0.02614450827240944,
      0.020718391984701157,
      -0.024311911314725876,
      0.021291043609380722,
      0.0025827218778431416,
      0.02217891812324524,
      0.01566811092197895,
      0.0326085090637207,
      0.07859517633914948,
      0.09565739333629608,
      -0.016680244356393814,
      0.010154598392546177,
      -0.020644662901759148,
      -0.0376194566488266,
      0.04914473742246628,
      0.02036295086145401,
      0.08289375901222229,
      -0.057019442319869995,
      -0.0037436492275446653,
      0.022766057401895523,
      0.06026872247457504,
      -0.001291371532715857,
      0.00036255005397833884,
      -0.04298092797398567,
      0.03714923933148384,
      0.0076605104841291904,
      -0.04172557592391968,
      0.021045535802841187,
      0.08858165144920349,
      -0.038058146834373474,
      -0.012438936159014702,
      0.10090183466672897,
      -0.05486012250185013,
      0.044208258390426636,
      0.10274086147546768,
      -0.017158057540655136,
      -0.024053189903497696,
      -0.0028205818962305784,
      0.07329617440700531,
      0.00019151694141328335,
      0.007749417331069708,
      -0.03534749895334244,
      -0.004313006065785885,
      0.051147084683179855,
      0.0393524207174778,
      0.004877456929534674,
      0.017542146146297455,
      0.01815473847091198,
      -0.040393490344285965,
      -0.05527976155281067,
      -0.005252748727798462,
      -0.044213756918907166,
      0.08890410512685776,
      -0.04452447593212128,
      0.021563945338129997,
      0.04475098475813866,
      -0.030195118859410286,
      0.014541064389050007,
      -0.04561199992895126,
      -0.039845023304224014,
      0.02076795883476734,
      0.07016254216432571,
      0.002392407041043043,
      -0.004419800825417042,
      -0.008308212272822857,
      0.015518330037593842,
      0.049519382417201996,
      -0.0021950758527964354,
      -0.031080760061740875,
      -0.022279059514403343,
      -0.016584334895014763,
      0.002867024391889572,
      0.005522545892745256,
      -0.06675776094198227,
      -0.09304287284612656,
      -0.1474863737821579,
      0.08285685628652573,
      -0.05190793052315712,
      0.07856990396976471,
      0.0411970354616642,
      0.05673930421471596,
      0.034678008407354355,
      0.03953239321708679,
      0.03533892706036568,
      -0.0452672578394413,
      0.028183814138174057,
      0.0770370215177536,
      0.05638647824525833,
      -0.0362728089094162,
      -0.06225591525435448,
      -0.01258816197514534,
      -0.07707076519727707,
      -0.04933180287480354,
      0.04305828735232353,
      -0.058838602155447006,
      -0.02200924977660179,
      -0.07218827307224274,
      0.019688617438077927,
      0.027664868161082268,
      -0.009705043397843838,
      0.03230946511030197,
      -0.05078037455677986,
      0.04287722334265709,
      0.00248505175113678,
      0.03471044823527336,
      -0.02305283211171627,
      -0.01857798732817173,
      0.02876092679798603,
      -0.003764401189982891,
      -0.003963756840676069,
      -0.022313376888632774,
      -0.049115780740976334,
      -0.044128842651844025,
      0.004478947259485722,
      -0.03385784849524498,
      -0.0009742745896801353,
      -0.018754689022898674,
      0.04982998967170715,
      -0.02440345101058483,
      -0.005048196762800217,
      -0.06966198980808258,
      0.0006424130988307297,
      0.05662861466407776,
      0.007278849370777607,
      -0.027065929025411606,
      0.02190595120191574,
      -0.03911808133125305,
      -0.049637746065855026,
      -0.03531955927610397,
      0.05244870483875275,
      -0.0761382207274437,
      0.04444552958011627,
      0.01988084428012371,
      0.022384392097592354,
      0.06757110357284546,
      0.014963686466217041,
      -0.010022206231951714,
      0.006953717675060034,
      0.06775365769863129,
      0.040910955518484116,
      0.006483124103397131,
      0.047614287585020065,
      -0.023207923397421837,
      0.025344954803586006,
      -0.07123108953237534,
      0.029657797887921333,
      -0.056248147040605545,
      0.01901356689631939,
      0.01406346820294857,
      -0.12460552155971527,
      0.046415869146585464,
      -0.05016074702143669,
      0.027145328000187874,
      0.009098388254642487,
      0.03266032785177231,
      -0.07932450622320175,
      0.04062923789024353,
      0.035068217664957047,
      -0.02409008890390396,
      -0.008087445981800556,
      -0.008136847987771034,
      0.02490859664976597,
      -0.041143596172332764,
      0.013966540805995464,
      0.0036126894410699606,
      0.0022268607281148434,
      -0.009873281233012676,
      -0.026692038401961327,
      -0.03846435621380806,
      0.016834339126944542,
      0.04037179425358772,
      0.0008545140735805035,
      0.035250935703516006,
      0.04799523949623108,
      -0.00588825810700655,
      0.02252791076898575,
      0.057331327348947525,
      0.0065142083913087845,
      -0.02022898569703102,
      -0.02913757786154747,
      -0.0011240725871175528,
      0.11095991730690002,
      -0.04693922773003578,
      0.001250299159437418,
      0.012912308797240257,
      -0.0191094521433115,
      -0.09710900485515594,
      0.05866704136133194,
      -0.030926210805773735,
      -0.04391035437583923,
      -0.07119345664978027,
      -0.009989652782678604,
      0.037400875240564346,
      0.021144695580005646,
      0.1243029236793518,
      0.003208201378583908,
      -0.05783580243587494,
      0.05180176720023155,
      -0.06037057936191559,
      -0.02136949822306633,
      -0.0033463051076978445,
      0.03580007702112198,
      -0.00393638527020812,
      0.02873007021844387,
      0.0675700232386589,
      -0.02528572455048561,
      0.0704507976770401,
      -0.06969908624887466,
      -0.024984465911984444,
      -0.05471018701791763,
      0.005545427091419697,
      0.05188611149787903,
      0.008890101686120033,
      -0.01238960400223732,
      0.015093736350536346,
      -0.03547770529985428,
      -0.013714979402720928,
      0.0627337247133255,
      0.10573785752058029,
      -0.00043112592538818717,
      -0.022293925285339355,
      0.0565890334546566,
      0.027487734332680702,
      -0.006005160044878721,
      -0.057617731392383575,
      0.03119600936770439,
      -0.053636085242033005,
      0.07815147191286087,
      -0.040130119770765305,
      0.02053244411945343,
      0.0006292907055467367,
      -0.01560214813798666,
      0.07862848788499832,
      0.00774850370362401,
      -0.134029820561409,
      0.002686840947717428,
      -0.01120116375386715,
      0.04225398227572441,
      -0.03229499235749245,
      0.04660414904356003,
      -0.003424769500270486,
      -0.06036392226815224,
      -0.07771869003772736,
      -0.01735008880496025,
      0.04551548883318901,
      -0.00026096816873177886,
      -0.004809255711734295,
      0.03008338436484337,
      0.008564649149775505,
      -0.00951849389821291,
      -0.0489845871925354,
      0.051860321313142776,
      -0.0018497698474675417,
      -0.039422135800123215,
      -0.0443139523267746,
      0.021688243374228477,
      -0.009387636557221413,
      -0.002326236804947257,
      -0.0193962212651968,
      0.015128285624086857,
      0.0267105083912611,
      -0.08269912004470825,
      0.02635718509554863,
      0.07402323931455612,
      0.04623493552207947,
      -0.04308601841330528,
      0.028598537668585777,
      -0.01045646145939827,
      0.053851667791604996,
      -0.049209848046302795,
      0.08968134969472885,
      -0.021324362605810165,
      -0.023696234449744225,
      0.06799982488155365,
      0.028535805642604828,
      -0.062285467982292175,
      -0.01005072146654129,
      -0.01282262708991766,
      -0.06636764854192734,
      -0.07110399007797241,
      -0.02910604700446129,
      -0.018402080982923508,
      0.03838906064629555,
      -0.04019330069422722,
      0.009866302832961082,
      0.030445216223597527,
      0.015612590126693249,
      -0.007270671892911196,
      0.03694536164402962,
      0.07758298516273499,
      0.0048217629082500935,
      0.00549754174426198,
      -0.031309500336647034,
      -0.020547829568386078,
      -0.07823122292757034,
      0.008884326554834843,
      -0.007556268945336342,
      0.03465858846902847,
      -0.06923331320285797,
      0.022498752921819687,
      0.02759409323334694,
      0.05957259237766266,
      0.03476259857416153,
      0.08274699002504349,
      0.03579939529299736,
      -0.0356980562210083,
      -0.026882505044341087,
      0.023670103400945663,
      0.04337182641029358,
      -0.025355594232678413,
      -0.0037199498619884253,
      0.06081993132829666,
      -0.01505736168473959,
      0.04524785652756691,
      0.024148309603333473,
      -0.01850598305463791,
      -0.04518123343586922,
      -0.004673409275710583,
      0.022790245711803436,
      0.018897797912359238
    ],
    [
      -0.05101177468895912,
      0.0004958637291565537,
      0.0016423618653789163,
      0.008662287145853043,
      0.03740711510181427,
      0.02060605399310589,
      0.05191829428076744,
      -0.007013757713139057,
      0.03543829172849655,
      -0.1307850331068039,
      -0.04415443539619446,
      0.013747714459896088,
      -0.04397256672382355,
      0.007562838960438967,
      -0.02987418696284294,
      -0.07560615241527557,
      -0.05548633262515068,
      0.06311096251010895,
      -0.02956540510058403,
      -0.047521743923425674,
      -0.019721409305930138,
      0.023439554497599602,
      0.05440697446465492,
      0.0632626935839653,
      -0.06670999526977539,
      0.009423564188182354,
      0.0767560750246048,
      0.060535989701747894,
      0.02679133601486683,
      0.013591392897069454,
      0.0024674562737345695,
      0.0016755921533331275,
      -0.0040455106645822525,
      -0.010884860530495644,
      0.006081824656575918,
      -0.02075527422130108,
      -0.043887898325920105,
      0.03478415310382843,
      -0.048124395310878754,
      0.055991072207689285,
      -0.026678239926695824,
      0.020999062806367874,
      -0.03161665052175522,
      -0.02925708331167698,
      0.022279400378465652,
      0.013150710612535477,
      -0.019867349416017532,
      -0.04990720376372337,
      0.05855260416865349,
      -0.043284822255373,
      -0.02751055359840393,
      0.06183703616261482,
      0.06166297569870949,
      0.03519274294376373,
      0.013283224776387215,
      -0.07978308945894241,
      -0.09447213262319565,
      0.04567607864737511,
      -0.016063787043094635,
      -0.011480335146188736,
      0.055149346590042114,
      0.03437637537717819,
      -0.017156584188342094,
      0.0013879098696634173,
      -0.014708862639963627,
      0.004928992595523596,
      -0.06713996082544327,
      0.024004556238651276,
      -0.04314902797341347,
      -0.02002848871052265,
      0.03275678679347038,
      0.025260603055357933,
      -0.04341373220086098,
      -0.042161062359809875,
      -0.03520114719867706,
      0.049752525985240936,
      0.037651967257261276,
      0.017637336626648903,
      -0.0020875777117908,
      0.10365384072065353,
      0.011582739651203156,
      0.007276475429534912,
      -0.01933869533240795,
      0.031082483008503914,
      0.01741807721555233,
      0.09259207546710968,
      0.022326204925775528,
      -0.052383095026016235,
      0.017971275374293327,
      -0.010602419264614582,
      -0.028445763513445854,
      -0.016838517040014267,
      0.004155456088483334,
      -0.006014241836965084,
      0.00400904193520546,
      0.009082125499844551,
      -0.005743941757827997,
      -0.03986160829663277,
      0.013524864800274372,
      0.0012381422566249967,
      0.06821710616350174,
      0.016387462615966797,
      0.017360985279083252,
      0.01015093456953764,
      0.011092474684119225,
      0.003858624491840601,
      0.07109019160270691,
      -0.042194388806819916,
      0.005417286418378353,
      0.10457953810691833,
      0.015496590174734592,
      0.0764613226056099,
      0.008670574054121971,
      0.06063716858625412,
      0.013657744973897934,
      0.027461323887109756,
      -0.0031499250326305628,
      0.007144456263631582,
      0.028064461424946785,
      0.04890459403395653,
      -0.04083830118179321,
      0.05990918353199959,
      -0.0020522726699709892,
      0.040043774992227554,
      -0.04572327435016632,
      0.005837397184222937,
      -0.02858477085828781,
      0.06524371355772018,
      -0.013354623690247536,
      0.06481444835662842,
      0.05634595453739166,
      -0.03348783403635025,
      -0.00038506693090312183,
      0.026154324412345886,
      0.01882767304778099,
      -0.040603600442409515,
      -0.07178898900747299,
      -0.04735571891069412,
      -0.025054795667529106,
      0.0012213133741170168,
      0.08211410790681839,
      0.004268322605639696,
      0.02818678691983223,
      -0.03435949236154556,
      -0.0076761567033827305,
      -0.016109870746731758,
      0.010810495354235172,
      0.03677975758910179,
      -0.007158015388995409,
      -0.015746962279081345,
      0.0019219873938709497,
      0.07109034061431885,
      0.03683657944202423,
      0.012055566534399986,
      -0.10766886174678802,
      -0.033162809908390045,
      0.0593789666891098,
      0.0034669232554733753,
      0.01437326055020094,
      0.024243144318461418,
      -0.04400009289383888,
      -0.05185442417860031,
      -0.07234980165958405,
      0.06706864386796951,
      -0.04387345537543297,
      0.0076869213953614235,
      -0.005576285533607006,
      -0.03308124095201492,
      -0.026444843038916588,
      -0.06716428697109222,
      0.0017097624950110912,
      0.03568831831216812,
      -0.011828910559415817,
      -0.05033767223358154,
      0.04853327199816704,
      0.017375916242599487,
      -0.009325065650045872,
      -0.05148487910628319,
      -0.010756964795291424,
      0.002122998470440507,
      0.011734683066606522,
      -0.023091882467269897,
      0.022265803068876266,
      0.014390329830348492,
      0.042065851390361786,
      -0.005632012151181698,
      0.017941633239388466,
      -0.06209062039852142,
      0.06215681508183479,
      -0.014934290200471878,
      -0.01601717434823513,
      -0.07500510662794113,
      0.05566037818789482,
      0.02698386274278164,
      -0.017881009727716446,
      0.10206601023674011,
      -0.014866671524941921,
      -0.016582194715738297,
      0.030824987217783928,
      -0.013992084190249443,
      -0.0653572678565979,
      -0.0017997826216742396,
      0.04707305133342743,
      -0.1202053353190422,
      -0.0355038084089756,
      -0.017683282494544983,
      -0.0634903609752655,
      -0.050106409937143326,
      0.0010755232069641352,
      -0.012777218595147133,
      0.11804771423339844,
      0.01776648685336113,
      0.0320512130856514,
      0.03793927654623985,
      0.05672464892268181,
      -0.025338666513562202,
      -0.08054176717996597,
      -0.09974268823862076,
      -0.009061005897819996,
      -0.05608311668038368,
      0.0006564322393387556,
      0.008194852620363235,
      0.0025588246062397957,
      -0.07942132651805878,
      0.02577606402337551,
      -0.0380241759121418,
      -0.008977532386779785,
      0.007714842911809683,
      0.04939151182770729,
      -0.0247102752327919,
      -0.01795915700495243,
      0.010449420660734177,
      -0.07586291432380676,
      -0.029502877965569496,
      -0.011839737184345722,
      0.009888206608593464,
      -0.0018445554887875915,
      0.018983496353030205,
      -0.007138829678297043,
      0.049092430621385574,
      0.02575399912893772,
      -0.0527179054915905,
      -0.041051141917705536,
      -0.07321082055568695,
      0.0862397775053978,
      -0.01609157584607601,
      -0.01910870522260666,
      -0.055588290095329285,
      -0.033194273710250854,
      0.008720322512090206,
      -0.02581554278731346,
      -0.035342536866664886,
      -0.004684614948928356,
      0.06500999629497528,
      -0.004330807365477085,
      0.03127402067184448,
      0.017479505389928818,
      0.02693607099354267,
      0.0036173504777252674,
      -0.00418828334659338,
      -0.04965102672576904,
      0.01254623755812645,
      -0.015545695088803768,
      -0.008372461423277855,
      0.050596121698617935,
      5.217498983256519e-05,
      -0.06023887172341347,
      0.04614127427339554,
      0.009473280049860477,
      -0.030238036066293716,
      -0.04376595839858055,
      -0.03709372133016586,
      -0.00841116439551115,
      0.009844533167779446,
      -0.0634210929274559,
      -0.07858213037252426,
      -0.006816026289016008,
      0.06249939277768135,
      -0.05013655871152878,
      -0.011720615439116955,
      0.015363488346338272,
      0.03296160325407982,
      0.08066748827695847,
      0.0217730849981308,
      0.043046142905950546,
      -0.025926409289240837,
      -0.09484782069921494,
      0.013052854686975479,
      -0.02592427283525467,
      0.04836682602763176,
      0.037306103855371475,
      0.011634106747806072,
      0.06862148642539978,
      -0.010084324516355991,
      0.05235033109784126,
      0.05660248547792435,
      -0.0009972212137654424,
      -0.04990628361701965,
      -0.046751055866479874,
      -0.04575721547007561,
      0.015352937392890453,
      0.05257565900683403,
      -0.007558341138064861,
      0.03592662513256073,
      -0.021096624433994293,
      -0.009545640088617802,
      -0.01298520714044571,
      -0.027306977659463882,
      0.054108262062072754,
      -0.03265856206417084,
      0.01952194981276989,
      0.007496262900531292,
      -0.050543125718832016,
      0.04141303896903992,
      0.034194085747003555,
      0.0391145721077919,
      -0.03904518485069275,
      -0.04622945934534073,
      0.02777431346476078,
      0.03879106417298317,
      -0.013873737305402756,
      -0.000541547080501914,
      0.013013790361583233,
      0.033173441886901855,
      -0.09285455197095871,
      0.025337092578411102,
      -0.03214297071099281,
      -0.06147092953324318,
      -0.06643179804086685,
      0.08210301399230957,
      -0.04235848784446716,
      0.07933557033538818,
      -0.03389352187514305,
      -0.02272605337202549,
      0.013921167701482773,
      0.0546293705701828,
      -0.04356224462389946,
      0.05363508313894272,
      0.0439860038459301,
      0.009011532180011272,
      -0.019786875694990158,
      0.01637783832848072,
      0.040471337735652924,
      -0.05607057362794876,
      -0.002625735243782401,
      0.03378406912088394,
      0.037045158445835114,
      0.021913856267929077,
      -0.011797196231782436,
      0.006070758681744337,
      -0.0034785973839461803,
      0.021288307383656502,
      -0.01935610920190811,
      0.033649079501628876,
      -0.02348165586590767,
      0.044183194637298584,
      -0.03235422447323799,
      -0.0007390066748484969,
      0.0207065436989069,
      0.008000696077942848,
      -0.020802980288863182,
      -0.016492554917931557,
      0.06567782908678055,
      -0.01007395051419735,
      0.009860925376415253,
      -0.00581656489521265,
      -0.005717722699046135,
      0.07093022018671036,
      0.050932809710502625,
      -0.05743398889899254,
      -0.07706648856401443,
      -0.10164488852024078,
      0.04336567595601082,
      -0.09420768171548843,
      -0.046886906027793884,
      -0.07410028576850891,
      -0.09176776558160782,
      0.10621121525764465,
      -0.061893120408058167,
      -0.016620542854070663,
      0.0347001887857914,
      0.020687200129032135,
      0.11748911440372467,
      -0.06454154849052429,
      -0.09247982501983643,
      -0.020517844706773758,
      -0.048262856900691986,
      -0.03633388876914978,
      -0.04155173897743225,
      -0.05580439791083336,
      0.005279619712382555,
      -0.014760833233594894,
      -0.06890860199928284,
      -0.050175994634628296,
      0.004371706862002611,
      -0.05308419093489647,
      -0.03440210968255997,
      -0.03209695592522621,
      0.03844580426812172,
      0.07716323435306549,
      0.0512361079454422,
      0.01783693954348564,
      0.057131193578243256,
      0.0516875758767128,
      0.020013730973005295,
      0.04973592609167099,
      0.03602883219718933,
      -0.01536487229168415,
      -0.019891789183020592,
      0.0012966690119355917,
      0.013570813462138176,
      -0.0450887493789196,
      -0.02010948397219181,
      0.043149303644895554,
      -0.09292246401309967,
      -0.020937519147992134,
      0.03844313323497772,
      -0.009792904369533062,
      0.008762414567172527,
      0.06953988969326019,
      0.02853565663099289,
      -0.1267639547586441,
      -0.018078023567795753,
      0.005560714285820723,
      0.029954109340906143,
      -0.0667078047990799,
      0.005748396273702383,
      0.05832758918404579,
      -0.04860734939575195,
      -0.008587479591369629,
      -0.02059180848300457,
      0.001531435176730156,
      -0.03177180886268616,
      0.039642851799726486,
      0.02326829731464386,
      0.0327133983373642,
      0.05069673806428909,
      0.05465736240148544,
      0.049212828278541565,
      -0.07422880828380585,
      -0.030193619430065155,
      -0.04268883913755417,
      0.04685903340578079,
      -0.023152049630880356,
      0.0027829997707158327,
      -0.04462436959147453,
      -0.022493911907076836,
      -0.018518969416618347,
      0.03131147101521492,
      0.038598693907260895,
      -0.017749538645148277,
      -0.008395337499678135,
      -0.08001556992530823,
      0.014082349836826324,
      0.09731866419315338,
      -0.0407346747815609,
      0.0016806981293484569,
      -0.03234608843922615,
      0.05130050703883171,
      -0.12854324281215668,
      -0.0033877843525260687,
      -0.03307586535811424,
      0.003643140196800232,
      -0.07078798860311508,
      -0.04614105820655823,
      -0.050696853548288345,
      0.024510422721505165,
      0.003773548873141408,
      -0.038929034024477005,
      0.012862014584243298,
      0.013056189753115177,
      -9.010305802803487e-05,
      -0.07940352708101273,
      -0.04881482943892479,
      0.05473898723721504,
      0.05933784693479538,
      -0.013190783560276031,
      -0.03646264225244522,
      -0.022537900134921074,
      -0.046709273010492325,
      -0.01837083324790001,
      0.00113212619908154,
      -0.08774358034133911,
      -0.11509396135807037,
      -0.06472522020339966,
      0.026376377791166306,
      0.05874919891357422,
      0.02374878153204918,
      -0.009879877790808678,
      -0.028397932648658752,
      -0.0351276770234108,
      -0.038991834968328476,
      0.0030022754799574614,
      0.005622927099466324,
      -0.03698466345667839,
      0.05489690229296684,
      -0.0029964770656079054,
      0.09824912995100021,
      0.034440189599990845,
      -0.0155598483979702,
      0.029720177873969078,
      0.06630225479602814,
      0.026615392416715622
    ],
    [
      -0.003152497811242938,
      0.006458225194364786,
      0.008833697997033596,
      0.09746090322732925,
      -0.07787766307592392,
      -0.034989453852176666,
      -8.393884490942582e-05,
      -0.010070964694023132,
      0.005746813490986824,
      0.0038452125154435635,
      0.06488792598247528,
      -0.09507542848587036,
      -0.06028297170996666,
      -0.04232453554868698,
      -0.02070101536810398,
      -0.06682239472866058,
      0.0027237755712121725,
      0.022471921518445015,
      -0.004353852942585945,
      -0.03305352106690407,
      -0.04225384443998337,
      -0.0563550665974617,
      0.04706649109721184,
      -0.0009327724692411721,
      -0.030844662338495255,
      -0.0169670432806015,
      -0.018641430884599686,
      -0.06230226904153824,
      -0.0051911636255681515,
      -0.05011202022433281,
      -0.02672160416841507,
      -0.028528623282909393,
      -0.02243863418698311,
      0.006443111225962639,
      -0.005642002448439598,
      0.05691014602780342,
      -0.002134481444954872,
      0.010382859036326408,
      -0.06913255900144577,
      -0.05982859805226326,
      0.04430611431598663,
      0.02048157900571823,
      0.025759246200323105,
      0.008089158684015274,
      -0.048914629966020584,
      0.05885626748204231,
      -0.03716686740517616,
      0.11732539534568787,
      -0.05017590895295143,
      -0.029405973851680756,
      -0.006023579742759466,
      -0.01245234813541174,
      0.024559279903769493,
      0.01946023665368557,
      0.08075249195098877,
      -0.010909841395914555,
      0.017101634293794632,
      0.12569554150104523,
      0.02014341950416565,
      -0.04339483007788658,
      0.050308674573898315,
      0.063454270362854,
      0.07514587044715881,
      -0.10070452094078064,
      -0.02017654851078987,
      0.038833584636449814,
      0.048876386135816574,
      -0.04023445397615433,
      -0.0677662044763565,
      0.030558599159121513,
      0.021694643422961235,
      -0.04546201974153519,
      0.018981045112013817,
      0.04040207341313362,
      0.049492206424474716,
      -0.043268535286188126,
      -0.12165772169828415,
      -0.03292736038565636,
      -0.01894996128976345,
      0.052526962012052536,
      -0.07445262372493744,
      0.017486291006207466,
      0.010419109836220741,
      -0.06330954283475876,
      -0.032617051154375076,
      -0.11931957304477692,
      0.09303198009729385,
      0.001826592953875661,
      0.02371945045888424,
      0.06739810109138489,
      0.02484288066625595,
      0.06045941635966301,
      -0.019001014530658722,
      -0.04077719897031784,
      -0.04293393716216087,
      -0.021147215738892555,
      0.025108356028795242,
      -0.032896045595407486,
      0.018473412841558456,
      0.006459754891693592,
      0.03153780847787857,
      0.003591154469177127,
      0.02953924424946308,
      0.008360975421965122,
      -0.028423482552170753,
      0.0454033799469471,
      -0.012790539301931858,
      0.01770477555692196,
      -0.044102512300014496,
      0.01483268104493618,
      0.06323718279600143,
      0.06116251274943352,
      -0.05471453443169594,
      -0.01773969456553459,
      0.09032680839300156,
      0.049761053174734116,
      -0.012869062833487988,
      -0.0004012271238025278,
      -0.02924736961722374,
      -0.02362484112381935,
      -0.042605943977832794,
      -0.027661582455039024,
      0.028957795351743698,
      -0.028111951425671577,
      -0.055012237280607224,
      -0.03632417321205139,
      -0.049812525510787964,
      -0.03234704211354256,
      -0.08264638483524323,
      0.01721152476966381,
      0.011262549087405205,
      0.03925717622041702,
      0.006117459386587143,
      -0.04037785157561302,
      0.020531048998236656,
      -0.05830850824713707,
      0.01090213656425476,
      0.002582882298156619,
      0.013083579950034618,
      -0.03575553745031357,
      -0.021205786615610123,
      -0.03295079991221428,
      -0.0020521427504718304,
      0.002968096174299717,
      0.023671111091971397,
      -0.009803913533687592,
      0.018096422776579857,
      0.023190289735794067,
      0.05987481400370598,
      0.0029361832421272993,
      0.08938630670309067,
      -0.030156878754496574,
      -0.031455449759960175,
      0.04003278911113739,
      -0.007824074476957321,
      0.02862497791647911,
      -0.016256462782621384,
      -0.0696912482380867,
      0.07745222747325897,
      -0.014376056380569935,
      -0.04004695266485214,
      0.022872203961014748,
      0.03443700075149536,
      0.056813180446624756,
      -0.019767632707953453,
      -0.0369269885122776,
      0.00955832377076149,
      -0.05064364895224571,
      0.03536051884293556,
      0.03270872309803963,
      0.0053467825055122375,
      -0.06981748342514038,
      0.09550347924232483,
      0.03650916740298271,
      0.04297179356217384,
      -0.009320891462266445,
      -0.08396865427494049,
      0.0774909257888794,
      0.02675226517021656,
      0.05464984476566315,
      -0.002441780874505639,
      -0.025424998253583908,
      0.12990140914916992,
      -0.08284793794155121,
      -0.0602235347032547,
      -0.04195556789636612,
      -0.020635705441236496,
      -0.023563193157315254,
      -0.05867825448513031,
      0.02814183384180069,
      0.017937539145350456,
      -0.005291124805808067,
      -0.04638863727450371,
      0.02013852447271347,
      0.006098614539951086,
      -0.03494248166680336,
      0.024369947612285614,
      0.018412215635180473,
      -0.10492465645074844,
      0.037468552589416504,
      -0.03210335597395897,
      -0.07056368142366409,
      -0.053176965564489365,
      -0.03212324157357216,
      0.010126573964953423,
      0.009436768479645252,
      -0.006795376539230347,
      -0.08809541165828705,
      -0.07906458526849747,
      -0.006188968196511269,
      -0.012383255176246166,
      0.008241217583417892,
      -0.015187663957476616,
      0.020119251683354378,
      -0.06730947643518448,
      -0.020151399075984955,
      0.06551693379878998,
      -0.07451096922159195,
      -0.009542659856379032,
      -0.027066292241215706,
      0.02050923928618431,
      0.04321398213505745,
      0.013691975735127926,
      0.028799979016184807,
      -0.05409862473607063,
      0.017243806272745132,
      0.0022085087839514017,
      -0.04713507369160652,
      0.029162416234612465,
      -0.031630340963602066,
      -0.0855478048324585,
      -0.053249869495630264,
      0.02102036029100418,
      -0.0905841737985611,
      -0.0022711839992552996,
      0.01902359537780285,
      0.06787195801734924,
      -0.016041256487369537,
      0.027756500989198685,
      -0.02058504894375801,
      -0.029223686084151268,
      0.07935956120491028,
      -0.00953772570937872,
      -0.003335799090564251,
      0.01269748155027628,
      0.03824320435523987,
      0.03097803145647049,
      0.02213987149298191,
      0.019507527351379395,
      0.011453933082520962,
      0.009269215166568756,
      -0.10614914447069168,
      -0.04243111237883568,
      -0.036233559250831604,
      -0.029592547565698624,
      0.05538984015583992,
      0.00886289868503809,
      0.03143702819943428,
      -0.00942303892225027,
      0.04597307741641998,
      -0.005511512514203787,
      -0.021122761070728302,
      0.018172701820731163,
      0.054665081202983856,
      -0.03249659389257431,
      -0.04893099516630173,
      -0.10371235013008118,
      -0.027162134647369385,
      0.04454956576228142,
      -0.007185885217040777,
      -0.025628164410591125,
      0.022569814696907997,
      0.06111428141593933,
      -0.007704653311520815,
      0.0283396877348423,
      -0.06621028482913971,
      0.01530055608600378,
      -0.061009932309389114,
      0.05198439210653305,
      -0.011016433127224445,
      0.01501383539289236,
      -0.0205219853669405,
      0.0748535692691803,
      0.005854077637195587,
      -0.0001137622311944142,
      -0.029623975977301598,
      -0.023355523124337196,
      0.015086693689227104,
      -0.015094292350113392,
      0.022777529433369637,
      0.011180908419191837,
      0.041688501834869385,
      0.02335844747722149,
      0.035916268825531006,
      -0.021576302126049995,
      -0.01238830666989088,
      0.020419858396053314,
      0.07832064479589462,
      -0.0266188345849514,
      0.022110575810074806,
      -0.005737565457820892,
      0.05059666931629181,
      0.05547673627734184,
      0.025227388367056847,
      0.02165168710052967,
      -0.006312174256891012,
      -0.11262224614620209,
      0.0016769188223406672,
      0.08024584501981735,
      -0.12777936458587646,
      0.01517899427562952,
      0.061770372092723846,
      0.007250049151480198,
      -0.026115069165825844,
      -0.0175493061542511,
      0.049211159348487854,
      0.013588217087090015,
      -0.02075195498764515,
      -0.015424846671521664,
      0.011824818328022957,
      -0.008789567276835442,
      -0.008050160482525826,
      0.07847599685192108,
      -0.01440115924924612,
      -0.01767142303287983,
      0.044733237475156784,
      -0.013115603476762772,
      -0.02329692430794239,
      0.01529910508543253,
      -0.09850258380174637,
      0.0024160128086805344,
      0.006660228595137596,
      0.05867995694279671,
      -0.07724133133888245,
      0.012621266767382622,
      -0.053638312965631485,
      -0.04439277946949005,
      -0.01045100949704647,
      -0.03139062225818634,
      0.05663175508379936,
      -0.046438876539468765,
      -0.10749917477369308,
      0.034804314374923706,
      -0.03794996067881584,
      -0.013138167560100555,
      0.03520921617746353,
      0.06522492319345474,
      -0.05050923302769661,
      0.020089272409677505,
      -0.03728703409433365,
      -0.03998361900448799,
      0.027153313159942627,
      -0.07505259662866592,
      0.0043425788171589375,
      0.054145872592926025,
      0.015933042392134666,
      0.04394825920462608,
      0.04678728058934212,
      0.016219034790992737,
      0.042088042944669724,
      -0.03407983481884003,
      -0.017454097047448158,
      0.048480324447155,
      -0.05751911550760269,
      0.02788320742547512,
      -0.019586212933063507,
      0.006221439223736525,
      -0.029799195006489754,
      -0.036011677235364914,
      -0.06319158524274826,
      0.03830008581280708,
      0.06611485034227371,
      -0.10754123330116272,
      -0.01782183349132538,
      0.03628550097346306,
      0.047749560326337814,
      -0.02177354507148266,
      0.04283931106328964,
      -0.019150353968143463,
      0.011230056174099445,
      -0.06049548462033272,
      0.001593168592080474,
      0.056719087064266205,
      0.061338044703006744,
      -0.04023447632789612,
      0.0696742981672287,
      -0.002472005086019635,
      -0.020868640393018723,
      0.02458941750228405,
      0.012371405959129333,
      0.06473062187433243,
      0.02074994333088398,
      0.0011929001193493605,
      0.006208126898854971,
      -0.051875509321689606,
      0.04545161873102188,
      0.023332271724939346,
      0.03412405401468277,
      0.029031306505203247,
      0.00793689489364624,
      -0.06807052344083786,
      0.03530408442020416,
      -0.01649385318160057,
      0.004562555346637964,
      0.01926792785525322,
      0.014584526419639587,
      0.06416527926921844,
      0.054143745452165604,
      -0.04752696305513382,
      0.01599816605448723,
      -0.025466982275247574,
      -0.025814387947320938,
      -0.03573250770568848,
      0.023415014147758484,
      0.010143210180103779,
      -0.029817361384630203,
      0.02224733680486679,
      0.015840880572795868,
      -0.002027900191023946,
      0.024037091061472893,
      0.017063811421394348,
      0.002352524781599641,
      -0.05750834569334984,
      -0.019860239699482918,
      -0.03156023845076561,
      0.024865252897143364,
      0.023384936153888702,
      -0.032650917768478394,
      0.028610821813344955,
      0.00672542629763484,
      0.006439066492021084,
      -0.01158852782100439,
      -0.05416981503367424,
      -0.0732506513595581,
      0.0015685069374740124,
      0.059555746614933014,
      0.003425677539780736,
      0.03661320358514786,
      0.08746441453695297,
      -0.055869098752737045,
      -0.042404014617204666,
      -0.02182185836136341,
      -0.016790693625807762,
      0.05615421012043953,
      -0.0547608807682991,
      0.05402904376387596,
      -0.07021930813789368,
      0.06660027801990509,
      -0.06515253335237503,
      0.08186198770999908,
      -0.010220280848443508,
      -0.030482513830065727,
      0.058534737676382065,
      0.12933968007564545,
      0.007909899577498436,
      -0.039853326976299286,
      0.020833343267440796,
      0.05993261933326721,
      -0.08915998041629791,
      -0.015288245864212513,
      0.05387486517429352,
      0.014651662670075893,
      0.025760788470506668,
      0.04019796475768089,
      -0.01838536374270916,
      -0.07177911698818207,
      0.009569640271365643,
      -0.040936049073934555,
      0.032071229070425034,
      0.06431572884321213,
      0.09628879278898239,
      -0.026270143687725067,
      0.010739711113274097,
      -0.04450450837612152,
      0.04242214933037758,
      0.047496236860752106,
      0.08724051713943481,
      -0.037422776222229004,
      -0.007911283522844315,
      0.03835368901491165,
      -0.008219698444008827,
      -0.05112724378705025,
      0.02863367088139057,
      0.01811966672539711,
      -0.06661611795425415,
      0.03326025605201721,
      0.037186939269304276,
      0.07495559751987457,
      -0.013669940643012524,
      -0.008444816805422306,
      0.034035857766866684,
      -0.018422221764922142,
      -0.04216146841645241,
      0.026671910658478737,
      0.02057180553674698,
      0.02006622590124607,
      0.012177631258964539,
      -0.04467287287116051,
      -0.030685976147651672,
      -0.06712768971920013,
      -0.01691305823624134,
      -0.10935155302286148,
      -0.032555658370256424
    ],
    [
      -0.04876700043678284,
      0.12405642867088318,
      -0.11696279048919678,
      -0.016418039798736572,
      0.007425209507346153,
      -0.06166417524218559,
      0.04608786106109619,
      0.04153051972389221,
      0.04634273424744606,
      -0.07584463804960251,
      -0.03762911632657051,
      0.055295467376708984,
      -0.055284205824136734,
      -0.005356977228075266,
      -0.0038022110238671303,
      0.08883627504110336,
      0.06091245263814926,
      0.0732707753777504,
      0.034427493810653687,
      -0.06387709081172943,
      -0.02339567057788372,
      0.011201305314898491,
      -0.01423333678394556,
      -0.06080140173435211,
      -0.004983601160347462,
      0.02320995181798935,
      0.027461405843496323,
      -0.013053469359874725,
      -0.011083675548434258,
      0.043534956872463226,
      -0.030298931524157524,
      -0.06877139210700989,
      0.013163571245968342,
      -0.008966909721493721,
      0.007503212429583073,
      -0.0345989391207695,
      -0.019353626295924187,
      0.043663643300533295,
      0.052340708673000336,
      0.012482878752052784,
      0.10123469680547714,
      0.07576140761375427,
      0.015964748337864876,
      0.016680395230650902,
      -0.026653999462723732,
      -0.02745913155376911,
      0.056998465210199356,
      -0.03613781929016113,
      -0.08106467872858047,
      -0.06281127780675888,
      0.012521505355834961,
      0.020630735903978348,
      -0.04018593952059746,
      0.009547503665089607,
      0.08469624817371368,
      0.0020323009230196476,
      -0.06896989792585373,
      0.03657779097557068,
      -0.017394697293639183,
      -0.0006998887984082103,
      0.0523812398314476,
      -0.03757373243570328,
      -0.014289207756519318,
      -0.022341707721352577,
      0.018617646768689156,
      -0.026860317215323448,
      0.026151787489652634,
      0.005295666866004467,
      0.08013635128736496,
      0.004356614779680967,
      0.047245170921087265,
      -0.006944217719137669,
      -0.053595490753650665,
      -0.02910824492573738,
      0.008524937555193901,
      -0.09763482213020325,
      0.01904832199215889,
      -0.10958550125360489,
      -0.006684960797429085,
      -0.0014371738070622087,
      -0.051500339061021805,
      -0.0014294898137450218,
      0.05143696814775467,
      0.04665262624621391,
      -0.05311507731676102,
      -0.06981585174798965,
      -0.033692311495542526,
      -0.049901332706213,
      -0.10526739805936813,
      -0.021815137937664986,
      -0.011151539161801338,
      0.06926637887954712,
      -0.05976351350545883,
      0.053163155913352966,
      0.030764499679207802,
      -0.0729530081152916,
      0.014541194774210453,
      0.0048237573355436325,
      0.02994683012366295,
      -0.03998526930809021,
      -0.07263212651014328,
      -0.02900356613099575,
      0.04334405064582825,
      -0.022028857842087746,
      -0.031224818900227547,
      -0.06146222725510597,
      -0.05262916907668114,
      0.055473051965236664,
      0.013064337894320488,
      0.0804155245423317,
      0.08702851086854935,
      0.042557552456855774,
      0.07631630450487137,
      0.03360343351960182,
      -0.05848988518118858,
      0.07182930409908295,
      -0.010417490266263485,
      -0.032246097922325134,
      -0.015435547567903996,
      0.07818210124969482,
      -0.03823235630989075,
      0.03511715680360794,
      0.014570286497473717,
      0.060107894241809845,
      0.013572851195931435,
      -0.06815620511770248,
      0.045893989503383636,
      0.014819091185927391,
      -0.02814607322216034,
      -0.008381159044802189,
      -0.04233955964446068,
      -0.03047066740691662,
      -0.06916543841362,
      0.03906942531466484,
      -0.06849748641252518,
      0.02597734145820141,
      0.0031391775701195,
      -0.030610695481300354,
      0.0875529870390892,
      0.0009501652093604207,
      0.04570931941270828,
      -0.031127197667956352,
      0.007571475580334663,
      -0.08018956333398819,
      0.06178739294409752,
      -0.03171498328447342,
      0.03904219716787338,
      0.022627092897892,
      0.06272134929895401,
      0.0973111167550087,
      0.0333431251347065,
      -0.027892138808965683,
      -0.012024509720504284,
      -0.06419212371110916,
      -0.02832767739892006,
      -0.01189030148088932,
      0.027380945160984993,
      0.06868347525596619,
      -0.01686038449406624,
      0.05501966550946236,
      0.0029495088383555412,
      0.06508699804544449,
      -0.06711744517087936,
      0.006355441641062498,
      -0.015014906413853168,
      0.008767698891460896,
      -0.006597793661057949,
      0.0630202367901802,
      -0.017663316801190376,
      0.06313035637140274,
      -0.0036597796715795994,
      -0.05390603840351105,
      -0.057852454483509064,
      -0.05134708806872368,
      0.038739606738090515,
      0.028185542672872543,
      -0.0030004081781953573,
      0.07193690538406372,
      0.03535212576389313,
      -5.811338633066043e-05,
      0.012624017894268036,
      -0.034182094037532806,
      0.009718560613691807,
      -0.005305412225425243,
      0.04386318475008011,
      0.07408644258975983,
      -0.04067405313253403,
      0.015483738854527473,
      -0.048251211643218994,
      0.013369529508054256,
      0.03254220262169838,
      0.04919561743736267,
      0.048730455338954926,
      0.020830515772104263,
      -0.010918029583990574,
      0.0005818543140776455,
      -0.11443687975406647,
      0.0013578477082774043,
      -0.05602606385946274,
      0.0069624390453100204,
      0.05449944734573364,
      -0.010881760157644749,
      -0.006588561460375786,
      0.0585767924785614,
      0.03932619467377663,
      0.10231644660234451,
      -0.054009683430194855,
      -0.013779250904917717,
      0.03642634302377701,
      0.0732855275273323,
      -0.04540316015481949,
      0.05858180299401283,
      0.042748089879751205,
      -0.05162530392408371,
      -0.018552077934145927,
      -0.14623059332370758,
      0.033897727727890015,
      0.026974888518452644,
      -0.10322846472263336,
      0.06731293350458145,
      0.013318929821252823,
      -0.030670080333948135,
      -0.12523457407951355,
      0.007398586720228195,
      -0.06369414180517197,
      0.04122396931052208,
      -0.0890115424990654,
      0.0921989232301712,
      0.039694901555776596,
      -0.006024675443768501,
      0.02018641121685505,
      -0.022660689428448677,
      0.1043601706624031,
      0.028013616800308228,
      -0.006099340505897999,
      -0.021420860663056374,
      -0.034739453345537186,
      -0.08020960539579391,
      -0.0641588494181633,
      0.04816070571541786,
      -0.041537750512361526,
      0.08636564761400223,
      0.0288576427847147,
      0.012417739257216454,
      -0.05578788369894028,
      -0.06729128956794739,
      0.008372687734663486,
      0.03901839256286621,
      0.013274548575282097,
      0.0070943767204880714,
      0.0189744234085083,
      -0.028971035033464432,
      -0.011198282241821289,
      -0.09107330441474915,
      0.006471117492765188,
      -0.0006777112721465528,
      -0.12119507044553757,
      0.027987921610474586,
      -0.008260470815002918,
      0.02225981093943119,
      -0.0319473072886467,
      0.030220841988921165,
      -0.004960494581609964,
      0.07847665995359421,
      0.07232150435447693,
      0.000583047338295728,
      -0.01878110133111477,
      -0.040483325719833374,
      -0.008820409886538982,
      0.004606070928275585,
      -0.035188592970371246,
      0.04042781889438629,
      -0.035342343151569366,
      -0.07240664958953857,
      0.059565864503383636,
      0.004878551233559847,
      -0.006325296126306057,
      -0.011143188923597336,
      0.0037392955273389816,
      -0.05210020765662193,
      0.01026165671646595,
      -0.04375535622239113,
      0.011903478763997555,
      -0.08330771327018738,
      -0.024920785799622536,
      -0.04223547875881195,
      -0.051874998956918716,
      0.0035398865584284067,
      4.7018918849062175e-05,
      0.025764407590031624,
      0.012954242527484894,
      0.013708999380469322,
      0.042799051851034164,
      0.00876674521714449,
      0.017430216073989868,
      0.041136499494314194,
      0.0032857023179531097,
      0.013524777255952358,
      0.013595519587397575,
      -0.027274606749415398,
      -0.007172862533479929,
      0.08632131665945053,
      -0.0020571560598909855,
      -0.0245417058467865,
      0.014898719266057014,
      0.052796464413404465,
      0.06304613500833511,
      -0.04650992155075073,
      0.010656376369297504,
      0.03731844574213028,
      0.004882553592324257,
      0.07614614814519882,
      -0.015601590275764465,
      0.05418848246335983,
      0.03919435665011406,
      0.0009889611974358559,
      0.051479097455739975,
      0.021797750145196915,
      0.011706716381013393,
      -0.03868021070957184,
      0.12384995073080063,
      -0.006784443277865648,
      -0.005010440479964018,
      0.06191125139594078,
      -0.061007529497146606,
      0.07506504654884338,
      0.004363006446510553,
      -0.01124475710093975,
      0.03390303999185562,
      -0.005590830463916063,
      0.02618289552628994,
      0.002434228314086795,
      0.009615009650588036,
      -0.07062903046607971,
      -0.006822784896939993,
      -0.045690108090639114,
      -0.0025081036146730185,
      -0.01796676404774189,
      -0.04537227749824524,
      -0.028605692088603973,
      0.05542570725083351,
      0.03214828670024872,
      0.01701236143708229,
      0.09027301520109177,
      0.018334349617362022,
      -0.02692766860127449,
      -0.03308725729584694,
      -0.05473588407039642,
      0.036692459136247635,
      0.0068267714232206345,
      -0.05148090794682503,
      -0.028513243421912193,
      -0.025409186258912086,
      0.026739023625850677,
      0.03797212988138199,
      0.023822734132409096,
      0.03502398729324341,
      0.015872562304139137,
      0.012787803076207638,
      -0.028868842869997025,
      0.050119444727897644,
      -0.015587096102535725,
      -0.034096065908670425,
      0.062419600784778595,
      -0.0054465970024466515,
      -0.028409434482455254,
      0.025079965591430664,
      -0.04133348912000656,
      -0.006862399633973837,
      -0.03975185379385948,
      0.08481267094612122,
      -0.053642090409994125,
      0.042157337069511414,
      -0.014575349166989326,
      -0.003279481315985322,
      -0.0014801379293203354,
      0.02674883045256138,
      0.07909007370471954,
      -0.030632559210062027,
      0.0034903769847005606,
      -0.045125313103199005,
      -0.0007165998104028404,
      -0.0501282624900341,
      0.007303959224373102,
      -0.006656089797616005,
      0.035130444914102554,
      -0.021393852308392525,
      0.09227699041366577,
      0.01356496475636959,
      0.004829019773751497,
      -0.007736447267234325,
      -0.02069004625082016,
      0.002683735452592373,
      0.03036629594862461,
      -0.00681294547393918,
      0.017337616533041,
      0.006555892992764711,
      0.0055365837179124355,
      0.010094551369547844,
      0.00331980106420815,
      -0.026764245703816414,
      0.0261301901191473,
      -0.015819324180483818,
      0.034833505749702454,
      -0.021628865972161293,
      0.07035756856203079,
      -0.05198884382843971,
      -0.012083890847861767,
      -0.11333458125591278,
      0.02588668093085289,
      0.04684259742498398,
      -0.03260398283600807,
      -0.0009329476743005216,
      0.02816160023212433,
      0.03866512328386307,
      -0.027261968702077866,
      0.005725536495447159,
      0.05471346899867058,
      0.009110523387789726,
      -0.03376975655555725,
      0.09400420635938644,
      -0.019780468195676804,
      -0.04180032014846802,
      0.03823519125580788,
      -0.08329293131828308,
      0.06044527143239975,
      -0.015568597242236137,
      0.07359359413385391,
      -0.03193444386124611,
      -0.08863072097301483,
      -0.06413007527589798,
      -0.08621776849031448,
      -0.06783797591924667,
      -0.045122552663087845,
      0.002404441125690937,
      -0.05417215824127197,
      0.04539869353175163,
      -0.005269695073366165,
      -0.01924895867705345,
      -0.05040272697806358,
      0.04131359979510307,
      0.0726986825466156,
      -0.08932431042194366,
      0.024807246401906013,
      0.045681461691856384,
      -0.007706944365054369,
      0.03056526556611061,
      0.050576403737068176,
      0.00405713589861989,
      0.054289598017930984,
      0.0009523628978058696,
      0.05951894074678421,
      -0.014249326661229134,
      -0.03257521986961365,
      -0.02801363542675972,
      0.08117176592350006,
      -0.04046536609530449,
      -0.06263569742441177,
      0.05795934796333313,
      0.059704333543777466,
      -0.0031968785915523767,
      0.03204141557216644,
      -0.019760342314839363,
      -0.019937653094530106,
      0.02041257731616497,
      0.046013593673706055,
      -0.04047692194581032,
      0.011790906079113483,
      0.04339566454291344,
      -0.0007497034384869039,
      0.07331634312868118,
      -0.025795603170990944,
      0.021932454779744148,
      0.008968419395387173,
      -0.01733515039086342,
      0.042809370905160904,
      0.06632295250892639,
      -0.02541961334645748,
      -0.0455305315554142,
      -0.012205284088850021,
      -0.007246112450957298,
      -0.026294421404600143,
      0.0035657284315675497,
      0.09762794524431229,
      0.03445528447628021,
      -0.020651178434491158,
      -0.02508159913122654,
      -0.031243517994880676,
      0.03918719291687012,
      0.09448602795600891,
      -0.003812185488641262,
      0.0538148358464241,
      -0.03005978651344776,
      -0.07419326156377792,
      -0.014851008541882038,
      -0.01959950663149357,
      0.005053479690104723,
      0.02422291785478592,
      0.017268603667616844,
      0.03164943680167198,
      -0.05381740629673004,
      -0.0011748700635507703,
      -0.03641995042562485
    ],
    [
      -0.010003295727074146,
      -0.015371774323284626,
      -0.0019664077553898096,
      0.017520269379019737,
      -0.022444698959589005,
      0.004419217351824045,
      0.07209676504135132,
      -0.0044875615276396275,
      -0.008050196804106236,
      -0.012577859684824944,
      -0.04375167936086655,
      -0.013616623356938362,
      0.013169423677027225,
      -0.01941179856657982,
      0.008380637504160404,
      0.015351034700870514,
      0.0505022294819355,
      0.0052298749797046185,
      -0.0070300837978720665,
      0.023323683068156242,
      -0.05806845426559448,
      0.05677643045783043,
      0.038606785237789154,
      -0.034452181309461594,
      -0.005594807211309671,
      -0.049470819532871246,
      0.03963745757937431,
      -0.010134098120033741,
      -0.010744583792984486,
      0.08930211514234543,
      0.00770470779389143,
      0.025784099474549294,
      -0.09348805248737335,
      0.007653906941413879,
      -0.08271583914756775,
      0.04650551825761795,
      0.07620830833911896,
      0.03882536292076111,
      0.005598850082606077,
      -0.02968684956431389,
      0.06981596350669861,
      -0.04244460165500641,
      -0.055745530873537064,
      -0.003752361750230193,
      -0.029542548581957817,
      -0.07615451514720917,
      -0.07072971761226654,
      -0.00759188923984766,
      -0.0665445551276207,
      0.005019924137741327,
      -0.03604111075401306,
      0.025183692574501038,
      -0.05393457040190697,
      -0.044535841792821884,
      0.004601454362273216,
      -0.06212913244962692,
      0.04791087657213211,
      0.04475384205579758,
      0.003431635210290551,
      0.078626848757267,
      -0.007941042073071003,
      0.012537707574665546,
      -0.06368841975927353,
      -0.023430604487657547,
      0.002130490494892001,
      -0.0472935326397419,
      -0.03541426360607147,
      -0.020190108567476273,
      -0.06442604213953018,
      -0.026893962174654007,
      0.03000904805958271,
      -0.01864556223154068,
      -0.014510457403957844,
      0.08712849766016006,
      -0.04040175676345825,
      -0.05298719182610512,
      0.008685431443154812,
      -0.012764805927872658,
      -0.006805711425840855,
      0.04525945708155632,
      0.014831136912107468,
      -0.05395166575908661,
      0.06354305893182755,
      -0.034867703914642334,
      0.028570642694830894,
      -0.05152297392487526,
      0.06571391224861145,
      0.05959988757967949,
      -0.03558332100510597,
      0.05385914444923401,
      0.022557314485311508,
      0.061570022255182266,
      -0.0027830316685140133,
      -0.08793863654136658,
      0.02817538194358349,
      -0.00897899828851223,
      0.024534186348319054,
      -0.004561693873256445,
      -0.0910576730966568,
      0.06041659414768219,
      0.023552851751446724,
      0.012910704128444195,
      0.08156835287809372,
      -0.005268150009214878,
      -0.012726383283734322,
      -0.008634359575808048,
      0.027346910908818245,
      0.008577938191592693,
      -0.039013948291540146,
      0.009502260014414787,
      -0.08154952526092529,
      0.010481548495590687,
      0.04668013006448746,
      -0.03240493685007095,
      -0.04520295187830925,
      0.02319926954805851,
      0.07303335517644882,
      0.00039258183096535504,
      0.022014660760760307,
      -0.00794968381524086,
      0.011428331024944782,
      0.03713731840252876,
      0.0839821845293045,
      -0.021036677062511444,
      -0.023923393338918686,
      -0.04422455653548241,
      -0.013921508565545082,
      -0.0890394076704979,
      0.07030897587537766,
      0.013472246937453747,
      0.005237588193267584,
      -0.07784504443407059,
      -0.010093110613524914,
      -0.012302246876060963,
      -0.04225754365324974,
      -0.013279667124152184,
      0.07031839340925217,
      -0.08819036185741425,
      0.04671948030591011,
      -0.04344789683818817,
      -0.02828647568821907,
      -0.07938393950462341,
      0.029901502653956413,
      0.04145115241408348,
      -0.005658281035721302,
      0.06594442576169968,
      -0.024208026006817818,
      -0.02762576937675476,
      0.00036312322481535375,
      -0.06338271498680115,
      -0.02904733270406723,
      -0.06256955116987228,
      0.04463036358356476,
      0.06309203058481216,
      -0.05827716737985611,
      -0.03649706393480301,
      0.0006833411753177643,
      0.03897915035486221,
      0.03921433538198471,
      -0.013494116254150867,
      0.02432115748524666,
      -0.042707763612270355,
      -0.03996637091040611,
      -0.021939130499958992,
      0.04157159477472305,
      0.02064572647213936,
      -0.01706400141119957,
      -0.0037713181227445602,
      0.04403779283165932,
      -0.02294592745602131,
      -0.05675375089049339,
      0.044155679643154144,
      0.012037953361868858,
      0.010855539701879025,
      0.004975329153239727,
      -0.024482015520334244,
      0.017338139936327934,
      0.036902688443660736,
      -0.0323210246860981,
      0.0415208674967289,
      0.008736750110983849,
      0.022664589807391167,
      0.03235389664769173,
      0.04208292439579964,
      0.0009749465389177203,
      0.07935148477554321,
      0.006802203133702278,
      0.06879202276468277,
      -0.04302029684185982,
      0.06655644625425339,
      -0.0008246491197496653,
      0.04472148418426514,
      0.0008580333087593317,
      -0.0919671282172203,
      0.07700885087251663,
      -0.031708747148513794,
      -0.05653367191553116,
      0.04266498610377312,
      -0.02559211291372776,
      0.001138257677666843,
      0.006927431561052799,
      -0.06279490143060684,
      -0.07121273875236511,
      0.06799063086509705,
      0.028011981397867203,
      -0.02086499147117138,
      -0.057341717183589935,
      -0.03972374275326729,
      -0.03379334136843681,
      -0.02132321149110794,
      -0.0038302657194435596,
      0.01975267566740513,
      -0.07918056845664978,
      0.08406786620616913,
      -0.05096984654664993,
      0.05007199943065643,
      -0.07707472890615463,
      0.03690550848841667,
      -0.015357723459601402,
      -0.03681664913892746,
      0.0014585453318431973,
      0.04021390900015831,
      -0.024992510676383972,
      0.020694267004728317,
      0.050189945846796036,
      0.022333411499857903,
      -0.018655510619282722,
      0.060222018510103226,
      0.016210654750466347,
      -0.022943437099456787,
      -0.03829305246472359,
      0.05993495136499405,
      -0.01941896416246891,
      0.022345077246427536,
      0.002891452517360449,
      -0.02121434360742569,
      0.015062852762639523,
      -0.032411642372608185,
      0.01558774709701538,
      0.007049624342471361,
      -0.05489994212985039,
      0.021609017625451088,
      0.034157656133174896,
      -0.008645104244351387,
      -0.06714586913585663,
      -0.014049402438104153,
      0.04835301637649536,
      -0.005173900164663792,
      -0.05005388706922531,
      0.01056045200675726,
      0.0782010406255722,
      0.03393989056348801,
      0.022779354825615883,
      -0.07695070654153824,
      -0.03590940684080124,
      -0.024643972516059875,
      -0.02105827070772648,
      0.0560152493417263,
      -0.08253945410251617,
      -0.02523895539343357,
      -0.045563798397779465,
      -0.07741757482290268,
      0.04050653427839279,
      0.006642968859523535,
      0.059463515877723694,
      0.009015219286084175,
      -0.03976431488990784,
      0.05333716422319412,
      -0.06434793025255203,
      0.012210124172270298,
      -0.04964287579059601,
      0.060812707990407944,
      0.06292397528886795,
      0.07066631317138672,
      0.010516163893043995,
      -0.03877711668610573,
      0.02474833093583584,
      0.023138059303164482,
      -0.09607972204685211,
      0.06091789901256561,
      -0.048081785440444946,
      0.00215502199716866,
      0.013009745627641678,
      -0.029927661642432213,
      0.0023940582759678364,
      -0.016911333426833153,
      -0.03130302578210831,
      -0.003412866033613682,
      0.01666034758090973,
      -0.02547983080148697,
      0.0545160211622715,
      0.03424987941980362,
      -0.045454949140548706,
      0.03788677603006363,
      0.04425449296832085,
      0.04193883761763573,
      0.05500570312142372,
      0.018862495198845863,
      0.016876185312867165,
      0.06399645656347275,
      -0.06544704735279083,
      0.03455682843923569,
      0.05782478675246239,
      0.032407645136117935,
      0.025205986574292183,
      -0.024006007239222527,
      -0.021786941215395927,
      0.04532640054821968,
      0.0435682013630867,
      0.022938203066587448,
      -0.029969288036227226,
      -0.03699229285120964,
      -0.014574057422578335,
      0.06879697740077972,
      0.031784120947122574,
      -0.054213616997003555,
      -0.038403190672397614,
      0.033940527588129044,
      0.014580940827727318,
      -0.002283539390191436,
      -0.03887949883937836,
      -0.006733547896146774,
      0.015313945710659027,
      0.03804168105125427,
      0.06821668148040771,
      0.03063187003135681,
      -0.07754255086183548,
      -0.08394404500722885,
      -0.01333469245582819,
      -0.02394612319767475,
      0.07431300729513168,
      -0.010482455603778362,
      -0.016580739989876747,
      -0.04506045952439308,
      0.02442048117518425,
      -0.020227260887622833,
      0.003002843586727977,
      -0.0783272460103035,
      -0.011938356794416904,
      0.015604974702000618,
      -0.04867587983608246,
      -0.04400889202952385,
      0.07417131215333939,
      0.042440902441740036,
      -0.02992790937423706,
      -0.057748377323150635,
      0.012381215579807758,
      0.1258779615163803,
      0.017476223409175873,
      0.0015667327679693699,
      0.004904818255454302,
      -0.04342668503522873,
      0.0009289673762395978,
      0.02560962364077568,
      0.0601683147251606,
      0.03513266146183014,
      -0.013839080929756165,
      -0.0120531702414155,
      0.05668456852436066,
      0.026918359100818634,
      0.036812346428632736,
      0.05210288241505623,
      0.02345028519630432,
      0.0325203575193882,
      0.0171664971858263,
      -0.0035271502565592527,
      -0.024598460644483566,
      -0.03189578279852867,
      -0.0013499801279976964,
      -0.11259348690509796,
      -0.057031676173210144,
      0.002242401475086808,
      0.010870462283492088,
      -0.014276481233537197,
      -0.013716600835323334,
      0.04717926308512688,
      -0.02977675013244152,
      -0.006561934482306242,
      0.002291128970682621,
      0.09922848641872406,
      0.060492243617773056,
      0.03088606707751751,
      -0.06523565948009491,
      -0.016205336898565292,
      0.007269527297466993,
      -0.05995727702975273,
      0.01967180334031582,
      0.1288415491580963,
      0.013442602939903736,
      0.10525618493556976,
      -0.04514172300696373,
      -0.008060900494456291,
      -0.00041201847488991916,
      -0.04191570356488228,
      0.07408570498228073,
      0.035919204354286194,
      -0.0008224948542192578,
      0.031047651544213295,
      0.036507587879896164,
      0.08808060735464096,
      -0.09427934139966965,
      0.06307456642389297,
      0.0024699470959603786,
      -0.020706860348582268,
      -0.0320550762116909,
      -0.030495941638946533,
      -0.007165372371673584,
      0.0061879875138401985,
      -0.06962088495492935,
      -0.0052798050455749035,
      0.0017709920648485422,
      0.027592111378908157,
      -0.03923426568508148,
      0.015101340599358082,
      -0.006190693471580744,
      -0.03952033445239067,
      -0.005956520326435566,
      -0.04680563881993294,
      -0.021563058719038963,
      -0.010334862396121025,
      0.09588388353586197,
      -0.008995006792247295,
      -0.021463289856910706,
      0.027807001024484634,
      -0.045215263962745667,
      0.06675594300031662,
      0.03550606966018677,
      -0.041086744517087936,
      0.030398407950997353,
      0.07052914798259735,
      0.0041006444953382015,
      0.018272951245307922,
      -0.055362261831760406,
      0.02847766876220703,
      0.035612333565950394,
      -0.05115959048271179,
      0.030812246724963188,
      -0.056411731988191605,
      0.06971287727355957,
      -0.019028574228286743,
      -0.022862853482365608,
      -0.03558748960494995,
      0.05626615136861801,
      0.06702118366956711,
      0.023129578679800034,
      -0.0712568461894989,
      -0.01978805661201477,
      0.006323134992271662,
      0.04770684242248535,
      0.022390391677618027,
      -0.0039297533221542835,
      0.034021031111478806,
      -0.05216652899980545,
      0.03560706228017807,
      -0.0015615524025633931,
      0.059914760291576385,
      0.057462140917778015,
      -0.03760503977537155,
      -0.0029537836089730263,
      0.0017191122751682997,
      -0.03354407846927643,
      -0.002793879248201847,
      -0.0011537092505022883,
      -0.022591326385736465,
      0.038329578936100006,
      0.01795128360390663,
      0.03189576789736748,
      0.04267772287130356,
      0.041993916034698486,
      -0.00726739689707756,
      0.018655899912118912,
      -0.03792489320039749,
      0.04340388998389244,
      -0.0039159259758889675,
      0.03036205843091011,
      0.017359109595417976,
      0.03391224890947342,
      0.008797588758170605,
      0.034428395330905914,
      0.02092023938894272,
      -0.05736811086535454,
      -0.026931174099445343,
      -0.08363419771194458,
      -0.046737853437662125,
      -0.06566573679447174,
      0.08795396238565445,
      0.007953282445669174,
      -0.04860735312104225,
      -0.03505880758166313,
      0.06709960848093033,
      -0.005095382686704397,
      0.003007077146321535,
      -0.08205409348011017,
      0.00533714983612299,
      0.04802662506699562,
      0.10367308557033539,
      -0.03154231980443001,
      -0.04477575793862343,
      0.012074027210474014,
      0.08436697721481323,
      -0.01598658412694931,
      -0.008369817398488522,
      -0.04461017996072769
    ],
    [
      0.022989215329289436,
      0.056465890258550644,
      -0.05457388237118721,
      0.021781697869300842,
      -0.009680485352873802,
      0.030169935896992683,
      -0.02834055945277214,
      -0.002666676649823785,
      -0.09601376950740814,
      0.01042681373655796,
      0.016599182039499283,
      -0.020426010712981224,
      -0.039579834789037704,
      0.08181849867105484,
      -0.021629516035318375,
      0.007051926106214523,
      0.06899596005678177,
      0.061599645763635635,
      -0.002270615193992853,
      0.009282474406063557,
      0.049542736262083054,
      -0.004926587920635939,
      0.006463566329330206,
      -0.019038846716284752,
      -0.022772671654820442,
      -0.03614535555243492,
      -0.028772462159395218,
      -0.015419366769492626,
      -0.028125837445259094,
      -0.012823405675590038,
      0.01358056254684925,
      -0.016430700197815895,
      0.03409953415393829,
      -0.006551019381731749,
      0.048607319593429565,
      0.0721224918961525,
      -0.023453252390027046,
      -0.008426464162766933,
      0.010640561580657959,
      0.09426307678222656,
      -0.03120545670390129,
      0.003405427560210228,
      0.047623954713344574,
      0.003978414461016655,
      0.0026658489368855953,
      0.0074324654415249825,
      0.0010732744121924043,
      -0.06057211384177208,
      -0.00858050025999546,
      0.041940126568078995,
      -0.09079659730195999,
      -0.0229845829308033,
      -0.012960238382220268,
      0.07932677865028381,
      -0.00999767892062664,
      0.056963082402944565,
      -0.05609433352947235,
      0.07528126239776611,
      -0.012158039957284927,
      0.032526902854442596,
      -0.030404075980186462,
      0.02263609878718853,
      -0.025128984823822975,
      0.012660481035709381,
      -0.0033008342143148184,
      -0.021891381591558456,
      -0.02378685399889946,
      0.03357067331671715,
      0.01628873683512211,
      -0.024980703368782997,
      -0.08088920265436172,
      0.008177434094250202,
      -0.07092474400997162,
      0.022532805800437927,
      0.04668724909424782,
      -0.04305378347635269,
      -0.056924089789390564,
      0.09630756080150604,
      0.04734942317008972,
      -0.010007204487919807,
      0.002974849194288254,
      -0.04146505892276764,
      0.056025441735982895,
      0.02875748835504055,
      0.04033111408352852,
      0.016301466152071953,
      -0.02724601700901985,
      0.03885478153824806,
      -0.015769148245453835,
      0.08279456198215485,
      0.011470053344964981,
      -0.025315839797258377,
      0.0792507454752922,
      0.04680723696947098,
      0.04486938938498497,
      -0.003825306659564376,
      0.013966873288154602,
      0.050656214356422424,
      0.006817909423261881,
      0.052320413291454315,
      0.025205165147781372,
      -0.009339208714663982,
      0.02241392619907856,
      -0.029549142345786095,
      0.04601564258337021,
      -0.022444438189268112,
      -0.04669233411550522,
      -0.02889164537191391,
      0.06662704795598984,
      0.02840692363679409,
      -0.006014825310558081,
      -0.016012364998459816,
      -0.04041869938373566,
      -0.05350957810878754,
      0.009492945857346058,
      -0.03263178840279579,
      -0.01886293850839138,
      0.0025579892098903656,
      0.01997738890349865,
      0.03771061450242996,
      -0.04349442198872566,
      -0.026698868721723557,
      -0.049802325665950775,
      0.024753844365477562,
      0.02191094495356083,
      -0.017482498660683632,
      -0.003290867432951927,
      -0.08849883079528809,
      -0.02624240517616272,
      0.0013448743848130107,
      0.05350630357861519,
      0.011809219606220722,
      -0.03246987611055374,
      -0.03334537148475647,
      -0.005502055399119854,
      0.058381903916597366,
      0.06461045891046524,
      -0.01787448301911354,
      0.07971076667308807,
      0.03402987867593765,
      0.04895474761724472,
      -0.020276682451367378,
      0.012246324680745602,
      -0.05186758190393448,
      -0.10115012526512146,
      0.028987063094973564,
      0.034697894006967545,
      0.0027878640685230494,
      0.007224717177450657,
      -0.017705151811242104,
      -0.0057085733860731125,
      -0.008943322114646435,
      0.032146234065294266,
      0.02627834863960743,
      0.007814157754182816,
      0.04745004326105118,
      -0.027728984132409096,
      -0.08704622834920883,
      -0.07015140354633331,
      -0.05530708655714989,
      0.029957767575979233,
      -0.02919924445450306,
      -0.020184095948934555,
      0.020198436453938484,
      0.007332383189350367,
      0.08020728826522827,
      -0.031739573925733566,
      -0.015926990658044815,
      -0.04400455951690674,
      0.02244354598224163,
      -0.033353887498378754,
      0.034005057066679,
      -0.05967266112565994,
      -0.016899239271879196,
      0.014924995601177216,
      0.0019369377987459302,
      0.0017307994421571493,
      -0.018669290468096733,
      0.08193285018205643,
      0.01704137772321701,
      0.02915104292333126,
      -0.010815796442329884,
      0.01620456390082836,
      -0.04266200587153435,
      0.02360689267516136,
      -0.021830454468727112,
      -0.025497332215309143,
      -0.015816466882824898,
      0.03766709193587303,
      0.03971176967024803,
      0.019655633717775345,
      -0.01991298608481884,
      0.040443409234285355,
      -0.037703610956668854,
      -0.02097557671368122,
      -0.0725826844573021,
      -0.010283553041517735,
      0.014435933902859688,
      -0.09771819412708282,
      0.08287529647350311,
      0.04537308216094971,
      -0.04382270947098732,
      0.027880018576979637,
      -0.014022096991539001,
      -0.0603407546877861,
      -0.014337394386529922,
      -0.027422485873103142,
      -0.08296747505664825,
      0.017878776416182518,
      0.04940805956721306,
      -0.03369696065783501,
      0.015875471755862236,
      -0.030943090096116066,
      0.00580688426271081,
      -0.043759677559137344,
      0.04600747674703598,
      0.002160742413252592,
      0.025116460397839546,
      -0.06288666278123856,
      0.06972609460353851,
      -0.0028661899268627167,
      -0.02398866042494774,
      -0.007606761064380407,
      -0.021502315998077393,
      0.0698944702744484,
      0.04198941960930824,
      0.0007988810539245605,
      0.07824944704771042,
      -0.05301092565059662,
      0.004927364643663168,
      0.06909521669149399,
      0.09285502135753632,
      -0.021802078932523727,
      0.019798744469881058,
      -0.06735300272703171,
      0.05538630113005638,
      -0.055106159299612045,
      0.03619662672281265,
      -0.02702888660132885,
      -0.02544744871556759,
      0.001351214712485671,
      0.002338424790650606,
      0.04935077950358391,
      -0.03507290780544281,
      -0.02817993238568306,
      0.036725930869579315,
      -0.006262633483856916,
      0.05390532314777374,
      0.005398123525083065,
      -0.0435912162065506,
      0.01827259175479412,
      0.07179036736488342,
      0.01837027072906494,
      0.033429473638534546,
      0.09053106606006622,
      -0.041985899209976196,
      0.04493807256221771,
      0.03243304789066315,
      -0.07453658431768417,
      0.03458510711789131,
      0.02705349586904049,
      0.007432830985635519,
      -0.027347460389137268,
      -0.015282347798347473,
      -0.007816420868039131,
      0.0384209118783474,
      0.0328807532787323,
      -0.044819463044404984,
      0.07076261192560196,
      0.011976725421845913,
      0.014757067896425724,
      -0.014205615036189556,
      -0.06675887852907181,
      -0.012559270486235619,
      0.05612185224890709,
      0.06357933580875397,
      0.07051743566989899,
      -0.03386590629816055,
      0.029122451320290565,
      0.032451972365379333,
      0.003179238410666585,
      -0.03548595309257507,
      0.040932267904281616,
      0.043072644621133804,
      -0.00361974211409688,
      -0.008434685878455639,
      0.028239987790584564,
      0.006355488207191229,
      -0.01788647472858429,
      -0.07025302201509476,
      0.004634183831512928,
      -0.02828329987823963,
      0.016059070825576782,
      0.028974808752536774,
      0.024920964613556862,
      -0.01973884552717209,
      -0.022980758920311928,
      -0.0017255052225664258,
      -0.03822498396039009,
      -0.04035314917564392,
      -0.07488387078046799,
      -0.040182411670684814,
      -0.0568963959813118,
      -0.031866446137428284,
      -0.028233949095010757,
      0.013534869067370892,
      0.0833335816860199,
      -0.024263598024845123,
      0.045343343168497086,
      -0.004072497598826885,
      -0.034668441861867905,
      0.0178744588047266,
      0.07239279896020889,
      0.07537201046943665,
      -0.0021797523368149996,
      0.020368967205286026,
      -0.015255071222782135,
      2.0991545170545578e-05,
      -0.00858176127076149,
      -0.02999468706548214,
      -0.06701686978340149,
      -0.070888452231884,
      0.03980923071503639,
      0.004091942682862282,
      0.08512535691261292,
      -0.008707311935722828,
      0.03995966538786888,
      -0.0018617892637848854,
      0.004789403174072504,
      0.06822452694177628,
      0.04954054206609726,
      -0.012176446616649628,
      0.027235321700572968,
      -0.05151038244366646,
      0.06613580137491226,
      0.0629395991563797,
      0.004564742092043161,
      0.05027740076184273,
      -0.052494436502456665,
      0.08383767306804657,
      0.0025955194141715765,
      0.07365233451128006,
      0.02569199539721012,
      -0.039197374135255814,
      0.06937020272016525,
      8.641382009955123e-06,
      0.0360594168305397,
      -0.04662046208977699,
      -0.02870246395468712,
      -0.02471645176410675,
      -0.047375861555337906,
      -0.02236657589673996,
      0.030923863872885704,
      -0.015434100292623043,
      0.05079324170947075,
      0.01597592793405056,
      -0.0837465152144432,
      -0.04301278665661812,
      0.010726269334554672,
      0.030441181734204292,
      -0.0198545902967453,
      0.1340300738811493,
      0.024699945002794266,
      0.0277814082801342,
      0.10972100496292114,
      0.016250425949692726,
      0.04021980240941048,
      0.04098091647028923,
      0.00427975133061409,
      0.09223422408103943,
      0.042742788791656494,
      -0.06456150114536285,
      0.05773225799202919,
      0.0621064268052578,
      0.01818067952990532,
      0.030341865494847298,
      -0.022284161299467087,
      0.11188614368438721,
      -0.0312810055911541,
      0.025197627022862434,
      -0.01988445594906807,
      0.07049600034952164,
      0.029537947848439217,
      -0.0836087167263031,
      -0.009151372127234936,
      -0.048781868070364,
      -0.06050919368863106,
      -0.04692642390727997,
      0.02470616064965725,
      -0.06293751299381256,
      0.040051061660051346,
      0.019278516992926598,
      -0.09477820992469788,
      -0.05175558105111122,
      0.014536956325173378,
      0.018015410751104355,
      0.016800973564386368,
      0.04355012997984886,
      0.07364746928215027,
      0.00044707380584441125,
      -0.05462091416120529,
      -0.06708560138940811,
      -0.027172168716788292,
      0.06635768711566925,
      0.05351370573043823,
      -0.013689938001334667,
      0.03186884894967079,
      0.0883495956659317,
      -0.0559646300971508,
      -0.003587024752050638,
      -0.018079640343785286,
      -0.11028452217578888,
      -0.02163226716220379,
      -0.06403305381536484,
      0.005584332160651684,
      -0.05345446988940239,
      0.026606909930706024,
      0.006286089774221182,
      -0.025700658559799194,
      0.032431211322546005,
      -0.01575571484863758,
      -0.005335355177521706,
      -0.0016162500251084566,
      -0.009678528644144535,
      -0.10642440617084503,
      0.00621075090020895,
      -0.036608561873435974,
      0.02727651782333851,
      0.030421743169426918,
      0.025808731094002724,
      -0.03949127718806267,
      0.040075916796922684,
      0.12448983639478683,
      -0.02254640869796276,
      -0.03294392675161362,
      0.07087299227714539,
      -0.003077372442930937,
      -0.010907618328928947,
      -0.00753730209544301,
      0.0603930726647377,
      -0.05315704643726349,
      0.00799093209207058,
      0.009686618112027645,
      -0.0518016591668129,
      -0.013753284700214863,
      -0.03891381993889809,
      -0.03686442971229553,
      -0.02346978522837162,
      0.07373543828725815,
      -0.047864194959402084,
      0.023763347417116165,
      -0.053257472813129425,
      0.022621050477027893,
      -0.004257683642208576,
      0.016534099355340004,
      -0.010469883680343628,
      0.027951737865805626,
      0.0033336214255541563,
      -0.01346367783844471,
      0.06611926853656769,
      0.07286377251148224,
      -0.09590877592563629,
      -0.04721997678279877,
      0.018419327214360237,
      0.035361338406801224,
      -0.0027016669046133757,
      0.039872583001852036,
      -0.08767250180244446,
      0.044464513659477234,
      -0.019547058269381523,
      0.021519476547837257,
      -0.06478756666183472,
      0.007892926223576069,
      0.051042284816503525,
      -0.05484670028090477,
      -0.07984652370214462,
      0.07986738532781601,
      -0.03894399106502533,
      0.029687564820051193,
      0.036788538098335266,
      -0.029080504551529884,
      -0.024138448759913445,
      0.05051862820982933,
      0.004785849712789059,
      -0.001477935933507979,
      0.05365711450576782,
      0.047238606959581375,
      0.053474146872758865,
      -0.0424787774682045,
      -0.05021411180496216,
      -0.05372007563710213,
      0.018115244805812836,
      0.02858450450003147,
      -0.007227698806673288,
      0.008495665155351162,
      0.018654288724064827,
      -0.045200712978839874,
      0.021089674904942513,
      -0.03780148923397064,
      0.057590991258621216,
      0.005828972440212965,
      -0.03568105027079582,
      -0.02842332236468792
    ],
    [
      -0.046401385217905045,
      0.010024292394518852,
      -0.06977254897356033,
      0.0286889486014843,
      -0.04340225085616112,
      0.019480662420392036,
      -0.022631648927927017,
      -0.04819869622588158,
      0.017343921586871147,
      0.017556864768266678,
      -0.023111918941140175,
      0.02503909543156624,
      -0.043008510023355484,
      -0.011777261272072792,
      0.001867083366960287,
      0.024239102378487587,
      0.10444725304841995,
      0.024953002110123634,
      0.044964633882045746,
      0.0019031221745535731,
      -0.05946691706776619,
      0.09054908156394958,
      0.007404271513223648,
      -0.03631633520126343,
      -0.013140750117599964,
      -0.02150186523795128,
      -0.011431703343987465,
      -0.02358880825340748,
      0.015508784912526608,
      -0.05910752713680267,
      0.09716843068599701,
      0.002790208440274,
      -0.09987427294254303,
      0.04942924901843071,
      0.041690923273563385,
      -0.029313325881958008,
      0.045419223606586456,
      0.06452634930610657,
      0.019715096801519394,
      -0.01326889730989933,
      0.04661351442337036,
      0.08218742161989212,
      0.041259292513132095,
      -0.03481217101216316,
      0.0230210330337286,
      -0.050374001264572144,
      -0.08833085000514984,
      -0.028238486498594284,
      -0.06941825151443481,
      -0.014820463955402374,
      0.07698008418083191,
      0.029408495873212814,
      -0.028579169884324074,
      0.08463609963655472,
      -0.00826694630086422,
      -0.023949580267071724,
      0.11484703421592712,
      -0.009212306700646877,
      0.04197327047586441,
      0.013643786311149597,
      0.012528512626886368,
      -0.013743274845182896,
      -0.017669249325990677,
      0.02125508151948452,
      0.030690010637044907,
      0.017464876174926758,
      -0.014851397834718227,
      -0.023392729461193085,
      -0.02983711287379265,
      0.07870978862047195,
      -0.060471344739198685,
      0.05860906466841698,
      -0.05174826830625534,
      -0.027473842725157738,
      0.03665301203727722,
      -0.007088709156960249,
      -0.047754060477018356,
      0.06542620807886124,
      -0.021296758204698563,
      0.05829402059316635,
      -0.053606804460287094,
      -0.07334622740745544,
      -0.07989949733018875,
      0.010522328317165375,
      0.048906274139881134,
      -0.03772847726941109,
      0.03147478401660919,
      0.010673514567315578,
      -0.04394041746854782,
      -0.07066846638917923,
      0.008876985870301723,
      0.03488330915570259,
      -0.0031074497383087873,
      -0.028613803908228874,
      0.06283500790596008,
      0.008942828513681889,
      0.08758872002363205,
      0.017523739486932755,
      0.007796174380928278,
      0.06282466650009155,
      -0.0150179797783494,
      -0.10249512642621994,
      0.007804441265761852,
      -0.007241212297230959,
      -0.016532840207219124,
      0.011290023103356361,
      -0.07923021167516708,
      -0.020268434658646584,
      0.02689054235816002,
      -0.027082843706011772,
      0.06855589896440506,
      0.00318813044577837,
      -0.035038456320762634,
      0.0950288325548172,
      0.03377247974276543,
      -0.04260377213358879,
      0.0015682050725445151,
      0.03882250562310219,
      0.038705531507730484,
      -0.04231950640678406,
      -0.05381551757454872,
      -0.03803040832281113,
      0.06962480396032333,
      0.1162128672003746,
      -0.03168145939707756,
      -0.02475479617714882,
      -0.030469847843050957,
      -0.01636543683707714,
      0.07099700719118118,
      0.021136801689863205,
      0.1483345478773117,
      -0.020106976851820946,
      -0.01010680478066206,
      0.01014008466154337,
      -0.07558290660381317,
      0.01543811522424221,
      0.01792406663298607,
      -0.07020460069179535,
      0.0463605672121048,
      -0.0007122327806428075,
      0.03354838490486145,
      -0.011509433388710022,
      -0.023811660706996918,
      -0.03553082421422005,
      -0.059186212718486786,
      -0.019820986315608025,
      -0.029750801622867584,
      0.032012373208999634,
      -0.024535352364182472,
      0.02490168809890747,
      0.02864750288426876,
      -0.062109921127557755,
      0.0017222071764990687,
      0.002476179040968418,
      -0.08319632709026337,
      0.0033924190793186426,
      0.0657929927110672,
      -0.016449090093374252,
      -0.07725536823272705,
      -0.030447496101260185,
      0.06375812739133835,
      0.04347854480147362,
      0.02389545552432537,
      -0.022027142345905304,
      -0.029787948355078697,
      0.02330305241048336,
      -0.03734628111124039,
      -0.011137163266539574,
      0.03065607137978077,
      -0.020592058077454567,
      0.05230038985610008,
      0.06750351190567017,
      0.012144263833761215,
      0.06070386618375778,
      0.021203037351369858,
      0.1057862937450409,
      0.025705629959702492,
      0.04613012447953224,
      0.032071515917778015,
      0.08811464160680771,
      -0.045154720544815063,
      -0.10775541514158249,
      0.022623082622885704,
      0.012151430360972881,
      -0.04433465376496315,
      0.04534810036420822,
      0.05340367183089256,
      -0.03382517769932747,
      0.028083566576242447,
      0.015930984169244766,
      0.09008472412824631,
      -0.04804752394556999,
      -0.059982962906360626,
      -0.014608544297516346,
      -0.030451854690909386,
      0.009047029539942741,
      0.02659429796040058,
      -0.02140224538743496,
      0.06536073982715607,
      0.07883661240339279,
      0.0409938208758831,
      -0.02996758744120598,
      0.0004626228183042258,
      0.06802669912576675,
      -0.07655822485685349,
      0.0021013326477259398,
      -0.02467024326324463,
      -0.07491951435804367,
      0.032148327678442,
      -0.09822922945022583,
      -0.11122338473796844,
      0.003504639258608222,
      -0.05534524470567703,
      -0.03894374519586563,
      -0.04785334691405296,
      0.014352438971400261,
      0.02553539350628853,
      0.04246709495782852,
      0.041583310812711716,
      0.022657860070466995,
      0.0016501166392117739,
      0.014286162331700325,
      -0.05124920234084129,
      -0.061277035623788834,
      0.03515180945396423,
      0.04577349126338959,
      0.03838091716170311,
      0.04985837638378143,
      -0.014614013954997063,
      -0.03211505338549614,
      0.004844139330089092,
      0.09029699116945267,
      0.09681037068367004,
      -0.09720625728368759,
      0.00915834866464138,
      -0.040480971336364746,
      0.006875837687402964,
      0.03681771457195282,
      0.13053135573863983,
      0.043848779052495956,
      0.003559047356247902,
      -0.020672108978033066,
      0.013784727081656456,
      -0.012001274153590202,
      -0.00791468471288681,
      -0.011761907488107681,
      -0.013748141005635262,
      -0.030982250347733498,
      0.09928076714277267,
      0.05094398930668831,
      -0.0023675174452364445,
      0.005493789445608854,
      0.053159840404987335,
      0.007915535941720009,
      -0.052069585770368576,
      0.020094098523259163,
      -0.020833736285567284,
      -0.050369057804346085,
      0.05612395331263542,
      -0.03550103306770325,
      -0.0250247735530138,
      -0.02410818636417389,
      -0.027430325746536255,
      0.04608318582177162,
      -0.0031850237865000963,
      0.09018474072217941,
      0.007429896388202906,
      -0.01313454657793045,
      -0.04358374699950218,
      0.04761403426527977,
      0.030556833371520042,
      0.007949578575789928,
      -0.06608343869447708,
      -0.02284100465476513,
      0.015188205055892467,
      -0.06118638068437576,
      0.004977487493306398,
      -0.05685224384069443,
      -0.03318171203136444,
      -0.12914080917835236,
      0.024574464187026024,
      0.0530950203537941,
      0.06712833046913147,
      -0.026856621727347374,
      -0.0283205583691597,
      -0.06792958080768585,
      0.013233751058578491,
      -0.0015365155413746834,
      0.0246631670743227,
      0.02731739543378353,
      0.028595684096217155,
      0.07214679569005966,
      0.0016517381882295012,
      0.011491465382277966,
      -0.02062401734292507,
      -0.009408003650605679,
      -0.0017546572489663959,
      -0.02757076732814312,
      -0.05430437624454498,
      0.0704818144440651,
      -0.048408593982458115,
      0.04322570934891701,
      0.03729550912976265,
      -0.00849850382655859,
      -0.0041382648050785065,
      -0.006969303358346224,
      -0.03680906444787979,
      -0.007496444508433342,
      0.05965520441532135,
      -0.03865252807736397,
      0.017147939652204514,
      -0.031118592247366905,
      -0.045282281935214996,
      0.08750227838754654,
      0.004001394379884005,
      0.045804183930158615,
      -0.10142821818590164,
      -0.007512574549764395,
      -0.035652823746204376,
      0.027056967839598656,
      -0.038808513432741165,
      -0.018963461741805077,
      -0.01241337414830923,
      0.022389424964785576,
      -0.01324622705578804,
      0.0023026280105113983,
      0.027417907491326332,
      -0.03425408527255058,
      -0.021981703117489815,
      -0.0024949500802904367,
      -0.04826926067471504,
      0.058822862803936005,
      0.03527011349797249,
      -0.06507183611392975,
      -0.021538086235523224,
      0.037702783942222595,
      -0.09289003163576126,
      -0.06424392014741898,
      0.017760317772626877,
      -0.05866211652755737,
      0.008781429380178452,
      -0.04363926500082016,
      0.047689154744148254,
      -0.09226245433092117,
      -0.004023053217679262,
      -0.0025840261951088905,
      0.04377010464668274,
      -0.0398678220808506,
      -0.02449026145040989,
      -0.0815049260854721,
      -0.028063520789146423,
      -0.024220336228609085,
      0.009618708863854408,
      0.13819220662117004,
      0.08270681649446487,
      -0.0031205592676997185,
      -0.03543234243988991,
      0.0029508459847420454,
      -0.022944089025259018,
      0.011268849484622478,
      0.02105170115828514,
      -0.03251025080680847,
      -0.019329333677887917,
      0.037592899054288864,
      -0.02784937620162964,
      -0.051672425121068954,
      0.02158348634839058,
      -0.00200070533901453,
      -0.0034392420202493668,
      -0.0034665295388549566,
      -0.008785208687186241,
      -0.0027135221753269434,
      0.04234922304749489,
      -0.039719365537166595,
      -0.021571287885308266,
      -0.05577109754085541,
      -0.0401744581758976,
      0.0068432120606303215,
      -0.02027679979801178,
      0.07449304312467575,
      0.04645261541008949,
      -0.017342928797006607,
      -0.014106429181993008,
      -0.05030861124396324,
      0.020261550322175026,
      -0.015884315595030785,
      -0.026356348767876625,
      -0.04041820019483566,
      0.018465442582964897,
      0.007953614927828312,
      -0.049017082899808884,
      0.03645830228924751,
      -0.02707790397107601,
      -0.03229691460728645,
      0.02412695623934269,
      -0.10448234528303146,
      0.028166376054286957,
      -0.01885385438799858,
      0.06733199208974838,
      -0.015435531735420227,
      -0.020605502650141716,
      -0.03390144929289818,
      -0.008083069697022438,
      0.001955031882971525,
      0.006331504788249731,
      -0.005287511739879847,
      0.0664675310254097,
      0.0700705498456955,
      -0.012894394807517529,
      0.024775490164756775,
      0.033418137580156326,
      0.03589768335223198,
      0.07544733583927155,
      0.024202778935432434,
      -0.021159987896680832,
      -0.0014364093076437712,
      -0.03871842101216316,
      0.08958794921636581,
      -0.0863274559378624,
      0.0621911846101284,
      0.10586400330066681,
      -0.0009318740339949727,
      -0.03207545354962349,
      -0.018752064555883408,
      0.037024322897195816,
      0.06173586845397949,
      -0.0020920871756970882,
      0.049855682998895645,
      -0.011256185360252857,
      0.003440443892031908,
      0.029088051989674568,
      0.016225865110754967,
      0.005871705710887909,
      0.002179010771214962,
      -0.015246274881064892,
      -0.045827679336071014,
      0.028593499213457108,
      0.0022548905108124018,
      0.030567407608032227,
      0.03384826332330704,
      0.0002580131695140153,
      0.022203266620635986,
      -0.06233574450016022,
      0.034071214497089386,
      -0.0044590481556952,
      0.0012117482256144285,
      -0.02812308818101883,
      -0.01021734531968832,
      0.007946458645164967,
      -0.10383910685777664,
      -0.03543107956647873,
      0.04806917533278465,
      0.022395463660359383,
      0.057568155229091644,
      -0.0946209654211998,
      0.013258952647447586,
      -0.00440229382365942,
      0.08406385034322739,
      -0.008060235530138016,
      -0.07646623253822327,
      0.04946335405111313,
      -0.0481996163725853,
      -0.05619991570711136,
      0.01590609736740589,
      0.018474232405424118,
      -0.017648397013545036,
      0.043460749089717865,
      -0.08264841139316559,
      0.034741662442684174,
      -0.02164873667061329,
      -0.007269210182130337,
      -0.05198424682021141,
      0.037629950791597366,
      0.03535575792193413,
      0.00997618492692709,
      -0.03323492407798767,
      -0.006356703583151102,
      0.018509358167648315,
      0.0008687748922966421,
      -0.04685891792178154,
      -0.07750070095062256,
      -0.032810620963573456,
      -0.025122564285993576,
      -0.0024517360143363476,
      0.03530600294470787,
      -0.051634687930345535,
      0.0527660995721817,
      0.04747559875249863,
      -0.03425445407629013,
      -0.03943141549825668,
      -0.09642849862575531,
      0.05472877249121666,
      0.017166465520858765,
      -0.019441787153482437,
      -0.042750127613544464,
      -0.06306717544794083,
      -0.02472410537302494,
      0.01996678113937378,
      -0.02168363519012928,
      -0.010646832175552845,
      0.07300024479627609,
      0.005886750761419535,
      0.024419289082288742
    ],
    [
      -0.03693138062953949,
      -0.08063666522502899,
      -0.10907784104347229,
      0.004868077114224434,
      -0.015263576991856098,
      -0.0255543552339077,
      0.01585618034005165,
      -0.04359747841954231,
      -0.04016506299376488,
      -0.011094562709331512,
      0.08023681491613388,
      -0.06871554255485535,
      -0.035015713423490524,
      -0.08415790647268295,
      -0.02350969985127449,
      0.024184148758649826,
      -0.03747158870100975,
      0.022850193083286285,
      0.001061231829226017,
      -0.03556353226304054,
      -0.03759307414293289,
      -0.05986404791474342,
      0.08715613931417465,
      0.026901675388216972,
      -0.009261622093617916,
      -0.04087565094232559,
      -0.008224896155297756,
      -0.01806882582604885,
      -0.04778129234910011,
      -0.11554709076881409,
      -0.021261727437376976,
      0.021614082157611847,
      -0.01405792124569416,
      -0.030113959684967995,
      0.04606210067868233,
      0.07922240346670151,
      -0.0014973132638260722,
      -0.05394446849822998,
      0.06351630389690399,
      0.03691699728369713,
      0.006481178104877472,
      0.007858327589929104,
      0.024107996374368668,
      -0.027117447927594185,
      0.010349735617637634,
      0.00736550148576498,
      -0.05105815455317497,
      -0.04031728208065033,
      0.06173615902662277,
      0.014839515089988708,
      0.05802735686302185,
      0.03230351209640503,
      0.0742880254983902,
      0.07186482846736908,
      -0.053555361926555634,
      -0.023576846346259117,
      0.01973181962966919,
      0.007548641879111528,
      0.07639646530151367,
      0.03715251758694649,
      0.07631521672010422,
      -0.06588379293680191,
      0.015390896238386631,
      0.021583087742328644,
      -0.026188038289546967,
      -0.018692348152399063,
      -0.06666337698698044,
      0.03640209510922432,
      0.0206928588449955,
      -0.00975582841783762,
      -0.0306764617562294,
      -0.008470729924738407,
      0.0012602541828528047,
      0.03398842737078667,
      -0.031207574531435966,
      0.012664565816521645,
      0.04888879880309105,
      -0.07747524976730347,
      0.0879029855132103,
      0.0032527560833841562,
      0.07163586467504501,
      0.007967938669025898,
      0.0473940335214138,
      0.042028479278087616,
      0.009789994917809963,
      0.043800003826618195,
      0.08627262711524963,
      0.036944180727005005,
      0.047512784600257874,
      -0.01673157885670662,
      -0.009920326061546803,
      0.0028883586637675762,
      0.05767664685845375,
      -0.006049456540495157,
      0.012154493480920792,
      0.00228926632553339,
      0.05103076994419098,
      -0.010072510689496994,
      0.007913662120699883,
      0.07669800519943237,
      0.006392848677933216,
      0.04626433178782463,
      0.06070888414978981,
      0.0022705700248479843,
      -0.10029193013906479,
      0.011465060524642467,
      0.023573346436023712,
      -0.03847189620137215,
      -0.010689475573599339,
      0.03366769105195999,
      -0.04086717218160629,
      -0.05602550134062767,
      0.018862413242459297,
      -0.010112631134688854,
      0.003843248588964343,
      -0.08617603033781052,
      0.04925646260380745,
      0.10327322781085968,
      0.009422102011740208,
      0.0645114928483963,
      0.05386820435523987,
      0.003563561011105776,
      -0.049881234765052795,
      0.051554396748542786,
      -0.013485893607139587,
      0.02007448486983776,
      0.021281441673636436,
      -0.024877414107322693,
      0.03258519992232323,
      0.013296756893396378,
      0.006578458473086357,
      -0.016401953995227814,
      -0.07732965052127838,
      0.04643132910132408,
      -0.010681290179491043,
      0.06386620551347733,
      -0.0025592297315597534,
      -0.02667774260044098,
      -0.007368249353021383,
      -0.022735148668289185,
      0.06968945264816284,
      -0.024383921176195145,
      -0.030939746648073196,
      0.0011485074646770954,
      -0.029529782012104988,
      0.04545513913035393,
      0.02969171479344368,
      -0.07378668338060379,
      -0.0059709614142775536,
      -0.0295367781072855,
      -0.11640553921461105,
      -0.0007853295537643135,
      0.08784177899360657,
      0.06151961162686348,
      -0.06916194409132004,
      0.003389668185263872,
      0.03241652250289917,
      -0.00282873073592782,
      -0.003105569863691926,
      0.025783468037843704,
      0.07200389355421066,
      -0.09005532413721085,
      -0.014489268884062767,
      0.034850768744945526,
      0.056834910064935684,
      -0.02444974146783352,
      0.010427242144942284,
      -0.0557125099003315,
      0.010401245206594467,
      -0.041947778314352036,
      -0.043068572878837585,
      0.03949924185872078,
      0.042446963489055634,
      -0.024658355861902237,
      0.026743469759821892,
      0.03471797704696655,
      0.0687234103679657,
      -0.060023099184036255,
      -0.07304766029119492,
      -0.01272531971335411,
      0.011275842785835266,
      0.05859942361712456,
      -0.03387153893709183,
      -0.02039257436990738,
      -0.015560519881546497,
      0.06315632164478302,
      0.009908346459269524,
      0.029376838356256485,
      0.06807474792003632,
      0.056610267609357834,
      -0.10655800998210907,
      0.07624372094869614,
      -0.06765257567167282,
      -0.025807416066527367,
      0.02767021209001541,
      -0.01725650206208229,
      0.02177383564412594,
      -0.002790280384942889,
      -0.04533451423048973,
      0.06673166155815125,
      0.022473864257335663,
      0.02570732869207859,
      0.016920605674386024,
      0.016511699184775352,
      0.04789751395583153,
      0.038865573704242706,
      0.08734440803527832,
      -0.04218180477619171,
      -0.06033990904688835,
      -0.013801105320453644,
      -0.012067779898643494,
      0.0017429772997274995,
      0.022228918969631195,
      -0.0037504052743315697,
      -0.0032546839211136103,
      0.057915206998586655,
      0.04075644910335541,
      -0.010714230127632618,
      -0.03959169238805771,
      0.00446300208568573,
      -0.002466428093612194,
      -0.015194020234048367,
      0.026968952268362045,
      0.029133064672350883,
      0.03310469910502434,
      -0.02388666197657585,
      0.029062850400805473,
      0.030826043337583542,
      -0.03384096175432205,
      -0.059018708765506744,
      -0.02128458581864834,
      0.013005638495087624,
      0.07588754594326019,
      -0.026534395292401314,
      -0.001438898965716362,
      0.024473555386066437,
      -0.08685237169265747,
      0.02760263904929161,
      -0.052044015377759933,
      0.016890760511159897,
      -0.01271304115653038,
      0.12277130037546158,
      0.056763648986816406,
      0.0180666446685791,
      0.03895852714776993,
      0.028235802426934242,
      0.030582794919610023,
      0.01758504845201969,
      0.008427842520177364,
      -0.022384099662303925,
      0.08500683307647705,
      0.010791467502713203,
      0.026095926761627197,
      -0.03425506129860878,
      -0.017196740955114365,
      0.045591048896312714,
      -0.01195309404283762,
      -0.0006445201579481363,
      0.01696249097585678,
      0.00439847819507122,
      0.03224305063486099,
      0.01376493088901043,
      -0.021153656765818596,
      -0.010707097128033638,
      -0.06224018335342407,
      -0.007868792861700058,
      0.05752057954668999,
      0.0510212704539299,
      -0.09829770028591156,
      -0.04674910381436348,
      0.029991887509822845,
      0.009134992957115173,
      0.006487485021352768,
      -0.014376797713339329,
      -0.004590071737766266,
      0.027005473151803017,
      -0.04089657962322235,
      -0.023075107485055923,
      0.023050382733345032,
      -0.06583159416913986,
      -0.0009699568036012352,
      -0.053258173167705536,
      0.027057519182562828,
      0.034714292734861374,
      -0.06517438590526581,
      0.007525676395744085,
      0.019557341933250427,
      -0.012254479341208935,
      0.08473476022481918,
      0.00959659181535244,
      0.0744086354970932,
      0.02112426422536373,
      -0.09096238017082214,
      -0.06891448050737381,
      0.006151871755719185,
      0.03187541663646698,
      0.09880274534225464,
      -0.06489741057157516,
      0.07133933156728745,
      -0.040972623974084854,
      -0.03527608513832092,
      0.0375254862010479,
      0.037278592586517334,
      -0.045315057039260864,
      0.04921479523181915,
      0.02450990490615368,
      -0.059564702212810516,
      -0.026832684874534607,
      -0.09052253514528275,
      -0.04772394150495529,
      0.014216382056474686,
      -0.057905666530132294,
      0.011634443886578083,
      -0.059317074716091156,
      0.029994726181030273,
      -0.006117484997957945,
      -0.009418198838829994,
      -0.007356950547546148,
      -0.03611437603831291,
      -0.022488392889499664,
      -0.03773760050535202,
      0.04423265904188156,
      0.0015776612563058734,
      0.03638745844364166,
      0.05312235653400421,
      0.01181277260184288,
      -0.03231862187385559,
      0.01990237645804882,
      -0.07816360890865326,
      0.002372179413214326,
      -0.005415962543338537,
      0.02660646103322506,
      0.07164072245359421,
      -0.031669825315475464,
      -0.001049703685566783,
      -0.021793091669678688,
      -0.012237658724188805,
      -0.014263137243688107,
      -0.04216248542070389,
      0.07496488094329834,
      -0.06034086272120476,
      0.008334045298397541,
      0.024297278374433517,
      -0.04610343649983406,
      -0.07860799878835678,
      -0.04793957248330116,
      -0.019446393474936485,
      0.017020506784319878,
      0.045091480016708374,
      -0.00030711086583323777,
      0.027032263576984406,
      -0.0026246372144669294,
      -0.002614665310829878,
      -0.011958543211221695,
      -0.02988542802631855,
      0.0024191956035792828,
      -0.017005397006869316,
      -0.02680830843746662,
      0.06135651096701622,
      0.04325450211763382,
      -0.02747192047536373,
      0.010104472748935223,
      -0.06286582350730896,
      0.037828389555215836,
      0.002920332597568631,
      0.06967028975486755,
      -0.06653048098087311,
      0.019293205812573433,
      0.047556664794683456,
      0.007729631382972002,
      0.0409911572933197,
      0.01490406971424818,
      -0.009483527392148972,
      0.03881583362817764,
      0.021630678325891495,
      -0.04173817113041878,
      0.009510336443781853,
      0.07300553470849991,
      -0.06686313450336456,
      -0.01961173117160797,
      0.015380097553133965,
      0.06444653123617172,
      -0.012025113217532635,
      0.043307963758707047,
      -0.03955240920186043,
      0.0319598987698555,
      0.012992547824978828,
      0.05769883096218109,
      -0.03937215358018875,
      0.02040691301226616,
      0.043151941150426865,
      0.026632165536284447,
      -0.013635351322591305,
      -0.025451647117733955,
      0.02039390429854393,
      0.017801186069846153,
      -0.04159193113446236,
      -0.04189736023545265,
      -0.03874830901622772,
      0.007140208035707474,
      -0.03685607388615608,
      0.04112567752599716,
      0.0156664177775383,
      -0.04708574712276459,
      -0.007857835851609707,
      0.011640902608633041,
      0.10542963445186615,
      -0.011678523384034634,
      0.08309923112392426,
      -0.0019497983157634735,
      -0.024643490090966225,
      0.013192241080105305,
      0.035325270146131516,
      0.03125804662704468,
      0.053507644683122635,
      0.07105450332164764,
      -0.002550810808315873,
      -0.002921153325587511,
      -0.026586607098579407,
      -0.02927378937602043,
      -0.025964045897126198,
      0.03697298467159271,
      -0.04926479235291481,
      -0.044774651527404785,
      0.04249103367328644,
      -0.025252023711800575,
      0.024395212531089783,
      -0.014715895988047123,
      0.0526764802634716,
      -0.024525202810764313,
      -0.05788985267281532,
      0.00476741511374712,
      0.009475073777139187,
      0.011430189944803715,
      0.03248121216893196,
      -0.07105350494384766,
      -0.00627227034419775,
      0.037068914622068405,
      0.08825807273387909,
      -0.004797917790710926,
      -0.03795299306511879,
      0.03372997045516968,
      0.010220134630799294,
      0.012613043189048767,
      -0.09063814580440521,
      -0.029049908742308617,
      -0.04777953773736954,
      -0.09490375965833664,
      0.059278830885887146,
      -0.0015548146329820156,
      0.04130231961607933,
      -0.020818248391151428,
      0.02960330620408058,
      -0.02746657282114029,
      -0.06286471337080002,
      -0.02372322976589203,
      0.01728886365890503,
      -0.019800160080194473,
      0.02525799721479416,
      -0.021320955827832222,
      -0.03949323669075966,
      -0.018674584105610847,
      0.021316180005669594,
      -0.06771828979253769,
      -0.018759319558739662,
      0.1006200760602951,
      0.009505808353424072,
      -0.03781029209494591,
      0.07765153050422668,
      -0.06317101418972015,
      -0.010600489564239979,
      0.02215813845396042,
      -0.023795953020453453,
      0.05277268588542938,
      -0.015352332964539528,
      -0.01978301629424095,
      -0.0030917865224182606,
      0.04283750429749489,
      0.03923254832625389,
      0.07211630046367645,
      -0.025555795058608055,
      0.026011964306235313,
      0.032587338238954544,
      -0.008221345022320747,
      0.010675342753529549,
      -0.07759793102741241,
      -0.00427200784906745,
      0.07803916186094284,
      0.012168259359896183,
      0.0051909857429564,
      -0.02168251760303974,
      0.03493654727935791,
      0.013014347292482853,
      -0.02099769562482834,
      0.0016247988678514957,
      0.05487082898616791,
      0.06833602488040924,
      0.09318950772285461,
      0.00012430560309439898,
      -0.028745943680405617,
      -0.027761247009038925,
      0.026347510516643524,
      -0.020709048956632614
    ],
    [
      -0.032913390547037125,
      -0.03191502392292023,
      -0.0450575053691864,
      0.03065124899148941,
      0.023100977763533592,
      -0.027323545888066292,
      -0.030556531623005867,
      -0.05339372903108597,
      0.03545078635215759,
      0.03221189230680466,
      0.04907453805208206,
      0.033299561589956284,
      0.009685725905001163,
      -0.054752521216869354,
      -0.06023172289133072,
      0.027207447215914726,
      0.05892470106482506,
      0.04738235101103783,
      -0.015519221313297749,
      0.0250107329338789,
      0.04112151637673378,
      0.007353540509939194,
      -0.05295870080590248,
      0.004029626026749611,
      0.01949303410947323,
      0.0695439949631691,
      0.05795280262827873,
      0.011004695668816566,
      0.0068890792317688465,
      -0.06202327832579613,
      0.09057683497667313,
      -0.019680211320519447,
      -0.06867744773626328,
      0.02474881522357464,
      0.009685602970421314,
      -0.012525436468422413,
      -0.005939309485256672,
      0.012208700180053711,
      0.05983675643801689,
      -0.007952939718961716,
      0.014519144780933857,
      0.02027955837547779,
      -0.031624600291252136,
      -0.009008423425257206,
      -0.01033769827336073,
      -0.006553475745022297,
      0.09542005509138107,
      0.038300078362226486,
      0.01695213094353676,
      0.03635341674089432,
      -0.0962032750248909,
      0.030638860538601875,
      -0.018709955736994743,
      -0.024599993601441383,
      0.08656048774719238,
      -0.015104266814887524,
      -0.0927116721868515,
      0.00907787773758173,
      -0.07376930117607117,
      0.021747637540102005,
      0.04942668229341507,
      0.053911928087472916,
      0.018559860065579414,
      -0.017572488635778427,
      -0.011881683021783829,
      -0.04166989400982857,
      0.030827952548861504,
      -0.05236894264817238,
      0.04236766695976257,
      0.02912203036248684,
      -0.02211703360080719,
      -0.046542178839445114,
      -0.017311014235019684,
      -0.08601837605237961,
      -0.047059930860996246,
      -0.03153141587972641,
      -0.04818258062005043,
      0.007955425418913364,
      0.01391011755913496,
      0.007043503690510988,
      -0.06635813415050507,
      0.05771630257368088,
      -0.03635534644126892,
      -0.09100012481212616,
      0.06170416995882988,
      -0.028770485892891884,
      -0.009544840082526207,
      0.013972554355859756,
      -0.029027368873357773,
      -0.11029643565416336,
      -0.0378127247095108,
      -0.05444129928946495,
      0.032083913683891296,
      0.019997283816337585,
      0.09474927186965942,
      -0.021718241274356842,
      0.06494264304637909,
      0.0350705049932003,
      -0.015863915905356407,
      -0.004179947543889284,
      -0.025930160656571388,
      0.015802400186657906,
      0.00030512557714246213,
      0.059920236468315125,
      0.06639225780963898,
      -0.009900357574224472,
      -0.01163181196898222,
      -0.021971503272652626,
      -0.003802431048825383,
      -0.15006917715072632,
      -0.010728009045124054,
      0.05917856842279434,
      0.06961393356323242,
      -0.02627651020884514,
      -0.12556177377700806,
      -0.05212168022990227,
      0.012177981436252594,
      0.0353173203766346,
      0.05186362937092781,
      0.05577152222394943,
      -0.03551985323429108,
      -0.038619205355644226,
      -0.05421258509159088,
      0.034604791551828384,
      0.051191430538892746,
      -0.058199357241392136,
      -0.02113374136388302,
      0.06749600917100906,
      -0.11094312369823456,
      0.038878001272678375,
      0.02025246061384678,
      0.0006402835133485496,
      0.028025036677718163,
      0.032904621213674545,
      -0.0023247282952070236,
      -0.0341041162610054,
      0.01992233656346798,
      -0.022469276562333107,
      0.0100408885627985,
      0.021619847044348717,
      -0.038425736129283905,
      -0.07005657255649567,
      -0.009564442560076714,
      -0.024420760571956635,
      -0.08516046404838562,
      -0.05499532073736191,
      0.014243216253817081,
      -0.02669699490070343,
      -0.043688513338565826,
      -0.015829648822546005,
      0.0045460243709385395,
      -0.020047763362526894,
      0.013867726549506187,
      -0.012729695066809654,
      0.02676966041326523,
      -0.046274181455373764,
      0.024900421500205994,
      -0.04500555247068405,
      0.011830076575279236,
      -0.004856338258832693,
      -0.009606558829545975,
      -0.012426725588738918,
      -0.06624764949083328,
      -0.0008261882467195392,
      0.014748740009963512,
      0.011682023294270039,
      0.06577667593955994,
      -0.012813765555620193,
      0.037404946982860565,
      -0.06722671538591385,
      0.02056748792529106,
      -0.09641189873218536,
      -0.009467748925089836,
      -0.14880932867527008,
      -0.04655136913061142,
      -0.05399775505065918,
      0.017655156552791595,
      0.010365501046180725,
      0.05443340167403221,
      0.03766012191772461,
      -0.04040561243891716,
      0.05300820246338844,
      0.03503463789820671,
      0.013243586756289005,
      0.04803847894072533,
      -0.07784570753574371,
      0.032191503793001175,
      -0.009377256035804749,
      0.05006064474582672,
      -0.022761179134249687,
      0.00602663354948163,
      -0.066279835999012,
      -0.026220498606562614,
      -0.07461487501859665,
      0.02009684406220913,
      0.002805529162287712,
      -0.047871604561805725,
      0.07117654383182526,
      0.023645441979169846,
      -0.04825207218527794,
      0.028493260964751244,
      -0.04359158128499985,
      -0.015391147695481777,
      -0.014445989392697811,
      0.049181822687387466,
      0.0010736881522461772,
      0.03289571404457092,
      0.0440586619079113,
      -0.009792804718017578,
      0.03287992998957634,
      0.038278479129076004,
      -0.03426453471183777,
      0.02234470844268799,
      0.040008794516325,
      -0.03287884220480919,
      -0.047075625509023666,
      0.08296618610620499,
      -0.02977658621966839,
      0.03580327332019806,
      -0.0377880334854126,
      -0.017182493582367897,
      0.020745709538459778,
      -0.009632538072764874,
      0.03312721475958824,
      -0.058298259973526,
      0.007788925431668758,
      0.0007583455299027264,
      -0.01675623282790184,
      0.0167397428303957,
      0.05380990356206894,
      -0.02125515416264534,
      -0.007504776120185852,
      -0.02914636768400669,
      0.052177175879478455,
      -0.03720400109887123,
      -0.048759739845991135,
      -0.01126917079091072,
      -0.006207485217601061,
      0.008484224788844585,
      0.014963027089834213,
      0.058382924646139145,
      0.011954691261053085,
      0.02026735059916973,
      -0.0035888522397726774,
      -0.0038333211559802294,
      -0.10065846890211105,
      0.011563862673938274,
      -0.0375320240855217,
      -0.05226168408989906,
      -0.0034332824870944023,
      -0.003617870854213834,
      0.04550163820385933,
      -0.11733164638280869,
      0.008524554781615734,
      0.0023293893318623304,
      0.04964163154363632,
      -0.0426739826798439,
      -0.026475034654140472,
      0.03337577357888222,
      -0.014353969134390354,
      -0.0012751836329698563,
      0.010453231632709503,
      -0.03183998167514801,
      0.007729578763246536,
      0.004777832422405481,
      -0.01467291358858347,
      -0.01394092570990324,
      0.006810528226196766,
      -0.05126335844397545,
      0.0394543819129467,
      0.015659254044294357,
      -0.030796300619840622,
      0.032166775315999985,
      -0.007309722248464823,
      -0.0011691430117934942,
      -0.07522527873516083,
      -0.007256031036376953,
      -0.033393923193216324,
      -0.017357906326651573,
      -0.008052296005189419,
      -0.005145147442817688,
      0.08383883535861969,
      0.06586778908967972,
      -0.08695835620164871,
      -0.011996228247880936,
      -0.00017869475414045155,
      0.13605710864067078,
      0.0023132760543376207,
      -0.025721706449985504,
      0.06285781413316727,
      -0.062394991517066956,
      -0.031762223690748215,
      -0.052496638149023056,
      0.017251400277018547,
      0.022742046043276787,
      0.04409870132803917,
      -0.049163904041051865,
      -0.033785346895456314,
      0.03592831268906593,
      0.04874959960579872,
      0.09343022853136063,
      0.005116867367178202,
      -0.013064361177384853,
      -0.008540879003703594,
      0.06330607831478119,
      0.0031626434065401554,
      -0.05183578282594681,
      0.016708286479115486,
      0.10134846717119217,
      -0.008579191751778126,
      -0.010436773300170898,
      0.031198007985949516,
      -0.13289348781108856,
      -0.010609056800603867,
      0.08880769461393356,
      0.010503431782126427,
      -0.012610898353159428,
      0.04420547932386398,
      0.057826075702905655,
      0.0773153156042099,
      -0.04769410938024521,
      0.10835785418748856,
      0.003208185313269496,
      -0.04369223862886429,
      -0.005570936016738415,
      -0.054674193263053894,
      0.00704587996006012,
      0.0014320292975753546,
      0.011866169050335884,
      -0.02629147283732891,
      -0.01646823063492775,
      -0.11244740337133408,
      -0.10655634850263596,
      0.031415704637765884,
      -0.030935756862163544,
      0.0795157179236412,
      0.0022967190016061068,
      -0.017663676291704178,
      -0.005144504830241203,
      -0.015441174618899822,
      -0.055491555482149124,
      0.010001755319535732,
      -0.0137580381706357,
      -0.05056469514966011,
      0.04916875809431076,
      0.0025148235727101564,
      -0.03152134642004967,
      0.010413366369903088,
      0.04953376203775406,
      -0.007435051258653402,
      -0.0339384488761425,
      -0.010448756627738476,
      0.10856515914201736,
      0.009761867113411427,
      -0.04624010622501373,
      0.004732015077024698,
      0.051229506731033325,
      0.022651515901088715,
      0.012241343967616558,
      0.04904957860708237,
      0.048471562564373016,
      0.04758165404200554,
      0.02982775680720806,
      0.0026975059881806374,
      -0.00474573252722621,
      -0.06621634215116501,
      0.008223706856369972,
      0.047620125114917755,
      -0.02633495442569256,
      -0.051082149147987366,
      -0.05182160809636116,
      -0.014901740476489067,
      -0.0415697880089283,
      -0.03399268537759781,
      -0.005921854171901941,
      -0.040504321455955505,
      -0.004474326502531767,
      0.025426296517252922,
      0.003835976356640458,
      0.019464561715722084,
      -0.06938859820365906,
      0.031122712418437004,
      0.04606189578771591,
      0.0030527093913406134,
      -0.05350174754858017,
      -0.04969378933310509,
      0.039811164140701294,
      0.0018605247605592012,
      0.036881957203149796,
      -0.046755559742450714,
      -0.042340200394392014,
      0.023169951513409615,
      0.010045371949672699,
      0.08509110659360886,
      0.03348536044359207,
      -0.036926038563251495,
      0.06258238106966019,
      0.02331017330288887,
      -0.022166091948747635,
      0.08436580002307892,
      -0.04626365005970001,
      -0.03514741361141205,
      -0.01522818487137556,
      -0.027497215196490288,
      0.09023883938789368,
      0.028966447338461876,
      0.03681480512022972,
      0.021445252001285553,
      0.00488804979249835,
      0.012670310214161873,
      0.02046830765902996,
      0.0089069539681077,
      -0.05096450448036194,
      -0.07759108394384384,
      -0.0315779373049736,
      -0.03553248196840286,
      0.0558127835392952,
      0.04899609461426735,
      -0.022952638566493988,
      -0.051105428487062454,
      0.03953233361244202,
      0.08130749315023422,
      -0.010399450547993183,
      0.018686672672629356,
      -0.03336288779973984,
      0.029887935146689415,
      0.042933572083711624,
      -0.008090288378298283,
      0.044834114611148834,
      -0.019901251420378685,
      -0.012829119339585304,
      -0.015154784545302391,
      -0.04896606504917145,
      0.01677708327770233,
      -0.004121113568544388,
      -0.006652265787124634,
      -0.007034594658762217,
      -0.009678767994046211,
      0.08916308730840683,
      0.04886818677186966,
      0.08715993911027908,
      0.016388824209570885,
      -0.0011110571213066578,
      -0.06447793543338776,
      0.009350629523396492,
      0.052055299282073975,
      -0.006765285041183233,
      -0.0991007387638092,
      -0.020003169775009155,
      -0.07806746661663055,
      -0.009046239778399467,
      0.035424429923295975,
      -0.06398607045412064,
      -0.022584693506360054,
      0.016632506623864174,
      0.004370987415313721,
      -0.1121729165315628,
      0.03142650052905083,
      -0.003070961218327284,
      0.05240289494395256,
      -0.027015555649995804,
      -0.0796470046043396,
      -0.04761539027094841,
      -0.02278607338666916,
      0.003597049508243799,
      0.01564309000968933,
      0.027948670089244843,
      -0.013956882990896702,
      0.008457697927951813,
      0.02934822253882885,
      0.04816914349794388,
      -0.06574408710002899,
      -0.0226273313164711,
      0.022456375882029533,
      -0.04072188213467598,
      -0.055097464472055435,
      0.009497466497123241,
      0.012166537344455719,
      0.011572089046239853,
      -0.06319452077150345,
      0.023139188066124916,
      -0.056422099471092224,
      -0.010085797868669033,
      0.010898593813180923,
      -0.01239188201725483,
      -0.006747390143573284,
      0.01647542044520378,
      -0.007349370513111353,
      0.0710003674030304,
      -0.14078396558761597,
      0.05250861123204231,
      -0.037247590720653534,
      0.007387085352092981,
      0.033144865185022354,
      -0.01850266382098198,
      -0.07163148373365402,
      -0.06736712157726288,
      -0.0004795796412508935,
      0.0017010602168738842,
      0.00016130736912600696,
      0.010517232120037079,
      -0.056153252720832825,
      0.02846134454011917
    ],
    [
      0.003675981890410185,
      -0.010053189471364021,
      0.04421142861247063,
      0.005788739770650864,
      -0.02637922205030918,
      0.051857925951480865,
      -0.039920225739479065,
      -0.030993808060884476,
      0.03842796012759209,
      -0.025214876979589462,
      0.0020535290241241455,
      -0.01829489879310131,
      -0.03665397688746452,
      0.05304446443915367,
      0.015551961958408356,
      -0.0063052126206457615,
      -0.040281035006046295,
      -0.03248082473874092,
      0.006981009617447853,
      -0.06497222185134888,
      -0.03856056556105614,
      0.06038068234920502,
      0.03099050000309944,
      0.007990804500877857,
      0.027643319219350815,
      0.0062903910875320435,
      -0.03950371965765953,
      -0.030937008559703827,
      -0.03261832520365715,
      -0.03868386894464493,
      -0.03052571415901184,
      -0.025881418958306313,
      0.04125446826219559,
      -0.03425438702106476,
      0.019463159143924713,
      0.023299895226955414,
      -0.009178414940834045,
      0.03530201315879822,
      0.049702875316143036,
      0.06993911415338516,
      0.02062014862895012,
      -0.03319527953863144,
      0.03343065083026886,
      0.05557982251048088,
      -0.028499171137809753,
      0.038172923028469086,
      -0.09112349897623062,
      0.00020921086252201349,
      -0.11549476534128189,
      0.052766136825084686,
      0.016247954219579697,
      -0.09608027338981628,
      0.010032599791884422,
      0.016813987866044044,
      0.11310584098100662,
      -0.06999526172876358,
      0.04217531904578209,
      -0.05195910483598709,
      -0.008099769242107868,
      -0.06879189610481262,
      0.012182767502963543,
      -0.004445128608494997,
      0.00720397150143981,
      0.026694055646657944,
      0.06332255899906158,
      -0.019656045362353325,
      -0.033802226185798645,
      -0.0351855531334877,
      0.032200396060943604,
      0.03262292966246605,
      -0.048044685274362564,
      -0.0045329853892326355,
      -0.07103963196277618,
      -0.03328432887792587,
      -0.10672120749950409,
      -0.0762251764535904,
      0.008248375728726387,
      -0.024046385660767555,
      -0.02908111736178398,
      0.009332487359642982,
      0.030584895983338356,
      0.030620792880654335,
      -0.01407188642770052,
      0.04436170309782028,
      -0.009338072501122952,
      -0.05641764774918556,
      0.02143171615898609,
      0.011400069110095501,
      0.09648924320936203,
      0.020471611991524696,
      -0.030245285481214523,
      -0.00401768134906888,
      0.049872081726789474,
      0.03880922123789787,
      -0.017169972881674767,
      0.0936354547739029,
      0.050445862114429474,
      0.040794793516397476,
      0.017714383080601692,
      0.034764476120471954,
      -0.030096039175987244,
      0.004229865036904812,
      -0.02903435379266739,
      -0.02403913252055645,
      -0.035764846950769424,
      -0.025078047066926956,
      -0.012623081915080547,
      0.08154738694429398,
      0.04105549678206444,
      -0.030953895300626755,
      -0.02967243082821369,
      0.001412036013789475,
      -0.0031460458412766457,
      -0.012389713898301125,
      -0.05701010674238205,
      -0.0030017828103154898,
      -0.002668677130714059,
      0.016355842351913452,
      -0.029752681031823158,
      -0.112397201359272,
      -0.004382179584354162,
      -0.030709076672792435,
      -0.0030808134470134974,
      -0.0362374447286129,
      0.05234286189079285,
      -0.13545088469982147,
      -0.0024721992667764425,
      0.028227292001247406,
      0.044227514415979385,
      -0.022014178335666656,
      -0.02694033272564411,
      0.024433715268969536,
      0.09885342419147491,
      -0.06021673232316971,
      -0.004465789999812841,
      -0.014030839316546917,
      0.008592786267399788,
      0.019371677190065384,
      -0.0303470678627491,
      0.040007349103689194,
      -0.056487344205379486,
      0.02655712701380253,
      0.005990572739392519,
      -0.04653644561767578,
      0.0027761117089539766,
      -0.012434451840817928,
      0.008706930093467236,
      0.025869101285934448,
      -0.05401778966188431,
      -0.031608518213033676,
      0.010894141159951687,
      0.011294160038232803,
      0.015572710894048214,
      -0.02659796178340912,
      -0.038171492516994476,
      -0.006096094846725464,
      0.07660526037216187,
      0.0029615021776407957,
      -0.057155318558216095,
      -0.0003892107924912125,
      -0.03261883556842804,
      0.04612608626484871,
      -0.010525503195822239,
      0.05182313919067383,
      0.02367258630692959,
      0.05873381346464157,
      0.05724351480603218,
      0.010825036093592644,
      0.05047627538442612,
      -0.046061184257268906,
      -0.02479214034974575,
      -0.008442685008049011,
      -0.07267045229673386,
      0.03931841626763344,
      0.029139451682567596,
      -0.023653434589505196,
      0.07151564955711365,
      -0.01569601148366928,
      -0.057223763316869736,
      0.1234537735581398,
      0.010878076776862144,
      -0.05115143954753876,
      -0.06491923332214355,
      -0.0648076981306076,
      0.08196865767240524,
      -0.0739448070526123,
      0.024508366361260414,
      -0.022159649059176445,
      0.06453913450241089,
      -0.008411634713411331,
      -0.045306067913770676,
      -0.008933869190514088,
      0.04134384170174599,
      0.048920124769210815,
      -0.04098743200302124,
      -0.04603422060608864,
      -0.021864287555217743,
      -0.04171537235379219,
      -0.019474582746624947,
      0.011146532371640205,
      -0.02448657527565956,
      -0.006842851638793945,
      -0.06775712966918945,
      -0.00615284638479352,
      0.07120358943939209,
      0.06257829070091248,
      0.028782915323972702,
      -0.01763538084924221,
      -0.005473547615110874,
      0.0012536047725006938,
      0.002558361738920212,
      -0.07776809483766556,
      -0.01266509760171175,
      0.01302901841700077,
      0.005804936867207289,
      -0.09412577748298645,
      0.03591547906398773,
      0.05682608112692833,
      0.11694784462451935,
      0.04870986193418503,
      -0.026053406298160553,
      -0.006086961831897497,
      -0.004878461826592684,
      0.0955565795302391,
      -0.051372088491916656,
      0.024126755073666573,
      0.002047089161351323,
      -0.02710201032459736,
      -0.05541514977812767,
      -0.011599335819482803,
      0.017639972269535065,
      -0.03908272087574005,
      0.07582078874111176,
      0.04215771704912186,
      0.023185970261693,
      -0.001283274032175541,
      -0.005996397230774164,
      0.04040956124663353,
      -0.013825656846165657,
      0.02216719649732113,
      0.06207529827952385,
      0.007525227963924408,
      0.00677742762491107,
      -0.05052588880062103,
      -0.05533698946237564,
      -0.021311258897185326,
      -0.0035386693198233843,
      -0.05015583336353302,
      0.03177133575081825,
      -0.03630934655666351,
      0.06742697209119797,
      -0.031228771433234215,
      -0.003129228949546814,
      0.017245464026927948,
      0.03512600436806679,
      0.02075180411338806,
      -0.032619621604681015,
      0.00938210729509592,
      -0.019321976229548454,
      0.03758862987160683,
      -0.04800298437476158,
      0.027642367407679558,
      0.12328232079744339,
      0.020578792318701744,
      0.04540702700614929,
      0.02925683557987213,
      0.04897579550743103,
      -0.05815260857343674,
      0.011585230939090252,
      0.011542974971234798,
      0.056699931621551514,
      0.024707715958356857,
      -0.005650987382978201,
      -0.019477421417832375,
      -0.018231192603707314,
      -0.040071338415145874,
      0.005366168916225433,
      -0.02081283926963806,
      0.05579729005694389,
      -0.020807741209864616,
      -0.007644828874617815,
      -0.027621516957879066,
      -0.06257446110248566,
      0.013857169076800346,
      0.013004117645323277,
      0.006449227686971426,
      -0.00791333056986332,
      0.042872097343206406,
      -0.029202602803707123,
      0.058005645871162415,
      -0.0028621042147278786,
      -0.0333189032971859,
      0.03252854570746422,
      0.09043136239051819,
      -0.06984850764274597,
      -0.07598152756690979,
      0.0419619083404541,
      -0.09863186627626419,
      -0.03232317045331001,
      0.03840677812695503,
      -0.03267064318060875,
      -0.014080149121582508,
      0.03792595863342285,
      0.03909173980355263,
      0.06962956488132477,
      0.06712944805622101,
      0.024829259142279625,
      -0.024844013154506683,
      -0.13403482735157013,
      -0.06821619719266891,
      0.004994823131710291,
      -0.013693678192794323,
      -0.035224299877882004,
      0.005115545354783535,
      -0.027018582448363304,
      0.024835746735334396,
      -0.026019837707281113,
      0.03219367191195488,
      -0.007538217585533857,
      -0.0356697216629982,
      0.014143805019557476,
      -0.07785101234912872,
      0.02295556291937828,
      -0.05586770176887512,
      0.07544634491205215,
      0.08220484852790833,
      -0.029061375185847282,
      -0.03140317276120186,
      -0.04826038330793381,
      0.015716928988695145,
      0.04981964826583862,
      0.06452310085296631,
      0.03415048494935036,
      0.00010428743553347886,
      -0.02468768134713173,
      0.017481043934822083,
      0.02131718210875988,
      -0.02704554982483387,
      0.030376380309462547,
      0.03773117437958717,
      0.034172918647527695,
      -0.04149453714489937,
      0.021218597888946533,
      -0.0412750169634819,
      -0.016587141901254654,
      0.020823895931243896,
      -0.005592036992311478,
      0.017007051035761833,
      -0.0033635792788118124,
      0.005549371242523193,
      0.026607699692249298,
      -0.05843232572078705,
      0.02382388338446617,
      -0.058442942798137665,
      -0.08332815021276474,
      -0.03529191017150879,
      -0.017147598788142204,
      0.006266435608267784,
      -0.011662188917398453,
      0.02257375232875347,
      0.00031678040977567434,
      -0.014993845485150814,
      0.05976192280650139,
      0.09224078059196472,
      0.06370678544044495,
      -0.027118025347590446,
      0.02999705635011196,
      0.026190707460045815,
      -0.008877383545041084,
      0.0008260647882707417,
      -0.057327430695295334,
      -0.010321290232241154,
      0.016115311533212662,
      -0.00036832349724136293,
      -0.012125734239816666,
      0.06830304116010666,
      0.06875003129243851,
      0.03666725009679794,
      0.0634627416729927,
      0.025470878928899765,
      -0.02412128448486328,
      0.04312053695321083,
      0.03192441910505295,
      -0.009533037431538105,
      -0.0010625532595440745,
      -0.04054741561412811,
      -0.009161916561424732,
      0.04258328303694725,
      -0.03219391405582428,
      0.08271634578704834,
      0.02241802029311657,
      -0.015449323691427708,
      0.010010072030127048,
      -0.05009789764881134,
      0.07607350498437881,
      0.07215838879346848,
      -0.04213090240955353,
      -0.023210182785987854,
      -0.07098595052957535,
      -0.028439562767744064,
      0.03628377988934517,
      -0.023321935907006264,
      -0.0034907434601336718,
      0.07447157055139542,
      -0.036992236971855164,
      0.01537406351417303,
      0.007616454269737005,
      0.08503708988428116,
      0.10710961371660233,
      0.015066774562001228,
      -0.008679813705384731,
      0.029748620465397835,
      -0.0681166797876358,
      -0.04797295108437538,
      -0.06123977527022362,
      0.011900713667273521,
      0.028886999934911728,
      -0.0015172826824709773,
      -0.021969731897115707,
      -0.019488872960209846,
      -0.07822269201278687,
      0.014625548385083675,
      -0.08628328889608383,
      -0.014053268358111382,
      -0.023273665457963943,
      0.12761712074279785,
      -0.11794980615377426,
      0.046104494482278824,
      0.013774233870208263,
      -0.007041702046990395,
      -0.0033817277289927006,
      -0.027949955314397812,
      0.07115589827299118,
      -0.009999104775488377,
      -0.04027243331074715,
      0.04282468929886818,
      -0.0031260226387530565,
      0.06526163965463638,
      0.059501778334379196,
      -0.01789325848221779,
      0.043331533670425415,
      -0.016238709911704063,
      0.061122484505176544,
      -0.015877069905400276,
      -0.03635096549987793,
      0.03462229296565056,
      -0.11203555017709732,
      0.08357745409011841,
      -0.06831283122301102,
      -0.01571384072303772,
      -0.03106672503054142,
      0.05959625542163849,
      0.06798698008060455,
      -0.01309923641383648,
      0.05868683010339737,
      0.008691387251019478,
      -0.0034651209134608507,
      -0.016308272257447243,
      -0.06522516906261444,
      0.03833664953708649,
      -0.08489935845136642,
      -0.051790766417980194,
      -0.04845961928367615,
      -0.010298149660229683,
      0.0026615760289132595,
      0.03756339102983475,
      0.00908609852194786,
      -0.0024754011537879705,
      0.003875501686707139,
      -0.041322968900203705,
      -0.0473269484937191,
      0.013470101170241833,
      0.03220389783382416,
      0.017595451325178146,
      -0.019480569288134575,
      0.01890777051448822,
      -0.015245642513036728,
      0.01439682487398386,
      -0.023420685902237892,
      -0.00298216356895864,
      -0.038327474147081375,
      0.02961931936442852,
      -0.07726026326417923,
      -0.05849751830101013,
      -0.08239123225212097,
      0.06090077757835388,
      0.09454075247049332,
      0.03612842783331871,
      -0.04476656764745712,
      -0.015335979871451855,
      0.003853647271171212,
      -0.036603353917598724,
      -0.00659937085583806,
      -0.03617621213197708,
      0.03393852338194847,
      0.020847296342253685,
      -0.021099142730236053,
      0.015272007323801517,
      0.08728568255901337,
      -0.029067523777484894,
      0.07142863422632217,
      -0.10159382969141006,
      -0.10362666845321655
    ],
    [
      -0.03993304446339607,
      -0.03174695745110512,
      -0.02730795368552208,
      0.05562007799744606,
      -0.019576948136091232,
      -0.0084464643150568,
      -0.01122553925961256,
      0.046412114053964615,
      0.03721977025270462,
      -0.03145710378885269,
      0.054275818169116974,
      0.01144543755799532,
      0.056824132800102234,
      -0.010877128690481186,
      -0.018905306234955788,
      -0.0025600765366107225,
      0.05473753437399864,
      -0.06816759705543518,
      0.021928509697318077,
      0.11305288225412369,
      0.029282638803124428,
      0.01937645860016346,
      -0.007228126283735037,
      -0.04968586936593056,
      -0.002690632827579975,
      0.02377788908779621,
      0.03195418789982796,
      -0.056555427610874176,
      0.004995420109480619,
      0.045431867241859436,
      0.01749446429312229,
      0.03800908848643303,
      0.04895267263054848,
      0.0581626333296299,
      0.001498998492024839,
      -0.06700856238603592,
      0.042969487607479095,
      -0.020489811897277832,
      -0.06174110621213913,
      -0.04684382304549217,
      0.06706981360912323,
      0.014566555619239807,
      0.12357532978057861,
      0.04440762847661972,
      0.0023663993924856186,
      -0.03537583723664284,
      0.023476554080843925,
      0.0018110097153112292,
      -0.11009665578603745,
      -0.02208259329199791,
      -0.015947017818689346,
      -0.03979426994919777,
      0.05386962741613388,
      -0.037908248603343964,
      -0.07139009237289429,
      -0.032133810222148895,
      0.013051260262727737,
      -0.02497986890375614,
      0.02011701837182045,
      -0.05451569706201553,
      0.0038636070676147938,
      0.005964430049061775,
      0.056073445826768875,
      0.05958129093050957,
      0.06762390583753586,
      -0.016332710161805153,
      0.027931999415159225,
      0.10006487369537354,
      0.030412975698709488,
      -0.04644794017076492,
      -0.051058150827884674,
      -0.0011144464369863272,
      -0.06407430022954941,
      -0.011027317494153976,
      0.00518854008987546,
      0.007075195200741291,
      0.001859744661487639,
      0.03125937283039093,
      0.05165158212184906,
      0.006401657592505217,
      -0.0013754661194980145,
      0.03472895175218582,
      0.009529970586299896,
      0.016901610419154167,
      0.014304845593869686,
      0.04474196210503578,
      0.026063669472932816,
      0.09420376271009445,
      -0.05469517782330513,
      -0.08399724960327148,
      0.006713796406984329,
      -0.020891766995191574,
      0.04247996211051941,
      -0.0748153030872345,
      -0.044808413833379745,
      -0.017625682055950165,
      0.07510317116975784,
      -0.009634850546717644,
      0.002847173484042287,
      0.03385987877845764,
      -0.0630885660648346,
      -0.032330334186553955,
      0.07655654847621918,
      -0.011455027386546135,
      -0.06861579418182373,
      0.11081405729055405,
      0.0005942823481746018,
      0.01940123736858368,
      -0.07762648910284042,
      -0.025711664929986,
      0.03845768794417381,
      0.026405099779367447,
      0.0948372483253479,
      -0.04103999212384224,
      -0.06789518147706985,
      0.018206708133220673,
      0.0372992679476738,
      -0.024390749633312225,
      0.05829482153058052,
      -0.007235139608383179,
      -0.0929068773984909,
      -0.0452137365937233,
      -0.027716537937521935,
      0.02457575872540474,
      -0.05688676983118057,
      0.057453569024801254,
      0.014606332406401634,
      -0.04397527500987053,
      0.05938682332634926,
      0.010794458910822868,
      0.028677068650722504,
      -0.07379481941461563,
      0.033981937915086746,
      -0.047226157039403915,
      0.049651551991701126,
      0.046822693198919296,
      0.029934372752904892,
      -0.0432681143283844,
      0.047735922038555145,
      0.02997524105012417,
      0.026401109993457794,
      -0.060738448053598404,
      0.11198762059211731,
      0.03899252787232399,
      -0.032418157905340195,
      -0.07221487164497375,
      -0.008112957701086998,
      -0.016235226765275,
      0.06538531929254532,
      -0.02194947749376297,
      0.03372485563158989,
      -0.04258182272315025,
      -0.09126659482717514,
      0.007033539470285177,
      -0.014848423190414906,
      0.0529596284031868,
      0.007383793126791716,
      -0.01187620684504509,
      -0.015355337411165237,
      -0.0013610431924462318,
      0.014619131572544575,
      0.01515128929167986,
      0.013115151785314083,
      0.06644938886165619,
      -0.01890755631029606,
      -0.010032827034592628,
      0.04441750422120094,
      0.0507403239607811,
      -0.039105966687202454,
      0.033536914736032486,
      0.09575250744819641,
      0.07639174163341522,
      0.003716292092576623,
      0.0028616581112146378,
      0.03544686362147331,
      -0.042264506220817566,
      0.07998822629451752,
      -0.03495210409164429,
      -0.04982757568359375,
      -0.06053972616791725,
      0.06771370023488998,
      -0.018666692078113556,
      0.05563750118017197,
      0.06099427863955498,
      -0.014055187813937664,
      -0.009934411384165287,
      -0.006455646362155676,
      0.04691772162914276,
      0.02712572179734707,
      -0.007886233739554882,
      0.03030790388584137,
      -0.07891800254583359,
      -0.046633098274469376,
      -0.03627292439341545,
      0.012207599356770515,
      0.05138015002012253,
      -0.0439382828772068,
      -0.04901805892586708,
      -0.015391947701573372,
      -0.01324419304728508,
      0.001285569160245359,
      0.004051074385643005,
      0.06059599295258522,
      0.010109140537679195,
      0.003117296611890197,
      0.07153787463903427,
      0.05367503687739372,
      0.012958893552422523,
      -0.04279889911413193,
      0.01107817143201828,
      -0.07375890016555786,
      0.03442711383104324,
      0.04574926197528839,
      -0.00707240728661418,
      0.007706185337156057,
      -0.0034332536160945892,
      0.07369090616703033,
      -0.012722001411020756,
      0.01595957577228546,
      0.002136619295924902,
      0.017681339755654335,
      -0.0076023186556994915,
      -0.07477553933858871,
      0.004360577557235956,
      -0.04301731660962105,
      0.04084218293428421,
      -0.014785186387598515,
      -0.11623604595661163,
      -0.02855127863585949,
      0.036732085049152374,
      0.02458125911653042,
      0.013936617411673069,
      0.10583964735269547,
      -0.04540489614009857,
      0.029857750982046127,
      -0.08848989754915237,
      0.02219059318304062,
      0.0370064452290535,
      -0.034771259874105453,
      0.004078180994838476,
      -0.007643062621355057,
      0.04342653602361679,
      0.02093447372317314,
      0.04486024007201195,
      -0.032416969537734985,
      0.018143273890018463,
      -0.011468040756881237,
      0.011560485698282719,
      -0.039624251425266266,
      0.04526964947581291,
      0.028668904677033424,
      -0.04738417640328407,
      -0.04139595478773117,
      -0.02316538617014885,
      0.03128211572766304,
      -0.07636377960443497,
      0.010594647377729416,
      0.03799530491232872,
      0.037752289324998856,
      0.055927589535713196,
      0.024677934125065804,
      -0.04805260896682739,
      0.022336898371577263,
      -0.030758239328861237,
      -0.0787099152803421,
      0.009412845596671104,
      0.07167480140924454,
      -0.011131451465189457,
      -0.013950850814580917,
      0.061007872223854065,
      -0.07955709844827652,
      0.0466613844037056,
      -0.10353536903858185,
      -0.02028755471110344,
      0.09892567247152328,
      0.05567524954676628,
      0.023577578365802765,
      0.03048136457800865,
      0.06115586683154106,
      -0.01551081147044897,
      0.02060333639383316,
      0.013281500898301601,
      -0.02142060548067093,
      0.046085428446531296,
      0.06478390097618103,
      -0.03813880309462547,
      -0.042070575058460236,
      0.007023461628705263,
      -0.003659825772047043,
      -0.02364255115389824,
      -0.04957849532365799,
      0.00304264179430902,
      0.03577768802642822,
      -0.015271608717739582,
      -0.05874156579375267,
      0.02749302238225937,
      -0.022346124053001404,
      -0.05405906215310097,
      -0.023195939138531685,
      -0.1066773310303688,
      0.023174230009317398,
      -0.04332131892442703,
      0.03424859791994095,
      0.03531935065984726,
      0.007568462751805782,
      0.014838949777185917,
      0.05287041887640953,
      0.014994869939982891,
      -0.04792867600917816,
      -0.013960137963294983,
      -0.06759896129369736,
      -0.04786600172519684,
      -0.01490757055580616,
      -0.0430767796933651,
      -0.06600786000490189,
      -0.0590440109372139,
      -0.044355474412441254,
      0.007907290942966938,
      0.11282328516244888,
      0.02517380379140377,
      0.005061989650130272,
      -0.07522858679294586,
      -0.05828908830881119,
      -0.04160327836871147,
      0.014807545579969883,
      -0.01983502507209778,
      -0.06424590945243835,
      0.02203943207859993,
      -0.031017523258924484,
      -0.07268694788217545,
      0.10137057304382324,
      0.018431687727570534,
      0.059019360691308975,
      -0.07642011344432831,
      -0.03193739429116249,
      0.02035493589937687,
      -0.014537475071847439,
      -0.06480532139539719,
      -0.05994097888469696,
      0.08219549059867859,
      -0.04491785913705826,
      -0.005171336233615875,
      0.026836786419153214,
      -0.060967642813920975,
      -0.0959957167506218,
      -0.005053796339780092,
      0.002541464986279607,
      0.02484143152832985,
      0.01698100008070469,
      0.01455962099134922,
      0.0764167383313179,
      -0.03817179799079895,
      0.028616556897759438,
      -0.015229328535497189,
      0.02400483936071396,
      0.010913461446762085,
      -0.027235060930252075,
      -0.08801019936800003,
      0.06840915977954865,
      0.016296127811074257,
      -0.00736185722053051,
      0.050839491188526154,
      -0.007699812296777964,
      -0.05651808902621269,
      -0.08996110409498215,
      0.019691256806254387,
      0.00700419070199132,
      0.03453266620635986,
      -0.06833983212709427,
      0.11651657521724701,
      0.00559760257601738,
      -0.005967920646071434,
      0.020196661353111267,
      -0.05475854501128197,
      0.024970395490527153,
      -0.007938597351312637,
      -0.02636995166540146,
      -0.049069393426179886,
      0.04424411058425903,
      0.037265896797180176,
      -0.06037651747465134,
      -0.01832106150686741,
      -0.017482193186879158,
      0.07048139721155167,
      0.06874343752861023,
      -0.006455820519477129,
      -0.0030476462561637163,
      -0.05303111672401428,
      0.031206713989377022,
      -0.02778683416545391,
      -0.04149087145924568,
      0.020457854494452477,
      0.055295493453741074,
      -0.1076536625623703,
      -0.015089403837919235,
      0.039458539336919785,
      0.017776163294911385,
      0.033836737275123596,
      -0.023841816931962967,
      0.043256726115942,
      -0.07582851499319077,
      0.026193806901574135,
      0.013807203620672226,
      -0.016435304656624794,
      -0.052273694425821304,
      -0.034081242978572845,
      0.05672717094421387,
      0.004450690001249313,
      -0.029904834926128387,
      -0.037813834846019745,
      -0.021664893254637718,
      0.030452817678451538,
      -0.02818974107503891,
      -0.01532731018960476,
      -0.02906053513288498,
      -0.019650137051939964,
      -0.003212676150724292,
      0.06512311100959778,
      -0.012147420085966587,
      0.062057819217443466,
      0.0510849766433239,
      0.014613883569836617,
      -0.03737572208046913,
      -0.032063666731119156,
      -0.011742481961846352,
      0.06842412054538727,
      -0.0568770170211792,
      0.005790356546640396,
      0.04677221551537514,
      -0.06241853907704353,
      0.031414441764354706,
      0.013928961008787155,
      -0.018035808578133583,
      -0.015445671044290066,
      0.054834216833114624,
      -0.06626613438129425,
      0.048815976828336716,
      -0.03352109342813492,
      -0.04300335794687271,
      -0.03047732263803482,
      0.04862917959690094,
      -0.04374304413795471,
      -0.07698696851730347,
      -0.04749387875199318,
      0.07536015659570694,
      0.012807187624275684,
      0.036220379173755646,
      0.014324324205517769,
      -0.016310378909111023,
      -0.0171315036714077,
      -0.02580687776207924,
      0.047854892909526825,
      -0.021529458463191986,
      -0.06529422104358673,
      0.057650841772556305,
      0.020937424153089523,
      0.03331070765852928,
      0.04451490566134453,
      0.04751209169626236,
      -0.0342065654695034,
      0.007077934220433235,
      -0.002677809214219451,
      -0.03211584687232971,
      -0.03870688006281853,
      -0.10839831084012985,
      -0.02506544254720211,
      0.0029555943328887224,
      0.004834423307329416,
      -0.0019499558256939054,
      -0.08515547960996628,
      0.030617734417319298,
      -0.05213818699121475,
      0.023426828905940056,
      -0.02736889198422432,
      -0.008147069253027439,
      -0.017332006245851517,
      -0.030805837363004684,
      0.018462762236595154,
      -0.015257628634572029,
      -0.045692652463912964,
      -0.0035324187483638525,
      0.05505006015300751,
      0.09777910262346268,
      -0.052720244973897934,
      0.042916715145111084,
      0.024132056161761284,
      -0.0077634407207369804,
      0.001571453409269452,
      -0.03206610679626465,
      -0.025647621601819992,
      0.039797183126211166,
      0.015970084816217422,
      0.03923119232058525,
      0.003448261646553874,
      -0.010697522200644016,
      -0.007517677266150713,
      -0.05888919532299042,
      0.06149287521839142,
      0.04452840983867645,
      0.06177428364753723,
      0.0433877594769001,
      0.011655103415250778,
      0.02619284577667713
    ],
    [
      0.050303343683481216,
      0.03196502476930618,
      -0.005176262464374304,
      0.0655728131532669,
      0.03297680988907814,
      -0.029704201966524124,
      -0.031982820481061935,
      -0.09741733968257904,
      0.03866076469421387,
      -0.034939441829919815,
      -0.023524267598986626,
      -0.008232895284891129,
      0.06792072206735611,
      0.026002012193202972,
      -0.001123299589380622,
      0.016794009134173393,
      0.03715367987751961,
      0.02538907155394554,
      0.00020479790691751987,
      -0.020296480506658554,
      -0.02911348268389702,
      -0.006232240702956915,
      -0.02036864496767521,
      -0.007293557748198509,
      -0.02313685230910778,
      -0.02034083753824234,
      -0.03683777153491974,
      -0.10379756987094879,
      0.10173742473125458,
      0.02529999054968357,
      0.005666295066475868,
      0.00681455060839653,
      0.00667173694819212,
      0.04732368886470795,
      -0.035831090062856674,
      -0.07744288444519043,
      -0.032689571380615234,
      -0.07248527556657791,
      0.06490638107061386,
      -0.057871490716934204,
      -0.009773359633982182,
      -0.07672394067049026,
      0.001594038214534521,
      -0.033655788749456406,
      -0.09912123531103134,
      -0.019369838759303093,
      -0.06463862955570221,
      0.00567221362143755,
      0.013584686443209648,
      -0.09542293846607208,
      -0.08502555638551712,
      -0.02103690430521965,
      -0.005892290733754635,
      -0.054673027247190475,
      0.03323312848806381,
      -0.007035511080175638,
      -0.0654723197221756,
      -0.07221954315900803,
      0.0455433689057827,
      -0.036880191415548325,
      0.045031193643808365,
      0.04568564519286156,
      0.032777443528175354,
      0.0044864472001791,
      0.030173107981681824,
      -0.041236940771341324,
      0.03306970000267029,
      -0.05662643164396286,
      0.0065513853915035725,
      -0.04580630362033844,
      0.05002593249082565,
      -0.021649254485964775,
      0.005846508778631687,
      0.04495777562260628,
      -0.020219705998897552,
      0.009361235424876213,
      -0.027143757790327072,
      0.00898088701069355,
      0.026061486452817917,
      0.028712179511785507,
      0.04692607372999191,
      0.04372873157262802,
      0.015222757123410702,
      -0.02043660171329975,
      -0.01677609793841839,
      -0.04635470360517502,
      0.00026557172532193363,
      0.017994118854403496,
      0.1090414822101593,
      0.04197975993156433,
      0.0415981188416481,
      0.0780458003282547,
      0.011431857012212276,
      0.03590749576687813,
      -0.08477650582790375,
      0.057657741010189056,
      -0.04055577889084816,
      0.017518067732453346,
      0.04812635853886604,
      -0.0402381457388401,
      -0.04607032611966133,
      -0.03220587223768234,
      0.0013883488718420267,
      -0.007975919172167778,
      0.03890363126993179,
      -0.012212136760354042,
      -0.052429262548685074,
      0.05080964043736458,
      -0.00922069139778614,
      -0.10803546011447906,
      0.019287249073386192,
      0.024018075317144394,
      0.06209469214081764,
      -0.039295095950365067,
      -0.0007888166001066566,
      0.00259206211194396,
      -0.02582297846674919,
      0.00028652686160057783,
      -0.030617307871580124,
      -0.13196618854999542,
      0.15841925144195557,
      0.051855627447366714,
      0.11259835213422775,
      -0.031495288014411926,
      0.03341063857078552,
      0.02032141014933586,
      -0.009518553502857685,
      0.026199346408247948,
      0.028309011831879616,
      0.019286934286355972,
      -0.007389372680336237,
      0.026187850162386894,
      -0.040697842836380005,
      -0.00010602871043374762,
      0.04879559203982353,
      0.06396988779306412,
      -0.022749783471226692,
      -0.018653789535164833,
      0.023920169100165367,
      -0.098093181848526,
      -0.0501667782664299,
      0.02931615523993969,
      0.13143792748451233,
      -0.03536522015929222,
      0.05815032124519348,
      0.08801675587892532,
      0.01870736852288246,
      0.01714356057345867,
      -0.01286791730672121,
      -0.023382846266031265,
      -0.0029823652002960443,
      0.046097248792648315,
      0.006849300116300583,
      0.01569526083767414,
      0.028157154098153114,
      -0.007423909846693277,
      0.002357965102419257,
      -0.027396366000175476,
      -0.03390774875879288,
      0.05770866945385933,
      0.02383251301944256,
      -0.028636915609240532,
      0.03361979499459267,
      0.03162901848554611,
      -0.013187927193939686,
      0.014316724613308907,
      -0.010900487191975117,
      -0.006241669412702322,
      -0.0271333958953619,
      -0.03168642893433571,
      0.03329436853528023,
      -0.0002084309235215187,
      0.0326111726462841,
      -0.02515372447669506,
      -0.04010465741157532,
      0.019055470824241638,
      0.057312123477458954,
      0.06025531142950058,
      0.08838966488838196,
      0.008598721586167812,
      0.03250187262892723,
      0.06405986845493317,
      -0.002833078382536769,
      -0.06570770591497421,
      0.022397445514798164,
      0.056357305496931076,
      0.07472532242536545,
      -0.05553470551967621,
      -0.011767929419875145,
      0.008586172945797443,
      -0.05330192670226097,
      -0.00893816351890564,
      0.032219044864177704,
      -0.038562484085559845,
      0.04967848211526871,
      0.0756387785077095,
      0.006882745306938887,
      0.16315393149852753,
      0.033656418323516846,
      -0.003699644235894084,
      -0.05315091088414192,
      -0.07139910012483597,
      0.011879627592861652,
      0.056669026613235474,
      0.05066915601491928,
      0.02935708686709404,
      0.008355438709259033,
      0.05126374214887619,
      0.07975321263074875,
      0.020557666197419167,
      -0.03217030316591263,
      0.01522408053278923,
      0.07444573938846588,
      -0.024877207353711128,
      0.029479974880814552,
      0.022353924810886383,
      -0.037526294589042664,
      0.022402562201023102,
      8.609635551692918e-05,
      0.02910003438591957,
      0.04330301657319069,
      -0.08912855386734009,
      -0.05398201569914818,
      0.020266573876142502,
      -0.0674842894077301,
      0.0008614265825599432,
      0.02170819230377674,
      0.038190506398677826,
      0.02802201360464096,
      -0.04561332240700722,
      -0.047171492129564285,
      -0.0669701099395752,
      -0.01696678437292576,
      0.026060711592435837,
      0.014014206826686859,
      0.09192108362913132,
      0.0360318161547184,
      -0.004303146153688431,
      -0.016814202070236206,
      -0.03753877058625221,
      -0.04177437722682953,
      -0.0023228537756949663,
      0.024749845266342163,
      0.003250191919505596,
      0.005252230912446976,
      0.11649242788553238,
      0.07479540258646011,
      -0.009281170554459095,
      -0.03021603636443615,
      -0.0570601150393486,
      -0.022963011637330055,
      0.0019567327108234167,
      -0.1073145940899849,
      -0.019841304048895836,
      0.03892935812473297,
      0.004513745661824942,
      0.02207821048796177,
      0.01577266864478588,
      0.025778377428650856,
      -0.10214849561452866,
      -0.08460976183414459,
      0.060707781463861465,
      -0.022125594317913055,
      -0.057293184101581573,
      -0.03410991653800011,
      0.06472466140985489,
      -0.055688224732875824,
      0.01628164015710354,
      0.06400790065526962,
      0.06497246026992798,
      0.03356033191084862,
      0.020255478098988533,
      0.018283972516655922,
      -0.0751013308763504,
      0.001254294067621231,
      -0.0029864017851650715,
      -0.057930056005716324,
      0.052416346967220306,
      -0.014720686711370945,
      -0.03418959304690361,
      -0.007408898789435625,
      -0.08787789940834045,
      0.050059691071510315,
      -0.01324521005153656,
      -0.020604589954018593,
      0.012298072688281536,
      -0.0022698701359331608,
      0.015873339027166367,
      -0.0381111316382885,
      0.02197946235537529,
      0.06228221207857132,
      0.0848909243941307,
      -0.07080680131912231,
      -0.030945638194680214,
      0.008140488527715206,
      0.003648606361821294,
      -0.1090274453163147,
      -0.027040382847189903,
      -0.0017622321611270308,
      0.008192314766347408,
      -0.01918802782893181,
      0.002556068357080221,
      0.02345147542655468,
      0.011127708479762077,
      0.013361240737140179,
      -0.1367201954126358,
      -0.027042856439948082,
      -0.03737156093120575,
      -0.03954046964645386,
      0.036317311227321625,
      -0.10236053913831711,
      -0.004264295566827059,
      -0.03127502277493477,
      -0.007284313440322876,
      -0.05789912864565849,
      -0.02443925105035305,
      0.010343571193516254,
      0.01690477319061756,
      0.02898058108985424,
      0.025254879146814346,
      0.01725565455853939,
      0.02014259248971939,
      0.04121015965938568,
      0.022031063213944435,
      -0.008246108889579773,
      -0.059774000197649,
      0.007698485627770424,
      -0.09498246759176254,
      0.03157385066151619,
      0.027183303609490395,
      0.0033089397475123405,
      0.01687508262693882,
      -0.010015038773417473,
      -0.04317183420062065,
      0.05028977617621422,
      0.02680264599621296,
      -0.04177282750606537,
      0.07397514581680298,
      0.004123528953641653,
      0.002512548817321658,
      0.00418475829064846,
      -0.031901322305202484,
      0.008037245832383633,
      0.047511026263237,
      -0.04342109337449074,
      0.0757097601890564,
      -0.006728797219693661,
      0.008675219491124153,
      -0.06891803443431854,
      -0.058490484952926636,
      0.030195387080311775,
      -0.010957043617963791,
      0.0179534200578928,
      -0.06503855437040329,
      0.028899725526571274,
      0.03953041136264801,
      0.04234190657734871,
      -0.01830216497182846,
      0.0869082659482956,
      0.02814832702279091,
      0.02533957175910473,
      0.0674358606338501,
      0.03665536269545555,
      -0.038429245352745056,
      0.04097325727343559,
      -0.04125756397843361,
      -0.07016075402498245,
      0.0784555971622467,
      0.013093823567032814,
      -0.05224780738353729,
      -0.002703026868402958,
      0.02176797203719616,
      -0.0833732858300209,
      -0.06985586136579514,
      0.08006200939416885,
      0.06659385561943054,
      -0.025940963998436928,
      -0.016077030450105667,
      -0.015776367858052254,
      -0.0058414689265191555,
      -0.04094613343477249,
      -0.07546404749155045,
      0.0927296057343483,
      0.02530778758227825,
      -0.004641626495867968,
      -0.036955397576093674,
      -0.010955926030874252,
      -0.024321800097823143,
      -0.016495611518621445,
      -0.01190604642033577,
      -0.009010305628180504,
      0.021934453397989273,
      0.06980898976325989,
      0.005153683479875326,
      -0.010781831108033657,
      0.000791165221016854,
      -0.0369703434407711,
      -0.001092978403903544,
      0.053078267723321915,
      -0.021274594590067863,
      0.06257464736700058,
      -0.004339417442679405,
      0.016385681927204132,
      -0.11991313844919205,
      0.0622866116464138,
      0.011969429440796375,
      0.07009059935808182,
      0.10752995312213898,
      0.00027641141787171364,
      -0.10343115776777267,
      0.11821524798870087,
      0.044419366866350174,
      0.0788382813334465,
      0.06572189927101135,
      0.0670403391122818,
      -0.11367522180080414,
      0.021244578063488007,
      0.005839821882545948,
      -0.07696239650249481,
      0.06450258195400238,
      0.03754125162959099,
      0.023804962635040283,
      0.012596353888511658,
      -0.040568090975284576,
      0.12086869776248932,
      0.028749532997608185,
      0.0065033878199756145,
      -0.050206396728754044,
      0.005633206106722355,
      -0.026028495281934738,
      0.17710527777671814,
      0.040579043328762054,
      0.028157349675893784,
      0.009794696234166622,
      -0.03689563646912575,
      0.035617996007204056,
      -0.022973718121647835,
      -0.028519446030259132,
      0.06214962154626846,
      0.019379813224077225,
      -0.011326994746923447,
      0.009570518508553505,
      -0.04111868888139725,
      0.004727596882730722,
      0.04256324842572212,
      0.03039744310081005,
      -0.03079044073820114,
      0.016773778945207596,
      -0.028308523818850517,
      -0.013350388035178185,
      -0.05967549979686737,
      -0.002527618780732155,
      -0.11449901759624481,
      -0.03151343762874603,
      0.00145699642598629,
      0.008081325329840183,
      -0.09253495931625366,
      0.03191510960459709,
      0.04672964662313461,
      0.0674414336681366,
      0.019021140411496162,
      -0.019264230504631996,
      0.045330677181482315,
      0.004223186522722244,
      -0.009479347616434097,
      -0.008884107694029808,
      0.07491214573383331,
      0.10952363163232803,
      -0.0010368132498115301,
      0.024232294410467148,
      0.06756193935871124,
      0.026734495535492897,
      -0.023749586194753647,
      -0.02445506490767002,
      0.007321897428482771,
      0.01329033076763153,
      0.013097884133458138,
      -0.05449683964252472,
      0.0181603841483593,
      0.03150109201669693,
      -0.05671006068587303,
      0.005335132125765085,
      0.07257546484470367,
      0.054123785346746445,
      -0.07997707277536392,
      -0.03389903903007507,
      0.014266403391957283,
      0.017432069405913353,
      0.0059139844961464405,
      -0.10042599588632584,
      -0.006287297233939171,
      -0.0333963967859745,
      0.004961694125086069,
      -0.013084392063319683,
      0.1295793056488037,
      -0.023917777463793755,
      0.05739999935030937,
      0.023542337119579315,
      0.032273199409246445,
      0.015795623883605003,
      -0.04982510209083557,
      0.046487417072057724,
      0.06042647734284401
    ],
    [
      0.019836418330669403,
      0.04286721348762512,
      0.0051989178173244,
      0.04886164143681526,
      -0.10761716961860657,
      -0.07613476365804672,
      -0.018378129228949547,
      0.04927048459649086,
      -0.019110428169369698,
      0.009856745600700378,
      0.059359293431043625,
      0.001411766279488802,
      -0.01238713413476944,
      -0.04982258379459381,
      -0.031384676694869995,
      0.02590029500424862,
      0.012176756747066975,
      -0.03109540417790413,
      0.0653262659907341,
      -0.015820153057575226,
      0.054937850683927536,
      -0.028255172073841095,
      0.040156152099370956,
      -0.02344622276723385,
      0.020036976784467697,
      0.0693163201212883,
      0.009756951592862606,
      -0.02753218077123165,
      -0.06789836287498474,
      0.03804410248994827,
      -0.03362736105918884,
      -0.023184441030025482,
      0.012503526173532009,
      0.07373382896184921,
      -0.0021804103162139654,
      -0.01400343794375658,
      0.0070807114243507385,
      -0.06708768755197525,
      0.08561473339796066,
      -0.08209995925426483,
      -0.029677551239728928,
      0.0325787328183651,
      0.07250954955816269,
      -0.002526575932279229,
      -0.029681600630283356,
      0.019268939271569252,
      0.024472856894135475,
      0.02680695429444313,
      -0.011882142163813114,
      -0.014502097852528095,
      0.022149713709950447,
      -0.057397980242967606,
      0.01672065258026123,
      -0.0024059140123426914,
      0.0044110179878771305,
      -0.003941131755709648,
      -0.04971965402364731,
      -0.03009411320090294,
      0.0044991932809352875,
      -0.07501447945833206,
      0.09390132129192352,
      -0.02137867361307144,
      -0.011754648759961128,
      0.007343178149312735,
      0.0024041282013058662,
      0.07837828993797302,
      0.09892647713422775,
      -0.10593506693840027,
      -0.007601969409734011,
      -0.0014072349295020103,
      0.11217734217643738,
      -0.010820564813911915,
      0.04060870409011841,
      0.005676380358636379,
      -0.01539466343820095,
      0.009700904600322247,
      0.05497393757104874,
      -0.03589010238647461,
      0.03824806958436966,
      -0.00674524437636137,
      -0.005180820357054472,
      0.03821869567036629,
      0.08205580711364746,
      0.06851828098297119,
      0.03181911259889603,
      0.04151272028684616,
      0.09749113768339157,
      -0.03004528395831585,
      0.023580104112625122,
      -0.051251526921987534,
      0.010431203059852123,
      0.04367183893918991,
      -0.0228994432836771,
      -0.05638131871819496,
      0.03794514760375023,
      -0.03234705701470375,
      -0.023012451827526093,
      0.053231582045555115,
      0.0012188496766611934,
      -0.05766461417078972,
      0.024817127734422684,
      0.010684256441891193,
      0.05626197159290314,
      0.004038583021610975,
      -0.043661706149578094,
      -0.007939246483147144,
      0.07295724004507065,
      0.02088075317442417,
      -0.022838396951556206,
      -0.07092898339033127,
      -0.03240140154957771,
      -0.10798166692256927,
      -0.0336386114358902,
      0.0005465567810460925,
      0.053868893533945084,
      0.06714801490306854,
      0.03859154134988785,
      -0.0061124162748456,
      -0.014215935952961445,
      -0.0006205140962265432,
      0.07642201334238052,
      0.009557407349348068,
      -0.03379411995410919,
      0.05769364908337593,
      0.06573560833930969,
      -0.028305983170866966,
      0.017941271886229515,
      0.0035108199808746576,
      0.06916231662034988,
      0.06321682035923004,
      0.001232581096701324,
      0.04995081573724747,
      -0.0018305968260392547,
      -0.008248863741755486,
      -0.0051964567974209785,
      0.03455496206879616,
      0.01647273264825344,
      0.016690237447619438,
      0.01270146295428276,
      0.01496448740363121,
      0.018479498103260994,
      0.05259792506694794,
      -0.043161503970623016,
      0.06083999574184418,
      0.07924382388591766,
      0.020283980295062065,
      0.00019293399236630648,
      0.01807447150349617,
      -0.020498178899288177,
      0.05457138642668724,
      0.0545070581138134,
      0.0014942005509510636,
      0.028975579887628555,
      0.05197880044579506,
      0.004669317975640297,
      -0.020586056634783745,
      0.07033742219209671,
      -0.014027083292603493,
      0.012591895647346973,
      -0.04158702492713928,
      0.09276030212640762,
      0.014131106436252594,
      -0.03611542657017708,
      0.13868971168994904,
      -0.0028171841986477375,
      0.03950320929288864,
      0.03781435266137123,
      0.028204550966620445,
      -0.016886413097381592,
      0.011070876382291317,
      0.01625720225274563,
      -0.04045962169766426,
      -0.010985709726810455,
      0.03436339646577835,
      -0.11625387519598007,
      -0.08731170743703842,
      -0.005943196825683117,
      0.012770721688866615,
      0.08867264539003372,
      -0.08268187195062637,
      -0.034632232040166855,
      -0.016856985166668892,
      0.03834008052945137,
      0.10115045309066772,
      -0.046578675508499146,
      0.03405375778675079,
      -0.0474463514983654,
      -0.08809079229831696,
      -0.006464317440986633,
      -0.030426526442170143,
      0.04640665277838707,
      -0.05557796359062195,
      0.01651245355606079,
      -0.06516513228416443,
      -0.04210194945335388,
      0.005408253986388445,
      -0.034442637115716934,
      0.0681384727358818,
      0.008280438371002674,
      -0.003063475014641881,
      -0.07547421008348465,
      0.026903904974460602,
      -0.034524690359830856,
      -0.005343377590179443,
      0.009246890433132648,
      0.03337950259447098,
      0.01223058346658945,
      0.02196536585688591,
      -0.00782023649662733,
      0.04646298661828041,
      -0.021705495193600655,
      0.046335071325302124,
      -0.012949453666806221,
      -0.014263642951846123,
      0.0001571131288073957,
      0.01430387794971466,
      -0.04379471391439438,
      0.014252821914851665,
      0.05161905288696289,
      -0.0759982019662857,
      0.007028639782220125,
      0.06521851569414139,
      0.028156906366348267,
      0.043619636446237564,
      -0.09419865161180496,
      0.019164426252245903,
      -0.0067816139198839664,
      0.05925321951508522,
      -0.039264436811208725,
      0.0803692415356636,
      0.02973555400967598,
      0.05495893210172653,
      0.054660726338624954,
      0.06868543475866318,
      0.03457814082503319,
      -0.04500541090965271,
      0.006356760859489441,
      -0.0024387044832110405,
      -0.015087418258190155,
      -0.008540192618966103,
      -0.03751595318317413,
      -0.030196906998753548,
      -0.031377870589494705,
      0.010301978327333927,
      -0.029700126498937607,
      -0.03972594812512398,
      0.04679078236222267,
      -0.039708737283945084,
      0.0555502288043499,
      -0.03848385438323021,
      -0.031975921243429184,
      0.04223557561635971,
      0.018164288252592087,
      0.003732581390067935,
      0.021718980744481087,
      0.04994475468993187,
      -0.005284697748720646,
      -0.0066804043017327785,
      0.008605190552771091,
      -0.014212435111403465,
      -0.009353293105959892,
      -0.023158691823482513,
      0.03995959460735321,
      -0.03979308530688286,
      -0.040277864784002304,
      -0.031448543071746826,
      0.03010489232838154,
      0.0087037468329072,
      0.022364361211657524,
      -0.03370600938796997,
      -0.008349773474037647,
      0.027257224544882774,
      0.06632369011640549,
      0.01827276684343815,
      -0.038822825998067856,
      0.030186910182237625,
      -0.04515808820724487,
      -0.0778694748878479,
      -0.02134672738611698,
      0.00381526374258101,
      0.058988817036151886,
      -0.02759864740073681,
      0.06037457287311554,
      0.007463029120117426,
      -0.021075034514069557,
      0.04369617626070976,
      0.04302872717380524,
      0.025762900710105896,
      0.042179711163043976,
      -0.06980729848146439,
      0.026846783235669136,
      0.009829000569880009,
      -0.043389350175857544,
      -0.039409052580595016,
      -0.07001947611570358,
      0.03976993262767792,
      -0.02440914325416088,
      -0.00044315183185972273,
      0.009958424605429173,
      0.03523967042565346,
      0.0782569870352745,
      0.011723901145160198,
      0.048477452248334885,
      0.004769772291183472,
      0.08475742489099503,
      -0.025405054911971092,
      0.04503972828388214,
      0.031218737363815308,
      -0.06328046321868896,
      -0.00860840454697609,
      -0.04106074944138527,
      0.022283393889665604,
      0.05836174637079239,
      0.06112241744995117,
      -0.03198454529047012,
      0.0327862985432148,
      -0.029124777764081955,
      -0.04030664637684822,
      0.02212713100016117,
      -0.0037354284431785345,
      -0.008817661553621292,
      0.021544449031352997,
      -0.020805219188332558,
      0.028407912701368332,
      0.02882291004061699,
      0.03660350665450096,
      -0.13877585530281067,
      0.012728792615234852,
      0.03381773829460144,
      -0.04962451010942459,
      0.04836559668183327,
      -0.020181160420179367,
      0.06197258085012436,
      -0.05189298838376999,
      0.036121197044849396,
      -0.0012801132397726178,
      -0.00910936389118433,
      -0.05111153423786163,
      0.026087360456585884,
      0.01606723852455616,
      0.08650550991296768,
      -0.011758278124034405,
      0.028826499357819557,
      -0.02117016352713108,
      0.02726423181593418,
      0.02361166477203369,
      -0.0460115522146225,
      -0.013083652593195438,
      -0.004742310848087072,
      -0.02471647784113884,
      -0.03864501416683197,
      0.08738984912633896,
      0.030444663017988205,
      0.021050382405519485,
      0.003510556183755398,
      -0.05971665680408478,
      0.08607355505228043,
      -0.022207150235772133,
      -0.018910374492406845,
      -0.028091808781027794,
      -0.02699916623532772,
      -0.008852283470332623,
      0.016268886625766754,
      -0.046867769211530685,
      -0.05666584149003029,
      -0.08767985552549362,
      -0.005458815023303032,
      0.0387297123670578,
      0.08453898876905441,
      0.06489133834838867,
      -0.048484981060028076,
      0.05675949528813362,
      -0.04077460616827011,
      -0.08411037176847458,
      0.009875557385385036,
      0.03666212409734726,
      0.04281724616885185,
      -0.01730060949921608,
      0.010094329714775085,
      0.011432458646595478,
      0.03841337561607361,
      0.03706307336688042,
      0.03566836565732956,
      -0.011828077025711536,
      -0.055820249021053314,
      0.020821072161197662,
      0.010597883723676205,
      -0.056259267032146454,
      -0.10481621325016022,
      -0.02717280015349388,
      0.0013537588529288769,
      -0.041602641344070435,
      -0.017346365377306938,
      -0.03970148414373398,
      -0.06441016495227814,
      0.08007268607616425,
      0.009807785972952843,
      0.010604945942759514,
      0.013341054320335388,
      0.04323650524020195,
      -0.07736171036958694,
      -0.03978818655014038,
      0.055074382573366165,
      -0.036976516246795654,
      -0.011592986062169075,
      -0.00926880445331335,
      0.05276757851243019,
      0.01719355396926403,
      0.03469080850481987,
      0.033158209174871445,
      -0.029221437871456146,
      -0.04232487455010414,
      -0.02423921972513199,
      -0.004195421002805233,
      -0.08908984065055847,
      0.02479364536702633,
      0.026690129190683365,
      -0.004293985664844513,
      0.052146513015031815,
      -0.026607083156704903,
      0.04664139822125435,
      0.05252705514431,
      -0.03580761328339577,
      0.01786055602133274,
      0.003655330277979374,
      0.029455596581101418,
      -0.05230891332030296,
      0.05853832885622978,
      -0.003990943543612957,
      -0.10214748233556747,
      -0.03894637152552605,
      -0.037160925567150116,
      0.014219087548553944,
      -0.03446220979094505,
      0.05007807910442352,
      0.04997510462999344,
      -0.10448557883501053,
      -0.015234943479299545,
      -0.0035493201576173306,
      0.009771890006959438,
      0.06105536222457886,
      0.05324748903512955,
      -0.014911094680428505,
      -0.03122570365667343,
      -0.0098009267821908,
      -0.10172400623559952,
      -0.07156725972890854,
      0.09911743551492691,
      0.04445141181349754,
      0.04260570928454399,
      -0.05009022727608681,
      0.009028901346027851,
      -0.0007210593321360648,
      -0.06927759200334549,
      -0.0018532871035858989,
      0.03401494398713112,
      0.02518785372376442,
      -0.030586842447519302,
      -0.12875594198703766,
      -0.06374983489513397,
      0.030045747756958008,
      0.02065344527363777,
      0.02077166922390461,
      0.023312708362936974,
      -0.05208221450448036,
      -0.01166109275072813,
      0.08421878516674042,
      0.04242267832159996,
      0.035643428564071655,
      -0.023816531524062157,
      -0.011371898464858532,
      0.0330941341817379,
      -0.039010435342788696,
      -0.021016646176576614,
      -0.02820228412747383,
      -0.04959113150835037,
      -0.008640436455607414,
      0.04992163926362991,
      0.07182947546243668,
      -0.054252732545137405,
      0.04545992985367775,
      0.04732488468289375,
      0.042605891823768616,
      0.04539301246404648,
      0.03967006504535675,
      0.07481376826763153,
      0.0374588780105114,
      -0.01643328368663788,
      0.004768167622387409,
      0.0236985944211483,
      0.04494885727763176,
      -0.04987914487719536,
      -0.06283753365278244,
      0.05372603237628937,
      -0.011310158297419548,
      -0.02411673404276371,
      0.03954653814435005,
      -0.02135292999446392,
      0.07607737928628922,
      -0.09658212214708328,
      0.046422328799963,
      -0.08924953639507294,
      0.0381600446999073
    ],
    [
      0.028811775147914886,
      0.02477649413049221,
      -0.012321961112320423,
      0.05428130179643631,
      -0.06509046256542206,
      0.027729157358407974,
      -0.06453150510787964,
      0.009305162355303764,
      0.01756330206990242,
      -0.055117785930633545,
      0.08097540587186813,
      -0.09068993479013443,
      0.015597694553434849,
      0.021947382017970085,
      0.03685588762164116,
      -0.08767102658748627,
      -0.02556571178138256,
      0.03675039857625961,
      0.026469342410564423,
      0.0034099591430276632,
      0.06094098836183548,
      -0.026537200435996056,
      0.006417012307792902,
      0.0341256745159626,
      0.03737284615635872,
      -0.05315453186631203,
      0.0011061154073104262,
      -0.06260329484939575,
      0.051345955580472946,
      -0.007242131978273392,
      -0.0905144065618515,
      0.09896906465291977,
      -0.016989341005682945,
      0.06196771189570427,
      -0.0328650064766407,
      0.08105922490358353,
      -0.01783508062362671,
      0.023993561044335365,
      -0.0007008099346421659,
      -0.05629507079720497,
      -0.03823963925242424,
      0.06417940557003021,
      -0.014955922029912472,
      -0.011875987984240055,
      -0.05727670341730118,
      0.05440681055188179,
      -0.11179421842098236,
      0.04005160927772522,
      -0.028974125161767006,
      0.0007176169892773032,
      0.03469235077500343,
      0.11690842360258102,
      0.01898827962577343,
      0.0274762324988842,
      0.07707246392965317,
      -0.012458084151148796,
      0.05978580191731453,
      0.028348665684461594,
      -0.04642215743660927,
      0.02471395581960678,
      -0.013685864396393299,
      0.08381080627441406,
      0.025381235405802727,
      -0.051246266812086105,
      -0.03288886323571205,
      0.01382651086896658,
      0.042214661836624146,
      0.0636669173836708,
      0.04945090413093567,
      -0.016087980940937996,
      0.07841896265745163,
      -0.005189915187656879,
      -0.00029648045892827213,
      -0.057855166494846344,
      0.03192506730556488,
      0.038306478410959244,
      0.01173181738704443,
      0.045285340398550034,
      0.1135261058807373,
      0.032620031386613846,
      -0.07763905078172684,
      -0.06035884842276573,
      0.051010698080062866,
      0.007256846409291029,
      0.04672784358263016,
      0.04228389263153076,
      0.043702006340026855,
      0.04382629320025444,
      0.0638425201177597,
      0.02781238593161106,
      0.03542286902666092,
      -0.027808593586087227,
      0.040026694536209106,
      -0.08154520392417908,
      0.030598241835832596,
      0.0659417137503624,
      0.07456827908754349,
      -0.00655747065320611,
      0.05255191773176193,
      -0.06137794256210327,
      -0.04502106457948685,
      -0.017811305820941925,
      0.044944263994693756,
      -0.030058041214942932,
      -0.08152278512716293,
      -0.015576770529150963,
      0.07396014034748077,
      -0.021698227152228355,
      0.004596840590238571,
      0.013611964881420135,
      -0.05289386585354805,
      0.035862602293491364,
      0.056576017290353775,
      0.03395550698041916,
      -0.07727237045764923,
      -0.02284347452223301,
      0.017336193472146988,
      0.04149080812931061,
      -0.0050130318850278854,
      0.008724191226065159,
      -0.04919053986668587,
      0.02572837471961975,
      -0.022627780213952065,
      -0.0011479026870802045,
      -0.04093538224697113,
      0.035808708518743515,
      0.029261568561196327,
      -0.0032603309955447912,
      0.01411432959139347,
      0.06204354017972946,
      0.0503595806658268,
      0.005187745671719313,
      -0.041604191064834595,
      -0.03695611655712128,
      0.024541281163692474,
      0.031508006155490875,
      0.02915154956281185,
      0.011242900043725967,
      0.02402077242732048,
      -0.05264656990766525,
      0.0004401678161229938,
      -0.06889652460813522,
      0.020474057644605637,
      -0.007006207946687937,
      0.07181371748447418,
      0.01746850460767746,
      0.03831303119659424,
      -0.03969039395451546,
      0.06761491298675537,
      0.0987161248922348,
      -0.006436142139136791,
      -0.014025802724063396,
      0.03470498323440552,
      0.04019024968147278,
      0.053913019597530365,
      0.00042775351903401315,
      -0.0543924905359745,
      0.018089260905981064,
      -0.011829673312604427,
      -0.007268486078828573,
      0.062247809022665024,
      0.07393905520439148,
      0.015567934140563011,
      -0.006862008012831211,
      -0.08533108234405518,
      0.019506150856614113,
      -0.029717208817601204,
      -0.019067874178290367,
      -0.0678844228386879,
      -0.011730225756764412,
      0.009082356467843056,
      0.004259221721440554,
      -0.02317555621266365,
      0.00968961976468563,
      -0.01529967412352562,
      -0.04103423282504082,
      -0.043588485568761826,
      -0.050756800919771194,
      0.002499999478459358,
      0.045569777488708496,
      -0.002731592860072851,
      0.010938984341919422,
      0.029975229874253273,
      0.06603793054819107,
      -0.05331782251596451,
      0.01289663091301918,
      0.0037433335091918707,
      0.031246937811374664,
      0.003905119374394417,
      0.026113973930478096,
      0.03069767728447914,
      0.02329505793750286,
      -0.00274454802274704,
      0.048898618668317795,
      0.046368230134248734,
      0.059402018785476685,
      0.03194442391395569,
      0.0384083054959774,
      -0.0035004299134016037,
      0.044198404997587204,
      0.05691947042942047,
      -0.003305432153865695,
      0.0701320692896843,
      0.12410430610179901,
      -0.011118865571916103,
      0.06447583436965942,
      -0.016934586688876152,
      -0.023875370621681213,
      0.02036379836499691,
      -0.0032226804178208113,
      0.013712341897189617,
      0.07738185673952103,
      -0.020433101803064346,
      -0.0382910780608654,
      -0.01117224432528019,
      -0.0029591855127364397,
      -0.15302079916000366,
      -0.0543554313480854,
      0.019294479861855507,
      -0.04304365813732147,
      -0.0080978162586689,
      -0.04889753460884094,
      -0.016053246334195137,
      -0.06415907293558121,
      0.0321166105568409,
      0.02266920730471611,
      -0.07383973896503448,
      0.0832786038517952,
      -0.12122713029384613,
      0.022055350244045258,
      0.015484496019780636,
      0.06729116290807724,
      0.04188263788819313,
      0.021088948473334312,
      -0.03224753215909004,
      -0.048059623688459396,
      0.05609041079878807,
      -0.03792387247085571,
      -0.013310682028532028,
      0.04316539317369461,
      -0.027768054977059364,
      -0.018646221607923508,
      0.08450759202241898,
      0.01734033413231373,
      -0.027795275673270226,
      0.03246188908815384,
      0.016572188585996628,
      -0.04853273183107376,
      0.061562683433294296,
      0.01806022785604,
      -0.03259707987308502,
      0.029957357794046402,
      0.052627112716436386,
      -0.04195793345570564,
      0.01420376356691122,
      0.04074336215853691,
      0.016877276822924614,
      0.007203920278698206,
      -0.0157871562987566,
      -0.054937783628702164,
      -0.024405069649219513,
      0.021241934970021248,
      -0.05901361256837845,
      0.029568931087851524,
      -0.048503752797842026,
      0.01671111211180687,
      0.01907585933804512,
      0.02219243347644806,
      0.03311599791049957,
      -0.03223574534058571,
      0.04556086286902428,
      -0.027625516057014465,
      -0.03193344548344612,
      -0.11435733735561371,
      0.004974728915840387,
      -0.047134678810834885,
      -0.026564301922917366,
      0.06830128282308578,
      0.058456357568502426,
      -0.06111569330096245,
      0.01112834457308054,
      -0.0405292771756649,
      0.07844271510839462,
      -0.06323173642158508,
      -0.020172862336039543,
      -0.021935421973466873,
      -0.08484876155853271,
      -0.07679605484008789,
      -0.0394059456884861,
      0.06693913787603378,
      0.0369342677295208,
      -0.05078836902976036,
      -0.02592616155743599,
      0.020335523411631584,
      -0.002931903814896941,
      0.024749599397182465,
      0.01621333695948124,
      -0.012402287684381008,
      -0.004688179586082697,
      -0.06620598584413528,
      -0.06857527792453766,
      -0.015148243866860867,
      -0.12123671174049377,
      -0.011200322769582272,
      -0.004083928186446428,
      0.0005527502507902682,
      0.07443340122699738,
      -0.011756720021367073,
      0.008612793870270252,
      0.006361749488860369,
      -0.10343082249164581,
      0.13331671059131622,
      -0.03140329569578171,
      -0.021648121997714043,
      0.03164977580308914,
      0.03504674509167671,
      -0.0386512354016304,
      0.035301126539707184,
      0.0044982279650866985,
      -0.03506559878587723,
      -0.002411771332845092,
      -0.0008869131561368704,
      -0.04077380150556564,
      0.02296409010887146,
      0.04389399662613869,
      -0.08711840212345123,
      -0.07426284998655319,
      -0.06610480695962906,
      -0.014609694480895996,
      -0.03424131125211716,
      0.004315375816076994,
      0.016496118158102036,
      -0.04796368628740311,
      -0.02156229317188263,
      -0.010276849381625652,
      -0.05237820744514465,
      -0.004407492931932211,
      -0.013079081661999226,
      -0.05125875771045685,
      0.025744430720806122,
      -0.05005190148949623,
      0.02718675322830677,
      0.019780738279223442,
      -0.016519516706466675,
      0.017548635601997375,
      0.034457676112651825,
      -0.050262562930583954,
      0.061322811990976334,
      -0.008309658616781235,
      -0.010943812318146229,
      0.06935185939073563,
      -0.0067842877469956875,
      0.021576011553406715,
      0.03706498071551323,
      -0.0037101854104548693,
      -0.007770075928419828,
      0.01641041971743107,
      -0.02561046928167343,
      0.0808936059474945,
      -0.022091643884778023,
      -0.06845741719007492,
      -0.03711772337555885,
      0.01098034530878067,
      -0.019131988286972046,
      0.04804366081953049,
      -0.08250647783279419,
      0.003844409016892314,
      0.009368306025862694,
      0.07491151243448257,
      -0.02710694633424282,
      0.041732531040906906,
      -0.013381260447204113,
      -0.010732944123446941,
      -0.05304713919758797,
      -0.0031658352818340063,
      0.07165549695491791,
      0.06094292178750038,
      -0.041529588401317596,
      0.00637354701757431,
      0.016256287693977356,
      0.022658180445432663,
      0.03662879765033722,
      0.0804075375199318,
      -0.03378341719508171,
      -0.025214415043592453,
      -0.022320786491036415,
      -0.040168557316064835,
      -0.017517071217298508,
      0.06102771684527397,
      -0.012254049070179462,
      0.032143305987119675,
      -0.01224075723439455,
      0.05586671456694603,
      -0.0018068170174956322,
      0.017645681276917458,
      0.06917169690132141,
      0.019981827586889267,
      0.037378307431936264,
      -0.108285091817379,
      -0.023078540340065956,
      0.07589531689882278,
      0.05904126912355423,
      -0.015385705046355724,
      0.06011586636304855,
      -0.038913097232580185,
      0.005725668277591467,
      -0.1504008024930954,
      0.021790282800793648,
      0.005140358582139015,
      -0.05730462446808815,
      -0.01872393861413002,
      -0.06513511389493942,
      -0.03634793683886528,
      -0.036509521305561066,
      -0.038982558995485306,
      0.0032297875732183456,
      -0.07116395980119705,
      -0.05444302782416344,
      -0.08560404926538467,
      0.07183939218521118,
      -0.039231084287166595,
      -0.04316077008843422,
      0.0524357333779335,
      0.0441548116505146,
      -0.0299591813236475,
      -0.12846899032592773,
      -0.018629278987646103,
      0.03624137118458748,
      -0.024665525183081627,
      -0.042023688554763794,
      -0.00013696294627152383,
      -0.002900787629187107,
      -0.003888169303536415,
      0.09174355864524841,
      -0.02495223842561245,
      -0.028034240007400513,
      -0.02379363402724266,
      -0.06138026714324951,
      0.028631487861275673,
      0.053412046283483505,
      0.056566864252090454,
      0.08247877657413483,
      -0.006049595773220062,
      0.008663800545036793,
      0.0019046647939831018,
      -0.027524765580892563,
      0.050530463457107544,
      -0.012911147437989712,
      0.06447252631187439,
      0.03015991859138012,
      0.013883674517273903,
      -0.13663138449192047,
      0.0873032733798027,
      -0.049802977591753006,
      0.02329530008137226,
      0.06187032535672188,
      -0.023888062685728073,
      -0.06016239896416664,
      -0.014282291755080223,
      -0.007917163893580437,
      0.046186771243810654,
      -0.01260276511311531,
      0.03631048649549484,
      -0.014465265907347202,
      0.09507560729980469,
      -0.033254802227020264,
      0.018315162509679794,
      0.030481159687042236,
      0.0746365487575531,
      -0.009001643396914005,
      0.020744282752275467,
      0.046135250478982925,
      0.003187519731000066,
      0.009831340052187443,
      -0.12780612707138062,
      0.07914379239082336,
      -0.08256197720766068,
      -0.00032258249120786786,
      0.022068483754992485,
      -0.06874137371778488,
      0.02283286862075329,
      0.004352965392172337,
      -0.030227055773139,
      -0.04090797156095505,
      -0.02696126140654087,
      0.032634805887937546,
      0.010841429233551025,
      0.026730887591838837,
      0.018219228833913803,
      0.004737818613648415,
      -0.0012508269865065813,
      0.022890163585543633,
      -0.01918933168053627,
      -0.03408282250165939,
      -0.06629583984613419,
      0.06871343404054642,
      0.010832938365638256,
      0.011058827862143517,
      -0.06128574535250664,
      0.052162978798151016,
      -0.04161399230360985,
      0.04294908791780472,
      -0.03623626381158829
    ],
    [
      0.011796374805271626,
      0.004586643073707819,
      0.03467385470867157,
      0.04469933360815048,
      -0.04783099517226219,
      0.01901376061141491,
      -0.02380608767271042,
      -0.029372001066803932,
      0.049262743443250656,
      0.0032436551991850138,
      -0.03230087086558342,
      0.029800347983837128,
      -0.017399800941348076,
      -0.016676604747772217,
      0.025843894109129906,
      0.04475763440132141,
      0.009247925132513046,
      -0.044657282531261444,
      -0.048233069479465485,
      -0.06925131380558014,
      -0.08590911328792572,
      0.05415137857198715,
      0.07549376785755157,
      0.038645170629024506,
      0.05743715912103653,
      -0.0291565153747797,
      -0.021500851958990097,
      -0.051067620515823364,
      0.03528118506073952,
      -0.0019084529485553503,
      -0.04227069020271301,
      -0.04842464253306389,
      -0.04995834454894066,
      0.04397047311067581,
      0.06060551479458809,
      0.0645202174782753,
      -0.07642657309770584,
      0.010954564437270164,
      0.005590840708464384,
      -0.0023541159462183714,
      -0.02745552733540535,
      -0.03236604854464531,
      0.02070099301636219,
      -0.033837854862213135,
      0.028175773099064827,
      0.0204745102673769,
      0.03055400401353836,
      -0.016360318288207054,
      0.050818707793951035,
      -0.015557937324047089,
      0.0015621449565514922,
      0.0552872009575367,
      -0.025889387354254723,
      -0.02041928842663765,
      0.11173925548791885,
      0.01755889691412449,
      0.043869748711586,
      0.019646789878606796,
      0.014977402985095978,
      0.0031800854485481977,
      -0.0028853733092546463,
      -0.05616999417543411,
      0.07090101391077042,
      0.010102702304720879,
      -0.09853783994913101,
      -0.008637858554720879,
      0.028912488371133804,
      -0.016176529228687286,
      -0.0067675006575882435,
      -0.06195475533604622,
      0.0374414436519146,
      0.029475783929228783,
      0.06860298663377762,
      -0.025480834767222404,
      0.06504667550325394,
      -0.009535606950521469,
      0.04445379599928856,
      -0.004855991341173649,
      0.05360377952456474,
      -0.018689459189772606,
      -0.06979651004076004,
      0.06882572919130325,
      -0.018203161656856537,
      -0.02665834315121174,
      0.043605685234069824,
      0.019482478499412537,
      -0.018307305872440338,
      0.0040433709509670734,
      0.04088849946856499,
      -0.06262434273958206,
      0.05940399691462517,
      -0.06001688912510872,
      0.0065695117227733135,
      -0.0019143082899972796,
      -0.020508717745542526,
      -0.025502309203147888,
      -0.002492253202944994,
      0.011640939861536026,
      0.05706730857491493,
      -0.027167778462171555,
      -0.06743307411670685,
      -0.012743922881782055,
      0.024467352777719498,
      0.0005835432675667107,
      0.008794699795544147,
      0.039949771016836166,
      0.048530202358961105,
      0.03424489125609398,
      -0.05487837642431259,
      -0.019853487610816956,
      0.001276826485991478,
      0.010760031640529633,
      -0.08614413440227509,
      0.01747598499059677,
      0.048647183924913406,
      -0.045611876994371414,
      -0.028665535151958466,
      0.02682715840637684,
      0.010560714639723301,
      0.045439109206199646,
      -0.01427668146789074,
      -0.09096028655767441,
      -0.012411420233547688,
      0.14187340438365936,
      0.003848431631922722,
      0.022130031138658524,
      -0.0037341658025979996,
      0.02886713109910488,
      0.10003283619880676,
      0.10268545150756836,
      -0.054238203912973404,
      -0.004229863174259663,
      0.04746117442846298,
      -0.0331563875079155,
      0.03734997287392616,
      -0.03720637038350105,
      -0.04801050201058388,
      -0.002481972798705101,
      0.04807594418525696,
      0.006204565986990929,
      0.030013710260391235,
      0.007413505110889673,
      0.09158731251955032,
      -0.0156759824603796,
      -0.05075483024120331,
      -0.0260637104511261,
      0.03790995478630066,
      0.07052325457334518,
      0.018968725576996803,
      0.006754640955477953,
      0.0020677694119513035,
      -0.049192871898412704,
      -0.024815920740365982,
      -0.03125210851430893,
      -0.02169184200465679,
      0.006444911006838083,
      0.05201779305934906,
      -0.04454747587442398,
      -0.006165596190840006,
      0.028515655547380447,
      0.0989430621266365,
      -0.07766914367675781,
      -0.09375766664743423,
      0.08782730996608734,
      0.017354628071188927,
      0.025541210547089577,
      -0.053117990493774414,
      0.07476477324962616,
      -0.027058381587266922,
      0.12997841835021973,
      -0.015751823782920837,
      -0.02003413252532482,
      0.04398342967033386,
      0.05629405006766319,
      0.053135376423597336,
      -0.020853988826274872,
      -0.04180525615811348,
      -0.0478486642241478,
      -0.03435445576906204,
      0.0003854859387502074,
      -0.009047397412359715,
      0.0015438069822266698,
      -0.009471660479903221,
      0.05271827057003975,
      -0.00024646162637509406,
      -0.013406941667199135,
      -0.024831129238009453,
      -0.048134591430425644,
      0.040261104702949524,
      0.06929098814725876,
      -0.07446438074111938,
      -0.023272983729839325,
      -0.10137972980737686,
      0.008462569676339626,
      -0.040423668920993805,
      -0.04874416068196297,
      0.014351800084114075,
      0.10167746990919113,
      -0.07031968981027603,
      -0.03190857172012329,
      0.031308650970458984,
      -0.009576498530805111,
      0.05653364211320877,
      -0.029456941410899162,
      0.03043592907488346,
      0.03733604773879051,
      -0.06180969998240471,
      0.00767665496096015,
      0.030559631064534187,
      0.014010634273290634,
      0.05661964789032936,
      0.035220224410295486,
      0.026797764003276825,
      -0.06383608281612396,
      -0.04081465303897858,
      0.00673697330057621,
      -0.04669751226902008,
      0.0216657817363739,
      0.0064139049500226974,
      -0.06921584904193878,
      0.05820402130484581,
      -0.017911385744810104,
      -0.03497793897986412,
      0.009603234007954597,
      -0.03316487371921539,
      0.0055985222570598125,
      0.06450080126523972,
      0.0452541746199131,
      -0.05712461471557617,
      0.01697073131799698,
      0.00765299191698432,
      0.067886121571064,
      0.003070626873522997,
      0.09812398999929428,
      0.0517638735473156,
      0.013825264759361744,
      0.012904168106615543,
      0.01515317615121603,
      0.03599592670798302,
      -0.019324569031596184,
      -0.05277208238840103,
      0.013860893435776234,
      0.05489550530910492,
      -0.05198804661631584,
      0.020085088908672333,
      -0.04760930314660072,
      -0.06061648577451706,
      0.06058449298143387,
      -0.014101121574640274,
      -0.013367186300456524,
      -0.030868491157889366,
      -0.008072691038250923,
      -0.018932867795228958,
      -0.01636100374162197,
      -0.004403403028845787,
      -0.016761502251029015,
      -0.08665353804826736,
      0.05124049261212349,
      0.018049726262688637,
      -0.006142643745988607,
      -0.0010665633017197251,
      -0.03181639686226845,
      0.017322782427072525,
      0.006573347374796867,
      0.006361491046845913,
      -0.034816619008779526,
      -0.08478250354528427,
      0.021777670830488205,
      0.0195388812571764,
      0.0036070868372917175,
      0.04474787414073944,
      -0.036418184638023376,
      0.03267538547515869,
      0.008106277324259281,
      0.012357061728835106,
      -0.11493798345327377,
      0.02464490383863449,
      0.06920342147350311,
      -0.022264912724494934,
      -0.01931399293243885,
      0.010368356481194496,
      0.009436228312551975,
      0.07379825413227081,
      0.04307008162140846,
      0.019361456856131554,
      -0.030560726299881935,
      0.02858283743262291,
      0.0341755673289299,
      0.0674671158194542,
      0.028212208300828934,
      -0.026950158178806305,
      0.036049872636795044,
      0.025534214451909065,
      0.057054564356803894,
      -0.031171981245279312,
      -0.007123928051441908,
      -0.03629392385482788,
      -0.057465068995952606,
      0.07255242764949799,
      -0.03310162574052811,
      -0.020666182041168213,
      0.026024186983704567,
      0.026777371764183044,
      0.07193917036056519,
      -0.062199871987104416,
      -0.022031724452972412,
      -0.00958493072539568,
      0.05705280601978302,
      -0.02466847375035286,
      0.05973773077130318,
      0.07395145297050476,
      -0.02892448566854,
      -0.017636604607105255,
      0.040765855461359024,
      0.0060478332452476025,
      -0.07090303301811218,
      -0.12227685004472733,
      0.013726521283388138,
      0.002132716588675976,
      -0.05147614702582359,
      -0.011890527792274952,
      0.020287323743104935,
      -0.0010344292968511581,
      -0.049704659730196,
      0.041638705879449844,
      0.028017275035381317,
      -0.04010709747672081,
      -0.034257665276527405,
      -0.06639667600393295,
      0.004844679031521082,
      0.01412199717015028,
      -0.025627048686146736,
      -0.009266186505556107,
      -0.03193041309714317,
      -0.012416026555001736,
      0.0322498083114624,
      -0.048248041421175,
      0.03002168983221054,
      0.0028261919505894184,
      0.02961890399456024,
      0.06081642955541611,
      0.015409513376653194,
      -0.0043411958031356335,
      0.0014595362590625882,
      -0.00481568556278944,
      -0.0416257306933403,
      -0.11925259232521057,
      -0.03157242015004158,
      0.041465938091278076,
      0.03969492018222809,
      -0.01933116652071476,
      0.06736905127763748,
      0.01682237908244133,
      0.07411818206310272,
      -0.030769072473049164,
      0.019281286746263504,
      -0.017487995326519012,
      0.004678535740822554,
      0.04093465209007263,
      -0.11443445086479187,
      0.005451860837638378,
      -0.024480240419507027,
      0.042542845010757446,
      0.04689919948577881,
      0.019027693197131157,
      -0.03144234046339989,
      -0.033079594373703,
      0.033545173704624176,
      0.03330013528466225,
      -0.02245049551129341,
      0.003095941385254264,
      -0.007169840857386589,
      -0.02132289856672287,
      -0.04047521948814392,
      -0.023404475301504135,
      0.0031340336427092552,
      0.06531359255313873,
      0.01637696474790573,
      0.014766035601496696,
      0.06915327906608582,
      -0.016433170065283775,
      0.0028796507976949215,
      0.006634360179305077,
      0.037377163767814636,
      0.10876268893480301,
      -0.05327437072992325,
      0.05131375789642334,
      0.045523837208747864,
      0.054531048983335495,
      0.00035449923598207533,
      -0.06979960948228836,
      -0.036778006702661514,
      0.019047953188419342,
      0.007412388455122709,
      -0.0018900566501542926,
      0.06229732930660248,
      -0.020060421898961067,
      -0.05016886442899704,
      0.014941969886422157,
      0.09143389761447906,
      0.030536390841007233,
      -0.028839148581027985,
      -0.06189402937889099,
      0.06752698123455048,
      -0.0957951471209526,
      0.08068547397851944,
      0.039157796651124954,
      0.013230806216597557,
      -0.029813960194587708,
      -0.019643738865852356,
      0.029959579929709435,
      0.05628243088722229,
      -0.013918516226112843,
      -0.00835310947149992,
      0.009208058007061481,
      0.05943882837891579,
      0.083061583340168,
      0.017415851354599,
      0.004330923780798912,
      0.04388405382633209,
      0.054457247257232666,
      0.022410860285162926,
      -0.04002508521080017,
      0.02184976078569889,
      -0.02250051312148571,
      -0.03853518143296242,
      -0.032002124935388565,
      -0.006874056067317724,
      -0.0077281673438847065,
      0.026828397065401077,
      -0.03523993119597435,
      0.04036283493041992,
      -0.027953991666436195,
      0.017298761755228043,
      -0.03584803268313408,
      0.003321034135296941,
      -0.07078880071640015,
      -0.018836192786693573,
      -0.031175514683127403,
      -0.015983058139681816,
      -0.06731116026639938,
      -0.01940939389169216,
      0.01339686568826437,
      0.015049371868371964,
      0.08164367824792862,
      0.011204017326235771,
      0.08726794272661209,
      0.04142673686146736,
      0.020627189427614212,
      0.01831563003361225,
      0.024083850905299187,
      -0.019230393692851067,
      -0.02994151972234249,
      0.040879905223846436,
      0.04954840615391731,
      -0.04516683146357536,
      0.05689350888133049,
      0.014664068818092346,
      0.04527464881539345,
      -0.09927176684141159,
      0.016487393528223038,
      0.057838816195726395,
      -0.03859812393784523,
      0.03724367544054985,
      -0.0031535630114376545,
      -0.01475831400603056,
      -0.030132846906781197,
      -0.005596958566457033,
      -0.09895001351833344,
      0.10660099238157272,
      -0.0029329743701964617,
      0.011905469000339508,
      0.03826340660452843,
      0.11831486970186234,
      -0.017739692702889442,
      -0.025569111108779907,
      0.008812550455331802,
      -0.01795141212642193,
      -0.008940434083342552,
      0.024660758674144745,
      -0.08294420689344406,
      -0.029741233214735985,
      -0.04671564698219299,
      -0.039947886019945145,
      -0.04337884858250618,
      -0.043458491563797,
      -0.01817106455564499,
      -0.02420824207365513,
      -0.012880752794444561,
      -0.061173245310783386,
      -0.09411346912384033,
      -0.029624231159687042,
      -0.0010171825997531414,
      0.03591729328036308,
      -8.311746205436066e-05,
      0.005606007296591997,
      -0.03668948635458946,
      -0.049625132232904434,
      -0.08877702802419662,
      0.05389542877674103,
      -0.04773477837443352,
      0.07246408611536026,
      -0.023482389748096466
    ],
    [
      -0.056275829672813416,
      -0.025886084884405136,
      -0.012733323499560356,
      -0.030820809304714203,
      0.0862336978316307,
      -0.06828703731298447,
      -0.03981609642505646,
      -0.007406869903206825,
      -0.024704836308956146,
      -0.017415398731827736,
      -0.01780332624912262,
      0.016769055277109146,
      -0.027453763410449028,
      -0.008917132392525673,
      -0.029730238020420074,
      -0.05808040127158165,
      0.05137042701244354,
      -0.024565111845731735,
      -0.0210223738104105,
      -0.08608753234148026,
      -0.023444855585694313,
      -0.025293705984950066,
      0.07830721884965897,
      0.06100613996386528,
      0.034748584032058716,
      0.03549410030245781,
      -0.07288406044244766,
      -0.09385114908218384,
      -0.028536956757307053,
      -0.07719388604164124,
      0.0010917475447058678,
      -0.03520040959119797,
      0.03659072518348694,
      0.01973809115588665,
      -0.0496218279004097,
      0.014960507862269878,
      -0.027956975623965263,
      -0.06821663677692413,
      -0.06800138205289841,
      0.04775848984718323,
      0.031171483919024467,
      0.030431462451815605,
      0.041130680590867996,
      -0.06620147824287415,
      0.0030640852637588978,
      0.02910357154905796,
      -0.017845898866653442,
      0.04613608494400978,
      -0.040389787405729294,
      -0.033093202859163284,
      -0.0013626542640849948,
      -0.0021440843120217323,
      0.05494329705834389,
      -0.10831835865974426,
      -0.022403685376048088,
      0.07593289762735367,
      -0.04938482865691185,
      0.029288534075021744,
      -0.06967488676309586,
      -0.0038920072838664055,
      -0.04940355569124222,
      0.052613209933042526,
      -0.019414514303207397,
      -0.09252876788377762,
      0.014939268119633198,
      -0.02222219482064247,
      -0.0008502173004671931,
      0.030020100995898247,
      -0.0652070865035057,
      -0.016027340665459633,
      -0.08464383333921432,
      -0.027227263897657394,
      -0.043748099356889725,
      -0.08537818491458893,
      -0.0038112832698971033,
      0.04889547452330589,
      -0.012642176821827888,
      0.024264471605420113,
      -0.07449004799127579,
      0.051146406680345535,
      -0.013507955707609653,
      -0.04453984647989273,
      -0.030341342091560364,
      0.03403535857796669,
      -0.038024820387363434,
      -0.04200885817408562,
      -0.006901968736201525,
      0.03858688101172447,
      0.016628842800855637,
      0.02773791179060936,
      0.05024287477135658,
      -3.40793194482103e-05,
      0.014579806476831436,
      0.01791807822883129,
      0.026276737451553345,
      0.04227897524833679,
      -0.015782181173563004,
      0.001592100947163999,
      0.035261452198028564,
      0.07965188473463058,
      0.0230147335678339,
      -0.033070504665374756,
      0.009825654327869415,
      -0.044228069484233856,
      -0.11591552197933197,
      0.08070296794176102,
      0.008736689575016499,
      -0.00587459746748209,
      -0.014017850160598755,
      0.013191759586334229,
      0.039767492562532425,
      -0.018396500498056412,
      0.01980389654636383,
      0.007862908765673637,
      0.1205592006444931,
      -0.03471513092517853,
      0.02189541421830654,
      0.06183801591396332,
      0.018586494028568268,
      0.03576568141579628,
      0.044336818158626556,
      0.02644927240908146,
      -0.018492214381694794,
      -0.028969375416636467,
      -0.05893384665250778,
      -0.03406050428748131,
      0.006846915930509567,
      -0.04020480811595917,
      0.010786093771457672,
      -0.05602879449725151,
      -0.007978435605764389,
      0.01702897809445858,
      -0.011974089778959751,
      0.01604645512998104,
      -0.014693351462483406,
      -0.026286661624908447,
      0.021210256963968277,
      -0.018820876255631447,
      0.03187209740281105,
      0.03528488799929619,
      -0.02086636610329151,
      0.10391811281442642,
      0.0872136652469635,
      0.03564155101776123,
      -0.04742563143372536,
      -0.09368346631526947,
      -0.09392612427473068,
      -0.021853523328900337,
      -0.04061249643564224,
      -0.07115227729082108,
      0.031230969354510307,
      0.022191122174263,
      -0.021278992295265198,
      -0.0459090955555439,
      0.027348417788743973,
      -0.011159553192555904,
      0.07705176621675491,
      -0.05243787541985512,
      0.0211350005120039,
      0.06703619658946991,
      0.011155874468386173,
      0.004523613024502993,
      -0.015200299210846424,
      0.0695524662733078,
      0.07814290374517441,
      0.0176051277667284,
      0.04949280247092247,
      0.004466427955776453,
      0.04987577721476555,
      -0.0329572968184948,
      0.047037653625011444,
      0.0789012536406517,
      -0.08068985491991043,
      0.039984580129384995,
      -0.033317647874355316,
      0.015576380304992199,
      -0.011282877996563911,
      0.044241297990083694,
      -0.0002797383349388838,
      -0.04240139573812485,
      0.008520826697349548,
      0.016861114650964737,
      0.06107421591877937,
      -0.03506733104586601,
      0.007181647699326277,
      0.003934921231120825,
      -0.03468957915902138,
      -0.013445706106722355,
      -0.029684055596590042,
      -0.059917889535427094,
      -0.01694939099252224,
      0.041994743049144745,
      -0.02276787720620632,
      0.02041463367640972,
      -0.004917968064546585,
      -0.05123576521873474,
      -0.0217218566685915,
      -0.0021726263221353292,
      0.020274531096220016,
      0.009321940131485462,
      0.007536819204688072,
      -0.018212273716926575,
      -0.014990085735917091,
      -0.01867752894759178,
      -0.020253010094165802,
      -0.009433448314666748,
      -0.05530288815498352,
      0.005539909936487675,
      0.052897125482559204,
      -0.03921592980623245,
      0.009914041496813297,
      -0.05338422209024429,
      -0.01695428602397442,
      -0.07497337460517883,
      0.04201480746269226,
      0.035507019609212875,
      -0.06006802245974541,
      -0.06295520067214966,
      0.011167136020958424,
      -0.016632238402962685,
      -0.01172703504562378,
      0.02774408459663391,
      -0.08366568386554718,
      0.0063485316932201385,
      -0.05506740137934685,
      -0.0014908065786585212,
      -0.07274442911148071,
      0.015125253237783909,
      -0.01767898164689541,
      -0.032690566033124924,
      -0.007544190622866154,
      -0.03751438856124878,
      0.03376968204975128,
      0.008468233048915863,
      -0.03823450580239296,
      0.027609385550022125,
      0.002214236417785287,
      -0.08183856308460236,
      0.030673610046505928,
      0.004485796205699444,
      0.021527953445911407,
      0.02516586147248745,
      0.02426695078611374,
      -0.009387463331222534,
      -0.006759401876479387,
      0.00992080383002758,
      -0.04189934954047203,
      0.03940374031662941,
      -0.06015738099813461,
      0.031616441905498505,
      0.03152989596128464,
      -0.026116644963622093,
      -0.04909444600343704,
      -0.013769776560366154,
      0.03018827736377716,
      0.04095744341611862,
      -0.017330773174762726,
      0.0029687301721423864,
      0.01237181294709444,
      0.01284536812454462,
      0.04706324264407158,
      0.03725831210613251,
      -0.07683391124010086,
      -0.04974083602428436,
      -0.03881281986832619,
      -0.03372909873723984,
      -0.062938392162323,
      -0.031769562512636185,
      -0.027051420882344246,
      -0.008752880617976189,
      0.09469085186719894,
      0.05663105472922325,
      -0.017640413716435432,
      -0.05668658763170242,
      -0.020369360223412514,
      0.0348476767539978,
      -0.027162453159689903,
      -0.08862505108118057,
      -0.10273762047290802,
      -0.004784895572811365,
      0.04438312351703644,
      0.018703918904066086,
      0.07300011068582535,
      0.06558897346258163,
      0.025069141760468483,
      -0.03581903502345085,
      0.06137191876769066,
      0.048492275178432465,
      0.007687022909522057,
      0.03243014216423035,
      0.011084005236625671,
      0.05887021869421005,
      0.017440373077988625,
      -0.04390397667884827,
      0.013893784955143929,
      -0.09491748362779617,
      0.014502462930977345,
      0.0520906075835228,
      0.007366758771240711,
      0.11124283075332642,
      0.007780701853334904,
      -0.10820496827363968,
      0.05838276445865631,
      0.05415172129869461,
      -0.020392917096614838,
      -0.023484349250793457,
      0.03467943146824837,
      -0.030142471194267273,
      -0.01379015389829874,
      0.045856181532144547,
      -0.023021921515464783,
      0.01816711761057377,
      -0.08522617816925049,
      -0.005815282929688692,
      0.001020127790980041,
      -0.031724296510219574,
      0.02146841026842594,
      0.0008230487001128495,
      -0.06804526597261429,
      0.021715154871344566,
      0.012289806269109249,
      -0.03286317363381386,
      0.08746383339166641,
      -0.06053057685494423,
      0.06666648387908936,
      0.03892885521054268,
      -0.007136603817343712,
      0.0018116976134479046,
      -0.03111945651471615,
      -0.03605375811457634,
      -0.018732786178588867,
      -0.05447912961244583,
      0.0021374011412262917,
      0.05278569459915161,
      0.011228896677494049,
      0.03346993401646614,
      0.009229510091245174,
      -0.022366810590028763,
      -0.005771244876086712,
      -0.03939609229564667,
      -0.05802128463983536,
      -0.01539814006537199,
      0.008481993339955807,
      0.06827828288078308,
      0.013532095588743687,
      -0.002980251098051667,
      -0.023312149569392204,
      -0.08647208660840988,
      0.045317910611629486,
      0.008196898736059666,
      0.030081219971179962,
      0.021246379241347313,
      -0.08063508570194244,
      0.019687149673700333,
      -0.03912944719195366,
      0.035235580056905746,
      -0.014507818967103958,
      -0.032189130783081055,
      -0.059407107532024384,
      -0.012072914279997349,
      -0.09933815151453018,
      0.05252603441476822,
      -0.021805960685014725,
      0.011620457284152508,
      0.05327959358692169,
      0.020214181393384933,
      0.044533248990774155,
      0.06403199583292007,
      -0.0008871624595485628,
      0.025767885148525238,
      -0.01077272742986679,
      -0.0649823546409607,
      0.010313238948583603,
      -0.07541843503713608,
      -0.03476351499557495,
      -0.05024560168385506,
      -0.02320636622607708,
      -0.008460287004709244,
      0.046056389808654785,
      -0.033709824085235596,
      0.03459050506353378,
      0.06195914372801781,
      0.03438090533018112,
      0.0430314727127552,
      0.01432804111391306,
      0.01429563108831644,
      -0.036949895322322845,
      -0.07056467980146408,
      0.018420346081256866,
      0.0018947391072288156,
      -0.025470739230513573,
      0.07050216197967529,
      0.018979990854859352,
      0.016901178285479546,
      0.018946319818496704,
      0.006158157717436552,
      -0.027752380818128586,
      -0.020205581560730934,
      0.016626572236418724,
      0.029164085164666176,
      0.015443611890077591,
      0.11276461184024811,
      -0.06964011490345001,
      0.018737830221652985,
      0.018045742064714432,
      0.07417791336774826,
      0.051022499799728394,
      -0.00545550137758255,
      -0.028420543298125267,
      0.012805216945707798,
      -0.05738881602883339,
      0.0048232958652079105,
      -0.008048036135733128,
      -0.0010070974240079522,
      -0.029786400496959686,
      0.022410854697227478,
      -0.007597276940941811,
      -0.02843671292066574,
      0.03497212007641792,
      -0.043074481189250946,
      -0.021534783765673637,
      0.06230194494128227,
      -0.053664639592170715,
      0.00017068651504814625,
      -0.017513511702418327,
      -0.02363746613264084,
      0.027006248012185097,
      0.027112465351819992,
      0.07554750889539719,
      0.03777375444769859,
      0.04585326462984085,
      0.016959013417363167,
      0.04011831432580948,
      -0.038402773439884186,
      0.030053062364459038,
      -0.011537007987499237,
      -0.05642329528927803,
      0.04269476234912872,
      0.06689108163118362,
      0.03872903063893318,
      0.015820465981960297,
      0.06721654534339905,
      -0.041459258645772934,
      0.003008435945957899,
      -0.01603427529335022,
      0.08208110183477402,
      -0.039195552468299866,
      -0.050022631883621216,
      -0.04973071441054344,
      -0.022012904286384583,
      0.02018996886909008,
      0.01633106730878353,
      0.02769094705581665,
      -0.03154817596077919,
      -0.06958486139774323,
      -0.05315956845879555,
      0.008379865437746048,
      -0.005648314952850342,
      0.07525481283664703,
      -0.03460860252380371,
      -0.06759987026453018,
      0.07693693786859512,
      0.05880283936858177,
      0.09788650274276733,
      -0.030917765572667122,
      -0.04742525890469551,
      -0.02205836959183216,
      -0.031533464789390564,
      -0.03068920038640499,
      -0.023245546966791153,
      0.02781594730913639,
      0.07786476612091064,
      -0.039039887487888336,
      -0.011531829833984375,
      0.0058965906500816345,
      -0.018427889794111252,
      0.021782102063298225,
      -0.018223637714982033,
      0.04074257239699364,
      0.07295329123735428,
      0.019243944436311722,
      -0.037740349769592285,
      0.003894543508067727,
      -0.014382857829332352,
      -0.0964164063334465,
      -0.01046681310981512,
      0.043168697506189346,
      0.03914158418774605,
      0.03670797497034073,
      -0.025682667270302773,
      -0.03334568440914154,
      0.06809420883655548,
      0.020769476890563965,
      0.006245930213481188,
      -0.03923635557293892,
      0.06785783171653748,
      0.02900242805480957,
      0.008563040755689144,
      0.03656933084130287,
      0.0087289959192276,
      0.04153703153133392,
      0.050152748823165894,
      -0.08288901299238205
    ],
    [
      -0.05557487532496452,
      -0.052433986216783524,
      0.004150140564888716,
      0.019759660586714745,
      -0.02971203625202179,
      0.003658505156636238,
      -0.020393241196870804,
      -0.021691180765628815,
      0.0015183333307504654,
      -0.0020612659864127636,
      0.019027702510356903,
      0.03907644748687744,
      0.06937670707702637,
      -0.14281781017780304,
      0.026548705995082855,
      -0.019795766100287437,
      -0.06024409458041191,
      -0.055146146565675735,
      -0.027809832245111465,
      0.012191022746264935,
      0.008747928775846958,
      0.006120293401181698,
      -0.009962640702724457,
      0.01948191039264202,
      0.1288747638463974,
      0.007942166179418564,
      0.039612699300050735,
      -0.030760319903492928,
      0.07174098491668701,
      -0.09190482646226883,
      0.03603262081742287,
      0.03130456805229187,
      -0.14507701992988586,
      0.003740759799256921,
      -0.029724441468715668,
      -0.011359068565070629,
      0.05769878998398781,
      0.046405013650655746,
      0.007868681102991104,
      -0.07962729781866074,
      -0.012301473878324032,
      -0.0021024353336542845,
      -0.039157889783382416,
      -0.01607864536345005,
      0.037287089973688126,
      -0.031561724841594696,
      -0.009026916697621346,
      0.08194985240697861,
      0.03310462087392807,
      -0.028251750394701958,
      -0.04794292896986008,
      0.04458281770348549,
      0.04461617395281792,
      -0.020927123725414276,
      0.06100551784038544,
      -0.056649159640073776,
      0.10402227193117142,
      0.06579730659723282,
      -0.1277255266904831,
      0.01677880436182022,
      -0.08296159654855728,
      -0.05263053625822067,
      0.013197217136621475,
      0.01938599906861782,
      0.018981149420142174,
      -0.010466566309332848,
      -0.12178846448659897,
      -0.01501397043466568,
      -0.0025372060481458902,
      -0.02738463506102562,
      -0.020635180175304413,
      0.06859786063432693,
      0.006347550544887781,
      0.035075943917036057,
      0.05942840501666069,
      -0.01219690777361393,
      0.06273142248392105,
      0.014458777382969856,
      -0.019006876274943352,
      -0.03454020246863365,
      0.035774290561676025,
      0.016247384250164032,
      0.009061602875590324,
      0.06126841530203819,
      0.028672000393271446,
      0.043056923896074295,
      0.03756839781999588,
      0.08802955597639084,
      0.018796583637595177,
      -0.012113058008253574,
      0.059791672974824905,
      -0.013808037154376507,
      0.01660757139325142,
      0.007299648132175207,
      0.09056250005960464,
      0.008783896453678608,
      0.050179511308670044,
      0.006041420623660088,
      0.11660895496606827,
      0.009508631192147732,
      0.0032243302557617426,
      0.02875707298517227,
      0.05664829537272453,
      -0.0962652713060379,
      -0.016940977424383163,
      -0.002670834306627512,
      -0.041875023394823074,
      -0.017638519406318665,
      0.06272722035646439,
      0.008399829268455505,
      -0.01915336772799492,
      0.03652945160865784,
      0.03292175754904747,
      -0.04876066371798515,
      0.002672535367310047,
      -0.054486460983753204,
      -0.07183929532766342,
      -0.0067316205240786076,
      -0.07235366851091385,
      0.029486654326319695,
      -0.11212554574012756,
      0.0046747224405407906,
      -0.0025480499025434256,
      0.03810594603419304,
      0.052137911319732666,
      -0.024515923112630844,
      -0.05418086051940918,
      -0.005906238686293364,
      0.048350561410188675,
      0.02186199650168419,
      0.02640359289944172,
      -0.018371909856796265,
      -0.02962651476264,
      -0.024947360157966614,
      -0.03655403479933739,
      0.03388265147805214,
      0.02163156308233738,
      0.056388743221759796,
      -0.0172495786100626,
      0.00854112021625042,
      -0.022677510976791382,
      0.05663470923900604,
      -0.038564443588256836,
      -0.03771920129656792,
      0.018431944772601128,
      0.03300338238477707,
      -0.030431227758526802,
      0.07892058789730072,
      0.030390899628400803,
      0.03691456839442253,
      0.0875091627240181,
      0.037062469869852066,
      0.035450808703899384,
      0.03435593843460083,
      0.06815454363822937,
      -0.03590402379631996,
      0.07672903686761856,
      -0.06685619801282883,
      -0.04678291082382202,
      -0.011999456211924553,
      -0.010625559836626053,
      -0.04293690249323845,
      0.009927741251885891,
      0.017885787412524223,
      -0.012069247663021088,
      -0.0061736637726426125,
      -0.004379593767225742,
      0.12398295104503632,
      0.004185042344033718,
      -0.08875733613967896,
      -0.01252969540655613,
      0.03648502379655838,
      -0.04727647826075554,
      0.004530140198767185,
      -0.05937296152114868,
      -0.02169373631477356,
      0.004131578374654055,
      -0.05034562945365906,
      0.030620582401752472,
      -0.037801288068294525,
      -0.010798385366797447,
      0.08486935496330261,
      0.043004728853702545,
      0.002022810047492385,
      -0.006683695130050182,
      -2.521290662116371e-05,
      -0.014142210595309734,
      -0.012460317462682724,
      -0.0023204456083476543,
      0.0007585097337141633,
      -0.043359965085983276,
      -0.02338659018278122,
      -0.002586852293461561,
      0.012534020468592644,
      0.01728188246488571,
      -0.02318016067147255,
      0.06478657573461533,
      0.03019605204463005,
      -0.002932131988927722,
      0.015860414132475853,
      -0.010899356566369534,
      0.06564202159643173,
      0.03591453284025192,
      -0.0038515161722898483,
      0.02009970135986805,
      0.09138387441635132,
      -0.05632263794541359,
      0.030922628939151764,
      0.06112666800618172,
      0.03392593562602997,
      -0.016626931726932526,
      0.0011598457349464297,
      0.013788649812340736,
      -0.040340356528759,
      -0.050496723502874374,
      0.012761136516928673,
      0.026827000081539154,
      0.04091688245534897,
      0.013929176144301891,
      -0.014023066498339176,
      -0.00750905042514205,
      0.06933287531137466,
      0.027017589658498764,
      -0.0025836278218775988,
      -0.05189625918865204,
      0.0003969537792727351,
      -0.03763493895530701,
      -0.016712205484509468,
      -0.012744981795549393,
      -0.0023572109639644623,
      0.015298416838049889,
      -0.048378340899944305,
      0.11282045394182205,
      0.028375133872032166,
      -0.05549877882003784,
      0.01559324748814106,
      0.03144698962569237,
      0.041488248854875565,
      0.028065618127584457,
      -0.050994083285331726,
      -0.007863267324864864,
      0.0727502778172493,
      -0.06885379552841187,
      0.0007286790641956031,
      0.014593067578971386,
      -0.061348214745521545,
      0.037257831543684006,
      0.04607374221086502,
      -0.04826432839035988,
      -0.03749268874526024,
      0.005046502221375704,
      0.026290075853466988,
      -0.08428820967674255,
      -0.0024100185837596655,
      0.030001958832144737,
      -0.03219478577375412,
      -0.009427634067833424,
      -0.00384554173797369,
      0.022803327068686485,
      -0.004166109953075647,
      0.014834096655249596,
      0.0066733649000525475,
      0.012986822053790092,
      -0.03773006424307823,
      -0.02779410034418106,
      0.035463545471429825,
      -0.09120971709489822,
      0.027091804891824722,
      -0.09143195301294327,
      -0.01365063339471817,
      0.0020056534558534622,
      -0.05562923476099968,
      0.03516363352537155,
      0.06822039186954498,
      -0.029062408953905106,
      0.012158367782831192,
      0.018298741430044174,
      0.04780373349785805,
      0.015884796157479286,
      0.010149537585675716,
      -0.025519397109746933,
      0.04479902982711792,
      -0.00935756042599678,
      0.09785842895507812,
      -0.006232357118278742,
      -0.00850648432970047,
      0.026047052815556526,
      0.0519540049135685,
      0.03285490721464157,
      0.00730142230167985,
      -0.009277408942580223,
      0.04246552288532257,
      0.0017037414945662022,
      0.0003973176353611052,
      0.04841426759958267,
      -0.019316256046295166,
      -0.020464183762669563,
      0.04574155434966087,
      0.027969766408205032,
      -0.03069748915731907,
      -0.002283649519085884,
      0.07548318058252335,
      0.0531810000538826,
      0.06751369684934616,
      -0.028070449829101562,
      0.08076683431863785,
      -0.027275584638118744,
      -0.056622203439474106,
      0.059557389467954636,
      -0.09696165472269058,
      -0.029849886894226074,
      0.011375385336577892,
      -0.027491867542266846,
      -0.014867260120809078,
      0.02918330393731594,
      -0.06938072293996811,
      -0.04330303892493248,
      0.019773302599787712,
      0.048532672226428986,
      -0.07404909282922745,
      0.010354815050959587,
      0.03594132885336876,
      0.011462760157883167,
      -0.0018491750815883279,
      0.05529005452990532,
      -0.05714826658368111,
      -0.02127692475914955,
      0.005266771651804447,
      0.062157969921827316,
      0.015986645594239235,
      0.0244409441947937,
      -0.058716390281915665,
      -0.04904733598232269,
      0.111052967607975,
      0.024136802181601524,
      0.059649817645549774,
      -0.04853008687496185,
      -0.051740970462560654,
      0.07498112320899963,
      0.014193263836205006,
      -0.017348941415548325,
      0.061515532433986664,
      -0.018867917358875275,
      0.059350401163101196,
      0.03265472874045372,
      0.007832612842321396,
      -0.02425323985517025,
      0.09068551659584045,
      0.030718810856342316,
      -0.04881579801440239,
      0.10983197391033173,
      0.06606695801019669,
      -0.04377377778291702,
      -0.006835021078586578,
      -0.032300062477588654,
      -0.007273837924003601,
      -0.006488598883152008,
      0.11137319356203079,
      0.018865900114178658,
      -0.03706526383757591,
      -0.013319835998117924,
      -0.026243114843964577,
      -0.005337676499038935,
      0.0008310184930451214,
      0.0072930241003632545,
      0.005966233089566231,
      0.021313130855560303,
      0.005993580911308527,
      -0.06109943985939026,
      0.001955363666638732,
      -0.02844952791929245,
      -0.10963062196969986,
      -0.043960679322481155,
      -0.05501924455165863,
      0.0181746706366539,
      0.008104374632239342,
      0.018198128789663315,
      0.04669491946697235,
      -0.08514394611120224,
      0.0008257662993855774,
      0.010571334511041641,
      0.0747593492269516,
      -0.023140449076890945,
      -0.009545080363750458,
      0.011425581760704517,
      -0.0013802741887047887,
      -0.012546868063509464,
      -0.046914078295230865,
      -0.056722670793533325,
      -0.007252690847963095,
      0.013099854812026024,
      0.05270787328481674,
      0.06458483636379242,
      -0.027766063809394836,
      -0.04079170525074005,
      -0.03441742807626724,
      0.05911261588335037,
      -0.0237757358700037,
      0.014313976280391216,
      0.03191177174448967,
      0.018790679052472115,
      0.024167384952306747,
      -0.003168548224493861,
      0.01033609826117754,
      -0.020451845601201057,
      0.05155985802412033,
      -0.03240075334906578,
      -0.022404665127396584,
      -0.022924067452549934,
      -0.05944833904504776,
      0.027971507981419563,
      -0.040059853345155716,
      0.01625407114624977,
      -0.007411452475935221,
      -0.018405983224511147,
      -0.053982339799404144,
      -0.07538295537233353,
      -0.0046499562449753284,
      0.03358116373419762,
      0.046469736844301224,
      -0.08393973112106323,
      0.060525570064783096,
      0.030503297224640846,
      -0.016839688643813133,
      -0.05641455203294754,
      -0.030082643032073975,
      0.12917059659957886,
      0.03864092007279396,
      -0.042446427047252655,
      -0.0489828959107399,
      0.07492804527282715,
      0.0377805121243,
      -0.01806039921939373,
      -0.00617098156362772,
      -0.03742826730012894,
      0.03250737488269806,
      0.052710194140672684,
      0.03506485000252724,
      -0.014186583459377289,
      0.03246359899640083,
      0.00826495885848999,
      0.005749493837356567,
      0.12600192427635193,
      -0.043041978031396866,
      -0.04322993755340576,
      -0.0026496113277971745,
      -0.030827339738607407,
      -0.012685849331319332,
      0.029835334047675133,
      -0.0521954782307148,
      0.0043944427743554115,
      -0.058161571621894836,
      -0.03774700686335564,
      0.0007577679352834821,
      0.02288874424993992,
      0.01569221168756485,
      0.05068565905094147,
      -0.04950181394815445,
      0.020120417699217796,
      0.02042483724653721,
      -0.019901951774954796,
      -0.01575653627514839,
      0.03421011194586754,
      0.029479030519723892,
      0.02870316058397293,
      0.010774975642561913,
      0.04724007099866867,
      0.05704724043607712,
      0.007310075685381889,
      -0.03344490006566048,
      0.019857602193951607,
      -0.018829215317964554,
      -0.03413037210702896,
      0.06232084333896637,
      0.0382726825773716,
      0.06394418329000473,
      0.011677570641040802,
      -0.018896963447332382,
      0.0016980674117803574,
      0.009543689899146557,
      0.0604291595518589,
      -0.03905501961708069,
      -0.027952298521995544,
      0.10565678775310516,
      0.013571631163358688,
      -0.05465337634086609,
      0.06271267682313919,
      -0.022207286208868027,
      0.03583754226565361,
      -0.05626918375492096,
      -0.03204548358917236,
      -0.00808650441467762,
      -0.004313313867896795,
      0.012587628327310085,
      0.0006656881887465715,
      -0.033713024109601974,
      -0.04382326453924179,
      -0.009880956262350082,
      -0.010442358441650867,
      -0.0046298387460410595,
      0.005607627332210541,
      0.05269455537199974,
      -0.049532074481248856
    ],
    [
      0.014125232584774494,
      -0.06305902451276779,
      -0.0031400921288877726,
      -0.1468181312084198,
      0.05926711857318878,
      -0.0433339923620224,
      0.05339011922478676,
      -0.0977083072066307,
      -0.030296452343463898,
      0.04505258426070213,
      0.03400097414851189,
      0.0002022559056058526,
      0.034842126071453094,
      -0.04448317736387253,
      0.010061495937407017,
      -0.0005550945061258972,
      -0.009578220546245575,
      0.026682956144213676,
      -0.02395855262875557,
      -0.01753966137766838,
      -0.001334338216111064,
      -0.06405539810657501,
      0.057056624442338943,
      0.036120764911174774,
      0.029263092204928398,
      0.011817554011940956,
      -0.03261089697480202,
      -0.034056566655635834,
      0.1031949445605278,
      0.040238697081804276,
      -0.03675515204668045,
      -0.03991612792015076,
      -0.05544136464595795,
      0.020295243710279465,
      0.062982939183712,
      0.06374816596508026,
      -0.06487567722797394,
      0.047862354665994644,
      -0.007551239803433418,
      -0.02018275298178196,
      -0.04227222129702568,
      -0.07062437385320663,
      -0.029387731105089188,
      0.04015889763832092,
      -0.02028738148510456,
      0.009817973710596561,
      0.03595627471804619,
      0.06779054552316666,
      0.05529720336198807,
      0.0012485507177188993,
      0.06633388996124268,
      -0.01115446351468563,
      0.051977936178445816,
      0.04065356031060219,
      0.09423096477985382,
      0.023175394162535667,
      0.009333266876637936,
      0.0897887721657753,
      -0.011079409159719944,
      0.015514635480940342,
      -0.049549710005521774,
      -0.06058388948440552,
      0.05394485220313072,
      0.006603624206036329,
      -0.09156432002782822,
      0.0719730481505394,
      0.023199595510959625,
      -0.002564440481364727,
      0.02315855398774147,
      0.005838002543896437,
      -0.04722655192017555,
      0.01861412264406681,
      -0.00044545670971274376,
      0.056179191917181015,
      -0.004086179193109274,
      -0.06978937983512878,
      0.003077288158237934,
      -0.0004744711040984839,
      -0.06222003325819969,
      -0.010292600840330124,
      -0.0018851544009521604,
      0.022779667750000954,
      -0.04610801115632057,
      0.06607343256473541,
      0.002516830572858453,
      -0.036464300006628036,
      -0.04336458817124367,
      -0.02570105530321598,
      -0.0013407160295173526,
      -0.059622686356306076,
      0.04445956274867058,
      0.01847384311258793,
      0.036066193133592606,
      0.047748591750860214,
      0.0719522014260292,
      0.0343497060239315,
      -0.016272109001874924,
      0.027242178097367287,
      -0.006591079756617546,
      -0.0865350067615509,
      -0.024389972910284996,
      0.009679120033979416,
      -0.03190241754055023,
      -0.0168497022241354,
      -0.020341746509075165,
      0.02744237147271633,
      0.025374125689268112,
      0.05567363649606705,
      -0.025834547355771065,
      0.05145403370261192,
      -0.06648589670658112,
      0.031877823173999786,
      -0.03246523067355156,
      -0.006642250344157219,
      -0.03783080354332924,
      -0.001289820997044444,
      -0.040924303233623505,
      0.1097860038280487,
      -0.06066705659031868,
      0.02417614497244358,
      -0.03481610491871834,
      0.01061289757490158,
      -0.019553542137145996,
      0.01964893564581871,
      0.07403557002544403,
      -0.0622960701584816,
      0.016542712226510048,
      0.049291666597127914,
      -0.023473726585507393,
      0.03200015798211098,
      -0.027629205957055092,
      0.04556417465209961,
      0.033082202076911926,
      0.008489384315907955,
      -0.003480465617030859,
      0.06944437325000763,
      0.015588306821882725,
      0.03674263879656792,
      0.010221444070339203,
      0.07239163666963577,
      -0.048480186611413956,
      -0.029369238764047623,
      -0.0709284096956253,
      -0.03746837005019188,
      -0.03850124403834343,
      0.029558205977082253,
      -0.03856443241238594,
      0.01670457236468792,
      0.010072514414787292,
      0.09406649321317673,
      0.016713254153728485,
      -0.023426273837685585,
      0.034994207322597504,
      -0.06354188919067383,
      0.01855749636888504,
      -0.01794600859284401,
      0.03258705511689186,
      -0.046378105878829956,
      0.005118963308632374,
      -0.021534910425543785,
      0.06107640266418457,
      -0.05361491069197655,
      -0.04525819793343544,
      0.10154958814382553,
      0.02415798418223858,
      0.09961716085672379,
      0.12251242250204086,
      0.02306300215423107,
      -0.010302991606295109,
      -0.03329874575138092,
      -0.050573673099279404,
      -0.03699683025479317,
      -0.022909272462129593,
      0.1082155779004097,
      0.04967030510306358,
      -0.019811004400253296,
      0.010685435496270657,
      -0.008914112113416195,
      0.01925748772919178,
      -0.0771322101354599,
      0.031094152480363846,
      -0.02743217535316944,
      -0.034633785486221313,
      -0.010482532903552055,
      0.012463054619729519,
      -0.0300448015332222,
      -0.009905820712447166,
      0.014945128932595253,
      0.0008332685683853924,
      0.05847927927970886,
      -0.039566025137901306,
      0.017103219404816628,
      -0.03343316912651062,
      -0.016945354640483856,
      0.04859749972820282,
      0.032186590135097504,
      -0.03377169370651245,
      0.018377922475337982,
      -0.035914573818445206,
      0.012063594534993172,
      0.021072886884212494,
      -0.04977484419941902,
      0.07347333431243896,
      -0.038142185658216476,
      0.054525863379240036,
      0.10556678473949432,
      -0.026720287278294563,
      -0.05606728792190552,
      -0.13921236991882324,
      0.028045570477843285,
      -0.0011103785363957286,
      0.005375409498810768,
      0.02449004165828228,
      0.016696259379386902,
      0.02567693591117859,
      0.09945081174373627,
      -0.001931074890308082,
      0.0045606354251503944,
      -0.055458854883909225,
      -0.04977031424641609,
      -0.02150777541100979,
      0.0337294340133667,
      -0.004319452680647373,
      0.05514790862798691,
      -0.01776365004479885,
      0.008009219542145729,
      0.04766632989048958,
      0.07486533373594284,
      -0.014635699801146984,
      -0.0296229999512434,
      -0.04395182430744171,
      -0.05742190405726433,
      0.01737063005566597,
      -0.0307998675853014,
      -0.002491182880476117,
      -0.0565929152071476,
      0.009931591339409351,
      0.04275719076395035,
      0.010488190688192844,
      -0.0004748204373754561,
      0.03127148747444153,
      -0.007025042083114386,
      0.05876324325799942,
      0.03551018610596657,
      0.0011060317046940327,
      0.06886166334152222,
      -0.053418200463056564,
      0.05619068071246147,
      0.09602697193622589,
      0.004781475756317377,
      -0.050014298409223557,
      -0.03534972295165062,
      0.02457370236515999,
      0.04820040613412857,
      -0.032595787197351456,
      0.0017469431040808558,
      -0.05934620276093483,
      -8.049803000176325e-05,
      0.025200923904776573,
      0.0866294801235199,
      -0.01476907916367054,
      -0.01030410174280405,
      -0.025793777778744698,
      -0.07088964432477951,
      -0.036283720284700394,
      0.04061390459537506,
      -0.04980906471610069,
      -0.08095657825469971,
      -0.035745784640312195,
      0.02967892959713936,
      -0.004492138512432575,
      -0.1277543604373932,
      -0.013083698228001595,
      -0.03081326000392437,
      0.014682019129395485,
      0.023472823202610016,
      -0.022759858518838882,
      0.028441620990633965,
      -0.013957052491605282,
      -0.06535962969064713,
      0.06027428060770035,
      0.06549863517284393,
      -0.04730914160609245,
      0.025336500257253647,
      0.09112348407506943,
      -0.028459787368774414,
      0.042935214936733246,
      0.025516442954540253,
      -0.0413522832095623,
      0.006383013911545277,
      0.04284313693642616,
      -0.07415986806154251,
      0.011768870055675507,
      -0.03709713742136955,
      -0.08638748526573181,
      -0.01745731756091118,
      -0.05196984484791756,
      0.045623667538166046,
      0.030879730358719826,
      0.03982369229197502,
      0.004499493166804314,
      -0.00793531071394682,
      0.011185841634869576,
      0.07404057681560516,
      0.02554142288863659,
      -0.050662897527217865,
      0.021727891638875008,
      0.019699765369296074,
      0.04113028571009636,
      -0.05030303820967674,
      0.024271829053759575,
      0.03153538331389427,
      -0.020251799374818802,
      -0.012012656778097153,
      0.014633454382419586,
      0.01413051038980484,
      -0.04860702529549599,
      0.07786190509796143,
      0.005669634789228439,
      -0.014353801496326923,
      0.03633202239871025,
      -0.015315287746489048,
      0.009600913152098656,
      0.011718736961483955,
      0.03274770826101303,
      0.05924716964364052,
      0.048407182097435,
      0.04175463691353798,
      -0.028621675446629524,
      -0.01163892075419426,
      0.0681237280368805,
      -0.010668586939573288,
      0.02468748576939106,
      0.03642492741346359,
      0.09329033643007278,
      0.014348292723298073,
      -0.040895916521549225,
      -0.07079518586397171,
      -0.021566353738307953,
      -0.006567702628672123,
      -0.032493095844984055,
      0.050801731646060944,
      0.005212582182139158,
      0.04070127382874489,
      -0.0385899692773819,
      0.06762346625328064,
      -0.15740999579429626,
      -0.01179771963506937,
      0.09392071515321732,
      -0.03367497771978378,
      -0.02968496084213257,
      0.004618755541741848,
      0.04279625788331032,
      -0.003491272684186697,
      -0.002405649982392788,
      -0.01605123281478882,
      -0.010063964873552322,
      -0.006699776742607355,
      0.021140489727258682,
      -0.022593118250370026,
      0.04278043657541275,
      -0.0715441107749939,
      -0.037826310843229294,
      0.05522956699132919,
      -0.0638950765132904,
      -0.017289921641349792,
      0.015648307278752327,
      -0.05185636878013611,
      0.012364792637526989,
      -0.02196848765015602,
      0.06653399765491486,
      -0.08487015217542648,
      0.00018556986469775438,
      -0.04420499876141548,
      0.08684055507183075,
      0.03288915008306503,
      0.08625584095716476,
      -0.07615260034799576,
      -0.0909559577703476,
      -0.04527070000767708,
      0.0910373404622078,
      0.047951243817806244,
      0.09125665575265884,
      -0.024991866201162338,
      0.02152249403297901,
      -0.0406607948243618,
      0.005117627792060375,
      -0.008648793213069439,
      0.016987893730401993,
      0.07342799007892609,
      0.07525812834501266,
      -0.049539752304553986,
      0.029802491888403893,
      -0.0270213782787323,
      -0.0673111081123352,
      -0.017209192737936974,
      0.11689053475856781,
      0.04128715395927429,
      -0.084614098072052,
      0.03432181477546692,
      -0.004762614611536264,
      0.028708074241876602,
      0.018521646037697792,
      -0.005825398024171591,
      0.03486550971865654,
      0.08425968885421753,
      0.022602977231144905,
      -0.05411182716488838,
      -0.022465752437710762,
      -0.07455012202262878,
      0.0586303174495697,
      -0.06195366382598877,
      0.005604353733360767,
      -0.07527624070644379,
      -0.010475454851984978,
      0.036266133189201355,
      0.044245388358831406,
      -0.011075646616518497,
      0.03634684160351753,
      -0.05830904841423035,
      0.0020246226340532303,
      0.00559919374063611,
      -0.004505553748458624,
      0.047641586512327194,
      -0.057084690779447556,
      -0.04687480255961418,
      -0.05780893564224243,
      -0.026908759027719498,
      0.03890009969472885,
      -0.08101586997509003,
      -0.037280816584825516,
      0.005031904671341181,
      0.036924075335264206,
      0.008015641011297703,
      0.009873861446976662,
      0.04694407433271408,
      0.035006724298000336,
      0.0264994278550148,
      0.008439620025455952,
      -0.011760582216084003,
      -0.004868683405220509,
      0.07160867005586624,
      0.007241185288876295,
      -0.036593809723854065,
      -0.020960135385394096,
      -0.039945390075445175,
      0.015966106206178665,
      0.08947544544935226,
      -0.00792793557047844,
      0.02366062067449093,
      0.008341597393155098,
      -0.010858791880309582,
      0.003409785218536854,
      -0.048980485647916794,
      -0.09850326925516129,
      -0.019032899290323257,
      -0.0873490646481514,
      0.035419680178165436,
      0.034426409751176834,
      0.023186570033431053,
      -0.04160191863775253,
      0.04725120589137077,
      0.06552465260028839,
      -0.025108201429247856,
      0.08663687109947205,
      -0.01202983595430851,
      0.06202598661184311,
      0.00935741513967514,
      -0.10817641019821167,
      -0.006523145828396082,
      0.002079092198982835,
      -0.029237786307930946,
      -0.00944965798407793,
      0.021768230944871902,
      -0.04141616076231003,
      0.042808257043361664,
      0.013063842430710793,
      0.008395178243517876,
      0.018653424456715584,
      -0.03457356616854668,
      -0.05615157261490822,
      0.00800356175750494,
      0.06215760484337807,
      -0.025198565796017647,
      -0.0927756130695343,
      0.09264197945594788,
      0.034121934324502945,
      0.014538874849677086,
      0.05303363874554634,
      -0.01645776815712452,
      0.013530489057302475,
      0.024776356294751167,
      0.023350082337856293,
      0.009051527827978134,
      0.03237944096326828,
      -0.02706271968781948,
      0.05493989959359169,
      -0.06260140985250473,
      0.01265808753669262,
      0.0004264240269549191,
      0.0363653190433979,
      -0.027249103412032127,
      0.043138422071933746
    ],
    [
      -0.06818577647209167,
      -0.0022904658690094948,
      0.01691417582333088,
      0.0721546933054924,
      0.008621926419436932,
      -0.009475200437009335,
      -0.009425168856978416,
      0.00643915357068181,
      -0.01564442366361618,
      -0.050408609211444855,
      0.07826519757509232,
      -0.15021999180316925,
      -0.06467942148447037,
      0.03579142689704895,
      0.06697314232587814,
      -0.015564476139843464,
      0.045215070247650146,
      -0.0015965665224939585,
      0.03692911937832832,
      0.10286923497915268,
      -0.007046235725283623,
      -0.015365258790552616,
      -0.002457802416756749,
      -0.03410867229104042,
      0.009553337469696999,
      0.06494836509227753,
      -0.02365054376423359,
      -0.02241699956357479,
      -0.03504559025168419,
      -0.0022829959634691477,
      0.024377427995204926,
      -0.02700743079185486,
      -0.04516902193427086,
      -0.031951453536748886,
      0.07692056894302368,
      0.015583706088364124,
      -0.019659051671624184,
      0.01795092225074768,
      0.03555591404438019,
      0.05520940199494362,
      -0.009726928547024727,
      -0.037906255573034286,
      0.04895893856883049,
      -0.021735722199082375,
      0.01378785353153944,
      -0.04295908287167549,
      -0.018221084028482437,
      -0.02491655759513378,
      -0.0307049211114645,
      -0.03571239486336708,
      -0.02476009726524353,
      0.01250727940350771,
      -0.08906296640634537,
      -0.035064924508333206,
      -0.04184974357485771,
      0.0316682830452919,
      0.0038235345855355263,
      0.03476350009441376,
      -0.006907158996909857,
      0.03304635360836983,
      0.02498680166900158,
      -0.025639191269874573,
      0.02021428942680359,
      -0.10104172676801682,
      0.023922214284539223,
      0.05822703614830971,
      0.04018876701593399,
      -0.11152718216180801,
      -0.01528042834252119,
      0.006922930013388395,
      -0.05751447007060051,
      0.04609886184334755,
      -0.02327605150640011,
      -0.06956733763217926,
      0.061220746487379074,
      -0.014080235734581947,
      0.0016710447380319238,
      -0.0672529935836792,
      -0.011977912858128548,
      -0.0245857834815979,
      -0.018780890852212906,
      -0.006515534594655037,
      -0.005161084234714508,
      0.11265257000923157,
      0.028951087966561317,
      -0.0012306886492297053,
      0.009501135908067226,
      -0.06615329533815384,
      0.020270949229598045,
      0.009622476994991302,
      0.07505615800619125,
      -0.012645820155739784,
      0.05460634082555771,
      -0.020207839086651802,
      -0.02649141661822796,
      -0.031153567135334015,
      -0.019872281700372696,
      0.09459082037210464,
      -0.012395723722875118,
      -0.06914068013429642,
      0.00320992898195982,
      0.0540926568210125,
      0.02322540618479252,
      0.025069845840334892,
      -0.013754582032561302,
      0.0011108667822554708,
      -0.0705052837729454,
      -0.017896505072712898,
      -0.06591862440109253,
      0.03515248000621796,
      0.090336874127388,
      0.05169788375496864,
      -0.051870230585336685,
      0.08676624298095703,
      0.08045534044504166,
      -0.046049997210502625,
      0.005588027648627758,
      0.005004396662116051,
      -0.10671788454055786,
      -0.015197987668216228,
      0.0035959051456302404,
      -0.043754760175943375,
      -0.0674772635102272,
      -0.026408858597278595,
      0.03751082345843315,
      0.01719343289732933,
      0.014238953590393066,
      -0.048876482993364334,
      0.04806532338261604,
      -0.09333875775337219,
      -0.011960855685174465,
      -0.046788718551397324,
      -0.0011299853213131428,
      0.053612761199474335,
      -0.03567667677998543,
      -0.010580860078334808,
      -0.01936209201812744,
      -0.0882922112941742,
      -0.014910407364368439,
      0.06645909696817398,
      -0.07700687646865845,
      0.017977474257349968,
      0.017891013994812965,
      -0.08172403275966644,
      -0.010676848702132702,
      0.007326594088226557,
      0.04244915768504143,
      -0.05604806914925575,
      0.04127855226397514,
      0.011988027952611446,
      -0.013376104645431042,
      0.08815067261457443,
      -0.014352131634950638,
      -0.0229437667876482,
      0.08610512316226959,
      0.04310154542326927,
      -0.08539564162492752,
      0.08827199786901474,
      -0.05167701095342636,
      0.08627240359783173,
      0.004100605845451355,
      -0.06405012309551239,
      0.036606110632419586,
      -0.027326736599206924,
      0.015879929065704346,
      0.04071924090385437,
      -0.006555507890880108,
      0.029051654040813446,
      0.03214443102478981,
      0.003430497134104371,
      0.03273925930261612,
      0.07018852233886719,
      0.05529293790459633,
      0.07064737379550934,
      0.07250513881444931,
      -0.02076425589621067,
      -0.0052317483350634575,
      0.03261183574795723,
      0.048322662711143494,
      0.023644762113690376,
      0.02390757016837597,
      0.049180250614881516,
      -0.015592791140079498,
      -0.013427936471998692,
      -0.08929811418056488,
      -0.036905817687511444,
      0.050271905958652496,
      -0.04886310547590256,
      -0.055040039122104645,
      -0.060817692428827286,
      0.004223414696753025,
      0.037123728543519974,
      0.06741606444120407,
      0.0178860891610384,
      0.0355776883661747,
      0.024216262623667717,
      0.045207783579826355,
      -0.029610075056552887,
      -0.02135513722896576,
      -0.04567872732877731,
      0.0035204088781028986,
      0.006285607814788818,
      -0.020424555987119675,
      -0.050865061581134796,
      0.0628906637430191,
      -0.03581254556775093,
      -0.07606961578130722,
      0.024627532809972763,
      0.036184415221214294,
      0.0007457948522642255,
      0.026279263198375702,
      -0.015967773273587227,
      -0.07586909085512161,
      -0.05973317474126816,
      -0.007855299860239029,
      0.021914638578891754,
      -0.0163436122238636,
      -0.05590648949146271,
      0.019839851185679436,
      -0.055897291749715805,
      -0.03195394203066826,
      -0.01118115521967411,
      -0.048503365367650986,
      0.03096778318285942,
      -0.01673203706741333,
      -0.02145092748105526,
      -0.024363962933421135,
      0.022654633969068527,
      -0.049472611397504807,
      0.044600456953048706,
      0.042899198830127716,
      -0.01363155897706747,
      -0.026620764285326004,
      -0.03899316489696503,
      0.030398551374673843,
      -0.008840732276439667,
      -0.06690898537635803,
      0.023805024102330208,
      0.015280759893357754,
      -0.01576429232954979,
      -0.01285354234278202,
      0.006204794626682997,
      -0.0451883003115654,
      0.010090496391057968,
      -0.050667840987443924,
      -0.008452387526631355,
      0.0005157756386324763,
      -0.060902487486600876,
      -0.038338564336299896,
      0.01645538955926895,
      -0.053873058408498764,
      0.0028495434671640396,
      0.027938349172472954,
      -0.011387613601982594,
      0.016521265730261803,
      -0.003435674589127302,
      -0.02191074937582016,
      -0.009343426674604416,
      0.006501190364360809,
      -0.024425838142633438,
      0.017331542447209358,
      0.015959128737449646,
      0.07842491567134857,
      0.023023447021842003,
      -0.004347871523350477,
      0.004611539654433727,
      -0.031026434153318405,
      -0.07291661202907562,
      0.00298775895498693,
      0.020575283095240593,
      -0.03453943505883217,
      -0.03129948675632477,
      -0.039508040994405746,
      -0.030865725129842758,
      -0.02983943000435829,
      0.005082485731691122,
      0.09981741011142731,
      -0.02171272598206997,
      -0.03048769198358059,
      0.010039902292191982,
      -0.011176680214703083,
      0.017697777599096298,
      0.012298953719437122,
      -0.04805412515997887,
      -0.027966918423771858,
      0.017465513199567795,
      0.09098983556032181,
      0.03990170359611511,
      -0.05413145199418068,
      -0.04724680632352829,
      -0.03421473130583763,
      0.013735773041844368,
      0.04764564707875252,
      0.003920945338904858,
      -0.07569493353366852,
      0.06192883104085922,
      -0.05821738392114639,
      0.07928897440433502,
      0.014563310891389847,
      0.08281774073839188,
      -0.028444582596421242,
      0.01640624739229679,
      0.00240081618539989,
      0.04460436850786209,
      0.009005452506244183,
      0.0019582072272896767,
      -0.11344840377569199,
      0.06409233063459396,
      0.05855801701545715,
      0.01808534748852253,
      1.1376327165635303e-05,
      0.020034927874803543,
      -0.08616890013217926,
      0.038030851632356644,
      0.007726459763944149,
      0.04287630692124367,
      -0.06510663777589798,
      -0.005574661307036877,
      -0.04034395143389702,
      0.11328531056642532,
      -0.011078210547566414,
      0.022380055859684944,
      0.0385311022400856,
      -0.025777297094464302,
      -0.08213222026824951,
      0.011636621318757534,
      -0.04405719041824341,
      0.011716644279658794,
      -0.027424274012446404,
      0.04659772291779518,
      -0.0068192207254469395,
      -0.008646908216178417,
      0.051763392984867096,
      -0.0173652283847332,
      0.0022478264290839434,
      -0.033186912536621094,
      -0.013395619578659534,
      -0.09396596997976303,
      0.03391781449317932,
      -0.07346291840076447,
      -0.03795991465449333,
      -0.044336799532175064,
      -0.057190392166376114,
      -0.02960972487926483,
      0.010032362304627895,
      0.06289436668157578,
      0.008913019672036171,
      0.012891965918242931,
      0.05605887249112129,
      -0.1005590632557869,
      0.026748506352305412,
      0.02542632445693016,
      0.04458482936024666,
      0.07163079082965851,
      0.021377908065915108,
      -0.05095365270972252,
      0.042746588587760925,
      0.005405974108725786,
      -0.07484994828701019,
      -0.05020379275083542,
      0.06857317686080933,
      -0.07267741113901138,
      -0.03659687936306,
      -0.05600203573703766,
      0.0658203735947609,
      -0.05149437114596367,
      -0.06717541813850403,
      0.001832954352721572,
      0.04623457044363022,
      0.058692023158073425,
      -0.004628861788660288,
      0.07853610068559647,
      0.03632109612226486,
      -0.002029708120971918,
      -0.033565666526556015,
      0.04013270512223244,
      0.06269214302301407,
      -0.020220259204506874,
      0.014280143193900585,
      -0.052007049322128296,
      0.08692551404237747,
      0.018852898851037025,
      0.039235569536685944,
      0.11148402839899063,
      0.04948253929615021,
      0.08434341847896576,
      0.007200296502560377,
      -0.03854319453239441,
      0.10226903110742569,
      -0.0026312347035855055,
      -0.03789439797401428,
      -0.0033472313079982996,
      -0.016063738614320755,
      0.00302001996897161,
      0.024953432381153107,
      -0.013269970193505287,
      -0.040756743401288986,
      0.021805301308631897,
      0.004382189363241196,
      0.030674206092953682,
      0.01551260706037283,
      0.04013505578041077,
      -0.021308258175849915,
      0.051066186279058456,
      0.0484539195895195,
      0.00807005725800991,
      -0.019035566598176956,
      0.013888876885175705,
      -0.0005567966145463288,
      -0.06147346273064613,
      0.016651106998324394,
      -0.07886900007724762,
      0.06174033135175705,
      -0.004518072586506605,
      -0.020980969071388245,
      0.04383707419037819,
      0.01726367138326168,
      -0.0008600475848652422,
      0.03815913200378418,
      0.003028559498488903,
      0.0018521395977586508,
      0.019313296303153038,
      -0.015912121161818504,
      0.040950316935777664,
      0.03322021663188934,
      -0.02927643433213234,
      -0.001927961129695177,
      0.023627854883670807,
      -0.02160872519016266,
      -0.0034742834977805614,
      -0.022526998072862625,
      -0.011890910565853119,
      0.10790276527404785,
      0.04046160355210304,
      0.04491201415657997,
      -0.004553067032247782,
      0.04315652325749397,
      -0.022774135693907738,
      -0.12152468413114548,
      0.03541316092014313,
      -0.07162539660930634,
      -0.008661561645567417,
      -0.06300248205661774,
      -0.02897961065173149,
      0.06371483206748962,
      -0.021285153925418854,
      0.023830978199839592,
      0.04422612488269806,
      0.07812007516622543,
      0.073211669921875,
      0.030269252136349678,
      0.01611417718231678,
      0.09616003930568695,
      -0.03469841554760933,
      0.009794358164072037,
      0.03208111226558685,
      0.014754904434084892,
      0.041362468153238297,
      0.038123417645692825,
      0.04563125595450401,
      0.04284702613949776,
      0.07744352519512177,
      0.05558078736066818,
      -0.0037256197538226843,
      -0.01470180694013834,
      0.04383639618754387,
      -0.018887391313910484,
      -0.003676986787468195,
      0.009281497448682785,
      0.004639111924916506,
      -0.06454496085643768,
      0.018539726734161377,
      0.005519273690879345,
      -0.02039884403347969,
      -0.04360753670334816,
      -0.0367451012134552,
      -0.014002414420247078,
      0.018248727545142174,
      -0.06999502331018448,
      0.024678397923707962,
      0.0004271382640581578,
      -0.018012894317507744,
      -0.027622180059552193,
      -0.0299746822565794,
      -0.020562157034873962,
      -0.01751112937927246,
      -0.005796478595584631,
      0.004011973273009062,
      0.018461044877767563,
      -0.04457499831914902,
      -0.13067620992660522,
      -0.009041465818881989,
      -0.031296078115701675,
      0.04770167917013168,
      -0.06884939223527908,
      0.04264536499977112,
      -0.0011656401911750436,
      -0.08154747635126114,
      -0.02754584699869156,
      0.05722509324550629,
      -0.044379569590091705,
      0.03135193884372711,
      -0.033324893563985825
    ],
    [
      0.060709040611982346,
      -0.024329373612999916,
      0.00031498970929533243,
      0.07961003482341766,
      -0.025270164012908936,
      -0.067600779235363,
      0.014901948161423206,
      -0.027933398261666298,
      -0.044161852449178696,
      0.02652551233768463,
      -0.06226414069533348,
      -0.011857843957841396,
      0.016521548852324486,
      0.03458740934729576,
      0.023955028504133224,
      -0.028506165370345116,
      0.018321501091122627,
      -0.06183556839823723,
      0.0001745613553794101,
      0.007316309493035078,
      0.0798126831650734,
      0.09519634395837784,
      -0.023449771106243134,
      -0.018195349723100662,
      0.010886221192777157,
      -0.09059926122426987,
      0.010897249914705753,
      0.07037756592035294,
      -0.009037124924361706,
      0.014194759540259838,
      0.03805435076355934,
      0.05896693468093872,
      -0.0014621707377955317,
      0.011998141184449196,
      0.009967777878046036,
      -0.03744656965136528,
      -0.04753812775015831,
      -0.0008435772615484893,
      0.026247423142194748,
      -0.018220748752355576,
      -0.03400721773505211,
      0.040582071989774704,
      0.03394237905740738,
      0.008865023963153362,
      0.09131697565317154,
      -0.013339837081730366,
      0.01769009232521057,
      -0.0672295019030571,
      -0.07071854919195175,
      0.041911035776138306,
      -0.00019130550208501518,
      0.0014043666888028383,
      0.11184702068567276,
      0.033775921911001205,
      0.015299176797270775,
      0.013600590638816357,
      4.9716036301106215e-05,
      -0.022840796038508415,
      -0.0911305844783783,
      0.04028826206922531,
      -0.07147952914237976,
      -0.035463351756334305,
      -0.06982070952653885,
      -0.0084022656083107,
      0.01750359870493412,
      -0.013101818971335888,
      0.017954858019948006,
      0.00023320702894125134,
      0.03055073879659176,
      -0.03577157109975815,
      0.05282026529312134,
      0.031876686960458755,
      0.016047868877649307,
      0.1036505475640297,
      -0.006728050298988819,
      0.06832230091094971,
      -0.027467651292681694,
      -0.08641622215509415,
      -0.05492297187447548,
      0.005665237549692392,
      0.02481190860271454,
      0.0012151898117735982,
      -0.04233966022729874,
      0.01787562668323517,
      0.03873293101787567,
      0.028766458854079247,
      -0.07545898109674454,
      0.01837133802473545,
      -0.03661801293492317,
      -0.03877217695116997,
      0.032146092504262924,
      0.0003417020197957754,
      -0.008655096404254436,
      0.0025851272512227297,
      0.08253094553947449,
      -0.06676329672336578,
      -0.025024671107530594,
      -0.01910276897251606,
      -0.06428485363721848,
      -0.012896262109279633,
      -0.020208124071359634,
      -0.0695699006319046,
      0.075643390417099,
      0.04802616313099861,
      -0.12124831974506378,
      0.11063580960035324,
      0.024347038939595222,
      0.04349948838353157,
      0.050469573587179184,
      -0.013018450699746609,
      -0.025614000856876373,
      -0.05090443044900894,
      0.028044404461979866,
      -0.05747123062610626,
      -0.00025764419115148485,
      0.05322786793112755,
      0.003810078138485551,
      0.03510463610291481,
      -0.01304822601377964,
      -0.03061811625957489,
      -0.04717523232102394,
      -0.09908108413219452,
      0.03719028830528259,
      0.03036317601799965,
      -0.05062109977006912,
      0.08375191688537598,
      0.06628266721963882,
      0.07273596525192261,
      0.035396475344896317,
      -0.019970763474702835,
      -0.0334625244140625,
      0.09334614127874374,
      0.049592096358537674,
      -0.02282674051821232,
      0.007931347005069256,
      -0.002640161896124482,
      0.003993279300630093,
      -0.055816978216171265,
      -0.061544694006443024,
      0.02141859009861946,
      0.0774989053606987,
      -0.016846081241965294,
      0.06321939826011658,
      -0.03445038944482803,
      0.03103816509246826,
      -0.038262538611888885,
      0.022889630869030952,
      0.011007796041667461,
      -0.0002288089890498668,
      -0.014742986299097538,
      -0.023772142827510834,
      0.011952764354646206,
      -0.0873800590634346,
      -0.0020826689433306456,
      0.004837298300117254,
      -0.04730888456106186,
      -0.04727336764335632,
      0.07642173767089844,
      0.0003503122425172478,
      0.04111142084002495,
      0.10994160920381546,
      0.0007196610094979405,
      -0.054253023117780685,
      0.026268936693668365,
      -0.049035534262657166,
      -0.022425318136811256,
      -0.05222675949335098,
      -0.0026344286743551493,
      0.007448325864970684,
      -0.06559797376394272,
      0.015246395021677017,
      0.021927956491708755,
      0.010417216457426548,
      0.0586920864880085,
      0.052083585411310196,
      -0.07017114013433456,
      -0.06533671170473099,
      0.0343870148062706,
      -0.0018988625379279256,
      -0.027492409572005272,
      0.044977303594350815,
      0.020793763920664787,
      -0.0010872302809730172,
      -0.11937689036130905,
      -0.05932549387216568,
      -0.038915425539016724,
      0.014659684151411057,
      -0.005303286015987396,
      0.09380823373794556,
      0.041054703295230865,
      -0.021644068881869316,
      -0.0011302366619929671,
      0.05449546501040459,
      0.011129021644592285,
      0.016248684376478195,
      0.030539052560925484,
      -0.02497950755059719,
      -0.05831575766205788,
      0.030775800347328186,
      0.056071799248456955,
      -0.005943698342889547,
      -0.009594351053237915,
      -0.03141685575246811,
      -0.02372162789106369,
      0.02548062801361084,
      0.021863508969545364,
      0.016988638788461685,
      0.055100977420806885,
      0.0399610698223114,
      0.08800168335437775,
      -0.0076339575462043285,
      0.03550188988447189,
      -0.08883866667747498,
      -0.08205839991569519,
      0.005831428337842226,
      -0.07600292563438416,
      -0.0056264749728143215,
      -0.024722056463360786,
      0.001094249775633216,
      -0.045561060309410095,
      0.03503667563199997,
      0.010637388564646244,
      0.05888114497065544,
      -0.022979317232966423,
      -0.012135101482272148,
      0.04789891093969345,
      0.053477901965379715,
      -0.07242944836616516,
      -0.04500279203057289,
      -0.031385552138090134,
      0.046663086861371994,
      -0.0336010679602623,
      -0.051776640117168427,
      -0.042663753032684326,
      -0.0008935881196521223,
      -0.012629765085875988,
      -0.016192039474844933,
      0.10778126120567322,
      -0.007027029525488615,
      -0.14541593194007874,
      -0.004224343225359917,
      0.04305349662899971,
      0.03546162322163582,
      0.03300856426358223,
      0.022610977292060852,
      0.010675562545657158,
      0.056926749646663666,
      -0.08722669631242752,
      0.039000559598207474,
      0.0548105426132679,
      -0.022389911115169525,
      -0.012752183713018894,
      -0.014007817022502422,
      0.04991961643099785,
      0.03468097001314163,
      0.023742754012346268,
      0.02990052103996277,
      0.021807493641972542,
      0.014621159993112087,
      0.058539386838674545,
      -0.04655645787715912,
      0.05419979244470596,
      4.6854736865498126e-05,
      0.0607176348567009,
      -0.044913798570632935,
      0.0035825876984745264,
      -0.010115241631865501,
      -0.03161139413714409,
      -0.028892604634165764,
      -0.05476270243525505,
      0.005443470552563667,
      -0.0985737293958664,
      0.06060624122619629,
      -0.008567874319851398,
      0.010998657904565334,
      0.054130662232637405,
      -0.012563807889819145,
      0.022869249805808067,
      -0.004093331750482321,
      -0.04265238717198372,
      0.054067667573690414,
      -0.004221497569233179,
      0.06655450910329819,
      0.019236769527196884,
      0.00972121674567461,
      -0.07074108719825745,
      0.013247525319457054,
      -0.011978261172771454,
      0.07477019727230072,
      0.03154304623603821,
      -0.04113152250647545,
      0.05599144473671913,
      0.13118073344230652,
      0.03119668737053871,
      0.07844068855047226,
      0.02529653161764145,
      -0.014313099905848503,
      0.0413082130253315,
      0.05800503119826317,
      0.006590608041733503,
      -0.06534189730882645,
      0.0007542965468019247,
      -0.10981538891792297,
      -0.03048742562532425,
      0.05884278565645218,
      -0.09317381680011749,
      -0.039626047015190125,
      0.1502200961112976,
      0.130940780043602,
      -0.02370588108897209,
      0.06190142408013344,
      -0.03858647868037224,
      -0.054640017449855804,
      -0.06925442814826965,
      0.11831776052713394,
      -0.012213299982249737,
      0.032165952026844025,
      0.1216222271323204,
      0.036608755588531494,
      -0.00014264238416217268,
      0.018030866980552673,
      -0.011589651927351952,
      -0.05704900622367859,
      -0.09973721206188202,
      0.045711178332567215,
      -0.009074722416698933,
      -0.06467129290103912,
      0.007516828365623951,
      -0.07960784435272217,
      -0.0452142097055912,
      -0.012778804637491703,
      -0.005426933988928795,
      0.0343778058886528,
      -0.012369439005851746,
      0.011958309449255466,
      -0.04703259468078613,
      0.056412503123283386,
      -0.006853469181805849,
      -0.005198062397539616,
      0.0681959018111229,
      -0.01566920429468155,
      0.05597059801220894,
      -0.05526876449584961,
      0.10307861119508743,
      -0.02128911390900612,
      0.032285574823617935,
      -0.013311797752976418,
      -0.0004458636394701898,
      0.019115734845399857,
      -0.027908727526664734,
      -0.0963871031999588,
      0.005517818033695221,
      0.06155522167682648,
      -0.02404564805328846,
      0.07733555138111115,
      0.0018944080220535398,
      -0.07884491235017776,
      0.08301986753940582,
      0.050409603863954544,
      -0.04628898948431015,
      -0.04311877861618996,
      0.052513428032398224,
      0.04220818355679512,
      -0.021286794915795326,
      0.014830043539404869,
      0.015915874391794205,
      -0.029422936961054802,
      0.03200368583202362,
      -0.09760481119155884,
      0.04921237379312515,
      -0.03440355882048607,
      0.017034262418746948,
      -0.0035038860514760017,
      0.047098852694034576,
      -0.01251745130866766,
      -0.014970866031944752,
      -0.04436376318335533,
      -0.08604520559310913,
      0.0005957232788205147,
      -0.004299739375710487,
      0.023584948852658272,
      0.05929570645093918,
      0.08890000730752945,
      0.11089479923248291,
      -0.010599383153021336,
      -0.0006104967906139791,
      -0.04698975384235382,
      -0.04456762596964836,
      0.015861736610531807,
      0.0043166629038751125,
      -0.008045362308621407,
      0.05958835780620575,
      0.019194409251213074,
      -0.011926071718335152,
      0.04883435368537903,
      -0.005553591065108776,
      0.05450906604528427,
      -0.03340223804116249,
      -0.029416780918836594,
      0.05905580520629883,
      0.01765158586204052,
      -0.05952135846018791,
      -0.049278128892183304,
      0.06031222268939018,
      -0.08312269300222397,
      -0.07235164940357208,
      0.0019241630798205733,
      -0.007441377267241478,
      -0.06348653882741928,
      -0.13332337141036987,
      0.006073404103517532,
      -0.05622418224811554,
      0.011417370289564133,
      0.04402643069624901,
      -0.027760513126850128,
      -0.06878210604190826,
      -0.05507732555270195,
      -0.07794748246669769,
      0.04850449785590172,
      0.024668971076607704,
      -0.006334591656923294,
      -0.06822332739830017,
      0.08586833626031876,
      0.02050887420773506,
      0.06914204359054565,
      -0.02553943730890751,
      -0.0022113521117717028,
      -0.07176882028579712,
      -0.013199662789702415,
      0.05174672231078148,
      -0.015426759608089924,
      -0.037296362221241,
      0.023503707721829414,
      0.023249901831150055,
      0.004849356133490801,
      0.07720594108104706,
      0.039510130882263184,
      -0.06709453463554382,
      0.020504582673311234,
      -0.06176568940281868,
      -0.03406344726681709,
      -0.03541531786322594,
      -0.010279275476932526,
      -0.005596590228378773,
      0.003285550745204091,
      -0.0072670974768698215,
      0.014443612657487392,
      -0.0566101036965847,
      -0.05853670462965965,
      0.020685674622654915,
      -0.024602293968200684,
      -0.026023996993899345,
      -0.04220220074057579,
      0.011127013713121414,
      0.02136758156120777,
      0.045604173094034195,
      -0.029556117951869965,
      0.010079092346131802,
      0.036212384700775146,
      0.028181806206703186,
      0.06384483724832535,
      0.04966813325881958,
      -0.025674907490611076,
      0.0189952552318573,
      -0.013540735468268394,
      -0.04223964735865593,
      -0.024459760636091232,
      -0.011676200665533543,
      -0.040794260799884796,
      -0.020945703610777855,
      -0.0068215979263186455,
      0.07166065275669098,
      -0.04546786844730377,
      -0.06154751405119896,
      -0.11668256670236588,
      -0.02614004537463188,
      -0.030593696981668472,
      0.030574427917599678,
      0.015930205583572388,
      0.04966152831912041,
      0.015589560382068157,
      -0.05959043651819229,
      0.027966300025582314,
      0.026391200721263885,
      -0.02330172434449196,
      -0.07038820534944534,
      0.021174231544137,
      -0.02929897978901863,
      -0.017664847895503044,
      0.04511402174830437,
      -0.08539006114006042,
      -0.013707493431866169,
      -0.026171760633587837,
      0.01700717769563198,
      -0.0600079670548439,
      0.0167276281863451,
      0.0184344369918108,
      -0.04095757380127907,
      0.050165317952632904,
      0.03674886375665665,
      0.01367682684212923,
      -0.03446515277028084,
      0.01829897239804268
    ],
    [
      0.08387555181980133,
      -0.03787112981081009,
      -0.03975886479020119,
      -0.019595760852098465,
      0.039671801030635834,
      -0.008066033944487572,
      -0.015168419107794762,
      0.005187145434319973,
      -0.05528780817985535,
      0.023777704685926437,
      -0.022587856277823448,
      -0.013259503990411758,
      0.005312112160027027,
      0.047347281128168106,
      0.001236399752087891,
      -0.03681561350822449,
      -0.046402446925640106,
      -0.031938500702381134,
      0.026231177151203156,
      0.03051690198481083,
      -0.01635289005935192,
      -0.01809324324131012,
      0.008432851172983646,
      0.05446317791938782,
      -0.04433996230363846,
      0.05913965031504631,
      -0.0001158180893980898,
      0.006025251466780901,
      -0.02167341113090515,
      -0.005207899957895279,
      0.05047685652971268,
      0.058904632925987244,
      -0.05500204116106033,
      0.03606600686907768,
      -0.000639817793853581,
      0.010182198137044907,
      -0.025774916633963585,
      -0.036871351301670074,
      0.04639419540762901,
      -0.0051259249448776245,
      -0.04255219176411629,
      -0.05554352328181267,
      0.06263667345046997,
      -0.0008632231038063765,
      -0.0865376889705658,
      0.014658085070550442,
      -0.03965979069471359,
      -0.013486376032233238,
      0.05414603278040886,
      -0.02512337826192379,
      0.0043190554715693,
      0.02550233155488968,
      0.07470766454935074,
      0.05407758802175522,
      0.08367691189050674,
      -0.06827893853187561,
      0.0528668612241745,
      0.014241782017052174,
      -0.010748025961220264,
      -0.060099925845861435,
      0.0014713691780343652,
      -0.046318382024765015,
      0.07662498205900192,
      0.008047281764447689,
      -0.05705074593424797,
      -0.07348077744245529,
      -0.03243796527385712,
      -0.01868194341659546,
      -0.02185836434364319,
      -0.02306552231311798,
      -0.1032726988196373,
      -0.02980642020702362,
      0.021344950422644615,
      0.012554969638586044,
      0.02397421933710575,
      0.008133649826049805,
      -0.02530105970799923,
      0.052198152989149094,
      0.04653007909655571,
      0.04551894962787628,
      -0.0020542298443615437,
      -0.07980649173259735,
      0.017683614045381546,
      -0.10555939376354218,
      0.009295159950852394,
      -0.043205298483371735,
      -0.032342325896024704,
      0.011963843367993832,
      -0.015109422616660595,
      -0.0054388283751904964,
      -0.02039175108075142,
      0.07148522138595581,
      0.047856949269771576,
      -0.01838759332895279,
      -0.030278079211711884,
      0.00043130898848176,
      0.029831726104021072,
      0.011493037454783916,
      -0.06931617856025696,
      -0.0034673598129302263,
      -0.05159571394324303,
      0.024118697270751,
      0.06878883391618729,
      -0.034487150609493256,
      -0.0714125707745552,
      0.03406414017081261,
      -0.048856642097234726,
      0.024851296097040176,
      -0.011389285326004028,
      -0.06423531472682953,
      0.03142505884170532,
      0.01274751964956522,
      -0.01864864118397236,
      -0.012900223955512047,
      0.07046958804130554,
      -0.05834634602069855,
      -0.02863840013742447,
      -0.004575473256409168,
      0.004428412299603224,
      0.011851471848785877,
      -0.06731420755386353,
      0.010001209564507008,
      -0.0026922309771180153,
      -0.017146850004792213,
      0.0052086059004068375,
      0.06620585918426514,
      0.0563775971531868,
      -0.05318556725978851,
      0.05851278826594353,
      0.02980623207986355,
      0.010166461579501629,
      0.04549085348844528,
      -0.03184771165251732,
      -0.06835193186998367,
      -0.05712287127971649,
      -0.0273133497685194,
      -0.03628663718700409,
      0.005455011036247015,
      -0.07386459410190582,
      0.13356219232082367,
      0.00492746289819479,
      -0.06247575953602791,
      -0.02719178795814514,
      0.050452783703804016,
      -0.02654905430972576,
      -0.02708246000111103,
      0.05611823499202728,
      -0.03517235070466995,
      -0.010882548987865448,
      0.017615465447306633,
      -0.017543265596032143,
      -0.005914897657930851,
      -0.06907275319099426,
      0.017565738409757614,
      -0.0138439005240798,
      0.03481833264231682,
      0.0737452358007431,
      -0.01435142196714878,
      0.06142977625131607,
      -0.003212295938283205,
      0.05341571941971779,
      0.023981193080544472,
      0.027137737721204758,
      -0.019862478598952293,
      0.03309647738933563,
      -0.021716518327593803,
      -0.012629324570298195,
      0.08123533427715302,
      0.040933094918727875,
      -0.00410747854039073,
      0.03142062947154045,
      -0.021494219079613686,
      0.009136805310845375,
      -0.002525451360270381,
      -0.0606050007045269,
      -0.018146315589547157,
      -0.011786913499236107,
      0.034858718514442444,
      0.05634710192680359,
      0.022849496454000473,
      0.005350228399038315,
      0.05406290292739868,
      -0.06695223599672318,
      0.037833068519830704,
      0.0813211128115654,
      0.08900909870862961,
      0.07289687544107437,
      0.024616090580821037,
      0.0013040238991379738,
      -0.02838580496609211,
      0.021251557394862175,
      -0.01038239523768425,
      0.06779227405786514,
      -0.03203561529517174,
      -0.007049534935504198,
      0.007974443025887012,
      -0.046044085174798965,
      0.015539308078587055,
      0.007067650090903044,
      0.0704648494720459,
      0.02740507572889328,
      0.009791468270123005,
      0.06311453133821487,
      -0.11815017461776733,
      -0.06561777740716934,
      0.028638148680329323,
      -0.060077033936977386,
      0.12405980378389359,
      0.014869904145598412,
      -0.013076442293822765,
      0.08595958352088928,
      0.025136297568678856,
      -0.027011441066861153,
      -0.010459673590958118,
      0.07884721457958221,
      -0.04316490516066551,
      0.06296131759881973,
      0.018999027088284492,
      0.0631723403930664,
      -0.0010619377717375755,
      0.03617548197507858,
      -0.018347350880503654,
      0.02386474423110485,
      -0.0009361919364891946,
      -0.06501785665750504,
      -0.0063371676951646805,
      0.019481588155031204,
      0.019233768805861473,
      0.052505698055028915,
      0.024304373189806938,
      0.05594741180539131,
      -0.022196820005774498,
      -0.06948156654834747,
      0.03867371752858162,
      0.023380769416689873,
      0.03856474906206131,
      0.029716426506638527,
      -0.02685299701988697,
      0.030667757615447044,
      -0.07584596425294876,
      -0.01025762502104044,
      -0.009422251023352146,
      0.015068664215505123,
      -0.027705952525138855,
      -0.028513487428426743,
      0.018057750537991524,
      -0.06270550191402435,
      -0.012949898838996887,
      -0.040223248302936554,
      -0.01643482781946659,
      -0.01962296850979328,
      -0.006252556573599577,
      0.028680365532636642,
      0.020706849172711372,
      0.07708141207695007,
      -0.0031425305642187595,
      0.02163594588637352,
      0.014045299962162971,
      -0.037010081112384796,
      -0.021273918449878693,
      -0.05738721042871475,
      0.06888309866189957,
      0.03797449171543121,
      0.002430289052426815,
      0.08425624668598175,
      0.008944764733314514,
      0.004479552619159222,
      -0.06019441410899162,
      -0.009992914274334908,
      0.0922631323337555,
      -0.06015903875231743,
      -0.03627539053559303,
      -0.011504674330353737,
      0.12784789502620697,
      0.009354139678180218,
      0.014665831811726093,
      0.07692277431488037,
      -0.03090745024383068,
      -0.059105463325977325,
      0.046908751130104065,
      -0.01006331667304039,
      -0.0015783571870997548,
      0.02836664393544197,
      0.02514767087996006,
      -0.05929173529148102,
      0.016619369387626648,
      -0.0623428076505661,
      0.045819543302059174,
      0.029789289459586143,
      -0.042239099740982056,
      0.09680048376321793,
      0.06514078378677368,
      0.02394292876124382,
      0.03252166137099266,
      -0.004446723964065313,
      0.00014002462557982653,
      0.06061412766575813,
      -0.07077828049659729,
      0.07234183698892593,
      0.021941322833299637,
      0.02539971098303795,
      0.03541701287031174,
      0.0694136694073677,
      0.042113929986953735,
      0.0039406511932611465,
      0.01718803495168686,
      0.01778753474354744,
      0.10623013228178024,
      0.04693516716361046,
      -0.003037730697542429,
      0.01824653334915638,
      -0.034683264791965485,
      -0.04112379625439644,
      0.019205758348107338,
      -0.020332729443907738,
      -0.06008825823664665,
      0.02684667520225048,
      0.03489948809146881,
      0.07231049984693527,
      0.017693446949124336,
      0.01525348424911499,
      0.006692168768495321,
      -0.0027148821391165257,
      -0.19181858003139496,
      -0.05636322870850563,
      0.0896097719669342,
      -0.031028475612401962,
      -0.039012275636196136,
      0.06215411797165871,
      0.025696970522403717,
      -0.033575639128685,
      0.0923822671175003,
      -0.028998328372836113,
      0.08365755528211594,
      -0.06132405251264572,
      -0.027565734460949898,
      -0.02258320525288582,
      0.04015413671731949,
      0.006219303701072931,
      -0.058079320937395096,
      -0.03396601229906082,
      -0.05673535168170929,
      -0.07331907004117966,
      0.0010327670024707913,
      0.013877985067665577,
      -0.043318748474121094,
      -0.007790025789290667,
      -0.07837371528148651,
      0.031409937888383865,
      -0.026459023356437683,
      0.03910079970955849,
      0.04952897131443024,
      -0.07165146619081497,
      -0.03691140189766884,
      0.006652041804045439,
      -0.024543577805161476,
      -0.021208925172686577,
      -0.019069109112024307,
      -0.033212654292583466,
      -0.0758204460144043,
      0.028488248586654663,
      -0.00414084130898118,
      1.4651094488726812e-06,
      -0.039097465574741364,
      0.03683847188949585,
      0.00040623723180033267,
      0.02512793429195881,
      -0.017774997279047966,
      0.011518377810716629,
      0.03066435270011425,
      0.004488944076001644,
      -0.00011901637481059879,
      0.03706918656826019,
      -0.024773569777607918,
      -0.040963828563690186,
      -0.04291277378797531,
      -0.0324166901409626,
      -0.03744742274284363,
      0.011859995312988758,
      -0.010096140205860138,
      -0.028850799426436424,
      0.03694383427500725,
      0.029434263706207275,
      0.06060430034995079,
      -0.055713627487421036,
      0.048755090683698654,
      -0.08052089810371399,
      -0.005846284795552492,
      0.00481663690879941,
      -0.040530119091272354,
      0.05138387903571129,
      -0.041933950036764145,
      0.046352215111255646,
      -0.0122159318998456,
      -0.020865391939878464,
      0.032434362918138504,
      0.053972724825143814,
      0.06225098296999931,
      0.04673604667186737,
      0.02217554859817028,
      -0.03424423933029175,
      0.00463324086740613,
      0.03464444726705551,
      -0.025842029601335526,
      -0.024728912860155106,
      0.05151217430830002,
      0.01094081997871399,
      -0.0998665988445282,
      0.008187968283891678,
      -0.006876805331557989,
      0.08756085485219955,
      -0.09633088111877441,
      0.03304818645119667,
      -0.02968308888375759,
      -0.01912439428269863,
      0.002420468023046851,
      0.03786297142505646,
      -0.039217788726091385,
      0.0025579079519957304,
      0.0423588864505291,
      0.048105645924806595,
      -0.0014829711290076375,
      0.02613578550517559,
      0.06047310307621956,
      -0.014070304110646248,
      -0.018475688993930817,
      -0.09959065914154053,
      0.038399070501327515,
      0.026006650179624557,
      -0.013091851957142353,
      0.023879682645201683,
      -0.019366228953003883,
      -0.018724238499999046,
      -0.06551806628704071,
      -0.007560336496680975,
      -0.05144813656806946,
      0.06849092990159988,
      -0.0428299680352211,
      -0.019735880196094513,
      -0.05914675444364548,
      -0.013133693486452103,
      -0.03695530816912651,
      0.029570916667580605,
      0.07890315353870392,
      -0.037364210933446884,
      -0.08904761075973511,
      -0.02628466486930847,
      -0.0029494513291865587,
      0.04541100934147835,
      -0.05625405162572861,
      -0.038830555975437164,
      -0.05878858640789986,
      0.016848798841238022,
      0.09174418449401855,
      -0.02977520227432251,
      0.06499243527650833,
      0.0016513352748006582,
      0.09166177362203598,
      -0.06512926518917084,
      0.06719540804624557,
      0.01971532590687275,
      0.07244233787059784,
      0.033375680446624756,
      -0.07446666806936264,
      0.01593817211687565,
      -0.027570953592658043,
      -0.04448969289660454,
      -0.031182488426566124,
      -0.07592830806970596,
      0.06363029032945633,
      -0.002282159635797143,
      0.032687146216630936,
      -0.0481538288295269,
      -0.036560364067554474,
      0.023387450724840164,
      0.003707990748807788,
      0.010125920176506042,
      -0.04076681286096573,
      -0.052616946399211884,
      0.0328713022172451,
      0.03852298483252525,
      0.031019434332847595,
      0.08258801698684692,
      0.0449754036962986,
      0.08607108891010284,
      0.021305309608578682,
      0.06688027083873749,
      -0.0013852015836164355,
      0.022861624136567116,
      0.010396805591881275,
      -0.07771981507539749,
      0.0003836057148873806,
      0.026695873588323593,
      0.062160711735486984,
      -0.06671495735645294,
      -0.044356826692819595,
      -0.016804851591587067,
      -0.03782764449715614,
      -0.022770946845412254,
      -0.04719259589910507,
      -0.012431317940354347,
      -0.015632517635822296,
      0.003698786487802863
    ],
    [
      -0.0004510616126935929,
      -0.025120718404650688,
      -0.0335189513862133,
      0.03711589053273201,
      0.04327932745218277,
      0.046435244381427765,
      0.0038857623003423214,
      0.056474510580301285,
      -0.05151456594467163,
      -0.010572091676294804,
      0.02609005756676197,
      -0.032492175698280334,
      -0.020441902801394463,
      0.11402315646409988,
      -0.015143459662795067,
      -0.04416106641292572,
      -0.034355271607637405,
      0.0487038716673851,
      0.0010609999299049377,
      -0.0350492000579834,
      -0.02066287025809288,
      -0.07000575959682465,
      -0.014795511029660702,
      -0.006402439903467894,
      -0.0027161717880517244,
      -0.02833823300898075,
      0.009421519003808498,
      0.022549910470843315,
      0.024599773809313774,
      -0.034866075962781906,
      -0.013145864009857178,
      -0.018037568777799606,
      0.012007618322968483,
      0.03844568505883217,
      0.07294435799121857,
      0.015443382784724236,
      0.07644291967153549,
      0.026730937883257866,
      0.06443212926387787,
      0.04927680641412735,
      0.059922683984041214,
      0.03022279031574726,
      0.058884818106889725,
      -0.012308312579989433,
      -0.04351440817117691,
      0.06521105766296387,
      0.024865662679076195,
      0.10461042821407318,
      0.028109801933169365,
      -0.05761445686221123,
      0.09570983797311783,
      0.07835765928030014,
      -0.05414716154336929,
      -0.05615741014480591,
      0.036827996373176575,
      0.09725935012102127,
      0.032312218099832535,
      -0.010608673095703125,
      0.05822603777050972,
      -0.050974637269973755,
      -0.05893741548061371,
      -0.08232925087213516,
      0.013763885013759136,
      0.015028816647827625,
      -0.007313295267522335,
      -0.06230368837714195,
      -0.017622128129005432,
      -0.0799127146601677,
      -0.0025559079367667437,
      0.024714943021535873,
      -0.006068682763725519,
      0.0008671880350448191,
      0.04256498068571091,
      0.003479521255940199,
      -0.012035056948661804,
      -0.02557460218667984,
      -0.06837148219347,
      0.03166477382183075,
      0.011236859485507011,
      0.058949217200279236,
      0.07489173859357834,
      0.0413663312792778,
      -0.05542906001210213,
      -0.0074287960305809975,
      -0.042364995926618576,
      -0.09500900655984879,
      -0.04359344393014908,
      -0.016563836485147476,
      -0.06223998963832855,
      -0.0776730477809906,
      0.0495852492749691,
      0.04670295864343643,
      -0.0028812948148697615,
      -0.10194689780473709,
      -0.010552379302680492,
      -0.012757291086018085,
      0.03527623787522316,
      0.04670730605721474,
      0.006377154961228371,
      -0.001045618555508554,
      -0.00524044968187809,
      0.042969685047864914,
      0.040608879178762436,
      -0.011948343366384506,
      6.405104795703664e-05,
      -0.11094838380813599,
      0.011866217479109764,
      -0.04187307879328728,
      -0.003644178155809641,
      0.06157280504703522,
      -0.030952593311667442,
      0.004126780200749636,
      -0.03814764320850372,
      0.03155519813299179,
      0.11363953351974487,
      -0.06509912014007568,
      -0.004371751565486193,
      0.03757358342409134,
      0.0019764055032283068,
      0.045982252806425095,
      0.0005784888635389507,
      0.058171264827251434,
      -0.04693668708205223,
      -0.02237360179424286,
      -0.022696446627378464,
      0.049298245459795,
      -0.05308692529797554,
      -0.10727711021900177,
      0.02353469841182232,
      -0.05268078297376633,
      0.02473687008023262,
      -0.0013660573167726398,
      0.13013909757137299,
      -0.029681431129574776,
      -0.06835398823022842,
      -0.0008456110372208059,
      -0.03373420983552933,
      -0.007619158364832401,
      0.02696922980248928,
      0.06330017745494843,
      -0.026817169040441513,
      0.03147423639893532,
      0.03787209093570709,
      0.014807022176682949,
      -0.03133498877286911,
      0.024129370227456093,
      0.029581865295767784,
      0.0014799699420109391,
      0.01744973100721836,
      -0.029489954933524132,
      -0.05069874972105026,
      0.0727735087275505,
      -0.026836911216378212,
      -0.08106375485658646,
      0.02650069072842598,
      -0.03388618305325508,
      -0.0202188678085804,
      -0.060122229158878326,
      0.019147560000419617,
      -0.04577247053384781,
      0.0013118833303451538,
      0.051501307636499405,
      -0.014559387229382992,
      0.07128003239631653,
      -0.05000271275639534,
      0.029612816870212555,
      0.04006984829902649,
      -0.038816869258880615,
      -0.01682044193148613,
      -0.032207805663347244,
      0.026609133929014206,
      0.021256407722830772,
      -0.024404384195804596,
      -0.00506412610411644,
      -0.039077840745449066,
      0.009545342065393925,
      -0.006215218920260668,
      0.049022115767002106,
      0.023952066898345947,
      -0.002902864245697856,
      -0.055095091462135315,
      0.0471641980111599,
      0.036315687000751495,
      -0.12833760678768158,
      0.043787725269794464,
      -0.021995626389980316,
      0.016184289008378983,
      -0.03656027466058731,
      0.012338673695921898,
      0.022620752453804016,
      -0.006854476872831583,
      -0.008713092654943466,
      0.09577212482690811,
      -0.044565074145793915,
      0.031098809093236923,
      -0.08703655004501343,
      0.03792048245668411,
      0.01388004794716835,
      0.0033251249697059393,
      -0.0027460469864308834,
      0.001109197735786438,
      -0.08094175159931183,
      -0.07228405773639679,
      -0.028178876265883446,
      0.008576422929763794,
      -0.04522358626127243,
      -0.0160635095089674,
      -0.029862049967050552,
      0.006543880328536034,
      0.009221673011779785,
      0.03331656754016876,
      -0.08469301462173462,
      0.049757227301597595,
      0.018354155123233795,
      -0.04767676442861557,
      -0.06234854832291603,
      0.10321500897407532,
      -0.06661072373390198,
      0.04945456236600876,
      0.013609686866402626,
      -0.016655728220939636,
      0.03482044115662575,
      -0.05212875083088875,
      -0.06528383493423462,
      0.045148108154535294,
      -0.05319288372993469,
      0.0682387724518776,
      -0.03032846562564373,
      -0.019292114302515984,
      -0.0453534871339798,
      0.04482853412628174,
      -0.060737501829862595,
      -0.040591854602098465,
      0.029476260766386986,
      -0.03341710567474365,
      -0.08374369144439697,
      -0.0866541713476181,
      -0.06765136122703552,
      0.05613770708441734,
      -0.024637604132294655,
      0.044174887239933014,
      0.02825913392007351,
      0.02083829790353775,
      0.014696112833917141,
      0.04309382662177086,
      0.0013662747805938125,
      -0.01914040930569172,
      -0.016735976561903954,
      -0.0825616717338562,
      0.019428299739956856,
      0.0036193830892443657,
      0.014588444493710995,
      -0.008281651884317398,
      0.035735681653022766,
      0.00276327901519835,
      0.037990279495716095,
      -0.023574508726596832,
      -0.032039668411016464,
      -0.034793999046087265,
      0.014731003902852535,
      0.011736348271369934,
      0.019638795405626297,
      -0.031684644520282745,
      0.015965858474373817,
      0.08176945149898529,
      -0.03380227088928223,
      0.029552489519119263,
      0.03795577213168144,
      0.03184693306684494,
      -0.12270943075418472,
      -0.005544556770473719,
      -0.029503829777240753,
      0.0038533874321728945,
      -0.0536792054772377,
      -0.006035806145519018,
      -0.020429041236639023,
      0.004132337402552366,
      0.03380128741264343,
      0.05137060210108757,
      -0.01813986897468567,
      -0.02619791589677334,
      -0.0026268826331943274,
      -0.09450685977935791,
      0.04677032679319382,
      -0.003495726967230439,
      -0.023906869813799858,
      0.021283868700265884,
      0.0846085175871849,
      0.003078673966228962,
      0.009338253177702427,
      0.025650881230831146,
      0.06890513747930527,
      -0.016260750591754913,
      0.11814451217651367,
      0.03217068687081337,
      0.003007447812706232,
      0.0480746328830719,
      0.01036726962774992,
      -0.018338697031140327,
      0.03279784694314003,
      -0.012721745297312737,
      -0.04105895757675171,
      0.011378477327525616,
      -0.03260013088583946,
      -0.03401263430714607,
      -0.01644180715084076,
      0.019626468420028687,
      -0.0017380558419972658,
      0.015649013221263885,
      0.027940237894654274,
      0.049962710589170456,
      -0.012978998012840748,
      0.034637074917554855,
      -0.08579398691654205,
      -0.058288220316171646,
      -0.01915006712079048,
      -0.011817692779004574,
      0.017793742939829826,
      0.08329017460346222,
      -0.022744739428162575,
      0.09484230726957321,
      0.05737569183111191,
      0.006945365108549595,
      0.009509434923529625,
      0.004285523667931557,
      0.02208002842962742,
      0.08257070928812027,
      -0.0030579857993870974,
      -0.03669990226626396,
      0.01622501015663147,
      0.047573477029800415,
      0.01810615323483944,
      0.0014626025222241879,
      0.0669746845960617,
      0.02450435981154442,
      -0.03427355736494064,
      -0.019938874989748,
      -0.0357116237282753,
      0.015850510448217392,
      0.060007281601428986,
      0.037633419036865234,
      -0.03542965278029442,
      -0.0353519581258297,
      -0.03379237279295921,
      -0.08921177685260773,
      -0.05191890522837639,
      -0.04956662654876709,
      -0.045114703476428986,
      -0.0931520164012909,
      0.004468168597668409,
      0.007010953966528177,
      -0.05186747387051582,
      0.07062410563230515,
      0.008773225359618664,
      0.01547075342386961,
      -0.010314500890672207,
      -0.05249762535095215,
      -0.011555388569831848,
      0.0024146409705281258,
      0.07945697754621506,
      -0.012998825870454311,
      0.054559364914894104,
      -0.030414894223213196,
      -0.04807956516742706,
      0.024736471474170685,
      -0.06901721656322479,
      -0.06513352692127228,
      -0.028469234704971313,
      -0.11593031883239746,
      0.02373187243938446,
      -0.01395217701792717,
      0.06535233557224274,
      -0.08157217502593994,
      0.05256488174200058,
      0.028757503256201744,
      0.019328562542796135,
      0.04971126466989517,
      -0.0415242575109005,
      -0.023736368864774704,
      -0.10535425692796707,
      -0.030685732141137123,
      -0.05458861589431763,
      0.025139661505818367,
      0.06518811732530594,
      -0.021623030304908752,
      0.00185316801071167,
      -0.02035115472972393,
      0.04613134264945984,
      0.07852581888437271,
      0.04630352929234505,
      -0.0043907067738473415,
      0.052126444876194,
      -0.036066021770238876,
      -0.026735907420516014,
      -0.011372573673725128,
      0.005049541592597961,
      0.006732325069606304,
      0.011486886069178581,
      0.07538510859012604,
      0.027916021645069122,
      0.018470603972673416,
      -0.015415191650390625,
      0.0580720454454422,
      -0.0065359314903616905,
      -0.04502817988395691,
      -0.026562189683318138,
      0.08863385021686554,
      0.0830070972442627,
      0.012839758768677711,
      -0.03201431408524513,
      0.013589548878371716,
      0.04520919919013977,
      0.0008811707375571132,
      0.01702970080077648,
      -0.0007461700006388128,
      0.01656287908554077,
      0.02798595279455185,
      -0.030301939696073532,
      -0.08749043941497803,
      -0.030407030135393143,
      0.03062477707862854,
      -0.048389144241809845,
      -0.023917976766824722,
      0.026317115873098373,
      -0.08304478228092194,
      0.00518961064517498,
      -0.014673375524580479,
      -0.024672089144587517,
      0.006478520110249519,
      0.0017147894250229,
      -0.006201234180480242,
      -0.011282622814178467,
      -0.059402260929346085,
      -0.046353746205568314,
      -0.05939517542719841,
      0.04010072723031044,
      0.06703633815050125,
      0.05905745178461075,
      -0.060091499239206314,
      0.015904631465673447,
      0.03238220140337944,
      -0.009683934971690178,
      -0.023210380226373672,
      0.04183609411120415,
      -0.000876125821378082,
      0.053240228444337845,
      -0.016585204750299454,
      -0.016306541860103607,
      -0.0454343818128109,
      0.0037768715992569923,
      0.018018679693341255,
      -0.061021238565444946,
      0.05429542064666748,
      -0.04363291710615158,
      0.04492464289069176,
      0.0055173346772789955,
      -0.0078063164837658405,
      -0.032661322504282,
      -0.02912282757461071,
      -0.0720600113272667,
      0.11872655898332596,
      0.07223353534936905,
      0.05342375114560127,
      -0.011250349693000317,
      -0.023950248956680298,
      0.08528643101453781,
      0.0032800512854009867,
      -0.029575709253549576,
      -0.021752702072262764,
      0.04791363701224327,
      0.03163431957364082,
      0.05835995823144913,
      0.005934266373515129,
      -0.019587162882089615,
      -0.04250926524400711,
      0.010516871698200703,
      -0.022794123739004135,
      -0.004314364865422249,
      0.03818866237998009,
      0.0073949797078967094,
      0.07180541753768921,
      0.037254903465509415,
      0.004861848894506693,
      0.009940559044480324,
      -0.018894506618380547,
      -0.07502622902393341,
      -0.026967348530888557,
      -0.03237666189670563,
      0.041653987020254135,
      -0.04393434152007103,
      -0.04426245391368866,
      -0.1351955682039261,
      -0.0056452699936926365,
      0.0488443486392498,
      -0.0034187855198979378,
      -0.06799286603927612,
      0.03891243785619736,
      0.07700159400701523,
      0.02589174546301365,
      -0.01155759021639824,
      -0.0012283760588616133,
      -0.11831032484769821,
      0.10416840016841888
    ],
    [
      -0.058327142149209976,
      -0.01570035144686699,
      -0.09781452268362045,
      0.052020393311977386,
      0.010651455260813236,
      -0.11819107830524445,
      -0.053747717291116714,
      0.0015487257624045014,
      -0.029914535582065582,
      -0.019483136013150215,
      -0.012776967138051987,
      -0.05853913351893425,
      -0.02017337456345558,
      -0.07430336624383926,
      0.0233708955347538,
      -0.03811335936188698,
      -0.03469341993331909,
      -0.05467522516846657,
      -0.05264171585440636,
      -0.04824744537472725,
      0.06478693336248398,
      -0.009800193831324577,
      0.0976216122508049,
      -0.04916084185242653,
      0.06242774799466133,
      0.028121015056967735,
      -0.010027796030044556,
      0.08153355866670609,
      -0.020678043365478516,
      -0.04162951931357384,
      -0.022269481793045998,
      -0.008406383916735649,
      -0.005982175003737211,
      0.06348881125450134,
      0.0027771545574069023,
      -0.03792499750852585,
      -0.0757007896900177,
      0.09515678882598877,
      -0.028447354212403297,
      0.04684634879231453,
      0.004698298405855894,
      -0.032605256885290146,
      0.03247770667076111,
      0.030272996053099632,
      -0.0053414166904985905,
      0.007627472747117281,
      0.07162424921989441,
      0.027634821832180023,
      0.0003549471730366349,
      0.028625216335058212,
      0.001634786487556994,
      0.07370704412460327,
      0.03513908013701439,
      -0.05019005388021469,
      0.05522056668996811,
      -0.07506813853979111,
      -0.004687630571424961,
      0.010843932628631592,
      -0.012706808745861053,
      -0.0001342286850558594,
      -0.09949740022420883,
      -0.008698821999132633,
      0.02506294660270214,
      0.03801652044057846,
      0.005004309117794037,
      -0.091572605073452,
      0.06039119139313698,
      0.054382044821977615,
      -0.10564401000738144,
      0.016698937863111496,
      0.026996219530701637,
      0.031049873679876328,
      0.014098292216658592,
      -0.06103852018713951,
      -0.009498486295342445,
      0.08719119429588318,
      0.040720853954553604,
      0.004645772278308868,
      -0.0038760320749133825,
      -0.04214596375823021,
      -0.007323163095861673,
      0.007254868745803833,
      -0.025122258812189102,
      -0.016274217516183853,
      0.037736959755420685,
      -0.001807111781090498,
      -0.02851809747517109,
      0.021810369566082954,
      0.06638563424348831,
      0.005677430424839258,
      -0.034650128334760666,
      0.019897310063242912,
      0.022944502532482147,
      0.023791059851646423,
      0.027406448498368263,
      0.0009177118190564215,
      0.059999335557222366,
      0.07424462586641312,
      -0.042775947600603104,
      0.011072684079408646,
      -0.09075309336185455,
      -0.017452655360102654,
      0.08009044826030731,
      -0.036675211042165756,
      -0.032799120992422104,
      -0.013387150131165981,
      -0.014184162020683289,
      0.03811239078640938,
      -0.08596624433994293,
      -0.021430354565382004,
      -0.05899495631456375,
      -0.026888582855463028,
      0.028373559936881065,
      -0.008383894339203835,
      0.023315515369176865,
      0.06873608380556107,
      0.02571023441851139,
      0.006377511657774448,
      -0.0730854719877243,
      0.026508543640375137,
      -0.04923979938030243,
      0.04921674355864525,
      -0.05209270119667053,
      0.006229911465197802,
      0.08994032442569733,
      0.09889930486679077,
      0.03395247459411621,
      0.05606022849678993,
      0.03954550251364708,
      -0.024211591109633446,
      0.009375297464430332,
      0.0007823635824024677,
      -0.04144097492098808,
      -0.05101494491100311,
      -0.07281898707151413,
      0.05005761981010437,
      -0.010995645076036453,
      -0.07079820334911346,
      -0.05389537662267685,
      0.045973699539899826,
      0.05217502638697624,
      0.0469677709043026,
      -0.014508522115647793,
      -0.03460444509983063,
      0.02915702573955059,
      0.018400538712739944,
      -0.044423073530197144,
      -0.07145579904317856,
      -0.010247006081044674,
      -0.0058222804218530655,
      -0.0001561613753437996,
      -0.05306898057460785,
      -0.050525881350040436,
      0.003402284812182188,
      -0.10703497380018234,
      -0.009117749519646168,
      -0.006582581903785467,
      -0.005530522670596838,
      -0.017692195251584053,
      -0.053347453474998474,
      0.038232382386922836,
      -0.06351448595523834,
      -0.024808745831251144,
      0.10539335012435913,
      -0.01879744417965412,
      -0.05446586385369301,
      -0.03802615776658058,
      -0.07881724089384079,
      -0.0005970445345155895,
      0.0023771647829562426,
      0.05933509021997452,
      0.02375883422791958,
      -0.03339684382081032,
      -0.012017128057777882,
      -0.04502158612012863,
      0.04810686782002449,
      -0.08790957927703857,
      0.02485847659409046,
      -0.009162870235741138,
      -0.03516196087002754,
      0.01152911875396967,
      0.03235265985131264,
      -0.059465788304805756,
      -0.0451367050409317,
      -0.02468966133892536,
      0.04861968010663986,
      0.026319297030568123,
      0.025795696303248405,
      -0.01966184750199318,
      0.13494351506233215,
      0.0045738485641777515,
      -0.02794461138546467,
      0.03254884108901024,
      -0.08326894044876099,
      -0.006142270285636187,
      -0.016915449872612953,
      -0.029245518147945404,
      -0.042495787143707275,
      -0.055607348680496216,
      0.04656412824988365,
      -0.018772419542074203,
      0.07099343836307526,
      -0.02734791859984398,
      -0.02466277964413166,
      -0.05423567444086075,
      0.0021361897233873606,
      -0.0522649884223938,
      -0.050192221999168396,
      -0.04454093426465988,
      0.09522159397602081,
      0.036891140043735504,
      -0.07742293179035187,
      -0.017541199922561646,
      0.13346423208713531,
      -0.0448995977640152,
      0.06771983206272125,
      0.026863938197493553,
      -0.07240761071443558,
      -0.021924767643213272,
      -0.0031287861056625843,
      -0.05272717773914337,
      -0.061533041298389435,
      -0.051122136414051056,
      -0.01670987531542778,
      -0.03798665851354599,
      0.0006760862888768315,
      -0.09886080771684647,
      0.038257990032434464,
      -0.057291626930236816,
      0.09888537973165512,
      0.061443958431482315,
      0.007562186568975449,
      -0.0901678204536438,
      -0.07471627742052078,
      0.0034725721925497055,
      0.13058365881443024,
      -0.08783408254384995,
      0.00424318527802825,
      -0.0010143654653802514,
      -0.049066632986068726,
      0.03732303902506828,
      -0.06265264004468918,
      0.04350673779845238,
      0.04065749794244766,
      0.03618690371513367,
      -0.046049993485212326,
      -0.04912056773900986,
      -0.04255283996462822,
      -0.01040418166667223,
      0.03602730482816696,
      -0.04492839425802231,
      0.011391536332666874,
      -0.04941153898835182,
      -0.010998889803886414,
      -0.02128484845161438,
      -0.009500364772975445,
      0.0056643495336174965,
      -0.11966056376695633,
      -0.002012719865888357,
      -0.02697734907269478,
      -0.05249854177236557,
      -0.04725946485996246,
      -0.046060193330049515,
      -0.015517794527113438,
      -0.014757279306650162,
      0.02871534787118435,
      -0.04927390441298485,
      0.016105208545923233,
      -0.06804188340902328,
      0.036406636238098145,
      -0.006055378820747137,
      -0.0006308180163614452,
      0.03479354456067085,
      0.019414247944951057,
      0.012306137010455132,
      0.04865298420190811,
      0.03208310529589653,
      -0.09036760777235031,
      0.043303605169057846,
      -0.05033314600586891,
      -0.040255866944789886,
      -0.06908325105905533,
      0.00646024476736784,
      -0.10799624770879745,
      -0.08657406270503998,
      0.03051377646625042,
      -0.024014728143811226,
      -0.12007833272218704,
      0.06815173476934433,
      -0.05001324042677879,
      0.041735321283340454,
      -0.05809314548969269,
      0.041822049766778946,
      -0.019980356097221375,
      -0.0009176764287985861,
      -0.0006775565561838448,
      -0.01693025790154934,
      0.04916507005691528,
      -0.07325808703899384,
      0.029364056885242462,
      0.01225472055375576,
      -0.014044033363461494,
      -0.026112817227840424,
      -0.02318471297621727,
      0.0378350131213665,
      0.014830363914370537,
      -0.04231153801083565,
      -0.02770582027733326,
      0.04697107523679733,
      0.03525424748659134,
      -0.021437665447592735,
      0.021427206695079803,
      -0.060873597860336304,
      -0.028075547888875008,
      0.10119103640317917,
      -0.026897402480244637,
      -0.0714554563164711,
      0.02029281109571457,
      0.010739820078015327,
      0.02594970539212227,
      0.018861843273043633,
      -0.03544130176305771,
      0.04459593445062637,
      -0.12233255803585052,
      -0.03370523452758789,
      -0.018177509307861328,
      0.026978636160492897,
      -0.052101731300354004,
      -0.044669680297374725,
      -0.05314522609114647,
      0.036649130284786224,
      0.0030014654621481895,
      0.040509775280952454,
      -0.027192361652851105,
      0.049741923809051514,
      0.09595629572868347,
      0.06754206866025925,
      -0.013789406046271324,
      0.0457887277007103,
      -0.0015398133546113968,
      0.0064049395732581615,
      0.04036996513605118,
      -0.06435977667570114,
      0.03071068972349167,
      0.016070595011115074,
      0.0903981477022171,
      0.007892780937254429,
      -0.00552373519167304,
      0.022746391594409943,
      -0.10749378800392151,
      -0.038108620792627335,
      0.03572487831115723,
      -0.021449491381645203,
      0.04348986595869064,
      0.04483666643500328,
      0.048062313348054886,
      0.06944520026445389,
      0.014521307311952114,
      -0.00748980650678277,
      0.030829453840851784,
      -0.030132528394460678,
      -0.05860714241862297,
      0.01457907259464264,
      0.0704437717795372,
      0.039364490658044815,
      -0.009016522206366062,
      -0.06341677159070969,
      -0.05711103230714798,
      0.01816091500222683,
      -0.006203393451869488,
      -0.055244699120521545,
      -0.006777475122362375,
      0.014874969609081745,
      -0.07897231727838516,
      0.005271085537970066,
      0.008677598088979721,
      -0.12796135246753693,
      -0.08414185047149658,
      0.03739277645945549,
      -0.057114843279123306,
      -0.010177608579397202,
      0.03879712522029877,
      -0.023611916229128838,
      0.11757665872573853,
      0.055750247091054916,
      -0.027584001421928406,
      -0.024351077154278755,
      -0.0387246310710907,
      0.08138860762119293,
      0.12169458717107773,
      -0.014851941727101803,
      -0.030990587547421455,
      0.059312690049409866,
      0.12584508955478668,
      -0.022031262516975403,
      -0.03905358910560608,
      0.02792825549840927,
      -0.007964439690113068,
      0.049621570855379105,
      0.018079379573464394,
      0.03527642413973808,
      0.06480100750923157,
      -0.04585615172982216,
      0.0014178953133523464,
      -0.006300034932792187,
      0.018047770485281944,
      0.002754268702119589,
      0.011313535273075104,
      -0.022604670375585556,
      -0.008816150948405266,
      -0.06646903604269028,
      -0.04845817759633064,
      -0.03145690634846687,
      -0.010721721686422825,
      -0.027415301650762558,
      -0.06084258109331131,
      -0.08948618918657303,
      0.004271768964827061,
      0.05470775440335274,
      -0.0064098890870809555,
      0.02978583052754402,
      0.015352845191955566,
      -0.05994831398129463,
      -0.0008948123431764543,
      0.08257025480270386,
      0.006979655008763075,
      -0.054935961961746216,
      0.01952509954571724,
      0.09164365381002426,
      0.051586832851171494,
      0.044668491929769516,
      -0.07868890464305878,
      -0.023427896201610565,
      -0.0028712355997413397,
      -0.018528414890170097,
      0.038732580840587616,
      0.04759356379508972,
      -0.02115773595869541,
      0.04836191236972809,
      -0.014529295265674591,
      -0.00017946973093785346,
      0.01157478615641594,
      -0.014791960828006268,
      -0.023898523300886154,
      -0.008329431526362896,
      -0.03792978823184967,
      0.035896722227334976,
      0.057452645152807236,
      0.0264260396361351,
      -0.04057876393198967,
      -0.062307290732860565,
      -0.09700426459312439,
      0.0008228547521866858,
      -0.016407663002610207,
      -0.005108044948428869,
      0.002765866694971919,
      -0.05639197677373886,
      -0.022327551618218422,
      -0.003173928242176771,
      0.04825470969080925,
      -0.007353266701102257,
      -0.012307889759540558,
      -0.05508123338222504,
      0.05681605264544487,
      -0.11639972776174545,
      -0.06234640255570412,
      -0.03470047190785408,
      -0.01250914204865694,
      0.013582689687609673,
      -0.0832306295633316,
      0.005323510617017746,
      0.036417651921510696,
      0.05548554286360741,
      -0.05766478553414345,
      -0.03111696057021618,
      -0.013274431228637695,
      0.050434719771146774,
      0.005528527777642012,
      -0.07242442667484283,
      -0.03707204386591911,
      -0.010144688189029694,
      0.03491710498929024,
      -0.031012779101729393,
      0.12634500861167908,
      -0.05021017789840698,
      -0.00022565088875126094,
      -0.020945683121681213,
      -0.048152703791856766,
      -0.026286669075489044,
      -0.04905662313103676,
      0.005524056032299995,
      0.053846538066864014,
      -0.01561792939901352,
      -0.04936731979250908,
      -0.04196421802043915,
      -0.0023468141444027424,
      0.05299074947834015,
      -0.06940186768770218,
      -0.02975332736968994,
      0.03646270930767059,
      -0.01606161519885063,
      -0.0037454210687428713,
      0.11653290688991547
    ],
    [
      0.02010137215256691,
      0.05318363755941391,
      0.042874015867710114,
      0.005380613729357719,
      0.019945243373513222,
      -0.078631192445755,
      0.06153446435928345,
      -0.021288936957716942,
      -0.06863322108983994,
      0.0406784825026989,
      0.008549551479518414,
      0.0020251269452273846,
      -0.0022870670072734356,
      -0.07860303670167923,
      -0.01505702082067728,
      0.018626555800437927,
      0.04581763222813606,
      0.10028602927923203,
      0.013912778347730637,
      -0.011507988907396793,
      0.05848711356520653,
      0.008975071832537651,
      0.03374430537223816,
      0.054826557636260986,
      -0.041805315762758255,
      0.044463157653808594,
      -0.05645592883229256,
      -0.121373750269413,
      -0.016342833638191223,
      -0.007701138034462929,
      0.004491772036999464,
      -0.07467010617256165,
      0.04636719077825546,
      0.048237089067697525,
      -0.040175069123506546,
      -0.004651645664125681,
      -0.041089724749326706,
      0.020674247294664383,
      0.05277512967586517,
      -0.05310280621051788,
      0.024560842663049698,
      0.02455887198448181,
      0.0029224164318293333,
      0.019108295440673828,
      -0.022720616310834885,
      0.021366536617279053,
      -0.007730446755886078,
      0.03195229172706604,
      -0.02443019300699234,
      0.009199176914989948,
      0.06869709491729736,
      0.047950487583875656,
      -0.014218074269592762,
      0.06509687751531601,
      -0.001820030272938311,
      -0.009000333957374096,
      0.048933785408735275,
      -0.06650061905384064,
      0.07662709802389145,
      -0.0029479446820914745,
      0.010795677080750465,
      -0.04401376470923424,
      0.01405313704162836,
      -0.005171025171875954,
      0.01582084223628044,
      -0.0966566726565361,
      -0.02587837725877762,
      0.04035349562764168,
      -0.00431068567559123,
      0.008688651025295258,
      0.014753463678061962,
      0.05167478322982788,
      0.023960499092936516,
      -0.009701725095510483,
      0.03324706479907036,
      0.046646345406770706,
      -0.03520422428846359,
      0.0641053169965744,
      0.01541278325021267,
      -0.030053846538066864,
      0.003414054634049535,
      0.02136896923184395,
      0.0180228091776371,
      0.011568069458007812,
      0.0069527579471468925,
      -0.04050059616565704,
      0.027420109137892723,
      0.07066832482814789,
      0.018287809565663338,
      0.1533489227294922,
      -0.007323216646909714,
      -0.06803194433450699,
      -0.0233068335801363,
      -0.0764584168791771,
      -0.02867782860994339,
      0.01935291476547718,
      0.019399890676140785,
      0.006108987145125866,
      0.039119504392147064,
      0.02167016640305519,
      0.04046062380075455,
      -0.016150537878274918,
      0.07021569460630417,
      -0.05590011179447174,
      -0.05367966741323471,
      0.04605003818869591,
      -0.006773420609533787,
      0.05909958481788635,
      0.04910380765795708,
      -0.02358255721628666,
      0.06677030026912689,
      0.013338511809706688,
      -0.018686462193727493,
      0.01741287112236023,
      0.01019442081451416,
      -0.005235778167843819,
      -0.026390928775072098,
      0.007159485016018152,
      -0.013385246507823467,
      0.0312700979411602,
      0.04650776833295822,
      0.015211757272481918,
      0.025445392355322838,
      -0.0034997453913092613,
      0.033355601131916046,
      0.03094719909131527,
      -0.021302392706274986,
      -0.020050618797540665,
      0.03973016515374184,
      0.053068142384290695,
      0.01499845553189516,
      -0.07674744725227356,
      -0.00011995861132163554,
      0.07709106057882309,
      -0.04497043415904045,
      -0.03189700096845627,
      -0.09396523982286453,
      0.0008933115750551224,
      -0.0051378654316067696,
      0.05580936744809151,
      -0.12497586756944656,
      0.09847201406955719,
      0.00967335980385542,
      0.02307165414094925,
      0.043725546449422836,
      0.043537404388189316,
      -0.01667322777211666,
      0.00545830512419343,
      0.06936449557542801,
      0.051549602299928665,
      -0.002930707298219204,
      0.03359651193022728,
      -0.04374578595161438,
      -0.061707716435194016,
      0.0227463748306036,
      0.06076874956488609,
      -0.12599100172519684,
      -0.047555822879076004,
      -0.13053929805755615,
      -0.022136328741908073,
      -0.05909748747944832,
      0.002843092894181609,
      0.036120444536209106,
      0.045906912535429,
      0.025541607290506363,
      -0.023154087364673615,
      0.036654163151979446,
      -0.01014272216707468,
      0.04665440693497658,
      -0.013524127192795277,
      0.056607071310281754,
      0.05858328938484192,
      -0.04845990985631943,
      0.02054198831319809,
      -0.00013736108667217195,
      -0.029018092900514603,
      -0.08247699588537216,
      -0.0012521157041192055,
      -0.019716722890734673,
      -0.019060352817177773,
      0.07995100319385529,
      -0.005136952735483646,
      0.05692862719297409,
      -0.02588587999343872,
      -0.007772240322083235,
      -0.03850332275032997,
      0.05571121722459793,
      0.008664457127451897,
      -0.010407225228846073,
      0.05497244372963905,
      0.048820316791534424,
      0.013491745106875896,
      -0.015862995758652687,
      0.036445312201976776,
      0.11564523726701736,
      0.059450943022966385,
      0.06820868700742722,
      0.021168548613786697,
      0.013028851710259914,
      0.013813307508826256,
      0.0069738454185426235,
      -0.05510415881872177,
      -0.04555291309952736,
      0.028539609163999557,
      -0.04034809023141861,
      -0.06011691316962242,
      0.010603702627122402,
      -0.07526213675737381,
      -0.02478879690170288,
      0.015228894539177418,
      0.044715363532304764,
      -0.030203750357031822,
      0.02218354120850563,
      0.038273002952337265,
      -0.016517914831638336,
      0.047960810363292694,
      0.06805726140737534,
      -0.03013424575328827,
      0.0174703486263752,
      -0.03330773115158081,
      0.06493689864873886,
      0.06471242755651474,
      -0.019013062119483948,
      0.023098748177289963,
      0.06390928477048874,
      0.01795654371380806,
      -0.03729637712240219,
      -0.013132882304489613,
      0.002043945947661996,
      -0.016948988661170006,
      0.07604917883872986,
      0.09861554950475693,
      -0.0417347326874733,
      -0.07072390615940094,
      0.049428053200244904,
      0.0200724545866251,
      0.01440693624317646,
      -0.04450704902410507,
      0.0077393027022480965,
      -0.09896639734506607,
      0.04429324343800545,
      -0.02731946110725403,
      0.011320884339511395,
      -0.06649868190288544,
      -0.019065923988819122,
      -0.09131065756082535,
      0.0728798359632492,
      0.045042820274829865,
      0.11401745676994324,
      -0.010306358337402344,
      -0.05909295752644539,
      0.060437701642513275,
      -0.044652171432971954,
      -0.008372022770345211,
      0.03937786445021629,
      -0.052992530167102814,
      -0.003986355382949114,
      0.07155100256204605,
      -0.033367566764354706,
      -0.0704624131321907,
      -0.03228367492556572,
      -0.06582017242908478,
      0.011737847700715065,
      -0.03206811845302582,
      0.07241787016391754,
      0.025274716317653656,
      -0.012448377907276154,
      0.027828730642795563,
      0.055872395634651184,
      -0.03645796328783035,
      -0.012428062036633492,
      0.047141093760728836,
      -0.04304454103112221,
      0.00012346875155344605,
      -0.04042137786746025,
      -0.014438964426517487,
      0.002297056373208761,
      0.08823015540838242,
      -0.08690071105957031,
      -0.01838671788573265,
      -0.02825506404042244,
      0.08376976102590561,
      -0.007895668968558311,
      -0.003890498774126172,
      -0.007350882515311241,
      0.05987014248967171,
      0.059335723519325256,
      0.04340500012040138,
      0.04697904735803604,
      0.001373080420307815,
      0.05953401327133179,
      -0.022475020959973335,
      0.020369652658700943,
      0.025761211290955544,
      -0.01732539013028145,
      0.04127640277147293,
      -0.05664283037185669,
      -0.04717763513326645,
      0.04050157591700554,
      0.019740281626582146,
      -0.04053739830851555,
      0.017363622784614563,
      -0.02948642335832119,
      -0.008180872537195683,
      -0.002251347526907921,
      -0.0439969040453434,
      0.021981315687298775,
      0.0032299510203301907,
      -0.020540611818432808,
      0.0271418709307909,
      0.02153225988149643,
      0.0050038923509418964,
      -0.02370302565395832,
      -0.031414780765771866,
      0.04062236472964287,
      0.047872286289930344,
      -0.06793661415576935,
      0.014496752992272377,
      0.056481555104255676,
      0.045178432017564774,
      -0.03354198858141899,
      -0.05181819200515747,
      0.07790762186050415,
      -0.001559677766636014,
      0.017648881301283836,
      0.008290874771773815,
      0.015611641108989716,
      0.030046841129660606,
      -0.09223141521215439,
      -0.00495642377063632,
      -0.05822756513953209,
      0.0775337740778923,
      -0.02713341824710369,
      0.10258350521326065,
      -0.05167025327682495,
      -0.005684932228177786,
      -0.08366598188877106,
      0.030767813324928284,
      -0.032085925340652466,
      0.09055639058351517,
      0.02129507064819336,
      -0.061277229338884354,
      0.0014583972515538335,
      0.06633349508047104,
      -0.022144371643662453,
      0.04582212120294571,
      -0.024631548672914505,
      -0.008355782367289066,
      -0.005576877389103174,
      -0.009662576019763947,
      0.07692625373601913,
      -0.037952981889247894,
      0.013219832442700863,
      0.010781138204038143,
      0.018538258969783783,
      0.053068771958351135,
      -0.006323038600385189,
      -0.003416389459744096,
      -0.0879131332039833,
      -0.0645054504275322,
      -0.057538364082574844,
      0.10127418488264084,
      0.012442539446055889,
      -0.013644924387335777,
      0.056867390871047974,
      -0.03844631835818291,
      -0.016507629305124283,
      -0.013334243558347225,
      0.07976237684488297,
      0.009006702341139317,
      0.04536263644695282,
      0.09815937280654907,
      -0.05872290953993797,
      0.02238653041422367,
      -0.11430547386407852,
      0.05844428762793541,
      0.0045306747779250145,
      -0.023959657177329063,
      0.06040841341018677,
      -0.013498598709702492,
      0.0035197290126234293,
      -0.02553035505115986,
      0.017233313992619514,
      -0.025276700034737587,
      0.04561349004507065,
      -0.01096240896731615,
      -0.030576076358556747,
      -0.005795380100607872,
      -0.007088209968060255,
      -0.059370920062065125,
      -0.0250802394002676,
      -0.0717538595199585,
      -0.017013616859912872,
      0.044973112642765045,
      0.011645425111055374,
      0.03706623613834381,
      -0.004365549888461828,
      -0.01465196069329977,
      -0.047629885375499725,
      -0.040906213223934174,
      -0.019595235586166382,
      0.019635021686553955,
      -0.0586296021938324,
      0.009565403684973717,
      -0.04713771864771843,
      0.060750167816877365,
      -0.012430338189005852,
      0.0014028564328327775,
      -0.035557039082050323,
      -0.0654667541384697,
      0.022336971014738083,
      -0.08990930765867233,
      -0.047951590269804,
      0.07694864273071289,
      -0.015015335753560066,
      0.019395343959331512,
      0.010039088316261768,
      0.0006013724487274885,
      0.056049179285764694,
      -0.04412727802991867,
      -0.01190862338989973,
      -0.033867307007312775,
      -0.011703547090291977,
      -0.03726550191640854,
      -0.05598185583949089,
      -0.09694859385490417,
      0.009847867302596569,
      -0.07734470069408417,
      0.07705800980329514,
      0.02014065906405449,
      0.0627388134598732,
      0.048213448375463486,
      0.0011707426747307181,
      -0.009565039537847042,
      -0.042493708431720734,
      0.059491731226444244,
      0.10260212421417236,
      -0.06327176839113235,
      -0.01485653780400753,
      0.031576305627822876,
      -0.025751302018761635,
      -0.04910454899072647,
      -0.039014264941215515,
      -0.058663543313741684,
      0.020648278295993805,
      -0.05250433459877968,
      -0.001358453999273479,
      0.008660264313220978,
      -0.09762237221002579,
      -0.006751979235559702,
      0.010063355788588524,
      0.10531247407197952,
      -0.015700552612543106,
      0.026419414207339287,
      0.007933116517961025,
      0.03946014866232872,
      0.060374047607183456,
      -0.03620683774352074,
      -0.023683082312345505,
      0.060544632375240326,
      0.10042476654052734,
      -0.05279901251196861,
      0.0736771821975708,
      -0.04202134162187576,
      0.06259720027446747,
      0.01920214109122753,
      0.05144825950264931,
      -0.05331328138709068,
      0.04357950761914253,
      0.047146815806627274,
      -0.023506944999098778,
      -0.018318794667720795,
      -0.05740125849843025,
      -0.02333725430071354,
      -0.11557967960834503,
      -0.060041699558496475,
      0.06167038902640343,
      0.017489703372120857,
      0.017386723309755325,
      -0.047916434705257416,
      0.04928850382566452,
      -0.004436801187694073,
      0.01575406827032566,
      0.026682049036026,
      -0.04483816772699356,
      0.04366828128695488,
      0.01792556419968605,
      0.11290016770362854,
      -0.03942154720425606,
      0.046661507338285446,
      -0.016310589388012886,
      0.016229044646024704,
      0.001288542291149497,
      0.04163016751408577,
      0.038979049772024155,
      -0.003042630385607481,
      0.06097632646560669,
      -0.007784975692629814,
      -0.09844033420085907,
      0.0323542095720768,
      0.08599953353404999,
      0.002467297948896885,
      -0.003453461918979883
    ],
    [
      -0.0422343909740448,
      -0.053300537168979645,
      0.0060722315683960915,
      -0.005536294542253017,
      0.04137362912297249,
      -0.023090887814760208,
      0.06014584004878998,
      0.040371112525463104,
      -0.05982346460223198,
      0.0036751460283994675,
      0.08115082234144211,
      -0.008307301439344883,
      0.031212253496050835,
      -0.0223948173224926,
      0.007270880974829197,
      -0.006988025736063719,
      -0.03191710263490677,
      0.025235798209905624,
      0.05588866397738457,
      0.05019816383719444,
      -0.006518303882330656,
      -0.0614754818379879,
      -0.10206267237663269,
      0.02617190219461918,
      -0.016664108261466026,
      -0.10126569122076035,
      -0.08626154065132141,
      -0.01890626735985279,
      0.05155746266245842,
      -0.07299375534057617,
      -0.028881683945655823,
      0.002593236044049263,
      -0.07084119319915771,
      0.013073526322841644,
      -0.010182647034525871,
      -0.02655305154621601,
      -0.02032599225640297,
      0.032384421676397324,
      0.05877950042486191,
      0.05384524166584015,
      0.04186030104756355,
      0.03834853693842888,
      -0.06801971793174744,
      -0.023376399651169777,
      0.008570726029574871,
      -0.00228955689817667,
      0.1029326319694519,
      0.04098109155893326,
      -0.023924628272652626,
      0.07260477542877197,
      -0.08484439551830292,
      -0.0013302179286256433,
      0.02492058090865612,
      -0.01233100425451994,
      0.01552402600646019,
      0.021057454869151115,
      0.03665592521429062,
      -0.06369861215353012,
      -0.03192434832453728,
      0.03342458978295326,
      -0.009728383272886276,
      -0.11833634227514267,
      0.013885896652936935,
      0.05951313674449921,
      -0.0047412472777068615,
      0.06500186026096344,
      0.02937069907784462,
      0.08549372106790543,
      0.07749628275632858,
      0.02476051077246666,
      -0.007978483103215694,
      -0.0313459075987339,
      0.03959504887461662,
      0.07155028730630875,
      -0.021002233028411865,
      -0.08393318951129913,
      -0.07236635684967041,
      0.005734572187066078,
      -0.0266472939401865,
      -0.03410777449607849,
      -0.07570463418960571,
      0.11121850460767746,
      0.02779659442603588,
      -0.04371695965528488,
      -0.08233699202537537,
      0.029540609568357468,
      -0.029670478776097298,
      -0.026797916740179062,
      -0.020741423591971397,
      -0.043335024267435074,
      -0.04800143465399742,
      0.016374342143535614,
      -0.07058055698871613,
      0.015663107857108116,
      0.012655338272452354,
      -0.005612886976450682,
      0.01773736998438835,
      0.04915858805179596,
      -0.04944887012243271,
      0.037492536008358,
      0.0025216613430529833,
      0.012181351892650127,
      0.07974862307310104,
      0.07405538856983185,
      -0.0004670140624511987,
      -0.034752435982227325,
      -0.06370644271373749,
      -0.0642084851861,
      0.00823001004755497,
      0.08888309448957443,
      -0.011142147704958916,
      0.052760571241378784,
      0.023955412209033966,
      -0.06346497684717178,
      -0.07699208706617355,
      -0.019778501242399216,
      0.04293978586792946,
      0.10549958050251007,
      -0.04201231896877289,
      -0.05980271100997925,
      0.03268308565020561,
      -0.03567037358880043,
      0.023841114714741707,
      -0.02957185171544552,
      -0.014992971904575825,
      0.07526102662086487,
      0.05577406659722328,
      0.004258451517671347,
      0.07172048836946487,
      0.005673845298588276,
      0.08311997354030609,
      0.018475499004125595,
      0.08730034530162811,
      0.011098743416368961,
      -0.03312171623110771,
      0.09058976173400879,
      0.00278983311727643,
      0.039645418524742126,
      0.029462439939379692,
      0.09290822595357895,
      0.03142208978533745,
      -0.023982983082532883,
      0.08386366069316864,
      -0.050405103713274,
      0.04680382087826729,
      0.009284324012696743,
      -0.0814334973692894,
      -0.05008699372410774,
      -0.050390925258398056,
      -0.04508465901017189,
      0.023490825667977333,
      -0.009557352401316166,
      -0.0486648753285408,
      0.021473094820976257,
      -0.014757540076971054,
      -0.025243407115340233,
      0.050102878361940384,
      0.01692476123571396,
      -0.03937087580561638,
      -0.07845306396484375,
      0.030535945668816566,
      0.13297826051712036,
      -0.0547209307551384,
      -0.018468940630555153,
      -0.004834300372749567,
      -0.026742905378341675,
      0.11526023596525192,
      0.07602698355913162,
      -0.009868002496659756,
      0.019571486860513687,
      -0.061798982322216034,
      -0.001565643004141748,
      -0.049682699143886566,
      -0.048781868070364,
      0.04375515878200531,
      0.055850591510534286,
      -0.044982243329286575,
      -0.0033067420590668917,
      0.028767643496394157,
      -0.005214647389948368,
      -0.06948930025100708,
      0.007361981086432934,
      0.035587310791015625,
      -0.025265520438551903,
      0.052921708673238754,
      -0.019707931205630302,
      -0.05415237322449684,
      -0.009977521374821663,
      -0.013683069497346878,
      0.024221722036600113,
      0.010291766375303268,
      -0.06292138993740082,
      0.06920909136533737,
      -0.003067492973059416,
      0.03744093328714371,
      0.011773004196584225,
      -0.07592568546533585,
      -0.0017641496378928423,
      -0.07933834195137024,
      -0.031339891254901886,
      -0.0034784830641001463,
      -0.033262595534324646,
      0.011784445494413376,
      0.023736879229545593,
      -0.10020830482244492,
      -0.06368555873632431,
      -0.06442495435476303,
      -0.0343809612095356,
      0.05256425589323044,
      0.036095649003982544,
      -0.03138072043657303,
      0.011838734149932861,
      -0.022947872057557106,
      0.013716391287744045,
      0.07754828035831451,
      0.06642652302980423,
      0.05527206510305405,
      -0.0683879405260086,
      -0.04787198081612587,
      -0.05413245037198067,
      -0.03630363196134567,
      0.01333036832511425,
      -0.0020744209177792072,
      -0.05237334221601486,
      -0.04319186881184578,
      -0.031550075858831406,
      -0.03950532153248787,
      0.03221734240651131,
      0.006471206899732351,
      -0.020010532811284065,
      0.021033158525824547,
      0.08214960247278214,
      -0.05321750044822693,
      -3.3091600926127285e-05,
      -0.03735899180173874,
      -0.05830848962068558,
      0.03753068298101425,
      0.021683601662516594,
      -0.06704609096050262,
      0.04982021450996399,
      0.014858742244541645,
      -0.012766992673277855,
      0.06460032612085342,
      -0.08660667389631271,
      -0.002534692408517003,
      0.04129615053534508,
      -0.008468607440590858,
      0.026957375928759575,
      -0.01855691894888878,
      0.0739983543753624,
      -0.0814882293343544,
      -0.0670992061495781,
      0.05579657107591629,
      -0.05036065727472305,
      -0.037144314497709274,
      -0.08731942623853683,
      0.015538208186626434,
      0.0017001965316012502,
      0.07382018119096756,
      -0.008171405643224716,
      0.02619650587439537,
      0.00879957526922226,
      0.07450195401906967,
      0.0034034785348922014,
      -0.035864006727933884,
      0.012562993913888931,
      0.09232404828071594,
      -0.027894429862499237,
      -0.08872157335281372,
      0.045261263847351074,
      0.029690105468034744,
      -0.016152458265423775,
      0.020397599786520004,
      -0.04659825563430786,
      0.00786685012280941,
      0.0497133769094944,
      0.003102557035163045,
      -0.05863000825047493,
      -0.02576649747788906,
      0.0147918201982975,
      -0.01417601853609085,
      0.03449714556336403,
      0.07729461789131165,
      0.05370263010263443,
      0.046148881316185,
      -0.03522747755050659,
      -0.06986796110868454,
      -0.10248032957315445,
      0.03966177999973297,
      -0.015796342864632607,
      -0.012591288425028324,
      -0.021856846287846565,
      0.07561347633600235,
      -0.09719731658697128,
      -0.03381308540701866,
      -0.01979231648147106,
      -0.002497320296242833,
      0.052457790821790695,
      0.0050490498542785645,
      0.0038344906643033028,
      -0.04660371318459511,
      0.0057154446840286255,
      0.03467191383242607,
      -0.017978478223085403,
      -0.024321511387825012,
      -0.025386478751897812,
      0.021224793046712875,
      0.02136273682117462,
      -0.04025518521666527,
      0.011516924016177654,
      -0.01621360518038273,
      0.05522951856255531,
      0.02844364568591118,
      0.07765572518110275,
      -0.021424366161227226,
      -0.06460768729448318,
      0.045462459325790405,
      0.04229810833930969,
      -0.03184404596686363,
      -0.007737814914435148,
      -0.08674834668636322,
      -0.033873606473207474,
      -0.07838950306177139,
      -0.06561004370450974,
      0.07396236807107925,
      0.014258409850299358,
      -0.01252050045877695,
      0.0571415089070797,
      -0.10674355179071426,
      0.000209307050681673,
      0.061046283692121506,
      -0.03052445314824581,
      -0.006557864136993885,
      -0.037515174597501755,
      -0.05694747716188431,
      -0.04883039370179176,
      -0.03145372495055199,
      0.03653746470808983,
      0.001864710939116776,
      0.11884599924087524,
      -0.008521651849150658,
      0.04047255590558052,
      0.06521373242139816,
      0.047502800822257996,
      0.020482616499066353,
      0.049633197486400604,
      0.07828973978757858,
      -0.039618268609046936,
      -0.04549194499850273,
      -0.05484436824917793,
      0.04434085264801979,
      0.07189063727855682,
      0.011261646635830402,
      0.00862517673522234,
      -0.08273578435182571,
      -0.0017497908556833863,
      0.025859180837869644,
      0.059882521629333496,
      0.0089484341442585,
      0.08806630969047546,
      -0.0843668207526207,
      0.04491527006030083,
      -0.004976531025022268,
      0.020089037716388702,
      -0.10099990665912628,
      0.012882044538855553,
      0.003770179348066449,
      -0.013238291256129742,
      0.07496998459100723,
      -0.006444281432777643,
      -0.024550778791308403,
      -0.042285509407520294,
      0.004623313434422016,
      -0.1193959042429924,
      -0.003337883623316884,
      0.022568093612790108,
      0.018350137397646904,
      0.05430116131901741,
      0.0002451566106174141,
      -0.018338976427912712,
      -0.03596268966794014,
      0.06412536650896072,
      -0.06761781126260757,
      0.035415198653936386,
      0.0457744263112545,
      -0.0301672276109457,
      0.011084800586104393,
      -0.021400125697255135,
      0.017723264172673225,
      0.0218794047832489,
      -0.04201996698975563,
      -0.03749646991491318,
      0.03655462712049484,
      0.00998086016625166,
      -0.03908601403236389,
      0.05644607916474342,
      0.007017200812697411,
      0.03391684219241142,
      0.059872906655073166,
      -0.011027636006474495,
      -0.10652325302362442,
      0.047692831605672836,
      -0.029495300725102425,
      -0.1016710102558136,
      -0.0021152053959667683,
      0.034230783581733704,
      -0.06159968301653862,
      -0.01319979876279831,
      -0.05016569420695305,
      0.06773315370082855,
      0.023001717403531075,
      0.010696204379200935,
      0.025532148778438568,
      0.04390823096036911,
      0.05213942378759384,
      0.02056603692471981,
      0.05461020767688751,
      -0.010684078559279442,
      -0.017418185248970985,
      -0.007443103473633528,
      0.06599069386720657,
      -0.0026771065313369036,
      -0.004134210757911205,
      0.02205486036837101,
      0.006548997946083546,
      -0.0006533419946208596,
      -0.023222744464874268,
      -0.017025472596287727,
      0.02797028049826622,
      -0.0032687089405953884,
      -0.006525799166411161,
      -0.010827817022800446,
      0.048589784651994705,
      0.03920738771557808,
      -0.0026828902773559093,
      0.02912040427327156,
      0.018890654668211937,
      0.04786035418510437,
      0.04888328164815903,
      -0.012717008590698242,
      -0.041066378355026245,
      0.03614659234881401,
      -0.03097432106733322,
      -0.03841709718108177,
      -0.0547628328204155,
      0.0838668942451477,
      0.018163083121180534,
      -0.04194008931517601,
      -0.04307207092642784,
      -0.10710598528385162,
      0.018880296498537064,
      0.07499400526285172,
      -0.054565250873565674,
      -0.04398094862699509,
      -0.02144201658666134,
      0.03671400994062424,
      -0.0820004791021347,
      -0.008412961848080158,
      -0.006118974648416042,
      -0.00879964791238308,
      -0.0020901095122098923,
      0.042895860970020294,
      0.02751895785331726,
      -0.04851297661662102,
      0.006921748165041208,
      0.03892560303211212,
      0.004780908115208149,
      -0.06462640315294266,
      -0.021036792546510696,
      -0.005605914629995823,
      -0.0505727119743824,
      -0.034930627793073654,
      0.026832999661564827,
      0.018045395612716675,
      -0.05487007647752762,
      -0.0024114162661135197,
      -0.06388061493635178,
      0.05293891578912735,
      -0.047123152762651443,
      -0.04845254868268967,
      0.059448275715112686,
      0.038493506610393524,
      0.057637929916381836,
      -0.057634275406599045,
      0.056412141770124435,
      -0.05701876059174538,
      -0.06455988436937332,
      0.045433223247528076,
      0.004517391324043274,
      -0.054527126252651215,
      0.06236541271209717,
      -0.027477703988552094,
      -0.03712340444326401,
      0.11453758925199509,
      -0.035006724298000336,
      -0.02219468355178833,
      -0.05102315545082092,
      -0.07317765057086945,
      -0.06796475499868393,
      0.003052780404686928,
      -0.06617375463247299,
      0.011146759614348412,
      0.03355378657579422
    ],
    [
      -0.08068764954805374,
      -0.04126076027750969,
      0.09181917458772659,
      0.01774822175502777,
      0.09331750124692917,
      0.006963703315705061,
      -0.010380777530372143,
      0.06173960119485855,
      -0.05963191017508507,
      0.03422131389379501,
      -0.07892162352800369,
      0.09472604095935822,
      -0.012935368344187737,
      -0.07216222584247589,
      0.0009702514507807791,
      -0.011783282272517681,
      -0.028191236779093742,
      -0.04666177183389664,
      0.007154225837439299,
      0.01369535643607378,
      -0.030535418540239334,
      -0.03267940878868103,
      -0.02370377443730831,
      -0.006043753586709499,
      -0.0789850577712059,
      -0.009883030317723751,
      0.007981091737747192,
      -0.00045244331704452634,
      0.04089052602648735,
      -0.0367312915623188,
      0.034078117460012436,
      -0.014433708041906357,
      -0.04023412987589836,
      0.025358540937304497,
      0.02315172739326954,
      -0.05697432532906532,
      -0.009669424965977669,
      0.03464895114302635,
      0.0038473736494779587,
      -0.007465930189937353,
      -0.025470977649092674,
      -0.057597566395998,
      0.041656482964754105,
      -0.010747323743999004,
      -0.057118166238069534,
      0.014597341418266296,
      0.0028220582753419876,
      0.000837320985738188,
      -0.08880311995744705,
      0.018876131623983383,
      -0.023993482813239098,
      0.05919000506401062,
      0.019390560686588287,
      0.06742333620786667,
      -0.006377577316015959,
      -0.040396060794591904,
      -0.0037885773926973343,
      -0.0010807625949382782,
      -0.05210522562265396,
      0.05903511866927147,
      -0.07165553420782089,
      -0.0439249724149704,
      0.04150708392262459,
      -0.02818957343697548,
      0.03963921591639519,
      -0.022096827626228333,
      -0.07481550425291061,
      0.017737070098519325,
      0.01959265023469925,
      0.11256319284439087,
      0.06103084236383438,
      -0.017442673444747925,
      0.027792904525995255,
      -0.07316961884498596,
      0.015324627049267292,
      0.06540635973215103,
      0.08100394904613495,
      0.008578452281653881,
      0.08385086059570312,
      -0.119545578956604,
      -0.001956861699000001,
      0.061564091593027115,
      0.036182161420583725,
      -0.03527053818106651,
      -0.07358036190271378,
      -0.028902428224682808,
      0.027864523231983185,
      0.02445867843925953,
      -0.08642451465129852,
      -0.01668807677924633,
      0.08590683341026306,
      -0.06418363004922867,
      0.04140165075659752,
      0.052587393671274185,
      -0.06908758729696274,
      0.016577668488025665,
      0.02355255000293255,
      -0.025183076038956642,
      -0.04690522328019142,
      0.016458207741379738,
      0.023936333134770393,
      -0.03360488638281822,
      0.067257359623909,
      0.0036173188127577305,
      0.04074709117412567,
      0.028251243755221367,
      -0.03618432953953743,
      0.05873360484838486,
      -0.04001496359705925,
      -0.00028528241091407835,
      -0.006638217251747847,
      -0.06978027522563934,
      0.01112956553697586,
      -0.08094603568315506,
      0.0215681754052639,
      0.06259249895811081,
      0.02973550744354725,
      0.05918889120221138,
      0.021344400942325592,
      0.05916120111942291,
      0.014702579006552696,
      -0.011556407436728477,
      0.06495652347803116,
      0.006777838803827763,
      0.004870663397014141,
      0.015445397235453129,
      0.048281628638505936,
      0.014215857721865177,
      0.007820318453013897,
      0.02282273769378662,
      0.03255575895309448,
      0.027911024168133736,
      0.030613571405410767,
      0.032199107110500336,
      0.008657700382173061,
      -0.006348426453769207,
      -0.013532880693674088,
      0.11201922595500946,
      0.008778559975326061,
      -0.05491047352552414,
      -0.04644201695919037,
      -0.02734091319143772,
      0.09872695058584213,
      0.038265421986579895,
      0.028462886810302734,
      0.0026575708761811256,
      -0.10331954061985016,
      -0.03277232125401497,
      0.03065171092748642,
      0.07643184810876846,
      0.03493726626038551,
      0.01324966736137867,
      -0.026604706421494484,
      -0.026884419843554497,
      0.013896466232836246,
      -0.0028045917861163616,
      -0.046258099377155304,
      0.12732933461666107,
      0.043997425585985184,
      0.06407453864812851,
      0.02894771099090576,
      0.01638404279947281,
      0.010535628534853458,
      0.021752718836069107,
      -0.028264736756682396,
      -0.06330809742212296,
      -0.0502488799393177,
      0.023032011464238167,
      0.033822063356637955,
      0.07684548199176788,
      -0.038892507553100586,
      -0.05350426957011223,
      0.010399967432022095,
      0.06960706412792206,
      -0.009872928261756897,
      -0.05598166957497597,
      0.06441418081521988,
      0.004305643495172262,
      0.020452162250876427,
      -0.05226811394095421,
      -0.009079878218472004,
      0.04351266101002693,
      -0.018196770921349525,
      0.01982934959232807,
      0.020912539213895798,
      -0.0002573247766122222,
      0.031138963997364044,
      0.0079729612916708,
      0.08087102323770523,
      0.008581404574215412,
      -0.029099836945533752,
      0.05108225345611572,
      -0.1607770323753357,
      0.06305025517940521,
      0.03813375160098076,
      -0.015055687166750431,
      -0.058755066245794296,
      0.05140537768602371,
      -0.042320698499679565,
      -0.009684821590781212,
      0.001556107308715582,
      -0.02937108278274536,
      0.0017375281313434243,
      -0.006155590061098337,
      0.052679531276226044,
      0.04498997703194618,
      -0.0016868308885022998,
      0.010313195176422596,
      0.010351656936109066,
      0.025407280772924423,
      0.06874696910381317,
      0.025093605741858482,
      -0.00468532694503665,
      -0.045068103820085526,
      -0.016787083819508553,
      0.0324522890150547,
      -0.012523354962468147,
      -0.057197995483875275,
      0.002041517524048686,
      0.01391585823148489,
      -0.10574974864721298,
      0.06899373978376389,
      -0.03982464596629143,
      0.01523718424141407,
      -0.037190161645412445,
      0.005090990569442511,
      -0.11020641028881073,
      0.01396890077739954,
      0.031908366829156876,
      0.019036728888750076,
      0.04721761867403984,
      -0.0508163645863533,
      0.06440135836601257,
      -0.05668837949633598,
      -0.010532433167099953,
      -0.032486431300640106,
      0.013200100511312485,
      -0.0015112063847482204,
      0.018854742869734764,
      0.0039771366864442825,
      -0.07247775048017502,
      -0.04662568122148514,
      -0.05142895132303238,
      -0.07523176819086075,
      -0.014026537537574768,
      0.025804752483963966,
      0.048562560230493546,
      -0.015469678677618504,
      0.013163368217647076,
      0.05888071283698082,
      -0.051221515983343124,
      -0.03363490104675293,
      -0.004933413118124008,
      0.06509585678577423,
      0.08928903937339783,
      0.0034674513153731823,
      -0.10723160207271576,
      -0.029036017134785652,
      0.021787727251648903,
      -0.006558464374393225,
      0.0048500290140509605,
      -0.03729500621557236,
      -0.005046311300247908,
      -0.009638854302465916,
      0.020350245758891106,
      -0.03111043944954872,
      0.029674064368009567,
      -0.05475584790110588,
      0.04423028230667114,
      -0.033578407019376755,
      0.05126175284385681,
      0.024027764797210693,
      -0.01184300146996975,
      -0.0669659823179245,
      -0.07482431828975677,
      0.036686282604932785,
      -0.06746052950620651,
      0.006304280366748571,
      -0.029063673689961433,
      -0.022449206560850143,
      0.01804157719016075,
      0.006332268938422203,
      -0.018295815214514732,
      -0.031003015115857124,
      0.060396503657102585,
      0.04355752840638161,
      0.010149032808840275,
      0.016320576891303062,
      -0.03792736679315567,
      0.052217159420251846,
      0.05117255821824074,
      0.048879120498895645,
      0.02254839986562729,
      -0.02410021424293518,
      -0.02023286186158657,
      -0.060645826160907745,
      0.011977935209870338,
      -0.03389652445912361,
      -0.06220952048897743,
      0.04364916309714317,
      -0.05760655552148819,
      -7.112010644050315e-05,
      0.028842533007264137,
      -0.07264009863138199,
      0.02779764123260975,
      -0.02364117093384266,
      0.03288876265287399,
      -0.006316165439784527,
      -0.0385127067565918,
      -0.01917477883398533,
      0.04681935906410217,
      -0.018538249656558037,
      -0.03990548849105835,
      -0.02388155832886696,
      0.045965977013111115,
      -0.013698199763894081,
      -0.05095571279525757,
      0.06224165856838226,
      -0.028908295556902885,
      0.02443782426416874,
      -0.030457904562354088,
      0.011212524957954884,
      -0.07646820694208145,
      -0.0154921505600214,
      0.06539363414049149,
      -0.034529995173215866,
      0.008287043310701847,
      0.04504008963704109,
      -0.01945061981678009,
      -0.08325111865997314,
      -0.001591379288583994,
      0.03660023584961891,
      -0.001954641891643405,
      -0.02149192802608013,
      -0.03173062205314636,
      0.057576391845941544,
      0.005641381721943617,
      0.08887407183647156,
      0.033611029386520386,
      -0.001156552229076624,
      0.052193716168403625,
      -0.01955917291343212,
      -0.01905777119100094,
      0.0730997622013092,
      -0.043235938996076584,
      0.015825945883989334,
      -0.06786799430847168,
      0.027004403993487358,
      0.07929014414548874,
      0.05805215984582901,
      0.044983044266700745,
      0.06404843181371689,
      0.030761945992708206,
      -0.04853615537285805,
      0.01916787214577198,
      -0.018506232649087906,
      0.003646412631496787,
      0.019548173993825912,
      -0.03681229427456856,
      -0.039997536689043045,
      -0.016547637060284615,
      0.026412298902869225,
      0.06703303009271622,
      -0.045075103640556335,
      -0.05902300029993057,
      -0.008607449941337109,
      -0.0933881476521492,
      0.04537009075284004,
      0.06125034764409065,
      0.02551061473786831,
      -0.07560515403747559,
      0.009471166878938675,
      -0.002506119664758444,
      0.017656002193689346,
      0.0025978805497288704,
      -0.09149828553199768,
      0.011055152863264084,
      -0.01505462545901537,
      -0.016553735360503197,
      0.008140099234879017,
      -0.06275425106287003,
      0.016303833574056625,
      0.04611573740839958,
      0.04988296329975128,
      -0.0183551087975502,
      0.005683005787432194,
      -0.04481394588947296,
      0.023520998656749725,
      0.0880194678902626,
      0.011106779798865318,
      0.01607045903801918,
      -0.12167792022228241,
      -0.025135545060038567,
      -0.01446688175201416,
      -0.019183456897735596,
      0.0017519787652418017,
      0.023620568215847015,
      -0.04935137555003166,
      -0.09371238946914673,
      0.10584720224142075,
      -0.028879964724183083,
      0.029342355206608772,
      0.012716619297862053,
      -0.032363273203372955,
      -0.0011872362811118364,
      -0.011290784925222397,
      -0.008578588254749775,
      -0.08278251439332962,
      -0.06981996446847916,
      0.02193601243197918,
      0.03637922927737236,
      0.05254200100898743,
      0.008815126493573189,
      -0.057255156338214874,
      0.004181502852588892,
      0.021052762866020203,
      0.0869651809334755,
      0.0021308339200913906,
      0.006192540749907494,
      -0.012582569383084774,
      0.00021975931304041296,
      -0.025977443903684616,
      0.012127787806093693,
      -0.043987300246953964,
      0.022611085325479507,
      0.0020074709318578243,
      0.01277095265686512,
      0.005199227016419172,
      0.0017138124676421285,
      -0.04037998616695404,
      -0.019478177651762962,
      -0.04763396456837654,
      -0.034607816487550735,
      0.04431472718715668,
      0.0546879842877388,
      0.06072108447551727,
      -0.02885158732533455,
      -0.054482411593198776,
      -0.00036193995038047433,
      -0.03251287713646889,
      -0.002582274843007326,
      -0.053047455847263336,
      0.06273753196001053,
      -0.023632919415831566,
      -0.07031159847974777,
      -0.005055139772593975,
      0.07331638038158417,
      -0.08687029778957367,
      -0.03539091348648071,
      -0.02628721296787262,
      -0.029968133196234703,
      0.11618571728467941,
      -0.05165329948067665,
      -0.037289831787347794,
      0.018642283976078033,
      0.012445814907550812,
      -0.031089287251234055,
      0.01783139258623123,
      0.02820994146168232,
      -0.025796618312597275,
      0.04582689702510834,
      0.009252489544451237,
      -0.01803729683160782,
      -0.07998340576887131,
      -0.021739939227700233,
      -0.01965933106839657,
      0.033360857516527176,
      -0.024851227179169655,
      -0.09177614748477936,
      0.03508961945772171,
      -0.08038745075464249,
      0.07710614055395126,
      0.019439494237303734,
      -0.06580276042222977,
      -0.023016255348920822,
      0.04434932768344879,
      0.033769745379686356,
      -0.031003303825855255,
      -0.056624483317136765,
      0.046114228665828705,
      -0.00466496217995882,
      -0.030202167108654976,
      -0.12015591561794281,
      -0.026219451799988747,
      0.07344064116477966,
      0.04917071759700775,
      0.04653764143586159,
      -0.007391671650111675,
      0.08360857516527176,
      -0.004457909148186445,
      -0.016007808968424797,
      0.046107981353998184,
      -0.040059350430965424,
      0.03226444497704506,
      -0.08416663110256195,
      -0.05158361420035362,
      0.05095088854432106,
      -0.03977493196725845,
      0.007919619791209698,
      -0.038671329617500305,
      -0.05051741749048233,
      0.05986183509230614,
      -0.008814696222543716
    ],
    [
      0.021541230380535126,
      0.09252367168664932,
      -0.05727192014455795,
      0.035497065633535385,
      0.04059820994734764,
      0.026979634538292885,
      -0.003766541136428714,
      0.04185382276773453,
      -0.029812609776854515,
      0.05075471103191376,
      0.08144961297512054,
      0.017549343407154083,
      0.01589161343872547,
      0.014187231659889221,
      0.01164246816188097,
      -0.033347249031066895,
      -0.02829740010201931,
      0.03775224834680557,
      0.008819089271128178,
      -0.09749452769756317,
      0.023382561281323433,
      0.00602834252640605,
      0.03368164226412773,
      0.14477424323558807,
      -0.008524522185325623,
      -0.05386437103152275,
      -0.05611935630440712,
      -0.07155146449804306,
      -0.032302048057317734,
      0.014442520216107368,
      -0.02168615162372589,
      -0.0018798460951074958,
      -0.0825771689414978,
      0.01405805628746748,
      0.015080934390425682,
      -0.023804986849427223,
      -0.057402148842811584,
      -0.03010878898203373,
      -0.03739934042096138,
      0.0005229176604188979,
      0.021719900891184807,
      -0.012955419719219208,
      0.01419482659548521,
      0.01756746508181095,
      0.002573550445958972,
      -0.005521457176655531,
      0.03613557666540146,
      -0.048490770161151886,
      -0.05792746692895889,
      -0.024832699447870255,
      -0.06972828507423401,
      -0.021111126989126205,
      -0.021454589441418648,
      0.07975713163614273,
      0.10530003905296326,
      -0.040733542293310165,
      0.013761221431195736,
      0.021505624055862427,
      -0.009609278291463852,
      -0.03807505592703819,
      0.012546280398964882,
      -0.03887709230184555,
      0.0035888657439500093,
      -0.11129993200302124,
      -0.039082229137420654,
      0.024181369692087173,
      -0.02349071577191353,
      0.1215481236577034,
      0.028455480933189392,
      -0.014751650393009186,
      0.012900169938802719,
      0.024249721318483353,
      -0.01462579146027565,
      0.0414576381444931,
      -0.07722244411706924,
      -0.08869177848100662,
      0.04060874134302139,
      -0.11765137314796448,
      -0.013079894706606865,
      -0.013948149047791958,
      0.009596406482160091,
      0.023837773129343987,
      0.00483261002227664,
      -0.03270038589835167,
      -0.073184534907341,
      0.023399977013468742,
      0.07287333160638809,
      -4.456984970602207e-05,
      -0.005242303945124149,
      0.05423669144511223,
      0.08004289120435715,
      -0.04266984760761261,
      -0.056646928191185,
      -0.033944062888622284,
      0.03926246613264084,
      0.0013243171852082014,
      0.012396088801324368,
      -0.08984231948852539,
      -0.044377401471138,
      0.008153446018695831,
      0.03748035058379173,
      0.0023556554224342108,
      0.05381082370877266,
      -0.07090632617473602,
      0.015179639682173729,
      -0.027020247653126717,
      -0.031051618978381157,
      0.00610471423715353,
      -0.03423386812210083,
      0.0459030456840992,
      0.08925700932741165,
      -0.0223965086042881,
      0.016272660344839096,
      -0.016124948859214783,
      0.05901644378900528,
      0.04364831745624542,
      -0.004769680555909872,
      0.0456729456782341,
      -0.03202928602695465,
      0.02017054706811905,
      0.018700353801250458,
      -0.07017938792705536,
      0.015145841054618359,
      -0.10705047845840454,
      -0.02782445214688778,
      0.015961822122335434,
      0.00036527059273794293,
      -0.046942636370658875,
      -0.03873567283153534,
      0.0098616573959589,
      0.04390017315745354,
      0.006767682731151581,
      0.058780599385499954,
      0.007098465226590633,
      -0.011086094193160534,
      0.056749556213617325,
      0.0203484445810318,
      0.0731988474726677,
      0.027274085208773613,
      -0.005322181154042482,
      -0.018769584596157074,
      0.04813084378838539,
      -0.01795215532183647,
      0.017490575090050697,
      -0.04338648542761803,
      -0.05831802263855934,
      -0.015906602144241333,
      -0.020679881796240807,
      0.0016673405189067125,
      -0.035954225808382034,
      -0.023808961734175682,
      0.061817873269319534,
      -0.008911052718758583,
      -0.0037047185469418764,
      -0.04516275227069855,
      0.02548721246421337,
      -0.04274488240480423,
      -0.04316229000687599,
      0.03295841068029404,
      -0.033574916422367096,
      -0.026575792580842972,
      -0.05013924464583397,
      -0.0013333115493878722,
      0.0054334732703864574,
      0.005148590542376041,
      0.06196248158812523,
      -0.00929056853055954,
      -0.012429489754140377,
      0.058409806340932846,
      -0.023412669077515602,
      -0.0017345304368063807,
      0.015904655680060387,
      -0.011602647602558136,
      0.020115302875638008,
      0.04224540665745735,
      0.033017367124557495,
      -0.017929473891854286,
      -0.01662738062441349,
      -0.038971301168203354,
      0.07876905798912048,
      -0.03439170494675636,
      0.10395418852567673,
      -0.04187464341521263,
      0.03903680294752121,
      0.00198722118511796,
      0.048264600336551666,
      -0.008494612760841846,
      -0.04585244134068489,
      -0.013964556157588959,
      0.05213945731520653,
      0.03075277991592884,
      0.005939691327512264,
      0.040994711220264435,
      -0.027066992595791817,
      -0.04518140107393265,
      -0.07720527052879333,
      -0.054021045565605164,
      -0.03791641816496849,
      -0.09974713623523712,
      -0.03450407087802887,
      -0.00010458726319484413,
      0.033291853964328766,
      -0.015610410831868649,
      0.004023109562695026,
      0.009344606660306454,
      -0.0303922351449728,
      0.015583742409944534,
      9.745488205226138e-05,
      -0.026134584099054337,
      0.0688275620341301,
      -0.08841289579868317,
      -0.051892247051000595,
      -0.00028252656920813024,
      -0.014210248365998268,
      0.027217024937272072,
      -0.010168016888201237,
      0.021174227818846703,
      -0.09772514551877975,
      0.014904270879924297,
      0.03243798762559891,
      -0.01066791545599699,
      0.016422543674707413,
      0.07032763957977295,
      0.0196355190128088,
      -0.0021789190359413624,
      0.07083767652511597,
      -0.09000349789857864,
      0.022288652136921883,
      0.00835246779024601,
      -0.09791497886180878,
      0.036861903965473175,
      0.02571050077676773,
      -0.03539511188864708,
      -0.08652234077453613,
      -0.020186826586723328,
      0.01635970175266266,
      -0.08079833537340164,
      -0.032426830381155014,
      0.03481508046388626,
      0.04510195180773735,
      0.08036710321903229,
      -0.004095674492418766,
      -0.013842766173183918,
      0.027079720050096512,
      0.006550666876137257,
      0.04384841024875641,
      0.04055970534682274,
      0.04198934882879257,
      -0.05307655408978462,
      -0.04279250279068947,
      -0.046753253787755966,
      -0.08119850605726242,
      0.0014335454907268286,
      0.0516447015106678,
      0.019569816067814827,
      0.004002956207841635,
      0.02758507803082466,
      -0.02082706056535244,
      -0.07551833242177963,
      0.0063910274766385555,
      0.00334330927580595,
      0.06162482127547264,
      0.006757283117622137,
      -0.026879331097006798,
      0.024159645661711693,
      -0.03449486196041107,
      -0.02083108201622963,
      -0.06703456491231918,
      -0.03581264987587929,
      -0.06913325935602188,
      0.054869938641786575,
      0.052212975919246674,
      -0.0428747683763504,
      0.0020602617878466845,
      0.00998027715831995,
      -0.05341852828860283,
      -0.09986390173435211,
      -0.03974456712603569,
      -0.031236570328474045,
      0.0029673397075384855,
      -0.010095736011862755,
      -0.018409807235002518,
      -0.08522851765155792,
      0.05129227042198181,
      0.06290894746780396,
      -0.06264783442020416,
      -0.02669212408363819,
      0.08821709454059601,
      -0.03273782134056091,
      0.0048940349370241165,
      0.013834330253303051,
      0.04070087522268295,
      0.05389927327632904,
      -0.004587382543832064,
      0.03716782480478287,
      0.04939284920692444,
      0.037160638719797134,
      0.03996801748871803,
      -0.005472836550325155,
      -0.03518636152148247,
      -0.05174579098820686,
      0.0035419880878180265,
      0.000497517001349479,
      -0.05144037306308746,
      0.0051675280556082726,
      -0.03556833416223526,
      -0.05140017718076706,
      0.03399651497602463,
      -0.04178621619939804,
      0.07125767320394516,
      -0.007989154197275639,
      0.08650506287813187,
      -0.061056096106767654,
      0.032485999166965485,
      0.03439607098698616,
      0.0058981068432331085,
      0.004682315047830343,
      -0.01274582277983427,
      0.08072616904973984,
      -0.0153020229190588,
      0.024261703714728355,
      -0.03371189907193184,
      -0.04877829924225807,
      -0.04542133957147598,
      0.020302606746554375,
      0.016487454995512962,
      -0.043606050312519073,
      0.07017835229635239,
      -0.0016313575906679034,
      0.01297490019351244,
      -0.098793625831604,
      -0.0034517114982008934,
      0.1014871820807457,
      0.008293944410979748,
      -0.03543228656053543,
      -0.035483021289110184,
      0.017436625435948372,
      0.049102988094091415,
      0.08488938212394714,
      0.02993648126721382,
      -0.019980033859610558,
      0.0343962125480175,
      -0.10122179239988327,
      -0.010435511358082294,
      0.027252493426203728,
      0.01989247463643551,
      -0.03466504439711571,
      0.08803951740264893,
      0.1444062739610672,
      -6.258662324398756e-05,
      -0.04630737751722336,
      0.0008849361911416054,
      0.07864055782556534,
      0.043941956013441086,
      -0.04884642735123634,
      0.010411370545625687,
      -0.024965250864624977,
      0.03675483167171478,
      -0.05078558251261711,
      -0.01886037550866604,
      -0.0027048876509070396,
      0.06270678341388702,
      -0.04720137268304825,
      0.01282729022204876,
      0.0634077787399292,
      -0.07209223508834839,
      0.028648141771554947,
      0.011758340522646904,
      -0.006195309106260538,
      -0.14056022465229034,
      -0.026245390996336937,
      -0.07656151801347733,
      -0.04864257946610451,
      -0.01598389260470867,
      -0.06021784245967865,
      0.0518278107047081,
      -0.012218667194247246,
      0.027220850810408592,
      -0.0072520156390964985,
      -0.06001758575439453,
      0.003316338639706373,
      0.07279390096664429,
      -0.007567531894892454,
      0.015199074521660805,
      -0.038011860102415085,
      0.0856781080365181,
      -0.019563237205147743,
      -0.039998505264520645,
      -0.07458126544952393,
      -0.031817685812711716,
      0.0013128999853506684,
      -0.0393388569355011,
      0.0029916393104940653,
      0.05022300407290459,
      0.008256101980805397,
      0.007967115379869938,
      0.029114171862602234,
      0.015108166262507439,
      -0.003343849442899227,
      0.008897967636585236,
      0.0030382960103452206,
      0.048931706696748734,
      -0.08354157954454422,
      0.027691984549164772,
      0.0019107392290607095,
      -0.003071359358727932,
      -0.004663590807467699,
      0.03299334645271301,
      0.029594983905553818,
      0.0452449694275856,
      0.048425376415252686,
      -0.08464504033327103,
      -0.031879764050245285,
      -0.008294223807752132,
      -0.034183111041784286,
      0.007973015308380127,
      -0.04497862979769707,
      0.017163453623652458,
      -0.018449939787387848,
      0.030772605910897255,
      0.048835255205631256,
      0.031434331089258194,
      -0.07371409982442856,
      -0.022610073909163475,
      -0.012705869041383266,
      -0.0019978934433311224,
      -0.017440861091017723,
      0.0033945024479180574,
      0.1005501002073288,
      -0.014868581667542458,
      0.06282251328229904,
      -0.06428617984056473,
      0.05456818640232086,
      -0.020976418629288673,
      -0.06155027821660042,
      -0.06075650453567505,
      0.026325931772589684,
      -0.047296587377786636,
      -0.008835447020828724,
      0.062972292304039,
      0.014676684513688087,
      0.07812504470348358,
      -0.0936524048447609,
      -0.025479035452008247,
      -0.13150157034397125,
      0.13071942329406738,
      -0.04392564669251442,
      0.04079611971974373,
      -0.01144906785339117,
      0.007294499780982733,
      -0.05744846537709236,
      0.039917558431625366,
      0.013960371725261211,
      0.015986165031790733,
      0.07010924071073532,
      0.026158450171351433,
      -0.0023005257826298475,
      -0.010393493808805943,
      0.08014805614948273,
      0.06657328456640244,
      0.05784742161631584,
      -0.05096001550555229,
      -0.02293206751346588,
      0.0011122802970930934,
      -0.015167918987572193,
      -0.06718719750642776,
      0.051659245043992996,
      0.008743003010749817,
      -0.016002878546714783,
      0.040653295814991,
      -0.0647125318646431,
      -0.05032537505030632,
      -0.01838596723973751,
      0.00014206116611603647,
      0.05477505922317505,
      0.049912746995687485,
      0.08334460109472275,
      0.050944481045007706,
      0.005119482055306435,
      0.06190325692296028,
      -0.04775266721844673,
      0.04754659906029701,
      -0.0033113148529082537,
      -0.02765871211886406,
      -0.05316586047410965,
      -0.02934342436492443,
      0.04969212785363197,
      -0.017055941745638847,
      -0.00233913934789598,
      -0.05426910147070885,
      0.004838665947318077,
      -0.023735471069812775,
      0.008133850060403347,
      0.00462446128949523,
      0.04804186150431633,
      -0.04581845924258232,
      0.022661378607153893,
      0.005606555845588446,
      0.049067724496126175,
      0.016448596492409706,
      0.019592495635151863,
      0.10382869094610214,
      0.08191881328821182
    ],
    [
      0.0019123933743685484,
      0.09724301844835281,
      0.031321000307798386,
      0.07009287178516388,
      -0.0010253232903778553,
      -0.015478504821658134,
      0.03660741075873375,
      0.011604896746575832,
      0.01449360977858305,
      0.024584291502833366,
      -0.01492767222225666,
      0.008594285696744919,
      -0.006482201628386974,
      -0.03383585438132286,
      0.03822343796491623,
      -0.04024628549814224,
      -0.06470280140638351,
      -0.054529882967472076,
      0.019344523549079895,
      -0.017481990158557892,
      -0.03212427720427513,
      0.09082002937793732,
      -0.004381481092423201,
      -0.007287459913641214,
      -0.014468176290392876,
      0.004028583411127329,
      0.029792984947562218,
      -0.02799075096845627,
      -0.0781724601984024,
      -0.006472292821854353,
      0.0691065788269043,
      0.058804627507925034,
      -0.025874191895127296,
      0.032127704471349716,
      -0.006539050489664078,
      -0.006149084772914648,
      -0.08249609172344208,
      -0.005349848419427872,
      0.012830454856157303,
      0.018094370141625404,
      0.06296999752521515,
      -0.0514645054936409,
      0.016476931050419807,
      0.002638336503878236,
      -0.059919457882642746,
      -0.01718352921307087,
      0.021428121253848076,
      -0.0207737535238266,
      0.021135389804840088,
      -0.009221488609910011,
      -0.022089866921305656,
      -0.016825947910547256,
      0.04676726087927818,
      0.009643469005823135,
      -0.07160205394029617,
      0.00973778311163187,
      0.04193834960460663,
      0.026620836928486824,
      0.025171587243676186,
      -0.0059387399815022945,
      0.006817259360104799,
      -0.01957603730261326,
      0.03205813094973564,
      -0.02843935415148735,
      -0.008793392218649387,
      0.060651957988739014,
      0.005843040999025106,
      -0.006742730271071196,
      0.012790573760867119,
      0.042095035314559937,
      -0.05269578471779823,
      0.017897361889481544,
      0.04075518995523453,
      0.024187296628952026,
      -0.010301976464688778,
      -0.034913185983896255,
      -0.02917700819671154,
      -0.014922198839485645,
      -0.00968188513070345,
      -0.006987186148762703,
      -0.05360899120569229,
      -0.014356348663568497,
      -0.044318221509456635,
      -0.061709027737379074,
      0.05749716982245445,
      -0.013284401036798954,
      0.018219750374555588,
      0.025443438440561295,
      0.027493860572576523,
      -0.03421703726053238,
      -0.03468600660562515,
      -0.015805477276444435,
      0.07476385682821274,
      -0.054595910012722015,
      0.0716732069849968,
      -0.007752543780952692,
      -0.015213916078209877,
      -0.01684553734958172,
      -0.05135241150856018,
      0.04628228023648262,
      0.024509649723768234,
      -0.05215759202837944,
      0.0668121725320816,
      0.0487903468310833,
      -0.007670559920370579,
      -0.024751942604780197,
      -0.01326021458953619,
      -0.08154566586017609,
      -0.1266755610704422,
      -0.004791184328496456,
      -0.01830464042723179,
      -0.046434007585048676,
      0.032103877514600754,
      -0.025901885703206062,
      0.005424973554909229,
      -0.03097868710756302,
      0.0490950345993042,
      -0.032862719148397446,
      -0.04122432321310043,
      -0.013219424523413181,
      -0.018541349098086357,
      -0.0161284226924181,
      -0.01453063078224659,
      -0.005519273225218058,
      -0.026358043774962425,
      -0.055086057633161545,
      0.02808857150375843,
      -0.006500951014459133,
      -0.00016079904162324965,
      -0.013673546724021435,
      0.0751718208193779,
      0.08233259618282318,
      -0.031091339886188507,
      0.06357238441705704,
      -0.05077240243554115,
      0.06746707856655121,
      0.08187508583068848,
      0.009361756034195423,
      -0.13304995000362396,
      -0.0439237505197525,
      0.0010679081315174699,
      0.004099546931684017,
      0.046395737677812576,
      -0.05217531695961952,
      -0.018622398376464844,
      0.05170276388525963,
      -0.0776609405875206,
      0.08317045867443085,
      0.046712249517440796,
      -0.016951756551861763,
      0.020701939240098,
      -0.10801459848880768,
      0.07096300274133682,
      -0.05762937664985657,
      -0.05327350273728371,
      0.05733177065849304,
      -0.0362771637737751,
      -0.0424635224044323,
      0.057913340628147125,
      -0.020551342517137527,
      0.06309812515974045,
      0.08204366266727448,
      -0.048682801425457,
      -0.050790537148714066,
      -0.01424356922507286,
      0.002690443303436041,
      0.100163035094738,
      -0.05137871950864792,
      0.01909736916422844,
      -0.03514128550887108,
      0.010144660249352455,
      0.04267524927854538,
      0.03898206353187561,
      -0.024778570979833603,
      0.03729845955967903,
      0.014847841113805771,
      0.025810163468122482,
      -0.056464552879333496,
      -0.014736170880496502,
      -0.030558710917830467,
      0.023884868249297142,
      -0.06248430162668228,
      0.06429790705442429,
      -0.00044603514834307134,
      0.0691051036119461,
      0.0260425116866827,
      0.044624749571084976,
      -0.025287356227636337,
      0.018040025606751442,
      0.06094632297754288,
      -0.012706169858574867,
      0.026470353826880455,
      0.03602367639541626,
      -0.008763335645198822,
      0.07110562920570374,
      -0.023682620376348495,
      -0.0021648721303790808,
      -0.06767330318689346,
      0.0355563685297966,
      -0.06270259618759155,
      -0.014047004282474518,
      0.05550343915820122,
      0.03747713193297386,
      -0.020210864022374153,
      0.018491774797439575,
      -0.003737210063263774,
      0.01046316884458065,
      0.01176134217530489,
      0.047505754977464676,
      -0.05593162029981613,
      0.08803200721740723,
      -0.07746009528636932,
      -0.01912890560925007,
      -0.08249816298484802,
      0.020935984328389168,
      -0.1269579529762268,
      0.036977656185626984,
      -0.0020069817546755075,
      -0.03565136715769768,
      0.011785327456891537,
      -0.009301289916038513,
      0.047701988369226456,
      -0.024777771905064583,
      -0.023592263460159302,
      0.015148651786148548,
      -0.06509502977132797,
      0.0011651579989120364,
      -0.05455120652914047,
      -0.056057173758745193,
      -0.022904228419065475,
      -0.008644931018352509,
      0.022415192797780037,
      -0.029518000781536102,
      0.03849766030907631,
      0.07651893049478531,
      -0.055596787482500076,
      0.053031910210847855,
      0.00391295924782753,
      0.0466427356004715,
      0.060626957565546036,
      -0.004098108038306236,
      -0.005762510001659393,
      0.02244454436004162,
      -0.04884381592273712,
      0.028207872062921524,
      0.05512530729174614,
      -0.001860213465988636,
      -0.020861735567450523,
      -0.03168811649084091,
      0.03251763433218002,
      -0.022035472095012665,
      0.010280667804181576,
      -0.0015239426866173744,
      -0.007588534150272608,
      -0.013187672942876816,
      -0.04787798598408699,
      0.05579521134495735,
      0.027537520974874496,
      -0.041886068880558014,
      -0.025108609348535538,
      0.07583493739366531,
      0.016902325674891472,
      0.005226362496614456,
      0.06709916889667511,
      -0.01669890061020851,
      -0.013370427303016186,
      0.03296305984258652,
      0.05689522996544838,
      0.028918741270899773,
      -0.06814008206129074,
      -0.030158095061779022,
      -0.02944903075695038,
      0.0485786534845829,
      0.018261021003127098,
      -0.001846930361352861,
      -0.07464619725942612,
      0.036871287971735,
      0.003550955792888999,
      -0.03027218207716942,
      0.0538201741874218,
      -0.008286583237349987,
      0.008737592026591301,
      -0.020736075937747955,
      0.07275587320327759,
      -0.015740754082798958,
      0.09124072641134262,
      -0.020110029727220535,
      0.023346394300460815,
      -0.03371811285614967,
      0.01913081482052803,
      -0.022513799369335175,
      -0.06836456060409546,
      -0.011355003342032433,
      0.15157541632652283,
      0.0005439122905954719,
      0.001186411245726049,
      -0.02575027197599411,
      -0.01584205962717533,
      -0.05909318849444389,
      0.03860212862491608,
      0.00037392464582808316,
      -0.01075639110058546,
      0.021205835044384003,
      -0.025021396577358246,
      0.03909007087349892,
      -0.050147783011198044,
      0.03615066409111023,
      -0.002557758241891861,
      -0.014769764617085457,
      0.015615644864737988,
      0.02175929769873619,
      0.02429315261542797,
      0.03872046247124672,
      -0.02069763094186783,
      -0.028826609253883362,
      -0.0411684550344944,
      -0.05661177635192871,
      0.04042487218976021,
      -0.00490197166800499,
      -0.06920405477285385,
      -0.04781080037355423,
      0.03383975476026535,
      0.0312486719340086,
      -0.07447583973407745,
      -0.06341905891895294,
      -0.03628504276275635,
      -0.006511526182293892,
      0.007408818230032921,
      -0.04291320964694023,
      0.03377507999539375,
      0.013919084332883358,
      0.0014146978501230478,
      0.02700447291135788,
      -0.06242486089468002,
      -0.011004511266946793,
      0.015152577310800552,
      -0.0227722879499197,
      0.05931135639548302,
      0.011198543943464756,
      -0.004888391587883234,
      0.01077071949839592,
      0.03232239931821823,
      0.019521944224834442,
      -0.05189898610115051,
      0.03596347197890282,
      0.08857842534780502,
      -0.04575997218489647,
      0.032440315932035446,
      0.04654412716627121,
      -0.01372772827744484,
      -0.06906520575284958,
      -0.000540063832886517,
      0.0026229757349938154,
      0.0348120778799057,
      -0.021529804915189743,
      -0.007469854783266783,
      -0.006560641340911388,
      0.017945045605301857,
      -0.0504322424530983,
      -0.036728519946336746,
      -0.016493801027536392,
      -0.12082086503505707,
      -0.025283122435212135,
      0.07215746492147446,
      0.0012003809679299593,
      -0.12751621007919312,
      0.07285599410533905,
      -0.0017816259060055017,
      -0.0528462752699852,
      0.017975782975554466,
      -0.03570941090583801,
      -0.018184253945946693,
      -0.05174983665347099,
      0.029922978952527046,
      -0.09949751198291779,
      0.00942232459783554,
      -0.0032935275230556726,
      0.030514052137732506,
      -0.0517200231552124,
      0.0066614896059036255,
      -0.06367391347885132,
      0.08388347923755646,
      -0.047883760184049606,
      -0.020834622904658318,
      0.10219363123178482,
      0.03327229991555214,
      0.041275039315223694,
      0.017147043719887733,
      -0.07019972801208496,
      0.10800755023956299,
      -0.07572000473737717,
      0.06131310760974884,
      -0.04191838577389717,
      0.04850573465228081,
      -0.05464799329638481,
      0.05146351456642151,
      -0.039016421884298325,
      0.0326484851539135,
      -0.002247611526399851,
      -0.02656858041882515,
      0.07345563918352127,
      0.049702420830726624,
      0.057231493294239044,
      -0.026431923732161522,
      0.07401641458272934,
      -0.052223335951566696,
      -0.014882374554872513,
      -0.01231989823281765,
      -0.03091293014585972,
      0.04604106396436691,
      -0.06205366179347038,
      -0.0007978768553584814,
      0.019808582961559296,
      -0.025156203657388687,
      0.0864080861210823,
      -0.0653490349650383,
      -0.09849094599485397,
      -0.04775145649909973,
      -0.04923698678612709,
      -0.008056449703872204,
      0.0093068927526474,
      -0.002244764007627964,
      -0.014560178853571415,
      0.0789763480424881,
      0.02853943035006523,
      -0.01307139452546835,
      0.017535049468278885,
      0.05294906720519066,
      -0.0268807765096426,
      0.013283167965710163,
      0.05263660103082657,
      0.03679555654525757,
      0.026858750730752945,
      -0.05331208184361458,
      0.02016831561923027,
      -0.0039937859401106834,
      0.08104917407035828,
      -0.03925127536058426,
      0.011208298616111279,
      0.002074316143989563,
      0.015235651284456253,
      -0.04061194881796837,
      -0.07700053602457047,
      0.03682172670960426,
      -0.023323917761445045,
      0.02290814183652401,
      -0.1327679455280304,
      -0.07110070437192917,
      0.005338271148502827,
      0.0008856228087097406,
      -0.022479942068457603,
      0.08229473978281021,
      -0.02162482775747776,
      -0.008500074036419392,
      0.049127932637929916,
      0.012850568629801273,
      0.07124222069978714,
      -0.08965127915143967,
      -0.01523103378713131,
      -0.012051587924361229,
      0.012021037749946117,
      -0.009505908004939556,
      -0.004600899759680033,
      0.027776602655649185,
      -0.013073816895484924,
      -0.06457950919866562,
      0.03277210518717766,
      0.06491425633430481,
      0.02598179131746292,
      0.018745923414826393,
      -0.051718421280384064,
      0.0018677124753594398,
      0.020528586581349373,
      0.02449008636176586,
      0.031194308772683144,
      0.06744101643562317,
      0.02543003484606743,
      -0.053940095007419586,
      0.033617135137319565,
      0.045664653182029724,
      0.05081827566027641,
      -0.02103104256093502,
      0.12289267778396606,
      -0.12880772352218628,
      0.010512927547097206,
      0.02905622310936451,
      -0.017391890287399292,
      0.04327666759490967,
      -0.00328925927169621,
      0.029614323750138283,
      0.05303587764501572,
      -0.012563230469822884,
      0.00852684024721384,
      0.05513951927423477,
      0.0302443765103817,
      -0.06421145796775818,
      -0.014121102169156075,
      -0.02804396115243435,
      -0.008814528584480286,
      -0.03715665265917778,
      0.004688335116952658,
      0.09067746996879578,
      0.002643556334078312
    ],
    [
      -0.023826012387871742,
      0.05162987858057022,
      -0.051592692732810974,
      -0.05914854258298874,
      0.001844927086494863,
      -0.045735396444797516,
      0.006073041819036007,
      -0.023932429030537605,
      -0.03635244816541672,
      -0.010846507735550404,
      -0.008931497111916542,
      -0.11255384981632233,
      0.007236779201775789,
      0.005798553116619587,
      -0.04668532684445381,
      0.002153614303097129,
      -0.01486292015761137,
      -0.02213306911289692,
      -0.00770279485732317,
      -0.016922248527407646,
      -0.037712547928094864,
      0.04393947124481201,
      -0.0072171008214354515,
      -0.009485626593232155,
      -0.030175309628248215,
      0.010999615304172039,
      0.023081304505467415,
      0.032042451202869415,
      -0.11516786366701126,
      -0.03325626626610756,
      -0.02119200862944126,
      -0.0788094624876976,
      -0.027333097532391548,
      0.03195356950163841,
      0.06485942751169205,
      -0.10565873980522156,
      -0.03543725237250328,
      -0.009021471254527569,
      -0.04945802688598633,
      0.045390766113996506,
      0.0851994976401329,
      0.011981953866779804,
      0.05738528072834015,
      -0.018473509699106216,
      0.0296785831451416,
      0.025061827152967453,
      0.037730298936367035,
      -0.00228128838352859,
      -0.0009211097494699061,
      0.0417025052011013,
      -0.04871749505400658,
      -0.004245522432029247,
      0.06214655190706253,
      0.08456958830356598,
      0.02619284950196743,
      -0.0006256034248508513,
      -0.009238779544830322,
      -0.044152483344078064,
      -0.01700500212609768,
      0.09537596255540848,
      0.02908281609416008,
      -0.03679604083299637,
      -0.01774272695183754,
      -0.005538198631256819,
      0.006508447229862213,
      0.043095510452985764,
      0.04493795335292816,
      0.05792322754859924,
      -0.00013447525270748883,
      -0.03101384826004505,
      -0.05323917791247368,
      -0.032004740089178085,
      0.07623297721147537,
      -0.056952930986881256,
      0.016795765608549118,
      0.04560646042227745,
      -0.07816171646118164,
      0.038306836038827896,
      0.048341311514377594,
      0.050023116171360016,
      -0.028159767389297485,
      -0.0441976934671402,
      -0.05113332346081734,
      0.005842466372996569,
      0.045376285910606384,
      0.05328967422246933,
      -0.05678622052073479,
      0.008934561163187027,
      0.03218134865164757,
      -0.05513666942715645,
      0.03904427960515022,
      0.010756422765552998,
      -0.027267880737781525,
      -0.0004107776330783963,
      0.06408537924289703,
      -0.018829654902219772,
      0.008233942091464996,
      0.012195325456559658,
      -0.03759555146098137,
      0.08092992007732391,
      0.008084794506430626,
      0.013662227429449558,
      -0.010497437790036201,
      0.0717458426952362,
      0.0009606254752725363,
      0.09765613824129105,
      0.005469615571200848,
      -0.04967601224780083,
      -0.08235087245702744,
      0.06847638636827469,
      0.0003685197443701327,
      -0.00815650261938572,
      0.004699749872088432,
      0.02508099004626274,
      -0.03637974336743355,
      0.020023267716169357,
      -0.05101478099822998,
      0.030217014253139496,
      -0.009513121098279953,
      0.04157537966966629,
      -0.038169171661138535,
      0.028930023312568665,
      -0.014250572770833969,
      0.06732193380594254,
      0.002877830294892192,
      0.03304128721356392,
      0.015447867102921009,
      -0.0195351280272007,
      0.00852018129080534,
      -0.08161948621273041,
      -0.0186476893723011,
      0.03733285516500473,
      0.05238296836614609,
      -0.013221435248851776,
      0.008495771326124668,
      -0.0863346979022026,
      0.05847034975886345,
      -0.08395973592996597,
      -0.03797085955739021,
      0.015414442867040634,
      -0.022175580263137817,
      0.025088876485824585,
      0.052707239985466,
      -0.016150878742337227,
      -0.03491964936256409,
      -0.014180731028318405,
      0.050583623349666595,
      -0.0576084703207016,
      0.029546571895480156,
      0.010733616538345814,
      0.042652539908885956,
      -0.005325245670974255,
      0.011381329968571663,
      -0.09832911193370819,
      -0.0302275437861681,
      0.015003662556409836,
      -0.006242020055651665,
      0.07807435095310211,
      0.010065421462059021,
      0.039866119623184204,
      0.060052238404750824,
      0.004929139744490385,
      -0.060887131839990616,
      0.015833953395485878,
      -0.08244886249303818,
      0.04957597330212593,
      -0.016616780310869217,
      -0.00672949617728591,
      -0.026773907244205475,
      -0.03468155488371849,
      0.038427188992500305,
      -0.04343660548329353,
      0.016449617221951485,
      0.02189233899116516,
      0.0362984724342823,
      -0.04573429375886917,
      0.005733628291636705,
      0.040364351123571396,
      0.0636836513876915,
      -0.046984829008579254,
      0.03791752830147743,
      -0.023423446342349052,
      0.04217974841594696,
      -0.005275968462228775,
      -0.028685901314020157,
      -0.052503425627946854,
      -0.038728196173906326,
      -0.012092777527868748,
      -0.03983049839735031,
      0.04525531083345413,
      -0.05911241099238396,
      0.06372656673192978,
      -0.004699392709881067,
      -0.05921859294176102,
      -0.0456622876226902,
      -0.027461761608719826,
      0.05023246631026268,
      -0.016865061596035957,
      -0.06399079412221909,
      0.09038148075342178,
      -0.016541797667741776,
      -0.00277553778141737,
      -0.001509221037849784,
      0.022364100441336632,
      0.020448898896574974,
      0.11617270857095718,
      -0.07430167496204376,
      -0.05228285491466522,
      0.0034152197185903788,
      0.014862393029034138,
      -0.006867682095617056,
      -0.022845344617962837,
      -0.03523774817585945,
      0.059844907373189926,
      0.0055036600679159164,
      -0.004395066294819117,
      -0.03443767875432968,
      -0.06444629281759262,
      0.01903573051095009,
      0.022596951574087143,
      0.017371781170368195,
      -0.005101856775581837,
      0.001867638318799436,
      0.05971384048461914,
      -0.003375610336661339,
      0.03162360563874245,
      -0.046434201300144196,
      0.030215222388505936,
      -0.047650296241045,
      -0.04133947193622589,
      -0.005374584347009659,
      -0.050045937299728394,
      -0.05358235165476799,
      0.04358042776584625,
      0.010174364782869816,
      -0.02117307297885418,
      -0.031835004687309265,
      0.013863597065210342,
      0.058317914605140686,
      -0.04165487363934517,
      -0.012954723089933395,
      -0.047653764486312866,
      0.00710353534668684,
      -0.0014000816736370325,
      0.0875859409570694,
      -0.05960783362388611,
      0.07040873169898987,
      0.015373630449175835,
      -0.016172070056200027,
      0.011143392883241177,
      -0.06295273452997208,
      0.05915060639381409,
      0.0313287153840065,
      0.03870565816760063,
      -0.03685833513736725,
      0.008355870842933655,
      -0.032903317362070084,
      0.014935550279915333,
      -0.04002426937222481,
      0.060009609907865524,
      0.03583512082695961,
      0.03064640238881111,
      0.017009107396006584,
      -0.06571099162101746,
      -0.029058918356895447,
      -0.026559971272945404,
      -0.0016429401002824306,
      0.012323819100856781,
      -0.014684724621474743,
      0.054789185523986816,
      0.021349605172872543,
      0.0021380658727139235,
      0.026188259944319725,
      0.09192734211683273,
      0.002793338615447283,
      -0.06182033568620682,
      0.0027766532730311155,
      -0.10675383359193802,
      -0.05270581692457199,
      0.05229942500591278,
      -0.030933823436498642,
      0.042572490870952606,
      -0.0009994100546464324,
      0.029303964227437973,
      -0.058338552713394165,
      0.027736162766814232,
      -0.04240620881319046,
      -0.05242903530597687,
      0.00027164374478161335,
      0.029242683202028275,
      0.08195069432258606,
      -0.028856562450528145,
      -0.04174189269542694,
      -0.02229488454759121,
      -0.06663714349269867,
      -0.007900403812527657,
      -0.016387565061450005,
      0.020441418513655663,
      0.002169594634324312,
      0.021820245310664177,
      -0.05607524514198303,
      -0.00017087948799598962,
      -0.03579791262745857,
      -0.09119253605604172,
      -0.08735828101634979,
      -0.0029738338198512793,
      0.05792586877942085,
      -0.04038075730204582,
      -0.09235461056232452,
      0.0067319744266569614,
      0.07138047367334366,
      0.00986347533762455,
      0.04003187641501427,
      -0.04876216500997543,
      0.08004223555326462,
      0.030076628550887108,
      -0.015093352645635605,
      -0.011034187860786915,
      -0.0015573181444779038,
      0.04034752398729324,
      -0.024694161489605904,
      -0.00868376437574625,
      0.04198891669511795,
      -0.0041919611394405365,
      0.01052160281687975,
      -0.022059354931116104,
      0.02591835707426071,
      -0.07980287820100784,
      0.042470935732126236,
      0.04582008346915245,
      -0.00603107362985611,
      0.00978938490152359,
      -0.003946965094655752,
      -0.002000165171921253,
      -0.0017923957202583551,
      0.02916407398879528,
      0.032725512981414795,
      -0.004992306232452393,
      0.04495452716946602,
      0.05204332619905472,
      0.04174818471074104,
      0.002197108929976821,
      -0.02671268954873085,
      0.041247352957725525,
      0.042703062295913696,
      -0.047245487570762634,
      -0.04615882784128189,
      0.06721659004688263,
      0.05063871294260025,
      -0.040610797703266144,
      -0.03210059925913811,
      0.02873314917087555,
      0.07166250795125961,
      0.01619894616305828,
      0.04118480160832405,
      0.04073866084218025,
      0.05809512361884117,
      -0.046349573880434036,
      -0.004858998116105795,
      0.05380840227007866,
      0.07876727730035782,
      -0.044217921793460846,
      0.06273011118173599,
      0.030157508328557014,
      0.0413912832736969,
      -0.02536429651081562,
      0.08393057435750961,
      0.006363791413605213,
      0.0399162657558918,
      -8.360470383195207e-05,
      -0.06999274343252182,
      0.006904006935656071,
      0.01611395925283432,
      -0.01740051433444023,
      0.0041590044274926186,
      -0.007739141583442688,
      -0.0264123547822237,
      0.06548900902271271,
      -0.04618951305747032,
      -0.024412289261817932,
      -0.030605824664235115,
      0.049583084881305695,
      0.03081846423447132,
      0.07128313183784485,
      -0.01071238238364458,
      0.00344918854534626,
      -0.06686171889305115,
      -0.019082194194197655,
      -0.04442410543560982,
      0.03799080476164818,
      -0.013454790227115154,
      0.04226716235280037,
      0.00590558210387826,
      -0.022212129086256027,
      0.033849235624074936,
      0.012193430215120316,
      -0.08890345692634583,
      0.07204295694828033,
      0.012662682682275772,
      -0.025461360812187195,
      -0.031201129779219627,
      0.013044042512774467,
      -0.01622898131608963,
      0.08166806399822235,
      0.14015015959739685,
      -0.08426263928413391,
      0.07347428798675537,
      -0.039673272520303726,
      -0.06219399347901344,
      -0.04882011190056801,
      0.10327590256929398,
      -0.014548699371516705,
      0.07454299181699753,
      0.017320293933153152,
      0.009819013997912407,
      0.019162869080901146,
      0.010839511640369892,
      -0.010431043803691864,
      -0.0301738940179348,
      0.04193907603621483,
      -0.017144305631518364,
      -0.06875540316104889,
      0.04262720048427582,
      -0.008140279911458492,
      -0.08111260086297989,
      -0.013615952804684639,
      0.05124857276678085,
      -0.010893188416957855,
      -0.028081383556127548,
      0.007848438806831837,
      0.024100352078676224,
      0.07121483981609344,
      0.016601867973804474,
      -0.00036590645322576165,
      0.025251317769289017,
      0.06271439790725708,
      0.0017167809419333935,
      -0.09303311258554459,
      0.029163353145122528,
      0.016261717304587364,
      -0.0003137839667033404,
      -0.02461014874279499,
      -0.0425749346613884,
      0.04659944772720337,
      -0.011324149556457996,
      -0.04075107350945473,
      0.026771869510412216,
      -0.042162954807281494,
      -0.03176174685359001,
      0.029170220717787743,
      -0.08808254450559616,
      0.016534246504306793,
      0.015781220048666,
      -0.0005768427508883178,
      -0.042299434542655945,
      0.04547785967588425,
      0.026074595749378204,
      0.029629208147525787,
      -0.06368549168109894,
      0.008065862581133842,
      -0.03994543105363846,
      -0.01408373098820448,
      0.04033040255308151,
      -0.10891999304294586,
      0.006395651027560234,
      0.045197371393442154,
      0.06581620126962662,
      -0.06235385313630104,
      -0.044860225170850754,
      -0.04532543942332268,
      0.0038782500196248293,
      0.036395274102687836,
      7.943839591462165e-05,
      0.04492083564400673,
      0.005248589441180229,
      -0.006550958845764399,
      0.05223202332854271,
      0.029519416391849518,
      -0.02472873404622078,
      -0.0173032283782959,
      0.019492579624056816,
      -0.004704579245299101,
      0.0021196119487285614,
      -0.008158182725310326,
      -0.004623098764568567,
      0.02020278200507164,
      -0.003552571637555957,
      -0.033794138580560684,
      0.042891621589660645,
      0.01755683124065399,
      0.024241114035248756,
      -0.02642568200826645,
      0.019052620977163315,
      0.0071174148470163345,
      0.03571917861700058,
      0.07347763329744339,
      -0.03356515243649483,
      -0.02977922558784485,
      -0.0674528107047081,
      0.0027687866240739822,
      -0.01780862547457218,
      -0.020186709240078926,
      0.0797523781657219
    ],
    [
      -0.018161846324801445,
      0.05592142418026924,
      0.03676934540271759,
      -0.009640172123908997,
      -0.00845620408654213,
      -0.05666615813970566,
      -0.020896323025226593,
      -0.07563202828168869,
      0.04922916367650032,
      0.015171751379966736,
      -0.013831330463290215,
      0.005901298951357603,
      -0.05801190808415413,
      0.03033396601676941,
      -0.03928309306502342,
      -0.030563941225409508,
      -0.033111318945884705,
      0.030194200575351715,
      0.0763612762093544,
      0.0012006651377305388,
      -0.026456307619810104,
      -0.0174686498939991,
      -0.05249397084116936,
      0.04274727776646614,
      -0.0747285708785057,
      0.07556812465190887,
      0.023314042016863823,
      0.07253409177064896,
      0.039687760174274445,
      -0.028113313019275665,
      0.03777856007218361,
      -0.005799329839646816,
      -0.03991039842367172,
      -0.03850414231419563,
      -0.0629870742559433,
      -0.02027798257768154,
      -0.08267854899168015,
      -0.039948273450136185,
      0.024596625939011574,
      0.03512613847851753,
      -0.03702668845653534,
      0.04203539714217186,
      0.09994777292013168,
      -0.055876489728689194,
      0.02399270236492157,
      0.07220300287008286,
      0.026550812646746635,
      -0.02945348061621189,
      0.004817158915102482,
      -0.0631343424320221,
      -0.056330662220716476,
      0.07465733587741852,
      0.06714431941509247,
      -0.061413537710905075,
      0.05532464385032654,
      -0.011426244862377644,
      0.05820930376648903,
      0.015005502849817276,
      -0.03728923946619034,
      -0.05069093406200409,
      0.0640922486782074,
      -0.004022777080535889,
      0.047158561646938324,
      -0.06644747406244278,
      -0.051851604133844376,
      -0.004149404354393482,
      -0.07166002690792084,
      -0.030807554721832275,
      -0.0409240648150444,
      -0.030812950804829597,
      0.00967857614159584,
      0.04149814322590828,
      -0.003897465765476227,
      -0.04800707846879959,
      -0.060728464275598526,
      -0.010381017811596394,
      -0.04780822619795799,
      -0.012950999662280083,
      -0.002878001192584634,
      0.003495246171951294,
      0.07664373517036438,
      0.03552514314651489,
      -0.01496871467679739,
      -0.03272072970867157,
      0.034450236707925797,
      0.009067388251423836,
      -0.023762816563248634,
      -0.034678421914577484,
      -0.0501590371131897,
      -0.0639842301607132,
      0.02532562054693699,
      -0.04551444202661514,
      -0.031741466373205185,
      0.02804921194911003,
      -0.014412733726203442,
      0.06139837205410004,
      -0.03619110584259033,
      -0.012791641056537628,
      0.05782270431518555,
      -0.07602036744356155,
      0.09203390777111053,
      -0.003951966762542725,
      0.046747393906116486,
      -0.01172255165874958,
      -0.01598912477493286,
      -0.05021114647388458,
      0.001370293670333922,
      -0.02806057222187519,
      -0.03222918510437012,
      0.024765102192759514,
      -0.019160693511366844,
      -0.017122507095336914,
      -0.00069007882848382,
      -0.017045218497514725,
      0.04008357226848602,
      -0.03515129163861275,
      -0.06538218259811401,
      -0.013131043873727322,
      -0.021603655070066452,
      -0.029648253694176674,
      0.018882935866713524,
      0.021388007327914238,
      -0.019890984520316124,
      -0.006997946184128523,
      -0.017536327242851257,
      -0.080169178545475,
      -0.030502984300255775,
      0.032866816967725754,
      -0.040200766175985336,
      -0.015246973372995853,
      -0.01771126314997673,
      -0.06479697674512863,
      0.017700085416436195,
      -0.06741073727607727,
      0.024293575435876846,
      -0.0037668414879590273,
      -0.05218403786420822,
      -0.10908965021371841,
      -0.02425958588719368,
      0.040800437331199646,
      -0.06678744405508041,
      -0.012250018306076527,
      0.07483501732349396,
      0.024327760562300682,
      -0.11231199651956558,
      -0.0505158007144928,
      -0.057804424315690994,
      0.011427229270339012,
      0.03937869891524315,
      -0.06564227491617203,
      0.0103944456204772,
      -0.010862687602639198,
      0.007302882149815559,
      -0.05478615686297417,
      -0.014429916627705097,
      0.042063746601343155,
      -0.06438242644071579,
      0.0535597987473011,
      0.036557637155056,
      0.04236281290650368,
      0.023306114599108696,
      0.02763558365404606,
      -0.0004329067887738347,
      -0.03660263866186142,
      -0.044036269187927246,
      0.08096195757389069,
      0.06585661321878433,
      -0.08561509847640991,
      0.041375793516635895,
      0.001703826361335814,
      0.10135825723409653,
      0.015288671478629112,
      -0.052440714091062546,
      0.025921553373336792,
      -0.0147681450471282,
      -0.06211468577384949,
      0.04478904977440834,
      -0.02349911257624626,
      0.09284820407629013,
      0.06035824865102768,
      0.03581112250685692,
      -0.04875662922859192,
      0.05592665821313858,
      0.0023877695202827454,
      -0.09945828467607498,
      0.00884614884853363,
      -0.040606170892715454,
      0.004560195375233889,
      0.06386391073465347,
      -0.090456523001194,
      0.023948324844241142,
      0.0352388359606266,
      -0.08029473572969437,
      0.013423970900475979,
      -0.003488347865641117,
      0.01759193278849125,
      -0.009618565440177917,
      0.030545741319656372,
      0.013071202673017979,
      0.036877021193504333,
      -0.023343542590737343,
      -0.0022847859654575586,
      -0.07302872836589813,
      0.06238681077957153,
      -0.0008048790623433888,
      -0.07032284885644913,
      -0.06191784143447876,
      -0.04908169060945511,
      -0.006897770334035158,
      0.036720022559165955,
      0.05930357426404953,
      0.035799164324998856,
      -0.030197016894817352,
      -0.06791242212057114,
      -0.005192685406655073,
      0.016626644879579544,
      0.001121698645874858,
      -0.009699949063360691,
      -0.024637963622808456,
      -0.01812821254134178,
      -0.015527602285146713,
      0.0952170267701149,
      -0.013819417916238308,
      0.0370560921728611,
      -0.01944112777709961,
      0.04245326668024063,
      -0.02195456065237522,
      -0.05466616153717041,
      -0.04608440399169922,
      -0.04296875,
      0.005085613578557968,
      -0.000863973400555551,
      0.038030873984098434,
      -0.03491213545203209,
      -0.0401952788233757,
      0.0019156119087710977,
      0.035197969526052475,
      0.006739281117916107,
      -0.018856238573789597,
      0.06037619709968567,
      -0.043340541422367096,
      -0.009996464475989342,
      -0.05350254103541374,
      -0.08872916549444199,
      0.028448594734072685,
      -0.08137772232294083,
      -0.032446764409542084,
      -0.018269767984747887,
      0.015131030231714249,
      0.007100889924913645,
      0.013978319242596626,
      0.050124697387218475,
      0.016530776396393776,
      -0.004677753429859877,
      0.0167380440980196,
      0.03395095095038414,
      0.012922092340886593,
      -0.04016554355621338,
      -0.08686653524637222,
      -0.03681264817714691,
      -0.048291731625795364,
      0.014106528833508492,
      0.06555265188217163,
      -0.006334208883345127,
      -0.07385223358869553,
      0.06703005731105804,
      0.01842932030558586,
      -0.032771967351436615,
      0.0035731098614633083,
      -0.019586186856031418,
      -0.00939943641424179,
      -0.02829233929514885,
      0.001221420825459063,
      -0.09122471511363983,
      -0.006018008571118116,
      -0.010582997463643551,
      -0.002838783897459507,
      -0.009313576854765415,
      -0.03154913708567619,
      -0.006552030798047781,
      -0.06296850740909576,
      0.06032479181885719,
      -0.03720422834157944,
      -0.04892713204026222,
      0.015297445468604565,
      0.09909330308437347,
      0.010691070929169655,
      -0.01946542225778103,
      -0.010907393880188465,
      0.014079442247748375,
      0.003913185093551874,
      0.01806732639670372,
      -0.04149042069911957,
      -0.044668179005384445,
      0.01023842766880989,
      0.006379502825438976,
      -0.027653822675347328,
      0.02604020945727825,
      -0.030520368367433548,
      -0.0340033657848835,
      -0.08410919457674026,
      0.04613944888114929,
      -0.08493947982788086,
      -0.0018154835561290383,
      0.0019653569906949997,
      -0.05615474283695221,
      0.0246359221637249,
      -0.06364864856004715,
      0.038341499865055084,
      0.08101440221071243,
      0.04154103621840477,
      -0.03798549994826317,
      -0.01730296015739441,
      -0.05572083964943886,
      -0.03898825868964195,
      -0.04102025553584099,
      0.027669917792081833,
      0.025356393307447433,
      0.11738284677267075,
      0.02797878161072731,
      0.019199728965759277,
      0.01318329107016325,
      -0.12178634852170944,
      -0.009177976287901402,
      -0.031623415648937225,
      0.02141173928976059,
      0.04175105318427086,
      0.0085267573595047,
      0.10826496034860611,
      -0.009744652546942234,
      -0.06807556003332138,
      -0.03602438047528267,
      -0.01968388818204403,
      0.04566054791212082,
      0.08691273629665375,
      -0.047994405031204224,
      -0.028116144239902496,
      0.04595158249139786,
      -0.07601087540388107,
      -0.008981726132333279,
      -0.06271125376224518,
      0.03661949932575226,
      -0.01018891204148531,
      -0.056466761976480484,
      0.029922321438789368,
      -0.003167068585753441,
      -0.033995069563388824,
      0.008949914015829563,
      0.0784851461648941,
      -0.003561638528481126,
      -0.037800319492816925,
      0.03404003381729126,
      -0.059923090040683746,
      0.026133626699447632,
      0.03169171139597893,
      0.0496797077357769,
      0.020812567323446274,
      0.015524632297456264,
      -0.009899168275296688,
      0.021653257310390472,
      -0.004285007249563932,
      0.03846371918916702,
      0.001124480040743947,
      -0.031066223978996277,
      0.029949139803647995,
      -0.02000018022954464,
      -0.06517241895198822,
      -0.007689888123422861,
      0.052980296313762665,
      0.040211308747529984,
      0.007505183108150959,
      -0.02465750277042389,
      0.017270822077989578,
      0.02328016608953476,
      -0.009165608324110508,
      -0.07015574723482132,
      -0.0318562351167202,
      -0.05843658000230789,
      -0.08250630646944046,
      -0.002679270226508379,
      -0.08561502397060394,
      -0.027760574594140053,
      -0.008256406523287296,
      0.04250183328986168,
      0.025562038645148277,
      -0.06698808073997498,
      0.01307626347988844,
      0.03349224850535393,
      0.07141289860010147,
      0.029365262016654015,
      0.06829960644245148,
      0.04481056332588196,
      -0.036418866366147995,
      0.011279572732746601,
      0.016018087044358253,
      0.05087779462337494,
      -0.06313158571720123,
      -0.046869467943906784,
      -0.04047218710184097,
      -0.03604866564273834,
      0.015003286302089691,
      0.04678434133529663,
      -0.0034581522922962904,
      0.04549282416701317,
      -0.044803060591220856,
      0.08035220205783844,
      -0.008047726936638355,
      -0.021808737888932228,
      0.07237416505813599,
      0.048472125083208084,
      -0.006727452855557203,
      -0.04905911907553673,
      0.038541194051504135,
      -0.042779047042131424,
      -0.0244353748857975,
      0.03165358304977417,
      -0.01088942214846611,
      -0.022253140807151794,
      -0.022626884281635284,
      -0.00309245684184134,
      0.04559427127242088,
      0.013082277961075306,
      -0.01533574890345335,
      0.005629616789519787,
      0.011343356221914291,
      -0.01836925372481346,
      0.011292756535112858,
      -0.03325928375124931,
      -0.03018224984407425,
      0.013530511409044266,
      -0.039306074380874634,
      0.022090204060077667,
      -0.0013043801300227642,
      -0.01003508921712637,
      0.01099410466849804,
      0.027484124526381493,
      -0.06682577729225159,
      -0.01856314204633236,
      0.017766496166586876,
      -0.0245712548494339,
      -0.04422052949666977,
      0.05020326375961304,
      -0.08867388963699341,
      0.0552399568259716,
      -0.03813726454973221,
      -0.08029385656118393,
      0.11825156956911087,
      0.07648444920778275,
      0.006104650907218456,
      0.032433561980724335,
      -0.12714079022407532,
      -0.0330122672021389,
      -0.08209256827831268,
      -0.04559136554598808,
      0.04796992242336273,
      0.0008889695745892823,
      0.03658941388130188,
      0.05628208816051483,
      0.008294531144201756,
      0.026576032862067223,
      -0.08560983091592789,
      0.05204525962471962,
      -0.03560970351099968,
      -0.07845649868249893,
      0.021545669063925743,
      -0.005589463748037815,
      0.01036873273551464,
      0.049771301448345184,
      -0.03699879348278046,
      -0.08894988149404526,
      -0.06257432699203491,
      -0.01654338277876377,
      -0.08532292395830154,
      -0.023621387779712677,
      0.021929044276475906,
      0.07428071647882462,
      0.06108977645635605,
      -0.08426103740930557,
      -0.06254761666059494,
      0.08046558499336243,
      -0.046642500907182693,
      -0.00032017999910749495,
      0.03196492791175842,
      -0.040469735860824585,
      0.03207694739103317,
      -0.0539964884519577,
      0.04532793164253235,
      0.024713529273867607,
      0.006997447926551104,
      -0.012735401280224323,
      -0.05069742724299431,
      -0.06777830421924591,
      0.04894043132662773,
      -0.016740970313549042,
      -0.028130916878581047,
      0.04435336962342262,
      0.012133135460317135,
      0.08193832635879517,
      -0.015513704158365726,
      0.008429992012679577,
      -0.11161597073078156,
      0.0564301423728466,
      -0.0010120323859155178
    ],
    [
      0.017779579386115074,
      -0.06140780448913574,
      -0.02017109841108322,
      0.03819574415683746,
      -0.00027869208133779466,
      -0.07687772810459137,
      -0.018207143992185593,
      -0.02297457680106163,
      -0.009719129651784897,
      0.0016054571606218815,
      0.012732267379760742,
      -0.001591926091350615,
      -0.07472148537635803,
      -0.0029639299027621746,
      0.03193086385726929,
      -0.13195601105690002,
      0.0061615221202373505,
      -0.008715430274605751,
      -0.0376647487282753,
      -0.036487821489572525,
      0.015054089017212391,
      0.0277464110404253,
      -0.0875583216547966,
      -0.006536583881825209,
      -0.030401041731238365,
      0.06190988048911095,
      -0.05287260562181473,
      -0.06356973201036453,
      -0.14278659224510193,
      -0.07447517663240433,
      0.03548278659582138,
      0.018738139420747757,
      -0.06015012413263321,
      0.05306011810898781,
      0.010373727418482304,
      0.06370963901281357,
      -0.06708980351686478,
      0.03838055953383446,
      0.008514671586453915,
      0.04267430678009987,
      0.11081951856613159,
      -0.010197638534009457,
      0.00887115765362978,
      -0.06385716050863266,
      0.00034597155172377825,
      -0.0014677969738841057,
      -0.038891565054655075,
      -0.00293451058678329,
      -0.05722551792860031,
      0.07438455522060394,
      0.002306695096194744,
      0.009083077311515808,
      0.009678118862211704,
      0.012916399165987968,
      -0.049265533685684204,
      0.018283257260918617,
      -0.05652213841676712,
      -0.03608336299657822,
      0.008407379500567913,
      -0.00508531229570508,
      0.0010293342638760805,
      0.0064141410402953625,
      0.042037151753902435,
      0.06056977063417435,
      -0.0070461248978972435,
      -0.045807864516973495,
      0.09032958000898361,
      0.054755788296461105,
      0.045112866908311844,
      0.017852501943707466,
      0.0021543067414313555,
      0.028752615675330162,
      -0.016577202826738358,
      0.04244992136955261,
      0.040591590106487274,
      0.04685696214437485,
      -0.0035198507830500603,
      0.06696239113807678,
      -0.05241131782531738,
      -0.027292802929878235,
      -0.01128138042986393,
      0.01598018780350685,
      -0.06299395114183426,
      -0.009372242726385593,
      0.036448657512664795,
      0.012966861017048359,
      0.03506002202630043,
      -0.0821453258395195,
      -0.016352536156773567,
      0.08849431574344635,
      -0.0726948082447052,
      0.0007826304063200951,
      -0.0567157119512558,
      0.07424172013998032,
      0.0328078530728817,
      0.024391476064920425,
      0.038761794567108154,
      -0.017852436751127243,
      -0.01912805251777172,
      0.01154126413166523,
      0.05067974328994751,
      0.04505511373281479,
      0.010439924895763397,
      0.0012167581589892507,
      0.07333387434482574,
      -0.008391832932829857,
      -0.04302132502198219,
      -0.03609456494450569,
      -0.0042335838079452515,
      0.01381590124219656,
      0.02803744189441204,
      -0.0808253064751625,
      0.01992761343717575,
      0.010202055796980858,
      0.11079203337430954,
      -0.04523218050599098,
      0.0209294855594635,
      0.003576445858925581,
      -0.036198247224092484,
      -0.09905006736516953,
      -0.04952836409211159,
      0.03546058386564255,
      0.12369083613157272,
      0.03204876184463501,
      -0.0017173724481835961,
      0.026345303282141685,
      0.05008586123585701,
      6.249499710975215e-05,
      0.021533969789743423,
      -0.001818800694309175,
      0.02194211445748806,
      0.0762275755405426,
      0.06721750646829605,
      0.07458878308534622,
      0.005428238306194544,
      -0.026235569268465042,
      0.053079862147569656,
      -0.011433612555265427,
      0.06876815855503082,
      0.03658812865614891,
      -0.03825840726494789,
      -0.007898170500993729,
      0.0006363303982652724,
      0.028527097776532173,
      -0.010464468970894814,
      0.0016058465698733926,
      0.09693612903356552,
      -0.06898183375597,
      -0.00564437173306942,
      0.010706489905714989,
      0.00627629179507494,
      0.01836121827363968,
      0.08517687767744064,
      0.05438168719410896,
      -0.015776386484503746,
      -0.06747379153966904,
      -0.017842844128608704,
      -0.1067505031824112,
      -0.03995233774185181,
      -0.04109622538089752,
      0.09571859240531921,
      -0.05201848968863487,
      -0.01958785019814968,
      -0.016979025676846504,
      -0.013774815946817398,
      -0.038114920258522034,
      0.012405321933329105,
      0.012718027457594872,
      0.018662389367818832,
      -0.06125946342945099,
      0.026957597583532333,
      -0.03750905022025108,
      0.02298104017972946,
      0.03820428252220154,
      0.009106668643653393,
      0.0057968515902757645,
      -0.0025856541469693184,
      0.05393309146165848,
      0.04021286964416504,
      0.011589694768190384,
      -0.05461180582642555,
      0.01086545828729868,
      -0.01465059258043766,
      -0.036029521375894547,
      0.009893115609884262,
      0.0651501789689064,
      -0.04566103592514992,
      -0.0642840787768364,
      0.0322265699505806,
      -0.013212853111326694,
      0.013365711085498333,
      0.037365615367889404,
      -0.0002890175092034042,
      -0.06404782086610794,
      0.027383388951420784,
      -0.016623105853796005,
      0.00034288608003407717,
      0.03157464414834976,
      0.12229059636592865,
      0.04206233471632004,
      -0.030900821089744568,
      0.02357434295117855,
      -0.0150644201785326,
      -0.05165672302246094,
      -0.02283315360546112,
      0.08481249958276749,
      -0.09464989602565765,
      -0.05004129931330681,
      -0.06560008227825165,
      -0.003474492346867919,
      0.015962768346071243,
      0.01571083627641201,
      -0.018690112978219986,
      -0.03519901633262634,
      0.044297900050878525,
      0.01424452569335699,
      0.019360467791557312,
      -0.001433946075849235,
      -0.0680573359131813,
      -0.004467439372092485,
      0.020567093044519424,
      0.11903746426105499,
      -0.06600052118301392,
      -0.06437310576438904,
      -0.006701761856675148,
      0.007952970452606678,
      0.03989316150546074,
      0.007866169326007366,
      0.028121354058384895,
      -0.019396353513002396,
      0.005981264635920525,
      0.07472161203622818,
      0.015441326424479485,
      0.0856475755572319,
      -0.05917838215827942,
      -0.0037958386819809675,
      0.07749098539352417,
      0.022034892812371254,
      0.04235295578837395,
      -0.023135654628276825,
      -0.08632200956344604,
      0.011772222816944122,
      -0.005049575585871935,
      0.012641316279768944,
      0.024822106584906578,
      -0.02719026431441307,
      -0.06875335425138474,
      0.06258110702037811,
      0.029356293380260468,
      0.002020671032369137,
      -0.01283708680421114,
      0.02950109727680683,
      -0.059288036078214645,
      -0.01691371574997902,
      0.09891568124294281,
      0.06843824684619904,
      -0.003299140138551593,
      0.0014953044010326266,
      -0.0472838319838047,
      -0.018453437834978104,
      -0.043079961091279984,
      -0.052630018442869186,
      -0.001943447394296527,
      -0.05592650547623634,
      -0.05200749635696411,
      0.008687776513397694,
      -0.04943126067519188,
      0.024443410336971283,
      -0.04510653764009476,
      -0.017704874277114868,
      0.04523060470819473,
      0.019173918291926384,
      0.0696028396487236,
      -0.08540114015340805,
      -0.022898659110069275,
      -0.04162470996379852,
      0.05448080599308014,
      -0.03850894421339035,
      -0.012785240076482296,
      -0.010737968608736992,
      0.015640469267964363,
      0.045868344604969025,
      -0.023198969662189484,
      -0.04669937863945961,
      -0.003038194961845875,
      -0.00670923525467515,
      0.08751022070646286,
      0.007453019730746746,
      -0.0037965099327266216,
      0.08730939030647278,
      0.0008977050893008709,
      0.023142436519265175,
      -0.05325581133365631,
      0.0050423117354512215,
      -0.004186710342764854,
      0.013126165606081486,
      0.010261120274662971,
      -0.05935309827327728,
      -0.07546825706958771,
      0.04404864087700844,
      0.08117971569299698,
      0.06874391436576843,
      0.0306604765355587,
      -0.0017917798832058907,
      0.034762971103191376,
      -0.07852288335561752,
      0.0066984472796320915,
      -0.0726609155535698,
      -0.021969005465507507,
      0.07019371539354324,
      0.0003822078579105437,
      -0.021206671372056007,
      -0.0261189304292202,
      0.04779829829931259,
      0.01926918886601925,
      0.01824941113591194,
      0.02176150120794773,
      -0.013141410425305367,
      0.10007266700267792,
      0.04784168675541878,
      -0.06274036318063736,
      -0.051654040813446045,
      0.026518069207668304,
      -0.062079962342977524,
      0.017572997137904167,
      0.027065644040703773,
      -0.06704559922218323,
      0.005664041265845299,
      0.0018257482443004847,
      -0.0135385412722826,
      -0.013580244034528732,
      0.014042825438082218,
      0.03199419379234314,
      -0.011213624849915504,
      -0.015711745247244835,
      -0.024971850216388702,
      -0.05749671533703804,
      -0.1147252768278122,
      -0.07104241847991943,
      0.09273407608270645,
      -0.01707093045115471,
      0.007207775954157114,
      0.006137528456747532,
      -0.020489852875471115,
      0.04486646503210068,
      -0.018736572936177254,
      -0.04713316261768341,
      -0.006965103093534708,
      -0.04640643671154976,
      0.0162721686065197,
      -0.027760323137044907,
      -0.034522030502557755,
      -0.026287920773029327,
      -0.05762653425335884,
      0.004549937788397074,
      -0.014706240966916084,
      0.008723456412553787,
      -0.01823541335761547,
      0.05282192304730415,
      0.015021038241684437,
      0.02391922101378441,
      -0.04053618386387825,
      -0.04180557280778885,
      0.04855111986398697,
      0.03306729719042778,
      0.001227784319780767,
      0.018559249117970467,
      0.022517791017889977,
      0.04238579049706459,
      -0.04836450517177582,
      0.023261742666363716,
      -0.046782780438661575,
      0.03535996377468109,
      -0.0576494038105011,
      0.009946473874151707,
      -0.026738235726952553,
      -0.01748022623360157,
      -0.015611098147928715,
      0.022983213886618614,
      0.0033222008496522903,
      0.07032898813486099,
      0.0925854966044426,
      -0.019437963142991066,
      -0.054271623492240906,
      -0.00386576890014112,
      -0.030859023332595825,
      0.002574963727965951,
      0.024457694962620735,
      -0.01965097337961197,
      0.008438416756689548,
      -0.01783350482583046,
      0.035236235707998276,
      -0.034013062715530396,
      0.04758598655462265,
      0.025915561243891716,
      0.010971605777740479,
      -0.024290671572089195,
      0.04259606450796127,
      0.051962994039058685,
      -0.015677325427532196,
      0.015885258093476295,
      0.05272439494729042,
      -0.020907225087285042,
      -0.07345082610845566,
      -0.04859565570950508,
      -0.0010056740138679743,
      0.009293927811086178,
      -0.0685536116361618,
      -0.0304434671998024,
      0.03919846937060356,
      0.005829278379678726,
      -0.12187293916940689,
      -0.0015268122078850865,
      -0.059279944747686386,
      -0.03095434606075287,
      0.024718137457966805,
      -0.11291548609733582,
      0.0469660609960556,
      0.020588748157024384,
      0.047938063740730286,
      -0.04544920101761818,
      -0.009541614912450314,
      0.1357896476984024,
      0.018049485981464386,
      -0.044495537877082825,
      -0.09103406220674515,
      -0.03531265631318092,
      0.013190156780183315,
      -0.1279209554195404,
      -0.007497177459299564,
      -0.02445518597960472,
      0.09381766617298126,
      0.053051840513944626,
      -0.052813466638326645,
      -0.01546479295939207,
      -0.039550576359033585,
      0.04814685508608818,
      -0.0742466151714325,
      -0.11905495077371597,
      0.019977465271949768,
      -0.07383257150650024,
      -0.0012059970758855343,
      0.001759982667863369,
      -0.003652578918263316,
      -0.08553013950586319,
      -0.019472550600767136,
      0.04018926993012428,
      0.005350920371711254,
      -0.10666757822036743,
      -0.007811020594090223,
      0.011905541643500328,
      0.0037848688662052155,
      -0.026351163163781166,
      -0.014126872643828392,
      0.04086202010512352,
      -0.00330730015411973,
      -0.05279725790023804,
      0.013283323496580124,
      -0.0018702625529840589,
      -0.055178333073854446,
      0.007323625031858683,
      -0.020118018612265587,
      -0.011774340644478798,
      0.011227436363697052,
      0.02022571861743927,
      -0.02907402068376541,
      0.014945066533982754,
      -0.03624583035707474,
      0.0018460588762536645,
      0.060943853110075,
      0.10141279548406601,
      -0.012386774644255638,
      0.012644571252167225,
      -0.015459430404007435,
      0.025133168324828148,
      -0.007650760002434254,
      0.06884944438934326,
      -0.0381549671292305,
      -0.03977300971746445,
      0.03363015130162239,
      0.061917632818222046,
      0.032653793692588806,
      -0.006492387969046831,
      -0.043083664029836655,
      0.04408722743391991,
      -0.022265730425715446,
      0.02810204215347767,
      0.0320560559630394,
      -0.03487899526953697,
      -0.0029299550224095583,
      -0.07330191880464554,
      -0.0172136127948761,
      -0.023161424323916435,
      0.04925237596035004,
      -0.07033370435237885,
      -0.046228572726249695,
      0.03526700288057327,
      -0.046444427222013474,
      0.005028183106333017,
      0.012835068628191948,
      -0.006277046166360378,
      0.003373707877472043,
      -0.010230309329926968
    ],
    [
      0.012870372273027897,
      -0.14563556015491486,
      0.012212401255965233,
      0.07845743000507355,
      -0.002419458469375968,
      0.009579802863299847,
      -0.016917996108531952,
      -0.04624336585402489,
      0.02040986157953739,
      -0.02122468687593937,
      -0.04012622684240341,
      -0.009281991980969906,
      0.06512344628572464,
      -0.019130585715174675,
      -0.028911616653203964,
      0.04116079583764076,
      0.03986117243766785,
      0.025990063324570656,
      0.06132727116346359,
      0.015758348628878593,
      -0.07048725336790085,
      -0.06274942308664322,
      0.002989691449329257,
      0.13057199120521545,
      -0.03632134571671486,
      -0.023450367152690887,
      -0.033845916390419006,
      -0.090510793030262,
      -0.057868923991918564,
      0.032994236797094345,
      -0.07499268651008606,
      0.031933918595314026,
      0.015469322912395,
      -0.044215548783540726,
      0.07087870687246323,
      0.008385644294321537,
      -0.07559999823570251,
      0.0065648821182549,
      0.01305721141397953,
      0.07009885460138321,
      0.20300985872745514,
      -0.07266752421855927,
      -0.004285776987671852,
      -0.02895244210958481,
      -0.0082493731752038,
      -0.10966376960277557,
      -0.04548076540231705,
      -0.0450720489025116,
      -0.12030720710754395,
      0.009932820685207844,
      0.006074647884815931,
      0.005860364530235529,
      -0.016114085912704468,
      -0.04846484586596489,
      0.004673619754612446,
      -0.025213489308953285,
      -0.0490376353263855,
      -0.028479479253292084,
      -0.06863284111022949,
      0.032791104167699814,
      0.028711095452308655,
      -0.0544784776866436,
      0.04635448008775711,
      -0.0055473498068749905,
      -0.040098052471876144,
      0.0641540065407753,
      0.015070165507495403,
      -0.03236807882785797,
      -0.0557502806186676,
      -0.048552390187978745,
      0.011535951867699623,
      0.009115000255405903,
      0.05452338606119156,
      0.05016801506280899,
      0.014553057961165905,
      -0.06695935875177383,
      0.04745131731033325,
      -0.00838429108262062,
      0.011496705003082752,
      -0.059749532490968704,
      0.031074071303009987,
      -0.0091996556147933,
      -0.020965833216905594,
      -0.01624295301735401,
      -0.01606025919318199,
      -0.050277065485715866,
      0.0017622599843889475,
      0.03937993198633194,
      0.019665610045194626,
      -0.05578929930925369,
      0.09916234016418457,
      -0.0038649511989206076,
      0.015551268123090267,
      0.01161676924675703,
      0.07452383637428284,
      0.025219835340976715,
      -0.05585441738367081,
      -0.014083398506045341,
      -0.00503328163176775,
      -0.056696586310863495,
      0.029974056407809258,
      0.02648700773715973,
      0.04579148069024086,
      -0.0009892802918329835,
      0.012599487788975239,
      -0.06130635738372803,
      0.0183859895914793,
      -0.00024374078202527016,
      -0.10785194486379623,
      -0.057229891419410706,
      -0.07284200191497803,
      0.008994797244668007,
      -0.034536100924015045,
      -0.04338455572724342,
      0.005746319890022278,
      -0.004339376464486122,
      -0.01688607595860958,
      0.03172454982995987,
      -0.002760005183517933,
      -0.053272735327482224,
      0.010371888987720013,
      0.013347860425710678,
      -0.0286408644169569,
      0.04279986768960953,
      -0.012379203923046589,
      0.015320827253162861,
      0.012587343342602253,
      0.06848826259374619,
      -0.0615994818508625,
      0.018815526738762856,
      -0.022366555407643318,
      -0.0020875195041298866,
      0.012360699474811554,
      0.02928425930440426,
      0.023703960701823235,
      0.019701307639479637,
      0.027598261833190918,
      -0.00018172802811022848,
      0.04518589749932289,
      -0.03071083128452301,
      -0.02266453020274639,
      -0.09943169355392456,
      -0.011001496575772762,
      0.022534631192684174,
      0.006736584473401308,
      -0.045809678733348846,
      -0.0119472686201334,
      -0.018079396337270737,
      0.019909001886844635,
      -0.0004907531547360122,
      0.0022451933473348618,
      -0.07720693945884705,
      -0.08837701380252838,
      -0.06930533796548843,
      0.08007120341062546,
      -0.020713726058602333,
      0.01644088514149189,
      -0.0333130843937397,
      0.06735903769731522,
      0.07935544103384018,
      -0.023271936923265457,
      0.007203921675682068,
      0.051488135010004044,
      -0.060721419751644135,
      0.015856249257922173,
      0.08490768820047379,
      -0.030077748000621796,
      -0.006391491275280714,
      0.01431635394692421,
      -0.0329260416328907,
      0.0053578512743115425,
      -0.02830224670469761,
      -0.006264315452426672,
      0.005936153698712587,
      0.08062510192394257,
      0.12082110345363617,
      -0.003357007633894682,
      -0.07009059935808182,
      0.07702324539422989,
      -0.004465028643608093,
      -0.023420004174113274,
      -0.12223223596811295,
      0.004983761813491583,
      0.007732017897069454,
      -0.04152541607618332,
      0.026912298053503036,
      0.04103511571884155,
      0.03738032281398773,
      0.08213914185762405,
      -0.08535684645175934,
      -0.0925382748246193,
      0.00010096481128130108,
      0.017509421333670616,
      0.009855687618255615,
      -0.07233407348394394,
      -0.039471693336963654,
      0.010539890266954899,
      -0.02185394987463951,
      -0.03816988691687584,
      0.016524704173207283,
      0.06187164783477783,
      -0.03027680143713951,
      0.0016792401438578963,
      -0.014434962533414364,
      -0.003961148671805859,
      -0.02769644744694233,
      0.002027741400524974,
      -0.0016178602818399668,
      0.07175561785697937,
      0.04491332173347473,
      0.08179572969675064,
      0.041513413190841675,
      -0.016516786068677902,
      -0.04300010949373245,
      -0.04125036671757698,
      -0.028470495715737343,
      -0.0120932562276721,
      0.004728949163109064,
      -0.04590163379907608,
      -0.05901818349957466,
      0.012546608224511147,
      -0.02301858551800251,
      -0.055064134299755096,
      0.06075475737452507,
      -0.04145563766360283,
      -0.0505811907351017,
      -0.02611519955098629,
      0.08522514253854752,
      0.04087521880865097,
      -0.014156212098896503,
      -0.02721436507999897,
      0.07367098331451416,
      -0.060375653207302094,
      0.08976256102323532,
      -0.0003583499928936362,
      -0.007116755470633507,
      0.00036539018037728965,
      0.06363489478826523,
      0.041272830218076706,
      0.0046811639331281185,
      -0.04550633579492569,
      -0.003966022282838821,
      0.08975813537836075,
      0.05954005941748619,
      -0.007578065153211355,
      -0.0034873078111559153,
      -0.0018741379026323557,
      -0.016629261896014214,
      0.03868398442864418,
      0.15307050943374634,
      -0.0372786670923233,
      0.05582042410969734,
      0.01068909466266632,
      0.013978936709463596,
      -0.01976487599313259,
      -0.042250487953424454,
      0.09404056519269943,
      0.10006560385227203,
      0.053103819489479065,
      0.09069447219371796,
      -0.011294584721326828,
      0.01836477406322956,
      -0.0634666457772255,
      -0.0615202859044075,
      -0.02490110509097576,
      -0.024676160886883736,
      -0.004907350521534681,
      0.03189145773649216,
      -0.01375239621847868,
      -0.014635764993727207,
      -0.012047028169035912,
      0.0988282561302185,
      0.011860432103276253,
      0.009749870747327805,
      0.008398154750466347,
      -0.03963785618543625,
      -0.010194368660449982,
      -0.046535126864910126,
      0.04083715379238129,
      -0.02767825499176979,
      -0.03665663301944733,
      -0.022711412981152534,
      -0.008302118629217148,
      -0.0029971988406032324,
      -0.01336645893752575,
      0.051388971507549286,
      0.007692151702940464,
      -0.0013976150657981634,
      0.09746343642473221,
      0.059090159833431244,
      -0.04455329477787018,
      0.029139691963791847,
      0.06964027136564255,
      0.04458935558795929,
      -0.04755027964711189,
      -0.025970952585339546,
      -0.01729361154139042,
      -0.03945796191692352,
      -0.029294388368725777,
      -0.04995110630989075,
      0.03195104002952576,
      -0.03955407440662384,
      0.03609434515237808,
      -0.0012823017314076424,
      0.017805414274334908,
      -0.07871315628290176,
      0.06113427132368088,
      0.015690110623836517,
      -0.021179506555199623,
      -0.06680260598659515,
      0.037286460399627686,
      -0.02135392278432846,
      -0.09962619841098785,
      0.012354127131402493,
      0.018649373203516006,
      0.050803765654563904,
      0.007079345639795065,
      -0.03536287695169449,
      0.059389811009168625,
      0.000658331613522023,
      0.020217617973685265,
      0.07577681541442871,
      -0.025175314396619797,
      0.015947675332427025,
      0.04024013504385948,
      0.01020241528749466,
      -0.0379837341606617,
      0.03714405372738838,
      -0.03845125064253807,
      0.01115992572158575,
      -0.07535725831985474,
      0.0035855125170201063,
      -0.014839273877441883,
      -0.053814131766557693,
      -0.0378975085914135,
      -0.0765669196844101,
      -0.004837723448872566,
      0.024192942306399345,
      -0.0008499635150656104,
      -0.009563269093632698,
      -0.08843038976192474,
      0.04281707480549812,
      -0.015425647608935833,
      0.0014615736436098814,
      0.03247756510972977,
      0.0224114041775465,
      -0.05547676980495453,
      -0.03026646561920643,
      0.018709631636738777,
      0.0076787760481238365,
      -0.05174913629889488,
      0.08392469584941864,
      0.009632276371121407,
      0.06220125034451485,
      -0.01026344858109951,
      -0.018872901797294617,
      0.02560415118932724,
      0.056091684848070145,
      0.0020623281598091125,
      0.017304684966802597,
      -0.09840484708547592,
      0.11243029683828354,
      0.04026089236140251,
      -0.03344437852501869,
      0.021817253902554512,
      -0.020103830844163895,
      0.019081614911556244,
      -0.0116603784263134,
      0.0035722628235816956,
      -0.024832960218191147,
      0.005403425078839064,
      -0.03548299893736839,
      -0.016676750034093857,
      0.058634478598833084,
      -0.009985120967030525,
      -0.07781486213207245,
      -0.08127453178167343,
      -0.052324920892715454,
      -0.016055388376116753,
      -0.0814371109008789,
      0.03744538500905037,
      0.01692991890013218,
      -0.03866884857416153,
      0.029592497274279594,
      -0.057632166892290115,
      -0.056535400450229645,
      0.022948140278458595,
      0.005026631988584995,
      0.025819582864642143,
      0.010539425536990166,
      0.008855598978698254,
      -0.039217643439769745,
      0.040726084262132645,
      -0.0026185838505625725,
      0.006378361489623785,
      -0.05246056616306305,
      0.07089148461818695,
      0.051764532923698425,
      -0.07554944604635239,
      0.008499445393681526,
      -0.026278391480445862,
      0.035726919770240784,
      -0.03319685906171799,
      -0.0035021884832531214,
      0.05763138085603714,
      -0.05426817014813423,
      0.07150734215974808,
      0.032539110630750656,
      -0.05584448575973511,
      -0.07171864807605743,
      -0.020455431193113327,
      -0.041737139225006104,
      -0.039044078439474106,
      -0.0836082398891449,
      -0.04091934114694595,
      2.1275734980008565e-05,
      -0.07659655809402466,
      0.0004188531602267176,
      0.0014314884319901466,
      0.036449600011110306,
      -0.014886638149619102,
      -0.048238009214401245,
      -0.022180359810590744,
      0.038363777101039886,
      0.033549897372722626,
      -0.0028456752188503742,
      0.0246806051582098,
      0.054493822157382965,
      -0.07855305820703506,
      -0.003218199359253049,
      -0.02549154870212078,
      0.024351691827178,
      -0.0835471972823143,
      0.0267858374863863,
      -0.14794255793094635,
      0.036074090749025345,
      -0.007165775168687105,
      -0.010531697422266006,
      0.023735830560326576,
      0.007322471588850021,
      0.078501395881176,
      0.0602632500231266,
      -0.048480577766895294,
      0.08661812543869019,
      0.041811276227235794,
      0.036304641515016556,
      0.04631925746798515,
      0.04627854377031326,
      0.00423197727650404,
      -0.01576753333210945,
      -0.10792533308267593,
      0.05616573616862297,
      0.06133625656366348,
      0.01436382532119751,
      -0.03244013711810112,
      -0.007197599392384291,
      0.02922867238521576,
      0.019393038004636765,
      0.045929741114377975,
      0.045718003064394,
      0.10683702677488327,
      0.0988902598619461,
      -0.03551444783806801,
      0.0033840383403003216,
      -0.014411970041692257,
      -0.08609237521886826,
      0.10629189759492874,
      -0.0186759065836668,
      0.00476221414282918,
      -0.0128343366086483,
      0.006860032677650452,
      -0.000609192531555891,
      0.07108057290315628,
      -0.039173368364572525,
      0.024131333455443382,
      0.05106737092137337,
      -0.01274957787245512,
      -0.02180180884897709,
      0.02233131229877472,
      0.02170155756175518,
      -0.03656788170337677,
      0.030517732724547386,
      0.04975215718150139,
      -0.05945761501789093,
      -0.08183803409337997,
      1.3268697330204304e-05,
      0.00201227655634284,
      0.006422568112611771,
      -0.05387778580188751,
      0.0811200961470604,
      -0.057982563972473145,
      -0.0066817947663366795,
      -0.02164355479180813,
      0.04706447944045067,
      -0.014234150759875774,
      -0.020922869443893433,
      -0.042569734156131744,
      0.08124550431966782,
      -0.012260044924914837,
      0.10003391653299332,
      -0.022803647443652153,
      -0.0429992638528347,
      0.03213232755661011
    ],
    [
      -0.07745450735092163,
      -0.0465255081653595,
      -0.03763595595955849,
      -0.020885519683361053,
      0.058480244129896164,
      0.016930589452385902,
      -0.017748791724443436,
      -0.029224779456853867,
      0.016037678346037865,
      0.019777730107307434,
      -0.003933957777917385,
      0.010801231488585472,
      -0.0041521452367305756,
      0.027361245825886726,
      -0.06844954192638397,
      0.017605667933821678,
      0.04938574880361557,
      0.06831412017345428,
      0.025924595072865486,
      0.009649967774748802,
      0.014089706353843212,
      0.02156195417046547,
      -0.03477481007575989,
      0.002826445270329714,
      0.027676532045006752,
      0.013795689679682255,
      -0.02862190268933773,
      -0.034113895148038864,
      -0.08095729351043701,
      -0.07603508234024048,
      0.010198403149843216,
      -0.04503951966762543,
      -0.030963512137532234,
      -0.0007948341080918908,
      -0.027887694537639618,
      -0.0346226841211319,
      0.021386517211794853,
      0.019883785396814346,
      0.017191212624311447,
      -0.039422981441020966,
      0.05397939682006836,
      0.03668438643217087,
      -0.025268040597438812,
      0.05173896998167038,
      0.04754645377397537,
      -0.04755972698330879,
      0.004342781379818916,
      -0.02052961476147175,
      -0.004589572083204985,
      0.0732058584690094,
      -0.03951447829604149,
      0.023374229669570923,
      -0.00792679376900196,
      -0.01387467235326767,
      -0.06897541135549545,
      -0.03537246957421303,
      0.021894855424761772,
      0.0030437170062214136,
      0.01468354370445013,
      0.0005599567084573209,
      0.030166959390044212,
      0.054279349744319916,
      0.030733902007341385,
      -0.03926996886730194,
      -0.030185258015990257,
      0.035130273550748825,
      -0.013981223106384277,
      -0.019337819889187813,
      0.005412922706454992,
      0.016924550756812096,
      0.07449266314506531,
      -0.03083181008696556,
      -0.04426074028015137,
      -0.04844236746430397,
      0.039772532880306244,
      0.022426867857575417,
      0.0013720215065404773,
      0.005844161845743656,
      0.07445525377988815,
      -0.03261861205101013,
      0.04116753488779068,
      0.1083592027425766,
      0.010603032074868679,
      -0.02142464742064476,
      0.0022595298942178488,
      -0.03615802153944969,
      -0.053118638694286346,
      0.016442706808447838,
      -0.10270626842975616,
      0.00787950586527586,
      0.016307469457387924,
      0.03999399021267891,
      0.019577957689762115,
      -0.033633410930633545,
      -0.029793279245495796,
      -0.015598437748849392,
      0.017376698553562164,
      0.045282237231731415,
      -0.07140617817640305,
      -0.07674665004014969,
      0.019784139469265938,
      0.04306473582983017,
      0.05309649929404259,
      0.02064560540020466,
      -0.02956869639456272,
      -0.009842955507338047,
      -0.0844993069767952,
      0.01256405096501112,
      -0.08706869930028915,
      -0.005113703664392233,
      -0.034150343388319016,
      0.03189465031027794,
      0.018762920051813126,
      -0.011993116699159145,
      0.004087924957275391,
      0.008151056244969368,
      0.04130157455801964,
      -0.02029493823647499,
      -0.02102135680615902,
      -0.031032489612698555,
      0.09901467710733414,
      -0.015806851908564568,
      -0.018847571685910225,
      0.010411566123366356,
      -0.03892272710800171,
      0.009487651288509369,
      0.041408125311136246,
      0.02131190150976181,
      0.0024926115293055773,
      0.004348264541476965,
      0.005251267459243536,
      0.07509448379278183,
      -0.013052532449364662,
      0.06311605125665665,
      -0.0035315058194100857,
      0.04492124915122986,
      -0.06862249970436096,
      -0.02977546863257885,
      -0.008308632299304008,
      0.034959543496370316,
      -0.04941025748848915,
      -0.013842214830219746,
      -0.005259922705590725,
      -0.011604607105255127,
      0.018613290041685104,
      -0.09007268399000168,
      0.004434820730239153,
      -0.03896850720047951,
      -0.020866340026259422,
      0.0004556268686428666,
      0.044735051691532135,
      -0.0726553276181221,
      0.03957270458340645,
      0.003617712762206793,
      0.020217346027493477,
      -0.01140325516462326,
      0.010799993760883808,
      -0.06461744755506516,
      -0.033608417958021164,
      0.05732279643416405,
      0.039985716342926025,
      -0.09976689517498016,
      -0.009940418414771557,
      -0.025679176673293114,
      0.013286854140460491,
      0.0453227199614048,
      0.04318995401263237,
      0.028451593592762947,
      -0.028379002586007118,
      -0.005589039530605078,
      0.0212248582392931,
      -0.062389545142650604,
      0.0270647332072258,
      0.018304673954844475,
      -0.044646698981523514,
      -0.015535853803157806,
      0.04556354135274887,
      -0.016959063708782196,
      0.021652311086654663,
      0.05317750573158264,
      0.04607576131820679,
      -0.004849182907491922,
      -0.09666839987039566,
      -0.06365057080984116,
      0.005870908498764038,
      -0.019873611629009247,
      -0.04414909705519676,
      0.0041466401889920235,
      0.028452297672629356,
      -0.0016843626508489251,
      0.0596630834043026,
      -0.031167082488536835,
      0.03422694653272629,
      0.04996555671095848,
      0.03569050505757332,
      0.06627387553453445,
      -0.01860443688929081,
      0.05147669464349747,
      -0.033072683960199356,
      0.04881038889288902,
      -0.03794008120894432,
      0.0899517759680748,
      0.07344600558280945,
      0.023520341143012047,
      0.0020407114643603563,
      0.04637107253074646,
      -0.05177338421344757,
      0.027777578681707382,
      -0.02741815149784088,
      0.002034528646618128,
      -0.015636231750249863,
      -0.007196358870714903,
      -0.016787003725767136,
      -0.002499805763363838,
      0.006760270334780216,
      -0.036586519330739975,
      0.003943170886486769,
      0.01089145615696907,
      0.06389293074607849,
      -0.042134400457143784,
      -0.005016774870455265,
      0.004660923965275288,
      -0.0786106064915657,
      0.03569413721561432,
      -0.009173070080578327,
      -0.048566170036792755,
      -0.009682544507086277,
      -0.03241582587361336,
      -0.11333584785461426,
      0.07291004806756973,
      -0.023163532838225365,
      -0.020050302147865295,
      -0.002021818421781063,
      0.052582722157239914,
      0.0591609962284565,
      -0.07747287303209305,
      0.037182439118623734,
      0.0032678493298590183,
      0.005506352987140417,
      0.04363895580172539,
      0.028561990708112717,
      0.010588676668703556,
      0.020275989547371864,
      0.028522254899144173,
      -0.009091036394238472,
      -0.05156538635492325,
      -0.006502990145236254,
      0.02834053337574005,
      -0.0553458146750927,
      0.05319056287407875,
      0.037374719977378845,
      -0.007603072561323643,
      -0.010526872240006924,
      0.02738344669342041,
      -0.0036343184765428305,
      -0.004967892542481422,
      0.040248967707157135,
      0.06592010706663132,
      -0.038819167762994766,
      0.032753441482782364,
      0.06579364091157913,
      0.08670070022344589,
      -0.007410867139697075,
      -0.019426193088293076,
      -0.11562388390302658,
      0.015985677018761635,
      0.02194688841700554,
      -0.024528488516807556,
      0.06013418361544609,
      0.06403912603855133,
      0.022181417793035507,
      -0.03470618277788162,
      0.024452170357108116,
      -0.002121592406183481,
      0.019434526562690735,
      0.00898293312638998,
      0.03081558085978031,
      0.023006733506917953,
      -0.039762236177921295,
      0.05045587569475174,
      0.0493115596473217,
      0.03318745270371437,
      -0.02876955270767212,
      -0.09586254507303238,
      -0.03452370688319206,
      0.04167526215314865,
      -0.0024731026496738195,
      -0.018715422600507736,
      0.04711132496595383,
      0.0035814382135868073,
      0.018543941900134087,
      0.06455914676189423,
      0.03314603492617607,
      0.04143885523080826,
      0.04064992815256119,
      -0.06341986358165741,
      -0.029196085408329964,
      -0.010305782780051231,
      -0.02801082469522953,
      -0.03921112045645714,
      0.029604002833366394,
      0.007790392730385065,
      -0.0295293927192688,
      -0.0629485547542572,
      0.010065081529319286,
      0.005947408266365528,
      0.01347221340984106,
      -0.013304966501891613,
      -0.006831097416579723,
      -0.0027226791717112064,
      0.01042367797344923,
      -0.04079955443739891,
      0.052144307643175125,
      0.029282942414283752,
      0.04524736478924751,
      0.018514318391680717,
      -0.046812910586595535,
      -0.021795880049467087,
      -0.04888571426272392,
      0.0017133569344878197,
      -0.044621244072914124,
      0.0019104318926110864,
      -0.049732014536857605,
      -0.06660338491201401,
      -0.0786416307091713,
      0.053330324590206146,
      -0.04268040508031845,
      0.020366352051496506,
      -0.014461381360888481,
      -0.08202768117189407,
      -0.024154234677553177,
      -0.026524975895881653,
      -0.004245735704898834,
      -0.006290328223258257,
      -0.029809970408678055,
      0.055414412170648575,
      0.029737867414951324,
      -0.036956965923309326,
      0.04478485882282257,
      0.042295489460229874,
      -0.05713389813899994,
      0.013091836124658585,
      -0.016894232481718063,
      -0.017857372760772705,
      -0.0474986769258976,
      -0.025418542325496674,
      -0.08027317374944687,
      0.0333443321287632,
      -0.006169892847537994,
      0.06469409912824631,
      -0.02516365982592106,
      0.01563875749707222,
      0.037128377705812454,
      0.040720194578170776,
      -0.004192909225821495,
      -0.032918356359004974,
      -0.06024469807744026,
      0.03900601342320442,
      -0.01943313330411911,
      0.01690543070435524,
      -0.009303404949605465,
      0.08098560571670532,
      -0.056528009474277496,
      0.0332203134894371,
      0.0339839905500412,
      0.026898236945271492,
      0.07564417272806168,
      0.10658355802297592,
      0.003558178897947073,
      0.008443797938525677,
      0.03760843724012375,
      -0.09193352609872818,
      -0.013798285275697708,
      0.029540807008743286,
      -0.029511259868741035,
      -0.05356435850262642,
      -0.0843542069196701,
      0.08204180002212524,
      0.042919695377349854,
      0.02374342456459999,
      -0.07397457212209702,
      -0.023682255297899246,
      -0.03736044093966484,
      -0.028135674074292183,
      -0.08026273548603058,
      0.030716804787516594,
      -0.010647807270288467,
      0.029894310981035233,
      -0.028556043282151222,
      0.04353020340204239,
      -0.09878377616405487,
      -0.03299243375658989,
      0.007804935332387686,
      -0.03235325589776039,
      0.004084630869328976,
      0.027114368975162506,
      -0.10084288567304611,
      0.009344248101115227,
      0.004645550157874823,
      -0.015044376254081726,
      -0.02546050399541855,
      0.016835404559969902,
      -0.017184054479002953,
      -0.03946758061647415,
      0.03322216495871544,
      -0.023090040311217308,
      0.0789276584982872,
      0.01800609938800335,
      -0.02531132660806179,
      0.02107979916036129,
      0.02226320281624794,
      -0.029882635921239853,
      -0.012596352025866508,
      -0.0069588348269462585,
      -0.01664830558001995,
      -0.03326622024178505,
      0.029504934325814247,
      -0.03598170354962349,
      -0.0469466969370842,
      0.04603981599211693,
      0.03606824949383736,
      -0.13566409051418304,
      0.03130941092967987,
      -0.025017237290740013,
      0.006765995640307665,
      -0.019009575247764587,
      0.07462821900844574,
      -0.006745705846697092,
      0.002243228256702423,
      0.025372741743922234,
      0.04705016314983368,
      -0.01928345486521721,
      -0.012265020981431007,
      0.0026817540638148785,
      0.06470328569412231,
      0.06616568565368652,
      0.030790261924266815,
      0.045671261847019196,
      0.026601415127515793,
      -0.043656207621097565,
      0.08441758900880814,
      0.005661448929458857,
      0.06923747062683105,
      -0.06508121639490128,
      -0.0150945158675313,
      -0.026615291833877563,
      -0.0028183828108012676,
      0.039697661995887756,
      0.13434526324272156,
      0.024964099749922752,
      -0.06942267715930939,
      -0.005902653560042381,
      0.05369371548295021,
      0.02243880368769169,
      -0.045145340263843536,
      0.027393614873290062,
      -0.006248121615499258,
      -0.013777029700577259,
      0.034336816519498825,
      -0.02690579742193222,
      -0.062209419906139374,
      -0.032662346959114075,
      0.0013575261691585183,
      0.0429733581840992,
      -0.006871310994029045,
      0.028133157640695572,
      -0.006564248353242874,
      -0.08575273305177689,
      -0.022407585754990578,
      -0.011574364267289639,
      0.03623529151082039,
      -0.11045512557029724,
      -0.03196270391345024,
      0.04223858192563057,
      -0.06201118975877762,
      -0.027468033134937286,
      -0.00827282015234232,
      0.029085807502269745,
      0.0634409710764885,
      0.03243115171790123,
      0.05306600034236908,
      -0.03825335204601288,
      -0.036436986178159714,
      -0.06891538947820663,
      -0.06680623441934586,
      -0.03823455795645714,
      0.09271502494812012,
      -0.020742036402225494,
      -0.035326115787029266,
      0.04707442596554756,
      -0.07139010727405548,
      0.023055003955960274,
      0.044617149978876114,
      -0.0005427356227301061,
      0.018617069348692894,
      0.031802184879779816,
      -0.012145007960498333,
      0.02115752175450325,
      0.011730306781828403,
      0.062316711992025375,
      -0.03638625517487526,
      -0.014113395474851131,
      -0.08699069917201996
    ],
    [
      0.0016581066884100437,
      -0.049844611436128616,
      -0.0598328597843647,
      0.008100373670458794,
      -0.0399501658976078,
      -0.02698802389204502,
      -0.06951848417520523,
      -0.03387921303510666,
      -0.022679293528199196,
      0.009038552641868591,
      -0.0037519943434745073,
      -0.07216538488864899,
      0.006006825249642134,
      0.02475002408027649,
      -0.0007529061986133456,
      -0.029188809916377068,
      -0.03693503513932228,
      -0.05240747332572937,
      0.045536138117313385,
      -0.025697313249111176,
      -0.06199127808213234,
      0.021151317283511162,
      0.02547936700284481,
      0.008312072604894638,
      0.024423284456133842,
      -0.005296663381159306,
      0.009387550875544548,
      -0.014524341560900211,
      -0.03486751392483711,
      -0.08104274421930313,
      -0.006506416480988264,
      0.017501773312687874,
      0.02749685011804104,
      -0.05173397809267044,
      -0.049770090728998184,
      -0.0026106603909283876,
      -0.01594475284218788,
      0.00199264963157475,
      0.008220857940614223,
      0.03694794327020645,
      0.08855392038822174,
      0.1273670494556427,
      -0.01461044605821371,
      0.024212131276726723,
      -0.006713909097015858,
      -0.007390439510345459,
      0.03897102177143097,
      -0.03966512531042099,
      -0.023691825568675995,
      -0.08222039043903351,
      -0.035264141857624054,
      -0.001694119768217206,
      0.00690503790974617,
      -0.0071297381073236465,
      -0.011592772789299488,
      -0.08323699235916138,
      -0.012534772977232933,
      -0.049746133387088776,
      0.06594187021255493,
      0.022734694182872772,
      -0.010228306986391544,
      -0.053261853754520416,
      0.06193150207400322,
      0.06956212222576141,
      0.03767275810241699,
      0.016808925196528435,
      0.012095687910914421,
      0.030180711299180984,
      -0.06530382484197617,
      -0.07010575383901596,
      0.0053176674991846085,
      -0.0351087711751461,
      0.01763237826526165,
      -0.04679400473833084,
      0.0498540922999382,
      -0.038623906672000885,
      -0.0364484079182148,
      -0.02716998942196369,
      0.0007240139530040324,
      0.027187423780560493,
      -0.020232709124684334,
      0.02244028076529503,
      -0.031878408044576645,
      -0.014032391831278801,
      0.04144256189465523,
      0.004963172134011984,
      -0.017101624980568886,
      0.03246193006634712,
      -0.03097451850771904,
      0.007970970124006271,
      0.03145750239491463,
      0.051324546337127686,
      0.04750210791826248,
      0.02826116420328617,
      -0.014581761322915554,
      -0.01374768652021885,
      0.09995513409376144,
      -0.03308699280023575,
      0.004552100785076618,
      -0.0385553278028965,
      0.021661104634404182,
      -0.01588805951178074,
      -0.010961503721773624,
      -0.03553297743201256,
      0.05247911438345909,
      -0.0442039780318737,
      -0.011444964446127415,
      0.009986021555960178,
      0.0325113907456398,
      0.056176476180553436,
      -0.05314180627465248,
      0.04375125467777252,
      -0.0006974156713113189,
      0.014610459096729755,
      0.022303342819213867,
      -0.030720453709363937,
      0.003519078716635704,
      -0.02054212987422943,
      -0.058238133788108826,
      -0.03602788597345352,
      0.008183030411601067,
      0.017175253480672836,
      0.006012545432895422,
      0.017457418143749237,
      0.018320860341191292,
      -0.005863510072231293,
      -0.030727431178092957,
      0.022583452984690666,
      0.009301248006522655,
      0.08839965611696243,
      -0.02573229745030403,
      0.017848705872893333,
      0.030330805107951164,
      -0.028389273211359978,
      -0.04143552482128143,
      -0.09342080354690552,
      -0.00997365452349186,
      -0.01159917376935482,
      -0.0136567372828722,
      0.04084580019116402,
      -0.007252460811287165,
      -0.011486287228763103,
      0.0050810775719583035,
      0.010959664359688759,
      0.00788821280002594,
      -0.020437143743038177,
      -0.03350171819329262,
      -0.07228359580039978,
      0.03564353287220001,
      0.042581889778375626,
      0.02839049883186817,
      -0.029076799750328064,
      0.08006089925765991,
      -0.009372254833579063,
      0.017700014635920525,
      -0.016271274536848068,
      -0.02937142550945282,
      -0.04105938971042633,
      -0.04792656749486923,
      0.017658879980444908,
      -0.03227437660098076,
      1.0121891136805061e-05,
      0.02476135827600956,
      0.019758740440011024,
      -0.006281841080635786,
      0.026292650029063225,
      -0.07752570509910583,
      -0.03382083401083946,
      0.059145692735910416,
      0.022118495777249336,
      0.05606962367892265,
      -0.055566199123859406,
      -0.0013668134342879057,
      -0.054496802389621735,
      -0.0023892391473054886,
      0.08179890364408493,
      -0.005231229122728109,
      -0.0787084624171257,
      0.05026523023843765,
      0.014408101327717304,
      -0.0431966707110405,
      -0.005542032420635223,
      -0.03422034531831741,
      -0.013816495425999165,
      0.03475191071629524,
      -0.005162619985640049,
      0.011707006022334099,
      0.014205473475158215,
      0.007595714181661606,
      -0.013019638136029243,
      0.0481477826833725,
      -0.049102526158094406,
      -0.03598399832844734,
      -0.01884348876774311,
      0.008661152794957161,
      0.012324575334787369,
      0.0037360815331339836,
      0.009804969653487206,
      -0.035496316850185394,
      -0.05446423962712288,
      -0.03450476750731468,
      0.057669077068567276,
      -0.033359594643116,
      -0.020062578842043877,
      -0.020831763744354248,
      -0.002563300309702754,
      -0.007919805124402046,
      -0.07283077389001846,
      0.02024758793413639,
      0.07315269857645035,
      -0.020932327955961227,
      0.08200380206108093,
      0.09601178020238876,
      0.03430134803056717,
      0.006339849904179573,
      0.06239240616559982,
      0.07024206966161728,
      0.04169270768761635,
      0.0644913986325264,
      -0.02799808420240879,
      0.021053817123174667,
      0.005471443757414818,
      -0.03338884934782982,
      -0.03199212998151779,
      0.0249694362282753,
      -0.021582774817943573,
      0.008035890758037567,
      -0.0829838439822197,
      -0.007107165176421404,
      -0.02084837667644024,
      -0.06257518380880356,
      0.08989573270082474,
      0.014854042790830135,
      -0.03646968677639961,
      0.051231857389211655,
      -0.024301426485180855,
      -0.13561910390853882,
      -0.029472840949892998,
      0.05811374634504318,
      0.02368953824043274,
      -0.04953882470726967,
      -0.0914912074804306,
      0.029256820678710938,
      0.007612412795424461,
      -0.06886380910873413,
      0.029948314651846886,
      -0.0011667029466480017,
      -0.04625948518514633,
      -0.0363650768995285,
      0.11612687259912491,
      -0.017994210124015808,
      0.019072502851486206,
      -0.019372010603547096,
      -0.027710583060979843,
      0.004603194538503885,
      0.0031434884294867516,
      0.11923987418413162,
      -0.013610724359750748,
      0.01606198027729988,
      0.012944920919835567,
      0.05963541194796562,
      -0.021850205957889557,
      0.042484868317842484,
      -0.1155247613787651,
      0.06839761137962341,
      -0.04143578186631203,
      0.06020103394985199,
      -0.050349168479442596,
      0.05481215938925743,
      -0.08618716150522232,
      -0.0422583743929863,
      0.01953430101275444,
      -0.053740378469228745,
      -0.04472506046295166,
      -0.003999359905719757,
      -0.021817868575453758,
      -0.1432007998228073,
      0.04100244864821434,
      -0.042417291551828384,
      0.022698909044265747,
      -0.010372091084718704,
      0.006470408756285906,
      0.03746678680181503,
      0.05816587805747986,
      -0.03880809247493744,
      -0.08913616091012955,
      -0.030557049438357353,
      -0.015444932505488396,
      0.035143833607435226,
      0.08476685732603073,
      -0.11088450253009796,
      0.09623239934444427,
      0.03506090119481087,
      -0.04008592292666435,
      -0.06514781713485718,
      -0.036426249891519547,
      0.0445331335067749,
      -0.10921621322631836,
      -0.05371621623635292,
      -0.0027346089482307434,
      -0.08782538771629333,
      0.08407940715551376,
      0.01215318776667118,
      -0.1075373962521553,
      -0.032613497227430344,
      -0.04537120833992958,
      -0.017891818657517433,
      -0.07047387212514877,
      -0.008158714510500431,
      0.009676698595285416,
      0.025552328675985336,
      0.008379283361136913,
      -0.03700888529419899,
      -0.0036776927299797535,
      0.024437252432107925,
      -0.0562506765127182,
      0.008359823375940323,
      0.027314718812704086,
      0.07551910728216171,
      0.048486266285181046,
      -0.0077109686098992825,
      0.04261938855051994,
      -0.07472092658281326,
      0.03478909656405449,
      -0.0012761753750965,
      -0.032368265092372894,
      -0.04120228439569473,
      0.011597134172916412,
      0.008684949018061161,
      0.03247968852519989,
      -0.03864976763725281,
      0.05339347571134567,
      -0.027569450438022614,
      0.03270680457353592,
      -0.01709885336458683,
      0.017147298902273178,
      0.057935699820518494,
      -0.08666808903217316,
      -0.023916851729154587,
      -0.06583339720964432,
      0.04992661252617836,
      -0.024997258558869362,
      0.049001749604940414,
      0.00787712074816227,
      -0.006849346682429314,
      -0.05752706900238991,
      -0.0026636384427547455,
      0.01966632530093193,
      -0.03326739743351936,
      -0.012812016531825066,
      0.014656010083854198,
      -0.02562425658106804,
      0.03123355843126774,
      0.013818807899951935,
      -0.046298686414957047,
      0.05157775804400444,
      -0.05074673146009445,
      0.02944585680961609,
      0.05852961912751198,
      -0.0027785070706158876,
      0.05911746621131897,
      -0.03619617223739624,
      0.03156685456633568,
      0.03616565465927124,
      0.04839690774679184,
      0.03868579491972923,
      -0.03866273909807205,
      0.01126247551292181,
      0.004202716983854771,
      -0.0516708642244339,
      -0.06637103110551834,
      -0.06937962025403976,
      0.007547329645603895,
      0.013427547179162502,
      -0.0029072402976453304,
      -0.02743919938802719,
      -0.015335194766521454,
      -0.011246475391089916,
      -0.008311260491609573,
      -0.003915100824087858,
      0.012598956935107708,
      -0.02327621914446354,
      -0.012219862081110477,
      -0.05789491534233093,
      0.037975843995809555,
      0.06499914824962616,
      -0.08443277329206467,
      0.0014678701991215348,
      -0.06496793776750565,
      -0.04155896231532097,
      -0.08270224183797836,
      -0.05896396562457085,
      0.016477826982736588,
      0.015268835239112377,
      -0.024729594588279724,
      0.028695475310087204,
      0.05061257258057594,
      -0.09158696979284286,
      0.0021796703804284334,
      -0.052830591797828674,
      -0.01617426425218582,
      -0.006662275642156601,
      -0.015387616120278835,
      0.0192034300416708,
      0.036790598183870316,
      0.010241850279271603,
      0.0063635664992034435,
      0.002056970726698637,
      -0.06256724148988724,
      0.013593627139925957,
      0.07684826850891113,
      -0.0038577578961849213,
      -0.0032495507039129734,
      -0.05930396914482117,
      -0.030841857194900513,
      0.0519295334815979,
      -0.05707574635744095,
      0.09904211759567261,
      -0.03491979092359543,
      -0.023631485179066658,
      0.07419288903474808,
      0.08518212288618088,
      0.00290734157897532,
      0.041146956384181976,
      0.024120891466736794,
      0.020897619426250458,
      0.08978773653507233,
      -0.04894304275512695,
      0.0035812747664749622,
      -0.007436144631356001,
      -0.004836334381252527,
      0.007634192239493132,
      -0.01670856960117817,
      -0.02021004818379879,
      0.005455151665955782,
      0.03787706047296524,
      0.022186683490872383,
      0.015889398753643036,
      0.05054493993520737,
      0.01644374616444111,
      0.028783630579710007,
      -0.05775132775306702,
      -0.06804585456848145,
      -0.03665248677134514,
      0.0487733818590641,
      0.006188496481627226,
      0.004759681411087513,
      -0.03823860362172127,
      -0.018033813685178757,
      0.05523939058184624,
      -0.06502792239189148,
      -0.00863825436681509,
      0.053138893097639084,
      0.03711067885160446,
      -0.034388091415166855,
      -0.001744731329381466,
      -0.001373137580230832,
      -0.029013557359576225,
      -0.03760913759469986,
      0.07030513882637024,
      0.05008925125002861,
      -0.02461105026304722,
      0.0026464711409062147,
      -0.03649402782320976,
      -0.03234611079096794,
      0.03100232593715191,
      0.06387417018413544,
      -0.0113438805565238,
      -0.09319759160280228,
      -0.02760409191250801,
      0.0570528544485569,
      0.01630169339478016,
      -0.012374600395560265,
      -0.007325417827814817,
      -0.0264399703592062,
      0.10076904296875,
      0.11288722604513168,
      0.05423842370510101,
      -0.016494890674948692,
      -0.01992206834256649,
      0.01622060127556324,
      -0.06599811464548111,
      0.07810119539499283,
      -0.028939947485923767,
      0.165094256401062,
      0.02294028550386429,
      0.08047071844339371,
      -0.017759118229150772,
      0.03028578869998455,
      -0.007960143499076366,
      -0.06027408689260483,
      -0.08208535611629486,
      -0.023043347522616386,
      0.03176707401871681,
      -0.004746197257190943,
      0.002669011941179633,
      0.014158993028104305,
      -0.0365276038646698,
      0.0005831139860674739,
      0.009455726481974125,
      0.033210597932338715,
      -0.0224313884973526,
      0.012869107536971569
    ],
    [
      0.04616696387529373,
      -0.07384222745895386,
      0.023098034784197807,
      0.013480900786817074,
      0.02132229320704937,
      -0.04339165240526199,
      -0.005962454713881016,
      -0.03277534991502762,
      -0.013899597339332104,
      -0.005778731778264046,
      -0.02805863693356514,
      0.02865004353225231,
      -0.02278635837137699,
      0.05623061954975128,
      -0.036319755017757416,
      -0.0005063175922259688,
      -0.019985314458608627,
      0.048790767788887024,
      -0.03729214891791344,
      0.01552843488752842,
      0.04225720465183258,
      0.046828772872686386,
      -0.04723894223570824,
      0.006921697407960892,
      -0.041684869676828384,
      0.026366807520389557,
      0.011001158505678177,
      -0.04232499748468399,
      -0.04457326978445053,
      0.10697563737630844,
      -0.03837141394615173,
      -0.020135844126343727,
      0.028840722516179085,
      0.07903533428907394,
      -0.11635445058345795,
      0.025683877989649773,
      0.007907926104962826,
      0.059170350432395935,
      -0.004771284759044647,
      0.07147065550088882,
      -0.03634728118777275,
      -0.00853127334266901,
      -0.021756760776042938,
      -0.0006753994384780526,
      -0.003519482212141156,
      -0.030329594388604164,
      -0.05756299942731857,
      0.02733241580426693,
      0.037215329706668854,
      -0.04112253710627556,
      0.030921107158064842,
      0.04401344433426857,
      0.01889854669570923,
      0.03347507864236832,
      -0.0326380580663681,
      -0.007531043142080307,
      -0.08159361034631729,
      -0.010332112200558186,
      -0.06004370003938675,
      0.018557215109467506,
      0.019375121220946312,
      -0.0025948481634259224,
      -0.007987240329384804,
      -0.013765210285782814,
      0.04585488513112068,
      0.014212001115083694,
      0.037880659103393555,
      -0.012849816121160984,
      0.031165117397904396,
      -0.021346470341086388,
      -0.042609330266714096,
      0.00913332775235176,
      0.06835301220417023,
      -0.048113271594047546,
      -0.042348701506853104,
      -0.0779750719666481,
      -0.06731570512056351,
      -0.013910640962421894,
      0.020989807322621346,
      -0.032888248562812805,
      0.0011559345293790102,
      0.04142633453011513,
      0.019006339833140373,
      0.05563393235206604,
      -0.042177435010671616,
      -0.03890465572476387,
      0.05720629170536995,
      0.054363660514354706,
      0.016987457871437073,
      -0.04707230255007744,
      -0.08630211651325226,
      0.025830145925283432,
      -0.025584401562809944,
      -0.05545685812830925,
      -0.03924889490008354,
      0.011532300151884556,
      -0.019000012427568436,
      -0.019901324063539505,
      0.06185254454612732,
      -0.0038296757265925407,
      -0.024512041360139847,
      -8.724450162844732e-05,
      -0.02158503793179989,
      -0.00917143002152443,
      0.058647044003009796,
      0.021509887650609016,
      -0.014864650554955006,
      -0.00908349733799696,
      -0.006183282472193241,
      -0.03551285341382027,
      0.04708894342184067,
      -0.033063553273677826,
      -0.009874384850263596,
      0.08073125034570694,
      0.018629152327775955,
      0.012301355600357056,
      0.03609275072813034,
      -0.06325111538171768,
      -0.08221080154180527,
      -0.054832395166158676,
      -0.05728534609079361,
      0.08433772623538971,
      0.02498386986553669,
      -0.036317452788352966,
      -0.021077753975987434,
      -0.08811745047569275,
      -0.02390654943883419,
      -0.004654687363654375,
      0.02334502339363098,
      0.12754203379154205,
      -0.019032353535294533,
      0.04854542762041092,
      0.024708855897188187,
      -0.0017280045431107283,
      0.03170858696103096,
      -0.052197106182575226,
      -0.08294753730297089,
      0.022977670654654503,
      -0.019980905577540398,
      0.01793380081653595,
      -0.0227990560233593,
      -0.05525488778948784,
      -0.007899752818048,
      -0.03616372123360634,
      -0.03788395971059799,
      0.037527214735746384,
      -0.08435378968715668,
      -0.07338885962963104,
      0.05632173269987106,
      0.05513535439968109,
      0.032969310879707336,
      -0.1363299936056137,
      0.0459740087389946,
      -0.05868285149335861,
      -0.07048247009515762,
      0.019898666068911552,
      -0.0905073881149292,
      -0.020569603890180588,
      -0.03730383887887001,
      -0.04454518482089043,
      0.045033108443021774,
      -0.01600058190524578,
      -0.041441161185503006,
      -0.008532864972949028,
      -0.04826069250702858,
      0.03712315112352371,
      -0.0573124997317791,
      0.036549270153045654,
      0.03880626708269119,
      0.04947071895003319,
      -0.12085415422916412,
      -0.03710983321070671,
      -0.07425526529550552,
      -0.058673810213804245,
      -0.0035030441358685493,
      -0.03514881432056427,
      -0.04231404885649681,
      -0.05381077900528908,
      -0.05336683616042137,
      -0.011991738341748714,
      0.06341496855020523,
      -0.043353140354156494,
      0.0355614610016346,
      -0.0273225586861372,
      -0.027327310293912888,
      -0.05938443914055824,
      -0.004316546022891998,
      -0.017329314723610878,
      0.05130628123879433,
      -0.022638948634266853,
      -0.055667392909526825,
      -0.0011422238312661648,
      -0.06630600243806839,
      -0.022853346541523933,
      -0.031947679817676544,
      0.03358462452888489,
      0.01802201382815838,
      -0.11721395701169968,
      0.00028755885432474315,
      0.05871536582708359,
      0.014718007296323776,
      0.04111223667860031,
      -0.012874464504420757,
      0.024356326088309288,
      0.016075370833277702,
      0.017808521166443825,
      -0.06009642779827118,
      0.020745040848851204,
      0.040373560041189194,
      0.04148073121905327,
      -0.013449289835989475,
      -0.049462608993053436,
      0.04605261608958244,
      -0.04320419207215309,
      0.03631329536437988,
      -0.026138415560126305,
      -0.06734714657068253,
      0.012566077522933483,
      0.010340599343180656,
      -0.06520169973373413,
      -0.011877506971359253,
      -0.010160267353057861,
      0.0013694714289158583,
      0.025201499462127686,
      0.03730953112244606,
      -0.09555757790803909,
      0.035100940614938736,
      -0.0869232565164566,
      0.0423826165497303,
      0.016291053965687752,
      0.038717299699783325,
      -0.0571899339556694,
      -0.019724737852811813,
      0.006510604172945023,
      -0.018826929852366447,
      0.05260206758975983,
      0.06896943598985672,
      0.017763912677764893,
      0.010295507498085499,
      -0.043908242136240005,
      0.03476450964808464,
      -0.050190720707178116,
      -0.03508200868964195,
      0.04949808493256569,
      0.06766956299543381,
      -0.03855026140809059,
      0.011213756166398525,
      0.012860151939094067,
      -0.08193094283342361,
      0.04854681342840195,
      0.02774794213473797,
      -0.009692047722637653,
      0.015006326138973236,
      0.013500017113983631,
      -0.014562098309397697,
      -0.007939470000565052,
      0.03907490894198418,
      -0.03542317822575569,
      -0.019584793597459793,
      0.00829040352255106,
      -0.02186160534620285,
      -0.012738658115267754,
      -0.027912914752960205,
      -0.02061956189572811,
      0.022902118042111397,
      -0.007723317015916109,
      -0.019999654963612556,
      0.020576685667037964,
      -0.016803361475467682,
      0.07240863889455795,
      0.07408970594406128,
      -0.020682962611317635,
      0.06015750393271446,
      -0.047706060111522675,
      -0.015606346540153027,
      0.06905211508274078,
      -0.013599037192761898,
      -0.06671252101659775,
      0.07252780348062515,
      0.02123432233929634,
      0.00943930633366108,
      -0.06951729208230972,
      -0.08428702503442764,
      -0.07740315794944763,
      -0.025016428902745247,
      -0.04108848050236702,
      -0.04336760193109512,
      -0.004907956346869469,
      -0.012067886069417,
      -0.0057411654852330685,
      -0.020296094939112663,
      -0.027059510350227356,
      -0.02508041448891163,
      0.00940627045929432,
      -0.004999059252440929,
      0.013002629391849041,
      -0.053942106664180756,
      0.00563612999394536,
      -0.05631735920906067,
      0.004384114872664213,
      -0.025248026475310326,
      0.017971621826291084,
      -0.025848155841231346,
      0.02964777499437332,
      0.026812521740794182,
      -0.052450865507125854,
      0.047991957515478134,
      -0.010091324336826801,
      0.07376696169376373,
      0.013404403813183308,
      0.041441235691308975,
      0.08137129992246628,
      0.0020584797021001577,
      -0.02070903219282627,
      0.06701109558343887,
      0.00028934483998455107,
      0.03996909409761429,
      0.016394944861531258,
      -0.025755733251571655,
      0.04360618069767952,
      0.02460336871445179,
      0.01962740533053875,
      -0.03221559524536133,
      -0.008842894807457924,
      0.014184643514454365,
      0.04879831522703171,
      0.003269266104325652,
      0.04375758394598961,
      -0.02647361159324646,
      0.023962609469890594,
      -0.040365420281887054,
      0.0009250018047168851,
      0.009563730098307133,
      0.02375996857881546,
      -0.002973726252093911,
      0.0016840917523950338,
      0.015376625582575798,
      0.0387590192258358,
      -0.043044935911893845,
      0.006935279816389084,
      0.020398525521159172,
      0.027521610260009766,
      -0.02343219891190529,
      0.006504883989691734,
      -0.05735723674297333,
      -0.04754933714866638,
      0.04529256746172905,
      -0.005349514540284872,
      0.06672198325395584,
      0.0071371509693562984,
      -0.034863296896219254,
      -0.024837080389261246,
      -0.10796336084604263,
      0.052922509610652924,
      0.08837040513753891,
      0.037214890122413635,
      -0.05016490817070007,
      -0.04466791823506355,
      -0.016420874744653702,
      0.015279075130820274,
      0.016156818717718124,
      -0.016323858872056007,
      0.04936252534389496,
      0.012599987909197807,
      -0.005947792902588844,
      -0.028584949672222137,
      -0.07885591685771942,
      -0.012276867404580116,
      -0.06931813806295395,
      0.01740996353328228,
      0.01808287762105465,
      -0.015948506072163582,
      -0.05829833075404167,
      -0.0006276099593378603,
      -0.050825294107198715,
      -0.05471019446849823,
      0.03465139493346214,
      0.0028206496499478817,
      -0.05126284807920456,
      0.055441197007894516,
      -0.006081771571189165,
      -0.029443707317113876,
      -0.011406701058149338,
      0.10078822821378708,
      0.05065198242664337,
      0.02199961431324482,
      0.08489679545164108,
      -0.024129901081323624,
      -0.03750723972916603,
      0.045978013426065445,
      -0.06543547660112381,
      -0.030655158683657646,
      0.027604995295405388,
      0.07792744785547256,
      0.014994068071246147,
      -0.040099602192640305,
      -0.033660709857940674,
      -0.08356576412916183,
      -0.03953498601913452,
      -0.0011828431161120534,
      0.07958801835775375,
      0.019631806761026382,
      -0.08986493945121765,
      0.014905671589076519,
      0.09596744924783707,
      0.0448678620159626,
      -0.01853441633284092,
      -0.06323421001434326,
      -0.006336646154522896,
      0.10852797329425812,
      -0.011904213577508926,
      -0.02058989740908146,
      -0.03859560564160347,
      -0.03826412931084633,
      -0.04322754219174385,
      -0.07237143069505692,
      0.0221371129155159,
      -0.022238194942474365,
      -0.005210176110267639,
      0.012260605581104755,
      -0.013502386398613453,
      0.07570820301771164,
      -0.030072465538978577,
      -0.0052861678414046764,
      -0.040546856820583344,
      -0.020287664607167244,
      0.005368777085095644,
      -0.02021077275276184,
      0.0879376083612442,
      0.0030699558556079865,
      0.05239409580826759,
      -0.05698726698756218,
      -0.005728662945330143,
      -0.0025074142031371593,
      -0.05575383082032204,
      -0.012091348879039288,
      -0.04239995777606964,
      0.027784138917922974,
      -0.024466289207339287,
      0.08686425536870956,
      -0.07007157802581787,
      0.025372186675667763,
      -0.01031084917485714,
      0.02128434367477894,
      0.003102271119132638,
      0.03533342108130455,
      0.017305156216025352,
      -0.033211883157491684,
      0.052893977612257004,
      0.012391237542033195,
      0.062238313257694244,
      -0.014327888377010822,
      -0.04787595570087433,
      0.005824904888868332,
      -0.06092460826039314,
      0.0032265654299408197,
      0.061429280787706375,
      -0.00980440340936184,
      -0.0011147756595164537,
      0.039931535720825195,
      -0.06205420568585396,
      0.01565910317003727,
      0.07282263040542603,
      0.02250995859503746,
      0.05431070178747177,
      -0.06241805478930473,
      0.055851683020591736,
      0.0012140822364017367,
      0.032696254551410675,
      0.04080476984381676,
      -0.015080397017300129,
      -0.07681537419557571,
      0.1370922178030014,
      0.01751328632235527,
      -0.00310344691388309,
      -0.041582830250263214,
      0.05783918872475624,
      -0.04896452650427818,
      0.0035833397414535284,
      0.0018109697848558426,
      0.010710290633141994,
      0.04319718852639198,
      -0.09765716642141342,
      -0.07903029769659042,
      -0.04391119256615639,
      -0.0016234020004048944,
      0.05890258029103279,
      0.07545538991689682,
      0.06363002955913544,
      -0.049787867814302444,
      -0.006845954339951277,
      0.009137436747550964,
      0.0076059200800955296,
      0.01689763367176056,
      0.01448026206344366,
      -0.007343559991568327,
      0.05940220504999161,
      0.025941144675016403,
      0.07834094762802124,
      -0.05238785594701767,
      -0.07484011352062225,
      0.08377328515052795,
      0.003449424169957638
    ],
    [
      0.031256865710020065,
      0.027168402448296547,
      -0.09958973526954651,
      -0.014748315326869488,
      0.003708726493641734,
      0.03028482384979725,
      0.006570103112608194,
      -0.02080540359020233,
      -0.0519070103764534,
      -0.06347771733999252,
      0.024719566106796265,
      -0.01865057647228241,
      -0.05220029503107071,
      0.007061500567942858,
      0.050562746822834015,
      -0.003557019867002964,
      -0.02251121588051319,
      0.05310481786727905,
      0.03149201348423958,
      0.08381324261426926,
      -0.03849250450730324,
      -0.005825648549944162,
      0.04601116478443146,
      -0.05991372838616371,
      -0.032713763415813446,
      -0.10352904349565506,
      -0.016688238829374313,
      0.0226542167365551,
      -0.017151888459920883,
      -0.07998524606227875,
      -0.01595398224890232,
      0.001168636605143547,
      -0.010312401689589024,
      0.05320185795426369,
      0.05011916160583496,
      -0.017107654362916946,
      0.008290358819067478,
      -0.05011198669672012,
      -0.020339077338576317,
      -0.015778731554746628,
      -0.04673753306269646,
      0.026196641847491264,
      -0.004185618367046118,
      0.04838845506310463,
      -0.030781559646129608,
      0.03454779088497162,
      -0.05311145260930061,
      -0.04017604514956474,
      0.006780209019780159,
      0.07162455469369888,
      0.027446096763014793,
      0.0005539032281376421,
      -0.026208961382508278,
      -0.04235853627324104,
      -0.013281906954944134,
      -0.023689132183790207,
      0.030417608097195625,
      0.03370296210050583,
      0.041545528918504715,
      -0.02814883552491665,
      -0.03200928494334221,
      -0.050907157361507416,
      -0.009727470576763153,
      0.022263161838054657,
      0.018081843852996826,
      0.04587199538946152,
      -0.030377238988876343,
      0.019293654710054398,
      -0.01709272712469101,
      0.02139538899064064,
      -0.006999751552939415,
      -0.001749931019730866,
      0.026055822148919106,
      0.02460378408432007,
      0.07052074372768402,
      -0.046062663197517395,
      -0.15034273266792297,
      0.04683433845639229,
      0.004725633189082146,
      0.02605576254427433,
      -0.0514698401093483,
      -0.056962985545396805,
      -0.09324517846107483,
      0.01692359708249569,
      -0.004632743541151285,
      -0.04978106543421745,
      -0.0017415208276361227,
      -0.022127877920866013,
      0.11792954802513123,
      -0.032520029693841934,
      0.05533881485462189,
      -0.016172654926776886,
      -0.035174399614334106,
      -0.010453909635543823,
      0.06365042924880981,
      -0.003150103148072958,
      -0.012372306548058987,
      -0.07339530438184738,
      0.019471537321805954,
      -0.010651488788425922,
      -0.0564308762550354,
      0.10157642513513565,
      0.05389408394694328,
      -0.007355728652328253,
      -0.0062844157218933105,
      0.02455674484372139,
      -0.02641012705862522,
      0.0887298658490181,
      -0.0442093200981617,
      -0.0234028622508049,
      0.01759098842740059,
      -0.11394082009792328,
      -0.020207639783620834,
      0.01606757938861847,
      -0.005627511069178581,
      0.02605605684220791,
      -0.04018090292811394,
      0.010688343085348606,
      -0.013400348834693432,
      0.007671145256608725,
      -0.044364191591739655,
      0.019727354869246483,
      0.0020433717872947454,
      0.04281138628721237,
      -0.023952797055244446,
      -0.0355837382376194,
      -0.007214512676000595,
      0.02900405041873455,
      -0.011679437011480331,
      0.013826452195644379,
      0.08448290079832077,
      -0.04214859753847122,
      -0.06620793044567108,
      -0.019104255363345146,
      0.08522134274244308,
      0.0259640421718359,
      -0.04388512670993805,
      -0.02160765416920185,
      0.029651690274477005,
      -0.031290434300899506,
      -0.05813862755894661,
      0.036265112459659576,
      0.06816602498292923,
      -0.03628809377551079,
      -0.017737964168190956,
      0.023428887128829956,
      0.010542517527937889,
      -0.030576877295970917,
      -0.048738960176706314,
      -0.004100837279111147,
      0.05755593255162239,
      -0.14598485827445984,
      0.0017959052929654717,
      0.024717779830098152,
      0.011359977535903454,
      -0.06386274844408035,
      -0.025994475930929184,
      0.0027983402833342552,
      0.016895150765776634,
      -0.029165856540203094,
      -0.039073336869478226,
      0.024744369089603424,
      0.03960742801427841,
      -0.05934154987335205,
      0.041738834232091904,
      -0.01862633228302002,
      0.028634484857320786,
      -0.0014839514624327421,
      0.08424359560012817,
      -0.06048502027988434,
      -0.016782229766249657,
      -0.0027844198048114777,
      -0.007586006540805101,
      0.029164671897888184,
      -0.07047604024410248,
      0.03939554840326309,
      0.008449026383459568,
      -0.03452443704009056,
      0.03757203742861748,
      -0.03209225833415985,
      -0.012528258375823498,
      0.0016679862746968865,
      -0.06169561296701431,
      0.0182728860527277,
      0.08848479390144348,
      0.03431411460042,
      -0.0033776487689465284,
      -0.03853202983736992,
      0.028150364756584167,
      0.03950091451406479,
      -0.01005440205335617,
      -0.032410189509391785,
      -0.04138021171092987,
      0.016462799161672592,
      -0.06984086334705353,
      -0.03996323421597481,
      0.059346068650484085,
      -0.08676254004240036,
      0.02775917388498783,
      -0.0038890372961759567,
      -0.10475032031536102,
      -0.00047480178182013333,
      0.03261745348572731,
      0.022606834769248962,
      0.011240705847740173,
      0.0034906836226582527,
      -0.00266102678142488,
      0.043546177446842194,
      -0.039910394698381424,
      0.03528612107038498,
      -0.005578533746302128,
      -0.011271885596215725,
      -0.035745877772569656,
      0.011216701939702034,
      0.006731338333338499,
      0.022691568359732628,
      0.041222844272851944,
      0.013931365683674812,
      -0.03272686526179314,
      -0.014315047301352024,
      0.04826812073588371,
      0.0512838289141655,
      0.01020524837076664,
      0.021011382341384888,
      0.02685375325381756,
      0.021813832223415375,
      0.011093671433627605,
      0.011957746930420399,
      0.014434607699513435,
      -0.030925370752811432,
      0.004859739448875189,
      0.015374800190329552,
      -0.01595151238143444,
      0.026950083673000336,
      -0.05450530722737312,
      -0.08229535818099976,
      0.021403096616268158,
      0.025985322892665863,
      0.06631962209939957,
      -0.005260286387056112,
      0.04027881845831871,
      -0.037415795028209686,
      -0.044680170714855194,
      0.054798226803541183,
      0.017435450106859207,
      -0.10782754421234131,
      -0.029173607006669044,
      0.010037672705948353,
      0.015630297362804413,
      0.030264368280768394,
      0.0009267117129638791,
      0.008356313221156597,
      0.05102279782295227,
      0.03545868769288063,
      -0.011120852082967758,
      0.0691324844956398,
      -0.14360477030277252,
      -0.04316240921616554,
      -0.06370996683835983,
      0.08741745352745056,
      -0.06088513880968094,
      -0.03757884353399277,
      -0.0466097854077816,
      -0.0012608617544174194,
      -0.07972640544176102,
      -0.05310598760843277,
      -0.001503748120740056,
      0.06293843686580658,
      -0.01891082338988781,
      -0.03657946735620499,
      -0.06453999876976013,
      0.028586113825440407,
      -0.04975871741771698,
      0.049589112401008606,
      0.044459596276283264,
      -0.025968611240386963,
      0.016282115131616592,
      -0.021880140528082848,
      0.04692889377474785,
      -0.0745677724480629,
      0.000560515618417412,
      -0.09709719568490982,
      0.03264779970049858,
      0.052988000214099884,
      -0.0031010594684630632,
      -0.04545547813177109,
      -0.055564995855093,
      -0.03978992626070976,
      0.06676282733678818,
      -0.0014800098724663258,
      0.10828450322151184,
      0.01210474967956543,
      0.041809432208538055,
      -0.002121265511959791,
      0.01077667810022831,
      -0.044895824044942856,
      0.04278826713562012,
      -0.0849524587392807,
      -0.007863524369895458,
      0.017897428944706917,
      0.04383549839258194,
      -0.015805501490831375,
      0.06960863620042801,
      0.03217799961566925,
      0.013226835988461971,
      -0.057982414960861206,
      0.030574031174182892,
      0.035247478634119034,
      -0.02863362245261669,
      -0.013397900387644768,
      0.022458326071500778,
      0.06912438571453094,
      0.05532548204064369,
      0.0044864630326628685,
      0.030708009377121925,
      0.0010362259345129132,
      0.050411149859428406,
      -0.02581181935966015,
      -0.042093854397535324,
      -0.023512162268161774,
      0.025258678942918777,
      -0.011000883765518665,
      0.028515513986349106,
      0.06985030323266983,
      0.02561485767364502,
      0.021215641871094704,
      0.023412605747580528,
      0.059531621634960175,
      0.03271681070327759,
      -0.019522031769156456,
      -0.024352870881557465,
      -0.006395286880433559,
      0.008467348292469978,
      0.02181926742196083,
      0.010412804782390594,
      -0.004352622665464878,
      0.006459492724388838,
      -0.009805794805288315,
      -0.07982950657606125,
      0.03299056366086006,
      0.03893124312162399,
      -0.010853549465537071,
      -0.0011711799306795,
      -0.005579433869570494,
      -0.012247607111930847,
      -0.007002678234130144,
      -0.008491246029734612,
      -0.036733683198690414,
      -0.040655702352523804,
      -0.023115823045372963,
      0.018551131710410118,
      -0.009657243266701698,
      0.02838081866502762,
      0.033908214420080185,
      0.044770497828722,
      0.06410215049982071,
      0.023071413859725,
      -0.08386728167533875,
      -0.007704770192503929,
      0.03878439962863922,
      0.0735568106174469,
      -0.01791602373123169,
      0.017334453761577606,
      -0.007438508793711662,
      -0.033990178257226944,
      -0.05000095069408417,
      -0.01668604277074337,
      -0.049251072108745575,
      -0.033822525292634964,
      -0.03657776117324829,
      -0.03881280496716499,
      0.09886249899864197,
      0.05056995898485184,
      -0.05325474590063095,
      0.007621276192367077,
      -0.03940993547439575,
      0.034981805831193924,
      0.038085225969552994,
      -0.08122869580984116,
      0.006593679543584585,
      -0.01610647514462471,
      -0.07009300589561462,
      -0.1056610569357872,
      0.008160481229424477,
      0.0235537588596344,
      0.033230286091566086,
      -0.060126300901174545,
      -0.0061792610213160515,
      0.012101128697395325,
      -0.010443486273288727,
      0.06311781704425812,
      -0.030124060809612274,
      -0.01622692123055458,
      -0.0751761719584465,
      0.00903894193470478,
      0.012204498052597046,
      -0.0602092407643795,
      0.015514145605266094,
      -0.04016634449362755,
      -0.02005406841635704,
      0.0255501177161932,
      -0.010194131173193455,
      0.03876074030995369,
      0.12394921481609344,
      0.008605996146798134,
      0.055207014083862305,
      0.025411373004317284,
      0.014676181599497795,
      -0.005729115102440119,
      -0.05290621146559715,
      0.03966449946165085,
      -0.04820570722222328,
      0.07078835368156433,
      -0.03303137049078941,
      0.028057269752025604,
      -0.0027274787425994873,
      0.0022717504762113094,
      -0.014730630442500114,
      0.03256472945213318,
      -0.03258629888296127,
      -0.04723415523767471,
      -0.020238976925611496,
      0.11584797501564026,
      -0.03157040849328041,
      0.028112240135669708,
      -0.0037804837338626385,
      0.02800905890762806,
      0.0729546770453453,
      0.03507784754037857,
      0.050459668040275574,
      -0.009721372276544571,
      0.021449599415063858,
      -0.01659916155040264,
      -0.012328681536018848,
      0.006735582370311022,
      0.0015531081007793546,
      0.008196852169930935,
      0.04310525208711624,
      -0.00997545663267374,
      0.01260340679436922,
      0.010952506214380264,
      0.048425763845443726,
      -0.02628997527062893,
      0.019283005967736244,
      -0.04479340463876724,
      0.0721575990319252,
      -0.052732281386852264,
      -0.03340158239006996,
      -0.022210178896784782,
      -0.02731233835220337,
      0.0013618390075862408,
      -0.00747211929410696,
      0.005902283359318972,
      -0.014563477598130703,
      0.08159934729337692,
      -0.02049686200916767,
      -0.0041581690311431885,
      -0.10672760009765625,
      -0.016888409852981567,
      -0.09579794853925705,
      -0.015395425260066986,
      0.07436048984527588,
      -0.016916440799832344,
      0.08024877309799194,
      -0.04189024493098259,
      0.03812220320105553,
      0.024796463549137115,
      0.013275634497404099,
      0.05550806596875191,
      0.01433221809566021,
      -0.061445266008377075,
      0.0035561169497668743,
      0.001365129603073001,
      0.02046298421919346,
      -0.008274504914879799,
      -0.017113342881202698,
      -0.014750685542821884,
      0.04866702854633331,
      0.022329920902848244,
      -0.06870591640472412,
      -0.02496398240327835,
      0.03680475801229477,
      -0.050289034843444824,
      0.015933807939291,
      0.014893041923642159,
      0.032324936240911484,
      0.006831020582467318,
      0.01881442405283451,
      -0.04225221276283264,
      -0.003928780555725098,
      -0.007563940715044737,
      0.013840019702911377,
      0.019802242517471313,
      0.08550318330526352,
      -0.006906097289174795,
      0.012920410372316837,
      -0.017451539635658264,
      -0.02678094431757927,
      -0.018753651529550552,
      0.026500066742300987,
      -0.028142854571342468,
      0.051966503262519836,
      -0.026035791262984276
    ],
    [
      0.0673014298081398,
      -0.028406456112861633,
      -0.028033627197146416,
      0.054898813366889954,
      0.03046288713812828,
      0.00869897659868002,
      0.054646268486976624,
      -0.03946073353290558,
      -0.0426362082362175,
      -0.0028106900863349438,
      -0.03278400003910065,
      0.008522812277078629,
      -0.052136246114969254,
      -0.05627476051449776,
      -0.031006623059511185,
      0.0201388131827116,
      -0.05130811035633087,
      -0.032436542212963104,
      -0.02383652701973915,
      0.03000101074576378,
      0.05456472560763359,
      0.009459352120757103,
      0.04317658394575119,
      -0.0025856660213321447,
      0.05539536848664284,
      -0.041505374014377594,
      0.045946817845106125,
      -0.0047258175909519196,
      0.058443162590265274,
      0.015812033787369728,
      -0.011361743323504925,
      0.0003407459589652717,
      -0.0006589554832316935,
      0.05810941383242607,
      0.019299717620015144,
      0.029865311458706856,
      -0.008762844838202,
      -0.08908937871456146,
      0.05158719792962074,
      0.0015212894650176167,
      -0.049190960824489594,
      0.047782327979803085,
      0.03635449707508087,
      0.014842705801129341,
      0.006873959209769964,
      -0.04737898334860802,
      -0.003583995858207345,
      -0.036201488226652145,
      -0.022519618272781372,
      0.00887411180883646,
      -0.029078489169478416,
      -0.012920835055410862,
      -0.06198287382721901,
      0.06338147073984146,
      0.018813852220773697,
      0.0015063501195982099,
      0.0004850025870837271,
      0.008278610184788704,
      0.08275550603866577,
      -0.04575273394584656,
      -0.04222574084997177,
      0.008840749971568584,
      -0.002691206056624651,
      -0.04375455528497696,
      -0.006330230738967657,
      0.008168734610080719,
      0.028067657724022865,
      0.0008833382744342089,
      0.018611915409564972,
      0.0435858890414238,
      -0.01097787357866764,
      0.056370172649621964,
      -0.04485202580690384,
      -0.08508842438459396,
      0.010436701588332653,
      -0.003241874510422349,
      -0.012498722411692142,
      0.10126423835754395,
      -0.02376861311495304,
      -0.08630652725696564,
      -0.01479012705385685,
      -0.026551246643066406,
      0.09918414801359177,
      0.000429973762948066,
      0.003336734836921096,
      -0.06857388466596603,
      0.021221516653895378,
      0.04047401621937752,
      -0.013813858851790428,
      0.004533357918262482,
      0.01747395098209381,
      0.012599964626133442,
      -0.05991333723068237,
      0.031406059861183167,
      0.0323917530477047,
      0.010614327155053616,
      -0.0727839544415474,
      -0.042249809950590134,
      0.01836520805954933,
      -0.09262092411518097,
      0.048413556069135666,
      0.05003591626882553,
      0.07579128444194794,
      -0.008134722709655762,
      0.016166573390364647,
      0.04336477071046829,
      0.013972979970276356,
      -0.009757365100085735,
      -0.02135201171040535,
      0.015076898969709873,
      -0.035617321729660034,
      -0.02406258136034012,
      0.002761957235634327,
      0.13919362425804138,
      -0.006993017625063658,
      -0.037806347012519836,
      -0.0015109892701730132,
      0.022776078432798386,
      -0.05329452082514763,
      0.07532631605863571,
      0.019484616816043854,
      0.027268938720226288,
      0.07686435431241989,
      -0.018977146595716476,
      0.029259013012051582,
      -0.020557479932904243,
      -0.04204521328210831,
      0.04339504614472389,
      0.01130630448460579,
      0.0668005719780922,
      0.00620281184092164,
      0.02503519132733345,
      0.0446268655359745,
      -0.022106073796749115,
      -0.030708564445376396,
      0.009827747941017151,
      -0.0524175651371479,
      -0.11381333321332932,
      0.050246432423591614,
      -0.00651053711771965,
      -0.07606898248195648,
      -0.05510786175727844,
      -0.054802149534225464,
      -0.026394331827759743,
      -0.05810745805501938,
      0.08644835650920868,
      -0.016649063676595688,
      -0.030202440917491913,
      0.0156468003988266,
      -0.026506362482905388,
      0.008946026675403118,
      -0.00698912562802434,
      0.06451500952243805,
      -0.050060439854860306,
      0.05674049258232117,
      0.007517322897911072,
      0.0058286720886826515,
      0.06951721012592316,
      -0.11196769773960114,
      -0.05061039701104164,
      0.05228523910045624,
      0.019693071022629738,
      0.0582711324095726,
      0.024510283023118973,
      0.0363553948700428,
      -0.025502176955342293,
      0.04847852513194084,
      0.0369131863117218,
      -0.014156527817249298,
      -0.056303612887859344,
      -0.04063938930630684,
      0.02826010063290596,
      0.03722206875681877,
      -0.03530126437544823,
      -0.0054192147217690945,
      -0.006794469431042671,
      -0.013651001267135143,
      -0.03687811642885208,
      0.038345906883478165,
      0.037799905985593796,
      0.020227139815688133,
      0.053903643041849136,
      0.018136803060770035,
      0.06335326284170151,
      -0.009822024032473564,
      -0.010269482620060444,
      0.028368748724460602,
      -0.007905438542366028,
      -0.004777399357408285,
      -0.0770275741815567,
      0.00462768180295825,
      0.04089992120862007,
      -0.04190099611878395,
      -0.04753122478723526,
      0.0469123050570488,
      -0.018640268594026566,
      -0.016641715541481972,
      0.03337865322828293,
      -0.023438245058059692,
      0.013105660676956177,
      -0.0015361728146672249,
      0.04964703321456909,
      -0.002810137812048197,
      0.07178584486246109,
      0.08688079565763474,
      0.02347353845834732,
      -0.07747676223516464,
      -0.10906117409467697,
      0.003577707801014185,
      0.014105397276580334,
      0.03502616658806801,
      0.048819709569215775,
      -0.029797447845339775,
      0.08821266889572144,
      -0.025060223415493965,
      -0.09255387634038925,
      -0.04767438769340515,
      0.050978392362594604,
      -0.022451480850577354,
      -0.03724142163991928,
      0.05174865201115608,
      0.019971458241343498,
      -0.0043266634456813335,
      -0.037332773208618164,
      -0.04332008212804794,
      0.09077777713537216,
      0.0028792486991733313,
      0.03286965936422348,
      0.011493883095681667,
      -0.05048437789082527,
      0.02342562936246395,
      -0.1388358324766159,
      0.021760165691375732,
      -0.005249674431979656,
      -0.0044716475531458855,
      -0.0362393893301487,
      0.0131156537681818,
      -0.039986662566661835,
      -0.0886930525302887,
      0.026406366378068924,
      0.03711394593119621,
      -0.012122228741645813,
      0.053448636084795,
      0.007375228684395552,
      0.00955349300056696,
      0.019077135249972343,
      0.07792182266712189,
      0.06231428310275078,
      0.006141703110188246,
      0.05020252242684364,
      -0.0222332626581192,
      -0.02094392478466034,
      0.0066783553920686245,
      -0.02577071078121662,
      0.010568270459771156,
      0.043554291129112244,
      0.07687260955572128,
      0.001723585301078856,
      0.01561019103974104,
      0.04101970046758652,
      -0.05895552411675453,
      -0.01753823645412922,
      -0.048741262406110764,
      0.0615629181265831,
      -0.019433854147791862,
      -0.041800159960985184,
      0.02617252804338932,
      -0.02356809563934803,
      0.03765317425131798,
      0.03080270066857338,
      -0.005085411947220564,
      0.005841478705406189,
      0.02637256681919098,
      -0.07358601689338684,
      -0.023244529962539673,
      -0.02386561408638954,
      -0.010093974880874157,
      0.03213885799050331,
      -0.0051445066928863525,
      0.009227734990417957,
      -0.009051060304045677,
      0.024631090462207794,
      -0.005967667326331139,
      0.023544996976852417,
      -0.04427725821733475,
      0.08949655294418335,
      0.02671784535050392,
      0.01062540989369154,
      -0.004326990805566311,
      0.037494268268346786,
      -0.0421069860458374,
      0.019526533782482147,
      0.04112518951296806,
      0.0012792769121006131,
      -0.06721771508455276,
      0.059936054050922394,
      -0.03239816427230835,
      -0.002842289861291647,
      0.028203845024108887,
      -0.05138937383890152,
      -0.06317921727895737,
      -0.020810913294553757,
      -0.030875666067004204,
      0.012481097131967545,
      0.0458855964243412,
      0.006858316715806723,
      0.10184488445520401,
      0.010148451663553715,
      0.06067714840173721,
      -0.047559842467308044,
      -0.04138820245862007,
      0.007369154132902622,
      -0.07579609751701355,
      -0.020716330036520958,
      -0.009963234886527061,
      0.03246765211224556,
      0.061295218765735626,
      0.010436436161398888,
      0.010158328339457512,
      0.010541860945522785,
      0.03438619151711464,
      -0.011555977165699005,
      0.0014866621932014823,
      -0.06635122746229172,
      0.08199998736381531,
      -0.016666822135448456,
      0.01665527932345867,
      -0.04305780678987503,
      0.026955824345350266,
      0.012498115189373493,
      0.05769108235836029,
      0.03606756776571274,
      0.005152108147740364,
      0.0019254399230703712,
      0.03603620454668999,
      -0.04778045415878296,
      -0.07610215991735458,
      -0.013006757013499737,
      0.055573370307683945,
      -0.03019016981124878,
      -0.005578141193836927,
      -0.05089819058775902,
      -0.0034280240070074797,
      0.035902898758649826,
      -0.009087705984711647,
      -0.03170352801680565,
      -0.01772966794669628,
      0.08720086514949799,
      -0.004326588939875364,
      0.09955588728189468,
      -0.08449916541576385,
      0.04515989124774933,
      -0.005916336551308632,
      0.006144089158624411,
      -0.07862423360347748,
      -0.042227815836668015,
      -0.0035076376516371965,
      0.06422306597232819,
      0.006724747829139233,
      0.011402741074562073,
      0.023541556671261787,
      -0.010470295324921608,
      -0.06517525017261505,
      -0.017197325825691223,
      -0.02152036689221859,
      -0.028949661180377007,
      -0.04241495206952095,
      0.0027478625997900963,
      0.01735801063477993,
      -0.01714208535850048,
      0.03556361049413681,
      -0.01842610165476799,
      0.04379578307271004,
      0.058717280626297,
      -0.019920596852898598,
      0.06678875535726547,
      -0.0382704995572567,
      -0.07846006751060486,
      0.04132623225450516,
      -0.0600268729031086,
      0.05843066796660423,
      0.08042863756418228,
      0.12596970796585083,
      0.04779885709285736,
      0.02590990625321865,
      0.06413579732179642,
      -0.038974300026893616,
      0.01236386876553297,
      -0.0356968455016613,
      0.053500983864068985,
      0.03735522925853729,
      -0.034246064722537994,
      0.04781617596745491,
      0.015682335942983627,
      -0.020000247284770012,
      0.023900486528873444,
      0.02407035045325756,
      0.016289617866277695,
      0.015384892001748085,
      -0.042145028710365295,
      -0.003835994051769376,
      0.050805240869522095,
      -0.023837681859731674,
      0.027685340493917465,
      0.014049029909074306,
      0.024757513776421547,
      -0.013690036721527576,
      -0.02877042256295681,
      0.036914996802806854,
      -0.05383306369185448,
      0.039567213505506516,
      -0.08436906337738037,
      0.042178552597761154,
      0.023135587573051453,
      -0.03812554478645325,
      -0.007512807846069336,
      0.05934903770685196,
      -0.06426620483398438,
      -0.00160116795450449,
      0.10134131461381912,
      0.05680106580257416,
      0.05818339064717293,
      0.006931743118911982,
      0.011206639930605888,
      0.04521022364497185,
      -0.015284321270883083,
      0.0007242534193210304,
      -0.04366112872958183,
      -0.04327377676963806,
      -0.020201604813337326,
      0.026891175657510757,
      0.008004451170563698,
      -0.02783459983766079,
      -0.01609027571976185,
      0.05423552915453911,
      0.00039216195000335574,
      0.026472071185708046,
      0.01233339961618185,
      -0.03216463699936867,
      -0.02263873629271984,
      0.12036649882793427,
      -0.04047194868326187,
      -0.03401444852352142,
      0.014922759495675564,
      -0.05622556805610657,
      -0.020896142348647118,
      0.012674990110099316,
      -0.020313413813710213,
      -0.0805988609790802,
      0.05421214550733566,
      0.0013719701673835516,
      0.026435259729623795,
      -0.042501602321863174,
      0.029682399705052376,
      0.07294601202011108,
      0.01588585413992405,
      0.0428718626499176,
      0.025928033515810966,
      0.0755670964717865,
      0.018272442743182182,
      -0.0477311871945858,
      0.02574155665934086,
      0.07525970041751862,
      0.003192927222698927,
      0.14124426245689392,
      0.0441281758248806,
      -0.0728626623749733,
      -0.008503436110913754,
      -0.0979519784450531,
      0.039001353085041046,
      0.0012068236246705055,
      0.001291553140617907,
      -0.0010330566437914968,
      -0.01879005692899227,
      0.010385770350694656,
      0.009166592732071877,
      0.019344335421919823,
      -0.026195380836725235,
      0.008743603713810444,
      -0.021906308829784393,
      0.05339549481868744,
      -0.008311919867992401,
      0.05389497056603432,
      -0.040545471012592316,
      0.0473552942276001,
      -0.010302656330168247,
      -0.036517612636089325,
      0.002056359313428402,
      -0.0659625381231308,
      -0.006572372280061245,
      -0.05445067957043648,
      0.04917452484369278,
      0.005280375946313143,
      -0.003949149511754513,
      0.030682506039738655,
      0.061943888664245605,
      -0.06094139814376831,
      0.024339955300092697,
      -0.024965280666947365,
      -0.0372888557612896,
      -0.061297088861465454,
      -0.005129707511514425,
      -0.05055708438158035
    ],
    [
      -0.03674291446805,
      -0.01081889495253563,
      -0.004484296776354313,
      0.059341710060834885,
      0.0005173051031306386,
      -0.006554934196174145,
      0.1385432332754135,
      0.08380674570798874,
      0.06504467129707336,
      0.10969970375299454,
      -0.026012321934103966,
      -0.00019836802675854415,
      0.05177658423781395,
      0.09223513305187225,
      0.00011128217010991648,
      0.07780338823795319,
      0.038659606128931046,
      -0.004204503260552883,
      -0.044381946325302124,
      -0.05248674005270004,
      -0.03419076278805733,
      -0.011713992804288864,
      0.01798236556351185,
      -0.038714975118637085,
      0.02218404971063137,
      -0.040840327739715576,
      -0.05068117752671242,
      -0.04007330909371376,
      0.00958302989602089,
      -0.001106671872548759,
      0.0023006226401776075,
      -0.035363178700208664,
      0.0212737787514925,
      0.01608748733997345,
      0.01562557741999626,
      0.01806425303220749,
      -0.021964088082313538,
      -0.04663293808698654,
      0.057140231132507324,
      0.009192424826323986,
      -0.011915228329598904,
      -0.06448613107204437,
      0.019042644649744034,
      -0.007204932626336813,
      0.024892743676900864,
      -0.024755578488111496,
      -0.019328078255057335,
      -0.03641713410615921,
      0.03803377225995064,
      -0.0012429294874891639,
      -0.08017098158597946,
      -0.038107190281152725,
      -0.02845105342566967,
      0.009809954091906548,
      0.05386299639940262,
      -0.022353902459144592,
      0.009728428907692432,
      -0.013065878301858902,
      0.00292877946048975,
      -0.01509662065654993,
      0.022582005709409714,
      -0.02291286177933216,
      0.07020821422338486,
      0.01887674070894718,
      0.004009266383945942,
      -0.06710202991962433,
      0.01850026845932007,
      0.0077889785170555115,
      0.109786257147789,
      -0.09341875463724136,
      0.01227090135216713,
      0.03383718430995941,
      0.007926256395876408,
      0.035325877368450165,
      -0.009964657947421074,
      -0.060471437871456146,
      -0.05389529839158058,
      0.05458812788128853,
      0.07056376338005066,
      -0.022365780547261238,
      0.0042120302096009254,
      -0.06961823254823685,
      -0.08426300436258316,
      -0.06628179550170898,
      -0.09378227591514587,
      -0.03253861889243126,
      0.019458910450339317,
      0.0012260533403605223,
      -0.04758856073021889,
      0.025586983188986778,
      0.011420661583542824,
      0.0474117286503315,
      -0.0042880140244960785,
      -0.03718677535653114,
      -0.025031909346580505,
      -0.010687545873224735,
      0.00343307014554739,
      -0.07195419818162918,
      0.05641584470868111,
      -0.005027045961469412,
      0.0022146322298794985,
      -0.05976259708404541,
      0.015875518321990967,
      -0.008636513724923134,
      -0.028303328901529312,
      -0.03535433113574982,
      0.005540622863918543,
      0.01637747883796692,
      0.0009147094679065049,
      -0.0801379457116127,
      -0.011680325493216515,
      0.004471812397241592,
      0.04599155858159065,
      0.028375735506415367,
      0.0454634465277195,
      0.05753610283136368,
      -0.01345431711524725,
      -0.05437926575541496,
      0.039908528327941895,
      -0.00682992534711957,
      0.03814484179019928,
      -0.01851603575050831,
      -0.03739972785115242,
      -0.004466995131224394,
      -0.006104921456426382,
      0.06664782762527466,
      0.05399372801184654,
      0.0027598082087934017,
      0.004256947431713343,
      0.020158791914582253,
      0.03101371042430401,
      -0.030235659331083298,
      0.041543010622262955,
      -0.0017535845981910825,
      0.03410142660140991,
      0.023389430716633797,
      -0.04393669217824936,
      -0.020531058311462402,
      0.08966515958309174,
      -0.06661581248044968,
      -0.007627525832504034,
      0.0406314916908741,
      -0.036716707050800323,
      0.03319365158677101,
      0.03148067742586136,
      -0.036641787737607956,
      0.0547955147922039,
      -0.04278386011719704,
      -0.02922605723142624,
      0.0004611943440977484,
      0.002704653423279524,
      -0.04316956177353859,
      -0.03570059686899185,
      -0.006927181966602802,
      -0.011189359240233898,
      0.05295748636126518,
      0.004149880725890398,
      -0.03788113594055176,
      -0.04883167892694473,
      -0.11080922186374664,
      -0.03742437809705734,
      0.08507619053125381,
      -0.08884239941835403,
      -0.00822413619607687,
      -0.018075210973620415,
      -0.07788575440645218,
      -0.055225782096385956,
      -0.06353137642145157,
      0.02929302118718624,
      0.04246082156896591,
      -0.06613309681415558,
      0.012132336385548115,
      0.014754898846149445,
      0.03543621301651001,
      -0.014339813031256199,
      -0.008982840925455093,
      -0.03400902450084686,
      0.022365931421518326,
      0.028772661462426186,
      -0.0054291486740112305,
      0.027136821299791336,
      -0.03447308763861656,
      0.05273198336362839,
      0.02517196349799633,
      0.053161486983299255,
      0.0012219662312418222,
      -0.034623436629772186,
      -0.004438820295035839,
      0.05283895879983902,
      0.019825415685772896,
      0.0721302330493927,
      0.06303983181715012,
      0.046246010810136795,
      0.043495457619428635,
      0.05333135649561882,
      -0.027124635875225067,
      0.04836380481719971,
      -0.04590825363993645,
      -0.05038927495479584,
      0.04748501628637314,
      -0.03905197232961655,
      0.037851184606552124,
      0.03988390415906906,
      0.06511190533638,
      0.08273621648550034,
      -0.03448398783802986,
      -0.012068456038832664,
      -0.0186759140342474,
      0.034700024873018265,
      -0.008338057436048985,
      -0.018895287066698074,
      0.0006277178763411939,
      0.03733550012111664,
      0.01354211289435625,
      -0.04682295024394989,
      -0.031184716150164604,
      0.02221396192908287,
      0.09228090941905975,
      -0.03901839628815651,
      -0.0909002497792244,
      0.006499499548226595,
      0.03228233754634857,
      -0.014091528952121735,
      -0.06428267061710358,
      0.004333381075412035,
      0.033012691885232925,
      0.00017009333532769233,
      -0.05508183315396309,
      -0.048780906945466995,
      -0.04518505558371544,
      0.0010986813576892018,
      0.042081765830516815,
      -0.018201006576418877,
      0.0321989431977272,
      -0.033791862428188324,
      0.006373885087668896,
      0.06219726800918579,
      0.02178351953625679,
      -0.0296944472938776,
      0.031616609543561935,
      0.056496262550354004,
      -0.0046766335144639015,
      -0.0077537428587675095,
      0.021999310702085495,
      -0.01566334068775177,
      -0.01694207265973091,
      -0.09438014030456543,
      0.03172611817717552,
      0.06534155458211899,
      -0.0194038487970829,
      -0.03600528463721275,
      0.033366650342941284,
      0.017665952444076538,
      0.003875200869515538,
      0.04086438566446304,
      0.1066688746213913,
      -0.020670609548687935,
      0.004582714289426804,
      -0.029156304895877838,
      0.029858890920877457,
      0.021274855360388756,
      0.052927542477846146,
      -0.07285905629396439,
      0.00798504427075386,
      -0.07043034583330154,
      -0.021131351590156555,
      0.045282650738954544,
      -0.03325191140174866,
      0.00396460946649313,
      0.0517985001206398,
      -0.09246815741062164,
      0.017624741420149803,
      -0.007007472217082977,
      -0.0778951495885849,
      0.008365824818611145,
      -0.03266119584441185,
      0.002539973706007004,
      -0.10536466538906097,
      0.04117674008011818,
      0.06646489351987839,
      0.03642362728714943,
      0.017349401488900185,
      -0.002133382484316826,
      -0.00011796487524406984,
      -0.04285862669348717,
      0.06093627214431763,
      -0.002399100223556161,
      0.0035454779863357544,
      0.015666861087083817,
      0.02803182043135166,
      -0.031596146523952484,
      -0.007761812303215265,
      0.02879241853952408,
      0.0866437554359436,
      0.05831560492515564,
      -0.08477415889501572,
      0.01999017968773842,
      -0.010006553493440151,
      0.061680953949689865,
      -0.028596844524145126,
      0.0585826076567173,
      0.06603076308965683,
      -0.0778282955288887,
      -0.0323297381401062,
      0.06280340999364853,
      0.043408263474702835,
      0.022969868034124374,
      0.010160021483898163,
      0.021026941016316414,
      0.012428456917405128,
      -0.006605866830796003,
      -0.0433516800403595,
      0.07413839548826218,
      0.04240009933710098,
      0.03434585779905319,
      -0.018363621085882187,
      -0.04557656869292259,
      -0.03130848705768585,
      0.02130340039730072,
      -0.04031732305884361,
      -0.03793495520949364,
      0.08828712999820709,
      -0.03975718840956688,
      -0.05820097774267197,
      -0.01143371220678091,
      -0.08361968398094177,
      0.007483240216970444,
      0.09616341441869736,
      0.07954049855470657,
      -0.012287025339901447,
      0.011558163911104202,
      -0.03990933299064636,
      -0.010821365751326084,
      -0.07616709917783737,
      -0.010784884914755821,
      -0.07513891905546188,
      -0.013452000916004181,
      0.03988953307271004,
      0.010133418254554272,
      -0.02510184980928898,
      -0.09696650505065918,
      0.07451590150594711,
      0.0003310738829895854,
      -0.11289259791374207,
      -0.050202831625938416,
      0.027822168543934822,
      -0.011952054686844349,
      -0.004978010896593332,
      0.0781787782907486,
      0.03477698564529419,
      0.010065094567835331,
      -0.027296701446175575,
      -0.008564873598515987,
      0.0301821231842041,
      -0.03077404759824276,
      -0.07975266128778458,
      -0.12242789566516876,
      0.02770940586924553,
      -0.006966353859752417,
      -0.017483675852417946,
      -0.014318411238491535,
      -0.054018888622522354,
      0.036863960325717926,
      -0.05495685338973999,
      -0.012448430061340332,
      0.035093147307634354,
      0.05324186012148857,
      0.11174073070287704,
      0.036857157945632935,
      0.08881312608718872,
      -0.09480804204940796,
      -0.09011610597372055,
      0.04721399396657944,
      -0.051023874431848526,
      -0.04123842343688011,
      0.001087385113351047,
      -0.017226124182343483,
      0.13172900676727295,
      -0.03645661473274231,
      0.039815809577703476,
      -0.009557927027344704,
      -0.011252815835177898,
      0.023603396490216255,
      -0.06861317902803421,
      0.04842867702245712,
      -0.0506754107773304,
      -0.04940367490053177,
      -0.041585419327020645,
      0.04237039387226105,
      0.0675312876701355,
      -0.039064671844244,
      0.06397339701652527,
      0.06818215548992157,
      -0.019804589450359344,
      -0.06379082798957825,
      -0.03177132084965706,
      0.017042238265275955,
      0.056432049721479416,
      -0.015963571146130562,
      0.030206190422177315,
      -0.04548415541648865,
      -0.03829681873321533,
      -0.007593074813485146,
      -0.01987592875957489,
      0.05567669868469238,
      -0.06615040451288223,
      0.009408383630216122,
      0.016451777890324593,
      -0.07996360957622528,
      0.07623004913330078,
      -0.06228230893611908,
      0.0538799874484539,
      -0.0605132095515728,
      -0.0058448719792068005,
      -0.0024446581955999136,
      0.06830140948295593,
      -0.016502099111676216,
      -0.00292022991925478,
      0.036450352519750595,
      0.019521305337548256,
      0.07041924446821213,
      0.031871307641267776,
      0.016159702092409134,
      -0.03171417862176895,
      -0.04849496856331825,
      0.032692763954401016,
      0.004697461612522602,
      0.05721726641058922,
      -0.050521060824394226,
      -0.1322879046201706,
      -0.07126789540052414,
      -0.04783075302839279,
      0.0935342013835907,
      -0.01935277134180069,
      0.031088953837752342,
      0.05443631857633591,
      -0.02824203111231327,
      0.015832137316465378,
      0.02957722544670105,
      -0.020892640575766563,
      -0.025066806003451347,
      -0.000493577856104821,
      0.015426678583025932,
      0.02568182907998562,
      -0.13903427124023438,
      0.09371808916330338,
      -0.01339526567608118,
      0.025133637711405754,
      0.11990455538034439,
      -0.04242156445980072,
      0.014050450176000595,
      0.03471062704920769,
      0.054854005575180054,
      -0.004515116568654776,
      0.04613160714507103,
      -0.062405116856098175,
      -0.07009758800268173,
      -0.02991708181798458,
      0.0652398094534874,
      0.03016028180718422,
      -0.09010143578052521,
      -0.020472519099712372,
      -0.01390857994556427,
      0.019870681688189507,
      0.06462297588586807,
      0.04773559048771858,
      0.013165702112019062,
      -0.028376642614603043,
      -0.07055435329675674,
      -0.0554204024374485,
      -0.0012199206976220012,
      -0.008232802152633667,
      0.006570977158844471,
      -0.026567185297608376,
      0.058942969888448715,
      -0.10197772830724716,
      0.015025447122752666,
      -0.01743992418050766,
      0.010057187639176846,
      0.050141144543886185,
      -0.04432365298271179,
      0.04858195036649704,
      -0.08013501018285751,
      0.001407313859090209,
      -0.01541297696530819,
      0.014499932527542114,
      -0.04403911158442497,
      0.08551907539367676,
      0.0036036460660398006,
      0.024583354592323303,
      -0.013559997081756592,
      -0.011269687674939632,
      -0.04949004203081131,
      -0.007988626137375832,
      0.002931805793195963,
      -0.007225407287478447,
      0.00923374854028225,
      -0.04472967982292175,
      0.010198472067713737,
      -0.001926220254972577,
      -0.0060768513940274715,
      0.0249470341950655,
      -0.020195234566926956
    ],
    [
      0.006998633500188589,
      0.051154863089323044,
      0.014547904022037983,
      0.08549682796001434,
      -0.04596097767353058,
      0.048788443207740784,
      0.013188060373067856,
      0.006382404826581478,
      -0.04892432317137718,
      0.13335473835468292,
      -0.04314441606402397,
      -0.039729006588459015,
      0.006227291654795408,
      -0.014782695099711418,
      0.0031512114219367504,
      -0.014255665242671967,
      -0.034614305943250656,
      0.002298087114468217,
      0.039660125970840454,
      0.002614016877487302,
      0.0009229627321474254,
      -0.046174366027116776,
      0.04493512585759163,
      -0.10312657803297043,
      -0.004733895417302847,
      0.053586818277835846,
      0.008061147294938564,
      -0.008344714529812336,
      -0.06318125128746033,
      0.026331733912229538,
      0.03284422308206558,
      -0.002109771827235818,
      -0.01052623987197876,
      0.01148295309394598,
      0.007051478140056133,
      0.046973563730716705,
      0.01519289705902338,
      -0.046119995415210724,
      -0.014420351013541222,
      -0.0014277705922722816,
      -0.022332215681672096,
      -0.06214204803109169,
      0.027745407074689865,
      -0.08486440032720566,
      0.012828108854591846,
      -0.032020747661590576,
      0.012484208680689335,
      -0.03745293989777565,
      0.0009648073464632034,
      0.012748422101140022,
      -0.038458388298749924,
      -0.03695424646139145,
      0.014893004670739174,
      0.05827971175312996,
      0.012739230878651142,
      0.009763418696820736,
      0.023455385118722916,
      0.01188289187848568,
      0.004735255613923073,
      -0.045091770589351654,
      -0.053111784160137177,
      -0.05945263057947159,
      -0.07677510380744934,
      -0.0627654418349266,
      0.02400270104408264,
      0.09503329545259476,
      -0.013830947689712048,
      0.11421272158622742,
      0.05116308480501175,
      0.06892937421798706,
      -0.04264269024133682,
      0.05709446594119072,
      -0.02207467146217823,
      -0.07304875552654266,
      0.06443305313587189,
      -0.03262666240334511,
      -0.028943832963705063,
      -0.07496954500675201,
      -0.04300697520375252,
      0.05637655034661293,
      -0.027734583243727684,
      -0.09229590743780136,
      0.060238052159547806,
      0.027031542733311653,
      -0.0350223071873188,
      -0.05231274664402008,
      0.021107573062181473,
      0.08051333576440811,
      -0.02189064584672451,
      -0.1296800673007965,
      -0.01638329029083252,
      -0.021663371473550797,
      0.021995119750499725,
      -0.015105866827070713,
      -0.09660473465919495,
      -0.05816277489066124,
      0.008502808399498463,
      0.016664741560816765,
      -0.06221667304635048,
      0.04591469094157219,
      0.016059521585702896,
      -0.016496386379003525,
      0.02189568430185318,
      0.01010733749717474,
      0.005923599470406771,
      -0.0004540572699625045,
      -0.02557945065200329,
      -0.04787150397896767,
      0.006618544925004244,
      -0.0008659197483211756,
      0.06183355301618576,
      -0.01249806396663189,
      -0.003651711856946349,
      0.0020100718829780817,
      0.07249219715595245,
      -0.03449569270014763,
      -0.012703155167400837,
      -0.06900309771299362,
      -0.022431522607803345,
      -0.004978370852768421,
      0.021191516891121864,
      -0.04431159794330597,
      0.00423904974013567,
      -0.005924407858401537,
      -0.011817597784101963,
      0.03050324320793152,
      -0.06435320526361465,
      0.01651092618703842,
      0.053180355578660965,
      0.05543271824717522,
      0.04853405803442001,
      -0.012951860204339027,
      -0.041266001760959625,
      0.01585986465215683,
      -0.0327417254447937,
      0.041237108409404755,
      -0.028744319453835487,
      0.007471983786672354,
      -0.05592108517885208,
      -0.01797245256602764,
      -0.03393279388546944,
      0.009109613485634327,
      0.025162074714899063,
      0.03858265280723572,
      0.062012746930122375,
      0.033221300691366196,
      0.02303187921643257,
      -0.0018446086905896664,
      0.0049452711828053,
      0.030676018446683884,
      -0.0011229395167902112,
      0.02014290913939476,
      0.06603633612394333,
      -0.015591730363667011,
      -0.02522684447467327,
      -0.03043805994093418,
      -0.04539736360311508,
      0.11006996780633926,
      0.015857722610235214,
      -0.015037314966320992,
      -0.06517459452152252,
      0.049718860536813736,
      -0.008037903346121311,
      0.02773316577076912,
      -0.046297769993543625,
      0.02145553007721901,
      0.012956925667822361,
      -0.04298144951462746,
      0.054133106023073196,
      0.0666775032877922,
      0.023336151614785194,
      0.050713568925857544,
      -0.11327862739562988,
      0.013218524865806103,
      0.016796208918094635,
      -0.012756484560668468,
      0.009517379105091095,
      -0.08141275495290756,
      -0.04096212983131409,
      0.02520054765045643,
      -0.008784854784607887,
      -0.018177898600697517,
      0.02511429600417614,
      -0.030883578583598137,
      0.028279317542910576,
      -0.0038979239761829376,
      -0.018280385062098503,
      0.00790710374712944,
      0.013605666346848011,
      0.015608943067491055,
      0.06771651655435562,
      -0.036073993891477585,
      -0.024818090721964836,
      -0.03366297855973244,
      0.0969177708029747,
      -0.020603515207767487,
      0.04553503170609474,
      -0.07611856609582901,
      0.06576007604598999,
      0.0002042336855083704,
      0.011733613908290863,
      0.004907893482595682,
      0.036928821355104446,
      0.0031537897884845734,
      0.033991824835538864,
      0.012846109457314014,
      -0.05919215455651283,
      0.0031668017618358135,
      0.010650631040334702,
      -0.01236590463668108,
      0.02650810405611992,
      0.03739822283387184,
      -0.02567949704825878,
      0.009470699355006218,
      -0.03021836280822754,
      -0.007295577321201563,
      0.0025867249350994825,
      0.02733755297958851,
      0.04629781097173691,
      -0.01128026470541954,
      0.07047213613986969,
      0.0059513733722269535,
      -0.08916380256414413,
      0.01286256592720747,
      -0.03806976228952408,
      1.2569565114972647e-05,
      -0.00878855213522911,
      0.0006624243105761707,
      -0.005748112685978413,
      0.03532334417104721,
      0.011938759125769138,
      0.0392911396920681,
      0.0337933711707592,
      0.018148060888051987,
      -0.024921812117099762,
      0.007351542357355356,
      -0.04067287594079971,
      -0.009774782694876194,
      0.09467194974422455,
      -0.008550196886062622,
      -0.009900718927383423,
      0.001639273134060204,
      -0.018609216436743736,
      0.0012885408941656351,
      -0.030942227691411972,
      0.07851843535900116,
      -0.032823964953422546,
      -0.0026711325626820326,
      0.04589246213436127,
      0.024494588375091553,
      -0.05786782503128052,
      0.045317571610212326,
      0.026806045323610306,
      0.009616059251129627,
      0.01903487555682659,
      0.037765275686979294,
      -0.08382024616003036,
      0.03490494191646576,
      -0.03394024819135666,
      0.03590202331542969,
      -0.008462907746434212,
      0.04496845230460167,
      0.03077874705195427,
      0.01989731937646866,
      -0.03656690940260887,
      0.025067633017897606,
      -0.019040970131754875,
      -0.08899262547492981,
      0.0524437241256237,
      0.01549529004842043,
      0.012182768434286118,
      -0.050003573298454285,
      0.07263719290494919,
      -0.054202377796173096,
      0.016547825187444687,
      -0.06047186255455017,
      0.02136833965778351,
      0.0967211052775383,
      -0.031178515404462814,
      0.0077643911354243755,
      -0.00016403268091380596,
      -0.046634480357170105,
      0.09659901261329651,
      -0.003949735779315233,
      0.023363055661320686,
      0.06407375633716583,
      -0.012650149874389172,
      0.019976617768406868,
      -0.05184542015194893,
      0.040381886065006256,
      0.024247074499726295,
      0.04030674695968628,
      -0.004854728002101183,
      0.002348652807995677,
      -0.03634544834494591,
      -0.02492472715675831,
      0.04603071138262749,
      0.06900794804096222,
      0.008855363354086876,
      -0.04643573984503746,
      -0.022013993933796883,
      0.025527600198984146,
      0.09767987579107285,
      0.008920451626181602,
      0.02795477956533432,
      -0.03008084185421467,
      -0.05963142216205597,
      -0.02890993282198906,
      -0.04568423703312874,
      0.023351913318037987,
      0.0022600959055125713,
      0.05741589888930321,
      -0.005281675606966019,
      0.03445514664053917,
      -0.026466475799679756,
      -0.013400372117757797,
      0.0408548004925251,
      -0.02390718273818493,
      0.025204438716173172,
      -0.060063086450099945,
      -0.07276994735002518,
      0.0571817010641098,
      -0.08046597242355347,
      0.003201435087248683,
      0.10737821459770203,
      0.058494795113801956,
      -0.002783210715278983,
      0.015462268143892288,
      0.052540089935064316,
      0.07217288017272949,
      0.023563366383314133,
      0.006235751789063215,
      0.013720137067139149,
      -0.02851434238255024,
      0.04211461916565895,
      -0.019950171932578087,
      0.022464681416749954,
      0.09909109771251678,
      0.043818503618240356,
      -0.08631554991006851,
      0.09246913343667984,
      0.008478381671011448,
      -0.08034899830818176,
      0.007658734917640686,
      -0.015143778175115585,
      -0.05535081773996353,
      -0.007067319005727768,
      0.013755670748651028,
      -0.040004663169384,
      -0.004513024352490902,
      -0.0038481703959405422,
      -0.046130213886499405,
      0.06691838800907135,
      -0.03194480761885643,
      0.035945020616054535,
      0.03949272260069847,
      0.006963090039789677,
      -0.0726480558514595,
      -0.027856504544615746,
      0.0018731962190940976,
      -0.007867547683417797,
      0.01620176061987877,
      -0.015403865836560726,
      -0.03767832741141319,
      -0.01561722718179226,
      -0.04436114430427551,
      -0.1278601437807083,
      -0.019885459914803505,
      0.07036560773849487,
      0.05270104855298996,
      0.04218212887644768,
      0.047932855784893036,
      -0.009660056792199612,
      -0.040683601051568985,
      0.030271533876657486,
      -0.01264098845422268,
      0.019123638048768044,
      0.046990856528282166,
      0.05262533575296402,
      0.0010409681126475334,
      0.01686837151646614,
      -0.10499503463506699,
      0.03331872448325157,
      0.01977476477622986,
      0.03126426041126251,
      0.01850363239645958,
      -0.028500007465481758,
      -0.055022817105054855,
      0.05838605388998985,
      0.016761377453804016,
      -0.06000727787613869,
      -0.02595987729728222,
      0.0015488151693716645,
      -0.024831248447299004,
      -0.04710378870368004,
      -0.07851425558328629,
      -0.027987519279122353,
      -0.03613787144422531,
      -0.07357963919639587,
      0.0006824898882769048,
      -0.03087373822927475,
      -0.025540802627801895,
      -0.005452627316117287,
      0.012933697551488876,
      -0.04846495762467384,
      -0.006440385244786739,
      -0.06482584029436111,
      -0.014383448287844658,
      -0.0271805040538311,
      0.014992562122642994,
      0.01596182771027088,
      0.014386756345629692,
      0.0009211011347360909,
      0.036195866763591766,
      -0.02356189489364624,
      -0.03955897316336632,
      -0.006119559518992901,
      0.06092262640595436,
      0.08064524829387665,
      -0.04087168723344803,
      0.057477883994579315,
      0.0043354034423828125,
      -0.03843807801604271,
      0.0475037507712841,
      -0.08064239472150803,
      -0.019713684916496277,
      0.0057908897288143635,
      -0.0360163152217865,
      -0.0543973334133625,
      -0.006876901723444462,
      -0.005499872844666243,
      0.05154372751712799,
      0.0378984771668911,
      -0.013263155706226826,
      -0.005889373365789652,
      0.04167746752500534,
      -0.0510762557387352,
      0.02558472752571106,
      0.08408019691705704,
      0.08895711600780487,
      0.01070642564445734,
      0.027014652267098427,
      -0.056986816227436066,
      0.0566096305847168,
      -0.03527636453509331,
      0.03710469231009483,
      -0.042108334600925446,
      -0.0323890820145607,
      -0.03342628479003906,
      0.005656974855810404,
      -0.009960491210222244,
      0.001348900725133717,
      -0.003960810136049986,
      -0.004440691787749529,
      0.07872264832258224,
      -0.07681373506784439,
      0.08666984736919403,
      -0.045588258653879166,
      -0.04539252817630768,
      0.058892570436000824,
      -0.006274273153394461,
      0.02944551222026348,
      -0.03442946821451187,
      0.0009074280387721956,
      0.006453678943216801,
      -0.03386828303337097,
      0.06469954550266266,
      -0.14023567736148834,
      0.017303064465522766,
      -0.017498023808002472,
      0.004507692065089941,
      0.054741326719522476,
      0.10186675190925598,
      -0.014936334453523159,
      0.039674412459135056,
      -0.07266075909137726,
      0.04804538935422897,
      -0.035655658692121506,
      -0.02380755916237831,
      -0.010877312161028385,
      -0.021672053262591362,
      0.04693432152271271,
      -0.023214424028992653,
      -0.08431663364171982,
      0.024933693930506706,
      -0.02458067052066326,
      0.17298589646816254,
      -0.06096361204981804,
      0.058741021901369095,
      -0.02951040305197239,
      0.050215721130371094,
      0.03618379309773445,
      -0.03171895816922188,
      0.01665600948035717,
      -0.0006404422456398606,
      0.03068072721362114,
      0.003964695148169994,
      0.027577277272939682,
      0.028978535905480385,
      0.06351149827241898,
      0.001794684212654829,
      0.038419295102357864,
      0.04768113046884537
    ],
    [
      -0.0028154058381915092,
      0.015500372275710106,
      0.03371022269129753,
      -0.0037458681035786867,
      0.01449199765920639,
      0.01100356224924326,
      -0.016693908721208572,
      0.01847330667078495,
      0.10972680896520615,
      -0.049008939415216446,
      0.04249657317996025,
      -0.047384753823280334,
      0.0379200205206871,
      0.021450122818350792,
      0.0413406565785408,
      -0.06205809861421585,
      0.015592418611049652,
      0.023715918883681297,
      0.05710609629750252,
      0.023327184841036797,
      0.008711999282240868,
      0.026012668386101723,
      0.050196338444948196,
      -0.04457878693938255,
      -0.06308940798044205,
      -0.022265098989009857,
      0.040057290345430374,
      0.004752805456519127,
      -0.013794904574751854,
      0.07331762462854385,
      -0.03900504484772682,
      -0.01914140395820141,
      0.0010587992146611214,
      0.08740562945604324,
      0.09460514783859253,
      -0.02469777688384056,
      -0.029327306896448135,
      0.050683118402957916,
      -0.01608230546116829,
      -0.012152648530900478,
      -0.0009613970178179443,
      -0.02845674194395542,
      -0.04404572397470474,
      -0.03430885076522827,
      -0.059766191989183426,
      0.008395129814743996,
      -0.0004724479222204536,
      -0.018709179013967514,
      -0.0032413629814982414,
      -0.00605165958404541,
      -0.00030048759072087705,
      -0.0010184812126681209,
      0.032035935670137405,
      -0.0450461246073246,
      -0.02501136250793934,
      0.009823935106396675,
      0.07705745846033096,
      0.002583132591098547,
      -0.06132025644183159,
      0.033992424607276917,
      -0.008999296464025974,
      -0.05188614875078201,
      -0.028917940333485603,
      -0.02223855070769787,
      0.017674222588539124,
      -0.009019864723086357,
      0.020731186494231224,
      -0.06926841288805008,
      0.024078212678432465,
      -0.004913675133138895,
      0.024149438366293907,
      -0.008306753821671009,
      0.09166430681943893,
      -0.0401732474565506,
      0.049611788243055344,
      -0.023534376174211502,
      0.03703075274825096,
      0.06518004834651947,
      0.04280376061797142,
      0.025298872962594032,
      0.06135310232639313,
      0.036661941558122635,
      -0.0371098555624485,
      -0.08591821789741516,
      0.017549986019730568,
      0.05866382271051407,
      0.0269196555018425,
      -0.033572424203157425,
      -0.03401729837059975,
      0.06281283497810364,
      -0.03096047230064869,
      0.03278995677828789,
      -0.008615093305706978,
      -0.0018686145776882768,
      0.02139023132622242,
      0.040956225246191025,
      0.04860091209411621,
      -0.019808633252978325,
      -0.014569582417607307,
      0.0787997916340828,
      1.2810649423045106e-05,
      -0.017774933949112892,
      -0.013705519028007984,
      0.04853243753314018,
      -0.02934436872601509,
      -0.06274286657571793,
      0.06178648769855499,
      -0.07132692635059357,
      0.027621464803814888,
      0.0010881955968216062,
      0.0064149172976613045,
      0.06678710132837296,
      0.023571372032165527,
      0.025103146210312843,
      -0.05790303647518158,
      -0.04863939434289932,
      0.01154014840722084,
      0.014997672289609909,
      -0.018086029216647148,
      0.062190014868974686,
      -0.036686599254608154,
      -0.02786147966980934,
      0.04521303251385689,
      0.06789147853851318,
      0.03172270953655243,
      -0.07354708760976791,
      -0.044277217239141464,
      -0.02297423779964447,
      0.020084630697965622,
      0.0010274997912347317,
      -0.016210127621889114,
      -0.008069000206887722,
      0.07447676360607147,
      0.014643418602645397,
      -0.034094687551259995,
      0.0038279653526842594,
      0.10594037920236588,
      0.01020819041877985,
      -0.017539041116833687,
      -0.02640974149107933,
      0.06227538734674454,
      0.0028678285889327526,
      0.02508285827934742,
      0.05979376286268234,
      0.09006351977586746,
      0.050959616899490356,
      -0.07904019951820374,
      -0.005561287514865398,
      -0.034064050763845444,
      0.005108965095132589,
      0.04856102541089058,
      0.03494131937623024,
      -0.03642170876264572,
      -0.023439960554242134,
      0.03135528415441513,
      -0.03037760592997074,
      -0.002327949972823262,
      -0.07503365725278854,
      -0.05401899665594101,
      -0.01797705888748169,
      0.042611263692379,
      -0.011810029856860638,
      -0.07791835069656372,
      0.018039047718048096,
      -0.042524028569459915,
      -0.003198313759639859,
      -0.0004463894001673907,
      -0.02340615727007389,
      -0.006684361957013607,
      -0.014009563252329826,
      0.03404098376631737,
      -0.006908732932060957,
      0.03399991616606712,
      -0.06048034131526947,
      0.05143031105399132,
      0.006327142007648945,
      0.03060760162770748,
      -0.029918566346168518,
      0.011245839297771454,
      -0.054408662021160126,
      0.1103934645652771,
      0.007164276670664549,
      0.0625516027212143,
      -0.007319536525756121,
      0.028912127017974854,
      -0.02291448973119259,
      -0.05237977206707001,
      -0.007785627152770758,
      -0.033729128539562225,
      -0.054054293781518936,
      0.08573036640882492,
      0.0017312384443357587,
      0.09847133606672287,
      0.004607077222317457,
      -0.039826732128858566,
      -0.032472219318151474,
      0.03819744661450386,
      0.08177487552165985,
      -0.04743887856602669,
      -0.037294577807188034,
      -0.044324133545160294,
      0.1001238003373146,
      0.08125335723161697,
      0.018345650285482407,
      0.013870922848582268,
      -0.023063020780682564,
      -0.03071777895092964,
      -0.03127841278910637,
      0.05485197529196739,
      -0.05182823911309242,
      0.001967573771253228,
      -0.046605341136455536,
      -0.009522750973701477,
      -0.03565424680709839,
      0.01789942756295204,
      -0.039926089346408844,
      0.1285422444343567,
      -0.029647037386894226,
      -0.00543139036744833,
      -0.022039569914340973,
      0.0409928560256958,
      -0.021752554923295975,
      -0.014975269325077534,
      0.07032176107168198,
      -0.042113471776247025,
      -0.004192803520709276,
      -0.004861209075897932,
      0.001129755051806569,
      0.0009097696165554225,
      -0.030438261106610298,
      -0.019871331751346588,
      -0.011191926896572113,
      0.04826124757528305,
      -0.032629817724227905,
      0.005667220335453749,
      0.003641693154349923,
      -0.00394397834315896,
      0.00975596159696579,
      -0.08650058507919312,
      0.11338679492473602,
      -0.02302962727844715,
      0.04626728594303131,
      0.030946221202611923,
      0.07450255006551743,
      -0.004563074558973312,
      -0.01083364523947239,
      -0.08376269042491913,
      -0.03317863121628761,
      -0.013639269396662712,
      0.07428738474845886,
      0.01498295646160841,
      -0.008627454750239849,
      0.014234483242034912,
      -0.010792385786771774,
      0.07707785815000534,
      -0.061317648738622665,
      0.04078041389584541,
      0.05477716028690338,
      0.04479009658098221,
      -0.028194397687911987,
      0.11656540632247925,
      -0.00426943926140666,
      -0.007743696682155132,
      -0.0547928661108017,
      -0.007716821040958166,
      0.03661847859621048,
      -0.07325263321399689,
      0.0004286012554075569,
      -0.038423389196395874,
      0.016024399548768997,
      0.03689957782626152,
      -0.0550391860306263,
      -0.01291485782712698,
      0.039487309753894806,
      -0.04178819805383682,
      -0.033755142241716385,
      -0.023239104077219963,
      0.03248930722475052,
      0.017809493467211723,
      0.11920060217380524,
      0.0016333692474290729,
      0.09496444463729858,
      -0.01458206120878458,
      -0.01946312002837658,
      -0.044778406620025635,
      0.009813953191041946,
      -0.01591801643371582,
      -0.03838447853922844,
      -0.0694870725274086,
      0.06290922313928604,
      -0.06821645051240921,
      -0.08182008564472198,
      -0.0012689217692241073,
      -0.0013495564926415682,
      0.04587952792644501,
      0.003193521872162819,
      0.013123310171067715,
      -0.007826259359717369,
      0.007404628209769726,
      0.017737707123160362,
      0.006518105044960976,
      0.022353369742631912,
      -0.009496189653873444,
      -0.02965022623538971,
      -0.0027475706301629543,
      -0.011181805282831192,
      0.005824208725243807,
      0.05898241698741913,
      0.028985189273953438,
      -0.03243069350719452,
      -0.0891638845205307,
      -0.016877897083759308,
      -0.018658477813005447,
      -0.0018792992923408747,
      -0.06018838286399841,
      -0.034459684044122696,
      -0.0276234932243824,
      -0.057569973170757294,
      -0.0042843325063586235,
      -0.002400906989350915,
      0.06023883447051048,
      -0.01581757888197899,
      -0.009556393139064312,
      -0.003330408362671733,
      0.01883447729051113,
      0.018032770603895187,
      0.01927325874567032,
      -0.06108999252319336,
      0.0016898175235837698,
      -0.022895559668540955,
      0.04195399582386017,
      -0.042790431529283524,
      0.030536923557519913,
      -0.02585979737341404,
      -0.019159970805048943,
      0.030016496777534485,
      0.00202493485994637,
      0.003498662728816271,
      0.03368506208062172,
      -0.006487049628049135,
      0.0015513758407905698,
      0.02801426872611046,
      -0.025941044092178345,
      0.0034578321501612663,
      -0.0908045768737793,
      -0.027034755796194077,
      0.037292204797267914,
      0.048944227397441864,
      -0.014021970331668854,
      -0.055307306349277496,
      0.025880280882120132,
      -0.07590846717357635,
      0.07062752544879913,
      -0.008569114841520786,
      -0.07295417785644531,
      0.03823109716176987,
      -0.007829495705664158,
      -0.07970399409532547,
      0.002420512493699789,
      -0.0030568880029022694,
      0.039515480399131775,
      -0.07235687971115112,
      0.011158325709402561,
      -0.011400534771382809,
      0.03399798274040222,
      0.05292480066418648,
      0.05173502489924431,
      0.1226123794913292,
      -0.018790677189826965,
      0.0213413517922163,
      0.07537354528903961,
      -0.03241044655442238,
      -0.025298859924077988,
      0.045155856758356094,
      0.053003933280706406,
      0.053589966148138046,
      -0.025579212233424187,
      -0.04264184460043907,
      -0.0035627405159175396,
      -0.02515660785138607,
      0.00043176449253223836,
      0.02383144199848175,
      0.017098620533943176,
      0.025776436552405357,
      -0.06537660956382751,
      0.010940171778202057,
      0.0076912338845431805,
      -0.06903337687253952,
      -0.0011822064407169819,
      0.08378611505031586,
      0.055921874940395355,
      0.03698510676622391,
      -0.010096651501953602,
      -0.02199440635740757,
      0.020354965701699257,
      -0.006194856949150562,
      0.02804252691566944,
      -0.048266008496284485,
      -0.03934483602643013,
      0.06778224557638168,
      -0.10019782930612564,
      0.09854148328304291,
      0.040416229516267776,
      -0.015108412131667137,
      0.019330192357301712,
      0.028472889214754105,
      -0.007183672394603491,
      -0.047457996755838394,
      0.02628045529127121,
      0.04639758914709091,
      -0.11484602838754654,
      -0.0598297193646431,
      0.030646223574876785,
      0.009752712212502956,
      0.0015720193041488528,
      0.005173234269022942,
      0.027832651510834694,
      0.04470539838075638,
      0.0654827132821083,
      0.06436251848936081,
      0.06613312661647797,
      0.049336988478899,
      0.00492082629352808,
      -0.037531375885009766,
      -0.07308618724346161,
      0.06717037409543991,
      0.002111713634803891,
      0.09742690622806549,
      -0.0024098148569464684,
      -0.023874061182141304,
      0.06982418894767761,
      0.049083612859249115,
      0.041057948023080826,
      0.04725603014230728,
      0.04488440230488777,
      -0.026983533054590225,
      0.024925565347075462,
      -0.023634804412722588,
      0.02415083535015583,
      0.01813635416328907,
      0.021749429404735565,
      -0.032968856394290924,
      0.03603150323033333,
      -0.056885767728090286,
      0.03838174790143967,
      -0.017935119569301605,
      -0.02952977828681469,
      -0.040869418531656265,
      -0.05959697440266609,
      -0.06510839611291885,
      0.05573437362909317,
      0.08025044202804565,
      0.005013994872570038,
      -0.04162363335490227,
      0.02682645618915558,
      0.0745861604809761,
      -0.05113371089100838,
      0.07583242654800415,
      0.02825608104467392,
      -0.023406105116009712,
      0.003004524391144514,
      0.041693009436130524,
      -0.034022677689790726,
      0.024768836796283722,
      0.03429027274250984,
      0.0314488485455513,
      -0.04380994662642479,
      0.020661797374486923,
      -0.02069324254989624,
      -0.008868743665516376,
      0.06121009588241577,
      0.014237131923437119,
      0.03579675406217575,
      0.023310916498303413,
      -0.054178327322006226,
      -0.0077518741600215435,
      0.007886424660682678,
      0.016637617722153664,
      0.02932414412498474,
      0.020884361118078232,
      0.06419848650693893,
      0.01462419144809246,
      -0.005485366564244032,
      0.022797303274273872,
      -0.006222466938197613,
      -0.024216758087277412,
      0.0033142664469778538,
      -0.02811284549534321,
      0.048477768898010254,
      0.010584649629890919,
      0.04848689213395119,
      -0.0009930400410667062,
      0.07661589980125427,
      0.04373406991362572,
      -0.012307477183640003,
      -0.04268038645386696,
      0.002329109236598015,
      -0.010468075051903725,
      -0.02954952046275139,
      0.01255441177636385,
      0.04965188354253769,
      -0.028317775577306747,
      -0.043602220714092255
    ],
    [
      -0.03966478630900383,
      -0.07470661401748657,
      0.0254487507045269,
      -0.009206730872392654,
      0.009448392316699028,
      -0.10123725235462189,
      -0.028470266610383987,
      0.0335172638297081,
      -0.04058460891246796,
      -0.0030110806692391634,
      0.021872449666261673,
      -0.043359242379665375,
      -0.07093758881092072,
      -0.08124107122421265,
      -0.0768231526017189,
      -0.017524998635053635,
      -0.018557371571660042,
      0.015735559165477753,
      -0.07990057021379471,
      0.024566493928432465,
      0.017121372744441032,
      0.03778136894106865,
      0.006603474263101816,
      0.007902039214968681,
      0.04202602058649063,
      -0.05396661162376404,
      -0.06188814714550972,
      0.005384199321269989,
      -0.06157330051064491,
      -0.02576574869453907,
      -0.016128744930028915,
      -0.05310623720288277,
      -0.02018255926668644,
      -0.02744358777999878,
      0.0545307956635952,
      -0.015101395547389984,
      0.039123449474573135,
      -0.004389861598610878,
      -0.007025697734206915,
      -0.007375516928732395,
      0.08518540114164352,
      -0.0964350625872612,
      0.01847541332244873,
      -0.04965880885720253,
      -0.04555213451385498,
      0.03099537082016468,
      0.06400704383850098,
      0.032913077622652054,
      -0.006560273468494415,
      0.007113701198250055,
      -0.008023792877793312,
      0.030357278883457184,
      -0.016233013942837715,
      0.04031146317720413,
      -0.027914537116885185,
      -0.0010593613842502236,
      0.04367509111762047,
      0.005075988359749317,
      0.004456011578440666,
      0.00020384113304316998,
      -0.002864058129489422,
      -0.0016069659031927586,
      0.03882404789328575,
      -0.06485878676176071,
      -0.10830678045749664,
      -0.014345456846058369,
      0.024890363216400146,
      0.03800635784864426,
      0.06721515208482742,
      -0.02816862240433693,
      -0.014880272559821606,
      -0.007599896285682917,
      -0.0010759234428405762,
      -0.018515054136514664,
      -0.05418664589524269,
      -0.005418756511062384,
      0.01729845628142357,
      -0.03271716460585594,
      0.053531304001808167,
      -0.0036992726381868124,
      0.055187974125146866,
      -0.02230197936296463,
      -0.07739289849996567,
      0.0432603694498539,
      -0.05688604339957237,
      0.03352756053209305,
      0.06882873177528381,
      -0.01878158375620842,
      -0.010706628672778606,
      -0.005673881154507399,
      0.018473945558071136,
      0.010138336569070816,
      0.014399191364645958,
      -0.024315480142831802,
      0.01716703362762928,
      -0.09575345367193222,
      0.024317866191267967,
      -0.026107344776391983,
      -0.06589536368846893,
      -0.012535545974969864,
      -0.05316925421357155,
      -0.014588179998099804,
      -0.04336899518966675,
      -0.04402117058634758,
      0.005605103448033333,
      -0.07043929398059845,
      0.004912004340440035,
      0.03787946328520775,
      0.03263212740421295,
      0.06329599022865295,
      0.026954369619488716,
      -0.02024182304739952,
      -0.011267043650150299,
      -0.03159483149647713,
      -0.03172046318650246,
      -0.06952804327011108,
      0.024884549900889397,
      -0.005010585766285658,
      -0.0037272998597472906,
      0.105597123503685,
      -0.00913888867944479,
      -0.00960457231849432,
      0.012067435309290886,
      -0.025248399004340172,
      0.02178388647735119,
      -0.02756514959037304,
      0.019043786451220512,
      0.00020443741232156754,
      -0.035608746111392975,
      0.005939684342592955,
      -0.008110648021101952,
      -0.15136778354644775,
      -0.055436134338378906,
      -0.0476299524307251,
      0.034388408064842224,
      -0.059712015092372894,
      -0.04070154204964638,
      -0.03761231526732445,
      0.04196960851550102,
      0.0022389679215848446,
      0.05558823049068451,
      0.03610563278198242,
      0.061427563428878784,
      0.056025441735982895,
      0.024890737608075142,
      0.014125222340226173,
      0.007154056802392006,
      0.03677325323224068,
      0.009948971681296825,
      -0.06674473732709885,
      0.05877450853586197,
      -0.07130450010299683,
      0.11126480251550674,
      0.02988092042505741,
      0.019452085718512535,
      -0.05214044824242592,
      0.052691444754600525,
      0.016533561050891876,
      0.04064786806702614,
      -0.045286476612091064,
      -0.08597821742296219,
      -0.04961070418357849,
      -0.035620395094156265,
      0.010400552302598953,
      -0.03282725065946579,
      -0.001193222589790821,
      0.07242558896541595,
      -0.017760545015335083,
      -0.04365355148911476,
      -0.03207916393876076,
      -0.0005961689166724682,
      -0.016877301037311554,
      -0.02291310951113701,
      0.0030532930977642536,
      0.0951189249753952,
      0.02571437507867813,
      -0.004362698644399643,
      0.023790638893842697,
      -0.06087744981050491,
      -0.09050492942333221,
      -0.037926990538835526,
      -0.14998942613601685,
      -0.012263691984117031,
      -0.07475446164608002,
      -0.007641360629349947,
      -0.06959240883588791,
      -0.06786956638097763,
      -0.002202245406806469,
      -0.004016178660094738,
      -0.048512641340494156,
      0.036157287657260895,
      -0.02775675244629383,
      0.018966685980558395,
      0.04519197344779968,
      0.03214201703667641,
      -0.02070760354399681,
      0.09517993777990341,
      0.007578263524919748,
      0.011304061859846115,
      0.016956903040409088,
      -0.0003006988554261625,
      -0.03338077291846275,
      0.034354858100414276,
      -0.0024502782616764307,
      0.059042416512966156,
      -0.051915157586336136,
      -0.06751194596290588,
      0.02122003398835659,
      -0.02267034910619259,
      0.08437582850456238,
      0.004302433226257563,
      0.03898164629936218,
      -0.04719281941652298,
      0.04614042118191719,
      -0.007834759540855885,
      0.004397460259497166,
      -0.03368839994072914,
      -0.016198763623833656,
      0.0347222276031971,
      0.00986394565552473,
      -0.02237401157617569,
      0.031264930963516235,
      0.030522804707288742,
      0.027494560927152634,
      -0.005840329919010401,
      -0.010099549777805805,
      0.011698895134031773,
      -0.020087389275431633,
      -0.018605193123221397,
      -0.0025565428659319878,
      0.014120223931968212,
      0.056947700679302216,
      0.07041999697685242,
      0.04796255752444267,
      0.096646249294281,
      -0.005012327805161476,
      -0.08787663280963898,
      -0.020907625555992126,
      0.047381751239299774,
      -0.05797383934259415,
      0.055777307599782944,
      -0.08310554921627045,
      -0.012327956035733223,
      0.014373311772942543,
      0.0327693447470665,
      0.026568850502371788,
      -0.08196169137954712,
      -0.012928852811455727,
      -0.04885704070329666,
      -0.044127412140369415,
      0.03650060296058655,
      0.03829937428236008,
      -0.06755178421735764,
      0.06923562288284302,
      0.059018574655056,
      0.0031710558105260134,
      -0.10769333690404892,
      0.002613475313410163,
      0.08945886790752411,
      -0.029123425483703613,
      0.012102575041353703,
      0.0033585496712476015,
      -0.06530508399009705,
      -0.013609735295176506,
      0.03243998438119888,
      0.0021119932644069195,
      -0.06610600650310516,
      -0.15407854318618774,
      -0.0334867462515831,
      0.033764101564884186,
      -0.016219407320022583,
      -0.012541702017188072,
      0.055799730122089386,
      0.02031308226287365,
      -0.062108516693115234,
      -0.07007535547018051,
      -0.030843660235404968,
      -0.05412738770246506,
      0.026175960898399353,
      -0.027994366362690926,
      -0.04154421016573906,
      -0.023787951096892357,
      -0.006911518517881632,
      -0.022412169724702835,
      0.0702962875366211,
      0.02862587384879589,
      0.05127163231372833,
      0.006253710016608238,
      -0.014671235345304012,
      -0.05101440101861954,
      0.02748088911175728,
      -0.019513873383402824,
      0.004994318354874849,
      0.038625601679086685,
      -0.03305606544017792,
      0.038319941610097885,
      0.04916277155280113,
      0.01311657577753067,
      0.017650602385401726,
      -0.01938525214791298,
      0.0018075838452205062,
      -0.050629060715436935,
      -0.015440761111676693,
      0.011072902008891106,
      0.031058844178915024,
      -0.05361108109354973,
      -0.009869705885648727,
      -0.08144274353981018,
      0.012166211381554604,
      -0.010089724324643612,
      -0.007175539620220661,
      0.04584012180566788,
      -0.003574289847165346,
      0.07554343342781067,
      -0.0016098343767225742,
      0.010702736675739288,
      0.025828758254647255,
      0.026703057810664177,
      0.05835586041212082,
      -0.017362035810947418,
      -0.03404654935002327,
      -0.00044861497008241713,
      0.008044254034757614,
      -0.0046492102555930614,
      0.029166406020522118,
      -0.008009315468370914,
      0.10143950581550598,
      -0.018104417249560356,
      -0.0429813489317894,
      0.053789880126714706,
      0.09204591810703278,
      -0.04098054766654968,
      0.03586120530962944,
      -0.020459888502955437,
      0.04773331061005592,
      0.07765940576791763,
      -0.05338754504919052,
      -0.02490025945007801,
      0.074140265583992,
      -0.00983627699315548,
      0.018589746206998825,
      0.06023560091853142,
      -0.0797906294465065,
      0.023462828248739243,
      0.01414936687797308,
      0.048277050256729126,
      -0.004916776437312365,
      -0.06373205780982971,
      0.005066708195954561,
      -0.07629413157701492,
      0.04059920459985733,
      0.019586332142353058,
      0.001430464442819357,
      -0.08598148822784424,
      -0.07338321208953857,
      -0.02524811029434204,
      -0.04944709688425064,
      0.09182490408420563,
      0.04034199193120003,
      0.04935082793235779,
      -0.03622542694211006,
      -0.07767567038536072,
      -0.041095975786447525,
      0.056259188801050186,
      0.009372866712510586,
      -0.0770328938961029,
      -0.06064038723707199,
      -0.029949115589261055,
      -0.05170687288045883,
      -0.10143787413835526,
      0.0041801598854362965,
      0.031641885638237,
      0.01921852119266987,
      -0.0077168685384094715,
      -0.04788782820105553,
      0.057691849768161774,
      -0.028320953249931335,
      0.025478802621364594,
      -0.00013580778613686562,
      0.009559285826981068,
      -0.003296158043667674,
      -0.0006454322719946504,
      -0.01296311803162098,
      0.031675707548856735,
      0.04564846679568291,
      0.08040717244148254,
      -0.05295683443546295,
      0.021901601925492287,
      -0.03277524188160896,
      0.005345453973859549,
      -0.0589178092777729,
      0.02136356383562088,
      -0.007442792411893606,
      0.050108686089515686,
      0.019441427662968636,
      -0.033921681344509125,
      -0.015335731208324432,
      -0.060415200889110565,
      -0.021599180996418,
      -0.04337979853153229,
      -0.10072643309831619,
      -0.06764212250709534,
      -0.02868204563856125,
      0.014451996423304081,
      -0.0022604733239859343,
      -0.027907881885766983,
      0.004228438716381788,
      0.01785728894174099,
      0.013798203319311142,
      0.027363808825612068,
      -0.0018093350809067488,
      -0.006415712181478739,
      0.025358298793435097,
      0.07467392832040787,
      0.02890681102871895,
      0.037250347435474396,
      -0.06686992198228836,
      0.0033056391403079033,
      0.02834383398294449,
      0.019305743277072906,
      -0.025509655475616455,
      0.06750607490539551,
      0.01565321534872055,
      -0.038469456136226654,
      0.013158801943063736,
      -0.0797712504863739,
      0.0074709574691951275,
      0.10911011695861816,
      -0.01510251872241497,
      0.06456849724054337,
      -0.023944571614265442,
      0.08830145001411438,
      -0.05895563215017319,
      -0.038128193467855453,
      0.06419391930103302,
      -0.007187954615801573,
      0.010169582441449165,
      0.06601499766111374,
      0.022925427183508873,
      -0.042711175978183746,
      0.0073637706227600574,
      -0.045882318168878555,
      0.029049815610051155,
      0.01839905045926571,
      0.011542958207428455,
      0.024979261681437492,
      0.04081312566995621,
      -0.006953728385269642,
      0.007069606799632311,
      0.01238546147942543,
      0.00797693245112896,
      -0.022925661876797676,
      -0.032241612672805786,
      -0.005175508558750153,
      -0.01947224698960781,
      0.04045175388455391,
      0.014015240594744682,
      0.004572228062897921,
      0.00042074924567714334,
      0.06889905035495758,
      0.03512929007411003,
      -0.028411298990249634,
      0.019337153062224388,
      -0.018170276656746864,
      0.05074881389737129,
      0.09677846729755402,
      0.005626251921057701,
      -0.02848692797124386,
      -0.03502648323774338,
      -0.05628225952386856,
      -0.00909185130149126,
      -0.037168800830841064,
      0.027304312214255333,
      0.010154709219932556,
      0.03199829161167145,
      0.001509553287178278,
      0.0326315276324749,
      -0.00490599824115634,
      -0.014987446367740631,
      0.0016317758709192276,
      -0.01798258163034916,
      0.08139757812023163,
      -0.010906876996159554,
      0.06974900513887405,
      -0.02018113248050213,
      -0.05272357910871506,
      -0.14185111224651337,
      0.034440938383340836,
      0.08193156123161316,
      0.0722498968243599,
      0.05315438657999039,
      0.04060671105980873,
      -0.06260880082845688,
      -0.03748992085456848,
      0.10647889226675034,
      -0.010827172547578812,
      -0.06991644948720932,
      0.0073168957605957985,
      -0.00801136065274477,
      -0.08809599280357361,
      -0.006403760984539986,
      0.0366918221116066,
      -0.008889023214578629
    ],
    [
      -0.03193852677941322,
      0.09805197268724442,
      0.11323634535074234,
      -0.09468306601047516,
      -0.02450045943260193,
      -0.031667742878198624,
      -0.01937270537018776,
      -0.013140172697603703,
      0.0017726115183904767,
      -0.03754447400569916,
      0.0042701950296759605,
      0.12318062037229538,
      -0.015188755467534065,
      0.06456170976161957,
      0.033621203154325485,
      0.06970375776290894,
      0.037710078060626984,
      -0.016757452860474586,
      -0.011589993722736835,
      0.05831826850771904,
      -0.031141061335802078,
      0.061037514358758926,
      -0.03285567834973335,
      -0.02814568020403385,
      -0.04127131775021553,
      0.02348390407860279,
      -0.03803829103708267,
      -0.104175865650177,
      0.0643189325928688,
      -0.0043416256085038185,
      0.0381370410323143,
      -0.05694970116019249,
      -0.020324289798736572,
      0.05200309678912163,
      -0.0393991582095623,
      -0.04952641576528549,
      -0.009115129709243774,
      0.033826954662799835,
      -0.03722187504172325,
      -0.0011344311060383916,
      0.012109051458537579,
      -0.009989309124648571,
      -0.06825534999370575,
      -0.008516116999089718,
      0.06474434584379196,
      0.04916326329112053,
      0.018201369792222977,
      -0.0031319756526499987,
      0.04145911708474159,
      0.0184955857694149,
      -0.10008274018764496,
      0.03717821091413498,
      0.05495142936706543,
      0.0017509500030428171,
      -0.023778455331921577,
      0.02413139119744301,
      0.009875164367258549,
      0.000436010945122689,
      0.015561074949800968,
      0.038470033556222916,
      -0.009239329025149345,
      -0.04298178479075432,
      0.021633317694067955,
      -0.04650270193815231,
      0.02342354692518711,
      0.02349228411912918,
      0.05139617249369621,
      0.022946316748857498,
      0.022124631330370903,
      0.08439426869153976,
      -0.006783703342080116,
      0.00905908178538084,
      0.08823030441999435,
      0.04069750756025314,
      0.018864257261157036,
      0.008149229921400547,
      -0.04391266405582428,
      0.010974396951496601,
      0.04633709788322449,
      -0.07850475609302521,
      -0.004965422209352255,
      -0.0476774200797081,
      0.010693826712667942,
      0.010088293813169003,
      -0.018705042079091072,
      0.051748599857091904,
      0.05651344358921051,
      0.07690747082233429,
      0.08741164207458496,
      -0.02586040273308754,
      0.06928503513336182,
      0.0037942822091281414,
      0.04743899032473564,
      0.1157517358660698,
      0.01478439848870039,
      0.04234610125422478,
      0.00994439609348774,
      0.04544532671570778,
      0.03089825250208378,
      -0.008134179748594761,
      -0.06689077615737915,
      0.063291996717453,
      0.040216922760009766,
      0.02101520262658596,
      -0.01714029535651207,
      -0.0016089963028207421,
      -0.04465928301215172,
      0.018067559227347374,
      0.00013583345571532845,
      -0.06416801363229752,
      -0.01945720613002777,
      0.030839920043945312,
      -0.026780856773257256,
      0.03494861721992493,
      0.029480479657649994,
      0.0034885003697127104,
      -0.05522087588906288,
      0.03016098029911518,
      0.05393783748149872,
      -0.08415063470602036,
      0.03028484433889389,
      0.08732475340366364,
      -0.03580675646662712,
      0.07851257175207138,
      -0.012732069939374924,
      -0.04418780654668808,
      0.00010965221736114472,
      -0.03277292102575302,
      -0.11464895308017731,
      -0.019225802272558212,
      0.08664023876190186,
      -0.05537457764148712,
      0.09147168695926666,
      0.09965325891971588,
      -0.012089134193956852,
      -0.0017357125179842114,
      -0.02813923917710781,
      -0.0012693563476204872,
      0.05534512549638748,
      -0.0007841571932658553,
      -0.055038146674633026,
      -0.03732258453965187,
      0.06367730349302292,
      0.023889951407909393,
      0.03898945823311806,
      -0.009795021265745163,
      0.023298433050513268,
      0.056997720152139664,
      0.05558956786990166,
      -0.04924837872385979,
      0.04668538644909859,
      0.015556423924863338,
      0.00541685102507472,
      0.03843233734369278,
      0.04880039021372795,
      0.012399091385304928,
      0.02114332653582096,
      -0.013953194953501225,
      -0.10601140558719635,
      -0.045786116272211075,
      -0.018164176493883133,
      -3.9968203054741025e-05,
      -0.03748044744133949,
      0.0952698290348053,
      -0.041310738772153854,
      -0.01887373812496662,
      -0.041379090398550034,
      0.04421091079711914,
      0.0431639738380909,
      -0.006963626481592655,
      0.010373227298259735,
      0.05617242678999901,
      -0.006679301615804434,
      0.067962646484375,
      -0.08013305068016052,
      -0.004668546840548515,
      0.057659413665533066,
      -0.04410138353705406,
      0.009698888286948204,
      -0.005319613963365555,
      0.000607593625318259,
      -0.06051972508430481,
      -0.027340354397892952,
      -0.06806599348783493,
      -0.08983452618122101,
      0.06537003070116043,
      -0.030251935124397278,
      -0.04072288051247597,
      -0.013187907636165619,
      -0.04481025040149689,
      -0.002231579041108489,
      -0.0069980560801923275,
      0.017928868532180786,
      -0.032547157257795334,
      -0.007033986505120993,
      -0.03168672323226929,
      0.02901030331850052,
      0.04077902063727379,
      -0.10260387510061264,
      0.036973122507333755,
      0.021991845220327377,
      0.06548496335744858,
      0.08962221443653107,
      0.006053105462342501,
      -0.06334305554628372,
      0.044686928391456604,
      0.04809226095676422,
      0.01002892293035984,
      -0.0007730712532065809,
      -0.007382286246865988,
      -0.03840853273868561,
      -0.029891187325119972,
      0.008034486323595047,
      -0.05238226801156998,
      -0.029562510550022125,
      -0.018620414659380913,
      0.045416973531246185,
      0.044096942991018295,
      0.03813277184963226,
      0.0621337965130806,
      -0.03121030703186989,
      0.02106085605919361,
      0.02770422212779522,
      -0.028767187148332596,
      -0.04123615846037865,
      0.0009842835133895278,
      -0.029731586575508118,
      0.10820166021585464,
      -0.011810974217951298,
      0.02051331289112568,
      -0.017793569713830948,
      -0.06047772616147995,
      0.07858992367982864,
      0.004234663676470518,
      -0.00717344181612134,
      0.03151830658316612,
      0.03915901482105255,
      0.011775621213018894,
      -0.0789782702922821,
      -0.05187265947461128,
      -0.05563826486468315,
      -0.0011883358238264918,
      0.007295889779925346,
      -0.012304664589464664,
      -0.037816230207681656,
      0.003910763189196587,
      -0.061718087643384933,
      -0.033392246812582016,
      -0.022787781432271004,
      0.024718524888157845,
      0.029016586020588875,
      0.046622466295957565,
      0.0415537990629673,
      -0.0834505632519722,
      0.02911728248000145,
      -0.004533041268587112,
      0.010993646457791328,
      0.008797483518719673,
      -0.023080209270119667,
      0.035669758915901184,
      0.0028271100018173456,
      0.023752469569444656,
      -0.007663636934012175,
      -0.00400539580732584,
      -0.05477806180715561,
      0.014540174044668674,
      -0.06131491810083389,
      -0.011809288524091244,
      0.0023113207425922155,
      -0.036492642015218735,
      0.00031424392363987863,
      -0.006421942729502916,
      -0.038805872201919556,
      0.028048422187566757,
      -0.07101429998874664,
      0.08958164602518082,
      0.014168702997267246,
      -0.0045863911509513855,
      0.07599390298128128,
      -0.04610440507531166,
      -0.03633197024464607,
      -0.01919613778591156,
      0.04272802919149399,
      -0.0238410085439682,
      0.0013632290065288544,
      0.026726633310317993,
      -0.03440406173467636,
      -0.004996820352971554,
      0.03560850769281387,
      0.05475274473428726,
      0.03350271284580231,
      0.006242705509066582,
      0.0262557752430439,
      -0.013643301092088223,
      -0.0008039081003516912,
      0.08773786574602127,
      0.0891035869717598,
      -0.08656440675258636,
      0.0035666462499648333,
      0.015767185017466545,
      -0.03788825869560242,
      -0.017055194824934006,
      0.06063130870461464,
      0.009570840746164322,
      0.03240766376256943,
      0.07942722737789154,
      -0.05361053720116615,
      0.030144644901156425,
      0.029276546090841293,
      -0.08246706426143646,
      -0.03866737335920334,
      -0.028383398428559303,
      -0.0014558619586750865,
      -0.002691925037652254,
      0.012675946578383446,
      0.01814861409366131,
      0.011184711009263992,
      0.09359733015298843,
      0.016375668346881866,
      0.002035432029515505,
      0.010591640137135983,
      -0.06687910109758377,
      -0.0005665252101607621,
      -0.020172880962491035,
      0.03431985527276993,
      -0.002123067853972316,
      0.06522103399038315,
      0.03136386722326279,
      -0.004271379671990871,
      -0.11257274448871613,
      0.011473112739622593,
      0.026662012562155724,
      -0.004550628364086151,
      -0.08647506684064865,
      -0.08837590366601944,
      -0.05432265251874924,
      0.041743822395801544,
      -0.01931845396757126,
      0.010575774125754833,
      0.06345470994710922,
      0.008475098758935928,
      -0.026231523603200912,
      0.05376475304365158,
      -0.0038257038686424494,
      0.005458721425384283,
      0.003188925562426448,
      -0.01564071699976921,
      0.05525708198547363,
      -0.0028032970149070024,
      -0.023485427722334862,
      -0.0033384535927325487,
      0.006420745514333248,
      0.036165833473205566,
      -0.01619420386850834,
      0.00418900279328227,
      0.038280680775642395,
      0.0511251799762249,
      0.013404401019215584,
      0.06238319352269173,
      0.019947530701756477,
      -0.002678698394447565,
      0.04312244802713394,
      -0.012848117388784885,
      -0.024662502110004425,
      -0.023340115323662758,
      -0.0030662380158901215,
      -0.018431851640343666,
      0.07943100482225418,
      -0.023828990757465363,
      0.014515537768602371,
      0.0323805958032608,
      0.03135424107313156,
      0.002490326529368758,
      -0.005515462718904018,
      -0.12530529499053955,
      -0.03983862325549126,
      -0.01691405102610588,
      -0.01516100112348795,
      -0.04787879064679146,
      -0.04812305420637131,
      -0.00743667408823967,
      -0.011964751407504082,
      0.027816832065582275,
      -0.0015353132039308548,
      0.024660253897309303,
      0.08477210253477097,
      0.07366273552179337,
      -0.010091740638017654,
      -0.07200826704502106,
      -0.06317885220050812,
      0.04109247028827667,
      0.028438396751880646,
      0.07654756307601929,
      -0.0177364069968462,
      0.04600202292203903,
      0.031034959480166435,
      -0.05301133170723915,
      -0.032959334552288055,
      -0.01915949210524559,
      -0.003335422370582819,
      -0.05784589424729347,
      0.06814420223236084,
      -0.028801189735531807,
      -0.0016312167281284928,
      0.01955398917198181,
      -0.028240634128451347,
      0.048900652676820755,
      -0.01463308371603489,
      -0.03409302607178688,
      0.05988479033112526,
      0.0392567478120327,
      0.02653011679649353,
      -0.03878883272409439,
      -0.005266765132546425,
      0.027257559821009636,
      0.03406328707933426,
      0.010153030045330524,
      0.08529271930456161,
      -0.02625587396323681,
      0.045767199248075485,
      0.059091966599226,
      0.03742854297161102,
      -0.036432817578315735,
      0.013756321743130684,
      0.04476277530193329,
      -0.059173520654439926,
      -0.026462282985448837,
      0.019761858507990837,
      -0.018011491745710373,
      -0.03642144054174423,
      0.04727848246693611,
      -0.06609604507684708,
      -0.019798606634140015,
      0.0030440103728324175,
      -0.00933775957673788,
      -0.025757940486073494,
      -0.00423512002453208,
      0.05903596803545952,
      -0.05116262286901474,
      -0.03040737845003605,
      -0.026155153289437294,
      0.04243702068924904,
      -0.02987915650010109,
      0.10599155724048615,
      -0.02080569602549076,
      0.04748081788420677,
      0.01599886640906334,
      0.05806376785039902,
      -0.01677827350795269,
      -0.002241843845695257,
      -0.0029005524702370167,
      -0.03634125366806984,
      0.011218113824725151,
      -0.010129975154995918,
      0.05274245887994766,
      0.023809978738427162,
      0.05667885020375252,
      0.021976806223392487,
      -0.010529836639761925,
      -0.029435722157359123,
      -0.13895396888256073,
      0.06302805244922638,
      -0.012726066634058952,
      0.05073700100183487,
      0.009116340428590775,
      0.01762481965124607,
      0.048033155500888824,
      -0.05135049670934677,
      0.06718501448631287,
      0.05800863355398178,
      -0.0146933663636446,
      -0.04724238067865372,
      0.05146340653300285,
      -0.021554015576839447,
      0.07690779864788055,
      -0.03232334554195404,
      0.014359486289322376,
      0.029614383354783058,
      0.03740321099758148,
      -0.005037327762693167,
      -0.005358648952096701,
      -0.02280375175178051,
      -0.0031897618900984526,
      0.001923705916851759,
      -0.01965734176337719,
      -0.07828009873628616,
      -0.03684866428375244,
      -0.03916165605187416,
      -0.038455717265605927,
      -0.031870681792497635,
      0.04985317215323448,
      -0.04691260680556297,
      -0.028823217377066612,
      0.025181230157613754,
      -0.038056738674640656,
      -0.013856086879968643,
      0.13643090426921844,
      0.05366826429963112,
      -0.10955511778593063,
      -0.0230714064091444,
      0.012364541180431843,
      0.03162362426519394,
      0.005871505942195654
    ],
    [
      -0.03440985083580017,
      0.039703916758298874,
      -0.04484357684850693,
      -0.005324282217770815,
      -0.08561399579048157,
      0.052450958639383316,
      -0.01766742393374443,
      0.00028115103486925364,
      -0.003985432907938957,
      -0.013757525943219662,
      -0.051094911992549896,
      0.06452159583568573,
      -0.040780190378427505,
      -0.03158410266041756,
      0.010595381259918213,
      -0.008111073635518551,
      0.08145689964294434,
      0.042562514543533325,
      -0.012708780355751514,
      -0.013304737396538258,
      -0.005046328064054251,
      0.08063963800668716,
      0.011701908893883228,
      -0.01688636839389801,
      -0.0012973445700481534,
      -0.006344982888549566,
      -0.04191865399479866,
      0.03534498065710068,
      0.03549940511584282,
      -0.05182071402668953,
      -0.016511095687747,
      0.06459300220012665,
      0.022753402590751648,
      -0.0045930370688438416,
      0.03151122108101845,
      0.0945926085114479,
      0.012565508484840393,
      -0.00029565347358584404,
      -0.014270282350480556,
      0.07893969863653183,
      0.0037075146101415157,
      0.04308333247900009,
      0.041346438229084015,
      0.05465349555015564,
      0.04919598251581192,
      0.0070709772408008575,
      -0.05056772381067276,
      0.03445480763912201,
      -0.05025072395801544,
      -0.004896087106317282,
      0.0075416481122374535,
      0.008153017610311508,
      -0.02070796862244606,
      0.017005648463964462,
      0.0609702467918396,
      -0.07983925193548203,
      0.08222920447587967,
      -0.0889231339097023,
      -0.02993052266538143,
      0.0072970143519341946,
      0.00853319838643074,
      -0.049810443073511124,
      0.010294737294316292,
      0.004653175827115774,
      0.010244612582027912,
      0.03470751643180847,
      0.012760580517351627,
      0.012243558652698994,
      0.03766820207238197,
      -0.02611895091831684,
      -0.05622592195868492,
      0.006486773490905762,
      -0.02149849385023117,
      0.06123806908726692,
      0.055304527282714844,
      -0.00903608463704586,
      -0.023468611761927605,
      -0.04126757010817528,
      -0.04342372715473175,
      -0.002019625622779131,
      0.03475983440876007,
      -0.009757149033248425,
      0.00471819331869483,
      -0.012054656632244587,
      0.0031014892738312483,
      -0.04477103054523468,
      0.004814045038074255,
      0.11697928607463837,
      -0.019028129056096077,
      0.0443926639854908,
      0.02488042041659355,
      0.08060932904481888,
      0.05067522078752518,
      -0.06127157434821129,
      -0.019494514912366867,
      0.03191534802317619,
      -0.0238187313079834,
      -0.06410616636276245,
      0.0729626715183258,
      0.06743717193603516,
      -0.026462996378540993,
      0.013167085126042366,
      -0.041500817984342575,
      0.027073079720139503,
      0.07605235278606415,
      -0.006556146778166294,
      -0.05229206383228302,
      0.04473291337490082,
      0.005435562692582607,
      0.02079305797815323,
      0.06758992373943329,
      0.05580049008131027,
      -0.04893921688199043,
      0.035073332488536835,
      -0.03556980937719345,
      -0.026695014908909798,
      0.03038966655731201,
      0.0891638919711113,
      0.009380879811942577,
      -0.027592530474066734,
      -0.05335793271660805,
      -0.007366369478404522,
      0.00020967490854673088,
      -0.015729995444417,
      0.022681087255477905,
      -0.01939651183784008,
      0.0588298961520195,
      -0.04340044781565666,
      -0.023759586736559868,
      0.03737197816371918,
      0.008886204101145267,
      0.0023356755264103413,
      0.07190088927745819,
      -0.03754083439707756,
      -0.07736320048570633,
      -0.027308670803904533,
      -0.013980116695165634,
      0.01818937622010708,
      0.048182230442762375,
      -0.037616029381752014,
      -0.026420366019010544,
      -0.000791405385825783,
      0.02597387135028839,
      0.036337848752737045,
      -0.019848519936203957,
      0.0010082892840728164,
      -0.011790331453084946,
      0.06246628239750862,
      -0.01763048581779003,
      0.05765914544463158,
      0.025318270549178123,
      -0.05427390709519386,
      -0.009907573461532593,
      0.045200981199741364,
      -0.07703392952680588,
      0.09047921001911163,
      0.0557003989815712,
      -0.01723303087055683,
      0.1268741637468338,
      0.05071042850613594,
      -0.05711504817008972,
      0.00596742145717144,
      -0.00016182450053747743,
      0.03562212362885475,
      0.050199612975120544,
      -0.027935903519392014,
      0.03868640959262848,
      0.022756952792406082,
      0.007322177290916443,
      -0.05641014128923416,
      0.10170521587133408,
      0.00784260593354702,
      -0.038726724684238434,
      0.051410723477602005,
      -0.06411784887313843,
      0.054090678691864014,
      -0.041976287961006165,
      -0.02493799850344658,
      -0.016149263828992844,
      -0.0034793559461832047,
      0.025609148666262627,
      -0.00041192545904777944,
      -0.01797206699848175,
      0.023120835423469543,
      -0.04397225379943848,
      0.0741206482052803,
      0.01267937757074833,
      0.044929247349500656,
      0.08020726591348648,
      0.00541724031791091,
      0.010688848793506622,
      0.038729164749383926,
      -0.009631003253161907,
      0.013059088960289955,
      -0.031086230650544167,
      0.06293985992670059,
      -0.10542133450508118,
      -0.0541427880525589,
      -0.009537700563669205,
      0.03546912595629692,
      0.03150949627161026,
      -0.0029075213242322206,
      0.037315282970666885,
      -0.01683897338807583,
      0.08371907472610474,
      -0.04225056990981102,
      0.03821972757577896,
      -0.0046258470974862576,
      -0.04117387533187866,
      0.04645515978336334,
      -0.06024995073676109,
      -0.02998654916882515,
      0.04059433564543724,
      -0.008173078298568726,
      -0.0751415342092514,
      -0.007187308743596077,
      -0.051072683185338974,
      0.0018235892057418823,
      0.035048708319664,
      0.018737589940428734,
      0.07866691052913666,
      0.08469585329294205,
      0.004504597745835781,
      0.03872346505522728,
      0.034562114626169205,
      -0.007125828880816698,
      0.025527235120534897,
      0.08474256098270416,
      -0.05459079518914223,
      0.0540030300617218,
      0.05021556466817856,
      0.04736518859863281,
      0.03913601115345955,
      0.1115596815943718,
      0.037617847323417664,
      -0.018225446343421936,
      0.04397301375865936,
      -0.0816860944032669,
      0.01049444917589426,
      -0.07379269599914551,
      0.0675843358039856,
      0.017725827172398567,
      0.057154953479766846,
      -0.05753912404179573,
      -0.001936295535415411,
      0.030494652688503265,
      -0.021329816430807114,
      0.02791222371160984,
      -0.0873536467552185,
      -0.035189755260944366,
      -0.05892995372414589,
      -0.009586907923221588,
      -0.009634369052946568,
      0.004579364322125912,
      0.06911095231771469,
      -0.07987435907125473,
      0.024697348475456238,
      0.03303810581564903,
      0.00772826885804534,
      0.022470705211162567,
      0.044306181371212006,
      0.08035779744386673,
      0.013135549612343311,
      -0.02332960069179535,
      -0.04006684944033623,
      0.025649618357419968,
      -0.08017344027757645,
      -0.004188603721559048,
      -0.028164474293589592,
      0.019026199355721474,
      0.0018516873242333531,
      -0.07502079755067825,
      -0.06711520254611969,
      -0.017053479328751564,
      0.07510131597518921,
      0.04215272516012192,
      -0.017069216817617416,
      0.02172194980084896,
      0.019611328840255737,
      0.10025360435247421,
      -0.034386929124593735,
      -0.12233907729387283,
      0.017989469692111015,
      -0.009079503826797009,
      -0.05179401859641075,
      -0.06093275919556618,
      -0.009166078642010689,
      0.0027061831206083298,
      0.0996515080332756,
      -0.043436210602521896,
      -0.013179239816963673,
      -0.01501155924052,
      -0.004735614638775587,
      -0.02174883708357811,
      -0.006706200540065765,
      0.0022449528332799673,
      -0.01899651810526848,
      0.030210277065634727,
      0.03228597342967987,
      0.030149046331644058,
      -0.016147058457136154,
      0.013824651017785072,
      0.03272165730595589,
      -0.052785344421863556,
      0.0912913903594017,
      -0.006213755812495947,
      -0.06655192375183105,
      -0.04802330210804939,
      0.030145343393087387,
      0.006606620270758867,
      -0.10159905254840851,
      0.05373215675354004,
      -0.07203828543424606,
      0.02743425779044628,
      0.087067149579525,
      -0.08659127354621887,
      -0.008451852947473526,
      -0.04014909639954567,
      0.034470345824956894,
      -0.01966390386223793,
      -0.031582076102495193,
      -0.08743077516555786,
      0.006134155206382275,
      -0.00065841362811625,
      -0.033749230206012726,
      0.0022053243592381477,
      0.034123409539461136,
      0.007299167104065418,
      0.02743714675307274,
      -0.046212129294872284,
      0.04567870497703552,
      -0.046033307909965515,
      -0.010848280042409897,
      0.04000883176922798,
      -0.024390531703829765,
      0.04492485895752907,
      -0.022318918257951736,
      -0.006891230586916208,
      0.0733066201210022,
      0.0895683690905571,
      -0.0012181088095530868,
      -0.07836445420980453,
      -0.037726908922195435,
      -0.05832555890083313,
      0.004220515489578247,
      -0.014477298595011234,
      -0.009519797749817371,
      0.02982686087489128,
      -0.05873842537403107,
      0.017877599224448204,
      0.0029886984266340733,
      0.04620007053017616,
      -0.013816975057125092,
      0.03237424045801163,
      0.0479004830121994,
      0.05889081582427025,
      -0.048352666199207306,
      0.047661468386650085,
      -0.01056087575852871,
      -0.0113447280600667,
      -0.009550997987389565,
      -0.04595218226313591,
      0.02205929532647133,
      -0.008089317940175533,
      -0.06323952972888947,
      -0.008433653973042965,
      0.045738667249679565,
      -0.03871973231434822,
      -0.025407709181308746,
      -0.012213286943733692,
      0.024935806170105934,
      -0.08339518308639526,
      0.005788981448858976,
      -0.04684660583734512,
      -0.026847805827856064,
      0.049257226288318634,
      -0.023896295577287674,
      -0.028960466384887695,
      -0.06408733129501343,
      0.045486580580472946,
      -0.018301036208868027,
      0.043198175728321075,
      -0.023932943120598793,
      -0.040206022560596466,
      -0.021971458569169044,
      0.006865146569907665,
      -0.0726262778043747,
      -0.047537095844745636,
      0.027760732918977737,
      -0.04950478672981262,
      0.034457698464393616,
      -0.032143037766218185,
      0.020836444571614265,
      -0.025534115731716156,
      0.0816977322101593,
      -0.01739005371928215,
      0.002772383391857147,
      -0.008827931247651577,
      0.03785407915711403,
      0.0012844708980992436,
      0.029852841049432755,
      0.010607642121613026,
      -0.02295870892703533,
      -0.011263875290751457,
      0.02096743881702423,
      -0.028673257678747177,
      0.05955100059509277,
      0.0025470065884292126,
      0.047004975378513336,
      -0.0012721865205094218,
      0.027760840952396393,
      0.019419973716139793,
      -0.03086959570646286,
      -0.03225715830922127,
      0.03536604717373848,
      0.05225340649485588,
      -0.024855943396687508,
      -0.04340750351548195,
      -0.01812179759144783,
      -0.01680288463830948,
      0.08068764209747314,
      0.014139040373265743,
      -0.005742231849581003,
      0.05879738926887512,
      -0.015639670193195343,
      -0.004802174866199493,
      -0.05165687948465347,
      0.04818766191601753,
      0.002772668609395623,
      0.022487983107566833,
      0.011355970054864883,
      -0.02118077501654625,
      0.00895934272557497,
      0.006280573550611734,
      0.09371266514062881,
      -0.04880233854055405,
      0.004763399716466665,
      0.005142713896930218,
      0.011730366386473179,
      -0.08153132349252701,
      0.02499987930059433,
      0.012131576426327229,
      0.02500489167869091,
      0.10825006663799286,
      0.056454889476299286,
      -0.05802673101425171,
      0.03897017240524292,
      0.04496178403496742,
      -0.032899245619773865,
      -0.004117419011890888,
      -0.051068149507045746,
      0.017352735623717308,
      0.014548975974321365,
      -0.040150899440050125,
      0.030474592000246048,
      -0.04550638049840927,
      0.04634624719619751,
      -0.012166126631200314,
      -0.01894271932542324,
      -0.004940278362482786,
      -0.0403435081243515,
      0.020747045055031776,
      -0.036577168852090836,
      -0.01841338723897934,
      0.012057453393936157,
      0.031976643949747086,
      0.032210368663072586,
      -0.11442293971776962,
      0.0009349617757834494,
      0.022111965343356133,
      -0.0016042562201619148,
      -0.05357244610786438,
      -0.06560631841421127,
      -0.027034644037485123,
      0.09859411418437958,
      -0.04914860427379608,
      0.0607571117579937,
      -0.05161605402827263,
      0.06380385160446167,
      -0.0491425022482872,
      0.04858783259987831,
      0.04421438276767731,
      -0.022480279207229614,
      -0.002525431802496314,
      0.06004856154322624,
      0.01397246029227972,
      0.03406701982021332,
      0.08638375997543335,
      -0.03865637630224228,
      -0.012104990892112255,
      0.028182053938508034,
      -0.04372390732169151,
      -0.08462610840797424,
      -0.0018962157191708684,
      -0.003867567516863346,
      -0.02905344031751156,
      -0.01761937513947487,
      -0.02644946612417698,
      -0.05513380840420723,
      0.018752632662653923,
      -0.04198620095849037,
      0.006113426294177771,
      -0.013975508511066437
    ],
    [
      -0.01581723615527153,
      -0.019508522003889084,
      0.035442549735307693,
      0.017995139583945274,
      -0.09049785137176514,
      -0.04621576890349388,
      -0.021316785365343094,
      -0.008221176452934742,
      -0.003506271168589592,
      -0.03934101387858391,
      0.023176370188593864,
      -0.0008402823004871607,
      0.024913249537348747,
      0.04165740683674812,
      0.0059804124757647514,
      -0.06640329957008362,
      -0.027262501418590546,
      0.06362368911504745,
      -0.014238466508686543,
      0.01151589211076498,
      -0.0392732098698616,
      -0.04316425696015358,
      0.05108831822872162,
      -0.02330823801457882,
      -0.008067780174314976,
      0.008733350783586502,
      -0.03135746344923973,
      -0.05610112473368645,
      0.049326732754707336,
      0.035232093185186386,
      0.03823534771800041,
      -0.011843124404549599,
      0.073784738779068,
      0.06536631286144257,
      -0.07550287991762161,
      -0.022630639374256134,
      -0.03885449096560478,
      -0.04546656832098961,
      -0.023566577583551407,
      0.07869499921798706,
      -0.034821394830942154,
      -0.042097896337509155,
      0.03001502901315689,
      0.04249280318617821,
      -0.011211138218641281,
      0.0708080530166626,
      -0.0033562430180609226,
      -0.01116347499191761,
      -0.06787809729576111,
      0.040661487728357315,
      0.06201615557074547,
      0.05136900022625923,
      -0.01042239647358656,
      -0.023166265338659286,
      0.024022366851568222,
      -0.05011989176273346,
      0.04812376946210861,
      0.006867761258035898,
      0.0037520669866353273,
      0.07047028839588165,
      0.02339559607207775,
      0.03303425386548042,
      0.0008438918157480657,
      -0.0824146419763565,
      0.008017634972929955,
      -0.06972097605466843,
      -0.03626314923167229,
      -0.01234292984008789,
      -0.08182986080646515,
      -0.013203146867454052,
      0.027308277785778046,
      -0.0052666799165308475,
      -0.008034548722207546,
      0.004208012018352747,
      -0.020405203104019165,
      0.03097096085548401,
      0.00412239832803607,
      -0.032469283789396286,
      0.029124289751052856,
      0.012373190373182297,
      -0.007168440613895655,
      -0.023731902241706848,
      -0.022857574746012688,
      -0.01915873773396015,
      0.0423169881105423,
      0.0367879718542099,
      0.03879852220416069,
      0.06490349024534225,
      0.007440493442118168,
      0.06529990583658218,
      0.060881633311510086,
      -0.08782213181257248,
      0.05846799910068512,
      0.016115043312311172,
      -0.017800698056817055,
      0.047695454210042953,
      0.01647370494902134,
      0.04518142715096474,
      -0.008515196852385998,
      -0.08889932930469513,
      -0.033975496888160706,
      0.01665792055428028,
      -0.008328360505402088,
      -0.025884557515382767,
      -0.02303491346538067,
      -0.057964157313108444,
      0.011424519121646881,
      0.016667161136865616,
      -0.0022766601759940386,
      0.019882408902049065,
      0.01710382103919983,
      0.00895041786134243,
      -0.0028163576498627663,
      0.04187599569559097,
      0.08296941220760345,
      0.01139563973993063,
      0.0076040904968976974,
      0.03542747721076012,
      -0.012179471552371979,
      -0.018622873350977898,
      -0.10302122682332993,
      0.1328669786453247,
      0.05273623391985893,
      0.025317562744021416,
      -0.02831101045012474,
      0.010272116400301456,
      0.07583068311214447,
      -0.017130322754383087,
      -0.004880755208432674,
      0.0551389642059803,
      0.0002779735077638179,
      -0.04215657338500023,
      -0.04315032437443733,
      0.01558378990739584,
      0.031122056767344475,
      -0.041954364627599716,
      0.0643371194601059,
      -0.026436885818839073,
      0.004005629103630781,
      0.0342596173286438,
      -0.009161263704299927,
      -0.04769027233123779,
      0.041880570352077484,
      -0.07327612489461899,
      -0.01492141094058752,
      -0.05718456953763962,
      0.05994449183344841,
      0.024695372208952904,
      0.049282003194093704,
      0.03561177849769592,
      -0.0009043406462296844,
      0.0651572197675705,
      0.07439720630645752,
      0.018213016912341118,
      -0.0634869858622551,
      -0.07188889384269714,
      0.09448461979627609,
      -0.0017975257942453027,
      0.04186863452196121,
      0.022904066368937492,
      -0.0487869493663311,
      -0.03315950930118561,
      -0.04706853628158569,
      0.0788315162062645,
      -0.04157458245754242,
      -0.04812636971473694,
      -0.016174329444766045,
      -0.04334032163023949,
      0.020317399874329567,
      -0.05354446917772293,
      -0.021498139947652817,
      0.025315014645457268,
      -0.019113020971417427,
      -0.014964754693210125,
      -0.07714477926492691,
      -0.037084683775901794,
      0.012311207130551338,
      -0.017678329721093178,
      0.03376920893788338,
      0.019725846126675606,
      0.009241849184036255,
      -0.011649380438029766,
      -0.05083329603075981,
      0.01718727871775627,
      -0.01612824946641922,
      0.04104134067893028,
      -0.008811778388917446,
      0.028617603704333305,
      -0.030066154897212982,
      -0.04964366555213928,
      -0.056031931191682816,
      -0.01913614198565483,
      0.01528234127908945,
      0.01843484491109848,
      0.05880463123321533,
      -0.05983394384384155,
      0.03452949970960617,
      0.08814169466495514,
      -0.04272802546620369,
      -0.03607601299881935,
      0.013563482090830803,
      -0.011147246696054935,
      0.06106717139482498,
      0.031248033046722412,
      -0.02019885927438736,
      0.05968230590224266,
      0.0332319512963295,
      0.0841313898563385,
      0.005388739984482527,
      0.007374865468591452,
      -0.056461673229932785,
      -0.12334112823009491,
      -0.021956251934170723,
      0.04376831278204918,
      0.011438852176070213,
      -0.023698367178440094,
      -0.006536934990435839,
      0.061853233724832535,
      0.031382009387016296,
      -0.023101769387722015,
      0.04728717729449272,
      0.039383068680763245,
      -0.043923500925302505,
      -0.01628151535987854,
      -0.034175384789705276,
      0.012541619129478931,
      0.03290406987071037,
      0.019250070676207542,
      -0.006131986156105995,
      0.10847345739603043,
      0.06873226910829544,
      -0.03673284500837326,
      0.0031555986497551203,
      0.001506773754954338,
      0.023291023448109627,
      -0.011970764957368374,
      0.040013834834098816,
      -0.047553375363349915,
      -0.018090205267071724,
      -0.03841029852628708,
      0.0008649799274280667,
      -0.06452324241399765,
      0.05550852417945862,
      -0.01968391239643097,
      -0.04091643914580345,
      -0.1061396598815918,
      -0.011408298276364803,
      0.00576454633846879,
      0.05869271978735924,
      0.021722281351685524,
      0.044355109333992004,
      0.02675454318523407,
      -0.03174708038568497,
      0.041265152394771576,
      0.06796739250421524,
      -0.007689447607845068,
      -0.09006313234567642,
      0.08555273711681366,
      0.0025803507305681705,
      -0.007824034430086613,
      0.022528359666466713,
      -0.014229260385036469,
      -0.025041431188583374,
      -0.00896406639367342,
      0.058827657252550125,
      0.12662073969841003,
      0.03177894651889801,
      0.024617666378617287,
      -0.028376180678606033,
      0.050788428634405136,
      -0.06992703676223755,
      -0.009154684841632843,
      0.004294537473469973,
      0.031769201159477234,
      0.03349294513463974,
      -0.016670700162649155,
      0.02856464684009552,
      -0.013876422308385372,
      0.001781411119736731,
      0.017958318814635277,
      -0.07897814363241196,
      0.03381849452853203,
      -0.0035697792191058397,
      -0.04497222974896431,
      0.04285641759634018,
      0.01961992122232914,
      -0.05255172774195671,
      -0.08932475000619888,
      -0.027081046253442764,
      -0.10974828898906708,
      -0.03084971196949482,
      -0.009905259124934673,
      -0.021802805364131927,
      0.019111862406134605,
      0.022296154871582985,
      -0.0011798628838732839,
      0.020523587241768837,
      0.010249256156384945,
      0.07312558591365814,
      0.015133798122406006,
      0.038551196455955505,
      -0.00031075740116648376,
      0.07216139137744904,
      0.005850284360349178,
      0.029383447021245956,
      0.05897760018706322,
      -0.03921137750148773,
      0.03435521945357323,
      -0.0026805244851857424,
      0.047443799674510956,
      -0.03028671070933342,
      0.02635303884744644,
      0.008833288215100765,
      -0.053227223455905914,
      -0.058286480605602264,
      0.06949011236429214,
      -0.011291482485830784,
      0.042141564190387726,
      0.01765218749642372,
      -0.05321778729557991,
      -0.012661810964345932,
      0.0338553823530674,
      -0.025083759799599648,
      -0.020045122131705284,
      -0.0042231520637869835,
      0.06547469645738602,
      0.014635922387242317,
      -0.006302979309111834,
      -0.003268548520281911,
      0.06379233300685883,
      -0.025356832891702652,
      0.04922864958643913,
      0.024266889318823814,
      0.055847298353910446,
      0.024334842339158058,
      0.07528054714202881,
      -0.03164563700556755,
      0.0288754403591156,
      0.0029643767047673464,
      0.06221308186650276,
      -0.000254375278018415,
      0.048045385628938675,
      -0.005712940823286772,
      0.054836779832839966,
      0.00775027135387063,
      -0.031690813601017,
      -0.04407030716538429,
      -0.01829991489648819,
      -0.03922051936388016,
      -0.05879070982336998,
      -0.036533746868371964,
      0.0010699236299842596,
      0.0541972853243351,
      -0.03732463717460632,
      -0.07107069343328476,
      -0.005700116045773029,
      -0.03686580806970596,
      0.003960632719099522,
      0.019066713750362396,
      0.042881470173597336,
      -0.04400403052568436,
      0.06532332301139832,
      0.07128112018108368,
      0.005273181479424238,
      -0.023404348641633987,
      -0.04049593582749367,
      -0.040293529629707336,
      -0.04650433734059334,
      0.02760465256869793,
      0.03777120262384415,
      0.04033631831407547,
      -0.023750903084874153,
      0.0064946855418384075,
      -0.03454604744911194,
      -0.014692405238747597,
      -0.006688976660370827,
      0.04089692234992981,
      0.07074008136987686,
      0.005452527664601803,
      -0.043146904557943344,
      -0.04046378284692764,
      0.025186872109770775,
      -0.02647263929247856,
      -0.03725351393222809,
      0.0495682954788208,
      0.02642878331243992,
      0.08666259795427322,
      -0.042710985988378525,
      0.002376148011535406,
      0.013688208535313606,
      -0.06940260529518127,
      0.03874508664011955,
      -0.016617368906736374,
      0.023981641978025436,
      0.016118494793772697,
      -0.03755173832178116,
      0.020296286791563034,
      0.029812293127179146,
      -0.05363363400101662,
      0.12431560456752777,
      -0.00018038367852568626,
      -0.09204841405153275,
      -0.042191535234451294,
      0.018927471712231636,
      -0.0011563051957637072,
      0.06030123308300972,
      -0.0006477236747741699,
      -0.025390999391674995,
      0.0271621011197567,
      0.008001565001904964,
      0.019498296082019806,
      0.032423753291368484,
      -0.04335753619670868,
      0.08881386369466782,
      -0.08452486246824265,
      0.04442531242966652,
      0.0323709100484848,
      -0.03963368013501167,
      0.02106245420873165,
      0.010264712385833263,
      0.04770766943693161,
      -0.02103770151734352,
      -0.003290109569206834,
      0.08055154979228973,
      0.04484441876411438,
      0.04453438147902489,
      0.0026392259169369936,
      0.019654158502817154,
      0.03492569923400879,
      -0.05361202359199524,
      -0.0069280085153877735,
      -0.027558499947190285,
      0.0268007293343544,
      -0.04991526156663895,
      0.06325630098581314,
      0.01484173908829689,
      -0.04122791066765785,
      -0.024806195870041847,
      -0.07999055087566376,
      -0.033827152103185654,
      -0.020594801753759384,
      0.06817559152841568,
      -0.02363675646483898,
      0.07126776874065399,
      0.053915321826934814,
      -0.06237926706671715,
      -0.04658958315849304,
      -0.0469825342297554,
      0.06583341956138611,
      0.02047940157353878,
      -0.008537190034985542,
      -0.027610808610916138,
      -0.0237855464220047,
      0.05520753189921379,
      -0.014587009325623512,
      -0.06108716130256653,
      -0.058859482407569885,
      -0.06313922256231308,
      -0.03982330113649368,
      -0.0011245406931266189,
      0.01580997183918953,
      -0.02385258674621582,
      -0.008546940051019192,
      0.06148742139339447,
      -0.02014874666929245,
      0.05054590478539467,
      0.053237371146678925,
      -0.06523098796606064,
      0.03583185002207756,
      0.06734221428632736,
      0.027632873505353928,
      0.007360172923654318,
      -0.03268512710928917,
      -0.011731021106243134,
      -0.021520644426345825,
      0.002319125458598137,
      -0.05145229771733284,
      0.01482901256531477,
      -0.03340362012386322,
      0.00749128358438611,
      -0.05369262769818306,
      0.0032431785948574543,
      0.0049687335267663,
      -0.0071670361794531345,
      0.01917712390422821,
      -0.07683666050434113,
      0.0505547970533371,
      0.0811537653207779,
      0.004073925316333771,
      0.022505303844809532,
      -0.007315199356526136,
      0.019027285277843475,
      -0.009010481648147106,
      -0.029576433822512627,
      0.020080147311091423,
      0.01945345476269722,
      -0.055011484771966934,
      0.04023287445306778,
      0.007067129481583834,
      0.05158952996134758,
      0.010964575223624706,
      -0.035850558429956436,
      0.018214384093880653
    ],
    [
      -0.029815277084708214,
      -0.029906706884503365,
      0.040718819946050644,
      0.10056492686271667,
      -0.053060390055179596,
      -0.012917036190629005,
      -0.00736738508567214,
      0.05530357360839844,
      0.007444344460964203,
      -0.023047415539622307,
      0.016330882906913757,
      -0.04260654374957085,
      0.01678912714123726,
      0.05966466665267944,
      0.009637905284762383,
      -0.012223527766764164,
      0.03435954451560974,
      -0.05175858363509178,
      0.040654636919498444,
      -0.0731782466173172,
      -0.014427877962589264,
      0.015395849011838436,
      0.025926219299435616,
      -0.019897039979696274,
      -0.035225145518779755,
      -0.07279311865568161,
      -0.0451505109667778,
      -0.058994121849536896,
      0.03615964949131012,
      0.004552050493657589,
      0.030848916620016098,
      0.03771543875336647,
      0.007637410424649715,
      0.01691030152142048,
      0.032787200063467026,
      -0.021683208644390106,
      -0.08639620244503021,
      -0.0006659334758296609,
      -0.032723210752010345,
      -0.03955955058336258,
      0.024640366435050964,
      -0.030639154836535454,
      0.021829446777701378,
      -0.021441692486405373,
      -0.08245787769556046,
      -0.03746575117111206,
      -0.037156250327825546,
      0.0778208002448082,
      0.013106083497405052,
      0.03692943975329399,
      -0.03888501599431038,
      -0.06734605878591537,
      -0.0010480416240170598,
      0.03130512312054634,
      -0.02000313438475132,
      0.011889439076185226,
      -0.03393688425421715,
      0.011353597976267338,
      0.10751595348119736,
      -0.03539753705263138,
      0.11720677465200424,
      -0.014204165898263454,
      0.035010144114494324,
      0.027105174958705902,
      -0.004398205317556858,
      -0.036123547703027725,
      0.04686149209737778,
      0.004004751332104206,
      0.11183547228574753,
      0.01884160190820694,
      -0.0069630262441933155,
      0.020762018859386444,
      -0.05082754045724869,
      -0.005565831437706947,
      -0.019475264474749565,
      -0.015467791818082333,
      -0.008397786878049374,
      0.011593569070100784,
      -0.013353058136999607,
      0.053627919405698776,
      0.05355879291892052,
      0.0008514309301972389,
      -0.022339103743433952,
      0.07456867396831512,
      -0.05095572769641876,
      0.011336121708154678,
      0.06642131507396698,
      0.004779472015798092,
      -0.035831548273563385,
      0.016297519207000732,
      -0.06216910481452942,
      0.005078720860183239,
      0.009489716961979866,
      0.02374987117946148,
      0.014444969594478607,
      0.01767447404563427,
      0.10861985385417938,
      0.04764467105269432,
      -0.04518136382102966,
      -0.007317695766687393,
      -0.07945432513952255,
      -0.028192954137921333,
      -0.04793681204319,
      -0.10505349189043045,
      -0.05874334275722504,
      -0.018336499109864235,
      0.013383195735514164,
      -0.046672217547893524,
      0.04913777485489845,
      -0.02030574530363083,
      0.04699430614709854,
      0.05966535210609436,
      -0.06067759171128273,
      -0.033443912863731384,
      0.007979567162692547,
      -0.030651148408651352,
      -0.03447412699460983,
      -0.042879682034254074,
      -0.0028063415084034204,
      0.013750854879617691,
      -0.047007810324430466,
      -0.05142432078719139,
      -0.019211960956454277,
      0.02000371366739273,
      0.08978434652090073,
      0.009881502948701382,
      -0.037226349115371704,
      0.03557703271508217,
      -0.03038134053349495,
      0.033337995409965515,
      -0.006760491523891687,
      -0.05978532135486603,
      0.005341146606951952,
      -0.020187005400657654,
      0.010803171433508396,
      -0.03905373439192772,
      -0.11126784980297089,
      -0.0669078528881073,
      -0.03325691074132919,
      0.0696358010172844,
      0.007935204543173313,
      -0.0010182387195527554,
      -0.04869522526860237,
      0.07804074883460999,
      0.10046182572841644,
      0.05373038724064827,
      -0.016136409714818,
      0.05241050198674202,
      0.002504852367565036,
      -0.02756137028336525,
      -0.044961731880903244,
      -0.029179928824305534,
      0.013057081028819084,
      -0.07142423838376999,
      0.01601419411599636,
      0.04969821497797966,
      0.11664009094238281,
      -0.0659337267279625,
      0.004747410304844379,
      0.02784493938088417,
      -0.006944736465811729,
      0.03467798978090286,
      -0.06656406074762344,
      0.025581464171409607,
      0.028684338554739952,
      0.010631261393427849,
      0.012488617561757565,
      0.06852105259895325,
      0.012341559864580631,
      -0.06549748033285141,
      0.007758723571896553,
      0.061392925679683685,
      0.01334896869957447,
      -0.028449684381484985,
      -0.060367438942193985,
      0.002761656418442726,
      -0.013111953623592854,
      0.043714575469493866,
      0.05298880860209465,
      0.06893619894981384,
      0.057333920150995255,
      -0.02268536202609539,
      -0.03509289771318436,
      0.018840616568922997,
      0.03188817948102951,
      -0.056067705154418945,
      -0.06413605064153671,
      0.005737644154578447,
      0.01380470022559166,
      0.060878705233335495,
      -0.017226168885827065,
      0.05535505339503288,
      0.00035074425977654755,
      0.05368538945913315,
      -0.07142715156078339,
      -0.03410666808485985,
      0.052840109914541245,
      0.054654110223054886,
      0.0021996893920004368,
      0.043312739580869675,
      0.04025869816541672,
      0.09918256103992462,
      -0.0017981207929551601,
      -0.0035000909119844437,
      -0.011929865926504135,
      0.06940639019012451,
      -0.11196353286504745,
      -0.027883198112249374,
      0.00667579285800457,
      0.06210366263985634,
      -0.020955562591552734,
      -0.006341590546071529,
      -0.02659727819263935,
      -0.0694185346364975,
      -0.024386348202824593,
      0.06694221496582031,
      0.04071500524878502,
      -0.07926394045352936,
      -0.009940650314092636,
      0.0088869733735919,
      -0.026016978546977043,
      -0.0010653403587639332,
      -0.07482729107141495,
      0.0076812319457530975,
      -0.015781257301568985,
      0.005676450207829475,
      0.03145124018192291,
      -0.01024690642952919,
      -0.0025243188720196486,
      -0.05793921649456024,
      -0.04272806644439697,
      0.05499105527997017,
      0.06747464090585709,
      0.06563176214694977,
      0.012988009490072727,
      -0.053902313113212585,
      0.033755529671907425,
      -0.023105667904019356,
      -0.01899963989853859,
      0.04258200526237488,
      0.049121830612421036,
      0.02531839720904827,
      -0.03776175528764725,
      -0.053202539682388306,
      0.03919666260480881,
      0.017765264958143234,
      0.04669470712542534,
      0.030956579372286797,
      -0.05494577810168266,
      0.013322840444743633,
      -0.06592097878456116,
      -0.01508887205272913,
      0.03879939392209053,
      -0.0034495729487389326,
      0.00805883202701807,
      0.0668935775756836,
      0.09271183609962463,
      0.03816617280244827,
      -0.08544743061065674,
      -0.007637061178684235,
      -0.03934946656227112,
      0.04440993070602417,
      -0.05977350100874901,
      0.01444465946406126,
      -0.01317200530320406,
      0.009064866229891777,
      -0.08354645222425461,
      0.007237010169774294,
      -0.11499368399381638,
      0.056349772959947586,
      -0.030174195766448975,
      0.011750987730920315,
      -0.01725216954946518,
      -0.04505659267306328,
      0.015065699815750122,
      -0.02608431689441204,
      -0.052985310554504395,
      -0.03388203680515289,
      0.022412003949284554,
      0.026022538542747498,
      -0.0013221141416579485,
      -0.003952615894377232,
      -0.047078028321266174,
      0.025104058906435966,
      -0.06900345534086227,
      -0.07997433841228485,
      0.03189479187130928,
      0.0033374233171343803,
      0.05999772250652313,
      -0.042122337967157364,
      0.08632836490869522,
      0.020413478836417198,
      -0.04561156406998634,
      -0.009126398712396622,
      0.02078535594046116,
      0.05358291417360306,
      0.06167091056704521,
      -0.0159378070384264,
      0.11768186092376709,
      -0.07162659615278244,
      0.013505687937140465,
      0.0032332774717360735,
      -0.07192467153072357,
      0.035161614418029785,
      -0.00669249938800931,
      -0.003756038611754775,
      0.0025791837833821774,
      0.06209607422351837,
      0.03979545459151268,
      -0.04833938181400299,
      -0.03639279678463936,
      0.03291499614715576,
      -0.00904284231364727,
      0.04630488157272339,
      -0.10658557713031769,
      -0.021578703075647354,
      -0.006228146143257618,
      -0.019607141613960266,
      -0.07011743634939194,
      -9.67976448009722e-05,
      -0.06247173249721527,
      -0.009415030479431152,
      -0.14731137454509735,
      -0.030457528308033943,
      0.0840691477060318,
      0.0077147711999714375,
      0.035384926944971085,
      -0.01423516497015953,
      0.04748211055994034,
      -0.04460696503520012,
      -0.007878821343183517,
      0.04236466437578201,
      0.0041090864688158035,
      -0.009332368150353432,
      -0.01690547727048397,
      -0.0056142196990549564,
      -0.007146631833165884,
      -0.028864188119769096,
      -0.056317321956157684,
      -0.069290392100811,
      -0.030236514285206795,
      -0.0663149431347847,
      -0.042428839951753616,
      -0.02287166379392147,
      -0.0385436937212944,
      0.010743276216089725,
      0.0069627477787435055,
      -0.03949962183833122,
      -0.006703603081405163,
      -0.03180084377527237,
      0.07258085161447525,
      0.011323124170303345,
      0.018270296975970268,
      -0.004113899543881416,
      0.10523826628923416,
      0.045600567013025284,
      -0.008235436864197254,
      -0.0313384011387825,
      -0.049831680953502655,
      0.03511019051074982,
      -0.057400308549404144,
      0.02099679969251156,
      0.0062849260866642,
      -0.01045391894876957,
      -0.07482650130987167,
      -0.035957034677267075,
      -0.04120083153247833,
      -0.0058730049058794975,
      -0.009319634176790714,
      -0.03034319542348385,
      0.012958875857293606,
      0.020383745431900024,
      0.0010902456706389785,
      0.03791993483901024,
      0.01075156033039093,
      -0.053256336599588394,
      0.00779228238388896,
      -0.07595575600862503,
      -0.03856516256928444,
      0.046844299882650375,
      -0.02978925034403801,
      0.05715364217758179,
      -0.08019400388002396,
      0.01900644786655903,
      -0.010618449188768864,
      -0.01011619158089161,
      -0.005662982352077961,
      -0.09502702951431274,
      -0.042041976004838943,
      0.06734181940555573,
      -0.016368769109249115,
      0.013007362373173237,
      0.00167199713177979,
      -0.012357719242572784,
      -0.041395705193281174,
      0.04716511070728302,
      -0.03870276361703873,
      -0.025344878435134888,
      -0.03696919232606888,
      0.02200883999466896,
      0.07446605712175369,
      -0.059164635837078094,
      -0.03479292616248131,
      0.1002480685710907,
      0.0008739507175050676,
      -0.07124190777540207,
      -0.004650810267776251,
      0.01125275157392025,
      0.011142835952341557,
      -0.03359326720237732,
      -0.024608954787254333,
      -0.0353056900203228,
      -0.024271339178085327,
      0.0659014955163002,
      0.038404904305934906,
      -0.018396925181150436,
      0.08224868029356003,
      -0.04501490294933319,
      -0.014483710750937462,
      0.06191546842455864,
      0.023691484704613686,
      0.016573330387473106,
      -0.02781425043940544,
      -0.03283235803246498,
      -0.021237893030047417,
      0.0032439797651022673,
      -0.012629703618586063,
      -0.005182105582207441,
      0.07988423854112625,
      -0.049605175852775574,
      -0.043624937534332275,
      -0.0028035403229296207,
      0.05438072234392166,
      0.02952449768781662,
      -0.03750801458954811,
      -0.06516130268573761,
      0.02568107284605503,
      -0.03606070950627327,
      0.03913869336247444,
      -0.039066560566425323,
      0.031199513003230095,
      0.033170633018016815,
      0.006163383834064007,
      0.003203545231372118,
      -0.009248868562281132,
      -0.08296800404787064,
      0.026863940060138702,
      -0.06444478034973145,
      0.04947560653090477,
      0.04425840079784393,
      -0.008323674090206623,
      -0.04073284566402435,
      0.0648442879319191,
      0.018334336578845978,
      0.04781458526849747,
      -0.019652632996439934,
      -0.039548613131046295,
      -0.002812434220686555,
      0.06916596740484238,
      -0.018761824816465378,
      0.0815919041633606,
      -0.0024034371599555016,
      -0.03821383789181709,
      0.017487743869423866,
      0.09130377322435379,
      -0.027756541967391968,
      -0.03278784453868866,
      -0.015674911439418793,
      0.009616994298994541,
      -0.004967267159372568,
      -0.013333369046449661,
      0.006510189268738031,
      0.06126699969172478,
      0.10331082344055176,
      -0.0028969442937523127,
      -0.0701785683631897,
      -0.0843682661652565,
      0.03893327713012695,
      0.09656482189893723,
      0.07262564450502396,
      0.002627738518640399,
      -0.01266302540898323,
      -0.06368238478899002,
      0.007902651093900204,
      -0.0048974440433084965,
      0.012319887988269329,
      0.015858089551329613,
      -0.1465422809123993,
      0.06589445471763611,
      -0.11208011209964752,
      -0.05327904224395752,
      0.01923779770731926,
      -0.037410885095596313,
      0.013552424497902393,
      0.024842916056513786,
      0.0667034164071083,
      -0.0016589106526225805,
      -0.01791340857744217,
      -0.01106077991425991,
      0.010998472571372986,
      -0.05765625834465027,
      0.11055158823728561,
      0.03980877622961998
    ],
    [
      0.056226518005132675,
      -0.05764435976743698,
      0.07589562237262726,
      -0.020948626101017,
      0.01872771605849266,
      0.00859597884118557,
      -0.008739631623029709,
      0.03301633894443512,
      0.024493690580129623,
      0.04204625263810158,
      0.011979106813669205,
      -0.08068298548460007,
      0.052216265350580215,
      -0.05992493778467178,
      0.04745859652757645,
      -0.008351568132638931,
      0.029735080897808075,
      -0.04356750473380089,
      -0.01623048633337021,
      -0.05334259569644928,
      -0.015244189649820328,
      -0.08056101948022842,
      0.014161147177219391,
      -0.04019533470273018,
      -0.013990146107971668,
      0.005676186177879572,
      0.06709364801645279,
      -0.06518233567476273,
      -0.030849453061819077,
      -0.0217111986130476,
      -0.10344349592924118,
      -0.01517887320369482,
      0.01677653193473816,
      0.06993351876735687,
      0.02991197258234024,
      0.006522010546177626,
      -0.042076047509908676,
      0.026941925287246704,
      -0.020702553912997246,
      0.005006032530218363,
      -0.059352729469537735,
      0.069406658411026,
      0.03834078833460808,
      -0.012731879018247128,
      -0.004699965938925743,
      0.05978117510676384,
      -0.01803068444132805,
      -0.02502160333096981,
      -0.017861997708678246,
      -0.054226238280534744,
      0.014325576834380627,
      0.019754644483327866,
      0.027687381953001022,
      0.012562775053083897,
      -0.015816891565918922,
      -0.021236663684248924,
      0.018789634108543396,
      -0.03381946682929993,
      -0.0701322928071022,
      -0.07824095338582993,
      0.03121885284781456,
      0.005929097533226013,
      -0.04772268980741501,
      -0.04166566953063011,
      0.055118147283792496,
      0.005745145957916975,
      -0.08239301294088364,
      0.02558312751352787,
      0.016458895057439804,
      0.02071072719991207,
      -0.0015886974288150668,
      0.040354110300540924,
      0.032705873250961304,
      -0.07040318846702576,
      -0.03582216426730156,
      -0.017358511686325073,
      -0.029785800725221634,
      0.09386845678091049,
      0.0004646957677323371,
      0.019533351063728333,
      0.06101386994123459,
      0.002883800771087408,
      0.011527427472174168,
      -0.01409236341714859,
      -0.006377797573804855,
      -0.06736578047275543,
      0.009119146503508091,
      -0.0021587114315479994,
      0.0045405784621834755,
      0.027526266872882843,
      -0.09046800434589386,
      0.014354255981743336,
      0.006063826847821474,
      0.061427392065525055,
      -0.008153922855854034,
      -0.021766042336821556,
      0.019900040701031685,
      -0.012641668319702148,
      0.06268107891082764,
      -0.06964757293462753,
      -0.05531248822808266,
      0.0006957250880077481,
      0.07705467194318771,
      0.027244966477155685,
      -0.03312571719288826,
      -0.09283281117677689,
      -0.03521549701690674,
      0.03880198299884796,
      -0.024999232962727547,
      0.011805037036538124,
      -0.002796825021505356,
      0.006199356634169817,
      -0.07109355181455612,
      -0.09253521263599396,
      0.03623756021261215,
      0.06105470284819603,
      -0.031844738870859146,
      -0.11934106051921844,
      0.0626419335603714,
      -0.02373172901570797,
      -0.04171398654580116,
      -0.039998240768909454,
      -0.05079082399606705,
      -0.044633302837610245,
      -0.008386128582060337,
      0.022417055442929268,
      -0.021965820342302322,
      0.018392721191048622,
      0.004982409533113241,
      -0.04379747435450554,
      0.05759093165397644,
      -0.018833715468645096,
      -0.02068949118256569,
      -0.015921926125884056,
      0.0017942273989319801,
      -0.03145012632012367,
      -0.03785588592290878,
      0.0018351948820054531,
      0.046137258410453796,
      -0.09458669275045395,
      -0.02008882164955139,
      -0.06693466007709503,
      0.04529746621847153,
      0.01795942895114422,
      0.012510697357356548,
      -0.01233039889484644,
      0.021957870572805405,
      -0.011912157759070396,
      0.05112890154123306,
      0.050804875791072845,
      0.028712915256619453,
      -0.0426187738776207,
      -0.009946678765118122,
      -0.06620026379823685,
      0.08169502019882202,
      0.08578295260667801,
      0.025546681135892868,
      0.06993521004915237,
      -0.006535504013299942,
      -0.021630071103572845,
      0.008959135040640831,
      -0.008641396649181843,
      0.018787305802106857,
      0.08767582476139069,
      -0.07616410404443741,
      -0.01373909879475832,
      -0.02256176434457302,
      -0.0698118582367897,
      0.02614898607134819,
      -0.012330765835940838,
      -0.07921943813562393,
      0.05604087933897972,
      -0.0049598985351622105,
      0.05507613345980644,
      0.028963303193449974,
      0.07735707610845566,
      0.006601735483855009,
      -0.009124968200922012,
      0.11270475387573242,
      -0.048917874693870544,
      0.01965426281094551,
      -0.014690681360661983,
      -0.012334262020885944,
      -0.023106660693883896,
      -0.029567884281277657,
      0.06259375810623169,
      -0.045718420296907425,
      0.012028934434056282,
      -0.01985885389149189,
      0.025567663833498955,
      -0.030998792499303818,
      0.02035916969180107,
      -0.03789842501282692,
      0.00307598733343184,
      0.026535145938396454,
      -0.024501876905560493,
      -0.06780460476875305,
      0.00731984106823802,
      -0.06412675976753235,
      0.0583418644964695,
      0.06882727891206741,
      -0.0887729600071907,
      -0.008018076419830322,
      0.01856083795428276,
      0.01644231379032135,
      0.057081956416368484,
      0.0012438572011888027,
      0.040703073143959045,
      0.060433220118284225,
      -0.013863561674952507,
      0.02883528731763363,
      -0.04075261577963829,
      -0.11738459020853043,
      0.006889004725962877,
      -0.03615172207355499,
      -0.04715122655034065,
      -0.020335419103503227,
      0.04728396609425545,
      0.04181971400976181,
      -0.04817046970129013,
      0.04685294255614281,
      -0.006618465296924114,
      -0.03689524158835411,
      -0.012967527844011784,
      0.053845297545194626,
      0.08707109838724136,
      -0.008783253841102123,
      0.06564238667488098,
      -0.07840471714735031,
      0.04158414527773857,
      0.01422498282045126,
      0.06938842684030533,
      0.012204697355628014,
      0.07134891301393509,
      0.001924579031765461,
      0.03021973744034767,
      -0.06548416614532471,
      -0.01945619471371174,
      0.042542025446891785,
      0.04078960046172142,
      -0.05060466751456261,
      -0.03507751226425171,
      0.004977979231625795,
      0.03305194899439812,
      -0.008376821875572205,
      -0.062369514256715775,
      0.04058992117643356,
      0.02660851553082466,
      -0.07709488272666931,
      0.012496342882514,
      0.05021458864212036,
      -0.05901210382580757,
      0.012657889164984226,
      -0.05390748381614685,
      0.0643785148859024,
      -0.012514988891780376,
      -0.007929233834147453,
      0.02066071704030037,
      0.07853297144174576,
      0.0453064925968647,
      0.08355236798524857,
      0.02612791210412979,
      0.012288503348827362,
      -0.09854671359062195,
      0.001871362212114036,
      0.0643639788031578,
      -0.07114636898040771,
      0.09558571130037308,
      0.008697306737303734,
      0.041620347648859024,
      0.050915613770484924,
      0.05235673487186432,
      -0.017240149900317192,
      0.07279035449028015,
      0.004597090184688568,
      0.021853815764188766,
      -0.042428359389305115,
      -0.06556222587823868,
      -0.06599404662847519,
      0.025785574689507484,
      -0.021673552691936493,
      -0.027582483366131783,
      0.044398486614227295,
      -0.035852283239364624,
      -0.005054109264165163,
      -0.008944339118897915,
      0.01582206040620804,
      -0.062397610396146774,
      -0.01142906490713358,
      -0.009335755370557308,
      -0.04827101528644562,
      0.07029193639755249,
      -0.06373137980699539,
      -0.03998049721121788,
      -0.019912848249077797,
      0.028541313484311104,
      -0.016613895073533058,
      0.020607713609933853,
      0.01727394200861454,
      -0.0073795681819319725,
      -0.032159749418497086,
      -0.037044208496809006,
      -0.08953212201595306,
      -0.013976923190057278,
      -0.0424734428524971,
      -0.025204837322235107,
      0.03397234156727791,
      0.0011171094374731183,
      0.06088826432824135,
      0.029794499278068542,
      -0.07188752293586731,
      0.03494423255324364,
      -0.01972190849483013,
      -0.00024102625320665538,
      -0.014871886000037193,
      0.031040476635098457,
      -0.013679798692464828,
      0.03514832258224487,
      0.10100390017032623,
      0.06418045610189438,
      -0.02016766183078289,
      -0.025729801505804062,
      0.014279032126069069,
      0.038069017231464386,
      0.08902435004711151,
      0.039557747542858124,
      0.07584456354379654,
      -0.08333416283130646,
      -0.06803396344184875,
      -0.008009888231754303,
      0.012887511402368546,
      -0.039586689323186874,
      -0.049328047782182693,
      0.015443229116499424,
      -0.020155802369117737,
      -0.04080004617571831,
      0.034045834094285965,
      -0.08022698760032654,
      0.13651347160339355,
      0.011222876608371735,
      -0.020664731040596962,
      -0.0677252784371376,
      0.03607563674449921,
      -0.029273461550474167,
      0.03571264073252678,
      0.06134481728076935,
      -0.037720683962106705,
      -0.00824549701064825,
      0.07570809125900269,
      -0.03528071194887161,
      0.052646491676568985,
      -0.017588842660188675,
      -0.07149995863437653,
      -0.004787967540323734,
      -0.02692517824470997,
      0.0581192784011364,
      0.06513625383377075,
      -0.0824870690703392,
      -0.02474578656256199,
      0.06025993078947067,
      0.004378072917461395,
      -0.00919087789952755,
      -0.08382181078195572,
      -0.007942942902445793,
      -0.045386698096990585,
      0.03616825491189957,
      -0.008391807787120342,
      0.04021234065294266,
      -0.021079659461975098,
      0.016627715900540352,
      0.044005025178194046,
      0.028062140569090843,
      0.00996307097375393,
      0.027559557929635048,
      -0.0584159791469574,
      0.07631335407495499,
      -0.030000461265444756,
      0.11703109741210938,
      -0.012926421128213406,
      0.023962415754795074,
      -0.019021734595298767,
      -0.04436613246798515,
      0.00545543571934104,
      0.0605577677488327,
      -0.05956582725048065,
      -0.006617150269448757,
      -0.007549127098172903,
      -0.05320972949266434,
      0.04183872044086456,
      0.05382915213704109,
      0.02322668954730034,
      -0.04624108597636223,
      -0.00019247937598265707,
      -0.06148241087794304,
      0.022789210081100464,
      -0.017835630103945732,
      0.036013200879096985,
      0.030397506430745125,
      -0.028293073177337646,
      0.0075991288758814335,
      -0.0038522144313901663,
      -0.022906187921762466,
      0.0882856622338295,
      0.021143808960914612,
      0.0758141279220581,
      0.04462990537285805,
      0.04773598536849022,
      0.041220664978027344,
      -0.007115479558706284,
      -0.019663695245981216,
      0.02694840170443058,
      0.043265942484140396,
      -0.05702436715364456,
      0.0915820449590683,
      0.012117089703679085,
      -0.053712863475084305,
      -0.06685353815555573,
      0.0030918288975954056,
      -0.0319717600941658,
      0.0020202905870974064,
      0.04831194505095482,
      0.05717315524816513,
      0.025572653859853745,
      -0.0879063680768013,
      0.11828041821718216,
      0.04474757984280586,
      -0.030371040105819702,
      0.025526786223053932,
      -0.03645167127251625,
      -0.03638233616948128,
      -0.018824314698576927,
      -0.00603867182508111,
      0.01755274273455143,
      0.04958822578191757,
      0.07162197679281235,
      0.06621939688920975,
      0.09770354628562927,
      0.019777076318860054,
      0.05309108644723892,
      -0.012887583114206791,
      -0.006203379016369581,
      -0.0007892015273682773,
      0.059123359620571136,
      0.06576772779226303,
      -0.048685722053050995,
      0.05506574735045433,
      0.004387463442981243,
      -0.006191733758896589,
      -0.013663598336279392,
      -0.012177263386547565,
      -0.045905403792858124,
      0.05283455178141594,
      0.011035863310098648,
      0.03673556074500084,
      0.03649215027689934,
      -9.482071618549526e-05,
      0.014056003652513027,
      -0.0656699538230896,
      -0.047005437314510345,
      0.0059141297824680805,
      -0.003223944688215852,
      -0.021651124581694603,
      -0.012349555268883705,
      -0.01572907343506813,
      -0.0020315605215728283,
      -0.0089104687795043,
      0.052045129239559174,
      -0.010421911254525185,
      0.007723615504801273,
      0.010817253030836582,
      -0.025542596355080605,
      -0.01634112000465393,
      0.02792365849018097,
      -0.00490235211327672,
      -0.07252354174852371,
      -0.02695818804204464,
      -0.012320838868618011,
      0.017096586525440216,
      0.05816919356584549,
      0.09218355268239975,
      0.0788533166050911,
      0.004726451821625233,
      -0.004118716344237328,
      -0.051701340824365616,
      0.023659510537981987,
      0.017365865409374237,
      0.10033854842185974,
      0.06081656366586685,
      0.0031820645090192556,
      -0.06274774670600891,
      0.06670534610748291,
      -0.10055356472730637,
      -0.04714260995388031,
      0.04915019869804382,
      0.03891802206635475,
      0.04960103705525398,
      0.04516533762216568,
      -0.11171068996191025,
      0.014442475512623787,
      -0.042136773467063904,
      -0.029694147408008575,
      0.0254038255661726,
      -0.03622173145413399
    ],
    [
      0.04499869421124458,
      0.053644340485334396,
      -0.010222328826785088,
      -0.016731787472963333,
      0.04822812229394913,
      -0.04851231351494789,
      0.021175995469093323,
      0.06498824059963226,
      -0.041621118783950806,
      -0.01729358173906803,
      0.017377393320202827,
      -0.04442893713712692,
      0.059941478073596954,
      0.06991906464099884,
      0.04253149405121803,
      -0.0171396154910326,
      -0.07229503989219666,
      -0.048528965562582016,
      -0.0025794622488319874,
      0.039357271045446396,
      0.028548497706651688,
      -0.08117127418518066,
      -0.03255434334278107,
      -0.041386570781469345,
      0.06297071278095245,
      -0.044756047427654266,
      -0.03773362562060356,
      -0.08755812048912048,
      -0.05315915122628212,
      0.0019441680051386356,
      0.04202530160546303,
      -0.005499718245118856,
      0.024338342249393463,
      -0.004520315211266279,
      -0.019620131701231003,
      0.04783231019973755,
      0.013199654407799244,
      0.021559568122029305,
      0.019723372533917427,
      -0.05242057517170906,
      -0.031745851039886475,
      -0.024931641295552254,
      -0.019470924511551857,
      -0.006024395115673542,
      -0.03529052436351776,
      -0.01734556443989277,
      0.02814231440424919,
      -0.028759410604834557,
      -0.02401338890194893,
      -0.045022811740636826,
      0.034414369612932205,
      0.035443440079689026,
      -0.07950976490974426,
      0.007438690867275,
      -0.05099796503782272,
      -0.06123950704932213,
      0.0071298484690487385,
      0.0003439939464442432,
      -0.053738534450531006,
      0.008692841045558453,
      -0.002675403142347932,
      0.031086677685379982,
      0.03191038593649864,
      0.01798139698803425,
      -0.005974835250526667,
      0.037978965789079666,
      0.018889037892222404,
      0.02716098353266716,
      0.031612612307071686,
      -0.04981052130460739,
      -0.01996334083378315,
      0.040916599333286285,
      -0.04483335465192795,
      -0.0361919105052948,
      -0.10749577730894089,
      0.004381396807730198,
      0.024035517126321793,
      0.03944334387779236,
      -0.022589053958654404,
      0.003034412395209074,
      0.039016563445329666,
      0.048396673053503036,
      0.02832852676510811,
      0.0017763545038178563,
      -0.0021383336279541254,
      0.0644044578075409,
      0.0513656847178936,
      0.010084646753966808,
      -0.04753701761364937,
      -0.021651633083820343,
      -0.039278652518987656,
      -0.011556143872439861,
      -0.07656823843717575,
      -0.03322669118642807,
      -0.04893149808049202,
      -0.035768307745456696,
      -0.019304323941469193,
      -0.07691886276006699,
      0.03592675179243088,
      -0.002856787061318755,
      -0.02053188905119896,
      -0.00010361209569964558,
      0.02124306745827198,
      -0.0398695208132267,
      0.023445123806595802,
      -0.00284627010114491,
      -0.08125810325145721,
      -0.015566305257380009,
      -0.005824791267514229,
      0.0788334384560585,
      0.039121635258197784,
      -0.015728114172816277,
      -0.028394082561135292,
      -0.034022584557533264,
      -0.05591094493865967,
      -0.005697210319340229,
      -0.013684767298400402,
      0.007387739606201649,
      -0.061009008437395096,
      0.0027221350464969873,
      -0.02729271724820137,
      0.034425441175699234,
      0.01769709214568138,
      0.04935857653617859,
      0.014018853195011616,
      0.05488942191004753,
      -0.06471887230873108,
      -0.013631883077323437,
      0.051188841462135315,
      -0.03550007566809654,
      0.024440260604023933,
      0.01353102270513773,
      -0.03285371512174606,
      -0.033166494220495224,
      0.04115007817745209,
      0.010667452588677406,
      -0.033598750829696655,
      0.03284105286002159,
      -0.07477991282939911,
      0.07806532829999924,
      -0.026108518242836,
      0.02852429263293743,
      -0.03834964707493782,
      0.0145803177729249,
      0.005079719237983227,
      -0.04240034148097038,
      0.03838617727160454,
      0.07996474206447601,
      0.04407598078250885,
      0.01643986999988556,
      -0.019725073128938675,
      0.02397129125893116,
      0.033385105431079865,
      0.014651949517428875,
      -0.03412314131855965,
      -0.027247251942753792,
      0.011653991416096687,
      0.016561320051550865,
      -0.022397605702280998,
      -0.040312644094228745,
      0.11534871906042099,
      0.04367087036371231,
      -0.09263168275356293,
      -0.007017575204372406,
      0.09816000610589981,
      0.045820072293281555,
      -0.044132981449365616,
      -0.048016563057899475,
      -0.056034233421087265,
      -0.06702467054128647,
      -0.003262518672272563,
      0.0648689866065979,
      -0.04397815838456154,
      0.04215662553906441,
      0.06280054897069931,
      0.05820203572511673,
      0.021016500890254974,
      -0.10984810441732407,
      0.04426140338182449,
      0.05234025791287422,
      -0.01272311620414257,
      -0.012718575075268745,
      -0.024998506531119347,
      0.017703834921121597,
      0.0054045566357672215,
      -0.06333084404468536,
      -0.011258059181272984,
      0.03257139399647713,
      -0.05321982502937317,
      -0.0005637214053422213,
      0.029839755967259407,
      -0.018274890258908272,
      0.033835068345069885,
      -0.0006291870959103107,
      -0.04191742092370987,
      0.15747030079364777,
      -0.00548210646957159,
      0.006154019385576248,
      -0.006260265130549669,
      0.010319172404706478,
      0.048280470073223114,
      -0.06343099474906921,
      -0.05669860169291496,
      -0.0417044498026371,
      0.025719469413161278,
      -0.08993933349847794,
      -0.012849162332713604,
      -0.03889230638742447,
      0.03585576266050339,
      0.008581786416471004,
      0.009872673079371452,
      0.04608483612537384,
      -0.12699875235557556,
      -0.06668210029602051,
      -0.025269322097301483,
      -0.0665481686592102,
      -0.058244045823812485,
      0.015355010516941547,
      -0.071935273706913,
      0.02100321836769581,
      0.04992538318037987,
      0.027861012145876884,
      -0.03806372731924057,
      0.05468814820051193,
      -0.046607110649347305,
      -0.035934995859861374,
      0.06614184379577637,
      0.06536037474870682,
      0.01019900944083929,
      0.0055466205812990665,
      0.02057037316262722,
      0.017734922468662262,
      -0.04984401538968086,
      0.08134617656469345,
      0.03632660210132599,
      0.08872628211975098,
      0.034083042293787,
      -0.008136585354804993,
      0.05480679124593735,
      0.04164149612188339,
      0.04617485776543617,
      -0.01427141111344099,
      0.05336138233542442,
      -0.0032742221374064684,
      0.012643118388950825,
      0.03452847898006439,
      0.09178885817527771,
      -0.0078439274802804,
      0.07775977998971939,
      0.05258360132575035,
      0.02635333500802517,
      0.023432156071066856,
      -0.013172105886042118,
      0.003962671849876642,
      0.0027106807101517916,
      -0.01717848889529705,
      -0.031026624143123627,
      -0.09143885225057602,
      0.043133676052093506,
      0.07009213417768478,
      -0.06428807973861694,
      0.006801762152463198,
      0.0140894940122962,
      -0.08717558532953262,
      -0.004629446659237146,
      0.018954282626509666,
      -0.005012793466448784,
      -0.028086591511964798,
      -0.04727942869067192,
      0.030265001580119133,
      0.09545017033815384,
      0.0011389858555048704,
      -0.05828513577580452,
      -0.05806630849838257,
      0.002020030515268445,
      -0.03371928632259369,
      -0.008662201464176178,
      0.0017383835511282086,
      0.0006261097732931376,
      -0.017679180949926376,
      -0.047704003751277924,
      0.0008297543390654027,
      -0.027452943846583366,
      -0.0028126491233706474,
      -0.045457132160663605,
      0.007774251978844404,
      0.05375736579298973,
      -0.04494549334049225,
      -0.02431904710829258,
      0.002916748635470867,
      -0.07844947278499603,
      0.03310808911919594,
      -0.01062453631311655,
      0.023950213566422462,
      -0.05650535970926285,
      0.0338907353579998,
      0.003305674297735095,
      0.08469509333372116,
      -0.08792340755462646,
      0.01736534759402275,
      -0.036661215126514435,
      -0.033718038350343704,
      -0.02649291232228279,
      -0.041396018117666245,
      0.0005684822099283338,
      -0.03217964619398117,
      0.0419902577996254,
      -0.024115780368447304,
      0.0251202043145895,
      0.041378166526556015,
      0.05357244983315468,
      -0.014481808990240097,
      -0.02563241496682167,
      0.046945277601480484,
      -0.05706026405096054,
      -0.004181944765150547,
      -0.003935794811695814,
      -0.002162825083360076,
      -0.0058054630644619465,
      0.042364928871393204,
      -0.03462079167366028,
      -0.0708680972456932,
      -0.0037143754307180643,
      -0.13169080018997192,
      -0.01359301246702671,
      -0.0839393362402916,
      -0.023807477205991745,
      0.020104074850678444,
      -0.02387767657637596,
      -0.011220802552998066,
      -0.052156832069158554,
      -0.007661397568881512,
      -0.009782012552022934,
      -0.0014177020639181137,
      -0.05200422927737236,
      -0.0636860653758049,
      -0.02328324504196644,
      -0.05074717849493027,
      -0.03976186364889145,
      0.009708398953080177,
      -0.03558608889579773,
      -0.01962558925151825,
      0.054442938417196274,
      -0.04299146309494972,
      0.025321438908576965,
      0.007336812559515238,
      -0.035479262471199036,
      -0.07961560785770416,
      0.006533512845635414,
      -0.009242606349289417,
      0.03474273160099983,
      0.024994652718305588,
      -0.04314456507563591,
      -0.008638299070298672,
      -0.06473132222890854,
      -0.035698361694812775,
      0.024203747510910034,
      -0.10031071305274963,
      0.06966706365346909,
      -0.1024838387966156,
      0.05360358953475952,
      0.003027901751920581,
      -0.032525479793548584,
      0.011672157794237137,
      -0.03529959172010422,
      0.010504473000764847,
      -0.038825005292892456,
      -0.07235381752252579,
      -0.03239276632666588,
      0.060596171766519547,
      0.005575136747211218,
      -0.027444319799542427,
      -0.023980775848031044,
      -0.011849046684801579,
      -0.04300253465771675,
      0.07645251601934433,
      0.021816253662109375,
      0.03391806781291962,
      0.011539706960320473,
      -0.0394597053527832,
      0.043778374791145325,
      0.022647013887763023,
      -0.009949621744453907,
      0.046220820397138596,
      0.03710127994418144,
      0.026102328673005104,
      0.00828560721129179,
      0.02863527648150921,
      -0.05025653541088104,
      -0.041958998888731,
      -0.0395648330450058,
      0.0291832834482193,
      -0.05254889652132988,
      -0.030543852597475052,
      0.07773482799530029,
      -0.05488243326544762,
      0.038678597658872604,
      0.0028742121066898108,
      -0.019543932750821114,
      -0.02074144035577774,
      0.043228134512901306,
      -0.1062517911195755,
      0.017991187050938606,
      -0.0013351438101381063,
      0.02138718217611313,
      0.02049577236175537,
      0.08471143245697021,
      0.0018772979965433478,
      0.037427134811878204,
      0.0032462773378938437,
      0.04026041552424431,
      0.024543894454836845,
      -0.06762593239545822,
      0.025896618142724037,
      -0.032501738518476486,
      -0.07023066282272339,
      -0.000876960635650903,
      0.04006712883710861,
      -0.014855727553367615,
      0.06735177338123322,
      0.01809345930814743,
      0.014883852563798428,
      0.02045125886797905,
      -0.03983420133590698,
      -0.009875230491161346,
      -0.016693007200956345,
      -0.03987898677587509,
      0.10028489679098129,
      0.04193625971674919,
      -0.07094030827283859,
      0.014456094242632389,
      0.10458910465240479,
      -0.015706293284893036,
      -0.030913446098566055,
      0.06313467025756836,
      -0.05597130209207535,
      0.004715621471405029,
      -0.004367021843791008,
      -0.04565985128283501,
      -0.06591712683439255,
      0.028216656297445297,
      -0.0022793274838477373,
      0.014567415229976177,
      -0.08497487008571625,
      0.04489962011575699,
      -0.07509400695562363,
      0.07921915501356125,
      -0.030666910111904144,
      -0.008228246122598648,
      0.06436360627412796,
      -0.11768974363803864,
      0.023163849487900734,
      -0.0390859879553318,
      0.1019362136721611,
      -0.015168806537985802,
      -0.04506269842386246,
      -0.011706636287271976,
      -0.0114505086094141,
      -0.07180916517972946,
      -0.07333201915025711,
      -0.06330317258834839,
      0.05019933730363846,
      -0.029683422297239304,
      0.010240469127893448,
      0.009180143475532532,
      0.0300045944750309,
      0.027894271537661552,
      -0.08608799427747726,
      -0.07410363107919693,
      -0.037615224719047546,
      0.0689915269613266,
      0.03475654125213623,
      -0.03257771208882332,
      0.003694766666740179,
      -0.07567118853330612,
      -0.03235529363155365,
      0.05112103000283241,
      -0.03810501471161842,
      0.007493474520742893,
      0.10170309990644455,
      -0.05765804275870323,
      -0.07828284800052643,
      0.0019716217648237944,
      -0.10605031996965408,
      0.029477961361408234,
      0.00738109927624464,
      0.06042877957224846,
      0.003994490951299667,
      -0.019645266234874725,
      0.03239798918366432,
      -0.02109581045806408,
      -0.02583187259733677,
      -0.039266783744096756,
      0.005072740372270346,
      -0.04431729391217232,
      0.0212386604398489,
      0.025406640022993088,
      0.04163054749369621,
      0.023062393069267273,
      0.07515496760606766,
      -0.026132017374038696,
      0.005269509274512529,
      -0.07874889671802521
    ],
    [
      -0.007343675475567579,
      -0.102660171687603,
      0.05990532040596008,
      0.02316717989742756,
      0.02825235389173031,
      -0.06365957111120224,
      -0.13164576888084412,
      0.046211156994104385,
      -0.07132044434547424,
      0.003502907697111368,
      -0.07114063948392868,
      0.004648162517696619,
      -0.06060675531625748,
      -0.026406794786453247,
      0.07562382519245148,
      -0.021977579221129417,
      0.016105594113469124,
      -0.008026238530874252,
      -0.0691566914319992,
      -0.03185996785759926,
      -0.03480816259980202,
      0.13272742927074432,
      -0.006886407732963562,
      -0.002591000869870186,
      0.0082312086597085,
      -0.035211075097322464,
      -0.0698741227388382,
      -0.058216944336891174,
      0.0033893210347741842,
      0.0006073966505937278,
      0.002154936781153083,
      0.05063599348068237,
      -0.004494111053645611,
      0.017440825700759888,
      -0.05292598530650139,
      0.034412894397974014,
      -0.022319138050079346,
      0.060422588139772415,
      0.11142075806856155,
      0.056342996656894684,
      -0.015276908874511719,
      -0.002503461902961135,
      0.01771673560142517,
      0.009624429978430271,
      0.025477413088083267,
      0.06789243221282959,
      -0.009822441264986992,
      0.11007477343082428,
      0.03204409405589104,
      -0.0069803958758711815,
      -0.016006050631403923,
      0.0738205537199974,
      0.03911212086677551,
      0.006758458912372589,
      0.09124203026294708,
      -0.04292277991771698,
      -0.025852033868432045,
      -0.03416809067130089,
      0.01291421428322792,
      -0.019507352262735367,
      -0.032272521406412125,
      0.02703681029379368,
      0.006935638375580311,
      -0.04364073649048805,
      -0.07662949711084366,
      0.03674941509962082,
      0.003741583088412881,
      0.0247038621455431,
      -0.00037920562317594886,
      0.045285023748874664,
      -0.13062116503715515,
      0.04338163137435913,
      -0.014881901443004608,
      0.0272405706346035,
      0.0019147120183333755,
      0.021406831219792366,
      -0.006529655773192644,
      -0.07730527222156525,
      0.025131380185484886,
      0.03771338611841202,
      -0.016289345920085907,
      0.04287291318178177,
      -0.012210236862301826,
      0.02478436566889286,
      -0.03887530416250229,
      -0.010238725692033768,
      -0.01031950581818819,
      0.03313647210597992,
      0.011122049763798714,
      0.006546373013406992,
      -0.04019076004624367,
      0.027513297274708748,
      0.00629711989313364,
      -0.05489550903439522,
      -0.001975137507542968,
      -0.07807762920856476,
      -0.013103638775646687,
      -0.051401812583208084,
      0.06695978343486786,
      0.0424770750105381,
      0.030790701508522034,
      0.06883059442043304,
      0.0052733346819877625,
      0.0027689621783792973,
      0.06223054975271225,
      -0.013548016548156738,
      0.024061622098088264,
      -0.1128552109003067,
      0.025031527504324913,
      -0.05479268357157707,
      0.01943395286798477,
      -0.03535529598593712,
      -0.02571958303451538,
      -0.000159196337335743,
      -0.12880559265613556,
      0.07907272130250931,
      -0.03604143112897873,
      -0.14588043093681335,
      -0.02534627914428711,
      -0.01401448342949152,
      0.015134863555431366,
      -0.06206610053777695,
      -0.04862884804606438,
      0.021496281027793884,
      0.07236611098051071,
      0.08473147451877594,
      0.07740165293216705,
      0.011173994280397892,
      0.07580158114433289,
      0.02887953445315361,
      0.02513621747493744,
      0.0031988394912332296,
      -0.07963479310274124,
      0.014127801172435284,
      -0.030595144256949425,
      0.03424310311675072,
      0.014852110296487808,
      0.01502247340977192,
      -0.03316638991236687,
      0.02002120390534401,
      -0.035773955285549164,
      0.03933572769165039,
      0.05721396580338478,
      -0.050564855337142944,
      0.05810605734586716,
      -0.03832980990409851,
      0.004330669529736042,
      -0.01831941306591034,
      0.01845717988908291,
      -0.03632934391498566,
      -0.005886044353246689,
      -0.06308168917894363,
      0.004849048797041178,
      -0.006928786635398865,
      0.03801698982715607,
      -0.009359167888760567,
      -0.007508144713938236,
      0.03515759855508804,
      0.0027559008449316025,
      -0.015728754922747612,
      -0.02417858876287937,
      -0.02363557741045952,
      0.03863149136304855,
      0.08132295310497284,
      0.02337881550192833,
      -0.007221770938485861,
      -0.07692369818687439,
      0.005367260426282883,
      -0.018877029418945312,
      -0.04037398099899292,
      0.0258170273154974,
      -0.015252936631441116,
      0.05029754340648651,
      -0.030963607132434845,
      0.04145752266049385,
      -0.03999671712517738,
      0.07297167927026749,
      -0.045947201550006866,
      0.0037149805575609207,
      -0.09462609142065048,
      -0.0269999448210001,
      -0.035630907863378525,
      -0.02168332040309906,
      0.0514412559568882,
      -0.012613006867468357,
      -0.005724299233406782,
      0.02388964593410492,
      -0.015728548169136047,
      0.041367582976818085,
      -0.06643263250589371,
      0.02970248833298683,
      0.012433163821697235,
      0.0020750435069203377,
      0.055827654898166656,
      -0.05103946477174759,
      0.012729965150356293,
      0.0006920095183886588,
      -0.03958775848150253,
      -0.04210003837943077,
      -0.030354473739862442,
      0.12470535188913345,
      0.003793518990278244,
      0.036699362099170685,
      0.03716281056404114,
      0.08644407242536545,
      -0.12086894363164902,
      -0.05475651100277901,
      -0.024646900594234467,
      -0.028798434883356094,
      -0.10270745307207108,
      0.0035486991982907057,
      0.06723219156265259,
      -0.008881662040948868,
      0.010103771463036537,
      0.12763167917728424,
      0.02328936755657196,
      0.07268061488866806,
      -0.03447335213422775,
      -0.015625854954123497,
      -0.035414937883615494,
      -0.00033614280982874334,
      0.03252623230218887,
      0.010907543823122978,
      -0.021875344216823578,
      0.017660807818174362,
      -0.037540704011917114,
      -0.05091523379087448,
      0.03600538149476051,
      -0.07980377227067947,
      -0.028768034651875496,
      -0.020901771262288094,
      0.011960899457335472,
      -0.09677731245756149,
      0.015028633177280426,
      0.04053572937846184,
      0.03866010904312134,
      0.0258563794195652,
      0.00902842078357935,
      -0.05992133170366287,
      0.01937076821923256,
      -0.010551976040005684,
      -0.0015644681407138705,
      -0.018752794712781906,
      0.024287024512887,
      -0.03538098931312561,
      0.014360625296831131,
      0.02051006257534027,
      -0.08313135802745819,
      -0.025593111291527748,
      -0.0669415220618248,
      -0.028192462399601936,
      0.032040033489465714,
      -0.06555410474538803,
      -0.04200974479317665,
      0.013513605110347271,
      0.029735196381807327,
      -0.031103668734431267,
      0.08428864926099777,
      0.008171887136995792,
      -0.0004147077852394432,
      0.07239425927400589,
      0.053395092487335205,
      -0.053785648196935654,
      0.029011575505137444,
      -0.013071761466562748,
      -0.002268238691613078,
      0.026360424235463142,
      0.07343372702598572,
      0.016340352594852448,
      0.04626324772834778,
      0.016969840973615646,
      0.005889021325856447,
      -0.05985477939248085,
      0.005676961969584227,
      -0.030453873798251152,
      -0.03713701292872429,
      -0.05742408707737923,
      0.03130922466516495,
      -0.03791927173733711,
      -0.010852785781025887,
      0.027919095009565353,
      0.021690567955374718,
      -0.015941808000206947,
      -0.03235011547803879,
      -0.008514123037457466,
      -0.0664239376783371,
      -0.02847420983016491,
      -0.012280029244720936,
      0.05693356692790985,
      0.07923532277345657,
      0.0625322014093399,
      0.08360795676708221,
      -0.013466340489685535,
      0.039009083062410355,
      0.02651321515440941,
      -0.0964866355061531,
      0.028640182688832283,
      -0.021155916154384613,
      -0.08000382781028748,
      0.036190301179885864,
      -0.057808857411146164,
      -0.04388163611292839,
      0.026719288900494576,
      -0.1329340785741806,
      0.017320750281214714,
      -0.012718556448817253,
      -0.003566712373867631,
      0.053280897438526154,
      -0.010187209583818913,
      0.034335408359766006,
      0.017100200057029724,
      -0.04770687222480774,
      -0.018742701038718224,
      0.024114925414323807,
      -0.017110269516706467,
      -0.0007322567980736494,
      -0.07365294545888901,
      0.04750093072652817,
      0.0061099897138774395,
      -0.01967117004096508,
      0.03103388473391533,
      0.025234011933207512,
      -0.04192227125167847,
      -0.02882314659655094,
      -0.0004428081156220287,
      -0.004744712729007006,
      0.04960697144269943,
      -0.020919546484947205,
      0.004499480128288269,
      -0.024333974346518517,
      -0.03779870271682739,
      -0.017780950292944908,
      0.018084445968270302,
      -0.0226975679397583,
      0.045294344425201416,
      0.005702294874936342,
      -0.09567875415086746,
      -0.043622635304927826,
      -0.027631841599941254,
      0.014964025467634201,
      0.03165702521800995,
      -0.04370806738734245,
      -0.023882579058408737,
      -0.011968831531703472,
      0.03064248152077198,
      0.05327776074409485,
      -0.037730686366558075,
      0.02849423512816429,
      0.046525951474905014,
      -0.04047422111034393,
      0.027566485106945038,
      -0.026926780119538307,
      -0.034552086144685745,
      0.059148721396923065,
      0.06421732902526855,
      0.012780236080288887,
      -0.02706989459693432,
      0.05540282279253006,
      0.09053755551576614,
      -0.029271215200424194,
      -0.03408454731106758,
      0.010540038347244263,
      0.018278691917657852,
      -0.04805218055844307,
      0.04023870825767517,
      -0.04016546532511711,
      0.06914335489273071,
      0.06540917605161667,
      -0.06297343224287033,
      -0.049605682492256165,
      -0.010301491245627403,
      0.027052488178014755,
      -0.052422672510147095,
      -0.0318472757935524,
      0.03689458221197128,
      0.03291438892483711,
      0.05331636592745781,
      0.03830312192440033,
      -0.01297453511506319,
      -0.017771698534488678,
      0.04723711684346199,
      0.008316566236317158,
      -0.0064953225664794445,
      0.009632088243961334,
      -0.0014118785038590431,
      -0.036437347531318665,
      0.004922933876514435,
      -0.015867481008172035,
      -0.03492756932973862,
      0.040625303983688354,
      0.007526732049882412,
      0.01158285140991211,
      -0.022216638550162315,
      0.03367073833942413,
      -0.0017955696675926447,
      -0.035249266773462296,
      0.1126241385936737,
      -0.013843064196407795,
      -0.10202765464782715,
      0.009855924174189568,
      0.017366869375109673,
      -0.014521732926368713,
      0.02464642934501171,
      -0.07627198845148087,
      -0.011979421600699425,
      -0.06714724749326706,
      0.02348192408680916,
      0.015333699993789196,
      0.01471194438636303,
      0.04997815936803818,
      -0.033144913613796234,
      -0.007963404059410095,
      0.03636813908815384,
      0.005114728584885597,
      0.008370778523385525,
      0.006617112550884485,
      -0.0004802251060027629,
      -0.007104137446731329,
      -0.04208515211939812,
      -0.02255750074982643,
      0.048470739275217056,
      0.07805193960666656,
      -0.02018824964761734,
      0.030596815049648285,
      0.029185418039560318,
      0.010750006884336472,
      -0.0642395168542862,
      -0.05526193231344223,
      -0.002572252880781889,
      0.050713859498500824,
      0.02116210013628006,
      -0.0031106930691748857,
      0.005644266493618488,
      -0.026177145540714264,
      -0.006089890841394663,
      0.024933109059929848,
      0.007741855923086405,
      0.03897159919142723,
      0.093512624502182,
      -0.036621395498514175,
      -0.043726272881031036,
      0.008085635490715504,
      -0.03257720172405243,
      0.07623201608657837,
      0.05094076693058014,
      -0.00972755253314972,
      0.024559365585446358,
      0.04263550788164139,
      -0.004941548220813274,
      0.045744068920612335,
      0.0366121381521225,
      -0.03541260585188866,
      -0.08235993981361389,
      0.007624412886798382,
      -0.0360884889960289,
      -0.0367472842335701,
      0.014183919876813889,
      -0.029074857011437416,
      0.08030020445585251,
      -0.03532375767827034,
      -0.03778320178389549,
      0.02938585914671421,
      -0.01643325760960579,
      -0.06957367807626724,
      -0.01091804075986147,
      -0.0040572164580225945,
      0.03937697783112526,
      0.000269566779024899,
      -0.062215253710746765,
      -0.004882930777966976,
      -0.058259911835193634,
      -0.013229180127382278,
      -0.052169427275657654,
      -0.07304541021585464,
      -0.012009240686893463,
      0.0674811527132988,
      -0.008590096607804298,
      -0.0020600801799446344,
      0.017700625583529472,
      -0.0005976675893180072,
      0.08561688661575317,
      -0.025970499962568283,
      0.031231533735990524,
      0.0616019070148468,
      -0.029241541400551796,
      -0.05602593347430229,
      -0.04691428318619728,
      0.04296251758933067,
      -0.08307529240846634,
      0.038290977478027344,
      -0.008800802752375603,
      0.026136573404073715,
      -0.00300912419334054,
      0.04694646969437599,
      0.01118171215057373,
      0.01718328520655632,
      0.05759343504905701,
      0.0008694364805705845,
      -0.003949003294110298,
      0.028307326138019562,
      -0.0038707267958670855,
      -0.05851087346673012,
      0.12674853205680847
    ],
    [
      -0.05465638265013695,
      -0.052642032504081726,
      -0.02092968486249447,
      -0.02809857204556465,
      -0.036082372069358826,
      0.03456500917673111,
      0.012970258481800556,
      -0.09303472191095352,
      -0.09138767421245575,
      0.017268657684326172,
      -0.00023628764029126614,
      0.06299621611833572,
      -0.07066551595926285,
      0.016589028760790825,
      -0.0651271715760231,
      0.006282612681388855,
      -0.10872647911310196,
      0.012982902117073536,
      -0.026922382414340973,
      0.04301377758383751,
      -0.008922751061618328,
      0.05537170544266701,
      -0.04939897730946541,
      -0.10644791275262833,
      0.005848133470863104,
      0.07191048562526703,
      -0.004112946800887585,
      -0.05116854980587959,
      -0.03958506882190704,
      -0.0549793466925621,
      0.038256336003541946,
      -0.013374485075473785,
      0.012944666668772697,
      0.016469785943627357,
      0.00013008638052269816,
      0.006069107446819544,
      -0.0506887324154377,
      -0.0370924174785614,
      -0.014163006097078323,
      0.011921873316168785,
      -0.003932780586183071,
      -0.051496122032403946,
      0.02443668618798256,
      -0.021751098334789276,
      0.03278260678052902,
      0.03673049435019493,
      -0.034920282661914825,
      -0.08034263551235199,
      0.020053358748555183,
      -0.03985493257641792,
      -0.01930043287575245,
      -0.0194924995303154,
      0.024547627195715904,
      0.053090956062078476,
      0.02127995528280735,
      -0.02696911245584488,
      -0.01578918658196926,
      0.017830654978752136,
      -0.023901984095573425,
      -0.037598464637994766,
      -0.01763894595205784,
      -0.019964391365647316,
      0.01632051169872284,
      -0.040025338530540466,
      0.017010780051350594,
      -0.03984595090150833,
      -0.026326753199100494,
      0.007355635054409504,
      0.02188131958246231,
      0.02584151178598404,
      -0.017266223207116127,
      0.06329036504030228,
      -0.007848369888961315,
      0.04524069279432297,
      -0.034718189388513565,
      -0.0236086193472147,
      0.05849950760602951,
      -0.062430575489997864,
      -0.0006795107037760317,
      0.06790729612112045,
      0.02295692265033722,
      0.04949537292122841,
      0.008177384734153748,
      0.026905622333288193,
      -0.03978775441646576,
      -0.026107754558324814,
      -0.03431086987257004,
      0.008461292833089828,
      0.03949740156531334,
      -0.06053316593170166,
      -0.005489746108651161,
      0.014954156242311,
      0.041820209473371506,
      0.0038886412512511015,
      -0.029301773756742477,
      -0.0232534222304821,
      -0.022104142233729362,
      -0.06827326118946075,
      -0.04964908957481384,
      -0.000292229960905388,
      -0.03828682377934456,
      0.058929502964019775,
      0.012938388623297215,
      0.016666067764163017,
      0.015239452011883259,
      -0.011101583018898964,
      0.052770402282476425,
      0.04124392569065094,
      0.015541890636086464,
      0.03166447579860687,
      -0.021619059145450592,
      0.03455505892634392,
      0.05025020241737366,
      0.019919943064451218,
      -0.03956383839249611,
      -0.06499804556369781,
      0.01290201861411333,
      -0.04517912119626999,
      -0.0018415850354358554,
      0.03747190535068512,
      -0.025121642276644707,
      -0.0853237509727478,
      0.026934010908007622,
      0.005618954077363014,
      0.024155722931027412,
      0.030001824721693993,
      -0.04644326493144035,
      -0.07943853735923767,
      0.04157615825533867,
      -0.019736889749765396,
      0.022645821794867516,
      -0.01229222770780325,
      -0.05861404538154602,
      -0.008601000532507896,
      -0.03369826823472977,
      -0.00621931254863739,
      0.023695722222328186,
      0.004355259705334902,
      -0.044920679181814194,
      -0.06997905671596527,
      0.012645415961742401,
      -0.05415394902229309,
      0.02508021891117096,
      0.001292353612370789,
      0.027902599424123764,
      -0.08221492916345596,
      0.02529860846698284,
      0.044816888868808746,
      0.03356022760272026,
      0.016527505591511726,
      0.03270160034298897,
      0.028069140389561653,
      0.05426907166838646,
      0.03330925479531288,
      -0.02612549439072609,
      0.04273095726966858,
      0.02292049303650856,
      0.0009566882508806884,
      -0.04385772719979286,
      0.04358460009098053,
      0.024752963334321976,
      -0.0002484384458512068,
      0.02185533195734024,
      -0.02269522100687027,
      -0.010596978478133678,
      -0.05838089808821678,
      -0.012194103561341763,
      -0.04022201523184776,
      -0.009719202294945717,
      -0.02481735125184059,
      0.023905690759420395,
      -0.039077937602996826,
      0.0012153424322605133,
      -0.031860850751399994,
      -0.014018841087818146,
      -0.010742786340415478,
      0.03111415170133114,
      -0.02170255221426487,
      -0.024042688310146332,
      0.04663873463869095,
      0.04422599822282791,
      -0.0017881273524835706,
      -0.031820524483919144,
      -0.00898909755051136,
      -0.043499719351530075,
      -0.011540918610990047,
      0.03336946666240692,
      -0.017047809436917305,
      -0.06469643115997314,
      0.039683081209659576,
      -0.01571616530418396,
      0.03609100356698036,
      -0.006678260397166014,
      0.006643381901085377,
      -0.04756774753332138,
      0.08624496310949326,
      0.06310445815324783,
      -0.05335468053817749,
      0.024032484740018845,
      0.10902006179094315,
      -0.13840554654598236,
      -0.01337774284183979,
      0.016728904098272324,
      0.06432123482227325,
      0.026592189446091652,
      -0.016546480357646942,
      -0.08653057366609573,
      -0.06183230131864548,
      0.05074315890669823,
      -0.07805801182985306,
      -0.015324512496590614,
      -0.03670138493180275,
      -0.06473498791456223,
      0.015683449804782867,
      -0.011138122528791428,
      0.019217435270547867,
      -0.07517118752002716,
      0.02177775464951992,
      -0.02788528986275196,
      -0.029796946793794632,
      -0.002272718818858266,
      0.02469889260828495,
      0.03000796213746071,
      0.04461432248353958,
      -0.025129269808530807,
      -0.04552241787314415,
      -0.09700725972652435,
      0.06512952595949173,
      -0.0396755151450634,
      0.009059359319508076,
      0.005363176576793194,
      -0.015683505684137344,
      0.0719219297170639,
      -0.001737649436108768,
      -0.009170125238597393,
      0.016692496836185455,
      -0.0014863908290863037,
      -0.04383786395192146,
      -0.061248812824487686,
      0.04358065500855446,
      0.018620675429701805,
      0.01869860850274563,
      -0.04331823065876961,
      0.0570337176322937,
      0.0579502135515213,
      0.05208613723516464,
      0.025325149297714233,
      -0.037484895437955856,
      0.026823777705430984,
      0.06501927226781845,
      0.06765852868556976,
      -0.04967665299773216,
      0.04532955586910248,
      -0.03237463906407356,
      0.008582940325140953,
      0.03380309045314789,
      -0.014071347191929817,
      -0.01837410405278206,
      0.02100515551865101,
      -0.025836223736405373,
      0.02909635193645954,
      -0.00029921747045591474,
      0.0011258089216426015,
      -0.04157046973705292,
      -0.08686267584562302,
      -0.012269015423953533,
      -0.05613131821155548,
      0.03488897904753685,
      0.0053939358331263065,
      0.01109606958925724,
      0.05820859223604202,
      -0.08182170987129211,
      0.049007438123226166,
      -0.004416975192725658,
      -0.022378996014595032,
      -0.12808239459991455,
      -0.0005567197222262621,
      0.040418218821287155,
      -0.017312517389655113,
      0.09650018811225891,
      0.059065427631139755,
      -0.04711092635989189,
      -0.1128850057721138,
      0.06305182725191116,
      -0.05308815464377403,
      0.007809815928339958,
      -0.03726278617978096,
      -0.011962383054196835,
      0.03667007386684418,
      0.02381087653338909,
      -0.05197606980800629,
      0.017483051866292953,
      0.024267269298434258,
      -0.008765426464378834,
      -0.027921248227357864,
      -0.04484307020902634,
      0.0056433542631566525,
      0.03515816107392311,
      -0.02954462356865406,
      -0.01674019917845726,
      -0.05813309922814369,
      -0.017083734273910522,
      0.02775733359158039,
      -0.03305824100971222,
      0.1944890171289444,
      0.03703571856021881,
      -0.05672171711921692,
      0.03810334950685501,
      0.009744442999362946,
      -0.038466982543468475,
      -0.033415235579013824,
      0.06614641100168228,
      -0.03403385356068611,
      0.08696777373552322,
      0.010957855731248856,
      -0.042540472000837326,
      -0.025877876207232475,
      -0.008329248055815697,
      0.05350193381309509,
      0.017585204914212227,
      -0.025068147107958794,
      -0.022253312170505524,
      0.032827265560626984,
      -0.03572748973965645,
      -0.0330367237329483,
      -0.007026562001556158,
      -0.015950407832860947,
      -0.005584582220762968,
      -0.04094266891479492,
      0.013861785642802715,
      -0.011413251981139183,
      -0.007219500374048948,
      -0.01851803809404373,
      -0.026074543595314026,
      -0.028834640979766846,
      0.06705240905284882,
      -0.003912653308361769,
      -0.0299641415476799,
      0.043463084846735,
      0.03334495797753334,
      0.04665127024054527,
      -0.04318318888545036,
      0.028318414464592934,
      0.031124817207455635,
      0.06583648175001144,
      -0.042646024376153946,
      0.008491341955959797,
      -0.008517753332853317,
      -0.004909710958600044,
      -0.06801310926675797,
      -0.12795329093933105,
      0.053394559770822525,
      -0.06974319368600845,
      -0.03794558718800545,
      -0.031494900584220886,
      -0.050621870905160904,
      0.02990022860467434,
      -0.08217091858386993,
      -0.01386239007115364,
      0.0091156717389822,
      -0.053565725684165955,
      -0.07130826264619827,
      0.019326116889715195,
      0.01826944388449192,
      -0.03309840336441994,
      0.03541650250554085,
      0.07672237604856491,
      -0.017361372709274292,
      0.08356265723705292,
      -0.029198499396443367,
      -0.017960870638489723,
      -0.03727354481816292,
      0.008116924203932285,
      0.023557024076581,
      -0.015469775535166264,
      0.045191168785095215,
      -0.0351099967956543,
      0.014640064910054207,
      0.05064784735441208,
      -0.026789654046297073,
      0.05650908127427101,
      0.03193438798189163,
      0.04364924505352974,
      -0.040759358555078506,
      -0.014143363572657108,
      -0.020766668021678925,
      -0.0009398127440363169,
      -0.01978417858481407,
      -0.04202385991811752,
      0.11790213733911514,
      0.014500199817121029,
      -0.007222193758934736,
      0.04276443272829056,
      0.10710448026657104,
      -0.015509462915360928,
      0.045922618359327316,
      -0.037296589463949203,
      -0.010820945724844933,
      0.01004569698125124,
      -0.0031827264465391636,
      0.011213339865207672,
      -0.03312664106488228,
      0.0557863675057888,
      0.013965977355837822,
      -0.04551107436418533,
      0.009736878797411919,
      -0.0004030996060464531,
      0.026753876358270645,
      -0.006241864990442991,
      -0.0346989780664444,
      -0.02557210437953472,
      -0.017877433449029922,
      -0.10426106303930283,
      -0.02601540833711624,
      0.04998578876256943,
      0.005211238749325275,
      0.006326962728053331,
      0.009381539188325405,
      0.056996773928403854,
      0.04887891560792923,
      0.06567926704883575,
      0.04869386553764343,
      0.030756289139389992,
      0.043055154383182526,
      0.023755177855491638,
      0.041826389729976654,
      0.021038122475147247,
      -0.020942017436027527,
      -0.027790596708655357,
      0.006447478197515011,
      -0.0696391612291336,
      -0.032991837710142136,
      -0.054427869617938995,
      -0.006631351076066494,
      0.011102155782282352,
      -0.05025700479745865,
      -0.037982724606990814,
      -0.048408761620521545,
      -0.09279664605855942,
      0.014583027921617031,
      -0.08190062642097473,
      -0.036893896758556366,
      -0.057674843817949295,
      0.018357187509536743,
      -0.02549968659877777,
      -0.02867739647626877,
      -0.040940478444099426,
      0.02224581316113472,
      0.013307824730873108,
      0.06189007684588432,
      0.01281706802546978,
      0.02337682619690895,
      0.04528701677918434,
      0.08708703517913818,
      0.00979653187096119,
      -0.07550321519374847,
      -0.011771373450756073,
      0.019297700375318527,
      0.03194139897823334,
      -0.03206900879740715,
      -0.015795106068253517,
      0.047074802219867706,
      -0.0032721562311053276,
      -0.025344468653202057,
      -0.06741713732481003,
      0.006460662931203842,
      -0.037197645753622055,
      0.028888266533613205,
      -0.07450932264328003,
      -0.01325405202805996,
      0.04370079189538956,
      -0.06869775056838989,
      0.03518635407090187,
      0.01709148660302162,
      0.005637960974127054,
      0.018702592700719833,
      0.005257821641862392,
      -0.03553725779056549,
      0.019859524443745613,
      0.1404646337032318,
      0.028782200068235397,
      -0.010834470391273499,
      -0.017910059541463852,
      -0.009951290674507618,
      -0.06427349895238876,
      -0.02361103892326355,
      0.0033637904562056065,
      -0.01964580826461315,
      -0.09630687534809113,
      -0.042814526706933975,
      -0.05713237449526787,
      -0.046831414103507996,
      0.036712031811475754,
      0.009928300976753235,
      -0.03536645695567131,
      0.005291172303259373,
      -0.008805636316537857,
      0.011345604434609413,
      0.003753893543034792,
      0.03597231209278107,
      -0.01339635532349348,
      -0.04328267276287079,
      0.029612092301249504
    ],
    [
      -0.0034770648926496506,
      -0.0322706364095211,
      -0.041829563677310944,
      0.006858402397483587,
      0.051172658801078796,
      0.03577223792672157,
      -0.05077337101101875,
      -0.02946830727159977,
      -0.06699375808238983,
      -0.0028996423352509737,
      0.008181658573448658,
      0.04288811236619949,
      2.245293762825895e-05,
      0.013988841325044632,
      0.046724408864974976,
      0.003407703712582588,
      -0.006002508569508791,
      -0.006318138912320137,
      0.017263704910874367,
      -0.02610069513320923,
      0.13332371413707733,
      -0.05046605318784714,
      -0.054876744747161865,
      0.02365780994296074,
      0.06875143200159073,
      0.05217614397406578,
      -0.017306847497820854,
      -0.052082210779190063,
      0.025867506861686707,
      -0.05539640411734581,
      0.04820866882801056,
      0.06913870573043823,
      0.09809235483407974,
      0.03259696811437607,
      -0.002294506411999464,
      -0.04696286842226982,
      0.05638391152024269,
      -0.02846488729119301,
      0.0037502734921872616,
      -0.024098457768559456,
      0.016600627452135086,
      0.007222606800496578,
      0.039872292429208755,
      -0.014018265530467033,
      -0.011223144829273224,
      -0.03999708592891693,
      -0.02016090229153633,
      0.07651198655366898,
      -0.05731521174311638,
      -0.03824401646852493,
      -0.010363690555095673,
      0.015542818233370781,
      0.061560939997434616,
      -0.02706698141992092,
      -0.02641509845852852,
      -0.0590866282582283,
      0.04892843961715698,
      0.02300070784986019,
      -0.05622120574116707,
      0.026945237070322037,
      -0.005823392886668444,
      0.020364422351121902,
      -0.08161687105894089,
      -0.04077726602554321,
      -0.08030782639980316,
      0.004851417150348425,
      0.04482603445649147,
      -0.08493252098560333,
      0.038185954093933105,
      0.007228217553347349,
      0.07009687274694443,
      0.06194537878036499,
      -0.03664708882570267,
      -0.03320682793855667,
      0.04829490929841995,
      0.007737541105598211,
      -0.010203150101006031,
      -0.0022814408876001835,
      0.030020248144865036,
      0.02893787808716297,
      -0.024048125371336937,
      0.03761634975671768,
      -0.06380072236061096,
      0.0035472004674375057,
      -0.02538166008889675,
      -0.01923925243318081,
      -0.027571221813559532,
      -0.018936429172754288,
      0.027831414714455605,
      -0.05473852530121803,
      -0.011595310643315315,
      0.05781356990337372,
      -0.079808309674263,
      0.0615997239947319,
      0.07376988232135773,
      -0.010199733078479767,
      0.030382445082068443,
      0.02121710404753685,
      -0.03269548341631889,
      -0.00898501556366682,
      0.03237459808588028,
      -0.041042059659957886,
      0.029522433876991272,
      0.057535573840141296,
      -0.057500649243593216,
      -0.007633383385837078,
      -0.048817723989486694,
      0.02556152455508709,
      -0.04251175746321678,
      -0.08887004107236862,
      0.013365502469241619,
      0.05469513684511185,
      0.007012366782873869,
      -0.08985377103090286,
      -0.0013410646934062243,
      0.037719909101724625,
      -0.0032073580659925938,
      -0.11203843355178833,
      -0.058131687343120575,
      -0.018913157284259796,
      -0.03826975077390671,
      0.06463361531496048,
      0.010548654943704605,
      -0.05515078082680702,
      -0.04626832157373428,
      0.06488343328237534,
      0.009807652793824673,
      -0.021288258954882622,
      -0.007168517913669348,
      -0.026184866204857826,
      0.005056765861809254,
      0.05830725282430649,
      0.033112477511167526,
      0.06642713397741318,
      0.007530525792390108,
      0.014634705148637295,
      -0.019768444821238518,
      -0.009647417813539505,
      -0.03830994293093681,
      -0.0607646107673645,
      0.016645481809973717,
      0.16616684198379517,
      -0.018403613939881325,
      -0.059402309358119965,
      -0.019595937803387642,
      -0.020973144099116325,
      -0.012852316722273827,
      -0.03506646677851677,
      -0.06795744597911835,
      -0.0745302215218544,
      0.02054912969470024,
      -0.059767838567495346,
      -0.01424325816333294,
      0.04469747096300125,
      -0.0326436422765255,
      -0.05277905985713005,
      -0.04278841242194176,
      0.013538717292249203,
      0.04605110362172127,
      -0.02174677699804306,
      -0.042414478957653046,
      -0.011463217437267303,
      -0.02377414144575596,
      0.015849292278289795,
      0.041704583913087845,
      -0.04894362762570381,
      -0.10762697458267212,
      0.03491703420877457,
      -0.001299769734032452,
      0.012242347002029419,
      0.05735139921307564,
      0.037412192672491074,
      -0.08312322199344635,
      -0.026809118688106537,
      0.000300042302114889,
      -0.03676508367061615,
      0.04337078332901001,
      -0.0334659069776535,
      0.010960581712424755,
      0.019237980246543884,
      0.01032414473593235,
      -0.03847478702664375,
      0.054226942360401154,
      0.0016395940911024809,
      -0.07515694200992584,
      -0.009767074137926102,
      0.03955274820327759,
      -0.011322184465825558,
      -0.0635569617152214,
      -0.032170843333005905,
      0.04480045288801193,
      0.015569483861327171,
      0.007062546908855438,
      0.023286612704396248,
      -0.07626499980688095,
      0.030019143596291542,
      0.09408634901046753,
      -0.02417999505996704,
      0.020901186391711235,
      0.03420858830213547,
      -0.016923869028687477,
      -0.05249044671654701,
      -0.02283415198326111,
      -0.04544248431921005,
      0.008958537131547928,
      -0.0035421778447926044,
      -0.01276242546737194,
      0.050041865557432175,
      0.010581651702523232,
      -0.042852021753787994,
      -0.03622221574187279,
      -0.0020778838079422712,
      -0.13536299765110016,
      0.04216063767671585,
      -0.041223734617233276,
      -0.06767939776182175,
      -0.0907331183552742,
      -0.03894444555044174,
      0.010596106760203838,
      0.012576382607221603,
      -0.011004291474819183,
      0.12723664939403534,
      -0.013860204257071018,
      -0.06185237318277359,
      0.018296435475349426,
      -0.1273590326309204,
      -0.012974508106708527,
      -0.040626391768455505,
      0.015159462578594685,
      -0.11386673152446747,
      -0.01749233715236187,
      0.09346729516983032,
      -0.015394010581076145,
      -0.03399123251438141,
      0.03214571252465248,
      0.045193497091531754,
      -0.03383759409189224,
      -0.02469840832054615,
      -0.05589276924729347,
      -0.017982563003897667,
      -0.04934187978506088,
      0.017816465348005295,
      -0.05276713892817497,
      1.005784361041151e-05,
      0.047974731773138046,
      0.03274323791265488,
      -0.039855122566223145,
      -0.020299674943089485,
      -0.04262084513902664,
      0.02881406992673874,
      -0.05235448107123375,
      -0.0011218346189707518,
      -0.07595356553792953,
      -0.04394043982028961,
      -0.008190395310521126,
      0.05741243064403534,
      -0.05722251161932945,
      0.009650049731135368,
      -0.0765622928738594,
      0.05140756443142891,
      -0.03662215545773506,
      -0.017362255603075027,
      0.02692064456641674,
      0.09846772253513336,
      -0.002152343513444066,
      0.03993552550673485,
      -0.0016472246497869492,
      0.005138514097779989,
      0.026491679251194,
      -0.0012447452172636986,
      0.006432205438613892,
      0.03845881670713425,
      -0.017892366275191307,
      -0.0386640764772892,
      -0.012995952740311623,
      0.013183725997805595,
      0.03217627480626106,
      0.026293888688087463,
      -0.09598128497600555,
      -0.06547991186380386,
      -0.04133401811122894,
      0.010487626306712627,
      0.0025522862561047077,
      0.015040596015751362,
      -0.031889695674180984,
      0.04975763335824013,
      0.017103152349591255,
      0.010976615361869335,
      0.007400576490908861,
      -0.060015253722667694,
      0.08519892394542694,
      -0.0010012424318119884,
      -0.04189268499612808,
      0.00626001413911581,
      0.045071668922901154,
      -0.016950370743870735,
      -0.07193764299154282,
      0.10487963259220123,
      0.08474040031433105,
      -0.03510092571377754,
      0.03884264454245567,
      -0.018275165930390358,
      -0.05561782792210579,
      -0.03932635858654976,
      0.041428595781326294,
      -0.03757057338953018,
      -0.001244233688339591,
      -0.050192564725875854,
      0.07238702476024628,
      0.049378350377082825,
      -0.003273446811363101,
      -0.043232474476099014,
      -0.04833732172846794,
      -0.05001198872923851,
      -0.04602058231830597,
      -0.017816893756389618,
      -0.026553671807050705,
      0.02256873808801174,
      -0.00479908287525177,
      0.05733604356646538,
      0.051436133682727814,
      -0.0993259847164154,
      0.004582144320011139,
      -0.07473606616258621,
      -0.04523196816444397,
      0.015984557569026947,
      -0.04674888402223587,
      0.024207500740885735,
      0.037943124771118164,
      -0.06633879989385605,
      -0.011922989040613174,
      -0.04487616568803787,
      -0.011146213859319687,
      0.04778742045164108,
      -0.008077018894255161,
      -0.02202683687210083,
      -0.0002277023741044104,
      0.009671845473349094,
      0.04845746234059334,
      0.001960709225386381,
      0.02015613578259945,
      0.011969015933573246,
      0.054510585963726044,
      -0.008800535462796688,
      -0.10366848111152649,
      -0.08854848146438599,
      -0.01249063853174448,
      -0.06217273324728012,
      -0.02572333626449108,
      0.07088173180818558,
      0.004061927553266287,
      0.09350503981113434,
      -0.019759055227041245,
      0.02126058004796505,
      0.01958508975803852,
      -0.06134141609072685,
      -0.0019134115427732468,
      -0.040819790214300156,
      0.01567486859858036,
      -0.00616081478074193,
      0.04723384976387024,
      -0.017237503081560135,
      -0.04221051558852196,
      0.0877254456281662,
      0.008779766038060188,
      0.008197685703635216,
      -0.03551601618528366,
      -0.016073141247034073,
      -0.012245496734976768,
      -0.0477721132338047,
      -0.016149038448929787,
      0.06470675766468048,
      -0.005449088755995035,
      -0.05688215419650078,
      0.03145672380924225,
      -0.05411656200885773,
      0.030283289030194283,
      0.0431583896279335,
      0.0469527393579483,
      -0.03946886211633682,
      0.03497219830751419,
      -0.024073893204331398,
      0.020908383652567863,
      0.03222166374325752,
      0.04502751678228378,
      0.06513254344463348,
      -0.013965124264359474,
      0.03524232655763626,
      -0.021677805110812187,
      0.0944787859916687,
      -0.008885226212441921,
      -0.015628181397914886,
      0.008312090300023556,
      -0.03652596101164818,
      0.027741307392716408,
      0.039706967771053314,
      0.045313142240047455,
      0.015129472129046917,
      0.0203788373619318,
      0.023642443120479584,
      0.08664140105247498,
      0.011948121711611748,
      -0.003936748951673508,
      -0.04906876012682915,
      -0.07502622157335281,
      -0.0653122290968895,
      0.10769154131412506,
      0.01027851365506649,
      -0.022642649710178375,
      0.023267457261681557,
      -0.05526217073202133,
      0.06199429929256439,
      0.011676589027047157,
      -0.0328730084002018,
      -0.0027460004203021526,
      -0.051858533173799515,
      0.017089132219552994,
      0.003424735041335225,
      -0.0009823815198615193,
      0.08274306356906891,
      -0.08285677433013916,
      -0.004344897344708443,
      0.06603357195854187,
      0.012058254331350327,
      0.016943564638495445,
      0.07248163223266602,
      0.05037682503461838,
      -0.02313368208706379,
      0.030162665992975235,
      0.016770917922258377,
      -0.014344846829771996,
      0.03577853739261627,
      -0.001644020900130272,
      -0.04120967909693718,
      0.015979992225766182,
      0.06268620491027832,
      0.04746696725487709,
      0.04599869251251221,
      -0.012351940385997295,
      -0.09348796308040619,
      -0.008664741180837154,
      0.025258153676986694,
      0.06522632390260696,
      0.05596860870718956,
      -0.04690457880496979,
      0.017658520489931107,
      -0.03795262426137924,
      -0.11190091818571091,
      -0.020601239055395126,
      -0.08913621306419373,
      -0.07626422494649887,
      0.026706840842962265,
      -0.07505779713392258,
      0.0111172404140234,
      -0.05427585169672966,
      -0.013291524723172188,
      -0.002193222986534238,
      -0.08009249716997147,
      0.045847855508327484,
      0.039878714829683304,
      0.0291918758302927,
      -0.006479684263467789,
      -0.042965617030858994,
      0.01523033156991005,
      0.017331790179014206,
      0.01224077120423317,
      -0.02610488049685955,
      -0.0648287907242775,
      -0.07965914160013199,
      0.009144500829279423,
      0.016326695680618286,
      -0.012424635700881481,
      -0.03640959784388542,
      -0.022228123620152473,
      0.04404454678297043,
      0.04755444452166557,
      0.010867888107895851,
      -0.08349773287773132,
      -0.01666688360273838,
      0.04041649028658867,
      -0.09491290152072906,
      -0.03686447814106941,
      -0.047939978539943695,
      -0.02094190940260887,
      -0.03099960647523403,
      -0.0031679761596024036,
      0.06476293504238129,
      -0.004473681561648846,
      -0.0030808926094323397,
      0.03621117025613785,
      0.05999694764614105,
      0.09559149295091629,
      0.052387598901987076,
      -0.0007220885017886758,
      0.012352128513157368,
      -0.018708795309066772,
      0.031850893050432205,
      0.02570444531738758,
      0.030594777315855026,
      0.01828637346625328,
      0.03992878273129463,
      -0.005033332388848066,
      0.08661127090454102
    ],
    [
      0.07750504463911057,
      0.10262805968523026,
      0.007048174738883972,
      0.05138476938009262,
      -0.037721965461969376,
      -0.0027690043207257986,
      0.03208513185381889,
      0.022266674786806107,
      -0.011394002474844456,
      -0.03592547029256821,
      0.10020088404417038,
      0.1028345599770546,
      -0.04350966587662697,
      0.012253150343894958,
      -0.04748688265681267,
      -0.004429452121257782,
      -0.009032591246068478,
      -0.033447641879320145,
      0.00938389077782631,
      -0.08596251904964447,
      -0.032344672828912735,
      0.03325355052947998,
      0.05046487972140312,
      0.059091560542583466,
      0.08242028951644897,
      -0.08052774518728256,
      -0.0136796273291111,
      -0.06340247392654419,
      -0.049123577773571014,
      -0.036183636635541916,
      -0.10468520224094391,
      -0.015706120058894157,
      -0.017279472202062607,
      0.03135302662849426,
      -0.01475302129983902,
      0.014916585758328438,
      -0.08051101863384247,
      0.013356432318687439,
      0.04189075157046318,
      0.023882359266281128,
      0.05737780034542084,
      0.014655373059213161,
      -0.035425666719675064,
      0.07406245917081833,
      -0.05459179729223251,
      0.026824405416846275,
      0.020558666437864304,
      0.027197817340493202,
      0.012957662343978882,
      -0.013531194999814034,
      -0.019330276176333427,
      0.042484309524297714,
      0.06915422528982162,
      -0.002944313222542405,
      0.010440178215503693,
      -0.05470987781882286,
      0.11612391471862793,
      -0.08795791119337082,
      -0.06448576599359512,
      -0.011504310183227062,
      -0.032895777374506,
      -0.06705712527036667,
      -0.049255672842264175,
      -0.0209515318274498,
      8.478043309878558e-05,
      -0.009693717584013939,
      0.05274690315127373,
      -0.022477854043245316,
      -0.014405082911252975,
      0.009268086403608322,
      0.0410812608897686,
      -0.07523933053016663,
      0.014154908247292042,
      -0.0013661514967679977,
      -0.07364781200885773,
      0.02349214255809784,
      0.04362987354397774,
      -0.006647038273513317,
      -0.022831877693533897,
      -5.0763715989887714e-05,
      0.024430885910987854,
      0.014348339289426804,
      0.011232773773372173,
      0.04190484434366226,
      0.05036906525492668,
      -0.013382160104811192,
      -0.029385976493358612,
      0.04911554977297783,
      -0.029855797067284584,
      0.003316958900541067,
      0.07354830950498581,
      0.012735418975353241,
      0.095952108502388,
      -0.02440793253481388,
      0.017065808176994324,
      -0.11658751219511032,
      0.08540499210357666,
      -0.019779453054070473,
      0.00845442432910204,
      0.018130352720618248,
      0.07916147261857986,
      0.03506898134946823,
      0.012554478831589222,
      0.01704053021967411,
      -0.036376405507326126,
      -0.0006264003459364176,
      0.007682668976485729,
      0.008840124122798443,
      -0.009847013279795647,
      -0.031959615647792816,
      0.030647480860352516,
      0.04537332057952881,
      0.07151302695274353,
      -0.08824914693832397,
      0.02779451012611389,
      -0.04742497205734253,
      0.00069275131681934,
      0.03343040123581886,
      -0.07863931357860565,
      0.012298354879021645,
      -0.015633169561624527,
      0.005958442110568285,
      -0.05930883437395096,
      0.005502041429281235,
      0.01075178012251854,
      0.002736159833148122,
      0.10007227957248688,
      -0.020097723230719566,
      0.03829014301300049,
      0.016017984598875046,
      -0.04363309592008591,
      0.0581291988492012,
      -0.05785127729177475,
      -0.00021365420252550393,
      0.038397401571273804,
      0.022900495678186417,
      0.04784544184803963,
      -0.03578135743737221,
      -0.032643575221300125,
      0.06892350316047668,
      -0.039688590914011,
      0.041271794587373734,
      0.06322501599788666,
      -0.03759123012423515,
      0.0162117388099432,
      0.03827780485153198,
      0.04434370622038841,
      0.0010068415431305766,
      0.02736353501677513,
      -0.05864241346716881,
      -0.03942353278398514,
      0.014494314789772034,
      0.008597924374043941,
      -0.012666091322898865,
      -0.040220215916633606,
      -0.049364615231752396,
      0.00363707821816206,
      -0.02968318946659565,
      0.011289792135357857,
      -0.015339563600718975,
      0.04371516406536102,
      -0.052022479474544525,
      -0.0047561246901750565,
      0.07417195290327072,
      0.06462505459785461,
      -0.041001096367836,
      -0.03652150183916092,
      -0.05135273188352585,
      0.036280106753110886,
      -0.04126191884279251,
      0.040592730045318604,
      0.057331379503011703,
      -0.009122619405388832,
      0.059927377849817276,
      -0.06556598097085953,
      0.004092380870133638,
      -0.055200088769197464,
      -0.052283432334661484,
      0.051131460815668106,
      -0.024102721363306046,
      0.012586577795445919,
      -0.004292738623917103,
      0.0230392012745142,
      -0.044292207807302475,
      0.061162061989307404,
      -0.010372728109359741,
      0.03163046017289162,
      -0.004709606524556875,
      0.007784984074532986,
      0.062270548194646835,
      -0.005835461895912886,
      -0.05660748854279518,
      0.0546567365527153,
      0.02216530404984951,
      0.02371281199157238,
      -0.02041773311793804,
      0.009701288305222988,
      -0.02680310606956482,
      -0.043126460164785385,
      0.05385121703147888,
      0.004751150496304035,
      0.000279693107586354,
      0.00686677685007453,
      -0.0005597189883701503,
      0.01398998312652111,
      -0.005356139969080687,
      -0.15519292652606964,
      -0.037451352924108505,
      -0.016340766102075577,
      -0.010721197351813316,
      0.012044338509440422,
      0.07054731249809265,
      -0.05621135234832764,
      0.01580962724983692,
      -0.03596743941307068,
      0.020866645500063896,
      0.010017217136919498,
      -0.001701782108284533,
      -0.045437827706336975,
      0.015493069775402546,
      0.0012696475023403764,
      0.0558541901409626,
      0.0059204380959272385,
      0.05378187820315361,
      0.007427415810525417,
      0.0062758345156908035,
      0.008034221827983856,
      0.030368445441126823,
      -0.017913535237312317,
      0.08036445826292038,
      -0.036910876631736755,
      0.014692273922264576,
      0.013589161448180676,
      0.050910983234643936,
      -0.013670075684785843,
      -0.018733466044068336,
      0.016834063455462456,
      0.01793421246111393,
      0.002300987020134926,
      -0.031258076429367065,
      -0.0400015264749527,
      -0.01437300629913807,
      -0.0025589389260858297,
      -0.03839089348912239,
      0.02711496129631996,
      0.011561543680727482,
      -0.0640447735786438,
      0.023054707795381546,
      0.03552660718560219,
      0.06557425111532211,
      -0.025739476084709167,
      0.01796831004321575,
      -0.02515924721956253,
      0.005681466311216354,
      0.028402987867593765,
      -0.02076849341392517,
      0.05018458142876625,
      0.006329360883682966,
      -0.1069827452301979,
      0.1166476160287857,
      -0.02111358381807804,
      -0.016748785972595215,
      0.0852513536810875,
      -0.004018676467239857,
      -0.0074843429028987885,
      -0.011430696584284306,
      0.025691529735922813,
      -0.040617864578962326,
      -0.09793159365653992,
      0.016005663201212883,
      0.042873166501522064,
      -0.0108881751075387,
      0.061734654009342194,
      0.01596835069358349,
      -0.014608832076191902,
      0.05205608159303665,
      0.007448993157595396,
      0.1390814334154129,
      -0.05261938273906708,
      0.02922176942229271,
      0.0033980198204517365,
      0.07756267488002777,
      0.010467088781297207,
      0.0441182516515255,
      0.015783334150910378,
      -0.013732431456446648,
      -0.027245959267020226,
      0.006608124356716871,
      0.02061980962753296,
      -0.02846016176044941,
      -0.03388267755508423,
      0.0227151270955801,
      0.024655094370245934,
      0.14185932278633118,
      -0.03446786105632782,
      -0.007033850532025099,
      0.00717485137283802,
      0.04005244001746178,
      -0.06358908861875534,
      -0.05698985978960991,
      0.04445776343345642,
      0.056115809828042984,
      0.027379728853702545,
      -0.001757647143676877,
      0.019958872348070145,
      -0.004289480857551098,
      0.03768813982605934,
      -0.027940616011619568,
      0.013519449159502983,
      -0.009153684601187706,
      0.007612251210957766,
      -0.03934057429432869,
      -0.06436950713396072,
      0.023979535326361656,
      -0.07557196170091629,
      -0.06602983921766281,
      -0.043059494346380234,
      0.05566881597042084,
      0.018140362575650215,
      -0.001597283175215125,
      -0.0016045025549829006,
      -0.07596888393163681,
      0.061674851924180984,
      -0.05440102517604828,
      0.022417018190026283,
      0.009100350551307201,
      -0.019659865647554398,
      -0.07707634568214417,
      -0.0030140995513647795,
      -0.03770410642027855,
      0.009336143732070923,
      0.022880880162119865,
      0.03449274227023125,
      -0.024321705102920532,
      0.020765874534845352,
      0.02553604543209076,
      0.037883859127759933,
      -0.047899555414915085,
      -0.024885885417461395,
      -0.006432246416807175,
      0.0314335860311985,
      -0.00824460107833147,
      0.01506421435624361,
      -0.02857133001089096,
      0.013245574198663235,
      -0.06340689957141876,
      -0.038451746106147766,
      -0.042109593749046326,
      -0.06791331619024277,
      0.014934789389371872,
      0.010956179350614548,
      0.00517888180911541,
      -0.0478844940662384,
      0.0033487449400126934,
      -0.043916866183280945,
      -0.055653005838394165,
      0.0856611356139183,
      0.019999029114842415,
      0.00445023737847805,
      -0.01592913828790188,
      -0.05144268646836281,
      -0.07716596126556396,
      -0.030863607302308083,
      -0.052581772208213806,
      0.047326166182756424,
      -0.0052343010902404785,
      0.011809762567281723,
      -0.025614595040678978,
      -0.0518220029771328,
      -0.05043285712599754,
      0.04210713133215904,
      0.06539231538772583,
      0.029297351837158203,
      0.018574059009552002,
      0.03820059448480606,
      0.023306068032979965,
      0.012103753164410591,
      -0.01614215597510338,
      -0.018250076100230217,
      0.0013693122891709208,
      0.001919254194945097,
      0.07383527606725693,
      -0.013201981782913208,
      0.028773972764611244,
      0.03442350775003433,
      0.078131765127182,
      -0.021467022597789764,
      0.0764961689710617,
      0.009353408589959145,
      0.015456315129995346,
      -0.005837876349687576,
      0.04472220689058304,
      -0.03654664754867554,
      0.021039780229330063,
      -0.026942165568470955,
      -0.0244322307407856,
      -0.00811411440372467,
      0.008465522900223732,
      0.06532292068004608,
      0.0007953726453706622,
      -0.02985941804945469,
      0.09437248855829239,
      0.006746464408934116,
      0.0021369478199630976,
      0.026607245206832886,
      -0.07072775810956955,
      0.0437922477722168,
      0.0027987025678157806,
      -0.027842078357934952,
      -0.0139023931697011,
      0.003470298135653138,
      0.01860090158879757,
      0.10566319525241852,
      0.08890742063522339,
      -0.06084628030657768,
      0.01756056770682335,
      -0.04059099033474922,
      -0.1527838259935379,
      0.03778690844774246,
      -0.056329693645238876,
      0.009610331617295742,
      0.05628671124577522,
      -0.0038087537977844477,
      0.02668786607682705,
      -0.024836912751197815,
      -0.008032403886318207,
      -0.05006461590528488,
      -0.00783670786768198,
      0.07962986826896667,
      0.03217782825231552,
      -0.044800832867622375,
      0.01894085854291916,
      -0.014293726533651352,
      0.026178456842899323,
      0.028575733304023743,
      0.06942930072546005,
      0.05523929372429848,
      -0.017000606283545494,
      0.015546989627182484,
      -0.002898520091548562,
      -0.00795422587543726,
      0.07570008933544159,
      0.0798054039478302,
      0.11248163133859634,
      0.00716310553252697,
      -0.07099956274032593,
      -0.06203961744904518,
      -0.03618793562054634,
      -0.011683977209031582,
      -0.010706783272325993,
      -0.04944349452853203,
      -0.08924219012260437,
      -0.030171837657690048,
      0.00647108256816864,
      0.09082280099391937,
      -0.029818087816238403,
      -0.027183573693037033,
      -0.07434652745723724,
      -0.045464858412742615,
      -0.029114587232470512,
      -0.031575582921504974,
      0.029043298214673996,
      0.03412819653749466,
      -0.01705041341483593,
      -0.01358101423829794,
      0.08390989154577255,
      0.03396322578191757,
      0.06506133824586868,
      -0.07013780623674393,
      0.07400033622980118,
      0.024844231083989143,
      0.0002505961456336081,
      0.0019581413362175226,
      0.005229697097092867,
      0.01058096531778574,
      -0.057231608778238297,
      -0.020416101440787315,
      0.013285101391375065,
      -0.018498556688427925,
      0.062197159975767136,
      -0.022526269778609276,
      -0.02711251936852932,
      -0.052860528230667114,
      0.005637817550450563,
      -0.09058266133069992,
      0.03371139615774155,
      -0.0822974145412445,
      -0.08720579743385315,
      -0.028336703777313232,
      -0.029385805130004883,
      0.048983193933963776,
      0.01572914607822895,
      0.021980995312333107,
      0.02259787917137146,
      -0.008261092938482761,
      0.06119566038250923,
      0.0004070546419825405,
      -0.03593278303742409,
      -0.00024803265114314854,
      0.023080583661794662,
      -0.10390119254589081,
      0.006894251331686974,
      0.027343744412064552
    ],
    [
      -0.019455576315522194,
      -0.038551509380340576,
      -0.041479356586933136,
      0.08952774852514267,
      -0.038725703954696655,
      0.00626826798543334,
      0.032132748514413834,
      -0.027627447620034218,
      -0.036336928606033325,
      0.04923734813928604,
      0.022328341379761696,
      -0.028388310223817825,
      -0.029211366549134254,
      -0.006179902236908674,
      0.008868851698935032,
      -0.02683486044406891,
      -0.0780041515827179,
      0.011913938447833061,
      0.01779421977698803,
      -0.05186237022280693,
      -0.03170201927423477,
      0.020820870995521545,
      0.03052905760705471,
      0.02128838188946247,
      0.07547999173402786,
      0.00649899709969759,
      -0.025529630482196808,
      -0.018798617646098137,
      -0.013167540542781353,
      -0.051620002835989,
      -0.03264808654785156,
      0.00533259054645896,
      0.03823351487517357,
      -0.015948586165905,
      -0.049177877604961395,
      -0.02359076589345932,
      -0.09644759446382523,
      -0.04786530137062073,
      0.00162721355445683,
      -0.06982960551977158,
      -0.03259924426674843,
      0.02443678118288517,
      0.058299720287323,
      -0.02173379249870777,
      -0.11468715220689774,
      -0.039657898247241974,
      0.07278572767972946,
      -0.05248206853866577,
      0.003417359199374914,
      -0.03850096836686134,
      0.03511492908000946,
      0.07695045322179794,
      0.09211716055870056,
      -0.059618376195430756,
      -0.02475222572684288,
      -0.042435675859451294,
      0.06461429595947266,
      0.039110708981752396,
      -0.07500696927309036,
      -0.020511023700237274,
      0.04399283230304718,
      -0.02922724559903145,
      0.017731323838233948,
      0.007718016393482685,
      -0.02169225551187992,
      0.02434026636183262,
      0.08084546774625778,
      0.0031086124945431948,
      -0.021742258220911026,
      -0.0384242981672287,
      0.033254433423280716,
      -0.024441462010145187,
      0.01400480791926384,
      -0.060994554311037064,
      -0.1022382378578186,
      0.047928597778081894,
      -0.10419649630784988,
      -0.0025335971731692553,
      -0.03511649742722511,
      0.03140225633978844,
      0.03456776961684227,
      -0.11633570492267609,
      -0.06156972795724869,
      0.028239892795681953,
      -0.0022454254794865847,
      -0.029757767915725708,
      -0.11959875375032425,
      -0.015822365880012512,
      -0.03866993635892868,
      -0.0025249593891203403,
      0.024535903707146645,
      -0.06812556833028793,
      0.07222436368465424,
      -0.04808104410767555,
      0.07254867255687714,
      0.07677283138036728,
      0.02596997655928135,
      0.0038765983190387487,
      0.01877715066075325,
      0.05901770293712616,
      -0.020877812057733536,
      0.01830071397125721,
      0.021996544674038887,
      0.057970307767391205,
      -0.0767284706234932,
      0.024419870227575302,
      -0.011513222940266132,
      0.0033915438689291477,
      0.0906134694814682,
      -0.016507824882864952,
      -0.026431826874613762,
      -0.030650600790977478,
      0.02198239229619503,
      0.05644354596734047,
      -0.03280892223119736,
      -0.04714582487940788,
      0.01944735087454319,
      0.034657031297683716,
      -0.04767720773816109,
      -0.010910529643297195,
      -0.08599437028169632,
      0.005058149341493845,
      -0.05133206024765968,
      0.013316964730620384,
      0.03791428729891777,
      0.09382881969213486,
      -0.027190664783120155,
      -0.07279659807682037,
      0.05724697187542915,
      -3.943957563024014e-05,
      -0.018214019015431404,
      0.02895757183432579,
      0.03144194185733795,
      -0.0502074658870697,
      -0.06848350912332535,
      0.014274731278419495,
      0.029505426064133644,
      0.03116622008383274,
      -0.028421299532055855,
      -0.02738550677895546,
      0.011606547981500626,
      0.009731263853609562,
      0.039924778044223785,
      0.00493651581928134,
      0.02810833230614662,
      0.04818253964185715,
      -0.036064110696315765,
      0.049176815897226334,
      -0.01900634542107582,
      0.03613746911287308,
      -0.00574845727533102,
      -0.016159500926733017,
      0.0444791242480278,
      0.04293975234031677,
      0.03782782331109047,
      -0.005618170835077763,
      0.026949329301714897,
      -0.029621955007314682,
      0.07115048915147781,
      0.030264962464571,
      -0.015234536491334438,
      -0.11999581754207611,
      -0.034539997577667236,
      0.016003400087356567,
      0.07226121425628662,
      0.01929747313261032,
      -0.03415706753730774,
      -0.004257770720869303,
      -0.07391824573278427,
      2.3927579604787752e-05,
      -0.010969771072268486,
      -0.013318061828613281,
      0.04089910909533501,
      0.01810932345688343,
      -0.00908137857913971,
      0.0020572817884385586,
      0.009389365091919899,
      -0.08400887250900269,
      0.030469156801700592,
      -0.014040892943739891,
      0.025951316580176353,
      -0.030454309657216072,
      0.004700510296970606,
      -0.03440413996577263,
      -0.030849579721689224,
      0.023736940696835518,
      -0.05315089970827103,
      0.007450468838214874,
      0.1049671471118927,
      0.003588644554838538,
      0.04573621228337288,
      0.011531277559697628,
      -0.009268858470022678,
      0.1268150955438614,
      0.0636444017291069,
      0.017654186114668846,
      -0.019483773037791252,
      -0.029111381620168686,
      -0.021571768447756767,
      0.03914942219853401,
      -0.03475707396864891,
      -0.04095068946480751,
      -0.018727121874690056,
      -0.048408202826976776,
      -0.033987823873758316,
      0.05370910093188286,
      -0.018803952261805534,
      0.05409034341573715,
      0.004802600014954805,
      -0.038847003132104874,
      -0.05230431631207466,
      -0.055910442024469376,
      0.0065573714673519135,
      -0.03622828423976898,
      -0.03989233076572418,
      -0.06521302461624146,
      -0.05182972550392151,
      -0.12500496208667755,
      -0.019013511016964912,
      0.003924011252820492,
      -0.03220684826374054,
      -0.025920966640114784,
      -0.0426449291408062,
      -0.05526173487305641,
      0.06535231322050095,
      0.019899265840649605,
      -0.022877616807818413,
      0.06476588547229767,
      -0.07514878362417221,
      0.06411182880401611,
      0.035945989191532135,
      0.07447946816682816,
      0.06825945526361465,
      0.016255276277661324,
      0.08656465262174606,
      0.06083744764328003,
      -0.016180094331502914,
      -0.03449250012636185,
      0.0008285801159217954,
      -0.05620032548904419,
      0.02048625610768795,
      -0.02839772216975689,
      -0.013971750624477863,
      0.006867112126201391,
      0.04813941940665245,
      0.04044074937701225,
      0.03461115434765816,
      -0.05035097524523735,
      -0.007481182459741831,
      -0.047400664538145065,
      0.020929425954818726,
      0.036172471940517426,
      0.012268180958926678,
      0.01229431014508009,
      0.006808179896324873,
      0.03801015391945839,
      -0.03707576170563698,
      -0.005898881703615189,
      -0.12233100831508636,
      -0.07653184235095978,
      0.1073189452290535,
      -0.07076645642518997,
      -0.010166117921471596,
      -0.11003198474645615,
      0.004548061639070511,
      -0.03490864112973213,
      0.008009870536625385,
      0.02429760992527008,
      -0.03898181393742561,
      0.02391931787133217,
      0.066543810069561,
      -0.03853040933609009,
      -0.04775300994515419,
      0.001988435862585902,
      0.040452662855386734,
      0.054278843104839325,
      -0.024233903735876083,
      0.04377724975347519,
      -0.10890155285596848,
      0.02222411334514618,
      0.06515632569789886,
      -0.04165004566311836,
      -0.05432889983057976,
      0.0299729872494936,
      -0.0735003873705864,
      0.09680088609457016,
      0.027594881132245064,
      0.07442813366651535,
      -0.009505422785878181,
      -0.011698614805936813,
      -0.12842941284179688,
      0.040386494249105453,
      -0.026386842131614685,
      0.007646089885383844,
      -0.034144576638936996,
      0.0021714528556913137,
      0.011016733944416046,
      0.04887520149350166,
      -0.027933815494179726,
      -0.0627249926328659,
      -0.025348545983433723,
      -0.04162682220339775,
      -0.051255688071250916,
      0.10770472139120102,
      0.027806222438812256,
      0.07858941704034805,
      -0.01098471600562334,
      0.05549447983503342,
      0.07551667094230652,
      -0.06588847190141678,
      0.09048216789960861,
      -0.05951394885778427,
      0.04150575026869774,
      -0.01167808286845684,
      -0.012042519636452198,
      0.04639176279306412,
      -0.030515506863594055,
      0.0941055566072464,
      -0.027614811435341835,
      -0.0593634657561779,
      -0.03275633230805397,
      0.06378018856048584,
      0.04004979133605957,
      -0.07970063388347626,
      0.04043491557240486,
      0.012010106816887856,
      -0.13506972789764404,
      -0.07840079069137573,
      0.07272855937480927,
      -0.01107020489871502,
      0.013783442787826061,
      0.03492281213402748,
      0.11867368966341019,
      0.012805214151740074,
      -0.0722602978348732,
      0.012479397468268871,
      -0.0636473298072815,
      0.0654793232679367,
      0.06646882742643356,
      0.0006286246352829039,
      0.003847250947728753,
      -0.015422658994793892,
      -0.015702299773693085,
      -0.0022807344794273376,
      -0.030402151867747307,
      0.01843888685107231,
      -0.04577723518013954,
      0.048235658556222916,
      0.014924215152859688,
      -0.027947857975959778,
      0.06885992735624313,
      -0.030449608340859413,
      -0.004480970557779074,
      0.00305678672157228,
      0.02662401832640171,
      -0.09542933106422424,
      0.05244295299053192,
      0.052956473082304,
      -0.0157450120896101,
      0.013057919219136238,
      0.010124924592673779,
      0.01920291967689991,
      0.01841709390282631,
      -0.014861365780234337,
      -0.013371464796364307,
      -0.01752101257443428,
      -0.0019245542353019118,
      0.008642851375043392,
      -0.007768713869154453,
      0.012186461128294468,
      -0.06865540146827698,
      0.01630485989153385,
      -0.09709424525499344,
      0.02912692353129387,
      0.025495748966932297,
      0.02494874969124794,
      -0.0072373198345303535,
      -0.06680453568696976,
      -0.017839327454566956,
      0.010929111391305923,
      0.059403084218502045,
      -0.025130514055490494,
      0.03677062317728996,
      0.12198557704687119,
      0.018249956890940666,
      -0.056611090898513794,
      0.0017685815691947937,
      0.09194417297840118,
      -0.026130354031920433,
      0.04920370131731033,
      -0.03757220879197121,
      0.0050799730233848095,
      -0.051457419991493225,
      0.02491796761751175,
      -0.0025801167357712984,
      0.030320387333631516,
      0.024586180225014687,
      -0.04826163873076439,
      0.008724784478545189,
      0.09668868035078049,
      -0.0003428826457820833,
      0.013615858741104603,
      -0.003989997319877148,
      -0.009338133968412876,
      0.030839331448078156,
      -0.003890412161126733,
      0.0437319278717041,
      -0.024673400446772575,
      0.04714309051632881,
      -0.026578020304441452,
      0.016183728352189064,
      0.02000371553003788,
      0.011186165735125542,
      -0.03927209600806236,
      0.07620105147361755,
      0.07791602611541748,
      -0.00696916226297617,
      0.06903950124979019,
      0.013456570915877819,
      0.09699445217847824,
      -0.08665450662374496,
      0.038506392389535904,
      0.005907595157623291,
      -0.017396844923496246,
      0.03489764779806137,
      -0.03948614001274109,
      0.003124723443761468,
      -0.08093106001615524,
      0.0700102224946022,
      0.009085298515856266,
      0.0399201326072216,
      -0.04113530367612839,
      -0.014570693485438824,
      -0.03286440670490265,
      -0.015757687389850616,
      0.05716165527701378,
      -0.07190752774477005,
      0.01623384840786457,
      -0.030977172777056694,
      0.04548393562436104,
      0.04794469103217125,
      -0.07268164306879044,
      0.02807813324034214,
      0.061071138828992844,
      0.0752507671713829,
      0.008176187053322792,
      -0.030762875452637672,
      -0.04452510178089142,
      0.01856853999197483,
      0.04056689515709877,
      -0.03181092068552971,
      -0.016681745648384094,
      0.021174252033233643,
      0.07290199398994446,
      0.04758673533797264,
      -0.07507476210594177,
      -0.006239663809537888,
      -0.005964266136288643,
      -0.04986860975623131,
      0.020344434306025505,
      0.06260283291339874,
      0.02032683603465557,
      0.010772328823804855,
      -0.038661278784275055,
      -0.042008157819509506,
      0.12954893708229065,
      -0.04675954952836037,
      -0.018552565947175026,
      0.022676099091768265,
      -0.033172961324453354,
      -0.014936808496713638,
      -0.011783252470195293,
      0.019598904997110367,
      -0.02934504859149456,
      -0.01560830045491457,
      -0.01623019389808178,
      -0.003958888817578554,
      0.059936925768852234,
      -0.08808466047048569,
      -0.010853132233023643,
      -0.05346792936325073,
      0.021591538563370705,
      -0.04127334803342819,
      0.01434684731066227,
      -0.013742576353251934,
      0.001686030882410705,
      0.06591054797172546,
      -0.07129427045583725,
      0.029699761420488358,
      0.019263828173279762,
      0.047778427600860596,
      0.0323413610458374,
      -0.016022788360714912,
      0.0634029284119606,
      0.0010027560638263822,
      -0.002295341808348894,
      0.048523709177970886,
      0.003854327602311969,
      -0.036631010472774506,
      0.04098638519644737,
      -0.11780630052089691,
      -0.008831054903566837,
      0.12462211400270462
    ],
    [
      0.0055563985370099545,
      -0.011212632991373539,
      0.009711243212223053,
      0.0859627053141594,
      -0.0016132777091115713,
      -0.009885727427899837,
      0.054972127079963684,
      -0.0403347872197628,
      -0.030307434499263763,
      -0.0010556908091530204,
      0.017517710104584694,
      -0.0005431782337836921,
      0.022939594462513924,
      0.0539139062166214,
      0.02485719695687294,
      -0.015139589086174965,
      -0.09771731495857239,
      -0.06441344320774078,
      -0.022089404985308647,
      0.04240725561976433,
      0.04069502279162407,
      0.028730740770697594,
      -0.014745242893695831,
      -0.0471905879676342,
      0.03658303618431091,
      -0.0025821756571531296,
      -0.005547906272113323,
      0.026998111978173256,
      0.009079820476472378,
      -0.03750387579202652,
      -0.050028737634420395,
      0.01608794927597046,
      0.0003949438105337322,
      0.0449327789247036,
      -0.0004072331066709012,
      -0.04700404778122902,
      -0.055638160556554794,
      0.05053781345486641,
      -0.006511772982776165,
      -0.01756034791469574,
      0.0008543990552425385,
      0.010315156541764736,
      0.03092031739652157,
      -0.0017191125079989433,
      -0.017703190445899963,
      -0.033781543374061584,
      0.003192381002008915,
      -0.01793230138719082,
      0.10173089802265167,
      -0.006972567178308964,
      0.009557043202221394,
      0.06977952271699905,
      -0.01606176048517227,
      0.03103671781718731,
      -0.017667599022388458,
      -0.0479818694293499,
      0.10494663566350937,
      -0.07265809923410416,
      -0.01828262023627758,
      0.009166599251329899,
      -0.01478874497115612,
      -0.014988641254603863,
      -0.025122812017798424,
      -0.0814308300614357,
      -0.033167704939842224,
      -0.02424822747707367,
      0.06900809705257416,
      -0.02892269939184189,
      -0.004162707831710577,
      0.015603396110236645,
      0.07096721231937408,
      0.02323612943291664,
      0.08478893339633942,
      0.0034508826211094856,
      -0.1093146950006485,
      0.039597973227500916,
      -0.009611834771931171,
      0.0252999197691679,
      0.016961824148893356,
      -0.007773972116410732,
      0.02320140227675438,
      -0.05896306782960892,
      -0.005915401969105005,
      0.019230283796787262,
      -0.026903847232460976,
      -0.012248742394149303,
      -0.1125212237238884,
      0.057235702872276306,
      0.080718994140625,
      0.003874103305861354,
      0.061705853790044785,
      -0.05797102674841881,
      -0.005310135427862406,
      -0.006222146563231945,
      0.04828435182571411,
      0.010747172869741917,
      -0.04211209714412689,
      -0.054413046687841415,
      -0.06386006623506546,
      0.07743164151906967,
      0.00331300450488925,
      -0.0445072203874588,
      0.06039055436849594,
      -0.015494424849748611,
      -0.06345272064208984,
      -0.06296098232269287,
      0.04250994697213173,
      0.09566409885883331,
      -0.019517870619893074,
      -0.022973258048295975,
      -0.010799126699566841,
      0.07036431133747101,
      0.061164747923612595,
      0.03303924575448036,
      -0.016848845407366753,
      0.0025318327825516462,
      -0.06170962378382683,
      -0.006106745917350054,
      -0.004302778746932745,
      0.00910862535238266,
      -0.05833617225289345,
      0.00014776161697227508,
      0.014728883281350136,
      -0.04670041427016258,
      -0.005145759787410498,
      0.062307942658662796,
      -0.017164120450615883,
      0.05268850177526474,
      0.025718681514263153,
      -0.0350106805562973,
      -0.02200460620224476,
      -0.005387437995523214,
      -0.005462359637022018,
      0.025349333882331848,
      -0.06826012581586838,
      -0.023758646100759506,
      0.0260054599493742,
      0.031395040452480316,
      0.06898695230484009,
      0.04838268831372261,
      0.0019851350225508213,
      -0.049893006682395935,
      0.045305948704481125,
      -0.0649975910782814,
      0.059297412633895874,
      -0.02576916478574276,
      -0.02426329255104065,
      -0.006190003827214241,
      0.0767606571316719,
      -0.0007524089887738228,
      -0.03911026194691658,
      0.054265160113573074,
      0.06959996372461319,
      0.012439251877367496,
      0.04179076477885246,
      0.024508124217391014,
      -0.04552895575761795,
      0.028747061267495155,
      -0.004765485413372517,
      0.026892947033047676,
      -0.02589886449277401,
      0.09939499944448471,
      0.031178975477814674,
      -7.767839269945398e-05,
      0.0162578783929348,
      0.02094561792910099,
      0.03615311160683632,
      0.034693777561187744,
      0.03678058087825775,
      0.028434423729777336,
      -0.06589896231889725,
      0.04653368517756462,
      -0.01839088834822178,
      -0.019656958058476448,
      0.029362406581640244,
      0.0023971286136657,
      0.030072081834077835,
      -0.09666404128074646,
      0.04033063352108002,
      -0.010166853666305542,
      0.07767610996961594,
      0.07197128236293793,
      0.04535973444581032,
      0.04404769092798233,
      -5.886715371161699e-05,
      0.006176612805575132,
      -0.025329194962978363,
      -0.04466870054602623,
      0.021836819127202034,
      -0.030305374413728714,
      0.007273381110280752,
      0.039842359721660614,
      0.057221271097660065,
      0.03283850476145744,
      0.0026226197369396687,
      -0.04932135343551636,
      0.017564699053764343,
      -0.0831001028418541,
      0.037731293588876724,
      0.006356836296617985,
      -0.007864924147725105,
      0.018529025837779045,
      -4.985569103155285e-05,
      -0.039206523448228836,
      0.06348104029893875,
      -0.03588675335049629,
      0.012012578547000885,
      -0.11191311478614807,
      0.02569735422730446,
      -0.006414984818547964,
      0.0149830412119627,
      0.018801918253302574,
      -0.08986128121614456,
      -0.09356927126646042,
      0.034456558525562286,
      0.02962304651737213,
      -0.06230250000953674,
      -0.07323461771011353,
      0.03258521482348442,
      -0.014161532744765282,
      -0.10034096240997314,
      0.03676252067089081,
      0.015763163566589355,
      -0.027230823412537575,
      -0.07997718453407288,
      -0.038455598056316376,
      -0.011094083078205585,
      0.004724267870187759,
      -0.059999018907547,
      -0.08147262781858444,
      -0.025054363533854485,
      -0.081580251455307,
      0.049765750765800476,
      -0.042524270713329315,
      -0.013601218350231647,
      -0.055557191371917725,
      -0.024250255897641182,
      0.03946467116475105,
      0.003945624455809593,
      0.020525310188531876,
      0.022194277495145798,
      -0.04976172372698784,
      0.030981741845607758,
      0.07582850009202957,
      0.04746058210730553,
      -0.009706197306513786,
      -0.08022734522819519,
      -0.02907557599246502,
      0.03534422069787979,
      0.03557734936475754,
      -0.08594942837953568,
      -0.06826983392238617,
      -0.008806486614048481,
      -0.08057421445846558,
      0.01801014132797718,
      -0.04934763163328171,
      -0.07169483602046967,
      0.0829506516456604,
      -0.04109504073858261,
      0.0008226482896134257,
      -0.02323111891746521,
      -0.03176111727952957,
      0.04848077893257141,
      -0.03281651809811592,
      -0.00851862970739603,
      -0.029535412788391113,
      -0.009109062142670155,
      -0.018270350992679596,
      -0.07599838078022003,
      0.019006481394171715,
      0.06299277395009995,
      -0.02126835100352764,
      -0.01660790853202343,
      -0.008903296664357185,
      -0.04086359217762947,
      0.027218157425522804,
      0.004068948328495026,
      0.0423772931098938,
      -0.031031137332320213,
      -0.011682347394526005,
      0.015621704049408436,
      0.02159675396978855,
      -0.011691540479660034,
      -0.03989031910896301,
      -0.02735108695924282,
      0.00508703850209713,
      -0.013092662207782269,
      0.07405591756105423,
      -0.08049775660037994,
      0.024073341861367226,
      0.05705045908689499,
      0.057831183075904846,
      0.10639409720897675,
      0.0707206204533577,
      -0.034786175936460495,
      -0.10186383873224258,
      -0.06267017871141434,
      -0.07197512686252594,
      -0.04978733882308006,
      0.035753581672906876,
      -0.019893240183591843,
      -0.10755939781665802,
      0.009883960708975792,
      0.037012528628110886,
      0.015358289703726768,
      0.03168452903628349,
      0.04277358576655388,
      0.041589826345443726,
      0.048176076263189316,
      -0.000266326533164829,
      0.06687162071466446,
      0.05007978528738022,
      0.05036726966500282,
      -0.01792674884200096,
      0.002148283179849386,
      0.002114512026309967,
      0.009121725335717201,
      0.057444460690021515,
      -0.0061281719245016575,
      -0.013894723728299141,
      0.09644731879234314,
      -0.03628138452768326,
      0.039533138275146484,
      0.02300347201526165,
      0.017792848870158195,
      -0.07138872891664505,
      -0.059871405363082886,
      -0.007867390289902687,
      -0.0691058412194252,
      -0.033623263239860535,
      -0.03270407393574715,
      -0.016739621758461,
      0.022227633744478226,
      -0.020938485860824585,
      -0.03168564662337303,
      -0.014206388965249062,
      -0.13413453102111816,
      0.10023628920316696,
      -0.032382309436798096,
      0.004169508349150419,
      0.004265447147190571,
      -0.020461460575461388,
      -0.09368350356817245,
      0.08421025425195694,
      -0.0741741806268692,
      -0.054976142942905426,
      0.018521292135119438,
      0.00843759998679161,
      -0.020706472918391228,
      -0.013735152781009674,
      -0.04531450942158699,
      -0.032790105789899826,
      -0.055461347103118896,
      -0.054791562259197235,
      -0.004404006060212851,
      -0.016618922352790833,
      0.011898002587258816,
      -0.06403820216655731,
      0.018997473642230034,
      0.011471929959952831,
      -0.043406642973423004,
      -0.016463419422507286,
      -0.0731763243675232,
      0.013421065174043179,
      -0.02298804000020027,
      0.011288219131529331,
      0.0017632683739066124,
      0.014722445048391819,
      -0.005840725265443325,
      0.04101476073265076,
      0.03447510302066803,
      -0.03106202930212021,
      -0.07921206206083298,
      -0.007778354454785585,
      -0.04092256724834442,
      0.13339896500110626,
      0.029203791171312332,
      -0.05896550044417381,
      0.01934380829334259,
      -0.08492734283208847,
      -0.020288554951548576,
      0.016848454251885414,
      0.06609136611223221,
      -0.015708649531006813,
      0.0721995085477829,
      0.03209070861339569,
      -0.04957113042473793,
      0.037025030702352524,
      0.009861461818218231,
      -0.0346132293343544,
      0.012992547824978828,
      -0.01919533684849739,
      -0.07090086489915848,
      0.0193086639046669,
      -0.009187384508550167,
      -0.12818072736263275,
      -0.0668526142835617,
      -0.04664365574717522,
      0.05683007836341858,
      0.0011895891511812806,
      0.04495072737336159,
      0.036512307822704315,
      0.08024170249700546,
      -0.012294432148337364,
      0.024996953085064888,
      -0.047058988362550735,
      0.0827341079711914,
      -0.04175460338592529,
      0.08512672036886215,
      0.0006106705986894667,
      0.05923851951956749,
      -0.052786923944950104,
      -0.058075640350580215,
      0.010772139765322208,
      0.02018720470368862,
      0.043710798025131226,
      -0.012600616551935673,
      0.07399871200323105,
      -0.10711357742547989,
      0.005207972601056099,
      0.05965542420744896,
      0.0652070939540863,
      -0.013407835736870766,
      -0.03274323046207428,
      -0.029288483783602715,
      0.03249068930745125,
      -0.07812464982271194,
      -0.047736700624227524,
      0.029227469116449356,
      -0.0025329170748591423,
      0.026947088539600372,
      0.04976649209856987,
      -0.0359615795314312,
      0.028908241540193558,
      -0.02684219926595688,
      -0.0451703816652298,
      -0.018373889848589897,
      -0.11954765766859055,
      -0.02657589688897133,
      -0.037407781928777695,
      -0.03249061852693558,
      0.03421151265501976,
      -0.03966253995895386,
      -0.006338466424494982,
      -0.016369331628084183,
      0.00832027941942215,
      -0.03234606608748436,
      -0.0528951920568943,
      -0.0956176295876503,
      -0.08817650377750397,
      0.020486991852521896,
      0.029516685754060745,
      0.06871459633111954,
      -0.016868416219949722,
      0.02385886386036873,
      -0.04257185757160187,
      0.001416270388290286,
      0.03840574249625206,
      0.019533896818757057,
      -0.02842281386256218,
      0.07802853733301163,
      0.062468238174915314,
      -0.007186900824308395,
      -0.01300414465367794,
      -0.08956972509622574,
      -0.10674403607845306,
      -0.03657025471329689,
      0.005510006099939346,
      -0.06258319318294525,
      0.0031724125146865845,
      -0.004471280612051487,
      0.031015615910291672,
      0.07843252271413803,
      -0.004048720933496952,
      0.06447857618331909,
      0.007253600750118494,
      0.06957675516605377,
      -0.024493446573615074,
      -0.003022178541868925,
      0.0961856096982956,
      0.0034362676087766886,
      -0.03035377338528633,
      0.04617704078555107,
      0.09028272330760956,
      -0.03488364443182945,
      -0.028127595782279968,
      -0.029008343815803528,
      0.030331885442137718,
      0.031160034239292145,
      0.0016277228714898229,
      0.07382379472255707,
      0.002821182832121849,
      -0.003889069426804781,
      0.05490793660283089,
      -0.005114339757710695,
      -0.022424809634685516,
      0.01705184206366539,
      -0.03153190016746521,
      0.0018376787193119526,
      0.0024461783468723297,
      -0.02849113568663597,
      0.013310328125953674,
      0.034249771386384964
    ],
    [
      0.04456842690706253,
      -0.009447912685573101,
      0.006182873621582985,
      0.02263479121029377,
      0.049839600920677185,
      -0.0137935820966959,
      0.04051048681139946,
      0.05433129519224167,
      -0.052774135023355484,
      -0.01636592112481594,
      0.024520108476281166,
      0.03835183382034302,
      -0.03213935345411301,
      0.023905498906970024,
      0.03872232511639595,
      -0.06543461233377457,
      -0.045861586928367615,
      0.02963632345199585,
      -0.0582115575671196,
      0.01906697265803814,
      -0.023778172209858894,
      -0.04642484337091446,
      -0.02297144941985607,
      -0.016281219199299812,
      0.03849402815103531,
      0.014149974100291729,
      -0.03265039250254631,
      0.06526440382003784,
      -0.011043865233659744,
      0.08096513897180557,
      -0.011687789112329483,
      0.1465596705675125,
      -0.00016479205805808306,
      0.10787670314311981,
      -0.003937175031751394,
      -0.028554202988743782,
      -0.15800854563713074,
      -0.14643222093582153,
      0.03429442644119263,
      -0.06810003519058228,
      0.0470561757683754,
      -0.09893225878477097,
      0.06319744139909744,
      -0.003177468664944172,
      0.03227950260043144,
      0.03201485425233841,
      0.03375140577554703,
      0.035724934190511703,
      -0.00011032575275748968,
      -0.030476022511720657,
      0.02291082963347435,
      0.11426607519388199,
      -0.04388738423585892,
      0.004706972744315863,
      -0.012568428181111813,
      -0.04687423259019852,
      0.11465843766927719,
      0.015004324726760387,
      0.07609047740697861,
      -0.004120531026273966,
      -0.0023048631846904755,
      0.007818376645445824,
      -0.015484192408621311,
      0.033692412078380585,
      0.0017025128472596407,
      0.026872994378209114,
      0.06225011497735977,
      -0.052246034145355225,
      0.0455193929374218,
      -0.014486907050013542,
      0.022297251969575882,
      0.01114628929644823,
      0.018046965822577477,
      0.002769197802990675,
      0.05829377844929695,
      0.0015542033361271024,
      -0.011707574129104614,
      0.04052414745092392,
      -0.035714685916900635,
      0.10377936065196991,
      -0.06141508370637894,
      0.04218284413218498,
      -0.05391791835427284,
      -0.051705844700336456,
      -0.0002561883011367172,
      0.020918043330311775,
      0.017113881185650826,
      -0.021815400570631027,
      0.08713459223508835,
      0.03292568027973175,
      0.06571324169635773,
      0.026946116238832474,
      0.0020197133999317884,
      -0.0461471825838089,
      0.07179250568151474,
      0.027961527928709984,
      -0.006628578528761864,
      -0.10912840068340302,
      -0.02196153812110424,
      -0.022582614794373512,
      -0.016947217285633087,
      -0.09131120145320892,
      0.06906399130821228,
      0.0022032626438885927,
      -0.1197066381573677,
      -0.011841196566820145,
      -0.0934615209698677,
      -0.0841759666800499,
      0.006114363204687834,
      -0.0921812653541565,
      -0.01425196323543787,
      -0.012518476694822311,
      -0.07200101763010025,
      0.07775815576314926,
      -0.039920199662446976,
      0.004381225444376469,
      -0.008632197044789791,
      -0.064656101167202,
      -0.020283734425902367,
      -0.04549311846494675,
      -0.04590877890586853,
      -0.03250153735280037,
      0.01725398562848568,
      -0.08439280837774277,
      -0.09112755954265594,
      0.085438571870327,
      -0.03606683760881424,
      0.02725067548453808,
      -0.07186682522296906,
      0.03435610607266426,
      0.03772382065653801,
      7.678802649024874e-05,
      0.02791459485888481,
      0.0021464095916599035,
      0.002216951921582222,
      -0.00476883165538311,
      -0.023973310366272926,
      0.09006316214799881,
      -0.030913030728697777,
      0.037575237452983856,
      -0.06418773531913757,
      0.03308672457933426,
      0.03712279722094536,
      -0.08352888375520706,
      -0.0008442220278084278,
      0.012690741568803787,
      -0.0001478670019423589,
      0.0757809579372406,
      0.09007943421602249,
      -0.03480540215969086,
      0.006323244888335466,
      -0.001036730594933033,
      -0.020476583391427994,
      0.043109092861413956,
      -0.01874580606818199,
      -0.06189378723502159,
      -0.08592595160007477,
      -0.014614000916481018,
      -0.04380067437887192,
      -0.05749274417757988,
      0.025272632017731667,
      -0.03200503811240196,
      -0.03980966657400131,
      0.13116900622844696,
      -0.023235203698277473,
      0.06466954946517944,
      0.07274843752384186,
      -0.011642558500170708,
      -0.051245227456092834,
      -0.010696492157876492,
      -0.04298227280378342,
      0.06211535260081291,
      -0.007007061503827572,
      -0.01277658250182867,
      0.05019953474402428,
      0.045235972851514816,
      0.04114389047026634,
      -0.05858301743865013,
      -0.015845634043216705,
      0.007047588936984539,
      0.04607684165239334,
      0.024751266464591026,
      -0.0743352621793747,
      -0.031176727265119553,
      0.0690326914191246,
      -0.034586433321237564,
      0.03213369846343994,
      -0.006606298498809338,
      0.031262222677469254,
      -0.10195259749889374,
      -0.0019087422406300902,
      -0.022609468549489975,
      0.08116061240434647,
      0.039563775062561035,
      -0.002607684349641204,
      -0.05731794238090515,
      0.018121639266610146,
      -0.07513490319252014,
      -0.027537504211068153,
      -0.08052492886781693,
      -0.000613445125054568,
      -0.03712013363838196,
      -0.01871786266565323,
      0.005467234179377556,
      0.013711695559322834,
      0.03393262252211571,
      -0.05149499699473381,
      0.062260597944259644,
      -0.06782876700162888,
      -0.0017267544753849506,
      0.059440650045871735,
      0.06887251883745193,
      -0.023117078468203545,
      -0.06816314160823822,
      -0.008166625164449215,
      -0.014490749686956406,
      -0.0007527159177698195,
      0.0030693495646119118,
      0.006891140714287758,
      -0.008026436902582645,
      0.014850657433271408,
      0.02614677883684635,
      -0.011458853259682655,
      -0.04827374592423439,
      0.05228904262185097,
      -0.024729061871767044,
      0.010041207075119019,
      -0.05839916691184044,
      -0.02240121178328991,
      0.019489558413624763,
      0.04352794960141182,
      -0.030793573707342148,
      -0.06315390020608902,
      0.027479179203510284,
      0.04118688777089119,
      -0.04525197297334671,
      0.05744365230202675,
      0.021720949560403824,
      0.0454707033932209,
      -0.010706881061196327,
      -0.015839669853448868,
      0.07711703330278397,
      -0.04641016200184822,
      0.03114437870681286,
      0.059389106929302216,
      -0.022878706455230713,
      0.03456322103738785,
      -0.07842358946800232,
      -0.061699699610471725,
      -0.0005532550276257098,
      0.001611962914466858,
      0.006413406692445278,
      -0.004982829559594393,
      -0.03459632396697998,
      0.02922702766954899,
      -0.005850125104188919,
      -0.06521692872047424,
      0.08540017157793045,
      -0.069635771214962,
      -0.038121145218610764,
      0.0015952447429299355,
      -0.0176088884472847,
      -0.05094039440155029,
      -0.023322448134422302,
      -0.08524514734745026,
      -0.06411124765872955,
      -0.0552772656083107,
      -0.0598921999335289,
      -0.007127918303012848,
      0.026209579780697823,
      0.03657858073711395,
      -0.0451943501830101,
      0.041854921728372574,
      0.022081993520259857,
      0.041301120072603226,
      -0.03428179398179054,
      -0.08220700174570084,
      0.06184742972254753,
      -0.057254236191511154,
      0.043603524565696716,
      0.06576366722583771,
      0.0006254122126847506,
      -0.048009876161813736,
      0.022532209753990173,
      0.006337841507047415,
      0.015780910849571228,
      -0.09105578809976578,
      -0.052782025188207626,
      0.005844776518642902,
      -0.013573925010859966,
      0.0041815172880887985,
      0.07844675332307816,
      -0.0016551505541428924,
      0.03565436974167824,
      -0.05685732513666153,
      0.0248720683157444,
      -0.02836611494421959,
      0.041332099586725235,
      -0.07301168143749237,
      0.046450234949588776,
      -0.031124141067266464,
      0.027480201795697212,
      0.08172071725130081,
      0.0859227180480957,
      -0.06059036776423454,
      0.03991324454545975,
      -0.030021201819181442,
      -0.025960909202694893,
      -0.027700915932655334,
      0.041577231138944626,
      0.014839929528534412,
      -0.005231449380517006,
      -0.03140686824917793,
      0.01442165207117796,
      0.06010160967707634,
      0.031082261353731155,
      -0.05896298959851265,
      0.0066734980791807175,
      0.09572575986385345,
      0.014989311806857586,
      0.044573426246643066,
      -0.07795456051826477,
      -0.04110218584537506,
      -0.058186717331409454,
      0.10520205646753311,
      -0.021017281338572502,
      -0.027195803821086884,
      0.015197419561445713,
      -0.03737201169133186,
      0.08818811923265457,
      0.10288012772798538,
      -0.021653469651937485,
      -0.018696289509534836,
      0.002742632757872343,
      -0.007443384267389774,
      -0.014988369308412075,
      -0.05026056990027428,
      0.0007414668216370046,
      -0.016847245395183563,
      -0.035764433443546295,
      0.042974796146154404,
      -0.1384298950433731,
      -0.03140537068247795,
      -0.023332932963967323,
      -0.029310278594493866,
      0.057185590267181396,
      -0.03511323779821396,
      -0.025245720520615578,
      0.03890511393547058,
      0.0547369085252285,
      -0.029172183945775032,
      -0.042208485305309296,
      0.032447751611471176,
      0.03993901610374451,
      -0.05391091853380203,
      0.015433096326887608,
      -0.054253287613391876,
      0.06375176459550858,
      -0.03264806792140007,
      0.006514406763017178,
      0.05614963918924332,
      -0.014897189103066921,
      -0.005166959948837757,
      -0.01869133487343788,
      0.011159115470945835,
      -0.03162408992648125,
      0.026389257982373238,
      -0.029803035780787468,
      -0.06244054436683655,
      0.043233346194028854,
      0.050148505717515945,
      -0.05633297562599182,
      0.03616757318377495,
      -0.0802842378616333,
      -0.06039761006832123,
      -0.025536319240927696,
      0.05686921626329422,
      0.05748558044433594,
      0.027954809367656708,
      -0.049386318773031235,
      0.009654843248426914,
      0.135078564286232,
      0.04955941438674927,
      -0.027144214138388634,
      -0.015062540769577026,
      -0.025938216596841812,
      0.07305540889501572,
      0.015633709728717804,
      -0.07171603292226791,
      0.051734089851379395,
      0.03426365554332733,
      0.11769870668649673,
      -0.0654042586684227,
      -0.058982014656066895,
      -0.05244600027799606,
      -0.01129465363919735,
      -0.0150907002389431,
      0.0637882873415947,
      0.022082146257162094,
      -0.030232025310397148,
      -0.002377328695729375,
      0.08304829895496368,
      -0.005217653699219227,
      0.02226891741156578,
      -0.04028628394007683,
      0.023844311013817787,
      -0.02558257058262825,
      -0.031478337943553925,
      -0.12859848141670227,
      0.06915970891714096,
      -0.11297740042209625,
      -0.09516160935163498,
      -0.008081881329417229,
      -0.014018179848790169,
      0.02070549689233303,
      0.021642697975039482,
      -0.005372670013457537,
      -0.0046113901771605015,
      0.05135875567793846,
      -0.03452286869287491,
      -0.09508075565099716,
      -0.07752881199121475,
      0.011151116341352463,
      0.018188180401921272,
      0.047748394310474396,
      -0.053308501839637756,
      -0.030817093327641487,
      0.06629126518964767,
      -0.009107542224228382,
      -0.007129145786166191,
      0.02758796513080597,
      -0.012002832256257534,
      0.013998214155435562,
      0.008555751293897629,
      -0.014002881944179535,
      -0.035733338445425034,
      0.03546180576086044,
      -0.001557285082526505,
      0.0394161157310009,
      0.03594423085451126,
      -0.0032340576872229576,
      -0.03345862403512001,
      -0.007046910934150219,
      -0.008359469473361969,
      -0.025006555020809174,
      -0.03384280949831009,
      -0.027385488152503967,
      -0.05817652493715286,
      0.03015921451151371,
      -0.03413878008723259,
      -0.031023912131786346,
      0.0351451113820076,
      -0.01940602995455265,
      0.023708723485469818,
      -0.031407441943883896,
      -0.02880060113966465,
      -0.07933997362852097,
      0.004236811306327581,
      -0.012268245220184326,
      0.05978876352310181,
      0.05075136199593544,
      0.07873464375734329,
      0.03405805677175522,
      -0.10070561617612839,
      -0.03184961527585983,
      -0.014584098942577839,
      -0.10271431505680084,
      0.02445814572274685,
      -0.021355800330638885,
      -0.04214086756110191,
      0.001225101645104587,
      -0.002490151207894087,
      -0.04381709173321724,
      -0.04640311747789383,
      -0.007510078139603138,
      0.0014323975192382932,
      0.02511880174279213,
      -0.007105429656803608,
      -0.014072401449084282,
      0.08917432278394699,
      0.00023315307043958455,
      -0.05964644253253937,
      0.06094351410865784,
      0.09224924445152283,
      -0.04089409485459328,
      0.040599577128887177,
      -0.035204194486141205,
      -0.07692425698041916,
      -0.01040260773152113,
      -0.04368149861693382,
      -0.04006035625934601,
      -6.691473390674219e-05,
      0.02212161011993885,
      -0.015541424043476582,
      0.011808732524514198,
      0.05787022411823273,
      0.066263347864151,
      -0.016077954322099686,
      0.019881349056959152,
      0.09800022095441818,
      -0.04858724772930145,
      0.07292654365301132,
      0.10837490856647491
    ],
    [
      0.02004888281226158,
      -0.020404785871505737,
      -0.062887042760849,
      -0.04461180046200752,
      -0.040667444467544556,
      -0.036482684314250946,
      0.05497349053621292,
      0.014126664027571678,
      -0.09571605920791626,
      0.012392143718898296,
      -0.020800776779651642,
      0.019406339153647423,
      0.03490104153752327,
      -0.02363518811762333,
      -0.030531156808137894,
      0.04964713752269745,
      -0.008799698203802109,
      0.017035571858286858,
      0.04345504194498062,
      0.02460523135960102,
      -0.002753336913883686,
      0.0034343029838055372,
      -0.04269382730126381,
      0.0696958675980568,
      0.008460922166705132,
      -0.06156426668167114,
      -0.023681791499257088,
      0.04323194921016693,
      -0.020725108683109283,
      -0.1378193199634552,
      -0.006773852743208408,
      -0.03267059102654457,
      0.016183562576770782,
      0.03280285373330116,
      0.017245756462216377,
      0.0018082489259541035,
      -0.037694476544857025,
      0.01642957516014576,
      0.08917587250471115,
      -0.044766925275325775,
      0.06079116836190224,
      -0.022525446489453316,
      0.09088507294654846,
      0.001962321111932397,
      -0.026449616998434067,
      0.012494382448494434,
      0.00463357288390398,
      -0.03478449210524559,
      0.016005322337150574,
      -0.022268569096922874,
      0.07108449190855026,
      0.03340119868516922,
      0.027226237580180168,
      -0.014224210754036903,
      -0.0016782990423962474,
      0.06383952498435974,
      0.0910940170288086,
      -0.025708738714456558,
      -0.0033261680509895086,
      0.01567397266626358,
      0.014364185743033886,
      0.00974572729319334,
      0.02507806196808815,
      -0.023222729563713074,
      0.04368652403354645,
      -0.02372373268008232,
      -0.0063338992185890675,
      0.004839274566620588,
      0.034066393971443176,
      0.0043815625831484795,
      -0.0054290564730763435,
      -0.0037437686696648598,
      -0.0001744108012644574,
      -0.03795979544520378,
      -0.062313955277204514,
      -0.030498836189508438,
      -0.004652345087379217,
      -0.03161831945180893,
      -0.015534689649939537,
      0.023150501772761345,
      0.025756776332855225,
      -0.05040431022644043,
      -0.045368000864982605,
      0.04209121689200401,
      0.016864467412233353,
      0.03967592492699623,
      -0.08328918367624283,
      -0.11276070028543472,
      0.07169555872678757,
      -0.0069654397666454315,
      -0.019228702411055565,
      -0.041903797537088394,
      -0.00925068836659193,
      -0.048673778772354126,
      -0.034658174961805344,
      0.04775233194231987,
      -0.06414200365543365,
      -0.09684357792139053,
      0.019543945789337158,
      -0.0319136343896389,
      -0.04020701348781586,
      0.04215998575091362,
      0.05889599770307541,
      -0.018485177308321,
      0.05456307902932167,
      0.03876931592822075,
      -0.03624790906906128,
      -0.051069531589746475,
      -0.02407725527882576,
      -0.07801935076713562,
      0.06448057293891907,
      -0.07405079901218414,
      0.02227921225130558,
      -0.0405740849673748,
      -0.07265672087669373,
      -0.013826703652739525,
      -0.06578207015991211,
      -0.03061722405254841,
      -0.04761379957199097,
      0.019802631810307503,
      -0.002034330042079091,
      0.00025148081476800144,
      -0.009611621499061584,
      0.0012480596778914332,
      -0.0528179332613945,
      0.06900294870138168,
      0.015072195790708065,
      -0.007028154097497463,
      0.0464862622320652,
      -0.004367978777736425,
      0.1054190918803215,
      0.01487184502184391,
      0.009688769467175007,
      0.03930218890309334,
      -0.06090722233057022,
      -0.02331104688346386,
      0.05385642126202583,
      -0.046879589557647705,
      0.02105560153722763,
      0.04457312449812889,
      0.009534785524010658,
      0.04033457860350609,
      -0.015431907027959824,
      -0.07430683076381683,
      0.0946139395236969,
      0.00710712606087327,
      0.05126810073852539,
      -0.009792853146791458,
      -0.01737711764872074,
      -0.01620401442050934,
      0.027158254757523537,
      -0.06632200628519058,
      -0.12753067910671234,
      -0.025557268410921097,
      -0.06607500463724136,
      0.00496063893660903,
      -0.011370018124580383,
      0.07150033861398697,
      0.05274254083633423,
      0.023531204089522362,
      0.03975149244070053,
      -0.021036041900515556,
      0.010308273136615753,
      -0.027717821300029755,
      0.030900388956069946,
      0.02268868312239647,
      -0.018742868676781654,
      -0.05862562730908394,
      -0.017206959426403046,
      -0.02707081101834774,
      -0.013714429922401905,
      0.07471629977226257,
      0.014810374937951565,
      -0.043349795043468475,
      0.05522419512271881,
      0.07125871628522873,
      -0.019922267645597458,
      0.03803817182779312,
      0.08092062175273895,
      0.02702776901423931,
      0.0979762151837349,
      -0.05079575255513191,
      -0.024740440770983696,
      -0.01955546624958515,
      -0.036348890513181686,
      0.005070650950074196,
      -0.01947406306862831,
      -0.0107495728880167,
      0.0606762059032917,
      -0.00917386170476675,
      0.007786640897393227,
      -0.02655324712395668,
      -0.0026926049031317234,
      -0.06336972862482071,
      -0.04666968807578087,
      -0.013595778495073318,
      0.015088889747858047,
      0.0026449882425367832,
      -0.035725828260183334,
      0.02122231014072895,
      -0.06731759011745453,
      -0.03384759649634361,
      -0.0034117239993065596,
      0.0723591297864914,
      0.060878366231918335,
      0.013654359616339207,
      -0.039869267493486404,
      -0.024005545303225517,
      -0.0037094345316290855,
      -0.02401549369096756,
      0.10524691641330719,
      0.0715809166431427,
      0.040192343294620514,
      0.0321718268096447,
      0.05157570540904999,
      -0.08346930891275406,
      -0.036729827523231506,
      -0.09735678881406784,
      -0.031828004866838455,
      0.003226690459996462,
      -0.03958795219659805,
      -0.04711906611919403,
      0.009555591270327568,
      -0.02071385830640793,
      0.042815156280994415,
      -0.07721835374832153,
      -0.05602698028087616,
      0.07781966030597687,
      -0.06324603408575058,
      -0.03898858278989792,
      0.0718342736363411,
      -0.031725622713565826,
      -0.11431947350502014,
      -0.006081826984882355,
      -0.0026838432531803846,
      0.018954681232571602,
      0.01785099506378174,
      0.005814414005726576,
      -0.055040474981069565,
      -0.030697213485836983,
      -0.026419861242175102,
      0.00622818898409605,
      0.004972249735146761,
      -0.02671627327799797,
      0.08222538232803345,
      -0.01762785203754902,
      -0.010675677098333836,
      -0.17944428324699402,
      -0.012582732364535332,
      -0.052978698164224625,
      -0.038241732865571976,
      -0.10404006391763687,
      0.022477135062217712,
      -0.026119597256183624,
      0.045740220695734024,
      0.005202144384384155,
      0.06997548788785934,
      0.005671149119734764,
      -0.01605142652988434,
      0.018875546753406525,
      0.04442773759365082,
      -0.045671768486499786,
      0.00828400906175375,
      -0.05726568400859833,
      0.017228806391358376,
      -0.04178857430815697,
      -0.06904991716146469,
      0.011164555326104164,
      0.05093963444232941,
      0.02011045813560486,
      0.05135507136583328,
      0.025531599298119545,
      0.04174215346574783,
      0.024077648296952248,
      0.05174601823091507,
      -0.002675482537597418,
      0.026410670951008797,
      -0.0055652097798883915,
      0.003458340186625719,
      -0.030369501560926437,
      0.007180056069046259,
      -0.004761544056236744,
      -0.022248119115829468,
      0.0165818203240633,
      0.023316077888011932,
      0.022930661216378212,
      0.03205578774213791,
      -0.04182874411344528,
      0.0002472173946443945,
      0.012871949002146721,
      -0.015552648343145847,
      0.014256538823246956,
      -0.04690190404653549,
      0.0002340467181056738,
      -0.06061047315597534,
      -0.07674003392457962,
      -0.017245521768927574,
      -0.004780958406627178,
      -0.04135701805353165,
      0.02977042831480503,
      0.020880982279777527,
      0.015560240484774113,
      0.013697772286832333,
      -0.026319723576307297,
      0.03330947831273079,
      -0.04787683114409447,
      -0.07694078981876373,
      -0.06429857015609741,
      0.031759489327669144,
      0.06407511234283447,
      0.050367411226034164,
      -0.04056858271360397,
      0.017107289284467697,
      0.08368702977895737,
      0.0777122750878334,
      -0.011096954345703125,
      -0.0737774521112442,
      -0.020072972401976585,
      -0.009735825471580029,
      0.06906868517398834,
      0.08754415065050125,
      0.05779612436890602,
      0.01665959507226944,
      -0.031561121344566345,
      0.05498404800891876,
      -0.03706231713294983,
      -0.06395233422517776,
      -0.03315432369709015,
      -0.08812171220779419,
      0.053375545889139175,
      0.024041615426540375,
      0.058889493346214294,
      -0.04236606881022453,
      -0.02134212665259838,
      -0.00467162299901247,
      -0.0982261598110199,
      0.03291873633861542,
      -0.02962607890367508,
      0.029120059683918953,
      0.021437102928757668,
      0.012773658148944378,
      -0.05266023054718971,
      -0.004333906807005405,
      0.08006496727466583,
      -0.06980520486831665,
      -0.013961588963866234,
      -0.09506256878376007,
      -0.03555171564221382,
      0.009938566014170647,
      0.09878052771091461,
      0.02554539404809475,
      -0.07158495485782623,
      0.009251770563423634,
      0.00533765833824873,
      0.007772945798933506,
      -0.004556664731353521,
      0.013808955438435078,
      0.01750611513853073,
      0.09890209138393402,
      -0.0023048019502311945,
      0.032969437539577484,
      0.05416540056467056,
      -0.026352250948548317,
      0.0019693616777658463,
      -0.011345149017870426,
      -0.030330559238791466,
      -0.010276421904563904,
      -0.011562553234398365,
      0.07583168894052505,
      0.060445450246334076,
      -0.060224853456020355,
      0.01254329178482294,
      0.013803967274725437,
      -0.06044580787420273,
      0.005801091901957989,
      -0.02218589000403881,
      0.05042051896452904,
      -0.06136499345302582,
      0.008189103566110134,
      -0.0028765785973519087,
      0.001700585475191474,
      0.04727107658982277,
      -0.013303665444254875,
      0.10777241736650467,
      0.05184280127286911,
      -0.09721466898918152,
      -0.04505884274840355,
      0.09630991518497467,
      0.019501684233546257,
      -0.013849589973688126,
      0.0544712170958519,
      0.018859047442674637,
      -0.004210671875625849,
      -0.013751707971096039,
      -0.01436302438378334,
      -0.051786620169878006,
      0.042105015367269516,
      -0.0049063959158957005,
      -0.07123985886573792,
      -0.09496203809976578,
      0.01351573970168829,
      0.06993506848812103,
      0.06395193934440613,
      0.05358307808637619,
      0.030679361894726753,
      0.0611417330801487,
      0.0062653846107423306,
      0.012085462920367718,
      0.015974588692188263,
      -0.012865970842540264,
      0.0057436577044427395,
      -0.02905867248773575,
      -0.00191887270193547,
      0.009266631677746773,
      -0.044259343296289444,
      -0.04596780240535736,
      0.011870590038597584,
      -0.03976437449455261,
      0.026418566703796387,
      -0.07534077018499374,
      -0.009682190604507923,
      -0.005584965460002422,
      -0.1076958179473877,
      0.11523398011922836,
      0.003914104774594307,
      0.03581320494413376,
      0.06082334369421005,
      -0.054521143436431885,
      -0.03138319030404091,
      -0.009700954891741276,
      0.028101136907935143,
      0.053311172872781754,
      0.02474600449204445,
      -0.02999011054635048,
      0.016891388222575188,
      -0.030547481030225754,
      0.07144820690155029,
      -0.045603640377521515,
      0.10601238161325455,
      0.05801495537161827,
      -0.06791169941425323,
      -0.017599010840058327,
      0.025145523250102997,
      0.037708546966314316,
      0.031478576362133026,
      -0.001477119978517294,
      0.03893022611737251,
      0.01888420432806015,
      -0.00825174804776907,
      0.002668142318725586,
      0.0024814577773213387,
      0.04805193096399307,
      0.034289710223674774,
      0.01757808029651642,
      -0.03219938650727272,
      -0.01026580948382616,
      0.0360962375998497,
      -0.025201594457030296,
      -0.01010309997946024,
      0.012901704758405685,
      0.00914022233337164,
      0.011120546609163284,
      0.0009003939921967685,
      -0.02655106596648693,
      -0.01745736412703991,
      -0.008194809779524803,
      0.029406951740384102,
      -0.015621312893927097,
      0.01171843707561493,
      0.010780541226267815,
      0.06391613930463791,
      -0.07679324597120285,
      -0.07506529241800308,
      0.08461369574069977,
      -0.030802208930253983,
      -0.04242144152522087,
      -0.006435711868107319,
      -0.016237542033195496,
      -0.0874154269695282,
      0.049377571791410446,
      -0.09181541204452515,
      0.01222701370716095,
      0.030733708292245865,
      0.02933698147535324,
      -0.0491047278046608,
      0.02570420876145363,
      -0.007796189747750759,
      -0.04551218822598457,
      0.007265252992510796,
      0.032759230583906174,
      0.016472984105348587,
      0.014653874561190605,
      -0.017071353271603584,
      0.04766085743904114,
      -0.012883164919912815,
      0.01684100739657879,
      -0.0202762633562088,
      -0.013123854994773865,
      0.07795540988445282,
      0.027155248448252678,
      -0.018232829868793488,
      0.022095991298556328,
      0.04297822713851929
    ],
    [
      0.00046651612501591444,
      -0.03902018442749977,
      -0.04561317339539528,
      0.021930893883109093,
      0.04925348237156868,
      -0.04834630712866783,
      -0.06102422624826431,
      -0.05790727585554123,
      -0.07738719880580902,
      0.018638482317328453,
      -0.022102313116192818,
      0.03015439212322235,
      -0.01846279203891754,
      -0.0013120754156261683,
      0.034424252808094025,
      -0.018471570685505867,
      -0.07071161270141602,
      0.07401806861162186,
      0.03751982003450394,
      -0.03620452806353569,
      0.04235014691948891,
      -0.050977032631635666,
      -0.05419066175818443,
      -0.03822331503033638,
      -0.0040668947622179985,
      0.011393466033041477,
      -0.026090402156114578,
      0.04368632659316063,
      -0.04907310754060745,
      -0.11816807091236115,
      0.020551595836877823,
      -0.05278824269771576,
      0.024768609553575516,
      0.032442521303892136,
      -0.10281285643577576,
      0.007657638285309076,
      -0.0018296994967386127,
      0.0537298284471035,
      0.05745849385857582,
      -0.020766770467162132,
      -0.08642082661390305,
      -0.015499900095164776,
      0.008241624571383,
      -0.07511700689792633,
      -0.026204751804471016,
      0.033201660960912704,
      0.06611192226409912,
      0.005107177887111902,
      0.012335718609392643,
      0.06559387594461441,
      0.04901541396975517,
      0.09444896131753922,
      0.014678986743092537,
      -0.0734633132815361,
      0.002533144084736705,
      -0.028687652200460434,
      0.09223634749650955,
      -0.0032924748957157135,
      -0.020515281707048416,
      0.07413104921579361,
      -0.021296314895153046,
      -0.014022666960954666,
      0.006435031071305275,
      -0.005342262331396341,
      -0.07213526964187622,
      0.04605774208903313,
      0.04439157620072365,
      0.055537283420562744,
      -0.036844804883003235,
      -0.09636826068162918,
      0.013392033986747265,
      -0.034189704805612564,
      0.010276319459080696,
      0.004893438890576363,
      0.09554558992385864,
      -0.026679612696170807,
      -0.09167005121707916,
      -0.0668909102678299,
      -0.022277725860476494,
      -0.00881262682378292,
      0.024919435381889343,
      0.00570883322507143,
      0.034266095608472824,
      0.020905951038002968,
      -0.05147378891706467,
      -0.0754253938794136,
      0.02125665545463562,
      -0.07513907551765442,
      0.00737776281312108,
      -0.07109987735748291,
      0.058763790875673294,
      0.001066599041223526,
      -0.0008966750465333462,
      -0.012302360497415066,
      -0.014790241606533527,
      0.015960562974214554,
      0.05724485591053963,
      -0.023413073271512985,
      -0.0824931189417839,
      -0.06843897700309753,
      0.013219046406447887,
      -0.07791872322559357,
      0.007440096698701382,
      0.0478195883333683,
      0.015281444415450096,
      0.05614161491394043,
      4.73603795398958e-05,
      -0.01243410911411047,
      -0.010093368589878082,
      -0.022053910419344902,
      -0.04586048051714897,
      -0.06169184669852257,
      -0.028637785464525223,
      0.017411066219210625,
      -0.022277839481830597,
      0.06579221040010452,
      -0.012654721736907959,
      -0.06597607582807541,
      0.006232712417840958,
      0.06870187819004059,
      -0.051581017673015594,
      -0.03972354158759117,
      0.037729933857917786,
      0.025366583839058876,
      -0.03234981372952461,
      -0.012643357738852501,
      0.007616670336574316,
      -0.044243454933166504,
      0.023208990693092346,
      -0.04838498309254646,
      0.06003045290708542,
      0.022707315161824226,
      -0.03132585808634758,
      -0.10388416051864624,
      -0.06331916898488998,
      -0.04198017343878746,
      -0.021322546526789665,
      -0.0744815394282341,
      -0.027513517066836357,
      0.06337656080722809,
      0.06537959724664688,
      0.048636745661497116,
      0.03915921226143837,
      -0.0052421619184315205,
      0.02878890000283718,
      0.044280264526605606,
      0.020451948046684265,
      -0.06386866420507431,
      0.07855802774429321,
      0.016586005687713623,
      -0.05711846426129341,
      0.05061376467347145,
      -0.0135275823995471,
      -0.0321074053645134,
      -0.06923629343509674,
      0.046601369976997375,
      -0.0542614571750164,
      -0.0367991104722023,
      0.05969882383942604,
      -0.05193031579256058,
      0.016031138598918915,
      0.016565341502428055,
      0.010370150208473206,
      -0.003904976649209857,
      -0.011467015370726585,
      -0.040643397718667984,
      0.03129661828279495,
      -0.12038332968950272,
      0.031402770429849625,
      -0.04131166264414787,
      0.059859953820705414,
      -0.02964186854660511,
      -0.04020810127258301,
      0.00852032471448183,
      0.014029530808329582,
      -0.019803190603852272,
      -0.042592890560626984,
      -0.04786229878664017,
      0.07035891711711884,
      0.009403586387634277,
      -0.0439799427986145,
      0.06503897160291672,
      -0.03477533906698227,
      -0.015568672679364681,
      -0.001194542390294373,
      -0.005472125019878149,
      -0.06937530636787415,
      -0.05900895968079567,
      -0.04773014038801193,
      0.056924208998680115,
      -0.01453709602355957,
      0.06644038110971451,
      0.0728669986128807,
      0.059255022555589676,
      -0.020703652873635292,
      0.06166033074259758,
      0.021249663084745407,
      0.010731079615652561,
      0.0262644924223423,
      0.055995237082242966,
      0.03892609849572182,
      0.038770005106925964,
      -0.039598241448402405,
      -0.03277187421917915,
      -0.0002830768353305757,
      0.05598888918757439,
      0.034072503447532654,
      0.08484341949224472,
      0.014551971107721329,
      0.027723124250769615,
      0.02855333872139454,
      0.036271367222070694,
      -0.026170194149017334,
      -0.033280130475759506,
      0.053294580429792404,
      -0.07842618227005005,
      0.08023401349782944,
      -0.1415441632270813,
      0.0451754629611969,
      0.03150704503059387,
      -0.0773874819278717,
      0.046144720166921616,
      0.06716419756412506,
      -0.0620039626955986,
      -0.04863118752837181,
      -0.011844480410218239,
      -0.053137265145778656,
      -0.025128746405243874,
      0.04987739399075508,
      -0.023748088628053665,
      0.04187995195388794,
      -0.017656631767749786,
      0.02863202430307865,
      -0.0158198531717062,
      -0.0031226312275975943,
      0.028889775276184082,
      0.015086857602000237,
      0.03205200284719467,
      0.040509097278118134,
      -0.031985994428396225,
      -0.06687785685062408,
      -0.04089811444282532,
      -0.04690047353506088,
      -0.03790424019098282,
      0.014557953923940659,
      -0.02057611756026745,
      -0.06743496656417847,
      -0.06316041201353073,
      -0.03242078050971031,
      0.10228175669908524,
      -0.01553259789943695,
      -0.02126297727227211,
      -0.013638460077345371,
      0.007511403411626816,
      -0.06972267478704453,
      0.033114753663539886,
      -0.03249343857169151,
      0.022412125021219254,
      -0.04373335838317871,
      0.024059632793068886,
      -0.027018874883651733,
      -0.054598368704319,
      -0.0010192390764132142,
      -0.03176458925008774,
      -0.01553861703723669,
      -0.005206718575209379,
      -0.021201102063059807,
      0.0748278945684433,
      -0.06325938552618027,
      0.055712319910526276,
      0.02594214305281639,
      -0.002120097866281867,
      0.03704283758997917,
      0.0012356890365481377,
      0.015227015130221844,
      0.009682288393378258,
      -0.023112472146749496,
      -0.050918255001306534,
      -0.0129186837002635,
      -0.014852170832455158,
      0.011802883818745613,
      0.023533746600151062,
      0.012604660354554653,
      0.10833003371953964,
      0.0851229727268219,
      -0.04809439927339554,
      0.007380057126283646,
      -0.04561355337500572,
      0.02781089022755623,
      -0.04897494614124298,
      0.04650130495429039,
      0.02626941166818142,
      -0.08312010765075684,
      -0.04623803868889809,
      -0.04530710354447365,
      0.04411089047789574,
      0.047679074108600616,
      -0.042584169656038284,
      -0.021344918757677078,
      0.02045607939362526,
      0.06664668768644333,
      -0.037685949355363846,
      0.004575975704938173,
      0.03281116485595703,
      0.00640125572681427,
      -0.02033376321196556,
      0.06864769011735916,
      0.005987236276268959,
      -0.06969504058361053,
      0.11548160761594772,
      -0.04308151826262474,
      0.001412463141605258,
      0.019418392330408096,
      0.07375679165124893,
      -0.027010878548026085,
      0.028801552951335907,
      -0.06845101714134216,
      -0.012632436119019985,
      0.06338103115558624,
      0.015818558633327484,
      0.0377703495323658,
      0.027365831658244133,
      -0.02249150350689888,
      -0.007121022325009108,
      -0.09789583832025528,
      0.035700179636478424,
      -0.01604582741856575,
      -0.014474932104349136,
      -0.07143674045801163,
      0.01229621097445488,
      0.04327132925391197,
      -0.006568489596247673,
      0.014946470968425274,
      -0.026413511484861374,
      0.03492375463247299,
      0.024159781634807587,
      -0.04598580300807953,
      0.008609205484390259,
      0.025020627304911613,
      -0.0035522514954209328,
      0.025446612387895584,
      0.018144667148590088,
      -0.05322480946779251,
      0.03818821161985397,
      0.034247320145368576,
      -0.08890615403652191,
      -0.0292409248650074,
      -0.019405756145715714,
      -0.020164644345641136,
      -0.02183588221669197,
      -0.06643841415643692,
      7.878559699747711e-05,
      0.04982718825340271,
      -0.02346418797969818,
      0.05245557054877281,
      0.00746370293200016,
      -0.05095084011554718,
      -0.000934210023842752,
      -0.00024401614791713655,
      -0.02576085925102234,
      -0.02973552606999874,
      0.04964638873934746,
      -0.02590174600481987,
      0.002171338303014636,
      0.017162518575787544,
      -0.014406572096049786,
      -0.019993234425783157,
      -0.025880679488182068,
      -0.04880291968584061,
      0.07785482704639435,
      -0.007176462560892105,
      0.025078682228922844,
      0.027694683521986008,
      0.0006932744290679693,
      -0.022089658305048943,
      0.06612226366996765,
      -0.03211091458797455,
      0.03204869478940964,
      -0.020354125648736954,
      0.05060756951570511,
      -0.0561983659863472,
      0.0073424577713012695,
      0.010227889753878117,
      -0.06149729713797569,
      0.058653250336647034,
      -0.01282523199915886,
      -0.04626460745930672,
      0.036999549716711044,
      -0.04151241108775139,
      -0.018645398318767548,
      0.04400571063160896,
      -0.05604226887226105,
      -0.02207365818321705,
      0.014225100167095661,
      0.012667614035308361,
      -0.0023375372402369976,
      0.027688004076480865,
      -0.00998641550540924,
      0.03137654438614845,
      0.04410756379365921,
      0.02293987013399601,
      0.04321720078587532,
      0.1291176676750183,
      -0.04171986132860184,
      -0.038046617060899734,
      0.09598620235919952,
      -0.04443424940109253,
      0.09712043404579163,
      -0.0294234249740839,
      -0.021062297746539116,
      0.07098742574453354,
      -0.022451750934123993,
      0.1005043238401413,
      0.08260013163089752,
      0.014117836020886898,
      0.019899241626262665,
      0.02508801594376564,
      -0.07173777371644974,
      -0.0077727544121444225,
      0.02272898517549038,
      -0.07659587264060974,
      -0.054728347808122635,
      0.030274730175733566,
      -0.009353622794151306,
      0.04281710833311081,
      0.029422439634799957,
      -0.04290350526571274,
      0.0085092568770051,
      -0.018698502331972122,
      -0.013888719491660595,
      -0.059992022812366486,
      -0.024122493341565132,
      0.00834876112639904,
      -0.004401292186230421,
      -0.01561831682920456,
      -0.015441402792930603,
      0.07135926932096481,
      -0.06998749822378159,
      -0.03925015404820442,
      0.08423163741827011,
      0.005542755592614412,
      -0.04344330355525017,
      0.026167266070842743,
      -0.017750367522239685,
      -0.01077263429760933,
      0.018644876778125763,
      0.01549503393471241,
      -0.04190440848469734,
      -0.05112127214670181,
      -0.02426474541425705,
      -0.07750777900218964,
      0.030470211058855057,
      0.02091773971915245,
      -0.003831995651125908,
      0.007271451875567436,
      0.07958406209945679,
      -0.03136129677295685,
      0.054369885474443436,
      0.030951952561736107,
      -0.01416433323174715,
      -0.025908611714839935,
      0.015328320674598217,
      0.04296128824353218,
      -0.07246392965316772,
      0.02210059016942978,
      0.05508044734597206,
      0.04821619763970375,
      0.0007969645666889846,
      -0.04879949614405632,
      0.032914306968450546,
      0.002157130977138877,
      -0.02350502647459507,
      -0.05383763462305069,
      -0.03894280642271042,
      -0.010015413165092468,
      0.1024070754647255,
      0.04733439162373543,
      -0.028248075395822525,
      0.005562364589422941,
      0.024025291204452515,
      0.009301564656198025,
      -0.01580081135034561,
      -0.015251430682837963,
      -0.0019919006153941154,
      0.009084715507924557,
      -0.013515155762434006,
      -0.0425298698246479,
      -0.0775337666273117,
      0.05033126473426819,
      0.04885024204850197,
      0.00717623857781291,
      0.026491759344935417,
      0.05861947312951088,
      0.07546333223581314,
      0.0028881810139864683,
      -0.052564170211553574,
      -0.052578069269657135,
      -0.08207639306783676,
      -0.021099954843521118,
      0.05740330368280411,
      0.02017928659915924,
      -0.007214777171611786,
      0.028164153918623924
    ],
    [
      0.06741759926080704,
      0.050475217401981354,
      0.04832204058766365,
      0.0031680120155215263,
      0.14733627438545227,
      0.03284860774874687,
      0.08909612149000168,
      -0.016979210078716278,
      -0.028650734573602676,
      0.05509436875581741,
      0.008712388575077057,
      0.012619199231266975,
      -0.031072763726115227,
      0.031176235526800156,
      -0.025861887261271477,
      0.026864951476454735,
      -9.028955537360162e-05,
      -0.013451418839395046,
      0.03057618997991085,
      -0.037844929844141006,
      0.03455224260687828,
      -0.013444970361888409,
      0.07970159500837326,
      -0.04777516797184944,
      -0.0020630129147320986,
      0.04906235635280609,
      -0.006987530272454023,
      -0.01840800605714321,
      -0.025416018441319466,
      0.022893767803907394,
      0.006993535906076431,
      -0.05708546191453934,
      -0.05511928349733353,
      0.042002685368061066,
      -0.02581760473549366,
      0.045017268508672714,
      -0.02477644570171833,
      0.01959919184446335,
      0.02859812043607235,
      0.03680218756198883,
      0.038120608776807785,
      -0.09468433260917664,
      -0.03586827963590622,
      0.0520535372197628,
      -0.05572640150785446,
      -0.07717859745025635,
      0.033851999789476395,
      -0.025102835148572922,
      0.00933345127850771,
      0.01735345646739006,
      -0.0538906566798687,
      0.05496891960501671,
      -0.06414058059453964,
      0.06741749495267868,
      -0.03130904957652092,
      -0.001217485056258738,
      0.09099186211824417,
      0.03669333457946777,
      0.01578730158507824,
      -0.021093375980854034,
      -0.06255517154932022,
      0.02846495993435383,
      -0.049018312245607376,
      0.057536184787750244,
      -0.03096725046634674,
      0.05580433830618858,
      0.013312422670423985,
      0.06684862822294235,
      -0.013688681647181511,
      0.0030138788279145956,
      0.03284904733300209,
      0.05902468413114548,
      0.07022326439619064,
      -0.08888447284698486,
      -0.020093481987714767,
      0.046314436942338943,
      0.03975042328238487,
      -0.006289318203926086,
      -0.06306841969490051,
      -0.0957539901137352,
      0.0027309167198836803,
      0.01537652313709259,
      0.09002503007650375,
      0.01485047023743391,
      0.01477307640016079,
      -0.013549858704209328,
      -0.0554325208067894,
      0.06163183972239494,
      -0.025978587567806244,
      0.07744242250919342,
      0.0011531536001712084,
      0.013673976995050907,
      -0.08034912496805191,
      -0.04229126125574112,
      0.036285802721977234,
      0.052080534398555756,
      -0.033911753445863724,
      -0.00920209288597107,
      -0.037711359560489655,
      -0.059109512716531754,
      0.04514672979712486,
      -0.13695821166038513,
      0.012858428061008453,
      -0.0033787572756409645,
      -0.1000920981168747,
      0.0328521691262722,
      -0.039244018495082855,
      0.04446251690387726,
      -0.040128253400325775,
      -0.04580255225300789,
      0.02429943159222603,
      -0.07401074469089508,
      -0.08381856232881546,
      0.057123322039842606,
      -0.0019353301031515002,
      -0.038079533725976944,
      -0.04330147057771683,
      -0.022532863542437553,
      -0.01713169366121292,
      0.03490833193063736,
      0.027427587658166885,
      -0.0021942737512290478,
      0.012364947237074375,
      -0.05406945198774338,
      0.026438256725668907,
      -0.009766110219061375,
      0.0728159174323082,
      0.013685627840459347,
      -0.031338274478912354,
      0.06866038590669632,
      0.05160955339670181,
      0.0954771339893341,
      -0.010298578068614006,
      -0.023351503536105156,
      -0.030011819675564766,
      -0.06443745642900467,
      -0.008876101113855839,
      -0.050317246466875076,
      -0.03407611325383186,
      0.0886833444237709,
      -0.035619061440229416,
      0.0024319859221577644,
      0.021886447444558144,
      -0.017354732379317284,
      -0.02427501790225506,
      -0.03518266975879669,
      0.007718968205153942,
      -0.027276428416371346,
      -0.046909917145967484,
      -0.012819146737456322,
      0.025997702032327652,
      0.02728929929435253,
      -0.045523982495069504,
      -0.05491695553064346,
      -0.006709056440740824,
      0.027237119153141975,
      -0.03915823996067047,
      -0.004483428783714771,
      -0.025281639769673347,
      0.002987463492900133,
      0.06781091541051865,
      0.020277272909879684,
      0.03353416174650192,
      0.00919610820710659,
      0.016248775646090508,
      -0.012311016209423542,
      -0.02534942887723446,
      0.04650324210524559,
      0.018999965861439705,
      0.08500545471906662,
      0.0026233794633299112,
      0.08965922892093658,
      -0.014681199565529823,
      0.01256292499601841,
      -0.018503988161683083,
      -0.03630447760224342,
      0.0016079420456662774,
      0.04981974512338638,
      0.005469697527587414,
      -0.011438333429396152,
      0.006718313787132502,
      -0.020946813747286797,
      0.037150170654058456,
      0.03937685117125511,
      0.08943990617990494,
      -0.04452868178486824,
      0.02566373720765114,
      -0.03845503553748131,
      0.05008639022707939,
      -0.046458374708890915,
      0.03976059705018997,
      0.003390032332390547,
      0.04288046807050705,
      -0.031248051673173904,
      0.006461583077907562,
      0.008598251268267632,
      0.033788517117500305,
      -0.052590951323509216,
      -0.02146022766828537,
      -0.004667728673666716,
      -0.04184025526046753,
      -0.0024052427615970373,
      0.06833257526159286,
      0.01993778720498085,
      -0.022278327494859695,
      -0.007183585781604052,
      0.006189896259456873,
      0.031929705291986465,
      -0.05530039593577385,
      -0.04874059185385704,
      -0.0006983691127970815,
      0.004396980162709951,
      0.03376825153827667,
      -0.001559963682666421,
      0.01791730895638466,
      -0.08070223033428192,
      -0.03600822389125824,
      -0.033939801156520844,
      0.06211993098258972,
      -0.03887666389346123,
      0.0010688970796763897,
      0.16830387711524963,
      -0.0007443132344633341,
      -0.09879110008478165,
      0.008073114790022373,
      0.00015224635717459023,
      0.007372604217380285,
      0.03341701999306679,
      -0.08228231966495514,
      0.04873859882354736,
      0.061327267438173294,
      0.03163059055805206,
      -0.011690872721374035,
      0.010721833445131779,
      0.0082238195464015,
      -0.028555789962410927,
      -0.014118432067334652,
      0.012722212821245193,
      0.022838762030005455,
      -0.014769327826797962,
      0.06990580260753632,
      0.06824082881212234,
      -0.062401387840509415,
      -0.018833955749869347,
      0.02312942035496235,
      0.07011852413415909,
      -0.022276023402810097,
      -0.04193729907274246,
      0.04477783665060997,
      -0.03818443790078163,
      -0.05606488138437271,
      0.05954036861658096,
      -0.02480236440896988,
      -0.007265947759151459,
      0.020327581092715263,
      0.06637770682573318,
      -0.049693163484334946,
      -0.04641352966427803,
      -0.0762905478477478,
      -0.12151870131492615,
      -0.04363019019365311,
      0.00025083389482460916,
      0.02652091160416603,
      0.09045856446027756,
      0.052522897720336914,
      0.009588418528437614,
      -0.00988033413887024,
      0.006538336630910635,
      -0.006023496855050325,
      -0.1005295068025589,
      0.04427680745720863,
      0.0634501650929451,
      0.02520894445478916,
      0.03996574878692627,
      -0.02171657793223858,
      0.07822228223085403,
      0.03430533409118652,
      0.037602778524160385,
      0.01114368624985218,
      -0.04272967576980591,
      -0.016392111778259277,
      -0.04883682355284691,
      0.003034393535926938,
      0.005811958108097315,
      0.006256066728383303,
      0.0779174193739891,
      0.010026481933891773,
      0.07945504039525986,
      -0.07571160048246384,
      -0.0488145686686039,
      0.029474686831235886,
      0.11405730992555618,
      -0.010625365190207958,
      0.06543847173452377,
      -0.06741636246442795,
      -0.012787532992661,
      -0.009560235776007175,
      0.05453234538435936,
      0.027196472510695457,
      0.051545292139053345,
      -0.023396426811814308,
      0.023734550923109055,
      -0.032523591071367264,
      -0.030065743252635002,
      -0.026166915893554688,
      0.02460712008178234,
      -0.06353067606687546,
      0.0471390038728714,
      -0.02477165311574936,
      0.011155879124999046,
      0.03367838263511658,
      -0.0619288869202137,
      0.01405390165746212,
      -0.07528436928987503,
      -0.04523087665438652,
      0.013823173940181732,
      0.08203590661287308,
      -0.004285312723368406,
      0.06080157682299614,
      -0.04360910877585411,
      0.020634500309824944,
      0.03825923427939415,
      -0.01456578727811575,
      -0.032428547739982605,
      0.05504348874092102,
      0.07831324636936188,
      0.003658300032839179,
      0.039872054010629654,
      -3.3501033612992615e-05,
      0.004956156015396118,
      0.00019863345369230956,
      0.06481125950813293,
      0.03453736752271652,
      0.019760577008128166,
      -0.03282583877444267,
      0.017037516459822655,
      -0.02094346471130848,
      -0.058389581739902496,
      0.07023997604846954,
      0.048101503401994705,
      0.008305083960294724,
      -0.03219909220933914,
      0.05141238495707512,
      -0.006398336496204138,
      -0.10553006827831268,
      -0.02098173089325428,
      -0.06524332612752914,
      0.023941082879900932,
      -0.02221667394042015,
      0.0305896308273077,
      -0.026246538385748863,
      0.012347066774964333,
      0.07474814355373383,
      0.00040703153354115784,
      -0.01301781926304102,
      -0.09612628072500229,
      0.022686854004859924,
      0.016856210306286812,
      -0.06250044703483582,
      0.005495680030435324,
      -0.05809267610311508,
      0.024462122470140457,
      -0.061559997498989105,
      -0.0022942018695175648,
      0.005592123605310917,
      0.00796893797814846,
      0.05434885993599892,
      -0.051137637346982956,
      0.00922316126525402,
      0.06652026623487473,
      -0.04572445526719093,
      -0.003842173144221306,
      -0.01187052670866251,
      -0.03847784921526909,
      -0.05752940848469734,
      -0.005489368923008442,
      -0.08274032175540924,
      -0.03770815208554268,
      0.016415540128946304,
      0.01948315091431141,
      -0.003541518934071064,
      0.05166786164045334,
      0.000749561411794275,
      0.022396793588995934,
      -0.03020741231739521,
      0.02922893688082695,
      -0.016789374873042107,
      0.059944674372673035,
      -0.022636210545897484,
      0.05079902708530426,
      -0.007103284355252981,
      0.09977532178163528,
      -0.06382430344820023,
      -0.0450638085603714,
      0.00430665910243988,
      0.005934676621109247,
      0.018862877041101456,
      -0.06811827421188354,
      0.08688878268003464,
      -0.017874008044600487,
      0.06302638351917267,
      0.014746243134140968,
      -0.03891640156507492,
      -0.02709854207932949,
      -0.04695809632539749,
      0.013629700057208538,
      -0.042385902255773544,
      -0.12778636813163757,
      -0.0247048307210207,
      -0.10794514417648315,
      0.0629531517624855,
      -0.10205550491809845,
      -0.09399880468845367,
      0.002186097204685211,
      0.014132427982985973,
      0.008033857680857182,
      -0.08926334977149963,
      0.0012488701613619924,
      0.060122352093458176,
      -0.002715826965868473,
      0.056105755269527435,
      0.05892495810985565,
      -0.021256808191537857,
      -0.009775978513062,
      0.03596915677189827,
      -0.010177710093557835,
      0.036245353519916534,
      0.047225259244441986,
      -0.0222796443849802,
      0.024919986724853516,
      -0.007117409724742174,
      0.058300599455833435,
      -0.03470804914832115,
      -0.029652142897248268,
      0.00690539600327611,
      -0.040739912539720535,
      0.03937726467847824,
      0.0521710067987442,
      -0.0071127042174339294,
      0.028180215507745743,
      0.016040775924921036,
      0.04920761659741402,
      -0.049632634967565536,
      -0.003098716028034687,
      -0.04185008630156517,
      0.029643263667821884,
      -0.014618922956287861,
      -0.07478321343660355,
      0.014731054194271564,
      -0.01215831283479929,
      0.024809259921312332,
      -0.08812491595745087,
      -0.04298771545290947,
      -0.03179095685482025,
      0.02791370078921318,
      -0.0010985865956172347,
      -0.020249489694833755,
      -0.04340285807847977,
      0.06573397666215897,
      -0.08515778183937073,
      0.04601021856069565,
      0.08407910168170929,
      0.034827105700969696,
      -0.021509617567062378,
      -0.0389394573867321,
      -0.010289168916642666,
      -0.03490683063864708,
      0.020558878779411316,
      0.059311266988515854,
      0.029873428866267204,
      0.04270258545875549,
      -0.039277032017707825,
      -0.0040453351102769375,
      0.09063313156366348,
      -0.04533771052956581,
      0.012593040242791176,
      -0.03479033336043358,
      -0.022732991725206375,
      -0.03671297803521156,
      0.04814958572387695,
      0.044040706008672714,
      -0.04032008349895477,
      0.011266719549894333,
      -0.0475136898458004,
      -0.005379800219088793,
      -0.06966046243906021,
      0.05488849803805351,
      -0.05062571167945862,
      0.02864180877804756,
      0.004202245268970728,
      0.06580331176519394,
      0.06192702800035477,
      -0.01322192419320345,
      0.110586978495121,
      0.019955646246671677,
      -0.002206602366641164,
      0.03546639531850815,
      -0.06515087187290192,
      0.013218827545642853,
      -0.006228552665561438,
      0.052559562027454376,
      -0.029960835352540016,
      0.045299187302589417
    ],
    [
      0.013288223184645176,
      -0.04585958644747734,
      -0.0026238791178911924,
      0.010009553283452988,
      0.0032482612878084183,
      0.05945101007819176,
      0.03061768040060997,
      0.03790288791060448,
      -0.0471019484102726,
      -0.003135344246402383,
      -0.030969372019171715,
      -0.12172812223434448,
      -0.06562206894159317,
      -0.010981391184031963,
      0.018595870584249496,
      0.05776076018810272,
      -0.02169264480471611,
      0.03490361198782921,
      0.00010043267684523016,
      0.041922427713871,
      -0.007072658743709326,
      0.011412222869694233,
      -0.000950490590184927,
      -0.02043289691209793,
      -0.05806168168783188,
      0.012885376811027527,
      0.04205548018217087,
      -0.025590553879737854,
      0.026817604899406433,
      -0.08384065330028534,
      -0.03415907919406891,
      0.03280104324221611,
      -0.13769091665744781,
      0.03992818668484688,
      0.014133780263364315,
      0.0023112960625439882,
      0.07408080995082855,
      0.013122082687914371,
      0.016598157584667206,
      -0.07651763409376144,
      0.055423133075237274,
      -0.00531055498868227,
      0.06047064810991287,
      -0.01602073572576046,
      0.01818486675620079,
      -0.012072240933775902,
      0.029103955253958702,
      -0.0023749733809381723,
      0.03975250571966171,
      0.0012103862827643752,
      0.03201062232255936,
      0.027880072593688965,
      0.02915150299668312,
      0.058027636259794235,
      -0.05446023866534233,
      -0.09811987727880478,
      0.028543010354042053,
      -0.006345256697386503,
      0.11543313413858414,
      0.018564218655228615,
      -0.009960821829736233,
      -0.05459792539477348,
      0.02627684362232685,
      -0.015438894741237164,
      -0.012682978995144367,
      -0.008446617051959038,
      0.020983610302209854,
      0.015427844598889351,
      0.02516106702387333,
      -0.04245762154459953,
      -0.006404736079275608,
      -0.09731750935316086,
      -0.00160123105160892,
      -0.021741047501564026,
      0.01865485869348049,
      0.04811977222561836,
      -0.03798127546906471,
      0.015260827727615833,
      -0.006921129766851664,
      -0.07229532301425934,
      -0.09956207126379013,
      -0.014727585949003696,
      -0.06451748311519623,
      -0.015156279318034649,
      0.037041615694761276,
      0.015008571557700634,
      0.015019815415143967,
      -0.005919355899095535,
      -0.050704311579465866,
      -0.0027815180364996195,
      0.0567437969148159,
      0.021509230136871338,
      0.10470674932003021,
      0.0008399689686484635,
      0.01138290949165821,
      -0.08029846101999283,
      0.0577334463596344,
      -0.07666176557540894,
      -0.029386671259999275,
      -0.12509475648403168,
      0.023815257474780083,
      0.019373595714569092,
      -0.005044296383857727,
      -0.07602116465568542,
      -0.007384598720818758,
      0.04741295054554939,
      0.03841902315616608,
      -0.004773459397256374,
      0.028518911451101303,
      -0.0065529863350093365,
      0.05789909139275551,
      0.009413791820406914,
      -0.024570204317569733,
      -0.07597720623016357,
      -0.017483726143836975,
      -0.01846461556851864,
      -0.034884028136730194,
      -0.03068937361240387,
      0.06526460498571396,
      -0.013403571210801601,
      -0.023927930742502213,
      0.025181416422128677,
      0.012173682451248169,
      -0.05622103810310364,
      0.025704873725771904,
      0.03073524311184883,
      0.018702123314142227,
      0.06596913188695908,
      0.024675438180565834,
      0.020833855494856834,
      0.014509893022477627,
      -0.05866237357258797,
      0.037774428725242615,
      -0.009421294555068016,
      -0.07288257777690887,
      0.01901455782353878,
      -0.06059962883591652,
      -0.002773821819573641,
      0.06571264564990997,
      -0.002755340887233615,
      0.05171719565987587,
      0.03294000029563904,
      0.03758420795202255,
      -0.021264705806970596,
      -0.010373711585998535,
      -0.0038194467779248953,
      0.010564032942056656,
      -0.03613067418336868,
      -0.040100082755088806,
      0.0658898875117302,
      -0.001676649902947247,
      0.05039846524596214,
      0.025648031383752823,
      -0.030720392242074013,
      0.016945552080869675,
      0.06485875695943832,
      -0.021327152848243713,
      0.03263760730624199,
      0.025989897549152374,
      0.025767892599105835,
      0.06244110316038132,
      -0.0036196785513311625,
      -0.0784485712647438,
      0.0938810259103775,
      0.02178141474723816,
      -0.008736979216337204,
      -0.0479525588452816,
      -0.04608567804098129,
      -0.1088658794760704,
      0.02266825921833515,
      0.027455568313598633,
      -0.043412212282419205,
      -0.05814042687416077,
      -0.018842468038201332,
      -0.016352729871869087,
      0.026933906599879265,
      0.004359729588031769,
      0.011816642247140408,
      0.027028176933526993,
      -0.042845405638217926,
      0.01083314884454012,
      0.06448276340961456,
      0.03778067231178284,
      -0.03358287364244461,
      -0.038019564002752304,
      0.009653029963374138,
      0.012712470255792141,
      -0.01767486333847046,
      0.005795610137283802,
      0.03563973307609558,
      0.13114993274211884,
      -0.015035607852041721,
      -0.05732753872871399,
      -0.08569533377885818,
      -0.03897399455308914,
      0.017214221879839897,
      0.026674367487430573,
      0.008416730910539627,
      0.02084353193640709,
      -0.02149955742061138,
      0.0659560039639473,
      -0.018842343240976334,
      -0.027538716793060303,
      -0.0013627044390887022,
      -0.0019048723625019193,
      0.06317659467458725,
      0.01906672865152359,
      -0.06434909999370575,
      0.052322760224342346,
      -0.0037317138630896807,
      -0.011945859529078007,
      0.0445900596678257,
      -0.026368550956249237,
      -0.054563846439123154,
      -0.00010406626097392291,
      0.08880840986967087,
      0.0009826552122831345,
      -0.013965499587357044,
      0.0006842364091426134,
      0.011543655768036842,
      -0.006145093124359846,
      0.04669013246893883,
      -0.014477727003395557,
      -0.10491255670785904,
      -0.028117824345827103,
      0.008042302913963795,
      -0.02758476883172989,
      -0.04569484665989876,
      -0.03963189199566841,
      -0.10797130316495895,
      0.033969245851039886,
      -0.04147937148809433,
      0.011814761906862259,
      0.016488883644342422,
      0.02639669179916382,
      0.034036844968795776,
      -0.022760365158319473,
      -0.10396113991737366,
      0.08822660893201828,
      0.010041006840765476,
      -0.04943081736564636,
      0.036307863891124725,
      -0.008329148404300213,
      0.0197538360953331,
      0.08543911576271057,
      0.08940279483795166,
      -0.015335746109485626,
      0.03447391837835312,
      0.015756728127598763,
      0.0015088979853317142,
      -0.017411956563591957,
      -0.04230630770325661,
      -0.015554585494101048,
      -0.06192648038268089,
      -0.034545253962278366,
      0.07143427431583405,
      -0.10391844063997269,
      -0.0022909005638211966,
      0.03941597789525986,
      -0.017641933634877205,
      0.06810995191335678,
      -0.015558397397398949,
      -0.01195912528783083,
      -0.03843240439891815,
      -0.09651534259319305,
      -0.0016397606814280152,
      -0.009970582090318203,
      0.02904721535742283,
      0.007263496518135071,
      -0.009170977398753166,
      -0.003705325536429882,
      -0.05947558209300041,
      -0.011013928800821304,
      -0.020746439695358276,
      -0.0481700524687767,
      -0.003039283910766244,
      -0.04894598200917244,
      0.14819778501987457,
      0.003880932694301009,
      0.062348417937755585,
      0.01687399297952652,
      -0.05690711736679077,
      -0.031047416850924492,
      0.035493697971105576,
      -0.06425154209136963,
      0.03598816692829132,
      -0.02884332276880741,
      0.11325143277645111,
      -0.06488024443387985,
      0.002326352521777153,
      -0.008643119595944881,
      -0.016896435990929604,
      -0.00802202895283699,
      0.002323827473446727,
      -0.04601772874593735,
      0.06591445207595825,
      -0.017555775120854378,
      -0.002613500924780965,
      -0.017520010471343994,
      0.11568526923656464,
      0.048494331538677216,
      -0.06340944021940231,
      -0.04496527463197708,
      -0.10504715144634247,
      -0.007293068338185549,
      -0.015658574178814888,
      0.027208903804421425,
      0.0013976022601127625,
      -0.027192924171686172,
      0.1070885881781578,
      0.11979111284017563,
      -0.004544348921626806,
      0.0028357107657939196,
      -0.06556395441293716,
      -0.018903998658061028,
      -0.058308713138103485,
      -0.08460653573274612,
      0.04034046083688736,
      0.10737044364213943,
      -0.0008555074455216527,
      -0.008530857041478157,
      -0.031023025512695312,
      -0.05490646883845329,
      -0.04162643849849701,
      -0.032372891902923584,
      0.002835293998941779,
      -0.11692837625741959,
      0.07169632613658905,
      0.013642453588545322,
      -0.023924855515360832,
      0.04270681366324425,
      0.0019739496055990458,
      -0.023309575393795967,
      -0.01617625169456005,
      -0.012641307897865772,
      -0.014965676702558994,
      0.0070060184225440025,
      0.0006604711525142193,
      0.038604240864515305,
      -0.02371908538043499,
      -0.020506007596850395,
      -0.05609634146094322,
      -0.043470896780490875,
      -0.041949789971113205,
      -0.0058532701805233955,
      0.003588595660403371,
      -0.09701510518789291,
      -0.018639549612998962,
      0.027575090527534485,
      -0.013317053206264973,
      0.03766879439353943,
      -0.00891463179141283,
      -0.016003871336579323,
      0.029880426824092865,
      -0.02584589086472988,
      -0.022991843521595,
      0.03283337503671646,
      -0.01265814807265997,
      0.02172018773853779,
      -0.014005531556904316,
      0.02048538625240326,
      -0.007871097885072231,
      -0.025784412398934364,
      0.0077773407101631165,
      -0.03307589888572693,
      0.03751520439982414,
      0.07711827754974365,
      -0.01796984113752842,
      0.030079485848546028,
      0.00040352382347919047,
      -0.0001459256891394034,
      -0.03752927482128143,
      -0.021090615540742874,
      -0.05096600204706192,
      0.015549559146165848,
      -0.010772253386676311,
      -0.04594112187623978,
      0.0015856404788792133,
      -0.07380950450897217,
      -0.028895583003759384,
      -0.049324881285429,
      0.024747148156166077,
      -0.08007173985242844,
      0.07072430849075317,
      0.004197681322693825,
      -0.04123521223664284,
      0.048202961683273315,
      -0.015572845935821533,
      -0.021681545302271843,
      0.04651578888297081,
      -0.037674590945243835,
      0.07160413265228271,
      -0.09086928516626358,
      0.045760367065668106,
      -0.043625008314847946,
      0.056716278195381165,
      0.003636779263615608,
      0.011299778707325459,
      0.02962658740580082,
      0.06926029175519943,
      0.04362878575921059,
      -0.0038660643622279167,
      -0.028305910527706146,
      0.07298170775175095,
      0.050967659801244736,
      0.006294237915426493,
      0.012898069806396961,
      -0.008015820756554604,
      -0.012314698658883572,
      0.027391618117690086,
      0.006938296370208263,
      -0.08315842598676682,
      -0.02337357960641384,
      -0.0058095199055969715,
      0.04063504934310913,
      0.0003834622330032289,
      0.03582022711634636,
      -0.015049455687403679,
      -0.02360284887254238,
      0.021567407995462418,
      -0.09148856997489929,
      0.04175557568669319,
      0.021082108840346336,
      0.020684627816081047,
      -0.013751079328358173,
      0.0183442160487175,
      0.041049279272556305,
      -0.05340671166777611,
      -0.01466312538832426,
      -0.002223949646577239,
      0.02351412922143936,
      -0.035674482583999634,
      -0.015358802862465382,
      -0.0684780403971672,
      -0.007498832419514656,
      -0.006411507725715637,
      0.04425136744976044,
      0.0746845155954361,
      -0.05301482975482941,
      0.018471408635377884,
      0.036939144134521484,
      0.03670848160982132,
      0.032599207013845444,
      -0.008028523065149784,
      0.005036619957536459,
      0.04562932252883911,
      -0.09677088260650635,
      0.045395299792289734,
      -0.012134889140725136,
      -0.07499223202466965,
      -0.07608509808778763,
      0.044672973453998566,
      0.0012824467848986387,
      0.04194309562444687,
      0.0655600056052208,
      -0.0686766505241394,
      0.022002829238772392,
      -0.011267774738371372,
      0.028342371806502342,
      -0.04434680938720703,
      0.011603825725615025,
      -0.06577371060848236,
      0.007048136088997126,
      0.012177785858511925,
      -0.09337723255157471,
      -0.003943605814129114,
      -0.059970512986183167,
      -0.06975352764129639,
      -0.10566504299640656,
      -0.0017403926467522979,
      -0.06456425786018372,
      -0.0574818029999733,
      0.06518535315990448,
      0.0219715628772974,
      0.021764270961284637,
      0.002034745179116726,
      0.012111964635550976,
      -0.03701453283429146,
      0.05571864917874336,
      0.051627013832330704,
      -0.011471725068986416,
      -0.057221513241529465,
      0.03077789582312107,
      -0.05453256890177727,
      0.03582221642136574,
      -0.05552739277482033,
      0.022119037806987762,
      -0.041502419859170914,
      -0.006620354484766722,
      -0.0649796575307846,
      -0.01530087273567915,
      -0.013128288090229034,
      -0.00934038870036602,
      0.048039425164461136,
      -0.01911267079412937,
      -0.09245786815881729,
      -0.021642763167619705,
      -0.08836653083562851,
      0.023253386840224266,
      -0.020728642120957375,
      -0.029602153226733208,
      -0.014652646146714687,
      0.008127891458570957
    ],
    [
      -0.004959060810506344,
      -0.004741646349430084,
      -0.02980700694024563,
      -0.053084857761859894,
      0.013668921776115894,
      -0.05884282663464546,
      0.033233802765607834,
      -0.04221361130475998,
      -0.08397470414638519,
      0.05904713273048401,
      0.02796080708503723,
      0.03192799538373947,
      -0.015829110518097878,
      0.03694598376750946,
      -0.09525108337402344,
      -0.0204221922904253,
      -0.014693711884319782,
      -0.048159610480070114,
      0.04601332172751427,
      0.08634654432535172,
      -0.017933178693056107,
      0.02696877531707287,
      0.0630163699388504,
      -0.05253421887755394,
      -0.05383160337805748,
      -0.0498293898999691,
      -0.027782335877418518,
      -0.047459036111831665,
      0.018979456275701523,
      -0.05332430079579353,
      0.013897482305765152,
      0.031032340601086617,
      0.03560496121644974,
      -0.006143548060208559,
      0.04886522516608238,
      -0.018611950799822807,
      -0.034340180456638336,
      0.05904467776417732,
      0.02547663077712059,
      -0.016307516023516655,
      0.08229038864374161,
      0.02742079272866249,
      0.001745554618537426,
      0.011269839480519295,
      0.00825435295701027,
      0.0280550979077816,
      -0.013920669443905354,
      -0.04056915268301964,
      0.00339783588424325,
      -0.11315090209245682,
      -0.012994065880775452,
      -0.01755392551422119,
      -0.010469409637153149,
      -0.059116657823324203,
      0.04999714344739914,
      -0.0151703841984272,
      0.0676945373415947,
      0.03476336598396301,
      0.007259361911565065,
      0.03290857747197151,
      0.004924593027681112,
      0.01591561734676361,
      -0.018496930599212646,
      0.01448778621852398,
      -0.009684701450169086,
      -0.03330294042825699,
      -0.10027547925710678,
      -0.009093773551285267,
      0.029575800523161888,
      0.06825786828994751,
      0.015008822083473206,
      -0.021952932700514793,
      0.12115168571472168,
      0.05928702652454376,
      -0.013867880217730999,
      -0.03949405625462532,
      -0.03657965362071991,
      0.044992007315158844,
      -0.044989682734012604,
      -0.03495573624968529,
      0.061401572078466415,
      0.03101188875734806,
      -0.05761703848838806,
      4.694792369264178e-05,
      0.08013468980789185,
      0.027344778180122375,
      0.026355978101491928,
      0.032092392444610596,
      0.025106554850935936,
      0.043442677706480026,
      0.02366628311574459,
      0.02288760617375374,
      0.03934461250901222,
      -0.03988456353545189,
      0.0029974961653351784,
      0.022061843425035477,
      0.014249555766582489,
      0.045287199318408966,
      -0.04031345993280411,
      0.02773190289735794,
      -0.003979221917688847,
      -0.012933632358908653,
      -0.014294185675680637,
      -0.040882475674152374,
      -0.08090098947286606,
      0.0689745545387268,
      0.007448624353855848,
      0.005732531659305096,
      0.03391488641500473,
      0.058566655963659286,
      0.0533575713634491,
      0.02127987891435623,
      0.09397763758897781,
      -0.005572290625423193,
      -0.015037059783935547,
      -0.03812624141573906,
      0.02546854130923748,
      0.052411630749702454,
      -0.04140612855553627,
      -0.03318655863404274,
      -0.04984589293599129,
      -0.05711542069911957,
      -0.011769035831093788,
      0.07278887927532196,
      0.0859593003988266,
      0.018522106111049652,
      -0.018563080579042435,
      -0.039362646639347076,
      -0.03790774568915367,
      -0.05232314020395279,
      0.08335398137569427,
      0.0001795881980797276,
      0.005574855487793684,
      0.030798714607954025,
      -0.058610688894987106,
      -0.020563455298542976,
      0.03565583378076553,
      0.02014448307454586,
      0.004354025237262249,
      0.07178261131048203,
      0.03354405611753464,
      0.07437454164028168,
      -0.009136420674622059,
      0.04879062622785568,
      0.018073786050081253,
      0.05009160190820694,
      -0.08740890771150589,
      -0.020794184878468513,
      -0.044524185359478,
      0.0531921423971653,
      -0.039861470460891724,
      -0.1213746964931488,
      0.02130652405321598,
      0.08177155256271362,
      -0.020530639216303825,
      0.09733561426401138,
      -0.02416275069117546,
      -0.03878171741962433,
      0.04330335557460785,
      0.009593739174306393,
      -0.001192753086797893,
      0.0048538194969296455,
      0.028174765408039093,
      0.01867368258535862,
      0.011860582046210766,
      -0.01946382038295269,
      -0.004790033679455519,
      -0.04117770493030548,
      -0.01907593011856079,
      -0.007488575764000416,
      -0.02996828593313694,
      -0.009502473287284374,
      -0.007911769673228264,
      0.05702940747141838,
      -0.02353980578482151,
      -0.01625247113406658,
      -0.023531850427389145,
      -0.025457320734858513,
      0.0622250996530056,
      0.03776371479034424,
      -0.006646968889981508,
      0.041182760149240494,
      0.0654403418302536,
      -0.015976103022694588,
      0.04392336681485176,
      -0.003178255632519722,
      -0.020678317174315453,
      0.01268000528216362,
      -0.026260271668434143,
      -0.1120886579155922,
      0.03792868182063103,
      -0.022783935070037842,
      0.028285784646868706,
      -0.06426604092121124,
      -0.06704972684383392,
      -0.01403710525482893,
      0.009100455790758133,
      -0.004317197948694229,
      0.04503125324845314,
      -0.016401441767811775,
      -0.03694245591759682,
      -0.009222812950611115,
      -0.09328366816043854,
      0.058921702206134796,
      -0.0368121974170208,
      0.013991721905767918,
      0.0023937136866152287,
      -0.10893639177083969,
      -0.008966921828687191,
      -0.030608370900154114,
      0.006739321164786816,
      0.11741799116134644,
      0.005330533254891634,
      -0.013993698172271252,
      -0.07942473888397217,
      -0.07829692214727402,
      0.09687488526105881,
      -0.06958528608083725,
      -0.005766027141362429,
      0.0595954991877079,
      0.04822351783514023,
      -0.09191645681858063,
      0.031838905066251755,
      -0.04389774799346924,
      -0.03957762569189072,
      0.09336869418621063,
      0.01778801530599594,
      -0.03964261710643768,
      -0.050656188279390335,
      0.053608428686857224,
      0.028590822592377663,
      -0.07779990881681442,
      -0.024229267612099648,
      0.0018911685328930616,
      0.010973258875310421,
      -0.07369352877140045,
      0.001109929638914764,
      0.01709040440618992,
      0.061019767075777054,
      0.03620816767215729,
      0.03001893125474453,
      -0.04224355146288872,
      -0.06425151973962784,
      -0.04493303224444389,
      0.12923240661621094,
      0.09997303038835526,
      0.025856558233499527,
      -0.01030793134123087,
      0.0030406226869672537,
      -0.008646608330309391,
      0.08879408985376358,
      -0.01609320007264614,
      -0.03494814783334732,
      0.03041490726172924,
      0.028470875695347786,
      -0.07290854305028915,
      0.0005453441990539432,
      0.032175999134778976,
      -0.06089811399579048,
      0.02011045441031456,
      0.06907692551612854,
      -0.05474235489964485,
      0.04277006536722183,
      0.0019473469583317637,
      0.052905578166246414,
      -0.003602856071665883,
      -0.0851503238081932,
      0.03926725313067436,
      0.01596062071621418,
      0.00427401065826416,
      0.043382152915000916,
      -0.009332135319709778,
      -0.032654572278261185,
      0.012634838931262493,
      0.00506443390622735,
      0.04981409013271332,
      -0.008892586454749107,
      0.04166293516755104,
      -0.04190386086702347,
      0.043099526315927505,
      -0.13494057953357697,
      -0.03587520867586136,
      -0.03650374710559845,
      -0.03891149163246155,
      0.05678657442331314,
      0.011915608309209347,
      0.06461133807897568,
      -0.0013450762489810586,
      -0.020387781783938408,
      -0.006252529565244913,
      0.06338899582624435,
      -0.046194445341825485,
      0.0021641713101416826,
      -0.08648870140314102,
      -0.0010278380941599607,
      -0.01382516510784626,
      0.0324442982673645,
      -0.09238550066947937,
      -0.00684692244976759,
      -0.04023514688014984,
      -0.05196847766637802,
      0.05807027593255043,
      0.044353071600198746,
      -0.03299782797694206,
      -0.07422047853469849,
      0.08471479266881943,
      0.06499797105789185,
      -0.008936528116464615,
      -0.011486250907182693,
      -0.03474608063697815,
      -0.016166338697075844,
      0.008997277356684208,
      -0.1082194522023201,
      -0.02935776859521866,
      0.010509451851248741,
      0.060423798859119415,
      -0.009983167983591557,
      0.029818853363394737,
      -0.018456412479281425,
      0.009173537604510784,
      -0.017861032858490944,
      0.015135432593524456,
      -0.04449738189578056,
      -0.0910637229681015,
      -0.018290631473064423,
      -0.004888591822236776,
      -0.09702369570732117,
      -0.005727823823690414,
      -0.0018641711212694645,
      -0.05351189523935318,
      0.06339656561613083,
      -0.02697792649269104,
      0.010408313944935799,
      0.03886983171105385,
      0.07373768091201782,
      -0.019780892878770828,
      0.08192209899425507,
      0.008560627698898315,
      -0.0691220685839653,
      -0.08057105541229248,
      0.022173691540956497,
      0.022805804386734962,
      0.06224595382809639,
      0.029791971668601036,
      0.03399725258350372,
      -0.09164810180664062,
      -0.05983850732445717,
      0.033842429518699646,
      -0.043508823961019516,
      0.027959078550338745,
      -0.00818313006311655,
      -0.009608659893274307,
      -0.013729539699852467,
      0.0920550525188446,
      0.01974215917289257,
      -0.007573617156594992,
      0.07143012434244156,
      -0.06565091758966446,
      -0.019583428278565407,
      -0.117713063955307,
      -0.009083080105483532,
      0.020599283277988434,
      -0.07977987080812454,
      0.0740404799580574,
      0.01438218541443348,
      0.040678780525922775,
      -0.04211181402206421,
      -0.037609100341796875,
      0.017963189631700516,
      0.008296001702547073,
      0.07277418673038483,
      -0.002019858919084072,
      0.09255842119455338,
      0.020097246393561363,
      -0.019892379641532898,
      0.07978072017431259,
      -0.019149109721183777,
      0.032637666910886765,
      -0.054458003491163254,
      -0.054218485951423645,
      -0.03321083262562752,
      0.05984856188297272,
      0.021053964272141457,
      -0.021725444123148918,
      0.027535438537597656,
      -0.014254248701035976,
      0.05251004919409752,
      0.07056989520788193,
      -0.04534478485584259,
      -0.02685571275651455,
      -0.06844893097877502,
      -0.05563868209719658,
      -0.010405773296952248,
      -0.009785263799130917,
      -0.08424672484397888,
      0.035932354629039764,
      -0.04143039137125015,
      0.010887629352509975,
      0.005958392284810543,
      -0.012399466708302498,
      0.07214904576539993,
      0.0581049881875515,
      0.002046331763267517,
      -0.06799871474504471,
      0.07620961964130402,
      -0.010679301805794239,
      -0.058678146451711655,
      -0.01389201171696186,
      -0.01807389222085476,
      -0.058885157108306885,
      0.05269143357872963,
      0.005434093531221151,
      0.006126589607447386,
      0.038171328604221344,
      0.06338721513748169,
      -0.018230509012937546,
      -0.037243761122226715,
      0.04286776855587959,
      -0.07571500539779663,
      -0.01529921405017376,
      -0.010633492842316628,
      -0.035966675728559494,
      -0.05199886113405228,
      0.00012002474250039086,
      -0.07057615369558334,
      0.02270592376589775,
      0.06865222752094269,
      0.05465620756149292,
      0.04955018684267998,
      0.017608825117349625,
      0.06311330199241638,
      -0.011664386838674545,
      -0.009108126163482666,
      0.020078444853425026,
      -0.050377510488033295,
      -0.005063182208687067,
      -0.0034206474665552378,
      -0.022748848423361778,
      0.10636073350906372,
      -0.02763584814965725,
      0.03054630197584629,
      0.038022108376026154,
      0.05231839790940285,
      -0.05506538227200508,
      -0.02673817053437233,
      0.024440916255116463,
      0.058136582374572754,
      0.0017110103508457541,
      -0.02032351680099964,
      0.027236489579081535,
      -0.025269635021686554,
      -0.0323156863451004,
      -0.11823767423629761,
      -0.030573580414056778,
      0.004562583286315203,
      0.012208927422761917,
      -0.02522001415491104,
      -0.01849776692688465,
      0.011362544260919094,
      0.043787308037281036,
      -0.004161948338150978,
      0.025987720116972923,
      0.025143349543213844,
      -0.014525843784213066,
      0.037422146648168564,
      0.028493525460362434,
      0.06962127983570099,
      0.013678246177732944,
      -0.05029992759227753,
      -0.01754862256348133,
      -0.028527716174721718,
      0.0669797956943512,
      0.06926315277814865,
      -0.026087909936904907,
      0.01577390730381012,
      0.10228723287582397,
      -0.018154079094529152,
      0.016995729878544807,
      -0.04707146808505058,
      0.06290622055530548,
      0.030104413628578186,
      0.09561478346586227,
      0.00578262796625495,
      -0.052214957773685455,
      0.006094459444284439,
      0.003588802181184292,
      -0.07865675538778305,
      0.05189994350075722,
      -0.0075149848125875,
      0.07898537069559097,
      0.06620103120803833,
      0.023150239139795303,
      -0.015991538763046265,
      0.06420306861400604,
      -0.03272070363163948,
      -0.028178691864013672,
      0.07997830212116241,
      0.019353574141860008,
      -0.006173556670546532,
      -0.027614757418632507,
      0.054956868290901184,
      -0.0593755729496479,
      0.01454517524689436
    ],
    [
      -0.03427829593420029,
      0.02492627687752247,
      -0.030407579615712166,
      -0.003758524777367711,
      0.05149107426404953,
      -0.02787066623568535,
      -0.01276944950222969,
      -0.01818348653614521,
      -0.0208451971411705,
      -0.029536528512835503,
      -0.09691015630960464,
      0.01378059946000576,
      0.004671165719628334,
      -0.01204456202685833,
      0.09749863296747208,
      -0.009737215004861355,
      0.011241463012993336,
      -0.02795480377972126,
      0.04535754397511482,
      0.05722571536898613,
      -0.033730655908584595,
      0.04449557140469551,
      0.019802151247859,
      0.10770855098962784,
      -0.009848153218626976,
      0.03320274129509926,
      0.17654037475585938,
      -0.039441291242837906,
      0.007788878865540028,
      -0.015509611926972866,
      -0.004859342705458403,
      -0.03520312160253525,
      -0.055115777999162674,
      0.09983700513839722,
      0.026361806318163872,
      -0.04344169795513153,
      0.044123291969299316,
      -0.0635107085108757,
      0.018128644675016403,
      0.0552450492978096,
      0.1316593438386917,
      -0.07813186198472977,
      0.03599235415458679,
      -0.00725934375077486,
      0.06376028060913086,
      0.033465947955846786,
      -0.05680026486515999,
      -0.042871881276369095,
      -0.013992857187986374,
      0.03622358664870262,
      -0.010301806032657623,
      -0.008623562753200531,
      -0.030429227277636528,
      -0.0049079968594014645,
      0.018537020310759544,
      0.029480963945388794,
      0.0004269543569535017,
      0.02294834703207016,
      -0.03908795118331909,
      -0.032845281064510345,
      0.15122249722480774,
      -0.019367340952157974,
      0.018069200217723846,
      -0.014867624267935753,
      -0.056862398982048035,
      0.06255622953176498,
      -0.038542844355106354,
      -0.027522576972842216,
      -0.009011408314108849,
      -0.015656284987926483,
      -0.009058677591383457,
      -0.005491606425493956,
      -0.00412004766985774,
      -0.01959453709423542,
      -0.07283324003219604,
      -0.004732203669846058,
      0.02532545104622841,
      -0.053757209330797195,
      0.03279224783182144,
      -0.08726903796195984,
      -0.07451789081096649,
      -0.006353455130010843,
      -0.018109610304236412,
      -0.0839090347290039,
      0.1029924675822258,
      0.004724838305264711,
      0.0838591605424881,
      0.036286789923906326,
      -0.03348253667354584,
      -0.02013171650469303,
      0.007629151921719313,
      0.019679255783557892,
      0.01683284156024456,
      -0.051292430609464645,
      0.047292012721300125,
      0.00792013481259346,
      0.0006037848652340472,
      0.03841380029916763,
      0.009270311333239079,
      -0.027494153007864952,
      0.052691929042339325,
      -0.029206953942775726,
      0.048041027039289474,
      0.029085751622915268,
      -0.06895660609006882,
      0.08375280350446701,
      -0.03576746582984924,
      -0.0206577330827713,
      -0.11184141784906387,
      0.08057639747858047,
      0.0051986840553581715,
      -0.031001079827547073,
      -0.019291911274194717,
      0.029802421107888222,
      -0.0061841970309615135,
      0.012963324785232544,
      -0.06093786656856537,
      0.06870446354150772,
      0.017514491453766823,
      0.08620869368314743,
      0.05928673595190048,
      -0.026628009974956512,
      0.021518971771001816,
      -0.04984816536307335,
      -0.07646015286445618,
      0.03575144708156586,
      0.014133879914879799,
      -0.009603365324437618,
      -0.06523244827985764,
      0.04254356399178505,
      -0.013823228888213634,
      0.0218411423265934,
      -0.023400841280817986,
      0.0912320613861084,
      0.05765518173575401,
      0.00747987674549222,
      0.026744995266199112,
      -0.0729614719748497,
      0.09259098768234253,
      0.013384713791310787,
      -0.020703326910734177,
      0.051321011036634445,
      0.02395772933959961,
      0.01979801617562771,
      -0.02989179641008377,
      0.03925298526883125,
      -0.04352492094039917,
      -0.04385499283671379,
      0.0037937727756798267,
      0.05416083708405495,
      0.03517293184995651,
      -0.021633224561810493,
      0.04315746948122978,
      -0.0013706749305129051,
      0.03708147257566452,
      0.08100911974906921,
      -0.0628577396273613,
      -0.024165362119674683,
      -0.0350971445441246,
      0.023565316572785378,
      -0.02125604636967182,
      0.008970067836344242,
      0.037312231957912445,
      -0.034470655024051666,
      0.011758329346776009,
      -0.0077992333099246025,
      -0.07790084183216095,
      0.041129220277071,
      -0.05186080560088158,
      0.02346545271575451,
      -0.09161229431629181,
      0.013035382144153118,
      -0.0199313685297966,
      -0.014271673746407032,
      -0.03485539183020592,
      -0.04586024582386017,
      -0.025075381621718407,
      0.03441615775227547,
      0.02968519553542137,
      0.002683134749531746,
      0.04505254700779915,
      -0.03096422739326954,
      -0.004617033991962671,
      -0.12018134444952011,
      0.008617948740720749,
      -0.03613872826099396,
      -0.0015947130741551518,
      0.09968164563179016,
      -0.008981868624687195,
      0.04216767102479935,
      0.03569842875003815,
      0.05462932214140892,
      -0.015721777454018593,
      -0.014286146499216557,
      0.010635023936629295,
      -0.040344178676605225,
      -0.03421981632709503,
      0.008048390038311481,
      0.0017579174600541592,
      0.05321989208459854,
      -0.05604125186800957,
      0.030091000720858574,
      -0.06519502401351929,
      0.030175672844052315,
      -0.03572931885719299,
      0.012832068838179111,
      0.06387405097484589,
      0.03528474643826485,
      -0.004843422677367926,
      -0.026770910248160362,
      0.01097801886498928,
      -0.013255688361823559,
      -0.0601612888276577,
      -0.02700764499604702,
      0.003998926840722561,
      0.007747096940875053,
      0.023299919441342354,
      0.014647193253040314,
      0.04353121295571327,
      -0.01467563770711422,
      -0.03751556947827339,
      0.0348842516541481,
      0.037992656230926514,
      -0.018840037286281586,
      -0.00507013825699687,
      0.056528620421886444,
      -0.03937816992402077,
      -0.014273022301495075,
      -0.05582708492875099,
      -0.057368937879800797,
      -0.019169678911566734,
      0.021665040403604507,
      0.04298395290970802,
      0.015550862066447735,
      0.009250135160982609,
      0.027055829763412476,
      -0.001153644290752709,
      0.04599030315876007,
      0.15809457004070282,
      -0.059349820017814636,
      0.0037047273945063353,
      -0.05859259516000748,
      -0.03339095041155815,
      0.014940451830625534,
      -0.017346881330013275,
      -0.0030536060221493244,
      0.007954449392855167,
      -0.07906497269868851,
      0.023761870339512825,
      0.025494754314422607,
      0.03214811906218529,
      -0.00020849856082350016,
      -0.01242693793028593,
      0.026666143909096718,
      0.007540179882198572,
      0.040690936148166656,
      0.03032948635518551,
      0.02702108770608902,
      -0.05250485986471176,
      0.08653827011585236,
      0.04183880239725113,
      0.041307102888822556,
      -0.03190269693732262,
      0.08665827661752701,
      -0.04401696100831032,
      0.03685498237609863,
      -0.04221003130078316,
      -0.021041765809059143,
      -0.006456924602389336,
      -0.050372637808322906,
      0.018509391695261,
      -0.02784297615289688,
      -0.059259187430143356,
      -0.021747995167970657,
      0.04912811517715454,
      -0.08606112748384476,
      0.042697083204984665,
      0.004517352674156427,
      -0.005109998397529125,
      -0.0205999743193388,
      -0.04268570989370346,
      0.051197536289691925,
      0.1188172698020935,
      0.010500941425561905,
      0.011424848809838295,
      0.0038935402408242226,
      0.060986343771219254,
      -0.016104260459542274,
      0.037041448056697845,
      -0.030355151742696762,
      0.005797012709081173,
      -0.0560973696410656,
      0.02569766156375408,
      0.02862304076552391,
      0.006988679990172386,
      -0.03161139786243439,
      -0.03801947087049484,
      0.03221633657813072,
      -0.0016478948527947068,
      -8.904824062483385e-05,
      0.024915389716625214,
      0.044454433023929596,
      0.046316277235746384,
      -0.022593310102820396,
      0.022662129253149033,
      0.059827957302331924,
      -0.054113756865262985,
      0.015862980857491493,
      0.0009561816114000976,
      -0.0008826541597954929,
      0.05788489431142807,
      -0.02275782823562622,
      -0.006778091192245483,
      0.04315710440278053,
      -0.026733607053756714,
      -0.019540075212717056,
      -0.01378666888922453,
      0.004232512321323156,
      0.03248923644423485,
      -0.03303182125091553,
      -0.01604427769780159,
      0.0016258463729172945,
      -0.03434523940086365,
      -0.006019602995365858,
      0.002669519977644086,
      -0.03646053001284599,
      -0.03069707565009594,
      0.03183664381504059,
      -0.08391673862934113,
      0.01333902683109045,
      0.030581273138523102,
      0.06731367856264114,
      0.026866517961025238,
      0.08023443073034286,
      0.06647023558616638,
      0.03055739961564541,
      -0.0058547197841107845,
      -0.017496701329946518,
      -0.01605660654604435,
      -0.00796334259212017,
      -0.05939560383558273,
      0.029241446405649185,
      -0.010677878744900227,
      0.004249081946909428,
      -0.021569645032286644,
      -0.028040755540132523,
      0.014933232218027115,
      -0.08250092715024948,
      0.014318052679300308,
      -0.10816038399934769,
      -0.020275123417377472,
      0.09926306456327438,
      0.0004979190998710692,
      0.0061715953052043915,
      -0.029439322650432587,
      0.01668052189052105,
      0.1325143575668335,
      -0.03697803243994713,
      -0.001480592181906104,
      0.04208764433860779,
      -0.027067391201853752,
      -0.04484405741095543,
      0.07335389405488968,
      -0.03151484206318855,
      0.013745439238846302,
      0.06316318362951279,
      0.019705237820744514,
      0.07079975306987762,
      -0.05504234880208969,
      0.054473552852869034,
      -0.020014656707644463,
      -0.0025489763356745243,
      0.045205749571323395,
      -0.008366351015865803,
      -0.0537208691239357,
      0.03533913940191269,
      -0.051348403096199036,
      -0.06967125833034515,
      -0.10597711056470871,
      0.08698752522468567,
      -0.04203825816512108,
      -0.04538910835981369,
      0.001825045794248581,
      -0.012507306411862373,
      0.03484788164496422,
      0.024097377434372902,
      -0.02770213410258293,
      -0.06468067318201065,
      -0.03289870172739029,
      -0.06282559782266617,
      -0.026325130835175514,
      -0.021191781386733055,
      -0.0005894800997339189,
      0.06635512411594391,
      -0.0073460242711007595,
      -0.02124899998307228,
      0.04252014309167862,
      -0.0010115003678947687,
      0.02109241858124733,
      -0.018165407702326775,
      -0.06946253031492233,
      0.049859706312417984,
      -0.02102810889482498,
      0.07413069158792496,
      0.05710352957248688,
      -0.04286617785692215,
      -0.013879815116524696,
      0.004807251039892435,
      0.021251391619443893,
      -0.04230237379670143,
      -0.021503891795873642,
      -0.010837280191481113,
      0.036058492958545685,
      0.008454326540231705,
      -0.01496447529643774,
      0.05078427121043205,
      0.056179508566856384,
      0.046617574989795685,
      0.005709584802389145,
      0.03331802785396576,
      0.01463813055306673,
      0.03548933193087578,
      -0.05428953096270561,
      0.04852987453341484,
      0.04363301768898964,
      0.03667331114411354,
      0.06403425335884094,
      0.06353053450584412,
      -0.03597268462181091,
      0.07028254121541977,
      0.03636176884174347,
      -0.022123677656054497,
      0.09145720303058624,
      -0.060387760400772095,
      -0.041831448674201965,
      0.033464465290308,
      -0.02963295951485634,
      0.020948711782693863,
      -0.05791221931576729,
      -0.01685510203242302,
      -0.047311361879110336,
      -0.06763099879026413,
      -0.08219309896230698,
      -0.03527572751045227,
      -0.028530243784189224,
      0.0100136399269104,
      -0.01743454858660698,
      0.04182656109333038,
      -0.05861249938607216,
      -0.047351520508527756,
      -0.0224489476531744,
      0.05091492086648941,
      -0.04170260578393936,
      0.0015521165914833546,
      -0.009411844424903393,
      -0.03149827942252159,
      -0.011345586739480495,
      -0.08121421188116074,
      0.08597063273191452,
      -0.0015640263445675373,
      0.007230952847748995,
      0.010222240351140499,
      0.056349921971559525,
      -0.12612462043762207,
      -0.013248587027192116,
      -0.07192277163267136,
      -0.014497368596494198,
      -0.035656288266181946,
      0.021432917565107346,
      0.03439584746956825,
      -0.02955075353384018,
      0.0024739999789744616,
      0.02068926766514778,
      -0.0031742777209728956,
      -0.010280641727149487,
      0.02542910724878311,
      0.021657651290297508,
      -0.059784114360809326,
      0.015276594087481499,
      0.04139253869652748,
      -0.020335912704467773,
      -0.07978680729866028,
      0.02036912925541401,
      -0.06677629053592682,
      -0.005770770832896233,
      0.006298378575593233,
      -0.04999770596623421,
      0.038883328437805176,
      -0.06150875613093376,
      -0.02300933189690113,
      -0.00935469102114439,
      -0.020887181162834167,
      -0.0177793949842453,
      0.01965324394404888,
      -0.07742313295602798,
      0.07156175374984741,
      0.031032338738441467,
      0.010258271358907223,
      -0.07499342411756516,
      0.053391825407743454,
      0.002369396621361375,
      0.014346454292535782,
      0.029337767511606216
    ],
    [
      -0.02741895243525505,
      -0.04894091933965683,
      0.06201036646962166,
      0.0788310170173645,
      -0.02864142507314682,
      0.0016382189933210611,
      0.036339953541755676,
      -0.07721269875764847,
      0.06263075023889542,
      0.10086313635110855,
      0.034847188740968704,
      0.016206176951527596,
      0.03943495452404022,
      0.07814992964267731,
      -0.013987621292471886,
      -0.04260667786002159,
      -0.03268082067370415,
      0.04648275300860405,
      0.017937446013092995,
      -0.028930842876434326,
      -0.027827216312289238,
      -0.018883882090449333,
      -0.08081978559494019,
      0.002205918775871396,
      -0.04983857274055481,
      -0.025895582512021065,
      -0.04927213117480278,
      0.05322747677564621,
      -0.04283590242266655,
      -0.046032149344682693,
      0.0008557108812965453,
      -0.07217605412006378,
      -0.031879574060440063,
      0.09238684922456741,
      -0.003562689060345292,
      -0.05538296326994896,
      -0.001832418842241168,
      -0.06524636596441269,
      0.0036755341570824385,
      0.044377051293849945,
      0.08603724092245102,
      -0.031017659232020378,
      0.07170955836772919,
      0.04604274779558182,
      0.06221535801887512,
      -0.03875649720430374,
      -0.035523444414138794,
      -0.03559977933764458,
      0.014502247795462608,
      -0.04763909801840782,
      -0.00664051016792655,
      0.07351992279291153,
      0.08293988555669785,
      -0.02212655171751976,
      0.03989574685692787,
      0.0373004712164402,
      -0.08247773349285126,
      -0.02719823643565178,
      -0.062358349561691284,
      -0.060696132481098175,
      -0.0019505564123392105,
      -0.00824376568198204,
      -0.014261474832892418,
      0.0573667474091053,
      -0.08002283424139023,
      0.03877919167280197,
      0.018273048102855682,
      -0.019580842927098274,
      0.08004303276538849,
      -0.0025968835689127445,
      -0.1111394390463829,
      0.07939615845680237,
      -0.06055549904704094,
      0.04364250972867012,
      -0.02873918041586876,
      0.045072223991155624,
      -0.001690068980678916,
      0.009788473136723042,
      0.08476244658231735,
      0.0021635727025568485,
      -0.05959614738821983,
      0.03509378060698509,
      -0.029539979994297028,
      -0.0578240305185318,
      -0.015693046152591705,
      0.07069277763366699,
      0.04587402567267418,
      -0.07161200791597366,
      0.08224192261695862,
      -0.00613631634041667,
      -0.014854397624731064,
      0.07311888039112091,
      -0.05774325877428055,
      -0.025716662406921387,
      0.07344301044940948,
      -0.005084730684757233,
      -0.008144056424498558,
      -0.03689318150281906,
      -0.04009195417165756,
      0.04520629346370697,
      0.020240990445017815,
      0.019722873345017433,
      -0.042781248688697815,
      -0.01860363781452179,
      -0.08832412958145142,
      0.014454043470323086,
      0.020791634917259216,
      0.06433393061161041,
      -0.004748916253447533,
      0.006276941858232021,
      0.045891135931015015,
      0.0757366418838501,
      -0.03866169601678848,
      0.03700957074761391,
      0.032207950949668884,
      -0.09201730042695999,
      0.0333673395216465,
      0.043595124036073685,
      -0.05259305611252785,
      -0.05498732998967171,
      -0.048114411532878876,
      -0.01337999664247036,
      0.06257371604442596,
      0.013631573878228664,
      -0.017050214111804962,
      0.014665615744888783,
      -0.05867376923561096,
      -0.06970205157995224,
      -0.08690892904996872,
      0.03882180154323578,
      0.007551165763288736,
      0.04112611338496208,
      -0.0375894270837307,
      -0.03725501894950867,
      -0.0643523558974266,
      -0.01983487233519554,
      -0.029825406149029732,
      0.006655407138168812,
      -0.039617542177438736,
      0.03089778870344162,
      0.05000954866409302,
      0.0497337207198143,
      0.008515226654708385,
      0.03323550522327423,
      -0.039970677345991135,
      -0.005463902372866869,
      -0.05196135863661766,
      0.030499907210469246,
      0.04373687878251076,
      -0.008445370011031628,
      0.013143850490450859,
      -0.06529221683740616,
      -0.007454480044543743,
      0.009974224492907524,
      -0.03916672617197037,
      -0.05162125080823898,
      -0.008582805283367634,
      -0.0220962967723608,
      -0.05518011376261711,
      -0.014966990798711777,
      0.09892614930868149,
      -0.02241889014840126,
      0.012214872986078262,
      -0.006074416916817427,
      0.0658717229962349,
      -0.014317703433334827,
      -0.025685332715511322,
      -0.039615798741579056,
      0.02857833541929722,
      -0.05053794011473656,
      -0.005946387071162462,
      -0.13509754836559296,
      -0.028386102989315987,
      -0.03454902395606041,
      -0.011787581257522106,
      0.013007907196879387,
      0.0084892138838768,
      -0.0555662177503109,
      -0.0505625456571579,
      0.057145170867443085,
      0.01713583432137966,
      -0.052060309797525406,
      0.04263213276863098,
      0.011979279108345509,
      0.09074092656373978,
      0.027301298454403877,
      -0.06653668731451035,
      -0.023249618709087372,
      0.012944912537932396,
      0.021318022161722183,
      -0.022138141095638275,
      0.02192879654467106,
      0.03057841956615448,
      -0.06485802680253983,
      0.0018905289471149445,
      0.03986776992678642,
      0.007443707901984453,
      -0.02929123118519783,
      0.04124828055500984,
      -0.047629859298467636,
      -0.09349377453327179,
      0.04959478974342346,
      -0.02620266191661358,
      0.000348885718267411,
      0.00598524184897542,
      -0.007957556284964085,
      0.017222771421074867,
      -0.005258394405245781,
      -0.0855356827378273,
      0.041052043437957764,
      -0.010370447300374508,
      0.018662620335817337,
      -0.023573849350214005,
      -0.02992638200521469,
      -0.04839548096060753,
      0.052188340574502945,
      0.00724219623953104,
      -0.03282380849123001,
      0.0036207467783242464,
      0.10174470394849777,
      -0.1300840675830841,
      0.01156594417989254,
      0.0063615296967327595,
      0.013127136044204235,
      0.03492111712694168,
      -0.09100450575351715,
      -0.10243178904056549,
      -0.0453011654317379,
      -0.09868943691253662,
      -0.007113727740943432,
      0.06600023061037064,
      0.010619595646858215,
      -0.013735169544816017,
      -0.0008238607551902533,
      -0.03633851557970047,
      0.006437462288886309,
      -0.01595570519566536,
      0.03431541472673416,
      -0.03471126779913902,
      -0.026455054059624672,
      0.04938343167304993,
      0.03034202754497528,
      -0.01349672582000494,
      -0.0748094841837883,
      0.04615965113043785,
      -0.0028827881906181574,
      -0.07798174768686295,
      0.04037049785256386,
      -0.06915745884180069,
      0.013970830477774143,
      0.028257349506020546,
      0.0968090146780014,
      0.029277479276061058,
      0.061170123517513275,
      0.06849871575832367,
      0.034207697957754135,
      0.002581959590315819,
      0.04598720744252205,
      6.224096432561055e-05,
      -0.028204377740621567,
      -0.006048892624676228,
      0.008859986439347267,
      0.03605244308710098,
      -0.010917372070252895,
      -0.0377502515912056,
      -0.028301110491156578,
      0.008709769695997238,
      -0.042004670947790146,
      0.010366600006818771,
      -0.03938686102628708,
      0.010267515666782856,
      0.04096157103776932,
      0.03748413920402527,
      0.041857216507196426,
      0.011464732699096203,
      -0.06275621056556702,
      0.0359308235347271,
      0.007923420518636703,
      0.022979844361543655,
      -0.04241031035780907,
      0.000485300668515265,
      0.02933141216635704,
      0.002536172978579998,
      0.08172601461410522,
      -0.07480832934379578,
      -0.03536834940314293,
      0.04739432409405708,
      -0.025178087875247,
      0.005880680400878191,
      0.024980438873171806,
      0.05014955252408981,
      0.06153253838419914,
      -0.012263577431440353,
      -0.06822974234819412,
      -0.04931182414293289,
      -0.026770181953907013,
      0.0034947944805026054,
      0.05018073320388794,
      0.048726361244916916,
      -0.03739163279533386,
      -0.004287003539502621,
      -0.0734606608748436,
      0.00269348849542439,
      0.006538608111441135,
      -0.01281875278800726,
      0.07562815397977829,
      0.019343173131346703,
      -0.05601153150200844,
      0.07022219896316528,
      0.007829180918633938,
      -0.05840756744146347,
      -0.0339321531355381,
      -0.0003822520957328379,
      0.09161313623189926,
      -0.008821897208690643,
      -0.016424644738435745,
      0.03017338179051876,
      -0.038010090589523315,
      0.04079210013151169,
      0.05778902769088745,
      -0.027455249801278114,
      0.10342645645141602,
      -0.033282265067100525,
      0.03621518239378929,
      -0.010201521217823029,
      0.06099890545010567,
      0.012468376196920872,
      0.052794717252254486,
      -0.06551831215620041,
      0.054613448679447174,
      -0.003660971764475107,
      -0.01904246024787426,
      -0.015117032453417778,
      -0.09445726871490479,
      -0.05683015286922455,
      -0.04521604999899864,
      0.09956291317939758,
      -0.019530292600393295,
      -0.025356346741318703,
      0.006766503676772118,
      -0.013331702910363674,
      -0.042593326419591904,
      -0.045044463127851486,
      0.06307205557823181,
      -0.044889990240335464,
      -0.04694646596908569,
      -0.02640523575246334,
      -0.012249098159372807,
      -0.013670786283910275,
      -0.03789487108588219,
      0.002071392722427845,
      -0.035099245607852936,
      -0.01651710271835327,
      -0.0006331036565825343,
      0.025839978829026222,
      -0.028973335400223732,
      -0.015219786204397678,
      -0.05843408778309822,
      0.038242440670728683,
      0.013673081062734127,
      -0.13818161189556122,
      -0.02613496035337448,
      -0.023607926443219185,
      0.08033645898103714,
      -0.04946429282426834,
      0.059139084070920944,
      0.06938595324754715,
      -0.03536659851670265,
      0.021479513496160507,
      0.02062954567372799,
      0.009085000492632389,
      0.05457586422562599,
      0.018204811960458755,
      -0.061953309923410416,
      -0.06432490050792694,
      0.06491214781999588,
      -0.11362443119287491,
      0.059305138885974884,
      -0.04998214915394783,
      0.0608912892639637,
      0.056872233748435974,
      0.040348999202251434,
      0.009566333144903183,
      0.029629796743392944,
      0.08245743811130524,
      0.039851848036050797,
      -0.016240444034337997,
      0.007714120205491781,
      0.016968557611107826,
      -0.029628170654177666,
      -0.02124800719320774,
      -0.085801862180233,
      -0.022159896790981293,
      -0.052153345197439194,
      0.04832366108894348,
      -0.016471171751618385,
      -0.16791164875030518,
      -0.07197188585996628,
      0.009041963145136833,
      0.010488763451576233,
      0.02534424513578415,
      0.06417564302682877,
      0.04233149066567421,
      0.021415403112769127,
      0.008354399353265762,
      -0.027756208553910255,
      -0.007369793951511383,
      -0.01938188448548317,
      -0.025557061657309532,
      0.041386935859918594,
      0.028026457875967026,
      -0.0012530279345810413,
      -0.06336470693349838,
      0.026011742651462555,
      -0.1109737679362297,
      0.02095949649810791,
      0.015334399417042732,
      -0.0647556260228157,
      0.024990685284137726,
      0.044678233563899994,
      -0.03599075227975845,
      -0.026606129482388496,
      -0.014553981833159924,
      0.0669291764497757,
      -0.01688428409397602,
      -0.01893838495016098,
      -0.023017140105366707,
      0.014084260910749435,
      -0.002674342133104801,
      -0.018347643315792084,
      0.026970673352479935,
      -0.10024644434452057,
      -0.010851056315004826,
      -0.023560667410492897,
      -0.07103331387042999,
      0.001991092460229993,
      -0.0008824946125969291,
      -0.0018774187192320824,
      -0.037557344883680344,
      0.08422990888357162,
      -0.020308325067162514,
      0.013894441537559032,
      0.003538296790793538,
      -0.1079927459359169,
      0.09045793116092682,
      0.05717978999018669,
      0.019596045836806297,
      -0.00470943795517087,
      -0.06503551453351974,
      0.0366286002099514,
      -0.02502569928765297,
      -0.010765676386654377,
      0.06918790191411972,
      0.008760744705796242,
      0.012843881733715534,
      0.008789293467998505,
      0.017848500981926918,
      -0.08777882158756256,
      0.0009399856207892299,
      -0.0001733212120598182,
      -0.015195992775261402,
      0.006463136989623308,
      0.016146907582879066,
      -0.023652615025639534,
      -0.04266110062599182,
      0.017514677718281746,
      -0.009302928112447262,
      -0.03242562711238861,
      0.008061274886131287,
      0.012408021837472916,
      -0.021458588540554047,
      -0.0033618886955082417,
      -0.01578247919678688,
      -9.416603279532865e-05,
      0.06410727649927139,
      -0.04166155681014061,
      0.09246473014354706,
      0.04025625064969063,
      0.005661271046847105,
      0.014456694945693016,
      -0.04166875407099724,
      0.0089164599776268,
      -0.05719777196645737,
      -0.03360798954963684,
      0.006499748211354017,
      -0.038694828748703,
      0.04786680266261101,
      -0.008194848895072937,
      -0.01752351224422455,
      0.05157003924250603,
      -0.04123624041676521,
      0.04450121521949768,
      -0.01963639073073864,
      -0.01803416572511196,
      0.08116892725229263,
      0.048033591359853745,
      -0.022624310106039047,
      -0.009831899777054787,
      -0.039506327360868454,
      0.02560824155807495,
      0.034088436514139175,
      0.01840917579829693,
      0.0072417608462274075
    ],
    [
      -0.007455352693796158,
      0.0421488881111145,
      0.07776150852441788,
      0.043679699301719666,
      0.0025601820088922977,
      -0.028146523982286453,
      0.012768439017236233,
      0.019644776359200478,
      -0.0003071091487072408,
      0.021662583574652672,
      -0.04476016014814377,
      0.0005747894756495953,
      -0.040841978043317795,
      0.00806505884975195,
      0.0707867294549942,
      0.044119611382484436,
      -0.0451706126332283,
      -0.03480445221066475,
      -0.019976511597633362,
      -0.0024048544000834227,
      -0.042170170694589615,
      0.0956687480211258,
      0.000553094141650945,
      0.08286022394895554,
      -0.036101989448070526,
      -0.004963740706443787,
      -0.0029643583111464977,
      -0.0857757180929184,
      0.01130628027021885,
      -0.09946400672197342,
      0.018449686467647552,
      -0.0766390860080719,
      0.0774129256606102,
      0.015682797878980637,
      0.0036749988794326782,
      0.07048003375530243,
      0.009835485368967056,
      0.04879103973507881,
      0.06983531266450882,
      0.014218351803719997,
      0.010001796297729015,
      0.05441094934940338,
      -0.002674463437870145,
      -0.05533911660313606,
      -0.007666176185011864,
      0.023838618770241737,
      0.11616554111242294,
      0.026053691282868385,
      -0.01614203490316868,
      -0.006194847170263529,
      -0.03778858110308647,
      -0.015503493137657642,
      0.01770319603383541,
      -0.028873106464743614,
      -0.06479153037071228,
      0.004382053390145302,
      -0.092304527759552,
      -0.03312615305185318,
      0.05643060803413391,
      -0.024071315303444862,
      -0.01918277144432068,
      -0.030846301466226578,
      0.06818954646587372,
      0.016339724883437157,
      -0.05677761882543564,
      -0.060412079095840454,
      0.07576241344213486,
      -0.02109656296670437,
      0.09515594691038132,
      -0.059057965874671936,
      -0.04676973074674606,
      -0.014254685491323471,
      0.055975381284952164,
      -0.007913751527667046,
      -0.0014362765941768885,
      -0.029986314475536346,
      0.01516738161444664,
      -0.008727607317268848,
      0.09599356353282928,
      -0.04414411261677742,
      0.035405103117227554,
      0.0442948080599308,
      0.011223836801946163,
      -0.04485893249511719,
      0.03098018281161785,
      0.04749452322721481,
      -0.03185802325606346,
      0.006242244970053434,
      -0.0577632337808609,
      -0.05798885598778725,
      -0.006311693228781223,
      -0.026513928547501564,
      -0.026001954451203346,
      0.08077452331781387,
      0.009653463959693909,
      0.0420532263815403,
      -0.020644521340727806,
      0.031742218881845474,
      0.01911845989525318,
      -0.01935315690934658,
      -0.039138976484537125,
      0.004666293039917946,
      0.04249558597803116,
      0.02789166383445263,
      -0.05477554351091385,
      0.011849254369735718,
      0.07018601149320602,
      0.0440695583820343,
      -0.1072450578212738,
      0.05996847152709961,
      -0.009759475477039814,
      0.014063621871173382,
      0.02461850643157959,
      0.00027476646937429905,
      0.025942014530301094,
      -0.047073524445295334,
      0.004641084466129541,
      -0.011253752745687962,
      0.01057832594960928,
      0.01051249634474516,
      0.003433839650824666,
      0.02048354409635067,
      0.010527531616389751,
      0.023234345018863678,
      -0.014793526381254196,
      0.07576211541891098,
      -0.009308883920311928,
      0.013072466477751732,
      0.060585226863622665,
      -0.06378769874572754,
      0.1206996887922287,
      -0.019548330456018448,
      0.012793296948075294,
      -0.049825217574834824,
      -0.03349638730287552,
      -0.0943477600812912,
      -0.03597104549407959,
      -0.06869135797023773,
      -0.037605829536914825,
      0.058013398200273514,
      0.024001816287636757,
      -0.02260451205074787,
      0.07995517551898956,
      -0.02537793293595314,
      0.00983805675059557,
      -0.012188599444925785,
      -0.02131168358027935,
      0.04539316147565842,
      0.02239537052810192,
      0.13067680597305298,
      -0.03336404263973236,
      0.0072751278057694435,
      0.06864790618419647,
      -0.032926253974437714,
      -0.03405759111046791,
      -0.02686052769422531,
      -0.011327647604048252,
      -0.05037682503461838,
      0.0029481283854693174,
      -0.0020894953049719334,
      0.055557750165462494,
      0.05482206121087074,
      0.029364747926592827,
      -0.03268955647945404,
      0.07651696354150772,
      -0.002153323031961918,
      0.10773209482431412,
      -0.03343530371785164,
      -0.02430621348321438,
      -0.015041565522551537,
      -0.029442964121699333,
      -0.080734483897686,
      0.025850290432572365,
      0.039427708834409714,
      0.056694116443395615,
      -0.03167157247662544,
      -0.004185255616903305,
      0.07020361721515656,
      0.04594481363892555,
      0.018401874229311943,
      -0.018609371036291122,
      -0.08409269154071808,
      -0.007373244501650333,
      -0.016833161935210228,
      -0.03268640860915184,
      0.009421239607036114,
      -0.0331539623439312,
      -0.04767123609781265,
      0.015124062076210976,
      -0.001582512748427689,
      -0.02254651114344597,
      0.020762791857123375,
      -0.08849997818470001,
      -0.01973779872059822,
      0.030137721449136734,
      -0.018811704590916634,
      -0.021371586248278618,
      -0.04899314045906067,
      -0.03792237117886543,
      0.09122166782617569,
      0.03787290304899216,
      0.03362356498837471,
      -0.0822639912366867,
      0.03437887504696846,
      0.018251167610287666,
      0.018634242936968803,
      -0.0427284874022007,
      -0.025535663589835167,
      0.027592185884714127,
      -0.0007032054127193987,
      -0.01348881982266903,
      -0.027171967551112175,
      0.030794654041528702,
      0.028759928420186043,
      0.02612597867846489,
      -0.07139986008405685,
      -0.022842777892947197,
      -0.00937714520841837,
      -0.003080751746892929,
      -0.004199307411909103,
      -0.0515609048306942,
      0.020250452682375908,
      -0.0425284206867218,
      0.004088648594915867,
      -0.0765913873910904,
      -0.06942198425531387,
      0.10520042479038239,
      -0.026277875527739525,
      -0.0039420221000909805,
      -0.03818625956773758,
      0.03606303781270981,
      0.11012522876262665,
      0.042744893580675125,
      0.024529581889510155,
      0.004450059030205011,
      0.03782862052321434,
      0.037793442606925964,
      0.045046593993902206,
      -0.0923292264342308,
      0.0681389793753624,
      0.08144835382699966,
      -0.019321253523230553,
      -0.023329632356762886,
      -0.03666532784700394,
      0.001489158719778061,
      0.05314851924777031,
      0.014810877852141857,
      -0.043523892760276794,
      -0.078070729970932,
      0.06558116525411606,
      0.03234069049358368,
      0.024929523468017578,
      -0.07387495040893555,
      -0.12725774943828583,
      0.043023593723773956,
      -0.05706813931465149,
      0.04951639100909233,
      0.02777761034667492,
      0.027015455067157745,
      0.02362571284174919,
      0.058338385075330734,
      0.009524318389594555,
      -0.03471198305487633,
      0.021078240126371384,
      0.00618176581338048,
      0.02370280772447586,
      -0.039245497435331345,
      -0.03989449143409729,
      0.01717051863670349,
      -0.013987678103148937,
      0.049129873514175415,
      -0.029041513800621033,
      -0.04381003603339195,
      0.08879544585943222,
      0.025920232757925987,
      -0.004717426374554634,
      0.10138864070177078,
      0.019836170598864555,
      -0.0702318400144577,
      0.01910461112856865,
      0.04737137258052826,
      -0.005875604692846537,
      0.008155164308845997,
      0.07837237417697906,
      0.10206828266382217,
      -0.002686257241293788,
      0.02915291301906109,
      0.06637006253004074,
      -0.044136714190244675,
      -0.004355632700026035,
      0.04473329707980156,
      0.07569877803325653,
      -0.021303504705429077,
      -0.013291457667946815,
      0.005180671811103821,
      0.0976240411400795,
      0.0036125192418694496,
      -0.029952220618724823,
      -0.08928216248750687,
      0.025625482201576233,
      0.014482470229268074,
      -0.030797909945249557,
      -0.09399822354316711,
      -0.0834738165140152,
      -0.07965776324272156,
      0.09538165479898453,
      -0.08390108495950699,
      -0.01166662946343422,
      -0.027539962902665138,
      0.06588634848594666,
      0.02401513233780861,
      -0.05287863686680794,
      -0.018657352775335312,
      -0.057263899594545364,
      0.02035677246749401,
      0.049020566046237946,
      -0.04215608909726143,
      -0.016634054481983185,
      0.013829616829752922,
      -0.010477135889232159,
      0.027436038479208946,
      0.08102599531412125,
      -0.03488374501466751,
      -0.05203068628907204,
      0.0691092386841774,
      -0.057449616491794586,
      -0.00118760927580297,
      0.06541111320257187,
      0.026118334382772446,
      -0.07710517942905426,
      -0.014057156629860401,
      -0.05596383288502693,
      0.011824971996247768,
      -0.04764426872134209,
      -0.029142916202545166,
      -0.03867926448583603,
      -0.003996569197624922,
      0.01185860950499773,
      0.0030216234736144543,
      0.0397975817322731,
      -0.022729456424713135,
      0.039732109755277634,
      0.018434951081871986,
      0.023504508659243584,
      -0.06154044345021248,
      -0.051776912063360214,
      -0.06207433342933655,
      0.009223412722349167,
      -0.02633288875222206,
      0.038659997284412384,
      -0.10561317950487137,
      0.02070370502769947,
      0.04959180951118469,
      -0.027237175032496452,
      0.011194177903234959,
      0.007880360819399357,
      -0.03596735745668411,
      -0.03878463804721832,
      0.02095971256494522,
      0.06916382908821106,
      0.037836287170648575,
      -0.003716688137501478,
      0.04879205673933029,
      -0.012746596708893776,
      0.0156369898468256,
      0.021147962659597397,
      0.02824508585035801,
      0.02223891392350197,
      0.011235151439905167,
      -0.06271031498908997,
      -0.011098528280854225,
      0.03756460174918175,
      0.03195881098508835,
      0.012812427245080471,
      0.06405128538608551,
      -0.055476412177085876,
      -0.02105526439845562,
      0.021457716822624207,
      -0.04147588461637497,
      -0.014170227572321892,
      -0.035528458654880524,
      -0.013891912065446377,
      -0.05246834084391594,
      -0.016824105754494667,
      0.0663546472787857,
      -0.018470939248800278,
      -0.015329938381910324,
      -0.0034665099810808897,
      0.013647929765284061,
      0.01601814664900303,
      -0.05466078594326973,
      -0.01389831118285656,
      -0.05122901499271393,
      0.04119175300002098,
      0.03882193565368652,
      0.05893533676862717,
      0.07292874157428741,
      -0.07522927969694138,
      0.06847220659255981,
      -0.014082329347729683,
      -0.005810102913528681,
      -0.02033917047083378,
      0.049832697957754135,
      0.02946789562702179,
      0.06433059275150299,
      -0.00021896895486861467,
      0.02680349349975586,
      -0.0233627799898386,
      -0.008869514800608158,
      0.014757447876036167,
      0.04445720836520195,
      -0.05613776296377182,
      0.1284254491329193,
      -0.0020853611640632153,
      0.08287955075502396,
      0.0044148825109004974,
      0.018051190301775932,
      -0.03738559037446976,
      0.004962085280567408,
      0.014718863181769848,
      -0.03632713481783867,
      0.03971381485462189,
      0.08985037356615067,
      -0.007597677409648895,
      -0.000823834037873894,
      -0.003923286683857441,
      -0.0006043208413757384,
      0.08292751759290695,
      -0.03442458435893059,
      -0.028269270434975624,
      -0.03807920217514038,
      -0.044948093593120575,
      0.0989508330821991,
      0.029027260839939117,
      0.014401538297533989,
      -0.04513963684439659,
      0.05724988132715225,
      0.019209619611501694,
      -0.022205714136362076,
      0.030179541558027267,
      -0.05044619366526604,
      0.027841759845614433,
      0.023380117490887642,
      0.01469340454787016,
      0.019422447308897972,
      0.03239088132977486,
      -0.05246465280652046,
      0.06534924358129501,
      -0.031267695128917694,
      -0.02733224630355835,
      -0.042076289653778076,
      0.025650493800640106,
      -0.0068210395984351635,
      -0.0329740047454834,
      0.01662347838282585,
      0.0383925624191761,
      -0.08486563712358475,
      -0.011356144212186337,
      -0.009094377048313618,
      -0.012118568643927574,
      -0.07326486706733704,
      -0.02663331851363182,
      -0.021941091865301132,
      0.03674774616956711,
      0.016484413295984268,
      -0.014551721513271332,
      -0.05388887599110603,
      0.04245502129197121,
      -0.07762549072504044,
      0.012560540810227394,
      0.07447724789381027,
      0.03947072476148605,
      0.04051065444946289,
      0.04988405108451843,
      0.009708407334983349,
      0.013769150711596012,
      0.07405097782611847,
      -0.01345919817686081,
      -0.017182407900691032,
      -0.0021581058390438557,
      -0.08362159878015518,
      -0.009808560833334923,
      0.026843588799238205,
      -0.03822016343474388,
      -0.02928144484758377,
      -0.0018465508474037051,
      0.11892842501401901,
      0.05872415378689766,
      -0.038596171885728836,
      0.017119305208325386,
      0.05131605267524719,
      -0.004177105147391558,
      -0.003113200655207038,
      0.035339049994945526,
      -0.052832383662462234,
      -0.022480251267552376,
      0.06448010355234146,
      -0.023617416620254517,
      0.022456644102931023,
      -0.050114311277866364,
      0.027999695390462875,
      0.02937949448823929,
      0.011576098389923573
    ],
    [
      -0.05500463768839836,
      -0.031003667041659355,
      0.0021011631470173597,
      -0.04290318861603737,
      0.028496362268924713,
      0.002759466180577874,
      -0.004605109337717295,
      -0.008359606377780437,
      0.0324784517288208,
      0.0013113933382555842,
      0.029936648905277252,
      0.012329072691500187,
      -0.04581320285797119,
      -0.019022909924387932,
      0.0345102958381176,
      -0.07080462574958801,
      0.03102727420628071,
      0.05947057157754898,
      -0.07162659615278244,
      -0.049825407564640045,
      -0.08177399635314941,
      -0.03701256960630417,
      0.03593374788761139,
      0.08279768377542496,
      -0.058685559779405594,
      0.10470489412546158,
      -0.02223951183259487,
      0.026759902015328407,
      0.02368873916566372,
      -0.016948152333498,
      0.047527555376291275,
      -0.10184670239686966,
      -0.019844213500618935,
      0.07541581243276596,
      0.056573234498500824,
      -0.06323172152042389,
      -0.02254856750369072,
      0.008872766979038715,
      0.02361072227358818,
      -0.024589810520410538,
      0.00392270740121603,
      -0.036507073789834976,
      0.03805253282189369,
      0.028562352061271667,
      0.017416687682271004,
      0.03064371831715107,
      0.06398822367191315,
      -0.00652819499373436,
      -0.04420562461018562,
      -0.05269865319132805,
      -0.07273919135332108,
      -0.06832969933748245,
      0.11254347860813141,
      -0.05316657945513725,
      0.021118180826306343,
      -0.03782794624567032,
      0.02794310450553894,
      0.1254732608795166,
      0.014175760559737682,
      -0.050886865705251694,
      0.021506598219275475,
      -0.07569557428359985,
      -0.018981188535690308,
      -0.030926937237381935,
      0.001528239226900041,
      -0.037343770265579224,
      -0.027022026479244232,
      0.02274501696228981,
      -0.05773826315999031,
      -0.004285483621060848,
      -0.006932118907570839,
      0.028540076687932014,
      0.012268324382603168,
      -0.023752424865961075,
      -0.013126643374562263,
      0.00808245874941349,
      -0.03952588140964508,
      -0.05938374996185303,
      0.027580412104725838,
      -0.05564724653959274,
      -0.03169381991028786,
      0.05526967719197273,
      0.01071912981569767,
      -0.06744985282421112,
      0.03984060510993004,
      0.06944899260997772,
      -0.017073364928364754,
      -0.0462111234664917,
      0.007669482845813036,
      0.04785880073904991,
      0.020479006692767143,
      -0.02654748409986496,
      0.04939043149352074,
      -0.029200159013271332,
      0.04714291915297508,
      0.04710956662893295,
      0.008466720581054688,
      0.03800065815448761,
      0.058547914028167725,
      0.047825753688812256,
      0.021822690963745117,
      0.023421447724103928,
      0.005583274643868208,
      0.003991013392806053,
      0.030878381803631783,
      0.10005433112382889,
      -0.028716640546917915,
      0.026898760348558426,
      -0.015891049057245255,
      0.09225577116012573,
      -0.07283452898263931,
      -0.003010963322594762,
      0.03606401011347771,
      0.01432980876415968,
      -0.020712926983833313,
      0.028212226927280426,
      0.024622749537229538,
      -0.003153447061777115,
      -0.04778597503900528,
      -0.026289934292435646,
      -0.014238639734685421,
      -0.030005119740962982,
      0.014693238772451878,
      -0.04420069232583046,
      -0.0030274733435362577,
      0.03223976865410805,
      0.01949530839920044,
      -0.04889095947146416,
      0.041413214057683945,
      -0.02460378222167492,
      0.08093636482954025,
      -0.047346994280815125,
      0.07299939543008804,
      -0.07381083816289902,
      0.007335818838328123,
      0.03946681320667267,
      -0.05127376690506935,
      0.03787752240896225,
      -0.06156177073717117,
      -0.006886526010930538,
      0.03685799241065979,
      0.05042951554059982,
      -0.023831157013773918,
      0.015745626762509346,
      -0.04146285355091095,
      -0.05620739981532097,
      -0.05012929067015648,
      9.856359247351065e-05,
      0.01874982938170433,
      0.034350231289863586,
      0.04214324429631233,
      -0.06859348714351654,
      0.013449938036501408,
      0.004996512550860643,
      -0.005501324776560068,
      -0.07441249489784241,
      0.038707129657268524,
      0.004831067752093077,
      0.037512823939323425,
      0.01042891200631857,
      0.02062934637069702,
      -0.06247232109308243,
      0.017436355352401733,
      -0.06093454733490944,
      0.05875562131404877,
      -0.0495670810341835,
      -0.015865249559283257,
      -0.026624487712979317,
      0.09643162041902542,
      0.0365753211081028,
      0.040183667093515396,
      -0.001002923003397882,
      0.03297866880893707,
      -0.08916264027357101,
      0.09685811400413513,
      0.005700862035155296,
      0.011275770142674446,
      -0.013727881014347076,
      0.05130365863442421,
      0.083379827439785,
      -0.04164016991853714,
      -0.02506435476243496,
      0.059289075434207916,
      -0.054466892033815384,
      -0.022352710366249084,
      0.011386950500309467,
      0.009458520449697971,
      -0.08334243297576904,
      0.013234407640993595,
      -0.053660351783037186,
      0.056717678904533386,
      0.05522604286670685,
      0.04859044775366783,
      -0.020676497370004654,
      0.09374964237213135,
      0.08905743062496185,
      -0.038623467087745667,
      -0.0372123196721077,
      0.03734597563743591,
      0.03396718576550484,
      -0.10822257399559021,
      0.04663736745715141,
      -0.035327520221471786,
      0.003088543424382806,
      0.062356214970350266,
      -0.04091864824295044,
      -0.06153612583875656,
      -0.033122409135103226,
      -0.02767283096909523,
      -0.04224314168095589,
      -0.07451510429382324,
      0.007206006906926632,
      0.000420311582274735,
      0.04348459839820862,
      0.03928515687584877,
      0.04214014485478401,
      0.09172725677490234,
      0.007335918955504894,
      0.08433803915977478,
      -0.03877107799053192,
      -0.0018518917495384812,
      0.04731028899550438,
      0.02405736781656742,
      -0.011187691241502762,
      0.028105461969971657,
      0.0520036518573761,
      0.007449856493622065,
      0.021064268425107002,
      -0.03655976057052612,
      -0.03965023532509804,
      -0.03390156105160713,
      -0.051562801003456116,
      0.008833973668515682,
      0.07717058807611465,
      -0.05979252606630325,
      -0.062006015330553055,
      -0.030493875965476036,
      -0.025039592757821083,
      0.0010480935452505946,
      0.024454662576317787,
      -0.01827194355428219,
      0.05364261195063591,
      -0.03188183903694153,
      0.08571527153253555,
      0.056763507425785065,
      0.03362436592578888,
      0.018976014107465744,
      0.053525473922491074,
      0.0008596392581239343,
      0.09499045461416245,
      0.04016055911779404,
      -0.0738612562417984,
      0.011357988230884075,
      -0.041351497173309326,
      -0.03925849497318268,
      0.048020023852586746,
      0.007560659199953079,
      -0.011794643476605415,
      0.045410580933094025,
      0.04143458977341652,
      -0.014649172313511372,
      0.013807425275444984,
      -0.03880902752280235,
      0.021069258451461792,
      -0.0630626529455185,
      0.03891875967383385,
      0.010376800782978535,
      0.030555153265595436,
      0.05290919914841652,
      0.0065932562574744225,
      0.020258232951164246,
      -0.02715046890079975,
      -0.013022806495428085,
      0.021436821669340134,
      0.00665978342294693,
      0.01369409542530775,
      0.024169649928808212,
      0.0055965036153793335,
      0.043682146817445755,
      0.024919169023633003,
      -0.04316863417625427,
      0.12679266929626465,
      0.023262562230229378,
      -0.0033168166410177946,
      0.10418933629989624,
      -0.021982353180646896,
      -0.020678509026765823,
      0.009851057082414627,
      -0.005283175967633724,
      -0.010293040424585342,
      -0.0051108780317008495,
      0.04082966223359108,
      0.004766109399497509,
      0.025321727618575096,
      -0.04342270642518997,
      -0.10520842671394348,
      0.015173994936048985,
      -0.033797796815633774,
      0.007151190657168627,
      -0.010077818296849728,
      0.019124355167150497,
      0.004402639344334602,
      -0.013593025505542755,
      0.040638599544763565,
      -0.0357954166829586,
      0.010081645101308823,
      -0.05530998855829239,
      0.02959119901061058,
      0.03425046429038048,
      -0.007557177450507879,
      0.03332189470529556,
      -0.03186864033341408,
      0.011432387866079807,
      0.03689022362232208,
      0.04350180923938751,
      -0.06485459208488464,
      -0.024623392149806023,
      -0.046321723610162735,
      0.061756815761327744,
      -0.08139815181493759,
      0.03528614342212677,
      0.03943677619099617,
      0.01701168343424797,
      0.0029309438541531563,
      -0.03448827937245369,
      -0.02805410325527191,
      -0.01064766850322485,
      0.003105594078078866,
      -0.09766440093517303,
      0.010140526108443737,
      -0.007133204955607653,
      -0.01579584740102291,
      0.016452031210064888,
      -0.04715212807059288,
      0.11783921718597412,
      0.054807983338832855,
      -0.01528627797961235,
      -0.007280144840478897,
      -0.026114219799637794,
      -0.03459697961807251,
      -0.08874943107366562,
      -0.04652281105518341,
      -0.003980109002441168,
      0.009131452068686485,
      -0.017219921573996544,
      0.014760392718017101,
      0.002959552453830838,
      0.030989978462457657,
      -0.004921724088490009,
      0.0637112557888031,
      -0.08159134536981583,
      0.04014506936073303,
      -0.14512373507022858,
      0.05784149095416069,
      0.0072727324441075325,
      -0.0500778928399086,
      -0.09077576547861099,
      0.03308694437146187,
      -0.02119617350399494,
      -0.004498424008488655,
      0.07339419424533844,
      -0.11001761257648468,
      -0.010188302956521511,
      0.0030351292807608843,
      0.01663381978869438,
      -0.012845322489738464,
      0.01632547192275524,
      0.06017494574189186,
      0.004322917200624943,
      0.02426416240632534,
      -0.04318580403923988,
      0.002465012716129422,
      0.025921955704689026,
      -0.00023356435121968389,
      0.021848412230610847,
      -0.06436482816934586,
      -0.039677053689956665,
      -0.019854623824357986,
      -0.07184410840272903,
      -0.041625648736953735,
      0.022589784115552902,
      0.02243606559932232,
      -0.0392758771777153,
      0.007888118736445904,
      -0.049028266221284866,
      0.022105015814304352,
      0.02375699020922184,
      -0.020534219220280647,
      0.07230547070503235,
      -0.0690915435552597,
      -0.022884799167513847,
      -0.06519678980112076,
      0.01084812730550766,
      0.018148062750697136,
      -0.0010862553026527166,
      0.010805448517203331,
      0.0763140320777893,
      0.06974169611930847,
      -0.03182382136583328,
      -0.05374116078019142,
      -0.06486594676971436,
      0.06061796098947525,
      -0.04792407527565956,
      -0.05785476788878441,
      0.010624187998473644,
      0.013519139029085636,
      0.0067098429426550865,
      -0.020879987627267838,
      -0.0649162083864212,
      0.008586266078054905,
      -0.022555211558938026,
      -0.03382322937250137,
      0.05169298127293587,
      -0.026811623945832253,
      0.0721355602145195,
      0.007386469282209873,
      -0.00489139324054122,
      0.01898966357111931,
      -0.026440182700753212,
      0.0514458529651165,
      -0.05474400892853737,
      -0.020420290529727936,
      0.018177878111600876,
      0.00046073502744548023,
      -0.0010576426284387708,
      0.02482319250702858,
      0.041195452213287354,
      -0.05545985698699951,
      0.015259211882948875,
      -0.030540933832526207,
      0.0016499182675033808,
      0.010140628553926945,
      0.06100798398256302,
      0.011327520944178104,
      -0.003218315541744232,
      -0.022505462169647217,
      0.06696032732725143,
      0.010199311189353466,
      0.06169533729553223,
      -0.030019836500287056,
      -0.016733283177018166,
      0.029319288209080696,
      0.0025412566028535366,
      0.04351238161325455,
      -0.04458928108215332,
      -0.020312760025262833,
      -0.023115796968340874,
      0.01585566997528076,
      -0.042555760592222214,
      -0.021344872191548347,
      -0.006323450244963169,
      0.0815267264842987,
      0.0231355931609869,
      -0.037699371576309204,
      -0.03304922953248024,
      -0.03894849494099617,
      0.023729952052235603,
      -0.08018827438354492,
      -0.06165030971169472,
      -0.01199620496481657,
      0.08116186410188675,
      -0.016044849529862404,
      0.019521581009030342,
      -0.06381148099899292,
      -0.05269720405340195,
      0.008270236663520336,
      0.03201955929398537,
      0.01592608541250229,
      -0.01504158228635788,
      -0.02570178173482418,
      -0.06443920731544495,
      -0.050246644765138626,
      0.02478637360036373,
      -0.006509270053356886,
      0.026787107810378075,
      0.016413964331150055,
      -0.04113201051950455,
      0.0059910849668085575,
      -0.06819287687540054,
      0.05047863721847534,
      -0.03469306603074074,
      0.0029625962488353252,
      0.05614083260297775,
      -0.056974515318870544,
      -0.0075347162783145905,
      0.005527729168534279,
      -0.039789341390132904,
      -0.002529129385948181,
      -0.04434574022889137,
      0.019852187484502792,
      -0.01447567529976368,
      0.11106470227241516,
      -0.023122087121009827,
      0.051740169525146484,
      0.05340738967061043,
      0.008717527613043785,
      0.0254322811961174,
      0.007114499807357788,
      -0.027984242886304855,
      0.04389888048171997,
      0.03192921727895737,
      -0.034773387014865875,
      -0.056260980665683746
    ],
    [
      -0.0722932368516922,
      0.05339717119932175,
      0.015014239586889744,
      0.035970792174339294,
      0.010063370689749718,
      -0.0020534617360681295,
      -0.010055351071059704,
      0.0038606582675129175,
      0.08255308866500854,
      -0.06495623290538788,
      0.053905509412288666,
      -0.00057476811343804,
      -0.046465154737234116,
      0.0008572814986109734,
      -0.039694830775260925,
      0.030819403007626534,
      0.06302139163017273,
      0.04066501930356026,
      0.028716497123241425,
      -0.05025939270853996,
      0.017911959439516068,
      0.010670636780560017,
      -0.019912421703338623,
      -0.0371733196079731,
      0.038166798651218414,
      0.018131447955965996,
      -0.024226874113082886,
      -0.009979598224163055,
      0.02598399482667446,
      0.01306042168289423,
      -0.020620757713913918,
      -0.10587533563375473,
      -0.004089662805199623,
      0.0007530987495556474,
      -0.013370036147534847,
      0.06807060539722443,
      -0.049889031797647476,
      0.00992504134774208,
      -0.025960862636566162,
      -0.006555828731507063,
      0.11903014779090881,
      0.021849267184734344,
      0.07035115361213684,
      -0.0009519054437987506,
      -0.033486947417259216,
      -0.025007039308547974,
      -0.01740489900112152,
      -0.03255220875144005,
      0.01767917349934578,
      0.015915360301733017,
      0.0163684394210577,
      0.023794077336788177,
      -0.07510918378829956,
      -0.014914166182279587,
      -0.043822094798088074,
      0.028126360848546028,
      -0.04699217155575752,
      0.0134528037160635,
      0.04568023607134819,
      0.04942040145397186,
      -0.043209824711084366,
      0.0043867286294698715,
      0.0026231969241052866,
      -0.05221809074282646,
      -0.025988994166254997,
      0.03298666700720787,
      0.008631132543087006,
      -0.023368781432509422,
      -0.017613397911190987,
      -0.044764768332242966,
      -0.027291003614664078,
      0.06269954890012741,
      -0.0027671821881085634,
      -0.0957084596157074,
      0.026347555220127106,
      -0.05099019408226013,
      0.008652042597532272,
      0.019663983955979347,
      0.0023928838782012463,
      -0.042370159178972244,
      -0.036792147904634476,
      0.01454335730522871,
      0.02879783883690834,
      0.06709519773721695,
      0.02406308799982071,
      0.02024856023490429,
      -0.047166481614112854,
      -0.012067660689353943,
      0.07098397612571716,
      0.0030924896709620953,
      0.05492202937602997,
      0.00607676524668932,
      0.027587968856096268,
      0.05776733532547951,
      -0.06079063564538956,
      -0.007338664494454861,
      0.034623656421899796,
      0.019499436020851135,
      -0.035562288016080856,
      0.027204414829611778,
      -0.0004134857445023954,
      -0.028738824650645256,
      -0.03217097371816635,
      -0.06465819478034973,
      0.040207620710134506,
      -0.029010822996497154,
      -0.007747263181954622,
      0.0014547856990247965,
      -0.05132049322128296,
      0.007422416936606169,
      -0.03857354819774628,
      -0.003547497559338808,
      0.04712994024157524,
      0.013610059395432472,
      0.010678342543542385,
      0.06793951988220215,
      -0.11281184107065201,
      0.00453923549503088,
      0.0028625635895878077,
      -0.05756863206624985,
      -0.02979518473148346,
      -0.056159961968660355,
      0.012471948750317097,
      -0.003158294828608632,
      0.049383051693439484,
      -0.005299344193190336,
      0.037904947996139526,
      -0.04509555548429489,
      0.027130264788866043,
      -0.011221172288060188,
      -0.038035351783037186,
      0.01506049744784832,
      -0.009816926904022694,
      -0.00029896452906541526,
      -0.03783995285630226,
      0.01853667013347149,
      0.05096103250980377,
      0.028093116357922554,
      0.02056930400431156,
      -0.013867605477571487,
      0.018863320350646973,
      0.05212472006678581,
      -0.0318666510283947,
      0.006735776085406542,
      -0.029870349913835526,
      -0.06041590869426727,
      0.010770672932267189,
      0.05573096498847008,
      0.061560578644275665,
      0.008828132413327694,
      -0.02615782804787159,
      -0.10872737318277359,
      -0.027102604508399963,
      0.04318094998598099,
      -0.028764626011252403,
      -0.003162998240441084,
      -0.026601437479257584,
      -0.03477264940738678,
      -0.029099633917212486,
      -0.056133631616830826,
      0.0030844872817397118,
      0.004807331133633852,
      -0.0034027649089694023,
      -0.06172740459442139,
      -0.00886696856468916,
      0.08124847710132599,
      -0.0724153146147728,
      0.02967640571296215,
      0.07603272795677185,
      -0.047131698578596115,
      0.015101389959454536,
      -0.02023295685648918,
      0.004823214840143919,
      -0.05572451278567314,
      -0.1246308907866478,
      -0.019821839407086372,
      -0.03009905107319355,
      -0.07300981134176254,
      0.0597691610455513,
      -0.022188572213053703,
      -0.027288811281323433,
      -0.05784951150417328,
      0.005118171684443951,
      -0.003113086801022291,
      -0.06934724748134613,
      -0.06277207285165787,
      -0.051420677453279495,
      -0.01900320313870907,
      0.04343441501259804,
      0.11404130607843399,
      -0.04129323363304138,
      0.0028955708257853985,
      0.009144596755504608,
      -0.1232835128903389,
      0.03373093158006668,
      0.0073118191212415695,
      0.03471115231513977,
      0.08356992155313492,
      -0.07453981786966324,
      0.05540517717599869,
      0.03389059752225876,
      -0.04514961689710617,
      -0.010999895632266998,
      0.026227010414004326,
      -0.0018650131532922387,
      0.018065685406327248,
      -0.03383150324225426,
      0.04079476371407509,
      -0.055089376866817474,
      -0.01076382864266634,
      0.05727846547961235,
      -0.05908123031258583,
      -0.0009261530358344316,
      -0.07888150960206985,
      -0.07958561927080154,
      -0.09948816150426865,
      0.09205428510904312,
      0.06876149773597717,
      -0.10105817764997482,
      -0.022600846365094185,
      0.009150184690952301,
      -0.007883892394602299,
      -0.0053885565139353275,
      0.005594482179731131,
      0.04179157689213753,
      -0.030844679102301598,
      -0.02891300991177559,
      0.12228214740753174,
      -0.012473834678530693,
      -0.03479508310556412,
      0.026162274181842804,
      -0.01429246086627245,
      0.05008332431316376,
      -0.007338768802583218,
      0.011502434499561787,
      0.013890516944229603,
      -0.036151766777038574,
      0.05696909502148628,
      0.0019100656500086188,
      0.059630393981933594,
      -0.03833387419581413,
      0.006755221169441938,
      -0.021762585267424583,
      0.024464810267090797,
      -0.029370475560426712,
      0.011256185360252857,
      -0.0031326410826295614,
      -0.08227366954088211,
      0.06848151236772537,
      0.058220889419317245,
      -0.04460558295249939,
      0.038964491337537766,
      -0.05733504518866539,
      -0.054480716586112976,
      0.07313136011362076,
      0.10628011077642441,
      -0.09507621079683304,
      -0.00528893293812871,
      0.036695100367069244,
      -0.0013710677158087492,
      0.07415883988142014,
      -0.008599722757935524,
      -0.046198341995477676,
      -0.026078306138515472,
      0.01719374768435955,
      0.0649222880601883,
      -0.05431097373366356,
      -0.03177434206008911,
      0.044265054166316986,
      -0.025476504117250443,
      -0.02478007599711418,
      -0.012940140441060066,
      -0.05700071156024933,
      0.008969605900347233,
      0.01665719412267208,
      0.04900326207280159,
      0.030153969302773476,
      -0.012911713682115078,
      -0.021402666345238686,
      -0.016347048804163933,
      -0.022763729095458984,
      0.09660262614488602,
      -0.03328460082411766,
      0.023114744573831558,
      0.03514207899570465,
      0.08614947646856308,
      -0.018033746629953384,
      0.10333297401666641,
      0.023187965154647827,
      -0.023900501430034637,
      0.04118482023477554,
      -0.055433016270399094,
      -0.015076289884746075,
      0.026280425488948822,
      0.03173420578241348,
      -0.03086296282708645,
      0.0036180145107209682,
      0.0006707024294883013,
      0.019999399781227112,
      -0.0291402917355299,
      0.0591440424323082,
      -0.049468375742435455,
      -0.024873802438378334,
      -0.0362727977335453,
      -0.0236811563372612,
      -0.015028470195829868,
      -0.015972349792718887,
      0.029437553137540817,
      0.044142257422208786,
      0.006591775454580784,
      0.05706159770488739,
      -0.0043830880895257,
      -0.029834942892193794,
      -0.017072826623916626,
      -0.020220573991537094,
      -0.05817081406712532,
      0.005807247944176197,
      0.08878148347139359,
      -0.0766238421201706,
      -0.03185754641890526,
      0.021813014522194862,
      -0.13895253837108612,
      -0.03085409291088581,
      0.012854925356805325,
      -0.0016286988975480199,
      -0.000836837338283658,
      -0.01380393747240305,
      -0.02715514414012432,
      -0.09230010956525803,
      -0.04314982891082764,
      0.01761115901172161,
      -0.002661875681951642,
      0.019381126388907433,
      0.025223294273018837,
      -0.06950537860393524,
      0.011536498554050922,
      0.06363270431756973,
      -0.03267143294215202,
      -0.05655074119567871,
      0.01629554107785225,
      -0.005869422107934952,
      -0.009433831088244915,
      -0.027818653732538223,
      0.0018296886701136827,
      0.03488462418317795,
      -0.02438018098473549,
      0.01175520196557045,
      -0.00155931047629565,
      -0.04981173574924469,
      -0.03884467855095863,
      -0.04127756878733635,
      -0.01568816602230072,
      -0.04481479525566101,
      -0.06094197556376457,
      0.024858934804797173,
      -0.02346915379166603,
      0.013204243965446949,
      -0.034770622849464417,
      -0.02909255214035511,
      0.05512843281030655,
      -0.019579026848077774,
      0.00405309721827507,
      -0.003381111426278949,
      -0.006616421975195408,
      0.020531341433525085,
      0.02270667441189289,
      0.0874319076538086,
      0.04526800289750099,
      -0.04336641728878021,
      0.049299005419015884,
      0.04181966185569763,
      -0.025422202423214912,
      0.008928703144192696,
      -0.04201788455247879,
      -0.05019421875476837,
      -0.06232750788331032,
      -0.05256708711385727,
      0.01783481426537037,
      0.029524028301239014,
      0.018824392929673195,
      -0.0037082729395478964,
      -0.027150124311447144,
      -0.04307590425014496,
      -0.08260980248451233,
      0.005709824152290821,
      0.09068485349416733,
      -0.014707699418067932,
      0.0267600379884243,
      0.01449794601649046,
      -0.004104627296328545,
      -0.040621884167194366,
      0.019534874707460403,
      -0.013233612291514874,
      -0.04117913544178009,
      0.04432055354118347,
      0.014037784188985825,
      -0.026399562135338783,
      -0.016049068421125412,
      0.042216651141643524,
      0.0629625916481018,
      0.00989658571779728,
      0.07394210249185562,
      0.092460036277771,
      -0.018297160044312477,
      0.054648224264383316,
      -0.01719394326210022,
      0.09979097545146942,
      0.009303251281380653,
      -0.0007524919928982854,
      -0.05570873245596886,
      0.03572097048163414,
      0.011708731763064861,
      -0.05006628483533859,
      -0.011272772215306759,
      0.011426268145442009,
      0.019483698531985283,
      -0.03737808018922806,
      0.11178991943597794,
      -0.03285594284534454,
      0.003160049906000495,
      -0.003281734650954604,
      -0.01835019700229168,
      -0.03438100591301918,
      0.04580998420715332,
      0.02754257433116436,
      0.03575523942708969,
      0.09841380268335342,
      0.06758715212345123,
      -0.035093825310468674,
      -0.06328430026769638,
      -0.025836091488599777,
      0.009896409697830677,
      -0.06476891040802002,
      0.005545107182115316,
      0.011873073875904083,
      0.022600717842578888,
      0.07529529929161072,
      0.05405249819159508,
      0.08185921609401703,
      -0.036635804921388626,
      0.045508671551942825,
      0.04897074028849602,
      0.06772507727146149,
      -0.027255818247795105,
      -0.030482016503810883,
      -0.010051162913441658,
      0.016111453995108604,
      -0.04254137724637985,
      0.05682138353586197,
      -0.04041138291358948,
      0.05454916134476662,
      -0.04576772451400757,
      -0.04415639117360115,
      0.009506280533969402,
      -0.013790672644972801,
      0.04630386084318161,
      0.03407151624560356,
      -0.006954115349799395,
      0.010210569947957993,
      0.02389492653310299,
      -0.04454253986477852,
      -0.04712093621492386,
      0.020451320335268974,
      0.04195666313171387,
      0.07657094299793243,
      0.02824113517999649,
      0.06809613853693008,
      -0.0627957135438919,
      -0.03217840567231178,
      0.059526342898607254,
      0.09157925099134445,
      -0.02280358038842678,
      0.04843105375766754,
      -0.026738157495856285,
      -0.02298516407608986,
      -0.04403720423579216,
      0.08852384984493256,
      -0.07207790017127991,
      0.004820310976356268,
      0.045446429401636124,
      0.035777971148490906,
      -0.051547300070524216,
      0.005660763941705227,
      0.011466815136373043,
      -0.03340020030736923,
      -0.062379416078329086,
      0.07340625673532486,
      -0.04024963453412056,
      -0.02293728105723858,
      -0.015375085175037384,
      -0.02094007283449173,
      0.0733497366309166,
      0.0364990159869194,
      0.019258661195635796,
      0.0077440799213945866,
      -0.040394898504018784,
      -0.038228265941143036,
      -0.015072334557771683,
      0.0034207331482321024,
      0.004342408385127783,
      0.07378131151199341,
      -0.006425502244383097,
      -0.01255745068192482
    ],
    [
      -0.006767499726265669,
      0.0019175586057826877,
      -0.017738495022058487,
      0.03169340640306473,
      0.02731863409280777,
      0.028201697394251823,
      -0.010293573141098022,
      0.0020295423455536366,
      0.008078282698988914,
      -0.03316301479935646,
      -0.03360581398010254,
      0.00344202877022326,
      -0.018461713567376137,
      -0.022182300686836243,
      -0.0017511775949969888,
      -0.022599006071686745,
      -0.11230921745300293,
      -0.0505174957215786,
      0.003213705262169242,
      -0.02957635000348091,
      0.03794626146554947,
      -0.029917890205979347,
      0.007853014394640923,
      0.07370156049728394,
      -0.01610179990530014,
      0.014566454105079174,
      0.07044477760791779,
      0.009742431342601776,
      0.05451905354857445,
      0.08655263483524323,
      -0.019841153174638748,
      0.12539620697498322,
      -0.04851934686303139,
      0.0020598426926881075,
      -0.018953287973999977,
      0.055138736963272095,
      -0.10533157736063004,
      -0.07007891684770584,
      -0.006607723888009787,
      0.028549527749419212,
      0.023005187511444092,
      0.020127933472394943,
      0.08167947828769684,
      -0.02640652284026146,
      -0.00503177847713232,
      0.07990265637636185,
      -0.09221933037042618,
      0.0015972680412232876,
      0.0469985157251358,
      -0.02321939915418625,
      -0.011562745086848736,
      0.030493922531604767,
      0.055453281849622726,
      0.012992188334465027,
      0.02508634328842163,
      -0.028582623228430748,
      -0.049332279711961746,
      -0.06087447330355644,
      0.04512622207403183,
      0.07838128507137299,
      0.038358595222234726,
      0.028244273737072945,
      -0.02129480242729187,
      -0.02735084481537342,
      0.06656775623559952,
      -0.09176817536354065,
      -0.06817745417356491,
      0.09490005671977997,
      0.021307390183210373,
      0.03646545857191086,
      -0.0013646751176565886,
      0.13430076837539673,
      0.016575951129198074,
      0.06766762584447861,
      0.02929050102829933,
      -0.016209624707698822,
      -0.029372908174991608,
      -0.040354639291763306,
      -0.012888628989458084,
      -0.059615202248096466,
      -0.020291760563850403,
      0.004234994761645794,
      -0.07341080904006958,
      0.0036285980604588985,
      0.029203588142991066,
      0.02001032419502735,
      0.02879112772643566,
      -0.022353917360305786,
      0.03361634537577629,
      0.04270168021321297,
      -0.09021320194005966,
      -0.07386596500873566,
      -0.007936868816614151,
      -0.046527985483407974,
      -0.04269690439105034,
      -0.01095428317785263,
      -0.10892891138792038,
      -0.02988186664879322,
      0.015685053542256355,
      0.013481652364134789,
      0.01810929924249649,
      -0.032401908189058304,
      -0.04349955916404724,
      0.06981395184993744,
      0.028652265667915344,
      -0.018816350027918816,
      0.008384453132748604,
      -0.1149931475520134,
      -0.09184286743402481,
      0.07625474035739899,
      -0.014758344739675522,
      0.014363987371325493,
      0.0054696714505553246,
      -0.018626229837536812,
      -0.015327935107052326,
      -0.0661701112985611,
      -0.07731487601995468,
      0.06321622431278229,
      0.013100052252411842,
      0.03757123649120331,
      -0.017010441049933434,
      0.007448859978467226,
      -0.0038912154268473387,
      0.03329885005950928,
      0.06485198438167572,
      0.05872812867164612,
      -0.0459761805832386,
      0.007278869394212961,
      -0.03570229560136795,
      0.02403445728123188,
      0.015353559516370296,
      0.019583571702241898,
      -0.07404126971960068,
      -0.006314931903034449,
      -0.029739852994680405,
      -0.002179337665438652,
      0.012037386186420918,
      0.035542964935302734,
      -0.0009328970336355269,
      -0.05551552027463913,
      -0.039685022085905075,
      0.053114429116249084,
      0.02275417372584343,
      -0.0714031234383583,
      -0.07450563460588455,
      0.0015385672450065613,
      0.01350340899080038,
      -0.021800940856337547,
      -0.08733362704515457,
      -0.01199869904667139,
      -0.01357346586883068,
      0.031637564301490784,
      0.1033262386918068,
      0.065715491771698,
      0.02154674381017685,
      0.07215659320354462,
      -0.034440480172634125,
      -0.011706157587468624,
      0.00868627056479454,
      -0.01138492114841938,
      -0.07784741371870041,
      0.004991170950233936,
      0.0579218789935112,
      -0.06129796802997589,
      -0.006146094761788845,
      0.016662588343024254,
      0.027511902153491974,
      -0.03460324928164482,
      -0.08911038190126419,
      0.012470142915844917,
      0.052833300083875656,
      -0.011544800363481045,
      -0.0293778944760561,
      0.00603424571454525,
      0.014908036217093468,
      -0.04475396126508713,
      0.0006323434063233435,
      -0.04058467224240303,
      0.05446686968207359,
      -0.007133353967219591,
      -0.022991521283984184,
      -0.058247826993465424,
      0.014903457835316658,
      0.02202008105814457,
      -0.00781832356005907,
      0.08389029651880264,
      -0.04941050708293915,
      -0.02998732402920723,
      0.04704223945736885,
      -0.07824904471635818,
      0.05100484937429428,
      0.011624176986515522,
      -0.008851521648466587,
      0.0413396917283535,
      -0.021127896383404732,
      -0.07416607439517975,
      -0.004567466676235199,
      -0.08403407782316208,
      0.0030863576103001833,
      0.04004567116498947,
      0.018472110852599144,
      0.022958453744649887,
      -0.016513438895344734,
      0.0018271130975335836,
      -0.012366941198706627,
      -0.028160491958260536,
      -0.014449523761868477,
      -0.10178866237401962,
      0.03380797430872917,
      -0.03352610021829605,
      0.0023571632336825132,
      0.011295302771031857,
      -0.026914438232779503,
      0.017641235142946243,
      0.03342756628990173,
      -0.014925156719982624,
      -0.01828182488679886,
      0.0577055960893631,
      -0.027832528576254845,
      -0.02811332792043686,
      0.0616777278482914,
      0.13180232048034668,
      -0.015352390706539154,
      0.015715843066573143,
      -0.005660697817802429,
      0.01737224869430065,
      0.0883592814207077,
      -0.07876279205083847,
      0.04604783281683922,
      0.027104398235678673,
      -0.04454531893134117,
      -0.08290226012468338,
      -0.042235471308231354,
      0.01753876730799675,
      0.07910452038049698,
      -0.030657537281513214,
      -0.010940263979136944,
      -0.03494507074356079,
      -0.060130275785923004,
      0.05550622195005417,
      -0.05026467144489288,
      0.00858231820166111,
      -0.10870662331581116,
      -0.0394648015499115,
      0.05864278972148895,
      0.053162287920713425,
      -0.0007642754935659468,
      -0.0364939384162426,
      0.02166643552482128,
      -0.00947756227105856,
      -0.013071849942207336,
      0.012849477119743824,
      0.008086957037448883,
      0.003006111364811659,
      0.01924794353544712,
      -0.02108091488480568,
      0.04773774370551109,
      0.0003493721887934953,
      -0.03917909413576126,
      -0.0008036860381253064,
      0.01848551258444786,
      0.1168520376086235,
      -0.013801954686641693,
      -0.10561066120862961,
      0.054057784378528595,
      -0.009276137687265873,
      0.09296777099370956,
      -0.0976158156991005,
      -0.00034128277911804616,
      0.010084542445838451,
      -0.025392329320311546,
      -0.05480511114001274,
      0.010108370333909988,
      0.004835687577724457,
      -0.09901024401187897,
      -0.05478503182530403,
      -0.0552365817129612,
      -0.0355510488152504,
      -0.038086120039224625,
      -0.026804151013493538,
      -0.10998169332742691,
      -0.06078233942389488,
      0.008466129191219807,
      -0.0019672480411827564,
      -0.10696545988321304,
      -0.031120533123612404,
      0.03008514642715454,
      0.027426019310951233,
      0.11752248555421829,
      0.08390097320079803,
      0.014694685116410255,
      0.012596391141414642,
      0.01195222046226263,
      0.015359709970653057,
      -0.03311865031719208,
      0.011770358309149742,
      -0.016801420599222183,
      0.005527630914002657,
      -0.09333845227956772,
      -0.024811727926135063,
      -0.06243405118584633,
      0.04107522591948509,
      0.016082685440778732,
      -0.07119257003068924,
      -0.09089521318674088,
      -0.026249630376696587,
      0.05838939547538757,
      0.018157752230763435,
      -0.06296644359827042,
      -0.01619631052017212,
      -0.05293985456228256,
      -0.008713370189070702,
      -0.010877537541091442,
      0.012346640229225159,
      0.01899649202823639,
      -0.019905908033251762,
      -0.0865243449807167,
      -0.014830059371888638,
      0.022293629124760628,
      -0.06533291935920715,
      0.008883588016033173,
      0.011567723006010056,
      -0.05568143352866173,
      -0.0011498585809022188,
      0.03128860518336296,
      -0.031145112589001656,
      0.09153849631547928,
      0.018639806658029556,
      0.0152016282081604,
      -0.014280215837061405,
      -0.03017420694231987,
      -0.034609824419021606,
      0.004112147726118565,
      0.016040271148085594,
      0.050334084779024124,
      0.055774539709091187,
      0.03364604339003563,
      -0.02198769338428974,
      0.08387050032615662,
      0.06363844126462936,
      0.036263927817344666,
      -0.009791182354092598,
      -0.030682723969221115,
      0.07081261277198792,
      -0.08197741955518723,
      0.0037171663716435432,
      -0.005928981117904186,
      0.01531880721449852,
      0.02391580119729042,
      0.004625839646905661,
      -0.02860911563038826,
      -0.0516691580414772,
      -0.007480244152247906,
      -0.02923465520143509,
      0.04137018322944641,
      -0.01170792430639267,
      -0.013056295923888683,
      -0.011618265882134438,
      0.011183640919625759,
      0.03791900724172592,
      0.08391911536455154,
      -0.057960186153650284,
      -0.0625247210264206,
      -0.007511599455028772,
      -0.005895738955587149,
      -0.028798943385481834,
      0.044641606509685516,
      0.019734539091587067,
      -0.020461734384298325,
      -0.011740420944988728,
      0.0191328302025795,
      0.010156464762985706,
      0.07076100260019302,
      -0.0703003853559494,
      0.004953340627253056,
      0.03896996006369591,
      -0.07331562042236328,
      0.00607454776763916,
      0.03268369659781456,
      -0.06975312530994415,
      -0.004238811321556568,
      -0.06925009936094284,
      -0.003690439974889159,
      -0.004074661992490292,
      -0.050747498869895935,
      -0.005345147103071213,
      -0.015939772129058838,
      -0.0080729303881526,
      -0.018213674426078796,
      0.06950841844081879,
      -0.0014887487050145864,
      -0.023833755403757095,
      -0.016502048820257187,
      -0.038487475365400314,
      -0.007098388392478228,
      -0.0065896096639335155,
      0.01908407174050808,
      0.08435403555631638,
      -0.07017773389816284,
      -0.1009771004319191,
      0.04048063978552818,
      0.0618380531668663,
      -0.010128322057425976,
      0.024929070845246315,
      0.07279567420482635,
      -0.017759500071406364,
      0.031450748443603516,
      0.0051526702009141445,
      -0.06817261874675751,
      0.011061303317546844,
      0.09198056161403656,
      0.05860181897878647,
      0.0029655946418642998,
      0.04917321726679802,
      -0.024224773049354553,
      0.06583579629659653,
      0.05966966226696968,
      0.03828728571534157,
      -0.04673758149147034,
      0.01548464223742485,
      -0.027876190841197968,
      -0.09640520066022873,
      0.00011992032523266971,
      0.0497773252427578,
      -0.0010459362529218197,
      0.0113926250487566,
      -0.005774359218776226,
      0.04598527029156685,
      0.007502515334635973,
      0.019594823941588402,
      -0.14128640294075012,
      0.04037673771381378,
      -0.024891182780265808,
      0.014727562665939331,
      0.05006789043545723,
      0.027876706793904305,
      -0.03282244876027107,
      0.010195261798799038,
      0.03987104073166847,
      0.020121144130825996,
      0.0073151071555912495,
      -0.04454747587442398,
      -0.024503007531166077,
      -0.011007166467607021,
      -0.07684166729450226,
      0.10919181257486343,
      0.03722555562853813,
      0.017941731959581375,
      0.002532267477363348,
      -0.053588610142469406,
      -0.016198059543967247,
      0.0054120877757668495,
      -0.028262335807085037,
      -0.04882749542593956,
      0.017862379550933838,
      0.020625600591301918,
      -0.03396198898553848,
      0.039604730904102325,
      -0.03284463286399841,
      -0.010892280377447605,
      0.08017230778932571,
      0.11194255948066711,
      -0.02394840307533741,
      0.02827296033501625,
      0.007521163206547499,
      0.011800648644566536,
      0.08110146224498749,
      -0.0897439494729042,
      0.02111309953033924,
      0.057135261595249176,
      0.04447844997048378,
      0.018991943448781967,
      -0.03712530434131622,
      -0.031796857714653015,
      -0.07544386386871338,
      -0.0009847583714872599,
      0.0441073477268219,
      -0.007698616944253445,
      0.056686680763959885,
      0.047380004078149796,
      0.05220198258757591,
      0.007426677271723747,
      -0.050127435475587845,
      -0.025844139978289604,
      0.008784442208707333,
      -0.004123390652239323,
      0.023273751139640808,
      -0.004570940509438515,
      0.020825762301683426,
      -0.010779106989502907,
      -0.012119494378566742,
      -0.008849204517900944,
      0.0517517551779747,
      0.054913174360990524,
      0.04847513511776924,
      -0.0038395433221012354,
      -0.005233097821474075,
      0.10668228566646576,
      0.0009153166902251542,
      -0.036169957369565964,
      0.009946965612471104,
      -0.06482608616352081,
      0.006410141009837389
    ],
    [
      -0.02056477963924408,
      -0.023329760879278183,
      -0.06534264981746674,
      0.06758224964141846,
      -0.002434239024296403,
      -0.030975593253970146,
      0.024889109656214714,
      0.002841769251972437,
      -0.01838706061244011,
      -0.03050004318356514,
      -0.03664793446660042,
      0.03470403701066971,
      -0.11026250571012497,
      0.031260840594768524,
      -0.0015127898659557104,
      0.039881691336631775,
      0.0034757559187710285,
      -0.03339558467268944,
      0.0052934973500669,
      -0.025405816733837128,
      0.007227674592286348,
      0.010336702689528465,
      -0.09903836995363235,
      0.015570063143968582,
      -0.07021579891443253,
      -0.01347282063215971,
      0.015992509201169014,
      -0.15243473649024963,
      -0.04338829219341278,
      0.05508728697896004,
      -0.02949441596865654,
      0.0014070419128984213,
      0.013880160637199879,
      -0.02307431772351265,
      -0.02935187704861164,
      -0.09488742798566818,
      -0.047178205102682114,
      0.04935489222407341,
      0.050825390964746475,
      0.0463329441845417,
      0.1403835415840149,
      0.019211729988455772,
      -0.006575620733201504,
      -0.005124396178871393,
      -0.035335782915353775,
      -0.030279623344540596,
      0.05166773870587349,
      -0.028774205595254898,
      -0.026981789618730545,
      -0.025199739262461662,
      -0.00943190511316061,
      0.0358298160135746,
      -0.012635663151741028,
      0.08287054300308228,
      0.009748309850692749,
      0.026497269049286842,
      -0.07850481569766998,
      -0.003523424034938216,
      -0.02998397685587406,
      0.033850204199552536,
      0.028650328516960144,
      -0.07041869312524796,
      -0.04352639615535736,
      -0.07624054700136185,
      0.0097708934918046,
      0.014940643683075905,
      0.02289930172264576,
      -0.012282314710319042,
      -0.06729883700609207,
      -0.02832302264869213,
      -0.033024296164512634,
      0.05018385872244835,
      -0.000753153522964567,
      -0.016242848709225655,
      -0.016525540500879288,
      0.010792648419737816,
      -0.0866951271891594,
      -0.09959518909454346,
      0.04275403544306755,
      -0.07660295814275742,
      0.05704822763800621,
      -0.07096979022026062,
      -0.01341981627047062,
      0.06007448956370354,
      0.05032888799905777,
      -0.011966010555624962,
      0.07768874615430832,
      0.1195160448551178,
      0.004015116021037102,
      -0.04690387472510338,
      -0.0474478043615818,
      0.028296802192926407,
      0.0745391994714737,
      0.009494875557720661,
      -0.01927717961370945,
      -0.06868727505207062,
      0.06349653750658035,
      -0.020120011642575264,
      -0.024458536878228188,
      -0.008924297988414764,
      -0.05715756490826607,
      0.030796431005001068,
      -0.030705109238624573,
      -0.05397234857082367,
      -0.0037027716170996428,
      -0.06711158156394958,
      0.08360254019498825,
      -0.11416855454444885,
      0.02900071069598198,
      0.05320549011230469,
      0.05261916294693947,
      -0.010691086761653423,
      -0.03836556524038315,
      0.0032153555657714605,
      0.020107023417949677,
      0.027937723323702812,
      -0.010970483534038067,
      0.049048230051994324,
      -0.0009041969897225499,
      -0.04956407845020294,
      -0.010768868029117584,
      0.0014673270052298903,
      -0.04408787563443184,
      0.013934562914073467,
      0.096237413585186,
      -0.11847256869077682,
      -0.05219964310526848,
      -0.023048650473356247,
      0.02453763410449028,
      -0.0654369592666626,
      0.06820418685674667,
      0.031541042029857635,
      0.07761763036251068,
      0.09331642091274261,
      -0.00026283104671165347,
      -0.014404837973415852,
      0.0027083423919975758,
      -0.014516100287437439,
      -0.014734650030732155,
      -0.058888670057058334,
      0.027555767446756363,
      0.07223397493362427,
      -0.05862167105078697,
      0.0022473949939012527,
      -0.0523780956864357,
      0.021554559469223022,
      0.006987856701016426,
      -0.021278399974107742,
      0.04976148158311844,
      -0.011329982429742813,
      0.00838723685592413,
      -0.0016344645991921425,
      -0.0169216375797987,
      0.06329462677240372,
      -0.05790187790989876,
      -0.022185608744621277,
      0.035238370299339294,
      0.029253611341118813,
      -0.017356717959046364,
      0.003842115867882967,
      -0.006340356543660164,
      -0.03228912129998207,
      -0.07996531575918198,
      0.02604876086115837,
      0.09257220476865768,
      -0.030280757695436478,
      0.008559878915548325,
      -0.02317710593342781,
      0.062069423496723175,
      0.010840697214007378,
      0.03260931745171547,
      0.03543082997202873,
      0.009551138617098331,
      -0.04558214545249939,
      -0.0033904057927429676,
      0.03105364367365837,
      0.054020319133996964,
      -0.036955468356609344,
      0.05142927169799805,
      0.02310217171907425,
      -0.07146520912647247,
      -0.004211885388940573,
      0.03100145049393177,
      0.0016202625120058656,
      -0.07835797220468521,
      -0.04044327512383461,
      0.004462390206754208,
      -0.04577742516994476,
      0.005411249119788408,
      0.0019937604665756226,
      -0.02014785259962082,
      -0.023793507367372513,
      0.024004152044653893,
      -0.09108436107635498,
      -0.03585401922464371,
      -0.03987402096390724,
      -0.03811272978782654,
      0.03287529945373535,
      0.0599096454679966,
      0.08499034494161606,
      0.03331096097826958,
      0.05892661213874817,
      -0.0034787910990417004,
      0.06918299943208694,
      0.044531699270009995,
      -0.071188785135746,
      0.017587725073099136,
      0.00574834318831563,
      -0.05794885382056236,
      -0.00689846184104681,
      -0.023342063650488853,
      -0.022899236530065536,
      0.005378184374421835,
      -0.004206900950521231,
      -0.03188595548272133,
      -0.02610102854669094,
      -0.034334469586610794,
      -0.027137411758303642,
      -0.006623724475502968,
      -0.032305024564266205,
      0.02233136259019375,
      -0.003245313186198473,
      -0.06436721980571747,
      -0.012044617906212807,
      -0.0012429076014086604,
      -0.054487962275743484,
      0.04111786559224129,
      -0.007919528521597385,
      -0.07733060419559479,
      -0.016766423359513283,
      -0.07205983996391296,
      0.03260073438286781,
      0.06147683784365654,
      -0.0881194919347763,
      -0.023399481549859047,
      0.033077143132686615,
      0.048579707741737366,
      -0.007373463828116655,
      -0.0025901671033352613,
      -0.05479699373245239,
      -0.042795684188604355,
      0.016565417870879173,
      -0.012781630270183086,
      -0.042812492698431015,
      -0.031692419201135635,
      0.08628209680318832,
      0.014135465957224369,
      -0.02217695862054825,
      -0.015242429450154305,
      -0.04198354482650757,
      -0.06748377531766891,
      0.0028517136815935373,
      -0.008126185275614262,
      -0.028909649699926376,
      0.01972217857837677,
      -0.07455971837043762,
      0.029313402250409126,
      0.049998100847005844,
      -0.00692448066547513,
      -0.006051614880561829,
      0.04600369185209274,
      0.0007777761202305555,
      -0.009466614574193954,
      0.007900094613432884,
      -0.055691733956336975,
      -0.006752491928637028,
      -0.052058394998311996,
      -0.0710117295384407,
      0.03982621431350708,
      0.0253343153744936,
      -6.740802200511098e-05,
      -0.017753198742866516,
      -0.10121377557516098,
      -0.09193965047597885,
      -0.08170799911022186,
      -0.011573976837098598,
      -0.056121211498975754,
      0.032262951135635376,
      0.021680450066924095,
      -0.043747060000896454,
      -0.04510526359081268,
      -0.09214805066585541,
      -0.15219302475452423,
      0.06359119713306427,
      -0.0655948743224144,
      -0.03191152960062027,
      -0.024073457345366478,
      0.05941353365778923,
      0.0032723559997975826,
      0.06434807181358337,
      0.03360023349523544,
      0.07948917895555496,
      -0.056969285011291504,
      0.005554846953600645,
      0.013609033077955246,
      -0.0076950956135988235,
      -0.027879759669303894,
      0.013009514659643173,
      -0.06985356658697128,
      -0.07552772760391235,
      0.00991924200206995,
      -0.02235015667974949,
      -0.0015883734449744225,
      0.027919583022594452,
      0.013193176127970219,
      0.04523112624883652,
      -0.12798775732517242,
      -0.04094268009066582,
      0.01058018859475851,
      -0.009010939858853817,
      0.0015477765118703246,
      0.04208460822701454,
      0.02110421471297741,
      -0.04229762405157089,
      -0.04657697677612305,
      0.054239753633737564,
      0.016851207241415977,
      0.011601858772337437,
      0.011570539325475693,
      -0.06611157208681107,
      0.020905574783682823,
      -0.020574364811182022,
      0.014869601465761662,
      -0.031090840697288513,
      0.037387777119874954,
      -0.05322498083114624,
      0.07832998037338257,
      0.009163789451122284,
      -0.004871591925621033,
      -0.13544419407844543,
      -0.02770806849002838,
      -0.005138027016073465,
      0.04445094242691994,
      0.016347162425518036,
      -0.0030324780382215977,
      -0.03361092135310173,
      -0.021657750010490417,
      -0.0525796003639698,
      0.019988147541880608,
      0.04437517747282982,
      -0.0009227877017110586,
      -0.020862843841314316,
      -0.0005909628816880286,
      0.0014899082016199827,
      -0.010706081055104733,
      -0.006560599431395531,
      -0.004467159975320101,
      0.008981009013950825,
      0.005656134337186813,
      -0.00711699528619647,
      0.031410835683345795,
      0.016767146065831184,
      -0.03266536816954613,
      -0.03070511855185032,
      -0.026974672451615334,
      0.00823249388486147,
      0.037690505385398865,
      -0.0521673783659935,
      -0.0064325896091759205,
      -0.0469311848282814,
      -0.053720686584711075,
      0.0307632926851511,
      -0.0033380803652107716,
      -0.013886365108191967,
      -0.03825899213552475,
      -0.06483127921819687,
      -0.058109283447265625,
      -0.04431307315826416,
      0.001369702396914363,
      -0.06487511843442917,
      0.0591597855091095,
      0.05785341560840607,
      0.016886672005057335,
      -0.023348946124315262,
      0.04366282373666763,
      -0.05700609087944031,
      0.009634231217205524,
      -0.015392258763313293,
      -0.03618441894650459,
      0.02049785666167736,
      0.03975953534245491,
      0.02850990742444992,
      -0.025242047384381294,
      0.024678586050868034,
      0.03782448172569275,
      -0.03980192169547081,
      0.06526615470647812,
      0.04689868539571762,
      -0.012555931694805622,
      0.019764738157391548,
      -0.043515998870134354,
      -0.007906284183263779,
      -0.09245041012763977,
      0.06951955705881119,
      -0.010638957843184471,
      0.04080025479197502,
      -0.04087456688284874,
      -0.0337272584438324,
      -0.01135767251253128,
      -0.033829040825366974,
      -0.08443955332040787,
      0.07260967791080475,
      -0.029423702508211136,
      0.026996569707989693,
      0.04865429550409317,
      -0.035599805414676666,
      0.15519917011260986,
      -0.010213609784841537,
      0.04848717525601387,
      -0.10536996275186539,
      0.04343462735414505,
      0.08048320561647415,
      0.03320268914103508,
      -0.07038871943950653,
      0.053553350269794464,
      0.02127929963171482,
      -0.052163682878017426,
      0.060349661856889725,
      -0.001774921896867454,
      0.04274171218276024,
      -0.020185327157378197,
      0.0498674102127552,
      9.355204383609816e-05,
      0.03574930131435394,
      0.06459341943264008,
      0.0011002634419128299,
      -0.001929887686856091,
      0.04467892274260521,
      0.029346972703933716,
      -0.08238434791564941,
      -0.020603857934474945,
      -0.0462370403110981,
      -0.07222272455692291,
      0.051386188715696335,
      0.06259257346391678,
      -0.029615769162774086,
      -0.02738269977271557,
      0.027685467153787613,
      0.013003652915358543,
      -0.03262706845998764,
      -0.04390621557831764,
      -0.04027608036994934,
      0.03926660493016243,
      0.06576083600521088,
      -0.05305451527237892,
      0.0823134332895279,
      -0.01627102680504322,
      0.031787022948265076,
      0.012278283014893532,
      -0.044982343912124634,
      0.04551030322909355,
      0.000383624283131212,
      0.032765939831733704,
      -0.005850490182638168,
      -0.04446572810411453,
      7.496405305573717e-05,
      0.1095958799123764,
      0.016036856919527054,
      0.0014817282790318131,
      0.09581368416547775,
      0.05100037157535553,
      -0.002805855590850115,
      -0.001601625350303948,
      0.02908238396048546,
      -0.013903067447245121,
      0.0390833243727684,
      0.0999755859375,
      -0.01229819469153881,
      0.01813938282430172,
      -0.06872434169054031,
      0.011902383528649807,
      0.03922354429960251,
      -0.001493337214924395,
      0.006973200477659702,
      -0.05343084782361984,
      -0.05854129418730736,
      0.10459289699792862,
      0.008565112017095089,
      -0.02199903503060341,
      0.07853180170059204,
      0.02278241701424122,
      0.019200926646590233,
      0.018328551203012466,
      -0.03759055212140083,
      0.015024951659142971,
      0.020267024636268616,
      -0.006778000388294458,
      0.09616311639547348,
      0.041902776807546616,
      0.03522646054625511,
      0.0566251315176487,
      -0.017049742862582207,
      -0.05008681118488312,
      -0.014661461114883423,
      0.11677676439285278,
      -0.06567613780498505,
      -0.0237949937582016,
      -0.07315155863761902,
      -0.007735570427030325,
      0.025708215311169624,
      0.04203510656952858,
      -0.003319597104564309,
      -0.04771929234266281
    ],
    [
      -0.003965225536376238,
      0.09855341911315918,
      -0.014354851096868515,
      -0.03534793108701706,
      -0.004451818764209747,
      0.03170732408761978,
      -0.01339786034077406,
      -0.04848887771368027,
      0.025547031313180923,
      -0.04416494071483612,
      0.02683594636619091,
      -0.0046767862513661385,
      -0.06787266582250595,
      0.013826640322804451,
      -0.11835731565952301,
      -0.023190831765532494,
      -0.0014706902438774705,
      -0.024465883150696754,
      -0.03024384193122387,
      0.0936412587761879,
      0.02264614962041378,
      -0.08050034195184708,
      0.00044421988422982395,
      -0.01260948833078146,
      0.0011198968859389424,
      0.022946573793888092,
      -0.04274299368262291,
      -0.026972688734531403,
      -0.024575822055339813,
      -0.01297453697770834,
      -0.022766251116991043,
      -0.03863116726279259,
      -0.044585805386304855,
      -0.03333762660622597,
      -0.05031438544392586,
      0.003205097047612071,
      -0.092166967689991,
      -0.008503112941980362,
      0.0030152732506394386,
      -0.0361596941947937,
      0.14161044359207153,
      -0.03450457379221916,
      0.01963675208389759,
      -0.027670390903949738,
      -0.007052162196487188,
      0.05854317173361778,
      0.018660463392734528,
      0.10840588063001633,
      0.013468680903315544,
      -0.019475528970360756,
      -0.010495111346244812,
      -0.0020976548548787832,
      -0.009788536466658115,
      -0.0068489378318190575,
      -0.07831715047359467,
      -0.06479614228010178,
      -0.0007092443411238492,
      -0.05515270680189133,
      -0.01493196189403534,
      0.03568975627422333,
      -0.019213417544960976,
      -0.0968853235244751,
      -0.03542276844382286,
      0.05421869456768036,
      -0.012170102447271347,
      0.03688132017850876,
      -0.002623301697894931,
      -0.025182373821735382,
      0.004146104212850332,
      -0.07285915315151215,
      -0.02383098192512989,
      0.08267176151275635,
      0.0025283819995820522,
      0.04139712452888489,
      0.005910211242735386,
      -0.017467020079493523,
      0.02842012420296669,
      0.11213605105876923,
      0.03196185827255249,
      -0.025905761867761612,
      0.018232792615890503,
      -0.012938141822814941,
      -0.09940969944000244,
      0.026306089013814926,
      0.04118905961513519,
      -0.040677815675735474,
      0.0620107538998127,
      -0.004361635539680719,
      0.04599888250231743,
      -0.056244250386953354,
      0.02267562597990036,
      0.035615213215351105,
      0.031435154378414154,
      -0.00011070937034673989,
      -0.018961859866976738,
      0.02339557558298111,
      0.03238546475768089,
      0.02466025948524475,
      -0.015044419094920158,
      0.04708243906497955,
      -0.1016569510102272,
      0.09083526581525803,
      0.020319340750575066,
      -0.006002827547490597,
      0.030229374766349792,
      0.00015228304255288094,
      -0.05289006233215332,
      0.030310707166790962,
      -0.01969241350889206,
      -0.00588261429220438,
      -0.017264587804675102,
      0.003474922152236104,
      0.07146573811769485,
      -0.02695939503610134,
      -0.02141052857041359,
      -0.011541597545146942,
      0.007228898350149393,
      0.030206304043531418,
      0.10702896118164062,
      0.05664665997028351,
      0.014900042675435543,
      0.05385030061006546,
      0.00573782529681921,
      -0.06636785715818405,
      0.00336457253433764,
      0.0006687905988655984,
      -0.06783154606819153,
      -0.014863606542348862,
      0.029125573113560677,
      -0.0008682017796672881,
      0.04656326025724411,
      0.06870768219232559,
      -0.0022426024079322815,
      -0.06234690919518471,
      -0.02853642776608467,
      0.04802173748612404,
      -0.06954140216112137,
      0.00870862603187561,
      0.0033209077082574368,
      0.045396920293569565,
      0.0628509521484375,
      -0.0073425862938165665,
      -0.057650648057460785,
      -0.017028512433171272,
      -0.02108372189104557,
      0.014749796129763126,
      -3.367370482010301e-06,
      -0.06647972762584686,
      0.06102083995938301,
      0.02281772717833519,
      -0.07486364245414734,
      -0.07410243898630142,
      0.044808097183704376,
      0.04509015008807182,
      -0.07215151190757751,
      0.029120171442627907,
      -0.01648121327161789,
      0.012544907629489899,
      0.024230167269706726,
      -0.008911948651075363,
      0.0001388016389682889,
      -0.054648805409669876,
      0.05564986914396286,
      -0.0019641988910734653,
      -0.03431334346532822,
      -0.04616658389568329,
      -0.04157325625419617,
      0.014187490567564964,
      0.042389363050460815,
      -0.04517945274710655,
      0.009116195142269135,
      -0.09222052991390228,
      -0.033776309341192245,
      0.03202376514673233,
      0.006374898366630077,
      -0.046541646122932434,
      -0.06280940026044846,
      0.04405464977025986,
      0.026262449100613594,
      -0.016527118161320686,
      0.03475773334503174,
      -0.010560473427176476,
      0.018901819363236427,
      -0.04337809234857559,
      0.04420046880841255,
      -0.012677569873631,
      -0.031155509874224663,
      0.004324197303503752,
      0.04817003011703491,
      0.023451130837202072,
      0.028316648676991463,
      -0.030155519023537636,
      -0.04247771203517914,
      0.039333444088697433,
      0.033936694264411926,
      0.013520192354917526,
      0.007111797109246254,
      -0.011438130401074886,
      0.05333535373210907,
      0.013773796148598194,
      0.07416202872991562,
      -0.022014206275343895,
      0.02369450032711029,
      0.0161141287535429,
      0.08785635977983475,
      -0.0633210837841034,
      0.0898638516664505,
      -0.018920661881566048,
      0.10699053853750229,
      0.02324860170483589,
      -0.01010121125727892,
      0.002078258665278554,
      0.0290815532207489,
      0.015728365629911423,
      -0.004173744935542345,
      -0.047515664249658585,
      0.038291897624731064,
      0.07548365741968155,
      -0.04886554554104805,
      0.03690754249691963,
      0.03529209643602371,
      -0.04605289548635483,
      0.0023731908295303583,
      0.004763445816934109,
      -0.04120253399014473,
      0.032510507851839066,
      -0.0011103203287348151,
      -0.061044830828905106,
      0.03768790140748024,
      -0.0002901327970903367,
      -0.07980452477931976,
      0.02224123850464821,
      0.033018361777067184,
      0.0012243050150573254,
      0.0019144960679113865,
      -0.02307136356830597,
      -0.05102270841598511,
      0.016935914754867554,
      0.0448889285326004,
      0.11422110348939896,
      0.05187779664993286,
      0.010747157037258148,
      0.08082353323698044,
      0.0376056432723999,
      -0.08491019159555435,
      -0.0520799420773983,
      0.08012804388999939,
      0.00852129328995943,
      -0.014400566928088665,
      0.026071149855852127,
      -0.0009312618058174849,
      0.031140804290771484,
      0.021746166050434113,
      -0.04694850742816925,
      -0.025768360123038292,
      0.10035596787929535,
      0.022944778203964233,
      0.002157516311854124,
      -0.05201748386025429,
      -0.050168901681900024,
      -0.03005949966609478,
      0.06113161891698837,
      -0.057729121297597885,
      0.010631730780005455,
      0.005987266078591347,
      0.13077421486377716,
      -0.03267144039273262,
      -0.02096182107925415,
      0.04887917637825012,
      -0.022026125341653824,
      0.004016140941530466,
      -0.08519725501537323,
      0.0012791674816980958,
      -0.06974219530820847,
      0.07541165500879288,
      -0.06420619785785675,
      -0.01245386153459549,
      -0.05027821287512779,
      0.03486684337258339,
      0.05846456065773964,
      -0.029110949486494064,
      0.0017104895086959004,
      0.018098009750247,
      -0.04174984619021416,
      -0.02888726443052292,
      -0.03875305876135826,
      0.07990432530641556,
      0.007996306754648685,
      -0.02458302117884159,
      0.03362978622317314,
      0.01759561151266098,
      0.11947367340326309,
      -0.0563415102660656,
      -0.01042274571955204,
      -0.0547822043299675,
      0.003866323037073016,
      -0.06028293818235397,
      -0.03161908686161041,
      0.006509792525321245,
      -0.008916148915886879,
      -0.030873581767082214,
      -0.04334963113069534,
      -0.011973962187767029,
      -0.03489351272583008,
      -0.009518557228147984,
      0.04851454123854637,
      -0.05226245895028114,
      0.008438162505626678,
      0.0024573802947998047,
      -0.00826327409595251,
      -0.005067174788564444,
      0.02541734091937542,
      -0.009724747389554977,
      0.013784107752144337,
      -0.01418248564004898,
      -0.02138819359242916,
      -0.015051629394292831,
      0.04370567575097084,
      0.02607877179980278,
      0.09192944318056107,
      -0.0063972631469368935,
      -0.014578091911971569,
      0.048955801874399185,
      0.005079654976725578,
      -0.07089894264936447,
      0.009808547794818878,
      0.017661478370428085,
      0.03988359495997429,
      0.08286752551794052,
      0.01607879064977169,
      -0.040620267391204834,
      0.025356072932481766,
      -0.0012001184513792396,
      0.06911424547433853,
      -0.028368715196847916,
      0.0021235235035419464,
      0.09430640935897827,
      0.0003245642001274973,
      0.00726405531167984,
      0.041504211723804474,
      0.03356267511844635,
      -0.04778233543038368,
      -0.06988619267940521,
      -0.052774764597415924,
      -0.005580361932516098,
      -0.04537399485707283,
      -0.007824411615729332,
      -0.07088593393564224,
      -0.02781246043741703,
      -0.09293296188116074,
      -0.01902276650071144,
      -0.06722302734851837,
      0.06978789716959,
      -0.09738579392433167,
      -0.032710205763578415,
      0.0538521371781826,
      0.02489364705979824,
      -0.01909065619111061,
      -0.056803006678819656,
      -0.007663711439818144,
      -0.05283736810088158,
      -0.012788783758878708,
      0.004890538286417723,
      0.06087784096598625,
      0.04174364358186722,
      -0.08423002064228058,
      -0.023305879905819893,
      -0.013504823669791222,
      0.07749151438474655,
      -0.05377456545829773,
      -0.058944687247276306,
      -0.04277491569519043,
      -0.021805331110954285,
      0.025052253156900406,
      0.0044809202663600445,
      0.0112796975299716,
      -0.05626706779003143,
      0.020657334476709366,
      -0.07894015312194824,
      -0.049532175064086914,
      0.027569051831960678,
      0.1304534673690796,
      -0.033345192670822144,
      0.03649590536952019,
      -0.009934845380485058,
      0.024527452886104584,
      -0.013598361983895302,
      0.0029949455056339502,
      0.05115789547562599,
      -0.015849296003580093,
      -0.018081950023770332,
      0.023026973009109497,
      0.015323713421821594,
      -0.10075053572654724,
      0.031083667650818825,
      -6.764294084860012e-05,
      0.09938985109329224,
      -0.0514240525662899,
      -0.03905756399035454,
      -0.07311385869979858,
      -0.04249965026974678,
      -0.004316884558647871,
      0.04277971014380455,
      -0.009749443270266056,
      0.1550266146659851,
      0.030160173773765564,
      -0.019175004214048386,
      -0.030507083982229233,
      -0.045109011232852936,
      0.05318903923034668,
      0.1077059730887413,
      0.013267944566905499,
      -0.01894640363752842,
      -0.031061086803674698,
      0.05769334360957146,
      0.01872713677585125,
      -0.0017341942293569446,
      -0.038363322615623474,
      -0.04325081780552864,
      0.08935260027647018,
      0.055366598069667816,
      0.05410214886069298,
      -0.04860684648156166,
      0.018506590276956558,
      -0.038023341447114944,
      -0.018737217411398888,
      -0.030864767730236053,
      0.012674613855779171,
      0.009025787934660912,
      -0.09633861482143402,
      -0.043835949152708054,
      -0.03659365326166153,
      -0.051787540316581726,
      0.0012670806609094143,
      -0.040126651525497437,
      -0.062317509204149246,
      0.0808502584695816,
      0.006246830336749554,
      -0.031813934445381165,
      -0.04222884029150009,
      0.01925833150744438,
      0.055918432772159576,
      -0.044855426996946335,
      0.015188186429440975,
      0.011524572037160397,
      0.07910066843032837,
      0.060101255774497986,
      -0.027173031121492386,
      0.05305538326501846,
      0.05126691982150078,
      0.0055305687710642815,
      -0.04927265644073486,
      -0.0033750133588910103,
      -0.06120684742927551,
      -0.017628945410251617,
      0.058749519288539886,
      -0.01690332405269146,
      0.02021772600710392,
      0.006070215255022049,
      0.0024277213960886,
      -0.008320743218064308,
      -0.05873795598745346,
      0.0592796690762043,
      -0.006272486876696348,
      -0.04365310072898865,
      -0.05474313721060753,
      0.03249863535165787,
      -0.03940117359161377,
      0.01680436171591282,
      0.032871540635824203,
      -0.009535681456327438,
      -0.020660782232880592,
      0.019340358674526215,
      0.01282410603016615,
      0.001771728741005063,
      -0.006316607818007469,
      0.0017934442730620503,
      0.04227052256464958,
      0.043885525315999985,
      -0.02749304473400116,
      -0.05174889788031578,
      -0.06846499443054199,
      0.08529136329889297,
      0.013945493847131729,
      0.04669579863548279,
      0.009206356480717659,
      0.03665031120181084,
      0.02383653074502945,
      -0.05763281136751175,
      -0.10322977602481842,
      -0.013331650756299496,
      0.035201217979192734,
      -0.014423314481973648,
      0.012783235870301723,
      0.00813006516546011,
      0.046492986381053925,
      0.0575735904276371,
      -0.11346210539340973,
      -0.016145633533596992,
      0.03563792258501053,
      0.004148653708398342,
      -0.00899866595864296,
      -0.022784605622291565
    ],
    [
      -0.004693148657679558,
      0.09070324152708054,
      -0.006246919743716717,
      -0.017671920359134674,
      0.007082390133291483,
      0.08916717767715454,
      0.034185439348220825,
      0.04067152366042137,
      -0.036352694034576416,
      -0.015593699179589748,
      0.0021779784001410007,
      -0.006641671527177095,
      -0.015301511622965336,
      -0.010215433314442635,
      0.01693076081573963,
      -0.03135328367352486,
      -0.07947725057601929,
      0.04778028652071953,
      0.009516626596450806,
      0.034348104149103165,
      0.02537393756210804,
      0.008304987102746964,
      -0.0021018481347709894,
      0.014746125787496567,
      0.06443072110414505,
      -0.007848156616091728,
      -0.020054424181580544,
      -0.051260970532894135,
      0.050682183355093,
      0.01988762617111206,
      -0.02904392220079899,
      -0.012229626066982746,
      -0.008888897486031055,
      0.0014107130700722337,
      -0.007824377156794071,
      0.04122474417090416,
      -0.06618328392505646,
      -0.04735167697072029,
      -0.0334317684173584,
      0.02132381871342659,
      0.054132938385009766,
      -0.0009923322359099984,
      0.06865531206130981,
      -0.0996432676911354,
      -0.05424077808856964,
      0.006697449367493391,
      -0.05216245353221893,
      0.03738661855459213,
      0.026062797755002975,
      0.026847057044506073,
      -0.06293231248855591,
      -0.050390973687171936,
      -0.03790583834052086,
      -0.1115250512957573,
      0.07503839582204819,
      0.0060388618148863316,
      -0.08990883082151413,
      0.022762438282370567,
      -0.04214075580239296,
      0.0006732457550242543,
      0.03523658215999603,
      -0.04967838525772095,
      0.036241646856069565,
      0.015149500221014023,
      -0.04876790940761566,
      0.0008721572230570018,
      -0.01473079714924097,
      -0.03160542622208595,
      0.04415171965956688,
      -0.03444066271185875,
      -0.030177965760231018,
      0.10660236328840256,
      -0.06009455770254135,
      -0.020798848941922188,
      -0.008060709573328495,
      -0.010531489737331867,
      0.025244630873203278,
      -0.0344691276550293,
      0.011492784135043621,
      0.02018435113132,
      -0.06694485992193222,
      0.010327575728297234,
      0.0004589258460327983,
      -0.03237249702215195,
      -0.04537495598196983,
      -0.004721027333289385,
      0.07277729362249374,
      0.039641644805669785,
      -0.06780003011226654,
      0.034239187836647034,
      -0.054567206650972366,
      0.05530860275030136,
      -0.02634153887629509,
      -0.03918867185711861,
      0.04132134094834328,
      0.006407723296433687,
      -0.017709270119667053,
      -0.028663353994488716,
      -0.047725774347782135,
      -0.04689188674092293,
      0.0068757436238229275,
      0.009517009370028973,
      -0.014435602352023125,
      0.009676412679255009,
      0.0740988552570343,
      -0.10742083191871643,
      -0.03499453142285347,
      -0.018080392852425575,
      -0.062302008271217346,
      -0.0782817006111145,
      0.05257994681596756,
      -0.024178821593523026,
      0.02145594358444214,
      0.03365476801991463,
      0.009717981331050396,
      0.04150349274277687,
      0.019255636259913445,
      -0.014116352424025536,
      0.033375635743141174,
      0.017315885052084923,
      0.04503939300775528,
      0.03579672425985336,
      -0.054870616644620895,
      0.007412727456539869,
      -0.012587472796440125,
      0.09280458837747574,
      -0.006034797988831997,
      -0.034426864236593246,
      -0.01582629792392254,
      0.043854519724845886,
      -0.03134765103459358,
      0.013393971137702465,
      -0.04438728094100952,
      0.002935335971415043,
      0.0052593848668038845,
      -0.011902998201549053,
      0.03661864623427391,
      -0.04250764101743698,
      0.010002339258790016,
      -0.007216583471745253,
      -0.03657873719930649,
      -0.00952294934540987,
      0.037051502615213394,
      0.015348110347986221,
      -0.10454565286636353,
      -0.010679186321794987,
      -0.03635706007480621,
      -0.015348927117884159,
      -0.03753751143813133,
      0.04615407809615135,
      -0.05450785532593727,
      -0.03289993852376938,
      0.01941824145615101,
      0.037968795746564865,
      0.02225838601589203,
      -0.02189822494983673,
      0.07938224077224731,
      -0.05463274195790291,
      0.05779688432812691,
      0.09664377570152283,
      0.04932845011353493,
      0.08655986189842224,
      -0.0919610857963562,
      -0.03252728283405304,
      0.014203185215592384,
      -0.003827726235613227,
      0.013286272995173931,
      -0.011816006153821945,
      -0.02074749395251274,
      -0.021878942847251892,
      0.08966966718435287,
      0.060158949345350266,
      0.06887504458427429,
      -0.009157982654869556,
      -0.059912361204624176,
      -0.005175387486815453,
      0.027240445837378502,
      0.0030017865356057882,
      0.04888032749295235,
      0.09291628748178482,
      0.005592620465904474,
      -0.03627121075987816,
      0.08128070086240768,
      0.012258974835276604,
      0.058667704463005066,
      0.007545121945440769,
      -0.03617070987820625,
      -0.007845427840948105,
      0.05593496561050415,
      0.021695515140891075,
      0.012914201244711876,
      -0.06695243716239929,
      0.02505609393119812,
      0.02373778074979782,
      -0.028870930895209312,
      0.01207675039768219,
      0.01955617591738701,
      0.050990331918001175,
      -0.061913009732961655,
      -0.02849319763481617,
      0.028611423447728157,
      0.02402607351541519,
      0.02397061511874199,
      0.10435512661933899,
      0.09107666462659836,
      0.06638734042644501,
      -0.04987819865345955,
      0.08488951623439789,
      -0.04422459006309509,
      0.11018292605876923,
      -0.030928684398531914,
      -0.03317103162407875,
      -0.01656569540500641,
      0.03196541965007782,
      0.008464493788778782,
      0.06234064698219299,
      -0.0021802305709570646,
      0.023198353126645088,
      0.058609407395124435,
      -0.015332677401602268,
      0.02923608385026455,
      0.06685633212327957,
      -0.06926316767930984,
      0.022727379575371742,
      0.008233042433857918,
      0.08681102842092514,
      0.004127411171793938,
      -0.043863970786333084,
      0.08496351540088654,
      -0.11086918413639069,
      0.024172339588403702,
      0.09375251829624176,
      -0.00035215693060308695,
      0.020531922578811646,
      -0.052823469042778015,
      -0.028785936534404755,
      0.02326214499771595,
      0.03672591224312782,
      0.056338049471378326,
      -0.004855196923017502,
      -0.041456982493400574,
      -0.05613529682159424,
      0.002421018900349736,
      0.07932204008102417,
      -0.04764071851968765,
      -0.05496776103973389,
      -0.020337460562586784,
      0.09282143414020538,
      0.07688815891742706,
      0.023179151117801666,
      -0.006680880207568407,
      -0.07830063253641129,
      0.017192909494042397,
      0.06558161228895187,
      0.025625864043831825,
      -0.004055906552821398,
      0.007283689454197884,
      0.006413402035832405,
      -0.04494196176528931,
      0.005080922041088343,
      -0.007593533955514431,
      0.04821975156664848,
      0.024257978424429893,
      -0.11715493351221085,
      -0.06867412477731705,
      0.08647533506155014,
      0.011765876784920692,
      -0.02721213549375534,
      -0.047174885869026184,
      -0.054857030510902405,
      -0.007265247870236635,
      0.02876819297671318,
      0.06041477993130684,
      -0.0029364172369241714,
      0.02850957214832306,
      0.018965529277920723,
      0.0410158708691597,
      -0.08688005059957504,
      0.0252116397023201,
      -0.0635528415441513,
      0.03673040121793747,
      -0.016144098713994026,
      0.038566067814826965,
      -0.030430423095822334,
      -0.07159459590911865,
      0.06070544943213463,
      0.005509905982762575,
      0.014904109761118889,
      -0.007441892754286528,
      -0.008105018176138401,
      -0.05814041942358017,
      -0.05479982867836952,
      0.011140726506710052,
      -0.01563754677772522,
      0.056565649807453156,
      -0.007454595062881708,
      -0.003602126846089959,
      -0.03759598731994629,
      -0.06245908886194229,
      -0.07985122501850128,
      0.04996007680892944,
      0.005537820048630238,
      -0.05922556295990944,
      -0.018168123438954353,
      0.03385070711374283,
      -0.025040648877620697,
      0.056297436356544495,
      -0.03792767599225044,
      -0.005495807621628046,
      -0.03444688767194748,
      0.014787140302360058,
      -0.0438617542386055,
      -0.05198797211050987,
      -0.030073469504714012,
      0.06551305949687958,
      -0.030067356303334236,
      0.018791308626532555,
      -0.017620952799916267,
      0.004006191622465849,
      0.03297024220228195,
      0.09180887043476105,
      -0.06130822002887726,
      0.013714343309402466,
      -0.003916471730917692,
      -0.0018208150286227465,
      0.003962239250540733,
      0.01357439998537302,
      0.024781519547104836,
      0.05440596491098404,
      0.049823932349681854,
      -0.0067572444677352905,
      -0.025951148942112923,
      0.07964203506708145,
      0.045065753161907196,
      -0.04383072629570961,
      -0.006523262243717909,
      0.011331921443343163,
      -0.0017526487354189157,
      -0.08906365185976028,
      0.02645428292453289,
      0.00515169370919466,
      0.03579041361808777,
      -0.03812122344970703,
      0.022358745336532593,
      -0.005185440648347139,
      -0.07027184218168259,
      0.03627501055598259,
      -0.017115391790866852,
      -0.07142943143844604,
      -0.024864768609404564,
      -0.025600656867027283,
      0.036217302083969116,
      -0.016396790742874146,
      0.007969129830598831,
      -0.01988193206489086,
      -0.07632854580879211,
      -0.04654553532600403,
      0.07866817712783813,
      0.07716529816389084,
      -0.0746239572763443,
      0.023974401876330376,
      -0.02634505182504654,
      0.04151945933699608,
      0.047650597989559174,
      -0.006332624237984419,
      0.04710415005683899,
      0.057899896055459976,
      0.06582918763160706,
      0.018185004591941833,
      -0.08820194751024246,
      -0.13831155002117157,
      -0.015497596934437752,
      0.005118050612509251,
      0.006755732465535402,
      -0.04441371187567711,
      -0.09293866157531738,
      -0.07951626181602478,
      0.014968007802963257,
      -0.02502477914094925,
      0.08080705255270004,
      -0.02726929262280464,
      0.004484052769839764,
      -0.04716048762202263,
      0.03141704201698303,
      -0.01748914271593094,
      -0.01737932488322258,
      -0.010265585035085678,
      0.05571531131863594,
      -0.04646043851971626,
      -0.06517603248357773,
      0.00491331797093153,
      0.046186663210392,
      0.016572559252381325,
      0.018003107979893684,
      0.01051278691738844,
      -0.08168616890907288,
      -0.011654792353510857,
      -0.011800022795796394,
      0.027111446484923363,
      -0.10061534494161606,
      -0.050910841673612595,
      0.05569427087903023,
      0.02645936980843544,
      -0.0667089968919754,
      0.047247935086488724,
      0.03848620504140854,
      0.048570483922958374,
      0.007609188091009855,
      -0.04026811569929123,
      -0.1062733605504036,
      0.015688398852944374,
      0.02887469157576561,
      0.045760657638311386,
      -0.01324875745922327,
      -0.03977815434336662,
      -0.07821109145879745,
      -0.0344887375831604,
      -0.026520363986492157,
      0.04296080768108368,
      0.014482185244560242,
      -0.01944313570857048,
      0.037183333188295364,
      0.013703595846891403,
      -0.021671952679753304,
      -0.010975142940878868,
      -0.01567002758383751,
      0.10061439871788025,
      0.04312499985098839,
      0.04162684455513954,
      0.016763700172305107,
      -0.023539168760180473,
      -0.02642831951379776,
      0.021504763513803482,
      0.07406192272901535,
      0.03266220912337303,
      0.02699115127325058,
      -0.04532121494412422,
      0.00898604467511177,
      0.11834626644849777,
      0.023705221712589264,
      -0.017649078741669655,
      0.038317177444696426,
      0.07122483104467392,
      0.017815878614783287,
      0.03951159119606018,
      0.05835367366671562,
      -0.11567597836256027,
      0.05278009548783302,
      -0.0035610392224043608,
      0.10146217048168182,
      0.08366220444440842,
      -0.048111021518707275,
      0.010088011622428894,
      -0.07111075520515442,
      0.04930949956178665,
      0.050213780254125595,
      0.03048923797905445,
      -0.004442431032657623,
      -0.07322821766138077,
      -0.014232211746275425,
      -0.005779511760920286,
      -0.08430326730012894,
      0.04510520398616791,
      -0.0593661367893219,
      -0.030652575194835663,
      -0.06719204783439636,
      0.007026180159300566,
      0.011516448110342026,
      0.09775716811418533,
      -0.02700987458229065,
      0.077291339635849,
      -0.045944586396217346,
      -0.10124079883098602,
      -0.036866120994091034,
      0.025157753378152847,
      -0.07593526691198349,
      0.057446230202913284,
      -0.008460013195872307,
      -0.02036578767001629,
      -0.05716918036341667,
      0.05466197058558464,
      -0.03188943862915039,
      0.07212071120738983,
      0.0018874609377235174,
      0.06116652488708496,
      0.007616644259542227,
      0.07838114351034164,
      0.019404560327529907,
      -0.005553902126848698,
      -0.00718714902177453,
      0.07246119529008865,
      -0.1057206243276596,
      0.03589443489909172,
      -0.059280429035425186,
      0.08406585454940796,
      0.08110205084085464,
      0.031020205467939377,
      -0.002905379282310605,
      -0.0067648799158632755,
      0.02829405665397644,
      -0.08011843264102936,
      -0.05586119741201401,
      -0.001493464456871152
    ],
    [
      0.005039760377258062,
      0.02466772124171257,
      -0.008833996020257473,
      0.030969101935625076,
      0.00740315904840827,
      0.011162493377923965,
      0.03691473230719566,
      -0.09075915813446045,
      -0.04800856485962868,
      0.042587485164403915,
      0.03035450167953968,
      -0.00012260755465831608,
      -0.03147588670253754,
      -0.005558741744607687,
      -0.036082178354263306,
      0.059535346925258636,
      0.0421409010887146,
      -0.03219813480973244,
      0.027404233813285828,
      -0.062010109424591064,
      0.027256587520241737,
      -0.04596332088112831,
      0.014794256538152695,
      -0.08997324854135513,
      0.007337971590459347,
      0.0426180437207222,
      -0.046503014862537384,
      0.08335841447114944,
      0.00646626204252243,
      -0.0012767636217176914,
      0.008253161795437336,
      0.08742519468069077,
      -0.0780537948012352,
      0.11326984316110611,
      -0.07303597778081894,
      -0.04665317386388779,
      0.03676404803991318,
      -0.02881813794374466,
      -0.040342509746551514,
      0.015988746657967567,
      0.050249192863702774,
      0.003952802624553442,
      0.014155123382806778,
      0.021288620308041573,
      0.05691668763756752,
      -0.0808575227856636,
      -0.004674363415688276,
      0.006102952640503645,
      0.0026901597157120705,
      -0.06222357228398323,
      -0.09691020846366882,
      0.039685554802417755,
      -0.055357448756694794,
      -0.020270688459277153,
      0.022413473576307297,
      0.011600433848798275,
      -0.04273726046085358,
      -0.07957860827445984,
      -0.0025383757892996073,
      0.08345785737037659,
      0.02947954647243023,
      0.011473461985588074,
      0.006494585424661636,
      -0.02469620294868946,
      0.10906071960926056,
      0.018660971894860268,
      -0.011331574060022831,
      0.018133459612727165,
      0.009408573620021343,
      0.029612287878990173,
      0.03551690652966499,
      0.039142049849033356,
      -0.03369677811861038,
      -0.04011742025613785,
      -0.07010772824287415,
      -0.028962373733520508,
      0.05154886469244957,
      -0.07367079704999924,
      0.07031349092721939,
      0.0744563564658165,
      0.026029126718640327,
      0.10240141302347183,
      -0.006019576918333769,
      0.03507952392101288,
      -0.03575752675533295,
      -0.09324865788221359,
      -0.028696930035948753,
      -0.0038430436979979277,
      0.041504766792058945,
      -0.031036822125315666,
      0.05911111831665039,
      -0.002820886904373765,
      0.04003412649035454,
      -0.02841145172715187,
      0.024599462747573853,
      -0.01604503020644188,
      0.03922906517982483,
      -0.01028155442327261,
      -0.07445014268159866,
      -0.0199555903673172,
      0.051528919488191605,
      0.04132611304521561,
      0.03686387464404106,
      -0.014302344992756844,
      -0.00247212708927691,
      0.02896784245967865,
      0.05025659501552582,
      0.026020484045147896,
      -0.06750421971082687,
      -0.1263817846775055,
      0.0185229629278183,
      0.047991447150707245,
      -0.03038710169494152,
      -0.05081953853368759,
      -0.07103142887353897,
      0.022905495017766953,
      0.018125412985682487,
      -0.012318987399339676,
      0.03692351281642914,
      0.05956337973475456,
      -0.12263665348291397,
      0.048310551792383194,
      -0.05158977955579758,
      -0.08865303546190262,
      -0.007693968713283539,
      -0.018600977957248688,
      -0.010390949435532093,
      0.09201787412166595,
      -0.010992752388119698,
      -0.028339920565485954,
      0.051193613559007645,
      -0.11003075540065765,
      -0.0289875790476799,
      -0.036281730979681015,
      0.049879662692546844,
      -0.012326465919613838,
      0.02506847307085991,
      0.10300108790397644,
      -0.03182169795036316,
      -0.067267045378685,
      -0.01156806480139494,
      -0.004444528836756945,
      -0.036573730409145355,
      -0.002587338676676154,
      -0.07072129845619202,
      -0.10567668080329895,
      0.07444443553686142,
      -0.024446671828627586,
      -0.032085247337818146,
      -0.0030217550229281187,
      -0.039953768253326416,
      0.023121226578950882,
      -0.024193426594138145,
      0.055669840425252914,
      -0.033713001757860184,
      0.017375998198986053,
      -0.004894847050309181,
      0.062799833714962,
      0.01593026891350746,
      0.027755217626690865,
      -0.033428389579057693,
      -0.04973863065242767,
      -0.097691610455513,
      0.07375197857618332,
      -0.019641214981675148,
      -0.007021933328360319,
      0.04435081034898758,
      -0.0012327986769378185,
      0.07657066732645035,
      -0.02544185146689415,
      -0.0037114317528903484,
      -0.027870427817106247,
      -0.004155964590609074,
      -0.04427199438214302,
      -0.03360312059521675,
      0.0036289088893681765,
      0.042703915387392044,
      -0.02662796527147293,
      0.11467248201370239,
      -0.04398117959499359,
      0.020405633375048637,
      -0.04511761665344238,
      -0.006987384986132383,
      -0.03475476801395416,
      0.03180861473083496,
      -0.04882907122373581,
      0.008023811504244804,
      -0.004062139429152012,
      0.02261473424732685,
      -0.06600257754325867,
      0.035702046006917953,
      0.044854871928691864,
      0.005857980810105801,
      0.011776372790336609,
      -0.003935308661311865,
      -0.03744780272245407,
      -0.07144219428300858,
      -0.07227277755737305,
      0.015347727574408054,
      0.04792443662881851,
      0.024655168876051903,
      0.03214370459318161,
      -0.013250669464468956,
      -0.0014971868367865682,
      -0.0022592293098568916,
      0.042544495314359665,
      -0.036342110484838486,
      0.07309439778327942,
      -0.044738028198480606,
      -0.05867057293653488,
      -0.06105833873152733,
      0.05271441489458084,
      -0.044181469827890396,
      -0.01360855158418417,
      -0.06943083554506302,
      -0.022522199898958206,
      0.009137317538261414,
      0.0013644592836499214,
      -0.029294811189174652,
      0.015338779427111149,
      0.03479813411831856,
      -0.027044886723160744,
      -0.020918777212500572,
      -0.018857138231396675,
      0.025120869278907776,
      -0.02632395550608635,
      0.03007359243929386,
      0.0066250828094780445,
      0.0033360098022967577,
      -0.0005131986690685153,
      0.11647845059633255,
      -0.0030737232882529497,
      0.035911496728658676,
      -0.049431078135967255,
      0.07601097971200943,
      -0.10517378151416779,
      -0.09445884823799133,
      -0.055586833506822586,
      -0.010959246195852757,
      0.041976332664489746,
      0.003961264155805111,
      -0.024004016071558,
      0.056682538241147995,
      -0.004247591365128756,
      -0.08230922371149063,
      -0.012465915642678738,
      -0.035941872745752335,
      0.022984033450484276,
      0.007504955865442753,
      -0.05769350379705429,
      -0.02795187570154667,
      -0.02197953872382641,
      0.05746205896139145,
      0.04944435879588127,
      0.06405084580183029,
      0.04178251698613167,
      -0.06634709239006042,
      -0.03477766737341881,
      0.07754110544919968,
      -0.02067164145410061,
      0.02850564755499363,
      -0.017764586955308914,
      0.005054265260696411,
      0.06323827058076859,
      -0.0220420453697443,
      -0.04699665307998657,
      -0.06676224619150162,
      -0.051413845270872116,
      0.001782614504918456,
      -0.016565639525651932,
      -0.02671351283788681,
      0.028513312339782715,
      0.08177211880683899,
      0.02548830397427082,
      0.03879322484135628,
      -0.014964605681598186,
      -0.02577008679509163,
      -0.011363876983523369,
      -0.03919835761189461,
      -0.07649628072977066,
      -0.016648082062602043,
      -0.0012076664716005325,
      0.03420286625623703,
      -0.01875249296426773,
      0.0010975563200190663,
      0.08537425100803375,
      0.01671772077679634,
      -0.04342934116721153,
      0.018767187371850014,
      -0.08143339306116104,
      0.034806013107299805,
      0.03229287639260292,
      0.005431591998785734,
      -0.010757301934063435,
      0.004412724636495113,
      0.006261155940592289,
      -0.044925298541784286,
      0.007184906862676144,
      -0.07988951355218887,
      -0.0063097854144871235,
      0.043527752161026,
      -0.002752140164375305,
      0.047417521476745605,
      0.09940506517887115,
      0.06501711905002594,
      -0.08234506100416183,
      0.04056765139102936,
      0.006839602254331112,
      0.009558478370308876,
      -0.007884637452661991,
      -0.029025282710790634,
      -0.04425358027219772,
      0.02507401444017887,
      -0.004294567741453648,
      -0.010275608859956264,
      -0.062071844935417175,
      -0.04540073871612549,
      -0.002138572046533227,
      -0.006311613600701094,
      -0.02880421280860901,
      -0.0009834073716774583,
      0.01761017180979252,
      0.0027488514315336943,
      -0.06361676752567291,
      -0.05716962739825249,
      -0.05160626396536827,
      -0.034126535058021545,
      -0.046251844614744186,
      0.07935740798711777,
      -0.05105241760611534,
      -0.04596763476729393,
      -0.03756646066904068,
      0.023189812898635864,
      -0.021337414160370827,
      0.0223146490752697,
      0.038151856511831284,
      -0.004993433132767677,
      0.04289735481142998,
      0.03473728522658348,
      0.007285706233233213,
      0.009463687427341938,
      0.09499828517436981,
      0.009818040765821934,
      -0.008657203055918217,
      -0.005926108919084072,
      0.033799175173044205,
      0.015775013715028763,
      0.018842853605747223,
      -0.01077662780880928,
      -0.018166258931159973,
      -0.04575745761394501,
      -0.020106958225369453,
      -0.07438481599092484,
      0.02117595262825489,
      -0.039640020579099655,
      -0.0009402263094671071,
      0.025813214480876923,
      -0.08274691551923752,
      -0.021365199238061905,
      0.08568334579467773,
      0.012940075248479843,
      -0.09327471256256104,
      0.047059111297130585,
      -0.04620093107223511,
      0.011152848601341248,
      -0.032304055988788605,
      0.07270961254835129,
      0.03276515007019043,
      0.0076278517954051495,
      -0.021904250606894493,
      0.053504496812820435,
      -0.013514019548892975,
      -0.017707213759422302,
      0.05318979173898697,
      -0.006211166735738516,
      -0.008720435202121735,
      -0.0019326165784150362,
      0.021254505962133408,
      0.007556037977337837,
      0.03589113429188728,
      0.011769005097448826,
      0.06556787341833115,
      -0.002078995108604431,
      0.05394510179758072,
      -0.006305832881480455,
      0.025989191606640816,
      -0.02352032996714115,
      0.053052861243486404,
      0.06977356225252151,
      0.03689710423350334,
      -0.03248419240117073,
      0.09098710119724274,
      -0.004617059603333473,
      0.018922584131360054,
      0.013962425291538239,
      0.07976032793521881,
      0.042667701840400696,
      0.024440379813313484,
      0.035905368626117706,
      0.012309589423239231,
      -0.055263787508010864,
      -0.017925243824720383,
      -0.06561437249183655,
      -0.02366240695118904,
      0.042818449437618256,
      0.03735446557402611,
      0.0020371878053992987,
      -0.003449198557063937,
      -0.057836320251226425,
      -0.020482780411839485,
      0.009230699390172958,
      -0.031174099072813988,
      -0.0022648603189736605,
      -0.014447730034589767,
      -0.002581875305622816,
      0.04650431498885155,
      -0.007639541756361723,
      -0.08620429784059525,
      -0.019376076757907867,
      0.011773491278290749,
      0.09753355383872986,
      0.07758906483650208,
      -0.061969365924596786,
      -0.009069046005606651,
      0.03651906177401543,
      -0.025183795019984245,
      -0.04004267230629921,
      0.026939261704683304,
      -0.049542441964149475,
      -0.02253853529691696,
      0.08362685143947601,
      0.0542120523750782,
      0.01273332443088293,
      -0.015900548547506332,
      0.0694478452205658,
      0.08591808378696442,
      0.008932557888329029,
      -0.006337357219308615,
      -0.0015682409284636378,
      -0.05065632611513138,
      -0.07974375039339066,
      -0.0821199044585228,
      0.002194944303482771,
      0.021097445860505104,
      -0.036958299577236176,
      0.01715628243982792,
      0.008144126273691654,
      0.038594044744968414,
      -0.021495841443538666,
      0.018208714202046394,
      0.056339990347623825,
      -0.14050662517547607,
      0.00996605958789587,
      0.021311288699507713,
      -0.02016260288655758,
      -0.012202482670545578,
      -0.01236331183463335,
      -0.0020779636688530445,
      0.04831361025571823,
      0.05512985214591026,
      0.05595630034804344,
      -0.06040747091174126,
      -0.031207123771309853,
      -0.025150051340460777,
      -0.05812757834792137,
      -0.0623219795525074,
      0.12279719859361649,
      -0.05210885405540466,
      0.0392034538090229,
      0.0013291070936247706,
      -0.026486562564969063,
      -0.026904990896582603,
      -0.04082810506224632,
      -0.016730818897485733,
      0.021945800632238388,
      -0.06165337562561035,
      0.1062207892537117,
      -0.04042275995016098,
      0.021226849406957626,
      -0.02186484821140766,
      0.026828281581401825,
      -0.09687913209199905,
      -0.062366824597120285,
      -0.00454408023506403,
      -0.0224659014493227,
      -0.025775890797376633,
      0.06222217157483101,
      0.0710907056927681,
      -0.06922011822462082,
      -0.021303316578269005,
      0.05563889816403389,
      -0.05528375878930092,
      0.03127913922071457,
      -0.022587662562727928,
      0.03690497577190399,
      -0.011516372673213482,
      -0.007029752712696791,
      0.021129917353391647,
      -0.08200306445360184,
      0.04707442224025726,
      0.031804393976926804,
      -0.06806239485740662,
      -0.08183278143405914
    ],
    [
      0.012841884978115559,
      -0.026136130094528198,
      0.0031317139510065317,
      0.026237115263938904,
      -0.10151275992393494,
      0.0751965269446373,
      -0.02996697835624218,
      -0.0022917406167834997,
      -0.005228349473327398,
      0.0921015739440918,
      -0.07176579535007477,
      -0.013883845880627632,
      -0.03596499189734459,
      -0.0213791411370039,
      -0.011028270237147808,
      -0.009911660104990005,
      0.08439916372299194,
      0.034755367785692215,
      0.018321016803383827,
      0.040695007890462875,
      0.011900563724339008,
      0.008720871061086655,
      0.030766934156417847,
      0.027708880603313446,
      0.01340899895876646,
      0.002858969150111079,
      -0.049357689917087555,
      -0.059143051505088806,
      0.0206076018512249,
      0.020137470215559006,
      -0.1390720158815384,
      -0.008758646436035633,
      -0.03735174983739853,
      -0.027863457798957825,
      0.002726184669882059,
      -0.031190553680062294,
      0.012571369297802448,
      -0.030145376920700073,
      0.012526062317192554,
      -0.11521884799003601,
      0.009774050675332546,
      -0.007590689230710268,
      -0.03378591686487198,
      -0.035165004432201385,
      -0.011826571077108383,
      0.042655035853385925,
      -0.042234841734170914,
      0.01636185683310032,
      -0.032853901386260986,
      -0.002485396806150675,
      -0.030088480561971664,
      -0.04280337691307068,
      0.054135311394929886,
      -0.06485651433467865,
      0.007090489845722914,
      -0.010481246747076511,
      -0.03055676259100437,
      0.06796793639659882,
      0.044003862887620926,
      -0.021717244759202003,
      0.03958650678396225,
      0.037511296570301056,
      -0.003798942780122161,
      -0.0539836585521698,
      0.05821481719613075,
      0.019541583955287933,
      -0.02670060098171234,
      -0.015098894946277142,
      -0.063319131731987,
      -0.018084963783621788,
      0.07350818812847137,
      -0.05954631045460701,
      0.06112951785326004,
      -0.024790722876787186,
      -0.07763013243675232,
      0.0014395453035831451,
      0.06300685554742813,
      -0.05525420606136322,
      -0.0018893865635618567,
      0.013647113926708698,
      0.008266774006187916,
      0.02013838291168213,
      -0.036534085869789124,
      -0.05865313485264778,
      -0.005028281360864639,
      -0.019895799458026886,
      0.06964456290006638,
      -0.0778074860572815,
      0.06577855348587036,
      0.005512547213584185,
      -0.025293635204434395,
      0.05422652140259743,
      0.004900782834738493,
      0.01429810095578432,
      -0.019955649971961975,
      0.05362037569284439,
      0.02907346561551094,
      -0.012893911451101303,
      -0.08092492073774338,
      0.0328238420188427,
      0.0033879196271300316,
      0.025212937965989113,
      0.010433469898998737,
      -0.045161373913288116,
      -0.00819677859544754,
      -0.042783983051776886,
      0.06321355700492859,
      0.06630691140890121,
      -0.05572177469730377,
      0.03644619882106781,
      -0.012274980545043945,
      0.0055377245880663395,
      0.03200940415263176,
      0.045521777123212814,
      0.023946456611156464,
      0.11063691973686218,
      0.04255061596632004,
      0.012539388611912727,
      0.011664985679090023,
      0.084219790995121,
      0.0012491588713601232,
      0.04596206918358803,
      -0.011496610008180141,
      -0.07432208210229874,
      0.005794340744614601,
      -0.042169831693172455,
      -0.0038785766810178757,
      -0.04956817254424095,
      0.032405294477939606,
      -0.0072250026278197765,
      -0.02128012850880623,
      -0.08150443434715271,
      -0.010340339504182339,
      0.061075326055288315,
      -0.056809134781360626,
      0.030010929331183434,
      -0.0030673514120280743,
      0.022818880155682564,
      -0.049938250333070755,
      -0.029335714876651764,
      -0.060394082218408585,
      0.04265248775482178,
      0.10688121616840363,
      0.03609520569443703,
      0.00572824664413929,
      0.002816517138853669,
      -0.09081676602363586,
      0.036015305668115616,
      0.04609085991978645,
      0.06812399625778198,
      0.0006908750510774553,
      0.02890690229833126,
      0.020982060581445694,
      0.04146410897374153,
      0.002497851150110364,
      0.014815536327660084,
      -0.00868722703307867,
      -0.015930771827697754,
      -0.021860742941498756,
      -0.0016793407266959548,
      -0.054247841238975525,
      0.03262406215071678,
      -0.030205493792891502,
      -0.06970855593681335,
      -0.01138674933463335,
      0.06576833128929138,
      0.08157768100500107,
      -0.04050768166780472,
      0.18339291214942932,
      -0.014614746905863285,
      -0.014053617604076862,
      -0.027179164811968803,
      -0.026577960699796677,
      0.034495048224925995,
      -0.03775942698121071,
      -0.008343445137143135,
      0.026362059637904167,
      0.04267837852239609,
      -0.018280044198036194,
      0.03976818546652794,
      0.10728288441896439,
      -0.0015873919473960996,
      -0.012417152523994446,
      0.06770112365484238,
      0.012797807343304157,
      -0.059028420597314835,
      -0.08885589987039566,
      0.05344904586672783,
      -0.02190599963068962,
      -0.031883351504802704,
      -0.04423268139362335,
      -0.024085935205221176,
      -0.08304014801979065,
      -0.0068024322390556335,
      0.007115832529962063,
      -0.037630900740623474,
      -0.07273976504802704,
      0.009309115819633007,
      0.002671547932550311,
      0.039319686591625214,
      0.06632748991250992,
      0.052096325904130936,
      0.014871113933622837,
      -0.010484966449439526,
      -0.024445028975605965,
      -0.016889523714780807,
      -0.1099945604801178,
      -0.034728311002254486,
      -0.049465663731098175,
      -0.001950870268046856,
      0.014937827363610268,
      0.09047400206327438,
      -0.04345246031880379,
      0.02190878614783287,
      0.02692287415266037,
      -0.07895040512084961,
      0.024126682430505753,
      -0.018493598327040672,
      -0.04289056733250618,
      -0.005083676893264055,
      0.0577554889023304,
      0.044207267463207245,
      -0.0015437968540936708,
      0.034011706709861755,
      -0.056294653564691544,
      0.010395742952823639,
      -0.08480559289455414,
      0.049517761915922165,
      -0.02821108140051365,
      0.013880738988518715,
      0.06272416561841965,
      0.031263645738363266,
      0.03479994833469391,
      0.01473038550466299,
      -0.051681362092494965,
      -0.06103742867708206,
      -0.024973221123218536,
      -0.008937896229326725,
      0.05140029639005661,
      0.07005969434976578,
      0.05072562396526337,
      -0.06974641978740692,
      0.007244212552905083,
      0.0025373974349349737,
      0.05519842728972435,
      0.008745217695832253,
      -0.010088803246617317,
      -0.060230355709791183,
      -0.07905470579862595,
      -0.02201475203037262,
      0.020549144595861435,
      -0.01649407111108303,
      0.00645287474617362,
      -0.05052614212036133,
      0.008277444168925285,
      -0.021117381751537323,
      0.015182414092123508,
      -0.04366276040673256,
      -0.0333440825343132,
      -0.024642549455165863,
      0.02022920548915863,
      0.015365712344646454,
      0.019408028572797775,
      -0.033692020922899246,
      -0.013115043751895428,
      0.02257699705660343,
      -0.0348765105009079,
      0.03958689048886299,
      0.003524441970512271,
      0.034515682607889175,
      -0.10568337887525558,
      -0.08587951213121414,
      0.010015098378062248,
      0.02323317527770996,
      -0.043725527822971344,
      -0.03193724527955055,
      0.02953759767115116,
      -0.013565135188400745,
      -0.0573834627866745,
      0.014879454858601093,
      -0.004614781588315964,
      -0.037675995379686356,
      0.04455968737602234,
      -0.051873624324798584,
      0.045546337962150574,
      0.06676533073186874,
      0.00029304029885679483,
      -0.003251098096370697,
      0.03636634722352028,
      0.01874670945107937,
      -0.03439246490597725,
      -0.01710207387804985,
      0.10740592330694199,
      -0.027479397132992744,
      -0.019751450046896935,
      0.004754422698169947,
      -0.0007048447150737047,
      -0.10301792621612549,
      0.012635177001357079,
      -0.004565776791423559,
      0.003894441295415163,
      0.02699802815914154,
      0.014968683943152428,
      0.008473155088722706,
      0.02730577439069748,
      -0.05727807804942131,
      0.026175301522016525,
      0.003014560788869858,
      0.036705825477838516,
      -0.12103746831417084,
      -0.01031460240483284,
      0.017147520557045937,
      -0.03470619395375252,
      0.10377579927444458,
      0.029552210122346878,
      0.0405372716486454,
      -0.020658494904637337,
      0.06793276220560074,
      0.01159729901701212,
      -0.059621457010507584,
      0.0031877788715064526,
      0.03352251648902893,
      0.010757183656096458,
      -0.0014611779479309916,
      -0.002026946982368827,
      0.03164495527744293,
      0.06906796991825104,
      0.017766859382390976,
      -0.04269355162978172,
      0.04488871246576309,
      -0.053185831755399704,
      0.02587219700217247,
      0.030754387378692627,
      0.028699243441224098,
      -0.05718449875712395,
      0.06281758844852448,
      0.0835907906293869,
      0.06350934505462646,
      0.05694224312901497,
      -0.0013388447696343064,
      0.019764376804232597,
      -0.04437391832470894,
      -0.007597734220325947,
      -0.0035567888990044594,
      0.05236115679144859,
      0.05111774057149887,
      0.03131752461194992,
      0.008337186649441719,
      -0.034422535449266434,
      0.07587362825870514,
      0.05077109485864639,
      0.06115276739001274,
      0.013387520797550678,
      -0.03544345125555992,
      0.04055919870734215,
      -0.020456217229366302,
      0.031859274953603745,
      0.015702160075306892,
      0.042678412050008774,
      -0.018803047016263008,
      0.024653414264321327,
      0.02038498781621456,
      0.0212317556142807,
      0.002799836453050375,
      0.02868696115911007,
      -0.02197376638650894,
      -0.059375837445259094,
      -0.05236256867647171,
      0.04475398734211922,
      -0.02604675106704235,
      -0.01615203358232975,
      0.07868126034736633,
      -0.09517690539360046,
      0.042536959052085876,
      -0.054756853729486465,
      -0.0807899534702301,
      0.046924859285354614,
      -0.04470819979906082,
      0.04159645736217499,
      -0.04420490935444832,
      -0.03645312413573265,
      0.04113641381263733,
      -0.025101162493228912,
      0.06695098429918289,
      -0.04979909583926201,
      0.038838259875774384,
      0.10560376942157745,
      -0.007839009165763855,
      0.05808214098215103,
      -0.06598339229822159,
      -0.08201566338539124,
      -0.015238805674016476,
      0.0064587583765387535,
      0.004282292444258928,
      -0.05273524299263954,
      -0.002592060249298811,
      0.05513770505785942,
      0.007809479720890522,
      0.04746920242905617,
      0.018638214096426964,
      -0.07981409132480621,
      -0.058432869613170624,
      0.003162893233820796,
      0.03504803031682968,
      -0.020557882264256477,
      0.016008825972676277,
      -0.03059803880751133,
      -0.052125342190265656,
      0.04314618557691574,
      -0.004113912116736174,
      0.04292422533035278,
      -0.0762987807393074,
      0.0031401982996612787,
      -0.016555240377783775,
      -0.020046455785632133,
      0.03888148441910744,
      0.03516542166471481,
      -0.05302773043513298,
      0.04945465922355652,
      0.03857307881116867,
      0.05768236145377159,
      -0.04120728000998497,
      -0.022912533953785896,
      0.010919097810983658,
      0.014169137924909592,
      0.042571790516376495,
      0.0037807628978043795,
      0.07045895606279373,
      0.07810922712087631,
      -0.006795898079872131,
      -0.05219413712620735,
      0.05506440997123718,
      -0.03732068091630936,
      0.013334207236766815,
      0.12088347226381302,
      -0.04526536539196968,
      -0.08042510598897934,
      -0.014182518236339092,
      0.04487129673361778,
      -0.023066582158207893,
      -0.0561678446829319,
      0.07389634102582932,
      -0.01708938367664814,
      -0.004864404909312725,
      -0.05516169220209122,
      0.03366732597351074,
      0.07086139917373657,
      -0.06334510445594788,
      -0.05029188469052315,
      0.08166086673736572,
      -0.050861455500125885,
      0.09427182376384735,
      -0.021457001566886902,
      -0.03665509819984436,
      0.0696663036942482,
      -0.03280536085367203,
      -0.03380044922232628,
      -0.057726792991161346,
      0.02248040959239006,
      0.000461945659480989,
      0.01756279543042183,
      -0.05918104574084282,
      -0.005226797424256802,
      0.0008839092333801091,
      0.056222256273031235,
      -0.008883661590516567,
      0.01339807454496622,
      0.06523902714252472,
      -0.004693165421485901,
      -0.020217590034008026,
      -0.03738844022154808,
      0.05316585674881935,
      0.013453655876219273,
      -0.02904282696545124,
      -0.04369477555155754,
      -0.031222162768244743,
      -0.06594445556402206,
      0.0643453598022461,
      -0.016883404925465584,
      0.021258531138300896,
      -0.05355503037571907,
      -0.03780527785420418,
      0.028855139389634132,
      -0.043881192803382874,
      0.03288055583834648,
      -0.016382617875933647,
      0.022095277905464172,
      0.08080073446035385,
      0.05956780165433884,
      0.06905122101306915,
      -0.02891414426267147,
      0.016559211537241936,
      0.029566936194896698,
      0.01727975718677044,
      0.08464395254850388,
      -0.0636114850640297,
      0.008409050293266773,
      0.07614331692457199,
      -0.018153270706534386,
      -0.026312081143260002,
      -0.03285640850663185,
      0.03953281417489052,
      -0.09678973257541656
    ],
    [
      0.0262991264462471,
      0.02846745029091835,
      -0.0010498195188120008,
      0.013645278289914131,
      -0.010033267550170422,
      0.005918286740779877,
      -0.03680574893951416,
      0.01980423368513584,
      0.07250186800956726,
      0.023754263296723366,
      -0.05623950436711311,
      0.02193211019039154,
      -0.06988092511892319,
      0.04085688665509224,
      0.057398661971092224,
      0.010933082550764084,
      -0.016998762264847755,
      0.020266911014914513,
      0.019064845517277718,
      -0.010731078684329987,
      0.03012986294925213,
      -0.06129011884331703,
      -0.01970946229994297,
      -0.001336648827418685,
      0.03318871185183525,
      -0.058413513004779816,
      0.04031098634004593,
      -0.015602410770952702,
      0.05246437340974808,
      0.02298714593052864,
      0.011468694545328617,
      -0.025097258388996124,
      0.06836900115013123,
      0.061849016696214676,
      -0.0029938698280602694,
      0.00276102963835001,
      -0.041397277265787125,
      -0.03694712370634079,
      -0.050608258694410324,
      -0.017413927242159843,
      -0.02014337293803692,
      0.0416165292263031,
      0.0007645045407116413,
      -0.0011694602435454726,
      0.002706993604078889,
      0.06362494081258774,
      0.021875493228435516,
      -0.08930210769176483,
      0.010025939904153347,
      -0.0011916436487808824,
      -0.04540824517607689,
      -0.09587767720222473,
      0.006442694459110498,
      0.0008906326838769019,
      -0.014060608111321926,
      -0.030881017446517944,
      0.0029314609710127115,
      -0.010181755758821964,
      0.06082573160529137,
      -0.061659399420022964,
      0.003585759550333023,
      -0.06692049652338028,
      0.01764564961194992,
      0.05252224951982498,
      0.03125889599323273,
      -0.02323240414261818,
      -0.020785748958587646,
      0.04788763448596001,
      0.032405465841293335,
      -0.02005092240869999,
      0.09112817049026489,
      0.005226667504757643,
      -0.004450494423508644,
      0.027010759338736534,
      -0.013114550150930882,
      0.03055172599852085,
      0.021473564207553864,
      0.04582701250910759,
      -0.001878814771771431,
      0.02121085859835148,
      -0.04882478341460228,
      -0.04932965710759163,
      0.017925811931490898,
      0.007982213981449604,
      0.044905222952365875,
      -0.03716756030917168,
      -0.022229058668017387,
      0.0451151467859745,
      0.0011310084955766797,
      -0.020238032564520836,
      -0.011446062475442886,
      0.0374835729598999,
      -0.03678915277123451,
      0.011388824321329594,
      -0.023175815120339394,
      -0.04655124992132187,
      -0.0906032919883728,
      -0.025262482464313507,
      0.0386989451944828,
      -0.02797149308025837,
      -0.02531536854803562,
      -0.000707451137714088,
      -0.024669088423252106,
      -0.006552618928253651,
      -0.0005556148826144636,
      0.06825079023838043,
      0.03869662433862686,
      0.03275933489203453,
      -0.005220569204539061,
      -0.010103899985551834,
      0.04222965985536575,
      0.05480490252375603,
      -0.011008967645466328,
      0.02050543762743473,
      0.028731003403663635,
      -0.061553776264190674,
      0.0039037142414599657,
      0.07547075301408768,
      -0.0452689565718174,
      0.004601210821419954,
      0.032032907009124756,
      -0.09200574457645416,
      -0.01622157357633114,
      -0.09102647006511688,
      -0.029040897265076637,
      -0.0663706436753273,
      -0.014822812750935555,
      -0.028079386800527573,
      0.033811211585998535,
      -0.0021348169539123774,
      0.04030539095401764,
      -0.03988345339894295,
      0.0197115670889616,
      0.017670363187789917,
      0.014640475623309612,
      -0.05642015114426613,
      -0.07772549986839294,
      0.030249377712607384,
      0.06570465862751007,
      -0.012670427560806274,
      0.0026214399840682745,
      -0.02129667066037655,
      -1.1622501915553585e-05,
      -0.002896691206842661,
      -0.09350292384624481,
      -0.018787391483783722,
      0.009086614474654198,
      -0.018448416143655777,
      -0.020258845761418343,
      -0.03216461464762688,
      -0.06642821431159973,
      0.06385960429906845,
      0.028302837163209915,
      0.027707792818546295,
      0.07315915822982788,
      0.03958731144666672,
      -0.028473712503910065,
      -0.0270080529153347,
      -0.06243690848350525,
      -0.04872579127550125,
      -0.09253428131341934,
      -0.0060058217495679855,
      -0.05529337748885155,
      0.020542902871966362,
      -0.0552993081510067,
      -0.061516374349594116,
      0.08826714009046555,
      0.05128874257206917,
      0.05079464241862297,
      -0.05586841702461243,
      0.048158518970012665,
      -0.01662367582321167,
      -0.0642673522233963,
      -0.011530360206961632,
      -0.10802185535430908,
      0.04811161011457443,
      -0.03402170166373253,
      -0.04319080337882042,
      -0.017248794436454773,
      0.025573506951332092,
      -0.04248756542801857,
      -0.06197267770767212,
      0.014221913181245327,
      -0.05967167764902115,
      -0.03639709949493408,
      0.01621251553297043,
      -0.042221736162900925,
      -0.03866802155971527,
      -0.07854922115802765,
      0.03868681192398071,
      0.000764592899940908,
      -0.001503801322542131,
      0.08576873689889908,
      0.015248174779117107,
      0.026663774624466896,
      -0.08278211206197739,
      0.056943077594041824,
      0.004440188407897949,
      0.03485770523548126,
      0.04257414489984512,
      -0.08384638279676437,
      0.12668661773204803,
      0.08008956164121628,
      0.03619605302810669,
      0.0458206981420517,
      0.008543283678591251,
      0.06627945601940155,
      -0.013948244974017143,
      -0.024450404569506645,
      0.03379661217331886,
      -0.04764662683010101,
      0.03940669447183609,
      0.014719637110829353,
      0.02804837003350258,
      0.03152497112751007,
      0.005913311615586281,
      0.04350779950618744,
      -0.005972761660814285,
      0.09836015105247498,
      0.07719592750072479,
      -0.06378771364688873,
      0.03727048262953758,
      -0.009461593814194202,
      -0.00565820699557662,
      -0.022980814799666405,
      0.03247345983982086,
      -0.022749999538064003,
      -0.04923360049724579,
      -0.03067731484770775,
      -0.014424229972064495,
      -0.033753570169210434,
      -0.021815719082951546,
      0.0007459581829607487,
      -0.07121080905199051,
      0.004659535828977823,
      -0.04714193940162659,
      0.029384208843111992,
      -0.03198223561048508,
      9.953471817425452e-06,
      0.06615344434976578,
      -0.06420451402664185,
      -0.001294519635848701,
      0.02516433410346508,
      -0.020937317982316017,
      0.009827950969338417,
      0.04132063314318657,
      -0.05227269232273102,
      -0.06818190217018127,
      -0.04329608753323555,
      0.026100603863596916,
      -0.07472594082355499,
      0.05614776536822319,
      -0.03783527761697769,
      -0.05418551340699196,
      -0.0010335585102438927,
      -0.026164114475250244,
      -0.011683019809424877,
      -0.031664036214351654,
      -0.0599261149764061,
      -0.037886906415224075,
      0.020442908629775047,
      -0.0027784002013504505,
      0.020119577646255493,
      -0.021313220262527466,
      -0.010839029215276241,
      0.015049099922180176,
      -0.046140577644109726,
      -0.05585258826613426,
      0.01558845303952694,
      -0.012921197339892387,
      0.016305487602949142,
      -0.007159499917179346,
      -0.010063130408525467,
      0.04660191014409065,
      0.037777408957481384,
      0.04939807206392288,
      -0.04906371980905533,
      -0.04686646908521652,
      0.0036359752994030714,
      -0.031121235340833664,
      -0.016923071816563606,
      0.0011256069410592318,
      -0.00651827035471797,
      -0.0150513406842947,
      -0.0481390506029129,
      0.0539412759244442,
      0.026453839614987373,
      0.0009594950824975967,
      0.07266634702682495,
      0.02648649737238884,
      -0.005824763793498278,
      -0.05544067546725273,
      -0.023598216474056244,
      -0.030108995735645294,
      -0.041257694363594055,
      0.07506538182497025,
      -0.02389821968972683,
      0.015831556171178818,
      0.00418508518487215,
      -0.018664628267288208,
      0.0632752776145935,
      0.05098729953169823,
      0.021434977650642395,
      -0.011731368489563465,
      -0.08330102264881134,
      0.06884759664535522,
      0.04068564623594284,
      0.07802542299032211,
      0.06891918927431107,
      -0.057634513825178146,
      -0.04188913106918335,
      -0.020637234672904015,
      -0.05188559740781784,
      0.03322939574718475,
      0.036035120487213135,
      0.03248745948076248,
      0.012075874954462051,
      0.04365796595811844,
      -0.026139989495277405,
      0.0013590131420642138,
      -0.012443184852600098,
      0.09406860917806625,
      0.04630127176642418,
      -0.018680034205317497,
      -0.006459484342485666,
      -0.06695450097322464,
      -0.004848395474255085,
      0.06725044548511505,
      -0.07030539959669113,
      -0.0008585195173509419,
      0.0279836468398571,
      0.049149371683597565,
      0.01359954196959734,
      -0.009335043840110302,
      -0.06490710377693176,
      0.052487559616565704,
      0.010813516564667225,
      -0.03424576669931412,
      -0.028398478403687477,
      0.013825537636876106,
      -0.006733702030032873,
      0.03978394716978073,
      -0.06380689144134521,
      -0.010558693669736385,
      -0.06822613626718521,
      0.00799114815890789,
      0.05091690272092819,
      0.019068332388997078,
      0.04860259220004082,
      0.003421442350372672,
      0.04703451320528984,
      0.003629817860201001,
      0.011586129665374756,
      -0.016955150291323662,
      0.025952916592359543,
      0.04789573699235916,
      0.0099791856482625,
      0.0014581144787371159,
      0.013294472359120846,
      0.057505927979946136,
      -0.01772197149693966,
      0.0346633605659008,
      0.05623174086213112,
      -0.02568606287240982,
      0.03771840035915375,
      -0.029921913519501686,
      0.002649001544341445,
      0.015438430942595005,
      0.004865876864641905,
      0.014461535960435867,
      -0.009029296226799488,
      -0.028913676738739014,
      0.06860070675611496,
      -0.06920737028121948,
      0.0030743435490876436,
      0.02028103545308113,
      0.003343418939039111,
      -0.0761428251862526,
      0.026335209608078003,
      0.0011783414520323277,
      -0.11682635545730591,
      0.05054587870836258,
      0.0370519794523716,
      -0.07631514966487885,
      -0.013078754767775536,
      -0.0033111085649579763,
      -0.002385097788646817,
      0.012078631669282913,
      -0.007285731844604015,
      0.017737407237291336,
      -0.0030750450678169727,
      -0.011282271705567837,
      0.01113884150981903,
      -0.03158154711127281,
      0.032371629029512405,
      0.0299201849848032,
      0.021417193114757538,
      0.043007127940654755,
      0.03828034549951553,
      -0.010029671713709831,
      -0.03927148878574371,
      0.0006079099257476628,
      0.011706791818141937,
      0.04252997413277626,
      -0.0334225557744503,
      -0.01489221677184105,
      0.049068547785282135,
      -0.04416074603796005,
      -0.019284863024950027,
      -0.07713282108306885,
      -0.046120017766952515,
      0.0861017033457756,
      -0.0015262140659615397,
      -0.012955844402313232,
      0.046149760484695435,
      -0.006270003039389849,
      -0.05486072972416878,
      -0.006788407918065786,
      0.01307625137269497,
      -0.004424319602549076,
      0.059174470603466034,
      -0.061314038932323456,
      -0.06784147769212723,
      -0.005080521572381258,
      0.00798149686306715,
      0.030466483905911446,
      0.08096197992563248,
      0.074795663356781,
      -0.054108500480651855,
      -0.024305475875735283,
      -0.023776205256581306,
      0.021393992006778717,
      -0.062423598021268845,
      0.05909724906086922,
      -0.02213815040886402,
      -0.020264722406864166,
      -0.009487849660217762,
      -0.09230277687311172,
      -0.031572677195072174,
      -0.028471359983086586,
      -0.09101133048534393,
      -0.0547841414809227,
      -0.01145204808562994,
      0.01950068026781082,
      -0.004277723375707865,
      0.008497744798660278,
      -0.020466310903429985,
      -0.011476987972855568,
      0.038600314408540726,
      -0.06203605979681015,
      0.029410962015390396,
      0.06464926153421402,
      -0.03173326328396797,
      -0.034135300666093826,
      0.04282459244132042,
      0.0988771989941597,
      0.10014304518699646,
      0.024844007566571236,
      0.018334781751036644,
      -0.05610211193561554,
      0.07118544727563858,
      0.031887222081422806,
      -0.05107198655605316,
      -0.02158992551267147,
      0.07595688104629517,
      -0.0124084847047925,
      -0.0071127102710306644,
      -0.01331561803817749,
      -0.013679709285497665,
      -0.09703822433948517,
      0.03288492187857628,
      0.057904522866010666,
      -0.04081406816840172,
      0.06655868887901306,
      0.022248201072216034,
      -0.03242645412683487,
      -0.005385145545005798,
      0.05529765412211418,
      0.011565065011382103,
      -0.013685756362974644,
      -0.07028438150882721,
      -0.010620294138789177,
      0.04173596203327179,
      0.021085668355226517,
      -0.003546865191310644,
      0.06384202837944031,
      0.03893177956342697,
      0.04613576456904411,
      -0.008695591241121292,
      -0.022121518850326538,
      0.02979285828769207,
      0.010973537340760231,
      -0.006575691048055887,
      -0.012402170337736607,
      0.059336911886930466,
      -0.00508855888620019,
      -0.042791303247213364,
      0.02678130753338337,
      -0.0425836481153965,
      0.02757985144853592,
      -0.04898994788527489,
      -0.03211880847811699,
      0.04328830912709236
    ],
    [
      0.00540465721860528,
      -0.041552331298589706,
      -0.0017178542912006378,
      -0.02053694613277912,
      0.0508737675845623,
      0.06280986219644547,
      0.06422454863786697,
      -0.03285421431064606,
      -0.06287633627653122,
      0.014304482378065586,
      0.029742436483502388,
      0.004998498130589724,
      -0.002433482324704528,
      0.03564320504665375,
      0.042061205953359604,
      0.011006803251802921,
      0.020953970029950142,
      -0.008688579313457012,
      0.07189813256263733,
      0.005461991298943758,
      -0.010703232139348984,
      -0.011444124393165112,
      0.014757387340068817,
      0.02223801240324974,
      -0.028694825246930122,
      -0.050700653344392776,
      -0.005090595223009586,
      -0.020298881456255913,
      0.0100099490955472,
      -0.013481169939041138,
      0.05582351237535477,
      -0.023321036249399185,
      0.08965246379375458,
      0.01392940990626812,
      -0.010703502222895622,
      -0.01903037168085575,
      0.060945164412260056,
      0.09702740609645844,
      0.072243332862854,
      -0.08730843663215637,
      0.08121403306722641,
      0.017380014061927795,
      -0.028035731986165047,
      -0.04248309135437012,
      0.01689036190509796,
      -0.052046675235033035,
      -0.04903784766793251,
      -0.06256696581840515,
      -0.047099240124225616,
      0.035514723509550095,
      0.023101501166820526,
      0.005451250821352005,
      0.08654043823480606,
      -0.02980090118944645,
      0.03363224118947983,
      -0.06777862459421158,
      -0.0072734057903289795,
      0.030063096433877945,
      -0.016998041421175003,
      -0.0516369491815567,
      -0.08178552240133286,
      0.006756723392754793,
      0.058685053139925,
      -0.06399695575237274,
      0.012633660808205605,
      0.025346463546156883,
      -0.034359756857156754,
      -0.045098792761564255,
      -0.004967051558196545,
      -0.01009161863476038,
      0.07494476437568665,
      -0.04716062173247337,
      0.07118610292673111,
      0.03297152370214462,
      0.024158060550689697,
      -0.060433316975831985,
      0.04281163588166237,
      -0.002873840043321252,
      -0.015481596812605858,
      -0.11553307622671127,
      -0.06274040043354034,
      -0.1035294383764267,
      -0.02412036992609501,
      -0.016658535227179527,
      0.00897930096834898,
      -0.016473600640892982,
      0.006814346648752689,
      0.058600421994924545,
      -0.08979109674692154,
      -0.052073609083890915,
      -0.018187161535024643,
      0.05066436156630516,
      0.06113803759217262,
      0.09041323512792587,
      0.0005758355255238712,
      -0.01715727709233761,
      0.01446349173784256,
      0.001571999047882855,
      0.039331987500190735,
      0.05485096946358681,
      -0.04625535383820534,
      -0.0012431013165041804,
      0.03650299087166786,
      0.04428825527429581,
      -0.010288972407579422,
      0.10988617688417435,
      0.025901326909661293,
      0.04869639500975609,
      -0.0932413712143898,
      -0.06984616816043854,
      0.05458982661366463,
      0.03667926788330078,
      -0.07452479749917984,
      -0.007618730887770653,
      0.019775060936808586,
      -0.000385146529879421,
      -0.09718938171863556,
      0.0587599016726017,
      -0.049721281975507736,
      -0.007112119812518358,
      0.03718654066324234,
      -0.001440567197278142,
      -0.027129892259836197,
      -0.013447104953229427,
      0.05171869695186615,
      -0.07041008025407791,
      -0.0574994795024395,
      -0.05718943476676941,
      0.026994118466973305,
      0.07626818120479584,
      0.10985647886991501,
      -0.09573081135749817,
      0.0210729967802763,
      -0.038602858781814575,
      -0.0441506989300251,
      -0.0025086344685405493,
      0.003412682795897126,
      -0.07120326161384583,
      0.10907895863056183,
      0.02230050601065159,
      0.012865213677287102,
      -0.07219351083040237,
      0.01525082252919674,
      -0.025836868211627007,
      0.045198164880275726,
      -0.06953002512454987,
      0.001222039689309895,
      0.01110740378499031,
      0.01234836783260107,
      -0.029014548286795616,
      0.047438859939575195,
      -0.0547594279050827,
      0.04849063232541084,
      0.051100295037031174,
      0.03893166407942772,
      0.04206240549683571,
      -0.005432029254734516,
      0.014638317748904228,
      0.01794949732720852,
      -0.027768712490797043,
      -0.031876929104328156,
      0.034073345363140106,
      -0.06541161984205246,
      0.10446352511644363,
      -0.016457872465252876,
      0.021657031029462814,
      0.0023415565956383944,
      -0.03396616131067276,
      -0.050326794385910034,
      -0.02094798907637596,
      -0.016837194561958313,
      0.0323612205684185,
      -0.03207850828766823,
      0.011827339418232441,
      0.04398158937692642,
      0.02169628255069256,
      -0.05539828538894653,
      0.02912355400621891,
      0.04791693761944771,
      0.05151016637682915,
      0.05049470067024231,
      -0.0013313640374690294,
      0.030131086707115173,
      0.029495414346456528,
      0.025332950055599213,
      -0.01495341956615448,
      -0.04255218803882599,
      0.012343699112534523,
      0.03494570404291153,
      -0.013343850150704384,
      0.021070947870612144,
      -0.034002237021923065,
      -0.022825486958026886,
      0.11689451336860657,
      0.03803680092096329,
      -0.08649145066738129,
      -0.01165136881172657,
      0.006171741522848606,
      -0.035295139998197556,
      -0.011007767170667648,
      0.030012886971235275,
      -0.007506738416850567,
      -0.009736793115735054,
      0.009467992931604385,
      0.020021822303533554,
      0.024354390799999237,
      0.020031005144119263,
      -0.08102113753557205,
      -0.023150242865085602,
      -0.01149710826575756,
      -0.0015896752011030912,
      0.046511564403772354,
      -0.021869810298085213,
      -0.02600022777915001,
      0.01300564594566822,
      -0.016545332968235016,
      0.043084945529699326,
      0.033004406839609146,
      0.005473903380334377,
      -0.010409252718091011,
      0.0049627479165792465,
      0.036916568875312805,
      -0.023514270782470703,
      0.051988501101732254,
      -0.04295238479971886,
      -0.04747895523905754,
      0.06833703070878983,
      -0.03474665433168411,
      -0.04128324240446091,
      -0.006218975409865379,
      0.11944949626922607,
      0.0031547495163977146,
      -0.021160153672099113,
      0.034307513386011124,
      0.031512483954429626,
      -0.022235658019781113,
      -0.05179040506482124,
      0.005474583711475134,
      -0.006529577076435089,
      0.043497234582901,
      -0.03462138772010803,
      -0.016443287953734398,
      0.08699186891317368,
      0.01201638299971819,
      -0.04659869149327278,
      -0.014681427739560604,
      0.02570638433098793,
      -0.01876869797706604,
      0.04826444759964943,
      -0.007496917154639959,
      0.0003344162832945585,
      -0.037229299545288086,
      0.04515537992119789,
      -0.03741682693362236,
      -0.015818821266293526,
      0.023008525371551514,
      -0.043273214250802994,
      -0.04444039613008499,
      0.055265456438064575,
      0.031244497746229172,
      -0.0709448829293251,
      -0.005972621031105518,
      -0.016079427674412727,
      0.06783277541399002,
      -0.036515653133392334,
      0.05133573338389397,
      -0.04162986949086189,
      -0.07325474917888641,
      0.019826875999569893,
      0.06415925920009613,
      0.07241244614124298,
      -0.05390101671218872,
      -0.046400438994169235,
      -0.0035620511043816805,
      -0.018670272082090378,
      0.043601538985967636,
      0.08301778882741928,
      0.0031373307574540377,
      0.04945680499076843,
      0.06128861382603645,
      -0.017617221921682358,
      0.015721123665571213,
      0.06536979228258133,
      0.03760653734207153,
      0.04732753708958626,
      -0.059183355420827866,
      -0.10060575604438782,
      0.04093647748231888,
      0.03574690595269203,
      0.01553276740014553,
      0.08898383378982544,
      -0.049855705350637436,
      -0.08754967898130417,
      -0.004142373334616423,
      0.017283564433455467,
      0.06313494592905045,
      -0.004401198122650385,
      0.02008580043911934,
      0.018982285633683205,
      -0.0016401110915467143,
      -0.02647305466234684,
      -8.510833868058398e-05,
      0.004922747611999512,
      0.03678698465228081,
      0.00205636047758162,
      0.0034164064563810825,
      0.06074146181344986,
      -0.025467127561569214,
      -0.02703961171209812,
      -0.022149858996272087,
      -0.06644739955663681,
      0.008547133766114712,
      0.030584001913666725,
      -0.033247027546167374,
      -0.008830803446471691,
      -0.046334780752658844,
      0.014942093752324581,
      -0.09629584848880768,
      0.040214426815509796,
      0.009069066494703293,
      0.025973007082939148,
      -0.05859730765223503,
      0.015584183856844902,
      -0.008540136739611626,
      0.01708625815808773,
      0.055078621953725815,
      -0.003593219444155693,
      0.008383186534047127,
      0.029845383018255234,
      0.024220025166869164,
      0.06951957195997238,
      -0.01907249167561531,
      -0.03748646378517151,
      0.04097001627087593,
      0.01253951620310545,
      -0.06265342235565186,
      -0.028114167973399162,
      0.025077754631638527,
      -0.0808442011475563,
      0.07040070742368698,
      -0.06628383696079254,
      -0.0059486934915184975,
      -0.10620088130235672,
      0.013933033682405949,
      0.014525826089084148,
      -0.07006223499774933,
      0.05137479677796364,
      0.022900396957993507,
      0.066384457051754,
      -0.003458490828052163,
      -0.023947037756443024,
      -0.02058425173163414,
      0.03807644546031952,
      -0.022074846550822258,
      -0.03256366774439812,
      0.09700937569141388,
      0.04764138534665108,
      -0.03579528629779816,
      -0.025460058823227882,
      0.0356094129383564,
      0.01316546369343996,
      0.0515175461769104,
      -0.033281926065683365,
      0.08701975643634796,
      -0.025592822581529617,
      0.008571832440793514,
      -0.07485440373420715,
      -0.07077491283416748,
      -0.09003189206123352,
      0.09163451194763184,
      0.02593124657869339,
      -0.05740513280034065,
      -0.04209006577730179,
      -0.10605025291442871,
      -0.001114150625653565,
      -0.008677194826304913,
      -0.021978024393320084,
      -0.040291983634233475,
      -0.04183393344283104,
      -0.060381434857845306,
      0.06172371655702591,
      -0.1207910031080246,
      -0.009114814922213554,
      -0.03408871591091156,
      0.058147914707660675,
      -0.022688334807753563,
      0.010128011927008629,
      0.05213167890906334,
      -0.02443399280309677,
      0.038958072662353516,
      0.011518268845975399,
      0.06097684055566788,
      0.03864460438489914,
      0.035708460956811905,
      -0.06925476342439651,
      -0.011380300857126713,
      0.029065482318401337,
      -0.0006139786564745009,
      -0.020570706576108932,
      -0.03343033790588379,
      0.013106187805533409,
      0.015497975051403046,
      -0.0014802090590819716,
      0.01917283795773983,
      0.0012922786409035325,
      -0.004599494859576225,
      -0.028024621307849884,
      0.08770275861024857,
      -0.052239567041397095,
      -0.027638554573059082,
      0.04870109632611275,
      0.001129322568885982,
      -0.026580175384879112,
      0.008037862367928028,
      -0.06057049334049225,
      0.04835427179932594,
      0.006863545626401901,
      0.010037372820079327,
      0.006612127181142569,
      0.021047256886959076,
      0.09939531981945038,
      0.057077422738075256,
      -0.0018750549061223865,
      0.025734765455126762,
      -0.05168529227375984,
      0.04883849248290062,
      0.04815829172730446,
      0.04933539032936096,
      0.008855501189827919,
      -0.009521815925836563,
      -0.00925325695425272,
      0.04026193544268608,
      0.009689858183264732,
      -0.0550154373049736,
      -0.05581953749060631,
      -0.0430905856192112,
      0.06263594329357147,
      0.10632350295782089,
      0.011979557573795319,
      0.017199620604515076,
      -0.04372451826930046,
      0.011193476617336273,
      0.002460742136463523,
      0.08297401666641235,
      0.002791177947074175,
      -0.0036856753285974264,
      -0.007689672522246838,
      -0.03073691390454769,
      -0.08585123717784882,
      0.03564237058162689,
      -0.0878029465675354,
      -0.04052601009607315,
      -0.014653285034000874,
      -0.012562744319438934,
      0.042853910475969315,
      0.012428737245500088,
      0.0516914539039135,
      -0.01033896952867508,
      -0.04418458789587021,
      0.0428633876144886,
      -0.008929137140512466,
      -0.008702433668076992,
      -0.06749050319194794,
      0.017389729619026184,
      -0.08930599689483643,
      0.05514778941869736,
      0.03220970928668976,
      0.03437241166830063,
      0.013417292386293411,
      0.029160136356949806,
      -0.1209811270236969,
      -0.017956886440515518,
      0.0664723664522171,
      0.004811554681509733,
      0.025405606254935265,
      0.0570116825401783,
      -0.06447834521532059,
      0.008491970598697662,
      0.023188946768641472,
      -0.04039507731795311,
      0.045981187373399734,
      0.018735572695732117,
      -0.020510787144303322,
      0.06635640561580658,
      0.006171638146042824,
      -0.042540013790130615,
      0.004894315265119076,
      0.06959103792905807,
      0.008220226503908634,
      0.004542260896414518,
      0.022277913987636566,
      0.05608561635017395,
      -0.050815705209970474,
      0.0791826844215393,
      0.021081095561385155,
      -0.050347112119197845,
      0.04046543315052986,
      0.04259679839015007,
      -0.011597122997045517,
      0.021643735468387604,
      0.04858696088194847,
      0.0811128243803978,
      0.01991480216383934
    ],
    [
      0.10099703818559647,
      0.05177957937121391,
      -0.03366129845380783,
      0.09951409697532654,
      0.03627070039510727,
      0.05613461881875992,
      0.049137722700834274,
      -0.03461572900414467,
      -0.005119937472045422,
      -0.02348671853542328,
      0.011786815710365772,
      0.003151099430397153,
      0.036210797727108,
      -0.04905984178185463,
      -0.011333840899169445,
      0.044894736260175705,
      0.056139953434467316,
      -0.008284462615847588,
      0.026735112071037292,
      0.07534870505332947,
      0.05968799069523811,
      -0.04209646210074425,
      -0.03251734375953674,
      0.033419832587242126,
      0.007693024817854166,
      -0.04570230841636658,
      0.0019012445118278265,
      0.013031978160142899,
      0.010151289403438568,
      0.004072317387908697,
      0.10441157966852188,
      -0.0425729863345623,
      -0.08432210236787796,
      -0.030172601342201233,
      -0.00849990826100111,
      0.0002853686164598912,
      -0.04898478835821152,
      -0.00579046132043004,
      -0.05416349321603775,
      -0.03596940264105797,
      0.05650115758180618,
      0.08483487367630005,
      0.005739664658904076,
      0.013956713490188122,
      -0.036768216639757156,
      -0.054287612438201904,
      0.0750688761472702,
      0.002114532981067896,
      -0.08609069883823395,
      0.002373709576204419,
      0.04107196256518364,
      0.058023516088724136,
      0.025289585813879967,
      -0.09306439012289047,
      0.05124150589108467,
      -0.0763314887881279,
      -0.05945589393377304,
      0.020016774535179138,
      0.006186096929013729,
      -0.03964291140437126,
      0.01786630041897297,
      -0.04723963886499405,
      -0.036672238260507584,
      0.0001873219880508259,
      -0.036449871957302094,
      -0.019619852304458618,
      -0.005620229989290237,
      0.027494478970766068,
      -0.046774640679359436,
      -0.015567697584629059,
      -0.05049979314208031,
      -0.0027521057054400444,
      -0.10792872309684753,
      -0.10534875839948654,
      -0.056487150490283966,
      -0.013832109048962593,
      0.0325925275683403,
      0.0005125666502863169,
      -0.029224269092082977,
      -0.013318653218448162,
      0.03616393730044365,
      -0.035682596266269684,
      -0.04707806184887886,
      0.03551552817225456,
      -0.06247547268867493,
      -0.10760001838207245,
      0.04813944920897484,
      -0.015365304425358772,
      -0.041298430413007736,
      0.013846125453710556,
      0.013276545330882072,
      0.024817947298288345,
      0.02994869276881218,
      0.05456114187836647,
      -0.05441054701805115,
      -0.06370577216148376,
      0.08721229434013367,
      0.10212943702936172,
      -0.01903633028268814,
      0.03312544524669647,
      0.01640067622065544,
      0.07292144000530243,
      -0.036670852452516556,
      0.0010260856943205,
      0.04496033117175102,
      0.003484919900074601,
      -0.015407984144985676,
      0.018652910366654396,
      -0.0510171540081501,
      -0.0009593370486982167,
      -0.009626454673707485,
      0.03131040930747986,
      -0.015523836016654968,
      0.05475377291440964,
      0.051516301929950714,
      -0.01660512201488018,
      -0.022863008081912994,
      0.00220374739728868,
      0.058459360152482986,
      -0.03326752036809921,
      0.04604972526431084,
      -0.0410582609474659,
      0.03805526718497276,
      -0.05040792375802994,
      -0.036955107003450394,
      -0.04474660009145737,
      -0.01858222857117653,
      -0.11065635085105896,
      0.04342840239405632,
      0.08555268496274948,
      -0.007887265644967556,
      -0.0032454172614961863,
      0.0036183642223477364,
      -0.024003608152270317,
      -0.002607835689559579,
      0.014271702617406845,
      -0.05727384239435196,
      -0.03570502623915672,
      -0.031729556620121,
      -0.04278004541993141,
      -0.012301817536354065,
      -0.06760973483324051,
      -0.021035373210906982,
      0.06145608052611351,
      -0.021344784647226334,
      -0.012377411127090454,
      -0.021080581471323967,
      0.052649740129709244,
      -0.0007599524687975645,
      0.026066500693559647,
      -0.01044518407434225,
      -0.01811738684773445,
      -0.02098669670522213,
      -0.04613841325044632,
      -0.024594387039542198,
      -0.03697282075881958,
      0.007187051232904196,
      0.03798680379986763,
      0.02657226100564003,
      -0.009028167463839054,
      0.03875314071774483,
      -0.11968517303466797,
      0.01618032716214657,
      -0.037848714739084244,
      -0.007064368575811386,
      0.0006915554404258728,
      -0.006800060626119375,
      -0.07728428393602371,
      0.025029432028532028,
      -0.020226217806339264,
      0.025088701397180557,
      0.05023679882287979,
      -0.02970723994076252,
      0.058987222611904144,
      -0.04876168444752693,
      0.013076839037239552,
      0.04344465583562851,
      0.005681823939085007,
      0.07831403613090515,
      0.01290992833673954,
      -0.016000784933567047,
      -0.03708161041140556,
      -0.03667813912034035,
      -0.0056839268654584885,
      -0.05007442086935043,
      -0.06530007719993591,
      -0.03613791614770889,
      0.026341231539845467,
      0.017100118100643158,
      -0.030778946354985237,
      0.062322281301021576,
      0.005951636005192995,
      -0.01426447369158268,
      -0.06273859739303589,
      0.021241674199700356,
      0.004060104489326477,
      0.01969986781477928,
      -0.035747382789850235,
      -0.00907744187861681,
      0.04046223685145378,
      -0.022344324737787247,
      0.10139156132936478,
      -0.04477829113602638,
      0.00771491089835763,
      0.0011450940510258079,
      -0.028729071840643883,
      0.004184038378298283,
      -0.05123540014028549,
      -0.048568930476903915,
      -0.03619172051548958,
      -0.03499874100089073,
      -0.009825998917222023,
      0.014953541569411755,
      0.004822327755391598,
      -0.013170630671083927,
      -0.06987457722425461,
      -0.009972954168915749,
      0.06943775713443756,
      0.025257088243961334,
      0.028116650879383087,
      -0.02152908965945244,
      -0.022689253091812134,
      0.03880789130926132,
      0.028202975168824196,
      0.017585037276148796,
      -0.022108042612671852,
      0.025283288210630417,
      -0.01863478124141693,
      -0.1006103903055191,
      0.021439088508486748,
      0.11444533616304398,
      0.017636651173233986,
      0.0027854994405061007,
      -0.006381304934620857,
      0.04970482364296913,
      -0.021445592865347862,
      0.012983177788555622,
      -0.06794606894254684,
      0.005586511455476284,
      0.05531235411763191,
      -0.005321422591805458,
      -0.010886021889746189,
      0.02702350728213787,
      -0.00756716588512063,
      -0.006080304272472858,
      -0.038447823375463486,
      -0.06409445405006409,
      0.0199666116386652,
      -0.012397482059895992,
      -0.05846841633319855,
      -0.02417909912765026,
      -0.04119647294282913,
      -0.02439890429377556,
      -0.10589912533760071,
      -0.022360889241099358,
      0.05253506824374199,
      0.16253085434436798,
      -0.021992826834321022,
      -0.0144349979236722,
      -0.009334114380180836,
      -0.022742122411727905,
      0.013638311997056007,
      -0.06479766219854355,
      0.013190828263759613,
      -0.038850437849760056,
      0.05764220654964447,
      -0.03832906112074852,
      -0.014047316275537014,
      0.0007290497305803001,
      0.03182820975780487,
      0.050558265298604965,
      0.03355592116713524,
      0.025990352034568787,
      -0.01639632321894169,
      -0.02641250565648079,
      -0.0764319971203804,
      -0.005742800422012806,
      -0.07211150974035263,
      0.02033788338303566,
      0.04675813764333725,
      0.05746014788746834,
      0.012795493006706238,
      0.015073749236762524,
      -0.03818939998745918,
      -0.033672820776700974,
      0.04433435574173927,
      0.008115798234939575,
      0.026362527161836624,
      0.012099135667085648,
      0.00963585451245308,
      -0.017986971884965897,
      -0.016860079020261765,
      -0.06156102940440178,
      -0.049306128174066544,
      0.020740313455462456,
      0.030031392350792885,
      0.0217738039791584,
      0.012227702885866165,
      -0.022464722394943237,
      0.03281547874212265,
      -0.052313074469566345,
      0.04703354462981224,
      0.066553995013237,
      -0.024002844467759132,
      0.009567220695316792,
      0.050057552754879,
      0.018926968798041344,
      -0.019518738612532616,
      0.0680176317691803,
      0.0038045207038521767,
      0.045659150928258896,
      0.13610252737998962,
      0.06685738265514374,
      0.006288053467869759,
      -0.0216081440448761,
      0.08940210193395615,
      0.04848792403936386,
      -0.0466645173728466,
      0.00568572711199522,
      -0.011569368652999401,
      -0.027404598891735077,
      0.008505760692059994,
      -0.04389750212430954,
      0.07174696028232574,
      0.029550516977906227,
      0.04757796227931976,
      -0.015489759854972363,
      -0.030521869659423828,
      0.014365001581609249,
      -0.02072964608669281,
      0.02268444374203682,
      -0.02093936875462532,
      0.04509024694561958,
      0.05833619832992554,
      -0.0296428631991148,
      0.023683229461312294,
      -0.1410760134458542,
      0.04871513321995735,
      -0.02505306527018547,
      0.058224841952323914,
      -0.0061967093497514725,
      0.003889575367793441,
      -0.012423870153725147,
      -0.032151736319065094,
      -0.009315752424299717,
      0.08043847978115082,
      -0.03226502984762192,
      -0.04129525646567345,
      0.04142602160573006,
      0.04792988300323486,
      0.02421986311674118,
      0.011210395954549313,
      0.010614708065986633,
      -0.05066607892513275,
      0.0021999701857566833,
      0.06855517625808716,
      0.08692577481269836,
      0.04471885412931442,
      0.004867455456405878,
      -0.04876701161265373,
      -0.10839048027992249,
      0.030625319108366966,
      -0.04905540496110916,
      0.03136756271123886,
      0.039108194410800934,
      0.0022503540385514498,
      -0.02713504619896412,
      0.021041614934802055,
      -0.026554573327302933,
      0.04307711496949196,
      -0.012657752260565758,
      -0.05071783810853958,
      0.03403250500559807,
      -0.02047988772392273,
      0.02056696079671383,
      -0.04340141639113426,
      -0.06625773757696152,
      0.0351041741669178,
      -0.005893937312066555,
      0.042889613658189774,
      0.016451982781291008,
      0.035340145230293274,
      -0.013010040856897831,
      0.03995807468891144,
      -0.07179133594036102,
      -0.03426501154899597,
      -0.017027435824275017,
      -0.011662160977721214,
      0.0367489792406559,
      0.04195258393883705,
      0.09145583212375641,
      0.02321716398000717,
      0.04878997430205345,
      -0.023408733308315277,
      0.0001769655937096104,
      0.04820828139781952,
      -0.057143278419971466,
      0.0005431086174212396,
      0.04793929308652878,
      0.0491250604391098,
      0.015278564766049385,
      0.035906966775655746,
      0.04251629859209061,
      0.05452704802155495,
      -0.013948052190244198,
      0.05375129356980324,
      0.08478331565856934,
      -0.00631047273054719,
      -0.02937343902885914,
      0.04296236112713814,
      -0.060978639870882034,
      -0.010070865973830223,
      -0.032215237617492676,
      -0.023513197898864746,
      0.03769614174962044,
      0.0429329089820385,
      -0.07941466569900513,
      -0.009458678774535656,
      -0.06326814740896225,
      -0.034418363124132156,
      0.027065066620707512,
      -0.018278999254107475,
      0.043457381427288055,
      -0.02598102018237114,
      0.029634537175297737,
      -0.0017220828449353576,
      -0.03462233021855354,
      0.04640309885144234,
      0.00013359601143747568,
      -0.03592531383037567,
      -0.0053311800584197044,
      -0.04091225564479828,
      -0.03984511271119118,
      0.03076334297657013,
      0.018789183348417282,
      0.04691914841532707,
      0.06708136945962906,
      0.0084540331736207,
      0.07950480282306671,
      -0.05276617780327797,
      -0.025265267118811607,
      0.06495990604162216,
      -0.006837817840278149,
      0.04925687238574028,
      -0.08520256727933884,
      0.02051679790019989,
      -0.042804960161447525,
      0.036213215440511703,
      0.038880426436662674,
      -0.018603922799229622,
      -0.08499284833669662,
      0.009910720400512218,
      0.018634939566254616,
      -0.005417864304035902,
      0.0213310569524765,
      -0.03722047433257103,
      0.10339675098657608,
      -0.014943119138479233,
      -0.02149282582104206,
      -0.08793959021568298,
      -0.013962571509182453,
      -0.036033663898706436,
      -0.005423601251095533,
      0.1072029396891594,
      0.0333334282040596,
      0.025797298178076744,
      -0.060707930475473404,
      -0.022500278428196907,
      0.0991191565990448,
      -0.11227896809577942,
      0.0845709964632988,
      0.00010214575013378635,
      -0.030248012393712997,
      0.010176446288824081,
      0.012320645153522491,
      0.02533155120909214,
      -0.025131557136774063,
      0.034036967903375626,
      -0.05339101701974869,
      0.03588749095797539,
      -0.007633983623236418,
      -0.08045054972171783,
      0.019779926165938377,
      0.04010532423853874,
      -0.056719668209552765,
      0.04501228407025337,
      0.0533469133079052,
      -0.007064748555421829,
      0.05591614171862602,
      -0.04679753631353378,
      0.04456239566206932,
      0.0686051994562149,
      0.03036803938448429,
      -0.022575341165065765,
      0.007570314221084118,
      0.0010889837285503745,
      0.05813470110297203,
      0.008385497145354748,
      -0.034389615058898926,
      0.015348932705819607,
      0.01167867612093687,
      0.04908232390880585,
      0.05108191445469856
    ],
    [
      0.018232058733701706,
      -0.004344480112195015,
      -0.03674786165356636,
      0.05440428853034973,
      -0.0111897774040699,
      0.0038697475101798773,
      -0.037221845239400864,
      0.049203306436538696,
      -0.05822250619530678,
      0.05217832699418068,
      0.010587260127067566,
      0.010241631418466568,
      -0.0207095667719841,
      0.01712796278297901,
      0.042294543236494064,
      0.07289085537195206,
      -0.08229436725378036,
      0.0066190860234200954,
      0.02998199313879013,
      -0.08469969034194946,
      0.051185399293899536,
      0.005134472157806158,
      -0.03441231697797775,
      0.04940161481499672,
      0.006345721427351236,
      0.008036576211452484,
      0.04985521733760834,
      0.052041567862033844,
      -0.008556238375604153,
      -0.03935005143284798,
      0.013569171540439129,
      -0.014231358654797077,
      0.0837176963686943,
      0.04244784265756607,
      0.005020164884626865,
      0.017730634659528732,
      0.033255938440561295,
      0.04025205597281456,
      -0.02036716230213642,
      -0.019116485491394997,
      0.05280119925737381,
      -0.08050886541604996,
      0.03740310296416283,
      0.08055253326892853,
      0.07357624173164368,
      0.01653398387134075,
      0.0307178758084774,
      -0.015510798431932926,
      0.019034432247281075,
      -0.02246762625873089,
      -0.01988307014107704,
      -0.10409900546073914,
      -0.01580743119120598,
      -0.0521233007311821,
      0.04371964931488037,
      -0.0006134496652521193,
      0.016453808173537254,
      0.0029242215678095818,
      0.05163930356502533,
      -0.09254296123981476,
      -0.06297357380390167,
      0.022748883813619614,
      -0.00617404468357563,
      -0.11423055827617645,
      -0.002065330510959029,
      -0.028212493285536766,
      -0.08312520384788513,
      -0.04965344816446304,
      -0.06951303780078888,
      0.022883664816617966,
      -0.05333913117647171,
      0.06722596287727356,
      -0.06801566481590271,
      -0.013963422738015652,
      -0.013967412523925304,
      -0.020089171826839447,
      -0.02212071605026722,
      -0.06243014708161354,
      -0.04103444144129753,
      0.03858291730284691,
      -0.11265809834003448,
      0.020579233765602112,
      -0.09300211817026138,
      0.024091368541121483,
      -0.013066580519080162,
      0.018670979887247086,
      0.08120724558830261,
      0.027683699503540993,
      -0.02369861491024494,
      0.04369776323437691,
      0.024487948045134544,
      -0.010780018754303455,
      0.04991303011775017,
      -0.015446016564965248,
      -0.078585684299469,
      0.01789347641170025,
      -0.062354009598493576,
      0.09342021495103836,
      0.044850096106529236,
      0.017588745802640915,
      0.026280570775270462,
      -0.007688539102673531,
      0.04190335422754288,
      0.03512512519955635,
      0.010782995261251926,
      0.0014591098297387362,
      0.005649778991937637,
      -0.06105490401387215,
      0.01213747076690197,
      0.03954866901040077,
      -0.022504307329654694,
      0.032828785479068756,
      -0.0676245242357254,
      -0.004214480519294739,
      0.012841575779020786,
      0.0028613004833459854,
      0.018956579267978668,
      -0.07174701243638992,
      -0.03842206299304962,
      0.008617152459919453,
      0.002233424223959446,
      -0.04772866517305374,
      -0.009041725657880306,
      0.022780641913414,
      0.1391584575176239,
      0.013536814600229263,
      0.0021364372223615646,
      0.011313377879559994,
      0.04447673261165619,
      0.023687340319156647,
      0.02686937339603901,
      0.017739318311214447,
      0.03413303196430206,
      -0.0718199610710144,
      0.023121461272239685,
      -0.007460562512278557,
      0.032353807240724564,
      -0.011680412106215954,
      -0.030835213139653206,
      0.08185732364654541,
      -0.08284416794776917,
      0.02166784182190895,
      0.044786036014556885,
      0.03546037897467613,
      0.05138860270380974,
      -0.01557945366948843,
      0.03460739180445671,
      0.008226529695093632,
      0.038373369723558426,
      0.06564588099718094,
      -0.08338658511638641,
      -0.02158031426370144,
      -0.0006002534064464271,
      -0.013446200639009476,
      -0.07095184922218323,
      0.09072498977184296,
      -0.004049576818943024,
      0.0006127614760771394,
      -0.03862813115119934,
      -0.013273845426738262,
      -0.018430296331644058,
      0.023179128766059875,
      0.01124135497957468,
      -0.023959433659911156,
      -0.032984282821416855,
      0.010833119042217731,
      -0.07152324914932251,
      0.06170440465211868,
      -0.0006257330533117056,
      0.04460488259792328,
      -0.035825107246637344,
      -0.03560361638665199,
      0.05157504603266716,
      -0.023266613483428955,
      -0.039156027138233185,
      -0.012188578955829144,
      0.011425762437283993,
      -0.07369270920753479,
      0.04515563324093819,
      -0.04604572057723999,
      -0.01730326935648918,
      0.03701044246554375,
      -0.05282256007194519,
      0.029597358778119087,
      0.08238466084003448,
      -0.054011840373277664,
      0.05307016149163246,
      -0.09309996664524078,
      -0.11483527719974518,
      -0.031046269461512566,
      0.033260002732276917,
      0.044484902173280716,
      -0.03321937099099159,
      -0.062237661331892014,
      -0.00669417530298233,
      -0.007072098087519407,
      -0.056797873228788376,
      -0.061834678053855896,
      -0.0002463616256136447,
      0.03157190605998039,
      -0.06218558922410011,
      -0.016533026471734047,
      0.021384716033935547,
      -0.02105100452899933,
      0.011626418679952621,
      0.03096330724656582,
      -0.021354064345359802,
      -0.02650788240134716,
      0.04872020334005356,
      -0.039487067610025406,
      0.013250455260276794,
      -0.03797069191932678,
      0.019396759569644928,
      0.02010832354426384,
      0.04568721354007721,
      -0.042109012603759766,
      0.0018924213945865631,
      0.013049820438027382,
      0.035894956439733505,
      -0.06135723739862442,
      -0.0627785325050354,
      -0.03044775314629078,
      0.012505586259067059,
      0.04271506145596504,
      0.029881002381443977,
      -0.011689310893416405,
      0.0010669955518096685,
      -0.015131529420614243,
      -0.007073178421705961,
      0.04762954264879227,
      0.04223835468292236,
      -0.05487430468201637,
      0.06519061326980591,
      0.02112037129700184,
      0.0033872982021421194,
      -0.009173325262963772,
      -0.02035749889910221,
      0.024413809180259705,
      -0.07484795898199081,
      -0.023342115804553032,
      0.029827868565917015,
      0.0009677765192463994,
      -0.011399555951356888,
      -0.029884804040193558,
      -0.03327728435397148,
      -0.04165906831622124,
      -0.038594819605350494,
      -0.04088670760393143,
      -0.0040783933363854885,
      -0.011293315328657627,
      -0.02320929989218712,
      -0.06824786961078644,
      -0.014957900159060955,
      0.06493795663118362,
      -0.03226768225431442,
      0.11221230030059814,
      -0.08475030213594437,
      -0.10333486646413803,
      -0.018398206681013107,
      -0.011812515556812286,
      0.07208004593849182,
      0.00025052897399291396,
      0.0237587820738554,
      -0.018711457028985023,
      -0.043478336185216904,
      0.023965032771229744,
      0.001132274279370904,
      0.011005730368196964,
      -0.0108420979231596,
      -0.07364581525325775,
      0.017684634774923325,
      0.07513780146837234,
      0.03722416236996651,
      -0.02030039206147194,
      -0.030439145863056183,
      0.022828510031104088,
      0.014979660511016846,
      -0.014260765165090561,
      0.002063383348286152,
      -0.002531636506319046,
      0.0014761716593056917,
      0.05756044015288353,
      -0.0032678369898349047,
      -0.051838282495737076,
      -0.01582777127623558,
      -0.05941535532474518,
      -0.08673550188541412,
      -0.046074848622083664,
      0.05203184112906456,
      0.04268994554877281,
      0.01678733341395855,
      0.04853937774896622,
      -0.028880525380373,
      0.004117865115404129,
      -0.027346521615982056,
      -0.052427325397729874,
      0.0489664301276207,
      -0.009221836924552917,
      0.07794398814439774,
      -0.03731130436062813,
      -0.0034130823332816362,
      -0.022579461336135864,
      0.0579511933028698,
      0.019826972857117653,
      -0.03859861567616463,
      0.027539730072021484,
      -0.05305537208914757,
      -0.02347888983786106,
      -0.011133715510368347,
      0.08216765522956848,
      0.01457698829472065,
      0.017644481733441353,
      -0.013779498636722565,
      -0.02947060391306877,
      0.03561072051525116,
      0.03462136536836624,
      -0.0157434344291687,
      -0.016278373077511787,
      0.01845235750079155,
      0.023698754608631134,
      -0.008221465162932873,
      -0.03910618647933006,
      -0.06600335240364075,
      0.1160537376999855,
      0.03008952923119068,
      -0.0495840422809124,
      0.0128388786688447,
      -0.019024355337023735,
      -0.04781469330191612,
      0.04973715916275978,
      0.0061202445067465305,
      0.05693856254220009,
      0.0083622382953763,
      -0.008149983361363411,
      -0.05602540075778961,
      0.00033192546106874943,
      0.0500057078897953,
      -0.0642867386341095,
      0.04455104097723961,
      0.05668877065181732,
      -0.07738030701875687,
      -0.0310052540153265,
      0.01957552693784237,
      0.019875213503837585,
      -0.06144106015563011,
      -0.015826135873794556,
      -0.004414002876728773,
      -0.056200556457042694,
      0.05635889992117882,
      -0.027524061501026154,
      0.014043542556464672,
      -0.009379428811371326,
      -0.02152576856315136,
      0.03708503022789955,
      -0.04862016811966896,
      -0.009745877236127853,
      0.01595759578049183,
      -0.00985440332442522,
      0.0013171348255127668,
      0.0684492215514183,
      0.004979800432920456,
      0.024952003732323647,
      -0.10116439312696457,
      0.05020906403660774,
      0.016359897330403328,
      -0.020060643553733826,
      -0.014055591076612473,
      0.08158835768699646,
      -0.046942003071308136,
      0.02328919619321823,
      -0.036859240382909775,
      0.015593414194881916,
      0.010170250199735165,
      0.034953124821186066,
      0.08243285864591599,
      0.0711519792675972,
      -0.05842408537864685,
      0.06535663455724716,
      0.023981155827641487,
      -0.06851357966661453,
      -0.0027712236624211073,
      0.03001805581152439,
      -0.04287368059158325,
      0.008465317077934742,
      0.0038396641612052917,
      -0.01815076917409897,
      0.056943852454423904,
      -0.05802977457642555,
      -0.04632920026779175,
      0.04398997873067856,
      -0.0013831479009240866,
      0.1177959218621254,
      -0.019985545426607132,
      0.08112867176532745,
      0.05451931804418564,
      0.017413951456546783,
      0.016080785542726517,
      -0.03162045031785965,
      0.011845885775983334,
      0.0491943284869194,
      -0.05036734789609909,
      -0.057326678186655045,
      -0.02717551775276661,
      0.025319404900074005,
      0.09940711408853531,
      0.03933359682559967,
      0.1008051335811615,
      0.028121303766965866,
      -0.020076919347047806,
      -0.02084418572485447,
      0.1327025145292282,
      0.005555001553148031,
      -0.03656623139977455,
      0.03245703503489494,
      0.01655752770602703,
      0.011907320469617844,
      0.029989587143063545,
      -0.013088076375424862,
      0.006155561190098524,
      -0.03937673941254616,
      -0.0521504171192646,
      0.02419658936560154,
      -0.036928046494722366,
      -0.06858410686254501,
      -0.011028622277081013,
      -0.004240597598254681,
      -0.03181617707014084,
      -0.0010501677170395851,
      0.031952131539583206,
      -0.036719877272844315,
      -0.03887525573372841,
      -0.04380660876631737,
      -0.07809892296791077,
      0.07715380936861038,
      -0.020482582971453667,
      0.07586050778627396,
      0.025734519585967064,
      0.1251276135444641,
      -0.10381529480218887,
      0.04384506121277809,
      -0.061473067849874496,
      0.04315733164548874,
      0.03879091143608093,
      0.08277479559183121,
      -0.050822049379348755,
      -0.03226397559046745,
      0.015353661961853504,
      0.00350740784779191,
      -0.02550436742603779,
      -0.033490139991045,
      -0.06658843904733658,
      -0.006275840103626251,
      0.0026782555505633354,
      -0.03292097896337509,
      0.04742790386080742,
      0.023099401965737343,
      -0.03853604570031166,
      0.0021994446869939566,
      0.04199410229921341,
      0.006091092713177204,
      -0.025234753265976906,
      -0.03651893511414528,
      0.006792975589632988,
      -0.04963798448443413,
      -0.021959535777568817,
      -0.02066212147474289,
      0.016409380361437798,
      -0.05993754416704178,
      0.008552390150725842,
      0.013983466662466526,
      -0.06115759164094925,
      0.04946475848555565,
      0.0519322007894516,
      -0.0006638307822868228,
      0.07341515272855759,
      -0.009108739905059338,
      0.0003229491412639618,
      0.0561261810362339,
      0.057326167821884155,
      0.05827460065484047,
      0.008489646948873997,
      0.02738577127456665,
      -0.02859996072947979,
      0.0627029687166214,
      0.01468601729720831,
      0.009805946610867977,
      0.017496097832918167,
      0.010185912251472473,
      0.046155840158462524,
      0.019252318888902664,
      0.037655845284461975,
      0.005400138907134533,
      -0.033763010054826736,
      0.004750014282763004,
      -0.006939491722732782,
      -0.021508218720555305,
      -0.0026358426548540592,
      -0.02143717370927334,
      0.00603424571454525,
      0.12228372693061829,
      -0.07455161213874817,
      -0.007536182180047035,
      0.019389841705560684
    ],
    [
      0.08989343047142029,
      0.005912858992815018,
      -0.04286815598607063,
      0.012323806062340736,
      -0.049874868243932724,
      0.06363437324762344,
      0.015554163604974747,
      0.06265556812286377,
      -0.025886455550789833,
      -0.0061707161366939545,
      -0.027736149728298187,
      -0.03880247846245766,
      0.020575910806655884,
      -0.018929651007056236,
      0.012711811810731888,
      0.022369572892785072,
      -0.033578403294086456,
      -0.10873940587043762,
      0.02680046111345291,
      -0.032629139721393585,
      -0.05594504624605179,
      -0.031163930892944336,
      0.09727516025304794,
      -0.1389135718345642,
      -0.012455652467906475,
      0.008758267387747765,
      0.03580079972743988,
      -0.08381824195384979,
      0.052460718899965286,
      0.009584746323525906,
      -0.06862827390432358,
      -0.08664859086275101,
      0.018465407192707062,
      0.033854465931653976,
      0.08036065101623535,
      0.05607941001653671,
      0.037067219614982605,
      -0.08495857566595078,
      0.05879925563931465,
      -0.025872910395264626,
      0.04026224836707115,
      0.030171534046530724,
      0.07531476765871048,
      -0.016669102013111115,
      -0.05770275741815567,
      0.03351535275578499,
      -0.10014795511960983,
      -0.01119113527238369,
      0.057468231767416,
      -0.033679138869047165,
      0.025098105892539024,
      -0.01187171135097742,
      -0.0006612127181142569,
      0.006022681016474962,
      -0.0686468854546547,
      -0.03704815357923508,
      0.03338044136762619,
      -0.023099549114704132,
      -0.02905617095530033,
      0.028213931247591972,
      0.024999959394335747,
      -0.04971453920006752,
      0.02522316575050354,
      0.029683886095881462,
      0.02677074447274208,
      -0.026216872036457062,
      0.06119806319475174,
      0.06954030692577362,
      0.029005248099565506,
      0.010453101247549057,
      -0.04635791480541229,
      -0.03625524416565895,
      -0.018865231424570084,
      0.042964302003383636,
      -0.03532671928405762,
      0.026353588327765465,
      -0.03875896707177162,
      -0.010381748899817467,
      -0.08234268426895142,
      -0.03623263165354729,
      -0.008476532064378262,
      0.0009394322987645864,
      0.0016230293549597263,
      -0.011454933322966099,
      0.073283851146698,
      0.037755969911813736,
      0.0873088613152504,
      0.07220004498958588,
      -0.05417453125119209,
      -0.009132731705904007,
      0.01598173752427101,
      0.032260019332170486,
      0.002480653580278158,
      0.0655403882265091,
      0.05552039295434952,
      -0.059693075716495514,
      0.032587748020887375,
      0.09233442693948746,
      -0.0002509818586986512,
      0.009858186356723309,
      -0.028704049065709114,
      -0.02602921612560749,
      0.05016017332673073,
      0.035415153950452805,
      0.029555555433034897,
      -0.03719625994563103,
      0.03423066437244415,
      0.03011346235871315,
      0.02842155285179615,
      -0.00015779922250658274,
      -0.0760936513543129,
      -0.03723018616437912,
      0.07381998747587204,
      0.04499070718884468,
      0.04702521860599518,
      -0.005531396716833115,
      -0.004338982980698347,
      -0.03413516283035278,
      0.008560228161513805,
      -0.015281087718904018,
      0.05503484979271889,
      -0.0613449364900589,
      -0.01791023463010788,
      0.031632840633392334,
      0.03677419573068619,
      -0.029902083799242973,
      -0.0010047901887446642,
      0.042279116809368134,
      0.002593087265267968,
      0.11477845907211304,
      0.021464375779032707,
      -0.019635092467069626,
      -0.009914008900523186,
      -0.0507783517241478,
      0.0833844244480133,
      -0.044216372072696686,
      -0.011897224001586437,
      0.0026448755525052547,
      -0.01606333814561367,
      0.030444294214248657,
      0.016405655071139336,
      0.10497702658176422,
      0.010299419052898884,
      -0.03840642794966698,
      -0.04212852567434311,
      -0.06588952988386154,
      -0.07922464609146118,
      -0.04050238057971001,
      -0.06230892613530159,
      0.047493450343608856,
      0.008800860494375229,
      0.044105157256126404,
      0.010992651805281639,
      -0.03612400218844414,
      -0.02325121872127056,
      -0.053638309240341187,
      0.03876449912786484,
      0.010648897849023342,
      0.02878972887992859,
      0.059212446212768555,
      -0.01846298761665821,
      -0.043441370129585266,
      0.08297209441661835,
      0.06206594780087471,
      0.041726771742105484,
      -0.03147100284695625,
      0.014497438445687294,
      -0.049761366099119186,
      0.022007452324032784,
      0.08531373739242554,
      0.02737327665090561,
      0.017898257821798325,
      0.02564437873661518,
      0.022602390497922897,
      -0.035182155668735504,
      -0.0546867735683918,
      0.03342345356941223,
      0.041206780821084976,
      -0.008491027168929577,
      0.019487690180540085,
      0.00341096636839211,
      0.04409422352910042,
      -0.011830354109406471,
      0.0009662817465141416,
      -0.009803220629692078,
      0.02870830148458481,
      -0.006610815413296223,
      0.009314900264143944,
      0.025976069271564484,
      -0.06389687210321426,
      -0.03149697557091713,
      -0.02574225142598152,
      0.03742041811347008,
      0.03819612041115761,
      0.04436682164669037,
      -0.0077515291050076485,
      0.052255235612392426,
      0.029964568093419075,
      0.028749223798513412,
      0.04759902134537697,
      0.02466176077723503,
      0.03297902271151543,
      0.03468698635697365,
      -0.01779082603752613,
      -0.03086177632212639,
      -0.047297313809394836,
      0.00735215051099658,
      -0.01953410916030407,
      -0.03859154134988785,
      0.11471050977706909,
      0.030288200825452805,
      -0.0570639967918396,
      0.05697108805179596,
      -0.09517496824264526,
      -0.012186694890260696,
      0.005848280154168606,
      0.0029205225873738527,
      0.007793388795107603,
      -0.14710862934589386,
      0.008529549464583397,
      0.04186504706740379,
      -0.012076523154973984,
      -0.087798573076725,
      -0.0014421986415982246,
      -0.0338837169110775,
      0.010352008044719696,
      -0.000845183851197362,
      0.00573655404150486,
      -0.00881339143961668,
      0.0884334146976471,
      -0.02248230203986168,
      -0.01123651210218668,
      0.07248062640428543,
      -0.018238145858049393,
      0.013098366558551788,
      -0.05005665868520737,
      -0.029599767178297043,
      0.021464399993419647,
      -0.03411320224404335,
      -0.03921372443437576,
      0.011358865536749363,
      0.0472734272480011,
      0.033444855362176895,
      -0.06343857944011688,
      -0.00933048315346241,
      -0.0036823253612965345,
      0.01990380883216858,
      0.06863028556108475,
      -0.01394454762339592,
      -0.018754543736577034,
      -0.04537661373615265,
      -0.09068624675273895,
      0.02558857761323452,
      -0.0533074326813221,
      -0.011326785199344158,
      -0.017264652997255325,
      -0.05582129582762718,
      -0.04111859202384949,
      0.047853097319602966,
      0.028257466852664948,
      -0.03667218238115311,
      -0.08728212863206863,
      -0.041066721081733704,
      -0.00934741273522377,
      -0.008260639384388924,
      -0.05508393421769142,
      -0.040274277329444885,
      0.03935312107205391,
      -0.028747964650392532,
      0.0017852656310424209,
      0.058814726769924164,
      -0.046659182757139206,
      -0.02635868825018406,
      -0.03401574492454529,
      0.01988392323255539,
      0.08785364776849747,
      -0.03772028163075447,
      -0.032299723476171494,
      0.009402472525835037,
      0.011205565184354782,
      0.01209073606878519,
      0.014742589555680752,
      0.0028135841712355614,
      -0.1040637269616127,
      -0.08792199194431305,
      -0.08557461202144623,
      0.025399550795555115,
      0.034577228128910065,
      0.03980276361107826,
      -0.05873513221740723,
      9.241078805644065e-05,
      -0.007505472749471664,
      0.02469440922141075,
      -0.0021866410970687866,
      -0.04598478227853775,
      0.0012091546086594462,
      0.012161090038716793,
      -0.0529627799987793,
      0.06751684099435806,
      -0.002434473019093275,
      0.15791618824005127,
      0.03386378288269043,
      -0.001448777038604021,
      -0.06771646440029144,
      -0.008764848113059998,
      0.03332509472966194,
      -0.04832760617136955,
      -0.0281559806317091,
      0.08789341151714325,
      0.016599608585238457,
      0.014341858215630054,
      -0.0640314370393753,
      0.012850593775510788,
      0.08249638974666595,
      -0.04153461754322052,
      0.0048468573950231075,
      -0.070344477891922,
      -0.038215264678001404,
      -0.025110948830842972,
      -0.016171637922525406,
      -0.09395405650138855,
      -0.004891727119684219,
      0.031593598425388336,
      0.029243605211377144,
      0.042050909250974655,
      0.07342655211687088,
      -0.04649391397833824,
      -0.020876573398709297,
      0.053400762379169464,
      0.014436532743275166,
      0.0014201097656041384,
      0.03650297224521637,
      0.01926901936531067,
      0.07422325760126114,
      -0.037607256323099136,
      -0.02434280700981617,
      -0.01655205897986889,
      -0.044618815183639526,
      0.026016829535365105,
      0.039338961243629456,
      -0.016884110867977142,
      -0.03205409646034241,
      -0.0049868798814713955,
      -0.05303717032074928,
      0.020730501040816307,
      -0.021134985610842705,
      -0.04931548610329628,
      -0.031001124531030655,
      0.038705337792634964,
      0.042126692831516266,
      -0.014744194224476814,
      0.027572594583034515,
      -0.0328911654651165,
      0.03565988689661026,
      -0.00013288127956911922,
      -0.013295290060341358,
      -0.01728830114006996,
      0.05014465004205704,
      0.09432701021432877,
      0.10766874253749847,
      0.03324393182992935,
      -0.03689021244645119,
      -0.05524733290076256,
      0.01909976452589035,
      -0.03634723648428917,
      -0.05853665620088577,
      -0.05613081157207489,
      -0.021959327161312103,
      -0.07979156821966171,
      -0.06373629719018936,
      -0.03273412212729454,
      -0.05316907539963722,
      0.07012023031711578,
      0.04884183034300804,
      -0.03120015375316143,
      0.05169693008065224,
      -0.017618486657738686,
      0.0652833878993988,
      0.015530169010162354,
      0.002654568525031209,
      -0.044634055346250534,
      0.06254914402961731,
      -0.07421357184648514,
      0.0012535231653600931,
      -0.021883144974708557,
      -0.019557960331439972,
      0.026377320289611816,
      -0.08039561659097672,
      0.04160235822200775,
      -0.07392977178096771,
      -0.035644371062517166,
      -0.006877390667796135,
      -0.02425384148955345,
      0.010244473814964294,
      0.08060118556022644,
      0.03625737875699997,
      -0.015325934626162052,
      0.016837220638990402,
      0.07450006902217865,
      -0.002269032411277294,
      0.046769462525844574,
      0.03743348643183708,
      -0.02210095152258873,
      -0.02840765193104744,
      0.03289621323347092,
      -0.02868497185409069,
      -0.08571799099445343,
      -0.033029962331056595,
      0.0005132136284373701,
      0.008593971841037273,
      0.02686994895339012,
      0.013226754032075405,
      -0.006045723333954811,
      -0.06409378349781036,
      0.0200190432369709,
      -0.07577644288539886,
      -0.06565574556589127,
      -0.03541857376694679,
      0.05069417506456375,
      0.061997830867767334,
      0.05467492714524269,
      0.04415597394108772,
      -0.010144319385290146,
      0.011663993820548058,
      0.004805208649486303,
      0.03473913297057152,
      0.03446376696228981,
      -0.13770237565040588,
      0.039364043623209,
      -0.05851998180150986,
      0.027489829808473587,
      -0.02854185365140438,
      -0.045225027948617935,
      0.055356841534376144,
      0.07051493972539902,
      -0.027833474799990654,
      0.04727473109960556,
      -0.02207482047379017,
      0.0055234250612556934,
      -0.02817920595407486,
      -0.020876524969935417,
      -0.06545108556747437,
      -0.031901098787784576,
      -0.02978866547346115,
      -0.06282022595405579,
      0.01761220581829548,
      0.058509524911642075,
      0.06128042936325073,
      0.005118801724165678,
      0.058675434440374374,
      -0.09157037734985352,
      0.07736525684595108,
      -0.025517335161566734,
      0.041557677090168,
      0.027084659785032272,
      0.010534199886023998,
      -0.03002949431538582,
      -0.049945611506700516,
      -0.08232160657644272,
      -0.05354372411966324,
      -0.0182639192789793,
      -0.037391163408756256,
      -0.025086047127842903,
      0.012043910101056099,
      0.07301945239305496,
      0.059841640293598175,
      0.03314383327960968,
      0.0030393865890800953,
      -0.0038514414336532354,
      -0.03914886340498924,
      0.07489748299121857,
      -0.07315313071012497,
      0.04760575667023659,
      0.0072245122864842415,
      -0.02949458360671997,
      -0.031103629618883133,
      0.025155922397971153,
      0.03839440643787384,
      -0.07328440994024277,
      -0.020742142572999,
      -0.02736787684261799,
      0.009932847693562508,
      -0.024063128978013992,
      -0.04176397994160652,
      0.075304314494133,
      -0.007813193835318089,
      -0.005094609223306179,
      0.06804413348436356,
      -0.028222711756825447,
      0.015035150572657585,
      0.09043626487255096,
      -0.03179492428898811,
      -0.04495595023036003,
      -0.06652340292930603,
      0.06224476546049118,
      0.006049324292689562,
      -0.01648697815835476,
      0.059260737150907516,
      0.030477939173579216,
      -0.009117668494582176,
      0.03853737935423851,
      -0.022351430729031563,
      0.03482048586010933
    ],
    [
      0.0027447352185845375,
      0.06475158035755157,
      0.013724071905016899,
      0.056217022240161896,
      -0.0018091162201017141,
      0.01894659362733364,
      0.1042228490114212,
      -0.009267217479646206,
      0.000965437269769609,
      0.005981295369565487,
      -0.05510299652814865,
      0.03806852549314499,
      -0.009225892834365368,
      0.10218536853790283,
      0.0066467332653701305,
      0.018153205513954163,
      -0.006083243992179632,
      0.024532606825232506,
      -0.010668843053281307,
      -0.017305875197052956,
      0.060495905578136444,
      0.052626293152570724,
      0.04735414311289787,
      -0.005094551481306553,
      -0.02240651287138462,
      -0.03341592475771904,
      -0.0891922116279602,
      -0.009929919615387917,
      0.023768527433276176,
      0.0026559336110949516,
      0.02291751094162464,
      -0.03194591403007507,
      -0.054625216871500015,
      -0.0022210413590073586,
      -0.04929383471608162,
      0.08899383991956711,
      0.00011575480311876163,
      0.051431603729724884,
      -0.004382053855806589,
      0.01975913904607296,
      -0.003159745130687952,
      -0.016941102221608162,
      -0.06816661357879639,
      0.059823695570230484,
      0.01844225823879242,
      -0.022333616390824318,
      -0.0862659439444542,
      -0.005603043362498283,
      -0.05294506251811981,
      -0.04177634418010712,
      0.08058177679777145,
      -0.04632600396871567,
      -0.024653438478708267,
      -0.019136160612106323,
      -0.034188345074653625,
      -0.03856169804930687,
      -0.004179902374744415,
      -0.00018769907183013856,
      0.05839715525507927,
      -0.08020459860563278,
      0.00876388419419527,
      0.0089009590446949,
      0.035124871879816055,
      0.0012976881116628647,
      0.005277501884847879,
      -0.016393035650253296,
      -0.06815735995769501,
      -7.676004315726459e-05,
      0.004100134130567312,
      0.029079433530569077,
      0.037629082798957825,
      -0.008015544153749943,
      -0.045176465064287186,
      0.028486056253314018,
      0.01099769864231348,
      0.05239371582865715,
      0.0347522534430027,
      -0.0599188394844532,
      -0.04759916290640831,
      -0.03189955651760101,
      0.017328811809420586,
      0.015339161269366741,
      0.008046089671552181,
      0.11702516674995422,
      -0.018458368256688118,
      -0.028203418478369713,
      0.04661644995212555,
      -0.004016021266579628,
      -0.014586849138140678,
      -0.02149404212832451,
      0.04791804403066635,
      0.019050249829888344,
      0.04793994501233101,
      0.0983787551522255,
      -0.05151371657848358,
      0.053397323936223984,
      0.04957802966237068,
      -0.01641436666250229,
      0.036539703607559204,
      -0.01241233292967081,
      -0.01429511047899723,
      -0.001972708385437727,
      0.07761752605438232,
      0.005757947918027639,
      0.04783954098820686,
      0.014398631639778614,
      0.07905467599630356,
      -0.0037484955973923206,
      0.035848602652549744,
      -0.055551014840602875,
      0.09451578557491302,
      -0.006060443818569183,
      -0.023736193776130676,
      -0.010049215517938137,
      -0.07738491892814636,
      0.022865891456604004,
      -0.005209044087678194,
      -0.016032667830586433,
      -0.024733686819672585,
      -0.12584882974624634,
      0.024886799976229668,
      0.016178173944354057,
      0.01638624630868435,
      0.0017451905878260732,
      -0.03898490220308304,
      0.045714907348155975,
      0.023268964141607285,
      -0.04054860398173332,
      -0.05782153084874153,
      0.022129150107502937,
      0.064572773873806,
      -0.015829794108867645,
      -0.0024095368571579456,
      -0.033452972769737244,
      0.027204234153032303,
      0.0784064307808876,
      0.009784516878426075,
      -0.0165984109044075,
      0.010558085516095161,
      0.0058713145554065704,
      -0.0627371147274971,
      -0.08914323151111603,
      -0.0070588244125247,
      0.03393736854195595,
      -0.012282000854611397,
      0.06649915874004364,
      -0.022386685013771057,
      -0.10087879002094269,
      0.005448391195386648,
      0.055930741131305695,
      0.005393559578806162,
      0.007532441522926092,
      0.007635148242115974,
      0.05363345518708229,
      0.07025608420372009,
      0.030659139156341553,
      -0.010255073197185993,
      -0.05849238112568855,
      0.012304984964430332,
      0.031401075422763824,
      0.017398929223418236,
      -0.023524509742856026,
      -0.10341781377792358,
      -0.0009902430465444922,
      -0.033525753766298294,
      -0.008639133535325527,
      0.02326495386660099,
      -0.006183446850627661,
      0.026123404502868652,
      0.036547381430864334,
      -0.05605471506714821,
      -0.01648271270096302,
      -0.017100725322961807,
      0.015078242868185043,
      -0.01849525235593319,
      -0.04017993062734604,
      0.01553849596530199,
      -0.02075829729437828,
      0.008964917622506618,
      0.0687725692987442,
      -0.027878468856215477,
      -0.022380227223038673,
      -0.0005704824579879642,
      -0.008877270855009556,
      -0.0246165469288826,
      -0.04695216938853264,
      0.06722746789455414,
      0.036881305277347565,
      -0.010279458947479725,
      -0.10022687911987305,
      0.06155317649245262,
      -0.04102681204676628,
      -0.013040109537541866,
      0.017000120133161545,
      -0.030852001160383224,
      0.02675776369869709,
      -0.05684463307261467,
      -0.0034722546115517616,
      -0.03362317010760307,
      -0.04990660399198532,
      0.010397201403975487,
      -0.06354201585054398,
      -0.02642541565001011,
      -0.005274361930787563,
      0.04232664406299591,
      0.007314369548112154,
      -0.015968620777130127,
      0.016507385298609734,
      -0.06932279467582703,
      0.06881030648946762,
      -0.04645775258541107,
      0.0023356203455477953,
      0.04105767607688904,
      0.017440691590309143,
      -0.00875850860029459,
      -0.007872084155678749,
      -0.10064409673213959,
      -0.02717956155538559,
      0.013699625618755817,
      0.0507962591946125,
      0.061892300844192505,
      0.005841446574777365,
      -0.038506392389535904,
      0.010736016556620598,
      0.026933500543236732,
      0.00830528698861599,
      -0.04556317999958992,
      0.0002474784851074219,
      -0.005557748023420572,
      0.08995025604963303,
      0.0921906977891922,
      -0.05898405611515045,
      0.039974991232156754,
      0.03155222162604332,
      0.004963505547493696,
      -0.053073279559612274,
      -0.021959567442536354,
      0.04359716922044754,
      -0.05058474466204643,
      -0.0062159886583685875,
      0.03594855219125748,
      0.01702244020998478,
      -0.011944733560085297,
      0.026542343199253082,
      0.019956523552536964,
      0.0036758731584995985,
      -0.037498507648706436,
      0.0480380654335022,
      0.03624653071165085,
      0.036347128450870514,
      0.06651201099157333,
      -0.1051611378788948,
      0.00909641943871975,
      -0.02396932989358902,
      0.03907817602157593,
      0.016560960561037064,
      -0.021956365555524826,
      0.037389617413282394,
      0.05716327577829361,
      -0.05963464081287384,
      0.04589013382792473,
      -0.04561467096209526,
      -0.08553510904312134,
      0.08230532705783844,
      0.023258892819285393,
      0.01592196151614189,
      0.022793017327785492,
      0.052694547921419144,
      -0.0015973363770172,
      -0.03806352615356445,
      -0.051262181252241135,
      0.021987618878483772,
      0.03302288427948952,
      -0.01909053511917591,
      -0.014963936991989613,
      -0.003614252433180809,
      -0.0767001360654831,
      -0.006974267307668924,
      0.047875694930553436,
      -0.008155123330652714,
      -0.09846454858779907,
      -0.047315601259469986,
      -0.07810480892658234,
      -0.04510166496038437,
      0.03586253151297569,
      0.07220494002103806,
      -0.0320236012339592,
      -0.07203853875398636,
      -0.019854091107845306,
      -0.017479252070188522,
      0.14456017315387726,
      0.015887031331658363,
      -0.052894607186317444,
      -0.00924230832606554,
      -0.007895645685493946,
      0.014542624354362488,
      0.02803514525294304,
      0.011833388358354568,
      -0.12046285718679428,
      0.06481027603149414,
      -0.0036715418100357056,
      -0.007446231786161661,
      0.03480499982833862,
      -0.002274592872709036,
      -0.02471238002181053,
      -0.023888133466243744,
      0.02982240729033947,
      -0.059568509459495544,
      0.023374777287244797,
      0.03950844332575798,
      -0.05094475299119949,
      0.08101553469896317,
      -0.005457267165184021,
      -0.01003065425902605,
      -0.000783568131737411,
      0.009349322877824306,
      0.047503940761089325,
      -0.008452308364212513,
      -0.005321335978806019,
      -0.013715657405555248,
      0.03509044274687767,
      0.0027013712096959352,
      -0.03980925679206848,
      0.06067109853029251,
      0.007020896766334772,
      0.03445504605770111,
      0.03090602718293667,
      -0.040997207164764404,
      -0.042013950645923615,
      0.01057814434170723,
      0.04575679451227188,
      0.022497301921248436,
      -0.0022813212126493454,
      -0.0007798374281264842,
      0.04131002351641655,
      -0.037116412073373795,
      0.06296856701374054,
      0.033900558948516846,
      -0.04746110364794731,
      -0.017254836857318878,
      0.03566688299179077,
      0.017106547951698303,
      -0.08272402733564377,
      -0.01663818582892418,
      0.03397826850414276,
      -0.006720288656651974,
      0.027579067274928093,
      -0.010205736383795738,
      -0.022840505465865135,
      -0.030035583302378654,
      0.04216284304857254,
      0.040151480585336685,
      -0.0018450093921273947,
      -0.018342934548854828,
      0.05077426880598068,
      -0.013551393523812294,
      0.04656321927905083,
      -0.07313259690999985,
      0.01849844865500927,
      -0.007762289140373468,
      -0.057898588478565216,
      0.02176811546087265,
      -0.06463800370693207,
      0.02983352728188038,
      -0.07416507601737976,
      -0.06491795927286148,
      0.021589433774352074,
      0.010554901324212551,
      0.0377056710422039,
      0.0005370398284867406,
      0.024763770401477814,
      -0.007873508147895336,
      -0.019150136038661003,
      0.025676652789115906,
      -0.06637309491634369,
      0.005726087372750044,
      -0.02551385946571827,
      -0.03181597590446472,
      0.06139811500906944,
      0.04153946414589882,
      0.043916624039411545,
      -0.00880612526088953,
      0.0017779363552108407,
      0.036714568734169006,
      0.057543739676475525,
      0.00955243594944477,
      -0.008630666881799698,
      0.05217568576335907,
      -0.029845189303159714,
      0.053306322544813156,
      -0.028498763218522072,
      0.10908002406358719,
      0.02905304916203022,
      -0.023957351222634315,
      -0.0356876403093338,
      -0.004913263954222202,
      -0.12232933193445206,
      0.08707123249769211,
      0.018200179561972618,
      -0.02619325928390026,
      0.06537226587533951,
      -0.04044220224022865,
      0.010933266952633858,
      0.030447902157902718,
      0.011708562262356281,
      -0.03518962487578392,
      0.04896019399166107,
      -0.030884966254234314,
      0.015455750748515129,
      -0.0020569157786667347,
      0.011201632209122181,
      0.0734739825129509,
      -0.019650857895612717,
      0.02758059650659561,
      0.0548524484038353,
      -0.021798744797706604,
      -0.06347712129354477,
      -0.027578255161643028,
      -0.003759985091164708,
      0.017977021634578705,
      0.013391325250267982,
      -0.05192377045750618,
      -0.06421168893575668,
      0.022020019590854645,
      -0.08608558773994446,
      -0.05015541613101959,
      0.02640814334154129,
      -0.00036406179424375296,
      -0.05200209096074104,
      -0.031146999448537827,
      -0.038244765251874924,
      -0.003578923875465989,
      -0.017345784232020378,
      0.034432996064424515,
      -0.029027825221419334,
      -0.007490674965083599,
      0.034117043018341064,
      -0.06870318949222565,
      -0.018520072102546692,
      -0.024670938029885292,
      0.0183032788336277,
      -0.03715994209051132,
      -0.04240398481488228,
      -0.002865358255803585,
      -0.02906930260360241,
      0.0054949019104242325,
      -0.03319377824664116,
      -0.00572236068546772,
      0.04142184555530548,
      0.01478141825646162,
      -0.010859829373657703,
      0.0014214609982445836,
      -0.02277587167918682,
      0.025041114538908005,
      -0.07935599237680435,
      -0.049966759979724884,
      -0.052311159670352936,
      0.0313623771071434,
      0.012463795952498913,
      -0.043222393840551376,
      -0.06405559182167053,
      -0.004695442970842123,
      -0.04505554586648941,
      -0.04206022247672081,
      0.015251560136675835,
      0.05245669186115265,
      -0.047526802867650986,
      0.01995781436562538,
      -0.031748104840517044,
      0.012759651988744736,
      -0.04106394574046135,
      -0.03782134875655174,
      -0.03362641483545303,
      0.007719496265053749,
      0.04154113680124283,
      -0.038614243268966675,
      -0.009051323868334293,
      0.0019464733777567744,
      0.01619674451649189,
      0.029632259160280228,
      -0.07338797301054001,
      -0.08837655186653137,
      0.03260766714811325,
      -0.026689542457461357,
      -0.02703258767724037,
      0.0415329672396183,
      0.01011891383677721,
      0.073344387114048,
      -0.009978165850043297,
      -0.017853951081633568,
      -0.08178122341632843,
      -0.035018034279346466,
      -0.0010051615536212921,
      0.04918688163161278,
      -0.005467097740620375,
      -0.015644637867808342,
      -0.03257293999195099,
      0.017373232170939445,
      -0.027872957289218903,
      0.05778450518846512,
      -0.0060255988501012325,
      -0.0389898456633091,
      0.06253022700548172
    ],
    [
      0.008687719702720642,
      -0.0637788400053978,
      0.026194285601377487,
      0.025136664509773254,
      -0.07065355032682419,
      0.027676545083522797,
      -0.0013202156405895948,
      0.022518662735819817,
      -0.026877492666244507,
      -0.06930261105298996,
      -0.015493511222302914,
      0.02212577499449253,
      -0.06424450874328613,
      -0.029993724077939987,
      -0.07844371348619461,
      0.026100831106305122,
      -0.01427052728831768,
      -0.07398630678653717,
      0.04144192114472389,
      0.04171917587518692,
      0.10365176200866699,
      -0.004309298470616341,
      0.0731869786977768,
      0.011737647466361523,
      0.051711369305849075,
      0.011367284692823887,
      -0.018773749470710754,
      -0.03468407690525055,
      -0.0033780422527343035,
      -0.03565308451652527,
      0.023311834782361984,
      -0.003907826729118824,
      0.015098607167601585,
      0.05718623846769333,
      -0.043416280299425125,
      0.04451337084174156,
      0.03370796516537666,
      -0.03509664535522461,
      -0.0137450210750103,
      0.0069998400285840034,
      0.044904693961143494,
      -0.00858775433152914,
      0.042608365416526794,
      0.05562188848853111,
      0.01881500706076622,
      -0.011234192177653313,
      0.01204130332916975,
      0.024527210742235184,
      0.008606606163084507,
      0.08013024181127548,
      -0.02785548008978367,
      -0.07043717801570892,
      0.09285391122102737,
      -0.06772227585315704,
      0.009391015395522118,
      0.06720714271068573,
      -0.01202865969389677,
      -0.050729479640722275,
      -0.11970753967761993,
      0.03043128363788128,
      0.06608055531978607,
      -0.007995737716555595,
      -0.005828624125570059,
      0.030612511560320854,
      -0.07217319309711456,
      0.03701802343130112,
      0.02417818456888199,
      0.05841834470629692,
      -0.029153186827898026,
      -0.011842099949717522,
      -0.021994175389409065,
      0.000193683838006109,
      -0.0035595044028013945,
      0.021710483357310295,
      -0.025495722889900208,
      0.012729601003229618,
      0.03308628499507904,
      0.06436577439308167,
      -0.05264677479863167,
      -0.005062397103756666,
      0.07792647182941437,
      0.036504555493593216,
      -0.045288752764463425,
      0.07389198988676071,
      0.06247131526470184,
      0.022917667403817177,
      0.05979548394680023,
      -0.004099278710782528,
      -0.06484617292881012,
      0.04614842310547829,
      -0.006961756385862827,
      -0.031068678945302963,
      -0.07252056151628494,
      0.05807235836982727,
      0.009686019271612167,
      0.08897915482521057,
      -0.016318542882800102,
      -0.013248964212834835,
      -0.011680240742862225,
      0.04818831756711006,
      -0.052344910800457,
      -0.04797396436333656,
      -0.04712233319878578,
      0.05709465220570564,
      -0.05842863395810127,
      0.014429893344640732,
      0.0034180961083620787,
      0.04342477023601532,
      0.02933555841445923,
      -0.07066931575536728,
      0.051813770085573196,
      0.05148667097091675,
      -0.04755673184990883,
      0.04399169608950615,
      0.053340014070272446,
      0.08188250660896301,
      0.040180426090955734,
      0.00022229351452551782,
      0.010842658579349518,
      -0.018393361940979958,
      -0.020315686240792274,
      -0.02134537696838379,
      0.015041359700262547,
      -0.021139271557331085,
      -0.03593093529343605,
      0.0069414484314620495,
      -0.09982414543628693,
      -0.013806045986711979,
      0.06591960042715073,
      0.04094911366701126,
      -0.04025960713624954,
      0.035870105028152466,
      -0.01776503585278988,
      0.00183667684905231,
      0.010799945332109928,
      -0.03665666654706001,
      -0.011205516755580902,
      -0.029872816056013107,
      -0.07812190800905228,
      0.025364046916365623,
      -0.013006160035729408,
      0.005086774006485939,
      0.03757421299815178,
      -0.027747072279453278,
      -0.016347508877515793,
      -0.0005472677876241505,
      -0.11292270570993423,
      0.019679993391036987,
      0.04258640855550766,
      0.007732871454209089,
      -0.004243676085025072,
      -0.03043382428586483,
      0.05895967036485672,
      -0.023186303675174713,
      0.0665169209241867,
      -0.01059796568006277,
      0.047019947320222855,
      0.03009643219411373,
      0.07178066670894623,
      -0.01646673120558262,
      0.014782612212002277,
      0.034635692834854126,
      0.057074934244155884,
      0.030154624953866005,
      0.03873123601078987,
      0.0006486816564574838,
      -0.0013852858683094382,
      0.00394602632150054,
      -0.08874933421611786,
      0.0013253566576167941,
      -0.07491328567266464,
      -0.0020692721009254456,
      0.07548590004444122,
      0.0026754899881780148,
      0.05278417095541954,
      -0.04346654936671257,
      -0.08968956768512726,
      -0.002765711396932602,
      0.0306059829890728,
      0.052064962685108185,
      0.07381074875593185,
      0.062137726694345474,
      -0.030215667560696602,
      -0.0953984260559082,
      -0.07737390697002411,
      -0.05681213364005089,
      0.01129321102052927,
      0.06971405446529388,
      0.02018049731850624,
      -0.0313827209174633,
      -0.022060927003622055,
      0.08560309559106827,
      -0.018869061022996902,
      -0.013648517429828644,
      -0.022240491583943367,
      0.011338436044752598,
      0.042678821831941605,
      -0.018895573914051056,
      -0.057396240532398224,
      0.012048022821545601,
      0.017741210758686066,
      0.007551460061222315,
      0.0037109749391674995,
      0.008181922137737274,
      0.0043196300975978374,
      0.05220431089401245,
      -0.02440601773560047,
      0.04275282844901085,
      -0.03496960550546646,
      0.04545643925666809,
      -0.03646278753876686,
      0.06479421257972717,
      0.055847350507974625,
      -0.02792937308549881,
      0.018202532082796097,
      -0.03748231753706932,
      0.08814365416765213,
      0.057764653116464615,
      -0.06815078854560852,
      -0.08714011311531067,
      -0.031672991812229156,
      0.02577223628759384,
      -0.07521524280309677,
      -0.025090109556913376,
      0.05642145127058029,
      -0.008849774487316608,
      0.05243885889649391,
      0.03684249147772789,
      0.00442062271758914,
      0.059409696608781815,
      0.04441457614302635,
      -0.04864029958844185,
      0.011629439890384674,
      -0.02369687519967556,
      0.00203658826649189,
      -0.026438865810632706,
      -0.11614427715539932,
      0.0409228578209877,
      0.010209881700575352,
      0.01987953670322895,
      -0.024501442909240723,
      -0.08547616004943848,
      0.03277088701725006,
      0.01910865306854248,
      -0.04340241849422455,
      -0.04225723817944527,
      0.12955303490161896,
      -0.03959432616829872,
      0.13380424678325653,
      0.020233625546097755,
      -0.03749927505850792,
      -0.010806244798004627,
      -0.021364349871873856,
      0.014599351212382317,
      -0.034776635468006134,
      -0.028003843501210213,
      0.004104518797248602,
      -0.026491694152355194,
      0.03191801905632019,
      0.12343569099903107,
      0.03577768802642822,
      0.089667908847332,
      -0.028254428878426552,
      -0.006284852046519518,
      0.03950829803943634,
      0.08729974925518036,
      -0.03606027364730835,
      -0.061603471636772156,
      0.04214306175708771,
      0.06075761467218399,
      -0.036410924047231674,
      -0.026756497099995613,
      0.008978776633739471,
      0.0031891653779894114,
      -0.004584989510476589,
      -0.016555340960621834,
      -0.015776611864566803,
      0.05447755381464958,
      0.1671687662601471,
      -0.005081779323518276,
      -0.02499568462371826,
      0.02328612096607685,
      -0.013690732419490814,
      -0.07055729627609253,
      0.04599298536777496,
      0.04946724325418472,
      0.016592396423220634,
      -0.05168114975094795,
      -0.09006041288375854,
      0.08646144717931747,
      0.028870170935988426,
      0.009027702733874321,
      -0.036984216421842575,
      -0.002691662637516856,
      0.02356228046119213,
      -0.025757648050785065,
      0.00015444026212207973,
      -0.09802602976560593,
      -0.02513924054801464,
      0.016085192561149597,
      0.05190011486411095,
      -0.025440964847803116,
      -0.053643614053726196,
      -0.019250230863690376,
      0.018085192888975143,
      0.032700106501579285,
      0.07829064130783081,
      0.0041725714690983295,
      -0.053728390485048294,
      0.01136060617864132,
      -0.0279863141477108,
      0.045529358088970184,
      -0.027764150872826576,
      -0.0012785580474883318,
      -0.042191483080387115,
      -0.05740054324269295,
      0.026210583746433258,
      0.052476655691862106,
      0.008346417918801308,
      0.037143733352422714,
      0.003934912849217653,
      -0.022672025486826897,
      -0.07831374555826187,
      0.022603921592235565,
      0.02709139510989189,
      0.05180281028151512,
      0.08468951284885406,
      -0.0381050668656826,
      -0.004766338504850864,
      0.056687161326408386,
      0.000997569179162383,
      -0.04259497672319412,
      -0.010850138030946255,
      0.03013700060546398,
      -0.05566169321537018,
      -0.06661199033260345,
      0.03176821023225784,
      0.056691061705350876,
      -0.019460290670394897,
      0.0586702860891819,
      0.01128359604626894,
      0.027353812009096146,
      -0.05497553199529648,
      -0.018092535436153412,
      0.04992454871535301,
      -0.02813929319381714,
      0.03895442187786102,
      -0.019309477880597115,
      -0.000398924428736791,
      0.03773918375372887,
      0.06369070708751678,
      0.03562825545668602,
      0.05549252778291702,
      0.0017434728797525167,
      0.04528962820768356,
      -0.102678082883358,
      0.04458819329738617,
      -0.013069236651062965,
      0.03599516674876213,
      0.07444397360086441,
      -0.03861236944794655,
      0.06400755792856216,
      0.0016913727158680558,
      -0.05265146866440773,
      0.05759157985448837,
      -0.015292570926249027,
      -0.046233825385570526,
      -0.05716920271515846,
      -0.029694698750972748,
      0.04128184914588928,
      0.027196750044822693,
      -0.02693929709494114,
      -0.05790794640779495,
      0.0012112305266782641,
      -0.061814263463020325,
      0.046856705099344254,
      -0.08380281925201416,
      0.07222418487071991,
      0.04722007364034653,
      -0.024592969566583633,
      -0.01112586073577404,
      0.018130382522940636,
      0.02552100084722042,
      0.025123106315732002,
      -0.03959343954920769,
      0.02226554974913597,
      -0.05577446520328522,
      -0.051613591611385345,
      0.04343164339661598,
      -0.01511362474411726,
      0.02318316511809826,
      -0.04479658603668213,
      0.0034642701502889395,
      0.0024624865036457777,
      0.05000777915120125,
      0.08098004758358002,
      0.044807612895965576,
      0.04610064998269081,
      -0.013499516062438488,
      -0.010615380480885506,
      -0.007181438151746988,
      0.04264862462878227,
      -0.0027157259173691273,
      -0.019481731578707695,
      0.07177606225013733,
      0.04672786593437195,
      -0.03121034987270832,
      0.04518687352538109,
      -0.0055561852641403675,
      0.05239566043019295,
      -0.006664632819592953,
      0.07437746226787567,
      -0.12238875031471252,
      0.015112186782062054,
      0.07089938223361969,
      -0.057711753994226456,
      -0.023218419402837753,
      -0.06273968517780304,
      -0.03789903596043587,
      0.13746026158332825,
      0.07047794759273529,
      -0.03883612900972366,
      0.008285257034003735,
      0.03396496921777725,
      0.03849642351269722,
      0.04244895651936531,
      0.00015935272676870227,
      0.03370879963040352,
      0.04307737946510315,
      -0.007649810053408146,
      0.010480373166501522,
      -0.0314093753695488,
      0.06239326670765877,
      0.01617288589477539,
      0.003582336474210024,
      -0.1411285698413849,
      0.010066647082567215,
      0.008807011879980564,
      -0.052349526435136795,
      0.01205233670771122,
      -0.07045165449380875,
      -0.022214941680431366,
      -0.0030377472285181284,
      0.00299723818898201,
      0.0054208654910326,
      -0.01575539819896221,
      -0.0158322025090456,
      -0.027298303321003914,
      -0.04168132320046425,
      -0.03926539048552513,
      -0.07979890704154968,
      -0.020602408796548843,
      0.07266855239868164,
      -0.012518800795078278,
      -0.03506673872470856,
      0.010618474334478378,
      -0.015743859112262726,
      0.06740406900644302,
      0.00497801648452878,
      0.011933645233511925,
      -0.020689375698566437,
      0.06230580061674118,
      -0.029753580689430237,
      -0.0016354895196855068,
      0.07820571213960648,
      0.030553480610251427,
      0.08253554999828339,
      -0.07166163623332977,
      -0.0019952694419771433,
      -0.01376340165734291,
      -0.02902214601635933,
      -0.05101342499256134,
      -0.005924571305513382,
      -0.04486968368291855,
      0.01839376986026764,
      -0.022413503378629684,
      0.019014665856957436,
      0.021911663934588432,
      0.07065372169017792,
      0.04229109361767769,
      0.09384190291166306,
      0.07116412371397018,
      0.02207278087735176,
      0.024501707404851913,
      0.004272261168807745,
      0.015189494006335735,
      0.03263568505644798,
      0.009476627223193645,
      -0.038051676005125046,
      0.014133663848042488,
      -0.0036155639681965113,
      0.06419534236192703,
      0.012253094464540482,
      0.049988459795713425,
      -0.03588602691888809,
      0.11018922179937363,
      -0.0405256412923336,
      0.005813299678266048,
      0.01862991414964199,
      -0.0069163208827376366,
      -0.07826194167137146,
      -0.025727029889822006,
      0.09227412194013596
    ],
    [
      0.018098201602697372,
      0.03069387935101986,
      -0.027760842815041542,
      0.08269942551851273,
      -0.05261717736721039,
      -0.043910227715969086,
      0.014487424865365028,
      0.03045760653913021,
      -0.045525409281253815,
      0.06896456331014633,
      0.054038867354393005,
      0.02116987109184265,
      -0.025206509977579117,
      -0.01924877054989338,
      -0.04306484013795853,
      0.006405919324606657,
      0.024612579494714737,
      -0.05703543871641159,
      0.037392519414424896,
      -0.03597116097807884,
      -0.07074923068284988,
      0.05471675843000412,
      -0.0015199508052319288,
      -0.016786541789770126,
      -0.002449579304084182,
      -0.00424178596585989,
      0.0004721850564237684,
      -0.08819610625505447,
      0.033333148807287216,
      0.07479585707187653,
      -0.016707515344023705,
      -0.04569108784198761,
      0.011741666123270988,
      0.036157071590423584,
      -0.057549960911273956,
      0.0646880567073822,
      -0.014567326754331589,
      0.029811851680278778,
      -0.010276108048856258,
      0.023980218917131424,
      0.027709847316145897,
      0.08408643305301666,
      -0.015733754262328148,
      0.026162663474678993,
      -0.006078899838030338,
      0.0528237484395504,
      -0.014046920463442802,
      0.019616523757576942,
      -0.002126163337379694,
      -0.03920963406562805,
      -0.04163520038127899,
      -1.200866336148465e-05,
      -0.02987729385495186,
      0.038588996976614,
      0.02993648871779442,
      0.014064083807170391,
      0.012645025737583637,
      -0.011920822784304619,
      0.02439025603234768,
      0.018861478194594383,
      0.009352150373160839,
      -0.04605609178543091,
      -0.006518804933875799,
      0.01121828705072403,
      -0.026271037757396698,
      -0.044989585876464844,
      -0.004515560809522867,
      0.03621652349829674,
      0.008766557089984417,
      0.005014342721551657,
      0.06649204343557358,
      0.0008421617094427347,
      0.052947916090488434,
      -0.011138690635561943,
      0.040210921317338943,
      0.07111100852489471,
      0.03798868879675865,
      -0.013599798083305359,
      0.010148901492357254,
      0.02584153786301613,
      0.07182913273572922,
      -0.03269475698471069,
      0.0019578500650823116,
      0.03587990254163742,
      0.025545386597514153,
      -0.00436552194878459,
      0.06763294339179993,
      0.0347728431224823,
      0.06016658991575241,
      0.02580222673714161,
      -0.0594065859913826,
      0.03482528403401375,
      -0.009875397197902203,
      0.007470353972166777,
      -0.04704767465591431,
      0.05831010267138481,
      -0.012074175290763378,
      0.010995190590620041,
      0.019854143261909485,
      -0.025914043188095093,
      0.020918190479278564,
      0.0036733513697981834,
      -0.015615656971931458,
      0.0630064606666565,
      -0.037428274750709534,
      0.044124942272901535,
      0.02400774508714676,
      0.021749036386609077,
      0.025679048150777817,
      -0.015623277984559536,
      -0.013108492828905582,
      -0.032037124037742615,
      -0.061133772134780884,
      0.027376459911465645,
      0.03993125259876251,
      0.05238644406199455,
      0.008234323933720589,
      -0.0690343901515007,
      -0.022623591125011444,
      0.06167258694767952,
      0.009429330937564373,
      0.06959054619073868,
      0.038768768310546875,
      -0.024852659553289413,
      0.0771368220448494,
      -0.010611130855977535,
      0.008333612233400345,
      -0.05813814327120781,
      0.03238688409328461,
      0.020161716267466545,
      -0.04460849240422249,
      0.013001810759305954,
      0.039379727095365524,
      -0.0440744124352932,
      0.013448392041027546,
      0.012034343555569649,
      0.03468846529722214,
      -0.051335033029317856,
      -0.005540134385228157,
      0.04291082173585892,
      0.0442536361515522,
      0.0028130344580858946,
      -0.020240286365151405,
      0.008000624366104603,
      0.015722980722784996,
      0.03676944226026535,
      -0.0969923809170723,
      0.07834522426128387,
      -0.08825977891683578,
      -0.06180022284388542,
      -0.06650317460298538,
      -0.0105711305513978,
      -0.0027590987738221884,
      -0.04033149778842926,
      -0.0021147197112441063,
      0.010348870418965816,
      0.07304712384939194,
      0.03579258173704147,
      0.023569416254758835,
      0.04447155445814133,
      -0.055598072707653046,
      0.03739713504910469,
      0.017918815836310387,
      0.07317908108234406,
      0.05301559716463089,
      0.0052656009793281555,
      -0.07229775190353394,
      0.14064271748065948,
      -0.06727064400911331,
      0.009099723771214485,
      0.019634205847978592,
      -0.017127089202404022,
      -0.06738221645355225,
      0.01048110332340002,
      -0.0658450797200203,
      0.01611766777932644,
      0.04709829390048981,
      -0.06661535799503326,
      0.01832253858447075,
      0.005840563680976629,
      -0.04187118262052536,
      -0.0860247015953064,
      0.02352992072701454,
      -0.07295041531324387,
      0.12048164755105972,
      -0.09924250841140747,
      -0.059903331100940704,
      -0.00703361164778471,
      -0.01827809400856495,
      0.03763146325945854,
      0.06262414902448654,
      -0.03168299049139023,
      0.0003521116159390658,
      0.054056718945503235,
      -0.012068944051861763,
      -0.004591874312609434,
      -0.043101973831653595,
      0.028323400765657425,
      0.015788868069648743,
      0.020188769325613976,
      -0.024004993960261345,
      -0.10677922517061234,
      -0.02171771600842476,
      -0.015803303569555283,
      0.017030388116836548,
      -0.08475472778081894,
      -0.05472087860107422,
      -0.010652750730514526,
      -0.020576437935233116,
      -0.005726042669266462,
      0.02207198180258274,
      -0.01844681426882744,
      -0.006381692830473185,
      -0.049489784985780716,
      0.03227183595299721,
      0.043475620448589325,
      0.0539136566221714,
      -0.017255453392863274,
      0.08075880259275436,
      0.022208012640476227,
      0.11414659023284912,
      0.07885196805000305,
      0.02031320333480835,
      -0.023309750482439995,
      0.006456585135310888,
      0.002755278954282403,
      0.017221486195921898,
      0.05305779352784157,
      0.0816822201013565,
      -0.081290602684021,
      0.0010523163946345448,
      0.014144904911518097,
      0.0030034570954740047,
      0.021767256781458855,
      -0.06797951459884644,
      0.0616835355758667,
      0.006374572403728962,
      -0.08784519135951996,
      0.03332674875855446,
      -0.04242218658328056,
      -0.0030944657046347857,
      -0.01404481939971447,
      0.04505506157875061,
      0.04291796311736107,
      -0.06133118271827698,
      0.035711150616407394,
      0.029168052598834038,
      -0.02071109041571617,
      -0.026594143360853195,
      -0.03797834366559982,
      0.03482096642255783,
      -0.08110077679157257,
      0.029576295986771584,
      -0.011672530323266983,
      0.03546648472547531,
      0.0480317547917366,
      -0.021032243967056274,
      0.0028736419044435024,
      0.012737398967146873,
      0.007780869957059622,
      0.01180671714246273,
      0.016319170594215393,
      0.06199406087398529,
      -0.0020274135749787092,
      -0.018277041614055634,
      0.04127632454037666,
      0.03209903836250305,
      0.05332012474536896,
      0.02789834514260292,
      0.03485729172825813,
      0.09284285455942154,
      -0.024431701749563217,
      -0.06708140671253204,
      0.009674194268882275,
      0.03125526010990143,
      0.054490529000759125,
      -0.06592100113630295,
      -0.0783180296421051,
      -0.0386187843978405,
      0.0004830950638279319,
      -0.0306102242320776,
      0.027711190283298492,
      -0.07633316516876221,
      -0.04626530781388283,
      0.05202329158782959,
      -0.02444799430668354,
      0.028039900586009026,
      0.04223066568374634,
      -0.02669372782111168,
      0.07954400032758713,
      0.07334056496620178,
      0.0046915230341255665,
      0.002438338240608573,
      0.04637531191110611,
      0.057179514318704605,
      -0.004016505554318428,
      0.003302020486444235,
      0.0035986274015158415,
      -0.0023903001565486193,
      -0.1148047000169754,
      0.04095762223005295,
      -0.009469466283917427,
      0.024080445989966393,
      -0.028926907107234,
      0.055605895817279816,
      0.08978308737277985,
      -0.023566674441099167,
      0.01194342877715826,
      0.021264364942908287,
      -0.04846884682774544,
      0.03218384459614754,
      0.01686236634850502,
      -0.054332755506038666,
      -0.060587383806705475,
      0.03343972936272621,
      0.004428756423294544,
      -0.02198982797563076,
      0.013304848223924637,
      -0.031646981835365295,
      0.0015381471021100879,
      0.1382848173379898,
      -0.04761002957820892,
      -0.012835907749831676,
      -0.016274791210889816,
      -0.027822555974125862,
      -0.014385118149220943,
      -0.0097656873986125,
      0.05659941956400871,
      -0.014465268701314926,
      -0.04174881428480148,
      0.024239806458353996,
      -0.058473050594329834,
      0.03241507336497307,
      0.010465981438755989,
      -0.04647408053278923,
      0.02924758568406105,
      0.0280451737344265,
      -0.01565919630229473,
      -0.010597815737128258,
      0.03712727129459381,
      -0.009202199056744576,
      0.009141129441559315,
      -0.005678801331669092,
      0.04189552739262581,
      -0.021859776228666306,
      0.01989532634615898,
      -0.0058580245822668076,
      0.08140407502651215,
      0.0923914983868599,
      0.0037153507582843304,
      0.05521102249622345,
      0.0006016403203830123,
      0.013500861823558807,
      0.026115624234080315,
      -0.0604720376431942,
      0.039801858365535736,
      -0.12093380838632584,
      0.019501077011227608,
      -0.06977657228708267,
      -0.017002245411276817,
      0.08465348929166794,
      -0.027270959690213203,
      0.053501520305871964,
      0.014807178638875484,
      -0.002324949484318495,
      -0.03133837878704071,
      0.0786156877875328,
      -0.012615294195711613,
      0.005263900384306908,
      -0.04932072386145592,
      0.03208228945732117,
      -0.047874826937913895,
      -0.0004496159090194851,
      0.04768277704715729,
      0.08836547285318375,
      0.06939300149679184,
      0.03942928835749626,
      0.04141537845134735,
      -0.01896175555884838,
      -0.005444676149636507,
      -0.022126996889710426,
      0.04226382449269295,
      0.007104155607521534,
      0.048453591763973236,
      0.08696591109037399,
      -0.01066275592893362,
      -0.02104760892689228,
      -0.027870895341038704,
      0.04918030649423599,
      0.06887165457010269,
      0.0639163926243782,
      -0.013247491791844368,
      -0.021778829395771027,
      -0.009254681877791882,
      -0.041295163333415985,
      -0.08953100442886353,
      0.07676080614328384,
      0.1417052149772644,
      -0.013476621359586716,
      -0.03381052613258362,
      -0.06723011285066605,
      0.05077330768108368,
      0.027824638411402702,
      -0.03103277087211609,
      -0.059982359409332275,
      0.060116641223430634,
      0.002809908241033554,
      0.04736557975411415,
      0.012652719393372536,
      -0.08063489943742752,
      0.032467275857925415,
      0.031915731728076935,
      -0.02688785269856453,
      0.03165854513645172,
      0.020593049004673958,
      -0.03149120509624481,
      0.03453340381383896,
      0.0064834547229111195,
      0.10590600222349167,
      0.09833604097366333,
      0.008171177469193935,
      -0.010815742425620556,
      0.0037132049910724163,
      -0.020757120102643967,
      -0.01205416303128004,
      0.001816044095903635,
      0.02448785863816738,
      -0.021539226174354553,
      0.05087690427899361,
      -0.0498865507543087,
      0.06129250302910805,
      -0.017737142741680145,
      -0.02770635299384594,
      -0.04659822955727577,
      0.02918296679854393,
      -0.03864685073494911,
      -0.014933548867702484,
      -0.042188648134469986,
      -0.07992564886808395,
      -0.06417611241340637,
      0.04559575393795967,
      0.0580742210149765,
      -0.005001373589038849,
      0.029447123408317566,
      -0.013278638012707233,
      0.07791297137737274,
      -0.0030996075365692377,
      -0.10377535223960876,
      -0.039269283413887024,
      -0.05238620191812515,
      0.037037309259176254,
      0.020058277994394302,
      -0.0303554218262434,
      -0.025768356397747993,
      0.03592436760663986,
      -0.06485255062580109,
      0.0020472609903663397,
      -0.1331820785999298,
      0.07882655411958694,
      0.00201346050016582,
      0.0014426913112401962,
      0.04618418589234352,
      0.08969040215015411,
      -0.035628870129585266,
      0.015135581605136395,
      0.04373016580939293,
      -0.044383201748132706,
      -0.0524202361702919,
      0.01996832713484764,
      -0.007604632992297411,
      0.039023883640766144,
      0.012708739377558231,
      0.017218608409166336,
      0.039293643087148666,
      0.0013521162327378988,
      -0.02383146621286869,
      0.06838501989841461,
      -0.036671534180641174,
      -0.019162997603416443,
      -0.05805277079343796,
      0.017315680161118507,
      -0.09006460011005402,
      -0.054279062896966934,
      0.03049183450639248,
      -0.051443811506032944,
      0.07966185361146927,
      0.03241008520126343,
      -0.014596452005207539,
      0.0372573584318161,
      0.01097448542714119,
      -0.013068992644548416,
      0.035092975944280624,
      0.07805567979812622,
      0.005563876125961542,
      0.016571342945098877,
      0.08789043128490448,
      0.03651270270347595,
      -0.0840277299284935,
      -0.04109424725174904,
      0.03155772015452385,
      -0.008554281666874886,
      -0.007160789333283901,
      0.04183032363653183
    ],
    [
      -0.05634631961584091,
      -0.07576104253530502,
      0.019456317648291588,
      0.033404842019081116,
      -0.041281457990407944,
      -0.013709456659853458,
      0.05266598239541054,
      -0.0011903016129508615,
      0.06026308611035347,
      -0.061064258217811584,
      -0.030144719406962395,
      0.11532633751630783,
      -0.07824240624904633,
      -0.015292364172637463,
      -0.07711481302976608,
      -0.04202190786600113,
      -0.005064853932708502,
      -0.028806863352656364,
      -0.06133653223514557,
      -0.002379209501668811,
      -0.0176717359572649,
      -0.040716756135225296,
      -0.03185536339879036,
      -0.07696396112442017,
      0.0076042958535254,
      0.07467707991600037,
      0.03534747660160065,
      -0.04714750871062279,
      -0.06657272577285767,
      -0.027542874217033386,
      -0.043538328260183334,
      -0.020807752385735512,
      -0.025579316541552544,
      0.0010285850148648024,
      -0.01694934442639351,
      0.09913208335638046,
      0.031953733414411545,
      0.0684555172920227,
      -0.004059324506670237,
      -0.0009050350054167211,
      -0.015600997023284435,
      0.04015747457742691,
      0.040673382580280304,
      -0.05324390530586243,
      -0.04566861689090729,
      0.014867083169519901,
      -0.0219323318451643,
      -0.01909017562866211,
      0.014399717561900616,
      0.019906505942344666,
      -0.03943116217851639,
      -0.06865473836660385,
      0.009712519124150276,
      0.022017214447259903,
      0.05445188283920288,
      -0.07632657140493393,
      0.004595469683408737,
      0.023186039179563522,
      0.039739854633808136,
      -0.07526490092277527,
      0.07838866114616394,
      0.04241495952010155,
      -0.016059469431638718,
      -0.05567680671811104,
      0.04696907103061676,
      0.03397351875901222,
      0.029166150838136673,
      0.03236451745033264,
      -0.01947072707116604,
      -0.06593318283557892,
      0.01939598098397255,
      0.04946189746260643,
      0.0012988894013687968,
      0.013264811597764492,
      0.03400983661413193,
      -0.03597571328282356,
      -0.019615251570940018,
      -0.06063306704163551,
      0.06388292461633682,
      0.04238750785589218,
      -0.0074816481210291386,
      -0.01174731831997633,
      -0.00940004549920559,
      -0.01585591956973076,
      0.04207102954387665,
      0.09193890541791916,
      0.01093085017055273,
      -0.03079058788716793,
      0.040151599794626236,
      -0.01053579617291689,
      0.013013668358325958,
      -0.00018053129315376282,
      -0.010581829585134983,
      -0.04191393032670021,
      -0.08682665228843689,
      -0.005981928203254938,
      0.041887443512678146,
      0.014842990785837173,
      -0.0007383195916190743,
      -0.01898054964840412,
      0.01017324160784483,
      -0.09534267336130142,
      0.04704452306032181,
      -0.04082556068897247,
      -0.0038216703105717897,
      0.002623029286041856,
      -0.03939472511410713,
      -0.0292571522295475,
      0.06075919046998024,
      -0.02687290869653225,
      -0.016127247363328934,
      0.010111838579177856,
      0.0027798593509942293,
      0.10492715984582901,
      -0.006050214171409607,
      0.09498611837625504,
      0.009424806572496891,
      -0.1012960746884346,
      -0.09382342547178268,
      -0.014370736666023731,
      -0.03461821749806404,
      -0.022167377173900604,
      0.03363102674484253,
      0.03917953744530678,
      0.026363922283053398,
      -0.03912753984332085,
      0.014902075752615929,
      -0.04024752974510193,
      0.0048460750840604305,
      -0.019210753962397575,
      0.023411976173520088,
      0.04153017699718475,
      -0.030321432277560234,
      -0.01088617742061615,
      -0.013930617831647396,
      0.028821632266044617,
      0.03262261673808098,
      0.021555539220571518,
      0.04279995337128639,
      -0.019093286246061325,
      -0.0033934013918042183,
      0.05774741992354393,
      -0.003199151251465082,
      0.03143945708870888,
      0.10274039208889008,
      0.012290796265006065,
      0.04594641551375389,
      -0.009466933086514473,
      0.0731806606054306,
      -0.06116753816604614,
      -0.01414012722671032,
      -0.13356605172157288,
      0.025910641998052597,
      0.04542078077793121,
      -0.013401573523879051,
      -0.11240649968385696,
      0.014836453832685947,
      -0.022414973005652428,
      -0.093167744576931,
      -0.0013060157652944326,
      0.0497736893594265,
      -0.05227828025817871,
      -0.01469134259968996,
      0.05297284200787544,
      0.028782768175005913,
      0.037256624549627304,
      0.01068216934800148,
      0.01638433337211609,
      0.005199663806706667,
      -0.033677246421575546,
      0.023107172921299934,
      -0.07832798361778259,
      0.0322355255484581,
      0.06823664158582687,
      -0.04469054937362671,
      0.03717649355530739,
      0.037715546786785126,
      -0.059415511786937714,
      -0.09489362686872482,
      0.0037945876829326153,
      0.05583741515874863,
      -0.06729643791913986,
      -0.025581682100892067,
      -0.03816235437989235,
      -0.08620661497116089,
      0.02377094328403473,
      0.026202278211712837,
      -0.03417464345693588,
      -0.06413102149963379,
      -0.01478109136223793,
      -0.024415992200374603,
      -0.026432018727064133,
      0.008843920193612576,
      -0.018841059878468513,
      0.023450488224625587,
      -0.026804806664586067,
      -0.02344544604420662,
      -0.007058913353830576,
      0.09348145872354507,
      -0.012078607454895973,
      0.016246333718299866,
      0.12129827588796616,
      -0.06657178699970245,
      0.020273204892873764,
      0.02425006777048111,
      0.009813069365918636,
      0.015885502099990845,
      -0.010785147547721863,
      -0.011012259870767593,
      0.005155126564204693,
      -0.11314801126718521,
      -0.08594618737697601,
      0.022997600957751274,
      -0.0337710976600647,
      0.013092707842588425,
      -0.025073207914829254,
      -0.024158207699656487,
      -0.006791126914322376,
      -0.02321670390665531,
      0.03368193656206131,
      0.019544538110494614,
      -0.006273695733398199,
      0.03677792474627495,
      -0.03819044306874275,
      0.04692309722304344,
      0.006765913218259811,
      0.07619830220937729,
      -0.023684080690145493,
      -0.005149228498339653,
      -0.05564845725893974,
      0.000363401893991977,
      0.01097299624234438,
      -0.005449383053928614,
      0.021931366994976997,
      -0.012877587229013443,
      0.06044968590140343,
      -0.02575349621474743,
      -0.031402431428432465,
      0.02326854132115841,
      0.023305172100663185,
      -0.0382860004901886,
      -0.049859419465065,
      0.00673937750980258,
      -0.09040763974189758,
      -0.006957109086215496,
      0.017447590827941895,
      -0.09863957017660141,
      0.01751198247075081,
      -0.025486595928668976,
      -0.053031738847494125,
      0.08589702844619751,
      -0.0294346921145916,
      -0.028444426134228706,
      0.03759477287530899,
      0.0763968825340271,
      0.03219489008188248,
      -0.04785287007689476,
      -0.03385629504919052,
      -0.09307168424129486,
      0.028661422431468964,
      -0.03645661473274231,
      -0.07620739936828613,
      -0.05517059192061424,
      -0.03671327233314514,
      -0.02625235542654991,
      0.08191083371639252,
      -0.03950663283467293,
      0.03764402121305466,
      -0.020596260204911232,
      -0.03546658158302307,
      -0.0043163239024579525,
      0.0359969437122345,
      0.017390428110957146,
      0.01753760501742363,
      -0.028556915000081062,
      -0.06646814942359924,
      -0.04579789191484451,
      0.04261570796370506,
      -0.0023067323490977287,
      0.014068775810301304,
      -0.07209616899490356,
      -0.03820697218179703,
      -0.022272588685154915,
      -0.0270998515188694,
      0.008899608626961708,
      -0.026392806321382523,
      -0.023594189435243607,
      0.010496596805751324,
      0.03630651906132698,
      -0.03411614149808884,
      0.045292049646377563,
      0.049006540328264236,
      0.016156017780303955,
      -0.04793119430541992,
      0.02053172141313553,
      -0.05817107483744621,
      -0.060100898146629333,
      0.08997402340173721,
      0.00671234168112278,
      -0.01756640523672104,
      -0.016537625342607498,
      -0.044326189905405045,
      0.023223362863063812,
      0.024423280730843544,
      0.016333842650055885,
      0.0005959726986475289,
      0.06590567529201508,
      -0.017954206094145775,
      -0.022471841424703598,
      -0.05327896028757095,
      0.03496649116277695,
      0.049566030502319336,
      0.024382486939430237,
      0.027169372886419296,
      -0.03348516300320625,
      -0.0429682694375515,
      0.004424484446644783,
      0.048534926027059555,
      0.04967380315065384,
      -0.01366433221846819,
      0.06578030437231064,
      0.03878410533070564,
      -0.024284454062581062,
      -0.03958757221698761,
      0.027191543951630592,
      -0.016964128240942955,
      -0.022909488528966904,
      0.0004916984471492469,
      0.028272243216633797,
      0.010282639414072037,
      -0.09119285643100739,
      -0.02749800495803356,
      -0.011463603936135769,
      -0.0003760230611078441,
      -0.024958569556474686,
      -0.006936696358025074,
      0.048395201563835144,
      -0.08181202411651611,
      -0.03152360022068024,
      -0.0032120838295668364,
      -0.0011797539191320539,
      0.014179768972098827,
      0.002799583598971367,
      0.045984797179698944,
      0.00412774458527565,
      0.017490029335021973,
      -0.05331641435623169,
      0.007093803025782108,
      0.04112415760755539,
      0.036585934460163116,
      0.03700469061732292,
      0.07270779460668564,
      -0.00258736708201468,
      0.04782657325267792,
      -0.06314685940742493,
      -0.01143471896648407,
      0.00531735410913825,
      0.005269485525786877,
      0.08983208984136581,
      0.03280908986926079,
      -0.03074825368821621,
      0.008832800202071667,
      0.0035643756855279207,
      0.010243041440844536,
      -0.07536905258893967,
      -0.014883624389767647,
      -0.05809907987713814,
      0.00348999653942883,
      0.0071953702718019485,
      -0.16016100347042084,
      -0.022751403972506523,
      -0.010106679983437061,
      -0.04351329803466797,
      -0.04550344496965408,
      0.08885032683610916,
      0.010673613287508488,
      0.08705677837133408,
      -0.019392654299736023,
      -0.05585756152868271,
      0.027204539626836777,
      0.006034394726157188,
      0.010996251367032528,
      0.06538283824920654,
      -0.02243204414844513,
      0.013776041567325592,
      -0.0011344982776790857,
      0.06249077618122101,
      -0.05174693465232849,
      0.10697883367538452,
      0.0380617119371891,
      0.03701622784137726,
      -0.030991051346063614,
      -0.0028599328361451626,
      -0.02196032740175724,
      -0.005690854042768478,
      -0.023366890847682953,
      0.009440920315682888,
      0.01030533853918314,
      -0.07932665199041367,
      0.06508404016494751,
      -0.004943056032061577,
      0.005720100365579128,
      0.059865280985832214,
      0.05246693268418312,
      0.05857567861676216,
      0.0259123295545578,
      -0.05134275183081627,
      0.0061017717234790325,
      -0.09873611479997635,
      0.1184053122997284,
      0.02623729780316353,
      0.0364675298333168,
      0.06808007508516312,
      0.0005465379799716175,
      0.037781860679388046,
      -0.025107553228735924,
      0.015006222762167454,
      0.00055169896222651,
      -0.05990483611822128,
      -0.027218807488679886,
      0.04432231932878494,
      -0.053692951798439026,
      -0.0697961375117302,
      -0.010149499401450157,
      -0.017029501497745514,
      -0.0536159910261631,
      0.0068056643940508366,
      -0.0764457955956459,
      -0.09636030346155167,
      -0.0027073777746409178,
      0.07911580055952072,
      0.056477490812540054,
      -0.019552461802959442,
      0.0036003515124320984,
      0.05539505556225777,
      -0.014939462766051292,
      -0.0645943284034729,
      -0.018245114013552666,
      -0.03143923729658127,
      0.03184017911553383,
      0.004227673169225454,
      0.0526091642677784,
      0.09221306443214417,
      0.06399189680814743,
      -0.044719260185956955,
      -0.033472925424575806,
      -0.03807050362229347,
      0.03139264136552811,
      -0.07400626689195633,
      -0.0010788936633616686,
      0.018735991790890694,
      0.0484282448887825,
      -0.036790963262319565,
      0.01836625672876835,
      -0.05054708942770958,
      -0.059189461171627045,
      -0.04507402330636978,
      -0.0793570876121521,
      0.02822858840227127,
      0.0761687234044075,
      -0.02098112739622593,
      -0.0014679614687338471,
      0.023114997893571854,
      -0.05911824107170105,
      0.10324744135141373,
      0.019284872338175774,
      0.03642839193344116,
      0.0643036887049675,
      0.0528876967728138,
      0.03291267529129982,
      -0.02587451972067356,
      0.019997987896203995,
      -0.022955289110541344,
      -0.02823062054812908,
      -0.06620904058218002,
      0.05683040991425514,
      -0.07244373112916946,
      -0.03508307412266731,
      -0.010569472797214985,
      0.037304554134607315,
      -0.0661122053861618,
      0.039030399173498154,
      0.022750547155737877,
      -0.0060294922441244125,
      -0.026761416345834732,
      -0.04873765632510185,
      -0.07212397456169128,
      0.04151592031121254,
      -0.03844062238931656,
      -0.07562746852636337,
      0.04802519083023071,
      0.024265078827738762,
      -0.03702755272388458,
      -0.014887232333421707,
      -0.0022053918801248074,
      0.12559284269809723,
      0.0018655203748494387,
      -0.060522984713315964,
      0.08053020387887955,
      -0.00933291669934988,
      0.051050666719675064,
      0.014492793940007687,
      -0.029868200421333313
    ],
    [
      -0.02343405783176422,
      0.057777103036642075,
      0.06701512634754181,
      0.09023615717887878,
      -0.032822370529174805,
      0.02136559598147869,
      0.051157496869564056,
      -0.03933817148208618,
      -0.07028474658727646,
      -0.027982262894511223,
      -0.06503751873970032,
      0.006843883544206619,
      -0.05872239172458649,
      -0.043954718858003616,
      -0.031043417751789093,
      -0.008177896030247211,
      -0.026499804109334946,
      0.033988840878009796,
      -0.015849826857447624,
      -0.010406695306301117,
      -0.10054376721382141,
      -0.005860552191734314,
      0.03208393603563309,
      0.013548284769058228,
      0.03421808034181595,
      -0.02544880099594593,
      -0.025464214384555817,
      -0.0452730692923069,
      -0.11379619687795639,
      0.06328313052654266,
      -0.018879247829318047,
      -0.08492826670408249,
      -0.017473939806222916,
      -0.04442202299833298,
      0.03887835517525673,
      -0.05204068869352341,
      -0.014610806480050087,
      0.06401468068361282,
      0.025494370609521866,
      0.006562791299074888,
      0.020302945747971535,
      0.09327705949544907,
      -0.04206281155347824,
      0.04496036469936371,
      0.059312161058187485,
      0.059744834899902344,
      -0.08841478824615479,
      0.03480830788612366,
      -0.05088251084089279,
      0.041738178580999374,
      0.012396115809679031,
      -0.009587282314896584,
      -0.02249922789633274,
      -0.01681515760719776,
      -0.0011094557121396065,
      0.019560879096388817,
      -0.06755626201629639,
      0.030655978247523308,
      -0.05408564209938049,
      0.02973746880888939,
      0.017976146191358566,
      -0.037568964064121246,
      0.0035480305086821318,
      0.0339290127158165,
      -0.0546225942671299,
      0.038914285600185394,
      0.02756653167307377,
      0.01075868122279644,
      0.06033111736178398,
      -0.03315411135554314,
      0.03598131984472275,
      0.050765763968229294,
      -0.08787532895803452,
      -0.013682792894542217,
      0.015680693089962006,
      -0.015567604452371597,
      0.016933996230363846,
      0.02175738289952278,
      0.020128225907683372,
      -0.03581532835960388,
      -0.021731318905949593,
      -0.017778906971216202,
      0.0018803856801241636,
      -0.006136722397059202,
      -0.05065811797976494,
      -0.02215350978076458,
      -0.004829961806535721,
      0.0027317481581121683,
      0.08422396332025528,
      0.03698819503188133,
      0.04798499867320061,
      -0.05642586946487427,
      -0.002783539006486535,
      -0.01700521819293499,
      0.04091320559382439,
      0.04305516555905342,
      0.028200749307870865,
      0.05906745046377182,
      0.03670024499297142,
      -0.010593113489449024,
      -0.0746910497546196,
      -0.036821529269218445,
      -0.0027144732885062695,
      0.043601371347904205,
      -0.0726325735449791,
      -0.015260035172104836,
      0.014304034411907196,
      0.0452139675617218,
      -0.014605013653635979,
      -0.0677516832947731,
      -0.02785658836364746,
      -0.049862369894981384,
      0.03985847905278206,
      -0.08069558441638947,
      0.0043311575427651405,
      0.0014626471092924476,
      0.01896752044558525,
      -0.09157560020685196,
      -0.017098860815167427,
      -0.014273201115429401,
      -0.0030962193850427866,
      0.09687704592943192,
      0.038795001804828644,
      -0.07855099439620972,
      0.02359716221690178,
      0.036069732159376144,
      -0.003114379243925214,
      -0.005485823843628168,
      0.02217254973948002,
      -0.06401190161705017,
      0.007528985384851694,
      0.07904879003763199,
      0.001848574262112379,
      0.03903636336326599,
      -0.09219285100698471,
      0.023297946900129318,
      0.016938894987106323,
      -0.021320002153515816,
      0.004927339963614941,
      0.10474973171949387,
      -0.1022593304514885,
      0.10106047987937927,
      0.010787388309836388,
      -0.0571991465985775,
      0.019965222105383873,
      0.052301760762929916,
      0.008332936093211174,
      -0.007435890380293131,
      0.014164526015520096,
      0.039484623819589615,
      -0.03145688772201538,
      -0.01697782799601555,
      0.025215091183781624,
      0.05768048018217087,
      0.018157701939344406,
      0.02747335471212864,
      0.061722282320261,
      0.09060818701982498,
      0.05372915789484978,
      -0.02894098497927189,
      0.08386930823326111,
      -0.017656313255429268,
      -0.07406952232122421,
      0.03196641430258751,
      -0.026183996349573135,
      0.056183211505413055,
      0.012360340915620327,
      -0.037555914372205734,
      0.04316399246454239,
      -0.09096243232488632,
      0.009209598414599895,
      -0.005202590953558683,
      0.032684650272130966,
      -0.05282096937298775,
      0.0026489810552448034,
      -0.019199276342988014,
      0.010332747362554073,
      -0.00031104616937227547,
      0.07617038488388062,
      0.009352076798677444,
      -0.05818313732743263,
      -0.06356998533010483,
      -0.028325913473963737,
      -0.0006992428097873926,
      -0.0551019050180912,
      -0.02659848891198635,
      -0.01257903128862381,
      -0.04169386252760887,
      -0.09833461046218872,
      -0.013768922537565231,
      0.07411304116249084,
      -0.06415677070617676,
      0.07265638560056686,
      0.022767672315239906,
      -0.02142348326742649,
      0.03315623477101326,
      0.01400074828416109,
      0.07261154055595398,
      -0.0451446957886219,
      0.05812644958496094,
      0.0815872997045517,
      0.05675831809639931,
      -0.09067898243665695,
      -0.10133683681488037,
      0.05787728726863861,
      0.0850866511464119,
      -0.07376173883676529,
      -0.0005501386476680636,
      0.019112329930067062,
      0.021531209349632263,
      -0.02718585729598999,
      -0.009882290847599506,
      0.0014069837052375078,
      -0.04606233909726143,
      0.03331684693694115,
      -0.03715861961245537,
      0.0670262798666954,
      -0.07422279566526413,
      -0.047837261110544205,
      -0.0015716950874775648,
      0.05669764056801796,
      0.10555312037467957,
      0.046320702880620956,
      0.005499465856701136,
      0.09971269965171814,
      -0.01965090073645115,
      -0.033692240715026855,
      -0.02879723347723484,
      0.02559029683470726,
      0.0008074882789514959,
      -0.005288973450660706,
      0.009745287708938122,
      -0.044011715799570084,
      -0.08250787109136581,
      0.033914268016815186,
      0.04231593757867813,
      -0.02093573473393917,
      -0.05498657375574112,
      -0.05244893953204155,
      0.0180733110755682,
      -0.02285940945148468,
      -0.031268876045942307,
      -0.04201041907072067,
      -0.05196571350097656,
      0.08049925416707993,
      -0.05280747637152672,
      -0.03901476413011551,
      0.0770578607916832,
      0.06339841336011887,
      0.01508596446365118,
      -0.022032346576452255,
      -0.062074776738882065,
      -0.043870508670806885,
      0.11034435778856277,
      -0.013116290792822838,
      0.05956636369228363,
      -0.012553420849144459,
      0.05012693256139755,
      -0.0046062832698225975,
      -0.01567569002509117,
      0.04734513536095619,
      0.04357771947979927,
      -0.09979475289583206,
      0.040434058755636215,
      0.03660586103796959,
      0.022040564566850662,
      0.006364244967699051,
      0.08793649822473526,
      0.05639190226793289,
      -0.05474189296364784,
      -0.016777217388153076,
      -0.03471952676773071,
      -0.011297385208308697,
      -0.014632447622716427,
      0.05874628201127052,
      0.030737673863768578,
      -0.026702728122472763,
      -0.0478891097009182,
      -0.0033983299508690834,
      -0.004333279561251402,
      0.010307569056749344,
      0.04726937785744667,
      -0.016431350260972977,
      0.036773670464754105,
      -0.08055713772773743,
      -0.027948204427957535,
      -0.04214560613036156,
      0.06397532671689987,
      -0.04280900955200195,
      -0.024175697937607765,
      0.0989256352186203,
      0.050555188208818436,
      -0.020378926768898964,
      0.022997070103883743,
      -0.01649298705160618,
      0.002091331873089075,
      0.01588011533021927,
      0.027753962203860283,
      0.00010211352491751313,
      -0.11774950474500656,
      -0.023219026625156403,
      -0.01338769681751728,
      -0.020637737587094307,
      0.012095198966562748,
      0.0483161099255085,
      -0.028240500018000603,
      -0.01365220919251442,
      -0.03205510601401329,
      0.02094225026667118,
      0.022190338000655174,
      -0.00954853929579258,
      0.032442592084407806,
      0.009133519604802132,
      0.08196140080690384,
      0.0339154489338398,
      -0.0997491255402565,
      -0.014514856971800327,
      0.048649366945028305,
      -0.0166921503841877,
      0.0236447025090456,
      0.011986537836492062,
      -0.0065910969860851765,
      0.003045800607651472,
      -0.022905955091118813,
      0.03261556848883629,
      0.04016823694109917,
      -0.011938796378672123,
      0.04638640210032463,
      -0.06976846605539322,
      -0.027770699933171272,
      -0.023455671966075897,
      -7.968952559167519e-05,
      0.014449936337769032,
      -0.006243761628866196,
      -0.01210514735430479,
      -0.08434008806943893,
      0.006395265925675631,
      0.0024284187238663435,
      -0.02780894748866558,
      -0.002213916042819619,
      0.052739523351192474,
      -0.007116697262972593,
      0.05649353191256523,
      -0.0544712170958519,
      0.024322422221302986,
      -0.08140366524457932,
      -0.009038536809384823,
      0.0013622586848214269,
      -0.012414315715432167,
      -0.014568452723324299,
      -0.029294081032276154,
      0.02239191345870495,
      -0.0023668059147894382,
      0.024928539991378784,
      -0.027404703199863434,
      -0.036049991846084595,
      -0.035764701664447784,
      0.040293559432029724,
      -0.02005399391055107,
      -0.08757076412439346,
      0.044041454792022705,
      0.002421050099655986,
      -0.08521716296672821,
      -0.0552620105445385,
      -0.011061128228902817,
      -0.06203025206923485,
      0.07061796635389328,
      -0.0017455487977713346,
      -0.003951298538595438,
      0.003421622095629573,
      -0.026874005794525146,
      0.031468674540519714,
      -0.09984499216079712,
      -0.04142938181757927,
      -0.0029777181334793568,
      0.058134905993938446,
      -0.10457669198513031,
      0.05362701416015625,
      0.022416330873966217,
      0.036449119448661804,
      -0.02671417035162449,
      -0.04552366957068443,
      0.046926286071538925,
      0.06889207661151886,
      0.04607178270816803,
      0.0711044892668724,
      -0.06709939241409302,
      0.007565178908407688,
      -0.044310055673122406,
      0.04748579114675522,
      0.0014013018226251006,
      -0.022765226662158966,
      0.0008858962683007121,
      -0.0887935683131218,
      0.00860078725963831,
      0.008005032315850258,
      0.05224078893661499,
      0.01481608860194683,
      0.04134508594870567,
      0.010987254790961742,
      0.02586597390472889,
      0.045573052018880844,
      -0.012663735076785088,
      0.04105494171380997,
      0.07203610986471176,
      -0.011011792346835136,
      0.004178754985332489,
      -0.006900046020746231,
      0.03143420070409775,
      0.032437629997730255,
      0.0029295270796865225,
      0.010376338846981525,
      0.008693680167198181,
      -0.014034080319106579,
      -0.06558560580015182,
      -0.00019632991461548954,
      -0.014016919769346714,
      0.00981154479086399,
      -0.0920344889163971,
      0.037038128823041916,
      -0.0031584559474140406,
      0.04822195693850517,
      -0.01782410219311714,
      -0.008626796305179596,
      0.01253341231495142,
      -0.09636326879262924,
      -0.015951938927173615,
      -0.021697668358683586,
      0.029053635895252228,
      0.03645004704594612,
      -0.037982095032930374,
      0.006041080690920353,
      0.04764992371201515,
      0.0026235110126435757,
      -0.06531927734613419,
      -0.007457064930349588,
      -0.008458089083433151,
      -0.007056967820972204,
      -0.05092712119221687,
      -0.004109121859073639,
      -0.03260086104273796,
      -0.008857762441039085,
      0.004275754559785128,
      0.12191872298717499,
      0.03686991706490517,
      -0.021498190239071846,
      -0.0024563265033066273,
      -0.00937068834900856,
      0.02354450337588787,
      -0.01922513358294964,
      -0.02547404356300831,
      0.019516870379447937,
      -0.006034406833350658,
      0.06093228980898857,
      0.005574183538556099,
      0.050582192838191986,
      0.007298169657588005,
      0.052510302513837814,
      0.030409149825572968,
      0.011195962317287922,
      0.023859556764364243,
      0.03116702288389206,
      0.038181621581315994,
      -0.0008767994586378336,
      0.04594551771879196,
      0.05774886533617973,
      0.0026306717190891504,
      0.01989494077861309,
      -0.04342597723007202,
      -0.1152905747294426,
      0.022084040567278862,
      0.05584579333662987,
      0.030655141919851303,
      0.07766807824373245,
      0.005616039037704468,
      -0.005957327783107758,
      0.01582205295562744,
      -0.023692408576607704,
      -0.0015048827044665813,
      -0.05934814363718033,
      -0.07695360481739044,
      -0.01923304982483387,
      0.021056048572063446,
      0.0031674266792833805,
      -0.027335530146956444,
      0.006559764966368675,
      -0.07949300855398178,
      0.03646690025925636,
      -0.05865973234176636,
      0.006420622579753399,
      -0.05576489865779877,
      -2.9844002710888162e-05,
      0.08734209835529327,
      -0.028687579557299614,
      0.023559588938951492,
      -0.10507538169622421,
      0.034197282046079636,
      0.0074901580810546875,
      -0.011231448501348495,
      0.023043174296617508,
      0.04805569723248482,
      -0.015948422253131866,
      0.09315979480743408
    ],
    [
      -0.04039089009165764,
      0.024668419733643532,
      0.026967691257596016,
      0.038841377943754196,
      -0.055708952248096466,
      -0.029717175289988518,
      -0.0034261096734553576,
      -0.003124950220808387,
      -0.025983884930610657,
      -0.0032466843258589506,
      0.003934340551495552,
      -0.1220841258764267,
      0.007807339541614056,
      0.02859465219080448,
      0.03356980159878731,
      -0.09552018344402313,
      -0.013046867214143276,
      -0.030835306271910667,
      -0.06276888400316238,
      -0.030707484111189842,
      0.03413129225373268,
      -0.023829393088817596,
      0.04516332969069481,
      0.015316197648644447,
      0.0008312956779263914,
      0.04920225590467453,
      -0.026925403624773026,
      0.011207890696823597,
      0.094491146504879,
      -0.004690264817327261,
      -0.004922809079289436,
      0.042333025485277176,
      0.0540059469640255,
      -0.017607463523745537,
      0.033239591866731644,
      0.020967472344636917,
      -0.0790417417883873,
      0.06501641869544983,
      -0.03536228463053703,
      -0.035664454102516174,
      -0.004772424232214689,
      0.016432220116257668,
      -0.02251470647752285,
      -0.02976997196674347,
      -0.10843584686517715,
      -0.00033069317578338087,
      -0.0004369603702798486,
      0.011659017764031887,
      0.01516525074839592,
      -0.0032151523046195507,
      -0.01242492813616991,
      0.0770774856209755,
      0.03008151613175869,
      -0.00013742773444391787,
      0.03870333358645439,
      -0.023505158722400665,
      0.005887304432690144,
      0.07550587505102158,
      -0.03377322480082512,
      0.040506839752197266,
      0.014666096307337284,
      -0.016592038795351982,
      -0.051074255257844925,
      -0.009569760411977768,
      -0.05662078782916069,
      -0.018591182306408882,
      -0.05183713883161545,
      0.01862386055290699,
      0.037408292293548584,
      -0.0550309419631958,
      0.04839441552758217,
      0.0780470073223114,
      -0.055520590394735336,
      -0.1293417364358902,
      -0.04122282564640045,
      -0.06459454447031021,
      -0.047054074704647064,
      -0.006545255426317453,
      0.04078751057386398,
      0.021039282903075218,
      -0.044956039637327194,
      0.012612772174179554,
      -0.05148841813206673,
      0.058420706540346146,
      0.016291633248329163,
      -0.012810669839382172,
      -0.04462037980556488,
      0.007486346177756786,
      0.048746831715106964,
      -0.02566078118979931,
      0.022337593138217926,
      0.06406400352716446,
      -0.07627604156732559,
      -0.060330022126436234,
      -0.03152139484882355,
      -0.034538883715867996,
      -0.029377389699220657,
      0.07533074170351028,
      0.018358930945396423,
      0.018766162917017937,
      -0.027775239199399948,
      0.02113250456750393,
      -0.02519576996564865,
      -0.04632287472486496,
      0.031337860971689224,
      -0.05342578515410423,
      0.006536702159792185,
      0.0722833052277565,
      -0.09266126900911331,
      0.07402977347373962,
      -0.0022407593205571175,
      0.009696940891444683,
      0.045505885034799576,
      0.011990107595920563,
      -0.06321077793836594,
      0.08681035041809082,
      0.08349715918302536,
      -0.004570421297103167,
      0.019693812355399132,
      0.01537762675434351,
      -0.043736670166254044,
      -0.05632892996072769,
      -0.01686904951930046,
      -0.009871759451925755,
      0.06930927187204361,
      -0.1039632186293602,
      0.09470542520284653,
      0.014460016041994095,
      -0.01095770113170147,
      0.0599253885447979,
      -0.034173015505075455,
      -0.09370134025812149,
      0.028643719851970673,
      -0.003172258846461773,
      -0.08330506086349487,
      0.026736335828900337,
      -0.014887926168739796,
      0.04231792315840721,
      0.011944510973989964,
      -0.009444775059819221,
      -0.03788422793149948,
      -0.009303081780672073,
      0.065383680164814,
      -0.06323544681072235,
      0.04689377173781395,
      -0.0257857833057642,
      -0.08347241580486298,
      -0.05887898802757263,
      -0.04896623641252518,
      -0.040742963552474976,
      0.0001989282900467515,
      0.06594909727573395,
      0.03511646389961243,
      -0.040036946535110474,
      0.04760216176509857,
      -0.00845345202833414,
      -0.11565347015857697,
      -0.012787091545760632,
      0.07505010813474655,
      0.013388658873736858,
      -0.05290796235203743,
      0.1329699158668518,
      -0.06375660002231598,
      0.04968351870775223,
      -0.03471250459551811,
      -0.02146492339670658,
      0.04172275587916374,
      0.08376869559288025,
      -0.020727507770061493,
      -0.050657618790864944,
      0.028720803558826447,
      0.07374785840511322,
      0.014087672345340252,
      -0.08839311450719833,
      -0.016654178500175476,
      -0.0210601557046175,
      0.047464750707149506,
      0.0340123325586319,
      0.05399069935083389,
      0.009415865875780582,
      -0.03296424821019173,
      0.000736039481125772,
      -0.01277146115899086,
      -0.04887090250849724,
      -0.0006122150807641447,
      -0.045734524726867676,
      0.06644478440284729,
      0.005312951281666756,
      -0.029507700353860855,
      0.04454696923494339,
      0.061123527586460114,
      0.008257942274212837,
      0.004707367159426212,
      -0.02785395085811615,
      0.012142211198806763,
      -0.03728363290429115,
      -0.04693886637687683,
      -0.0013022421626374125,
      -0.06846044957637787,
      0.03844556212425232,
      0.011703917756676674,
      -0.025215331465005875,
      0.02444729581475258,
      -0.009629379026591778,
      -0.014269374310970306,
      -0.019612938165664673,
      -0.01710454747080803,
      -0.018459711223840714,
      -0.06669461727142334,
      -0.01805102825164795,
      -0.03635457530617714,
      -0.012178957462310791,
      -0.08857531100511551,
      0.017545001581311226,
      -0.03939693793654442,
      0.042249761521816254,
      0.05890153720974922,
      0.04058128967881203,
      0.030203793197870255,
      0.0008827536948956549,
      -0.0035111214965581894,
      -0.08021632581949234,
      0.013363376259803772,
      -0.007138318847864866,
      0.05778763070702553,
      -0.02093449793756008,
      -0.01184732187539339,
      -0.04396643489599228,
      -0.021190185099840164,
      -0.010002290830016136,
      -0.06347182393074036,
      0.010383102111518383,
      0.0024625554215162992,
      -0.02491668611764908,
      0.07026977092027664,
      -0.002091592876240611,
      -0.06744211167097092,
      0.012158856727182865,
      -0.027279378846287727,
      0.01310130674391985,
      0.07313060015439987,
      -0.05284048244357109,
      -0.03671279549598694,
      0.06886804103851318,
      -0.03258069232106209,
      -0.03987086936831474,
      -0.05495215207338333,
      0.002757364185526967,
      0.024199197068810463,
      -0.04519880563020706,
      0.03494112938642502,
      0.006539558060467243,
      0.04932011291384697,
      -0.02066274918615818,
      0.03454307094216347,
      -0.03137185424566269,
      0.03343392536044121,
      0.025481458753347397,
      -0.04273711517453194,
      -0.01679181680083275,
      0.053624533116817474,
      -0.03647265583276749,
      -0.089114710688591,
      0.027325142174959183,
      -0.022764073684811592,
      -0.010766002349555492,
      -0.0885864794254303,
      -0.010537004098296165,
      0.01148656290024519,
      0.03290333226323128,
      0.0272863507270813,
      -0.04288341477513313,
      0.02879246696829796,
      -0.05653425306081772,
      0.08237733691930771,
      -0.020691707730293274,
      0.027983972802758217,
      0.04365285113453865,
      0.001427268609404564,
      -0.015756767243146896,
      -0.053114552050828934,
      -0.008019755594432354,
      0.029872223734855652,
      0.09724287688732147,
      0.01889890618622303,
      0.03967951238155365,
      -0.03891269117593765,
      0.02951495721936226,
      0.04309391975402832,
      0.018897878006100655,
      0.07513319700956345,
      0.04406139627099037,
      -0.006432779133319855,
      -0.008283622562885284,
      0.021682869642972946,
      -0.006545772310346365,
      0.05661405250430107,
      0.009921947494149208,
      -0.06923317909240723,
      -0.06625399738550186,
      -0.049774181097745895,
      0.031689148396253586,
      -0.06590601801872253,
      0.06432478874921799,
      -0.02818053402006626,
      -0.0289745070040226,
      -0.02079744078218937,
      0.08394724130630493,
      0.012318266555666924,
      0.08238343149423599,
      0.048609498888254166,
      -0.04922923818230629,
      -0.018836764618754387,
      0.006500130984932184,
      -0.027401210740208626,
      -0.030051041394472122,
      -0.04035168141126633,
      -0.00042649541865102947,
      -0.03140967711806297,
      0.10832696408033371,
      0.031931597739458084,
      -0.07198059558868408,
      -0.013282437808811665,
      -0.0750875249505043,
      -0.04913723096251488,
      -0.0531083419919014,
      -0.031337108463048935,
      -0.016319334506988525,
      -0.034132324159145355,
      0.020369544625282288,
      -0.007112730294466019,
      0.02035602554678917,
      -0.06874583661556244,
      -0.029737846925854683,
      0.0012049103388562799,
      0.00756280729547143,
      0.05017274245619774,
      0.008231650106608868,
      0.04690437763929367,
      -0.04309041053056717,
      -0.03870704025030136,
      -0.009047525003552437,
      -0.018802683800458908,
      -0.011319249868392944,
      0.06623806804418564,
      0.05380761995911598,
      0.07853775471448898,
      0.02341792732477188,
      -0.0418630987405777,
      -0.009038466028869152,
      0.028082454577088356,
      -0.02907712012529373,
      -0.03991685062646866,
      0.02886229380965233,
      -0.05990497022867203,
      -0.016648968681693077,
      -0.011835859157145023,
      -0.07023309171199799,
      0.027972757816314697,
      0.027070078998804092,
      0.10671713203191757,
      0.0717456117272377,
      0.04526722803711891,
      0.004037412349134684,
      0.007182138506323099,
      -0.05563642084598541,
      0.11367471516132355,
      -0.14790058135986328,
      -0.007521037477999926,
      0.007452170830219984,
      -0.005746608600020409,
      -0.05137855187058449,
      -0.01895042508840561,
      -0.06303747743368149,
      0.002186149824410677,
      -0.015502997674047947,
      0.09237993508577347,
      0.054094333201646805,
      -0.04068192467093468,
      0.03503202274441719,
      -0.015937242656946182,
      -0.010962028056383133,
      0.023696670308709145,
      0.05682257562875748,
      0.09257873147726059,
      0.06653756648302078,
      -0.006057212594896555,
      0.1021093800663948,
      -0.05807695537805557,
      0.08519881963729858,
      0.04616956785321236,
      -0.06005791947245598,
      0.07181945443153381,
      -0.03483455255627632,
      -0.03642059862613678,
      0.08303553611040115,
      -0.09383352100849152,
      -0.0028393117245286703,
      0.024840446189045906,
      0.019810710102319717,
      -0.04910210892558098,
      0.05449593439698219,
      -0.04485206678509712,
      0.08196555823087692,
      0.05554769188165665,
      0.02370343543589115,
      0.03819657862186432,
      -0.01547314040362835,
      -0.07048937678337097,
      0.049741487950086594,
      -0.019489161670207977,
      0.0432274267077446,
      -0.041219666600227356,
      -0.0488719642162323,
      0.010434871539473534,
      0.026285445317626,
      -0.0013902232749387622,
      -0.012512353248894215,
      -0.07837995141744614,
      0.04631149396300316,
      0.13920347392559052,
      0.12809357047080994,
      -0.014829259365797043,
      -0.009248423390090466,
      0.0581766813993454,
      -0.00951425451785326,
      0.045437026768922806,
      -0.0023009164724498987,
      -0.06229744106531143,
      -0.029934978112578392,
      -0.005810217000544071,
      -0.03265434131026268,
      0.025756478309631348,
      -0.04315153509378433,
      0.03918452933430672,
      0.038627710193395615,
      0.029151586815714836,
      -0.061751291155815125,
      -0.05043308809399605,
      -0.02130327932536602,
      0.04016204923391342,
      -0.03243806213140488,
      0.057626351714134216,
      -0.08039802312850952,
      0.0492643304169178,
      -0.004857969004660845,
      0.016230184584856033,
      0.0665416494011879,
      0.022865742444992065,
      0.004869568627327681,
      0.0017486850265413523,
      0.019774621352553368,
      -0.005982746370136738,
      -0.016655316576361656,
      -0.08601652085781097,
      0.026744041591882706,
      -0.020968476310372353,
      0.03992953151464462,
      0.04043835401535034,
      0.11606988310813904,
      0.008550850674510002,
      0.06843214482069016,
      -0.12233675271272659,
      -0.039028748869895935,
      -0.06096057593822479,
      0.0018993373960256577,
      0.02085758186876774,
      0.017538169398903847,
      0.014343831688165665,
      -0.03701837733387947,
      0.045954033732414246,
      -0.03163047879934311,
      0.04949047788977623,
      -0.0682891309261322,
      -0.014142253436148167,
      -0.03563721477985382,
      -0.07321364432573318,
      -0.043670687824487686,
      0.015636436641216278,
      0.07311300188302994,
      0.020406896248459816,
      -0.06999560445547104,
      0.009300624951720238,
      -0.04264456406235695,
      -0.03089754469692707,
      0.08465790748596191,
      0.018430108204483986,
      0.010389970615506172,
      0.030620256438851357,
      -0.01128199603408575,
      -0.008098090067505836,
      -0.004689898807555437,
      0.17632797360420227,
      -0.07069297134876251,
      0.07641396671533585,
      -0.0465778224170208,
      -0.040323853492736816,
      -0.05353840813040733,
      0.004562824498862028,
      -0.00854683667421341,
      -0.04152650013566017,
      -0.02820647321641445,
      0.042027752846479416
    ],
    [
      -0.0013810867676511407,
      0.023266177624464035,
      -0.009651001542806625,
      0.03237868845462799,
      -0.026396064087748528,
      0.07310492545366287,
      0.01976044289767742,
      -0.05920807272195816,
      -0.06108381226658821,
      0.007974492385983467,
      -0.045102592557668686,
      0.012468627654016018,
      -0.04187789559364319,
      0.06032250449061394,
      0.036401670426130295,
      -0.0015479670837521553,
      0.015325228683650494,
      -0.04764954000711441,
      -0.03354945406317711,
      0.08168406784534454,
      -0.03423910215497017,
      0.036857932806015015,
      -0.07262345403432846,
      0.04979729279875755,
      0.021316254511475563,
      0.06317070126533508,
      0.02747282199561596,
      -0.07928518205881119,
      -0.018789581954479218,
      -0.08539076149463654,
      0.030338801443576813,
      0.017635682597756386,
      0.018768901005387306,
      -0.006357747130095959,
      -0.013955428265035152,
      -0.08078071475028992,
      -0.04559096321463585,
      0.020800715312361717,
      0.07956034690141678,
      0.017615828663110733,
      0.01168676745146513,
      0.04099741578102112,
      0.003511098911985755,
      -0.08209399878978729,
      0.01563209481537342,
      0.025053970515727997,
      0.01860879547894001,
      -0.08295148611068726,
      0.024266134947538376,
      -0.024122348055243492,
      -0.029848871752619743,
      0.032940372824668884,
      -0.0489642433822155,
      0.014128035865724087,
      -0.0314500667154789,
      -0.06738144904375076,
      0.011810814961791039,
      0.06263900548219681,
      0.0009737528744153678,
      -0.08915447443723679,
      0.08302842080593109,
      -0.011493708938360214,
      -0.034667931497097015,
      0.021808568388223648,
      0.01950736530125141,
      -0.034591276198625565,
      -0.050546083599328995,
      0.059466224163770676,
      -0.06408999860286713,
      -0.027938054874539375,
      0.04627833142876625,
      -0.02591673843562603,
      0.04853514954447746,
      0.012034428305923939,
      -0.05695143714547157,
      -0.037501681596040726,
      0.017801249399781227,
      -0.028685249388217926,
      0.04691904038190842,
      0.05999357998371124,
      -0.0025801979936659336,
      0.031627994030714035,
      0.049397408962249756,
      -0.006856209132820368,
      0.015547618269920349,
      0.06592050194740295,
      0.002130841137841344,
      -0.0007113685132935643,
      0.07233953475952148,
      0.025519302114844322,
      0.03148915618658066,
      -0.045690543949604034,
      -0.04226773977279663,
      0.0024107261560857296,
      -0.0048635369166731834,
      0.05493554100394249,
      -0.055710531771183014,
      0.05214479938149452,
      0.034365247935056686,
      0.07718899846076965,
      -0.01841694861650467,
      0.029651982709765434,
      0.06895042955875397,
      -0.03355934098362923,
      -0.006761934608221054,
      0.07559292018413544,
      0.03225885331630707,
      0.008140633814036846,
      0.012910982593894005,
      -0.023730099201202393,
      0.020806381478905678,
      0.04493849352002144,
      -0.055077098309993744,
      -0.06950230896472931,
      0.04966183751821518,
      0.0021712814923375845,
      0.02800976298749447,
      0.07591782510280609,
      -0.015171948820352554,
      -0.07754028588533401,
      -0.05828613042831421,
      -0.03648710623383522,
      0.0013160655507817864,
      -0.05983282998204231,
      -0.03154829889535904,
      0.06167962774634361,
      -0.012902881018817425,
      0.027925357222557068,
      0.0651651918888092,
      -0.03737754747271538,
      0.02938920632004738,
      0.06305869668722153,
      0.0014183428138494492,
      0.05931047722697258,
      -0.06205631420016289,
      0.0221275482326746,
      -0.0008243886986747384,
      -0.010913500562310219,
      0.0012335096253082156,
      0.05202670767903328,
      0.05394101142883301,
      0.03785719722509384,
      0.04270988330245018,
      -0.0331202857196331,
      -0.0064581893384456635,
      0.002153111156076193,
      0.028008367866277695,
      0.014574836939573288,
      -0.04937921464443207,
      -0.0011132829822599888,
      -0.0022946104872971773,
      -0.010674932971596718,
      0.019345011562108994,
      -0.015159751288592815,
      -0.03440715745091438,
      0.10242046415805817,
      -0.01866048574447632,
      0.0995611771941185,
      0.0781223252415657,
      0.09298394620418549,
      -0.02763802371919155,
      -0.05041986703872681,
      -0.026280663907527924,
      -0.008176783099770546,
      -0.08153319358825684,
      -0.009961673989892006,
      -0.02058483473956585,
      -0.04956184700131416,
      0.0005861992249265313,
      -0.05248549208045006,
      -0.014240876771509647,
      0.007581941783428192,
      0.0006707586580887437,
      0.013082332909107208,
      0.009417077526450157,
      0.023962270468473434,
      0.01150747574865818,
      0.027074767276644707,
      -0.01441424060612917,
      -0.044808294624090195,
      -0.07386960834264755,
      0.013469954021275043,
      -0.010961080901324749,
      -0.06737005710601807,
      -0.036158427596092224,
      0.005268982611596584,
      0.019071225076913834,
      0.01876487210392952,
      -0.025106314569711685,
      -0.0490882433950901,
      -0.011280231177806854,
      0.11021088808774948,
      0.03367752581834793,
      0.05085211247205734,
      -0.014111008495092392,
      -0.07797877490520477,
      0.010028642602264881,
      0.0042577749118208885,
      0.01977117918431759,
      0.006267976015806198,
      0.0025159395299851894,
      -0.04472553730010986,
      0.11172996461391449,
      0.021068109199404716,
      0.026980537921190262,
      -0.05947647988796234,
      -0.029205484315752983,
      0.0025465060025453568,
      -0.03413648530840874,
      -0.03332659974694252,
      0.033492106944322586,
      -0.0465058758854866,
      -0.10372785478830338,
      -0.08466456085443497,
      0.07707777619361877,
      -0.03238673508167267,
      0.10245493799448013,
      -0.060207266360521317,
      -0.04171484708786011,
      -0.03195013478398323,
      -0.001328607788309455,
      0.02250615879893303,
      -0.010182088240981102,
      -0.04558107629418373,
      -0.11305367946624756,
      0.03269205614924431,
      0.05147670954465866,
      0.0037111397832632065,
      -0.059175651520490646,
      -0.02219848707318306,
      -0.034404609352350235,
      0.04865908995270729,
      0.0012262366944923997,
      0.04822777211666107,
      0.0010119262151420116,
      0.007097155787050724,
      -0.034701887518167496,
      0.01617366634309292,
      0.024952158331871033,
      0.01709546148777008,
      0.05896530672907829,
      0.04086713865399361,
      -0.03709457069635391,
      0.0008904594578780234,
      0.04727667570114136,
      0.03215040639042854,
      0.04527243599295616,
      -0.04849661514163017,
      0.027865080162882805,
      0.05687261000275612,
      -0.05679160729050636,
      0.02288348414003849,
      0.0553753562271595,
      -0.009140566922724247,
      0.03840211406350136,
      0.018464775756001472,
      0.10304421931505203,
      0.029992492869496346,
      -0.012058594264090061,
      0.019403941929340363,
      0.034375034272670746,
      -0.010234368033707142,
      0.0016816153656691313,
      -0.02649948000907898,
      0.07041060924530029,
      0.027714461088180542,
      0.006689426023513079,
      0.06135173514485359,
      0.02625877410173416,
      -0.06940878927707672,
      -0.012248585000634193,
      0.01947321742773056,
      -0.06160767003893852,
      -0.03528695926070213,
      0.036527134478092194,
      0.010244275443255901,
      -0.08273079246282578,
      0.014845899306237698,
      -0.15417087078094482,
      -0.030849698930978775,
      0.031535554677248,
      0.004931936506181955,
      -0.03873514011502266,
      -0.004495413973927498,
      -0.0028923614881932735,
      0.00438643479719758,
      -0.02452312968671322,
      -0.004566885530948639,
      0.058431725949048996,
      -0.03897769749164581,
      0.08002223074436188,
      0.06220307946205139,
      0.044233713299036026,
      0.009424416348338127,
      -0.041904088109731674,
      -0.03229980915784836,
      0.057318806648254395,
      0.023327631875872612,
      -0.03466116636991501,
      -0.017213886603713036,
      0.03670932725071907,
      0.03131655231118202,
      0.029913105070590973,
      0.04028626158833504,
      0.026893841102719307,
      -0.038087449967861176,
      0.02777973935008049,
      0.07344435155391693,
      -0.03821560740470886,
      0.028284640982747078,
      -0.0034185340628027916,
      0.04505140706896782,
      -0.11783906072378159,
      0.021976741030812263,
      -0.022276049479842186,
      0.09035835415124893,
      -0.014324607327580452,
      -0.009977506473660469,
      0.028055069968104362,
      0.04160033166408539,
      0.033195436000823975,
      0.05474703386425972,
      0.012086935341358185,
      -0.10898515582084656,
      -0.08857620507478714,
      0.02695503830909729,
      -0.0003718816442415118,
      -0.04290405660867691,
      0.006553147919476032,
      -0.016599316149950027,
      0.0653858631849289,
      -0.018461143597960472,
      -0.008304734714329243,
      0.009143601171672344,
      0.01494587305933237,
      -0.02709728293120861,
      0.033110275864601135,
      -0.00040772854117676616,
      0.0005385418771766126,
      -0.01934991218149662,
      -5.8286983403377235e-05,
      -0.07551471143960953,
      -0.041929081082344055,
      -0.016162332147359848,
      0.06476462632417679,
      0.03675137087702751,
      -0.10095355659723282,
      0.023649774491786957,
      -0.05411696806550026,
      0.02231493592262268,
      0.029109496623277664,
      -0.08170601725578308,
      -0.01024217065423727,
      0.0391831211745739,
      -0.03341764956712723,
      -0.11184842139482498,
      0.059776607900857925,
      -0.005352863110601902,
      0.030169904232025146,
      0.033675942569971085,
      -0.020464321598410606,
      -0.008670083247125149,
      -0.09147721529006958,
      0.05588585138320923,
      0.00408058101311326,
      -0.007956985384225845,
      -0.06775206327438354,
      -0.04725930467247963,
      0.02655051462352276,
      -0.03181073069572449,
      0.07641521096229553,
      -0.06784000992774963,
      -0.0523727722465992,
      -0.037164606153964996,
      0.0034584496170282364,
      0.06504455208778381,
      0.05604381486773491,
      0.03546200320124626,
      0.023820141330361366,
      0.010301950387656689,
      -0.022961320355534554,
      0.02023819088935852,
      -0.1132703647017479,
      0.01606457680463791,
      0.0019185515120625496,
      0.007119522430002689,
      0.0007031300920061767,
      -0.028854377567768097,
      -0.038715120404958725,
      -0.03431813791394234,
      0.05765014886856079,
      0.025446144863963127,
      0.007285240106284618,
      0.013261320069432259,
      -0.057394176721572876,
      -0.00830116681754589,
      0.04236332327127457,
      0.12141450494527817,
      -0.02843235246837139,
      -0.005142644979059696,
      -0.04732426255941391,
      0.07360612601041794,
      -0.0011110363993793726,
      -0.039309874176979065,
      0.02893862873315811,
      0.03781181573867798,
      0.07670804858207703,
      0.03452629968523979,
      -0.05331123620271683,
      0.059948939830064774,
      0.027807913720607758,
      -0.06351079046726227,
      -0.06670509278774261,
      -0.03503691405057907,
      -0.026844799518585205,
      0.013116341084241867,
      0.009220203384757042,
      -0.013057907111942768,
      0.0692349299788475,
      0.013230870477855206,
      0.030004603788256645,
      0.09132274985313416,
      -0.021079862490296364,
      -0.018753690645098686,
      0.034113816916942596,
      0.04240094870328903,
      0.013233775272965431,
      0.009287905879318714,
      0.006820577196776867,
      0.014570998027920723,
      0.02330588921904564,
      0.0896386131644249,
      0.036888059228658676,
      0.09089267998933792,
      0.020398616790771484,
      0.024149596691131592,
      0.028738602995872498,
      -0.01527262944728136,
      0.09637869894504547,
      -0.0011085703736171126,
      0.0818229466676712,
      -0.03806505724787712,
      -0.037678882479667664,
      0.03452933952212334,
      0.0026981818955391645,
      -0.042242150753736496,
      -0.013300790451467037,
      -0.09323886781930923,
      0.03443586826324463,
      0.0028620813973248005,
      -0.04255549982190132,
      0.025719398632645607,
      -0.03371238335967064,
      -0.028297368437051773,
      -0.016383863985538483,
      -0.03149751201272011,
      0.02202257513999939,
      -0.02011728100478649,
      0.010156210511922836,
      0.03584890067577362,
      0.03712879493832588,
      0.023558052256703377,
      -0.05675956979393959,
      -0.015448497608304024,
      -0.0006095498101785779,
      -0.027643660083413124,
      0.04539339244365692,
      0.019403815269470215,
      0.027776945382356644,
      0.03243419900536537,
      0.08793345838785172,
      -0.026604600250720978,
      -0.05185423046350479,
      -0.02245759777724743,
      0.056416042149066925,
      0.09125545620918274,
      0.06820424646139145,
      -0.05882067233324051,
      0.0918954461812973,
      0.028985626995563507,
      0.0174421276897192,
      0.025223037227988243,
      -0.05055062472820282,
      0.03971898555755615,
      -0.04598094895482063,
      0.0006138081662356853,
      -0.03775264322757721,
      -0.05348474904894829,
      -0.05512722581624985,
      -0.0736762136220932,
      0.03378857672214508,
      0.04052688181400299,
      0.0645085871219635,
      0.03877574950456619,
      -0.026646682992577553,
      -0.10740546137094498,
      -0.028185313567519188,
      -0.07070973515510559,
      0.034268151968717575,
      -0.03362409397959709,
      -0.004560310859233141,
      0.03158912807703018,
      -0.02099807746708393
    ],
    [
      -0.006916663143783808,
      0.024549804627895355,
      0.004174931440502405,
      0.05992572009563446,
      0.05369752645492554,
      -0.07731573283672333,
      -0.0010868492536246777,
      -0.01626427285373211,
      -0.009428827092051506,
      -0.03751586750149727,
      -0.05002947524189949,
      -0.0035737319849431515,
      -0.009163830429315567,
      0.0245369803160429,
      0.0006349532632157207,
      0.07423084229230881,
      0.00956402625888586,
      -0.004060864914208651,
      0.027232684195041656,
      -0.06942638754844666,
      0.06126245856285095,
      -0.09785094112157822,
      0.03416033089160919,
      -0.10261083394289017,
      0.07816118001937866,
      0.024779077619314194,
      0.06582027673721313,
      0.012195911258459091,
      0.0525430366396904,
      0.019842013716697693,
      0.07249339669942856,
      -0.03763527423143387,
      -0.04026350378990173,
      0.08160340785980225,
      0.001927842851728201,
      -0.03639233857393265,
      -0.04497012123465538,
      -0.04023415222764015,
      -0.02453608252108097,
      0.061756521463394165,
      -0.02417757548391819,
      0.019666073843836784,
      0.03758223354816437,
      -0.1197422668337822,
      0.03754414990544319,
      0.021981798112392426,
      0.018839286640286446,
      0.09109172970056534,
      0.004628476686775684,
      0.031125593930482864,
      0.024740716442465782,
      0.011649791151285172,
      0.010737943463027477,
      -0.023294322192668915,
      0.023378578945994377,
      -0.022730572149157524,
      0.05592348426580429,
      0.043630871921777725,
      -0.031174181029200554,
      -0.04009843245148659,
      0.03546611964702606,
      0.0013177426299080253,
      -0.004198719747364521,
      -0.018995890393853188,
      0.0379817821085453,
      -0.019516566768288612,
      0.04043903574347496,
      -0.0026552400086075068,
      0.10503686219453812,
      0.013662920333445072,
      0.014768718741834164,
      -0.008420471101999283,
      0.05156012624502182,
      -0.0061604054644703865,
      0.02946924977004528,
      0.022510306909680367,
      0.05565470829606056,
      0.018349235877394676,
      -0.07470672577619553,
      -0.05963463336229324,
      0.009960625320672989,
      0.057074178010225296,
      -0.05071822553873062,
      -0.07181358337402344,
      0.09877242892980576,
      0.043162889778614044,
      -0.06064467877149582,
      -0.014164972119033337,
      -0.04793620482087135,
      -0.06913560628890991,
      0.05029815435409546,
      0.026342829689383507,
      -0.009453891776502132,
      -0.0010467128595337272,
      -0.016964349895715714,
      0.04328858107328415,
      0.06445618718862534,
      0.0023736804723739624,
      0.09035876393318176,
      0.0787568986415863,
      0.09443746507167816,
      -0.006303408183157444,
      0.02438107505440712,
      0.000883694679941982,
      -0.053165823221206665,
      -0.0028238920494914055,
      0.045077692717313766,
      0.057305458933115005,
      -0.021478217095136642,
      -0.05361519381403923,
      0.1103719174861908,
      0.05252416804432869,
      -0.03051144815981388,
      0.08856004476547241,
      0.07584473490715027,
      0.03325709328055382,
      -0.029688341543078423,
      -0.0320543497800827,
      -0.034024856984615326,
      -0.04526987671852112,
      -0.11776760220527649,
      -0.06134967878460884,
      0.011303574778139591,
      0.07060305774211884,
      -0.035326678305864334,
      0.0034810795914381742,
      0.06590200960636139,
      -0.08678736537694931,
      0.006337986793369055,
      0.0010674443328753114,
      -0.012950642965734005,
      -0.05486756190657616,
      -0.05507506802678108,
      0.04619569703936577,
      0.0627477839589119,
      0.04704201966524124,
      -0.009102383628487587,
      -0.010255676694214344,
      0.03313402086496353,
      0.03417452424764633,
      -0.044533129781484604,
      0.05963777005672455,
      0.05408149212598801,
      -0.019838469102978706,
      -0.06670830398797989,
      -0.123853899538517,
      0.07649178802967072,
      0.022946374490857124,
      0.08074308931827545,
      0.050820525735616684,
      0.002993058878928423,
      0.04916937276721001,
      -0.04714420437812805,
      -0.0562349334359169,
      0.02065962553024292,
      -0.027218356728553772,
      0.00023314429563470185,
      -0.00046646533883176744,
      -0.012118109501898289,
      0.0017245427006855607,
      -0.01395378541201353,
      0.012758180499076843,
      0.03280704468488693,
      -0.007963518612086773,
      -0.04938545450568199,
      0.05378001928329468,
      0.08604401350021362,
      0.0659036710858345,
      -0.027728881686925888,
      0.026172222569584846,
      0.008773342706263065,
      0.01708392985165119,
      -0.020649826154112816,
      0.0452328585088253,
      0.01415980514138937,
      -0.031081071123480797,
      -0.03077150322496891,
      0.053911082446575165,
      -0.04120533540844917,
      -0.06843313574790955,
      0.044603534042835236,
      -0.02825557440519333,
      -0.0462002269923687,
      -0.027818171307444572,
      0.0325479656457901,
      -0.0023505317512899637,
      0.0779978558421135,
      0.007627958431839943,
      -0.06430277973413467,
      0.042038578540086746,
      -0.015035617165267467,
      0.04614166170358658,
      0.029544536024332047,
      -0.007293319795280695,
      -0.05480862781405449,
      -0.004556499421596527,
      -0.01676557958126068,
      -0.006766205653548241,
      -0.024051835760474205,
      -0.04534320533275604,
      -0.08350260555744171,
      0.06485293805599213,
      -0.0010045963572338223,
      0.030258920043706894,
      0.026444023475050926,
      -0.019818825647234917,
      -0.058363672345876694,
      0.07046926766633987,
      0.05340317636728287,
      0.05708557367324829,
      0.015540987253189087,
      -0.07173538208007812,
      -0.1042243093252182,
      0.023819752037525177,
      -0.030633166432380676,
      -0.06296751648187637,
      -0.025638675317168236,
      -0.04429160803556442,
      -0.019052917137742043,
      0.06097688153386116,
      -0.07371452450752258,
      0.1039113998413086,
      -0.04805706813931465,
      -0.05072174221277237,
      0.026745503768324852,
      -0.007049492094665766,
      -0.06062881648540497,
      0.04688749089837074,
      -0.026963667944073677,
      -0.017893865704536438,
      -0.005206167697906494,
      0.017675552517175674,
      -0.04704330861568451,
      -0.015372359193861485,
      -0.05537337437272072,
      0.011624319478869438,
      -0.00023092185438144952,
      -0.012529818341135979,
      -0.017911702394485474,
      0.05069243162870407,
      0.07169543206691742,
      0.03857334330677986,
      -0.04616900160908699,
      -0.0019384089391678572,
      0.04429829865694046,
      0.1263747215270996,
      -0.029487932100892067,
      -0.004717023577541113,
      0.022142184898257256,
      -0.012506473809480667,
      0.03327580541372299,
      0.006582561880350113,
      -0.053388725966215134,
      -0.02390347607433796,
      -0.024859128519892693,
      -0.0063553969375789165,
      -0.06327313184738159,
      0.025652041658759117,
      0.07045673578977585,
      -0.059830937534570694,
      -0.009926331229507923,
      -0.11606002599000931,
      -0.05370281636714935,
      -0.01663198508322239,
      0.08675133436918259,
      0.021785395219922066,
      0.022913271561264992,
      -0.008288051001727581,
      -0.000949875102378428,
      -0.007322689983993769,
      0.08920370042324066,
      -0.029901962727308273,
      0.011217117309570312,
      0.030806099995970726,
      0.008070195093750954,
      -0.03847964480519295,
      0.10135078430175781,
      -0.03076537698507309,
      -0.10254865884780884,
      -0.006670207716524601,
      -0.07974903285503387,
      0.02387617900967598,
      0.004899405874311924,
      -0.009946245700120926,
      -0.007920472882688046,
      -0.011209450662136078,
      0.01687176153063774,
      0.01499972864985466,
      -0.009687520563602448,
      0.021354738622903824,
      0.02236383594572544,
      -0.0014975937083363533,
      -0.04405752196907997,
      0.046616848558187485,
      0.07535240799188614,
      -0.054825153201818466,
      0.02478657104074955,
      -0.002842145273461938,
      0.016361594200134277,
      -0.0838971957564354,
      0.01765052042901516,
      -0.025614727288484573,
      0.08317796140909195,
      0.014789815060794353,
      0.0411316379904747,
      -0.04834442213177681,
      -0.01500878855586052,
      0.034260865300893784,
      0.056956008076667786,
      -0.012569488026201725,
      -0.030601102858781815,
      0.04765734821557999,
      -0.027611497789621353,
      0.03492693603038788,
      -0.0060992310754954815,
      -0.006999734789133072,
      -0.0012353218626230955,
      -0.06785479187965393,
      0.13468807935714722,
      0.050969235599040985,
      0.05248388648033142,
      -0.056822534650564194,
      0.07276260107755661,
      -0.06961918622255325,
      0.06225615367293358,
      0.016746923327445984,
      -0.018982306122779846,
      0.0022865617647767067,
      0.01760486140847206,
      0.03162284940481186,
      -0.028542621061205864,
      0.054587818682193756,
      0.021118013188242912,
      -0.05185260623693466,
      0.0504937581717968,
      0.004756562877446413,
      -0.016702594235539436,
      0.011075442656874657,
      -0.008182741701602936,
      0.024045223370194435,
      0.02867085114121437,
      -0.00785867776721716,
      0.003175284480676055,
      0.010370603762567043,
      -0.07252569496631622,
      -0.14215902984142303,
      -0.037745073437690735,
      0.01748581789433956,
      -0.09628485143184662,
      -0.015102933160960674,
      -0.06507091224193573,
      0.01495859120041132,
      0.020637737587094307,
      -0.01657567173242569,
      -0.05544789880514145,
      -0.06847962737083435,
      0.03286118805408478,
      0.01901180110871792,
      0.046991005539894104,
      0.018670380115509033,
      0.04228810966014862,
      -0.017092538997530937,
      -0.0017833065940067172,
      0.021229665726423264,
      0.02363085001707077,
      0.012783804908394814,
      0.05391322821378708,
      0.04130101948976517,
      0.07785744220018387,
      0.055986613035202026,
      0.012132380157709122,
      0.048181917518377304,
      0.002118295058608055,
      -0.020211799070239067,
      -0.026450052857398987,
      -0.008365456946194172,
      0.04415649548172951,
      -0.025356827303767204,
      0.08988926559686661,
      -0.04945032298564911,
      0.051933471113443375,
      0.10621071606874466,
      0.028768466785550117,
      0.01651657372713089,
      -0.009060067124664783,
      0.008509384468197823,
      -0.00027316348860040307,
      -0.04086434468626976,
      0.04854155704379082,
      0.012552251107990742,
      -0.00402335450053215,
      -0.03031802736222744,
      -0.037498343735933304,
      -0.06281276792287827,
      -0.016582565382122993,
      0.02870062179863453,
      -0.04118000715970993,
      -0.01922844909131527,
      0.026900703087449074,
      -0.02016809955239296,
      -0.004674795549362898,
      0.1198461577296257,
      -0.04553700238466263,
      -0.016658049076795578,
      -0.024745993316173553,
      -0.0785212516784668,
      0.026325862854719162,
      0.022978343069553375,
      0.06602789461612701,
      -0.046508997678756714,
      0.022147513926029205,
      -0.02235312946140766,
      -0.012328645214438438,
      -0.010633943602442741,
      -0.059428125619888306,
      -0.0065790689550340176,
      -0.026313040405511856,
      0.03533273935317993,
      -0.011199334636330605,
      -0.0641014575958252,
      0.010419306345283985,
      0.04072640463709831,
      -0.06126878410577774,
      -0.04985455423593521,
      0.006404842250049114,
      0.010165941901504993,
      -0.00272354600019753,
      0.0013068594271317124,
      -0.07960620522499084,
      0.003819862613454461,
      -0.02209448628127575,
      0.04887719452381134,
      -0.014097075909376144,
      0.06148602068424225,
      0.009111003950238228,
      0.005351139698177576,
      -0.08270350843667984,
      -0.07844633609056473,
      -0.035539548844099045,
      -0.043019045144319534,
      -0.02355305850505829,
      0.05273057520389557,
      0.10328969359397888,
      -0.04750954359769821,
      -0.05142948403954506,
      0.023423073813319206,
      0.009736517444252968,
      -0.030330142006278038,
      0.05593359097838402,
      -0.030441535636782646,
      -0.03192409500479698,
      -0.028373394161462784,
      -0.0002491527411621064,
      -0.0706300437450409,
      0.014914882369339466,
      -0.03153373673558235,
      -0.029495911672711372,
      -0.08580794930458069,
      0.03478391841053963,
      -0.004732802975922823,
      0.07732079923152924,
      0.1002897173166275,
      -0.010641753673553467,
      0.08265779167413712,
      -0.05019168183207512,
      -0.03388964384794235,
      0.03358806297183037,
      0.025692392140626907,
      0.021418755874037743,
      0.041885487735271454,
      0.05984283983707428,
      0.006054189521819353,
      -0.04361396282911301,
      -0.02611885592341423,
      0.09041798859834671,
      0.07152218371629715,
      0.015038560144603252,
      -0.016406215727329254,
      -0.02051130309700966,
      0.02544466219842434,
      0.004709075205028057,
      -0.0390695296227932,
      0.019234227016568184,
      0.05715583637356758,
      0.044427353888750076,
      0.023386448621749878,
      0.01031352486461401,
      -0.05019974708557129,
      -0.0037671735044568777,
      -0.02975105494260788,
      0.037398893386125565,
      -0.010941734537482262,
      0.1183958500623703,
      -0.06846538931131363,
      -0.038156308233737946,
      0.002636031247675419,
      0.021613866090774536,
      0.035044170916080475,
      -0.07857820391654968,
      0.044028617441654205,
      0.06268960982561111,
      0.11445394158363342,
      0.02875121869146824
    ],
    [
      -0.0053629218600690365,
      -0.04360866919159889,
      -0.08541467785835266,
      -0.01614445634186268,
      -6.720618330291472e-06,
      -0.044508494436740875,
      0.006515346933156252,
      -0.07594671100378036,
      -0.1542651206254959,
      0.017015373334288597,
      0.04158494621515274,
      -0.03600439801812172,
      -0.07338111102581024,
      -0.006371361669152975,
      0.06807976216077805,
      -0.04570036008954048,
      -0.014516291208565235,
      -0.06573384255170822,
      0.05459260940551758,
      -0.0354263074696064,
      0.028345907106995583,
      0.006449156440794468,
      -0.029111607000231743,
      -0.00737188383936882,
      0.035127900540828705,
      0.025310929864645004,
      -0.017214199528098106,
      -0.06755489110946655,
      -0.06034558638930321,
      0.09327537566423416,
      -0.05376068130135536,
      -0.015180656686425209,
      -0.002312060911208391,
      -0.045403651893138885,
      -0.03400450199842453,
      -0.01919613592326641,
      -0.01810706965625286,
      -0.026721974834799767,
      -0.00904807634651661,
      -0.10479466617107391,
      0.003285742597654462,
      0.0009936487767845392,
      0.05693304166197777,
      -0.05045401677489281,
      0.04261377826333046,
      -0.03246083855628967,
      0.0024528461508452892,
      0.0005980056012049317,
      -0.03580133616924286,
      0.007742995861917734,
      0.06820635497570038,
      0.045003652572631836,
      0.02132107876241207,
      -0.018965205177664757,
      -0.008352731354534626,
      0.05083855614066124,
      0.09400811791419983,
      0.023927779868245125,
      -0.05118918791413307,
      0.004457841161638498,
      -0.05885510891675949,
      -0.018087590113282204,
      -0.0637722760438919,
      0.02457934059202671,
      -0.0019523737719282508,
      0.05315735936164856,
      0.14314502477645874,
      -0.0011096432572230697,
      -0.020004259422421455,
      -0.020170023664832115,
      0.014847270213067532,
      -0.0014528805622830987,
      -0.008302510716021061,
      -0.004450832027941942,
      0.012234177440404892,
      0.08154461532831192,
      0.09227868914604187,
      -0.06301404535770416,
      0.022734878584742546,
      -0.0824153795838356,
      0.012920362874865532,
      -0.06961547583341599,
      -0.06181043013930321,
      -0.09960094839334488,
      -0.020811833441257477,
      0.007791736628860235,
      0.01795344613492489,
      -0.08629865944385529,
      0.01934283785521984,
      -0.017684506252408028,
      0.06462908536195755,
      -0.017818953841924667,
      -0.004639735911041498,
      -0.0027490502689033747,
      -0.07552774250507355,
      0.014835912734270096,
      0.02040199376642704,
      0.006148716434836388,
      -0.04054854065179825,
      0.03472384437918663,
      0.044615909457206726,
      0.019597912207245827,
      0.025036480277776718,
      0.0375632718205452,
      -0.06654412299394608,
      -0.02666456066071987,
      -0.024673381820321083,
      0.0010005963267758489,
      -0.00500786118209362,
      -0.037865668535232544,
      0.04496965929865837,
      0.03872611001133919,
      -0.03554384037852287,
      0.0032297030556946993,
      0.033023230731487274,
      0.052496086806058884,
      -0.02514573186635971,
      -0.06744552403688431,
      -0.002032434567809105,
      0.021072829142212868,
      -0.021184539422392845,
      -0.049845822155475616,
      0.005057934205979109,
      0.10232485085725784,
      0.03382343426346779,
      0.043659236282110214,
      0.054459720849990845,
      -0.0712447315454483,
      0.04024510085582733,
      -0.017105961218476295,
      -0.021104030311107635,
      -0.007377170491963625,
      0.019759975373744965,
      -0.014365185052156448,
      0.02975618652999401,
      -0.02981421910226345,
      -0.019394462928175926,
      -0.13207028806209564,
      0.022709833458065987,
      0.05071954429149628,
      0.03033789061009884,
      0.012150835245847702,
      0.004875573329627514,
      -0.07299351692199707,
      -0.020432500168681145,
      0.036100130528211594,
      0.08644566684961319,
      -0.014482071623206139,
      0.008353253826498985,
      -0.022422250360250473,
      0.02784745581448078,
      0.0348929762840271,
      -0.05740610882639885,
      0.06310799717903137,
      0.014946223236620426,
      0.015366454608738422,
      -0.05020686239004135,
      -0.0495472215116024,
      -0.10773032158613205,
      -0.000283153640339151,
      -0.01303641777485609,
      -0.012576217763125896,
      -0.025441961362957954,
      0.07797887176275253,
      0.03319181874394417,
      -0.03418338671326637,
      0.021883055567741394,
      0.04681041091680527,
      -0.015153933316469193,
      0.052724651992321014,
      0.05294838920235634,
      0.009317302145063877,
      0.03943709656596184,
      -0.02898280881345272,
      -0.10678280144929886,
      0.029667166993021965,
      -0.08059655874967575,
      0.0440325103700161,
      0.018460962921380997,
      0.0452035553753376,
      -0.03856140375137329,
      0.034413136541843414,
      -0.039911165833473206,
      0.07762051373720169,
      -0.015748051926493645,
      0.06846130639314651,
      0.04589104279875755,
      0.0026283343322575092,
      0.0031642813701182604,
      -0.018489405512809753,
      -0.03591126203536987,
      -0.01337498053908348,
      -0.013363818638026714,
      -0.03388195484876633,
      -0.015990527346730232,
      0.02264251373708248,
      0.015423285774886608,
      0.08025912940502167,
      -0.020744334906339645,
      0.09874507039785385,
      0.06790585815906525,
      -0.06216629594564438,
      -0.05079592019319534,
      0.0191030316054821,
      0.01108847837895155,
      0.023083694279193878,
      -0.026115424931049347,
      -0.03681774064898491,
      -0.12443672865629196,
      -0.004984996281564236,
      0.06933173537254333,
      0.07327860593795776,
      -0.15463349223136902,
      0.05674073472619057,
      -0.018631909042596817,
      -0.01460539735853672,
      -0.015173173509538174,
      -0.044399194419384,
      -0.02871706150472164,
      0.06909415870904922,
      -0.025541896000504494,
      0.005195619072765112,
      -0.0028937901370227337,
      -0.03476238623261452,
      0.03779615834355354,
      0.03730245307087898,
      0.018454883247613907,
      0.0860070139169693,
      -0.013768831267952919,
      -0.0669742003083229,
      0.0072562601417303085,
      -0.009123449213802814,
      0.02996990457177162,
      -0.03863104060292244,
      0.03555005416274071,
      -0.059305720031261444,
      -0.03525221347808838,
      0.0037649080622941256,
      -0.01426224410533905,
      0.006062062457203865,
      -0.032663244754076004,
      0.0211639404296875,
      -0.11891143023967743,
      -0.01905439794063568,
      0.03421637788414955,
      -0.053759489208459854,
      0.017083942890167236,
      0.0018034034874290228,
      0.11863923817873001,
      0.06636125594377518,
      -0.004566441755741835,
      -0.11601920425891876,
      0.0008007612777873874,
      -0.0021241409704089165,
      0.10907479375600815,
      0.06549250334501266,
      -0.011841275729238987,
      0.08750537782907486,
      0.0222784373909235,
      0.0777861475944519,
      -0.034333236515522,
      0.0325283408164978,
      0.06205792352557182,
      -0.009286229498684406,
      -0.039050981402397156,
      0.05516130477190018,
      0.010019478388130665,
      -0.02608737349510193,
      -0.04447450488805771,
      0.022932257503271103,
      0.04729236289858818,
      -0.06467260420322418,
      0.023352021351456642,
      -0.057592276483774185,
      -0.0923033282160759,
      0.028304100036621094,
      -0.03963182866573334,
      -0.06098191440105438,
      -0.09189101308584213,
      0.06269828230142593,
      0.0016883373027667403,
      0.050512541085481644,
      0.05069360509514809,
      0.05039811134338379,
      0.07103636115789413,
      -0.06754475831985474,
      0.02786172367632389,
      0.013185055926442146,
      0.004539698828011751,
      0.05609162151813507,
      0.0763164609670639,
      0.07237734645605087,
      0.015791449695825577,
      -0.02000310644507408,
      0.0077016521245241165,
      -0.030833479017019272,
      -0.021258419379591942,
      -0.05186744034290314,
      0.038151729851961136,
      -0.007260854355990887,
      -0.015778537839651108,
      0.03811710700392723,
      -0.033327072858810425,
      0.08151670545339584,
      0.13636024296283722,
      -0.023616867139935493,
      0.034940846264362335,
      0.007036441471427679,
      -0.016542484983801842,
      0.06556815654039383,
      0.047870803624391556,
      -0.005113479681313038,
      -0.03087865747511387,
      0.015554300509393215,
      0.014505406841635704,
      0.04621296375989914,
      0.015081179328262806,
      0.01431894488632679,
      -0.0009573116549290717,
      -0.03691865876317024,
      0.05287067964673042,
      -0.04131580889225006,
      0.020741067826747894,
      -0.1165778711438179,
      -0.019203949719667435,
      -0.052178628742694855,
      -0.05768605321645737,
      0.0895453616976738,
      -0.04408104717731476,
      0.06325754523277283,
      0.07059960812330246,
      0.0005831398884765804,
      -0.030369268730282784,
      0.015673279762268066,
      -0.0915793851017952,
      0.09708991646766663,
      0.029696373268961906,
      -0.008701803162693977,
      0.037899915128946304,
      0.02816237509250641,
      0.014505455270409584,
      -0.05499715358018875,
      0.04104338213801384,
      0.062418121844530106,
      -0.019073262810707092,
      0.014525368809700012,
      -0.02861769124865532,
      0.05870211869478226,
      -0.03246069326996803,
      -0.0755334347486496,
      0.1013130322098732,
      -0.041720908135175705,
      0.04504640772938728,
      -0.040598154067993164,
      -0.05902513116598129,
      -0.016120390966534615,
      -0.014242706820368767,
      -0.02635335363447666,
      -0.0037599005736410618,
      -0.05364033579826355,
      0.03129221871495247,
      0.05015917867422104,
      0.03960847482085228,
      0.0063469973392784595,
      0.04775223881006241,
      -0.02774485945701599,
      0.02033858560025692,
      -0.03583022579550743,
      0.0023182581644505262,
      0.007310067303478718,
      -0.03854694217443466,
      0.054289765655994415,
      -0.003745599649846554,
      0.01350331585854292,
      0.048957522958517075,
      -0.023773500695824623,
      -0.03980199992656708,
      -0.032787423580884933,
      -0.002126264851540327,
      0.021288476884365082,
      -0.0595189705491066,
      0.038828279823064804,
      -0.07811996340751648,
      0.07106345146894455,
      0.034479156136512756,
      -0.0270093884319067,
      -0.06544487178325653,
      -0.04163828864693642,
      0.01666914112865925,
      0.0029949499294161797,
      0.022581327706575394,
      -0.027207741513848305,
      -0.014833816327154636,
      0.0326504223048687,
      0.022254781797528267,
      -0.036293551325798035,
      -0.03589881584048271,
      -0.003584920195862651,
      0.003941633738577366,
      -0.0036762412637472153,
      0.0018258342752233148,
      0.03243747353553772,
      0.06559968739748001,
      -0.024094602093100548,
      -0.045106448233127594,
      -0.010475289076566696,
      0.011475898325443268,
      0.03172779083251953,
      -0.017014482989907265,
      -0.013180959038436413,
      0.11817954480648041,
      -0.017819499596953392,
      -0.09256961941719055,
      0.024367941543459892,
      -0.07110151648521423,
      -0.028292467817664146,
      -0.008866570889949799,
      0.03912336751818657,
      0.027762524783611298,
      -0.07772745937108994,
      -0.04382507503032684,
      0.013263910077512264,
      -0.015883944928646088,
      0.034282561391592026,
      0.00013911776477470994,
      -9.579122706782073e-05,
      -0.056822650134563446,
      -0.03367779031395912,
      0.09764118492603302,
      -0.03665192797780037,
      -0.030928021296858788,
      -0.0005708200624212623,
      0.01054732222110033,
      -0.010541339404881,
      0.015555686317384243,
      -0.04797377064824104,
      0.022932985797524452,
      -0.006889488082379103,
      -0.023491373285651207,
      -0.05815868079662323,
      0.03891772776842117,
      0.08495400846004486,
      0.025952763855457306,
      0.04901030659675598,
      0.060689736157655716,
      -0.03414348512887955,
      0.02444891259074211,
      -0.09034702926874161,
      -0.010223482735455036,
      -0.03636796027421951,
      0.03220495954155922,
      -0.06636922806501389,
      0.03859221935272217,
      -0.01745789311826229,
      0.012877501547336578,
      -0.02391360141336918,
      -0.0604681596159935,
      -0.03296167403459549,
      0.03526516258716583,
      0.006674628239125013,
      0.10444644838571548,
      0.036073941737413406,
      0.07037138938903809,
      -0.04318082332611084,
      -0.02015562169253826,
      0.05861876159906387,
      -0.05661753565073013,
      0.013268324546515942,
      0.02409614436328411,
      0.05563994497060776,
      0.0011929965112358332,
      0.020586047321558,
      -0.013447113335132599,
      0.00825478695333004,
      -0.013426841236650944,
      0.030733302235603333,
      -0.07666710764169693,
      0.015257483348250389,
      0.013040014542639256,
      0.10830830782651901,
      -0.008844091556966305,
      0.02176850102841854,
      -0.059343382716178894,
      0.07957164943218231,
      0.010987349785864353,
      0.025491410866379738,
      -0.04655216634273529,
      -0.030622627586126328,
      -0.02095590904355049,
      -0.029583387076854706,
      -0.018436457961797714,
      -0.024467844516038895,
      0.022752203047275543,
      -0.029139962047338486,
      -0.020807696506381035,
      -0.005911724641919136,
      0.0036751306615769863,
      -0.048892442137002945,
      0.005880988202989101,
      -0.04416573420166969,
      -0.044058188796043396,
      -0.014767986722290516,
      0.01185328047722578
    ],
    [
      -0.005609347019344568,
      -0.032068781554698944,
      0.0058196657337248325,
      0.0680212453007698,
      0.00023074570344761014,
      -0.03017561137676239,
      0.05358061566948891,
      0.004362908657640219,
      -0.0513826385140419,
      -0.04649782553315163,
      0.0513029508292675,
      0.05839617922902107,
      -0.0035795755684375763,
      -0.04182907193899155,
      0.08262098580598831,
      -0.0308377705514431,
      0.0217924565076828,
      -0.08543273061513901,
      0.0863729789853096,
      0.02759351022541523,
      -0.04508921131491661,
      -0.002230491256341338,
      -0.08581662178039551,
      0.03490641713142395,
      0.05553828552365303,
      0.1208706796169281,
      -0.0017801083158701658,
      0.018175899982452393,
      -0.03294605761766434,
      -0.07094577699899673,
      0.04430887848138809,
      -0.004459083080291748,
      0.006296979729086161,
      0.09238480031490326,
      -0.0650196373462677,
      0.019378405064344406,
      -0.0805828794836998,
      -0.10152903199195862,
      -0.0058265808038413525,
      -0.009024348109960556,
      -0.06722891330718994,
      0.07178886234760284,
      -0.01708739623427391,
      -0.04668876528739929,
      0.08735176920890808,
      -0.04820651561021805,
      -0.018493114039301872,
      0.018503015860915184,
      0.0036862201523035765,
      -0.012536738067865372,
      0.024651169776916504,
      0.08799929916858673,
      0.04634692892432213,
      0.01792466826736927,
      -0.00420987606048584,
      -0.018106091767549515,
      0.08706575632095337,
      -0.048446569591760635,
      0.06641674041748047,
      -0.04778130725026131,
      -0.02442917972803116,
      -0.005317627917975187,
      0.039644740521907806,
      0.06728368997573853,
      -0.0850057303905487,
      0.11010342091321945,
      0.03968450054526329,
      -0.015237001702189445,
      0.025633953511714935,
      0.018054364249110222,
      0.061655815690755844,
      -0.07276453077793121,
      0.03050658106803894,
      -0.0008580966386944056,
      0.03308182954788208,
      -0.03758104890584946,
      -0.014319377020001411,
      -0.022470150142908096,
      -0.03978781774640083,
      0.0627007931470871,
      0.08632500469684601,
      -0.026267174631357193,
      0.021147657185792923,
      0.04750992730259895,
      0.02455666847527027,
      -0.0414276160299778,
      -0.0609205961227417,
      -0.011564639396965504,
      0.023486969992518425,
      -0.0006529504898935556,
      0.07710880041122437,
      0.02104990743100643,
      0.08505517244338989,
      -0.024207180365920067,
      -0.018570026382803917,
      0.033044926822185516,
      -0.01988418586552143,
      0.031996481120586395,
      -0.014774959534406662,
      -0.017335698008537292,
      0.05063209310173988,
      0.06639013439416885,
      0.07070823013782501,
      -0.01870444044470787,
      -0.001555258990265429,
      0.03757575526833534,
      0.00020620512077584863,
      -0.045238252729177475,
      0.05532766878604889,
      -0.08273622393608093,
      -0.03569026291370392,
      0.004348491318523884,
      0.07055722922086716,
      -0.08490216732025146,
      -0.03840453177690506,
      -0.037135131657123566,
      -0.030927063897252083,
      0.06984762847423553,
      -0.03823850676417351,
      0.006156574934720993,
      0.050623971968889236,
      0.0024175120051950216,
      0.022544773295521736,
      0.002517273649573326,
      0.03314324840903282,
      0.0305839404463768,
      -0.0017807258991524577,
      -0.025925390422344208,
      0.04654885455965996,
      -0.018775342032313347,
      0.03689223900437355,
      -0.0004124714760109782,
      0.028420446440577507,
      -0.00012195903400424868,
      -0.03840856999158859,
      -0.02010255493223667,
      0.030496180057525635,
      -0.008718500845134258,
      0.012938147410750389,
      0.019038526341319084,
      -0.03668825328350067,
      -0.04130086302757263,
      0.1031828373670578,
      0.014064613729715347,
      0.07110383361577988,
      -0.007657626643776894,
      0.013237802311778069,
      0.03164421394467354,
      -0.012867431156337261,
      0.05421685799956322,
      -0.03379318118095398,
      0.02435002475976944,
      -0.008331830613315105,
      0.005432025995105505,
      -0.003162988694384694,
      -0.03816141560673714,
      -0.07990572601556778,
      0.03315149247646332,
      0.027437441051006317,
      0.005027885548770428,
      -0.03141387552022934,
      -0.01352933794260025,
      0.04454059526324272,
      0.10473325848579407,
      0.062867172062397,
      -0.04283347725868225,
      0.07076963037252426,
      -0.03964417427778244,
      -0.06509383022785187,
      -0.056499190628528595,
      -0.030449436977505684,
      -0.033320456743240356,
      -0.05017249286174774,
      0.02922653593122959,
      0.012589925900101662,
      -0.0016800315352156758,
      0.03685104846954346,
      0.001037844456732273,
      0.03745153546333313,
      -0.007265463471412659,
      0.05988800898194313,
      0.07131431251764297,
      0.0157542135566473,
      -0.04815016686916351,
      -0.0050805192440748215,
      -0.09718876332044601,
      0.04083472490310669,
      0.03338463604450226,
      0.021488254889845848,
      -0.0938260555267334,
      0.0341547429561615,
      0.029707152396440506,
      0.06643333286046982,
      0.015474139712750912,
      -0.05634981766343117,
      -0.0010634900536388159,
      -0.023789627477526665,
      0.007968038320541382,
      -0.05673208087682724,
      -0.015044813975691795,
      0.046927161514759064,
      -0.04123319312930107,
      -0.03901497274637222,
      -0.05100502446293831,
      0.009576456621289253,
      -0.004611166659742594,
      0.09329895675182343,
      -0.02539638616144657,
      -0.06348215788602829,
      -0.04085734486579895,
      0.030512096360325813,
      -0.04922618344426155,
      -0.004475574940443039,
      -0.027602940797805786,
      0.019642719998955727,
      -0.009940466843545437,
      0.021313147619366646,
      -0.09426848590373993,
      0.10552084445953369,
      -0.05364469066262245,
      0.007277927827090025,
      -0.06382567435503006,
      0.04189012199640274,
      -0.04888355731964111,
      -0.025474833324551582,
      0.014559490606188774,
      -0.0502455048263073,
      0.04259243607521057,
      0.006188319995999336,
      0.002092934912070632,
      -0.007073788903653622,
      -0.11305931955575943,
      -0.002863713540136814,
      0.0006383600411936641,
      0.029635543003678322,
      -0.03281286358833313,
      -0.04015364497900009,
      -0.017167571932077408,
      0.05347466468811035,
      -0.02045207843184471,
      0.04771006852388382,
      -0.0011788703268393874,
      -0.0509805865585804,
      0.03227680176496506,
      0.058211687952280045,
      -0.037070274353027344,
      -0.045989543199539185,
      -0.03827449679374695,
      0.08695696294307709,
      -0.004421514924615622,
      -0.009077128954231739,
      -0.0003688493452500552,
      0.054217379540205,
      -0.02653919719159603,
      -0.006851166021078825,
      -0.030896758660674095,
      -0.006678604055196047,
      0.010684944689273834,
      0.017932692542672157,
      -0.024754555895924568,
      0.021556176245212555,
      -0.04754272848367691,
      -0.09359604865312576,
      -0.07007548213005066,
      0.021171579137444496,
      -0.0019494747975841165,
      0.001252810936421156,
      0.03838774561882019,
      -0.039343561977148056,
      0.03484669700264931,
      0.03405372053384781,
      -0.030010808259248734,
      -0.0644669309258461,
      -0.006387235596776009,
      -0.03530032932758331,
      0.06471522152423859,
      0.07207254320383072,
      -0.009044263511896133,
      -0.056844841688871384,
      -0.06375829130411148,
      0.11572220921516418,
      0.057781707495450974,
      -0.01672966592013836,
      -0.007206845097243786,
      -0.03765714913606644,
      0.026783714070916176,
      0.015334391966462135,
      0.02984508126974106,
      0.04058538377285004,
      -0.029714200645685196,
      -0.007494031451642513,
      -0.009311270900070667,
      -0.019122770056128502,
      0.0195973739027977,
      -0.006716243922710419,
      -0.040105804800987244,
      0.016473187133669853,
      0.06254065036773682,
      0.03351763263344765,
      -0.023130880668759346,
      -0.04585247114300728,
      -0.022823728621006012,
      0.034868378192186356,
      0.08537431061267853,
      0.01372454222291708,
      -0.05454642325639725,
      0.05598796159029007,
      0.007523988839238882,
      0.02898884192109108,
      0.04001862555742264,
      0.006355389021337032,
      -0.004112549591809511,
      -0.05126313865184784,
      0.06855842471122742,
      -0.010107112117111683,
      0.010460802353918552,
      -0.039233431220054626,
      0.050852783024311066,
      -0.05689483508467674,
      0.027934130281209946,
      0.09035327285528183,
      0.032616496086120605,
      0.03611724078655243,
      -0.15078628063201904,
      0.04086346551775932,
      -0.006531182676553726,
      -0.046373285353183746,
      -0.021877065300941467,
      -0.09833713620901108,
      -0.00880382303148508,
      0.03126627951860428,
      -0.016930438578128815,
      0.015314631164073944,
      0.049251824617385864,
      0.04472476989030838,
      0.10116080939769745,
      -0.010068857111036777,
      0.004439624026417732,
      -0.03984224051237106,
      -0.06499391794204712,
      0.007023527752608061,
      -0.06016514450311661,
      -0.03845611587166786,
      -0.002268489683046937,
      -0.03180232644081116,
      0.05073452740907669,
      -0.03949451819062233,
      -0.003020120784640312,
      0.016529658809304237,
      0.01162455789744854,
      0.03466947004199028,
      -0.014311470091342926,
      -0.038026366382837296,
      0.003924416843801737,
      -0.02737623266875744,
      -0.03177981823682785,
      -0.0033608749508857727,
      0.04023846983909607,
      -0.010734856128692627,
      -0.024334650486707687,
      -0.04574906453490257,
      0.025262733921408653,
      -0.0064085377380251884,
      -0.05253458768129349,
      0.010313334874808788,
      0.030145736411213875,
      0.003128452692180872,
      -0.03310161456465721,
      -0.014392873272299767,
      -0.05861563980579376,
      0.00446405541151762,
      0.004697905387729406,
      -0.05918534845113754,
      0.010119323618710041,
      0.001865461003035307,
      0.031456779688596725,
      0.038999445736408234,
      -0.04664013907313347,
      -0.017014600336551666,
      0.010060274973511696,
      0.012327843345701694,
      0.04220806807279587,
      -0.08769513666629791,
      0.007370091043412685,
      0.019419657066464424,
      0.015568950213491917,
      -0.009986801072955132,
      0.02757398784160614,
      0.002820920431986451,
      0.0011036483338102698,
      -0.020705845206975937,
      0.04048091173171997,
      0.04126350209116936,
      0.0018804982537403703,
      -0.06101594120264053,
      -0.020322492346167564,
      -0.008234191685914993,
      0.005617101676762104,
      -0.004072501324117184,
      -0.006247844081372023,
      0.050807368010282516,
      -0.014755789190530777,
      -0.03297821804881096,
      0.011853561736643314,
      -0.005535844247788191,
      0.016599303111433983,
      -0.03262530639767647,
      -0.012153182178735733,
      0.0012224329402670264,
      -0.032903168350458145,
      0.061126966029405594,
      -0.12369076162576675,
      0.01436739694327116,
      0.01603778265416622,
      0.03542129695415497,
      0.05001331493258476,
      -0.08588454127311707,
      -0.06806866079568863,
      0.021555736660957336,
      0.021121211349964142,
      -0.006858334876596928,
      0.02466794289648533,
      0.0043531181290745735,
      0.06544005870819092,
      0.032720763236284256,
      0.06815076619386673,
      -0.07794146239757538,
      -0.09846322983503342,
      0.027915246784687042,
      0.005714760161936283,
      0.0338839590549469,
      0.06431888788938522,
      -0.014905169606208801,
      0.03462015837430954,
      0.05662745609879494,
      -0.029976310208439827,
      -0.009134205058217049,
      0.040713340044021606,
      0.026770256459712982,
      0.014957844279706478,
      -0.020361699163913727,
      -0.05587555468082428,
      0.043344348669052124,
      0.0751887783408165,
      -0.03092793934047222,
      -0.012173948809504509,
      -0.05810501053929329,
      0.022460034117102623,
      0.08668997883796692,
      0.04029706120491028,
      0.03547317907214165,
      0.08709926158189774,
      0.013635867275297642,
      -0.012738100253045559,
      -0.04074868559837341,
      -0.027672920376062393,
      -0.024597948417067528,
      0.014847688376903534,
      0.07059011608362198,
      0.0558965764939785,
      -0.008505831472575665,
      0.0632026344537735,
      -0.12710851430892944,
      0.03515976294875145,
      -0.01736416295170784,
      -0.028062716126441956,
      -0.057207223027944565,
      -0.027264365926384926,
      -0.018532266840338707,
      -0.01098962128162384,
      0.06317352503538132,
      -0.04735923185944557,
      -0.0182834230363369,
      -0.034722570329904556,
      0.012652412988245487,
      -0.03204471617937088,
      0.003541077021509409,
      0.013272440060973167,
      -0.072771817445755,
      -0.06876178830862045,
      -0.023432476446032524,
      -0.014752634800970554,
      0.01861838437616825,
      0.12144748121500015,
      -0.01589326746761799,
      -0.022393425926566124,
      -0.025775695219635963,
      -0.05875682458281517,
      -0.009489079006016254,
      0.04733692482113838,
      0.06478330492973328,
      0.010977571830153465,
      -0.01863180287182331,
      0.057126227766275406,
      0.030049597844481468,
      0.05427372083067894,
      -0.010508409701287746,
      -0.03218744695186615,
      0.03605949506163597,
      0.007443744223564863,
      0.00108254118822515,
      -0.00949704460799694,
      0.11739804595708847
    ],
    [
      -0.04898902028799057,
      -0.03676961362361908,
      0.015130233950912952,
      -0.0309100653976202,
      0.08828844130039215,
      0.0322529561817646,
      -0.04937130585312843,
      -0.002635032869875431,
      -0.013808089308440685,
      -0.062001727521419525,
      0.005675446707755327,
      -0.022217031568288803,
      0.027645397931337357,
      0.019549958407878876,
      -0.03534742444753647,
      -0.0396716333925724,
      0.013803689740598202,
      0.010121246799826622,
      -0.01744581013917923,
      -0.03753802552819252,
      -0.03268960490822792,
      0.02213912084698677,
      -0.024251503869891167,
      -0.04866606369614601,
      0.06237183138728142,
      -0.03428845480084419,
      -0.02129608392715454,
      0.03926394507288933,
      -0.014726229943335056,
      -0.038546305149793625,
      -0.008959569036960602,
      0.0450475849211216,
      0.062370412051677704,
      0.05095915123820305,
      -0.0586213693022728,
      -0.05641796439886093,
      -0.05088024586439133,
      0.028252143412828445,
      -0.012461181730031967,
      -0.020045319572091103,
      0.008988055400550365,
      0.0146288787946105,
      -0.004477597773075104,
      -0.021589212119579315,
      0.010876234620809555,
      0.05369050055742264,
      0.04937220364809036,
      -0.046791158616542816,
      0.026088442653417587,
      0.022218119353055954,
      0.06271060556173325,
      0.056359101086854935,
      0.09145833551883698,
      -0.10328980535268784,
      -0.0894097164273262,
      0.08684176206588745,
      0.0468553863465786,
      -0.09734392911195755,
      0.0728832259774208,
      -0.0351179875433445,
      0.03073924034833908,
      -0.005588212050497532,
      0.0368167906999588,
      0.04043335095047951,
      -0.10636141896247864,
      0.04784607142210007,
      0.042387284338474274,
      -0.0012209665728732944,
      0.0671083927154541,
      -0.08533454686403275,
      -0.015062977559864521,
      -0.01969047822058201,
      0.03517564386129379,
      0.00776582071557641,
      -0.02869352139532566,
      -0.01753460243344307,
      -0.013611746951937675,
      -0.04394380748271942,
      0.012885238975286484,
      0.056400641798973083,
      -0.0063094827346503735,
      0.014474918134510517,
      -0.038731254637241364,
      0.031194034963846207,
      -0.016376735642552376,
      -0.004638166166841984,
      -0.09310252219438553,
      -0.050780829042196274,
      0.030449330806732178,
      0.0014232463436201215,
      0.06543660163879395,
      0.012221171520650387,
      0.016057556495070457,
      -0.01585228368639946,
      0.04611828550696373,
      -0.03783116862177849,
      0.06573162972927094,
      -0.006454588379710913,
      -0.05625506117939949,
      0.002177003538236022,
      -0.06095442175865173,
      -0.0077506559900939465,
      0.1623266637325287,
      0.04069901257753372,
      0.00792604684829712,
      -0.016539443284273148,
      0.031050078570842743,
      -0.06933103501796722,
      0.04642818868160248,
      -0.0976373627781868,
      -0.05406118184328079,
      0.05357348918914795,
      0.03878222778439522,
      -0.03627963736653328,
      -0.015522871166467667,
      -0.05797309800982475,
      0.0594610758125782,
      -0.020698655396699905,
      -0.02062636986374855,
      -0.057835642248392105,
      -0.07891155034303665,
      0.008318443782627583,
      0.010896370746195316,
      -0.05696529895067215,
      0.0013525683898478746,
      0.047851622104644775,
      0.005768385250121355,
      -0.03476647287607193,
      0.15208430588245392,
      0.06510921567678452,
      0.0387599840760231,
      0.015991639345884323,
      0.008342323824763298,
      -0.050071485340595245,
      -0.02788095362484455,
      -0.015399130992591381,
      0.04965522885322571,
      0.018114285543560982,
      -0.023181993514299393,
      0.06170320138335228,
      0.05829690769314766,
      -0.007894325070083141,
      -0.03327590599656105,
      -0.009648791514337063,
      -0.00730103300884366,
      -0.018709687516093254,
      0.03284595161676407,
      0.028943337500095367,
      0.0021975915879011154,
      -0.005532938055694103,
      -0.06772404164075851,
      0.049847040325403214,
      -0.02697076089680195,
      -0.013789445161819458,
      0.03416016697883606,
      0.028981776908040047,
      -0.08201853185892105,
      -0.009229703806340694,
      0.03414176031947136,
      0.002042264211922884,
      0.05709667131304741,
      0.01565598137676716,
      -0.006235439796000719,
      0.023024428635835648,
      -0.06259072571992874,
      -0.02531045489013195,
      -0.08748040348291397,
      -0.017157353460788727,
      0.017194924876093864,
      -0.042519424110651016,
      0.012630784884095192,
      0.02628342993557453,
      0.025461606681346893,
      0.008255218155682087,
      0.06991676986217499,
      0.04311642423272133,
      0.026775412261486053,
      0.022778986021876335,
      0.0005992324440740049,
      -0.07273728400468826,
      0.019013185054063797,
      0.01670251227915287,
      0.06397609412670135,
      -0.05972382798790932,
      0.020308956503868103,
      -0.019180480390787125,
      0.03658229857683182,
      0.025512635707855225,
      0.026519743725657463,
      -0.020276978611946106,
      0.013754511252045631,
      0.020717382431030273,
      -0.008368239738047123,
      0.0003499778395052999,
      -0.009771564044058323,
      -0.03514862805604935,
      0.0036632779520004988,
      -0.021750424057245255,
      0.04780840501189232,
      -0.022070134058594704,
      -0.013160968199372292,
      0.009635885246098042,
      0.05249100923538208,
      0.06367957592010498,
      -0.08176624774932861,
      -0.02345086820423603,
      -0.018992235884070396,
      0.016117775812745094,
      -0.029279977083206177,
      -0.006426735781133175,
      0.02396358922123909,
      -0.05165707692503929,
      -0.09749781340360641,
      0.044836219400167465,
      -0.0488160103559494,
      0.0026266395580023527,
      0.0037190315779298544,
      -0.10190802067518234,
      0.0018059994326904416,
      0.094402976334095,
      0.05292466655373573,
      0.030597088858485222,
      0.013835208490490913,
      -0.10355176031589508,
      -0.04826172813773155,
      -0.08097295463085175,
      0.11375745385885239,
      -0.013478307984769344,
      -0.0017375336028635502,
      -0.00023200077703222632,
      0.018772387877106667,
      0.02613956108689308,
      0.0008958051330409944,
      -0.10533295571804047,
      -0.022832021117210388,
      0.07911070436239243,
      -0.011923149228096008,
      0.015325531363487244,
      0.012670275755226612,
      -0.06092660501599312,
      -0.03579538315534592,
      0.08787762373685837,
      0.05544236674904823,
      -0.11898098886013031,
      0.03324688598513603,
      -0.02477075159549713,
      -0.005960846319794655,
      -0.05119147151708603,
      0.07875286787748337,
      0.03743454068899155,
      -0.02343803457915783,
      0.011348684318363667,
      0.01710391975939274,
      -0.0021489986684173346,
      0.03045283444225788,
      0.04442315548658371,
      0.0014447810826823115,
      0.05778774246573448,
      -0.051304858177900314,
      0.051792364567518234,
      -0.12705613672733307,
      -0.11936034262180328,
      0.003223117208108306,
      0.034728921949863434,
      0.023237861692905426,
      0.010639509186148643,
      -0.0007138008368201554,
      0.02952256053686142,
      0.02621481940150261,
      -0.01339634321630001,
      -0.01905183680355549,
      -0.0386338084936142,
      0.07529616355895996,
      -0.03184518963098526,
      -0.003732147393748164,
      -0.03376982733607292,
      -0.10705998539924622,
      0.008062167093157768,
      -0.049604061990976334,
      -0.020063139498233795,
      -0.005720385815948248,
      -0.026937315240502357,
      0.02106085792183876,
      0.057740334421396255,
      0.06968312710523605,
      -0.033055927604436874,
      0.04884942248463631,
      0.01033991202712059,
      0.038130491971969604,
      -0.014956530183553696,
      0.036200717091560364,
      0.03294830024242401,
      -0.03125132992863655,
      0.04949449747800827,
      -0.004714815877377987,
      0.003772190771996975,
      0.023414665833115578,
      0.025111444294452667,
      -0.053282104432582855,
      -0.0697723925113678,
      0.09601721167564392,
      -0.016710015013813972,
      -0.053061340004205704,
      -0.012970274314284325,
      -0.04801502078771591,
      -0.007297075353562832,
      0.013013233430683613,
      -0.08888845145702362,
      0.0822317898273468,
      0.14942307770252228,
      -0.02724325656890869,
      0.03121306747198105,
      0.011627217754721642,
      -0.023011138662695885,
      -0.019910616800189018,
      -0.029317276552319527,
      0.022251715883612633,
      0.02461874485015869,
      0.07235481590032578,
      -0.03468001261353493,
      0.09239567071199417,
      0.01754949241876602,
      -0.010223403573036194,
      -0.17330004274845123,
      0.044550471007823944,
      0.012857506982982159,
      0.01623811013996601,
      -0.03562372550368309,
      -0.043555933982133865,
      -0.006378771271556616,
      0.07187043130397797,
      -0.02881140634417534,
      -0.042008399963378906,
      -0.0031517420429736376,
      -0.025196077302098274,
      -0.06865896284580231,
      0.037121593952178955,
      0.03996912017464638,
      0.01201559416949749,
      -0.0057092648930847645,
      -0.03785034269094467,
      -0.013566449284553528,
      0.07062751799821854,
      -0.11481409519910812,
      -0.07169954478740692,
      -0.09244965761899948,
      -0.0448385626077652,
      -0.010105275548994541,
      -0.05066010355949402,
      0.004894021898508072,
      0.028520260006189346,
      -0.04615002125501633,
      -0.0031802733428776264,
      0.05145203694701195,
      0.03231675550341606,
      -0.02672274224460125,
      -0.05593525990843773,
      -0.003359671914950013,
      0.008135650306940079,
      0.07177882641553879,
      -0.02136007323861122,
      0.06803715974092484,
      0.019245989620685577,
      -0.09064637869596481,
      -0.04122953116893768,
      0.007974217645823956,
      0.05504798889160156,
      0.026536084711551666,
      -0.05815231055021286,
      0.07207948714494705,
      -0.030445415526628494,
      0.10643716901540756,
      -0.08817433565855026,
      -0.05406739562749863,
      -0.0004400848411023617,
      0.07431317120790482,
      0.05055149272084236,
      0.005193585529923439,
      -0.012993249110877514,
      -0.033491216599941254,
      -0.004362589679658413,
      -0.0035686346236616373,
      0.03710317984223366,
      0.0813780128955841,
      0.028554322198033333,
      -0.07752188295125961,
      0.020864954218268394,
      0.0502779521048069,
      0.10889825969934464,
      -0.029498009011149406,
      0.0878584012389183,
      -0.024005742743611336,
      -0.018284277990460396,
      0.052097391337156296,
      -0.06764274835586548,
      0.04651279374957085,
      -0.0036940029822289944,
      -0.054251447319984436,
      -0.05681203678250313,
      0.00877340603619814,
      -0.056172337383031845,
      -0.02917773462831974,
      0.03344416245818138,
      -0.0026313536800444126,
      -0.01030964870005846,
      0.04069472849369049,
      0.08598250150680542,
      -0.0128449946641922,
      -0.03105253353714943,
      -0.012033761478960514,
      0.030263058841228485,
      -0.08961375802755356,
      0.08010051399469376,
      -0.01994568668305874,
      0.08521591871976852,
      -0.030834831297397614,
      -0.010085522197186947,
      0.007432638201862574,
      0.011309662833809853,
      -0.09678200632333755,
      -0.03216196596622467,
      0.08053278923034668,
      0.05196287855505943,
      -0.010316708125174046,
      -0.007347777485847473,
      0.014046939089894295,
      0.050229012966156006,
      -0.08082171529531479,
      0.06780867278575897,
      0.0050013926811516285,
      -0.013200980611145496,
      -0.03771534189581871,
      -0.03246084228157997,
      -0.004516665358096361,
      0.041732460260391235,
      -0.021740738302469254,
      -0.03308320790529251,
      0.07143127918243408,
      -0.036504797637462616,
      0.027784574776887894,
      0.013569947332143784,
      0.001619974267669022,
      -0.06255924701690674,
      -0.03963754326105118,
      0.053158365190029144,
      0.015521190129220486,
      -0.00769473472610116,
      -0.06095510348677635,
      0.013937505893409252,
      -0.09537654370069504,
      -0.050102461129426956,
      0.027046065777540207,
      0.030568303540349007,
      -0.0002923241991084069,
      0.041880398988723755,
      -0.07068464159965515,
      0.07403819262981415,
      -0.07709313184022903,
      -0.07106193155050278,
      0.0026612121146172285,
      -0.07717448472976685,
      0.017830347642302513,
      -0.0673806220293045,
      -0.02360827848315239,
      -0.08948992192745209,
      -0.024506861343979836,
      -0.039780013263225555,
      0.00012505639460869133,
      0.04685598239302635,
      -0.004479407332837582,
      -0.11567345261573792,
      0.08148106932640076,
      0.06406669318675995,
      -0.0029140268452465534,
      0.03127947822213173,
      0.08502352982759476,
      0.03756382688879967,
      0.010899493470788002,
      -0.04605245217680931,
      -0.01713232323527336,
      0.02427063323557377,
      -0.019541563466191292,
      -0.02272603288292885,
      0.021211162209510803,
      0.019403384998440742,
      -0.021945036947727203,
      0.05350319668650627,
      0.04951493814587593,
      0.0907888188958168,
      -0.03484148532152176,
      0.046009406447410583,
      0.029328281059861183,
      -0.028106240555644035,
      0.03428297117352486,
      0.006210355553776026,
      -0.011436010710895061,
      -0.03341006860136986,
      0.04597384110093117,
      -0.003703067312017083,
      -0.022923091426491737,
      -0.01210812572389841,
      0.020367706194519997,
      -0.011875176802277565
    ],
    [
      0.06097976118326187,
      0.04262937977910042,
      -0.09435588121414185,
      -0.040501631796360016,
      0.08833375573158264,
      0.0671181008219719,
      -0.0015725897392258048,
      0.07787255197763443,
      -0.02994791977107525,
      -0.013656467199325562,
      0.015779748558998108,
      0.031092828139662743,
      -0.0232966560870409,
      0.08071139454841614,
      0.04216276481747627,
      0.0474979467689991,
      -0.0905836895108223,
      0.0650114119052887,
      0.07151538878679276,
      0.032309696078300476,
      -0.005175793077796698,
      0.02254726178944111,
      0.02048325724899769,
      0.009886622428894043,
      -0.023869428783655167,
      -0.029893815517425537,
      0.08382932841777802,
      0.10196630656719208,
      -0.008869723416864872,
      -0.05493629351258278,
      0.052877601236104965,
      0.014104356057941914,
      0.12854637205600739,
      -0.017400939017534256,
      0.004625883884727955,
      -0.053905315697193146,
      -0.0017935626674443483,
      0.03916670009493828,
      0.06309614330530167,
      0.024412214756011963,
      0.01311566773802042,
      0.08509637415409088,
      0.021830210462212563,
      0.028711190447211266,
      -0.04202420637011528,
      -0.061596162617206573,
      0.05272967368364334,
      -0.008110132068395615,
      -0.031944867223501205,
      -0.014042651280760765,
      -0.04952974244952202,
      0.06228244677186012,
      -0.009609346278011799,
      -0.07393962144851685,
      0.018270937725901604,
      -0.001524565857835114,
      0.12736867368221283,
      -0.045250214636325836,
      -0.039058007299900055,
      -0.02808823250234127,
      0.044039469212293625,
      -0.016222767531871796,
      -0.023359540849924088,
      0.07775995135307312,
      0.004902377724647522,
      -0.009392895735800266,
      0.050096623599529266,
      -0.008498767390847206,
      0.02122720144689083,
      -0.10050994902849197,
      -0.03690946102142334,
      0.028238574042916298,
      0.04674682393670082,
      -0.026306690648198128,
      0.044732023030519485,
      -0.05131273716688156,
      0.01895865797996521,
      -0.017466364428400993,
      -0.0750439316034317,
      0.01799909770488739,
      0.02335638925433159,
      -0.020267099142074585,
      -0.02388613484799862,
      -0.03698377311229706,
      0.0002778385824058205,
      -0.012531764805316925,
      -0.06309901922941208,
      -0.009060081094503403,
      -0.0016369872028008103,
      -0.09101514518260956,
      0.04015820845961571,
      0.10550238192081451,
      0.04453002288937569,
      -0.04481527581810951,
      -0.005425537936389446,
      0.13002127408981323,
      -0.017902754247188568,
      0.019817162305116653,
      0.03390088304877281,
      -0.017557941377162933,
      -0.032946452498435974,
      -0.03623662516474724,
      -0.022460807114839554,
      0.007793520111590624,
      -0.044140417128801346,
      -0.026368584483861923,
      0.012240568175911903,
      0.007879771292209625,
      -0.055555954575538635,
      -0.07298658043146133,
      0.07260532677173615,
      0.016943063586950302,
      0.07914531975984573,
      0.06595325469970703,
      -0.011612317524850368,
      0.05485966056585312,
      0.006074611097574234,
      -0.001454740995541215,
      0.024994133040308952,
      0.025326933711767197,
      0.06537575274705887,
      -0.07022462785243988,
      -0.008414995856583118,
      -0.010378013364970684,
      0.0025614050682634115,
      0.03337884321808815,
      0.04233873635530472,
      -0.05326266586780548,
      0.01800650730729103,
      0.029294652864336967,
      0.014736062847077847,
      0.013531956821680069,
      0.036984771490097046,
      0.03018493950366974,
      -0.031489163637161255,
      -0.026735689491033554,
      -0.03050721436738968,
      -0.07819193601608276,
      -0.027548030018806458,
      0.08149393647909164,
      -0.01936347968876362,
      -0.033434588462114334,
      0.09543366730213165,
      -0.05359983816742897,
      -0.0060486760921776295,
      0.03300027176737785,
      0.018738793209195137,
      0.05752335488796234,
      -0.03729129210114479,
      -0.04969349130988121,
      -0.016328344121575356,
      0.06082930788397789,
      -0.005789625458419323,
      -0.06300093233585358,
      0.037690307945013046,
      -0.05624162778258324,
      -0.07965150475502014,
      -0.01944730617105961,
      -0.05630332976579666,
      0.05403025820851326,
      -0.02574959769845009,
      0.009009269997477531,
      0.0008657755679450929,
      0.036206718534231186,
      0.02036869153380394,
      -0.018906112760305405,
      -0.11843781918287277,
      -0.05938667058944702,
      -0.08845137804746628,
      0.06322310864925385,
      0.00403599115088582,
      0.030975596979260445,
      -0.030091792345046997,
      0.004543950315564871,
      0.03325239568948746,
      -0.00706084119156003,
      -0.004351888317614794,
      -0.051259301602840424,
      0.035640228539705276,
      -0.020244350656867027,
      0.0322810560464859,
      0.04665593430399895,
      -0.057325951755046844,
      -0.04124806448817253,
      -0.05580056831240654,
      0.05699679255485535,
      0.00976349227130413,
      0.059483036398887634,
      -0.06017811968922615,
      0.043006811290979385,
      -0.009089851751923561,
      0.010153728537261486,
      -0.05402972176671028,
      -0.02016146294772625,
      0.034625861793756485,
      -0.00435449555516243,
      0.014654483646154404,
      -0.02079637534916401,
      -0.03281991928815842,
      -0.0022329941857606173,
      0.08462107926607132,
      -0.1002207100391388,
      -0.011286327615380287,
      0.030285203829407692,
      -0.04298380762338638,
      0.015326245687901974,
      0.0755198746919632,
      -0.01705506630241871,
      -0.01342085376381874,
      0.03834562376141548,
      -0.01940143294632435,
      -0.04452119022607803,
      -0.050607629120349884,
      0.012496023438870907,
      -0.02125067077577114,
      0.05384105443954468,
      -0.04367876052856445,
      0.020097525790333748,
      -0.002875549951568246,
      -0.05664985999464989,
      0.02591765485703945,
      0.028259320184588432,
      -0.08562605082988739,
      -0.03582288324832916,
      0.017379645258188248,
      0.02612350694835186,
      -0.07629159092903137,
      -0.04390474781394005,
      -0.03057652898132801,
      0.03940844535827637,
      -0.05308777093887329,
      0.00429811654612422,
      0.023861531168222427,
      0.060478802770376205,
      -0.027587542310357094,
      -0.006861655041575432,
      0.03456714004278183,
      -0.05374152585864067,
      -0.009059934876859188,
      -0.009532270021736622,
      -0.051754727959632874,
      -0.024598149582743645,
      -0.030195197090506554,
      0.05499132722616196,
      -0.04517776146531105,
      -0.023727845400571823,
      -0.02702769637107849,
      -0.04551441967487335,
      -0.005367164500057697,
      0.03961414098739624,
      -0.024042874574661255,
      0.0406467467546463,
      -0.021676477044820786,
      0.03692687302827835,
      0.019896693527698517,
      0.01327851228415966,
      -0.003548666834831238,
      0.02700689621269703,
      -0.010152284987270832,
      0.019208593294024467,
      0.02267211675643921,
      -0.038744617253541946,
      0.02611710876226425,
      0.0055123730562627316,
      0.023980559781193733,
      -0.0013451093109324574,
      -0.0078886142000556,
      0.006712597329169512,
      0.03124959208071232,
      0.060620538890361786,
      0.00417732261121273,
      0.003906650003045797,
      -0.019627686589956284,
      0.05154413729906082,
      0.01429285854101181,
      -0.005322631914168596,
      -0.02239280566573143,
      0.008074632845818996,
      -0.0783289447426796,
      0.04698837921023369,
      -0.0020219990983605385,
      0.034427594393491745,
      0.0504252053797245,
      -0.034302446991205215,
      -0.04466963931918144,
      0.007582416292279959,
      -0.0583781823515892,
      0.009046979248523712,
      0.09510685503482819,
      -0.04461604729294777,
      -0.005071317311376333,
      0.09631045907735825,
      0.04555382579565048,
      -0.017674706876277924,
      -0.012751257047057152,
      -0.03684769570827484,
      -0.008421857841312885,
      -0.04731117933988571,
      -0.07951898872852325,
      0.05555496737360954,
      0.05846245959401131,
      0.002859833650290966,
      0.035440560430288315,
      0.09703986346721649,
      0.08065353333950043,
      -0.02256096340715885,
      0.02319193072617054,
      -0.014086194336414337,
      0.05216912180185318,
      0.032538000494241714,
      0.1116076409816742,
      0.01779751293361187,
      -0.006731587462127209,
      -0.023303065448999405,
      0.017883120104670525,
      0.099943146109581,
      0.06056806817650795,
      -0.003663588548079133,
      -0.028025668114423752,
      -0.05695252865552902,
      0.02281992696225643,
      -0.013186819851398468,
      0.01941794902086258,
      -0.020713606849312782,
      0.03109641931951046,
      0.005738420411944389,
      -0.0871896892786026,
      -0.07514391094446182,
      -0.08358117192983627,
      0.015021772123873234,
      0.05011613667011261,
      -0.04545019939541817,
      -0.0159351397305727,
      0.06905324757099152,
      0.02748185023665428,
      -0.06273476779460907,
      0.08175565302371979,
      -0.001483574160374701,
      0.05846385285258293,
      0.041519228368997574,
      0.014234497211873531,
      -0.025857683271169662,
      -0.030959831550717354,
      -0.018748393282294273,
      -0.041864894330501556,
      -0.07476585358381271,
      -0.032782670110464096,
      -0.03676752373576164,
      -0.011882346123456955,
      -0.04001997038722038,
      0.04379315674304962,
      -0.0005077754613012075,
      -0.032879915088415146,
      0.005433512851595879,
      -0.07023511081933975,
      0.025507265701889992,
      -0.017344677820801735,
      -0.03999797999858856,
      -0.05448359623551369,
      -0.0135278245434165,
      0.11481357365846634,
      -0.0019592070020735264,
      0.016572346910834312,
      -0.02791840024292469,
      -0.02800174057483673,
      0.07149761915206909,
      0.006584873888641596,
      -0.04464021697640419,
      0.01752055622637272,
      -0.029425179585814476,
      -0.06224105507135391,
      0.10754355043172836,
      -0.05354663357138634,
      0.03761938586831093,
      -0.031794559210538864,
      -0.01487076934427023,
      0.018760977312922478,
      0.015915097668766975,
      -0.00438910024240613,
      0.0020309684332460165,
      -0.04282668977975845,
      0.0649302750825882,
      -0.06736409664154053,
      0.010554506443440914,
      0.03288513794541359,
      0.04320363327860832,
      -0.03164989873766899,
      0.10098482668399811,
      0.04648125171661377,
      0.029990172013640404,
      -0.0047044288367033005,
      -0.013239576481282711,
      0.027562608942389488,
      -0.07402409613132477,
      0.007643344812095165,
      0.013532166369259357,
      0.022623689845204353,
      0.021400086581707,
      0.05430833250284195,
      0.02748560532927513,
      0.056059397757053375,
      -0.018954303115606308,
      0.01737985573709011,
      0.06858912110328674,
      0.01773119531571865,
      0.08573345094919205,
      0.037236955016851425,
      -0.006373997777700424,
      -0.05421275272965431,
      -0.0018452225485816598,
      0.10436399281024933,
      -0.03208990395069122,
      -0.025256311520934105,
      -0.05664542317390442,
      -0.022944185882806778,
      0.040516674518585205,
      -0.010727226734161377,
      0.12023547291755676,
      0.0900217592716217,
      -0.05099980905652046,
      -0.02155817486345768,
      -0.04036600887775421,
      -0.09606633335351944,
      -0.040797993540763855,
      -0.026012971997261047,
      0.08149418234825134,
      -0.029910273849964142,
      0.000510709302034229,
      0.06627242267131805,
      0.07999736815690994,
      -0.017619453370571136,
      0.060346007347106934,
      -0.042855631560087204,
      -0.006819324567914009,
      -0.03529081121087074,
      -0.06199857220053673,
      -0.07257649302482605,
      -0.10005037486553192,
      -0.023059876635670662,
      0.07138890027999878,
      -0.0031705955043435097,
      -0.06568899005651474,
      0.006269311998039484,
      -0.027602020651102066,
      -0.01575460471212864,
      0.032488469034433365,
      -0.05355484411120415,
      -0.022246094420552254,
      -0.059974025934934616,
      0.01150685828179121,
      0.012286389246582985,
      0.07095707207918167,
      0.06827159970998764,
      0.05568215250968933,
      -0.009923988953232765,
      -0.031130829825997353,
      -0.025515399873256683,
      0.02860894240438938,
      0.06464050710201263,
      -0.011519188992679119,
      -0.01595321297645569,
      0.026445265859365463,
      -0.05677998065948486,
      -0.07101374119520187,
      -0.1367967277765274,
      -0.08528812229633331,
      0.007169090211391449,
      0.03794659301638603,
      0.018253866583108902,
      -0.007302183657884598,
      -0.06461596488952637,
      0.04493262246251106,
      0.06971516460180283,
      0.03930309787392616,
      -0.09995689988136292,
      0.0024021402932703495,
      -0.0590088851749897,
      -0.008848960511386395,
      -0.018392397090792656,
      0.1237698569893837,
      -0.041178613901138306,
      -0.12659402191638947,
      -0.04705159738659859,
      0.06811434030532837,
      -0.015704941004514694,
      -0.022702382877469063,
      0.023788411170244217,
      -0.013992851600050926,
      0.007123677060008049,
      -0.020557980984449387,
      0.0749308168888092,
      0.050217170268297195,
      0.004931196104735136,
      0.007827875204384327,
      -0.01504415925592184,
      -0.0354180708527565,
      0.05172847956418991,
      -0.04729512333869934,
      -0.011618724092841148,
      -0.007217369973659515,
      -0.0005592423258349299,
      0.020989790558815002,
      -0.027453798800706863
    ],
    [
      -0.001622576150111854,
      0.034610066562891006,
      0.06064489856362343,
      0.026943083852529526,
      0.018393240869045258,
      -0.026354527100920677,
      -0.08550302684307098,
      -0.0025585342664271593,
      -0.051919542253017426,
      0.042842697352170944,
      -0.06467951089143753,
      -0.02178909443318844,
      0.015613159164786339,
      -0.009292603470385075,
      -0.04250103980302811,
      0.02177913300693035,
      0.03486110270023346,
      0.026202883571386337,
      0.02241457998752594,
      -0.04891175776720047,
      0.030834177508950233,
      0.04846755415201187,
      0.050239142030477524,
      0.04297178238630295,
      -0.007916397415101528,
      0.003453456796705723,
      0.03616833686828613,
      0.06948311626911163,
      0.00010520545765757561,
      0.007769899442791939,
      0.06974544376134872,
      0.015131350606679916,
      0.09083447605371475,
      0.09655389189720154,
      0.053561341017484665,
      -0.1086813360452652,
      -0.009644131176173687,
      -0.047777313739061356,
      -0.005535603035241365,
      0.0015926468186080456,
      -0.02536691725254059,
      -0.014056575484573841,
      -0.020655997097492218,
      0.0017473282059654593,
      -0.014407032169401646,
      0.02434968575835228,
      -0.02014559879899025,
      -0.03895274177193642,
      0.03977184370160103,
      -0.11888919025659561,
      -0.011234836652874947,
      0.060580674558877945,
      -0.022038917988538742,
      0.0816512182354927,
      -0.020483195781707764,
      0.017188724130392075,
      0.05441379174590111,
      0.06738151609897614,
      -0.06387905776500702,
      -0.019836943596601486,
      0.02274758741259575,
      0.05262470245361328,
      0.045061271637678146,
      -0.020395265892148018,
      0.02833019569516182,
      0.01293895673006773,
      0.03519761934876442,
      -0.04242262244224548,
      -0.024411149322986603,
      -0.07395380735397339,
      -0.01861431822180748,
      -0.024128980934619904,
      0.013138820417225361,
      -0.007518723141402006,
      -0.009971323423087597,
      0.009695875458419323,
      -0.09569992870092392,
      -0.05449575558304787,
      -0.08318235725164413,
      0.020426839590072632,
      0.04020202159881592,
      0.037142541259527206,
      -0.011022440157830715,
      -0.022044247016310692,
      -0.018313728272914886,
      0.04554888233542442,
      0.013560355640947819,
      -0.029007405042648315,
      0.015413505956530571,
      -0.02084045112133026,
      0.08549979329109192,
      0.028940312564373016,
      -0.08105967193841934,
      -0.03930700570344925,
      -0.04831609129905701,
      0.0650453194975853,
      0.019718525931239128,
      -0.06142300367355347,
      -0.007896550931036472,
      0.026962315663695335,
      -0.004011652898043394,
      -0.018299845978617668,
      -0.0058149173855781555,
      -0.045380886644124985,
      -0.04614710435271263,
      0.019803110510110855,
      0.05227750539779663,
      0.014005307108163834,
      -0.009266940876841545,
      0.005775860045105219,
      0.03441270440816879,
      0.03181425854563713,
      -0.024015018716454506,
      -0.03973252326250076,
      -0.0441221222281456,
      0.05080443248152733,
      0.049927011132240295,
      0.05591370165348053,
      0.023466337472200394,
      -0.009603528305888176,
      -0.0026836153119802475,
      -0.011939981020987034,
      -0.03128303959965706,
      -0.09574411064386368,
      0.022463226690888405,
      -0.03275206685066223,
      -0.09364549815654755,
      -0.03875615447759628,
      -0.011456312611699104,
      -0.01306662056595087,
      -0.019476749002933502,
      0.027340998873114586,
      0.0521555095911026,
      -0.01055336557328701,
      -0.086397185921669,
      0.004521828144788742,
      -0.04021892696619034,
      -0.05910644680261612,
      -0.016622794792056084,
      0.08450755476951599,
      0.005997991189360619,
      0.015254292637109756,
      0.05985331907868385,
      -0.09930522739887238,
      -0.08070261031389236,
      0.04215363413095474,
      0.08782344311475754,
      -0.04803209379315376,
      0.0718071460723877,
      0.022277917712926865,
      0.04458366706967354,
      0.04168182983994484,
      -0.029790028929710388,
      -0.017099060118198395,
      -0.03834111616015434,
      0.004453309811651707,
      -0.013601281680166721,
      -0.015699900686740875,
      0.01653130352497101,
      0.03543474152684212,
      -0.004742653574794531,
      -0.07693912833929062,
      -0.0076284147799015045,
      -0.07135294377803802,
      -0.039803627878427505,
      -0.053978390991687775,
      -0.027660835534334183,
      0.010097704827785492,
      -0.05534904822707176,
      0.06320898234844208,
      -0.010901701636612415,
      -0.009102887474000454,
      0.03445269539952278,
      -0.02365197241306305,
      -0.01134402584284544,
      -0.051845483481884,
      -0.07835406810045242,
      0.018814263865351677,
      0.02478845603764057,
      -0.07044631987810135,
      0.026228949427604675,
      0.05077199637889862,
      -0.007469325326383114,
      -0.04691985622048378,
      -0.0793783962726593,
      -0.03377671539783478,
      0.00820203498005867,
      -0.08107894659042358,
      0.08069863170385361,
      0.08313880115747452,
      -0.031053123995661736,
      -0.08526611328125,
      0.06119203940033913,
      0.004238073248416185,
      0.036207035183906555,
      0.025167223066091537,
      0.04474927857518196,
      0.020312782377004623,
      0.10744252055883408,
      -0.02490551583468914,
      0.052842527627944946,
      -0.040565721690654755,
      -0.13536378741264343,
      0.06653019040822983,
      -0.07157139480113983,
      -0.03504766896367073,
      0.029297668486833572,
      0.0510765016078949,
      0.05921172350645065,
      0.025443444028496742,
      0.013198528438806534,
      -0.037632811814546585,
      -0.03184818848967552,
      -0.04883135110139847,
      -0.036491163074970245,
      0.07589485496282578,
      0.01110003236681223,
      -0.006390263792127371,
      0.013207667507231236,
      0.02227775752544403,
      0.06719720363616943,
      0.007563748396933079,
      0.016183676198124886,
      -0.07617666572332382,
      0.04705021157860756,
      -0.02033303678035736,
      -0.0633758157491684,
      0.018345454707741737,
      -0.018843185156583786,
      -0.07393530756235123,
      0.044804465025663376,
      0.008570915088057518,
      -0.11103037744760513,
      -0.04067378491163254,
      0.012977644801139832,
      -0.003581981174647808,
      0.05638255923986435,
      0.019856806844472885,
      0.04329374432563782,
      -0.1138492152094841,
      0.009358251467347145,
      -0.01076539047062397,
      -0.06491369754076004,
      0.035053618252277374,
      0.04667166620492935,
      -0.005929283332079649,
      0.019101707264780998,
      -0.00460667023435235,
      0.021401116624474525,
      0.0551961287856102,
      0.01097636204212904,
      -0.013032233342528343,
      0.08150675147771835,
      0.08013161271810532,
      0.056009646505117416,
      -0.018671873956918716,
      -0.1103368028998375,
      0.041533488780260086,
      -0.02474021166563034,
      0.0009639890631660819,
      -0.016843711957335472,
      -0.13857316970825195,
      -0.04214755818247795,
      -0.04343179613351822,
      0.016688961535692215,
      -0.056310515850782394,
      -0.009730915538966656,
      -0.034812722355127335,
      0.050474267452955246,
      -0.049425043165683746,
      0.07443959265947342,
      -0.06977459043264389,
      -0.05772373080253601,
      -0.056773409247398376,
      -0.018269337713718414,
      -0.011279868893325329,
      0.0035656618420034647,
      0.06505690515041351,
      -0.02223796956241131,
      0.009761815890669823,
      0.07530619949102402,
      0.036747828125953674,
      -0.0029560993425548077,
      0.030054505914449692,
      0.0007707886397838593,
      0.008852587081491947,
      0.0929507464170456,
      0.03930389881134033,
      0.007050747983157635,
      -0.029728198423981667,
      0.026456434279680252,
      0.006276697386056185,
      -0.054314255714416504,
      0.0579209066927433,
      -0.07037156820297241,
      -0.008691779337823391,
      0.075954370200634,
      0.013277946971356869,
      -0.02626090869307518,
      -0.01638851873576641,
      -0.03145685791969299,
      0.048133622854948044,
      0.039141856133937836,
      -0.053416237235069275,
      0.0498257540166378,
      0.0691227838397026,
      0.023993290960788727,
      0.030466973781585693,
      0.007750369142740965,
      0.06109260767698288,
      -0.007634051144123077,
      -0.004799107555299997,
      -0.046710945665836334,
      -0.0008338980260305107,
      0.0388680063188076,
      0.007723976857960224,
      -0.02021879516541958,
      0.01426925789564848,
      0.05912959575653076,
      0.04905068129301071,
      0.0453794002532959,
      0.03288780525326729,
      0.025834528729319572,
      -0.053545087575912476,
      0.006296871230006218,
      0.07662343233823776,
      -0.008077169768512249,
      0.03219737485051155,
      -0.019174041226506233,
      0.033439911901950836,
      0.00552857993170619,
      0.02364382892847061,
      -0.00911758840084076,
      -0.003159248735755682,
      0.024518875405192375,
      -0.025386156514286995,
      0.022813769057393074,
      -0.014485598541796207,
      0.03539543226361275,
      0.008998885750770569,
      -0.006750691216439009,
      0.10234715044498444,
      -0.03561411798000336,
      -0.029800821095705032,
      0.02531888522207737,
      -0.030661053955554962,
      -0.07250736653804779,
      -0.0531209334731102,
      -0.03439083695411682,
      -0.04385458305478096,
      -0.0391555093228817,
      -0.013303820975124836,
      -0.003524499014019966,
      0.03281380236148834,
      -0.05588575452566147,
      -0.016717873513698578,
      -0.09713313728570938,
      0.014256615191698074,
      -0.02118682861328125,
      -0.003841257421299815,
      -0.06560760736465454,
      -0.06635985523462296,
      0.006060870364308357,
      -0.037212759256362915,
      -0.0069479309022426605,
      -0.03956703096628189,
      -0.02499636821448803,
      -0.020626256242394447,
      0.0655207559466362,
      -0.020793570205569267,
      0.09767036885023117,
      0.0107654370367527,
      -0.059004541486501694,
      -0.13871163129806519,
      -0.021337561309337616,
      0.03273405507206917,
      0.005305048078298569,
      0.002441644435748458,
      -0.014500613324344158,
      -0.09537075459957123,
      0.06579393893480301,
      0.046475961804389954,
      -0.07478448003530502,
      0.053438615053892136,
      -0.02406216785311699,
      -0.06893356144428253,
      -0.07635211199522018,
      0.022013690322637558,
      0.04409952834248543,
      -0.054652485996484756,
      0.0430595763027668,
      -0.02533630281686783,
      -0.04699499532580376,
      0.07599391043186188,
      -0.05905665457248688,
      -0.021078936755657196,
      0.04501534253358841,
      0.004552275408059359,
      -0.0453394278883934,
      0.013818325474858284,
      -0.0559791661798954,
      0.03872998058795929,
      0.007592351641505957,
      0.04677033796906471,
      0.08886414021253586,
      0.0016410049283877015,
      -0.07445410639047623,
      0.019549913704395294,
      0.10437338054180145,
      0.04711887985467911,
      0.06393564492464066,
      0.012790965847671032,
      -0.023109540343284607,
      -0.017364608123898506,
      0.030718492344021797,
      -0.0547625795006752,
      -0.05968797579407692,
      0.06540282815694809,
      -0.013330421410501003,
      0.016838565468788147,
      -0.09893752634525299,
      -0.01941208355128765,
      -0.06172240898013115,
      -0.006888371892273426,
      -0.020691407844424248,
      -0.08222714811563492,
      -0.04300066456198692,
      -0.04508058726787567,
      0.04276347905397415,
      -0.004161166027188301,
      0.013042300008237362,
      -0.03417036682367325,
      0.014276496134698391,
      -0.013058250769972801,
      -0.06878283619880676,
      0.027738379314541817,
      0.07299015671014786,
      0.015849238261580467,
      0.03249821811914444,
      -0.014858164824545383,
      -0.03172595798969269,
      -0.006088791880756617,
      0.008730278350412846,
      -0.05467798188328743,
      0.02009611204266548,
      0.015021778643131256,
      -0.11525534093379974,
      -0.10242754220962524,
      -0.05290582776069641,
      0.0732107013463974,
      -0.02100498415529728,
      0.07679357379674911,
      -0.034883394837379456,
      0.09675490856170654,
      0.08860954642295837,
      0.010390613228082657,
      0.02705434150993824,
      0.006813740357756615,
      0.06075138971209526,
      0.031248576939105988,
      0.09935414791107178,
      0.02549409680068493,
      -0.05578060820698738,
      -0.07907038182020187,
      -0.10338830202817917,
      -0.0016661896370351315,
      0.03346183896064758,
      -0.019831473007798195,
      -0.02094551920890808,
      -0.057861823588609695,
      -0.02690146118402481,
      0.01709897443652153,
      0.0033321091905236244,
      -0.04702327772974968,
      0.051523108035326004,
      0.020760685205459595,
      -0.003393711056560278,
      0.06301230937242508,
      0.0005387966521084309,
      0.05466530844569206,
      -0.007604426704347134,
      -0.00846892036497593,
      -0.0927436426281929,
      0.08468928933143616,
      -0.039894431829452515,
      -0.02443460002541542,
      0.017601599916815758,
      -0.09395120292901993,
      -0.011055471375584602,
      0.04301932454109192,
      0.007807069458067417,
      0.08928659558296204,
      -0.020473221316933632,
      -0.030989162623882294,
      -0.030041184276342392,
      -0.00908277090638876,
      0.11263268440961838,
      0.0515822134912014,
      -0.033679161220788956,
      -0.012193758971989155,
      0.051793213933706284,
      -0.043271150439977646,
      -0.02676274999976158
    ],
    [
      0.03589247539639473,
      -0.04582132771611214,
      0.04316571727395058,
      -0.027720067650079727,
      0.12197639793157578,
      0.04482323303818703,
      -0.0025887400843203068,
      0.04519200697541237,
      -0.07012370973825455,
      0.025873709470033646,
      0.04397175461053848,
      -0.024903221055865288,
      0.030383478850126266,
      0.003493329742923379,
      0.03674253076314926,
      -0.05086632817983627,
      -0.032681845128536224,
      0.04754702374339104,
      0.0212823785841465,
      0.04867267608642578,
      0.02976767159998417,
      0.020782053470611572,
      0.057959191501140594,
      -0.037994883954524994,
      0.11168687045574188,
      -0.009899328462779522,
      0.0514037124812603,
      0.009371164254844189,
      -0.05038798972964287,
      -0.06784868985414505,
      0.014211656525731087,
      -0.030240489169955254,
      0.005291699897497892,
      0.04058988019824028,
      -0.1057000532746315,
      -0.06212196871638298,
      -0.06339037418365479,
      -0.03181571140885353,
      -0.0025651247706264257,
      0.08408467471599579,
      -0.008489248342812061,
      -0.02235519327223301,
      -0.05818793550133705,
      -0.012528887949883938,
      -0.04764588922262192,
      0.03264126554131508,
      0.07164382934570312,
      0.006566398777067661,
      0.05536637455224991,
      0.039996061474084854,
      0.008628220297396183,
      0.0673297792673111,
      -0.030710501596331596,
      -0.051646824926137924,
      0.024376677349209785,
      0.013853714801371098,
      0.10534795373678207,
      0.08797677606344223,
      -0.011028743349015713,
      0.03352080285549164,
      0.03902774676680565,
      0.07743002474308014,
      0.005334505345672369,
      0.03807805851101875,
      0.03305693715810776,
      0.00524203572422266,
      0.02014770358800888,
      0.012291518040001392,
      0.01617203839123249,
      -0.003915376961231232,
      0.12574821710586548,
      -0.020187068730592728,
      -0.03608369082212448,
      -0.061732128262519836,
      0.017426718026399612,
      -0.036818090826272964,
      -0.051808930933475494,
      0.01432450395077467,
      0.035626765340566635,
      0.06285121291875839,
      0.00852401927113533,
      -0.04382777959108353,
      0.022306857630610466,
      -0.00763220340013504,
      -0.028046198189258575,
      -0.010187756270170212,
      -0.04766795039176941,
      -0.04805225878953934,
      0.0890171006321907,
      0.0014707386726513505,
      0.12316673994064331,
      -0.0257571991533041,
      0.019856002181768417,
      0.006839977111667395,
      0.027372261509299278,
      -0.01820693351328373,
      0.02384568750858307,
      -0.018908927217125893,
      -0.015653925016522408,
      -0.03090093843638897,
      -0.0172280240803957,
      0.0995711237192154,
      0.046558085829019547,
      -0.029507579281926155,
      0.037146929651498795,
      0.007224305998533964,
      0.04649481177330017,
      -0.015110251493752003,
      0.001889669569209218,
      0.018854334950447083,
      0.1103803962469101,
      -0.01090536080300808,
      -0.05957306548953056,
      0.025975394994020462,
      0.07353981584310532,
      -0.04549291729927063,
      -0.010772829875349998,
      -0.03648044541478157,
      -0.04513446241617203,
      0.04998199641704559,
      -0.09868516027927399,
      0.009013901464641094,
      -0.038093604147434235,
      0.007629535160958767,
      -0.028103673830628395,
      0.0645575225353241,
      -0.08575107157230377,
      -0.06589535623788834,
      0.028942598029971123,
      0.07778671383857727,
      0.02628382295370102,
      0.07092422991991043,
      0.04482930898666382,
      0.044594597071409225,
      -0.031992845237255096,
      0.05055312067270279,
      0.06506910920143127,
      -0.04205626621842384,
      0.03566032275557518,
      0.041848644614219666,
      -0.005930859129875898,
      0.01448389794677496,
      0.00014764565275982022,
      -0.049661774188280106,
      -0.02653600089251995,
      -0.10300217568874359,
      -0.06336767226457596,
      0.059286024421453476,
      0.03195731341838837,
      -0.03774549439549446,
      -0.07621610909700394,
      0.04635936766862869,
      -0.003074208041653037,
      -0.022132044658064842,
      0.10044100135564804,
      -0.06885504722595215,
      -0.029884714633226395,
      0.005675826221704483,
      -0.06268440186977386,
      0.01142575778067112,
      0.06468355655670166,
      0.017753442749381065,
      -0.03934165835380554,
      0.08916518092155457,
      0.015295872464776039,
      0.05553098022937775,
      -0.030534207820892334,
      -0.023274805396795273,
      0.0016519109485670924,
      0.06613580137491226,
      0.0675637349486351,
      0.05506407842040062,
      0.12932436168193817,
      0.03700970113277435,
      -0.053830187767744064,
      -0.015445472672581673,
      -0.028613107278943062,
      -0.07093369960784912,
      0.051622726023197174,
      -0.07407968491315842,
      -0.037068672478199005,
      0.03736534342169762,
      0.017723269760608673,
      -0.016625937074422836,
      -0.06359897553920746,
      0.006634814664721489,
      0.0013602509861811996,
      -0.040146004408597946,
      0.038135554641485214,
      -0.07525570690631866,
      -0.029977938160300255,
      0.015606274828314781,
      -0.03899994492530823,
      0.0733104944229126,
      0.025700023397803307,
      0.056332338601350784,
      -0.0003509960661176592,
      -0.020786849781870842,
      -0.035273708403110504,
      -0.011700982227921486,
      -0.03607282415032387,
      0.004844606854021549,
      -0.11723010241985321,
      -0.04133029654622078,
      -0.03500944748520851,
      -0.03177012503147125,
      0.03623972088098526,
      0.0008760411874391139,
      -0.008086520247161388,
      -0.043760403990745544,
      0.0232767965644598,
      -0.012650081887841225,
      0.11033529043197632,
      0.0365116260945797,
      0.05921712890267372,
      -0.025254888460040092,
      0.020358942449092865,
      -0.0838044062256813,
      -0.09717574715614319,
      -0.026363050565123558,
      -0.0001397433807142079,
      0.036719903349876404,
      0.05406033992767334,
      0.01523496676236391,
      -0.00789650622755289,
      -0.09269721806049347,
      -0.05443098023533821,
      -0.014065282419323921,
      -0.053551528602838516,
      -0.011920569464564323,
      -0.013743349350988865,
      0.02925746515393257,
      0.009697419591248035,
      -0.08084074407815933,
      -0.013719119131565094,
      0.009681631810963154,
      -0.016212476417422295,
      0.009938451461493969,
      -0.0971318706870079,
      -0.05434641242027283,
      -0.012245134450495243,
      0.09252717345952988,
      0.009272146038711071,
      0.03389967232942581,
      -0.021240951493382454,
      -0.033246442675590515,
      -0.07110951840877533,
      0.009281312115490437,
      -0.0618944950401783,
      0.01966186985373497,
      -0.02700844593346119,
      -0.01244356669485569,
      0.03357673063874245,
      -0.018983542919158936,
      -0.049310341477394104,
      -0.04705384746193886,
      -0.06382734328508377,
      0.009142668917775154,
      -0.07624942809343338,
      0.000938954995945096,
      0.0954536646604538,
      -0.001688066404312849,
      -0.04675731062889099,
      -0.008279817178845406,
      0.03416760265827179,
      0.05373242497444153,
      0.062540203332901,
      -0.03733792528510094,
      -0.086434505879879,
      0.08538644760847092,
      0.0798085555434227,
      0.07485832273960114,
      0.04173634573817253,
      0.05435499921441078,
      -0.06460627168416977,
      0.025001976639032364,
      0.0010274759260937572,
      -0.004054047167301178,
      -0.08868341892957687,
      -0.0679369866847992,
      -0.05956076830625534,
      -0.04407742619514465,
      0.008541268296539783,
      -0.04933902248740196,
      0.038672495633363724,
      0.003793561365455389,
      0.01637510396540165,
      0.06698470562696457,
      0.08476587384939194,
      0.01668727584183216,
      0.011541619896888733,
      -0.011380068957805634,
      -0.05456921085715294,
      -0.004197103437036276,
      -0.060562945902347565,
      -0.011723154224455357,
      0.030252743512392044,
      0.012017287313938141,
      0.040397174656391144,
      -0.05284777656197548,
      0.04620201885700226,
      0.09270231425762177,
      0.009640648029744625,
      -0.02410673163831234,
      0.04365013167262077,
      -0.02168959192931652,
      0.02183680050075054,
      -0.008220658637583256,
      -0.01860039308667183,
      -0.02997138351202011,
      0.008539155125617981,
      0.06571093946695328,
      -0.04606243222951889,
      0.001169925439171493,
      0.0797567144036293,
      -0.03883221372961998,
      -0.02671605721116066,
      0.0033378549851477146,
      0.03428218513727188,
      -0.011183181777596474,
      0.08656467497348785,
      -0.03832905739545822,
      0.03953961655497551,
      -0.06988287717103958,
      0.08073633909225464,
      -0.021449485793709755,
      -0.002653195057064295,
      -0.07220813632011414,
      -0.0063753072172403336,
      0.007047752384096384,
      -0.04232301935553551,
      -0.044789448380470276,
      -0.02731185406446457,
      -0.02954750880599022,
      -0.06992277503013611,
      -0.03903002291917801,
      -0.10355624556541443,
      0.006940923165529966,
      0.006270095705986023,
      0.012240431271493435,
      -0.012284623458981514,
      -0.06182262673974037,
      0.004525591619312763,
      0.01070315670222044,
      0.007702663540840149,
      0.044519875198602676,
      -0.08842260390520096,
      -0.05810702592134476,
      0.01709209755063057,
      -0.007316749542951584,
      0.03022606298327446,
      0.07663239538669586,
      0.09224238246679306,
      0.03702148422598839,
      -0.012638595886528492,
      -0.0011614077957347035,
      -0.08904441446065903,
      0.06470069289207458,
      -0.009807932190597057,
      -0.013443018309772015,
      0.036155395209789276,
      0.029088478535413742,
      -0.08648329228162766,
      0.03599798306822777,
      -0.016977574676275253,
      0.05426839739084244,
      -0.024699434638023376,
      0.003040535841137171,
      0.0027995833661407232,
      -0.03046485036611557,
      -0.004368147812783718,
      0.008821078576147556,
      0.02960636094212532,
      -0.0001920252834679559,
      0.08744113892316818,
      0.013192328624427319,
      -0.017308851704001427,
      -0.019586805254220963,
      0.007832535542547703,
      0.009359098970890045,
      0.031249072402715683,
      0.024089133366942406,
      -0.0166111309081316,
      0.01651027239859104,
      -0.06481978297233582,
      -0.14130899310112,
      0.012385387904942036,
      0.010788433253765106,
      -0.00031147742993198335,
      0.07861947268247604,
      0.029792705550789833,
      0.015143260359764099,
      -0.02337324060499668,
      0.02162538841366768,
      0.042544689029455185,
      0.0910327285528183,
      0.07805817574262619,
      -0.05462047457695007,
      -0.12001673132181168,
      0.03416700288653374,
      0.0001340386224910617,
      -0.022283248603343964,
      -0.017913293093442917,
      -0.07202407717704773,
      -0.04176587983965874,
      -0.0705438032746315,
      -0.04258189722895622,
      -0.007466531824320555,
      0.06335613131523132,
      -0.01972155086696148,
      0.09804989397525787,
      -0.12851007282733917,
      0.009844019077718258,
      0.01975160650908947,
      0.018361413851380348,
      0.03836081176996231,
      -0.0036087108310312033,
      -0.09937956929206848,
      -0.02565862610936165,
      -0.1376868188381195,
      -0.030419060960412025,
      0.046364232897758484,
      0.033455342054367065,
      0.04251565784215927,
      0.04728126525878906,
      -0.06309133768081665,
      -0.008747104555368423,
      -0.08698897063732147,
      -0.008400965481996536,
      -0.05625181645154953,
      -0.07366956770420074,
      -0.002502676099538803,
      -0.01961926743388176,
      -0.015322064980864525,
      0.006952417083084583,
      0.017756646499037743,
      -0.00927715003490448,
      0.0023186239413917065,
      -0.05787448585033417,
      0.05929214507341385,
      -0.0007221403066068888,
      0.04025387763977051,
      -0.06014936417341232,
      -0.018123161047697067,
      0.003643222153186798,
      0.007532821036875248,
      -0.0063672298565506935,
      -0.18946987390518188,
      0.10506437718868256,
      -0.038577187806367874,
      -0.007928358390927315,
      -0.0068454379215836525,
      0.006418745964765549,
      0.10742846876382828,
      3.080666283494793e-05,
      0.019406987354159355,
      -0.013720747083425522,
      0.04886297509074211,
      0.0467575266957283,
      -0.032925184816122055,
      0.04663611575961113,
      0.008626092225313187,
      0.06826053559780121,
      -0.05443494766950607,
      -0.029201380908489227,
      0.04025854542851448,
      -0.026434870436787605,
      -0.061121515929698944,
      -0.015574121847748756,
      -0.017419766634702682,
      -0.03885866701602936,
      -0.045151643455028534,
      -0.06706599146127701,
      -0.0027289914432913065,
      -0.039594866335392,
      -0.015075593255460262,
      0.047694604843854904,
      -0.030071163550019264,
      0.03475383669137955,
      -0.02510659024119377,
      0.013178328052163124,
      0.05214479938149452,
      0.0009694196633063257,
      0.020220914855599403,
      -0.003633035346865654,
      -0.0653415322303772,
      -0.05941437929868698,
      -0.059615835547447205,
      0.015881646424531937,
      -0.0168302021920681,
      0.027337929233908653,
      -0.017359916120767593,
      -0.024721071124076843,
      0.015068120323121548,
      -0.039805229753255844,
      -0.045873261988162994,
      0.06231662631034851,
      -0.09667899459600449,
      -0.028073467314243317,
      0.013089065439999104,
      0.04600246995687485,
      -0.07864335179328918,
      -0.002080210717394948
    ],
    [
      0.020783841609954834,
      0.02562105469405651,
      0.026874467730522156,
      -0.014093303121626377,
      0.10264123231172562,
      -0.029993584379553795,
      -0.009762810543179512,
      0.007411755155771971,
      -0.12190964072942734,
      0.052907563745975494,
      -0.09415867179632187,
      -0.04618297144770622,
      0.05168982222676277,
      0.06256534159183502,
      -0.0938069149851799,
      -0.06164558231830597,
      -0.049353886395692825,
      0.029202770441770554,
      -0.03979862108826637,
      -0.030675219371914864,
      -0.06324610114097595,
      -0.09080815315246582,
      0.03413291275501251,
      -0.018636252731084824,
      0.021194739267230034,
      -0.0428447499871254,
      0.04330911114811897,
      0.026021748781204224,
      0.049446072429418564,
      -0.07346883416175842,
      -0.026500677689909935,
      0.02669357880949974,
      -0.0030396541114896536,
      0.007092662155628204,
      -0.03409776836633682,
      -0.015310720540583134,
      0.005373832304030657,
      -0.011679521761834621,
      0.07777824997901917,
      -0.04053542762994766,
      -0.012309235520660877,
      0.020324544981122017,
      -0.06408923864364624,
      0.10450386255979538,
      -0.010628235526382923,
      -0.0025897047016769648,
      0.035411685705184937,
      -0.010041422210633755,
      -0.1385052502155304,
      0.023167302832007408,
      0.017111198976635933,
      0.02485007233917713,
      0.05173124372959137,
      0.022472593933343887,
      0.059104520827531815,
      0.014634787105023861,
      0.09993310272693634,
      0.10642068833112717,
      -0.0390314906835556,
      -0.10506168752908707,
      0.019775576889514923,
      0.012004545889794827,
      -0.043448906391859055,
      0.01646096259355545,
      -0.0008838981157168746,
      -0.016660502180457115,
      0.024063371121883392,
      -0.008782248012721539,
      -0.02792748063802719,
      0.03352689743041992,
      -0.019563207402825356,
      0.046094659715890884,
      -0.0445343442261219,
      0.027179421856999397,
      0.037828121334314346,
      0.019070973619818687,
      -0.004538207780569792,
      -0.0036756261251866817,
      0.003829413093626499,
      -0.0027562985196709633,
      -0.05934164300560951,
      0.05871985852718353,
      0.005362886004149914,
      -0.033287324011325836,
      -0.010735670104622841,
      -0.03113866224884987,
      0.02962535433471203,
      -0.033411409705877304,
      -0.04430010914802551,
      0.004654475022107363,
      0.02924339659512043,
      -0.04059192165732384,
      0.01546756736934185,
      -0.06474630534648895,
      0.03081018105149269,
      0.052919913083314896,
      0.008642836473882198,
      -0.03247060999274254,
      -0.04424048960208893,
      0.0015206204261630774,
      0.08333831280469894,
      -0.02913380227982998,
      0.06218283623456955,
      0.01887860707938671,
      -0.024771735072135925,
      0.0242490042001009,
      -0.018949566408991814,
      0.013391872867941856,
      -0.05771375074982643,
      0.016863740980625153,
      0.005426137708127499,
      0.02352796494960785,
      -0.01382166426628828,
      -0.011036412790417671,
      0.01973542757332325,
      0.002632001880556345,
      -4.5813238102709875e-05,
      0.011859585531055927,
      -0.07264634221792221,
      -0.04837649315595627,
      0.034277815371751785,
      0.06357423961162567,
      0.09401055425405502,
      -0.03490375727415085,
      -0.005631325766444206,
      -0.0038742595352232456,
      -0.023732170462608337,
      0.018111802637577057,
      -0.050076644867658615,
      0.014062892645597458,
      0.04872160777449608,
      -0.03635691851377487,
      0.013133782893419266,
      -0.056923769414424896,
      -0.0201333686709404,
      -0.004974097013473511,
      0.02138330601155758,
      -0.03799820318818092,
      -0.0336446613073349,
      0.063898965716362,
      0.022120775654911995,
      0.0031229520682245493,
      0.08306150883436203,
      -0.03947284817695618,
      -0.08366712182760239,
      0.015278524719178677,
      -0.052028756588697433,
      0.05073864385485649,
      -0.019265970215201378,
      -0.03825971484184265,
      -0.04749525338411331,
      -0.0063934545032680035,
      0.037927862256765366,
      -0.026435967534780502,
      -0.0025678088422864676,
      0.035647060722112656,
      -0.06282943487167358,
      -0.07019208371639252,
      -0.07981233298778534,
      0.04368392378091812,
      -0.0005985940806567669,
      -0.07582686841487885,
      -0.044270921498537064,
      0.05489494279026985,
      -0.05112047865986824,
      -0.05095868930220604,
      -0.06519235670566559,
      -0.021289881318807602,
      -0.03523223474621773,
      0.06006434187293053,
      0.03479777276515961,
      0.05246703699231148,
      0.02783040888607502,
      -0.06573859602212906,
      0.005953511223196983,
      0.013928171247243881,
      -0.0037947024684399366,
      0.0314270444214344,
      0.09396570175886154,
      0.0232902392745018,
      -0.01876307651400566,
      0.08794242888689041,
      0.006167917512357235,
      0.08630793541669846,
      -0.03140864148736,
      -0.029887370765209198,
      -0.03041907213628292,
      -0.01340419240295887,
      -0.006017457693815231,
      0.02230708673596382,
      -0.051943033933639526,
      0.009936099871993065,
      0.047001469880342484,
      0.039209771901369095,
      0.029789693653583527,
      -0.05119405686855316,
      -0.022823497653007507,
      0.011358157731592655,
      -0.008566786535084248,
      0.017168624326586723,
      -0.05426100641489029,
      0.07213452458381653,
      -0.0391947366297245,
      -0.08978073298931122,
      0.014186673797667027,
      0.06458699703216553,
      -0.009854557923972607,
      -0.055154409259557724,
      -0.007555925752967596,
      0.010126477107405663,
      -0.03777274116873741,
      0.024182328954339027,
      -0.05130666494369507,
      0.04534323140978813,
      0.028535358607769012,
      0.00016400757886003703,
      0.004543274641036987,
      0.016307981684803963,
      -0.02239014022052288,
      -0.07309982925653458,
      -0.02310742437839508,
      -0.0037848183419555426,
      0.06109524518251419,
      0.0031736083328723907,
      0.018463805317878723,
      0.026406537741422653,
      -0.0464031882584095,
      0.003467958653345704,
      -0.018231835216283798,
      -0.04778221622109413,
      -0.006724586244672537,
      -0.05679091811180115,
      0.020447688177227974,
      0.036962997168302536,
      -0.013055466115474701,
      0.0031974539160728455,
      0.037468813359737396,
      -0.01545500848442316,
      0.045818425714969635,
      0.002305404283106327,
      -0.0244524497538805,
      -0.04484757408499718,
      0.006961575709283352,
      -0.00679063843563199,
      0.037061333656311035,
      0.015401630662381649,
      -0.055073972791433334,
      0.031606581062078476,
      0.04657452926039696,
      -0.06304092705249786,
      -0.06904126703739166,
      -0.01155339926481247,
      0.00593128427863121,
      -0.03174196556210518,
      -0.0003513319534249604,
      -0.04730043560266495,
      -0.07350454479455948,
      0.06961444765329361,
      -0.04944457486271858,
      0.027773477137088776,
      -0.03768521174788475,
      -0.03850037232041359,
      0.035756707191467285,
      -0.03471073880791664,
      -0.052572429180145264,
      0.015932107344269753,
      0.018067052587866783,
      -0.0334610790014267,
      -0.014019564725458622,
      -0.037546318024396896,
      0.07991693168878555,
      0.03396167606115341,
      0.04842093959450722,
      -0.040142010897397995,
      -0.08200673013925552,
      0.08029699325561523,
      -0.03504043072462082,
      0.015128584578633308,
      -0.021539699286222458,
      0.0013209726894274354,
      -0.026542989537119865,
      -0.03216751664876938,
      0.02924494445323944,
      -0.0416228361427784,
      -0.00437135249376297,
      -0.03306948393583298,
      -0.014714694581925869,
      -0.011929117143154144,
      -0.018541717901825905,
      -0.04424034804105759,
      0.03858054801821709,
      0.05181073769927025,
      -0.018663596361875534,
      0.013690873980522156,
      -0.0026021061930805445,
      0.011631886474788189,
      0.025166235864162445,
      -0.036048274487257004,
      -0.008914210833609104,
      0.09937786310911179,
      -0.004192084074020386,
      0.03230021148920059,
      -0.0697893425822258,
      0.057718366384506226,
      -0.005302149336785078,
      -0.0821046531200409,
      -0.0033220669720321894,
      -0.005482547916471958,
      0.038539741188287735,
      0.07108693569898605,
      -0.06927225738763809,
      -0.006642413325607777,
      -0.04745898023247719,
      -0.004153104033321142,
      -0.028412623330950737,
      -0.009608645923435688,
      0.039753153920173645,
      -0.07409753650426865,
      -0.008524502627551556,
      -0.032132331281900406,
      0.013908780179917812,
      -0.01614013873040676,
      0.038699183613061905,
      -0.1452094316482544,
      0.09507377445697784,
      -0.07494465261697769,
      -0.031615618616342545,
      0.04126233235001564,
      -0.07583878189325333,
      0.007878505624830723,
      0.016184207051992416,
      0.006748424377292395,
      0.05379520729184151,
      -0.05796871334314346,
      0.002748072613030672,
      0.021376051008701324,
      -0.01665079966187477,
      0.0023973900824785233,
      0.09616246074438095,
      -0.008286254480481148,
      0.07751091569662094,
      0.07292244583368301,
      -0.08104025572538376,
      0.036194078624248505,
      -0.0010390531970188022,
      -0.027115605771541595,
      0.026627885177731514,
      -0.027569489553570747,
      0.0033269664272665977,
      -0.07463894784450531,
      -0.01605876348912716,
      -0.017830386757850647,
      0.06251707673072815,
      -0.016888437792658806,
      0.0015856580575928092,
      0.04964153468608856,
      -0.031662534922361374,
      -0.0018579282332211733,
      -0.021531851962208748,
      -0.02088048867881298,
      0.10768991708755493,
      -0.0562317781150341,
      -0.02793845534324646,
      0.03499308601021767,
      -0.04271145537495613,
      -0.0022085460368543863,
      0.0742010697722435,
      0.07253950089216232,
      -0.04038048908114433,
      -0.062207624316215515,
      -0.031097084283828735,
      -0.10928182303905487,
      -0.022846130654215813,
      0.015720566734671593,
      -0.006312082055956125,
      -0.018981685861945152,
      -0.014115327037870884,
      -0.011997409164905548,
      0.029943130910396576,
      0.03354783356189728,
      0.04716917499899864,
      0.09298332035541534,
      -0.03251668065786362,
      0.08991962671279907,
      -0.010868706740438938,
      0.005988802295178175,
      0.012694326229393482,
      0.05964589864015579,
      0.06514719873666763,
      -0.08575599640607834,
      -0.05040494352579117,
      0.027115272358059883,
      -0.0708470419049263,
      0.007025952450931072,
      -0.00987724307924509,
      -0.03485165163874626,
      -0.024378018453717232,
      -0.01307295635342598,
      0.01774129644036293,
      0.01910223253071308,
      -0.03421131521463394,
      0.063921719789505,
      -0.10306272655725479,
      -0.049669910222291946,
      0.030334392562508583,
      0.050077494233846664,
      -0.030631836503744125,
      0.03190041705965996,
      -0.10007397085428238,
      -0.006054021418094635,
      -0.008398551493883133,
      -0.03628626838326454,
      -0.06422888487577438,
      -0.0012063704198226333,
      0.01953982189297676,
      0.00137781270314008,
      0.0394727997481823,
      0.019270028918981552,
      -0.0003511968534439802,
      0.0066954935900866985,
      -0.014212372712790966,
      -0.049420520663261414,
      -0.05127479135990143,
      -0.020252764225006104,
      0.06725453585386276,
      -0.03034922666847706,
      -0.06221330910921097,
      -0.0193482656031847,
      0.04027881845831871,
      0.016001801937818527,
      0.03795114904642105,
      0.02669752761721611,
      -0.06866098940372467,
      0.03473140671849251,
      -0.09155118465423584,
      -0.03133610263466835,
      0.07468385249376297,
      0.0053661842830479145,
      0.02644292637705803,
      0.01697549782693386,
      -0.04429780691862106,
      -0.04528207331895828,
      0.016287431120872498,
      -0.04257100820541382,
      -0.02015865221619606,
      0.09236516803503036,
      -0.0100522730499506,
      -0.021941153332591057,
      0.04627060517668724,
      -0.03559460863471031,
      -0.10126341879367828,
      -0.0011852786410599947,
      -0.0006053744582459331,
      0.037470895797014236,
      0.026561707258224487,
      0.048900850117206573,
      0.06304505467414856,
      -0.009068173356354237,
      0.03376581519842148,
      -0.08102439343929291,
      -0.03867926076054573,
      0.059508174657821655,
      0.04527023434638977,
      -0.015370418317615986,
      -0.008475527167320251,
      -0.06490864604711533,
      0.04274359345436096,
      0.024869445711374283,
      0.01781279221177101,
      -0.002897186903283,
      -0.0013112101005390286,
      0.019276846200227737,
      -0.03603466972708702,
      0.04238574206829071,
      -0.016957005485892296,
      0.025389278307557106,
      -0.0024851826019585133,
      0.017786314710974693,
      -0.04616115242242813,
      -0.013503183610737324,
      -0.042476341128349304,
      0.00519456434994936,
      -0.03221376985311508,
      0.019141536206007004,
      -0.06336992979049683,
      -0.02767842449247837,
      -0.08034481108188629,
      0.042522843927145004,
      -0.10750935971736908,
      0.021384045481681824,
      -0.016089625656604767,
      -0.005717672873288393,
      0.025450609624385834,
      0.027961350977420807,
      -0.02504625730216503,
      -0.044273436069488525,
      -0.03528602421283722,
      -0.03547842800617218,
      0.04087456688284874,
      -0.044630084186792374,
      0.09231823682785034,
      0.043565455824136734,
      0.0009865101892501116
    ],
    [
      -0.04536595195531845,
      0.02461656555533409,
      0.06359878182411194,
      -0.005065971985459328,
      0.003227140987291932,
      -0.039622507989406586,
      -0.023170996457338333,
      0.020716281607747078,
      -0.052045803517103195,
      0.0007092769956216216,
      -0.09803691506385803,
      -0.06203412264585495,
      -0.05333658680319786,
      -0.002171577187255025,
      0.03256698697805405,
      0.06072143837809563,
      -0.06862068176269531,
      -0.00226111919619143,
      0.07742047309875488,
      0.05964445322751999,
      0.0015359475510194898,
      0.052530933171510696,
      0.0213623046875,
      0.07030840218067169,
      0.030871335417032242,
      -0.006162769161164761,
      0.08664701879024506,
      -0.005305404309183359,
      -0.023616435006260872,
      0.07332419604063034,
      0.06567984819412231,
      0.00357931200414896,
      -0.010008901357650757,
      0.05997991934418678,
      0.04796525463461876,
      0.047966305166482925,
      -0.10751959681510925,
      0.004308999981731176,
      -0.0013525644317269325,
      0.08187059313058853,
      -0.07848560810089111,
      -0.016634734347462654,
      0.09470918774604797,
      0.03700273111462593,
      -0.045815154910087585,
      0.023813579231500626,
      0.042088523507118225,
      -0.011104059405624866,
      0.01301075704395771,
      0.027013832703232765,
      0.02965807542204857,
      0.0414208322763443,
      0.03146429359912872,
      -0.017881033942103386,
      -0.05939606577157974,
      0.06743422895669937,
      0.055445559322834015,
      -0.03211035579442978,
      0.02281610481441021,
      -0.09425970911979675,
      0.05638095736503601,
      -0.012837083078920841,
      0.01796366460621357,
      -0.040036290884017944,
      0.01283115241676569,
      0.04060710594058037,
      -0.02831173874437809,
      0.019599266350269318,
      0.001873048604466021,
      -0.02917027473449707,
      -0.009386108256876469,
      -0.015858275815844536,
      -0.04900065064430237,
      -0.03726322948932648,
      -0.1093289852142334,
      -0.05707859247922897,
      0.017268845811486244,
      0.048476528376340866,
      -0.05367628484964371,
      -0.08167299628257751,
      0.030440175905823708,
      -0.020132632926106453,
      0.028265761211514473,
      0.025332868099212646,
      0.006877658423036337,
      -0.0799851268529892,
      0.10151096433401108,
      0.05354970321059227,
      0.008800909854471684,
      0.04469427093863487,
      0.03588887304067612,
      -0.04771800711750984,
      0.07849904894828796,
      0.004159574396908283,
      0.07614180445671082,
      0.005859025288373232,
      -0.001586988684721291,
      0.039811402559280396,
      -0.08031761646270752,
      -0.051277656108140945,
      0.02031843736767769,
      -0.005540124140679836,
      0.08778370171785355,
      -0.061492178589105606,
      -0.05237511917948723,
      0.09840936958789825,
      0.05527865141630173,
      -0.0003544282808434218,
      -0.09982825070619583,
      0.08252603560686111,
      -0.007805489934980869,
      -0.037630416452884674,
      -0.022308139130473137,
      0.022502504289150238,
      0.005541019607335329,
      -0.06193969026207924,
      0.023071762174367905,
      -0.03278578445315361,
      -0.03479963168501854,
      -0.02254636399447918,
      0.045317161828279495,
      0.11445926874876022,
      0.08967912942171097,
      -0.025758178904652596,
      0.04185353219509125,
      0.13942620158195496,
      -0.0013882091734558344,
      0.07128748297691345,
      0.04582113027572632,
      0.0552261658012867,
      0.01671764627099037,
      0.004553205333650112,
      0.003318881383165717,
      -0.03962197154760361,
      -0.11986459046602249,
      -0.09381582587957382,
      0.06049257144331932,
      -0.02237214334309101,
      -0.04386928677558899,
      -0.0256284661591053,
      -0.009301258251070976,
      0.0567178912460804,
      0.0654512271285057,
      -0.032359495759010315,
      0.018713926896452904,
      -0.017933279275894165,
      -0.11499699205160141,
      -0.018501993268728256,
      -0.033049076795578,
      -0.03823632374405861,
      -0.002537465887144208,
      -0.008207139559090137,
      0.09471175074577332,
      -0.0005501587293110788,
      -0.015065325424075127,
      0.009606408886611462,
      -0.09485014528036118,
      0.05175255611538887,
      0.04109863191843033,
      0.005167746916413307,
      0.08133947849273682,
      0.007143337745219469,
      -0.1039719209074974,
      -0.044325295835733414,
      0.02142675593495369,
      -0.01077805645763874,
      0.042028676718473434,
      0.027347177267074585,
      -0.03162539005279541,
      0.0015695278998464346,
      -0.0006122169434092939,
      -0.0341055728495121,
      0.006336514372378588,
      -0.024225102737545967,
      -0.010411059483885765,
      0.030975230038166046,
      0.003635270521044731,
      -0.021618690341711044,
      0.035623788833618164,
      -0.021446600556373596,
      -0.03364259749650955,
      0.06235813722014427,
      0.029666803777217865,
      0.07154722511768341,
      0.008122795261442661,
      0.011550849303603172,
      0.025374751538038254,
      -0.07400956749916077,
      -0.0014235822018235922,
      -0.07269605994224548,
      0.06048368662595749,
      -0.017678996548056602,
      -0.07427182793617249,
      0.0558888278901577,
      0.008176883682608604,
      -0.02017085812985897,
      0.032759908586740494,
      -0.13214196264743805,
      0.019864782691001892,
      0.05500490590929985,
      0.00772442389279604,
      0.02347322553396225,
      -0.02210383303463459,
      0.025401048362255096,
      0.02376416325569153,
      0.014081127941608429,
      0.03176606446504593,
      0.03204799443483353,
      -0.005279007833451033,
      -0.05184200406074524,
      -0.012817504815757275,
      0.06349753588438034,
      -0.009076268412172794,
      0.029257213696837425,
      -0.02859395556151867,
      0.010358508676290512,
      -0.008554955013096333,
      -0.07696393132209778,
      -0.037002116441726685,
      -0.005045210476964712,
      0.024063384160399437,
      0.08027771860361099,
      -0.016671624034643173,
      0.03488216549158096,
      -0.05461975932121277,
      -0.03579263016581535,
      -0.09106659144163132,
      -0.010459339246153831,
      -0.016394389793276787,
      -0.05770670250058174,
      0.1040961742401123,
      -0.02732030116021633,
      -0.028317470103502274,
      0.02106967568397522,
      0.03417746722698212,
      0.014592585153877735,
      -0.040187519043684006,
      0.04588502645492554,
      0.036620885133743286,
      -0.03833676129579544,
      0.050713520497083664,
      -0.0008599876891821623,
      -0.08237803727388382,
      0.01843845099210739,
      0.0333896167576313,
      0.03935714811086655,
      -0.11458031088113785,
      0.03646228462457657,
      0.08636147528886795,
      -0.05586212873458862,
      -0.04685309901833534,
      0.018279090523719788,
      -0.035702481865882874,
      0.0023120716214179993,
      0.0445370078086853,
      -0.022389201447367668,
      -0.04693304002285004,
      -0.007941126823425293,
      -0.0249536894261837,
      -0.03687980771064758,
      -0.0711282342672348,
      -0.02418743446469307,
      0.013417549431324005,
      -0.011316584423184395,
      -0.03392365574836731,
      -0.059107422828674316,
      0.06942415982484818,
      -0.004150537773966789,
      0.027186334133148193,
      -0.024491162970662117,
      0.03873366490006447,
      0.04826071858406067,
      0.10590493679046631,
      0.07616162300109863,
      0.02322421967983246,
      0.0923738032579422,
      0.042522210627794266,
      -0.008834519423544407,
      -0.10030041635036469,
      -0.08386232703924179,
      0.017367800697684288,
      -0.04780566692352295,
      0.023315485566854477,
      0.02178478240966797,
      -0.04554309323430061,
      0.06539585441350937,
      -0.008059339597821236,
      0.06707864999771118,
      -0.018677059561014175,
      -0.07438087463378906,
      -0.03840767592191696,
      0.06231367960572243,
      -0.019465211778879166,
      -0.030507441610097885,
      -0.03964352607727051,
      0.03379257023334503,
      0.07002104073762894,
      -0.04494134336709976,
      0.041505005210638046,
      0.017837680876255035,
      0.022176532074809074,
      -0.017542026937007904,
      0.08499535918235779,
      -0.08076320588588715,
      0.0022097777109593153,
      -0.013302153907716274,
      -0.08386286348104477,
      -0.03521512821316719,
      0.02854856289923191,
      0.028323650360107422,
      0.008214369416236877,
      -0.005373554304242134,
      0.027818018570542336,
      -0.11240258812904358,
      -0.0685102641582489,
      0.03528091311454773,
      0.0681178942322731,
      -0.004545008298009634,
      0.005775361321866512,
      -0.020948635414242744,
      0.05445069819688797,
      -0.07663436979055405,
      0.030151523649692535,
      -0.10365451872348785,
      -0.00931197963654995,
      -0.0005674390704371035,
      0.03464977815747261,
      0.05326880142092705,
      0.00826233346015215,
      -0.05864908546209335,
      0.02976077049970627,
      0.05672617256641388,
      0.01992233842611313,
      0.07738405466079712,
      -0.0726199522614479,
      -0.016294976696372032,
      -0.0023102611303329468,
      0.020246004685759544,
      0.04593372717499733,
      -0.03357281535863876,
      -0.02862946316599846,
      0.007796881720423698,
      0.06527116149663925,
      -0.04621746391057968,
      0.02217140980064869,
      -0.011557420715689659,
      -0.039787326008081436,
      -0.054119884967803955,
      0.04045481234788895,
      0.01039923820644617,
      -0.011700807139277458,
      0.0368369035422802,
      -0.025537285953760147,
      -0.031761910766363144,
      0.010988605208694935,
      0.006014910060912371,
      0.02642805501818657,
      0.006412264425307512,
      0.08120711147785187,
      0.022875266149640083,
      0.028427015990018845,
      0.004590068943798542,
      2.6549447284196503e-05,
      0.0024731620214879513,
      -0.03585611283779144,
      -0.08515118807554245,
      0.005871532950550318,
      -0.020861800760030746,
      -0.026447413489222527,
      -0.02235318161547184,
      0.02144099585711956,
      -0.01752818562090397,
      0.03648538514971733,
      -0.03885483741760254,
      0.043989039957523346,
      0.045300085097551346,
      0.008784309029579163,
      -0.059263695031404495,
      -0.06279248744249344,
      -0.06703554093837738,
      -0.01832033321261406,
      -0.04375425726175308,
      0.0040636020712554455,
      0.04617980867624283,
      0.010533224791288376,
      -0.0022272702772170305,
      -0.008678511716425419,
      -0.03847876191139221,
      0.021463563665747643,
      -0.03877509385347366,
      -0.043167199939489365,
      0.0839545950293541,
      0.049473412334918976,
      0.052391234785318375,
      0.06754674017429352,
      -0.04078318551182747,
      0.06646720319986343,
      -0.06584585458040237,
      -0.04873567819595337,
      -0.04431147128343582,
      -0.06652965396642685,
      0.019932784140110016,
      -0.015558125451207161,
      0.09303639829158783,
      0.01354272197932005,
      0.057248540222644806,
      -0.018259361386299133,
      0.005203853361308575,
      0.01823703572154045,
      -0.044019054621458054,
      -0.019676588475704193,
      -0.08315357565879822,
      -0.020136311650276184,
      -0.006769182160496712,
      0.0550081692636013,
      -0.06720426678657532,
      -0.10274001210927963,
      0.0704386979341507,
      -0.06488490849733353,
      0.024731470271945,
      -0.0019945886451750994,
      -0.11232706159353256,
      0.03099576011300087,
      0.033494312316179276,
      -0.055781736969947815,
      -0.006870909593999386,
      -0.10744128376245499,
      0.048232898116111755,
      0.09343773871660233,
      -0.02206805720925331,
      -0.01042555645108223,
      -0.07938715070486069,
      0.047164782881736755,
      -0.016809294000267982,
      -0.03035200573503971,
      -0.032257772982120514,
      -0.004868137184530497,
      0.10675472766160965,
      -0.08173304051160812,
      0.049478888511657715,
      0.014564692974090576,
      -0.05396828427910805,
      -0.005781600717455149,
      -0.028944624587893486,
      -0.001885193632915616,
      -0.005153323523700237,
      -0.04289945960044861,
      -0.053747374564409256,
      -0.01118488796055317,
      -0.04816356301307678,
      0.047021541744470596,
      -0.0490180179476738,
      -0.006182466633617878,
      0.004980181343853474,
      0.0883772224187851,
      0.013205611146986485,
      -0.07591255754232407,
      0.014896348118782043,
      0.0010604970157146454,
      -0.017711980268359184,
      0.019254006445407867,
      0.09266387671232224,
      0.08913128077983856,
      -0.03489822521805763,
      0.026467790827155113,
      0.00427684560418129,
      -0.0077918400056660175,
      -0.03029899299144745,
      0.0239559393376112,
      -0.08815621584653854,
      0.00013905548257753253,
      -0.012709497474133968,
      0.07024314999580383,
      -0.025668935850262642,
      0.016801899299025536,
      0.051718417555093765,
      0.007973178289830685,
      0.008391192182898521,
      0.019743379205465317,
      0.09121675789356232,
      -0.023515837267041206,
      -0.03332702815532684,
      -0.010496892035007477,
      0.0705024003982544,
      0.0015076134586706758,
      0.06578420102596283,
      0.01279735192656517,
      0.05138976499438286,
      -0.09449715167284012,
      -0.07209042459726334,
      0.00478025758638978,
      0.020584341138601303,
      -0.029219094663858414,
      0.024841124191880226,
      0.04598172754049301,
      -0.044466108083724976,
      -0.1133790984749794,
      -0.054286498576402664,
      0.004213878884911537,
      0.03126804530620575,
      0.07027603685855865,
      0.046107277274131775,
      -0.009524914436042309
    ],
    [
      0.011020006611943245,
      0.02424188330769539,
      0.008867730386555195,
      0.03559475764632225,
      0.02991187386214733,
      0.039753954857587814,
      0.06485847383737564,
      -0.018322201445698738,
      -0.10003255307674408,
      0.04938510060310364,
      0.06788592040538788,
      -0.027506181970238686,
      0.008009240962564945,
      0.051670610904693604,
      0.005205771420150995,
      -0.07277945429086685,
      -0.053495872765779495,
      0.009452411904931068,
      0.012187874875962734,
      0.055623751133680344,
      -0.03701663017272949,
      0.039717573672533035,
      0.0470537468791008,
      0.020019864663481712,
      0.04558340460062027,
      0.005873546935617924,
      -0.05260010436177254,
      0.032967619597911835,
      -0.07081739604473114,
      -0.058659933507442474,
      0.03283103555440903,
      -0.0793219581246376,
      0.01894195005297661,
      0.026736091822385788,
      -0.0004259816778358072,
      -0.031154774129390717,
      -0.018149761483073235,
      0.0285757128149271,
      0.00578735675662756,
      0.02717777155339718,
      0.019012410193681717,
      0.06358949095010757,
      0.048228442668914795,
      0.03763037547469139,
      0.027592387050390244,
      -0.0063379970379173756,
      0.01738348789513111,
      -0.05687224119901657,
      0.048810821026563644,
      0.07232370227575302,
      -0.0020302392076700926,
      0.05366674065589905,
      -0.05140840262174606,
      -0.007974952459335327,
      -0.021538715809583664,
      -0.0740823745727539,
      0.034800514578819275,
      0.003528613829985261,
      -0.02294452302157879,
      0.021725794300436974,
      0.04555029794573784,
      0.06767973303794861,
      -0.039756450802087784,
      -0.0012841338757425547,
      0.07896380871534348,
      0.03323113173246384,
      -0.01691877655684948,
      -0.017820052802562714,
      0.074971042573452,
      -0.042628396302461624,
      0.035298850387334824,
      0.030451364815235138,
      -0.006217596586793661,
      0.01751963049173355,
      -0.0005984699819236994,
      -0.04843330383300781,
      -0.04538947716355324,
      -0.01718772016465664,
      -0.04538331553339958,
      -0.01625308394432068,
      0.024768715724349022,
      -0.034235499799251556,
      -0.01589655689895153,
      -0.022928837686777115,
      -0.048082076013088226,
      -0.08542903512716293,
      0.08038908243179321,
      0.01838790811598301,
      0.0001890720595838502,
      0.04502645879983902,
      0.010577221401035786,
      0.05095575749874115,
      -0.018039382994174957,
      0.029879732057452202,
      0.012379460036754608,
      -0.031376540660858154,
      -0.044701799750328064,
      -0.010659945197403431,
      -0.06803843379020691,
      -0.03017115592956543,
      0.038178686052560806,
      -0.032037198543548584,
      0.02428286522626877,
      -0.053048986941576004,
      -0.08435551822185516,
      0.022206444293260574,
      -0.03877826780080795,
      -0.010021481662988663,
      -0.009143243543803692,
      -0.03916645795106888,
      0.008134466595947742,
      -0.05687595531344414,
      0.02837458625435829,
      -0.016962692141532898,
      -0.10401811450719833,
      0.022330492734909058,
      0.05501846596598625,
      0.03594483807682991,
      -0.042340777814388275,
      0.0029907713178545237,
      0.009810220450162888,
      -0.009452808648347855,
      0.03193274140357971,
      -0.06576012820005417,
      0.00042933173244819045,
      0.00617359159514308,
      -0.06511467695236206,
      0.09744729101657867,
      0.01783277839422226,
      -0.05151960998773575,
      0.008482355624437332,
      0.054776765406131744,
      -0.05276679992675781,
      -0.019234217703342438,
      -0.05433528870344162,
      -0.01479809544980526,
      -0.060353510081768036,
      -0.04298350214958191,
      -0.023507138714194298,
      0.041330959647893906,
      0.00983753614127636,
      -0.010853201150894165,
      0.02129407972097397,
      0.014622353948652744,
      -0.050626203417778015,
      0.0020963968709111214,
      -0.1299871802330017,
      0.03320786729454994,
      -0.017893821001052856,
      0.04045454412698746,
      -0.006510531064122915,
      -0.0948256403207779,
      0.024216510355472565,
      0.016037026420235634,
      0.0063659632578492165,
      0.037076983600854874,
      -0.10443230718374252,
      0.015450669452548027,
      -0.05622135475277901,
      0.011200934648513794,
      -0.03261560946702957,
      0.004712762776762247,
      -0.02104984223842621,
      -0.008530897088348866,
      -0.045542161911726,
      0.057228438556194305,
      -0.028034262359142303,
      0.03071361780166626,
      -0.042806655168533325,
      -0.0574541911482811,
      -0.05467727780342102,
      0.07035325467586517,
      -0.026445597410202026,
      -0.02892945148050785,
      -0.07837440818548203,
      0.03324778005480766,
      -0.04213888570666313,
      0.04231543093919754,
      -0.011845850385725498,
      -0.09337163716554642,
      0.005296960938721895,
      0.09086735546588898,
      0.0147617869079113,
      0.058845482766628265,
      0.014646592549979687,
      0.026525644585490227,
      -0.0034410010557621717,
      0.026654772460460663,
      -0.008033533580601215,
      0.10439703613519669,
      0.035523440688848495,
      -0.06218007206916809,
      0.03150147944688797,
      -0.061326369643211365,
      0.08213935792446136,
      -0.06885788589715958,
      -0.013879167847335339,
      0.02359139733016491,
      0.04939056560397148,
      -0.0400993786752224,
      -0.05681583657860756,
      0.008639875799417496,
      -0.07661424577236176,
      -0.035491254180669785,
      0.05976278707385063,
      -0.06412758678197861,
      0.0367768369615078,
      0.03500053286552429,
      0.08731529116630554,
      -0.045126378536224365,
      -0.03403596207499504,
      0.09177914261817932,
      0.003912694286555052,
      -0.07667544484138489,
      0.044751815497875214,
      0.009227038361132145,
      -0.05583040416240692,
      0.025493787601590157,
      -0.14498282968997955,
      -0.005391044542193413,
      -0.10376313328742981,
      0.08031758666038513,
      -0.018515951931476593,
      0.04063031077384949,
      -0.010203886777162552,
      0.05879630148410797,
      -0.14022788405418396,
      0.005015047732740641,
      -0.00961371697485447,
      -0.0758993923664093,
      0.0349678173661232,
      0.11318978667259216,
      0.09420082718133926,
      -0.02390943095088005,
      0.034176673740148544,
      -0.03519215062260628,
      -0.07737158238887787,
      -0.02837434783577919,
      0.039984721690416336,
      -0.022661585360765457,
      -0.0007556861382909119,
      0.052624933421611786,
      0.012598293833434582,
      0.009604656137526035,
      -0.04492252692580223,
      0.008935896679759026,
      -0.0017993388464674354,
      0.041153404861688614,
      -0.043858177959918976,
      -0.0837072804570198,
      0.09538920223712921,
      0.032037314027547836,
      -0.0769796073436737,
      0.03080075792968273,
      0.04356459528207779,
      0.010646805167198181,
      -0.014196693897247314,
      -0.03293436020612717,
      -0.002188501413911581,
      -0.046555936336517334,
      0.06461426615715027,
      -0.05975409969687462,
      0.05117298662662506,
      0.08936542272567749,
      -0.09264326840639114,
      -0.014882580377161503,
      -0.010442357510328293,
      0.0002292919234605506,
      -0.03220413625240326,
      0.009342229925096035,
      0.0289752334356308,
      -0.003015512367710471,
      -0.061485350131988525,
      0.01545130554586649,
      -0.03967558220028877,
      0.016553018242120743,
      -0.026578353717923164,
      -0.02735830284655094,
      -0.02727607451379299,
      -0.08548003435134888,
      -0.06467371433973312,
      -0.019103504717350006,
      -0.008810151368379593,
      -0.016953201964497566,
      0.015074063092470169,
      0.047434672713279724,
      0.049646757543087006,
      0.06849762052297592,
      -0.06517913937568665,
      0.011137448251247406,
      -0.005404287949204445,
      0.016625642776489258,
      -0.07081499695777893,
      0.06979018449783325,
      -0.012026507407426834,
      0.005817233584821224,
      -0.006376870907843113,
      0.057420194149017334,
      0.04405049607157707,
      0.026507854461669922,
      -0.040023062378168106,
      0.01173101831227541,
      0.05704639106988907,
      0.018242482095956802,
      0.03702741861343384,
      0.0029129674658179283,
      -0.010962074622511864,
      0.010599642992019653,
      -0.013489028438925743,
      -0.008582484908401966,
      0.07119881361722946,
      -0.02416267991065979,
      0.027018388733267784,
      -0.028357164934277534,
      -0.06812969595193863,
      0.004509368911385536,
      -0.002506171353161335,
      -0.054457858204841614,
      0.03552481532096863,
      0.0034181340597569942,
      -0.009799815714359283,
      -0.03823138773441315,
      -0.03030136227607727,
      -0.02531410939991474,
      -0.03927251696586609,
      0.07641918957233429,
      0.06474409997463226,
      0.031095007434487343,
      0.0014206802006810904,
      -0.016302399337291718,
      0.06517662107944489,
      0.011250176467001438,
      -0.01256499718874693,
      -0.04226386547088623,
      0.08322380483150482,
      0.00587063655257225,
      -0.07749851047992706,
      0.05140158534049988,
      0.0035694013349711895,
      0.058405548334121704,
      -0.004961734171956778,
      0.01994093880057335,
      -0.059421733021736145,
      -0.027550939470529556,
      0.050163403153419495,
      0.007614996749907732,
      -0.01570332795381546,
      0.06048235669732094,
      -0.045743800699710846,
      0.027198152616620064,
      0.041978657245635986,
      0.044019125401973724,
      -0.02706272527575493,
      0.03622448816895485,
      -0.006137417163699865,
      0.055346038192510605,
      -0.002204406075179577,
      0.014693142846226692,
      -0.015939408913254738,
      0.06540949642658234,
      0.009800074622035027,
      0.021492449566721916,
      0.003231979440897703,
      0.0037961716298013926,
      -0.005534201394766569,
      -0.10938979685306549,
      -0.03385534882545471,
      0.03504234552383423,
      0.0669594332575798,
      0.02971690520644188,
      0.07425997406244278,
      0.05535012483596802,
      0.03174659609794617,
      -0.024831129238009453,
      0.015680784359574318,
      -0.06317055225372314,
      -0.023861519992351532,
      -0.0114385224878788,
      0.06525973975658417,
      0.04845668002963066,
      0.044910404831171036,
      -0.02837630920112133,
      -0.038714099675416946,
      0.01873396337032318,
      0.039390772581100464,
      -0.005103124771267176,
      -0.06693556159734726,
      0.04291808605194092,
      0.04688739404082298,
      0.04126325622200966,
      0.01962488517165184,
      -0.0008929570904001594,
      0.021563038229942322,
      0.09825646132230759,
      -0.030635252594947815,
      -0.006142607424408197,
      -0.0063815186731517315,
      0.017014607787132263,
      0.026159800589084625,
      0.02487863600254059,
      -0.05265011638402939,
      -0.044918160885572433,
      -0.031660422682762146,
      0.01049529667943716,
      0.08083336800336838,
      0.08502914011478424,
      -0.00729890912771225,
      -0.009129330515861511,
      -0.13519300520420074,
      -0.018280459567904472,
      -0.01336816418915987,
      -0.059812191873788834,
      -0.04018697515130043,
      0.06673108041286469,
      -0.01644153706729412,
      0.08200102299451828,
      -0.004465173464268446,
      0.06571716070175171,
      -0.01224092673510313,
      0.024704068899154663,
      0.08184896409511566,
      -0.04582640528678894,
      -0.016686948016285896,
      -0.0005444824346341193,
      -0.0022162182722240686,
      -0.03511444106698036,
      -0.017255915328860283,
      0.030135463923215866,
      0.02277708426117897,
      -0.051548879593610764,
      0.01582600176334381,
      -0.002967430278658867,
      0.0297809187322855,
      0.04406425356864929,
      -0.08602600544691086,
      -0.004356314428150654,
      0.002800408285111189,
      -0.032059524208307266,
      0.02832648530602455,
      0.04405387490987778,
      0.0036916357930749655,
      0.0541309155523777,
      0.07983127981424332,
      0.008820269256830215,
      -0.03001277707517147,
      0.03152300417423248,
      0.00763350073248148,
      -0.027737155556678772,
      -0.020532073453068733,
      0.004433264024555683,
      0.016805684193968773,
      0.02703539840877056,
      -0.0134956706315279,
      0.02839699760079384,
      0.01675405167043209,
      -0.05292259529232979,
      0.07940098643302917,
      0.11736784875392914,
      0.09204515814781189,
      -0.038682468235492706,
      0.05907057970762253,
      0.01952775940299034,
      0.020780280232429504,
      -0.041604071855545044,
      0.03171365335583687,
      0.07529009133577347,
      0.09016109257936478,
      -0.030211614444851875,
      0.014672055840492249,
      -0.06772208213806152,
      -0.008881310932338238,
      -0.034599728882312775,
      0.006721096113324165,
      -0.021480275318026543,
      0.04742932692170143,
      0.040853362530469894,
      0.03714093565940857,
      0.04139426350593567,
      -0.00996705424040556,
      0.12669241428375244,
      0.016349708661437035,
      0.017909325659275055,
      0.042432621121406555,
      0.03407413139939308,
      0.018997294828295708,
      -0.03881178796291351,
      -0.04079800471663475,
      0.05742841958999634,
      -0.019729413092136383,
      -0.011018157936632633,
      0.016346001997590065,
      0.05641048401594162,
      -0.002376794582232833,
      -0.0049072857946157455,
      -0.025821147486567497,
      -0.04825085029006004,
      0.01773017831146717,
      -0.010749699547886848,
      -0.023231983184814453,
      -0.020880650728940964,
      0.03916431590914726,
      -0.033918414264917374,
      0.06356250494718552
    ],
    [
      0.0072324094362556934,
      0.030116034671664238,
      0.016695722937583923,
      0.018091954290866852,
      -0.0050160447135567665,
      -0.04488756135106087,
      0.006481674965471029,
      0.007453774567693472,
      -0.028354326263070107,
      0.028611930087208748,
      -0.0001281888980884105,
      0.004960926715284586,
      0.02642923966050148,
      -0.03641904145479202,
      0.04781176149845123,
      0.07949775457382202,
      0.02547719143331051,
      0.008860914036631584,
      -0.038891781121492386,
      -0.02212095446884632,
      -0.03667163476347923,
      0.04947289079427719,
      -0.06632592529058456,
      0.027943957597017288,
      -0.06582175195217133,
      -0.054932620376348495,
      0.0752919614315033,
      -0.055105626583099365,
      0.02536165714263916,
      -0.0426609143614769,
      -0.002744838362559676,
      0.0006263364339247346,
      -0.05112795904278755,
      0.056979913264513016,
      -0.0205791387706995,
      0.024415146559476852,
      -0.03512492775917053,
      0.1067168116569519,
      0.022090230137109756,
      0.0545295886695385,
      0.10156447440385818,
      -0.03534189611673355,
      -0.0157322995364666,
      -0.036387816071510315,
      -0.002068934729322791,
      0.009505349211394787,
      0.032029300928115845,
      -0.0370708592236042,
      -0.07787520438432693,
      -0.06318805366754532,
      -0.0065764798782765865,
      0.061070822179317474,
      0.03583810105919838,
      0.00917124841362238,
      0.009290328249335289,
      -0.041230764240026474,
      0.046472370624542236,
      0.015491358004510403,
      -0.034087736159563065,
      -0.039828017354011536,
      -0.03466557338833809,
      -0.008732374757528305,
      0.10217152535915375,
      0.021632229909300804,
      0.018656190484762192,
      0.010863087140023708,
      -0.03566322848200798,
      0.05601968988776207,
      -0.04833082854747772,
      -0.039256200194358826,
      0.0814671665430069,
      0.02402987703680992,
      -0.0018570844549685717,
      -0.009351357817649841,
      -0.006860667373985052,
      0.015369676984846592,
      0.025595523416996002,
      -0.04147769510746002,
      0.011202636174857616,
      -0.033902402967214584,
      0.02525961957871914,
      0.045433420687913895,
      -0.06706949323415756,
      -0.05356269329786301,
      0.034373171627521515,
      0.055521611124277115,
      -0.030044715851545334,
      0.046962227672338486,
      -0.01235243584960699,
      -0.08570202440023422,
      0.07061084359884262,
      -0.0017696453724056482,
      -0.01120206993073225,
      -0.0523330383002758,
      0.059431690722703934,
      -0.043919485062360764,
      -0.0405183769762516,
      0.04383230581879616,
      -0.0658264011144638,
      -0.12167873978614807,
      0.03656317666172981,
      0.04249119386076927,
      -0.10096736997365952,
      0.01587681844830513,
      -0.03497501090168953,
      -0.008259215392172337,
      0.026026861742138863,
      -0.010636687278747559,
      -0.0354740284383297,
      -0.0009020629222504795,
      0.028652960434556007,
      -0.00036755218752659857,
      0.0035269600339233875,
      0.07729163765907288,
      -0.010802111588418484,
      -0.0608622282743454,
      0.020576342940330505,
      -0.009251739829778671,
      0.004161605145782232,
      -0.032169900834560394,
      0.0038482067175209522,
      0.02261194959282875,
      -0.03051726333796978,
      -0.06168891116976738,
      -0.018436428159475327,
      -0.00388716091401875,
      0.019106116145849228,
      -0.005251337308436632,
      0.046442341059446335,
      0.024219747632741928,
      0.055971577763557434,
      0.044291067868471146,
      0.0328940711915493,
      -0.018292294815182686,
      -0.07358932495117188,
      0.0095831248909235,
      0.018070200458168983,
      0.027975715696811676,
      0.07819754630327225,
      -0.05456676706671715,
      -0.04564041644334793,
      -0.03351455181837082,
      -0.03117244690656662,
      -0.055147211998701096,
      -0.014686068519949913,
      -0.05406712368130684,
      -0.08665009588003159,
      -0.020656168460845947,
      -0.024408508092164993,
      0.024857163429260254,
      -0.08482537418603897,
      0.07734695076942444,
      -0.03245910629630089,
      -0.052076082676649094,
      0.04089228808879852,
      0.04676755145192146,
      -0.07439183443784714,
      -0.07555265724658966,
      0.027159487828612328,
      -0.015184891410171986,
      0.017844779416918755,
      -0.058153096586465836,
      -0.03250092640519142,
      -0.04030858352780342,
      0.008648510091006756,
      -0.029891416430473328,
      -0.019210925325751305,
      0.0008434472838416696,
      -0.060500822961330414,
      0.06062239408493042,
      -0.05761001259088516,
      -0.011566399596631527,
      0.00040983714279718697,
      -0.022789863869547844,
      -0.00235513923689723,
      0.0607759952545166,
      0.046978242695331573,
      -0.08163104951381683,
      0.03793550655245781,
      -0.04003717750310898,
      0.03905448690056801,
      0.016978507861495018,
      0.0073271142318844795,
      0.02695794776082039,
      -0.08251669257879257,
      0.047368790954351425,
      -0.06575111299753189,
      0.04386502876877785,
      0.022360708564519882,
      0.00028929280233569443,
      -0.04714878648519516,
      -0.015594154596328735,
      -0.018808279186487198,
      0.013318736106157303,
      -0.04747925326228142,
      -0.052172765135765076,
      -0.002891519572585821,
      0.0696372538805008,
      -0.0433383472263813,
      0.0015841838903725147,
      -0.02176465280354023,
      0.042219869792461395,
      -0.04221075773239136,
      -0.018772704526782036,
      -0.06392866373062134,
      0.057934217154979706,
      0.003276734845712781,
      -0.05368725582957268,
      0.05282978713512421,
      -0.027205150574445724,
      -0.06747891753911972,
      -0.0014561915304511786,
      0.014845469035208225,
      -0.040649205446243286,
      0.0028947333339601755,
      -0.035256363451480865,
      -0.017003139480948448,
      0.006969934795051813,
      -0.08581489324569702,
      0.07616299390792847,
      -0.0404120497405529,
      0.014238866046071053,
      -0.008865149691700935,
      0.017613979056477547,
      -0.09522675722837448,
      -0.036284178495407104,
      -0.04443858563899994,
      0.020313039422035217,
      -0.013037299737334251,
      0.03696587309241295,
      0.056993987411260605,
      0.054522063583135605,
      0.013864141888916492,
      0.024438440799713135,
      -0.005322577431797981,
      0.013647197745740414,
      -0.02446017786860466,
      0.0500764399766922,
      0.06380752474069595,
      0.024702485650777817,
      0.04349331930279732,
      0.07942231744527817,
      0.012422477826476097,
      0.01462077908217907,
      -0.011180100962519646,
      0.03422495350241661,
      0.001554918009787798,
      -0.024026917293667793,
      0.06160898506641388,
      -0.041190456598997116,
      -0.04525809362530708,
      0.0880972295999527,
      -0.06196519359946251,
      -0.052888866513967514,
      0.026326879858970642,
      -0.015490011312067509,
      0.02857903018593788,
      -0.01583646424114704,
      0.04911917820572853,
      -0.0912732481956482,
      -0.057338304817676544,
      -0.006952454335987568,
      0.008061445318162441,
      0.056222643703222275,
      -0.061497483402490616,
      -0.0230574868619442,
      -0.0030401742551475763,
      -0.03845297545194626,
      -0.04142552614212036,
      -0.03826389089226723,
      -0.03419655188918114,
      0.02414604276418686,
      -0.02825590781867504,
      -0.029223207384347916,
      0.0005819731741212308,
      0.041139088571071625,
      0.06627513468265533,
      -0.017253229394555092,
      0.02635507844388485,
      0.028808023780584335,
      -0.06915564835071564,
      0.033137571066617966,
      -0.05613795295357704,
      0.054037321358919144,
      -0.03149360045790672,
      -0.040039993822574615,
      -0.04522671177983284,
      0.13145940005779266,
      -0.043570514768362045,
      0.020555414259433746,
      0.08587056398391724,
      0.05916763097047806,
      -0.039282556623220444,
      -0.02617431990802288,
      0.02129669114947319,
      0.04797542095184326,
      0.007275661453604698,
      -0.008104385808110237,
      0.04920347407460213,
      -0.039205845445394516,
      0.019844187423586845,
      -0.015498874709010124,
      -0.00445866584777832,
      -0.0505630262196064,
      -0.10959839075803757,
      0.05914544686675072,
      -0.013280387036502361,
      -0.09817969053983688,
      0.06603842973709106,
      -0.01894020289182663,
      -0.011737396009266376,
      -0.010937266983091831,
      0.05364982411265373,
      0.0009280423983000219,
      -0.04572032392024994,
      -0.0330316536128521,
      0.019545840099453926,
      -0.06901150196790695,
      0.07216134667396545,
      0.009834467433393002,
      0.05388830974698067,
      0.0272744782269001,
      -0.05139537528157234,
      -0.047076743096113205,
      0.056684814393520355,
      0.07700546830892563,
      0.018181098625063896,
      -0.016784464940428734,
      0.0014054891653358936,
      -0.02516457438468933,
      0.0003414175007492304,
      -0.06533943861722946,
      0.010884441435337067,
      0.03271603211760521,
      0.055166490375995636,
      0.021043799817562103,
      0.050875741988420486,
      -0.0011158791603520513,
      0.027279768139123917,
      0.08400766551494598,
      0.010141090489923954,
      0.005595759488642216,
      0.05597512051463127,
      0.03844808042049408,
      0.001028143335133791,
      0.06252007186412811,
      -0.02793082408607006,
      0.0036313175223767757,
      -0.011341414414346218,
      -0.07637833058834076,
      -0.013279866427183151,
      0.00594064686447382,
      0.01865066960453987,
      0.024918003007769585,
      -0.023828379809856415,
      0.08980777114629745,
      0.030449777841567993,
      0.06821013987064362,
      -0.00827347207814455,
      0.06353703886270523,
      0.03425811603665352,
      0.05764400213956833,
      -0.04058096185326576,
      -0.006646680645644665,
      0.028569871559739113,
      -0.01959577016532421,
      -0.021725963801145554,
      -0.047033295035362244,
      0.055867500603199005,
      -0.04855530336499214,
      -0.0902273952960968,
      0.047823261469602585,
      0.0029633326921612024,
      -0.043183259665966034,
      0.01463539618998766,
      0.03158269450068474,
      -0.08248813450336456,
      -0.06590432673692703,
      -0.003869585460051894,
      0.03845231980085373,
      -0.013418812304735184,
      0.03281491994857788,
      -0.07410262525081635,
      0.05852065235376358,
      -0.022468511015176773,
      0.050163596868515015,
      0.007736943196505308,
      0.058798909187316895,
      -0.009193643927574158,
      0.042689599096775055,
      0.020530281588435173,
      -0.014057259075343609,
      0.05696403235197067,
      0.04995255172252655,
      0.03326154500246048,
      -0.010950043797492981,
      0.018327418714761734,
      -0.05426670238375664,
      -0.03114038147032261,
      -0.015779798850417137,
      -0.07417360693216324,
      -0.025935808196663857,
      -0.08749305456876755,
      0.028555935248732567,
      0.05998452007770538,
      0.04446166381239891,
      -0.05618508160114288,
      0.036114804446697235,
      -0.05406434088945389,
      0.06352084875106812,
      0.05663439631462097,
      -0.03179887309670448,
      0.010651769116520882,
      0.0045806835405528545,
      0.025119327008724213,
      0.0357997864484787,
      0.05089544877409935,
      0.05980459973216057,
      -0.01744854636490345,
      0.07422243803739548,
      -0.1009383499622345,
      -0.021922269836068153,
      -0.0191631019115448,
      -0.018479295074939728,
      -0.03771299868822098,
      -0.005006454419344664,
      -0.07702869176864624,
      0.02450549043715,
      -0.015756087377667427,
      -0.03961927071213722,
      -0.04503199830651283,
      -0.002917762380093336,
      0.05244455114006996,
      0.06774893403053284,
      -0.05636921897530556,
      0.009175790473818779,
      0.04284541308879852,
      -0.023465817794203758,
      0.041811563074588776,
      0.0020202004816383123,
      0.0417192317545414,
      -0.003396214684471488,
      -0.0405263714492321,
      0.0043468354269862175,
      -0.019627241417765617,
      0.024240940809249878,
      0.0005883355624973774,
      0.03146665170788765,
      0.049946531653404236,
      -0.06643732637166977,
      -0.004178090952336788,
      -0.011612985283136368,
      0.034088585525751114,
      0.0328042209148407,
      0.039882104843854904,
      -0.009897527284920216,
      -0.012904302217066288,
      0.06931135803461075,
      0.01329176127910614,
      -0.023711806163191795,
      0.005707108415663242,
      0.03062152862548828,
      0.009749053977429867,
      0.03712668642401695,
      -0.017077449709177017,
      0.01929984614253044,
      0.01467733271420002,
      -0.04456125572323799,
      0.009397854097187519,
      -0.02738720364868641,
      -0.05898987501859665,
      -0.06609850376844406,
      0.011060415767133236,
      0.006224604789167643,
      -0.09487276524305344,
      -0.03265509754419327,
      -0.015949880704283714,
      0.06450299918651581,
      0.003143110079690814,
      0.02623339556157589,
      0.017101416364312172,
      -0.005017494782805443,
      0.06067667156457901,
      0.0195817481726408,
      0.07291524112224579,
      -0.04981597885489464,
      -0.02484152466058731,
      0.12285128980875015,
      0.04726924002170563,
      -0.002422317862510681,
      -0.08552716672420502,
      0.02487798221409321,
      0.007249146234244108,
      0.12671424448490143,
      0.02998906560242176,
      0.013104796409606934,
      0.04702982306480408,
      -0.011421526782214642,
      0.033392760902643204,
      0.055621784180402756,
      0.05413375794887543,
      0.09316493570804596,
      -0.045441363006830215
    ],
    [
      -0.03372044488787651,
      -0.07333482801914215,
      -0.039342816919088364,
      0.031151238828897476,
      0.009774237871170044,
      0.04803619161248207,
      -0.024223482236266136,
      -0.06963036209344864,
      -0.0478481724858284,
      0.043012943118810654,
      -0.10507745295763016,
      -0.04636343941092491,
      -0.009700431488454342,
      0.03827594220638275,
      -0.014830968342721462,
      0.023310281336307526,
      -0.03880569338798523,
      -0.008312742225825787,
      0.034099724143743515,
      0.07485874742269516,
      -0.03271123394370079,
      -0.013870124705135822,
      0.022117355838418007,
      0.06747301667928696,
      0.02274281345307827,
      0.04398911073803902,
      0.025439750403165817,
      0.01370626874268055,
      0.013247424736618996,
      -0.055777475237846375,
      0.03582562878727913,
      -0.034540798515081406,
      -0.042410947382450104,
      -0.009319271892309189,
      -0.06744377315044403,
      -0.03250286728143692,
      -0.026242535561323166,
      0.023481646552681923,
      -0.057608697563409805,
      0.009196369908750057,
      0.061488185077905655,
      0.033841684460639954,
      0.007888243533670902,
      -0.02156486175954342,
      0.05706115439534187,
      0.021780163049697876,
      -0.03613635525107384,
      0.07362711429595947,
      0.01582447998225689,
      0.048724833875894547,
      0.01361862849444151,
      -0.060570672154426575,
      0.03728742524981499,
      -0.012966340407729149,
      -0.08102913945913315,
      -0.0166364423930645,
      0.04821805655956268,
      -0.007711161859333515,
      -0.00017442672105971724,
      0.04194594919681549,
      0.056605394929647446,
      -0.02031843177974224,
      0.012630225159227848,
      -0.06473048776388168,
      -0.04182560369372368,
      -0.05406930670142174,
      -0.0211325790733099,
      -0.013827471062541008,
      0.045711949467659,
      -0.0979890450835228,
      0.030391257256269455,
      -0.015298997983336449,
      -0.028151066973805428,
      0.047780249267816544,
      -0.03856348618865013,
      0.02234243042767048,
      -0.05200662836432457,
      0.008231185376644135,
      -0.028783341869711876,
      -0.03978751227259636,
      0.031216392293572426,
      -0.006954820826649666,
      -0.03838087245821953,
      0.017402073368430138,
      0.014321156777441502,
      -0.08495297282934189,
      0.030155321583151817,
      -0.021959085017442703,
      0.0526328943669796,
      -0.004217381589114666,
      0.01399634126573801,
      -0.10901463031768799,
      -0.06671807914972305,
      -0.04906456172466278,
      0.029152559116482735,
      -0.00023776254965923727,
      -0.07012777030467987,
      0.02079949714243412,
      0.05507985129952431,
      -0.036705099046230316,
      0.041293203830718994,
      0.1343245804309845,
      -0.011539943516254425,
      -0.03148337081074715,
      -0.0208217091858387,
      0.01627812534570694,
      0.014607397839426994,
      0.03967714309692383,
      -0.007072094362229109,
      0.028631748631596565,
      0.03828253969550133,
      -0.028017323464155197,
      -0.04245960712432861,
      -0.049662996083498,
      -0.10469376295804977,
      0.03774464875459671,
      0.04828927293419838,
      0.05846908688545227,
      -0.02202644757926464,
      -0.011396669782698154,
      -0.013886255212128162,
      0.06424656510353088,
      -0.022719817236065865,
      -0.01050121895968914,
      0.06213333085179329,
      -0.011619539931416512,
      0.03593679517507553,
      0.015815244987607002,
      -0.05867551639676094,
      0.02068670466542244,
      0.07784885168075562,
      0.0352289192378521,
      0.07236957550048828,
      -0.007002608850598335,
      0.0011396249756217003,
      0.007685050368309021,
      -0.01236011367291212,
      0.03710411116480827,
      0.07812593132257462,
      -0.014843063428997993,
      0.03074692189693451,
      0.01805226132273674,
      0.04787816107273102,
      0.019541192799806595,
      -0.04618128761649132,
      -0.05706436559557915,
      0.03552216291427612,
      -0.010164925828576088,
      0.0750587061047554,
      0.06900092214345932,
      -0.027885662391781807,
      -0.030894802883267403,
      -0.011017752811312675,
      -0.0030202465131878853,
      -0.021479178220033646,
      0.0031223949044942856,
      0.04431388899683952,
      -0.009767167270183563,
      0.04436192661523819,
      0.04143527150154114,
      -0.0006219520582817495,
      -0.005524788051843643,
      0.013972638174891472,
      -0.024632055312395096,
      -0.008165503852069378,
      0.04915008321404457,
      -0.016113827005028725,
      -0.03284019976854324,
      -0.052420392632484436,
      0.0024875705130398273,
      0.01686199940741062,
      0.06807177513837814,
      -0.008944699540734291,
      0.023553410544991493,
      0.03941578045487404,
      -0.02723909728229046,
      0.12291870266199112,
      0.0066666859202086926,
      -0.041271358728408813,
      -0.022958705201745033,
      0.01845739036798477,
      0.05280529335141182,
      0.006250991951674223,
      -0.012100993655622005,
      -0.05501530319452286,
      -0.009032422676682472,
      -0.03298578038811684,
      -0.0018984328489750624,
      0.010067460127174854,
      0.05616515874862671,
      -0.0012436200631782413,
      -0.06097957491874695,
      -0.037262629717588425,
      -0.05837763100862503,
      0.021328577771782875,
      0.012633286416530609,
      0.014741886407136917,
      0.007891403511166573,
      0.020026611164212227,
      -0.019440166652202606,
      -0.03492690995335579,
      0.032883476465940475,
      0.004900110419839621,
      -0.01486164890229702,
      -0.053498525172472,
      -0.06357425451278687,
      0.06125282496213913,
      -0.027773698791861534,
      0.0009592010173946619,
      -0.05576804652810097,
      -0.050800759345293045,
      -0.026192283257842064,
      0.005934635177254677,
      -0.11299524456262589,
      -0.07313472032546997,
      -0.025052230805158615,
      0.029431631788611412,
      -0.03713389113545418,
      -0.05955544486641884,
      -0.058493807911872864,
      -0.02510845847427845,
      0.09008878469467163,
      -0.05775965750217438,
      0.04429545998573303,
      0.06964793801307678,
      -0.00241808220744133,
      -0.05878791958093643,
      0.05645408108830452,
      0.037859413772821426,
      -0.07363715767860413,
      -0.01725819706916809,
      -0.047630425542593,
      0.006310619413852692,
      0.042524199932813644,
      -0.03710826858878136,
      -0.020330024883151054,
      -0.05366930365562439,
      -0.045269280672073364,
      0.06169740483164787,
      0.02777336910367012,
      -0.02370276302099228,
      0.02613171748816967,
      0.011259854771196842,
      -0.039156679064035416,
      0.03726060688495636,
      0.04392766207456589,
      0.00971277616918087,
      -0.023951390758156776,
      0.007109664846211672,
      0.07714484632015228,
      0.025389527902007103,
      -0.03549305349588394,
      0.0321892574429512,
      -0.011793818324804306,
      -0.06656739115715027,
      0.057576004415750504,
      -0.07128290832042694,
      -0.0534098818898201,
      -0.042916640639305115,
      -0.07517725229263306,
      -0.029956195503473282,
      0.025611739605665207,
      0.06105665862560272,
      0.0332338772714138,
      0.04132268205285072,
      0.027467943727970123,
      -0.010402839630842209,
      0.08270911872386932,
      0.02979038842022419,
      0.019945915788412094,
      0.07474394142627716,
      -0.003078848822042346,
      -0.03625074774026871,
      -0.06338965147733688,
      0.06998971849679947,
      -0.0059179505333304405,
      -0.007685577962547541,
      0.0064957235008478165,
      -0.033537592738866806,
      -0.01963435858488083,
      -0.023172063753008842,
      0.05572797730565071,
      0.021861810237169266,
      -0.045235298573970795,
      0.038926176726818085,
      -0.030358674004673958,
      -0.0016033552819862962,
      -0.019033292308449745,
      -0.04792836681008339,
      -0.03075047768652439,
      0.01996944472193718,
      0.06632094085216522,
      -0.014435301534831524,
      -0.00014259527961257845,
      0.08344574272632599,
      0.10950827598571777,
      -0.05138470605015755,
      -0.08778710663318634,
      0.06166199594736099,
      -0.00700475974008441,
      -0.03605428710579872,
      0.04690754786133766,
      -0.026511043310165405,
      -0.0537501722574234,
      -0.005680446047335863,
      -0.002926033455878496,
      0.005779140163213015,
      0.012477762997150421,
      0.023846331983804703,
      0.04181722551584244,
      0.002287705661728978,
      0.011541568674147129,
      -0.0032358288299292326,
      -0.003484495682641864,
      -0.014598648995161057,
      -0.023111119866371155,
      -0.01881086453795433,
      0.04190633073449135,
      -0.026624323800206184,
      0.052448175847530365,
      -0.021915826946496964,
      0.0006669281865470111,
      -0.07436830550432205,
      0.08385638892650604,
      -0.0794302225112915,
      -0.011729154735803604,
      0.05066695809364319,
      -0.0045515429228544235,
      0.05248800292611122,
      0.003764668246731162,
      0.025376975536346436,
      0.02963331714272499,
      -0.04049181565642357,
      -0.05814976245164871,
      -0.0026994533836841583,
      0.047264792025089264,
      0.058425482362508774,
      0.03427644446492195,
      -0.01497398316860199,
      0.08614538609981537,
      -0.02128554880619049,
      0.04155426844954491,
      -0.035768765956163406,
      0.02249089814722538,
      0.03747459128499031,
      -0.03990685194730759,
      -0.03434477373957634,
      -0.018573254346847534,
      -0.07628148794174194,
      0.009180665016174316,
      -0.05686315894126892,
      0.012651621364057064,
      -0.010421546176075935,
      0.054378215223550797,
      0.03379276022315025,
      -0.05749054253101349,
      -0.07027066498994827,
      -0.028706181794404984,
      -0.018964769318699837,
      -0.02849731408059597,
      0.04046275094151497,
      -0.008336972445249557,
      -0.16559505462646484,
      0.04306357726454735,
      -0.00412207655608654,
      -0.0007520598592236638,
      -0.016663964837789536,
      -0.038280848413705826,
      -0.0014347574906423688,
      0.0036473118234425783,
      -0.010697927325963974,
      0.015001674182713032,
      -0.007912027649581432,
      0.04608745127916336,
      0.04414127394556999,
      0.001841746037825942,
      0.010600768961012363,
      0.11477512121200562,
      0.030155539512634277,
      0.014185833744704723,
      -0.017164910212159157,
      0.03717763349413872,
      0.010050087235867977,
      0.018209338188171387,
      0.006563684903085232,
      -0.002943575382232666,
      -0.018518643453717232,
      -0.010149963200092316,
      -0.09678079187870026,
      0.0374886691570282,
      0.06573029607534409,
      -0.017073458060622215,
      0.017067087814211845,
      0.043401625007390976,
      0.044046640396118164,
      -0.08029676228761673,
      -0.061742812395095825,
      0.05900384858250618,
      -0.07385437190532684,
      -0.07262736558914185,
      -0.05278640240430832,
      0.03703572228550911,
      -0.023415736854076385,
      -0.029914123937487602,
      -0.03464562073349953,
      0.04130157083272934,
      0.03493775054812431,
      0.053933676332235336,
      -0.03836961090564728,
      -0.009814298711717129,
      0.026005007326602936,
      0.02287529595196247,
      -0.043230071663856506,
      0.07733327150344849,
      -0.0429287850856781,
      0.03196769580245018,
      0.010683559812605381,
      0.0464530773460865,
      0.016983114182949066,
      0.05943756550550461,
      -0.08358317613601685,
      0.04023663327097893,
      0.058762818574905396,
      -0.016931040212512016,
      -0.016947781667113304,
      0.08058634400367737,
      -0.014948327094316483,
      0.053045690059661865,
      0.017002558335661888,
      0.02754787541925907,
      -0.02423507533967495,
      0.09491697698831558,
      0.044169917702674866,
      -0.004987332969903946,
      -0.07802960276603699,
      -0.014273889362812042,
      0.0038485259283334017,
      0.06530969589948654,
      0.015279191546142101,
      0.055532537400722504,
      0.03288904204964638,
      0.013639814220368862,
      0.020319245755672455,
      0.03595711290836334,
      0.020199701189994812,
      0.056640625,
      -0.046357858926057816,
      -0.03894311189651489,
      0.0015938343713060021,
      0.002552528865635395,
      0.0431218147277832,
      0.01882832683622837,
      -0.0391080267727375,
      0.06540173292160034,
      -0.01021994836628437,
      0.05941367894411087,
      -0.017478633671998978,
      0.010814805515110493,
      -0.04165450111031532,
      0.017839467152953148,
      -0.026162205263972282,
      -0.022090360522270203,
      -0.04280636087059975,
      0.030229629948735237,
      0.12435833364725113,
      -0.06896033138036728,
      0.012762331403791904,
      -0.13666296005249023,
      0.008554245345294476,
      0.09014531224966049,
      0.14111854135990143,
      0.013745189644396305,
      0.07919719815254211,
      -0.06830653548240662,
      -0.03688449040055275,
      -0.10780232399702072,
      0.09129808843135834,
      0.06806667894124985,
      -0.0140152582898736,
      0.03547264635562897,
      -0.023338545113801956,
      0.086079902946949,
      0.03616523742675781,
      -0.037405580282211304,
      -0.005632523912936449,
      -0.06424117833375931,
      -0.009825905784964561,
      0.04229173809289932,
      -0.05994243174791336,
      0.025737768039107323,
      -0.05266523361206055,
      -0.048524774610996246,
      0.04778050258755684,
      0.016251616179943085,
      -0.05493014678359032,
      -0.03156651556491852,
      0.011859911493957043,
      0.09253078699111938,
      0.08833035826683044,
      -0.022586215287446976,
      0.07334278523921967,
      -0.08203232288360596,
      0.0066246576607227325
    ],
    [
      0.08032310754060745,
      0.021117916330695152,
      0.037982162088155746,
      0.050472505390644073,
      0.0039245993830263615,
      0.0074983746744692326,
      -0.03725351765751839,
      -0.069110207259655,
      0.011001904495060444,
      0.0841616690158844,
      -0.0955570712685585,
      0.00426147785037756,
      -0.022045878693461418,
      -0.057967402040958405,
      -0.01370203960686922,
      0.02044258825480938,
      0.04837348684668541,
      -0.07031339406967163,
      0.0002285725058754906,
      0.020226337015628815,
      -0.016566459089517593,
      0.026766624301671982,
      -0.08614229410886765,
      0.01847703941166401,
      0.004631849005818367,
      0.00839071162045002,
      -0.03653525933623314,
      0.009455922991037369,
      0.019613366574048996,
      -0.0902976393699646,
      0.002630537608638406,
      0.0024665826931595802,
      0.06831549108028412,
      0.0028110758867114782,
      -0.05170922726392746,
      -0.05121002346277237,
      -0.013556932099163532,
      0.03244049847126007,
      0.030511830002069473,
      0.04906834289431572,
      0.012927583418786526,
      0.0005992293008603156,
      0.027987627312541008,
      0.013293934985995293,
      0.10028988122940063,
      0.06980767101049423,
      -0.03352849930524826,
      0.09064289927482605,
      -0.01192637998610735,
      -0.014668883755803108,
      -0.03932618722319603,
      0.014937746338546276,
      -0.09648261964321136,
      0.010180417448282242,
      0.08633343130350113,
      0.023391038179397583,
      -0.06107936426997185,
      0.02302231639623642,
      0.013195332139730453,
      -0.059371672570705414,
      0.020786922425031662,
      0.06193173676729202,
      0.08596570789813995,
      -0.028009911999106407,
      0.06390958279371262,
      0.08326598256826401,
      0.005144019611179829,
      0.04463409259915352,
      -0.02122805453836918,
      -0.02120506577193737,
      0.09799407422542572,
      -0.08889242261648178,
      -0.03364487364888191,
      -0.026474637910723686,
      0.011590434238314629,
      -0.04125611111521721,
      -0.0813010111451149,
      0.02585708722472191,
      0.0407240130007267,
      -0.03788362443447113,
      -0.048575159162282944,
      0.06522461026906967,
      -0.0386955700814724,
      -0.0012625595554709435,
      -0.01951831392943859,
      0.002004626439884305,
      0.07161865383386612,
      0.013458235189318657,
      0.0997026264667511,
      -0.05461425334215164,
      0.045765552669763565,
      0.0003510959795676172,
      -8.661948231747374e-05,
      -0.04571743309497833,
      0.07479385286569595,
      0.009789621457457542,
      -0.06161064654588699,
      -0.012712756171822548,
      0.043701961636543274,
      -0.001122059184126556,
      0.07052716612815857,
      -0.009043722413480282,
      -0.014356245286762714,
      0.0092625105753541,
      -0.05702335759997368,
      0.03702276945114136,
      0.0064370096661150455,
      0.041022446006536484,
      -0.001875857007689774,
      0.04146234318614006,
      -0.035128526389598846,
      -0.022300008684396744,
      -0.02384071983397007,
      -0.013594386167824268,
      -0.026966022327542305,
      -0.04334241896867752,
      -0.046289991587400436,
      0.07095743715763092,
      -0.018935222178697586,
      -0.010329462587833405,
      -0.0713188648223877,
      0.03849760442972183,
      0.05970823019742966,
      -0.07214124500751495,
      0.13486112654209137,
      -0.0200676117092371,
      0.016058161854743958,
      0.01870385743677616,
      0.01821640320122242,
      -0.0003962849441450089,
      0.028952239081263542,
      0.045764002948999405,
      0.0047336770221591,
      -0.036193810403347015,
      -0.03461569920182228,
      -0.039672620594501495,
      0.011870804242789745,
      0.034402888268232346,
      -0.07383957505226135,
      -0.001765287946909666,
      -0.00216222763992846,
      0.015322571620345116,
      -0.0337422639131546,
      -0.05585962533950806,
      0.021324479952454567,
      -0.006723885424435139,
      -0.0056739868596196175,
      -0.030024614185094833,
      0.0079199755564332,
      -0.0024919246789067984,
      -0.002135597402229905,
      -0.0072302124463021755,
      0.03226538375020027,
      -0.03988977521657944,
      0.04986214265227318,
      0.05319076031446457,
      -0.027702394872903824,
      -0.05292101949453354,
      -0.0063215927220880985,
      -0.027763914316892624,
      -0.02498503588140011,
      -0.03064558282494545,
      -0.034811921417713165,
      -0.062484681606292725,
      0.04975777491927147,
      0.10210325568914413,
      0.05251862481236458,
      -0.06574898213148117,
      0.04423510655760765,
      -0.02079804614186287,
      0.0867556482553482,
      -0.08773890137672424,
      0.058317624032497406,
      -0.04814012348651886,
      0.03973875939846039,
      0.04280651733279228,
      0.008883511647582054,
      0.001188946422189474,
      0.025947481393814087,
      0.05821552872657776,
      -0.022619562223553658,
      -0.031717199832201004,
      0.022642510011792183,
      -0.00013026523811277002,
      0.054953400045633316,
      0.01144393626600504,
      -0.09652739018201828,
      -0.03587526082992554,
      0.06943938136100769,
      0.04736859351396561,
      -0.026555968448519707,
      0.018197160214185715,
      -0.04596170037984848,
      -0.06671729683876038,
      -0.038840342313051224,
      0.029355036094784737,
      0.0048758783377707005,
      0.0024057405535131693,
      -0.02463448792695999,
      0.009272233583033085,
      -0.0755082368850708,
      0.015025096014142036,
      -0.09725449979305267,
      0.005305170081555843,
      -0.0812075287103653,
      -0.038798738270998,
      -0.061312321573495865,
      0.004013936035335064,
      0.026661530137062073,
      0.04271702468395233,
      -0.017739523202180862,
      0.050771795213222504,
      -0.0015475478721782565,
      -0.01284230686724186,
      0.043051306158304214,
      -0.006846408825367689,
      0.021103329956531525,
      0.04132089763879776,
      -0.10271406173706055,
      -0.0708923265337944,
      -0.007621430791914463,
      0.04656074941158295,
      -0.058872245252132416,
      0.010381803847849369,
      -0.05403061583638191,
      -0.0068735056556761265,
      0.019145362079143524,
      0.013528157956898212,
      -0.06980583816766739,
      -0.016356831416487694,
      0.06383077800273895,
      0.03975726291537285,
      -0.019821513444185257,
      0.07674131542444229,
      0.0013512412551790476,
      -0.0006921316380612552,
      -0.017921458929777145,
      -0.05734669044613838,
      0.02180779166519642,
      -0.006478066090494394,
      -0.02161262556910515,
      -0.017430379986763,
      0.0032146857120096684,
      -0.011224600486457348,
      -0.09788620471954346,
      0.022114766761660576,
      -0.0007428358658216894,
      -0.08253146708011627,
      0.020998338237404823,
      0.019030986353754997,
      0.044388674199581146,
      -0.009549650363624096,
      -0.0018150050891563296,
      0.015374606475234032,
      0.042679544538259506,
      -0.039202287793159485,
      0.023259269073605537,
      0.007037668488919735,
      0.028136756271123886,
      0.057381901890039444,
      -0.012866989709436893,
      0.059169966727495193,
      0.018800076097249985,
      -0.0006550485268235207,
      -0.03885883837938309,
      0.04672906920313835,
      -0.005029625259339809,
      -0.06593643873929977,
      0.03861016780138016,
      -0.009599391371011734,
      0.059637319296598434,
      0.007400345057249069,
      -0.04100346565246582,
      0.08853493630886078,
      0.07160736620426178,
      0.04392072558403015,
      -0.04727091267704964,
      -0.010963373817503452,
      0.045985929667949677,
      -0.07172620296478271,
      0.031637415289878845,
      0.013124637305736542,
      -0.037369124591350555,
      0.05447716265916824,
      -0.004392457194626331,
      -0.0373091958463192,
      -0.003639792324975133,
      0.03443143516778946,
      -0.01791958510875702,
      -0.044464949518442154,
      -0.033740341663360596,
      0.02432767115533352,
      -0.01958164945244789,
      -0.00956454873085022,
      0.03486534580588341,
      0.04485686868429184,
      0.0007253361982293427,
      -0.05508972331881523,
      -0.047549616545438766,
      0.009034117683768272,
      0.03359231352806091,
      0.052271824330091476,
      0.04683060944080353,
      0.020887013524770737,
      0.039166584610939026,
      -0.0338197723031044,
      0.0025854611303657293,
      -0.018225090578198433,
      -0.012628607451915741,
      0.022100234404206276,
      0.006974638439714909,
      -0.016283592209219933,
      0.03568554297089577,
      0.009781071916222572,
      0.011594714596867561,
      0.009970390237867832,
      0.02786584571003914,
      -0.07605351507663727,
      0.013507204130291939,
      0.05387777462601662,
      0.0349905863404274,
      0.03282524645328522,
      -0.041107356548309326,
      -0.007668029982596636,
      0.03344354033470154,
      0.03321390226483345,
      -0.08436360955238342,
      0.013522474095225334,
      0.06127595528960228,
      0.09472940117120743,
      -0.04652531072497368,
      0.037443649023771286,
      0.03466452658176422,
      -0.02425776794552803,
      0.01565469056367874,
      0.006053174380213022,
      0.042736366391181946,
      -0.07145211845636368,
      0.011164361611008644,
      -0.0380372479557991,
      -0.036478858441114426,
      -0.02542678453028202,
      0.02954198606312275,
      0.03374850004911423,
      0.048018768429756165,
      0.011878005228936672,
      -0.018159838393330574,
      0.04333471134305,
      0.028139766305685043,
      0.052593912929296494,
      0.023222634568810463,
      -0.0019879823084920645,
      0.010816617868840694,
      0.07752149552106857,
      -0.06908653676509857,
      -0.0990782305598259,
      -0.0753064677119255,
      0.029184872284531593,
      0.008568407967686653,
      0.01732577383518219,
      0.11342514306306839,
      -0.02583276480436325,
      -0.0017594025703147054,
      0.04241742193698883,
      -0.008633147925138474,
      -0.06545235216617584,
      -0.014177369885146618,
      0.07091019302606583,
      0.10054171085357666,
      0.033003050833940506,
      0.01926892064511776,
      0.02002735249698162,
      -0.019877679646015167,
      -0.029173249378800392,
      -0.01862402819097042,
      -0.10043791681528091,
      -0.06933698803186417,
      0.036758583039045334,
      0.03830757364630699,
      -0.018255190923810005,
      0.00956815853714943,
      0.05393925681710243,
      0.013903842307627201,
      0.051738541573286057,
      0.0276905819773674,
      0.017864668741822243,
      0.09455829113721848,
      -0.02243279665708542,
      0.030139153823256493,
      -0.050913237035274506,
      -0.057438477873802185,
      0.056753888726234436,
      -0.022350331768393517,
      0.015965184196829796,
      0.05854193866252899,
      0.04160404950380325,
      0.045733492821455,
      0.04652014747262001,
      0.009108982980251312,
      0.019008606672286987,
      0.037982065230607986,
      -0.01088032592087984,
      0.0484977550804615,
      -0.02076205611228943,
      -0.04297599568963051,
      0.07262983173131943,
      0.052787043154239655,
      -0.04661666601896286,
      -0.08016909658908844,
      -0.04471169784665108,
      -0.03355542570352554,
      0.03601834177970886,
      -0.04639679566025734,
      0.011823201552033424,
      0.025218313559889793,
      0.08165079355239868,
      0.05073989927768707,
      0.06637205928564072,
      0.03342027962207794,
      -0.024886274710297585,
      -0.032088033854961395,
      -0.05675061419606209,
      -0.030162956565618515,
      -0.08061346411705017,
      0.06633306294679642,
      -0.047362785786390305,
      0.04691349342465401,
      -0.024849701672792435,
      0.024377532303333282,
      -0.0008567976183257997,
      -0.006618899293243885,
      0.03811771050095558,
      0.009877003729343414,
      -0.015775274485349655,
      -0.03813391551375389,
      -0.047073978930711746,
      -0.08275619149208069,
      0.008742810226976871,
      -0.06711675226688385,
      -0.06106755509972572,
      -0.003649145131930709,
      0.0024604748468846083,
      -0.04607652127742767,
      -0.028881365433335304,
      -0.006596453953534365,
      0.06674090027809143,
      0.025834502652287483,
      0.05674431845545769,
      0.06361956149339676,
      0.05464748293161392,
      -0.006835033185780048,
      0.008321159519255161,
      -0.05889446288347244,
      0.0249228123575449,
      0.015361756086349487,
      -0.002826702781021595,
      -0.07073143869638443,
      -0.0075885336846113205,
      -0.0705656111240387,
      0.0703384131193161,
      -0.061799246817827225,
      -0.02350357547402382,
      -0.012804431840777397,
      0.0938917025923729,
      -0.028148554265499115,
      -0.0036744007375091314,
      0.0008868863806128502,
      0.07136936485767365,
      -0.023445991799235344,
      0.039690300822257996,
      -0.02226654253900051,
      -0.008233004249632359,
      -0.018148085102438927,
      0.029236655682325363,
      -0.022188644856214523,
      0.016232065856456757,
      0.06602337211370468,
      -0.031781554222106934,
      -0.010977011173963547,
      -0.017303911969065666,
      0.012966725043952465,
      -0.010768464766442776,
      -0.03356611728668213,
      0.03378632664680481,
      0.005296445917338133,
      -0.014228612184524536,
      0.02816006913781166,
      -0.043300800025463104,
      0.011724174953997135,
      0.028962792828679085,
      -0.045560676604509354,
      -0.01685492694377899,
      -0.04063565656542778,
      -0.03764345496892929,
      0.00493148947134614,
      0.0005771871656179428,
      -0.039255235344171524,
      -0.09305467456579208,
      -0.026444315910339355,
      0.06773235648870468,
      -0.061886630952358246,
      -0.014243409968912601,
      -0.050209157168865204
    ],
    [
      0.004024901892989874,
      0.010996031574904919,
      -0.039639830589294434,
      0.055480290204286575,
      0.046568602323532104,
      0.0034392159432172775,
      0.01781100034713745,
      -0.057738956063985825,
      -0.027865121141076088,
      -0.03438885137438774,
      -0.04979543015360832,
      0.025192582979798317,
      0.04372900724411011,
      -0.00448810588568449,
      0.029505278915166855,
      0.011291319504380226,
      0.018609698861837387,
      -0.046997617930173874,
      -0.012465767562389374,
      0.003137529594823718,
      -0.012664778158068657,
      0.027606697753071785,
      0.008216985501348972,
      -0.030838925391435623,
      -0.07812342047691345,
      0.09655813872814178,
      -0.060363542288541794,
      -0.007771097123622894,
      0.05291074141860008,
      0.06796637177467346,
      -0.05086568370461464,
      0.020841924473643303,
      -0.011796316131949425,
      0.03836013749241829,
      -0.000837311614304781,
      0.00555832963436842,
      -0.01915263570845127,
      -0.001893089385703206,
      -0.08657846599817276,
      -0.00185927446000278,
      0.0438193641602993,
      -0.027683887630701065,
      0.034868642687797546,
      -0.05039273202419281,
      0.04030761122703552,
      -0.03486686199903488,
      -0.04454844444990158,
      0.018449924886226654,
      -0.04166344925761223,
      0.05598915368318558,
      0.005498306825757027,
      -0.026811452582478523,
      -0.03458130732178688,
      0.0011084512807428837,
      -0.009827000088989735,
      -0.06691297143697739,
      -0.07027827203273773,
      -0.009857755154371262,
      -0.05282885208725929,
      -0.0618889257311821,
      0.033927276730537415,
      0.021642528474330902,
      -0.016534844413399696,
      0.04220866039395332,
      0.005690311081707478,
      -0.029756061732769012,
      0.01052235160022974,
      0.05692902207374573,
      -0.00873322132974863,
      -0.0018892231164500117,
      0.03881314396858215,
      0.027176987379789352,
      -0.02024686150252819,
      -0.04046643152832985,
      0.029910122975707054,
      0.04649800807237625,
      -0.025595467537641525,
      -0.0014503790298476815,
      0.0031095866579562426,
      0.04110231250524521,
      -0.06943704932928085,
      0.08439045399427414,
      -0.04972827062010765,
      -0.047911711037158966,
      0.0008926046430133283,
      -0.049996133893728256,
      0.00625308183953166,
      0.012548418715596199,
      0.006129210349172354,
      -0.014930631034076214,
      -0.043389689177274704,
      -0.07073111832141876,
      0.07411857694387436,
      -0.036978233605623245,
      -0.005531830247491598,
      0.053453169763088226,
      0.04520397260785103,
      0.03282824903726578,
      0.05396473407745361,
      -0.041785262525081635,
      -0.04387550428509712,
      0.020168473944067955,
      -0.0481913797557354,
      -0.008025147952139378,
      -0.027847401797771454,
      -0.030945518985390663,
      -0.04477450251579285,
      0.020938215777277946,
      -0.040333155542612076,
      0.03146170824766159,
      -0.022891439497470856,
      -0.04095888510346413,
      -0.0557054802775383,
      -0.008330144919455051,
      0.006556923501193523,
      0.03212503343820572,
      -0.0927068367600441,
      0.0010524401441216469,
      0.007707061246037483,
      0.023160750046372414,
      0.010537981986999512,
      -0.0504300631582737,
      0.010328379459679127,
      0.013304296880960464,
      -0.03801174461841583,
      -0.10639327764511108,
      0.07855357974767685,
      -0.047800786793231964,
      -0.05116472765803337,
      -0.013647537678480148,
      0.02396155148744583,
      0.10192761570215225,
      0.028765292838215828,
      -0.033294059336185455,
      0.038690485060214996,
      0.021479088813066483,
      0.034080930054187775,
      0.11071600764989853,
      -0.015180316753685474,
      -0.043372124433517456,
      0.05403907969594002,
      -0.03452624753117561,
      0.019878851249814034,
      -0.012605622410774231,
      -0.07041805237531662,
      -0.01347532868385315,
      -0.060800701379776,
      0.07340461760759354,
      0.02731725573539734,
      0.01348502654582262,
      -0.019649688154459,
      0.020622964948415756,
      0.059450943022966385,
      0.0112748509272933,
      -0.04726755991578102,
      -0.07363688945770264,
      0.006792629603296518,
      -0.06017138808965683,
      0.04313036799430847,
      0.058855995535850525,
      -0.04473979398608208,
      -0.022199491038918495,
      -0.06944408267736435,
      -0.10543494671583176,
      -0.02947256900370121,
      -0.004241622053086758,
      0.04267827048897743,
      0.00599339185282588,
      0.058604646474123,
      -0.005222647450864315,
      0.025290029123425484,
      -0.03527834266424179,
      0.03802948817610741,
      0.03517666831612587,
      0.03366348147392273,
      0.026750080287456512,
      0.018994012847542763,
      0.009414786472916603,
      -0.047855932265520096,
      0.05257128179073334,
      -0.037693604826927185,
      -0.007288223598152399,
      -0.017436804249882698,
      -0.015496667474508286,
      -0.03526096045970917,
      -0.06894945353269577,
      0.0728209838271141,
      -0.04129881039261818,
      0.02088903822004795,
      0.02413043938577175,
      -0.006937341298907995,
      0.03222091868519783,
      -0.027831854298710823,
      0.021816791966557503,
      0.04581065475940704,
      -0.016072986647486687,
      0.07043199241161346,
      -0.028682542964816093,
      0.046388834714889526,
      -0.01794801466166973,
      -0.11390650272369385,
      0.00706494553014636,
      0.013956835493445396,
      -0.00783031526952982,
      0.06732339411973953,
      0.008990183472633362,
      0.06056485325098038,
      -0.03460681438446045,
      -0.06070796027779579,
      0.019393183290958405,
      -0.003968370612710714,
      0.01907026395201683,
      0.09600464254617691,
      -0.043734997510910034,
      -0.028072210028767586,
      -0.0016648488817736506,
      -0.022912681102752686,
      0.04624383896589279,
      -0.0512683242559433,
      -0.04379591345787048,
      -0.014145259745419025,
      0.051768504083156586,
      -0.09149997681379318,
      0.044926922768354416,
      -0.042012471705675125,
      -0.014568918384611607,
      -0.016925690695643425,
      0.014477585442364216,
      -0.06901317834854126,
      0.008214482106268406,
      -0.026629090309143066,
      -0.01277383603155613,
      -0.07090704143047333,
      0.09002245962619781,
      0.001875947811640799,
      -0.01932167261838913,
      0.058716464787721634,
      -0.02654585987329483,
      0.015306847169995308,
      0.007073028013110161,
      -0.0107391607016325,
      -0.0512673556804657,
      -0.06828819960355759,
      -0.01601548120379448,
      0.023188915103673935,
      0.04758473113179207,
      0.07335226237773895,
      0.01730860210955143,
      0.032781098037958145,
      0.006685344502329826,
      0.06471218168735504,
      -0.015673894435167313,
      -0.002060512313619256,
      0.00912562757730484,
      -0.006142111029475927,
      -0.021969014778733253,
      0.04200845584273338,
      -0.04545203223824501,
      0.012824100442230701,
      0.05520201846957207,
      -0.0153120718896389,
      0.12258874624967575,
      -0.028629137203097343,
      0.011706557124853134,
      0.008958582766354084,
      0.022504685446619987,
      -0.03522385284304619,
      0.020584318786859512,
      0.01165725477039814,
      0.04446829482913017,
      -0.09190411865711212,
      -0.018745068460702896,
      -0.08196768909692764,
      0.05670835077762604,
      -0.0567665733397007,
      -0.027697114273905754,
      0.0336984321475029,
      -0.10034803301095963,
      0.012517067603766918,
      0.05418534576892853,
      -0.04936566203832626,
      0.006772948894649744,
      -0.045034684240818024,
      0.07999999821186066,
      -0.033082131296396255,
      0.008518556132912636,
      0.017331821843981743,
      0.053030092269182205,
      0.05372738093137741,
      -0.02905772626399994,
      -0.034818146377801895,
      -0.025362471118569374,
      0.006893059704452753,
      -0.00868966430425644,
      -0.05369460582733154,
      0.12367889285087585,
      0.031443383544683456,
      -0.017430244013667107,
      0.027511363849043846,
      0.025529975071549416,
      0.07096455246210098,
      -0.056998711079359055,
      0.03591568022966385,
      -0.00285108620300889,
      0.07852480560541153,
      0.027947962284088135,
      0.03127514198422432,
      0.08317214995622635,
      -0.030384832993149757,
      0.03556303307414055,
      0.04480642080307007,
      0.057527102530002594,
      -0.06755533814430237,
      0.033896781504154205,
      -0.03666236996650696,
      -0.0018434727098792791,
      0.058913446962833405,
      0.025958461686968803,
      -0.036063872277736664,
      0.05310302600264549,
      -0.026556923985481262,
      -0.09443441778421402,
      -0.03908956050872803,
      0.007371529936790466,
      -0.023719048127532005,
      -0.00392838753759861,
      0.01901266910135746,
      -0.03496355190873146,
      -0.012958809733390808,
      0.01008534338325262,
      -0.011378902941942215,
      0.009228378534317017,
      0.019837161526083946,
      -0.04836156591773033,
      0.0010746564948931336,
      -0.08804356306791306,
      0.019257619976997375,
      -0.007024218328297138,
      -0.018710343167185783,
      0.020943792536854744,
      -0.009895880706608295,
      -0.015228115022182465,
      0.06456221640110016,
      0.007887402549386024,
      0.012714788317680359,
      0.03008197247982025,
      -0.04634936526417732,
      -0.015523813664913177,
      0.01085173711180687,
      0.012289161793887615,
      0.007338530384004116,
      0.07907163351774216,
      -0.04402194544672966,
      0.06476783752441406,
      0.00518844136968255,
      -0.051815446466207504,
      -0.024006947875022888,
      -0.06895779073238373,
      0.004059917759150267,
      0.048405829817056656,
      -0.06806499511003494,
      -0.06491746008396149,
      -0.025365011766552925,
      0.03984823077917099,
      -0.014475311152637005,
      0.04186589643359184,
      -0.0007307148771360517,
      -0.028637994080781937,
      0.07903233915567398,
      -0.02548867277801037,
      -0.06661773473024368,
      0.024963300675153732,
      0.04912176355719566,
      0.013073225505650043,
      -0.058938536792993546,
      -0.07235688716173172,
      -0.020082050934433937,
      -0.022865967825055122,
      -0.016342604532837868,
      0.02027350477874279,
      -0.04335065931081772,
      -0.04033534973859787,
      -0.07846904546022415,
      -0.01169803086668253,
      0.0004167880106251687,
      -0.008419722318649292,
      -0.020399784669280052,
      -0.015308001078665257,
      -0.03343807905912399,
      0.02743549458682537,
      0.08333410322666168,
      -0.060153014957904816,
      0.02258586324751377,
      0.07765433937311172,
      -0.012813991867005825,
      0.07628379762172699,
      0.032831452786922455,
      0.0045656682923436165,
      -0.011027694679796696,
      -0.06662847101688385,
      0.0328187495470047,
      0.02758985199034214,
      0.022853665053844452,
      -0.025587646290659904,
      0.014839441515505314,
      0.026855384930968285,
      0.13182736933231354,
      -0.015460297465324402,
      -0.06301561743021011,
      0.006420519202947617,
      -0.015566406771540642,
      0.07512790709733963,
      0.019018812105059624,
      0.08414019644260406,
      -0.02813585475087166,
      -0.030829129740595818,
      0.11224642395973206,
      -0.02813713252544403,
      0.04944939166307449,
      0.00697648199275136,
      -0.010672817006707191,
      0.07343912124633789,
      0.015381976962089539,
      -0.05727052688598633,
      0.08963825553655624,
      -0.049168068915605545,
      0.042910587042570114,
      0.056472476571798325,
      0.03442874923348427,
      -0.0071283988654613495,
      0.06610725820064545,
      -0.07582907378673553,
      -0.033344488590955734,
      -0.010855009779334068,
      -0.06453008949756622,
      -0.033386409282684326,
      0.027042364701628685,
      0.02844160981476307,
      0.04976232349872589,
      -0.01567665860056877,
      0.02628323994576931,
      -0.005364596378058195,
      -0.024613434448838234,
      0.006244503427296877,
      -0.015175892040133476,
      0.001619955524802208,
      0.020552145317196846,
      0.009418786503374577,
      -0.015724778175354004,
      0.051526471972465515,
      0.07715673744678497,
      -0.0026712387334555387,
      0.023931361734867096,
      0.05036429315805435,
      -0.02668865956366062,
      -0.0014772748108953238,
      -0.049793146550655365,
      -0.024031324312090874,
      -0.04828038066625595,
      -0.0265330970287323,
      0.00973931048065424,
      -0.004231927450746298,
      -0.05413707718253136,
      0.004029977135360241,
      -0.013455730862915516,
      -0.0017670660745352507,
      -0.02798336185514927,
      -0.013240830972790718,
      -0.12700355052947998,
      0.008235838264226913,
      -0.009975874796509743,
      -0.012990119867026806,
      0.041072867810726166,
      -0.07792390882968903,
      -0.01794065162539482,
      0.03212057799100876,
      0.022359736263751984,
      -0.0071367560885846615,
      0.020804937928915024,
      -0.02110859751701355,
      0.07144228368997574,
      -0.05344612896442413,
      -0.04818198084831238,
      -0.05587414279580116,
      -0.017522359266877174,
      -0.007526778616011143,
      0.03553495928645134,
      0.002804237650707364,
      0.084450364112854,
      0.04273102432489395,
      0.09632586687803268,
      0.032980628311634064,
      0.013747471384704113,
      -0.019530804827809334,
      -0.0024936895351856947,
      -0.06102054566144943,
      -0.03810996562242508,
      0.04179314896464348,
      -0.02544362097978592,
      0.07757802307605743,
      0.0652708038687706,
      0.07300104945898056,
      -0.005031676962971687
    ],
    [
      0.010875963605940342,
      -0.009971040301024914,
      0.010638355277478695,
      0.03842022269964218,
      -0.04083026945590973,
      -0.05745086446404457,
      0.005471111740916967,
      -0.048895470798015594,
      0.013010604307055473,
      0.025210749357938766,
      -0.06992477178573608,
      -0.01519917231053114,
      -0.10494094341993332,
      -0.03560835123062134,
      0.055231936275959015,
      -0.006257758941501379,
      -0.043609555810689926,
      -0.02434532158076763,
      0.014570209197700024,
      -0.04926978051662445,
      -0.02571807987987995,
      -0.06590553373098373,
      0.05451260507106781,
      0.0323360301554203,
      -0.08218707144260406,
      -0.07450292259454727,
      -0.05794589966535568,
      -0.024660449475049973,
      -0.005369310732930899,
      0.029118012636899948,
      0.02392633631825447,
      -0.018501196056604385,
      -0.13494427502155304,
      -0.015785910189151764,
      -0.03819193318486214,
      0.0073100244626402855,
      -0.009754791855812073,
      -0.015933234244585037,
      0.01109490729868412,
      0.029019227251410484,
      0.029547329992055893,
      0.07269386202096939,
      -0.026372915133833885,
      0.03989928215742111,
      0.02596360258758068,
      0.05093049630522728,
      -0.05249461159110069,
      -0.04231513291597366,
      0.018803829327225685,
      -0.02604958415031433,
      -0.028182867914438248,
      0.0008659841842018068,
      -0.004405011422932148,
      -0.06213410571217537,
      -0.0340905487537384,
      0.004558484070003033,
      -0.135697603225708,
      -0.01774369366466999,
      0.01684393547475338,
      -0.040691692382097244,
      0.030608749017119408,
      0.002753694774582982,
      0.05393750220537186,
      -0.05066762492060661,
      0.013053255155682564,
      0.03439293056726456,
      0.007465797010809183,
      0.06576910614967346,
      -0.09040813148021698,
      0.033396851271390915,
      0.08912552893161774,
      -0.012405234389007092,
      0.01633041724562645,
      0.029848892241716385,
      -0.01927013508975506,
      -0.00930352509021759,
      -0.0430641733109951,
      -0.023186909034848213,
      -0.009665866382420063,
      0.02788527123630047,
      0.046597350388765335,
      0.10313735157251358,
      -0.010507682338356972,
      -0.05089154466986656,
      0.018902398645877838,
      -0.000617399113252759,
      -0.017293401062488556,
      -0.01298642810434103,
      -0.010633556172251701,
      -0.004922043066471815,
      0.040570683777332306,
      0.09201692789793015,
      -0.003950953017920256,
      0.038261327892541885,
      -0.009616567753255367,
      0.011447395198047161,
      -0.024610821157693863,
      -0.1477094143629074,
      0.022011466324329376,
      -0.01460832729935646,
      -0.0507589690387249,
      -0.013601288199424744,
      0.06602459400892258,
      0.0069397189654409885,
      0.04855123907327652,
      -0.066582590341568,
      -0.0030073917005211115,
      0.0014466545544564724,
      -0.031039273366332054,
      0.05697500333189964,
      -0.03129550814628601,
      -0.001843909965828061,
      -0.00392463244497776,
      0.054290782660245895,
      -0.07489205151796341,
      -0.055307552218437195,
      0.006986910942941904,
      0.028723491355776787,
      -0.06253167986869812,
      0.014623632654547691,
      0.007575218100100756,
      0.04806059971451759,
      0.037163011729717255,
      -0.019979754462838173,
      0.046241529285907745,
      -0.0734696239233017,
      0.004655040334910154,
      0.0010825403733178973,
      -0.030750030651688576,
      -0.1524650603532791,
      0.03649688512086868,
      0.04285971447825432,
      0.06465793401002884,
      -0.03884469345211983,
      0.008963949047029018,
      0.016920480877161026,
      -0.11343146115541458,
      0.0013434902066364884,
      0.04180166870355606,
      -0.06942147761583328,
      -0.054692890495061874,
      -0.019777199253439903,
      -0.039954233914613724,
      0.006249922793358564,
      -0.043941106647253036,
      0.06978685408830643,
      0.05825447663664818,
      0.02505188249051571,
      -0.03590044006705284,
      -0.06986726075410843,
      -0.03484456241130829,
      -0.06931141018867493,
      0.0555344857275486,
      -0.019726423546671867,
      -0.011327099055051804,
      0.060895830392837524,
      0.014657530002295971,
      0.03851873800158501,
      -0.006068438291549683,
      -0.02104801870882511,
      -0.0064419046975672245,
      -0.04480094462633133,
      -0.054250288754701614,
      0.03928609564900398,
      -0.0035332273691892624,
      -0.004471140448004007,
      0.043954998254776,
      0.04243551939725876,
      0.013212427496910095,
      -0.0013481841888278723,
      -0.051268190145492554,
      0.005832184571772814,
      0.04396306723356247,
      0.01306714117527008,
      -0.09372913837432861,
      0.06558353453874588,
      -0.0024792158510535955,
      0.013519974425435066,
      0.033711615949869156,
      0.06492944806814194,
      0.02141561731696129,
      -0.06620480120182037,
      0.042860355228185654,
      -0.0018774796044453979,
      -0.0412292554974556,
      -0.009831774048507214,
      -0.02249334752559662,
      -0.045986372977495193,
      0.07886812835931778,
      0.03836742416024208,
      -0.03946521133184433,
      -0.015126852318644524,
      -0.02107257768511772,
      0.017703035846352577,
      -0.007139506749808788,
      -0.05351666733622551,
      0.021962743252515793,
      0.025764083489775658,
      0.009117389097809792,
      -0.00966884009540081,
      0.029672641307115555,
      -0.001001448486931622,
      0.004718403797596693,
      0.04038888216018677,
      0.06933732330799103,
      0.052678585052490234,
      0.01414717547595501,
      -0.050515055656433105,
      -0.07040946185588837,
      -0.07334110140800476,
      -0.10308632999658585,
      0.09801854938268661,
      -0.021190403029322624,
      0.07009231299161911,
      0.04235675185918808,
      -0.002492031082510948,
      0.010627370327711105,
      0.03044050745666027,
      -0.06801561266183853,
      0.011269805021584034,
      0.07420548051595688,
      -0.003599958959966898,
      -0.010288034565746784,
      -0.037184860557317734,
      -0.017994549125432968,
      -0.0118340365588665,
      0.024609612300992012,
      -0.0159150343388319,
      -0.08693742752075195,
      -0.026531744748353958,
      -0.043372247368097305,
      0.07081219553947449,
      0.0021010003983974457,
      0.12398284673690796,
      0.03226405754685402,
      0.018283771350979805,
      -0.026650583371520042,
      -0.03779347985982895,
      -0.04110191762447357,
      0.0006155493319965899,
      -0.09327953308820724,
      -0.023064348846673965,
      0.021017970517277718,
      -0.060935478657484055,
      0.03704572468996048,
      -0.005181924439966679,
      -0.0015638874610885978,
      -0.020183386281132698,
      -0.0018752660835161805,
      -0.00932936929166317,
      -0.014695879071950912,
      0.004305312875658274,
      -0.02039491757750511,
      -0.028309401124715805,
      0.03868675231933594,
      0.038813747465610504,
      0.04434843361377716,
      0.04023094102740288,
      -0.09109175950288773,
      -0.0012133127311244607,
      0.039001889526844025,
      -0.03836587816476822,
      -0.05846139043569565,
      -0.018216097727417946,
      0.07069037854671478,
      0.07576469331979752,
      0.00284344470128417,
      0.050293490290641785,
      0.023436548188328743,
      -1.8886393036154914e-06,
      -0.017464587464928627,
      0.04645059257745743,
      -0.008770270273089409,
      0.05144234746694565,
      -0.0725635439157486,
      0.048190757632255554,
      -0.02063942328095436,
      -0.014431229792535305,
      0.12959979474544525,
      0.01848178170621395,
      0.030989091843366623,
      0.05828363820910454,
      0.004290869925171137,
      0.046313896775245667,
      0.014640996232628822,
      -0.03922586143016815,
      0.05871536210179329,
      0.02526148222386837,
      -0.04892434552311897,
      0.017731809988617897,
      0.01907268911600113,
      0.040025535970926285,
      0.059944842010736465,
      0.008476927876472473,
      -0.00972597487270832,
      0.02870110608637333,
      0.017535775899887085,
      0.016741089522838593,
      -0.006219071801751852,
      0.039349015802145004,
      -0.045518677681684494,
      0.013350027613341808,
      -0.06075890362262726,
      -0.038480471819639206,
      0.040183279663324356,
      0.01639607734978199,
      0.024621814489364624,
      -0.006366823799908161,
      -0.03676372021436691,
      -0.03761793673038483,
      0.021704867482185364,
      0.016028866171836853,
      -0.014540094882249832,
      -0.025973251089453697,
      -0.005833635572344065,
      0.04882470890879631,
      0.04332425072789192,
      0.036753252148628235,
      -0.03645746409893036,
      0.014966252259910107,
      -0.031695928424596786,
      0.0028633761685341597,
      -0.08897969871759415,
      0.059886809438467026,
      -0.010616118088364601,
      0.05086224526166916,
      0.019325844943523407,
      -0.024292748421430588,
      -0.0892142653465271,
      -0.05446694791316986,
      -0.006512833759188652,
      -0.09043900668621063,
      0.05271345376968384,
      0.04924065247178078,
      -0.0002900640538427979,
      -0.08713080734014511,
      0.09953576326370239,
      -0.017109932377934456,
      -0.05618825927376747,
      0.010844491422176361,
      -0.0819334164261818,
      0.007058994844555855,
      -0.0074319965206086636,
      0.003871224820613861,
      6.197088077897206e-05,
      -0.08434829115867615,
      -0.04858161136507988,
      -0.07690112292766571,
      0.034166786819696426,
      -0.02697625197470188,
      -0.012064255774021149,
      0.0334189347922802,
      0.017496222630143166,
      -0.02274986170232296,
      -0.011478208005428314,
      0.02468695491552353,
      0.022105902433395386,
      0.052453961223363876,
      0.11551220715045929,
      0.06605542451143265,
      0.0044550346210598946,
      -0.03551876172423363,
      -0.03484581410884857,
      0.013529026880860329,
      0.00638133380562067,
      0.052123066037893295,
      -0.07416637241840363,
      0.047401536256074905,
      -0.01682187244296074,
      -0.06830088794231415,
      -0.017491724342107773,
      -0.026852179318666458,
      0.022902725264430046,
      -0.005046722013503313,
      -0.007178159896284342,
      -0.044430166482925415,
      -0.04525797814130783,
      0.0074469721876084805,
      0.03469942510128021,
      -0.00595553545281291,
      -0.01651889830827713,
      -0.07617256045341492,
      -0.05872773379087448,
      0.07885719835758209,
      -0.047659922391176224,
      -0.08259467035531998,
      0.02777237445116043,
      -0.012189757078886032,
      0.0042023975402116776,
      -0.04352067410945892,
      -0.02184801921248436,
      -0.06739598512649536,
      0.028275197371840477,
      0.03489280864596367,
      0.04254592955112457,
      0.08264151215553284,
      -0.00558294914662838,
      -0.07099325954914093,
      -0.010717862285673618,
      -0.03923417255282402,
      -0.05361313372850418,
      0.07126085460186005,
      -0.02002621814608574,
      0.07057490944862366,
      0.0656920075416565,
      -0.06550268083810806,
      -0.04790138080716133,
      -0.06479189544916153,
      -0.0664115846157074,
      0.06465774029493332,
      -0.00768785597756505,
      -0.0037322978023439646,
      -0.042425476014614105,
      -0.014081091620028019,
      -0.05267550051212311,
      -0.05268086865544319,
      -0.017970368266105652,
      0.022063665091991425,
      -0.05400708690285683,
      0.06457968056201935,
      -0.11973270773887634,
      -0.01743924990296364,
      -0.06410708278417587,
      0.08125694841146469,
      -0.04250369966030121,
      0.01471040491014719,
      0.06493356078863144,
      -0.036937884986400604,
      0.023951493203639984,
      -0.12411366403102875,
      0.04836445301771164,
      -0.020471196621656418,
      -0.0021538278087973595,
      -0.010107229463756084,
      -0.02163272351026535,
      0.0029927839059382677,
      0.020752761512994766,
      0.046620145440101624,
      0.031786903738975525,
      -0.03895719349384308,
      0.032098978757858276,
      -0.016850709915161133,
      0.050992511212825775,
      -0.056755609810352325,
      0.013969045132398605,
      0.02794811688363552,
      -0.06109319254755974,
      -0.017217643558979034,
      0.1202535331249237,
      -0.058260492980480194,
      0.008736285381019115,
      -0.013600684702396393,
      0.03568118065595627,
      -0.029534386470913887,
      -0.03603190556168556,
      -0.005705896764993668,
      0.025160683318972588,
      0.03733782470226288,
      0.05548187717795372,
      0.04919303581118584,
      -0.00020725007925648242,
      -0.015494583174586296,
      0.023533150553703308,
      -0.06350929290056229,
      0.04361504688858986,
      -0.013319631107151508,
      0.024973876774311066,
      0.00871418509632349,
      -0.0361463837325573,
      0.11158117651939392,
      -0.030050136148929596,
      0.0181670393794775,
      -0.0221920944750309,
      0.014693479053676128,
      -0.0072166165336966515,
      -0.08109743148088455,
      0.08918429911136627,
      -0.013791488483548164,
      0.02835579589009285,
      -0.004987910855561495,
      -0.08842270076274872,
      -0.050804562866687775,
      -0.015559026971459389,
      -0.013931351713836193,
      0.0036018118262290955,
      0.012244809418916702,
      0.03395304083824158,
      -0.03634880855679512,
      -0.036131855100393295,
      -0.007420398760586977,
      -0.019478900358080864,
      0.000628899724688381,
      0.035095758736133575,
      0.022604361176490784,
      0.0030477605760097504,
      0.016010817140340805,
      0.04753363877534866,
      0.027452943846583366,
      -0.014055571518838406,
      0.01661675050854683,
      -0.009395930916070938,
      0.020900901407003403,
      0.006165832746773958
    ],
    [
      0.05418533459305763,
      0.05798565223813057,
      0.026627691462635994,
      0.05774658918380737,
      -0.028652014210820198,
      0.03561980277299881,
      0.06089639663696289,
      -0.01497759111225605,
      0.015278024598956108,
      0.021004019305109978,
      0.04373424872756004,
      -0.06066432222723961,
      -0.06334588676691055,
      -0.022671330720186234,
      -0.0316595621407032,
      -0.011327612213790417,
      0.0818062350153923,
      -0.09474104642868042,
      0.015113859437406063,
      -0.026886938139796257,
      -0.055253177881240845,
      -0.09777239710092545,
      0.03824711963534355,
      0.0006709881126880646,
      -0.028675174340605736,
      -0.022537872195243835,
      0.020322030410170555,
      -0.015526924282312393,
      -0.01979663223028183,
      -0.02201300673186779,
      -0.033620867878198624,
      0.019791796803474426,
      0.027786830440163612,
      0.015569309704005718,
      -0.002952749840915203,
      0.028973016887903214,
      0.05174992233514786,
      0.0066627985797822475,
      -0.06000032648444176,
      0.06294999271631241,
      -0.04719887673854828,
      -0.07788310945034027,
      0.08338700234889984,
      0.04711300507187843,
      -0.0423329658806324,
      -0.0969884917140007,
      0.054364752024412155,
      0.037244100123643875,
      -0.024688534438610077,
      -0.015641260892152786,
      -0.06061311811208725,
      0.032601043581962585,
      -0.019127197563648224,
      0.04310337454080582,
      0.025943242013454437,
      -0.08656269311904907,
      -0.04468012601137161,
      0.02774531953036785,
      -0.008222906850278378,
      0.024102119728922844,
      0.07934360206127167,
      -0.017426740378141403,
      -0.0196994561702013,
      0.04966264218091965,
      -0.010597463697195053,
      -0.05863850936293602,
      -0.0505681186914444,
      0.054326411336660385,
      0.003936144057661295,
      -0.027314981445670128,
      0.012352515012025833,
      0.00785372219979763,
      -0.023233041167259216,
      -0.026721715927124023,
      0.05267588049173355,
      -0.09009408950805664,
      -0.03776664659380913,
      0.0009237640188075602,
      -0.006049704737961292,
      -0.06743758171796799,
      -0.022850913926959038,
      0.01542353443801403,
      0.03872590512037277,
      -0.09882564097642899,
      -0.07410808652639389,
      0.008056390099227428,
      0.016261039301753044,
      -0.0394844152033329,
      0.10200226306915283,
      -0.01737751066684723,
      0.00511440634727478,
      -0.06919258087873459,
      0.06283970177173615,
      -0.031154200434684753,
      0.05003591254353523,
      0.008347658440470695,
      0.032491616904735565,
      -0.04465947300195694,
      -0.0005260974285192788,
      -0.041665706783533096,
      -0.03304939344525337,
      0.02561214752495289,
      0.03624500334262848,
      -0.04414818808436394,
      -0.021792784333229065,
      0.01822289265692234,
      -0.0540098212659359,
      -0.016563259065151215,
      -0.08711721003055573,
      0.006179416086524725,
      0.03752481937408447,
      -0.05823809653520584,
      0.04408654570579529,
      -0.010386543348431587,
      0.007656550500541925,
      -0.0324067696928978,
      -0.057979732751846313,
      0.01931091956794262,
      -0.0025039399042725563,
      0.018516765907406807,
      -0.014708194881677628,
      0.021908896043896675,
      -0.0946388840675354,
      -0.022848477587103844,
      -0.012023504823446274,
      -0.035673338919878006,
      -0.02026180922985077,
      9.770120959728956e-05,
      0.015078325755894184,
      0.013583828695118427,
      0.036750659346580505,
      0.028619125485420227,
      -0.0035517974756658077,
      0.04057461768388748,
      0.047356005758047104,
      -0.014018382877111435,
      -0.023412225767970085,
      -0.07354903221130371,
      -0.05802752077579498,
      -0.0604352131485939,
      0.021837972104549408,
      0.0338820144534111,
      0.015388760715723038,
      0.0430624857544899,
      -0.038008809089660645,
      0.03159267455339432,
      0.05988065525889397,
      -0.050968799740076065,
      -0.05930940806865692,
      0.03170929104089737,
      -0.019480211660265923,
      -0.019984213635325432,
      0.023665091022849083,
      0.0310639888048172,
      0.0247784536331892,
      -0.07236793637275696,
      0.06553013622760773,
      0.04309382289648056,
      0.009651350788772106,
      0.02164464257657528,
      0.044920582324266434,
      0.08960051089525223,
      -0.07526134699583054,
      -0.03653153404593468,
      0.051950372755527496,
      0.025547455996274948,
      0.009067242033779621,
      0.011218477971851826,
      0.08414047956466675,
      -0.07407767325639725,
      -0.024061860516667366,
      -0.07481153309345245,
      -0.0008303007343783975,
      -0.046431515365839005,
      -0.008528843522071838,
      -0.09315629303455353,
      0.05091124400496483,
      0.02069866843521595,
      0.007670703809708357,
      0.08543308824300766,
      0.0035067785065621138,
      -0.07197529822587967,
      -0.04820951074361801,
      0.048699475824832916,
      -0.0035246459301561117,
      -0.00414784112945199,
      0.0001759511505952105,
      -0.005975538399070501,
      -0.023425817489624023,
      0.0059042684733867645,
      -0.07916183769702911,
      -0.05634492635726929,
      -0.03422075882554054,
      -0.039831772446632385,
      -0.00516982888802886,
      -0.02934778481721878,
      0.021245528012514114,
      0.011732653714716434,
      -0.01707734912633896,
      0.07415919750928879,
      0.011947499588131905,
      0.013746819458901882,
      -0.007828816771507263,
      0.03415454924106598,
      0.12846063077449799,
      -0.012429459020495415,
      0.03963209688663483,
      0.026620468124747276,
      0.0499371662735939,
      0.00315293250605464,
      -0.04628895968198776,
      0.09766257554292679,
      -0.0838233157992363,
      -0.029569368809461594,
      0.05071711540222168,
      -0.01834803819656372,
      -0.021034875884652138,
      -0.020991036668419838,
      0.040424056351184845,
      -0.011174671351909637,
      0.09153244644403458,
      0.0749925822019577,
      -0.07390574365854263,
      0.03787346929311752,
      -0.07878521084785461,
      0.01723770797252655,
      0.02066846191883087,
      0.0037916088476777077,
      0.0002528125769458711,
      0.03197414427995682,
      -0.029022840782999992,
      0.009243994951248169,
      0.07960544526576996,
      0.01675737090408802,
      0.0002948459004983306,
      -0.022688472643494606,
      -0.01490235235542059,
      0.03428225964307785,
      -0.06022212654352188,
      0.061296992003917694,
      0.030406827107071877,
      -0.00972586590796709,
      0.01836102455854416,
      -0.0022288879845291376,
      -0.009521120227873325,
      0.03066982515156269,
      -0.01791081391274929,
      0.09605900198221207,
      -0.054404228925704956,
      0.0016301649156957865,
      -0.03850369527935982,
      0.001313611282967031,
      0.001874681212939322,
      -0.043575093150138855,
      0.022149084135890007,
      -0.07532385736703873,
      -0.008604872040450573,
      -0.016603441908955574,
      0.027339337393641472,
      -0.021268649026751518,
      0.07195980101823807,
      0.03175592049956322,
      -0.015347203239798546,
      -0.03213110193610191,
      -0.008190455846488476,
      -0.016239739954471588,
      -0.0361037440598011,
      -0.06446464359760284,
      -0.01908436045050621,
      0.010808667168021202,
      0.003963849972933531,
      -0.07464689761400223,
      -0.015844175592064857,
      -0.011911780573427677,
      -0.0026816183235496283,
      0.014090541750192642,
      -0.015029053203761578,
      -0.09924871474504471,
      -0.003002504352480173,
      0.03247692063450813,
      0.024304715916514397,
      0.04333266243338585,
      -0.019051343202590942,
      -0.05173679068684578,
      0.03895028680562973,
      -0.04938380792737007,
      0.06537069380283356,
      0.04831945523619652,
      0.09681146591901779,
      -0.06713143736124039,
      -0.04395737871527672,
      -0.039321575313806534,
      0.051974281668663025,
      -0.03480533882975578,
      -0.00034905868233181536,
      0.006544769275933504,
      -0.03145303204655647,
      -0.039313122630119324,
      -0.00742572546005249,
      -0.03234792873263359,
      0.028637854382395744,
      0.01632654294371605,
      -0.06220540776848793,
      0.011991447769105434,
      0.0010385258356109262,
      0.09251315146684647,
      0.05673355609178543,
      0.03646153211593628,
      0.04536576569080353,
      -0.06125015392899513,
      0.048484835773706436,
      -0.05465296283364296,
      -0.030855128541588783,
      -0.02160598710179329,
      0.03298143297433853,
      -0.07390689104795456,
      0.0008816798799671233,
      0.026192491874098778,
      0.07107595354318619,
      -0.025882547721266747,
      -0.10028913617134094,
      0.00017275442951358855,
      0.04843759164214134,
      0.08617415279150009,
      -0.023738685995340347,
      -0.06788472086191177,
      -0.008547527715563774,
      -0.008668270893394947,
      0.025616416707634926,
      -0.01289035938680172,
      0.10359466820955276,
      0.024880973622202873,
      0.04904046282172203,
      -0.04494534060359001,
      0.06613519787788391,
      -0.031117144972085953,
      -0.025909021496772766,
      0.01896670088171959,
      0.021730775013566017,
      -0.03399261459708214,
      -0.031128665432333946,
      0.037963420152664185,
      -0.05194544047117233,
      -0.08874472230672836,
      -0.02091560699045658,
      0.007554276380687952,
      -0.030493559315800667,
      -0.008043484762310982,
      0.06330199539661407,
      -0.07085458934307098,
      -0.03463630750775337,
      -0.03159845247864723,
      0.011878320947289467,
      -0.009804750792682171,
      -0.06610511243343353,
      0.011300229467451572,
      0.011372595094144344,
      -0.09611358493566513,
      0.005151344463229179,
      0.03350156545639038,
      0.0002806117699947208,
      0.038767535239458084,
      -0.0031431347597390413,
      0.005036571994423866,
      0.028773462399840355,
      -0.041263025254011154,
      0.02286292053759098,
      -0.04557199403643608,
      0.005928355269134045,
      -0.05144219845533371,
      0.007768841926008463,
      -0.08280256390571594,
      -0.019916880875825882,
      0.028185511007905006,
      0.042962316423654556,
      0.010385884903371334,
      -0.00869754608720541,
      0.07469415664672852,
      -0.0563933327794075,
      -0.04085420444607735,
      0.055580176413059235,
      -0.03884989023208618,
      -0.0950145423412323,
      -0.009669389575719833,
      -0.001712139113806188,
      0.0018152542179450393,
      -0.0953521728515625,
      -0.018869061022996902,
      -0.02651074156165123,
      0.04080629721283913,
      -0.04191064462065697,
      -0.06898313015699387,
      -0.011031035333871841,
      0.02530350349843502,
      0.03144868463277817,
      0.047063685953617096,
      -0.05976032465696335,
      0.0017416770569980145,
      0.026725050061941147,
      0.008818887174129486,
      -0.013347192667424679,
      -0.03131945803761482,
      0.0581577904522419,
      0.04028591141104698,
      -0.037426985800266266,
      -0.02847551554441452,
      0.0760858878493309,
      0.01865752972662449,
      -0.02433370053768158,
      0.08142673224210739,
      -0.014753499068319798,
      -0.026361314579844475,
      -0.029332738369703293,
      0.07872884720563889,
      -0.07412555068731308,
      0.0278531014919281,
      -0.11984150856733322,
      -0.1226963996887207,
      -0.022031161934137344,
      -0.020388903096318245,
      0.00894206017255783,
      -0.01484896894544363,
      0.04913981258869171,
      -0.04901480674743652,
      0.004723925609141588,
      0.04994219169020653,
      0.04229871556162834,
      0.02418222650885582,
      0.034039970487356186,
      0.04932405427098274,
      -0.06185244768857956,
      0.06314229965209961,
      -0.03282835707068443,
      0.03558981418609619,
      0.003625081619247794,
      0.018269658088684082,
      0.011220875196158886,
      0.008727844804525375,
      -0.03522418439388275,
      -0.06640168279409409,
      0.004307908937335014,
      -0.005695189815014601,
      -0.04114942252635956,
      0.0162778589874506,
      -0.006067201960831881,
      0.04856063053011894,
      0.07395534962415695,
      0.013590586371719837,
      -0.009380875155329704,
      -0.0408363901078701,
      -0.026019901037216187,
      -0.046174999326467514,
      0.10875660926103592,
      0.048688411712646484,
      0.011887376196682453,
      0.01151331141591072,
      -0.03717003017663956,
      0.04070163890719414,
      0.023885982111096382,
      -0.06666138768196106,
      0.007719742134213448,
      -0.03341035172343254,
      0.05108911171555519,
      -0.04254033416509628,
      -0.012448880821466446,
      -0.01457672193646431,
      0.10461672395467758,
      0.0442776121199131,
      0.06430035829544067,
      0.05944150686264038,
      -0.031910307705402374,
      0.05236733704805374,
      -0.0750187486410141,
      0.015152422711253166,
      -0.00800634827464819,
      0.0018081855960190296,
      0.06781487911939621,
      0.0263642817735672,
      0.028319649398326874,
      0.014995984733104706,
      -0.016255320981144905,
      0.07667718827724457,
      -0.045337576419115067,
      -0.10609803348779678,
      -0.05277979373931885,
      0.02755807898938656,
      0.047384053468704224,
      0.06829273700714111,
      0.03899865224957466,
      -0.007852849550545216,
      0.005464531015604734,
      0.0887075886130333,
      -0.0653059110045433,
      0.02437623031437397,
      0.04301896691322327,
      -0.016985828056931496,
      0.051646385341882706,
      0.03801901265978813,
      -0.009367864578962326,
      0.001710207900032401,
      -0.03780042380094528,
      -0.052086107432842255,
      -0.04908907040953636
    ],
    [
      -0.06727463006973267,
      0.022374490275979042,
      0.027390865609049797,
      0.040043819695711136,
      -0.08127003163099289,
      0.038751233369112015,
      0.049307893961668015,
      0.023275962099432945,
      -0.03538192808628082,
      -0.08290596306324005,
      -0.05015998333692551,
      -0.06130408123135567,
      -0.044508520513772964,
      -0.07922516763210297,
      -0.03055804781615734,
      0.07955927401781082,
      0.026994910091161728,
      0.05466163158416748,
      0.07432250678539276,
      -0.02941763587296009,
      0.039028655737638474,
      0.04870562255382538,
      0.038696568459272385,
      0.05337230861186981,
      0.0121156619861722,
      0.03851795196533203,
      0.035981498658657074,
      0.06913822889328003,
      -0.009626124054193497,
      0.009658265858888626,
      -0.038245972245931625,
      -0.02723211795091629,
      0.03827637806534767,
      0.08038987219333649,
      0.029045769944787025,
      0.09464480727910995,
      0.03037456050515175,
      -0.016225066035985947,
      0.0008878191583789885,
      -0.01496974565088749,
      0.015151377767324448,
      0.04926345869898796,
      0.04533391818404198,
      -0.07812173664569855,
      0.006417167838662863,
      -0.02236560918390751,
      -0.0020562305580824614,
      -0.02363092638552189,
      -0.057073771953582764,
      -0.019818386062979698,
      0.021618476137518883,
      0.031747426837682724,
      0.013916796073317528,
      -0.0027611670084297657,
      -0.0068318285048007965,
      -0.1061069592833519,
      -0.0698554590344429,
      0.05560611933469772,
      0.006176532246172428,
      0.017253970727324486,
      0.035042669624090195,
      -0.04414084553718567,
      0.0035843488294631243,
      -0.01837063580751419,
      0.05375239625573158,
      0.0049190097488462925,
      -0.004497988615185022,
      0.014065221883356571,
      0.0030512369703501463,
      0.030441373586654663,
      -0.05408048257231712,
      -0.012341159395873547,
      -0.004645832814276218,
      0.02607704885303974,
      -0.060491371899843216,
      -0.02960916981101036,
      -0.025421952828764915,
      -0.018569570034742355,
      -0.02614908665418625,
      -0.0340418815612793,
      -0.06009655445814133,
      -0.005713650956749916,
      -0.015430147759616375,
      -0.004398241173475981,
      0.0034899346064776182,
      -0.015806620940566063,
      0.08538676798343658,
      -0.025064123794436455,
      0.02838241681456566,
      0.0003711874014697969,
      -0.03248431906104088,
      -0.04993043094873428,
      -0.02650454081594944,
      -0.003788016503676772,
      0.049751948565244675,
      0.0036224795039743185,
      0.07930440455675125,
      0.005310325417667627,
      0.09730854630470276,
      0.017303617671132088,
      0.01694438047707081,
      0.05996227636933327,
      -0.0528164878487587,
      0.07262756675481796,
      -0.015867263078689575,
      0.027906173840165138,
      -0.0308469757437706,
      -0.005662532523274422,
      -0.001574907568283379,
      -0.016873283311724663,
      0.0737026184797287,
      0.0032945536077022552,
      0.03598405793309212,
      -0.006005779840052128,
      -0.02403760887682438,
      -0.02941766008734703,
      0.025408446788787842,
      0.0660848394036293,
      -0.02234732173383236,
      -0.01831328123807907,
      -0.03113958053290844,
      0.031004341319203377,
      0.03487275540828705,
      0.038102325052022934,
      0.07848995923995972,
      0.027417033910751343,
      0.012012970633804798,
      0.0239131897687912,
      0.05137559399008751,
      0.0426735058426857,
      0.030361197888851166,
      -0.08160299807786942,
      -0.01469473633915186,
      0.05190862715244293,
      -0.010203137993812561,
      0.0038131866604089737,
      -0.01943337731063366,
      0.037245504558086395,
      -0.006151292473077774,
      0.052565157413482666,
      0.004381363280117512,
      -0.04131113737821579,
      0.035634852945804596,
      -0.08009004592895508,
      -0.04898817092180252,
      0.0008064863504841924,
      -0.009047186933457851,
      0.015514368191361427,
      -0.014092698693275452,
      -0.00037957145832479,
      -0.008497313596308231,
      -0.048793159425258636,
      0.01240770798176527,
      0.0386928915977478,
      -0.006970657035708427,
      -0.06854968518018723,
      0.008040708489716053,
      0.005001611076295376,
      0.023360662162303925,
      -0.007687952369451523,
      -0.04028133675456047,
      -0.025290438905358315,
      -0.06090696156024933,
      -0.007835284806787968,
      0.0856827050447464,
      -0.03937320411205292,
      0.03230937197804451,
      0.03511868044734001,
      0.03140399605035782,
      0.007503662724047899,
      0.05376240238547325,
      -0.01684054546058178,
      0.044929467141628265,
      -0.02011227235198021,
      -0.0713907852768898,
      0.004427293315529823,
      -0.0740162655711174,
      -0.004892068449407816,
      -0.05539493262767792,
      0.06740503758192062,
      0.05859888717532158,
      0.018296195194125175,
      -0.003999677021056414,
      0.041180647909641266,
      0.05534755811095238,
      -0.05528172478079796,
      0.015878809615969658,
      -0.021455883979797363,
      0.03878878802061081,
      -0.02036439999938011,
      -0.002891719341278076,
      -0.06266148388385773,
      -0.016221288591623306,
      -0.03146445006132126,
      0.06895013898611069,
      -0.030577171593904495,
      0.047813139855861664,
      0.015667900443077087,
      -0.1766771674156189,
      0.03330392763018608,
      0.011828855611383915,
      0.028681747615337372,
      -0.030836399644613266,
      0.01569097861647606,
      -0.008774601854383945,
      -0.002694134134799242,
      0.00982140563428402,
      -0.04950800538063049,
      0.019339408725500107,
      0.041901595890522,
      0.00712293665856123,
      0.055390920490026474,
      0.05037549510598183,
      -0.019817667081952095,
      -0.015717824921011925,
      0.05185800790786743,
      -0.007856578566133976,
      -0.03619964420795441,
      0.046620238572359085,
      0.02336709015071392,
      -0.03293924033641815,
      0.08792690187692642,
      -0.009939179755747318,
      0.03338995948433876,
      -0.01179856900125742,
      -0.028865577653050423,
      -0.0434713214635849,
      0.01713847555220127,
      -0.05424823239445686,
      0.03013821318745613,
      -0.06099720671772957,
      0.05544228479266167,
      0.00405567092821002,
      0.021469062194228172,
      -0.09214773029088974,
      0.0068243443965911865,
      -0.023146502673625946,
      0.0267105121165514,
      0.030544893816113472,
      0.017233675345778465,
      -0.025311391800642014,
      0.07835777848958969,
      -0.050938062369823456,
      -0.04297870770096779,
      -0.04721534997224808,
      -0.003283189842477441,
      0.07813148945569992,
      0.022234968841075897,
      -0.09245816618204117,
      -0.03701087832450867,
      0.07328806072473526,
      -0.019328858703374863,
      -0.0019367658533155918,
      0.005302836187183857,
      -0.0309764314442873,
      -0.01411886140704155,
      0.03962375223636627,
      0.03428690880537033,
      -0.05869879946112633,
      -0.02754543535411358,
      0.09983567893505096,
      -0.00467480206862092,
      -0.014402302913367748,
      0.04250597208738327,
      0.020851947367191315,
      -0.1122642531991005,
      0.007052971515804529,
      0.025747433304786682,
      0.008924105204641819,
      0.028942152857780457,
      -0.009594492614269257,
      -0.07834857702255249,
      -0.043490078300237656,
      0.04213550686836243,
      0.0007500431383959949,
      0.04383108764886856,
      -0.03788580745458603,
      -0.08011061698198318,
      0.01098310761153698,
      -0.032297149300575256,
      0.019588245078921318,
      0.06385179609060287,
      -0.05408349633216858,
      -0.017738357186317444,
      0.02182268723845482,
      0.08110177516937256,
      -0.10855314135551453,
      0.07196308672428131,
      0.03696383163332939,
      0.041875097900629044,
      -0.04014630988240242,
      0.023209568113088608,
      -0.00802211556583643,
      -0.04574808105826378,
      0.07113818824291229,
      0.05769394710659981,
      -0.04638269171118736,
      0.05324585363268852,
      0.028974562883377075,
      -0.028666775673627853,
      0.030304688960313797,
      0.049722280353307724,
      0.0280635803937912,
      -0.061435818672180176,
      -0.04675732180476189,
      -0.041591499000787735,
      -0.016587667167186737,
      0.0840802863240242,
      -0.006717322859913111,
      -0.01900583878159523,
      -0.0008818668429739773,
      -0.003265627194195986,
      -0.012425382621586323,
      -0.004431245382875204,
      -0.03865252062678337,
      -0.10484655201435089,
      -0.029072750359773636,
      0.04271697625517845,
      0.09688728302717209,
      -0.05448690801858902,
      -0.03877033665776253,
      -0.0967811718583107,
      -0.031005745753645897,
      0.070156529545784,
      0.020101234316825867,
      0.07174921035766602,
      -0.04623628035187721,
      -0.06922601163387299,
      0.02711579203605652,
      -0.02496570535004139,
      -0.0649031475186348,
      -0.008080320432782173,
      0.08152265101671219,
      0.01960744708776474,
      0.009207714349031448,
      0.039365679025650024,
      -0.044111914932727814,
      0.02348516322672367,
      0.05225130170583725,
      -0.00015104026533663273,
      -0.046239160001277924,
      0.03505641222000122,
      0.026330573484301567,
      0.018327603116631508,
      -0.005509186070412397,
      0.05953102558851242,
      0.004364029038697481,
      0.022102050483226776,
      -0.008294370956718922,
      -0.014310384169220924,
      -0.008687185123562813,
      -0.050686415284872055,
      0.06544505059719086,
      0.0030523077584803104,
      0.016822122037410736,
      -0.0018480115104466677,
      0.06289105117321014,
      -0.024344641715288162,
      -0.01445732545107603,
      0.046641573309898376,
      -0.0823696181178093,
      -0.07201813906431198,
      -0.02079029008746147,
      0.034582871943712234,
      -0.005624239798635244,
      -0.005866945255547762,
      -0.01666915975511074,
      0.007216487545520067,
      0.010295030660927296,
      -0.0126796318218112,
      0.007730023469775915,
      -0.04241945222020149,
      0.03652195259928703,
      -0.02472412958741188,
      -0.011612662114202976,
      0.042268265038728714,
      0.02188257686793804,
      0.006633306387811899,
      0.08153808861970901,
      0.03167848661541939,
      -0.004826202057301998,
      0.08121782541275024,
      -0.04834040626883507,
      0.02563422918319702,
      -0.01273693609982729,
      0.000961882236879319,
      -0.01673663966357708,
      0.04316331446170807,
      4.151091707171872e-05,
      -0.05993044003844261,
      0.019701490178704262,
      -0.06952006369829178,
      -0.016559917479753494,
      0.008868351578712463,
      0.029028665274381638,
      -0.059825245290994644,
      -0.049422767013311386,
      -0.035697244107723236,
      -0.01831292361021042,
      0.021064557135105133,
      0.02091609127819538,
      -0.049941856414079666,
      0.023458149284124374,
      0.018653936684131622,
      0.013125725090503693,
      0.04301419109106064,
      -0.029706481844186783,
      0.07554271072149277,
      -0.05992189794778824,
      -0.01076842937618494,
      -0.006123655941337347,
      -0.019006485119462013,
      -0.03924158960580826,
      -0.04271684214472771,
      0.013554361648857594,
      -0.02822607196867466,
      0.021642552688717842,
      0.023618480190634727,
      0.09149425476789474,
      0.0926397442817688,
      0.08198480308055878,
      -0.048959698528051376,
      0.04598797485232353,
      -0.056220222264528275,
      -0.0982624888420105,
      0.05715231969952583,
      0.021864615380764008,
      0.08486700803041458,
      -0.013445847667753696,
      -0.00512919295579195,
      0.042247653007507324,
      -0.03681979328393936,
      0.10838121175765991,
      0.024119766429066658,
      0.066583052277565,
      -0.00040122345671989024,
      0.02751830779016018,
      0.014388933777809143,
      -0.01718869060277939,
      0.0630585327744484,
      0.04241670295596123,
      -0.00031812366796657443,
      -0.006987280212342739,
      -0.019243953749537468,
      -0.044694799929857254,
      -0.00337050249800086,
      0.022266332060098648,
      -0.03664453327655792,
      0.09947246313095093,
      0.022650156170129776,
      -0.01414368487894535,
      -0.01096187625080347,
      0.0009426294709555805,
      0.03519003838300705,
      0.09525150060653687,
      0.08279041200876236,
      0.050235386937856674,
      0.01694081351161003,
      -0.02131749875843525,
      0.0020636452827602625,
      -0.06374913454055786,
      -0.037365712225437164,
      0.02069750428199768,
      0.06885465234518051,
      0.012424319051206112,
      -0.004663976840674877,
      -0.0008976876270025969,
      -0.01026110164821148,
      0.007218324579298496,
      0.021876199170947075,
      -0.06759115308523178,
      -0.0058128065429627895,
      -0.03762000426650047,
      -0.014898373745381832,
      0.04606742411851883,
      -0.02945210039615631,
      -0.03247622400522232,
      0.05501702427864075,
      -0.012354224920272827,
      -0.02271917648613453,
      -0.00888950563967228,
      0.0967954769730568,
      -0.028587548062205315,
      -0.05812687799334526,
      -0.035206396132707596,
      0.03439469635486603,
      -0.019767798483371735,
      0.07647085189819336,
      -0.08543949574232101,
      0.002459953771904111,
      -0.07861447334289551,
      -0.048156652599573135,
      0.014525296166539192,
      0.02044946700334549,
      -0.002450504805892706,
      0.006862102076411247,
      -0.015652095898985863,
      0.09207725524902344,
      -0.00830361433327198,
      0.025119217112660408,
      -0.059590764343738556,
      -0.05372239276766777,
      -0.0029865410178899765
    ],
    [
      -4.434314541867934e-05,
      -0.03285914286971092,
      0.022593451663851738,
      -0.023415498435497284,
      -0.034207783639431,
      -0.08920872211456299,
      0.023396026343107224,
      -0.05349301919341087,
      0.05774203687906265,
      -0.014357922598719597,
      -0.0318903923034668,
      0.07765834033489227,
      -0.03823141008615494,
      -0.04771646484732628,
      -0.04013480618596077,
      -0.019124099984765053,
      0.015386456623673439,
      -0.028905881568789482,
      0.03871549293398857,
      0.087030328810215,
      -0.019653312861919403,
      0.03339778631925583,
      0.02360093593597412,
      -0.00200093281455338,
      -0.07347124069929123,
      0.0036263889633119106,
      -0.039326123893260956,
      0.05723820626735687,
      -0.02396429516375065,
      -0.003756147576496005,
      0.061526861041784286,
      -0.00540541484951973,
      -0.04761315509676933,
      0.0023468604777008295,
      0.035227831453084946,
      -0.02775317244231701,
      -0.0555320642888546,
      0.022000392898917198,
      -0.033343855291604996,
      0.02532319352030754,
      -0.01789613999426365,
      0.0068144416436553,
      -0.0580909289419651,
      0.05297696590423584,
      -0.0045446897856891155,
      0.014162136241793633,
      0.03729339316487312,
      -0.018379688262939453,
      -0.03770739585161209,
      -0.09429782629013062,
      -0.009409009478986263,
      -0.05250077694654465,
      -0.04694831371307373,
      0.11752066761255264,
      -0.01329577062278986,
      -0.07030105590820312,
      0.05746754631400108,
      0.04899022355675697,
      0.014817263931035995,
      -0.055311478674411774,
      0.04628846049308777,
      -0.08921721577644348,
      0.01711706630885601,
      -0.02199947088956833,
      0.07001027464866638,
      0.04938206076622009,
      -0.03406248986721039,
      -0.008284714072942734,
      0.004564265254884958,
      0.07222437113523483,
      -0.03448532149195671,
      -0.03644812852144241,
      0.05256478115916252,
      -0.00891781598329544,
      0.03212255984544754,
      -0.02840058133006096,
      0.015860555693507195,
      0.013122406788170338,
      0.035236988216638565,
      0.030770573765039444,
      -0.02438213676214218,
      -0.05067210644483566,
      -0.01100238598883152,
      -0.10258132219314575,
      -0.07193198055028915,
      -0.011687224730849266,
      0.05829504132270813,
      0.010686286725103855,
      0.0015010000206530094,
      -0.009554496966302395,
      -0.030316486954689026,
      0.058704912662506104,
      0.054883576929569244,
      0.04609359800815582,
      0.050227224826812744,
      0.028811711817979813,
      0.00431131012737751,
      -0.05344120040535927,
      -0.008609366603195667,
      0.00942070223391056,
      -0.10077328979969025,
      -0.004109152592718601,
      0.06493189930915833,
      0.010087505914270878,
      0.03802049160003662,
      0.013215597718954086,
      -0.05345718190073967,
      -0.04615388065576553,
      0.05134344846010208,
      0.03421773016452789,
      0.05254240334033966,
      0.0985339879989624,
      -0.027107032015919685,
      -0.028592707589268684,
      -0.005372221115976572,
      0.00575609877705574,
      -0.05564473941922188,
      -0.0504133477807045,
      -0.019558338448405266,
      -0.02562418021261692,
      0.017787644639611244,
      -0.09452813863754272,
      -0.006256193853914738,
      -0.02167086862027645,
      -0.04811599478125572,
      -0.003835701383650303,
      -0.038322996348142624,
      -0.023006947711110115,
      -0.0005511395866051316,
      0.0373283326625824,
      -0.016654007136821747,
      0.024131696671247482,
      -0.048912640661001205,
      0.011577814817428589,
      -0.02367740496993065,
      -0.023850092664361,
      0.047967441380023956,
      -0.0011977225076407194,
      -0.07013430446386337,
      -0.0455622598528862,
      -0.03644433617591858,
      -0.004837571177631617,
      -0.02399860881268978,
      -0.04191030189394951,
      -0.026049377396702766,
      -0.021782968193292618,
      -0.05222013592720032,
      -0.05269357189536095,
      0.08090182393789291,
      -0.031072542071342468,
      0.0617329366505146,
      -0.00401647575199604,
      -0.08336909115314484,
      -0.00044477780465967953,
      -0.0641348585486412,
      -0.07521671801805496,
      -0.009321300312876701,
      -0.06082058325409889,
      0.009312593378126621,
      0.07387959957122803,
      0.011744292452931404,
      -0.0002657977456692606,
      -0.030779343098402023,
      -0.008637912571430206,
      -0.007408906705677509,
      0.10194303840398788,
      0.03247927129268646,
      -0.039031982421875,
      -0.011772158555686474,
      0.044559676200151443,
      -0.027183473110198975,
      -0.018979838117957115,
      -0.06366613507270813,
      -0.007907825522124767,
      -0.0006077474681660533,
      -0.03665854036808014,
      0.03592819347977638,
      0.023950066417455673,
      0.07320529222488403,
      0.06253964453935623,
      -0.05121219903230667,
      0.019632136449217796,
      0.023263825103640556,
      0.004209940321743488,
      -0.006096169352531433,
      -0.059470951557159424,
      0.05161963030695915,
      0.0068671852350234985,
      0.007870219647884369,
      0.07845816761255264,
      -0.0358029380440712,
      -0.034402407705783844,
      -0.019398190081119537,
      -0.008784942328929901,
      -0.004668570589274168,
      0.05295456573367119,
      0.05545992776751518,
      0.006509358063340187,
      -0.046866778284311295,
      0.03296520933508873,
      -0.05374659597873688,
      -0.012549237348139286,
      -0.09571172297000885,
      0.04524771869182587,
      0.01639392226934433,
      0.005070212297141552,
      0.04616127163171768,
      -0.014443326741456985,
      -0.09987489134073257,
      0.016072366386651993,
      -0.03226841241121292,
      -0.03632848709821701,
      -0.06106981635093689,
      0.056141361594200134,
      0.0029399211052805185,
      0.02941197156906128,
      0.011432662606239319,
      0.01995869167149067,
      -0.09638327360153198,
      0.0016668078023940325,
      0.0034906885121017694,
      -0.016693200916051865,
      -0.0053514582104980946,
      0.03290591388940811,
      0.021708672866225243,
      0.04689280688762665,
      -0.00592019921168685,
      -0.03613997623324394,
      -0.05173468589782715,
      0.0262092724442482,
      0.0964527428150177,
      -0.04935970529913902,
      0.03744058310985565,
      -0.02123764157295227,
      -0.02251577004790306,
      -0.043375272303819656,
      0.02603638730943203,
      0.055158115923404694,
      0.0037782839499413967,
      -0.0980706587433815,
      0.05324443057179451,
      0.08124836534261703,
      -0.052248112857341766,
      0.046155452728271484,
      -0.0046358960680663586,
      0.03334546089172363,
      -0.04953031241893768,
      -0.014513946138322353,
      -0.08152822405099869,
      0.053603094071149826,
      -0.0416998453438282,
      -0.03952593356370926,
      -0.001725974609144032,
      0.005293537396937609,
      0.028140833601355553,
      0.01851492188870907,
      0.014092409051954746,
      0.023820197209715843,
      -0.000265956565272063,
      -0.04368806257843971,
      0.08298967033624649,
      0.05155474692583084,
      0.0027402020059525967,
      -0.10839104652404785,
      -0.03364547714591026,
      -0.04284445568919182,
      -0.026464207097887993,
      0.08312428742647171,
      0.017129894345998764,
      -0.000861674954649061,
      -0.011623690836131573,
      0.0038320443127304316,
      -0.08457869291305542,
      0.03781850263476372,
      -0.061123188585042953,
      -0.012267050333321095,
      -0.0533348023891449,
      -0.09410180151462555,
      -0.027115754783153534,
      0.019342690706253052,
      -0.005950636696070433,
      0.01434545312076807,
      0.001974440412595868,
      0.02984241396188736,
      0.03625885397195816,
      0.03733229637145996,
      -0.008098665624856949,
      0.06163877248764038,
      0.054646942764520645,
      0.0026016493793576956,
      -0.04198204353451729,
      0.004093699157238007,
      0.042706023901700974,
      0.07851321995258331,
      -0.049885984510183334,
      -0.019262079149484634,
      -0.055540747940540314,
      -0.03106219321489334,
      0.11987818032503128,
      -0.0686638206243515,
      0.008131208829581738,
      -0.04400167986750603,
      0.05718240886926651,
      -0.020642071962356567,
      -0.07628405839204788,
      0.03758656233549118,
      -0.003456893377006054,
      0.025210143998265266,
      0.08488622307777405,
      -0.019782938063144684,
      0.015964318066835403,
      -0.025891683995723724,
      0.031116396188735962,
      0.04363381862640381,
      0.005637065507471561,
      -0.10296403616666794,
      -0.009620796889066696,
      0.02225237898528576,
      -0.02490263432264328,
      0.03897268325090408,
      -0.021742038428783417,
      -0.0416594073176384,
      -0.017629045993089676,
      0.0768890380859375,
      0.032236453145742416,
      -0.02347763627767563,
      -0.03644746169447899,
      -0.058677926659584045,
      0.021127086132764816,
      0.05422879755496979,
      -0.03930889442563057,
      -0.008310908451676369,
      -0.020450687035918236,
      0.009903248399496078,
      -0.04516494274139404,
      0.011347171850502491,
      -0.039075419306755066,
      0.09947822988033295,
      -0.04331918805837631,
      -0.0159959327429533,
      0.030144616961479187,
      0.039020173251628876,
      -0.03969995304942131,
      -0.14321622252464294,
      0.017966998741030693,
      -0.089881531894207,
      -0.0002684038772713393,
      0.03416939079761505,
      -0.014112399891018867,
      -0.020787207409739494,
      -0.015473206527531147,
      -0.02947627566754818,
      0.05134795233607292,
      0.00662115216255188,
      -0.0047055939212441444,
      -0.02463764324784279,
      -0.02502189762890339,
      0.038893599063158035,
      -0.023060010746121407,
      0.0041201128624379635,
      -0.030919767916202545,
      -0.052498046308755875,
      0.013746188022196293,
      0.1084628775715828,
      -0.031965773552656174,
      0.02373759262263775,
      -0.05350727215409279,
      0.03239867463707924,
      -0.028140949085354805,
      0.033754415810108185,
      -0.034239839762449265,
      -0.008054658770561218,
      0.016907237470149994,
      0.07895129919052124,
      0.015532146207988262,
      -0.0015643610386177897,
      0.007560389116406441,
      0.01636228710412979,
      -0.03127992898225784,
      0.022221574559807777,
      0.0014949250034987926,
      -0.0271892212331295,
      0.004562658257782459,
      -0.002514475490897894,
      0.008511852473020554,
      0.06637578457593918,
      -0.011344659142196178,
      -0.022719837725162506,
      0.017069710418581963,
      -7.291087240446359e-05,
      -0.039762191474437714,
      -0.02610684186220169,
      0.02899891696870327,
      0.0502057746052742,
      -0.002821411704644561,
      0.03727679327130318,
      -0.08968565613031387,
      0.025510555133223534,
      -0.06673640757799149,
      -0.01470301952213049,
      -0.06625522673130035,
      -0.07804804295301437,
      -0.10992743074893951,
      -0.03150153532624245,
      0.0513526014983654,
      -0.0199566800147295,
      0.007917261682450771,
      -0.04515385255217552,
      0.0633574053645134,
      0.04324718564748764,
      -0.015736356377601624,
      -0.0434919036924839,
      -0.05161382630467415,
      -0.020451638847589493,
      -0.07847043871879578,
      0.03429349511861801,
      0.004148696083575487,
      0.02386271208524704,
      0.013034775853157043,
      0.05481228977441788,
      0.06555622816085815,
      -0.033858004957437515,
      0.02447337657213211,
      0.013224257156252861,
      0.01459767296910286,
      -0.009864063933491707,
      -0.047105610370635986,
      -0.017692063003778458,
      -0.04678386077284813,
      -0.030871137976646423,
      0.03557056933641434,
      0.022405873984098434,
      -0.01850581355392933,
      0.007944541983306408,
      0.019323822110891342,
      0.06072458252310753,
      -0.0012871692888438702,
      0.01152834389358759,
      -0.04909786954522133,
      -0.004013468977063894,
      0.07475902140140533,
      -0.024051139131188393,
      -0.08282478898763657,
      0.11943302303552628,
      0.04251463711261749,
      0.06262578815221786,
      -0.042426351457834244,
      0.01064962800592184,
      0.039123695343732834,
      0.016611410304903984,
      -0.018443632870912552,
      0.0064745876006782055,
      -0.08776843547821045,
      -0.026530925184488297,
      -0.07532744109630585,
      0.028452837839722633,
      -0.08411470800638199,
      0.00473946426063776,
      -0.010744644328951836,
      0.03116573765873909,
      -0.010689440183341503,
      -0.0415351502597332,
      -0.07006333023309708,
      -0.014146462082862854,
      0.10101079195737839,
      -0.09419047087430954,
      0.04622838646173477,
      0.021138032898306847,
      -0.031423721462488174,
      -0.016850803047418594,
      -0.06784747540950775,
      0.06492161005735397,
      0.039893146604299545,
      -0.028492556884884834,
      -0.03072865679860115,
      0.029959263280034065,
      -0.005177158862352371,
      0.03198683261871338,
      0.030615350231528282,
      0.0031502728816121817,
      -0.0007785589550621808,
      0.061975374817848206,
      0.004097019322216511,
      -0.09775779396295547,
      -0.03438390791416168,
      0.08780469000339508,
      0.012191591784358025,
      0.07415991276502609,
      0.017446400597691536,
      0.03362160176038742,
      -0.08089739084243774,
      0.016113005578517914,
      -0.033846914768218994,
      -0.026832450181245804,
      0.0679672509431839,
      -0.02138262800872326,
      -0.04736506566405296,
      0.003548326203599572,
      0.02759641595184803,
      0.06305722147226334,
      0.005217899102717638,
      0.06471539288759232,
      0.09131684899330139
    ],
    [
      -0.02131473273038864,
      -0.04886437579989433,
      -0.027492135763168335,
      -0.01647801883518696,
      -0.010753879323601723,
      0.01837577112019062,
      -0.10271910578012466,
      -0.022303881123661995,
      0.12519429624080658,
      0.023334991186857224,
      -0.025857191532850266,
      -0.03759259358048439,
      0.01737719587981701,
      -0.05105651542544365,
      -0.03493203595280647,
      0.01638815738260746,
      0.055736348032951355,
      0.07115091383457184,
      0.025929778814315796,
      0.013929407112300396,
      0.015972118824720383,
      0.02151072956621647,
      0.011355562135577202,
      -0.018263040110468864,
      0.014811954461038113,
      -0.012820004485547543,
      0.022567706182599068,
      0.03315034136176109,
      -0.05817265436053276,
      0.0019537126645445824,
      0.020323216915130615,
      0.037635501474142075,
      -0.016748575493693352,
      0.07647296041250229,
      -0.008267655968666077,
      0.003260970814153552,
      0.0377344936132431,
      0.025483256205916405,
      0.04479483515024185,
      0.06800016015768051,
      -0.03910920023918152,
      -0.0059602996334433556,
      0.02063630148768425,
      -0.0676974505186081,
      -0.047940943390131,
      -0.012538856826722622,
      -0.04807071015238762,
      -0.028934840112924576,
      -0.013233189471065998,
      -0.01938779279589653,
      -0.06190825626254082,
      0.025898873805999756,
      -0.022064242511987686,
      0.03128468617796898,
      -0.03525637835264206,
      -0.03808419778943062,
      -0.044206615537405014,
      -0.08637145161628723,
      0.04451213404536247,
      0.04458623006939888,
      -0.03484610840678215,
      -0.06844626367092133,
      -0.013342239893972874,
      0.035978902131319046,
      -0.01925663836300373,
      0.07363805919885635,
      0.021497491747140884,
      -0.018555674701929092,
      0.01970350369811058,
      -0.0023592591751366854,
      0.032970182597637177,
      0.0383559986948967,
      0.017802294343709946,
      0.04333985596895218,
      0.03133532777428627,
      -0.08442704379558563,
      0.003227245295420289,
      0.05896386876702309,
      0.040290236473083496,
      -0.022620396688580513,
      -0.061153996735811234,
      0.058487698435783386,
      0.04191802069544792,
      0.020209485664963722,
      0.03075171634554863,
      -0.07995963096618652,
      0.020177749916911125,
      0.049324918538331985,
      0.039074867963790894,
      -0.028390664607286453,
      -0.017205998301506042,
      0.06461717188358307,
      0.025672363117337227,
      -0.04076841101050377,
      0.06902015954256058,
      -0.037478961050510406,
      -0.04236319661140442,
      0.006952092982828617,
      -0.02758624590933323,
      -0.0005242092302069068,
      -0.011220055632293224,
      0.07991673052310944,
      -0.025984514504671097,
      0.0843355804681778,
      -0.006380133330821991,
      -0.11269235610961914,
      -0.06281203776597977,
      -0.021908240392804146,
      -0.05272563919425011,
      0.08897418528795242,
      -0.0312819704413414,
      0.009672070853412151,
      0.06591115146875381,
      -0.07747789472341537,
      -0.009826865047216415,
      0.03677970543503761,
      -0.04642264544963837,
      -0.0778680220246315,
      0.09388695657253265,
      0.0053668757900595665,
      -0.03045283816754818,
      0.04333619400858879,
      0.03117872215807438,
      -0.060340847820043564,
      -0.038974665105342865,
      0.06443838030099869,
      -0.01919769123196602,
      0.02716405689716339,
      -0.020256703719496727,
      0.0068209609016776085,
      0.0454777330160141,
      -0.04900515079498291,
      0.0525137260556221,
      -0.03930879756808281,
      0.06866931170225143,
      0.017276331782341003,
      0.04523216933012009,
      0.02568591944873333,
      0.04168384522199631,
      -0.04343561455607414,
      0.03527406230568886,
      0.011918967589735985,
      0.045762624591588974,
      0.0389605313539505,
      -0.013533675111830235,
      0.01367325522005558,
      -0.019781379029154778,
      0.0007180730463005602,
      0.07743940502405167,
      0.009572003036737442,
      -0.024130873382091522,
      0.006074343807995319,
      0.020601289346814156,
      0.0312596894800663,
      -0.019270366057753563,
      -0.0386565700173378,
      -0.00902667734771967,
      0.03858464956283569,
      -0.04415443167090416,
      0.015912892296910286,
      -0.07750909775495529,
      -0.0033528206404298544,
      0.05937046930193901,
      -0.03721465542912483,
      -0.0038074657786637545,
      -0.03536369279026985,
      -0.060760799795389175,
      -0.011902759782969952,
      0.0037227796856313944,
      -0.04963349178433418,
      -0.04202170670032501,
      -0.01679016649723053,
      0.020069876685738564,
      0.058633286505937576,
      0.031023148447275162,
      0.05010785907506943,
      0.06921360641717911,
      -0.013667293824255466,
      0.010472320951521397,
      0.02975323610007763,
      -0.04238753393292427,
      -0.09545228630304337,
      0.09594357013702393,
      0.05913564935326576,
      -0.11065679043531418,
      -0.006930350326001644,
      0.013841884210705757,
      -0.08001210540533066,
      0.04806873947381973,
      -0.03880486637353897,
      -0.022825486958026886,
      -0.0018429290503263474,
      0.010969940572977066,
      -0.00579961109906435,
      0.018462738022208214,
      0.025751590728759766,
      -0.025274144485592842,
      -0.03678916022181511,
      -0.005337873473763466,
      0.012882777489721775,
      0.06411010026931763,
      -0.057526011019945145,
      0.05381408706307411,
      0.07194337248802185,
      0.019829003140330315,
      -0.0035382851492613554,
      0.07881785184144974,
      0.00991672184318304,
      -0.05059491842985153,
      -0.03425624594092369,
      -0.05184721574187279,
      0.026052959263324738,
      0.09376750141382217,
      -0.06843461841344833,
      -0.056067775934934616,
      -0.006596229504793882,
      0.07151539623737335,
      -0.04066826030611992,
      0.008151314221322536,
      -0.009186817333102226,
      0.016532395035028458,
      0.06510210037231445,
      -0.11706148087978363,
      -0.01970161870121956,
      0.04426638036966324,
      0.029708335176110268,
      0.05567573383450508,
      0.05752294883131981,
      -0.09647676348686218,
      0.047713760286569595,
      0.03746844083070755,
      0.0026681681629270315,
      0.03876630961894989,
      0.016118494793772697,
      -0.025249676778912544,
      -0.02503686398267746,
      0.007520187646150589,
      -0.028594426810741425,
      0.005879945587366819,
      -0.014601904898881912,
      -0.021633991971611977,
      -0.0124391233548522,
      0.0016371202655136585,
      -0.06364347040653229,
      0.02813059464097023,
      0.06260205805301666,
      0.01728348806500435,
      -0.017014510929584503,
      0.030327029526233673,
      0.03811201453208923,
      0.05726490169763565,
      -0.08785669505596161,
      0.050022415816783905,
      -0.0031514372676610947,
      -0.05005720630288124,
      -0.08308997750282288,
      0.048764586448669434,
      -0.036144860088825226,
      -0.00014146833564154804,
      0.020823204889893532,
      0.022118933498859406,
      0.041027989238500595,
      0.01911567524075508,
      -0.0006336483056657016,
      0.07754963636398315,
      -0.03962649777531624,
      0.0428321547806263,
      -0.03553154319524765,
      0.01287507638335228,
      0.02650623209774494,
      0.008466595783829689,
      0.016481759026646614,
      -0.0029399110935628414,
      0.02766808308660984,
      -0.013154767453670502,
      -0.04156382381916046,
      0.04481705650687218,
      0.0549287311732769,
      -0.05179138854146004,
      -0.021551797166466713,
      0.07192431390285492,
      0.08253518491983414,
      0.020563779398798943,
      -0.0877888947725296,
      0.021961303427815437,
      0.024998361244797707,
      0.05984019860625267,
      0.008042876608669758,
      0.02053920179605484,
      -0.07099031656980515,
      0.018415624275803566,
      -0.08582835644483566,
      0.09870759397745132,
      0.02841176465153694,
      -0.0420159213244915,
      -0.01100868359208107,
      0.03360264375805855,
      0.002175242407247424,
      0.026105806231498718,
      -0.05776509270071983,
      0.007827620021998882,
      -0.010056573897600174,
      0.012158696539700031,
      -0.015168365091085434,
      0.04430381581187248,
      -0.00915680080652237,
      -0.0006523645715788007,
      0.05824409797787666,
      -0.08350665122270584,
      -0.053101569414138794,
      0.038862306624650955,
      0.029608841985464096,
      -0.014866909012198448,
      0.033169250935316086,
      0.020355232059955597,
      0.06322385370731354,
      -0.027119915932416916,
      0.027105702087283134,
      -0.05032307654619217,
      0.027640284970402718,
      -0.05398748815059662,
      -0.04699830710887909,
      -0.021025341004133224,
      -0.04822918400168419,
      -0.07436195015907288,
      0.0767148807644844,
      -0.09130740165710449,
      0.010019659996032715,
      0.0050392658449709415,
      -0.00037834892282262444,
      0.03785198926925659,
      0.030674222856760025,
      0.025093065574765205,
      -0.029838820919394493,
      -0.07665334641933441,
      -0.002816819353029132,
      0.027735738083720207,
      -0.011632206849753857,
      -0.05357620120048523,
      0.06936119496822357,
      0.024431366473436356,
      -0.025428641587495804,
      0.0008368006674572825,
      0.08574730157852173,
      -0.0684119313955307,
      0.025401916354894638,
      -0.08733569830656052,
      -0.03641578555107117,
      -0.009631035849452019,
      0.016588618978857994,
      0.003344601718708873,
      0.019014935940504074,
      0.04627569764852524,
      0.053217437118291855,
      0.047235019505023956,
      0.012041345238685608,
      0.013771924190223217,
      -0.03251679241657257,
      -0.01448944490402937,
      0.05047249421477318,
      -0.00021773771732114255,
      -0.03600369766354561,
      0.03772420063614845,
      -0.045777060091495514,
      0.0346842035651207,
      0.08370804041624069,
      -0.02545536309480667,
      -0.00990409404039383,
      0.07531040161848068,
      0.03205130621790886,
      -0.018272945657372475,
      0.0072479709051549435,
      -0.021088656038045883,
      0.020090987905859947,
      -0.024768564850091934,
      -0.05377884954214096,
      -0.07640785723924637,
      0.0566418431699276,
      -0.036964286118745804,
      -0.014058549888432026,
      0.04605674743652344,
      0.09385822713375092,
      0.034751880913972855,
      -0.04123599827289581,
      -0.0461941622197628,
      -0.016351185739040375,
      0.03592035174369812,
      -0.02505321614444256,
      0.007621362339705229,
      0.04764913022518158,
      0.006619723979383707,
      -0.016691574826836586,
      0.05779416859149933,
      0.053579289466142654,
      0.02650521509349346,
      -0.059471968561410904,
      0.011629280634224415,
      -0.03302789106965065,
      0.03133233264088631,
      0.023033227771520615,
      0.010497117415070534,
      -0.028352269902825356,
      0.03412263095378876,
      -0.06897610425949097,
      -0.003550041699782014,
      0.0038276019040495157,
      -0.02024327591061592,
      0.035600632429122925,
      -0.04885232448577881,
      0.11672089248895645,
      0.014761816710233688,
      0.03524503856897354,
      0.0501139871776104,
      -0.026519954204559326,
      0.04574834927916527,
      0.0710841566324234,
      0.029784221202135086,
      0.009087835438549519,
      -0.017980655655264854,
      -0.020124422386288643,
      -0.04959502071142197,
      0.012497836723923683,
      -0.05921904742717743,
      0.01723673939704895,
      0.006178606767207384,
      0.05365949124097824,
      0.02259140834212303,
      0.050569381564855576,
      0.010753988288342953,
      -0.0005042172269895673,
      -0.005435334052890539,
      -0.03130089491605759,
      0.0649668350815773,
      0.05558577552437782,
      0.0039578997530043125,
      0.06354659050703049,
      0.012662128545343876,
      -0.01988215558230877,
      -0.03228902444243431,
      -0.018353108316659927,
      0.002598314080387354,
      -0.013565463945269585,
      0.040309883654117584,
      0.042285069823265076,
      -0.015431420877575874,
      0.07779083400964737,
      -0.0012741780374199152,
      0.04256820306181908,
      -0.0023116040974855423,
      0.02529466338455677,
      -0.009329182095825672,
      -0.0077253300696611404,
      0.056692272424697876,
      -0.036840081214904785,
      0.03441552072763443,
      0.09836501628160477,
      0.018472235649824142,
      0.008587622083723545,
      -0.0224015936255455,
      -0.0317351296544075,
      0.0792585238814354,
      0.016160963103175163,
      -0.0963769406080246,
      0.0959482491016388,
      0.017858926206827164,
      -0.09746779501438141,
      0.0643521323800087,
      -0.06992141902446747,
      -0.026481304317712784,
      -0.03672327101230621,
      -0.008212410844862461,
      0.0028185690753161907,
      0.025267083197832108,
      0.04448292776942253,
      0.013149095699191093,
      -0.008433288894593716,
      0.05305257812142372,
      -0.014377779327332973,
      0.09313090145587921,
      0.0003206778201274574,
      0.006858753971755505,
      0.03365527465939522,
      -0.04602107033133507,
      0.11462067067623138,
      0.060348838567733765,
      0.03239866718649864,
      0.10247165709733963,
      0.03432630002498627,
      -0.015935143455863,
      -0.013012460432946682,
      -0.0826958641409874,
      -0.04761325195431709,
      -0.03984227403998375,
      -0.047266650944948196,
      0.04237312823534012,
      0.009258754551410675,
      -0.01046140119433403,
      0.039147742092609406,
      0.0020455243065953255,
      -0.0014044709969311953,
      -0.062295399606227875,
      -0.05535423383116722,
      0.0349845327436924
    ],
    [
      -0.012733171693980694,
      0.028554927557706833,
      0.029523195698857307,
      -0.01265863236039877,
      -0.06944625824689865,
      0.0229874886572361,
      0.0498807318508625,
      -0.03625424578785896,
      -0.015922486782073975,
      0.07662169635295868,
      0.038465771824121475,
      -0.08562719076871872,
      -0.08375939726829529,
      -0.09531303495168686,
      -0.0631936565041542,
      -0.0005709933466278017,
      -0.04144321382045746,
      -0.04732764512300491,
      0.010045998729765415,
      -0.03582872822880745,
      0.07556135952472687,
      0.030236326158046722,
      0.005693019833415747,
      -0.02270284853875637,
      0.021150626242160797,
      0.009866945445537567,
      0.03513798490166664,
      -0.08239468932151794,
      -0.02270803600549698,
      -0.008790262043476105,
      -0.023658709600567818,
      -0.07472363114356995,
      0.037017274647951126,
      0.01155867800116539,
      -0.01228684838861227,
      0.05575688183307648,
      -0.0044136750511825085,
      0.014453881420195103,
      0.0014590448699891567,
      -0.04084990918636322,
      -0.0030751964077353477,
      -0.08759091794490814,
      -0.02117674984037876,
      -0.0889601856470108,
      0.08294614404439926,
      0.05209377035498619,
      -0.025063851848244667,
      0.026289135217666626,
      -0.01848265714943409,
      0.05886060744524002,
      -0.020173288881778717,
      -0.07719862461090088,
      0.03023136593401432,
      -0.004346923902630806,
      0.007797847036272287,
      0.021166455000638962,
      -0.041663192212581635,
      0.005339791066944599,
      -0.024718355387449265,
      -0.03848622366786003,
      -0.06445611268281937,
      -0.06062674522399902,
      0.0364510603249073,
      0.046975407749414444,
      0.05927256867289543,
      0.0017484916606917977,
      -0.0020919598173350096,
      0.013978012837469578,
      -0.03242950886487961,
      -0.008971075527369976,
      -0.046659693121910095,
      -0.036256104707717896,
      0.0989319458603859,
      0.011814593337476254,
      0.012163715437054634,
      -0.017525924369692802,
      0.04138670116662979,
      -0.040470387786626816,
      0.03311314061284065,
      0.012632003985345364,
      -0.06406629830598831,
      0.010793245397508144,
      -0.08798055350780487,
      0.03557806462049484,
      -0.0425669364631176,
      -0.06009938567876816,
      0.05911875143647194,
      0.04066186770796776,
      0.11348243057727814,
      -0.07624596357345581,
      -0.054832685738801956,
      -0.02825751155614853,
      -0.047736987471580505,
      0.041168518364429474,
      0.0022999534849077463,
      0.005090872757136822,
      0.03351585566997528,
      0.0075268871150910854,
      0.055987756699323654,
      -0.004086088854819536,
      -0.01984701119363308,
      0.016441740095615387,
      0.04837658256292343,
      -0.03631666302680969,
      0.03248165547847748,
      -0.07015573978424072,
      -0.049004897475242615,
      0.03477608785033226,
      -0.04509676620364189,
      -0.021068446338176727,
      0.04326546937227249,
      0.09285841137170792,
      -0.020862797275185585,
      0.03751328960061073,
      0.06356833875179291,
      0.07567009329795837,
      -0.11314744502305984,
      0.027067936956882477,
      -0.07209887355566025,
      0.10152126848697662,
      -0.004394728224724531,
      -0.11567077040672302,
      -0.0001693908852757886,
      -0.054188746958971024,
      0.07165712863206863,
      0.04494556039571762,
      0.022653235122561455,
      -0.0351218581199646,
      -0.05463336408138275,
      -0.019272146746516228,
      -0.031177153810858727,
      -0.012532802298665047,
      0.05074594169855118,
      -0.02126167342066765,
      -0.006189663428813219,
      0.009749899618327618,
      -0.0367761068046093,
      0.006941431667655706,
      0.004529425408691168,
      -0.05109875276684761,
      0.05259450897574425,
      -0.017465705052018166,
      0.07899221777915955,
      0.06389667838811874,
      -0.09723708778619766,
      0.021157559007406235,
      0.0755200907588005,
      -0.0003874381072819233,
      0.0009990576654672623,
      -0.0031551546417176723,
      0.06369681656360626,
      -0.0682855024933815,
      -0.010213453322649002,
      0.02283296547830105,
      -0.13159285485744476,
      0.045661572366952896,
      -0.007746791001409292,
      -0.0739213228225708,
      -0.029044799506664276,
      -0.011463169008493423,
      0.022032544016838074,
      0.02523404359817505,
      -0.05836334452033043,
      -0.031228726729750633,
      -0.038008157163858414,
      0.06591743975877762,
      -0.008502702228724957,
      0.0018094128463417292,
      -0.02498548850417137,
      0.03221311792731285,
      -0.010437367483973503,
      0.012078467756509781,
      0.013719821348786354,
      0.003388528246432543,
      0.043046727776527405,
      0.06247608736157417,
      0.10626818239688873,
      -0.07828206568956375,
      -0.053501881659030914,
      0.009504408575594425,
      -0.028254156932234764,
      0.02054317481815815,
      -0.029710941016674042,
      -0.026058221235871315,
      -0.008204045705497265,
      -0.016042036935687065,
      -0.02541832998394966,
      0.0329715833067894,
      0.011577858589589596,
      -0.00043027623905800283,
      -0.0030716832261532545,
      0.015546618029475212,
      -0.08473192900419235,
      -0.006738036405295134,
      -0.05197404697537422,
      0.05230480805039406,
      0.04989483207464218,
      -0.04117352515459061,
      0.0155399851500988,
      -0.025172971189022064,
      0.02911776676774025,
      0.01919872872531414,
      -0.03519603982567787,
      -0.03919706866145134,
      0.029955485835671425,
      -0.016927680000662804,
      0.0038768325466662645,
      0.044537752866744995,
      -0.11525366455316544,
      -0.0024858438409864902,
      0.012873691506683826,
      0.0008576182881370187,
      0.01903679594397545,
      0.012965824455022812,
      0.0026019352953881025,
      -0.042108647525310516,
      0.01758248172700405,
      -0.006532423663884401,
      -0.047780267894268036,
      -0.029780179262161255,
      0.08917883038520813,
      0.004848172422498465,
      -0.04701622948050499,
      -0.023578478023409843,
      0.005319047253578901,
      0.03731216862797737,
      0.012377995997667313,
      -0.06081176549196243,
      -0.09764308482408524,
      0.027824973687529564,
      -0.07201424241065979,
      0.06794567406177521,
      -0.018114320933818817,
      0.003935833461582661,
      0.008141841739416122,
      0.042656950652599335,
      0.012011158280074596,
      -0.012745927087962627,
      -0.06850521266460419,
      -0.08020960539579391,
      -0.025574011728167534,
      -0.029038673266768456,
      0.04141290485858917,
      0.0012601130874827504,
      -0.0015018589328974485,
      0.04182019457221031,
      -0.02120976150035858,
      -0.03281928226351738,
      0.05891133472323418,
      -0.03807944804430008,
      0.025847868993878365,
      -0.023494021967053413,
      0.00950194988399744,
      0.06075885891914368,
      0.05091365799307823,
      0.043232716619968414,
      -0.019495556131005287,
      0.055893801152706146,
      -0.03669073060154915,
      -0.0018119417363777757,
      0.0026915317866951227,
      -0.0026429553981870413,
      -0.037587668746709824,
      -0.03509950637817383,
      -0.06447988003492355,
      0.021004587411880493,
      0.060781627893447876,
      0.01669381558895111,
      -0.05236777290701866,
      -0.05323747545480728,
      -0.004981623962521553,
      0.06830448657274246,
      -0.0483141653239727,
      -0.016631022095680237,
      -0.0452129989862442,
      0.06877172738313675,
      -0.06163188815116882,
      -0.03506617248058319,
      0.06865385919809341,
      0.029329407960176468,
      0.02959873341023922,
      -0.012927023693919182,
      -0.0386364571750164,
      -0.006855119951069355,
      0.06140764802694321,
      -0.004117990843951702,
      0.0731324702501297,
      -0.08941370993852615,
      -0.027638621628284454,
      0.021136635914444923,
      0.03666670620441437,
      0.005619933363050222,
      -0.03666115552186966,
      0.028326520696282387,
      0.011842894367873669,
      -0.0468951016664505,
      0.009314143098890781,
      -0.01551206037402153,
      -0.03180309757590294,
      -0.024554206058382988,
      0.12444290518760681,
      0.021045507863163948,
      0.016469113528728485,
      -0.06014604866504669,
      -0.001777938101440668,
      0.008420867845416069,
      0.11523409932851791,
      0.05715285241603851,
      0.09966237843036652,
      0.029940783977508545,
      -3.477564314380288e-05,
      -0.05025816336274147,
      0.02597229741513729,
      0.004878186155110598,
      -0.011062545701861382,
      0.0008481803815811872,
      -0.0695556253194809,
      0.10561788827180862,
      0.017077848315238953,
      -0.07128971815109253,
      -0.05328306183218956,
      -0.03969720005989075,
      0.014590434730052948,
      0.016613829880952835,
      -0.00258401851169765,
      -0.009113498963415623,
      0.006918904837220907,
      0.03998557850718498,
      0.0341092050075531,
      0.05144384875893593,
      0.030701786279678345,
      0.009388311766088009,
      0.02903936430811882,
      0.018005989491939545,
      -0.013381263241171837,
      -0.01138448715209961,
      -0.01082631852477789,
      0.032855525612831116,
      -0.0675821378827095,
      0.09163259714841843,
      -0.0003363396681379527,
      0.029262622818350792,
      0.08617638051509857,
      -0.051109664142131805,
      0.013396624475717545,
      -0.05026859790086746,
      -0.026715608313679695,
      -0.04729333519935608,
      -0.0697019174695015,
      -0.017351895570755005,
      -0.022112684324383736,
      0.044222332537174225,
      -0.027263734489679337,
      -0.024400973692536354,
      0.000489147612825036,
      -0.03967740759253502,
      0.06909964978694916,
      -0.0502876415848732,
      0.10217094421386719,
      0.03560560196638107,
      -0.07650533318519592,
      0.05685028061270714,
      0.07110791653394699,
      0.021975364536046982,
      0.04764935374259949,
      0.04150843992829323,
      -0.12558695673942566,
      0.003936556167900562,
      0.02284122258424759,
      -0.0925607979297638,
      -0.0830022543668747,
      0.048747945576906204,
      -0.006491814274340868,
      0.05846099182963371,
      0.056682124733924866,
      -0.08697865158319473,
      -0.02312852256000042,
      -0.010348958894610405,
      -0.044520605355501175,
      0.005126425996422768,
      7.505771645810455e-05,
      -0.011412575840950012,
      0.0005317829200066626,
      -7.35129724489525e-05,
      0.11411314457654953,
      0.029426950961351395,
      -0.07599790394306183,
      0.020442649722099304,
      0.005431730300188065,
      -0.016199780628085136,
      -0.023480065166950226,
      -0.12480322271585464,
      0.04605098441243172,
      -0.06504052877426147,
      0.014528091996908188,
      -0.03561461716890335,
      -0.018441278487443924,
      0.028210537508130074,
      0.015695715323090553,
      0.018799353390932083,
      0.024920031428337097,
      -0.0031225907150655985,
      -0.041109051555395126,
      -0.01703125424683094,
      -0.0023872479796409607,
      -0.008099229075014591,
      -0.0226251482963562,
      -0.05044259503483772,
      -0.00945213995873928,
      -0.0011314661242067814,
      -0.05802414193749428,
      0.06514830142259598,
      -0.0066731637343764305,
      -0.04062150791287422,
      0.08517105877399445,
      0.025818705558776855,
      0.008428388275206089,
      0.019645074382424355,
      0.006620520260185003,
      0.06858038902282715,
      0.068315789103508,
      -0.021235352382063866,
      0.02012121118605137,
      -0.16449053585529327,
      -0.03010626696050167,
      -0.0009491405799053609,
      0.07877515256404877,
      -0.016601363196969032,
      -0.023233715444803238,
      -0.015034908428788185,
      0.049002449959516525,
      0.0014572322834283113,
      -0.002325700828805566,
      0.056105006486177444,
      0.0894172266125679,
      -0.011926411651074886,
      -0.021344970911741257,
      -0.0037304183933883905,
      -0.0013519396306946874,
      -0.01528176013380289,
      -0.005898196715861559,
      -0.041273150593042374,
      -0.08845150470733643,
      0.016092272475361824,
      -0.04379807040095329,
      -0.0013365198392421007,
      0.01621425151824951,
      0.07553219050168991,
      -0.08279204368591309,
      0.026217736303806305,
      -0.05562764033675194,
      0.02518348954617977,
      0.08091946691274643,
      -0.07404694706201553,
      0.06122569739818573,
      -0.005280307028442621,
      0.039586130529642105,
      -0.010770492255687714,
      -0.022979822009801865,
      0.002750285202637315,
      -0.013965130783617496,
      -0.08285273611545563,
      -0.04291173070669174,
      0.019146738573908806,
      0.015079228207468987,
      0.06879574060440063,
      -0.025520911440253258,
      -0.024040577933192253,
      0.008633742108941078,
      -0.014772418886423111,
      -0.058506086468696594,
      0.0431375652551651,
      -0.02184382826089859,
      -0.04076780378818512,
      -0.032360661774873734,
      0.002875407226383686,
      0.022358156740665436,
      -0.049539964646101,
      -0.002745359903201461,
      -0.0868440791964531,
      -0.021944710984826088,
      -0.04212149232625961,
      -0.013695977628231049,
      -0.007212786469608545,
      0.048164308071136475,
      -0.06945936381816864,
      0.0009781919652596116,
      -0.02723545953631401,
      -0.013263448141515255,
      -0.001326868892647326,
      0.031459398567676544,
      -0.025151845067739487,
      -0.05088808760046959,
      0.027257150039076805,
      0.004175110254436731,
      0.0021830243058502674,
      0.0025212052278220654,
      -0.0033016041852533817,
      -7.432451093336567e-05,
      0.013181358575820923,
      -0.015096524730324745,
      0.06678153574466705,
      0.020171061158180237
    ],
    [
      -0.026698332279920578,
      -0.0015819487161934376,
      -0.05211798474192619,
      -0.022422926500439644,
      -0.03851334750652313,
      -0.08297265321016312,
      0.03801329433917999,
      -0.020828519016504288,
      -0.04903969168663025,
      0.040720485150814056,
      -0.08525434881448746,
      -0.040148135274648666,
      -0.02232259325683117,
      -0.043032463639974594,
      -0.003980749286711216,
      0.04140935838222504,
      0.025701386854052544,
      0.03563568741083145,
      0.061325542628765106,
      0.007152252364903688,
      -0.04446817934513092,
      0.009971130639314651,
      -0.05608217790722847,
      0.06161123886704445,
      0.0002514702791813761,
      -0.09657708555459976,
      0.025591889396309853,
      0.0681253969669342,
      -0.0579780712723732,
      -0.039981402456760406,
      -0.044211577624082565,
      -0.007991054095327854,
      -0.045865871012210846,
      -0.0641578659415245,
      0.005214635748416185,
      -0.002337484620511532,
      -0.03942372649908066,
      -0.024012837558984756,
      0.007940497249364853,
      0.026623113080859184,
      0.03143449127674103,
      -0.006957158446311951,
      0.049766656011343,
      0.050577085465192795,
      -0.067622110247612,
      0.04539407044649124,
      0.09598315507173538,
      0.027027392759919167,
      0.03700675070285797,
      0.046116363257169724,
      0.02059367671608925,
      -0.01287553645670414,
      -0.12049359828233719,
      -0.027867896482348442,
      -0.024545472115278244,
      -0.011960904113948345,
      -0.040614280849695206,
      0.08161365985870361,
      0.06696346402168274,
      -0.01819741167128086,
      0.02510593645274639,
      -0.031927358359098434,
      0.032908495515584946,
      -0.09025704115629196,
      -0.024910416454076767,
      -0.013253255747258663,
      -0.041531212627887726,
      0.04735048487782478,
      0.05643221735954285,
      -0.07211864739656448,
      0.005823279265314341,
      0.05372528359293938,
      -0.05944068357348442,
      0.014629868790507317,
      0.04671061784029007,
      -0.0113355852663517,
      -0.10447373241186142,
      -0.01215850468724966,
      -0.016023285686969757,
      -0.029854707419872284,
      -0.036974962800741196,
      0.04453423619270325,
      0.00915558822453022,
      0.01211242750287056,
      0.05415866896510124,
      -0.022565526887774467,
      0.000797314802184701,
      0.020186463370919228,
      -0.04398483783006668,
      -0.014772593975067139,
      0.01960577443242073,
      -0.07382457703351974,
      -0.01135993842035532,
      -0.09846995025873184,
      -0.03885273635387421,
      -0.08028142154216766,
      0.031937774270772934,
      0.06060762330889702,
      -0.01721120998263359,
      -0.014897103421390057,
      -0.045786816626787186,
      -0.01709131896495819,
      0.04722696542739868,
      0.07625080645084381,
      -0.01196741871535778,
      -0.024745281785726547,
      0.032291095703840256,
      0.08627228438854218,
      -0.007694392930716276,
      -0.018747130408883095,
      0.06071238964796066,
      0.06400766223669052,
      0.03491498902440071,
      -0.013876459561288357,
      0.027964599430561066,
      -0.020943043753504753,
      -0.022594952955842018,
      -0.004781842231750488,
      0.006833683233708143,
      -0.05003643408417702,
      0.090813048183918,
      0.025615928694605827,
      0.10031268745660782,
      -0.048759281635284424,
      -0.10620351135730743,
      -0.032645732164382935,
      -0.00567980483174324,
      0.018974818289279938,
      0.017283586785197258,
      0.07855954766273499,
      0.08066041022539139,
      -0.02419932559132576,
      0.02549741044640541,
      -0.01707650162279606,
      0.07649759203195572,
      -0.037273310124874115,
      0.040808919817209244,
      -0.04482091963291168,
      -0.03140484541654587,
      0.015292485244572163,
      0.010656160302460194,
      -0.045554257929325104,
      0.05301373451948166,
      -0.007497280370444059,
      0.032840579748153687,
      0.07007993757724762,
      -0.028544390574097633,
      0.019414640963077545,
      0.019566375762224197,
      0.0911368802189827,
      -0.06340006738901138,
      -0.005531687289476395,
      0.0075590708293020725,
      -0.03517799451947212,
      0.032661985605955124,
      0.050255920737981796,
      0.04523675516247749,
      0.0210051778703928,
      -0.04337458312511444,
      -0.017229827120900154,
      -0.05359946936368942,
      0.009593036025762558,
      0.07784052193164825,
      0.021354541182518005,
      0.01165733765810728,
      -0.046699754893779755,
      0.057450514286756516,
      -0.011457188054919243,
      0.007132880389690399,
      -0.024475254118442535,
      -0.055942095816135406,
      -0.011750640347599983,
      0.06700880080461502,
      -0.017709817737340927,
      0.03519253805279732,
      0.021381055936217308,
      0.032240256667137146,
      -0.0273716002702713,
      -0.06445182114839554,
      0.08240256458520889,
      -0.00789421796798706,
      0.022780923172831535,
      0.016613254323601723,
      0.06506916135549545,
      0.016537833958864212,
      0.028211522847414017,
      0.045374657958745956,
      -0.0024956553243100643,
      0.09360764920711517,
      -0.011819192208349705,
      -0.025313932448625565,
      0.026775602251291275,
      0.08760368824005127,
      -0.04300769418478012,
      0.0015577921876683831,
      0.05004100874066353,
      -0.0822073295712471,
      0.05578165873885155,
      -0.019845353439450264,
      0.013690752908587456,
      -0.052810534834861755,
      0.0355585440993309,
      0.07186143100261688,
      -0.007005220744758844,
      0.04966532438993454,
      0.07401138544082642,
      -0.09911064803600311,
      -0.04223362356424332,
      -0.0372246578335762,
      -0.04528537392616272,
      0.0625106543302536,
      0.0024246154353022575,
      0.012096022255718708,
      -0.01528887264430523,
      -0.034809716045856476,
      -0.02960505150258541,
      0.14770816266536713,
      -0.02541167661547661,
      -0.013782707042992115,
      0.06296190619468689,
      0.05310479551553726,
      0.033826254308223724,
      0.004950682166963816,
      -0.05135461688041687,
      0.010780313052237034,
      0.01813022419810295,
      -0.019569003954529762,
      -0.03327931836247444,
      0.05904560908675194,
      -0.001631303341127932,
      -0.05693942308425903,
      -0.07018915563821793,
      0.10158294439315796,
      0.04340844228863716,
      -0.009447960183024406,
      0.018422806635499,
      0.015560664236545563,
      0.009109138511121273,
      -0.08449240028858185,
      0.03333427384495735,
      -0.06530879437923431,
      0.08400178700685501,
      0.03203601762652397,
      0.04426091909408569,
      -0.04671713337302208,
      -0.04184651002287865,
      0.05626937001943588,
      0.005092666484415531,
      -0.05491357669234276,
      0.01862976886332035,
      -0.042081743478775024,
      -0.014319508336484432,
      -0.004996961914002895,
      -0.005748950410634279,
      -0.09411348402500153,
      0.014261221513152122,
      -0.0833299458026886,
      -0.00684721302241087,
      -0.031104881316423416,
      -0.030862826853990555,
      0.009959346614778042,
      0.0495605543255806,
      -0.011994227766990662,
      0.04431138560175896,
      0.05562296509742737,
      -0.010537292808294296,
      0.062274765223264694,
      -0.0026433574967086315,
      -0.0016088769771158695,
      0.03133004903793335,
      0.022415926679968834,
      -0.05835269019007683,
      -0.0012806047452613711,
      0.034257736057043076,
      0.01045044232159853,
      -0.03279314562678337,
      -0.015349830500781536,
      0.06657303869724274,
      0.07285402715206146,
      0.09701602160930634,
      0.007443870883435011,
      0.045153506100177765,
      -0.011204419657588005,
      0.0026154418010264635,
      -0.01370134949684143,
      0.0019404111662879586,
      0.02074269764125347,
      -0.08830263465642929,
      0.028999367728829384,
      -0.01691819727420807,
      0.020946595817804337,
      -0.017786867916584015,
      0.016054324805736542,
      -0.033181313425302505,
      0.044450271874666214,
      -0.009806940332055092,
      0.07081199437379837,
      0.06954680383205414,
      0.12040405720472336,
      0.018824582919478416,
      0.012061825953423977,
      0.01833830215036869,
      -0.04034121707081795,
      -0.01025039330124855,
      -0.012014173902571201,
      -0.06229880452156067,
      0.010135135613381863,
      0.03816641867160797,
      0.001418362488038838,
      -0.005822069942951202,
      0.02772623486816883,
      -0.04911167919635773,
      -0.08985669910907745,
      0.0004330908413976431,
      0.02314138226211071,
      0.04396910220384598,
      -0.04610080271959305,
      0.010368186980485916,
      0.08521723747253418,
      -0.12499985843896866,
      -0.04945780336856842,
      -0.0018866831669583917,
      -0.01716609112918377,
      0.08116930723190308,
      -0.06302987784147263,
      0.05619259923696518,
      -0.0031760954298079014,
      -0.09481137245893478,
      0.09295352548360825,
      -0.06604406982660294,
      -0.025627799332141876,
      0.05605143681168556,
      0.027441833168268204,
      0.006051731761544943,
      -0.04955582693219185,
      0.029854072257876396,
      0.06369578838348389,
      -0.027797535061836243,
      -0.004010866861790419,
      0.06597022712230682,
      0.03520230948925018,
      0.07145623862743378,
      -0.004634701181203127,
      0.03744120895862579,
      0.07367930561304092,
      0.0013611362082883716,
      -0.0316704660654068,
      -0.031679291278123856,
      0.025068802759051323,
      -0.016674555838108063,
      -0.012582525610923767,
      -0.09981299191713333,
      0.051464177668094635,
      0.033200670033693314,
      -0.009848360903561115,
      0.036528605967760086,
      -0.0482773594558239,
      0.017784183844923973,
      0.07689020782709122,
      0.027205655351281166,
      0.008490204811096191,
      -0.10378042608499527,
      -0.023557765409350395,
      0.08945927768945694,
      0.056805502623319626,
      -0.018653949722647667,
      -0.027945885434746742,
      -0.07383471727371216,
      0.0014697107253596187,
      0.0270225927233696,
      0.10112481564283371,
      -0.07108236849308014,
      -0.019683020189404488,
      0.017557527869939804,
      -0.0015220105415210128,
      -0.03220224380493164,
      -0.024468261748552322,
      -0.019767610356211662,
      -0.05815816670656204,
      0.0028812994714826345,
      -0.008038309402763844,
      0.09439072757959366,
      -0.05611982196569443,
      -0.006130347494035959,
      0.06813938170671463,
      -0.05991450697183609,
      0.0047192564234137535,
      -0.011034857481718063,
      0.012548975646495819,
      0.01504645124077797,
      -0.04948856681585312,
      -0.0026776210870593786,
      0.0016244981670752168,
      0.011161495000123978,
      0.008302167057991028,
      0.059944964945316315,
      0.05411651358008385,
      0.0842633992433548,
      -0.03751230239868164,
      -0.01105659082531929,
      0.00669743912294507,
      -0.044325996190309525,
      0.06695817410945892,
      -0.010226067155599594,
      -0.0003431921941228211,
      0.01975548453629017,
      0.00950483325868845,
      0.003985090181231499,
      -0.05517774820327759,
      0.061642199754714966,
      -0.04644867777824402,
      0.08434849977493286,
      0.054954249411821365,
      -0.027197329327464104,
      -0.02253272756934166,
      -0.04388526827096939,
      0.003107513068243861,
      0.037215009331703186,
      -0.015941288322210312,
      0.024893132969737053,
      0.01758403889834881,
      0.04434830695390701,
      -0.03031945414841175,
      -0.013822446577250957,
      -0.07703767716884613,
      0.05989706888794899,
      0.00402858154848218,
      -0.004146585240960121,
      0.01737765409052372,
      -0.012175153009593487,
      0.0610274113714695,
      0.04240532964468002,
      -0.027384361252188683,
      0.0007753103272989392,
      -0.07732562720775604,
      0.08889706432819366,
      0.038719769567251205,
      0.019409339874982834,
      -0.014875509776175022,
      0.02772016078233719,
      0.04870213568210602,
      -0.0784652978181839,
      -0.002769340295344591,
      0.08385840058326721,
      0.024294337257742882,
      0.05656662955880165,
      -0.05266933515667915,
      0.023442119359970093,
      -0.02078559249639511,
      0.05861810967326164,
      -0.07449006289243698,
      -0.03541378676891327,
      0.016026092693209648,
      0.07211878895759583,
      -0.016621580347418785,
      0.025309225544333458,
      -0.025679633021354675,
      -0.02116263285279274,
      0.06624840945005417,
      -0.02299618348479271,
      -0.037221625447273254,
      -0.009073669090867043,
      0.03526931256055832,
      -0.032060399651527405,
      0.06473775953054428,
      -0.010835119523108006,
      -0.07883613556623459,
      0.0054461173713207245,
      0.04922890663146973,
      0.01218536775559187,
      -0.09908891469240189,
      0.02770533412694931,
      0.008163940161466599,
      0.17078788578510284,
      -0.03401469066739082,
      0.01765979267656803,
      0.04025639593601227,
      0.051263924688100815,
      0.06495963782072067,
      0.025636345148086548,
      0.02592392824590206,
      0.005362358409911394,
      0.03434160724282265,
      0.0036676903255283833,
      0.0328657366335392,
      -0.010648291558027267,
      -0.029329268261790276,
      0.03219443932175636,
      0.030381053686141968,
      0.009493531659245491,
      -0.056172601878643036,
      -0.07009576261043549,
      -0.03141612187027931,
      0.008380616083741188,
      -0.007803463842719793,
      0.03989354148507118,
      -0.03861076757311821,
      0.036776814609766006,
      0.011328662745654583,
      0.033990927040576935,
      0.05659402906894684,
      -0.037496503442525864,
      -0.031180737540125847
    ],
    [
      0.0256313756108284,
      -0.014772409573197365,
      0.0018634372390806675,
      0.0543728731572628,
      0.05309831723570824,
      0.051235198974609375,
      0.06117184832692146,
      -0.06118955835700035,
      -0.04934575408697128,
      -0.03071030043065548,
      0.015831878408789635,
      0.058568768203258514,
      0.027903232723474503,
      0.02817106992006302,
      -0.0649058073759079,
      0.02837187796831131,
      0.04751676321029663,
      -0.0003080976603087038,
      0.018428217619657516,
      0.002206457545980811,
      0.014236608520150185,
      -0.02034051902592182,
      0.022668473422527313,
      -0.07518717646598816,
      0.04356613755226135,
      -0.05192667618393898,
      0.016092808917164803,
      0.024886256083846092,
      0.07403679937124252,
      0.03688398748636246,
      -0.04166947305202484,
      0.04147608205676079,
      -0.02735789306461811,
      0.002523686969652772,
      0.0849723070859909,
      -0.02365115098655224,
      -0.05756855756044388,
      0.09168656170368195,
      0.005138015374541283,
      0.015380478464066982,
      0.05596059933304787,
      0.013715913519263268,
      0.04326237738132477,
      0.02198653481900692,
      -0.02149817906320095,
      -0.030993705615401268,
      -0.015773728489875793,
      -0.026052147150039673,
      0.030220281332731247,
      0.017259011045098305,
      0.012896629050374031,
      0.0037274977657943964,
      -0.022459177300333977,
      -0.00727481534704566,
      0.04872959479689598,
      -0.08089768886566162,
      -0.010156385600566864,
      0.044904112815856934,
      0.061074476689100266,
      0.06733208149671555,
      0.03656904771924019,
      0.06868524104356766,
      0.024728024378418922,
      0.022621044889092445,
      -0.07296920567750931,
      0.049089908599853516,
      -0.004783633165061474,
      0.025725392624735832,
      -0.026590516790747643,
      0.03700866922736168,
      -0.07687100768089294,
      0.08043872565031052,
      0.04391459375619888,
      -0.028070660308003426,
      -0.031067462638020515,
      -0.04465031996369362,
      0.05819859355688095,
      -0.07207648456096649,
      0.028791939839720726,
      0.039035532623529434,
      0.005944487173110247,
      -0.019353747367858887,
      0.034390002489089966,
      0.014153637923300266,
      0.057556573301553726,
      -0.001683712936937809,
      0.0820184275507927,
      0.05705329775810242,
      -0.01738116517663002,
      -0.06404686719179153,
      0.025188028812408447,
      0.03241611644625664,
      -0.003788676345720887,
      0.0129182618111372,
      -0.0023979085963219404,
      -0.025465544313192368,
      0.047768790274858475,
      -0.0018476201221346855,
      0.002282549161463976,
      -0.010887512937188148,
      -0.1046074628829956,
      -0.02065643109381199,
      0.018106739968061447,
      0.010796129703521729,
      0.0686056986451149,
      0.050400517880916595,
      0.03296691179275513,
      -0.05670249089598656,
      -0.04234917461872101,
      -0.023082386702299118,
      0.0697595477104187,
      0.043807502835989,
      -0.00880432315170765,
      0.008236519061028957,
      -0.03733709827065468,
      -0.007878739386796951,
      -0.0056802756153047085,
      -0.019883310422301292,
      0.0288572758436203,
      -0.013743349350988865,
      -0.0002764167438726872,
      -0.05542514845728874,
      0.013256526552140713,
      -0.009609847329556942,
      0.04419257864356041,
      -0.03636547178030014,
      -0.0016413740813732147,
      -0.02720688469707966,
      -0.06618544459342957,
      -0.02470577321946621,
      0.05495830625295639,
      0.029521619901061058,
      0.03845088183879852,
      0.05755710229277611,
      0.10634016990661621,
      -0.06026065722107887,
      0.06164555996656418,
      -0.042497165501117706,
      0.03259960934519768,
      -0.0721665620803833,
      -0.0048900144174695015,
      -0.017781807109713554,
      -0.04601595923304558,
      0.03741320222616196,
      0.032713159918785095,
      0.021212715655565262,
      0.010473432019352913,
      0.10085383802652359,
      -0.028784343972802162,
      -0.01612824760377407,
      0.059419192373752594,
      0.024461546912789345,
      0.05588558688759804,
      -0.026889966800808907,
      -0.04162578657269478,
      -0.029139552265405655,
      -0.0273565836250782,
      0.04058525338768959,
      0.017220383509993553,
      0.06237141042947769,
      -0.06659355014562607,
      0.05607466399669647,
      0.033092040568590164,
      -0.028114408254623413,
      0.07712773233652115,
      -0.06736040115356445,
      0.1042666807770729,
      -0.01620011031627655,
      0.07412493973970413,
      -0.06717482954263687,
      0.05655645206570625,
      0.03560120239853859,
      -0.06307908147573471,
      -0.031356409192085266,
      0.01563023403286934,
      -0.018883220851421356,
      -0.012108719907701015,
      0.023916617035865784,
      -0.009656010195612907,
      -0.008679907768964767,
      0.052476849406957626,
      0.014588438905775547,
      -0.052566345781087875,
      0.0619739294052124,
      -0.06158370152115822,
      -0.06882818043231964,
      0.05108209326863289,
      -0.012082970701158047,
      0.07748090475797653,
      0.02205834724009037,
      -0.09443611651659012,
      -0.037714723497629166,
      0.0008007144788280129,
      -0.027840374037623405,
      -0.08468311280012131,
      0.03707476332783699,
      -0.07051754742860794,
      0.016362017020583153,
      0.01607951521873474,
      0.019313743337988853,
      0.11009544134140015,
      -0.008189327083528042,
      0.021169118583202362,
      -0.013365654274821281,
      0.011712570674717426,
      -0.014445481821894646,
      0.0217526163905859,
      0.03421728312969208,
      -0.054353076964616776,
      -0.04741621017456055,
      0.018496010452508926,
      0.05177006125450134,
      8.830623846733943e-05,
      -0.028117744252085686,
      -0.03353479132056236,
      0.035030148923397064,
      0.06024862825870514,
      0.0070039052516222,
      0.07771594822406769,
      -0.08274835348129272,
      0.052561938762664795,
      0.006853702012449503,
      0.018096068874001503,
      0.008345839567482471,
      0.03653404116630554,
      0.05468044802546501,
      0.04735913500189781,
      -0.04291754588484764,
      -0.005452362354844809,
      -0.017498701810836792,
      -0.03165590390563011,
      -0.05133259668946266,
      0.05111340060830116,
      0.06625889986753464,
      0.05208618566393852,
      0.03586446866393089,
      0.0028469813987612724,
      -0.0672328844666481,
      0.06376932561397552,
      -0.08195093274116516,
      -0.02681407518684864,
      0.02054540067911148,
      -0.07343721389770508,
      -0.023314449936151505,
      0.06112649664282799,
      0.06604503095149994,
      0.032963190227746964,
      -0.01973210647702217,
      0.057807233184576035,
      0.05075849965214729,
      -0.04052625223994255,
      -0.017070619389414787,
      0.052556488662958145,
      0.04441225156188011,
      -0.031835053116083145,
      -0.017996931448578835,
      -0.029023999348282814,
      0.00241402187384665,
      0.031909577548503876,
      -0.004828619305044413,
      -0.05326757952570915,
      0.0956936627626419,
      0.06012099236249924,
      0.07845354080200195,
      0.10764440149068832,
      -0.01930307410657406,
      -0.014774677343666553,
      -0.04722901061177254,
      0.0589841865003109,
      -0.11369755119085312,
      0.04974716901779175,
      -0.04157973453402519,
      0.014753666706383228,
      0.05007989704608917,
      0.08586559444665909,
      0.03434121981263161,
      0.059818655252456665,
      0.013875046744942665,
      0.03699592500925064,
      -0.030112894251942635,
      -0.040845565497875214,
      0.03805271163582802,
      0.011215390637516975,
      -0.022454120218753815,
      -0.025273479521274567,
      0.06126299127936363,
      -0.06721178442239761,
      -0.01468440517783165,
      -0.03881855309009552,
      0.05775684490799904,
      -0.016742851585149765,
      0.0992458164691925,
      0.08095686137676239,
      0.022235684096813202,
      -0.12480299174785614,
      0.05353303998708725,
      -0.05454019084572792,
      -0.04160125553607941,
      0.06450808048248291,
      -0.018905380740761757,
      -0.03645290434360504,
      0.024451719596982002,
      0.0387505404651165,
      -0.06500231474637985,
      -0.02318079024553299,
      -0.024382201954722404,
      -0.012839037925004959,
      -0.04806290194392204,
      0.04955584183335304,
      -0.02982349880039692,
      0.03439557924866676,
      0.007405775133520365,
      0.12277761846780777,
      -0.023417508229613304,
      0.009425726719200611,
      -0.04578970745205879,
      0.014204522594809532,
      -0.030425038188695908,
      0.01101277768611908,
      0.07209868729114532,
      -0.02420235425233841,
      0.08434562385082245,
      0.06827275454998016,
      -0.05325964093208313,
      -0.03639081120491028,
      0.06618370115756989,
      0.011989109218120575,
      -0.05150672048330307,
      0.030709734186530113,
      0.016715427860617638,
      -0.006751712877303362,
      0.039050713181495667,
      0.07390882819890976,
      0.030109280720353127,
      -0.021309487521648407,
      -0.023693345487117767,
      0.03361566364765167,
      0.08782614767551422,
      -0.01941685937345028,
      -0.009328889660537243,
      0.020424675196409225,
      0.023593703284859657,
      -0.0025910146068781614,
      -0.015459839254617691,
      -0.1666310578584671,
      -0.010127671994268894,
      -0.04858369752764702,
      -0.0061131808906793594,
      -0.0026031534653156996,
      -0.06042865291237831,
      -0.027878662571310997,
      -0.008155178278684616,
      -0.03962640091776848,
      -0.03490994870662689,
      -0.04455822333693504,
      0.021742792800068855,
      -0.010403660126030445,
      -0.05498542636632919,
      0.006662080995738506,
      -0.05039655789732933,
      0.05069626867771149,
      -0.05227086320519447,
      -0.015523348934948444,
      -0.01100149191915989,
      -0.0013773884857073426,
      0.012284131720662117,
      0.0460485965013504,
      0.044737737625837326,
      0.040698401629924774,
      0.038198322057724,
      0.016635101288557053,
      0.08149724453687668,
      -0.020881954580545425,
      0.10228788107633591,
      -0.06073244288563728,
      -0.025347258895635605,
      0.018301941454410553,
      0.023799624294042587,
      -0.030096078291535378,
      -0.013338511809706688,
      -0.005623374134302139,
      -0.01041550561785698,
      -0.061671219766139984,
      -0.01993362233042717,
      0.0026890691369771957,
      -0.05348847061395645,
      0.05290653929114342,
      0.06146544963121414,
      0.062399063259363174,
      -0.022182835265994072,
      -0.02094443328678608,
      -0.05991632491350174,
      0.0454866923391819,
      -0.0007570610614493489,
      -0.06410867720842361,
      -0.047121811658144,
      0.035376086831092834,
      0.0358671136200428,
      -0.00878112018108368,
      0.004757722374051809,
      -0.01284554973244667,
      -0.03980778902769089,
      0.08821643143892288,
      -0.06041410565376282,
      -0.04499029368162155,
      -0.03711536154150963,
      -0.004275922663509846,
      -0.03380076214671135,
      -0.07610692828893661,
      0.0438099279999733,
      -0.06189471110701561,
      0.0142661202698946,
      0.0066015091724693775,
      -0.012589992955327034,
      -0.0014437385834753513,
      0.008168794214725494,
      -0.022813478484749794,
      0.03595156967639923,
      -0.004553201608359814,
      0.004006745759397745,
      0.04564399644732475,
      0.06618134677410126,
      -0.03337477147579193,
      0.03170944005250931,
      -0.0288153775036335,
      -0.041170064359903336,
      0.0878928154706955,
      0.03180490434169769,
      0.03700936585664749,
      0.02983967773616314,
      -0.07319428026676178,
      -0.02087198570370674,
      0.0020979035180062056,
      0.022253960371017456,
      -0.001147652044892311,
      -0.10741648823022842,
      0.04741388186812401,
      -0.015669533982872963,
      -0.06381555646657944,
      0.0021398558747023344,
      -0.01330128125846386,
      -0.042652007192373276,
      0.04593358933925629,
      -0.020189275965094566,
      0.0660756528377533,
      0.06458748877048492,
      0.0031462707556784153,
      -0.008765572682023048,
      0.0735376626253128,
      -0.03341600298881531,
      0.03425508365035057,
      -0.018416665494441986,
      0.07207188010215759,
      -0.006573263090103865,
      -0.006333211902529001,
      -0.023237647488713264,
      0.04665569216012955,
      0.01794370450079441,
      0.004342120140790939,
      -0.010566302575170994,
      0.01405827235430479,
      0.022952327504754066,
      0.09808868914842606,
      -0.03304918482899666,
      0.01612941175699234,
      -0.11042939126491547,
      0.00039772765012457967,
      0.01867729239165783,
      0.07932110130786896,
      0.016273949295282364,
      -0.04203876480460167,
      0.08561143279075623,
      -0.008991380222141743,
      -0.042857587337493896,
      0.013373537920415401,
      -0.020882077515125275,
      -0.06835471093654633,
      -0.02110498584806919,
      0.012993034906685352,
      -0.03568242862820625,
      -0.013186261989176273,
      0.0650523230433464,
      0.06212260574102402,
      -0.024902043864130974,
      -0.06342320144176483,
      -0.03135252743959427,
      0.007020863238722086,
      0.009015122428536415,
      -0.09550949931144714,
      0.05273210257291794,
      -0.07916520535945892,
      0.014659026637673378,
      -0.059001374989748,
      -0.014722730964422226,
      -0.037965670228004456,
      -0.004891036543995142,
      -0.008235549554228783,
      0.06706291437149048,
      0.08201567828655243,
      -0.02719319611787796,
      0.01564549282193184,
      0.015869632363319397,
      0.09435933083295822
    ]
  ];
export const b1 = [
    0.033665575087070465,
    0.08879638463258743,
    -0.04423709586262703,
    0.10932409018278122,
    0.014465219341218472,
    -0.031088631600141525,
    0.0520591139793396,
    0.0005598794668912888,
    -0.03630717471241951,
    -0.034588370472192764,
    -0.0208059623837471,
    0.005497361067682505,
    -0.03206922113895416,
    0.01740659959614277,
    0.00902275089174509,
    0.07617955654859543,
    -0.017312852665781975,
    0.008804058656096458,
    0.005897988565266132,
    -0.073057621717453,
    0.04511311277747154,
    -0.02840730920433998,
    0.00011301470658509061,
    -0.07489871978759766,
    -0.019475286826491356,
    -0.034039855003356934,
    -0.06139720603823662,
    0.007576564326882362,
    -0.055936362594366074,
    0.0638413205742836,
    -0.03499123454093933,
    -0.00448699900880456,
    -0.01921684667468071,
    0.02075403556227684,
    0.00105149089358747,
    0.0313623882830143,
    7.810672104824334e-05,
    -0.02013542875647545,
    -0.007708385121077299,
    -0.002170257270336151,
    0.0018371717305853963,
    -0.10071210563182831,
    -0.03500283136963844,
    0.025364205241203308,
    -0.019586971029639244,
    0.0618070550262928,
    0.00131004280410707,
    -0.04530416429042816,
    0.07911207526922226,
    -0.023600561544299126,
    -0.030399059876799583,
    -0.06140793114900589,
    -0.0953579694032669,
    -0.021229883655905724,
    0.00702504999935627,
    0.07538483291864395,
    0.051143039017915726,
    0.11250758171081543,
    -0.009919971227645874,
    -0.044218190014362335,
    -0.05330575630068779,
    -0.03182992339134216,
    0.03677387535572052,
    -0.018761051818728447,
    -0.05088076367974281,
    0.0018363033886998892,
    0.041803114116191864,
    0.0024527753703296185,
    -0.0012505952036008239,
    0.06908071041107178,
    -0.11438966542482376,
    0.03436736389994621,
    0.01241896953433752,
    -0.019871769472956657,
    0.022707203403115273,
    -0.031258437782526016,
    -0.06627291440963745,
    -0.02725183218717575,
    0.0386628620326519,
    -0.0636582300066948,
    -0.03186652064323425,
    -0.03401755914092064,
    0.035449862480163574,
    -0.06845098733901978,
    0.02293568290770054,
    -0.010796962305903435,
    0.03261313587427139,
    -0.019407637417316437,
    -0.029584819450974464,
    -0.007347417995333672,
    -0.044124785810709,
    0.0013346270425245166,
    0.029414236545562744,
    0.002346535213291645,
    0.0025375927798449993,
    0.0018700847867876291,
    0.058199331164360046,
    0.04710531234741211,
    0.012142539024353027,
    -0.050276678055524826,
    0.04607536643743515,
    -0.05560344457626343,
    0.043179482221603394,
    0.06495968252420425,
    -0.0477374866604805,
    0.05997027829289436,
    0.010121363215148449,
    -0.02208753116428852,
    0.06290213763713837,
    -0.06239328533411026,
    -0.026836195960640907,
    -0.037539735436439514,
    -0.050000570714473724,
    -0.044213827699422836,
    -0.006430447101593018,
    -0.08189670741558075,
    -0.01272831205278635,
    0.008767245337367058,
    -0.030189115554094315,
    -0.018443625420331955,
    -0.0792253166437149,
    -0.038034502416849136,
    -0.005928065627813339,
    -0.05526251718401909,
    0.03776516765356064,
    -0.08024946600198746,
    0.03415476158261299,
    -0.016977356746792793,
    0.005753966514021158,
    0.11506351828575134,
    0.11430675536394119,
    0.01341276615858078,
    -0.011768583208322525,
    0.007092535030096769,
    0.050110381096601486,
    0.07327966392040253,
    0.061391428112983704,
    -0.037563223391771317,
    -0.028736617416143417,
    -0.04506448656320572,
    0.025018058717250824,
    0.0575193390250206,
    0.09427852928638458,
    -0.03016522340476513,
    -0.009074566885828972,
    -0.01821690984070301,
    -0.02104056626558304,
    -0.04476238414645195,
    -0.046148743480443954,
    -0.0057983105070889,
    -0.001229252084158361,
    0.018791329115629196,
    0.01787373051047325,
    0.018360232934355736,
    -0.0032580283004790545,
    0.00037988321855664253,
    0.06859374791383743,
    0.016565421596169472,
    0.05856676027178764,
    0.07910162955522537,
    -0.0035896804183721542,
    -0.03798423334956169,
    -0.0019961437210440636,
    0.12732122838497162,
    -0.0013702013529837132,
    0.06012884899973869,
    -0.014494313858449459,
    0.0012439663987606764,
    0.07448508590459824,
    -0.008120272308588028,
    0.047380682080984116,
    0.0135983070358634,
    0.044215213507413864,
    0.04167107865214348,
    0.10404400527477264,
    0.03283236548304558,
    0.07875767350196838,
    -0.012209784239530563,
    0.0436200276017189,
    0.08593191206455231,
    -0.022959871217608452,
    -0.0055814459919929504,
    0.018476475030183792,
    -0.05189032480120659,
    0.016465691849589348,
    -0.039447952061891556,
    -0.0021020707208663225,
    0.002052031457424164,
    0.0649157166481018,
    0.039023254066705704,
    -0.016743823885917664,
    0.030861373990774155,
    0.00979570485651493,
    -0.03655647113919258,
    -0.01422099582850933,
    -0.026784591376781464,
    0.012834115885198116,
    -0.03579084575176239,
    0.013620717450976372,
    0.07411640137434006,
    -0.015767354518175125,
    0.0059912982396781445,
    -0.017316922545433044,
    0.011043330654501915,
    -0.023244202136993408,
    0.026761772111058235,
    -0.018833620473742485,
    0.02867882326245308,
    -0.017224078997969627,
    0.012861487455666065,
    -0.06482094526290894,
    0.02234010025858879,
    -0.04865722358226776,
    -0.059120334684848785,
    -0.018160758540034294,
    -0.0010548431891947985,
    -0.009190796874463558,
    -0.01988058164715767,
    0.00011573956726351753,
    0.06797558814287186,
    0.0015053305542096496,
    0.04159807786345482,
    -0.07983270287513733,
    -0.003158243838697672,
    0.012781175784766674,
    0.08353586494922638,
    -0.03386453539133072,
    0.030726540833711624,
    -0.06906699389219284,
    -0.009240763261914253,
    0.02957841195166111,
    0.005662298295646906,
    -0.004933394957333803,
    0.049203209578990936,
    0.031844839453697205,
    -0.02264198660850525,
    0.06710775941610336,
    0.024672238156199455,
    -0.05267558619379997,
    0.0007819501333869994,
    -0.05297166854143143,
    -0.07784566283226013,
    0.05544552579522133,
    -0.024669099599123,
    -0.023792492225766182,
    -0.0006988071836531162,
    -0.03234729543328285,
    -0.10866504907608032,
    -0.02247304469347,
    0.07770837843418121,
    0.009511756710708141,
    0.009544212371110916,
    -0.03717869520187378,
    -0.009824709966778755,
    -0.046196069568395615,
    0.011485250666737556,
    -0.0008759138290770352,
    0.036418408155441284,
    0.03346966207027435,
    0.002186472062021494,
    0.1211942583322525,
    0.0004158802912570536,
    -0.028343839570879936,
    -0.010494610294699669,
    0.027303941547870636,
    0.10474769026041031,
    -0.06140689179301262,
    -0.026923082768917084,
    -0.05034809559583664,
    0.02853630855679512,
    0.0017622034065425396,
    0.014446579851210117,
    -0.11766239255666733,
    0.034308865666389465,
    -0.02458803728222847,
    0.0591224804520607,
    -0.07544413954019547,
    -0.03353133052587509,
    0.011678875423967838,
    -0.01999034732580185,
    -0.005374134052544832,
    -0.09174074977636337,
    -0.010105795226991177,
    -0.0306696854531765,
    -0.031166065484285355,
    0.01886972412467003,
    -0.016252897679805756,
    -0.012549473904073238,
    0.027018846943974495,
    -0.10583468526601791,
    -0.02888179011642933,
    0.0177913885563612,
    -0.054581671953201294,
    0.04217490926384926,
    -0.02485634572803974,
    0.09728818386793137,
    -0.016531940549612045,
    0.05711989477276802,
    0.005290462635457516,
    0.056442342698574066,
    0.0034647046122699976,
    0.016681617125868797,
    -0.08475583046674728,
    0.04632728919386864,
    -0.02371359057724476,
    0.011522171087563038,
    0.04210903123021126,
    -0.027359262108802795,
    -0.03663837909698486,
    -0.01848774217069149,
    0.024866154417395592,
    0.01912045106291771,
    -0.01864565722644329,
    0.005108031444251537,
    -0.049709029495716095,
    -0.007419315166771412,
    -0.043754495680332184,
    -0.03897416964173317,
    0.03634001687169075,
    -0.010082594119012356,
    0.027148259803652763,
    0.034028101712465286,
    -0.08300728350877762,
    0.03312047943472862,
    -0.04329277575016022,
    -0.061312999576330185,
    0.004091527778655291,
    0.041328996419906616,
    -0.13598452508449554,
    0.12767605483531952,
    0.02732575312256813,
    0.03040286712348461,
    -0.09464471787214279,
    0.061065077781677246,
    0.06382914632558823,
    0.018485218286514282,
    0.03975744917988777,
    0.027873611077666283,
    0.039088424295186996,
    0.0037944542709738016,
    0.013893269933760166,
    -0.036886684596538544,
    0.03988974168896675,
    0.035134006291627884,
    -0.011748983524739742,
    -0.04442039132118225,
    0.01325884647667408,
    -0.02927219495177269,
    -0.07110738009214401,
    -0.027561599388718605,
    0.004521931055933237,
    0.013645517639815807,
    0.007999628782272339,
    0.06876285374164581,
    -0.030340949073433876,
    0.015121628530323505,
    0.012197082862257957,
    0.03679589554667473,
    -0.0016512783477082849,
    0.03389842063188553,
    -0.06697645038366318,
    0.00967029482126236,
    -0.06912210583686829,
    0.01539117842912674,
    -0.03232017531991005,
    0.0008621092420071363,
    0.0804600939154625,
    -0.0286810752004385,
    0.016924697905778885,
    0.014040310867130756,
    0.06460803747177124,
    0.0006171126733534038,
    -0.08201885968446732,
    0.028689932078123093,
    -0.03963755443692207,
    -0.05195806547999382,
    0.002364984480664134,
    -0.04856375604867935,
    -0.04539107903838158,
    0.07160785794258118,
    0.06679776310920715,
    0.04362811520695686,
    0.02317309007048607,
    0.032012149691581726,
    -0.001887022634036839,
    0.03487003222107887,
    0.04363008216023445,
    0.019115718081593513,
    -0.0030329131986945868,
    -0.0020251008681952953,
    0.026710543781518936,
    -0.008335663937032223,
    0.03867622837424278,
    0.01940932311117649,
    -0.04362908750772476,
    0.006519065238535404,
    -0.050002966076135635,
    0.04635218530893326,
    -0.053154926747083664,
    -0.027955995872616768,
    -0.028584172949194908,
    -0.05902821570634842,
    -0.000650546164251864,
    0.03637096658349037,
    0.0009752578916959465,
    0.022758280858397484,
    -0.01417363341897726,
    -0.035793859511613846,
    0.06207045912742615,
    0.0474381260573864,
    0.005229308735579252,
    0.03148907423019409,
    -0.024174854159355164,
    0.0025291754864156246,
    -0.04467181861400604,
    0.03200140595436096,
    -0.057721372693777084,
    -0.020016664639115334,
    0.025972196832299232,
    -0.0423215888440609,
    0.04377792030572891,
    0.050986237823963165,
    -0.041201189160346985,
    0.025502989068627357,
    -0.005555429495871067,
    0.05241083353757858,
    0.02445499785244465,
    -0.01538165658712387,
    0.061667926609516144,
    0.05511706694960594,
    0.004235401749610901,
    0.013944460079073906,
    0.002427318599075079,
    -0.034861695021390915,
    -0.08880612999200821,
    -0.005435300059616566,
    -0.05133335292339325,
    -0.05244755744934082,
    -0.10438661277294159,
    -0.0214378759264946,
    -0.002428102772682905,
    -0.04194491356611252,
    0.07851344347000122,
    -0.012152210809290409,
    0.04697231948375702,
    0.0654909610748291,
    0.06247716397047043,
    0.01756293512880802,
    -0.025840453803539276,
    0.04794331267476082,
    -0.024552572518587112,
    0.017689211294054985,
    0.008511885069310665,
    -0.03348234295845032,
    0.020640317350625992,
    0.055325113236904144,
    0.014947925694286823,
    0.005517917685210705,
    -0.05029277503490448,
    0.033045921474695206,
    -0.0034864996559917927,
    0.009546489454805851,
    -0.03529135137796402,
    -0.04429309070110321,
    -0.061141107231378555,
    0.013939451426267624,
    -0.013952932320535183,
    0.018953708931803703,
    0.019794384017586708,
    0.014580867253243923,
    0.05074482411146164,
    0.03257107362151146,
    -0.0037195486947894096,
    0.05027800798416138,
    0.010643948800861835,
    -0.05817852169275284,
    0.04624326154589653,
    -0.022216452285647392,
    -0.002648631576448679,
    -0.04432549327611923,
    -0.03997929021716118,
    0.12242164462804794,
    -0.005743955262005329,
    0.04729203134775162,
    0.014057861641049385,
    -0.11039206385612488,
    0.07964258641004562,
    0.003571520559489727,
    -0.05737273767590523,
    -0.003289989661425352,
    -0.038851868361234665,
    -0.030402302742004395,
    0.020914573222398758,
    0.11804283410310745,
    -0.040502697229385376,
    0.04142157733440399,
    -0.04192138463258743,
    0.06152060627937317,
    -0.032553184777498245,
    -0.004591007251292467,
    -0.06253673881292343,
    0.019944114610552788,
    0.03788894787430763
  ];
export const w2 = [
    [
      -0.06650245934724808,
      -0.2251727283000946,
      0.18929338455200195,
      0.0898662656545639,
      -0.10795203596353531,
      0.08670428395271301,
      -0.06473521143198013,
      0.05430597439408302,
      0.06709065288305283,
      0.02242644503712654,
      -0.02435438334941864,
      -0.027923496440052986,
      -0.05775973200798035,
      0.026622122153639793,
      -0.04590130224823952,
      -0.006276651285588741,
      0.06727255880832672
    ],
    [
      0.04510315880179405,
      0.08716865628957748,
      -0.07601380348205566,
      0.019925279542803764,
      -0.09056784957647324,
      0.0036984405014663935,
      -0.02377239055931568,
      -0.02518979273736477,
      0.029840493574738503,
      0.017532361671328545,
      0.04947813227772713,
      -0.023733943700790405,
      -0.02943272329866886,
      0.0126947658136487,
      -0.01013508066534996,
      0.0510818250477314,
      0.08417504280805588
    ],
    [
      0.0019217428052797914,
      0.02439294196665287,
      0.027108941227197647,
      0.08297143131494522,
      0.05069560930132866,
      -0.07422696799039841,
      -0.022132938727736473,
      -0.003486873582005501,
      -0.0762820690870285,
      0.0738082304596901,
      0.0324234813451767,
      0.022559378296136856,
      0.0942448079586029,
      -0.055312126874923706,
      -0.054094165563583374,
      0.09043032675981522,
      -0.05620633065700531
    ],
    [
      -0.2006823718547821,
      -0.16229967772960663,
      0.14988116919994354,
      -0.14053793251514435,
      -0.07135408371686935,
      -0.03681694716215134,
      -0.3239920735359192,
      0.007566148415207863,
      -0.11620979756116867,
      0.2894630432128906,
      0.002875733422115445,
      0.0026087623555213213,
      0.10049103945493698,
      0.09918557107448578,
      0.019240625202655792,
      -0.12571771442890167,
      0.04964134469628334
    ],
    [
      -0.26553818583488464,
      0.21717356145381927,
      0.07371673732995987,
      0.06795687973499298,
      0.06482220441102982,
      0.025353655219078064,
      0.16809873282909393,
      0.01248593907803297,
      -0.05446813255548477,
      -0.09590107947587967,
      0.047235067933797836,
      0.0039038220420479774,
      0.11280710250139236,
      -0.14792920649051666,
      -0.18172703683376312,
      -0.07330058515071869,
      0.06761208176612854
    ],
    [
      0.08763673901557922,
      0.1170806735754013,
      -0.011539892293512821,
      -0.0840088501572609,
      0.05572492629289627,
      0.06903384625911713,
      0.02757810242474079,
      0.006667574867606163,
      -0.047085631638765335,
      -0.023031840100884438,
      0.04774114117026329,
      0.09485511481761932,
      -0.03589955344796181,
      -0.05707291141152382,
      -0.11634915322065353,
      0.054176460951566696,
      0.08244404196739197
    ],
    [
      -0.027346843853592873,
      -0.13649369776248932,
      -0.1396062672138214,
      -0.09486671537160873,
      -0.030761348083615303,
      -0.1282295137643814,
      -0.3312758207321167,
      0.20425766706466675,
      -0.06456101685762405,
      0.04169056937098503,
      0.016313737258315086,
      0.07762585580348969,
      -0.004511930048465729,
      0.13857731223106384,
      -0.1106824278831482,
      -0.0822187289595604,
      0.25331541895866394
    ],
    [
      -0.05135880783200264,
      -0.14756986498832703,
      0.07916021347045898,
      -0.09094050526618958,
      -0.05978761985898018,
      -0.07613655924797058,
      0.06574112921953201,
      -0.01653171516954899,
      -0.0427127443253994,
      -0.04316924139857292,
      0.0650700107216835,
      0.015697022899985313,
      -0.0272228941321373,
      -0.05886375904083252,
      0.16642062366008759,
      0.035493191331624985,
      0.040627285838127136
    ],
    [
      0.10778943449258804,
      0.18194837868213654,
      -0.31257107853889465,
      -0.1800604611635208,
      -0.015750490128993988,
      0.05842938274145126,
      -0.14230844378471375,
      0.06661819666624069,
      0.16399511694908142,
      -0.1568194329738617,
      0.17389103770256042,
      0.10026303678750992,
      0.01853102631866932,
      0.006628087721765041,
      0.10954207181930542,
      -0.1090189665555954,
      -0.2027084231376648
    ],
    [
      -0.20952686667442322,
      0.2556408941745758,
      -0.09694366157054901,
      0.07659748196601868,
      0.05868614464998245,
      0.12224343419075012,
      0.004431946203112602,
      0.1755693554878235,
      -0.031051892787218094,
      -0.11190251260995865,
      -0.03349439799785614,
      -0.1073121502995491,
      -0.04266810044646263,
      -0.023294975981116295,
      -0.03397631645202637,
      0.04183458909392357,
      0.06023232266306877
    ],
    [
      0.05215759202837944,
      0.02283615618944168,
      -0.24140022695064545,
      -0.1100972592830658,
      -0.05692934989929199,
      -0.059434305876493454,
      0.10221704095602036,
      0.12826964259147644,
      -0.10072546452283859,
      0.06785369664430618,
      -0.08550072461366653,
      0.04313097521662712,
      0.00868786871433258,
      0.0496566966176033,
      -0.09133870154619217,
      -0.20217770338058472,
      0.10598622262477875
    ],
    [
      -0.15025627613067627,
      0.05739680305123329,
      -0.08578962087631226,
      -0.13774655759334564,
      0.02455887943506241,
      0.08596836030483246,
      -0.04510779678821564,
      -0.06693287193775177,
      0.06301871687173843,
      -0.0788695439696312,
      -0.07739963382482529,
      0.10550563782453537,
      0.053613197058439255,
      0.013563711196184158,
      -0.14836260676383972,
      0.13801643252372742,
      -0.02074800617992878
    ],
    [
      0.08114297688007355,
      0.07188344746828079,
      -0.03988133370876312,
      0.012665657326579094,
      0.03652767091989517,
      -0.044154565781354904,
      0.04637041687965393,
      -0.03099919855594635,
      -0.02797417901456356,
      0.0008845324045978487,
      -0.04719594866037369,
      -0.035168278962373734,
      -0.04740406200289726,
      0.07851403951644897,
      -0.019388703629374504,
      -0.12305746227502823,
      -0.0052652484737336636
    ],
    [
      -0.20595955848693848,
      -0.05027121305465698,
      0.19157375395298004,
      0.14647738635540009,
      0.05585617199540138,
      0.06265171617269516,
      0.018814407289028168,
      0.09062352031469345,
      -0.026137467473745346,
      -0.017322096973657608,
      -0.057717353105545044,
      0.022553276270627975,
      0.07371070981025696,
      -0.023020273074507713,
      0.034930724650621414,
      0.05373962223529816,
      -0.11453129351139069
    ],
    [
      -0.08155149221420288,
      -0.07965321838855743,
      -0.15259084105491638,
      0.07768643647432327,
      0.039242520928382874,
      -0.0017883783439174294,
      0.09789580851793289,
      -0.0631004273891449,
      0.0993766263127327,
      0.005589908454567194,
      -0.052597980946302414,
      0.04102466627955437,
      -0.07548705488443375,
      -0.16477002203464508,
      -0.0031744656153023243,
      0.14078865945339203,
      0.2107045203447342
    ],
    [
      -0.025703396648168564,
      0.07011160999536514,
      -0.07275624573230743,
      -0.025885572656989098,
      -0.021779654547572136,
      0.04013308882713318,
      -0.0202657338231802,
      0.08663106709718704,
      -0.017208321020007133,
      -0.018572403118014336,
      0.05345532298088074,
      -0.003893095999956131,
      -0.006697130389511585,
      -0.012436898425221443,
      -0.015478814952075481,
      -0.04061476141214371,
      -0.027129946276545525
    ],
    [
      -0.03915325179696083,
      -0.15456806123256683,
      -0.011091809719800949,
      0.07132918387651443,
      -0.033551283180713654,
      -0.05435873940587044,
      -0.12785105407238007,
      0.10914528369903564,
      0.0021052176598459482,
      0.04789777845144272,
      -0.04808405414223671,
      0.11696082353591919,
      0.08544836193323135,
      0.13293568789958954,
      0.01262997929006815,
      -0.09899201989173889,
      -0.026909859851002693
    ],
    [
      -0.03508783131837845,
      0.03879173472523689,
      0.12908220291137695,
      0.0890885442495346,
      0.010224856436252594,
      0.0028878103476017714,
      0.026988552883267403,
      0.010274101980030537,
      0.07603441178798676,
      0.03479780629277229,
      -0.023086728528141975,
      -0.017679503187537193,
      0.06970665603876114,
      0.08058872073888779,
      -0.005230688024312258,
      -0.01812945306301117,
      -0.08074827492237091
    ],
    [
      -0.09910488873720169,
      -0.10990411788225174,
      0.00920503493398428,
      0.034073296934366226,
      -0.08174777776002884,
      -0.02532123774290085,
      -0.07678776979446411,
      0.14139686524868011,
      0.0029577678069472313,
      0.000871237542014569,
      0.05603215470910072,
      0.04761568456888199,
      0.004298542160540819,
      0.04224858433008194,
      0.0495542474091053,
      0.07408250868320465,
      0.047661591321229935
    ],
    [
      -0.17936977744102478,
      0.03376331180334091,
      0.00601439643651247,
      -0.07300633937120438,
      -0.043851640075445175,
      0.05268000811338425,
      -0.022419527173042297,
      0.09964140504598618,
      -0.14079004526138306,
      -0.0033963932655751705,
      0.010030914098024368,
      -0.048181191086769104,
      0.02230258472263813,
      -0.0019228552700951695,
      -0.05314845219254494,
      0.02505360171198845,
      0.026187937706708908
    ],
    [
      -0.09372875094413757,
      -0.10769987106323242,
      0.06500815600156784,
      0.0939340591430664,
      0.07001132518053055,
      -0.018314875662326813,
      0.09943679720163345,
      0.16006052494049072,
      0.017689445987343788,
      0.05524129793047905,
      0.015315798111259937,
      -0.03931252285838127,
      -0.005350757390260696,
      0.10530298203229904,
      0.01600426435470581,
      -0.1214410737156868,
      0.12332133203744888
    ],
    [
      -0.07006055861711502,
      0.008682250045239925,
      -0.07700109481811523,
      0.15510877966880798,
      0.05530603229999542,
      0.09380612522363663,
      -0.113530233502388,
      0.05670645087957382,
      0.0009968372760340571,
      -0.057118333876132965,
      0.09677471220493317,
      -0.013593138195574284,
      0.03252563998103142,
      -0.11896954476833344,
      -0.018022723495960236,
      -0.07240986824035645,
      -0.03739874064922333
    ],
    [
      -0.10110931843519211,
      -0.0136010916903615,
      0.03555893525481224,
      0.052335865795612335,
      0.025756387040019035,
      -0.04248875752091408,
      -0.03887949511408806,
      0.03380649909377098,
      0.0004469305567909032,
      -0.011853359639644623,
      0.012818049639463425,
      0.05126630887389183,
      0.07715210318565369,
      0.052945591509342194,
      0.12688769400119781,
      0.0295699592679739,
      0.09778522700071335
    ],
    [
      0.08688255399465561,
      0.21073955297470093,
      -0.10838018357753754,
      0.10935334861278534,
      0.13122516870498657,
      0.05579328164458275,
      0.005115858279168606,
      0.08255412429571152,
      -0.04661176726222038,
      -0.16615109145641327,
      0.04029414430260658,
      0.0036457234527915716,
      -0.0681561827659607,
      -0.1365756094455719,
      -0.19369134306907654,
      -0.01438560988754034,
      0.02126844972372055
    ],
    [
      0.2017592340707779,
      -0.04970470070838928,
      0.27647659182548523,
      -0.09818056970834732,
      -0.005482405889779329,
      -0.17699068784713745,
      0.11440610885620117,
      -0.03853995352983475,
      -0.27514418959617615,
      0.38863101601600647,
      -0.04597103223204613,
      0.18861323595046997,
      -0.06728997081518173,
      0.00687667028978467,
      -0.14501063525676727,
      -0.2951854467391968,
      0.09033485502004623
    ],
    [
      0.12166358530521393,
      0.060098178684711456,
      0.03241616114974022,
      0.17024777829647064,
      -0.008631549775600433,
      -0.02381441555917263,
      0.19289423525333405,
      -0.2558995187282562,
      0.25107237696647644,
      -0.17118732631206512,
      -0.04633719474077225,
      -0.035113755613565445,
      0.12850923836231232,
      -0.17375865578651428,
      0.0059928069822490215,
      0.03990725427865982,
      0.02971031330525875
    ],
    [
      -0.03681810200214386,
      -0.08039569854736328,
      0.005478071514517069,
      0.003928986378014088,
      0.08133280277252197,
      -0.010636115446686745,
      0.04361024498939514,
      0.006384200882166624,
      -0.06734558939933777,
      0.025776950642466545,
      -0.006769506260752678,
      -0.033957887440919876,
      -0.014758213423192501,
      0.03665430098772049,
      -0.01119372621178627,
      -0.07757162302732468,
      0.028928617015480995
    ],
    [
      0.14945602416992188,
      -0.120963916182518,
      -0.34918928146362305,
      0.08374061435461044,
      0.05666486546397209,
      -0.015225550159811974,
      0.007907852530479431,
      0.1389504224061966,
      0.0993051677942276,
      -0.21925769746303558,
      0.290764719247818,
      -0.011690585874021053,
      -0.05224311351776123,
      -0.11919715255498886,
      0.091807059943676,
      0.053856078535318375,
      0.30004069209098816
    ],
    [
      0.0610605850815773,
      0.04529738053679466,
      0.047451138496398926,
      -0.004630900453776121,
      -0.0018339131493121386,
      0.07914166897535324,
      0.009023798629641533,
      -0.06618994474411011,
      0.00284588267095387,
      -0.022845743224024773,
      0.018367866054177284,
      -0.016791662201285362,
      0.011426365002989769,
      0.051182594150304794,
      0.04624784737825394,
      0.13983984291553497,
      -0.09578533470630646
    ],
    [
      0.05319640040397644,
      -0.0514659583568573,
      -0.021381154656410217,
      -0.1568337380886078,
      0.01868959330022335,
      0.16243085265159607,
      0.0314248763024807,
      0.11069636046886444,
      0.03579581156373024,
      -0.13552923500537872,
      -0.00580610241740942,
      0.10142679512500763,
      -0.013374303467571735,
      0.10749393701553345,
      -0.007206599693745375,
      -0.16357529163360596,
      -0.12379489094018936
    ],
    [
      -0.17503800988197327,
      -0.0007779167499393225,
      0.03864552825689316,
      0.09530115127563477,
      0.06639169901609421,
      0.07153791189193726,
      0.1240052729845047,
      -0.24979257583618164,
      0.16349342465400696,
      0.09919124096632004,
      0.0685458779335022,
      -0.1252746433019638,
      -0.11629713326692581,
      -0.11097684502601624,
      0.2093762308359146,
      0.0534849539399147,
      0.06056744232773781
    ],
    [
      0.026845233514904976,
      -0.02802964486181736,
      0.016506532207131386,
      0.021377485245466232,
      -0.05642066150903702,
      -0.016939254477620125,
      -0.0128144146874547,
      0.0705782026052475,
      0.0406259223818779,
      0.03798217698931694,
      0.002005385933443904,
      0.10895141214132309,
      -0.0486949123442173,
      0.04169435054063797,
      0.007908498868346214,
      0.1014540046453476,
      0.09934603422880173
    ],
    [
      0.07417230308055878,
      -0.02974209003150463,
      0.14330339431762695,
      0.1343608796596527,
      0.06814161688089371,
      -0.08033467829227448,
      -0.08302831649780273,
      0.007759926840662956,
      0.03560260683298111,
      -0.008347652852535248,
      0.1099487692117691,
      0.05208311229944229,
      -0.0394667349755764,
      -0.029967786744236946,
      -0.025628484785556793,
      -0.08376895636320114,
      0.0618157759308815
    ],
    [
      -0.1449553519487381,
      0.030041983351111412,
      0.19173960387706757,
      0.19948075711727142,
      0.10378722101449966,
      0.01403254084289074,
      -0.08808400481939316,
      -0.07731731981039047,
      -0.04761581867933273,
      0.16916225850582123,
      -0.12757059931755066,
      -0.1348852962255478,
      0.21409545838832855,
      0.00041738300933502614,
      0.022248664870858192,
      0.03587906435132027,
      -0.1109718307852745
    ],
    [
      -0.06638140976428986,
      -0.05302588641643524,
      0.029426824301481247,
      0.09043604135513306,
      -0.1042453944683075,
      0.004679150879383087,
      0.044416576623916626,
      -0.03780573606491089,
      0.051893264055252075,
      -0.1294904202222824,
      -0.060368675738573074,
      -0.08558960258960724,
      0.01375136524438858,
      0.010329586453735828,
      -0.06666508316993713,
      -0.05586937069892883,
      -0.016419928520917892
    ],
    [
      0.012632518075406551,
      0.0596727654337883,
      0.009297691285610199,
      0.07797767966985703,
      -0.14697004854679108,
      0.11978545784950256,
      -0.07969941198825836,
      -0.017816942185163498,
      -0.057140424847602844,
      0.03830829635262489,
      -0.06265353411436081,
      0.08961797505617142,
      0.08415023237466812,
      0.03730723261833191,
      0.02466117963194847,
      0.02420726791024208,
      -0.043531425297260284
    ],
    [
      -0.11187411099672318,
      -0.06447046250104904,
      -0.2251678854227066,
      -0.01200168952345848,
      -0.012034929357469082,
      -0.1474764049053192,
      -0.001781340455636382,
      0.10335242003202438,
      0.0628737211227417,
      -0.17711065709590912,
      0.01778189279139042,
      0.0774286612868309,
      -0.11824799329042435,
      0.07931218296289444,
      0.05522250384092331,
      -0.06751732528209686,
      0.017428487539291382
    ],
    [
      -0.01751326583325863,
      -0.03364239260554314,
      -0.00449446402490139,
      -0.07451364398002625,
      -0.021413549780845642,
      0.03107789158821106,
      -0.050610315054655075,
      0.1044359803199768,
      0.054546058177948,
      0.15221057832241058,
      0.03378274291753769,
      0.03356719762086868,
      -0.021061642095446587,
      -0.01569843664765358,
      -0.07151062041521072,
      -0.026780903339385986,
      0.02593027800321579
    ],
    [
      0.09062909334897995,
      0.007598098833113909,
      0.11367922276258469,
      -0.13032445311546326,
      0.04174073785543442,
      0.036450039595365524,
      -0.009225624613463879,
      0.03964434564113617,
      -0.02686028741300106,
      -0.04485487937927246,
      0.10394524782896042,
      0.14666756987571716,
      0.11632704734802246,
      -0.047208093106746674,
      -0.04095339775085449,
      -0.035412248224020004,
      0.05196872726082802
    ],
    [
      -0.12765398621559143,
      0.11164766550064087,
      0.2557002305984497,
      0.11989904940128326,
      -0.041620876640081406,
      -0.05765349045395851,
      -0.08162622153759003,
      -0.11295398324728012,
      -0.06615271419286728,
      0.04724262282252312,
      0.1021391823887825,
      0.08136599510908127,
      0.06238047033548355,
      0.08246742188930511,
      -0.02058773674070835,
      0.003938751760870218,
      0.05262905731797218
    ],
    [
      0.012871090322732925,
      0.01467929594218731,
      -0.19847969710826874,
      -0.010100655257701874,
      0.03943755477666855,
      0.2546025812625885,
      -0.28489747643470764,
      -0.11200862377882004,
      -0.10696281492710114,
      -0.1761889010667801,
      0.12742431461811066,
      -0.2050173133611679,
      0.11363602429628372,
      -0.011983982287347317,
      0.17993320524692535,
      0.3258523643016815,
      0.027202293276786804
    ],
    [
      0.1113535687327385,
      -0.05587777495384216,
      -0.045486725866794586,
      -0.04381457343697548,
      -0.09391587227582932,
      0.0037413379177451134,
      0.021367890760302544,
      -0.010942268185317516,
      0.010256190784275532,
      -0.12833918631076813,
      -0.08827448636293411,
      0.03234162926673889,
      -0.005620119161903858,
      0.011801755987107754,
      0.1166045293211937,
      -0.042775530368089676,
      -0.05886088311672211
    ],
    [
      0.03691498190164566,
      -0.05995246022939682,
      0.06179676949977875,
      -0.18996654450893402,
      0.12787820398807526,
      0.0031358676496893167,
      -0.2074875831604004,
      0.19679300487041473,
      -0.018157899379730225,
      0.09705466777086258,
      -0.0498155876994133,
      0.038862913846969604,
      -0.0781504213809967,
      -0.04927701875567436,
      0.043274298310279846,
      0.016649797558784485,
      0.0633532851934433
    ],
    [
      0.004384506493806839,
      -0.03731644153594971,
      0.04380093142390251,
      0.004822639282792807,
      0.027885055169463158,
      -0.06643170118331909,
      0.07026566565036774,
      0.025492940098047256,
      -0.044671352952718735,
      0.040188878774642944,
      0.017496375367045403,
      -0.021090146154165268,
      -0.060253553092479706,
      -0.008952358737587929,
      0.03167814761400223,
      0.01910453848540783,
      -0.06795059889554977
    ],
    [
      0.009605498984456062,
      -0.07110264897346497,
      -0.0514373742043972,
      -0.27885696291923523,
      -0.14292395114898682,
      0.035753004252910614,
      0.09502318501472473,
      -0.0819840356707573,
      0.23272491991519928,
      -0.13339105248451233,
      0.03501983359456062,
      -0.2302676886320114,
      0.05187071114778519,
      -0.008433080278337002,
      0.05885427072644234,
      0.030345691367983818,
      0.005080138333141804
    ],
    [
      -0.06074085459113121,
      0.10315883159637451,
      0.12826623022556305,
      0.06511727720499039,
      0.013501073233783245,
      0.07280494272708893,
      -0.07506518065929413,
      -0.09018440544605255,
      -0.11611926555633545,
      0.08967914432287216,
      0.05187039077281952,
      -0.004208543337881565,
      -0.04552793130278587,
      -0.042319558560848236,
      0.1698419451713562,
      -0.030103448778390884,
      -0.04965091869235039
    ],
    [
      -0.21186967194080353,
      -0.003971700090914965,
      0.06266820430755615,
      -0.005502938758581877,
      -0.05809427425265312,
      -0.3580263555049896,
      0.28548532724380493,
      0.0480533204972744,
      0.0894070491194725,
      0.1334499716758728,
      0.06567322462797165,
      -0.010409238748252392,
      -0.08152148872613907,
      0.21221959590911865,
      -0.1873157024383545,
      -0.2358899861574173,
      0.4825955629348755
    ],
    [
      -0.08439257740974426,
      0.010066037997603416,
      -0.003269229084253311,
      0.07898914068937302,
      0.011890575289726257,
      -0.02614438720047474,
      0.13384735584259033,
      0.01310841552913189,
      0.0215652734041214,
      0.004453267436474562,
      -0.11326339095830917,
      -0.0747179165482521,
      0.07903188467025757,
      -0.1064210832118988,
      0.028916556388139725,
      0.06725417822599411,
      0.14650173485279083
    ],
    [
      -0.038823202252388,
      0.03397704288363457,
      -0.0902867242693901,
      0.011646793223917484,
      -0.07395020127296448,
      -0.07485020160675049,
      0.2988921105861664,
      0.019756212830543518,
      0.035518959164619446,
      0.10882322490215302,
      0.1349732130765915,
      -0.06177247688174248,
      -0.09018687158823013,
      -0.05238598212599754,
      0.07513900846242905,
      -0.052042603492736816,
      -0.021011412143707275
    ],
    [
      0.04516461119055748,
      -0.06998597830533981,
      0.11748985201120377,
      0.051879238337278366,
      -0.12765268981456757,
      0.016476167365908623,
      0.007175392471253872,
      0.002122384263202548,
      0.01910865306854248,
      -0.036250192672014236,
      -0.011897195130586624,
      -0.024314705282449722,
      -0.06519332528114319,
      0.05282538756728172,
      0.010973794385790825,
      0.02322923019528389,
      0.08461085706949234
    ],
    [
      -0.10465367883443832,
      -0.027104612439870834,
      -0.10063033550977707,
      -0.05563337355852127,
      0.035364970564842224,
      -0.1696862131357193,
      -0.014229955151677132,
      0.29633551836013794,
      -0.12634067237377167,
      0.06554652005434036,
      -0.10874510556459427,
      0.04378382861614227,
      -0.015115619637072086,
      0.029402099549770355,
      -0.11961115896701813,
      -0.0693754106760025,
      -0.0010859878966584802
    ],
    [
      0.025660434737801552,
      -0.19190900027751923,
      0.2840556800365448,
      0.15652048587799072,
      -0.07206347584724426,
      -0.2195141613483429,
      0.06346935778856277,
      -0.1544201374053955,
      0.061582956463098526,
      0.1887894719839096,
      -0.10145062953233719,
      -0.10299619287252426,
      0.219419464468956,
      0.036067090928554535,
      0.046249955892562866,
      -0.017372027039527893,
      0.1604723334312439
    ],
    [
      0.04143809154629707,
      -0.08697488158941269,
      -0.05454147234559059,
      0.13939514756202698,
      0.003474519355222583,
      -0.07253273576498032,
      -0.07629697769880295,
      -0.03385460004210472,
      -0.030877545475959778,
      0.015477128326892853,
      -0.031047901138663292,
      0.057065609842538834,
      0.09280862659215927,
      -0.09939081966876984,
      -0.03358184173703194,
      0.07710757106542587,
      -0.006753120105713606
    ],
    [
      0.006601796485483646,
      0.024587109684944153,
      0.05880092456936836,
      -0.07982954382896423,
      0.0025588530115783215,
      0.013163423165678978,
      0.07823631912469864,
      0.05066097155213356,
      -0.04861987382173538,
      -0.008448186330497265,
      -0.03086446225643158,
      0.12124637514352798,
      0.1401960700750351,
      0.0856906995177269,
      0.004164678975939751,
      0.012270449660718441,
      -0.07245855033397675
    ],
    [
      -0.144685298204422,
      0.10471661388874054,
      0.1667364090681076,
      -0.14323323965072632,
      -0.08904910087585449,
      0.09794902801513672,
      0.037337303161621094,
      -0.017885401844978333,
      0.0034274253994226456,
      0.06423825770616531,
      -0.1462835967540741,
      -0.061006151139736176,
      0.057778652757406235,
      -0.07448519766330719,
      0.05922108516097069,
      0.036195822060108185,
      -0.08759495615959167
    ],
    [
      0.06488017737865448,
      0.013630595058202744,
      -0.11208804696798325,
      -0.04699757695198059,
      -0.0074995425529778,
      -0.08570633828639984,
      0.11940678209066391,
      0.11036635935306549,
      0.06163574755191803,
      -0.20327997207641602,
      0.20697632431983948,
      -0.07594391703605652,
      -0.09867671132087708,
      -0.09021524339914322,
      -0.05737747251987457,
      -0.1945699155330658,
      0.15252363681793213
    ],
    [
      -0.22555632889270782,
      0.02161571756005287,
      0.30172914266586304,
      0.04433904215693474,
      0.0741211399435997,
      -0.170611172914505,
      0.3002893328666687,
      -0.17536082863807678,
      -0.20360814034938812,
      0.32239094376564026,
      -0.019672922790050507,
      -0.14558114111423492,
      -0.035398561507463455,
      -0.25970757007598877,
      0.026611654087901115,
      0.027537263929843903,
      0.30153384804725647
    ],
    [
      -0.1581447720527649,
      0.07160262018442154,
      0.06886894255876541,
      -0.2723971903324127,
      -0.17989541590213776,
      -0.15832094848155975,
      0.05815870687365532,
      -0.11839324980974197,
      0.037281911820173264,
      0.1121935248374939,
      -0.019593045115470886,
      -0.09012753516435623,
      0.1330721080303192,
      0.18818917870521545,
      0.1502314954996109,
      -0.07593848556280136,
      0.033679716289043427
    ],
    [
      -0.11463580280542374,
      -0.14913371205329895,
      0.01622622460126877,
      -0.21070168912410736,
      -0.0346226803958416,
      -0.024853965267539024,
      -0.03824006766080856,
      0.013981112278997898,
      -0.07070690393447876,
      0.076918825507164,
      0.12130966782569885,
      0.1889369636774063,
      -0.07172492891550064,
      0.10089169442653656,
      0.021032894030213356,
      -0.18420098721981049,
      0.15896408259868622
    ],
    [
      0.03824624791741371,
      0.07706429809331894,
      -0.12189041823148727,
      -0.018854238092899323,
      0.03440026193857193,
      0.02340676635503769,
      -0.059521209448575974,
      -0.07749058306217194,
      -0.02011531963944435,
      -0.03647414222359657,
      0.07827635854482651,
      -0.01999824121594429,
      0.053064607083797455,
      0.01668204553425312,
      -0.05578821524977684,
      0.13244077563285828,
      0.0010080764768645167
    ],
    [
      -0.06558413803577423,
      0.08621922135353088,
      0.16090594232082367,
      -0.021951936185359955,
      -0.020027391612529755,
      0.11701139062643051,
      -0.0868092030286789,
      -0.042716238647699356,
      0.06325708329677582,
      0.04821053892374039,
      -0.1162063479423523,
      -0.12632474303245544,
      0.028490647673606873,
      -0.049499694257974625,
      0.004981762729585171,
      0.021198708564043045,
      -0.013948160223662853
    ],
    [
      -0.07092452794313431,
      0.1355380266904831,
      0.07050015032291412,
      0.0682302787899971,
      -0.01892971619963646,
      0.1301964521408081,
      0.27050215005874634,
      -0.05023815482854843,
      0.13561241328716278,
      -0.09717557579278946,
      -0.24086445569992065,
      -0.09177685528993607,
      0.00899709202349186,
      -0.09230046719312668,
      0.1367914229631424,
      -0.12308239936828613,
      -0.018266746774315834
    ],
    [
      -0.13233856856822968,
      -0.05200802534818649,
      -0.023158464580774307,
      -0.16776229441165924,
      -0.00917181558907032,
      0.042337819933891296,
      -0.1535678207874298,
      0.021677622571587563,
      0.12716111540794373,
      0.03997895494103432,
      -0.10290806740522385,
      -0.06569357961416245,
      0.2501872181892395,
      0.1031017154455185,
      0.08439844101667404,
      -0.08800255507230759,
      0.009143766947090626
    ],
    [
      -0.006085338536649942,
      0.030980948358774185,
      -0.07437451928853989,
      -0.052014123648405075,
      0.013911491259932518,
      -0.07166546583175659,
      0.011216853745281696,
      -0.08992046117782593,
      -0.12376505881547928,
      -0.08366696536540985,
      -0.05258893966674805,
      -0.05955894663929939,
      0.005129897966980934,
      0.09877970069646835,
      0.022985266521573067,
      0.00707131065428257,
      -0.007031255401670933
    ],
    [
      0.01006587315350771,
      -0.18490777909755707,
      0.041407354176044464,
      0.11753076314926147,
      0.05659150332212448,
      0.2193000763654709,
      0.011758903972804546,
      -0.037875495851039886,
      -0.07229307293891907,
      -0.2016930878162384,
      -0.0020422395318746567,
      0.03470681235194206,
      -0.14422869682312012,
      0.05995183065533638,
      0.13259239494800568,
      0.2277507781982422,
      -0.12692861258983612
    ],
    [
      -0.06125260889530182,
      -0.01950930617749691,
      0.023725735023617744,
      -0.14491431415081024,
      0.044364869594573975,
      0.09074468165636063,
      0.008882054127752781,
      -0.01740761287510395,
      0.10546953976154327,
      -0.13900387287139893,
      -0.04305111616849899,
      0.09778452664613724,
      0.016884392127394676,
      0.011304316110908985,
      0.08656550198793411,
      -0.11207445710897446,
      0.017704954370856285
    ],
    [
      0.07292626798152924,
      -0.15855294466018677,
      0.12552626430988312,
      0.19628967344760895,
      0.11980389803647995,
      -0.09108122438192368,
      -0.13427267968654633,
      0.003921791911125183,
      -0.12807634472846985,
      0.10579672455787659,
      -0.15403848886489868,
      0.04578016698360443,
      0.16140665113925934,
      -0.00365606346167624,
      -0.08588487654924393,
      -0.11052814871072769,
      -0.11440007388591766
    ],
    [
      -0.01194556150585413,
      -0.03043847158551216,
      0.003931769635528326,
      -0.03736984729766846,
      0.06225885450839996,
      0.16111449897289276,
      -0.014311060309410095,
      0.08412899076938629,
      0.0022488345857709646,
      -0.05303635075688362,
      0.09849677979946136,
      0.02512786164879799,
      0.0581953302025795,
      -0.0038517594803124666,
      -0.018063809722661972,
      -0.007358782924711704,
      0.03438478708267212
    ],
    [
      0.03356904536485672,
      -0.05296961963176727,
      0.0349273681640625,
      -0.003147195791825652,
      0.10741367936134338,
      -0.004601475317031145,
      -0.013312267139554024,
      0.04739215597510338,
      -0.05268789082765579,
      0.04489309340715408,
      0.004314630292356014,
      -0.012734581716358662,
      -0.022677481174468994,
      0.06889662891626358,
      0.05729931965470314,
      -0.12816327810287476,
      -0.014435378834605217
    ],
    [
      -0.02402407117187977,
      -0.07381897419691086,
      0.03216775879263878,
      -0.07167812436819077,
      0.024616459384560585,
      -0.04859831929206848,
      0.06719910353422165,
      0.1336955577135086,
      -0.1433219015598297,
      0.05487687885761261,
      -0.019671166315674782,
      0.292114794254303,
      0.001396576757542789,
      0.11717454344034195,
      -0.10766588896512985,
      -0.06285277754068375,
      -0.02731219120323658
    ],
    [
      0.000497130851726979,
      0.010903577320277691,
      -0.0477980338037014,
      0.001375960768200457,
      0.012299959547817707,
      -0.0499144122004509,
      0.004823075141757727,
      0.11656118184328079,
      0.061178334057331085,
      0.009579193778336048,
      0.1271018534898758,
      0.04469634220004082,
      0.06253864616155624,
      0.006550621706992388,
      -0.06299711763858795,
      -0.09882519394159317,
      0.05042511224746704
    ],
    [
      0.016106359660625458,
      0.1109846904873848,
      0.0064756316132843494,
      0.02332308143377304,
      -0.051930490881204605,
      0.1907649040222168,
      -0.07993356883525848,
      0.044799383729696274,
      -0.013525182381272316,
      -0.01763237453997135,
      -0.09034772217273712,
      6.771631160518155e-05,
      0.04415004327893257,
      -0.08504115790128708,
      -0.05844789743423462,
      -0.030775023624300957,
      -0.1272510290145874
    ],
    [
      -0.013269806280732155,
      0.025766143575310707,
      0.05038191378116608,
      0.08210909366607666,
      -0.009838221594691277,
      -0.06485356390476227,
      -0.09079527109861374,
      0.03425660356879234,
      0.04557128995656967,
      0.03515482321381569,
      -0.0023350385017693043,
      0.04931463301181793,
      0.03331708908081055,
      -0.028943916782736778,
      -0.09794927388429642,
      0.1299293339252472,
      0.012123903259634972
    ],
    [
      -0.09218898415565491,
      0.07320104539394379,
      -0.03876364231109619,
      0.07983627170324326,
      -0.008648240938782692,
      -0.0996098443865776,
      -0.03432120010256767,
      0.05689606815576553,
      0.02812480367720127,
      0.02652272768318653,
      0.006067721173167229,
      0.052806876599788666,
      0.04243463650345802,
      -0.05238175392150879,
      -0.028464633971452713,
      -0.21161487698554993,
      -0.01918715424835682
    ],
    [
      0.01820395514369011,
      -0.04163076728582382,
      0.011946482583880424,
      -0.149222269654274,
      -0.03830448538064957,
      0.006601420696824789,
      -0.07004205137491226,
      0.20864467322826385,
      0.044627729803323746,
      -0.016339916735887527,
      0.0594913512468338,
      0.026319358497858047,
      0.024899931624531746,
      -0.1371147334575653,
      -0.01528303511440754,
      0.015597866848111153,
      0.009386716410517693
    ],
    [
      0.03912246227264404,
      -0.004408582579344511,
      -0.07544317841529846,
      -0.06053602695465088,
      -0.14316222071647644,
      0.06450630724430084,
      0.06442596018314362,
      -0.006583329755812883,
      -0.12380985915660858,
      -0.014282871037721634,
      -0.05618240684270859,
      -0.012687324546277523,
      0.06233435869216919,
      -0.005927215795964003,
      0.05682424455881119,
      -0.07847914844751358,
      0.03901843726634979
    ],
    [
      0.09223657101392746,
      -0.0669298842549324,
      -0.10292753577232361,
      0.0854005515575409,
      0.03447512164711952,
      0.00401093065738678,
      0.16385802626609802,
      0.0612838938832283,
      0.0475032664835453,
      -0.075734943151474,
      -0.046946581453084946,
      0.06224936246871948,
      -0.1262354999780655,
      -0.054861411452293396,
      0.037718191742897034,
      0.04390848055481911,
      -0.055363669991493225
    ],
    [
      0.07621828466653824,
      -0.18363192677497864,
      0.019081735983490944,
      0.10462705045938492,
      0.05642124265432358,
      -0.07545876502990723,
      -0.04606590420007706,
      0.03817778825759888,
      -0.13203199207782745,
      -0.06727142632007599,
      -0.05461106449365616,
      0.2409438192844391,
      -0.05270852521061897,
      0.04808114096522331,
      -0.17880074679851532,
      0.09669068455696106,
      0.07849863171577454
    ],
    [
      -0.10711076855659485,
      -0.08166123181581497,
      0.045590318739414215,
      -0.10621574521064758,
      0.0702466368675232,
      0.009564236737787724,
      -0.20286394655704498,
      0.10754815489053726,
      0.012234928086400032,
      0.013260920532047749,
      0.05714769661426544,
      0.09817760437726974,
      -0.044839244335889816,
      0.082094706594944,
      0.08288981020450592,
      -0.03998662158846855,
      -0.135385662317276
    ],
    [
      0.17031021416187286,
      -0.06355281174182892,
      0.12274066358804703,
      -0.19271798431873322,
      -0.0813499316573143,
      -0.05554498732089996,
      0.21141637861728668,
      -0.022655127570033073,
      -0.0895271822810173,
      0.17509517073631287,
      -0.032762911170721054,
      0.002507016295567155,
      0.021481424570083618,
      -0.05206303298473358,
      0.032228756695985794,
      -0.14189065992832184,
      -0.14500083029270172
    ],
    [
      0.1561218649148941,
      -0.16621391475200653,
      0.14568369090557098,
      0.06535238027572632,
      -0.05073758214712143,
      -0.13279567658901215,
      0.06253086030483246,
      -0.06015106663107872,
      -0.06586446613073349,
      0.08822982013225555,
      0.0388869009912014,
      0.19044242799282074,
      -0.047259461134672165,
      -0.05456986278295517,
      -0.1423148661851883,
      -0.1958308219909668,
      0.046225305646657944
    ],
    [
      -0.030845023691654205,
      0.22053982317447662,
      0.030959226191043854,
      0.22032780945301056,
      0.16236832737922668,
      0.09739986062049866,
      -0.03710310161113739,
      0.002375617390498519,
      0.07220417261123657,
      0.0070619299076497555,
      0.03363515064120293,
      0.07635363191366196,
      -0.008497903123497963,
      -0.1411135047674179,
      -0.24089670181274414,
      0.012765839695930481,
      0.008423743769526482
    ],
    [
      -0.03570007532835007,
      0.029054837301373482,
      -0.04594355821609497,
      -0.026384010910987854,
      -0.051054906100034714,
      0.02465592324733734,
      -0.008266518823802471,
      0.026217827573418617,
      -0.12582416832447052,
      -0.063869908452034,
      0.0012776830699294806,
      -0.003321972442790866,
      -0.04625727981328964,
      0.019120482727885246,
      -0.050181321799755096,
      0.07379517704248428,
      0.038828328251838684
    ],
    [
      0.151715487241745,
      0.19842925667762756,
      0.05812455713748932,
      -0.13783715665340424,
      -0.015012037940323353,
      -0.09856604784727097,
      0.09045808017253876,
      0.18187320232391357,
      0.10712175816297531,
      0.01714494824409485,
      -0.26640480756759644,
      -0.021025553345680237,
      0.010540780611336231,
      0.025816811248660088,
      -0.0164717398583889,
      -0.1030261442065239,
      -0.08776502311229706
    ],
    [
      -0.05543103441596031,
      -0.11586599797010422,
      -0.018492139875888824,
      0.005208821967244148,
      0.04655012860894203,
      -0.008296443149447441,
      -0.11655093729496002,
      0.04319115728139877,
      0.10674411058425903,
      0.021631212905049324,
      0.014270942658185959,
      -0.024860868230462074,
      -0.01377642247825861,
      -0.02062414214015007,
      0.010407078079879284,
      0.07890112698078156,
      -0.007202365901321173
    ],
    [
      0.030002901330590248,
      0.06279216706752777,
      0.12333808094263077,
      0.0356932207942009,
      -0.03528623655438423,
      0.05482792109251022,
      0.048154428601264954,
      0.0766775906085968,
      0.05717203766107559,
      0.0384538508951664,
      0.040308497846126556,
      -0.029762139543890953,
      0.08211108297109604,
      -0.016797835007309914,
      -0.06307162344455719,
      -0.04005550965666771,
      -0.0433473214507103
    ],
    [
      -0.38491290807724,
      0.057774998247623444,
      0.062209270894527435,
      -0.1197473481297493,
      -0.10462271422147751,
      -0.06118185445666313,
      -0.16530629992485046,
      -0.15616409480571747,
      0.15935219824314117,
      0.1630917340517044,
      0.06622414290904999,
      0.19141888618469238,
      0.18274730443954468,
      0.23941880464553833,
      0.1531902402639389,
      -0.27237197756767273,
      -0.1596042960882187
    ],
    [
      -0.09446784108877182,
      -0.0849388986825943,
      0.02748420648276806,
      -0.20113109052181244,
      -0.004911927971988916,
      0.18249787390232086,
      0.00913125742226839,
      -0.07649847865104675,
      -0.15808677673339844,
      0.21293982863426208,
      0.13072875142097473,
      0.16301505267620087,
      -0.0004162447003182024,
      0.09801733493804932,
      0.036343928426504135,
      -0.0854765996336937,
      -0.01457478292286396
    ],
    [
      -0.016549352556467056,
      0.11266925930976868,
      -0.0938296988606453,
      -0.024129748344421387,
      0.036786988377571106,
      0.14831820130348206,
      0.13261505961418152,
      -0.16280478239059448,
      0.21473079919815063,
      -0.0797092542052269,
      -0.016931995749473572,
      -0.20028850436210632,
      -0.0030645381193608046,
      -0.17852844297885895,
      0.14477376639842987,
      0.07939088344573975,
      0.006217692978680134
    ],
    [
      0.0672449842095375,
      -0.0402674674987793,
      0.016093363985419273,
      -0.06658481061458588,
      -0.04311134293675423,
      -0.0539209246635437,
      0.08863791078329086,
      0.04266795888543129,
      0.08640608936548233,
      -0.06337810307741165,
      -0.04348659887909889,
      -0.09258449077606201,
      -0.014371921308338642,
      0.10842204838991165,
      0.015463580377399921,
      0.0002542097354307771,
      0.06771565973758698
    ],
    [
      -0.10967468470335007,
      -0.019218729808926582,
      0.13755245506763458,
      -0.19675609469413757,
      0.07031363248825073,
      -0.1850486695766449,
      0.04537205025553703,
      -0.07520826160907745,
      -0.2029271274805069,
      0.09269209206104279,
      0.06574881821870804,
      -0.15816856920719147,
      0.12413469702005386,
      0.017218634486198425,
      -0.029752405360341072,
      -0.14765775203704834,
      0.0926799476146698
    ],
    [
      -0.07675087451934814,
      -0.10639510303735733,
      0.12444336712360382,
      0.044774509966373444,
      -0.050730202347040176,
      -0.06450624763965607,
      -0.13477714359760284,
      0.03765939176082611,
      -0.015853960067033768,
      0.05280870199203491,
      0.056116875261068344,
      0.015292031690478325,
      -0.11343977600336075,
      0.03863655403256416,
      -0.021265964955091476,
      0.014196899719536304,
      -0.08468768000602722
    ],
    [
      -0.24063637852668762,
      -0.20863941311836243,
      0.16816474497318268,
      0.11284296214580536,
      0.13310590386390686,
      -0.09144610911607742,
      -0.048485688865184784,
      -0.12633116543293,
      0.17481425404548645,
      0.07117213308811188,
      0.03956213966012001,
      0.07919986546039581,
      0.15837588906288147,
      -0.046287793666124344,
      0.044148512184619904,
      -0.18053101003170013,
      0.010586075484752655
    ],
    [
      -0.12459253519773483,
      -0.01712319254875183,
      0.07805883139371872,
      -0.18425023555755615,
      0.024070756509900093,
      0.04559864103794098,
      -0.08774000406265259,
      0.11217177659273148,
      0.008493421599268913,
      0.07271780073642731,
      0.039339520037174225,
      0.10214196145534515,
      -0.1252632439136505,
      0.04159135743975639,
      -0.06758985668420792,
      -0.0532105416059494,
      -0.04800140857696533
    ],
    [
      -0.01932452619075775,
      -0.1859724074602127,
      -0.06093353033065796,
      0.15864703059196472,
      0.10171322524547577,
      -0.25520187616348267,
      -0.05428975448012352,
      -0.38655275106430054,
      0.17963245511054993,
      -0.015019800513982773,
      0.018138160929083824,
      0.01136030349880457,
      0.1287657469511032,
      0.14613419771194458,
      0.02915213257074356,
      0.11419005692005157,
      0.1573711633682251
    ],
    [
      -0.009472989477217197,
      0.0965898260474205,
      0.09299477189779282,
      -0.03961098566651344,
      -0.006155181676149368,
      -0.015366866253316402,
      -0.005252466071397066,
      0.00619441457092762,
      0.03843182325363159,
      -0.06592772156000137,
      0.016692476347088814,
      -0.03801821917295456,
      -0.08157671988010406,
      -0.11820456385612488,
      -0.06461885571479797,
      -0.09578687697649002,
      -0.005967489909380674
    ],
    [
      -0.05430913344025612,
      -0.15115080773830414,
      0.05888845771551132,
      0.05829550698399544,
      0.0733305811882019,
      0.016219278797507286,
      -0.1077091321349144,
      0.16693346202373505,
      0.017505357041954994,
      0.07759776711463928,
      0.10351642221212387,
      -0.10548388957977295,
      0.06890866905450821,
      -0.008152185007929802,
      0.19108594954013824,
      -0.0267610065639019,
      0.027029605582356453
    ],
    [
      0.0040273405611515045,
      0.018947523087263107,
      -0.1053057312965393,
      0.010783223435282707,
      0.0007685920572839677,
      -0.09130434691905975,
      0.04739611595869064,
      0.0065041459165513515,
      0.1138877421617508,
      -0.014816085807979107,
      0.06692343205213547,
      0.03714139014482498,
      -0.09290091693401337,
      -0.00032329626264981925,
      0.08199866116046906,
      0.17540310323238373,
      -0.006404376123100519
    ],
    [
      0.1919451504945755,
      -0.06464122980833054,
      0.036125022917985916,
      -0.08524638414382935,
      -0.044833023101091385,
      0.07214060425758362,
      0.12253370136022568,
      -0.1933375895023346,
      0.10410204529762268,
      -0.04192239046096802,
      0.04543134942650795,
      0.23044058680534363,
      -0.04525388777256012,
      -0.053633201867341995,
      0.006996749434620142,
      0.02603471651673317,
      0.0012980985920876265
    ],
    [
      0.08196669816970825,
      -0.24598145484924316,
      0.007467311806976795,
      0.0010955461766570807,
      0.07280255854129791,
      -0.02082601748406887,
      -0.08415749669075012,
      0.10936927050352097,
      -0.17077773809432983,
      0.0014481405960395932,
      -0.1389092057943344,
      0.15929360687732697,
      -0.10778071731328964,
      0.01662362925708294,
      -0.1497126966714859,
      0.10593767464160919,
      0.005305966828018427
    ],
    [
      -0.0160091370344162,
      0.20826765894889832,
      -0.21325688064098358,
      0.06052837520837784,
      0.01483133900910616,
      0.04317060112953186,
      0.04157708212733269,
      0.1619999259710312,
      0.16637703776359558,
      -0.1723995804786682,
      0.10889136791229248,
      -0.17291316390037537,
      0.04649146646261215,
      -0.09977000951766968,
      0.04482056200504303,
      0.16580745577812195,
      0.009473096579313278
    ],
    [
      -0.022154884412884712,
      0.15039744973182678,
      -0.06624449044466019,
      -0.14556793868541718,
      -0.060998428612947464,
      0.043360110372304916,
      0.14642271399497986,
      0.002128788037225604,
      0.021966248750686646,
      0.02506687492132187,
      0.015646034851670265,
      -0.0014058726374059916,
      0.030822884291410446,
      0.012417702935636044,
      0.014665954746305943,
      -0.012211738154292107,
      0.03596924990415573
    ],
    [
      0.04649234935641289,
      -0.34337282180786133,
      0.11163472384214401,
      0.013261083513498306,
      0.05165277421474457,
      0.06373149156570435,
      0.017707644030451775,
      -0.18461933732032776,
      -0.09448935091495514,
      0.10340695083141327,
      -0.1878168284893036,
      -0.23087827861309052,
      -0.04210885986685753,
      -0.06953797489404678,
      0.1359306424856186,
      0.26877784729003906,
      0.11669563502073288
    ],
    [
      -0.1436656415462494,
      -0.15311512351036072,
      -0.1365925669670105,
      -0.260713130235672,
      -0.0861293226480484,
      0.023152125999331474,
      -0.11865556240081787,
      0.23611925542354584,
      0.16494370996952057,
      -0.047454431653022766,
      -0.1226002648472786,
      -0.11567876487970352,
      0.11340311169624329,
      0.24830593168735504,
      0.1976185292005539,
      -0.024758120998740196,
      0.09766393899917603
    ],
    [
      -0.032885611057281494,
      0.20452193915843964,
      -0.11117672920227051,
      -0.037636857479810715,
      -0.01535989623516798,
      0.010903118178248405,
      -0.047165241092443466,
      0.1310674101114273,
      0.19426976144313812,
      -0.017335733398795128,
      0.08946741372346878,
      0.0890159085392952,
      0.08296391367912292,
      -0.10848861932754517,
      0.028623472899198532,
      -0.3973788917064667,
      -0.13075612485408783
    ],
    [
      -0.13587063550949097,
      0.24595578014850616,
      -0.08153650909662247,
      -0.08406613767147064,
      -0.0493597686290741,
      0.02029448188841343,
      0.09827926754951477,
      -0.05722705274820328,
      0.09210488200187683,
      -0.10279504954814911,
      -0.13789045810699463,
      -0.18595923483371735,
      0.016072891652584076,
      -0.1588750183582306,
      0.06486240774393082,
      0.01909562200307846,
      0.09202427417039871
    ],
    [
      -0.04848335310816765,
      -0.04520007222890854,
      0.05672786384820938,
      -0.0703984946012497,
      0.09928592294454575,
      0.0386204719543457,
      -0.08060278743505478,
      0.0037733225617557764,
      -0.007653262000530958,
      -0.02953377738595009,
      -0.041431963443756104,
      -0.034126702696084976,
      0.016612036153674126,
      0.006735692266374826,
      -0.1319749802350998,
      0.03446628153324127,
      -0.06004147231578827
    ],
    [
      -0.045353323221206665,
      -0.08901304006576538,
      0.05791750177741051,
      0.03977512940764427,
      0.01480147521942854,
      0.010131538845598698,
      -0.09093095362186432,
      -0.041145000606775284,
      0.0017347484827041626,
      0.11723720282316208,
      0.06835012137889862,
      0.11548469215631485,
      0.1125480979681015,
      0.058438342064619064,
      0.09774355590343475,
      -0.031154967844486237,
      -0.03739505633711815
    ],
    [
      0.06547006964683533,
      -0.30956873297691345,
      -0.14309760928153992,
      0.10571088641881943,
      0.023602645844221115,
      -0.07523990422487259,
      0.1320057511329651,
      -0.04997526854276657,
      -0.13491974771022797,
      -0.07052730768918991,
      0.014609330333769321,
      0.22783364355564117,
      -0.21615323424339294,
      -0.11676983535289764,
      -0.06917198747396469,
      0.0762251764535904,
      -0.03581688553094864
    ],
    [
      -0.04348577558994293,
      0.32095232605934143,
      -0.25373849272727966,
      0.28883635997772217,
      0.21260623633861542,
      0.0766482949256897,
      -0.06836990267038345,
      -0.12861040234565735,
      0.15526150166988373,
      -0.1343013495206833,
      0.0967443510890007,
      -0.09669634699821472,
      0.0027305809780955315,
      -0.18419352173805237,
      0.020026415586471558,
      0.1431656777858734,
      -0.11009450256824493
    ],
    [
      -0.20473194122314453,
      -0.05309515446424484,
      0.2484769970178604,
      -0.10798624902963638,
      0.014166966080665588,
      -0.04242883622646332,
      0.14668963849544525,
      -0.24588966369628906,
      0.08122511208057404,
      0.1488322913646698,
      -0.011418245732784271,
      -0.17838126420974731,
      -0.03880247473716736,
      0.1547580063343048,
      0.18718686699867249,
      0.04382573068141937,
      0.045238301157951355
    ],
    [
      0.03873912990093231,
      0.048009712249040604,
      0.006304131355136633,
      0.07846447080373764,
      -0.025344327092170715,
      0.020565275102853775,
      0.13721558451652527,
      -0.04897855967283249,
      -0.023175975307822227,
      0.0020295269787311554,
      -0.0060450113378465176,
      -0.04536738991737366,
      0.04913901165127754,
      -0.02496490068733692,
      -0.047334831207990646,
      -0.010622159577906132,
      0.07434682548046112
    ],
    [
      0.04178563132882118,
      0.004342379979789257,
      0.046178050339221954,
      0.033266179263591766,
      0.01982741616666317,
      -0.046246662735939026,
      -0.034916605800390244,
      -0.03623552620410919,
      -0.04471511021256447,
      0.060973625630140305,
      -0.03771503269672394,
      -0.04169270023703575,
      0.034600090235471725,
      0.07816369086503983,
      -0.040305525064468384,
      0.025775346904993057,
      -0.04679366946220398
    ],
    [
      0.05225296691060066,
      0.07502572238445282,
      0.030121279880404472,
      0.006175620015710592,
      0.0028295081574469805,
      0.06347529590129852,
      0.08840221166610718,
      -0.061650749295949936,
      -0.0008577575208619237,
      0.05243001878261566,
      -0.03317297250032425,
      0.03316478431224823,
      0.08824923634529114,
      -0.07149805873632431,
      0.050393246114254,
      -0.011083027347922325,
      -0.06121896579861641
    ],
    [
      -0.061882637441158295,
      -0.05294523388147354,
      -0.08198592066764832,
      0.012730907648801804,
      0.0059907580725848675,
      0.014349417760968208,
      -0.01061040349304676,
      -0.05235939472913742,
      0.14516890048980713,
      -0.133530393242836,
      -0.0679747462272644,
      -0.10019583255052567,
      0.13605235517024994,
      -0.056823909282684326,
      -0.12770912051200867,
      0.008772864937782288,
      -0.04634200036525726
    ],
    [
      -0.00645402679219842,
      0.00355349900200963,
      0.047933150082826614,
      0.05556495115160942,
      -0.0987996906042099,
      0.041872039437294006,
      -0.012348891235888004,
      0.04851461201906204,
      -0.05362579599022865,
      0.0520109198987484,
      0.07331269979476929,
      -0.02834314852952957,
      -0.032720521092414856,
      0.023786935955286026,
      0.06552906334400177,
      0.01368325762450695,
      -0.03986263647675514
    ],
    [
      0.046190325170755386,
      -0.05872005969285965,
      -0.005935588851571083,
      -0.023626811802387238,
      -0.05027535930275917,
      -0.02243090234696865,
      0.03385605663061142,
      -0.06120447441935539,
      0.05730527639389038,
      0.012386338785290718,
      0.024514684453606606,
      -0.07239537686109543,
      0.06613245606422424,
      -0.08550698310136795,
      0.055046625435352325,
      -0.06544739753007889,
      -0.018894478678703308
    ],
    [
      -0.12777143716812134,
      -0.2386089414358139,
      -0.1301487237215042,
      -0.0063164387829601765,
      0.07716495543718338,
      0.024186592549085617,
      -0.12133914977312088,
      0.022277822718024254,
      0.16145166754722595,
      -0.059611544013023376,
      0.24445880949497223,
      -0.07925628870725632,
      0.08192577213048935,
      0.0839528813958168,
      0.04573703557252884,
      -0.011416162364184856,
      0.04739759489893913
    ],
    [
      -0.057534463703632355,
      0.09234064072370529,
      -0.07711891084909439,
      -0.012521783821284771,
      -0.012032754719257355,
      -0.034726012498140335,
      0.16839699447155,
      0.015437863767147064,
      0.17387858033180237,
      -0.09853067994117737,
      0.015634678304195404,
      0.010928480885922909,
      -0.012354902923107147,
      0.02432831935584545,
      0.017407158389687538,
      -0.06614689528942108,
      -0.11237882077693939
    ],
    [
      0.01843332313001156,
      -0.024824632331728935,
      -0.0524127297103405,
      -0.0002644164487719536,
      0.12019482254981995,
      -0.06568927317857742,
      0.0866512879729271,
      0.016708673909306526,
      0.07952678203582764,
      -0.014161861501634121,
      -0.07529255002737045,
      -0.0062095350585877895,
      0.03702716529369354,
      0.038950756192207336,
      0.07393115758895874,
      -0.014777986332774162,
      -0.034871142357587814
    ],
    [
      -0.17535735666751862,
      0.05446337163448334,
      -0.4020253121852875,
      -0.1624637097120285,
      0.018231654539704323,
      0.044255826622247696,
      0.00808697659522295,
      0.06191658228635788,
      0.474035382270813,
      -0.2513215243816376,
      0.06481758505105972,
      -0.1377500295639038,
      0.18104255199432373,
      0.03373244032263756,
      0.23525941371917725,
      -0.09808523207902908,
      0.09622835367918015
    ],
    [
      0.08961399644613266,
      0.01412162184715271,
      0.01408538781106472,
      -0.07134553045034409,
      0.047346800565719604,
      0.10506407916545868,
      0.011594428680837154,
      0.11886497586965561,
      0.020895132794976234,
      -0.061895813792943954,
      0.0726735070347786,
      -0.015508283860981464,
      -0.06724931299686432,
      -0.046537186950445175,
      -0.10857696831226349,
      -0.08236858993768692,
      -0.12814602255821228
    ],
    [
      -0.14165517687797546,
      0.041003379970788956,
      0.15218126773834229,
      -0.015649717301130295,
      0.09806166589260101,
      -0.0019803286995738745,
      0.059536468237638474,
      -0.1607314944267273,
      0.20004774630069733,
      -0.17564257979393005,
      -0.0037543680518865585,
      -0.16828161478042603,
      -0.0677967295050621,
      -0.10633335262537003,
      0.13339458405971527,
      -0.052736930549144745,
      -0.059672314673662186
    ],
    [
      0.08999480307102203,
      0.1678411066532135,
      -0.0724971815943718,
      -0.2310577630996704,
      -0.07766083627939224,
      -0.007341103162616491,
      0.048854656517505646,
      0.07801900804042816,
      -0.003000916913151741,
      -0.04988153651356697,
      -0.0682334452867508,
      -0.003737046616151929,
      0.023434000089764595,
      0.09147564321756363,
      -0.024466179311275482,
      -0.04850543290376663,
      -0.12803497910499573
    ],
    [
      0.005485048051923513,
      0.1016496941447258,
      0.270925372838974,
      0.004122305195778608,
      -0.17121411859989166,
      -0.11661650985479355,
      0.054146479815244675,
      -0.1866830736398697,
      -0.01596488244831562,
      0.13612918555736542,
      -0.004166020080447197,
      0.019721142947673798,
      -0.016533367335796356,
      0.150262251496315,
      0.1026444286108017,
      -0.00521196611225605,
      0.13079184293746948
    ],
    [
      -0.037248000502586365,
      -0.0008454960188828409,
      -0.01844659075140953,
      0.04866607487201691,
      -0.05851350352168083,
      -0.007194865960627794,
      0.13996224105358124,
      -0.07319905608892441,
      -0.06356888264417648,
      0.07841157913208008,
      -0.08556759357452393,
      -0.31504279375076294,
      0.006874463055282831,
      -0.21012639999389648,
      0.05842754989862442,
      0.008714643307030201,
      0.03433377668261528
    ],
    [
      0.07292356342077255,
      0.10110969841480255,
      -0.037127964198589325,
      0.018317172303795815,
      -0.12460441887378693,
      0.08292488753795624,
      0.08384157717227936,
      -0.12922604382038116,
      0.10477153956890106,
      0.04088258370757103,
      -0.02238108590245247,
      -0.15377306938171387,
      0.055856119841337204,
      -0.1556769758462906,
      0.17369981110095978,
      0.031220057979226112,
      -0.1591615080833435
    ],
    [
      -0.07699024677276611,
      0.1240239366889,
      -0.083888478577137,
      0.14002399146556854,
      0.11210467666387558,
      0.2464204728603363,
      0.1510806679725647,
      0.09167274087667465,
      0.17173658311367035,
      -0.21201476454734802,
      -0.13226419687271118,
      0.007208642549812794,
      0.03868624195456505,
      -0.01991376280784607,
      0.011476380750536919,
      -0.08746593445539474,
      -0.015573651529848576
    ],
    [
      0.12897183001041412,
      -0.26328161358833313,
      0.3260473310947418,
      0.12356064468622208,
      0.03378187492489815,
      0.20154348015785217,
      0.10737568885087967,
      -0.053245026618242264,
      -0.0019195489585399628,
      -0.06752929836511612,
      -0.20410865545272827,
      -0.06596538424491882,
      -0.07737302035093307,
      -0.043728068470954895,
      -0.013546541333198547,
      0.01132849883288145,
      0.011758251115679741
    ],
    [
      -0.26682713627815247,
      -0.15105153620243073,
      0.342928409576416,
      -0.1358596384525299,
      -0.008617754094302654,
      -0.11945647746324539,
      -0.05241309106349945,
      0.008526048623025417,
      0.071357361972332,
      0.12863735854625702,
      0.08456877619028091,
      0.05128178372979164,
      0.013607315719127655,
      0.13481348752975464,
      -0.11425846815109253,
      -0.05616391450166702,
      0.01064481120556593
    ],
    [
      -0.15488827228546143,
      -0.14913928508758545,
      0.12579384446144104,
      -0.047441788017749786,
      -0.0720410943031311,
      0.1353265345096588,
      -0.23421253263950348,
      -0.051957521587610245,
      -0.11217685788869858,
      0.12330172210931778,
      0.03293156623840332,
      -0.0859958752989769,
      0.05070417374372482,
      0.04496558755636215,
      0.1437552571296692,
      0.09470266848802567,
      0.11029951274394989
    ],
    [
      0.12088474631309509,
      0.06398892402648926,
      -0.21128416061401367,
      0.026620719581842422,
      -0.055214717984199524,
      -0.07711021602153778,
      -0.18765155971050262,
      0.09252382814884186,
      -0.11538376659154892,
      -0.06249094381928444,
      0.06223922595381737,
      -0.20907779037952423,
      0.13134527206420898,
      0.01342383399605751,
      -0.0891730934381485,
      0.09719561785459518,
      -0.052732598036527634
    ],
    [
      0.14538879692554474,
      -0.018273374065756798,
      0.020076654851436615,
      -0.10898978263139725,
      0.06156742200255394,
      0.04046649858355522,
      0.005944998003542423,
      -0.0864252820611,
      -0.11868114769458771,
      0.03293365240097046,
      -0.03187740594148636,
      -0.023557493463158607,
      0.08563050627708435,
      -0.018110521137714386,
      0.00528129143640399,
      -0.038425326347351074,
      0.027891626581549644
    ],
    [
      -0.004492129199206829,
      0.01850883476436138,
      0.041478268802165985,
      0.09339866042137146,
      -0.14953340590000153,
      0.03469955548644066,
      0.03509834408760071,
      -0.00836892519146204,
      -0.08226844668388367,
      0.11894441395998001,
      0.026364747434854507,
      0.04306689277291298,
      0.07961691170930862,
      -0.03306221961975098,
      0.008807442151010036,
      -0.07537628710269928,
      -0.017188575118780136
    ],
    [
      0.16893352568149567,
      -0.10823677480220795,
      0.04319804161787033,
      -0.1233140379190445,
      -0.06973633915185928,
      0.014124714769423008,
      -0.05875209718942642,
      0.10610160231590271,
      -0.05039636790752411,
      -0.04773279279470444,
      -0.08932860195636749,
      0.15276925265789032,
      0.032647013664245605,
      0.16317196190357208,
      0.01526305079460144,
      -0.3017599582672119,
      -0.13161461055278778
    ],
    [
      -0.005560738500207663,
      0.032247163355350494,
      -0.0824437141418457,
      -0.11609900742769241,
      0.11576817184686661,
      0.09611344337463379,
      0.1070144772529602,
      0.03551875054836273,
      -0.042749177664518356,
      -0.09921238571405411,
      -0.06340238451957703,
      0.05077865719795227,
      -0.006566129624843597,
      0.205012246966362,
      0.05434533581137657,
      -0.07304755598306656,
      0.05224727466702461
    ],
    [
      0.061180997639894485,
      -0.111368827521801,
      -0.01075147558003664,
      -0.007574648130685091,
      0.058965589851140976,
      -0.031085163354873657,
      0.15483736991882324,
      0.0016574839828535914,
      0.03927008435130119,
      -0.05073445662856102,
      0.02125915326178074,
      -0.04412152245640755,
      -0.0637626126408577,
      -0.08010027557611465,
      0.008000610396265984,
      -0.02899624966084957,
      -0.07522643357515335
    ],
    [
      0.06232116371393204,
      -0.03236500918865204,
      -0.08216363191604614,
      0.04094406217336655,
      -0.027172109112143517,
      -0.07264532893896103,
      0.14448943734169006,
      -0.08930801600217819,
      -0.1945098489522934,
      -0.004901506472378969,
      0.009759767912328243,
      -0.10399697721004486,
      -0.09044983983039856,
      0.03389459848403931,
      -0.11020862311124802,
      -0.048521846532821655,
      -0.09637405723333359
    ],
    [
      0.09138451516628265,
      -0.02355189062654972,
      -0.0300117377191782,
      0.06129305809736252,
      -0.008358987048268318,
      0.07940612733364105,
      -0.02674904279410839,
      -0.07805478572845459,
      -0.04762904345989227,
      0.09837983548641205,
      0.009139579720795155,
      -0.0033260842319577932,
      -0.06683895736932755,
      -0.03170670568943024,
      0.003503224113956094,
      0.059578172862529755,
      0.014075543731451035
    ],
    [
      -0.013218141160905361,
      0.11435458064079285,
      0.07301785051822662,
      0.30266568064689636,
      -0.009192869998514652,
      -0.12566262483596802,
      0.10321234911680222,
      0.006736174691468477,
      0.022361814975738525,
      0.10139236599206924,
      -0.167349711060524,
      -0.19220979511737823,
      0.05837055295705795,
      -0.053571660071611404,
      0.14711801707744598,
      -0.037785716354846954,
      0.1271643042564392
    ],
    [
      0.032250188291072845,
      0.07664148509502411,
      -0.0028068521060049534,
      0.06198852136731148,
      0.042152173817157745,
      0.027146128937602043,
      0.182474285364151,
      -0.04269872233271599,
      -0.0007413031416945159,
      0.029522111639380455,
      -0.0901598408818245,
      0.06458579748868942,
      -0.03589440509676933,
      -0.07507915794849396,
      -0.06086739897727966,
      0.1200832948088646,
      -0.01207414548844099
    ],
    [
      -0.063092902302742,
      0.06114010512828827,
      0.05340678244829178,
      -0.19656752049922943,
      0.0235727671533823,
      -0.2215646207332611,
      -0.20729005336761475,
      0.09245333075523376,
      0.0024932282976806164,
      0.12361495941877365,
      -0.023776212707161903,
      -0.1528310328722,
      0.10466481745243073,
      -0.056750427931547165,
      -0.0758337453007698,
      0.08848603069782257,
      -0.016857024282217026
    ],
    [
      -0.05155613645911217,
      -0.046767108142375946,
      0.23255302011966705,
      -0.20572501420974731,
      0.09230346977710724,
      -0.1424960494041443,
      -0.0020402874797582626,
      -0.13196857273578644,
      -0.03704587742686272,
      0.17480118572711945,
      -0.06650668382644653,
      -0.08164453506469727,
      0.07954507321119308,
      -0.11634610593318939,
      0.10937008261680603,
      0.0037305511068552732,
      0.11045254021883011
    ],
    [
      0.027243591845035553,
      0.0321313850581646,
      0.09690568596124649,
      -0.2428441345691681,
      -0.006494477856904268,
      -0.07756244391202927,
      0.1519729346036911,
      0.13565199077129364,
      0.21461597084999084,
      -0.16210341453552246,
      8.546235039830208e-05,
      0.08927153795957565,
      -0.1826244294643402,
      0.1271408051252365,
      0.14298810064792633,
      -0.2524978220462799,
      0.16935865581035614
    ],
    [
      0.0002943930448964238,
      0.024241052567958832,
      0.19040238857269287,
      -0.10403140634298325,
      -0.11881030350923538,
      -0.158549964427948,
      0.03540515527129173,
      0.3476436734199524,
      0.0026730450335890055,
      -0.06107442453503609,
      -0.2683790624141693,
      -0.11246629059314728,
      -0.031265780329704285,
      0.16498009860515594,
      -0.06744595617055893,
      -0.1369875818490982,
      0.07233486324548721
    ],
    [
      -0.05968901887536049,
      0.026172958314418793,
      0.021963171660900116,
      0.03988434001803398,
      0.049761973321437836,
      0.003432181663811207,
      -0.06098119914531708,
      0.007309061475098133,
      0.045831870287656784,
      0.05947542563080788,
      -0.10716904699802399,
      0.028325293213129044,
      0.011901653371751308,
      -0.012182535603642464,
      0.01512807235121727,
      -0.011159748770296574,
      -0.07842325419187546
    ],
    [
      0.17778849601745605,
      -0.305825799703598,
      -0.07685550302267075,
      -0.15480047464370728,
      0.005165359936654568,
      0.04086410999298096,
      -0.01871061697602272,
      0.061436597257852554,
      -0.19528481364250183,
      0.1327132284641266,
      0.11950002610683441,
      0.11465710401535034,
      -0.028525924310088158,
      0.07759591937065125,
      0.11043998599052429,
      -0.011912846006453037,
      -0.010878561064600945
    ],
    [
      0.1091962531208992,
      0.049802348017692566,
      -0.010931932367384434,
      -0.03733581304550171,
      7.41474941605702e-05,
      -0.0006216464098542929,
      0.09903787076473236,
      -0.10924923419952393,
      0.03089742921292782,
      -0.07172804325819016,
      -0.0863489955663681,
      -0.038190361112356186,
      0.06114839017391205,
      -0.08037072420120239,
      -0.04908199980854988,
      -0.04831285774707794,
      0.06865202635526657
    ],
    [
      0.21058471500873566,
      -0.005725506227463484,
      -0.06801654398441315,
      0.18949788808822632,
      0.10860402882099152,
      0.10351027548313141,
      -0.12701982259750366,
      -0.0001119610751629807,
      -0.07099036127328873,
      -0.02648758329451084,
      0.011334842070937157,
      -0.1746668666601181,
      0.14309227466583252,
      -0.18651114404201508,
      0.007650755811482668,
      0.15510819852352142,
      -0.1458413004875183
    ],
    [
      -0.028103267773985863,
      -0.022673225030303,
      -0.07589945942163467,
      0.09590433537960052,
      -0.03543486073613167,
      0.04195675998926163,
      -0.16676731407642365,
      -0.06540774554014206,
      -0.14194104075431824,
      0.01475825160741806,
      0.028551960363984108,
      0.059765301644802094,
      0.07090893387794495,
      -0.046209320425987244,
      -0.03255311772227287,
      0.07108362019062042,
      0.09882423281669617
    ],
    [
      0.020469915121793747,
      -0.041056107729673386,
      0.029862364754080772,
      -0.036929115653038025,
      -0.00676256138831377,
      -0.017240213230252266,
      0.017255766317248344,
      -0.14786916971206665,
      -0.04935869202017784,
      0.05942017212510109,
      0.015102162957191467,
      -0.04121740907430649,
      -0.038566138595342636,
      0.01364799402654171,
      0.005051900167018175,
      -0.035337429493665695,
      -0.15127286314964294
    ],
    [
      0.03128368780016899,
      0.010308343917131424,
      0.03886861354112625,
      0.07736535370349884,
      0.17390477657318115,
      -0.1464725136756897,
      0.21924103796482086,
      0.16359147429466248,
      0.10976467281579971,
      -0.11348308622837067,
      -0.14194314181804657,
      -0.1566496640443802,
      -0.12325295805931091,
      0.001895326655358076,
      0.031390074640512466,
      -0.1964443027973175,
      0.1296035498380661
    ],
    [
      -0.12304451316595078,
      0.015065345913171768,
      -0.03792113438248634,
      -0.03202483430504799,
      -0.040218863636255264,
      0.17742092907428741,
      -0.20763646066188812,
      0.02128598652780056,
      -0.10504541546106339,
      0.08070997148752213,
      0.026248615235090256,
      0.020214160904288292,
      -0.012418466620147228,
      0.13005399703979492,
      -0.03774922713637352,
      0.05753004923462868,
      -0.08705497533082962
    ],
    [
      0.13066048920154572,
      -0.14040476083755493,
      -0.11357542127370834,
      0.004403462167829275,
      0.06220569461584091,
      0.18198569118976593,
      -0.03297071531414986,
      0.11799349635839462,
      -0.17517049610614777,
      0.02042897418141365,
      0.11541721969842911,
      0.1503467857837677,
      0.016693569719791412,
      0.014859745278954506,
      -0.04359095171093941,
      -0.10824275016784668,
      -0.02313067391514778
    ],
    [
      0.06566494703292847,
      0.08043528348207474,
      0.06349755823612213,
      -0.1500454992055893,
      0.05817791447043419,
      -0.11628987640142441,
      0.159391388297081,
      0.1401209831237793,
      -0.11474548280239105,
      0.1443546712398529,
      0.03253798186779022,
      0.07962504774332047,
      0.09831444919109344,
      0.10437896102666855,
      0.18684040009975433,
      -0.04434515908360481,
      -0.13575507700443268
    ],
    [
      0.17323650419712067,
      -0.010753761045634747,
      -0.00931824091821909,
      0.004939052276313305,
      -0.03950558230280876,
      -0.029469596222043037,
      0.03903872147202492,
      -0.024134326726198196,
      0.052388329058885574,
      0.10333875566720963,
      0.11106409132480621,
      0.05708913505077362,
      0.028003936633467674,
      -0.0026537373196333647,
      0.09868638217449188,
      0.0045528290793299675,
      0.06273429840803146
    ],
    [
      0.1072843000292778,
      0.09688518941402435,
      -0.010220262221992016,
      -0.09112440049648285,
      -0.039122212678194046,
      0.08662547171115875,
      0.1293107569217682,
      -0.1302989423274994,
      0.06066538393497467,
      0.14043308794498444,
      -0.12298204004764557,
      0.16879594326019287,
      -0.08170813322067261,
      0.21797166764736176,
      0.09323292225599289,
      -0.1173989325761795,
      -0.212998166680336
    ],
    [
      0.10336419939994812,
      -0.021718719974160194,
      -0.07763372361660004,
      -0.00579395517706871,
      0.05970562994480133,
      0.006233927793800831,
      0.1963340938091278,
      -0.07627952098846436,
      0.06010182574391365,
      -0.05374961718916893,
      -0.06139989569783211,
      0.055746566504240036,
      0.05823693051934242,
      0.04155856743454933,
      0.056246109306812286,
      -0.044666558504104614,
      -0.013993922621011734
    ],
    [
      -0.0040344540029764175,
      -0.07862222194671631,
      0.00043528713285923004,
      0.03988289088010788,
      0.006707550026476383,
      0.03288433328270912,
      -0.05230981856584549,
      0.19209615886211395,
      -0.05023270100355148,
      0.03319612145423889,
      0.004032348748296499,
      -0.03709213435649872,
      -0.024605125188827515,
      0.08370943367481232,
      0.12105648964643478,
      -0.09460680931806564,
      0.010558404959738255
    ],
    [
      -0.057832978665828705,
      0.046421319246292114,
      0.09084995836019516,
      -0.0936729833483696,
      -0.08418793231248856,
      0.07368916273117065,
      -0.030462294816970825,
      0.018676409497857094,
      0.05366648733615875,
      -0.11687664687633514,
      0.0022616498172283173,
      -0.048778925091028214,
      0.051121193915605545,
      0.10961193591356277,
      -0.013617487624287605,
      -0.10203063488006592,
      0.13130155205726624
    ],
    [
      -0.013622905127704144,
      0.2517533600330353,
      0.2522047162055969,
      0.05825342237949371,
      0.03463970869779587,
      -0.149093896150589,
      0.06361951678991318,
      -0.10182291269302368,
      0.10231491923332214,
      0.17961858212947845,
      -0.13963864743709564,
      -0.1531151831150055,
      -0.07900804281234741,
      -0.04626982659101486,
      0.01089728344231844,
      -0.008228586986660957,
      -0.10791295766830444
    ],
    [
      0.10031810402870178,
      0.023077163845300674,
      0.049265872687101364,
      -0.06783659756183624,
      -0.010753911919891834,
      0.052636679261922836,
      0.04476581886410713,
      0.042621366679668427,
      -0.0016294080996885896,
      0.03973769396543503,
      -0.004393260460346937,
      0.04816824570298195,
      0.11307014524936676,
      -0.0708337128162384,
      -0.041400328278541565,
      0.008116848766803741,
      0.12224841862916946
    ],
    [
      0.157419815659523,
      -0.007640838623046875,
      0.01118456944823265,
      -0.006006790790706873,
      0.1533534973859787,
      -0.06228262186050415,
      0.03234638646245003,
      0.32174262404441833,
      0.11855989694595337,
      -0.2610926032066345,
      -0.10628828406333923,
      0.1750386506319046,
      0.019307825714349747,
      0.07225897163152695,
      -0.19383373856544495,
      -0.026383928954601288,
      0.08014269173145294
    ],
    [
      -0.09754912555217743,
      -0.15949894487857819,
      0.20102941989898682,
      0.03820430859923363,
      0.10568977892398834,
      0.09671046584844589,
      0.11355895549058914,
      0.051897551864385605,
      -0.044143594801425934,
      0.16559556126594543,
      -0.2386704832315445,
      0.04784538224339485,
      0.05140796676278114,
      0.12785261869430542,
      -0.06162257120013237,
      0.044069528579711914,
      0.10799364745616913
    ],
    [
      -0.10230659693479538,
      -0.15650051832199097,
      0.0913199931383133,
      -0.03427663818001747,
      0.02940591424703598,
      0.06993130594491959,
      -0.0966191217303276,
      0.004183724522590637,
      0.004595320206135511,
      -0.01960207149386406,
      -0.027661744505167007,
      0.12237855792045593,
      -0.010533667169511318,
      0.015620994381606579,
      -0.13658136129379272,
      -0.027089262381196022,
      0.0726914182305336
    ],
    [
      -0.034862667322158813,
      0.015979692339897156,
      -0.024055587127804756,
      0.03432847931981087,
      -0.07957419008016586,
      0.12240489572286606,
      0.0031306473538279533,
      0.009142769500613213,
      0.016191549599170685,
      0.13104502856731415,
      -0.06311386823654175,
      -0.003338164184242487,
      -0.08208410441875458,
      -0.13749812543392181,
      -0.04237540438771248,
      -0.07870649546384811,
      -0.07473935931921005
    ],
    [
      -0.1071353480219841,
      -0.05040306597948074,
      -0.22097806632518768,
      -0.04512396454811096,
      0.057124096900224686,
      0.08381650596857071,
      0.0006938232691027224,
      -0.060807377099990845,
      -0.08752626180648804,
      0.027699457481503487,
      0.0046748630702495575,
      -0.018234729766845703,
      -0.028255973011255264,
      -0.06010778620839119,
      0.016550803557038307,
      -0.019318237900733948,
      -0.14289098978042603
    ],
    [
      0.03798917308449745,
      0.08920576423406601,
      -0.01959805004298687,
      0.13154275715351105,
      -0.06410329043865204,
      -0.05529922619462013,
      0.11864140629768372,
      -0.058297108858823776,
      -0.1445007473230362,
      -0.0759797915816307,
      -0.05499202385544777,
      -0.030422503128647804,
      -0.012239891104400158,
      -0.0416010320186615,
      -0.06107380613684654,
      -0.05452689528465271,
      0.06568820774555206
    ],
    [
      0.013458618894219398,
      -0.18460652232170105,
      -0.009595812298357487,
      0.15621264278888702,
      0.042918138206005096,
      0.06748863309621811,
      -0.20297370851039886,
      -0.00015992183762136847,
      -0.0034211119636893272,
      0.06408233195543289,
      -0.004144413396716118,
      0.13714605569839478,
      -0.08395850658416748,
      0.13361819088459015,
      -0.1025484949350357,
      0.09026358276605606,
      0.010777647607028484
    ],
    [
      -0.006612380966544151,
      0.019249100238084793,
      0.005028857383877039,
      0.09075380116701126,
      -0.14010903239250183,
      -0.2744489312171936,
      -0.007380292285233736,
      0.1712382286787033,
      -0.06651367247104645,
      -0.1751302033662796,
      0.018761275336146355,
      -0.05512949079275131,
      -0.0009544137283228338,
      0.049558255821466446,
      -0.019833186641335487,
      -0.08659196645021439,
      0.0049678729847073555
    ],
    [
      0.1508512794971466,
      0.06581375002861023,
      0.03907788544893265,
      0.14197631180286407,
      0.0610259510576725,
      0.05280499532818794,
      -0.08129963278770447,
      0.03810600936412811,
      -0.09644564241170883,
      -0.2669490873813629,
      -0.17502659559249878,
      -0.09684375673532486,
      0.04565815627574921,
      -0.17109905183315277,
      0.021086642518639565,
      0.2556791305541992,
      -0.09506735950708389
    ],
    [
      -0.06073329225182533,
      -0.12104929983615875,
      0.08685281872749329,
      0.12344441562891006,
      0.028188297525048256,
      -0.2115771770477295,
      0.044627852737903595,
      0.05715697631239891,
      0.003610626794397831,
      0.018939495086669922,
      0.01630120910704136,
      -0.06106476113200188,
      0.12256783246994019,
      0.11267565190792084,
      0.06646778434515,
      -0.12402799725532532,
      0.11614306271076202
    ],
    [
      -0.02153373695909977,
      -0.1280817687511444,
      -0.0950995683670044,
      0.045262277126312256,
      -0.06452803313732147,
      0.08375727385282516,
      -0.0007759717991575599,
      0.032813798636198044,
      -0.0739690437912941,
      0.12764380872249603,
      0.06571046262979507,
      -0.03085155598819256,
      -0.027662046253681183,
      0.004255674779415131,
      0.079627625644207,
      0.011108444072306156,
      -0.01723237708210945
    ],
    [
      -0.21708686649799347,
      -0.11324308067560196,
      0.20540682971477509,
      0.01256259623914957,
      -0.02989422157406807,
      -0.02378358319401741,
      0.05344071984291077,
      -0.12771311402320862,
      -0.03031138889491558,
      0.1418805569410324,
      -0.0878012627363205,
      0.032910555601119995,
      -0.08734676986932755,
      -0.12052224576473236,
      -0.13009391725063324,
      -0.0782833844423294,
      0.011625736951828003
    ],
    [
      -0.003434352809563279,
      -0.05755333602428436,
      0.036030177026987076,
      0.07908353954553604,
      -0.02605096809566021,
      0.12942013144493103,
      0.035705674439668655,
      0.035186029970645905,
      0.08452434837818146,
      -0.021571211516857147,
      -0.022855550050735474,
      0.003956959582865238,
      -0.011935564689338207,
      -0.04573873430490494,
      0.06544603407382965,
      -0.005902249366044998,
      -0.005473141558468342
    ],
    [
      -0.17475886642932892,
      -0.2501996159553528,
      0.08481725305318832,
      -0.09555662423372269,
      -0.055697593837976456,
      -0.007913820445537567,
      -0.05471853166818619,
      -0.005973576568067074,
      0.021039877086877823,
      -0.1388803869485855,
      -0.0762925073504448,
      0.05772070959210396,
      0.07112056016921997,
      0.1511918157339096,
      -0.0025172606110572815,
      -0.0222439244389534,
      0.009044770151376724
    ],
    [
      -0.003825465217232704,
      0.015365301631391048,
      0.012299357913434505,
      -0.1093253567814827,
      0.06430463492870331,
      0.1405402272939682,
      -0.12006735056638718,
      -0.05098555609583855,
      0.002401983365416527,
      0.1179908886551857,
      0.018041269853711128,
      0.2152816653251648,
      0.05776354670524597,
      -0.0386725477874279,
      -0.06318898499011993,
      0.04669664427638054,
      0.0015032491646707058
    ],
    [
      -0.03052222728729248,
      -0.04340917244553566,
      -0.1484517753124237,
      -0.05158580467104912,
      0.07221613079309464,
      0.09181327372789383,
      0.027416376397013664,
      0.2827307879924774,
      0.08365336805582047,
      -0.09450526535511017,
      0.06565291434526443,
      -0.06950203329324722,
      -0.08086733520030975,
      0.0969281792640686,
      -0.06951291114091873,
      0.05471532791852951,
      -0.020596027374267578
    ],
    [
      -0.16477546095848083,
      -0.10509015619754791,
      0.13519492745399475,
      -0.04287618771195412,
      0.0513872466981411,
      -0.017333107069134712,
      -0.09827269613742828,
      -0.08017861098051071,
      -0.09374307096004486,
      0.1637955605983734,
      0.045471347868442535,
      -0.039098676294088364,
      -0.07116685062646866,
      0.153038889169693,
      -0.012142891064286232,
      0.1947113275527954,
      0.1538190096616745
    ],
    [
      -0.08344925194978714,
      -0.0274788998067379,
      -0.20594583451747894,
      0.2571973204612732,
      0.014001035131514072,
      0.17360244691371918,
      -0.2048330456018448,
      -0.018094399943947792,
      0.0038636107929050922,
      -0.22068381309509277,
      0.053677260875701904,
      0.04731779918074608,
      0.01718798466026783,
      -0.11410858482122421,
      0.00019876261649187654,
      0.2399844229221344,
      -0.12855389714241028
    ],
    [
      0.04168000817298889,
      0.05673353374004364,
      0.013678256422281265,
      -0.012994863092899323,
      0.17979852855205536,
      0.00023297609004657716,
      -0.06315065175294876,
      0.032945651561021805,
      0.01887468248605728,
      -0.0667480081319809,
      0.09799092262983322,
      0.09789878129959106,
      -0.03248424828052521,
      -0.1068444550037384,
      0.07589544355869293,
      -0.023855360224843025,
      -0.03349616006016731
    ],
    [
      -0.22278834879398346,
      -0.09489993005990982,
      -0.09662661701440811,
      0.029802775010466576,
      0.04386244714260101,
      -0.10197389870882034,
      0.10155891627073288,
      0.30439481139183044,
      -0.0640418753027916,
      0.02092018350958824,
      -0.12756459414958954,
      -0.14935044944286346,
      -0.03458976745605469,
      0.026599552482366562,
      0.0072588324546813965,
      -0.18362115323543549,
      0.11021453142166138
    ],
    [
      -0.11880137026309967,
      -0.021376853808760643,
      0.17852817475795746,
      0.020987696945667267,
      -0.09652913361787796,
      -0.036324307322502136,
      -0.23137079179286957,
      0.07507193833589554,
      -0.051821231842041016,
      -0.0025557135231792927,
      -0.04870657995343208,
      0.03750038519501686,
      -0.10645004361867905,
      -0.0187998004257679,
      0.0036759504582732916,
      -0.02054326795041561,
      -0.07603202015161514
    ],
    [
      -0.061615217477083206,
      -0.008964898996055126,
      0.10224688798189163,
      0.01942303590476513,
      -0.006489746272563934,
      -0.16378557682037354,
      0.21257014572620392,
      -0.11119572818279266,
      0.2445211261510849,
      -0.1406981647014618,
      0.0010793927358463407,
      -0.048540130257606506,
      0.0408853180706501,
      0.10543550550937653,
      0.07636915892362595,
      -0.19109299778938293,
      -0.0827130600810051
    ],
    [
      -0.05162045732140541,
      0.0809611976146698,
      -0.03563402220606804,
      -0.013004288077354431,
      0.02001115493476391,
      -0.052889637649059296,
      -0.07152789831161499,
      0.04372427985072136,
      -0.13315854966640472,
      -0.05118284001946449,
      0.02252357080578804,
      0.03179018199443817,
      0.06419867277145386,
      -0.019758665934205055,
      -0.009485265240073204,
      -0.028176741674542427,
      0.08098287135362625
    ],
    [
      0.004984515253454447,
      -0.013948610052466393,
      0.09475050866603851,
      -0.03660931810736656,
      -0.08831141889095306,
      -0.029007093980908394,
      -0.09231221675872803,
      0.05078038573265076,
      -0.027587255463004112,
      -0.04341283068060875,
      0.04936305433511734,
      0.07723842561244965,
      -0.05335603281855583,
      -0.009992023929953575,
      0.07405055314302444,
      0.014030025340616703,
      0.03275701031088829
    ],
    [
      0.19781003892421722,
      0.006075065582990646,
      -0.24495218694210052,
      0.14718393981456757,
      -0.054184362292289734,
      0.018505113199353218,
      0.1508147269487381,
      0.04801857843995094,
      -0.0886000320315361,
      -0.02214214950799942,
      -0.010633242316544056,
      -0.0521751344203949,
      -0.15475362539291382,
      -0.07078494876623154,
      -0.1111704558134079,
      0.31580790877342224,
      -0.039619073271751404
    ],
    [
      0.02443436160683632,
      -0.021741578355431557,
      -0.003695569233968854,
      -0.008403969928622246,
      0.019968492910265923,
      0.09982141107320786,
      0.017263906076550484,
      0.017809594050049782,
      0.030648084357380867,
      -0.013405981473624706,
      0.05815029516816139,
      0.009367376565933228,
      0.000990041415207088,
      0.007667719852179289,
      -0.07761986553668976,
      -0.07629027962684631,
      0.04004484415054321
    ],
    [
      -0.09124228358268738,
      -0.12135988473892212,
      0.1388026773929596,
      -0.1755083054304123,
      -0.11211377382278442,
      -0.041132569313049316,
      -0.1765928417444229,
      0.029471568763256073,
      -0.007011613808572292,
      -0.012173530645668507,
      0.04893876984715462,
      0.09162097424268723,
      0.10127326846122742,
      0.12309052050113678,
      0.23237772285938263,
      0.13881081342697144,
      0.19662898778915405
    ],
    [
      0.06246064603328705,
      0.06364724040031433,
      -0.007763771340250969,
      0.12831060588359833,
      -0.05678334832191467,
      -0.014215734787285328,
      0.03384928032755852,
      0.04521200433373451,
      -0.06808023899793625,
      0.010353226214647293,
      0.012205209583044052,
      -0.02204333245754242,
      -0.01974107138812542,
      -0.05693830922245979,
      0.09563839435577393,
      -0.01699758879840374,
      0.05785385146737099
    ],
    [
      -0.1316245049238205,
      -0.012313255108892918,
      0.06165294721722603,
      -0.06979497522115707,
      0.053085338324308395,
      0.010463004931807518,
      -0.046624910086393356,
      0.01778053119778633,
      -0.006719190161675215,
      0.02754526399075985,
      -0.04642675817012787,
      -0.0031413536053150892,
      0.01986110955476761,
      0.12304303795099258,
      -0.05253893882036209,
      -0.06026710569858551,
      5.563833474298008e-05
    ],
    [
      -0.0017081433907151222,
      -0.02644122578203678,
      0.05422801896929741,
      -0.09712690860033035,
      -0.08128248900175095,
      0.016287457197904587,
      0.02735101245343685,
      0.02659674920141697,
      -0.05524780973792076,
      -0.07536731660366058,
      -0.0388374924659729,
      0.033545803278684616,
      0.10715288668870926,
      0.10202910006046295,
      0.032836876809597015,
      0.033657290041446686,
      0.1063694953918457
    ],
    [
      -0.04324166104197502,
      0.011043856851756573,
      -0.05030011758208275,
      -0.10393202304840088,
      0.0013758043060079217,
      -0.16562806069850922,
      -0.05844021961092949,
      0.11281810700893402,
      -0.04675084352493286,
      0.07758253812789917,
      -0.09900371730327606,
      -0.08669840544462204,
      0.15269243717193604,
      0.07622746378183365,
      0.01562776230275631,
      0.12476876378059387,
      0.051188431680202484
    ],
    [
      0.007607054430991411,
      0.007482145447283983,
      -0.0438004732131958,
      0.036987196654081345,
      -0.061460476368665695,
      0.08301898092031479,
      0.05551288649439812,
      0.07307490706443787,
      0.075656458735466,
      0.06052441522479057,
      0.1080893948674202,
      0.15199482440948486,
      -0.058890365064144135,
      0.017316533252596855,
      -0.07440785318613052,
      -0.04525662586092949,
      -0.03610951080918312
    ],
    [
      0.03953235596418381,
      -0.008050463162362576,
      0.06434617936611176,
      0.10635682940483093,
      0.021654145792126656,
      -0.0031057095620781183,
      0.13439080119132996,
      0.05545097962021828,
      -0.06646589934825897,
      -0.07376394420862198,
      0.025888103991746902,
      -0.0035838906187564135,
      0.05089876800775528,
      0.047629404813051224,
      -0.05094192922115326,
      0.08374834060668945,
      -0.10104633122682571
    ],
    [
      -0.08635998517274857,
      -0.05213553458452225,
      -0.06303218007087708,
      0.030770765617489815,
      -0.04563959315419197,
      -0.0025675625074654818,
      0.05789639800786972,
      -0.0641743540763855,
      -0.07464198768138885,
      0.10016878694295883,
      -0.016253583133220673,
      0.006398926023393869,
      0.010397618636488914,
      -0.04300222173333168,
      0.03838898614048958,
      0.017354726791381836,
      -0.03914976865053177
    ],
    [
      -0.10581745952367783,
      -0.044075094163417816,
      -0.007158753927797079,
      -0.07328620553016663,
      0.005098559893667698,
      0.04961663857102394,
      -0.00086833699606359,
      -0.0798807144165039,
      -0.06264994293451309,
      -0.12030912935733795,
      0.04341711103916168,
      -0.052213508635759354,
      0.03960090130567551,
      0.034728359431028366,
      0.0482228547334671,
      0.044994134455919266,
      -0.09475025534629822
    ],
    [
      -0.002536054467782378,
      0.10010246932506561,
      -0.043177589774131775,
      -0.04424295946955681,
      0.013701589778065681,
      -0.054871704429388046,
      0.044136956334114075,
      0.06360497325658798,
      0.05684886872768402,
      -0.05914453789591789,
      -0.01034484338015318,
      0.028078600764274597,
      0.04195956140756607,
      0.1379287987947464,
      0.06990625709295273,
      -0.0018535371636971831,
      -0.018996043130755424
    ],
    [
      0.07702173292636871,
      0.046539075672626495,
      -0.09152226895093918,
      0.010144184343516827,
      0.14335831999778748,
      -0.08441010117530823,
      0.0370573066174984,
      0.001860997756011784,
      0.0928448736667633,
      0.03317413851618767,
      -0.0037466695066541433,
      0.008578278124332428,
      -0.04409864544868469,
      -0.07117468118667603,
      0.07646473497152328,
      -0.02029101364314556,
      -0.07604128122329712
    ],
    [
      0.20637011528015137,
      -0.22635874152183533,
      -0.12196529656648636,
      -0.17888478934764862,
      0.04746690392494202,
      -0.028715262189507484,
      -0.358039915561676,
      -0.09837449342012405,
      -0.25305113196372986,
      0.21674279868602753,
      0.1502622365951538,
      0.14069214463233948,
      0.1028759554028511,
      0.0967872217297554,
      -0.030763881281018257,
      0.07766234129667282,
      0.03917839378118515
    ],
    [
      0.08363141119480133,
      -0.07179171591997147,
      0.07497486472129822,
      -0.10624529421329498,
      -0.004231487400829792,
      0.07369347661733627,
      0.0946621522307396,
      0.3357253670692444,
      0.046755485236644745,
      -0.1417435258626938,
      -0.07754943519830704,
      0.010300700552761555,
      0.029374415054917336,
      -0.03977763652801514,
      -0.09471118450164795,
      0.04031522572040558,
      -0.09370793402194977
    ],
    [
      -0.051288995891809464,
      -0.008250562474131584,
      -0.010690005496144295,
      -0.08854489028453827,
      -0.02773558534681797,
      0.0933336690068245,
      -0.030427828431129456,
      -0.03801015391945839,
      0.043073710054159164,
      -0.1673041731119156,
      0.06843732297420502,
      -0.04325999319553375,
      -0.08086051791906357,
      0.08073576539754868,
      0.2407149374485016,
      0.005694547202438116,
      0.06626490503549576
    ],
    [
      0.19577844440937042,
      0.016704551875591278,
      -0.05602915957570076,
      -0.1773219257593155,
      0.0652686357498169,
      0.19412201642990112,
      -0.030485661700367928,
      0.2749648094177246,
      -0.0023473789915442467,
      -0.07314355671405792,
      -0.008956721052527428,
      0.1199951320886612,
      -0.04155917093157768,
      -0.07766751945018768,
      -0.01856045424938202,
      0.014738433994352818,
      -0.1582808494567871
    ],
    [
      -0.16138087213039398,
      0.008705907501280308,
      0.14194345474243164,
      0.08047530055046082,
      -0.08575949817895889,
      -0.059821177273988724,
      0.052130088210105896,
      -0.24580006301403046,
      0.20520856976509094,
      -0.06607682257890701,
      0.06011638045310974,
      0.026724692434072495,
      0.051150135695934296,
      -0.08094904571771622,
      0.06760192662477493,
      0.04318650811910629,
      -0.1052815169095993
    ],
    [
      -0.02017551101744175,
      0.07044699043035507,
      -0.05880998820066452,
      0.02864711917936802,
      -0.11918198317289352,
      0.11062643676996231,
      -0.08551342040300369,
      -0.20086947083473206,
      -0.08755618333816528,
      0.12954217195510864,
      0.015393133275210857,
      -0.09411145746707916,
      -0.13720394670963287,
      -0.013565081171691418,
      0.06389647722244263,
      0.062251247465610504,
      -0.08625975996255875
    ],
    [
      -0.0060199107974767685,
      0.09546878933906555,
      -0.0037156962789595127,
      -0.07305597513914108,
      -0.03566572070121765,
      0.04293864220380783,
      0.005001078825443983,
      -0.043650589883327484,
      0.05712271109223366,
      0.10473589599132538,
      0.01606244035065174,
      0.03093777224421501,
      0.029593540355563164,
      0.039626192301511765,
      0.07660111784934998,
      0.12164752930402756,
      -0.04308466240763664
    ],
    [
      -0.27977511286735535,
      0.05207272619009018,
      0.06857617199420929,
      -0.08682960271835327,
      0.02581275999546051,
      -0.059953950345516205,
      0.04629300534725189,
      0.23327945172786713,
      0.07852219045162201,
      -0.0079137422144413,
      0.026438873261213303,
      0.014680319465696812,
      0.01982041820883751,
      -0.06573935598134995,
      0.08822645246982574,
      0.05536002293229103,
      -0.021028349176049232
    ],
    [
      0.026913903653621674,
      -0.03439243137836456,
      0.05252492055296898,
      0.01486815232783556,
      -0.00721960561349988,
      -0.0759735107421875,
      -0.06898919492959976,
      0.015083279460668564,
      0.02511175349354744,
      -0.04329284653067589,
      0.056287623941898346,
      -0.028861405327916145,
      -0.11197840422391891,
      -0.12571042776107788,
      0.11119317263364792,
      0.05244124308228493,
      -0.0414903499186039
    ],
    [
      -0.1724119335412979,
      0.30719155073165894,
      0.0869431421160698,
      0.13994883000850677,
      0.010558401234447956,
      0.0683881863951683,
      -0.006048573646694422,
      0.23852841556072235,
      -0.07258500158786774,
      0.04806667938828468,
      -0.2056257277727127,
      0.10474468767642975,
      -0.27409180998802185,
      0.05390995740890503,
      -0.21656499803066254,
      0.07866199314594269,
      -0.018696844577789307
    ],
    [
      -0.06329567730426788,
      -0.21410802006721497,
      -0.024194378405809402,
      0.14235863089561462,
      0.04025961458683014,
      -0.034157104790210724,
      -0.08222741633653641,
      -0.014677689410746098,
      0.13326279819011688,
      -0.05983111634850502,
      0.062254052609205246,
      0.07508283108472824,
      0.12029499560594559,
      0.11902929842472076,
      0.003956202883273363,
      -0.11737170070409775,
      -0.007589487358927727
    ],
    [
      0.0632186308503151,
      0.0666721761226654,
      -0.031385183334350586,
      0.19570565223693848,
      -0.0263831689953804,
      -0.17763975262641907,
      -0.06065019965171814,
      0.2022200971841812,
      -0.1359163075685501,
      0.06145225092768669,
      -0.09238021075725555,
      0.019848354160785675,
      -0.050023403018713,
      -0.055624350905418396,
      -0.13047650456428528,
      0.005377986002713442,
      0.11110049486160278
    ],
    [
      0.01824752800166607,
      0.007289625704288483,
      -0.12081004679203033,
      0.18036594986915588,
      -0.04902135208249092,
      0.11028900742530823,
      -0.05385523661971092,
      -0.14545994997024536,
      -0.10935640335083008,
      0.03205456584692001,
      0.1355772316455841,
      0.06122158095240593,
      -0.06107240170240402,
      -0.11496789753437042,
      -0.050262123346328735,
      0.25249767303466797,
      -0.0348178967833519
    ],
    [
      -0.0004883664078079164,
      0.08931709825992584,
      -0.2961316406726837,
      0.013928456231951714,
      0.03787577152252197,
      0.23061448335647583,
      -0.13456103205680847,
      0.23379497230052948,
      0.11895863711833954,
      -0.49762266874313354,
      0.031207405030727386,
      -0.1359645575284958,
      -0.12396522611379623,
      0.009319950826466084,
      0.04079629108309746,
      0.20688554644584656,
      -0.1343553215265274
    ],
    [
      0.09300395846366882,
      -0.26376616954803467,
      -0.21214090287685394,
      0.09198170155286789,
      0.04209902882575989,
      0.13683520257472992,
      0.04901667311787605,
      0.16898728907108307,
      0.11162072420120239,
      -0.35547399520874023,
      0.13883453607559204,
      -0.1562071591615677,
      -0.054469700902700424,
      0.0760967805981636,
      0.12106578797101974,
      0.0367584228515625,
      -0.03346104547381401
    ],
    [
      0.04291903227567673,
      -0.10507801920175552,
      0.13222506642341614,
      -0.0429534986615181,
      0.06110160052776337,
      0.06436172127723694,
      -0.023961715400218964,
      -0.012779509648680687,
      0.027140269055962563,
      0.1105806827545166,
      0.008301024325191975,
      0.052127059549093246,
      0.05311744660139084,
      0.09209172427654266,
      0.01978607289493084,
      -0.09017210453748703,
      0.021810170263051987
    ],
    [
      -0.08775291591882706,
      -0.004118683747947216,
      0.01775694638490677,
      0.08892445266246796,
      0.04172274097800255,
      0.021585330367088318,
      0.018411627039313316,
      0.10675235837697983,
      -0.04233568161725998,
      -0.059351127594709396,
      -0.09909037500619888,
      0.0108817582949996,
      0.0627426877617836,
      0.04157635197043419,
      -0.020430751144886017,
      0.017233457416296005,
      0.014843654818832874
    ],
    [
      -0.1760888248682022,
      -0.15778541564941406,
      -0.28347668051719666,
      -0.08839982002973557,
      -0.07770504057407379,
      -0.07118042558431625,
      -0.05296353995800018,
      -0.037935107946395874,
      -0.19741342961788177,
      0.04306633397936821,
      0.1000080555677414,
      0.05434419587254524,
      0.1388966292142868,
      0.18876677751541138,
      -0.09885235875844955,
      -0.009681547991931438,
      0.08949379622936249
    ],
    [
      0.04616917669773102,
      0.299919992685318,
      -0.0658167153596878,
      -0.32786452770233154,
      -0.03371052071452141,
      0.2383347451686859,
      0.017490150406956673,
      -0.0013455530861392617,
      0.1390412151813507,
      -0.0301655326038599,
      0.18702220916748047,
      0.00022319238632917404,
      -0.10348283499479294,
      0.007264059968292713,
      0.1175554171204567,
      -0.12332945317029953,
      -0.1731923669576645
    ],
    [
      0.041274044662714005,
      -0.32575130462646484,
      0.09626863151788712,
      0.02492371015250683,
      -0.03008933737874031,
      -0.0014715262223035097,
      0.07121406495571136,
      0.11138192564249039,
      -0.04162703827023506,
      0.06579828262329102,
      -0.059963248670101166,
      -0.04626170173287392,
      -0.046183329075574875,
      0.31020912528038025,
      0.09018637239933014,
      -0.10914259403944016,
      0.04283994436264038
    ],
    [
      -0.13213005661964417,
      -0.05136162415146828,
      0.07450161129236221,
      -0.151031032204628,
      -0.10773096978664398,
      -0.08997692167758942,
      -0.012541127391159534,
      0.25686243176460266,
      0.1163715347647667,
      0.22206997871398926,
      -0.01875147596001625,
      0.13771943747997284,
      0.028758686035871506,
      0.04586438089609146,
      0.04538669437170029,
      -0.10817187279462814,
      0.013976273126900196
    ],
    [
      0.06703449785709381,
      -0.18267449736595154,
      -0.09326571226119995,
      -0.04991835355758667,
      0.12106702476739883,
      0.15805228054523468,
      -0.023109011352062225,
      -0.06416213512420654,
      0.07158394902944565,
      0.10082129389047623,
      0.14670829474925995,
      0.040978822857141495,
      0.04315173625946045,
      0.02274644374847412,
      0.14370161294937134,
      -0.07357728481292725,
      -0.11251966655254364
    ],
    [
      -0.027401572093367577,
      0.006516647525131702,
      0.17349158227443695,
      0.13569332659244537,
      0.0007474437588825822,
      0.006944047287106514,
      -0.23212309181690216,
      -0.32891637086868286,
      -0.12464626133441925,
      0.05828355625271797,
      -0.015537730418145657,
      -0.16200223565101624,
      -0.021211745217442513,
      -0.041238997131586075,
      0.04237419366836548,
      0.23170605301856995,
      -0.028327597305178642
    ],
    [
      -0.011225433088839054,
      0.03486151620745659,
      0.09522532671689987,
      -0.2525205910205841,
      -0.024316424503922462,
      -0.11183075606822968,
      0.0797315165400505,
      0.46247780323028564,
      0.06981339305639267,
      0.05911826342344284,
      -0.09084168076515198,
      0.023205963894724846,
      -0.047722797840833664,
      0.06165073812007904,
      0.019802985712885857,
      -0.17446781694889069,
      0.2522802948951721
    ],
    [
      0.025601321831345558,
      -0.03401774913072586,
      -0.12851059436798096,
      -0.054129477590322495,
      -0.020980799570679665,
      0.05162642523646355,
      -0.10719636082649231,
      0.008672581985592842,
      0.09125816822052002,
      -0.004531531129032373,
      -0.10720830410718918,
      0.14559096097946167,
      0.0454648956656456,
      0.04065345600247383,
      -0.06178230047225952,
      0.011711244471371174,
      -0.18187712132930756
    ],
    [
      0.10179482400417328,
      0.029172131791710854,
      -0.0664970725774765,
      -0.01310780830681324,
      0.05371320620179176,
      -0.024382255971431732,
      -0.056417424231767654,
      -0.007677440997213125,
      0.10821955651044846,
      -0.20516370236873627,
      -0.06073655188083649,
      0.04402248561382294,
      -0.03716462478041649,
      0.11818506568670273,
      -0.04598921537399292,
      -0.14395460486412048,
      0.03628857806324959
    ],
    [
      -0.09549430757761002,
      -0.0483316034078598,
      0.02566666528582573,
      -0.14906658232212067,
      -0.06044376268982887,
      -0.005037662573158741,
      -0.0030207852832973003,
      0.10371492058038712,
      -0.013871760107576847,
      -0.010029335506260395,
      0.11782743036746979,
      0.08712855726480484,
      0.09691551327705383,
      0.06404686719179153,
      -0.09195052832365036,
      -0.06617269665002823,
      -0.044268898665905
    ],
    [
      0.2791339159011841,
      -0.18249164521694183,
      -0.10375457257032394,
      -0.01898098737001419,
      0.022602807730436325,
      0.19970563054084778,
      -0.03310706093907356,
      0.007270821835845709,
      0.018286021426320076,
      -0.10207575559616089,
      0.05249527841806412,
      0.13054578006267548,
      -0.15575014054775238,
      0.12006983160972595,
      0.14930368959903717,
      0.10695534944534302,
      -0.006275948137044907
    ],
    [
      0.2739426791667938,
      -0.06542240083217621,
      0.031237078830599785,
      -0.04082929342985153,
      0.11865712702274323,
      0.11447460204362869,
      0.038599252700805664,
      -0.04519190266728401,
      -0.28155702352523804,
      0.1171211302280426,
      0.04401503503322601,
      0.0810583159327507,
      -0.0422428622841835,
      -0.19078406691551208,
      -0.01128366682678461,
      0.2183229774236679,
      -0.1919778734445572
    ],
    [
      0.03857271745800972,
      -0.1085699051618576,
      -0.10880552977323532,
      -0.0342169925570488,
      0.05101842060685158,
      -0.16901659965515137,
      0.11484640836715698,
      0.1721407026052475,
      -0.07884003221988678,
      0.025340773165225983,
      0.17398600280284882,
      0.24228481948375702,
      -0.20871126651763916,
      0.07196104526519775,
      -0.08684072643518448,
      -0.07934565097093582,
      -0.03915838897228241
    ],
    [
      0.12940271198749542,
      -0.13396723568439484,
      -0.05601320043206215,
      -0.19862329959869385,
      -0.07352909445762634,
      -0.004545658826828003,
      -0.08837282657623291,
      0.10227898508310318,
      -0.08636237680912018,
      0.09003390371799469,
      0.025152117013931274,
      0.1847550868988037,
      0.03984491154551506,
      0.06444580107927322,
      0.005456871818751097,
      -0.09895413368940353,
      0.021033817902207375
    ],
    [
      -0.17144864797592163,
      0.060433752834796906,
      -0.0801442489027977,
      -0.06376691162586212,
      0.06037371605634689,
      0.0033558860886842012,
      -0.1618340015411377,
      0.0946323350071907,
      0.07082682102918625,
      0.12660382688045502,
      0.0603170208632946,
      -0.20739807188510895,
      0.18048594892024994,
      -0.11990053206682205,
      -0.03809629753232002,
      -0.2209346443414688,
      -0.04638015106320381
    ],
    [
      0.09815284609794617,
      0.029305197298526764,
      0.008965224958956242,
      -0.013328427448868752,
      0.026428185403347015,
      0.05986706539988518,
      0.11894245445728302,
      -0.3218235671520233,
      0.08263057470321655,
      -0.20424968004226685,
      0.0832066610455513,
      0.044131089001894,
      -0.002441782969981432,
      -0.04228103160858154,
      -0.02615072764456272,
      0.0434909351170063,
      0.009797872975468636
    ],
    [
      -0.1249278113245964,
      0.06851045787334442,
      0.10932239145040512,
      -0.16149328649044037,
      -0.0021401066333055496,
      0.26910704374313354,
      0.04713129624724388,
      -0.006070886272937059,
      0.242617666721344,
      0.08252770453691483,
      -0.042051129043102264,
      -0.1170547604560852,
      0.13704846799373627,
      0.14851056039333344,
      0.13015015423297882,
      -0.19193494319915771,
      -0.2551954686641693
    ],
    [
      -0.2847042381763458,
      0.2446719855070114,
      0.10712296515703201,
      -0.03883223608136177,
      -0.11552854627370834,
      0.050317104905843735,
      -0.11752372235059738,
      -0.016600949689745903,
      0.057737454771995544,
      0.18190304934978485,
      -0.07177892327308655,
      0.049680326133966446,
      0.19308945536613464,
      -0.03237873315811157,
      0.011026564985513687,
      -0.22588400542736053,
      -0.13992910087108612
    ],
    [
      0.02951030805706978,
      -0.025169458240270615,
      -0.03628389164805412,
      0.04417527839541435,
      -0.08556506037712097,
      -0.012640492990612984,
      0.08354820311069489,
      0.030816344544291496,
      0.05410267040133476,
      0.1410481184720993,
      0.10658793896436691,
      0.08356761932373047,
      -0.047122977674007416,
      0.07454609125852585,
      -0.015049165114760399,
      0.03484534099698067,
      0.05598750710487366
    ],
    [
      -0.11755272001028061,
      -0.009060702286660671,
      0.026976274326443672,
      -0.059140611439943314,
      0.16319063305854797,
      0.10958264768123627,
      -0.05682210251688957,
      -0.05140625312924385,
      -0.07089453935623169,
      -0.005024190992116928,
      0.01689332164824009,
      0.02040831558406353,
      -0.006346618756651878,
      -0.024435333907604218,
      -0.011132853105664253,
      0.010534948669373989,
      -0.13974954187870026
    ],
    [
      0.06520752608776093,
      0.04765905439853668,
      0.05546295642852783,
      0.006310857366770506,
      0.014186724089086056,
      -0.10155563056468964,
      -0.11614271998405457,
      -0.012334123253822327,
      0.03330857306718826,
      -0.1049596294760704,
      -0.05721604824066162,
      0.005261078476905823,
      0.1360114961862564,
      -0.051968466490507126,
      0.042678650468587875,
      -0.021111447364091873,
      0.07869431376457214
    ],
    [
      0.09419940412044525,
      0.038863006979227066,
      0.12750093638896942,
      -0.02785351127386093,
      -0.04706164821982384,
      -0.03943223878741264,
      -0.05192022770643234,
      -0.0014839419163763523,
      0.09448399394750595,
      0.0688033327460289,
      -0.0040893941186368465,
      0.050498154014348984,
      0.03992996737360954,
      0.04406242445111275,
      0.06430619210004807,
      -0.04588174447417259,
      0.11690779775381088
    ],
    [
      0.03336828574538231,
      -0.05239957571029663,
      -0.08911702781915665,
      -0.02386079914867878,
      0.07273541390895844,
      -0.05816593021154404,
      -0.02287411317229271,
      0.07685217261314392,
      -0.025888176634907722,
      0.005386099684983492,
      -0.012854201719164848,
      -0.014176473021507263,
      -0.027754200622439384,
      -0.02037474699318409,
      -0.02755100652575493,
      -0.07960113883018494,
      -0.01633133739233017
    ],
    [
      0.03648076206445694,
      -0.05526076257228851,
      0.03473621979355812,
      0.06698911637067795,
      -0.11819007247686386,
      -0.022057997062802315,
      -0.06482725590467453,
      -0.1250011920928955,
      0.11384692788124084,
      -0.060279298573732376,
      0.07642291486263275,
      0.10267289727926254,
      -0.10995662212371826,
      -0.02646951749920845,
      -0.012618346139788628,
      0.014475276693701744,
      -0.0174629595130682
    ],
    [
      0.08314717561006546,
      -0.12321849912405014,
      -0.07418682426214218,
      0.044122692197561264,
      -0.010489572770893574,
      -0.07418781518936157,
      0.09816734492778778,
      -0.022440003231167793,
      0.09908518195152283,
      0.014027358964085579,
      -0.06068921461701393,
      -0.02864890731871128,
      0.09675560891628265,
      0.1218206137418747,
      0.18116214871406555,
      0.11306969076395035,
      0.0746624693274498
    ],
    [
      -0.07208026200532913,
      -0.041381049901247025,
      0.024307619780302048,
      -0.07214701920747757,
      0.14073720574378967,
      -0.03202174976468086,
      0.10754311829805374,
      0.01708601973950863,
      0.11779109388589859,
      0.031742844730615616,
      -0.05067705363035202,
      -0.01986088417470455,
      -0.04565882310271263,
      -0.050464801490306854,
      0.009956401772797108,
      0.08392022550106049,
      0.0492028072476387
    ],
    [
      0.046332940459251404,
      -0.2447846680879593,
      -0.19326673448085785,
      0.06699449568986893,
      -0.16520284116268158,
      0.02837892808020115,
      -0.048140041530132294,
      -0.006001787725836039,
      0.0301413182169199,
      -0.07765798270702362,
      0.1833670288324356,
      0.018300065770745277,
      0.0831010490655899,
      0.15327699482440948,
      0.13860417902469635,
      0.0684540718793869,
      0.024986417964100838
    ],
    [
      0.009578785859048367,
      -0.03495079278945923,
      -0.0443023145198822,
      0.038513075560331345,
      -0.09702201932668686,
      0.010210721753537655,
      0.08210553228855133,
      -0.009550130926072598,
      -0.016654612496495247,
      0.016848638653755188,
      -0.01050605345517397,
      -0.016343029215931892,
      -0.014819517731666565,
      -0.04899543523788452,
      -0.06964628398418427,
      -0.04326711967587471,
      0.05987357348203659
    ],
    [
      0.024069327861070633,
      -0.09091711789369583,
      0.3234134912490845,
      0.02914094366133213,
      0.0006249916623346508,
      -0.1784082055091858,
      0.10285920649766922,
      -0.08664415031671524,
      -0.0012847005855292082,
      -0.008919604122638702,
      -0.04244250804185867,
      0.007492766249924898,
      0.014021814800798893,
      0.04073913395404816,
      -0.23503205180168152,
      0.060007184743881226,
      0.08180992305278778
    ],
    [
      -0.08659059554338455,
      0.008340461179614067,
      0.007928353734314442,
      0.22326914966106415,
      0.12278831005096436,
      0.21982935070991516,
      -0.016744600608944893,
      -0.11773937195539474,
      -0.1230497807264328,
      0.030573958531022072,
      -0.007976231165230274,
      -0.002999034943059087,
      -0.18434779345989227,
      -0.09657979756593704,
      -0.10809802263975143,
      0.2061270922422409,
      -0.03995033726096153
    ],
    [
      0.013021986000239849,
      0.00452254805713892,
      -0.020413260906934738,
      0.024269461631774902,
      0.11854951828718185,
      -0.10449965298175812,
      -0.006431009620428085,
      -0.022779738530516624,
      -0.08753591030836105,
      -0.07813791930675507,
      0.11712002754211426,
      -0.007615445181727409,
      -0.037403404712677,
      -0.10214772820472717,
      0.08104844391345978,
      0.025292547419667244,
      -0.0672265887260437
    ],
    [
      -0.05642024427652359,
      0.20331931114196777,
      0.002947453409433365,
      0.1656191200017929,
      -0.005851402413100004,
      0.1737087368965149,
      0.07003084570169449,
      -0.050375163555145264,
      0.13711003959178925,
      0.008888154290616512,
      -0.10095518827438354,
      -0.07952727377414703,
      -0.1326400637626648,
      -0.038734547793865204,
      -0.012882133945822716,
      -0.09433555603027344,
      -0.22725911438465118
    ],
    [
      0.015781687572598457,
      0.05379246547818184,
      0.013241445645689964,
      -0.01603604294359684,
      0.136448472738266,
      -0.005538875237107277,
      -0.07923410087823868,
      -0.05706081911921501,
      -0.13932406902313232,
      0.11457040160894394,
      -0.06819532066583633,
      0.06923507153987885,
      0.13116362690925598,
      0.07116243243217468,
      -0.00851673074066639,
      -0.013426671735942364,
      0.049274951219558716
    ],
    [
      0.21079811453819275,
      -0.25670287013053894,
      0.0034923828206956387,
      0.12784312665462494,
      -0.047590818256139755,
      0.033524081110954285,
      -0.16173803806304932,
      -0.0935472920536995,
      -0.2660087049007416,
      0.037749696522951126,
      0.05582073703408241,
      0.05368102714419365,
      0.029797779396176338,
      -0.048731639981269836,
      -0.035675473511219025,
      0.07069774717092514,
      0.11346809566020966
    ],
    [
      0.15348024666309357,
      -0.0001989803131436929,
      -0.06705984473228455,
      0.01785006746649742,
      -0.05700114741921425,
      -0.028097443282604218,
      0.011120378971099854,
      0.010603073053061962,
      -0.06329675018787384,
      0.016298318281769753,
      -0.07495636492967606,
      -0.05968577042222023,
      -0.041824668645858765,
      -0.06133439764380455,
      0.11083640903234482,
      0.06930985301733017,
      -0.04770616069436073
    ],
    [
      0.01372867077589035,
      0.16287384927272797,
      -0.16133186221122742,
      0.0058007026091217995,
      0.013169104233384132,
      0.06123108044266701,
      -0.03613799437880516,
      0.0718570351600647,
      -0.15318724513053894,
      -0.17770996689796448,
      0.018947182223200798,
      0.018872380256652832,
      -0.22352120280265808,
      -0.17649996280670166,
      -0.14139172434806824,
      0.2332330048084259,
      -0.0064268577843904495
    ],
    [
      0.10915347188711166,
      -0.04419075697660446,
      -0.035759087651968,
      -0.01596190594136715,
      -0.05630983039736748,
      0.056019727140665054,
      0.11019818484783173,
      -0.06298176199197769,
      0.005514640361070633,
      -0.07457058876752853,
      -0.026151234284043312,
      0.0001301655574934557,
      -0.0730833113193512,
      0.11872022598981857,
      0.07805424183607101,
      0.019925693050026894,
      0.02128787524998188
    ],
    [
      0.09496396780014038,
      0.0006365979206748307,
      0.0017926067812368274,
      -0.055154941976070404,
      0.06846657395362854,
      -0.03759263828396797,
      -0.13919588923454285,
      0.15022124350070953,
      0.06044565513730049,
      0.05306192487478256,
      -0.03509059548377991,
      -0.06242778152227402,
      -0.10049603879451752,
      -0.05493215471506119,
      0.08437997102737427,
      0.008255589753389359,
      0.07015310972929001
    ],
    [
      -0.04375096783041954,
      -0.03187548741698265,
      0.372807115316391,
      -0.031384702771902084,
      0.07114265859127045,
      -0.023626573383808136,
      0.16542428731918335,
      -0.33872002363204956,
      -0.03257909044623375,
      0.07647872716188431,
      -0.0826706513762474,
      0.052876610308885574,
      -0.04008852690458298,
      0.012341109104454517,
      -0.08336841315031052,
      0.0007576995994895697,
      0.10775019973516464
    ],
    [
      0.10253407061100006,
      -0.0403965525329113,
      0.0995369702577591,
      -0.04617217555642128,
      0.038088712841272354,
      -0.06353213638067245,
      0.09903538972139359,
      -0.23335957527160645,
      -0.016638925299048424,
      0.20364700257778168,
      -0.05995337665081024,
      0.08930353820323944,
      -0.12478586286306381,
      -0.007431568577885628,
      -0.10759175568819046,
      0.09568416327238083,
      0.042968492954969406
    ],
    [
      0.18947173655033112,
      -0.12166855484247208,
      -0.1047445610165596,
      -0.04714339226484299,
      -0.08114805072546005,
      0.08095276355743408,
      -0.03576543927192688,
      -0.08659377694129944,
      -0.07069838792085648,
      -0.0035299400333315134,
      0.08199728280305862,
      0.17067569494247437,
      -0.09405310451984406,
      0.05884161964058876,
      0.11045120656490326,
      0.022005658596754074,
      0.022048162296414375
    ],
    [
      0.13067609071731567,
      0.037954095751047134,
      0.03193280100822449,
      -0.19790570437908173,
      0.025183342397212982,
      -0.16011126339435577,
      -0.03080536052584648,
      0.02729146182537079,
      -0.18101760745048523,
      0.2441253811120987,
      -0.0011490924516692758,
      0.011232712306082249,
      0.0057975733652710915,
      0.10032860189676285,
      0.06564664840698242,
      -0.01475917175412178,
      0.1801714152097702
    ],
    [
      -0.09131235629320145,
      -0.11126060038805008,
      -0.020078055560588837,
      -0.14438985288143158,
      0.051485396921634674,
      -0.09936121106147766,
      -0.06727246940135956,
      0.259737491607666,
      0.03941217064857483,
      -0.072572261095047,
      -0.10143589228391647,
      0.20384711027145386,
      -0.07859478145837784,
      0.09142429381608963,
      -0.08185257017612457,
      -0.15794621407985687,
      0.09006951004266739
    ],
    [
      0.08282847702503204,
      0.13307464122772217,
      -0.07448557764291763,
      0.01032974198460579,
      0.07574104517698288,
      0.015550540760159492,
      0.020370664075016975,
      0.07185228168964386,
      0.03173601254820824,
      -0.016349591314792633,
      -0.060909051448106766,
      0.06896902620792389,
      -0.06345193088054657,
      -0.03202366456389427,
      0.04668465629220009,
      -0.07522327452898026,
      -0.08794493228197098
    ],
    [
      -0.12148301303386688,
      0.07005085796117783,
      0.18168875575065613,
      -0.0038038112688809633,
      0.046363409608602524,
      -0.15566648542881012,
      -0.056578680872917175,
      -0.06497399508953094,
      0.13294947147369385,
      0.0321752205491066,
      0.019324135035276413,
      -0.10798513144254684,
      0.09275514632463455,
      0.21089570224285126,
      0.23101972043514252,
      -0.0751051977276802,
      -0.016712907701730728
    ],
    [
      -0.00558254262432456,
      0.20020966231822968,
      -0.03800030052661896,
      0.05246007442474365,
      0.07951156795024872,
      0.1257791668176651,
      -0.19286783039569855,
      -0.09840662777423859,
      0.02433249168097973,
      -0.012113818898797035,
      0.1463436782360077,
      0.11582037806510925,
      0.004092514049261808,
      0.014080725610256195,
      0.1558198183774948,
      -0.08013200014829636,
      -0.16200432181358337
    ],
    [
      0.02414550818502903,
      -0.022810427471995354,
      0.032279279083013535,
      -0.05401527136564255,
      -0.030265571549534798,
      -0.10019627213478088,
      0.010869435034692287,
      -0.06375107914209366,
      0.0012808623723685741,
      0.0004526789707597345,
      -0.03472839668393135,
      0.03333054482936859,
      0.0903114601969719,
      0.0811154842376709,
      0.027388963848352432,
      -0.07702914625406265,
      0.07435108721256256
    ],
    [
      -0.11861544847488403,
      -0.10753455013036728,
      0.06028230860829353,
      -0.07032900303602219,
      -0.17161519825458527,
      -0.05850626528263092,
      -0.013171561993658543,
      0.08824805170297623,
      0.042453743517398834,
      -0.01544143259525299,
      -0.07754389196634293,
      0.06100985407829285,
      -0.03192359581589699,
      0.062011078000068665,
      0.22658149898052216,
      0.10524049401283264,
      0.03061436489224434
    ],
    [
      -0.03174913674592972,
      -0.052800580859184265,
      -0.00660981610417366,
      0.040897056460380554,
      0.046089231967926025,
      -0.044510770589113235,
      0.06533773988485336,
      -0.04606129601597786,
      -0.06791441887617111,
      0.11342547833919525,
      0.0027422558050602674,
      0.019910823553800583,
      -0.09151061624288559,
      -0.08763835579156876,
      -0.09227351099252701,
      -0.02114148996770382,
      -0.061741750687360764
    ],
    [
      -0.0032473306637257338,
      0.018199341371655464,
      0.12725181877613068,
      0.07345039397478104,
      0.09579212218523026,
      0.009226315654814243,
      0.07408595085144043,
      -0.06483934819698334,
      0.07464618980884552,
      -0.042087603360414505,
      0.06326329708099365,
      0.0913565382361412,
      -0.04298046603798866,
      -0.00043014256516471505,
      0.008136343210935593,
      -0.09184711426496506,
      -0.026446670293807983
    ],
    [
      0.076957106590271,
      -0.0064405351877212524,
      0.1269279569387436,
      0.025024661794304848,
      0.020623505115509033,
      0.05967685952782631,
      -0.006644900888204575,
      -0.0839952901005745,
      -0.057233817875385284,
      0.02461444027721882,
      -0.05245932191610336,
      0.07471833378076553,
      0.07213345170021057,
      0.001426132395863533,
      -0.004531407728791237,
      -0.06806465238332748,
      -0.05769183114171028
    ],
    [
      0.054023247212171555,
      -0.1426975429058075,
      -0.06275622546672821,
      -0.04725215211510658,
      0.11076360940933228,
      -0.11661775410175323,
      0.04253823310136795,
      0.11178968846797943,
      -0.12026815861463547,
      -0.08390461653470993,
      -0.09219837188720703,
      -0.010660344734787941,
      0.00019175076158717275,
      0.01590457744896412,
      0.06795725226402283,
      -0.046093374490737915,
      -0.019423309713602066
    ],
    [
      -0.0456303171813488,
      -0.02258257009088993,
      -0.05504181608557701,
      0.04550405964255333,
      -0.019892528653144836,
      -0.00341765652410686,
      0.11057693511247635,
      -0.05934271588921547,
      0.12867708504199982,
      0.035330697894096375,
      -0.0621827058494091,
      -0.012928176671266556,
      -0.06818018108606339,
      0.018561501055955887,
      0.01112211961299181,
      -0.10049866884946823,
      -0.0051960693672299385
    ],
    [
      0.12186874449253082,
      -0.06171087175607681,
      -0.13106542825698853,
      -0.13862629234790802,
      0.07343341410160065,
      0.10117343068122864,
      0.029423389583826065,
      -0.03320547565817833,
      -0.12024471163749695,
      -0.02997603453695774,
      -0.05651145055890083,
      -0.12481140345335007,
      0.09301401674747467,
      -0.08734291046857834,
      0.15492559969425201,
      0.08891162276268005,
      -0.06611520051956177
    ],
    [
      -0.02638254314661026,
      0.11069390177726746,
      0.32379278540611267,
      -0.08793367445468903,
      -0.046436045318841934,
      -0.2708086371421814,
      -0.08456980437040329,
      0.19735026359558105,
      -0.012124303728342056,
      0.19627277553081512,
      -0.06611882150173187,
      -0.06745500862598419,
      0.24666470289230347,
      -0.09381036460399628,
      -0.08907259255647659,
      -0.23651883006095886,
      0.010331127792596817
    ],
    [
      -0.05152780935168266,
      0.1871393769979477,
      -0.2987622618675232,
      -0.0006361508276313543,
      0.024312084540724754,
      0.1455816775560379,
      -0.0024166011717170477,
      0.10405997186899185,
      0.0933442935347557,
      -0.1592884659767151,
      0.11458932608366013,
      0.002374904928728938,
      0.17874528467655182,
      -0.043249886482954025,
      0.0013873346615582705,
      -0.02772044949233532,
      -0.07884657382965088
    ],
    [
      0.08422663807868958,
      -0.07484486699104309,
      0.007566926069557667,
      -0.04249414801597595,
      0.04459945484995842,
      0.037897370755672455,
      -0.033964838832616806,
      0.025324011221528053,
      -0.030711190775036812,
      -0.004280234687030315,
      -0.007027049548923969,
      -0.007698872592300177,
      -0.04970067739486694,
      0.10970091074705124,
      -0.031658705323934555,
      -0.011322238482534885,
      0.06844855844974518
    ],
    [
      -0.008707846514880657,
      0.0264337956905365,
      0.03597492724657059,
      0.07873170077800751,
      0.00017025068518705666,
      -0.05138223245739937,
      -0.01638803631067276,
      0.08153313398361206,
      -0.04680205509066582,
      -0.045624274760484695,
      0.0816231220960617,
      -0.08967533707618713,
      -0.043644171208143234,
      0.08688874542713165,
      -0.009153030812740326,
      0.027577344328165054,
      0.05969339609146118
    ],
    [
      0.07893963158130646,
      0.0035221551079303026,
      -0.07219651341438293,
      -0.004603122361004353,
      0.018697841092944145,
      0.08600477874279022,
      0.010945375077426434,
      0.014152712188661098,
      0.0031180926598608494,
      -0.07158228009939194,
      -0.017967788502573967,
      0.0035391601268202066,
      0.14684739708900452,
      0.0760490819811821,
      -0.002508229110389948,
      -0.028226759284734726,
      0.07884688675403595
    ],
    [
      -0.054353974759578705,
      -0.15756955742835999,
      0.03597980737686157,
      -0.19279919564723969,
      -0.006879227701574564,
      0.07746509462594986,
      0.04337441176176071,
      0.10150955617427826,
      0.020413104444742203,
      -0.04238775745034218,
      -0.035850513726472855,
      0.09598784893751144,
      0.04498733580112457,
      0.04221361130475998,
      -0.02833055891096592,
      0.042705561965703964,
      0.005933433771133423
    ],
    [
      0.05289441719651222,
      -0.05404530093073845,
      0.030076516792178154,
      -0.1475526988506317,
      -0.05640600994229317,
      -0.025117866694927216,
      -0.039806243032217026,
      0.000419595162384212,
      0.09744266420602798,
      -0.02519846521317959,
      -0.010130628943443298,
      -0.06605939567089081,
      0.10250718891620636,
      0.033823296427726746,
      0.04604548588395119,
      0.06818780303001404,
      0.07747919857501984
    ],
    [
      -0.15247775614261627,
      0.18171270191669464,
      -0.03659813478589058,
      0.07584071159362793,
      -0.04068722948431969,
      -0.29953813552856445,
      0.049051959067583084,
      0.15167535841464996,
      -0.1298234611749649,
      0.004841094836592674,
      -0.0924394354224205,
      -0.03708508983254433,
      0.14933274686336517,
      0.039455752819776535,
      0.01257429737597704,
      0.01287361141294241,
      0.05088670924305916
    ],
    [
      -0.06397570669651031,
      -0.1075180172920227,
      0.024471288546919823,
      -0.222183495759964,
      -0.13654308021068573,
      0.09468558430671692,
      -0.10698695480823517,
      0.0888909101486206,
      0.18012602627277374,
      -0.1045118123292923,
      0.031174160540103912,
      -0.0347926989197731,
      -0.15552464127540588,
      0.01223093643784523,
      0.12900328636169434,
      -0.2034853845834732,
      -0.09774009138345718
    ],
    [
      -0.01935855858027935,
      -0.19867399334907532,
      0.0026301515754312277,
      -0.109049491584301,
      -0.005554931238293648,
      0.11545085906982422,
      0.16047725081443787,
      -0.025860244408249855,
      0.03066413663327694,
      -0.06965875625610352,
      0.0009729653829708695,
      0.03299706429243088,
      -0.06174013763666153,
      -0.001106302603147924,
      0.018214348703622818,
      -0.017897512763738632,
      0.008714375086128712
    ],
    [
      0.11328683793544769,
      -0.11170325428247452,
      -0.07078760117292404,
      0.037185635417699814,
      0.048136599361896515,
      -0.12491344660520554,
      0.06110182777047157,
      -0.1493087261915207,
      0.038537297397851944,
      -0.028630971908569336,
      0.03668411076068878,
      0.016929466277360916,
      -0.07311703264713287,
      -0.09247822314500809,
      -0.063631072640419,
      0.07139705866575241,
      -0.06665048003196716
    ],
    [
      0.16785600781440735,
      0.10336042940616608,
      -0.1747109740972519,
      0.008736200630664825,
      0.1298021525144577,
      0.150070458650589,
      -0.04070427268743515,
      -0.10326309502124786,
      0.02605953812599182,
      -0.002350895432755351,
      0.12054542452096939,
      -0.07555325329303741,
      0.07660016417503357,
      -0.06847258657217026,
      0.02528637833893299,
      0.1525481939315796,
      -0.08619219064712524
    ],
    [
      -0.2135324627161026,
      -0.008427826687693596,
      -0.058229729533195496,
      -0.08159783482551575,
      0.06038893386721611,
      -0.07533510029315948,
      0.1736537218093872,
      -0.06191027909517288,
      0.2677357494831085,
      0.03081950545310974,
      0.03724893927574158,
      -0.005858882330358028,
      0.10234791785478592,
      0.068006232380867,
      0.10653676837682724,
      -0.2794550955295563,
      -0.05278269201517105
    ],
    [
      0.0860554426908493,
      0.10625039786100388,
      -0.08284159004688263,
      0.22106285393238068,
      0.07275298237800598,
      0.028179055079817772,
      0.04182272031903267,
      -0.14451609551906586,
      -0.01613192819058895,
      -0.1595393717288971,
      0.06610511988401413,
      -0.12040539085865021,
      0.02993428148329258,
      -0.08359447121620178,
      0.010060399770736694,
      0.019794130697846413,
      -0.024606626480817795
    ],
    [
      0.07980415225028992,
      0.023931119590997696,
      0.08814532309770584,
      -0.04670358821749687,
      0.013846739195287228,
      0.08417855203151703,
      0.0950160026550293,
      0.10474153608083725,
      0.011081558652222157,
      -0.018113858997821808,
      -0.03248244524002075,
      0.036741599440574646,
      0.020858144387602806,
      -0.07207159698009491,
      0.008685242384672165,
      -0.04595028981566429,
      -0.0008002292597666383
    ],
    [
      -0.014884693548083305,
      -0.1566818207502365,
      0.11153778433799744,
      0.1095031350851059,
      0.12699323892593384,
      -0.0662432312965393,
      -0.23554658889770508,
      0.0034703442361205816,
      -0.02146078646183014,
      0.013806222938001156,
      0.06955383718013763,
      0.019195731729269028,
      0.05551718547940254,
      0.06665046513080597,
      0.0059285592287778854,
      0.126690074801445,
      0.07367926090955734
    ],
    [
      0.048373837023973465,
      0.06262892484664917,
      -0.0918067917227745,
      -0.04516448825597763,
      -0.057040128856897354,
      -0.09463831782341003,
      -0.06762640923261642,
      -0.005188459996134043,
      0.04902781546115875,
      -0.04170849919319153,
      0.0004281400179024786,
      -0.023170292377471924,
      -0.03562122955918312,
      -0.0015244504902511835,
      0.05868889018893242,
      -0.01350728515535593,
      -0.034928370267152786
    ],
    [
      -0.08835248649120331,
      0.1877250373363495,
      -0.4341399669647217,
      -0.013095961883664131,
      -0.0259284358471632,
      -0.013105294667184353,
      -0.058795224875211716,
      -0.09841326624155045,
      0.09816874563694,
      -0.09289496392011642,
      0.21811561286449432,
      -0.24063095450401306,
      0.373073935508728,
      -0.027120979502797127,
      0.04281998053193092,
      0.15274569392204285,
      -0.01690562814474106
    ],
    [
      0.0036272855941206217,
      -0.12077795714139938,
      0.10630801320075989,
      -0.06038396805524826,
      -0.08210410922765732,
      0.01194935292005539,
      0.04268955439329147,
      0.0009277727222070098,
      -0.07192187756299973,
      0.1421060860157013,
      0.02525181882083416,
      -0.08075614273548126,
      0.12450413405895233,
      -0.015944859012961388,
      0.01000426895916462,
      -0.10967821627855301,
      -0.017272965982556343
    ],
    [
      0.11185325682163239,
      -0.08932004868984222,
      0.04001859948039055,
      0.08461780101060867,
      0.04176945984363556,
      -0.04731910303235054,
      0.008694658055901527,
      0.01438808161765337,
      -0.05155885964632034,
      0.06137363240122795,
      -0.0434892438352108,
      -0.11489616334438324,
      -0.06877397745847702,
      -0.052882906049489975,
      0.01561881322413683,
      0.01809537783265114,
      -0.008709724992513657
    ],
    [
      -0.05452524498105049,
      0.09570463746786118,
      0.17188747227191925,
      0.025911878794431686,
      -0.13607290387153625,
      0.15614041686058044,
      0.03692024573683739,
      -0.1918979287147522,
      -0.1273021250963211,
      -0.06054413318634033,
      -0.10996825993061066,
      -0.32725170254707336,
      -0.15645182132720947,
      -0.08160120993852615,
      -0.012209359556436539,
      0.23327843844890594,
      0.01795171946287155
    ],
    [
      0.22382040321826935,
      -0.07799678295850754,
      0.16366635262966156,
      0.06882884353399277,
      0.002943858504295349,
      0.16464830935001373,
      -0.20467005670070648,
      0.0830162987112999,
      -0.22296543419361115,
      0.23663941025733948,
      -0.017912128940224648,
      0.06293554604053497,
      0.10705677419900894,
      0.011617632582783699,
      -0.14900577068328857,
      0.1456073522567749,
      0.03859724476933479
    ],
    [
      0.027455288916826248,
      -0.01597600430250168,
      0.029600277543067932,
      0.02293315716087818,
      -0.006323224864900112,
      0.01981857605278492,
      -0.09282474219799042,
      0.011262481100857258,
      0.0333017036318779,
      0.003304976737126708,
      0.07419365644454956,
      0.019598791375756264,
      -0.009095540270209312,
      0.009118425659835339,
      0.055532898753881454,
      0.02892882563173771,
      -0.03748983517289162
    ],
    [
      0.020151996985077858,
      -0.023970752954483032,
      -0.04922282323241234,
      0.05854209512472153,
      -0.01879422925412655,
      0.038886260241270065,
      -0.0477885939180851,
      -0.05409925803542137,
      0.1250697821378708,
      -0.00473356805741787,
      0.007948009297251701,
      -0.03820555657148361,
      0.005876121111214161,
      -0.1454235166311264,
      0.025941643863916397,
      -0.026226313784718513,
      0.06959088146686554
    ],
    [
      0.03830469399690628,
      0.04230082780122757,
      -0.04900521785020828,
      -0.0003063231415580958,
      -0.07452664524316788,
      -0.17637844383716583,
      0.046337295323610306,
      0.14281176030635834,
      -0.014227685518562794,
      0.0574273020029068,
      -0.04086708277463913,
      -0.03442881628870964,
      0.0654195249080658,
      0.04613442346453667,
      -0.06561186909675598,
      -0.012176278047263622,
      0.017331894487142563
    ],
    [
      -0.0959218218922615,
      -0.009817118756473064,
      -0.20147700607776642,
      -0.11062076687812805,
      -0.011797708459198475,
      0.0028456375002861023,
      -0.16996483504772186,
      0.20512084662914276,
      0.1239800676703453,
      -0.1046968400478363,
      0.07038258761167526,
      -0.002923869527876377,
      0.09968409687280655,
      0.18101654946804047,
      -0.05426248908042908,
      0.015495755709707737,
      -0.0009634284651838243
    ],
    [
      -0.06129344925284386,
      -0.10461056232452393,
      0.048186108469963074,
      0.05252978205680847,
      -0.028105517849326134,
      -0.03246048837900162,
      -0.03612857311964035,
      -0.0376674123108387,
      0.011757975444197655,
      -0.09251674264669418,
      -0.07391805946826935,
      0.026779459789395332,
      -0.024265235289931297,
      0.06932315975427628,
      0.0534176267683506,
      0.04071236401796341,
      -0.05776790529489517
    ],
    [
      0.026108717545866966,
      0.08685296028852463,
      0.09135702252388,
      -0.2200998216867447,
      -0.023003190755844116,
      -0.15055418014526367,
      0.16439040005207062,
      -0.04346428066492081,
      -0.10929755866527557,
      0.1935299038887024,
      -0.08474721014499664,
      0.13184724748134613,
      -0.1143079400062561,
      -0.04967085272073746,
      -0.07046178728342056,
      0.00040089269168674946,
      0.09139914810657501
    ],
    [
      -0.09997187554836273,
      -0.1822015345096588,
      0.19437801837921143,
      -0.010934477671980858,
      -0.07552741467952728,
      0.04906656965613365,
      0.0824766457080841,
      0.0938529297709465,
      0.05691209435462952,
      0.10132388770580292,
      -0.07111205905675888,
      -0.0443723239004612,
      -0.05603579059243202,
      0.050692442804574966,
      0.08480556309223175,
      -0.03835783898830414,
      0.11047907918691635
    ],
    [
      0.021882208064198494,
      0.03703613579273224,
      -0.1416199654340744,
      -0.009856883436441422,
      -0.0631309226155281,
      0.11195488274097443,
      0.014031628146767616,
      0.03207096457481384,
      0.06453363597393036,
      -0.09106726944446564,
      0.07196828722953796,
      -0.009063782170414925,
      -0.01898893155157566,
      -0.036015305668115616,
      0.012633811682462692,
      0.06278829276561737,
      0.04920732229948044
    ],
    [
      0.08345220237970352,
      -0.08708391338586807,
      -0.05149849131703377,
      -0.051666490733623505,
      0.04101363196969032,
      -0.023246517404913902,
      0.022617854177951813,
      0.02620808221399784,
      0.023798096925020218,
      0.01665342226624489,
      -0.007281868252903223,
      -0.07342845946550369,
      0.033400170505046844,
      0.023172149434685707,
      0.041129160672426224,
      -0.07570558041334152,
      -0.03625517711043358
    ],
    [
      -0.0731157511472702,
      0.10999107360839844,
      -0.022580048069357872,
      0.0666646957397461,
      -0.061652038246393204,
      -0.00231998972594738,
      0.03349293768405914,
      -0.0499446839094162,
      -0.023827238008379936,
      0.06998945772647858,
      0.13826875388622284,
      -0.06488794088363647,
      0.03612713888287544,
      0.11660424619913101,
      0.05128045007586479,
      0.15442287921905518,
      0.024759670719504356
    ],
    [
      -0.12065760046243668,
      -0.1478237509727478,
      0.11238203197717667,
      -0.017463654279708862,
      -0.04615553095936775,
      0.07831892371177673,
      -0.007959459908306599,
      -0.046579744666814804,
      -0.05950462073087692,
      0.008975370787084103,
      0.00718615623190999,
      0.06356354802846909,
      0.07416389137506485,
      0.18060335516929626,
      0.20175649225711823,
      -0.050165481865406036,
      0.07215683907270432
    ],
    [
      0.12028154730796814,
      -0.025972189381718636,
      0.1516636610031128,
      -0.13048173487186432,
      0.054550930857658386,
      0.10851377993822098,
      0.28876805305480957,
      0.04508177563548088,
      -0.10049678385257721,
      0.06883124262094498,
      -0.07495751231908798,
      -0.027833497151732445,
      -0.15725170075893402,
      -0.14485368132591248,
      0.031735390424728394,
      -0.027151158079504967,
      -0.07852291315793991
    ],
    [
      -0.06942254304885864,
      -0.2380443662405014,
      0.13903819024562836,
      0.018665524199604988,
      -0.13254985213279724,
      -0.022926751524209976,
      0.029366767033934593,
      0.038872119039297104,
      -0.026147576048970222,
      0.15395620465278625,
      0.005920329596847296,
      -0.023956120014190674,
      0.10624971985816956,
      0.06704579293727875,
      0.006707071792334318,
      -0.04896662011742592,
      -0.07535888999700546
    ],
    [
      -0.04389623925089836,
      0.05409025028347969,
      -0.093232661485672,
      -0.030468879267573357,
      -0.10180380195379257,
      -0.011154541745781898,
      -0.048818349838256836,
      -0.07707899063825607,
      0.01817563734948635,
      -0.054533880203962326,
      0.05299796536564827,
      -0.01621365174651146,
      0.0707678496837616,
      0.07975063472986221,
      0.004171166103333235,
      -0.046712372452020645,
      -0.017055707052350044
    ],
    [
      0.13359299302101135,
      -0.05371953174471855,
      -0.027095068246126175,
      -0.04092543572187424,
      -0.0027083640452474356,
      0.12468067556619644,
      0.11996422708034515,
      -0.012672049924731255,
      0.03230925276875496,
      -0.06323376297950745,
      0.10842268168926239,
      0.024803180247545242,
      -0.1356034129858017,
      -0.0039012888446450233,
      0.04357680305838585,
      -0.0036516087129712105,
      0.040704067796468735
    ],
    [
      0.05798286572098732,
      -0.10659357905387878,
      -0.011975986883044243,
      0.005762460641562939,
      -0.06872520595788956,
      -0.009343221783638,
      0.06244121491909027,
      0.04206214100122452,
      0.07758882641792297,
      -0.042550619691610336,
      -0.021380729973316193,
      0.028278658166527748,
      -0.06580193340778351,
      -0.010179710574448109,
      0.007475745398551226,
      0.018395207822322845,
      0.023378463461995125
    ],
    [
      -0.0009987067896872759,
      -0.16908027231693268,
      0.26009976863861084,
      -0.17199088633060455,
      -0.013576267287135124,
      0.0735657811164856,
      -0.05668194591999054,
      -0.07310434430837631,
      -0.11369608342647552,
      0.14999283850193024,
      -0.01824181340634823,
      -0.11365062743425369,
      -0.0746903270483017,
      0.06359966844320297,
      -0.021289633587002754,
      0.03179775923490524,
      0.08679654449224472
    ],
    [
      -0.08271723985671997,
      0.14828535914421082,
      0.03187725692987442,
      0.04868616163730621,
      0.032985709607601166,
      -0.04838578402996063,
      0.034233544021844864,
      0.10847844183444977,
      -0.07434030622243881,
      -0.032020509243011475,
      -0.14489926397800446,
      -0.12553393840789795,
      -0.06884299963712692,
      -0.022564897313714027,
      -0.08669117838144302,
      0.09566909819841385,
      0.15788571536540985
    ],
    [
      0.07981743663549423,
      -0.09600498527288437,
      -0.3466169238090515,
      -0.014330769889056683,
      0.08676131069660187,
      -0.04460259526968002,
      -0.26881393790245056,
      0.029341915622353554,
      -0.13870859146118164,
      0.007176368962973356,
      0.25906917452812195,
      -0.009635725058615208,
      -0.008447828702628613,
      -0.07652278989553452,
      -0.09308356791734695,
      0.16582751274108887,
      0.3186587691307068
    ],
    [
      -0.0484100840985775,
      -0.01855463534593582,
      -0.012080692686140537,
      -0.07461613416671753,
      -0.001640284201130271,
      0.02933434024453163,
      -0.0629531666636467,
      0.04671395942568779,
      -0.045437589287757874,
      0.11481959372758865,
      -0.06357888132333755,
      -0.09629815816879272,
      0.18021099269390106,
      0.08682803064584732,
      -0.01757768541574478,
      0.008294862695038319,
      0.00282574282027781
    ],
    [
      -0.02812328189611435,
      0.08298356086015701,
      -0.044569507241249084,
      -0.302297443151474,
      -0.01215292140841484,
      -0.09969980269670486,
      -0.034079428762197495,
      0.049316201359033585,
      0.08055243641138077,
      0.14517387747764587,
      0.16096000373363495,
      0.07045193761587143,
      -0.09175065904855728,
      0.008600854314863682,
      0.10483069717884064,
      -0.13909372687339783,
      0.1417950987815857
    ],
    [
      -0.004629907198250294,
      0.007010455243289471,
      0.0624699704349041,
      0.03815030679106712,
      -0.056496284902095795,
      -0.08228787779808044,
      -0.007336826995015144,
      -0.044501692056655884,
      -0.021154336631298065,
      -0.009374015033245087,
      -0.011941385455429554,
      0.11224882304668427,
      -0.006732533220201731,
      -0.03944336622953415,
      -0.01901956833899021,
      0.010541336610913277,
      -0.04215920716524124
    ],
    [
      0.07845482975244522,
      0.03234921023249626,
      -0.011606131680309772,
      0.045204758644104004,
      0.03429296985268593,
      0.0641590878367424,
      0.056458573788404465,
      -0.04017728194594383,
      -0.03972934931516647,
      0.0015744299162179232,
      0.01797422207891941,
      0.011768759228289127,
      -0.0010267908219248056,
      0.06450872868299484,
      0.09206398576498032,
      0.04121189936995506,
      0.021566661074757576
    ],
    [
      -0.08105155825614929,
      0.014589848928153515,
      0.049862537533044815,
      0.07243014872074127,
      -0.10169093310832977,
      0.03448457643389702,
      0.07430317252874374,
      -0.06978362798690796,
      0.029714157804846764,
      0.032478541135787964,
      0.04273655638098717,
      0.03129418566823006,
      -0.059993647038936615,
      -0.011963882483541965,
      -0.012971592135727406,
      0.0910860225558281,
      -0.023167362436652184
    ],
    [
      0.05192403495311737,
      0.04170018434524536,
      0.04596397653222084,
      -0.24948406219482422,
      -0.03588486835360527,
      -0.055228736251592636,
      -0.002138435607776046,
      0.32247787714004517,
      -0.07130779325962067,
      -0.227265864610672,
      -0.1105668842792511,
      0.17315392196178436,
      0.08980490267276764,
      0.1581205129623413,
      0.046081408858299255,
      -0.1813293993473053,
      0.1448672115802765
    ],
    [
      0.11450233310461044,
      0.004272593185305595,
      0.026542315259575844,
      -0.023251499980688095,
      0.09949585050344467,
      -0.006002976559102535,
      -0.13801030814647675,
      -0.08648660778999329,
      0.004731225315481424,
      0.19849233329296112,
      0.07557179033756256,
      -0.02246250957250595,
      0.058976348489522934,
      0.22335585951805115,
      0.0006384467706084251,
      -0.00872783549129963,
      0.11878541857004166
    ],
    [
      -0.13111528754234314,
      -0.01930946670472622,
      0.07949244230985641,
      -0.0095723457634449,
      0.022169945761561394,
      -0.004504865501075983,
      -0.20707187056541443,
      0.12153548002243042,
      -0.08091805875301361,
      0.2575467526912689,
      -0.07941888272762299,
      -0.07509589940309525,
      0.20382846891880035,
      0.0028061370830982924,
      0.03407011553645134,
      0.0032718840520828962,
      0.12708444893360138
    ],
    [
      -0.06100811809301376,
      -0.05905821919441223,
      -0.06354336440563202,
      -0.04770638048648834,
      0.011737460270524025,
      -0.032290928065776825,
      -0.041760604828596115,
      -0.022300992161035538,
      -0.1328369379043579,
      -0.09676998108625412,
      -0.014087698422372341,
      0.06516367942094803,
      -0.006741165649145842,
      -0.04182272031903267,
      0.03910854831337929,
      -0.05293523892760277,
      0.027095852419734
    ],
    [
      0.08798754215240479,
      -0.12749581038951874,
      0.2034757286310196,
      0.22501100599765778,
      0.02929745614528656,
      -0.12229632586240768,
      0.004483936354517937,
      0.10305920988321304,
      -0.0859452337026596,
      -0.0365491583943367,
      -0.0650971308350563,
      0.05022231116890907,
      0.08432313054800034,
      -0.17676863074302673,
      -0.34307223558425903,
      -0.22642439603805542,
      -0.01395189855247736
    ],
    [
      -0.0743931233882904,
      0.049559734761714935,
      0.0020406057592481375,
      0.03842642903327942,
      0.008055707439780235,
      -0.039547521620988846,
      -0.021820975467562675,
      0.03159314766526222,
      0.04790345951914787,
      -0.07105877995491028,
      0.06119725480675697,
      -0.010401188395917416,
      0.09836957603693008,
      0.02544415555894375,
      0.020779430866241455,
      -0.08373471349477768,
      0.06123650446534157
    ],
    [
      0.07986811548471451,
      -0.00935736671090126,
      0.20765502750873566,
      0.27685263752937317,
      -0.04177871719002724,
      -0.03036370687186718,
      0.03238361328840256,
      0.038036610931158066,
      0.12862476706504822,
      0.04695269092917442,
      0.05228693038225174,
      -0.022180799394845963,
      -0.10616651922464371,
      -0.14994214475154877,
      -0.028444690629839897,
      -0.18372052907943726,
      -0.019873835146427155
    ],
    [
      0.21008150279521942,
      -0.08332626521587372,
      -0.29626303911209106,
      -0.03348408639431,
      -0.11374928802251816,
      0.16599100828170776,
      -0.040699947625398636,
      0.14383628964424133,
      0.18093039095401764,
      -0.22709213197231293,
      0.187515527009964,
      0.309058278799057,
      -0.18458376824855804,
      0.2155386060476303,
      -0.011923180893063545,
      -0.03472364693880081,
      -0.04359126836061478
    ],
    [
      -0.08041413128376007,
      0.0037872379180043936,
      0.2765454351902008,
      -0.003752992255613208,
      -0.13778412342071533,
      -0.005779814440757036,
      0.06581669300794601,
      -0.16833938658237457,
      -0.07408303022384644,
      0.08363025635480881,
      -0.15362878143787384,
      0.05891721695661545,
      -0.06800518184900284,
      -0.01866079494357109,
      0.048289280384778976,
      -0.04863429814577103,
      0.0700136125087738
    ],
    [
      0.027461471036076546,
      -0.06805212050676346,
      -0.02257665805518627,
      0.054613687098026276,
      0.01492764800786972,
      0.22664007544517517,
      -0.19447389245033264,
      0.13899867236614227,
      -0.09948905557394028,
      -0.08170546591281891,
      -0.15264813601970673,
      -0.07554803043603897,
      -0.0360897034406662,
      0.09766114503145218,
      -0.023843133822083473,
      0.21354204416275024,
      -0.009785585105419159
    ],
    [
      -0.02807830646634102,
      0.0026979560498148203,
      -0.023356299847364426,
      -0.29718559980392456,
      -0.04167759045958519,
      0.01283720787614584,
      0.06495370715856552,
      0.03566448763012886,
      0.020635481923818588,
      -0.07211598008871078,
      0.08389654010534286,
      -0.026528114452958107,
      -0.12293905019760132,
      0.20937316119670868,
      0.01760796830058098,
      -0.1417136788368225,
      0.05311965569853783
    ],
    [
      -0.23064549267292023,
      0.18642570078372955,
      -0.057043079286813736,
      0.007954173721373081,
      0.10245157033205032,
      -0.12527239322662354,
      0.14442671835422516,
      -0.10020679980516434,
      0.04426826909184456,
      -0.03315521031618118,
      0.0845419391989708,
      -0.018068762496113777,
      0.04197940230369568,
      -0.06942658871412277,
      0.19071084260940552,
      -0.008397531695663929,
      -0.04580824822187424
    ],
    [
      0.12534445524215698,
      0.15682117640972137,
      -0.29159727692604065,
      -0.09079620987176895,
      0.04196799173951149,
      0.02596008963882923,
      0.1011965349316597,
      0.11274617910385132,
      0.049470480531454086,
      -0.15548834204673767,
      0.039428241550922394,
      0.11368633061647415,
      0.2258499711751938,
      0.12358047813177109,
      -0.13698270916938782,
      -0.04260387271642685,
      -0.042171962559223175
    ],
    [
      0.09501757472753525,
      -0.1737060397863388,
      -0.10647942870855331,
      -0.17185917496681213,
      0.06243438646197319,
      0.005866271909326315,
      0.032899145036935806,
      0.12324404716491699,
      -0.21563391387462616,
      0.017504023388028145,
      -0.044874388724565506,
      0.19082295894622803,
      -0.16755668818950653,
      -0.05429019406437874,
      -0.05438774451613426,
      0.20090962946414948,
      0.11267994344234467
    ],
    [
      -0.0852099135518074,
      -0.12059266865253448,
      0.2380223423242569,
      0.11170352250337601,
      0.08979039639234543,
      -0.00362203735858202,
      0.04730695113539696,
      0.08714267611503601,
      -0.1471882462501526,
      -0.013340294361114502,
      -0.025793183594942093,
      -0.018128780648112297,
      0.12156157195568085,
      0.016141759231686592,
      -0.04304622486233711,
      -0.041101809591054916,
      0.06190747022628784
    ],
    [
      0.096206896007061,
      0.04167542979121208,
      -0.015184488147497177,
      -0.0033632458653301,
      -0.05283558368682861,
      -0.05654266104102135,
      0.01335054263472557,
      -0.025356626138091087,
      -0.019123433157801628,
      0.10741594433784485,
      0.08449706435203552,
      0.034213755279779434,
      -0.0035273528192192316,
      -0.05878399685025215,
      -0.007481869775801897,
      -0.08259251713752747,
      0.06104062870144844
    ],
    [
      -0.0748186856508255,
      -0.1753704845905304,
      0.03630104288458824,
      -0.08869469165802002,
      0.0441109873354435,
      0.09203359484672546,
      -0.05571170523762703,
      -0.06552715599536896,
      -0.08800049126148224,
      -0.01181927789002657,
      0.04823115095496178,
      0.04055452719330788,
      0.03355344384908676,
      -0.056348104029893875,
      -0.005387439858168364,
      -0.0743636041879654,
      0.07136806100606918
    ],
    [
      0.05060393735766411,
      -0.011960752308368683,
      0.0025747455656528473,
      -0.013046413660049438,
      -0.06851308792829514,
      -0.004477832466363907,
      -0.060559578239917755,
      -0.05446888133883476,
      -0.05333852395415306,
      -0.08964895457029343,
      0.08908689022064209,
      0.06707819551229477,
      -0.020592957735061646,
      0.07715530693531036,
      0.05903111398220062,
      -0.08648984134197235,
      -0.040940262377262115
    ],
    [
      0.06398510187864304,
      0.10711964219808578,
      0.10622134059667587,
      -0.10842900723218918,
      0.11242419481277466,
      0.008899735286831856,
      0.05333951115608215,
      -0.09985927492380142,
      0.007870749570429325,
      0.0006309052114374936,
      0.07794438302516937,
      0.032707661390304565,
      -0.019518693909049034,
      -0.06793024390935898,
      0.0008362873340956867,
      0.06855567544698715,
      -0.01162814162671566
    ],
    [
      -0.007113378960639238,
      0.004467360209673643,
      0.03030257672071457,
      -0.0005075581721030176,
      0.05691990628838539,
      -0.040598273277282715,
      0.07428346574306488,
      -0.03513327240943909,
      -0.09388504922389984,
      0.04791315644979477,
      -0.05302077531814575,
      0.006998822093009949,
      -0.04733002930879593,
      0.02250947430729866,
      -0.0432366281747818,
      -0.04576457664370537,
      0.061832938343286514
    ],
    [
      -0.00968774314969778,
      0.09185287356376648,
      -0.045525748282670975,
      0.04247210547327995,
      0.02407863736152649,
      0.1260295957326889,
      0.040068358182907104,
      -0.09139595925807953,
      0.13452553749084473,
      -0.15055689215660095,
      0.09512531012296677,
      -0.07327207177877426,
      0.05157146975398064,
      -0.12177339196205139,
      0.05588642135262489,
      -0.04296112805604935,
      -0.15729032456874847
    ],
    [
      -0.09508675336837769,
      -0.092055544257164,
      0.03599165752530098,
      0.04559966176748276,
      -0.0010686051100492477,
      0.05562174692749977,
      -0.03965425491333008,
      0.11538894474506378,
      -0.16596433520317078,
      -0.0649113729596138,
      0.02294210158288479,
      0.18038207292556763,
      0.05077804625034332,
      0.004151714034378529,
      -0.11212905496358871,
      0.07613856345415115,
      0.10415908694267273
    ],
    [
      -0.01136796548962593,
      -0.017960429191589355,
      -0.035935986787080765,
      -0.26009801030158997,
      0.03655444085597992,
      -0.10024397075176239,
      0.14998206496238708,
      0.09587407112121582,
      -0.024606866762042046,
      0.03436735272407532,
      0.0416439026594162,
      -0.06057426705956459,
      0.072043277323246,
      -0.03985823318362236,
      -0.04054443538188934,
      0.06138474494218826,
      -0.032447975128889084
    ],
    [
      -0.20167876780033112,
      -0.026923662051558495,
      0.026170488446950912,
      -0.11106929928064346,
      -0.03892434388399124,
      -0.011521043255925179,
      -0.13676106929779053,
      -0.08607064187526703,
      0.11263544112443924,
      0.05155906826257706,
      0.027613922953605652,
      -0.15309680998325348,
      0.16940569877624512,
      0.2195596694946289,
      0.05132906511425972,
      -0.021405132487416267,
      0.05829252302646637
    ],
    [
      -0.02941223420202732,
      -0.14042702317237854,
      0.018625684082508087,
      0.09010247886180878,
      -0.08911260962486267,
      -0.05862424522638321,
      -0.11924342811107635,
      -0.040067799389362335,
      0.08166543394327164,
      0.1146918386220932,
      0.0466245673596859,
      -0.0854858011007309,
      0.046075064688920975,
      0.014200289733707905,
      -0.09855670481920242,
      0.20817254483699799,
      -0.10083089768886566
    ],
    [
      0.1360248625278473,
      9.008119832287775e-07,
      0.10180135816335678,
      0.02137191779911518,
      0.005869160871952772,
      0.0075032999739050865,
      -0.005751274526119232,
      -0.06908385455608368,
      -0.13332152366638184,
      -0.030872413888573647,
      -0.037490393966436386,
      -0.03552991896867752,
      -0.021313806995749474,
      0.03747822716832161,
      -0.045419178903102875,
      0.08218306303024292,
      -0.11378676444292068
    ],
    [
      0.07888128608465195,
      -0.148384690284729,
      0.13838167488574982,
      0.027596058323979378,
      0.04427873343229294,
      -0.022306717932224274,
      0.012622430920600891,
      0.012636278755962849,
      -0.0033009445760399103,
      0.006059387233108282,
      0.00034513624268583953,
      0.0071631791070103645,
      0.007748779840767384,
      -0.03742276877164841,
      -0.004252019803971052,
      0.007888277992606163,
      0.009009791538119316
    ],
    [
      -0.03165450692176819,
      -0.03218352794647217,
      -0.033919475972652435,
      0.004118640907108784,
      -0.006894292309880257,
      0.03055562637746334,
      0.08207060396671295,
      -0.039227236062288284,
      0.08574696630239487,
      -0.09967249631881714,
      0.03730675205588341,
      -0.04870216175913811,
      0.04567277431488037,
      0.06441803276538849,
      -0.0684145987033844,
      0.014195408672094345,
      -0.026079120114445686
    ],
    [
      0.05033840984106064,
      0.11781800538301468,
      0.08685840666294098,
      0.06318860501050949,
      -0.007422833703458309,
      0.17286691069602966,
      0.12555663287639618,
      0.06962210685014725,
      0.1957215666770935,
      -0.1216944083571434,
      0.0037407134659588337,
      0.15854278206825256,
      0.12446723133325577,
      -0.10356861352920532,
      -0.05232343450188637,
      -0.016523830592632294,
      -0.16960765421390533
    ],
    [
      -0.02696499228477478,
      -0.06412507593631744,
      0.014929338358342648,
      -0.054168783128261566,
      0.07450125366449356,
      -0.09544482827186584,
      -0.025624316185712814,
      -0.0226865466684103,
      0.00867599155753851,
      0.06418641656637192,
      -0.04958849772810936,
      -0.00785812083631754,
      0.12108127027750015,
      0.003573464462533593,
      0.05907251685857773,
      -0.01987084001302719,
      -0.012301229871809483
    ],
    [
      0.10530996322631836,
      0.003792057279497385,
      -0.20564039051532745,
      -0.11275241523981094,
      0.05431269109249115,
      0.10633083432912827,
      0.07527600973844528,
      0.2647268772125244,
      -0.04566432535648346,
      -0.13096025586128235,
      0.06608504801988602,
      0.11755838990211487,
      -0.17229653894901276,
      0.09073697030544281,
      0.04155132174491882,
      -0.014154070988297462,
      -0.12329019606113434
    ],
    [
      -0.17426717281341553,
      0.10911749303340912,
      -0.017053700983524323,
      -0.20666725933551788,
      -0.09011103957891464,
      -0.1683039516210556,
      0.09762362390756607,
      -0.10633135586977005,
      0.2506062984466553,
      0.02662278153002262,
      -0.014343056827783585,
      0.01411772146821022,
      0.092424176633358,
      0.03468300402164459,
      -0.05246682092547417,
      -0.024892952293157578,
      0.043783772736787796
    ],
    [
      0.04242243245244026,
      0.02820419706404209,
      0.0910070613026619,
      0.0595768578350544,
      0.04935421794652939,
      -0.002699306234717369,
      -0.05071626603603363,
      -0.08276712894439697,
      0.034430887550115585,
      0.0016881850315257907,
      0.024043599143624306,
      -0.16562657058238983,
      -0.19634585082530975,
      0.06802453845739365,
      -0.03629298508167267,
      -0.012674317695200443,
      -0.15007612109184265
    ],
    [
      0.0064446162432432175,
      0.054709259420633316,
      -0.03547182306647301,
      -0.013681077398359776,
      0.0023580919951200485,
      -0.05590779334306717,
      0.06576278060674667,
      -0.0455193817615509,
      -0.008350875228643417,
      0.09272117167711258,
      0.051809605211019516,
      0.03923732414841652,
      -0.09883511811494827,
      -0.0005205402849242091,
      -0.004510306287556887,
      -0.0256331916898489,
      -0.10325498878955841
    ],
    [
      -0.012398013845086098,
      -0.030367504805326462,
      0.16955414414405823,
      -0.18173743784427643,
      -0.09074067324399948,
      -0.10890202224254608,
      0.22243034839630127,
      0.04474334046244621,
      0.08515997231006622,
      0.09043150395154953,
      0.000546485825907439,
      -0.16200576722621918,
      0.11087825894355774,
      -0.004965438041836023,
      0.1689547896385193,
      -0.21217139065265656,
      0.062136273831129074
    ],
    [
      -0.009527865797281265,
      0.205638125538826,
      -0.1160825714468956,
      -0.02360541746020317,
      0.02185961790382862,
      -0.03875040262937546,
      0.0662049800157547,
      -0.007569437380880117,
      0.061699070036411285,
      0.05766651779413223,
      0.035264868289232254,
      -0.13490983843803406,
      0.17748038470745087,
      -0.056884802877902985,
      0.17823787033557892,
      -0.0019614200573414564,
      -0.14080151915550232
    ],
    [
      0.004732829984277487,
      0.0733216181397438,
      -0.045664601027965546,
      -0.06358055025339127,
      -0.02029028721153736,
      0.015246517956256866,
      0.0005831705639138818,
      0.08094830065965652,
      0.028064046055078506,
      0.008892079815268517,
      -0.01716761291027069,
      -0.13442093133926392,
      -0.032214321196079254,
      0.002665797248482704,
      -0.04209662601351738,
      0.09490456432104111,
      -0.0724046528339386
    ],
    [
      -0.12858927249908447,
      -0.018967121839523315,
      0.04125203937292099,
      -0.02288798987865448,
      -0.04558413475751877,
      0.029700256884098053,
      -0.24450117349624634,
      -0.04108477383852005,
      0.05363432317972183,
      0.02130405232310295,
      0.0835966095328331,
      -0.07426132261753082,
      0.11491578817367554,
      0.04462471976876259,
      -0.030701017007231712,
      -0.013363909907639027,
      0.017588598653674126
    ],
    [
      0.09659861773252487,
      -0.15936630964279175,
      -0.008006853982806206,
      -0.03246184065937996,
      0.042219795286655426,
      -0.010453731752932072,
      0.036372847855091095,
      -0.02704395353794098,
      -0.015323839150369167,
      0.0637570172548294,
      -0.010086184367537498,
      -0.007831819355487823,
      -0.03542612865567207,
      0.007970204576849937,
      0.05041475594043732,
      -0.1031879335641861,
      -0.10233031213283539
    ],
    [
      -0.14860470592975616,
      0.11938502639532089,
      0.12674856185913086,
      -0.02405261993408203,
      0.10265123099088669,
      0.15392909944057465,
      -0.10790777206420898,
      0.18165670335292816,
      0.07586212456226349,
      0.036996029317379,
      0.04023353382945061,
      -0.05203590914607048,
      0.05510030314326286,
      -0.014365199953317642,
      -0.005541109945625067,
      -0.13713474571704865,
      -0.0834994688630104
    ],
    [
      -0.011875644326210022,
      0.02747374027967453,
      0.09623289108276367,
      0.03573354706168175,
      0.047883450984954834,
      0.08975997567176819,
      0.09807783365249634,
      -0.02626364305615425,
      0.028418900445103645,
      0.07755836099386215,
      -0.06834276765584946,
      -0.16682273149490356,
      0.011460764333605766,
      -0.04104945808649063,
      0.10243656486272812,
      0.1804821640253067,
      -0.015214941464364529
    ],
    [
      -0.14207680523395538,
      0.061097610741853714,
      -0.21699215471744537,
      -0.02032605931162834,
      0.21130502223968506,
      0.14563842117786407,
      0.07504554837942123,
      0.2093169391155243,
      -0.32610780000686646,
      -0.07417933642864227,
      -0.0831155851483345,
      0.017887664958834648,
      -0.04448791220784187,
      -0.23597124218940735,
      -0.025824686512351036,
      0.08538077771663666,
      -0.1316431164741516
    ],
    [
      0.1760742962360382,
      -0.11544212698936462,
      0.19408980011940002,
      -0.08959709852933884,
      0.08537294715642929,
      0.05153072625398636,
      -0.10581759363412857,
      -0.20478804409503937,
      -0.023853173479437828,
      0.15257962048053741,
      0.04918705299496651,
      0.09964104741811752,
      0.06427465379238129,
      -0.10264450311660767,
      0.03169987350702286,
      0.02697436884045601,
      -0.22379173338413239
    ],
    [
      0.07610636949539185,
      -0.035872869193553925,
      -0.011814924888312817,
      -0.23869019746780396,
      -0.09477950632572174,
      0.12959995865821838,
      -0.1037713959813118,
      0.12249631434679031,
      0.0265653133392334,
      0.18541346490383148,
      0.175253227353096,
      0.0321238674223423,
      0.08154972642660141,
      0.1643170267343521,
      -0.017344214022159576,
      -0.016136595979332924,
      0.0807073563337326
    ],
    [
      0.09360608458518982,
      -0.024476947262883186,
      0.005305126309394836,
      -0.03944288566708565,
      -0.0044826315715909,
      -0.10190735012292862,
      0.033555254340171814,
      0.0012469105422496796,
      0.008075726218521595,
      0.038233935832977295,
      -0.006614739540964365,
      -0.04700102284550667,
      0.03978242725133896,
      -0.040558796375989914,
      0.072632797062397,
      0.021436622366309166,
      -0.02322842925786972
    ],
    [
      -0.0335000678896904,
      -0.03833199292421341,
      -0.08916954696178436,
      0.02964874356985092,
      0.007565407548099756,
      0.029208777472376823,
      0.059991057962179184,
      -0.08698815107345581,
      -0.0514509417116642,
      -0.026154616847634315,
      -0.0860501080751419,
      -0.07347502559423447,
      -0.010721483267843723,
      0.028689760714769363,
      0.003024363424628973,
      0.033210013061761856,
      -0.08741770684719086
    ],
    [
      0.005779750179499388,
      0.0241717416793108,
      0.048245493322610855,
      -0.049200281500816345,
      -0.003115458646789193,
      0.0016273249639198184,
      -0.08080093562602997,
      0.049520522356033325,
      -0.014090040698647499,
      -0.02179640531539917,
      0.09122950583696365,
      0.11545287072658539,
      0.07404735684394836,
      0.10495905578136444,
      0.0008592924568802118,
      0.03761623799800873,
      0.009645920246839523
    ],
    [
      0.02454346790909767,
      0.21886718273162842,
      -0.05088203772902489,
      -0.10647989064455032,
      -0.053209878504276276,
      -0.05779876932501793,
      -0.11759751290082932,
      0.09737502783536911,
      -0.18382179737091064,
      -0.05507158860564232,
      0.07510159909725189,
      0.018197033554315567,
      -0.07390710711479187,
      -0.029845522716641426,
      -0.1071556806564331,
      0.1400706022977829,
      -0.041658952832221985
    ],
    [
      0.19472278654575348,
      0.030486976727843285,
      0.04488300159573555,
      0.03627295419573784,
      -0.021663304418325424,
      -0.04684005677700043,
      -0.2284059077501297,
      0.10317091643810272,
      -0.10300850868225098,
      0.033955372869968414,
      0.16814041137695312,
      -0.09335149079561234,
      0.059187036007642746,
      0.0183926559984684,
      -0.04904666543006897,
      0.09112643450498581,
      -0.04228443279862404
    ],
    [
      0.009456709027290344,
      -0.10832598805427551,
      0.08679142594337463,
      0.0826742872595787,
      0.07240641117095947,
      -0.07095081359148026,
      -0.09486641734838486,
      -0.035757582634687424,
      0.11442365497350693,
      -0.005317436531186104,
      -0.014060773886740208,
      -0.01427917554974556,
      0.023846512660384178,
      0.01164292637258768,
      -0.03898099064826965,
      0.06581470370292664,
      0.08044213801622391
    ],
    [
      -0.1212901771068573,
      0.11141163855791092,
      -0.07698072493076324,
      -0.06170923635363579,
      -0.05757025629281998,
      0.05627422407269478,
      0.1170952320098877,
      -0.0323670357465744,
      -0.005590978544205427,
      -0.04146787151694298,
      0.02791946940124035,
      -0.006599430460482836,
      -0.09205362200737,
      -0.03996942192316055,
      -0.024044066667556763,
      -0.10170715302228928,
      0.07292966544628143
    ],
    [
      -0.06340325623750687,
      0.08170067518949509,
      0.13701093196868896,
      -0.05208272114396095,
      -0.016374900937080383,
      0.03839077427983284,
      -0.00928324181586504,
      -0.036691371351480484,
      0.1229700818657875,
      -0.05425353720784187,
      0.01908213272690773,
      -0.014062071219086647,
      -0.02343691885471344,
      0.12041260302066803,
      -0.020237676799297333,
      -0.12321227043867111,
      -0.05791511759161949
    ],
    [
      0.057679276913404465,
      -0.00630256999284029,
      -0.11749449372291565,
      -0.09167361259460449,
      0.08133652061223984,
      0.07668272405862808,
      0.09714515507221222,
      0.007953709922730923,
      0.09849915653467178,
      -0.08559606969356537,
      -0.10694386810064316,
      -0.004711734596639872,
      0.0003583630023058504,
      0.007990987040102482,
      0.09957943856716156,
      -0.08397277444601059,
      0.023831363767385483
    ],
    [
      -0.16341541707515717,
      -0.07606539130210876,
      -0.020465482026338577,
      -0.0740695372223854,
      0.11753782629966736,
      0.07300300151109695,
      -0.030666891485452652,
      0.1700906753540039,
      0.024445239454507828,
      -0.015587077476084232,
      -0.020151328295469284,
      0.043731383979320526,
      -0.0018048039637506008,
      -0.12357033044099808,
      -0.041224949061870575,
      -0.1699487417936325,
      0.06674396246671677
    ],
    [
      -0.010157836601138115,
      -0.19724006950855255,
      0.02541079930961132,
      -0.05201290175318718,
      -0.13009437918663025,
      -0.11763003468513489,
      0.15261958539485931,
      -0.12222066521644592,
      -0.0316142663359642,
      0.07358121126890182,
      0.11273568868637085,
      0.16262394189834595,
      -0.01962069608271122,
      0.020367225632071495,
      -0.03076082468032837,
      -0.05206970497965813,
      0.1485154777765274
    ],
    [
      0.007672180887311697,
      -0.14216871559619904,
      0.03732291981577873,
      0.1057572290301323,
      0.14432306587696075,
      0.10809198766946793,
      0.007501843851059675,
      0.14943738281726837,
      -0.13087791204452515,
      -0.12215881049633026,
      -0.06910425424575806,
      0.04065962880849838,
      -0.11970285326242447,
      -0.04681552201509476,
      -0.030180558562278748,
      0.1911289542913437,
      0.07702402025461197
    ],
    [
      -0.09137813746929169,
      0.15108664333820343,
      -0.1642332524061203,
      -0.05340617522597313,
      -0.007896583527326584,
      0.04064251109957695,
      -0.050692953169345856,
      0.07713133096694946,
      -0.1355869323015213,
      -0.0400688573718071,
      0.12409469485282898,
      0.18505537509918213,
      -0.03580725938081741,
      0.03549555689096451,
      -0.08171086758375168,
      0.0477214977145195,
      -0.012644953094422817
    ],
    [
      -0.05450751632452011,
      -0.026873832568526268,
      -0.04528193920850754,
      -0.09563237428665161,
      -0.03714356943964958,
      -0.18747887015342712,
      0.0937918871641159,
      -0.0024794945493340492,
      -0.03199039772152901,
      -0.047956906259059906,
      0.047698378562927246,
      0.09065566211938858,
      0.008617117069661617,
      0.1661771982908249,
      0.15089870989322662,
      0.008557125926017761,
      -0.04285050556063652
    ],
    [
      -0.02543587051331997,
      0.022805465385317802,
      -0.0233773160725832,
      0.005640614777803421,
      -0.030323922634124756,
      -0.06260927021503448,
      -0.035325486212968826,
      -0.017669234424829483,
      -0.0749099850654602,
      -0.005301972851157188,
      -0.0764281153678894,
      0.087851382791996,
      0.09120155870914459,
      -0.09083159267902374,
      0.03526068851351738,
      0.09619089215993881,
      -0.11936451494693756
    ],
    [
      -0.16986112296581268,
      0.11783299595117569,
      0.15762785077095032,
      -0.1642446368932724,
      0.023592835292220116,
      0.1149773970246315,
      0.07626982778310776,
      0.1463865488767624,
      -0.3060879707336426,
      0.06333140283823013,
      -0.2897063195705414,
      0.059561844915151596,
      -0.18518295884132385,
      -0.10575194656848907,
      0.16668002307415009,
      0.09031674265861511,
      0.1342996209859848
    ],
    [
      0.03615158051252365,
      -0.06981107592582703,
      -0.03015298955142498,
      -0.018656229600310326,
      0.08991891145706177,
      0.030208373442292213,
      0.09839283674955368,
      0.10365474224090576,
      0.04187662899494171,
      -0.07776585221290588,
      -0.10636013001203537,
      0.07240042090415955,
      0.0692148208618164,
      -0.14570660889148712,
      -0.0797870010137558,
      0.1334725320339203,
      -0.0920528694987297
    ],
    [
      0.019728314131498337,
      0.06885728240013123,
      0.055077213793992996,
      0.11540370434522629,
      -0.048572950065135956,
      0.05787154659628868,
      -0.16671022772789001,
      0.023142697289586067,
      -0.10526832193136215,
      -0.01047099195420742,
      0.07915230095386505,
      0.19779793918132782,
      0.07396653294563293,
      0.0279692355543375,
      -0.0062119136564433575,
      -0.26528358459472656,
      -0.09130967408418655
    ],
    [
      -0.03209705650806427,
      0.049784936010837555,
      0.11250994354486465,
      -0.08791422843933105,
      -0.039121855050325394,
      0.040694545954465866,
      0.03783527389168739,
      0.006079284008592367,
      0.05690949410200119,
      0.037642884999513626,
      0.0006017523119226098,
      0.053858473896980286,
      0.10231514275074005,
      -0.014240607619285583,
      -0.11457257717847824,
      0.07340186834335327,
      -0.04437091946601868
    ],
    [
      -0.002673342591151595,
      -0.09022138267755508,
      0.13834111392498016,
      0.06152227148413658,
      -0.12499651312828064,
      -0.017437685281038284,
      0.07220081239938736,
      -0.11009280383586884,
      0.08309292048215866,
      -0.217383474111557,
      0.006844629533588886,
      -0.02214742824435234,
      0.024693895131349564,
      -0.16276216506958008,
      -0.02244974486529827,
      0.1446823626756668,
      -0.04848653823137283
    ],
    [
      0.07505367696285248,
      0.007642543874680996,
      0.07230113446712494,
      -0.14957310259342194,
      -0.029725059866905212,
      0.09234509617090225,
      0.00627293111756444,
      0.02612612023949623,
      0.009946671314537525,
      0.005662001669406891,
      0.041567251086235046,
      -0.029571061953902245,
      0.027397073805332184,
      -0.02282685786485672,
      0.06029920652508736,
      -0.03263326361775398,
      -0.0812859833240509
    ],
    [
      0.014575369656085968,
      0.010868272744119167,
      0.18002213537693024,
      0.10740739852190018,
      -0.09495844691991806,
      -0.1792360246181488,
      -0.14070647954940796,
      -0.11679455637931824,
      -0.11395992338657379,
      0.32463526725769043,
      -0.005352763459086418,
      -0.1357513666152954,
      0.08056435734033585,
      -0.013930589891970158,
      0.005727583542466164,
      -0.07622925937175751,
      0.09035462141036987
    ],
    [
      -0.03246850147843361,
      -0.036588288843631744,
      0.0093852449208498,
      -0.04405828192830086,
      -0.06155962869524956,
      -0.02711394615471363,
      0.0028970264829695225,
      -0.06948365271091461,
      -0.08175048232078552,
      -0.11204487085342407,
      0.06916802376508713,
      0.04972255602478981,
      -0.15419183671474457,
      -0.07563795149326324,
      0.026684315875172615,
      -0.09315206855535507,
      -0.06139782443642616
    ],
    [
      -0.10296030342578888,
      -0.08832348138093948,
      0.14279769361019135,
      0.1065119132399559,
      -0.027725473046302795,
      -0.08634265512228012,
      0.012378789484500885,
      -0.3767260015010834,
      -0.11864137649536133,
      0.1328210085630417,
      -0.03428620100021362,
      -0.14913728833198547,
      -0.18098482489585876,
      -0.08768550306558609,
      0.0036410626489669085,
      0.18721431493759155,
      0.1588476300239563
    ],
    [
      -0.006053476594388485,
      0.0035741606261581182,
      -0.08440669625997543,
      0.07779030501842499,
      0.03139053285121918,
      -0.003020048839971423,
      -0.017089001834392548,
      -0.07196248322725296,
      0.030344679951667786,
      0.012561671435832977,
      0.026909442618489265,
      0.06006647273898125,
      -0.1037960797548294,
      0.001053640735335648,
      -0.023493347689509392,
      0.04250241443514824,
      -0.09597717225551605
    ],
    [
      0.03943783417344093,
      0.1023201271891594,
      -0.01217773649841547,
      0.09743334352970123,
      0.008583975955843925,
      0.05056145042181015,
      0.0720926821231842,
      -0.20763719081878662,
      -0.008862610906362534,
      -0.17850206792354584,
      -0.04588041454553604,
      -0.038570795208215714,
      -0.09775374829769135,
      -0.03212704136967659,
      0.11421699821949005,
      0.09759923070669174,
      -0.0982355996966362
    ],
    [
      0.04137703776359558,
      0.021761657670140266,
      0.025737419724464417,
      0.03708133101463318,
      0.014343936927616596,
      -0.03749803081154823,
      -0.04552985355257988,
      -0.01084247324615717,
      0.08143214881420135,
      0.04237411543726921,
      0.03286074474453926,
      -0.09089132398366928,
      0.0491623692214489,
      0.008826491422951221,
      0.017833290621638298,
      0.04681440815329552,
      0.01475947443395853
    ],
    [
      0.01279434934258461,
      0.05092184245586395,
      -0.013844586908817291,
      0.02436229959130287,
      -0.029259534552693367,
      -0.1192069947719574,
      0.09042791277170181,
      0.02681807056069374,
      0.0442468523979187,
      -0.021226955577731133,
      -0.04215869680047035,
      -0.1200401708483696,
      0.022859441116452217,
      0.04891597107052803,
      -0.02025093324482441,
      -0.09568819403648376,
      0.015040045604109764
    ],
    [
      -0.11677533388137817,
      -0.18819695711135864,
      -0.1272794008255005,
      -0.07233447581529617,
      -0.09692421555519104,
      0.13481730222702026,
      0.03418901562690735,
      0.02300967276096344,
      -0.053235456347465515,
      0.1387099176645279,
      0.131259024143219,
      0.11369156092405319,
      0.16063076257705688,
      -0.015374657697975636,
      0.0036277284380048513,
      -0.1382015198469162,
      0.10740143805742264
    ],
    [
      0.05785531550645828,
      -0.08868560194969177,
      0.12654238939285278,
      0.0249179694801569,
      -0.028073109686374664,
      -0.012184747494757175,
      -0.11683891713619232,
      -0.0783252939581871,
      -0.09976182132959366,
      0.14157068729400635,
      -0.027900991961359978,
      0.024237805977463722,
      0.03545694425702095,
      -0.01857294887304306,
      -0.07451334595680237,
      -0.06395593285560608,
      -0.032686494290828705
    ],
    [
      -0.06686510890722275,
      -0.10656533390283585,
      -0.020357895642518997,
      -0.058247897773981094,
      0.020682716742157936,
      0.026213744655251503,
      0.02305554784834385,
      0.004418991040438414,
      0.040161870419979095,
      0.031216025352478027,
      -0.015615248121321201,
      -0.006894825957715511,
      -0.06654246151447296,
      0.01037256047129631,
      -0.0018195478478446603,
      -0.0662892535328865,
      -0.05597386136651039
    ],
    [
      0.13431409001350403,
      0.08329229056835175,
      -0.10209772735834122,
      -0.19788415729999542,
      0.019234489649534225,
      0.08437515050172806,
      0.16013629734516144,
      0.15736466646194458,
      -0.13992755115032196,
      -0.0035901516675949097,
      0.04891810566186905,
      -0.024928787723183632,
      -0.241917222738266,
      -0.12190171331167221,
      -0.09496023505926132,
      0.15130238234996796,
      -0.08980170637369156
    ],
    [
      -0.25782856345176697,
      -0.032555822283029556,
      0.18536560237407684,
      -0.07857505977153778,
      -0.09008973836898804,
      -0.0558357760310173,
      0.18126451969146729,
      0.026427308097481728,
      0.08801665157079697,
      0.12371647357940674,
      -0.09457500278949738,
      0.2246887981891632,
      0.11818764358758926,
      0.07714176177978516,
      -0.016409503296017647,
      0.016266988590359688,
      0.01167430728673935
    ],
    [
      -0.24073219299316406,
      -0.13348285853862762,
      0.10943319648504257,
      0.16219213604927063,
      0.06158756464719772,
      -0.16182157397270203,
      -0.08273553848266602,
      -0.0055313012562692165,
      -0.030017387121915817,
      0.03645174577832222,
      0.06791878491640091,
      0.00018046512559521943,
      0.05470999330282211,
      -0.01061046402901411,
      -0.02136635221540928,
      -0.1360454261302948,
      0.05582528933882713
    ],
    [
      -0.12860770523548126,
      0.16831591725349426,
      0.06054234504699707,
      -0.058577559888362885,
      0.04776456579566002,
      -0.042921990156173706,
      -0.19694893062114716,
      0.02896692417562008,
      0.004410174209624529,
      0.16747908294200897,
      0.0022994554601609707,
      0.1740308403968811,
      -0.07187259942293167,
      0.09225555509328842,
      0.03980281203985214,
      -0.0648449957370758,
      0.08074715733528137
    ],
    [
      -0.09378773719072342,
      -0.09083639085292816,
      0.08448323607444763,
      -0.10652296990156174,
      0.014514781534671783,
      0.12379856407642365,
      -0.09767992794513702,
      0.08563156425952911,
      0.0763506069779396,
      0.16503116488456726,
      0.09186433255672455,
      0.02302459441125393,
      0.08736308664083481,
      0.009728671982884407,
      0.039729952812194824,
      0.05371551588177681,
      -0.13632580637931824
    ],
    [
      0.06755513697862625,
      0.19217057526111603,
      -0.0033533102832734585,
      0.035093218088150024,
      -0.09028209000825882,
      -0.05126440152525902,
      -0.07340414822101593,
      -0.08008314669132233,
      0.006127815693616867,
      0.01807416044175625,
      -0.01645314134657383,
      -0.08731803297996521,
      0.037740740925073624,
      0.015014203265309334,
      -0.04475352540612221,
      0.03637395799160004,
      -0.04311249032616615
    ],
    [
      0.024581337347626686,
      -0.07923517376184464,
      -0.016637731343507767,
      -0.006296650040894747,
      -0.014821583405137062,
      -0.1581605076789856,
      -0.05497448146343231,
      -0.18627196550369263,
      0.09701723605394363,
      0.015260837972164154,
      0.10277599841356277,
      -0.07144883275032043,
      -0.0797281563282013,
      -0.006155754905194044,
      0.18584226071834564,
      0.11113256961107254,
      -0.018688777461647987
    ],
    [
      0.19490109384059906,
      0.08263298124074936,
      -0.11860230565071106,
      -0.010877239517867565,
      -0.08670260012149811,
      -0.10177572816610336,
      0.054131146520376205,
      0.052657000720500946,
      -0.08848731219768524,
      -0.03145590052008629,
      -0.003577279858291149,
      0.024736031889915466,
      0.06684483587741852,
      -0.03345714136958122,
      0.007342812605202198,
      0.004275825340300798,
      -0.04803796485066414
    ],
    [
      -0.05235211178660393,
      0.07904206216335297,
      -0.007453029043972492,
      -0.04493861272931099,
      0.01566392369568348,
      0.03170877695083618,
      -0.06972211599349976,
      0.013101390562951565,
      0.027167916297912598,
      -0.035691313445568085,
      -0.09272594749927521,
      0.06518450379371643,
      -0.0036221728660166264,
      -0.07124234735965729,
      -0.04491957277059555,
      0.05240989848971367,
      0.004001764114946127
    ],
    [
      0.3890622854232788,
      0.034838173538446426,
      0.15232005715370178,
      -0.015388581901788712,
      0.06696289777755737,
      -0.0756225734949112,
      0.1646965742111206,
      -0.008964125998318195,
      0.04136653617024422,
      -0.044330861419439316,
      -0.15985967218875885,
      -0.042955219745635986,
      -0.09393377602100372,
      -0.009104459546506405,
      0.09902127832174301,
      0.08994436264038086,
      0.10661935061216354
    ],
    [
      0.09453857690095901,
      0.01995163969695568,
      0.07525811344385147,
      0.2906285524368286,
      -0.0024495928082615137,
      -0.085673026740551,
      -0.05999506264925003,
      -0.2127876728773117,
      0.035749927163124084,
      0.03479913994669914,
      0.02173343300819397,
      -0.043846484273672104,
      0.019735129550099373,
      -0.11915126442909241,
      -0.014278499409556389,
      0.02853918820619583,
      0.0902562215924263
    ],
    [
      0.10630279034376144,
      -0.11644170433282852,
      0.18633070588111877,
      0.06715642660856247,
      -0.10590337961912155,
      -0.07864408940076828,
      0.08294524252414703,
      -0.16280250251293182,
      -0.07099329680204391,
      -0.038442548364400864,
      -0.04725443571805954,
      0.12739220261573792,
      -0.004969685338437557,
      0.08458591252565384,
      -0.17568296194076538,
      -0.005194174125790596,
      0.05491352453827858
    ],
    [
      0.02059815637767315,
      0.05716382712125778,
      -0.11512654274702072,
      0.09898994117975235,
      -0.14859996736049652,
      0.04929091036319733,
      0.10732600837945938,
      0.019578227773308754,
      0.008968948386609554,
      -0.12415165454149246,
      0.053322385996580124,
      0.020134415477514267,
      0.06321410834789276,
      -0.010204401798546314,
      0.16378198564052582,
      0.079205222427845,
      -0.026204274967312813
    ],
    [
      -0.07741017639636993,
      -0.028882527723908424,
      -0.05377884581685066,
      -0.08800579607486725,
      0.1092609241604805,
      0.057732466608285904,
      -0.20514804124832153,
      -0.019906098023056984,
      0.01176575105637312,
      0.08749116957187653,
      0.02490171790122986,
      -0.007758622989058495,
      0.089630626142025,
      -0.08781246840953827,
      -0.04612177610397339,
      0.246133953332901,
      -0.08309917151927948
    ],
    [
      -0.21028612554073334,
      -0.015773111954331398,
      -0.08398779481649399,
      -0.09947435557842255,
      -0.05279401317238808,
      -0.054740387946367264,
      -0.11518518626689911,
      0.07670974731445312,
      0.01210925541818142,
      0.024441521614789963,
      0.048248689621686935,
      -0.0834091454744339,
      0.2062021940946579,
      0.07469072937965393,
      0.1440248042345047,
      0.1348709911108017,
      0.04958308860659599
    ],
    [
      0.2094559520483017,
      0.09199953824281693,
      0.13337785005569458,
      -0.07628639042377472,
      0.07349154353141785,
      0.16932141780853271,
      -0.10688663274049759,
      -0.02393055148422718,
      -0.12816226482391357,
      -0.04284321144223213,
      -0.05323891341686249,
      0.041564349085092545,
      0.08883099257946014,
      -0.04920661449432373,
      -0.03559449315071106,
      0.17437617480754852,
      -0.030549487099051476
    ],
    [
      -0.05131727457046509,
      0.03196217864751816,
      0.05627213045954704,
      -0.026895789429545403,
      -0.1490786373615265,
      -0.16761434078216553,
      0.05975756421685219,
      -0.09399347752332687,
      -0.07446324080228806,
      0.1350911259651184,
      0.021372850984334946,
      0.07899223268032074,
      0.040162134915590286,
      0.038516391068696976,
      -0.06601598113775253,
      -0.1221998929977417,
      0.022970039397478104
    ],
    [
      -0.026948777958750725,
      -0.08080384135246277,
      -0.005687246099114418,
      -0.05444440618157387,
      -0.06672558933496475,
      0.19222857058048248,
      0.3205011785030365,
      0.1499481052160263,
      -0.010205095633864403,
      -0.23038625717163086,
      -0.04594091325998306,
      -0.00995349045842886,
      0.018651986494660378,
      -0.038770612329244614,
      0.05074673518538475,
      -0.08850444108247757,
      -0.2081720232963562
    ],
    [
      0.018556226044893265,
      -0.027983013540506363,
      -0.025001993402838707,
      -0.06210634484887123,
      -0.01627897471189499,
      0.015666157007217407,
      0.020783131942152977,
      0.0445864163339138,
      -0.019261028617620468,
      0.049478091299533844,
      0.005270614288747311,
      -0.12821075320243835,
      0.013295510783791542,
      -0.07895590364933014,
      0.05680011957883835,
      -0.06749767065048218,
      -0.057211101055145264
    ],
    [
      0.018958961591124535,
      -0.1175854429602623,
      -0.03326461836695671,
      0.17210212349891663,
      0.1281154602766037,
      0.12309300899505615,
      -0.1289433240890503,
      -0.13785125315189362,
      -0.2188785821199417,
      0.05563288554549217,
      0.05172227323055267,
      -0.06354629993438721,
      -0.12315136194229126,
      -0.14620329439640045,
      -0.021711664274334908,
      0.3532983958721161,
      -0.14485418796539307
    ],
    [
      -0.015230619348585606,
      0.04462717846035957,
      -0.31299737095832825,
      -0.21551038324832916,
      -0.05988973006606102,
      0.08692986518144608,
      0.043549709022045135,
      0.07288874685764313,
      0.22004230320453644,
      -0.051052067428827286,
      0.023358557373285294,
      0.1605684757232666,
      -0.009222008287906647,
      0.07353106886148453,
      0.12459838390350342,
      -0.034673307090997696,
      -0.09061217308044434
    ],
    [
      0.021095948293805122,
      0.02224254421889782,
      0.03403288871049881,
      -0.02612120844423771,
      -0.06206173822283745,
      -0.016390692442655563,
      0.07558414340019226,
      -0.05921640247106552,
      0.07474584132432938,
      0.03386382386088371,
      -0.15506504476070404,
      0.07470555603504181,
      0.027561116963624954,
      -0.019592661410570145,
      -0.011065003462135792,
      0.002224882133305073,
      -0.02070116251707077
    ],
    [
      -0.08430267870426178,
      -0.07080765813589096,
      0.006775695830583572,
      -0.009794075973331928,
      0.035313379019498825,
      -0.13646510243415833,
      0.019221285358071327,
      -0.10295981913805008,
      0.12339797616004944,
      -0.022752506658434868,
      0.008686506189405918,
      -0.10485883057117462,
      0.08556527644395828,
      -0.007674371358007193,
      0.040051039308309555,
      -0.04268724471330643,
      0.06323675811290741
    ],
    [
      0.07110127806663513,
      -0.00696189608424902,
      0.0259102750569582,
      -0.012754999101161957,
      -0.1465279906988144,
      -0.09413224458694458,
      0.0060051665641367435,
      0.07088834792375565,
      0.02817535027861595,
      0.01372521836310625,
      -0.04814494773745537,
      -0.0007244946318678558,
      0.047858826816082,
      -0.030354799702763557,
      -0.011290829628705978,
      -0.06839212030172348,
      0.045147549360990524
    ],
    [
      -0.03818916156888008,
      -0.0008405224652960896,
      0.07781541347503662,
      0.09258175641298294,
      0.11429114639759064,
      -0.035590481013059616,
      -0.0786823183298111,
      -0.12106447666883469,
      0.007012463174760342,
      0.08383305370807648,
      0.03660750761628151,
      -0.07381637394428253,
      -0.010437783785164356,
      -0.017728913575410843,
      0.047847334295511246,
      0.006642048247158527,
      0.019083289429545403
    ],
    [
      -0.01993504725396633,
      0.05303340032696724,
      -0.06096554920077324,
      -0.08273569494485855,
      0.018368087708950043,
      -0.06768688559532166,
      -0.06078692898154259,
      -0.018433205783367157,
      -0.05270491540431976,
      -0.01671157218515873,
      0.0751694068312645,
      0.04938632249832153,
      -0.04163242504000664,
      -0.07562156021595001,
      0.027249153703451157,
      -0.0058273570612072945,
      0.045733608305454254
    ],
    [
      0.03229346126317978,
      0.03360858932137489,
      -0.0022968787234276533,
      0.04028860107064247,
      -0.04819867014884949,
      -0.058785226196050644,
      -0.06009221822023392,
      0.014205310493707657,
      -0.06739126145839691,
      -0.02138507552444935,
      -0.07939812541007996,
      -0.07647741585969925,
      0.06372624635696411,
      -0.012587598524987698,
      0.05708329379558563,
      0.001030312036164105,
      0.03754846751689911
    ],
    [
      0.006024531088769436,
      0.009060278534889221,
      0.0003391031932551414,
      -0.03299041837453842,
      0.12378454953432083,
      -0.08092403411865234,
      -0.006627162918448448,
      0.14814196527004242,
      0.04656480252742767,
      -0.002102773869410157,
      0.01059071347117424,
      0.12013154476881027,
      -0.004870407748967409,
      -0.0013028511311858892,
      -0.020248139277100563,
      -0.05527616664767265,
      0.050811767578125
    ],
    [
      -0.04672031104564667,
      0.09875532984733582,
      0.10768691450357437,
      0.1349467933177948,
      -0.027331341058015823,
      0.0034176574554294348,
      -0.12793509662151337,
      -0.31633445620536804,
      0.21447871625423431,
      0.2617378234863281,
      -0.016564788296818733,
      -0.13037577271461487,
      0.14257602393627167,
      0.20213085412979126,
      0.157274529337883,
      -0.1843537539243698,
      -0.24472686648368835
    ],
    [
      -0.2861963212490082,
      -0.1949564516544342,
      0.17889021337032318,
      -0.05829814821481705,
      -0.043375588953495026,
      -0.0002388864813838154,
      -0.06312932819128036,
      -0.009058353491127491,
      -0.04155803099274635,
      0.2772257626056671,
      0.05314144119620323,
      0.19091439247131348,
      -0.011023534461855888,
      0.09604626148939133,
      0.003479689359664917,
      -0.07759980857372284,
      -0.15117238461971283
    ],
    [
      -0.027686668559908867,
      -0.18433356285095215,
      0.2739504873752594,
      -0.05086616054177284,
      -0.0396641306579113,
      -0.07886476069688797,
      -0.026717379689216614,
      -0.17566506564617157,
      0.015587148256599903,
      0.1599699705839157,
      -0.17899489402770996,
      0.181571826338768,
      0.01375128049403429,
      0.07890314608812332,
      0.03848631680011749,
      -0.17650847136974335,
      0.12029241025447845
    ],
    [
      -0.05105791613459587,
      0.08984241634607315,
      -0.06655188649892807,
      -0.060437288135290146,
      0.13103258609771729,
      0.1048918142914772,
      0.03764462098479271,
      -0.020154006779193878,
      0.02979239448904991,
      0.020530935376882553,
      -0.030856426805257797,
      0.005379830487072468,
      -0.1230236291885376,
      0.03447788581252098,
      -0.035295017063617706,
      -0.017925865948200226,
      -0.00260851695202291
    ],
    [
      0.006716177798807621,
      0.05104845017194748,
      0.05404243990778923,
      0.019317327067255974,
      -0.028227463364601135,
      0.005757659208029509,
      0.011170521378517151,
      -0.04189635440707207,
      -0.0019286881433799863,
      0.03231516107916832,
      0.05022602900862694,
      -0.0500616617500782,
      0.10332047939300537,
      -0.011336220428347588,
      0.0370803140103817,
      0.029714256525039673,
      -0.05278630182147026
    ],
    [
      0.03839140012860298,
      0.0906323716044426,
      -0.0045394026674330235,
      0.06095554679632187,
      -0.07770496606826782,
      0.1160927340388298,
      -0.10646731406450272,
      0.04114137962460518,
      0.017819534987211227,
      0.09277479350566864,
      0.029298000037670135,
      -0.013172109611332417,
      0.0277429036796093,
      0.06113006919622421,
      -0.14752209186553955,
      -0.07279594242572784,
      -0.07688972353935242
    ],
    [
      0.031007230281829834,
      0.07114303857088089,
      0.05531082674860954,
      0.08327050507068634,
      -0.03520656377077103,
      -0.04594925045967102,
      0.12782777845859528,
      0.07789680361747742,
      0.0007454708684235811,
      0.061294689774513245,
      -0.10911300033330917,
      -0.08852656185626984,
      0.08972018957138062,
      0.0067542968317866325,
      0.046741634607315063,
      -0.06199716776609421,
      -0.0701894536614418
    ],
    [
      -0.028575757518410683,
      0.0378241240978241,
      -0.14621256291866302,
      0.04072442650794983,
      0.04681636020541191,
      0.07959726452827454,
      -0.024497631937265396,
      -0.22567136585712433,
      0.057846784591674805,
      0.026819853112101555,
      0.10116388648748398,
      0.07819177955389023,
      -0.13192100822925568,
      0.06184729188680649,
      -0.11780039221048355,
      0.04910825565457344,
      0.15702641010284424
    ],
    [
      -0.17634280025959015,
      0.07439567893743515,
      0.020923731848597527,
      -0.11801920086145401,
      -0.14555373787879944,
      -0.0005786256515420973,
      -0.2821333408355713,
      0.01073603518307209,
      0.28259706497192383,
      0.14452631771564484,
      -0.009437345899641514,
      0.08453401178121567,
      0.1722092628479004,
      0.17193397879600525,
      0.013860792852938175,
      -0.14625084400177002,
      0.01999747008085251
    ],
    [
      -0.15027263760566711,
      -0.06663387268781662,
      -0.2403453141450882,
      -0.15135669708251953,
      0.047368552535772324,
      -0.05545420944690704,
      0.0015947349602356553,
      0.08064305782318115,
      -0.1828981339931488,
      0.08454117178916931,
      -0.02595190703868866,
      0.009635127149522305,
      0.06926116347312927,
      -0.042063720524311066,
      0.14003920555114746,
      0.13210634887218475,
      0.17258475720882416
    ],
    [
      0.08940431475639343,
      -0.09054090082645416,
      -0.11416739225387573,
      -0.1536841243505478,
      0.021970301866531372,
      0.010088169947266579,
      0.0361565425992012,
      0.23619550466537476,
      -0.0078577296808362,
      -0.029454344883561134,
      0.022009529173374176,
      0.01846248097717762,
      -0.070655956864357,
      0.13513833284378052,
      -0.10130903869867325,
      -0.0554058812558651,
      0.1845286786556244
    ],
    [
      -0.06409847736358643,
      0.060525089502334595,
      -0.04270470514893532,
      0.029891282320022583,
      -0.02132417820394039,
      -0.029120519757270813,
      -0.029753511771559715,
      -0.045081332325935364,
      -0.064171202480793,
      -0.03879064694046974,
      0.020780887454748154,
      0.07978451997041702,
      -0.05691996589303017,
      0.024790635332465172,
      0.11541309952735901,
      0.0018217961769551039,
      -0.09575881063938141
    ],
    [
      0.07226862758398056,
      -0.04557585343718529,
      0.09568890184164047,
      -0.17686477303504944,
      -0.018005702644586563,
      -0.14452293515205383,
      -0.019675027579069138,
      0.08839035779237747,
      -0.120665043592453,
      0.03021441027522087,
      0.0035709517542272806,
      0.082234688103199,
      -0.03958214446902275,
      0.04639618471264839,
      0.10214684903621674,
      0.10512964427471161,
      0.14531676471233368
    ],
    [
      -0.024135416373610497,
      0.0015419061528518796,
      0.11809587478637695,
      -0.057210907340049744,
      -0.040446486324071884,
      0.025740642100572586,
      -0.0608205571770668,
      0.024952420964837074,
      -0.0308394655585289,
      0.04178668186068535,
      0.09463819116353989,
      -0.024555692449212074,
      -0.026033297181129456,
      0.0118109667673707,
      -0.05637959763407707,
      -0.05247136205434799,
      0.0906590074300766
    ],
    [
      -0.017489098012447357,
      0.019868677482008934,
      -0.012622286565601826,
      0.05750724673271179,
      -0.0020000499207526445,
      -0.017019696533679962,
      -0.03141363337635994,
      -0.05844646319746971,
      0.18708732724189758,
      -0.07841736078262329,
      0.030902303755283356,
      0.08132412284612656,
      -0.1374596506357193,
      0.06592750549316406,
      0.07759638130664825,
      -0.05335831269621849,
      -0.0807703509926796
    ],
    [
      -0.019607754424214363,
      -0.017785724252462387,
      -0.06789736449718475,
      0.10586252808570862,
      0.04381702095270157,
      -0.03431008383631706,
      0.0643351748585701,
      -0.06286915391683578,
      0.024766171351075172,
      0.0006360588013194501,
      0.019929923117160797,
      0.07629112154245377,
      0.16078899800777435,
      0.07282183319330215,
      -0.057060834020376205,
      -0.0720168799161911,
      0.14270341396331787
    ],
    [
      0.021557623520493507,
      -0.035332828760147095,
      0.09852821379899979,
      -0.09811876714229584,
      -0.02806929685175419,
      -0.01195012778043747,
      -0.08247856795787811,
      0.004056510049849749,
      0.027704108506441116,
      0.07412349432706833,
      0.10416076332330704,
      0.025467539206147194,
      -0.018747668713331223,
      0.07779679447412491,
      0.0664290189743042,
      0.11117824167013168,
      0.030002424493432045
    ],
    [
      -0.09366728365421295,
      0.018507160246372223,
      0.15079650282859802,
      0.1116257831454277,
      0.04934696480631828,
      -0.10697323828935623,
      0.0536375567317009,
      -0.16223588585853577,
      0.034806106239557266,
      -0.032904867082834244,
      -0.01310024131089449,
      -0.13659889996051788,
      0.2006874531507492,
      -0.00420459033921361,
      -0.01181561779230833,
      -0.0785188376903534,
      -0.028834640979766846
    ],
    [
      -0.14932876825332642,
      0.07465580105781555,
      -0.03731663152575493,
      -0.022565729916095734,
      0.003294149413704872,
      0.04163818061351776,
      0.14648109674453735,
      -0.061154332011938095,
      0.22887928783893585,
      0.10096091032028198,
      0.15482768416404724,
      0.05213625729084015,
      0.10665043443441391,
      0.04355335235595703,
      0.10554082691669464,
      -0.1493263989686966,
      -0.032735902816057205
    ],
    [
      -0.0879555270075798,
      0.0877690315246582,
      0.02449329011142254,
      -0.05350155383348465,
      0.0411602184176445,
      0.09205181151628494,
      0.048323798924684525,
      0.02127903699874878,
      -0.0784861147403717,
      -0.0037943581119179726,
      0.0033251517452299595,
      0.14584408700466156,
      -0.005823025945574045,
      0.1454564332962036,
      -0.05229635164141655,
      0.010008387267589569,
      0.08941978961229324
    ],
    [
      0.059324268251657486,
      -0.06721234321594238,
      0.07666584849357605,
      0.059281256049871445,
      -0.083483025431633,
      -0.02632819674909115,
      0.1287052482366562,
      -0.06481166183948517,
      -0.039840634912252426,
      -0.10737442970275879,
      -0.0009352139313705266,
      0.05546534061431885,
      0.03193831443786621,
      0.009104570373892784,
      -0.04186026006937027,
      -0.03761834651231766,
      -0.08951739966869354
    ],
    [
      0.05915374681353569,
      0.116590715944767,
      0.04132772982120514,
      0.032529138028621674,
      -0.023326309397816658,
      0.08252694457769394,
      0.0001275897811865434,
      0.05121593549847603,
      0.03673926740884781,
      0.06816749274730682,
      -0.005064455326646566,
      0.03612982854247093,
      0.1006607636809349,
      0.034861937165260315,
      -0.08638272434473038,
      -0.00693510239943862,
      0.07202351093292236
    ],
    [
      0.008743101730942726,
      0.043977610766887665,
      0.1504426747560501,
      0.1068682000041008,
      0.02497454732656479,
      -0.049992967396974564,
      -0.039542462676763535,
      -0.12284008413553238,
      0.0017699087038636208,
      0.09683304280042648,
      -0.09087614715099335,
      0.13685330748558044,
      -0.17741595208644867,
      -0.04566306248307228,
      -0.17810748517513275,
      0.09345964342355728,
      0.08167970925569534
    ],
    [
      -0.03683464601635933,
      0.037904102355241776,
      0.0712629184126854,
      -0.0006719850935041904,
      0.09293138980865479,
      0.008952680975198746,
      0.01656753569841385,
      0.022094281390309334,
      -0.030197864398360252,
      0.06772548705339432,
      -0.039975881576538086,
      0.03593761473894119,
      0.11349304765462875,
      0.07951910048723221,
      0.0001611944317119196,
      0.05236208066344261,
      -0.004796841647475958
    ],
    [
      0.1576184332370758,
      -0.1337919980287552,
      0.035118941217660904,
      -0.03448404744267464,
      0.016620874404907227,
      0.07736673951148987,
      -0.04471208527684212,
      -0.09475594758987427,
      -0.004468785133212805,
      0.07370924949645996,
      -0.02417466603219509,
      -0.040093664079904556,
      -0.060755349695682526,
      0.10447972267866135,
      -0.04789307713508606,
      0.09460296481847763,
      -0.15193074941635132
    ],
    [
      0.05674927681684494,
      0.058530502021312714,
      0.17315740883350372,
      -0.12299054861068726,
      -0.044286295771598816,
      -0.12784726917743683,
      0.09418333321809769,
      0.05144888535141945,
      0.12400773912668228,
      -0.014896534383296967,
      -0.16641166806221008,
      0.08621728420257568,
      -0.07406371831893921,
      -0.03957219794392586,
      0.10059507191181183,
      -0.16090475022792816,
      -0.013836155645549297
    ],
    [
      -0.21503117680549622,
      -0.08120812475681305,
      0.23231837153434753,
      -0.1391499936580658,
      -0.06329039484262466,
      -0.044702883809804916,
      -0.15858471393585205,
      0.06912841647863388,
      0.005352458450943232,
      0.03390038385987282,
      0.05699828639626503,
      -0.04063223674893379,
      -0.06296757608652115,
      0.04215055704116821,
      -0.13825759291648865,
      -0.003442601067945361,
      -0.002639067592099309
    ],
    [
      0.07608164101839066,
      0.06361796706914902,
      -0.08958921581506729,
      0.07226216048002243,
      0.10098322480916977,
      0.003034519264474511,
      0.010899867862462997,
      -0.0831163302063942,
      -0.007751643192023039,
      -0.09191076457500458,
      -0.0705818384885788,
      -0.02584282122552395,
      0.055041953921318054,
      0.033202894032001495,
      0.04061711207032204,
      -0.15516488254070282,
      0.13307276368141174
    ],
    [
      0.051477741450071335,
      0.18244867026805878,
      0.015476391650736332,
      0.040047068148851395,
      -0.02563427947461605,
      0.11038166284561157,
      -0.10338792204856873,
      -0.02633083611726761,
      0.11208456754684448,
      -0.11286202073097229,
      -0.0345323346555233,
      0.038692619651556015,
      -0.06691859662532806,
      -0.0357493981719017,
      -0.017784984782338142,
      -0.18668660521507263,
      -0.0969761461019516
    ],
    [
      -0.044292911887168884,
      0.04636608064174652,
      -0.07143402844667435,
      0.04462762549519539,
      0.04524436593055725,
      -0.018595801666378975,
      -0.112606942653656,
      0.14955386519432068,
      -0.018686871975660324,
      0.01736471988260746,
      0.1035071462392807,
      0.09874967485666275,
      0.08593709766864777,
      -0.08949755132198334,
      -0.07040104269981384,
      -0.07294441014528275,
      -0.02856065146625042
    ],
    [
      0.012258053757250309,
      0.049273956567049026,
      -0.004245298448950052,
      0.06325053423643112,
      -0.021902238950133324,
      -0.02739778161048889,
      0.023323217406868935,
      -0.0933302789926529,
      0.10398126393556595,
      -0.0834077000617981,
      0.032012730836868286,
      -0.024450363591313362,
      -0.00035394023871049285,
      0.0009313103510066867,
      -0.06415709108114243,
      0.04372084513306618,
      -0.08656808733940125
    ],
    [
      -0.0467509850859642,
      0.05803334712982178,
      0.039925746619701385,
      0.004027176182717085,
      -0.0155413169413805,
      -0.01671835221350193,
      0.13040794432163239,
      0.028828313574194908,
      0.05904362350702286,
      0.012703864835202694,
      7.113379979273304e-05,
      0.08579286187887192,
      -0.2960730493068695,
      -0.06368096172809601,
      0.057241760194301605,
      0.006190984044224024,
      0.008357476443052292
    ],
    [
      -0.13624386489391327,
      0.04593096673488617,
      -0.06387108564376831,
      -0.01928591914474964,
      -0.018163319677114487,
      0.03291342779994011,
      0.028993934392929077,
      0.07955143600702286,
      0.004522550385445356,
      0.042383868247270584,
      0.02574482187628746,
      -0.11484946310520172,
      -0.03675110638141632,
      0.00641824584454298,
      -0.08604995906352997,
      0.007048190571367741,
      0.012483875267207623
    ],
    [
      0.31051915884017944,
      -0.2891627252101898,
      0.0970064327120781,
      0.06141224130988121,
      0.1104283481836319,
      0.1252041906118393,
      0.020168190822005272,
      -0.010898958891630173,
      -0.09182532876729965,
      -0.017798349261283875,
      0.22276881337165833,
      0.05221141129732132,
      -0.12226743996143341,
      0.0012850277125835419,
      -0.20890401303768158,
      0.08659038692712784,
      -0.002415870549157262
    ],
    [
      -0.04946984350681305,
      0.08810475468635559,
      -0.061866480857133865,
      -0.059287700802087784,
      0.12513776123523712,
      -0.004255030769854784,
      -0.017705919221043587,
      0.1036321148276329,
      -0.05631686747074127,
      -0.08365608751773834,
      0.01803557015955448,
      0.05138552933931351,
      0.009977845475077629,
      0.03312766179442406,
      0.04011079668998718,
      0.03624674677848816,
      0.09507979452610016
    ],
    [
      -0.1832979917526245,
      0.07265110313892365,
      -0.09416412562131882,
      0.1408126801252365,
      0.18611139059066772,
      0.04184415191411972,
      -0.11972136795520782,
      0.11659448593854904,
      0.031874850392341614,
      0.15613079071044922,
      0.1167311891913414,
      0.030193649232387543,
      -0.04262949898838997,
      -0.12710772454738617,
      -0.02275046892464161,
      -0.027187824249267578,
      0.07412881404161453
    ],
    [
      0.09641522914171219,
      0.042610712349414825,
      0.04552916809916496,
      -0.19969655573368073,
      -0.08183705806732178,
      -0.3846045434474945,
      -0.023473408073186874,
      -0.0843471884727478,
      0.027986619621515274,
      -0.039064984768629074,
      0.20878289639949799,
      -0.020038343966007233,
      0.08406064659357071,
      0.3301156461238861,
      0.1077275201678276,
      -0.06367474049329758,
      0.12556913495063782
    ],
    [
      -0.024271517992019653,
      0.042496226727962494,
      0.07149758189916611,
      0.07239444553852081,
      -0.016081606969237328,
      -0.030717400833964348,
      -0.010486694052815437,
      -0.10463086515665054,
      -0.020299183204770088,
      -0.02825220115482807,
      -0.0061820559203624725,
      0.029772434383630753,
      0.11199979484081268,
      0.02188679575920105,
      -0.02504449151456356,
      0.03533652797341347,
      -0.024127965793013573
    ],
    [
      0.06139637157320976,
      0.0895046666264534,
      -0.12097003310918808,
      0.05837123841047287,
      -0.04116120561957359,
      0.007712565362453461,
      0.06363767385482788,
      0.20857009291648865,
      0.21862591803073883,
      -0.2613903880119324,
      0.0423441156744957,
      0.003451236290857196,
      0.17649009823799133,
      -0.01756136119365692,
      -0.0688343420624733,
      -0.02774640917778015,
      -0.08276750147342682
    ],
    [
      -0.09220704436302185,
      0.043596647679805756,
      -0.0937080979347229,
      -0.0036973031237721443,
      -0.041538774967193604,
      0.03696658834815025,
      0.023866984993219376,
      0.029510237276554108,
      0.10901837050914764,
      -0.04054086655378342,
      0.005993637256324291,
      -0.2187218964099884,
      0.08641920238733292,
      -0.0115658575668931,
      0.15588654577732086,
      0.015369325876235962,
      0.02366761304438114
    ],
    [
      -0.02564626932144165,
      -0.013788380660116673,
      -0.0009787280578166246,
      -0.0688876137137413,
      0.043091099709272385,
      -0.014275600202381611,
      0.059561505913734436,
      -0.11156793683767319,
      -0.026836082339286804,
      0.03453757241368294,
      0.03263178840279579,
      0.08653539419174194,
      0.03162726014852524,
      -0.03914187103509903,
      0.04215213283896446,
      0.06493476033210754,
      -0.006607439834624529
    ],
    [
      -0.03411616012454033,
      -0.05076371505856514,
      0.10181516408920288,
      0.1411043107509613,
      0.030283277854323387,
      -0.04054195433855057,
      -0.027240553870797157,
      0.01884225383400917,
      -0.06198376789689064,
      -0.1302594542503357,
      -0.05468698590993881,
      -0.030690740793943405,
      0.23883900046348572,
      -0.02109663002192974,
      0.057846516370773315,
      -0.07534940540790558,
      -0.017453236505389214
    ],
    [
      0.1876041144132614,
      0.0027577364817261696,
      0.2765812277793884,
      0.014283628202974796,
      -0.09961730241775513,
      -0.20509487390518188,
      0.33307430148124695,
      -0.17593419551849365,
      -0.1372244954109192,
      0.0719464123249054,
      -0.362904816865921,
      -0.025390608236193657,
      -0.020535219460725784,
      -0.06329695880413055,
      -0.04380658268928528,
      0.09299206733703613,
      0.11943166702985764
    ],
    [
      -0.033245671540498734,
      -0.10313597321510315,
      -0.04223400354385376,
      -0.09741897135972977,
      0.0671800971031189,
      0.14346492290496826,
      -0.027233455330133438,
      0.093356654047966,
      -0.060794394463300705,
      0.060402076691389084,
      0.00231487350538373,
      0.05927443131804466,
      -0.041142065078020096,
      -0.02831665799021721,
      -0.024300260469317436,
      -0.017068732529878616,
      -0.037908561527729034
    ],
    [
      0.09392303228378296,
      -0.05690321698784828,
      -0.020711315795779228,
      0.11192365735769272,
      0.0710165798664093,
      0.19561435282230377,
      0.03345295041799545,
      0.004512512590736151,
      0.13122159242630005,
      -0.16853125393390656,
      0.012023234739899635,
      -0.038271114230155945,
      -0.3722842037677765,
      -0.16322731971740723,
      0.19554324448108673,
      -0.04647986963391304,
      -0.20429034531116486
    ],
    [
      -0.08353330194950104,
      -0.10817650705575943,
      0.10743226855993271,
      -0.16965658962726593,
      -0.1350964456796646,
      -0.028598006814718246,
      0.05709291249513626,
      -0.14689067006111145,
      0.10284565389156342,
      0.07583285868167877,
      0.03969823941588402,
      0.11883411556482315,
      -0.1258908212184906,
      0.1583431363105774,
      0.00150321947876364,
      -0.08994246274232864,
      0.026224741712212563
    ],
    [
      -0.002613597083836794,
      -0.09802695363759995,
      0.03184717148542404,
      0.11714661121368408,
      -0.028422754257917404,
      -0.09612830728292465,
      0.024020984768867493,
      0.001209760899655521,
      0.005400253459811211,
      -0.0011900676181539893,
      -0.02912745252251625,
      -0.006173311732709408,
      -0.10286089032888412,
      0.00956374779343605,
      0.020273540169000626,
      -0.001045983750373125,
      -0.09451265633106232
    ],
    [
      0.07860933989286423,
      0.0630224421620369,
      0.18994228541851044,
      0.21009355783462524,
      0.10356076806783676,
      0.0786353349685669,
      -0.13683682680130005,
      -0.26629015803337097,
      0.08479124307632446,
      -0.05510377883911133,
      -0.04349341616034508,
      0.06132970005273819,
      0.07807976752519608,
      -0.049456868320703506,
      -0.13286055624485016,
      0.021920759230852127,
      -0.2090451866388321
    ],
    [
      -0.08170128613710403,
      -0.05394408851861954,
      0.012765440158545971,
      -0.08252953737974167,
      0.0036484047304838896,
      -0.06917586177587509,
      0.10043012350797653,
      -0.0013149456353858113,
      0.14466571807861328,
      0.0663665160536766,
      -0.011226940900087357,
      0.02174009382724762,
      -0.07386089861392975,
      0.06321359425783157,
      -0.07295193523168564,
      -0.009275004267692566,
      -0.006266685202717781
    ],
    [
      -0.0640224814414978,
      -0.03857002034783363,
      -0.04721957445144653,
      -0.23606014251708984,
      -0.08090675622224808,
      -0.015666261315345764,
      -0.004926102701574564,
      0.2834036648273468,
      0.08341490477323532,
      0.1900634616613388,
      0.09433924406766891,
      -0.03233305737376213,
      0.16348280012607574,
      0.1906995177268982,
      0.1523856520652771,
      -0.25945398211479187,
      -0.19723422825336456
    ],
    [
      0.004473840352147818,
      0.013511989265680313,
      0.0556655190885067,
      -0.07164857536554337,
      0.015802212059497833,
      0.05041433498263359,
      0.049584049731492996,
      0.029326047748327255,
      -0.006006814073771238,
      -0.047037720680236816,
      -0.11762215942144394,
      -0.08445608615875244,
      0.023242086172103882,
      -0.09764400124549866,
      -0.17385533452033997,
      -0.0066041527315974236,
      0.0020193937234580517
    ],
    [
      0.06807761639356613,
      0.09981647878885269,
      -0.029391666874289513,
      0.1784958392381668,
      -0.027834400534629822,
      0.05678737908601761,
      0.014170692302286625,
      0.053255531936883926,
      -0.08726625889539719,
      -0.16289858520030975,
      -0.26906800270080566,
      0.1461440771818161,
      -0.027205249294638634,
      -0.04386691749095917,
      -0.2703944146633148,
      -0.0596468560397625,
      -0.028626656159758568
    ],
    [
      -0.0025163760874420404,
      0.19488313794136047,
      -0.02375686727464199,
      0.10453779995441437,
      0.06576412171125412,
      -0.034873463213443756,
      -0.011628493666648865,
      -0.016312850639224052,
      -0.06182640790939331,
      0.02435939572751522,
      0.0066935415379703045,
      0.017956621944904327,
      0.058482807129621506,
      0.036889638751745224,
      0.04795506224036217,
      -0.0036537209525704384,
      -0.036909304559230804
    ],
    [
      -0.12306128442287445,
      -0.0056397276930511,
      0.049174148589372635,
      -0.07296818494796753,
      -0.02970188856124878,
      0.08538990467786789,
      -0.22221916913986206,
      0.08362632244825363,
      0.11361537128686905,
      -0.04880603775382042,
      0.07877309620380402,
      0.017043953761458397,
      -0.011238239705562592,
      0.011281832121312618,
      0.008644092828035355,
      0.18867094814777374,
      -0.032869987189769745
    ],
    [
      0.07705311477184296,
      -0.004570891615003347,
      -0.05138640105724335,
      0.07977136969566345,
      0.0625002533197403,
      -0.04994509741663933,
      0.04937945306301117,
      -0.020340541377663612,
      0.06558401137590408,
      -0.022197982296347618,
      -0.01688532531261444,
      -0.02239282988011837,
      0.0045512062497437,
      0.035441506654024124,
      0.03598156198859215,
      0.05126744136214256,
      -0.03279726952314377
    ],
    [
      0.00320036499761045,
      -0.045176174491643906,
      -0.05801313742995262,
      -0.017665687948465347,
      -0.11400160193443298,
      0.07508614659309387,
      0.11032449454069138,
      0.06600288301706314,
      0.04071873053908348,
      0.054473016411066055,
      0.0056256926618516445,
      -0.030481811612844467,
      -0.007116703782230616,
      0.15352945029735565,
      -0.021573787555098534,
      -0.02666686475276947,
      -0.07342609018087387
    ],
    [
      -0.052345160394907,
      0.02843339741230011,
      -0.010091066360473633,
      0.012203085236251354,
      0.05922456085681915,
      0.08808094263076782,
      0.012531614862382412,
      0.08841358870267868,
      -0.02431776560842991,
      0.003477270482107997,
      0.10106935352087021,
      0.057090990245342255,
      0.006496323738247156,
      0.07450852543115616,
      0.05695217475295067,
      -0.07899501174688339,
      0.16463115811347961
    ],
    [
      -0.11660811305046082,
      -0.06574613600969315,
      0.07650956511497498,
      0.040268681943416595,
      0.030902069061994553,
      0.19881990551948547,
      -0.2435983121395111,
      -0.18759013712406158,
      -0.1358901709318161,
      0.1821439415216446,
      0.0165262408554554,
      -0.030026152729988098,
      0.03487343713641167,
      -0.04041022062301636,
      -0.05600046366453171,
      0.11454787105321884,
      -0.1727284938097
    ],
    [
      0.00865271594375372,
      0.02868911437690258,
      -0.03673236072063446,
      -0.1457979381084442,
      -0.08771750330924988,
      0.07594023644924164,
      0.01560147199779749,
      0.08769336342811584,
      -0.09829529374837875,
      0.010202549397945404,
      0.05469120666384697,
      0.09632062166929245,
      -0.0487048514187336,
      0.06760881096124649,
      -0.03497326374053955,
      -0.027748212218284607,
      0.021040797233581543
    ],
    [
      -0.08536312729120255,
      0.14866213500499725,
      0.07103464007377625,
      -0.17970415949821472,
      -0.04592667892575264,
      0.011240506544709206,
      0.04307153448462486,
      -0.02224056050181389,
      0.2051461786031723,
      0.04324530065059662,
      -0.15175382792949677,
      0.0213681161403656,
      0.11046990752220154,
      -0.008033196441829205,
      0.003157122991979122,
      -0.10802262276411057,
      -0.10703614354133606
    ],
    [
      -0.135416179895401,
      0.10930338501930237,
      0.08792609721422195,
      0.2345419079065323,
      0.07051584869623184,
      0.034482620656490326,
      0.023116199299693108,
      -0.08981993049383163,
      -0.07192172110080719,
      0.2570030689239502,
      -0.12527790665626526,
      -0.022307753562927246,
      0.03899656981229782,
      -0.10993149131536484,
      -0.14539556205272675,
      0.04782217741012573,
      -0.002594149671494961
    ],
    [
      -0.014852860942482948,
      0.08345450460910797,
      0.0699262022972107,
      0.07934386283159256,
      0.13484510779380798,
      0.0029428342822939157,
      -0.051615625619888306,
      0.12348157912492752,
      -0.05354014039039612,
      0.001362875453196466,
      -0.12760494649410248,
      0.05234067887067795,
      0.00023472194152418524,
      0.002472958527505398,
      -0.025663217529654503,
      0.17269659042358398,
      -0.05196055397391319
    ],
    [
      -0.16146168112754822,
      0.009708591736853123,
      -0.17762154340744019,
      0.11401340365409851,
      0.048913903534412384,
      0.09752560406923294,
      0.023758193477988243,
      -0.03868783265352249,
      0.2586959898471832,
      -0.1728219985961914,
      0.08200465887784958,
      -0.13576312363147736,
      -0.11563166230916977,
      0.03439031541347504,
      0.07316488772630692,
      -0.027511226013302803,
      0.0594332329928875
    ],
    [
      -0.04296140745282173,
      0.08789043873548508,
      0.0498645156621933,
      0.0030639762990176678,
      -0.024109166115522385,
      -0.06470177322626114,
      -0.006613030098378658,
      0.024404630064964294,
      -0.016348861157894135,
      0.07309295237064362,
      -0.14100924134254456,
      -0.031308699399232864,
      -0.029438616707921028,
      -0.016582675278186798,
      -0.08249141275882721,
      0.07945659011602402,
      0.033812154084444046
    ],
    [
      -0.079231396317482,
      -0.26344984769821167,
      0.08686284720897675,
      -0.08388658612966537,
      0.04371034353971481,
      -0.144118070602417,
      0.11945800483226776,
      0.05291295051574707,
      -0.3161337971687317,
      0.03963504359126091,
      -0.09364721179008484,
      0.021282782778143883,
      -0.15144465863704681,
      -0.04069431126117706,
      0.06288397312164307,
      0.011594418436288834,
      0.018164167180657387
    ],
    [
      -0.1672593504190445,
      0.07554534077644348,
      -0.024462372064590454,
      0.14132560789585114,
      -0.024723974987864494,
      0.03543708473443985,
      0.01972879469394684,
      -0.023162610828876495,
      0.11711376160383224,
      -0.14417770504951477,
      0.07458822429180145,
      0.03207726404070854,
      0.045128047466278076,
      -0.12267639487981796,
      -0.07675903290510178,
      -0.025712203234434128,
      -0.03951715677976608
    ],
    [
      0.021054033190011978,
      -0.029935266822576523,
      0.016088802367448807,
      0.021202098578214645,
      -0.08310087770223618,
      -0.07061749696731567,
      0.10381151735782623,
      -0.1317986249923706,
      0.11513122916221619,
      -0.05810169503092766,
      -0.060532860457897186,
      0.09984888881444931,
      0.05343719571828842,
      -0.007077973801642656,
      0.022545741870999336,
      -0.15993347764015198,
      0.07172655314207077
    ],
    [
      0.05505417287349701,
      0.05940155312418938,
      0.02205279842019081,
      -0.1518213301897049,
      -0.10492712259292603,
      0.23819848895072937,
      -0.20732806622982025,
      -0.18981143832206726,
      -0.14803797006607056,
      0.16359016299247742,
      0.09592028707265854,
      -0.09576418250799179,
      -0.06996873021125793,
      0.06949417293071747,
      0.23298896849155426,
      0.06232229992747307,
      -0.1349916160106659
    ],
    [
      -0.09382971376180649,
      -0.1151791587471962,
      0.13787655532360077,
      -0.32553601264953613,
      -0.13178570568561554,
      -0.08067678660154343,
      -0.13739919662475586,
      -0.15609876811504364,
      0.17161189019680023,
      -0.050886739045381546,
      0.03514672443270683,
      0.02215810865163803,
      -0.05901342257857323,
      0.22830304503440857,
      0.13495561480522156,
      0.08330560475587845,
      0.09263653308153152
    ],
    [
      -0.08615249395370483,
      -0.07877963781356812,
      0.004880247171968222,
      0.06217052415013313,
      -0.02982007898390293,
      -0.02423369325697422,
      -0.006726230960339308,
      0.04078333079814911,
      -0.008592220023274422,
      -0.04250694438815117,
      0.016857918351888657,
      -0.0263329166918993,
      -0.024344364181160927,
      0.02624393440783024,
      0.10131452232599258,
      -0.14630001783370972,
      -0.008054557256400585
    ],
    [
      -0.04677298665046692,
      0.057682834565639496,
      0.014145596884191036,
      0.026744937524199486,
      -0.08815609663724899,
      0.04406328126788139,
      -0.08057163655757904,
      -0.04867764189839363,
      -0.159739151597023,
      0.02928181365132332,
      -0.06657174229621887,
      -0.09206625819206238,
      -0.009150202386081219,
      -0.012895886786282063,
      0.06588883697986603,
      -0.11360304057598114,
      0.019283004105091095
    ],
    [
      -0.10100064426660538,
      -0.10790104418992996,
      -0.029427915811538696,
      -0.05531797930598259,
      0.04860568791627884,
      -0.02614821493625641,
      -0.050457511097192764,
      -0.04371998831629753,
      0.023682190105319023,
      0.05665392801165581,
      -0.020710328593850136,
      0.02480299212038517,
      -0.09550950676202774,
      -0.019873203709721565,
      0.12183327227830887,
      0.09885898232460022,
      -0.03203759342432022
    ],
    [
      -0.05804336443543434,
      0.004587581381201744,
      0.004106912761926651,
      -0.04298811033368111,
      -0.11833050847053528,
      0.10241218656301498,
      -0.0991402342915535,
      0.0660739466547966,
      -0.10777153819799423,
      0.07713048160076141,
      -0.04478500038385391,
      0.025397665798664093,
      0.06942252814769745,
      0.017411142587661743,
      -0.06202995032072067,
      0.03522566705942154,
      -0.00046369145275093615
    ],
    [
      0.19521039724349976,
      -0.051046811044216156,
      -0.1697264462709427,
      -0.03294495493173599,
      0.025729473680257797,
      0.004672937095165253,
      0.10082374513149261,
      0.02498611807823181,
      -0.010674049146473408,
      0.009793270379304886,
      0.16468560695648193,
      -0.0647462010383606,
      0.05982609838247299,
      -0.034046564251184464,
      0.026406964287161827,
      0.18111319839954376,
      -0.002006692113354802
    ],
    [
      -0.07931983470916748,
      -0.0870099663734436,
      0.03269713744521141,
      0.04424034059047699,
      -0.0084384148940444,
      -0.10286610573530197,
      -0.06500186771154404,
      -0.1628868132829666,
      -0.011569896712899208,
      0.09374061971902847,
      0.12097939848899841,
      -0.1750623881816864,
      0.08553411066532135,
      -0.10752921551465988,
      0.052816979587078094,
      0.028653599321842194,
      0.08679968863725662
    ],
    [
      -0.0029471428133547306,
      -0.16416919231414795,
      -0.011704280972480774,
      0.06200186163187027,
      -0.07497553527355194,
      -0.03607572987675667,
      -0.18913091719150543,
      -0.034350909292697906,
      0.22983193397521973,
      -0.04669482260942459,
      -0.07731419801712036,
      0.002286656526848674,
      -0.003059651702642441,
      0.07031291723251343,
      0.10261151194572449,
      -0.08215716481208801,
      -0.019350877031683922
    ],
    [
      0.09543197602033615,
      -0.02586555667221546,
      0.024865172803401947,
      -0.07909814268350601,
      -0.08512294292449951,
      0.0011363399680703878,
      -0.05088648572564125,
      0.052410926669836044,
      -0.10315997898578644,
      0.09230711311101913,
      0.06083032488822937,
      0.0955839604139328,
      -0.03086433745920658,
      -0.05440354719758034,
      0.06487470865249634,
      -0.11518886685371399,
      -0.02507087215781212
    ],
    [
      -0.020607994869351387,
      -0.06461908668279648,
      0.05833429843187332,
      -7.43787968531251e-05,
      0.06578415632247925,
      -0.015942785888910294,
      -0.009014280512928963,
      0.03227318823337555,
      0.045127540826797485,
      0.007307250052690506,
      0.0012765961000695825,
      0.024492276832461357,
      -0.00013873499119654298,
      0.02020767331123352,
      0.043720073997974396,
      0.049501240253448486,
      -0.0872420221567154
    ],
    [
      0.23444347083568573,
      -0.11435403674840927,
      -0.06930527836084366,
      0.03811891749501228,
      -0.04344001039862633,
      0.06763625890016556,
      -0.11887983977794647,
      0.09806865453720093,
      0.17778296768665314,
      -0.14016778767108917,
      0.1893937736749649,
      -0.08028559386730194,
      -0.007858372293412685,
      0.049305181950330734,
      0.16275037825107574,
      -0.07362359017133713,
      -0.1387459933757782
    ],
    [
      -0.010435819625854492,
      0.06404489278793335,
      -0.05736113712191582,
      -0.02770170383155346,
      0.10902415961027145,
      -0.15069817006587982,
      0.08823222666978836,
      -0.006833146326243877,
      -0.0019838300067931414,
      0.05873407423496246,
      -0.06848181784152985,
      -0.054202884435653687,
      0.09996029734611511,
      -0.0652594044804573,
      -0.0014046445721760392,
      -0.033208172768354416,
      0.04187959060072899
    ],
    [
      0.02589116245508194,
      0.044113654643297195,
      0.09421050548553467,
      0.10402008146047592,
      0.03939594328403473,
      -0.030388200655579567,
      -0.12379055470228195,
      -0.16268682479858398,
      0.00732917245477438,
      0.025007080286741257,
      -0.07034478336572647,
      -0.06258150190114975,
      0.012101939879357815,
      0.047629982233047485,
      -0.06882043927907944,
      0.08414699882268906,
      -0.011891435831785202
    ],
    [
      -0.241194486618042,
      0.1421920210123062,
      -0.09545733779668808,
      -0.02967146784067154,
      0.05105371028184891,
      0.03428872674703598,
      -0.020351700484752655,
      -0.007828188128769398,
      -0.002256114035844803,
      0.13218851387500763,
      -0.01685408502817154,
      -0.008894453756511211,
      -0.12652070820331573,
      -0.053785596042871475,
      -0.08387431502342224,
      0.1348331719636917,
      -0.0026173314545303583
    ],
    [
      0.0005972939543426037,
      -0.04797012358903885,
      0.05800729617476463,
      0.1240491047501564,
      -0.02649770677089691,
      0.04598021134734154,
      -0.007466737646609545,
      -0.10033757239580154,
      -0.06251510977745056,
      0.004159743897616863,
      -0.02414568141102791,
      0.0063073462806642056,
      -0.021343670785427094,
      0.09930693358182907,
      -0.09448584914207458,
      0.05171606317162514,
      -0.10285795480012894
    ],
    [
      -0.09984830766916275,
      -0.28979063034057617,
      -0.0413559228181839,
      0.3488590717315674,
      0.11841855198144913,
      -0.05534270405769348,
      -0.05969962105154991,
      -0.13058757781982422,
      -0.0388430617749691,
      0.11952341347932816,
      -0.10925497859716415,
      -0.09810634702444077,
      0.05106401443481445,
      -0.07246457785367966,
      -0.047431427985429764,
      0.021783897653222084,
      0.0436369925737381
    ]
  ];
export const b2 = [
    -0.03977227210998535,
    -0.14236055314540863,
    0.020045282319188118,
    -0.06964170932769775,
    -0.05266699194908142,
    0.024600179865956306,
    0.053647272288799286,
    -0.021588871255517006,
    0.1462593376636505,
    -0.01644708402454853,
    0.010913078673183918,
    -0.008022609166800976,
    0.0555918924510479,
    0.011582446284592152,
    0.09853469580411911,
    0.03717072308063507,
    -0.0039902860298752785
  ];